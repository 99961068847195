const UnitsLiptovskýMikuláš = {
  Beňadiková: [
    { lat: 49.0664549, lng: 19.6715476 },
    { lat: 49.0666644, lng: 19.6720298 },
    { lat: 49.0667799, lng: 19.6724247 },
    { lat: 49.0667798, lng: 19.6728537 },
    { lat: 49.0668431, lng: 19.673089 },
    { lat: 49.0670635, lng: 19.6732568 },
    { lat: 49.0671966, lng: 19.6733876 },
    { lat: 49.0673132, lng: 19.6738819 },
    { lat: 49.0673257, lng: 19.6744677 },
    { lat: 49.0674039, lng: 19.6746084 },
    { lat: 49.067394, lng: 19.6747625 },
    { lat: 49.0673917, lng: 19.6749672 },
    { lat: 49.0674017, lng: 19.6751171 },
    { lat: 49.0675653, lng: 19.6752976 },
    { lat: 49.0676915, lng: 19.6754775 },
    { lat: 49.0678988, lng: 19.6758605 },
    { lat: 49.0679835, lng: 19.6761546 },
    { lat: 49.0680106, lng: 19.6762486 },
    { lat: 49.0680066, lng: 19.6764853 },
    { lat: 49.0680044, lng: 19.6766135 },
    { lat: 49.0680508, lng: 19.6770015 },
    { lat: 49.0680806, lng: 19.677214 },
    { lat: 49.0682314, lng: 19.6774702 },
    { lat: 49.0684167, lng: 19.6777092 },
    { lat: 49.0685288, lng: 19.6780753 },
    { lat: 49.0686188, lng: 19.6783805 },
    { lat: 49.0686621, lng: 19.6784145 },
    { lat: 49.0690189, lng: 19.6786358 },
    { lat: 49.0691878, lng: 19.6787852 },
    { lat: 49.0694706, lng: 19.6790692 },
    { lat: 49.0697386, lng: 19.6791391 },
    { lat: 49.0699112, lng: 19.6792992 },
    { lat: 49.0700477, lng: 19.6792996 },
    { lat: 49.0700459, lng: 19.6793875 },
    { lat: 49.0700186, lng: 19.6795606 },
    { lat: 49.0700723, lng: 19.6801113 },
    { lat: 49.0702414, lng: 19.68014 },
    { lat: 49.0705447, lng: 19.6795623 },
    { lat: 49.070563, lng: 19.6795159 },
    { lat: 49.0708411, lng: 19.6797403 },
    { lat: 49.0708166, lng: 19.6797942 },
    { lat: 49.0703956, lng: 19.681025 },
    { lat: 49.0725306, lng: 19.6827339 },
    { lat: 49.0727013, lng: 19.6828742 },
    { lat: 49.0726946, lng: 19.6829521 },
    { lat: 49.0727826, lng: 19.6833348 },
    { lat: 49.0727767, lng: 19.683431 },
    { lat: 49.0727319, lng: 19.6834639 },
    { lat: 49.0727332, lng: 19.6835472 },
    { lat: 49.0727511, lng: 19.6835665 },
    { lat: 49.0726601, lng: 19.6836977 },
    { lat: 49.0726452, lng: 19.6837459 },
    { lat: 49.0726621, lng: 19.683898 },
    { lat: 49.0726068, lng: 19.684254 },
    { lat: 49.0726192, lng: 19.6843271 },
    { lat: 49.072644, lng: 19.6843314 },
    { lat: 49.072642, lng: 19.6844089 },
    { lat: 49.0725673, lng: 19.6844564 },
    { lat: 49.0725982, lng: 19.6845398 },
    { lat: 49.0725662, lng: 19.6846064 },
    { lat: 49.072563, lng: 19.6846883 },
    { lat: 49.0725806, lng: 19.6847562 },
    { lat: 49.0725135, lng: 19.685048 },
    { lat: 49.0725112, lng: 19.6851722 },
    { lat: 49.0724918, lng: 19.6853212 },
    { lat: 49.0724258, lng: 19.6853818 },
    { lat: 49.0724009, lng: 19.6856366 },
    { lat: 49.0723678, lng: 19.6856982 },
    { lat: 49.0723495, lng: 19.6857982 },
    { lat: 49.0722225, lng: 19.6859797 },
    { lat: 49.0722138, lng: 19.6861186 },
    { lat: 49.0721603, lng: 19.6864371 },
    { lat: 49.0721684, lng: 19.6865926 },
    { lat: 49.0721194, lng: 19.6866583 },
    { lat: 49.0721273, lng: 19.6868372 },
    { lat: 49.0721557, lng: 19.6868925 },
    { lat: 49.0720687, lng: 19.6873965 },
    { lat: 49.0720785, lng: 19.687517 },
    { lat: 49.0720007, lng: 19.6877452 },
    { lat: 49.0720206, lng: 19.6878359 },
    { lat: 49.0720129, lng: 19.6881482 },
    { lat: 49.0719931, lng: 19.6883084 },
    { lat: 49.0720126, lng: 19.6884196 },
    { lat: 49.0719847, lng: 19.6885791 },
    { lat: 49.0719934, lng: 19.6887751 },
    { lat: 49.0720415, lng: 19.6888694 },
    { lat: 49.0720293, lng: 19.6889522 },
    { lat: 49.0720091, lng: 19.6890031 },
    { lat: 49.0720167, lng: 19.6890462 },
    { lat: 49.0719966, lng: 19.6891046 },
    { lat: 49.0720161, lng: 19.6892137 },
    { lat: 49.0719617, lng: 19.689336 },
    { lat: 49.0720226, lng: 19.6895379 },
    { lat: 49.0719862, lng: 19.6899164 },
    { lat: 49.0719558, lng: 19.6900665 },
    { lat: 49.0719711, lng: 19.6902108 },
    { lat: 49.0719468, lng: 19.6902697 },
    { lat: 49.0719657, lng: 19.6903648 },
    { lat: 49.0719571, lng: 19.6905029 },
    { lat: 49.0719783, lng: 19.6905769 },
    { lat: 49.071927, lng: 19.6909429 },
    { lat: 49.071964, lng: 19.6910965 },
    { lat: 49.071938, lng: 19.6911438 },
    { lat: 49.0719172, lng: 19.6914901 },
    { lat: 49.0718738, lng: 19.6916534 },
    { lat: 49.0718877, lng: 19.6918931 },
    { lat: 49.0719091, lng: 19.6919441 },
    { lat: 49.0719131, lng: 19.6920087 },
    { lat: 49.0718857, lng: 19.6921254 },
    { lat: 49.071864, lng: 19.6923231 },
    { lat: 49.0719002, lng: 19.6925601 },
    { lat: 49.0718318, lng: 19.6926893 },
    { lat: 49.0718498, lng: 19.6928732 },
    { lat: 49.0717752, lng: 19.6929916 },
    { lat: 49.0717108, lng: 19.6933169 },
    { lat: 49.0717064, lng: 19.6937054 },
    { lat: 49.0716705, lng: 19.6941165 },
    { lat: 49.0716396, lng: 19.6943619 },
    { lat: 49.0716064, lng: 19.6947074 },
    { lat: 49.072303, lng: 19.6949182 },
    { lat: 49.0729468, lng: 19.6951337 },
    { lat: 49.0730765, lng: 19.695188 },
    { lat: 49.0733652, lng: 19.6952943 },
    { lat: 49.0737377, lng: 19.6954647 },
    { lat: 49.0741892, lng: 19.6957142 },
    { lat: 49.0742395, lng: 19.6957576 },
    { lat: 49.0745186, lng: 19.6959562 },
    { lat: 49.075541, lng: 19.696276 },
    { lat: 49.075771, lng: 19.6963517 },
    { lat: 49.0760944, lng: 19.6964034 },
    { lat: 49.0769172, lng: 19.6964429 },
    { lat: 49.0779751, lng: 19.6965939 },
    { lat: 49.0779795, lng: 19.6965169 },
    { lat: 49.0783079, lng: 19.6965545 },
    { lat: 49.0789868, lng: 19.6968247 },
    { lat: 49.0796006, lng: 19.697076 },
    { lat: 49.0800961, lng: 19.6972751 },
    { lat: 49.0805911, lng: 19.6974876 },
    { lat: 49.081076, lng: 19.6978826 },
    { lat: 49.0813654, lng: 19.6984654 },
    { lat: 49.0820908, lng: 19.6999676 },
    { lat: 49.0823266, lng: 19.7005132 },
    { lat: 49.0828787, lng: 19.7018987 },
    { lat: 49.0839739, lng: 19.7044548 },
    { lat: 49.0842745, lng: 19.7048859 },
    { lat: 49.0845717, lng: 19.705192 },
    { lat: 49.0846259, lng: 19.7052695 },
    { lat: 49.0847051, lng: 19.7053049 },
    { lat: 49.0855644, lng: 19.7058814 },
    { lat: 49.0859111, lng: 19.7062604 },
    { lat: 49.0863919, lng: 19.706918 },
    { lat: 49.0871131, lng: 19.7077926 },
    { lat: 49.0874096, lng: 19.7080287 },
    { lat: 49.0878599, lng: 19.7085618 },
    { lat: 49.0887984, lng: 19.7096091 },
    { lat: 49.0891147, lng: 19.7098874 },
    { lat: 49.0897059, lng: 19.710367 },
    { lat: 49.0899298, lng: 19.7106159 },
    { lat: 49.0904768, lng: 19.7108372 },
    { lat: 49.0903324, lng: 19.7088584 },
    { lat: 49.0905113, lng: 19.7077251 },
    { lat: 49.0906492, lng: 19.7068674 },
    { lat: 49.0906957, lng: 19.7065938 },
    { lat: 49.0908315, lng: 19.706068 },
    { lat: 49.0910533, lng: 19.7052722 },
    { lat: 49.0914861, lng: 19.7044052 },
    { lat: 49.0917444, lng: 19.7040042 },
    { lat: 49.091896, lng: 19.7037856 },
    { lat: 49.0919518, lng: 19.7036753 },
    { lat: 49.0921369, lng: 19.703274 },
    { lat: 49.0925552, lng: 19.7023074 },
    { lat: 49.0928804, lng: 19.7009919 },
    { lat: 49.0929913, lng: 19.700574 },
    { lat: 49.0929927, lng: 19.700525 },
    { lat: 49.0930696, lng: 19.699484 },
    { lat: 49.0932951, lng: 19.6988749 },
    { lat: 49.0935683, lng: 19.6982747 },
    { lat: 49.0939635, lng: 19.6975042 },
    { lat: 49.0940461, lng: 19.6975907 },
    { lat: 49.0942829, lng: 19.6971343 },
    { lat: 49.0945626, lng: 19.6965758 },
    { lat: 49.0947442, lng: 19.6961507 },
    { lat: 49.0948845, lng: 19.6959063 },
    { lat: 49.0950899, lng: 19.6956322 },
    { lat: 49.095298, lng: 19.6953732 },
    { lat: 49.0954327, lng: 19.695307 },
    { lat: 49.0956441, lng: 19.6951487 },
    { lat: 49.0957024, lng: 19.6943986 },
    { lat: 49.0959563, lng: 19.6936943 },
    { lat: 49.0960658, lng: 19.6933146 },
    { lat: 49.0961565, lng: 19.69315 },
    { lat: 49.096028, lng: 19.6929931 },
    { lat: 49.0959642, lng: 19.6930018 },
    { lat: 49.0956487, lng: 19.6927745 },
    { lat: 49.0954928, lng: 19.6925596 },
    { lat: 49.0953331, lng: 19.6922359 },
    { lat: 49.0950478, lng: 19.6919437 },
    { lat: 49.0950362, lng: 19.6919392 },
    { lat: 49.0948442, lng: 19.6920077 },
    { lat: 49.0947502, lng: 19.6918131 },
    { lat: 49.0946391, lng: 19.6915693 },
    { lat: 49.0946512, lng: 19.6915666 },
    { lat: 49.094714, lng: 19.6914311 },
    { lat: 49.0946451, lng: 19.6912997 },
    { lat: 49.0946531, lng: 19.691277 },
    { lat: 49.0946028, lng: 19.6911815 },
    { lat: 49.0944398, lng: 19.6912165 },
    { lat: 49.0943816, lng: 19.6911757 },
    { lat: 49.0942545, lng: 19.6912272 },
    { lat: 49.0941517, lng: 19.6910217 },
    { lat: 49.094126, lng: 19.6910006 },
    { lat: 49.0941127, lng: 19.691003 },
    { lat: 49.0940134, lng: 19.6911509 },
    { lat: 49.0939538, lng: 19.6911534 },
    { lat: 49.0938975, lng: 19.6910542 },
    { lat: 49.0937804, lng: 19.6908789 },
    { lat: 49.0936457, lng: 19.6906554 },
    { lat: 49.0935217, lng: 19.6906332 },
    { lat: 49.0934281, lng: 19.6905023 },
    { lat: 49.0934262, lng: 19.6904856 },
    { lat: 49.0934495, lng: 19.6903721 },
    { lat: 49.0932399, lng: 19.6901973 },
    { lat: 49.0931922, lng: 19.690085 },
    { lat: 49.0931079, lng: 19.6900616 },
    { lat: 49.0930611, lng: 19.6899701 },
    { lat: 49.0929284, lng: 19.6898992 },
    { lat: 49.0927927, lng: 19.6896618 },
    { lat: 49.0927362, lng: 19.6896809 },
    { lat: 49.0926058, lng: 19.689485 },
    { lat: 49.0924722, lng: 19.68937 },
    { lat: 49.0924591, lng: 19.6893771 },
    { lat: 49.0923491, lng: 19.6895918 },
    { lat: 49.0923335, lng: 19.6895967 },
    { lat: 49.0922206, lng: 19.6895356 },
    { lat: 49.092211, lng: 19.6895215 },
    { lat: 49.0922031, lng: 19.6892408 },
    { lat: 49.0921986, lng: 19.6892204 },
    { lat: 49.0920854, lng: 19.6892536 },
    { lat: 49.0920647, lng: 19.6893262 },
    { lat: 49.0920446, lng: 19.689352 },
    { lat: 49.0920286, lng: 19.6893479 },
    { lat: 49.0918716, lng: 19.6889563 },
    { lat: 49.0916907, lng: 19.6889389 },
    { lat: 49.0915429, lng: 19.6890133 },
    { lat: 49.0915292, lng: 19.6890101 },
    { lat: 49.091478, lng: 19.6889711 },
    { lat: 49.0913499, lng: 19.6887757 },
    { lat: 49.0913349, lng: 19.6887679 },
    { lat: 49.0912239, lng: 19.6888037 },
    { lat: 49.0911648, lng: 19.688717 },
    { lat: 49.091147, lng: 19.6886728 },
    { lat: 49.0911533, lng: 19.6885582 },
    { lat: 49.0911398, lng: 19.6885578 },
    { lat: 49.0910782, lng: 19.6884948 },
    { lat: 49.0910643, lng: 19.6882809 },
    { lat: 49.0910976, lng: 19.6881153 },
    { lat: 49.091054, lng: 19.6879936 },
    { lat: 49.0909647, lng: 19.6879607 },
    { lat: 49.0909271, lng: 19.6879279 },
    { lat: 49.0908873, lng: 19.6879183 },
    { lat: 49.0907756, lng: 19.6878384 },
    { lat: 49.0907699, lng: 19.6878106 },
    { lat: 49.0907909, lng: 19.6875752 },
    { lat: 49.090774, lng: 19.687395 },
    { lat: 49.0907498, lng: 19.6873835 },
    { lat: 49.0906613, lng: 19.6874691 },
    { lat: 49.0906516, lng: 19.6875582 },
    { lat: 49.0905798, lng: 19.6876928 },
    { lat: 49.0905627, lng: 19.6876841 },
    { lat: 49.090535, lng: 19.6876176 },
    { lat: 49.0904608, lng: 19.6875997 },
    { lat: 49.0904789, lng: 19.6874713 },
    { lat: 49.0903906, lng: 19.6872826 },
    { lat: 49.0903938, lng: 19.6872535 },
    { lat: 49.0904723, lng: 19.6871977 },
    { lat: 49.090473, lng: 19.6871613 },
    { lat: 49.0905164, lng: 19.6869933 },
    { lat: 49.0905145, lng: 19.6869511 },
    { lat: 49.0903922, lng: 19.6869129 },
    { lat: 49.0903101, lng: 19.6869677 },
    { lat: 49.0901869, lng: 19.686857 },
    { lat: 49.0901596, lng: 19.6867746 },
    { lat: 49.0901329, lng: 19.6867842 },
    { lat: 49.0900607, lng: 19.6868329 },
    { lat: 49.0899238, lng: 19.6866137 },
    { lat: 49.0900895, lng: 19.6863627 },
    { lat: 49.0900595, lng: 19.6862665 },
    { lat: 49.0900166, lng: 19.6862134 },
    { lat: 49.0899189, lng: 19.6862213 },
    { lat: 49.0897869, lng: 19.6863962 },
    { lat: 49.0896787, lng: 19.6863936 },
    { lat: 49.0897087, lng: 19.6862083 },
    { lat: 49.0897348, lng: 19.6861834 },
    { lat: 49.0897264, lng: 19.686021 },
    { lat: 49.0895732, lng: 19.6856898 },
    { lat: 49.0895576, lng: 19.6856958 },
    { lat: 49.089196, lng: 19.6848324 },
    { lat: 49.0891613, lng: 19.6844793 },
    { lat: 49.0890073, lng: 19.6842043 },
    { lat: 49.0890074, lng: 19.6840526 },
    { lat: 49.0887168, lng: 19.6835997 },
    { lat: 49.0887299, lng: 19.6832335 },
    { lat: 49.0887571, lng: 19.6831602 },
    { lat: 49.0887229, lng: 19.6831494 },
    { lat: 49.0884707, lng: 19.6829502 },
    { lat: 49.0883308, lng: 19.6828672 },
    { lat: 49.0883239, lng: 19.6828391 },
    { lat: 49.088457, lng: 19.6827424 },
    { lat: 49.0885342, lng: 19.6826259 },
    { lat: 49.0885639, lng: 19.6824845 },
    { lat: 49.0885382, lng: 19.6824642 },
    { lat: 49.0884759, lng: 19.682454 },
    { lat: 49.0884434, lng: 19.6824358 },
    { lat: 49.0882587, lng: 19.6824328 },
    { lat: 49.0882435, lng: 19.6824203 },
    { lat: 49.0882023, lng: 19.6821043 },
    { lat: 49.0881267, lng: 19.6818989 },
    { lat: 49.0880629, lng: 19.6816995 },
    { lat: 49.0879786, lng: 19.6816581 },
    { lat: 49.0879089, lng: 19.6815822 },
    { lat: 49.0877267, lng: 19.6814388 },
    { lat: 49.0876006, lng: 19.6816401 },
    { lat: 49.087583, lng: 19.6816531 },
    { lat: 49.0874053, lng: 19.6815803 },
    { lat: 49.0873502, lng: 19.6814835 },
    { lat: 49.0873433, lng: 19.6814537 },
    { lat: 49.0875719, lng: 19.680918 },
    { lat: 49.0875757, lng: 19.6806998 },
    { lat: 49.0875683, lng: 19.680633 },
    { lat: 49.0875, lng: 19.6806167 },
    { lat: 49.0874285, lng: 19.6806315 },
    { lat: 49.0873242, lng: 19.680414 },
    { lat: 49.0872784, lng: 19.6801157 },
    { lat: 49.0872874, lng: 19.680091 },
    { lat: 49.0874122, lng: 19.6799341 },
    { lat: 49.087486, lng: 19.6798676 },
    { lat: 49.0873267, lng: 19.6798304 },
    { lat: 49.0867933, lng: 19.6797431 },
    { lat: 49.0866947, lng: 19.6796019 },
    { lat: 49.0865185, lng: 19.6795409 },
    { lat: 49.0864372, lng: 19.6794641 },
    { lat: 49.0864013, lng: 19.6795443 },
    { lat: 49.0862659, lng: 19.6796901 },
    { lat: 49.0861067, lng: 19.6797593 },
    { lat: 49.0859709, lng: 19.6797204 },
    { lat: 49.0859033, lng: 19.6795965 },
    { lat: 49.0859357, lng: 19.6794098 },
    { lat: 49.0858934, lng: 19.679394 },
    { lat: 49.0857786, lng: 19.6794227 },
    { lat: 49.0855889, lng: 19.67926 },
    { lat: 49.0855744, lng: 19.6792381 },
    { lat: 49.0855922, lng: 19.6791805 },
    { lat: 49.0857418, lng: 19.6791522 },
    { lat: 49.0857488, lng: 19.6791282 },
    { lat: 49.0857447, lng: 19.6790905 },
    { lat: 49.0855807, lng: 19.6790214 },
    { lat: 49.0855936, lng: 19.6789611 },
    { lat: 49.085308, lng: 19.6787435 },
    { lat: 49.0852447, lng: 19.6786375 },
    { lat: 49.0851943, lng: 19.6786197 },
    { lat: 49.0848965, lng: 19.6785848 },
    { lat: 49.0846429, lng: 19.6784552 },
    { lat: 49.0845279, lng: 19.6783006 },
    { lat: 49.0835218, lng: 19.67723 },
    { lat: 49.0834259, lng: 19.6771295 },
    { lat: 49.0825321, lng: 19.6761757 },
    { lat: 49.0826237, lng: 19.6759575 },
    { lat: 49.0821758, lng: 19.6760674 },
    { lat: 49.0819044, lng: 19.676022 },
    { lat: 49.081784, lng: 19.676075 },
    { lat: 49.0817066, lng: 19.6759542 },
    { lat: 49.0816924, lng: 19.675942 },
    { lat: 49.0816113, lng: 19.6754332 },
    { lat: 49.0815544, lng: 19.6755184 },
    { lat: 49.0814497, lng: 19.6755486 },
    { lat: 49.0813702, lng: 19.6755338 },
    { lat: 49.0812717, lng: 19.6754192 },
    { lat: 49.0812956, lng: 19.6752981 },
    { lat: 49.0811643, lng: 19.675234 },
    { lat: 49.0810559, lng: 19.6752817 },
    { lat: 49.080927, lng: 19.6751915 },
    { lat: 49.0808691, lng: 19.6752107 },
    { lat: 49.0808504, lng: 19.6751929 },
    { lat: 49.0807564, lng: 19.6748787 },
    { lat: 49.0807072, lng: 19.6747846 },
    { lat: 49.0806936, lng: 19.6747824 },
    { lat: 49.0806136, lng: 19.6751126 },
    { lat: 49.0804514, lng: 19.6752148 },
    { lat: 49.0804345, lng: 19.6752165 },
    { lat: 49.0803406, lng: 19.6750519 },
    { lat: 49.0802048, lng: 19.6750669 },
    { lat: 49.080019, lng: 19.6749922 },
    { lat: 49.0799719, lng: 19.6749948 },
    { lat: 49.0797769, lng: 19.6748682 },
    { lat: 49.0797576, lng: 19.6748634 },
    { lat: 49.079678, lng: 19.6748734 },
    { lat: 49.0796667, lng: 19.6748684 },
    { lat: 49.079582, lng: 19.6747908 },
    { lat: 49.0794526, lng: 19.674844 },
    { lat: 49.0794187, lng: 19.6748191 },
    { lat: 49.0794158, lng: 19.6748022 },
    { lat: 49.0794489, lng: 19.6747603 },
    { lat: 49.0794456, lng: 19.6747391 },
    { lat: 49.0793561, lng: 19.6747001 },
    { lat: 49.0792773, lng: 19.6745537 },
    { lat: 49.079266, lng: 19.674548 },
    { lat: 49.0791783, lng: 19.6745297 },
    { lat: 49.0791668, lng: 19.6745481 },
    { lat: 49.0791936, lng: 19.6747729 },
    { lat: 49.0791085, lng: 19.674884 },
    { lat: 49.0788714, lng: 19.6748983 },
    { lat: 49.0787112, lng: 19.6747394 },
    { lat: 49.0784585, lng: 19.674609 },
    { lat: 49.0782974, lng: 19.6745806 },
    { lat: 49.0781667, lng: 19.6745031 },
    { lat: 49.0779022, lng: 19.6744846 },
    { lat: 49.0778968, lng: 19.6744658 },
    { lat: 49.0779295, lng: 19.6743143 },
    { lat: 49.077916, lng: 19.6743132 },
    { lat: 49.0778779, lng: 19.6742413 },
    { lat: 49.077777, lng: 19.6739987 },
    { lat: 49.0776198, lng: 19.6738351 },
    { lat: 49.0776592, lng: 19.673807 },
    { lat: 49.0776939, lng: 19.6737272 },
    { lat: 49.0776551, lng: 19.6736622 },
    { lat: 49.0776344, lng: 19.6736536 },
    { lat: 49.0775382, lng: 19.6737727 },
    { lat: 49.0775111, lng: 19.673769 },
    { lat: 49.0774592, lng: 19.6738714 },
    { lat: 49.0773449, lng: 19.6738324 },
    { lat: 49.0772654, lng: 19.6737705 },
    { lat: 49.0771914, lng: 19.6735772 },
    { lat: 49.077078, lng: 19.6734311 },
    { lat: 49.0770556, lng: 19.6733835 },
    { lat: 49.0770987, lng: 19.673264 },
    { lat: 49.076989, lng: 19.6732013 },
    { lat: 49.0768738, lng: 19.673096 },
    { lat: 49.0767336, lng: 19.6729522 },
    { lat: 49.0764469, lng: 19.6723589 },
    { lat: 49.0760113, lng: 19.6719117 },
    { lat: 49.0758967, lng: 19.6718128 },
    { lat: 49.0758733, lng: 19.6717764 },
    { lat: 49.0756182, lng: 19.6713799 },
    { lat: 49.0754177, lng: 19.6711274 },
    { lat: 49.0751272, lng: 19.6708256 },
    { lat: 49.0746283, lng: 19.6703202 },
    { lat: 49.0744733, lng: 19.6701043 },
    { lat: 49.0743835, lng: 19.6698584 },
    { lat: 49.0743522, lng: 19.6697377 },
    { lat: 49.0738084, lng: 19.6676863 },
    { lat: 49.0737886, lng: 19.6674961 },
    { lat: 49.0737974, lng: 19.6672623 },
    { lat: 49.0737383, lng: 19.6668192 },
    { lat: 49.073743, lng: 19.6667458 },
    { lat: 49.0738306, lng: 19.6661345 },
    { lat: 49.0735409, lng: 19.6660563 },
    { lat: 49.0738445, lng: 19.6660378 },
    { lat: 49.0739313, lng: 19.6654292 },
    { lat: 49.0739327, lng: 19.6653323 },
    { lat: 49.0739051, lng: 19.6651954 },
    { lat: 49.0738549, lng: 19.6651064 },
    { lat: 49.0737198, lng: 19.665005 },
    { lat: 49.0736833, lng: 19.6649972 },
    { lat: 49.0736079, lng: 19.664946 },
    { lat: 49.0735146, lng: 19.6648223 },
    { lat: 49.0734636, lng: 19.6646421 },
    { lat: 49.0732503, lng: 19.6634383 },
    { lat: 49.0731629, lng: 19.6631976 },
    { lat: 49.0730123, lng: 19.6629281 },
    { lat: 49.0727453, lng: 19.6626997 },
    { lat: 49.0725522, lng: 19.6625615 },
    { lat: 49.0715805, lng: 19.6617915 },
    { lat: 49.0714783, lng: 19.6616709 },
    { lat: 49.0712613, lng: 19.6612781 },
    { lat: 49.0710508, lng: 19.660897 },
    { lat: 49.0708218, lng: 19.6605343 },
    { lat: 49.0707153, lng: 19.6604238 },
    { lat: 49.0705858, lng: 19.6600958 },
    { lat: 49.0705619, lng: 19.6600102 },
    { lat: 49.0705133, lng: 19.6597547 },
    { lat: 49.0705104, lng: 19.6595164 },
    { lat: 49.070491, lng: 19.6593068 },
    { lat: 49.0705143, lng: 19.6591463 },
    { lat: 49.0698463, lng: 19.6590692 },
    { lat: 49.069831, lng: 19.6590549 },
    { lat: 49.069716, lng: 19.6590538 },
    { lat: 49.0695355, lng: 19.6590243 },
    { lat: 49.0689594, lng: 19.6589643 },
    { lat: 49.0686706, lng: 19.6598931 },
    { lat: 49.0684442, lng: 19.6605524 },
    { lat: 49.0683111, lng: 19.6609304 },
    { lat: 49.0681692, lng: 19.6609098 },
    { lat: 49.0679348, lng: 19.6609587 },
    { lat: 49.067821, lng: 19.661033 },
    { lat: 49.067481, lng: 19.6612164 },
    { lat: 49.0671396, lng: 19.6619773 },
    { lat: 49.0670371, lng: 19.6621039 },
    { lat: 49.0670929, lng: 19.6629012 },
    { lat: 49.067185, lng: 19.6635025 },
    { lat: 49.0672935, lng: 19.6637141 },
    { lat: 49.0674765, lng: 19.6642088 },
    { lat: 49.0676512, lng: 19.664539 },
    { lat: 49.0677517, lng: 19.6647492 },
    { lat: 49.0679031, lng: 19.6651124 },
    { lat: 49.0679346, lng: 19.6652367 },
    { lat: 49.0680566, lng: 19.6660811 },
    { lat: 49.0680548, lng: 19.6662207 },
    { lat: 49.0680655, lng: 19.6671693 },
    { lat: 49.0680511, lng: 19.6674305 },
    { lat: 49.0679893, lng: 19.6678893 },
    { lat: 49.0679424, lng: 19.668156 },
    { lat: 49.0677847, lng: 19.6685876 },
    { lat: 49.0676846, lng: 19.6687951 },
    { lat: 49.0674828, lng: 19.6691734 },
    { lat: 49.067309, lng: 19.669418 },
    { lat: 49.0671884, lng: 19.6695953 },
    { lat: 49.0668394, lng: 19.6702111 },
    { lat: 49.0667266, lng: 19.6704612 },
    { lat: 49.0664799, lng: 19.6709185 },
    { lat: 49.0662687, lng: 19.67139 },
    { lat: 49.0664549, lng: 19.6715476 },
  ],
  Bobrovec: [
    { lat: 49.212318, lng: 19.687498 },
    { lat: 49.213613, lng: 19.686213 },
    { lat: 49.21472, lng: 19.685505 },
    { lat: 49.216861, lng: 19.681866 },
    { lat: 49.220129, lng: 19.683341 },
    { lat: 49.222706, lng: 19.682307 },
    { lat: 49.223844, lng: 19.680536 },
    { lat: 49.224949, lng: 19.679577 },
    { lat: 49.226146, lng: 19.676282 },
    { lat: 49.226064, lng: 19.675352 },
    { lat: 49.226417, lng: 19.672916 },
    { lat: 49.226418, lng: 19.671486 },
    { lat: 49.226125, lng: 19.668975 },
    { lat: 49.226309, lng: 19.666499 },
    { lat: 49.22516, lng: 19.662748 },
    { lat: 49.222045, lng: 19.658584 },
    { lat: 49.2207068, lng: 19.6544826 },
    { lat: 49.2196878, lng: 19.6527146 },
    { lat: 49.218873, lng: 19.6518452 },
    { lat: 49.2187192, lng: 19.6515544 },
    { lat: 49.2170407, lng: 19.6493012 },
    { lat: 49.2148986, lng: 19.6476904 },
    { lat: 49.2135099, lng: 19.6478748 },
    { lat: 49.2122198, lng: 19.646021 },
    { lat: 49.2112229, lng: 19.6429048 },
    { lat: 49.2110459, lng: 19.6421679 },
    { lat: 49.2102963, lng: 19.6418329 },
    { lat: 49.209007, lng: 19.6412621 },
    { lat: 49.2078848, lng: 19.6418695 },
    { lat: 49.2057526, lng: 19.6420789 },
    { lat: 49.20497, lng: 19.6423711 },
    { lat: 49.2037641, lng: 19.6410909 },
    { lat: 49.2025438, lng: 19.63977 },
    { lat: 49.2021814, lng: 19.639376 },
    { lat: 49.2011938, lng: 19.6378639 },
    { lat: 49.2007878, lng: 19.6371959 },
    { lat: 49.2005488, lng: 19.6362083 },
    { lat: 49.1991592, lng: 19.6365743 },
    { lat: 49.1970472, lng: 19.6371143 },
    { lat: 49.196141, lng: 19.6368783 },
    { lat: 49.1948834, lng: 19.6373541 },
    { lat: 49.1946311, lng: 19.6375049 },
    { lat: 49.1942405, lng: 19.6377535 },
    { lat: 49.1929286, lng: 19.6385881 },
    { lat: 49.1909327, lng: 19.6398914 },
    { lat: 49.1905827, lng: 19.6396719 },
    { lat: 49.1894644, lng: 19.6390732 },
    { lat: 49.1888141, lng: 19.6387138 },
    { lat: 49.1869925, lng: 19.6377532 },
    { lat: 49.1859681, lng: 19.6372918 },
    { lat: 49.1841064, lng: 19.6361421 },
    { lat: 49.1835557, lng: 19.635794 },
    { lat: 49.1832299, lng: 19.6354611 },
    { lat: 49.1830378, lng: 19.6352714 },
    { lat: 49.1817775, lng: 19.6341935 },
    { lat: 49.181284, lng: 19.6337621 },
    { lat: 49.1803019, lng: 19.6335143 },
    { lat: 49.1799554, lng: 19.6333993 },
    { lat: 49.1794693, lng: 19.6331876 },
    { lat: 49.1780115, lng: 19.6328332 },
    { lat: 49.1769236, lng: 19.6321333 },
    { lat: 49.1764653, lng: 19.6318142 },
    { lat: 49.1748735, lng: 19.6306243 },
    { lat: 49.1730142, lng: 19.6292304 },
    { lat: 49.1701692, lng: 19.6229643 },
    { lat: 49.1695574, lng: 19.6218311 },
    { lat: 49.1695411, lng: 19.617861 },
    { lat: 49.1695261, lng: 19.6170506 },
    { lat: 49.167421, lng: 19.6155007 },
    { lat: 49.1671367, lng: 19.6153395 },
    { lat: 49.1668912, lng: 19.6152667 },
    { lat: 49.166499, lng: 19.6148607 },
    { lat: 49.166211, lng: 19.6142632 },
    { lat: 49.1661244, lng: 19.6142185 },
    { lat: 49.1658531, lng: 19.6140207 },
    { lat: 49.1652111, lng: 19.6136234 },
    { lat: 49.1648099, lng: 19.6131687 },
    { lat: 49.164334, lng: 19.61308 },
    { lat: 49.1638097, lng: 19.612924 },
    { lat: 49.1635971, lng: 19.6123513 },
    { lat: 49.1634111, lng: 19.6120993 },
    { lat: 49.1629021, lng: 19.6113717 },
    { lat: 49.1623951, lng: 19.6108197 },
    { lat: 49.1619483, lng: 19.6096484 },
    { lat: 49.1612459, lng: 19.6078034 },
    { lat: 49.1612096, lng: 19.6076733 },
    { lat: 49.1611543, lng: 19.6075189 },
    { lat: 49.1608498, lng: 19.6067302 },
    { lat: 49.1602468, lng: 19.6057238 },
    { lat: 49.1600334, lng: 19.6053076 },
    { lat: 49.1599685, lng: 19.6050933 },
    { lat: 49.1596748, lng: 19.6043701 },
    { lat: 49.1591819, lng: 19.6035291 },
    { lat: 49.1584481, lng: 19.6037705 },
    { lat: 49.157981, lng: 19.6039589 },
    { lat: 49.1573604, lng: 19.6041391 },
    { lat: 49.156709, lng: 19.6044594 },
    { lat: 49.1562235, lng: 19.60469 },
    { lat: 49.1554751, lng: 19.6053375 },
    { lat: 49.1546412, lng: 19.6059114 },
    { lat: 49.1537987, lng: 19.6063915 },
    { lat: 49.1525473, lng: 19.6073577 },
    { lat: 49.1517655, lng: 19.6080892 },
    { lat: 49.1508886, lng: 19.6088356 },
    { lat: 49.1503902, lng: 19.6093256 },
    { lat: 49.1499464, lng: 19.6100851 },
    { lat: 49.1495613, lng: 19.6098103 },
    { lat: 49.1487481, lng: 19.6112515 },
    { lat: 49.1478372, lng: 19.6123472 },
    { lat: 49.147376, lng: 19.6118071 },
    { lat: 49.1469783, lng: 19.6122631 },
    { lat: 49.1463127, lng: 19.6122669 },
    { lat: 49.1455142, lng: 19.6126246 },
    { lat: 49.142638, lng: 19.6139114 },
    { lat: 49.1409812, lng: 19.6146314 },
    { lat: 49.1396841, lng: 19.6139193 },
    { lat: 49.1390142, lng: 19.6131214 },
    { lat: 49.1383044, lng: 19.6121427 },
    { lat: 49.1378903, lng: 19.6113726 },
    { lat: 49.1374038, lng: 19.6101959 },
    { lat: 49.1364179, lng: 19.6078837 },
    { lat: 49.1360326, lng: 19.6060524 },
    { lat: 49.1353875, lng: 19.6045023 },
    { lat: 49.1354109, lng: 19.6039705 },
    { lat: 49.1354387, lng: 19.6034074 },
    { lat: 49.135224, lng: 19.6028426 },
    { lat: 49.1348471, lng: 19.6019776 },
    { lat: 49.1342983, lng: 19.6012976 },
    { lat: 49.1336637, lng: 19.6002351 },
    { lat: 49.1330154, lng: 19.5991477 },
    { lat: 49.1325031, lng: 19.5987273 },
    { lat: 49.1308429, lng: 19.597362 },
    { lat: 49.1299618, lng: 19.5963308 },
    { lat: 49.1295477, lng: 19.5958911 },
    { lat: 49.1292114, lng: 19.5949005 },
    { lat: 49.1292746, lng: 19.5928508 },
    { lat: 49.1292859, lng: 19.592452 },
    { lat: 49.1284411, lng: 19.5905497 },
    { lat: 49.1275779, lng: 19.589794 },
    { lat: 49.1275047, lng: 19.5899726 },
    { lat: 49.1257998, lng: 19.5891901 },
    { lat: 49.1238116, lng: 19.5889007 },
    { lat: 49.1231189, lng: 19.5871025 },
    { lat: 49.122411, lng: 19.58577 },
    { lat: 49.1219527, lng: 19.5845743 },
    { lat: 49.1218535, lng: 19.5844264 },
    { lat: 49.121881, lng: 19.5818083 },
    { lat: 49.1210169, lng: 19.5805358 },
    { lat: 49.1206055, lng: 19.5796076 },
    { lat: 49.1205232, lng: 19.578954 },
    { lat: 49.120555, lng: 19.578561 },
    { lat: 49.1205537, lng: 19.5778619 },
    { lat: 49.1204919, lng: 19.5771619 },
    { lat: 49.1204554, lng: 19.5768819 },
    { lat: 49.1203202, lng: 19.5760824 },
    { lat: 49.1203049, lng: 19.5758642 },
    { lat: 49.1202743, lng: 19.5747875 },
    { lat: 49.1204574, lng: 19.5716578 },
    { lat: 49.1204761, lng: 19.571498 },
    { lat: 49.1205981, lng: 19.5705542 },
    { lat: 49.1208631, lng: 19.5694383 },
    { lat: 49.1211952, lng: 19.5683588 },
    { lat: 49.1222759, lng: 19.5661205 },
    { lat: 49.1232046, lng: 19.5632347 },
    { lat: 49.1221095, lng: 19.5625924 },
    { lat: 49.1203613, lng: 19.5617544 },
    { lat: 49.1195539, lng: 19.5613169 },
    { lat: 49.1195178, lng: 19.5612862 },
    { lat: 49.1192376, lng: 19.5610998 },
    { lat: 49.1188917, lng: 19.5607775 },
    { lat: 49.1179823, lng: 19.5600489 },
    { lat: 49.1177241, lng: 19.5598465 },
    { lat: 49.1164606, lng: 19.5589901 },
    { lat: 49.1156802, lng: 19.5603184 },
    { lat: 49.1140805, lng: 19.5615688 },
    { lat: 49.1131789, lng: 19.564403 },
    { lat: 49.1131604, lng: 19.5644449 },
    { lat: 49.1124285, lng: 19.565227 },
    { lat: 49.1103792, lng: 19.5676989 },
    { lat: 49.1087446, lng: 19.5691981 },
    { lat: 49.108367, lng: 19.5693956 },
    { lat: 49.107757, lng: 19.5710843 },
    { lat: 49.107616, lng: 19.5718522 },
    { lat: 49.1076255, lng: 19.5722137 },
    { lat: 49.1078179, lng: 19.5723539 },
    { lat: 49.1082984, lng: 19.5725183 },
    { lat: 49.1085763, lng: 19.572706 },
    { lat: 49.1086448, lng: 19.5731254 },
    { lat: 49.1088959, lng: 19.5733202 },
    { lat: 49.1092221, lng: 19.5736576 },
    { lat: 49.1092372, lng: 19.5740455 },
    { lat: 49.1091405, lng: 19.5745976 },
    { lat: 49.1096057, lng: 19.5752933 },
    { lat: 49.1100802, lng: 19.5761445 },
    { lat: 49.1105106, lng: 19.5766472 },
    { lat: 49.1105375, lng: 19.5769652 },
    { lat: 49.1106919, lng: 19.5774428 },
    { lat: 49.1105994, lng: 19.5778398 },
    { lat: 49.1108509, lng: 19.5783968 },
    { lat: 49.1106862, lng: 19.5786403 },
    { lat: 49.1104914, lng: 19.579193 },
    { lat: 49.1100598, lng: 19.5802879 },
    { lat: 49.1095575, lng: 19.5815417 },
    { lat: 49.1083951, lng: 19.5834794 },
    { lat: 49.1080671, lng: 19.5848968 },
    { lat: 49.1077152, lng: 19.5857381 },
    { lat: 49.1075299, lng: 19.5857287 },
    { lat: 49.1069968, lng: 19.5883017 },
    { lat: 49.1061804, lng: 19.5908629 },
    { lat: 49.1058947, lng: 19.5922266 },
    { lat: 49.1056588, lng: 19.5928453 },
    { lat: 49.1053877, lng: 19.5942296 },
    { lat: 49.1051077, lng: 19.5942919 },
    { lat: 49.1049491, lng: 19.5943516 },
    { lat: 49.1049329, lng: 19.5943386 },
    { lat: 49.1049173, lng: 19.5943427 },
    { lat: 49.1048427, lng: 19.59459 },
    { lat: 49.1047594, lng: 19.5948418 },
    { lat: 49.1045705, lng: 19.5952254 },
    { lat: 49.1043011, lng: 19.5956232 },
    { lat: 49.1041016, lng: 19.5959227 },
    { lat: 49.1040284, lng: 19.5960745 },
    { lat: 49.1038552, lng: 19.5964554 },
    { lat: 49.1036793, lng: 19.5968553 },
    { lat: 49.1030403, lng: 19.59848 },
    { lat: 49.1056342, lng: 19.6031484 },
    { lat: 49.1059761, lng: 19.6036655 },
    { lat: 49.1066532, lng: 19.6048899 },
    { lat: 49.1074405, lng: 19.6075719 },
    { lat: 49.1078459, lng: 19.610083 },
    { lat: 49.1092016, lng: 19.6125737 },
    { lat: 49.1107013, lng: 19.6145615 },
    { lat: 49.1117309, lng: 19.6151376 },
    { lat: 49.1119537, lng: 19.6152601 },
    { lat: 49.1120272, lng: 19.615061 },
    { lat: 49.1137185, lng: 19.6171531 },
    { lat: 49.1146747, lng: 19.61901 },
    { lat: 49.1161092, lng: 19.619922 },
    { lat: 49.1164974, lng: 19.6198335 },
    { lat: 49.1165714, lng: 19.6199517 },
    { lat: 49.1175762, lng: 19.6223151 },
    { lat: 49.1191915, lng: 19.6242462 },
    { lat: 49.1200762, lng: 19.6243274 },
    { lat: 49.1248616, lng: 19.6252438 },
    { lat: 49.1266505, lng: 19.6252923 },
    { lat: 49.127434, lng: 19.6258546 },
    { lat: 49.1286257, lng: 19.6265026 },
    { lat: 49.1296898, lng: 19.6258594 },
    { lat: 49.1306048, lng: 19.6253076 },
    { lat: 49.1322248, lng: 19.6261623 },
    { lat: 49.1343783, lng: 19.6266037 },
    { lat: 49.1347914, lng: 19.6267987 },
    { lat: 49.1352008, lng: 19.6270099 },
    { lat: 49.1357973, lng: 19.6264644 },
    { lat: 49.1358288, lng: 19.6264388 },
    { lat: 49.1358449, lng: 19.6264451 },
    { lat: 49.1374057, lng: 19.6267048 },
    { lat: 49.1393447, lng: 19.6269595 },
    { lat: 49.1409536, lng: 19.6279934 },
    { lat: 49.1412462, lng: 19.6282646 },
    { lat: 49.1412689, lng: 19.6282655 },
    { lat: 49.1423212, lng: 19.6291779 },
    { lat: 49.1437226, lng: 19.6299534 },
    { lat: 49.1437754, lng: 19.6299051 },
    { lat: 49.1440854, lng: 19.6305607 },
    { lat: 49.1445649, lng: 19.6309576 },
    { lat: 49.1450346, lng: 19.6312275 },
    { lat: 49.1451374, lng: 19.6312865 },
    { lat: 49.1451843, lng: 19.6313795 },
    { lat: 49.1452453, lng: 19.6317885 },
    { lat: 49.1459563, lng: 19.6326498 },
    { lat: 49.1460934, lng: 19.6326154 },
    { lat: 49.1463138, lng: 19.6328096 },
    { lat: 49.1463221, lng: 19.6328411 },
    { lat: 49.1463781, lng: 19.6328583 },
    { lat: 49.1465361, lng: 19.6326645 },
    { lat: 49.1470678, lng: 19.6323589 },
    { lat: 49.1474814, lng: 19.632288 },
    { lat: 49.1482067, lng: 19.6326601 },
    { lat: 49.1485673, lng: 19.6323823 },
    { lat: 49.1489915, lng: 19.6330272 },
    { lat: 49.1492598, lng: 19.6330822 },
    { lat: 49.1506931, lng: 19.6333406 },
    { lat: 49.1509356, lng: 19.6341314 },
    { lat: 49.1510869, lng: 19.6343094 },
    { lat: 49.151386, lng: 19.6343259 },
    { lat: 49.1519448, lng: 19.6351843 },
    { lat: 49.1526624, lng: 19.6352824 },
    { lat: 49.1530661, lng: 19.6358737 },
    { lat: 49.1532583, lng: 19.6360665 },
    { lat: 49.1534905, lng: 19.6361958 },
    { lat: 49.153718, lng: 19.6368233 },
    { lat: 49.1542537, lng: 19.6378005 },
    { lat: 49.1549575, lng: 19.6382448 },
    { lat: 49.1550405, lng: 19.6387139 },
    { lat: 49.1551929, lng: 19.6389029 },
    { lat: 49.1552882, lng: 19.6389171 },
    { lat: 49.1553749, lng: 19.6390662 },
    { lat: 49.1556553, lng: 19.6394155 },
    { lat: 49.1562259, lng: 19.6394039 },
    { lat: 49.1563222, lng: 19.6397427 },
    { lat: 49.1567441, lng: 19.6402886 },
    { lat: 49.1568833, lng: 19.6407314 },
    { lat: 49.1570542, lng: 19.6412558 },
    { lat: 49.1572793, lng: 19.6415557 },
    { lat: 49.1576335, lng: 19.6416378 },
    { lat: 49.1580528, lng: 19.6414951 },
    { lat: 49.1582799, lng: 19.6415583 },
    { lat: 49.1584588, lng: 19.6425643 },
    { lat: 49.1585493, lng: 19.6426244 },
    { lat: 49.1587353, lng: 19.6426757 },
    { lat: 49.1590637, lng: 19.6423861 },
    { lat: 49.1592742, lng: 19.6421104 },
    { lat: 49.1594925, lng: 19.6421754 },
    { lat: 49.1595761, lng: 19.6423558 },
    { lat: 49.1595603, lng: 19.6431134 },
    { lat: 49.159642, lng: 19.6437082 },
    { lat: 49.1598559, lng: 19.6439042 },
    { lat: 49.159924, lng: 19.6439358 },
    { lat: 49.16051, lng: 19.643946 },
    { lat: 49.1606471, lng: 19.6441043 },
    { lat: 49.1611454, lng: 19.6446798 },
    { lat: 49.1613838, lng: 19.6448815 },
    { lat: 49.1615816, lng: 19.6449907 },
    { lat: 49.1617659, lng: 19.6456635 },
    { lat: 49.1617737, lng: 19.6460929 },
    { lat: 49.1618781, lng: 19.6462866 },
    { lat: 49.1620069, lng: 19.6464793 },
    { lat: 49.1622146, lng: 19.6465585 },
    { lat: 49.1626312, lng: 19.6465773 },
    { lat: 49.1629747, lng: 19.6468806 },
    { lat: 49.1633268, lng: 19.6471973 },
    { lat: 49.1635936, lng: 19.6476204 },
    { lat: 49.1639033, lng: 19.6475686 },
    { lat: 49.1642063, lng: 19.6476439 },
    { lat: 49.1650446, lng: 19.6477703 },
    { lat: 49.1656091, lng: 19.6488395 },
    { lat: 49.1658724, lng: 19.6488047 },
    { lat: 49.1661275, lng: 19.6486153 },
    { lat: 49.1662884, lng: 19.6484802 },
    { lat: 49.1668786, lng: 19.6489848 },
    { lat: 49.1670564, lng: 19.6492353 },
    { lat: 49.1677283, lng: 19.6494488 },
    { lat: 49.1686203, lng: 19.6509372 },
    { lat: 49.1687284, lng: 19.6510654 },
    { lat: 49.1689704, lng: 19.6513363 },
    { lat: 49.1693368, lng: 19.6513679 },
    { lat: 49.1697303, lng: 19.6513247 },
    { lat: 49.1697794, lng: 19.6513884 },
    { lat: 49.1700101, lng: 19.6518683 },
    { lat: 49.1701914, lng: 19.6523142 },
    { lat: 49.1702278, lng: 19.6523528 },
    { lat: 49.1702965, lng: 19.6524796 },
    { lat: 49.1703791, lng: 19.6525629 },
    { lat: 49.170505, lng: 19.6526338 },
    { lat: 49.170764, lng: 19.6526567 },
    { lat: 49.1709029, lng: 19.6525898 },
    { lat: 49.1713675, lng: 19.6530387 },
    { lat: 49.1715264, lng: 19.6532066 },
    { lat: 49.1716523, lng: 19.6532775 },
    { lat: 49.171847, lng: 19.6533933 },
    { lat: 49.1720573, lng: 19.6535047 },
    { lat: 49.1725249, lng: 19.6539489 },
    { lat: 49.1730949, lng: 19.6540992 },
    { lat: 49.1734933, lng: 19.6551541 },
    { lat: 49.1738393, lng: 19.6554882 },
    { lat: 49.174286, lng: 19.655755 },
    { lat: 49.1745698, lng: 19.6556927 },
    { lat: 49.1755683, lng: 19.656679 },
    { lat: 49.176543, lng: 19.6571391 },
    { lat: 49.1768363, lng: 19.6572219 },
    { lat: 49.1773319, lng: 19.6575276 },
    { lat: 49.1774104, lng: 19.6575663 },
    { lat: 49.1777311, lng: 19.6575341 },
    { lat: 49.1781145, lng: 19.6579462 },
    { lat: 49.1783043, lng: 19.6579846 },
    { lat: 49.1794793, lng: 19.6579332 },
    { lat: 49.1800319, lng: 19.6576644 },
    { lat: 49.1806531, lng: 19.6572729 },
    { lat: 49.1808152, lng: 19.6572925 },
    { lat: 49.1808797, lng: 19.6573723 },
    { lat: 49.1808692, lng: 19.6575455 },
    { lat: 49.1806021, lng: 19.6683202 },
    { lat: 49.1801536, lng: 19.6690049 },
    { lat: 49.1802459, lng: 19.6691794 },
    { lat: 49.1804188, lng: 19.6701847 },
    { lat: 49.1808926, lng: 19.6710427 },
    { lat: 49.1810706, lng: 19.6718332 },
    { lat: 49.1811148, lng: 19.6721927 },
    { lat: 49.1813503, lng: 19.6728574 },
    { lat: 49.1816982, lng: 19.6725191 },
    { lat: 49.1826426, lng: 19.6719451 },
    { lat: 49.1830783, lng: 19.6709185 },
    { lat: 49.1832222, lng: 19.6709631 },
    { lat: 49.1834922, lng: 19.6704444 },
    { lat: 49.1837136, lng: 19.6701511 },
    { lat: 49.1856103, lng: 19.6682051 },
    { lat: 49.1859492, lng: 19.6676699 },
    { lat: 49.1862702, lng: 19.6668787 },
    { lat: 49.1882943, lng: 19.6629037 },
    { lat: 49.1883597, lng: 19.6628019 },
    { lat: 49.1892774, lng: 19.6634503 },
    { lat: 49.1907874, lng: 19.6640473 },
    { lat: 49.1926868, lng: 19.66524 },
    { lat: 49.1932471, lng: 19.6655032 },
    { lat: 49.1951408, lng: 19.6670266 },
    { lat: 49.1953218, lng: 19.6671956 },
    { lat: 49.1958169, lng: 19.6673885 },
    { lat: 49.1967855, lng: 19.6674324 },
    { lat: 49.1969903, lng: 19.6675038 },
    { lat: 49.1979305, lng: 19.6670867 },
    { lat: 49.1998674, lng: 19.6682153 },
    { lat: 49.2001866, lng: 19.6688831 },
    { lat: 49.2006771, lng: 19.6695307 },
    { lat: 49.2014287, lng: 19.6700686 },
    { lat: 49.2028739, lng: 19.6712596 },
    { lat: 49.2034548, lng: 19.6717114 },
    { lat: 49.2038521, lng: 19.672315 },
    { lat: 49.2043548, lng: 19.6723443 },
    { lat: 49.2056154, lng: 19.6742065 },
    { lat: 49.2060814, lng: 19.67514 },
    { lat: 49.2068512, lng: 19.6759893 },
    { lat: 49.207188, lng: 19.6766248 },
    { lat: 49.2076422, lng: 19.6777783 },
    { lat: 49.2088576, lng: 19.6801708 },
    { lat: 49.2097228, lng: 19.6812953 },
    { lat: 49.2104047, lng: 19.6825545 },
    { lat: 49.2109439, lng: 19.6838719 },
    { lat: 49.211448, lng: 19.6853879 },
    { lat: 49.2117045, lng: 19.6860988 },
    { lat: 49.212318, lng: 19.687498 },
  ],
  Bobrovník: [
    { lat: 49.121879, lng: 19.457877 },
    { lat: 49.121739, lng: 19.457841 },
    { lat: 49.121666, lng: 19.457822 },
    { lat: 49.12155, lng: 19.457792 },
    { lat: 49.120654, lng: 19.458496 },
    { lat: 49.120222, lng: 19.458444 },
    { lat: 49.119865, lng: 19.458031 },
    { lat: 49.119441, lng: 19.457893 },
    { lat: 49.118821, lng: 19.457693 },
    { lat: 49.11869, lng: 19.45807 },
    { lat: 49.118306, lng: 19.458581 },
    { lat: 49.117802, lng: 19.458405 },
    { lat: 49.116644, lng: 19.458038 },
    { lat: 49.116149, lng: 19.458015 },
    { lat: 49.115632, lng: 19.458322 },
    { lat: 49.115426, lng: 19.458962 },
    { lat: 49.115128, lng: 19.459085 },
    { lat: 49.115058, lng: 19.458905 },
    { lat: 49.114462, lng: 19.459201 },
    { lat: 49.113657, lng: 19.459292 },
    { lat: 49.112975, lng: 19.459164 },
    { lat: 49.112586, lng: 19.459099 },
    { lat: 49.112255, lng: 19.459126 },
    { lat: 49.111161, lng: 19.459916 },
    { lat: 49.110365, lng: 19.459704 },
    { lat: 49.10961, lng: 19.45962 },
    { lat: 49.109437, lng: 19.459635 },
    { lat: 49.109068, lng: 19.460374 },
    { lat: 49.108745, lng: 19.461121 },
    { lat: 49.1082173, lng: 19.4613697 },
    { lat: 49.1080771, lng: 19.461434 },
    { lat: 49.1075894, lng: 19.461657 },
    { lat: 49.1066035, lng: 19.4630001 },
    { lat: 49.1061003, lng: 19.4634053 },
    { lat: 49.1061674, lng: 19.463856 },
    { lat: 49.1061727, lng: 19.4638996 },
    { lat: 49.1061917, lng: 19.46404 },
    { lat: 49.1061984, lng: 19.4640882 },
    { lat: 49.1061957, lng: 19.4641788 },
    { lat: 49.1061924, lng: 19.4642766 },
    { lat: 49.1061907, lng: 19.4643627 },
    { lat: 49.1061858, lng: 19.4645485 },
    { lat: 49.1061824, lng: 19.4645718 },
    { lat: 49.1061645, lng: 19.464695 },
    { lat: 49.1061351, lng: 19.4648927 },
    { lat: 49.1061046, lng: 19.4649887 },
    { lat: 49.1060752, lng: 19.4650843 },
    { lat: 49.1060327, lng: 19.4652104 },
    { lat: 49.105984, lng: 19.4653526 },
    { lat: 49.1059039, lng: 19.4654968 },
    { lat: 49.1058384, lng: 19.4656156 },
    { lat: 49.1058185, lng: 19.4656505 },
    { lat: 49.1057989, lng: 19.4656871 },
    { lat: 49.1057353, lng: 19.4657522 },
    { lat: 49.105708, lng: 19.4658418 },
    { lat: 49.1052313, lng: 19.4673579 },
    { lat: 49.1044671, lng: 19.4684264 },
    { lat: 49.1043838, lng: 19.4694333 },
    { lat: 49.104357, lng: 19.4699019 },
    { lat: 49.1043157, lng: 19.4705018 },
    { lat: 49.1043043, lng: 19.470962 },
    { lat: 49.1045828, lng: 19.4735586 },
    { lat: 49.1048493, lng: 19.4739167 },
    { lat: 49.1051287, lng: 19.4751381 },
    { lat: 49.1049946, lng: 19.4765103 },
    { lat: 49.104984, lng: 19.4772074 },
    { lat: 49.1049689, lng: 19.4785234 },
    { lat: 49.1056159, lng: 19.4793075 },
    { lat: 49.1056773, lng: 19.4792991 },
    { lat: 49.1067808, lng: 19.4791365 },
    { lat: 49.1080089, lng: 19.4789821 },
    { lat: 49.1085915, lng: 19.4788408 },
    { lat: 49.1092259, lng: 19.4788397 },
    { lat: 49.1101154, lng: 19.4791419 },
    { lat: 49.110924, lng: 19.4799882 },
    { lat: 49.1109012, lng: 19.482312 },
    { lat: 49.1110897, lng: 19.483767 },
    { lat: 49.111135, lng: 19.4846815 },
    { lat: 49.1111392, lng: 19.4847742 },
    { lat: 49.1115162, lng: 19.4848074 },
    { lat: 49.1115137, lng: 19.4849033 },
    { lat: 49.1114882, lng: 19.4860192 },
    { lat: 49.1114705, lng: 19.4868103 },
    { lat: 49.1114487, lng: 19.4877553 },
    { lat: 49.1112448, lng: 19.4888629 },
    { lat: 49.111142, lng: 19.4896432 },
    { lat: 49.1111645, lng: 19.4902328 },
    { lat: 49.1110618, lng: 19.4921219 },
    { lat: 49.1111722, lng: 19.4923411 },
    { lat: 49.1111292, lng: 19.4924841 },
    { lat: 49.1110747, lng: 19.4926694 },
    { lat: 49.1109915, lng: 19.4929455 },
    { lat: 49.1108062, lng: 19.4934533 },
    { lat: 49.110568, lng: 19.4939581 },
    { lat: 49.1102555, lng: 19.4943219 },
    { lat: 49.1102876, lng: 19.4946909 },
    { lat: 49.110966, lng: 19.4959074 },
    { lat: 49.1109489, lng: 19.4966662 },
    { lat: 49.1107571, lng: 19.4972752 },
    { lat: 49.1104237, lng: 19.4973859 },
    { lat: 49.1100919, lng: 19.497272 },
    { lat: 49.1099628, lng: 19.4990944 },
    { lat: 49.1098797, lng: 19.5006005 },
    { lat: 49.1098481, lng: 19.5011175 },
    { lat: 49.1102826, lng: 19.5012062 },
    { lat: 49.1106621, lng: 19.5014702 },
    { lat: 49.111021, lng: 19.5017781 },
    { lat: 49.111673, lng: 19.5016466 },
    { lat: 49.1121878, lng: 19.5013221 },
    { lat: 49.1123997, lng: 19.5012996 },
    { lat: 49.1125846, lng: 19.5013767 },
    { lat: 49.1128701, lng: 19.5017547 },
    { lat: 49.1130354, lng: 19.5022907 },
    { lat: 49.1130934, lng: 19.5026719 },
    { lat: 49.1132971, lng: 19.5031549 },
    { lat: 49.1136137, lng: 19.5035504 },
    { lat: 49.1139034, lng: 19.5037275 },
    { lat: 49.11404, lng: 19.503754 },
    { lat: 49.1142, lng: 19.5036816 },
    { lat: 49.1142988, lng: 19.5040449 },
    { lat: 49.1154344, lng: 19.5049034 },
    { lat: 49.1156269, lng: 19.5045545 },
    { lat: 49.116045, lng: 19.5032267 },
    { lat: 49.1166447, lng: 19.5018745 },
    { lat: 49.1174377, lng: 19.5003187 },
    { lat: 49.1178775, lng: 19.5002288 },
    { lat: 49.1179888, lng: 19.5001486 },
    { lat: 49.1185474, lng: 19.4999918 },
    { lat: 49.1187516, lng: 19.4986002 },
    { lat: 49.1187206, lng: 19.4983398 },
    { lat: 49.1187839, lng: 19.4969435 },
    { lat: 49.1189479, lng: 19.4957519 },
    { lat: 49.1190246, lng: 19.4955293 },
    { lat: 49.1189696, lng: 19.4954111 },
    { lat: 49.1189544, lng: 19.4951044 },
    { lat: 49.1188829, lng: 19.4938634 },
    { lat: 49.1195706, lng: 19.4939445 },
    { lat: 49.1203456, lng: 19.4938945 },
    { lat: 49.1209725, lng: 19.4938522 },
    { lat: 49.1222071, lng: 19.4938665 },
    { lat: 49.1229765, lng: 19.4935181 },
    { lat: 49.1240144, lng: 19.4932295 },
    { lat: 49.1257685, lng: 19.4926564 },
    { lat: 49.1260097, lng: 19.4926549 },
    { lat: 49.1269222, lng: 19.4924052 },
    { lat: 49.1274862, lng: 19.4921509 },
    { lat: 49.1284111, lng: 19.4916311 },
    { lat: 49.1286606, lng: 19.4914908 },
    { lat: 49.1290303, lng: 19.4912716 },
    { lat: 49.1294673, lng: 19.490849 },
    { lat: 49.1297854, lng: 19.490541 },
    { lat: 49.12992, lng: 19.4904284 },
    { lat: 49.1323458, lng: 19.488779 },
    { lat: 49.1325677, lng: 19.4886279 },
    { lat: 49.1339742, lng: 19.4875501 },
    { lat: 49.1342402, lng: 19.4873072 },
    { lat: 49.1344813, lng: 19.4870865 },
    { lat: 49.1348285, lng: 19.4867685 },
    { lat: 49.1351885, lng: 19.486439 },
    { lat: 49.135439, lng: 19.4861755 },
    { lat: 49.1370685, lng: 19.4839689 },
    { lat: 49.1356706, lng: 19.4825321 },
    { lat: 49.1348765, lng: 19.48205 },
    { lat: 49.1339171, lng: 19.4828235 },
    { lat: 49.1329553, lng: 19.4836447 },
    { lat: 49.1307139, lng: 19.4841302 },
    { lat: 49.1304205, lng: 19.4841938 },
    { lat: 49.1303124, lng: 19.4843658 },
    { lat: 49.1300772, lng: 19.4847399 },
    { lat: 49.129691, lng: 19.4843832 },
    { lat: 49.1294143, lng: 19.484734 },
    { lat: 49.1291436, lng: 19.4847312 },
    { lat: 49.1287764, lng: 19.4843382 },
    { lat: 49.1287437, lng: 19.4837239 },
    { lat: 49.1286692, lng: 19.4829619 },
    { lat: 49.1286533, lng: 19.4820489 },
    { lat: 49.1287033, lng: 19.4817201 },
    { lat: 49.1287647, lng: 19.4813131 },
    { lat: 49.1287794, lng: 19.4812162 },
    { lat: 49.1287757, lng: 19.4811101 },
    { lat: 49.1285661, lng: 19.4804236 },
    { lat: 49.1283503, lng: 19.4801408 },
    { lat: 49.1282234, lng: 19.4800266 },
    { lat: 49.1276148, lng: 19.4794832 },
    { lat: 49.1275913, lng: 19.4793878 },
    { lat: 49.1275178, lng: 19.4790888 },
    { lat: 49.127385, lng: 19.4785539 },
    { lat: 49.127458, lng: 19.4785022 },
    { lat: 49.127697, lng: 19.4783342 },
    { lat: 49.1278905, lng: 19.4781063 },
    { lat: 49.128125, lng: 19.4773717 },
    { lat: 49.1283513, lng: 19.4769784 },
    { lat: 49.1283647, lng: 19.4769453 },
    { lat: 49.1285979, lng: 19.4763843 },
    { lat: 49.1288216, lng: 19.4762485 },
    { lat: 49.1292295, lng: 19.4762967 },
    { lat: 49.1294169, lng: 19.4764991 },
    { lat: 49.1289698, lng: 19.4743765 },
    { lat: 49.1289327, lng: 19.4741822 },
    { lat: 49.1286548, lng: 19.4727971 },
    { lat: 49.1285906, lng: 19.4724791 },
    { lat: 49.1281128, lng: 19.4700281 },
    { lat: 49.1280357, lng: 19.4691138 },
    { lat: 49.1279884, lng: 19.4685532 },
    { lat: 49.1279738, lng: 19.468281 },
    { lat: 49.1279332, lng: 19.4675492 },
    { lat: 49.1279942, lng: 19.4660596 },
    { lat: 49.1280354, lng: 19.4652679 },
    { lat: 49.1281862, lng: 19.4651384 },
    { lat: 49.1287009, lng: 19.4649099 },
    { lat: 49.1290091, lng: 19.4648035 },
    { lat: 49.1302537, lng: 19.4643735 },
    { lat: 49.1305487, lng: 19.4641498 },
    { lat: 49.1312541, lng: 19.4636137 },
    { lat: 49.1315939, lng: 19.462871 },
    { lat: 49.1316227, lng: 19.4618049 },
    { lat: 49.1320868, lng: 19.4605599 },
    { lat: 49.1322527, lng: 19.460075 },
    { lat: 49.1322755, lng: 19.460033 },
    { lat: 49.1337002, lng: 19.4575029 },
    { lat: 49.132216, lng: 19.456096 },
    { lat: 49.13175, lng: 19.457359 },
    { lat: 49.130828, lng: 19.459104 },
    { lat: 49.130257, lng: 19.460001 },
    { lat: 49.129307, lng: 19.46061 },
    { lat: 49.128499, lng: 19.46065 },
    { lat: 49.127734, lng: 19.460678 },
    { lat: 49.126487, lng: 19.460523 },
    { lat: 49.125668, lng: 19.460397 },
    { lat: 49.125335, lng: 19.460195 },
    { lat: 49.124887, lng: 19.460013 },
    { lat: 49.124135, lng: 19.459381 },
    { lat: 49.123863, lng: 19.458879 },
    { lat: 49.123344, lng: 19.458409 },
    { lat: 49.123027, lng: 19.458407 },
    { lat: 49.1225, lng: 19.45807 },
    { lat: 49.121879, lng: 19.457877 },
  ],
  Bobrovček: [
    { lat: 49.1369301, lng: 19.5858066 },
    { lat: 49.1366795, lng: 19.5865943 },
    { lat: 49.1364814, lng: 19.587475 },
    { lat: 49.1359165, lng: 19.5886171 },
    { lat: 49.1351887, lng: 19.5900454 },
    { lat: 49.1351671, lng: 19.5901167 },
    { lat: 49.1348388, lng: 19.5897308 },
    { lat: 49.1347465, lng: 19.5895846 },
    { lat: 49.1347, lng: 19.5896003 },
    { lat: 49.1346099, lng: 19.5896802 },
    { lat: 49.1345543, lng: 19.5897673 },
    { lat: 49.1340627, lng: 19.5907369 },
    { lat: 49.1343088, lng: 19.592329 },
    { lat: 49.1343585, lng: 19.5926573 },
    { lat: 49.1338573, lng: 19.5936624 },
    { lat: 49.1336608, lng: 19.5941791 },
    { lat: 49.1334058, lng: 19.5941212 },
    { lat: 49.1331913, lng: 19.5941864 },
    { lat: 49.132985, lng: 19.5943558 },
    { lat: 49.1328589, lng: 19.5944874 },
    { lat: 49.1325664, lng: 19.594845 },
    { lat: 49.1324205, lng: 19.5950599 },
    { lat: 49.1322962, lng: 19.5953258 },
    { lat: 49.1322085, lng: 19.5956537 },
    { lat: 49.1321526, lng: 19.5963446 },
    { lat: 49.1318671, lng: 19.5963111 },
    { lat: 49.1317977, lng: 19.5962946 },
    { lat: 49.1317543, lng: 19.5964329 },
    { lat: 49.1317032, lng: 19.5966981 },
    { lat: 49.1318299, lng: 19.5969122 },
    { lat: 49.1318541, lng: 19.5971454 },
    { lat: 49.1319693, lng: 19.5973298 },
    { lat: 49.1319945, lng: 19.5974901 },
    { lat: 49.1320424, lng: 19.5976017 },
    { lat: 49.132093, lng: 19.597677 },
    { lat: 49.1320901, lng: 19.597759 },
    { lat: 49.1321162, lng: 19.5978401 },
    { lat: 49.1321778, lng: 19.5978808 },
    { lat: 49.1322792, lng: 19.5980321 },
    { lat: 49.132321, lng: 19.5981364 },
    { lat: 49.1323889, lng: 19.5981923 },
    { lat: 49.1324241, lng: 19.5981977 },
    { lat: 49.1324732, lng: 19.5983149 },
    { lat: 49.1325526, lng: 19.5984519 },
    { lat: 49.1325764, lng: 19.598557 },
    { lat: 49.1325776, lng: 19.5986033 },
    { lat: 49.132549, lng: 19.5986714 },
    { lat: 49.1325031, lng: 19.5987273 },
    { lat: 49.1330154, lng: 19.5991477 },
    { lat: 49.1336637, lng: 19.6002351 },
    { lat: 49.1342983, lng: 19.6012976 },
    { lat: 49.1348471, lng: 19.6019776 },
    { lat: 49.135224, lng: 19.6028426 },
    { lat: 49.1354387, lng: 19.6034074 },
    { lat: 49.1354109, lng: 19.6039705 },
    { lat: 49.1353875, lng: 19.6045023 },
    { lat: 49.1360326, lng: 19.6060524 },
    { lat: 49.1364179, lng: 19.6078837 },
    { lat: 49.1374038, lng: 19.6101959 },
    { lat: 49.1378903, lng: 19.6113726 },
    { lat: 49.1383044, lng: 19.6121427 },
    { lat: 49.1390142, lng: 19.6131214 },
    { lat: 49.1396841, lng: 19.6139193 },
    { lat: 49.1409812, lng: 19.6146314 },
    { lat: 49.142638, lng: 19.6139114 },
    { lat: 49.1455142, lng: 19.6126246 },
    { lat: 49.1463127, lng: 19.6122669 },
    { lat: 49.1469783, lng: 19.6122631 },
    { lat: 49.147376, lng: 19.6118071 },
    { lat: 49.1478372, lng: 19.6123472 },
    { lat: 49.1487481, lng: 19.6112515 },
    { lat: 49.1495613, lng: 19.6098103 },
    { lat: 49.1499464, lng: 19.6100851 },
    { lat: 49.1503902, lng: 19.6093256 },
    { lat: 49.1508886, lng: 19.6088356 },
    { lat: 49.1517655, lng: 19.6080892 },
    { lat: 49.1525473, lng: 19.6073577 },
    { lat: 49.1537987, lng: 19.6063915 },
    { lat: 49.1536244, lng: 19.6061477 },
    { lat: 49.1532435, lng: 19.6059458 },
    { lat: 49.1524967, lng: 19.6055248 },
    { lat: 49.1522671, lng: 19.6050534 },
    { lat: 49.1516253, lng: 19.6037828 },
    { lat: 49.1514155, lng: 19.6033465 },
    { lat: 49.1515697, lng: 19.6020649 },
    { lat: 49.1517536, lng: 19.6012902 },
    { lat: 49.1519542, lng: 19.6012412 },
    { lat: 49.1521184, lng: 19.6012853 },
    { lat: 49.152145, lng: 19.6011468 },
    { lat: 49.1522678, lng: 19.6009227 },
    { lat: 49.1525017, lng: 19.6006626 },
    { lat: 49.1526624, lng: 19.6006764 },
    { lat: 49.1528872, lng: 19.6005144 },
    { lat: 49.1531135, lng: 19.6003129 },
    { lat: 49.153126, lng: 19.6002112 },
    { lat: 49.1535153, lng: 19.5998488 },
    { lat: 49.1538116, lng: 19.5999018 },
    { lat: 49.1539048, lng: 19.5998953 },
    { lat: 49.1541002, lng: 19.5997557 },
    { lat: 49.1546414, lng: 19.5991842 },
    { lat: 49.1547263, lng: 19.5992711 },
    { lat: 49.1547955, lng: 19.5993034 },
    { lat: 49.1549186, lng: 19.5993104 },
    { lat: 49.1548981, lng: 19.5992145 },
    { lat: 49.1548599, lng: 19.599035 },
    { lat: 49.1549265, lng: 19.5987099 },
    { lat: 49.1549065, lng: 19.5985158 },
    { lat: 49.1550732, lng: 19.5983112 },
    { lat: 49.1551665, lng: 19.5980607 },
    { lat: 49.1553115, lng: 19.5978976 },
    { lat: 49.1553614, lng: 19.5976785 },
    { lat: 49.155357, lng: 19.5974335 },
    { lat: 49.1553988, lng: 19.5973341 },
    { lat: 49.1554072, lng: 19.5972695 },
    { lat: 49.1554005, lng: 19.597173 },
    { lat: 49.1553298, lng: 19.5969007 },
    { lat: 49.1552889, lng: 19.5967225 },
    { lat: 49.1552632, lng: 19.5962706 },
    { lat: 49.155217, lng: 19.595847 },
    { lat: 49.1551984, lng: 19.5957323 },
    { lat: 49.1551762, lng: 19.5956637 },
    { lat: 49.1550558, lng: 19.595442 },
    { lat: 49.1549923, lng: 19.5951599 },
    { lat: 49.1550074, lng: 19.5950903 },
    { lat: 49.1550007, lng: 19.5950421 },
    { lat: 49.1549622, lng: 19.5949143 },
    { lat: 49.1548917, lng: 19.5947234 },
    { lat: 49.1548895, lng: 19.5946728 },
    { lat: 49.1548494, lng: 19.5944593 },
    { lat: 49.1548456, lng: 19.5943747 },
    { lat: 49.1547854, lng: 19.5941636 },
    { lat: 49.1547724, lng: 19.5940008 },
    { lat: 49.1545597, lng: 19.5936289 },
    { lat: 49.1544718, lng: 19.5933792 },
    { lat: 49.1544812, lng: 19.5932631 },
    { lat: 49.1544411, lng: 19.593196 },
    { lat: 49.1543892, lng: 19.5930185 },
    { lat: 49.1543741, lng: 19.5929078 },
    { lat: 49.1543702, lng: 19.5927732 },
    { lat: 49.1535816, lng: 19.5924993 },
    { lat: 49.1525902, lng: 19.5921 },
    { lat: 49.1523696, lng: 19.5920025 },
    { lat: 49.1521951, lng: 19.5920553 },
    { lat: 49.1519966, lng: 19.5921239 },
    { lat: 49.1520114, lng: 19.5922285 },
    { lat: 49.151757, lng: 19.5928404 },
    { lat: 49.1517832, lng: 19.5928924 },
    { lat: 49.1516776, lng: 19.5931269 },
    { lat: 49.1514754, lng: 19.5933399 },
    { lat: 49.1513098, lng: 19.5934644 },
    { lat: 49.1512165, lng: 19.5934947 },
    { lat: 49.1511031, lng: 19.5934572 },
    { lat: 49.1509051, lng: 19.5935114 },
    { lat: 49.1507293, lng: 19.5934866 },
    { lat: 49.1499728, lng: 19.593548 },
    { lat: 49.1497446, lng: 19.5935092 },
    { lat: 49.1494366, lng: 19.5933736 },
    { lat: 49.1488503, lng: 19.5930163 },
    { lat: 49.1482953, lng: 19.592602 },
    { lat: 49.1474156, lng: 19.5917766 },
    { lat: 49.147638, lng: 19.5911357 },
    { lat: 49.1478572, lng: 19.5906496 },
    { lat: 49.1481343, lng: 19.5900966 },
    { lat: 49.1476702, lng: 19.5896474 },
    { lat: 49.1474149, lng: 19.5892839 },
    { lat: 49.147361, lng: 19.5891866 },
    { lat: 49.1472633, lng: 19.5889165 },
    { lat: 49.1470713, lng: 19.5887048 },
    { lat: 49.146864, lng: 19.588504 },
    { lat: 49.1466176, lng: 19.5883642 },
    { lat: 49.1463799, lng: 19.5880148 },
    { lat: 49.145818, lng: 19.5877478 },
    { lat: 49.145509, lng: 19.5874911 },
    { lat: 49.1452, lng: 19.5872564 },
    { lat: 49.1447496, lng: 19.5873154 },
    { lat: 49.1444485, lng: 19.5873821 },
    { lat: 49.1442836, lng: 19.5873224 },
    { lat: 49.1442405, lng: 19.5873395 },
    { lat: 49.1441988, lng: 19.587342 },
    { lat: 49.1440603, lng: 19.5873907 },
    { lat: 49.1440029, lng: 19.5873222 },
    { lat: 49.1439415, lng: 19.5873578 },
    { lat: 49.1439064, lng: 19.5873298 },
    { lat: 49.1437726, lng: 19.5874096 },
    { lat: 49.1436681, lng: 19.5873176 },
    { lat: 49.1436685, lng: 19.5872733 },
    { lat: 49.1435601, lng: 19.587267 },
    { lat: 49.1434702, lng: 19.587352 },
    { lat: 49.1433092, lng: 19.5873012 },
    { lat: 49.1432579, lng: 19.5872151 },
    { lat: 49.1431442, lng: 19.5872897 },
    { lat: 49.1430551, lng: 19.5871885 },
    { lat: 49.1429862, lng: 19.5871579 },
    { lat: 49.1429296, lng: 19.5872545 },
    { lat: 49.1428682, lng: 19.5872167 },
    { lat: 49.1427068, lng: 19.5872598 },
    { lat: 49.1426697, lng: 19.5872392 },
    { lat: 49.1425962, lng: 19.5872329 },
    { lat: 49.1425216, lng: 19.5872997 },
    { lat: 49.1424031, lng: 19.5873225 },
    { lat: 49.1423095, lng: 19.5872463 },
    { lat: 49.1421944, lng: 19.5872655 },
    { lat: 49.1419836, lng: 19.5870652 },
    { lat: 49.1419038, lng: 19.5871926 },
    { lat: 49.1418278, lng: 19.5871826 },
    { lat: 49.141738, lng: 19.5872373 },
    { lat: 49.1416505, lng: 19.5872443 },
    { lat: 49.1416334, lng: 19.5873166 },
    { lat: 49.1415003, lng: 19.5872906 },
    { lat: 49.1413601, lng: 19.5873812 },
    { lat: 49.1413253, lng: 19.5875313 },
    { lat: 49.1412875, lng: 19.5876191 },
    { lat: 49.1412346, lng: 19.5876455 },
    { lat: 49.1411887, lng: 19.5877547 },
    { lat: 49.1410253, lng: 19.5875742 },
    { lat: 49.1409799, lng: 19.5875937 },
    { lat: 49.1409248, lng: 19.5876452 },
    { lat: 49.140883, lng: 19.5876179 },
    { lat: 49.1407917, lng: 19.5877667 },
    { lat: 49.1407858, lng: 19.5877345 },
    { lat: 49.1406682, lng: 19.5877515 },
    { lat: 49.1405662, lng: 19.5878878 },
    { lat: 49.1405023, lng: 19.5878937 },
    { lat: 49.1403496, lng: 19.5881109 },
    { lat: 49.1402502, lng: 19.5881259 },
    { lat: 49.1402188, lng: 19.5882303 },
    { lat: 49.1401153, lng: 19.5883858 },
    { lat: 49.1399728, lng: 19.5883986 },
    { lat: 49.1399039, lng: 19.5884212 },
    { lat: 49.1398465, lng: 19.5884934 },
    { lat: 49.1398769, lng: 19.5886191 },
    { lat: 49.1398221, lng: 19.5886029 },
    { lat: 49.1397135, lng: 19.5886434 },
    { lat: 49.1397119, lng: 19.5887612 },
    { lat: 49.1396587, lng: 19.5887286 },
    { lat: 49.1393496, lng: 19.5890204 },
    { lat: 49.1391709, lng: 19.589205 },
    { lat: 49.1391454, lng: 19.5892897 },
    { lat: 49.1389515, lng: 19.5894384 },
    { lat: 49.1389197, lng: 19.589482 },
    { lat: 49.1388426, lng: 19.5893692 },
    { lat: 49.1383769, lng: 19.5886633 },
    { lat: 49.1381389, lng: 19.5882542 },
    { lat: 49.1380009, lng: 19.5879696 },
    { lat: 49.137882, lng: 19.5876042 },
    { lat: 49.1374637, lng: 19.5867436 },
    { lat: 49.1369301, lng: 19.5858066 },
  ],
  Bukovina: [
    { lat: 49.1337002, lng: 19.4575029 },
    { lat: 49.1322755, lng: 19.460033 },
    { lat: 49.1322527, lng: 19.460075 },
    { lat: 49.1320868, lng: 19.4605599 },
    { lat: 49.1316227, lng: 19.4618049 },
    { lat: 49.1315939, lng: 19.462871 },
    { lat: 49.1312541, lng: 19.4636137 },
    { lat: 49.1305487, lng: 19.4641498 },
    { lat: 49.1302537, lng: 19.4643735 },
    { lat: 49.1290091, lng: 19.4648035 },
    { lat: 49.1287009, lng: 19.4649099 },
    { lat: 49.1281862, lng: 19.4651384 },
    { lat: 49.1280354, lng: 19.4652679 },
    { lat: 49.1279942, lng: 19.4660596 },
    { lat: 49.1279332, lng: 19.4675492 },
    { lat: 49.1279738, lng: 19.468281 },
    { lat: 49.1279884, lng: 19.4685532 },
    { lat: 49.1280357, lng: 19.4691138 },
    { lat: 49.1281128, lng: 19.4700281 },
    { lat: 49.1285906, lng: 19.4724791 },
    { lat: 49.1286548, lng: 19.4727971 },
    { lat: 49.1289327, lng: 19.4741822 },
    { lat: 49.1289698, lng: 19.4743765 },
    { lat: 49.1294169, lng: 19.4764991 },
    { lat: 49.1292295, lng: 19.4762967 },
    { lat: 49.1288216, lng: 19.4762485 },
    { lat: 49.1285979, lng: 19.4763843 },
    { lat: 49.1283647, lng: 19.4769453 },
    { lat: 49.1283513, lng: 19.4769784 },
    { lat: 49.128125, lng: 19.4773717 },
    { lat: 49.1278905, lng: 19.4781063 },
    { lat: 49.127697, lng: 19.4783342 },
    { lat: 49.127458, lng: 19.4785022 },
    { lat: 49.127385, lng: 19.4785539 },
    { lat: 49.1275178, lng: 19.4790888 },
    { lat: 49.1275913, lng: 19.4793878 },
    { lat: 49.1276148, lng: 19.4794832 },
    { lat: 49.1282234, lng: 19.4800266 },
    { lat: 49.1283503, lng: 19.4801408 },
    { lat: 49.1285661, lng: 19.4804236 },
    { lat: 49.1287757, lng: 19.4811101 },
    { lat: 49.1287794, lng: 19.4812162 },
    { lat: 49.1287647, lng: 19.4813131 },
    { lat: 49.1287033, lng: 19.4817201 },
    { lat: 49.1286533, lng: 19.4820489 },
    { lat: 49.1286692, lng: 19.4829619 },
    { lat: 49.1287437, lng: 19.4837239 },
    { lat: 49.1287764, lng: 19.4843382 },
    { lat: 49.1291436, lng: 19.4847312 },
    { lat: 49.1294143, lng: 19.484734 },
    { lat: 49.129691, lng: 19.4843832 },
    { lat: 49.1300772, lng: 19.4847399 },
    { lat: 49.1303124, lng: 19.4843658 },
    { lat: 49.1304205, lng: 19.4841938 },
    { lat: 49.1307139, lng: 19.4841302 },
    { lat: 49.1329553, lng: 19.4836447 },
    { lat: 49.1339171, lng: 19.4828235 },
    { lat: 49.1348765, lng: 19.48205 },
    { lat: 49.1356706, lng: 19.4825321 },
    { lat: 49.1370685, lng: 19.4839689 },
    { lat: 49.1387069, lng: 19.4822302 },
    { lat: 49.1397083, lng: 19.4842362 },
    { lat: 49.1402901, lng: 19.4855439 },
    { lat: 49.1424664, lng: 19.4846672 },
    { lat: 49.1425931, lng: 19.4840685 },
    { lat: 49.1426555, lng: 19.4840288 },
    { lat: 49.1425117, lng: 19.4835449 },
    { lat: 49.1422642, lng: 19.4827219 },
    { lat: 49.141865, lng: 19.4816326 },
    { lat: 49.141573, lng: 19.4810174 },
    { lat: 49.1413681, lng: 19.4805509 },
    { lat: 49.141258, lng: 19.4801948 },
    { lat: 49.1411565, lng: 19.4799966 },
    { lat: 49.1409124, lng: 19.479513 },
    { lat: 49.1406477, lng: 19.4791738 },
    { lat: 49.140636, lng: 19.4789922 },
    { lat: 49.1407421, lng: 19.4785516 },
    { lat: 49.1410793, lng: 19.4775043 },
    { lat: 49.141243, lng: 19.4768739 },
    { lat: 49.1412668, lng: 19.4767806 },
    { lat: 49.1411385, lng: 19.4766853 },
    { lat: 49.1384483, lng: 19.4745427 },
    { lat: 49.1366406, lng: 19.4732133 },
    { lat: 49.1374611, lng: 19.4706932 },
    { lat: 49.1377888, lng: 19.4699532 },
    { lat: 49.1386859, lng: 19.4689802 },
    { lat: 49.1387659, lng: 19.4688835 },
    { lat: 49.1388573, lng: 19.4687849 },
    { lat: 49.1389251, lng: 19.4687153 },
    { lat: 49.1389474, lng: 19.4687126 },
    { lat: 49.1390057, lng: 19.4687049 },
    { lat: 49.1390089, lng: 19.4687046 },
    { lat: 49.1390173, lng: 19.4685889 },
    { lat: 49.1390186, lng: 19.4685718 },
    { lat: 49.1390261, lng: 19.4684875 },
    { lat: 49.1390321, lng: 19.4683973 },
    { lat: 49.1390342, lng: 19.4683701 },
    { lat: 49.1389965, lng: 19.4683327 },
    { lat: 49.1388382, lng: 19.4681751 },
    { lat: 49.1387731, lng: 19.4680593 },
    { lat: 49.1387289, lng: 19.4679817 },
    { lat: 49.138675, lng: 19.467887 },
    { lat: 49.1386844, lng: 19.467845 },
    { lat: 49.1387269, lng: 19.4676655 },
    { lat: 49.1387106, lng: 19.4676309 },
    { lat: 49.1386743, lng: 19.4675556 },
    { lat: 49.1386706, lng: 19.467546 },
    { lat: 49.1386156, lng: 19.4674021 },
    { lat: 49.1386039, lng: 19.4673703 },
    { lat: 49.1386037, lng: 19.4673649 },
    { lat: 49.1385993, lng: 19.4673204 },
    { lat: 49.1386073, lng: 19.4671968 },
    { lat: 49.1385906, lng: 19.4671065 },
    { lat: 49.1385889, lng: 19.4669972 },
    { lat: 49.138603, lng: 19.466959 },
    { lat: 49.1385742, lng: 19.4669182 },
    { lat: 49.1384912, lng: 19.4664906 },
    { lat: 49.1384445, lng: 19.4663797 },
    { lat: 49.1384524, lng: 19.4663306 },
    { lat: 49.1383153, lng: 19.4663161 },
    { lat: 49.1382893, lng: 19.4663635 },
    { lat: 49.1382333, lng: 19.4662241 },
    { lat: 49.1382194, lng: 19.4660917 },
    { lat: 49.1381569, lng: 19.4658883 },
    { lat: 49.1380788, lng: 19.4658013 },
    { lat: 49.1379295, lng: 19.4657435 },
    { lat: 49.1377923, lng: 19.4656757 },
    { lat: 49.1376399, lng: 19.4657658 },
    { lat: 49.1375068, lng: 19.4659841 },
    { lat: 49.137543, lng: 19.4661605 },
    { lat: 49.1374788, lng: 19.4663808 },
    { lat: 49.1374187, lng: 19.4664444 },
    { lat: 49.1373974, lng: 19.4664489 },
    { lat: 49.1372827, lng: 19.4664752 },
    { lat: 49.1372316, lng: 19.4663713 },
    { lat: 49.1371319, lng: 19.4657234 },
    { lat: 49.1369817, lng: 19.4655685 },
    { lat: 49.1368998, lng: 19.4655506 },
    { lat: 49.1366614, lng: 19.4653422 },
    { lat: 49.136653, lng: 19.4651852 },
    { lat: 49.1366371, lng: 19.4649108 },
    { lat: 49.1366346, lng: 19.4648561 },
    { lat: 49.1366561, lng: 19.464468 },
    { lat: 49.136596, lng: 19.4639738 },
    { lat: 49.136555, lng: 19.4635244 },
    { lat: 49.1364226, lng: 19.4630945 },
    { lat: 49.13606, lng: 19.4618522 },
    { lat: 49.1356935, lng: 19.4614053 },
    { lat: 49.135174, lng: 19.4604026 },
    { lat: 49.1350276, lng: 19.4598944 },
    { lat: 49.1349632, lng: 19.4596713 },
    { lat: 49.134783, lng: 19.4589637 },
    { lat: 49.1343227, lng: 19.4580724 },
    { lat: 49.1337002, lng: 19.4575029 },
  ],
  DemänovskáDolina: [
    { lat: 48.940538, lng: 19.572775 },
    { lat: 48.940054, lng: 19.575388 },
    { lat: 48.940044, lng: 19.57645 },
    { lat: 48.939756, lng: 19.57791 },
    { lat: 48.94023, lng: 19.57879 },
    { lat: 48.940226, lng: 19.579599 },
    { lat: 48.940769, lng: 19.581137 },
    { lat: 48.941098, lng: 19.581533 },
    { lat: 48.941276, lng: 19.582105 },
    { lat: 48.94201, lng: 19.582897 },
    { lat: 48.943225, lng: 19.588412 },
    { lat: 48.943723, lng: 19.590672 },
    { lat: 48.94301, lng: 19.592725 },
    { lat: 48.94176, lng: 19.596192 },
    { lat: 48.941163, lng: 19.597394 },
    { lat: 48.940866, lng: 19.600165 },
    { lat: 48.940653, lng: 19.600846 },
    { lat: 48.941064, lng: 19.602532 },
    { lat: 48.941194, lng: 19.604146 },
    { lat: 48.941099, lng: 19.605385 },
    { lat: 48.941071, lng: 19.60565 },
    { lat: 48.941264, lng: 19.609564 },
    { lat: 48.941923, lng: 19.611077 },
    { lat: 48.941709, lng: 19.612225 },
    { lat: 48.941809, lng: 19.613528 },
    { lat: 48.94108, lng: 19.615923 },
    { lat: 48.940314, lng: 19.618249 },
    { lat: 48.939532, lng: 19.620848 },
    { lat: 48.939521, lng: 19.620898 },
    { lat: 48.939066, lng: 19.622435 },
    { lat: 48.938527, lng: 19.624231 },
    { lat: 48.93849, lng: 19.624794 },
    { lat: 48.938412, lng: 19.625963 },
    { lat: 48.939498, lng: 19.629889 },
    { lat: 48.9401352, lng: 19.6299161 },
    { lat: 48.9410887, lng: 19.6298226 },
    { lat: 48.9413092, lng: 19.629764 },
    { lat: 48.9421287, lng: 19.6292478 },
    { lat: 48.945059, lng: 19.6274056 },
    { lat: 48.9469649, lng: 19.6269437 },
    { lat: 48.9482002, lng: 19.6266493 },
    { lat: 48.9486147, lng: 19.6266197 },
    { lat: 48.9504841, lng: 19.6286627 },
    { lat: 48.9535011, lng: 19.630461 },
    { lat: 48.9555947, lng: 19.6311955 },
    { lat: 48.9559867, lng: 19.6308643 },
    { lat: 48.9582844, lng: 19.6306477 },
    { lat: 48.9596101, lng: 19.6306882 },
    { lat: 48.9603378, lng: 19.6307586 },
    { lat: 48.9639987, lng: 19.629759 },
    { lat: 48.9640452, lng: 19.6297638 },
    { lat: 48.966508, lng: 19.6306661 },
    { lat: 48.9681465, lng: 19.6317555 },
    { lat: 48.9705208, lng: 19.6322644 },
    { lat: 48.9735698, lng: 19.6329228 },
    { lat: 48.9745181, lng: 19.6330818 },
    { lat: 48.9750961, lng: 19.6331898 },
    { lat: 48.9765359, lng: 19.6334402 },
    { lat: 48.9769416, lng: 19.6335138 },
    { lat: 48.9775073, lng: 19.6326595 },
    { lat: 48.97883, lng: 19.633416 },
    { lat: 48.9811327, lng: 19.6327573 },
    { lat: 48.9818788, lng: 19.6316281 },
    { lat: 48.9826095, lng: 19.6298566 },
    { lat: 48.9828552, lng: 19.6297817 },
    { lat: 48.9828453, lng: 19.629079 },
    { lat: 48.9829908, lng: 19.6286801 },
    { lat: 48.9831473, lng: 19.6283267 },
    { lat: 48.9831525, lng: 19.6280417 },
    { lat: 48.9833087, lng: 19.6275818 },
    { lat: 48.9835886, lng: 19.6271899 },
    { lat: 48.9837282, lng: 19.6272586 },
    { lat: 48.9841404, lng: 19.6268795 },
    { lat: 48.9843158, lng: 19.6265676 },
    { lat: 48.9848741, lng: 19.6262824 },
    { lat: 48.9848926, lng: 19.6263949 },
    { lat: 48.9869561, lng: 19.625724 },
    { lat: 48.9901309, lng: 19.6236576 },
    { lat: 48.9912174, lng: 19.6230095 },
    { lat: 48.9925548, lng: 19.6228847 },
    { lat: 48.9928082, lng: 19.6228089 },
    { lat: 48.9937652, lng: 19.6225157 },
    { lat: 48.9952025, lng: 19.6219543 },
    { lat: 48.9957019, lng: 19.6213812 },
    { lat: 48.9956822, lng: 19.6211679 },
    { lat: 48.9964422, lng: 19.6209051 },
    { lat: 48.997032, lng: 19.6204895 },
    { lat: 48.997281, lng: 19.6196621 },
    { lat: 48.9972306, lng: 19.6196699 },
    { lat: 48.9972414, lng: 19.619531 },
    { lat: 48.9972939, lng: 19.6195503 },
    { lat: 48.9973024, lng: 19.619159 },
    { lat: 48.9973476, lng: 19.6172059 },
    { lat: 48.9974006, lng: 19.6148921 },
    { lat: 48.997504, lng: 19.6131106 },
    { lat: 48.9976397, lng: 19.6107857 },
    { lat: 48.9976925, lng: 19.6107856 },
    { lat: 49.000313, lng: 19.6107522 },
    { lat: 49.0003706, lng: 19.6107577 },
    { lat: 49.0024987, lng: 19.6109229 },
    { lat: 49.0026562, lng: 19.6109443 },
    { lat: 49.0036013, lng: 19.6111178 },
    { lat: 49.0048761, lng: 19.6091222 },
    { lat: 49.0067403, lng: 19.6062 },
    { lat: 49.0071851, lng: 19.6055967 },
    { lat: 49.0076027, lng: 19.6045072 },
    { lat: 49.0087999, lng: 19.6043228 },
    { lat: 49.0097666, lng: 19.6043982 },
    { lat: 49.0102629, lng: 19.6040314 },
    { lat: 49.0113299, lng: 19.6028814 },
    { lat: 49.012539, lng: 19.6016064 },
    { lat: 49.0130564, lng: 19.601059 },
    { lat: 49.0140575, lng: 19.6008975 },
    { lat: 49.0148658, lng: 19.6006052 },
    { lat: 49.0154059, lng: 19.6007906 },
    { lat: 49.0158418, lng: 19.6006347 },
    { lat: 49.0160948, lng: 19.6003492 },
    { lat: 49.0161149, lng: 19.6001974 },
    { lat: 49.0162193, lng: 19.6001632 },
    { lat: 49.0167717, lng: 19.5994212 },
    { lat: 49.0174505, lng: 19.5994058 },
    { lat: 49.0177258, lng: 19.5992372 },
    { lat: 49.0181531, lng: 19.5988682 },
    { lat: 49.0182915, lng: 19.5986872 },
    { lat: 49.0184211, lng: 19.5987656 },
    { lat: 49.0186945, lng: 19.5983283 },
    { lat: 49.0188855, lng: 19.5983917 },
    { lat: 49.0191072, lng: 19.5981618 },
    { lat: 49.019561, lng: 19.5977578 },
    { lat: 49.0197508, lng: 19.5978418 },
    { lat: 49.0199728, lng: 19.597642 },
    { lat: 49.020005, lng: 19.5973838 },
    { lat: 49.0200965, lng: 19.5970404 },
    { lat: 49.0209148, lng: 19.5961949 },
    { lat: 49.02118, lng: 19.596001 },
    { lat: 49.0217068, lng: 19.5957146 },
    { lat: 49.0218451, lng: 19.5956356 },
    { lat: 49.0219864, lng: 19.5954956 },
    { lat: 49.0221083, lng: 19.5951727 },
    { lat: 49.0221462, lng: 19.5949397 },
    { lat: 49.0232804, lng: 19.5937542 },
    { lat: 49.024035, lng: 19.5928218 },
    { lat: 49.0242669, lng: 19.5927438 },
    { lat: 49.02436, lng: 19.5927607 },
    { lat: 49.0246083, lng: 19.592617 },
    { lat: 49.0249549, lng: 19.5922621 },
    { lat: 49.0254182, lng: 19.5919388 },
    { lat: 49.0255165, lng: 19.5913688 },
    { lat: 49.0255969, lng: 19.5910054 },
    { lat: 49.0254781, lng: 19.5902189 },
    { lat: 49.0260494, lng: 19.5898922 },
    { lat: 49.026273, lng: 19.5897565 },
    { lat: 49.0266719, lng: 19.5895555 },
    { lat: 49.0267733, lng: 19.5895316 },
    { lat: 49.0273007, lng: 19.5891585 },
    { lat: 49.0284512, lng: 19.5891351 },
    { lat: 49.029027, lng: 19.5893849 },
    { lat: 49.0291793, lng: 19.5894441 },
    { lat: 49.0300435, lng: 19.589791 },
    { lat: 49.0305773, lng: 19.5900089 },
    { lat: 49.0307661, lng: 19.5900488 },
    { lat: 49.0315446, lng: 19.5901974 },
    { lat: 49.0326924, lng: 19.5896645 },
    { lat: 49.0335489, lng: 19.5891449 },
    { lat: 49.0338469, lng: 19.5890313 },
    { lat: 49.0344977, lng: 19.5888233 },
    { lat: 49.0355903, lng: 19.5891403 },
    { lat: 49.0356712, lng: 19.589185 },
    { lat: 49.0357393, lng: 19.5891452 },
    { lat: 49.0362192, lng: 19.5887666 },
    { lat: 49.0367011, lng: 19.5880591 },
    { lat: 49.0372345, lng: 19.5866726 },
    { lat: 49.0373327, lng: 19.5862023 },
    { lat: 49.0373722, lng: 19.5859263 },
    { lat: 49.0374501, lng: 19.5855591 },
    { lat: 49.0375741, lng: 19.5848071 },
    { lat: 49.0375916, lng: 19.5840748 },
    { lat: 49.0377511, lng: 19.5837112 },
    { lat: 49.0379134, lng: 19.5835423 },
    { lat: 49.0375833, lng: 19.5827929 },
    { lat: 49.037628, lng: 19.582586 },
    { lat: 49.0374628, lng: 19.5820172 },
    { lat: 49.0371259, lng: 19.5810447 },
    { lat: 49.0370544, lng: 19.5807853 },
    { lat: 49.036972, lng: 19.5802298 },
    { lat: 49.0365595, lng: 19.5796343 },
    { lat: 49.0356869, lng: 19.5780255 },
    { lat: 49.0359783, lng: 19.5771691 },
    { lat: 49.0362094, lng: 19.5764946 },
    { lat: 49.0362656, lng: 19.5763174 },
    { lat: 49.0358165, lng: 19.5758824 },
    { lat: 49.0358256, lng: 19.5757813 },
    { lat: 49.0355769, lng: 19.5755408 },
    { lat: 49.0350657, lng: 19.5749238 },
    { lat: 49.0348082, lng: 19.5744875 },
    { lat: 49.0346784, lng: 19.5742849 },
    { lat: 49.0346064, lng: 19.5742327 },
    { lat: 49.0344839, lng: 19.574144 },
    { lat: 49.0341402, lng: 19.5740683 },
    { lat: 49.0339742, lng: 19.5739115 },
    { lat: 49.0336849, lng: 19.5733464 },
    { lat: 49.0335984, lng: 19.5731235 },
    { lat: 49.0335847, lng: 19.5729989 },
    { lat: 49.0335569, lng: 19.5729623 },
    { lat: 49.0332518, lng: 19.5725608 },
    { lat: 49.0328698, lng: 19.5722108 },
    { lat: 49.0324817, lng: 19.5718266 },
    { lat: 49.0320615, lng: 19.5717556 },
    { lat: 49.031992, lng: 19.5717381 },
    { lat: 49.0319616, lng: 19.5717325 },
    { lat: 49.0316387, lng: 19.5716731 },
    { lat: 49.031529, lng: 19.5717385 },
    { lat: 49.0313684, lng: 19.5717262 },
    { lat: 49.0313044, lng: 19.5716841 },
    { lat: 49.0311154, lng: 19.571739 },
    { lat: 49.0308994, lng: 19.5720701 },
    { lat: 49.0307151, lng: 19.5722111 },
    { lat: 49.0300998, lng: 19.5720561 },
    { lat: 49.0296732, lng: 19.5719625 },
    { lat: 49.0293822, lng: 19.5718572 },
    { lat: 49.0291367, lng: 19.5717583 },
    { lat: 49.0289897, lng: 19.5717238 },
    { lat: 49.0288485, lng: 19.5717195 },
    { lat: 49.028482, lng: 19.5716853 },
    { lat: 49.0281456, lng: 19.5715453 },
    { lat: 49.0280111, lng: 19.5715378 },
    { lat: 49.027841, lng: 19.571738 },
    { lat: 49.0276697, lng: 19.5719078 },
    { lat: 49.0270962, lng: 19.5719876 },
    { lat: 49.0268423, lng: 19.5721273 },
    { lat: 49.0263682, lng: 19.5723094 },
    { lat: 49.0261943, lng: 19.5722234 },
    { lat: 49.0259803, lng: 19.5719288 },
    { lat: 49.0260123, lng: 19.5713147 },
    { lat: 49.0260671, lng: 19.570336 },
    { lat: 49.0260746, lng: 19.5703018 },
    { lat: 49.0261473, lng: 19.5701136 },
    { lat: 49.0264091, lng: 19.5695512 },
    { lat: 49.0269484, lng: 19.568945 },
    { lat: 49.0275284, lng: 19.5655544 },
    { lat: 49.0265796, lng: 19.5660725 },
    { lat: 49.026342, lng: 19.5661541 },
    { lat: 49.0260512, lng: 19.5661975 },
    { lat: 49.0245055, lng: 19.5664054 },
    { lat: 49.0240927, lng: 19.5664971 },
    { lat: 49.0232502, lng: 19.5667777 },
    { lat: 49.0228033, lng: 19.5672058 },
    { lat: 49.0223658, lng: 19.5674714 },
    { lat: 49.0217782, lng: 19.5673379 },
    { lat: 49.0210622, lng: 19.5672984 },
    { lat: 49.0203167, lng: 19.5679584 },
    { lat: 49.0200183, lng: 19.5682632 },
    { lat: 49.0195094, lng: 19.5684167 },
    { lat: 49.019147, lng: 19.5683544 },
    { lat: 49.0185056, lng: 19.567998 },
    { lat: 49.0179522, lng: 19.567847 },
    { lat: 49.0176005, lng: 19.5677398 },
    { lat: 49.017057, lng: 19.5679813 },
    { lat: 49.0161129, lng: 19.5678583 },
    { lat: 49.0150182, lng: 19.5677212 },
    { lat: 49.0142269, lng: 19.5685837 },
    { lat: 49.0131954, lng: 19.5686228 },
    { lat: 49.0128113, lng: 19.5685298 },
    { lat: 49.0115231, lng: 19.5683732 },
    { lat: 49.0104555, lng: 19.5686317 },
    { lat: 49.0099585, lng: 19.5686059 },
    { lat: 49.0094199, lng: 19.5688037 },
    { lat: 49.0090081, lng: 19.5689447 },
    { lat: 49.0083496, lng: 19.5683339 },
    { lat: 49.0073236, lng: 19.5672278 },
    { lat: 49.0065686, lng: 19.5650877 },
    { lat: 49.0061635, lng: 19.5651262 },
    { lat: 49.0060301, lng: 19.5649643 },
    { lat: 49.005683, lng: 19.5650111 },
    { lat: 49.0052328, lng: 19.5648759 },
    { lat: 49.0051727, lng: 19.5648481 },
    { lat: 49.0047882, lng: 19.564663 },
    { lat: 49.0045201, lng: 19.564489 },
    { lat: 49.0044838, lng: 19.5642879 },
    { lat: 49.0028156, lng: 19.5627554 },
    { lat: 49.0025854, lng: 19.5621511 },
    { lat: 49.0021301, lng: 19.5601877 },
    { lat: 49.0018038, lng: 19.5598293 },
    { lat: 49.0017139, lng: 19.5590603 },
    { lat: 49.0014286, lng: 19.5582358 },
    { lat: 49.0009474, lng: 19.5574135 },
    { lat: 49.0006259, lng: 19.5566412 },
    { lat: 49.0002852, lng: 19.5556631 },
    { lat: 49.0002677, lng: 19.555313 },
    { lat: 49.0002609, lng: 19.5551762 },
    { lat: 48.9988684, lng: 19.5551576 },
    { lat: 48.9955483, lng: 19.5571048 },
    { lat: 48.9910042, lng: 19.5567614 },
    { lat: 48.9872294, lng: 19.5554663 },
    { lat: 48.9851364, lng: 19.553896 },
    { lat: 48.9840394, lng: 19.5518698 },
    { lat: 48.9834343, lng: 19.5500832 },
    { lat: 48.9821603, lng: 19.5499468 },
    { lat: 48.9798438, lng: 19.5498517 },
    { lat: 48.979244, lng: 19.5498684 },
    { lat: 48.9789698, lng: 19.5498606 },
    { lat: 48.9775234, lng: 19.549742 },
    { lat: 48.9769979, lng: 19.5493872 },
    { lat: 48.9754661, lng: 19.5462355 },
    { lat: 48.9741615, lng: 19.5445784 },
    { lat: 48.972451, lng: 19.5443302 },
    { lat: 48.9699371, lng: 19.5449272 },
    { lat: 48.9690026, lng: 19.5450379 },
    { lat: 48.9670503, lng: 19.5439369 },
    { lat: 48.965471, lng: 19.5436022 },
    { lat: 48.9649065, lng: 19.5431314 },
    { lat: 48.9644465, lng: 19.542752 },
    { lat: 48.9641482, lng: 19.5422174 },
    { lat: 48.9635039, lng: 19.5415299 },
    { lat: 48.9629642, lng: 19.5419244 },
    { lat: 48.9621055, lng: 19.5418755 },
    { lat: 48.9612264, lng: 19.5422885 },
    { lat: 48.9596888, lng: 19.5432626 },
    { lat: 48.9592793, lng: 19.5430331 },
    { lat: 48.9588335, lng: 19.542796 },
    { lat: 48.9584648, lng: 19.542592 },
    { lat: 48.9580212, lng: 19.542355 },
    { lat: 48.9576362, lng: 19.542139 },
    { lat: 48.9571689, lng: 19.5420193 },
    { lat: 48.9560828, lng: 19.5417243 },
    { lat: 48.9556191, lng: 19.5416375 },
    { lat: 48.9553436, lng: 19.5415772 },
    { lat: 48.9546549, lng: 19.541448 },
    { lat: 48.9545109, lng: 19.5413461 },
    { lat: 48.9539202, lng: 19.5409279 },
    { lat: 48.9533593, lng: 19.5407653 },
    { lat: 48.9529103, lng: 19.5406286 },
    { lat: 48.951786, lng: 19.5406083 },
    { lat: 48.9513552, lng: 19.5405292 },
    { lat: 48.9503058, lng: 19.5403469 },
    { lat: 48.9500401, lng: 19.5403042 },
    { lat: 48.9498044, lng: 19.5402448 },
    { lat: 48.9487443, lng: 19.5399572 },
    { lat: 48.947248, lng: 19.539928 },
    { lat: 48.946563, lng: 19.540925 },
    { lat: 48.945921, lng: 19.54151 },
    { lat: 48.945532, lng: 19.541861 },
    { lat: 48.944777, lng: 19.542549 },
    { lat: 48.944098, lng: 19.542964 },
    { lat: 48.943356, lng: 19.543425 },
    { lat: 48.943026, lng: 19.543902 },
    { lat: 48.942566, lng: 19.544571 },
    { lat: 48.942236, lng: 19.545583 },
    { lat: 48.941961, lng: 19.546431 },
    { lat: 48.941667, lng: 19.548402 },
    { lat: 48.941647, lng: 19.549975 },
    { lat: 48.94177, lng: 19.551158 },
    { lat: 48.941727, lng: 19.552428 },
    { lat: 48.941913, lng: 19.554279 },
    { lat: 48.941921, lng: 19.55507 },
    { lat: 48.941851, lng: 19.555331 },
    { lat: 48.9418, lng: 19.555519 },
    { lat: 48.941773, lng: 19.556098 },
    { lat: 48.94188, lng: 19.557338 },
    { lat: 48.941815, lng: 19.558868 },
    { lat: 48.941825, lng: 19.558993 },
    { lat: 48.942001, lng: 19.561288 },
    { lat: 48.941544, lng: 19.563685 },
    { lat: 48.941264, lng: 19.564212 },
    { lat: 48.940832, lng: 19.566709 },
    { lat: 48.940911, lng: 19.568362 },
    { lat: 48.940779, lng: 19.568895 },
    { lat: 48.940936, lng: 19.571193 },
    { lat: 48.940934, lng: 19.571301 },
    { lat: 48.94067, lng: 19.572086 },
    { lat: 48.940538, lng: 19.572775 },
  ],
  Dúbrava: [
    { lat: 48.943267, lng: 19.491013 },
    { lat: 48.942345, lng: 19.492641 },
    { lat: 48.942147, lng: 19.493588 },
    { lat: 48.941356, lng: 19.494885 },
    { lat: 48.941231, lng: 19.495536 },
    { lat: 48.940991, lng: 19.496798 },
    { lat: 48.940564, lng: 19.50063 },
    { lat: 48.94049, lng: 19.501916 },
    { lat: 48.94029, lng: 19.503387 },
    { lat: 48.940562, lng: 19.505009 },
    { lat: 48.940893, lng: 19.507301 },
    { lat: 48.9413996, lng: 19.5073357 },
    { lat: 48.9432932, lng: 19.5076936 },
    { lat: 48.9446709, lng: 19.5084681 },
    { lat: 48.945335, lng: 19.5086507 },
    { lat: 48.9465395, lng: 19.5089894 },
    { lat: 48.9500872, lng: 19.5092958 },
    { lat: 48.9515508, lng: 19.5090188 },
    { lat: 48.9543783, lng: 19.5094954 },
    { lat: 48.9591076, lng: 19.5111881 },
    { lat: 48.9597051, lng: 19.5115394 },
    { lat: 48.960811, lng: 19.5117227 },
    { lat: 48.9627383, lng: 19.5122229 },
    { lat: 48.9643361, lng: 19.5116287 },
    { lat: 48.9644069, lng: 19.5116026 },
    { lat: 48.9655435, lng: 19.5111787 },
    { lat: 48.9655679, lng: 19.51117 },
    { lat: 48.9659292, lng: 19.5110353 },
    { lat: 48.9670472, lng: 19.5112872 },
    { lat: 48.9672135, lng: 19.5113242 },
    { lat: 48.9677768, lng: 19.5114461 },
    { lat: 48.9678261, lng: 19.5114646 },
    { lat: 48.9678455, lng: 19.5114715 },
    { lat: 48.9679005, lng: 19.5112903 },
    { lat: 48.9682192, lng: 19.5109446 },
    { lat: 48.968716, lng: 19.5109387 },
    { lat: 48.9689402, lng: 19.5110139 },
    { lat: 48.9691196, lng: 19.5111668 },
    { lat: 48.9694167, lng: 19.5110597 },
    { lat: 48.9697914, lng: 19.5106772 },
    { lat: 48.9702053, lng: 19.5105176 },
    { lat: 48.9705014, lng: 19.5105835 },
    { lat: 48.9709017, lng: 19.5105135 },
    { lat: 48.9713098, lng: 19.5102458 },
    { lat: 48.9717817, lng: 19.5102691 },
    { lat: 48.9721326, lng: 19.5100075 },
    { lat: 48.9722729, lng: 19.5099722 },
    { lat: 48.9723593, lng: 19.5098429 },
    { lat: 48.9726737, lng: 19.5098183 },
    { lat: 48.9729067, lng: 19.509602 },
    { lat: 48.9730504, lng: 19.5095524 },
    { lat: 48.9733881, lng: 19.509436 },
    { lat: 48.9738688, lng: 19.5091418 },
    { lat: 48.9739803, lng: 19.5091481 },
    { lat: 48.9741424, lng: 19.5091338 },
    { lat: 48.9743488, lng: 19.509239 },
    { lat: 48.9744512, lng: 19.5092364 },
    { lat: 48.9749737, lng: 19.5094258 },
    { lat: 48.9751855, lng: 19.5094973 },
    { lat: 48.9754908, lng: 19.5093736 },
    { lat: 48.9760598, lng: 19.5094956 },
    { lat: 48.9762392, lng: 19.5096244 },
    { lat: 48.9765725, lng: 19.5095953 },
    { lat: 48.9767844, lng: 19.5097935 },
    { lat: 48.9771905, lng: 19.5099474 },
    { lat: 48.9774645, lng: 19.5099751 },
    { lat: 48.9779042, lng: 19.5103781 },
    { lat: 48.9785752, lng: 19.5102167 },
    { lat: 48.9789682, lng: 19.5102595 },
    { lat: 48.9792578, lng: 19.5104584 },
    { lat: 48.9794977, lng: 19.5103287 },
    { lat: 48.9800623, lng: 19.5103292 },
    { lat: 48.9807202, lng: 19.5103034 },
    { lat: 48.9809113, lng: 19.5097282 },
    { lat: 48.9812251, lng: 19.5095888 },
    { lat: 48.9815174, lng: 19.5090847 },
    { lat: 48.9817077, lng: 19.5085419 },
    { lat: 48.9819037, lng: 19.5083174 },
    { lat: 48.9821536, lng: 19.5079936 },
    { lat: 48.9823446, lng: 19.5075658 },
    { lat: 48.9827561, lng: 19.5070024 },
    { lat: 48.9831662, lng: 19.5066301 },
    { lat: 48.9834925, lng: 19.5065995 },
    { lat: 48.9836309, lng: 19.5062756 },
    { lat: 48.9838259, lng: 19.5060077 },
    { lat: 48.9840476, lng: 19.5061443 },
    { lat: 48.9842573, lng: 19.5060471 },
    { lat: 48.9845629, lng: 19.5060284 },
    { lat: 48.985023, lng: 19.5058656 },
    { lat: 48.9851691, lng: 19.5059585 },
    { lat: 48.9854282, lng: 19.506123 },
    { lat: 48.9854618, lng: 19.5061449 },
    { lat: 48.985906, lng: 19.5059041 },
    { lat: 48.986048, lng: 19.5058816 },
    { lat: 48.9861006, lng: 19.5058753 },
    { lat: 48.9865444, lng: 19.5058204 },
    { lat: 48.9866341, lng: 19.5058091 },
    { lat: 48.9868649, lng: 19.5057811 },
    { lat: 48.9870774, lng: 19.5057241 },
    { lat: 48.9871445, lng: 19.505688 },
    { lat: 48.9872753, lng: 19.5056167 },
    { lat: 48.987303, lng: 19.505602 },
    { lat: 48.9873908, lng: 19.5055546 },
    { lat: 48.9875051, lng: 19.5055404 },
    { lat: 48.9876216, lng: 19.5055262 },
    { lat: 48.9876934, lng: 19.5055172 },
    { lat: 48.9879666, lng: 19.5053821 },
    { lat: 48.9880678, lng: 19.5053043 },
    { lat: 48.988205, lng: 19.5052001 },
    { lat: 48.9882072, lng: 19.5051984 },
    { lat: 48.9884701, lng: 19.5049377 },
    { lat: 48.9884864, lng: 19.5049223 },
    { lat: 48.9885101, lng: 19.5048983 },
    { lat: 48.9889483, lng: 19.5044795 },
    { lat: 48.9894476, lng: 19.5046781 },
    { lat: 48.9895153, lng: 19.5046562 },
    { lat: 48.9898486, lng: 19.5045495 },
    { lat: 48.9901665, lng: 19.5043558 },
    { lat: 48.9903906, lng: 19.5044038 },
    { lat: 48.990697, lng: 19.5042512 },
    { lat: 48.9911124, lng: 19.5036048 },
    { lat: 48.9915842, lng: 19.503548 },
    { lat: 48.9918529, lng: 19.5036343 },
    { lat: 48.9921942, lng: 19.5033858 },
    { lat: 48.9925438, lng: 19.5034643 },
    { lat: 48.9928184, lng: 19.5038256 },
    { lat: 48.9933678, lng: 19.5037408 },
    { lat: 48.9937854, lng: 19.5039553 },
    { lat: 48.9940913, lng: 19.5042613 },
    { lat: 48.9949441, lng: 19.5044508 },
    { lat: 48.9950191, lng: 19.5046841 },
    { lat: 48.9958742, lng: 19.5052943 },
    { lat: 48.9968634, lng: 19.5057343 },
    { lat: 48.9969962, lng: 19.505853 },
    { lat: 48.9978151, lng: 19.5060448 },
    { lat: 48.9979426, lng: 19.5061737 },
    { lat: 48.998047, lng: 19.506627 },
    { lat: 48.9986884, lng: 19.5068556 },
    { lat: 48.9991346, lng: 19.5071729 },
    { lat: 48.9998655, lng: 19.5072624 },
    { lat: 49.0004052, lng: 19.507482 },
    { lat: 49.0007386, lng: 19.507849 },
    { lat: 49.001331, lng: 19.508214 },
    { lat: 49.0015485, lng: 19.5087032 },
    { lat: 49.001567, lng: 19.508984 },
    { lat: 49.0017912, lng: 19.5092811 },
    { lat: 49.0023832, lng: 19.5092648 },
    { lat: 49.0025877, lng: 19.5095485 },
    { lat: 49.0028806, lng: 19.509768 },
    { lat: 49.0030004, lng: 19.5097758 },
    { lat: 49.0037071, lng: 19.5102961 },
    { lat: 49.0037745, lng: 19.5104361 },
    { lat: 49.0039771, lng: 19.5106275 },
    { lat: 49.0041687, lng: 19.5110013 },
    { lat: 49.0042205, lng: 19.5112704 },
    { lat: 49.0045792, lng: 19.5117467 },
    { lat: 49.0046561, lng: 19.5122181 },
    { lat: 49.0048093, lng: 19.512512 },
    { lat: 49.0052825, lng: 19.5128054 },
    { lat: 49.0053175, lng: 19.5129826 },
    { lat: 49.0053133, lng: 19.513132 },
    { lat: 49.0054174, lng: 19.5134415 },
    { lat: 49.0055221, lng: 19.5138557 },
    { lat: 49.0058276, lng: 19.5141281 },
    { lat: 49.0060315, lng: 19.5144974 },
    { lat: 49.0061374, lng: 19.5148674 },
    { lat: 49.006163, lng: 19.5159931 },
    { lat: 49.0065393, lng: 19.5165804 },
    { lat: 49.0070609, lng: 19.5171218 },
    { lat: 49.0071958, lng: 19.5173867 },
    { lat: 49.0072502, lng: 19.5177413 },
    { lat: 49.0073926, lng: 19.5180669 },
    { lat: 49.0075564, lng: 19.518224 },
    { lat: 49.0089946, lng: 19.5184784 },
    { lat: 49.0091692, lng: 19.518923 },
    { lat: 49.009641, lng: 19.5191362 },
    { lat: 49.0102273, lng: 19.5195861 },
    { lat: 49.0107783, lng: 19.5203524 },
    { lat: 49.011443, lng: 19.5202768 },
    { lat: 49.0116024, lng: 19.5204832 },
    { lat: 49.0118479, lng: 19.5208737 },
    { lat: 49.0122434, lng: 19.5215154 },
    { lat: 49.0124162, lng: 19.5217972 },
    { lat: 49.012645, lng: 19.5218434 },
    { lat: 49.0131218, lng: 19.5223423 },
    { lat: 49.0135224, lng: 19.5225319 },
    { lat: 49.0136126, lng: 19.5226559 },
    { lat: 49.0136315, lng: 19.5227946 },
    { lat: 49.0140416, lng: 19.52314 },
    { lat: 49.0143201, lng: 19.5231419 },
    { lat: 49.014771, lng: 19.5233481 },
    { lat: 49.0148692, lng: 19.5235236 },
    { lat: 49.015099, lng: 19.523839 },
    { lat: 49.0152416, lng: 19.523979 },
    { lat: 49.0163105, lng: 19.5238428 },
    { lat: 49.0167487, lng: 19.5241376 },
    { lat: 49.0175528, lng: 19.5237318 },
    { lat: 49.0177168, lng: 19.5237241 },
    { lat: 49.0180237, lng: 19.5240012 },
    { lat: 49.0184388, lng: 19.5239599 },
    { lat: 49.0191761, lng: 19.5246363 },
    { lat: 49.0195521, lng: 19.5248497 },
    { lat: 49.0197422, lng: 19.5251133 },
    { lat: 49.0201577, lng: 19.5242236 },
    { lat: 49.0203643, lng: 19.5237372 },
    { lat: 49.02037, lng: 19.5236906 },
    { lat: 49.0206667, lng: 19.5231513 },
    { lat: 49.0210937, lng: 19.5222377 },
    { lat: 49.0223289, lng: 19.5221191 },
    { lat: 49.0224382, lng: 19.5218975 },
    { lat: 49.0225941, lng: 19.5219346 },
    { lat: 49.022672, lng: 19.5220118 },
    { lat: 49.0227727, lng: 19.5219473 },
    { lat: 49.0228255, lng: 19.5219913 },
    { lat: 49.0229481, lng: 19.5219873 },
    { lat: 49.0230831, lng: 19.5219282 },
    { lat: 49.0232507, lng: 19.5219485 },
    { lat: 49.0233545, lng: 19.521925 },
    { lat: 49.0234441, lng: 19.5220634 },
    { lat: 49.023611, lng: 19.5221909 },
    { lat: 49.023643, lng: 19.5222726 },
    { lat: 49.0240526, lng: 19.5225586 },
    { lat: 49.0241355, lng: 19.5227465 },
    { lat: 49.0242653, lng: 19.5228739 },
    { lat: 49.0245318, lng: 19.5229865 },
    { lat: 49.0245741, lng: 19.5229501 },
    { lat: 49.0247593, lng: 19.5231273 },
    { lat: 49.024809, lng: 19.5232086 },
    { lat: 49.0249031, lng: 19.5231434 },
    { lat: 49.0249994, lng: 19.5231792 },
    { lat: 49.0250651, lng: 19.5232886 },
    { lat: 49.0251158, lng: 19.5233123 },
    { lat: 49.0252925, lng: 19.5234276 },
    { lat: 49.0255423, lng: 19.5234848 },
    { lat: 49.0256786, lng: 19.5235692 },
    { lat: 49.0258601, lng: 19.5236976 },
    { lat: 49.0258933, lng: 19.5238301 },
    { lat: 49.0260838, lng: 19.5238337 },
    { lat: 49.0261873, lng: 19.5238045 },
    { lat: 49.0263209, lng: 19.5238909 },
    { lat: 49.0264287, lng: 19.5238831 },
    { lat: 49.0265289, lng: 19.5239792 },
    { lat: 49.0265807, lng: 19.523903 },
    { lat: 49.0266797, lng: 19.5241216 },
    { lat: 49.0268496, lng: 19.5241442 },
    { lat: 49.0268914, lng: 19.5242425 },
    { lat: 49.0270796, lng: 19.5242697 },
    { lat: 49.0270947, lng: 19.5242063 },
    { lat: 49.0270837, lng: 19.5241881 },
    { lat: 49.027109, lng: 19.5241477 },
    { lat: 49.0272177, lng: 19.5241348 },
    { lat: 49.0275092, lng: 19.5243486 },
    { lat: 49.0275707, lng: 19.5245374 },
    { lat: 49.0278431, lng: 19.5247528 },
    { lat: 49.028024, lng: 19.5249865 },
    { lat: 49.0283141, lng: 19.525046 },
    { lat: 49.0285135, lng: 19.5249502 },
    { lat: 49.0285422, lng: 19.52486 },
    { lat: 49.0288671, lng: 19.5250149 },
    { lat: 49.0289212, lng: 19.5250684 },
    { lat: 49.0291271, lng: 19.5252079 },
    { lat: 49.0293361, lng: 19.5251977 },
    { lat: 49.0294816, lng: 19.5252243 },
    { lat: 49.0295883, lng: 19.5251664 },
    { lat: 49.0298061, lng: 19.5252224 },
    { lat: 49.0301907, lng: 19.5251319 },
    { lat: 49.0303392, lng: 19.5251776 },
    { lat: 49.0304669, lng: 19.5251368 },
    { lat: 49.0305459, lng: 19.5250147 },
    { lat: 49.0306859, lng: 19.5250972 },
    { lat: 49.0309339, lng: 19.5247259 },
    { lat: 49.0311099, lng: 19.5245845 },
    { lat: 49.0313378, lng: 19.5247134 },
    { lat: 49.0320061, lng: 19.5248389 },
    { lat: 49.0322949, lng: 19.5248454 },
    { lat: 49.0324893, lng: 19.52491 },
    { lat: 49.0326654, lng: 19.5248674 },
    { lat: 49.0327869, lng: 19.5247612 },
    { lat: 49.0328253, lng: 19.5247894 },
    { lat: 49.0328296, lng: 19.5248579 },
    { lat: 49.0330043, lng: 19.5248844 },
    { lat: 49.0330968, lng: 19.5247902 },
    { lat: 49.0331015, lng: 19.524794 },
    { lat: 49.033145, lng: 19.5248049 },
    { lat: 49.0331515, lng: 19.5248297 },
    { lat: 49.0331557, lng: 19.5248957 },
    { lat: 49.0334678, lng: 19.5250182 },
    { lat: 49.0336225, lng: 19.524952 },
    { lat: 49.0337121, lng: 19.5250111 },
    { lat: 49.0340868, lng: 19.525052 },
    { lat: 49.0342368, lng: 19.5251051 },
    { lat: 49.0343501, lng: 19.5250935 },
    { lat: 49.0345116, lng: 19.5251001 },
    { lat: 49.0346874, lng: 19.5251476 },
    { lat: 49.0348065, lng: 19.5252909 },
    { lat: 49.0350652, lng: 19.5253548 },
    { lat: 49.0355098, lng: 19.5257388 },
    { lat: 49.0355816, lng: 19.5256828 },
    { lat: 49.0356763, lng: 19.5256832 },
    { lat: 49.0358995, lng: 19.5256101 },
    { lat: 49.0361311, lng: 19.5257511 },
    { lat: 49.036205, lng: 19.5258644 },
    { lat: 49.0362494, lng: 19.5257976 },
    { lat: 49.0364646, lng: 19.525819 },
    { lat: 49.0366348, lng: 19.5256477 },
    { lat: 49.036872, lng: 19.5256611 },
    { lat: 49.0371331, lng: 19.5256077 },
    { lat: 49.0372212, lng: 19.5256615 },
    { lat: 49.037413, lng: 19.5256887 },
    { lat: 49.0374352, lng: 19.525584 },
    { lat: 49.0377029, lng: 19.5256452 },
    { lat: 49.0378069, lng: 19.5255333 },
    { lat: 49.0378978, lng: 19.5255743 },
    { lat: 49.0379423, lng: 19.525463 },
    { lat: 49.037988, lng: 19.525498 },
    { lat: 49.03835, lng: 19.5256537 },
    { lat: 49.0385544, lng: 19.5256149 },
    { lat: 49.0387547, lng: 19.5254616 },
    { lat: 49.0387848, lng: 19.5253305 },
    { lat: 49.0388797, lng: 19.52536 },
    { lat: 49.0390259, lng: 19.5252778 },
    { lat: 49.039303, lng: 19.5254004 },
    { lat: 49.0393751, lng: 19.525279 },
    { lat: 49.0395022, lng: 19.5253478 },
    { lat: 49.0396148, lng: 19.5253467 },
    { lat: 49.039889, lng: 19.525628 },
    { lat: 49.0400386, lng: 19.5255928 },
    { lat: 49.040131, lng: 19.5253719 },
    { lat: 49.0406432, lng: 19.525089 },
    { lat: 49.040705, lng: 19.5249636 },
    { lat: 49.0408278, lng: 19.5249388 },
    { lat: 49.041004, lng: 19.5247478 },
    { lat: 49.0410023, lng: 19.5246848 },
    { lat: 49.0410375, lng: 19.5247155 },
    { lat: 49.0409855, lng: 19.5240476 },
    { lat: 49.0408578, lng: 19.5233963 },
    { lat: 49.0408201, lng: 19.5231865 },
    { lat: 49.040544, lng: 19.5227208 },
    { lat: 49.0404701, lng: 19.5225084 },
    { lat: 49.0404709, lng: 19.5222771 },
    { lat: 49.0405745, lng: 19.5218832 },
    { lat: 49.0406525, lng: 19.5216678 },
    { lat: 49.0406994, lng: 19.5215572 },
    { lat: 49.0408298, lng: 19.5211293 },
    { lat: 49.0408979, lng: 19.5208721 },
    { lat: 49.0409624, lng: 19.5205324 },
    { lat: 49.0411269, lng: 19.519616 },
    { lat: 49.0412627, lng: 19.5190115 },
    { lat: 49.0414438, lng: 19.5180446 },
    { lat: 49.041676, lng: 19.5175039 },
    { lat: 49.0420473, lng: 19.5167527 },
    { lat: 49.0424011, lng: 19.5160634 },
    { lat: 49.0431084, lng: 19.5146754 },
    { lat: 49.0433186, lng: 19.5142229 },
    { lat: 49.0435598, lng: 19.5136313 },
    { lat: 49.0436996, lng: 19.5132393 },
    { lat: 49.043794, lng: 19.513109 },
    { lat: 49.0438786, lng: 19.5129621 },
    { lat: 49.0439176, lng: 19.5129314 },
    { lat: 49.0440497, lng: 19.5128869 },
    { lat: 49.0441657, lng: 19.5127385 },
    { lat: 49.0442394, lng: 19.5125752 },
    { lat: 49.0443285, lng: 19.5124526 },
    { lat: 49.0445284, lng: 19.5125381 },
    { lat: 49.0447585, lng: 19.5127357 },
    { lat: 49.0449468, lng: 19.5129615 },
    { lat: 49.0452015, lng: 19.5129906 },
    { lat: 49.0452883, lng: 19.5129408 },
    { lat: 49.0453098, lng: 19.5129155 },
    { lat: 49.0453877, lng: 19.5128696 },
    { lat: 49.0454308, lng: 19.5128783 },
    { lat: 49.0454975, lng: 19.512762 },
    { lat: 49.0457479, lng: 19.512594 },
    { lat: 49.045765, lng: 19.5125696 },
    { lat: 49.0457437, lng: 19.5125011 },
    { lat: 49.0457894, lng: 19.5124632 },
    { lat: 49.0458299, lng: 19.512387 },
    { lat: 49.0456934, lng: 19.512335 },
    { lat: 49.0456913, lng: 19.5122171 },
    { lat: 49.0458482, lng: 19.5118276 },
    { lat: 49.0459433, lng: 19.5117127 },
    { lat: 49.0459951, lng: 19.5116634 },
    { lat: 49.0460416, lng: 19.5116477 },
    { lat: 49.0461139, lng: 19.5117004 },
    { lat: 49.0461716, lng: 19.5116827 },
    { lat: 49.0464185, lng: 19.5116602 },
    { lat: 49.0464878, lng: 19.5116457 },
    { lat: 49.0467, lng: 19.5115313 },
    { lat: 49.0467285, lng: 19.5114675 },
    { lat: 49.046731, lng: 19.5113466 },
    { lat: 49.0467879, lng: 19.5112852 },
    { lat: 49.0469771, lng: 19.5111355 },
    { lat: 49.0471423, lng: 19.5110261 },
    { lat: 49.0472172, lng: 19.510966 },
    { lat: 49.0474076, lng: 19.5109621 },
    { lat: 49.0475009, lng: 19.511158 },
    { lat: 49.0476411, lng: 19.5110433 },
    { lat: 49.047687, lng: 19.5110151 },
    { lat: 49.047765, lng: 19.5111149 },
    { lat: 49.0477542, lng: 19.5112752 },
    { lat: 49.047883, lng: 19.5112332 },
    { lat: 49.0479285, lng: 19.5113189 },
    { lat: 49.0480219, lng: 19.5112124 },
    { lat: 49.0481327, lng: 19.5111475 },
    { lat: 49.0481886, lng: 19.5112648 },
    { lat: 49.0482841, lng: 19.5112044 },
    { lat: 49.0484462, lng: 19.5110784 },
    { lat: 49.0486247, lng: 19.5110156 },
    { lat: 49.048748, lng: 19.5109042 },
    { lat: 49.0489591, lng: 19.5106928 },
    { lat: 49.0491253, lng: 19.5105772 },
    { lat: 49.0492348, lng: 19.5104373 },
    { lat: 49.0493378, lng: 19.5102008 },
    { lat: 49.0493241, lng: 19.5100974 },
    { lat: 49.049396, lng: 19.5099723 },
    { lat: 49.0495036, lng: 19.5099591 },
    { lat: 49.0495134, lng: 19.5098755 },
    { lat: 49.0495078, lng: 19.5098498 },
    { lat: 49.0495926, lng: 19.5098085 },
    { lat: 49.0496068, lng: 19.5098523 },
    { lat: 49.0497568, lng: 19.5097042 },
    { lat: 49.0498543, lng: 19.5096931 },
    { lat: 49.0499874, lng: 19.5095476 },
    { lat: 49.049991, lng: 19.5093561 },
    { lat: 49.0500223, lng: 19.5093025 },
    { lat: 49.0500673, lng: 19.5092751 },
    { lat: 49.0501502, lng: 19.5091664 },
    { lat: 49.0502667, lng: 19.5090499 },
    { lat: 49.0503394, lng: 19.5090397 },
    { lat: 49.050494, lng: 19.50907 },
    { lat: 49.0505335, lng: 19.5090238 },
    { lat: 49.0505987, lng: 19.5089942 },
    { lat: 49.0506667, lng: 19.5089066 },
    { lat: 49.0506719, lng: 19.5088184 },
    { lat: 49.0507301, lng: 19.5086153 },
    { lat: 49.0508114, lng: 19.5085191 },
    { lat: 49.0508815, lng: 19.508549 },
    { lat: 49.0509151, lng: 19.5085203 },
    { lat: 49.050971, lng: 19.5084357 },
    { lat: 49.0511557, lng: 19.5084359 },
    { lat: 49.0512508, lng: 19.5084714 },
    { lat: 49.0514384, lng: 19.5084332 },
    { lat: 49.0514686, lng: 19.5083812 },
    { lat: 49.0514829, lng: 19.5083739 },
    { lat: 49.0515037, lng: 19.5084048 },
    { lat: 49.0515584, lng: 19.5083968 },
    { lat: 49.0516381, lng: 19.5082669 },
    { lat: 49.0517319, lng: 19.5081995 },
    { lat: 49.0517417, lng: 19.5079916 },
    { lat: 49.0518355, lng: 19.5079766 },
    { lat: 49.0518723, lng: 19.5079414 },
    { lat: 49.0519433, lng: 19.507945 },
    { lat: 49.0520187, lng: 19.5078433 },
    { lat: 49.0521399, lng: 19.50788 },
    { lat: 49.0523199, lng: 19.5077729 },
    { lat: 49.0523977, lng: 19.5078268 },
    { lat: 49.0524206, lng: 19.5077353 },
    { lat: 49.0525333, lng: 19.5076393 },
    { lat: 49.0525965, lng: 19.5076154 },
    { lat: 49.0528152, lng: 19.5075915 },
    { lat: 49.0528475, lng: 19.5074106 },
    { lat: 49.0528697, lng: 19.5073784 },
    { lat: 49.0528929, lng: 19.5073954 },
    { lat: 49.0529027, lng: 19.5074604 },
    { lat: 49.053041, lng: 19.5073297 },
    { lat: 49.0531201, lng: 19.5073081 },
    { lat: 49.0532236, lng: 19.5071864 },
    { lat: 49.0533698, lng: 19.5072036 },
    { lat: 49.053489, lng: 19.50715 },
    { lat: 49.0536297, lng: 19.5071448 },
    { lat: 49.0536998, lng: 19.5070048 },
    { lat: 49.0538949, lng: 19.506857 },
    { lat: 49.0541018, lng: 19.506945 },
    { lat: 49.0541813, lng: 19.5069143 },
    { lat: 49.0541979, lng: 19.506859 },
    { lat: 49.0543217, lng: 19.5069791 },
    { lat: 49.0543603, lng: 19.5069797 },
    { lat: 49.0543942, lng: 19.5068608 },
    { lat: 49.0546503, lng: 19.5070902 },
    { lat: 49.0547817, lng: 19.5070313 },
    { lat: 49.0548911, lng: 19.5068372 },
    { lat: 49.0549356, lng: 19.5068221 },
    { lat: 49.0550077, lng: 19.5069704 },
    { lat: 49.0550883, lng: 19.5069349 },
    { lat: 49.0551603, lng: 19.5068317 },
    { lat: 49.0552375, lng: 19.5068203 },
    { lat: 49.0553885, lng: 19.5068431 },
    { lat: 49.05543, lng: 19.5068883 },
    { lat: 49.0554694, lng: 19.5069868 },
    { lat: 49.0555521, lng: 19.5070233 },
    { lat: 49.0555823, lng: 19.5069655 },
    { lat: 49.0556308, lng: 19.5069676 },
    { lat: 49.0557357, lng: 19.5071709 },
    { lat: 49.0558018, lng: 19.5071338 },
    { lat: 49.0557929, lng: 19.5071167 },
    { lat: 49.0557487, lng: 19.5068358 },
    { lat: 49.0553236, lng: 19.5045452 },
    { lat: 49.0553084, lng: 19.504456 },
    { lat: 49.0552919, lng: 19.5043697 },
    { lat: 49.0552902, lng: 19.5043569 },
    { lat: 49.0551457, lng: 19.5035639 },
    { lat: 49.0552317, lng: 19.5029056 },
    { lat: 49.0552424, lng: 19.50282 },
    { lat: 49.0556019, lng: 19.5020087 },
    { lat: 49.0556671, lng: 19.5018588 },
    { lat: 49.0557334, lng: 19.5017092 },
    { lat: 49.0557733, lng: 19.5016198 },
    { lat: 49.0558789, lng: 19.5012673 },
    { lat: 49.0562507, lng: 19.5002085 },
    { lat: 49.0563428, lng: 19.4999559 },
    { lat: 49.05649, lng: 19.4992124 },
    { lat: 49.0568686, lng: 19.4979578 },
    { lat: 49.0569881, lng: 19.4978607 },
    { lat: 49.0579885, lng: 19.4975127 },
    { lat: 49.0585135, lng: 19.4974382 },
    { lat: 49.0593566, lng: 19.4973185 },
    { lat: 49.0596041, lng: 19.4973601 },
    { lat: 49.059973, lng: 19.4973921 },
    { lat: 49.0599949, lng: 19.4973553 },
    { lat: 49.0607672, lng: 19.4970904 },
    { lat: 49.0608444, lng: 19.4970797 },
    { lat: 49.060945, lng: 19.4970658 },
    { lat: 49.0609463, lng: 19.4964548 },
    { lat: 49.0609666, lng: 19.4951785 },
    { lat: 49.0609684, lng: 19.4950957 },
    { lat: 49.061033, lng: 19.4951025 },
    { lat: 49.0611305, lng: 19.4951133 },
    { lat: 49.061186, lng: 19.4951189 },
    { lat: 49.0612347, lng: 19.4951242 },
    { lat: 49.0612404, lng: 19.4951246 },
    { lat: 49.0613216, lng: 19.494909 },
    { lat: 49.061335, lng: 19.4948825 },
    { lat: 49.0612637, lng: 19.4948979 },
    { lat: 49.0611575, lng: 19.4949405 },
    { lat: 49.0610358, lng: 19.4947217 },
    { lat: 49.0608996, lng: 19.4945773 },
    { lat: 49.0607738, lng: 19.4944606 },
    { lat: 49.0607158, lng: 19.4942519 },
    { lat: 49.0606519, lng: 19.4939917 },
    { lat: 49.0604907, lng: 19.493942 },
    { lat: 49.0603597, lng: 19.4941335 },
    { lat: 49.0602208, lng: 19.4941019 },
    { lat: 49.0601962, lng: 19.4938842 },
    { lat: 49.0600071, lng: 19.4939359 },
    { lat: 49.0598295, lng: 19.4939465 },
    { lat: 49.0598179, lng: 19.4937196 },
    { lat: 49.0596486, lng: 19.4936162 },
    { lat: 49.0593808, lng: 19.4936719 },
    { lat: 49.059476, lng: 19.4931692 },
    { lat: 49.0593498, lng: 19.4931661 },
    { lat: 49.0592285, lng: 19.4932246 },
    { lat: 49.0591288, lng: 19.4931411 },
    { lat: 49.0589952, lng: 19.4931732 },
    { lat: 49.0588749, lng: 19.4929327 },
    { lat: 49.0587367, lng: 19.4928188 },
    { lat: 49.0584773, lng: 19.4927907 },
    { lat: 49.0581933, lng: 19.4927188 },
    { lat: 49.0579506, lng: 19.4929304 },
    { lat: 49.0577724, lng: 19.493365 },
    { lat: 49.0577569, lng: 19.493371 },
    { lat: 49.0575792, lng: 19.4933798 },
    { lat: 49.0575102, lng: 19.4932747 },
    { lat: 49.0570902, lng: 19.4930868 },
    { lat: 49.056536, lng: 19.492799 },
    { lat: 49.0563892, lng: 19.492718 },
    { lat: 49.0562333, lng: 19.4927051 },
    { lat: 49.0560663, lng: 19.4928489 },
    { lat: 49.0557251, lng: 19.4928787 },
    { lat: 49.0556103, lng: 19.493007 },
    { lat: 49.0554973, lng: 19.4929468 },
    { lat: 49.0552579, lng: 19.49284 },
    { lat: 49.055213, lng: 19.4929896 },
    { lat: 49.0550703, lng: 19.4929968 },
    { lat: 49.054964, lng: 19.4931605 },
    { lat: 49.0547968, lng: 19.4929598 },
    { lat: 49.0545811, lng: 19.4930693 },
    { lat: 49.05452, lng: 19.493138 },
    { lat: 49.0544229, lng: 19.4929888 },
    { lat: 49.0543001, lng: 19.4929612 },
    { lat: 49.0541434, lng: 19.492841 },
    { lat: 49.053974, lng: 19.4929251 },
    { lat: 49.0539963, lng: 19.4927524 },
    { lat: 49.0540027, lng: 19.4925477 },
    { lat: 49.0539481, lng: 19.4924127 },
    { lat: 49.0538072, lng: 19.4925117 },
    { lat: 49.053645, lng: 19.4924154 },
    { lat: 49.0535731, lng: 19.4922948 },
    { lat: 49.0534878, lng: 19.4924019 },
    { lat: 49.0533417, lng: 19.4923356 },
    { lat: 49.0532479, lng: 19.4921853 },
    { lat: 49.0531481, lng: 19.4921463 },
    { lat: 49.0531514, lng: 19.492192 },
    { lat: 49.0530756, lng: 19.4929951 },
    { lat: 49.0530714, lng: 19.4930012 },
    { lat: 49.052072, lng: 19.4930536 },
    { lat: 49.0518992, lng: 19.4930712 },
    { lat: 49.0514189, lng: 19.4929157 },
    { lat: 49.0513934, lng: 19.4929015 },
    { lat: 49.0508715, lng: 19.4928478 },
    { lat: 49.0502972, lng: 19.4926372 },
    { lat: 49.0500498, lng: 19.4925701 },
    { lat: 49.0498531, lng: 19.4925084 },
    { lat: 49.0498392, lng: 19.4921082 },
    { lat: 49.0498579, lng: 19.4920998 },
    { lat: 49.0497971, lng: 19.4919529 },
    { lat: 49.0496295, lng: 19.4917882 },
    { lat: 49.0495406, lng: 19.4916421 },
    { lat: 49.0494273, lng: 19.491653 },
    { lat: 49.0492849, lng: 19.4917411 },
    { lat: 49.0491993, lng: 19.4919136 },
    { lat: 49.0491287, lng: 19.4921435 },
    { lat: 49.0491916, lng: 19.4922852 },
    { lat: 49.0493116, lng: 19.4922757 },
    { lat: 49.0493345, lng: 19.4923778 },
    { lat: 49.0492452, lng: 19.4924958 },
    { lat: 49.0491397, lng: 19.4925335 },
    { lat: 49.0490671, lng: 19.4926181 },
    { lat: 49.0491366, lng: 19.4927846 },
    { lat: 49.0491244, lng: 19.4929343 },
    { lat: 49.0490268, lng: 19.492991 },
    { lat: 49.0488775, lng: 19.4929569 },
    { lat: 49.0487925, lng: 19.4927461 },
    { lat: 49.0486741, lng: 19.4926265 },
    { lat: 49.0485673, lng: 19.4926759 },
    { lat: 49.0485685, lng: 19.4928263 },
    { lat: 49.0486411, lng: 19.49306 },
    { lat: 49.0485436, lng: 19.4930445 },
    { lat: 49.0484509, lng: 19.493017 },
    { lat: 49.048344, lng: 19.4930168 },
    { lat: 49.0482199, lng: 19.4929898 },
    { lat: 49.0481466, lng: 19.4930378 },
    { lat: 49.048037, lng: 19.4929607 },
    { lat: 49.0479617, lng: 19.4929612 },
    { lat: 49.0479654, lng: 19.4931393 },
    { lat: 49.0478587, lng: 19.4931956 },
    { lat: 49.0477543, lng: 19.4931341 },
    { lat: 49.0476887, lng: 19.4929812 },
    { lat: 49.0475821, lng: 19.4928693 },
    { lat: 49.0476573, lng: 19.4926411 },
    { lat: 49.047551, lng: 19.4926097 },
    { lat: 49.0474282, lng: 19.4928562 },
    { lat: 49.0473082, lng: 19.4927925 },
    { lat: 49.0473009, lng: 19.4926348 },
    { lat: 49.0472778, lng: 19.4925227 },
    { lat: 49.0471606, lng: 19.4925977 },
    { lat: 49.0470269, lng: 19.492628 },
    { lat: 49.0469824, lng: 19.4924693 },
    { lat: 49.0471472, lng: 19.4921102 },
    { lat: 49.0470999, lng: 19.492011 },
    { lat: 49.0469691, lng: 19.4920821 },
    { lat: 49.0468356, lng: 19.4924159 },
    { lat: 49.046765, lng: 19.4924985 },
    { lat: 49.046677, lng: 19.4924932 },
    { lat: 49.046581, lng: 19.4927121 },
    { lat: 49.0464543, lng: 19.4927966 },
    { lat: 49.0464272, lng: 19.4928699 },
    { lat: 49.0464414, lng: 19.4931281 },
    { lat: 49.0463951, lng: 19.4934007 },
    { lat: 49.046101, lng: 19.4935731 },
    { lat: 49.0459101, lng: 19.4934105 },
    { lat: 49.0457519, lng: 19.4934007 },
    { lat: 49.045567, lng: 19.4935483 },
    { lat: 49.0454487, lng: 19.4933543 },
    { lat: 49.0453653, lng: 19.4930805 },
    { lat: 49.0453086, lng: 19.4928782 },
    { lat: 49.0451423, lng: 19.4931336 },
    { lat: 49.0449504, lng: 19.4931511 },
    { lat: 49.0448549, lng: 19.4930111 },
    { lat: 49.0447919, lng: 19.4927186 },
    { lat: 49.0446054, lng: 19.4926052 },
    { lat: 49.0445333, lng: 19.4929497 },
    { lat: 49.0444402, lng: 19.4929112 },
    { lat: 49.0444112, lng: 19.4928194 },
    { lat: 49.0444155, lng: 19.4927895 },
    { lat: 49.0443463, lng: 19.4927545 },
    { lat: 49.044357, lng: 19.4926668 },
    { lat: 49.0443401, lng: 19.4925461 },
    { lat: 49.0443806, lng: 19.4924721 },
    { lat: 49.0443524, lng: 19.4923005 },
    { lat: 49.0442777, lng: 19.4923397 },
    { lat: 49.0442887, lng: 19.4922609 },
    { lat: 49.0442284, lng: 19.4920791 },
    { lat: 49.0441794, lng: 19.4920394 },
    { lat: 49.0441394, lng: 19.491905 },
    { lat: 49.0441252, lng: 19.4919655 },
    { lat: 49.0440935, lng: 19.4919634 },
    { lat: 49.0440255, lng: 19.4919269 },
    { lat: 49.043997, lng: 19.4919698 },
    { lat: 49.0438334, lng: 19.4920116 },
    { lat: 49.0437711, lng: 19.4920078 },
    { lat: 49.0436684, lng: 19.4920542 },
    { lat: 49.0436482, lng: 19.4920316 },
    { lat: 49.0436524, lng: 19.4920254 },
    { lat: 49.0436027, lng: 19.4919707 },
    { lat: 49.0435932, lng: 19.4919847 },
    { lat: 49.04346, lng: 19.4918354 },
    { lat: 49.0433892, lng: 19.4916407 },
    { lat: 49.0432946, lng: 19.4915911 },
    { lat: 49.0431861, lng: 19.4916612 },
    { lat: 49.0430931, lng: 19.491647 },
    { lat: 49.0429685, lng: 19.4915604 },
    { lat: 49.0428929, lng: 19.4915813 },
    { lat: 49.0428509, lng: 19.4914278 },
    { lat: 49.0427106, lng: 19.4913428 },
    { lat: 49.0426073, lng: 19.4913007 },
    { lat: 49.0425331, lng: 19.4912331 },
    { lat: 49.0423456, lng: 19.4911959 },
    { lat: 49.0422121, lng: 19.491111 },
    { lat: 49.04212, lng: 19.4911671 },
    { lat: 49.0419911, lng: 19.4910601 },
    { lat: 49.0418875, lng: 19.4910356 },
    { lat: 49.0418063, lng: 19.4910819 },
    { lat: 49.0417026, lng: 19.4907636 },
    { lat: 49.0415675, lng: 19.4907424 },
    { lat: 49.0415316, lng: 19.490572 },
    { lat: 49.0413762, lng: 19.4905763 },
    { lat: 49.0412526, lng: 19.4906563 },
    { lat: 49.0411468, lng: 19.4905852 },
    { lat: 49.0409854, lng: 19.4905309 },
    { lat: 49.0408612, lng: 19.4905761 },
    { lat: 49.0407726, lng: 19.4904892 },
    { lat: 49.0407038, lng: 19.4903633 },
    { lat: 49.0406407, lng: 19.4903625 },
    { lat: 49.040508, lng: 19.4902699 },
    { lat: 49.0403552, lng: 19.4901346 },
    { lat: 49.0402167, lng: 19.4901116 },
    { lat: 49.0400459, lng: 19.4900479 },
    { lat: 49.0400337, lng: 19.4898294 },
    { lat: 49.0399197, lng: 19.4897021 },
    { lat: 49.0397966, lng: 19.4897206 },
    { lat: 49.0396659, lng: 19.4897959 },
    { lat: 49.039566, lng: 19.4896352 },
    { lat: 49.0393499, lng: 19.4894639 },
    { lat: 49.0392027, lng: 19.4894781 },
    { lat: 49.0390871, lng: 19.4895575 },
    { lat: 49.0389495, lng: 19.4894357 },
    { lat: 49.0388382, lng: 19.4893678 },
    { lat: 49.0387707, lng: 19.4893926 },
    { lat: 49.0386499, lng: 19.4893379 },
    { lat: 49.0385554, lng: 19.4892201 },
    { lat: 49.0384382, lng: 19.4893212 },
    { lat: 49.0383004, lng: 19.489235 },
    { lat: 49.0381886, lng: 19.4892591 },
    { lat: 49.0380721, lng: 19.4891493 },
    { lat: 49.0378919, lng: 19.4891272 },
    { lat: 49.037712, lng: 19.4891844 },
    { lat: 49.0376204, lng: 19.4891291 },
    { lat: 49.0375427, lng: 19.4890059 },
    { lat: 49.0374713, lng: 19.4887772 },
    { lat: 49.037352, lng: 19.4886312 },
    { lat: 49.0372298, lng: 19.4886452 },
    { lat: 49.0370851, lng: 19.4888574 },
    { lat: 49.0369095, lng: 19.4887141 },
    { lat: 49.0369674, lng: 19.4885043 },
    { lat: 49.0369688, lng: 19.488386 },
    { lat: 49.0368814, lng: 19.4881996 },
    { lat: 49.0367387, lng: 19.4881637 },
    { lat: 49.0366723, lng: 19.4882857 },
    { lat: 49.0365876, lng: 19.4881579 },
    { lat: 49.0362985, lng: 19.4881197 },
    { lat: 49.0362507, lng: 19.4882063 },
    { lat: 49.0361551, lng: 19.488062 },
    { lat: 49.0360838, lng: 19.4880822 },
    { lat: 49.0359664, lng: 19.4878092 },
    { lat: 49.035873, lng: 19.4878126 },
    { lat: 49.0358209, lng: 19.4878807 },
    { lat: 49.0357285, lng: 19.4877303 },
    { lat: 49.0355455, lng: 19.4877006 },
    { lat: 49.0355032, lng: 19.4877805 },
    { lat: 49.0355122, lng: 19.4878528 },
    { lat: 49.0355422, lng: 19.4878945 },
    { lat: 49.0355464, lng: 19.4880381 },
    { lat: 49.0354047, lng: 19.488042 },
    { lat: 49.0352387, lng: 19.487888 },
    { lat: 49.0351778, lng: 19.4879602 },
    { lat: 49.0350882, lng: 19.4879216 },
    { lat: 49.0349929, lng: 19.4880782 },
    { lat: 49.034925, lng: 19.4880503 },
    { lat: 49.0349133, lng: 19.4879179 },
    { lat: 49.0347506, lng: 19.4880307 },
    { lat: 49.0346633, lng: 19.4879449 },
    { lat: 49.0347286, lng: 19.4878201 },
    { lat: 49.0346568, lng: 19.4877311 },
    { lat: 49.0346146, lng: 19.4877956 },
    { lat: 49.0345852, lng: 19.4879108 },
    { lat: 49.0345869, lng: 19.4879731 },
    { lat: 49.0345118, lng: 19.4880342 },
    { lat: 49.0344532, lng: 19.4879568 },
    { lat: 49.034402, lng: 19.4881421 },
    { lat: 49.0343727, lng: 19.4881226 },
    { lat: 49.0343264, lng: 19.4879214 },
    { lat: 49.034211, lng: 19.4879376 },
    { lat: 49.0341178, lng: 19.4880675 },
    { lat: 49.0340692, lng: 19.4879139 },
    { lat: 49.0339476, lng: 19.4879908 },
    { lat: 49.0338887, lng: 19.4882053 },
    { lat: 49.0336523, lng: 19.4882808 },
    { lat: 49.0336308, lng: 19.4880151 },
    { lat: 49.0334285, lng: 19.4881706 },
    { lat: 49.033307, lng: 19.4881523 },
    { lat: 49.0331911, lng: 19.4879114 },
    { lat: 49.0329825, lng: 19.4878058 },
    { lat: 49.0328672, lng: 19.48785 },
    { lat: 49.0327906, lng: 19.4877256 },
    { lat: 49.0326778, lng: 19.4878234 },
    { lat: 49.0326108, lng: 19.4876661 },
    { lat: 49.0324693, lng: 19.487675 },
    { lat: 49.0323556, lng: 19.4876016 },
    { lat: 49.0321086, lng: 19.4877694 },
    { lat: 49.0318864, lng: 19.4876408 },
    { lat: 49.031797, lng: 19.4877113 },
    { lat: 49.0318002, lng: 19.4878266 },
    { lat: 49.0316016, lng: 19.4877691 },
    { lat: 49.0315457, lng: 19.4877093 },
    { lat: 49.031169, lng: 19.4879706 },
    { lat: 49.0310428, lng: 19.4881413 },
    { lat: 49.0309069, lng: 19.4882272 },
    { lat: 49.030796, lng: 19.4883366 },
    { lat: 49.0307097, lng: 19.4885185 },
    { lat: 49.030579, lng: 19.4885217 },
    { lat: 49.030529, lng: 19.4885334 },
    { lat: 49.0302436, lng: 19.4886988 },
    { lat: 49.0299228, lng: 19.4889002 },
    { lat: 49.0296465, lng: 19.488972 },
    { lat: 49.0295024, lng: 19.4890746 },
    { lat: 49.0294073, lng: 19.4889673 },
    { lat: 49.0291273, lng: 19.4889539 },
    { lat: 49.0288571, lng: 19.489281 },
    { lat: 49.0287846, lng: 19.4892449 },
    { lat: 49.0281804, lng: 19.4893382 },
    { lat: 49.0281778, lng: 19.4894559 },
    { lat: 49.0280795, lng: 19.4895213 },
    { lat: 49.027976, lng: 19.4894006 },
    { lat: 49.0274076, lng: 19.4895651 },
    { lat: 49.0272822, lng: 19.4896768 },
    { lat: 49.0271659, lng: 19.4895761 },
    { lat: 49.027088, lng: 19.4896246 },
    { lat: 49.026956, lng: 19.489495 },
    { lat: 49.0265244, lng: 19.4893469 },
    { lat: 49.026097, lng: 19.4891665 },
    { lat: 49.0258093, lng: 19.4887447 },
    { lat: 49.0255016, lng: 19.4880093 },
    { lat: 49.024932, lng: 19.4874577 },
    { lat: 49.0247786, lng: 19.4872812 },
    { lat: 49.024685, lng: 19.4872571 },
    { lat: 49.0244963, lng: 19.4869288 },
    { lat: 49.0242092, lng: 19.4865655 },
    { lat: 49.0240529, lng: 19.4864256 },
    { lat: 49.0239033, lng: 19.4861355 },
    { lat: 49.0236781, lng: 19.4859685 },
    { lat: 49.023315, lng: 19.4856656 },
    { lat: 49.0228717, lng: 19.4854133 },
    { lat: 49.0224005, lng: 19.4850208 },
    { lat: 49.0220247, lng: 19.4848593 },
    { lat: 49.0215691, lng: 19.4845592 },
    { lat: 49.0213773, lng: 19.4843577 },
    { lat: 49.0210697, lng: 19.4843115 },
    { lat: 49.0209091, lng: 19.4843177 },
    { lat: 49.0208562, lng: 19.4842851 },
    { lat: 49.0206977, lng: 19.4841111 },
    { lat: 49.0204257, lng: 19.4840751 },
    { lat: 49.0202249, lng: 19.4839952 },
    { lat: 49.0198662, lng: 19.4839903 },
    { lat: 49.0197544, lng: 19.484056 },
    { lat: 49.019603, lng: 19.4839519 },
    { lat: 49.0195001, lng: 19.483993 },
    { lat: 49.0190816, lng: 19.4838591 },
    { lat: 49.0189837, lng: 19.4839836 },
    { lat: 49.0187839, lng: 19.4840038 },
    { lat: 49.018485, lng: 19.4839943 },
    { lat: 49.0183766, lng: 19.484041 },
    { lat: 49.0180706, lng: 19.484 },
    { lat: 49.0178665, lng: 19.4841929 },
    { lat: 49.0176746, lng: 19.4840927 },
    { lat: 49.017214, lng: 19.4843909 },
    { lat: 49.0170051, lng: 19.4844795 },
    { lat: 49.0168596, lng: 19.4844017 },
    { lat: 49.0167207, lng: 19.4843446 },
    { lat: 49.0165242, lng: 19.4842162 },
    { lat: 49.0163957, lng: 19.484241 },
    { lat: 49.0160768, lng: 19.4840879 },
    { lat: 49.0158111, lng: 19.4840716 },
    { lat: 49.0155707, lng: 19.4840861 },
    { lat: 49.0155019, lng: 19.484163 },
    { lat: 49.0151899, lng: 19.4842123 },
    { lat: 49.0150626, lng: 19.4841652 },
    { lat: 49.014999, lng: 19.4841788 },
    { lat: 49.0149199, lng: 19.4842705 },
    { lat: 49.0147935, lng: 19.4842183 },
    { lat: 49.0142744, lng: 19.4844939 },
    { lat: 49.0140749, lng: 19.4847395 },
    { lat: 49.0139311, lng: 19.4849454 },
    { lat: 49.0136274, lng: 19.4852268 },
    { lat: 49.0134432, lng: 19.4853854 },
    { lat: 49.0132507, lng: 19.485637 },
    { lat: 49.0130042, lng: 19.4859353 },
    { lat: 49.0127753, lng: 19.4861524 },
    { lat: 49.0126083, lng: 19.4863938 },
    { lat: 49.0124925, lng: 19.4863953 },
    { lat: 49.0123603, lng: 19.486535 },
    { lat: 49.0122909, lng: 19.4867201 },
    { lat: 49.0121843, lng: 19.4867285 },
    { lat: 49.0120129, lng: 19.4873412 },
    { lat: 49.0119022, lng: 19.4872848 },
    { lat: 49.0118158, lng: 19.487388 },
    { lat: 49.0118069, lng: 19.4873904 },
    { lat: 49.011399, lng: 19.4876384 },
    { lat: 49.0100096, lng: 19.4866327 },
    { lat: 49.0085566, lng: 19.4868417 },
    { lat: 49.008443, lng: 19.4868208 },
    { lat: 49.0082554, lng: 19.4880083 },
    { lat: 49.0078813, lng: 19.4888187 },
    { lat: 49.0072536, lng: 19.4887953 },
    { lat: 49.0065356, lng: 19.4894495 },
    { lat: 49.0061107, lng: 19.4895704 },
    { lat: 49.004822, lng: 19.4906613 },
    { lat: 49.0043595, lng: 19.4913116 },
    { lat: 49.003616, lng: 19.4920258 },
    { lat: 49.0023749, lng: 19.4931923 },
    { lat: 49.0015149, lng: 19.4941212 },
    { lat: 49.0009701, lng: 19.4935456 },
    { lat: 49.0005461, lng: 19.4919437 },
    { lat: 48.9997786, lng: 19.4911344 },
    { lat: 48.9992074, lng: 19.4907673 },
    { lat: 48.9989155, lng: 19.4907183 },
    { lat: 48.9988178, lng: 19.4907258 },
    { lat: 48.9981574, lng: 19.4901615 },
    { lat: 48.9977461, lng: 19.4896184 },
    { lat: 48.9974414, lng: 19.4893382 },
    { lat: 48.9970671, lng: 19.4893844 },
    { lat: 48.9966799, lng: 19.4892737 },
    { lat: 48.9946663, lng: 19.4891256 },
    { lat: 48.9940406, lng: 19.4892193 },
    { lat: 48.9924468, lng: 19.4900693 },
    { lat: 48.9919682, lng: 19.4898287 },
    { lat: 48.9915588, lng: 19.4897211 },
    { lat: 48.9895826, lng: 19.4924986 },
    { lat: 48.9874456, lng: 19.4922428 },
    { lat: 48.9858423, lng: 19.4925207 },
    { lat: 48.9849954, lng: 19.4924556 },
    { lat: 48.983785, lng: 19.4919623 },
    { lat: 48.9817431, lng: 19.4919085 },
    { lat: 48.9798245, lng: 19.4909624 },
    { lat: 48.9779341, lng: 19.4905335 },
    { lat: 48.9769577, lng: 19.4900818 },
    { lat: 48.9751878, lng: 19.488817 },
    { lat: 48.9730056, lng: 19.4873331 },
    { lat: 48.9718799, lng: 19.4857654 },
    { lat: 48.9687052, lng: 19.4838248 },
    { lat: 48.9676897, lng: 19.482992 },
    { lat: 48.9638757, lng: 19.4829006 },
    { lat: 48.9600914, lng: 19.4862211 },
    { lat: 48.959607, lng: 19.48723 },
    { lat: 48.9578165, lng: 19.487817 },
    { lat: 48.9557532, lng: 19.4870331 },
    { lat: 48.9538766, lng: 19.4860732 },
    { lat: 48.9508669, lng: 19.4862218 },
    { lat: 48.9500609, lng: 19.4864099 },
    { lat: 48.9479643, lng: 19.4887498 },
    { lat: 48.9464426, lng: 19.4897427 },
    { lat: 48.9456961, lng: 19.490075 },
    { lat: 48.9444873, lng: 19.4905459 },
    { lat: 48.943267, lng: 19.491013 },
  ],
  Galovany: [
    { lat: 49.1005993, lng: 19.4986772 },
    { lat: 49.0999226, lng: 19.4994255 },
    { lat: 49.0998148, lng: 19.4996811 },
    { lat: 49.0990659, lng: 19.4995949 },
    { lat: 49.0990888, lng: 19.4998211 },
    { lat: 49.0994957, lng: 19.4998736 },
    { lat: 49.0994595, lng: 19.4999705 },
    { lat: 49.0993944, lng: 19.500318 },
    { lat: 49.0993873, lng: 19.5005072 },
    { lat: 49.0993895, lng: 19.5008748 },
    { lat: 49.0993781, lng: 19.5010695 },
    { lat: 49.0994191, lng: 19.5012284 },
    { lat: 49.0994852, lng: 19.5015637 },
    { lat: 49.0995741, lng: 19.5017358 },
    { lat: 49.0997244, lng: 19.5018418 },
    { lat: 49.0998302, lng: 19.5020114 },
    { lat: 49.0997638, lng: 19.5021601 },
    { lat: 49.0997497, lng: 19.5023465 },
    { lat: 49.0996763, lng: 19.5023632 },
    { lat: 49.0994949, lng: 19.5025382 },
    { lat: 49.0994319, lng: 19.5026409 },
    { lat: 49.0994842, lng: 19.5027993 },
    { lat: 49.0995603, lng: 19.5028117 },
    { lat: 49.0995689, lng: 19.5028748 },
    { lat: 49.0995322, lng: 19.5029855 },
    { lat: 49.0992293, lng: 19.5035549 },
    { lat: 49.099163, lng: 19.5035824 },
    { lat: 49.0990358, lng: 19.5035111 },
    { lat: 49.0989347, lng: 19.5034948 },
    { lat: 49.0988393, lng: 19.5035776 },
    { lat: 49.0986881, lng: 19.5035965 },
    { lat: 49.0986022, lng: 19.5036884 },
    { lat: 49.0985992, lng: 19.503871 },
    { lat: 49.0985391, lng: 19.5039367 },
    { lat: 49.0984687, lng: 19.5039247 },
    { lat: 49.0983572, lng: 19.5041962 },
    { lat: 49.097758, lng: 19.5042231 },
    { lat: 49.097635, lng: 19.5042462 },
    { lat: 49.0975502, lng: 19.5041937 },
    { lat: 49.0972132, lng: 19.5044075 },
    { lat: 49.0971574, lng: 19.5043753 },
    { lat: 49.0971346, lng: 19.5042954 },
    { lat: 49.0969467, lng: 19.5043484 },
    { lat: 49.0969381, lng: 19.5042332 },
    { lat: 49.0968487, lng: 19.504275 },
    { lat: 49.0967341, lng: 19.5041606 },
    { lat: 49.0966784, lng: 19.5041967 },
    { lat: 49.0965122, lng: 19.5041874 },
    { lat: 49.0964887, lng: 19.5044635 },
    { lat: 49.0964447, lng: 19.5044614 },
    { lat: 49.0963886, lng: 19.5045874 },
    { lat: 49.0962949, lng: 19.5045107 },
    { lat: 49.0961929, lng: 19.5047437 },
    { lat: 49.0960782, lng: 19.5047228 },
    { lat: 49.0960108, lng: 19.5048504 },
    { lat: 49.0959703, lng: 19.5048522 },
    { lat: 49.0959313, lng: 19.5047902 },
    { lat: 49.095851, lng: 19.5050069 },
    { lat: 49.0958105, lng: 19.5050037 },
    { lat: 49.0957027, lng: 19.5051341 },
    { lat: 49.0955655, lng: 19.5053407 },
    { lat: 49.0954543, lng: 19.5053201 },
    { lat: 49.0953618, lng: 19.505368 },
    { lat: 49.0953021, lng: 19.5055642 },
    { lat: 49.0952713, lng: 19.5053866 },
    { lat: 49.0952314, lng: 19.5053761 },
    { lat: 49.0951314, lng: 19.5055032 },
    { lat: 49.095146, lng: 19.5056002 },
    { lat: 49.0951024, lng: 19.5056843 },
    { lat: 49.0949578, lng: 19.5054817 },
    { lat: 49.0949197, lng: 19.5054854 },
    { lat: 49.0948493, lng: 19.505645 },
    { lat: 49.0948877, lng: 19.5057452 },
    { lat: 49.0948045, lng: 19.5057997 },
    { lat: 49.0947271, lng: 19.5056569 },
    { lat: 49.0946262, lng: 19.5055939 },
    { lat: 49.0943453, lng: 19.5055126 },
    { lat: 49.0943165, lng: 19.5054035 },
    { lat: 49.0940601, lng: 19.5054145 },
    { lat: 49.0940465, lng: 19.5053386 },
    { lat: 49.0941027, lng: 19.5053352 },
    { lat: 49.0940741, lng: 19.505226 },
    { lat: 49.0940005, lng: 19.5051961 },
    { lat: 49.0938647, lng: 19.5052572 },
    { lat: 49.0938186, lng: 19.5051316 },
    { lat: 49.0937716, lng: 19.5051416 },
    { lat: 49.0937207, lng: 19.5054336 },
    { lat: 49.0935201, lng: 19.5053848 },
    { lat: 49.0934975, lng: 19.5055569 },
    { lat: 49.0934272, lng: 19.505541 },
    { lat: 49.0933716, lng: 19.5054365 },
    { lat: 49.0933254, lng: 19.5054842 },
    { lat: 49.0931602, lng: 19.505346 },
    { lat: 49.0929824, lng: 19.5055008 },
    { lat: 49.0929621, lng: 19.5055996 },
    { lat: 49.0928836, lng: 19.5055566 },
    { lat: 49.0928389, lng: 19.50574 },
    { lat: 49.092722, lng: 19.5057707 },
    { lat: 49.0926457, lng: 19.5060488 },
    { lat: 49.0925782, lng: 19.5058989 },
    { lat: 49.0923427, lng: 19.5059293 },
    { lat: 49.0924117, lng: 19.5061783 },
    { lat: 49.0922933, lng: 19.506325 },
    { lat: 49.0921667, lng: 19.5062147 },
    { lat: 49.0920671, lng: 19.5062835 },
    { lat: 49.0919703, lng: 19.5061852 },
    { lat: 49.0919506, lng: 19.50605 },
    { lat: 49.0918541, lng: 19.5060613 },
    { lat: 49.0917415, lng: 19.5062832 },
    { lat: 49.0916562, lng: 19.5061901 },
    { lat: 49.0916148, lng: 19.5063416 },
    { lat: 49.0914281, lng: 19.5064006 },
    { lat: 49.0913745, lng: 19.5064893 },
    { lat: 49.0912616, lng: 19.5064046 },
    { lat: 49.0912514, lng: 19.5065541 },
    { lat: 49.0911407, lng: 19.5065954 },
    { lat: 49.0910683, lng: 19.5065146 },
    { lat: 49.0908102, lng: 19.5065919 },
    { lat: 49.0908162, lng: 19.5064504 },
    { lat: 49.0907466, lng: 19.5063848 },
    { lat: 49.0906777, lng: 19.5064787 },
    { lat: 49.0905963, lng: 19.5063249 },
    { lat: 49.0904994, lng: 19.5064207 },
    { lat: 49.0902956, lng: 19.5064765 },
    { lat: 49.0902792, lng: 19.5066342 },
    { lat: 49.090231, lng: 19.5066138 },
    { lat: 49.0902076, lng: 19.5067407 },
    { lat: 49.0900699, lng: 19.5067618 },
    { lat: 49.0894607, lng: 19.5069673 },
    { lat: 49.0894409, lng: 19.5070039 },
    { lat: 49.0891208, lng: 19.5071235 },
    { lat: 49.0883048, lng: 19.5075634 },
    { lat: 49.0883474, lng: 19.5078299 },
    { lat: 49.0881443, lng: 19.5078479 },
    { lat: 49.0880966, lng: 19.5080136 },
    { lat: 49.0879983, lng: 19.5079813 },
    { lat: 49.0878862, lng: 19.5080917 },
    { lat: 49.0877901, lng: 19.5079879 },
    { lat: 49.0877146, lng: 19.5080088 },
    { lat: 49.0876877, lng: 19.5079434 },
    { lat: 49.0874039, lng: 19.5079692 },
    { lat: 49.0874086, lng: 19.5080467 },
    { lat: 49.0872984, lng: 19.5081511 },
    { lat: 49.0871988, lng: 19.5083382 },
    { lat: 49.0871434, lng: 19.5083081 },
    { lat: 49.0870443, lng: 19.5084808 },
    { lat: 49.0867723, lng: 19.5085466 },
    { lat: 49.0868205, lng: 19.5087644 },
    { lat: 49.0866867, lng: 19.5088433 },
    { lat: 49.0865077, lng: 19.5087515 },
    { lat: 49.086495, lng: 19.5089625 },
    { lat: 49.086304, lng: 19.5091499 },
    { lat: 49.0863216, lng: 19.5092857 },
    { lat: 49.0861821, lng: 19.5093127 },
    { lat: 49.0859335, lng: 19.5095199 },
    { lat: 49.0858227, lng: 19.5095147 },
    { lat: 49.0858009, lng: 19.5095789 },
    { lat: 49.0855964, lng: 19.5096667 },
    { lat: 49.0853489, lng: 19.5099194 },
    { lat: 49.0852274, lng: 19.510148 },
    { lat: 49.0850483, lng: 19.5105901 },
    { lat: 49.0833045, lng: 19.5104028 },
    { lat: 49.080321, lng: 19.5109774 },
    { lat: 49.0802877, lng: 19.5109874 },
    { lat: 49.0802557, lng: 19.5109793 },
    { lat: 49.0783259, lng: 19.5114038 },
    { lat: 49.0756784, lng: 19.5112196 },
    { lat: 49.0744118, lng: 19.5114874 },
    { lat: 49.0732285, lng: 19.5119287 },
    { lat: 49.0727695, lng: 19.5125599 },
    { lat: 49.0720745, lng: 19.5138227 },
    { lat: 49.0719794, lng: 19.5142583 },
    { lat: 49.0714301, lng: 19.5157493 },
    { lat: 49.071449, lng: 19.5168287 },
    { lat: 49.068606, lng: 19.5195402 },
    { lat: 49.0686109, lng: 19.519673 },
    { lat: 49.0643332, lng: 19.5181605 },
    { lat: 49.0643323, lng: 19.5200647 },
    { lat: 49.0643332, lng: 19.5202544 },
    { lat: 49.0643254, lng: 19.5208237 },
    { lat: 49.0642054, lng: 19.5223941 },
    { lat: 49.0641853, lng: 19.522642 },
    { lat: 49.0641582, lng: 19.5229118 },
    { lat: 49.0641208, lng: 19.5234289 },
    { lat: 49.0641167, lng: 19.5239794 },
    { lat: 49.0641215, lng: 19.5246279 },
    { lat: 49.0640171, lng: 19.5247629 },
    { lat: 49.0640802, lng: 19.5250793 },
    { lat: 49.0640996, lng: 19.5252367 },
    { lat: 49.0641604, lng: 19.5258282 },
    { lat: 49.0642055, lng: 19.526347 },
    { lat: 49.0642343, lng: 19.5265844 },
    { lat: 49.0643268, lng: 19.5271071 },
    { lat: 49.0644117, lng: 19.5277306 },
    { lat: 49.0644556, lng: 19.5282481 },
    { lat: 49.0644504, lng: 19.5288749 },
    { lat: 49.0645157, lng: 19.528951 },
    { lat: 49.0646374, lng: 19.5287765 },
    { lat: 49.064793, lng: 19.528755 },
    { lat: 49.0650531, lng: 19.528923 },
    { lat: 49.0653346, lng: 19.5289921 },
    { lat: 49.0655853, lng: 19.5289549 },
    { lat: 49.0656003, lng: 19.5290356 },
    { lat: 49.0657128, lng: 19.5300737 },
    { lat: 49.0657974, lng: 19.5300756 },
    { lat: 49.0659025, lng: 19.5299577 },
    { lat: 49.0659045, lng: 19.5301713 },
    { lat: 49.0658637, lng: 19.531433 },
    { lat: 49.0660211, lng: 19.531374 },
    { lat: 49.0666167, lng: 19.5314387 },
    { lat: 49.0669398, lng: 19.5314808 },
    { lat: 49.0672129, lng: 19.5315623 },
    { lat: 49.0670231, lng: 19.5332852 },
    { lat: 49.0670146, lng: 19.5333925 },
    { lat: 49.0670149, lng: 19.5333989 },
    { lat: 49.0669238, lng: 19.5342972 },
    { lat: 49.0668731, lng: 19.5349638 },
    { lat: 49.066803, lng: 19.5356543 },
    { lat: 49.0667993, lng: 19.5357944 },
    { lat: 49.0667838, lng: 19.5360214 },
    { lat: 49.0667477, lng: 19.5363166 },
    { lat: 49.0667273, lng: 19.5365369 },
    { lat: 49.0666914, lng: 19.5368375 },
    { lat: 49.0666754, lng: 19.5371299 },
    { lat: 49.0666926, lng: 19.5375859 },
    { lat: 49.0665986, lng: 19.5381919 },
    { lat: 49.0665713, lng: 19.5383357 },
    { lat: 49.0665275, lng: 19.5384621 },
    { lat: 49.0661042, lng: 19.5397349 },
    { lat: 49.06601, lng: 19.5399684 },
    { lat: 49.0655241, lng: 19.5406807 },
    { lat: 49.0647484, lng: 19.5408918 },
    { lat: 49.064457, lng: 19.5409991 },
    { lat: 49.0634703, lng: 19.5414788 },
    { lat: 49.0634577, lng: 19.5448675 },
    { lat: 49.0636348, lng: 19.5465373 },
    { lat: 49.0638924, lng: 19.5462558 },
    { lat: 49.0650063, lng: 19.5448565 },
    { lat: 49.0660687, lng: 19.5437105 },
    { lat: 49.0664978, lng: 19.5447458 },
    { lat: 49.0669509, lng: 19.545843 },
    { lat: 49.0671224, lng: 19.5450028 },
    { lat: 49.0671843, lng: 19.5447278 },
    { lat: 49.0672986, lng: 19.5441944 },
    { lat: 49.0686001, lng: 19.5450154 },
    { lat: 49.0701366, lng: 19.5459738 },
    { lat: 49.0704467, lng: 19.5456063 },
    { lat: 49.0710663, lng: 19.5457538 },
    { lat: 49.0712831, lng: 19.5458115 },
    { lat: 49.0749607, lng: 19.54973 },
    { lat: 49.0750471, lng: 19.5505878 },
    { lat: 49.0750483, lng: 19.550817 },
    { lat: 49.0752591, lng: 19.55077 },
    { lat: 49.0753828, lng: 19.5515552 },
    { lat: 49.0754336, lng: 19.5516598 },
    { lat: 49.0754814, lng: 19.5517192 },
    { lat: 49.0757427, lng: 19.5514207 },
    { lat: 49.0761692, lng: 19.5512364 },
    { lat: 49.0766423, lng: 19.5508337 },
    { lat: 49.076967, lng: 19.5509379 },
    { lat: 49.0770666, lng: 19.5508707 },
    { lat: 49.0770484, lng: 19.5507702 },
    { lat: 49.0771436, lng: 19.5507034 },
    { lat: 49.0773316, lng: 19.5507253 },
    { lat: 49.0774548, lng: 19.5508349 },
    { lat: 49.0774748, lng: 19.5507282 },
    { lat: 49.0776969, lng: 19.5507587 },
    { lat: 49.0779499, lng: 19.5505938 },
    { lat: 49.0780628, lng: 19.5502509 },
    { lat: 49.0782139, lng: 19.5503043 },
    { lat: 49.0783135, lng: 19.5501404 },
    { lat: 49.0785253, lng: 19.5501943 },
    { lat: 49.078506, lng: 19.5503135 },
    { lat: 49.0785794, lng: 19.5504654 },
    { lat: 49.0784734, lng: 19.5505851 },
    { lat: 49.0785462, lng: 19.5507535 },
    { lat: 49.0786899, lng: 19.5508401 },
    { lat: 49.0786341, lng: 19.5510505 },
    { lat: 49.0789155, lng: 19.5514145 },
    { lat: 49.0790911, lng: 19.5516829 },
    { lat: 49.0791126, lng: 19.5519124 },
    { lat: 49.0792119, lng: 19.5519358 },
    { lat: 49.079466, lng: 19.5523487 },
    { lat: 49.0796048, lng: 19.5524523 },
    { lat: 49.0797394, lng: 19.5523634 },
    { lat: 49.0797846, lng: 19.552464 },
    { lat: 49.0798271, lng: 19.5524854 },
    { lat: 49.0799774, lng: 19.5524412 },
    { lat: 49.0802254, lng: 19.5525474 },
    { lat: 49.0802778, lng: 19.5524588 },
    { lat: 49.0804694, lng: 19.5525854 },
    { lat: 49.0806473, lng: 19.5526313 },
    { lat: 49.0807296, lng: 19.5528042 },
    { lat: 49.0807445, lng: 19.5529367 },
    { lat: 49.0808572, lng: 19.5530873 },
    { lat: 49.0809319, lng: 19.553366 },
    { lat: 49.0810603, lng: 19.5535361 },
    { lat: 49.0811944, lng: 19.553439 },
    { lat: 49.0813731, lng: 19.5534533 },
    { lat: 49.0814505, lng: 19.5535979 },
    { lat: 49.0815752, lng: 19.5533896 },
    { lat: 49.0817686, lng: 19.5533808 },
    { lat: 49.0818375, lng: 19.5532866 },
    { lat: 49.0820263, lng: 19.5534705 },
    { lat: 49.0821225, lng: 19.5534324 },
    { lat: 49.0822803, lng: 19.5534075 },
    { lat: 49.0829215, lng: 19.5535364 },
    { lat: 49.0834182, lng: 19.5535291 },
    { lat: 49.083614, lng: 19.5536757 },
    { lat: 49.083799, lng: 19.553626 },
    { lat: 49.0840963, lng: 19.5536494 },
    { lat: 49.0843048, lng: 19.5537001 },
    { lat: 49.0853567, lng: 19.55394 },
    { lat: 49.0858438, lng: 19.5538464 },
    { lat: 49.0859117, lng: 19.5535064 },
    { lat: 49.0865402, lng: 19.5532243 },
    { lat: 49.0865929, lng: 19.5531213 },
    { lat: 49.0866521, lng: 19.5531097 },
    { lat: 49.0867367, lng: 19.5530642 },
    { lat: 49.0868447, lng: 19.5529841 },
    { lat: 49.0868845, lng: 19.5531902 },
    { lat: 49.0869792, lng: 19.5531965 },
    { lat: 49.087, lng: 19.553254 },
    { lat: 49.0869321, lng: 19.5534229 },
    { lat: 49.0869951, lng: 19.5534942 },
    { lat: 49.0871088, lng: 19.5534406 },
    { lat: 49.0871368, lng: 19.5535113 },
    { lat: 49.0871318, lng: 19.553651 },
    { lat: 49.0871895, lng: 19.5536047 },
    { lat: 49.0873537, lng: 19.5538732 },
    { lat: 49.0872669, lng: 19.5539222 },
    { lat: 49.0872652, lng: 19.5540094 },
    { lat: 49.0871912, lng: 19.5540902 },
    { lat: 49.0872081, lng: 19.5541657 },
    { lat: 49.0872649, lng: 19.5541266 },
    { lat: 49.0873189, lng: 19.5541738 },
    { lat: 49.0872469, lng: 19.5542777 },
    { lat: 49.0871589, lng: 19.5542992 },
    { lat: 49.0871662, lng: 19.5544346 },
    { lat: 49.087267, lng: 19.5544701 },
    { lat: 49.0872455, lng: 19.5546948 },
    { lat: 49.087468, lng: 19.554779 },
    { lat: 49.0874415, lng: 19.5546681 },
    { lat: 49.087524, lng: 19.5546239 },
    { lat: 49.0875278, lng: 19.5547558 },
    { lat: 49.087561, lng: 19.5548156 },
    { lat: 49.0875803, lng: 19.554747 },
    { lat: 49.0875663, lng: 19.5546104 },
    { lat: 49.0876659, lng: 19.5545723 },
    { lat: 49.0877441, lng: 19.5547028 },
    { lat: 49.0878422, lng: 19.5545807 },
    { lat: 49.0882091, lng: 19.5545231 },
    { lat: 49.0882298, lng: 19.5546047 },
    { lat: 49.0883171, lng: 19.55462 },
    { lat: 49.0885475, lng: 19.5544318 },
    { lat: 49.0885778, lng: 19.5542812 },
    { lat: 49.0886412, lng: 19.5543619 },
    { lat: 49.0891062, lng: 19.5542555 },
    { lat: 49.0893694, lng: 19.5542692 },
    { lat: 49.0894963, lng: 19.5542099 },
    { lat: 49.0898869, lng: 19.5541016 },
    { lat: 49.0900908, lng: 19.5541251 },
    { lat: 49.09028, lng: 19.5540232 },
    { lat: 49.0903179, lng: 19.554089 },
    { lat: 49.0904192, lng: 19.5540614 },
    { lat: 49.0907234, lng: 19.5541121 },
    { lat: 49.0908685, lng: 19.5540832 },
    { lat: 49.0910399, lng: 19.5541598 },
    { lat: 49.0916568, lng: 19.554169 },
    { lat: 49.0918347, lng: 19.5541463 },
    { lat: 49.0919913, lng: 19.5541953 },
    { lat: 49.0921115, lng: 19.5541834 },
    { lat: 49.0928073, lng: 19.5543252 },
    { lat: 49.0936542, lng: 19.5545893 },
    { lat: 49.0937858, lng: 19.5546071 },
    { lat: 49.0942791, lng: 19.5548018 },
    { lat: 49.0945291, lng: 19.5546965 },
    { lat: 49.0946354, lng: 19.5548072 },
    { lat: 49.0947848, lng: 19.5547954 },
    { lat: 49.0953604, lng: 19.5548198 },
    { lat: 49.0955769, lng: 19.5548988 },
    { lat: 49.0961223, lng: 19.5551215 },
    { lat: 49.0976486, lng: 19.5533956 },
    { lat: 49.0978841, lng: 19.5525751 },
    { lat: 49.0982644, lng: 19.5511263 },
    { lat: 49.0984239, lng: 19.5502692 },
    { lat: 49.0984329, lng: 19.5491982 },
    { lat: 49.0984278, lng: 19.5482168 },
    { lat: 49.0985444, lng: 19.5474095 },
    { lat: 49.0987731, lng: 19.546517 },
    { lat: 49.0989259, lng: 19.5460867 },
    { lat: 49.0989766, lng: 19.5458879 },
    { lat: 49.0989923, lng: 19.545713 },
    { lat: 49.0991038, lng: 19.54504 },
    { lat: 49.0991475, lng: 19.5444881 },
    { lat: 49.0990405, lng: 19.5437227 },
    { lat: 49.0987908, lng: 19.5432366 },
    { lat: 49.0986173, lng: 19.5429888 },
    { lat: 49.0982234, lng: 19.5430436 },
    { lat: 49.0981643, lng: 19.5427622 },
    { lat: 49.0981969, lng: 19.5425656 },
    { lat: 49.0982514, lng: 19.5423517 },
    { lat: 49.0983844, lng: 19.5422751 },
    { lat: 49.0986013, lng: 19.5420635 },
    { lat: 49.098506, lng: 19.5417284 },
    { lat: 49.0984908, lng: 19.541342 },
    { lat: 49.0984844, lng: 19.5407799 },
    { lat: 49.0985081, lng: 19.5405847 },
    { lat: 49.0986098, lng: 19.5402662 },
    { lat: 49.0991303, lng: 19.5395741 },
    { lat: 49.1000493, lng: 19.538921 },
    { lat: 49.1005249, lng: 19.5392185 },
    { lat: 49.1008524, lng: 19.538937 },
    { lat: 49.1011343, lng: 19.5384955 },
    { lat: 49.1012943, lng: 19.537206 },
    { lat: 49.101663, lng: 19.5360467 },
    { lat: 49.1013944, lng: 19.5348499 },
    { lat: 49.1021238, lng: 19.5337441 },
    { lat: 49.1024876, lng: 19.5328725 },
    { lat: 49.1029495, lng: 19.5316852 },
    { lat: 49.1034971, lng: 19.5295812 },
    { lat: 49.1041758, lng: 19.5270404 },
    { lat: 49.1042789, lng: 19.5266598 },
    { lat: 49.1049726, lng: 19.5245251 },
    { lat: 49.1052073, lng: 19.5230949 },
    { lat: 49.1052366, lng: 19.5229519 },
    { lat: 49.1057402, lng: 19.5227012 },
    { lat: 49.1060408, lng: 19.5224223 },
    { lat: 49.1065196, lng: 19.5218516 },
    { lat: 49.1068716, lng: 19.5211476 },
    { lat: 49.1063225, lng: 19.5208188 },
    { lat: 49.1070142, lng: 19.5193833 },
    { lat: 49.1074093, lng: 19.5192026 },
    { lat: 49.1074316, lng: 19.5184033 },
    { lat: 49.1070505, lng: 19.5173108 },
    { lat: 49.1071265, lng: 19.5168553 },
    { lat: 49.1071072, lng: 19.5161126 },
    { lat: 49.1071185, lng: 19.5150781 },
    { lat: 49.1069815, lng: 19.514988 },
    { lat: 49.1070868, lng: 19.5145791 },
    { lat: 49.1072017, lng: 19.5139102 },
    { lat: 49.1070991, lng: 19.5139603 },
    { lat: 49.1070318, lng: 19.5137469 },
    { lat: 49.1069382, lng: 19.5135493 },
    { lat: 49.1070423, lng: 19.5134344 },
    { lat: 49.1070645, lng: 19.5118956 },
    { lat: 49.1069914, lng: 19.5114319 },
    { lat: 49.1069605, lng: 19.5112723 },
    { lat: 49.1068305, lng: 19.5112911 },
    { lat: 49.106778, lng: 19.5113448 },
    { lat: 49.1056253, lng: 19.5091766 },
    { lat: 49.1055306, lng: 19.5080908 },
    { lat: 49.1054639, lng: 19.5070298 },
    { lat: 49.1054624, lng: 19.5062071 },
    { lat: 49.1042081, lng: 19.5032248 },
    { lat: 49.1005993, lng: 19.4986772 },
  ],
  Gôtovany: [
    { lat: 49.0643332, lng: 19.5181605 },
    { lat: 49.0686109, lng: 19.519673 },
    { lat: 49.068606, lng: 19.5195402 },
    { lat: 49.071449, lng: 19.5168287 },
    { lat: 49.0714301, lng: 19.5157493 },
    { lat: 49.0719794, lng: 19.5142583 },
    { lat: 49.0720745, lng: 19.5138227 },
    { lat: 49.0727695, lng: 19.5125599 },
    { lat: 49.0732285, lng: 19.5119287 },
    { lat: 49.0744118, lng: 19.5114874 },
    { lat: 49.0745032, lng: 19.5082658 },
    { lat: 49.0744865, lng: 19.5060558 },
    { lat: 49.0744822, lng: 19.5055904 },
    { lat: 49.0744991, lng: 19.5046535 },
    { lat: 49.0745876, lng: 19.5034879 },
    { lat: 49.0747957, lng: 19.5007727 },
    { lat: 49.0749085, lng: 19.4998173 },
    { lat: 49.07508, lng: 19.4982942 },
    { lat: 49.0752507, lng: 19.4955352 },
    { lat: 49.0752548, lng: 19.4942438 },
    { lat: 49.0753462, lng: 19.4917699 },
    { lat: 49.0753356, lng: 19.4916841 },
    { lat: 49.0732361, lng: 19.4913104 },
    { lat: 49.0723467, lng: 19.4913535 },
    { lat: 49.0717171, lng: 19.4915064 },
    { lat: 49.0711908, lng: 19.4920986 },
    { lat: 49.0709873, lng: 19.4923247 },
    { lat: 49.0709134, lng: 19.4928037 },
    { lat: 49.0707149, lng: 19.4933136 },
    { lat: 49.0707089, lng: 19.4942892 },
    { lat: 49.070463, lng: 19.4953633 },
    { lat: 49.071032, lng: 19.4958297 },
    { lat: 49.0711293, lng: 19.4963314 },
    { lat: 49.0707272, lng: 19.4967274 },
    { lat: 49.0705213, lng: 19.4971037 },
    { lat: 49.0704709, lng: 19.4970842 },
    { lat: 49.0704052, lng: 19.4971505 },
    { lat: 49.0702807, lng: 19.4973089 },
    { lat: 49.070271, lng: 19.4970253 },
    { lat: 49.0702338, lng: 19.4969247 },
    { lat: 49.0699624, lng: 19.4968558 },
    { lat: 49.0697129, lng: 19.4971151 },
    { lat: 49.0694849, lng: 19.4972813 },
    { lat: 49.0692907, lng: 19.4972944 },
    { lat: 49.0691473, lng: 19.4973855 },
    { lat: 49.0690257, lng: 19.4964076 },
    { lat: 49.0689496, lng: 19.4963445 },
    { lat: 49.0678329, lng: 19.4967133 },
    { lat: 49.0677204, lng: 19.4972822 },
    { lat: 49.067789, lng: 19.4978424 },
    { lat: 49.0676811, lng: 19.4978721 },
    { lat: 49.0673661, lng: 19.497883 },
    { lat: 49.0672456, lng: 19.497664 },
    { lat: 49.0669665, lng: 19.4977213 },
    { lat: 49.0668091, lng: 19.4975073 },
    { lat: 49.0666528, lng: 19.4974409 },
    { lat: 49.0664568, lng: 19.4973474 },
    { lat: 49.0662448, lng: 19.497467 },
    { lat: 49.0660896, lng: 19.4973257 },
    { lat: 49.0660677, lng: 19.4970657 },
    { lat: 49.065945, lng: 19.4969943 },
    { lat: 49.0662193, lng: 19.4960506 },
    { lat: 49.063173, lng: 19.4949592 },
    { lat: 49.0631368, lng: 19.4949774 },
    { lat: 49.0630751, lng: 19.4948363 },
    { lat: 49.0629358, lng: 19.4948946 },
    { lat: 49.062798, lng: 19.4948406 },
    { lat: 49.0626992, lng: 19.494919 },
    { lat: 49.0625826, lng: 19.4949364 },
    { lat: 49.0624031, lng: 19.4951003 },
    { lat: 49.0622734, lng: 19.4953142 },
    { lat: 49.0619816, lng: 19.4952446 },
    { lat: 49.0618586, lng: 19.4950187 },
    { lat: 49.061717, lng: 19.4949062 },
    { lat: 49.0615855, lng: 19.4949108 },
    { lat: 49.0615662, lng: 19.495154 },
    { lat: 49.0614573, lng: 19.4953337 },
    { lat: 49.0613476, lng: 19.4952534 },
    { lat: 49.061335, lng: 19.4948825 },
    { lat: 49.0613216, lng: 19.494909 },
    { lat: 49.0612404, lng: 19.4951246 },
    { lat: 49.0612347, lng: 19.4951242 },
    { lat: 49.061186, lng: 19.4951189 },
    { lat: 49.0611305, lng: 19.4951133 },
    { lat: 49.061033, lng: 19.4951025 },
    { lat: 49.0609684, lng: 19.4950957 },
    { lat: 49.0609666, lng: 19.4951785 },
    { lat: 49.0609463, lng: 19.4964548 },
    { lat: 49.060945, lng: 19.4970658 },
    { lat: 49.0608444, lng: 19.4970797 },
    { lat: 49.0607672, lng: 19.4970904 },
    { lat: 49.0599949, lng: 19.4973553 },
    { lat: 49.059973, lng: 19.4973921 },
    { lat: 49.0596041, lng: 19.4973601 },
    { lat: 49.0593566, lng: 19.4973185 },
    { lat: 49.0585135, lng: 19.4974382 },
    { lat: 49.0579885, lng: 19.4975127 },
    { lat: 49.0569881, lng: 19.4978607 },
    { lat: 49.0568686, lng: 19.4979578 },
    { lat: 49.05649, lng: 19.4992124 },
    { lat: 49.0563428, lng: 19.4999559 },
    { lat: 49.0562507, lng: 19.5002085 },
    { lat: 49.0558789, lng: 19.5012673 },
    { lat: 49.0557733, lng: 19.5016198 },
    { lat: 49.0557334, lng: 19.5017092 },
    { lat: 49.0556671, lng: 19.5018588 },
    { lat: 49.0556019, lng: 19.5020087 },
    { lat: 49.0552424, lng: 19.50282 },
    { lat: 49.0552317, lng: 19.5029056 },
    { lat: 49.0551457, lng: 19.5035639 },
    { lat: 49.0552902, lng: 19.5043569 },
    { lat: 49.0552919, lng: 19.5043697 },
    { lat: 49.0553084, lng: 19.504456 },
    { lat: 49.0553236, lng: 19.5045452 },
    { lat: 49.0557487, lng: 19.5068358 },
    { lat: 49.0557929, lng: 19.5071167 },
    { lat: 49.0558018, lng: 19.5071338 },
    { lat: 49.0558697, lng: 19.507266 },
    { lat: 49.0559364, lng: 19.508032 },
    { lat: 49.0559802, lng: 19.5084477 },
    { lat: 49.0566645, lng: 19.5081844 },
    { lat: 49.0566866, lng: 19.50825 },
    { lat: 49.0572446, lng: 19.5080809 },
    { lat: 49.0582278, lng: 19.5079724 },
    { lat: 49.0587954, lng: 19.507743 },
    { lat: 49.0589316, lng: 19.5077175 },
    { lat: 49.0589501, lng: 19.5077762 },
    { lat: 49.0590797, lng: 19.5078006 },
    { lat: 49.0591285, lng: 19.5078349 },
    { lat: 49.0594209, lng: 19.5078481 },
    { lat: 49.0594385, lng: 19.5078092 },
    { lat: 49.0595541, lng: 19.5077499 },
    { lat: 49.0596786, lng: 19.5079839 },
    { lat: 49.0598783, lng: 19.5082858 },
    { lat: 49.0599233, lng: 19.5084078 },
    { lat: 49.0600289, lng: 19.5084976 },
    { lat: 49.0602921, lng: 19.5088362 },
    { lat: 49.0605972, lng: 19.5094916 },
    { lat: 49.0608543, lng: 19.5098154 },
    { lat: 49.0613567, lng: 19.510724 },
    { lat: 49.0615272, lng: 19.5111515 },
    { lat: 49.0616694, lng: 19.5114792 },
    { lat: 49.0616862, lng: 19.5114994 },
    { lat: 49.0615117, lng: 19.5119688 },
    { lat: 49.0613471, lng: 19.5126348 },
    { lat: 49.0612822, lng: 19.5128892 },
    { lat: 49.0611603, lng: 19.5133315 },
    { lat: 49.0610254, lng: 19.5139537 },
    { lat: 49.0609834, lng: 19.514638 },
    { lat: 49.0609891, lng: 19.5153499 },
    { lat: 49.0609582, lng: 19.5156352 },
    { lat: 49.0609868, lng: 19.5163594 },
    { lat: 49.0610342, lng: 19.516879 },
    { lat: 49.0610501, lng: 19.5169801 },
    { lat: 49.0620459, lng: 19.5170741 },
    { lat: 49.06273, lng: 19.5171027 },
    { lat: 49.0631906, lng: 19.5170741 },
    { lat: 49.0632394, lng: 19.5174962 },
    { lat: 49.0635818, lng: 19.5175437 },
    { lat: 49.0642953, lng: 19.5175993 },
    { lat: 49.0643332, lng: 19.5181605 },
  ],
  Huty: [
    { lat: 49.242461, lng: 19.557881 },
    { lat: 49.24249, lng: 19.557093 },
    { lat: 49.242482, lng: 19.557025 },
    { lat: 49.24238, lng: 19.555959 },
    { lat: 49.242363, lng: 19.555789 },
    { lat: 49.242343, lng: 19.555729 },
    { lat: 49.242009, lng: 19.554759 },
    { lat: 49.2411536, lng: 19.5548992 },
    { lat: 49.2399358, lng: 19.5548256 },
    { lat: 49.2393319, lng: 19.5551233 },
    { lat: 49.2375541, lng: 19.5546281 },
    { lat: 49.236799, lng: 19.5554931 },
    { lat: 49.2343667, lng: 19.5567979 },
    { lat: 49.2334255, lng: 19.557162 },
    { lat: 49.2330923, lng: 19.5572909 },
    { lat: 49.232782, lng: 19.5578529 },
    { lat: 49.2326468, lng: 19.5587277 },
    { lat: 49.2325289, lng: 19.5593389 },
    { lat: 49.2318842, lng: 19.5604542 },
    { lat: 49.23183, lng: 19.5605511 },
    { lat: 49.2315518, lng: 19.5607253 },
    { lat: 49.2314833, lng: 19.5609809 },
    { lat: 49.2313818, lng: 19.5611812 },
    { lat: 49.2311883, lng: 19.5613127 },
    { lat: 49.2309095, lng: 19.561603 },
    { lat: 49.2306158, lng: 19.5617057 },
    { lat: 49.2304303, lng: 19.5618907 },
    { lat: 49.2303737, lng: 19.562034 },
    { lat: 49.2304154, lng: 19.5621623 },
    { lat: 49.2304232, lng: 19.5622577 },
    { lat: 49.2303253, lng: 19.5623365 },
    { lat: 49.2300585, lng: 19.5625672 },
    { lat: 49.2297976, lng: 19.5628012 },
    { lat: 49.2295467, lng: 19.5630811 },
    { lat: 49.2295285, lng: 19.5630019 },
    { lat: 49.2295298, lng: 19.562983 },
    { lat: 49.2295896, lng: 19.5619132 },
    { lat: 49.2291581, lng: 19.5618101 },
    { lat: 49.2284714, lng: 19.5613521 },
    { lat: 49.2285264, lng: 19.5612219 },
    { lat: 49.2280067, lng: 19.5608909 },
    { lat: 49.2275711, lng: 19.5605251 },
    { lat: 49.2271641, lng: 19.5600971 },
    { lat: 49.2269861, lng: 19.5600232 },
    { lat: 49.2268436, lng: 19.5597592 },
    { lat: 49.2269456, lng: 19.5597481 },
    { lat: 49.2259173, lng: 19.5582097 },
    { lat: 49.2255046, lng: 19.5577773 },
    { lat: 49.2252896, lng: 19.557982 },
    { lat: 49.2248325, lng: 19.5574898 },
    { lat: 49.2244937, lng: 19.5570731 },
    { lat: 49.223771, lng: 19.5566381 },
    { lat: 49.2227782, lng: 19.5550405 },
    { lat: 49.2227672, lng: 19.5550219 },
    { lat: 49.2226615, lng: 19.5548513 },
    { lat: 49.2212163, lng: 19.5535121 },
    { lat: 49.220382, lng: 19.5528514 },
    { lat: 49.2200886, lng: 19.5526178 },
    { lat: 49.2194288, lng: 19.5515094 },
    { lat: 49.2191451, lng: 19.5510149 },
    { lat: 49.2189077, lng: 19.5508514 },
    { lat: 49.2180218, lng: 19.5502427 },
    { lat: 49.2179006, lng: 19.5503298 },
    { lat: 49.2176175, lng: 19.5502461 },
    { lat: 49.21685, lng: 19.5500185 },
    { lat: 49.2162469, lng: 19.5498399 },
    { lat: 49.2162003, lng: 19.5498264 },
    { lat: 49.2161792, lng: 19.5498085 },
    { lat: 49.2161362, lng: 19.5497824 },
    { lat: 49.2161185, lng: 19.5497666 },
    { lat: 49.2158081, lng: 19.5496278 },
    { lat: 49.2157655, lng: 19.5496088 },
    { lat: 49.2157172, lng: 19.5495865 },
    { lat: 49.215358, lng: 19.5492449 },
    { lat: 49.2150229, lng: 19.5486589 },
    { lat: 49.2144788, lng: 19.5481667 },
    { lat: 49.2139648, lng: 19.5477859 },
    { lat: 49.2132289, lng: 19.5472581 },
    { lat: 49.2132172, lng: 19.5472503 },
    { lat: 49.2129327, lng: 19.547036 },
    { lat: 49.2124309, lng: 19.5466273 },
    { lat: 49.2119533, lng: 19.5462391 },
    { lat: 49.2114304, lng: 19.5458597 },
    { lat: 49.2105552, lng: 19.5453444 },
    { lat: 49.2103416, lng: 19.5452311 },
    { lat: 49.2103132, lng: 19.5453231 },
    { lat: 49.2099767, lng: 19.5455562 },
    { lat: 49.2099597, lng: 19.5455291 },
    { lat: 49.2099475, lng: 19.5455099 },
    { lat: 49.2099185, lng: 19.5454639 },
    { lat: 49.2097191, lng: 19.5454158 },
    { lat: 49.2094351, lng: 19.545191 },
    { lat: 49.2093474, lng: 19.5451217 },
    { lat: 49.209247, lng: 19.5450429 },
    { lat: 49.2091826, lng: 19.5449913 },
    { lat: 49.2091266, lng: 19.5449475 },
    { lat: 49.2090846, lng: 19.5449134 },
    { lat: 49.2090068, lng: 19.5448888 },
    { lat: 49.2088719, lng: 19.5448454 },
    { lat: 49.2087416, lng: 19.5448043 },
    { lat: 49.2086134, lng: 19.5447627 },
    { lat: 49.2084637, lng: 19.5447151 },
    { lat: 49.2084512, lng: 19.5447114 },
    { lat: 49.2082529, lng: 19.5446116 },
    { lat: 49.2076175, lng: 19.5442938 },
    { lat: 49.2075753, lng: 19.5443048 },
    { lat: 49.2074317, lng: 19.5443401 },
    { lat: 49.2074249, lng: 19.5443419 },
    { lat: 49.2072948, lng: 19.5440796 },
    { lat: 49.2070774, lng: 19.5436856 },
    { lat: 49.206943, lng: 19.5436007 },
    { lat: 49.2067807, lng: 19.5436773 },
    { lat: 49.2064891, lng: 19.5434587 },
    { lat: 49.2060856, lng: 19.5431558 },
    { lat: 49.2056832, lng: 19.5428523 },
    { lat: 49.2056553, lng: 19.542861 },
    { lat: 49.2055998, lng: 19.5428773 },
    { lat: 49.2055798, lng: 19.5428838 },
    { lat: 49.2038247, lng: 19.5415737 },
    { lat: 49.2038087, lng: 19.5417931 },
    { lat: 49.2040406, lng: 19.5422363 },
    { lat: 49.2042675, lng: 19.5427153 },
    { lat: 49.2042676, lng: 19.5427189 },
    { lat: 49.2043695, lng: 19.5429813 },
    { lat: 49.2045125, lng: 19.543026 },
    { lat: 49.2045916, lng: 19.5432822 },
    { lat: 49.2047421, lng: 19.543592 },
    { lat: 49.2048568, lng: 19.5437374 },
    { lat: 49.2050761, lng: 19.5439016 },
    { lat: 49.2052232, lng: 19.5439643 },
    { lat: 49.2053911, lng: 19.5440647 },
    { lat: 49.2057161, lng: 19.5443493 },
    { lat: 49.2058348, lng: 19.5446085 },
    { lat: 49.2058619, lng: 19.5448301 },
    { lat: 49.2060536, lng: 19.545238 },
    { lat: 49.2062034, lng: 19.545683 },
    { lat: 49.2062751, lng: 19.5457692 },
    { lat: 49.2062626, lng: 19.5461179 },
    { lat: 49.206179, lng: 19.5463847 },
    { lat: 49.2062744, lng: 19.5466506 },
    { lat: 49.2064573, lng: 19.5469813 },
    { lat: 49.2065472, lng: 19.5472773 },
    { lat: 49.2067306, lng: 19.5476673 },
    { lat: 49.2067754, lng: 19.5477855 },
    { lat: 49.2068163, lng: 19.5478922 },
    { lat: 49.2068533, lng: 19.5479902 },
    { lat: 49.206863, lng: 19.5480051 },
    { lat: 49.2069332, lng: 19.5481123 },
    { lat: 49.2069586, lng: 19.5481514 },
    { lat: 49.2070008, lng: 19.5482129 },
    { lat: 49.2070407, lng: 19.5482706 },
    { lat: 49.2070539, lng: 19.5482898 },
    { lat: 49.2071992, lng: 19.5487091 },
    { lat: 49.2072287, lng: 19.5487935 },
    { lat: 49.2072499, lng: 19.5488601 },
    { lat: 49.2072763, lng: 19.5489467 },
    { lat: 49.2072841, lng: 19.5489678 },
    { lat: 49.2073019, lng: 19.5490128 },
    { lat: 49.2073692, lng: 19.5492828 },
    { lat: 49.2072884, lng: 19.5494124 },
    { lat: 49.2071622, lng: 19.5496135 },
    { lat: 49.2034151, lng: 19.5572118 },
    { lat: 49.2033642, lng: 19.5573091 },
    { lat: 49.2033197, lng: 19.5573956 },
    { lat: 49.2032491, lng: 19.5575305 },
    { lat: 49.2011278, lng: 19.5618722 },
    { lat: 49.2011029, lng: 19.5619227 },
    { lat: 49.2004119, lng: 19.5632885 },
    { lat: 49.2004752, lng: 19.5637906 },
    { lat: 49.200571, lng: 19.5644121 },
    { lat: 49.200963, lng: 19.5702045 },
    { lat: 49.2009916, lng: 19.5706404 },
    { lat: 49.2019459, lng: 19.5724887 },
    { lat: 49.2023042, lng: 19.5742641 },
    { lat: 49.2022672, lng: 19.5745959 },
    { lat: 49.2022565, lng: 19.5746828 },
    { lat: 49.2024711, lng: 19.5761642 },
    { lat: 49.2032054, lng: 19.5792381 },
    { lat: 49.2039191, lng: 19.5825538 },
    { lat: 49.203941, lng: 19.5869528 },
    { lat: 49.2045349, lng: 19.5871273 },
    { lat: 49.2049782, lng: 19.5878938 },
    { lat: 49.2049918, lng: 19.5879176 },
    { lat: 49.2055414, lng: 19.5883649 },
    { lat: 49.2064257, lng: 19.5892402 },
    { lat: 49.2068715, lng: 19.5900345 },
    { lat: 49.207572, lng: 19.5903825 },
    { lat: 49.2083785, lng: 19.5904019 },
    { lat: 49.2085748, lng: 19.5904058 },
    { lat: 49.20933, lng: 19.5915444 },
    { lat: 49.209544, lng: 19.5918673 },
    { lat: 49.2099696, lng: 19.5927395 },
    { lat: 49.2102392, lng: 19.5928496 },
    { lat: 49.2104014, lng: 19.5927231 },
    { lat: 49.210771, lng: 19.5931515 },
    { lat: 49.2109257, lng: 19.5933321 },
    { lat: 49.2124208, lng: 19.5952147 },
    { lat: 49.2133263, lng: 19.5959107 },
    { lat: 49.2141558, lng: 19.5977301 },
    { lat: 49.2146397, lng: 19.5997225 },
    { lat: 49.2149324, lng: 19.6005028 },
    { lat: 49.2152497, lng: 19.6013451 },
    { lat: 49.2163307, lng: 19.6021335 },
    { lat: 49.216492, lng: 19.6034199 },
    { lat: 49.216575, lng: 19.6042 },
    { lat: 49.216916, lng: 19.603147 },
    { lat: 49.21959, lng: 19.601983 },
    { lat: 49.220929, lng: 19.59927 },
    { lat: 49.221884, lng: 19.597731 },
    { lat: 49.222429, lng: 19.596857 },
    { lat: 49.222469, lng: 19.596821 },
    { lat: 49.222553, lng: 19.596754 },
    { lat: 49.22267, lng: 19.596678 },
    { lat: 49.222866, lng: 19.596579 },
    { lat: 49.222923, lng: 19.596567 },
    { lat: 49.223106, lng: 19.596112 },
    { lat: 49.223142, lng: 19.595651 },
    { lat: 49.223329, lng: 19.595443 },
    { lat: 49.223342, lng: 19.595267 },
    { lat: 49.223451, lng: 19.593837 },
    { lat: 49.223577, lng: 19.593136 },
    { lat: 49.223698, lng: 19.592637 },
    { lat: 49.223805, lng: 19.59159 },
    { lat: 49.223721, lng: 19.591093 },
    { lat: 49.2237, lng: 19.591003 },
    { lat: 49.223622, lng: 19.590668 },
    { lat: 49.22353, lng: 19.590288 },
    { lat: 49.223545, lng: 19.589342 },
    { lat: 49.223707, lng: 19.588568 },
    { lat: 49.22366, lng: 19.588536 },
    { lat: 49.223898, lng: 19.58813 },
    { lat: 49.224114, lng: 19.588075 },
    { lat: 49.224339, lng: 19.588254 },
    { lat: 49.224691, lng: 19.588183 },
    { lat: 49.224899, lng: 19.588054 },
    { lat: 49.225081, lng: 19.587785 },
    { lat: 49.225199, lng: 19.587262 },
    { lat: 49.225423, lng: 19.586478 },
    { lat: 49.225724, lng: 19.58529 },
    { lat: 49.226444, lng: 19.584681 },
    { lat: 49.227071, lng: 19.584228 },
    { lat: 49.227577, lng: 19.584321 },
    { lat: 49.227932, lng: 19.58437 },
    { lat: 49.228138, lng: 19.584758 },
    { lat: 49.228359, lng: 19.585249 },
    { lat: 49.228894, lng: 19.585387 },
    { lat: 49.229692, lng: 19.585804 },
    { lat: 49.230229, lng: 19.586694 },
    { lat: 49.231569, lng: 19.586153 },
    { lat: 49.232909, lng: 19.585311 },
    { lat: 49.234688, lng: 19.584927 },
    { lat: 49.235148, lng: 19.584737 },
    { lat: 49.235706, lng: 19.584304 },
    { lat: 49.235973, lng: 19.583399 },
    { lat: 49.23627, lng: 19.582423 },
    { lat: 49.236373, lng: 19.581936 },
    { lat: 49.236385, lng: 19.581181 },
    { lat: 49.237432, lng: 19.579226 },
    { lat: 49.237803, lng: 19.578582 },
    { lat: 49.238757, lng: 19.577286 },
    { lat: 49.239407, lng: 19.576558 },
    { lat: 49.240244, lng: 19.575417 },
    { lat: 49.241038, lng: 19.574506 },
    { lat: 49.24163, lng: 19.573165 },
    { lat: 49.241843, lng: 19.572182 },
    { lat: 49.241652, lng: 19.571932 },
    { lat: 49.241315, lng: 19.570856 },
    { lat: 49.241344, lng: 19.570739 },
    { lat: 49.240967, lng: 19.569512 },
    { lat: 49.240935, lng: 19.568773 },
    { lat: 49.240956, lng: 19.568337 },
    { lat: 49.240983, lng: 19.567789 },
    { lat: 49.241095, lng: 19.567766 },
    { lat: 49.241362, lng: 19.5669 },
    { lat: 49.241665, lng: 19.566245 },
    { lat: 49.24251, lng: 19.564667 },
    { lat: 49.242644, lng: 19.564197 },
    { lat: 49.242681, lng: 19.563728 },
    { lat: 49.242712, lng: 19.562555 },
    { lat: 49.242781, lng: 19.561599 },
    { lat: 49.242819, lng: 19.560734 },
    { lat: 49.242645, lng: 19.559606 },
    { lat: 49.242461, lng: 19.557881 },
  ],
  Hybe: [
    { lat: 49.132569, lng: 19.91928 },
    { lat: 49.132559, lng: 19.919207 },
    { lat: 49.132559, lng: 19.919206 },
    { lat: 49.132503, lng: 19.91917 },
    { lat: 49.132146, lng: 19.918972 },
    { lat: 49.131987, lng: 19.918717 },
    { lat: 49.131617, lng: 19.918128 },
    { lat: 49.131559, lng: 19.918036 },
    { lat: 49.131351, lng: 19.917705 },
    { lat: 49.131171, lng: 19.917602 },
    { lat: 49.130433, lng: 19.917179 },
    { lat: 49.130253, lng: 19.917025 },
    { lat: 49.129653, lng: 19.916513 },
    { lat: 49.129254, lng: 19.915386 },
    { lat: 49.129028, lng: 19.914748 },
    { lat: 49.128074, lng: 19.914706 },
    { lat: 49.126981, lng: 19.914411 },
    { lat: 49.126295, lng: 19.914554 },
    { lat: 49.126048, lng: 19.914605 },
    { lat: 49.12592, lng: 19.914632 },
    { lat: 49.125608, lng: 19.914697 },
    { lat: 49.125547, lng: 19.914717 },
    { lat: 49.125537, lng: 19.91472 },
    { lat: 49.124795, lng: 19.914961 },
    { lat: 49.124667, lng: 19.915003 },
    { lat: 49.124525, lng: 19.915049 },
    { lat: 49.124503, lng: 19.915057 },
    { lat: 49.124173, lng: 19.915164 },
    { lat: 49.122895, lng: 19.914376 },
    { lat: 49.122847, lng: 19.914347 },
    { lat: 49.12282, lng: 19.91433 },
    { lat: 49.122721, lng: 19.914269 },
    { lat: 49.122452, lng: 19.914098 },
    { lat: 49.122115, lng: 19.913884 },
    { lat: 49.121223, lng: 19.913318 },
    { lat: 49.121217, lng: 19.913315 },
    { lat: 49.121212, lng: 19.913312 },
    { lat: 49.1210649, lng: 19.9133623 },
    { lat: 49.1203891, lng: 19.9130427 },
    { lat: 49.1200014, lng: 19.9128282 },
    { lat: 49.1194947, lng: 19.9125093 },
    { lat: 49.1193813, lng: 19.9124693 },
    { lat: 49.1191441, lng: 19.912425 },
    { lat: 49.1188687, lng: 19.9124095 },
    { lat: 49.1183827, lng: 19.9123691 },
    { lat: 49.1181778, lng: 19.9123194 },
    { lat: 49.1176192, lng: 19.9121073 },
    { lat: 49.1172678, lng: 19.9119249 },
    { lat: 49.1171595, lng: 19.9110934 },
    { lat: 49.1170456, lng: 19.91085 },
    { lat: 49.1169908, lng: 19.9106475 },
    { lat: 49.1169594, lng: 19.9095798 },
    { lat: 49.1168727, lng: 19.9091535 },
    { lat: 49.1168648, lng: 19.9090216 },
    { lat: 49.1169112, lng: 19.9084676 },
    { lat: 49.1169838, lng: 19.908157 },
    { lat: 49.1168999, lng: 19.9075335 },
    { lat: 49.1168788, lng: 19.9063144 },
    { lat: 49.1169056, lng: 19.906178 },
    { lat: 49.1170682, lng: 19.9057586 },
    { lat: 49.1172271, lng: 19.9054431 },
    { lat: 49.1173626, lng: 19.9044887 },
    { lat: 49.117416, lng: 19.9042109 },
    { lat: 49.1175437, lng: 19.9032329 },
    { lat: 49.1175321, lng: 19.9030097 },
    { lat: 49.1172983, lng: 19.9020632 },
    { lat: 49.1171865, lng: 19.9010177 },
    { lat: 49.1171347, lng: 19.9004057 },
    { lat: 49.1171595, lng: 19.900012 },
    { lat: 49.1171982, lng: 19.8996713 },
    { lat: 49.1171974, lng: 19.899283 },
    { lat: 49.1169533, lng: 19.8984904 },
    { lat: 49.1168127, lng: 19.8980972 },
    { lat: 49.1162418, lng: 19.8971173 },
    { lat: 49.1159108, lng: 19.8960837 },
    { lat: 49.1156783, lng: 19.8949304 },
    { lat: 49.1153482, lng: 19.8943163 },
    { lat: 49.1151288, lng: 19.8940557 },
    { lat: 49.115013, lng: 19.8940099 },
    { lat: 49.114559, lng: 19.8940347 },
    { lat: 49.1143299, lng: 19.8939699 },
    { lat: 49.1137767, lng: 19.8941548 },
    { lat: 49.1136651, lng: 19.8940953 },
    { lat: 49.1134576, lng: 19.893799 },
    { lat: 49.1131922, lng: 19.8933839 },
    { lat: 49.1130039, lng: 19.8932198 },
    { lat: 49.1129416, lng: 19.8931576 },
    { lat: 49.1125243, lng: 19.8929351 },
    { lat: 49.1121291, lng: 19.8925393 },
    { lat: 49.1120916, lng: 19.8924782 },
    { lat: 49.1119038, lng: 19.8924068 },
    { lat: 49.1115025, lng: 19.8920778 },
    { lat: 49.1112458, lng: 19.8917356 },
    { lat: 49.1111186, lng: 19.8912634 },
    { lat: 49.1109159, lng: 19.8906786 },
    { lat: 49.1105988, lng: 19.8901033 },
    { lat: 49.1105642, lng: 19.8900607 },
    { lat: 49.1105509, lng: 19.8897758 },
    { lat: 49.1105825, lng: 19.8897187 },
    { lat: 49.1106702, lng: 19.8894282 },
    { lat: 49.1108118, lng: 19.8892036 },
    { lat: 49.1108443, lng: 19.8888861 },
    { lat: 49.1109057, lng: 19.8886575 },
    { lat: 49.1106661, lng: 19.888346 },
    { lat: 49.1105098, lng: 19.8880885 },
    { lat: 49.1102698, lng: 19.8879784 },
    { lat: 49.1101565, lng: 19.8878073 },
    { lat: 49.1100128, lng: 19.8875234 },
    { lat: 49.1098255, lng: 19.8871442 },
    { lat: 49.1095746, lng: 19.8868597 },
    { lat: 49.1090074, lng: 19.8864478 },
    { lat: 49.1088656, lng: 19.8862665 },
    { lat: 49.1085832, lng: 19.8855807 },
    { lat: 49.1083444, lng: 19.8853709 },
    { lat: 49.1082575, lng: 19.8851022 },
    { lat: 49.1081275, lng: 19.8847706 },
    { lat: 49.1080317, lng: 19.884372 },
    { lat: 49.1080095, lng: 19.8838759 },
    { lat: 49.1078566, lng: 19.8836166 },
    { lat: 49.1077249, lng: 19.8834894 },
    { lat: 49.1074645, lng: 19.8828704 },
    { lat: 49.1073552, lng: 19.8827147 },
    { lat: 49.1071349, lng: 19.8823014 },
    { lat: 49.107145, lng: 19.8820337 },
    { lat: 49.1072833, lng: 19.8818173 },
    { lat: 49.1072587, lng: 19.8815817 },
    { lat: 49.1071604, lng: 19.8812308 },
    { lat: 49.1068637, lng: 19.8807136 },
    { lat: 49.1067021, lng: 19.8796984 },
    { lat: 49.1064413, lng: 19.8791007 },
    { lat: 49.1063116, lng: 19.8786986 },
    { lat: 49.1060162, lng: 19.8782184 },
    { lat: 49.105924, lng: 19.8780599 },
    { lat: 49.1058553, lng: 19.8777421 },
    { lat: 49.1058211, lng: 19.8774424 },
    { lat: 49.105817, lng: 19.8772627 },
    { lat: 49.1058658, lng: 19.8768519 },
    { lat: 49.1057691, lng: 19.8765361 },
    { lat: 49.1057473, lng: 19.8763416 },
    { lat: 49.1057557, lng: 19.8758231 },
    { lat: 49.1056091, lng: 19.8750786 },
    { lat: 49.1053747, lng: 19.8744956 },
    { lat: 49.1053526, lng: 19.8743698 },
    { lat: 49.1051169, lng: 19.8732312 },
    { lat: 49.1049923, lng: 19.8729487 },
    { lat: 49.1049602, lng: 19.8729101 },
    { lat: 49.1048307, lng: 19.872645 },
    { lat: 49.104753, lng: 19.8720908 },
    { lat: 49.1045831, lng: 19.8716417 },
    { lat: 49.1044438, lng: 19.8712298 },
    { lat: 49.104312, lng: 19.8706478 },
    { lat: 49.1041243, lng: 19.8700469 },
    { lat: 49.1038488, lng: 19.8695579 },
    { lat: 49.1035602, lng: 19.8690511 },
    { lat: 49.1034708, lng: 19.8689852 },
    { lat: 49.1032878, lng: 19.8685514 },
    { lat: 49.1030121, lng: 19.8680311 },
    { lat: 49.1027127, lng: 19.8677695 },
    { lat: 49.1022405, lng: 19.8670243 },
    { lat: 49.1019522, lng: 19.866474 },
    { lat: 49.1017061, lng: 19.8658214 },
    { lat: 49.1016002, lng: 19.865453 },
    { lat: 49.1014974, lng: 19.86492 },
    { lat: 49.1013647, lng: 19.8645301 },
    { lat: 49.1011023, lng: 19.8642886 },
    { lat: 49.1008571, lng: 19.864007 },
    { lat: 49.1006098, lng: 19.8638384 },
    { lat: 49.100166, lng: 19.863677 },
    { lat: 49.1000729, lng: 19.8637686 },
    { lat: 49.0997045, lng: 19.8637141 },
    { lat: 49.0993038, lng: 19.8629036 },
    { lat: 49.0991274, lng: 19.8627294 },
    { lat: 49.0983907, lng: 19.8621984 },
    { lat: 49.0980842, lng: 19.8621691 },
    { lat: 49.0974293, lng: 19.8622361 },
    { lat: 49.0966717, lng: 19.8623545 },
    { lat: 49.096586, lng: 19.8623767 },
    { lat: 49.0961256, lng: 19.862231 },
    { lat: 49.0959984, lng: 19.8622033 },
    { lat: 49.0955127, lng: 19.8617255 },
    { lat: 49.095343, lng: 19.8613933 },
    { lat: 49.0952004, lng: 19.8610322 },
    { lat: 49.0949769, lng: 19.8606524 },
    { lat: 49.0944024, lng: 19.8601504 },
    { lat: 49.0940818, lng: 19.8597889 },
    { lat: 49.0935765, lng: 19.8585622 },
    { lat: 49.0932826, lng: 19.8580587 },
    { lat: 49.093135, lng: 19.8577207 },
    { lat: 49.0908049, lng: 19.8544102 },
    { lat: 49.0903977, lng: 19.8541041 },
    { lat: 49.090134, lng: 19.8538103 },
    { lat: 49.0886396, lng: 19.8529273 },
    { lat: 49.0875804, lng: 19.8511836 },
    { lat: 49.0859207, lng: 19.8491391 },
    { lat: 49.0854178, lng: 19.8487117 },
    { lat: 49.0841775, lng: 19.8464705 },
    { lat: 49.0841198, lng: 19.8463587 },
    { lat: 49.0838457, lng: 19.8457448 },
    { lat: 49.0837089, lng: 19.8454166 },
    { lat: 49.0834385, lng: 19.8447858 },
    { lat: 49.0833167, lng: 19.8444662 },
    { lat: 49.0830419, lng: 19.8438912 },
    { lat: 49.0828345, lng: 19.8433864 },
    { lat: 49.0826016, lng: 19.8429478 },
    { lat: 49.0823624, lng: 19.8425942 },
    { lat: 49.0820348, lng: 19.8423353 },
    { lat: 49.0820043, lng: 19.8422575 },
    { lat: 49.0815972, lng: 19.8419278 },
    { lat: 49.0813245, lng: 19.8415366 },
    { lat: 49.08135, lng: 19.8413447 },
    { lat: 49.081268, lng: 19.8411387 },
    { lat: 49.0811335, lng: 19.8411013 },
    { lat: 49.0810157, lng: 19.840901 },
    { lat: 49.0808381, lng: 19.8406743 },
    { lat: 49.0808087, lng: 19.8404354 },
    { lat: 49.0806938, lng: 19.8401989 },
    { lat: 49.0804557, lng: 19.8395301 },
    { lat: 49.0803022, lng: 19.8393887 },
    { lat: 49.0801908, lng: 19.8392632 },
    { lat: 49.0801016, lng: 19.8390992 },
    { lat: 49.0800783, lng: 19.8390289 },
    { lat: 49.0800774, lng: 19.8388537 },
    { lat: 49.0799108, lng: 19.8387222 },
    { lat: 49.0798178, lng: 19.8384475 },
    { lat: 49.0796543, lng: 19.8382087 },
    { lat: 49.0796408, lng: 19.8379693 },
    { lat: 49.0795241, lng: 19.8377193 },
    { lat: 49.0795001, lng: 19.8375021 },
    { lat: 49.0795114, lng: 19.8371042 },
    { lat: 49.0795851, lng: 19.8367275 },
    { lat: 49.0794949, lng: 19.8365108 },
    { lat: 49.0793914, lng: 19.8363594 },
    { lat: 49.0793178, lng: 19.836218 },
    { lat: 49.0793938, lng: 19.8359441 },
    { lat: 49.079362, lng: 19.8358007 },
    { lat: 49.0792746, lng: 19.8356574 },
    { lat: 49.0792154, lng: 19.8354326 },
    { lat: 49.079155, lng: 19.8351518 },
    { lat: 49.0790398, lng: 19.8347008 },
    { lat: 49.0789009, lng: 19.8345077 },
    { lat: 49.0788365, lng: 19.8342431 },
    { lat: 49.0789003, lng: 19.8341301 },
    { lat: 49.0788295, lng: 19.8339761 },
    { lat: 49.0787204, lng: 19.8336129 },
    { lat: 49.0787597, lng: 19.8334505 },
    { lat: 49.0786722, lng: 19.8333047 },
    { lat: 49.0786986, lng: 19.8331814 },
    { lat: 49.0785952, lng: 19.833137 },
    { lat: 49.0785764, lng: 19.8327767 },
    { lat: 49.0785521, lng: 19.832524 },
    { lat: 49.0764424, lng: 19.8311921 },
    { lat: 49.0751989, lng: 19.8297787 },
    { lat: 49.07517, lng: 19.8297618 },
    { lat: 49.0750423, lng: 19.8293815 },
    { lat: 49.0748669, lng: 19.8288927 },
    { lat: 49.0745584, lng: 19.8283212 },
    { lat: 49.0743773, lng: 19.8278039 },
    { lat: 49.0741861, lng: 19.8270752 },
    { lat: 49.0740875, lng: 19.8267473 },
    { lat: 49.0739828, lng: 19.8264857 },
    { lat: 49.0739506, lng: 19.8261821 },
    { lat: 49.0739069, lng: 19.8259277 },
    { lat: 49.0736507, lng: 19.8250259 },
    { lat: 49.0734439, lng: 19.8245661 },
    { lat: 49.0733789, lng: 19.8239715 },
    { lat: 49.0733732, lng: 19.8229753 },
    { lat: 49.0735172, lng: 19.8218464 },
    { lat: 49.073753, lng: 19.8207831 },
    { lat: 49.0738566, lng: 19.8199155 },
    { lat: 49.0740857, lng: 19.8189591 },
    { lat: 49.0744036, lng: 19.8179918 },
    { lat: 49.0743704, lng: 19.8171187 },
    { lat: 49.0735397, lng: 19.8183385 },
    { lat: 49.0728365, lng: 19.817177 },
    { lat: 49.0724305, lng: 19.8170404 },
    { lat: 49.0722781, lng: 19.8169783 },
    { lat: 49.0717576, lng: 19.8168456 },
    { lat: 49.0715475, lng: 19.8168069 },
    { lat: 49.0714425, lng: 19.816775 },
    { lat: 49.0711783, lng: 19.8168132 },
    { lat: 49.0710221, lng: 19.8168741 },
    { lat: 49.0707128, lng: 19.8169111 },
    { lat: 49.0704099, lng: 19.8169934 },
    { lat: 49.0702525, lng: 19.817053 },
    { lat: 49.0696714, lng: 19.8166669 },
    { lat: 49.0690451, lng: 19.8156458 },
    { lat: 49.0675639, lng: 19.8151262 },
    { lat: 49.0664897, lng: 19.8147197 },
    { lat: 49.0655252, lng: 19.8143249 },
    { lat: 49.0644767, lng: 19.8141193 },
    { lat: 49.0639082, lng: 19.8136081 },
    { lat: 49.0626701, lng: 19.8137332 },
    { lat: 49.0621589, lng: 19.8137695 },
    { lat: 49.0617867, lng: 19.8138354 },
    { lat: 49.0617885, lng: 19.813851 },
    { lat: 49.0617239, lng: 19.8138417 },
    { lat: 49.0616565, lng: 19.8137668 },
    { lat: 49.0616552, lng: 19.8136323 },
    { lat: 49.0615608, lng: 19.8135831 },
    { lat: 49.0614922, lng: 19.8135871 },
    { lat: 49.0612642, lng: 19.8132359 },
    { lat: 49.0611395, lng: 19.8132189 },
    { lat: 49.0610487, lng: 19.8131501 },
    { lat: 49.0609539, lng: 19.8131452 },
    { lat: 49.0608582, lng: 19.8131224 },
    { lat: 49.0607919, lng: 19.8128934 },
    { lat: 49.0607106, lng: 19.8128853 },
    { lat: 49.0606348, lng: 19.812644 },
    { lat: 49.0604636, lng: 19.8124628 },
    { lat: 49.0604431, lng: 19.8122981 },
    { lat: 49.0602908, lng: 19.8122149 },
    { lat: 49.0601167, lng: 19.8121522 },
    { lat: 49.0600783, lng: 19.8118094 },
    { lat: 49.059944, lng: 19.8115911 },
    { lat: 49.0597386, lng: 19.811191 },
    { lat: 49.0596743, lng: 19.8109536 },
    { lat: 49.0595836, lng: 19.81076 },
    { lat: 49.0595586, lng: 19.810598 },
    { lat: 49.059246, lng: 19.8105362 },
    { lat: 49.0591026, lng: 19.8102912 },
    { lat: 49.0591386, lng: 19.8100807 },
    { lat: 49.058752, lng: 19.8096552 },
    { lat: 49.0584558, lng: 19.8096073 },
    { lat: 49.0584336, lng: 19.8095642 },
    { lat: 49.0583218, lng: 19.8094231 },
    { lat: 49.0582571, lng: 19.809177 },
    { lat: 49.0582495, lng: 19.8091602 },
    { lat: 49.0581356, lng: 19.8090257 },
    { lat: 49.0577364, lng: 19.8086544 },
    { lat: 49.0576605, lng: 19.8087438 },
    { lat: 49.0575242, lng: 19.8086925 },
    { lat: 49.0575141, lng: 19.8083551 },
    { lat: 49.0574205, lng: 19.8083788 },
    { lat: 49.0573338, lng: 19.8081964 },
    { lat: 49.0570531, lng: 19.8079073 },
    { lat: 49.0568746, lng: 19.8074234 },
    { lat: 49.0566669, lng: 19.8073385 },
    { lat: 49.0568006, lng: 19.8071208 },
    { lat: 49.056561, lng: 19.8069957 },
    { lat: 49.0564934, lng: 19.806659 },
    { lat: 49.0562687, lng: 19.8065407 },
    { lat: 49.0561407, lng: 19.8067356 },
    { lat: 49.0559329, lng: 19.8066411 },
    { lat: 49.0555576, lng: 19.8066409 },
    { lat: 49.0552802, lng: 19.8062416 },
    { lat: 49.0552865, lng: 19.8064144 },
    { lat: 49.0552416, lng: 19.8064688 },
    { lat: 49.0549933, lng: 19.8060918 },
    { lat: 49.0548227, lng: 19.806084 },
    { lat: 49.0546242, lng: 19.8060504 },
    { lat: 49.0543619, lng: 19.8059523 },
    { lat: 49.0538196, lng: 19.8056539 },
    { lat: 49.0535809, lng: 19.8053706 },
    { lat: 49.0536113, lng: 19.8051058 },
    { lat: 49.0533992, lng: 19.8050972 },
    { lat: 49.0531133, lng: 19.8044243 },
    { lat: 49.0530319, lng: 19.8039501 },
    { lat: 49.0527347, lng: 19.8037762 },
    { lat: 49.0526815, lng: 19.8033254 },
    { lat: 49.0524945, lng: 19.8030363 },
    { lat: 49.0522318, lng: 19.8026442 },
    { lat: 49.0522325, lng: 19.8024738 },
    { lat: 49.0520419, lng: 19.8023133 },
    { lat: 49.0521368, lng: 19.802269 },
    { lat: 49.0520619, lng: 19.801996 },
    { lat: 49.0517986, lng: 19.8020518 },
    { lat: 49.0517591, lng: 19.8020795 },
    { lat: 49.051728, lng: 19.8020632 },
    { lat: 49.0517257, lng: 19.8019327 },
    { lat: 49.0517322, lng: 19.8019252 },
    { lat: 49.0517627, lng: 19.8019811 },
    { lat: 49.0517879, lng: 19.8019638 },
    { lat: 49.0517649, lng: 19.8017154 },
    { lat: 49.0516455, lng: 19.8016667 },
    { lat: 49.0515775, lng: 19.8013459 },
    { lat: 49.0513357, lng: 19.800988 },
    { lat: 49.0512592, lng: 19.8009673 },
    { lat: 49.0512522, lng: 19.8008261 },
    { lat: 49.0512978, lng: 19.8008165 },
    { lat: 49.0512483, lng: 19.8007152 },
    { lat: 49.0512382, lng: 19.8006109 },
    { lat: 49.0513083, lng: 19.8003816 },
    { lat: 49.0512714, lng: 19.8003102 },
    { lat: 49.0511471, lng: 19.8003281 },
    { lat: 49.0510844, lng: 19.8001278 },
    { lat: 49.0511295, lng: 19.8000458 },
    { lat: 49.0511104, lng: 19.8000243 },
    { lat: 49.0504958, lng: 19.7998248 },
    { lat: 49.0504838, lng: 19.7995763 },
    { lat: 49.0503892, lng: 19.7996268 },
    { lat: 49.0502126, lng: 19.7996861 },
    { lat: 49.0501933, lng: 19.7995508 },
    { lat: 49.0500977, lng: 19.7994803 },
    { lat: 49.0500977, lng: 19.7992454 },
    { lat: 49.0499779, lng: 19.7993666 },
    { lat: 49.0497494, lng: 19.7992642 },
    { lat: 49.0497206, lng: 19.7991431 },
    { lat: 49.0496737, lng: 19.7991848 },
    { lat: 49.0496223, lng: 19.7993972 },
    { lat: 49.0495052, lng: 19.7994025 },
    { lat: 49.0495104, lng: 19.7992888 },
    { lat: 49.0491728, lng: 19.7989283 },
    { lat: 49.0491517, lng: 19.7991214 },
    { lat: 49.0490006, lng: 19.7989847 },
    { lat: 49.0487506, lng: 19.7987008 },
    { lat: 49.0486467, lng: 19.7981998 },
    { lat: 49.0485481, lng: 19.7981896 },
    { lat: 49.0485182, lng: 19.7983538 },
    { lat: 49.0483996, lng: 19.7982436 },
    { lat: 49.0482933, lng: 19.7981828 },
    { lat: 49.0483135, lng: 19.7981028 },
    { lat: 49.0483178, lng: 19.797967 },
    { lat: 49.0482449, lng: 19.7977678 },
    { lat: 49.0483907, lng: 19.7977274 },
    { lat: 49.0484517, lng: 19.7976257 },
    { lat: 49.0483084, lng: 19.7974619 },
    { lat: 49.0482714, lng: 19.7974696 },
    { lat: 49.0483012, lng: 19.7975851 },
    { lat: 49.0482686, lng: 19.7976103 },
    { lat: 49.0482551, lng: 19.7975334 },
    { lat: 49.0481558, lng: 19.7974528 },
    { lat: 49.0480577, lng: 19.7974231 },
    { lat: 49.0480202, lng: 19.7974671 },
    { lat: 49.0479003, lng: 19.7969598 },
    { lat: 49.0477571, lng: 19.7969584 },
    { lat: 49.0477245, lng: 19.7967551 },
    { lat: 49.0476651, lng: 19.7965772 },
    { lat: 49.0475958, lng: 19.7965138 },
    { lat: 49.0475655, lng: 19.7963326 },
    { lat: 49.0473456, lng: 19.796198 },
    { lat: 49.0472925, lng: 19.7962482 },
    { lat: 49.0472186, lng: 19.7962036 },
    { lat: 49.0471461, lng: 19.796246 },
    { lat: 49.0470126, lng: 19.7961287 },
    { lat: 49.0469632, lng: 19.7958219 },
    { lat: 49.0469572, lng: 19.7958157 },
    { lat: 49.0468517, lng: 19.7958754 },
    { lat: 49.0466473, lng: 19.795707 },
    { lat: 49.0465495, lng: 19.7958404 },
    { lat: 49.046524, lng: 19.7956939 },
    { lat: 49.0464239, lng: 19.7955692 },
    { lat: 49.0464237, lng: 19.7953563 },
    { lat: 49.0463968, lng: 19.7953299 },
    { lat: 49.0462106, lng: 19.7953744 },
    { lat: 49.0461567, lng: 19.7951496 },
    { lat: 49.0460984, lng: 19.7951537 },
    { lat: 49.0459056, lng: 19.7950962 },
    { lat: 49.0459357, lng: 19.7950116 },
    { lat: 49.0457852, lng: 19.7948669 },
    { lat: 49.0458095, lng: 19.794779 },
    { lat: 49.0456888, lng: 19.7946201 },
    { lat: 49.0455349, lng: 19.7945767 },
    { lat: 49.045571, lng: 19.7944186 },
    { lat: 49.0454939, lng: 19.7942299 },
    { lat: 49.0454276, lng: 19.7942578 },
    { lat: 49.0454008, lng: 19.7939006 },
    { lat: 49.045319, lng: 19.7938539 },
    { lat: 49.0452925, lng: 19.7936619 },
    { lat: 49.0453358, lng: 19.7935161 },
    { lat: 49.0452772, lng: 19.7934128 },
    { lat: 49.0451278, lng: 19.7928729 },
    { lat: 49.0449828, lng: 19.7926217 },
    { lat: 49.0447988, lng: 19.7923295 },
    { lat: 49.0448106, lng: 19.7921061 },
    { lat: 49.0448648, lng: 19.7919229 },
    { lat: 49.0448377, lng: 19.7917662 },
    { lat: 49.0447588, lng: 19.7917435 },
    { lat: 49.0445755, lng: 19.7913877 },
    { lat: 49.044405, lng: 19.791402 },
    { lat: 49.0443151, lng: 19.7910713 },
    { lat: 49.0440673, lng: 19.7909676 },
    { lat: 49.0439527, lng: 19.7909715 },
    { lat: 49.0438818, lng: 19.7910016 },
    { lat: 49.0438039, lng: 19.7905835 },
    { lat: 49.0436676, lng: 19.7905315 },
    { lat: 49.0436582, lng: 19.7903374 },
    { lat: 49.0435515, lng: 19.7900583 },
    { lat: 49.0434808, lng: 19.7900949 },
    { lat: 49.0434641, lng: 19.7897579 },
    { lat: 49.0433746, lng: 19.7896979 },
    { lat: 49.0435397, lng: 19.789319 },
    { lat: 49.0435142, lng: 19.7893008 },
    { lat: 49.0432857, lng: 19.7893079 },
    { lat: 49.0430718, lng: 19.7894418 },
    { lat: 49.0428763, lng: 19.789452 },
    { lat: 49.0426587, lng: 19.7891611 },
    { lat: 49.0425965, lng: 19.7887432 },
    { lat: 49.0424409, lng: 19.7888131 },
    { lat: 49.0424189, lng: 19.7889043 },
    { lat: 49.0423393, lng: 19.7889138 },
    { lat: 49.0422927, lng: 19.7888524 },
    { lat: 49.0422952, lng: 19.7888349 },
    { lat: 49.0423644, lng: 19.7888182 },
    { lat: 49.0421805, lng: 19.7884758 },
    { lat: 49.0420122, lng: 19.7881551 },
    { lat: 49.0418051, lng: 19.7878966 },
    { lat: 49.0418969, lng: 19.7876241 },
    { lat: 49.041798, lng: 19.7873676 },
    { lat: 49.0415768, lng: 19.7875923 },
    { lat: 49.0415458, lng: 19.7873717 },
    { lat: 49.0414139, lng: 19.787553 },
    { lat: 49.0412685, lng: 19.7874229 },
    { lat: 49.0412665, lng: 19.7873484 },
    { lat: 49.0412945, lng: 19.7871345 },
    { lat: 49.0412857, lng: 19.7870897 },
    { lat: 49.041229, lng: 19.7870832 },
    { lat: 49.0411095, lng: 19.7871584 },
    { lat: 49.041059, lng: 19.7871585 },
    { lat: 49.0409271, lng: 19.7870026 },
    { lat: 49.0407703, lng: 19.7868438 },
    { lat: 49.0407298, lng: 19.7867146 },
    { lat: 49.0406695, lng: 19.7866754 },
    { lat: 49.0405134, lng: 19.7867658 },
    { lat: 49.0404401, lng: 19.7867907 },
    { lat: 49.0402124, lng: 19.7864983 },
    { lat: 49.0401095, lng: 19.7863586 },
    { lat: 49.0399873, lng: 19.7863755 },
    { lat: 49.0398393, lng: 19.7862341 },
    { lat: 49.0397079, lng: 19.7864265 },
    { lat: 49.0396542, lng: 19.7864358 },
    { lat: 49.0394914, lng: 19.7862637 },
    { lat: 49.039229, lng: 19.7858997 },
    { lat: 49.0391504, lng: 19.7858853 },
    { lat: 49.0390579, lng: 19.7859072 },
    { lat: 49.03901, lng: 19.7858919 },
    { lat: 49.0388946, lng: 19.7857239 },
    { lat: 49.0388133, lng: 19.7857486 },
    { lat: 49.0387396, lng: 19.7856339 },
    { lat: 49.0385982, lng: 19.7856165 },
    { lat: 49.0385031, lng: 19.785793 },
    { lat: 49.0384264, lng: 19.7857878 },
    { lat: 49.0383615, lng: 19.7856672 },
    { lat: 49.0383604, lng: 19.7855384 },
    { lat: 49.0383975, lng: 19.7853821 },
    { lat: 49.0384036, lng: 19.7852616 },
    { lat: 49.0383443, lng: 19.7850926 },
    { lat: 49.0382877, lng: 19.7850064 },
    { lat: 49.0381746, lng: 19.7850713 },
    { lat: 49.0381274, lng: 19.7850218 },
    { lat: 49.0380502, lng: 19.7849085 },
    { lat: 49.0379652, lng: 19.7848966 },
    { lat: 49.0378521, lng: 19.7846789 },
    { lat: 49.0377732, lng: 19.7846225 },
    { lat: 49.0377156, lng: 19.784698 },
    { lat: 49.0376736, lng: 19.7847162 },
    { lat: 49.0374855, lng: 19.7846406 },
    { lat: 49.03742, lng: 19.7845564 },
    { lat: 49.0373228, lng: 19.7842911 },
    { lat: 49.0372009, lng: 19.7838969 },
    { lat: 49.0371034, lng: 19.7835713 },
    { lat: 49.0370539, lng: 19.7833655 },
    { lat: 49.036969, lng: 19.783122 },
    { lat: 49.0368783, lng: 19.7827185 },
    { lat: 49.0368433, lng: 19.7824847 },
    { lat: 49.0368523, lng: 19.7821467 },
    { lat: 49.0368239, lng: 19.781961 },
    { lat: 49.0367682, lng: 19.781712 },
    { lat: 49.0367113, lng: 19.7816449 },
    { lat: 49.0365882, lng: 19.7816163 },
    { lat: 49.0364823, lng: 19.7815357 },
    { lat: 49.0363772, lng: 19.7813725 },
    { lat: 49.0362658, lng: 19.7812214 },
    { lat: 49.0361775, lng: 19.7810791 },
    { lat: 49.0360752, lng: 19.7807512 },
    { lat: 49.0360079, lng: 19.7806804 },
    { lat: 49.0359805, lng: 19.780592 },
    { lat: 49.0359205, lng: 19.780482 },
    { lat: 49.0358396, lng: 19.7804345 },
    { lat: 49.0357769, lng: 19.7804433 },
    { lat: 49.0356457, lng: 19.7805369 },
    { lat: 49.0355862, lng: 19.7806463 },
    { lat: 49.0355654, lng: 19.780759 },
    { lat: 49.0355293, lng: 19.780787 },
    { lat: 49.0354877, lng: 19.7807956 },
    { lat: 49.0354545, lng: 19.7808024 },
    { lat: 49.035096, lng: 19.7810553 },
    { lat: 49.0349849, lng: 19.7811181 },
    { lat: 49.0348195, lng: 19.7812493 },
    { lat: 49.0347408, lng: 19.7812041 },
    { lat: 49.0346734, lng: 19.7811308 },
    { lat: 49.0345034, lng: 19.7809224 },
    { lat: 49.0344847, lng: 19.7809329 },
    { lat: 49.0343953, lng: 19.7807997 },
    { lat: 49.0342367, lng: 19.7806738 },
    { lat: 49.0341584, lng: 19.7812586 },
    { lat: 49.0340357, lng: 19.7817747 },
    { lat: 49.0338525, lng: 19.782095 },
    { lat: 49.033829, lng: 19.7820776 },
    { lat: 49.0336063, lng: 19.7821897 },
    { lat: 49.0334405, lng: 19.7822366 },
    { lat: 49.0329144, lng: 19.7823623 },
    { lat: 49.0326192, lng: 19.7824138 },
    { lat: 49.032484, lng: 19.7824655 },
    { lat: 49.0323689, lng: 19.7825384 },
    { lat: 49.0321127, lng: 19.7827372 },
    { lat: 49.0318924, lng: 19.783035 },
    { lat: 49.0317465, lng: 19.7832805 },
    { lat: 49.0313847, lng: 19.7839997 },
    { lat: 49.0312215, lng: 19.7843406 },
    { lat: 49.031166, lng: 19.7844647 },
    { lat: 49.031155, lng: 19.7845491 },
    { lat: 49.031056, lng: 19.7847364 },
    { lat: 49.0308701, lng: 19.7850182 },
    { lat: 49.0308444, lng: 19.7850243 },
    { lat: 49.0306326, lng: 19.7852847 },
    { lat: 49.0305622, lng: 19.7853474 },
    { lat: 49.0302307, lng: 19.7855756 },
    { lat: 49.0299748, lng: 19.7856498 },
    { lat: 49.029925, lng: 19.7856458 },
    { lat: 49.0297746, lng: 19.785606 },
    { lat: 49.0295399, lng: 19.7854633 },
    { lat: 49.0292228, lng: 19.7851961 },
    { lat: 49.0291686, lng: 19.7851383 },
    { lat: 49.0290566, lng: 19.785049 },
    { lat: 49.0288848, lng: 19.784937 },
    { lat: 49.0286988, lng: 19.7848268 },
    { lat: 49.0285197, lng: 19.7846452 },
    { lat: 49.0285096, lng: 19.7845967 },
    { lat: 49.0283303, lng: 19.7844844 },
    { lat: 49.028259, lng: 19.7843494 },
    { lat: 49.0280916, lng: 19.7841529 },
    { lat: 49.0278885, lng: 19.784015 },
    { lat: 49.0277132, lng: 19.7841313 },
    { lat: 49.0272572, lng: 19.7847579 },
    { lat: 49.0270801, lng: 19.785013 },
    { lat: 49.0268658, lng: 19.785265 },
    { lat: 49.0267293, lng: 19.7854158 },
    { lat: 49.0264784, lng: 19.7857856 },
    { lat: 49.0263814, lng: 19.7859396 },
    { lat: 49.0262404, lng: 19.7862481 },
    { lat: 49.026165, lng: 19.786643 },
    { lat: 49.0260526, lng: 19.7869027 },
    { lat: 49.0257971, lng: 19.7876133 },
    { lat: 49.0256166, lng: 19.7884649 },
    { lat: 49.0254177, lng: 19.7890229 },
    { lat: 49.025324, lng: 19.7892739 },
    { lat: 49.0252519, lng: 19.7894833 },
    { lat: 49.025241, lng: 19.78975 },
    { lat: 49.025246, lng: 19.7898428 },
    { lat: 49.0251225, lng: 19.7901355 },
    { lat: 49.0249394, lng: 19.7905886 },
    { lat: 49.0247789, lng: 19.7909655 },
    { lat: 49.0246965, lng: 19.7912498 },
    { lat: 49.0246111, lng: 19.7914084 },
    { lat: 49.0242884, lng: 19.7918155 },
    { lat: 49.0239506, lng: 19.79234 },
    { lat: 49.0236971, lng: 19.7926989 },
    { lat: 49.0235019, lng: 19.7930322 },
    { lat: 49.0231815, lng: 19.7936206 },
    { lat: 49.0230233, lng: 19.7940012 },
    { lat: 49.0229689, lng: 19.7941459 },
    { lat: 49.0229178, lng: 19.7943714 },
    { lat: 49.0227614, lng: 19.7951531 },
    { lat: 49.0227204, lng: 19.7953288 },
    { lat: 49.0223929, lng: 19.7972363 },
    { lat: 49.0223205, lng: 19.7976183 },
    { lat: 49.0221121, lng: 19.7982967 },
    { lat: 49.0218145, lng: 19.7992799 },
    { lat: 49.0214942, lng: 19.8001314 },
    { lat: 49.0212001, lng: 19.8009336 },
    { lat: 49.0210028, lng: 19.8015283 },
    { lat: 49.020659, lng: 19.8026675 },
    { lat: 49.0205637, lng: 19.8030464 },
    { lat: 49.0204537, lng: 19.8031816 },
    { lat: 49.020164, lng: 19.8042729 },
    { lat: 49.0200347, lng: 19.8049287 },
    { lat: 49.0199575, lng: 19.8055419 },
    { lat: 49.0199362, lng: 19.8064309 },
    { lat: 49.0198668, lng: 19.8066489 },
    { lat: 49.0192007, lng: 19.8079717 },
    { lat: 49.019094, lng: 19.8081902 },
    { lat: 49.0189428, lng: 19.8086259 },
    { lat: 49.0186391, lng: 19.8092118 },
    { lat: 49.0185678, lng: 19.8092017 },
    { lat: 49.0184812, lng: 19.8092075 },
    { lat: 49.0184469, lng: 19.8092217 },
    { lat: 49.0178234, lng: 19.8096253 },
    { lat: 49.0174386, lng: 19.8096906 },
    { lat: 49.0171986, lng: 19.8096866 },
    { lat: 49.0168478, lng: 19.8096254 },
    { lat: 49.0162813, lng: 19.8094957 },
    { lat: 49.0159758, lng: 19.8094688 },
    { lat: 49.0158432, lng: 19.8096059 },
    { lat: 49.0158206, lng: 19.8097614 },
    { lat: 49.0154867, lng: 19.8103485 },
    { lat: 49.0151735, lng: 19.8109999 },
    { lat: 49.0149962, lng: 19.8114088 },
    { lat: 49.0149624, lng: 19.8115119 },
    { lat: 49.0148335, lng: 19.8118675 },
    { lat: 49.0148131, lng: 19.811913 },
    { lat: 49.0146512, lng: 19.8121818 },
    { lat: 49.0144745, lng: 19.8126064 },
    { lat: 49.0142981, lng: 19.8131903 },
    { lat: 49.0142372, lng: 19.8134734 },
    { lat: 49.0141227, lng: 19.8146605 },
    { lat: 49.0141062, lng: 19.8151912 },
    { lat: 49.0141366, lng: 19.8157645 },
    { lat: 49.0142411, lng: 19.8163322 },
    { lat: 49.0142393, lng: 19.8167347 },
    { lat: 49.0141759, lng: 19.8169338 },
    { lat: 49.013886, lng: 19.8173721 },
    { lat: 49.0137067, lng: 19.8179416 },
    { lat: 49.0134337, lng: 19.8188477 },
    { lat: 49.0132599, lng: 19.8200407 },
    { lat: 49.013222, lng: 19.8206011 },
    { lat: 49.0131719, lng: 19.8216568 },
    { lat: 49.0131525, lng: 19.8220948 },
    { lat: 49.012834, lng: 19.8230969 },
    { lat: 49.0126967, lng: 19.8237558 },
    { lat: 49.0124949, lng: 19.8244035 },
    { lat: 49.0123167, lng: 19.8249247 },
    { lat: 49.0122987, lng: 19.8250557 },
    { lat: 49.0123565, lng: 19.8254548 },
    { lat: 49.0126889, lng: 19.8263712 },
    { lat: 49.0127786, lng: 19.826677 },
    { lat: 49.0127708, lng: 19.8271476 },
    { lat: 49.0127278, lng: 19.8273568 },
    { lat: 49.01252, lng: 19.8284189 },
    { lat: 49.0124999, lng: 19.8289437 },
    { lat: 49.0124352, lng: 19.8301351 },
    { lat: 49.0124072, lng: 19.830927 },
    { lat: 49.01228, lng: 19.8319488 },
    { lat: 49.0122663, lng: 19.8330953 },
    { lat: 49.0122871, lng: 19.8341558 },
    { lat: 49.0121082, lng: 19.8341341 },
    { lat: 49.0119918, lng: 19.8344148 },
    { lat: 49.0117551, lng: 19.8347293 },
    { lat: 49.0116583, lng: 19.835124 },
    { lat: 49.0117531, lng: 19.8357591 },
    { lat: 49.0118913, lng: 19.8360902 },
    { lat: 49.0120216, lng: 19.8363421 },
    { lat: 49.0121507, lng: 19.8364359 },
    { lat: 49.0122936, lng: 19.8366148 },
    { lat: 49.0123676, lng: 19.8368199 },
    { lat: 49.012498, lng: 19.8368105 },
    { lat: 49.0125943, lng: 19.8367712 },
    { lat: 49.012726, lng: 19.8367448 },
    { lat: 49.0127795, lng: 19.8369893 },
    { lat: 49.0131067, lng: 19.8380775 },
    { lat: 49.0133464, lng: 19.8397795 },
    { lat: 49.0135577, lng: 19.8406313 },
    { lat: 49.0136837, lng: 19.8412565 },
    { lat: 49.0138809, lng: 19.8417881 },
    { lat: 49.0137689, lng: 19.8418789 },
    { lat: 49.0135872, lng: 19.8421341 },
    { lat: 49.0134383, lng: 19.8422067 },
    { lat: 49.0131004, lng: 19.8423131 },
    { lat: 49.0131006, lng: 19.8426105 },
    { lat: 49.0130001, lng: 19.8426815 },
    { lat: 49.0129264, lng: 19.8426156 },
    { lat: 49.0125066, lng: 19.8425474 },
    { lat: 49.0124495, lng: 19.8427135 },
    { lat: 49.0125105, lng: 19.8429242 },
    { lat: 49.0126983, lng: 19.843207 },
    { lat: 49.0130879, lng: 19.8434903 },
    { lat: 49.0133755, lng: 19.8434991 },
    { lat: 49.0136119, lng: 19.8435492 },
    { lat: 49.0137502, lng: 19.8435165 },
    { lat: 49.0138837, lng: 19.8442127 },
    { lat: 49.0139788, lng: 19.8444599 },
    { lat: 49.0140659, lng: 19.8447071 },
    { lat: 49.0141776, lng: 19.8449997 },
    { lat: 49.0144066, lng: 19.8458713 },
    { lat: 49.0145026, lng: 19.8463746 },
    { lat: 49.0145671, lng: 19.8469872 },
    { lat: 49.0146388, lng: 19.8480822 },
    { lat: 49.0145242, lng: 19.8481671 },
    { lat: 49.0144187, lng: 19.8482067 },
    { lat: 49.0144223, lng: 19.8485479 },
    { lat: 49.0143095, lng: 19.8486758 },
    { lat: 49.0139079, lng: 19.8489832 },
    { lat: 49.0135862, lng: 19.8492039 },
    { lat: 49.0134536, lng: 19.8493171 },
    { lat: 49.0132906, lng: 19.8496649 },
    { lat: 49.0131371, lng: 19.8502094 },
    { lat: 49.0130341, lng: 19.8505167 },
    { lat: 49.0130555, lng: 19.850905 },
    { lat: 49.0133046, lng: 19.8514086 },
    { lat: 49.0136111, lng: 19.8521498 },
    { lat: 49.0135178, lng: 19.8533379 },
    { lat: 49.0136584, lng: 19.8539107 },
    { lat: 49.0136868, lng: 19.8540777 },
    { lat: 49.0137844, lng: 19.8545912 },
    { lat: 49.0138115, lng: 19.854806 },
    { lat: 49.0138799, lng: 19.8548222 },
    { lat: 49.0138436, lng: 19.8549991 },
    { lat: 49.013878, lng: 19.8550977 },
    { lat: 49.0138636, lng: 19.8551788 },
    { lat: 49.0138774, lng: 19.8552657 },
    { lat: 49.0139579, lng: 19.8554081 },
    { lat: 49.0139686, lng: 19.8554763 },
    { lat: 49.0139915, lng: 19.8555382 },
    { lat: 49.0139422, lng: 19.8555941 },
    { lat: 49.0139788, lng: 19.8556881 },
    { lat: 49.0139554, lng: 19.8557464 },
    { lat: 49.0140089, lng: 19.8558886 },
    { lat: 49.0140358, lng: 19.8560417 },
    { lat: 49.0141314, lng: 19.8562805 },
    { lat: 49.0141234, lng: 19.8564353 },
    { lat: 49.0140882, lng: 19.8565339 },
    { lat: 49.0141302, lng: 19.8565383 },
    { lat: 49.0142064, lng: 19.8570012 },
    { lat: 49.0142837, lng: 19.8572308 },
    { lat: 49.0143106, lng: 19.8573559 },
    { lat: 49.0143418, lng: 19.8574289 },
    { lat: 49.0143166, lng: 19.857496 },
    { lat: 49.0143329, lng: 19.8576957 },
    { lat: 49.0143677, lng: 19.8577705 },
    { lat: 49.0143485, lng: 19.8579009 },
    { lat: 49.0144504, lng: 19.8583114 },
    { lat: 49.0144225, lng: 19.8584211 },
    { lat: 49.0144376, lng: 19.8584828 },
    { lat: 49.0144238, lng: 19.8585839 },
    { lat: 49.0144236, lng: 19.8587353 },
    { lat: 49.0144762, lng: 19.8588059 },
    { lat: 49.0145082, lng: 19.8589247 },
    { lat: 49.0144696, lng: 19.859048 },
    { lat: 49.0145529, lng: 19.8590979 },
    { lat: 49.0145673, lng: 19.859146 },
    { lat: 49.0146451, lng: 19.8591731 },
    { lat: 49.0145889, lng: 19.8593603 },
    { lat: 49.0146815, lng: 19.859471 },
    { lat: 49.0147175, lng: 19.8595791 },
    { lat: 49.0146726, lng: 19.8596894 },
    { lat: 49.0147191, lng: 19.8598764 },
    { lat: 49.0147903, lng: 19.859934 },
    { lat: 49.0148191, lng: 19.8600011 },
    { lat: 49.0148973, lng: 19.8600698 },
    { lat: 49.0149271, lng: 19.8600819 },
    { lat: 49.0149751, lng: 19.8601486 },
    { lat: 49.0149499, lng: 19.8602724 },
    { lat: 49.0149904, lng: 19.8604521 },
    { lat: 49.0150695, lng: 19.8605914 },
    { lat: 49.0150839, lng: 19.8606622 },
    { lat: 49.0151352, lng: 19.8607292 },
    { lat: 49.0151482, lng: 19.860851 },
    { lat: 49.0152241, lng: 19.8609666 },
    { lat: 49.0152149, lng: 19.8610662 },
    { lat: 49.0152599, lng: 19.8611192 },
    { lat: 49.0152618, lng: 19.8611904 },
    { lat: 49.0154275, lng: 19.8612455 },
    { lat: 49.0154369, lng: 19.8613594 },
    { lat: 49.0153734, lng: 19.8614274 },
    { lat: 49.015366, lng: 19.8616502 },
    { lat: 49.0154005, lng: 19.8618008 },
    { lat: 49.0154109, lng: 19.861911 },
    { lat: 49.0154383, lng: 19.8619944 },
    { lat: 49.0154814, lng: 19.8620314 },
    { lat: 49.0155347, lng: 19.8621694 },
    { lat: 49.0155098, lng: 19.8622199 },
    { lat: 49.0155382, lng: 19.8623061 },
    { lat: 49.0155589, lng: 19.8625014 },
    { lat: 49.0156225, lng: 19.8624819 },
    { lat: 49.0156376, lng: 19.8625776 },
    { lat: 49.0156839, lng: 19.8626057 },
    { lat: 49.0157027, lng: 19.8626247 },
    { lat: 49.0157634, lng: 19.8627518 },
    { lat: 49.0157242, lng: 19.8627575 },
    { lat: 49.0156992, lng: 19.8628027 },
    { lat: 49.0157512, lng: 19.86302 },
    { lat: 49.0157802, lng: 19.8630907 },
    { lat: 49.0158137, lng: 19.8631133 },
    { lat: 49.0158737, lng: 19.8632236 },
    { lat: 49.015848, lng: 19.8632534 },
    { lat: 49.0159161, lng: 19.8633996 },
    { lat: 49.0159314, lng: 19.8634925 },
    { lat: 49.0159903, lng: 19.8635279 },
    { lat: 49.0161256, lng: 19.863872 },
    { lat: 49.0162432, lng: 19.8640445 },
    { lat: 49.0163198, lng: 19.8642009 },
    { lat: 49.0164263, lng: 19.8642909 },
    { lat: 49.0165592, lng: 19.8644787 },
    { lat: 49.0169582, lng: 19.8650878 },
    { lat: 49.0170657, lng: 19.8651282 },
    { lat: 49.0170964, lng: 19.8652127 },
    { lat: 49.0171109, lng: 19.8653146 },
    { lat: 49.0171884, lng: 19.8653601 },
    { lat: 49.0173635, lng: 19.8656913 },
    { lat: 49.0174689, lng: 19.8657322 },
    { lat: 49.017537, lng: 19.8658235 },
    { lat: 49.0176327, lng: 19.8658467 },
    { lat: 49.017685, lng: 19.8659395 },
    { lat: 49.0177003, lng: 19.8660327 },
    { lat: 49.0177799, lng: 19.8660766 },
    { lat: 49.0178374, lng: 19.8663121 },
    { lat: 49.0178601, lng: 19.8662922 },
    { lat: 49.0179039, lng: 19.866317 },
    { lat: 49.0179665, lng: 19.8664131 },
    { lat: 49.0180212, lng: 19.8665807 },
    { lat: 49.0181399, lng: 19.8667247 },
    { lat: 49.0181924, lng: 19.8667439 },
    { lat: 49.0182552, lng: 19.8668644 },
    { lat: 49.0182508, lng: 19.8669729 },
    { lat: 49.0184856, lng: 19.8672741 },
    { lat: 49.0186606, lng: 19.8673649 },
    { lat: 49.0189289, lng: 19.8676851 },
    { lat: 49.0189599, lng: 19.8678073 },
    { lat: 49.0190415, lng: 19.867922 },
    { lat: 49.0191131, lng: 19.8679634 },
    { lat: 49.0191263, lng: 19.8680339 },
    { lat: 49.0191706, lng: 19.868098 },
    { lat: 49.0192156, lng: 19.8680975 },
    { lat: 49.019293, lng: 19.8681667 },
    { lat: 49.0193557, lng: 19.8681817 },
    { lat: 49.0193398, lng: 19.8682346 },
    { lat: 49.019389, lng: 19.8682538 },
    { lat: 49.0194995, lng: 19.8682551 },
    { lat: 49.0196278, lng: 19.8683289 },
    { lat: 49.0196683, lng: 19.8683045 },
    { lat: 49.0199633, lng: 19.8682764 },
    { lat: 49.0199887, lng: 19.8682079 },
    { lat: 49.0202447, lng: 19.8680337 },
    { lat: 49.0203097, lng: 19.8679688 },
    { lat: 49.0207263, lng: 19.8677037 },
    { lat: 49.0209098, lng: 19.8675686 },
    { lat: 49.0211034, lng: 19.8675348 },
    { lat: 49.0214611, lng: 19.8680511 },
    { lat: 49.0216456, lng: 19.8682045 },
    { lat: 49.0218402, lng: 19.8683328 },
    { lat: 49.0222524, lng: 19.8685674 },
    { lat: 49.0224559, lng: 19.8687171 },
    { lat: 49.0225172, lng: 19.8686995 },
    { lat: 49.0226077, lng: 19.8688166 },
    { lat: 49.0226511, lng: 19.8690165 },
    { lat: 49.0228167, lng: 19.8692001 },
    { lat: 49.0228399, lng: 19.8692133 },
    { lat: 49.0228639, lng: 19.8692996 },
    { lat: 49.022864, lng: 19.8693028 },
    { lat: 49.0229335, lng: 19.8692715 },
    { lat: 49.0229575, lng: 19.8693312 },
    { lat: 49.0230718, lng: 19.8693429 },
    { lat: 49.0232802, lng: 19.8694763 },
    { lat: 49.023314, lng: 19.8695814 },
    { lat: 49.0233564, lng: 19.869597 },
    { lat: 49.0234115, lng: 19.869725 },
    { lat: 49.0234624, lng: 19.8697078 },
    { lat: 49.0235634, lng: 19.8698066 },
    { lat: 49.0236289, lng: 19.8698098 },
    { lat: 49.0236541, lng: 19.8698673 },
    { lat: 49.0237199, lng: 19.8698561 },
    { lat: 49.0238559, lng: 19.8701887 },
    { lat: 49.02379, lng: 19.870519 },
    { lat: 49.0238147, lng: 19.8706231 },
    { lat: 49.0239389, lng: 19.8708184 },
    { lat: 49.0239591, lng: 19.8714732 },
    { lat: 49.0239603, lng: 19.8720553 },
    { lat: 49.0239405, lng: 19.8724642 },
    { lat: 49.0239595, lng: 19.8732531 },
    { lat: 49.0239283, lng: 19.8735512 },
    { lat: 49.0239359, lng: 19.8737845 },
    { lat: 49.0239232, lng: 19.8741192 },
    { lat: 49.0239405, lng: 19.8744193 },
    { lat: 49.0239225, lng: 19.875083 },
    { lat: 49.023833, lng: 19.8753093 },
    { lat: 49.0237105, lng: 19.8754979 },
    { lat: 49.0236497, lng: 19.8756332 },
    { lat: 49.0236743, lng: 19.8758658 },
    { lat: 49.0238324, lng: 19.8762982 },
    { lat: 49.0238631, lng: 19.8764366 },
    { lat: 49.0238332, lng: 19.8771358 },
    { lat: 49.0238329, lng: 19.8775005 },
    { lat: 49.023848, lng: 19.877826 },
    { lat: 49.0239863, lng: 19.8783745 },
    { lat: 49.0241106, lng: 19.8786996 },
    { lat: 49.0242022, lng: 19.878976 },
    { lat: 49.0242459, lng: 19.8791547 },
    { lat: 49.0242996, lng: 19.87933 },
    { lat: 49.024361, lng: 19.8794528 },
    { lat: 49.0246907, lng: 19.8797064 },
    { lat: 49.0247618, lng: 19.8798175 },
    { lat: 49.0248382, lng: 19.8799718 },
    { lat: 49.0250229, lng: 19.880444 },
    { lat: 49.0252622, lng: 19.8808551 },
    { lat: 49.0254794, lng: 19.8812429 },
    { lat: 49.025608, lng: 19.8815698 },
    { lat: 49.0257704, lng: 19.8819404 },
    { lat: 49.0258594, lng: 19.8820475 },
    { lat: 49.0261182, lng: 19.8821976 },
    { lat: 49.026532, lng: 19.8825542 },
    { lat: 49.026752, lng: 19.8828531 },
    { lat: 49.0269222, lng: 19.8831486 },
    { lat: 49.0271789, lng: 19.883567 },
    { lat: 49.0273285, lng: 19.8837472 },
    { lat: 49.0275593, lng: 19.8839851 },
    { lat: 49.0278049, lng: 19.8841939 },
    { lat: 49.028126, lng: 19.8843555 },
    { lat: 49.0282859, lng: 19.8844585 },
    { lat: 49.0285194, lng: 19.8845756 },
    { lat: 49.0288325, lng: 19.8846517 },
    { lat: 49.0290607, lng: 19.8847208 },
    { lat: 49.0293714, lng: 19.8847965 },
    { lat: 49.0299652, lng: 19.8853045 },
    { lat: 49.030003, lng: 19.8852667 },
    { lat: 49.0300389, lng: 19.8853397 },
    { lat: 49.0300331, lng: 19.8853643 },
    { lat: 49.0301441, lng: 19.8854873 },
    { lat: 49.0301503, lng: 19.8854999 },
    { lat: 49.0306905, lng: 19.8861235 },
    { lat: 49.0306778, lng: 19.8861412 },
    { lat: 49.0307094, lng: 19.886197 },
    { lat: 49.0307389, lng: 19.8861751 },
    { lat: 49.0307382, lng: 19.8865301 },
    { lat: 49.0307018, lng: 19.8866016 },
    { lat: 49.030765, lng: 19.8865771 },
    { lat: 49.0310159, lng: 19.8863323 },
    { lat: 49.0310891, lng: 19.8852719 },
    { lat: 49.0310516, lng: 19.8846022 },
    { lat: 49.031229, lng: 19.8841381 },
    { lat: 49.0320404, lng: 19.8833267 },
    { lat: 49.0322706, lng: 19.8831501 },
    { lat: 49.032524, lng: 19.8824872 },
    { lat: 49.0329323, lng: 19.8822858 },
    { lat: 49.0334786, lng: 19.8824166 },
    { lat: 49.0340495, lng: 19.8829409 },
    { lat: 49.034411, lng: 19.8833103 },
    { lat: 49.0345736, lng: 19.885039 },
    { lat: 49.0350646, lng: 19.8845635 },
    { lat: 49.035713, lng: 19.8837867 },
    { lat: 49.0359701, lng: 19.8833647 },
    { lat: 49.0363742, lng: 19.8830428 },
    { lat: 49.0369361, lng: 19.8827477 },
    { lat: 49.0374766, lng: 19.8830617 },
    { lat: 49.0377829, lng: 19.8835324 },
    { lat: 49.038278, lng: 19.8844215 },
    { lat: 49.0384249, lng: 19.884513 },
    { lat: 49.0387672, lng: 19.8844588 },
    { lat: 49.0402637, lng: 19.8844958 },
    { lat: 49.0412778, lng: 19.8845823 },
    { lat: 49.041309, lng: 19.8845767 },
    { lat: 49.0420062, lng: 19.8845742 },
    { lat: 49.0424376, lng: 19.8847339 },
    { lat: 49.0428632, lng: 19.8840687 },
    { lat: 49.0429444, lng: 19.8839357 },
    { lat: 49.0431327, lng: 19.8837287 },
    { lat: 49.0436872, lng: 19.8833621 },
    { lat: 49.0438692, lng: 19.8832469 },
    { lat: 49.0439487, lng: 19.883206 },
    { lat: 49.0450567, lng: 19.882753 },
    { lat: 49.0448601, lng: 19.8818879 },
    { lat: 49.0448404, lng: 19.8813175 },
    { lat: 49.0447725, lng: 19.880438 },
    { lat: 49.0446091, lng: 19.8797984 },
    { lat: 49.0444761, lng: 19.8792984 },
    { lat: 49.0443753, lng: 19.8787277 },
    { lat: 49.044735, lng: 19.8783372 },
    { lat: 49.0447641, lng: 19.8783113 },
    { lat: 49.0448218, lng: 19.8782359 },
    { lat: 49.0448645, lng: 19.8781801 },
    { lat: 49.0456052, lng: 19.8772938 },
    { lat: 49.0457051, lng: 19.877183 },
    { lat: 49.0464351, lng: 19.8763128 },
    { lat: 49.0460286, lng: 19.8757073 },
    { lat: 49.0457387, lng: 19.8750346 },
    { lat: 49.0456471, lng: 19.8747086 },
    { lat: 49.0456797, lng: 19.8742059 },
    { lat: 49.0457586, lng: 19.8740462 },
    { lat: 49.0459799, lng: 19.8741371 },
    { lat: 49.0463327, lng: 19.8744334 },
    { lat: 49.0466364, lng: 19.8747144 },
    { lat: 49.0468215, lng: 19.8747267 },
    { lat: 49.0471206, lng: 19.8745773 },
    { lat: 49.0474913, lng: 19.8742657 },
    { lat: 49.0475778, lng: 19.8742611 },
    { lat: 49.0476345, lng: 19.8740569 },
    { lat: 49.0479868, lng: 19.8735514 },
    { lat: 49.0482164, lng: 19.873096 },
    { lat: 49.0492133, lng: 19.8730142 },
    { lat: 49.0496315, lng: 19.8730247 },
    { lat: 49.0496313, lng: 19.8729906 },
    { lat: 49.0495892, lng: 19.8728726 },
    { lat: 49.0489698, lng: 19.8705801 },
    { lat: 49.05128, lng: 19.8692292 },
    { lat: 49.0534028, lng: 19.8669172 },
    { lat: 49.0535201, lng: 19.8667903 },
    { lat: 49.0535673, lng: 19.8667077 },
    { lat: 49.0538268, lng: 19.8666146 },
    { lat: 49.0539433, lng: 19.8665942 },
    { lat: 49.0540941, lng: 19.8665677 },
    { lat: 49.0560168, lng: 19.8658243 },
    { lat: 49.0560543, lng: 19.8662552 },
    { lat: 49.0571342, lng: 19.8660594 },
    { lat: 49.0571762, lng: 19.86607 },
    { lat: 49.0572427, lng: 19.8660684 },
    { lat: 49.0572727, lng: 19.8660583 },
    { lat: 49.0582806, lng: 19.8663175 },
    { lat: 49.0588482, lng: 19.8661059 },
    { lat: 49.0595294, lng: 19.8658353 },
    { lat: 49.0604932, lng: 19.8671179 },
    { lat: 49.0608407, lng: 19.8675789 },
    { lat: 49.06246, lng: 19.8720301 },
    { lat: 49.0624719, lng: 19.8721557 },
    { lat: 49.0628931, lng: 19.8717811 },
    { lat: 49.0632279, lng: 19.8712294 },
    { lat: 49.0635788, lng: 19.8709559 },
    { lat: 49.0638407, lng: 19.8705701 },
    { lat: 49.0643605, lng: 19.8700277 },
    { lat: 49.0647567, lng: 19.8695446 },
    { lat: 49.0653424, lng: 19.8689372 },
    { lat: 49.0655257, lng: 19.8692456 },
    { lat: 49.0658221, lng: 19.8694085 },
    { lat: 49.0656461, lng: 19.8698257 },
    { lat: 49.0657399, lng: 19.8699878 },
    { lat: 49.0659009, lng: 19.8701731 },
    { lat: 49.0659237, lng: 19.8709173 },
    { lat: 49.0660217, lng: 19.871001 },
    { lat: 49.0660888, lng: 19.8713073 },
    { lat: 49.0659422, lng: 19.8715132 },
    { lat: 49.065941, lng: 19.8716657 },
    { lat: 49.0661048, lng: 19.8717062 },
    { lat: 49.0662246, lng: 19.8716646 },
    { lat: 49.0663409, lng: 19.8718848 },
    { lat: 49.0665688, lng: 19.8719133 },
    { lat: 49.0665892, lng: 19.8720503 },
    { lat: 49.0667565, lng: 19.87212 },
    { lat: 49.0668322, lng: 19.8724115 },
    { lat: 49.0668826, lng: 19.8724086 },
    { lat: 49.0669256, lng: 19.8725441 },
    { lat: 49.0668832, lng: 19.8727405 },
    { lat: 49.0667763, lng: 19.8726634 },
    { lat: 49.0667124, lng: 19.8729582 },
    { lat: 49.0667569, lng: 19.8732099 },
    { lat: 49.0667146, lng: 19.8737508 },
    { lat: 49.0666221, lng: 19.8736683 },
    { lat: 49.0663988, lng: 19.8737403 },
    { lat: 49.0663617, lng: 19.8739832 },
    { lat: 49.066484, lng: 19.8743185 },
    { lat: 49.066485, lng: 19.874447 },
    { lat: 49.0662613, lng: 19.8746411 },
    { lat: 49.0663991, lng: 19.8748614 },
    { lat: 49.0665164, lng: 19.8748128 },
    { lat: 49.0667969, lng: 19.8747579 },
    { lat: 49.0669053, lng: 19.8747662 },
    { lat: 49.0668771, lng: 19.8749741 },
    { lat: 49.0668348, lng: 19.8753824 },
    { lat: 49.0669287, lng: 19.8757094 },
    { lat: 49.0668868, lng: 19.8759435 },
    { lat: 49.0669332, lng: 19.8762399 },
    { lat: 49.0669114, lng: 19.876787 },
    { lat: 49.0671686, lng: 19.8768195 },
    { lat: 49.0671775, lng: 19.8770288 },
    { lat: 49.066964, lng: 19.8773085 },
    { lat: 49.0669631, lng: 19.8775765 },
    { lat: 49.0669788, lng: 19.8778383 },
    { lat: 49.06705, lng: 19.878086 },
    { lat: 49.067343, lng: 19.8782202 },
    { lat: 49.067427, lng: 19.878365 },
    { lat: 49.0674244, lng: 19.878464 },
    { lat: 49.0672996, lng: 19.8786038 },
    { lat: 49.067457, lng: 19.8787546 },
    { lat: 49.0674454, lng: 19.8788797 },
    { lat: 49.0672644, lng: 19.8789917 },
    { lat: 49.0672882, lng: 19.8793127 },
    { lat: 49.0671067, lng: 19.8794412 },
    { lat: 49.0672122, lng: 19.8799132 },
    { lat: 49.0673346, lng: 19.8797708 },
    { lat: 49.0674219, lng: 19.8799709 },
    { lat: 49.0675045, lng: 19.879975 },
    { lat: 49.0676524, lng: 19.8797168 },
    { lat: 49.0676728, lng: 19.8800651 },
    { lat: 49.0674949, lng: 19.8800165 },
    { lat: 49.0674227, lng: 19.8800951 },
    { lat: 49.0676024, lng: 19.880613 },
    { lat: 49.0676677, lng: 19.8806871 },
    { lat: 49.0677274, lng: 19.8806589 },
    { lat: 49.0678174, lng: 19.8804223 },
    { lat: 49.0677928, lng: 19.8807706 },
    { lat: 49.0679379, lng: 19.8808702 },
    { lat: 49.0681493, lng: 19.8812331 },
    { lat: 49.0682682, lng: 19.8812203 },
    { lat: 49.0684314, lng: 19.8811392 },
    { lat: 49.0684391, lng: 19.8808718 },
    { lat: 49.0685873, lng: 19.8809647 },
    { lat: 49.0686564, lng: 19.880891 },
    { lat: 49.0687083, lng: 19.8809452 },
    { lat: 49.0687523, lng: 19.8810806 },
    { lat: 49.0686177, lng: 19.8812027 },
    { lat: 49.0686821, lng: 19.8815222 },
    { lat: 49.0686838, lng: 19.8817749 },
    { lat: 49.0689387, lng: 19.881941 },
    { lat: 49.0691153, lng: 19.8818858 },
    { lat: 49.0691924, lng: 19.8816835 },
    { lat: 49.0692935, lng: 19.881864 },
    { lat: 49.0695724, lng: 19.8818282 },
    { lat: 49.0697111, lng: 19.8815088 },
    { lat: 49.0698605, lng: 19.8815541 },
    { lat: 49.0698719, lng: 19.8817441 },
    { lat: 49.0698626, lng: 19.8820802 },
    { lat: 49.0699972, lng: 19.8824912 },
    { lat: 49.0701416, lng: 19.8823351 },
    { lat: 49.0701797, lng: 19.8823324 },
    { lat: 49.0702327, lng: 19.8824397 },
    { lat: 49.0702541, lng: 19.8826775 },
    { lat: 49.0703409, lng: 19.882919 },
    { lat: 49.0704787, lng: 19.8828465 },
    { lat: 49.0705058, lng: 19.882873 },
    { lat: 49.0704996, lng: 19.8830722 },
    { lat: 49.0705909, lng: 19.8831254 },
    { lat: 49.0706345, lng: 19.8832253 },
    { lat: 49.0707628, lng: 19.8834103 },
    { lat: 49.0709082, lng: 19.8833323 },
    { lat: 49.0711074, lng: 19.8835161 },
    { lat: 49.0710579, lng: 19.8839675 },
    { lat: 49.0710571, lng: 19.8839705 },
    { lat: 49.0712339, lng: 19.8841309 },
    { lat: 49.0712914, lng: 19.8841069 },
    { lat: 49.0712992, lng: 19.8839463 },
    { lat: 49.0713411, lng: 19.8839001 },
    { lat: 49.0715849, lng: 19.8840658 },
    { lat: 49.0714092, lng: 19.8843349 },
    { lat: 49.0714399, lng: 19.8844742 },
    { lat: 49.0717136, lng: 19.884472 },
    { lat: 49.0718446, lng: 19.8844541 },
    { lat: 49.0718617, lng: 19.884724 },
    { lat: 49.0721438, lng: 19.8846808 },
    { lat: 49.0722233, lng: 19.8848842 },
    { lat: 49.0722992, lng: 19.8849709 },
    { lat: 49.0723856, lng: 19.8848614 },
    { lat: 49.0723412, lng: 19.8846603 },
    { lat: 49.0724951, lng: 19.8847604 },
    { lat: 49.0725423, lng: 19.8849652 },
    { lat: 49.0726256, lng: 19.8850196 },
    { lat: 49.0726982, lng: 19.8849517 },
    { lat: 49.0726618, lng: 19.8847347 },
    { lat: 49.0727716, lng: 19.8847677 },
    { lat: 49.0727808, lng: 19.8851444 },
    { lat: 49.0728747, lng: 19.8852339 },
    { lat: 49.0731777, lng: 19.8852372 },
    { lat: 49.0731614, lng: 19.8854378 },
    { lat: 49.073295, lng: 19.8856366 },
    { lat: 49.0734077, lng: 19.8857459 },
    { lat: 49.0735791, lng: 19.8854567 },
    { lat: 49.0737006, lng: 19.8855604 },
    { lat: 49.0738171, lng: 19.8855701 },
    { lat: 49.07383, lng: 19.8853943 },
    { lat: 49.0739448, lng: 19.8851541 },
    { lat: 49.0740799, lng: 19.8852328 },
    { lat: 49.0742511, lng: 19.8848344 },
    { lat: 49.0743426, lng: 19.8844434 },
    { lat: 49.0744003, lng: 19.8844234 },
    { lat: 49.0744281, lng: 19.8844663 },
    { lat: 49.074374, lng: 19.8845991 },
    { lat: 49.0744508, lng: 19.8847123 },
    { lat: 49.0745562, lng: 19.8845147 },
    { lat: 49.0746239, lng: 19.8845699 },
    { lat: 49.0747543, lng: 19.8845107 },
    { lat: 49.0747525, lng: 19.8846517 },
    { lat: 49.0749767, lng: 19.8851301 },
    { lat: 49.0751209, lng: 19.8850796 },
    { lat: 49.0751993, lng: 19.8849073 },
    { lat: 49.0751957, lng: 19.8846106 },
    { lat: 49.0754174, lng: 19.8847903 },
    { lat: 49.0755404, lng: 19.8848256 },
    { lat: 49.0755862, lng: 19.8844686 },
    { lat: 49.0756377, lng: 19.8843813 },
    { lat: 49.0757838, lng: 19.8844316 },
    { lat: 49.0757907, lng: 19.8846702 },
    { lat: 49.0762778, lng: 19.8852909 },
    { lat: 49.0763644, lng: 19.885264 },
    { lat: 49.0764734, lng: 19.8852849 },
    { lat: 49.0766584, lng: 19.8850224 },
    { lat: 49.0767242, lng: 19.885299 },
    { lat: 49.0766012, lng: 19.885402 },
    { lat: 49.0766698, lng: 19.8856342 },
    { lat: 49.0766697, lng: 19.8857927 },
    { lat: 49.0768154, lng: 19.8858843 },
    { lat: 49.0768224, lng: 19.8859433 },
    { lat: 49.0766819, lng: 19.8862126 },
    { lat: 49.0767194, lng: 19.8864564 },
    { lat: 49.0768404, lng: 19.8865785 },
    { lat: 49.0768535, lng: 19.886698 },
    { lat: 49.0767663, lng: 19.8866879 },
    { lat: 49.0766568, lng: 19.8867888 },
    { lat: 49.0768204, lng: 19.8873505 },
    { lat: 49.0767811, lng: 19.8874104 },
    { lat: 49.0767563, lng: 19.8873033 },
    { lat: 49.0766255, lng: 19.8873521 },
    { lat: 49.0766087, lng: 19.8875693 },
    { lat: 49.0767524, lng: 19.8876633 },
    { lat: 49.0769146, lng: 19.8878737 },
    { lat: 49.0768969, lng: 19.8880415 },
    { lat: 49.0767815, lng: 19.8880259 },
    { lat: 49.0767586, lng: 19.8881539 },
    { lat: 49.0767908, lng: 19.8882725 },
    { lat: 49.0771041, lng: 19.8885956 },
    { lat: 49.077325, lng: 19.8883626 },
    { lat: 49.0775896, lng: 19.8886506 },
    { lat: 49.077511, lng: 19.8889447 },
    { lat: 49.0775655, lng: 19.8890902 },
    { lat: 49.0776879, lng: 19.8889981 },
    { lat: 49.0777383, lng: 19.8893118 },
    { lat: 49.0778721, lng: 19.8893058 },
    { lat: 49.0779545, lng: 19.8894181 },
    { lat: 49.0778625, lng: 19.8895306 },
    { lat: 49.0778613, lng: 19.8896917 },
    { lat: 49.078012, lng: 19.8898425 },
    { lat: 49.0781803, lng: 19.8899338 },
    { lat: 49.0782032, lng: 19.890182 },
    { lat: 49.0780773, lng: 19.8900812 },
    { lat: 49.0780423, lng: 19.8900792 },
    { lat: 49.0780357, lng: 19.8902678 },
    { lat: 49.0782765, lng: 19.8906077 },
    { lat: 49.0784368, lng: 19.8905646 },
    { lat: 49.0785912, lng: 19.8905387 },
    { lat: 49.0787472, lng: 19.8907648 },
    { lat: 49.0788468, lng: 19.8906921 },
    { lat: 49.078842, lng: 19.8904515 },
    { lat: 49.0788475, lng: 19.8902871 },
    { lat: 49.0789482, lng: 19.8903239 },
    { lat: 49.0789435, lng: 19.8904508 },
    { lat: 49.078957, lng: 19.8905314 },
    { lat: 49.0790763, lng: 19.890528 },
    { lat: 49.0791182, lng: 19.8906694 },
    { lat: 49.0791952, lng: 19.8905989 },
    { lat: 49.0793034, lng: 19.8906512 },
    { lat: 49.0792853, lng: 19.8907571 },
    { lat: 49.0792914, lng: 19.8908183 },
    { lat: 49.0792802, lng: 19.8910041 },
    { lat: 49.0794077, lng: 19.8911439 },
    { lat: 49.0794764, lng: 19.8913018 },
    { lat: 49.0797581, lng: 19.8911989 },
    { lat: 49.0798064, lng: 19.8915133 },
    { lat: 49.0798344, lng: 19.8915386 },
    { lat: 49.0799545, lng: 19.8914757 },
    { lat: 49.0800291, lng: 19.8917713 },
    { lat: 49.0801254, lng: 19.8919429 },
    { lat: 49.0802097, lng: 19.8923669 },
    { lat: 49.0803749, lng: 19.8924613 },
    { lat: 49.080514, lng: 19.8924523 },
    { lat: 49.0806367, lng: 19.8923181 },
    { lat: 49.0808229, lng: 19.8923221 },
    { lat: 49.0808376, lng: 19.8924007 },
    { lat: 49.080951, lng: 19.8925039 },
    { lat: 49.0810814, lng: 19.8924964 },
    { lat: 49.0812712, lng: 19.892352 },
    { lat: 49.0812962, lng: 19.8924067 },
    { lat: 49.0813092, lng: 19.8925574 },
    { lat: 49.0815668, lng: 19.8924641 },
    { lat: 49.0816698, lng: 19.8922395 },
    { lat: 49.0816524, lng: 19.8921241 },
    { lat: 49.0817983, lng: 19.8920795 },
    { lat: 49.0819021, lng: 19.8923 },
    { lat: 49.0819143, lng: 19.8925597 },
    { lat: 49.0818645, lng: 19.8928442 },
    { lat: 49.0821328, lng: 19.8929597 },
    { lat: 49.0822054, lng: 19.892862 },
    { lat: 49.0823136, lng: 19.8928924 },
    { lat: 49.0824436, lng: 19.8928476 },
    { lat: 49.0825865, lng: 19.893061 },
    { lat: 49.0826297, lng: 19.8932781 },
    { lat: 49.0828918, lng: 19.8936685 },
    { lat: 49.0830895, lng: 19.8937123 },
    { lat: 49.0831878, lng: 19.8937975 },
    { lat: 49.0832033, lng: 19.8939249 },
    { lat: 49.0831367, lng: 19.8939168 },
    { lat: 49.0830891, lng: 19.8938855 },
    { lat: 49.083067, lng: 19.8940821 },
    { lat: 49.083109, lng: 19.8941714 },
    { lat: 49.0832734, lng: 19.8942465 },
    { lat: 49.083348, lng: 19.8942022 },
    { lat: 49.0834145, lng: 19.8942578 },
    { lat: 49.08347, lng: 19.8943971 },
    { lat: 49.0835821, lng: 19.8944637 },
    { lat: 49.0836629, lng: 19.8948277 },
    { lat: 49.0837989, lng: 19.8948992 },
    { lat: 49.0838776, lng: 19.8950794 },
    { lat: 49.0842431, lng: 19.8954671 },
    { lat: 49.0842783, lng: 19.8955783 },
    { lat: 49.0844029, lng: 19.8957012 },
    { lat: 49.0844543, lng: 19.8960903 },
    { lat: 49.0847495, lng: 19.896361 },
    { lat: 49.0850956, lng: 19.8965789 },
    { lat: 49.0852221, lng: 19.8965841 },
    { lat: 49.0855569, lng: 19.8969368 },
    { lat: 49.0856604, lng: 19.8969633 },
    { lat: 49.0858884, lng: 19.8971084 },
    { lat: 49.0860027, lng: 19.8973325 },
    { lat: 49.086279, lng: 19.8974732 },
    { lat: 49.0865301, lng: 19.8976297 },
    { lat: 49.0867599, lng: 19.8976556 },
    { lat: 49.0868365, lng: 19.897495 },
    { lat: 49.0872665, lng: 19.8974377 },
    { lat: 49.087812, lng: 19.8974452 },
    { lat: 49.0880644, lng: 19.8977133 },
    { lat: 49.0883038, lng: 19.8975661 },
    { lat: 49.088408, lng: 19.8976055 },
    { lat: 49.0884615, lng: 19.8977562 },
    { lat: 49.0889596, lng: 19.8980785 },
    { lat: 49.0893555, lng: 19.8979853 },
    { lat: 49.0894875, lng: 19.8980162 },
    { lat: 49.089641, lng: 19.898234 },
    { lat: 49.0897947, lng: 19.8985151 },
    { lat: 49.0901386, lng: 19.8986822 },
    { lat: 49.0903689, lng: 19.8989083 },
    { lat: 49.0906281, lng: 19.8988594 },
    { lat: 49.0910462, lng: 19.8989158 },
    { lat: 49.0911661, lng: 19.8990061 },
    { lat: 49.0912263, lng: 19.8991515 },
    { lat: 49.0912774, lng: 19.8993995 },
    { lat: 49.0914047, lng: 19.8997737 },
    { lat: 49.0915207, lng: 19.8999043 },
    { lat: 49.0920643, lng: 19.9001061 },
    { lat: 49.0921531, lng: 19.9002073 },
    { lat: 49.0922771, lng: 19.9005826 },
    { lat: 49.0923106, lng: 19.9008931 },
    { lat: 49.0925767, lng: 19.9012749 },
    { lat: 49.0928871, lng: 19.9016387 },
    { lat: 49.0932767, lng: 19.9018463 },
    { lat: 49.0934281, lng: 19.9018824 },
    { lat: 49.0937534, lng: 19.9018002 },
    { lat: 49.0938215, lng: 19.901998 },
    { lat: 49.0939337, lng: 19.9022512 },
    { lat: 49.0940767, lng: 19.9024618 },
    { lat: 49.0944341, lng: 19.9026596 },
    { lat: 49.0948041, lng: 19.9026948 },
    { lat: 49.095093, lng: 19.9028188 },
    { lat: 49.0951997, lng: 19.9027566 },
    { lat: 49.09537, lng: 19.9026037 },
    { lat: 49.0955444, lng: 19.9027027 },
    { lat: 49.0956363, lng: 19.9028284 },
    { lat: 49.0957204, lng: 19.9030592 },
    { lat: 49.0957496, lng: 19.9032404 },
    { lat: 49.0958555, lng: 19.903428 },
    { lat: 49.0960093, lng: 19.9040294 },
    { lat: 49.0960464, lng: 19.9041867 },
    { lat: 49.0958898, lng: 19.9043951 },
    { lat: 49.0959947, lng: 19.9046159 },
    { lat: 49.0961274, lng: 19.9050059 },
    { lat: 49.0960908, lng: 19.9052905 },
    { lat: 49.0964215, lng: 19.9057836 },
    { lat: 49.0964153, lng: 19.9059308 },
    { lat: 49.0968476, lng: 19.9059794 },
    { lat: 49.0970884, lng: 19.9063422 },
    { lat: 49.0971903, lng: 19.9061194 },
    { lat: 49.0975133, lng: 19.9068846 },
    { lat: 49.0977658, lng: 19.906913 },
    { lat: 49.0985115, lng: 19.9075002 },
    { lat: 49.0986463, lng: 19.9076512 },
    { lat: 49.0989518, lng: 19.9077367 },
    { lat: 49.0991427, lng: 19.9078574 },
    { lat: 49.0995703, lng: 19.9085653 },
    { lat: 49.1001262, lng: 19.9087394 },
    { lat: 49.1002181, lng: 19.9088634 },
    { lat: 49.100319, lng: 19.9093025 },
    { lat: 49.1004103, lng: 19.9095193 },
    { lat: 49.1005637, lng: 19.9097358 },
    { lat: 49.1007017, lng: 19.9098861 },
    { lat: 49.1007565, lng: 19.9098747 },
    { lat: 49.1008265, lng: 19.909985 },
    { lat: 49.1009592, lng: 19.910087 },
    { lat: 49.1009302, lng: 19.9102815 },
    { lat: 49.1010016, lng: 19.9103697 },
    { lat: 49.1011977, lng: 19.9104015 },
    { lat: 49.1013082, lng: 19.9103515 },
    { lat: 49.1014652, lng: 19.9103581 },
    { lat: 49.1016018, lng: 19.9102908 },
    { lat: 49.1016897, lng: 19.9104486 },
    { lat: 49.1018386, lng: 19.9105587 },
    { lat: 49.102004, lng: 19.9106378 },
    { lat: 49.1022184, lng: 19.9109917 },
    { lat: 49.1023627, lng: 19.9111835 },
    { lat: 49.1024415, lng: 19.9112315 },
    { lat: 49.1024791, lng: 19.9113238 },
    { lat: 49.1023731, lng: 19.911506 },
    { lat: 49.1022187, lng: 19.9115855 },
    { lat: 49.1021967, lng: 19.9117336 },
    { lat: 49.10236, lng: 19.9118898 },
    { lat: 49.102492, lng: 19.9118679 },
    { lat: 49.1026637, lng: 19.9120675 },
    { lat: 49.1026685, lng: 19.9121515 },
    { lat: 49.1026444, lng: 19.9122523 },
    { lat: 49.1027456, lng: 19.9123506 },
    { lat: 49.1029467, lng: 19.9123396 },
    { lat: 49.1029993, lng: 19.9123847 },
    { lat: 49.1029874, lng: 19.912584 },
    { lat: 49.1030722, lng: 19.9126979 },
    { lat: 49.1031862, lng: 19.9127306 },
    { lat: 49.1032676, lng: 19.9126565 },
    { lat: 49.1033835, lng: 19.912658 },
    { lat: 49.103626, lng: 19.91285 },
    { lat: 49.1040189, lng: 19.9129258 },
    { lat: 49.1040432, lng: 19.9130751 },
    { lat: 49.1040032, lng: 19.913197 },
    { lat: 49.1040376, lng: 19.9134751 },
    { lat: 49.1040249, lng: 19.9137337 },
    { lat: 49.1041578, lng: 19.9138109 },
    { lat: 49.1041561, lng: 19.9136137 },
    { lat: 49.1042827, lng: 19.9135992 },
    { lat: 49.1044595, lng: 19.9137839 },
    { lat: 49.1045908, lng: 19.9140385 },
    { lat: 49.104638, lng: 19.9140378 },
    { lat: 49.1046675, lng: 19.9139364 },
    { lat: 49.1047385, lng: 19.9138802 },
    { lat: 49.1047379, lng: 19.9138163 },
    { lat: 49.1046849, lng: 19.9137348 },
    { lat: 49.1047203, lng: 19.9136397 },
    { lat: 49.1048114, lng: 19.913661 },
    { lat: 49.1049237, lng: 19.9138376 },
    { lat: 49.1049732, lng: 19.9138094 },
    { lat: 49.1049987, lng: 19.913776 },
    { lat: 49.1050162, lng: 19.9136319 },
    { lat: 49.1051007, lng: 19.9136815 },
    { lat: 49.1051933, lng: 19.9138235 },
    { lat: 49.1054091, lng: 19.9140249 },
    { lat: 49.1054656, lng: 19.9142965 },
    { lat: 49.1054794, lng: 19.9144612 },
    { lat: 49.1055118, lng: 19.9145353 },
    { lat: 49.1056297, lng: 19.9145842 },
    { lat: 49.1056777, lng: 19.9145468 },
    { lat: 49.1056941, lng: 19.9144247 },
    { lat: 49.1057804, lng: 19.9143908 },
    { lat: 49.1058477, lng: 19.9144363 },
    { lat: 49.105903, lng: 19.9144363 },
    { lat: 49.1059986, lng: 19.9143802 },
    { lat: 49.1060704, lng: 19.9143997 },
    { lat: 49.1063565, lng: 19.9142941 },
    { lat: 49.1067163, lng: 19.9143843 },
    { lat: 49.1067853, lng: 19.9143934 },
    { lat: 49.1069781, lng: 19.9141077 },
    { lat: 49.1071221, lng: 19.9142363 },
    { lat: 49.107266, lng: 19.914278 },
    { lat: 49.1074106, lng: 19.9141811 },
    { lat: 49.1075654, lng: 19.9140347 },
    { lat: 49.1077193, lng: 19.9139733 },
    { lat: 49.1078344, lng: 19.9136805 },
    { lat: 49.1079323, lng: 19.9135728 },
    { lat: 49.1080005, lng: 19.9136172 },
    { lat: 49.1080308, lng: 19.9137702 },
    { lat: 49.1082765, lng: 19.9138324 },
    { lat: 49.1084239, lng: 19.9140639 },
    { lat: 49.1085064, lng: 19.9142359 },
    { lat: 49.1087044, lng: 19.9143622 },
    { lat: 49.1089108, lng: 19.9143941 },
    { lat: 49.1090067, lng: 19.9142887 },
    { lat: 49.1090617, lng: 19.914206 },
    { lat: 49.1092751, lng: 19.9142441 },
    { lat: 49.1093326, lng: 19.914567 },
    { lat: 49.1094394, lng: 19.9146713 },
    { lat: 49.1095519, lng: 19.9145855 },
    { lat: 49.1096688, lng: 19.9145521 },
    { lat: 49.1099089, lng: 19.9147446 },
    { lat: 49.1100027, lng: 19.914725 },
    { lat: 49.1101766, lng: 19.9145991 },
    { lat: 49.1103094, lng: 19.9147317 },
    { lat: 49.1104239, lng: 19.9149887 },
    { lat: 49.1105375, lng: 19.9150926 },
    { lat: 49.1105916, lng: 19.9152275 },
    { lat: 49.1107094, lng: 19.9152954 },
    { lat: 49.1108668, lng: 19.9151542 },
    { lat: 49.1109372, lng: 19.9151167 },
    { lat: 49.1110663, lng: 19.9153453 },
    { lat: 49.1111533, lng: 19.9154328 },
    { lat: 49.1111878, lng: 19.9154219 },
    { lat: 49.111441, lng: 19.915126 },
    { lat: 49.1116055, lng: 19.9152861 },
    { lat: 49.1116682, lng: 19.9154352 },
    { lat: 49.1117254, lng: 19.9156162 },
    { lat: 49.1118, lng: 19.915678 },
    { lat: 49.1117978, lng: 19.9158403 },
    { lat: 49.1118089, lng: 19.9160751 },
    { lat: 49.1119213, lng: 19.9161195 },
    { lat: 49.112155, lng: 19.916025 },
    { lat: 49.1122405, lng: 19.9157869 },
    { lat: 49.112361, lng: 19.9159179 },
    { lat: 49.1124778, lng: 19.915933 },
    { lat: 49.1126055, lng: 19.9156764 },
    { lat: 49.1127715, lng: 19.9157407 },
    { lat: 49.1128743, lng: 19.9158543 },
    { lat: 49.1129684, lng: 19.9161097 },
    { lat: 49.1132458, lng: 19.9162515 },
    { lat: 49.1133676, lng: 19.9163356 },
    { lat: 49.1135266, lng: 19.9162888 },
    { lat: 49.1137401, lng: 19.9161683 },
    { lat: 49.1138125, lng: 19.9163906 },
    { lat: 49.1138459, lng: 19.9164333 },
    { lat: 49.1139437, lng: 19.9164795 },
    { lat: 49.1141566, lng: 19.9164295 },
    { lat: 49.1142753, lng: 19.9163348 },
    { lat: 49.1143177, lng: 19.9161365 },
    { lat: 49.1143863, lng: 19.9161338 },
    { lat: 49.1144598, lng: 19.9162481 },
    { lat: 49.1144888, lng: 19.9165606 },
    { lat: 49.1146667, lng: 19.9165604 },
    { lat: 49.1147361, lng: 19.9166547 },
    { lat: 49.1147192, lng: 19.9169763 },
    { lat: 49.1147932, lng: 19.9171291 },
    { lat: 49.1148949, lng: 19.9171634 },
    { lat: 49.1151099, lng: 19.9170014 },
    { lat: 49.1155024, lng: 19.9167418 },
    { lat: 49.1155914, lng: 19.916933 },
    { lat: 49.1157747, lng: 19.917088 },
    { lat: 49.1158775, lng: 19.9170895 },
    { lat: 49.1158593, lng: 19.9172746 },
    { lat: 49.1159177, lng: 19.9174836 },
    { lat: 49.1159516, lng: 19.9174871 },
    { lat: 49.1160763, lng: 19.9173994 },
    { lat: 49.1160857, lng: 19.9172766 },
    { lat: 49.1161712, lng: 19.9172707 },
    { lat: 49.1163668, lng: 19.9174486 },
    { lat: 49.1165511, lng: 19.9176833 },
    { lat: 49.1166623, lng: 19.91778 },
    { lat: 49.1170409, lng: 19.9177296 },
    { lat: 49.1172371, lng: 19.9176295 },
    { lat: 49.1174264, lng: 19.9177641 },
    { lat: 49.1176809, lng: 19.917923 },
    { lat: 49.1178861, lng: 19.9178727 },
    { lat: 49.118121, lng: 19.917941 },
    { lat: 49.1184358, lng: 19.9183831 },
    { lat: 49.1187572, lng: 19.9186548 },
    { lat: 49.118945, lng: 19.9189172 },
    { lat: 49.1190025, lng: 19.9189486 },
    { lat: 49.1191723, lng: 19.9192571 },
    { lat: 49.1193464, lng: 19.9194623 },
    { lat: 49.1193782, lng: 19.9194682 },
    { lat: 49.1195291, lng: 19.9194135 },
    { lat: 49.1195877, lng: 19.9194433 },
    { lat: 49.1195993, lng: 19.9196119 },
    { lat: 49.1196305, lng: 19.9196581 },
    { lat: 49.119646, lng: 19.9198343 },
    { lat: 49.1198437, lng: 19.92009 },
    { lat: 49.119909, lng: 19.9203562 },
    { lat: 49.1200063, lng: 19.9204474 },
    { lat: 49.1201113, lng: 19.9206378 },
    { lat: 49.1202838, lng: 19.920727 },
    { lat: 49.120355, lng: 19.9208649 },
    { lat: 49.1203962, lng: 19.921041 },
    { lat: 49.1206385, lng: 19.9212295 },
    { lat: 49.1207255, lng: 19.9214227 },
    { lat: 49.1208948, lng: 19.9214019 },
    { lat: 49.1208312, lng: 19.9217137 },
    { lat: 49.12084, lng: 19.9217873 },
    { lat: 49.1209221, lng: 19.921866 },
    { lat: 49.1210247, lng: 19.9219465 },
    { lat: 49.1210792, lng: 19.9221207 },
    { lat: 49.1211103, lng: 19.9221367 },
    { lat: 49.1211874, lng: 19.922145 },
    { lat: 49.1212687, lng: 19.9222589 },
    { lat: 49.1214228, lng: 19.9222255 },
    { lat: 49.1216924, lng: 19.9223455 },
    { lat: 49.1217334, lng: 19.9225155 },
    { lat: 49.1218282, lng: 19.9226267 },
    { lat: 49.1219437, lng: 19.9226395 },
    { lat: 49.1220388, lng: 19.9229974 },
    { lat: 49.1221934, lng: 19.9231376 },
    { lat: 49.122346, lng: 19.9234212 },
    { lat: 49.1224864, lng: 19.9234931 },
    { lat: 49.122482, lng: 19.9236528 },
    { lat: 49.1225202, lng: 19.9237289 },
    { lat: 49.1225102, lng: 19.9238665 },
    { lat: 49.1225244, lng: 19.9239133 },
    { lat: 49.1227498, lng: 19.924047 },
    { lat: 49.1228663, lng: 19.9242755 },
    { lat: 49.1229289, lng: 19.9242881 },
    { lat: 49.122972, lng: 19.9241335 },
    { lat: 49.1230788, lng: 19.924159 },
    { lat: 49.1232431, lng: 19.924075 },
    { lat: 49.1233109, lng: 19.9240784 },
    { lat: 49.1233131, lng: 19.9244263 },
    { lat: 49.1233285, lng: 19.9244734 },
    { lat: 49.1234328, lng: 19.9245117 },
    { lat: 49.1234945, lng: 19.9243227 },
    { lat: 49.1235757, lng: 19.9242949 },
    { lat: 49.1236573, lng: 19.9243614 },
    { lat: 49.1237159, lng: 19.9245243 },
    { lat: 49.1238465, lng: 19.9244925 },
    { lat: 49.1239528, lng: 19.9245073 },
    { lat: 49.1239807, lng: 19.9245801 },
    { lat: 49.1239746, lng: 19.9249673 },
    { lat: 49.1239873, lng: 19.9250293 },
    { lat: 49.1240224, lng: 19.9250327 },
    { lat: 49.124099, lng: 19.9248458 },
    { lat: 49.1242123, lng: 19.9247283 },
    { lat: 49.124401, lng: 19.9250105 },
    { lat: 49.1244465, lng: 19.9250215 },
    { lat: 49.1245343, lng: 19.9249133 },
    { lat: 49.1246497, lng: 19.9250325 },
    { lat: 49.1246358, lng: 19.9251839 },
    { lat: 49.1246791, lng: 19.9253037 },
    { lat: 49.1247023, lng: 19.9254507 },
    { lat: 49.1247286, lng: 19.925487 },
    { lat: 49.1247787, lng: 19.9254504 },
    { lat: 49.1248821, lng: 19.9250958 },
    { lat: 49.1249283, lng: 19.9251229 },
    { lat: 49.1249892, lng: 19.9250724 },
    { lat: 49.1249932, lng: 19.9251389 },
    { lat: 49.1250954, lng: 19.925263 },
    { lat: 49.1251738, lng: 19.9252528 },
    { lat: 49.1252703, lng: 19.9254876 },
    { lat: 49.1254172, lng: 19.9255797 },
    { lat: 49.1254667, lng: 19.9253868 },
    { lat: 49.1255299, lng: 19.9253403 },
    { lat: 49.1256343, lng: 19.9253607 },
    { lat: 49.125689, lng: 19.9254808 },
    { lat: 49.1257211, lng: 19.9254921 },
    { lat: 49.1258038, lng: 19.9254513 },
    { lat: 49.1260084, lng: 19.9255503 },
    { lat: 49.1260493, lng: 19.9256632 },
    { lat: 49.1263419, lng: 19.9256288 },
    { lat: 49.1264297, lng: 19.9257062 },
    { lat: 49.1265492, lng: 19.9256855 },
    { lat: 49.126691, lng: 19.9258986 },
    { lat: 49.1267407, lng: 19.9258456 },
    { lat: 49.126887, lng: 19.9258942 },
    { lat: 49.1269633, lng: 19.9260263 },
    { lat: 49.1271809, lng: 19.9261658 },
    { lat: 49.1272708, lng: 19.9262922 },
    { lat: 49.1272895, lng: 19.9263907 },
    { lat: 49.1274875, lng: 19.9265534 },
    { lat: 49.1277237, lng: 19.9267475 },
    { lat: 49.1279329, lng: 19.9270637 },
    { lat: 49.12796, lng: 19.9271733 },
    { lat: 49.1280564, lng: 19.9272376 },
    { lat: 49.1280664, lng: 19.9272121 },
    { lat: 49.1285408, lng: 19.9270259 },
    { lat: 49.1289721, lng: 19.9268668 },
    { lat: 49.1295831, lng: 19.9265834 },
    { lat: 49.1298894, lng: 19.9265038 },
    { lat: 49.1303002, lng: 19.9263043 },
    { lat: 49.1306495, lng: 19.9260981 },
    { lat: 49.1307227, lng: 19.9260681 },
    { lat: 49.1312542, lng: 19.9257428 },
    { lat: 49.1315991, lng: 19.9254023 },
    { lat: 49.1317988, lng: 19.9251961 },
    { lat: 49.1323781, lng: 19.9246704 },
    { lat: 49.1326528, lng: 19.9233959 },
    { lat: 49.1324648, lng: 19.9218315 },
    { lat: 49.1326208, lng: 19.9201102 },
    { lat: 49.132569, lng: 19.91928 },
  ],
  Ižipovce: [
    { lat: 49.12992, lng: 19.4904284 },
    { lat: 49.132748, lng: 19.4957082 },
    { lat: 49.133092, lng: 19.4986424 },
    { lat: 49.1331149, lng: 19.4987497 },
    { lat: 49.1331314, lng: 19.4993575 },
    { lat: 49.1341861, lng: 19.4987642 },
    { lat: 49.1343434, lng: 19.498388 },
    { lat: 49.1348996, lng: 19.4984268 },
    { lat: 49.1349966, lng: 19.4985769 },
    { lat: 49.1353131, lng: 19.4985735 },
    { lat: 49.1355897, lng: 19.4983375 },
    { lat: 49.1360063, lng: 19.4981864 },
    { lat: 49.1363602, lng: 19.4978644 },
    { lat: 49.1366782, lng: 19.4973279 },
    { lat: 49.1370498, lng: 19.4968317 },
    { lat: 49.1374766, lng: 19.4965311 },
    { lat: 49.1378367, lng: 19.4961747 },
    { lat: 49.1381721, lng: 19.4957752 },
    { lat: 49.1382851, lng: 19.4957578 },
    { lat: 49.1388289, lng: 19.4953559 },
    { lat: 49.1389264, lng: 19.4948503 },
    { lat: 49.1389415, lng: 19.4948098 },
    { lat: 49.1389701, lng: 19.4947946 },
    { lat: 49.1389977, lng: 19.4947589 },
    { lat: 49.1390148, lng: 19.4947135 },
    { lat: 49.1390406, lng: 19.4946902 },
    { lat: 49.1391212, lng: 19.4948713 },
    { lat: 49.1391932, lng: 19.4948187 },
    { lat: 49.1392365, lng: 19.4947604 },
    { lat: 49.139381, lng: 19.4947397 },
    { lat: 49.1394432, lng: 19.4946694 },
    { lat: 49.1396864, lng: 19.4949368 },
    { lat: 49.1399483, lng: 19.4949496 },
    { lat: 49.1401252, lng: 19.4947019 },
    { lat: 49.1403689, lng: 19.4947331 },
    { lat: 49.1404684, lng: 19.494467 },
    { lat: 49.140715, lng: 19.4944181 },
    { lat: 49.1410243, lng: 19.4940361 },
    { lat: 49.1411679, lng: 19.4935781 },
    { lat: 49.1416329, lng: 19.4936481 },
    { lat: 49.1417305, lng: 19.49351 },
    { lat: 49.1420168, lng: 19.4934161 },
    { lat: 49.142621, lng: 19.4935433 },
    { lat: 49.143045, lng: 19.4935273 },
    { lat: 49.1433807, lng: 19.4932788 },
    { lat: 49.1439104, lng: 19.4929406 },
    { lat: 49.1442541, lng: 19.49299 },
    { lat: 49.1446326, lng: 19.4928133 },
    { lat: 49.1452374, lng: 19.492831 },
    { lat: 49.1459246, lng: 19.4926578 },
    { lat: 49.1459772, lng: 19.4889377 },
    { lat: 49.1471308, lng: 19.4882939 },
    { lat: 49.147811, lng: 19.4874011 },
    { lat: 49.1481579, lng: 19.4872022 },
    { lat: 49.1485118, lng: 19.4870309 },
    { lat: 49.1492373, lng: 19.4868982 },
    { lat: 49.1496477, lng: 19.4868126 },
    { lat: 49.1505123, lng: 19.4864771 },
    { lat: 49.150561, lng: 19.4864773 },
    { lat: 49.1514185, lng: 19.4862602 },
    { lat: 49.1528128, lng: 19.4848444 },
    { lat: 49.1529833, lng: 19.4843366 },
    { lat: 49.1531009, lng: 19.4839515 },
    { lat: 49.1535659, lng: 19.4837007 },
    { lat: 49.1545488, lng: 19.4831635 },
    { lat: 49.1557818, lng: 19.4825106 },
    { lat: 49.1558555, lng: 19.4824448 },
    { lat: 49.157221, lng: 19.4811646 },
    { lat: 49.1588119, lng: 19.479887 },
    { lat: 49.1593391, lng: 19.4796856 },
    { lat: 49.1599959, lng: 19.4793645 },
    { lat: 49.1616658, lng: 19.4787739 },
    { lat: 49.1621929, lng: 19.4785243 },
    { lat: 49.1622437, lng: 19.4785034 },
    { lat: 49.162659, lng: 19.4782466 },
    { lat: 49.1643285, lng: 19.4733834 },
    { lat: 49.1659513, lng: 19.4721382 },
    { lat: 49.1683043, lng: 19.4719957 },
    { lat: 49.1667499, lng: 19.4705624 },
    { lat: 49.1652252, lng: 19.470705 },
    { lat: 49.1639612, lng: 19.4708583 },
    { lat: 49.1633767, lng: 19.4703274 },
    { lat: 49.1631126, lng: 19.4701447 },
    { lat: 49.16282, lng: 19.4698375 },
    { lat: 49.1618865, lng: 19.4697001 },
    { lat: 49.161306, lng: 19.4698476 },
    { lat: 49.1607734, lng: 19.4704403 },
    { lat: 49.1598218, lng: 19.4710404 },
    { lat: 49.1591928, lng: 19.4713078 },
    { lat: 49.158866, lng: 19.4715991 },
    { lat: 49.1580212, lng: 19.4719959 },
    { lat: 49.1576188, lng: 19.4723122 },
    { lat: 49.1563613, lng: 19.4736858 },
    { lat: 49.155746, lng: 19.474545 },
    { lat: 49.1556275, lng: 19.4747823 },
    { lat: 49.154399, lng: 19.4757585 },
    { lat: 49.1537511, lng: 19.4770071 },
    { lat: 49.1535681, lng: 19.4770479 },
    { lat: 49.1531394, lng: 19.4769885 },
    { lat: 49.1527024, lng: 19.4772365 },
    { lat: 49.1526235, lng: 19.477211 },
    { lat: 49.1526039, lng: 19.477252 },
    { lat: 49.1510861, lng: 19.4787734 },
    { lat: 49.150469, lng: 19.4791507 },
    { lat: 49.1502994, lng: 19.4797955 },
    { lat: 49.1495503, lng: 19.4796811 },
    { lat: 49.1488795, lng: 19.4795274 },
    { lat: 49.1486844, lng: 19.4794751 },
    { lat: 49.1486166, lng: 19.4794687 },
    { lat: 49.1478106, lng: 19.4793157 },
    { lat: 49.1473882, lng: 19.4792197 },
    { lat: 49.146712, lng: 19.4791944 },
    { lat: 49.1461509, lng: 19.4790689 },
    { lat: 49.1436537, lng: 19.4786027 },
    { lat: 49.1430127, lng: 19.4783113 },
    { lat: 49.1423673, lng: 19.4777029 },
    { lat: 49.1412668, lng: 19.4767806 },
    { lat: 49.141243, lng: 19.4768739 },
    { lat: 49.1410793, lng: 19.4775043 },
    { lat: 49.1407421, lng: 19.4785516 },
    { lat: 49.140636, lng: 19.4789922 },
    { lat: 49.1406477, lng: 19.4791738 },
    { lat: 49.1409124, lng: 19.479513 },
    { lat: 49.1411565, lng: 19.4799966 },
    { lat: 49.141258, lng: 19.4801948 },
    { lat: 49.1413681, lng: 19.4805509 },
    { lat: 49.141573, lng: 19.4810174 },
    { lat: 49.141865, lng: 19.4816326 },
    { lat: 49.1422642, lng: 19.4827219 },
    { lat: 49.1425117, lng: 19.4835449 },
    { lat: 49.1426555, lng: 19.4840288 },
    { lat: 49.1425931, lng: 19.4840685 },
    { lat: 49.1424664, lng: 19.4846672 },
    { lat: 49.1402901, lng: 19.4855439 },
    { lat: 49.1397083, lng: 19.4842362 },
    { lat: 49.1387069, lng: 19.4822302 },
    { lat: 49.1370685, lng: 19.4839689 },
    { lat: 49.135439, lng: 19.4861755 },
    { lat: 49.1351885, lng: 19.486439 },
    { lat: 49.1348285, lng: 19.4867685 },
    { lat: 49.1344813, lng: 19.4870865 },
    { lat: 49.1342402, lng: 19.4873072 },
    { lat: 49.1339742, lng: 19.4875501 },
    { lat: 49.1325677, lng: 19.4886279 },
    { lat: 49.1323458, lng: 19.488779 },
    { lat: 49.12992, lng: 19.4904284 },
  ],
  Jakubovany: [
    { lat: 49.1095244, lng: 19.7201795 },
    { lat: 49.1088234, lng: 19.7198657 },
    { lat: 49.1070295, lng: 19.7194202 },
    { lat: 49.1062122, lng: 19.7191102 },
    { lat: 49.1055836, lng: 19.7188717 },
    { lat: 49.1044712, lng: 19.7185007 },
    { lat: 49.1037502, lng: 19.7181657 },
    { lat: 49.1034731, lng: 19.7179106 },
    { lat: 49.1027799, lng: 19.7174674 },
    { lat: 49.1024586, lng: 19.7174336 },
    { lat: 49.1024489, lng: 19.7174724 },
    { lat: 49.1024413, lng: 19.717631 },
    { lat: 49.1022956, lng: 19.7182916 },
    { lat: 49.102221, lng: 19.7188504 },
    { lat: 49.1021666, lng: 19.7191223 },
    { lat: 49.1019599, lng: 19.7196255 },
    { lat: 49.1017969, lng: 19.7200419 },
    { lat: 49.1016398, lng: 19.7203678 },
    { lat: 49.1015773, lng: 19.720509 },
    { lat: 49.1014923, lng: 19.7206762 },
    { lat: 49.1014774, lng: 19.7207985 },
    { lat: 49.1014626, lng: 19.7208216 },
    { lat: 49.1014523, lng: 19.7208457 },
    { lat: 49.1011021, lng: 19.7204121 },
    { lat: 49.1001513, lng: 19.7192057 },
    { lat: 49.0999583, lng: 19.7189643 },
    { lat: 49.0998865, lng: 19.7188192 },
    { lat: 49.0998386, lng: 19.7187547 },
    { lat: 49.0997262, lng: 19.7184742 },
    { lat: 49.099697, lng: 19.7184818 },
    { lat: 49.0995257, lng: 19.7186348 },
    { lat: 49.0994209, lng: 19.7187591 },
    { lat: 49.0993735, lng: 19.7187823 },
    { lat: 49.0991631, lng: 19.7190482 },
    { lat: 49.0991464, lng: 19.7191534 },
    { lat: 49.0990321, lng: 19.719448 },
    { lat: 49.0984866, lng: 19.7207557 },
    { lat: 49.0981024, lng: 19.721626 },
    { lat: 49.0980927, lng: 19.7216083 },
    { lat: 49.0980643, lng: 19.7215806 },
    { lat: 49.0980181, lng: 19.7215771 },
    { lat: 49.0979833, lng: 19.7215314 },
    { lat: 49.0969589, lng: 19.7200071 },
    { lat: 49.0960245, lng: 19.7185593 },
    { lat: 49.0958839, lng: 19.7187192 },
    { lat: 49.0955178, lng: 19.7192076 },
    { lat: 49.0946671, lng: 19.7205136 },
    { lat: 49.0941767, lng: 19.7218674 },
    { lat: 49.0930372, lng: 19.7234489 },
    { lat: 49.0916457, lng: 19.7251919 },
    { lat: 49.0914674, lng: 19.7251845 },
    { lat: 49.0913147, lng: 19.7250943 },
    { lat: 49.0911895, lng: 19.7249579 },
    { lat: 49.0909196, lng: 19.7244817 },
    { lat: 49.0908904, lng: 19.7245598 },
    { lat: 49.0907528, lng: 19.7246612 },
    { lat: 49.0904871, lng: 19.7255901 },
    { lat: 49.0899061, lng: 19.7265215 },
    { lat: 49.0898551, lng: 19.7265914 },
    { lat: 49.089943, lng: 19.726695 },
    { lat: 49.0899938, lng: 19.726825 },
    { lat: 49.0902388, lng: 19.7272497 },
    { lat: 49.0904252, lng: 19.7275446 },
    { lat: 49.0906185, lng: 19.727764 },
    { lat: 49.0906436, lng: 19.7278736 },
    { lat: 49.0907254, lng: 19.7280425 },
    { lat: 49.0908092, lng: 19.7280807 },
    { lat: 49.0909396, lng: 19.728561 },
    { lat: 49.0909937, lng: 19.7284606 },
    { lat: 49.091092, lng: 19.7285908 },
    { lat: 49.0911977, lng: 19.7286926 },
    { lat: 49.0911795, lng: 19.7288421 },
    { lat: 49.0911384, lng: 19.7289333 },
    { lat: 49.0911337, lng: 19.7290862 },
    { lat: 49.091252, lng: 19.7290598 },
    { lat: 49.0913472, lng: 19.7292755 },
    { lat: 49.0915616, lng: 19.7295406 },
    { lat: 49.0915637, lng: 19.7296647 },
    { lat: 49.0917345, lng: 19.7297865 },
    { lat: 49.0918935, lng: 19.7299932 },
    { lat: 49.0920243, lng: 19.7302035 },
    { lat: 49.0921723, lng: 19.7305483 },
    { lat: 49.092222, lng: 19.7305511 },
    { lat: 49.0923521, lng: 19.7307141 },
    { lat: 49.0923463, lng: 19.7308674 },
    { lat: 49.0925165, lng: 19.7309691 },
    { lat: 49.0926611, lng: 19.7312621 },
    { lat: 49.0927268, lng: 19.7312698 },
    { lat: 49.0928974, lng: 19.7312579 },
    { lat: 49.0929973, lng: 19.7315058 },
    { lat: 49.093234, lng: 19.7318927 },
    { lat: 49.0935172, lng: 19.7322403 },
    { lat: 49.0937095, lng: 19.7327452 },
    { lat: 49.0937397, lng: 19.7327389 },
    { lat: 49.0939701, lng: 19.7330621 },
    { lat: 49.0941877, lng: 19.7333261 },
    { lat: 49.0946027, lng: 19.7335622 },
    { lat: 49.0946708, lng: 19.7335754 },
    { lat: 49.0947546, lng: 19.7338466 },
    { lat: 49.0949322, lng: 19.7343214 },
    { lat: 49.0949954, lng: 19.7346874 },
    { lat: 49.0950868, lng: 19.7348173 },
    { lat: 49.0951976, lng: 19.7351651 },
    { lat: 49.0952414, lng: 19.7353897 },
    { lat: 49.0952228, lng: 19.7356116 },
    { lat: 49.09531, lng: 19.7356987 },
    { lat: 49.0953351, lng: 19.7359385 },
    { lat: 49.0953236, lng: 19.7361614 },
    { lat: 49.0953457, lng: 19.7363314 },
    { lat: 49.0957235, lng: 19.7356697 },
    { lat: 49.0964343, lng: 19.7345647 },
    { lat: 49.0965512, lng: 19.7333248 },
    { lat: 49.0967594, lng: 19.7328281 },
    { lat: 49.0971272, lng: 19.7319667 },
    { lat: 49.0972932, lng: 19.7316648 },
    { lat: 49.0974217, lng: 19.7319516 },
    { lat: 49.0978163, lng: 19.7329098 },
    { lat: 49.0978832, lng: 19.7331277 },
    { lat: 49.098181, lng: 19.7339819 },
    { lat: 49.0989964, lng: 19.7354712 },
    { lat: 49.099242, lng: 19.7357796 },
    { lat: 49.0995098, lng: 19.7362154 },
    { lat: 49.0998528, lng: 19.7368181 },
    { lat: 49.1000961, lng: 19.7371996 },
    { lat: 49.1003993, lng: 19.7375629 },
    { lat: 49.1005943, lng: 19.7377736 },
    { lat: 49.1009551, lng: 19.7381928 },
    { lat: 49.1012416, lng: 19.7386164 },
    { lat: 49.1014467, lng: 19.7388476 },
    { lat: 49.1016651, lng: 19.7390002 },
    { lat: 49.101979, lng: 19.7391431 },
    { lat: 49.1019811, lng: 19.7390897 },
    { lat: 49.1025589, lng: 19.7389854 },
    { lat: 49.1030239, lng: 19.7387728 },
    { lat: 49.1034752, lng: 19.7385005 },
    { lat: 49.103557, lng: 19.738494 },
    { lat: 49.1037745, lng: 19.7385705 },
    { lat: 49.1040304, lng: 19.7387538 },
    { lat: 49.1046767, lng: 19.7397777 },
    { lat: 49.1055272, lng: 19.7409656 },
    { lat: 49.1061836, lng: 19.7419619 },
    { lat: 49.1062314, lng: 19.7419974 },
    { lat: 49.1062543, lng: 19.7419551 },
    { lat: 49.1076026, lng: 19.7416383 },
    { lat: 49.1076575, lng: 19.7416083 },
    { lat: 49.1077405, lng: 19.7415215 },
    { lat: 49.107855, lng: 19.7413319 },
    { lat: 49.107929, lng: 19.7412208 },
    { lat: 49.1080368, lng: 19.7410847 },
    { lat: 49.1082078, lng: 19.7408491 },
    { lat: 49.1082662, lng: 19.7407914 },
    { lat: 49.1083792, lng: 19.7407235 },
    { lat: 49.1086192, lng: 19.74088 },
    { lat: 49.1089827, lng: 19.7413326 },
    { lat: 49.1092843, lng: 19.7418654 },
    { lat: 49.1111344, lng: 19.7443777 },
    { lat: 49.1114961, lng: 19.7449207 },
    { lat: 49.112203, lng: 19.7460149 },
    { lat: 49.1124302, lng: 19.7464719 },
    { lat: 49.1130941, lng: 19.7473069 },
    { lat: 49.1133379, lng: 19.7478846 },
    { lat: 49.1136573, lng: 19.7493939 },
    { lat: 49.1140382, lng: 19.7503813 },
    { lat: 49.113828, lng: 19.7505461 },
    { lat: 49.1138542, lng: 19.750579 },
    { lat: 49.1141271, lng: 19.7509747 },
    { lat: 49.1142448, lng: 19.7517856 },
    { lat: 49.1148809, lng: 19.7533265 },
    { lat: 49.114977, lng: 19.7535437 },
    { lat: 49.1151018, lng: 19.7541313 },
    { lat: 49.1152061, lng: 19.7543534 },
    { lat: 49.1152232, lng: 19.7543549 },
    { lat: 49.1151655, lng: 19.7545309 },
    { lat: 49.1150246, lng: 19.7549982 },
    { lat: 49.1147124, lng: 19.7555106 },
    { lat: 49.1145456, lng: 19.7561561 },
    { lat: 49.1144262, lng: 19.7563099 },
    { lat: 49.1141371, lng: 19.7566038 },
    { lat: 49.1141637, lng: 19.757371 },
    { lat: 49.1150121, lng: 19.7578099 },
    { lat: 49.1159862, lng: 19.7584537 },
    { lat: 49.1162763, lng: 19.7587236 },
    { lat: 49.1173014, lng: 19.75955 },
    { lat: 49.1173609, lng: 19.7599572 },
    { lat: 49.1173084, lng: 19.7600474 },
    { lat: 49.1173024, lng: 19.7600872 },
    { lat: 49.1177009, lng: 19.7605012 },
    { lat: 49.1181383, lng: 19.7608968 },
    { lat: 49.1185827, lng: 19.7611119 },
    { lat: 49.1190026, lng: 19.7613655 },
    { lat: 49.1196542, lng: 19.7618917 },
    { lat: 49.1200309, lng: 19.7621899 },
    { lat: 49.1201951, lng: 19.7623891 },
    { lat: 49.1206341, lng: 19.763054 },
    { lat: 49.1207252, lng: 19.763184 },
    { lat: 49.1209151, lng: 19.7634313 },
    { lat: 49.1210007, lng: 19.763532 },
    { lat: 49.121228, lng: 19.7637586 },
    { lat: 49.1214227, lng: 19.7639911 },
    { lat: 49.1215943, lng: 19.7640997 },
    { lat: 49.1218228, lng: 19.7642021 },
    { lat: 49.1219708, lng: 19.7643166 },
    { lat: 49.1221699, lng: 19.7646163 },
    { lat: 49.1229898, lng: 19.7655194 },
    { lat: 49.1238089, lng: 19.7661711 },
    { lat: 49.1244805, lng: 19.7666907 },
    { lat: 49.1250561, lng: 19.7670801 },
    { lat: 49.1255937, lng: 19.767347 },
    { lat: 49.1258419, lng: 19.7674033 },
    { lat: 49.1256212, lng: 19.7668403 },
    { lat: 49.1254776, lng: 19.7664084 },
    { lat: 49.1253081, lng: 19.7656209 },
    { lat: 49.125167, lng: 19.7651729 },
    { lat: 49.125117, lng: 19.7644888 },
    { lat: 49.1248661, lng: 19.763747 },
    { lat: 49.1248133, lng: 19.7633631 },
    { lat: 49.124757, lng: 19.7625624 },
    { lat: 49.1246494, lng: 19.7617431 },
    { lat: 49.1245575, lng: 19.7610316 },
    { lat: 49.1244766, lng: 19.7604624 },
    { lat: 49.1244016, lng: 19.7600093 },
    { lat: 49.1242694, lng: 19.759277 },
    { lat: 49.1241687, lng: 19.7586174 },
    { lat: 49.1239759, lng: 19.7576593 },
    { lat: 49.1238616, lng: 19.7572849 },
    { lat: 49.1236612, lng: 19.7564614 },
    { lat: 49.123558, lng: 19.75621 },
    { lat: 49.1234112, lng: 19.7559669 },
    { lat: 49.1233056, lng: 19.7558197 },
    { lat: 49.1231232, lng: 19.7556702 },
    { lat: 49.1229733, lng: 19.7555875 },
    { lat: 49.1226743, lng: 19.7555519 },
    { lat: 49.1224378, lng: 19.7554773 },
    { lat: 49.1222588, lng: 19.7554008 },
    { lat: 49.1220707, lng: 19.7552497 },
    { lat: 49.1235989, lng: 19.7517979 },
    { lat: 49.1236349, lng: 19.751718 },
    { lat: 49.1237058, lng: 19.7519259 },
    { lat: 49.1240565, lng: 19.7522911 },
    { lat: 49.1242539, lng: 19.7524564 },
    { lat: 49.1244972, lng: 19.7526102 },
    { lat: 49.1245618, lng: 19.7529007 },
    { lat: 49.1245519, lng: 19.7534283 },
    { lat: 49.1248544, lng: 19.7534124 },
    { lat: 49.1250067, lng: 19.7536251 },
    { lat: 49.125173, lng: 19.7537683 },
    { lat: 49.1253158, lng: 19.753633 },
    { lat: 49.1255668, lng: 19.7536835 },
    { lat: 49.1257489, lng: 19.7536964 },
    { lat: 49.1261031, lng: 19.7537375 },
    { lat: 49.1262973, lng: 19.7539516 },
    { lat: 49.1263435, lng: 19.7539775 },
    { lat: 49.1265873, lng: 19.7538572 },
    { lat: 49.1267814, lng: 19.753994 },
    { lat: 49.1269563, lng: 19.7541335 },
    { lat: 49.1271554, lng: 19.7540501 },
    { lat: 49.1275491, lng: 19.754216 },
    { lat: 49.1277892, lng: 19.7541942 },
    { lat: 49.1280032, lng: 19.7542409 },
    { lat: 49.1280998, lng: 19.7540318 },
    { lat: 49.1282903, lng: 19.7538787 },
    { lat: 49.1283159, lng: 19.753919 },
    { lat: 49.1285899, lng: 19.7540042 },
    { lat: 49.1286773, lng: 19.7541529 },
    { lat: 49.1287919, lng: 19.7541435 },
    { lat: 49.1289977, lng: 19.7542403 },
    { lat: 49.1291117, lng: 19.7545809 },
    { lat: 49.129249, lng: 19.7549095 },
    { lat: 49.1293018, lng: 19.7552721 },
    { lat: 49.1295146, lng: 19.7554754 },
    { lat: 49.1298133, lng: 19.7555855 },
    { lat: 49.1299781, lng: 19.7558735 },
    { lat: 49.1301693, lng: 19.7558937 },
    { lat: 49.1303611, lng: 19.7561081 },
    { lat: 49.130583, lng: 19.7566501 },
    { lat: 49.130772, lng: 19.7567731 },
    { lat: 49.1310195, lng: 19.7571279 },
    { lat: 49.1314646, lng: 19.7578567 },
    { lat: 49.1315847, lng: 19.7580237 },
    { lat: 49.1325546, lng: 19.7591675 },
    { lat: 49.1326965, lng: 19.7597089 },
    { lat: 49.1331377, lng: 19.7602414 },
    { lat: 49.1333434, lng: 19.7609052 },
    { lat: 49.1335649, lng: 19.7611512 },
    { lat: 49.1338341, lng: 19.761669 },
    { lat: 49.1339637, lng: 19.7619574 },
    { lat: 49.1342133, lng: 19.7623056 },
    { lat: 49.1342368, lng: 19.7623046 },
    { lat: 49.1341862, lng: 19.7619162 },
    { lat: 49.1340369, lng: 19.7615442 },
    { lat: 49.1338562, lng: 19.7608591 },
    { lat: 49.1337996, lng: 19.7599201 },
    { lat: 49.1337502, lng: 19.7593279 },
    { lat: 49.1337663, lng: 19.7587662 },
    { lat: 49.1337808, lng: 19.7581171 },
    { lat: 49.133767, lng: 19.7575408 },
    { lat: 49.1338133, lng: 19.7571022 },
    { lat: 49.1338445, lng: 19.7568638 },
    { lat: 49.1338768, lng: 19.7550693 },
    { lat: 49.1338707, lng: 19.7550041 },
    { lat: 49.1340519, lng: 19.7541252 },
    { lat: 49.1341032, lng: 19.7536969 },
    { lat: 49.1341925, lng: 19.7521546 },
    { lat: 49.1343528, lng: 19.7507425 },
    { lat: 49.1344606, lng: 19.7501401 },
    { lat: 49.1344525, lng: 19.7493045 },
    { lat: 49.1344695, lng: 19.7489747 },
    { lat: 49.1344775, lng: 19.7489739 },
    { lat: 49.1395271, lng: 19.748626 },
    { lat: 49.1437446, lng: 19.7483357 },
    { lat: 49.1442089, lng: 19.7481616 },
    { lat: 49.1443538, lng: 19.7481491 },
    { lat: 49.1450279, lng: 19.7485734 },
    { lat: 49.1463609, lng: 19.7490716 },
    { lat: 49.1468046, lng: 19.7487067 },
    { lat: 49.1470154, lng: 19.7485274 },
    { lat: 49.1491058, lng: 19.7483667 },
    { lat: 49.1507586, lng: 19.7473624 },
    { lat: 49.1507297, lng: 19.7457981 },
    { lat: 49.1510627, lng: 19.7443669 },
    { lat: 49.1521064, lng: 19.7398855 },
    { lat: 49.1518069, lng: 19.7396319 },
    { lat: 49.1515123, lng: 19.739335 },
    { lat: 49.1511537, lng: 19.7394816 },
    { lat: 49.1504685, lng: 19.7391643 },
    { lat: 49.1500282, lng: 19.739557 },
    { lat: 49.1493792, lng: 19.7395293 },
    { lat: 49.1486058, lng: 19.7396946 },
    { lat: 49.148027, lng: 19.7400646 },
    { lat: 49.1477917, lng: 19.7400932 },
    { lat: 49.1474325, lng: 19.7401689 },
    { lat: 49.1471082, lng: 19.7400733 },
    { lat: 49.1467448, lng: 19.7401365 },
    { lat: 49.1464291, lng: 19.7401027 },
    { lat: 49.1458353, lng: 19.7403799 },
    { lat: 49.1451373, lng: 19.7403928 },
    { lat: 49.1444846, lng: 19.7412849 },
    { lat: 49.1442088, lng: 19.7411852 },
    { lat: 49.1435563, lng: 19.7405907 },
    { lat: 49.1432643, lng: 19.7407909 },
    { lat: 49.143026, lng: 19.740696 },
    { lat: 49.1427179, lng: 19.7408673 },
    { lat: 49.1422624, lng: 19.7407782 },
    { lat: 49.1419397, lng: 19.7409732 },
    { lat: 49.1416422, lng: 19.7410484 },
    { lat: 49.1414162, lng: 19.7413153 },
    { lat: 49.1411464, lng: 19.7414074 },
    { lat: 49.1405991, lng: 19.7412053 },
    { lat: 49.1399065, lng: 19.741389 },
    { lat: 49.1396588, lng: 19.7413457 },
    { lat: 49.1394755, lng: 19.7415032 },
    { lat: 49.1391796, lng: 19.7414631 },
    { lat: 49.1387813, lng: 19.7419119 },
    { lat: 49.1385285, lng: 19.7420314 },
    { lat: 49.1380232, lng: 19.7420647 },
    { lat: 49.1376964, lng: 19.7425832 },
    { lat: 49.1369702, lng: 19.7428235 },
    { lat: 49.1364328, lng: 19.742411 },
    { lat: 49.1360787, lng: 19.7426336 },
    { lat: 49.1353937, lng: 19.7424239 },
    { lat: 49.1347774, lng: 19.7427588 },
    { lat: 49.1345023, lng: 19.7429572 },
    { lat: 49.1344166, lng: 19.7427522 },
    { lat: 49.1343509, lng: 19.7424106 },
    { lat: 49.1345754, lng: 19.7388064 },
    { lat: 49.1348707, lng: 19.7373136 },
    { lat: 49.1351206, lng: 19.7366647 },
    { lat: 49.1353331, lng: 19.7356225 },
    { lat: 49.1354076, lng: 19.7350094 },
    { lat: 49.1356193, lng: 19.7342104 },
    { lat: 49.1358691, lng: 19.7327315 },
    { lat: 49.1359139, lng: 19.7322884 },
    { lat: 49.136087, lng: 19.7319244 },
    { lat: 49.1356889, lng: 19.731473 },
    { lat: 49.1345185, lng: 19.731705 },
    { lat: 49.1333001, lng: 19.7317202 },
    { lat: 49.1318524, lng: 19.7316155 },
    { lat: 49.1314155, lng: 19.7319013 },
    { lat: 49.1302793, lng: 19.7313466 },
    { lat: 49.129439, lng: 19.7314025 },
    { lat: 49.1280904, lng: 19.7316026 },
    { lat: 49.1264663, lng: 19.7309957 },
    { lat: 49.1247291, lng: 19.7307097 },
    { lat: 49.1236453, lng: 19.7308868 },
    { lat: 49.1222972, lng: 19.730949 },
    { lat: 49.1218046, lng: 19.7309649 },
    { lat: 49.1204094, lng: 19.7301568 },
    { lat: 49.1189339, lng: 19.7293951 },
    { lat: 49.1187494, lng: 19.7293491 },
    { lat: 49.118107, lng: 19.7290569 },
    { lat: 49.1181201, lng: 19.7287125 },
    { lat: 49.1174912, lng: 19.7283995 },
    { lat: 49.1167149, lng: 19.727729 },
    { lat: 49.1162401, lng: 19.7274316 },
    { lat: 49.1155801, lng: 19.7268935 },
    { lat: 49.1151766, lng: 19.7264041 },
    { lat: 49.1151595, lng: 19.7263745 },
    { lat: 49.1143578, lng: 19.7254837 },
    { lat: 49.1141538, lng: 19.7251714 },
    { lat: 49.1131749, lng: 19.7234298 },
    { lat: 49.1127019, lng: 19.7226609 },
    { lat: 49.1121465, lng: 19.7219687 },
    { lat: 49.1117581, lng: 19.7216378 },
    { lat: 49.1114368, lng: 19.7214292 },
    { lat: 49.1101002, lng: 19.7208001 },
    { lat: 49.1101461, lng: 19.7206135 },
    { lat: 49.1100315, lng: 19.72044 },
    { lat: 49.1098865, lng: 19.7203192 },
    { lat: 49.1095244, lng: 19.7201795 },
  ],
  Jalovec: [
    { lat: 49.127434, lng: 19.6258546 },
    { lat: 49.1269602, lng: 19.6281641 },
    { lat: 49.1259371, lng: 19.6277068 },
    { lat: 49.1257766, lng: 19.6308471 },
    { lat: 49.128145, lng: 19.6314071 },
    { lat: 49.1297403, lng: 19.631858 },
    { lat: 49.1297311, lng: 19.631933 },
    { lat: 49.128786, lng: 19.6367302 },
    { lat: 49.1289707, lng: 19.638199 },
    { lat: 49.128928, lng: 19.6383531 },
    { lat: 49.1276232, lng: 19.6424439 },
    { lat: 49.1303998, lng: 19.6432126 },
    { lat: 49.1310064, lng: 19.6469681 },
    { lat: 49.1317397, lng: 19.6472719 },
    { lat: 49.1332152, lng: 19.647971 },
    { lat: 49.1343119, lng: 19.6486424 },
    { lat: 49.1353588, lng: 19.6492276 },
    { lat: 49.1366611, lng: 19.6499609 },
    { lat: 49.1370773, lng: 19.6501737 },
    { lat: 49.1380071, lng: 19.6506379 },
    { lat: 49.1394408, lng: 19.6515121 },
    { lat: 49.1407249, lng: 19.6521395 },
    { lat: 49.141917, lng: 19.6528289 },
    { lat: 49.1430593, lng: 19.653485 },
    { lat: 49.1439868, lng: 19.6540784 },
    { lat: 49.1462973, lng: 19.6556198 },
    { lat: 49.1467919, lng: 19.6559484 },
    { lat: 49.1479975, lng: 19.6567665 },
    { lat: 49.1489826, lng: 19.6574088 },
    { lat: 49.1502093, lng: 19.6586222 },
    { lat: 49.1511804, lng: 19.659687 },
    { lat: 49.1511856, lng: 19.6596775 },
    { lat: 49.1518788, lng: 19.6601415 },
    { lat: 49.1527902, lng: 19.6610057 },
    { lat: 49.1538809, lng: 19.6621252 },
    { lat: 49.1545366, lng: 19.6629143 },
    { lat: 49.1546908, lng: 19.6631125 },
    { lat: 49.1547528, lng: 19.6631641 },
    { lat: 49.1553483, lng: 19.6637636 },
    { lat: 49.1561847, lng: 19.6646679 },
    { lat: 49.1566426, lng: 19.6651607 },
    { lat: 49.1569999, lng: 19.6661278 },
    { lat: 49.157243, lng: 19.6668363 },
    { lat: 49.1583855, lng: 19.6684151 },
    { lat: 49.1588156, lng: 19.6691235 },
    { lat: 49.1592809, lng: 19.6699338 },
    { lat: 49.159537, lng: 19.6704769 },
    { lat: 49.1601882, lng: 19.6720581 },
    { lat: 49.1606044, lng: 19.6727043 },
    { lat: 49.1607948, lng: 19.6729831 },
    { lat: 49.1611231, lng: 19.6735044 },
    { lat: 49.1613999, lng: 19.6739079 },
    { lat: 49.1617053, lng: 19.6743636 },
    { lat: 49.1622919, lng: 19.6749933 },
    { lat: 49.162627, lng: 19.6753106 },
    { lat: 49.1630358, lng: 19.6756138 },
    { lat: 49.1641357, lng: 19.6760774 },
    { lat: 49.1647062, lng: 19.6763438 },
    { lat: 49.1654467, lng: 19.6774258 },
    { lat: 49.1660622, lng: 19.6791136 },
    { lat: 49.1665737, lng: 19.6803153 },
    { lat: 49.1674761, lng: 19.6814888 },
    { lat: 49.1676667, lng: 19.6817471 },
    { lat: 49.1680605, lng: 19.6822446 },
    { lat: 49.1683524, lng: 19.6829382 },
    { lat: 49.1684004, lng: 19.6830784 },
    { lat: 49.1688743, lng: 19.6866966 },
    { lat: 49.1724008, lng: 19.6913751 },
    { lat: 49.1734346, lng: 19.6919345 },
    { lat: 49.1736342, lng: 19.6921131 },
    { lat: 49.1745388, lng: 19.6929072 },
    { lat: 49.176404, lng: 19.6945621 },
    { lat: 49.1800217, lng: 19.6941099 },
    { lat: 49.180735, lng: 19.6947246 },
    { lat: 49.1814347, lng: 19.6963656 },
    { lat: 49.1823276, lng: 19.6972793 },
    { lat: 49.1852197, lng: 19.7001022 },
    { lat: 49.1874144, lng: 19.702946 },
    { lat: 49.1894845, lng: 19.7056007 },
    { lat: 49.1901787, lng: 19.7059169 },
    { lat: 49.1903171, lng: 19.7060284 },
    { lat: 49.1923673, lng: 19.7076798 },
    { lat: 49.1939075, lng: 19.710336 },
    { lat: 49.1940407, lng: 19.7105657 },
    { lat: 49.197795, lng: 19.709945 },
    { lat: 49.197858, lng: 19.709759 },
    { lat: 49.1991443, lng: 19.7073177 },
    { lat: 49.199731, lng: 19.706204 },
    { lat: 49.201411, lng: 19.702706 },
    { lat: 49.203728, lng: 19.70436 },
    { lat: 49.206009, lng: 19.707414 },
    { lat: 49.207584, lng: 19.706897 },
    { lat: 49.208292, lng: 19.70226 },
    { lat: 49.208992, lng: 19.69935 },
    { lat: 49.209851, lng: 19.694138 },
    { lat: 49.211821, lng: 19.688008 },
    { lat: 49.212318, lng: 19.687498 },
    { lat: 49.2117045, lng: 19.6860988 },
    { lat: 49.211448, lng: 19.6853879 },
    { lat: 49.2109439, lng: 19.6838719 },
    { lat: 49.2104047, lng: 19.6825545 },
    { lat: 49.2097228, lng: 19.6812953 },
    { lat: 49.2088576, lng: 19.6801708 },
    { lat: 49.2076422, lng: 19.6777783 },
    { lat: 49.207188, lng: 19.6766248 },
    { lat: 49.2068512, lng: 19.6759893 },
    { lat: 49.2060814, lng: 19.67514 },
    { lat: 49.2056154, lng: 19.6742065 },
    { lat: 49.2043548, lng: 19.6723443 },
    { lat: 49.2038521, lng: 19.672315 },
    { lat: 49.2034548, lng: 19.6717114 },
    { lat: 49.2028739, lng: 19.6712596 },
    { lat: 49.2014287, lng: 19.6700686 },
    { lat: 49.2006771, lng: 19.6695307 },
    { lat: 49.2001866, lng: 19.6688831 },
    { lat: 49.1998674, lng: 19.6682153 },
    { lat: 49.1979305, lng: 19.6670867 },
    { lat: 49.1969903, lng: 19.6675038 },
    { lat: 49.1967855, lng: 19.6674324 },
    { lat: 49.1958169, lng: 19.6673885 },
    { lat: 49.1953218, lng: 19.6671956 },
    { lat: 49.1951408, lng: 19.6670266 },
    { lat: 49.1932471, lng: 19.6655032 },
    { lat: 49.1926868, lng: 19.66524 },
    { lat: 49.1907874, lng: 19.6640473 },
    { lat: 49.1892774, lng: 19.6634503 },
    { lat: 49.1883597, lng: 19.6628019 },
    { lat: 49.1882943, lng: 19.6629037 },
    { lat: 49.1862702, lng: 19.6668787 },
    { lat: 49.1859492, lng: 19.6676699 },
    { lat: 49.1856103, lng: 19.6682051 },
    { lat: 49.1837136, lng: 19.6701511 },
    { lat: 49.1834922, lng: 19.6704444 },
    { lat: 49.1832222, lng: 19.6709631 },
    { lat: 49.1830783, lng: 19.6709185 },
    { lat: 49.1826426, lng: 19.6719451 },
    { lat: 49.1816982, lng: 19.6725191 },
    { lat: 49.1813503, lng: 19.6728574 },
    { lat: 49.1811148, lng: 19.6721927 },
    { lat: 49.1810706, lng: 19.6718332 },
    { lat: 49.1808926, lng: 19.6710427 },
    { lat: 49.1804188, lng: 19.6701847 },
    { lat: 49.1802459, lng: 19.6691794 },
    { lat: 49.1801536, lng: 19.6690049 },
    { lat: 49.1806021, lng: 19.6683202 },
    { lat: 49.1808692, lng: 19.6575455 },
    { lat: 49.1808797, lng: 19.6573723 },
    { lat: 49.1808152, lng: 19.6572925 },
    { lat: 49.1806531, lng: 19.6572729 },
    { lat: 49.1800319, lng: 19.6576644 },
    { lat: 49.1794793, lng: 19.6579332 },
    { lat: 49.1783043, lng: 19.6579846 },
    { lat: 49.1781145, lng: 19.6579462 },
    { lat: 49.1777311, lng: 19.6575341 },
    { lat: 49.1774104, lng: 19.6575663 },
    { lat: 49.1773319, lng: 19.6575276 },
    { lat: 49.1768363, lng: 19.6572219 },
    { lat: 49.176543, lng: 19.6571391 },
    { lat: 49.1755683, lng: 19.656679 },
    { lat: 49.1745698, lng: 19.6556927 },
    { lat: 49.174286, lng: 19.655755 },
    { lat: 49.1738393, lng: 19.6554882 },
    { lat: 49.1734933, lng: 19.6551541 },
    { lat: 49.1730949, lng: 19.6540992 },
    { lat: 49.1725249, lng: 19.6539489 },
    { lat: 49.1720573, lng: 19.6535047 },
    { lat: 49.171847, lng: 19.6533933 },
    { lat: 49.1716523, lng: 19.6532775 },
    { lat: 49.1715264, lng: 19.6532066 },
    { lat: 49.1713675, lng: 19.6530387 },
    { lat: 49.1709029, lng: 19.6525898 },
    { lat: 49.170764, lng: 19.6526567 },
    { lat: 49.170505, lng: 19.6526338 },
    { lat: 49.1703791, lng: 19.6525629 },
    { lat: 49.1702965, lng: 19.6524796 },
    { lat: 49.1702278, lng: 19.6523528 },
    { lat: 49.1701914, lng: 19.6523142 },
    { lat: 49.1700101, lng: 19.6518683 },
    { lat: 49.1697794, lng: 19.6513884 },
    { lat: 49.1697303, lng: 19.6513247 },
    { lat: 49.1693368, lng: 19.6513679 },
    { lat: 49.1689704, lng: 19.6513363 },
    { lat: 49.1687284, lng: 19.6510654 },
    { lat: 49.1686203, lng: 19.6509372 },
    { lat: 49.1677283, lng: 19.6494488 },
    { lat: 49.1670564, lng: 19.6492353 },
    { lat: 49.1668786, lng: 19.6489848 },
    { lat: 49.1662884, lng: 19.6484802 },
    { lat: 49.1661275, lng: 19.6486153 },
    { lat: 49.1658724, lng: 19.6488047 },
    { lat: 49.1656091, lng: 19.6488395 },
    { lat: 49.1650446, lng: 19.6477703 },
    { lat: 49.1642063, lng: 19.6476439 },
    { lat: 49.1639033, lng: 19.6475686 },
    { lat: 49.1635936, lng: 19.6476204 },
    { lat: 49.1633268, lng: 19.6471973 },
    { lat: 49.1629747, lng: 19.6468806 },
    { lat: 49.1626312, lng: 19.6465773 },
    { lat: 49.1622146, lng: 19.6465585 },
    { lat: 49.1620069, lng: 19.6464793 },
    { lat: 49.1618781, lng: 19.6462866 },
    { lat: 49.1617737, lng: 19.6460929 },
    { lat: 49.1617659, lng: 19.6456635 },
    { lat: 49.1615816, lng: 19.6449907 },
    { lat: 49.1613838, lng: 19.6448815 },
    { lat: 49.1611454, lng: 19.6446798 },
    { lat: 49.1606471, lng: 19.6441043 },
    { lat: 49.16051, lng: 19.643946 },
    { lat: 49.159924, lng: 19.6439358 },
    { lat: 49.1598559, lng: 19.6439042 },
    { lat: 49.159642, lng: 19.6437082 },
    { lat: 49.1595603, lng: 19.6431134 },
    { lat: 49.1595761, lng: 19.6423558 },
    { lat: 49.1594925, lng: 19.6421754 },
    { lat: 49.1592742, lng: 19.6421104 },
    { lat: 49.1590637, lng: 19.6423861 },
    { lat: 49.1587353, lng: 19.6426757 },
    { lat: 49.1585493, lng: 19.6426244 },
    { lat: 49.1584588, lng: 19.6425643 },
    { lat: 49.1582799, lng: 19.6415583 },
    { lat: 49.1580528, lng: 19.6414951 },
    { lat: 49.1576335, lng: 19.6416378 },
    { lat: 49.1572793, lng: 19.6415557 },
    { lat: 49.1570542, lng: 19.6412558 },
    { lat: 49.1568833, lng: 19.6407314 },
    { lat: 49.1567441, lng: 19.6402886 },
    { lat: 49.1563222, lng: 19.6397427 },
    { lat: 49.1562259, lng: 19.6394039 },
    { lat: 49.1556553, lng: 19.6394155 },
    { lat: 49.1553749, lng: 19.6390662 },
    { lat: 49.1552882, lng: 19.6389171 },
    { lat: 49.1551929, lng: 19.6389029 },
    { lat: 49.1550405, lng: 19.6387139 },
    { lat: 49.1549575, lng: 19.6382448 },
    { lat: 49.1542537, lng: 19.6378005 },
    { lat: 49.153718, lng: 19.6368233 },
    { lat: 49.1534905, lng: 19.6361958 },
    { lat: 49.1532583, lng: 19.6360665 },
    { lat: 49.1530661, lng: 19.6358737 },
    { lat: 49.1526624, lng: 19.6352824 },
    { lat: 49.1519448, lng: 19.6351843 },
    { lat: 49.151386, lng: 19.6343259 },
    { lat: 49.1510869, lng: 19.6343094 },
    { lat: 49.1509356, lng: 19.6341314 },
    { lat: 49.1506931, lng: 19.6333406 },
    { lat: 49.1492598, lng: 19.6330822 },
    { lat: 49.1489915, lng: 19.6330272 },
    { lat: 49.1485673, lng: 19.6323823 },
    { lat: 49.1482067, lng: 19.6326601 },
    { lat: 49.1474814, lng: 19.632288 },
    { lat: 49.1470678, lng: 19.6323589 },
    { lat: 49.1465361, lng: 19.6326645 },
    { lat: 49.1463781, lng: 19.6328583 },
    { lat: 49.1463221, lng: 19.6328411 },
    { lat: 49.1463138, lng: 19.6328096 },
    { lat: 49.1460934, lng: 19.6326154 },
    { lat: 49.1459563, lng: 19.6326498 },
    { lat: 49.1452453, lng: 19.6317885 },
    { lat: 49.1451843, lng: 19.6313795 },
    { lat: 49.1451374, lng: 19.6312865 },
    { lat: 49.1450346, lng: 19.6312275 },
    { lat: 49.1445649, lng: 19.6309576 },
    { lat: 49.1440854, lng: 19.6305607 },
    { lat: 49.1437754, lng: 19.6299051 },
    { lat: 49.1437226, lng: 19.6299534 },
    { lat: 49.1423212, lng: 19.6291779 },
    { lat: 49.1412689, lng: 19.6282655 },
    { lat: 49.1412462, lng: 19.6282646 },
    { lat: 49.1409536, lng: 19.6279934 },
    { lat: 49.1393447, lng: 19.6269595 },
    { lat: 49.1374057, lng: 19.6267048 },
    { lat: 49.1358449, lng: 19.6264451 },
    { lat: 49.1358288, lng: 19.6264388 },
    { lat: 49.1357973, lng: 19.6264644 },
    { lat: 49.1352008, lng: 19.6270099 },
    { lat: 49.1347914, lng: 19.6267987 },
    { lat: 49.1343783, lng: 19.6266037 },
    { lat: 49.1322248, lng: 19.6261623 },
    { lat: 49.1306048, lng: 19.6253076 },
    { lat: 49.1296898, lng: 19.6258594 },
    { lat: 49.1286257, lng: 19.6265026 },
    { lat: 49.127434, lng: 19.6258546 },
  ],
  Jamník: [
    { lat: 49.0904768, lng: 19.7108372 },
    { lat: 49.0899298, lng: 19.7106159 },
    { lat: 49.0897059, lng: 19.710367 },
    { lat: 49.0891147, lng: 19.7098874 },
    { lat: 49.0887984, lng: 19.7096091 },
    { lat: 49.0878599, lng: 19.7085618 },
    { lat: 49.0874096, lng: 19.7080287 },
    { lat: 49.0871131, lng: 19.7077926 },
    { lat: 49.0863919, lng: 19.706918 },
    { lat: 49.0859111, lng: 19.7062604 },
    { lat: 49.0855644, lng: 19.7058814 },
    { lat: 49.0847051, lng: 19.7053049 },
    { lat: 49.0846259, lng: 19.7052695 },
    { lat: 49.0845717, lng: 19.705192 },
    { lat: 49.0842745, lng: 19.7048859 },
    { lat: 49.0839739, lng: 19.7044548 },
    { lat: 49.0828787, lng: 19.7018987 },
    { lat: 49.0823266, lng: 19.7005132 },
    { lat: 49.0820908, lng: 19.6999676 },
    { lat: 49.0813654, lng: 19.6984654 },
    { lat: 49.081076, lng: 19.6978826 },
    { lat: 49.0805911, lng: 19.6974876 },
    { lat: 49.0800961, lng: 19.6972751 },
    { lat: 49.0796006, lng: 19.697076 },
    { lat: 49.0789868, lng: 19.6968247 },
    { lat: 49.0783079, lng: 19.6965545 },
    { lat: 49.0779795, lng: 19.6965169 },
    { lat: 49.0779751, lng: 19.6965939 },
    { lat: 49.0769172, lng: 19.6964429 },
    { lat: 49.0760944, lng: 19.6964034 },
    { lat: 49.075771, lng: 19.6963517 },
    { lat: 49.075541, lng: 19.696276 },
    { lat: 49.0745186, lng: 19.6959562 },
    { lat: 49.0742395, lng: 19.6957576 },
    { lat: 49.0741892, lng: 19.6957142 },
    { lat: 49.0737377, lng: 19.6954647 },
    { lat: 49.0733652, lng: 19.6952943 },
    { lat: 49.0730765, lng: 19.695188 },
    { lat: 49.0729468, lng: 19.6951337 },
    { lat: 49.072303, lng: 19.6949182 },
    { lat: 49.0716064, lng: 19.6947074 },
    { lat: 49.0716396, lng: 19.6943619 },
    { lat: 49.071512, lng: 19.6943537 },
    { lat: 49.070746, lng: 19.6941259 },
    { lat: 49.0694281, lng: 19.693905 },
    { lat: 49.0694735, lng: 19.6932978 },
    { lat: 49.0689287, lng: 19.6931279 },
    { lat: 49.0675707, lng: 19.6926085 },
    { lat: 49.0668763, lng: 19.6917634 },
    { lat: 49.0665915, lng: 19.6912044 },
    { lat: 49.0663411, lng: 19.6918095 },
    { lat: 49.0656618, lng: 19.69324 },
    { lat: 49.0654463, lng: 19.6938947 },
    { lat: 49.0651388, lng: 19.6944576 },
    { lat: 49.0648549, lng: 19.6950216 },
    { lat: 49.0645291, lng: 19.6959581 },
    { lat: 49.0645194, lng: 19.6969144 },
    { lat: 49.0645208, lng: 19.697022 },
    { lat: 49.0645366, lng: 19.6972514 },
    { lat: 49.0645536, lng: 19.6977653 },
    { lat: 49.0645617, lng: 19.6984363 },
    { lat: 49.0645511, lng: 19.6991908 },
    { lat: 49.0643019, lng: 19.6992626 },
    { lat: 49.0641547, lng: 19.699324 },
    { lat: 49.062819, lng: 19.6991809 },
    { lat: 49.0618832, lng: 19.6991112 },
    { lat: 49.0617598, lng: 19.6991249 },
    { lat: 49.0616881, lng: 19.6996183 },
    { lat: 49.0616802, lng: 19.700028 },
    { lat: 49.0617074, lng: 19.7001833 },
    { lat: 49.0616063, lng: 19.7007781 },
    { lat: 49.0614226, lng: 19.7022055 },
    { lat: 49.0613784, lng: 19.7025362 },
    { lat: 49.0611073, lng: 19.7046393 },
    { lat: 49.0608584, lng: 19.7065848 },
    { lat: 49.0607679, lng: 19.7073169 },
    { lat: 49.0604869, lng: 19.7080496 },
    { lat: 49.0605601, lng: 19.7081773 },
    { lat: 49.0607088, lng: 19.7082796 },
    { lat: 49.0609277, lng: 19.7082871 },
    { lat: 49.0610797, lng: 19.7083876 },
    { lat: 49.061232, lng: 19.7083933 },
    { lat: 49.0612917, lng: 19.7085424 },
    { lat: 49.0611603, lng: 19.7088119 },
    { lat: 49.0611296, lng: 19.708957 },
    { lat: 49.0611466, lng: 19.7090371 },
    { lat: 49.0612233, lng: 19.7092201 },
    { lat: 49.0612056, lng: 19.7092247 },
    { lat: 49.0604396, lng: 19.7109162 },
    { lat: 49.0602874, lng: 19.7112238 },
    { lat: 49.0608168, lng: 19.7117026 },
    { lat: 49.0609192, lng: 19.7118032 },
    { lat: 49.0599374, lng: 19.7136897 },
    { lat: 49.0596257, lng: 19.7135964 },
    { lat: 49.0595574, lng: 19.7136048 },
    { lat: 49.0595474, lng: 19.7136827 },
    { lat: 49.0591286, lng: 19.714483 },
    { lat: 49.0586269, lng: 19.7153477 },
    { lat: 49.058499, lng: 19.7163102 },
    { lat: 49.0585458, lng: 19.7166532 },
    { lat: 49.0586611, lng: 19.7169464 },
    { lat: 49.0589126, lng: 19.717029 },
    { lat: 49.0597344, lng: 19.719114 },
    { lat: 49.0601044, lng: 19.7184326 },
    { lat: 49.0607873, lng: 19.7188765 },
    { lat: 49.0615065, lng: 19.7193457 },
    { lat: 49.0611749, lng: 19.7201601 },
    { lat: 49.0606052, lng: 19.7215509 },
    { lat: 49.0609316, lng: 19.7217206 },
    { lat: 49.0612342, lng: 19.7216331 },
    { lat: 49.0612927, lng: 19.7216868 },
    { lat: 49.0618644, lng: 19.7225458 },
    { lat: 49.0625498, lng: 19.7236599 },
    { lat: 49.0619709, lng: 19.7245091 },
    { lat: 49.0623004, lng: 19.7246459 },
    { lat: 49.0624837, lng: 19.724666 },
    { lat: 49.0630408, lng: 19.7237296 },
    { lat: 49.0631408, lng: 19.7238505 },
    { lat: 49.0640673, lng: 19.7250953 },
    { lat: 49.0650079, lng: 19.726352 },
    { lat: 49.0659258, lng: 19.7276003 },
    { lat: 49.0666804, lng: 19.7287003 },
    { lat: 49.0659249, lng: 19.7297916 },
    { lat: 49.0659001, lng: 19.7304861 },
    { lat: 49.0659814, lng: 19.7310279 },
    { lat: 49.0659895, lng: 19.7316012 },
    { lat: 49.0659119, lng: 19.7325019 },
    { lat: 49.0672977, lng: 19.7326314 },
    { lat: 49.0683024, lng: 19.7327073 },
    { lat: 49.0693736, lng: 19.7327807 },
    { lat: 49.06939, lng: 19.7325882 },
    { lat: 49.0703025, lng: 19.732639 },
    { lat: 49.0710421, lng: 19.7325477 },
    { lat: 49.0712662, lng: 19.734014 },
    { lat: 49.0714644, lng: 19.7353485 },
    { lat: 49.0717539, lng: 19.737253 },
    { lat: 49.0729765, lng: 19.7376963 },
    { lat: 49.0750316, lng: 19.7386802 },
    { lat: 49.0759622, lng: 19.7392456 },
    { lat: 49.0798929, lng: 19.7412093 },
    { lat: 49.0799324, lng: 19.7412322 },
    { lat: 49.084305, lng: 19.7437459 },
    { lat: 49.0869237, lng: 19.7452514 },
    { lat: 49.0879293, lng: 19.745835 },
    { lat: 49.0891867, lng: 19.7465606 },
    { lat: 49.0893246, lng: 19.7473729 },
    { lat: 49.0893654, lng: 19.7474806 },
    { lat: 49.0898116, lng: 19.7484375 },
    { lat: 49.0903658, lng: 19.7496744 },
    { lat: 49.0907874, lng: 19.7506112 },
    { lat: 49.0909604, lng: 19.7509877 },
    { lat: 49.0911687, lng: 19.7514504 },
    { lat: 49.0916929, lng: 19.7525936 },
    { lat: 49.0923113, lng: 19.7539628 },
    { lat: 49.0927005, lng: 19.7548275 },
    { lat: 49.0928761, lng: 19.7552105 },
    { lat: 49.0929799, lng: 19.7554463 },
    { lat: 49.0931313, lng: 19.7557415 },
    { lat: 49.0932718, lng: 19.7560458 },
    { lat: 49.0934761, lng: 19.756518 },
    { lat: 49.0939153, lng: 19.7574974 },
    { lat: 49.0941933, lng: 19.7581117 },
    { lat: 49.0948743, lng: 19.7596238 },
    { lat: 49.094883, lng: 19.7596452 },
    { lat: 49.0947225, lng: 19.760746 },
    { lat: 49.0946784, lng: 19.7611261 },
    { lat: 49.0945121, lng: 19.7622774 },
    { lat: 49.0943415, lng: 19.7634841 },
    { lat: 49.0942068, lng: 19.7644244 },
    { lat: 49.0940931, lng: 19.7652033 },
    { lat: 49.0939949, lng: 19.7658973 },
    { lat: 49.0939261, lng: 19.7663611 },
    { lat: 49.0936475, lng: 19.7682703 },
    { lat: 49.0935839, lng: 19.768675 },
    { lat: 49.0935552, lng: 19.7688943 },
    { lat: 49.095407, lng: 19.7702878 },
    { lat: 49.0986073, lng: 19.7726945 },
    { lat: 49.0991127, lng: 19.7730753 },
    { lat: 49.1013246, lng: 19.7744394 },
    { lat: 49.1041572, lng: 19.7761872 },
    { lat: 49.1050414, lng: 19.7771714 },
    { lat: 49.1060929, lng: 19.7781967 },
    { lat: 49.1071801, lng: 19.7796038 },
    { lat: 49.1081411, lng: 19.7826404 },
    { lat: 49.1085627, lng: 19.7839747 },
    { lat: 49.1125738, lng: 19.786123 },
    { lat: 49.1127335, lng: 19.7861976 },
    { lat: 49.1157655, lng: 19.7872025 },
    { lat: 49.1168492, lng: 19.7875395 },
    { lat: 49.1197898, lng: 19.7886408 },
    { lat: 49.1201956, lng: 19.7888551 },
    { lat: 49.1233602, lng: 19.7904264 },
    { lat: 49.1253918, lng: 19.7897579 },
    { lat: 49.1273712, lng: 19.7892141 },
    { lat: 49.1305298, lng: 19.7888754 },
    { lat: 49.1315157, lng: 19.7899246 },
    { lat: 49.1319392, lng: 19.7903904 },
    { lat: 49.1320414, lng: 19.7904877 },
    { lat: 49.1321199, lng: 19.7905827 },
    { lat: 49.1322961, lng: 19.7906193 },
    { lat: 49.1326972, lng: 19.7910898 },
    { lat: 49.132789, lng: 19.7912579 },
    { lat: 49.1333248, lng: 19.7911973 },
    { lat: 49.1336076, lng: 19.7913552 },
    { lat: 49.1338093, lng: 19.7914072 },
    { lat: 49.1340422, lng: 19.79164 },
    { lat: 49.1342105, lng: 19.7917537 },
    { lat: 49.1344078, lng: 19.7919875 },
    { lat: 49.1346927, lng: 19.7921182 },
    { lat: 49.1347076, lng: 19.7920689 },
    { lat: 49.1349266, lng: 19.7919764 },
    { lat: 49.13517, lng: 19.7917951 },
    { lat: 49.1354637, lng: 19.7916328 },
    { lat: 49.1356722, lng: 19.7916626 },
    { lat: 49.1360993, lng: 19.7919258 },
    { lat: 49.136514, lng: 19.7919583 },
    { lat: 49.1367849, lng: 19.79218 },
    { lat: 49.1371073, lng: 19.7927294 },
    { lat: 49.1373305, lng: 19.7933337 },
    { lat: 49.1380943, lng: 19.7943744 },
    { lat: 49.1385012, lng: 19.7946682 },
    { lat: 49.1390502, lng: 19.7949894 },
    { lat: 49.1393341, lng: 19.7952501 },
    { lat: 49.1398185, lng: 19.7957484 },
    { lat: 49.1402453, lng: 19.7961355 },
    { lat: 49.1404363, lng: 19.7962289 },
    { lat: 49.1408611, lng: 19.7962813 },
    { lat: 49.1410618, lng: 19.7963644 },
    { lat: 49.1412984, lng: 19.7965742 },
    { lat: 49.1415074, lng: 19.7966708 },
    { lat: 49.1419856, lng: 19.7967844 },
    { lat: 49.1421722, lng: 19.7967491 },
    { lat: 49.1424957, lng: 19.7965968 },
    { lat: 49.142778, lng: 19.7965383 },
    { lat: 49.1429994, lng: 19.7965484 },
    { lat: 49.1431326, lng: 19.7966095 },
    { lat: 49.143429, lng: 19.7968968 },
    { lat: 49.1443531, lng: 19.7971969 },
    { lat: 49.1444274, lng: 19.7972179 },
    { lat: 49.1445613, lng: 19.7971084 },
    { lat: 49.1448181, lng: 19.7966386 },
    { lat: 49.1451955, lng: 19.79607 },
    { lat: 49.1454966, lng: 19.7958656 },
    { lat: 49.1459418, lng: 19.7958482 },
    { lat: 49.1463154, lng: 19.7958143 },
    { lat: 49.146588, lng: 19.7957308 },
    { lat: 49.1467661, lng: 19.7955266 },
    { lat: 49.1469755, lng: 19.7949248 },
    { lat: 49.1472981, lng: 19.7942842 },
    { lat: 49.1475908, lng: 19.7940166 },
    { lat: 49.1477891, lng: 19.7937565 },
    { lat: 49.1478584, lng: 19.7936655 },
    { lat: 49.1481489, lng: 19.7935874 },
    { lat: 49.1483495, lng: 19.793745 },
    { lat: 49.1493603, lng: 19.7936475 },
    { lat: 49.1497528, lng: 19.7933234 },
    { lat: 49.1499547, lng: 19.7929098 },
    { lat: 49.1502468, lng: 19.7925292 },
    { lat: 49.1508012, lng: 19.7923742 },
    { lat: 49.1511062, lng: 19.7923705 },
    { lat: 49.1515707, lng: 19.7917787 },
    { lat: 49.1517404, lng: 19.7915331 },
    { lat: 49.1519502, lng: 19.7913342 },
    { lat: 49.152224, lng: 19.7911491 },
    { lat: 49.1524018, lng: 19.7910952 },
    { lat: 49.1524854, lng: 19.7910199 },
    { lat: 49.1527063, lng: 19.790762 },
    { lat: 49.1532271, lng: 19.7902511 },
    { lat: 49.1536108, lng: 19.7895388 },
    { lat: 49.1536951, lng: 19.7892219 },
    { lat: 49.1538457, lng: 19.7888735 },
    { lat: 49.1540146, lng: 19.7886136 },
    { lat: 49.1551906, lng: 19.7871782 },
    { lat: 49.1560287, lng: 19.7859814 },
    { lat: 49.1561428, lng: 19.7858832 },
    { lat: 49.1565957, lng: 19.7852884 },
    { lat: 49.1571543, lng: 19.784788 },
    { lat: 49.157317, lng: 19.7847245 },
    { lat: 49.1578339, lng: 19.7840185 },
    { lat: 49.1580327, lng: 19.7837138 },
    { lat: 49.1585184, lng: 19.7834623 },
    { lat: 49.1590947, lng: 19.783116 },
    { lat: 49.1594947, lng: 19.7832225 },
    { lat: 49.1596236, lng: 19.7832819 },
    { lat: 49.1601674, lng: 19.783432 },
    { lat: 49.16081, lng: 19.783391 },
    { lat: 49.1611712, lng: 19.7829397 },
    { lat: 49.1613184, lng: 19.7822557 },
    { lat: 49.1613126, lng: 19.7821224 },
    { lat: 49.1614742, lng: 19.7817436 },
    { lat: 49.1619399, lng: 19.7809736 },
    { lat: 49.1619986, lng: 19.7808957 },
    { lat: 49.162068, lng: 19.7808632 },
    { lat: 49.1621657, lng: 19.7808569 },
    { lat: 49.1622859, lng: 19.7808956 },
    { lat: 49.1623381, lng: 19.7811153 },
    { lat: 49.1623227, lng: 19.7816964 },
    { lat: 49.1623997, lng: 19.781888 },
    { lat: 49.162413, lng: 19.7820389 },
    { lat: 49.1625438, lng: 19.7826384 },
    { lat: 49.1626649, lng: 19.7831226 },
    { lat: 49.162836, lng: 19.7833516 },
    { lat: 49.1633317, lng: 19.7841857 },
    { lat: 49.1637453, lng: 19.7846333 },
    { lat: 49.1646005, lng: 19.7861494 },
    { lat: 49.1647553, lng: 19.7865251 },
    { lat: 49.1662789, lng: 19.7882314 },
    { lat: 49.1662769, lng: 19.7882118 },
    { lat: 49.16717, lng: 19.7884661 },
    { lat: 49.1681841, lng: 19.7889966 },
    { lat: 49.1686378, lng: 19.7893009 },
    { lat: 49.168883, lng: 19.7896838 },
    { lat: 49.1696646, lng: 19.7900714 },
    { lat: 49.1702339, lng: 19.7907302 },
    { lat: 49.1710025, lng: 19.7910748 },
    { lat: 49.1719709, lng: 19.7922962 },
    { lat: 49.1728337, lng: 19.7928651 },
    { lat: 49.1738527, lng: 19.7933513 },
    { lat: 49.1743115, lng: 19.793829 },
    { lat: 49.1745791, lng: 19.7935555 },
    { lat: 49.1753911, lng: 19.7930296 },
    { lat: 49.1781484, lng: 19.7932321 },
    { lat: 49.1792827, lng: 19.7937813 },
    { lat: 49.1821046, lng: 19.7937847 },
    { lat: 49.1834713, lng: 19.7937625 },
    { lat: 49.1846513, lng: 19.7951344 },
    { lat: 49.1864699, lng: 19.7962956 },
    { lat: 49.1877299, lng: 19.7962022 },
    { lat: 49.1890061, lng: 19.7963851 },
    { lat: 49.1898079, lng: 19.797583 },
    { lat: 49.1905876, lng: 19.7987051 },
    { lat: 49.192167, lng: 19.8011411 },
    { lat: 49.1923429, lng: 19.8011699 },
    { lat: 49.1929261, lng: 19.8012165 },
    { lat: 49.1931665, lng: 19.80133 },
    { lat: 49.1933472, lng: 19.8012458 },
    { lat: 49.1936462, lng: 19.8009353 },
    { lat: 49.19454, lng: 19.7994063 },
    { lat: 49.195114, lng: 19.7983765 },
    { lat: 49.1955331, lng: 19.7979364 },
    { lat: 49.1959493, lng: 19.7972913 },
    { lat: 49.1961633, lng: 19.7969006 },
    { lat: 49.1970866, lng: 19.7954312 },
    { lat: 49.1973866, lng: 19.79507 },
    { lat: 49.198366, lng: 19.795032 },
    { lat: 49.198799, lng: 19.793086 },
    { lat: 49.1992432, lng: 19.7899946 },
    { lat: 49.199213, lng: 19.789612 },
    { lat: 49.199071, lng: 19.788257 },
    { lat: 49.199065, lng: 19.787583 },
    { lat: 49.199136, lng: 19.787376 },
    { lat: 49.1991, lng: 19.787304 },
    { lat: 49.199075, lng: 19.787166 },
    { lat: 49.199091, lng: 19.787068 },
    { lat: 49.199345, lng: 19.786612 },
    { lat: 49.199481, lng: 19.786467 },
    { lat: 49.199422, lng: 19.786239 },
    { lat: 49.199436, lng: 19.785847 },
    { lat: 49.199404, lng: 19.785449 },
    { lat: 49.199332, lng: 19.785255 },
    { lat: 49.199356, lng: 19.785039 },
    { lat: 49.199309, lng: 19.784604 },
    { lat: 49.199287, lng: 19.784254 },
    { lat: 49.199363, lng: 19.783829 },
    { lat: 49.199421, lng: 19.783631 },
    { lat: 49.199661, lng: 19.783032 },
    { lat: 49.19977, lng: 19.782866 },
    { lat: 49.199845, lng: 19.782435 },
    { lat: 49.200095, lng: 19.781949 },
    { lat: 49.20025, lng: 19.781731 },
    { lat: 49.200356, lng: 19.781576 },
    { lat: 49.20049, lng: 19.781554 },
    { lat: 49.200637, lng: 19.781428 },
    { lat: 49.200939, lng: 19.781233 },
    { lat: 49.201235, lng: 19.781144 },
    { lat: 49.201578, lng: 19.780896 },
    { lat: 49.202028, lng: 19.7808 },
    { lat: 49.202499, lng: 19.780442 },
    { lat: 49.2029588, lng: 19.7801355 },
    { lat: 49.203124, lng: 19.780073 },
    { lat: 49.203639, lng: 19.779606 },
    { lat: 49.20399, lng: 19.779234 },
    { lat: 49.204268, lng: 19.779049 },
    { lat: 49.204955, lng: 19.778588 },
    { lat: 49.204983, lng: 19.778248 },
    { lat: 49.204979, lng: 19.778029 },
    { lat: 49.205056, lng: 19.7777 },
    { lat: 49.205066, lng: 19.777516 },
    { lat: 49.205033, lng: 19.777404 },
    { lat: 49.20506, lng: 19.777272 },
    { lat: 49.20504, lng: 19.777052 },
    { lat: 49.205084, lng: 19.776681 },
    { lat: 49.20503, lng: 19.776289 },
    { lat: 49.205051, lng: 19.776129 },
    { lat: 49.205095, lng: 19.775917 },
    { lat: 49.205196, lng: 19.775867 },
    { lat: 49.205287, lng: 19.775543 },
    { lat: 49.205243, lng: 19.775328 },
    { lat: 49.2052502, lng: 19.7750795 },
    { lat: 49.205179, lng: 19.774906 },
    { lat: 49.2052061, lng: 19.7743212 },
    { lat: 49.2051654, lng: 19.774215 },
    { lat: 49.2052292, lng: 19.7739141 },
    { lat: 49.2055683, lng: 19.7731952 },
    { lat: 49.2056594, lng: 19.7728533 },
    { lat: 49.205645, lng: 19.77171 },
    { lat: 49.2058782, lng: 19.7703038 },
    { lat: 49.2058655, lng: 19.7694905 },
    { lat: 49.2061099, lng: 19.7686055 },
    { lat: 49.2060922, lng: 19.7683042 },
    { lat: 49.2059497, lng: 19.7675053 },
    { lat: 49.2060116, lng: 19.7673024 },
    { lat: 49.2063906, lng: 19.7665269 },
    { lat: 49.2064546, lng: 19.7661775 },
    { lat: 49.2068456, lng: 19.7655109 },
    { lat: 49.2069959, lng: 19.7651585 },
    { lat: 49.2075775, lng: 19.7631071 },
    { lat: 49.2070122, lng: 19.7627333 },
    { lat: 49.2062281, lng: 19.761621 },
    { lat: 49.2053369, lng: 19.7610449 },
    { lat: 49.2050074, lng: 19.7603486 },
    { lat: 49.2045106, lng: 19.7598383 },
    { lat: 49.2039308, lng: 19.7594797 },
    { lat: 49.2036388, lng: 19.7593443 },
    { lat: 49.203326, lng: 19.75896 },
    { lat: 49.2031602, lng: 19.7589267 },
    { lat: 49.202696, lng: 19.758757 },
    { lat: 49.202181, lng: 19.758412 },
    { lat: 49.201675, lng: 19.758168 },
    { lat: 49.200368, lng: 19.757905 },
    { lat: 49.200185, lng: 19.757478 },
    { lat: 49.199787, lng: 19.755328 },
    { lat: 49.198743, lng: 19.752993 },
    { lat: 49.197583, lng: 19.751034 },
    { lat: 49.19725, lng: 19.750338 },
    { lat: 49.197249, lng: 19.750319 },
    { lat: 49.197129, lng: 19.748232 },
    { lat: 49.1932701, lng: 19.7506818 },
    { lat: 49.1931579, lng: 19.7507177 },
    { lat: 49.1915983, lng: 19.7518688 },
    { lat: 49.1914821, lng: 19.7518946 },
    { lat: 49.1908612, lng: 19.7520926 },
    { lat: 49.1904169, lng: 19.7520865 },
    { lat: 49.1898912, lng: 19.7524272 },
    { lat: 49.189628, lng: 19.752487 },
    { lat: 49.1888464, lng: 19.7523908 },
    { lat: 49.1884926, lng: 19.7521793 },
    { lat: 49.1882957, lng: 19.7523134 },
    { lat: 49.1880037, lng: 19.7523354 },
    { lat: 49.1877138, lng: 19.7522488 },
    { lat: 49.1871074, lng: 19.7518533 },
    { lat: 49.1857667, lng: 19.7500668 },
    { lat: 49.1846068, lng: 19.7491966 },
    { lat: 49.184419, lng: 19.7489715 },
    { lat: 49.1841714, lng: 19.7488741 },
    { lat: 49.1838738, lng: 19.7489731 },
    { lat: 49.183087, lng: 19.7484962 },
    { lat: 49.1828253, lng: 19.7480715 },
    { lat: 49.182333, lng: 19.7472183 },
    { lat: 49.1820403, lng: 19.7464964 },
    { lat: 49.1817802, lng: 19.7461944 },
    { lat: 49.1816078, lng: 19.7458802 },
    { lat: 49.1811567, lng: 19.7447578 },
    { lat: 49.1809574, lng: 19.7445782 },
    { lat: 49.180828, lng: 19.7441988 },
    { lat: 49.1793791, lng: 19.7424886 },
    { lat: 49.1787271, lng: 19.742131 },
    { lat: 49.1783586, lng: 19.7420513 },
    { lat: 49.1780432, lng: 19.7418446 },
    { lat: 49.1770082, lng: 19.7417982 },
    { lat: 49.1763586, lng: 19.7415501 },
    { lat: 49.1755922, lng: 19.741849 },
    { lat: 49.1752548, lng: 19.7417072 },
    { lat: 49.1749777, lng: 19.741811 },
    { lat: 49.1747292, lng: 19.7417689 },
    { lat: 49.174257, lng: 19.7415083 },
    { lat: 49.1738067, lng: 19.7414143 },
    { lat: 49.1736395, lng: 19.7412707 },
    { lat: 49.1736456, lng: 19.7418753 },
    { lat: 49.1734793, lng: 19.742609 },
    { lat: 49.173044, lng: 19.7434752 },
    { lat: 49.1724633, lng: 19.7444976 },
    { lat: 49.172392, lng: 19.7446982 },
    { lat: 49.1722736, lng: 19.7456247 },
    { lat: 49.1718202, lng: 19.7466712 },
    { lat: 49.1715513, lng: 19.7468824 },
    { lat: 49.1708788, lng: 19.746911 },
    { lat: 49.1703838, lng: 19.7471337 },
    { lat: 49.1698952, lng: 19.7476815 },
    { lat: 49.1694077, lng: 19.7483358 },
    { lat: 49.1689103, lng: 19.7490169 },
    { lat: 49.1686986, lng: 19.7494085 },
    { lat: 49.1682954, lng: 19.7497219 },
    { lat: 49.167459, lng: 19.7506908 },
    { lat: 49.1650512, lng: 19.752545 },
    { lat: 49.1641132, lng: 19.7529629 },
    { lat: 49.1609875, lng: 19.7556908 },
    { lat: 49.1602908, lng: 19.7543316 },
    { lat: 49.1597396, lng: 19.7532378 },
    { lat: 49.1590101, lng: 19.7516196 },
    { lat: 49.15863, lng: 19.7507029 },
    { lat: 49.1578873, lng: 19.7499499 },
    { lat: 49.1575949, lng: 19.7492363 },
    { lat: 49.156792, lng: 19.7485481 },
    { lat: 49.1559226, lng: 19.7479024 },
    { lat: 49.1550126, lng: 19.7472101 },
    { lat: 49.1543504, lng: 19.7458991 },
    { lat: 49.1536683, lng: 19.7435112 },
    { lat: 49.1535474, lng: 19.7428339 },
    { lat: 49.1534167, lng: 19.7417754 },
    { lat: 49.1532094, lng: 19.7410504 },
    { lat: 49.1525033, lng: 19.7407956 },
    { lat: 49.1521811, lng: 19.7399966 },
    { lat: 49.1521064, lng: 19.7398855 },
    { lat: 49.1510627, lng: 19.7443669 },
    { lat: 49.1507297, lng: 19.7457981 },
    { lat: 49.1507586, lng: 19.7473624 },
    { lat: 49.1491058, lng: 19.7483667 },
    { lat: 49.1470154, lng: 19.7485274 },
    { lat: 49.1468046, lng: 19.7487067 },
    { lat: 49.1463609, lng: 19.7490716 },
    { lat: 49.1450279, lng: 19.7485734 },
    { lat: 49.1443538, lng: 19.7481491 },
    { lat: 49.1442089, lng: 19.7481616 },
    { lat: 49.1437446, lng: 19.7483357 },
    { lat: 49.1395271, lng: 19.748626 },
    { lat: 49.1344775, lng: 19.7489739 },
    { lat: 49.1344695, lng: 19.7489747 },
    { lat: 49.1344525, lng: 19.7493045 },
    { lat: 49.1344606, lng: 19.7501401 },
    { lat: 49.1343528, lng: 19.7507425 },
    { lat: 49.1341925, lng: 19.7521546 },
    { lat: 49.1341032, lng: 19.7536969 },
    { lat: 49.1340519, lng: 19.7541252 },
    { lat: 49.1338707, lng: 19.7550041 },
    { lat: 49.1338768, lng: 19.7550693 },
    { lat: 49.1338445, lng: 19.7568638 },
    { lat: 49.1338133, lng: 19.7571022 },
    { lat: 49.133767, lng: 19.7575408 },
    { lat: 49.1337808, lng: 19.7581171 },
    { lat: 49.1337663, lng: 19.7587662 },
    { lat: 49.1337502, lng: 19.7593279 },
    { lat: 49.1337996, lng: 19.7599201 },
    { lat: 49.1338562, lng: 19.7608591 },
    { lat: 49.1340369, lng: 19.7615442 },
    { lat: 49.1341862, lng: 19.7619162 },
    { lat: 49.1342368, lng: 19.7623046 },
    { lat: 49.1342133, lng: 19.7623056 },
    { lat: 49.1339637, lng: 19.7619574 },
    { lat: 49.1338341, lng: 19.761669 },
    { lat: 49.1335649, lng: 19.7611512 },
    { lat: 49.1333434, lng: 19.7609052 },
    { lat: 49.1331377, lng: 19.7602414 },
    { lat: 49.1326965, lng: 19.7597089 },
    { lat: 49.1325546, lng: 19.7591675 },
    { lat: 49.1315847, lng: 19.7580237 },
    { lat: 49.1314646, lng: 19.7578567 },
    { lat: 49.1310195, lng: 19.7571279 },
    { lat: 49.130772, lng: 19.7567731 },
    { lat: 49.130583, lng: 19.7566501 },
    { lat: 49.1303611, lng: 19.7561081 },
    { lat: 49.1301693, lng: 19.7558937 },
    { lat: 49.1299781, lng: 19.7558735 },
    { lat: 49.1298133, lng: 19.7555855 },
    { lat: 49.1295146, lng: 19.7554754 },
    { lat: 49.1293018, lng: 19.7552721 },
    { lat: 49.129249, lng: 19.7549095 },
    { lat: 49.1291117, lng: 19.7545809 },
    { lat: 49.1289977, lng: 19.7542403 },
    { lat: 49.1287919, lng: 19.7541435 },
    { lat: 49.1286773, lng: 19.7541529 },
    { lat: 49.1285899, lng: 19.7540042 },
    { lat: 49.1283159, lng: 19.753919 },
    { lat: 49.1282903, lng: 19.7538787 },
    { lat: 49.1280998, lng: 19.7540318 },
    { lat: 49.1280032, lng: 19.7542409 },
    { lat: 49.1277892, lng: 19.7541942 },
    { lat: 49.1275491, lng: 19.754216 },
    { lat: 49.1271554, lng: 19.7540501 },
    { lat: 49.1269563, lng: 19.7541335 },
    { lat: 49.1267814, lng: 19.753994 },
    { lat: 49.1265873, lng: 19.7538572 },
    { lat: 49.1263435, lng: 19.7539775 },
    { lat: 49.1262973, lng: 19.7539516 },
    { lat: 49.1261031, lng: 19.7537375 },
    { lat: 49.1257489, lng: 19.7536964 },
    { lat: 49.1255668, lng: 19.7536835 },
    { lat: 49.1253158, lng: 19.753633 },
    { lat: 49.125173, lng: 19.7537683 },
    { lat: 49.1250067, lng: 19.7536251 },
    { lat: 49.1248544, lng: 19.7534124 },
    { lat: 49.1245519, lng: 19.7534283 },
    { lat: 49.1245618, lng: 19.7529007 },
    { lat: 49.1244972, lng: 19.7526102 },
    { lat: 49.1242539, lng: 19.7524564 },
    { lat: 49.1240565, lng: 19.7522911 },
    { lat: 49.1237058, lng: 19.7519259 },
    { lat: 49.1236349, lng: 19.751718 },
    { lat: 49.1235989, lng: 19.7517979 },
    { lat: 49.1220707, lng: 19.7552497 },
    { lat: 49.1222588, lng: 19.7554008 },
    { lat: 49.1224378, lng: 19.7554773 },
    { lat: 49.1226743, lng: 19.7555519 },
    { lat: 49.1229733, lng: 19.7555875 },
    { lat: 49.1231232, lng: 19.7556702 },
    { lat: 49.1233056, lng: 19.7558197 },
    { lat: 49.1234112, lng: 19.7559669 },
    { lat: 49.123558, lng: 19.75621 },
    { lat: 49.1236612, lng: 19.7564614 },
    { lat: 49.1238616, lng: 19.7572849 },
    { lat: 49.1239759, lng: 19.7576593 },
    { lat: 49.1241687, lng: 19.7586174 },
    { lat: 49.1242694, lng: 19.759277 },
    { lat: 49.1244016, lng: 19.7600093 },
    { lat: 49.1244766, lng: 19.7604624 },
    { lat: 49.1245575, lng: 19.7610316 },
    { lat: 49.1246494, lng: 19.7617431 },
    { lat: 49.124757, lng: 19.7625624 },
    { lat: 49.1248133, lng: 19.7633631 },
    { lat: 49.1248661, lng: 19.763747 },
    { lat: 49.125117, lng: 19.7644888 },
    { lat: 49.125167, lng: 19.7651729 },
    { lat: 49.1253081, lng: 19.7656209 },
    { lat: 49.1254776, lng: 19.7664084 },
    { lat: 49.1256212, lng: 19.7668403 },
    { lat: 49.1258419, lng: 19.7674033 },
    { lat: 49.1255937, lng: 19.767347 },
    { lat: 49.1250561, lng: 19.7670801 },
    { lat: 49.1244805, lng: 19.7666907 },
    { lat: 49.1238089, lng: 19.7661711 },
    { lat: 49.1229898, lng: 19.7655194 },
    { lat: 49.1221699, lng: 19.7646163 },
    { lat: 49.1219708, lng: 19.7643166 },
    { lat: 49.1218228, lng: 19.7642021 },
    { lat: 49.1215943, lng: 19.7640997 },
    { lat: 49.1214227, lng: 19.7639911 },
    { lat: 49.121228, lng: 19.7637586 },
    { lat: 49.1210007, lng: 19.763532 },
    { lat: 49.1209151, lng: 19.7634313 },
    { lat: 49.1207252, lng: 19.763184 },
    { lat: 49.1206341, lng: 19.763054 },
    { lat: 49.1201951, lng: 19.7623891 },
    { lat: 49.1200309, lng: 19.7621899 },
    { lat: 49.1196542, lng: 19.7618917 },
    { lat: 49.1190026, lng: 19.7613655 },
    { lat: 49.1185827, lng: 19.7611119 },
    { lat: 49.1181383, lng: 19.7608968 },
    { lat: 49.1177009, lng: 19.7605012 },
    { lat: 49.1173024, lng: 19.7600872 },
    { lat: 49.1173084, lng: 19.7600474 },
    { lat: 49.1173609, lng: 19.7599572 },
    { lat: 49.1173014, lng: 19.75955 },
    { lat: 49.1162763, lng: 19.7587236 },
    { lat: 49.1159862, lng: 19.7584537 },
    { lat: 49.1150121, lng: 19.7578099 },
    { lat: 49.1141637, lng: 19.757371 },
    { lat: 49.1141371, lng: 19.7566038 },
    { lat: 49.1144262, lng: 19.7563099 },
    { lat: 49.1145456, lng: 19.7561561 },
    { lat: 49.1147124, lng: 19.7555106 },
    { lat: 49.1150246, lng: 19.7549982 },
    { lat: 49.1151655, lng: 19.7545309 },
    { lat: 49.1152232, lng: 19.7543549 },
    { lat: 49.1152061, lng: 19.7543534 },
    { lat: 49.1151018, lng: 19.7541313 },
    { lat: 49.114977, lng: 19.7535437 },
    { lat: 49.1148809, lng: 19.7533265 },
    { lat: 49.1142448, lng: 19.7517856 },
    { lat: 49.1141271, lng: 19.7509747 },
    { lat: 49.1138542, lng: 19.750579 },
    { lat: 49.113828, lng: 19.7505461 },
    { lat: 49.1140382, lng: 19.7503813 },
    { lat: 49.1136573, lng: 19.7493939 },
    { lat: 49.1133379, lng: 19.7478846 },
    { lat: 49.1130941, lng: 19.7473069 },
    { lat: 49.1124302, lng: 19.7464719 },
    { lat: 49.112203, lng: 19.7460149 },
    { lat: 49.1114961, lng: 19.7449207 },
    { lat: 49.1111344, lng: 19.7443777 },
    { lat: 49.1092843, lng: 19.7418654 },
    { lat: 49.1089827, lng: 19.7413326 },
    { lat: 49.1086192, lng: 19.74088 },
    { lat: 49.1083792, lng: 19.7407235 },
    { lat: 49.1082662, lng: 19.7407914 },
    { lat: 49.1082078, lng: 19.7408491 },
    { lat: 49.1080368, lng: 19.7410847 },
    { lat: 49.107929, lng: 19.7412208 },
    { lat: 49.107855, lng: 19.7413319 },
    { lat: 49.1077405, lng: 19.7415215 },
    { lat: 49.1076575, lng: 19.7416083 },
    { lat: 49.1076026, lng: 19.7416383 },
    { lat: 49.1062543, lng: 19.7419551 },
    { lat: 49.1062314, lng: 19.7419974 },
    { lat: 49.1061836, lng: 19.7419619 },
    { lat: 49.1055272, lng: 19.7409656 },
    { lat: 49.1046767, lng: 19.7397777 },
    { lat: 49.1040304, lng: 19.7387538 },
    { lat: 49.1037745, lng: 19.7385705 },
    { lat: 49.103557, lng: 19.738494 },
    { lat: 49.1034752, lng: 19.7385005 },
    { lat: 49.1030239, lng: 19.7387728 },
    { lat: 49.1025589, lng: 19.7389854 },
    { lat: 49.1019811, lng: 19.7390897 },
    { lat: 49.101979, lng: 19.7391431 },
    { lat: 49.1016651, lng: 19.7390002 },
    { lat: 49.1014467, lng: 19.7388476 },
    { lat: 49.1012416, lng: 19.7386164 },
    { lat: 49.1009551, lng: 19.7381928 },
    { lat: 49.1005943, lng: 19.7377736 },
    { lat: 49.1003993, lng: 19.7375629 },
    { lat: 49.1000961, lng: 19.7371996 },
    { lat: 49.0998528, lng: 19.7368181 },
    { lat: 49.0995098, lng: 19.7362154 },
    { lat: 49.099242, lng: 19.7357796 },
    { lat: 49.0989964, lng: 19.7354712 },
    { lat: 49.098181, lng: 19.7339819 },
    { lat: 49.0978832, lng: 19.7331277 },
    { lat: 49.0978163, lng: 19.7329098 },
    { lat: 49.0974217, lng: 19.7319516 },
    { lat: 49.0972932, lng: 19.7316648 },
    { lat: 49.0971272, lng: 19.7319667 },
    { lat: 49.0967594, lng: 19.7328281 },
    { lat: 49.0965512, lng: 19.7333248 },
    { lat: 49.0964343, lng: 19.7345647 },
    { lat: 49.0957235, lng: 19.7356697 },
    { lat: 49.0953457, lng: 19.7363314 },
    { lat: 49.0953236, lng: 19.7361614 },
    { lat: 49.0953351, lng: 19.7359385 },
    { lat: 49.09531, lng: 19.7356987 },
    { lat: 49.0952228, lng: 19.7356116 },
    { lat: 49.0952414, lng: 19.7353897 },
    { lat: 49.0951976, lng: 19.7351651 },
    { lat: 49.0950868, lng: 19.7348173 },
    { lat: 49.0949954, lng: 19.7346874 },
    { lat: 49.0949322, lng: 19.7343214 },
    { lat: 49.0947546, lng: 19.7338466 },
    { lat: 49.0946708, lng: 19.7335754 },
    { lat: 49.0946027, lng: 19.7335622 },
    { lat: 49.0941877, lng: 19.7333261 },
    { lat: 49.0939701, lng: 19.7330621 },
    { lat: 49.0937397, lng: 19.7327389 },
    { lat: 49.0937095, lng: 19.7327452 },
    { lat: 49.0935172, lng: 19.7322403 },
    { lat: 49.093234, lng: 19.7318927 },
    { lat: 49.0929973, lng: 19.7315058 },
    { lat: 49.0928974, lng: 19.7312579 },
    { lat: 49.0927268, lng: 19.7312698 },
    { lat: 49.0926611, lng: 19.7312621 },
    { lat: 49.0925165, lng: 19.7309691 },
    { lat: 49.0923463, lng: 19.7308674 },
    { lat: 49.0923521, lng: 19.7307141 },
    { lat: 49.092222, lng: 19.7305511 },
    { lat: 49.0921723, lng: 19.7305483 },
    { lat: 49.0920243, lng: 19.7302035 },
    { lat: 49.0918935, lng: 19.7299932 },
    { lat: 49.0917345, lng: 19.7297865 },
    { lat: 49.0915637, lng: 19.7296647 },
    { lat: 49.0915616, lng: 19.7295406 },
    { lat: 49.0913472, lng: 19.7292755 },
    { lat: 49.091252, lng: 19.7290598 },
    { lat: 49.0911337, lng: 19.7290862 },
    { lat: 49.0911384, lng: 19.7289333 },
    { lat: 49.0911795, lng: 19.7288421 },
    { lat: 49.0911977, lng: 19.7286926 },
    { lat: 49.091092, lng: 19.7285908 },
    { lat: 49.0909937, lng: 19.7284606 },
    { lat: 49.0909396, lng: 19.728561 },
    { lat: 49.0908092, lng: 19.7280807 },
    { lat: 49.0907254, lng: 19.7280425 },
    { lat: 49.0906436, lng: 19.7278736 },
    { lat: 49.0906185, lng: 19.727764 },
    { lat: 49.0904252, lng: 19.7275446 },
    { lat: 49.0902388, lng: 19.7272497 },
    { lat: 49.0899938, lng: 19.726825 },
    { lat: 49.089943, lng: 19.726695 },
    { lat: 49.0898551, lng: 19.7265914 },
    { lat: 49.0899061, lng: 19.7265215 },
    { lat: 49.0904871, lng: 19.7255901 },
    { lat: 49.0907528, lng: 19.7246612 },
    { lat: 49.0908904, lng: 19.7245598 },
    { lat: 49.0909196, lng: 19.7244817 },
    { lat: 49.0911895, lng: 19.7249579 },
    { lat: 49.0913147, lng: 19.7250943 },
    { lat: 49.0914674, lng: 19.7251845 },
    { lat: 49.0916457, lng: 19.7251919 },
    { lat: 49.0930372, lng: 19.7234489 },
    { lat: 49.0941767, lng: 19.7218674 },
    { lat: 49.0946671, lng: 19.7205136 },
    { lat: 49.0955178, lng: 19.7192076 },
    { lat: 49.0958839, lng: 19.7187192 },
    { lat: 49.0954468, lng: 19.7176396 },
    { lat: 49.094981, lng: 19.7166567 },
    { lat: 49.0939566, lng: 19.7140329 },
    { lat: 49.0933939, lng: 19.7129439 },
    { lat: 49.0933733, lng: 19.7129104 },
    { lat: 49.093088, lng: 19.7123658 },
    { lat: 49.0930661, lng: 19.7123299 },
    { lat: 49.0927274, lng: 19.7116696 },
    { lat: 49.0918876, lng: 19.7113789 },
    { lat: 49.0913838, lng: 19.7107074 },
    { lat: 49.0908427, lng: 19.7102126 },
    { lat: 49.0904768, lng: 19.7108372 },
  ],
  Konská: [
    { lat: 49.1736395, lng: 19.7412707 },
    { lat: 49.173598, lng: 19.7412238 },
    { lat: 49.1734984, lng: 19.7408644 },
    { lat: 49.1734975, lng: 19.740187 },
    { lat: 49.1727582, lng: 19.7398962 },
    { lat: 49.1717598, lng: 19.7392178 },
    { lat: 49.1714743, lng: 19.7386625 },
    { lat: 49.170916, lng: 19.7383167 },
    { lat: 49.17048, lng: 19.7379772 },
    { lat: 49.1700018, lng: 19.7374764 },
    { lat: 49.1691011, lng: 19.7366348 },
    { lat: 49.1681578, lng: 19.7361651 },
    { lat: 49.1678337, lng: 19.7358095 },
    { lat: 49.1673203, lng: 19.7355078 },
    { lat: 49.1672174, lng: 19.7359608 },
    { lat: 49.1668952, lng: 19.7362214 },
    { lat: 49.1662771, lng: 19.7360466 },
    { lat: 49.1657681, lng: 19.7360285 },
    { lat: 49.1653421, lng: 19.7357392 },
    { lat: 49.1642759, lng: 19.7352399 },
    { lat: 49.1638144, lng: 19.7348339 },
    { lat: 49.1627601, lng: 19.7341902 },
    { lat: 49.1625454, lng: 19.7341757 },
    { lat: 49.1614678, lng: 19.7343662 },
    { lat: 49.1611505, lng: 19.7346357 },
    { lat: 49.1606383, lng: 19.73516 },
    { lat: 49.1582667, lng: 19.7344839 },
    { lat: 49.1565065, lng: 19.7332336 },
    { lat: 49.1555307, lng: 19.7324038 },
    { lat: 49.1545415, lng: 19.7317044 },
    { lat: 49.1536648, lng: 19.7316747 },
    { lat: 49.1532687, lng: 19.731168 },
    { lat: 49.1522459, lng: 19.7293181 },
    { lat: 49.151327, lng: 19.7283971 },
    { lat: 49.1508136, lng: 19.7268349 },
    { lat: 49.1501136, lng: 19.7235362 },
    { lat: 49.1496851, lng: 19.7221133 },
    { lat: 49.1487676, lng: 19.7185022 },
    { lat: 49.1486587, lng: 19.7175644 },
    { lat: 49.148566, lng: 19.7167317 },
    { lat: 49.1486485, lng: 19.7155066 },
    { lat: 49.1484396, lng: 19.7149809 },
    { lat: 49.1482716, lng: 19.714826 },
    { lat: 49.1457478, lng: 19.7119448 },
    { lat: 49.1451672, lng: 19.7112923 },
    { lat: 49.1436741, lng: 19.7098204 },
    { lat: 49.1430942, lng: 19.7093391 },
    { lat: 49.1424996, lng: 19.7089112 },
    { lat: 49.1408326, lng: 19.7081995 },
    { lat: 49.1408027, lng: 19.708186 },
    { lat: 49.1390922, lng: 19.7074555 },
    { lat: 49.1390825, lng: 19.7072366 },
    { lat: 49.1382048, lng: 19.7066178 },
    { lat: 49.1365587, lng: 19.7048471 },
    { lat: 49.1366472, lng: 19.7046326 },
    { lat: 49.1365617, lng: 19.7045337 },
    { lat: 49.1356576, lng: 19.7036727 },
    { lat: 49.1356322, lng: 19.7036573 },
    { lat: 49.1352321, lng: 19.7033219 },
    { lat: 49.1336722, lng: 19.7018483 },
    { lat: 49.1325176, lng: 19.7008501 },
    { lat: 49.1324012, lng: 19.7006137 },
    { lat: 49.1323829, lng: 19.7004566 },
    { lat: 49.1307106, lng: 19.6991375 },
    { lat: 49.1296269, lng: 19.698272 },
    { lat: 49.1295047, lng: 19.698082 },
    { lat: 49.1293496, lng: 19.6981878 },
    { lat: 49.1292167, lng: 19.6981942 },
    { lat: 49.1289869, lng: 19.6982926 },
    { lat: 49.1287535, lng: 19.6982157 },
    { lat: 49.1285663, lng: 19.6983115 },
    { lat: 49.1284464, lng: 19.6983022 },
    { lat: 49.1281566, lng: 19.6984203 },
    { lat: 49.127971, lng: 19.6984792 },
    { lat: 49.1277707, lng: 19.6984335 },
    { lat: 49.1275499, lng: 19.6984586 },
    { lat: 49.1273283, lng: 19.6985194 },
    { lat: 49.1271918, lng: 19.6986143 },
    { lat: 49.1269133, lng: 19.699099 },
    { lat: 49.1267626, lng: 19.6993853 },
    { lat: 49.1267542, lng: 19.6996516 },
    { lat: 49.1266257, lng: 19.6998306 },
    { lat: 49.1264552, lng: 19.6998469 },
    { lat: 49.1265037, lng: 19.7001546 },
    { lat: 49.1265966, lng: 19.7004994 },
    { lat: 49.1267107, lng: 19.7009683 },
    { lat: 49.125862, lng: 19.7019821 },
    { lat: 49.1258246, lng: 19.7021294 },
    { lat: 49.1255363, lng: 19.7023362 },
    { lat: 49.1251458, lng: 19.7024765 },
    { lat: 49.1246526, lng: 19.7026359 },
    { lat: 49.1243973, lng: 19.7027465 },
    { lat: 49.1241658, lng: 19.7028601 },
    { lat: 49.124009, lng: 19.7029887 },
    { lat: 49.1233429, lng: 19.703773 },
    { lat: 49.1230075, lng: 19.7039576 },
    { lat: 49.1227404, lng: 19.7041853 },
    { lat: 49.1225505, lng: 19.7044028 },
    { lat: 49.1224925, lng: 19.7044429 },
    { lat: 49.1219995, lng: 19.7046077 },
    { lat: 49.1216625, lng: 19.7045497 },
    { lat: 49.1214731, lng: 19.7045708 },
    { lat: 49.1212421, lng: 19.7046739 },
    { lat: 49.1211558, lng: 19.7047367 },
    { lat: 49.1210579, lng: 19.7048391 },
    { lat: 49.1208581, lng: 19.7049829 },
    { lat: 49.120735, lng: 19.7050282 },
    { lat: 49.120537, lng: 19.7050628 },
    { lat: 49.1198439, lng: 19.705261 },
    { lat: 49.1192353, lng: 19.7055141 },
    { lat: 49.1190102, lng: 19.7055918 },
    { lat: 49.1188994, lng: 19.7056581 },
    { lat: 49.1186413, lng: 19.705887 },
    { lat: 49.1184199, lng: 19.7039516 },
    { lat: 49.1182635, lng: 19.7035464 },
    { lat: 49.118215, lng: 19.7032917 },
    { lat: 49.1175331, lng: 19.7036444 },
    { lat: 49.1173957, lng: 19.7037462 },
    { lat: 49.1173602, lng: 19.7036053 },
    { lat: 49.1172652, lng: 19.7030334 },
    { lat: 49.1171094, lng: 19.7020528 },
    { lat: 49.1164668, lng: 19.702299 },
    { lat: 49.1162247, lng: 19.7026068 },
    { lat: 49.1153622, lng: 19.7028251 },
    { lat: 49.1136825, lng: 19.7026997 },
    { lat: 49.1132182, lng: 19.7026698 },
    { lat: 49.1129018, lng: 19.7030071 },
    { lat: 49.1122297, lng: 19.7035527 },
    { lat: 49.1115498, lng: 19.703849 },
    { lat: 49.1108175, lng: 19.7039774 },
    { lat: 49.110837, lng: 19.7041351 },
    { lat: 49.1109648, lng: 19.7052753 },
    { lat: 49.1110593, lng: 19.7061406 },
    { lat: 49.1110982, lng: 19.7064394 },
    { lat: 49.111043, lng: 19.707589 },
    { lat: 49.1111093, lng: 19.710176 },
    { lat: 49.111044, lng: 19.7110684 },
    { lat: 49.1109078, lng: 19.7118964 },
    { lat: 49.1108626, lng: 19.7120988 },
    { lat: 49.1105111, lng: 19.7137845 },
    { lat: 49.1104705, lng: 19.7139674 },
    { lat: 49.1103574, lng: 19.7148812 },
    { lat: 49.1102926, lng: 19.7153312 },
    { lat: 49.1101546, lng: 19.7164186 },
    { lat: 49.1101175, lng: 19.7166001 },
    { lat: 49.1095244, lng: 19.7201795 },
    { lat: 49.1098865, lng: 19.7203192 },
    { lat: 49.1100315, lng: 19.72044 },
    { lat: 49.1101461, lng: 19.7206135 },
    { lat: 49.1101002, lng: 19.7208001 },
    { lat: 49.1114368, lng: 19.7214292 },
    { lat: 49.1117581, lng: 19.7216378 },
    { lat: 49.1121465, lng: 19.7219687 },
    { lat: 49.1127019, lng: 19.7226609 },
    { lat: 49.1131749, lng: 19.7234298 },
    { lat: 49.1141538, lng: 19.7251714 },
    { lat: 49.1143578, lng: 19.7254837 },
    { lat: 49.1151595, lng: 19.7263745 },
    { lat: 49.1151766, lng: 19.7264041 },
    { lat: 49.1155801, lng: 19.7268935 },
    { lat: 49.1162401, lng: 19.7274316 },
    { lat: 49.1167149, lng: 19.727729 },
    { lat: 49.1174912, lng: 19.7283995 },
    { lat: 49.1181201, lng: 19.7287125 },
    { lat: 49.118107, lng: 19.7290569 },
    { lat: 49.1187494, lng: 19.7293491 },
    { lat: 49.1189339, lng: 19.7293951 },
    { lat: 49.1204094, lng: 19.7301568 },
    { lat: 49.1218046, lng: 19.7309649 },
    { lat: 49.1222972, lng: 19.730949 },
    { lat: 49.1236453, lng: 19.7308868 },
    { lat: 49.1247291, lng: 19.7307097 },
    { lat: 49.1264663, lng: 19.7309957 },
    { lat: 49.1280904, lng: 19.7316026 },
    { lat: 49.129439, lng: 19.7314025 },
    { lat: 49.1302793, lng: 19.7313466 },
    { lat: 49.1314155, lng: 19.7319013 },
    { lat: 49.1318524, lng: 19.7316155 },
    { lat: 49.1333001, lng: 19.7317202 },
    { lat: 49.1345185, lng: 19.731705 },
    { lat: 49.1356889, lng: 19.731473 },
    { lat: 49.136087, lng: 19.7319244 },
    { lat: 49.1359139, lng: 19.7322884 },
    { lat: 49.1358691, lng: 19.7327315 },
    { lat: 49.1356193, lng: 19.7342104 },
    { lat: 49.1354076, lng: 19.7350094 },
    { lat: 49.1353331, lng: 19.7356225 },
    { lat: 49.1351206, lng: 19.7366647 },
    { lat: 49.1348707, lng: 19.7373136 },
    { lat: 49.1345754, lng: 19.7388064 },
    { lat: 49.1343509, lng: 19.7424106 },
    { lat: 49.1344166, lng: 19.7427522 },
    { lat: 49.1345023, lng: 19.7429572 },
    { lat: 49.1347774, lng: 19.7427588 },
    { lat: 49.1353937, lng: 19.7424239 },
    { lat: 49.1360787, lng: 19.7426336 },
    { lat: 49.1364328, lng: 19.742411 },
    { lat: 49.1369702, lng: 19.7428235 },
    { lat: 49.1376964, lng: 19.7425832 },
    { lat: 49.1380232, lng: 19.7420647 },
    { lat: 49.1385285, lng: 19.7420314 },
    { lat: 49.1387813, lng: 19.7419119 },
    { lat: 49.1391796, lng: 19.7414631 },
    { lat: 49.1394755, lng: 19.7415032 },
    { lat: 49.1396588, lng: 19.7413457 },
    { lat: 49.1399065, lng: 19.741389 },
    { lat: 49.1405991, lng: 19.7412053 },
    { lat: 49.1411464, lng: 19.7414074 },
    { lat: 49.1414162, lng: 19.7413153 },
    { lat: 49.1416422, lng: 19.7410484 },
    { lat: 49.1419397, lng: 19.7409732 },
    { lat: 49.1422624, lng: 19.7407782 },
    { lat: 49.1427179, lng: 19.7408673 },
    { lat: 49.143026, lng: 19.740696 },
    { lat: 49.1432643, lng: 19.7407909 },
    { lat: 49.1435563, lng: 19.7405907 },
    { lat: 49.1442088, lng: 19.7411852 },
    { lat: 49.1444846, lng: 19.7412849 },
    { lat: 49.1451373, lng: 19.7403928 },
    { lat: 49.1458353, lng: 19.7403799 },
    { lat: 49.1464291, lng: 19.7401027 },
    { lat: 49.1467448, lng: 19.7401365 },
    { lat: 49.1471082, lng: 19.7400733 },
    { lat: 49.1474325, lng: 19.7401689 },
    { lat: 49.1477917, lng: 19.7400932 },
    { lat: 49.148027, lng: 19.7400646 },
    { lat: 49.1486058, lng: 19.7396946 },
    { lat: 49.1493792, lng: 19.7395293 },
    { lat: 49.1500282, lng: 19.739557 },
    { lat: 49.1504685, lng: 19.7391643 },
    { lat: 49.1511537, lng: 19.7394816 },
    { lat: 49.1515123, lng: 19.739335 },
    { lat: 49.1518069, lng: 19.7396319 },
    { lat: 49.1521064, lng: 19.7398855 },
    { lat: 49.1521811, lng: 19.7399966 },
    { lat: 49.1525033, lng: 19.7407956 },
    { lat: 49.1532094, lng: 19.7410504 },
    { lat: 49.1534167, lng: 19.7417754 },
    { lat: 49.1535474, lng: 19.7428339 },
    { lat: 49.1536683, lng: 19.7435112 },
    { lat: 49.1543504, lng: 19.7458991 },
    { lat: 49.1550126, lng: 19.7472101 },
    { lat: 49.1559226, lng: 19.7479024 },
    { lat: 49.156792, lng: 19.7485481 },
    { lat: 49.1575949, lng: 19.7492363 },
    { lat: 49.1578873, lng: 19.7499499 },
    { lat: 49.15863, lng: 19.7507029 },
    { lat: 49.1590101, lng: 19.7516196 },
    { lat: 49.1597396, lng: 19.7532378 },
    { lat: 49.1602908, lng: 19.7543316 },
    { lat: 49.1609875, lng: 19.7556908 },
    { lat: 49.1641132, lng: 19.7529629 },
    { lat: 49.1650512, lng: 19.752545 },
    { lat: 49.167459, lng: 19.7506908 },
    { lat: 49.1682954, lng: 19.7497219 },
    { lat: 49.1686986, lng: 19.7494085 },
    { lat: 49.1689103, lng: 19.7490169 },
    { lat: 49.1694077, lng: 19.7483358 },
    { lat: 49.1698952, lng: 19.7476815 },
    { lat: 49.1703838, lng: 19.7471337 },
    { lat: 49.1708788, lng: 19.746911 },
    { lat: 49.1715513, lng: 19.7468824 },
    { lat: 49.1718202, lng: 19.7466712 },
    { lat: 49.1722736, lng: 19.7456247 },
    { lat: 49.172392, lng: 19.7446982 },
    { lat: 49.1724633, lng: 19.7444976 },
    { lat: 49.173044, lng: 19.7434752 },
    { lat: 49.1734793, lng: 19.742609 },
    { lat: 49.1736456, lng: 19.7418753 },
    { lat: 49.1736395, lng: 19.7412707 },
  ],
  KráľovaLehota: [
    { lat: 49.0136584, lng: 19.8539107 },
    { lat: 49.0135178, lng: 19.8533379 },
    { lat: 49.0136111, lng: 19.8521498 },
    { lat: 49.0133046, lng: 19.8514086 },
    { lat: 49.0130555, lng: 19.850905 },
    { lat: 49.0130341, lng: 19.8505167 },
    { lat: 49.0131371, lng: 19.8502094 },
    { lat: 49.0132906, lng: 19.8496649 },
    { lat: 49.0134536, lng: 19.8493171 },
    { lat: 49.0135862, lng: 19.8492039 },
    { lat: 49.0139079, lng: 19.8489832 },
    { lat: 49.0143095, lng: 19.8486758 },
    { lat: 49.0144223, lng: 19.8485479 },
    { lat: 49.0144187, lng: 19.8482067 },
    { lat: 49.0145242, lng: 19.8481671 },
    { lat: 49.0146388, lng: 19.8480822 },
    { lat: 49.0145671, lng: 19.8469872 },
    { lat: 49.0145026, lng: 19.8463746 },
    { lat: 49.0144066, lng: 19.8458713 },
    { lat: 49.0141776, lng: 19.8449997 },
    { lat: 49.0140659, lng: 19.8447071 },
    { lat: 49.0139788, lng: 19.8444599 },
    { lat: 49.0138837, lng: 19.8442127 },
    { lat: 49.0137502, lng: 19.8435165 },
    { lat: 49.0136119, lng: 19.8435492 },
    { lat: 49.0133755, lng: 19.8434991 },
    { lat: 49.0130879, lng: 19.8434903 },
    { lat: 49.0126983, lng: 19.843207 },
    { lat: 49.0125105, lng: 19.8429242 },
    { lat: 49.0124495, lng: 19.8427135 },
    { lat: 49.0125066, lng: 19.8425474 },
    { lat: 49.0129264, lng: 19.8426156 },
    { lat: 49.0130001, lng: 19.8426815 },
    { lat: 49.0131006, lng: 19.8426105 },
    { lat: 49.0131004, lng: 19.8423131 },
    { lat: 49.0134383, lng: 19.8422067 },
    { lat: 49.0135872, lng: 19.8421341 },
    { lat: 49.0137689, lng: 19.8418789 },
    { lat: 49.0138809, lng: 19.8417881 },
    { lat: 49.0136837, lng: 19.8412565 },
    { lat: 49.0135577, lng: 19.8406313 },
    { lat: 49.0133464, lng: 19.8397795 },
    { lat: 49.0131067, lng: 19.8380775 },
    { lat: 49.0127795, lng: 19.8369893 },
    { lat: 49.012726, lng: 19.8367448 },
    { lat: 49.0125943, lng: 19.8367712 },
    { lat: 49.012498, lng: 19.8368105 },
    { lat: 49.0123676, lng: 19.8368199 },
    { lat: 49.0122936, lng: 19.8366148 },
    { lat: 49.0121507, lng: 19.8364359 },
    { lat: 49.0120216, lng: 19.8363421 },
    { lat: 49.0118913, lng: 19.8360902 },
    { lat: 49.0117531, lng: 19.8357591 },
    { lat: 49.0116583, lng: 19.835124 },
    { lat: 49.0117551, lng: 19.8347293 },
    { lat: 49.0119918, lng: 19.8344148 },
    { lat: 49.0121082, lng: 19.8341341 },
    { lat: 49.0122871, lng: 19.8341558 },
    { lat: 49.0122663, lng: 19.8330953 },
    { lat: 49.01228, lng: 19.8319488 },
    { lat: 49.0124072, lng: 19.830927 },
    { lat: 49.0124352, lng: 19.8301351 },
    { lat: 49.0124999, lng: 19.8289437 },
    { lat: 49.01252, lng: 19.8284189 },
    { lat: 49.0127278, lng: 19.8273568 },
    { lat: 49.0127708, lng: 19.8271476 },
    { lat: 49.0127786, lng: 19.826677 },
    { lat: 49.0126889, lng: 19.8263712 },
    { lat: 49.0123565, lng: 19.8254548 },
    { lat: 49.0122987, lng: 19.8250557 },
    { lat: 49.0123167, lng: 19.8249247 },
    { lat: 49.0124949, lng: 19.8244035 },
    { lat: 49.0126967, lng: 19.8237558 },
    { lat: 49.012834, lng: 19.8230969 },
    { lat: 49.0131525, lng: 19.8220948 },
    { lat: 49.0131719, lng: 19.8216568 },
    { lat: 49.013222, lng: 19.8206011 },
    { lat: 49.0132599, lng: 19.8200407 },
    { lat: 49.0134337, lng: 19.8188477 },
    { lat: 49.0137067, lng: 19.8179416 },
    { lat: 49.013886, lng: 19.8173721 },
    { lat: 49.0141759, lng: 19.8169338 },
    { lat: 49.0142393, lng: 19.8167347 },
    { lat: 49.0142411, lng: 19.8163322 },
    { lat: 49.0141366, lng: 19.8157645 },
    { lat: 49.0141062, lng: 19.8151912 },
    { lat: 49.0141227, lng: 19.8146605 },
    { lat: 49.0142372, lng: 19.8134734 },
    { lat: 49.0142981, lng: 19.8131903 },
    { lat: 49.0144745, lng: 19.8126064 },
    { lat: 49.0146512, lng: 19.8121818 },
    { lat: 49.0148131, lng: 19.811913 },
    { lat: 49.0148335, lng: 19.8118675 },
    { lat: 49.0149624, lng: 19.8115119 },
    { lat: 49.0149962, lng: 19.8114088 },
    { lat: 49.0151735, lng: 19.8109999 },
    { lat: 49.0154867, lng: 19.8103485 },
    { lat: 49.0158206, lng: 19.8097614 },
    { lat: 49.0158432, lng: 19.8096059 },
    { lat: 49.0159758, lng: 19.8094688 },
    { lat: 49.0162813, lng: 19.8094957 },
    { lat: 49.0168478, lng: 19.8096254 },
    { lat: 49.0171986, lng: 19.8096866 },
    { lat: 49.0174386, lng: 19.8096906 },
    { lat: 49.0178234, lng: 19.8096253 },
    { lat: 49.0184469, lng: 19.8092217 },
    { lat: 49.0184812, lng: 19.8092075 },
    { lat: 49.0185678, lng: 19.8092017 },
    { lat: 49.0186391, lng: 19.8092118 },
    { lat: 49.0189428, lng: 19.8086259 },
    { lat: 49.019094, lng: 19.8081902 },
    { lat: 49.0192007, lng: 19.8079717 },
    { lat: 49.0198668, lng: 19.8066489 },
    { lat: 49.0199362, lng: 19.8064309 },
    { lat: 49.0199575, lng: 19.8055419 },
    { lat: 49.0200347, lng: 19.8049287 },
    { lat: 49.020164, lng: 19.8042729 },
    { lat: 49.0204537, lng: 19.8031816 },
    { lat: 49.0205637, lng: 19.8030464 },
    { lat: 49.020659, lng: 19.8026675 },
    { lat: 49.0210028, lng: 19.8015283 },
    { lat: 49.0212001, lng: 19.8009336 },
    { lat: 49.0214942, lng: 19.8001314 },
    { lat: 49.0218145, lng: 19.7992799 },
    { lat: 49.0221121, lng: 19.7982967 },
    { lat: 49.0223205, lng: 19.7976183 },
    { lat: 49.0223929, lng: 19.7972363 },
    { lat: 49.0227204, lng: 19.7953288 },
    { lat: 49.0227614, lng: 19.7951531 },
    { lat: 49.0229178, lng: 19.7943714 },
    { lat: 49.0229689, lng: 19.7941459 },
    { lat: 49.0230233, lng: 19.7940012 },
    { lat: 49.0231815, lng: 19.7936206 },
    { lat: 49.0235019, lng: 19.7930322 },
    { lat: 49.0236971, lng: 19.7926989 },
    { lat: 49.0239506, lng: 19.79234 },
    { lat: 49.0242884, lng: 19.7918155 },
    { lat: 49.0246111, lng: 19.7914084 },
    { lat: 49.0246965, lng: 19.7912498 },
    { lat: 49.0247789, lng: 19.7909655 },
    { lat: 49.0249394, lng: 19.7905886 },
    { lat: 49.0251225, lng: 19.7901355 },
    { lat: 49.025246, lng: 19.7898428 },
    { lat: 49.025241, lng: 19.78975 },
    { lat: 49.0252519, lng: 19.7894833 },
    { lat: 49.025324, lng: 19.7892739 },
    { lat: 49.0254177, lng: 19.7890229 },
    { lat: 49.0256166, lng: 19.7884649 },
    { lat: 49.0257971, lng: 19.7876133 },
    { lat: 49.0260526, lng: 19.7869027 },
    { lat: 49.026165, lng: 19.786643 },
    { lat: 49.0262404, lng: 19.7862481 },
    { lat: 49.0263814, lng: 19.7859396 },
    { lat: 49.0264784, lng: 19.7857856 },
    { lat: 49.0267293, lng: 19.7854158 },
    { lat: 49.0268658, lng: 19.785265 },
    { lat: 49.0270801, lng: 19.785013 },
    { lat: 49.0272572, lng: 19.7847579 },
    { lat: 49.0277132, lng: 19.7841313 },
    { lat: 49.0278885, lng: 19.784015 },
    { lat: 49.0278274, lng: 19.7839827 },
    { lat: 49.027791, lng: 19.7839494 },
    { lat: 49.02766, lng: 19.7837109 },
    { lat: 49.0275688, lng: 19.783579 },
    { lat: 49.0274864, lng: 19.7834196 },
    { lat: 49.0273722, lng: 19.7832516 },
    { lat: 49.027342, lng: 19.7831825 },
    { lat: 49.0272103, lng: 19.7828191 },
    { lat: 49.0271417, lng: 19.7827176 },
    { lat: 49.0271404, lng: 19.7827145 },
    { lat: 49.0270137, lng: 19.7825201 },
    { lat: 49.0268813, lng: 19.7823521 },
    { lat: 49.0268083, lng: 19.7822801 },
    { lat: 49.0266975, lng: 19.782191 },
    { lat: 49.0264248, lng: 19.7821413 },
    { lat: 49.026252, lng: 19.7820771 },
    { lat: 49.026027, lng: 19.7819299 },
    { lat: 49.0258276, lng: 19.7817195 },
    { lat: 49.0257168, lng: 19.7815048 },
    { lat: 49.0256326, lng: 19.7813273 },
    { lat: 49.0255727, lng: 19.7813028 },
    { lat: 49.0250317, lng: 19.7811615 },
    { lat: 49.0248718, lng: 19.7811101 },
    { lat: 49.0245837, lng: 19.7810432 },
    { lat: 49.0243186, lng: 19.7810601 },
    { lat: 49.0241513, lng: 19.7810234 },
    { lat: 49.0240013, lng: 19.7809664 },
    { lat: 49.023868, lng: 19.7809026 },
    { lat: 49.0236204, lng: 19.7806482 },
    { lat: 49.0234749, lng: 19.7803857 },
    { lat: 49.0234168, lng: 19.7802942 },
    { lat: 49.0232452, lng: 19.7798969 },
    { lat: 49.0231574, lng: 19.7796637 },
    { lat: 49.0231527, lng: 19.7796366 },
    { lat: 49.0231527, lng: 19.7793483 },
    { lat: 49.0231696, lng: 19.7791467 },
    { lat: 49.0231673, lng: 19.7789351 },
    { lat: 49.0231293, lng: 19.778837 },
    { lat: 49.0230815, lng: 19.7787485 },
    { lat: 49.0230155, lng: 19.7786575 },
    { lat: 49.0228284, lng: 19.7785252 },
    { lat: 49.0225707, lng: 19.7784017 },
    { lat: 49.0223733, lng: 19.7783674 },
    { lat: 49.0221996, lng: 19.7783152 },
    { lat: 49.0220079, lng: 19.7782063 },
    { lat: 49.0219663, lng: 19.7782037 },
    { lat: 49.0216924, lng: 19.7781494 },
    { lat: 49.0214368, lng: 19.778053 },
    { lat: 49.0213643, lng: 19.778014 },
    { lat: 49.0212895, lng: 19.7780043 },
    { lat: 49.0211938, lng: 19.777903 },
    { lat: 49.0210792, lng: 19.7778043 },
    { lat: 49.0209941, lng: 19.7777134 },
    { lat: 49.0207735, lng: 19.7774337 },
    { lat: 49.0205831, lng: 19.7772264 },
    { lat: 49.0203706, lng: 19.7770557 },
    { lat: 49.0199855, lng: 19.7769024 },
    { lat: 49.0197883, lng: 19.7768522 },
    { lat: 49.0196158, lng: 19.7767942 },
    { lat: 49.0195055, lng: 19.7767697 },
    { lat: 49.0193829, lng: 19.7767243 },
    { lat: 49.0191433, lng: 19.7766554 },
    { lat: 49.0188774, lng: 19.7766534 },
    { lat: 49.0187696, lng: 19.7766122 },
    { lat: 49.0186487, lng: 19.7766003 },
    { lat: 49.0185785, lng: 19.7765434 },
    { lat: 49.0185143, lng: 19.7764401 },
    { lat: 49.0184304, lng: 19.7762213 },
    { lat: 49.0183668, lng: 19.7760009 },
    { lat: 49.0183051, lng: 19.7758736 },
    { lat: 49.0182028, lng: 19.7757266 },
    { lat: 49.0181137, lng: 19.7756197 },
    { lat: 49.0179593, lng: 19.7754141 },
    { lat: 49.0178621, lng: 19.7753015 },
    { lat: 49.0177492, lng: 19.7751384 },
    { lat: 49.017579, lng: 19.7749577 },
    { lat: 49.0174654, lng: 19.7748823 },
    { lat: 49.0173435, lng: 19.7747488 },
    { lat: 49.0172143, lng: 19.7746287 },
    { lat: 49.017127, lng: 19.7745089 },
    { lat: 49.0169725, lng: 19.7742747 },
    { lat: 49.0168371, lng: 19.7740381 },
    { lat: 49.0167208, lng: 19.7738761 },
    { lat: 49.0166162, lng: 19.7737463 },
    { lat: 49.0165435, lng: 19.7736316 },
    { lat: 49.0164503, lng: 19.7735071 },
    { lat: 49.0162488, lng: 19.7732018 },
    { lat: 49.0161362, lng: 19.7730502 },
    { lat: 49.0160033, lng: 19.7728436 },
    { lat: 49.0158433, lng: 19.7727369 },
    { lat: 49.0157509, lng: 19.7727347 },
    { lat: 49.0154981, lng: 19.7727774 },
    { lat: 49.0153387, lng: 19.7728695 },
    { lat: 49.0150402, lng: 19.7728931 },
    { lat: 49.0149292, lng: 19.7728812 },
    { lat: 49.0146957, lng: 19.7727942 },
    { lat: 49.0145353, lng: 19.7726829 },
    { lat: 49.0142527, lng: 19.7724554 },
    { lat: 49.0141727, lng: 19.7723526 },
    { lat: 49.0140434, lng: 19.7722027 },
    { lat: 49.0139407, lng: 19.77212 },
    { lat: 49.0138334, lng: 19.7720092 },
    { lat: 49.0137296, lng: 19.7718272 },
    { lat: 49.0136511, lng: 19.7717307 },
    { lat: 49.0135619, lng: 19.7716464 },
    { lat: 49.0134869, lng: 19.7715556 },
    { lat: 49.0132383, lng: 19.7714349 },
    { lat: 49.0131318, lng: 19.7713412 },
    { lat: 49.0129716, lng: 19.7712582 },
    { lat: 49.0128371, lng: 19.7711731 },
    { lat: 49.0126672, lng: 19.7710972 },
    { lat: 49.0124269, lng: 19.7709351 },
    { lat: 49.0122537, lng: 19.7708657 },
    { lat: 49.0119627, lng: 19.7706739 },
    { lat: 49.0118713, lng: 19.7706189 },
    { lat: 49.0117862, lng: 19.7705287 },
    { lat: 49.0117055, lng: 19.7704579 },
    { lat: 49.0114434, lng: 19.7702087 },
    { lat: 49.0113397, lng: 19.7701032 },
    { lat: 49.0112294, lng: 19.7700325 },
    { lat: 49.0111204, lng: 19.7700381 },
    { lat: 49.0110028, lng: 19.7700262 },
    { lat: 49.0107657, lng: 19.770091 },
    { lat: 49.0106418, lng: 19.7700916 },
    { lat: 49.0105195, lng: 19.7700515 },
    { lat: 49.0103341, lng: 19.770012 },
    { lat: 49.010192, lng: 19.7700845 },
    { lat: 49.010087, lng: 19.7701065 },
    { lat: 49.0100369, lng: 19.770094 },
    { lat: 49.0099304, lng: 19.7700293 },
    { lat: 49.0098929, lng: 19.7699696 },
    { lat: 49.0097657, lng: 19.7698191 },
    { lat: 49.0095646, lng: 19.7692077 },
    { lat: 49.0093584, lng: 19.7685115 },
    { lat: 49.0092443, lng: 19.7682169 },
    { lat: 49.0091758, lng: 19.768091 },
    { lat: 49.0090785, lng: 19.7679806 },
    { lat: 49.0087567, lng: 19.7678077 },
    { lat: 49.0083541, lng: 19.7677194 },
    { lat: 49.0079278, lng: 19.7675291 },
    { lat: 49.0076902, lng: 19.7674575 },
    { lat: 49.007549, lng: 19.7674441 },
    { lat: 49.0073808, lng: 19.7674632 },
    { lat: 49.0071807, lng: 19.7675264 },
    { lat: 49.0065993, lng: 19.7677543 },
    { lat: 49.0063026, lng: 19.7677235 },
    { lat: 49.0062468, lng: 19.7676832 },
    { lat: 49.0052905, lng: 19.7667514 },
    { lat: 49.0049235, lng: 19.7663746 },
    { lat: 49.0048759, lng: 19.7663389 },
    { lat: 49.0047587, lng: 19.7662882 },
    { lat: 49.0046496, lng: 19.7663179 },
    { lat: 49.0044428, lng: 19.7664051 },
    { lat: 49.0037069, lng: 19.7670217 },
    { lat: 49.0035696, lng: 19.7671005 },
    { lat: 49.0034098, lng: 19.7671335 },
    { lat: 49.0031172, lng: 19.7671615 },
    { lat: 49.0027534, lng: 19.7671403 },
    { lat: 49.0022796, lng: 19.7672352 },
    { lat: 49.0019428, lng: 19.7673855 },
    { lat: 49.0018874, lng: 19.7674317 },
    { lat: 49.0015509, lng: 19.7679539 },
    { lat: 49.0014165, lng: 19.7681307 },
    { lat: 49.0013123, lng: 19.7681932 },
    { lat: 49.0010665, lng: 19.7682412 },
    { lat: 49.0009844, lng: 19.7682208 },
    { lat: 49.0009044, lng: 19.7681137 },
    { lat: 49.0008327, lng: 19.7679677 },
    { lat: 49.000824, lng: 19.7677694 },
    { lat: 49.0007806, lng: 19.7675747 },
    { lat: 49.0007128, lng: 19.7674659 },
    { lat: 49.0006093, lng: 19.767419 },
    { lat: 49.0004327, lng: 19.7673983 },
    { lat: 49.0003493, lng: 19.7673744 },
    { lat: 49.0002164, lng: 19.7673753 },
    { lat: 49.000103, lng: 19.7673562 },
    { lat: 48.9999291, lng: 19.7673966 },
    { lat: 48.9997238, lng: 19.7675978 },
    { lat: 48.9996042, lng: 19.7676446 },
    { lat: 48.9994061, lng: 19.7676718 },
    { lat: 48.9993171, lng: 19.7676258 },
    { lat: 48.9992585, lng: 19.767523 },
    { lat: 48.9991973, lng: 19.7673813 },
    { lat: 48.9991609, lng: 19.7672444 },
    { lat: 48.9991349, lng: 19.7670098 },
    { lat: 48.9991232, lng: 19.7668442 },
    { lat: 48.9991231, lng: 19.7666641 },
    { lat: 48.9990299, lng: 19.7668769 },
    { lat: 48.9985817, lng: 19.7675782 },
    { lat: 48.9981563, lng: 19.7682611 },
    { lat: 48.9980907, lng: 19.7683577 },
    { lat: 48.9980703, lng: 19.7684039 },
    { lat: 48.9980331, lng: 19.7684604 },
    { lat: 48.9980498, lng: 19.7685032 },
    { lat: 48.9980538, lng: 19.7685455 },
    { lat: 48.9980433, lng: 19.7685875 },
    { lat: 48.9979595, lng: 19.7687053 },
    { lat: 48.9979701, lng: 19.7688456 },
    { lat: 48.9978093, lng: 19.7691388 },
    { lat: 48.997712, lng: 19.769207 },
    { lat: 48.9976287, lng: 19.7692352 },
    { lat: 48.9975614, lng: 19.769323 },
    { lat: 48.9974497, lng: 19.7696528 },
    { lat: 48.9974569, lng: 19.7698175 },
    { lat: 48.9974219, lng: 19.7699452 },
    { lat: 48.9973663, lng: 19.7700415 },
    { lat: 48.9973696, lng: 19.7700882 },
    { lat: 48.9973955, lng: 19.7701907 },
    { lat: 48.9973728, lng: 19.7702924 },
    { lat: 48.9973564, lng: 19.7704856 },
    { lat: 48.9972933, lng: 19.770586 },
    { lat: 48.9972911, lng: 19.7706658 },
    { lat: 48.9972489, lng: 19.7707824 },
    { lat: 48.9970916, lng: 19.7710246 },
    { lat: 48.9969743, lng: 19.7712297 },
    { lat: 48.9969736, lng: 19.7712632 },
    { lat: 48.9968904, lng: 19.771396 },
    { lat: 48.9968874, lng: 19.7714563 },
    { lat: 48.9967871, lng: 19.7715618 },
    { lat: 48.9967707, lng: 19.7716499 },
    { lat: 48.9967059, lng: 19.7717131 },
    { lat: 48.9966949, lng: 19.7719047 },
    { lat: 48.9965673, lng: 19.7720488 },
    { lat: 48.9965559, lng: 19.7721766 },
    { lat: 48.996446, lng: 19.7721902 },
    { lat: 48.9963522, lng: 19.7723629 },
    { lat: 48.9962923, lng: 19.7725163 },
    { lat: 48.9961855, lng: 19.7726221 },
    { lat: 48.9960696, lng: 19.7728067 },
    { lat: 48.9959682, lng: 19.7728575 },
    { lat: 48.9959623, lng: 19.7729061 },
    { lat: 48.9959161, lng: 19.772957 },
    { lat: 48.9958276, lng: 19.7730165 },
    { lat: 48.9956335, lng: 19.7732717 },
    { lat: 48.9952762, lng: 19.7733715 },
    { lat: 48.9951447, lng: 19.7734791 },
    { lat: 48.995028, lng: 19.773592 },
    { lat: 48.9947386, lng: 19.7737757 },
    { lat: 48.9945815, lng: 19.7738173 },
    { lat: 48.9943027, lng: 19.7738059 },
    { lat: 48.9941381, lng: 19.7737507 },
    { lat: 48.9939552, lng: 19.7737404 },
    { lat: 48.9937924, lng: 19.7737546 },
    { lat: 48.9936358, lng: 19.7738604 },
    { lat: 48.9935258, lng: 19.7740239 },
    { lat: 48.9933825, lng: 19.7741958 },
    { lat: 48.9931487, lng: 19.7743678 },
    { lat: 48.9927547, lng: 19.774298 },
    { lat: 48.9924339, lng: 19.7742481 },
    { lat: 48.9916951, lng: 19.7741265 },
    { lat: 48.9913119, lng: 19.7740721 },
    { lat: 48.9911116, lng: 19.7740728 },
    { lat: 48.9903689, lng: 19.7744596 },
    { lat: 48.9899345, lng: 19.774806 },
    { lat: 48.9892598, lng: 19.7752712 },
    { lat: 48.9889316, lng: 19.7756281 },
    { lat: 48.9886803, lng: 19.7759608 },
    { lat: 48.9884983, lng: 19.776124 },
    { lat: 48.9883051, lng: 19.7763722 },
    { lat: 48.9878701, lng: 19.7769924 },
    { lat: 48.9874657, lng: 19.777301 },
    { lat: 48.9871774, lng: 19.77736 },
    { lat: 48.9870344, lng: 19.7774856 },
    { lat: 48.9867371, lng: 19.7776198 },
    { lat: 48.9863913, lng: 19.7777503 },
    { lat: 48.9860193, lng: 19.7777956 },
    { lat: 48.9859958, lng: 19.7778507 },
    { lat: 48.9856249, lng: 19.7788815 },
    { lat: 48.985512, lng: 19.7794233 },
    { lat: 48.9852965, lng: 19.7797774 },
    { lat: 48.9852534, lng: 19.7802089 },
    { lat: 48.9851416, lng: 19.7807751 },
    { lat: 48.9851308, lng: 19.7808906 },
    { lat: 48.9852915, lng: 19.7811388 },
    { lat: 48.9853272, lng: 19.7815685 },
    { lat: 48.9853219, lng: 19.782045 },
    { lat: 48.9853156, lng: 19.782291 },
    { lat: 48.9853703, lng: 19.7824315 },
    { lat: 48.9854945, lng: 19.7826482 },
    { lat: 48.985608, lng: 19.7831307 },
    { lat: 48.985858, lng: 19.7834959 },
    { lat: 48.9858547, lng: 19.7838599 },
    { lat: 48.9858944, lng: 19.7843811 },
    { lat: 48.985979, lng: 19.7848238 },
    { lat: 48.9860458, lng: 19.7853774 },
    { lat: 48.9859842, lng: 19.7857272 },
    { lat: 48.9860031, lng: 19.7879237 },
    { lat: 48.985817, lng: 19.7889549 },
    { lat: 48.9853225, lng: 19.7900227 },
    { lat: 48.9846527, lng: 19.7909664 },
    { lat: 48.9839486, lng: 19.7913063 },
    { lat: 48.9835777, lng: 19.7914797 },
    { lat: 48.9835526, lng: 19.7921752 },
    { lat: 48.9834448, lng: 19.7925464 },
    { lat: 48.9834059, lng: 19.7931289 },
    { lat: 48.9832952, lng: 19.7934907 },
    { lat: 48.9833332, lng: 19.7940274 },
    { lat: 48.9834205, lng: 19.7941973 },
    { lat: 48.9834061, lng: 19.7951384 },
    { lat: 48.9834466, lng: 19.795577 },
    { lat: 48.9833066, lng: 19.7959859 },
    { lat: 48.9833555, lng: 19.7963332 },
    { lat: 48.9835424, lng: 19.7971122 },
    { lat: 48.9835297, lng: 19.7975438 },
    { lat: 48.9836397, lng: 19.7978481 },
    { lat: 48.983678, lng: 19.7982897 },
    { lat: 48.9838669, lng: 19.7990111 },
    { lat: 48.9838831, lng: 19.7994352 },
    { lat: 48.983951, lng: 19.79996 },
    { lat: 48.9843032, lng: 19.8006041 },
    { lat: 48.9844251, lng: 19.8009219 },
    { lat: 48.9845587, lng: 19.8013042 },
    { lat: 48.9850332, lng: 19.8022955 },
    { lat: 48.9846127, lng: 19.8033593 },
    { lat: 48.9843931, lng: 19.8039566 },
    { lat: 48.983441, lng: 19.8064551 },
    { lat: 48.983097, lng: 19.8071472 },
    { lat: 48.9829758, lng: 19.8074451 },
    { lat: 48.9827797, lng: 19.807965 },
    { lat: 48.9824952, lng: 19.8086505 },
    { lat: 48.9821181, lng: 19.8096475 },
    { lat: 48.9813821, lng: 19.8106549 },
    { lat: 48.9809821, lng: 19.8111761 },
    { lat: 48.9806714, lng: 19.8114423 },
    { lat: 48.9798635, lng: 19.8123474 },
    { lat: 48.9799113, lng: 19.8126722 },
    { lat: 48.980024, lng: 19.8133036 },
    { lat: 48.9801263, lng: 19.8144432 },
    { lat: 48.9801145, lng: 19.8149293 },
    { lat: 48.98008, lng: 19.815721 },
    { lat: 48.9800832, lng: 19.8167851 },
    { lat: 48.9801059, lng: 19.8176493 },
    { lat: 48.9800309, lng: 19.8180018 },
    { lat: 48.9799955, lng: 19.818564 },
    { lat: 48.9797614, lng: 19.8194029 },
    { lat: 48.9798956, lng: 19.8200856 },
    { lat: 48.9800734, lng: 19.8213332 },
    { lat: 48.9801366, lng: 19.8222249 },
    { lat: 48.9802201, lng: 19.8227545 },
    { lat: 48.9802643, lng: 19.8235445 },
    { lat: 48.9803733, lng: 19.8243474 },
    { lat: 48.9806703, lng: 19.824282 },
    { lat: 48.9810844, lng: 19.824196 },
    { lat: 48.9815588, lng: 19.8243037 },
    { lat: 48.981969, lng: 19.8244118 },
    { lat: 48.9820888, lng: 19.8244475 },
    { lat: 48.982373, lng: 19.8245828 },
    { lat: 48.9830933, lng: 19.8249369 },
    { lat: 48.9833497, lng: 19.8250581 },
    { lat: 48.9836135, lng: 19.8258983 },
    { lat: 48.9840745, lng: 19.8266315 },
    { lat: 48.9845517, lng: 19.827381 },
    { lat: 48.9846739, lng: 19.8276314 },
    { lat: 48.9849127, lng: 19.8281001 },
    { lat: 48.9850728, lng: 19.8284468 },
    { lat: 48.9851785, lng: 19.8286486 },
    { lat: 48.9854955, lng: 19.8288667 },
    { lat: 48.9856803, lng: 19.8289205 },
    { lat: 48.9860894, lng: 19.8292657 },
    { lat: 48.9866487, lng: 19.8296746 },
    { lat: 48.9870986, lng: 19.8300252 },
    { lat: 48.9873823, lng: 19.8302545 },
    { lat: 48.9876907, lng: 19.8305087 },
    { lat: 48.9879304, lng: 19.8306373 },
    { lat: 48.9881407, lng: 19.8308327 },
    { lat: 48.9883108, lng: 19.8310733 },
    { lat: 48.9884659, lng: 19.8313528 },
    { lat: 48.9887048, lng: 19.8315342 },
    { lat: 48.9889218, lng: 19.8315524 },
    { lat: 48.9892343, lng: 19.8312761 },
    { lat: 48.9893827, lng: 19.8311641 },
    { lat: 48.9895394, lng: 19.8311152 },
    { lat: 48.9897643, lng: 19.8309752 },
    { lat: 48.9899846, lng: 19.83078 },
    { lat: 48.9902733, lng: 19.8304723 },
    { lat: 48.9905574, lng: 19.830448 },
    { lat: 48.9909046, lng: 19.8303762 },
    { lat: 48.9916525, lng: 19.8303223 },
    { lat: 48.9918278, lng: 19.8306769 },
    { lat: 48.9919532, lng: 19.8308197 },
    { lat: 48.9921362, lng: 19.8312772 },
    { lat: 48.9927598, lng: 19.8320496 },
    { lat: 48.9929696, lng: 19.8326293 },
    { lat: 48.99318, lng: 19.8330424 },
    { lat: 48.9934492, lng: 19.8331986 },
    { lat: 48.9936197, lng: 19.8332104 },
    { lat: 48.9937025, lng: 19.8333808 },
    { lat: 48.9938238, lng: 19.8335284 },
    { lat: 48.9939523, lng: 19.8339239 },
    { lat: 48.9940751, lng: 19.8342116 },
    { lat: 48.9940885, lng: 19.8344452 },
    { lat: 48.9942035, lng: 19.8346071 },
    { lat: 48.9942694, lng: 19.8348545 },
    { lat: 48.9944507, lng: 19.8351669 },
    { lat: 48.9951854, lng: 19.8349103 },
    { lat: 48.995805, lng: 19.8346464 },
    { lat: 48.996135, lng: 19.8349383 },
    { lat: 48.9965304, lng: 19.8355351 },
    { lat: 48.9969253, lng: 19.8364695 },
    { lat: 48.9973244, lng: 19.8371876 },
    { lat: 48.9977743, lng: 19.8381148 },
    { lat: 48.998316, lng: 19.8391294 },
    { lat: 48.998632, lng: 19.8397988 },
    { lat: 48.9988851, lng: 19.8402892 },
    { lat: 48.9990062, lng: 19.8403267 },
    { lat: 48.9993521, lng: 19.8411979 },
    { lat: 48.999649, lng: 19.841839 },
    { lat: 48.999685, lng: 19.841975 },
    { lat: 49.0001481, lng: 19.8427861 },
    { lat: 49.0003473, lng: 19.8431761 },
    { lat: 49.0005564, lng: 19.8436372 },
    { lat: 49.0006971, lng: 19.8439243 },
    { lat: 49.000766, lng: 19.8441654 },
    { lat: 49.0008493, lng: 19.844297 },
    { lat: 49.0011023, lng: 19.8446806 },
    { lat: 49.0014593, lng: 19.8455952 },
    { lat: 49.0016716, lng: 19.8457622 },
    { lat: 49.0018463, lng: 19.8460283 },
    { lat: 49.0019644, lng: 19.8461296 },
    { lat: 49.0020894, lng: 19.8461033 },
    { lat: 49.0023735, lng: 19.84582 },
    { lat: 49.0026586, lng: 19.8456655 },
    { lat: 49.0038499, lng: 19.8457566 },
    { lat: 49.0050446, lng: 19.8464018 },
    { lat: 49.0052786, lng: 19.8468942 },
    { lat: 49.005752, lng: 19.8473191 },
    { lat: 49.0059611, lng: 19.847467 },
    { lat: 49.0062288, lng: 19.847485 },
    { lat: 49.0064505, lng: 19.8478211 },
    { lat: 49.0070876, lng: 19.8481513 },
    { lat: 49.007808, lng: 19.8488765 },
    { lat: 49.0082645, lng: 19.8487506 },
    { lat: 49.0087939, lng: 19.8492497 },
    { lat: 49.009262, lng: 19.8499432 },
    { lat: 49.0097177, lng: 19.8504105 },
    { lat: 49.009727, lng: 19.8503895 },
    { lat: 49.0100099, lng: 19.8507619 },
    { lat: 49.0100782, lng: 19.8508578 },
    { lat: 49.0104564, lng: 19.8511674 },
    { lat: 49.010605, lng: 19.8512133 },
    { lat: 49.0105971, lng: 19.8512654 },
    { lat: 49.0112051, lng: 19.8519426 },
    { lat: 49.0112214, lng: 19.8519313 },
    { lat: 49.011478, lng: 19.8526093 },
    { lat: 49.0114632, lng: 19.852628 },
    { lat: 49.0115813, lng: 19.8529163 },
    { lat: 49.0118324, lng: 19.8534656 },
    { lat: 49.0121989, lng: 19.8539766 },
    { lat: 49.0125206, lng: 19.8541471 },
    { lat: 49.0130702, lng: 19.8539058 },
    { lat: 49.0136584, lng: 19.8539107 },
  ],
  Lazisko: [
    { lat: 48.947248, lng: 19.539928 },
    { lat: 48.9487443, lng: 19.5399572 },
    { lat: 48.9498044, lng: 19.5402448 },
    { lat: 48.9500401, lng: 19.5403042 },
    { lat: 48.9503058, lng: 19.5403469 },
    { lat: 48.9513552, lng: 19.5405292 },
    { lat: 48.951786, lng: 19.5406083 },
    { lat: 48.9529103, lng: 19.5406286 },
    { lat: 48.9533593, lng: 19.5407653 },
    { lat: 48.9539202, lng: 19.5409279 },
    { lat: 48.9545109, lng: 19.5413461 },
    { lat: 48.9546549, lng: 19.541448 },
    { lat: 48.9553436, lng: 19.5415772 },
    { lat: 48.9556191, lng: 19.5416375 },
    { lat: 48.9560828, lng: 19.5417243 },
    { lat: 48.9571689, lng: 19.5420193 },
    { lat: 48.9576362, lng: 19.542139 },
    { lat: 48.9580212, lng: 19.542355 },
    { lat: 48.9584648, lng: 19.542592 },
    { lat: 48.9588335, lng: 19.542796 },
    { lat: 48.9592793, lng: 19.5430331 },
    { lat: 48.9596888, lng: 19.5432626 },
    { lat: 48.9612264, lng: 19.5422885 },
    { lat: 48.9621055, lng: 19.5418755 },
    { lat: 48.9629642, lng: 19.5419244 },
    { lat: 48.9635039, lng: 19.5415299 },
    { lat: 48.9641482, lng: 19.5422174 },
    { lat: 48.9644465, lng: 19.542752 },
    { lat: 48.9649065, lng: 19.5431314 },
    { lat: 48.965471, lng: 19.5436022 },
    { lat: 48.9670503, lng: 19.5439369 },
    { lat: 48.9690026, lng: 19.5450379 },
    { lat: 48.9699371, lng: 19.5449272 },
    { lat: 48.972451, lng: 19.5443302 },
    { lat: 48.9741615, lng: 19.5445784 },
    { lat: 48.9754661, lng: 19.5462355 },
    { lat: 48.9769979, lng: 19.5493872 },
    { lat: 48.9775234, lng: 19.549742 },
    { lat: 48.9789698, lng: 19.5498606 },
    { lat: 48.979244, lng: 19.5498684 },
    { lat: 48.9798438, lng: 19.5498517 },
    { lat: 48.9821603, lng: 19.5499468 },
    { lat: 48.9834343, lng: 19.5500832 },
    { lat: 48.9840394, lng: 19.5518698 },
    { lat: 48.9851364, lng: 19.553896 },
    { lat: 48.9872294, lng: 19.5554663 },
    { lat: 48.9910042, lng: 19.5567614 },
    { lat: 48.9955483, lng: 19.5571048 },
    { lat: 48.9988684, lng: 19.5551576 },
    { lat: 49.0002609, lng: 19.5551762 },
    { lat: 49.0002677, lng: 19.555313 },
    { lat: 49.0005118, lng: 19.5548201 },
    { lat: 49.0007009, lng: 19.5541223 },
    { lat: 49.0008371, lng: 19.5534517 },
    { lat: 49.0008908, lng: 19.5527721 },
    { lat: 49.0011764, lng: 19.5516429 },
    { lat: 49.0014727, lng: 19.5514456 },
    { lat: 49.0015071, lng: 19.5514079 },
    { lat: 49.0017283, lng: 19.5512656 },
    { lat: 49.0022048, lng: 19.5507942 },
    { lat: 49.0022522, lng: 19.5507462 },
    { lat: 49.0025863, lng: 19.5506052 },
    { lat: 49.0027655, lng: 19.5501888 },
    { lat: 49.0030265, lng: 19.5495832 },
    { lat: 49.0032111, lng: 19.5491561 },
    { lat: 49.0034407, lng: 19.548852 },
    { lat: 49.0039683, lng: 19.5481434 },
    { lat: 49.0042757, lng: 19.5478373 },
    { lat: 49.0047041, lng: 19.5476462 },
    { lat: 49.0050126, lng: 19.5475151 },
    { lat: 49.0057018, lng: 19.5473372 },
    { lat: 49.005872, lng: 19.5472838 },
    { lat: 49.0061097, lng: 19.5472093 },
    { lat: 49.0064531, lng: 19.5471086 },
    { lat: 49.0068944, lng: 19.5460798 },
    { lat: 49.0070327, lng: 19.545902 },
    { lat: 49.0071719, lng: 19.5456656 },
    { lat: 49.0072618, lng: 19.5454619 },
    { lat: 49.0072864, lng: 19.5454113 },
    { lat: 49.0073195, lng: 19.5452728 },
    { lat: 49.0074344, lng: 19.5447985 },
    { lat: 49.0075724, lng: 19.5446131 },
    { lat: 49.0078888, lng: 19.5442098 },
    { lat: 49.0081964, lng: 19.5437141 },
    { lat: 49.008212, lng: 19.5435376 },
    { lat: 49.0084717, lng: 19.5428847 },
    { lat: 49.0085285, lng: 19.5425963 },
    { lat: 49.0085471, lng: 19.5425326 },
    { lat: 49.0085724, lng: 19.5422485 },
    { lat: 49.0087499, lng: 19.541961 },
    { lat: 49.0090224, lng: 19.5418846 },
    { lat: 49.0094004, lng: 19.5416503 },
    { lat: 49.0096155, lng: 19.5414733 },
    { lat: 49.0099474, lng: 19.5413386 },
    { lat: 49.0099929, lng: 19.5413468 },
    { lat: 49.0104812, lng: 19.5413568 },
    { lat: 49.0107846, lng: 19.5413622 },
    { lat: 49.0113785, lng: 19.5413632 },
    { lat: 49.0115115, lng: 19.541364 },
    { lat: 49.0117179, lng: 19.5414682 },
    { lat: 49.0119748, lng: 19.5414168 },
    { lat: 49.0120725, lng: 19.5413843 },
    { lat: 49.0121171, lng: 19.5414 },
    { lat: 49.0122758, lng: 19.541519 },
    { lat: 49.0124814, lng: 19.5416797 },
    { lat: 49.0126229, lng: 19.5416961 },
    { lat: 49.0127962, lng: 19.5418875 },
    { lat: 49.0130025, lng: 19.5420427 },
    { lat: 49.0130825, lng: 19.5421664 },
    { lat: 49.0131749, lng: 19.5422873 },
    { lat: 49.013411, lng: 19.5424481 },
    { lat: 49.0135607, lng: 19.5423954 },
    { lat: 49.0135809, lng: 19.5424442 },
    { lat: 49.0136691, lng: 19.5427239 },
    { lat: 49.0137133, lng: 19.543024 },
    { lat: 49.0138305, lng: 19.5431502 },
    { lat: 49.013902, lng: 19.5432343 },
    { lat: 49.0140779, lng: 19.5433633 },
    { lat: 49.0142679, lng: 19.5435529 },
    { lat: 49.0143407, lng: 19.5436375 },
    { lat: 49.0144632, lng: 19.5437571 },
    { lat: 49.0147367, lng: 19.5439703 },
    { lat: 49.0149203, lng: 19.5441707 },
    { lat: 49.0149729, lng: 19.5443364 },
    { lat: 49.0150585, lng: 19.5446289 },
    { lat: 49.0151173, lng: 19.5449562 },
    { lat: 49.0152704, lng: 19.5448816 },
    { lat: 49.0153084, lng: 19.5448754 },
    { lat: 49.0155186, lng: 19.5448146 },
    { lat: 49.0157035, lng: 19.5446695 },
    { lat: 49.0159019, lng: 19.5445933 },
    { lat: 49.0162238, lng: 19.5447857 },
    { lat: 49.0163421, lng: 19.5449839 },
    { lat: 49.0164515, lng: 19.5449132 },
    { lat: 49.0166888, lng: 19.5447256 },
    { lat: 49.016901, lng: 19.5447091 },
    { lat: 49.0169665, lng: 19.5446907 },
    { lat: 49.0171, lng: 19.5446766 },
    { lat: 49.0173638, lng: 19.5446518 },
    { lat: 49.0176065, lng: 19.5448391 },
    { lat: 49.017813, lng: 19.5452152 },
    { lat: 49.0181594, lng: 19.5453773 },
    { lat: 49.0182766, lng: 19.5454245 },
    { lat: 49.0183163, lng: 19.5454311 },
    { lat: 49.0184504, lng: 19.5454817 },
    { lat: 49.0187242, lng: 19.5455265 },
    { lat: 49.0188827, lng: 19.5454445 },
    { lat: 49.0192829, lng: 19.5453263 },
    { lat: 49.0197879, lng: 19.5451838 },
    { lat: 49.0201806, lng: 19.5447759 },
    { lat: 49.0202764, lng: 19.5447993 },
    { lat: 49.0209237, lng: 19.5449505 },
    { lat: 49.0211186, lng: 19.5449084 },
    { lat: 49.0213226, lng: 19.5449299 },
    { lat: 49.0215196, lng: 19.5450264 },
    { lat: 49.0217145, lng: 19.5450731 },
    { lat: 49.0220376, lng: 19.545416 },
    { lat: 49.0221281, lng: 19.5455749 },
    { lat: 49.0223679, lng: 19.545744 },
    { lat: 49.0224656, lng: 19.5457369 },
    { lat: 49.0225574, lng: 19.5457273 },
    { lat: 49.0226594, lng: 19.5457155 },
    { lat: 49.0227525, lng: 19.5456813 },
    { lat: 49.0228088, lng: 19.5457033 },
    { lat: 49.0229164, lng: 19.5457398 },
    { lat: 49.0229656, lng: 19.5457561 },
    { lat: 49.0230135, lng: 19.5457719 },
    { lat: 49.0230606, lng: 19.5457885 },
    { lat: 49.0232426, lng: 19.5458505 },
    { lat: 49.0235448, lng: 19.5459534 },
    { lat: 49.0237023, lng: 19.5458234 },
    { lat: 49.0237141, lng: 19.5458602 },
    { lat: 49.0242214, lng: 19.5455724 },
    { lat: 49.0246232, lng: 19.5454364 },
    { lat: 49.0247553, lng: 19.5453917 },
    { lat: 49.0251921, lng: 19.5453335 },
    { lat: 49.0256207, lng: 19.5452687 },
    { lat: 49.0260685, lng: 19.5452144 },
    { lat: 49.0263792, lng: 19.5451767 },
    { lat: 49.0264535, lng: 19.545177 },
    { lat: 49.0268093, lng: 19.5451643 },
    { lat: 49.0269272, lng: 19.5451611 },
    { lat: 49.026989, lng: 19.5452056 },
    { lat: 49.0270295, lng: 19.5453288 },
    { lat: 49.0271844, lng: 19.5454367 },
    { lat: 49.0273081, lng: 19.5455307 },
    { lat: 49.0276554, lng: 19.5456594 },
    { lat: 49.0281583, lng: 19.5456952 },
    { lat: 49.0286611, lng: 19.5458732 },
    { lat: 49.0291432, lng: 19.5458217 },
    { lat: 49.0296045, lng: 19.5457047 },
    { lat: 49.0296292, lng: 19.5457038 },
    { lat: 49.0296941, lng: 19.5456913 },
    { lat: 49.029759, lng: 19.5456809 },
    { lat: 49.0298105, lng: 19.5456769 },
    { lat: 49.0298794, lng: 19.5456801 },
    { lat: 49.0307711, lng: 19.5456893 },
    { lat: 49.0312965, lng: 19.5456937 },
    { lat: 49.031728, lng: 19.5456192 },
    { lat: 49.0321334, lng: 19.5455877 },
    { lat: 49.0326678, lng: 19.5458177 },
    { lat: 49.0328707, lng: 19.5460226 },
    { lat: 49.0331874, lng: 19.5464701 },
    { lat: 49.0333979, lng: 19.5466394 },
    { lat: 49.0334611, lng: 19.5466902 },
    { lat: 49.033574, lng: 19.5467688 },
    { lat: 49.0336346, lng: 19.5468127 },
    { lat: 49.0337439, lng: 19.5468845 },
    { lat: 49.033952, lng: 19.5470278 },
    { lat: 49.0342863, lng: 19.5470936 },
    { lat: 49.0347244, lng: 19.5475122 },
    { lat: 49.0350342, lng: 19.547856 },
    { lat: 49.0353696, lng: 19.5479457 },
    { lat: 49.0356324, lng: 19.5481494 },
    { lat: 49.0356559, lng: 19.5482255 },
    { lat: 49.0356955, lng: 19.5473814 },
    { lat: 49.0357593, lng: 19.5470242 },
    { lat: 49.0360167, lng: 19.5463913 },
    { lat: 49.0362277, lng: 19.5460049 },
    { lat: 49.0364166, lng: 19.545497 },
    { lat: 49.0365296, lng: 19.5447159 },
    { lat: 49.0365639, lng: 19.5440565 },
    { lat: 49.0365758, lng: 19.543773 },
    { lat: 49.0365611, lng: 19.5433457 },
    { lat: 49.0365735, lng: 19.5428769 },
    { lat: 49.0366077, lng: 19.5427626 },
    { lat: 49.0367381, lng: 19.5424577 },
    { lat: 49.0368544, lng: 19.5422892 },
    { lat: 49.0368498, lng: 19.5422653 },
    { lat: 49.037164, lng: 19.5419155 },
    { lat: 49.0374162, lng: 19.5416357 },
    { lat: 49.0373936, lng: 19.5416115 },
    { lat: 49.0373675, lng: 19.541583 },
    { lat: 49.0374641, lng: 19.5414745 },
    { lat: 49.0379687, lng: 19.5411473 },
    { lat: 49.0381697, lng: 19.5409376 },
    { lat: 49.0381205, lng: 19.5408469 },
    { lat: 49.0381035, lng: 19.5408214 },
    { lat: 49.0380754, lng: 19.5406502 },
    { lat: 49.0378601, lng: 19.5405726 },
    { lat: 49.0375892, lng: 19.5407322 },
    { lat: 49.0374416, lng: 19.5407833 },
    { lat: 49.0372544, lng: 19.5407779 },
    { lat: 49.0370938, lng: 19.5406939 },
    { lat: 49.0367715, lng: 19.5404193 },
    { lat: 49.0364919, lng: 19.5403924 },
    { lat: 49.03628, lng: 19.5405532 },
    { lat: 49.0361115, lng: 19.5406253 },
    { lat: 49.0359485, lng: 19.5406342 },
    { lat: 49.035835, lng: 19.5405887 },
    { lat: 49.0357247, lng: 19.5405152 },
    { lat: 49.0351466, lng: 19.5402704 },
    { lat: 49.0349621, lng: 19.5403258 },
    { lat: 49.0347432, lng: 19.5402677 },
    { lat: 49.0342735, lng: 19.5400022 },
    { lat: 49.0339414, lng: 19.540128 },
    { lat: 49.0332375, lng: 19.5395941 },
    { lat: 49.0332077, lng: 19.5394576 },
    { lat: 49.0328334, lng: 19.5390837 },
    { lat: 49.0327745, lng: 19.5389768 },
    { lat: 49.0327035, lng: 19.5389002 },
    { lat: 49.0322624, lng: 19.5386407 },
    { lat: 49.0320912, lng: 19.5385945 },
    { lat: 49.0316674, lng: 19.5385939 },
    { lat: 49.0314101, lng: 19.5381653 },
    { lat: 49.030965, lng: 19.5381385 },
    { lat: 49.0307706, lng: 19.5377805 },
    { lat: 49.0304273, lng: 19.5374644 },
    { lat: 49.0298281, lng: 19.5370769 },
    { lat: 49.0297672, lng: 19.5369979 },
    { lat: 49.0296067, lng: 19.5365907 },
    { lat: 49.0295607, lng: 19.5363216 },
    { lat: 49.029455, lng: 19.5361058 },
    { lat: 49.0291558, lng: 19.5358461 },
    { lat: 49.0290046, lng: 19.5356929 },
    { lat: 49.0288146, lng: 19.5351829 },
    { lat: 49.0286088, lng: 19.5350911 },
    { lat: 49.0282657, lng: 19.5351535 },
    { lat: 49.0279462, lng: 19.5349703 },
    { lat: 49.0275371, lng: 19.5345484 },
    { lat: 49.0267387, lng: 19.5341877 },
    { lat: 49.026492, lng: 19.5339408 },
    { lat: 49.0262556, lng: 19.5337489 },
    { lat: 49.0260645, lng: 19.5334103 },
    { lat: 49.0255259, lng: 19.5328657 },
    { lat: 49.0252487, lng: 19.5323473 },
    { lat: 49.0248103, lng: 19.5322464 },
    { lat: 49.0244082, lng: 19.5317588 },
    { lat: 49.0240815, lng: 19.5315376 },
    { lat: 49.0239461, lng: 19.5314855 },
    { lat: 49.0238123, lng: 19.5314644 },
    { lat: 49.0236802, lng: 19.5314119 },
    { lat: 49.0233037, lng: 19.530642 },
    { lat: 49.0231393, lng: 19.5303466 },
    { lat: 49.0230887, lng: 19.5300543 },
    { lat: 49.0230826, lng: 19.530019 },
    { lat: 49.0228127, lng: 19.5295843 },
    { lat: 49.022659, lng: 19.5295481 },
    { lat: 49.0225844, lng: 19.529444 },
    { lat: 49.0224633, lng: 19.5291595 },
    { lat: 49.0224435, lng: 19.5288036 },
    { lat: 49.0224817, lng: 19.5284047 },
    { lat: 49.0225155, lng: 19.5281114 },
    { lat: 49.0224493, lng: 19.5279891 },
    { lat: 49.0223007, lng: 19.5278942 },
    { lat: 49.0222548, lng: 19.5278516 },
    { lat: 49.0221728, lng: 19.5276837 },
    { lat: 49.0220747, lng: 19.5273344 },
    { lat: 49.0220111, lng: 19.5272566 },
    { lat: 49.0220011, lng: 19.5271725 },
    { lat: 49.0218638, lng: 19.5270203 },
    { lat: 49.0217615, lng: 19.5268494 },
    { lat: 49.0214644, lng: 19.5265967 },
    { lat: 49.021346, lng: 19.526457 },
    { lat: 49.0212146, lng: 19.5261684 },
    { lat: 49.0210299, lng: 19.5261743 },
    { lat: 49.0210204, lng: 19.5260556 },
    { lat: 49.0209741, lng: 19.5258275 },
    { lat: 49.0209096, lng: 19.5255391 },
    { lat: 49.0207598, lng: 19.5252998 },
    { lat: 49.0205893, lng: 19.5253856 },
    { lat: 49.0204474, lng: 19.5253074 },
    { lat: 49.020424, lng: 19.5252922 },
    { lat: 49.0201184, lng: 19.5254828 },
    { lat: 49.0199557, lng: 19.5254246 },
    { lat: 49.019729, lng: 19.5251445 },
    { lat: 49.0197422, lng: 19.5251133 },
    { lat: 49.0195521, lng: 19.5248497 },
    { lat: 49.0191761, lng: 19.5246363 },
    { lat: 49.0184388, lng: 19.5239599 },
    { lat: 49.0180237, lng: 19.5240012 },
    { lat: 49.0177168, lng: 19.5237241 },
    { lat: 49.0175528, lng: 19.5237318 },
    { lat: 49.0167487, lng: 19.5241376 },
    { lat: 49.0163105, lng: 19.5238428 },
    { lat: 49.0152416, lng: 19.523979 },
    { lat: 49.015099, lng: 19.523839 },
    { lat: 49.0148692, lng: 19.5235236 },
    { lat: 49.014771, lng: 19.5233481 },
    { lat: 49.0143201, lng: 19.5231419 },
    { lat: 49.0140416, lng: 19.52314 },
    { lat: 49.0136315, lng: 19.5227946 },
    { lat: 49.0136126, lng: 19.5226559 },
    { lat: 49.0135224, lng: 19.5225319 },
    { lat: 49.0131218, lng: 19.5223423 },
    { lat: 49.012645, lng: 19.5218434 },
    { lat: 49.0124162, lng: 19.5217972 },
    { lat: 49.0122434, lng: 19.5215154 },
    { lat: 49.0118479, lng: 19.5208737 },
    { lat: 49.0116024, lng: 19.5204832 },
    { lat: 49.011443, lng: 19.5202768 },
    { lat: 49.0107783, lng: 19.5203524 },
    { lat: 49.0102273, lng: 19.5195861 },
    { lat: 49.009641, lng: 19.5191362 },
    { lat: 49.0091692, lng: 19.518923 },
    { lat: 49.0089946, lng: 19.5184784 },
    { lat: 49.0075564, lng: 19.518224 },
    { lat: 49.0073926, lng: 19.5180669 },
    { lat: 49.0072502, lng: 19.5177413 },
    { lat: 49.0071958, lng: 19.5173867 },
    { lat: 49.0070609, lng: 19.5171218 },
    { lat: 49.0065393, lng: 19.5165804 },
    { lat: 49.006163, lng: 19.5159931 },
    { lat: 49.0061374, lng: 19.5148674 },
    { lat: 49.0060315, lng: 19.5144974 },
    { lat: 49.0058276, lng: 19.5141281 },
    { lat: 49.0055221, lng: 19.5138557 },
    { lat: 49.0054174, lng: 19.5134415 },
    { lat: 49.0053133, lng: 19.513132 },
    { lat: 49.0053175, lng: 19.5129826 },
    { lat: 49.0052825, lng: 19.5128054 },
    { lat: 49.0048093, lng: 19.512512 },
    { lat: 49.0046561, lng: 19.5122181 },
    { lat: 49.0045792, lng: 19.5117467 },
    { lat: 49.0042205, lng: 19.5112704 },
    { lat: 49.0041687, lng: 19.5110013 },
    { lat: 49.0039771, lng: 19.5106275 },
    { lat: 49.0037745, lng: 19.5104361 },
    { lat: 49.0037071, lng: 19.5102961 },
    { lat: 49.0030004, lng: 19.5097758 },
    { lat: 49.0028806, lng: 19.509768 },
    { lat: 49.0025877, lng: 19.5095485 },
    { lat: 49.0023832, lng: 19.5092648 },
    { lat: 49.0017912, lng: 19.5092811 },
    { lat: 49.001567, lng: 19.508984 },
    { lat: 49.0015485, lng: 19.5087032 },
    { lat: 49.001331, lng: 19.508214 },
    { lat: 49.0007386, lng: 19.507849 },
    { lat: 49.0004052, lng: 19.507482 },
    { lat: 48.9998655, lng: 19.5072624 },
    { lat: 48.9991346, lng: 19.5071729 },
    { lat: 48.9986884, lng: 19.5068556 },
    { lat: 48.998047, lng: 19.506627 },
    { lat: 48.9979426, lng: 19.5061737 },
    { lat: 48.9978151, lng: 19.5060448 },
    { lat: 48.9969962, lng: 19.505853 },
    { lat: 48.9968634, lng: 19.5057343 },
    { lat: 48.9958742, lng: 19.5052943 },
    { lat: 48.9950191, lng: 19.5046841 },
    { lat: 48.9949441, lng: 19.5044508 },
    { lat: 48.9940913, lng: 19.5042613 },
    { lat: 48.9937854, lng: 19.5039553 },
    { lat: 48.9933678, lng: 19.5037408 },
    { lat: 48.9928184, lng: 19.5038256 },
    { lat: 48.9925438, lng: 19.5034643 },
    { lat: 48.9921942, lng: 19.5033858 },
    { lat: 48.9918529, lng: 19.5036343 },
    { lat: 48.9915842, lng: 19.503548 },
    { lat: 48.9911124, lng: 19.5036048 },
    { lat: 48.990697, lng: 19.5042512 },
    { lat: 48.9903906, lng: 19.5044038 },
    { lat: 48.9901665, lng: 19.5043558 },
    { lat: 48.9898486, lng: 19.5045495 },
    { lat: 48.9895153, lng: 19.5046562 },
    { lat: 48.9894476, lng: 19.5046781 },
    { lat: 48.9889483, lng: 19.5044795 },
    { lat: 48.9885101, lng: 19.5048983 },
    { lat: 48.9884864, lng: 19.5049223 },
    { lat: 48.9884701, lng: 19.5049377 },
    { lat: 48.9882072, lng: 19.5051984 },
    { lat: 48.988205, lng: 19.5052001 },
    { lat: 48.9880678, lng: 19.5053043 },
    { lat: 48.9879666, lng: 19.5053821 },
    { lat: 48.9876934, lng: 19.5055172 },
    { lat: 48.9876216, lng: 19.5055262 },
    { lat: 48.9875051, lng: 19.5055404 },
    { lat: 48.9873908, lng: 19.5055546 },
    { lat: 48.987303, lng: 19.505602 },
    { lat: 48.9872753, lng: 19.5056167 },
    { lat: 48.9871445, lng: 19.505688 },
    { lat: 48.9870774, lng: 19.5057241 },
    { lat: 48.9868649, lng: 19.5057811 },
    { lat: 48.9866341, lng: 19.5058091 },
    { lat: 48.9865444, lng: 19.5058204 },
    { lat: 48.9861006, lng: 19.5058753 },
    { lat: 48.986048, lng: 19.5058816 },
    { lat: 48.985906, lng: 19.5059041 },
    { lat: 48.9854618, lng: 19.5061449 },
    { lat: 48.9854282, lng: 19.506123 },
    { lat: 48.9851691, lng: 19.5059585 },
    { lat: 48.985023, lng: 19.5058656 },
    { lat: 48.9845629, lng: 19.5060284 },
    { lat: 48.9842573, lng: 19.5060471 },
    { lat: 48.9840476, lng: 19.5061443 },
    { lat: 48.9838259, lng: 19.5060077 },
    { lat: 48.9836309, lng: 19.5062756 },
    { lat: 48.9834925, lng: 19.5065995 },
    { lat: 48.9831662, lng: 19.5066301 },
    { lat: 48.9827561, lng: 19.5070024 },
    { lat: 48.9823446, lng: 19.5075658 },
    { lat: 48.9821536, lng: 19.5079936 },
    { lat: 48.9819037, lng: 19.5083174 },
    { lat: 48.9817077, lng: 19.5085419 },
    { lat: 48.9815174, lng: 19.5090847 },
    { lat: 48.9812251, lng: 19.5095888 },
    { lat: 48.9809113, lng: 19.5097282 },
    { lat: 48.9807202, lng: 19.5103034 },
    { lat: 48.9800623, lng: 19.5103292 },
    { lat: 48.9794977, lng: 19.5103287 },
    { lat: 48.9792578, lng: 19.5104584 },
    { lat: 48.9789682, lng: 19.5102595 },
    { lat: 48.9785752, lng: 19.5102167 },
    { lat: 48.9779042, lng: 19.5103781 },
    { lat: 48.9774645, lng: 19.5099751 },
    { lat: 48.9771905, lng: 19.5099474 },
    { lat: 48.9767844, lng: 19.5097935 },
    { lat: 48.9765725, lng: 19.5095953 },
    { lat: 48.9762392, lng: 19.5096244 },
    { lat: 48.9760598, lng: 19.5094956 },
    { lat: 48.9754908, lng: 19.5093736 },
    { lat: 48.9751855, lng: 19.5094973 },
    { lat: 48.9749737, lng: 19.5094258 },
    { lat: 48.9744512, lng: 19.5092364 },
    { lat: 48.9743488, lng: 19.509239 },
    { lat: 48.9741424, lng: 19.5091338 },
    { lat: 48.9739803, lng: 19.5091481 },
    { lat: 48.9738688, lng: 19.5091418 },
    { lat: 48.9733881, lng: 19.509436 },
    { lat: 48.9730504, lng: 19.5095524 },
    { lat: 48.9729067, lng: 19.509602 },
    { lat: 48.9726737, lng: 19.5098183 },
    { lat: 48.9723593, lng: 19.5098429 },
    { lat: 48.9722729, lng: 19.5099722 },
    { lat: 48.9721326, lng: 19.5100075 },
    { lat: 48.9717817, lng: 19.5102691 },
    { lat: 48.9713098, lng: 19.5102458 },
    { lat: 48.9709017, lng: 19.5105135 },
    { lat: 48.9705014, lng: 19.5105835 },
    { lat: 48.9702053, lng: 19.5105176 },
    { lat: 48.9697914, lng: 19.5106772 },
    { lat: 48.9694167, lng: 19.5110597 },
    { lat: 48.9691196, lng: 19.5111668 },
    { lat: 48.9689402, lng: 19.5110139 },
    { lat: 48.968716, lng: 19.5109387 },
    { lat: 48.9682192, lng: 19.5109446 },
    { lat: 48.9679005, lng: 19.5112903 },
    { lat: 48.9678455, lng: 19.5114715 },
    { lat: 48.9678261, lng: 19.5114646 },
    { lat: 48.9677768, lng: 19.5114461 },
    { lat: 48.9672135, lng: 19.5113242 },
    { lat: 48.9670472, lng: 19.5112872 },
    { lat: 48.9659292, lng: 19.5110353 },
    { lat: 48.9655679, lng: 19.51117 },
    { lat: 48.9655435, lng: 19.5111787 },
    { lat: 48.9644069, lng: 19.5116026 },
    { lat: 48.9643361, lng: 19.5116287 },
    { lat: 48.9627383, lng: 19.5122229 },
    { lat: 48.960811, lng: 19.5117227 },
    { lat: 48.9597051, lng: 19.5115394 },
    { lat: 48.9591076, lng: 19.5111881 },
    { lat: 48.9543783, lng: 19.5094954 },
    { lat: 48.9515508, lng: 19.5090188 },
    { lat: 48.9500872, lng: 19.5092958 },
    { lat: 48.9465395, lng: 19.5089894 },
    { lat: 48.945335, lng: 19.5086507 },
    { lat: 48.9446709, lng: 19.5084681 },
    { lat: 48.9432932, lng: 19.5076936 },
    { lat: 48.9413996, lng: 19.5073357 },
    { lat: 48.940893, lng: 19.507301 },
    { lat: 48.940445, lng: 19.508514 },
    { lat: 48.939683, lng: 19.512003 },
    { lat: 48.939647, lng: 19.51227 },
    { lat: 48.939225, lng: 19.515358 },
    { lat: 48.939137, lng: 19.515825 },
    { lat: 48.938864, lng: 19.517291 },
    { lat: 48.938896, lng: 19.51742 },
    { lat: 48.941296, lng: 19.522713 },
    { lat: 48.941813, lng: 19.523753 },
    { lat: 48.943362, lng: 19.526532 },
    { lat: 48.946493, lng: 19.533086 },
    { lat: 48.946367, lng: 19.534508 },
    { lat: 48.946431, lng: 19.535488 },
    { lat: 48.9465206, lng: 19.5356411 },
    { lat: 48.947146, lng: 19.536653 },
    { lat: 48.946978, lng: 19.538343 },
    { lat: 48.947248, lng: 19.539928 },
  ],
  LiptovskáAnna: [
    { lat: 49.132216, lng: 19.456096 },
    { lat: 49.1337002, lng: 19.4575029 },
    { lat: 49.1343227, lng: 19.4580724 },
    { lat: 49.134783, lng: 19.4589637 },
    { lat: 49.1349632, lng: 19.4596713 },
    { lat: 49.1350276, lng: 19.4598944 },
    { lat: 49.135174, lng: 19.4604026 },
    { lat: 49.1356935, lng: 19.4614053 },
    { lat: 49.13606, lng: 19.4618522 },
    { lat: 49.1364226, lng: 19.4630945 },
    { lat: 49.136555, lng: 19.4635244 },
    { lat: 49.136596, lng: 19.4639738 },
    { lat: 49.1366561, lng: 19.464468 },
    { lat: 49.1366346, lng: 19.4648561 },
    { lat: 49.1366371, lng: 19.4649108 },
    { lat: 49.136653, lng: 19.4651852 },
    { lat: 49.1366614, lng: 19.4653422 },
    { lat: 49.1368998, lng: 19.4655506 },
    { lat: 49.1369817, lng: 19.4655685 },
    { lat: 49.1371319, lng: 19.4657234 },
    { lat: 49.1372316, lng: 19.4663713 },
    { lat: 49.1372827, lng: 19.4664752 },
    { lat: 49.1373974, lng: 19.4664489 },
    { lat: 49.1374187, lng: 19.4664444 },
    { lat: 49.1374788, lng: 19.4663808 },
    { lat: 49.137543, lng: 19.4661605 },
    { lat: 49.1375068, lng: 19.4659841 },
    { lat: 49.1376399, lng: 19.4657658 },
    { lat: 49.1377923, lng: 19.4656757 },
    { lat: 49.1379295, lng: 19.4657435 },
    { lat: 49.1380788, lng: 19.4658013 },
    { lat: 49.1381569, lng: 19.4658883 },
    { lat: 49.1382194, lng: 19.4660917 },
    { lat: 49.1382333, lng: 19.4662241 },
    { lat: 49.1382893, lng: 19.4663635 },
    { lat: 49.1383153, lng: 19.4663161 },
    { lat: 49.1384524, lng: 19.4663306 },
    { lat: 49.1384445, lng: 19.4663797 },
    { lat: 49.1384912, lng: 19.4664906 },
    { lat: 49.1385742, lng: 19.4669182 },
    { lat: 49.138603, lng: 19.466959 },
    { lat: 49.1385889, lng: 19.4669972 },
    { lat: 49.1385906, lng: 19.4671065 },
    { lat: 49.1386073, lng: 19.4671968 },
    { lat: 49.1385993, lng: 19.4673204 },
    { lat: 49.1386037, lng: 19.4673649 },
    { lat: 49.1386039, lng: 19.4673703 },
    { lat: 49.1386156, lng: 19.4674021 },
    { lat: 49.1386706, lng: 19.467546 },
    { lat: 49.1386743, lng: 19.4675556 },
    { lat: 49.1387106, lng: 19.4676309 },
    { lat: 49.1387269, lng: 19.4676655 },
    { lat: 49.1386844, lng: 19.467845 },
    { lat: 49.138675, lng: 19.467887 },
    { lat: 49.1387289, lng: 19.4679817 },
    { lat: 49.1387731, lng: 19.4680593 },
    { lat: 49.1388382, lng: 19.4681751 },
    { lat: 49.1389965, lng: 19.4683327 },
    { lat: 49.1390342, lng: 19.4683701 },
    { lat: 49.1390321, lng: 19.4683973 },
    { lat: 49.1390261, lng: 19.4684875 },
    { lat: 49.1390186, lng: 19.4685718 },
    { lat: 49.1390173, lng: 19.4685889 },
    { lat: 49.1390089, lng: 19.4687046 },
    { lat: 49.1390057, lng: 19.4687049 },
    { lat: 49.1389474, lng: 19.4687126 },
    { lat: 49.1389251, lng: 19.4687153 },
    { lat: 49.1388573, lng: 19.4687849 },
    { lat: 49.1387659, lng: 19.4688835 },
    { lat: 49.1386859, lng: 19.4689802 },
    { lat: 49.1377888, lng: 19.4699532 },
    { lat: 49.1374611, lng: 19.4706932 },
    { lat: 49.1366406, lng: 19.4732133 },
    { lat: 49.1384483, lng: 19.4745427 },
    { lat: 49.1411385, lng: 19.4766853 },
    { lat: 49.1412668, lng: 19.4767806 },
    { lat: 49.1423673, lng: 19.4777029 },
    { lat: 49.1430127, lng: 19.4783113 },
    { lat: 49.1436537, lng: 19.4786027 },
    { lat: 49.1461509, lng: 19.4790689 },
    { lat: 49.146712, lng: 19.4791944 },
    { lat: 49.1473882, lng: 19.4792197 },
    { lat: 49.1478106, lng: 19.4793157 },
    { lat: 49.1486166, lng: 19.4794687 },
    { lat: 49.1486844, lng: 19.4794751 },
    { lat: 49.1488795, lng: 19.4795274 },
    { lat: 49.1495503, lng: 19.4796811 },
    { lat: 49.1502994, lng: 19.4797955 },
    { lat: 49.150469, lng: 19.4791507 },
    { lat: 49.1510861, lng: 19.4787734 },
    { lat: 49.1526039, lng: 19.477252 },
    { lat: 49.1526235, lng: 19.477211 },
    { lat: 49.1527024, lng: 19.4772365 },
    { lat: 49.1531394, lng: 19.4769885 },
    { lat: 49.1535681, lng: 19.4770479 },
    { lat: 49.1537511, lng: 19.4770071 },
    { lat: 49.154399, lng: 19.4757585 },
    { lat: 49.1556275, lng: 19.4747823 },
    { lat: 49.155746, lng: 19.474545 },
    { lat: 49.1563613, lng: 19.4736858 },
    { lat: 49.1576188, lng: 19.4723122 },
    { lat: 49.1580212, lng: 19.4719959 },
    { lat: 49.158866, lng: 19.4715991 },
    { lat: 49.1591928, lng: 19.4713078 },
    { lat: 49.1598218, lng: 19.4710404 },
    { lat: 49.1607734, lng: 19.4704403 },
    { lat: 49.161306, lng: 19.4698476 },
    { lat: 49.1618865, lng: 19.4697001 },
    { lat: 49.16282, lng: 19.4698375 },
    { lat: 49.1631126, lng: 19.4701447 },
    { lat: 49.1633767, lng: 19.4703274 },
    { lat: 49.1639612, lng: 19.4708583 },
    { lat: 49.1652252, lng: 19.470705 },
    { lat: 49.1667499, lng: 19.4705624 },
    { lat: 49.1683043, lng: 19.4719957 },
    { lat: 49.1709579, lng: 19.4716286 },
    { lat: 49.1717213, lng: 19.4722287 },
    { lat: 49.1728758, lng: 19.4720198 },
    { lat: 49.1730512, lng: 19.4718394 },
    { lat: 49.173773, lng: 19.4707501 },
    { lat: 49.174455, lng: 19.470202 },
    { lat: 49.173491, lng: 19.469282 },
    { lat: 49.174062, lng: 19.468165 },
    { lat: 49.174277, lng: 19.467615 },
    { lat: 49.174385, lng: 19.467336 },
    { lat: 49.174664, lng: 19.466618 },
    { lat: 49.175249, lng: 19.465268 },
    { lat: 49.175718, lng: 19.464196 },
    { lat: 49.175947, lng: 19.462721 },
    { lat: 49.175928, lng: 19.46256 },
    { lat: 49.175851, lng: 19.461905 },
    { lat: 49.175769, lng: 19.461209 },
    { lat: 49.175398, lng: 19.459621 },
    { lat: 49.175209, lng: 19.45883 },
    { lat: 49.175163, lng: 19.458215 },
    { lat: 49.174276, lng: 19.456102 },
    { lat: 49.173941, lng: 19.455382 },
    { lat: 49.172769, lng: 19.455299 },
    { lat: 49.172483, lng: 19.454959 },
    { lat: 49.172217, lng: 19.454635 },
    { lat: 49.172198, lng: 19.454611 },
    { lat: 49.171977, lng: 19.454335 },
    { lat: 49.171246, lng: 19.453421 },
    { lat: 49.169935, lng: 19.452012 },
    { lat: 49.168825, lng: 19.451055 },
    { lat: 49.168916, lng: 19.450343 },
    { lat: 49.169136, lng: 19.450233 },
    { lat: 49.169406, lng: 19.450254 },
    { lat: 49.170004, lng: 19.449196 },
    { lat: 49.170694, lng: 19.44808 },
    { lat: 49.171677, lng: 19.44628 },
    { lat: 49.171718, lng: 19.441074 },
    { lat: 49.171264, lng: 19.439461 },
    { lat: 49.170809, lng: 19.438303 },
    { lat: 49.170568, lng: 19.437546 },
    { lat: 49.16999, lng: 19.436105 },
    { lat: 49.168478, lng: 19.432902 },
    { lat: 49.168515, lng: 19.432547 },
    { lat: 49.168527, lng: 19.432488 },
    { lat: 49.16854, lng: 19.432427 },
    { lat: 49.1679905, lng: 19.4320112 },
    { lat: 49.1674992, lng: 19.4318661 },
    { lat: 49.1671941, lng: 19.4320697 },
    { lat: 49.1671265, lng: 19.4321454 },
    { lat: 49.1667077, lng: 19.4321977 },
    { lat: 49.1664222, lng: 19.4324564 },
    { lat: 49.1662068, lng: 19.4324566 },
    { lat: 49.1658075, lng: 19.4327369 },
    { lat: 49.1652699, lng: 19.4328348 },
    { lat: 49.1638959, lng: 19.432755 },
    { lat: 49.1634506, lng: 19.4328479 },
    { lat: 49.1629394, lng: 19.4332649 },
    { lat: 49.1627506, lng: 19.4333033 },
    { lat: 49.1623738, lng: 19.4332682 },
    { lat: 49.1614831, lng: 19.4328468 },
    { lat: 49.1606786, lng: 19.4328705 },
    { lat: 49.1602706, lng: 19.4330862 },
    { lat: 49.1593932, lng: 19.4337816 },
    { lat: 49.1590893, lng: 19.4341525 },
    { lat: 49.1588925, lng: 19.4342327 },
    { lat: 49.1586416, lng: 19.4340729 },
    { lat: 49.1582479, lng: 19.4337952 },
    { lat: 49.1580849, lng: 19.4336299 },
    { lat: 49.1580184, lng: 19.4336061 },
    { lat: 49.1577717, lng: 19.4332924 },
    { lat: 49.1574286, lng: 19.4330381 },
    { lat: 49.157196, lng: 19.4328846 },
    { lat: 49.1558673, lng: 19.4328078 },
    { lat: 49.1555685, lng: 19.4329498 },
    { lat: 49.155283, lng: 19.4328913 },
    { lat: 49.1550402, lng: 19.4333916 },
    { lat: 49.154713, lng: 19.4332853 },
    { lat: 49.1540734, lng: 19.4343176 },
    { lat: 49.153915, lng: 19.434424 },
    { lat: 49.1535679, lng: 19.4345218 },
    { lat: 49.1523404, lng: 19.4341731 },
    { lat: 49.1520465, lng: 19.4342526 },
    { lat: 49.151236, lng: 19.4352377 },
    { lat: 49.151159, lng: 19.4353558 },
    { lat: 49.1505532, lng: 19.4358976 },
    { lat: 49.1496819, lng: 19.4363064 },
    { lat: 49.1492651, lng: 19.4366522 },
    { lat: 49.1485306, lng: 19.4377979 },
    { lat: 49.1476774, lng: 19.439088 },
    { lat: 49.1471427, lng: 19.4404125 },
    { lat: 49.1466061, lng: 19.4413579 },
    { lat: 49.1456648, lng: 19.442688 },
    { lat: 49.1453793, lng: 19.4428011 },
    { lat: 49.1453275, lng: 19.4428491 },
    { lat: 49.144888, lng: 19.4437268 },
    { lat: 49.144724, lng: 19.4438372 },
    { lat: 49.1440051, lng: 19.4449576 },
    { lat: 49.1437028, lng: 19.4456815 },
    { lat: 49.1434675, lng: 19.4456172 },
    { lat: 49.1427907, lng: 19.4467444 },
    { lat: 49.1425649, lng: 19.4470041 },
    { lat: 49.1426395, lng: 19.4479333 },
    { lat: 49.1421792, lng: 19.4481659 },
    { lat: 49.142075, lng: 19.4486918 },
    { lat: 49.1420352, lng: 19.4494663 },
    { lat: 49.1417664, lng: 19.4497708 },
    { lat: 49.1418753, lng: 19.4501755 },
    { lat: 49.1416095, lng: 19.4506683 },
    { lat: 49.141856, lng: 19.4510961 },
    { lat: 49.1417775, lng: 19.4514004 },
    { lat: 49.1414375, lng: 19.4515049 },
    { lat: 49.1413624, lng: 19.4519988 },
    { lat: 49.1412397, lng: 19.4519814 },
    { lat: 49.1409135, lng: 19.4524799 },
    { lat: 49.1408444, lng: 19.452523 },
    { lat: 49.1407847, lng: 19.4522789 },
    { lat: 49.1402152, lng: 19.4510009 },
    { lat: 49.1396271, lng: 19.4498076 },
    { lat: 49.1388464, lng: 19.4485699 },
    { lat: 49.1389011, lng: 19.447899 },
    { lat: 49.138659, lng: 19.4466819 },
    { lat: 49.1383709, lng: 19.4463227 },
    { lat: 49.1381373, lng: 19.4458314 },
    { lat: 49.135765, lng: 19.440831 },
    { lat: 49.134005, lng: 19.441621 },
    { lat: 49.133677, lng: 19.441687 },
    { lat: 49.133421, lng: 19.442701 },
    { lat: 49.133365, lng: 19.442924 },
    { lat: 49.133061, lng: 19.444547 },
    { lat: 49.132283, lng: 19.445933 },
    { lat: 49.131633, lng: 19.446704 },
    { lat: 49.131692, lng: 19.447655 },
    { lat: 49.131855, lng: 19.44892 },
    { lat: 49.132452, lng: 19.45151 },
    { lat: 49.133311, lng: 19.453271 },
    { lat: 49.132743, lng: 19.454646 },
    { lat: 49.132216, lng: 19.456096 },
  ],
  LiptovskáKokava: [
    { lat: 49.131288, lng: 19.894896 },
    { lat: 49.1311967, lng: 19.8942936 },
    { lat: 49.1312162, lng: 19.8939863 },
    { lat: 49.13108, lng: 19.8935332 },
    { lat: 49.1309356, lng: 19.8931852 },
    { lat: 49.1305282, lng: 19.8924991 },
    { lat: 49.1304201, lng: 19.8923932 },
    { lat: 49.1302241, lng: 19.8923121 },
    { lat: 49.1297901, lng: 19.8927602 },
    { lat: 49.1295989, lng: 19.8928718 },
    { lat: 49.1291686, lng: 19.8930548 },
    { lat: 49.1290017, lng: 19.8929993 },
    { lat: 49.1289031, lng: 19.8929324 },
    { lat: 49.128436, lng: 19.8924874 },
    { lat: 49.128294, lng: 19.8922722 },
    { lat: 49.1279919, lng: 19.8917107 },
    { lat: 49.1279253, lng: 19.8914137 },
    { lat: 49.1279314, lng: 19.8911365 },
    { lat: 49.1280965, lng: 19.8904577 },
    { lat: 49.1280003, lng: 19.8899148 },
    { lat: 49.1276559, lng: 19.8889912 },
    { lat: 49.1269252, lng: 19.8881802 },
    { lat: 49.1267966, lng: 19.8883026 },
    { lat: 49.1247509, lng: 19.8844775 },
    { lat: 49.1230025, lng: 19.881502 },
    { lat: 49.123075, lng: 19.8799757 },
    { lat: 49.122813, lng: 19.8785303 },
    { lat: 49.1225918, lng: 19.8782033 },
    { lat: 49.1224174, lng: 19.8779633 },
    { lat: 49.1222709, lng: 19.8777282 },
    { lat: 49.1220706, lng: 19.8772513 },
    { lat: 49.121914, lng: 19.8766716 },
    { lat: 49.1218587, lng: 19.8760038 },
    { lat: 49.1219337, lng: 19.8752534 },
    { lat: 49.1221156, lng: 19.874946 },
    { lat: 49.1214619, lng: 19.8704134 },
    { lat: 49.1209019, lng: 19.8697502 },
    { lat: 49.1199328, lng: 19.8666721 },
    { lat: 49.1189963, lng: 19.8633286 },
    { lat: 49.1184693, lng: 19.8614892 },
    { lat: 49.1167712, lng: 19.856537 },
    { lat: 49.1165476, lng: 19.8552042 },
    { lat: 49.1164023, lng: 19.8548101 },
    { lat: 49.1155022, lng: 19.8523556 },
    { lat: 49.1151234, lng: 19.8513241 },
    { lat: 49.1148209, lng: 19.8504926 },
    { lat: 49.1143535, lng: 19.8496476 },
    { lat: 49.1133805, lng: 19.8478916 },
    { lat: 49.112979, lng: 19.8471684 },
    { lat: 49.1119463, lng: 19.8453045 },
    { lat: 49.1118505, lng: 19.8451447 },
    { lat: 49.1113981, lng: 19.8441271 },
    { lat: 49.1102733, lng: 19.8415903 },
    { lat: 49.110085, lng: 19.8397739 },
    { lat: 49.1090625, lng: 19.8363897 },
    { lat: 49.1090002, lng: 19.8361974 },
    { lat: 49.1085029, lng: 19.8329006 },
    { lat: 49.1083363, lng: 19.831803 },
    { lat: 49.1076232, lng: 19.8302514 },
    { lat: 49.1073548, lng: 19.8282233 },
    { lat: 49.107248, lng: 19.8274451 },
    { lat: 49.1072155, lng: 19.8274484 },
    { lat: 49.1071596, lng: 19.8274324 },
    { lat: 49.1072132, lng: 19.8271308 },
    { lat: 49.1072161, lng: 19.8267537 },
    { lat: 49.1071624, lng: 19.826448 },
    { lat: 49.1071068, lng: 19.8255727 },
    { lat: 49.1069902, lng: 19.8252708 },
    { lat: 49.1065842, lng: 19.8252858 },
    { lat: 49.1065396, lng: 19.8252424 },
    { lat: 49.10637, lng: 19.8248893 },
    { lat: 49.1063581, lng: 19.8245087 },
    { lat: 49.1064158, lng: 19.8242531 },
    { lat: 49.1065544, lng: 19.8237528 },
    { lat: 49.1066353, lng: 19.8232029 },
    { lat: 49.106555, lng: 19.8230376 },
    { lat: 49.1061278, lng: 19.8225061 },
    { lat: 49.1059958, lng: 19.8223179 },
    { lat: 49.1057282, lng: 19.8215943 },
    { lat: 49.1053395, lng: 19.8209388 },
    { lat: 49.1052093, lng: 19.8208961 },
    { lat: 49.1050316, lng: 19.8206391 },
    { lat: 49.1047351, lng: 19.8199051 },
    { lat: 49.104568, lng: 19.8194004 },
    { lat: 49.1045168, lng: 19.8187598 },
    { lat: 49.1044198, lng: 19.8183636 },
    { lat: 49.1043729, lng: 19.8179549 },
    { lat: 49.1042879, lng: 19.8177321 },
    { lat: 49.1040834, lng: 19.8171964 },
    { lat: 49.1038566, lng: 19.8163756 },
    { lat: 49.1037146, lng: 19.8156654 },
    { lat: 49.103169, lng: 19.8145339 },
    { lat: 49.1029885, lng: 19.8141075 },
    { lat: 49.1027497, lng: 19.8135828 },
    { lat: 49.1024302, lng: 19.8130988 },
    { lat: 49.1023368, lng: 19.8128928 },
    { lat: 49.1021286, lng: 19.8122956 },
    { lat: 49.1020582, lng: 19.8117573 },
    { lat: 49.1022505, lng: 19.8112024 },
    { lat: 49.1023138, lng: 19.8107654 },
    { lat: 49.1022878, lng: 19.8103689 },
    { lat: 49.1021116, lng: 19.8096294 },
    { lat: 49.1020743, lng: 19.809338 },
    { lat: 49.1019894, lng: 19.8089352 },
    { lat: 49.1014867, lng: 19.8086137 },
    { lat: 49.1011194, lng: 19.8084553 },
    { lat: 49.1008454, lng: 19.8083936 },
    { lat: 49.1007998, lng: 19.8083834 },
    { lat: 49.1004542, lng: 19.8084179 },
    { lat: 49.099968, lng: 19.8084792 },
    { lat: 49.0995492, lng: 19.8082525 },
    { lat: 49.0993499, lng: 19.8078087 },
    { lat: 49.099245, lng: 19.807468 },
    { lat: 49.098975, lng: 19.8064277 },
    { lat: 49.0988397, lng: 19.806246 },
    { lat: 49.0986374, lng: 19.8060222 },
    { lat: 49.09845, lng: 19.8060409 },
    { lat: 49.0981941, lng: 19.805798 },
    { lat: 49.0978958, lng: 19.8054386 },
    { lat: 49.0973604, lng: 19.8050895 },
    { lat: 49.0971297, lng: 19.8048624 },
    { lat: 49.0969995, lng: 19.8047169 },
    { lat: 49.0967238, lng: 19.8040454 },
    { lat: 49.0965077, lng: 19.8036827 },
    { lat: 49.0963059, lng: 19.80342 },
    { lat: 49.0958283, lng: 19.80308 },
    { lat: 49.0956716, lng: 19.802894 },
    { lat: 49.0954454, lng: 19.8026917 },
    { lat: 49.0950156, lng: 19.8022599 },
    { lat: 49.0948195, lng: 19.8021264 },
    { lat: 49.0945135, lng: 19.801877 },
    { lat: 49.0943159, lng: 19.8016588 },
    { lat: 49.0940055, lng: 19.801352 },
    { lat: 49.0937471, lng: 19.8010871 },
    { lat: 49.0927222, lng: 19.7998368 },
    { lat: 49.0923217, lng: 19.7991732 },
    { lat: 49.0921554, lng: 19.7989469 },
    { lat: 49.0919559, lng: 19.7987882 },
    { lat: 49.0920335, lng: 19.7985234 },
    { lat: 49.092046, lng: 19.798501 },
    { lat: 49.0888823, lng: 19.7962507 },
    { lat: 49.0869107, lng: 19.7954629 },
    { lat: 49.0856225, lng: 19.7949549 },
    { lat: 49.0847863, lng: 19.7940364 },
    { lat: 49.0845821, lng: 19.7938172 },
    { lat: 49.0843985, lng: 19.794288 },
    { lat: 49.0840194, lng: 19.7952382 },
    { lat: 49.083759, lng: 19.7958875 },
    { lat: 49.0833967, lng: 19.7966242 },
    { lat: 49.0827917, lng: 19.7978759 },
    { lat: 49.0819236, lng: 19.7997083 },
    { lat: 49.0814319, lng: 19.8007401 },
    { lat: 49.0813602, lng: 19.8009065 },
    { lat: 49.0798976, lng: 19.8042839 },
    { lat: 49.0799284, lng: 19.8042697 },
    { lat: 49.0792457, lng: 19.8058056 },
    { lat: 49.0781219, lng: 19.8084262 },
    { lat: 49.0773732, lng: 19.8101558 },
    { lat: 49.0770262, lng: 19.810939 },
    { lat: 49.0763341, lng: 19.8125486 },
    { lat: 49.0753131, lng: 19.8149183 },
    { lat: 49.0747206, lng: 19.8163044 },
    { lat: 49.0743704, lng: 19.8171187 },
    { lat: 49.0744036, lng: 19.8179918 },
    { lat: 49.0740857, lng: 19.8189591 },
    { lat: 49.0738566, lng: 19.8199155 },
    { lat: 49.073753, lng: 19.8207831 },
    { lat: 49.0735172, lng: 19.8218464 },
    { lat: 49.0733732, lng: 19.8229753 },
    { lat: 49.0733789, lng: 19.8239715 },
    { lat: 49.0734439, lng: 19.8245661 },
    { lat: 49.0736507, lng: 19.8250259 },
    { lat: 49.0739069, lng: 19.8259277 },
    { lat: 49.0739506, lng: 19.8261821 },
    { lat: 49.0739828, lng: 19.8264857 },
    { lat: 49.0740875, lng: 19.8267473 },
    { lat: 49.0741861, lng: 19.8270752 },
    { lat: 49.0743773, lng: 19.8278039 },
    { lat: 49.0745584, lng: 19.8283212 },
    { lat: 49.0748669, lng: 19.8288927 },
    { lat: 49.0750423, lng: 19.8293815 },
    { lat: 49.07517, lng: 19.8297618 },
    { lat: 49.0751989, lng: 19.8297787 },
    { lat: 49.0764424, lng: 19.8311921 },
    { lat: 49.0785521, lng: 19.832524 },
    { lat: 49.0785764, lng: 19.8327767 },
    { lat: 49.0785952, lng: 19.833137 },
    { lat: 49.0786986, lng: 19.8331814 },
    { lat: 49.0786722, lng: 19.8333047 },
    { lat: 49.0787597, lng: 19.8334505 },
    { lat: 49.0787204, lng: 19.8336129 },
    { lat: 49.0788295, lng: 19.8339761 },
    { lat: 49.0789003, lng: 19.8341301 },
    { lat: 49.0788365, lng: 19.8342431 },
    { lat: 49.0789009, lng: 19.8345077 },
    { lat: 49.0790398, lng: 19.8347008 },
    { lat: 49.079155, lng: 19.8351518 },
    { lat: 49.0792154, lng: 19.8354326 },
    { lat: 49.0792746, lng: 19.8356574 },
    { lat: 49.079362, lng: 19.8358007 },
    { lat: 49.0793938, lng: 19.8359441 },
    { lat: 49.0793178, lng: 19.836218 },
    { lat: 49.0793914, lng: 19.8363594 },
    { lat: 49.0794949, lng: 19.8365108 },
    { lat: 49.0795851, lng: 19.8367275 },
    { lat: 49.0795114, lng: 19.8371042 },
    { lat: 49.0795001, lng: 19.8375021 },
    { lat: 49.0795241, lng: 19.8377193 },
    { lat: 49.0796408, lng: 19.8379693 },
    { lat: 49.0796543, lng: 19.8382087 },
    { lat: 49.0798178, lng: 19.8384475 },
    { lat: 49.0799108, lng: 19.8387222 },
    { lat: 49.0800774, lng: 19.8388537 },
    { lat: 49.0800783, lng: 19.8390289 },
    { lat: 49.0801016, lng: 19.8390992 },
    { lat: 49.0801908, lng: 19.8392632 },
    { lat: 49.0803022, lng: 19.8393887 },
    { lat: 49.0804557, lng: 19.8395301 },
    { lat: 49.0806938, lng: 19.8401989 },
    { lat: 49.0808087, lng: 19.8404354 },
    { lat: 49.0808381, lng: 19.8406743 },
    { lat: 49.0810157, lng: 19.840901 },
    { lat: 49.0811335, lng: 19.8411013 },
    { lat: 49.081268, lng: 19.8411387 },
    { lat: 49.08135, lng: 19.8413447 },
    { lat: 49.0813245, lng: 19.8415366 },
    { lat: 49.0815972, lng: 19.8419278 },
    { lat: 49.0820043, lng: 19.8422575 },
    { lat: 49.0820348, lng: 19.8423353 },
    { lat: 49.0823624, lng: 19.8425942 },
    { lat: 49.0826016, lng: 19.8429478 },
    { lat: 49.0828345, lng: 19.8433864 },
    { lat: 49.0830419, lng: 19.8438912 },
    { lat: 49.0833167, lng: 19.8444662 },
    { lat: 49.0834385, lng: 19.8447858 },
    { lat: 49.0837089, lng: 19.8454166 },
    { lat: 49.0838457, lng: 19.8457448 },
    { lat: 49.0841198, lng: 19.8463587 },
    { lat: 49.0841775, lng: 19.8464705 },
    { lat: 49.0854178, lng: 19.8487117 },
    { lat: 49.0859207, lng: 19.8491391 },
    { lat: 49.0875804, lng: 19.8511836 },
    { lat: 49.0886396, lng: 19.8529273 },
    { lat: 49.090134, lng: 19.8538103 },
    { lat: 49.0903977, lng: 19.8541041 },
    { lat: 49.0908049, lng: 19.8544102 },
    { lat: 49.093135, lng: 19.8577207 },
    { lat: 49.0932826, lng: 19.8580587 },
    { lat: 49.0935765, lng: 19.8585622 },
    { lat: 49.0940818, lng: 19.8597889 },
    { lat: 49.0944024, lng: 19.8601504 },
    { lat: 49.0949769, lng: 19.8606524 },
    { lat: 49.0952004, lng: 19.8610322 },
    { lat: 49.095343, lng: 19.8613933 },
    { lat: 49.0955127, lng: 19.8617255 },
    { lat: 49.0959984, lng: 19.8622033 },
    { lat: 49.0961256, lng: 19.862231 },
    { lat: 49.096586, lng: 19.8623767 },
    { lat: 49.0966717, lng: 19.8623545 },
    { lat: 49.0974293, lng: 19.8622361 },
    { lat: 49.0980842, lng: 19.8621691 },
    { lat: 49.0983907, lng: 19.8621984 },
    { lat: 49.0991274, lng: 19.8627294 },
    { lat: 49.0993038, lng: 19.8629036 },
    { lat: 49.0997045, lng: 19.8637141 },
    { lat: 49.1000729, lng: 19.8637686 },
    { lat: 49.100166, lng: 19.863677 },
    { lat: 49.1006098, lng: 19.8638384 },
    { lat: 49.1008571, lng: 19.864007 },
    { lat: 49.1011023, lng: 19.8642886 },
    { lat: 49.1013647, lng: 19.8645301 },
    { lat: 49.1014974, lng: 19.86492 },
    { lat: 49.1016002, lng: 19.865453 },
    { lat: 49.1017061, lng: 19.8658214 },
    { lat: 49.1019522, lng: 19.866474 },
    { lat: 49.1022405, lng: 19.8670243 },
    { lat: 49.1027127, lng: 19.8677695 },
    { lat: 49.1030121, lng: 19.8680311 },
    { lat: 49.1032878, lng: 19.8685514 },
    { lat: 49.1034708, lng: 19.8689852 },
    { lat: 49.1035602, lng: 19.8690511 },
    { lat: 49.1038488, lng: 19.8695579 },
    { lat: 49.1041243, lng: 19.8700469 },
    { lat: 49.104312, lng: 19.8706478 },
    { lat: 49.1044438, lng: 19.8712298 },
    { lat: 49.1045831, lng: 19.8716417 },
    { lat: 49.104753, lng: 19.8720908 },
    { lat: 49.1048307, lng: 19.872645 },
    { lat: 49.1049602, lng: 19.8729101 },
    { lat: 49.1049923, lng: 19.8729487 },
    { lat: 49.1051169, lng: 19.8732312 },
    { lat: 49.1053526, lng: 19.8743698 },
    { lat: 49.1053747, lng: 19.8744956 },
    { lat: 49.1056091, lng: 19.8750786 },
    { lat: 49.1057557, lng: 19.8758231 },
    { lat: 49.1057473, lng: 19.8763416 },
    { lat: 49.1057691, lng: 19.8765361 },
    { lat: 49.1058658, lng: 19.8768519 },
    { lat: 49.105817, lng: 19.8772627 },
    { lat: 49.1058211, lng: 19.8774424 },
    { lat: 49.1058553, lng: 19.8777421 },
    { lat: 49.105924, lng: 19.8780599 },
    { lat: 49.1060162, lng: 19.8782184 },
    { lat: 49.1063116, lng: 19.8786986 },
    { lat: 49.1064413, lng: 19.8791007 },
    { lat: 49.1067021, lng: 19.8796984 },
    { lat: 49.1068637, lng: 19.8807136 },
    { lat: 49.1071604, lng: 19.8812308 },
    { lat: 49.1072587, lng: 19.8815817 },
    { lat: 49.1072833, lng: 19.8818173 },
    { lat: 49.107145, lng: 19.8820337 },
    { lat: 49.1071349, lng: 19.8823014 },
    { lat: 49.1073552, lng: 19.8827147 },
    { lat: 49.1074645, lng: 19.8828704 },
    { lat: 49.1077249, lng: 19.8834894 },
    { lat: 49.1078566, lng: 19.8836166 },
    { lat: 49.1080095, lng: 19.8838759 },
    { lat: 49.1080317, lng: 19.884372 },
    { lat: 49.1081275, lng: 19.8847706 },
    { lat: 49.1082575, lng: 19.8851022 },
    { lat: 49.1083444, lng: 19.8853709 },
    { lat: 49.1085832, lng: 19.8855807 },
    { lat: 49.1088656, lng: 19.8862665 },
    { lat: 49.1090074, lng: 19.8864478 },
    { lat: 49.1095746, lng: 19.8868597 },
    { lat: 49.1098255, lng: 19.8871442 },
    { lat: 49.1100128, lng: 19.8875234 },
    { lat: 49.1101565, lng: 19.8878073 },
    { lat: 49.1102698, lng: 19.8879784 },
    { lat: 49.1105098, lng: 19.8880885 },
    { lat: 49.1106661, lng: 19.888346 },
    { lat: 49.1109057, lng: 19.8886575 },
    { lat: 49.1108443, lng: 19.8888861 },
    { lat: 49.1108118, lng: 19.8892036 },
    { lat: 49.1106702, lng: 19.8894282 },
    { lat: 49.1105825, lng: 19.8897187 },
    { lat: 49.1105509, lng: 19.8897758 },
    { lat: 49.1105642, lng: 19.8900607 },
    { lat: 49.1105988, lng: 19.8901033 },
    { lat: 49.1109159, lng: 19.8906786 },
    { lat: 49.1111186, lng: 19.8912634 },
    { lat: 49.1112458, lng: 19.8917356 },
    { lat: 49.1115025, lng: 19.8920778 },
    { lat: 49.1119038, lng: 19.8924068 },
    { lat: 49.1120916, lng: 19.8924782 },
    { lat: 49.1121291, lng: 19.8925393 },
    { lat: 49.1125243, lng: 19.8929351 },
    { lat: 49.1129416, lng: 19.8931576 },
    { lat: 49.1130039, lng: 19.8932198 },
    { lat: 49.1131922, lng: 19.8933839 },
    { lat: 49.1134576, lng: 19.893799 },
    { lat: 49.1136651, lng: 19.8940953 },
    { lat: 49.1137767, lng: 19.8941548 },
    { lat: 49.1143299, lng: 19.8939699 },
    { lat: 49.114559, lng: 19.8940347 },
    { lat: 49.115013, lng: 19.8940099 },
    { lat: 49.1151288, lng: 19.8940557 },
    { lat: 49.1153482, lng: 19.8943163 },
    { lat: 49.1156783, lng: 19.8949304 },
    { lat: 49.1159108, lng: 19.8960837 },
    { lat: 49.1162418, lng: 19.8971173 },
    { lat: 49.1168127, lng: 19.8980972 },
    { lat: 49.1169533, lng: 19.8984904 },
    { lat: 49.1171974, lng: 19.899283 },
    { lat: 49.1171982, lng: 19.8996713 },
    { lat: 49.1171595, lng: 19.900012 },
    { lat: 49.1171347, lng: 19.9004057 },
    { lat: 49.1171865, lng: 19.9010177 },
    { lat: 49.1172983, lng: 19.9020632 },
    { lat: 49.1175321, lng: 19.9030097 },
    { lat: 49.1175437, lng: 19.9032329 },
    { lat: 49.117416, lng: 19.9042109 },
    { lat: 49.1173626, lng: 19.9044887 },
    { lat: 49.1172271, lng: 19.9054431 },
    { lat: 49.1170682, lng: 19.9057586 },
    { lat: 49.1169056, lng: 19.906178 },
    { lat: 49.1168788, lng: 19.9063144 },
    { lat: 49.1168999, lng: 19.9075335 },
    { lat: 49.1169838, lng: 19.908157 },
    { lat: 49.1169112, lng: 19.9084676 },
    { lat: 49.1168648, lng: 19.9090216 },
    { lat: 49.1168727, lng: 19.9091535 },
    { lat: 49.1169594, lng: 19.9095798 },
    { lat: 49.1169908, lng: 19.9106475 },
    { lat: 49.1170456, lng: 19.91085 },
    { lat: 49.1171595, lng: 19.9110934 },
    { lat: 49.1172678, lng: 19.9119249 },
    { lat: 49.1176192, lng: 19.9121073 },
    { lat: 49.1181778, lng: 19.9123194 },
    { lat: 49.1183827, lng: 19.9123691 },
    { lat: 49.1188687, lng: 19.9124095 },
    { lat: 49.1191441, lng: 19.912425 },
    { lat: 49.1193813, lng: 19.9124693 },
    { lat: 49.1194947, lng: 19.9125093 },
    { lat: 49.1200014, lng: 19.9128282 },
    { lat: 49.1203891, lng: 19.9130427 },
    { lat: 49.1210649, lng: 19.9133623 },
    { lat: 49.121212, lng: 19.913312 },
    { lat: 49.121691, lng: 19.912492 },
    { lat: 49.121695, lng: 19.912487 },
    { lat: 49.121705, lng: 19.912468 },
    { lat: 49.12242, lng: 19.911098 },
    { lat: 49.123152, lng: 19.909746 },
    { lat: 49.123513, lng: 19.909139 },
    { lat: 49.12393, lng: 19.90844 },
    { lat: 49.124423, lng: 19.907622 },
    { lat: 49.124804, lng: 19.906991 },
    { lat: 49.125325, lng: 19.906196 },
    { lat: 49.125493, lng: 19.905893 },
    { lat: 49.12664, lng: 19.905498 },
    { lat: 49.126812, lng: 19.905439 },
    { lat: 49.127199, lng: 19.904699 },
    { lat: 49.127442, lng: 19.904233 },
    { lat: 49.127682, lng: 19.903537 },
    { lat: 49.127937, lng: 19.902794 },
    { lat: 49.128327, lng: 19.901909 },
    { lat: 49.12847, lng: 19.901585 },
    { lat: 49.128652, lng: 19.901154 },
    { lat: 49.129006, lng: 19.900314 },
    { lat: 49.129612, lng: 19.899073 },
    { lat: 49.129802, lng: 19.89924 },
    { lat: 49.12988, lng: 19.899309 },
    { lat: 49.130599, lng: 19.899485 },
    { lat: 49.130814, lng: 19.899075 },
    { lat: 49.131028, lng: 19.898898 },
    { lat: 49.13148, lng: 19.898333 },
    { lat: 49.131442, lng: 19.898284 },
    { lat: 49.131417, lng: 19.898252 },
    { lat: 49.131285, lng: 19.898084 },
    { lat: 49.131025, lng: 19.897755 },
    { lat: 49.13101, lng: 19.897735 },
    { lat: 49.130962, lng: 19.897676 },
    { lat: 49.130626, lng: 19.897256 },
    { lat: 49.130646, lng: 19.896826 },
    { lat: 49.130692, lng: 19.895905 },
    { lat: 49.130692, lng: 19.895895 },
    { lat: 49.130705, lng: 19.895853 },
    { lat: 49.130943, lng: 19.895048 },
    { lat: 49.131164, lng: 19.894954 },
    { lat: 49.131288, lng: 19.894896 },
  ],
  LiptovskáPorúbka: [
    { lat: 49.0278885, lng: 19.784015 },
    { lat: 49.0280916, lng: 19.7841529 },
    { lat: 49.028259, lng: 19.7843494 },
    { lat: 49.0283303, lng: 19.7844844 },
    { lat: 49.0285096, lng: 19.7845967 },
    { lat: 49.0285197, lng: 19.7846452 },
    { lat: 49.0286988, lng: 19.7848268 },
    { lat: 49.0288848, lng: 19.784937 },
    { lat: 49.0290566, lng: 19.785049 },
    { lat: 49.0291686, lng: 19.7851383 },
    { lat: 49.0292228, lng: 19.7851961 },
    { lat: 49.0295399, lng: 19.7854633 },
    { lat: 49.0297746, lng: 19.785606 },
    { lat: 49.029925, lng: 19.7856458 },
    { lat: 49.0299748, lng: 19.7856498 },
    { lat: 49.0302307, lng: 19.7855756 },
    { lat: 49.0305622, lng: 19.7853474 },
    { lat: 49.0306326, lng: 19.7852847 },
    { lat: 49.0308444, lng: 19.7850243 },
    { lat: 49.0308701, lng: 19.7850182 },
    { lat: 49.031056, lng: 19.7847364 },
    { lat: 49.031155, lng: 19.7845491 },
    { lat: 49.031166, lng: 19.7844647 },
    { lat: 49.0312215, lng: 19.7843406 },
    { lat: 49.0313847, lng: 19.7839997 },
    { lat: 49.0317465, lng: 19.7832805 },
    { lat: 49.0318924, lng: 19.783035 },
    { lat: 49.0321127, lng: 19.7827372 },
    { lat: 49.0323689, lng: 19.7825384 },
    { lat: 49.032484, lng: 19.7824655 },
    { lat: 49.0326192, lng: 19.7824138 },
    { lat: 49.0329144, lng: 19.7823623 },
    { lat: 49.0334405, lng: 19.7822366 },
    { lat: 49.0336063, lng: 19.7821897 },
    { lat: 49.033829, lng: 19.7820776 },
    { lat: 49.0338525, lng: 19.782095 },
    { lat: 49.0340357, lng: 19.7817747 },
    { lat: 49.0341584, lng: 19.7812586 },
    { lat: 49.0342367, lng: 19.7806738 },
    { lat: 49.0342549, lng: 19.7804957 },
    { lat: 49.0342804, lng: 19.7802511 },
    { lat: 49.0343267, lng: 19.7795025 },
    { lat: 49.0344125, lng: 19.7779774 },
    { lat: 49.0344485, lng: 19.777377 },
    { lat: 49.0344861, lng: 19.7764279 },
    { lat: 49.0344825, lng: 19.7763726 },
    { lat: 49.034421, lng: 19.7760973 },
    { lat: 49.0343956, lng: 19.7760069 },
    { lat: 49.0342122, lng: 19.7755952 },
    { lat: 49.0339465, lng: 19.7746152 },
    { lat: 49.0339357, lng: 19.7745258 },
    { lat: 49.0339223, lng: 19.7739836 },
    { lat: 49.0339545, lng: 19.7737897 },
    { lat: 49.0340216, lng: 19.7734932 },
    { lat: 49.0340712, lng: 19.7733144 },
    { lat: 49.0341733, lng: 19.7730446 },
    { lat: 49.0343137, lng: 19.7727504 },
    { lat: 49.0345172, lng: 19.7724032 },
    { lat: 49.0345867, lng: 19.7720856 },
    { lat: 49.0344714, lng: 19.7714818 },
    { lat: 49.0344564, lng: 19.7714464 },
    { lat: 49.0342574, lng: 19.7711957 },
    { lat: 49.0340474, lng: 19.7709537 },
    { lat: 49.0337248, lng: 19.7705018 },
    { lat: 49.0335816, lng: 19.7701141 },
    { lat: 49.0335372, lng: 19.7698421 },
    { lat: 49.0335144, lng: 19.7695518 },
    { lat: 49.0335107, lng: 19.7693895 },
    { lat: 49.0335383, lng: 19.769167 },
    { lat: 49.0335902, lng: 19.7686789 },
    { lat: 49.0336353, lng: 19.7684453 },
    { lat: 49.0337881, lng: 19.7679689 },
    { lat: 49.0339273, lng: 19.7675987 },
    { lat: 49.0340812, lng: 19.7673057 },
    { lat: 49.0341959, lng: 19.7670668 },
    { lat: 49.0343212, lng: 19.76679 },
    { lat: 49.0345438, lng: 19.7663381 },
    { lat: 49.0346531, lng: 19.7661325 },
    { lat: 49.0348218, lng: 19.7657612 },
    { lat: 49.0349176, lng: 19.7654801 },
    { lat: 49.0349085, lng: 19.7652156 },
    { lat: 49.0348883, lng: 19.7647796 },
    { lat: 49.034894, lng: 19.7646509 },
    { lat: 49.0348692, lng: 19.7645252 },
    { lat: 49.0348148, lng: 19.7641555 },
    { lat: 49.0347762, lng: 19.7639407 },
    { lat: 49.034748, lng: 19.7638621 },
    { lat: 49.0346547, lng: 19.7637088 },
    { lat: 49.0345726, lng: 19.7635329 },
    { lat: 49.0344222, lng: 19.7631316 },
    { lat: 49.0344162, lng: 19.7630949 },
    { lat: 49.0344284, lng: 19.7628606 },
    { lat: 49.03443, lng: 19.7627183 },
    { lat: 49.0344488, lng: 19.7624252 },
    { lat: 49.034465, lng: 19.7619949 },
    { lat: 49.034468, lng: 19.7617267 },
    { lat: 49.0345118, lng: 19.7611026 },
    { lat: 49.0344946, lng: 19.7606318 },
    { lat: 49.0344732, lng: 19.7601683 },
    { lat: 49.034451, lng: 19.7598374 },
    { lat: 49.0344664, lng: 19.7594724 },
    { lat: 49.0344948, lng: 19.7591368 },
    { lat: 49.0344927, lng: 19.7590673 },
    { lat: 49.0344529, lng: 19.7587457 },
    { lat: 49.0344329, lng: 19.7586217 },
    { lat: 49.0343084, lng: 19.7582737 },
    { lat: 49.034292, lng: 19.7582058 },
    { lat: 49.0342912, lng: 19.758166 },
    { lat: 49.0342738, lng: 19.7580489 },
    { lat: 49.0342489, lng: 19.7579703 },
    { lat: 49.0341176, lng: 19.7578018 },
    { lat: 49.0339474, lng: 19.7575429 },
    { lat: 49.0337324, lng: 19.7571596 },
    { lat: 49.0336856, lng: 19.7570663 },
    { lat: 49.0335231, lng: 19.7566968 },
    { lat: 49.0334491, lng: 19.7565534 },
    { lat: 49.0332739, lng: 19.7561525 },
    { lat: 49.0331725, lng: 19.7559441 },
    { lat: 49.0329545, lng: 19.7555403 },
    { lat: 49.0326128, lng: 19.7549447 },
    { lat: 49.0324305, lng: 19.7546891 },
    { lat: 49.032309, lng: 19.7545108 },
    { lat: 49.0322618, lng: 19.7544642 },
    { lat: 49.0321354, lng: 19.7543879 },
    { lat: 49.0320017, lng: 19.7542862 },
    { lat: 49.0319526, lng: 19.7542699 },
    { lat: 49.0318146, lng: 19.7542562 },
    { lat: 49.0317402, lng: 19.7542289 },
    { lat: 49.0315323, lng: 19.7541918 },
    { lat: 49.0312343, lng: 19.7540757 },
    { lat: 49.0310927, lng: 19.7540017 },
    { lat: 49.0310677, lng: 19.7539974 },
    { lat: 49.0310088, lng: 19.7539405 },
    { lat: 49.0308101, lng: 19.7537746 },
    { lat: 49.0307283, lng: 19.7536856 },
    { lat: 49.0306225, lng: 19.7535333 },
    { lat: 49.0305516, lng: 19.75338 },
    { lat: 49.0305317, lng: 19.7533073 },
    { lat: 49.0305275, lng: 19.7532661 },
    { lat: 49.0305348, lng: 19.752893 },
    { lat: 49.0305508, lng: 19.752405 },
    { lat: 49.0306965, lng: 19.7517411 },
    { lat: 49.0307382, lng: 19.7515391 },
    { lat: 49.0307667, lng: 19.7513643 },
    { lat: 49.0308385, lng: 19.7509983 },
    { lat: 49.0309106, lng: 19.7506119 },
    { lat: 49.0309853, lng: 19.7502576 },
    { lat: 49.0310374, lng: 19.7500552 },
    { lat: 49.0311208, lng: 19.7498242 },
    { lat: 49.0312519, lng: 19.7496533 },
    { lat: 49.0314655, lng: 19.7494106 },
    { lat: 49.0314804, lng: 19.7493409 },
    { lat: 49.0317352, lng: 19.748699 },
    { lat: 49.0318605, lng: 19.7486806 },
    { lat: 49.0322203, lng: 19.7478928 },
    { lat: 49.0322347, lng: 19.7478881 },
    { lat: 49.0326159, lng: 19.7475347 },
    { lat: 49.0330735, lng: 19.7467646 },
    { lat: 49.033344, lng: 19.7461491 },
    { lat: 49.0334952, lng: 19.7457952 },
    { lat: 49.0335665, lng: 19.7457259 },
    { lat: 49.0345146, lng: 19.7466655 },
    { lat: 49.0345417, lng: 19.7467177 },
    { lat: 49.0346043, lng: 19.746762 },
    { lat: 49.0349112, lng: 19.747006 },
    { lat: 49.0349822, lng: 19.7462311 },
    { lat: 49.0360245, lng: 19.7466212 },
    { lat: 49.0363896, lng: 19.7442001 },
    { lat: 49.0363936, lng: 19.7441674 },
    { lat: 49.036771, lng: 19.7416682 },
    { lat: 49.0355188, lng: 19.7413487 },
    { lat: 49.0359695, lng: 19.7371559 },
    { lat: 49.0359445, lng: 19.7368432 },
    { lat: 49.0360912, lng: 19.7353533 },
    { lat: 49.0360329, lng: 19.7353376 },
    { lat: 49.035996, lng: 19.73534 },
    { lat: 49.0357942, lng: 19.7353152 },
    { lat: 49.0357168, lng: 19.7353485 },
    { lat: 49.0355465, lng: 19.7354972 },
    { lat: 49.0354059, lng: 19.7356069 },
    { lat: 49.0346699, lng: 19.7360341 },
    { lat: 49.0343534, lng: 19.7362666 },
    { lat: 49.0340684, lng: 19.7366279 },
    { lat: 49.0339356, lng: 19.7368604 },
    { lat: 49.033893, lng: 19.7368439 },
    { lat: 49.0337672, lng: 19.7366839 },
    { lat: 49.0339415, lng: 19.7362254 },
    { lat: 49.0339719, lng: 19.7360952 },
    { lat: 49.034013, lng: 19.7358826 },
    { lat: 49.0340297, lng: 19.7357712 },
    { lat: 49.0340594, lng: 19.7355481 },
    { lat: 49.0340879, lng: 19.7352724 },
    { lat: 49.0341058, lng: 19.73496 },
    { lat: 49.0341121, lng: 19.7347687 },
    { lat: 49.0340814, lng: 19.7342253 },
    { lat: 49.033998, lng: 19.7338898 },
    { lat: 49.033946, lng: 19.7336556 },
    { lat: 49.0339165, lng: 19.7335484 },
    { lat: 49.0336685, lng: 19.7328522 },
    { lat: 49.033582, lng: 19.7325956 },
    { lat: 49.0335251, lng: 19.7324089 },
    { lat: 49.0334629, lng: 19.7321424 },
    { lat: 49.0333884, lng: 19.7317823 },
    { lat: 49.0333643, lng: 19.7315887 },
    { lat: 49.033345, lng: 19.7312783 },
    { lat: 49.0333269, lng: 19.730762 },
    { lat: 49.0333331, lng: 19.7301614 },
    { lat: 49.0333341, lng: 19.7298245 },
    { lat: 49.0333951, lng: 19.728491 },
    { lat: 49.0333983, lng: 19.7283057 },
    { lat: 49.0333736, lng: 19.7276954 },
    { lat: 49.0333105, lng: 19.7273075 },
    { lat: 49.0332798, lng: 19.7271724 },
    { lat: 49.033166, lng: 19.7267628 },
    { lat: 49.03299, lng: 19.7263193 },
    { lat: 49.0328433, lng: 19.7259838 },
    { lat: 49.0326505, lng: 19.7255657 },
    { lat: 49.0324426, lng: 19.7249872 },
    { lat: 49.0323115, lng: 19.7245456 },
    { lat: 49.0322216, lng: 19.724218 },
    { lat: 49.0320957, lng: 19.7239141 },
    { lat: 49.0320796, lng: 19.7238059 },
    { lat: 49.0320456, lng: 19.7234887 },
    { lat: 49.0320279, lng: 19.7232683 },
    { lat: 49.0320971, lng: 19.7226904 },
    { lat: 49.0321475, lng: 19.7221445 },
    { lat: 49.0322029, lng: 19.7217397 },
    { lat: 49.0322296, lng: 19.7216802 },
    { lat: 49.032299, lng: 19.7212836 },
    { lat: 49.0323806, lng: 19.7208614 },
    { lat: 49.0324097, lng: 19.7207284 },
    { lat: 49.03248, lng: 19.7204821 },
    { lat: 49.032642, lng: 19.7201171 },
    { lat: 49.0329261, lng: 19.7197866 },
    { lat: 49.0331482, lng: 19.7194577 },
    { lat: 49.0331526, lng: 19.7194286 },
    { lat: 49.0334837, lng: 19.7188409 },
    { lat: 49.0336013, lng: 19.7186167 },
    { lat: 49.0337576, lng: 19.7182504 },
    { lat: 49.0337676, lng: 19.7181718 },
    { lat: 49.0338214, lng: 19.7179624 },
    { lat: 49.0338469, lng: 19.7179045 },
    { lat: 49.0341225, lng: 19.7171961 },
    { lat: 49.0341582, lng: 19.7170912 },
    { lat: 49.0343393, lng: 19.7166466 },
    { lat: 49.0347033, lng: 19.7158304 },
    { lat: 49.0348094, lng: 19.715504 },
    { lat: 49.0348529, lng: 19.7153405 },
    { lat: 49.0349327, lng: 19.7148731 },
    { lat: 49.0349451, lng: 19.714544 },
    { lat: 49.0349664, lng: 19.7137185 },
    { lat: 49.0349614, lng: 19.7133262 },
    { lat: 49.0349464, lng: 19.7130319 },
    { lat: 49.0349269, lng: 19.7128479 },
    { lat: 49.0348701, lng: 19.7124519 },
    { lat: 49.0348176, lng: 19.7122569 },
    { lat: 49.034672, lng: 19.7117924 },
    { lat: 49.0346534, lng: 19.7117505 },
    { lat: 49.0346369, lng: 19.7116875 },
    { lat: 49.0346087, lng: 19.7114814 },
    { lat: 49.0345935, lng: 19.7114155 },
    { lat: 49.0345886, lng: 19.7113578 },
    { lat: 49.0346235, lng: 19.7112013 },
    { lat: 49.0346617, lng: 19.710969 },
    { lat: 49.0347179, lng: 19.7101106 },
    { lat: 49.034699, lng: 19.7102086 },
    { lat: 49.0347155, lng: 19.7100194 },
    { lat: 49.0346455, lng: 19.7099619 },
    { lat: 49.0346585, lng: 19.7097173 },
    { lat: 49.0346467, lng: 19.7094007 },
    { lat: 49.0345455, lng: 19.7089942 },
    { lat: 49.0342781, lng: 19.7081453 },
    { lat: 49.0342564, lng: 19.7080862 },
    { lat: 49.033895, lng: 19.7073736 },
    { lat: 49.0336081, lng: 19.7072826 },
    { lat: 49.0332092, lng: 19.7075085 },
    { lat: 49.0325912, lng: 19.7081327 },
    { lat: 49.0322321, lng: 19.7085646 },
    { lat: 49.0319973, lng: 19.708847 },
    { lat: 49.0316818, lng: 19.709026 },
    { lat: 49.0312062, lng: 19.7091463 },
    { lat: 49.0309338, lng: 19.709126 },
    { lat: 49.0304672, lng: 19.7090213 },
    { lat: 49.0300026, lng: 19.7089096 },
    { lat: 49.0296855, lng: 19.7087697 },
    { lat: 49.028109, lng: 19.7080958 },
    { lat: 49.0277617, lng: 19.7079603 },
    { lat: 49.0277418, lng: 19.7079437 },
    { lat: 49.0266918, lng: 19.7068746 },
    { lat: 49.0266443, lng: 19.7068145 },
    { lat: 49.0262526, lng: 19.7065456 },
    { lat: 49.0256227, lng: 19.7061062 },
    { lat: 49.0253162, lng: 19.7056966 },
    { lat: 49.0248228, lng: 19.7051625 },
    { lat: 49.0244194, lng: 19.7048274 },
    { lat: 49.0242671, lng: 19.7046968 },
    { lat: 49.0238062, lng: 19.7043101 },
    { lat: 49.0233426, lng: 19.7041668 },
    { lat: 49.0229894, lng: 19.7040545 },
    { lat: 49.0223702, lng: 19.7037835 },
    { lat: 49.0216552, lng: 19.7036898 },
    { lat: 49.0211775, lng: 19.7029474 },
    { lat: 49.0206755, lng: 19.7026296 },
    { lat: 49.0204207, lng: 19.7024729 },
    { lat: 49.0200514, lng: 19.7019649 },
    { lat: 49.0195611, lng: 19.7012956 },
    { lat: 49.0185124, lng: 19.700483 },
    { lat: 49.0184387, lng: 19.7004496 },
    { lat: 49.0182074, lng: 19.7001616 },
    { lat: 49.0180934, lng: 19.7000289 },
    { lat: 49.0171391, lng: 19.6990068 },
    { lat: 49.015943, lng: 19.6979674 },
    { lat: 49.015048, lng: 19.6974759 },
    { lat: 49.0143791, lng: 19.6982225 },
    { lat: 49.0135901, lng: 19.697474 },
    { lat: 49.0130479, lng: 19.697797 },
    { lat: 49.0116556, lng: 19.6964523 },
    { lat: 49.0094499, lng: 19.6968135 },
    { lat: 49.0093745, lng: 19.6968431 },
    { lat: 49.0092628, lng: 19.6969908 },
    { lat: 49.0075761, lng: 19.6986455 },
    { lat: 49.0068684, lng: 19.6998665 },
    { lat: 49.0060853, lng: 19.7012199 },
    { lat: 49.0060405, lng: 19.7014029 },
    { lat: 49.0053006, lng: 19.703986 },
    { lat: 49.0042863, lng: 19.703878 },
    { lat: 49.0023594, lng: 19.7036894 },
    { lat: 49.0017124, lng: 19.7033495 },
    { lat: 49.0005744, lng: 19.7024835 },
    { lat: 49.0004452, lng: 19.7024116 },
    { lat: 48.9990393, lng: 19.7024437 },
    { lat: 48.9977587, lng: 19.7026019 },
    { lat: 48.9975051, lng: 19.7026001 },
    { lat: 48.9968725, lng: 19.7017214 },
    { lat: 48.9965443, lng: 19.7018635 },
    { lat: 48.996068, lng: 19.7014608 },
    { lat: 48.9957198, lng: 19.7013589 },
    { lat: 48.9936295, lng: 19.700942 },
    { lat: 48.9932491, lng: 19.7025554 },
    { lat: 48.9932365, lng: 19.7027085 },
    { lat: 48.9932998, lng: 19.7028387 },
    { lat: 48.992972, lng: 19.7042426 },
    { lat: 48.9894313, lng: 19.7037682 },
    { lat: 48.9861732, lng: 19.7033326 },
    { lat: 48.9855928, lng: 19.7030747 },
    { lat: 48.9853889, lng: 19.7035083 },
    { lat: 48.9848292, lng: 19.7041802 },
    { lat: 48.9843401, lng: 19.7053251 },
    { lat: 48.9829508, lng: 19.708768 },
    { lat: 48.9828644, lng: 19.7096463 },
    { lat: 48.9826457, lng: 19.7119695 },
    { lat: 48.9802691, lng: 19.711113 },
    { lat: 48.9778869, lng: 19.7083169 },
    { lat: 48.9752422, lng: 19.7066982 },
    { lat: 48.9732417, lng: 19.707342 },
    { lat: 48.971776, lng: 19.7078336 },
    { lat: 48.970779, lng: 19.708165 },
    { lat: 48.9693626, lng: 19.7090292 },
    { lat: 48.968733, lng: 19.7093135 },
    { lat: 48.9681166, lng: 19.7093887 },
    { lat: 48.9677437, lng: 19.7093582 },
    { lat: 48.9674552, lng: 19.7092832 },
    { lat: 48.9664532, lng: 19.7090696 },
    { lat: 48.9660343, lng: 19.7088681 },
    { lat: 48.965137, lng: 19.7086571 },
    { lat: 48.9648003, lng: 19.7087832 },
    { lat: 48.9639864, lng: 19.7091836 },
    { lat: 48.9629225, lng: 19.7095339 },
    { lat: 48.9620168, lng: 19.7089757 },
    { lat: 48.9617544, lng: 19.7089246 },
    { lat: 48.9614056, lng: 19.7094735 },
    { lat: 48.9611245, lng: 19.7098387 },
    { lat: 48.960888, lng: 19.7101518 },
    { lat: 48.9606243, lng: 19.7105576 },
    { lat: 48.9603984, lng: 19.7109549 },
    { lat: 48.9601154, lng: 19.7115895 },
    { lat: 48.9596621, lng: 19.7114499 },
    { lat: 48.9592126, lng: 19.7114766 },
    { lat: 48.9584195, lng: 19.7114526 },
    { lat: 48.9576786, lng: 19.7113329 },
    { lat: 48.9564397, lng: 19.7110129 },
    { lat: 48.9551853, lng: 19.7103859 },
    { lat: 48.9543022, lng: 19.7100072 },
    { lat: 48.9535194, lng: 19.7095817 },
    { lat: 48.9527267, lng: 19.7092572 },
    { lat: 48.9525239, lng: 19.7091822 },
    { lat: 48.9511276, lng: 19.7086879 },
    { lat: 48.9496259, lng: 19.7081059 },
    { lat: 48.9489208, lng: 19.7079539 },
    { lat: 48.9481857, lng: 19.7079922 },
    { lat: 48.9472099, lng: 19.7079366 },
    { lat: 48.9469439, lng: 19.7081418 },
    { lat: 48.9467502, lng: 19.7082479 },
    { lat: 48.9449986, lng: 19.7091705 },
    { lat: 48.9438807, lng: 19.7096792 },
    { lat: 48.9423104, lng: 19.7108793 },
    { lat: 48.9412493, lng: 19.7114986 },
    { lat: 48.940133, lng: 19.713418 },
    { lat: 48.940295, lng: 19.7140036 },
    { lat: 48.9411941, lng: 19.716378 },
    { lat: 48.9429271, lng: 19.7181488 },
    { lat: 48.9436593, lng: 19.718992 },
    { lat: 48.9456828, lng: 19.7214467 },
    { lat: 48.9461938, lng: 19.7230002 },
    { lat: 48.9475162, lng: 19.7256317 },
    { lat: 48.9481098, lng: 19.7270333 },
    { lat: 48.9483677, lng: 19.7278046 },
    { lat: 48.9485939, lng: 19.7285412 },
    { lat: 48.9492282, lng: 19.7302577 },
    { lat: 48.9495331, lng: 19.7314803 },
    { lat: 48.9497991, lng: 19.7321455 },
    { lat: 48.9499355, lng: 19.7334562 },
    { lat: 48.9499396, lng: 19.7345271 },
    { lat: 48.9509346, lng: 19.7356609 },
    { lat: 48.9516043, lng: 19.736418 },
    { lat: 48.9524126, lng: 19.7368904 },
    { lat: 48.9536023, lng: 19.7375805 },
    { lat: 48.9534461, lng: 19.7395672 },
    { lat: 48.9536772, lng: 19.7402131 },
    { lat: 48.9539126, lng: 19.7405968 },
    { lat: 48.9541603, lng: 19.740903 },
    { lat: 48.9548598, lng: 19.742391 },
    { lat: 48.9549276, lng: 19.7430376 },
    { lat: 48.9551231, lng: 19.7443098 },
    { lat: 48.9552648, lng: 19.7448167 },
    { lat: 48.9554385, lng: 19.745338 },
    { lat: 48.9558083, lng: 19.7463481 },
    { lat: 48.9560172, lng: 19.7473132 },
    { lat: 48.9561409, lng: 19.7479973 },
    { lat: 48.9571358, lng: 19.749519 },
    { lat: 48.9581151, lng: 19.7500181 },
    { lat: 48.9584146, lng: 19.7507866 },
    { lat: 48.9588981, lng: 19.7515366 },
    { lat: 48.959283, lng: 19.7523008 },
    { lat: 48.9594469, lng: 19.75275 },
    { lat: 48.9603285, lng: 19.7529192 },
    { lat: 48.9608141, lng: 19.7530477 },
    { lat: 48.9616672, lng: 19.7531685 },
    { lat: 48.9622855, lng: 19.753256 },
    { lat: 48.9628143, lng: 19.7533439 },
    { lat: 48.9634801, lng: 19.7539608 },
    { lat: 48.9637842, lng: 19.7544737 },
    { lat: 48.9640399, lng: 19.7549879 },
    { lat: 48.964328, lng: 19.7557774 },
    { lat: 48.9646462, lng: 19.7567438 },
    { lat: 48.9649041, lng: 19.7572334 },
    { lat: 48.9657551, lng: 19.7580953 },
    { lat: 48.9663275, lng: 19.7588942 },
    { lat: 48.9662713, lng: 19.7593628 },
    { lat: 48.96646, lng: 19.7604847 },
    { lat: 48.9671215, lng: 19.7616741 },
    { lat: 48.967724, lng: 19.7626536 },
    { lat: 48.968279, lng: 19.7636763 },
    { lat: 48.9689142, lng: 19.7647569 },
    { lat: 48.969051, lng: 19.7650511 },
    { lat: 48.9691615, lng: 19.7653875 },
    { lat: 48.9694066, lng: 19.7657884 },
    { lat: 48.9697156, lng: 19.7660324 },
    { lat: 48.9699126, lng: 19.7661538 },
    { lat: 48.9703175, lng: 19.7662407 },
    { lat: 48.970653, lng: 19.7666541 },
    { lat: 48.9709236, lng: 19.7670938 },
    { lat: 48.9711491, lng: 19.7684434 },
    { lat: 48.9713515, lng: 19.7689254 },
    { lat: 48.9715942, lng: 19.7696372 },
    { lat: 48.9730058, lng: 19.7700238 },
    { lat: 48.9735572, lng: 19.7705737 },
    { lat: 48.9741434, lng: 19.7713345 },
    { lat: 48.9747661, lng: 19.7719999 },
    { lat: 48.9760114, lng: 19.7725798 },
    { lat: 48.9763663, lng: 19.772684 },
    { lat: 48.9767677, lng: 19.7725124 },
    { lat: 48.9771183, lng: 19.7726712 },
    { lat: 48.977826, lng: 19.7730935 },
    { lat: 48.9781781, lng: 19.7732374 },
    { lat: 48.978312, lng: 19.7732053 },
    { lat: 48.9785603, lng: 19.773085 },
    { lat: 48.9786141, lng: 19.7730509 },
    { lat: 48.9785893, lng: 19.7729745 },
    { lat: 48.9784877, lng: 19.7725565 },
    { lat: 48.9784614, lng: 19.772369 },
    { lat: 48.9784633, lng: 19.7721802 },
    { lat: 48.9785099, lng: 19.7715637 },
    { lat: 48.9785877, lng: 19.7713615 },
    { lat: 48.9787337, lng: 19.7710377 },
    { lat: 48.9789412, lng: 19.7709135 },
    { lat: 48.9790373, lng: 19.7708698 },
    { lat: 48.9790986, lng: 19.770755 },
    { lat: 48.9791708, lng: 19.7706505 },
    { lat: 48.9792428, lng: 19.7704661 },
    { lat: 48.9793649, lng: 19.7702193 },
    { lat: 48.9794654, lng: 19.7699685 },
    { lat: 48.9795564, lng: 19.7696789 },
    { lat: 48.9796437, lng: 19.7692017 },
    { lat: 48.9796781, lng: 19.7690318 },
    { lat: 48.9796956, lng: 19.7688644 },
    { lat: 48.9797286, lng: 19.76877 },
    { lat: 48.9799574, lng: 19.7684095 },
    { lat: 48.9800598, lng: 19.7682783 },
    { lat: 48.9802578, lng: 19.7680923 },
    { lat: 48.9805868, lng: 19.7678082 },
    { lat: 48.9807775, lng: 19.7676344 },
    { lat: 48.9809059, lng: 19.7674497 },
    { lat: 48.981021, lng: 19.7673011 },
    { lat: 48.9811618, lng: 19.7670922 },
    { lat: 48.9813029, lng: 19.7668947 },
    { lat: 48.9814982, lng: 19.766643 },
    { lat: 48.9815268, lng: 19.7665989 },
    { lat: 48.9816706, lng: 19.7662803 },
    { lat: 48.98177, lng: 19.7660816 },
    { lat: 48.9818735, lng: 19.765899 },
    { lat: 48.9819419, lng: 19.7657096 },
    { lat: 48.9820805, lng: 19.7653746 },
    { lat: 48.9821823, lng: 19.7651535 },
    { lat: 48.982253, lng: 19.765014 },
    { lat: 48.9823812, lng: 19.76483 },
    { lat: 48.9825654, lng: 19.7645081 },
    { lat: 48.9827039, lng: 19.7643284 },
    { lat: 48.9828906, lng: 19.7641378 },
    { lat: 48.9830061, lng: 19.7640462 },
    { lat: 48.9832742, lng: 19.7638929 },
    { lat: 48.9835509, lng: 19.7636764 },
    { lat: 48.983643, lng: 19.7635638 },
    { lat: 48.9837013, lng: 19.7634554 },
    { lat: 48.9837657, lng: 19.7632549 },
    { lat: 48.9838049, lng: 19.7630447 },
    { lat: 48.9838585, lng: 19.7628216 },
    { lat: 48.9839093, lng: 19.7626453 },
    { lat: 48.9840739, lng: 19.7622618 },
    { lat: 48.9841756, lng: 19.7621149 },
    { lat: 48.9844122, lng: 19.7618565 },
    { lat: 48.9845815, lng: 19.7616562 },
    { lat: 48.9847437, lng: 19.7614738 },
    { lat: 48.984884, lng: 19.7613345 },
    { lat: 48.9850171, lng: 19.7611826 },
    { lat: 48.9852232, lng: 19.7609764 },
    { lat: 48.9852873, lng: 19.7608709 },
    { lat: 48.9853821, lng: 19.7606695 },
    { lat: 48.9855254, lng: 19.7604639 },
    { lat: 48.9858507, lng: 19.7600197 },
    { lat: 48.9859609, lng: 19.7599099 },
    { lat: 48.9861358, lng: 19.7598116 },
    { lat: 48.986367, lng: 19.7597714 },
    { lat: 48.9868495, lng: 19.7597009 },
    { lat: 48.9870387, lng: 19.759566 },
    { lat: 48.9872861, lng: 19.7593499 },
    { lat: 48.9876359, lng: 19.7589498 },
    { lat: 48.9881777, lng: 19.7584084 },
    { lat: 48.988311, lng: 19.7583139 },
    { lat: 48.9886569, lng: 19.7582629 },
    { lat: 48.9888729, lng: 19.7583053 },
    { lat: 48.9891799, lng: 19.75842 },
    { lat: 48.9894314, lng: 19.7584822 },
    { lat: 48.9896061, lng: 19.75864 },
    { lat: 48.9897282, lng: 19.758726 },
    { lat: 48.9898264, lng: 19.758781 },
    { lat: 48.9899923, lng: 19.7588113 },
    { lat: 48.9901897, lng: 19.7587914 },
    { lat: 48.9903861, lng: 19.7587256 },
    { lat: 48.9908336, lng: 19.7587282 },
    { lat: 48.9911258, lng: 19.7587921 },
    { lat: 48.9915114, lng: 19.7589516 },
    { lat: 48.9917234, lng: 19.7590873 },
    { lat: 48.9920447, lng: 19.759352 },
    { lat: 48.9921999, lng: 19.7594995 },
    { lat: 48.9924043, lng: 19.7596127 },
    { lat: 48.992512, lng: 19.7596309 },
    { lat: 48.9928858, lng: 19.7596227 },
    { lat: 48.9932869, lng: 19.7596005 },
    { lat: 48.9934972, lng: 19.7596149 },
    { lat: 48.9936385, lng: 19.7597054 },
    { lat: 48.9938808, lng: 19.7599134 },
    { lat: 48.993944, lng: 19.7600466 },
    { lat: 48.9940894, lng: 19.7604851 },
    { lat: 48.9942686, lng: 19.7611824 },
    { lat: 48.9945183, lng: 19.7617438 },
    { lat: 48.994604, lng: 19.7616946 },
    { lat: 48.9947397, lng: 19.7615478 },
    { lat: 48.9948687, lng: 19.7611979 },
    { lat: 48.9950184, lng: 19.7609393 },
    { lat: 48.9951221, lng: 19.7608649 },
    { lat: 48.995202, lng: 19.7608626 },
    { lat: 48.995332, lng: 19.7609478 },
    { lat: 48.9956098, lng: 19.7611939 },
    { lat: 48.9957661, lng: 19.7613464 },
    { lat: 48.9960697, lng: 19.7617464 },
    { lat: 48.9963291, lng: 19.7621619 },
    { lat: 48.9964238, lng: 19.7623209 },
    { lat: 48.9965539, lng: 19.7626684 },
    { lat: 48.9967022, lng: 19.7630194 },
    { lat: 48.9969119, lng: 19.7639053 },
    { lat: 48.9970254, lng: 19.7642332 },
    { lat: 48.9971645, lng: 19.7647387 },
    { lat: 48.9972512, lng: 19.7650217 },
    { lat: 48.9974336, lng: 19.765253 },
    { lat: 48.9975179, lng: 19.7653489 },
    { lat: 48.9976597, lng: 19.7654516 },
    { lat: 48.997761, lng: 19.7654992 },
    { lat: 48.997918, lng: 19.7654933 },
    { lat: 48.9979794, lng: 19.7655235 },
    { lat: 48.9982416, lng: 19.7654623 },
    { lat: 48.9983061, lng: 19.7654676 },
    { lat: 48.9983991, lng: 19.7655113 },
    { lat: 48.9985256, lng: 19.7655908 },
    { lat: 48.9987045, lng: 19.7657421 },
    { lat: 48.9988835, lng: 19.7659218 },
    { lat: 48.9989508, lng: 19.7660424 },
    { lat: 48.9991231, lng: 19.7666641 },
    { lat: 48.9991232, lng: 19.7668442 },
    { lat: 48.9991349, lng: 19.7670098 },
    { lat: 48.9991609, lng: 19.7672444 },
    { lat: 48.9991973, lng: 19.7673813 },
    { lat: 48.9992585, lng: 19.767523 },
    { lat: 48.9993171, lng: 19.7676258 },
    { lat: 48.9994061, lng: 19.7676718 },
    { lat: 48.9996042, lng: 19.7676446 },
    { lat: 48.9997238, lng: 19.7675978 },
    { lat: 48.9999291, lng: 19.7673966 },
    { lat: 49.000103, lng: 19.7673562 },
    { lat: 49.0002164, lng: 19.7673753 },
    { lat: 49.0003493, lng: 19.7673744 },
    { lat: 49.0004327, lng: 19.7673983 },
    { lat: 49.0006093, lng: 19.767419 },
    { lat: 49.0007128, lng: 19.7674659 },
    { lat: 49.0007806, lng: 19.7675747 },
    { lat: 49.000824, lng: 19.7677694 },
    { lat: 49.0008327, lng: 19.7679677 },
    { lat: 49.0009044, lng: 19.7681137 },
    { lat: 49.0009844, lng: 19.7682208 },
    { lat: 49.0010665, lng: 19.7682412 },
    { lat: 49.0013123, lng: 19.7681932 },
    { lat: 49.0014165, lng: 19.7681307 },
    { lat: 49.0015509, lng: 19.7679539 },
    { lat: 49.0018874, lng: 19.7674317 },
    { lat: 49.0019428, lng: 19.7673855 },
    { lat: 49.0022796, lng: 19.7672352 },
    { lat: 49.0027534, lng: 19.7671403 },
    { lat: 49.0031172, lng: 19.7671615 },
    { lat: 49.0034098, lng: 19.7671335 },
    { lat: 49.0035696, lng: 19.7671005 },
    { lat: 49.0037069, lng: 19.7670217 },
    { lat: 49.0044428, lng: 19.7664051 },
    { lat: 49.0046496, lng: 19.7663179 },
    { lat: 49.0047587, lng: 19.7662882 },
    { lat: 49.0048759, lng: 19.7663389 },
    { lat: 49.0049235, lng: 19.7663746 },
    { lat: 49.0052905, lng: 19.7667514 },
    { lat: 49.0062468, lng: 19.7676832 },
    { lat: 49.0063026, lng: 19.7677235 },
    { lat: 49.0065993, lng: 19.7677543 },
    { lat: 49.0071807, lng: 19.7675264 },
    { lat: 49.0073808, lng: 19.7674632 },
    { lat: 49.007549, lng: 19.7674441 },
    { lat: 49.0076902, lng: 19.7674575 },
    { lat: 49.0079278, lng: 19.7675291 },
    { lat: 49.0083541, lng: 19.7677194 },
    { lat: 49.0087567, lng: 19.7678077 },
    { lat: 49.0090785, lng: 19.7679806 },
    { lat: 49.0091758, lng: 19.768091 },
    { lat: 49.0092443, lng: 19.7682169 },
    { lat: 49.0093584, lng: 19.7685115 },
    { lat: 49.0095646, lng: 19.7692077 },
    { lat: 49.0097657, lng: 19.7698191 },
    { lat: 49.0098929, lng: 19.7699696 },
    { lat: 49.0099304, lng: 19.7700293 },
    { lat: 49.0100369, lng: 19.770094 },
    { lat: 49.010087, lng: 19.7701065 },
    { lat: 49.010192, lng: 19.7700845 },
    { lat: 49.0103341, lng: 19.770012 },
    { lat: 49.0105195, lng: 19.7700515 },
    { lat: 49.0106418, lng: 19.7700916 },
    { lat: 49.0107657, lng: 19.770091 },
    { lat: 49.0110028, lng: 19.7700262 },
    { lat: 49.0111204, lng: 19.7700381 },
    { lat: 49.0112294, lng: 19.7700325 },
    { lat: 49.0113397, lng: 19.7701032 },
    { lat: 49.0114434, lng: 19.7702087 },
    { lat: 49.0117055, lng: 19.7704579 },
    { lat: 49.0117862, lng: 19.7705287 },
    { lat: 49.0118713, lng: 19.7706189 },
    { lat: 49.0119627, lng: 19.7706739 },
    { lat: 49.0122537, lng: 19.7708657 },
    { lat: 49.0124269, lng: 19.7709351 },
    { lat: 49.0126672, lng: 19.7710972 },
    { lat: 49.0128371, lng: 19.7711731 },
    { lat: 49.0129716, lng: 19.7712582 },
    { lat: 49.0131318, lng: 19.7713412 },
    { lat: 49.0132383, lng: 19.7714349 },
    { lat: 49.0134869, lng: 19.7715556 },
    { lat: 49.0135619, lng: 19.7716464 },
    { lat: 49.0136511, lng: 19.7717307 },
    { lat: 49.0137296, lng: 19.7718272 },
    { lat: 49.0138334, lng: 19.7720092 },
    { lat: 49.0139407, lng: 19.77212 },
    { lat: 49.0140434, lng: 19.7722027 },
    { lat: 49.0141727, lng: 19.7723526 },
    { lat: 49.0142527, lng: 19.7724554 },
    { lat: 49.0145353, lng: 19.7726829 },
    { lat: 49.0146957, lng: 19.7727942 },
    { lat: 49.0149292, lng: 19.7728812 },
    { lat: 49.0150402, lng: 19.7728931 },
    { lat: 49.0153387, lng: 19.7728695 },
    { lat: 49.0154981, lng: 19.7727774 },
    { lat: 49.0157509, lng: 19.7727347 },
    { lat: 49.0158433, lng: 19.7727369 },
    { lat: 49.0160033, lng: 19.7728436 },
    { lat: 49.0161362, lng: 19.7730502 },
    { lat: 49.0162488, lng: 19.7732018 },
    { lat: 49.0164503, lng: 19.7735071 },
    { lat: 49.0165435, lng: 19.7736316 },
    { lat: 49.0166162, lng: 19.7737463 },
    { lat: 49.0167208, lng: 19.7738761 },
    { lat: 49.0168371, lng: 19.7740381 },
    { lat: 49.0169725, lng: 19.7742747 },
    { lat: 49.017127, lng: 19.7745089 },
    { lat: 49.0172143, lng: 19.7746287 },
    { lat: 49.0173435, lng: 19.7747488 },
    { lat: 49.0174654, lng: 19.7748823 },
    { lat: 49.017579, lng: 19.7749577 },
    { lat: 49.0177492, lng: 19.7751384 },
    { lat: 49.0178621, lng: 19.7753015 },
    { lat: 49.0179593, lng: 19.7754141 },
    { lat: 49.0181137, lng: 19.7756197 },
    { lat: 49.0182028, lng: 19.7757266 },
    { lat: 49.0183051, lng: 19.7758736 },
    { lat: 49.0183668, lng: 19.7760009 },
    { lat: 49.0184304, lng: 19.7762213 },
    { lat: 49.0185143, lng: 19.7764401 },
    { lat: 49.0185785, lng: 19.7765434 },
    { lat: 49.0186487, lng: 19.7766003 },
    { lat: 49.0187696, lng: 19.7766122 },
    { lat: 49.0188774, lng: 19.7766534 },
    { lat: 49.0191433, lng: 19.7766554 },
    { lat: 49.0193829, lng: 19.7767243 },
    { lat: 49.0195055, lng: 19.7767697 },
    { lat: 49.0196158, lng: 19.7767942 },
    { lat: 49.0197883, lng: 19.7768522 },
    { lat: 49.0199855, lng: 19.7769024 },
    { lat: 49.0203706, lng: 19.7770557 },
    { lat: 49.0205831, lng: 19.7772264 },
    { lat: 49.0207735, lng: 19.7774337 },
    { lat: 49.0209941, lng: 19.7777134 },
    { lat: 49.0210792, lng: 19.7778043 },
    { lat: 49.0211938, lng: 19.777903 },
    { lat: 49.0212895, lng: 19.7780043 },
    { lat: 49.0213643, lng: 19.778014 },
    { lat: 49.0214368, lng: 19.778053 },
    { lat: 49.0216924, lng: 19.7781494 },
    { lat: 49.0219663, lng: 19.7782037 },
    { lat: 49.0220079, lng: 19.7782063 },
    { lat: 49.0221996, lng: 19.7783152 },
    { lat: 49.0223733, lng: 19.7783674 },
    { lat: 49.0225707, lng: 19.7784017 },
    { lat: 49.0228284, lng: 19.7785252 },
    { lat: 49.0230155, lng: 19.7786575 },
    { lat: 49.0230815, lng: 19.7787485 },
    { lat: 49.0231293, lng: 19.778837 },
    { lat: 49.0231673, lng: 19.7789351 },
    { lat: 49.0231696, lng: 19.7791467 },
    { lat: 49.0231527, lng: 19.7793483 },
    { lat: 49.0231527, lng: 19.7796366 },
    { lat: 49.0231574, lng: 19.7796637 },
    { lat: 49.0232452, lng: 19.7798969 },
    { lat: 49.0234168, lng: 19.7802942 },
    { lat: 49.0234749, lng: 19.7803857 },
    { lat: 49.0236204, lng: 19.7806482 },
    { lat: 49.023868, lng: 19.7809026 },
    { lat: 49.0240013, lng: 19.7809664 },
    { lat: 49.0241513, lng: 19.7810234 },
    { lat: 49.0243186, lng: 19.7810601 },
    { lat: 49.0245837, lng: 19.7810432 },
    { lat: 49.0248718, lng: 19.7811101 },
    { lat: 49.0250317, lng: 19.7811615 },
    { lat: 49.0255727, lng: 19.7813028 },
    { lat: 49.0256326, lng: 19.7813273 },
    { lat: 49.0257168, lng: 19.7815048 },
    { lat: 49.0258276, lng: 19.7817195 },
    { lat: 49.026027, lng: 19.7819299 },
    { lat: 49.026252, lng: 19.7820771 },
    { lat: 49.0264248, lng: 19.7821413 },
    { lat: 49.0266975, lng: 19.782191 },
    { lat: 49.0268083, lng: 19.7822801 },
    { lat: 49.0268813, lng: 19.7823521 },
    { lat: 49.0270137, lng: 19.7825201 },
    { lat: 49.0271404, lng: 19.7827145 },
    { lat: 49.0271417, lng: 19.7827176 },
    { lat: 49.0272103, lng: 19.7828191 },
    { lat: 49.027342, lng: 19.7831825 },
    { lat: 49.0273722, lng: 19.7832516 },
    { lat: 49.0274864, lng: 19.7834196 },
    { lat: 49.0275688, lng: 19.783579 },
    { lat: 49.02766, lng: 19.7837109 },
    { lat: 49.027791, lng: 19.7839494 },
    { lat: 49.0278274, lng: 19.7839827 },
    { lat: 49.0278885, lng: 19.784015 },
  ],
  LiptovskéBeharovce: [
    { lat: 49.1369301, lng: 19.5858066 },
    { lat: 49.1374637, lng: 19.5867436 },
    { lat: 49.137882, lng: 19.5876042 },
    { lat: 49.1380009, lng: 19.5879696 },
    { lat: 49.1381389, lng: 19.5882542 },
    { lat: 49.1383769, lng: 19.5886633 },
    { lat: 49.1388426, lng: 19.5893692 },
    { lat: 49.1389197, lng: 19.589482 },
    { lat: 49.1389515, lng: 19.5894384 },
    { lat: 49.1391454, lng: 19.5892897 },
    { lat: 49.1391709, lng: 19.589205 },
    { lat: 49.1393496, lng: 19.5890204 },
    { lat: 49.1396587, lng: 19.5887286 },
    { lat: 49.1397119, lng: 19.5887612 },
    { lat: 49.1397135, lng: 19.5886434 },
    { lat: 49.1398221, lng: 19.5886029 },
    { lat: 49.1398769, lng: 19.5886191 },
    { lat: 49.1398465, lng: 19.5884934 },
    { lat: 49.1399039, lng: 19.5884212 },
    { lat: 49.1399728, lng: 19.5883986 },
    { lat: 49.1401153, lng: 19.5883858 },
    { lat: 49.1402188, lng: 19.5882303 },
    { lat: 49.1402502, lng: 19.5881259 },
    { lat: 49.1403496, lng: 19.5881109 },
    { lat: 49.1405023, lng: 19.5878937 },
    { lat: 49.1405662, lng: 19.5878878 },
    { lat: 49.1406682, lng: 19.5877515 },
    { lat: 49.1407858, lng: 19.5877345 },
    { lat: 49.1407917, lng: 19.5877667 },
    { lat: 49.140883, lng: 19.5876179 },
    { lat: 49.1409248, lng: 19.5876452 },
    { lat: 49.1409799, lng: 19.5875937 },
    { lat: 49.1410253, lng: 19.5875742 },
    { lat: 49.1411887, lng: 19.5877547 },
    { lat: 49.1412346, lng: 19.5876455 },
    { lat: 49.1412875, lng: 19.5876191 },
    { lat: 49.1413253, lng: 19.5875313 },
    { lat: 49.1413601, lng: 19.5873812 },
    { lat: 49.1415003, lng: 19.5872906 },
    { lat: 49.1416334, lng: 19.5873166 },
    { lat: 49.1416505, lng: 19.5872443 },
    { lat: 49.141738, lng: 19.5872373 },
    { lat: 49.1418278, lng: 19.5871826 },
    { lat: 49.1419038, lng: 19.5871926 },
    { lat: 49.1419836, lng: 19.5870652 },
    { lat: 49.1421944, lng: 19.5872655 },
    { lat: 49.1423095, lng: 19.5872463 },
    { lat: 49.1424031, lng: 19.5873225 },
    { lat: 49.1425216, lng: 19.5872997 },
    { lat: 49.1425962, lng: 19.5872329 },
    { lat: 49.1426697, lng: 19.5872392 },
    { lat: 49.1427068, lng: 19.5872598 },
    { lat: 49.1428682, lng: 19.5872167 },
    { lat: 49.1429296, lng: 19.5872545 },
    { lat: 49.1429862, lng: 19.5871579 },
    { lat: 49.1430551, lng: 19.5871885 },
    { lat: 49.1431442, lng: 19.5872897 },
    { lat: 49.1432579, lng: 19.5872151 },
    { lat: 49.1433092, lng: 19.5873012 },
    { lat: 49.1434702, lng: 19.587352 },
    { lat: 49.1435601, lng: 19.587267 },
    { lat: 49.1436685, lng: 19.5872733 },
    { lat: 49.1436681, lng: 19.5873176 },
    { lat: 49.1437726, lng: 19.5874096 },
    { lat: 49.1439064, lng: 19.5873298 },
    { lat: 49.1439415, lng: 19.5873578 },
    { lat: 49.1440029, lng: 19.5873222 },
    { lat: 49.1440603, lng: 19.5873907 },
    { lat: 49.1441988, lng: 19.587342 },
    { lat: 49.1442405, lng: 19.5873395 },
    { lat: 49.1442836, lng: 19.5873224 },
    { lat: 49.1444485, lng: 19.5873821 },
    { lat: 49.1447496, lng: 19.5873154 },
    { lat: 49.1452, lng: 19.5872564 },
    { lat: 49.1453829, lng: 19.5870653 },
    { lat: 49.1455268, lng: 19.5870103 },
    { lat: 49.1456325, lng: 19.5868776 },
    { lat: 49.1457472, lng: 19.5868512 },
    { lat: 49.1461052, lng: 19.5866687 },
    { lat: 49.1461892, lng: 19.5866826 },
    { lat: 49.1463, lng: 19.5866177 },
    { lat: 49.1463575, lng: 19.5866153 },
    { lat: 49.1463836, lng: 19.5865701 },
    { lat: 49.146455, lng: 19.5865033 },
    { lat: 49.1464943, lng: 19.5864275 },
    { lat: 49.1466133, lng: 19.5863924 },
    { lat: 49.1467656, lng: 19.5861987 },
    { lat: 49.1468807, lng: 19.5861733 },
    { lat: 49.146935, lng: 19.5861795 },
    { lat: 49.1469958, lng: 19.5861044 },
    { lat: 49.147067, lng: 19.5860592 },
    { lat: 49.1472816, lng: 19.5859428 },
    { lat: 49.1474706, lng: 19.5856891 },
    { lat: 49.1475233, lng: 19.5856325 },
    { lat: 49.1476921, lng: 19.5855014 },
    { lat: 49.147947, lng: 19.5854093 },
    { lat: 49.1480513, lng: 19.5853195 },
    { lat: 49.1481639, lng: 19.5852923 },
    { lat: 49.1484666, lng: 19.5850886 },
    { lat: 49.148511, lng: 19.5850749 },
    { lat: 49.1486069, lng: 19.584976 },
    { lat: 49.1487806, lng: 19.5847275 },
    { lat: 49.1489354, lng: 19.5845155 },
    { lat: 49.1490671, lng: 19.5842843 },
    { lat: 49.1490764, lng: 19.5842182 },
    { lat: 49.1490699, lng: 19.5841739 },
    { lat: 49.1491338, lng: 19.5840938 },
    { lat: 49.1492819, lng: 19.5839523 },
    { lat: 49.1493286, lng: 19.5838887 },
    { lat: 49.1493419, lng: 19.5838596 },
    { lat: 49.1493404, lng: 19.5836276 },
    { lat: 49.1493994, lng: 19.5834588 },
    { lat: 49.1494284, lng: 19.5833269 },
    { lat: 49.1494587, lng: 19.5830498 },
    { lat: 49.1495335, lng: 19.5828606 },
    { lat: 49.149576, lng: 19.5827759 },
    { lat: 49.1495919, lng: 19.5826781 },
    { lat: 49.1496414, lng: 19.5826301 },
    { lat: 49.1496619, lng: 19.5825337 },
    { lat: 49.1497212, lng: 19.5824263 },
    { lat: 49.149745, lng: 19.5823259 },
    { lat: 49.1499839, lng: 19.581779 },
    { lat: 49.150295, lng: 19.5811547 },
    { lat: 49.1512621, lng: 19.5801648 },
    { lat: 49.1515076, lng: 19.5798126 },
    { lat: 49.1517847, lng: 19.5791357 },
    { lat: 49.1518765, lng: 19.5788456 },
    { lat: 49.1519137, lng: 19.578696 },
    { lat: 49.1516721, lng: 19.5783855 },
    { lat: 49.1512251, lng: 19.57782 },
    { lat: 49.1512021, lng: 19.5777807 },
    { lat: 49.1510491, lng: 19.5774386 },
    { lat: 49.1509135, lng: 19.5770299 },
    { lat: 49.1506881, lng: 19.5763764 },
    { lat: 49.1503672, lng: 19.5757582 },
    { lat: 49.1502344, lng: 19.5755091 },
    { lat: 49.1499999, lng: 19.5750103 },
    { lat: 49.1498564, lng: 19.5747234 },
    { lat: 49.149472, lng: 19.5740683 },
    { lat: 49.1488651, lng: 19.5734531 },
    { lat: 49.1484111, lng: 19.5730033 },
    { lat: 49.1479334, lng: 19.5727305 },
    { lat: 49.1475672, lng: 19.5725314 },
    { lat: 49.1471292, lng: 19.572212 },
    { lat: 49.1467984, lng: 19.5718228 },
    { lat: 49.1465015, lng: 19.5715851 },
    { lat: 49.1456872, lng: 19.5713185 },
    { lat: 49.1448304, lng: 19.5710315 },
    { lat: 49.1445331, lng: 19.5707845 },
    { lat: 49.144262, lng: 19.5705481 },
    { lat: 49.1439822, lng: 19.5700401 },
    { lat: 49.1437521, lng: 19.5696346 },
    { lat: 49.1435668, lng: 19.569301 },
    { lat: 49.1431381, lng: 19.5686902 },
    { lat: 49.1430975, lng: 19.5686844 },
    { lat: 49.1425897, lng: 19.5685711 },
    { lat: 49.1421558, lng: 19.5685418 },
    { lat: 49.1417049, lng: 19.5688871 },
    { lat: 49.1413446, lng: 19.5691178 },
    { lat: 49.1413233, lng: 19.5691423 },
    { lat: 49.1413107, lng: 19.5691674 },
    { lat: 49.1410658, lng: 19.5694061 },
    { lat: 49.1404138, lng: 19.5701604 },
    { lat: 49.1401285, lng: 19.5704822 },
    { lat: 49.1399836, lng: 19.5707148 },
    { lat: 49.1391982, lng: 19.5712876 },
    { lat: 49.1391571, lng: 19.5713027 },
    { lat: 49.1391132, lng: 19.5715002 },
    { lat: 49.1390669, lng: 19.5717987 },
    { lat: 49.1389701, lng: 19.5719985 },
    { lat: 49.1389425, lng: 19.5720338 },
    { lat: 49.138809, lng: 19.572326 },
    { lat: 49.138644, lng: 19.57229 },
    { lat: 49.1385809, lng: 19.5723409 },
    { lat: 49.1385343, lng: 19.5723551 },
    { lat: 49.138386, lng: 19.5725153 },
    { lat: 49.1381418, lng: 19.5725935 },
    { lat: 49.1381087, lng: 19.5726333 },
    { lat: 49.1379528, lng: 19.5728979 },
    { lat: 49.1378422, lng: 19.5730949 },
    { lat: 49.1376452, lng: 19.5735743 },
    { lat: 49.137355, lng: 19.5742586 },
    { lat: 49.1365487, lng: 19.5761887 },
    { lat: 49.1363553, lng: 19.5766486 },
    { lat: 49.1363302, lng: 19.5766944 },
    { lat: 49.1362539, lng: 19.5766524 },
    { lat: 49.1361737, lng: 19.5765241 },
    { lat: 49.1360893, lng: 19.5764476 },
    { lat: 49.1360759, lng: 19.5764271 },
    { lat: 49.1360554, lng: 19.5762986 },
    { lat: 49.1359909, lng: 19.5761669 },
    { lat: 49.1359187, lng: 19.5761413 },
    { lat: 49.1359149, lng: 19.5759507 },
    { lat: 49.1357544, lng: 19.5758426 },
    { lat: 49.1357099, lng: 19.5758563 },
    { lat: 49.1356114, lng: 19.575818 },
    { lat: 49.1355801, lng: 19.5757 },
    { lat: 49.1354617, lng: 19.5756498 },
    { lat: 49.1352763, lng: 19.575462 },
    { lat: 49.1350012, lng: 19.5752569 },
    { lat: 49.1349669, lng: 19.5752965 },
    { lat: 49.1349474, lng: 19.5753615 },
    { lat: 49.1348595, lng: 19.5753394 },
    { lat: 49.1348026, lng: 19.5752979 },
    { lat: 49.1346841, lng: 19.5753715 },
    { lat: 49.1345957, lng: 19.5753588 },
    { lat: 49.1344869, lng: 19.5754213 },
    { lat: 49.134449, lng: 19.5755559 },
    { lat: 49.1342542, lng: 19.5755277 },
    { lat: 49.1342395, lng: 19.5756325 },
    { lat: 49.1341498, lng: 19.5757369 },
    { lat: 49.1339615, lng: 19.5757138 },
    { lat: 49.133671, lng: 19.5758619 },
    { lat: 49.1336186, lng: 19.5758012 },
    { lat: 49.1332858, lng: 19.5758447 },
    { lat: 49.1331108, lng: 19.5758584 },
    { lat: 49.1331067, lng: 19.5758462 },
    { lat: 49.1330933, lng: 19.5758217 },
    { lat: 49.132809, lng: 19.5757623 },
    { lat: 49.1327692, lng: 19.5757085 },
    { lat: 49.1326271, lng: 19.5757025 },
    { lat: 49.1324949, lng: 19.5756459 },
    { lat: 49.1324644, lng: 19.5756451 },
    { lat: 49.1324238, lng: 19.575568 },
    { lat: 49.1323802, lng: 19.5755748 },
    { lat: 49.1322891, lng: 19.57568 },
    { lat: 49.1322033, lng: 19.5755724 },
    { lat: 49.1320906, lng: 19.5755763 },
    { lat: 49.1319857, lng: 19.5755211 },
    { lat: 49.1319384, lng: 19.5754469 },
    { lat: 49.1319151, lng: 19.575381 },
    { lat: 49.1318784, lng: 19.5753899 },
    { lat: 49.1318691, lng: 19.5753085 },
    { lat: 49.1317746, lng: 19.5751874 },
    { lat: 49.1316949, lng: 19.5751939 },
    { lat: 49.1316208, lng: 19.5751276 },
    { lat: 49.1315389, lng: 19.5751862 },
    { lat: 49.13151, lng: 19.5751417 },
    { lat: 49.1314648, lng: 19.5751116 },
    { lat: 49.1314533, lng: 19.575035 },
    { lat: 49.1313601, lng: 19.5749646 },
    { lat: 49.1312553, lng: 19.5749637 },
    { lat: 49.1311859, lng: 19.5749026 },
    { lat: 49.1311423, lng: 19.5748359 },
    { lat: 49.1311687, lng: 19.5746936 },
    { lat: 49.1310665, lng: 19.5746251 },
    { lat: 49.1310371, lng: 19.5745498 },
    { lat: 49.1309203, lng: 19.5744836 },
    { lat: 49.1308855, lng: 19.5745128 },
    { lat: 49.1308635, lng: 19.5744697 },
    { lat: 49.1308778, lng: 19.5744157 },
    { lat: 49.1308198, lng: 19.5742293 },
    { lat: 49.1307022, lng: 19.5742434 },
    { lat: 49.1307125, lng: 19.5740743 },
    { lat: 49.1306686, lng: 19.5739501 },
    { lat: 49.1306064, lng: 19.5739671 },
    { lat: 49.1304761, lng: 19.5737544 },
    { lat: 49.1304, lng: 19.5737149 },
    { lat: 49.1303936, lng: 19.5735943 },
    { lat: 49.1303683, lng: 19.5735113 },
    { lat: 49.1302843, lng: 19.5734485 },
    { lat: 49.1302379, lng: 19.5733412 },
    { lat: 49.13023, lng: 19.5731934 },
    { lat: 49.1302587, lng: 19.5730555 },
    { lat: 49.1302549, lng: 19.5729972 },
    { lat: 49.1301495, lng: 19.5730047 },
    { lat: 49.1300909, lng: 19.5729565 },
    { lat: 49.1299216, lng: 19.5726997 },
    { lat: 49.1298356, lng: 19.5724421 },
    { lat: 49.1297552, lng: 19.5722832 },
    { lat: 49.1297298, lng: 19.5721956 },
    { lat: 49.1296385, lng: 19.5720195 },
    { lat: 49.1295711, lng: 19.5719237 },
    { lat: 49.1295308, lng: 19.5717563 },
    { lat: 49.1294767, lng: 19.5715792 },
    { lat: 49.1293899, lng: 19.5714818 },
    { lat: 49.1293627, lng: 19.5715009 },
    { lat: 49.1293473, lng: 19.5715827 },
    { lat: 49.1297153, lng: 19.5728465 },
    { lat: 49.1299003, lng: 19.5734776 },
    { lat: 49.1299466, lng: 19.5736505 },
    { lat: 49.1299629, lng: 19.5736402 },
    { lat: 49.1301398, lng: 19.5741135 },
    { lat: 49.1310631, lng: 19.5760006 },
    { lat: 49.1315629, lng: 19.5771417 },
    { lat: 49.1318102, lng: 19.5776763 },
    { lat: 49.1319708, lng: 19.578165 },
    { lat: 49.1321058, lng: 19.578882 },
    { lat: 49.1323847, lng: 19.5793234 },
    { lat: 49.1325475, lng: 19.5796604 },
    { lat: 49.1329503, lng: 19.5801947 },
    { lat: 49.1333605, lng: 19.5809001 },
    { lat: 49.1337253, lng: 19.5815434 },
    { lat: 49.1343515, lng: 19.582888 },
    { lat: 49.1346337, lng: 19.5833489 },
    { lat: 49.1348742, lng: 19.5836094 },
    { lat: 49.1355301, lng: 19.5842126 },
    { lat: 49.1358476, lng: 19.5845572 },
    { lat: 49.1369301, lng: 19.5858066 },
  ],
  LiptovskéKľačany: [
    { lat: 48.9638757, lng: 19.4829006 },
    { lat: 48.9676897, lng: 19.482992 },
    { lat: 48.9687052, lng: 19.4838248 },
    { lat: 48.9718799, lng: 19.4857654 },
    { lat: 48.9722531, lng: 19.4847179 },
    { lat: 48.9745573, lng: 19.4820988 },
    { lat: 48.9756978, lng: 19.480755 },
    { lat: 48.9767179, lng: 19.4797351 },
    { lat: 48.9771724, lng: 19.4786188 },
    { lat: 48.9783657, lng: 19.4776632 },
    { lat: 48.9802247, lng: 19.4769954 },
    { lat: 48.9815977, lng: 19.4773002 },
    { lat: 48.9821313, lng: 19.4772639 },
    { lat: 48.9824428, lng: 19.475979 },
    { lat: 48.9830293, lng: 19.4743542 },
    { lat: 48.9836337, lng: 19.4730682 },
    { lat: 48.9843478, lng: 19.4721827 },
    { lat: 48.9846927, lng: 19.471695 },
    { lat: 48.9853476, lng: 19.4714315 },
    { lat: 48.9872275, lng: 19.4709962 },
    { lat: 48.9881185, lng: 19.4706719 },
    { lat: 48.9884664, lng: 19.4705915 },
    { lat: 48.9892612, lng: 19.4707701 },
    { lat: 48.9897286, lng: 19.4706938 },
    { lat: 48.9906695, lng: 19.4710101 },
    { lat: 48.9923402, lng: 19.4707545 },
    { lat: 48.9922887, lng: 19.4697628 },
    { lat: 48.9924307, lng: 19.4692726 },
    { lat: 48.9932869, lng: 19.4674628 },
    { lat: 48.9934668, lng: 19.4674056 },
    { lat: 48.9937892, lng: 19.4672384 },
    { lat: 48.9941645, lng: 19.467338 },
    { lat: 48.9945206, lng: 19.4675824 },
    { lat: 48.9956177, lng: 19.4676452 },
    { lat: 48.9961592, lng: 19.4678763 },
    { lat: 48.9968703, lng: 19.4678529 },
    { lat: 48.997304, lng: 19.4677011 },
    { lat: 48.9975219, lng: 19.4676129 },
    { lat: 48.9979735, lng: 19.4675856 },
    { lat: 48.9982387, lng: 19.4677644 },
    { lat: 48.9998614, lng: 19.4664443 },
    { lat: 49.0001605, lng: 19.4664343 },
    { lat: 49.000415, lng: 19.4663558 },
    { lat: 49.0010157, lng: 19.4651648 },
    { lat: 49.0015866, lng: 19.4645906 },
    { lat: 49.0026614, lng: 19.4637351 },
    { lat: 49.0033064, lng: 19.4638138 },
    { lat: 49.0041873, lng: 19.4632945 },
    { lat: 49.0043915, lng: 19.4629618 },
    { lat: 49.0057739, lng: 19.4606395 },
    { lat: 49.0061094, lng: 19.4602652 },
    { lat: 49.0065551, lng: 19.459742 },
    { lat: 49.0075941, lng: 19.4583524 },
    { lat: 49.0086443, lng: 19.457598 },
    { lat: 49.0107532, lng: 19.4574609 },
    { lat: 49.0119504, lng: 19.457838 },
    { lat: 49.0131744, lng: 19.4582784 },
    { lat: 49.0140268, lng: 19.459411 },
    { lat: 49.0145034, lng: 19.4606557 },
    { lat: 49.014742, lng: 19.461331 },
    { lat: 49.0147603, lng: 19.4614112 },
    { lat: 49.0148749, lng: 19.4613297 },
    { lat: 49.0149389, lng: 19.461469 },
    { lat: 49.0151303, lng: 19.4619314 },
    { lat: 49.0154238, lng: 19.4621101 },
    { lat: 49.0155422, lng: 19.4623325 },
    { lat: 49.015651, lng: 19.462616 },
    { lat: 49.0158674, lng: 19.4629092 },
    { lat: 49.0161384, lng: 19.4633839 },
    { lat: 49.0163212, lng: 19.4638537 },
    { lat: 49.0166351, lng: 19.4645013 },
    { lat: 49.0168219, lng: 19.4646192 },
    { lat: 49.0170651, lng: 19.464833 },
    { lat: 49.0171859, lng: 19.4651073 },
    { lat: 49.0171907, lng: 19.4654048 },
    { lat: 49.017314, lng: 19.4657065 },
    { lat: 49.0175191, lng: 19.465853 },
    { lat: 49.017645, lng: 19.4661142 },
    { lat: 49.0177865, lng: 19.4662484 },
    { lat: 49.0179684, lng: 19.4662154 },
    { lat: 49.0181366, lng: 19.4662933 },
    { lat: 49.018258, lng: 19.4664821 },
    { lat: 49.0183422, lng: 19.467254 },
    { lat: 49.0184981, lng: 19.467387 },
    { lat: 49.0186294, lng: 19.4676444 },
    { lat: 49.0187604, lng: 19.4679446 },
    { lat: 49.0190131, lng: 19.4683901 },
    { lat: 49.0192568, lng: 19.4687131 },
    { lat: 49.01931, lng: 19.4687911 },
    { lat: 49.0195023, lng: 19.4694635 },
    { lat: 49.0197736, lng: 19.4699242 },
    { lat: 49.0198111, lng: 19.4701989 },
    { lat: 49.0199674, lng: 19.4703875 },
    { lat: 49.020359, lng: 19.4707163 },
    { lat: 49.0204833, lng: 19.4710933 },
    { lat: 49.0207302, lng: 19.4714328 },
    { lat: 49.0210574, lng: 19.4719789 },
    { lat: 49.0212656, lng: 19.4722184 },
    { lat: 49.0213773, lng: 19.4724693 },
    { lat: 49.0213936, lng: 19.472674 },
    { lat: 49.0214224, lng: 19.4727869 },
    { lat: 49.021564, lng: 19.4730468 },
    { lat: 49.0218671, lng: 19.4733628 },
    { lat: 49.0221636, lng: 19.4739538 },
    { lat: 49.0223829, lng: 19.4742342 },
    { lat: 49.0226008, lng: 19.4742235 },
    { lat: 49.0227753, lng: 19.4743665 },
    { lat: 49.0228696, lng: 19.4744562 },
    { lat: 49.0230808, lng: 19.4746843 },
    { lat: 49.0237031, lng: 19.4753767 },
    { lat: 49.0259432, lng: 19.4759599 },
    { lat: 49.0263357, lng: 19.4760883 },
    { lat: 49.0271786, lng: 19.4763126 },
    { lat: 49.0274168, lng: 19.4764442 },
    { lat: 49.0276506, lng: 19.4766244 },
    { lat: 49.0277748, lng: 19.4766811 },
    { lat: 49.0280288, lng: 19.4767154 },
    { lat: 49.0282327, lng: 19.4764653 },
    { lat: 49.0282867, lng: 19.4765167 },
    { lat: 49.0283393, lng: 19.4767032 },
    { lat: 49.0285729, lng: 19.4765422 },
    { lat: 49.0285483, lng: 19.4764698 },
    { lat: 49.0292517, lng: 19.4762329 },
    { lat: 49.0292508, lng: 19.4765558 },
    { lat: 49.0297433, lng: 19.4766067 },
    { lat: 49.0304415, lng: 19.4767184 },
    { lat: 49.0304534, lng: 19.4762894 },
    { lat: 49.03048, lng: 19.476135 },
    { lat: 49.0308465, lng: 19.4761653 },
    { lat: 49.0311579, lng: 19.476247 },
    { lat: 49.0313183, lng: 19.4768164 },
    { lat: 49.0314765, lng: 19.4769758 },
    { lat: 49.0317097, lng: 19.4767269 },
    { lat: 49.0319261, lng: 19.4767552 },
    { lat: 49.0320591, lng: 19.4768013 },
    { lat: 49.0321641, lng: 19.4769536 },
    { lat: 49.0323709, lng: 19.4769692 },
    { lat: 49.0325376, lng: 19.4771587 },
    { lat: 49.0327945, lng: 19.4773069 },
    { lat: 49.0329774, lng: 19.4773858 },
    { lat: 49.0333273, lng: 19.4777443 },
    { lat: 49.0343684, lng: 19.4782511 },
    { lat: 49.034936, lng: 19.4780509 },
    { lat: 49.0354868, lng: 19.4779714 },
    { lat: 49.0354718, lng: 19.4778671 },
    { lat: 49.0355553, lng: 19.4776484 },
    { lat: 49.0355531, lng: 19.4775036 },
    { lat: 49.0353896, lng: 19.4769385 },
    { lat: 49.0356168, lng: 19.4769035 },
    { lat: 49.0358414, lng: 19.4769144 },
    { lat: 49.035928, lng: 19.4769856 },
    { lat: 49.0365929, lng: 19.4768343 },
    { lat: 49.0367109, lng: 19.476732 },
    { lat: 49.036791, lng: 19.476834 },
    { lat: 49.0372521, lng: 19.4765202 },
    { lat: 49.0373762, lng: 19.4765709 },
    { lat: 49.0375062, lng: 19.4764601 },
    { lat: 49.0376435, lng: 19.4763539 },
    { lat: 49.0380776, lng: 19.4765105 },
    { lat: 49.0382242, lng: 19.4764367 },
    { lat: 49.0381083, lng: 19.4759254 },
    { lat: 49.0386409, lng: 19.4759904 },
    { lat: 49.0388705, lng: 19.4761556 },
    { lat: 49.039091, lng: 19.4759033 },
    { lat: 49.0392658, lng: 19.4758603 },
    { lat: 49.0392883, lng: 19.4755881 },
    { lat: 49.0392048, lng: 19.4753453 },
    { lat: 49.0393052, lng: 19.4751981 },
    { lat: 49.0396073, lng: 19.4750563 },
    { lat: 49.0396093, lng: 19.4750737 },
    { lat: 49.0396815, lng: 19.4750517 },
    { lat: 49.0396798, lng: 19.475035 },
    { lat: 49.0401242, lng: 19.4753184 },
    { lat: 49.040166, lng: 19.4754141 },
    { lat: 49.0402203, lng: 19.4753484 },
    { lat: 49.0405167, lng: 19.4752974 },
    { lat: 49.0405872, lng: 19.4757772 },
    { lat: 49.040746, lng: 19.4757029 },
    { lat: 49.0408376, lng: 19.4756116 },
    { lat: 49.0409063, lng: 19.4756114 },
    { lat: 49.0409745, lng: 19.4758197 },
    { lat: 49.0411058, lng: 19.4758103 },
    { lat: 49.0413553, lng: 19.4762837 },
    { lat: 49.0415616, lng: 19.4763091 },
    { lat: 49.0416706, lng: 19.476379 },
    { lat: 49.0418271, lng: 19.4762485 },
    { lat: 49.0420559, lng: 19.4759572 },
    { lat: 49.0421816, lng: 19.4756752 },
    { lat: 49.0423001, lng: 19.4757581 },
    { lat: 49.0424396, lng: 19.475921 },
    { lat: 49.0427946, lng: 19.4760718 },
    { lat: 49.0428914, lng: 19.4763635 },
    { lat: 49.0429954, lng: 19.476416 },
    { lat: 49.0430836, lng: 19.4764246 },
    { lat: 49.0432834, lng: 19.4760656 },
    { lat: 49.04333, lng: 19.4761702 },
    { lat: 49.043963, lng: 19.4764364 },
    { lat: 49.0439523, lng: 19.4767152 },
    { lat: 49.0453685, lng: 19.4775396 },
    { lat: 49.046203, lng: 19.4784112 },
    { lat: 49.0462265, lng: 19.4784087 },
    { lat: 49.0462256, lng: 19.478436 },
    { lat: 49.0463285, lng: 19.4785418 },
    { lat: 49.0463396, lng: 19.4783679 },
    { lat: 49.0464974, lng: 19.4783942 },
    { lat: 49.0465049, lng: 19.4783068 },
    { lat: 49.0465782, lng: 19.4774566 },
    { lat: 49.046569, lng: 19.4774019 },
    { lat: 49.0466188, lng: 19.4767238 },
    { lat: 49.0466393, lng: 19.476727 },
    { lat: 49.0466475, lng: 19.4766137 },
    { lat: 49.0466486, lng: 19.4766111 },
    { lat: 49.0466665, lng: 19.4762941 },
    { lat: 49.0466676, lng: 19.4762915 },
    { lat: 49.0466772, lng: 19.4761824 },
    { lat: 49.0466769, lng: 19.4761752 },
    { lat: 49.0467064, lng: 19.4758376 },
    { lat: 49.0467778, lng: 19.4752797 },
    { lat: 49.046867, lng: 19.4744278 },
    { lat: 49.0468591, lng: 19.4744053 },
    { lat: 49.0468714, lng: 19.4742571 },
    { lat: 49.0469768, lng: 19.4730949 },
    { lat: 49.0470093, lng: 19.4728235 },
    { lat: 49.0470092, lng: 19.4728206 },
    { lat: 49.0470583, lng: 19.472343 },
    { lat: 49.0471277, lng: 19.4718011 },
    { lat: 49.0472227, lng: 19.4711458 },
    { lat: 49.0473635, lng: 19.4702665 },
    { lat: 49.0474609, lng: 19.4696393 },
    { lat: 49.0476084, lng: 19.4690754 },
    { lat: 49.0477042, lng: 19.4685856 },
    { lat: 49.0477297, lng: 19.4684079 },
    { lat: 49.0477674, lng: 19.468077 },
    { lat: 49.0478368, lng: 19.4674064 },
    { lat: 49.0478623, lng: 19.4670793 },
    { lat: 49.0478746, lng: 19.4666663 },
    { lat: 49.0478989, lng: 19.4662147 },
    { lat: 49.0479438, lng: 19.4656722 },
    { lat: 49.0480333, lng: 19.4644868 },
    { lat: 49.0481943, lng: 19.463555 },
    { lat: 49.0482072, lng: 19.4632512 },
    { lat: 49.0481494, lng: 19.4619742 },
    { lat: 49.0482478, lng: 19.462079 },
    { lat: 49.048611, lng: 19.462209 },
    { lat: 49.0486326, lng: 19.4619908 },
    { lat: 49.0486682, lng: 19.4610343 },
    { lat: 49.0486832, lng: 19.460842 },
    { lat: 49.0487421, lng: 19.4599692 },
    { lat: 49.0487984, lng: 19.4594096 },
    { lat: 49.0488845, lng: 19.4585199 },
    { lat: 49.0488842, lng: 19.4585135 },
    { lat: 49.0489466, lng: 19.4579115 },
    { lat: 49.0490412, lng: 19.4571524 },
    { lat: 49.0428299, lng: 19.455181 },
    { lat: 49.0422074, lng: 19.4550217 },
    { lat: 49.04179, lng: 19.4546702 },
    { lat: 49.0414816, lng: 19.4540484 },
    { lat: 49.0407549, lng: 19.4520034 },
    { lat: 49.0406619, lng: 19.4526232 },
    { lat: 49.0405192, lng: 19.4529269 },
    { lat: 49.0405347, lng: 19.4533081 },
    { lat: 49.0403637, lng: 19.4536084 },
    { lat: 49.0402837, lng: 19.4540218 },
    { lat: 49.040235, lng: 19.4544555 },
    { lat: 49.0402941, lng: 19.454757 },
    { lat: 49.0403288, lng: 19.4550427 },
    { lat: 49.0401831, lng: 19.4553805 },
    { lat: 49.0399125, lng: 19.4561318 },
    { lat: 49.0398922, lng: 19.4564227 },
    { lat: 49.0397257, lng: 19.4567437 },
    { lat: 49.0398415, lng: 19.4572758 },
    { lat: 49.039924, lng: 19.4576965 },
    { lat: 49.0397472, lng: 19.4581648 },
    { lat: 49.0395903, lng: 19.4583769 },
    { lat: 49.0394935, lng: 19.4589124 },
    { lat: 49.0392297, lng: 19.4590807 },
    { lat: 49.0390031, lng: 19.4593233 },
    { lat: 49.0388755, lng: 19.4595614 },
    { lat: 49.0387493, lng: 19.4598302 },
    { lat: 49.0387295, lng: 19.4601836 },
    { lat: 49.0385655, lng: 19.4603121 },
    { lat: 49.0385694, lng: 19.4605466 },
    { lat: 49.0383801, lng: 19.4605923 },
    { lat: 49.0382367, lng: 19.4609295 },
    { lat: 49.0381156, lng: 19.4608936 },
    { lat: 49.0378479, lng: 19.4617346 },
    { lat: 49.0375599, lng: 19.4618882 },
    { lat: 49.0373862, lng: 19.4618658 },
    { lat: 49.037195, lng: 19.4621168 },
    { lat: 49.0370325, lng: 19.4620602 },
    { lat: 49.0368193, lng: 19.462204 },
    { lat: 49.0366764, lng: 19.4621363 },
    { lat: 49.0363817, lng: 19.461902 },
    { lat: 49.0360157, lng: 19.4615648 },
    { lat: 49.0357493, lng: 19.4611389 },
    { lat: 49.0354763, lng: 19.4610119 },
    { lat: 49.0351976, lng: 19.4606875 },
    { lat: 49.0350249, lng: 19.46039 },
    { lat: 49.0348178, lng: 19.4599804 },
    { lat: 49.0346974, lng: 19.4596895 },
    { lat: 49.0345365, lng: 19.4592595 },
    { lat: 49.0343709, lng: 19.458991 },
    { lat: 49.0342597, lng: 19.4586082 },
    { lat: 49.0338611, lng: 19.4581743 },
    { lat: 49.0336837, lng: 19.457749 },
    { lat: 49.0336269, lng: 19.4570372 },
    { lat: 49.0335966, lng: 19.4569388 },
    { lat: 49.0333415, lng: 19.4566594 },
    { lat: 49.0331556, lng: 19.4563672 },
    { lat: 49.0329802, lng: 19.4561844 },
    { lat: 49.0328175, lng: 19.4560769 },
    { lat: 49.0327628, lng: 19.4557229 },
    { lat: 49.0324998, lng: 19.4553721 },
    { lat: 49.032418, lng: 19.4553335 },
    { lat: 49.0322733, lng: 19.4552021 },
    { lat: 49.0322177, lng: 19.4550981 },
    { lat: 49.0322203, lng: 19.4550045 },
    { lat: 49.032154, lng: 19.4549599 },
    { lat: 49.0321072, lng: 19.454827 },
    { lat: 49.0319824, lng: 19.454636 },
    { lat: 49.0318712, lng: 19.4545491 },
    { lat: 49.0318163, lng: 19.454434 },
    { lat: 49.0316623, lng: 19.4542253 },
    { lat: 49.0315183, lng: 19.4538877 },
    { lat: 49.0314395, lng: 19.4536444 },
    { lat: 49.0311358, lng: 19.4530474 },
    { lat: 49.0307735, lng: 19.4521589 },
    { lat: 49.0306252, lng: 19.4520207 },
    { lat: 49.0295385, lng: 19.450357 },
    { lat: 49.0293075, lng: 19.4500646 },
    { lat: 49.028885, lng: 19.4496298 },
    { lat: 49.0285475, lng: 19.4493477 },
    { lat: 49.0284154, lng: 19.4492699 },
    { lat: 49.0280517, lng: 19.4490855 },
    { lat: 49.0277499, lng: 19.4490144 },
    { lat: 49.0274066, lng: 19.4489481 },
    { lat: 49.0270049, lng: 19.4489172 },
    { lat: 49.0268034, lng: 19.4489463 },
    { lat: 49.0264403, lng: 19.4491116 },
    { lat: 49.0262801, lng: 19.4492508 },
    { lat: 49.0262478, lng: 19.4493092 },
    { lat: 49.0251346, lng: 19.4496288 },
    { lat: 49.0249652, lng: 19.4496681 },
    { lat: 49.0241665, lng: 19.4497217 },
    { lat: 49.0239286, lng: 19.4497698 },
    { lat: 49.0238373, lng: 19.4498395 },
    { lat: 49.0235251, lng: 19.4498625 },
    { lat: 49.0232843, lng: 19.4498488 },
    { lat: 49.0230386, lng: 19.4498216 },
    { lat: 49.0227103, lng: 19.4497386 },
    { lat: 49.0223505, lng: 19.4494892 },
    { lat: 49.0218574, lng: 19.4492594 },
    { lat: 49.0199865, lng: 19.4482053 },
    { lat: 49.0198483, lng: 19.448214 },
    { lat: 49.019088, lng: 19.4479798 },
    { lat: 49.0186119, lng: 19.4479899 },
    { lat: 49.0180637, lng: 19.4478095 },
    { lat: 49.0174419, lng: 19.4477163 },
    { lat: 49.0170527, lng: 19.4476338 },
    { lat: 49.0159454, lng: 19.4466951 },
    { lat: 49.0152574, lng: 19.4454703 },
    { lat: 49.0140372, lng: 19.4418276 },
    { lat: 49.0135945, lng: 19.4413452 },
    { lat: 49.0123579, lng: 19.4404145 },
    { lat: 49.0117058, lng: 19.4416119 },
    { lat: 49.0117879, lng: 19.4418767 },
    { lat: 49.0113333, lng: 19.4429794 },
    { lat: 49.010509, lng: 19.4423404 },
    { lat: 49.0095568, lng: 19.4415637 },
    { lat: 49.0093844, lng: 19.4413913 },
    { lat: 49.0091155, lng: 19.4407201 },
    { lat: 49.0088371, lng: 19.4403551 },
    { lat: 49.0084209, lng: 19.4395935 },
    { lat: 49.0083498, lng: 19.4393739 },
    { lat: 49.0075842, lng: 19.4402123 },
    { lat: 49.0061058, lng: 19.4416756 },
    { lat: 49.0053759, lng: 19.4426264 },
    { lat: 49.0051224, lng: 19.4429443 },
    { lat: 49.0044408, lng: 19.4433598 },
    { lat: 49.0037913, lng: 19.4438622 },
    { lat: 49.0033899, lng: 19.4445136 },
    { lat: 49.0029961, lng: 19.4448921 },
    { lat: 49.0016944, lng: 19.4448908 },
    { lat: 49.0014365, lng: 19.4448696 },
    { lat: 49.0008534, lng: 19.4447361 },
    { lat: 49.0001332, lng: 19.4440542 },
    { lat: 48.9999455, lng: 19.4438199 },
    { lat: 48.9986404, lng: 19.44593 },
    { lat: 48.9975639, lng: 19.4474083 },
    { lat: 48.9970411, lng: 19.4482584 },
    { lat: 48.9963212, lng: 19.4504 },
    { lat: 48.9931856, lng: 19.4514116 },
    { lat: 48.991136, lng: 19.4522699 },
    { lat: 48.9905643, lng: 19.4526005 },
    { lat: 48.9885233, lng: 19.4534215 },
    { lat: 48.9873932, lng: 19.4548857 },
    { lat: 48.9870895, lng: 19.4545594 },
    { lat: 48.986431, lng: 19.45506 },
    { lat: 48.9857132, lng: 19.455888 },
    { lat: 48.9852163, lng: 19.4564007 },
    { lat: 48.9848856, lng: 19.45668 },
    { lat: 48.9847831, lng: 19.4567566 },
    { lat: 48.9837154, lng: 19.4571824 },
    { lat: 48.982727, lng: 19.4563262 },
    { lat: 48.9824891, lng: 19.4563236 },
    { lat: 48.9817035, lng: 19.4565141 },
    { lat: 48.9813002, lng: 19.4564941 },
    { lat: 48.9798481, lng: 19.4563593 },
    { lat: 48.9787812, lng: 19.4563901 },
    { lat: 48.978022, lng: 19.4567618 },
    { lat: 48.9776129, lng: 19.4574171 },
    { lat: 48.9765876, lng: 19.4586627 },
    { lat: 48.9752293, lng: 19.4595298 },
    { lat: 48.9740693, lng: 19.4595931 },
    { lat: 48.9729854, lng: 19.4616899 },
    { lat: 48.9725984, lng: 19.4621207 },
    { lat: 48.9717206, lng: 19.462996 },
    { lat: 48.9693271, lng: 19.4646307 },
    { lat: 48.9675721, lng: 19.4654276 },
    { lat: 48.9666918, lng: 19.4665921 },
    { lat: 48.9661532, lng: 19.4670788 },
    { lat: 48.9657419, lng: 19.4680558 },
    { lat: 48.9655417, lng: 19.4694056 },
    { lat: 48.965464, lng: 19.4700382 },
    { lat: 48.96483, lng: 19.4727855 },
    { lat: 48.9644099, lng: 19.4736454 },
    { lat: 48.9639166, lng: 19.4745048 },
    { lat: 48.9637778, lng: 19.4757432 },
    { lat: 48.9637451, lng: 19.4775786 },
    { lat: 48.9637577, lng: 19.4787775 },
    { lat: 48.9638107, lng: 19.4821057 },
    { lat: 48.9638757, lng: 19.4829006 },
  ],
  LiptovskéMatiašovce: [
    { lat: 49.1428338, lng: 19.5507075 },
    { lat: 49.1428657, lng: 19.5507603 },
    { lat: 49.1424642, lng: 19.5517994 },
    { lat: 49.1441845, lng: 19.5516978 },
    { lat: 49.1463033, lng: 19.5521046 },
    { lat: 49.1482125, lng: 19.5535866 },
    { lat: 49.1505009, lng: 19.5544313 },
    { lat: 49.1511952, lng: 19.5548106 },
    { lat: 49.1514554, lng: 19.5550104 },
    { lat: 49.1517967, lng: 19.5555036 },
    { lat: 49.1518703, lng: 19.5555372 },
    { lat: 49.1519082, lng: 19.5555761 },
    { lat: 49.1520756, lng: 19.5554159 },
    { lat: 49.152084, lng: 19.5566965 },
    { lat: 49.1520984, lng: 19.5591599 },
    { lat: 49.1506276, lng: 19.5632851 },
    { lat: 49.1512214, lng: 19.5649061 },
    { lat: 49.1513228, lng: 19.5651791 },
    { lat: 49.1516923, lng: 19.5661507 },
    { lat: 49.1520056, lng: 19.5671231 },
    { lat: 49.1518818, lng: 19.5683973 },
    { lat: 49.1520359, lng: 19.5688181 },
    { lat: 49.1523355, lng: 19.5697866 },
    { lat: 49.1530469, lng: 19.5709751 },
    { lat: 49.1536932, lng: 19.5715876 },
    { lat: 49.1541407, lng: 19.5720413 },
    { lat: 49.1546088, lng: 19.5723266 },
    { lat: 49.1549473, lng: 19.5724607 },
    { lat: 49.1555408, lng: 19.5726331 },
    { lat: 49.1556775, lng: 19.5727167 },
    { lat: 49.1559691, lng: 19.5729834 },
    { lat: 49.1563577, lng: 19.5732125 },
    { lat: 49.1568452, lng: 19.5734999 },
    { lat: 49.1570904, lng: 19.5736384 },
    { lat: 49.1575332, lng: 19.5737881 },
    { lat: 49.1582549, lng: 19.5740285 },
    { lat: 49.1582982, lng: 19.5740139 },
    { lat: 49.1587262, lng: 19.574086 },
    { lat: 49.1590571, lng: 19.5743036 },
    { lat: 49.1598175, lng: 19.5748262 },
    { lat: 49.1599808, lng: 19.5750506 },
    { lat: 49.1605184, lng: 19.5753535 },
    { lat: 49.16094, lng: 19.5756287 },
    { lat: 49.1614874, lng: 19.5764745 },
    { lat: 49.1617894, lng: 19.5769005 },
    { lat: 49.1622554, lng: 19.5776174 },
    { lat: 49.1629866, lng: 19.5783915 },
    { lat: 49.1630975, lng: 19.57858 },
    { lat: 49.1631435, lng: 19.5788719 },
    { lat: 49.1632222, lng: 19.5790674 },
    { lat: 49.1631874, lng: 19.5791502 },
    { lat: 49.1633344, lng: 19.5800587 },
    { lat: 49.1632374, lng: 19.5803576 },
    { lat: 49.1631992, lng: 19.5808109 },
    { lat: 49.163274, lng: 19.5809928 },
    { lat: 49.1632098, lng: 19.5813437 },
    { lat: 49.1633706, lng: 19.5814122 },
    { lat: 49.1640281, lng: 19.5824569 },
    { lat: 49.164442, lng: 19.5831124 },
    { lat: 49.1656458, lng: 19.5844002 },
    { lat: 49.1659577, lng: 19.5847744 },
    { lat: 49.1659895, lng: 19.5848028 },
    { lat: 49.165993, lng: 19.5848297 },
    { lat: 49.1660437, lng: 19.5849 },
    { lat: 49.1662836, lng: 19.5852325 },
    { lat: 49.1666668, lng: 19.5857839 },
    { lat: 49.1669119, lng: 19.5862249 },
    { lat: 49.1671166, lng: 19.5867126 },
    { lat: 49.1673063, lng: 19.586952 },
    { lat: 49.1675792, lng: 19.587435 },
    { lat: 49.1679679, lng: 19.5881347 },
    { lat: 49.1681604, lng: 19.5891349 },
    { lat: 49.1681751, lng: 19.5891568 },
    { lat: 49.1677636, lng: 19.5900279 },
    { lat: 49.1678727, lng: 19.5902221 },
    { lat: 49.1681541, lng: 19.5912988 },
    { lat: 49.168332, lng: 19.5919956 },
    { lat: 49.1686139, lng: 19.5924533 },
    { lat: 49.1691228, lng: 19.5932747 },
    { lat: 49.1692697, lng: 19.5936075 },
    { lat: 49.1697977, lng: 19.5937767 },
    { lat: 49.1701374, lng: 19.5936339 },
    { lat: 49.1715786, lng: 19.5932636 },
    { lat: 49.1717314, lng: 19.5933319 },
    { lat: 49.1724209, lng: 19.5929776 },
    { lat: 49.1728385, lng: 19.5930711 },
    { lat: 49.1730665, lng: 19.5930278 },
    { lat: 49.1735863, lng: 19.5932158 },
    { lat: 49.1736541, lng: 19.5931436 },
    { lat: 49.173617, lng: 19.5926703 },
    { lat: 49.1736614, lng: 19.5925019 },
    { lat: 49.1735995, lng: 19.5924068 },
    { lat: 49.1737819, lng: 19.5919509 },
    { lat: 49.1740317, lng: 19.5910177 },
    { lat: 49.1745824, lng: 19.5905367 },
    { lat: 49.1746703, lng: 19.5901605 },
    { lat: 49.1747912, lng: 19.5898407 },
    { lat: 49.1749047, lng: 19.5898594 },
    { lat: 49.1749945, lng: 19.5899764 },
    { lat: 49.1751678, lng: 19.5902975 },
    { lat: 49.1754759, lng: 19.5906121 },
    { lat: 49.1756523, lng: 19.5907251 },
    { lat: 49.1759565, lng: 19.5910008 },
    { lat: 49.176362, lng: 19.5912238 },
    { lat: 49.1768947, lng: 19.5916979 },
    { lat: 49.1775208, lng: 19.5922641 },
    { lat: 49.1781467, lng: 19.585838 },
    { lat: 49.1784654, lng: 19.5825527 },
    { lat: 49.1788553, lng: 19.5827535 },
    { lat: 49.1788844, lng: 19.5827504 },
    { lat: 49.1788663, lng: 19.5826526 },
    { lat: 49.1787044, lng: 19.5824607 },
    { lat: 49.1786117, lng: 19.5823789 },
    { lat: 49.1785053, lng: 19.5823162 },
    { lat: 49.177968, lng: 19.5820441 },
    { lat: 49.1775029, lng: 19.5816203 },
    { lat: 49.1769578, lng: 19.5815539 },
    { lat: 49.1759128, lng: 19.5817404 },
    { lat: 49.1755852, lng: 19.5816699 },
    { lat: 49.1751621, lng: 19.58168 },
    { lat: 49.1747237, lng: 19.5813032 },
    { lat: 49.1744127, lng: 19.581003 },
    { lat: 49.1739706, lng: 19.5806684 },
    { lat: 49.1737354, lng: 19.5804748 },
    { lat: 49.1728075, lng: 19.5796811 },
    { lat: 49.1722562, lng: 19.5788275 },
    { lat: 49.1721717, lng: 19.578675 },
    { lat: 49.171868, lng: 19.5779147 },
    { lat: 49.1718379, lng: 19.577247 },
    { lat: 49.1718826, lng: 19.576085 },
    { lat: 49.1719246, lng: 19.5760939 },
    { lat: 49.1719302, lng: 19.5760148 },
    { lat: 49.1720561, lng: 19.5749109 },
    { lat: 49.1720023, lng: 19.5743923 },
    { lat: 49.1719304, lng: 19.5738453 },
    { lat: 49.1718128, lng: 19.5728411 },
    { lat: 49.1717632, lng: 19.5713382 },
    { lat: 49.1702108, lng: 19.5689198 },
    { lat: 49.1696293, lng: 19.5664001 },
    { lat: 49.1696179, lng: 19.5663455 },
    { lat: 49.1693603, lng: 19.5662815 },
    { lat: 49.1687607, lng: 19.5651033 },
    { lat: 49.1685183, lng: 19.5643003 },
    { lat: 49.1683893, lng: 19.5640618 },
    { lat: 49.1681691, lng: 19.5637767 },
    { lat: 49.1681043, lng: 19.5633198 },
    { lat: 49.1677832, lng: 19.562323 },
    { lat: 49.1676078, lng: 19.5615547 },
    { lat: 49.1675123, lng: 19.5612854 },
    { lat: 49.1673382, lng: 19.5610279 },
    { lat: 49.1667371, lng: 19.5603918 },
    { lat: 49.1664682, lng: 19.5601252 },
    { lat: 49.1660712, lng: 19.55984 },
    { lat: 49.1655981, lng: 19.559147 },
    { lat: 49.1650824, lng: 19.5587534 },
    { lat: 49.1644565, lng: 19.5578716 },
    { lat: 49.164444, lng: 19.5578196 },
    { lat: 49.1639189, lng: 19.5572452 },
    { lat: 49.163797, lng: 19.5570888 },
    { lat: 49.1632807, lng: 19.5561397 },
    { lat: 49.1626682, lng: 19.5559712 },
    { lat: 49.1625651, lng: 19.5557585 },
    { lat: 49.1624328, lng: 19.5553044 },
    { lat: 49.1622735, lng: 19.5550961 },
    { lat: 49.161929, lng: 19.5549239 },
    { lat: 49.1618883, lng: 19.5547763 },
    { lat: 49.1616615, lng: 19.5542205 },
    { lat: 49.1615821, lng: 19.5539578 },
    { lat: 49.161591, lng: 19.5536587 },
    { lat: 49.1615304, lng: 19.5534631 },
    { lat: 49.1613413, lng: 19.5533437 },
    { lat: 49.1611688, lng: 19.5533396 },
    { lat: 49.1609463, lng: 19.5529054 },
    { lat: 49.1607525, lng: 19.5523102 },
    { lat: 49.1604811, lng: 19.5513642 },
    { lat: 49.1603316, lng: 19.5506553 },
    { lat: 49.15948, lng: 19.5510308 },
    { lat: 49.1594598, lng: 19.5510585 },
    { lat: 49.1591435, lng: 19.5499938 },
    { lat: 49.1587474, lng: 19.5493843 },
    { lat: 49.1582759, lng: 19.5488735 },
    { lat: 49.158232, lng: 19.5486765 },
    { lat: 49.1582931, lng: 19.5483118 },
    { lat: 49.1581483, lng: 19.548024 },
    { lat: 49.1581041, lng: 19.5474966 },
    { lat: 49.1580547, lng: 19.5470259 },
    { lat: 49.1575255, lng: 19.5461179 },
    { lat: 49.1574496, lng: 19.5457613 },
    { lat: 49.1575324, lng: 19.5455264 },
    { lat: 49.1576634, lng: 19.5448848 },
    { lat: 49.1575933, lng: 19.5445581 },
    { lat: 49.1572936, lng: 19.5442578 },
    { lat: 49.1571669, lng: 19.5440206 },
    { lat: 49.1569914, lng: 19.5439308 },
    { lat: 49.156685, lng: 19.5441038 },
    { lat: 49.1565704, lng: 19.5435932 },
    { lat: 49.1564565, lng: 19.5434904 },
    { lat: 49.1560019, lng: 19.543551 },
    { lat: 49.1558661, lng: 19.543364 },
    { lat: 49.1560174, lng: 19.5430241 },
    { lat: 49.1559958, lng: 19.5429432 },
    { lat: 49.1558581, lng: 19.5429433 },
    { lat: 49.155547, lng: 19.543163 },
    { lat: 49.1553852, lng: 19.5431196 },
    { lat: 49.1549962, lng: 19.5428665 },
    { lat: 49.1548561, lng: 19.5424123 },
    { lat: 49.1548513, lng: 19.5419577 },
    { lat: 49.1547672, lng: 19.5416927 },
    { lat: 49.1545052, lng: 19.541631 },
    { lat: 49.1543462, lng: 19.5413796 },
    { lat: 49.1544094, lng: 19.5410861 },
    { lat: 49.1547234, lng: 19.5408298 },
    { lat: 49.1548661, lng: 19.5406973 },
    { lat: 49.1547974, lng: 19.5401261 },
    { lat: 49.1547107, lng: 19.5398545 },
    { lat: 49.1545105, lng: 19.5395894 },
    { lat: 49.1542959, lng: 19.5394555 },
    { lat: 49.1540146, lng: 19.5392397 },
    { lat: 49.153901, lng: 19.5390663 },
    { lat: 49.1539518, lng: 19.5386257 },
    { lat: 49.153963, lng: 19.5383002 },
    { lat: 49.1537601, lng: 19.538246 },
    { lat: 49.1534604, lng: 19.5383742 },
    { lat: 49.1533285, lng: 19.5382718 },
    { lat: 49.1533283, lng: 19.5378204 },
    { lat: 49.1532646, lng: 19.5374373 },
    { lat: 49.1525943, lng: 19.5370902 },
    { lat: 49.1521156, lng: 19.537316 },
    { lat: 49.1519828, lng: 19.5373963 },
    { lat: 49.1516984, lng: 19.5375347 },
    { lat: 49.1506743, lng: 19.5378861 },
    { lat: 49.1502083, lng: 19.5380689 },
    { lat: 49.1499804, lng: 19.5381617 },
    { lat: 49.1497755, lng: 19.5382657 },
    { lat: 49.1495219, lng: 19.5384584 },
    { lat: 49.1494006, lng: 19.5385414 },
    { lat: 49.1491939, lng: 19.5387529 },
    { lat: 49.1490468, lng: 19.538888 },
    { lat: 49.1487842, lng: 19.5390625 },
    { lat: 49.1483455, lng: 19.5395267 },
    { lat: 49.1473858, lng: 19.5405735 },
    { lat: 49.1471425, lng: 19.5408245 },
    { lat: 49.1461218, lng: 19.541968 },
    { lat: 49.146005, lng: 19.5421261 },
    { lat: 49.1459175, lng: 19.5422329 },
    { lat: 49.1458534, lng: 19.5424059 },
    { lat: 49.1456181, lng: 19.5429091 },
    { lat: 49.1454022, lng: 19.5434152 },
    { lat: 49.1452458, lng: 19.5437146 },
    { lat: 49.1451245, lng: 19.5438994 },
    { lat: 49.1447709, lng: 19.5442761 },
    { lat: 49.14433, lng: 19.5446645 },
    { lat: 49.1439035, lng: 19.5449279 },
    { lat: 49.143867, lng: 19.5449911 },
    { lat: 49.1438532, lng: 19.5450351 },
    { lat: 49.1438413, lng: 19.5451212 },
    { lat: 49.1439293, lng: 19.5456673 },
    { lat: 49.1439917, lng: 19.5463506 },
    { lat: 49.1439998, lng: 19.5465283 },
    { lat: 49.1439783, lng: 19.5468725 },
    { lat: 49.1439496, lng: 19.5472343 },
    { lat: 49.1438666, lng: 19.5476897 },
    { lat: 49.1437514, lng: 19.5481535 },
    { lat: 49.1436037, lng: 19.5486038 },
    { lat: 49.1434654, lng: 19.5490783 },
    { lat: 49.143357, lng: 19.549374 },
    { lat: 49.1432148, lng: 19.5496913 },
    { lat: 49.1430216, lng: 19.5502983 },
    { lat: 49.1428338, lng: 19.5507075 },
  ],
  LiptovskýJán: [
    { lat: 49.0350691, lng: 19.7030605 },
    { lat: 49.0353117, lng: 19.7034278 },
    { lat: 49.0354381, lng: 19.7032533 },
    { lat: 49.0355574, lng: 19.7031212 },
    { lat: 49.0356408, lng: 19.7030481 },
    { lat: 49.0358195, lng: 19.7029336 },
    { lat: 49.0362108, lng: 19.7027397 },
    { lat: 49.0365591, lng: 19.7023544 },
    { lat: 49.0370602, lng: 19.7018921 },
    { lat: 49.0372526, lng: 19.7018111 },
    { lat: 49.037657, lng: 19.7013526 },
    { lat: 49.0378839, lng: 19.7013574 },
    { lat: 49.038126, lng: 19.7012052 },
    { lat: 49.0385092, lng: 19.7011044 },
    { lat: 49.0386486, lng: 19.7011483 },
    { lat: 49.0387087, lng: 19.7010826 },
    { lat: 49.0389629, lng: 19.7010972 },
    { lat: 49.0389894, lng: 19.7011088 },
    { lat: 49.0390103, lng: 19.701097 },
    { lat: 49.039346, lng: 19.7009938 },
    { lat: 49.0395237, lng: 19.7009653 },
    { lat: 49.0399765, lng: 19.7006016 },
    { lat: 49.0402348, lng: 19.7002538 },
    { lat: 49.0404446, lng: 19.6998999 },
    { lat: 49.0407676, lng: 19.6995362 },
    { lat: 49.0410047, lng: 19.6991633 },
    { lat: 49.0411445, lng: 19.6988872 },
    { lat: 49.0414207, lng: 19.6983038 },
    { lat: 49.0415781, lng: 19.6978101 },
    { lat: 49.0416279, lng: 19.6977124 },
    { lat: 49.0417516, lng: 19.6974028 },
    { lat: 49.0417559, lng: 19.6973179 },
    { lat: 49.0418138, lng: 19.6970998 },
    { lat: 49.0419212, lng: 19.6967792 },
    { lat: 49.0422572, lng: 19.696198 },
    { lat: 49.0428136, lng: 19.6954531 },
    { lat: 49.0430952, lng: 19.6951733 },
    { lat: 49.0433282, lng: 19.6949663 },
    { lat: 49.0437352, lng: 19.6947142 },
    { lat: 49.0443716, lng: 19.694354 },
    { lat: 49.0449979, lng: 19.6940709 },
    { lat: 49.0451757, lng: 19.6940653 },
    { lat: 49.0453437, lng: 19.6940208 },
    { lat: 49.0456874, lng: 19.6938654 },
    { lat: 49.0457673, lng: 19.6937875 },
    { lat: 49.0458693, lng: 19.6936212 },
    { lat: 49.0462032, lng: 19.692918 },
    { lat: 49.0464313, lng: 19.6923696 },
    { lat: 49.0465741, lng: 19.6923155 },
    { lat: 49.046744, lng: 19.6923333 },
    { lat: 49.0470328, lng: 19.692265 },
    { lat: 49.0472863, lng: 19.6921909 },
    { lat: 49.0476017, lng: 19.6920614 },
    { lat: 49.0477982, lng: 19.6919676 },
    { lat: 49.0481036, lng: 19.6917672 },
    { lat: 49.0483231, lng: 19.6915846 },
    { lat: 49.0486596, lng: 19.6912453 },
    { lat: 49.0491897, lng: 19.6903359 },
    { lat: 49.0495239, lng: 19.6901548 },
    { lat: 49.0497685, lng: 19.6899254 },
    { lat: 49.0499138, lng: 19.68982 },
    { lat: 49.0500419, lng: 19.6897376 },
    { lat: 49.0505903, lng: 19.6895037 },
    { lat: 49.0507299, lng: 19.6894987 },
    { lat: 49.0508165, lng: 19.6894227 },
    { lat: 49.0509061, lng: 19.6893833 },
    { lat: 49.0517631, lng: 19.6894257 },
    { lat: 49.0520022, lng: 19.6895108 },
    { lat: 49.0524113, lng: 19.6894628 },
    { lat: 49.0527834, lng: 19.6895702 },
    { lat: 49.0532076, lng: 19.689558 },
    { lat: 49.0533903, lng: 19.6896906 },
    { lat: 49.0534653, lng: 19.6897809 },
    { lat: 49.0534962, lng: 19.6897893 },
    { lat: 49.0537273, lng: 19.6898967 },
    { lat: 49.0537199, lng: 19.690006 },
    { lat: 49.0537381, lng: 19.689965 },
    { lat: 49.0537352, lng: 19.6898995 },
    { lat: 49.0537636, lng: 19.6896488 },
    { lat: 49.0538119, lng: 19.6890817 },
    { lat: 49.0538921, lng: 19.68909 },
    { lat: 49.0538961, lng: 19.6890748 },
    { lat: 49.0540176, lng: 19.6890994 },
    { lat: 49.0540156, lng: 19.6891273 },
    { lat: 49.0543741, lng: 19.6892102 },
    { lat: 49.0545213, lng: 19.6892501 },
    { lat: 49.0548167, lng: 19.6891024 },
    { lat: 49.0551756, lng: 19.688917 },
    { lat: 49.0551714, lng: 19.6887698 },
    { lat: 49.0552436, lng: 19.6887196 },
    { lat: 49.0551877, lng: 19.6885296 },
    { lat: 49.0551389, lng: 19.6879332 },
    { lat: 49.0549942, lng: 19.6875899 },
    { lat: 49.0550838, lng: 19.687552 },
    { lat: 49.0555299, lng: 19.6873468 },
    { lat: 49.055616, lng: 19.6868204 },
    { lat: 49.0556305, lng: 19.6866954 },
    { lat: 49.0558098, lng: 19.685423 },
    { lat: 49.0558097, lng: 19.6854194 },
    { lat: 49.0558182, lng: 19.6853549 },
    { lat: 49.0558182, lng: 19.6853341 },
    { lat: 49.0558211, lng: 19.6853198 },
    { lat: 49.0558216, lng: 19.6853108 },
    { lat: 49.0558258, lng: 19.6852981 },
    { lat: 49.055887, lng: 19.6848522 },
    { lat: 49.0559695, lng: 19.6842995 },
    { lat: 49.0560533, lng: 19.6836946 },
    { lat: 49.0560655, lng: 19.6836395 },
    { lat: 49.0560653, lng: 19.6836352 },
    { lat: 49.0560912, lng: 19.6834317 },
    { lat: 49.0561377, lng: 19.6832348 },
    { lat: 49.0561173, lng: 19.6828485 },
    { lat: 49.0560941, lng: 19.6824788 },
    { lat: 49.0561667, lng: 19.6817242 },
    { lat: 49.0560837, lng: 19.6813264 },
    { lat: 49.056125, lng: 19.6810905 },
    { lat: 49.0561678, lng: 19.6807843 },
    { lat: 49.0561694, lng: 19.6804882 },
    { lat: 49.0561637, lng: 19.6801263 },
    { lat: 49.0561545, lng: 19.6798212 },
    { lat: 49.0560177, lng: 19.679809 },
    { lat: 49.0560235, lng: 19.6795889 },
    { lat: 49.0561401, lng: 19.6793431 },
    { lat: 49.0562363, lng: 19.6792553 },
    { lat: 49.0563525, lng: 19.6790519 },
    { lat: 49.0564457, lng: 19.6788642 },
    { lat: 49.056458, lng: 19.6787117 },
    { lat: 49.0565179, lng: 19.6780797 },
    { lat: 49.0564008, lng: 19.6771423 },
    { lat: 49.0563678, lng: 19.6768555 },
    { lat: 49.056333, lng: 19.6764499 },
    { lat: 49.056444, lng: 19.6757531 },
    { lat: 49.0565117, lng: 19.6751983 },
    { lat: 49.0566011, lng: 19.6749779 },
    { lat: 49.0589785, lng: 19.6756173 },
    { lat: 49.0590286, lng: 19.675628 },
    { lat: 49.0604302, lng: 19.6760101 },
    { lat: 49.0609529, lng: 19.6761608 },
    { lat: 49.0619164, lng: 19.6764216 },
    { lat: 49.0620566, lng: 19.6764575 },
    { lat: 49.0625208, lng: 19.6765881 },
    { lat: 49.0634973, lng: 19.6768587 },
    { lat: 49.0636393, lng: 19.676607 },
    { lat: 49.0639677, lng: 19.6757286 },
    { lat: 49.0640514, lng: 19.6757567 },
    { lat: 49.064668, lng: 19.6759431 },
    { lat: 49.0652582, lng: 19.6760959 },
    { lat: 49.0656248, lng: 19.6761801 },
    { lat: 49.0655975, lng: 19.6757402 },
    { lat: 49.0658511, lng: 19.6730246 },
    { lat: 49.0659435, lng: 19.6726436 },
    { lat: 49.0661539, lng: 19.6721279 },
    { lat: 49.06633, lng: 19.6716539 },
    { lat: 49.066415, lng: 19.6716164 },
    { lat: 49.0664549, lng: 19.6715476 },
    { lat: 49.0662687, lng: 19.67139 },
    { lat: 49.0664799, lng: 19.6709185 },
    { lat: 49.0667266, lng: 19.6704612 },
    { lat: 49.0668394, lng: 19.6702111 },
    { lat: 49.0671884, lng: 19.6695953 },
    { lat: 49.067309, lng: 19.669418 },
    { lat: 49.0674828, lng: 19.6691734 },
    { lat: 49.0676846, lng: 19.6687951 },
    { lat: 49.0677847, lng: 19.6685876 },
    { lat: 49.0679424, lng: 19.668156 },
    { lat: 49.0679893, lng: 19.6678893 },
    { lat: 49.0680511, lng: 19.6674305 },
    { lat: 49.0680655, lng: 19.6671693 },
    { lat: 49.0680548, lng: 19.6662207 },
    { lat: 49.0680566, lng: 19.6660811 },
    { lat: 49.0679346, lng: 19.6652367 },
    { lat: 49.0679031, lng: 19.6651124 },
    { lat: 49.0677517, lng: 19.6647492 },
    { lat: 49.0676512, lng: 19.664539 },
    { lat: 49.0674765, lng: 19.6642088 },
    { lat: 49.0672935, lng: 19.6637141 },
    { lat: 49.067185, lng: 19.6635025 },
    { lat: 49.0670929, lng: 19.6629012 },
    { lat: 49.0670371, lng: 19.6621039 },
    { lat: 49.0671396, lng: 19.6619773 },
    { lat: 49.067481, lng: 19.6612164 },
    { lat: 49.067821, lng: 19.661033 },
    { lat: 49.0679348, lng: 19.6609587 },
    { lat: 49.0681692, lng: 19.6609098 },
    { lat: 49.0683111, lng: 19.6609304 },
    { lat: 49.0684442, lng: 19.6605524 },
    { lat: 49.0686706, lng: 19.6598931 },
    { lat: 49.0689594, lng: 19.6589643 },
    { lat: 49.0690518, lng: 19.6581284 },
    { lat: 49.0692256, lng: 19.6565629 },
    { lat: 49.0693066, lng: 19.6559807 },
    { lat: 49.069436, lng: 19.6555193 },
    { lat: 49.069666, lng: 19.6552968 },
    { lat: 49.0696754, lng: 19.6552794 },
    { lat: 49.0697307, lng: 19.6551058 },
    { lat: 49.0691697, lng: 19.6549782 },
    { lat: 49.0693121, lng: 19.6553919 },
    { lat: 49.0657821, lng: 19.6542728 },
    { lat: 49.0638163, lng: 19.6536501 },
    { lat: 49.0623662, lng: 19.6531891 },
    { lat: 49.0618879, lng: 19.653052 },
    { lat: 49.0614033, lng: 19.6530211 },
    { lat: 49.0613476, lng: 19.6529855 },
    { lat: 49.0612135, lng: 19.653466 },
    { lat: 49.0594211, lng: 19.652989 },
    { lat: 49.0593688, lng: 19.6526732 },
    { lat: 49.0593678, lng: 19.6524991 },
    { lat: 49.0591767, lng: 19.6523047 },
    { lat: 49.0591909, lng: 19.6540923 },
    { lat: 49.0591027, lng: 19.6547419 },
    { lat: 49.059018, lng: 19.65494 },
    { lat: 49.0588196, lng: 19.6553732 },
    { lat: 49.0589057, lng: 19.6559133 },
    { lat: 49.0585695, lng: 19.6585607 },
    { lat: 49.0585633, lng: 19.6586023 },
    { lat: 49.0582968, lng: 19.6612943 },
    { lat: 49.0527504, lng: 19.6597773 },
    { lat: 49.0520614, lng: 19.6595899 },
    { lat: 49.0520149, lng: 19.6595792 },
    { lat: 49.0517926, lng: 19.6598518 },
    { lat: 49.0516494, lng: 19.6602325 },
    { lat: 49.0516327, lng: 19.6607906 },
    { lat: 49.0502319, lng: 19.6605074 },
    { lat: 49.0501915, lng: 19.6599289 },
    { lat: 49.0492774, lng: 19.6594623 },
    { lat: 49.0484763, lng: 19.6589827 },
    { lat: 49.0484394, lng: 19.6589606 },
    { lat: 49.0480247, lng: 19.658856 },
    { lat: 49.0476404, lng: 19.6587048 },
    { lat: 49.0474016, lng: 19.6592111 },
    { lat: 49.0473007, lng: 19.6589442 },
    { lat: 49.0472578, lng: 19.6582335 },
    { lat: 49.0466072, lng: 19.6574877 },
    { lat: 49.0466342, lng: 19.6570266 },
    { lat: 49.045685, lng: 19.6569146 },
    { lat: 49.044838, lng: 19.6571979 },
    { lat: 49.0438278, lng: 19.6560934 },
    { lat: 49.0431361, lng: 19.6557922 },
    { lat: 49.0427411, lng: 19.6566352 },
    { lat: 49.0425018, lng: 19.6570319 },
    { lat: 49.0420971, lng: 19.6569288 },
    { lat: 49.0408297, lng: 19.6567253 },
    { lat: 49.0402961, lng: 19.6567893 },
    { lat: 49.039833, lng: 19.6585851 },
    { lat: 49.0394213, lng: 19.6592821 },
    { lat: 49.0393298, lng: 19.6593802 },
    { lat: 49.0388516, lng: 19.6588086 },
    { lat: 49.0384325, lng: 19.659344 },
    { lat: 49.0381897, lng: 19.65981 },
    { lat: 49.0371881, lng: 19.6588239 },
    { lat: 49.0351008, lng: 19.6572386 },
    { lat: 49.0349323, lng: 19.6547952 },
    { lat: 49.0334816, lng: 19.6537902 },
    { lat: 49.033113, lng: 19.653305 },
    { lat: 49.0326617, lng: 19.6530366 },
    { lat: 49.0312842, lng: 19.6536079 },
    { lat: 49.0302317, lng: 19.6539045 },
    { lat: 49.0291783, lng: 19.65426 },
    { lat: 49.0281215, lng: 19.6545606 },
    { lat: 49.0273491, lng: 19.6547546 },
    { lat: 49.026864, lng: 19.6550911 },
    { lat: 49.0267177, lng: 19.6543127 },
    { lat: 49.0264659, lng: 19.6528584 },
    { lat: 49.0262658, lng: 19.6517312 },
    { lat: 49.0260351, lng: 19.6505498 },
    { lat: 49.0258445, lng: 19.6497573 },
    { lat: 49.0257089, lng: 19.6493473 },
    { lat: 49.0254735, lng: 19.6489666 },
    { lat: 49.0254041, lng: 19.6484471 },
    { lat: 49.0252935, lng: 19.6474073 },
    { lat: 49.0248224, lng: 19.6446007 },
    { lat: 49.0246449, lng: 19.6436554 },
    { lat: 49.0236793, lng: 19.6380502 },
    { lat: 49.0210183, lng: 19.6319843 },
    { lat: 49.0178528, lng: 19.6334159 },
    { lat: 49.0168715, lng: 19.6338597 },
    { lat: 49.0161306, lng: 19.6336688 },
    { lat: 49.0139807, lng: 19.6331879 },
    { lat: 49.0123324, lng: 19.6337201 },
    { lat: 49.0111483, lng: 19.6339179 },
    { lat: 49.0106133, lng: 19.633924 },
    { lat: 49.0099371, lng: 19.6341446 },
    { lat: 49.0096198, lng: 19.6344577 },
    { lat: 49.0084119, lng: 19.6348571 },
    { lat: 49.0073226, lng: 19.6351652 },
    { lat: 49.0069127, lng: 19.6352955 },
    { lat: 49.0055246, lng: 19.635173 },
    { lat: 49.003109, lng: 19.6352711 },
    { lat: 49.0028654, lng: 19.6353562 },
    { lat: 49.0025274, lng: 19.6353696 },
    { lat: 49.0006411, lng: 19.6352157 },
    { lat: 48.9984135, lng: 19.6349811 },
    { lat: 48.9957042, lng: 19.6351914 },
    { lat: 48.9945154, lng: 19.6353648 },
    { lat: 48.9916284, lng: 19.63657 },
    { lat: 48.9912025, lng: 19.6369604 },
    { lat: 48.9905472, lng: 19.637561 },
    { lat: 48.9892999, lng: 19.6379143 },
    { lat: 48.9883191, lng: 19.6364399 },
    { lat: 48.9873655, lng: 19.6354945 },
    { lat: 48.9870568, lng: 19.6352967 },
    { lat: 48.9867332, lng: 19.6353228 },
    { lat: 48.9863922, lng: 19.6353503 },
    { lat: 48.9854003, lng: 19.6344845 },
    { lat: 48.9849709, lng: 19.6330459 },
    { lat: 48.9848004, lng: 19.6328382 },
    { lat: 48.9830269, lng: 19.6333149 },
    { lat: 48.9817346, lng: 19.6327852 },
    { lat: 48.9811327, lng: 19.6327573 },
    { lat: 48.97883, lng: 19.633416 },
    { lat: 48.9775073, lng: 19.6326595 },
    { lat: 48.9769416, lng: 19.6335138 },
    { lat: 48.9765359, lng: 19.6334402 },
    { lat: 48.9750961, lng: 19.6331898 },
    { lat: 48.9745181, lng: 19.6330818 },
    { lat: 48.9735698, lng: 19.6329228 },
    { lat: 48.9705208, lng: 19.6322644 },
    { lat: 48.9681465, lng: 19.6317555 },
    { lat: 48.966508, lng: 19.6306661 },
    { lat: 48.9640452, lng: 19.6297638 },
    { lat: 48.9639987, lng: 19.629759 },
    { lat: 48.9603378, lng: 19.6307586 },
    { lat: 48.9596101, lng: 19.6306882 },
    { lat: 48.9582844, lng: 19.6306477 },
    { lat: 48.9559867, lng: 19.6308643 },
    { lat: 48.9555947, lng: 19.6311955 },
    { lat: 48.9535011, lng: 19.630461 },
    { lat: 48.9504841, lng: 19.6286627 },
    { lat: 48.9486147, lng: 19.6266197 },
    { lat: 48.9482002, lng: 19.6266493 },
    { lat: 48.9469649, lng: 19.6269437 },
    { lat: 48.945059, lng: 19.6274056 },
    { lat: 48.9421287, lng: 19.6292478 },
    { lat: 48.9413092, lng: 19.629764 },
    { lat: 48.9410887, lng: 19.6298226 },
    { lat: 48.9401352, lng: 19.6299161 },
    { lat: 48.939498, lng: 19.629889 },
    { lat: 48.938473, lng: 19.631335 },
    { lat: 48.938054, lng: 19.632107 },
    { lat: 48.937874, lng: 19.632134 },
    { lat: 48.937697, lng: 19.632964 },
    { lat: 48.937389, lng: 19.635162 },
    { lat: 48.937282, lng: 19.636801 },
    { lat: 48.936996, lng: 19.637295 },
    { lat: 48.936439, lng: 19.640074 },
    { lat: 48.936396, lng: 19.640266 },
    { lat: 48.936203, lng: 19.640706 },
    { lat: 48.934345, lng: 19.64494 },
    { lat: 48.932974, lng: 19.648062 },
    { lat: 48.927359, lng: 19.649271 },
    { lat: 48.927199, lng: 19.649422 },
    { lat: 48.92703, lng: 19.649581 },
    { lat: 48.926803, lng: 19.649794 },
    { lat: 48.926494, lng: 19.650699 },
    { lat: 48.925759, lng: 19.652936 },
    { lat: 48.925538, lng: 19.653425 },
    { lat: 48.925207, lng: 19.654133 },
    { lat: 48.924733, lng: 19.654635 },
    { lat: 48.924067, lng: 19.655509 },
    { lat: 48.923285, lng: 19.657761 },
    { lat: 48.92286, lng: 19.658708 },
    { lat: 48.922688, lng: 19.659374 },
    { lat: 48.922434, lng: 19.660352 },
    { lat: 48.922171, lng: 19.662189 },
    { lat: 48.921748, lng: 19.663805 },
    { lat: 48.921629, lng: 19.664684 },
    { lat: 48.921552, lng: 19.665257 },
    { lat: 48.921327, lng: 19.665658 },
    { lat: 48.919159, lng: 19.669322 },
    { lat: 48.918191, lng: 19.67527 },
    { lat: 48.919235, lng: 19.676474 },
    { lat: 48.919782, lng: 19.677217 },
    { lat: 48.920782, lng: 19.67833 },
    { lat: 48.920951, lng: 19.679317 },
    { lat: 48.9211253, lng: 19.6804926 },
    { lat: 48.921152, lng: 19.680673 },
    { lat: 48.921354, lng: 19.681236 },
    { lat: 48.921951, lng: 19.682475 },
    { lat: 48.923154, lng: 19.687505 },
    { lat: 48.923698, lng: 19.690142 },
    { lat: 48.9260156, lng: 19.6902398 },
    { lat: 48.9264971, lng: 19.690253 },
    { lat: 48.9292032, lng: 19.6911123 },
    { lat: 48.9303251, lng: 19.6928855 },
    { lat: 48.9313264, lng: 19.6938741 },
    { lat: 48.9316031, lng: 19.69414 },
    { lat: 48.9325495, lng: 19.6950823 },
    { lat: 48.9331227, lng: 19.6959459 },
    { lat: 48.9333979, lng: 19.6963811 },
    { lat: 48.9345234, lng: 19.6981105 },
    { lat: 48.9354459, lng: 19.699533 },
    { lat: 48.9363272, lng: 19.7007387 },
    { lat: 48.9366001, lng: 19.7026826 },
    { lat: 48.936716, lng: 19.7054821 },
    { lat: 48.9367582, lng: 19.7066152 },
    { lat: 48.9369201, lng: 19.7072219 },
    { lat: 48.9374807, lng: 19.7097867 },
    { lat: 48.9389905, lng: 19.7118325 },
    { lat: 48.940133, lng: 19.713418 },
    { lat: 48.9412493, lng: 19.7114986 },
    { lat: 48.9423104, lng: 19.7108793 },
    { lat: 48.9438807, lng: 19.7096792 },
    { lat: 48.9449986, lng: 19.7091705 },
    { lat: 48.9467502, lng: 19.7082479 },
    { lat: 48.9469439, lng: 19.7081418 },
    { lat: 48.9472099, lng: 19.7079366 },
    { lat: 48.9481857, lng: 19.7079922 },
    { lat: 48.9489208, lng: 19.7079539 },
    { lat: 48.9496259, lng: 19.7081059 },
    { lat: 48.9511276, lng: 19.7086879 },
    { lat: 48.9525239, lng: 19.7091822 },
    { lat: 48.9527267, lng: 19.7092572 },
    { lat: 48.9535194, lng: 19.7095817 },
    { lat: 48.9543022, lng: 19.7100072 },
    { lat: 48.9551853, lng: 19.7103859 },
    { lat: 48.9564397, lng: 19.7110129 },
    { lat: 48.9576786, lng: 19.7113329 },
    { lat: 48.9584195, lng: 19.7114526 },
    { lat: 48.9592126, lng: 19.7114766 },
    { lat: 48.9596621, lng: 19.7114499 },
    { lat: 48.9601154, lng: 19.7115895 },
    { lat: 48.9603984, lng: 19.7109549 },
    { lat: 48.9606243, lng: 19.7105576 },
    { lat: 48.960888, lng: 19.7101518 },
    { lat: 48.9611245, lng: 19.7098387 },
    { lat: 48.9614056, lng: 19.7094735 },
    { lat: 48.9617544, lng: 19.7089246 },
    { lat: 48.9620168, lng: 19.7089757 },
    { lat: 48.9629225, lng: 19.7095339 },
    { lat: 48.9639864, lng: 19.7091836 },
    { lat: 48.9648003, lng: 19.7087832 },
    { lat: 48.965137, lng: 19.7086571 },
    { lat: 48.9660343, lng: 19.7088681 },
    { lat: 48.9664532, lng: 19.7090696 },
    { lat: 48.9674552, lng: 19.7092832 },
    { lat: 48.9677437, lng: 19.7093582 },
    { lat: 48.9681166, lng: 19.7093887 },
    { lat: 48.968733, lng: 19.7093135 },
    { lat: 48.9693626, lng: 19.7090292 },
    { lat: 48.970779, lng: 19.708165 },
    { lat: 48.971776, lng: 19.7078336 },
    { lat: 48.9732417, lng: 19.707342 },
    { lat: 48.9752422, lng: 19.7066982 },
    { lat: 48.9778869, lng: 19.7083169 },
    { lat: 48.9802691, lng: 19.711113 },
    { lat: 48.9826457, lng: 19.7119695 },
    { lat: 48.9828644, lng: 19.7096463 },
    { lat: 48.9829508, lng: 19.708768 },
    { lat: 48.9843401, lng: 19.7053251 },
    { lat: 48.9848292, lng: 19.7041802 },
    { lat: 48.9853889, lng: 19.7035083 },
    { lat: 48.9855928, lng: 19.7030747 },
    { lat: 48.9861732, lng: 19.7033326 },
    { lat: 48.9894313, lng: 19.7037682 },
    { lat: 48.992972, lng: 19.7042426 },
    { lat: 48.9932998, lng: 19.7028387 },
    { lat: 48.9932365, lng: 19.7027085 },
    { lat: 48.9932491, lng: 19.7025554 },
    { lat: 48.9936295, lng: 19.700942 },
    { lat: 48.9957198, lng: 19.7013589 },
    { lat: 48.996068, lng: 19.7014608 },
    { lat: 48.9965443, lng: 19.7018635 },
    { lat: 48.9968725, lng: 19.7017214 },
    { lat: 48.9975051, lng: 19.7026001 },
    { lat: 48.9977587, lng: 19.7026019 },
    { lat: 48.9990393, lng: 19.7024437 },
    { lat: 49.0004452, lng: 19.7024116 },
    { lat: 49.0005744, lng: 19.7024835 },
    { lat: 49.0017124, lng: 19.7033495 },
    { lat: 49.0023594, lng: 19.7036894 },
    { lat: 49.0042863, lng: 19.703878 },
    { lat: 49.0053006, lng: 19.703986 },
    { lat: 49.0060405, lng: 19.7014029 },
    { lat: 49.0060853, lng: 19.7012199 },
    { lat: 49.0068684, lng: 19.6998665 },
    { lat: 49.0075761, lng: 19.6986455 },
    { lat: 49.0092628, lng: 19.6969908 },
    { lat: 49.0093745, lng: 19.6968431 },
    { lat: 49.0094499, lng: 19.6968135 },
    { lat: 49.0116556, lng: 19.6964523 },
    { lat: 49.0130479, lng: 19.697797 },
    { lat: 49.0135901, lng: 19.697474 },
    { lat: 49.0143791, lng: 19.6982225 },
    { lat: 49.015048, lng: 19.6974759 },
    { lat: 49.0148399, lng: 19.6973783 },
    { lat: 49.0145638, lng: 19.6970433 },
    { lat: 49.0141988, lng: 19.6964614 },
    { lat: 49.0140438, lng: 19.6961894 },
    { lat: 49.0147326, lng: 19.6946966 },
    { lat: 49.0149021, lng: 19.6932282 },
    { lat: 49.0148942, lng: 19.6922865 },
    { lat: 49.0156638, lng: 19.6904017 },
    { lat: 49.0158271, lng: 19.6898398 },
    { lat: 49.0158991, lng: 19.6895568 },
    { lat: 49.01633, lng: 19.6891893 },
    { lat: 49.0168334, lng: 19.6887516 },
    { lat: 49.0171385, lng: 19.6885406 },
    { lat: 49.0177871, lng: 19.6879986 },
    { lat: 49.0182429, lng: 19.6875791 },
    { lat: 49.0183915, lng: 19.687449 },
    { lat: 49.0187618, lng: 19.6872905 },
    { lat: 49.0188338, lng: 19.6872656 },
    { lat: 49.0191741, lng: 19.6871866 },
    { lat: 49.0194696, lng: 19.6871951 },
    { lat: 49.0204908, lng: 19.6863874 },
    { lat: 49.020789, lng: 19.6862004 },
    { lat: 49.0210237, lng: 19.6860849 },
    { lat: 49.0222039, lng: 19.6855143 },
    { lat: 49.0226443, lng: 19.6862478 },
    { lat: 49.0231124, lng: 19.6864642 },
    { lat: 49.0234313, lng: 19.6866182 },
    { lat: 49.0236663, lng: 19.6867381 },
    { lat: 49.0242381, lng: 19.68718 },
    { lat: 49.0247579, lng: 19.687498 },
    { lat: 49.0257889, lng: 19.6881601 },
    { lat: 49.0265174, lng: 19.6881279 },
    { lat: 49.0270404, lng: 19.6880813 },
    { lat: 49.0279174, lng: 19.6881438 },
    { lat: 49.0280699, lng: 19.6881759 },
    { lat: 49.0283272, lng: 19.6883402 },
    { lat: 49.0286805, lng: 19.68861 },
    { lat: 49.0290304, lng: 19.688903 },
    { lat: 49.0297735, lng: 19.6894015 },
    { lat: 49.0301199, lng: 19.6896167 },
    { lat: 49.030251, lng: 19.6892923 },
    { lat: 49.0304619, lng: 19.6889429 },
    { lat: 49.030717, lng: 19.6887262 },
    { lat: 49.0310101, lng: 19.6885537 },
    { lat: 49.0313724, lng: 19.6884649 },
    { lat: 49.0314348, lng: 19.6885271 },
    { lat: 49.0319433, lng: 19.6889889 },
    { lat: 49.0323438, lng: 19.6893113 },
    { lat: 49.0326169, lng: 19.6894984 },
    { lat: 49.0329117, lng: 19.6896395 },
    { lat: 49.0340236, lng: 19.6900974 },
    { lat: 49.0343993, lng: 19.6902647 },
    { lat: 49.0351701, lng: 19.690778 },
    { lat: 49.0358023, lng: 19.6917496 },
    { lat: 49.0360492, lng: 19.6925718 },
    { lat: 49.0355724, lng: 19.6944012 },
    { lat: 49.0354144, lng: 19.6952863 },
    { lat: 49.0355082, lng: 19.6972537 },
    { lat: 49.0353504, lng: 19.698835 },
    { lat: 49.0349296, lng: 19.7025526 },
    { lat: 49.0349061, lng: 19.702764 },
    { lat: 49.0350961, lng: 19.7030089 },
    { lat: 49.0350691, lng: 19.7030605 },
  ],
  LiptovskýOndrej: [
    { lat: 49.0904768, lng: 19.7108372 },
    { lat: 49.0908427, lng: 19.7102126 },
    { lat: 49.0913838, lng: 19.7107074 },
    { lat: 49.0918876, lng: 19.7113789 },
    { lat: 49.0927274, lng: 19.7116696 },
    { lat: 49.0930661, lng: 19.7123299 },
    { lat: 49.093088, lng: 19.7123658 },
    { lat: 49.0933733, lng: 19.7129104 },
    { lat: 49.0933939, lng: 19.7129439 },
    { lat: 49.0939566, lng: 19.7140329 },
    { lat: 49.094981, lng: 19.7166567 },
    { lat: 49.0954468, lng: 19.7176396 },
    { lat: 49.0958839, lng: 19.7187192 },
    { lat: 49.0960245, lng: 19.7185593 },
    { lat: 49.0969589, lng: 19.7200071 },
    { lat: 49.0979833, lng: 19.7215314 },
    { lat: 49.0980181, lng: 19.7215771 },
    { lat: 49.0980643, lng: 19.7215806 },
    { lat: 49.0980927, lng: 19.7216083 },
    { lat: 49.0981024, lng: 19.721626 },
    { lat: 49.0984866, lng: 19.7207557 },
    { lat: 49.0990321, lng: 19.719448 },
    { lat: 49.0991464, lng: 19.7191534 },
    { lat: 49.0991631, lng: 19.7190482 },
    { lat: 49.0993735, lng: 19.7187823 },
    { lat: 49.0994209, lng: 19.7187591 },
    { lat: 49.0995257, lng: 19.7186348 },
    { lat: 49.099697, lng: 19.7184818 },
    { lat: 49.0997262, lng: 19.7184742 },
    { lat: 49.0998386, lng: 19.7187547 },
    { lat: 49.0998865, lng: 19.7188192 },
    { lat: 49.0999583, lng: 19.7189643 },
    { lat: 49.1001513, lng: 19.7192057 },
    { lat: 49.1011021, lng: 19.7204121 },
    { lat: 49.1014523, lng: 19.7208457 },
    { lat: 49.1014626, lng: 19.7208216 },
    { lat: 49.1014774, lng: 19.7207985 },
    { lat: 49.1014923, lng: 19.7206762 },
    { lat: 49.1015773, lng: 19.720509 },
    { lat: 49.1016398, lng: 19.7203678 },
    { lat: 49.1017969, lng: 19.7200419 },
    { lat: 49.1019599, lng: 19.7196255 },
    { lat: 49.1021666, lng: 19.7191223 },
    { lat: 49.102221, lng: 19.7188504 },
    { lat: 49.1022956, lng: 19.7182916 },
    { lat: 49.1024413, lng: 19.717631 },
    { lat: 49.1024489, lng: 19.7174724 },
    { lat: 49.1024586, lng: 19.7174336 },
    { lat: 49.1027799, lng: 19.7174674 },
    { lat: 49.1034731, lng: 19.7179106 },
    { lat: 49.1037502, lng: 19.7181657 },
    { lat: 49.1044712, lng: 19.7185007 },
    { lat: 49.1055836, lng: 19.7188717 },
    { lat: 49.1062122, lng: 19.7191102 },
    { lat: 49.1070295, lng: 19.7194202 },
    { lat: 49.1088234, lng: 19.7198657 },
    { lat: 49.1095244, lng: 19.7201795 },
    { lat: 49.1101175, lng: 19.7166001 },
    { lat: 49.1101546, lng: 19.7164186 },
    { lat: 49.1102926, lng: 19.7153312 },
    { lat: 49.1103574, lng: 19.7148812 },
    { lat: 49.1104705, lng: 19.7139674 },
    { lat: 49.1105111, lng: 19.7137845 },
    { lat: 49.1108626, lng: 19.7120988 },
    { lat: 49.1109078, lng: 19.7118964 },
    { lat: 49.111044, lng: 19.7110684 },
    { lat: 49.1111093, lng: 19.710176 },
    { lat: 49.111043, lng: 19.707589 },
    { lat: 49.1110982, lng: 19.7064394 },
    { lat: 49.1110593, lng: 19.7061406 },
    { lat: 49.1109648, lng: 19.7052753 },
    { lat: 49.110837, lng: 19.7041351 },
    { lat: 49.1108175, lng: 19.7039774 },
    { lat: 49.1115498, lng: 19.703849 },
    { lat: 49.1122297, lng: 19.7035527 },
    { lat: 49.1129018, lng: 19.7030071 },
    { lat: 49.1132182, lng: 19.7026698 },
    { lat: 49.1136825, lng: 19.7026997 },
    { lat: 49.1153622, lng: 19.7028251 },
    { lat: 49.1162247, lng: 19.7026068 },
    { lat: 49.1164668, lng: 19.702299 },
    { lat: 49.1171094, lng: 19.7020528 },
    { lat: 49.1172652, lng: 19.7030334 },
    { lat: 49.1173602, lng: 19.7036053 },
    { lat: 49.1173957, lng: 19.7037462 },
    { lat: 49.1175331, lng: 19.7036444 },
    { lat: 49.118215, lng: 19.7032917 },
    { lat: 49.1182635, lng: 19.7035464 },
    { lat: 49.1184199, lng: 19.7039516 },
    { lat: 49.1186413, lng: 19.705887 },
    { lat: 49.1188994, lng: 19.7056581 },
    { lat: 49.1190102, lng: 19.7055918 },
    { lat: 49.1192353, lng: 19.7055141 },
    { lat: 49.1198439, lng: 19.705261 },
    { lat: 49.120537, lng: 19.7050628 },
    { lat: 49.120735, lng: 19.7050282 },
    { lat: 49.1208581, lng: 19.7049829 },
    { lat: 49.1210579, lng: 19.7048391 },
    { lat: 49.1211558, lng: 19.7047367 },
    { lat: 49.1212421, lng: 19.7046739 },
    { lat: 49.1214731, lng: 19.7045708 },
    { lat: 49.1216625, lng: 19.7045497 },
    { lat: 49.1219995, lng: 19.7046077 },
    { lat: 49.1224925, lng: 19.7044429 },
    { lat: 49.1225505, lng: 19.7044028 },
    { lat: 49.1227404, lng: 19.7041853 },
    { lat: 49.1230075, lng: 19.7039576 },
    { lat: 49.1233429, lng: 19.703773 },
    { lat: 49.124009, lng: 19.7029887 },
    { lat: 49.1241658, lng: 19.7028601 },
    { lat: 49.1243973, lng: 19.7027465 },
    { lat: 49.1246526, lng: 19.7026359 },
    { lat: 49.1251458, lng: 19.7024765 },
    { lat: 49.1255363, lng: 19.7023362 },
    { lat: 49.1258246, lng: 19.7021294 },
    { lat: 49.125862, lng: 19.7019821 },
    { lat: 49.1267107, lng: 19.7009683 },
    { lat: 49.1265966, lng: 19.7004994 },
    { lat: 49.1265037, lng: 19.7001546 },
    { lat: 49.1264552, lng: 19.6998469 },
    { lat: 49.1266257, lng: 19.6998306 },
    { lat: 49.1267542, lng: 19.6996516 },
    { lat: 49.1267626, lng: 19.6993853 },
    { lat: 49.1269133, lng: 19.699099 },
    { lat: 49.1271918, lng: 19.6986143 },
    { lat: 49.1273283, lng: 19.6985194 },
    { lat: 49.1275499, lng: 19.6984586 },
    { lat: 49.1277707, lng: 19.6984335 },
    { lat: 49.127971, lng: 19.6984792 },
    { lat: 49.1281566, lng: 19.6984203 },
    { lat: 49.1284464, lng: 19.6983022 },
    { lat: 49.1285663, lng: 19.6983115 },
    { lat: 49.1287535, lng: 19.6982157 },
    { lat: 49.1289869, lng: 19.6982926 },
    { lat: 49.1292167, lng: 19.6981942 },
    { lat: 49.1293496, lng: 19.6981878 },
    { lat: 49.1295047, lng: 19.698082 },
    { lat: 49.1254899, lng: 19.6973971 },
    { lat: 49.1254372, lng: 19.697096 },
    { lat: 49.1254332, lng: 19.6970525 },
    { lat: 49.124259, lng: 19.6968194 },
    { lat: 49.1226405, lng: 19.6965455 },
    { lat: 49.1213314, lng: 19.696213 },
    { lat: 49.1194615, lng: 19.6955285 },
    { lat: 49.1176873, lng: 19.6945614 },
    { lat: 49.1175898, lng: 19.694498 },
    { lat: 49.1174019, lng: 19.6943744 },
    { lat: 49.117352, lng: 19.6943418 },
    { lat: 49.1170365, lng: 19.6941345 },
    { lat: 49.116925, lng: 19.6940578 },
    { lat: 49.116906, lng: 19.6940411 },
    { lat: 49.1165759, lng: 19.6936059 },
    { lat: 49.1155956, lng: 19.692933 },
    { lat: 49.115304, lng: 19.6927075 },
    { lat: 49.1152483, lng: 19.6926758 },
    { lat: 49.1151049, lng: 19.6928182 },
    { lat: 49.1146359, lng: 19.6927349 },
    { lat: 49.1144724, lng: 19.6927592 },
    { lat: 49.1143483, lng: 19.6928557 },
    { lat: 49.1137941, lng: 19.6927575 },
    { lat: 49.1135029, lng: 19.6926701 },
    { lat: 49.113426, lng: 19.692734 },
    { lat: 49.1132638, lng: 19.69276 },
    { lat: 49.113209, lng: 19.6927706 },
    { lat: 49.1130047, lng: 19.6926358 },
    { lat: 49.112688, lng: 19.6928348 },
    { lat: 49.1122221, lng: 19.6932069 },
    { lat: 49.111646, lng: 19.6935809 },
    { lat: 49.1112566, lng: 19.6935139 },
    { lat: 49.1110182, lng: 19.6935998 },
    { lat: 49.1107154, lng: 19.6935808 },
    { lat: 49.1104685, lng: 19.6934748 },
    { lat: 49.1101254, lng: 19.6934093 },
    { lat: 49.1097991, lng: 19.6932154 },
    { lat: 49.1095319, lng: 19.6932331 },
    { lat: 49.1093016, lng: 19.6932987 },
    { lat: 49.1091962, lng: 19.6932858 },
    { lat: 49.1089699, lng: 19.6934157 },
    { lat: 49.1086541, lng: 19.6933859 },
    { lat: 49.1080714, lng: 19.6934807 },
    { lat: 49.1077903, lng: 19.6936976 },
    { lat: 49.107748, lng: 19.6938619 },
    { lat: 49.107559, lng: 19.6938949 },
    { lat: 49.1075219, lng: 19.6938962 },
    { lat: 49.1074094, lng: 19.694026 },
    { lat: 49.107209, lng: 19.6944119 },
    { lat: 49.1068502, lng: 19.6945036 },
    { lat: 49.1062118, lng: 19.6945132 },
    { lat: 49.1060137, lng: 19.6944386 },
    { lat: 49.1057046, lng: 19.6946345 },
    { lat: 49.1054643, lng: 19.6945768 },
    { lat: 49.1052987, lng: 19.6945998 },
    { lat: 49.1051726, lng: 19.6944984 },
    { lat: 49.1049627, lng: 19.6946684 },
    { lat: 49.1049393, lng: 19.6946787 },
    { lat: 49.1046997, lng: 19.6948129 },
    { lat: 49.104563, lng: 19.6949297 },
    { lat: 49.1043276, lng: 19.6949085 },
    { lat: 49.1040964, lng: 19.6951321 },
    { lat: 49.1038651, lng: 19.6951751 },
    { lat: 49.1032708, lng: 19.6951094 },
    { lat: 49.1028796, lng: 19.6948528 },
    { lat: 49.101978, lng: 19.6950429 },
    { lat: 49.1015949, lng: 19.6952755 },
    { lat: 49.1012238, lng: 19.6954199 },
    { lat: 49.1007208, lng: 19.6958122 },
    { lat: 49.1003889, lng: 19.6957491 },
    { lat: 49.1002329, lng: 19.6957118 },
    { lat: 49.0997888, lng: 19.6956048 },
    { lat: 49.0994598, lng: 19.6953224 },
    { lat: 49.0993886, lng: 19.6953409 },
    { lat: 49.0992411, lng: 19.695316 },
    { lat: 49.0988406, lng: 19.6953081 },
    { lat: 49.098611, lng: 19.6952087 },
    { lat: 49.0985427, lng: 19.6951161 },
    { lat: 49.0985313, lng: 19.6949853 },
    { lat: 49.0985625, lng: 19.6947739 },
    { lat: 49.0985754, lng: 19.6944778 },
    { lat: 49.0984001, lng: 19.6942595 },
    { lat: 49.0981257, lng: 19.6942959 },
    { lat: 49.0977455, lng: 19.694411 },
    { lat: 49.0971576, lng: 19.6940358 },
    { lat: 49.0961565, lng: 19.69315 },
    { lat: 49.0960658, lng: 19.6933146 },
    { lat: 49.0959563, lng: 19.6936943 },
    { lat: 49.0957024, lng: 19.6943986 },
    { lat: 49.0956441, lng: 19.6951487 },
    { lat: 49.0954327, lng: 19.695307 },
    { lat: 49.095298, lng: 19.6953732 },
    { lat: 49.0950899, lng: 19.6956322 },
    { lat: 49.0948845, lng: 19.6959063 },
    { lat: 49.0947442, lng: 19.6961507 },
    { lat: 49.0945626, lng: 19.6965758 },
    { lat: 49.0942829, lng: 19.6971343 },
    { lat: 49.0940461, lng: 19.6975907 },
    { lat: 49.0939635, lng: 19.6975042 },
    { lat: 49.0935683, lng: 19.6982747 },
    { lat: 49.0932951, lng: 19.6988749 },
    { lat: 49.0930696, lng: 19.699484 },
    { lat: 49.0929927, lng: 19.700525 },
    { lat: 49.0929913, lng: 19.700574 },
    { lat: 49.0928804, lng: 19.7009919 },
    { lat: 49.0925552, lng: 19.7023074 },
    { lat: 49.0921369, lng: 19.703274 },
    { lat: 49.0919518, lng: 19.7036753 },
    { lat: 49.091896, lng: 19.7037856 },
    { lat: 49.0917444, lng: 19.7040042 },
    { lat: 49.0914861, lng: 19.7044052 },
    { lat: 49.0910533, lng: 19.7052722 },
    { lat: 49.0908315, lng: 19.706068 },
    { lat: 49.0906957, lng: 19.7065938 },
    { lat: 49.0906492, lng: 19.7068674 },
    { lat: 49.0905113, lng: 19.7077251 },
    { lat: 49.0903324, lng: 19.7088584 },
    { lat: 49.0904768, lng: 19.7108372 },
  ],
  LiptovskýPeter: [
    { lat: 49.0446034, lng: 19.7198433 },
    { lat: 49.0446068, lng: 19.720173 },
    { lat: 49.04461, lng: 19.7203271 },
    { lat: 49.0446157, lng: 19.720863 },
    { lat: 49.0446239, lng: 19.7214386 },
    { lat: 49.0446284, lng: 19.7217431 },
    { lat: 49.0447113, lng: 19.7221433 },
    { lat: 49.0448096, lng: 19.722612 },
    { lat: 49.0448986, lng: 19.7230203 },
    { lat: 49.0450171, lng: 19.7235897 },
    { lat: 49.0450922, lng: 19.7239397 },
    { lat: 49.0451721, lng: 19.7245012 },
    { lat: 49.0452509, lng: 19.7250445 },
    { lat: 49.0453504, lng: 19.7257408 },
    { lat: 49.0454168, lng: 19.7262038 },
    { lat: 49.0454343, lng: 19.7262907 },
    { lat: 49.045476, lng: 19.726471 },
    { lat: 49.0455584, lng: 19.7268376 },
    { lat: 49.0456718, lng: 19.7273404 },
    { lat: 49.0458059, lng: 19.7279312 },
    { lat: 49.0459362, lng: 19.7285137 },
    { lat: 49.0459672, lng: 19.728652 },
    { lat: 49.0460049, lng: 19.7288203 },
    { lat: 49.0461334, lng: 19.7293104 },
    { lat: 49.0462859, lng: 19.7298875 },
    { lat: 49.0464242, lng: 19.730419 },
    { lat: 49.0465388, lng: 19.7308517 },
    { lat: 49.0465566, lng: 19.7309187 },
    { lat: 49.0465619, lng: 19.7309362 },
    { lat: 49.0465658, lng: 19.7309512 },
    { lat: 49.0465841, lng: 19.7310348 },
    { lat: 49.0465872, lng: 19.7310517 },
    { lat: 49.0466083, lng: 19.7311764 },
    { lat: 49.0466402, lng: 19.7313603 },
    { lat: 49.0466607, lng: 19.7314451 },
    { lat: 49.046686, lng: 19.7315337 },
    { lat: 49.0467514, lng: 19.7318446 },
    { lat: 49.0468694, lng: 19.7324051 },
    { lat: 49.0470605, lng: 19.7333173 },
    { lat: 49.0471027, lng: 19.7336395 },
    { lat: 49.0472299, lng: 19.7346146 },
    { lat: 49.0473499, lng: 19.7355261 },
    { lat: 49.0473734, lng: 19.7362661 },
    { lat: 49.0473987, lng: 19.7371305 },
    { lat: 49.0474395, lng: 19.7385471 },
    { lat: 49.0474674, lng: 19.739626 },
    { lat: 49.0476709, lng: 19.740722 },
    { lat: 49.0477546, lng: 19.7411664 },
    { lat: 49.0479088, lng: 19.7419879 },
    { lat: 49.0482014, lng: 19.7435511 },
    { lat: 49.0482737, lng: 19.7458247 },
    { lat: 49.0478827, lng: 19.7458445 },
    { lat: 49.0479206, lng: 19.7460418 },
    { lat: 49.0481544, lng: 19.7471415 },
    { lat: 49.048384, lng: 19.7481907 },
    { lat: 49.0484455, lng: 19.7484923 },
    { lat: 49.0485157, lng: 19.7488077 },
    { lat: 49.0487013, lng: 19.7496778 },
    { lat: 49.049016, lng: 19.7511318 },
    { lat: 49.0490854, lng: 19.7514574 },
    { lat: 49.05022, lng: 19.7531119 },
    { lat: 49.0505966, lng: 19.7536596 },
    { lat: 49.0510331, lng: 19.7542832 },
    { lat: 49.0511731, lng: 19.7544831 },
    { lat: 49.0512965, lng: 19.7545992 },
    { lat: 49.051604, lng: 19.7548778 },
    { lat: 49.0517672, lng: 19.7550312 },
    { lat: 49.0520121, lng: 19.75525 },
    { lat: 49.0525343, lng: 19.7557266 },
    { lat: 49.0533875, lng: 19.7564856 },
    { lat: 49.0539299, lng: 19.7571169 },
    { lat: 49.0546335, lng: 19.7579305 },
    { lat: 49.0549672, lng: 19.7583512 },
    { lat: 49.0554815, lng: 19.7590101 },
    { lat: 49.0559126, lng: 19.7595693 },
    { lat: 49.0566894, lng: 19.7605678 },
    { lat: 49.0586309, lng: 19.7583556 },
    { lat: 49.0589885, lng: 19.7580827 },
    { lat: 49.0593333, lng: 19.7577728 },
    { lat: 49.0596565, lng: 19.7574312 },
    { lat: 49.0598118, lng: 19.7572733 },
    { lat: 49.0602836, lng: 19.7568815 },
    { lat: 49.06005, lng: 19.7562021 },
    { lat: 49.0599556, lng: 19.7559002 },
    { lat: 49.0597322, lng: 19.755168 },
    { lat: 49.0596609, lng: 19.754923 },
    { lat: 49.0593665, lng: 19.7538855 },
    { lat: 49.0594428, lng: 19.7538261 },
    { lat: 49.0597151, lng: 19.7536401 },
    { lat: 49.0604673, lng: 19.7531176 },
    { lat: 49.061254, lng: 19.7525305 },
    { lat: 49.0609129, lng: 19.751141 },
    { lat: 49.0608805, lng: 19.7509161 },
    { lat: 49.06073, lng: 19.7499205 },
    { lat: 49.0608144, lng: 19.749862 },
    { lat: 49.0606605, lng: 19.7486906 },
    { lat: 49.0606594, lng: 19.7486868 },
    { lat: 49.0605936, lng: 19.7482139 },
    { lat: 49.0605757, lng: 19.7480346 },
    { lat: 49.0605416, lng: 19.7473584 },
    { lat: 49.0606163, lng: 19.7471335 },
    { lat: 49.0607354, lng: 19.7468179 },
    { lat: 49.0609671, lng: 19.7469648 },
    { lat: 49.0611505, lng: 19.7470892 },
    { lat: 49.0612836, lng: 19.7471687 },
    { lat: 49.061442, lng: 19.7466943 },
    { lat: 49.0616527, lng: 19.7460749 },
    { lat: 49.0618285, lng: 19.7455387 },
    { lat: 49.0619271, lng: 19.745574 },
    { lat: 49.0619788, lng: 19.7455756 },
    { lat: 49.062054, lng: 19.7455396 },
    { lat: 49.0620957, lng: 19.7454901 },
    { lat: 49.0621175, lng: 19.7454487 },
    { lat: 49.0622053, lng: 19.7454219 },
    { lat: 49.0623682, lng: 19.7454855 },
    { lat: 49.0625298, lng: 19.7454986 },
    { lat: 49.062664, lng: 19.7454261 },
    { lat: 49.0628203, lng: 19.7454153 },
    { lat: 49.0630495, lng: 19.7455336 },
    { lat: 49.0631881, lng: 19.7455828 },
    { lat: 49.0633386, lng: 19.7456021 },
    { lat: 49.0633595, lng: 19.7455888 },
    { lat: 49.0634223, lng: 19.7454808 },
    { lat: 49.0634929, lng: 19.7452922 },
    { lat: 49.0636113, lng: 19.7451667 },
    { lat: 49.0636553, lng: 19.7450879 },
    { lat: 49.0636659, lng: 19.7450215 },
    { lat: 49.0636866, lng: 19.7450028 },
    { lat: 49.0637091, lng: 19.7450049 },
    { lat: 49.0638615, lng: 19.7451382 },
    { lat: 49.0639038, lng: 19.7451835 },
    { lat: 49.0639973, lng: 19.7454628 },
    { lat: 49.0640247, lng: 19.7455229 },
    { lat: 49.0640611, lng: 19.745557 },
    { lat: 49.0641088, lng: 19.7455417 },
    { lat: 49.0641418, lng: 19.7454964 },
    { lat: 49.0641578, lng: 19.7454516 },
    { lat: 49.0641799, lng: 19.7452618 },
    { lat: 49.064191, lng: 19.745228 },
    { lat: 49.0642168, lng: 19.7452039 },
    { lat: 49.0642866, lng: 19.7452288 },
    { lat: 49.0643101, lng: 19.7452476 },
    { lat: 49.0643419, lng: 19.7453044 },
    { lat: 49.0643598, lng: 19.7453536 },
    { lat: 49.064361, lng: 19.7454073 },
    { lat: 49.064321, lng: 19.7454703 },
    { lat: 49.064251, lng: 19.7455216 },
    { lat: 49.0642352, lng: 19.7455422 },
    { lat: 49.0642232, lng: 19.7455822 },
    { lat: 49.0642311, lng: 19.7456339 },
    { lat: 49.064261, lng: 19.745675 },
    { lat: 49.064292, lng: 19.7456866 },
    { lat: 49.0644281, lng: 19.7455759 },
    { lat: 49.06446, lng: 19.7455611 },
    { lat: 49.0645262, lng: 19.7455516 },
    { lat: 49.0646383, lng: 19.7455682 },
    { lat: 49.0646622, lng: 19.745596 },
    { lat: 49.0647159, lng: 19.7457982 },
    { lat: 49.0647489, lng: 19.7458567 },
    { lat: 49.0648779, lng: 19.7460222 },
    { lat: 49.0648939, lng: 19.7460802 },
    { lat: 49.064919, lng: 19.7462648 },
    { lat: 49.0649676, lng: 19.7463727 },
    { lat: 49.0650193, lng: 19.7464206 },
    { lat: 49.0651474, lng: 19.746495 },
    { lat: 49.0651702, lng: 19.7465246 },
    { lat: 49.0652304, lng: 19.7466382 },
    { lat: 49.0652684, lng: 19.7467619 },
    { lat: 49.0652731, lng: 19.7468164 },
    { lat: 49.0652353, lng: 19.7469553 },
    { lat: 49.0652464, lng: 19.7470307 },
    { lat: 49.065258, lng: 19.7470647 },
    { lat: 49.065301, lng: 19.7471189 },
    { lat: 49.0655383, lng: 19.7471089 },
    { lat: 49.065682, lng: 19.7470976 },
    { lat: 49.0657887, lng: 19.7471199 },
    { lat: 49.0658132, lng: 19.7471393 },
    { lat: 49.0658355, lng: 19.7472071 },
    { lat: 49.0658228, lng: 19.7472515 },
    { lat: 49.0658338, lng: 19.7472752 },
    { lat: 49.0658404, lng: 19.7472709 },
    { lat: 49.0659478, lng: 19.7473301 },
    { lat: 49.0664317, lng: 19.7475768 },
    { lat: 49.0671131, lng: 19.7477532 },
    { lat: 49.0672111, lng: 19.7477257 },
    { lat: 49.0672338, lng: 19.747731 },
    { lat: 49.067407, lng: 19.7476969 },
    { lat: 49.0679988, lng: 19.7475993 },
    { lat: 49.0684296, lng: 19.7477666 },
    { lat: 49.0686017, lng: 19.7478383 },
    { lat: 49.0689007, lng: 19.747955 },
    { lat: 49.0693719, lng: 19.7480625 },
    { lat: 49.069485, lng: 19.7480198 },
    { lat: 49.0700404, lng: 19.7475864 },
    { lat: 49.0704347, lng: 19.747273 },
    { lat: 49.0706084, lng: 19.7470991 },
    { lat: 49.0708038, lng: 19.746876 },
    { lat: 49.0710588, lng: 19.745884 },
    { lat: 49.0710505, lng: 19.7450243 },
    { lat: 49.0710365, lng: 19.7437743 },
    { lat: 49.0710862, lng: 19.7436197 },
    { lat: 49.0712243, lng: 19.743144 },
    { lat: 49.0712774, lng: 19.7429709 },
    { lat: 49.071485, lng: 19.7422549 },
    { lat: 49.0715842, lng: 19.7419211 },
    { lat: 49.0718208, lng: 19.7411739 },
    { lat: 49.0719259, lng: 19.7408244 },
    { lat: 49.0719143, lng: 19.7405054 },
    { lat: 49.0718936, lng: 19.7398259 },
    { lat: 49.0718521, lng: 19.7387478 },
    { lat: 49.0717817, lng: 19.7375502 },
    { lat: 49.0717539, lng: 19.737253 },
    { lat: 49.0714644, lng: 19.7353485 },
    { lat: 49.0712662, lng: 19.734014 },
    { lat: 49.0710421, lng: 19.7325477 },
    { lat: 49.0703025, lng: 19.732639 },
    { lat: 49.06939, lng: 19.7325882 },
    { lat: 49.0693736, lng: 19.7327807 },
    { lat: 49.0683024, lng: 19.7327073 },
    { lat: 49.0672977, lng: 19.7326314 },
    { lat: 49.0659119, lng: 19.7325019 },
    { lat: 49.0659895, lng: 19.7316012 },
    { lat: 49.0659814, lng: 19.7310279 },
    { lat: 49.0659001, lng: 19.7304861 },
    { lat: 49.0659249, lng: 19.7297916 },
    { lat: 49.0666804, lng: 19.7287003 },
    { lat: 49.0659258, lng: 19.7276003 },
    { lat: 49.0650079, lng: 19.726352 },
    { lat: 49.0640673, lng: 19.7250953 },
    { lat: 49.0631408, lng: 19.7238505 },
    { lat: 49.0630408, lng: 19.7237296 },
    { lat: 49.0624837, lng: 19.724666 },
    { lat: 49.0623004, lng: 19.7246459 },
    { lat: 49.0619709, lng: 19.7245091 },
    { lat: 49.0625498, lng: 19.7236599 },
    { lat: 49.0618644, lng: 19.7225458 },
    { lat: 49.0612927, lng: 19.7216868 },
    { lat: 49.0612342, lng: 19.7216331 },
    { lat: 49.0609316, lng: 19.7217206 },
    { lat: 49.0606052, lng: 19.7215509 },
    { lat: 49.0611749, lng: 19.7201601 },
    { lat: 49.0615065, lng: 19.7193457 },
    { lat: 49.0607873, lng: 19.7188765 },
    { lat: 49.0601044, lng: 19.7184326 },
    { lat: 49.0597344, lng: 19.719114 },
    { lat: 49.0593472, lng: 19.7196862 },
    { lat: 49.0592213, lng: 19.7197992 },
    { lat: 49.0589215, lng: 19.7200793 },
    { lat: 49.0587661, lng: 19.7202335 },
    { lat: 49.0585919, lng: 19.7203949 },
    { lat: 49.0584894, lng: 19.7204736 },
    { lat: 49.0579905, lng: 19.720784 },
    { lat: 49.0577666, lng: 19.7209167 },
    { lat: 49.0577157, lng: 19.7209409 },
    { lat: 49.0573656, lng: 19.7211508 },
    { lat: 49.0572346, lng: 19.7212744 },
    { lat: 49.0562233, lng: 19.7222052 },
    { lat: 49.0562073, lng: 19.7221475 },
    { lat: 49.0559341, lng: 19.7213129 },
    { lat: 49.0557386, lng: 19.7206621 },
    { lat: 49.0554806, lng: 19.719813 },
    { lat: 49.0553016, lng: 19.7192529 },
    { lat: 49.0552655, lng: 19.7186085 },
    { lat: 49.0551887, lng: 19.7179637 },
    { lat: 49.0551099, lng: 19.7173258 },
    { lat: 49.0550319, lng: 19.7172156 },
    { lat: 49.0548329, lng: 19.7171999 },
    { lat: 49.0547792, lng: 19.716515 },
    { lat: 49.0547213, lng: 19.7158647 },
    { lat: 49.0546999, lng: 19.7155877 },
    { lat: 49.0546476, lng: 19.7150101 },
    { lat: 49.0546127, lng: 19.7145768 },
    { lat: 49.0547925, lng: 19.7145628 },
    { lat: 49.0547874, lng: 19.7142692 },
    { lat: 49.0544237, lng: 19.7142505 },
    { lat: 49.0538064, lng: 19.7142247 },
    { lat: 49.0533186, lng: 19.7142018 },
    { lat: 49.0526998, lng: 19.7141686 },
    { lat: 49.0524885, lng: 19.7140769 },
    { lat: 49.0525023, lng: 19.7135709 },
    { lat: 49.0525148, lng: 19.7132417 },
    { lat: 49.0524809, lng: 19.7132394 },
    { lat: 49.0522247, lng: 19.7131735 },
    { lat: 49.0514016, lng: 19.7129829 },
    { lat: 49.0508223, lng: 19.7127208 },
    { lat: 49.0505695, lng: 19.7126101 },
    { lat: 49.0504287, lng: 19.7137081 },
    { lat: 49.0503233, lng: 19.7136462 },
    { lat: 49.0500707, lng: 19.7135118 },
    { lat: 49.0496238, lng: 19.7132639 },
    { lat: 49.049576, lng: 19.7134824 },
    { lat: 49.0493743, lng: 19.7133029 },
    { lat: 49.0489427, lng: 19.7130179 },
    { lat: 49.0486617, lng: 19.7128292 },
    { lat: 49.0484738, lng: 19.712807 },
    { lat: 49.0481057, lng: 19.7123284 },
    { lat: 49.048045, lng: 19.712456 },
    { lat: 49.0478799, lng: 19.7123166 },
    { lat: 49.0477592, lng: 19.7125932 },
    { lat: 49.0475081, lng: 19.7131863 },
    { lat: 49.0471303, lng: 19.7140483 },
    { lat: 49.0470017, lng: 19.7143541 },
    { lat: 49.0469084, lng: 19.71436 },
    { lat: 49.0469, lng: 19.7143443 },
    { lat: 49.0468872, lng: 19.7141294 },
    { lat: 49.0469111, lng: 19.7140882 },
    { lat: 49.0469326, lng: 19.7140623 },
    { lat: 49.0470015, lng: 19.7140179 },
    { lat: 49.0469955, lng: 19.7138803 },
    { lat: 49.0469751, lng: 19.7137994 },
    { lat: 49.0469348, lng: 19.7137316 },
    { lat: 49.0470127, lng: 19.7136012 },
    { lat: 49.0470279, lng: 19.7135925 },
    { lat: 49.0470951, lng: 19.7136079 },
    { lat: 49.0471328, lng: 19.7134938 },
    { lat: 49.0471072, lng: 19.7132925 },
    { lat: 49.0470653, lng: 19.7132633 },
    { lat: 49.0470775, lng: 19.7131321 },
    { lat: 49.0471264, lng: 19.7130894 },
    { lat: 49.0471134, lng: 19.7129988 },
    { lat: 49.0470904, lng: 19.7129368 },
    { lat: 49.0470611, lng: 19.7128083 },
    { lat: 49.0470658, lng: 19.7127651 },
    { lat: 49.0470358, lng: 19.712747 },
    { lat: 49.0470006, lng: 19.712714 },
    { lat: 49.0469824, lng: 19.7124791 },
    { lat: 49.0469702, lng: 19.7124858 },
    { lat: 49.0469033, lng: 19.7125802 },
    { lat: 49.0465767, lng: 19.713066 },
    { lat: 49.0463837, lng: 19.7131593 },
    { lat: 49.0463454, lng: 19.7131854 },
    { lat: 49.0463053, lng: 19.7131978 },
    { lat: 49.0460249, lng: 19.7131976 },
    { lat: 49.0456139, lng: 19.7131285 },
    { lat: 49.0455377, lng: 19.7136232 },
    { lat: 49.0455281, lng: 19.7136637 },
    { lat: 49.0454437, lng: 19.7142024 },
    { lat: 49.0453953, lng: 19.7145341 },
    { lat: 49.045217, lng: 19.7154778 },
    { lat: 49.0449843, lng: 19.71672 },
    { lat: 49.0448176, lng: 19.7175892 },
    { lat: 49.0446827, lng: 19.7183122 },
    { lat: 49.0445874, lng: 19.7188132 },
    { lat: 49.0446034, lng: 19.7198433 },
  ],
  Malatíny: [
    { lat: 49.076502, lng: 19.4706428 },
    { lat: 49.0767647, lng: 19.4708923 },
    { lat: 49.0771864, lng: 19.4711883 },
    { lat: 49.077711, lng: 19.4713972 },
    { lat: 49.0784789, lng: 19.4715104 },
    { lat: 49.0788121, lng: 19.4715989 },
    { lat: 49.0797273, lng: 19.4717758 },
    { lat: 49.0799431, lng: 19.4708078 },
    { lat: 49.0803041, lng: 19.469716 },
    { lat: 49.0805209, lng: 19.4689937 },
    { lat: 49.0808158, lng: 19.4683996 },
    { lat: 49.0810785, lng: 19.4680132 },
    { lat: 49.0812556, lng: 19.4676754 },
    { lat: 49.0819091, lng: 19.4670632 },
    { lat: 49.082368, lng: 19.4666504 },
    { lat: 49.0825099, lng: 19.4664044 },
    { lat: 49.0827464, lng: 19.4662077 },
    { lat: 49.0833347, lng: 19.4659183 },
    { lat: 49.0834901, lng: 19.4659653 },
    { lat: 49.083614, lng: 19.4657885 },
    { lat: 49.0837862, lng: 19.4657148 },
    { lat: 49.0838807, lng: 19.465664 },
    { lat: 49.0839372, lng: 19.4656141 },
    { lat: 49.0840515, lng: 19.4656034 },
    { lat: 49.0847131, lng: 19.465459 },
    { lat: 49.0848672, lng: 19.4655493 },
    { lat: 49.0853966, lng: 19.4654484 },
    { lat: 49.0858528, lng: 19.4655399 },
    { lat: 49.0864343, lng: 19.4653954 },
    { lat: 49.0864681, lng: 19.4654669 },
    { lat: 49.0871786, lng: 19.4654312 },
    { lat: 49.0877523, lng: 19.4654591 },
    { lat: 49.088076, lng: 19.4655151 },
    { lat: 49.0881516, lng: 19.4655167 },
    { lat: 49.0883469, lng: 19.4655533 },
    { lat: 49.088555, lng: 19.4654678 },
    { lat: 49.0894041, lng: 19.4658513 },
    { lat: 49.0905523, lng: 19.4655779 },
    { lat: 49.0909166, lng: 19.4653919 },
    { lat: 49.0910609, lng: 19.4655149 },
    { lat: 49.0909994, lng: 19.4652579 },
    { lat: 49.0908307, lng: 19.4650653 },
    { lat: 49.0900715, lng: 19.464609 },
    { lat: 49.0900547, lng: 19.4644907 },
    { lat: 49.0900736, lng: 19.4643883 },
    { lat: 49.0900898, lng: 19.4639559 },
    { lat: 49.0900622, lng: 19.463526 },
    { lat: 49.0900211, lng: 19.4634185 },
    { lat: 49.0895691, lng: 19.4632231 },
    { lat: 49.089392, lng: 19.4630954 },
    { lat: 49.0892459, lng: 19.4629334 },
    { lat: 49.0888299, lng: 19.4623146 },
    { lat: 49.0888018, lng: 19.4623197 },
    { lat: 49.0887541, lng: 19.4621149 },
    { lat: 49.088768, lng: 19.4617312 },
    { lat: 49.0889036, lng: 19.4607133 },
    { lat: 49.0884576, lng: 19.4605259 },
    { lat: 49.087994, lng: 19.4608818 },
    { lat: 49.0877574, lng: 19.4608575 },
    { lat: 49.0876804, lng: 19.4608255 },
    { lat: 49.0875118, lng: 19.4606609 },
    { lat: 49.0870304, lng: 19.4599977 },
    { lat: 49.0867486, lng: 19.4595101 },
    { lat: 49.0862734, lng: 19.4590523 },
    { lat: 49.0847911, lng: 19.4578952 },
    { lat: 49.0840208, lng: 19.4577817 },
    { lat: 49.0822215, lng: 19.4567391 },
    { lat: 49.0817539, lng: 19.4568133 },
    { lat: 49.0807245, lng: 19.4570924 },
    { lat: 49.0804184, lng: 19.4587381 },
    { lat: 49.0794331, lng: 19.458534 },
    { lat: 49.0788952, lng: 19.4581096 },
    { lat: 49.0790469, lng: 19.4573468 },
    { lat: 49.0765897, lng: 19.4550603 },
    { lat: 49.0760933, lng: 19.4546828 },
    { lat: 49.0754288, lng: 19.4544003 },
    { lat: 49.0749688, lng: 19.4539295 },
    { lat: 49.0744705, lng: 19.4534121 },
    { lat: 49.0740539, lng: 19.4532989 },
    { lat: 49.0725994, lng: 19.4536685 },
    { lat: 49.0717695, lng: 19.4537057 },
    { lat: 49.0708079, lng: 19.4514837 },
    { lat: 49.0693174, lng: 19.4515841 },
    { lat: 49.0674862, lng: 19.4517087 },
    { lat: 49.066192, lng: 19.4517698 },
    { lat: 49.0655542, lng: 19.4520616 },
    { lat: 49.0652532, lng: 19.4520827 },
    { lat: 49.0645323, lng: 19.4520176 },
    { lat: 49.0637318, lng: 19.4519815 },
    { lat: 49.0634749, lng: 19.4513993 },
    { lat: 49.063533, lng: 19.4521904 },
    { lat: 49.063103, lng: 19.4538549 },
    { lat: 49.0630801, lng: 19.4549491 },
    { lat: 49.0630423, lng: 19.4565662 },
    { lat: 49.0630816, lng: 19.4568301 },
    { lat: 49.0630356, lng: 19.4570563 },
    { lat: 49.0629778, lng: 19.4574862 },
    { lat: 49.0629543, lng: 19.4577348 },
    { lat: 49.062868, lng: 19.4579403 },
    { lat: 49.0624069, lng: 19.457543 },
    { lat: 49.0624073, lng: 19.4574563 },
    { lat: 49.0623208, lng: 19.4572191 },
    { lat: 49.0622346, lng: 19.457086 },
    { lat: 49.0621506, lng: 19.4570975 },
    { lat: 49.0620146, lng: 19.457005 },
    { lat: 49.0619701, lng: 19.4569221 },
    { lat: 49.0618019, lng: 19.4569149 },
    { lat: 49.0616681, lng: 19.4568954 },
    { lat: 49.0615258, lng: 19.4568625 },
    { lat: 49.0614881, lng: 19.4567555 },
    { lat: 49.0615342, lng: 19.4565849 },
    { lat: 49.0615113, lng: 19.4565288 },
    { lat: 49.0613339, lng: 19.4565625 },
    { lat: 49.061193, lng: 19.4564414 },
    { lat: 49.0608573, lng: 19.4559574 },
    { lat: 49.0605766, lng: 19.4555401 },
    { lat: 49.0605276, lng: 19.4554767 },
    { lat: 49.0601251, lng: 19.4549884 },
    { lat: 49.0600521, lng: 19.4548931 },
    { lat: 49.0598937, lng: 19.4546862 },
    { lat: 49.0597856, lng: 19.4544423 },
    { lat: 49.0594459, lng: 19.4537248 },
    { lat: 49.059384, lng: 19.453509 },
    { lat: 49.0593962, lng: 19.4531149 },
    { lat: 49.0593675, lng: 19.4529337 },
    { lat: 49.0592722, lng: 19.4526748 },
    { lat: 49.0591528, lng: 19.4525036 },
    { lat: 49.0590843, lng: 19.4524879 },
    { lat: 49.0589586, lng: 19.4523278 },
    { lat: 49.0589135, lng: 19.4522504 },
    { lat: 49.0587184, lng: 19.4522239 },
    { lat: 49.058622, lng: 19.4522335 },
    { lat: 49.0586054, lng: 19.4521716 },
    { lat: 49.058698, lng: 19.4520539 },
    { lat: 49.0586936, lng: 19.45203 },
    { lat: 49.0586241, lng: 19.4519429 },
    { lat: 49.058537, lng: 19.4517837 },
    { lat: 49.0584947, lng: 19.4515292 },
    { lat: 49.0584703, lng: 19.4515368 },
    { lat: 49.0584187, lng: 19.4515909 },
    { lat: 49.0583694, lng: 19.4516745 },
    { lat: 49.0583228, lng: 19.4516867 },
    { lat: 49.0582789, lng: 19.4516152 },
    { lat: 49.0581643, lng: 19.4515994 },
    { lat: 49.0580278, lng: 19.4516902 },
    { lat: 49.057923, lng: 19.4514729 },
    { lat: 49.0579166, lng: 19.4514316 },
    { lat: 49.0579968, lng: 19.4513166 },
    { lat: 49.0579743, lng: 19.4512447 },
    { lat: 49.0578885, lng: 19.4511655 },
    { lat: 49.057754, lng: 19.4509625 },
    { lat: 49.0576006, lng: 19.4508377 },
    { lat: 49.0574759, lng: 19.4507239 },
    { lat: 49.0574057, lng: 19.4505967 },
    { lat: 49.0573112, lng: 19.4505734 },
    { lat: 49.057136, lng: 19.4506866 },
    { lat: 49.057022, lng: 19.4506583 },
    { lat: 49.0569949, lng: 19.4505825 },
    { lat: 49.0570301, lng: 19.4504903 },
    { lat: 49.0570098, lng: 19.4504142 },
    { lat: 49.0569333, lng: 19.4503695 },
    { lat: 49.0568569, lng: 19.4504011 },
    { lat: 49.056771, lng: 19.450296 },
    { lat: 49.0567306, lng: 19.4501513 },
    { lat: 49.056718, lng: 19.4499298 },
    { lat: 49.0566315, lng: 19.4498162 },
    { lat: 49.0565039, lng: 19.449836 },
    { lat: 49.0564655, lng: 19.449806 },
    { lat: 49.0563778, lng: 19.449667 },
    { lat: 49.0562437, lng: 19.4493979 },
    { lat: 49.0561638, lng: 19.4493497 },
    { lat: 49.0560922, lng: 19.4494105 },
    { lat: 49.055994, lng: 19.44936 },
    { lat: 49.0559599, lng: 19.4493058 },
    { lat: 49.0560237, lng: 19.4490004 },
    { lat: 49.0560086, lng: 19.4489448 },
    { lat: 49.055779, lng: 19.4486824 },
    { lat: 49.0557574, lng: 19.4487019 },
    { lat: 49.0557035, lng: 19.4488539 },
    { lat: 49.055582, lng: 19.4489528 },
    { lat: 49.0555675, lng: 19.4490058 },
    { lat: 49.0554966, lng: 19.4488873 },
    { lat: 49.0554299, lng: 19.4486874 },
    { lat: 49.0552287, lng: 19.4484309 },
    { lat: 49.0551825, lng: 19.4483349 },
    { lat: 49.0550948, lng: 19.4483389 },
    { lat: 49.0549794, lng: 19.4482546 },
    { lat: 49.0548827, lng: 19.4481389 },
    { lat: 49.0547512, lng: 19.4481199 },
    { lat: 49.0545901, lng: 19.4481221 },
    { lat: 49.0545245, lng: 19.448164 },
    { lat: 49.0544815, lng: 19.4481381 },
    { lat: 49.0544721, lng: 19.4479357 },
    { lat: 49.0544369, lng: 19.4478281 },
    { lat: 49.0543127, lng: 19.4476815 },
    { lat: 49.0542442, lng: 19.4476849 },
    { lat: 49.0541269, lng: 19.4477783 },
    { lat: 49.0540133, lng: 19.44764 },
    { lat: 49.0539209, lng: 19.4476136 },
    { lat: 49.0538582, lng: 19.4476976 },
    { lat: 49.0537549, lng: 19.4477769 },
    { lat: 49.0537439, lng: 19.447855 },
    { lat: 49.0536594, lng: 19.4478047 },
    { lat: 49.0536506, lng: 19.4477374 },
    { lat: 49.0536191, lng: 19.4477156 },
    { lat: 49.053555, lng: 19.4478648 },
    { lat: 49.0535075, lng: 19.447937 },
    { lat: 49.0534262, lng: 19.4479048 },
    { lat: 49.0533782, lng: 19.4478205 },
    { lat: 49.0532471, lng: 19.4476423 },
    { lat: 49.0530992, lng: 19.4475835 },
    { lat: 49.0530358, lng: 19.4476517 },
    { lat: 49.0527808, lng: 19.4480814 },
    { lat: 49.0527431, lng: 19.4480977 },
    { lat: 49.0525544, lng: 19.4480122 },
    { lat: 49.0524375, lng: 19.4479199 },
    { lat: 49.0523401, lng: 19.447912 },
    { lat: 49.0522085, lng: 19.4481086 },
    { lat: 49.0520543, lng: 19.4481384 },
    { lat: 49.0519647, lng: 19.4480758 },
    { lat: 49.0519484, lng: 19.4480395 },
    { lat: 49.0519168, lng: 19.4477493 },
    { lat: 49.0518558, lng: 19.4477695 },
    { lat: 49.0518223, lng: 19.4477993 },
    { lat: 49.0517983, lng: 19.4478439 },
    { lat: 49.051819, lng: 19.4478974 },
    { lat: 49.0517528, lng: 19.4479782 },
    { lat: 49.0516453, lng: 19.4477978 },
    { lat: 49.0515387, lng: 19.4477335 },
    { lat: 49.051407, lng: 19.447934 },
    { lat: 49.0506627, lng: 19.4479434 },
    { lat: 49.0499748, lng: 19.4479082 },
    { lat: 49.0484492, lng: 19.447844 },
    { lat: 49.0483196, lng: 19.4478891 },
    { lat: 49.0482637, lng: 19.4479993 },
    { lat: 49.0482519, lng: 19.4480602 },
    { lat: 49.0482351, lng: 19.4480591 },
    { lat: 49.0481441, lng: 19.4484302 },
    { lat: 49.047613, lng: 19.4483779 },
    { lat: 49.0476701, lng: 19.4481207 },
    { lat: 49.0475279, lng: 19.447998 },
    { lat: 49.0473822, lng: 19.4482838 },
    { lat: 49.0472839, lng: 19.4481797 },
    { lat: 49.0470373, lng: 19.448352 },
    { lat: 49.0464859, lng: 19.4485142 },
    { lat: 49.0462511, lng: 19.4481923 },
    { lat: 49.0460989, lng: 19.4481899 },
    { lat: 49.0460642, lng: 19.4485105 },
    { lat: 49.0459473, lng: 19.4486383 },
    { lat: 49.0458071, lng: 19.4482876 },
    { lat: 49.0455182, lng: 19.4482794 },
    { lat: 49.0451211, lng: 19.4483163 },
    { lat: 49.0448524, lng: 19.4484295 },
    { lat: 49.0445625, lng: 19.4484972 },
    { lat: 49.0444091, lng: 19.4482499 },
    { lat: 49.0441394, lng: 19.4484588 },
    { lat: 49.0438489, lng: 19.4486347 },
    { lat: 49.0436884, lng: 19.4484083 },
    { lat: 49.0432942, lng: 19.4486291 },
    { lat: 49.0429931, lng: 19.4490126 },
    { lat: 49.0426967, lng: 19.4491848 },
    { lat: 49.042378, lng: 19.4495024 },
    { lat: 49.0419371, lng: 19.4498136 },
    { lat: 49.0417794, lng: 19.450278 },
    { lat: 49.0413287, lng: 19.45079 },
    { lat: 49.0411014, lng: 19.4513837 },
    { lat: 49.0409391, lng: 19.4514082 },
    { lat: 49.0407879, lng: 19.4517674 },
    { lat: 49.0407549, lng: 19.4520034 },
    { lat: 49.0414816, lng: 19.4540484 },
    { lat: 49.04179, lng: 19.4546702 },
    { lat: 49.0422074, lng: 19.4550217 },
    { lat: 49.0428299, lng: 19.455181 },
    { lat: 49.0490412, lng: 19.4571524 },
    { lat: 49.049347, lng: 19.457189 },
    { lat: 49.0499237, lng: 19.457354 },
    { lat: 49.0506625, lng: 19.4575358 },
    { lat: 49.050863, lng: 19.4576092 },
    { lat: 49.0517398, lng: 19.4580284 },
    { lat: 49.0519402, lng: 19.4581173 },
    { lat: 49.0523853, lng: 19.4582672 },
    { lat: 49.0527713, lng: 19.4583765 },
    { lat: 49.0542526, lng: 19.4592423 },
    { lat: 49.055832, lng: 19.4601129 },
    { lat: 49.0568119, lng: 19.4607608 },
    { lat: 49.0570469, lng: 19.4611873 },
    { lat: 49.0575256, lng: 19.4609888 },
    { lat: 49.0582224, lng: 19.4608762 },
    { lat: 49.0588597, lng: 19.460549 },
    { lat: 49.0597386, lng: 19.4602779 },
    { lat: 49.0600847, lng: 19.4601597 },
    { lat: 49.0602512, lng: 19.4601552 },
    { lat: 49.0603025, lng: 19.46014 },
    { lat: 49.0610121, lng: 19.4599876 },
    { lat: 49.0610505, lng: 19.4601584 },
    { lat: 49.061155, lng: 19.4600596 },
    { lat: 49.0614065, lng: 19.4599382 },
    { lat: 49.0615921, lng: 19.4598867 },
    { lat: 49.0616096, lng: 19.4598 },
    { lat: 49.0616964, lng: 19.459752 },
    { lat: 49.0619622, lng: 19.4593556 },
    { lat: 49.0621832, lng: 19.45907 },
    { lat: 49.0622778, lng: 19.4590178 },
    { lat: 49.0623788, lng: 19.4590559 },
    { lat: 49.0624448, lng: 19.4589507 },
    { lat: 49.0625076, lng: 19.4588703 },
    { lat: 49.0625166, lng: 19.4589416 },
    { lat: 49.0625605, lng: 19.4589671 },
    { lat: 49.0625601, lng: 19.45903 },
    { lat: 49.0625531, lng: 19.4590732 },
    { lat: 49.0625623, lng: 19.4591538 },
    { lat: 49.0626096, lng: 19.4591767 },
    { lat: 49.0626799, lng: 19.4592371 },
    { lat: 49.0627516, lng: 19.4591985 },
    { lat: 49.0628587, lng: 19.4592259 },
    { lat: 49.0629648, lng: 19.459202 },
    { lat: 49.0630928, lng: 19.4589526 },
    { lat: 49.0631316, lng: 19.4589128 },
    { lat: 49.0630435, lng: 19.4586628 },
    { lat: 49.0631556, lng: 19.4585286 },
    { lat: 49.0632128, lng: 19.458791 },
    { lat: 49.0631908, lng: 19.4588515 },
    { lat: 49.063208, lng: 19.4588849 },
    { lat: 49.0631755, lng: 19.4591575 },
    { lat: 49.0631925, lng: 19.4592815 },
    { lat: 49.0631511, lng: 19.4594077 },
    { lat: 49.0631921, lng: 19.4597576 },
    { lat: 49.0632943, lng: 19.4597765 },
    { lat: 49.0635174, lng: 19.4599449 },
    { lat: 49.0636896, lng: 19.4597479 },
    { lat: 49.0637598, lng: 19.4598259 },
    { lat: 49.0636911, lng: 19.4601481 },
    { lat: 49.0636657, lng: 19.4602313 },
    { lat: 49.063534, lng: 19.4608411 },
    { lat: 49.0635118, lng: 19.4613635 },
    { lat: 49.0634533, lng: 19.4616554 },
    { lat: 49.0633163, lng: 19.462765 },
    { lat: 49.063324, lng: 19.4632482 },
    { lat: 49.0633949, lng: 19.4639564 },
    { lat: 49.0634434, lng: 19.4647159 },
    { lat: 49.0626009, lng: 19.4662357 },
    { lat: 49.0627786, lng: 19.4668412 },
    { lat: 49.0639841, lng: 19.4692559 },
    { lat: 49.0640277, lng: 19.4693447 },
    { lat: 49.0645616, lng: 19.4692183 },
    { lat: 49.0651097, lng: 19.4691059 },
    { lat: 49.0658033, lng: 19.4688974 },
    { lat: 49.0667881, lng: 19.4685073 },
    { lat: 49.0678035, lng: 19.4680208 },
    { lat: 49.068316, lng: 19.4677925 },
    { lat: 49.0688025, lng: 19.4677125 },
    { lat: 49.0692979, lng: 19.4677279 },
    { lat: 49.070215, lng: 19.4677726 },
    { lat: 49.0707176, lng: 19.4678738 },
    { lat: 49.0711757, lng: 19.4680315 },
    { lat: 49.0719637, lng: 19.468237 },
    { lat: 49.0722553, lng: 19.4683299 },
    { lat: 49.0727326, lng: 19.4685614 },
    { lat: 49.0734078, lng: 19.4689116 },
    { lat: 49.0746497, lng: 19.4692067 },
    { lat: 49.0751587, lng: 19.4694923 },
    { lat: 49.0754986, lng: 19.4697511 },
    { lat: 49.0759937, lng: 19.4701795 },
    { lat: 49.0760681, lng: 19.4702503 },
    { lat: 49.076502, lng: 19.4706428 },
  ],
  Malužiná: [
    { lat: 48.9786141, lng: 19.7730509 },
    { lat: 48.9786528, lng: 19.7732464 },
    { lat: 48.9786733, lng: 19.7734559 },
    { lat: 48.9786761, lng: 19.7736504 },
    { lat: 48.9786585, lng: 19.773839 },
    { lat: 48.9786159, lng: 19.7742091 },
    { lat: 48.9785254, lng: 19.7747673 },
    { lat: 48.9784798, lng: 19.7751929 },
    { lat: 48.9784713, lng: 19.7754928 },
    { lat: 48.9782915, lng: 19.7755048 },
    { lat: 48.9782907, lng: 19.775535 },
    { lat: 48.9780911, lng: 19.7755332 },
    { lat: 48.978049, lng: 19.7754707 },
    { lat: 48.9779365, lng: 19.7753974 },
    { lat: 48.977867, lng: 19.7756396 },
    { lat: 48.9778575, lng: 19.775883 },
    { lat: 48.9778315, lng: 19.7758824 },
    { lat: 48.9777798, lng: 19.7759902 },
    { lat: 48.9776448, lng: 19.7764574 },
    { lat: 48.9775791, lng: 19.776814 },
    { lat: 48.9775019, lng: 19.7770312 },
    { lat: 48.9775276, lng: 19.7772348 },
    { lat: 48.9775904, lng: 19.7773325 },
    { lat: 48.9778131, lng: 19.7775304 },
    { lat: 48.978011, lng: 19.7775481 },
    { lat: 48.9779681, lng: 19.7777296 },
    { lat: 48.9779772, lng: 19.7778087 },
    { lat: 48.9779021, lng: 19.7778126 },
    { lat: 48.9778904, lng: 19.778091 },
    { lat: 48.9779124, lng: 19.7783911 },
    { lat: 48.977956, lng: 19.7786127 },
    { lat: 48.9780024, lng: 19.778773 },
    { lat: 48.9779795, lng: 19.7790285 },
    { lat: 48.9784084, lng: 19.7793301 },
    { lat: 48.9783079, lng: 19.7795055 },
    { lat: 48.9780812, lng: 19.7798076 },
    { lat: 48.9777614, lng: 19.7800987 },
    { lat: 48.9775582, lng: 19.7801912 },
    { lat: 48.9774163, lng: 19.7802448 },
    { lat: 48.9769891, lng: 19.7803444 },
    { lat: 48.9768972, lng: 19.7803805 },
    { lat: 48.9768061, lng: 19.7805388 },
    { lat: 48.9764777, lng: 19.7810699 },
    { lat: 48.976194, lng: 19.7815384 },
    { lat: 48.9759534, lng: 19.7818645 },
    { lat: 48.9756468, lng: 19.7822725 },
    { lat: 48.9754299, lng: 19.7826751 },
    { lat: 48.9750849, lng: 19.7832103 },
    { lat: 48.9747259, lng: 19.7837398 },
    { lat: 48.9743709, lng: 19.7842301 },
    { lat: 48.9742728, lng: 19.7843572 },
    { lat: 48.9741767, lng: 19.7844532 },
    { lat: 48.9739264, lng: 19.7846523 },
    { lat: 48.9738464, lng: 19.784756 },
    { lat: 48.9738055, lng: 19.7848534 },
    { lat: 48.9737371, lng: 19.785169 },
    { lat: 48.9736088, lng: 19.7858467 },
    { lat: 48.9735041, lng: 19.7861863 },
    { lat: 48.9734318, lng: 19.7863919 },
    { lat: 48.9733875, lng: 19.7864856 },
    { lat: 48.973126, lng: 19.7869751 },
    { lat: 48.9729734, lng: 19.787354 },
    { lat: 48.9728585, lng: 19.7876646 },
    { lat: 48.9727285, lng: 19.7880976 },
    { lat: 48.9724067, lng: 19.7888577 },
    { lat: 48.9723334, lng: 19.7890612 },
    { lat: 48.9723063, lng: 19.789192 },
    { lat: 48.9723773, lng: 19.7902029 },
    { lat: 48.972378, lng: 19.7904807 },
    { lat: 48.9723154, lng: 19.7906967 },
    { lat: 48.9721844, lng: 19.7909075 },
    { lat: 48.9717344, lng: 19.791825 },
    { lat: 48.9715074, lng: 19.7922737 },
    { lat: 48.9712475, lng: 19.7928038 },
    { lat: 48.9710948, lng: 19.7932559 },
    { lat: 48.9709313, lng: 19.7935139 },
    { lat: 48.9707949, lng: 19.7937972 },
    { lat: 48.9706553, lng: 19.7942913 },
    { lat: 48.9706201, lng: 19.7948298 },
    { lat: 48.9705938, lng: 19.7958078 },
    { lat: 48.9705654, lng: 19.7960885 },
    { lat: 48.9705309, lng: 19.7962307 },
    { lat: 48.9703264, lng: 19.7967073 },
    { lat: 48.9702099, lng: 19.7968308 },
    { lat: 48.9701881, lng: 19.7969 },
    { lat: 48.9700707, lng: 19.7974102 },
    { lat: 48.9700226, lng: 19.7977051 },
    { lat: 48.9699899, lng: 19.7979633 },
    { lat: 48.9698978, lng: 19.7983332 },
    { lat: 48.9698405, lng: 19.7985469 },
    { lat: 48.9698148, lng: 19.7989403 },
    { lat: 48.9696954, lng: 19.7994367 },
    { lat: 48.9695353, lng: 19.7997463 },
    { lat: 48.9694433, lng: 19.7998566 },
    { lat: 48.9691286, lng: 19.8001613 },
    { lat: 48.9689546, lng: 19.8004889 },
    { lat: 48.9686148, lng: 19.8013306 },
    { lat: 48.9683429, lng: 19.8020738 },
    { lat: 48.9681812, lng: 19.802422 },
    { lat: 48.9681413, lng: 19.8025708 },
    { lat: 48.9681225, lng: 19.8026792 },
    { lat: 48.9680711, lng: 19.8028231 },
    { lat: 48.9680066, lng: 19.8030497 },
    { lat: 48.9674885, lng: 19.8038325 },
    { lat: 48.9673598, lng: 19.804267 },
    { lat: 48.9672806, lng: 19.8046507 },
    { lat: 48.967131, lng: 19.804969 },
    { lat: 48.9670358, lng: 19.8051104 },
    { lat: 48.9667071, lng: 19.8058987 },
    { lat: 48.966625, lng: 19.8060309 },
    { lat: 48.9665495, lng: 19.806107 },
    { lat: 48.9662077, lng: 19.8065677 },
    { lat: 48.9658608, lng: 19.8068261 },
    { lat: 48.9655974, lng: 19.8071223 },
    { lat: 48.9655073, lng: 19.8073027 },
    { lat: 48.965433, lng: 19.8075898 },
    { lat: 48.9652208, lng: 19.8081516 },
    { lat: 48.9646468, lng: 19.8086603 },
    { lat: 48.9643086, lng: 19.808784 },
    { lat: 48.9638944, lng: 19.8089225 },
    { lat: 48.9629385, lng: 19.8090942 },
    { lat: 48.9626585, lng: 19.809154 },
    { lat: 48.9624044, lng: 19.8092174 },
    { lat: 48.9622715, lng: 19.809245 },
    { lat: 48.9620522, lng: 19.8093608 },
    { lat: 48.9618095, lng: 19.8095076 },
    { lat: 48.9617108, lng: 19.8095393 },
    { lat: 48.9615374, lng: 19.8095695 },
    { lat: 48.9610866, lng: 19.8096152 },
    { lat: 48.9609408, lng: 19.8097373 },
    { lat: 48.9608598, lng: 19.8098185 },
    { lat: 48.9607297, lng: 19.8100397 },
    { lat: 48.9605183, lng: 19.8105953 },
    { lat: 48.9603172, lng: 19.810814 },
    { lat: 48.9602482, lng: 19.8109105 },
    { lat: 48.9600209, lng: 19.8113637 },
    { lat: 48.9598957, lng: 19.811487 },
    { lat: 48.9598183, lng: 19.8115219 },
    { lat: 48.9597105, lng: 19.8115069 },
    { lat: 48.9595177, lng: 19.8113995 },
    { lat: 48.959092, lng: 19.8113212 },
    { lat: 48.9589013, lng: 19.8112065 },
    { lat: 48.958617, lng: 19.8111502 },
    { lat: 48.9584932, lng: 19.8111489 },
    { lat: 48.958358, lng: 19.8112255 },
    { lat: 48.9582176, lng: 19.8112667 },
    { lat: 48.9580824, lng: 19.8112393 },
    { lat: 48.9579058, lng: 19.8111691 },
    { lat: 48.9577814, lng: 19.811103 },
    { lat: 48.9575772, lng: 19.8110165 },
    { lat: 48.9574489, lng: 19.8109967 },
    { lat: 48.9572637, lng: 19.8109345 },
    { lat: 48.9569989, lng: 19.8107993 },
    { lat: 48.9568212, lng: 19.810756 },
    { lat: 48.956596, lng: 19.8107573 },
    { lat: 48.9564966, lng: 19.8107504 },
    { lat: 48.9564341, lng: 19.8106602 },
    { lat: 48.9562752, lng: 19.8106305 },
    { lat: 48.9559416, lng: 19.8106297 },
    { lat: 48.9556248, lng: 19.8107011 },
    { lat: 48.9550077, lng: 19.8108648 },
    { lat: 48.9544272, lng: 19.8110944 },
    { lat: 48.9539826, lng: 19.8112821 },
    { lat: 48.9538205, lng: 19.8111047 },
    { lat: 48.9533444, lng: 19.8105636 },
    { lat: 48.9532098, lng: 19.8102916 },
    { lat: 48.9529371, lng: 19.8099776 },
    { lat: 48.9527462, lng: 19.8097282 },
    { lat: 48.9524086, lng: 19.8091665 },
    { lat: 48.952134, lng: 19.8088096 },
    { lat: 48.9520498, lng: 19.8086875 },
    { lat: 48.9518809, lng: 19.8083237 },
    { lat: 48.9516511, lng: 19.8078527 },
    { lat: 48.9513622, lng: 19.8073231 },
    { lat: 48.951261, lng: 19.807221 },
    { lat: 48.9511557, lng: 19.8071562 },
    { lat: 48.951, lng: 19.8070994 },
    { lat: 48.9506911, lng: 19.8070424 },
    { lat: 48.9503134, lng: 19.8070621 },
    { lat: 48.9498739, lng: 19.8070314 },
    { lat: 48.9491934, lng: 19.8070832 },
    { lat: 48.9489312, lng: 19.8070613 },
    { lat: 48.948858, lng: 19.8070399 },
    { lat: 48.9484766, lng: 19.8067644 },
    { lat: 48.9483233, lng: 19.8066044 },
    { lat: 48.9479466, lng: 19.8064635 },
    { lat: 48.9471946, lng: 19.8064765 },
    { lat: 48.9469062, lng: 19.806658 },
    { lat: 48.9467371, lng: 19.8068358 },
    { lat: 48.9465578, lng: 19.8068612 },
    { lat: 48.9464117, lng: 19.8069478 },
    { lat: 48.9460758, lng: 19.8069935 },
    { lat: 48.9457899, lng: 19.8071288 },
    { lat: 48.9454923, lng: 19.8073105 },
    { lat: 48.9452907, lng: 19.8073327 },
    { lat: 48.9451563, lng: 19.8075368 },
    { lat: 48.9448646, lng: 19.8077698 },
    { lat: 48.9445593, lng: 19.8080568 },
    { lat: 48.9442847, lng: 19.808038 },
    { lat: 48.9439558, lng: 19.8080647 },
    { lat: 48.9435421, lng: 19.8080841 },
    { lat: 48.9431996, lng: 19.8080577 },
    { lat: 48.9425128, lng: 19.8079635 },
    { lat: 48.9424387, lng: 19.807968 },
    { lat: 48.9419764, lng: 19.808065 },
    { lat: 48.9416464, lng: 19.8081965 },
    { lat: 48.9413933, lng: 19.8084145 },
    { lat: 48.94116, lng: 19.8087237 },
    { lat: 48.9408875, lng: 19.8089838 },
    { lat: 48.940707, lng: 19.8091115 },
    { lat: 48.9404953, lng: 19.8093189 },
    { lat: 48.9403117, lng: 19.8094236 },
    { lat: 48.9402509, lng: 19.8094763 },
    { lat: 48.9401291, lng: 19.8096278 },
    { lat: 48.9399901, lng: 19.8098545 },
    { lat: 48.9397344, lng: 19.8100108 },
    { lat: 48.939634, lng: 19.8101136 },
    { lat: 48.9394233, lng: 19.8103453 },
    { lat: 48.9393561, lng: 19.8104069 },
    { lat: 48.9392818, lng: 19.8104354 },
    { lat: 48.939202, lng: 19.810414 },
    { lat: 48.9391068, lng: 19.8105317 },
    { lat: 48.9387954, lng: 19.8106226 },
    { lat: 48.938452, lng: 19.8107568 },
    { lat: 48.9383056, lng: 19.8108652 },
    { lat: 48.9380536, lng: 19.8109767 },
    { lat: 48.9379872, lng: 19.8109522 },
    { lat: 48.9375287, lng: 19.8112361 },
    { lat: 48.9373504, lng: 19.8113095 },
    { lat: 48.9369137, lng: 19.811421 },
    { lat: 48.9367042, lng: 19.8114491 },
    { lat: 48.9363407, lng: 19.8113796 },
    { lat: 48.9362108, lng: 19.8113721 },
    { lat: 48.9356879, lng: 19.8113938 },
    { lat: 48.9355405, lng: 19.8113436 },
    { lat: 48.935112, lng: 19.8110742 },
    { lat: 48.9349922, lng: 19.8109901 },
    { lat: 48.9346661, lng: 19.8106894 },
    { lat: 48.934574, lng: 19.8105904 },
    { lat: 48.9343942, lng: 19.8103123 },
    { lat: 48.93416, lng: 19.8100841 },
    { lat: 48.933889, lng: 19.8098593 },
    { lat: 48.9338104, lng: 19.8098169 },
    { lat: 48.9337075, lng: 19.8098053 },
    { lat: 48.933544, lng: 19.8098292 },
    { lat: 48.9333455, lng: 19.809826 },
    { lat: 48.9329738, lng: 19.8097455 },
    { lat: 48.9325177, lng: 19.8097229 },
    { lat: 48.9323606, lng: 19.8096325 },
    { lat: 48.9320242, lng: 19.8095134 },
    { lat: 48.9319102, lng: 19.8094807 },
    { lat: 48.9315114, lng: 19.8095344 },
    { lat: 48.9310567, lng: 19.809703 },
    { lat: 48.9308376, lng: 19.8097664 },
    { lat: 48.9305379, lng: 19.8098178 },
    { lat: 48.9300634, lng: 19.809812 },
    { lat: 48.9296662, lng: 19.8097431 },
    { lat: 48.9294883, lng: 19.8096963 },
    { lat: 48.9292962, lng: 19.8096538 },
    { lat: 48.9291171, lng: 19.8096025 },
    { lat: 48.9289485, lng: 19.8095631 },
    { lat: 48.9288341, lng: 19.8095215 },
    { lat: 48.9287177, lng: 19.8094618 },
    { lat: 48.9285587, lng: 19.8093003 },
    { lat: 48.9282858, lng: 19.8088797 },
    { lat: 48.928184, lng: 19.8087626 },
    { lat: 48.9279917, lng: 19.808564 },
    { lat: 48.9276991, lng: 19.8083112 },
    { lat: 48.9275425, lng: 19.8082039 },
    { lat: 48.9273381, lng: 19.8079613 },
    { lat: 48.9272645, lng: 19.8079006 },
    { lat: 48.9268823, lng: 19.8075514 },
    { lat: 48.9266431, lng: 19.8073653 },
    { lat: 48.9265612, lng: 19.807294 },
    { lat: 48.9263688, lng: 19.8071699 },
    { lat: 48.9260751, lng: 19.8069953 },
    { lat: 48.925893, lng: 19.8068988 },
    { lat: 48.9256265, lng: 19.8067305 },
    { lat: 48.9253641, lng: 19.8065478 },
    { lat: 48.9248989, lng: 19.806213 },
    { lat: 48.9247167, lng: 19.8061416 },
    { lat: 48.9245928, lng: 19.8060605 },
    { lat: 48.9242476, lng: 19.8058692 },
    { lat: 48.9239386, lng: 19.8057607 },
    { lat: 48.9233857, lng: 19.805607 },
    { lat: 48.923239, lng: 19.8055783 },
    { lat: 48.9231918, lng: 19.8056041 },
    { lat: 48.923007, lng: 19.8056064 },
    { lat: 48.9226837, lng: 19.8056025 },
    { lat: 48.9225928, lng: 19.8055582 },
    { lat: 48.9220465, lng: 19.8055636 },
    { lat: 48.9218038, lng: 19.8056011 },
    { lat: 48.9215862, lng: 19.8055984 },
    { lat: 48.9214539, lng: 19.8055615 },
    { lat: 48.9213678, lng: 19.8055267 },
    { lat: 48.9213042, lng: 19.8055398 },
    { lat: 48.9212492, lng: 19.805517 },
    { lat: 48.9211605, lng: 19.805422 },
    { lat: 48.9209445, lng: 19.8050337 },
    { lat: 48.9209262, lng: 19.8049274 },
    { lat: 48.9208441, lng: 19.8048525 },
    { lat: 48.920805, lng: 19.8047296 },
    { lat: 48.9206329, lng: 19.8044741 },
    { lat: 48.9204097, lng: 19.8042363 },
    { lat: 48.9202587, lng: 19.8039007 },
    { lat: 48.9201529, lng: 19.8034574 },
    { lat: 48.920086, lng: 19.8030307 },
    { lat: 48.9200429, lng: 19.8028426 },
    { lat: 48.9198591, lng: 19.8019848 },
    { lat: 48.9197714, lng: 19.8018015 },
    { lat: 48.9197789, lng: 19.8016385 },
    { lat: 48.9197393, lng: 19.8015078 },
    { lat: 48.9197062, lng: 19.8012632 },
    { lat: 48.9196585, lng: 19.8012013 },
    { lat: 48.919612, lng: 19.8010398 },
    { lat: 48.919485, lng: 19.8008382 },
    { lat: 48.9194985, lng: 19.800757 },
    { lat: 48.9193404, lng: 19.8004382 },
    { lat: 48.919302, lng: 19.8002819 },
    { lat: 48.9192149, lng: 19.8002208 },
    { lat: 48.9191273, lng: 19.7999659 },
    { lat: 48.9190553, lng: 19.7998882 },
    { lat: 48.9188098, lng: 19.7997096 },
    { lat: 48.9186891, lng: 19.7996805 },
    { lat: 48.9184003, lng: 19.7996728 },
    { lat: 48.9178703, lng: 19.7997662 },
    { lat: 48.917717, lng: 19.7997632 },
    { lat: 48.9175021, lng: 19.7997678 },
    { lat: 48.9172682, lng: 19.7996758 },
    { lat: 48.9169644, lng: 19.799632 },
    { lat: 48.9168654, lng: 19.7996355 },
    { lat: 48.9167736, lng: 19.7995923 },
    { lat: 48.9165147, lng: 19.799654 },
    { lat: 48.9163243, lng: 19.799625 },
    { lat: 48.9162214, lng: 19.799592 },
    { lat: 48.9160581, lng: 19.7994904 },
    { lat: 48.9157133, lng: 19.799383 },
    { lat: 48.9156356, lng: 19.7993345 },
    { lat: 48.9155534, lng: 19.7993048 },
    { lat: 48.9153911, lng: 19.7991766 },
    { lat: 48.915112, lng: 19.7991336 },
    { lat: 48.9143464, lng: 19.7987791 },
    { lat: 48.9139103, lng: 19.7986185 },
    { lat: 48.9135798, lng: 19.7986845 },
    { lat: 48.912918, lng: 19.7982352 },
    { lat: 48.9126575, lng: 19.7980975 },
    { lat: 48.912395, lng: 19.797858 },
    { lat: 48.912528, lng: 19.798624 },
    { lat: 48.912959, lng: 19.799307 },
    { lat: 48.913616, lng: 19.80006 },
    { lat: 48.91474, lng: 19.802546 },
    { lat: 48.914884, lng: 19.802876 },
    { lat: 48.915107, lng: 19.803283 },
    { lat: 48.915421, lng: 19.804032 },
    { lat: 48.915471, lng: 19.804191 },
    { lat: 48.915553, lng: 19.804359 },
    { lat: 48.914962, lng: 19.806009 },
    { lat: 48.914541, lng: 19.807348 },
    { lat: 48.914387, lng: 19.808738 },
    { lat: 48.914333, lng: 19.809189 },
    { lat: 48.914328, lng: 19.809411 },
    { lat: 48.91436, lng: 19.810001 },
    { lat: 48.914426, lng: 19.810553 },
    { lat: 48.914618, lng: 19.811781 },
    { lat: 48.914755, lng: 19.812505 },
    { lat: 48.914822, lng: 19.812888 },
    { lat: 48.915017, lng: 19.81368 },
    { lat: 48.915158, lng: 19.814379 },
    { lat: 48.915551, lng: 19.815269 },
    { lat: 48.915899, lng: 19.816152 },
    { lat: 48.916217, lng: 19.816802 },
    { lat: 48.916365, lng: 19.817217 },
    { lat: 48.916109, lng: 19.8179 },
    { lat: 48.915499, lng: 19.818135 },
    { lat: 48.915145, lng: 19.818425 },
    { lat: 48.914716, lng: 19.818627 },
    { lat: 48.91378, lng: 19.818778 },
    { lat: 48.913417, lng: 19.818915 },
    { lat: 48.913222, lng: 19.818939 },
    { lat: 48.912706, lng: 19.81968 },
    { lat: 48.912538, lng: 19.819884 },
    { lat: 48.912369, lng: 19.819962 },
    { lat: 48.91194, lng: 19.820315 },
    { lat: 48.911885, lng: 19.820382 },
    { lat: 48.911378, lng: 19.820866 },
    { lat: 48.911127, lng: 19.82118 },
    { lat: 48.911021, lng: 19.821172 },
    { lat: 48.910535, lng: 19.821152 },
    { lat: 48.910125, lng: 19.821387 },
    { lat: 48.909864, lng: 19.821653 },
    { lat: 48.90941, lng: 19.821952 },
    { lat: 48.908956, lng: 19.82228 },
    { lat: 48.908358, lng: 19.822691 },
    { lat: 48.907995, lng: 19.823592 },
    { lat: 48.907778, lng: 19.824074 },
    { lat: 48.908033, lng: 19.825214 },
    { lat: 48.90813, lng: 19.826648 },
    { lat: 48.90806, lng: 19.827141 },
    { lat: 48.907835, lng: 19.827712 },
    { lat: 48.907261, lng: 19.828296 },
    { lat: 48.906734, lng: 19.829812 },
    { lat: 48.906473, lng: 19.830838 },
    { lat: 48.906126, lng: 19.832397 },
    { lat: 48.906265, lng: 19.834726 },
    { lat: 48.905879, lng: 19.835515 },
    { lat: 48.905575, lng: 19.836854 },
    { lat: 48.905352, lng: 19.837261 },
    { lat: 48.905034, lng: 19.837567 },
    { lat: 48.904838, lng: 19.837807 },
    { lat: 48.904222, lng: 19.83832 },
    { lat: 48.904455, lng: 19.840338 },
    { lat: 48.904715, lng: 19.842614 },
    { lat: 48.905284, lng: 19.844593 },
    { lat: 48.905967, lng: 19.846985 },
    { lat: 48.906848, lng: 19.847465 },
    { lat: 48.906794, lng: 19.847942 },
    { lat: 48.907009, lng: 19.84884 },
    { lat: 48.90705, lng: 19.849224 },
    { lat: 48.907149, lng: 19.849539 },
    { lat: 48.907202, lng: 19.849938 },
    { lat: 48.907377, lng: 19.850506 },
    { lat: 48.907583, lng: 19.851222 },
    { lat: 48.907678, lng: 19.852114 },
    { lat: 48.907719, lng: 19.852769 },
    { lat: 48.907827, lng: 19.85371 },
    { lat: 48.90794, lng: 19.854413 },
    { lat: 48.908082, lng: 19.85478 },
    { lat: 48.908153, lng: 19.855171 },
    { lat: 48.90806, lng: 19.856383 },
    { lat: 48.907358, lng: 19.860051 },
    { lat: 48.908229, lng: 19.862855 },
    { lat: 48.909005, lng: 19.863137 },
    { lat: 48.907185, lng: 19.866479 },
    { lat: 48.906474, lng: 19.868628 },
    { lat: 48.906031, lng: 19.870225 },
    { lat: 48.905815, lng: 19.872896 },
    { lat: 48.906898, lng: 19.874991 },
    { lat: 48.908246, lng: 19.877602 },
    { lat: 48.909042, lng: 19.877666 },
    { lat: 48.909464, lng: 19.878395 },
    { lat: 48.909562, lng: 19.879796 },
    { lat: 48.909856, lng: 19.880884 },
    { lat: 48.909632, lng: 19.881341 },
    { lat: 48.9101405, lng: 19.8813198 },
    { lat: 48.9114356, lng: 19.8808062 },
    { lat: 48.911968, lng: 19.8806665 },
    { lat: 48.9127608, lng: 19.8804752 },
    { lat: 48.9133642, lng: 19.8803314 },
    { lat: 48.9140998, lng: 19.880151 },
    { lat: 48.9145648, lng: 19.880039 },
    { lat: 48.9158604, lng: 19.8797234 },
    { lat: 48.9158902, lng: 19.8797136 },
    { lat: 48.9164147, lng: 19.879358 },
    { lat: 48.9166672, lng: 19.8792028 },
    { lat: 48.9171488, lng: 19.8788719 },
    { lat: 48.9179298, lng: 19.8783266 },
    { lat: 48.918434, lng: 19.8779726 },
    { lat: 48.9196986, lng: 19.8779071 },
    { lat: 48.920048, lng: 19.8778874 },
    { lat: 48.920582, lng: 19.8778665 },
    { lat: 48.9211445, lng: 19.8777152 },
    { lat: 48.9224148, lng: 19.8770706 },
    { lat: 48.9230258, lng: 19.8767644 },
    { lat: 48.9240501, lng: 19.8760358 },
    { lat: 48.9244865, lng: 19.876283 },
    { lat: 48.924933, lng: 19.8765286 },
    { lat: 48.9253717, lng: 19.8767795 },
    { lat: 48.9265454, lng: 19.877147 },
    { lat: 48.9273037, lng: 19.877387 },
    { lat: 48.9279331, lng: 19.8775651 },
    { lat: 48.9284777, lng: 19.8777642 },
    { lat: 48.9293701, lng: 19.8780329 },
    { lat: 48.9301691, lng: 19.878275 },
    { lat: 48.9307484, lng: 19.8784416 },
    { lat: 48.9311984, lng: 19.87856 },
    { lat: 48.9316756, lng: 19.878708 },
    { lat: 48.9319324, lng: 19.878945 },
    { lat: 48.9335281, lng: 19.8795424 },
    { lat: 48.9367288, lng: 19.8807391 },
    { lat: 48.9385004, lng: 19.8814153 },
    { lat: 48.9395504, lng: 19.8818145 },
    { lat: 48.9397115, lng: 19.8818162 },
    { lat: 48.940169, lng: 19.8815789 },
    { lat: 48.9404697, lng: 19.8812351 },
    { lat: 48.9423163, lng: 19.8791325 },
    { lat: 48.9433374, lng: 19.8779801 },
    { lat: 48.9443393, lng: 19.8756139 },
    { lat: 48.9452717, lng: 19.8734468 },
    { lat: 48.9457501, lng: 19.8723304 },
    { lat: 48.9466377, lng: 19.8702476 },
    { lat: 48.9489693, lng: 19.8648126 },
    { lat: 48.9501109, lng: 19.8645868 },
    { lat: 48.9506011, lng: 19.8645632 },
    { lat: 48.9510083, lng: 19.8645782 },
    { lat: 48.9514043, lng: 19.8646979 },
    { lat: 48.9516823, lng: 19.8647451 },
    { lat: 48.951946, lng: 19.8647467 },
    { lat: 48.9524672, lng: 19.8646845 },
    { lat: 48.9529426, lng: 19.864791 },
    { lat: 48.9534677, lng: 19.8648489 },
    { lat: 48.9538228, lng: 19.8648286 },
    { lat: 48.954162, lng: 19.8648285 },
    { lat: 48.9552664, lng: 19.8649974 },
    { lat: 48.9558327, lng: 19.8649358 },
    { lat: 48.9560266, lng: 19.8649443 },
    { lat: 48.9561841, lng: 19.864751 },
    { lat: 48.9566498, lng: 19.864448 },
    { lat: 48.956942, lng: 19.8638794 },
    { lat: 48.9574903, lng: 19.8633192 },
    { lat: 48.9575568, lng: 19.863185 },
    { lat: 48.957886, lng: 19.8626424 },
    { lat: 48.9579579, lng: 19.8621102 },
    { lat: 48.9580084, lng: 19.8616306 },
    { lat: 48.9582334, lng: 19.8609639 },
    { lat: 48.9584976, lng: 19.8605568 },
    { lat: 48.9591503, lng: 19.860017 },
    { lat: 48.9596131, lng: 19.8594092 },
    { lat: 48.9600359, lng: 19.8583892 },
    { lat: 48.9602207, lng: 19.857816 },
    { lat: 48.9603887, lng: 19.8577923 },
    { lat: 48.9605461, lng: 19.8578133 },
    { lat: 48.9607267, lng: 19.8577901 },
    { lat: 48.9610429, lng: 19.8573355 },
    { lat: 48.9624044, lng: 19.8562495 },
    { lat: 48.963956, lng: 19.8567849 },
    { lat: 48.9642583, lng: 19.8564005 },
    { lat: 48.9645778, lng: 19.8562069 },
    { lat: 48.9647429, lng: 19.8561429 },
    { lat: 48.9653261, lng: 19.8558214 },
    { lat: 48.9654774, lng: 19.8557218 },
    { lat: 48.9657633, lng: 19.8554516 },
    { lat: 48.9658692, lng: 19.855401 },
    { lat: 48.9661199, lng: 19.8550198 },
    { lat: 48.9664728, lng: 19.8549484 },
    { lat: 48.9665772, lng: 19.8548348 },
    { lat: 48.9666806, lng: 19.8546959 },
    { lat: 48.9668493, lng: 19.8544315 },
    { lat: 48.9669541, lng: 19.8540884 },
    { lat: 48.96701, lng: 19.8539441 },
    { lat: 48.967044, lng: 19.8538185 },
    { lat: 48.9670289, lng: 19.8537296 },
    { lat: 48.9670939, lng: 19.8534837 },
    { lat: 48.9671683, lng: 19.853277 },
    { lat: 48.9672097, lng: 19.8530091 },
    { lat: 48.9671856, lng: 19.8528142 },
    { lat: 48.9671536, lng: 19.852674 },
    { lat: 48.967155, lng: 19.8525473 },
    { lat: 48.9671391, lng: 19.8524395 },
    { lat: 48.9671255, lng: 19.8521727 },
    { lat: 48.9671332, lng: 19.8519055 },
    { lat: 48.967097, lng: 19.8516678 },
    { lat: 48.9670749, lng: 19.851285 },
    { lat: 48.967085, lng: 19.8507551 },
    { lat: 48.9670885, lng: 19.8502084 },
    { lat: 48.9671306, lng: 19.8497421 },
    { lat: 48.9671747, lng: 19.8496151 },
    { lat: 48.9671877, lng: 19.8494231 },
    { lat: 48.9673095, lng: 19.8489291 },
    { lat: 48.9673263, lng: 19.8487421 },
    { lat: 48.967327, lng: 19.8479942 },
    { lat: 48.9673926, lng: 19.8475022 },
    { lat: 48.9674077, lng: 19.8472497 },
    { lat: 48.967403, lng: 19.8470638 },
    { lat: 48.9674324, lng: 19.8467758 },
    { lat: 48.9674888, lng: 19.8466713 },
    { lat: 48.9675323, lng: 19.8465555 },
    { lat: 48.9676435, lng: 19.8464183 },
    { lat: 48.9678573, lng: 19.8460206 },
    { lat: 48.9679389, lng: 19.8455576 },
    { lat: 48.9679334, lng: 19.8452978 },
    { lat: 48.9678115, lng: 19.8449768 },
    { lat: 48.9677366, lng: 19.8448376 },
    { lat: 48.9677335, lng: 19.8447368 },
    { lat: 48.9674833, lng: 19.8434454 },
    { lat: 48.9675896, lng: 19.8433549 },
    { lat: 48.9680905, lng: 19.8432655 },
    { lat: 48.9682009, lng: 19.8431606 },
    { lat: 48.9683761, lng: 19.8430949 },
    { lat: 48.9688325, lng: 19.8427567 },
    { lat: 48.9694315, lng: 19.8424367 },
    { lat: 48.9699081, lng: 19.841809 },
    { lat: 48.9703346, lng: 19.8416154 },
    { lat: 48.9707978, lng: 19.8412002 },
    { lat: 48.9713502, lng: 19.8408417 },
    { lat: 48.9717722, lng: 19.8405711 },
    { lat: 48.9719764, lng: 19.8403694 },
    { lat: 48.9724756, lng: 19.8400596 },
    { lat: 48.9735616, lng: 19.8398221 },
    { lat: 48.9737179, lng: 19.8391088 },
    { lat: 48.9739669, lng: 19.8381411 },
    { lat: 48.9741845, lng: 19.837701 },
    { lat: 48.9743356, lng: 19.8373321 },
    { lat: 48.9745316, lng: 19.8368892 },
    { lat: 48.9749144, lng: 19.8364657 },
    { lat: 48.9750714, lng: 19.8360048 },
    { lat: 48.9749467, lng: 19.8349116 },
    { lat: 48.9747743, lng: 19.83339 },
    { lat: 48.9746425, lng: 19.8314778 },
    { lat: 48.974696, lng: 19.8302634 },
    { lat: 48.9746394, lng: 19.8298114 },
    { lat: 48.9746059, lng: 19.829662 },
    { lat: 48.9744902, lng: 19.8293274 },
    { lat: 48.9744562, lng: 19.8290371 },
    { lat: 48.9744154, lng: 19.8285338 },
    { lat: 48.9744453, lng: 19.8281321 },
    { lat: 48.9744245, lng: 19.8278584 },
    { lat: 48.9744785, lng: 19.827621 },
    { lat: 48.9745138, lng: 19.8273941 },
    { lat: 48.9749715, lng: 19.8272729 },
    { lat: 48.9756602, lng: 19.8269674 },
    { lat: 48.9762341, lng: 19.8265844 },
    { lat: 48.9766403, lng: 19.8266272 },
    { lat: 48.9769667, lng: 19.826565 },
    { lat: 48.9773049, lng: 19.8265199 },
    { lat: 48.9774864, lng: 19.8265245 },
    { lat: 48.9781791, lng: 19.8262591 },
    { lat: 48.9797114, lng: 19.8253016 },
    { lat: 48.9803733, lng: 19.8243474 },
    { lat: 48.9802643, lng: 19.8235445 },
    { lat: 48.9802201, lng: 19.8227545 },
    { lat: 48.9801366, lng: 19.8222249 },
    { lat: 48.9800734, lng: 19.8213332 },
    { lat: 48.9798956, lng: 19.8200856 },
    { lat: 48.9797614, lng: 19.8194029 },
    { lat: 48.9799955, lng: 19.818564 },
    { lat: 48.9800309, lng: 19.8180018 },
    { lat: 48.9801059, lng: 19.8176493 },
    { lat: 48.9800832, lng: 19.8167851 },
    { lat: 48.98008, lng: 19.815721 },
    { lat: 48.9801145, lng: 19.8149293 },
    { lat: 48.9801263, lng: 19.8144432 },
    { lat: 48.980024, lng: 19.8133036 },
    { lat: 48.9799113, lng: 19.8126722 },
    { lat: 48.9798635, lng: 19.8123474 },
    { lat: 48.9806714, lng: 19.8114423 },
    { lat: 48.9809821, lng: 19.8111761 },
    { lat: 48.9813821, lng: 19.8106549 },
    { lat: 48.9821181, lng: 19.8096475 },
    { lat: 48.9824952, lng: 19.8086505 },
    { lat: 48.9827797, lng: 19.807965 },
    { lat: 48.9829758, lng: 19.8074451 },
    { lat: 48.983097, lng: 19.8071472 },
    { lat: 48.983441, lng: 19.8064551 },
    { lat: 48.9843931, lng: 19.8039566 },
    { lat: 48.9846127, lng: 19.8033593 },
    { lat: 48.9850332, lng: 19.8022955 },
    { lat: 48.9845587, lng: 19.8013042 },
    { lat: 48.9844251, lng: 19.8009219 },
    { lat: 48.9843032, lng: 19.8006041 },
    { lat: 48.983951, lng: 19.79996 },
    { lat: 48.9838831, lng: 19.7994352 },
    { lat: 48.9838669, lng: 19.7990111 },
    { lat: 48.983678, lng: 19.7982897 },
    { lat: 48.9836397, lng: 19.7978481 },
    { lat: 48.9835297, lng: 19.7975438 },
    { lat: 48.9835424, lng: 19.7971122 },
    { lat: 48.9833555, lng: 19.7963332 },
    { lat: 48.9833066, lng: 19.7959859 },
    { lat: 48.9834466, lng: 19.795577 },
    { lat: 48.9834061, lng: 19.7951384 },
    { lat: 48.9834205, lng: 19.7941973 },
    { lat: 48.9833332, lng: 19.7940274 },
    { lat: 48.9832952, lng: 19.7934907 },
    { lat: 48.9834059, lng: 19.7931289 },
    { lat: 48.9834448, lng: 19.7925464 },
    { lat: 48.9835526, lng: 19.7921752 },
    { lat: 48.9835777, lng: 19.7914797 },
    { lat: 48.9839486, lng: 19.7913063 },
    { lat: 48.9846527, lng: 19.7909664 },
    { lat: 48.9853225, lng: 19.7900227 },
    { lat: 48.985817, lng: 19.7889549 },
    { lat: 48.9860031, lng: 19.7879237 },
    { lat: 48.9859842, lng: 19.7857272 },
    { lat: 48.9860458, lng: 19.7853774 },
    { lat: 48.985979, lng: 19.7848238 },
    { lat: 48.9858944, lng: 19.7843811 },
    { lat: 48.9858547, lng: 19.7838599 },
    { lat: 48.985858, lng: 19.7834959 },
    { lat: 48.985608, lng: 19.7831307 },
    { lat: 48.9854945, lng: 19.7826482 },
    { lat: 48.9853703, lng: 19.7824315 },
    { lat: 48.9853156, lng: 19.782291 },
    { lat: 48.9853219, lng: 19.782045 },
    { lat: 48.9853272, lng: 19.7815685 },
    { lat: 48.9852915, lng: 19.7811388 },
    { lat: 48.9851308, lng: 19.7808906 },
    { lat: 48.9851416, lng: 19.7807751 },
    { lat: 48.9852534, lng: 19.7802089 },
    { lat: 48.9852965, lng: 19.7797774 },
    { lat: 48.985512, lng: 19.7794233 },
    { lat: 48.9856249, lng: 19.7788815 },
    { lat: 48.9859958, lng: 19.7778507 },
    { lat: 48.9860193, lng: 19.7777956 },
    { lat: 48.9863913, lng: 19.7777503 },
    { lat: 48.9867371, lng: 19.7776198 },
    { lat: 48.9870344, lng: 19.7774856 },
    { lat: 48.9871774, lng: 19.77736 },
    { lat: 48.9874657, lng: 19.777301 },
    { lat: 48.9878701, lng: 19.7769924 },
    { lat: 48.9883051, lng: 19.7763722 },
    { lat: 48.9884983, lng: 19.776124 },
    { lat: 48.9886803, lng: 19.7759608 },
    { lat: 48.9889316, lng: 19.7756281 },
    { lat: 48.9892598, lng: 19.7752712 },
    { lat: 48.9899345, lng: 19.774806 },
    { lat: 48.9903689, lng: 19.7744596 },
    { lat: 48.9911116, lng: 19.7740728 },
    { lat: 48.9913119, lng: 19.7740721 },
    { lat: 48.9916951, lng: 19.7741265 },
    { lat: 48.9924339, lng: 19.7742481 },
    { lat: 48.9927547, lng: 19.774298 },
    { lat: 48.9931487, lng: 19.7743678 },
    { lat: 48.9933825, lng: 19.7741958 },
    { lat: 48.9935258, lng: 19.7740239 },
    { lat: 48.9936358, lng: 19.7738604 },
    { lat: 48.9937924, lng: 19.7737546 },
    { lat: 48.9939552, lng: 19.7737404 },
    { lat: 48.9941381, lng: 19.7737507 },
    { lat: 48.9943027, lng: 19.7738059 },
    { lat: 48.9945815, lng: 19.7738173 },
    { lat: 48.9947386, lng: 19.7737757 },
    { lat: 48.995028, lng: 19.773592 },
    { lat: 48.9951447, lng: 19.7734791 },
    { lat: 48.9952762, lng: 19.7733715 },
    { lat: 48.9956335, lng: 19.7732717 },
    { lat: 48.9958276, lng: 19.7730165 },
    { lat: 48.9959161, lng: 19.772957 },
    { lat: 48.9959623, lng: 19.7729061 },
    { lat: 48.9959682, lng: 19.7728575 },
    { lat: 48.9960696, lng: 19.7728067 },
    { lat: 48.9961855, lng: 19.7726221 },
    { lat: 48.9962923, lng: 19.7725163 },
    { lat: 48.9963522, lng: 19.7723629 },
    { lat: 48.996446, lng: 19.7721902 },
    { lat: 48.9965559, lng: 19.7721766 },
    { lat: 48.9965673, lng: 19.7720488 },
    { lat: 48.9966949, lng: 19.7719047 },
    { lat: 48.9967059, lng: 19.7717131 },
    { lat: 48.9967707, lng: 19.7716499 },
    { lat: 48.9967871, lng: 19.7715618 },
    { lat: 48.9968874, lng: 19.7714563 },
    { lat: 48.9968904, lng: 19.771396 },
    { lat: 48.9969736, lng: 19.7712632 },
    { lat: 48.9969743, lng: 19.7712297 },
    { lat: 48.9970916, lng: 19.7710246 },
    { lat: 48.9972489, lng: 19.7707824 },
    { lat: 48.9972911, lng: 19.7706658 },
    { lat: 48.9972933, lng: 19.770586 },
    { lat: 48.9973564, lng: 19.7704856 },
    { lat: 48.9973728, lng: 19.7702924 },
    { lat: 48.9973955, lng: 19.7701907 },
    { lat: 48.9973696, lng: 19.7700882 },
    { lat: 48.9973663, lng: 19.7700415 },
    { lat: 48.9974219, lng: 19.7699452 },
    { lat: 48.9974569, lng: 19.7698175 },
    { lat: 48.9974497, lng: 19.7696528 },
    { lat: 48.9975614, lng: 19.769323 },
    { lat: 48.9976287, lng: 19.7692352 },
    { lat: 48.997712, lng: 19.769207 },
    { lat: 48.9978093, lng: 19.7691388 },
    { lat: 48.9979701, lng: 19.7688456 },
    { lat: 48.9979595, lng: 19.7687053 },
    { lat: 48.9980433, lng: 19.7685875 },
    { lat: 48.9980538, lng: 19.7685455 },
    { lat: 48.9980498, lng: 19.7685032 },
    { lat: 48.9980331, lng: 19.7684604 },
    { lat: 48.9980703, lng: 19.7684039 },
    { lat: 48.9980907, lng: 19.7683577 },
    { lat: 48.9981563, lng: 19.7682611 },
    { lat: 48.9985817, lng: 19.7675782 },
    { lat: 48.9990299, lng: 19.7668769 },
    { lat: 48.9991231, lng: 19.7666641 },
    { lat: 48.9989508, lng: 19.7660424 },
    { lat: 48.9988835, lng: 19.7659218 },
    { lat: 48.9987045, lng: 19.7657421 },
    { lat: 48.9985256, lng: 19.7655908 },
    { lat: 48.9983991, lng: 19.7655113 },
    { lat: 48.9983061, lng: 19.7654676 },
    { lat: 48.9982416, lng: 19.7654623 },
    { lat: 48.9979794, lng: 19.7655235 },
    { lat: 48.997918, lng: 19.7654933 },
    { lat: 48.997761, lng: 19.7654992 },
    { lat: 48.9976597, lng: 19.7654516 },
    { lat: 48.9975179, lng: 19.7653489 },
    { lat: 48.9974336, lng: 19.765253 },
    { lat: 48.9972512, lng: 19.7650217 },
    { lat: 48.9971645, lng: 19.7647387 },
    { lat: 48.9970254, lng: 19.7642332 },
    { lat: 48.9969119, lng: 19.7639053 },
    { lat: 48.9967022, lng: 19.7630194 },
    { lat: 48.9965539, lng: 19.7626684 },
    { lat: 48.9964238, lng: 19.7623209 },
    { lat: 48.9963291, lng: 19.7621619 },
    { lat: 48.9960697, lng: 19.7617464 },
    { lat: 48.9957661, lng: 19.7613464 },
    { lat: 48.9956098, lng: 19.7611939 },
    { lat: 48.995332, lng: 19.7609478 },
    { lat: 48.995202, lng: 19.7608626 },
    { lat: 48.9951221, lng: 19.7608649 },
    { lat: 48.9950184, lng: 19.7609393 },
    { lat: 48.9948687, lng: 19.7611979 },
    { lat: 48.9947397, lng: 19.7615478 },
    { lat: 48.994604, lng: 19.7616946 },
    { lat: 48.9945183, lng: 19.7617438 },
    { lat: 48.9942686, lng: 19.7611824 },
    { lat: 48.9940894, lng: 19.7604851 },
    { lat: 48.993944, lng: 19.7600466 },
    { lat: 48.9938808, lng: 19.7599134 },
    { lat: 48.9936385, lng: 19.7597054 },
    { lat: 48.9934972, lng: 19.7596149 },
    { lat: 48.9932869, lng: 19.7596005 },
    { lat: 48.9928858, lng: 19.7596227 },
    { lat: 48.992512, lng: 19.7596309 },
    { lat: 48.9924043, lng: 19.7596127 },
    { lat: 48.9921999, lng: 19.7594995 },
    { lat: 48.9920447, lng: 19.759352 },
    { lat: 48.9917234, lng: 19.7590873 },
    { lat: 48.9915114, lng: 19.7589516 },
    { lat: 48.9911258, lng: 19.7587921 },
    { lat: 48.9908336, lng: 19.7587282 },
    { lat: 48.9903861, lng: 19.7587256 },
    { lat: 48.9901897, lng: 19.7587914 },
    { lat: 48.9899923, lng: 19.7588113 },
    { lat: 48.9898264, lng: 19.758781 },
    { lat: 48.9897282, lng: 19.758726 },
    { lat: 48.9896061, lng: 19.75864 },
    { lat: 48.9894314, lng: 19.7584822 },
    { lat: 48.9891799, lng: 19.75842 },
    { lat: 48.9888729, lng: 19.7583053 },
    { lat: 48.9886569, lng: 19.7582629 },
    { lat: 48.988311, lng: 19.7583139 },
    { lat: 48.9881777, lng: 19.7584084 },
    { lat: 48.9876359, lng: 19.7589498 },
    { lat: 48.9872861, lng: 19.7593499 },
    { lat: 48.9870387, lng: 19.759566 },
    { lat: 48.9868495, lng: 19.7597009 },
    { lat: 48.986367, lng: 19.7597714 },
    { lat: 48.9861358, lng: 19.7598116 },
    { lat: 48.9859609, lng: 19.7599099 },
    { lat: 48.9858507, lng: 19.7600197 },
    { lat: 48.9855254, lng: 19.7604639 },
    { lat: 48.9853821, lng: 19.7606695 },
    { lat: 48.9852873, lng: 19.7608709 },
    { lat: 48.9852232, lng: 19.7609764 },
    { lat: 48.9850171, lng: 19.7611826 },
    { lat: 48.984884, lng: 19.7613345 },
    { lat: 48.9847437, lng: 19.7614738 },
    { lat: 48.9845815, lng: 19.7616562 },
    { lat: 48.9844122, lng: 19.7618565 },
    { lat: 48.9841756, lng: 19.7621149 },
    { lat: 48.9840739, lng: 19.7622618 },
    { lat: 48.9839093, lng: 19.7626453 },
    { lat: 48.9838585, lng: 19.7628216 },
    { lat: 48.9838049, lng: 19.7630447 },
    { lat: 48.9837657, lng: 19.7632549 },
    { lat: 48.9837013, lng: 19.7634554 },
    { lat: 48.983643, lng: 19.7635638 },
    { lat: 48.9835509, lng: 19.7636764 },
    { lat: 48.9832742, lng: 19.7638929 },
    { lat: 48.9830061, lng: 19.7640462 },
    { lat: 48.9828906, lng: 19.7641378 },
    { lat: 48.9827039, lng: 19.7643284 },
    { lat: 48.9825654, lng: 19.7645081 },
    { lat: 48.9823812, lng: 19.76483 },
    { lat: 48.982253, lng: 19.765014 },
    { lat: 48.9821823, lng: 19.7651535 },
    { lat: 48.9820805, lng: 19.7653746 },
    { lat: 48.9819419, lng: 19.7657096 },
    { lat: 48.9818735, lng: 19.765899 },
    { lat: 48.98177, lng: 19.7660816 },
    { lat: 48.9816706, lng: 19.7662803 },
    { lat: 48.9815268, lng: 19.7665989 },
    { lat: 48.9814982, lng: 19.766643 },
    { lat: 48.9813029, lng: 19.7668947 },
    { lat: 48.9811618, lng: 19.7670922 },
    { lat: 48.981021, lng: 19.7673011 },
    { lat: 48.9809059, lng: 19.7674497 },
    { lat: 48.9807775, lng: 19.7676344 },
    { lat: 48.9805868, lng: 19.7678082 },
    { lat: 48.9802578, lng: 19.7680923 },
    { lat: 48.9800598, lng: 19.7682783 },
    { lat: 48.9799574, lng: 19.7684095 },
    { lat: 48.9797286, lng: 19.76877 },
    { lat: 48.9796956, lng: 19.7688644 },
    { lat: 48.9796781, lng: 19.7690318 },
    { lat: 48.9796437, lng: 19.7692017 },
    { lat: 48.9795564, lng: 19.7696789 },
    { lat: 48.9794654, lng: 19.7699685 },
    { lat: 48.9793649, lng: 19.7702193 },
    { lat: 48.9792428, lng: 19.7704661 },
    { lat: 48.9791708, lng: 19.7706505 },
    { lat: 48.9790986, lng: 19.770755 },
    { lat: 48.9790373, lng: 19.7708698 },
    { lat: 48.9789412, lng: 19.7709135 },
    { lat: 48.9787337, lng: 19.7710377 },
    { lat: 48.9785877, lng: 19.7713615 },
    { lat: 48.9785099, lng: 19.7715637 },
    { lat: 48.9784633, lng: 19.7721802 },
    { lat: 48.9784614, lng: 19.772369 },
    { lat: 48.9784877, lng: 19.7725565 },
    { lat: 48.9785893, lng: 19.7729745 },
    { lat: 48.9786141, lng: 19.7730509 },
  ],
  MaléBorové: [
    { lat: 49.236117, lng: 19.528936 },
    { lat: 49.235246, lng: 19.528259 },
    { lat: 49.234635, lng: 19.528059 },
    { lat: 49.233461, lng: 19.527645 },
    { lat: 49.231271, lng: 19.526304 },
    { lat: 49.230951, lng: 19.526002 },
    { lat: 49.230383, lng: 19.52595 },
    { lat: 49.22895, lng: 19.525818 },
    { lat: 49.227951, lng: 19.525636 },
    { lat: 49.226962, lng: 19.525195 },
    { lat: 49.2268426, lng: 19.5251396 },
    { lat: 49.223981, lng: 19.523812 },
    { lat: 49.223435, lng: 19.523801 },
    { lat: 49.222492, lng: 19.523351 },
    { lat: 49.222136, lng: 19.523258 },
    { lat: 49.220659, lng: 19.523205 },
    { lat: 49.218954, lng: 19.522807 },
    { lat: 49.218141, lng: 19.522158 },
    { lat: 49.218121, lng: 19.522136 },
    { lat: 49.2177763, lng: 19.5234239 },
    { lat: 49.217674, lng: 19.5238532 },
    { lat: 49.2174589, lng: 19.5255863 },
    { lat: 49.2178511, lng: 19.5268084 },
    { lat: 49.2179091, lng: 19.5269944 },
    { lat: 49.2181069, lng: 19.5276273 },
    { lat: 49.2181148, lng: 19.5276239 },
    { lat: 49.2181475, lng: 19.528421 },
    { lat: 49.2181637, lng: 19.5289235 },
    { lat: 49.2181958, lng: 19.5299301 },
    { lat: 49.2180569, lng: 19.5315823 },
    { lat: 49.2177861, lng: 19.5332152 },
    { lat: 49.2176336, lng: 19.5339493 },
    { lat: 49.2174917, lng: 19.5345937 },
    { lat: 49.217279, lng: 19.5355208 },
    { lat: 49.2169574, lng: 19.5350088 },
    { lat: 49.2169032, lng: 19.5350582 },
    { lat: 49.2165664, lng: 19.5343917 },
    { lat: 49.2163487, lng: 19.5345102 },
    { lat: 49.2153301, lng: 19.5342601 },
    { lat: 49.213942, lng: 19.5347828 },
    { lat: 49.2139916, lng: 19.5361979 },
    { lat: 49.2140285, lng: 19.5370125 },
    { lat: 49.2138985, lng: 19.537648 },
    { lat: 49.2127096, lng: 19.5397021 },
    { lat: 49.2117444, lng: 19.5407523 },
    { lat: 49.2114983, lng: 19.5408446 },
    { lat: 49.2109844, lng: 19.5418103 },
    { lat: 49.2107115, lng: 19.5424049 },
    { lat: 49.2106748, lng: 19.542763 },
    { lat: 49.2105324, lng: 19.5441418 },
    { lat: 49.2099475, lng: 19.5455099 },
    { lat: 49.2099597, lng: 19.5455291 },
    { lat: 49.2099767, lng: 19.5455562 },
    { lat: 49.2103132, lng: 19.5453231 },
    { lat: 49.2103416, lng: 19.5452311 },
    { lat: 49.2105552, lng: 19.5453444 },
    { lat: 49.2114304, lng: 19.5458597 },
    { lat: 49.2119533, lng: 19.5462391 },
    { lat: 49.2124309, lng: 19.5466273 },
    { lat: 49.2129327, lng: 19.547036 },
    { lat: 49.2132172, lng: 19.5472503 },
    { lat: 49.2132289, lng: 19.5472581 },
    { lat: 49.2139648, lng: 19.5477859 },
    { lat: 49.2144788, lng: 19.5481667 },
    { lat: 49.2150229, lng: 19.5486589 },
    { lat: 49.215358, lng: 19.5492449 },
    { lat: 49.2157172, lng: 19.5495865 },
    { lat: 49.2157655, lng: 19.5496088 },
    { lat: 49.2158081, lng: 19.5496278 },
    { lat: 49.2161185, lng: 19.5497666 },
    { lat: 49.2161362, lng: 19.5497824 },
    { lat: 49.2161792, lng: 19.5498085 },
    { lat: 49.2162003, lng: 19.5498264 },
    { lat: 49.2162469, lng: 19.5498399 },
    { lat: 49.21685, lng: 19.5500185 },
    { lat: 49.2176175, lng: 19.5502461 },
    { lat: 49.2179006, lng: 19.5503298 },
    { lat: 49.2180218, lng: 19.5502427 },
    { lat: 49.2189077, lng: 19.5508514 },
    { lat: 49.2191451, lng: 19.5510149 },
    { lat: 49.2194288, lng: 19.5515094 },
    { lat: 49.2200886, lng: 19.5526178 },
    { lat: 49.220382, lng: 19.5528514 },
    { lat: 49.2212163, lng: 19.5535121 },
    { lat: 49.2226615, lng: 19.5548513 },
    { lat: 49.2227672, lng: 19.5550219 },
    { lat: 49.2227782, lng: 19.5550405 },
    { lat: 49.223771, lng: 19.5566381 },
    { lat: 49.2244937, lng: 19.5570731 },
    { lat: 49.2248325, lng: 19.5574898 },
    { lat: 49.2252896, lng: 19.557982 },
    { lat: 49.2255046, lng: 19.5577773 },
    { lat: 49.2259173, lng: 19.5582097 },
    { lat: 49.2269456, lng: 19.5597481 },
    { lat: 49.2268436, lng: 19.5597592 },
    { lat: 49.2269861, lng: 19.5600232 },
    { lat: 49.2271641, lng: 19.5600971 },
    { lat: 49.2275711, lng: 19.5605251 },
    { lat: 49.2280067, lng: 19.5608909 },
    { lat: 49.2285264, lng: 19.5612219 },
    { lat: 49.2284714, lng: 19.5613521 },
    { lat: 49.2291581, lng: 19.5618101 },
    { lat: 49.2295896, lng: 19.5619132 },
    { lat: 49.2295298, lng: 19.562983 },
    { lat: 49.2295285, lng: 19.5630019 },
    { lat: 49.2295467, lng: 19.5630811 },
    { lat: 49.2297976, lng: 19.5628012 },
    { lat: 49.2300585, lng: 19.5625672 },
    { lat: 49.2303253, lng: 19.5623365 },
    { lat: 49.2304232, lng: 19.5622577 },
    { lat: 49.2304154, lng: 19.5621623 },
    { lat: 49.2303737, lng: 19.562034 },
    { lat: 49.2304303, lng: 19.5618907 },
    { lat: 49.2306158, lng: 19.5617057 },
    { lat: 49.2309095, lng: 19.561603 },
    { lat: 49.2311883, lng: 19.5613127 },
    { lat: 49.2313818, lng: 19.5611812 },
    { lat: 49.2314833, lng: 19.5609809 },
    { lat: 49.2315518, lng: 19.5607253 },
    { lat: 49.23183, lng: 19.5605511 },
    { lat: 49.2318842, lng: 19.5604542 },
    { lat: 49.2325289, lng: 19.5593389 },
    { lat: 49.2326468, lng: 19.5587277 },
    { lat: 49.232782, lng: 19.5578529 },
    { lat: 49.2330923, lng: 19.5572909 },
    { lat: 49.2334255, lng: 19.557162 },
    { lat: 49.2343667, lng: 19.5567979 },
    { lat: 49.236799, lng: 19.5554931 },
    { lat: 49.2375541, lng: 19.5546281 },
    { lat: 49.2393319, lng: 19.5551233 },
    { lat: 49.2399358, lng: 19.5548256 },
    { lat: 49.2411536, lng: 19.5548992 },
    { lat: 49.242009, lng: 19.554759 },
    { lat: 49.24116, lng: 19.553306 },
    { lat: 49.240838, lng: 19.552303 },
    { lat: 49.239865, lng: 19.548433 },
    { lat: 49.239829, lng: 19.548352 },
    { lat: 49.239445, lng: 19.547177 },
    { lat: 49.238689, lng: 19.544857 },
    { lat: 49.238297, lng: 19.543434 },
    { lat: 49.237553, lng: 19.541512 },
    { lat: 49.237323, lng: 19.540084 },
    { lat: 49.237182, lng: 19.538368 },
    { lat: 49.236986, lng: 19.536147 },
    { lat: 49.236941, lng: 19.535811 },
    { lat: 49.236932, lng: 19.535743 },
    { lat: 49.236778, lng: 19.534589 },
    { lat: 49.236571, lng: 19.533365 },
    { lat: 49.236334, lng: 19.530947 },
    { lat: 49.236288, lng: 19.530472 },
    { lat: 49.236171, lng: 19.529496 },
    { lat: 49.236123, lng: 19.529039 },
    { lat: 49.236117, lng: 19.528936 },
  ],
  NižnáBoca: [
    { lat: 48.940133, lng: 19.713418 },
    { lat: 48.9383828, lng: 19.7221289 },
    { lat: 48.9386759, lng: 19.722206 },
    { lat: 48.9379746, lng: 19.7267251 },
    { lat: 48.9376013, lng: 19.7266379 },
    { lat: 48.9373514, lng: 19.730436 },
    { lat: 48.9371475, lng: 19.7335392 },
    { lat: 48.9369132, lng: 19.7372066 },
    { lat: 48.9353835, lng: 19.7414493 },
    { lat: 48.9346105, lng: 19.7435527 },
    { lat: 48.9345617, lng: 19.7441363 },
    { lat: 48.9345692, lng: 19.7454919 },
    { lat: 48.9345093, lng: 19.7460537 },
    { lat: 48.934519, lng: 19.7463505 },
    { lat: 48.9344087, lng: 19.7477935 },
    { lat: 48.9344733, lng: 19.7476995 },
    { lat: 48.9345498, lng: 19.7474134 },
    { lat: 48.9345835, lng: 19.7474136 },
    { lat: 48.9346284, lng: 19.7477903 },
    { lat: 48.9348787, lng: 19.7479005 },
    { lat: 48.9349303, lng: 19.7482536 },
    { lat: 48.9347476, lng: 19.7484326 },
    { lat: 48.9345307, lng: 19.7485473 },
    { lat: 48.9345405, lng: 19.7485922 },
    { lat: 48.9348347, lng: 19.7494178 },
    { lat: 48.9350163, lng: 19.7491393 },
    { lat: 48.9351993, lng: 19.7491997 },
    { lat: 48.9354929, lng: 19.7494998 },
    { lat: 48.935785, lng: 19.7499702 },
    { lat: 48.936199, lng: 19.7501076 },
    { lat: 48.9364373, lng: 19.7502767 },
    { lat: 48.9367317, lng: 19.7503377 },
    { lat: 48.9369157, lng: 19.7503245 },
    { lat: 48.9368594, lng: 19.7508877 },
    { lat: 48.9368955, lng: 19.7510439 },
    { lat: 48.937059, lng: 19.7511493 },
    { lat: 48.9370466, lng: 19.7512795 },
    { lat: 48.9371562, lng: 19.7514645 },
    { lat: 48.9372017, lng: 19.7516308 },
    { lat: 48.9373298, lng: 19.752292 },
    { lat: 48.9374393, lng: 19.752707 },
    { lat: 48.9375765, lng: 19.752729 },
    { lat: 48.9375851, lng: 19.7529256 },
    { lat: 48.9375112, lng: 19.7532971 },
    { lat: 48.9379186, lng: 19.7536191 },
    { lat: 48.9379978, lng: 19.753811 },
    { lat: 48.9382905, lng: 19.7540636 },
    { lat: 48.9382434, lng: 19.7543263 },
    { lat: 48.9384109, lng: 19.7546728 },
    { lat: 48.9384167, lng: 19.7548055 },
    { lat: 48.9386338, lng: 19.7550857 },
    { lat: 48.9387941, lng: 19.755042 },
    { lat: 48.9390342, lng: 19.7550956 },
    { lat: 48.9389635, lng: 19.7552274 },
    { lat: 48.9389438, lng: 19.7558906 },
    { lat: 48.9391717, lng: 19.7561521 },
    { lat: 48.9391866, lng: 19.7565194 },
    { lat: 48.93922, lng: 19.7567916 },
    { lat: 48.9390908, lng: 19.7568548 },
    { lat: 48.9390271, lng: 19.7566605 },
    { lat: 48.9389612, lng: 19.7565156 },
    { lat: 48.9389309, lng: 19.7565183 },
    { lat: 48.9389421, lng: 19.7569314 },
    { lat: 48.9389471, lng: 19.757094 },
    { lat: 48.9389503, lng: 19.757195 },
    { lat: 48.9387802, lng: 19.7572226 },
    { lat: 48.9387874, lng: 19.7572595 },
    { lat: 48.9388049, lng: 19.7573466 },
    { lat: 48.9388945, lng: 19.7578013 },
    { lat: 48.9389073, lng: 19.7578631 },
    { lat: 48.9389249, lng: 19.7579516 },
    { lat: 48.938942, lng: 19.7580355 },
    { lat: 48.9387484, lng: 19.758117 },
    { lat: 48.9384406, lng: 19.7581652 },
    { lat: 48.9382818, lng: 19.7581407 },
    { lat: 48.9376607, lng: 19.7580284 },
    { lat: 48.9371255, lng: 19.7579257 },
    { lat: 48.9370413, lng: 19.7579048 },
    { lat: 48.9365539, lng: 19.757783 },
    { lat: 48.9361108, lng: 19.7575198 },
    { lat: 48.9357629, lng: 19.7573427 },
    { lat: 48.9354616, lng: 19.757329 },
    { lat: 48.93477, lng: 19.7574886 },
    { lat: 48.9347276, lng: 19.7584044 },
    { lat: 48.9348133, lng: 19.7585065 },
    { lat: 48.9346584, lng: 19.7588038 },
    { lat: 48.9345981, lng: 19.7590499 },
    { lat: 48.9346005, lng: 19.7593363 },
    { lat: 48.9344917, lng: 19.759601 },
    { lat: 48.9343391, lng: 19.7598489 },
    { lat: 48.9342586, lng: 19.7600161 },
    { lat: 48.9341408, lng: 19.7601552 },
    { lat: 48.9340717, lng: 19.7602754 },
    { lat: 48.9339889, lng: 19.7603697 },
    { lat: 48.9338864, lng: 19.7602933 },
    { lat: 48.9335372, lng: 19.7599293 },
    { lat: 48.9334845, lng: 19.7598106 },
    { lat: 48.9334354, lng: 19.7595615 },
    { lat: 48.9333793, lng: 19.7592819 },
    { lat: 48.9333618, lng: 19.7590594 },
    { lat: 48.9332361, lng: 19.7588692 },
    { lat: 48.9331568, lng: 19.7585985 },
    { lat: 48.9330753, lng: 19.7584089 },
    { lat: 48.9329128, lng: 19.7582518 },
    { lat: 48.9327513, lng: 19.7580613 },
    { lat: 48.932626, lng: 19.7579492 },
    { lat: 48.9325079, lng: 19.7578017 },
    { lat: 48.9323085, lng: 19.7577225 },
    { lat: 48.9321593, lng: 19.7576107 },
    { lat: 48.9320072, lng: 19.7574297 },
    { lat: 48.9318916, lng: 19.7573345 },
    { lat: 48.9319027, lng: 19.7584424 },
    { lat: 48.931953, lng: 19.7636654 },
    { lat: 48.9319545, lng: 19.764063 },
    { lat: 48.9318996, lng: 19.7648181 },
    { lat: 48.9317288, lng: 19.7672531 },
    { lat: 48.9316167, lng: 19.7688122 },
    { lat: 48.9315178, lng: 19.7701113 },
    { lat: 48.9308816, lng: 19.7705335 },
    { lat: 48.9299732, lng: 19.7706807 },
    { lat: 48.9291819, lng: 19.7711571 },
    { lat: 48.9288925, lng: 19.771212 },
    { lat: 48.9281287, lng: 19.7715775 },
    { lat: 48.9279367, lng: 19.7718261 },
    { lat: 48.9277577, lng: 19.7721608 },
    { lat: 48.9274568, lng: 19.7725188 },
    { lat: 48.927466, lng: 19.7725996 },
    { lat: 48.9273023, lng: 19.7729825 },
    { lat: 48.9271472, lng: 19.7731715 },
    { lat: 48.9269829, lng: 19.7734595 },
    { lat: 48.9269463, lng: 19.7740669 },
    { lat: 48.9271416, lng: 19.7750598 },
    { lat: 48.9269635, lng: 19.7753933 },
    { lat: 48.9268375, lng: 19.7757076 },
    { lat: 48.9265872, lng: 19.7759602 },
    { lat: 48.9265654, lng: 19.7759814 },
    { lat: 48.9264802, lng: 19.7760677 },
    { lat: 48.9262577, lng: 19.7764128 },
    { lat: 48.9259808, lng: 19.7761851 },
    { lat: 48.9256793, lng: 19.7765317 },
    { lat: 48.9254897, lng: 19.7767084 },
    { lat: 48.9251586, lng: 19.7768645 },
    { lat: 48.9250822, lng: 19.7767689 },
    { lat: 48.9250099, lng: 19.7769937 },
    { lat: 48.9247323, lng: 19.7775256 },
    { lat: 48.9246485, lng: 19.7779331 },
    { lat: 48.9245847, lng: 19.7785112 },
    { lat: 48.9247049, lng: 19.7785006 },
    { lat: 48.9247302, lng: 19.7791859 },
    { lat: 48.9250616, lng: 19.7799645 },
    { lat: 48.9248688, lng: 19.7801457 },
    { lat: 48.9248544, lng: 19.7804066 },
    { lat: 48.925104, lng: 19.7808354 },
    { lat: 48.9253752, lng: 19.781528 },
    { lat: 48.9255062, lng: 19.7820537 },
    { lat: 48.9257096, lng: 19.782302 },
    { lat: 48.9255372, lng: 19.7831805 },
    { lat: 48.9252621, lng: 19.7836931 },
    { lat: 48.9249246, lng: 19.7837549 },
    { lat: 48.9249684, lng: 19.7841439 },
    { lat: 48.9247217, lng: 19.7841163 },
    { lat: 48.9246707, lng: 19.784576 },
    { lat: 48.9235971, lng: 19.7844965 },
    { lat: 48.9234771, lng: 19.7847708 },
    { lat: 48.923392, lng: 19.7846489 },
    { lat: 48.9231788, lng: 19.7849318 },
    { lat: 48.9231165, lng: 19.7849219 },
    { lat: 48.9228956, lng: 19.7854377 },
    { lat: 48.9224503, lng: 19.7864957 },
    { lat: 48.9223758, lng: 19.7866704 },
    { lat: 48.9222082, lng: 19.7870691 },
    { lat: 48.9216463, lng: 19.7875889 },
    { lat: 48.9212985, lng: 19.7877545 },
    { lat: 48.9207857, lng: 19.787205 },
    { lat: 48.9205902, lng: 19.787346 },
    { lat: 48.9191613, lng: 19.7867938 },
    { lat: 48.919014, lng: 19.7865388 },
    { lat: 48.917694, lng: 19.7859495 },
    { lat: 48.9170831, lng: 19.786 },
    { lat: 48.9170134, lng: 19.7862319 },
    { lat: 48.9167901, lng: 19.7862263 },
    { lat: 48.9165265, lng: 19.7863039 },
    { lat: 48.9165011, lng: 19.7870673 },
    { lat: 48.9165637, lng: 19.7871323 },
    { lat: 48.916593, lng: 19.787422 },
    { lat: 48.9166473, lng: 19.7878381 },
    { lat: 48.916471, lng: 19.7883171 },
    { lat: 48.9164102, lng: 19.7886037 },
    { lat: 48.9162497, lng: 19.7886204 },
    { lat: 48.9159158, lng: 19.7890744 },
    { lat: 48.9158499, lng: 19.789557 },
    { lat: 48.9156328, lng: 19.7897703 },
    { lat: 48.9152642, lng: 19.7904895 },
    { lat: 48.9151848, lng: 19.7906852 },
    { lat: 48.9155032, lng: 19.7912963 },
    { lat: 48.9157348, lng: 19.7916506 },
    { lat: 48.9158331, lng: 19.7916042 },
    { lat: 48.9160499, lng: 19.7916922 },
    { lat: 48.9162653, lng: 19.7920102 },
    { lat: 48.9160827, lng: 19.7921388 },
    { lat: 48.9160152, lng: 19.7923228 },
    { lat: 48.9160236, lng: 19.7924416 },
    { lat: 48.9155603, lng: 19.7924052 },
    { lat: 48.9152348, lng: 19.7925907 },
    { lat: 48.9149938, lng: 19.7927229 },
    { lat: 48.9149357, lng: 19.7926768 },
    { lat: 48.9147839, lng: 19.7928414 },
    { lat: 48.9146787, lng: 19.7928852 },
    { lat: 48.9143651, lng: 19.793211 },
    { lat: 48.9141778, lng: 19.7936208 },
    { lat: 48.9140215, lng: 19.7940179 },
    { lat: 48.913981, lng: 19.7945628 },
    { lat: 48.9138803, lng: 19.7948364 },
    { lat: 48.9137133, lng: 19.7950408 },
    { lat: 48.9137443, lng: 19.7954138 },
    { lat: 48.9139093, lng: 19.7957386 },
    { lat: 48.9139723, lng: 19.7957105 },
    { lat: 48.91402, lng: 19.7959031 },
    { lat: 48.9138521, lng: 19.7962644 },
    { lat: 48.9137228, lng: 19.7964525 },
    { lat: 48.913634, lng: 19.796793 },
    { lat: 48.9136504, lng: 19.7983895 },
    { lat: 48.912918, lng: 19.7982352 },
    { lat: 48.9135798, lng: 19.7986845 },
    { lat: 48.9139103, lng: 19.7986185 },
    { lat: 48.9143464, lng: 19.7987791 },
    { lat: 48.915112, lng: 19.7991336 },
    { lat: 48.9153911, lng: 19.7991766 },
    { lat: 48.9155534, lng: 19.7993048 },
    { lat: 48.9156356, lng: 19.7993345 },
    { lat: 48.9157133, lng: 19.799383 },
    { lat: 48.9160581, lng: 19.7994904 },
    { lat: 48.9162214, lng: 19.799592 },
    { lat: 48.9163243, lng: 19.799625 },
    { lat: 48.9165147, lng: 19.799654 },
    { lat: 48.9167736, lng: 19.7995923 },
    { lat: 48.9168654, lng: 19.7996355 },
    { lat: 48.9169644, lng: 19.799632 },
    { lat: 48.9172682, lng: 19.7996758 },
    { lat: 48.9175021, lng: 19.7997678 },
    { lat: 48.917717, lng: 19.7997632 },
    { lat: 48.9178703, lng: 19.7997662 },
    { lat: 48.9184003, lng: 19.7996728 },
    { lat: 48.9186891, lng: 19.7996805 },
    { lat: 48.9188098, lng: 19.7997096 },
    { lat: 48.9190553, lng: 19.7998882 },
    { lat: 48.9191273, lng: 19.7999659 },
    { lat: 48.9192149, lng: 19.8002208 },
    { lat: 48.919302, lng: 19.8002819 },
    { lat: 48.9193404, lng: 19.8004382 },
    { lat: 48.9194985, lng: 19.800757 },
    { lat: 48.919485, lng: 19.8008382 },
    { lat: 48.919612, lng: 19.8010398 },
    { lat: 48.9196585, lng: 19.8012013 },
    { lat: 48.9197062, lng: 19.8012632 },
    { lat: 48.9197393, lng: 19.8015078 },
    { lat: 48.9197789, lng: 19.8016385 },
    { lat: 48.9197714, lng: 19.8018015 },
    { lat: 48.9198591, lng: 19.8019848 },
    { lat: 48.9200429, lng: 19.8028426 },
    { lat: 48.920086, lng: 19.8030307 },
    { lat: 48.9201529, lng: 19.8034574 },
    { lat: 48.9202587, lng: 19.8039007 },
    { lat: 48.9204097, lng: 19.8042363 },
    { lat: 48.9206329, lng: 19.8044741 },
    { lat: 48.920805, lng: 19.8047296 },
    { lat: 48.9208441, lng: 19.8048525 },
    { lat: 48.9209262, lng: 19.8049274 },
    { lat: 48.9209445, lng: 19.8050337 },
    { lat: 48.9211605, lng: 19.805422 },
    { lat: 48.9212492, lng: 19.805517 },
    { lat: 48.9213042, lng: 19.8055398 },
    { lat: 48.9213678, lng: 19.8055267 },
    { lat: 48.9214539, lng: 19.8055615 },
    { lat: 48.9215862, lng: 19.8055984 },
    { lat: 48.9218038, lng: 19.8056011 },
    { lat: 48.9220465, lng: 19.8055636 },
    { lat: 48.9225928, lng: 19.8055582 },
    { lat: 48.9226837, lng: 19.8056025 },
    { lat: 48.923007, lng: 19.8056064 },
    { lat: 48.9231918, lng: 19.8056041 },
    { lat: 48.923239, lng: 19.8055783 },
    { lat: 48.9233857, lng: 19.805607 },
    { lat: 48.9239386, lng: 19.8057607 },
    { lat: 48.9242476, lng: 19.8058692 },
    { lat: 48.9245928, lng: 19.8060605 },
    { lat: 48.9247167, lng: 19.8061416 },
    { lat: 48.9248989, lng: 19.806213 },
    { lat: 48.9253641, lng: 19.8065478 },
    { lat: 48.9256265, lng: 19.8067305 },
    { lat: 48.925893, lng: 19.8068988 },
    { lat: 48.9260751, lng: 19.8069953 },
    { lat: 48.9263688, lng: 19.8071699 },
    { lat: 48.9265612, lng: 19.807294 },
    { lat: 48.9266431, lng: 19.8073653 },
    { lat: 48.9268823, lng: 19.8075514 },
    { lat: 48.9272645, lng: 19.8079006 },
    { lat: 48.9273381, lng: 19.8079613 },
    { lat: 48.9275425, lng: 19.8082039 },
    { lat: 48.9276991, lng: 19.8083112 },
    { lat: 48.9279917, lng: 19.808564 },
    { lat: 48.928184, lng: 19.8087626 },
    { lat: 48.9282858, lng: 19.8088797 },
    { lat: 48.9285587, lng: 19.8093003 },
    { lat: 48.9287177, lng: 19.8094618 },
    { lat: 48.9288341, lng: 19.8095215 },
    { lat: 48.9289485, lng: 19.8095631 },
    { lat: 48.9291171, lng: 19.8096025 },
    { lat: 48.9292962, lng: 19.8096538 },
    { lat: 48.9294883, lng: 19.8096963 },
    { lat: 48.9296662, lng: 19.8097431 },
    { lat: 48.9300634, lng: 19.809812 },
    { lat: 48.9305379, lng: 19.8098178 },
    { lat: 48.9308376, lng: 19.8097664 },
    { lat: 48.9310567, lng: 19.809703 },
    { lat: 48.9315114, lng: 19.8095344 },
    { lat: 48.9319102, lng: 19.8094807 },
    { lat: 48.9320242, lng: 19.8095134 },
    { lat: 48.9323606, lng: 19.8096325 },
    { lat: 48.9325177, lng: 19.8097229 },
    { lat: 48.9329738, lng: 19.8097455 },
    { lat: 48.9333455, lng: 19.809826 },
    { lat: 48.933544, lng: 19.8098292 },
    { lat: 48.9337075, lng: 19.8098053 },
    { lat: 48.9338104, lng: 19.8098169 },
    { lat: 48.933889, lng: 19.8098593 },
    { lat: 48.93416, lng: 19.8100841 },
    { lat: 48.9343942, lng: 19.8103123 },
    { lat: 48.934574, lng: 19.8105904 },
    { lat: 48.9346661, lng: 19.8106894 },
    { lat: 48.9349922, lng: 19.8109901 },
    { lat: 48.935112, lng: 19.8110742 },
    { lat: 48.9355405, lng: 19.8113436 },
    { lat: 48.9356879, lng: 19.8113938 },
    { lat: 48.9362108, lng: 19.8113721 },
    { lat: 48.9363407, lng: 19.8113796 },
    { lat: 48.9367042, lng: 19.8114491 },
    { lat: 48.9369137, lng: 19.811421 },
    { lat: 48.9373504, lng: 19.8113095 },
    { lat: 48.9375287, lng: 19.8112361 },
    { lat: 48.9379872, lng: 19.8109522 },
    { lat: 48.9380536, lng: 19.8109767 },
    { lat: 48.9383056, lng: 19.8108652 },
    { lat: 48.938452, lng: 19.8107568 },
    { lat: 48.9387954, lng: 19.8106226 },
    { lat: 48.9391068, lng: 19.8105317 },
    { lat: 48.939202, lng: 19.810414 },
    { lat: 48.9392818, lng: 19.8104354 },
    { lat: 48.9393561, lng: 19.8104069 },
    { lat: 48.9394233, lng: 19.8103453 },
    { lat: 48.939634, lng: 19.8101136 },
    { lat: 48.9397344, lng: 19.8100108 },
    { lat: 48.9399901, lng: 19.8098545 },
    { lat: 48.9401291, lng: 19.8096278 },
    { lat: 48.9402509, lng: 19.8094763 },
    { lat: 48.9403117, lng: 19.8094236 },
    { lat: 48.9404953, lng: 19.8093189 },
    { lat: 48.940707, lng: 19.8091115 },
    { lat: 48.9408875, lng: 19.8089838 },
    { lat: 48.94116, lng: 19.8087237 },
    { lat: 48.9413933, lng: 19.8084145 },
    { lat: 48.9416464, lng: 19.8081965 },
    { lat: 48.9419764, lng: 19.808065 },
    { lat: 48.9424387, lng: 19.807968 },
    { lat: 48.9425128, lng: 19.8079635 },
    { lat: 48.9431996, lng: 19.8080577 },
    { lat: 48.9435421, lng: 19.8080841 },
    { lat: 48.9439558, lng: 19.8080647 },
    { lat: 48.9442847, lng: 19.808038 },
    { lat: 48.9445593, lng: 19.8080568 },
    { lat: 48.9448646, lng: 19.8077698 },
    { lat: 48.9451563, lng: 19.8075368 },
    { lat: 48.9452907, lng: 19.8073327 },
    { lat: 48.9454923, lng: 19.8073105 },
    { lat: 48.9457899, lng: 19.8071288 },
    { lat: 48.9460758, lng: 19.8069935 },
    { lat: 48.9464117, lng: 19.8069478 },
    { lat: 48.9465578, lng: 19.8068612 },
    { lat: 48.9467371, lng: 19.8068358 },
    { lat: 48.9469062, lng: 19.806658 },
    { lat: 48.9471946, lng: 19.8064765 },
    { lat: 48.9479466, lng: 19.8064635 },
    { lat: 48.9483233, lng: 19.8066044 },
    { lat: 48.9484766, lng: 19.8067644 },
    { lat: 48.948858, lng: 19.8070399 },
    { lat: 48.9489312, lng: 19.8070613 },
    { lat: 48.9491934, lng: 19.8070832 },
    { lat: 48.9498739, lng: 19.8070314 },
    { lat: 48.9503134, lng: 19.8070621 },
    { lat: 48.9506911, lng: 19.8070424 },
    { lat: 48.951, lng: 19.8070994 },
    { lat: 48.9511557, lng: 19.8071562 },
    { lat: 48.951261, lng: 19.807221 },
    { lat: 48.9513622, lng: 19.8073231 },
    { lat: 48.9516511, lng: 19.8078527 },
    { lat: 48.9518809, lng: 19.8083237 },
    { lat: 48.9520498, lng: 19.8086875 },
    { lat: 48.952134, lng: 19.8088096 },
    { lat: 48.9524086, lng: 19.8091665 },
    { lat: 48.9527462, lng: 19.8097282 },
    { lat: 48.9529371, lng: 19.8099776 },
    { lat: 48.9532098, lng: 19.8102916 },
    { lat: 48.9533444, lng: 19.8105636 },
    { lat: 48.9538205, lng: 19.8111047 },
    { lat: 48.9539826, lng: 19.8112821 },
    { lat: 48.9544272, lng: 19.8110944 },
    { lat: 48.9550077, lng: 19.8108648 },
    { lat: 48.9556248, lng: 19.8107011 },
    { lat: 48.9559416, lng: 19.8106297 },
    { lat: 48.9562752, lng: 19.8106305 },
    { lat: 48.9564341, lng: 19.8106602 },
    { lat: 48.9564966, lng: 19.8107504 },
    { lat: 48.956596, lng: 19.8107573 },
    { lat: 48.9568212, lng: 19.810756 },
    { lat: 48.9569989, lng: 19.8107993 },
    { lat: 48.9572637, lng: 19.8109345 },
    { lat: 48.9574489, lng: 19.8109967 },
    { lat: 48.9575772, lng: 19.8110165 },
    { lat: 48.9577814, lng: 19.811103 },
    { lat: 48.9579058, lng: 19.8111691 },
    { lat: 48.9580824, lng: 19.8112393 },
    { lat: 48.9582176, lng: 19.8112667 },
    { lat: 48.958358, lng: 19.8112255 },
    { lat: 48.9584932, lng: 19.8111489 },
    { lat: 48.958617, lng: 19.8111502 },
    { lat: 48.9589013, lng: 19.8112065 },
    { lat: 48.959092, lng: 19.8113212 },
    { lat: 48.9595177, lng: 19.8113995 },
    { lat: 48.9597105, lng: 19.8115069 },
    { lat: 48.9598183, lng: 19.8115219 },
    { lat: 48.9598957, lng: 19.811487 },
    { lat: 48.9600209, lng: 19.8113637 },
    { lat: 48.9602482, lng: 19.8109105 },
    { lat: 48.9603172, lng: 19.810814 },
    { lat: 48.9605183, lng: 19.8105953 },
    { lat: 48.9607297, lng: 19.8100397 },
    { lat: 48.9608598, lng: 19.8098185 },
    { lat: 48.9609408, lng: 19.8097373 },
    { lat: 48.9610866, lng: 19.8096152 },
    { lat: 48.9615374, lng: 19.8095695 },
    { lat: 48.9617108, lng: 19.8095393 },
    { lat: 48.9618095, lng: 19.8095076 },
    { lat: 48.9620522, lng: 19.8093608 },
    { lat: 48.9622715, lng: 19.809245 },
    { lat: 48.9624044, lng: 19.8092174 },
    { lat: 48.9626585, lng: 19.809154 },
    { lat: 48.9629385, lng: 19.8090942 },
    { lat: 48.9638944, lng: 19.8089225 },
    { lat: 48.9643086, lng: 19.808784 },
    { lat: 48.9646468, lng: 19.8086603 },
    { lat: 48.9652208, lng: 19.8081516 },
    { lat: 48.965433, lng: 19.8075898 },
    { lat: 48.9655073, lng: 19.8073027 },
    { lat: 48.9655974, lng: 19.8071223 },
    { lat: 48.9658608, lng: 19.8068261 },
    { lat: 48.9662077, lng: 19.8065677 },
    { lat: 48.9665495, lng: 19.806107 },
    { lat: 48.966625, lng: 19.8060309 },
    { lat: 48.9667071, lng: 19.8058987 },
    { lat: 48.9670358, lng: 19.8051104 },
    { lat: 48.967131, lng: 19.804969 },
    { lat: 48.9672806, lng: 19.8046507 },
    { lat: 48.9673598, lng: 19.804267 },
    { lat: 48.9674885, lng: 19.8038325 },
    { lat: 48.9680066, lng: 19.8030497 },
    { lat: 48.9680711, lng: 19.8028231 },
    { lat: 48.9681225, lng: 19.8026792 },
    { lat: 48.9681413, lng: 19.8025708 },
    { lat: 48.9681812, lng: 19.802422 },
    { lat: 48.9683429, lng: 19.8020738 },
    { lat: 48.9686148, lng: 19.8013306 },
    { lat: 48.9689546, lng: 19.8004889 },
    { lat: 48.9691286, lng: 19.8001613 },
    { lat: 48.9694433, lng: 19.7998566 },
    { lat: 48.9695353, lng: 19.7997463 },
    { lat: 48.9696954, lng: 19.7994367 },
    { lat: 48.9698148, lng: 19.7989403 },
    { lat: 48.9698405, lng: 19.7985469 },
    { lat: 48.9698978, lng: 19.7983332 },
    { lat: 48.9699899, lng: 19.7979633 },
    { lat: 48.9700226, lng: 19.7977051 },
    { lat: 48.9700707, lng: 19.7974102 },
    { lat: 48.9701881, lng: 19.7969 },
    { lat: 48.9702099, lng: 19.7968308 },
    { lat: 48.9703264, lng: 19.7967073 },
    { lat: 48.9705309, lng: 19.7962307 },
    { lat: 48.9705654, lng: 19.7960885 },
    { lat: 48.9705938, lng: 19.7958078 },
    { lat: 48.9706201, lng: 19.7948298 },
    { lat: 48.9706553, lng: 19.7942913 },
    { lat: 48.9707949, lng: 19.7937972 },
    { lat: 48.9709313, lng: 19.7935139 },
    { lat: 48.9710948, lng: 19.7932559 },
    { lat: 48.9712475, lng: 19.7928038 },
    { lat: 48.9715074, lng: 19.7922737 },
    { lat: 48.9717344, lng: 19.791825 },
    { lat: 48.9721844, lng: 19.7909075 },
    { lat: 48.9723154, lng: 19.7906967 },
    { lat: 48.972378, lng: 19.7904807 },
    { lat: 48.9723773, lng: 19.7902029 },
    { lat: 48.9723063, lng: 19.789192 },
    { lat: 48.9723334, lng: 19.7890612 },
    { lat: 48.9724067, lng: 19.7888577 },
    { lat: 48.9727285, lng: 19.7880976 },
    { lat: 48.9728585, lng: 19.7876646 },
    { lat: 48.9729734, lng: 19.787354 },
    { lat: 48.973126, lng: 19.7869751 },
    { lat: 48.9733875, lng: 19.7864856 },
    { lat: 48.9734318, lng: 19.7863919 },
    { lat: 48.9735041, lng: 19.7861863 },
    { lat: 48.9736088, lng: 19.7858467 },
    { lat: 48.9737371, lng: 19.785169 },
    { lat: 48.9738055, lng: 19.7848534 },
    { lat: 48.9738464, lng: 19.784756 },
    { lat: 48.9739264, lng: 19.7846523 },
    { lat: 48.9741767, lng: 19.7844532 },
    { lat: 48.9742728, lng: 19.7843572 },
    { lat: 48.9743709, lng: 19.7842301 },
    { lat: 48.9747259, lng: 19.7837398 },
    { lat: 48.9750849, lng: 19.7832103 },
    { lat: 48.9754299, lng: 19.7826751 },
    { lat: 48.9756468, lng: 19.7822725 },
    { lat: 48.9759534, lng: 19.7818645 },
    { lat: 48.976194, lng: 19.7815384 },
    { lat: 48.9764777, lng: 19.7810699 },
    { lat: 48.9768061, lng: 19.7805388 },
    { lat: 48.9768972, lng: 19.7803805 },
    { lat: 48.9769891, lng: 19.7803444 },
    { lat: 48.9774163, lng: 19.7802448 },
    { lat: 48.9775582, lng: 19.7801912 },
    { lat: 48.9777614, lng: 19.7800987 },
    { lat: 48.9780812, lng: 19.7798076 },
    { lat: 48.9783079, lng: 19.7795055 },
    { lat: 48.9784084, lng: 19.7793301 },
    { lat: 48.9779795, lng: 19.7790285 },
    { lat: 48.9780024, lng: 19.778773 },
    { lat: 48.977956, lng: 19.7786127 },
    { lat: 48.9779124, lng: 19.7783911 },
    { lat: 48.9778904, lng: 19.778091 },
    { lat: 48.9779021, lng: 19.7778126 },
    { lat: 48.9779772, lng: 19.7778087 },
    { lat: 48.9779681, lng: 19.7777296 },
    { lat: 48.978011, lng: 19.7775481 },
    { lat: 48.9778131, lng: 19.7775304 },
    { lat: 48.9775904, lng: 19.7773325 },
    { lat: 48.9775276, lng: 19.7772348 },
    { lat: 48.9775019, lng: 19.7770312 },
    { lat: 48.9775791, lng: 19.776814 },
    { lat: 48.9776448, lng: 19.7764574 },
    { lat: 48.9777798, lng: 19.7759902 },
    { lat: 48.9778315, lng: 19.7758824 },
    { lat: 48.9778575, lng: 19.775883 },
    { lat: 48.977867, lng: 19.7756396 },
    { lat: 48.9779365, lng: 19.7753974 },
    { lat: 48.978049, lng: 19.7754707 },
    { lat: 48.9780911, lng: 19.7755332 },
    { lat: 48.9782907, lng: 19.775535 },
    { lat: 48.9782915, lng: 19.7755048 },
    { lat: 48.9784713, lng: 19.7754928 },
    { lat: 48.9784798, lng: 19.7751929 },
    { lat: 48.9785254, lng: 19.7747673 },
    { lat: 48.9786159, lng: 19.7742091 },
    { lat: 48.9786585, lng: 19.773839 },
    { lat: 48.9786761, lng: 19.7736504 },
    { lat: 48.9786733, lng: 19.7734559 },
    { lat: 48.9786528, lng: 19.7732464 },
    { lat: 48.9786141, lng: 19.7730509 },
    { lat: 48.9785603, lng: 19.773085 },
    { lat: 48.978312, lng: 19.7732053 },
    { lat: 48.9781781, lng: 19.7732374 },
    { lat: 48.977826, lng: 19.7730935 },
    { lat: 48.9771183, lng: 19.7726712 },
    { lat: 48.9767677, lng: 19.7725124 },
    { lat: 48.9763663, lng: 19.772684 },
    { lat: 48.9760114, lng: 19.7725798 },
    { lat: 48.9747661, lng: 19.7719999 },
    { lat: 48.9741434, lng: 19.7713345 },
    { lat: 48.9735572, lng: 19.7705737 },
    { lat: 48.9730058, lng: 19.7700238 },
    { lat: 48.9715942, lng: 19.7696372 },
    { lat: 48.9713515, lng: 19.7689254 },
    { lat: 48.9711491, lng: 19.7684434 },
    { lat: 48.9709236, lng: 19.7670938 },
    { lat: 48.970653, lng: 19.7666541 },
    { lat: 48.9703175, lng: 19.7662407 },
    { lat: 48.9699126, lng: 19.7661538 },
    { lat: 48.9697156, lng: 19.7660324 },
    { lat: 48.9694066, lng: 19.7657884 },
    { lat: 48.9691615, lng: 19.7653875 },
    { lat: 48.969051, lng: 19.7650511 },
    { lat: 48.9689142, lng: 19.7647569 },
    { lat: 48.968279, lng: 19.7636763 },
    { lat: 48.967724, lng: 19.7626536 },
    { lat: 48.9671215, lng: 19.7616741 },
    { lat: 48.96646, lng: 19.7604847 },
    { lat: 48.9662713, lng: 19.7593628 },
    { lat: 48.9663275, lng: 19.7588942 },
    { lat: 48.9657551, lng: 19.7580953 },
    { lat: 48.9649041, lng: 19.7572334 },
    { lat: 48.9646462, lng: 19.7567438 },
    { lat: 48.964328, lng: 19.7557774 },
    { lat: 48.9640399, lng: 19.7549879 },
    { lat: 48.9637842, lng: 19.7544737 },
    { lat: 48.9634801, lng: 19.7539608 },
    { lat: 48.9628143, lng: 19.7533439 },
    { lat: 48.9622855, lng: 19.753256 },
    { lat: 48.9616672, lng: 19.7531685 },
    { lat: 48.9608141, lng: 19.7530477 },
    { lat: 48.9603285, lng: 19.7529192 },
    { lat: 48.9594469, lng: 19.75275 },
    { lat: 48.959283, lng: 19.7523008 },
    { lat: 48.9588981, lng: 19.7515366 },
    { lat: 48.9584146, lng: 19.7507866 },
    { lat: 48.9581151, lng: 19.7500181 },
    { lat: 48.9571358, lng: 19.749519 },
    { lat: 48.9561409, lng: 19.7479973 },
    { lat: 48.9560172, lng: 19.7473132 },
    { lat: 48.9558083, lng: 19.7463481 },
    { lat: 48.9554385, lng: 19.745338 },
    { lat: 48.9552648, lng: 19.7448167 },
    { lat: 48.9551231, lng: 19.7443098 },
    { lat: 48.9549276, lng: 19.7430376 },
    { lat: 48.9548598, lng: 19.742391 },
    { lat: 48.9541603, lng: 19.740903 },
    { lat: 48.9539126, lng: 19.7405968 },
    { lat: 48.9536772, lng: 19.7402131 },
    { lat: 48.9534461, lng: 19.7395672 },
    { lat: 48.9536023, lng: 19.7375805 },
    { lat: 48.9524126, lng: 19.7368904 },
    { lat: 48.9516043, lng: 19.736418 },
    { lat: 48.9509346, lng: 19.7356609 },
    { lat: 48.9499396, lng: 19.7345271 },
    { lat: 48.9499355, lng: 19.7334562 },
    { lat: 48.9497991, lng: 19.7321455 },
    { lat: 48.9495331, lng: 19.7314803 },
    { lat: 48.9492282, lng: 19.7302577 },
    { lat: 48.9485939, lng: 19.7285412 },
    { lat: 48.9483677, lng: 19.7278046 },
    { lat: 48.9481098, lng: 19.7270333 },
    { lat: 48.9475162, lng: 19.7256317 },
    { lat: 48.9461938, lng: 19.7230002 },
    { lat: 48.9456828, lng: 19.7214467 },
    { lat: 48.9436593, lng: 19.718992 },
    { lat: 48.9429271, lng: 19.7181488 },
    { lat: 48.9411941, lng: 19.716378 },
    { lat: 48.940295, lng: 19.7140036 },
    { lat: 48.940133, lng: 19.713418 },
  ],
  PartizánskaĽupča: [
    { lat: 49.0723789, lng: 19.4099686 },
    { lat: 49.071217, lng: 19.410143 },
    { lat: 49.070398, lng: 19.410266 },
    { lat: 49.070394, lng: 19.411043 },
    { lat: 49.07024, lng: 19.411071 },
    { lat: 49.070159, lng: 19.411797 },
    { lat: 49.07002, lng: 19.411905 },
    { lat: 49.069943, lng: 19.411965 },
    { lat: 49.06961, lng: 19.412223 },
    { lat: 49.069478, lng: 19.412382 },
    { lat: 49.069183, lng: 19.413137 },
    { lat: 49.069103, lng: 19.41334 },
    { lat: 49.068426, lng: 19.415398 },
    { lat: 49.068379, lng: 19.415461 },
    { lat: 49.068377, lng: 19.415463 },
    { lat: 49.068011, lng: 19.416047 },
    { lat: 49.067821, lng: 19.416536 },
    { lat: 49.067766, lng: 19.41682 },
    { lat: 49.06727, lng: 19.417335 },
    { lat: 49.067263, lng: 19.417407 },
    { lat: 49.067153, lng: 19.418604 },
    { lat: 49.067042, lng: 19.418611 },
    { lat: 49.0668, lng: 19.418541 },
    { lat: 49.066186, lng: 19.418539 },
    { lat: 49.065976, lng: 19.418497 },
    { lat: 49.065791, lng: 19.418557 },
    { lat: 49.065612, lng: 19.418699 },
    { lat: 49.065454, lng: 19.418815 },
    { lat: 49.06542, lng: 19.418818 },
    { lat: 49.064983, lng: 19.418868 },
    { lat: 49.064799, lng: 19.418864 },
    { lat: 49.064466, lng: 19.418753 },
    { lat: 49.064032, lng: 19.418811 },
    { lat: 49.063606, lng: 19.418816 },
    { lat: 49.063187, lng: 19.418811 },
    { lat: 49.062644, lng: 19.41887 },
    { lat: 49.0625, lng: 19.418941 },
    { lat: 49.062364, lng: 19.419061 },
    { lat: 49.062003, lng: 19.419335 },
    { lat: 49.061697, lng: 19.419453 },
    { lat: 49.061346, lng: 19.419803 },
    { lat: 49.061101, lng: 19.420018 },
    { lat: 49.060897, lng: 19.420137 },
    { lat: 49.060662, lng: 19.420293 },
    { lat: 49.060551, lng: 19.420335 },
    { lat: 49.05995, lng: 19.420578 },
    { lat: 49.059673, lng: 19.420937 },
    { lat: 49.059561, lng: 19.421037 },
    { lat: 49.059436, lng: 19.421136 },
    { lat: 49.059307, lng: 19.421191 },
    { lat: 49.058768, lng: 19.42141 },
    { lat: 49.058522, lng: 19.421441 },
    { lat: 49.058449, lng: 19.421414 },
    { lat: 49.058093, lng: 19.421428 },
    { lat: 49.057818, lng: 19.421461 },
    { lat: 49.057476, lng: 19.421437 },
    { lat: 49.057287, lng: 19.421443 },
    { lat: 49.056878, lng: 19.421477 },
    { lat: 49.056596, lng: 19.421506 },
    { lat: 49.056434, lng: 19.421526 },
    { lat: 49.05636, lng: 19.421534 },
    { lat: 49.05628, lng: 19.421543 },
    { lat: 49.055437, lng: 19.421633 },
    { lat: 49.05515, lng: 19.421627 },
    { lat: 49.054885, lng: 19.421672 },
    { lat: 49.054756, lng: 19.421687 },
    { lat: 49.05448, lng: 19.421777 },
    { lat: 49.054341, lng: 19.421792 },
    { lat: 49.054098, lng: 19.421791 },
    { lat: 49.053781, lng: 19.42169 },
    { lat: 49.053443, lng: 19.421636 },
    { lat: 49.053302, lng: 19.421661 },
    { lat: 49.053072, lng: 19.4216 },
    { lat: 49.052808, lng: 19.421566 },
    { lat: 49.052451, lng: 19.42156 },
    { lat: 49.052165, lng: 19.421509 },
    { lat: 49.051825, lng: 19.421374 },
    { lat: 49.051414, lng: 19.421054 },
    { lat: 49.0512, lng: 19.421198 },
    { lat: 49.051148, lng: 19.421374 },
    { lat: 49.051049, lng: 19.421307 },
    { lat: 49.050708, lng: 19.421498 },
    { lat: 49.05074, lng: 19.421165 },
    { lat: 49.050812, lng: 19.420792 },
    { lat: 49.050605, lng: 19.420709 },
    { lat: 49.050652, lng: 19.42039 },
    { lat: 49.049872, lng: 19.42087 },
    { lat: 49.049906, lng: 19.420088 },
    { lat: 49.050063, lng: 19.420144 },
    { lat: 49.050154, lng: 19.419879 },
    { lat: 49.049894, lng: 19.419719 },
    { lat: 49.049992, lng: 19.4193 },
    { lat: 49.049995, lng: 19.41929 },
    { lat: 49.050023, lng: 19.419169 },
    { lat: 49.049596, lng: 19.418399 },
    { lat: 49.049561, lng: 19.418361 },
    { lat: 49.049507, lng: 19.418306 },
    { lat: 49.049389, lng: 19.418186 },
    { lat: 49.048857, lng: 19.417644 },
    { lat: 49.048299, lng: 19.417302 },
    { lat: 49.047592, lng: 19.41632 },
    { lat: 49.04708, lng: 19.415089 },
    { lat: 49.046727, lng: 19.413941 },
    { lat: 49.046356, lng: 19.413086 },
    { lat: 49.045968, lng: 19.411926 },
    { lat: 49.045699, lng: 19.411279 },
    { lat: 49.045425, lng: 19.410694 },
    { lat: 49.044623, lng: 19.409888 },
    { lat: 49.043871, lng: 19.40941 },
    { lat: 49.043415, lng: 19.40915 },
    { lat: 49.043067, lng: 19.408921 },
    { lat: 49.042685, lng: 19.408515 },
    { lat: 49.041841, lng: 19.407617 },
    { lat: 49.041474, lng: 19.407227 },
    { lat: 49.041471, lng: 19.407164 },
    { lat: 49.041482, lng: 19.40714 },
    { lat: 49.041217, lng: 19.406869 },
    { lat: 49.040921, lng: 19.4067 },
    { lat: 49.040587, lng: 19.40661 },
    { lat: 49.040344, lng: 19.406598 },
    { lat: 49.039724, lng: 19.406217 },
    { lat: 49.039257, lng: 19.405988 },
    { lat: 49.039166, lng: 19.405944 },
    { lat: 49.038971, lng: 19.405849 },
    { lat: 49.038968, lng: 19.405871 },
    { lat: 49.038123, lng: 19.405184 },
    { lat: 49.037203, lng: 19.404742 },
    { lat: 49.03676, lng: 19.404345 },
    { lat: 49.035987, lng: 19.403548 },
    { lat: 49.035128, lng: 19.403228 },
    { lat: 49.034555, lng: 19.402946 },
    { lat: 49.033948, lng: 19.402522 },
    { lat: 49.033187, lng: 19.402177 },
    { lat: 49.031514, lng: 19.400833 },
    { lat: 49.030872, lng: 19.400046 },
    { lat: 49.030233, lng: 19.398501 },
    { lat: 49.029999, lng: 19.397677 },
    { lat: 49.02913, lng: 19.397411 },
    { lat: 49.028804, lng: 19.396762 },
    { lat: 49.027519, lng: 19.396267 },
    { lat: 49.027207, lng: 19.396216 },
    { lat: 49.0258, lng: 19.395309 },
    { lat: 49.024628, lng: 19.394224 },
    { lat: 49.023578, lng: 19.392985 },
    { lat: 49.023233, lng: 19.391856 },
    { lat: 49.02287, lng: 19.390018 },
    { lat: 49.022152, lng: 19.389233 },
    { lat: 49.02144, lng: 19.389001 },
    { lat: 49.020968, lng: 19.389033 },
    { lat: 49.02057, lng: 19.389055 },
    { lat: 49.019927, lng: 19.388775 },
    { lat: 49.019363, lng: 19.388358 },
    { lat: 49.017555, lng: 19.387632 },
    { lat: 49.015673, lng: 19.386647 },
    { lat: 49.014833, lng: 19.386207 },
    { lat: 49.014637, lng: 19.385999 },
    { lat: 49.014299, lng: 19.38563 },
    { lat: 49.013819, lng: 19.384919 },
    { lat: 49.013635, lng: 19.384283 },
    { lat: 49.013172, lng: 19.382649 },
    { lat: 49.011746, lng: 19.37984 },
    { lat: 49.010373, lng: 19.377872 },
    { lat: 49.009614, lng: 19.376985 },
    { lat: 49.008115, lng: 19.37431 },
    { lat: 49.007839, lng: 19.373209 },
    { lat: 49.007808, lng: 19.373033 },
    { lat: 49.007748, lng: 19.372653 },
    { lat: 49.007527, lng: 19.371312 },
    { lat: 49.00744, lng: 19.370918 },
    { lat: 49.006997, lng: 19.370469 },
    { lat: 49.006593, lng: 19.370019 },
    { lat: 49.005898, lng: 19.369755 },
    { lat: 49.0056, lng: 19.369615 },
    { lat: 49.005361, lng: 19.369539 },
    { lat: 49.005117, lng: 19.369468 },
    { lat: 49.002692, lng: 19.369847 },
    { lat: 49.001704, lng: 19.370709 },
    { lat: 49.000252, lng: 19.369786 },
    { lat: 49.000797, lng: 19.368908 },
    { lat: 48.999665, lng: 19.367436 },
    { lat: 48.998855, lng: 19.367249 },
    { lat: 48.998416, lng: 19.366869 },
    { lat: 48.997642, lng: 19.366618 },
    { lat: 48.997004, lng: 19.366402 },
    { lat: 48.996613, lng: 19.36577 },
    { lat: 48.9954682, lng: 19.365077 },
    { lat: 48.995389, lng: 19.365029 },
    { lat: 48.995224, lng: 19.364448 },
    { lat: 48.994986, lng: 19.364245 },
    { lat: 48.994762, lng: 19.363969 },
    { lat: 48.994445, lng: 19.362662 },
    { lat: 48.993477, lng: 19.362472 },
    { lat: 48.991854, lng: 19.362823 },
    { lat: 48.990938, lng: 19.362954 },
    { lat: 48.988536, lng: 19.363518 },
    { lat: 48.988255, lng: 19.363844 },
    { lat: 48.988, lng: 19.363977 },
    { lat: 48.98737, lng: 19.363902 },
    { lat: 48.98704, lng: 19.363729 },
    { lat: 48.986857, lng: 19.363705 },
    { lat: 48.986383, lng: 19.363448 },
    { lat: 48.985985, lng: 19.363372 },
    { lat: 48.985118, lng: 19.363754 },
    { lat: 48.983264, lng: 19.363649 },
    { lat: 48.982295, lng: 19.363141 },
    { lat: 48.981241, lng: 19.363065 },
    { lat: 48.980115, lng: 19.362466 },
    { lat: 48.97995, lng: 19.362576 },
    { lat: 48.979793, lng: 19.362616 },
    { lat: 48.979606, lng: 19.362589 },
    { lat: 48.979284, lng: 19.362566 },
    { lat: 48.978619, lng: 19.361769 },
    { lat: 48.977451, lng: 19.362698 },
    { lat: 48.974237, lng: 19.362404 },
    { lat: 48.973262, lng: 19.362379 },
    { lat: 48.972137, lng: 19.363183 },
    { lat: 48.971818, lng: 19.36339 },
    { lat: 48.971458, lng: 19.363547 },
    { lat: 48.971277, lng: 19.363569 },
    { lat: 48.970566, lng: 19.363441 },
    { lat: 48.969763, lng: 19.362737 },
    { lat: 48.96828, lng: 19.361939 },
    { lat: 48.96626, lng: 19.360853 },
    { lat: 48.965762, lng: 19.358005 },
    { lat: 48.964776, lng: 19.35775 },
    { lat: 48.963281, lng: 19.35754 },
    { lat: 48.959852, lng: 19.358576 },
    { lat: 48.957575, lng: 19.356088 },
    { lat: 48.956816, lng: 19.357926 },
    { lat: 48.956522, lng: 19.359223 },
    { lat: 48.955957, lng: 19.360675 },
    { lat: 48.955266, lng: 19.361822 },
    { lat: 48.954401, lng: 19.362348 },
    { lat: 48.9539, lng: 19.364039 },
    { lat: 48.954601, lng: 19.367947 },
    { lat: 48.955817, lng: 19.371638 },
    { lat: 48.955348, lng: 19.37194 },
    { lat: 48.95325, lng: 19.373353 },
    { lat: 48.951807, lng: 19.374324 },
    { lat: 48.951138, lng: 19.374732 },
    { lat: 48.950286, lng: 19.374458 },
    { lat: 48.949275, lng: 19.3753 },
    { lat: 48.948308, lng: 19.376281 },
    { lat: 48.948055, lng: 19.37724 },
    { lat: 48.948212, lng: 19.377948 },
    { lat: 48.948266, lng: 19.378901 },
    { lat: 48.948298, lng: 19.379468 },
    { lat: 48.948062, lng: 19.380282 },
    { lat: 48.947845, lng: 19.381031 },
    { lat: 48.947099, lng: 19.382416 },
    { lat: 48.947262, lng: 19.383605 },
    { lat: 48.944611, lng: 19.383443 },
    { lat: 48.944015, lng: 19.383407 },
    { lat: 48.943963, lng: 19.383398 },
    { lat: 48.943906, lng: 19.383389 },
    { lat: 48.941169, lng: 19.382934 },
    { lat: 48.94106, lng: 19.382916 },
    { lat: 48.940948, lng: 19.382897 },
    { lat: 48.940843, lng: 19.38288 },
    { lat: 48.937607, lng: 19.382343 },
    { lat: 48.936676, lng: 19.382188 },
    { lat: 48.936086, lng: 19.380456 },
    { lat: 48.935264, lng: 19.380109 },
    { lat: 48.93488, lng: 19.380037 },
    { lat: 48.934265, lng: 19.380321 },
    { lat: 48.932892, lng: 19.380762 },
    { lat: 48.931248, lng: 19.381291 },
    { lat: 48.926153, lng: 19.384609 },
    { lat: 48.926683, lng: 19.388479 },
    { lat: 48.926555, lng: 19.390772 },
    { lat: 48.927675, lng: 19.395335 },
    { lat: 48.926156, lng: 19.401368 },
    { lat: 48.926011, lng: 19.401926 },
    { lat: 48.9257, lng: 19.403274 },
    { lat: 48.925313, lng: 19.403874 },
    { lat: 48.924936, lng: 19.404556 },
    { lat: 48.926936, lng: 19.406787 },
    { lat: 48.927197, lng: 19.408853 },
    { lat: 48.927492, lng: 19.410358 },
    { lat: 48.927752, lng: 19.412297 },
    { lat: 48.927781, lng: 19.412724 },
    { lat: 48.92773, lng: 19.414008 },
    { lat: 48.92763, lng: 19.414472 },
    { lat: 48.927059, lng: 19.417174 },
    { lat: 48.926981, lng: 19.417768 },
    { lat: 48.92691, lng: 19.418425 },
    { lat: 48.926895, lng: 19.418936 },
    { lat: 48.927125, lng: 19.419963 },
    { lat: 48.927047, lng: 19.420546 },
    { lat: 48.927194, lng: 19.420979 },
    { lat: 48.927167, lng: 19.421771 },
    { lat: 48.927169, lng: 19.422374 },
    { lat: 48.927369, lng: 19.424483 },
    { lat: 48.927348, lng: 19.424888 },
    { lat: 48.927375, lng: 19.425405 },
    { lat: 48.92735, lng: 19.425783 },
    { lat: 48.927352, lng: 19.426204 },
    { lat: 48.927275, lng: 19.426471 },
    { lat: 48.927258, lng: 19.426911 },
    { lat: 48.927282, lng: 19.427334 },
    { lat: 48.927268, lng: 19.427584 },
    { lat: 48.927282, lng: 19.427813 },
    { lat: 48.927236, lng: 19.428402 },
    { lat: 48.926978, lng: 19.429226 },
    { lat: 48.926988, lng: 19.429419 },
    { lat: 48.92749, lng: 19.436643 },
    { lat: 48.927753, lng: 19.436961 },
    { lat: 48.927448, lng: 19.440842 },
    { lat: 48.928523, lng: 19.448368 },
    { lat: 48.92893, lng: 19.449285 },
    { lat: 48.93133, lng: 19.453228 },
    { lat: 48.931131, lng: 19.455048 },
    { lat: 48.931041, lng: 19.455844 },
    { lat: 48.931007, lng: 19.457321 },
    { lat: 48.931135, lng: 19.458344 },
    { lat: 48.930991, lng: 19.458818 },
    { lat: 48.931953, lng: 19.461705 },
    { lat: 48.934155, lng: 19.463649 },
    { lat: 48.936378, lng: 19.465182 },
    { lat: 48.937638, lng: 19.47166 },
    { lat: 48.937851, lng: 19.475272 },
    { lat: 48.938, lng: 19.477297 },
    { lat: 48.93871, lng: 19.479161 },
    { lat: 48.939801, lng: 19.480325 },
    { lat: 48.940235, lng: 19.480694 },
    { lat: 48.940233, lng: 19.481461 },
    { lat: 48.939931, lng: 19.483349 },
    { lat: 48.939843, lng: 19.483882 },
    { lat: 48.9402, lng: 19.486059 },
    { lat: 48.941189, lng: 19.487704 },
    { lat: 48.942045, lng: 19.488524 },
    { lat: 48.943267, lng: 19.491013 },
    { lat: 48.9444873, lng: 19.4905459 },
    { lat: 48.9456961, lng: 19.490075 },
    { lat: 48.9464426, lng: 19.4897427 },
    { lat: 48.9479643, lng: 19.4887498 },
    { lat: 48.9500609, lng: 19.4864099 },
    { lat: 48.9508669, lng: 19.4862218 },
    { lat: 48.9538766, lng: 19.4860732 },
    { lat: 48.9557532, lng: 19.4870331 },
    { lat: 48.9578165, lng: 19.487817 },
    { lat: 48.959607, lng: 19.48723 },
    { lat: 48.9600914, lng: 19.4862211 },
    { lat: 48.9638757, lng: 19.4829006 },
    { lat: 48.9638107, lng: 19.4821057 },
    { lat: 48.9637577, lng: 19.4787775 },
    { lat: 48.9637451, lng: 19.4775786 },
    { lat: 48.9637778, lng: 19.4757432 },
    { lat: 48.9639166, lng: 19.4745048 },
    { lat: 48.9644099, lng: 19.4736454 },
    { lat: 48.96483, lng: 19.4727855 },
    { lat: 48.965464, lng: 19.4700382 },
    { lat: 48.9655417, lng: 19.4694056 },
    { lat: 48.9657419, lng: 19.4680558 },
    { lat: 48.9661532, lng: 19.4670788 },
    { lat: 48.9666918, lng: 19.4665921 },
    { lat: 48.9675721, lng: 19.4654276 },
    { lat: 48.9693271, lng: 19.4646307 },
    { lat: 48.9717206, lng: 19.462996 },
    { lat: 48.9725984, lng: 19.4621207 },
    { lat: 48.9729854, lng: 19.4616899 },
    { lat: 48.9740693, lng: 19.4595931 },
    { lat: 48.9752293, lng: 19.4595298 },
    { lat: 48.9765876, lng: 19.4586627 },
    { lat: 48.9776129, lng: 19.4574171 },
    { lat: 48.978022, lng: 19.4567618 },
    { lat: 48.9787812, lng: 19.4563901 },
    { lat: 48.9798481, lng: 19.4563593 },
    { lat: 48.9813002, lng: 19.4564941 },
    { lat: 48.9817035, lng: 19.4565141 },
    { lat: 48.9824891, lng: 19.4563236 },
    { lat: 48.982727, lng: 19.4563262 },
    { lat: 48.9837154, lng: 19.4571824 },
    { lat: 48.9847831, lng: 19.4567566 },
    { lat: 48.9848856, lng: 19.45668 },
    { lat: 48.9852163, lng: 19.4564007 },
    { lat: 48.9857132, lng: 19.455888 },
    { lat: 48.986431, lng: 19.45506 },
    { lat: 48.9870895, lng: 19.4545594 },
    { lat: 48.9873932, lng: 19.4548857 },
    { lat: 48.9885233, lng: 19.4534215 },
    { lat: 48.9905643, lng: 19.4526005 },
    { lat: 48.991136, lng: 19.4522699 },
    { lat: 48.9931856, lng: 19.4514116 },
    { lat: 48.9963212, lng: 19.4504 },
    { lat: 48.9970411, lng: 19.4482584 },
    { lat: 48.9975639, lng: 19.4474083 },
    { lat: 48.9986404, lng: 19.44593 },
    { lat: 48.9999455, lng: 19.4438199 },
    { lat: 49.0001332, lng: 19.4440542 },
    { lat: 49.0008534, lng: 19.4447361 },
    { lat: 49.0014365, lng: 19.4448696 },
    { lat: 49.0016944, lng: 19.4448908 },
    { lat: 49.0029961, lng: 19.4448921 },
    { lat: 49.0033899, lng: 19.4445136 },
    { lat: 49.0037913, lng: 19.4438622 },
    { lat: 49.0044408, lng: 19.4433598 },
    { lat: 49.0051224, lng: 19.4429443 },
    { lat: 49.0053759, lng: 19.4426264 },
    { lat: 49.0061058, lng: 19.4416756 },
    { lat: 49.0075842, lng: 19.4402123 },
    { lat: 49.0083498, lng: 19.4393739 },
    { lat: 49.0084209, lng: 19.4395935 },
    { lat: 49.0088371, lng: 19.4403551 },
    { lat: 49.0091155, lng: 19.4407201 },
    { lat: 49.0093844, lng: 19.4413913 },
    { lat: 49.0095568, lng: 19.4415637 },
    { lat: 49.010509, lng: 19.4423404 },
    { lat: 49.0113333, lng: 19.4429794 },
    { lat: 49.0117879, lng: 19.4418767 },
    { lat: 49.0117058, lng: 19.4416119 },
    { lat: 49.0123579, lng: 19.4404145 },
    { lat: 49.0135945, lng: 19.4413452 },
    { lat: 49.0140372, lng: 19.4418276 },
    { lat: 49.0152574, lng: 19.4454703 },
    { lat: 49.0159454, lng: 19.4466951 },
    { lat: 49.0170527, lng: 19.4476338 },
    { lat: 49.0174419, lng: 19.4477163 },
    { lat: 49.0180637, lng: 19.4478095 },
    { lat: 49.0186119, lng: 19.4479899 },
    { lat: 49.019088, lng: 19.4479798 },
    { lat: 49.0198483, lng: 19.448214 },
    { lat: 49.0199865, lng: 19.4482053 },
    { lat: 49.0218574, lng: 19.4492594 },
    { lat: 49.0223505, lng: 19.4494892 },
    { lat: 49.0227103, lng: 19.4497386 },
    { lat: 49.0230386, lng: 19.4498216 },
    { lat: 49.0232843, lng: 19.4498488 },
    { lat: 49.0235251, lng: 19.4498625 },
    { lat: 49.0238373, lng: 19.4498395 },
    { lat: 49.0239286, lng: 19.4497698 },
    { lat: 49.0241665, lng: 19.4497217 },
    { lat: 49.0249652, lng: 19.4496681 },
    { lat: 49.0251346, lng: 19.4496288 },
    { lat: 49.0262478, lng: 19.4493092 },
    { lat: 49.0262801, lng: 19.4492508 },
    { lat: 49.0264403, lng: 19.4491116 },
    { lat: 49.0268034, lng: 19.4489463 },
    { lat: 49.0270049, lng: 19.4489172 },
    { lat: 49.0274066, lng: 19.4489481 },
    { lat: 49.0277499, lng: 19.4490144 },
    { lat: 49.0280517, lng: 19.4490855 },
    { lat: 49.0284154, lng: 19.4492699 },
    { lat: 49.0285475, lng: 19.4493477 },
    { lat: 49.028885, lng: 19.4496298 },
    { lat: 49.0293075, lng: 19.4500646 },
    { lat: 49.0295385, lng: 19.450357 },
    { lat: 49.0306252, lng: 19.4520207 },
    { lat: 49.0307735, lng: 19.4521589 },
    { lat: 49.0311358, lng: 19.4530474 },
    { lat: 49.0314395, lng: 19.4536444 },
    { lat: 49.0315183, lng: 19.4538877 },
    { lat: 49.0316623, lng: 19.4542253 },
    { lat: 49.0318163, lng: 19.454434 },
    { lat: 49.0318712, lng: 19.4545491 },
    { lat: 49.0319824, lng: 19.454636 },
    { lat: 49.0321072, lng: 19.454827 },
    { lat: 49.032154, lng: 19.4549599 },
    { lat: 49.0322203, lng: 19.4550045 },
    { lat: 49.0322177, lng: 19.4550981 },
    { lat: 49.0322733, lng: 19.4552021 },
    { lat: 49.032418, lng: 19.4553335 },
    { lat: 49.0324998, lng: 19.4553721 },
    { lat: 49.0327628, lng: 19.4557229 },
    { lat: 49.0328175, lng: 19.4560769 },
    { lat: 49.0329802, lng: 19.4561844 },
    { lat: 49.0331556, lng: 19.4563672 },
    { lat: 49.0333415, lng: 19.4566594 },
    { lat: 49.0335966, lng: 19.4569388 },
    { lat: 49.0336269, lng: 19.4570372 },
    { lat: 49.0336837, lng: 19.457749 },
    { lat: 49.0338611, lng: 19.4581743 },
    { lat: 49.0342597, lng: 19.4586082 },
    { lat: 49.0343709, lng: 19.458991 },
    { lat: 49.0345365, lng: 19.4592595 },
    { lat: 49.0346974, lng: 19.4596895 },
    { lat: 49.0348178, lng: 19.4599804 },
    { lat: 49.0350249, lng: 19.46039 },
    { lat: 49.0351976, lng: 19.4606875 },
    { lat: 49.0354763, lng: 19.4610119 },
    { lat: 49.0357493, lng: 19.4611389 },
    { lat: 49.0360157, lng: 19.4615648 },
    { lat: 49.0363817, lng: 19.461902 },
    { lat: 49.0366764, lng: 19.4621363 },
    { lat: 49.0368193, lng: 19.462204 },
    { lat: 49.0370325, lng: 19.4620602 },
    { lat: 49.037195, lng: 19.4621168 },
    { lat: 49.0373862, lng: 19.4618658 },
    { lat: 49.0375599, lng: 19.4618882 },
    { lat: 49.0378479, lng: 19.4617346 },
    { lat: 49.0381156, lng: 19.4608936 },
    { lat: 49.0382367, lng: 19.4609295 },
    { lat: 49.0383801, lng: 19.4605923 },
    { lat: 49.0385694, lng: 19.4605466 },
    { lat: 49.0385655, lng: 19.4603121 },
    { lat: 49.0387295, lng: 19.4601836 },
    { lat: 49.0387493, lng: 19.4598302 },
    { lat: 49.0388755, lng: 19.4595614 },
    { lat: 49.0390031, lng: 19.4593233 },
    { lat: 49.0392297, lng: 19.4590807 },
    { lat: 49.0394935, lng: 19.4589124 },
    { lat: 49.0395903, lng: 19.4583769 },
    { lat: 49.0397472, lng: 19.4581648 },
    { lat: 49.039924, lng: 19.4576965 },
    { lat: 49.0398415, lng: 19.4572758 },
    { lat: 49.0397257, lng: 19.4567437 },
    { lat: 49.0398922, lng: 19.4564227 },
    { lat: 49.0399125, lng: 19.4561318 },
    { lat: 49.0401831, lng: 19.4553805 },
    { lat: 49.0403288, lng: 19.4550427 },
    { lat: 49.0402941, lng: 19.454757 },
    { lat: 49.040235, lng: 19.4544555 },
    { lat: 49.0402837, lng: 19.4540218 },
    { lat: 49.0403637, lng: 19.4536084 },
    { lat: 49.0405347, lng: 19.4533081 },
    { lat: 49.0405192, lng: 19.4529269 },
    { lat: 49.0406619, lng: 19.4526232 },
    { lat: 49.0407549, lng: 19.4520034 },
    { lat: 49.0407879, lng: 19.4517674 },
    { lat: 49.0409391, lng: 19.4514082 },
    { lat: 49.0411014, lng: 19.4513837 },
    { lat: 49.0413287, lng: 19.45079 },
    { lat: 49.0417794, lng: 19.450278 },
    { lat: 49.0419371, lng: 19.4498136 },
    { lat: 49.042378, lng: 19.4495024 },
    { lat: 49.0426967, lng: 19.4491848 },
    { lat: 49.0429931, lng: 19.4490126 },
    { lat: 49.0432942, lng: 19.4486291 },
    { lat: 49.0436884, lng: 19.4484083 },
    { lat: 49.0438489, lng: 19.4486347 },
    { lat: 49.0441394, lng: 19.4484588 },
    { lat: 49.0444091, lng: 19.4482499 },
    { lat: 49.0445625, lng: 19.4484972 },
    { lat: 49.0448524, lng: 19.4484295 },
    { lat: 49.0451211, lng: 19.4483163 },
    { lat: 49.0455182, lng: 19.4482794 },
    { lat: 49.0458071, lng: 19.4482876 },
    { lat: 49.0459473, lng: 19.4486383 },
    { lat: 49.0460642, lng: 19.4485105 },
    { lat: 49.0460989, lng: 19.4481899 },
    { lat: 49.0462511, lng: 19.4481923 },
    { lat: 49.0464859, lng: 19.4485142 },
    { lat: 49.0470373, lng: 19.448352 },
    { lat: 49.0472839, lng: 19.4481797 },
    { lat: 49.0473822, lng: 19.4482838 },
    { lat: 49.0475279, lng: 19.447998 },
    { lat: 49.0476701, lng: 19.4481207 },
    { lat: 49.047613, lng: 19.4483779 },
    { lat: 49.0481441, lng: 19.4484302 },
    { lat: 49.0482351, lng: 19.4480591 },
    { lat: 49.0482519, lng: 19.4480602 },
    { lat: 49.0482637, lng: 19.4479993 },
    { lat: 49.0483196, lng: 19.4478891 },
    { lat: 49.0484492, lng: 19.447844 },
    { lat: 49.0499748, lng: 19.4479082 },
    { lat: 49.0506627, lng: 19.4479434 },
    { lat: 49.051407, lng: 19.447934 },
    { lat: 49.0515387, lng: 19.4477335 },
    { lat: 49.0516453, lng: 19.4477978 },
    { lat: 49.0517528, lng: 19.4479782 },
    { lat: 49.051819, lng: 19.4478974 },
    { lat: 49.0517983, lng: 19.4478439 },
    { lat: 49.0518223, lng: 19.4477993 },
    { lat: 49.0518558, lng: 19.4477695 },
    { lat: 49.0519168, lng: 19.4477493 },
    { lat: 49.0519484, lng: 19.4480395 },
    { lat: 49.0519647, lng: 19.4480758 },
    { lat: 49.0520543, lng: 19.4481384 },
    { lat: 49.0522085, lng: 19.4481086 },
    { lat: 49.0523401, lng: 19.447912 },
    { lat: 49.0524375, lng: 19.4479199 },
    { lat: 49.0525544, lng: 19.4480122 },
    { lat: 49.0527431, lng: 19.4480977 },
    { lat: 49.0527808, lng: 19.4480814 },
    { lat: 49.0530358, lng: 19.4476517 },
    { lat: 49.0530992, lng: 19.4475835 },
    { lat: 49.0532471, lng: 19.4476423 },
    { lat: 49.0533782, lng: 19.4478205 },
    { lat: 49.0534262, lng: 19.4479048 },
    { lat: 49.0535075, lng: 19.447937 },
    { lat: 49.053555, lng: 19.4478648 },
    { lat: 49.0536191, lng: 19.4477156 },
    { lat: 49.0536506, lng: 19.4477374 },
    { lat: 49.0536594, lng: 19.4478047 },
    { lat: 49.0537439, lng: 19.447855 },
    { lat: 49.0537549, lng: 19.4477769 },
    { lat: 49.0538582, lng: 19.4476976 },
    { lat: 49.0539209, lng: 19.4476136 },
    { lat: 49.0540133, lng: 19.44764 },
    { lat: 49.0541269, lng: 19.4477783 },
    { lat: 49.0542442, lng: 19.4476849 },
    { lat: 49.0543127, lng: 19.4476815 },
    { lat: 49.0544369, lng: 19.4478281 },
    { lat: 49.0544721, lng: 19.4479357 },
    { lat: 49.0544815, lng: 19.4481381 },
    { lat: 49.0545245, lng: 19.448164 },
    { lat: 49.0545901, lng: 19.4481221 },
    { lat: 49.0547512, lng: 19.4481199 },
    { lat: 49.0548827, lng: 19.4481389 },
    { lat: 49.0549794, lng: 19.4482546 },
    { lat: 49.0550948, lng: 19.4483389 },
    { lat: 49.0551825, lng: 19.4483349 },
    { lat: 49.0552287, lng: 19.4484309 },
    { lat: 49.0554299, lng: 19.4486874 },
    { lat: 49.0554966, lng: 19.4488873 },
    { lat: 49.0555675, lng: 19.4490058 },
    { lat: 49.055582, lng: 19.4489528 },
    { lat: 49.0557035, lng: 19.4488539 },
    { lat: 49.0557574, lng: 19.4487019 },
    { lat: 49.055779, lng: 19.4486824 },
    { lat: 49.0560086, lng: 19.4489448 },
    { lat: 49.0560237, lng: 19.4490004 },
    { lat: 49.0559599, lng: 19.4493058 },
    { lat: 49.055994, lng: 19.44936 },
    { lat: 49.0560922, lng: 19.4494105 },
    { lat: 49.0561638, lng: 19.4493497 },
    { lat: 49.0562437, lng: 19.4493979 },
    { lat: 49.0563778, lng: 19.449667 },
    { lat: 49.0564655, lng: 19.449806 },
    { lat: 49.0565039, lng: 19.449836 },
    { lat: 49.0566315, lng: 19.4498162 },
    { lat: 49.056718, lng: 19.4499298 },
    { lat: 49.0567306, lng: 19.4501513 },
    { lat: 49.056771, lng: 19.450296 },
    { lat: 49.0568569, lng: 19.4504011 },
    { lat: 49.0569333, lng: 19.4503695 },
    { lat: 49.0570098, lng: 19.4504142 },
    { lat: 49.0570301, lng: 19.4504903 },
    { lat: 49.0569949, lng: 19.4505825 },
    { lat: 49.057022, lng: 19.4506583 },
    { lat: 49.057136, lng: 19.4506866 },
    { lat: 49.0573112, lng: 19.4505734 },
    { lat: 49.0574057, lng: 19.4505967 },
    { lat: 49.0574759, lng: 19.4507239 },
    { lat: 49.0576006, lng: 19.4508377 },
    { lat: 49.057754, lng: 19.4509625 },
    { lat: 49.0578885, lng: 19.4511655 },
    { lat: 49.0579743, lng: 19.4512447 },
    { lat: 49.0579968, lng: 19.4513166 },
    { lat: 49.0579166, lng: 19.4514316 },
    { lat: 49.057923, lng: 19.4514729 },
    { lat: 49.0580278, lng: 19.4516902 },
    { lat: 49.0581643, lng: 19.4515994 },
    { lat: 49.0582789, lng: 19.4516152 },
    { lat: 49.0583228, lng: 19.4516867 },
    { lat: 49.0583694, lng: 19.4516745 },
    { lat: 49.0584187, lng: 19.4515909 },
    { lat: 49.0584703, lng: 19.4515368 },
    { lat: 49.0584947, lng: 19.4515292 },
    { lat: 49.058537, lng: 19.4517837 },
    { lat: 49.0586241, lng: 19.4519429 },
    { lat: 49.0586936, lng: 19.45203 },
    { lat: 49.058698, lng: 19.4520539 },
    { lat: 49.0586054, lng: 19.4521716 },
    { lat: 49.058622, lng: 19.4522335 },
    { lat: 49.0587184, lng: 19.4522239 },
    { lat: 49.0589135, lng: 19.4522504 },
    { lat: 49.0589586, lng: 19.4523278 },
    { lat: 49.0590843, lng: 19.4524879 },
    { lat: 49.0591528, lng: 19.4525036 },
    { lat: 49.0592722, lng: 19.4526748 },
    { lat: 49.0593675, lng: 19.4529337 },
    { lat: 49.0593962, lng: 19.4531149 },
    { lat: 49.059384, lng: 19.453509 },
    { lat: 49.0594459, lng: 19.4537248 },
    { lat: 49.0597856, lng: 19.4544423 },
    { lat: 49.0598937, lng: 19.4546862 },
    { lat: 49.0600521, lng: 19.4548931 },
    { lat: 49.0601251, lng: 19.4549884 },
    { lat: 49.0605276, lng: 19.4554767 },
    { lat: 49.0605766, lng: 19.4555401 },
    { lat: 49.0608573, lng: 19.4559574 },
    { lat: 49.061193, lng: 19.4564414 },
    { lat: 49.0613339, lng: 19.4565625 },
    { lat: 49.0615113, lng: 19.4565288 },
    { lat: 49.0615342, lng: 19.4565849 },
    { lat: 49.0614881, lng: 19.4567555 },
    { lat: 49.0615258, lng: 19.4568625 },
    { lat: 49.0616681, lng: 19.4568954 },
    { lat: 49.0618019, lng: 19.4569149 },
    { lat: 49.0619701, lng: 19.4569221 },
    { lat: 49.0620146, lng: 19.457005 },
    { lat: 49.0621506, lng: 19.4570975 },
    { lat: 49.0622346, lng: 19.457086 },
    { lat: 49.0623208, lng: 19.4572191 },
    { lat: 49.0624073, lng: 19.4574563 },
    { lat: 49.0624069, lng: 19.457543 },
    { lat: 49.062868, lng: 19.4579403 },
    { lat: 49.0629543, lng: 19.4577348 },
    { lat: 49.0629778, lng: 19.4574862 },
    { lat: 49.0630356, lng: 19.4570563 },
    { lat: 49.0630816, lng: 19.4568301 },
    { lat: 49.0630423, lng: 19.4565662 },
    { lat: 49.0630801, lng: 19.4549491 },
    { lat: 49.063103, lng: 19.4538549 },
    { lat: 49.063533, lng: 19.4521904 },
    { lat: 49.0634749, lng: 19.4513993 },
    { lat: 49.0637318, lng: 19.4519815 },
    { lat: 49.0645323, lng: 19.4520176 },
    { lat: 49.0652532, lng: 19.4520827 },
    { lat: 49.0655542, lng: 19.4520616 },
    { lat: 49.066192, lng: 19.4517698 },
    { lat: 49.0674862, lng: 19.4517087 },
    { lat: 49.0693174, lng: 19.4515841 },
    { lat: 49.0708079, lng: 19.4514837 },
    { lat: 49.0717695, lng: 19.4537057 },
    { lat: 49.0725994, lng: 19.4536685 },
    { lat: 49.0740539, lng: 19.4532989 },
    { lat: 49.0744705, lng: 19.4534121 },
    { lat: 49.0749688, lng: 19.4539295 },
    { lat: 49.0754288, lng: 19.4544003 },
    { lat: 49.0760933, lng: 19.4546828 },
    { lat: 49.0765897, lng: 19.4550603 },
    { lat: 49.0790469, lng: 19.4573468 },
    { lat: 49.0788952, lng: 19.4581096 },
    { lat: 49.0794331, lng: 19.458534 },
    { lat: 49.0804184, lng: 19.4587381 },
    { lat: 49.0807245, lng: 19.4570924 },
    { lat: 49.0817539, lng: 19.4568133 },
    { lat: 49.0822215, lng: 19.4567391 },
    { lat: 49.0840208, lng: 19.4577817 },
    { lat: 49.0847911, lng: 19.4578952 },
    { lat: 49.0862734, lng: 19.4590523 },
    { lat: 49.0867486, lng: 19.4595101 },
    { lat: 49.0870304, lng: 19.4599977 },
    { lat: 49.0875118, lng: 19.4606609 },
    { lat: 49.0876804, lng: 19.4608255 },
    { lat: 49.0877574, lng: 19.4608575 },
    { lat: 49.087994, lng: 19.4608818 },
    { lat: 49.0884576, lng: 19.4605259 },
    { lat: 49.0889036, lng: 19.4607133 },
    { lat: 49.088768, lng: 19.4617312 },
    { lat: 49.0887541, lng: 19.4621149 },
    { lat: 49.0888018, lng: 19.4623197 },
    { lat: 49.0888299, lng: 19.4623146 },
    { lat: 49.0892459, lng: 19.4629334 },
    { lat: 49.089392, lng: 19.4630954 },
    { lat: 49.0895691, lng: 19.4632231 },
    { lat: 49.0900211, lng: 19.4634185 },
    { lat: 49.0900622, lng: 19.463526 },
    { lat: 49.0908845, lng: 19.4634477 },
    { lat: 49.0910428, lng: 19.4630968 },
    { lat: 49.0913132, lng: 19.4614593 },
    { lat: 49.0913024, lng: 19.4600989 },
    { lat: 49.0912059, lng: 19.4586944 },
    { lat: 49.0916423, lng: 19.4574332 },
    { lat: 49.091847, lng: 19.456859 },
    { lat: 49.0919572, lng: 19.4557326 },
    { lat: 49.0919652, lng: 19.4552474 },
    { lat: 49.0919802, lng: 19.4544512 },
    { lat: 49.0924293, lng: 19.4520506 },
    { lat: 49.0922386, lng: 19.4515112 },
    { lat: 49.092187, lng: 19.4515128 },
    { lat: 49.092071, lng: 19.451124 },
    { lat: 49.0925194, lng: 19.4507828 },
    { lat: 49.092887, lng: 19.4506881 },
    { lat: 49.0929469, lng: 19.4506461 },
    { lat: 49.0931887, lng: 19.4495648 },
    { lat: 49.0937, lng: 19.4478751 },
    { lat: 49.0946276, lng: 19.4463722 },
    { lat: 49.0946013, lng: 19.4463135 },
    { lat: 49.094554, lng: 19.4462916 },
    { lat: 49.0945459, lng: 19.4461886 },
    { lat: 49.0943007, lng: 19.4456407 },
    { lat: 49.0942357, lng: 19.4455009 },
    { lat: 49.0940758, lng: 19.4454807 },
    { lat: 49.0940557, lng: 19.4453815 },
    { lat: 49.094153, lng: 19.4451733 },
    { lat: 49.0942449, lng: 19.4450872 },
    { lat: 49.0943182, lng: 19.4450185 },
    { lat: 49.0943621, lng: 19.4449926 },
    { lat: 49.094496, lng: 19.444731 },
    { lat: 49.094372, lng: 19.444552 },
    { lat: 49.094301, lng: 19.444448 },
    { lat: 49.094255, lng: 19.444381 },
    { lat: 49.094186, lng: 19.444283 },
    { lat: 49.094143, lng: 19.44422 },
    { lat: 49.093982, lng: 19.444016 },
    { lat: 49.093794, lng: 19.443702 },
    { lat: 49.093368, lng: 19.443286 },
    { lat: 49.093274, lng: 19.443242 },
    { lat: 49.092574, lng: 19.443053 },
    { lat: 49.092151, lng: 19.442898 },
    { lat: 49.092088, lng: 19.442871 },
    { lat: 49.091818, lng: 19.442756 },
    { lat: 49.091593, lng: 19.442671 },
    { lat: 49.091434, lng: 19.442609 },
    { lat: 49.091256, lng: 19.442528 },
    { lat: 49.091049, lng: 19.44245 },
    { lat: 49.090751, lng: 19.44236 },
    { lat: 49.090756, lng: 19.441996 },
    { lat: 49.09075, lng: 19.441664 },
    { lat: 49.09084, lng: 19.440686 },
    { lat: 49.090797, lng: 19.440522 },
    { lat: 49.090771, lng: 19.440393 },
    { lat: 49.090765, lng: 19.440202 },
    { lat: 49.090875, lng: 19.43979 },
    { lat: 49.090853, lng: 19.439578 },
    { lat: 49.09078, lng: 19.439464 },
    { lat: 49.090597, lng: 19.438896 },
    { lat: 49.090446, lng: 19.439096 },
    { lat: 49.090405, lng: 19.439542 },
    { lat: 49.090168, lng: 19.439491 },
    { lat: 49.089689, lng: 19.44003 },
    { lat: 49.089236, lng: 19.439912 },
    { lat: 49.089016, lng: 19.4401 },
    { lat: 49.087503, lng: 19.439642 },
    { lat: 49.085649, lng: 19.439174 },
    { lat: 49.085937, lng: 19.438733 },
    { lat: 49.087114, lng: 19.436929 },
    { lat: 49.086867, lng: 19.436437 },
    { lat: 49.086801, lng: 19.436305 },
    { lat: 49.086759, lng: 19.436215 },
    { lat: 49.086715, lng: 19.436116 },
    { lat: 49.086675, lng: 19.43603 },
    { lat: 49.086695, lng: 19.435797 },
    { lat: 49.086751, lng: 19.435094 },
    { lat: 49.086938, lng: 19.432762 },
    { lat: 49.086941, lng: 19.430913 },
    { lat: 49.083595, lng: 19.430406 },
    { lat: 49.081801, lng: 19.430184 },
    { lat: 49.080982, lng: 19.429949 },
    { lat: 49.080858, lng: 19.429955 },
    { lat: 49.080827, lng: 19.429957 },
    { lat: 49.080015, lng: 19.429997 },
    { lat: 49.078958, lng: 19.429615 },
    { lat: 49.078822, lng: 19.429782 },
    { lat: 49.078827, lng: 19.429746 },
    { lat: 49.078834, lng: 19.429694 },
    { lat: 49.079282, lng: 19.426212 },
    { lat: 49.079406, lng: 19.425289 },
    { lat: 49.079602, lng: 19.421833 },
    { lat: 49.079632, lng: 19.421394 },
    { lat: 49.078373, lng: 19.42179 },
    { lat: 49.078345, lng: 19.421456 },
    { lat: 49.078241, lng: 19.420225 },
    { lat: 49.078226, lng: 19.41981 },
    { lat: 49.078192, lng: 19.41885 },
    { lat: 49.078184, lng: 19.41863 },
    { lat: 49.078176, lng: 19.418414 },
    { lat: 49.078011, lng: 19.417825 },
    { lat: 49.077005, lng: 19.414307 },
    { lat: 49.076259, lng: 19.412337 },
    { lat: 49.076076, lng: 19.411849 },
    { lat: 49.075757, lng: 19.411688 },
    { lat: 49.0739976, lng: 19.410779 },
    { lat: 49.0723789, lng: 19.4099686 },
  ],
  PavčinaLehota: [
    { lat: 49.0358256, lng: 19.5757813 },
    { lat: 49.0361494, lng: 19.5758408 },
    { lat: 49.0362889, lng: 19.5758345 },
    { lat: 49.0366263, lng: 19.5759719 },
    { lat: 49.0368915, lng: 19.575956 },
    { lat: 49.0371341, lng: 19.5758653 },
    { lat: 49.0372438, lng: 19.5758388 },
    { lat: 49.0374149, lng: 19.5757975 },
    { lat: 49.0374888, lng: 19.5758291 },
    { lat: 49.0376941, lng: 19.575917 },
    { lat: 49.0379696, lng: 19.5762028 },
    { lat: 49.0381225, lng: 19.5756739 },
    { lat: 49.0382858, lng: 19.5747468 },
    { lat: 49.0381851, lng: 19.5746392 },
    { lat: 49.0379341, lng: 19.5741681 },
    { lat: 49.037201, lng: 19.5741327 },
    { lat: 49.0370925, lng: 19.574124 },
    { lat: 49.0371255, lng: 19.5737578 },
    { lat: 49.0372456, lng: 19.5723784 },
    { lat: 49.0374221, lng: 19.5703765 },
    { lat: 49.0375793, lng: 19.5684707 },
    { lat: 49.0374749, lng: 19.567531 },
    { lat: 49.0373986, lng: 19.5667953 },
    { lat: 49.0378192, lng: 19.5660523 },
    { lat: 49.0381531, lng: 19.5654894 },
    { lat: 49.0385914, lng: 19.5655656 },
    { lat: 49.0387046, lng: 19.5656245 },
    { lat: 49.0388983, lng: 19.5656994 },
    { lat: 49.0390302, lng: 19.5656256 },
    { lat: 49.0391509, lng: 19.5657031 },
    { lat: 49.0392325, lng: 19.564992 },
    { lat: 49.0393944, lng: 19.5639899 },
    { lat: 49.0396339, lng: 19.562538 },
    { lat: 49.0397576, lng: 19.562558 },
    { lat: 49.0397867, lng: 19.5623506 },
    { lat: 49.0397732, lng: 19.562105 },
    { lat: 49.0398558, lng: 19.5618133 },
    { lat: 49.0397125, lng: 19.5615367 },
    { lat: 49.0395163, lng: 19.5615336 },
    { lat: 49.0395249, lng: 19.5613998 },
    { lat: 49.0393958, lng: 19.5610096 },
    { lat: 49.0393149, lng: 19.5603454 },
    { lat: 49.0395142, lng: 19.5592981 },
    { lat: 49.0397417, lng: 19.5579792 },
    { lat: 49.0400283, lng: 19.5576399 },
    { lat: 49.0401708, lng: 19.5570553 },
    { lat: 49.0404072, lng: 19.5568269 },
    { lat: 49.0405616, lng: 19.5563053 },
    { lat: 49.0406955, lng: 19.5557604 },
    { lat: 49.0408417, lng: 19.555749 },
    { lat: 49.0408961, lng: 19.5545861 },
    { lat: 49.0409028, lng: 19.5544436 },
    { lat: 49.0408988, lng: 19.5544023 },
    { lat: 49.0408629, lng: 19.5536619 },
    { lat: 49.0410753, lng: 19.5532754 },
    { lat: 49.0412153, lng: 19.552662 },
    { lat: 49.0412164, lng: 19.5524611 },
    { lat: 49.0410805, lng: 19.551403 },
    { lat: 49.0408648, lng: 19.5504495 },
    { lat: 49.0401625, lng: 19.5492307 },
    { lat: 49.0399958, lng: 19.5489332 },
    { lat: 49.0396618, lng: 19.5482508 },
    { lat: 49.0394167, lng: 19.5483645 },
    { lat: 49.0387457, lng: 19.5485008 },
    { lat: 49.0379631, lng: 19.5487835 },
    { lat: 49.0375715, lng: 19.5487704 },
    { lat: 49.0372293, lng: 19.5485554 },
    { lat: 49.0369422, lng: 19.5485345 },
    { lat: 49.0366298, lng: 19.5483056 },
    { lat: 49.0364647, lng: 19.5481904 },
    { lat: 49.0362668, lng: 19.548153 },
    { lat: 49.0360326, lng: 19.5482064 },
    { lat: 49.0356559, lng: 19.5482255 },
    { lat: 49.0356324, lng: 19.5481494 },
    { lat: 49.0353696, lng: 19.5479457 },
    { lat: 49.0350342, lng: 19.547856 },
    { lat: 49.0347244, lng: 19.5475122 },
    { lat: 49.0342863, lng: 19.5470936 },
    { lat: 49.033952, lng: 19.5470278 },
    { lat: 49.0337439, lng: 19.5468845 },
    { lat: 49.0336346, lng: 19.5468127 },
    { lat: 49.033574, lng: 19.5467688 },
    { lat: 49.0334611, lng: 19.5466902 },
    { lat: 49.0333979, lng: 19.5466394 },
    { lat: 49.0331874, lng: 19.5464701 },
    { lat: 49.0328707, lng: 19.5460226 },
    { lat: 49.0326678, lng: 19.5458177 },
    { lat: 49.0321334, lng: 19.5455877 },
    { lat: 49.031728, lng: 19.5456192 },
    { lat: 49.0312965, lng: 19.5456937 },
    { lat: 49.0307711, lng: 19.5456893 },
    { lat: 49.0298794, lng: 19.5456801 },
    { lat: 49.0298105, lng: 19.5456769 },
    { lat: 49.029759, lng: 19.5456809 },
    { lat: 49.0296941, lng: 19.5456913 },
    { lat: 49.0296292, lng: 19.5457038 },
    { lat: 49.0296045, lng: 19.5457047 },
    { lat: 49.0291432, lng: 19.5458217 },
    { lat: 49.0286611, lng: 19.5458732 },
    { lat: 49.0281583, lng: 19.5456952 },
    { lat: 49.0276554, lng: 19.5456594 },
    { lat: 49.0273081, lng: 19.5455307 },
    { lat: 49.0271844, lng: 19.5454367 },
    { lat: 49.0270295, lng: 19.5453288 },
    { lat: 49.026989, lng: 19.5452056 },
    { lat: 49.0269272, lng: 19.5451611 },
    { lat: 49.0268093, lng: 19.5451643 },
    { lat: 49.0264535, lng: 19.545177 },
    { lat: 49.0263792, lng: 19.5451767 },
    { lat: 49.0260685, lng: 19.5452144 },
    { lat: 49.0256207, lng: 19.5452687 },
    { lat: 49.0251921, lng: 19.5453335 },
    { lat: 49.0247553, lng: 19.5453917 },
    { lat: 49.0246232, lng: 19.5454364 },
    { lat: 49.0242214, lng: 19.5455724 },
    { lat: 49.0237141, lng: 19.5458602 },
    { lat: 49.0237023, lng: 19.5458234 },
    { lat: 49.0235448, lng: 19.5459534 },
    { lat: 49.0232426, lng: 19.5458505 },
    { lat: 49.0230606, lng: 19.5457885 },
    { lat: 49.0230135, lng: 19.5457719 },
    { lat: 49.0229656, lng: 19.5457561 },
    { lat: 49.0229164, lng: 19.5457398 },
    { lat: 49.0228088, lng: 19.5457033 },
    { lat: 49.0227525, lng: 19.5456813 },
    { lat: 49.0226594, lng: 19.5457155 },
    { lat: 49.0225574, lng: 19.5457273 },
    { lat: 49.0224656, lng: 19.5457369 },
    { lat: 49.0223679, lng: 19.545744 },
    { lat: 49.0221281, lng: 19.5455749 },
    { lat: 49.0220376, lng: 19.545416 },
    { lat: 49.0217145, lng: 19.5450731 },
    { lat: 49.0215196, lng: 19.5450264 },
    { lat: 49.0213226, lng: 19.5449299 },
    { lat: 49.0211186, lng: 19.5449084 },
    { lat: 49.0209237, lng: 19.5449505 },
    { lat: 49.0202764, lng: 19.5447993 },
    { lat: 49.0201806, lng: 19.5447759 },
    { lat: 49.0197879, lng: 19.5451838 },
    { lat: 49.0192829, lng: 19.5453263 },
    { lat: 49.0188827, lng: 19.5454445 },
    { lat: 49.0187242, lng: 19.5455265 },
    { lat: 49.0184504, lng: 19.5454817 },
    { lat: 49.0183163, lng: 19.5454311 },
    { lat: 49.0182766, lng: 19.5454245 },
    { lat: 49.0181594, lng: 19.5453773 },
    { lat: 49.017813, lng: 19.5452152 },
    { lat: 49.0176065, lng: 19.5448391 },
    { lat: 49.0173638, lng: 19.5446518 },
    { lat: 49.0171, lng: 19.5446766 },
    { lat: 49.0169665, lng: 19.5446907 },
    { lat: 49.016901, lng: 19.5447091 },
    { lat: 49.0166888, lng: 19.5447256 },
    { lat: 49.0164515, lng: 19.5449132 },
    { lat: 49.0163421, lng: 19.5449839 },
    { lat: 49.0162238, lng: 19.5447857 },
    { lat: 49.0159019, lng: 19.5445933 },
    { lat: 49.0157035, lng: 19.5446695 },
    { lat: 49.0155186, lng: 19.5448146 },
    { lat: 49.0153084, lng: 19.5448754 },
    { lat: 49.0152704, lng: 19.5448816 },
    { lat: 49.0151173, lng: 19.5449562 },
    { lat: 49.0150585, lng: 19.5446289 },
    { lat: 49.0149729, lng: 19.5443364 },
    { lat: 49.0149203, lng: 19.5441707 },
    { lat: 49.0147367, lng: 19.5439703 },
    { lat: 49.0144632, lng: 19.5437571 },
    { lat: 49.0143407, lng: 19.5436375 },
    { lat: 49.0142679, lng: 19.5435529 },
    { lat: 49.0140779, lng: 19.5433633 },
    { lat: 49.013902, lng: 19.5432343 },
    { lat: 49.0138305, lng: 19.5431502 },
    { lat: 49.0137133, lng: 19.543024 },
    { lat: 49.0136691, lng: 19.5427239 },
    { lat: 49.0135809, lng: 19.5424442 },
    { lat: 49.0135607, lng: 19.5423954 },
    { lat: 49.013411, lng: 19.5424481 },
    { lat: 49.0131749, lng: 19.5422873 },
    { lat: 49.0130825, lng: 19.5421664 },
    { lat: 49.0130025, lng: 19.5420427 },
    { lat: 49.0127962, lng: 19.5418875 },
    { lat: 49.0126229, lng: 19.5416961 },
    { lat: 49.0124814, lng: 19.5416797 },
    { lat: 49.0122758, lng: 19.541519 },
    { lat: 49.0121171, lng: 19.5414 },
    { lat: 49.0120725, lng: 19.5413843 },
    { lat: 49.0119748, lng: 19.5414168 },
    { lat: 49.0117179, lng: 19.5414682 },
    { lat: 49.0115115, lng: 19.541364 },
    { lat: 49.0113785, lng: 19.5413632 },
    { lat: 49.0107846, lng: 19.5413622 },
    { lat: 49.0104812, lng: 19.5413568 },
    { lat: 49.0099929, lng: 19.5413468 },
    { lat: 49.0099474, lng: 19.5413386 },
    { lat: 49.0096155, lng: 19.5414733 },
    { lat: 49.0094004, lng: 19.5416503 },
    { lat: 49.0090224, lng: 19.5418846 },
    { lat: 49.0087499, lng: 19.541961 },
    { lat: 49.0085724, lng: 19.5422485 },
    { lat: 49.0085471, lng: 19.5425326 },
    { lat: 49.0085285, lng: 19.5425963 },
    { lat: 49.0084717, lng: 19.5428847 },
    { lat: 49.008212, lng: 19.5435376 },
    { lat: 49.0081964, lng: 19.5437141 },
    { lat: 49.0078888, lng: 19.5442098 },
    { lat: 49.0075724, lng: 19.5446131 },
    { lat: 49.0074344, lng: 19.5447985 },
    { lat: 49.0073195, lng: 19.5452728 },
    { lat: 49.0072864, lng: 19.5454113 },
    { lat: 49.0072618, lng: 19.5454619 },
    { lat: 49.0071719, lng: 19.5456656 },
    { lat: 49.0070327, lng: 19.545902 },
    { lat: 49.0068944, lng: 19.5460798 },
    { lat: 49.0064531, lng: 19.5471086 },
    { lat: 49.0061097, lng: 19.5472093 },
    { lat: 49.005872, lng: 19.5472838 },
    { lat: 49.0057018, lng: 19.5473372 },
    { lat: 49.0050126, lng: 19.5475151 },
    { lat: 49.0047041, lng: 19.5476462 },
    { lat: 49.0042757, lng: 19.5478373 },
    { lat: 49.0039683, lng: 19.5481434 },
    { lat: 49.0034407, lng: 19.548852 },
    { lat: 49.0032111, lng: 19.5491561 },
    { lat: 49.0030265, lng: 19.5495832 },
    { lat: 49.0027655, lng: 19.5501888 },
    { lat: 49.0025863, lng: 19.5506052 },
    { lat: 49.0022522, lng: 19.5507462 },
    { lat: 49.0022048, lng: 19.5507942 },
    { lat: 49.0017283, lng: 19.5512656 },
    { lat: 49.0015071, lng: 19.5514079 },
    { lat: 49.0014727, lng: 19.5514456 },
    { lat: 49.0011764, lng: 19.5516429 },
    { lat: 49.0008908, lng: 19.5527721 },
    { lat: 49.0008371, lng: 19.5534517 },
    { lat: 49.0007009, lng: 19.5541223 },
    { lat: 49.0005118, lng: 19.5548201 },
    { lat: 49.0002677, lng: 19.555313 },
    { lat: 49.0002852, lng: 19.5556631 },
    { lat: 49.0006259, lng: 19.5566412 },
    { lat: 49.0009474, lng: 19.5574135 },
    { lat: 49.0014286, lng: 19.5582358 },
    { lat: 49.0017139, lng: 19.5590603 },
    { lat: 49.0018038, lng: 19.5598293 },
    { lat: 49.0021301, lng: 19.5601877 },
    { lat: 49.0025854, lng: 19.5621511 },
    { lat: 49.0028156, lng: 19.5627554 },
    { lat: 49.0044838, lng: 19.5642879 },
    { lat: 49.0045201, lng: 19.564489 },
    { lat: 49.0047882, lng: 19.564663 },
    { lat: 49.0051727, lng: 19.5648481 },
    { lat: 49.0052328, lng: 19.5648759 },
    { lat: 49.005683, lng: 19.5650111 },
    { lat: 49.0060301, lng: 19.5649643 },
    { lat: 49.0061635, lng: 19.5651262 },
    { lat: 49.0065686, lng: 19.5650877 },
    { lat: 49.0073236, lng: 19.5672278 },
    { lat: 49.0083496, lng: 19.5683339 },
    { lat: 49.0090081, lng: 19.5689447 },
    { lat: 49.0094199, lng: 19.5688037 },
    { lat: 49.0099585, lng: 19.5686059 },
    { lat: 49.0104555, lng: 19.5686317 },
    { lat: 49.0115231, lng: 19.5683732 },
    { lat: 49.0128113, lng: 19.5685298 },
    { lat: 49.0131954, lng: 19.5686228 },
    { lat: 49.0142269, lng: 19.5685837 },
    { lat: 49.0150182, lng: 19.5677212 },
    { lat: 49.0161129, lng: 19.5678583 },
    { lat: 49.017057, lng: 19.5679813 },
    { lat: 49.0176005, lng: 19.5677398 },
    { lat: 49.0179522, lng: 19.567847 },
    { lat: 49.0185056, lng: 19.567998 },
    { lat: 49.019147, lng: 19.5683544 },
    { lat: 49.0195094, lng: 19.5684167 },
    { lat: 49.0200183, lng: 19.5682632 },
    { lat: 49.0203167, lng: 19.5679584 },
    { lat: 49.0210622, lng: 19.5672984 },
    { lat: 49.0217782, lng: 19.5673379 },
    { lat: 49.0223658, lng: 19.5674714 },
    { lat: 49.0228033, lng: 19.5672058 },
    { lat: 49.0232502, lng: 19.5667777 },
    { lat: 49.0240927, lng: 19.5664971 },
    { lat: 49.0245055, lng: 19.5664054 },
    { lat: 49.0260512, lng: 19.5661975 },
    { lat: 49.026342, lng: 19.5661541 },
    { lat: 49.0265796, lng: 19.5660725 },
    { lat: 49.0275284, lng: 19.5655544 },
    { lat: 49.0269484, lng: 19.568945 },
    { lat: 49.0264091, lng: 19.5695512 },
    { lat: 49.0261473, lng: 19.5701136 },
    { lat: 49.0260746, lng: 19.5703018 },
    { lat: 49.0260671, lng: 19.570336 },
    { lat: 49.0260123, lng: 19.5713147 },
    { lat: 49.0259803, lng: 19.5719288 },
    { lat: 49.0261943, lng: 19.5722234 },
    { lat: 49.0263682, lng: 19.5723094 },
    { lat: 49.0268423, lng: 19.5721273 },
    { lat: 49.0270962, lng: 19.5719876 },
    { lat: 49.0276697, lng: 19.5719078 },
    { lat: 49.027841, lng: 19.571738 },
    { lat: 49.0280111, lng: 19.5715378 },
    { lat: 49.0281456, lng: 19.5715453 },
    { lat: 49.028482, lng: 19.5716853 },
    { lat: 49.0288485, lng: 19.5717195 },
    { lat: 49.0289897, lng: 19.5717238 },
    { lat: 49.0291367, lng: 19.5717583 },
    { lat: 49.0293822, lng: 19.5718572 },
    { lat: 49.0296732, lng: 19.5719625 },
    { lat: 49.0300998, lng: 19.5720561 },
    { lat: 49.0307151, lng: 19.5722111 },
    { lat: 49.0308994, lng: 19.5720701 },
    { lat: 49.0311154, lng: 19.571739 },
    { lat: 49.0313044, lng: 19.5716841 },
    { lat: 49.0313684, lng: 19.5717262 },
    { lat: 49.031529, lng: 19.5717385 },
    { lat: 49.0316387, lng: 19.5716731 },
    { lat: 49.0319616, lng: 19.5717325 },
    { lat: 49.031992, lng: 19.5717381 },
    { lat: 49.0320615, lng: 19.5717556 },
    { lat: 49.0324817, lng: 19.5718266 },
    { lat: 49.0328698, lng: 19.5722108 },
    { lat: 49.0332518, lng: 19.5725608 },
    { lat: 49.0335569, lng: 19.5729623 },
    { lat: 49.0335847, lng: 19.5729989 },
    { lat: 49.0335984, lng: 19.5731235 },
    { lat: 49.0336849, lng: 19.5733464 },
    { lat: 49.0339742, lng: 19.5739115 },
    { lat: 49.0341402, lng: 19.5740683 },
    { lat: 49.0344839, lng: 19.574144 },
    { lat: 49.0346064, lng: 19.5742327 },
    { lat: 49.0346784, lng: 19.5742849 },
    { lat: 49.0348082, lng: 19.5744875 },
    { lat: 49.0350657, lng: 19.5749238 },
    { lat: 49.0355769, lng: 19.5755408 },
    { lat: 49.0358256, lng: 19.5757813 },
  ],
  Podtureň: [
    { lat: 49.033895, lng: 19.7073736 },
    { lat: 49.0342564, lng: 19.7080862 },
    { lat: 49.0342781, lng: 19.7081453 },
    { lat: 49.0345455, lng: 19.7089942 },
    { lat: 49.0346467, lng: 19.7094007 },
    { lat: 49.0346585, lng: 19.7097173 },
    { lat: 49.0346455, lng: 19.7099619 },
    { lat: 49.0347155, lng: 19.7100194 },
    { lat: 49.0353531, lng: 19.7105004 },
    { lat: 49.0358873, lng: 19.7108359 },
    { lat: 49.0360079, lng: 19.7109439 },
    { lat: 49.036262, lng: 19.7111873 },
    { lat: 49.0370452, lng: 19.7119557 },
    { lat: 49.0377337, lng: 19.7126285 },
    { lat: 49.0386207, lng: 19.7135041 },
    { lat: 49.03924, lng: 19.7141104 },
    { lat: 49.0398716, lng: 19.7147413 },
    { lat: 49.0406876, lng: 19.7155161 },
    { lat: 49.0409043, lng: 19.7159613 },
    { lat: 49.0411286, lng: 19.7162466 },
    { lat: 49.0418262, lng: 19.7169703 },
    { lat: 49.0421873, lng: 19.7173375 },
    { lat: 49.0426018, lng: 19.7176972 },
    { lat: 49.0428539, lng: 19.7180777 },
    { lat: 49.0430256, lng: 19.7183418 },
    { lat: 49.0431614, lng: 19.718611 },
    { lat: 49.0433185, lng: 19.7189591 },
    { lat: 49.0434733, lng: 19.7191725 },
    { lat: 49.0440038, lng: 19.7191455 },
    { lat: 49.0440078, lng: 19.7194675 },
    { lat: 49.0439917, lng: 19.7199166 },
    { lat: 49.0441132, lng: 19.7202483 },
    { lat: 49.0446034, lng: 19.7198433 },
    { lat: 49.0445874, lng: 19.7188132 },
    { lat: 49.0446827, lng: 19.7183122 },
    { lat: 49.0448176, lng: 19.7175892 },
    { lat: 49.0449843, lng: 19.71672 },
    { lat: 49.045217, lng: 19.7154778 },
    { lat: 49.0453953, lng: 19.7145341 },
    { lat: 49.0454437, lng: 19.7142024 },
    { lat: 49.0455281, lng: 19.7136637 },
    { lat: 49.0455377, lng: 19.7136232 },
    { lat: 49.0456139, lng: 19.7131285 },
    { lat: 49.0460249, lng: 19.7131976 },
    { lat: 49.0463053, lng: 19.7131978 },
    { lat: 49.0463454, lng: 19.7131854 },
    { lat: 49.0463837, lng: 19.7131593 },
    { lat: 49.0465767, lng: 19.713066 },
    { lat: 49.0469033, lng: 19.7125802 },
    { lat: 49.0469702, lng: 19.7124858 },
    { lat: 49.0469824, lng: 19.7124791 },
    { lat: 49.0470006, lng: 19.712714 },
    { lat: 49.0470358, lng: 19.712747 },
    { lat: 49.0470658, lng: 19.7127651 },
    { lat: 49.0470611, lng: 19.7128083 },
    { lat: 49.0470904, lng: 19.7129368 },
    { lat: 49.0471134, lng: 19.7129988 },
    { lat: 49.0471264, lng: 19.7130894 },
    { lat: 49.0470775, lng: 19.7131321 },
    { lat: 49.0470653, lng: 19.7132633 },
    { lat: 49.0471072, lng: 19.7132925 },
    { lat: 49.0471328, lng: 19.7134938 },
    { lat: 49.0470951, lng: 19.7136079 },
    { lat: 49.0470279, lng: 19.7135925 },
    { lat: 49.0470127, lng: 19.7136012 },
    { lat: 49.0469348, lng: 19.7137316 },
    { lat: 49.0469751, lng: 19.7137994 },
    { lat: 49.0469955, lng: 19.7138803 },
    { lat: 49.0470015, lng: 19.7140179 },
    { lat: 49.0469326, lng: 19.7140623 },
    { lat: 49.0469111, lng: 19.7140882 },
    { lat: 49.0468872, lng: 19.7141294 },
    { lat: 49.0469, lng: 19.7143443 },
    { lat: 49.0469084, lng: 19.71436 },
    { lat: 49.0470017, lng: 19.7143541 },
    { lat: 49.0471303, lng: 19.7140483 },
    { lat: 49.0475081, lng: 19.7131863 },
    { lat: 49.0477592, lng: 19.7125932 },
    { lat: 49.0478799, lng: 19.7123166 },
    { lat: 49.048045, lng: 19.712456 },
    { lat: 49.0481057, lng: 19.7123284 },
    { lat: 49.0484738, lng: 19.712807 },
    { lat: 49.0486617, lng: 19.7128292 },
    { lat: 49.0489427, lng: 19.7130179 },
    { lat: 49.0493743, lng: 19.7133029 },
    { lat: 49.049576, lng: 19.7134824 },
    { lat: 49.0496238, lng: 19.7132639 },
    { lat: 49.0500707, lng: 19.7135118 },
    { lat: 49.0503233, lng: 19.7136462 },
    { lat: 49.0504287, lng: 19.7137081 },
    { lat: 49.0505695, lng: 19.7126101 },
    { lat: 49.0508223, lng: 19.7127208 },
    { lat: 49.0514016, lng: 19.7129829 },
    { lat: 49.0522247, lng: 19.7131735 },
    { lat: 49.0524809, lng: 19.7132394 },
    { lat: 49.0525148, lng: 19.7132417 },
    { lat: 49.0525023, lng: 19.7135709 },
    { lat: 49.0524885, lng: 19.7140769 },
    { lat: 49.0526998, lng: 19.7141686 },
    { lat: 49.0533186, lng: 19.7142018 },
    { lat: 49.0538064, lng: 19.7142247 },
    { lat: 49.0544237, lng: 19.7142505 },
    { lat: 49.0547874, lng: 19.7142692 },
    { lat: 49.0547925, lng: 19.7145628 },
    { lat: 49.0546127, lng: 19.7145768 },
    { lat: 49.0546476, lng: 19.7150101 },
    { lat: 49.0546999, lng: 19.7155877 },
    { lat: 49.0547213, lng: 19.7158647 },
    { lat: 49.0547792, lng: 19.716515 },
    { lat: 49.0548329, lng: 19.7171999 },
    { lat: 49.0550319, lng: 19.7172156 },
    { lat: 49.0551099, lng: 19.7173258 },
    { lat: 49.0551887, lng: 19.7179637 },
    { lat: 49.0552655, lng: 19.7186085 },
    { lat: 49.0553016, lng: 19.7192529 },
    { lat: 49.0554806, lng: 19.719813 },
    { lat: 49.0557386, lng: 19.7206621 },
    { lat: 49.0559341, lng: 19.7213129 },
    { lat: 49.0562073, lng: 19.7221475 },
    { lat: 49.0562233, lng: 19.7222052 },
    { lat: 49.0572346, lng: 19.7212744 },
    { lat: 49.0573656, lng: 19.7211508 },
    { lat: 49.0577157, lng: 19.7209409 },
    { lat: 49.0577666, lng: 19.7209167 },
    { lat: 49.0579905, lng: 19.720784 },
    { lat: 49.0584894, lng: 19.7204736 },
    { lat: 49.0585919, lng: 19.7203949 },
    { lat: 49.0587661, lng: 19.7202335 },
    { lat: 49.0589215, lng: 19.7200793 },
    { lat: 49.0592213, lng: 19.7197992 },
    { lat: 49.0593472, lng: 19.7196862 },
    { lat: 49.0597344, lng: 19.719114 },
    { lat: 49.0589126, lng: 19.717029 },
    { lat: 49.0586611, lng: 19.7169464 },
    { lat: 49.0585458, lng: 19.7166532 },
    { lat: 49.058499, lng: 19.7163102 },
    { lat: 49.0586269, lng: 19.7153477 },
    { lat: 49.0591286, lng: 19.714483 },
    { lat: 49.0595474, lng: 19.7136827 },
    { lat: 49.0595574, lng: 19.7136048 },
    { lat: 49.0596257, lng: 19.7135964 },
    { lat: 49.0599374, lng: 19.7136897 },
    { lat: 49.0609192, lng: 19.7118032 },
    { lat: 49.0608168, lng: 19.7117026 },
    { lat: 49.0602874, lng: 19.7112238 },
    { lat: 49.0604396, lng: 19.7109162 },
    { lat: 49.0612056, lng: 19.7092247 },
    { lat: 49.0612233, lng: 19.7092201 },
    { lat: 49.0611466, lng: 19.7090371 },
    { lat: 49.0611296, lng: 19.708957 },
    { lat: 49.0611603, lng: 19.7088119 },
    { lat: 49.0612917, lng: 19.7085424 },
    { lat: 49.061232, lng: 19.7083933 },
    { lat: 49.0610797, lng: 19.7083876 },
    { lat: 49.0609277, lng: 19.7082871 },
    { lat: 49.0607088, lng: 19.7082796 },
    { lat: 49.0605601, lng: 19.7081773 },
    { lat: 49.0604869, lng: 19.7080496 },
    { lat: 49.0607679, lng: 19.7073169 },
    { lat: 49.0608584, lng: 19.7065848 },
    { lat: 49.0611073, lng: 19.7046393 },
    { lat: 49.0613784, lng: 19.7025362 },
    { lat: 49.0614226, lng: 19.7022055 },
    { lat: 49.0616063, lng: 19.7007781 },
    { lat: 49.0617074, lng: 19.7001833 },
    { lat: 49.0616802, lng: 19.700028 },
    { lat: 49.0616881, lng: 19.6996183 },
    { lat: 49.0617598, lng: 19.6991249 },
    { lat: 49.0618832, lng: 19.6991112 },
    { lat: 49.062819, lng: 19.6991809 },
    { lat: 49.0641547, lng: 19.699324 },
    { lat: 49.0643019, lng: 19.6992626 },
    { lat: 49.0645511, lng: 19.6991908 },
    { lat: 49.0645617, lng: 19.6984363 },
    { lat: 49.0645536, lng: 19.6977653 },
    { lat: 49.0645366, lng: 19.6972514 },
    { lat: 49.0645208, lng: 19.697022 },
    { lat: 49.0645194, lng: 19.6969144 },
    { lat: 49.0645291, lng: 19.6959581 },
    { lat: 49.0648549, lng: 19.6950216 },
    { lat: 49.0651388, lng: 19.6944576 },
    { lat: 49.0654463, lng: 19.6938947 },
    { lat: 49.0656618, lng: 19.69324 },
    { lat: 49.0663411, lng: 19.6918095 },
    { lat: 49.0665915, lng: 19.6912044 },
    { lat: 49.0662924, lng: 19.6901729 },
    { lat: 49.066321, lng: 19.6900787 },
    { lat: 49.0659899, lng: 19.6888098 },
    { lat: 49.0657897, lng: 19.6884089 },
    { lat: 49.0650945, lng: 19.6871338 },
    { lat: 49.0649649, lng: 19.6870066 },
    { lat: 49.0649027, lng: 19.6869242 },
    { lat: 49.0647686, lng: 19.6867971 },
    { lat: 49.0640016, lng: 19.685883 },
    { lat: 49.0636143, lng: 19.685507 },
    { lat: 49.0632031, lng: 19.6852002 },
    { lat: 49.062886, lng: 19.6848354 },
    { lat: 49.0627572, lng: 19.6847002 },
    { lat: 49.0622154, lng: 19.6840911 },
    { lat: 49.0620684, lng: 19.6839229 },
    { lat: 49.0619256, lng: 19.6841362 },
    { lat: 49.0615437, lng: 19.6846923 },
    { lat: 49.0606498, lng: 19.6859831 },
    { lat: 49.0598012, lng: 19.6871768 },
    { lat: 49.0596153, lng: 19.6874775 },
    { lat: 49.059533, lng: 19.6876303 },
    { lat: 49.0587222, lng: 19.6890914 },
    { lat: 49.0583427, lng: 19.6896594 },
    { lat: 49.0581456, lng: 19.6899112 },
    { lat: 49.0579676, lng: 19.6900929 },
    { lat: 49.0578452, lng: 19.6902031 },
    { lat: 49.0576996, lng: 19.690301 },
    { lat: 49.0575977, lng: 19.6903395 },
    { lat: 49.0573, lng: 19.6904357 },
    { lat: 49.0568275, lng: 19.6905526 },
    { lat: 49.0565225, lng: 19.6905576 },
    { lat: 49.0562427, lng: 19.6905518 },
    { lat: 49.0551009, lng: 19.6902598 },
    { lat: 49.0546408, lng: 19.6900684 },
    { lat: 49.0543638, lng: 19.6900468 },
    { lat: 49.0541005, lng: 19.6899814 },
    { lat: 49.0537352, lng: 19.6898995 },
    { lat: 49.0537381, lng: 19.689965 },
    { lat: 49.0537199, lng: 19.690006 },
    { lat: 49.0537273, lng: 19.6898967 },
    { lat: 49.0534962, lng: 19.6897893 },
    { lat: 49.0534653, lng: 19.6897809 },
    { lat: 49.0533903, lng: 19.6896906 },
    { lat: 49.0532076, lng: 19.689558 },
    { lat: 49.0527834, lng: 19.6895702 },
    { lat: 49.0524113, lng: 19.6894628 },
    { lat: 49.0520022, lng: 19.6895108 },
    { lat: 49.0517631, lng: 19.6894257 },
    { lat: 49.0509061, lng: 19.6893833 },
    { lat: 49.0508165, lng: 19.6894227 },
    { lat: 49.0507299, lng: 19.6894987 },
    { lat: 49.0505903, lng: 19.6895037 },
    { lat: 49.0500419, lng: 19.6897376 },
    { lat: 49.0499138, lng: 19.68982 },
    { lat: 49.0497685, lng: 19.6899254 },
    { lat: 49.0495239, lng: 19.6901548 },
    { lat: 49.0491897, lng: 19.6903359 },
    { lat: 49.0486596, lng: 19.6912453 },
    { lat: 49.0483231, lng: 19.6915846 },
    { lat: 49.0481036, lng: 19.6917672 },
    { lat: 49.0477982, lng: 19.6919676 },
    { lat: 49.0476017, lng: 19.6920614 },
    { lat: 49.0472863, lng: 19.6921909 },
    { lat: 49.0470328, lng: 19.692265 },
    { lat: 49.046744, lng: 19.6923333 },
    { lat: 49.0465741, lng: 19.6923155 },
    { lat: 49.0464313, lng: 19.6923696 },
    { lat: 49.0462032, lng: 19.692918 },
    { lat: 49.0458693, lng: 19.6936212 },
    { lat: 49.0457673, lng: 19.6937875 },
    { lat: 49.0456874, lng: 19.6938654 },
    { lat: 49.0453437, lng: 19.6940208 },
    { lat: 49.0451757, lng: 19.6940653 },
    { lat: 49.0449979, lng: 19.6940709 },
    { lat: 49.0443716, lng: 19.694354 },
    { lat: 49.0437352, lng: 19.6947142 },
    { lat: 49.0433282, lng: 19.6949663 },
    { lat: 49.0430952, lng: 19.6951733 },
    { lat: 49.0428136, lng: 19.6954531 },
    { lat: 49.0422572, lng: 19.696198 },
    { lat: 49.0419212, lng: 19.6967792 },
    { lat: 49.0418138, lng: 19.6970998 },
    { lat: 49.0417559, lng: 19.6973179 },
    { lat: 49.0417516, lng: 19.6974028 },
    { lat: 49.0416279, lng: 19.6977124 },
    { lat: 49.0415781, lng: 19.6978101 },
    { lat: 49.0414207, lng: 19.6983038 },
    { lat: 49.0411445, lng: 19.6988872 },
    { lat: 49.0410047, lng: 19.6991633 },
    { lat: 49.0407676, lng: 19.6995362 },
    { lat: 49.0404446, lng: 19.6998999 },
    { lat: 49.0402348, lng: 19.7002538 },
    { lat: 49.0399765, lng: 19.7006016 },
    { lat: 49.0395237, lng: 19.7009653 },
    { lat: 49.039346, lng: 19.7009938 },
    { lat: 49.0390103, lng: 19.701097 },
    { lat: 49.0389894, lng: 19.7011088 },
    { lat: 49.0389629, lng: 19.7010972 },
    { lat: 49.0387087, lng: 19.7010826 },
    { lat: 49.0386486, lng: 19.7011483 },
    { lat: 49.0385092, lng: 19.7011044 },
    { lat: 49.038126, lng: 19.7012052 },
    { lat: 49.0378839, lng: 19.7013574 },
    { lat: 49.037657, lng: 19.7013526 },
    { lat: 49.0372526, lng: 19.7018111 },
    { lat: 49.0370602, lng: 19.7018921 },
    { lat: 49.0365591, lng: 19.7023544 },
    { lat: 49.0362108, lng: 19.7027397 },
    { lat: 49.0358195, lng: 19.7029336 },
    { lat: 49.0356408, lng: 19.7030481 },
    { lat: 49.0355574, lng: 19.7031212 },
    { lat: 49.0354381, lng: 19.7032533 },
    { lat: 49.0353117, lng: 19.7034278 },
    { lat: 49.0350691, lng: 19.7030605 },
    { lat: 49.0349342, lng: 19.7033191 },
    { lat: 49.0348794, lng: 19.7035344 },
    { lat: 49.0348334, lng: 19.7039473 },
    { lat: 49.0347106, lng: 19.7045606 },
    { lat: 49.0346001, lng: 19.7059384 },
    { lat: 49.0347293, lng: 19.7064161 },
    { lat: 49.0348439, lng: 19.7075857 },
    { lat: 49.0345691, lng: 19.7075645 },
    { lat: 49.0341271, lng: 19.7075029 },
    { lat: 49.033895, lng: 19.7073736 },
  ],
  Pribylina: [
    { lat: 49.204178, lng: 19.883928 },
    { lat: 49.204174, lng: 19.883917 },
    { lat: 49.20417, lng: 19.883903 },
    { lat: 49.204114, lng: 19.883696 },
    { lat: 49.204113, lng: 19.883691 },
    { lat: 49.2041006, lng: 19.8833826 },
    { lat: 49.204091, lng: 19.883144 },
    { lat: 49.204083, lng: 19.882856 },
    { lat: 49.204067, lng: 19.882297 },
    { lat: 49.2040619, lng: 19.8821203 },
    { lat: 49.204165, lng: 19.881705 },
    { lat: 49.204241, lng: 19.881247 },
    { lat: 49.204293, lng: 19.881189 },
    { lat: 49.204367, lng: 19.881104 },
    { lat: 49.204165, lng: 19.880753 },
    { lat: 49.204111, lng: 19.88066 },
    { lat: 49.204002, lng: 19.880454 },
    { lat: 49.203952, lng: 19.880228 },
    { lat: 49.203943, lng: 19.880216 },
    { lat: 49.203847, lng: 19.880106 },
    { lat: 49.203678, lng: 19.879903 },
    { lat: 49.203665, lng: 19.879866 },
    { lat: 49.203528, lng: 19.879473 },
    { lat: 49.203519, lng: 19.879409 },
    { lat: 49.203503, lng: 19.879299 },
    { lat: 49.203466, lng: 19.879244 },
    { lat: 49.203425, lng: 19.879182 },
    { lat: 49.203006, lng: 19.878363 },
    { lat: 49.202749, lng: 19.877865 },
    { lat: 49.202432, lng: 19.877249 },
    { lat: 49.202021, lng: 19.876393 },
    { lat: 49.201874, lng: 19.876087 },
    { lat: 49.2018111, lng: 19.875998 },
    { lat: 49.201672, lng: 19.875801 },
    { lat: 49.201087, lng: 19.874975 },
    { lat: 49.200993, lng: 19.87483 },
    { lat: 49.200908, lng: 19.8747 },
    { lat: 49.2004992, lng: 19.8735448 },
    { lat: 49.200285, lng: 19.873254 },
    { lat: 49.199722, lng: 19.872556 },
    { lat: 49.1992228, lng: 19.8717669 },
    { lat: 49.199206, lng: 19.871858 },
    { lat: 49.198863, lng: 19.870993 },
    { lat: 49.198677, lng: 19.870651 },
    { lat: 49.1985473, lng: 19.8704132 },
    { lat: 49.198486, lng: 19.870301 },
    { lat: 49.198407, lng: 19.870259 },
    { lat: 49.1983, lng: 19.870201 },
    { lat: 49.198187, lng: 19.870106 },
    { lat: 49.198036, lng: 19.869979 },
    { lat: 49.1978074, lng: 19.8695792 },
    { lat: 49.197758, lng: 19.869865 },
    { lat: 49.197667, lng: 19.869827 },
    { lat: 49.197259, lng: 19.869864 },
    { lat: 49.197244, lng: 19.869871 },
    { lat: 49.197138, lng: 19.869917 },
    { lat: 49.197045, lng: 19.870027 },
    { lat: 49.196908, lng: 19.87009 },
    { lat: 49.196832, lng: 19.870094 },
    { lat: 49.196719, lng: 19.8701 },
    { lat: 49.1965529, lng: 19.8698651 },
    { lat: 49.196477, lng: 19.869979 },
    { lat: 49.196341, lng: 19.869876 },
    { lat: 49.196265, lng: 19.869775 },
    { lat: 49.1962498, lng: 19.8696822 },
    { lat: 49.196185, lng: 19.86967 },
    { lat: 49.196125, lng: 19.8696 },
    { lat: 49.195958, lng: 19.869556 },
    { lat: 49.195797, lng: 19.869438 },
    { lat: 49.195908, lng: 19.868558 },
    { lat: 49.196093, lng: 19.867677 },
    { lat: 49.196193, lng: 19.86693 },
    { lat: 49.196342, lng: 19.866198 },
    { lat: 49.196498, lng: 19.865303 },
    { lat: 49.19666, lng: 19.864404 },
    { lat: 49.196713, lng: 19.864109 },
    { lat: 49.196687, lng: 19.863342 },
    { lat: 49.1966381, lng: 19.8624037 },
    { lat: 49.196622, lng: 19.862095 },
    { lat: 49.196621, lng: 19.862077 },
    { lat: 49.196619, lng: 19.862042 },
    { lat: 49.196562, lng: 19.860725 },
    { lat: 49.196385, lng: 19.859706 },
    { lat: 49.196328, lng: 19.858746 },
    { lat: 49.196323, lng: 19.85866 },
    { lat: 49.196333, lng: 19.85852 },
    { lat: 49.1963295, lng: 19.8579373 },
    { lat: 49.196396, lng: 19.857594 },
    { lat: 49.196365, lng: 19.857389 },
    { lat: 49.196279, lng: 19.856832 },
    { lat: 49.196278, lng: 19.856828 },
    { lat: 49.196351, lng: 19.856302 },
    { lat: 49.196327, lng: 19.856219 },
    { lat: 49.196281, lng: 19.856057 },
    { lat: 49.196418, lng: 19.855409 },
    { lat: 49.196292, lng: 19.854595 },
    { lat: 49.1962907, lng: 19.8544488 },
    { lat: 49.196285, lng: 19.853777 },
    { lat: 49.196279, lng: 19.853165 },
    { lat: 49.196231, lng: 19.853012 },
    { lat: 49.196169, lng: 19.852817 },
    { lat: 49.196187, lng: 19.852434 },
    { lat: 49.196202, lng: 19.852127 },
    { lat: 49.196252, lng: 19.851147 },
    { lat: 49.196236, lng: 19.851025 },
    { lat: 49.196221, lng: 19.850897 },
    { lat: 49.196059, lng: 19.850298 },
    { lat: 49.195823, lng: 19.849425 },
    { lat: 49.195554, lng: 19.848214 },
    { lat: 49.195518, lng: 19.847655 },
    { lat: 49.1955, lng: 19.847384 },
    { lat: 49.195468, lng: 19.847154 },
    { lat: 49.195396, lng: 19.846623 },
    { lat: 49.195254, lng: 19.84633 },
    { lat: 49.195121, lng: 19.84529 },
    { lat: 49.195109, lng: 19.845157 },
    { lat: 49.195066, lng: 19.844676 },
    { lat: 49.194919, lng: 19.844343 },
    { lat: 49.194621, lng: 19.84353 },
    { lat: 49.194407, lng: 19.843121 },
    { lat: 49.194206, lng: 19.842734 },
    { lat: 49.193602, lng: 19.842026 },
    { lat: 49.193167, lng: 19.841382 },
    { lat: 49.192908, lng: 19.841203 },
    { lat: 49.192616, lng: 19.841 },
    { lat: 49.193087, lng: 19.840827 },
    { lat: 49.193675, lng: 19.840579 },
    { lat: 49.194448, lng: 19.840552 },
    { lat: 49.195213, lng: 19.840051 },
    { lat: 49.195889, lng: 19.840025 },
    { lat: 49.196089, lng: 19.839647 },
    { lat: 49.196076, lng: 19.83944 },
    { lat: 49.196066, lng: 19.839297 },
    { lat: 49.196194, lng: 19.838771 },
    { lat: 49.196382, lng: 19.838198 },
    { lat: 49.196384, lng: 19.838183 },
    { lat: 49.196407, lng: 19.83784 },
    { lat: 49.196411, lng: 19.837725 },
    { lat: 49.196411, lng: 19.837718 },
    { lat: 49.196337, lng: 19.837475 },
    { lat: 49.196322, lng: 19.83714 },
    { lat: 49.196311, lng: 19.836872 },
    { lat: 49.196506, lng: 19.836445 },
    { lat: 49.196552, lng: 19.83615 },
    { lat: 49.196576, lng: 19.835998 },
    { lat: 49.196598, lng: 19.835246 },
    { lat: 49.19654, lng: 19.835017 },
    { lat: 49.196494, lng: 19.834835 },
    { lat: 49.196513, lng: 19.83465 },
    { lat: 49.196525, lng: 19.834528 },
    { lat: 49.196706, lng: 19.834324 },
    { lat: 49.196767, lng: 19.833958 },
    { lat: 49.196777, lng: 19.833899 },
    { lat: 49.196877, lng: 19.833835 },
    { lat: 49.196992, lng: 19.833762 },
    { lat: 49.197258, lng: 19.833459 },
    { lat: 49.197435, lng: 19.833294 },
    { lat: 49.197527, lng: 19.832951 },
    { lat: 49.197721, lng: 19.832867 },
    { lat: 49.197897, lng: 19.83252 },
    { lat: 49.198161, lng: 19.832422 },
    { lat: 49.198457, lng: 19.832313 },
    { lat: 49.198798, lng: 19.831932 },
    { lat: 49.199226, lng: 19.831453 },
    { lat: 49.199502, lng: 19.831436 },
    { lat: 49.199573, lng: 19.831298 },
    { lat: 49.199641, lng: 19.831166 },
    { lat: 49.19991, lng: 19.831119 },
    { lat: 49.200022, lng: 19.831075 },
    { lat: 49.200133, lng: 19.831031 },
    { lat: 49.200157, lng: 19.83104 },
    { lat: 49.200313, lng: 19.831106 },
    { lat: 49.200368, lng: 19.831064 },
    { lat: 49.20071, lng: 19.830806 },
    { lat: 49.201125, lng: 19.829922 },
    { lat: 49.201443, lng: 19.829664 },
    { lat: 49.2016922, lng: 19.8294593 },
    { lat: 49.201743, lng: 19.827693 },
    { lat: 49.201656, lng: 19.827486 },
    { lat: 49.201346, lng: 19.826749 },
    { lat: 49.201317, lng: 19.826634 },
    { lat: 49.20147, lng: 19.826096 },
    { lat: 49.201466, lng: 19.825739 },
    { lat: 49.201458, lng: 19.825581 },
    { lat: 49.201451, lng: 19.825456 },
    { lat: 49.201506, lng: 19.8253 },
    { lat: 49.201531, lng: 19.825229 },
    { lat: 49.201533, lng: 19.824917 },
    { lat: 49.201528, lng: 19.824899 },
    { lat: 49.201273, lng: 19.824098 },
    { lat: 49.201283, lng: 19.823363 },
    { lat: 49.201432, lng: 19.822689 },
    { lat: 49.201438, lng: 19.82266 },
    { lat: 49.201367, lng: 19.822538 },
    { lat: 49.201188, lng: 19.822237 },
    { lat: 49.200943, lng: 19.821678 },
    { lat: 49.20081, lng: 19.821373 },
    { lat: 49.200511, lng: 19.821069 },
    { lat: 49.20008, lng: 19.820632 },
    { lat: 49.199844, lng: 19.820291 },
    { lat: 49.199528, lng: 19.819834 },
    { lat: 49.199859, lng: 19.819131 },
    { lat: 49.200089, lng: 19.818811 },
    { lat: 49.200666, lng: 19.81806 },
    { lat: 49.201039, lng: 19.817575 },
    { lat: 49.2016, lng: 19.816823 },
    { lat: 49.201985, lng: 19.816324 },
    { lat: 49.202459, lng: 19.815712 },
    { lat: 49.202954, lng: 19.814804 },
    { lat: 49.203264, lng: 19.814236 },
    { lat: 49.203504, lng: 19.813648 },
    { lat: 49.203503, lng: 19.813647 },
    { lat: 49.203827, lng: 19.812375 },
    { lat: 49.203863, lng: 19.812241 },
    { lat: 49.204294, lng: 19.810658 },
    { lat: 49.204517, lng: 19.810152 },
    { lat: 49.204976, lng: 19.809112 },
    { lat: 49.205652, lng: 19.807521 },
    { lat: 49.205161, lng: 19.8061 },
    { lat: 49.2051, lng: 19.805941 },
    { lat: 49.205028, lng: 19.805749 },
    { lat: 49.204771, lng: 19.804723 },
    { lat: 49.204763, lng: 19.80461 },
    { lat: 49.204758, lng: 19.804555 },
    { lat: 49.204685, lng: 19.804354 },
    { lat: 49.204618, lng: 19.803739 },
    { lat: 49.204511, lng: 19.8036 },
    { lat: 49.20439, lng: 19.803443 },
    { lat: 49.20398, lng: 19.802937 },
    { lat: 49.203877, lng: 19.802742 },
    { lat: 49.203412, lng: 19.802197 },
    { lat: 49.202948, lng: 19.801633 },
    { lat: 49.202588, lng: 19.801195 },
    { lat: 49.20245, lng: 19.801069 },
    { lat: 49.20226, lng: 19.800898 },
    { lat: 49.202143, lng: 19.80073 },
    { lat: 49.202063, lng: 19.800469 },
    { lat: 49.202059, lng: 19.800465 },
    { lat: 49.201979, lng: 19.800387 },
    { lat: 49.201793, lng: 19.799879 },
    { lat: 49.201769, lng: 19.799794 },
    { lat: 49.201622, lng: 19.799266 },
    { lat: 49.201469, lng: 19.799172 },
    { lat: 49.200945, lng: 19.798336 },
    { lat: 49.200849, lng: 19.798075 },
    { lat: 49.200668, lng: 19.797822 },
    { lat: 49.200552, lng: 19.797499 },
    { lat: 49.200493, lng: 19.797336 },
    { lat: 49.200383, lng: 19.797269 },
    { lat: 49.200348, lng: 19.797247 },
    { lat: 49.19988, lng: 19.79662 },
    { lat: 49.199777, lng: 19.79648 },
    { lat: 49.199611, lng: 19.796256 },
    { lat: 49.199556, lng: 19.796069 },
    { lat: 49.199541, lng: 19.796055 },
    { lat: 49.199378, lng: 19.795905 },
    { lat: 49.199259, lng: 19.795812 },
    { lat: 49.1990342, lng: 19.7956367 },
    { lat: 49.1987565, lng: 19.7952356 },
    { lat: 49.198366, lng: 19.795032 },
    { lat: 49.1973866, lng: 19.79507 },
    { lat: 49.1970866, lng: 19.7954312 },
    { lat: 49.1961633, lng: 19.7969006 },
    { lat: 49.1959493, lng: 19.7972913 },
    { lat: 49.1955331, lng: 19.7979364 },
    { lat: 49.195114, lng: 19.7983765 },
    { lat: 49.19454, lng: 19.7994063 },
    { lat: 49.1936462, lng: 19.8009353 },
    { lat: 49.1933472, lng: 19.8012458 },
    { lat: 49.1931665, lng: 19.80133 },
    { lat: 49.1929261, lng: 19.8012165 },
    { lat: 49.1923429, lng: 19.8011699 },
    { lat: 49.192167, lng: 19.8011411 },
    { lat: 49.1905876, lng: 19.7987051 },
    { lat: 49.1898079, lng: 19.797583 },
    { lat: 49.1890061, lng: 19.7963851 },
    { lat: 49.1877299, lng: 19.7962022 },
    { lat: 49.1864699, lng: 19.7962956 },
    { lat: 49.1846513, lng: 19.7951344 },
    { lat: 49.1834713, lng: 19.7937625 },
    { lat: 49.1821046, lng: 19.7937847 },
    { lat: 49.1792827, lng: 19.7937813 },
    { lat: 49.1781484, lng: 19.7932321 },
    { lat: 49.1753911, lng: 19.7930296 },
    { lat: 49.1745791, lng: 19.7935555 },
    { lat: 49.1743115, lng: 19.793829 },
    { lat: 49.1738527, lng: 19.7933513 },
    { lat: 49.1728337, lng: 19.7928651 },
    { lat: 49.1719709, lng: 19.7922962 },
    { lat: 49.1710025, lng: 19.7910748 },
    { lat: 49.1702339, lng: 19.7907302 },
    { lat: 49.1696646, lng: 19.7900714 },
    { lat: 49.168883, lng: 19.7896838 },
    { lat: 49.1686378, lng: 19.7893009 },
    { lat: 49.1681841, lng: 19.7889966 },
    { lat: 49.16717, lng: 19.7884661 },
    { lat: 49.1662769, lng: 19.7882118 },
    { lat: 49.1662789, lng: 19.7882314 },
    { lat: 49.1647553, lng: 19.7865251 },
    { lat: 49.1646005, lng: 19.7861494 },
    { lat: 49.1637453, lng: 19.7846333 },
    { lat: 49.1633317, lng: 19.7841857 },
    { lat: 49.162836, lng: 19.7833516 },
    { lat: 49.1626649, lng: 19.7831226 },
    { lat: 49.1625438, lng: 19.7826384 },
    { lat: 49.162413, lng: 19.7820389 },
    { lat: 49.1623997, lng: 19.781888 },
    { lat: 49.1623227, lng: 19.7816964 },
    { lat: 49.1623381, lng: 19.7811153 },
    { lat: 49.1622859, lng: 19.7808956 },
    { lat: 49.1621657, lng: 19.7808569 },
    { lat: 49.162068, lng: 19.7808632 },
    { lat: 49.1619986, lng: 19.7808957 },
    { lat: 49.1619399, lng: 19.7809736 },
    { lat: 49.1614742, lng: 19.7817436 },
    { lat: 49.1613126, lng: 19.7821224 },
    { lat: 49.1613184, lng: 19.7822557 },
    { lat: 49.1611712, lng: 19.7829397 },
    { lat: 49.16081, lng: 19.783391 },
    { lat: 49.1601674, lng: 19.783432 },
    { lat: 49.1596236, lng: 19.7832819 },
    { lat: 49.1594947, lng: 19.7832225 },
    { lat: 49.1590947, lng: 19.783116 },
    { lat: 49.1585184, lng: 19.7834623 },
    { lat: 49.1580327, lng: 19.7837138 },
    { lat: 49.1578339, lng: 19.7840185 },
    { lat: 49.157317, lng: 19.7847245 },
    { lat: 49.1571543, lng: 19.784788 },
    { lat: 49.1565957, lng: 19.7852884 },
    { lat: 49.1561428, lng: 19.7858832 },
    { lat: 49.1560287, lng: 19.7859814 },
    { lat: 49.1551906, lng: 19.7871782 },
    { lat: 49.1540146, lng: 19.7886136 },
    { lat: 49.1538457, lng: 19.7888735 },
    { lat: 49.1536951, lng: 19.7892219 },
    { lat: 49.1536108, lng: 19.7895388 },
    { lat: 49.1532271, lng: 19.7902511 },
    { lat: 49.1527063, lng: 19.790762 },
    { lat: 49.1524854, lng: 19.7910199 },
    { lat: 49.1524018, lng: 19.7910952 },
    { lat: 49.152224, lng: 19.7911491 },
    { lat: 49.1519502, lng: 19.7913342 },
    { lat: 49.1517404, lng: 19.7915331 },
    { lat: 49.1515707, lng: 19.7917787 },
    { lat: 49.1511062, lng: 19.7923705 },
    { lat: 49.1508012, lng: 19.7923742 },
    { lat: 49.1502468, lng: 19.7925292 },
    { lat: 49.1499547, lng: 19.7929098 },
    { lat: 49.1497528, lng: 19.7933234 },
    { lat: 49.1493603, lng: 19.7936475 },
    { lat: 49.1483495, lng: 19.793745 },
    { lat: 49.1481489, lng: 19.7935874 },
    { lat: 49.1478584, lng: 19.7936655 },
    { lat: 49.1477891, lng: 19.7937565 },
    { lat: 49.1475908, lng: 19.7940166 },
    { lat: 49.1472981, lng: 19.7942842 },
    { lat: 49.1469755, lng: 19.7949248 },
    { lat: 49.1467661, lng: 19.7955266 },
    { lat: 49.146588, lng: 19.7957308 },
    { lat: 49.1463154, lng: 19.7958143 },
    { lat: 49.1459418, lng: 19.7958482 },
    { lat: 49.1454966, lng: 19.7958656 },
    { lat: 49.1451955, lng: 19.79607 },
    { lat: 49.1448181, lng: 19.7966386 },
    { lat: 49.1445613, lng: 19.7971084 },
    { lat: 49.1444274, lng: 19.7972179 },
    { lat: 49.1443531, lng: 19.7971969 },
    { lat: 49.143429, lng: 19.7968968 },
    { lat: 49.1431326, lng: 19.7966095 },
    { lat: 49.1429994, lng: 19.7965484 },
    { lat: 49.142778, lng: 19.7965383 },
    { lat: 49.1424957, lng: 19.7965968 },
    { lat: 49.1421722, lng: 19.7967491 },
    { lat: 49.1419856, lng: 19.7967844 },
    { lat: 49.1415074, lng: 19.7966708 },
    { lat: 49.1412984, lng: 19.7965742 },
    { lat: 49.1410618, lng: 19.7963644 },
    { lat: 49.1408611, lng: 19.7962813 },
    { lat: 49.1404363, lng: 19.7962289 },
    { lat: 49.1402453, lng: 19.7961355 },
    { lat: 49.1398185, lng: 19.7957484 },
    { lat: 49.1393341, lng: 19.7952501 },
    { lat: 49.1390502, lng: 19.7949894 },
    { lat: 49.1385012, lng: 19.7946682 },
    { lat: 49.1380943, lng: 19.7943744 },
    { lat: 49.1373305, lng: 19.7933337 },
    { lat: 49.1371073, lng: 19.7927294 },
    { lat: 49.1367849, lng: 19.79218 },
    { lat: 49.136514, lng: 19.7919583 },
    { lat: 49.1360993, lng: 19.7919258 },
    { lat: 49.1356722, lng: 19.7916626 },
    { lat: 49.1354637, lng: 19.7916328 },
    { lat: 49.13517, lng: 19.7917951 },
    { lat: 49.1349266, lng: 19.7919764 },
    { lat: 49.1347076, lng: 19.7920689 },
    { lat: 49.1346927, lng: 19.7921182 },
    { lat: 49.1344078, lng: 19.7919875 },
    { lat: 49.1342105, lng: 19.7917537 },
    { lat: 49.1340422, lng: 19.79164 },
    { lat: 49.1338093, lng: 19.7914072 },
    { lat: 49.1336076, lng: 19.7913552 },
    { lat: 49.1333248, lng: 19.7911973 },
    { lat: 49.132789, lng: 19.7912579 },
    { lat: 49.1326972, lng: 19.7910898 },
    { lat: 49.1322961, lng: 19.7906193 },
    { lat: 49.1321199, lng: 19.7905827 },
    { lat: 49.1320414, lng: 19.7904877 },
    { lat: 49.1319392, lng: 19.7903904 },
    { lat: 49.1315157, lng: 19.7899246 },
    { lat: 49.1305298, lng: 19.7888754 },
    { lat: 49.1273712, lng: 19.7892141 },
    { lat: 49.1253918, lng: 19.7897579 },
    { lat: 49.1233602, lng: 19.7904264 },
    { lat: 49.1201956, lng: 19.7888551 },
    { lat: 49.1197898, lng: 19.7886408 },
    { lat: 49.1168492, lng: 19.7875395 },
    { lat: 49.1157655, lng: 19.7872025 },
    { lat: 49.1127335, lng: 19.7861976 },
    { lat: 49.1125738, lng: 19.786123 },
    { lat: 49.1085627, lng: 19.7839747 },
    { lat: 49.1081411, lng: 19.7826404 },
    { lat: 49.1071801, lng: 19.7796038 },
    { lat: 49.1060929, lng: 19.7781967 },
    { lat: 49.1050414, lng: 19.7771714 },
    { lat: 49.1041572, lng: 19.7761872 },
    { lat: 49.1013246, lng: 19.7744394 },
    { lat: 49.0991127, lng: 19.7730753 },
    { lat: 49.0986073, lng: 19.7726945 },
    { lat: 49.095407, lng: 19.7702878 },
    { lat: 49.0935552, lng: 19.7688943 },
    { lat: 49.0933902, lng: 19.7691679 },
    { lat: 49.0932593, lng: 19.7693986 },
    { lat: 49.0931729, lng: 19.769637 },
    { lat: 49.0930119, lng: 19.7701036 },
    { lat: 49.0926768, lng: 19.7707147 },
    { lat: 49.0923178, lng: 19.7713731 },
    { lat: 49.0921244, lng: 19.7716658 },
    { lat: 49.0920466, lng: 19.7717638 },
    { lat: 49.0918731, lng: 19.7720261 },
    { lat: 49.0917655, lng: 19.7722231 },
    { lat: 49.091644, lng: 19.772404 },
    { lat: 49.091383, lng: 19.7729047 },
    { lat: 49.0912292, lng: 19.7732279 },
    { lat: 49.090613, lng: 19.7744761 },
    { lat: 49.0903752, lng: 19.7749418 },
    { lat: 49.090129, lng: 19.7755719 },
    { lat: 49.0899995, lng: 19.7758854 },
    { lat: 49.0896765, lng: 19.7767745 },
    { lat: 49.0893993, lng: 19.7775015 },
    { lat: 49.088974, lng: 19.7784989 },
    { lat: 49.0888413, lng: 19.7788149 },
    { lat: 49.0887521, lng: 19.7789676 },
    { lat: 49.0886247, lng: 19.779171 },
    { lat: 49.0881362, lng: 19.7799637 },
    { lat: 49.088052, lng: 19.7801232 },
    { lat: 49.087721, lng: 19.7807739 },
    { lat: 49.0874102, lng: 19.7814022 },
    { lat: 49.0872155, lng: 19.7817686 },
    { lat: 49.0870605, lng: 19.7820699 },
    { lat: 49.0869847, lng: 19.7822429 },
    { lat: 49.086829, lng: 19.7825727 },
    { lat: 49.0864692, lng: 19.7831598 },
    { lat: 49.0862759, lng: 19.7834808 },
    { lat: 49.086085, lng: 19.7838367 },
    { lat: 49.0859678, lng: 19.7840408 },
    { lat: 49.0857827, lng: 19.7843735 },
    { lat: 49.0855356, lng: 19.7853748 },
    { lat: 49.0854516, lng: 19.7858552 },
    { lat: 49.0852396, lng: 19.7865539 },
    { lat: 49.0851313, lng: 19.7868602 },
    { lat: 49.0850898, lng: 19.7870991 },
    { lat: 49.0850405, lng: 19.7872855 },
    { lat: 49.0849163, lng: 19.7876718 },
    { lat: 49.0847725, lng: 19.788097 },
    { lat: 49.0845644, lng: 19.7886207 },
    { lat: 49.0843608, lng: 19.7893337 },
    { lat: 49.0843212, lng: 19.7895641 },
    { lat: 49.0841959, lng: 19.7903375 },
    { lat: 49.0841501, lng: 19.7906817 },
    { lat: 49.083972, lng: 19.7926306 },
    { lat: 49.0839227, lng: 19.7932102 },
    { lat: 49.0843637, lng: 19.7936152 },
    { lat: 49.0845821, lng: 19.7938172 },
    { lat: 49.0847863, lng: 19.7940364 },
    { lat: 49.0856225, lng: 19.7949549 },
    { lat: 49.0869107, lng: 19.7954629 },
    { lat: 49.0888823, lng: 19.7962507 },
    { lat: 49.092046, lng: 19.798501 },
    { lat: 49.0920335, lng: 19.7985234 },
    { lat: 49.0919559, lng: 19.7987882 },
    { lat: 49.0921554, lng: 19.7989469 },
    { lat: 49.0923217, lng: 19.7991732 },
    { lat: 49.0927222, lng: 19.7998368 },
    { lat: 49.0937471, lng: 19.8010871 },
    { lat: 49.0940055, lng: 19.801352 },
    { lat: 49.0943159, lng: 19.8016588 },
    { lat: 49.0945135, lng: 19.801877 },
    { lat: 49.0948195, lng: 19.8021264 },
    { lat: 49.0950156, lng: 19.8022599 },
    { lat: 49.0954454, lng: 19.8026917 },
    { lat: 49.0956716, lng: 19.802894 },
    { lat: 49.0958283, lng: 19.80308 },
    { lat: 49.0963059, lng: 19.80342 },
    { lat: 49.0965077, lng: 19.8036827 },
    { lat: 49.0967238, lng: 19.8040454 },
    { lat: 49.0969995, lng: 19.8047169 },
    { lat: 49.0971297, lng: 19.8048624 },
    { lat: 49.0973604, lng: 19.8050895 },
    { lat: 49.0978958, lng: 19.8054386 },
    { lat: 49.0981941, lng: 19.805798 },
    { lat: 49.09845, lng: 19.8060409 },
    { lat: 49.0986374, lng: 19.8060222 },
    { lat: 49.0988397, lng: 19.806246 },
    { lat: 49.098975, lng: 19.8064277 },
    { lat: 49.099245, lng: 19.807468 },
    { lat: 49.0993499, lng: 19.8078087 },
    { lat: 49.0995492, lng: 19.8082525 },
    { lat: 49.099968, lng: 19.8084792 },
    { lat: 49.1004542, lng: 19.8084179 },
    { lat: 49.1007998, lng: 19.8083834 },
    { lat: 49.1008454, lng: 19.8083936 },
    { lat: 49.1011194, lng: 19.8084553 },
    { lat: 49.1014867, lng: 19.8086137 },
    { lat: 49.1019894, lng: 19.8089352 },
    { lat: 49.1020743, lng: 19.809338 },
    { lat: 49.1021116, lng: 19.8096294 },
    { lat: 49.1022878, lng: 19.8103689 },
    { lat: 49.1023138, lng: 19.8107654 },
    { lat: 49.1022505, lng: 19.8112024 },
    { lat: 49.1020582, lng: 19.8117573 },
    { lat: 49.1021286, lng: 19.8122956 },
    { lat: 49.1023368, lng: 19.8128928 },
    { lat: 49.1024302, lng: 19.8130988 },
    { lat: 49.1027497, lng: 19.8135828 },
    { lat: 49.1029885, lng: 19.8141075 },
    { lat: 49.103169, lng: 19.8145339 },
    { lat: 49.1037146, lng: 19.8156654 },
    { lat: 49.1038566, lng: 19.8163756 },
    { lat: 49.1040834, lng: 19.8171964 },
    { lat: 49.1042879, lng: 19.8177321 },
    { lat: 49.1043729, lng: 19.8179549 },
    { lat: 49.1044198, lng: 19.8183636 },
    { lat: 49.1045168, lng: 19.8187598 },
    { lat: 49.104568, lng: 19.8194004 },
    { lat: 49.1047351, lng: 19.8199051 },
    { lat: 49.1050316, lng: 19.8206391 },
    { lat: 49.1052093, lng: 19.8208961 },
    { lat: 49.1053395, lng: 19.8209388 },
    { lat: 49.1057282, lng: 19.8215943 },
    { lat: 49.1059958, lng: 19.8223179 },
    { lat: 49.1061278, lng: 19.8225061 },
    { lat: 49.106555, lng: 19.8230376 },
    { lat: 49.1066353, lng: 19.8232029 },
    { lat: 49.1065544, lng: 19.8237528 },
    { lat: 49.1064158, lng: 19.8242531 },
    { lat: 49.1063581, lng: 19.8245087 },
    { lat: 49.10637, lng: 19.8248893 },
    { lat: 49.1065396, lng: 19.8252424 },
    { lat: 49.1065842, lng: 19.8252858 },
    { lat: 49.1069902, lng: 19.8252708 },
    { lat: 49.1071068, lng: 19.8255727 },
    { lat: 49.1071624, lng: 19.826448 },
    { lat: 49.1072161, lng: 19.8267537 },
    { lat: 49.1072132, lng: 19.8271308 },
    { lat: 49.1071596, lng: 19.8274324 },
    { lat: 49.1072155, lng: 19.8274484 },
    { lat: 49.107248, lng: 19.8274451 },
    { lat: 49.1073548, lng: 19.8282233 },
    { lat: 49.1076232, lng: 19.8302514 },
    { lat: 49.1083363, lng: 19.831803 },
    { lat: 49.1085029, lng: 19.8329006 },
    { lat: 49.1090002, lng: 19.8361974 },
    { lat: 49.1090625, lng: 19.8363897 },
    { lat: 49.110085, lng: 19.8397739 },
    { lat: 49.1102733, lng: 19.8415903 },
    { lat: 49.1113981, lng: 19.8441271 },
    { lat: 49.1118505, lng: 19.8451447 },
    { lat: 49.1119463, lng: 19.8453045 },
    { lat: 49.112979, lng: 19.8471684 },
    { lat: 49.1133805, lng: 19.8478916 },
    { lat: 49.1143535, lng: 19.8496476 },
    { lat: 49.1148209, lng: 19.8504926 },
    { lat: 49.1151234, lng: 19.8513241 },
    { lat: 49.1155022, lng: 19.8523556 },
    { lat: 49.1164023, lng: 19.8548101 },
    { lat: 49.1165476, lng: 19.8552042 },
    { lat: 49.1167712, lng: 19.856537 },
    { lat: 49.1184693, lng: 19.8614892 },
    { lat: 49.1189963, lng: 19.8633286 },
    { lat: 49.1199328, lng: 19.8666721 },
    { lat: 49.1209019, lng: 19.8697502 },
    { lat: 49.1214619, lng: 19.8704134 },
    { lat: 49.1221156, lng: 19.874946 },
    { lat: 49.1219337, lng: 19.8752534 },
    { lat: 49.1218587, lng: 19.8760038 },
    { lat: 49.121914, lng: 19.8766716 },
    { lat: 49.1220706, lng: 19.8772513 },
    { lat: 49.1222709, lng: 19.8777282 },
    { lat: 49.1224174, lng: 19.8779633 },
    { lat: 49.1225918, lng: 19.8782033 },
    { lat: 49.122813, lng: 19.8785303 },
    { lat: 49.123075, lng: 19.8799757 },
    { lat: 49.1230025, lng: 19.881502 },
    { lat: 49.1247509, lng: 19.8844775 },
    { lat: 49.1267966, lng: 19.8883026 },
    { lat: 49.1269252, lng: 19.8881802 },
    { lat: 49.1276559, lng: 19.8889912 },
    { lat: 49.1280003, lng: 19.8899148 },
    { lat: 49.1280965, lng: 19.8904577 },
    { lat: 49.1279314, lng: 19.8911365 },
    { lat: 49.1279253, lng: 19.8914137 },
    { lat: 49.1279919, lng: 19.8917107 },
    { lat: 49.128294, lng: 19.8922722 },
    { lat: 49.128436, lng: 19.8924874 },
    { lat: 49.1289031, lng: 19.8929324 },
    { lat: 49.1290017, lng: 19.8929993 },
    { lat: 49.1291686, lng: 19.8930548 },
    { lat: 49.1295989, lng: 19.8928718 },
    { lat: 49.1297901, lng: 19.8927602 },
    { lat: 49.1302241, lng: 19.8923121 },
    { lat: 49.1304201, lng: 19.8923932 },
    { lat: 49.1305282, lng: 19.8924991 },
    { lat: 49.1309356, lng: 19.8931852 },
    { lat: 49.13108, lng: 19.8935332 },
    { lat: 49.1312162, lng: 19.8939863 },
    { lat: 49.1311967, lng: 19.8942936 },
    { lat: 49.131288, lng: 19.894896 },
    { lat: 49.131369, lng: 19.895088 },
    { lat: 49.131465, lng: 19.895314 },
    { lat: 49.131643, lng: 19.895728 },
    { lat: 49.131726, lng: 19.895903 },
    { lat: 49.131739, lng: 19.895929 },
    { lat: 49.13178, lng: 19.895986 },
    { lat: 49.131831, lng: 19.896029 },
    { lat: 49.131889, lng: 19.896077 },
    { lat: 49.132167, lng: 19.896282 },
    { lat: 49.13229, lng: 19.896367 },
    { lat: 49.132311, lng: 19.896381 },
    { lat: 49.132593, lng: 19.896519 },
    { lat: 49.132709, lng: 19.89654 },
    { lat: 49.132791, lng: 19.896547 },
    { lat: 49.132957, lng: 19.89655 },
    { lat: 49.133135, lng: 19.896526 },
    { lat: 49.133347, lng: 19.89645 },
    { lat: 49.133649, lng: 19.896328 },
    { lat: 49.133902, lng: 19.896207 },
    { lat: 49.134146, lng: 19.896151 },
    { lat: 49.13423, lng: 19.896174 },
    { lat: 49.1342932, lng: 19.8961979 },
    { lat: 49.1343391, lng: 19.896223 },
    { lat: 49.134454, lng: 19.896341 },
    { lat: 49.134527, lng: 19.896542 },
    { lat: 49.134574, lng: 19.896858 },
    { lat: 49.134666, lng: 19.897158 },
    { lat: 49.134825, lng: 19.897447 },
    { lat: 49.134936, lng: 19.897543 },
    { lat: 49.135107, lng: 19.897654 },
    { lat: 49.135171, lng: 19.897702 },
    { lat: 49.135187, lng: 19.897718 },
    { lat: 49.135412, lng: 19.897896 },
    { lat: 49.135492, lng: 19.897961 },
    { lat: 49.136051, lng: 19.898482 },
    { lat: 49.136125, lng: 19.898567 },
    { lat: 49.136243, lng: 19.898711 },
    { lat: 49.136481, lng: 19.899154 },
    { lat: 49.136649, lng: 19.899263 },
    { lat: 49.136746, lng: 19.899326 },
    { lat: 49.1367564, lng: 19.8993317 },
    { lat: 49.1367689, lng: 19.8993377 },
    { lat: 49.136792, lng: 19.899347 },
    { lat: 49.137455, lng: 19.899558 },
    { lat: 49.13746, lng: 19.899559 },
    { lat: 49.137728, lng: 19.899645 },
    { lat: 49.137756, lng: 19.899636 },
    { lat: 49.137998, lng: 19.89956 },
    { lat: 49.138246, lng: 19.89961 },
    { lat: 49.138278, lng: 19.899631 },
    { lat: 49.138373, lng: 19.899693 },
    { lat: 49.138706, lng: 19.899914 },
    { lat: 49.139065, lng: 19.900264 },
    { lat: 49.139623, lng: 19.900938 },
    { lat: 49.139836, lng: 19.901102 },
    { lat: 49.139898, lng: 19.90115 },
    { lat: 49.139971, lng: 19.901206 },
    { lat: 49.140046, lng: 19.901412 },
    { lat: 49.140279, lng: 19.902051 },
    { lat: 49.140331, lng: 19.902192 },
    { lat: 49.140341, lng: 19.902215 },
    { lat: 49.14054, lng: 19.902664 },
    { lat: 49.140584, lng: 19.902681 },
    { lat: 49.140647, lng: 19.902715 },
    { lat: 49.140848, lng: 19.902823 },
    { lat: 49.140926, lng: 19.902885 },
    { lat: 49.141642, lng: 19.903613 },
    { lat: 49.141769, lng: 19.903677 },
    { lat: 49.141953, lng: 19.903763 },
    { lat: 49.142171, lng: 19.903792 },
    { lat: 49.142363, lng: 19.903921 },
    { lat: 49.142424, lng: 19.904006 },
    { lat: 49.142451, lng: 19.904043 },
    { lat: 49.142578, lng: 19.904222 },
    { lat: 49.142664, lng: 19.90443 },
    { lat: 49.142771, lng: 19.904689 },
    { lat: 49.142942, lng: 19.905169 },
    { lat: 49.143021, lng: 19.905412 },
    { lat: 49.143023, lng: 19.905434 },
    { lat: 49.143083, lng: 19.906029 },
    { lat: 49.143181, lng: 19.906377 },
    { lat: 49.143327, lng: 19.90657 },
    { lat: 49.143416, lng: 19.906647 },
    { lat: 49.143755, lng: 19.906699 },
    { lat: 49.143869, lng: 19.906716 },
    { lat: 49.144252, lng: 19.90695 },
    { lat: 49.144957, lng: 19.907977 },
    { lat: 49.145291, lng: 19.908329 },
    { lat: 49.14604, lng: 19.908856 },
    { lat: 49.146083, lng: 19.908946 },
    { lat: 49.146173, lng: 19.90922 },
    { lat: 49.146458, lng: 19.910574 },
    { lat: 49.146565, lng: 19.911019 },
    { lat: 49.14661, lng: 19.911182 },
    { lat: 49.146687, lng: 19.911274 },
    { lat: 49.146874, lng: 19.911426 },
    { lat: 49.147447, lng: 19.911584 },
    { lat: 49.148022, lng: 19.911756 },
    { lat: 49.148362, lng: 19.911909 },
    { lat: 49.14869, lng: 19.911987 },
    { lat: 49.148926, lng: 19.912016 },
    { lat: 49.149132, lng: 19.912202 },
    { lat: 49.149289, lng: 19.912409 },
    { lat: 49.149473, lng: 19.912833 },
    { lat: 49.149541, lng: 19.913161 },
    { lat: 49.149524, lng: 19.913672 },
    { lat: 49.149513, lng: 19.914068 },
    { lat: 49.149577, lng: 19.914341 },
    { lat: 49.149611, lng: 19.914479 },
    { lat: 49.14963, lng: 19.914686 },
    { lat: 49.149605, lng: 19.91496 },
    { lat: 49.149527, lng: 19.915828 },
    { lat: 49.149481, lng: 19.916096 },
    { lat: 49.149475, lng: 19.916429 },
    { lat: 49.149501, lng: 19.916836 },
    { lat: 49.149581, lng: 19.917232 },
    { lat: 49.149658, lng: 19.917708 },
    { lat: 49.149903, lng: 19.918175 },
    { lat: 49.150073, lng: 19.918349 },
    { lat: 49.150189, lng: 19.918469 },
    { lat: 49.150622, lng: 19.918847 },
    { lat: 49.15074, lng: 19.918924 },
    { lat: 49.150755, lng: 19.918934 },
    { lat: 49.150794, lng: 19.919006 },
    { lat: 49.151412, lng: 19.920127 },
    { lat: 49.151572, lng: 19.920166 },
    { lat: 49.151744, lng: 19.920174 },
    { lat: 49.151778, lng: 19.920154 },
    { lat: 49.15207, lng: 19.919983 },
    { lat: 49.152572, lng: 19.919846 },
    { lat: 49.152782, lng: 19.919853 },
    { lat: 49.152929, lng: 19.919921 },
    { lat: 49.152956, lng: 19.919935 },
    { lat: 49.153044, lng: 19.920003 },
    { lat: 49.15349, lng: 19.920462 },
    { lat: 49.154015, lng: 19.921383 },
    { lat: 49.154329, lng: 19.921712 },
    { lat: 49.154949, lng: 19.922603 },
    { lat: 49.155328, lng: 19.922901 },
    { lat: 49.155392, lng: 19.922906 },
    { lat: 49.155739, lng: 19.923017 },
    { lat: 49.155793, lng: 19.923056 },
    { lat: 49.156133, lng: 19.923083 },
    { lat: 49.156724, lng: 19.923091 },
    { lat: 49.157141, lng: 19.923186 },
    { lat: 49.157368, lng: 19.923135 },
    { lat: 49.157637, lng: 19.92286 },
    { lat: 49.158103, lng: 19.922739 },
    { lat: 49.15834, lng: 19.922729 },
    { lat: 49.158402, lng: 19.922734 },
    { lat: 49.158884, lng: 19.92274 },
    { lat: 49.158888, lng: 19.92274 },
    { lat: 49.159257, lng: 19.922859 },
    { lat: 49.159414, lng: 19.922909 },
    { lat: 49.160025, lng: 19.923211 },
    { lat: 49.160289, lng: 19.922931 },
    { lat: 49.160585, lng: 19.92262 },
    { lat: 49.160687, lng: 19.922549 },
    { lat: 49.161226, lng: 19.922177 },
    { lat: 49.161565, lng: 19.921993 },
    { lat: 49.161806, lng: 19.92194 },
    { lat: 49.162158, lng: 19.921943 },
    { lat: 49.162322, lng: 19.92196 },
    { lat: 49.162325, lng: 19.92196 },
    { lat: 49.162365, lng: 19.921982 },
    { lat: 49.162385, lng: 19.921993 },
    { lat: 49.162387, lng: 19.921993 },
    { lat: 49.162633, lng: 19.922046 },
    { lat: 49.162974, lng: 19.922043 },
    { lat: 49.16335, lng: 19.921948 },
    { lat: 49.163602, lng: 19.921791 },
    { lat: 49.163803, lng: 19.92175 },
    { lat: 49.164078, lng: 19.921764 },
    { lat: 49.164443, lng: 19.921753 },
    { lat: 49.164667, lng: 19.921731 },
    { lat: 49.164868, lng: 19.921711 },
    { lat: 49.165112, lng: 19.921591 },
    { lat: 49.165422, lng: 19.921374 },
    { lat: 49.165526, lng: 19.921265 },
    { lat: 49.165707, lng: 19.921076 },
    { lat: 49.165995, lng: 19.920963 },
    { lat: 49.166117, lng: 19.920946 },
    { lat: 49.166551, lng: 19.921063 },
    { lat: 49.1674, lng: 19.921367 },
    { lat: 49.167693, lng: 19.921467 },
    { lat: 49.167938, lng: 19.92149 },
    { lat: 49.168446, lng: 19.921465 },
    { lat: 49.168681, lng: 19.921453 },
    { lat: 49.168809, lng: 19.921375 },
    { lat: 49.16937, lng: 19.921034 },
    { lat: 49.170189, lng: 19.920536 },
    { lat: 49.1703803, lng: 19.9205376 },
    { lat: 49.170555, lng: 19.920539 },
    { lat: 49.170872, lng: 19.92062 },
    { lat: 49.171568, lng: 19.92089 },
    { lat: 49.171859, lng: 19.921032 },
    { lat: 49.171952, lng: 19.921078 },
    { lat: 49.172144, lng: 19.921136 },
    { lat: 49.172308, lng: 19.920999 },
    { lat: 49.172469, lng: 19.920725 },
    { lat: 49.172581, lng: 19.920299 },
    { lat: 49.172605, lng: 19.919934 },
    { lat: 49.17267, lng: 19.919181 },
    { lat: 49.172804, lng: 19.918567 },
    { lat: 49.172903, lng: 19.918318 },
    { lat: 49.173046, lng: 19.918074 },
    { lat: 49.173395, lng: 19.917515 },
    { lat: 49.173949, lng: 19.916743 },
    { lat: 49.174341, lng: 19.916391 },
    { lat: 49.174944, lng: 19.915935 },
    { lat: 49.175308, lng: 19.915585 },
    { lat: 49.175546, lng: 19.915439 },
    { lat: 49.175895, lng: 19.915305 },
    { lat: 49.176165, lng: 19.915162 },
    { lat: 49.176372, lng: 19.91497 },
    { lat: 49.176549, lng: 19.914806 },
    { lat: 49.176745, lng: 19.914767 },
    { lat: 49.177027, lng: 19.914836 },
    { lat: 49.177046, lng: 19.914841 },
    { lat: 49.177085, lng: 19.914844 },
    { lat: 49.177481, lng: 19.914879 },
    { lat: 49.177619, lng: 19.914849 },
    { lat: 49.177986, lng: 19.914695 },
    { lat: 49.178108, lng: 19.914724 },
    { lat: 49.178581, lng: 19.91481 },
    { lat: 49.179213, lng: 19.914949 },
    { lat: 49.179571, lng: 19.914983 },
    { lat: 49.179816, lng: 19.915106 },
    { lat: 49.180232, lng: 19.915275 },
    { lat: 49.180779, lng: 19.915447 },
    { lat: 49.180909, lng: 19.915504 },
    { lat: 49.181536, lng: 19.915276 },
    { lat: 49.181891, lng: 19.915253 },
    { lat: 49.18196, lng: 19.915021 },
    { lat: 49.181993, lng: 19.914896 },
    { lat: 49.18199, lng: 19.914775 },
    { lat: 49.181981, lng: 19.914755 },
    { lat: 49.181949, lng: 19.914686 },
    { lat: 49.181753, lng: 19.914481 },
    { lat: 49.181748, lng: 19.914301 },
    { lat: 49.181824, lng: 19.91411 },
    { lat: 49.181891, lng: 19.913843 },
    { lat: 49.181956, lng: 19.91367 },
    { lat: 49.182123, lng: 19.913455 },
    { lat: 49.182208, lng: 19.913293 },
    { lat: 49.182243, lng: 19.913016 },
    { lat: 49.182428, lng: 19.912733 },
    { lat: 49.182486, lng: 19.912619 },
    { lat: 49.182514, lng: 19.912273 },
    { lat: 49.182561, lng: 19.91216 },
    { lat: 49.182791, lng: 19.911924 },
    { lat: 49.18295, lng: 19.911702 },
    { lat: 49.183065, lng: 19.911436 },
    { lat: 49.183128, lng: 19.911038 },
    { lat: 49.183161, lng: 19.910521 },
    { lat: 49.18321, lng: 19.910238 },
    { lat: 49.1832455, lng: 19.9101744 },
    { lat: 49.183339, lng: 19.910007 },
    { lat: 49.1834249, lng: 19.9098071 },
    { lat: 49.183434, lng: 19.909786 },
    { lat: 49.183454, lng: 19.909528 },
    { lat: 49.183436, lng: 19.909288 },
    { lat: 49.1834165, lng: 19.9092025 },
    { lat: 49.183397, lng: 19.909117 },
    { lat: 49.183372, lng: 19.909005 },
    { lat: 49.183236, lng: 19.90871 },
    { lat: 49.182986, lng: 19.908388 },
    { lat: 49.182934, lng: 19.908311 },
    { lat: 49.182837, lng: 19.908163 },
    { lat: 49.182764, lng: 19.908022 },
    { lat: 49.182673, lng: 19.907711 },
    { lat: 49.182565, lng: 19.907324 },
    { lat: 49.182427, lng: 19.907021 },
    { lat: 49.182266, lng: 19.906697 },
    { lat: 49.182119, lng: 19.906365 },
    { lat: 49.181951, lng: 19.905905 },
    { lat: 49.18176, lng: 19.905269 },
    { lat: 49.181677, lng: 19.905022 },
    { lat: 49.181434, lng: 19.904679 },
    { lat: 49.181363, lng: 19.904477 },
    { lat: 49.181345, lng: 19.904332 },
    { lat: 49.181373, lng: 19.904092 },
    { lat: 49.18147, lng: 19.903585 },
    { lat: 49.181544, lng: 19.903273 },
    { lat: 49.181567, lng: 19.903037 },
    { lat: 49.181562, lng: 19.902839 },
    { lat: 49.181558, lng: 19.902696 },
    { lat: 49.18166, lng: 19.902221 },
    { lat: 49.181879, lng: 19.901652 },
    { lat: 49.181926, lng: 19.901501 },
    { lat: 49.181991, lng: 19.901284 },
    { lat: 49.182094, lng: 19.900926 },
    { lat: 49.182209, lng: 19.900772 },
    { lat: 49.182377, lng: 19.900564 },
    { lat: 49.182559, lng: 19.900154 },
    { lat: 49.183135, lng: 19.89982 },
    { lat: 49.183179, lng: 19.899795 },
    { lat: 49.183476, lng: 19.89968 },
    { lat: 49.18403, lng: 19.899288 },
    { lat: 49.184323, lng: 19.899052 },
    { lat: 49.184381, lng: 19.899005 },
    { lat: 49.184589, lng: 19.898746 },
    { lat: 49.184921, lng: 19.898314 },
    { lat: 49.185003, lng: 19.898203 },
    { lat: 49.185227, lng: 19.89789 },
    { lat: 49.185451, lng: 19.897623 },
    { lat: 49.185525, lng: 19.897587 },
    { lat: 49.185732, lng: 19.897587 },
    { lat: 49.185815, lng: 19.897551 },
    { lat: 49.186005, lng: 19.897469 },
    { lat: 49.186021, lng: 19.897463 },
    { lat: 49.186164, lng: 19.897325 },
    { lat: 49.186378, lng: 19.897125 },
    { lat: 49.186395, lng: 19.897108 },
    { lat: 49.186419, lng: 19.897122 },
    { lat: 49.186673, lng: 19.897281 },
    { lat: 49.186846, lng: 19.897462 },
    { lat: 49.186892, lng: 19.89751 },
    { lat: 49.187094, lng: 19.897548 },
    { lat: 49.187388, lng: 19.897499 },
    { lat: 49.187901, lng: 19.897497 },
    { lat: 49.188209, lng: 19.897497 },
    { lat: 49.188576, lng: 19.89765 },
    { lat: 49.188766, lng: 19.897734 },
    { lat: 49.189005, lng: 19.898056 },
    { lat: 49.18953, lng: 19.898513 },
    { lat: 49.189757, lng: 19.898694 },
    { lat: 49.19008, lng: 19.89891 },
    { lat: 49.190414, lng: 19.899054 },
    { lat: 49.190526, lng: 19.899058 },
    { lat: 49.190845, lng: 19.899072 },
    { lat: 49.191267, lng: 19.899232 },
    { lat: 49.19143, lng: 19.89918 },
    { lat: 49.191518, lng: 19.89918 },
    { lat: 49.192075, lng: 19.899506 },
    { lat: 49.192136, lng: 19.899574 },
    { lat: 49.192194, lng: 19.899664 },
    { lat: 49.192419, lng: 19.899846 },
    { lat: 49.192492, lng: 19.899905 },
    { lat: 49.192729, lng: 19.899945 },
    { lat: 49.192821, lng: 19.899962 },
    { lat: 49.193168, lng: 19.900361 },
    { lat: 49.193398, lng: 19.900604 },
    { lat: 49.193458, lng: 19.900642 },
    { lat: 49.193741, lng: 19.900818 },
    { lat: 49.193842, lng: 19.900882 },
    { lat: 49.193984, lng: 19.900956 },
    { lat: 49.194487, lng: 19.901404 },
    { lat: 49.19459, lng: 19.901385 },
    { lat: 49.194841, lng: 19.901593 },
    { lat: 49.195246, lng: 19.901697 },
    { lat: 49.195596, lng: 19.901752 },
    { lat: 49.195844, lng: 19.901821 },
    { lat: 49.195944, lng: 19.901736 },
    { lat: 49.195957, lng: 19.901725 },
    { lat: 49.196189, lng: 19.901702 },
    { lat: 49.196255, lng: 19.901774 },
    { lat: 49.196419, lng: 19.901761 },
    { lat: 49.196503, lng: 19.901826 },
    { lat: 49.196543, lng: 19.901857 },
    { lat: 49.196644, lng: 19.901804 },
    { lat: 49.196755, lng: 19.901744 },
    { lat: 49.196869, lng: 19.901713 },
    { lat: 49.197037, lng: 19.901703 },
    { lat: 49.197075, lng: 19.9017 },
    { lat: 49.197229, lng: 19.90138 },
    { lat: 49.197402, lng: 19.901355 },
    { lat: 49.197505, lng: 19.901436 },
    { lat: 49.197645, lng: 19.901546 },
    { lat: 49.197714, lng: 19.901504 },
    { lat: 49.197858, lng: 19.901417 },
    { lat: 49.19793, lng: 19.901398 },
    { lat: 49.198015, lng: 19.901375 },
    { lat: 49.198114, lng: 19.901315 },
    { lat: 49.198205, lng: 19.901189 },
    { lat: 49.198274, lng: 19.901094 },
    { lat: 49.198337, lng: 19.90106 },
    { lat: 49.198398, lng: 19.901077 },
    { lat: 49.198498, lng: 19.901107 },
    { lat: 49.198548, lng: 19.901123 },
    { lat: 49.198589, lng: 19.901101 },
    { lat: 49.198888, lng: 19.900744 },
    { lat: 49.198959, lng: 19.900753 },
    { lat: 49.199017, lng: 19.90076 },
    { lat: 49.199049, lng: 19.900764 },
    { lat: 49.199107, lng: 19.900744 },
    { lat: 49.199316, lng: 19.900461 },
    { lat: 49.199346, lng: 19.900415 },
    { lat: 49.199475, lng: 19.900527 },
    { lat: 49.199622, lng: 19.900446 },
    { lat: 49.199729, lng: 19.900368 },
    { lat: 49.199837, lng: 19.900361 },
    { lat: 49.200092, lng: 19.900367 },
    { lat: 49.200131, lng: 19.900328 },
    { lat: 49.200204, lng: 19.900347 },
    { lat: 49.200219, lng: 19.900351 },
    { lat: 49.200314, lng: 19.897474 },
    { lat: 49.200345, lng: 19.896569 },
    { lat: 49.200403, lng: 19.894814 },
    { lat: 49.200422, lng: 19.894287 },
    { lat: 49.20048, lng: 19.892519 },
    { lat: 49.200524, lng: 19.891214 },
    { lat: 49.200543, lng: 19.890635 },
    { lat: 49.200547, lng: 19.890534 },
    { lat: 49.200569, lng: 19.889858 },
    { lat: 49.200612, lng: 19.888584 },
    { lat: 49.200947, lng: 19.88826 },
    { lat: 49.201227, lng: 19.887694 },
    { lat: 49.201303, lng: 19.887542 },
    { lat: 49.204178, lng: 19.883958 },
    { lat: 49.204178, lng: 19.883928 },
  ],
  Prosiek: [
    { lat: 49.174455, lng: 19.470202 },
    { lat: 49.173773, lng: 19.4707501 },
    { lat: 49.1730512, lng: 19.4718394 },
    { lat: 49.1728758, lng: 19.4720198 },
    { lat: 49.1717213, lng: 19.4722287 },
    { lat: 49.1709579, lng: 19.4716286 },
    { lat: 49.1683043, lng: 19.4719957 },
    { lat: 49.1659513, lng: 19.4721382 },
    { lat: 49.1643285, lng: 19.4733834 },
    { lat: 49.162659, lng: 19.4782466 },
    { lat: 49.1622437, lng: 19.4785034 },
    { lat: 49.1621929, lng: 19.4785243 },
    { lat: 49.1616658, lng: 19.4787739 },
    { lat: 49.1599959, lng: 19.4793645 },
    { lat: 49.1593391, lng: 19.4796856 },
    { lat: 49.1588119, lng: 19.479887 },
    { lat: 49.157221, lng: 19.4811646 },
    { lat: 49.1558555, lng: 19.4824448 },
    { lat: 49.1557818, lng: 19.4825106 },
    { lat: 49.1545488, lng: 19.4831635 },
    { lat: 49.1535659, lng: 19.4837007 },
    { lat: 49.1531009, lng: 19.4839515 },
    { lat: 49.1529833, lng: 19.4843366 },
    { lat: 49.1528128, lng: 19.4848444 },
    { lat: 49.1514185, lng: 19.4862602 },
    { lat: 49.150561, lng: 19.4864773 },
    { lat: 49.1505123, lng: 19.4864771 },
    { lat: 49.1496477, lng: 19.4868126 },
    { lat: 49.1492373, lng: 19.4868982 },
    { lat: 49.1485118, lng: 19.4870309 },
    { lat: 49.1481579, lng: 19.4872022 },
    { lat: 49.147811, lng: 19.4874011 },
    { lat: 49.1471308, lng: 19.4882939 },
    { lat: 49.1459772, lng: 19.4889377 },
    { lat: 49.1459246, lng: 19.4926578 },
    { lat: 49.1452374, lng: 19.492831 },
    { lat: 49.1446326, lng: 19.4928133 },
    { lat: 49.1442541, lng: 19.49299 },
    { lat: 49.1439104, lng: 19.4929406 },
    { lat: 49.1433807, lng: 19.4932788 },
    { lat: 49.143045, lng: 19.4935273 },
    { lat: 49.142621, lng: 19.4935433 },
    { lat: 49.1420168, lng: 19.4934161 },
    { lat: 49.1417305, lng: 19.49351 },
    { lat: 49.1416329, lng: 19.4936481 },
    { lat: 49.1411679, lng: 19.4935781 },
    { lat: 49.1410243, lng: 19.4940361 },
    { lat: 49.140715, lng: 19.4944181 },
    { lat: 49.1404684, lng: 19.494467 },
    { lat: 49.1403689, lng: 19.4947331 },
    { lat: 49.1401252, lng: 19.4947019 },
    { lat: 49.1399483, lng: 19.4949496 },
    { lat: 49.1396864, lng: 19.4949368 },
    { lat: 49.1394432, lng: 19.4946694 },
    { lat: 49.139381, lng: 19.4947397 },
    { lat: 49.1392365, lng: 19.4947604 },
    { lat: 49.1391932, lng: 19.4948187 },
    { lat: 49.1391212, lng: 19.4948713 },
    { lat: 49.1390406, lng: 19.4946902 },
    { lat: 49.1390148, lng: 19.4947135 },
    { lat: 49.1389977, lng: 19.4947589 },
    { lat: 49.1389701, lng: 19.4947946 },
    { lat: 49.1389415, lng: 19.4948098 },
    { lat: 49.1389264, lng: 19.4948503 },
    { lat: 49.1388289, lng: 19.4953559 },
    { lat: 49.1382851, lng: 19.4957578 },
    { lat: 49.1381721, lng: 19.4957752 },
    { lat: 49.1378367, lng: 19.4961747 },
    { lat: 49.1374766, lng: 19.4965311 },
    { lat: 49.1370498, lng: 19.4968317 },
    { lat: 49.1366782, lng: 19.4973279 },
    { lat: 49.1363602, lng: 19.4978644 },
    { lat: 49.1360063, lng: 19.4981864 },
    { lat: 49.1355897, lng: 19.4983375 },
    { lat: 49.1353131, lng: 19.4985735 },
    { lat: 49.1349966, lng: 19.4985769 },
    { lat: 49.1348996, lng: 19.4984268 },
    { lat: 49.1343434, lng: 19.498388 },
    { lat: 49.1341861, lng: 19.4987642 },
    { lat: 49.1331314, lng: 19.4993575 },
    { lat: 49.1331149, lng: 19.4987497 },
    { lat: 49.133092, lng: 19.4986424 },
    { lat: 49.132748, lng: 19.4957082 },
    { lat: 49.12992, lng: 19.4904284 },
    { lat: 49.1297854, lng: 19.490541 },
    { lat: 49.1294673, lng: 19.490849 },
    { lat: 49.1290303, lng: 19.4912716 },
    { lat: 49.1286606, lng: 19.4914908 },
    { lat: 49.1284111, lng: 19.4916311 },
    { lat: 49.1274862, lng: 19.4921509 },
    { lat: 49.1275819, lng: 19.4925185 },
    { lat: 49.1282613, lng: 19.4949968 },
    { lat: 49.1285474, lng: 19.4960533 },
    { lat: 49.1290613, lng: 19.4980491 },
    { lat: 49.1293911, lng: 19.4993162 },
    { lat: 49.1291835, lng: 19.4994149 },
    { lat: 49.1291516, lng: 19.4991607 },
    { lat: 49.128674, lng: 19.4992866 },
    { lat: 49.128722, lng: 19.4996949 },
    { lat: 49.1282566, lng: 19.4999164 },
    { lat: 49.1279128, lng: 19.5000612 },
    { lat: 49.1274117, lng: 19.5003369 },
    { lat: 49.1276829, lng: 19.5016828 },
    { lat: 49.1276649, lng: 19.5017592 },
    { lat: 49.1277993, lng: 19.5016143 },
    { lat: 49.1280925, lng: 19.5015472 },
    { lat: 49.1284317, lng: 19.5013744 },
    { lat: 49.1293675, lng: 19.5012156 },
    { lat: 49.129625, lng: 19.5013526 },
    { lat: 49.1298234, lng: 19.5015503 },
    { lat: 49.1298702, lng: 19.5016133 },
    { lat: 49.1299541, lng: 19.5015742 },
    { lat: 49.1299912, lng: 19.5016253 },
    { lat: 49.1300553, lng: 19.5015707 },
    { lat: 49.1301891, lng: 19.5014913 },
    { lat: 49.1303092, lng: 19.5014077 },
    { lat: 49.1303896, lng: 19.5014167 },
    { lat: 49.1308578, lng: 19.5015285 },
    { lat: 49.1311163, lng: 19.5016371 },
    { lat: 49.1312155, lng: 19.5017608 },
    { lat: 49.1312818, lng: 19.5018066 },
    { lat: 49.1314832, lng: 19.5019712 },
    { lat: 49.1315579, lng: 19.5021029 },
    { lat: 49.1316848, lng: 19.5021664 },
    { lat: 49.1319486, lng: 19.5021934 },
    { lat: 49.1320163, lng: 19.5023208 },
    { lat: 49.1321825, lng: 19.5022589 },
    { lat: 49.13234, lng: 19.5024743 },
    { lat: 49.1325282, lng: 19.5024244 },
    { lat: 49.1327152, lng: 19.5025945 },
    { lat: 49.1328514, lng: 19.5024224 },
    { lat: 49.1331285, lng: 19.5024405 },
    { lat: 49.1334299, lng: 19.5026042 },
    { lat: 49.1335719, lng: 19.5027039 },
    { lat: 49.1337479, lng: 19.5029018 },
    { lat: 49.1338536, lng: 19.5029255 },
    { lat: 49.1341371, lng: 19.5027173 },
    { lat: 49.1341521, lng: 19.5027718 },
    { lat: 49.1342681, lng: 19.503337 },
    { lat: 49.1350003, lng: 19.5049832 },
    { lat: 49.1356512, lng: 19.5062837 },
    { lat: 49.1360252, lng: 19.5070227 },
    { lat: 49.1360768, lng: 19.5071392 },
    { lat: 49.1360792, lng: 19.5071656 },
    { lat: 49.1364497, lng: 19.507037 },
    { lat: 49.1370259, lng: 19.5069258 },
    { lat: 49.1379829, lng: 19.5068587 },
    { lat: 49.1383809, lng: 19.5068399 },
    { lat: 49.1386305, lng: 19.5069026 },
    { lat: 49.1393029, lng: 19.5070929 },
    { lat: 49.1396769, lng: 19.5071402 },
    { lat: 49.1406523, lng: 19.5072803 },
    { lat: 49.14182, lng: 19.5074835 },
    { lat: 49.1426774, lng: 19.5073104 },
    { lat: 49.1429829, lng: 19.5072412 },
    { lat: 49.1431924, lng: 19.5073352 },
    { lat: 49.1436882, lng: 19.5076079 },
    { lat: 49.1442541, lng: 19.5079326 },
    { lat: 49.1444605, lng: 19.5080363 },
    { lat: 49.145376, lng: 19.5084214 },
    { lat: 49.1461993, lng: 19.5086593 },
    { lat: 49.1467974, lng: 19.5088503 },
    { lat: 49.1473841, lng: 19.5092117 },
    { lat: 49.1483106, lng: 19.5099869 },
    { lat: 49.1491672, lng: 19.5106321 },
    { lat: 49.149885, lng: 19.5120137 },
    { lat: 49.1499195, lng: 19.5120824 },
    { lat: 49.1506632, lng: 19.5130675 },
    { lat: 49.1516156, lng: 19.5141374 },
    { lat: 49.1518829, lng: 19.5145885 },
    { lat: 49.1522199, lng: 19.5152051 },
    { lat: 49.1524335, lng: 19.5159121 },
    { lat: 49.1528123, lng: 19.5168571 },
    { lat: 49.1533444, lng: 19.517678 },
    { lat: 49.1541795, lng: 19.5182479 },
    { lat: 49.1547898, lng: 19.5184388 },
    { lat: 49.1551386, lng: 19.5178859 },
    { lat: 49.1551528, lng: 19.5171821 },
    { lat: 49.1552574, lng: 19.5158923 },
    { lat: 49.1553132, lng: 19.5152618 },
    { lat: 49.1557064, lng: 19.5148403 },
    { lat: 49.1557245, lng: 19.5148175 },
    { lat: 49.1561146, lng: 19.5152438 },
    { lat: 49.1561315, lng: 19.51522 },
    { lat: 49.1564917, lng: 19.5149651 },
    { lat: 49.1569022, lng: 19.5158104 },
    { lat: 49.1604728, lng: 19.5135892 },
    { lat: 49.1609898, lng: 19.5130907 },
    { lat: 49.1623093, lng: 19.5124244 },
    { lat: 49.1632908, lng: 19.5124502 },
    { lat: 49.1642745, lng: 19.5129687 },
    { lat: 49.1666066, lng: 19.5131612 },
    { lat: 49.1684677, lng: 19.5132316 },
    { lat: 49.1692117, lng: 19.5142754 },
    { lat: 49.1703252, lng: 19.5145502 },
    { lat: 49.1712061, lng: 19.5148193 },
    { lat: 49.1720892, lng: 19.5146911 },
    { lat: 49.1728289, lng: 19.514556 },
    { lat: 49.173309, lng: 19.5144587 },
    { lat: 49.1742273, lng: 19.514233 },
    { lat: 49.174411, lng: 19.5141134 },
    { lat: 49.1749963, lng: 19.5134484 },
    { lat: 49.1747166, lng: 19.5121179 },
    { lat: 49.1742744, lng: 19.5107992 },
    { lat: 49.1735534, lng: 19.509244 },
    { lat: 49.1731197, lng: 19.5058167 },
    { lat: 49.1734973, lng: 19.5020461 },
    { lat: 49.1736119, lng: 19.5009074 },
    { lat: 49.1739225, lng: 19.5000374 },
    { lat: 49.1754472, lng: 19.4967859 },
    { lat: 49.1767875, lng: 19.4956171 },
    { lat: 49.180444, lng: 19.4943391 },
    { lat: 49.1806801, lng: 19.494256 },
    { lat: 49.1807226, lng: 19.4942986 },
    { lat: 49.1811541, lng: 19.4940527 },
    { lat: 49.1817239, lng: 19.4936718 },
    { lat: 49.1822522, lng: 19.4930046 },
    { lat: 49.1822828, lng: 19.4930115 },
    { lat: 49.1823244, lng: 19.4930078 },
    { lat: 49.1827271, lng: 19.4930412 },
    { lat: 49.1828567, lng: 19.4930663 },
    { lat: 49.1832336, lng: 19.493275 },
    { lat: 49.1836081, lng: 19.4935334 },
    { lat: 49.1838186, lng: 19.4933298 },
    { lat: 49.1839276, lng: 19.4936949 },
    { lat: 49.1840903, lng: 19.4939017 },
    { lat: 49.1843367, lng: 19.4940627 },
    { lat: 49.1843709, lng: 19.4943415 },
    { lat: 49.1846439, lng: 19.4943726 },
    { lat: 49.1846754, lng: 19.4945461 },
    { lat: 49.1847352, lng: 19.4947684 },
    { lat: 49.1846588, lng: 19.4950701 },
    { lat: 49.1845585, lng: 19.4956345 },
    { lat: 49.1847704, lng: 19.4957852 },
    { lat: 49.1849174, lng: 19.4966625 },
    { lat: 49.1850966, lng: 19.4968321 },
    { lat: 49.1851873, lng: 19.4967706 },
    { lat: 49.1853416, lng: 19.4969437 },
    { lat: 49.1854689, lng: 19.4967939 },
    { lat: 49.1856769, lng: 19.4972732 },
    { lat: 49.185886, lng: 19.497711 },
    { lat: 49.1859605, lng: 19.4978572 },
    { lat: 49.186067, lng: 19.4978477 },
    { lat: 49.1862442, lng: 19.4982214 },
    { lat: 49.1862659, lng: 19.4985988 },
    { lat: 49.1864682, lng: 19.4991302 },
    { lat: 49.1866199, lng: 19.4991951 },
    { lat: 49.1866821, lng: 19.4994504 },
    { lat: 49.1866805, lng: 19.4995579 },
    { lat: 49.1866626, lng: 19.4997089 },
    { lat: 49.1867214, lng: 19.4997401 },
    { lat: 49.1867866, lng: 19.4996374 },
    { lat: 49.1869066, lng: 19.499898 },
    { lat: 49.1868357, lng: 19.5000713 },
    { lat: 49.1869934, lng: 19.5003661 },
    { lat: 49.1869915, lng: 19.5005453 },
    { lat: 49.1870945, lng: 19.500655 },
    { lat: 49.1870571, lng: 19.5008251 },
    { lat: 49.1871431, lng: 19.5010281 },
    { lat: 49.1872549, lng: 19.5013292 },
    { lat: 49.1873453, lng: 19.5013344 },
    { lat: 49.187376, lng: 19.5013646 },
    { lat: 49.1875213, lng: 19.5015635 },
    { lat: 49.1876481, lng: 19.5015741 },
    { lat: 49.187801, lng: 19.5018144 },
    { lat: 49.1879729, lng: 19.501831 },
    { lat: 49.1880718, lng: 19.5016735 },
    { lat: 49.1883128, lng: 19.5016216 },
    { lat: 49.1891545, lng: 19.5011822 },
    { lat: 49.1898627, lng: 19.5006717 },
    { lat: 49.1901482, lng: 19.5003625 },
    { lat: 49.1908173, lng: 19.4995722 },
    { lat: 49.19096, lng: 19.4992904 },
    { lat: 49.191141, lng: 19.4990093 },
    { lat: 49.1912117, lng: 19.4989535 },
    { lat: 49.1913718, lng: 19.4989335 },
    { lat: 49.1915375, lng: 19.4989577 },
    { lat: 49.1917779, lng: 19.4988204 },
    { lat: 49.1919034, lng: 19.4990243 },
    { lat: 49.1920882, lng: 19.49893 },
    { lat: 49.1921403, lng: 19.4988099 },
    { lat: 49.1923088, lng: 19.4987499 },
    { lat: 49.1924018, lng: 19.4987864 },
    { lat: 49.1925178, lng: 19.498787 },
    { lat: 49.1925984, lng: 19.498727 },
    { lat: 49.192619, lng: 19.4987799 },
    { lat: 49.1926435, lng: 19.4989758 },
    { lat: 49.192732, lng: 19.4990366 },
    { lat: 49.1928859, lng: 19.4989777 },
    { lat: 49.1930886, lng: 19.4988261 },
    { lat: 49.1931917, lng: 19.4989117 },
    { lat: 49.1932895, lng: 19.4990785 },
    { lat: 49.1936149, lng: 19.4991473 },
    { lat: 49.1937883, lng: 19.4992199 },
    { lat: 49.194166, lng: 19.4991783 },
    { lat: 49.1942996, lng: 19.4988939 },
    { lat: 49.1934368, lng: 19.4967794 },
    { lat: 49.1945667, lng: 19.4952226 },
    { lat: 49.195265, lng: 19.4935687 },
    { lat: 49.1952801, lng: 19.4935275 },
    { lat: 49.1967181, lng: 19.4908975 },
    { lat: 49.197353, lng: 19.491534 },
    { lat: 49.198136, lng: 19.489421 },
    { lat: 49.19883, lng: 19.486806 },
    { lat: 49.198883, lng: 19.48643 },
    { lat: 49.196433, lng: 19.483486 },
    { lat: 49.19616, lng: 19.483622 },
    { lat: 49.196114, lng: 19.483558 },
    { lat: 49.195474, lng: 19.482687 },
    { lat: 49.195325, lng: 19.482108 },
    { lat: 49.194436, lng: 19.48216 },
    { lat: 49.194104, lng: 19.482103 },
    { lat: 49.193863, lng: 19.481976 },
    { lat: 49.1934, lng: 19.481706 },
    { lat: 49.193153, lng: 19.481083 },
    { lat: 49.193095, lng: 19.48094 },
    { lat: 49.192927, lng: 19.480514 },
    { lat: 49.192832, lng: 19.480259 },
    { lat: 49.19264, lng: 19.47974 },
    { lat: 49.192087, lng: 19.479132 },
    { lat: 49.190985, lng: 19.479121 },
    { lat: 49.19009, lng: 19.479002 },
    { lat: 49.188699, lng: 19.478817 },
    { lat: 49.187505, lng: 19.47918 },
    { lat: 49.18746, lng: 19.479194 },
    { lat: 49.186389, lng: 19.47952 },
    { lat: 49.185277, lng: 19.479913 },
    { lat: 49.184615, lng: 19.479732 },
    { lat: 49.18458, lng: 19.479722 },
    { lat: 49.184501, lng: 19.479701 },
    { lat: 49.183312, lng: 19.479849 },
    { lat: 49.182409, lng: 19.479961 },
    { lat: 49.180744, lng: 19.480105 },
    { lat: 49.179626, lng: 19.479332 },
    { lat: 49.179569, lng: 19.479288 },
    { lat: 49.178966, lng: 19.478308 },
    { lat: 49.17684, lng: 19.478169 },
    { lat: 49.176613, lng: 19.478145 },
    { lat: 49.175396, lng: 19.475612 },
    { lat: 49.174263, lng: 19.473452 },
    { lat: 49.174203, lng: 19.473214 },
    { lat: 49.174192, lng: 19.473177 },
    { lat: 49.174342, lng: 19.471492 },
    { lat: 49.174417, lng: 19.471074 },
    { lat: 49.174455, lng: 19.470202 },
  ],
  Smrečany: [
    { lat: 49.102866, lng: 19.6301426 },
    { lat: 49.1028612, lng: 19.6301356 },
    { lat: 49.1028474, lng: 19.6301021 },
    { lat: 49.1026921, lng: 19.6302624 },
    { lat: 49.1024107, lng: 19.6305947 },
    { lat: 49.102173, lng: 19.630898 },
    { lat: 49.1020132, lng: 19.6311774 },
    { lat: 49.1018595, lng: 19.6315482 },
    { lat: 49.1018263, lng: 19.6316613 },
    { lat: 49.1016698, lng: 19.6321435 },
    { lat: 49.1014477, lng: 19.6330209 },
    { lat: 49.1013855, lng: 19.633347 },
    { lat: 49.1012979, lng: 19.633781 },
    { lat: 49.1012221, lng: 19.6341768 },
    { lat: 49.1010015, lng: 19.6348138 },
    { lat: 49.1007791, lng: 19.6356606 },
    { lat: 49.1007421, lng: 19.6357888 },
    { lat: 49.1004547, lng: 19.6363701 },
    { lat: 49.1001936, lng: 19.6368038 },
    { lat: 49.0999388, lng: 19.6372318 },
    { lat: 49.0997291, lng: 19.6377051 },
    { lat: 49.099539, lng: 19.638345 },
    { lat: 49.0993427, lng: 19.638919 },
    { lat: 49.0993087, lng: 19.6389667 },
    { lat: 49.0993096, lng: 19.639013 },
    { lat: 49.0992373, lng: 19.6390593 },
    { lat: 49.0992385, lng: 19.6403733 },
    { lat: 49.0992351, lng: 19.6423666 },
    { lat: 49.0992032, lng: 19.6427377 },
    { lat: 49.09914, lng: 19.6430136 },
    { lat: 49.0989405, lng: 19.644481 },
    { lat: 49.0989189, lng: 19.6447543 },
    { lat: 49.0989205, lng: 19.6452456 },
    { lat: 49.0989378, lng: 19.646439 },
    { lat: 49.0990954, lng: 19.6475038 },
    { lat: 49.0993976, lng: 19.6494059 },
    { lat: 49.0997313, lng: 19.6515352 },
    { lat: 49.1002235, lng: 19.6546491 },
    { lat: 49.1003054, lng: 19.6551954 },
    { lat: 49.1005267, lng: 19.6567798 },
    { lat: 49.1006972, lng: 19.658104 },
    { lat: 49.1009162, lng: 19.6596646 },
    { lat: 49.1009339, lng: 19.6597533 },
    { lat: 49.1009396, lng: 19.6597812 },
    { lat: 49.1010887, lng: 19.6596885 },
    { lat: 49.1013696, lng: 19.6598257 },
    { lat: 49.1014541, lng: 19.6597727 },
    { lat: 49.1015092, lng: 19.6597207 },
    { lat: 49.1016822, lng: 19.659609 },
    { lat: 49.1017967, lng: 19.659498 },
    { lat: 49.101863, lng: 19.6594171 },
    { lat: 49.1020071, lng: 19.6593648 },
    { lat: 49.1022912, lng: 19.6594467 },
    { lat: 49.1024604, lng: 19.6594242 },
    { lat: 49.1025657, lng: 19.6594346 },
    { lat: 49.1026216, lng: 19.6595295 },
    { lat: 49.1026739, lng: 19.6596909 },
    { lat: 49.1027879, lng: 19.6596152 },
    { lat: 49.1028702, lng: 19.6594647 },
    { lat: 49.1029555, lng: 19.6594616 },
    { lat: 49.1030488, lng: 19.6595322 },
    { lat: 49.1032069, lng: 19.6598701 },
    { lat: 49.103354, lng: 19.6600088 },
    { lat: 49.1034942, lng: 19.6600231 },
    { lat: 49.1035852, lng: 19.6600163 },
    { lat: 49.1037363, lng: 19.6601496 },
    { lat: 49.1038067, lng: 19.6602347 },
    { lat: 49.1039172, lng: 19.6602611 },
    { lat: 49.1039684, lng: 19.6602242 },
    { lat: 49.1040748, lng: 19.6602067 },
    { lat: 49.1041615, lng: 19.6602812 },
    { lat: 49.1043132, lng: 19.6604756 },
    { lat: 49.1044418, lng: 19.6605069 },
    { lat: 49.1045718, lng: 19.6606956 },
    { lat: 49.1047543, lng: 19.6604898 },
    { lat: 49.1048844, lng: 19.6606534 },
    { lat: 49.1049042, lng: 19.660871 },
    { lat: 49.1051445, lng: 19.6610095 },
    { lat: 49.1052617, lng: 19.6610582 },
    { lat: 49.1054554, lng: 19.6611835 },
    { lat: 49.1055874, lng: 19.6612375 },
    { lat: 49.1056635, lng: 19.6611481 },
    { lat: 49.1057579, lng: 19.66117 },
    { lat: 49.1059001, lng: 19.6614045 },
    { lat: 49.1063362, lng: 19.6616872 },
    { lat: 49.1064936, lng: 19.661676 },
    { lat: 49.1065421, lng: 19.661858 },
    { lat: 49.1066125, lng: 19.6620201 },
    { lat: 49.1069593, lng: 19.6619118 },
    { lat: 49.1070018, lng: 19.6618333 },
    { lat: 49.1070755, lng: 19.6619052 },
    { lat: 49.1072168, lng: 19.6620553 },
    { lat: 49.1075824, lng: 19.662041 },
    { lat: 49.1077487, lng: 19.6622627 },
    { lat: 49.107986, lng: 19.6622516 },
    { lat: 49.1081835, lng: 19.6624153 },
    { lat: 49.1085452, lng: 19.6623349 },
    { lat: 49.1087304, lng: 19.6623209 },
    { lat: 49.1089124, lng: 19.6624863 },
    { lat: 49.1092005, lng: 19.6624037 },
    { lat: 49.1092367, lng: 19.6624572 },
    { lat: 49.109356, lng: 19.6624808 },
    { lat: 49.1092989, lng: 19.6626672 },
    { lat: 49.1092996, lng: 19.6627107 },
    { lat: 49.1094354, lng: 19.6630524 },
    { lat: 49.1094645, lng: 19.6629454 },
    { lat: 49.1094977, lng: 19.6629816 },
    { lat: 49.1096458, lng: 19.6630479 },
    { lat: 49.1097606, lng: 19.6634198 },
    { lat: 49.1098663, lng: 19.6635956 },
    { lat: 49.1099786, lng: 19.6636412 },
    { lat: 49.1099891, lng: 19.6637494 },
    { lat: 49.1101475, lng: 19.6637382 },
    { lat: 49.1101735, lng: 19.6637923 },
    { lat: 49.1101577, lng: 19.6640431 },
    { lat: 49.1102705, lng: 19.6642236 },
    { lat: 49.1102605, lng: 19.6643285 },
    { lat: 49.1105436, lng: 19.6643864 },
    { lat: 49.1106681, lng: 19.66448 },
    { lat: 49.1106616, lng: 19.664736 },
    { lat: 49.1106789, lng: 19.664744 },
    { lat: 49.1108489, lng: 19.664643 },
    { lat: 49.1109372, lng: 19.6648292 },
    { lat: 49.1108491, lng: 19.6648965 },
    { lat: 49.110855, lng: 19.6650297 },
    { lat: 49.1110016, lng: 19.6650624 },
    { lat: 49.1112077, lng: 19.6648557 },
    { lat: 49.1112479, lng: 19.6653039 },
    { lat: 49.1113409, lng: 19.6652922 },
    { lat: 49.111591, lng: 19.6641513 },
    { lat: 49.1117897, lng: 19.6632224 },
    { lat: 49.11213, lng: 19.6616726 },
    { lat: 49.1124095, lng: 19.6603858 },
    { lat: 49.1128336, lng: 19.6584422 },
    { lat: 49.1130635, lng: 19.6574046 },
    { lat: 49.1133407, lng: 19.6561108 },
    { lat: 49.1134475, lng: 19.6556499 },
    { lat: 49.1138345, lng: 19.6539405 },
    { lat: 49.1139968, lng: 19.6532059 },
    { lat: 49.1142923, lng: 19.6518987 },
    { lat: 49.1143372, lng: 19.6517124 },
    { lat: 49.1144874, lng: 19.6516725 },
    { lat: 49.1146657, lng: 19.651731 },
    { lat: 49.1150323, lng: 19.6519165 },
    { lat: 49.1151376, lng: 19.6520067 },
    { lat: 49.1152261, lng: 19.6521451 },
    { lat: 49.1154006, lng: 19.652267 },
    { lat: 49.115572, lng: 19.6524467 },
    { lat: 49.1158452, lng: 19.6526397 },
    { lat: 49.1159414, lng: 19.6527269 },
    { lat: 49.1160184, lng: 19.6529617 },
    { lat: 49.116045, lng: 19.6531827 },
    { lat: 49.1160106, lng: 19.6532693 },
    { lat: 49.1160085, lng: 19.6534224 },
    { lat: 49.1160754, lng: 19.6535852 },
    { lat: 49.1163785, lng: 19.6537849 },
    { lat: 49.1165999, lng: 19.6540282 },
    { lat: 49.1169692, lng: 19.6544019 },
    { lat: 49.1170896, lng: 19.6545028 },
    { lat: 49.1173763, lng: 19.6546638 },
    { lat: 49.1174745, lng: 19.6548493 },
    { lat: 49.1175761, lng: 19.6548617 },
    { lat: 49.117751, lng: 19.654883 },
    { lat: 49.1180317, lng: 19.6547329 },
    { lat: 49.1182961, lng: 19.6545995 },
    { lat: 49.1183641, lng: 19.6545583 },
    { lat: 49.1185007, lng: 19.6546154 },
    { lat: 49.1182811, lng: 19.6548212 },
    { lat: 49.1186329, lng: 19.6550827 },
    { lat: 49.1191333, lng: 19.6554145 },
    { lat: 49.1192319, lng: 19.6554748 },
    { lat: 49.1194253, lng: 19.6556196 },
    { lat: 49.1199399, lng: 19.6560427 },
    { lat: 49.1200025, lng: 19.6560863 },
    { lat: 49.1202061, lng: 19.6561789 },
    { lat: 49.1204447, lng: 19.6562989 },
    { lat: 49.1207618, lng: 19.6565676 },
    { lat: 49.1209485, lng: 19.656816 },
    { lat: 49.1210584, lng: 19.6565464 },
    { lat: 49.1214381, lng: 19.6556361 },
    { lat: 49.1217105, lng: 19.6549972 },
    { lat: 49.1217988, lng: 19.6548053 },
    { lat: 49.1220209, lng: 19.6550853 },
    { lat: 49.1221039, lng: 19.6552069 },
    { lat: 49.1226162, lng: 19.6558548 },
    { lat: 49.1226653, lng: 19.6559231 },
    { lat: 49.1231468, lng: 19.6565425 },
    { lat: 49.1234844, lng: 19.6569883 },
    { lat: 49.1242439, lng: 19.6579826 },
    { lat: 49.1251397, lng: 19.6591766 },
    { lat: 49.1259421, lng: 19.6602216 },
    { lat: 49.1269073, lng: 19.6614848 },
    { lat: 49.1270484, lng: 19.661058 },
    { lat: 49.1272864, lng: 19.6603032 },
    { lat: 49.127316, lng: 19.6602365 },
    { lat: 49.1269954, lng: 19.6597087 },
    { lat: 49.1273159, lng: 19.6589095 },
    { lat: 49.1270152, lng: 19.6585609 },
    { lat: 49.1270074, lng: 19.6585592 },
    { lat: 49.1268578, lng: 19.658439 },
    { lat: 49.1268477, lng: 19.6584401 },
    { lat: 49.1266576, lng: 19.6582967 },
    { lat: 49.1265617, lng: 19.6581408 },
    { lat: 49.1263099, lng: 19.6577451 },
    { lat: 49.1259541, lng: 19.6571915 },
    { lat: 49.1257231, lng: 19.6568376 },
    { lat: 49.1255536, lng: 19.6565723 },
    { lat: 49.1254577, lng: 19.6563693 },
    { lat: 49.1254034, lng: 19.6561353 },
    { lat: 49.125436, lng: 19.6556995 },
    { lat: 49.1256917, lng: 19.6551422 },
    { lat: 49.1265264, lng: 19.6563321 },
    { lat: 49.1268111, lng: 19.6566768 },
    { lat: 49.1277344, lng: 19.6543321 },
    { lat: 49.1282503, lng: 19.6528892 },
    { lat: 49.1283363, lng: 19.6526144 },
    { lat: 49.1283854, lng: 19.6526036 },
    { lat: 49.1284006, lng: 19.6525157 },
    { lat: 49.1284099, lng: 19.6524967 },
    { lat: 49.1284233, lng: 19.6524961 },
    { lat: 49.1284382, lng: 19.6525025 },
    { lat: 49.1285125, lng: 19.6526006 },
    { lat: 49.1285435, lng: 19.6526675 },
    { lat: 49.1286403, lng: 19.6529698 },
    { lat: 49.1288238, lng: 19.6532689 },
    { lat: 49.1288885, lng: 19.6534057 },
    { lat: 49.1290716, lng: 19.6535217 },
    { lat: 49.1291365, lng: 19.6535093 },
    { lat: 49.1294045, lng: 19.6533838 },
    { lat: 49.1295361, lng: 19.6533752 },
    { lat: 49.129715, lng: 19.6533211 },
    { lat: 49.1298125, lng: 19.6534122 },
    { lat: 49.1299052, lng: 19.6535425 },
    { lat: 49.1300101, lng: 19.6537997 },
    { lat: 49.130025, lng: 19.6539842 },
    { lat: 49.1300418, lng: 19.6540339 },
    { lat: 49.1300807, lng: 19.6540957 },
    { lat: 49.1302284, lng: 19.6540722 },
    { lat: 49.1303989, lng: 19.6540046 },
    { lat: 49.13046, lng: 19.6540091 },
    { lat: 49.1306944, lng: 19.6541637 },
    { lat: 49.1308796, lng: 19.6543241 },
    { lat: 49.1311005, lng: 19.6546093 },
    { lat: 49.1311216, lng: 19.6547017 },
    { lat: 49.1311311, lng: 19.6550709 },
    { lat: 49.1311784, lng: 19.6551466 },
    { lat: 49.1312968, lng: 19.6552236 },
    { lat: 49.1313186, lng: 19.6552574 },
    { lat: 49.1314877, lng: 19.6551838 },
    { lat: 49.1316124, lng: 19.6552746 },
    { lat: 49.131686, lng: 19.6554612 },
    { lat: 49.1319761, lng: 19.6556238 },
    { lat: 49.1320874, lng: 19.6557213 },
    { lat: 49.1322, lng: 19.6556464 },
    { lat: 49.1323115, lng: 19.655442 },
    { lat: 49.1324159, lng: 19.6552309 },
    { lat: 49.1327609, lng: 19.6554395 },
    { lat: 49.1327639, lng: 19.6554529 },
    { lat: 49.1327657, lng: 19.6554722 },
    { lat: 49.1327575, lng: 19.6555388 },
    { lat: 49.1328152, lng: 19.6556995 },
    { lat: 49.1328235, lng: 19.6557058 },
    { lat: 49.1328313, lng: 19.6557071 },
    { lat: 49.13291, lng: 19.6556757 },
    { lat: 49.1329821, lng: 19.6554498 },
    { lat: 49.1329909, lng: 19.6554417 },
    { lat: 49.1330849, lng: 19.6554781 },
    { lat: 49.1331281, lng: 19.6555628 },
    { lat: 49.1331839, lng: 19.6559582 },
    { lat: 49.1333212, lng: 19.6564614 },
    { lat: 49.1334313, lng: 19.6564782 },
    { lat: 49.1335348, lng: 19.6564016 },
    { lat: 49.133686, lng: 19.6564306 },
    { lat: 49.1338133, lng: 19.6565326 },
    { lat: 49.1339331, lng: 19.6565138 },
    { lat: 49.134062, lng: 19.6564764 },
    { lat: 49.1340669, lng: 19.6564867 },
    { lat: 49.1340573, lng: 19.6567003 },
    { lat: 49.1341198, lng: 19.6568896 },
    { lat: 49.1340846, lng: 19.6571131 },
    { lat: 49.134136, lng: 19.6572283 },
    { lat: 49.1343635, lng: 19.6573012 },
    { lat: 49.1344868, lng: 19.6573673 },
    { lat: 49.1345886, lng: 19.6574997 },
    { lat: 49.1346541, lng: 19.6576044 },
    { lat: 49.1347723, lng: 19.6576545 },
    { lat: 49.1349416, lng: 19.6576338 },
    { lat: 49.135194, lng: 19.6577153 },
    { lat: 49.1353517, lng: 19.6576361 },
    { lat: 49.1355205, lng: 19.6576079 },
    { lat: 49.1356015, lng: 19.6577057 },
    { lat: 49.1357063, lng: 19.6578043 },
    { lat: 49.1358144, lng: 19.6579852 },
    { lat: 49.1358568, lng: 19.6583281 },
    { lat: 49.1358807, lng: 19.658485 },
    { lat: 49.1359548, lng: 19.6585587 },
    { lat: 49.136094, lng: 19.658726 },
    { lat: 49.136341, lng: 19.658956 },
    { lat: 49.1364411, lng: 19.6590785 },
    { lat: 49.1365942, lng: 19.6591559 },
    { lat: 49.1368064, lng: 19.6593686 },
    { lat: 49.1369866, lng: 19.6595918 },
    { lat: 49.1370911, lng: 19.6596648 },
    { lat: 49.1372345, lng: 19.6599501 },
    { lat: 49.1373906, lng: 19.6601157 },
    { lat: 49.1377049, lng: 19.6600895 },
    { lat: 49.1378328, lng: 19.6599784 },
    { lat: 49.137913, lng: 19.6599806 },
    { lat: 49.1379948, lng: 19.6600473 },
    { lat: 49.1380892, lng: 19.6602711 },
    { lat: 49.1381922, lng: 19.6604375 },
    { lat: 49.1382963, lng: 19.6604948 },
    { lat: 49.1383754, lng: 19.6606558 },
    { lat: 49.1384802, lng: 19.6607295 },
    { lat: 49.1385369, lng: 19.6608158 },
    { lat: 49.1386815, lng: 19.6609232 },
    { lat: 49.1388381, lng: 19.6611287 },
    { lat: 49.1388791, lng: 19.6612109 },
    { lat: 49.138884, lng: 19.6611992 },
    { lat: 49.1390692, lng: 19.6610531 },
    { lat: 49.1392438, lng: 19.6611574 },
    { lat: 49.1393856, lng: 19.661028 },
    { lat: 49.1395421, lng: 19.6609738 },
    { lat: 49.1396444, lng: 19.6610186 },
    { lat: 49.1397073, lng: 19.661142 },
    { lat: 49.1398049, lng: 19.6612363 },
    { lat: 49.1400138, lng: 19.6612929 },
    { lat: 49.1401536, lng: 19.6614753 },
    { lat: 49.1401863, lng: 19.661754 },
    { lat: 49.1403208, lng: 19.6617639 },
    { lat: 49.1404794, lng: 19.6618588 },
    { lat: 49.1407056, lng: 19.6615983 },
    { lat: 49.1407782, lng: 19.6614088 },
    { lat: 49.1408392, lng: 19.6614108 },
    { lat: 49.1409636, lng: 19.6616768 },
    { lat: 49.1410723, lng: 19.6616908 },
    { lat: 49.1411101, lng: 19.6617804 },
    { lat: 49.1410868, lng: 19.6618634 },
    { lat: 49.1410893, lng: 19.6619729 },
    { lat: 49.1412339, lng: 19.6620825 },
    { lat: 49.1413278, lng: 19.6621646 },
    { lat: 49.1416057, lng: 19.661878 },
    { lat: 49.1417756, lng: 19.6618248 },
    { lat: 49.1420793, lng: 19.6621185 },
    { lat: 49.1422023, lng: 19.6624484 },
    { lat: 49.1424868, lng: 19.6624648 },
    { lat: 49.1426639, lng: 19.6622641 },
    { lat: 49.1428344, lng: 19.662222 },
    { lat: 49.142975, lng: 19.662268 },
    { lat: 49.1430039, lng: 19.6622869 },
    { lat: 49.1430852, lng: 19.6624171 },
    { lat: 49.1430554, lng: 19.6626332 },
    { lat: 49.1431253, lng: 19.6627142 },
    { lat: 49.1433748, lng: 19.6627507 },
    { lat: 49.1435615, lng: 19.6629722 },
    { lat: 49.1437434, lng: 19.6631622 },
    { lat: 49.1438797, lng: 19.6631852 },
    { lat: 49.1440058, lng: 19.6631046 },
    { lat: 49.1440538, lng: 19.6632781 },
    { lat: 49.144394, lng: 19.6632251 },
    { lat: 49.1448103, lng: 19.6633869 },
    { lat: 49.1450049, lng: 19.6636116 },
    { lat: 49.14505, lng: 19.6635105 },
    { lat: 49.1454457, lng: 19.6636932 },
    { lat: 49.1455119, lng: 19.6638641 },
    { lat: 49.1458695, lng: 19.663747 },
    { lat: 49.1460659, lng: 19.6634792 },
    { lat: 49.1463601, lng: 19.6634809 },
    { lat: 49.146567, lng: 19.6633448 },
    { lat: 49.1466457, lng: 19.6632129 },
    { lat: 49.1468593, lng: 19.6632553 },
    { lat: 49.1471534, lng: 19.6635093 },
    { lat: 49.1473219, lng: 19.6634966 },
    { lat: 49.1475443, lng: 19.6636104 },
    { lat: 49.1477974, lng: 19.6635243 },
    { lat: 49.1480536, lng: 19.6635641 },
    { lat: 49.1481326, lng: 19.6637157 },
    { lat: 49.1483789, lng: 19.6637864 },
    { lat: 49.1485104, lng: 19.663548 },
    { lat: 49.1487723, lng: 19.6636613 },
    { lat: 49.1489223, lng: 19.6637434 },
    { lat: 49.1489758, lng: 19.6636558 },
    { lat: 49.1491842, lng: 19.663756 },
    { lat: 49.1492881, lng: 19.6640393 },
    { lat: 49.1493307, lng: 19.664008 },
    { lat: 49.1494862, lng: 19.6640873 },
    { lat: 49.1495878, lng: 19.6645994 },
    { lat: 49.1497177, lng: 19.6645579 },
    { lat: 49.1499868, lng: 19.6646796 },
    { lat: 49.1500508, lng: 19.664877 },
    { lat: 49.1502825, lng: 19.6648165 },
    { lat: 49.1503788, lng: 19.66488 },
    { lat: 49.1504565, lng: 19.6651354 },
    { lat: 49.1505996, lng: 19.6651592 },
    { lat: 49.1506665, lng: 19.6653247 },
    { lat: 49.1507675, lng: 19.6653877 },
    { lat: 49.1508294, lng: 19.6654648 },
    { lat: 49.1507671, lng: 19.6656616 },
    { lat: 49.150773, lng: 19.6658215 },
    { lat: 49.1506707, lng: 19.6660808 },
    { lat: 49.1507931, lng: 19.6662747 },
    { lat: 49.1508299, lng: 19.6664919 },
    { lat: 49.1507833, lng: 19.666789 },
    { lat: 49.1509544, lng: 19.6669635 },
    { lat: 49.1510008, lng: 19.6671207 },
    { lat: 49.1511291, lng: 19.6671921 },
    { lat: 49.1511504, lng: 19.6674444 },
    { lat: 49.151107, lng: 19.6675093 },
    { lat: 49.1512403, lng: 19.6677939 },
    { lat: 49.1512123, lng: 19.6680523 },
    { lat: 49.1513478, lng: 19.6681917 },
    { lat: 49.1514913, lng: 19.6685055 },
    { lat: 49.1514143, lng: 19.6688014 },
    { lat: 49.1514576, lng: 19.6688621 },
    { lat: 49.1513925, lng: 19.6690728 },
    { lat: 49.1514438, lng: 19.6692389 },
    { lat: 49.1514322, lng: 19.6694564 },
    { lat: 49.1515567, lng: 19.6695976 },
    { lat: 49.1516796, lng: 19.670006 },
    { lat: 49.1516747, lng: 19.6703312 },
    { lat: 49.1516792, lng: 19.6706845 },
    { lat: 49.1517715, lng: 19.6707859 },
    { lat: 49.1517214, lng: 19.6710293 },
    { lat: 49.1517479, lng: 19.6711702 },
    { lat: 49.1516242, lng: 19.6715547 },
    { lat: 49.1516703, lng: 19.6716835 },
    { lat: 49.1517306, lng: 19.6717971 },
    { lat: 49.1515696, lng: 19.6722085 },
    { lat: 49.1516353, lng: 19.6723651 },
    { lat: 49.1516656, lng: 19.6725697 },
    { lat: 49.1517755, lng: 19.6729402 },
    { lat: 49.1521347, lng: 19.6729321 },
    { lat: 49.1523178, lng: 19.6729194 },
    { lat: 49.1524825, lng: 19.6730025 },
    { lat: 49.152694, lng: 19.6730707 },
    { lat: 49.1529051, lng: 19.6732869 },
    { lat: 49.1529726, lng: 19.6735143 },
    { lat: 49.1530275, lng: 19.6737357 },
    { lat: 49.1530962, lng: 19.6739133 },
    { lat: 49.1533652, lng: 19.6738302 },
    { lat: 49.1534576, lng: 19.6739326 },
    { lat: 49.153633, lng: 19.6742047 },
    { lat: 49.153774, lng: 19.6741862 },
    { lat: 49.1541392, lng: 19.6742124 },
    { lat: 49.1542793, lng: 19.6743481 },
    { lat: 49.1543805, lng: 19.6742189 },
    { lat: 49.1544682, lng: 19.6741613 },
    { lat: 49.1545718, lng: 19.6741877 },
    { lat: 49.1547593, lng: 19.6743005 },
    { lat: 49.1548164, lng: 19.6744447 },
    { lat: 49.1550973, lng: 19.6745029 },
    { lat: 49.1551638, lng: 19.6746077 },
    { lat: 49.1553438, lng: 19.6746784 },
    { lat: 49.1555472, lng: 19.6746125 },
    { lat: 49.1556, lng: 19.674638 },
    { lat: 49.1558463, lng: 19.6748578 },
    { lat: 49.1561353, lng: 19.6750736 },
    { lat: 49.156437, lng: 19.6754774 },
    { lat: 49.1571898, lng: 19.6766047 },
    { lat: 49.1578424, lng: 19.6773752 },
    { lat: 49.1581862, lng: 19.6778147 },
    { lat: 49.1582694, lng: 19.6781909 },
    { lat: 49.1588038, lng: 19.6795243 },
    { lat: 49.1591218, lng: 19.6801702 },
    { lat: 49.1595908, lng: 19.6805313 },
    { lat: 49.1596701, lng: 19.6809277 },
    { lat: 49.1598495, lng: 19.6812632 },
    { lat: 49.1603377, lng: 19.6816803 },
    { lat: 49.1608677, lng: 19.6822039 },
    { lat: 49.161162, lng: 19.682641 },
    { lat: 49.1613762, lng: 19.6840211 },
    { lat: 49.1616457, lng: 19.6849236 },
    { lat: 49.1618184, lng: 19.6854663 },
    { lat: 49.1618762, lng: 19.6855983 },
    { lat: 49.1620982, lng: 19.6859873 },
    { lat: 49.1626919, lng: 19.6868249 },
    { lat: 49.163257, lng: 19.6875578 },
    { lat: 49.1639289, lng: 19.6884907 },
    { lat: 49.1645843, lng: 19.6894286 },
    { lat: 49.165501, lng: 19.6907235 },
    { lat: 49.1665526, lng: 19.6908122 },
    { lat: 49.1676072, lng: 19.690762 },
    { lat: 49.168341, lng: 19.6907746 },
    { lat: 49.1690989, lng: 19.6907657 },
    { lat: 49.1701332, lng: 19.690849 },
    { lat: 49.171236, lng: 19.6912252 },
    { lat: 49.1719679, lng: 19.6916287 },
    { lat: 49.1724008, lng: 19.6913751 },
    { lat: 49.1688743, lng: 19.6866966 },
    { lat: 49.1684004, lng: 19.6830784 },
    { lat: 49.1683524, lng: 19.6829382 },
    { lat: 49.1680605, lng: 19.6822446 },
    { lat: 49.1676667, lng: 19.6817471 },
    { lat: 49.1674761, lng: 19.6814888 },
    { lat: 49.1665737, lng: 19.6803153 },
    { lat: 49.1660622, lng: 19.6791136 },
    { lat: 49.1654467, lng: 19.6774258 },
    { lat: 49.1647062, lng: 19.6763438 },
    { lat: 49.1641357, lng: 19.6760774 },
    { lat: 49.1630358, lng: 19.6756138 },
    { lat: 49.162627, lng: 19.6753106 },
    { lat: 49.1622919, lng: 19.6749933 },
    { lat: 49.1617053, lng: 19.6743636 },
    { lat: 49.1613999, lng: 19.6739079 },
    { lat: 49.1611231, lng: 19.6735044 },
    { lat: 49.1607948, lng: 19.6729831 },
    { lat: 49.1606044, lng: 19.6727043 },
    { lat: 49.1601882, lng: 19.6720581 },
    { lat: 49.159537, lng: 19.6704769 },
    { lat: 49.1592809, lng: 19.6699338 },
    { lat: 49.1588156, lng: 19.6691235 },
    { lat: 49.1583855, lng: 19.6684151 },
    { lat: 49.157243, lng: 19.6668363 },
    { lat: 49.1569999, lng: 19.6661278 },
    { lat: 49.1566426, lng: 19.6651607 },
    { lat: 49.1561847, lng: 19.6646679 },
    { lat: 49.1553483, lng: 19.6637636 },
    { lat: 49.1547528, lng: 19.6631641 },
    { lat: 49.1546908, lng: 19.6631125 },
    { lat: 49.1545366, lng: 19.6629143 },
    { lat: 49.1538809, lng: 19.6621252 },
    { lat: 49.1527902, lng: 19.6610057 },
    { lat: 49.1518788, lng: 19.6601415 },
    { lat: 49.1511856, lng: 19.6596775 },
    { lat: 49.1511804, lng: 19.659687 },
    { lat: 49.1502093, lng: 19.6586222 },
    { lat: 49.1489826, lng: 19.6574088 },
    { lat: 49.1479975, lng: 19.6567665 },
    { lat: 49.1467919, lng: 19.6559484 },
    { lat: 49.1462973, lng: 19.6556198 },
    { lat: 49.1439868, lng: 19.6540784 },
    { lat: 49.1430593, lng: 19.653485 },
    { lat: 49.141917, lng: 19.6528289 },
    { lat: 49.1407249, lng: 19.6521395 },
    { lat: 49.1394408, lng: 19.6515121 },
    { lat: 49.1380071, lng: 19.6506379 },
    { lat: 49.1370773, lng: 19.6501737 },
    { lat: 49.1366611, lng: 19.6499609 },
    { lat: 49.1353588, lng: 19.6492276 },
    { lat: 49.1343119, lng: 19.6486424 },
    { lat: 49.1332152, lng: 19.647971 },
    { lat: 49.1317397, lng: 19.6472719 },
    { lat: 49.1310064, lng: 19.6469681 },
    { lat: 49.1300851, lng: 19.6467219 },
    { lat: 49.1301327, lng: 19.6455384 },
    { lat: 49.1303416, lng: 19.6436547 },
    { lat: 49.1288299, lng: 19.6435854 },
    { lat: 49.127397, lng: 19.6433329 },
    { lat: 49.1255622, lng: 19.6425614 },
    { lat: 49.1251801, lng: 19.6442017 },
    { lat: 49.1242331, lng: 19.6436277 },
    { lat: 49.1236459, lng: 19.6432175 },
    { lat: 49.1231013, lng: 19.6428339 },
    { lat: 49.1226879, lng: 19.6425572 },
    { lat: 49.1221208, lng: 19.6422439 },
    { lat: 49.1217713, lng: 19.6420355 },
    { lat: 49.1214337, lng: 19.6419186 },
    { lat: 49.1208336, lng: 19.6417774 },
    { lat: 49.1206679, lng: 19.6417244 },
    { lat: 49.1203364, lng: 19.6417393 },
    { lat: 49.119595, lng: 19.641816 },
    { lat: 49.1194258, lng: 19.641813 },
    { lat: 49.1185051, lng: 19.6417298 },
    { lat: 49.1179751, lng: 19.6416181 },
    { lat: 49.1177721, lng: 19.6414391 },
    { lat: 49.1174432, lng: 19.6410102 },
    { lat: 49.1169106, lng: 19.6403064 },
    { lat: 49.1168836, lng: 19.6402819 },
    { lat: 49.1168217, lng: 19.6401063 },
    { lat: 49.1166835, lng: 19.6398659 },
    { lat: 49.1165043, lng: 19.6390047 },
    { lat: 49.116343, lng: 19.6387758 },
    { lat: 49.1162113, lng: 19.6383733 },
    { lat: 49.1161692, lng: 19.6381863 },
    { lat: 49.116069, lng: 19.6380089 },
    { lat: 49.1159673, lng: 19.6378784 },
    { lat: 49.1159179, lng: 19.6376545 },
    { lat: 49.1157395, lng: 19.6372392 },
    { lat: 49.1156678, lng: 19.6369959 },
    { lat: 49.1156136, lng: 19.6368393 },
    { lat: 49.1154713, lng: 19.6365084 },
    { lat: 49.1153137, lng: 19.6362557 },
    { lat: 49.1152006, lng: 19.636144 },
    { lat: 49.1151005, lng: 19.6360228 },
    { lat: 49.1145251, lng: 19.6350217 },
    { lat: 49.1144852, lng: 19.6349111 },
    { lat: 49.1143463, lng: 19.6348244 },
    { lat: 49.1143236, lng: 19.6347246 },
    { lat: 49.1142041, lng: 19.6346705 },
    { lat: 49.1140411, lng: 19.6344518 },
    { lat: 49.113903, lng: 19.6343071 },
    { lat: 49.1138147, lng: 19.6342238 },
    { lat: 49.1136382, lng: 19.6340306 },
    { lat: 49.1135078, lng: 19.6339381 },
    { lat: 49.1134039, lng: 19.6337801 },
    { lat: 49.1133148, lng: 19.6337027 },
    { lat: 49.1131491, lng: 19.6335246 },
    { lat: 49.1130495, lng: 19.6334396 },
    { lat: 49.1129743, lng: 19.6333154 },
    { lat: 49.1128567, lng: 19.633156 },
    { lat: 49.1127941, lng: 19.6330449 },
    { lat: 49.1127082, lng: 19.6330092 },
    { lat: 49.1124777, lng: 19.6329439 },
    { lat: 49.1123802, lng: 19.6328806 },
    { lat: 49.1122938, lng: 19.6329385 },
    { lat: 49.1122367, lng: 19.6328976 },
    { lat: 49.1121126, lng: 19.6327404 },
    { lat: 49.112056, lng: 19.6326365 },
    { lat: 49.1119842, lng: 19.6327202 },
    { lat: 49.1118367, lng: 19.6325683 },
    { lat: 49.1116778, lng: 19.6325927 },
    { lat: 49.1114942, lng: 19.6326703 },
    { lat: 49.111452, lng: 19.6327826 },
    { lat: 49.1113348, lng: 19.6328328 },
    { lat: 49.1111446, lng: 19.6327867 },
    { lat: 49.1110286, lng: 19.6327901 },
    { lat: 49.1109632, lng: 19.6327372 },
    { lat: 49.1108668, lng: 19.6327263 },
    { lat: 49.1107426, lng: 19.6326924 },
    { lat: 49.1105524, lng: 19.6327948 },
    { lat: 49.1104018, lng: 19.6327813 },
    { lat: 49.1103054, lng: 19.6327406 },
    { lat: 49.1102956, lng: 19.6327499 },
    { lat: 49.1102599, lng: 19.6329345 },
    { lat: 49.1101853, lng: 19.6329289 },
    { lat: 49.1101383, lng: 19.6329122 },
    { lat: 49.1101318, lng: 19.6329136 },
    { lat: 49.1101024, lng: 19.6329393 },
    { lat: 49.1100072, lng: 19.6329268 },
    { lat: 49.1098959, lng: 19.6327816 },
    { lat: 49.109888, lng: 19.6327788 },
    { lat: 49.1097507, lng: 19.632834 },
    { lat: 49.1096192, lng: 19.6326919 },
    { lat: 49.1096125, lng: 19.6326901 },
    { lat: 49.1094784, lng: 19.6327425 },
    { lat: 49.1094578, lng: 19.632736 },
    { lat: 49.1094585, lng: 19.6326715 },
    { lat: 49.1094488, lng: 19.6326567 },
    { lat: 49.1093595, lng: 19.6326483 },
    { lat: 49.10935, lng: 19.6326626 },
    { lat: 49.1093736, lng: 19.6328159 },
    { lat: 49.1093233, lng: 19.6329272 },
    { lat: 49.109207, lng: 19.6328925 },
    { lat: 49.1092045, lng: 19.6327881 },
    { lat: 49.109196, lng: 19.6327742 },
    { lat: 49.1090092, lng: 19.6327558 },
    { lat: 49.1089154, lng: 19.6327972 },
    { lat: 49.1088033, lng: 19.6328876 },
    { lat: 49.1086718, lng: 19.6330727 },
    { lat: 49.1085437, lng: 19.6332011 },
    { lat: 49.108307, lng: 19.6333047 },
    { lat: 49.1081272, lng: 19.6330633 },
    { lat: 49.1081108, lng: 19.6330708 },
    { lat: 49.1080827, lng: 19.6333998 },
    { lat: 49.1075081, lng: 19.6333256 },
    { lat: 49.107489, lng: 19.6333527 },
    { lat: 49.107478, lng: 19.6336092 },
    { lat: 49.1072959, lng: 19.6336723 },
    { lat: 49.1069033, lng: 19.6333555 },
    { lat: 49.1068901, lng: 19.6333648 },
    { lat: 49.1068449, lng: 19.6334633 },
    { lat: 49.1063352, lng: 19.6333475 },
    { lat: 49.1061345, lng: 19.6331198 },
    { lat: 49.1055302, lng: 19.6327314 },
    { lat: 49.1051669, lng: 19.6325191 },
    { lat: 49.1049864, lng: 19.6324648 },
    { lat: 49.1046108, lng: 19.6322528 },
    { lat: 49.1045547, lng: 19.6317533 },
    { lat: 49.104546, lng: 19.6317323 },
    { lat: 49.1040311, lng: 19.6313045 },
    { lat: 49.1034968, lng: 19.6308194 },
    { lat: 49.1033647, lng: 19.6308155 },
    { lat: 49.1032028, lng: 19.6304407 },
    { lat: 49.1030791, lng: 19.6303003 },
    { lat: 49.1029134, lng: 19.6304477 },
    { lat: 49.1028057, lng: 19.6302046 },
    { lat: 49.102866, lng: 19.6301426 },
  ],
  SvätýKríž: [
    { lat: 49.0558018, lng: 19.5071338 },
    { lat: 49.0557357, lng: 19.5071709 },
    { lat: 49.0556308, lng: 19.5069676 },
    { lat: 49.0555823, lng: 19.5069655 },
    { lat: 49.0555521, lng: 19.5070233 },
    { lat: 49.0554694, lng: 19.5069868 },
    { lat: 49.05543, lng: 19.5068883 },
    { lat: 49.0553885, lng: 19.5068431 },
    { lat: 49.0552375, lng: 19.5068203 },
    { lat: 49.0551603, lng: 19.5068317 },
    { lat: 49.0550883, lng: 19.5069349 },
    { lat: 49.0550077, lng: 19.5069704 },
    { lat: 49.0549356, lng: 19.5068221 },
    { lat: 49.0548911, lng: 19.5068372 },
    { lat: 49.0547817, lng: 19.5070313 },
    { lat: 49.0546503, lng: 19.5070902 },
    { lat: 49.0543942, lng: 19.5068608 },
    { lat: 49.0543603, lng: 19.5069797 },
    { lat: 49.0543217, lng: 19.5069791 },
    { lat: 49.0541979, lng: 19.506859 },
    { lat: 49.0541813, lng: 19.5069143 },
    { lat: 49.0541018, lng: 19.506945 },
    { lat: 49.0538949, lng: 19.506857 },
    { lat: 49.0536998, lng: 19.5070048 },
    { lat: 49.0536297, lng: 19.5071448 },
    { lat: 49.053489, lng: 19.50715 },
    { lat: 49.0533698, lng: 19.5072036 },
    { lat: 49.0532236, lng: 19.5071864 },
    { lat: 49.0531201, lng: 19.5073081 },
    { lat: 49.053041, lng: 19.5073297 },
    { lat: 49.0529027, lng: 19.5074604 },
    { lat: 49.0528929, lng: 19.5073954 },
    { lat: 49.0528697, lng: 19.5073784 },
    { lat: 49.0528475, lng: 19.5074106 },
    { lat: 49.0528152, lng: 19.5075915 },
    { lat: 49.0525965, lng: 19.5076154 },
    { lat: 49.0525333, lng: 19.5076393 },
    { lat: 49.0524206, lng: 19.5077353 },
    { lat: 49.0523977, lng: 19.5078268 },
    { lat: 49.0523199, lng: 19.5077729 },
    { lat: 49.0521399, lng: 19.50788 },
    { lat: 49.0520187, lng: 19.5078433 },
    { lat: 49.0519433, lng: 19.507945 },
    { lat: 49.0518723, lng: 19.5079414 },
    { lat: 49.0518355, lng: 19.5079766 },
    { lat: 49.0517417, lng: 19.5079916 },
    { lat: 49.0517319, lng: 19.5081995 },
    { lat: 49.0516381, lng: 19.5082669 },
    { lat: 49.0515584, lng: 19.5083968 },
    { lat: 49.0515037, lng: 19.5084048 },
    { lat: 49.0514829, lng: 19.5083739 },
    { lat: 49.0514686, lng: 19.5083812 },
    { lat: 49.0514384, lng: 19.5084332 },
    { lat: 49.0512508, lng: 19.5084714 },
    { lat: 49.0511557, lng: 19.5084359 },
    { lat: 49.050971, lng: 19.5084357 },
    { lat: 49.0509151, lng: 19.5085203 },
    { lat: 49.0508815, lng: 19.508549 },
    { lat: 49.0508114, lng: 19.5085191 },
    { lat: 49.0507301, lng: 19.5086153 },
    { lat: 49.0506719, lng: 19.5088184 },
    { lat: 49.0506667, lng: 19.5089066 },
    { lat: 49.0505987, lng: 19.5089942 },
    { lat: 49.0505335, lng: 19.5090238 },
    { lat: 49.050494, lng: 19.50907 },
    { lat: 49.0503394, lng: 19.5090397 },
    { lat: 49.0502667, lng: 19.5090499 },
    { lat: 49.0501502, lng: 19.5091664 },
    { lat: 49.0500673, lng: 19.5092751 },
    { lat: 49.0500223, lng: 19.5093025 },
    { lat: 49.049991, lng: 19.5093561 },
    { lat: 49.0499874, lng: 19.5095476 },
    { lat: 49.0498543, lng: 19.5096931 },
    { lat: 49.0497568, lng: 19.5097042 },
    { lat: 49.0496068, lng: 19.5098523 },
    { lat: 49.0495926, lng: 19.5098085 },
    { lat: 49.0495078, lng: 19.5098498 },
    { lat: 49.0495134, lng: 19.5098755 },
    { lat: 49.0495036, lng: 19.5099591 },
    { lat: 49.049396, lng: 19.5099723 },
    { lat: 49.0493241, lng: 19.5100974 },
    { lat: 49.0493378, lng: 19.5102008 },
    { lat: 49.0492348, lng: 19.5104373 },
    { lat: 49.0491253, lng: 19.5105772 },
    { lat: 49.0489591, lng: 19.5106928 },
    { lat: 49.048748, lng: 19.5109042 },
    { lat: 49.0486247, lng: 19.5110156 },
    { lat: 49.0484462, lng: 19.5110784 },
    { lat: 49.0482841, lng: 19.5112044 },
    { lat: 49.0481886, lng: 19.5112648 },
    { lat: 49.0481327, lng: 19.5111475 },
    { lat: 49.0480219, lng: 19.5112124 },
    { lat: 49.0479285, lng: 19.5113189 },
    { lat: 49.047883, lng: 19.5112332 },
    { lat: 49.0477542, lng: 19.5112752 },
    { lat: 49.047765, lng: 19.5111149 },
    { lat: 49.047687, lng: 19.5110151 },
    { lat: 49.0476411, lng: 19.5110433 },
    { lat: 49.0475009, lng: 19.511158 },
    { lat: 49.0474076, lng: 19.5109621 },
    { lat: 49.0472172, lng: 19.510966 },
    { lat: 49.0471423, lng: 19.5110261 },
    { lat: 49.0469771, lng: 19.5111355 },
    { lat: 49.0467879, lng: 19.5112852 },
    { lat: 49.046731, lng: 19.5113466 },
    { lat: 49.0467285, lng: 19.5114675 },
    { lat: 49.0467, lng: 19.5115313 },
    { lat: 49.0464878, lng: 19.5116457 },
    { lat: 49.0464185, lng: 19.5116602 },
    { lat: 49.0461716, lng: 19.5116827 },
    { lat: 49.0461139, lng: 19.5117004 },
    { lat: 49.0460416, lng: 19.5116477 },
    { lat: 49.0459951, lng: 19.5116634 },
    { lat: 49.0459433, lng: 19.5117127 },
    { lat: 49.0458482, lng: 19.5118276 },
    { lat: 49.0456913, lng: 19.5122171 },
    { lat: 49.0456934, lng: 19.512335 },
    { lat: 49.0458299, lng: 19.512387 },
    { lat: 49.0457894, lng: 19.5124632 },
    { lat: 49.0457437, lng: 19.5125011 },
    { lat: 49.045765, lng: 19.5125696 },
    { lat: 49.0457479, lng: 19.512594 },
    { lat: 49.0454975, lng: 19.512762 },
    { lat: 49.0454308, lng: 19.5128783 },
    { lat: 49.0453877, lng: 19.5128696 },
    { lat: 49.0453098, lng: 19.5129155 },
    { lat: 49.0452883, lng: 19.5129408 },
    { lat: 49.0452015, lng: 19.5129906 },
    { lat: 49.0449468, lng: 19.5129615 },
    { lat: 49.0447585, lng: 19.5127357 },
    { lat: 49.0445284, lng: 19.5125381 },
    { lat: 49.0443285, lng: 19.5124526 },
    { lat: 49.0442394, lng: 19.5125752 },
    { lat: 49.0441657, lng: 19.5127385 },
    { lat: 49.0440497, lng: 19.5128869 },
    { lat: 49.0439176, lng: 19.5129314 },
    { lat: 49.0438786, lng: 19.5129621 },
    { lat: 49.043794, lng: 19.513109 },
    { lat: 49.0436996, lng: 19.5132393 },
    { lat: 49.0435598, lng: 19.5136313 },
    { lat: 49.0433186, lng: 19.5142229 },
    { lat: 49.0431084, lng: 19.5146754 },
    { lat: 49.0424011, lng: 19.5160634 },
    { lat: 49.0420473, lng: 19.5167527 },
    { lat: 49.041676, lng: 19.5175039 },
    { lat: 49.0414438, lng: 19.5180446 },
    { lat: 49.0412627, lng: 19.5190115 },
    { lat: 49.0411269, lng: 19.519616 },
    { lat: 49.0409624, lng: 19.5205324 },
    { lat: 49.0408979, lng: 19.5208721 },
    { lat: 49.0408298, lng: 19.5211293 },
    { lat: 49.0406994, lng: 19.5215572 },
    { lat: 49.0406525, lng: 19.5216678 },
    { lat: 49.0405745, lng: 19.5218832 },
    { lat: 49.0404709, lng: 19.5222771 },
    { lat: 49.0404701, lng: 19.5225084 },
    { lat: 49.040544, lng: 19.5227208 },
    { lat: 49.0408201, lng: 19.5231865 },
    { lat: 49.0408578, lng: 19.5233963 },
    { lat: 49.0409855, lng: 19.5240476 },
    { lat: 49.0410375, lng: 19.5247155 },
    { lat: 49.0410023, lng: 19.5246848 },
    { lat: 49.041004, lng: 19.5247478 },
    { lat: 49.0408278, lng: 19.5249388 },
    { lat: 49.040705, lng: 19.5249636 },
    { lat: 49.0406432, lng: 19.525089 },
    { lat: 49.040131, lng: 19.5253719 },
    { lat: 49.0400386, lng: 19.5255928 },
    { lat: 49.039889, lng: 19.525628 },
    { lat: 49.0396148, lng: 19.5253467 },
    { lat: 49.0395022, lng: 19.5253478 },
    { lat: 49.0393751, lng: 19.525279 },
    { lat: 49.039303, lng: 19.5254004 },
    { lat: 49.0390259, lng: 19.5252778 },
    { lat: 49.0388797, lng: 19.52536 },
    { lat: 49.0387848, lng: 19.5253305 },
    { lat: 49.0387547, lng: 19.5254616 },
    { lat: 49.0385544, lng: 19.5256149 },
    { lat: 49.03835, lng: 19.5256537 },
    { lat: 49.037988, lng: 19.525498 },
    { lat: 49.0379423, lng: 19.525463 },
    { lat: 49.0378978, lng: 19.5255743 },
    { lat: 49.0378069, lng: 19.5255333 },
    { lat: 49.0377029, lng: 19.5256452 },
    { lat: 49.0374352, lng: 19.525584 },
    { lat: 49.037413, lng: 19.5256887 },
    { lat: 49.0372212, lng: 19.5256615 },
    { lat: 49.0371331, lng: 19.5256077 },
    { lat: 49.036872, lng: 19.5256611 },
    { lat: 49.0366348, lng: 19.5256477 },
    { lat: 49.0364646, lng: 19.525819 },
    { lat: 49.0362494, lng: 19.5257976 },
    { lat: 49.036205, lng: 19.5258644 },
    { lat: 49.0361311, lng: 19.5257511 },
    { lat: 49.0358995, lng: 19.5256101 },
    { lat: 49.0356763, lng: 19.5256832 },
    { lat: 49.0355816, lng: 19.5256828 },
    { lat: 49.0355098, lng: 19.5257388 },
    { lat: 49.0350652, lng: 19.5253548 },
    { lat: 49.0348065, lng: 19.5252909 },
    { lat: 49.0346874, lng: 19.5251476 },
    { lat: 49.0345116, lng: 19.5251001 },
    { lat: 49.0343501, lng: 19.5250935 },
    { lat: 49.0342368, lng: 19.5251051 },
    { lat: 49.0340868, lng: 19.525052 },
    { lat: 49.0337121, lng: 19.5250111 },
    { lat: 49.0336225, lng: 19.524952 },
    { lat: 49.0334678, lng: 19.5250182 },
    { lat: 49.0331557, lng: 19.5248957 },
    { lat: 49.0331515, lng: 19.5248297 },
    { lat: 49.033145, lng: 19.5248049 },
    { lat: 49.0331015, lng: 19.524794 },
    { lat: 49.0330968, lng: 19.5247902 },
    { lat: 49.0330043, lng: 19.5248844 },
    { lat: 49.0328296, lng: 19.5248579 },
    { lat: 49.0328253, lng: 19.5247894 },
    { lat: 49.0327869, lng: 19.5247612 },
    { lat: 49.0326654, lng: 19.5248674 },
    { lat: 49.0324893, lng: 19.52491 },
    { lat: 49.0322949, lng: 19.5248454 },
    { lat: 49.0320061, lng: 19.5248389 },
    { lat: 49.0313378, lng: 19.5247134 },
    { lat: 49.0311099, lng: 19.5245845 },
    { lat: 49.0309339, lng: 19.5247259 },
    { lat: 49.0306859, lng: 19.5250972 },
    { lat: 49.0305459, lng: 19.5250147 },
    { lat: 49.0304669, lng: 19.5251368 },
    { lat: 49.0303392, lng: 19.5251776 },
    { lat: 49.0301907, lng: 19.5251319 },
    { lat: 49.0298061, lng: 19.5252224 },
    { lat: 49.0295883, lng: 19.5251664 },
    { lat: 49.0294816, lng: 19.5252243 },
    { lat: 49.0293361, lng: 19.5251977 },
    { lat: 49.0291271, lng: 19.5252079 },
    { lat: 49.0289212, lng: 19.5250684 },
    { lat: 49.0288671, lng: 19.5250149 },
    { lat: 49.0285422, lng: 19.52486 },
    { lat: 49.0285135, lng: 19.5249502 },
    { lat: 49.0283141, lng: 19.525046 },
    { lat: 49.028024, lng: 19.5249865 },
    { lat: 49.0278431, lng: 19.5247528 },
    { lat: 49.0275707, lng: 19.5245374 },
    { lat: 49.0275092, lng: 19.5243486 },
    { lat: 49.0272177, lng: 19.5241348 },
    { lat: 49.027109, lng: 19.5241477 },
    { lat: 49.0270837, lng: 19.5241881 },
    { lat: 49.0270947, lng: 19.5242063 },
    { lat: 49.0270796, lng: 19.5242697 },
    { lat: 49.0268914, lng: 19.5242425 },
    { lat: 49.0268496, lng: 19.5241442 },
    { lat: 49.0266797, lng: 19.5241216 },
    { lat: 49.0265807, lng: 19.523903 },
    { lat: 49.0265289, lng: 19.5239792 },
    { lat: 49.0264287, lng: 19.5238831 },
    { lat: 49.0263209, lng: 19.5238909 },
    { lat: 49.0261873, lng: 19.5238045 },
    { lat: 49.0260838, lng: 19.5238337 },
    { lat: 49.0258933, lng: 19.5238301 },
    { lat: 49.0258601, lng: 19.5236976 },
    { lat: 49.0256786, lng: 19.5235692 },
    { lat: 49.0255423, lng: 19.5234848 },
    { lat: 49.0252925, lng: 19.5234276 },
    { lat: 49.0251158, lng: 19.5233123 },
    { lat: 49.0250651, lng: 19.5232886 },
    { lat: 49.0249994, lng: 19.5231792 },
    { lat: 49.0249031, lng: 19.5231434 },
    { lat: 49.024809, lng: 19.5232086 },
    { lat: 49.0247593, lng: 19.5231273 },
    { lat: 49.0245741, lng: 19.5229501 },
    { lat: 49.0245318, lng: 19.5229865 },
    { lat: 49.0242653, lng: 19.5228739 },
    { lat: 49.0241355, lng: 19.5227465 },
    { lat: 49.0240526, lng: 19.5225586 },
    { lat: 49.023643, lng: 19.5222726 },
    { lat: 49.023611, lng: 19.5221909 },
    { lat: 49.0234441, lng: 19.5220634 },
    { lat: 49.0233545, lng: 19.521925 },
    { lat: 49.0232507, lng: 19.5219485 },
    { lat: 49.0230831, lng: 19.5219282 },
    { lat: 49.0229481, lng: 19.5219873 },
    { lat: 49.0228255, lng: 19.5219913 },
    { lat: 49.0227727, lng: 19.5219473 },
    { lat: 49.022672, lng: 19.5220118 },
    { lat: 49.0225941, lng: 19.5219346 },
    { lat: 49.0224382, lng: 19.5218975 },
    { lat: 49.0223289, lng: 19.5221191 },
    { lat: 49.0210937, lng: 19.5222377 },
    { lat: 49.0206667, lng: 19.5231513 },
    { lat: 49.02037, lng: 19.5236906 },
    { lat: 49.0203643, lng: 19.5237372 },
    { lat: 49.0201577, lng: 19.5242236 },
    { lat: 49.0197422, lng: 19.5251133 },
    { lat: 49.019729, lng: 19.5251445 },
    { lat: 49.0199557, lng: 19.5254246 },
    { lat: 49.0201184, lng: 19.5254828 },
    { lat: 49.020424, lng: 19.5252922 },
    { lat: 49.0204474, lng: 19.5253074 },
    { lat: 49.0205893, lng: 19.5253856 },
    { lat: 49.0207598, lng: 19.5252998 },
    { lat: 49.0209096, lng: 19.5255391 },
    { lat: 49.0209741, lng: 19.5258275 },
    { lat: 49.0210204, lng: 19.5260556 },
    { lat: 49.0210299, lng: 19.5261743 },
    { lat: 49.0212146, lng: 19.5261684 },
    { lat: 49.021346, lng: 19.526457 },
    { lat: 49.0214644, lng: 19.5265967 },
    { lat: 49.0217615, lng: 19.5268494 },
    { lat: 49.0218638, lng: 19.5270203 },
    { lat: 49.0220011, lng: 19.5271725 },
    { lat: 49.0220111, lng: 19.5272566 },
    { lat: 49.0220747, lng: 19.5273344 },
    { lat: 49.0221728, lng: 19.5276837 },
    { lat: 49.0222548, lng: 19.5278516 },
    { lat: 49.0223007, lng: 19.5278942 },
    { lat: 49.0224493, lng: 19.5279891 },
    { lat: 49.0225155, lng: 19.5281114 },
    { lat: 49.0224817, lng: 19.5284047 },
    { lat: 49.0224435, lng: 19.5288036 },
    { lat: 49.0224633, lng: 19.5291595 },
    { lat: 49.0225844, lng: 19.529444 },
    { lat: 49.022659, lng: 19.5295481 },
    { lat: 49.0228127, lng: 19.5295843 },
    { lat: 49.0230826, lng: 19.530019 },
    { lat: 49.0230887, lng: 19.5300543 },
    { lat: 49.0231393, lng: 19.5303466 },
    { lat: 49.0233037, lng: 19.530642 },
    { lat: 49.0236802, lng: 19.5314119 },
    { lat: 49.0238123, lng: 19.5314644 },
    { lat: 49.0239461, lng: 19.5314855 },
    { lat: 49.0240815, lng: 19.5315376 },
    { lat: 49.0244082, lng: 19.5317588 },
    { lat: 49.0248103, lng: 19.5322464 },
    { lat: 49.0252487, lng: 19.5323473 },
    { lat: 49.0255259, lng: 19.5328657 },
    { lat: 49.0260645, lng: 19.5334103 },
    { lat: 49.0262556, lng: 19.5337489 },
    { lat: 49.026492, lng: 19.5339408 },
    { lat: 49.0267387, lng: 19.5341877 },
    { lat: 49.0275371, lng: 19.5345484 },
    { lat: 49.0279462, lng: 19.5349703 },
    { lat: 49.0282657, lng: 19.5351535 },
    { lat: 49.0286088, lng: 19.5350911 },
    { lat: 49.0288146, lng: 19.5351829 },
    { lat: 49.0290046, lng: 19.5356929 },
    { lat: 49.0291558, lng: 19.5358461 },
    { lat: 49.029455, lng: 19.5361058 },
    { lat: 49.0295607, lng: 19.5363216 },
    { lat: 49.0296067, lng: 19.5365907 },
    { lat: 49.0297672, lng: 19.5369979 },
    { lat: 49.0298281, lng: 19.5370769 },
    { lat: 49.0304273, lng: 19.5374644 },
    { lat: 49.0307706, lng: 19.5377805 },
    { lat: 49.030965, lng: 19.5381385 },
    { lat: 49.0314101, lng: 19.5381653 },
    { lat: 49.0316674, lng: 19.5385939 },
    { lat: 49.0320912, lng: 19.5385945 },
    { lat: 49.0322624, lng: 19.5386407 },
    { lat: 49.0327035, lng: 19.5389002 },
    { lat: 49.0327745, lng: 19.5389768 },
    { lat: 49.0328334, lng: 19.5390837 },
    { lat: 49.0332077, lng: 19.5394576 },
    { lat: 49.0332375, lng: 19.5395941 },
    { lat: 49.0339414, lng: 19.540128 },
    { lat: 49.0342735, lng: 19.5400022 },
    { lat: 49.0347432, lng: 19.5402677 },
    { lat: 49.0349621, lng: 19.5403258 },
    { lat: 49.0351466, lng: 19.5402704 },
    { lat: 49.0357247, lng: 19.5405152 },
    { lat: 49.035835, lng: 19.5405887 },
    { lat: 49.0359485, lng: 19.5406342 },
    { lat: 49.0361115, lng: 19.5406253 },
    { lat: 49.03628, lng: 19.5405532 },
    { lat: 49.0364919, lng: 19.5403924 },
    { lat: 49.0367715, lng: 19.5404193 },
    { lat: 49.0370938, lng: 19.5406939 },
    { lat: 49.0372544, lng: 19.5407779 },
    { lat: 49.0374416, lng: 19.5407833 },
    { lat: 49.0375892, lng: 19.5407322 },
    { lat: 49.0378601, lng: 19.5405726 },
    { lat: 49.0380754, lng: 19.5406502 },
    { lat: 49.0381035, lng: 19.5408214 },
    { lat: 49.0381205, lng: 19.5408469 },
    { lat: 49.0381697, lng: 19.5409376 },
    { lat: 49.0379687, lng: 19.5411473 },
    { lat: 49.0374641, lng: 19.5414745 },
    { lat: 49.0373675, lng: 19.541583 },
    { lat: 49.0373936, lng: 19.5416115 },
    { lat: 49.0374162, lng: 19.5416357 },
    { lat: 49.037164, lng: 19.5419155 },
    { lat: 49.0368498, lng: 19.5422653 },
    { lat: 49.0368544, lng: 19.5422892 },
    { lat: 49.0367381, lng: 19.5424577 },
    { lat: 49.0366077, lng: 19.5427626 },
    { lat: 49.0365735, lng: 19.5428769 },
    { lat: 49.0365611, lng: 19.5433457 },
    { lat: 49.0365758, lng: 19.543773 },
    { lat: 49.0365639, lng: 19.5440565 },
    { lat: 49.0365296, lng: 19.5447159 },
    { lat: 49.0364166, lng: 19.545497 },
    { lat: 49.0362277, lng: 19.5460049 },
    { lat: 49.0360167, lng: 19.5463913 },
    { lat: 49.0357593, lng: 19.5470242 },
    { lat: 49.0356955, lng: 19.5473814 },
    { lat: 49.0356559, lng: 19.5482255 },
    { lat: 49.0360326, lng: 19.5482064 },
    { lat: 49.0362668, lng: 19.548153 },
    { lat: 49.0364647, lng: 19.5481904 },
    { lat: 49.0366298, lng: 19.5483056 },
    { lat: 49.0369422, lng: 19.5485345 },
    { lat: 49.0372293, lng: 19.5485554 },
    { lat: 49.0375715, lng: 19.5487704 },
    { lat: 49.0379631, lng: 19.5487835 },
    { lat: 49.0387457, lng: 19.5485008 },
    { lat: 49.0394167, lng: 19.5483645 },
    { lat: 49.0396618, lng: 19.5482508 },
    { lat: 49.0399958, lng: 19.5489332 },
    { lat: 49.0401625, lng: 19.5492307 },
    { lat: 49.0408648, lng: 19.5504495 },
    { lat: 49.0417535, lng: 19.5503956 },
    { lat: 49.0426536, lng: 19.5503387 },
    { lat: 49.0429042, lng: 19.5503774 },
    { lat: 49.0436024, lng: 19.5504408 },
    { lat: 49.0442624, lng: 19.5504584 },
    { lat: 49.0450809, lng: 19.5505699 },
    { lat: 49.0460013, lng: 19.5509861 },
    { lat: 49.046872, lng: 19.551058 },
    { lat: 49.0468434, lng: 19.5517169 },
    { lat: 49.0475212, lng: 19.5517768 },
    { lat: 49.047908, lng: 19.551762 },
    { lat: 49.0481953, lng: 19.5517606 },
    { lat: 49.048853, lng: 19.5520234 },
    { lat: 49.0495599, lng: 19.5521326 },
    { lat: 49.0499991, lng: 19.5525301 },
    { lat: 49.0500612, lng: 19.5522323 },
    { lat: 49.0503978, lng: 19.5527724 },
    { lat: 49.050825, lng: 19.5537193 },
    { lat: 49.0513489, lng: 19.5535159 },
    { lat: 49.0517952, lng: 19.5535427 },
    { lat: 49.0519373, lng: 19.553397 },
    { lat: 49.0522649, lng: 19.553045 },
    { lat: 49.0529453, lng: 19.5531635 },
    { lat: 49.0533629, lng: 19.5532296 },
    { lat: 49.0537014, lng: 19.5532892 },
    { lat: 49.0541534, lng: 19.5533431 },
    { lat: 49.0541341, lng: 19.5532933 },
    { lat: 49.0539607, lng: 19.5529484 },
    { lat: 49.0539172, lng: 19.5527579 },
    { lat: 49.0539071, lng: 19.5523587 },
    { lat: 49.0539076, lng: 19.5520745 },
    { lat: 49.0539321, lng: 19.5515934 },
    { lat: 49.0539295, lng: 19.551515 },
    { lat: 49.0539725, lng: 19.5510956 },
    { lat: 49.0540044, lng: 19.5505598 },
    { lat: 49.0541719, lng: 19.5487632 },
    { lat: 49.0542608, lng: 19.5482617 },
    { lat: 49.054399, lng: 19.5475358 },
    { lat: 49.0544547, lng: 19.5472224 },
    { lat: 49.0545219, lng: 19.5467947 },
    { lat: 49.0546482, lng: 19.546748 },
    { lat: 49.0547835, lng: 19.5467212 },
    { lat: 49.0549045, lng: 19.5466833 },
    { lat: 49.0548992, lng: 19.5462955 },
    { lat: 49.0550685, lng: 19.5462277 },
    { lat: 49.0551809, lng: 19.5461721 },
    { lat: 49.0550694, lng: 19.5446591 },
    { lat: 49.056103, lng: 19.5437467 },
    { lat: 49.0560844, lng: 19.5436858 },
    { lat: 49.0563606, lng: 19.5434929 },
    { lat: 49.0565009, lng: 19.5434052 },
    { lat: 49.056716, lng: 19.5432543 },
    { lat: 49.0573757, lng: 19.5428446 },
    { lat: 49.0578388, lng: 19.5425501 },
    { lat: 49.0578659, lng: 19.5425224 },
    { lat: 49.0578489, lng: 19.5425688 },
    { lat: 49.0587221, lng: 19.5420719 },
    { lat: 49.0597248, lng: 19.5415761 },
    { lat: 49.0605592, lng: 19.5411458 },
    { lat: 49.0608952, lng: 19.5409488 },
    { lat: 49.0612936, lng: 19.5407882 },
    { lat: 49.0613126, lng: 19.5425713 },
    { lat: 49.0634703, lng: 19.5414788 },
    { lat: 49.064457, lng: 19.5409991 },
    { lat: 49.0647484, lng: 19.5408918 },
    { lat: 49.0655241, lng: 19.5406807 },
    { lat: 49.06601, lng: 19.5399684 },
    { lat: 49.0661042, lng: 19.5397349 },
    { lat: 49.0665275, lng: 19.5384621 },
    { lat: 49.0665713, lng: 19.5383357 },
    { lat: 49.0665986, lng: 19.5381919 },
    { lat: 49.0666926, lng: 19.5375859 },
    { lat: 49.0666754, lng: 19.5371299 },
    { lat: 49.0666914, lng: 19.5368375 },
    { lat: 49.0667273, lng: 19.5365369 },
    { lat: 49.0667477, lng: 19.5363166 },
    { lat: 49.0667838, lng: 19.5360214 },
    { lat: 49.0667993, lng: 19.5357944 },
    { lat: 49.066803, lng: 19.5356543 },
    { lat: 49.0668731, lng: 19.5349638 },
    { lat: 49.0669238, lng: 19.5342972 },
    { lat: 49.0670149, lng: 19.5333989 },
    { lat: 49.0670146, lng: 19.5333925 },
    { lat: 49.0670231, lng: 19.5332852 },
    { lat: 49.0672129, lng: 19.5315623 },
    { lat: 49.0669398, lng: 19.5314808 },
    { lat: 49.0666167, lng: 19.5314387 },
    { lat: 49.0660211, lng: 19.531374 },
    { lat: 49.0658637, lng: 19.531433 },
    { lat: 49.0659045, lng: 19.5301713 },
    { lat: 49.0659025, lng: 19.5299577 },
    { lat: 49.0657974, lng: 19.5300756 },
    { lat: 49.0657128, lng: 19.5300737 },
    { lat: 49.0656003, lng: 19.5290356 },
    { lat: 49.0655853, lng: 19.5289549 },
    { lat: 49.0653346, lng: 19.5289921 },
    { lat: 49.0650531, lng: 19.528923 },
    { lat: 49.064793, lng: 19.528755 },
    { lat: 49.0646374, lng: 19.5287765 },
    { lat: 49.0645157, lng: 19.528951 },
    { lat: 49.0644504, lng: 19.5288749 },
    { lat: 49.0644556, lng: 19.5282481 },
    { lat: 49.0644117, lng: 19.5277306 },
    { lat: 49.0643268, lng: 19.5271071 },
    { lat: 49.0642343, lng: 19.5265844 },
    { lat: 49.0642055, lng: 19.526347 },
    { lat: 49.0641604, lng: 19.5258282 },
    { lat: 49.0640996, lng: 19.5252367 },
    { lat: 49.0640802, lng: 19.5250793 },
    { lat: 49.0640171, lng: 19.5247629 },
    { lat: 49.0641215, lng: 19.5246279 },
    { lat: 49.0641167, lng: 19.5239794 },
    { lat: 49.0641208, lng: 19.5234289 },
    { lat: 49.0641582, lng: 19.5229118 },
    { lat: 49.0641853, lng: 19.522642 },
    { lat: 49.0642054, lng: 19.5223941 },
    { lat: 49.0643254, lng: 19.5208237 },
    { lat: 49.0643332, lng: 19.5202544 },
    { lat: 49.0643323, lng: 19.5200647 },
    { lat: 49.0643332, lng: 19.5181605 },
    { lat: 49.0642953, lng: 19.5175993 },
    { lat: 49.0635818, lng: 19.5175437 },
    { lat: 49.0632394, lng: 19.5174962 },
    { lat: 49.0631906, lng: 19.5170741 },
    { lat: 49.06273, lng: 19.5171027 },
    { lat: 49.0620459, lng: 19.5170741 },
    { lat: 49.0610501, lng: 19.5169801 },
    { lat: 49.0610342, lng: 19.516879 },
    { lat: 49.0609868, lng: 19.5163594 },
    { lat: 49.0609582, lng: 19.5156352 },
    { lat: 49.0609891, lng: 19.5153499 },
    { lat: 49.0609834, lng: 19.514638 },
    { lat: 49.0610254, lng: 19.5139537 },
    { lat: 49.0611603, lng: 19.5133315 },
    { lat: 49.0612822, lng: 19.5128892 },
    { lat: 49.0613471, lng: 19.5126348 },
    { lat: 49.0615117, lng: 19.5119688 },
    { lat: 49.0616862, lng: 19.5114994 },
    { lat: 49.0616694, lng: 19.5114792 },
    { lat: 49.0615272, lng: 19.5111515 },
    { lat: 49.0613567, lng: 19.510724 },
    { lat: 49.0608543, lng: 19.5098154 },
    { lat: 49.0605972, lng: 19.5094916 },
    { lat: 49.0602921, lng: 19.5088362 },
    { lat: 49.0600289, lng: 19.5084976 },
    { lat: 49.0599233, lng: 19.5084078 },
    { lat: 49.0598783, lng: 19.5082858 },
    { lat: 49.0596786, lng: 19.5079839 },
    { lat: 49.0595541, lng: 19.5077499 },
    { lat: 49.0594385, lng: 19.5078092 },
    { lat: 49.0594209, lng: 19.5078481 },
    { lat: 49.0591285, lng: 19.5078349 },
    { lat: 49.0590797, lng: 19.5078006 },
    { lat: 49.0589501, lng: 19.5077762 },
    { lat: 49.0589316, lng: 19.5077175 },
    { lat: 49.0587954, lng: 19.507743 },
    { lat: 49.0582278, lng: 19.5079724 },
    { lat: 49.0572446, lng: 19.5080809 },
    { lat: 49.0566866, lng: 19.50825 },
    { lat: 49.0566645, lng: 19.5081844 },
    { lat: 49.0559802, lng: 19.5084477 },
    { lat: 49.0559364, lng: 19.508032 },
    { lat: 49.0558697, lng: 19.507266 },
    { lat: 49.0558018, lng: 19.5071338 },
  ],
  Trstené: [
    { lat: 49.101906, lng: 19.6201645 },
    { lat: 49.1020838, lng: 19.6206138 },
    { lat: 49.102448, lng: 19.6207436 },
    { lat: 49.1023946, lng: 19.6209846 },
    { lat: 49.1022215, lng: 19.6212741 },
    { lat: 49.1016023, lng: 19.6218874 },
    { lat: 49.1010864, lng: 19.6223182 },
    { lat: 49.1007094, lng: 19.6228042 },
    { lat: 49.100375, lng: 19.623215 },
    { lat: 49.1000519, lng: 19.6236236 },
    { lat: 49.1000206, lng: 19.6236782 },
    { lat: 49.0998778, lng: 19.6238858 },
    { lat: 49.0996815, lng: 19.6239834 },
    { lat: 49.0996562, lng: 19.6240716 },
    { lat: 49.0997071, lng: 19.6242012 },
    { lat: 49.0997816, lng: 19.6241266 },
    { lat: 49.0998078, lng: 19.6239825 },
    { lat: 49.0999599, lng: 19.6240574 },
    { lat: 49.1000639, lng: 19.6243206 },
    { lat: 49.1000476, lng: 19.6244574 },
    { lat: 49.1001594, lng: 19.6247662 },
    { lat: 49.1001148, lng: 19.6248762 },
    { lat: 49.1002428, lng: 19.6250409 },
    { lat: 49.1003883, lng: 19.6248219 },
    { lat: 49.1005382, lng: 19.6249466 },
    { lat: 49.10062, lng: 19.6252405 },
    { lat: 49.1006094, lng: 19.6253567 },
    { lat: 49.1004915, lng: 19.6253135 },
    { lat: 49.1004674, lng: 19.6253808 },
    { lat: 49.1006447, lng: 19.625618 },
    { lat: 49.1004705, lng: 19.6256501 },
    { lat: 49.1004392, lng: 19.6258054 },
    { lat: 49.1005448, lng: 19.6258499 },
    { lat: 49.1006898, lng: 19.6258194 },
    { lat: 49.1006044, lng: 19.625926 },
    { lat: 49.1006726, lng: 19.6260365 },
    { lat: 49.100619, lng: 19.6261525 },
    { lat: 49.1006942, lng: 19.6262195 },
    { lat: 49.100809, lng: 19.6261144 },
    { lat: 49.1008822, lng: 19.626214 },
    { lat: 49.1009326, lng: 19.6263335 },
    { lat: 49.1010112, lng: 19.6265551 },
    { lat: 49.1010397, lng: 19.6266647 },
    { lat: 49.1011514, lng: 19.6267175 },
    { lat: 49.1011164, lng: 19.6268434 },
    { lat: 49.1010922, lng: 19.6268829 },
    { lat: 49.1010867, lng: 19.6270867 },
    { lat: 49.1011458, lng: 19.627146 },
    { lat: 49.1012015, lng: 19.6273358 },
    { lat: 49.1013002, lng: 19.627299 },
    { lat: 49.1014346, lng: 19.6273336 },
    { lat: 49.1016957, lng: 19.6273475 },
    { lat: 49.1017116, lng: 19.6275817 },
    { lat: 49.1018047, lng: 19.6275695 },
    { lat: 49.1018978, lng: 19.6276868 },
    { lat: 49.1018345, lng: 19.6278343 },
    { lat: 49.1018618, lng: 19.6279153 },
    { lat: 49.1019447, lng: 19.6279541 },
    { lat: 49.1021153, lng: 19.6278142 },
    { lat: 49.1021587, lng: 19.6279291 },
    { lat: 49.1022062, lng: 19.6279831 },
    { lat: 49.1023211, lng: 19.6280079 },
    { lat: 49.1023512, lng: 19.6280734 },
    { lat: 49.1023304, lng: 19.6281162 },
    { lat: 49.1023681, lng: 19.6282245 },
    { lat: 49.1024602, lng: 19.6283214 },
    { lat: 49.1025277, lng: 19.6282932 },
    { lat: 49.1026817, lng: 19.6283373 },
    { lat: 49.1027167, lng: 19.6284127 },
    { lat: 49.1026603, lng: 19.6287418 },
    { lat: 49.1026832, lng: 19.6288531 },
    { lat: 49.1026574, lng: 19.6289773 },
    { lat: 49.1026941, lng: 19.6291961 },
    { lat: 49.1027279, lng: 19.6292706 },
    { lat: 49.1027675, lng: 19.6292755 },
    { lat: 49.1027787, lng: 19.629325 },
    { lat: 49.1028066, lng: 19.6293682 },
    { lat: 49.1028373, lng: 19.6294006 },
    { lat: 49.1030004, lng: 19.6294682 },
    { lat: 49.1030722, lng: 19.6295629 },
    { lat: 49.1029586, lng: 19.6296958 },
    { lat: 49.1030457, lng: 19.6300007 },
    { lat: 49.1029585, lng: 19.6300202 },
    { lat: 49.102866, lng: 19.6301426 },
    { lat: 49.1028057, lng: 19.6302046 },
    { lat: 49.1029134, lng: 19.6304477 },
    { lat: 49.1030791, lng: 19.6303003 },
    { lat: 49.1032028, lng: 19.6304407 },
    { lat: 49.1033647, lng: 19.6308155 },
    { lat: 49.1034968, lng: 19.6308194 },
    { lat: 49.1040311, lng: 19.6313045 },
    { lat: 49.104546, lng: 19.6317323 },
    { lat: 49.1045547, lng: 19.6317533 },
    { lat: 49.1046108, lng: 19.6322528 },
    { lat: 49.1049864, lng: 19.6324648 },
    { lat: 49.1051669, lng: 19.6325191 },
    { lat: 49.1055302, lng: 19.6327314 },
    { lat: 49.1061345, lng: 19.6331198 },
    { lat: 49.1063352, lng: 19.6333475 },
    { lat: 49.1068449, lng: 19.6334633 },
    { lat: 49.1068901, lng: 19.6333648 },
    { lat: 49.1069033, lng: 19.6333555 },
    { lat: 49.1072959, lng: 19.6336723 },
    { lat: 49.107478, lng: 19.6336092 },
    { lat: 49.107489, lng: 19.6333527 },
    { lat: 49.1075081, lng: 19.6333256 },
    { lat: 49.1080827, lng: 19.6333998 },
    { lat: 49.1081108, lng: 19.6330708 },
    { lat: 49.1081272, lng: 19.6330633 },
    { lat: 49.108307, lng: 19.6333047 },
    { lat: 49.1085437, lng: 19.6332011 },
    { lat: 49.1086718, lng: 19.6330727 },
    { lat: 49.1088033, lng: 19.6328876 },
    { lat: 49.1089154, lng: 19.6327972 },
    { lat: 49.1090092, lng: 19.6327558 },
    { lat: 49.109196, lng: 19.6327742 },
    { lat: 49.1092045, lng: 19.6327881 },
    { lat: 49.109207, lng: 19.6328925 },
    { lat: 49.1093233, lng: 19.6329272 },
    { lat: 49.1093736, lng: 19.6328159 },
    { lat: 49.10935, lng: 19.6326626 },
    { lat: 49.1093595, lng: 19.6326483 },
    { lat: 49.1094488, lng: 19.6326567 },
    { lat: 49.1094585, lng: 19.6326715 },
    { lat: 49.1094578, lng: 19.632736 },
    { lat: 49.1094784, lng: 19.6327425 },
    { lat: 49.1096125, lng: 19.6326901 },
    { lat: 49.1096192, lng: 19.6326919 },
    { lat: 49.1097507, lng: 19.632834 },
    { lat: 49.109888, lng: 19.6327788 },
    { lat: 49.1098959, lng: 19.6327816 },
    { lat: 49.1100072, lng: 19.6329268 },
    { lat: 49.1101024, lng: 19.6329393 },
    { lat: 49.1101318, lng: 19.6329136 },
    { lat: 49.1101383, lng: 19.6329122 },
    { lat: 49.1101853, lng: 19.6329289 },
    { lat: 49.1102599, lng: 19.6329345 },
    { lat: 49.1102956, lng: 19.6327499 },
    { lat: 49.1103054, lng: 19.6327406 },
    { lat: 49.1104018, lng: 19.6327813 },
    { lat: 49.1105524, lng: 19.6327948 },
    { lat: 49.1107426, lng: 19.6326924 },
    { lat: 49.1108668, lng: 19.6327263 },
    { lat: 49.1109632, lng: 19.6327372 },
    { lat: 49.1110286, lng: 19.6327901 },
    { lat: 49.1111446, lng: 19.6327867 },
    { lat: 49.1113348, lng: 19.6328328 },
    { lat: 49.111452, lng: 19.6327826 },
    { lat: 49.1114942, lng: 19.6326703 },
    { lat: 49.1116778, lng: 19.6325927 },
    { lat: 49.1118367, lng: 19.6325683 },
    { lat: 49.1119842, lng: 19.6327202 },
    { lat: 49.112056, lng: 19.6326365 },
    { lat: 49.1121126, lng: 19.6327404 },
    { lat: 49.1122367, lng: 19.6328976 },
    { lat: 49.1122938, lng: 19.6329385 },
    { lat: 49.1123802, lng: 19.6328806 },
    { lat: 49.1124777, lng: 19.6329439 },
    { lat: 49.1127082, lng: 19.6330092 },
    { lat: 49.1127941, lng: 19.6330449 },
    { lat: 49.1128567, lng: 19.633156 },
    { lat: 49.1129743, lng: 19.6333154 },
    { lat: 49.1130495, lng: 19.6334396 },
    { lat: 49.1131491, lng: 19.6335246 },
    { lat: 49.1133148, lng: 19.6337027 },
    { lat: 49.1134039, lng: 19.6337801 },
    { lat: 49.1135078, lng: 19.6339381 },
    { lat: 49.1136382, lng: 19.6340306 },
    { lat: 49.1138147, lng: 19.6342238 },
    { lat: 49.113903, lng: 19.6343071 },
    { lat: 49.1140411, lng: 19.6344518 },
    { lat: 49.1142041, lng: 19.6346705 },
    { lat: 49.1143236, lng: 19.6347246 },
    { lat: 49.1143463, lng: 19.6348244 },
    { lat: 49.1144852, lng: 19.6349111 },
    { lat: 49.1145251, lng: 19.6350217 },
    { lat: 49.1151005, lng: 19.6360228 },
    { lat: 49.1152006, lng: 19.636144 },
    { lat: 49.1153137, lng: 19.6362557 },
    { lat: 49.1154713, lng: 19.6365084 },
    { lat: 49.1156136, lng: 19.6368393 },
    { lat: 49.1156678, lng: 19.6369959 },
    { lat: 49.1157395, lng: 19.6372392 },
    { lat: 49.1159179, lng: 19.6376545 },
    { lat: 49.1159673, lng: 19.6378784 },
    { lat: 49.116069, lng: 19.6380089 },
    { lat: 49.1161692, lng: 19.6381863 },
    { lat: 49.1162113, lng: 19.6383733 },
    { lat: 49.116343, lng: 19.6387758 },
    { lat: 49.1165043, lng: 19.6390047 },
    { lat: 49.1166835, lng: 19.6398659 },
    { lat: 49.1168217, lng: 19.6401063 },
    { lat: 49.1168836, lng: 19.6402819 },
    { lat: 49.1169106, lng: 19.6403064 },
    { lat: 49.1174432, lng: 19.6410102 },
    { lat: 49.1177721, lng: 19.6414391 },
    { lat: 49.1179751, lng: 19.6416181 },
    { lat: 49.1185051, lng: 19.6417298 },
    { lat: 49.1194258, lng: 19.641813 },
    { lat: 49.119595, lng: 19.641816 },
    { lat: 49.1203364, lng: 19.6417393 },
    { lat: 49.1206679, lng: 19.6417244 },
    { lat: 49.1208336, lng: 19.6417774 },
    { lat: 49.1214337, lng: 19.6419186 },
    { lat: 49.1217713, lng: 19.6420355 },
    { lat: 49.1221208, lng: 19.6422439 },
    { lat: 49.1226879, lng: 19.6425572 },
    { lat: 49.1231013, lng: 19.6428339 },
    { lat: 49.1236459, lng: 19.6432175 },
    { lat: 49.1242331, lng: 19.6436277 },
    { lat: 49.1251801, lng: 19.6442017 },
    { lat: 49.1255622, lng: 19.6425614 },
    { lat: 49.127397, lng: 19.6433329 },
    { lat: 49.1288299, lng: 19.6435854 },
    { lat: 49.1303416, lng: 19.6436547 },
    { lat: 49.1301327, lng: 19.6455384 },
    { lat: 49.1300851, lng: 19.6467219 },
    { lat: 49.1310064, lng: 19.6469681 },
    { lat: 49.1303998, lng: 19.6432126 },
    { lat: 49.1276232, lng: 19.6424439 },
    { lat: 49.128928, lng: 19.6383531 },
    { lat: 49.1289707, lng: 19.638199 },
    { lat: 49.128786, lng: 19.6367302 },
    { lat: 49.1297311, lng: 19.631933 },
    { lat: 49.1297403, lng: 19.631858 },
    { lat: 49.128145, lng: 19.6314071 },
    { lat: 49.1257766, lng: 19.6308471 },
    { lat: 49.1259371, lng: 19.6277068 },
    { lat: 49.1269602, lng: 19.6281641 },
    { lat: 49.127434, lng: 19.6258546 },
    { lat: 49.1266505, lng: 19.6252923 },
    { lat: 49.1248616, lng: 19.6252438 },
    { lat: 49.1200762, lng: 19.6243274 },
    { lat: 49.1191915, lng: 19.6242462 },
    { lat: 49.1175762, lng: 19.6223151 },
    { lat: 49.1165714, lng: 19.6199517 },
    { lat: 49.1164974, lng: 19.6198335 },
    { lat: 49.1161092, lng: 19.619922 },
    { lat: 49.1146747, lng: 19.61901 },
    { lat: 49.1137185, lng: 19.6171531 },
    { lat: 49.1120272, lng: 19.615061 },
    { lat: 49.1119537, lng: 19.6152601 },
    { lat: 49.1117309, lng: 19.6151376 },
    { lat: 49.1107013, lng: 19.6145615 },
    { lat: 49.1092016, lng: 19.6125737 },
    { lat: 49.1078459, lng: 19.610083 },
    { lat: 49.1074405, lng: 19.6075719 },
    { lat: 49.1063639, lng: 19.608086 },
    { lat: 49.1059435, lng: 19.6084368 },
    { lat: 49.1058433, lng: 19.6085401 },
    { lat: 49.105624, lng: 19.6086977 },
    { lat: 49.1054125, lng: 19.6089039 },
    { lat: 49.1050966, lng: 19.6091726 },
    { lat: 49.1050349, lng: 19.6090762 },
    { lat: 49.1045357, lng: 19.6097056 },
    { lat: 49.1045137, lng: 19.6097407 },
    { lat: 49.1045976, lng: 19.6099009 },
    { lat: 49.104515, lng: 19.6104234 },
    { lat: 49.1044242, lng: 19.611111 },
    { lat: 49.1044153, lng: 19.6112166 },
    { lat: 49.1041977, lng: 19.6139254 },
    { lat: 49.1041932, lng: 19.6140269 },
    { lat: 49.1042123, lng: 19.6141996 },
    { lat: 49.1046365, lng: 19.6189148 },
    { lat: 49.1042352, lng: 19.6192171 },
    { lat: 49.1043263, lng: 19.6197399 },
    { lat: 49.101906, lng: 19.6201645 },
  ],
  Vavrišovo: [
    { lat: 49.0839227, lng: 19.7932102 },
    { lat: 49.083972, lng: 19.7926306 },
    { lat: 49.0841501, lng: 19.7906817 },
    { lat: 49.0841959, lng: 19.7903375 },
    { lat: 49.0843212, lng: 19.7895641 },
    { lat: 49.0843608, lng: 19.7893337 },
    { lat: 49.0845644, lng: 19.7886207 },
    { lat: 49.0847725, lng: 19.788097 },
    { lat: 49.0849163, lng: 19.7876718 },
    { lat: 49.0850405, lng: 19.7872855 },
    { lat: 49.0850898, lng: 19.7870991 },
    { lat: 49.0851313, lng: 19.7868602 },
    { lat: 49.0852396, lng: 19.7865539 },
    { lat: 49.0854516, lng: 19.7858552 },
    { lat: 49.0855356, lng: 19.7853748 },
    { lat: 49.0857827, lng: 19.7843735 },
    { lat: 49.0859678, lng: 19.7840408 },
    { lat: 49.086085, lng: 19.7838367 },
    { lat: 49.0862759, lng: 19.7834808 },
    { lat: 49.0864692, lng: 19.7831598 },
    { lat: 49.086829, lng: 19.7825727 },
    { lat: 49.0869847, lng: 19.7822429 },
    { lat: 49.0870605, lng: 19.7820699 },
    { lat: 49.0872155, lng: 19.7817686 },
    { lat: 49.0874102, lng: 19.7814022 },
    { lat: 49.087721, lng: 19.7807739 },
    { lat: 49.088052, lng: 19.7801232 },
    { lat: 49.0881362, lng: 19.7799637 },
    { lat: 49.0886247, lng: 19.779171 },
    { lat: 49.0887521, lng: 19.7789676 },
    { lat: 49.0888413, lng: 19.7788149 },
    { lat: 49.088974, lng: 19.7784989 },
    { lat: 49.0893993, lng: 19.7775015 },
    { lat: 49.0896765, lng: 19.7767745 },
    { lat: 49.0899995, lng: 19.7758854 },
    { lat: 49.090129, lng: 19.7755719 },
    { lat: 49.0903752, lng: 19.7749418 },
    { lat: 49.090613, lng: 19.7744761 },
    { lat: 49.0912292, lng: 19.7732279 },
    { lat: 49.091383, lng: 19.7729047 },
    { lat: 49.091644, lng: 19.772404 },
    { lat: 49.0917655, lng: 19.7722231 },
    { lat: 49.0918731, lng: 19.7720261 },
    { lat: 49.0920466, lng: 19.7717638 },
    { lat: 49.0921244, lng: 19.7716658 },
    { lat: 49.0923178, lng: 19.7713731 },
    { lat: 49.0926768, lng: 19.7707147 },
    { lat: 49.0930119, lng: 19.7701036 },
    { lat: 49.0931729, lng: 19.769637 },
    { lat: 49.0932593, lng: 19.7693986 },
    { lat: 49.0933902, lng: 19.7691679 },
    { lat: 49.0935552, lng: 19.7688943 },
    { lat: 49.0935839, lng: 19.768675 },
    { lat: 49.0936475, lng: 19.7682703 },
    { lat: 49.0939261, lng: 19.7663611 },
    { lat: 49.0939949, lng: 19.7658973 },
    { lat: 49.0940931, lng: 19.7652033 },
    { lat: 49.0942068, lng: 19.7644244 },
    { lat: 49.0943415, lng: 19.7634841 },
    { lat: 49.0945121, lng: 19.7622774 },
    { lat: 49.0946784, lng: 19.7611261 },
    { lat: 49.0947225, lng: 19.760746 },
    { lat: 49.094883, lng: 19.7596452 },
    { lat: 49.0948743, lng: 19.7596238 },
    { lat: 49.0941933, lng: 19.7581117 },
    { lat: 49.0939153, lng: 19.7574974 },
    { lat: 49.0934761, lng: 19.756518 },
    { lat: 49.0932718, lng: 19.7560458 },
    { lat: 49.0931313, lng: 19.7557415 },
    { lat: 49.0929799, lng: 19.7554463 },
    { lat: 49.0928761, lng: 19.7552105 },
    { lat: 49.0927005, lng: 19.7548275 },
    { lat: 49.0923113, lng: 19.7539628 },
    { lat: 49.0916929, lng: 19.7525936 },
    { lat: 49.0911687, lng: 19.7514504 },
    { lat: 49.0909604, lng: 19.7509877 },
    { lat: 49.0907874, lng: 19.7506112 },
    { lat: 49.0903658, lng: 19.7496744 },
    { lat: 49.0898116, lng: 19.7484375 },
    { lat: 49.0893654, lng: 19.7474806 },
    { lat: 49.0893246, lng: 19.7473729 },
    { lat: 49.0891867, lng: 19.7465606 },
    { lat: 49.0879293, lng: 19.745835 },
    { lat: 49.0869237, lng: 19.7452514 },
    { lat: 49.084305, lng: 19.7437459 },
    { lat: 49.0799324, lng: 19.7412322 },
    { lat: 49.0798929, lng: 19.7412093 },
    { lat: 49.0759622, lng: 19.7392456 },
    { lat: 49.0750316, lng: 19.7386802 },
    { lat: 49.0729765, lng: 19.7376963 },
    { lat: 49.0717539, lng: 19.737253 },
    { lat: 49.0717817, lng: 19.7375502 },
    { lat: 49.0718521, lng: 19.7387478 },
    { lat: 49.0718936, lng: 19.7398259 },
    { lat: 49.0719143, lng: 19.7405054 },
    { lat: 49.0719259, lng: 19.7408244 },
    { lat: 49.0718208, lng: 19.7411739 },
    { lat: 49.0715842, lng: 19.7419211 },
    { lat: 49.071485, lng: 19.7422549 },
    { lat: 49.0712774, lng: 19.7429709 },
    { lat: 49.0712243, lng: 19.743144 },
    { lat: 49.0710862, lng: 19.7436197 },
    { lat: 49.0710365, lng: 19.7437743 },
    { lat: 49.0710505, lng: 19.7450243 },
    { lat: 49.0710588, lng: 19.745884 },
    { lat: 49.0708038, lng: 19.746876 },
    { lat: 49.0706084, lng: 19.7470991 },
    { lat: 49.0704347, lng: 19.747273 },
    { lat: 49.0700404, lng: 19.7475864 },
    { lat: 49.069485, lng: 19.7480198 },
    { lat: 49.0693719, lng: 19.7480625 },
    { lat: 49.0689007, lng: 19.747955 },
    { lat: 49.0686017, lng: 19.7478383 },
    { lat: 49.0684296, lng: 19.7477666 },
    { lat: 49.0679988, lng: 19.7475993 },
    { lat: 49.067407, lng: 19.7476969 },
    { lat: 49.0672338, lng: 19.747731 },
    { lat: 49.0672111, lng: 19.7477257 },
    { lat: 49.0671131, lng: 19.7477532 },
    { lat: 49.0664317, lng: 19.7475768 },
    { lat: 49.0659478, lng: 19.7473301 },
    { lat: 49.0658404, lng: 19.7472709 },
    { lat: 49.0658338, lng: 19.7472752 },
    { lat: 49.0658228, lng: 19.7472515 },
    { lat: 49.0658355, lng: 19.7472071 },
    { lat: 49.0658132, lng: 19.7471393 },
    { lat: 49.0657887, lng: 19.7471199 },
    { lat: 49.065682, lng: 19.7470976 },
    { lat: 49.0655383, lng: 19.7471089 },
    { lat: 49.065301, lng: 19.7471189 },
    { lat: 49.065258, lng: 19.7470647 },
    { lat: 49.0652464, lng: 19.7470307 },
    { lat: 49.0652353, lng: 19.7469553 },
    { lat: 49.0652731, lng: 19.7468164 },
    { lat: 49.0652684, lng: 19.7467619 },
    { lat: 49.0652304, lng: 19.7466382 },
    { lat: 49.0651702, lng: 19.7465246 },
    { lat: 49.0651474, lng: 19.746495 },
    { lat: 49.0650193, lng: 19.7464206 },
    { lat: 49.0649676, lng: 19.7463727 },
    { lat: 49.064919, lng: 19.7462648 },
    { lat: 49.0648939, lng: 19.7460802 },
    { lat: 49.0648779, lng: 19.7460222 },
    { lat: 49.0647489, lng: 19.7458567 },
    { lat: 49.0647159, lng: 19.7457982 },
    { lat: 49.0646622, lng: 19.745596 },
    { lat: 49.0646383, lng: 19.7455682 },
    { lat: 49.0645262, lng: 19.7455516 },
    { lat: 49.06446, lng: 19.7455611 },
    { lat: 49.0644281, lng: 19.7455759 },
    { lat: 49.064292, lng: 19.7456866 },
    { lat: 49.064261, lng: 19.745675 },
    { lat: 49.0642311, lng: 19.7456339 },
    { lat: 49.0642232, lng: 19.7455822 },
    { lat: 49.0642352, lng: 19.7455422 },
    { lat: 49.064251, lng: 19.7455216 },
    { lat: 49.064321, lng: 19.7454703 },
    { lat: 49.064361, lng: 19.7454073 },
    { lat: 49.0643598, lng: 19.7453536 },
    { lat: 49.0643419, lng: 19.7453044 },
    { lat: 49.0643101, lng: 19.7452476 },
    { lat: 49.0642866, lng: 19.7452288 },
    { lat: 49.0642168, lng: 19.7452039 },
    { lat: 49.064191, lng: 19.745228 },
    { lat: 49.0641799, lng: 19.7452618 },
    { lat: 49.0641578, lng: 19.7454516 },
    { lat: 49.0641418, lng: 19.7454964 },
    { lat: 49.0641088, lng: 19.7455417 },
    { lat: 49.0640611, lng: 19.745557 },
    { lat: 49.0640247, lng: 19.7455229 },
    { lat: 49.0639973, lng: 19.7454628 },
    { lat: 49.0639038, lng: 19.7451835 },
    { lat: 49.0638615, lng: 19.7451382 },
    { lat: 49.0637091, lng: 19.7450049 },
    { lat: 49.0636866, lng: 19.7450028 },
    { lat: 49.0636659, lng: 19.7450215 },
    { lat: 49.0636553, lng: 19.7450879 },
    { lat: 49.0636113, lng: 19.7451667 },
    { lat: 49.0634929, lng: 19.7452922 },
    { lat: 49.0634223, lng: 19.7454808 },
    { lat: 49.0633595, lng: 19.7455888 },
    { lat: 49.0633386, lng: 19.7456021 },
    { lat: 49.0631881, lng: 19.7455828 },
    { lat: 49.0630495, lng: 19.7455336 },
    { lat: 49.0628203, lng: 19.7454153 },
    { lat: 49.062664, lng: 19.7454261 },
    { lat: 49.0625298, lng: 19.7454986 },
    { lat: 49.0623682, lng: 19.7454855 },
    { lat: 49.0622053, lng: 19.7454219 },
    { lat: 49.0621175, lng: 19.7454487 },
    { lat: 49.0620957, lng: 19.7454901 },
    { lat: 49.062054, lng: 19.7455396 },
    { lat: 49.0619788, lng: 19.7455756 },
    { lat: 49.0619271, lng: 19.745574 },
    { lat: 49.0618285, lng: 19.7455387 },
    { lat: 49.0616527, lng: 19.7460749 },
    { lat: 49.061442, lng: 19.7466943 },
    { lat: 49.0612836, lng: 19.7471687 },
    { lat: 49.0611505, lng: 19.7470892 },
    { lat: 49.0609671, lng: 19.7469648 },
    { lat: 49.0607354, lng: 19.7468179 },
    { lat: 49.0606163, lng: 19.7471335 },
    { lat: 49.0605416, lng: 19.7473584 },
    { lat: 49.0605757, lng: 19.7480346 },
    { lat: 49.0605936, lng: 19.7482139 },
    { lat: 49.0606594, lng: 19.7486868 },
    { lat: 49.0606605, lng: 19.7486906 },
    { lat: 49.0608144, lng: 19.749862 },
    { lat: 49.06073, lng: 19.7499205 },
    { lat: 49.0608805, lng: 19.7509161 },
    { lat: 49.0609129, lng: 19.751141 },
    { lat: 49.061254, lng: 19.7525305 },
    { lat: 49.0604673, lng: 19.7531176 },
    { lat: 49.0597151, lng: 19.7536401 },
    { lat: 49.0594428, lng: 19.7538261 },
    { lat: 49.0593665, lng: 19.7538855 },
    { lat: 49.0596609, lng: 19.754923 },
    { lat: 49.0597322, lng: 19.755168 },
    { lat: 49.0599556, lng: 19.7559002 },
    { lat: 49.06005, lng: 19.7562021 },
    { lat: 49.0602836, lng: 19.7568815 },
    { lat: 49.0598118, lng: 19.7572733 },
    { lat: 49.0596565, lng: 19.7574312 },
    { lat: 49.0593333, lng: 19.7577728 },
    { lat: 49.0589885, lng: 19.7580827 },
    { lat: 49.0586309, lng: 19.7583556 },
    { lat: 49.0566894, lng: 19.7605678 },
    { lat: 49.0569127, lng: 19.7608326 },
    { lat: 49.0569665, lng: 19.7609037 },
    { lat: 49.0569875, lng: 19.7609177 },
    { lat: 49.0570803, lng: 19.7608218 },
    { lat: 49.0574681, lng: 19.761037 },
    { lat: 49.0575861, lng: 19.7611066 },
    { lat: 49.057683, lng: 19.7611367 },
    { lat: 49.0579062, lng: 19.7610373 },
    { lat: 49.0580893, lng: 19.7610224 },
    { lat: 49.0583703, lng: 19.7610853 },
    { lat: 49.0587513, lng: 19.7612477 },
    { lat: 49.05916, lng: 19.7614971 },
    { lat: 49.0593681, lng: 19.7617771 },
    { lat: 49.0594938, lng: 19.7620985 },
    { lat: 49.059595, lng: 19.762612 },
    { lat: 49.0598066, lng: 19.763308 },
    { lat: 49.0599899, lng: 19.7637652 },
    { lat: 49.0601777, lng: 19.764044 },
    { lat: 49.0602381, lng: 19.7640825 },
    { lat: 49.0603894, lng: 19.7642508 },
    { lat: 49.0606246, lng: 19.7645004 },
    { lat: 49.0607785, lng: 19.7646731 },
    { lat: 49.0609342, lng: 19.7647325 },
    { lat: 49.0614376, lng: 19.7649354 },
    { lat: 49.0617233, lng: 19.7649741 },
    { lat: 49.0619444, lng: 19.7651602 },
    { lat: 49.0619431, lng: 19.7654197 },
    { lat: 49.0620867, lng: 19.7655826 },
    { lat: 49.0623459, lng: 19.7658223 },
    { lat: 49.062408, lng: 19.7658481 },
    { lat: 49.0624363, lng: 19.765621 },
    { lat: 49.0625597, lng: 19.7659445 },
    { lat: 49.0626825, lng: 19.7661735 },
    { lat: 49.0627113, lng: 19.7663466 },
    { lat: 49.0626904, lng: 19.7669038 },
    { lat: 49.0626217, lng: 19.7671568 },
    { lat: 49.0626469, lng: 19.7674819 },
    { lat: 49.0629905, lng: 19.7685911 },
    { lat: 49.0630615, lng: 19.7688286 },
    { lat: 49.0632435, lng: 19.7690229 },
    { lat: 49.0633425, lng: 19.7690488 },
    { lat: 49.0635013, lng: 19.7691219 },
    { lat: 49.0636623, lng: 19.7693288 },
    { lat: 49.0636867, lng: 19.7693684 },
    { lat: 49.0638259, lng: 19.7695408 },
    { lat: 49.0640604, lng: 19.7697266 },
    { lat: 49.0641365, lng: 19.7697948 },
    { lat: 49.064554, lng: 19.7703009 },
    { lat: 49.0648534, lng: 19.7706555 },
    { lat: 49.0648813, lng: 19.7708586 },
    { lat: 49.0647679, lng: 19.7708129 },
    { lat: 49.0649286, lng: 19.7714513 },
    { lat: 49.0649768, lng: 19.7718872 },
    { lat: 49.0650209, lng: 19.7723304 },
    { lat: 49.0652477, lng: 19.7726287 },
    { lat: 49.0653714, lng: 19.7727485 },
    { lat: 49.066257, lng: 19.7735819 },
    { lat: 49.0663767, lng: 19.7736967 },
    { lat: 49.0662877, lng: 19.7737987 },
    { lat: 49.0663532, lng: 19.7739322 },
    { lat: 49.0663724, lng: 19.7740104 },
    { lat: 49.0664091, lng: 19.7740782 },
    { lat: 49.066494, lng: 19.7741911 },
    { lat: 49.0666439, lng: 19.7742942 },
    { lat: 49.0667654, lng: 19.7743186 },
    { lat: 49.0670744, lng: 19.7742746 },
    { lat: 49.0672591, lng: 19.7742714 },
    { lat: 49.067329, lng: 19.7743003 },
    { lat: 49.0677131, lng: 19.7746856 },
    { lat: 49.0679947, lng: 19.7748642 },
    { lat: 49.068138, lng: 19.774849 },
    { lat: 49.0682943, lng: 19.7746864 },
    { lat: 49.0684434, lng: 19.7749006 },
    { lat: 49.0686538, lng: 19.7754636 },
    { lat: 49.0686932, lng: 19.7757776 },
    { lat: 49.0687422, lng: 19.7761291 },
    { lat: 49.0687534, lng: 19.7765656 },
    { lat: 49.0687605, lng: 19.7770125 },
    { lat: 49.0687725, lng: 19.7775204 },
    { lat: 49.0687897, lng: 19.778021 },
    { lat: 49.0690107, lng: 19.7787015 },
    { lat: 49.0692016, lng: 19.7788968 },
    { lat: 49.0695184, lng: 19.779212 },
    { lat: 49.0696497, lng: 19.7794345 },
    { lat: 49.0696909, lng: 19.7796545 },
    { lat: 49.0696857, lng: 19.7797212 },
    { lat: 49.0695404, lng: 19.779951 },
    { lat: 49.0695792, lng: 19.7802737 },
    { lat: 49.0696424, lng: 19.780305 },
    { lat: 49.0697544, lng: 19.7804996 },
    { lat: 49.0698376, lng: 19.7806257 },
    { lat: 49.0701634, lng: 19.7809651 },
    { lat: 49.0703156, lng: 19.7810504 },
    { lat: 49.0703329, lng: 19.7813171 },
    { lat: 49.0703364, lng: 19.7817076 },
    { lat: 49.0703815, lng: 19.7822032 },
    { lat: 49.0705384, lng: 19.7826754 },
    { lat: 49.0706024, lng: 19.783269 },
    { lat: 49.0710518, lng: 19.7843847 },
    { lat: 49.0711796, lng: 19.7847064 },
    { lat: 49.0712564, lng: 19.7850792 },
    { lat: 49.0715424, lng: 19.7854916 },
    { lat: 49.0715682, lng: 19.7857488 },
    { lat: 49.0716445, lng: 19.7859718 },
    { lat: 49.0717477, lng: 19.7862518 },
    { lat: 49.0719955, lng: 19.7865057 },
    { lat: 49.0721217, lng: 19.7867715 },
    { lat: 49.0722878, lng: 19.7869855 },
    { lat: 49.0724204, lng: 19.7871641 },
    { lat: 49.0724413, lng: 19.7872281 },
    { lat: 49.072677, lng: 19.7876765 },
    { lat: 49.0729867, lng: 19.7878068 },
    { lat: 49.0730995, lng: 19.7878874 },
    { lat: 49.0732159, lng: 19.7879975 },
    { lat: 49.0734843, lng: 19.7880841 },
    { lat: 49.0736683, lng: 19.7880649 },
    { lat: 49.0739888, lng: 19.7881068 },
    { lat: 49.0743669, lng: 19.7883825 },
    { lat: 49.0747658, lng: 19.7885678 },
    { lat: 49.0749524, lng: 19.7887398 },
    { lat: 49.0750404, lng: 19.789054 },
    { lat: 49.075141, lng: 19.7892196 },
    { lat: 49.0753911, lng: 19.7894489 },
    { lat: 49.0756303, lng: 19.7895938 },
    { lat: 49.0758621, lng: 19.7897412 },
    { lat: 49.0763104, lng: 19.7899215 },
    { lat: 49.0766774, lng: 19.7900978 },
    { lat: 49.0771517, lng: 19.7903621 },
    { lat: 49.0771602, lng: 19.790634 },
    { lat: 49.0771991, lng: 19.7909876 },
    { lat: 49.0773478, lng: 19.7914783 },
    { lat: 49.077521, lng: 19.7917591 },
    { lat: 49.0777437, lng: 19.7919847 },
    { lat: 49.078094, lng: 19.7921932 },
    { lat: 49.078452, lng: 19.7922666 },
    { lat: 49.078655, lng: 19.7926645 },
    { lat: 49.0788545, lng: 19.7926923 },
    { lat: 49.0790578, lng: 19.7926499 },
    { lat: 49.0791386, lng: 19.7925376 },
    { lat: 49.0794582, lng: 19.7925864 },
    { lat: 49.080004, lng: 19.7931415 },
    { lat: 49.0803492, lng: 19.7932316 },
    { lat: 49.0804906, lng: 19.7931933 },
    { lat: 49.0807765, lng: 19.7932649 },
    { lat: 49.0809841, lng: 19.7932462 },
    { lat: 49.0815964, lng: 19.7933925 },
    { lat: 49.0821466, lng: 19.7935881 },
    { lat: 49.0827, lng: 19.7936444 },
    { lat: 49.0829374, lng: 19.7935322 },
    { lat: 49.0831591, lng: 19.793267 },
    { lat: 49.0832755, lng: 19.7931475 },
    { lat: 49.0834339, lng: 19.7931093 },
    { lat: 49.0835997, lng: 19.7931926 },
    { lat: 49.0839114, lng: 19.7933159 },
    { lat: 49.0839227, lng: 19.7932102 },
  ],
  Važec: [
    { lat: 49.071283, lng: 20.019669 },
    { lat: 49.071122, lng: 20.020157 },
    { lat: 49.07124, lng: 20.020328 },
    { lat: 49.071426, lng: 20.020338 },
    { lat: 49.071462, lng: 20.020467 },
    { lat: 49.071527, lng: 20.020697 },
    { lat: 49.071426, lng: 20.021119 },
    { lat: 49.071399, lng: 20.021454 },
    { lat: 49.071498, lng: 20.021621 },
    { lat: 49.071669, lng: 20.021447 },
    { lat: 49.071844, lng: 20.021389 },
    { lat: 49.071916, lng: 20.021642 },
    { lat: 49.071846, lng: 20.021819 },
    { lat: 49.071886, lng: 20.021938 },
    { lat: 49.071713, lng: 20.022257 },
    { lat: 49.071746, lng: 20.022592 },
    { lat: 49.071924, lng: 20.022999 },
    { lat: 49.071976, lng: 20.023206 },
    { lat: 49.071936, lng: 20.023332 },
    { lat: 49.071837, lng: 20.023335 },
    { lat: 49.071689, lng: 20.023241 },
    { lat: 49.071418, lng: 20.023132 },
    { lat: 49.071383, lng: 20.023438 },
    { lat: 49.071408, lng: 20.023507 },
    { lat: 49.07171, lng: 20.023902 },
    { lat: 49.071728, lng: 20.024203 },
    { lat: 49.071754, lng: 20.02462 },
    { lat: 49.07169, lng: 20.024688 },
    { lat: 49.071572, lng: 20.024539 },
    { lat: 49.071524, lng: 20.024499 },
    { lat: 49.071498, lng: 20.02452 },
    { lat: 49.07147, lng: 20.024582 },
    { lat: 49.071499, lng: 20.024773 },
    { lat: 49.071525, lng: 20.024976 },
    { lat: 49.071641, lng: 20.025148 },
    { lat: 49.071657, lng: 20.025396 },
    { lat: 49.071533, lng: 20.025656 },
    { lat: 49.071583, lng: 20.025788 },
    { lat: 49.071631, lng: 20.02582 },
    { lat: 49.071751, lng: 20.02577 },
    { lat: 49.071883, lng: 20.02576 },
    { lat: 49.072109, lng: 20.026188 },
    { lat: 49.072107, lng: 20.026715 },
    { lat: 49.072141, lng: 20.026712 },
    { lat: 49.072546, lng: 20.026706 },
    { lat: 49.072709, lng: 20.026697 },
    { lat: 49.072872, lng: 20.026687 },
    { lat: 49.072992, lng: 20.026746 },
    { lat: 49.073224, lng: 20.026606 },
    { lat: 49.073543, lng: 20.026695 },
    { lat: 49.073835, lng: 20.026754 },
    { lat: 49.073919, lng: 20.026819 },
    { lat: 49.074267, lng: 20.02711 },
    { lat: 49.074601, lng: 20.027429 },
    { lat: 49.074727, lng: 20.027465 },
    { lat: 49.074728, lng: 20.027465 },
    { lat: 49.074847, lng: 20.027499 },
    { lat: 49.075218, lng: 20.028113 },
    { lat: 49.075957, lng: 20.028789 },
    { lat: 49.076153, lng: 20.028754 },
    { lat: 49.076366, lng: 20.029042 },
    { lat: 49.076472, lng: 20.02899 },
    { lat: 49.076476, lng: 20.029028 },
    { lat: 49.076946, lng: 20.029218 },
    { lat: 49.077248, lng: 20.029474 },
    { lat: 49.077828, lng: 20.030163 },
    { lat: 49.077987, lng: 20.03016 },
    { lat: 49.078037, lng: 20.030153 },
    { lat: 49.078095, lng: 20.030134 },
    { lat: 49.078171, lng: 20.030123 },
    { lat: 49.078583, lng: 20.030166 },
    { lat: 49.079404, lng: 20.030486 },
    { lat: 49.080172, lng: 20.030675 },
    { lat: 49.081025, lng: 20.031037 },
    { lat: 49.081878, lng: 20.031218 },
    { lat: 49.082365, lng: 20.031077 },
    { lat: 49.082748, lng: 20.031395 },
    { lat: 49.08378, lng: 20.031661 },
    { lat: 49.084098, lng: 20.031324 },
    { lat: 49.084435, lng: 20.030739 },
    { lat: 49.084773, lng: 20.030057 },
    { lat: 49.084802, lng: 20.030025 },
    { lat: 49.084837, lng: 20.029948 },
    { lat: 49.085457, lng: 20.0302 },
    { lat: 49.08567, lng: 20.030102 },
    { lat: 49.086627, lng: 20.030043 },
    { lat: 49.08675, lng: 20.029949 },
    { lat: 49.087436, lng: 20.028862 },
    { lat: 49.087456, lng: 20.028832 },
    { lat: 49.087547, lng: 20.029186 },
    { lat: 49.087788, lng: 20.029543 },
    { lat: 49.08809, lng: 20.02973 },
    { lat: 49.088323, lng: 20.029728 },
    { lat: 49.089112, lng: 20.030439 },
    { lat: 49.089371, lng: 20.030596 },
    { lat: 49.089514, lng: 20.03036 },
    { lat: 49.089719, lng: 20.030324 },
    { lat: 49.089885, lng: 20.030453 },
    { lat: 49.090094, lng: 20.030699 },
    { lat: 49.090104, lng: 20.030725 },
    { lat: 49.090427, lng: 20.030882 },
    { lat: 49.090878, lng: 20.030823 },
    { lat: 49.091042, lng: 20.0309 },
    { lat: 49.091404, lng: 20.031011 },
    { lat: 49.091666, lng: 20.031012 },
    { lat: 49.092081, lng: 20.031189 },
    { lat: 49.092451, lng: 20.031743 },
    { lat: 49.092913, lng: 20.032028 },
    { lat: 49.093309, lng: 20.032224 },
    { lat: 49.093872, lng: 20.032287 },
    { lat: 49.09421, lng: 20.032666 },
    { lat: 49.094491, lng: 20.032698 },
    { lat: 49.094723, lng: 20.032718 },
    { lat: 49.09524, lng: 20.032776 },
    { lat: 49.095619, lng: 20.032963 },
    { lat: 49.09611, lng: 20.033161 },
    { lat: 49.096716, lng: 20.033216 },
    { lat: 49.097118, lng: 20.033282 },
    { lat: 49.097452, lng: 20.033298 },
    { lat: 49.097558, lng: 20.033261 },
    { lat: 49.097818, lng: 20.03338 },
    { lat: 49.098195, lng: 20.03373 },
    { lat: 49.098396, lng: 20.033888 },
    { lat: 49.098407, lng: 20.03387 },
    { lat: 49.099472, lng: 20.034936 },
    { lat: 49.099672, lng: 20.035136 },
    { lat: 49.099971, lng: 20.03528 },
    { lat: 49.100312, lng: 20.035189 },
    { lat: 49.100562, lng: 20.035353 },
    { lat: 49.100814, lng: 20.035357 },
    { lat: 49.101141, lng: 20.03548 },
    { lat: 49.101263, lng: 20.035492 },
    { lat: 49.101521, lng: 20.035732 },
    { lat: 49.101843, lng: 20.036013 },
    { lat: 49.102069, lng: 20.036129 },
    { lat: 49.102336, lng: 20.036213 },
    { lat: 49.102545, lng: 20.036595 },
    { lat: 49.103004, lng: 20.03663 },
    { lat: 49.103255, lng: 20.036808 },
    { lat: 49.103396, lng: 20.036778 },
    { lat: 49.10358, lng: 20.036873 },
    { lat: 49.10362, lng: 20.036804 },
    { lat: 49.103812, lng: 20.036772 },
    { lat: 49.103894, lng: 20.036912 },
    { lat: 49.104031, lng: 20.036957 },
    { lat: 49.104216, lng: 20.037121 },
    { lat: 49.104538, lng: 20.036915 },
    { lat: 49.104968, lng: 20.037049 },
    { lat: 49.105158, lng: 20.037124 },
    { lat: 49.105593, lng: 20.037357 },
    { lat: 49.105819, lng: 20.037421 },
    { lat: 49.106069, lng: 20.037531 },
    { lat: 49.106293, lng: 20.037338 },
    { lat: 49.106498, lng: 20.037174 },
    { lat: 49.106793, lng: 20.037006 },
    { lat: 49.107075, lng: 20.037022 },
    { lat: 49.107333, lng: 20.037177 },
    { lat: 49.107725, lng: 20.037332 },
    { lat: 49.108016, lng: 20.037832 },
    { lat: 49.108312, lng: 20.037754 },
    { lat: 49.108486, lng: 20.037488 },
    { lat: 49.108391, lng: 20.037451 },
    { lat: 49.108439, lng: 20.03729 },
    { lat: 49.108487, lng: 20.037124 },
    { lat: 49.108669, lng: 20.036674 },
    { lat: 49.108695, lng: 20.036608 },
    { lat: 49.108873, lng: 20.036432 },
    { lat: 49.108948, lng: 20.036401 },
    { lat: 49.108856, lng: 20.036077 },
    { lat: 49.109573, lng: 20.035767 },
    { lat: 49.10974, lng: 20.035627 },
    { lat: 49.109941, lng: 20.035458 },
    { lat: 49.110036, lng: 20.035418 },
    { lat: 49.110097, lng: 20.035393 },
    { lat: 49.11014, lng: 20.035196 },
    { lat: 49.110195, lng: 20.034944 },
    { lat: 49.110195, lng: 20.034943 },
    { lat: 49.110413, lng: 20.03511 },
    { lat: 49.110354, lng: 20.034786 },
    { lat: 49.110056, lng: 20.034099 },
    { lat: 49.109995, lng: 20.033958 },
    { lat: 49.109994, lng: 20.033945 },
    { lat: 49.109829, lng: 20.032928 },
    { lat: 49.109773, lng: 20.032416 },
    { lat: 49.109858, lng: 20.031639 },
    { lat: 49.110017, lng: 20.030757 },
    { lat: 49.110012, lng: 20.030075 },
    { lat: 49.11005, lng: 20.029907 },
    { lat: 49.110222, lng: 20.029145 },
    { lat: 49.109462, lng: 20.028885 },
    { lat: 49.109108, lng: 20.028764 },
    { lat: 49.108932, lng: 20.02876 },
    { lat: 49.108581, lng: 20.028152 },
    { lat: 49.108798, lng: 20.028165 },
    { lat: 49.109775, lng: 20.028591 },
    { lat: 49.110596, lng: 20.02894 },
    { lat: 49.11079, lng: 20.029022 },
    { lat: 49.110969, lng: 20.028158 },
    { lat: 49.11098, lng: 20.027449 },
    { lat: 49.110985, lng: 20.027094 },
    { lat: 49.110407, lng: 20.025887 },
    { lat: 49.110192, lng: 20.024192 },
    { lat: 49.110297, lng: 20.02373 },
    { lat: 49.110191, lng: 20.022961 },
    { lat: 49.110164, lng: 20.02286 },
    { lat: 49.11014, lng: 20.022772 },
    { lat: 49.110128, lng: 20.022728 },
    { lat: 49.110099, lng: 20.022616 },
    { lat: 49.110079, lng: 20.022538 },
    { lat: 49.110073, lng: 20.022518 },
    { lat: 49.109848, lng: 20.022445 },
    { lat: 49.1097, lng: 20.022397 },
    { lat: 49.109429, lng: 20.022269 },
    { lat: 49.109376, lng: 20.02198 },
    { lat: 49.109339, lng: 20.021778 },
    { lat: 49.109632, lng: 20.02125 },
    { lat: 49.109814, lng: 20.0208 },
    { lat: 49.109777, lng: 20.020525 },
    { lat: 49.109743, lng: 20.020514 },
    { lat: 49.109272, lng: 20.020369 },
    { lat: 49.10905, lng: 20.020385 },
    { lat: 49.108875, lng: 20.020398 },
    { lat: 49.108652, lng: 20.020293 },
    { lat: 49.108637, lng: 20.019865 },
    { lat: 49.108628, lng: 20.019625 },
    { lat: 49.108605, lng: 20.019272 },
    { lat: 49.108491, lng: 20.018355 },
    { lat: 49.108037, lng: 20.018122 },
    { lat: 49.107951, lng: 20.017299 },
    { lat: 49.107908, lng: 20.017132 },
    { lat: 49.107818, lng: 20.016783 },
    { lat: 49.107626, lng: 20.016833 },
    { lat: 49.107608, lng: 20.016862 },
    { lat: 49.10736, lng: 20.017273 },
    { lat: 49.106368, lng: 20.017694 },
    { lat: 49.10607, lng: 20.017602 },
    { lat: 49.106019, lng: 20.01699 },
    { lat: 49.106002, lng: 20.016783 },
    { lat: 49.106111, lng: 20.016674 },
    { lat: 49.106113, lng: 20.016472 },
    { lat: 49.106113, lng: 20.016324 },
    { lat: 49.106113, lng: 20.016276 },
    { lat: 49.106115, lng: 20.015781 },
    { lat: 49.106259, lng: 20.015086 },
    { lat: 49.106509, lng: 20.01313 },
    { lat: 49.106437, lng: 20.012834 },
    { lat: 49.106262, lng: 20.012118 },
    { lat: 49.106087, lng: 20.011202 },
    { lat: 49.106239, lng: 20.011067 },
    { lat: 49.106699, lng: 20.011339 },
    { lat: 49.107342, lng: 20.01164 },
    { lat: 49.107171, lng: 20.010752 },
    { lat: 49.106942, lng: 20.010161 },
    { lat: 49.106747, lng: 20.00966 },
    { lat: 49.106431, lng: 20.008813 },
    { lat: 49.106366, lng: 20.008758 },
    { lat: 49.106257, lng: 20.008149 },
    { lat: 49.106091, lng: 20.007869 },
    { lat: 49.105896, lng: 20.00754 },
    { lat: 49.106002, lng: 20.007258 },
    { lat: 49.106022, lng: 20.007237 },
    { lat: 49.106293, lng: 20.006956 },
    { lat: 49.106397, lng: 20.006361 },
    { lat: 49.106403, lng: 20.006099 },
    { lat: 49.106409, lng: 20.005862 },
    { lat: 49.106352, lng: 20.005856 },
    { lat: 49.106107, lng: 20.005834 },
    { lat: 49.105993, lng: 20.005825 },
    { lat: 49.105367, lng: 20.006071 },
    { lat: 49.105259, lng: 20.006113 },
    { lat: 49.104882, lng: 20.005477 },
    { lat: 49.104695, lng: 20.005162 },
    { lat: 49.104695, lng: 20.005163 },
    { lat: 49.104469, lng: 20.005322 },
    { lat: 49.104399, lng: 20.005371 },
    { lat: 49.10366, lng: 20.004597 },
    { lat: 49.104156, lng: 20.004281 },
    { lat: 49.104331, lng: 20.00417 },
    { lat: 49.104417, lng: 20.00266 },
    { lat: 49.10442, lng: 20.002604 },
    { lat: 49.10455, lng: 20.000316 },
    { lat: 49.104553, lng: 20.000294 },
    { lat: 49.104713, lng: 19.998831 },
    { lat: 49.104901, lng: 19.997116 },
    { lat: 49.104909, lng: 19.996977 },
    { lat: 49.104916, lng: 19.996849 },
    { lat: 49.104919, lng: 19.996794 },
    { lat: 49.104922, lng: 19.996755 },
    { lat: 49.104964, lng: 19.995969 },
    { lat: 49.10499, lng: 19.995487 },
    { lat: 49.105034, lng: 19.994655 },
    { lat: 49.105066, lng: 19.994398 },
    { lat: 49.105072, lng: 19.994348 },
    { lat: 49.105245, lng: 19.99125 },
    { lat: 49.105248, lng: 19.991205 },
    { lat: 49.105248, lng: 19.991189 },
    { lat: 49.10533, lng: 19.989794 },
    { lat: 49.105359, lng: 19.988903 },
    { lat: 49.105359, lng: 19.988902 },
    { lat: 49.106166, lng: 19.991097 },
    { lat: 49.106245, lng: 19.991405 },
    { lat: 49.106312, lng: 19.991667 },
    { lat: 49.106327, lng: 19.991726 },
    { lat: 49.10653, lng: 19.992523 },
    { lat: 49.107391, lng: 19.993017 },
    { lat: 49.10749, lng: 19.993075 },
    { lat: 49.107837, lng: 19.992818 },
    { lat: 49.108323, lng: 19.992872 },
    { lat: 49.108578, lng: 19.992583 },
    { lat: 49.108876, lng: 19.991946 },
    { lat: 49.108902, lng: 19.991891 },
    { lat: 49.109492, lng: 19.990627 },
    { lat: 49.109542, lng: 19.990521 },
    { lat: 49.109475, lng: 19.989912 },
    { lat: 49.109354, lng: 19.988818 },
    { lat: 49.109343, lng: 19.988715 },
    { lat: 49.109182, lng: 19.988444 },
    { lat: 49.109096, lng: 19.988299 },
    { lat: 49.108903, lng: 19.988333 },
    { lat: 49.108809, lng: 19.98835 },
    { lat: 49.10863, lng: 19.987981 },
    { lat: 49.108514, lng: 19.987709 },
    { lat: 49.108407, lng: 19.987458 },
    { lat: 49.108205, lng: 19.986909 },
    { lat: 49.108201, lng: 19.986899 },
    { lat: 49.108198, lng: 19.986878 },
    { lat: 49.108142, lng: 19.986546 },
    { lat: 49.108197, lng: 19.986128 },
    { lat: 49.108224, lng: 19.985915 },
    { lat: 49.108222, lng: 19.985429 },
    { lat: 49.108245, lng: 19.984902 },
    { lat: 49.108031, lng: 19.984745 },
    { lat: 49.107941, lng: 19.984679 },
    { lat: 49.107952, lng: 19.984663 },
    { lat: 49.108016, lng: 19.984569 },
    { lat: 49.108099, lng: 19.984037 },
    { lat: 49.108118, lng: 19.983679 },
    { lat: 49.108019, lng: 19.983404 },
    { lat: 49.107982, lng: 19.983302 },
    { lat: 49.107903, lng: 19.982585 },
    { lat: 49.107896, lng: 19.982517 },
    { lat: 49.107748, lng: 19.982179 },
    { lat: 49.107716, lng: 19.982105 },
    { lat: 49.107662, lng: 19.981984 },
    { lat: 49.107634, lng: 19.981342 },
    { lat: 49.107636, lng: 19.981242 },
    { lat: 49.107645, lng: 19.980542 },
    { lat: 49.107624, lng: 19.980083 },
    { lat: 49.107591, lng: 19.979397 },
    { lat: 49.107548, lng: 19.978928 },
    { lat: 49.107839, lng: 19.978835 },
    { lat: 49.107877, lng: 19.978265 },
    { lat: 49.107883, lng: 19.978162 },
    { lat: 49.107953, lng: 19.977961 },
    { lat: 49.108053, lng: 19.977797 },
    { lat: 49.108291, lng: 19.977267 },
    { lat: 49.108292, lng: 19.977211 },
    { lat: 49.108297, lng: 19.977 },
    { lat: 49.108416, lng: 19.97672 },
    { lat: 49.10838, lng: 19.976286 },
    { lat: 49.108377, lng: 19.976248 },
    { lat: 49.108392, lng: 19.975746 },
    { lat: 49.108275, lng: 19.97553 },
    { lat: 49.108345, lng: 19.975405 },
    { lat: 49.10852, lng: 19.97509 },
    { lat: 49.108658, lng: 19.975311 },
    { lat: 49.108761, lng: 19.975478 },
    { lat: 49.109001, lng: 19.975197 },
    { lat: 49.108957, lng: 19.974943 },
    { lat: 49.108942, lng: 19.974859 },
    { lat: 49.109437, lng: 19.973948 },
    { lat: 49.109563, lng: 19.973831 },
    { lat: 49.109954, lng: 19.973903 },
    { lat: 49.110287, lng: 19.973095 },
    { lat: 49.11044, lng: 19.972965 },
    { lat: 49.110473, lng: 19.972937 },
    { lat: 49.110538, lng: 19.972861 },
    { lat: 49.11063, lng: 19.972756 },
    { lat: 49.110701, lng: 19.972569 },
    { lat: 49.111353, lng: 19.972936 },
    { lat: 49.111496, lng: 19.973016 },
    { lat: 49.111723, lng: 19.972693 },
    { lat: 49.111865, lng: 19.972489 },
    { lat: 49.111982, lng: 19.972399 },
    { lat: 49.112035, lng: 19.972358 },
    { lat: 49.112427, lng: 19.972347 },
    { lat: 49.112603, lng: 19.972082 },
    { lat: 49.11261, lng: 19.97207 },
    { lat: 49.112633, lng: 19.971909 },
    { lat: 49.112711, lng: 19.971343 },
    { lat: 49.114222, lng: 19.972597 },
    { lat: 49.114222, lng: 19.972596 },
    { lat: 49.114425, lng: 19.972163 },
    { lat: 49.114504, lng: 19.972021 },
    { lat: 49.114631, lng: 19.971793 },
    { lat: 49.115814, lng: 19.972091 },
    { lat: 49.116144, lng: 19.971898 },
    { lat: 49.116619, lng: 19.971621 },
    { lat: 49.116689, lng: 19.971626 },
    { lat: 49.1168, lng: 19.971633 },
    { lat: 49.116842, lng: 19.971636 },
    { lat: 49.116919, lng: 19.971641 },
    { lat: 49.117493, lng: 19.971679 },
    { lat: 49.117706, lng: 19.971693 },
    { lat: 49.117783, lng: 19.971173 },
    { lat: 49.117826, lng: 19.970884 },
    { lat: 49.117771, lng: 19.970432 },
    { lat: 49.117717, lng: 19.969988 },
    { lat: 49.117671, lng: 19.969608 },
    { lat: 49.117496, lng: 19.969152 },
    { lat: 49.117444, lng: 19.969016 },
    { lat: 49.11739, lng: 19.96861 },
    { lat: 49.117612, lng: 19.968008 },
    { lat: 49.117674, lng: 19.967838 },
    { lat: 49.117512, lng: 19.967392 },
    { lat: 49.117574, lng: 19.96679 },
    { lat: 49.117598, lng: 19.966566 },
    { lat: 49.117044, lng: 19.966245 },
    { lat: 49.116445, lng: 19.965305 },
    { lat: 49.116238, lng: 19.964982 },
    { lat: 49.11605, lng: 19.96488 },
    { lat: 49.115712, lng: 19.964699 },
    { lat: 49.115583, lng: 19.96463 },
    { lat: 49.115353, lng: 19.964748 },
    { lat: 49.115094, lng: 19.964512 },
    { lat: 49.115086, lng: 19.964505 },
    { lat: 49.114998, lng: 19.964424 },
    { lat: 49.11486, lng: 19.964637 },
    { lat: 49.114872, lng: 19.964711 },
    { lat: 49.114963, lng: 19.965262 },
    { lat: 49.114628, lng: 19.965814 },
    { lat: 49.114437, lng: 19.966217 },
    { lat: 49.114385, lng: 19.966085 },
    { lat: 49.114318, lng: 19.965916 },
    { lat: 49.114179, lng: 19.965567 },
    { lat: 49.113926, lng: 19.964931 },
    { lat: 49.113923, lng: 19.964929 },
    { lat: 49.113442, lng: 19.964704 },
    { lat: 49.113258, lng: 19.964618 },
    { lat: 49.112619, lng: 19.963949 },
    { lat: 49.112647, lng: 19.963865 },
    { lat: 49.112761, lng: 19.963524 },
    { lat: 49.112486, lng: 19.963192 },
    { lat: 49.112267, lng: 19.962927 },
    { lat: 49.112047, lng: 19.96176 },
    { lat: 49.111814, lng: 19.960525 },
    { lat: 49.111563, lng: 19.960336 },
    { lat: 49.111215, lng: 19.960076 },
    { lat: 49.111449, lng: 19.95758 },
    { lat: 49.111842, lng: 19.956409 },
    { lat: 49.112169, lng: 19.956354 },
    { lat: 49.112405, lng: 19.956314 },
    { lat: 49.112309, lng: 19.95608 },
    { lat: 49.112299, lng: 19.956055 },
    { lat: 49.112291, lng: 19.956034 },
    { lat: 49.112219, lng: 19.955858 },
    { lat: 49.111928, lng: 19.955692 },
    { lat: 49.111618, lng: 19.955513 },
    { lat: 49.111383, lng: 19.95639 },
    { lat: 49.110911, lng: 19.956518 },
    { lat: 49.110866, lng: 19.956531 },
    { lat: 49.110698, lng: 19.956731 },
    { lat: 49.110665, lng: 19.956761 },
    { lat: 49.110521, lng: 19.956894 },
    { lat: 49.110327, lng: 19.957072 },
    { lat: 49.110325, lng: 19.956781 },
    { lat: 49.11032, lng: 19.956295 },
    { lat: 49.110243, lng: 19.955449 },
    { lat: 49.110216, lng: 19.955153 },
    { lat: 49.109997, lng: 19.954746 },
    { lat: 49.109571, lng: 19.953951 },
    { lat: 49.109258, lng: 19.953225 },
    { lat: 49.10926, lng: 19.953165 },
    { lat: 49.10927, lng: 19.952787 },
    { lat: 49.109272, lng: 19.952719 },
    { lat: 49.109618, lng: 19.952896 },
    { lat: 49.109692, lng: 19.95282 },
    { lat: 49.110048, lng: 19.952446 },
    { lat: 49.110063, lng: 19.952431 },
    { lat: 49.109814, lng: 19.9521865 },
    { lat: 49.1092196, lng: 19.95214 },
    { lat: 49.1088595, lng: 19.9519324 },
    { lat: 49.1085821, lng: 19.9518457 },
    { lat: 49.1083215, lng: 19.951591 },
    { lat: 49.1081113, lng: 19.9512275 },
    { lat: 49.1081324, lng: 19.9509554 },
    { lat: 49.1080849, lng: 19.9506476 },
    { lat: 49.1079791, lng: 19.9504386 },
    { lat: 49.1075561, lng: 19.9502921 },
    { lat: 49.1073345, lng: 19.9500828 },
    { lat: 49.1071104, lng: 19.9501078 },
    { lat: 49.1066669, lng: 19.9506276 },
    { lat: 49.1063742, lng: 19.9506547 },
    { lat: 49.1062086, lng: 19.9502782 },
    { lat: 49.105802, lng: 19.9500391 },
    { lat: 49.1054201, lng: 19.9498455 },
    { lat: 49.1049184, lng: 19.9501074 },
    { lat: 49.1047902, lng: 19.9498938 },
    { lat: 49.1037443, lng: 19.9499857 },
    { lat: 49.1034171, lng: 19.9498375 },
    { lat: 49.1029106, lng: 19.9494161 },
    { lat: 49.1024156, lng: 19.9492687 },
    { lat: 49.1023223, lng: 19.9490305 },
    { lat: 49.1021841, lng: 19.9489314 },
    { lat: 49.1018791, lng: 19.9489125 },
    { lat: 49.1014987, lng: 19.9491073 },
    { lat: 49.1010079, lng: 19.9490073 },
    { lat: 49.1008239, lng: 19.9490227 },
    { lat: 49.1004083, lng: 19.9489438 },
    { lat: 49.0997767, lng: 19.9487363 },
    { lat: 49.0995322, lng: 19.9487579 },
    { lat: 49.0986531, lng: 19.9483452 },
    { lat: 49.0983876, lng: 19.9482999 },
    { lat: 49.0977698, lng: 19.9485005 },
    { lat: 49.0970052, lng: 19.9482618 },
    { lat: 49.0967816, lng: 19.9482188 },
    { lat: 49.0954229, lng: 19.9493149 },
    { lat: 49.0950569, lng: 19.9496416 },
    { lat: 49.0945896, lng: 19.9499357 },
    { lat: 49.0945548, lng: 19.9502101 },
    { lat: 49.0942984, lng: 19.9503026 },
    { lat: 49.0941646, lng: 19.9501183 },
    { lat: 49.0939034, lng: 19.9503341 },
    { lat: 49.0936532, lng: 19.9503063 },
    { lat: 49.0931258, lng: 19.9507299 },
    { lat: 49.0926464, lng: 19.9508696 },
    { lat: 49.0924816, lng: 19.9513755 },
    { lat: 49.0920207, lng: 19.9513423 },
    { lat: 49.0915895, lng: 19.95175 },
    { lat: 49.0913783, lng: 19.9521378 },
    { lat: 49.0912263, lng: 19.9523592 },
    { lat: 49.0910966, lng: 19.9521368 },
    { lat: 49.0910877, lng: 19.9521373 },
    { lat: 49.0909131, lng: 19.9520885 },
    { lat: 49.0906574, lng: 19.9522733 },
    { lat: 49.0902127, lng: 19.9524911 },
    { lat: 49.0900139, lng: 19.9524271 },
    { lat: 49.0899894, lng: 19.9521642 },
    { lat: 49.0898075, lng: 19.9520978 },
    { lat: 49.0896432, lng: 19.9522891 },
    { lat: 49.0895692, lng: 19.9522963 },
    { lat: 49.0884918, lng: 19.9526037 },
    { lat: 49.0876446, lng: 19.9526004 },
    { lat: 49.0873277, lng: 19.9524566 },
    { lat: 49.0870791, lng: 19.952116 },
    { lat: 49.0867299, lng: 19.9520866 },
    { lat: 49.0867144, lng: 19.9523623 },
    { lat: 49.0866283, lng: 19.9524612 },
    { lat: 49.0863124, lng: 19.952419 },
    { lat: 49.0860702, lng: 19.9525532 },
    { lat: 49.0860453, lng: 19.9525225 },
    { lat: 49.0860566, lng: 19.9522293 },
    { lat: 49.0861899, lng: 19.9521811 },
    { lat: 49.0861506, lng: 19.9520268 },
    { lat: 49.0860636, lng: 19.951939 },
    { lat: 49.0857057, lng: 19.952157 },
    { lat: 49.0852425, lng: 19.9519904 },
    { lat: 49.0851078, lng: 19.9518112 },
    { lat: 49.0850024, lng: 19.9518811 },
    { lat: 49.0848201, lng: 19.9519085 },
    { lat: 49.084655, lng: 19.9517862 },
    { lat: 49.0846376, lng: 19.9515852 },
    { lat: 49.0847417, lng: 19.9513329 },
    { lat: 49.0846565, lng: 19.9511256 },
    { lat: 49.0844478, lng: 19.9510388 },
    { lat: 49.0843499, lng: 19.9507411 },
    { lat: 49.0841206, lng: 19.9505136 },
    { lat: 49.0839981, lng: 19.9502761 },
    { lat: 49.0840018, lng: 19.9500451 },
    { lat: 49.0837946, lng: 19.9498543 },
    { lat: 49.0837703, lng: 19.9495724 },
    { lat: 49.0835043, lng: 19.9493816 },
    { lat: 49.0831998, lng: 19.9490989 },
    { lat: 49.0832224, lng: 19.9489404 },
    { lat: 49.0831552, lng: 19.9488169 },
    { lat: 49.0829148, lng: 19.9487252 },
    { lat: 49.0828729, lng: 19.9486143 },
    { lat: 49.0829932, lng: 19.9485002 },
    { lat: 49.0828954, lng: 19.9484246 },
    { lat: 49.0826254, lng: 19.9484875 },
    { lat: 49.0824203, lng: 19.9482404 },
    { lat: 49.0824286, lng: 19.9479827 },
    { lat: 49.0824972, lng: 19.9479013 },
    { lat: 49.0825826, lng: 19.9475455 },
    { lat: 49.0825087, lng: 19.9473439 },
    { lat: 49.082312, lng: 19.9472449 },
    { lat: 49.0819131, lng: 19.9474518 },
    { lat: 49.0816807, lng: 19.9473645 },
    { lat: 49.081521, lng: 19.9469402 },
    { lat: 49.0807837, lng: 19.9466824 },
    { lat: 49.0805289, lng: 19.9470772 },
    { lat: 49.0804713, lng: 19.946914 },
    { lat: 49.0801894, lng: 19.9469069 },
    { lat: 49.0798073, lng: 19.9469027 },
    { lat: 49.0795791, lng: 19.9470205 },
    { lat: 49.0794458, lng: 19.9473047 },
    { lat: 49.0791275, lng: 19.9475038 },
    { lat: 49.0787525, lng: 19.94756 },
    { lat: 49.0784943, lng: 19.9478208 },
    { lat: 49.0783939, lng: 19.9477681 },
    { lat: 49.07848, lng: 19.9474575 },
    { lat: 49.0784648, lng: 19.9474144 },
    { lat: 49.0782302, lng: 19.9474096 },
    { lat: 49.0780917, lng: 19.9475179 },
    { lat: 49.0781748, lng: 19.9478041 },
    { lat: 49.0778222, lng: 19.9477776 },
    { lat: 49.0777068, lng: 19.9478948 },
    { lat: 49.0778383, lng: 19.9483463 },
    { lat: 49.0776618, lng: 19.9483807 },
    { lat: 49.0775625, lng: 19.9481352 },
    { lat: 49.0774882, lng: 19.9480853 },
    { lat: 49.0772323, lng: 19.9484558 },
    { lat: 49.0771195, lng: 19.9483166 },
    { lat: 49.0769197, lng: 19.9485207 },
    { lat: 49.0767924, lng: 19.9483064 },
    { lat: 49.0769009, lng: 19.9480731 },
    { lat: 49.0768839, lng: 19.9480172 },
    { lat: 49.076454, lng: 19.9478636 },
    { lat: 49.0761503, lng: 19.9480332 },
    { lat: 49.0759572, lng: 19.9482625 },
    { lat: 49.0755343, lng: 19.9481746 },
    { lat: 49.0753348, lng: 19.9482749 },
    { lat: 49.0751627, lng: 19.9479355 },
    { lat: 49.0751096, lng: 19.9479033 },
    { lat: 49.0750253, lng: 19.9483624 },
    { lat: 49.0747905, lng: 19.9484043 },
    { lat: 49.074599, lng: 19.9485138 },
    { lat: 49.0743089, lng: 19.9483893 },
    { lat: 49.073949, lng: 19.9485647 },
    { lat: 49.0738501, lng: 19.9484073 },
    { lat: 49.0736889, lng: 19.9486723 },
    { lat: 49.0735608, lng: 19.9490012 },
    { lat: 49.073403, lng: 19.9489753 },
    { lat: 49.0734015, lng: 19.948748 },
    { lat: 49.0730775, lng: 19.9488107 },
    { lat: 49.0730957, lng: 19.9491941 },
    { lat: 49.0730201, lng: 19.9494066 },
    { lat: 49.0729801, lng: 19.9494428 },
    { lat: 49.072929, lng: 19.9493781 },
    { lat: 49.0728317, lng: 19.9491569 },
    { lat: 49.0727096, lng: 19.9491156 },
    { lat: 49.0726689, lng: 19.9492765 },
    { lat: 49.0727864, lng: 19.9495802 },
    { lat: 49.0727844, lng: 19.9496403 },
    { lat: 49.0726941, lng: 19.9496653 },
    { lat: 49.0723654, lng: 19.9492374 },
    { lat: 49.0723823, lng: 19.9490504 },
    { lat: 49.0723641, lng: 19.9490173 },
    { lat: 49.0720157, lng: 19.9490386 },
    { lat: 49.071937, lng: 19.9493067 },
    { lat: 49.0717356, lng: 19.9493388 },
    { lat: 49.0715951, lng: 19.9493187 },
    { lat: 49.0715776, lng: 19.9491997 },
    { lat: 49.0714392, lng: 19.9491761 },
    { lat: 49.0712398, lng: 19.9492569 },
    { lat: 49.0711811, lng: 19.9492235 },
    { lat: 49.0712972, lng: 19.9489866 },
    { lat: 49.071194, lng: 19.9488605 },
    { lat: 49.0709625, lng: 19.94874 },
    { lat: 49.0708753, lng: 19.948433 },
    { lat: 49.0706164, lng: 19.9481115 },
    { lat: 49.0704362, lng: 19.9480867 },
    { lat: 49.0702971, lng: 19.9483172 },
    { lat: 49.0702934, lng: 19.9486333 },
    { lat: 49.0701003, lng: 19.9485921 },
    { lat: 49.0698834, lng: 19.9486821 },
    { lat: 49.0696572, lng: 19.9484727 },
    { lat: 49.0695482, lng: 19.9485074 },
    { lat: 49.0694567, lng: 19.9488252 },
    { lat: 49.0692279, lng: 19.949008 },
    { lat: 49.0688922, lng: 19.948708 },
    { lat: 49.0689064, lng: 19.9485123 },
    { lat: 49.0687933, lng: 19.9483932 },
    { lat: 49.068564, lng: 19.9484586 },
    { lat: 49.0684026, lng: 19.9485885 },
    { lat: 49.0678333, lng: 19.9486837 },
    { lat: 49.067794, lng: 19.9488158 },
    { lat: 49.0678039, lng: 19.9489743 },
    { lat: 49.0677025, lng: 19.9492672 },
    { lat: 49.0676286, lng: 19.9493075 },
    { lat: 49.0672729, lng: 19.9488287 },
    { lat: 49.0670052, lng: 19.9487541 },
    { lat: 49.0669059, lng: 19.9488583 },
    { lat: 49.0667935, lng: 19.9491088 },
    { lat: 49.0664821, lng: 19.9491186 },
    { lat: 49.066275, lng: 19.94856 },
    { lat: 49.0661927, lng: 19.948128 },
    { lat: 49.0660985, lng: 19.9480592 },
    { lat: 49.0658195, lng: 19.9484149 },
    { lat: 49.0657348, lng: 19.9483592 },
    { lat: 49.0657042, lng: 19.9482196 },
    { lat: 49.0657817, lng: 19.9480519 },
    { lat: 49.0658408, lng: 19.948146 },
    { lat: 49.0659209, lng: 19.9480409 },
    { lat: 49.0659143, lng: 19.9479621 },
    { lat: 49.0657127, lng: 19.9475887 },
    { lat: 49.0655791, lng: 19.9475159 },
    { lat: 49.0654668, lng: 19.9472308 },
    { lat: 49.0655304, lng: 19.9469466 },
    { lat: 49.0655062, lng: 19.9467737 },
    { lat: 49.0654602, lng: 19.9466966 },
    { lat: 49.0653505, lng: 19.9467421 },
    { lat: 49.0650523, lng: 19.9464819 },
    { lat: 49.0648074, lng: 19.9461476 },
    { lat: 49.0647965, lng: 19.9460495 },
    { lat: 49.0648769, lng: 19.9458955 },
    { lat: 49.0648696, lng: 19.9457777 },
    { lat: 49.0646521, lng: 19.9456131 },
    { lat: 49.064406, lng: 19.9454675 },
    { lat: 49.0637484, lng: 19.9448264 },
    { lat: 49.0633664, lng: 19.944342 },
    { lat: 49.062692, lng: 19.942441 },
    { lat: 49.0626788, lng: 19.9415375 },
    { lat: 49.0626254, lng: 19.94118 },
    { lat: 49.0621708, lng: 19.9408938 },
    { lat: 49.0619924, lng: 19.9406428 },
    { lat: 49.0616821, lng: 19.9404708 },
    { lat: 49.0614511, lng: 19.9400691 },
    { lat: 49.0605257, lng: 19.9394846 },
    { lat: 49.0595008, lng: 19.9390852 },
    { lat: 49.0587155, lng: 19.9386821 },
    { lat: 49.0579697, lng: 19.9374183 },
    { lat: 49.0574468, lng: 19.9367161 },
    { lat: 49.0569856, lng: 19.9364356 },
    { lat: 49.0566173, lng: 19.9363558 },
    { lat: 49.0562337, lng: 19.9362072 },
    { lat: 49.0560076, lng: 19.9359735 },
    { lat: 49.0558057, lng: 19.9359131 },
    { lat: 49.0556134, lng: 19.9359778 },
    { lat: 49.0552839, lng: 19.935882 },
    { lat: 49.0549366, lng: 19.9354418 },
    { lat: 49.0545082, lng: 19.935565 },
    { lat: 49.0541425, lng: 19.9359217 },
    { lat: 49.0535526, lng: 19.9357199 },
    { lat: 49.0531083, lng: 19.9357621 },
    { lat: 49.052448, lng: 19.9352708 },
    { lat: 49.0519412, lng: 19.9345801 },
    { lat: 49.0509498, lng: 19.9338773 },
    { lat: 49.0501233, lng: 19.9338853 },
    { lat: 49.0493876, lng: 19.9355908 },
    { lat: 49.0492695, lng: 19.9361618 },
    { lat: 49.0489145, lng: 19.9369587 },
    { lat: 49.0487826, lng: 19.9374128 },
    { lat: 49.0484741, lng: 19.937654 },
    { lat: 49.0479838, lng: 19.9374852 },
    { lat: 49.0474754, lng: 19.937558 },
    { lat: 49.0472252, lng: 19.9380348 },
    { lat: 49.0472956, lng: 19.93848 },
    { lat: 49.0469839, lng: 19.9396896 },
    { lat: 49.0468547, lng: 19.9399384 },
    { lat: 49.0463289, lng: 19.940507 },
    { lat: 49.0458815, lng: 19.9411742 },
    { lat: 49.0458332, lng: 19.9414669 },
    { lat: 49.0457289, lng: 19.9416142 },
    { lat: 49.0456818, lng: 19.94178 },
    { lat: 49.045319, lng: 19.9417776 },
    { lat: 49.0447424, lng: 19.9416724 },
    { lat: 49.0440207, lng: 19.9418144 },
    { lat: 49.0438991, lng: 19.941977 },
    { lat: 49.0435912, lng: 19.941565 },
    { lat: 49.0434444, lng: 19.9414457 },
    { lat: 49.0423918, lng: 19.9414386 },
    { lat: 49.0414895, lng: 19.9413547 },
    { lat: 49.0413769, lng: 19.9412971 },
    { lat: 49.0408227, lng: 19.9408713 },
    { lat: 49.0401291, lng: 19.9405037 },
    { lat: 49.0394169, lng: 19.938565 },
    { lat: 49.0393896, lng: 19.938023 },
    { lat: 49.0393548, lng: 19.9379197 },
    { lat: 49.0391011, lng: 19.9371119 },
    { lat: 49.0386926, lng: 19.9360211 },
    { lat: 49.0379981, lng: 19.9353107 },
    { lat: 49.0370401, lng: 19.9350271 },
    { lat: 49.0363724, lng: 19.9355444 },
    { lat: 49.0358824, lng: 19.9350885 },
    { lat: 49.0353277, lng: 19.9345394 },
    { lat: 49.0346014, lng: 19.9350556 },
    { lat: 49.0334753, lng: 19.9354872 },
    { lat: 49.0324543, lng: 19.9360666 },
    { lat: 49.0312187, lng: 19.9375365 },
    { lat: 49.0308168, lng: 19.9384802 },
    { lat: 49.0308346, lng: 19.9387183 },
    { lat: 49.0312495, lng: 19.9421311 },
    { lat: 49.0315013, lng: 19.9444775 },
    { lat: 49.031561, lng: 19.9456778 },
    { lat: 49.0316229, lng: 19.9473711 },
    { lat: 49.0311398, lng: 19.9476646 },
    { lat: 49.0295725, lng: 19.9476797 },
    { lat: 49.0291589, lng: 19.9477019 },
    { lat: 49.0286164, lng: 19.9477155 },
    { lat: 49.0282935, lng: 19.9476934 },
    { lat: 49.0275598, lng: 19.9468522 },
    { lat: 49.0266685, lng: 19.9463641 },
    { lat: 49.0263945, lng: 19.9461671 },
    { lat: 49.0255677, lng: 19.9457119 },
    { lat: 49.0252565, lng: 19.9457055 },
    { lat: 49.0246818, lng: 19.945593 },
    { lat: 49.0242367, lng: 19.9454547 },
    { lat: 49.0226795, lng: 19.9485478 },
    { lat: 49.0213985, lng: 19.9484346 },
    { lat: 49.0213939, lng: 19.9489712 },
    { lat: 49.0214049, lng: 19.9498225 },
    { lat: 49.0214693, lng: 19.9505243 },
    { lat: 49.0217517, lng: 19.9516704 },
    { lat: 49.0219212, lng: 19.9525993 },
    { lat: 49.0220668, lng: 19.9533561 },
    { lat: 49.0222101, lng: 19.9543259 },
    { lat: 49.0222552, lng: 19.9557266 },
    { lat: 49.0221912, lng: 19.9565891 },
    { lat: 49.0220921, lng: 19.9570448 },
    { lat: 49.0217296, lng: 19.9583141 },
    { lat: 49.0208561, lng: 19.9612048 },
    { lat: 49.0207554, lng: 19.9615167 },
    { lat: 49.0202905, lng: 19.9624144 },
    { lat: 49.0199462, lng: 19.9630413 },
    { lat: 49.0194638, lng: 19.9645942 },
    { lat: 49.019271, lng: 19.9652588 },
    { lat: 49.0190818, lng: 19.9658265 },
    { lat: 49.0189256, lng: 19.9658107 },
    { lat: 49.0188493, lng: 19.9658648 },
    { lat: 49.0185263, lng: 19.9660941 },
    { lat: 49.017806, lng: 19.9663479 },
    { lat: 49.0173804, lng: 19.96659 },
    { lat: 49.0169361, lng: 19.9670689 },
    { lat: 49.0166528, lng: 19.9670784 },
    { lat: 49.0165975, lng: 19.9671038 },
    { lat: 49.0167363, lng: 19.9674597 },
    { lat: 49.0167775, lng: 19.9678238 },
    { lat: 49.0167435, lng: 19.9682244 },
    { lat: 49.0167159, lng: 19.9683727 },
    { lat: 49.0165884, lng: 19.9688774 },
    { lat: 49.01657, lng: 19.968958 },
    { lat: 49.016582, lng: 19.969999 },
    { lat: 49.016513, lng: 19.970374 },
    { lat: 49.016502, lng: 19.970447 },
    { lat: 49.016505, lng: 19.970549 },
    { lat: 49.016505, lng: 19.970555 },
    { lat: 49.016506, lng: 19.970569 },
    { lat: 49.016509, lng: 19.970587 },
    { lat: 49.01651, lng: 19.970592 },
    { lat: 49.016525, lng: 19.970668 },
    { lat: 49.016542, lng: 19.970791 },
    { lat: 49.016594, lng: 19.971064 },
    { lat: 49.016617, lng: 19.971241 },
    { lat: 49.016647, lng: 19.971559 },
    { lat: 49.016697, lng: 19.971818 },
    { lat: 49.016702, lng: 19.971872 },
    { lat: 49.016756, lng: 19.97206 },
    { lat: 49.016812, lng: 19.972244 },
    { lat: 49.016824, lng: 19.97244 },
    { lat: 49.016782, lng: 19.972734 },
    { lat: 49.016725, lng: 19.972913 },
    { lat: 49.016692, lng: 19.972973 },
    { lat: 49.01662, lng: 19.973024 },
    { lat: 49.01652, lng: 19.973058 },
    { lat: 49.016292, lng: 19.973083 },
    { lat: 49.016202, lng: 19.97313 },
    { lat: 49.0162, lng: 19.97313 },
    { lat: 49.016179, lng: 19.973142 },
    { lat: 49.016125, lng: 19.973174 },
    { lat: 49.01607, lng: 19.973244 },
    { lat: 49.016069, lng: 19.973244 },
    { lat: 49.016033, lng: 19.97329 },
    { lat: 49.015999, lng: 19.97338 },
    { lat: 49.015956, lng: 19.973528 },
    { lat: 49.015956, lng: 19.97353 },
    { lat: 49.015937, lng: 19.973591 },
    { lat: 49.015891, lng: 19.973816 },
    { lat: 49.015866, lng: 19.974097 },
    { lat: 49.01587, lng: 19.974321 },
    { lat: 49.015912, lng: 19.974562 },
    { lat: 49.015974, lng: 19.974946 },
    { lat: 49.016009, lng: 19.975417 },
    { lat: 49.01591, lng: 19.975938 },
    { lat: 49.015925, lng: 19.976153 },
    { lat: 49.016001, lng: 19.976823 },
    { lat: 49.01599, lng: 19.977204 },
    { lat: 49.015956, lng: 19.977607 },
    { lat: 49.015829, lng: 19.978154 },
    { lat: 49.015752, lng: 19.978409 },
    { lat: 49.015664, lng: 19.978633 },
    { lat: 49.015548, lng: 19.978895 },
    { lat: 49.015464, lng: 19.979031 },
    { lat: 49.015289, lng: 19.979127 },
    { lat: 49.015032, lng: 19.979253 },
    { lat: 49.014907, lng: 19.979332 },
    { lat: 49.014596, lng: 19.979586 },
    { lat: 49.014353, lng: 19.979793 },
    { lat: 49.014197, lng: 19.979954 },
    { lat: 49.014095, lng: 19.980081 },
    { lat: 49.013935, lng: 19.98031 },
    { lat: 49.013796, lng: 19.980516 },
    { lat: 49.013781, lng: 19.980528 },
    { lat: 49.01378, lng: 19.980528 },
    { lat: 49.013673, lng: 19.98061 },
    { lat: 49.013584, lng: 19.980657 },
    { lat: 49.013583, lng: 19.980657 },
    { lat: 49.013454, lng: 19.980725 },
    { lat: 49.013335, lng: 19.980763 },
    { lat: 49.013226, lng: 19.980833 },
    { lat: 49.01311, lng: 19.980929 },
    { lat: 49.012861, lng: 19.981096 },
    { lat: 49.012506, lng: 19.981219 },
    { lat: 49.012414, lng: 19.981275 },
    { lat: 49.012361, lng: 19.981334 },
    { lat: 49.012349, lng: 19.981349 },
    { lat: 49.012348, lng: 19.98135 },
    { lat: 49.012266, lng: 19.981453 },
    { lat: 49.012273, lng: 19.981521 },
    { lat: 49.012275, lng: 19.98155 },
    { lat: 49.012263, lng: 19.981777 },
    { lat: 49.012256, lng: 19.981902 },
    { lat: 49.012271, lng: 19.982042 },
    { lat: 49.01238, lng: 19.982537 },
    { lat: 49.012563, lng: 19.983095 },
    { lat: 49.012665, lng: 19.983346 },
    { lat: 49.012667, lng: 19.983353 },
    { lat: 49.012718, lng: 19.983478 },
    { lat: 49.012965, lng: 19.98428 },
    { lat: 49.013039, lng: 19.9846 },
    { lat: 49.013093, lng: 19.984883 },
    { lat: 49.013163, lng: 19.985372 },
    { lat: 49.0132, lng: 19.98548 },
    { lat: 49.013234, lng: 19.985596 },
    { lat: 49.013244, lng: 19.985716 },
    { lat: 49.013856, lng: 19.985985 },
    { lat: 49.013956, lng: 19.986097 },
    { lat: 49.014052, lng: 19.98628 },
    { lat: 49.01411, lng: 19.986424 },
    { lat: 49.01411, lng: 19.986426 },
    { lat: 49.01415, lng: 19.986528 },
    { lat: 49.014192, lng: 19.986656 },
    { lat: 49.014321, lng: 19.987256 },
    { lat: 49.01438, lng: 19.98759 },
    { lat: 49.014417, lng: 19.987738 },
    { lat: 49.014519, lng: 19.98811 },
    { lat: 49.014529, lng: 19.988202 },
    { lat: 49.014573, lng: 19.988626 },
    { lat: 49.014573, lng: 19.988629 },
    { lat: 49.014596, lng: 19.988844 },
    { lat: 49.014587, lng: 19.988952 },
    { lat: 49.014574, lng: 19.989721 },
    { lat: 49.014616, lng: 19.989853 },
    { lat: 49.014728, lng: 19.990199 },
    { lat: 49.014842, lng: 19.990541 },
    { lat: 49.014913, lng: 19.990869 },
    { lat: 49.014914, lng: 19.99087 },
    { lat: 49.014957, lng: 19.991073 },
    { lat: 49.015023, lng: 19.99149 },
    { lat: 49.015026, lng: 19.991601 },
    { lat: 49.015012, lng: 19.991805 },
    { lat: 49.014983, lng: 19.992005 },
    { lat: 49.014926, lng: 19.992179 },
    { lat: 49.014762, lng: 19.992603 },
    { lat: 49.014695, lng: 19.992735 },
    { lat: 49.014693, lng: 19.992736 },
    { lat: 49.014588, lng: 19.992942 },
    { lat: 49.014531, lng: 19.993084 },
    { lat: 49.014503, lng: 19.993204 },
    { lat: 49.014503, lng: 19.993205 },
    { lat: 49.014487, lng: 19.993276 },
    { lat: 49.014502, lng: 19.993423 },
    { lat: 49.01454, lng: 19.993617 },
    { lat: 49.01454, lng: 19.99362 },
    { lat: 49.014567, lng: 19.99376 },
    { lat: 49.014568, lng: 19.993766 },
    { lat: 49.014638, lng: 19.994129 },
    { lat: 49.01465, lng: 19.994277 },
    { lat: 49.014646, lng: 19.994431 },
    { lat: 49.014629, lng: 19.994567 },
    { lat: 49.014647, lng: 19.99474 },
    { lat: 49.0147, lng: 19.995043 },
    { lat: 49.014845, lng: 19.995357 },
    { lat: 49.014919, lng: 19.995792 },
    { lat: 49.014909, lng: 19.995966 },
    { lat: 49.014793, lng: 19.996856 },
    { lat: 49.014791, lng: 19.997144 },
    { lat: 49.014811, lng: 19.997318 },
    { lat: 49.014842, lng: 19.997478 },
    { lat: 49.014901, lng: 19.997667 },
    { lat: 49.015014, lng: 19.997949 },
    { lat: 49.015093, lng: 19.998068 },
    { lat: 49.015161, lng: 19.998147 },
    { lat: 49.015277, lng: 19.998242 },
    { lat: 49.015362, lng: 19.99831 },
    { lat: 49.015434, lng: 19.998386 },
    { lat: 49.015498, lng: 19.998472 },
    { lat: 49.01556, lng: 19.998585 },
    { lat: 49.015626, lng: 19.998766 },
    { lat: 49.015805, lng: 19.999357 },
    { lat: 49.015915, lng: 19.999803 },
    { lat: 49.015996, lng: 20.000131 },
    { lat: 49.016143, lng: 20.000493 },
    { lat: 49.016374, lng: 20.000764 },
    { lat: 49.016405, lng: 20.000829 },
    { lat: 49.016439, lng: 20.000909 },
    { lat: 49.01649, lng: 20.001098 },
    { lat: 49.016505, lng: 20.001193 },
    { lat: 49.016507, lng: 20.001341 },
    { lat: 49.016495, lng: 20.001493 },
    { lat: 49.016461, lng: 20.001697 },
    { lat: 49.016391, lng: 20.002026 },
    { lat: 49.01628, lng: 20.002445 },
    { lat: 49.016279, lng: 20.002447 },
    { lat: 49.016239, lng: 20.002597 },
    { lat: 49.016149, lng: 20.002925 },
    { lat: 49.016097, lng: 20.003197 },
    { lat: 49.016097, lng: 20.003199 },
    { lat: 49.016093, lng: 20.00322 },
    { lat: 49.016096, lng: 20.003278 },
    { lat: 49.016106, lng: 20.003311 },
    { lat: 49.016107, lng: 20.003313 },
    { lat: 49.016128, lng: 20.003378 },
    { lat: 49.01616, lng: 20.00348 },
    { lat: 49.016188, lng: 20.003599 },
    { lat: 49.016196, lng: 20.003668 },
    { lat: 49.016197, lng: 20.003671 },
    { lat: 49.016202, lng: 20.003718 },
    { lat: 49.016195, lng: 20.003822 },
    { lat: 49.016158, lng: 20.004031 },
    { lat: 49.016111, lng: 20.004209 },
    { lat: 49.016034, lng: 20.004418 },
    { lat: 49.01596, lng: 20.004614 },
    { lat: 49.015906, lng: 20.004801 },
    { lat: 49.015822, lng: 20.005085 },
    { lat: 49.015736, lng: 20.005318 },
    { lat: 49.015696, lng: 20.005426 },
    { lat: 49.015609, lng: 20.005632 },
    { lat: 49.015552, lng: 20.0057 },
    { lat: 49.01547, lng: 20.005787 },
    { lat: 49.015448, lng: 20.0058 },
    { lat: 49.015448, lng: 20.005801 },
    { lat: 49.015411, lng: 20.005824 },
    { lat: 49.01539, lng: 20.005917 },
    { lat: 49.015354, lng: 20.006033 },
    { lat: 49.015198, lng: 20.006348 },
    { lat: 49.015049, lng: 20.006861 },
    { lat: 49.015094, lng: 20.007128 },
    { lat: 49.015104, lng: 20.007455 },
    { lat: 49.015081, lng: 20.007702 },
    { lat: 49.014969, lng: 20.008149 },
    { lat: 49.01486, lng: 20.008784 },
    { lat: 49.014822, lng: 20.008964 },
    { lat: 49.014806, lng: 20.00934 },
    { lat: 49.014779, lng: 20.00959 },
    { lat: 49.014804, lng: 20.009732 },
    { lat: 49.014843, lng: 20.009863 },
    { lat: 49.014859, lng: 20.010192 },
    { lat: 49.014961, lng: 20.01041 },
    { lat: 49.015135, lng: 20.010759 },
    { lat: 49.015219, lng: 20.010943 },
    { lat: 49.015321, lng: 20.011075 },
    { lat: 49.015453, lng: 20.011301 },
    { lat: 49.015645, lng: 20.011463 },
    { lat: 49.015812, lng: 20.011833 },
    { lat: 49.015837, lng: 20.011915 },
    { lat: 49.015903, lng: 20.012299 },
    { lat: 49.015905, lng: 20.012593 },
    { lat: 49.015872, lng: 20.013204 },
    { lat: 49.015822, lng: 20.013642 },
    { lat: 49.015822, lng: 20.013894 },
    { lat: 49.015841, lng: 20.01454 },
    { lat: 49.015892, lng: 20.014766 },
    { lat: 49.015939, lng: 20.015023 },
    { lat: 49.015943, lng: 20.015054 },
    { lat: 49.015977, lng: 20.015293 },
    { lat: 49.015998, lng: 20.015299 },
    { lat: 49.01606, lng: 20.01532 },
    { lat: 49.016363, lng: 20.0154 },
    { lat: 49.016771, lng: 20.015503 },
    { lat: 49.016829, lng: 20.015517 },
    { lat: 49.016839, lng: 20.015519 },
    { lat: 49.018654, lng: 20.015791 },
    { lat: 49.019201, lng: 20.015889 },
    { lat: 49.019545, lng: 20.015952 },
    { lat: 49.02014, lng: 20.015978 },
    { lat: 49.021251, lng: 20.01572 },
    { lat: 49.021733, lng: 20.015784 },
    { lat: 49.021853, lng: 20.015799 },
    { lat: 49.0226, lng: 20.015653 },
    { lat: 49.022987, lng: 20.015542 },
    { lat: 49.023684, lng: 20.015342 },
    { lat: 49.025176, lng: 20.014988 },
    { lat: 49.025953, lng: 20.014877 },
    { lat: 49.026272, lng: 20.014822 },
    { lat: 49.026812, lng: 20.014729 },
    { lat: 49.02807, lng: 20.014523 },
    { lat: 49.030308, lng: 20.014146 },
    { lat: 49.030496, lng: 20.014114 },
    { lat: 49.031924, lng: 20.013759 },
    { lat: 49.033108, lng: 20.013516 },
    { lat: 49.034828, lng: 20.013063 },
    { lat: 49.035438, lng: 20.01295 },
    { lat: 49.036045, lng: 20.012858 },
    { lat: 49.036571, lng: 20.013328 },
    { lat: 49.037004, lng: 20.013716 },
    { lat: 49.037658, lng: 20.013615 },
    { lat: 49.037908, lng: 20.014283 },
    { lat: 49.038498, lng: 20.015151 },
    { lat: 49.038768, lng: 20.015194 },
    { lat: 49.039163, lng: 20.015821 },
    { lat: 49.039601, lng: 20.016491 },
    { lat: 49.040313, lng: 20.016879 },
    { lat: 49.040341, lng: 20.016894 },
    { lat: 49.040362, lng: 20.016905 },
    { lat: 49.041094, lng: 20.017612 },
    { lat: 49.041563, lng: 20.018065 },
    { lat: 49.041628, lng: 20.018172 },
    { lat: 49.042152, lng: 20.019031 },
    { lat: 49.042381, lng: 20.019433 },
    { lat: 49.044015, lng: 20.021036 },
    { lat: 49.044522, lng: 20.021373 },
    { lat: 49.046029, lng: 20.022232 },
    { lat: 49.046056, lng: 20.022231 },
    { lat: 49.046206, lng: 20.02237 },
    { lat: 49.046368, lng: 20.022425 },
    { lat: 49.046611, lng: 20.021919 },
    { lat: 49.047371, lng: 20.022204 },
    { lat: 49.047768, lng: 20.022734 },
    { lat: 49.04819, lng: 20.023296 },
    { lat: 49.049068, lng: 20.021104 },
    { lat: 49.049193, lng: 20.021186 },
    { lat: 49.04927, lng: 20.021059 },
    { lat: 49.0493124, lng: 20.0212945 },
    { lat: 49.049347, lng: 20.021487 },
    { lat: 49.049511, lng: 20.021511 },
    { lat: 49.049571, lng: 20.021268 },
    { lat: 49.049621, lng: 20.021267 },
    { lat: 49.049646, lng: 20.021496 },
    { lat: 49.049784, lng: 20.021554 },
    { lat: 49.049826, lng: 20.021667 },
    { lat: 49.049841, lng: 20.021925 },
    { lat: 49.049966, lng: 20.022027 },
    { lat: 49.049977, lng: 20.022134 },
    { lat: 49.050081, lng: 20.022072 },
    { lat: 49.050121, lng: 20.022134 },
    { lat: 49.050119, lng: 20.022345 },
    { lat: 49.050164, lng: 20.022369 },
    { lat: 49.050365, lng: 20.022274 },
    { lat: 49.050332, lng: 20.02257 },
    { lat: 49.050398, lng: 20.022669 },
    { lat: 49.050603, lng: 20.022592 },
    { lat: 49.050682, lng: 20.022838 },
    { lat: 49.050791, lng: 20.022806 },
    { lat: 49.05088, lng: 20.023031 },
    { lat: 49.050945, lng: 20.02298 },
    { lat: 49.051042, lng: 20.023011 },
    { lat: 49.051093, lng: 20.023168 },
    { lat: 49.051221, lng: 20.023159 },
    { lat: 49.051384, lng: 20.023336 },
    { lat: 49.051581, lng: 20.023382 },
    { lat: 49.052198, lng: 20.023376 },
    { lat: 49.052322, lng: 20.023508 },
    { lat: 49.05245, lng: 20.023478 },
    { lat: 49.052588, lng: 20.023708 },
    { lat: 49.052754, lng: 20.023793 },
    { lat: 49.052906, lng: 20.023786 },
    { lat: 49.052991, lng: 20.02365 },
    { lat: 49.053027, lng: 20.023841 },
    { lat: 49.053158, lng: 20.023908 },
    { lat: 49.053432, lng: 20.024141 },
    { lat: 49.053979, lng: 20.024914 },
    { lat: 49.05429, lng: 20.025274 },
    { lat: 49.054359, lng: 20.025661 },
    { lat: 49.054912, lng: 20.026401 },
    { lat: 49.055326, lng: 20.026576 },
    { lat: 49.055583, lng: 20.027025 },
    { lat: 49.056294, lng: 20.02706 },
    { lat: 49.056771, lng: 20.026862 },
    { lat: 49.057658, lng: 20.02706 },
    { lat: 49.057994, lng: 20.026979 },
    { lat: 49.058511, lng: 20.027102 },
    { lat: 49.058935, lng: 20.027121 },
    { lat: 49.059505, lng: 20.027067 },
    { lat: 49.061211, lng: 20.026907 },
    { lat: 49.060945, lng: 20.025693 },
    { lat: 49.061015, lng: 20.025652 },
    { lat: 49.062406, lng: 20.025264 },
    { lat: 49.064052, lng: 20.024843 },
    { lat: 49.06425, lng: 20.024765 },
    { lat: 49.064425, lng: 20.024156 },
    { lat: 49.065484, lng: 20.022857 },
    { lat: 49.065977, lng: 20.021567 },
    { lat: 49.066579, lng: 20.020935 },
    { lat: 49.066942, lng: 20.020553 },
    { lat: 49.067771, lng: 20.020087 },
    { lat: 49.068231, lng: 20.020175 },
    { lat: 49.06832, lng: 20.020192 },
    { lat: 49.070635, lng: 20.019541 },
    { lat: 49.070801, lng: 20.019412 },
    { lat: 49.070879, lng: 20.019377 },
    { lat: 49.071171, lng: 20.0195 },
    { lat: 49.071283, lng: 20.019669 },
  ],
  VeternáPoruba: [
    { lat: 49.0984001, lng: 19.6942595 },
    { lat: 49.0985754, lng: 19.6944778 },
    { lat: 49.0985625, lng: 19.6947739 },
    { lat: 49.0985313, lng: 19.6949853 },
    { lat: 49.0985427, lng: 19.6951161 },
    { lat: 49.098611, lng: 19.6952087 },
    { lat: 49.0988406, lng: 19.6953081 },
    { lat: 49.0992411, lng: 19.695316 },
    { lat: 49.0993886, lng: 19.6953409 },
    { lat: 49.0994598, lng: 19.6953224 },
    { lat: 49.0997888, lng: 19.6956048 },
    { lat: 49.1002329, lng: 19.6957118 },
    { lat: 49.1003889, lng: 19.6957491 },
    { lat: 49.1007208, lng: 19.6958122 },
    { lat: 49.1012238, lng: 19.6954199 },
    { lat: 49.1015949, lng: 19.6952755 },
    { lat: 49.101978, lng: 19.6950429 },
    { lat: 49.1028796, lng: 19.6948528 },
    { lat: 49.1032708, lng: 19.6951094 },
    { lat: 49.1038651, lng: 19.6951751 },
    { lat: 49.1040964, lng: 19.6951321 },
    { lat: 49.1043276, lng: 19.6949085 },
    { lat: 49.104563, lng: 19.6949297 },
    { lat: 49.1046997, lng: 19.6948129 },
    { lat: 49.1049393, lng: 19.6946787 },
    { lat: 49.1049627, lng: 19.6946684 },
    { lat: 49.1051726, lng: 19.6944984 },
    { lat: 49.1052987, lng: 19.6945998 },
    { lat: 49.1054643, lng: 19.6945768 },
    { lat: 49.1057046, lng: 19.6946345 },
    { lat: 49.1060137, lng: 19.6944386 },
    { lat: 49.1062118, lng: 19.6945132 },
    { lat: 49.1068502, lng: 19.6945036 },
    { lat: 49.107209, lng: 19.6944119 },
    { lat: 49.1074094, lng: 19.694026 },
    { lat: 49.1075219, lng: 19.6938962 },
    { lat: 49.107559, lng: 19.6938949 },
    { lat: 49.107748, lng: 19.6938619 },
    { lat: 49.1077903, lng: 19.6936976 },
    { lat: 49.1080714, lng: 19.6934807 },
    { lat: 49.1086541, lng: 19.6933859 },
    { lat: 49.1089699, lng: 19.6934157 },
    { lat: 49.1091962, lng: 19.6932858 },
    { lat: 49.1093016, lng: 19.6932987 },
    { lat: 49.1095319, lng: 19.6932331 },
    { lat: 49.1097991, lng: 19.6932154 },
    { lat: 49.1099186, lng: 19.6931147 },
    { lat: 49.1100746, lng: 19.6929207 },
    { lat: 49.1102382, lng: 19.6921611 },
    { lat: 49.110243, lng: 19.6915798 },
    { lat: 49.1101449, lng: 19.6910145 },
    { lat: 49.1104785, lng: 19.6899184 },
    { lat: 49.1109496, lng: 19.6887039 },
    { lat: 49.1109707, lng: 19.6886679 },
    { lat: 49.1120503, lng: 19.686765 },
    { lat: 49.1124036, lng: 19.6861422 },
    { lat: 49.1136988, lng: 19.6841666 },
    { lat: 49.1139795, lng: 19.6836588 },
    { lat: 49.11411, lng: 19.6837339 },
    { lat: 49.1142637, lng: 19.6835153 },
    { lat: 49.1145134, lng: 19.6831754 },
    { lat: 49.1145407, lng: 19.6831316 },
    { lat: 49.1156686, lng: 19.6842023 },
    { lat: 49.1166013, lng: 19.6852234 },
    { lat: 49.1172964, lng: 19.6863404 },
    { lat: 49.1182829, lng: 19.6879164 },
    { lat: 49.1187828, lng: 19.688774 },
    { lat: 49.1189512, lng: 19.6889161 },
    { lat: 49.1191935, lng: 19.6890459 },
    { lat: 49.1193261, lng: 19.6892161 },
    { lat: 49.1194379, lng: 19.689428 },
    { lat: 49.1195415, lng: 19.6896832 },
    { lat: 49.1196515, lng: 19.6899277 },
    { lat: 49.1197577, lng: 19.690061 },
    { lat: 49.1200593, lng: 19.6903114 },
    { lat: 49.1202242, lng: 19.690474 },
    { lat: 49.1204336, lng: 19.6901364 },
    { lat: 49.1206086, lng: 19.68984 },
    { lat: 49.1209488, lng: 19.6895563 },
    { lat: 49.1213188, lng: 19.6891083 },
    { lat: 49.1213388, lng: 19.6889998 },
    { lat: 49.1214032, lng: 19.6889303 },
    { lat: 49.1214208, lng: 19.6887659 },
    { lat: 49.1215671, lng: 19.6886102 },
    { lat: 49.1216077, lng: 19.6884539 },
    { lat: 49.1205983, lng: 19.6874877 },
    { lat: 49.1207288, lng: 19.6872297 },
    { lat: 49.1208193, lng: 19.6870352 },
    { lat: 49.1208457, lng: 19.6869696 },
    { lat: 49.1209873, lng: 19.6866252 },
    { lat: 49.1210666, lng: 19.6863596 },
    { lat: 49.1211643, lng: 19.6860154 },
    { lat: 49.1212136, lng: 19.6857837 },
    { lat: 49.121262, lng: 19.6854274 },
    { lat: 49.1212768, lng: 19.6852003 },
    { lat: 49.1212593, lng: 19.684955 },
    { lat: 49.1211424, lng: 19.6847555 },
    { lat: 49.1212147, lng: 19.6826734 },
    { lat: 49.1213316, lng: 19.6811086 },
    { lat: 49.1213567, lng: 19.680759 },
    { lat: 49.121368, lng: 19.6806089 },
    { lat: 49.121384, lng: 19.6804371 },
    { lat: 49.1213961, lng: 19.6802985 },
    { lat: 49.1214163, lng: 19.6803021 },
    { lat: 49.1214109, lng: 19.6802815 },
    { lat: 49.1213961, lng: 19.680224 },
    { lat: 49.1214253, lng: 19.6800199 },
    { lat: 49.1214191, lng: 19.6798803 },
    { lat: 49.1213906, lng: 19.6798214 },
    { lat: 49.1212362, lng: 19.6797958 },
    { lat: 49.1211502, lng: 19.6796335 },
    { lat: 49.121003, lng: 19.6794115 },
    { lat: 49.1209238, lng: 19.6792765 },
    { lat: 49.1207797, lng: 19.6788942 },
    { lat: 49.120542, lng: 19.6785172 },
    { lat: 49.1204402, lng: 19.6784565 },
    { lat: 49.1203595, lng: 19.6782601 },
    { lat: 49.1202709, lng: 19.6781944 },
    { lat: 49.1200223, lng: 19.6778685 },
    { lat: 49.1199959, lng: 19.6777579 },
    { lat: 49.1200085, lng: 19.6776113 },
    { lat: 49.119984, lng: 19.677462 },
    { lat: 49.1199074, lng: 19.6772832 },
    { lat: 49.1197753, lng: 19.6768961 },
    { lat: 49.1196634, lng: 19.6768393 },
    { lat: 49.119606, lng: 19.6767362 },
    { lat: 49.1194958, lng: 19.6766677 },
    { lat: 49.1193857, lng: 19.6764678 },
    { lat: 49.1192702, lng: 19.676356 },
    { lat: 49.1192775, lng: 19.6762387 },
    { lat: 49.1191445, lng: 19.6759858 },
    { lat: 49.1191065, lng: 19.6758362 },
    { lat: 49.1191142, lng: 19.6757059 },
    { lat: 49.1190656, lng: 19.675681 },
    { lat: 49.1189929, lng: 19.6757122 },
    { lat: 49.1189788, lng: 19.6757029 },
    { lat: 49.1189, lng: 19.6754484 },
    { lat: 49.1187525, lng: 19.6754492 },
    { lat: 49.1187169, lng: 19.675386 },
    { lat: 49.1187248, lng: 19.6751805 },
    { lat: 49.1186883, lng: 19.6751191 },
    { lat: 49.1186231, lng: 19.6751233 },
    { lat: 49.1185814, lng: 19.6750999 },
    { lat: 49.1185606, lng: 19.6750884 },
    { lat: 49.1185105, lng: 19.6750964 },
    { lat: 49.1184623, lng: 19.6750546 },
    { lat: 49.1184542, lng: 19.6749979 },
    { lat: 49.1185417, lng: 19.6748105 },
    { lat: 49.118524, lng: 19.6747206 },
    { lat: 49.1183199, lng: 19.6747642 },
    { lat: 49.1182744, lng: 19.6747013 },
    { lat: 49.1182443, lng: 19.6746098 },
    { lat: 49.1182557, lng: 19.6744079 },
    { lat: 49.1182274, lng: 19.6743296 },
    { lat: 49.1181306, lng: 19.6742539 },
    { lat: 49.1180437, lng: 19.6743017 },
    { lat: 49.1179659, lng: 19.6741766 },
    { lat: 49.1179239, lng: 19.6740374 },
    { lat: 49.1178806, lng: 19.6740041 },
    { lat: 49.1178195, lng: 19.6740003 },
    { lat: 49.1177692, lng: 19.6739026 },
    { lat: 49.1177627, lng: 19.6737835 },
    { lat: 49.1175617, lng: 19.6735952 },
    { lat: 49.1173252, lng: 19.6733408 },
    { lat: 49.1172557, lng: 19.6731997 },
    { lat: 49.1171743, lng: 19.6729876 },
    { lat: 49.1170753, lng: 19.6728892 },
    { lat: 49.1170188, lng: 19.6728342 },
    { lat: 49.1168422, lng: 19.6723589 },
    { lat: 49.1166207, lng: 19.6719105 },
    { lat: 49.116611, lng: 19.6717655 },
    { lat: 49.1166443, lng: 19.6716545 },
    { lat: 49.1165275, lng: 19.6714335 },
    { lat: 49.1164376, lng: 19.6713366 },
    { lat: 49.1164204, lng: 19.6711525 },
    { lat: 49.1164347, lng: 19.6710708 },
    { lat: 49.1164182, lng: 19.6709747 },
    { lat: 49.1163624, lng: 19.6709095 },
    { lat: 49.1163474, lng: 19.6709053 },
    { lat: 49.1162515, lng: 19.6708738 },
    { lat: 49.1161856, lng: 19.6708357 },
    { lat: 49.1161863, lng: 19.6707486 },
    { lat: 49.1162122, lng: 19.6706732 },
    { lat: 49.1162109, lng: 19.6704957 },
    { lat: 49.1161298, lng: 19.6704163 },
    { lat: 49.1160011, lng: 19.6704382 },
    { lat: 49.1159654, lng: 19.6703418 },
    { lat: 49.1159077, lng: 19.6701309 },
    { lat: 49.1159317, lng: 19.670013 },
    { lat: 49.1158485, lng: 19.6699147 },
    { lat: 49.1156935, lng: 19.6695699 },
    { lat: 49.1156168, lng: 19.6693142 },
    { lat: 49.1155719, lng: 19.6692447 },
    { lat: 49.1154965, lng: 19.6692705 },
    { lat: 49.1155047, lng: 19.6693808 },
    { lat: 49.1154569, lng: 19.6694472 },
    { lat: 49.1153685, lng: 19.6694347 },
    { lat: 49.1152836, lng: 19.6693452 },
    { lat: 49.1152632, lng: 19.6691143 },
    { lat: 49.1150932, lng: 19.6689369 },
    { lat: 49.1150886, lng: 19.6688611 },
    { lat: 49.1151301, lng: 19.6686525 },
    { lat: 49.1150762, lng: 19.6684042 },
    { lat: 49.1150187, lng: 19.6683752 },
    { lat: 49.1148776, lng: 19.6683976 },
    { lat: 49.114853, lng: 19.6683027 },
    { lat: 49.1148525, lng: 19.6681618 },
    { lat: 49.1148245, lng: 19.6681165 },
    { lat: 49.1147498, lng: 19.6681565 },
    { lat: 49.1146551, lng: 19.6681839 },
    { lat: 49.1145482, lng: 19.6681319 },
    { lat: 49.1144117, lng: 19.6681291 },
    { lat: 49.114364, lng: 19.6680444 },
    { lat: 49.1144152, lng: 19.6678262 },
    { lat: 49.114409, lng: 19.6676866 },
    { lat: 49.1143406, lng: 19.6675922 },
    { lat: 49.1141409, lng: 19.6676872 },
    { lat: 49.1140958, lng: 19.667561 },
    { lat: 49.1141322, lng: 19.667417 },
    { lat: 49.1142007, lng: 19.6672855 },
    { lat: 49.1141827, lng: 19.667183 },
    { lat: 49.1139542, lng: 19.6672163 },
    { lat: 49.1137912, lng: 19.6671964 },
    { lat: 49.1136357, lng: 19.6672947 },
    { lat: 49.1135072, lng: 19.6672173 },
    { lat: 49.1134547, lng: 19.6670515 },
    { lat: 49.1134412, lng: 19.6669015 },
    { lat: 49.1132942, lng: 19.666835 },
    { lat: 49.1132038, lng: 19.666909 },
    { lat: 49.113198, lng: 19.6669014 },
    { lat: 49.1131309, lng: 19.6666119 },
    { lat: 49.1129333, lng: 19.6663453 },
    { lat: 49.1129059, lng: 19.6662873 },
    { lat: 49.1128434, lng: 19.6661981 },
    { lat: 49.1126809, lng: 19.6660904 },
    { lat: 49.1125612, lng: 19.6661113 },
    { lat: 49.1125559, lng: 19.6660925 },
    { lat: 49.1125806, lng: 19.6659094 },
    { lat: 49.1124341, lng: 19.6657842 },
    { lat: 49.1123201, lng: 19.6657535 },
    { lat: 49.1122351, lng: 19.6655871 },
    { lat: 49.1121912, lng: 19.6655413 },
    { lat: 49.112149, lng: 19.6656539 },
    { lat: 49.1121115, lng: 19.6656225 },
    { lat: 49.1119925, lng: 19.6655053 },
    { lat: 49.111886, lng: 19.6654663 },
    { lat: 49.1118029, lng: 19.6654989 },
    { lat: 49.1115964, lng: 19.6652612 },
    { lat: 49.1113409, lng: 19.6652922 },
    { lat: 49.1112479, lng: 19.6653039 },
    { lat: 49.1112077, lng: 19.6648557 },
    { lat: 49.1110016, lng: 19.6650624 },
    { lat: 49.110855, lng: 19.6650297 },
    { lat: 49.1108491, lng: 19.6648965 },
    { lat: 49.1109372, lng: 19.6648292 },
    { lat: 49.1108489, lng: 19.664643 },
    { lat: 49.1106789, lng: 19.664744 },
    { lat: 49.1106616, lng: 19.664736 },
    { lat: 49.1106681, lng: 19.66448 },
    { lat: 49.1105436, lng: 19.6643864 },
    { lat: 49.1102605, lng: 19.6643285 },
    { lat: 49.1102705, lng: 19.6642236 },
    { lat: 49.1101577, lng: 19.6640431 },
    { lat: 49.1101735, lng: 19.6637923 },
    { lat: 49.1101475, lng: 19.6637382 },
    { lat: 49.1099891, lng: 19.6637494 },
    { lat: 49.1099786, lng: 19.6636412 },
    { lat: 49.1098663, lng: 19.6635956 },
    { lat: 49.1097606, lng: 19.6634198 },
    { lat: 49.1096458, lng: 19.6630479 },
    { lat: 49.1094977, lng: 19.6629816 },
    { lat: 49.1094645, lng: 19.6629454 },
    { lat: 49.1094354, lng: 19.6630524 },
    { lat: 49.1092996, lng: 19.6627107 },
    { lat: 49.1092989, lng: 19.6626672 },
    { lat: 49.109356, lng: 19.6624808 },
    { lat: 49.1092367, lng: 19.6624572 },
    { lat: 49.1092005, lng: 19.6624037 },
    { lat: 49.1089124, lng: 19.6624863 },
    { lat: 49.1087304, lng: 19.6623209 },
    { lat: 49.1085452, lng: 19.6623349 },
    { lat: 49.1081835, lng: 19.6624153 },
    { lat: 49.107986, lng: 19.6622516 },
    { lat: 49.1077487, lng: 19.6622627 },
    { lat: 49.1075824, lng: 19.662041 },
    { lat: 49.1072168, lng: 19.6620553 },
    { lat: 49.1070755, lng: 19.6619052 },
    { lat: 49.1070018, lng: 19.6618333 },
    { lat: 49.1069593, lng: 19.6619118 },
    { lat: 49.1066125, lng: 19.6620201 },
    { lat: 49.1065421, lng: 19.661858 },
    { lat: 49.1064936, lng: 19.661676 },
    { lat: 49.1063362, lng: 19.6616872 },
    { lat: 49.1059001, lng: 19.6614045 },
    { lat: 49.1057579, lng: 19.66117 },
    { lat: 49.1056635, lng: 19.6611481 },
    { lat: 49.1055874, lng: 19.6612375 },
    { lat: 49.1054554, lng: 19.6611835 },
    { lat: 49.1052617, lng: 19.6610582 },
    { lat: 49.1051445, lng: 19.6610095 },
    { lat: 49.1049042, lng: 19.660871 },
    { lat: 49.1048844, lng: 19.6606534 },
    { lat: 49.1047543, lng: 19.6604898 },
    { lat: 49.1045718, lng: 19.6606956 },
    { lat: 49.1044418, lng: 19.6605069 },
    { lat: 49.1043132, lng: 19.6604756 },
    { lat: 49.1041615, lng: 19.6602812 },
    { lat: 49.1040748, lng: 19.6602067 },
    { lat: 49.1039684, lng: 19.6602242 },
    { lat: 49.1039172, lng: 19.6602611 },
    { lat: 49.1038067, lng: 19.6602347 },
    { lat: 49.1037363, lng: 19.6601496 },
    { lat: 49.1035852, lng: 19.6600163 },
    { lat: 49.1034942, lng: 19.6600231 },
    { lat: 49.103354, lng: 19.6600088 },
    { lat: 49.1032069, lng: 19.6598701 },
    { lat: 49.1030488, lng: 19.6595322 },
    { lat: 49.1029555, lng: 19.6594616 },
    { lat: 49.1028702, lng: 19.6594647 },
    { lat: 49.1027879, lng: 19.6596152 },
    { lat: 49.1026739, lng: 19.6596909 },
    { lat: 49.1026216, lng: 19.6595295 },
    { lat: 49.1025657, lng: 19.6594346 },
    { lat: 49.1024604, lng: 19.6594242 },
    { lat: 49.1022912, lng: 19.6594467 },
    { lat: 49.1020071, lng: 19.6593648 },
    { lat: 49.101863, lng: 19.6594171 },
    { lat: 49.1017967, lng: 19.659498 },
    { lat: 49.1016822, lng: 19.659609 },
    { lat: 49.1015092, lng: 19.6597207 },
    { lat: 49.1014541, lng: 19.6597727 },
    { lat: 49.1013696, lng: 19.6598257 },
    { lat: 49.1010887, lng: 19.6596885 },
    { lat: 49.1009396, lng: 19.6597812 },
    { lat: 49.1009339, lng: 19.6597533 },
    { lat: 49.1009175, lng: 19.6597683 },
    { lat: 49.1006412, lng: 19.6599147 },
    { lat: 49.1003768, lng: 19.6598927 },
    { lat: 49.0998123, lng: 19.6599944 },
    { lat: 49.099601, lng: 19.6599838 },
    { lat: 49.0994547, lng: 19.6600118 },
    { lat: 49.099189, lng: 19.6600147 },
    { lat: 49.0987607, lng: 19.6597065 },
    { lat: 49.0986842, lng: 19.6596587 },
    { lat: 49.0983874, lng: 19.6596986 },
    { lat: 49.098181, lng: 19.6596192 },
    { lat: 49.0977102, lng: 19.6589378 },
    { lat: 49.0976007, lng: 19.6588542 },
    { lat: 49.0974186, lng: 19.6588413 },
    { lat: 49.0972818, lng: 19.6588008 },
    { lat: 49.0972247, lng: 19.6584827 },
    { lat: 49.0971473, lng: 19.6584145 },
    { lat: 49.0969803, lng: 19.6584569 },
    { lat: 49.0969899, lng: 19.6584703 },
    { lat: 49.096923, lng: 19.6584887 },
    { lat: 49.0965287, lng: 19.6581586 },
    { lat: 49.0964266, lng: 19.6580929 },
    { lat: 49.0963781, lng: 19.6581173 },
    { lat: 49.0964107, lng: 19.6581913 },
    { lat: 49.0968543, lng: 19.6590958 },
    { lat: 49.0970288, lng: 19.6592712 },
    { lat: 49.0977328, lng: 19.6596481 },
    { lat: 49.0976692, lng: 19.659811 },
    { lat: 49.0977862, lng: 19.6601114 },
    { lat: 49.0977858, lng: 19.6602082 },
    { lat: 49.0977458, lng: 19.6602695 },
    { lat: 49.0973761, lng: 19.660318 },
    { lat: 49.0969874, lng: 19.6602412 },
    { lat: 49.0968703, lng: 19.6602946 },
    { lat: 49.0967725, lng: 19.6603992 },
    { lat: 49.0967018, lng: 19.6605826 },
    { lat: 49.0966635, lng: 19.6607354 },
    { lat: 49.0966053, lng: 19.6611006 },
    { lat: 49.0965847, lng: 19.661294 },
    { lat: 49.0965548, lng: 19.6620154 },
    { lat: 49.096514, lng: 19.6636104 },
    { lat: 49.0965456, lng: 19.6641206 },
    { lat: 49.0967347, lng: 19.6647205 },
    { lat: 49.0952134, lng: 19.6640581 },
    { lat: 49.0949946, lng: 19.6652171 },
    { lat: 49.0946718, lng: 19.6678175 },
    { lat: 49.0946626, lng: 19.6678659 },
    { lat: 49.095008, lng: 19.6679307 },
    { lat: 49.0952528, lng: 19.6678659 },
    { lat: 49.0956275, lng: 19.6674981 },
    { lat: 49.0957543, lng: 19.6674351 },
    { lat: 49.0960658, lng: 19.6673951 },
    { lat: 49.0963009, lng: 19.6674378 },
    { lat: 49.0966531, lng: 19.6674512 },
    { lat: 49.0968182, lng: 19.6674899 },
    { lat: 49.0969635, lng: 19.6675408 },
    { lat: 49.0973244, lng: 19.6677039 },
    { lat: 49.0976037, lng: 19.6679071 },
    { lat: 49.0979343, lng: 19.6680945 },
    { lat: 49.0984196, lng: 19.6682887 },
    { lat: 49.0979835, lng: 19.6703477 },
    { lat: 49.0978998, lng: 19.671277 },
    { lat: 49.0979419, lng: 19.6716944 },
    { lat: 49.0979178, lng: 19.6718116 },
    { lat: 49.0979206, lng: 19.6718742 },
    { lat: 49.0979341, lng: 19.6719505 },
    { lat: 49.0979223, lng: 19.6720894 },
    { lat: 49.0979405, lng: 19.6722493 },
    { lat: 49.0979206, lng: 19.6723865 },
    { lat: 49.097935, lng: 19.6725037 },
    { lat: 49.0979078, lng: 19.6728052 },
    { lat: 49.0978869, lng: 19.6732974 },
    { lat: 49.0978914, lng: 19.6734523 },
    { lat: 49.097881, lng: 19.6737021 },
    { lat: 49.0978996, lng: 19.6739688 },
    { lat: 49.0978937, lng: 19.674138 },
    { lat: 49.0979024, lng: 19.6742601 },
    { lat: 49.097874, lng: 19.6745358 },
    { lat: 49.0977884, lng: 19.6747618 },
    { lat: 49.0977927, lng: 19.6750885 },
    { lat: 49.0977274, lng: 19.6752448 },
    { lat: 49.0977908, lng: 19.6754561 },
    { lat: 49.097801, lng: 19.6756068 },
    { lat: 49.0977155, lng: 19.6761703 },
    { lat: 49.0977115, lng: 19.6764109 },
    { lat: 49.0976506, lng: 19.6765599 },
    { lat: 49.097629, lng: 19.6766858 },
    { lat: 49.0976227, lng: 19.6771772 },
    { lat: 49.0975695, lng: 19.6773516 },
    { lat: 49.0974219, lng: 19.6782152 },
    { lat: 49.0973919, lng: 19.6786077 },
    { lat: 49.0973623, lng: 19.678858 },
    { lat: 49.0973131, lng: 19.6795002 },
    { lat: 49.0973759, lng: 19.6794161 },
    { lat: 49.0974374, lng: 19.6794547 },
    { lat: 49.0974875, lng: 19.6794193 },
    { lat: 49.0975479, lng: 19.6794606 },
    { lat: 49.0976517, lng: 19.6794647 },
    { lat: 49.0976939, lng: 19.6795269 },
    { lat: 49.0977417, lng: 19.6795619 },
    { lat: 49.0977885, lng: 19.6795262 },
    { lat: 49.0978498, lng: 19.6795339 },
    { lat: 49.0979011, lng: 19.6793708 },
    { lat: 49.0980186, lng: 19.6793523 },
    { lat: 49.0980694, lng: 19.6793061 },
    { lat: 49.0981309, lng: 19.6793461 },
    { lat: 49.0982105, lng: 19.6793092 },
    { lat: 49.098316, lng: 19.6793519 },
    { lat: 49.0983834, lng: 19.6792206 },
    { lat: 49.0984322, lng: 19.6792293 },
    { lat: 49.0984689, lng: 19.6793168 },
    { lat: 49.0985353, lng: 19.6793675 },
    { lat: 49.0985665, lng: 19.6794373 },
    { lat: 49.0986047, lng: 19.6794352 },
    { lat: 49.0986512, lng: 19.6793938 },
    { lat: 49.0986965, lng: 19.6794463 },
    { lat: 49.0987342, lng: 19.6794331 },
    { lat: 49.0988764, lng: 19.6794627 },
    { lat: 49.0990333, lng: 19.679343 },
    { lat: 49.0991104, lng: 19.6793556 },
    { lat: 49.0991568, lng: 19.6793861 },
    { lat: 49.0993126, lng: 19.6796469 },
    { lat: 49.0994388, lng: 19.6797728 },
    { lat: 49.099427, lng: 19.681444 },
    { lat: 49.1007551, lng: 19.6829135 },
    { lat: 49.1008214, lng: 19.6843121 },
    { lat: 49.1006866, lng: 19.6846279 },
    { lat: 49.1006513, lng: 19.6848484 },
    { lat: 49.1006174, lng: 19.6853847 },
    { lat: 49.1005241, lng: 19.685492 },
    { lat: 49.0998158, lng: 19.6856509 },
    { lat: 49.0997718, lng: 19.685723 },
    { lat: 49.0993896, lng: 19.6865898 },
    { lat: 49.0995252, lng: 19.6878674 },
    { lat: 49.0994979, lng: 19.6901579 },
    { lat: 49.0998134, lng: 19.6926908 },
    { lat: 49.0990007, lng: 19.6935495 },
    { lat: 49.098584, lng: 19.6940344 },
    { lat: 49.0984963, lng: 19.6941185 },
    { lat: 49.0984001, lng: 19.6942595 },
  ],
  VeľkéBorové: [
    { lat: 49.1944902, lng: 19.5377323 },
    { lat: 49.1948772, lng: 19.5381183 },
    { lat: 49.1956159, lng: 19.5384019 },
    { lat: 49.1963906, lng: 19.5382473 },
    { lat: 49.1969605, lng: 19.5381165 },
    { lat: 49.1970003, lng: 19.5380986 },
    { lat: 49.197132, lng: 19.538071 },
    { lat: 49.1977393, lng: 19.5378483 },
    { lat: 49.1980866, lng: 19.5380994 },
    { lat: 49.1982865, lng: 19.5382375 },
    { lat: 49.201201, lng: 19.5399206 },
    { lat: 49.2015325, lng: 19.5403007 },
    { lat: 49.2033711, lng: 19.5414627 },
    { lat: 49.2038247, lng: 19.5415737 },
    { lat: 49.2055798, lng: 19.5428838 },
    { lat: 49.2055998, lng: 19.5428773 },
    { lat: 49.2056553, lng: 19.542861 },
    { lat: 49.2056832, lng: 19.5428523 },
    { lat: 49.2060856, lng: 19.5431558 },
    { lat: 49.2064891, lng: 19.5434587 },
    { lat: 49.2067807, lng: 19.5436773 },
    { lat: 49.206943, lng: 19.5436007 },
    { lat: 49.2070774, lng: 19.5436856 },
    { lat: 49.2072948, lng: 19.5440796 },
    { lat: 49.2074249, lng: 19.5443419 },
    { lat: 49.2074317, lng: 19.5443401 },
    { lat: 49.2075753, lng: 19.5443048 },
    { lat: 49.2076175, lng: 19.5442938 },
    { lat: 49.2082529, lng: 19.5446116 },
    { lat: 49.2084512, lng: 19.5447114 },
    { lat: 49.2084637, lng: 19.5447151 },
    { lat: 49.2086134, lng: 19.5447627 },
    { lat: 49.2087416, lng: 19.5448043 },
    { lat: 49.2088719, lng: 19.5448454 },
    { lat: 49.2090068, lng: 19.5448888 },
    { lat: 49.2090846, lng: 19.5449134 },
    { lat: 49.2091266, lng: 19.5449475 },
    { lat: 49.2091826, lng: 19.5449913 },
    { lat: 49.209247, lng: 19.5450429 },
    { lat: 49.2093474, lng: 19.5451217 },
    { lat: 49.2094351, lng: 19.545191 },
    { lat: 49.2097191, lng: 19.5454158 },
    { lat: 49.2099185, lng: 19.5454639 },
    { lat: 49.2099475, lng: 19.5455099 },
    { lat: 49.2105324, lng: 19.5441418 },
    { lat: 49.2106748, lng: 19.542763 },
    { lat: 49.2107115, lng: 19.5424049 },
    { lat: 49.2109844, lng: 19.5418103 },
    { lat: 49.2114983, lng: 19.5408446 },
    { lat: 49.2117444, lng: 19.5407523 },
    { lat: 49.2127096, lng: 19.5397021 },
    { lat: 49.2138985, lng: 19.537648 },
    { lat: 49.2140285, lng: 19.5370125 },
    { lat: 49.2139916, lng: 19.5361979 },
    { lat: 49.213942, lng: 19.5347828 },
    { lat: 49.2153301, lng: 19.5342601 },
    { lat: 49.2163487, lng: 19.5345102 },
    { lat: 49.2165664, lng: 19.5343917 },
    { lat: 49.2169032, lng: 19.5350582 },
    { lat: 49.2169574, lng: 19.5350088 },
    { lat: 49.217279, lng: 19.5355208 },
    { lat: 49.2174917, lng: 19.5345937 },
    { lat: 49.2176336, lng: 19.5339493 },
    { lat: 49.2177861, lng: 19.5332152 },
    { lat: 49.2180569, lng: 19.5315823 },
    { lat: 49.2181958, lng: 19.5299301 },
    { lat: 49.2181637, lng: 19.5289235 },
    { lat: 49.2181475, lng: 19.528421 },
    { lat: 49.2181148, lng: 19.5276239 },
    { lat: 49.2181069, lng: 19.5276273 },
    { lat: 49.2179091, lng: 19.5269944 },
    { lat: 49.2178511, lng: 19.5268084 },
    { lat: 49.2174589, lng: 19.5255863 },
    { lat: 49.217674, lng: 19.5238532 },
    { lat: 49.2177763, lng: 19.5234239 },
    { lat: 49.218121, lng: 19.522136 },
    { lat: 49.21713, lng: 19.520222 },
    { lat: 49.215684, lng: 19.518106 },
    { lat: 49.214453, lng: 19.516335 },
    { lat: 49.213816, lng: 19.514391 },
    { lat: 49.213474, lng: 19.513524 },
    { lat: 49.212959, lng: 19.512889 },
    { lat: 49.212223, lng: 19.512013 },
    { lat: 49.211782, lng: 19.511755 },
    { lat: 49.210905, lng: 19.51153 },
    { lat: 49.210115, lng: 19.511268 },
    { lat: 49.209988, lng: 19.511226 },
    { lat: 49.208614, lng: 19.510741 },
    { lat: 49.207962, lng: 19.510499 },
    { lat: 49.207024, lng: 19.510259 },
    { lat: 49.206892, lng: 19.510212 },
    { lat: 49.206513, lng: 19.509768 },
    { lat: 49.206316, lng: 19.508364 },
    { lat: 49.206258, lng: 19.507998 },
    { lat: 49.206069, lng: 19.507552 },
    { lat: 49.205932, lng: 19.50715 },
    { lat: 49.205875, lng: 19.506984 },
    { lat: 49.205314, lng: 19.504929 },
    { lat: 49.204685, lng: 19.503518 },
    { lat: 49.204406, lng: 19.503269 },
    { lat: 49.204304, lng: 19.502957 },
    { lat: 49.2042, lng: 19.50268 },
    { lat: 49.204123, lng: 19.502627 },
    { lat: 49.20402, lng: 19.502565 },
    { lat: 49.203906, lng: 19.502484 },
    { lat: 49.203925, lng: 19.502403 },
    { lat: 49.203941, lng: 19.502363 },
    { lat: 49.203957, lng: 19.502321 },
    { lat: 49.203409, lng: 19.501681 },
    { lat: 49.202736, lng: 19.500585 },
    { lat: 49.202607, lng: 19.500309 },
    { lat: 49.202491, lng: 19.499747 },
    { lat: 49.202394, lng: 19.499278 },
    { lat: 49.201654, lng: 19.498522 },
    { lat: 49.2011, lng: 19.497904 },
    { lat: 49.20081, lng: 19.497581 },
    { lat: 49.199395, lng: 19.49621 },
    { lat: 49.199398, lng: 19.49503 },
    { lat: 49.197469, lng: 19.492378 },
    { lat: 49.197386, lng: 19.491837 },
    { lat: 49.197353, lng: 19.491534 },
    { lat: 49.1967181, lng: 19.4908975 },
    { lat: 49.1952801, lng: 19.4935275 },
    { lat: 49.195265, lng: 19.4935687 },
    { lat: 49.1945667, lng: 19.4952226 },
    { lat: 49.1934368, lng: 19.4967794 },
    { lat: 49.1942996, lng: 19.4988939 },
    { lat: 49.194166, lng: 19.4991783 },
    { lat: 49.1937883, lng: 19.4992199 },
    { lat: 49.1936149, lng: 19.4991473 },
    { lat: 49.1932895, lng: 19.4990785 },
    { lat: 49.1931917, lng: 19.4989117 },
    { lat: 49.1930886, lng: 19.4988261 },
    { lat: 49.1928859, lng: 19.4989777 },
    { lat: 49.192732, lng: 19.4990366 },
    { lat: 49.1926435, lng: 19.4989758 },
    { lat: 49.192619, lng: 19.4987799 },
    { lat: 49.1925984, lng: 19.498727 },
    { lat: 49.1925178, lng: 19.498787 },
    { lat: 49.1924018, lng: 19.4987864 },
    { lat: 49.1923088, lng: 19.4987499 },
    { lat: 49.1921403, lng: 19.4988099 },
    { lat: 49.1920882, lng: 19.49893 },
    { lat: 49.1919034, lng: 19.4990243 },
    { lat: 49.1917779, lng: 19.4988204 },
    { lat: 49.1915375, lng: 19.4989577 },
    { lat: 49.1913718, lng: 19.4989335 },
    { lat: 49.1912117, lng: 19.4989535 },
    { lat: 49.191141, lng: 19.4990093 },
    { lat: 49.19096, lng: 19.4992904 },
    { lat: 49.1908173, lng: 19.4995722 },
    { lat: 49.1901482, lng: 19.5003625 },
    { lat: 49.1898627, lng: 19.5006717 },
    { lat: 49.1891545, lng: 19.5011822 },
    { lat: 49.1883128, lng: 19.5016216 },
    { lat: 49.1880718, lng: 19.5016735 },
    { lat: 49.1879729, lng: 19.501831 },
    { lat: 49.187801, lng: 19.5018144 },
    { lat: 49.1876481, lng: 19.5015741 },
    { lat: 49.1875213, lng: 19.5015635 },
    { lat: 49.187376, lng: 19.5013646 },
    { lat: 49.1873453, lng: 19.5013344 },
    { lat: 49.1872549, lng: 19.5013292 },
    { lat: 49.1871431, lng: 19.5010281 },
    { lat: 49.1870571, lng: 19.5008251 },
    { lat: 49.1870945, lng: 19.500655 },
    { lat: 49.1869915, lng: 19.5005453 },
    { lat: 49.1869934, lng: 19.5003661 },
    { lat: 49.1868357, lng: 19.5000713 },
    { lat: 49.1869066, lng: 19.499898 },
    { lat: 49.1867866, lng: 19.4996374 },
    { lat: 49.1867214, lng: 19.4997401 },
    { lat: 49.1866626, lng: 19.4997089 },
    { lat: 49.1866805, lng: 19.4995579 },
    { lat: 49.1866821, lng: 19.4994504 },
    { lat: 49.1866199, lng: 19.4991951 },
    { lat: 49.1864682, lng: 19.4991302 },
    { lat: 49.1862659, lng: 19.4985988 },
    { lat: 49.1862442, lng: 19.4982214 },
    { lat: 49.186067, lng: 19.4978477 },
    { lat: 49.1859605, lng: 19.4978572 },
    { lat: 49.185886, lng: 19.497711 },
    { lat: 49.1856769, lng: 19.4972732 },
    { lat: 49.1854689, lng: 19.4967939 },
    { lat: 49.1853416, lng: 19.4969437 },
    { lat: 49.1851873, lng: 19.4967706 },
    { lat: 49.1850966, lng: 19.4968321 },
    { lat: 49.1849174, lng: 19.4966625 },
    { lat: 49.1847704, lng: 19.4957852 },
    { lat: 49.1845585, lng: 19.4956345 },
    { lat: 49.1846588, lng: 19.4950701 },
    { lat: 49.1847352, lng: 19.4947684 },
    { lat: 49.1846754, lng: 19.4945461 },
    { lat: 49.1846439, lng: 19.4943726 },
    { lat: 49.1843709, lng: 19.4943415 },
    { lat: 49.1843367, lng: 19.4940627 },
    { lat: 49.1840903, lng: 19.4939017 },
    { lat: 49.1839276, lng: 19.4936949 },
    { lat: 49.1838186, lng: 19.4933298 },
    { lat: 49.1836081, lng: 19.4935334 },
    { lat: 49.1832336, lng: 19.493275 },
    { lat: 49.1828567, lng: 19.4930663 },
    { lat: 49.1827271, lng: 19.4930412 },
    { lat: 49.1823244, lng: 19.4930078 },
    { lat: 49.1822828, lng: 19.4930115 },
    { lat: 49.1822522, lng: 19.4930046 },
    { lat: 49.1817239, lng: 19.4936718 },
    { lat: 49.1811541, lng: 19.4940527 },
    { lat: 49.1807226, lng: 19.4942986 },
    { lat: 49.1806801, lng: 19.494256 },
    { lat: 49.180444, lng: 19.4943391 },
    { lat: 49.1767875, lng: 19.4956171 },
    { lat: 49.1754472, lng: 19.4967859 },
    { lat: 49.1739225, lng: 19.5000374 },
    { lat: 49.1736119, lng: 19.5009074 },
    { lat: 49.1734973, lng: 19.5020461 },
    { lat: 49.1731197, lng: 19.5058167 },
    { lat: 49.1735534, lng: 19.509244 },
    { lat: 49.1742744, lng: 19.5107992 },
    { lat: 49.1747166, lng: 19.5121179 },
    { lat: 49.1749963, lng: 19.5134484 },
    { lat: 49.1757634, lng: 19.5140196 },
    { lat: 49.1761683, lng: 19.5142528 },
    { lat: 49.1785296, lng: 19.51637 },
    { lat: 49.1787499, lng: 19.5165566 },
    { lat: 49.1798342, lng: 19.5175022 },
    { lat: 49.1805613, lng: 19.5179816 },
    { lat: 49.181166, lng: 19.5186144 },
    { lat: 49.1822308, lng: 19.5191621 },
    { lat: 49.1832556, lng: 19.5199424 },
    { lat: 49.1840235, lng: 19.5208187 },
    { lat: 49.1840729, lng: 19.520914 },
    { lat: 49.1844554, lng: 19.5214217 },
    { lat: 49.1846668, lng: 19.5217296 },
    { lat: 49.1851195, lng: 19.522246 },
    { lat: 49.1854124, lng: 19.5227142 },
    { lat: 49.1856351, lng: 19.523598 },
    { lat: 49.185916, lng: 19.5244007 },
    { lat: 49.1869899, lng: 19.5261348 },
    { lat: 49.1871327, lng: 19.5266219 },
    { lat: 49.1873925, lng: 19.5279012 },
    { lat: 49.1874853, lng: 19.528207 },
    { lat: 49.1879212, lng: 19.5299644 },
    { lat: 49.1878934, lng: 19.5301205 },
    { lat: 49.1883942, lng: 19.5313225 },
    { lat: 49.1889517, lng: 19.5324555 },
    { lat: 49.1894323, lng: 19.5333164 },
    { lat: 49.1897549, lng: 19.5343371 },
    { lat: 49.1902124, lng: 19.5346367 },
    { lat: 49.190803, lng: 19.5353869 },
    { lat: 49.1922208, lng: 19.5353195 },
    { lat: 49.1928871, lng: 19.535752 },
    { lat: 49.1932145, lng: 19.5361616 },
    { lat: 49.1935198, lng: 19.5368787 },
    { lat: 49.19368, lng: 19.5370325 },
    { lat: 49.1936956, lng: 19.5370791 },
    { lat: 49.1944902, lng: 19.5377323 },
  ],
  VyšnáBoca: [
    { lat: 48.906102, lng: 19.777009 },
    { lat: 48.906281, lng: 19.777233 },
    { lat: 48.906434, lng: 19.777552 },
    { lat: 48.906588, lng: 19.777731 },
    { lat: 48.90706, lng: 19.778905 },
    { lat: 48.907266, lng: 19.779272 },
    { lat: 48.907841, lng: 19.780476 },
    { lat: 48.908019, lng: 19.781237 },
    { lat: 48.908253, lng: 19.781809 },
    { lat: 48.908241, lng: 19.781997 },
    { lat: 48.908324, lng: 19.782532 },
    { lat: 48.908441, lng: 19.782995 },
    { lat: 48.908587, lng: 19.783351 },
    { lat: 48.908889, lng: 19.783737 },
    { lat: 48.909031, lng: 19.783995 },
    { lat: 48.909128, lng: 19.784247 },
    { lat: 48.909182, lng: 19.784536 },
    { lat: 48.909314, lng: 19.785002 },
    { lat: 48.909376, lng: 19.78532 },
    { lat: 48.90957, lng: 19.785738 },
    { lat: 48.909724, lng: 19.78599 },
    { lat: 48.910204, lng: 19.786448 },
    { lat: 48.910597, lng: 19.786801 },
    { lat: 48.910592, lng: 19.787689 },
    { lat: 48.910671, lng: 19.788383 },
    { lat: 48.910776, lng: 19.78871 },
    { lat: 48.910875, lng: 19.789291 },
    { lat: 48.911338, lng: 19.790358 },
    { lat: 48.911445, lng: 19.790812 },
    { lat: 48.911586, lng: 19.791032 },
    { lat: 48.912032, lng: 19.792095 },
    { lat: 48.912307, lng: 19.79286 },
    { lat: 48.912447, lng: 19.793719 },
    { lat: 48.912524, lng: 19.794189 },
    { lat: 48.912532, lng: 19.794581 },
    { lat: 48.912526, lng: 19.794871 },
    { lat: 48.912652, lng: 19.79532 },
    { lat: 48.912848, lng: 19.795694 },
    { lat: 48.9123, lng: 19.796761 },
    { lat: 48.912366, lng: 19.797526 },
    { lat: 48.912395, lng: 19.797858 },
    { lat: 48.9126575, lng: 19.7980975 },
    { lat: 48.912918, lng: 19.7982352 },
    { lat: 48.9136504, lng: 19.7983895 },
    { lat: 48.913634, lng: 19.796793 },
    { lat: 48.9137228, lng: 19.7964525 },
    { lat: 48.9138521, lng: 19.7962644 },
    { lat: 48.91402, lng: 19.7959031 },
    { lat: 48.9139723, lng: 19.7957105 },
    { lat: 48.9139093, lng: 19.7957386 },
    { lat: 48.9137443, lng: 19.7954138 },
    { lat: 48.9137133, lng: 19.7950408 },
    { lat: 48.9138803, lng: 19.7948364 },
    { lat: 48.913981, lng: 19.7945628 },
    { lat: 48.9140215, lng: 19.7940179 },
    { lat: 48.9141778, lng: 19.7936208 },
    { lat: 48.9143651, lng: 19.793211 },
    { lat: 48.9146787, lng: 19.7928852 },
    { lat: 48.9147839, lng: 19.7928414 },
    { lat: 48.9149357, lng: 19.7926768 },
    { lat: 48.9149938, lng: 19.7927229 },
    { lat: 48.9152348, lng: 19.7925907 },
    { lat: 48.9155603, lng: 19.7924052 },
    { lat: 48.9160236, lng: 19.7924416 },
    { lat: 48.9160152, lng: 19.7923228 },
    { lat: 48.9160827, lng: 19.7921388 },
    { lat: 48.9162653, lng: 19.7920102 },
    { lat: 48.9160499, lng: 19.7916922 },
    { lat: 48.9158331, lng: 19.7916042 },
    { lat: 48.9157348, lng: 19.7916506 },
    { lat: 48.9155032, lng: 19.7912963 },
    { lat: 48.9151848, lng: 19.7906852 },
    { lat: 48.9152642, lng: 19.7904895 },
    { lat: 48.9156328, lng: 19.7897703 },
    { lat: 48.9158499, lng: 19.789557 },
    { lat: 48.9159158, lng: 19.7890744 },
    { lat: 48.9162497, lng: 19.7886204 },
    { lat: 48.9164102, lng: 19.7886037 },
    { lat: 48.916471, lng: 19.7883171 },
    { lat: 48.9166473, lng: 19.7878381 },
    { lat: 48.916593, lng: 19.787422 },
    { lat: 48.9165637, lng: 19.7871323 },
    { lat: 48.9165011, lng: 19.7870673 },
    { lat: 48.9165265, lng: 19.7863039 },
    { lat: 48.9167901, lng: 19.7862263 },
    { lat: 48.9170134, lng: 19.7862319 },
    { lat: 48.9170831, lng: 19.786 },
    { lat: 48.917694, lng: 19.7859495 },
    { lat: 48.919014, lng: 19.7865388 },
    { lat: 48.9191613, lng: 19.7867938 },
    { lat: 48.9205902, lng: 19.787346 },
    { lat: 48.9207857, lng: 19.787205 },
    { lat: 48.9212985, lng: 19.7877545 },
    { lat: 48.9216463, lng: 19.7875889 },
    { lat: 48.9222082, lng: 19.7870691 },
    { lat: 48.9223758, lng: 19.7866704 },
    { lat: 48.9224503, lng: 19.7864957 },
    { lat: 48.9228956, lng: 19.7854377 },
    { lat: 48.9231165, lng: 19.7849219 },
    { lat: 48.9231788, lng: 19.7849318 },
    { lat: 48.923392, lng: 19.7846489 },
    { lat: 48.9234771, lng: 19.7847708 },
    { lat: 48.9235971, lng: 19.7844965 },
    { lat: 48.9246707, lng: 19.784576 },
    { lat: 48.9247217, lng: 19.7841163 },
    { lat: 48.9249684, lng: 19.7841439 },
    { lat: 48.9249246, lng: 19.7837549 },
    { lat: 48.9252621, lng: 19.7836931 },
    { lat: 48.9255372, lng: 19.7831805 },
    { lat: 48.9257096, lng: 19.782302 },
    { lat: 48.9255062, lng: 19.7820537 },
    { lat: 48.9253752, lng: 19.781528 },
    { lat: 48.925104, lng: 19.7808354 },
    { lat: 48.9248544, lng: 19.7804066 },
    { lat: 48.9248688, lng: 19.7801457 },
    { lat: 48.9250616, lng: 19.7799645 },
    { lat: 48.9247302, lng: 19.7791859 },
    { lat: 48.9247049, lng: 19.7785006 },
    { lat: 48.9245847, lng: 19.7785112 },
    { lat: 48.9246485, lng: 19.7779331 },
    { lat: 48.9247323, lng: 19.7775256 },
    { lat: 48.9250099, lng: 19.7769937 },
    { lat: 48.9250822, lng: 19.7767689 },
    { lat: 48.9251586, lng: 19.7768645 },
    { lat: 48.9254897, lng: 19.7767084 },
    { lat: 48.9256793, lng: 19.7765317 },
    { lat: 48.9259808, lng: 19.7761851 },
    { lat: 48.9262577, lng: 19.7764128 },
    { lat: 48.9264802, lng: 19.7760677 },
    { lat: 48.9265654, lng: 19.7759814 },
    { lat: 48.9265872, lng: 19.7759602 },
    { lat: 48.9268375, lng: 19.7757076 },
    { lat: 48.9269635, lng: 19.7753933 },
    { lat: 48.9271416, lng: 19.7750598 },
    { lat: 48.9269463, lng: 19.7740669 },
    { lat: 48.9269829, lng: 19.7734595 },
    { lat: 48.9271472, lng: 19.7731715 },
    { lat: 48.9273023, lng: 19.7729825 },
    { lat: 48.927466, lng: 19.7725996 },
    { lat: 48.9274568, lng: 19.7725188 },
    { lat: 48.9277577, lng: 19.7721608 },
    { lat: 48.9279367, lng: 19.7718261 },
    { lat: 48.9281287, lng: 19.7715775 },
    { lat: 48.9288925, lng: 19.771212 },
    { lat: 48.9291819, lng: 19.7711571 },
    { lat: 48.9299732, lng: 19.7706807 },
    { lat: 48.9308816, lng: 19.7705335 },
    { lat: 48.9315178, lng: 19.7701113 },
    { lat: 48.9316167, lng: 19.7688122 },
    { lat: 48.9317288, lng: 19.7672531 },
    { lat: 48.9318996, lng: 19.7648181 },
    { lat: 48.9319545, lng: 19.764063 },
    { lat: 48.931953, lng: 19.7636654 },
    { lat: 48.9319027, lng: 19.7584424 },
    { lat: 48.9318916, lng: 19.7573345 },
    { lat: 48.9320072, lng: 19.7574297 },
    { lat: 48.9321593, lng: 19.7576107 },
    { lat: 48.9323085, lng: 19.7577225 },
    { lat: 48.9325079, lng: 19.7578017 },
    { lat: 48.932626, lng: 19.7579492 },
    { lat: 48.9327513, lng: 19.7580613 },
    { lat: 48.9329128, lng: 19.7582518 },
    { lat: 48.9330753, lng: 19.7584089 },
    { lat: 48.9331568, lng: 19.7585985 },
    { lat: 48.9332361, lng: 19.7588692 },
    { lat: 48.9333618, lng: 19.7590594 },
    { lat: 48.9333793, lng: 19.7592819 },
    { lat: 48.9334354, lng: 19.7595615 },
    { lat: 48.9334845, lng: 19.7598106 },
    { lat: 48.9335372, lng: 19.7599293 },
    { lat: 48.9338864, lng: 19.7602933 },
    { lat: 48.9339889, lng: 19.7603697 },
    { lat: 48.9340717, lng: 19.7602754 },
    { lat: 48.9341408, lng: 19.7601552 },
    { lat: 48.9342586, lng: 19.7600161 },
    { lat: 48.9343391, lng: 19.7598489 },
    { lat: 48.9344917, lng: 19.759601 },
    { lat: 48.9346005, lng: 19.7593363 },
    { lat: 48.9345981, lng: 19.7590499 },
    { lat: 48.9346584, lng: 19.7588038 },
    { lat: 48.9348133, lng: 19.7585065 },
    { lat: 48.9347276, lng: 19.7584044 },
    { lat: 48.93477, lng: 19.7574886 },
    { lat: 48.9354616, lng: 19.757329 },
    { lat: 48.9357629, lng: 19.7573427 },
    { lat: 48.9361108, lng: 19.7575198 },
    { lat: 48.9365539, lng: 19.757783 },
    { lat: 48.9370413, lng: 19.7579048 },
    { lat: 48.9371255, lng: 19.7579257 },
    { lat: 48.9376607, lng: 19.7580284 },
    { lat: 48.9382818, lng: 19.7581407 },
    { lat: 48.9384406, lng: 19.7581652 },
    { lat: 48.9387484, lng: 19.758117 },
    { lat: 48.938942, lng: 19.7580355 },
    { lat: 48.9389249, lng: 19.7579516 },
    { lat: 48.9389073, lng: 19.7578631 },
    { lat: 48.9388945, lng: 19.7578013 },
    { lat: 48.9388049, lng: 19.7573466 },
    { lat: 48.9387874, lng: 19.7572595 },
    { lat: 48.9387802, lng: 19.7572226 },
    { lat: 48.9389503, lng: 19.757195 },
    { lat: 48.9389471, lng: 19.757094 },
    { lat: 48.9389421, lng: 19.7569314 },
    { lat: 48.9389309, lng: 19.7565183 },
    { lat: 48.9389612, lng: 19.7565156 },
    { lat: 48.9390271, lng: 19.7566605 },
    { lat: 48.9390908, lng: 19.7568548 },
    { lat: 48.93922, lng: 19.7567916 },
    { lat: 48.9391866, lng: 19.7565194 },
    { lat: 48.9391717, lng: 19.7561521 },
    { lat: 48.9389438, lng: 19.7558906 },
    { lat: 48.9389635, lng: 19.7552274 },
    { lat: 48.9390342, lng: 19.7550956 },
    { lat: 48.9387941, lng: 19.755042 },
    { lat: 48.9386338, lng: 19.7550857 },
    { lat: 48.9384167, lng: 19.7548055 },
    { lat: 48.9384109, lng: 19.7546728 },
    { lat: 48.9382434, lng: 19.7543263 },
    { lat: 48.9382905, lng: 19.7540636 },
    { lat: 48.9379978, lng: 19.753811 },
    { lat: 48.9379186, lng: 19.7536191 },
    { lat: 48.9375112, lng: 19.7532971 },
    { lat: 48.9375851, lng: 19.7529256 },
    { lat: 48.9375765, lng: 19.752729 },
    { lat: 48.9374393, lng: 19.752707 },
    { lat: 48.9373298, lng: 19.752292 },
    { lat: 48.9372017, lng: 19.7516308 },
    { lat: 48.9371562, lng: 19.7514645 },
    { lat: 48.9370466, lng: 19.7512795 },
    { lat: 48.937059, lng: 19.7511493 },
    { lat: 48.9368955, lng: 19.7510439 },
    { lat: 48.9368594, lng: 19.7508877 },
    { lat: 48.9369157, lng: 19.7503245 },
    { lat: 48.9367317, lng: 19.7503377 },
    { lat: 48.9364373, lng: 19.7502767 },
    { lat: 48.936199, lng: 19.7501076 },
    { lat: 48.935785, lng: 19.7499702 },
    { lat: 48.9354929, lng: 19.7494998 },
    { lat: 48.9351993, lng: 19.7491997 },
    { lat: 48.9350163, lng: 19.7491393 },
    { lat: 48.9348347, lng: 19.7494178 },
    { lat: 48.9345405, lng: 19.7485922 },
    { lat: 48.9345307, lng: 19.7485473 },
    { lat: 48.9347476, lng: 19.7484326 },
    { lat: 48.9349303, lng: 19.7482536 },
    { lat: 48.9348787, lng: 19.7479005 },
    { lat: 48.9346284, lng: 19.7477903 },
    { lat: 48.9345835, lng: 19.7474136 },
    { lat: 48.9345498, lng: 19.7474134 },
    { lat: 48.9344733, lng: 19.7476995 },
    { lat: 48.9344087, lng: 19.7477935 },
    { lat: 48.934519, lng: 19.7463505 },
    { lat: 48.9345093, lng: 19.7460537 },
    { lat: 48.9345692, lng: 19.7454919 },
    { lat: 48.9345617, lng: 19.7441363 },
    { lat: 48.9346105, lng: 19.7435527 },
    { lat: 48.9353835, lng: 19.7414493 },
    { lat: 48.9369132, lng: 19.7372066 },
    { lat: 48.9371475, lng: 19.7335392 },
    { lat: 48.9373514, lng: 19.730436 },
    { lat: 48.9376013, lng: 19.7266379 },
    { lat: 48.9379746, lng: 19.7267251 },
    { lat: 48.9386759, lng: 19.722206 },
    { lat: 48.9383828, lng: 19.7221289 },
    { lat: 48.940133, lng: 19.713418 },
    { lat: 48.9389905, lng: 19.7118325 },
    { lat: 48.9374807, lng: 19.7097867 },
    { lat: 48.9369201, lng: 19.7072219 },
    { lat: 48.9367582, lng: 19.7066152 },
    { lat: 48.936716, lng: 19.7054821 },
    { lat: 48.9366001, lng: 19.7026826 },
    { lat: 48.9363272, lng: 19.7007387 },
    { lat: 48.9354459, lng: 19.699533 },
    { lat: 48.9345234, lng: 19.6981105 },
    { lat: 48.9333979, lng: 19.6963811 },
    { lat: 48.9331227, lng: 19.6959459 },
    { lat: 48.9325495, lng: 19.6950823 },
    { lat: 48.9316031, lng: 19.69414 },
    { lat: 48.9313264, lng: 19.6938741 },
    { lat: 48.9303251, lng: 19.6928855 },
    { lat: 48.9292032, lng: 19.6911123 },
    { lat: 48.9264971, lng: 19.690253 },
    { lat: 48.9260156, lng: 19.6902398 },
    { lat: 48.923698, lng: 19.690142 },
    { lat: 48.923617, lng: 19.693233 },
    { lat: 48.9235448, lng: 19.6938623 },
    { lat: 48.9234678, lng: 19.6945337 },
    { lat: 48.923281, lng: 19.696162 },
    { lat: 48.923191, lng: 19.696479 },
    { lat: 48.922208, lng: 19.698495 },
    { lat: 48.922005, lng: 19.698911 },
    { lat: 48.921332, lng: 19.702154 },
    { lat: 48.921096, lng: 19.70261 },
    { lat: 48.919032, lng: 19.704388 },
    { lat: 48.91816, lng: 19.703952 },
    { lat: 48.918252, lng: 19.705707 },
    { lat: 48.918232, lng: 19.706318 },
    { lat: 48.918285, lng: 19.706689 },
    { lat: 48.9180882, lng: 19.7070959 },
    { lat: 48.918079, lng: 19.707115 },
    { lat: 48.917822, lng: 19.707446 },
    { lat: 48.917429, lng: 19.708342 },
    { lat: 48.916751, lng: 19.70888 },
    { lat: 48.916476, lng: 19.709186 },
    { lat: 48.916148, lng: 19.709524 },
    { lat: 48.915843, lng: 19.709738 },
    { lat: 48.915225, lng: 19.7104 },
    { lat: 48.914403, lng: 19.711266 },
    { lat: 48.914157, lng: 19.711496 },
    { lat: 48.91398, lng: 19.71163 },
    { lat: 48.9136499, lng: 19.7117296 },
    { lat: 48.913244, lng: 19.711852 },
    { lat: 48.912471, lng: 19.712224 },
    { lat: 48.91223, lng: 19.712577 },
    { lat: 48.911988, lng: 19.712944 },
    { lat: 48.911533, lng: 19.715198 },
    { lat: 48.911294, lng: 19.716584 },
    { lat: 48.911245, lng: 19.717081 },
    { lat: 48.911277, lng: 19.718329 },
    { lat: 48.911072, lng: 19.719127 },
    { lat: 48.908444, lng: 19.721982 },
    { lat: 48.908217, lng: 19.724034 },
    { lat: 48.908108, lng: 19.724936 },
    { lat: 48.907637, lng: 19.727776 },
    { lat: 48.907599, lng: 19.730571 },
    { lat: 48.907858, lng: 19.731924 },
    { lat: 48.907662, lng: 19.733419 },
    { lat: 48.90751, lng: 19.733689 },
    { lat: 48.907033, lng: 19.734238 },
    { lat: 48.907031, lng: 19.734241 },
    { lat: 48.907012, lng: 19.734267 },
    { lat: 48.906786, lng: 19.734574 },
    { lat: 48.906498, lng: 19.735126 },
    { lat: 48.90629, lng: 19.735521 },
    { lat: 48.906247, lng: 19.73556 },
    { lat: 48.905825, lng: 19.735946 },
    { lat: 48.905796, lng: 19.73596 },
    { lat: 48.905687, lng: 19.736007 },
    { lat: 48.905585, lng: 19.736052 },
    { lat: 48.905496, lng: 19.736075 },
    { lat: 48.905476, lng: 19.736084 },
    { lat: 48.905426, lng: 19.736121 },
    { lat: 48.905264, lng: 19.736052 },
    { lat: 48.904723, lng: 19.735871 },
    { lat: 48.904464, lng: 19.736134 },
    { lat: 48.904181, lng: 19.736445 },
    { lat: 48.903516, lng: 19.736642 },
    { lat: 48.903298, lng: 19.736606 },
    { lat: 48.903056, lng: 19.736665 },
    { lat: 48.903005, lng: 19.736555 },
    { lat: 48.902835, lng: 19.736491 },
    { lat: 48.902609, lng: 19.736446 },
    { lat: 48.902469, lng: 19.73648 },
    { lat: 48.901321, lng: 19.736133 },
    { lat: 48.900028, lng: 19.737315 },
    { lat: 48.898121, lng: 19.740607 },
    { lat: 48.897873, lng: 19.741581 },
    { lat: 48.896834, lng: 19.744618 },
    { lat: 48.897412, lng: 19.746719 },
    { lat: 48.897767, lng: 19.748328 },
    { lat: 48.898226, lng: 19.749862 },
    { lat: 48.898264, lng: 19.750118 },
    { lat: 48.8983, lng: 19.750267 },
    { lat: 48.898313, lng: 19.750438 },
    { lat: 48.898847, lng: 19.751029 },
    { lat: 48.898969, lng: 19.751449 },
    { lat: 48.899025, lng: 19.751585 },
    { lat: 48.899158, lng: 19.751779 },
    { lat: 48.899203, lng: 19.751877 },
    { lat: 48.899256, lng: 19.751973 },
    { lat: 48.899443, lng: 19.752396 },
    { lat: 48.899549, lng: 19.752674 },
    { lat: 48.899777, lng: 19.752971 },
    { lat: 48.899965, lng: 19.75319 },
    { lat: 48.900011, lng: 19.753418 },
    { lat: 48.900016, lng: 19.753739 },
    { lat: 48.900073, lng: 19.75432 },
    { lat: 48.900121, lng: 19.754773 },
    { lat: 48.900254, lng: 19.755558 },
    { lat: 48.900394, lng: 19.755877 },
    { lat: 48.900695, lng: 19.756488 },
    { lat: 48.900779, lng: 19.756707 },
    { lat: 48.900968, lng: 19.756905 },
    { lat: 48.90149, lng: 19.757486 },
    { lat: 48.901948, lng: 19.758288 },
    { lat: 48.902169, lng: 19.75848 },
    { lat: 48.902825, lng: 19.759056 },
    { lat: 48.903086, lng: 19.75937 },
    { lat: 48.904175, lng: 19.760375 },
    { lat: 48.904621, lng: 19.760771 },
    { lat: 48.904859, lng: 19.761125 },
    { lat: 48.905268, lng: 19.761417 },
    { lat: 48.904258, lng: 19.762774 },
    { lat: 48.904176, lng: 19.763046 },
    { lat: 48.90411, lng: 19.763351 },
    { lat: 48.90404, lng: 19.763763 },
    { lat: 48.903942, lng: 19.764128 },
    { lat: 48.904048, lng: 19.765277 },
    { lat: 48.904117, lng: 19.765888 },
    { lat: 48.904066, lng: 19.766237 },
    { lat: 48.904212, lng: 19.767011 },
    { lat: 48.904268, lng: 19.767348 },
    { lat: 48.904295, lng: 19.767558 },
    { lat: 48.904495, lng: 19.768289 },
    { lat: 48.904762, lng: 19.769162 },
    { lat: 48.904942, lng: 19.769562 },
    { lat: 48.905178, lng: 19.771244 },
    { lat: 48.905218, lng: 19.771708 },
    { lat: 48.905208, lng: 19.772716 },
    { lat: 48.905292, lng: 19.773424 },
    { lat: 48.905336, lng: 19.773733 },
    { lat: 48.905424, lng: 19.774273 },
    { lat: 48.905542, lng: 19.774734 },
    { lat: 48.905659, lng: 19.775541 },
    { lat: 48.905849, lng: 19.776169 },
    { lat: 48.906102, lng: 19.777009 },
  ],
  Východná: [
    { lat: 48.9803733, lng: 19.8243474 },
    { lat: 48.9797114, lng: 19.8253016 },
    { lat: 48.9781791, lng: 19.8262591 },
    { lat: 48.9774864, lng: 19.8265245 },
    { lat: 48.9773049, lng: 19.8265199 },
    { lat: 48.9769667, lng: 19.826565 },
    { lat: 48.9766403, lng: 19.8266272 },
    { lat: 48.9762341, lng: 19.8265844 },
    { lat: 48.9756602, lng: 19.8269674 },
    { lat: 48.9749715, lng: 19.8272729 },
    { lat: 48.9745138, lng: 19.8273941 },
    { lat: 48.9744785, lng: 19.827621 },
    { lat: 48.9744245, lng: 19.8278584 },
    { lat: 48.9744453, lng: 19.8281321 },
    { lat: 48.9744154, lng: 19.8285338 },
    { lat: 48.9744562, lng: 19.8290371 },
    { lat: 48.9744902, lng: 19.8293274 },
    { lat: 48.9746059, lng: 19.829662 },
    { lat: 48.9746394, lng: 19.8298114 },
    { lat: 48.974696, lng: 19.8302634 },
    { lat: 48.9746425, lng: 19.8314778 },
    { lat: 48.9747743, lng: 19.83339 },
    { lat: 48.9749467, lng: 19.8349116 },
    { lat: 48.9750714, lng: 19.8360048 },
    { lat: 48.9749144, lng: 19.8364657 },
    { lat: 48.9745316, lng: 19.8368892 },
    { lat: 48.9743356, lng: 19.8373321 },
    { lat: 48.9741845, lng: 19.837701 },
    { lat: 48.9739669, lng: 19.8381411 },
    { lat: 48.9737179, lng: 19.8391088 },
    { lat: 48.9735616, lng: 19.8398221 },
    { lat: 48.9724756, lng: 19.8400596 },
    { lat: 48.9719764, lng: 19.8403694 },
    { lat: 48.9717722, lng: 19.8405711 },
    { lat: 48.9713502, lng: 19.8408417 },
    { lat: 48.9707978, lng: 19.8412002 },
    { lat: 48.9703346, lng: 19.8416154 },
    { lat: 48.9699081, lng: 19.841809 },
    { lat: 48.9694315, lng: 19.8424367 },
    { lat: 48.9688325, lng: 19.8427567 },
    { lat: 48.9683761, lng: 19.8430949 },
    { lat: 48.9682009, lng: 19.8431606 },
    { lat: 48.9680905, lng: 19.8432655 },
    { lat: 48.9675896, lng: 19.8433549 },
    { lat: 48.9674833, lng: 19.8434454 },
    { lat: 48.9677335, lng: 19.8447368 },
    { lat: 48.9677366, lng: 19.8448376 },
    { lat: 48.9678115, lng: 19.8449768 },
    { lat: 48.9679334, lng: 19.8452978 },
    { lat: 48.9679389, lng: 19.8455576 },
    { lat: 48.9678573, lng: 19.8460206 },
    { lat: 48.9676435, lng: 19.8464183 },
    { lat: 48.9675323, lng: 19.8465555 },
    { lat: 48.9674888, lng: 19.8466713 },
    { lat: 48.9674324, lng: 19.8467758 },
    { lat: 48.967403, lng: 19.8470638 },
    { lat: 48.9674077, lng: 19.8472497 },
    { lat: 48.9673926, lng: 19.8475022 },
    { lat: 48.967327, lng: 19.8479942 },
    { lat: 48.9673263, lng: 19.8487421 },
    { lat: 48.9673095, lng: 19.8489291 },
    { lat: 48.9671877, lng: 19.8494231 },
    { lat: 48.9671747, lng: 19.8496151 },
    { lat: 48.9671306, lng: 19.8497421 },
    { lat: 48.9670885, lng: 19.8502084 },
    { lat: 48.967085, lng: 19.8507551 },
    { lat: 48.9670749, lng: 19.851285 },
    { lat: 48.967097, lng: 19.8516678 },
    { lat: 48.9671332, lng: 19.8519055 },
    { lat: 48.9671255, lng: 19.8521727 },
    { lat: 48.9671391, lng: 19.8524395 },
    { lat: 48.967155, lng: 19.8525473 },
    { lat: 48.9671536, lng: 19.852674 },
    { lat: 48.9671856, lng: 19.8528142 },
    { lat: 48.9672097, lng: 19.8530091 },
    { lat: 48.9671683, lng: 19.853277 },
    { lat: 48.9670939, lng: 19.8534837 },
    { lat: 48.9670289, lng: 19.8537296 },
    { lat: 48.967044, lng: 19.8538185 },
    { lat: 48.96701, lng: 19.8539441 },
    { lat: 48.9669541, lng: 19.8540884 },
    { lat: 48.9668493, lng: 19.8544315 },
    { lat: 48.9666806, lng: 19.8546959 },
    { lat: 48.9665772, lng: 19.8548348 },
    { lat: 48.9664728, lng: 19.8549484 },
    { lat: 48.9661199, lng: 19.8550198 },
    { lat: 48.9658692, lng: 19.855401 },
    { lat: 48.9657633, lng: 19.8554516 },
    { lat: 48.9654774, lng: 19.8557218 },
    { lat: 48.9653261, lng: 19.8558214 },
    { lat: 48.9647429, lng: 19.8561429 },
    { lat: 48.9645778, lng: 19.8562069 },
    { lat: 48.9642583, lng: 19.8564005 },
    { lat: 48.963956, lng: 19.8567849 },
    { lat: 48.9624044, lng: 19.8562495 },
    { lat: 48.9610429, lng: 19.8573355 },
    { lat: 48.9607267, lng: 19.8577901 },
    { lat: 48.9605461, lng: 19.8578133 },
    { lat: 48.9603887, lng: 19.8577923 },
    { lat: 48.9602207, lng: 19.857816 },
    { lat: 48.9600359, lng: 19.8583892 },
    { lat: 48.9596131, lng: 19.8594092 },
    { lat: 48.9591503, lng: 19.860017 },
    { lat: 48.9584976, lng: 19.8605568 },
    { lat: 48.9582334, lng: 19.8609639 },
    { lat: 48.9580084, lng: 19.8616306 },
    { lat: 48.9579579, lng: 19.8621102 },
    { lat: 48.957886, lng: 19.8626424 },
    { lat: 48.9575568, lng: 19.863185 },
    { lat: 48.9574903, lng: 19.8633192 },
    { lat: 48.956942, lng: 19.8638794 },
    { lat: 48.9566498, lng: 19.864448 },
    { lat: 48.9561841, lng: 19.864751 },
    { lat: 48.9560266, lng: 19.8649443 },
    { lat: 48.9558327, lng: 19.8649358 },
    { lat: 48.9552664, lng: 19.8649974 },
    { lat: 48.954162, lng: 19.8648285 },
    { lat: 48.9538228, lng: 19.8648286 },
    { lat: 48.9534677, lng: 19.8648489 },
    { lat: 48.9529426, lng: 19.864791 },
    { lat: 48.9524672, lng: 19.8646845 },
    { lat: 48.951946, lng: 19.8647467 },
    { lat: 48.9516823, lng: 19.8647451 },
    { lat: 48.9514043, lng: 19.8646979 },
    { lat: 48.9510083, lng: 19.8645782 },
    { lat: 48.9506011, lng: 19.8645632 },
    { lat: 48.9501109, lng: 19.8645868 },
    { lat: 48.9489693, lng: 19.8648126 },
    { lat: 48.9466377, lng: 19.8702476 },
    { lat: 48.9457501, lng: 19.8723304 },
    { lat: 48.9452717, lng: 19.8734468 },
    { lat: 48.9443393, lng: 19.8756139 },
    { lat: 48.9433374, lng: 19.8779801 },
    { lat: 48.9423163, lng: 19.8791325 },
    { lat: 48.9404697, lng: 19.8812351 },
    { lat: 48.940169, lng: 19.8815789 },
    { lat: 48.9397115, lng: 19.8818162 },
    { lat: 48.9395504, lng: 19.8818145 },
    { lat: 48.9385004, lng: 19.8814153 },
    { lat: 48.9367288, lng: 19.8807391 },
    { lat: 48.9335281, lng: 19.8795424 },
    { lat: 48.9319324, lng: 19.878945 },
    { lat: 48.9316756, lng: 19.878708 },
    { lat: 48.9311984, lng: 19.87856 },
    { lat: 48.9307484, lng: 19.8784416 },
    { lat: 48.9301691, lng: 19.878275 },
    { lat: 48.9293701, lng: 19.8780329 },
    { lat: 48.9284777, lng: 19.8777642 },
    { lat: 48.9279331, lng: 19.8775651 },
    { lat: 48.9273037, lng: 19.877387 },
    { lat: 48.9265454, lng: 19.877147 },
    { lat: 48.9253717, lng: 19.8767795 },
    { lat: 48.924933, lng: 19.8765286 },
    { lat: 48.9244865, lng: 19.876283 },
    { lat: 48.9240501, lng: 19.8760358 },
    { lat: 48.9230258, lng: 19.8767644 },
    { lat: 48.9224148, lng: 19.8770706 },
    { lat: 48.9211445, lng: 19.8777152 },
    { lat: 48.920582, lng: 19.8778665 },
    { lat: 48.920048, lng: 19.8778874 },
    { lat: 48.9196986, lng: 19.8779071 },
    { lat: 48.918434, lng: 19.8779726 },
    { lat: 48.9179298, lng: 19.8783266 },
    { lat: 48.9171488, lng: 19.8788719 },
    { lat: 48.9166672, lng: 19.8792028 },
    { lat: 48.9164147, lng: 19.879358 },
    { lat: 48.9158902, lng: 19.8797136 },
    { lat: 48.9158604, lng: 19.8797234 },
    { lat: 48.9145648, lng: 19.880039 },
    { lat: 48.9140998, lng: 19.880151 },
    { lat: 48.9133642, lng: 19.8803314 },
    { lat: 48.9127608, lng: 19.8804752 },
    { lat: 48.911968, lng: 19.8806665 },
    { lat: 48.9114356, lng: 19.8808062 },
    { lat: 48.9101405, lng: 19.8813198 },
    { lat: 48.909632, lng: 19.881341 },
    { lat: 48.908217, lng: 19.889915 },
    { lat: 48.909879, lng: 19.898707 },
    { lat: 48.909116, lng: 19.900892 },
    { lat: 48.909188, lng: 19.903183 },
    { lat: 48.909228, lng: 19.905379 },
    { lat: 48.908813, lng: 19.906853 },
    { lat: 48.907926, lng: 19.908969 },
    { lat: 48.907699, lng: 19.912869 },
    { lat: 48.908136, lng: 19.915632 },
    { lat: 48.908368, lng: 19.916952 },
    { lat: 48.908382, lng: 19.918471 },
    { lat: 48.908378, lng: 19.91919 },
    { lat: 48.909081, lng: 19.922531 },
    { lat: 48.90841, lng: 19.922701 },
    { lat: 48.907223, lng: 19.923868 },
    { lat: 48.906329, lng: 19.924708 },
    { lat: 48.906112, lng: 19.925835 },
    { lat: 48.90522, lng: 19.928436 },
    { lat: 48.905617, lng: 19.929444 },
    { lat: 48.906137, lng: 19.931221 },
    { lat: 48.906739, lng: 19.933277 },
    { lat: 48.907761, lng: 19.934914 },
    { lat: 48.907383, lng: 19.935524 },
    { lat: 48.907214, lng: 19.936386 },
    { lat: 48.906887, lng: 19.938984 },
    { lat: 48.906983, lng: 19.939841 },
    { lat: 48.907194, lng: 19.94096 },
    { lat: 48.907322, lng: 19.941855 },
    { lat: 48.907503, lng: 19.942805 },
    { lat: 48.907443, lng: 19.945871 },
    { lat: 48.907397, lng: 19.946672 },
    { lat: 48.907364, lng: 19.947736 },
    { lat: 48.907343, lng: 19.948297 },
    { lat: 48.907292, lng: 19.950035 },
    { lat: 48.907276, lng: 19.950317 },
    { lat: 48.907227, lng: 19.950568 },
    { lat: 48.907134, lng: 19.950791 },
    { lat: 48.907085, lng: 19.950998 },
    { lat: 48.907096, lng: 19.951284 },
    { lat: 48.906954, lng: 19.951857 },
    { lat: 48.906941, lng: 19.952111 },
    { lat: 48.907012, lng: 19.952364 },
    { lat: 48.907031, lng: 19.954277 },
    { lat: 48.907093, lng: 19.954468 },
    { lat: 48.907394, lng: 19.955496 },
    { lat: 48.907418, lng: 19.955576 },
    { lat: 48.907541, lng: 19.95592 },
    { lat: 48.907614, lng: 19.956345 },
    { lat: 48.908178, lng: 19.960148 },
    { lat: 48.908437, lng: 19.961218 },
    { lat: 48.908655, lng: 19.96168 },
    { lat: 48.909697, lng: 19.963844 },
    { lat: 48.910308, lng: 19.965373 },
    { lat: 48.910454, lng: 19.965954 },
    { lat: 48.911128, lng: 19.968653 },
    { lat: 48.911599, lng: 19.969924 },
    { lat: 48.913738, lng: 19.972194 },
    { lat: 48.91379, lng: 19.972194 },
    { lat: 48.914526, lng: 19.972676 },
    { lat: 48.9147291, lng: 19.9730372 },
    { lat: 48.915728, lng: 19.974981 },
    { lat: 48.915911, lng: 19.976884 },
    { lat: 48.914277, lng: 19.980674 },
    { lat: 48.913819, lng: 19.981923 },
    { lat: 48.912986, lng: 19.983683 },
    { lat: 48.910542, lng: 19.988105 },
    { lat: 48.910364, lng: 19.988339 },
    { lat: 48.9103, lng: 19.988416 },
    { lat: 48.9083063, lng: 19.9909129 },
    { lat: 48.90824, lng: 19.990996 },
    { lat: 48.907501, lng: 19.992261 },
    { lat: 48.907531, lng: 19.992348 },
    { lat: 48.908163, lng: 19.994524 },
    { lat: 48.90994, lng: 20.000654 },
    { lat: 48.90983, lng: 20.000781 },
    { lat: 48.909745, lng: 20.000893 },
    { lat: 48.909594, lng: 20.001162 },
    { lat: 48.909547, lng: 20.00123 },
    { lat: 48.909435, lng: 20.001422 },
    { lat: 48.909341, lng: 20.0016 },
    { lat: 48.909253, lng: 20.001896 },
    { lat: 48.90934, lng: 20.002138 },
    { lat: 48.909394, lng: 20.003559 },
    { lat: 48.909529, lng: 20.006114 },
    { lat: 48.909623, lng: 20.008318 },
    { lat: 48.90981, lng: 20.015286 },
    { lat: 48.91088, lng: 20.022518 },
    { lat: 48.911373, lng: 20.026586 },
    { lat: 48.913004, lng: 20.029626 },
    { lat: 48.913443, lng: 20.030481 },
    { lat: 48.913522, lng: 20.030637 },
    { lat: 48.914133, lng: 20.031912 },
    { lat: 48.914665, lng: 20.033049 },
    { lat: 48.914695, lng: 20.033111 },
    { lat: 48.914737, lng: 20.0332 },
    { lat: 48.914772, lng: 20.03326 },
    { lat: 48.914385, lng: 20.034034 },
    { lat: 48.914379, lng: 20.034073 },
    { lat: 48.914392, lng: 20.034371 },
    { lat: 48.91432, lng: 20.034711 },
    { lat: 48.914298, lng: 20.035042 },
    { lat: 48.914486, lng: 20.035535 },
    { lat: 48.914503, lng: 20.03558 },
    { lat: 48.914535, lng: 20.035632 },
    { lat: 48.914648, lng: 20.035822 },
    { lat: 48.914715, lng: 20.035933 },
    { lat: 48.914731, lng: 20.035961 },
    { lat: 48.914802, lng: 20.03608 },
    { lat: 48.91489, lng: 20.036197 },
    { lat: 48.915092, lng: 20.036465 },
    { lat: 48.915108, lng: 20.036486 },
    { lat: 48.915186, lng: 20.036601 },
    { lat: 48.915213, lng: 20.036641 },
    { lat: 48.915275, lng: 20.036785 },
    { lat: 48.915486, lng: 20.037424 },
    { lat: 48.91561, lng: 20.0378 },
    { lat: 48.916017, lng: 20.03869 },
    { lat: 48.916126, lng: 20.03894 },
    { lat: 48.916231, lng: 20.039177 },
    { lat: 48.916328, lng: 20.039396 },
    { lat: 48.916482, lng: 20.039671 },
    { lat: 48.916583, lng: 20.03982 },
    { lat: 48.917701, lng: 20.041364 },
    { lat: 48.917727, lng: 20.0414 },
    { lat: 48.917854, lng: 20.041697 },
    { lat: 48.918864, lng: 20.044194 },
    { lat: 48.918924, lng: 20.044342 },
    { lat: 48.919028, lng: 20.044599 },
    { lat: 48.91906, lng: 20.044676 },
    { lat: 48.919675, lng: 20.045469 },
    { lat: 48.920173, lng: 20.045696 },
    { lat: 48.921162, lng: 20.046617 },
    { lat: 48.921225, lng: 20.046676 },
    { lat: 48.921531, lng: 20.047146 },
    { lat: 48.921584, lng: 20.047227 },
    { lat: 48.922201, lng: 20.04786 },
    { lat: 48.922609, lng: 20.048292 },
    { lat: 48.923123, lng: 20.048836 },
    { lat: 48.923256, lng: 20.048977 },
    { lat: 48.923333, lng: 20.049059 },
    { lat: 48.923524, lng: 20.049496 },
    { lat: 48.923525, lng: 20.049498 },
    { lat: 48.923683, lng: 20.049672 },
    { lat: 48.92379, lng: 20.049791 },
    { lat: 48.92386, lng: 20.050103 },
    { lat: 48.923852, lng: 20.05035 },
    { lat: 48.923939, lng: 20.050608 },
    { lat: 48.924028, lng: 20.051323 },
    { lat: 48.92424, lng: 20.051612 },
    { lat: 48.924687, lng: 20.052221 },
    { lat: 48.924881, lng: 20.052657 },
    { lat: 48.92494, lng: 20.053033 },
    { lat: 48.92513, lng: 20.053566 },
    { lat: 48.925236, lng: 20.054042 },
    { lat: 48.925269, lng: 20.05447 },
    { lat: 48.925481, lng: 20.055155 },
    { lat: 48.925892, lng: 20.055584 },
    { lat: 48.925957, lng: 20.055652 },
    { lat: 48.926361, lng: 20.055779 },
    { lat: 48.926631, lng: 20.055721 },
    { lat: 48.926815, lng: 20.05558 },
    { lat: 48.927202, lng: 20.055396 },
    { lat: 48.927635, lng: 20.055426 },
    { lat: 48.927988, lng: 20.055373 },
    { lat: 48.92821, lng: 20.05559 },
    { lat: 48.928323, lng: 20.055712 },
    { lat: 48.92873, lng: 20.056063 },
    { lat: 48.928737, lng: 20.056069 },
    { lat: 48.928784, lng: 20.056083 },
    { lat: 48.929129, lng: 20.056496 },
    { lat: 48.929465, lng: 20.056908 },
    { lat: 48.929584, lng: 20.057056 },
    { lat: 48.929686, lng: 20.05718 },
    { lat: 48.930394, lng: 20.057947 },
    { lat: 48.930711, lng: 20.05857 },
    { lat: 48.931382, lng: 20.05925 },
    { lat: 48.931729, lng: 20.059597 },
    { lat: 48.93211, lng: 20.060159 },
    { lat: 48.932176, lng: 20.060254 },
    { lat: 48.932304, lng: 20.060438 },
    { lat: 48.932802, lng: 20.05984 },
    { lat: 48.933277, lng: 20.059379 },
    { lat: 48.933315, lng: 20.057501 },
    { lat: 48.933668, lng: 20.05609 },
    { lat: 48.933574, lng: 20.055321 },
    { lat: 48.933665, lng: 20.055135 },
    { lat: 48.933644, lng: 20.053901 },
    { lat: 48.93378, lng: 20.05366 },
    { lat: 48.933715, lng: 20.05262 },
    { lat: 48.934383, lng: 20.052322 },
    { lat: 48.934403, lng: 20.052209 },
    { lat: 48.934471, lng: 20.051711 },
    { lat: 48.934304, lng: 20.050657 },
    { lat: 48.934218, lng: 20.050121 },
    { lat: 48.934161, lng: 20.050125 },
    { lat: 48.933955, lng: 20.05014 },
    { lat: 48.933904, lng: 20.050018 },
    { lat: 48.93376, lng: 20.049676 },
    { lat: 48.933642, lng: 20.049175 },
    { lat: 48.933634, lng: 20.049157 },
    { lat: 48.933535, lng: 20.048905 },
    { lat: 48.933529, lng: 20.04889 },
    { lat: 48.933364, lng: 20.04854 },
    { lat: 48.933107, lng: 20.047758 },
    { lat: 48.933346, lng: 20.047193 },
    { lat: 48.933552, lng: 20.046479 },
    { lat: 48.933592, lng: 20.046059 },
    { lat: 48.933693, lng: 20.045659 },
    { lat: 48.933613, lng: 20.044894 },
    { lat: 48.933521, lng: 20.044792 },
    { lat: 48.933459, lng: 20.044721 },
    { lat: 48.933453, lng: 20.044653 },
    { lat: 48.933389, lng: 20.04395 },
    { lat: 48.933453, lng: 20.043682 },
    { lat: 48.933526, lng: 20.043084 },
    { lat: 48.933623, lng: 20.042774 },
    { lat: 48.933589, lng: 20.042459 },
    { lat: 48.933512, lng: 20.042401 },
    { lat: 48.933481, lng: 20.042378 },
    { lat: 48.933463, lng: 20.042265 },
    { lat: 48.933443, lng: 20.04215 },
    { lat: 48.933354, lng: 20.041985 },
    { lat: 48.933308, lng: 20.041843 },
    { lat: 48.933258, lng: 20.041683 },
    { lat: 48.933121, lng: 20.041334 },
    { lat: 48.93298, lng: 20.041137 },
    { lat: 48.932784, lng: 20.040841 },
    { lat: 48.932734, lng: 20.040752 },
    { lat: 48.932609, lng: 20.040171 },
    { lat: 48.932462, lng: 20.039816 },
    { lat: 48.932655, lng: 20.038778 },
    { lat: 48.933061, lng: 20.037235 },
    { lat: 48.932954, lng: 20.037133 },
    { lat: 48.932868, lng: 20.037052 },
    { lat: 48.932887, lng: 20.036977 },
    { lat: 48.933202, lng: 20.035769 },
    { lat: 48.933232, lng: 20.035658 },
    { lat: 48.933271, lng: 20.035967 },
    { lat: 48.933576, lng: 20.034864 },
    { lat: 48.933537, lng: 20.034046 },
    { lat: 48.933605, lng: 20.033603 },
    { lat: 48.933645, lng: 20.033581 },
    { lat: 48.933659, lng: 20.033501 },
    { lat: 48.933794, lng: 20.033482 },
    { lat: 48.933935, lng: 20.032757 },
    { lat: 48.933955, lng: 20.032745 },
    { lat: 48.934094, lng: 20.032777 },
    { lat: 48.934211, lng: 20.0326 },
    { lat: 48.934447, lng: 20.032681 },
    { lat: 48.934467, lng: 20.032689 },
    { lat: 48.934528, lng: 20.03283 },
    { lat: 48.934603, lng: 20.033006 },
    { lat: 48.934912, lng: 20.033191 },
    { lat: 48.935152, lng: 20.033486 },
    { lat: 48.935298, lng: 20.033629 },
    { lat: 48.935769, lng: 20.033767 },
    { lat: 48.935965, lng: 20.033826 },
    { lat: 48.936085, lng: 20.033629 },
    { lat: 48.936523, lng: 20.033764 },
    { lat: 48.93656, lng: 20.0334 },
    { lat: 48.937207, lng: 20.033573 },
    { lat: 48.938745, lng: 20.030804 },
    { lat: 48.939185, lng: 20.030192 },
    { lat: 48.939246, lng: 20.030031 },
    { lat: 48.939245, lng: 20.029767 },
    { lat: 48.939303, lng: 20.029737 },
    { lat: 48.939465, lng: 20.030148 },
    { lat: 48.940671, lng: 20.029587 },
    { lat: 48.940699, lng: 20.029258 },
    { lat: 48.940887, lng: 20.028932 },
    { lat: 48.940815, lng: 20.028741 },
    { lat: 48.940777, lng: 20.028643 },
    { lat: 48.940743, lng: 20.028553 },
    { lat: 48.941488, lng: 20.028803 },
    { lat: 48.942559, lng: 20.027443 },
    { lat: 48.94281, lng: 20.026444 },
    { lat: 48.943034, lng: 20.025722 },
    { lat: 48.943039, lng: 20.025706 },
    { lat: 48.943833, lng: 20.026832 },
    { lat: 48.943955, lng: 20.027004 },
    { lat: 48.945261, lng: 20.026045 },
    { lat: 48.945704, lng: 20.024818 },
    { lat: 48.94641, lng: 20.024236 },
    { lat: 48.947329, lng: 20.024821 },
    { lat: 48.948774, lng: 20.020787 },
    { lat: 48.949842, lng: 20.019823 },
    { lat: 48.95002, lng: 20.019717 },
    { lat: 48.950087, lng: 20.019676 },
    { lat: 48.950622, lng: 20.020542 },
    { lat: 48.950448, lng: 20.020996 },
    { lat: 48.950622, lng: 20.021303 },
    { lat: 48.950584, lng: 20.021474 },
    { lat: 48.950953, lng: 20.021809 },
    { lat: 48.950832, lng: 20.022042 },
    { lat: 48.95083, lng: 20.022044 },
    { lat: 48.951353, lng: 20.022458 },
    { lat: 48.952459, lng: 20.022511 },
    { lat: 48.952567, lng: 20.022516 },
    { lat: 48.952787, lng: 20.02309 },
    { lat: 48.952852, lng: 20.023259 },
    { lat: 48.95474, lng: 20.022882 },
    { lat: 48.955275, lng: 20.023266 },
    { lat: 48.955976, lng: 20.023006 },
    { lat: 48.956317, lng: 20.022269 },
    { lat: 48.95652, lng: 20.021239 },
    { lat: 48.956705, lng: 20.020189 },
    { lat: 48.956827, lng: 20.019414 },
    { lat: 48.95697, lng: 20.01863 },
    { lat: 48.957103, lng: 20.017863 },
    { lat: 48.957513, lng: 20.018231 },
    { lat: 48.957589, lng: 20.018299 },
    { lat: 48.957719, lng: 20.018489 },
    { lat: 48.957815, lng: 20.018629 },
    { lat: 48.958487, lng: 20.019371 },
    { lat: 48.958604, lng: 20.019501 },
    { lat: 48.958677, lng: 20.019623 },
    { lat: 48.958805, lng: 20.019833 },
    { lat: 48.959311, lng: 20.019892 },
    { lat: 48.959721, lng: 20.020185 },
    { lat: 48.959942, lng: 20.020342 },
    { lat: 48.959266, lng: 20.021204 },
    { lat: 48.95959, lng: 20.021639 },
    { lat: 48.959755, lng: 20.02186 },
    { lat: 48.959934, lng: 20.021725 },
    { lat: 48.960483, lng: 20.022344 },
    { lat: 48.960665, lng: 20.022549 },
    { lat: 48.960974, lng: 20.023559 },
    { lat: 48.960986, lng: 20.023588 },
    { lat: 48.961071, lng: 20.023777 },
    { lat: 48.963305, lng: 20.024268 },
    { lat: 48.96354, lng: 20.024245 },
    { lat: 48.963583, lng: 20.023826 },
    { lat: 48.963848, lng: 20.023768 },
    { lat: 48.963971, lng: 20.023968 },
    { lat: 48.964189, lng: 20.024016 },
    { lat: 48.964305, lng: 20.024041 },
    { lat: 48.964364, lng: 20.024253 },
    { lat: 48.964441, lng: 20.024535 },
    { lat: 48.966037, lng: 20.023336 },
    { lat: 48.966019, lng: 20.022559 },
    { lat: 48.966349, lng: 20.022741 },
    { lat: 48.966453, lng: 20.022798 },
    { lat: 48.966472, lng: 20.022841 },
    { lat: 48.966623, lng: 20.023183 },
    { lat: 48.966639, lng: 20.022873 },
    { lat: 48.966838, lng: 20.022446 },
    { lat: 48.96705, lng: 20.022295 },
    { lat: 48.967455, lng: 20.021858 },
    { lat: 48.969843, lng: 20.01843 },
    { lat: 48.970629, lng: 20.017222 },
    { lat: 48.971144, lng: 20.016484 },
    { lat: 48.971374, lng: 20.016339 },
    { lat: 48.971413, lng: 20.016315 },
    { lat: 48.971468, lng: 20.016356 },
    { lat: 48.971547, lng: 20.016414 },
    { lat: 48.971545, lng: 20.016619 },
    { lat: 48.97163, lng: 20.016544 },
    { lat: 48.971698, lng: 20.016221 },
    { lat: 48.972798, lng: 20.012172 },
    { lat: 48.97218, lng: 20.010461 },
    { lat: 48.973617, lng: 20.009402 },
    { lat: 48.976058, lng: 20.006834 },
    { lat: 48.977283, lng: 20.005599 },
    { lat: 48.977663, lng: 20.005543 },
    { lat: 48.980147, lng: 20.005195 },
    { lat: 48.981211, lng: 20.004824 },
    { lat: 48.98256, lng: 20.003195 },
    { lat: 48.983546, lng: 20.002003 },
    { lat: 48.984172, lng: 20.001462 },
    { lat: 48.984674, lng: 20.00034 },
    { lat: 48.986618, lng: 19.998574 },
    { lat: 48.987994, lng: 19.995552 },
    { lat: 48.988567, lng: 19.994773 },
    { lat: 48.989429, lng: 19.994328 },
    { lat: 48.990189, lng: 19.99462 },
    { lat: 48.990904, lng: 19.994489 },
    { lat: 48.992687, lng: 19.99329 },
    { lat: 48.993149, lng: 19.992038 },
    { lat: 48.993652, lng: 19.991044 },
    { lat: 48.994149, lng: 19.99047 },
    { lat: 48.994273, lng: 19.990318 },
    { lat: 48.996503, lng: 19.988665 },
    { lat: 48.996826, lng: 19.986946 },
    { lat: 48.997371, lng: 19.9863 },
    { lat: 48.998703, lng: 19.982988 },
    { lat: 48.999761, lng: 19.981921 },
    { lat: 48.999749, lng: 19.98115 },
    { lat: 48.999659, lng: 19.980466 },
    { lat: 48.999529, lng: 19.979476 },
    { lat: 48.99971, lng: 19.976971 },
    { lat: 49.001425, lng: 19.975618 },
    { lat: 49.002285, lng: 19.974517 },
    { lat: 49.003047, lng: 19.972415 },
    { lat: 49.003968, lng: 19.971521 },
    { lat: 49.004883, lng: 19.970943 },
    { lat: 49.005221, lng: 19.96815 },
    { lat: 49.00575, lng: 19.965214 },
    { lat: 49.006117, lng: 19.96439 },
    { lat: 49.007446, lng: 19.964294 },
    { lat: 49.008929, lng: 19.963551 },
    { lat: 49.009527, lng: 19.963568 },
    { lat: 49.009738, lng: 19.96379 },
    { lat: 49.009745, lng: 19.963892 },
    { lat: 49.009776, lng: 19.964281 },
    { lat: 49.009877, lng: 19.964747 },
    { lat: 49.0099, lng: 19.964789 },
    { lat: 49.010181, lng: 19.965292 },
    { lat: 49.011905, lng: 19.967868 },
    { lat: 49.0134, lng: 19.968341 },
    { lat: 49.013362, lng: 19.968404 },
    { lat: 49.01332, lng: 19.968457 },
    { lat: 49.013586, lng: 19.968496 },
    { lat: 49.01376, lng: 19.968641 },
    { lat: 49.01414, lng: 19.968884 },
    { lat: 49.014424, lng: 19.968718 },
    { lat: 49.014536, lng: 19.968788 },
    { lat: 49.015011, lng: 19.969366 },
    { lat: 49.015074, lng: 19.969326 },
    { lat: 49.01531, lng: 19.969256 },
    { lat: 49.015585, lng: 19.969214 },
    { lat: 49.015783, lng: 19.969142 },
    { lat: 49.015825, lng: 19.969123 },
    { lat: 49.01587, lng: 19.969108 },
    { lat: 49.016042, lng: 19.969042 },
    { lat: 49.016489, lng: 19.968954 },
    { lat: 49.016525, lng: 19.968954 },
    { lat: 49.01657, lng: 19.968958 },
    { lat: 49.0165884, lng: 19.9688774 },
    { lat: 49.0167159, lng: 19.9683727 },
    { lat: 49.0167435, lng: 19.9682244 },
    { lat: 49.0167775, lng: 19.9678238 },
    { lat: 49.0167363, lng: 19.9674597 },
    { lat: 49.0165975, lng: 19.9671038 },
    { lat: 49.0166528, lng: 19.9670784 },
    { lat: 49.0169361, lng: 19.9670689 },
    { lat: 49.0173804, lng: 19.96659 },
    { lat: 49.017806, lng: 19.9663479 },
    { lat: 49.0185263, lng: 19.9660941 },
    { lat: 49.0188493, lng: 19.9658648 },
    { lat: 49.0189256, lng: 19.9658107 },
    { lat: 49.0190818, lng: 19.9658265 },
    { lat: 49.019271, lng: 19.9652588 },
    { lat: 49.0194638, lng: 19.9645942 },
    { lat: 49.0199462, lng: 19.9630413 },
    { lat: 49.0202905, lng: 19.9624144 },
    { lat: 49.0207554, lng: 19.9615167 },
    { lat: 49.0208561, lng: 19.9612048 },
    { lat: 49.0217296, lng: 19.9583141 },
    { lat: 49.0220921, lng: 19.9570448 },
    { lat: 49.0221912, lng: 19.9565891 },
    { lat: 49.0222552, lng: 19.9557266 },
    { lat: 49.0222101, lng: 19.9543259 },
    { lat: 49.0220668, lng: 19.9533561 },
    { lat: 49.0219212, lng: 19.9525993 },
    { lat: 49.0217517, lng: 19.9516704 },
    { lat: 49.0214693, lng: 19.9505243 },
    { lat: 49.0214049, lng: 19.9498225 },
    { lat: 49.0213939, lng: 19.9489712 },
    { lat: 49.0213985, lng: 19.9484346 },
    { lat: 49.0226795, lng: 19.9485478 },
    { lat: 49.0242367, lng: 19.9454547 },
    { lat: 49.0246818, lng: 19.945593 },
    { lat: 49.0252565, lng: 19.9457055 },
    { lat: 49.0255677, lng: 19.9457119 },
    { lat: 49.0263945, lng: 19.9461671 },
    { lat: 49.0266685, lng: 19.9463641 },
    { lat: 49.0275598, lng: 19.9468522 },
    { lat: 49.0282935, lng: 19.9476934 },
    { lat: 49.0286164, lng: 19.9477155 },
    { lat: 49.0291589, lng: 19.9477019 },
    { lat: 49.0295725, lng: 19.9476797 },
    { lat: 49.0311398, lng: 19.9476646 },
    { lat: 49.0316229, lng: 19.9473711 },
    { lat: 49.031561, lng: 19.9456778 },
    { lat: 49.0315013, lng: 19.9444775 },
    { lat: 49.0312495, lng: 19.9421311 },
    { lat: 49.0308346, lng: 19.9387183 },
    { lat: 49.0308168, lng: 19.9384802 },
    { lat: 49.0312187, lng: 19.9375365 },
    { lat: 49.0324543, lng: 19.9360666 },
    { lat: 49.0334753, lng: 19.9354872 },
    { lat: 49.0346014, lng: 19.9350556 },
    { lat: 49.0353277, lng: 19.9345394 },
    { lat: 49.0358824, lng: 19.9350885 },
    { lat: 49.0363724, lng: 19.9355444 },
    { lat: 49.0370401, lng: 19.9350271 },
    { lat: 49.0379981, lng: 19.9353107 },
    { lat: 49.0386926, lng: 19.9360211 },
    { lat: 49.0391011, lng: 19.9371119 },
    { lat: 49.0393548, lng: 19.9379197 },
    { lat: 49.0393896, lng: 19.938023 },
    { lat: 49.0394169, lng: 19.938565 },
    { lat: 49.0401291, lng: 19.9405037 },
    { lat: 49.0408227, lng: 19.9408713 },
    { lat: 49.0413769, lng: 19.9412971 },
    { lat: 49.0414895, lng: 19.9413547 },
    { lat: 49.0423918, lng: 19.9414386 },
    { lat: 49.0434444, lng: 19.9414457 },
    { lat: 49.0435912, lng: 19.941565 },
    { lat: 49.0438991, lng: 19.941977 },
    { lat: 49.0440207, lng: 19.9418144 },
    { lat: 49.0447424, lng: 19.9416724 },
    { lat: 49.045319, lng: 19.9417776 },
    { lat: 49.0456818, lng: 19.94178 },
    { lat: 49.0457289, lng: 19.9416142 },
    { lat: 49.0458332, lng: 19.9414669 },
    { lat: 49.0458815, lng: 19.9411742 },
    { lat: 49.0463289, lng: 19.940507 },
    { lat: 49.0468547, lng: 19.9399384 },
    { lat: 49.0469839, lng: 19.9396896 },
    { lat: 49.0472956, lng: 19.93848 },
    { lat: 49.0472252, lng: 19.9380348 },
    { lat: 49.0474754, lng: 19.937558 },
    { lat: 49.0479838, lng: 19.9374852 },
    { lat: 49.0484741, lng: 19.937654 },
    { lat: 49.0487826, lng: 19.9374128 },
    { lat: 49.0489145, lng: 19.9369587 },
    { lat: 49.0492695, lng: 19.9361618 },
    { lat: 49.0493876, lng: 19.9355908 },
    { lat: 49.0501233, lng: 19.9338853 },
    { lat: 49.0509498, lng: 19.9338773 },
    { lat: 49.0519412, lng: 19.9345801 },
    { lat: 49.052448, lng: 19.9352708 },
    { lat: 49.0531083, lng: 19.9357621 },
    { lat: 49.0535526, lng: 19.9357199 },
    { lat: 49.0541425, lng: 19.9359217 },
    { lat: 49.0545082, lng: 19.935565 },
    { lat: 49.0549366, lng: 19.9354418 },
    { lat: 49.0552839, lng: 19.935882 },
    { lat: 49.0556134, lng: 19.9359778 },
    { lat: 49.0558057, lng: 19.9359131 },
    { lat: 49.0560076, lng: 19.9359735 },
    { lat: 49.0562337, lng: 19.9362072 },
    { lat: 49.0566173, lng: 19.9363558 },
    { lat: 49.0569856, lng: 19.9364356 },
    { lat: 49.0574468, lng: 19.9367161 },
    { lat: 49.0579697, lng: 19.9374183 },
    { lat: 49.0587155, lng: 19.9386821 },
    { lat: 49.0595008, lng: 19.9390852 },
    { lat: 49.0605257, lng: 19.9394846 },
    { lat: 49.0614511, lng: 19.9400691 },
    { lat: 49.0616821, lng: 19.9404708 },
    { lat: 49.0619924, lng: 19.9406428 },
    { lat: 49.0621708, lng: 19.9408938 },
    { lat: 49.0626254, lng: 19.94118 },
    { lat: 49.0626788, lng: 19.9415375 },
    { lat: 49.062692, lng: 19.942441 },
    { lat: 49.0633664, lng: 19.944342 },
    { lat: 49.0637484, lng: 19.9448264 },
    { lat: 49.064406, lng: 19.9454675 },
    { lat: 49.0646521, lng: 19.9456131 },
    { lat: 49.0648696, lng: 19.9457777 },
    { lat: 49.0648769, lng: 19.9458955 },
    { lat: 49.0647965, lng: 19.9460495 },
    { lat: 49.0648074, lng: 19.9461476 },
    { lat: 49.0650523, lng: 19.9464819 },
    { lat: 49.0653505, lng: 19.9467421 },
    { lat: 49.0654602, lng: 19.9466966 },
    { lat: 49.0655062, lng: 19.9467737 },
    { lat: 49.0655304, lng: 19.9469466 },
    { lat: 49.0654668, lng: 19.9472308 },
    { lat: 49.0655791, lng: 19.9475159 },
    { lat: 49.0657127, lng: 19.9475887 },
    { lat: 49.0659143, lng: 19.9479621 },
    { lat: 49.0659209, lng: 19.9480409 },
    { lat: 49.0658408, lng: 19.948146 },
    { lat: 49.0657817, lng: 19.9480519 },
    { lat: 49.0657042, lng: 19.9482196 },
    { lat: 49.0657348, lng: 19.9483592 },
    { lat: 49.0658195, lng: 19.9484149 },
    { lat: 49.0660985, lng: 19.9480592 },
    { lat: 49.0661927, lng: 19.948128 },
    { lat: 49.066275, lng: 19.94856 },
    { lat: 49.0664821, lng: 19.9491186 },
    { lat: 49.0667935, lng: 19.9491088 },
    { lat: 49.0669059, lng: 19.9488583 },
    { lat: 49.0670052, lng: 19.9487541 },
    { lat: 49.0672729, lng: 19.9488287 },
    { lat: 49.0676286, lng: 19.9493075 },
    { lat: 49.0677025, lng: 19.9492672 },
    { lat: 49.0678039, lng: 19.9489743 },
    { lat: 49.067794, lng: 19.9488158 },
    { lat: 49.0678333, lng: 19.9486837 },
    { lat: 49.0684026, lng: 19.9485885 },
    { lat: 49.068564, lng: 19.9484586 },
    { lat: 49.0687933, lng: 19.9483932 },
    { lat: 49.0689064, lng: 19.9485123 },
    { lat: 49.0688922, lng: 19.948708 },
    { lat: 49.0692279, lng: 19.949008 },
    { lat: 49.0694567, lng: 19.9488252 },
    { lat: 49.0695482, lng: 19.9485074 },
    { lat: 49.0696572, lng: 19.9484727 },
    { lat: 49.0698834, lng: 19.9486821 },
    { lat: 49.0701003, lng: 19.9485921 },
    { lat: 49.0702934, lng: 19.9486333 },
    { lat: 49.0702971, lng: 19.9483172 },
    { lat: 49.0704362, lng: 19.9480867 },
    { lat: 49.0706164, lng: 19.9481115 },
    { lat: 49.0708753, lng: 19.948433 },
    { lat: 49.0709625, lng: 19.94874 },
    { lat: 49.071194, lng: 19.9488605 },
    { lat: 49.0712972, lng: 19.9489866 },
    { lat: 49.0711811, lng: 19.9492235 },
    { lat: 49.0712398, lng: 19.9492569 },
    { lat: 49.0714392, lng: 19.9491761 },
    { lat: 49.0715776, lng: 19.9491997 },
    { lat: 49.0715951, lng: 19.9493187 },
    { lat: 49.0717356, lng: 19.9493388 },
    { lat: 49.071937, lng: 19.9493067 },
    { lat: 49.0720157, lng: 19.9490386 },
    { lat: 49.0723641, lng: 19.9490173 },
    { lat: 49.0723823, lng: 19.9490504 },
    { lat: 49.0723654, lng: 19.9492374 },
    { lat: 49.0726941, lng: 19.9496653 },
    { lat: 49.0727844, lng: 19.9496403 },
    { lat: 49.0727864, lng: 19.9495802 },
    { lat: 49.0726689, lng: 19.9492765 },
    { lat: 49.0727096, lng: 19.9491156 },
    { lat: 49.0728317, lng: 19.9491569 },
    { lat: 49.072929, lng: 19.9493781 },
    { lat: 49.0729801, lng: 19.9494428 },
    { lat: 49.0730201, lng: 19.9494066 },
    { lat: 49.0730957, lng: 19.9491941 },
    { lat: 49.0730775, lng: 19.9488107 },
    { lat: 49.0734015, lng: 19.948748 },
    { lat: 49.073403, lng: 19.9489753 },
    { lat: 49.0735608, lng: 19.9490012 },
    { lat: 49.0736889, lng: 19.9486723 },
    { lat: 49.0738501, lng: 19.9484073 },
    { lat: 49.073949, lng: 19.9485647 },
    { lat: 49.0743089, lng: 19.9483893 },
    { lat: 49.074599, lng: 19.9485138 },
    { lat: 49.0747905, lng: 19.9484043 },
    { lat: 49.0750253, lng: 19.9483624 },
    { lat: 49.0751096, lng: 19.9479033 },
    { lat: 49.0751627, lng: 19.9479355 },
    { lat: 49.0753348, lng: 19.9482749 },
    { lat: 49.0755343, lng: 19.9481746 },
    { lat: 49.0759572, lng: 19.9482625 },
    { lat: 49.0761503, lng: 19.9480332 },
    { lat: 49.076454, lng: 19.9478636 },
    { lat: 49.0768839, lng: 19.9480172 },
    { lat: 49.0769009, lng: 19.9480731 },
    { lat: 49.0767924, lng: 19.9483064 },
    { lat: 49.0769197, lng: 19.9485207 },
    { lat: 49.0771195, lng: 19.9483166 },
    { lat: 49.0772323, lng: 19.9484558 },
    { lat: 49.0774882, lng: 19.9480853 },
    { lat: 49.0775625, lng: 19.9481352 },
    { lat: 49.0776618, lng: 19.9483807 },
    { lat: 49.0778383, lng: 19.9483463 },
    { lat: 49.0777068, lng: 19.9478948 },
    { lat: 49.0778222, lng: 19.9477776 },
    { lat: 49.0781748, lng: 19.9478041 },
    { lat: 49.0780917, lng: 19.9475179 },
    { lat: 49.0782302, lng: 19.9474096 },
    { lat: 49.0784648, lng: 19.9474144 },
    { lat: 49.07848, lng: 19.9474575 },
    { lat: 49.0783939, lng: 19.9477681 },
    { lat: 49.0784943, lng: 19.9478208 },
    { lat: 49.0787525, lng: 19.94756 },
    { lat: 49.0791275, lng: 19.9475038 },
    { lat: 49.0794458, lng: 19.9473047 },
    { lat: 49.0795791, lng: 19.9470205 },
    { lat: 49.0798073, lng: 19.9469027 },
    { lat: 49.0801894, lng: 19.9469069 },
    { lat: 49.0804713, lng: 19.946914 },
    { lat: 49.0805289, lng: 19.9470772 },
    { lat: 49.0807837, lng: 19.9466824 },
    { lat: 49.081521, lng: 19.9469402 },
    { lat: 49.0816807, lng: 19.9473645 },
    { lat: 49.0819131, lng: 19.9474518 },
    { lat: 49.082312, lng: 19.9472449 },
    { lat: 49.0825087, lng: 19.9473439 },
    { lat: 49.0825826, lng: 19.9475455 },
    { lat: 49.0824972, lng: 19.9479013 },
    { lat: 49.0824286, lng: 19.9479827 },
    { lat: 49.0824203, lng: 19.9482404 },
    { lat: 49.0826254, lng: 19.9484875 },
    { lat: 49.0828954, lng: 19.9484246 },
    { lat: 49.0829932, lng: 19.9485002 },
    { lat: 49.0828729, lng: 19.9486143 },
    { lat: 49.0829148, lng: 19.9487252 },
    { lat: 49.0831552, lng: 19.9488169 },
    { lat: 49.0832224, lng: 19.9489404 },
    { lat: 49.0831998, lng: 19.9490989 },
    { lat: 49.0835043, lng: 19.9493816 },
    { lat: 49.0837703, lng: 19.9495724 },
    { lat: 49.0837946, lng: 19.9498543 },
    { lat: 49.0840018, lng: 19.9500451 },
    { lat: 49.0839981, lng: 19.9502761 },
    { lat: 49.0841206, lng: 19.9505136 },
    { lat: 49.0843499, lng: 19.9507411 },
    { lat: 49.0844478, lng: 19.9510388 },
    { lat: 49.0846565, lng: 19.9511256 },
    { lat: 49.0847417, lng: 19.9513329 },
    { lat: 49.0846376, lng: 19.9515852 },
    { lat: 49.084655, lng: 19.9517862 },
    { lat: 49.0848201, lng: 19.9519085 },
    { lat: 49.0850024, lng: 19.9518811 },
    { lat: 49.0851078, lng: 19.9518112 },
    { lat: 49.0852425, lng: 19.9519904 },
    { lat: 49.0857057, lng: 19.952157 },
    { lat: 49.0860636, lng: 19.951939 },
    { lat: 49.0861506, lng: 19.9520268 },
    { lat: 49.0861899, lng: 19.9521811 },
    { lat: 49.0860566, lng: 19.9522293 },
    { lat: 49.0860453, lng: 19.9525225 },
    { lat: 49.0860702, lng: 19.9525532 },
    { lat: 49.0863124, lng: 19.952419 },
    { lat: 49.0866283, lng: 19.9524612 },
    { lat: 49.0867144, lng: 19.9523623 },
    { lat: 49.0867299, lng: 19.9520866 },
    { lat: 49.0870791, lng: 19.952116 },
    { lat: 49.0873277, lng: 19.9524566 },
    { lat: 49.0876446, lng: 19.9526004 },
    { lat: 49.0884918, lng: 19.9526037 },
    { lat: 49.0895692, lng: 19.9522963 },
    { lat: 49.0896432, lng: 19.9522891 },
    { lat: 49.0898075, lng: 19.9520978 },
    { lat: 49.0899894, lng: 19.9521642 },
    { lat: 49.0900139, lng: 19.9524271 },
    { lat: 49.0902127, lng: 19.9524911 },
    { lat: 49.0906574, lng: 19.9522733 },
    { lat: 49.0909131, lng: 19.9520885 },
    { lat: 49.0910877, lng: 19.9521373 },
    { lat: 49.0910966, lng: 19.9521368 },
    { lat: 49.0912263, lng: 19.9523592 },
    { lat: 49.0913783, lng: 19.9521378 },
    { lat: 49.0915895, lng: 19.95175 },
    { lat: 49.0920207, lng: 19.9513423 },
    { lat: 49.0924816, lng: 19.9513755 },
    { lat: 49.0926464, lng: 19.9508696 },
    { lat: 49.0931258, lng: 19.9507299 },
    { lat: 49.0936532, lng: 19.9503063 },
    { lat: 49.0939034, lng: 19.9503341 },
    { lat: 49.0941646, lng: 19.9501183 },
    { lat: 49.0942984, lng: 19.9503026 },
    { lat: 49.0945548, lng: 19.9502101 },
    { lat: 49.0945896, lng: 19.9499357 },
    { lat: 49.0950569, lng: 19.9496416 },
    { lat: 49.0954229, lng: 19.9493149 },
    { lat: 49.0967816, lng: 19.9482188 },
    { lat: 49.0970052, lng: 19.9482618 },
    { lat: 49.0977698, lng: 19.9485005 },
    { lat: 49.0983876, lng: 19.9482999 },
    { lat: 49.0986531, lng: 19.9483452 },
    { lat: 49.0995322, lng: 19.9487579 },
    { lat: 49.0997767, lng: 19.9487363 },
    { lat: 49.1004083, lng: 19.9489438 },
    { lat: 49.1008239, lng: 19.9490227 },
    { lat: 49.1010079, lng: 19.9490073 },
    { lat: 49.1014987, lng: 19.9491073 },
    { lat: 49.1018791, lng: 19.9489125 },
    { lat: 49.1021841, lng: 19.9489314 },
    { lat: 49.1023223, lng: 19.9490305 },
    { lat: 49.1024156, lng: 19.9492687 },
    { lat: 49.1029106, lng: 19.9494161 },
    { lat: 49.1034171, lng: 19.9498375 },
    { lat: 49.1037443, lng: 19.9499857 },
    { lat: 49.1047902, lng: 19.9498938 },
    { lat: 49.1049184, lng: 19.9501074 },
    { lat: 49.1054201, lng: 19.9498455 },
    { lat: 49.105802, lng: 19.9500391 },
    { lat: 49.1062086, lng: 19.9502782 },
    { lat: 49.1063742, lng: 19.9506547 },
    { lat: 49.1066669, lng: 19.9506276 },
    { lat: 49.1071104, lng: 19.9501078 },
    { lat: 49.1073345, lng: 19.9500828 },
    { lat: 49.1075561, lng: 19.9502921 },
    { lat: 49.1079791, lng: 19.9504386 },
    { lat: 49.1080849, lng: 19.9506476 },
    { lat: 49.1081324, lng: 19.9509554 },
    { lat: 49.1081113, lng: 19.9512275 },
    { lat: 49.1083215, lng: 19.951591 },
    { lat: 49.1085821, lng: 19.9518457 },
    { lat: 49.1088595, lng: 19.9519324 },
    { lat: 49.1092196, lng: 19.95214 },
    { lat: 49.109814, lng: 19.9521865 },
    { lat: 49.110063, lng: 19.952431 },
    { lat: 49.110129, lng: 19.952418 },
    { lat: 49.11047, lng: 19.952481 },
    { lat: 49.110581, lng: 19.952443 },
    { lat: 49.110757, lng: 19.952463 },
    { lat: 49.111015, lng: 19.952403 },
    { lat: 49.111218, lng: 19.952317 },
    { lat: 49.111839, lng: 19.952237 },
    { lat: 49.112129, lng: 19.95203 },
    { lat: 49.112201, lng: 19.952124 },
    { lat: 49.11262, lng: 19.952128 },
    { lat: 49.113034, lng: 19.952072 },
    { lat: 49.1131733, lng: 19.9520814 },
    { lat: 49.113554, lng: 19.952107 },
    { lat: 49.113994, lng: 19.952073 },
    { lat: 49.114286, lng: 19.952035 },
    { lat: 49.114713, lng: 19.952044 },
    { lat: 49.114959, lng: 19.952081 },
    { lat: 49.114768, lng: 19.951527 },
    { lat: 49.11475, lng: 19.95148 },
    { lat: 49.114852, lng: 19.951364 },
    { lat: 49.115097, lng: 19.951203 },
    { lat: 49.115408, lng: 19.951004 },
    { lat: 49.115627, lng: 19.951061 },
    { lat: 49.115968, lng: 19.950552 },
    { lat: 49.116124, lng: 19.9506 },
    { lat: 49.116607, lng: 19.950845 },
    { lat: 49.116801, lng: 19.950636 },
    { lat: 49.117206, lng: 19.950264 },
    { lat: 49.117323, lng: 19.949942 },
    { lat: 49.117829, lng: 19.949452 },
    { lat: 49.117888, lng: 19.94874 },
    { lat: 49.117828, lng: 19.948516 },
    { lat: 49.11796, lng: 19.948387 },
    { lat: 49.118383, lng: 19.948104 },
    { lat: 49.118549, lng: 19.948271 },
    { lat: 49.118706, lng: 19.948303 },
    { lat: 49.118933, lng: 19.947967 },
    { lat: 49.119204, lng: 19.947904 },
    { lat: 49.119327, lng: 19.9478 },
    { lat: 49.119468, lng: 19.947518 },
    { lat: 49.119928, lng: 19.947752 },
    { lat: 49.120227, lng: 19.947614 },
    { lat: 49.120254, lng: 19.947376 },
    { lat: 49.12051, lng: 19.947382 },
    { lat: 49.12051, lng: 19.947407 },
    { lat: 49.120521, lng: 19.947499 },
    { lat: 49.12063, lng: 19.947593 },
    { lat: 49.120781, lng: 19.947667 },
    { lat: 49.121012, lng: 19.947906 },
    { lat: 49.121152, lng: 19.948027 },
    { lat: 49.121339, lng: 19.948115 },
    { lat: 49.121396, lng: 19.948171 },
    { lat: 49.121502, lng: 19.948295 },
    { lat: 49.121595, lng: 19.948297 },
    { lat: 49.122134, lng: 19.948232 },
    { lat: 49.122244, lng: 19.948206 },
    { lat: 49.122332, lng: 19.948233 },
    { lat: 49.122581, lng: 19.948394 },
    { lat: 49.122654, lng: 19.948406 },
    { lat: 49.122774, lng: 19.94837 },
    { lat: 49.123275, lng: 19.948042 },
    { lat: 49.123758, lng: 19.947948 },
    { lat: 49.124039, lng: 19.94788 },
    { lat: 49.124785, lng: 19.947632 },
    { lat: 49.125142, lng: 19.947585 },
    { lat: 49.125443, lng: 19.947587 },
    { lat: 49.12565, lng: 19.947625 },
    { lat: 49.125806, lng: 19.94771 },
    { lat: 49.125934, lng: 19.947862 },
    { lat: 49.126107, lng: 19.948007 },
    { lat: 49.126315, lng: 19.948222 },
    { lat: 49.126992, lng: 19.948483 },
    { lat: 49.127296, lng: 19.948574 },
    { lat: 49.127483, lng: 19.948699 },
    { lat: 49.127633, lng: 19.948937 },
    { lat: 49.127756, lng: 19.949168 },
    { lat: 49.127858, lng: 19.949435 },
    { lat: 49.127988, lng: 19.949763 },
    { lat: 49.128124, lng: 19.950001 },
    { lat: 49.128303, lng: 19.950216 },
    { lat: 49.128545, lng: 19.950402 },
    { lat: 49.128814, lng: 19.950711 },
    { lat: 49.129082, lng: 19.95109 },
    { lat: 49.129318, lng: 19.951458 },
    { lat: 49.129462, lng: 19.951707 },
    { lat: 49.129638, lng: 19.952104 },
    { lat: 49.129777, lng: 19.952151 },
    { lat: 49.129794, lng: 19.952592 },
    { lat: 49.129957, lng: 19.952651 },
    { lat: 49.130125, lng: 19.952893 },
    { lat: 49.130145, lng: 19.952994 },
    { lat: 49.13017, lng: 19.953051 },
    { lat: 49.130194, lng: 19.953074 },
    { lat: 49.130231, lng: 19.95308 },
    { lat: 49.130301, lng: 19.953071 },
    { lat: 49.130351, lng: 19.953063 },
    { lat: 49.130387, lng: 19.953067 },
    { lat: 49.1306, lng: 19.953345 },
    { lat: 49.130639, lng: 19.953432 },
    { lat: 49.130788, lng: 19.953759 },
    { lat: 49.130818, lng: 19.953815 },
    { lat: 49.130892, lng: 19.95391 },
    { lat: 49.13095, lng: 19.954021 },
    { lat: 49.130927, lng: 19.9545 },
    { lat: 49.131112, lng: 19.954476 },
    { lat: 49.13138, lng: 19.954933 },
    { lat: 49.131384, lng: 19.955244 },
    { lat: 49.13159, lng: 19.955386 },
    { lat: 49.131535, lng: 19.955549 },
    { lat: 49.131571, lng: 19.955799 },
    { lat: 49.131534, lng: 19.955826 },
    { lat: 49.131636, lng: 19.955877 },
    { lat: 49.132069, lng: 19.956229 },
    { lat: 49.132651, lng: 19.956808 },
    { lat: 49.133449, lng: 19.957825 },
    { lat: 49.134057, lng: 19.95864 },
    { lat: 49.13433, lng: 19.958878 },
    { lat: 49.134566, lng: 19.958989 },
    { lat: 49.134805, lng: 19.959224 },
    { lat: 49.135001, lng: 19.959678 },
    { lat: 49.135762, lng: 19.960441 },
    { lat: 49.136359, lng: 19.960943 },
    { lat: 49.136598, lng: 19.961207 },
    { lat: 49.136987, lng: 19.961244 },
    { lat: 49.137172, lng: 19.961528 },
    { lat: 49.137368, lng: 19.961739 },
    { lat: 49.137563, lng: 19.962158 },
    { lat: 49.137947, lng: 19.963207 },
    { lat: 49.138171, lng: 19.963514 },
    { lat: 49.138449, lng: 19.963734 },
    { lat: 49.138693, lng: 19.96385 },
    { lat: 49.138998, lng: 19.963982 },
    { lat: 49.139106, lng: 19.964286 },
    { lat: 49.139179, lng: 19.964793 },
    { lat: 49.139227, lng: 19.96501 },
    { lat: 49.139261, lng: 19.96517 },
    { lat: 49.139283, lng: 19.965268 },
    { lat: 49.139537, lng: 19.966045 },
    { lat: 49.139536, lng: 19.966298 },
    { lat: 49.139544, lng: 19.966503 },
    { lat: 49.139487, lng: 19.966887 },
    { lat: 49.139495, lng: 19.967149 },
    { lat: 49.139648, lng: 19.967521 },
    { lat: 49.139728, lng: 19.968526 },
    { lat: 49.13972, lng: 19.969083 },
    { lat: 49.139739, lng: 19.969984 },
    { lat: 49.139435, lng: 19.970794 },
    { lat: 49.139252, lng: 19.971878 },
    { lat: 49.13949, lng: 19.972688 },
    { lat: 49.139763, lng: 19.973123 },
    { lat: 49.139958, lng: 19.974131 },
    { lat: 49.14027, lng: 19.975254 },
    { lat: 49.140297, lng: 19.97579 },
    { lat: 49.140192, lng: 19.977088 },
    { lat: 49.140074, lng: 19.977468 },
    { lat: 49.139933, lng: 19.977692 },
    { lat: 49.139965, lng: 19.978177 },
    { lat: 49.139725, lng: 19.978507 },
    { lat: 49.139579, lng: 19.979571 },
    { lat: 49.140207, lng: 19.980318 },
    { lat: 49.140516, lng: 19.980937 },
    { lat: 49.140786, lng: 19.981261 },
    { lat: 49.141231, lng: 19.981677 },
    { lat: 49.141366, lng: 19.981808 },
    { lat: 49.141699, lng: 19.981797 },
    { lat: 49.142028, lng: 19.981673 },
    { lat: 49.142285, lng: 19.981555 },
    { lat: 49.142413, lng: 19.981629 },
    { lat: 49.142515, lng: 19.981622 },
    { lat: 49.142631, lng: 19.981513 },
    { lat: 49.142742, lng: 19.981499 },
    { lat: 49.142848, lng: 19.981554 },
    { lat: 49.142932, lng: 19.981483 },
    { lat: 49.143095, lng: 19.98143 },
    { lat: 49.143248, lng: 19.981375 },
    { lat: 49.143372, lng: 19.981364 },
    { lat: 49.143632, lng: 19.981306 },
    { lat: 49.14381, lng: 19.981269 },
    { lat: 49.143954, lng: 19.981371 },
    { lat: 49.144221, lng: 19.981542 },
    { lat: 49.144262, lng: 19.98155 },
    { lat: 49.144415, lng: 19.981707 },
    { lat: 49.144483, lng: 19.98189 },
    { lat: 49.144562, lng: 19.982032 },
    { lat: 49.144709, lng: 19.982132 },
    { lat: 49.144902, lng: 19.982412 },
    { lat: 49.145174, lng: 19.982729 },
    { lat: 49.1453, lng: 19.982766 },
    { lat: 49.145479, lng: 19.982798 },
    { lat: 49.14595, lng: 19.983018 },
    { lat: 49.14646, lng: 19.982996 },
    { lat: 49.146684, lng: 19.983009 },
    { lat: 49.146839, lng: 19.983196 },
    { lat: 49.147055, lng: 19.983419 },
    { lat: 49.147208, lng: 19.983544 },
    { lat: 49.147233, lng: 19.983769 },
    { lat: 49.147524, lng: 19.984068 },
    { lat: 49.147687, lng: 19.984417 },
    { lat: 49.148039, lng: 19.984876 },
    { lat: 49.148147, lng: 19.984918 },
    { lat: 49.14832, lng: 19.985067 },
    { lat: 49.148364, lng: 19.985058 },
    { lat: 49.148427, lng: 19.98514 },
    { lat: 49.148503, lng: 19.985381 },
    { lat: 49.148708, lng: 19.985649 },
    { lat: 49.148806, lng: 19.985854 },
    { lat: 49.148891, lng: 19.985907 },
    { lat: 49.149444, lng: 19.986057 },
    { lat: 49.149891, lng: 19.986052 },
    { lat: 49.150111, lng: 19.98591 },
    { lat: 49.150251, lng: 19.985872 },
    { lat: 49.150336, lng: 19.985977 },
    { lat: 49.150431, lng: 19.986135 },
    { lat: 49.150714, lng: 19.986107 },
    { lat: 49.151109, lng: 19.985913 },
    { lat: 49.151558, lng: 19.985811 },
    { lat: 49.151756, lng: 19.985784 },
    { lat: 49.151933, lng: 19.985664 },
    { lat: 49.152056, lng: 19.985639 },
    { lat: 49.152296, lng: 19.98563 },
    { lat: 49.152617, lng: 19.98585 },
    { lat: 49.152747, lng: 19.985921 },
    { lat: 49.153166, lng: 19.986518 },
    { lat: 49.153435, lng: 19.986979 },
    { lat: 49.153736, lng: 19.987342 },
    { lat: 49.154244, lng: 19.987804 },
    { lat: 49.154501, lng: 19.988218 },
    { lat: 49.154658, lng: 19.988451 },
    { lat: 49.154838, lng: 19.988929 },
    { lat: 49.155019, lng: 19.989239 },
    { lat: 49.155434, lng: 19.989811 },
    { lat: 49.156053, lng: 19.990656 },
    { lat: 49.156171, lng: 19.991176 },
    { lat: 49.156304, lng: 19.991591 },
    { lat: 49.156475, lng: 19.991934 },
    { lat: 49.157335, lng: 19.996509 },
    { lat: 49.162818, lng: 20.000131 },
    { lat: 49.161044, lng: 19.996887 },
    { lat: 49.160742, lng: 19.996475 },
    { lat: 49.160674, lng: 19.996026 },
    { lat: 49.160395, lng: 19.99545 },
    { lat: 49.159979, lng: 19.994804 },
    { lat: 49.159869, lng: 19.994634 },
    { lat: 49.159751, lng: 19.99432 },
    { lat: 49.159653, lng: 19.993583 },
    { lat: 49.159589, lng: 19.993318 },
    { lat: 49.15944, lng: 19.993049 },
    { lat: 49.15911, lng: 19.992411 },
    { lat: 49.158774, lng: 19.991724 },
    { lat: 49.15854, lng: 19.991068 },
    { lat: 49.158397, lng: 19.990606 },
    { lat: 49.15834, lng: 19.990197 },
    { lat: 49.158345, lng: 19.989918 },
    { lat: 49.158369, lng: 19.989186 },
    { lat: 49.158343, lng: 19.987576 },
    { lat: 49.158325, lng: 19.987192 },
    { lat: 49.158359, lng: 19.986705 },
    { lat: 49.158305, lng: 19.986539 },
    { lat: 49.157981, lng: 19.98617 },
    { lat: 49.157929, lng: 19.986008 },
    { lat: 49.157658, lng: 19.985829 },
    { lat: 49.157387, lng: 19.985479 },
    { lat: 49.157233, lng: 19.98486 },
    { lat: 49.157134, lng: 19.983727 },
    { lat: 49.157044, lng: 19.983234 },
    { lat: 49.157027, lng: 19.982823 },
    { lat: 49.156947, lng: 19.982583 },
    { lat: 49.1569, lng: 19.981937 },
    { lat: 49.156951, lng: 19.981764 },
    { lat: 49.156865, lng: 19.981435 },
    { lat: 49.156857, lng: 19.981235 },
    { lat: 49.156736, lng: 19.981028 },
    { lat: 49.156696, lng: 19.980668 },
    { lat: 49.156601, lng: 19.980304 },
    { lat: 49.156673, lng: 19.980114 },
    { lat: 49.156539, lng: 19.979014 },
    { lat: 49.155992, lng: 19.978088 },
    { lat: 49.155895, lng: 19.977922 },
    { lat: 49.155266, lng: 19.977453 },
    { lat: 49.154436, lng: 19.976353 },
    { lat: 49.154186, lng: 19.975898 },
    { lat: 49.154098, lng: 19.975698 },
    { lat: 49.153722, lng: 19.974863 },
    { lat: 49.153602, lng: 19.974572 },
    { lat: 49.153148, lng: 19.97347 },
    { lat: 49.152964, lng: 19.972945 },
    { lat: 49.152759, lng: 19.972996 },
    { lat: 49.152259, lng: 19.972247 },
    { lat: 49.152045, lng: 19.971787 },
    { lat: 49.151921, lng: 19.971317 },
    { lat: 49.151126, lng: 19.971215 },
    { lat: 49.150723, lng: 19.970919 },
    { lat: 49.150512, lng: 19.971017 },
    { lat: 49.150493, lng: 19.970738 },
    { lat: 49.149977, lng: 19.970021 },
    { lat: 49.149823, lng: 19.97005 },
    { lat: 49.149627, lng: 19.970346 },
    { lat: 49.149494, lng: 19.97035 },
    { lat: 49.149439, lng: 19.97009 },
    { lat: 49.149252, lng: 19.970102 },
    { lat: 49.149167, lng: 19.969888 },
    { lat: 49.146932, lng: 19.964561 },
    { lat: 49.146865, lng: 19.964424 },
    { lat: 49.1455, lng: 19.961283 },
    { lat: 49.144904, lng: 19.960392 },
    { lat: 49.144731, lng: 19.960057 },
    { lat: 49.143893, lng: 19.957998 },
    { lat: 49.143763, lng: 19.957036 },
    { lat: 49.143794, lng: 19.956071 },
    { lat: 49.143785, lng: 19.955373 },
    { lat: 49.143861, lng: 19.954723 },
    { lat: 49.144384, lng: 19.953721 },
    { lat: 49.144567, lng: 19.953111 },
    { lat: 49.144512, lng: 19.951806 },
    { lat: 49.144431, lng: 19.951524 },
    { lat: 49.144008, lng: 19.950417 },
    { lat: 49.143594, lng: 19.94988 },
    { lat: 49.143524, lng: 19.949569 },
    { lat: 49.143224, lng: 19.948771 },
    { lat: 49.142767, lng: 19.946947 },
    { lat: 49.141889, lng: 19.94457 },
    { lat: 49.141812, lng: 19.94379 },
    { lat: 49.141636, lng: 19.943249 },
    { lat: 49.141622, lng: 19.943011 },
    { lat: 49.141561, lng: 19.942534 },
    { lat: 49.141472, lng: 19.941635 },
    { lat: 49.141413, lng: 19.941198 },
    { lat: 49.141327, lng: 19.940353 },
    { lat: 49.14148, lng: 19.939198 },
    { lat: 49.141675, lng: 19.938258 },
    { lat: 49.141677, lng: 19.937618 },
    { lat: 49.141786, lng: 19.937129 },
    { lat: 49.141941, lng: 19.936573 },
    { lat: 49.141945, lng: 19.936202 },
    { lat: 49.141956, lng: 19.935879 },
    { lat: 49.142162, lng: 19.935218 },
    { lat: 49.142141, lng: 19.93383 },
    { lat: 49.142125, lng: 19.932484 },
    { lat: 49.142169, lng: 19.93178 },
    { lat: 49.1422148, lng: 19.9310178 },
    { lat: 49.142235, lng: 19.930949 },
    { lat: 49.1421621, lng: 19.9306208 },
    { lat: 49.142044, lng: 19.930262 },
    { lat: 49.142003, lng: 19.929781 },
    { lat: 49.141965, lng: 19.9296 },
    { lat: 49.1420762, lng: 19.9288774 },
    { lat: 49.142123, lng: 19.928798 },
    { lat: 49.1421165, lng: 19.9277039 },
    { lat: 49.142085, lng: 19.927339 },
    { lat: 49.142034, lng: 19.927125 },
    { lat: 49.14194, lng: 19.926903 },
    { lat: 49.1418678, lng: 19.9267925 },
    { lat: 49.14181, lng: 19.926704 },
    { lat: 49.1415232, lng: 19.9263632 },
    { lat: 49.141163, lng: 19.925935 },
    { lat: 49.140806, lng: 19.925637 },
    { lat: 49.1407206, lng: 19.9255419 },
    { lat: 49.140639, lng: 19.925451 },
    { lat: 49.140328, lng: 19.924995 },
    { lat: 49.140268, lng: 19.924935 },
    { lat: 49.140196, lng: 19.924863 },
    { lat: 49.140115, lng: 19.924781 },
    { lat: 49.140027, lng: 19.92473 },
    { lat: 49.139828, lng: 19.924735 },
    { lat: 49.139717, lng: 19.924716 },
    { lat: 49.1396, lng: 19.924594 },
    { lat: 49.139263, lng: 19.923848 },
    { lat: 49.139109, lng: 19.923606 },
    { lat: 49.13896, lng: 19.923507 },
    { lat: 49.138759, lng: 19.923405 },
    { lat: 49.13862, lng: 19.923318 },
    { lat: 49.138398, lng: 19.923264 },
    { lat: 49.137946, lng: 19.923269 },
    { lat: 49.13625, lng: 19.922756 },
    { lat: 49.136041, lng: 19.922711 },
    { lat: 49.13543, lng: 19.922496 },
    { lat: 49.134883, lng: 19.921904 },
    { lat: 49.133971, lng: 19.920582 },
    { lat: 49.133676, lng: 19.919982 },
    { lat: 49.133024, lng: 19.919397 },
    { lat: 49.132681, lng: 19.9192 },
    { lat: 49.132569, lng: 19.91928 },
    { lat: 49.1326208, lng: 19.9201102 },
    { lat: 49.1324648, lng: 19.9218315 },
    { lat: 49.1326528, lng: 19.9233959 },
    { lat: 49.1323781, lng: 19.9246704 },
    { lat: 49.1317988, lng: 19.9251961 },
    { lat: 49.1315991, lng: 19.9254023 },
    { lat: 49.1312542, lng: 19.9257428 },
    { lat: 49.1307227, lng: 19.9260681 },
    { lat: 49.1306495, lng: 19.9260981 },
    { lat: 49.1303002, lng: 19.9263043 },
    { lat: 49.1298894, lng: 19.9265038 },
    { lat: 49.1295831, lng: 19.9265834 },
    { lat: 49.1289721, lng: 19.9268668 },
    { lat: 49.1285408, lng: 19.9270259 },
    { lat: 49.1280664, lng: 19.9272121 },
    { lat: 49.1280564, lng: 19.9272376 },
    { lat: 49.12796, lng: 19.9271733 },
    { lat: 49.1279329, lng: 19.9270637 },
    { lat: 49.1277237, lng: 19.9267475 },
    { lat: 49.1274875, lng: 19.9265534 },
    { lat: 49.1272895, lng: 19.9263907 },
    { lat: 49.1272708, lng: 19.9262922 },
    { lat: 49.1271809, lng: 19.9261658 },
    { lat: 49.1269633, lng: 19.9260263 },
    { lat: 49.126887, lng: 19.9258942 },
    { lat: 49.1267407, lng: 19.9258456 },
    { lat: 49.126691, lng: 19.9258986 },
    { lat: 49.1265492, lng: 19.9256855 },
    { lat: 49.1264297, lng: 19.9257062 },
    { lat: 49.1263419, lng: 19.9256288 },
    { lat: 49.1260493, lng: 19.9256632 },
    { lat: 49.1260084, lng: 19.9255503 },
    { lat: 49.1258038, lng: 19.9254513 },
    { lat: 49.1257211, lng: 19.9254921 },
    { lat: 49.125689, lng: 19.9254808 },
    { lat: 49.1256343, lng: 19.9253607 },
    { lat: 49.1255299, lng: 19.9253403 },
    { lat: 49.1254667, lng: 19.9253868 },
    { lat: 49.1254172, lng: 19.9255797 },
    { lat: 49.1252703, lng: 19.9254876 },
    { lat: 49.1251738, lng: 19.9252528 },
    { lat: 49.1250954, lng: 19.925263 },
    { lat: 49.1249932, lng: 19.9251389 },
    { lat: 49.1249892, lng: 19.9250724 },
    { lat: 49.1249283, lng: 19.9251229 },
    { lat: 49.1248821, lng: 19.9250958 },
    { lat: 49.1247787, lng: 19.9254504 },
    { lat: 49.1247286, lng: 19.925487 },
    { lat: 49.1247023, lng: 19.9254507 },
    { lat: 49.1246791, lng: 19.9253037 },
    { lat: 49.1246358, lng: 19.9251839 },
    { lat: 49.1246497, lng: 19.9250325 },
    { lat: 49.1245343, lng: 19.9249133 },
    { lat: 49.1244465, lng: 19.9250215 },
    { lat: 49.124401, lng: 19.9250105 },
    { lat: 49.1242123, lng: 19.9247283 },
    { lat: 49.124099, lng: 19.9248458 },
    { lat: 49.1240224, lng: 19.9250327 },
    { lat: 49.1239873, lng: 19.9250293 },
    { lat: 49.1239746, lng: 19.9249673 },
    { lat: 49.1239807, lng: 19.9245801 },
    { lat: 49.1239528, lng: 19.9245073 },
    { lat: 49.1238465, lng: 19.9244925 },
    { lat: 49.1237159, lng: 19.9245243 },
    { lat: 49.1236573, lng: 19.9243614 },
    { lat: 49.1235757, lng: 19.9242949 },
    { lat: 49.1234945, lng: 19.9243227 },
    { lat: 49.1234328, lng: 19.9245117 },
    { lat: 49.1233285, lng: 19.9244734 },
    { lat: 49.1233131, lng: 19.9244263 },
    { lat: 49.1233109, lng: 19.9240784 },
    { lat: 49.1232431, lng: 19.924075 },
    { lat: 49.1230788, lng: 19.924159 },
    { lat: 49.122972, lng: 19.9241335 },
    { lat: 49.1229289, lng: 19.9242881 },
    { lat: 49.1228663, lng: 19.9242755 },
    { lat: 49.1227498, lng: 19.924047 },
    { lat: 49.1225244, lng: 19.9239133 },
    { lat: 49.1225102, lng: 19.9238665 },
    { lat: 49.1225202, lng: 19.9237289 },
    { lat: 49.122482, lng: 19.9236528 },
    { lat: 49.1224864, lng: 19.9234931 },
    { lat: 49.122346, lng: 19.9234212 },
    { lat: 49.1221934, lng: 19.9231376 },
    { lat: 49.1220388, lng: 19.9229974 },
    { lat: 49.1219437, lng: 19.9226395 },
    { lat: 49.1218282, lng: 19.9226267 },
    { lat: 49.1217334, lng: 19.9225155 },
    { lat: 49.1216924, lng: 19.9223455 },
    { lat: 49.1214228, lng: 19.9222255 },
    { lat: 49.1212687, lng: 19.9222589 },
    { lat: 49.1211874, lng: 19.922145 },
    { lat: 49.1211103, lng: 19.9221367 },
    { lat: 49.1210792, lng: 19.9221207 },
    { lat: 49.1210247, lng: 19.9219465 },
    { lat: 49.1209221, lng: 19.921866 },
    { lat: 49.12084, lng: 19.9217873 },
    { lat: 49.1208312, lng: 19.9217137 },
    { lat: 49.1208948, lng: 19.9214019 },
    { lat: 49.1207255, lng: 19.9214227 },
    { lat: 49.1206385, lng: 19.9212295 },
    { lat: 49.1203962, lng: 19.921041 },
    { lat: 49.120355, lng: 19.9208649 },
    { lat: 49.1202838, lng: 19.920727 },
    { lat: 49.1201113, lng: 19.9206378 },
    { lat: 49.1200063, lng: 19.9204474 },
    { lat: 49.119909, lng: 19.9203562 },
    { lat: 49.1198437, lng: 19.92009 },
    { lat: 49.119646, lng: 19.9198343 },
    { lat: 49.1196305, lng: 19.9196581 },
    { lat: 49.1195993, lng: 19.9196119 },
    { lat: 49.1195877, lng: 19.9194433 },
    { lat: 49.1195291, lng: 19.9194135 },
    { lat: 49.1193782, lng: 19.9194682 },
    { lat: 49.1193464, lng: 19.9194623 },
    { lat: 49.1191723, lng: 19.9192571 },
    { lat: 49.1190025, lng: 19.9189486 },
    { lat: 49.118945, lng: 19.9189172 },
    { lat: 49.1187572, lng: 19.9186548 },
    { lat: 49.1184358, lng: 19.9183831 },
    { lat: 49.118121, lng: 19.917941 },
    { lat: 49.1178861, lng: 19.9178727 },
    { lat: 49.1176809, lng: 19.917923 },
    { lat: 49.1174264, lng: 19.9177641 },
    { lat: 49.1172371, lng: 19.9176295 },
    { lat: 49.1170409, lng: 19.9177296 },
    { lat: 49.1166623, lng: 19.91778 },
    { lat: 49.1165511, lng: 19.9176833 },
    { lat: 49.1163668, lng: 19.9174486 },
    { lat: 49.1161712, lng: 19.9172707 },
    { lat: 49.1160857, lng: 19.9172766 },
    { lat: 49.1160763, lng: 19.9173994 },
    { lat: 49.1159516, lng: 19.9174871 },
    { lat: 49.1159177, lng: 19.9174836 },
    { lat: 49.1158593, lng: 19.9172746 },
    { lat: 49.1158775, lng: 19.9170895 },
    { lat: 49.1157747, lng: 19.917088 },
    { lat: 49.1155914, lng: 19.916933 },
    { lat: 49.1155024, lng: 19.9167418 },
    { lat: 49.1151099, lng: 19.9170014 },
    { lat: 49.1148949, lng: 19.9171634 },
    { lat: 49.1147932, lng: 19.9171291 },
    { lat: 49.1147192, lng: 19.9169763 },
    { lat: 49.1147361, lng: 19.9166547 },
    { lat: 49.1146667, lng: 19.9165604 },
    { lat: 49.1144888, lng: 19.9165606 },
    { lat: 49.1144598, lng: 19.9162481 },
    { lat: 49.1143863, lng: 19.9161338 },
    { lat: 49.1143177, lng: 19.9161365 },
    { lat: 49.1142753, lng: 19.9163348 },
    { lat: 49.1141566, lng: 19.9164295 },
    { lat: 49.1139437, lng: 19.9164795 },
    { lat: 49.1138459, lng: 19.9164333 },
    { lat: 49.1138125, lng: 19.9163906 },
    { lat: 49.1137401, lng: 19.9161683 },
    { lat: 49.1135266, lng: 19.9162888 },
    { lat: 49.1133676, lng: 19.9163356 },
    { lat: 49.1132458, lng: 19.9162515 },
    { lat: 49.1129684, lng: 19.9161097 },
    { lat: 49.1128743, lng: 19.9158543 },
    { lat: 49.1127715, lng: 19.9157407 },
    { lat: 49.1126055, lng: 19.9156764 },
    { lat: 49.1124778, lng: 19.915933 },
    { lat: 49.112361, lng: 19.9159179 },
    { lat: 49.1122405, lng: 19.9157869 },
    { lat: 49.112155, lng: 19.916025 },
    { lat: 49.1119213, lng: 19.9161195 },
    { lat: 49.1118089, lng: 19.9160751 },
    { lat: 49.1117978, lng: 19.9158403 },
    { lat: 49.1118, lng: 19.915678 },
    { lat: 49.1117254, lng: 19.9156162 },
    { lat: 49.1116682, lng: 19.9154352 },
    { lat: 49.1116055, lng: 19.9152861 },
    { lat: 49.111441, lng: 19.915126 },
    { lat: 49.1111878, lng: 19.9154219 },
    { lat: 49.1111533, lng: 19.9154328 },
    { lat: 49.1110663, lng: 19.9153453 },
    { lat: 49.1109372, lng: 19.9151167 },
    { lat: 49.1108668, lng: 19.9151542 },
    { lat: 49.1107094, lng: 19.9152954 },
    { lat: 49.1105916, lng: 19.9152275 },
    { lat: 49.1105375, lng: 19.9150926 },
    { lat: 49.1104239, lng: 19.9149887 },
    { lat: 49.1103094, lng: 19.9147317 },
    { lat: 49.1101766, lng: 19.9145991 },
    { lat: 49.1100027, lng: 19.914725 },
    { lat: 49.1099089, lng: 19.9147446 },
    { lat: 49.1096688, lng: 19.9145521 },
    { lat: 49.1095519, lng: 19.9145855 },
    { lat: 49.1094394, lng: 19.9146713 },
    { lat: 49.1093326, lng: 19.914567 },
    { lat: 49.1092751, lng: 19.9142441 },
    { lat: 49.1090617, lng: 19.914206 },
    { lat: 49.1090067, lng: 19.9142887 },
    { lat: 49.1089108, lng: 19.9143941 },
    { lat: 49.1087044, lng: 19.9143622 },
    { lat: 49.1085064, lng: 19.9142359 },
    { lat: 49.1084239, lng: 19.9140639 },
    { lat: 49.1082765, lng: 19.9138324 },
    { lat: 49.1080308, lng: 19.9137702 },
    { lat: 49.1080005, lng: 19.9136172 },
    { lat: 49.1079323, lng: 19.9135728 },
    { lat: 49.1078344, lng: 19.9136805 },
    { lat: 49.1077193, lng: 19.9139733 },
    { lat: 49.1075654, lng: 19.9140347 },
    { lat: 49.1074106, lng: 19.9141811 },
    { lat: 49.107266, lng: 19.914278 },
    { lat: 49.1071221, lng: 19.9142363 },
    { lat: 49.1069781, lng: 19.9141077 },
    { lat: 49.1067853, lng: 19.9143934 },
    { lat: 49.1067163, lng: 19.9143843 },
    { lat: 49.1063565, lng: 19.9142941 },
    { lat: 49.1060704, lng: 19.9143997 },
    { lat: 49.1059986, lng: 19.9143802 },
    { lat: 49.105903, lng: 19.9144363 },
    { lat: 49.1058477, lng: 19.9144363 },
    { lat: 49.1057804, lng: 19.9143908 },
    { lat: 49.1056941, lng: 19.9144247 },
    { lat: 49.1056777, lng: 19.9145468 },
    { lat: 49.1056297, lng: 19.9145842 },
    { lat: 49.1055118, lng: 19.9145353 },
    { lat: 49.1054794, lng: 19.9144612 },
    { lat: 49.1054656, lng: 19.9142965 },
    { lat: 49.1054091, lng: 19.9140249 },
    { lat: 49.1051933, lng: 19.9138235 },
    { lat: 49.1051007, lng: 19.9136815 },
    { lat: 49.1050162, lng: 19.9136319 },
    { lat: 49.1049987, lng: 19.913776 },
    { lat: 49.1049732, lng: 19.9138094 },
    { lat: 49.1049237, lng: 19.9138376 },
    { lat: 49.1048114, lng: 19.913661 },
    { lat: 49.1047203, lng: 19.9136397 },
    { lat: 49.1046849, lng: 19.9137348 },
    { lat: 49.1047379, lng: 19.9138163 },
    { lat: 49.1047385, lng: 19.9138802 },
    { lat: 49.1046675, lng: 19.9139364 },
    { lat: 49.104638, lng: 19.9140378 },
    { lat: 49.1045908, lng: 19.9140385 },
    { lat: 49.1044595, lng: 19.9137839 },
    { lat: 49.1042827, lng: 19.9135992 },
    { lat: 49.1041561, lng: 19.9136137 },
    { lat: 49.1041578, lng: 19.9138109 },
    { lat: 49.1040249, lng: 19.9137337 },
    { lat: 49.1040376, lng: 19.9134751 },
    { lat: 49.1040032, lng: 19.913197 },
    { lat: 49.1040432, lng: 19.9130751 },
    { lat: 49.1040189, lng: 19.9129258 },
    { lat: 49.103626, lng: 19.91285 },
    { lat: 49.1033835, lng: 19.912658 },
    { lat: 49.1032676, lng: 19.9126565 },
    { lat: 49.1031862, lng: 19.9127306 },
    { lat: 49.1030722, lng: 19.9126979 },
    { lat: 49.1029874, lng: 19.912584 },
    { lat: 49.1029993, lng: 19.9123847 },
    { lat: 49.1029467, lng: 19.9123396 },
    { lat: 49.1027456, lng: 19.9123506 },
    { lat: 49.1026444, lng: 19.9122523 },
    { lat: 49.1026685, lng: 19.9121515 },
    { lat: 49.1026637, lng: 19.9120675 },
    { lat: 49.102492, lng: 19.9118679 },
    { lat: 49.10236, lng: 19.9118898 },
    { lat: 49.1021967, lng: 19.9117336 },
    { lat: 49.1022187, lng: 19.9115855 },
    { lat: 49.1023731, lng: 19.911506 },
    { lat: 49.1024791, lng: 19.9113238 },
    { lat: 49.1024415, lng: 19.9112315 },
    { lat: 49.1023627, lng: 19.9111835 },
    { lat: 49.1022184, lng: 19.9109917 },
    { lat: 49.102004, lng: 19.9106378 },
    { lat: 49.1018386, lng: 19.9105587 },
    { lat: 49.1016897, lng: 19.9104486 },
    { lat: 49.1016018, lng: 19.9102908 },
    { lat: 49.1014652, lng: 19.9103581 },
    { lat: 49.1013082, lng: 19.9103515 },
    { lat: 49.1011977, lng: 19.9104015 },
    { lat: 49.1010016, lng: 19.9103697 },
    { lat: 49.1009302, lng: 19.9102815 },
    { lat: 49.1009592, lng: 19.910087 },
    { lat: 49.1008265, lng: 19.909985 },
    { lat: 49.1007565, lng: 19.9098747 },
    { lat: 49.1007017, lng: 19.9098861 },
    { lat: 49.1005637, lng: 19.9097358 },
    { lat: 49.1004103, lng: 19.9095193 },
    { lat: 49.100319, lng: 19.9093025 },
    { lat: 49.1002181, lng: 19.9088634 },
    { lat: 49.1001262, lng: 19.9087394 },
    { lat: 49.0995703, lng: 19.9085653 },
    { lat: 49.0991427, lng: 19.9078574 },
    { lat: 49.0989518, lng: 19.9077367 },
    { lat: 49.0986463, lng: 19.9076512 },
    { lat: 49.0985115, lng: 19.9075002 },
    { lat: 49.0977658, lng: 19.906913 },
    { lat: 49.0975133, lng: 19.9068846 },
    { lat: 49.0971903, lng: 19.9061194 },
    { lat: 49.0970884, lng: 19.9063422 },
    { lat: 49.0968476, lng: 19.9059794 },
    { lat: 49.0964153, lng: 19.9059308 },
    { lat: 49.0964215, lng: 19.9057836 },
    { lat: 49.0960908, lng: 19.9052905 },
    { lat: 49.0961274, lng: 19.9050059 },
    { lat: 49.0959947, lng: 19.9046159 },
    { lat: 49.0958898, lng: 19.9043951 },
    { lat: 49.0960464, lng: 19.9041867 },
    { lat: 49.0960093, lng: 19.9040294 },
    { lat: 49.0958555, lng: 19.903428 },
    { lat: 49.0957496, lng: 19.9032404 },
    { lat: 49.0957204, lng: 19.9030592 },
    { lat: 49.0956363, lng: 19.9028284 },
    { lat: 49.0955444, lng: 19.9027027 },
    { lat: 49.09537, lng: 19.9026037 },
    { lat: 49.0951997, lng: 19.9027566 },
    { lat: 49.095093, lng: 19.9028188 },
    { lat: 49.0948041, lng: 19.9026948 },
    { lat: 49.0944341, lng: 19.9026596 },
    { lat: 49.0940767, lng: 19.9024618 },
    { lat: 49.0939337, lng: 19.9022512 },
    { lat: 49.0938215, lng: 19.901998 },
    { lat: 49.0937534, lng: 19.9018002 },
    { lat: 49.0934281, lng: 19.9018824 },
    { lat: 49.0932767, lng: 19.9018463 },
    { lat: 49.0928871, lng: 19.9016387 },
    { lat: 49.0925767, lng: 19.9012749 },
    { lat: 49.0923106, lng: 19.9008931 },
    { lat: 49.0922771, lng: 19.9005826 },
    { lat: 49.0921531, lng: 19.9002073 },
    { lat: 49.0920643, lng: 19.9001061 },
    { lat: 49.0915207, lng: 19.8999043 },
    { lat: 49.0914047, lng: 19.8997737 },
    { lat: 49.0912774, lng: 19.8993995 },
    { lat: 49.0912263, lng: 19.8991515 },
    { lat: 49.0911661, lng: 19.8990061 },
    { lat: 49.0910462, lng: 19.8989158 },
    { lat: 49.0906281, lng: 19.8988594 },
    { lat: 49.0903689, lng: 19.8989083 },
    { lat: 49.0901386, lng: 19.8986822 },
    { lat: 49.0897947, lng: 19.8985151 },
    { lat: 49.089641, lng: 19.898234 },
    { lat: 49.0894875, lng: 19.8980162 },
    { lat: 49.0893555, lng: 19.8979853 },
    { lat: 49.0889596, lng: 19.8980785 },
    { lat: 49.0884615, lng: 19.8977562 },
    { lat: 49.088408, lng: 19.8976055 },
    { lat: 49.0883038, lng: 19.8975661 },
    { lat: 49.0880644, lng: 19.8977133 },
    { lat: 49.087812, lng: 19.8974452 },
    { lat: 49.0872665, lng: 19.8974377 },
    { lat: 49.0868365, lng: 19.897495 },
    { lat: 49.0867599, lng: 19.8976556 },
    { lat: 49.0865301, lng: 19.8976297 },
    { lat: 49.086279, lng: 19.8974732 },
    { lat: 49.0860027, lng: 19.8973325 },
    { lat: 49.0858884, lng: 19.8971084 },
    { lat: 49.0856604, lng: 19.8969633 },
    { lat: 49.0855569, lng: 19.8969368 },
    { lat: 49.0852221, lng: 19.8965841 },
    { lat: 49.0850956, lng: 19.8965789 },
    { lat: 49.0847495, lng: 19.896361 },
    { lat: 49.0844543, lng: 19.8960903 },
    { lat: 49.0844029, lng: 19.8957012 },
    { lat: 49.0842783, lng: 19.8955783 },
    { lat: 49.0842431, lng: 19.8954671 },
    { lat: 49.0838776, lng: 19.8950794 },
    { lat: 49.0837989, lng: 19.8948992 },
    { lat: 49.0836629, lng: 19.8948277 },
    { lat: 49.0835821, lng: 19.8944637 },
    { lat: 49.08347, lng: 19.8943971 },
    { lat: 49.0834145, lng: 19.8942578 },
    { lat: 49.083348, lng: 19.8942022 },
    { lat: 49.0832734, lng: 19.8942465 },
    { lat: 49.083109, lng: 19.8941714 },
    { lat: 49.083067, lng: 19.8940821 },
    { lat: 49.0830891, lng: 19.8938855 },
    { lat: 49.0831367, lng: 19.8939168 },
    { lat: 49.0832033, lng: 19.8939249 },
    { lat: 49.0831878, lng: 19.8937975 },
    { lat: 49.0830895, lng: 19.8937123 },
    { lat: 49.0828918, lng: 19.8936685 },
    { lat: 49.0826297, lng: 19.8932781 },
    { lat: 49.0825865, lng: 19.893061 },
    { lat: 49.0824436, lng: 19.8928476 },
    { lat: 49.0823136, lng: 19.8928924 },
    { lat: 49.0822054, lng: 19.892862 },
    { lat: 49.0821328, lng: 19.8929597 },
    { lat: 49.0818645, lng: 19.8928442 },
    { lat: 49.0819143, lng: 19.8925597 },
    { lat: 49.0819021, lng: 19.8923 },
    { lat: 49.0817983, lng: 19.8920795 },
    { lat: 49.0816524, lng: 19.8921241 },
    { lat: 49.0816698, lng: 19.8922395 },
    { lat: 49.0815668, lng: 19.8924641 },
    { lat: 49.0813092, lng: 19.8925574 },
    { lat: 49.0812962, lng: 19.8924067 },
    { lat: 49.0812712, lng: 19.892352 },
    { lat: 49.0810814, lng: 19.8924964 },
    { lat: 49.080951, lng: 19.8925039 },
    { lat: 49.0808376, lng: 19.8924007 },
    { lat: 49.0808229, lng: 19.8923221 },
    { lat: 49.0806367, lng: 19.8923181 },
    { lat: 49.080514, lng: 19.8924523 },
    { lat: 49.0803749, lng: 19.8924613 },
    { lat: 49.0802097, lng: 19.8923669 },
    { lat: 49.0801254, lng: 19.8919429 },
    { lat: 49.0800291, lng: 19.8917713 },
    { lat: 49.0799545, lng: 19.8914757 },
    { lat: 49.0798344, lng: 19.8915386 },
    { lat: 49.0798064, lng: 19.8915133 },
    { lat: 49.0797581, lng: 19.8911989 },
    { lat: 49.0794764, lng: 19.8913018 },
    { lat: 49.0794077, lng: 19.8911439 },
    { lat: 49.0792802, lng: 19.8910041 },
    { lat: 49.0792914, lng: 19.8908183 },
    { lat: 49.0792853, lng: 19.8907571 },
    { lat: 49.0793034, lng: 19.8906512 },
    { lat: 49.0791952, lng: 19.8905989 },
    { lat: 49.0791182, lng: 19.8906694 },
    { lat: 49.0790763, lng: 19.890528 },
    { lat: 49.078957, lng: 19.8905314 },
    { lat: 49.0789435, lng: 19.8904508 },
    { lat: 49.0789482, lng: 19.8903239 },
    { lat: 49.0788475, lng: 19.8902871 },
    { lat: 49.078842, lng: 19.8904515 },
    { lat: 49.0788468, lng: 19.8906921 },
    { lat: 49.0787472, lng: 19.8907648 },
    { lat: 49.0785912, lng: 19.8905387 },
    { lat: 49.0784368, lng: 19.8905646 },
    { lat: 49.0782765, lng: 19.8906077 },
    { lat: 49.0780357, lng: 19.8902678 },
    { lat: 49.0780423, lng: 19.8900792 },
    { lat: 49.0780773, lng: 19.8900812 },
    { lat: 49.0782032, lng: 19.890182 },
    { lat: 49.0781803, lng: 19.8899338 },
    { lat: 49.078012, lng: 19.8898425 },
    { lat: 49.0778613, lng: 19.8896917 },
    { lat: 49.0778625, lng: 19.8895306 },
    { lat: 49.0779545, lng: 19.8894181 },
    { lat: 49.0778721, lng: 19.8893058 },
    { lat: 49.0777383, lng: 19.8893118 },
    { lat: 49.0776879, lng: 19.8889981 },
    { lat: 49.0775655, lng: 19.8890902 },
    { lat: 49.077511, lng: 19.8889447 },
    { lat: 49.0775896, lng: 19.8886506 },
    { lat: 49.077325, lng: 19.8883626 },
    { lat: 49.0771041, lng: 19.8885956 },
    { lat: 49.0767908, lng: 19.8882725 },
    { lat: 49.0767586, lng: 19.8881539 },
    { lat: 49.0767815, lng: 19.8880259 },
    { lat: 49.0768969, lng: 19.8880415 },
    { lat: 49.0769146, lng: 19.8878737 },
    { lat: 49.0767524, lng: 19.8876633 },
    { lat: 49.0766087, lng: 19.8875693 },
    { lat: 49.0766255, lng: 19.8873521 },
    { lat: 49.0767563, lng: 19.8873033 },
    { lat: 49.0767811, lng: 19.8874104 },
    { lat: 49.0768204, lng: 19.8873505 },
    { lat: 49.0766568, lng: 19.8867888 },
    { lat: 49.0767663, lng: 19.8866879 },
    { lat: 49.0768535, lng: 19.886698 },
    { lat: 49.0768404, lng: 19.8865785 },
    { lat: 49.0767194, lng: 19.8864564 },
    { lat: 49.0766819, lng: 19.8862126 },
    { lat: 49.0768224, lng: 19.8859433 },
    { lat: 49.0768154, lng: 19.8858843 },
    { lat: 49.0766697, lng: 19.8857927 },
    { lat: 49.0766698, lng: 19.8856342 },
    { lat: 49.0766012, lng: 19.885402 },
    { lat: 49.0767242, lng: 19.885299 },
    { lat: 49.0766584, lng: 19.8850224 },
    { lat: 49.0764734, lng: 19.8852849 },
    { lat: 49.0763644, lng: 19.885264 },
    { lat: 49.0762778, lng: 19.8852909 },
    { lat: 49.0757907, lng: 19.8846702 },
    { lat: 49.0757838, lng: 19.8844316 },
    { lat: 49.0756377, lng: 19.8843813 },
    { lat: 49.0755862, lng: 19.8844686 },
    { lat: 49.0755404, lng: 19.8848256 },
    { lat: 49.0754174, lng: 19.8847903 },
    { lat: 49.0751957, lng: 19.8846106 },
    { lat: 49.0751993, lng: 19.8849073 },
    { lat: 49.0751209, lng: 19.8850796 },
    { lat: 49.0749767, lng: 19.8851301 },
    { lat: 49.0747525, lng: 19.8846517 },
    { lat: 49.0747543, lng: 19.8845107 },
    { lat: 49.0746239, lng: 19.8845699 },
    { lat: 49.0745562, lng: 19.8845147 },
    { lat: 49.0744508, lng: 19.8847123 },
    { lat: 49.074374, lng: 19.8845991 },
    { lat: 49.0744281, lng: 19.8844663 },
    { lat: 49.0744003, lng: 19.8844234 },
    { lat: 49.0743426, lng: 19.8844434 },
    { lat: 49.0742511, lng: 19.8848344 },
    { lat: 49.0740799, lng: 19.8852328 },
    { lat: 49.0739448, lng: 19.8851541 },
    { lat: 49.07383, lng: 19.8853943 },
    { lat: 49.0738171, lng: 19.8855701 },
    { lat: 49.0737006, lng: 19.8855604 },
    { lat: 49.0735791, lng: 19.8854567 },
    { lat: 49.0734077, lng: 19.8857459 },
    { lat: 49.073295, lng: 19.8856366 },
    { lat: 49.0731614, lng: 19.8854378 },
    { lat: 49.0731777, lng: 19.8852372 },
    { lat: 49.0728747, lng: 19.8852339 },
    { lat: 49.0727808, lng: 19.8851444 },
    { lat: 49.0727716, lng: 19.8847677 },
    { lat: 49.0726618, lng: 19.8847347 },
    { lat: 49.0726982, lng: 19.8849517 },
    { lat: 49.0726256, lng: 19.8850196 },
    { lat: 49.0725423, lng: 19.8849652 },
    { lat: 49.0724951, lng: 19.8847604 },
    { lat: 49.0723412, lng: 19.8846603 },
    { lat: 49.0723856, lng: 19.8848614 },
    { lat: 49.0722992, lng: 19.8849709 },
    { lat: 49.0722233, lng: 19.8848842 },
    { lat: 49.0721438, lng: 19.8846808 },
    { lat: 49.0718617, lng: 19.884724 },
    { lat: 49.0718446, lng: 19.8844541 },
    { lat: 49.0717136, lng: 19.884472 },
    { lat: 49.0714399, lng: 19.8844742 },
    { lat: 49.0714092, lng: 19.8843349 },
    { lat: 49.0715849, lng: 19.8840658 },
    { lat: 49.0713411, lng: 19.8839001 },
    { lat: 49.0712992, lng: 19.8839463 },
    { lat: 49.0712914, lng: 19.8841069 },
    { lat: 49.0712339, lng: 19.8841309 },
    { lat: 49.0710571, lng: 19.8839705 },
    { lat: 49.0710579, lng: 19.8839675 },
    { lat: 49.0711074, lng: 19.8835161 },
    { lat: 49.0709082, lng: 19.8833323 },
    { lat: 49.0707628, lng: 19.8834103 },
    { lat: 49.0706345, lng: 19.8832253 },
    { lat: 49.0705909, lng: 19.8831254 },
    { lat: 49.0704996, lng: 19.8830722 },
    { lat: 49.0705058, lng: 19.882873 },
    { lat: 49.0704787, lng: 19.8828465 },
    { lat: 49.0703409, lng: 19.882919 },
    { lat: 49.0702541, lng: 19.8826775 },
    { lat: 49.0702327, lng: 19.8824397 },
    { lat: 49.0701797, lng: 19.8823324 },
    { lat: 49.0701416, lng: 19.8823351 },
    { lat: 49.0699972, lng: 19.8824912 },
    { lat: 49.0698626, lng: 19.8820802 },
    { lat: 49.0698719, lng: 19.8817441 },
    { lat: 49.0698605, lng: 19.8815541 },
    { lat: 49.0697111, lng: 19.8815088 },
    { lat: 49.0695724, lng: 19.8818282 },
    { lat: 49.0692935, lng: 19.881864 },
    { lat: 49.0691924, lng: 19.8816835 },
    { lat: 49.0691153, lng: 19.8818858 },
    { lat: 49.0689387, lng: 19.881941 },
    { lat: 49.0686838, lng: 19.8817749 },
    { lat: 49.0686821, lng: 19.8815222 },
    { lat: 49.0686177, lng: 19.8812027 },
    { lat: 49.0687523, lng: 19.8810806 },
    { lat: 49.0687083, lng: 19.8809452 },
    { lat: 49.0686564, lng: 19.880891 },
    { lat: 49.0685873, lng: 19.8809647 },
    { lat: 49.0684391, lng: 19.8808718 },
    { lat: 49.0684314, lng: 19.8811392 },
    { lat: 49.0682682, lng: 19.8812203 },
    { lat: 49.0681493, lng: 19.8812331 },
    { lat: 49.0679379, lng: 19.8808702 },
    { lat: 49.0677928, lng: 19.8807706 },
    { lat: 49.0678174, lng: 19.8804223 },
    { lat: 49.0677274, lng: 19.8806589 },
    { lat: 49.0676677, lng: 19.8806871 },
    { lat: 49.0676024, lng: 19.880613 },
    { lat: 49.0674227, lng: 19.8800951 },
    { lat: 49.0674949, lng: 19.8800165 },
    { lat: 49.0676728, lng: 19.8800651 },
    { lat: 49.0676524, lng: 19.8797168 },
    { lat: 49.0675045, lng: 19.879975 },
    { lat: 49.0674219, lng: 19.8799709 },
    { lat: 49.0673346, lng: 19.8797708 },
    { lat: 49.0672122, lng: 19.8799132 },
    { lat: 49.0671067, lng: 19.8794412 },
    { lat: 49.0672882, lng: 19.8793127 },
    { lat: 49.0672644, lng: 19.8789917 },
    { lat: 49.0674454, lng: 19.8788797 },
    { lat: 49.067457, lng: 19.8787546 },
    { lat: 49.0672996, lng: 19.8786038 },
    { lat: 49.0674244, lng: 19.878464 },
    { lat: 49.067427, lng: 19.878365 },
    { lat: 49.067343, lng: 19.8782202 },
    { lat: 49.06705, lng: 19.878086 },
    { lat: 49.0669788, lng: 19.8778383 },
    { lat: 49.0669631, lng: 19.8775765 },
    { lat: 49.066964, lng: 19.8773085 },
    { lat: 49.0671775, lng: 19.8770288 },
    { lat: 49.0671686, lng: 19.8768195 },
    { lat: 49.0669114, lng: 19.876787 },
    { lat: 49.0669332, lng: 19.8762399 },
    { lat: 49.0668868, lng: 19.8759435 },
    { lat: 49.0669287, lng: 19.8757094 },
    { lat: 49.0668348, lng: 19.8753824 },
    { lat: 49.0668771, lng: 19.8749741 },
    { lat: 49.0669053, lng: 19.8747662 },
    { lat: 49.0667969, lng: 19.8747579 },
    { lat: 49.0665164, lng: 19.8748128 },
    { lat: 49.0663991, lng: 19.8748614 },
    { lat: 49.0662613, lng: 19.8746411 },
    { lat: 49.066485, lng: 19.874447 },
    { lat: 49.066484, lng: 19.8743185 },
    { lat: 49.0663617, lng: 19.8739832 },
    { lat: 49.0663988, lng: 19.8737403 },
    { lat: 49.0666221, lng: 19.8736683 },
    { lat: 49.0667146, lng: 19.8737508 },
    { lat: 49.0667569, lng: 19.8732099 },
    { lat: 49.0667124, lng: 19.8729582 },
    { lat: 49.0667763, lng: 19.8726634 },
    { lat: 49.0668832, lng: 19.8727405 },
    { lat: 49.0669256, lng: 19.8725441 },
    { lat: 49.0668826, lng: 19.8724086 },
    { lat: 49.0668322, lng: 19.8724115 },
    { lat: 49.0667565, lng: 19.87212 },
    { lat: 49.0665892, lng: 19.8720503 },
    { lat: 49.0665688, lng: 19.8719133 },
    { lat: 49.0663409, lng: 19.8718848 },
    { lat: 49.0662246, lng: 19.8716646 },
    { lat: 49.0661048, lng: 19.8717062 },
    { lat: 49.065941, lng: 19.8716657 },
    { lat: 49.0659422, lng: 19.8715132 },
    { lat: 49.0660888, lng: 19.8713073 },
    { lat: 49.0660217, lng: 19.871001 },
    { lat: 49.0659237, lng: 19.8709173 },
    { lat: 49.0659009, lng: 19.8701731 },
    { lat: 49.0657399, lng: 19.8699878 },
    { lat: 49.0656461, lng: 19.8698257 },
    { lat: 49.0658221, lng: 19.8694085 },
    { lat: 49.0655257, lng: 19.8692456 },
    { lat: 49.0653424, lng: 19.8689372 },
    { lat: 49.0647567, lng: 19.8695446 },
    { lat: 49.0643605, lng: 19.8700277 },
    { lat: 49.0638407, lng: 19.8705701 },
    { lat: 49.0635788, lng: 19.8709559 },
    { lat: 49.0632279, lng: 19.8712294 },
    { lat: 49.0628931, lng: 19.8717811 },
    { lat: 49.0624719, lng: 19.8721557 },
    { lat: 49.06246, lng: 19.8720301 },
    { lat: 49.0608407, lng: 19.8675789 },
    { lat: 49.0604932, lng: 19.8671179 },
    { lat: 49.0595294, lng: 19.8658353 },
    { lat: 49.0588482, lng: 19.8661059 },
    { lat: 49.0582806, lng: 19.8663175 },
    { lat: 49.0572727, lng: 19.8660583 },
    { lat: 49.0572427, lng: 19.8660684 },
    { lat: 49.0571762, lng: 19.86607 },
    { lat: 49.0571342, lng: 19.8660594 },
    { lat: 49.0560543, lng: 19.8662552 },
    { lat: 49.0560168, lng: 19.8658243 },
    { lat: 49.0540941, lng: 19.8665677 },
    { lat: 49.0539433, lng: 19.8665942 },
    { lat: 49.0538268, lng: 19.8666146 },
    { lat: 49.0535673, lng: 19.8667077 },
    { lat: 49.0535201, lng: 19.8667903 },
    { lat: 49.0534028, lng: 19.8669172 },
    { lat: 49.05128, lng: 19.8692292 },
    { lat: 49.0489698, lng: 19.8705801 },
    { lat: 49.0495892, lng: 19.8728726 },
    { lat: 49.0496313, lng: 19.8729906 },
    { lat: 49.0496315, lng: 19.8730247 },
    { lat: 49.0492133, lng: 19.8730142 },
    { lat: 49.0482164, lng: 19.873096 },
    { lat: 49.0479868, lng: 19.8735514 },
    { lat: 49.0476345, lng: 19.8740569 },
    { lat: 49.0475778, lng: 19.8742611 },
    { lat: 49.0474913, lng: 19.8742657 },
    { lat: 49.0471206, lng: 19.8745773 },
    { lat: 49.0468215, lng: 19.8747267 },
    { lat: 49.0466364, lng: 19.8747144 },
    { lat: 49.0463327, lng: 19.8744334 },
    { lat: 49.0459799, lng: 19.8741371 },
    { lat: 49.0457586, lng: 19.8740462 },
    { lat: 49.0456797, lng: 19.8742059 },
    { lat: 49.0456471, lng: 19.8747086 },
    { lat: 49.0457387, lng: 19.8750346 },
    { lat: 49.0460286, lng: 19.8757073 },
    { lat: 49.0464351, lng: 19.8763128 },
    { lat: 49.0457051, lng: 19.877183 },
    { lat: 49.0456052, lng: 19.8772938 },
    { lat: 49.0448645, lng: 19.8781801 },
    { lat: 49.0448218, lng: 19.8782359 },
    { lat: 49.0447641, lng: 19.8783113 },
    { lat: 49.044735, lng: 19.8783372 },
    { lat: 49.0443753, lng: 19.8787277 },
    { lat: 49.0444761, lng: 19.8792984 },
    { lat: 49.0446091, lng: 19.8797984 },
    { lat: 49.0447725, lng: 19.880438 },
    { lat: 49.0448404, lng: 19.8813175 },
    { lat: 49.0448601, lng: 19.8818879 },
    { lat: 49.0450567, lng: 19.882753 },
    { lat: 49.0439487, lng: 19.883206 },
    { lat: 49.0438692, lng: 19.8832469 },
    { lat: 49.0436872, lng: 19.8833621 },
    { lat: 49.0431327, lng: 19.8837287 },
    { lat: 49.0429444, lng: 19.8839357 },
    { lat: 49.0428632, lng: 19.8840687 },
    { lat: 49.0424376, lng: 19.8847339 },
    { lat: 49.0420062, lng: 19.8845742 },
    { lat: 49.041309, lng: 19.8845767 },
    { lat: 49.0412778, lng: 19.8845823 },
    { lat: 49.0402637, lng: 19.8844958 },
    { lat: 49.0387672, lng: 19.8844588 },
    { lat: 49.0384249, lng: 19.884513 },
    { lat: 49.038278, lng: 19.8844215 },
    { lat: 49.0377829, lng: 19.8835324 },
    { lat: 49.0374766, lng: 19.8830617 },
    { lat: 49.0369361, lng: 19.8827477 },
    { lat: 49.0363742, lng: 19.8830428 },
    { lat: 49.0359701, lng: 19.8833647 },
    { lat: 49.035713, lng: 19.8837867 },
    { lat: 49.0350646, lng: 19.8845635 },
    { lat: 49.0345736, lng: 19.885039 },
    { lat: 49.034411, lng: 19.8833103 },
    { lat: 49.0340495, lng: 19.8829409 },
    { lat: 49.0334786, lng: 19.8824166 },
    { lat: 49.0329323, lng: 19.8822858 },
    { lat: 49.032524, lng: 19.8824872 },
    { lat: 49.0322706, lng: 19.8831501 },
    { lat: 49.0320404, lng: 19.8833267 },
    { lat: 49.031229, lng: 19.8841381 },
    { lat: 49.0310516, lng: 19.8846022 },
    { lat: 49.0310891, lng: 19.8852719 },
    { lat: 49.0310159, lng: 19.8863323 },
    { lat: 49.030765, lng: 19.8865771 },
    { lat: 49.0307018, lng: 19.8866016 },
    { lat: 49.0307382, lng: 19.8865301 },
    { lat: 49.0307389, lng: 19.8861751 },
    { lat: 49.0307094, lng: 19.886197 },
    { lat: 49.0306778, lng: 19.8861412 },
    { lat: 49.0306905, lng: 19.8861235 },
    { lat: 49.0301503, lng: 19.8854999 },
    { lat: 49.0301441, lng: 19.8854873 },
    { lat: 49.0300331, lng: 19.8853643 },
    { lat: 49.0300389, lng: 19.8853397 },
    { lat: 49.030003, lng: 19.8852667 },
    { lat: 49.0299652, lng: 19.8853045 },
    { lat: 49.0293714, lng: 19.8847965 },
    { lat: 49.0290607, lng: 19.8847208 },
    { lat: 49.0288325, lng: 19.8846517 },
    { lat: 49.0285194, lng: 19.8845756 },
    { lat: 49.0282859, lng: 19.8844585 },
    { lat: 49.028126, lng: 19.8843555 },
    { lat: 49.0278049, lng: 19.8841939 },
    { lat: 49.0275593, lng: 19.8839851 },
    { lat: 49.0273285, lng: 19.8837472 },
    { lat: 49.0271789, lng: 19.883567 },
    { lat: 49.0269222, lng: 19.8831486 },
    { lat: 49.026752, lng: 19.8828531 },
    { lat: 49.026532, lng: 19.8825542 },
    { lat: 49.0261182, lng: 19.8821976 },
    { lat: 49.0258594, lng: 19.8820475 },
    { lat: 49.0257704, lng: 19.8819404 },
    { lat: 49.025608, lng: 19.8815698 },
    { lat: 49.0254794, lng: 19.8812429 },
    { lat: 49.0252622, lng: 19.8808551 },
    { lat: 49.0250229, lng: 19.880444 },
    { lat: 49.0248382, lng: 19.8799718 },
    { lat: 49.0247618, lng: 19.8798175 },
    { lat: 49.0246907, lng: 19.8797064 },
    { lat: 49.024361, lng: 19.8794528 },
    { lat: 49.0242996, lng: 19.87933 },
    { lat: 49.0242459, lng: 19.8791547 },
    { lat: 49.0242022, lng: 19.878976 },
    { lat: 49.0241106, lng: 19.8786996 },
    { lat: 49.0239863, lng: 19.8783745 },
    { lat: 49.023848, lng: 19.877826 },
    { lat: 49.0238329, lng: 19.8775005 },
    { lat: 49.0238332, lng: 19.8771358 },
    { lat: 49.0238631, lng: 19.8764366 },
    { lat: 49.0238324, lng: 19.8762982 },
    { lat: 49.0236743, lng: 19.8758658 },
    { lat: 49.0236497, lng: 19.8756332 },
    { lat: 49.0237105, lng: 19.8754979 },
    { lat: 49.023833, lng: 19.8753093 },
    { lat: 49.0239225, lng: 19.875083 },
    { lat: 49.0239405, lng: 19.8744193 },
    { lat: 49.0239232, lng: 19.8741192 },
    { lat: 49.0239359, lng: 19.8737845 },
    { lat: 49.0239283, lng: 19.8735512 },
    { lat: 49.0239595, lng: 19.8732531 },
    { lat: 49.0239405, lng: 19.8724642 },
    { lat: 49.0239603, lng: 19.8720553 },
    { lat: 49.0239591, lng: 19.8714732 },
    { lat: 49.0239389, lng: 19.8708184 },
    { lat: 49.0238147, lng: 19.8706231 },
    { lat: 49.02379, lng: 19.870519 },
    { lat: 49.0238559, lng: 19.8701887 },
    { lat: 49.0237199, lng: 19.8698561 },
    { lat: 49.0236541, lng: 19.8698673 },
    { lat: 49.0236289, lng: 19.8698098 },
    { lat: 49.0235634, lng: 19.8698066 },
    { lat: 49.0234624, lng: 19.8697078 },
    { lat: 49.0234115, lng: 19.869725 },
    { lat: 49.0233564, lng: 19.869597 },
    { lat: 49.023314, lng: 19.8695814 },
    { lat: 49.0232802, lng: 19.8694763 },
    { lat: 49.0230718, lng: 19.8693429 },
    { lat: 49.0229575, lng: 19.8693312 },
    { lat: 49.0229335, lng: 19.8692715 },
    { lat: 49.022864, lng: 19.8693028 },
    { lat: 49.0228639, lng: 19.8692996 },
    { lat: 49.0228399, lng: 19.8692133 },
    { lat: 49.0228167, lng: 19.8692001 },
    { lat: 49.0226511, lng: 19.8690165 },
    { lat: 49.0226077, lng: 19.8688166 },
    { lat: 49.0225172, lng: 19.8686995 },
    { lat: 49.0224559, lng: 19.8687171 },
    { lat: 49.0222524, lng: 19.8685674 },
    { lat: 49.0218402, lng: 19.8683328 },
    { lat: 49.0216456, lng: 19.8682045 },
    { lat: 49.0214611, lng: 19.8680511 },
    { lat: 49.0211034, lng: 19.8675348 },
    { lat: 49.0209098, lng: 19.8675686 },
    { lat: 49.0207263, lng: 19.8677037 },
    { lat: 49.0203097, lng: 19.8679688 },
    { lat: 49.0202447, lng: 19.8680337 },
    { lat: 49.0199887, lng: 19.8682079 },
    { lat: 49.0199633, lng: 19.8682764 },
    { lat: 49.0196683, lng: 19.8683045 },
    { lat: 49.0196278, lng: 19.8683289 },
    { lat: 49.0194995, lng: 19.8682551 },
    { lat: 49.019389, lng: 19.8682538 },
    { lat: 49.0193398, lng: 19.8682346 },
    { lat: 49.0193557, lng: 19.8681817 },
    { lat: 49.019293, lng: 19.8681667 },
    { lat: 49.0192156, lng: 19.8680975 },
    { lat: 49.0191706, lng: 19.868098 },
    { lat: 49.0191263, lng: 19.8680339 },
    { lat: 49.0191131, lng: 19.8679634 },
    { lat: 49.0190415, lng: 19.867922 },
    { lat: 49.0189599, lng: 19.8678073 },
    { lat: 49.0189289, lng: 19.8676851 },
    { lat: 49.0186606, lng: 19.8673649 },
    { lat: 49.0184856, lng: 19.8672741 },
    { lat: 49.0182508, lng: 19.8669729 },
    { lat: 49.0182552, lng: 19.8668644 },
    { lat: 49.0181924, lng: 19.8667439 },
    { lat: 49.0181399, lng: 19.8667247 },
    { lat: 49.0180212, lng: 19.8665807 },
    { lat: 49.0179665, lng: 19.8664131 },
    { lat: 49.0179039, lng: 19.866317 },
    { lat: 49.0178601, lng: 19.8662922 },
    { lat: 49.0178374, lng: 19.8663121 },
    { lat: 49.0177799, lng: 19.8660766 },
    { lat: 49.0177003, lng: 19.8660327 },
    { lat: 49.017685, lng: 19.8659395 },
    { lat: 49.0176327, lng: 19.8658467 },
    { lat: 49.017537, lng: 19.8658235 },
    { lat: 49.0174689, lng: 19.8657322 },
    { lat: 49.0173635, lng: 19.8656913 },
    { lat: 49.0171884, lng: 19.8653601 },
    { lat: 49.0171109, lng: 19.8653146 },
    { lat: 49.0170964, lng: 19.8652127 },
    { lat: 49.0170657, lng: 19.8651282 },
    { lat: 49.0169582, lng: 19.8650878 },
    { lat: 49.0165592, lng: 19.8644787 },
    { lat: 49.0164263, lng: 19.8642909 },
    { lat: 49.0163198, lng: 19.8642009 },
    { lat: 49.0162432, lng: 19.8640445 },
    { lat: 49.0161256, lng: 19.863872 },
    { lat: 49.0159903, lng: 19.8635279 },
    { lat: 49.0159314, lng: 19.8634925 },
    { lat: 49.0159161, lng: 19.8633996 },
    { lat: 49.015848, lng: 19.8632534 },
    { lat: 49.0158737, lng: 19.8632236 },
    { lat: 49.0158137, lng: 19.8631133 },
    { lat: 49.0157802, lng: 19.8630907 },
    { lat: 49.0157512, lng: 19.86302 },
    { lat: 49.0156992, lng: 19.8628027 },
    { lat: 49.0157242, lng: 19.8627575 },
    { lat: 49.0157634, lng: 19.8627518 },
    { lat: 49.0157027, lng: 19.8626247 },
    { lat: 49.0156839, lng: 19.8626057 },
    { lat: 49.0156376, lng: 19.8625776 },
    { lat: 49.0156225, lng: 19.8624819 },
    { lat: 49.0155589, lng: 19.8625014 },
    { lat: 49.0155382, lng: 19.8623061 },
    { lat: 49.0155098, lng: 19.8622199 },
    { lat: 49.0155347, lng: 19.8621694 },
    { lat: 49.0154814, lng: 19.8620314 },
    { lat: 49.0154383, lng: 19.8619944 },
    { lat: 49.0154109, lng: 19.861911 },
    { lat: 49.0154005, lng: 19.8618008 },
    { lat: 49.015366, lng: 19.8616502 },
    { lat: 49.0153734, lng: 19.8614274 },
    { lat: 49.0154369, lng: 19.8613594 },
    { lat: 49.0154275, lng: 19.8612455 },
    { lat: 49.0152618, lng: 19.8611904 },
    { lat: 49.0152599, lng: 19.8611192 },
    { lat: 49.0152149, lng: 19.8610662 },
    { lat: 49.0152241, lng: 19.8609666 },
    { lat: 49.0151482, lng: 19.860851 },
    { lat: 49.0151352, lng: 19.8607292 },
    { lat: 49.0150839, lng: 19.8606622 },
    { lat: 49.0150695, lng: 19.8605914 },
    { lat: 49.0149904, lng: 19.8604521 },
    { lat: 49.0149499, lng: 19.8602724 },
    { lat: 49.0149751, lng: 19.8601486 },
    { lat: 49.0149271, lng: 19.8600819 },
    { lat: 49.0148973, lng: 19.8600698 },
    { lat: 49.0148191, lng: 19.8600011 },
    { lat: 49.0147903, lng: 19.859934 },
    { lat: 49.0147191, lng: 19.8598764 },
    { lat: 49.0146726, lng: 19.8596894 },
    { lat: 49.0147175, lng: 19.8595791 },
    { lat: 49.0146815, lng: 19.859471 },
    { lat: 49.0145889, lng: 19.8593603 },
    { lat: 49.0146451, lng: 19.8591731 },
    { lat: 49.0145673, lng: 19.859146 },
    { lat: 49.0145529, lng: 19.8590979 },
    { lat: 49.0144696, lng: 19.859048 },
    { lat: 49.0145082, lng: 19.8589247 },
    { lat: 49.0144762, lng: 19.8588059 },
    { lat: 49.0144236, lng: 19.8587353 },
    { lat: 49.0144238, lng: 19.8585839 },
    { lat: 49.0144376, lng: 19.8584828 },
    { lat: 49.0144225, lng: 19.8584211 },
    { lat: 49.0144504, lng: 19.8583114 },
    { lat: 49.0143485, lng: 19.8579009 },
    { lat: 49.0143677, lng: 19.8577705 },
    { lat: 49.0143329, lng: 19.8576957 },
    { lat: 49.0143166, lng: 19.857496 },
    { lat: 49.0143418, lng: 19.8574289 },
    { lat: 49.0143106, lng: 19.8573559 },
    { lat: 49.0142837, lng: 19.8572308 },
    { lat: 49.0142064, lng: 19.8570012 },
    { lat: 49.0141302, lng: 19.8565383 },
    { lat: 49.0140882, lng: 19.8565339 },
    { lat: 49.0141234, lng: 19.8564353 },
    { lat: 49.0141314, lng: 19.8562805 },
    { lat: 49.0140358, lng: 19.8560417 },
    { lat: 49.0140089, lng: 19.8558886 },
    { lat: 49.0139554, lng: 19.8557464 },
    { lat: 49.0139788, lng: 19.8556881 },
    { lat: 49.0139422, lng: 19.8555941 },
    { lat: 49.0139915, lng: 19.8555382 },
    { lat: 49.0139686, lng: 19.8554763 },
    { lat: 49.0139579, lng: 19.8554081 },
    { lat: 49.0138774, lng: 19.8552657 },
    { lat: 49.0138636, lng: 19.8551788 },
    { lat: 49.013878, lng: 19.8550977 },
    { lat: 49.0138436, lng: 19.8549991 },
    { lat: 49.0138799, lng: 19.8548222 },
    { lat: 49.0138115, lng: 19.854806 },
    { lat: 49.0137844, lng: 19.8545912 },
    { lat: 49.0136868, lng: 19.8540777 },
    { lat: 49.0136584, lng: 19.8539107 },
    { lat: 49.0130702, lng: 19.8539058 },
    { lat: 49.0125206, lng: 19.8541471 },
    { lat: 49.0121989, lng: 19.8539766 },
    { lat: 49.0118324, lng: 19.8534656 },
    { lat: 49.0115813, lng: 19.8529163 },
    { lat: 49.0114632, lng: 19.852628 },
    { lat: 49.011478, lng: 19.8526093 },
    { lat: 49.0112214, lng: 19.8519313 },
    { lat: 49.0112051, lng: 19.8519426 },
    { lat: 49.0105971, lng: 19.8512654 },
    { lat: 49.010605, lng: 19.8512133 },
    { lat: 49.0104564, lng: 19.8511674 },
    { lat: 49.0100782, lng: 19.8508578 },
    { lat: 49.0100099, lng: 19.8507619 },
    { lat: 49.009727, lng: 19.8503895 },
    { lat: 49.0097177, lng: 19.8504105 },
    { lat: 49.009262, lng: 19.8499432 },
    { lat: 49.0087939, lng: 19.8492497 },
    { lat: 49.0082645, lng: 19.8487506 },
    { lat: 49.007808, lng: 19.8488765 },
    { lat: 49.0070876, lng: 19.8481513 },
    { lat: 49.0064505, lng: 19.8478211 },
    { lat: 49.0062288, lng: 19.847485 },
    { lat: 49.0059611, lng: 19.847467 },
    { lat: 49.005752, lng: 19.8473191 },
    { lat: 49.0052786, lng: 19.8468942 },
    { lat: 49.0050446, lng: 19.8464018 },
    { lat: 49.0038499, lng: 19.8457566 },
    { lat: 49.0026586, lng: 19.8456655 },
    { lat: 49.0023735, lng: 19.84582 },
    { lat: 49.0020894, lng: 19.8461033 },
    { lat: 49.0019644, lng: 19.8461296 },
    { lat: 49.0018463, lng: 19.8460283 },
    { lat: 49.0016716, lng: 19.8457622 },
    { lat: 49.0014593, lng: 19.8455952 },
    { lat: 49.0011023, lng: 19.8446806 },
    { lat: 49.0008493, lng: 19.844297 },
    { lat: 49.000766, lng: 19.8441654 },
    { lat: 49.0006971, lng: 19.8439243 },
    { lat: 49.0005564, lng: 19.8436372 },
    { lat: 49.0003473, lng: 19.8431761 },
    { lat: 49.0001481, lng: 19.8427861 },
    { lat: 48.999685, lng: 19.841975 },
    { lat: 48.999649, lng: 19.841839 },
    { lat: 48.9993521, lng: 19.8411979 },
    { lat: 48.9990062, lng: 19.8403267 },
    { lat: 48.9988851, lng: 19.8402892 },
    { lat: 48.998632, lng: 19.8397988 },
    { lat: 48.998316, lng: 19.8391294 },
    { lat: 48.9977743, lng: 19.8381148 },
    { lat: 48.9973244, lng: 19.8371876 },
    { lat: 48.9969253, lng: 19.8364695 },
    { lat: 48.9965304, lng: 19.8355351 },
    { lat: 48.996135, lng: 19.8349383 },
    { lat: 48.995805, lng: 19.8346464 },
    { lat: 48.9951854, lng: 19.8349103 },
    { lat: 48.9944507, lng: 19.8351669 },
    { lat: 48.9942694, lng: 19.8348545 },
    { lat: 48.9942035, lng: 19.8346071 },
    { lat: 48.9940885, lng: 19.8344452 },
    { lat: 48.9940751, lng: 19.8342116 },
    { lat: 48.9939523, lng: 19.8339239 },
    { lat: 48.9938238, lng: 19.8335284 },
    { lat: 48.9937025, lng: 19.8333808 },
    { lat: 48.9936197, lng: 19.8332104 },
    { lat: 48.9934492, lng: 19.8331986 },
    { lat: 48.99318, lng: 19.8330424 },
    { lat: 48.9929696, lng: 19.8326293 },
    { lat: 48.9927598, lng: 19.8320496 },
    { lat: 48.9921362, lng: 19.8312772 },
    { lat: 48.9919532, lng: 19.8308197 },
    { lat: 48.9918278, lng: 19.8306769 },
    { lat: 48.9916525, lng: 19.8303223 },
    { lat: 48.9909046, lng: 19.8303762 },
    { lat: 48.9905574, lng: 19.830448 },
    { lat: 48.9902733, lng: 19.8304723 },
    { lat: 48.9899846, lng: 19.83078 },
    { lat: 48.9897643, lng: 19.8309752 },
    { lat: 48.9895394, lng: 19.8311152 },
    { lat: 48.9893827, lng: 19.8311641 },
    { lat: 48.9892343, lng: 19.8312761 },
    { lat: 48.9889218, lng: 19.8315524 },
    { lat: 48.9887048, lng: 19.8315342 },
    { lat: 48.9884659, lng: 19.8313528 },
    { lat: 48.9883108, lng: 19.8310733 },
    { lat: 48.9881407, lng: 19.8308327 },
    { lat: 48.9879304, lng: 19.8306373 },
    { lat: 48.9876907, lng: 19.8305087 },
    { lat: 48.9873823, lng: 19.8302545 },
    { lat: 48.9870986, lng: 19.8300252 },
    { lat: 48.9866487, lng: 19.8296746 },
    { lat: 48.9860894, lng: 19.8292657 },
    { lat: 48.9856803, lng: 19.8289205 },
    { lat: 48.9854955, lng: 19.8288667 },
    { lat: 48.9851785, lng: 19.8286486 },
    { lat: 48.9850728, lng: 19.8284468 },
    { lat: 48.9849127, lng: 19.8281001 },
    { lat: 48.9846739, lng: 19.8276314 },
    { lat: 48.9845517, lng: 19.827381 },
    { lat: 48.9840745, lng: 19.8266315 },
    { lat: 48.9836135, lng: 19.8258983 },
    { lat: 48.9833497, lng: 19.8250581 },
    { lat: 48.9830933, lng: 19.8249369 },
    { lat: 48.982373, lng: 19.8245828 },
    { lat: 48.9820888, lng: 19.8244475 },
    { lat: 48.981969, lng: 19.8244118 },
    { lat: 48.9815588, lng: 19.8243037 },
    { lat: 48.9810844, lng: 19.824196 },
    { lat: 48.9806703, lng: 19.824282 },
    { lat: 48.9803733, lng: 19.8243474 },
  ],
  ZávažnáPoruba: [
    { lat: 49.0675654, lng: 19.6316605 },
    { lat: 49.0674244, lng: 19.6314544 },
    { lat: 49.0673592, lng: 19.6314105 },
    { lat: 49.0671196, lng: 19.6315445 },
    { lat: 49.0669551, lng: 19.6312184 },
    { lat: 49.066702, lng: 19.6309748 },
    { lat: 49.0665284, lng: 19.6308936 },
    { lat: 49.0664119, lng: 19.6309876 },
    { lat: 49.0661021, lng: 19.6308382 },
    { lat: 49.0660924, lng: 19.6303947 },
    { lat: 49.0659164, lng: 19.6299857 },
    { lat: 49.0657185, lng: 19.630095 },
    { lat: 49.0654769, lng: 19.6301347 },
    { lat: 49.0654775, lng: 19.6298695 },
    { lat: 49.0652502, lng: 19.629648 },
    { lat: 49.0652016, lng: 19.6295179 },
    { lat: 49.0651467, lng: 19.6295258 },
    { lat: 49.0649346, lng: 19.6292912 },
    { lat: 49.0648077, lng: 19.6295307 },
    { lat: 49.0646456, lng: 19.6295062 },
    { lat: 49.0645771, lng: 19.6293106 },
    { lat: 49.0645644, lng: 19.6292264 },
    { lat: 49.0645684, lng: 19.6290898 },
    { lat: 49.0642756, lng: 19.6285916 },
    { lat: 49.0638174, lng: 19.628799 },
    { lat: 49.0633033, lng: 19.6282896 },
    { lat: 49.0629584, lng: 19.6284129 },
    { lat: 49.0627977, lng: 19.6285205 },
    { lat: 49.0627884, lng: 19.6282635 },
    { lat: 49.0625122, lng: 19.6281322 },
    { lat: 49.0621988, lng: 19.6278255 },
    { lat: 49.0621076, lng: 19.6277009 },
    { lat: 49.0621261, lng: 19.6275136 },
    { lat: 49.0621207, lng: 19.6274664 },
    { lat: 49.0619667, lng: 19.6273232 },
    { lat: 49.0618003, lng: 19.6272556 },
    { lat: 49.0617086, lng: 19.6271214 },
    { lat: 49.0614983, lng: 19.62708 },
    { lat: 49.0614615, lng: 19.6269864 },
    { lat: 49.0612764, lng: 19.6269819 },
    { lat: 49.0613152, lng: 19.6267867 },
    { lat: 49.061295, lng: 19.6267432 },
    { lat: 49.061155, lng: 19.6266848 },
    { lat: 49.0608874, lng: 19.6264175 },
    { lat: 49.0607444, lng: 19.6264945 },
    { lat: 49.0606114, lng: 19.6264709 },
    { lat: 49.0604215, lng: 19.6261047 },
    { lat: 49.0600498, lng: 19.626056 },
    { lat: 49.059794, lng: 19.6257799 },
    { lat: 49.0596909, lng: 19.6255654 },
    { lat: 49.0596683, lng: 19.6256396 },
    { lat: 49.0597141, lng: 19.6267636 },
    { lat: 49.0592481, lng: 19.626416 },
    { lat: 49.0586777, lng: 19.6259308 },
    { lat: 49.058038, lng: 19.6254392 },
    { lat: 49.057655, lng: 19.6249565 },
    { lat: 49.0573792, lng: 19.6243873 },
    { lat: 49.0570852, lng: 19.6240118 },
    { lat: 49.0569405, lng: 19.6238483 },
    { lat: 49.0568369, lng: 19.6237491 },
    { lat: 49.0566069, lng: 19.6236425 },
    { lat: 49.0563933, lng: 19.6235533 },
    { lat: 49.0562399, lng: 19.6234496 },
    { lat: 49.0560475, lng: 19.6233028 },
    { lat: 49.0560108, lng: 19.6233171 },
    { lat: 49.0541324, lng: 19.6219308 },
    { lat: 49.053989, lng: 19.6218702 },
    { lat: 49.0532824, lng: 19.6212376 },
    { lat: 49.0527511, lng: 19.6207729 },
    { lat: 49.0522144, lng: 19.620209 },
    { lat: 49.0519914, lng: 19.6199839 },
    { lat: 49.0515094, lng: 19.619439 },
    { lat: 49.0516563, lng: 19.6190703 },
    { lat: 49.0516335, lng: 19.619036 },
    { lat: 49.0516126, lng: 19.6190238 },
    { lat: 49.0515726, lng: 19.6189615 },
    { lat: 49.0515601, lng: 19.6189309 },
    { lat: 49.0514055, lng: 19.6187005 },
    { lat: 49.0513077, lng: 19.6185828 },
    { lat: 49.0509375, lng: 19.6181136 },
    { lat: 49.0507838, lng: 19.6179514 },
    { lat: 49.0507243, lng: 19.6179091 },
    { lat: 49.0506934, lng: 19.6178986 },
    { lat: 49.0505723, lng: 19.6177567 },
    { lat: 49.0505671, lng: 19.617767 },
    { lat: 49.0504601, lng: 19.6176459 },
    { lat: 49.0508829, lng: 19.6168225 },
    { lat: 49.0509781, lng: 19.6166341 },
    { lat: 49.0509711, lng: 19.6166262 },
    { lat: 49.0510175, lng: 19.6165338 },
    { lat: 49.0509969, lng: 19.6165021 },
    { lat: 49.0509442, lng: 19.6165047 },
    { lat: 49.0509398, lng: 19.6165063 },
    { lat: 49.0509421, lng: 19.6164583 },
    { lat: 49.0507219, lng: 19.6164703 },
    { lat: 49.050634, lng: 19.6164722 },
    { lat: 49.0503232, lng: 19.6164787 },
    { lat: 49.0502729, lng: 19.6164613 },
    { lat: 49.0499059, lng: 19.6161919 },
    { lat: 49.0495679, lng: 19.6159137 },
    { lat: 49.0491675, lng: 19.6154036 },
    { lat: 49.0487869, lng: 19.6145647 },
    { lat: 49.0485161, lng: 19.6139989 },
    { lat: 49.0482031, lng: 19.6133216 },
    { lat: 49.0479064, lng: 19.612833 },
    { lat: 49.0476646, lng: 19.6125654 },
    { lat: 49.047556, lng: 19.6125595 },
    { lat: 49.0472521, lng: 19.6122642 },
    { lat: 49.0470757, lng: 19.6119747 },
    { lat: 49.0468512, lng: 19.6118644 },
    { lat: 49.046747, lng: 19.6116051 },
    { lat: 49.046447, lng: 19.6114423 },
    { lat: 49.0460756, lng: 19.6109281 },
    { lat: 49.0454702, lng: 19.6103912 },
    { lat: 49.04529, lng: 19.6100166 },
    { lat: 49.0448515, lng: 19.6094631 },
    { lat: 49.0445479, lng: 19.6092472 },
    { lat: 49.0443147, lng: 19.6089755 },
    { lat: 49.044126, lng: 19.6088593 },
    { lat: 49.0439227, lng: 19.6088481 },
    { lat: 49.043886, lng: 19.6086616 },
    { lat: 49.0438006, lng: 19.6085157 },
    { lat: 49.0436362, lng: 19.6084667 },
    { lat: 49.0434206, lng: 19.6082337 },
    { lat: 49.0433561, lng: 19.6081751 },
    { lat: 49.0431324, lng: 19.6081635 },
    { lat: 49.0428543, lng: 19.6076386 },
    { lat: 49.0423762, lng: 19.6072541 },
    { lat: 49.0420234, lng: 19.6067265 },
    { lat: 49.0417595, lng: 19.6064458 },
    { lat: 49.0415397, lng: 19.6059662 },
    { lat: 49.0414694, lng: 19.6057049 },
    { lat: 49.0413253, lng: 19.6055572 },
    { lat: 49.0411955, lng: 19.6052744 },
    { lat: 49.0409566, lng: 19.6051225 },
    { lat: 49.0408002, lng: 19.6050792 },
    { lat: 49.040438, lng: 19.6049157 },
    { lat: 49.0402738, lng: 19.6047242 },
    { lat: 49.0400903, lng: 19.6048025 },
    { lat: 49.0399514, lng: 19.604948 },
    { lat: 49.0396673, lng: 19.6047926 },
    { lat: 49.0395076, lng: 19.6046228 },
    { lat: 49.039267, lng: 19.6045595 },
    { lat: 49.0387904, lng: 19.6039048 },
    { lat: 49.0385289, lng: 19.6039547 },
    { lat: 49.0384067, lng: 19.6039476 },
    { lat: 49.0382504, lng: 19.6038005 },
    { lat: 49.0379589, lng: 19.6036344 },
    { lat: 49.0375514, lng: 19.6032936 },
    { lat: 49.0374863, lng: 19.6032946 },
    { lat: 49.0373309, lng: 19.6033708 },
    { lat: 49.0370874, lng: 19.6034176 },
    { lat: 49.0368711, lng: 19.6031184 },
    { lat: 49.0366996, lng: 19.6027897 },
    { lat: 49.0364311, lng: 19.6026793 },
    { lat: 49.0361576, lng: 19.6026611 },
    { lat: 49.0359328, lng: 19.6026755 },
    { lat: 49.0358133, lng: 19.6027235 },
    { lat: 49.035466, lng: 19.602565 },
    { lat: 49.0352356, lng: 19.6025545 },
    { lat: 49.0349096, lng: 19.6026189 },
    { lat: 49.0347187, lng: 19.602637 },
    { lat: 49.034568, lng: 19.6027669 },
    { lat: 49.0343886, lng: 19.6026365 },
    { lat: 49.034151, lng: 19.6026882 },
    { lat: 49.0339976, lng: 19.6029368 },
    { lat: 49.0338335, lng: 19.602946 },
    { lat: 49.0336381, lng: 19.602786 },
    { lat: 49.0332629, lng: 19.6027331 },
    { lat: 49.0330297, lng: 19.602835 },
    { lat: 49.032764, lng: 19.6030152 },
    { lat: 49.0321979, lng: 19.6028023 },
    { lat: 49.032096, lng: 19.6028678 },
    { lat: 49.0319698, lng: 19.6028906 },
    { lat: 49.0318312, lng: 19.603044 },
    { lat: 49.0317534, lng: 19.6032187 },
    { lat: 49.0313913, lng: 19.6031311 },
    { lat: 49.0311883, lng: 19.6034262 },
    { lat: 49.031164, lng: 19.6035167 },
    { lat: 49.0310795, lng: 19.6035912 },
    { lat: 49.0309894, lng: 19.6035919 },
    { lat: 49.0306269, lng: 19.6038217 },
    { lat: 49.0302176, lng: 19.6037918 },
    { lat: 49.0300365, lng: 19.6038242 },
    { lat: 49.0297482, lng: 19.6041009 },
    { lat: 49.0296789, lng: 19.604214 },
    { lat: 49.029058, lng: 19.6043881 },
    { lat: 49.0287469, lng: 19.6046635 },
    { lat: 49.0286071, lng: 19.6046915 },
    { lat: 49.0284822, lng: 19.6047165 },
    { lat: 49.0283855, lng: 19.6048181 },
    { lat: 49.0281944, lng: 19.604852 },
    { lat: 49.0281306, lng: 19.6049344 },
    { lat: 49.0281041, lng: 19.6051239 },
    { lat: 49.0279868, lng: 19.605298 },
    { lat: 49.0276486, lng: 19.6053163 },
    { lat: 49.0273638, lng: 19.605473 },
    { lat: 49.0271307, lng: 19.6054768 },
    { lat: 49.026998, lng: 19.60558 },
    { lat: 49.0268746, lng: 19.6057465 },
    { lat: 49.0266581, lng: 19.6059187 },
    { lat: 49.0265088, lng: 19.6059267 },
    { lat: 49.0262569, lng: 19.606216 },
    { lat: 49.0259677, lng: 19.6066482 },
    { lat: 49.0255023, lng: 19.6069723 },
    { lat: 49.0253968, lng: 19.6072112 },
    { lat: 49.025125, lng: 19.607377 },
    { lat: 49.0250792, lng: 19.6076873 },
    { lat: 49.0250346, lng: 19.6077519 },
    { lat: 49.0247143, lng: 19.6077435 },
    { lat: 49.0244985, lng: 19.6078528 },
    { lat: 49.0244169, lng: 19.6081435 },
    { lat: 49.0242958, lng: 19.6082799 },
    { lat: 49.0239502, lng: 19.6083399 },
    { lat: 49.023669, lng: 19.6085752 },
    { lat: 49.0235348, lng: 19.6087995 },
    { lat: 49.0231707, lng: 19.6089939 },
    { lat: 49.0227352, lng: 19.6089063 },
    { lat: 49.0224615, lng: 19.6088844 },
    { lat: 49.0223432, lng: 19.6087327 },
    { lat: 49.0222432, lng: 19.6085144 },
    { lat: 49.0220861, lng: 19.6082797 },
    { lat: 49.0214446, lng: 19.6080172 },
    { lat: 49.0211527, lng: 19.6080411 },
    { lat: 49.0210765, lng: 19.6082744 },
    { lat: 49.0209636, lng: 19.6083203 },
    { lat: 49.0207576, lng: 19.6083503 },
    { lat: 49.020538, lng: 19.6085285 },
    { lat: 49.0202503, lng: 19.609224 },
    { lat: 49.0202314, lng: 19.6093541 },
    { lat: 49.020089, lng: 19.6096701 },
    { lat: 49.0197672, lng: 19.6098324 },
    { lat: 49.0196567, lng: 19.6099548 },
    { lat: 49.0193508, lng: 19.6105911 },
    { lat: 49.0192469, lng: 19.611065 },
    { lat: 49.0188488, lng: 19.6118375 },
    { lat: 49.018807, lng: 19.612058 },
    { lat: 49.0187258, lng: 19.6123306 },
    { lat: 49.0186339, lng: 19.6123671 },
    { lat: 49.0184083, lng: 19.6120645 },
    { lat: 49.0183123, lng: 19.6120088 },
    { lat: 49.0181935, lng: 19.6120445 },
    { lat: 49.0180784, lng: 19.612167 },
    { lat: 49.0179609, lng: 19.6123849 },
    { lat: 49.0177292, lng: 19.6124667 },
    { lat: 49.0175621, lng: 19.6123376 },
    { lat: 49.0169993, lng: 19.6124481 },
    { lat: 49.0168079, lng: 19.6125498 },
    { lat: 49.0166434, lng: 19.6127772 },
    { lat: 49.0164453, lng: 19.6127561 },
    { lat: 49.0163308, lng: 19.6126941 },
    { lat: 49.0162248, lng: 19.6127148 },
    { lat: 49.0160232, lng: 19.6129945 },
    { lat: 49.0158782, lng: 19.6133251 },
    { lat: 49.0156935, lng: 19.6135033 },
    { lat: 49.0153155, lng: 19.6141714 },
    { lat: 49.015099, lng: 19.6141396 },
    { lat: 49.0149434, lng: 19.6143396 },
    { lat: 49.0149057, lng: 19.6145043 },
    { lat: 49.0148433, lng: 19.6145962 },
    { lat: 49.0147551, lng: 19.6156657 },
    { lat: 49.0144502, lng: 19.6162275 },
    { lat: 49.014065, lng: 19.6165574 },
    { lat: 49.0139501, lng: 19.6168572 },
    { lat: 49.0139409, lng: 19.6169573 },
    { lat: 49.013864, lng: 19.6171483 },
    { lat: 49.0136214, lng: 19.6175212 },
    { lat: 49.0135439, lng: 19.6176775 },
    { lat: 49.0134549, lng: 19.6177033 },
    { lat: 49.0130027, lng: 19.6177692 },
    { lat: 49.0128699, lng: 19.6178465 },
    { lat: 49.0125019, lng: 19.6179058 },
    { lat: 49.0123238, lng: 19.6180793 },
    { lat: 49.0120941, lng: 19.6187636 },
    { lat: 49.0121163, lng: 19.6190592 },
    { lat: 49.0119962, lng: 19.6192174 },
    { lat: 49.0120211, lng: 19.6195733 },
    { lat: 49.0120002, lng: 19.6200601 },
    { lat: 49.0116406, lng: 19.6207603 },
    { lat: 49.0115496, lng: 19.6210145 },
    { lat: 49.0113755, lng: 19.6216093 },
    { lat: 49.0111523, lng: 19.6217282 },
    { lat: 49.0110194, lng: 19.6218809 },
    { lat: 49.01094, lng: 19.6220223 },
    { lat: 49.0106474, lng: 19.6223573 },
    { lat: 49.0105634, lng: 19.6224888 },
    { lat: 49.0103849, lng: 19.6225798 },
    { lat: 49.0102047, lng: 19.622756 },
    { lat: 49.0100766, lng: 19.622943 },
    { lat: 49.0097068, lng: 19.6232368 },
    { lat: 49.0095655, lng: 19.6236298 },
    { lat: 49.0097103, lng: 19.624601 },
    { lat: 49.0097268, lng: 19.6248696 },
    { lat: 49.0096705, lng: 19.6250926 },
    { lat: 49.0095165, lng: 19.6255823 },
    { lat: 49.0095177, lng: 19.6258359 },
    { lat: 49.0093792, lng: 19.6260386 },
    { lat: 49.0093658, lng: 19.6261646 },
    { lat: 49.0089226, lng: 19.6267592 },
    { lat: 49.0087439, lng: 19.6269191 },
    { lat: 49.0083815, lng: 19.6273101 },
    { lat: 49.0083655, lng: 19.6274797 },
    { lat: 49.0081206, lng: 19.628122 },
    { lat: 49.0076059, lng: 19.6281789 },
    { lat: 49.0072707, lng: 19.6279634 },
    { lat: 49.0067394, lng: 19.6282779 },
    { lat: 49.0065846, lng: 19.6285702 },
    { lat: 49.006317, lng: 19.6285573 },
    { lat: 49.0061806, lng: 19.6287333 },
    { lat: 49.0060819, lng: 19.6290715 },
    { lat: 49.0057407, lng: 19.6296028 },
    { lat: 49.0054649, lng: 19.6297067 },
    { lat: 49.0051647, lng: 19.6299477 },
    { lat: 49.0045057, lng: 19.6313078 },
    { lat: 49.0042623, lng: 19.6313613 },
    { lat: 49.0038767, lng: 19.6318339 },
    { lat: 49.0035912, lng: 19.6325026 },
    { lat: 49.0031828, lng: 19.6326144 },
    { lat: 49.0031416, lng: 19.6327274 },
    { lat: 49.0028461, lng: 19.6326187 },
    { lat: 49.0027035, lng: 19.6324551 },
    { lat: 49.002336, lng: 19.6325279 },
    { lat: 49.0019897, lng: 19.6323172 },
    { lat: 49.0018605, lng: 19.6321461 },
    { lat: 49.001581, lng: 19.6318674 },
    { lat: 49.0014646, lng: 19.6316372 },
    { lat: 49.0007557, lng: 19.6309258 },
    { lat: 49.0003753, lng: 19.6307821 },
    { lat: 49.0002945, lng: 19.6305662 },
    { lat: 49.0000009, lng: 19.6300202 },
    { lat: 48.9998016, lng: 19.6296964 },
    { lat: 48.9995998, lng: 19.6292927 },
    { lat: 48.9995881, lng: 19.6293065 },
    { lat: 48.9988825, lng: 19.6288222 },
    { lat: 48.9984565, lng: 19.6285408 },
    { lat: 48.9980028, lng: 19.6284744 },
    { lat: 48.9971339, lng: 19.6285958 },
    { lat: 48.9966264, lng: 19.628813 },
    { lat: 48.9952439, lng: 19.6287855 },
    { lat: 48.9945637, lng: 19.6292484 },
    { lat: 48.9936843, lng: 19.6295076 },
    { lat: 48.9932004, lng: 19.6296208 },
    { lat: 48.9926647, lng: 19.629565 },
    { lat: 48.9920456, lng: 19.6296739 },
    { lat: 48.9905543, lng: 19.6297875 },
    { lat: 48.9904102, lng: 19.6297131 },
    { lat: 48.9894736, lng: 19.6294336 },
    { lat: 48.9889879, lng: 19.6293791 },
    { lat: 48.9880788, lng: 19.6291566 },
    { lat: 48.9872444, lng: 19.6289663 },
    { lat: 48.9868272, lng: 19.6285549 },
    { lat: 48.9858866, lng: 19.6280797 },
    { lat: 48.9849667, lng: 19.6284743 },
    { lat: 48.9828552, lng: 19.6297817 },
    { lat: 48.9826095, lng: 19.6298566 },
    { lat: 48.9818788, lng: 19.6316281 },
    { lat: 48.9811327, lng: 19.6327573 },
    { lat: 48.9817346, lng: 19.6327852 },
    { lat: 48.9830269, lng: 19.6333149 },
    { lat: 48.9848004, lng: 19.6328382 },
    { lat: 48.9849709, lng: 19.6330459 },
    { lat: 48.9854003, lng: 19.6344845 },
    { lat: 48.9863922, lng: 19.6353503 },
    { lat: 48.9867332, lng: 19.6353228 },
    { lat: 48.9870568, lng: 19.6352967 },
    { lat: 48.9873655, lng: 19.6354945 },
    { lat: 48.9883191, lng: 19.6364399 },
    { lat: 48.9892999, lng: 19.6379143 },
    { lat: 48.9905472, lng: 19.637561 },
    { lat: 48.9912025, lng: 19.6369604 },
    { lat: 48.9916284, lng: 19.63657 },
    { lat: 48.9945154, lng: 19.6353648 },
    { lat: 48.9957042, lng: 19.6351914 },
    { lat: 48.9984135, lng: 19.6349811 },
    { lat: 49.0006411, lng: 19.6352157 },
    { lat: 49.0025274, lng: 19.6353696 },
    { lat: 49.0028654, lng: 19.6353562 },
    { lat: 49.003109, lng: 19.6352711 },
    { lat: 49.0055246, lng: 19.635173 },
    { lat: 49.0069127, lng: 19.6352955 },
    { lat: 49.0073226, lng: 19.6351652 },
    { lat: 49.0084119, lng: 19.6348571 },
    { lat: 49.0096198, lng: 19.6344577 },
    { lat: 49.0099371, lng: 19.6341446 },
    { lat: 49.0106133, lng: 19.633924 },
    { lat: 49.0111483, lng: 19.6339179 },
    { lat: 49.0123324, lng: 19.6337201 },
    { lat: 49.0139807, lng: 19.6331879 },
    { lat: 49.0161306, lng: 19.6336688 },
    { lat: 49.0168715, lng: 19.6338597 },
    { lat: 49.0178528, lng: 19.6334159 },
    { lat: 49.0210183, lng: 19.6319843 },
    { lat: 49.0236793, lng: 19.6380502 },
    { lat: 49.0246449, lng: 19.6436554 },
    { lat: 49.0248224, lng: 19.6446007 },
    { lat: 49.0252935, lng: 19.6474073 },
    { lat: 49.0254041, lng: 19.6484471 },
    { lat: 49.0254735, lng: 19.6489666 },
    { lat: 49.0257089, lng: 19.6493473 },
    { lat: 49.0258445, lng: 19.6497573 },
    { lat: 49.0260351, lng: 19.6505498 },
    { lat: 49.0262658, lng: 19.6517312 },
    { lat: 49.0264659, lng: 19.6528584 },
    { lat: 49.0267177, lng: 19.6543127 },
    { lat: 49.026864, lng: 19.6550911 },
    { lat: 49.0273491, lng: 19.6547546 },
    { lat: 49.0281215, lng: 19.6545606 },
    { lat: 49.0291783, lng: 19.65426 },
    { lat: 49.0302317, lng: 19.6539045 },
    { lat: 49.0312842, lng: 19.6536079 },
    { lat: 49.0326617, lng: 19.6530366 },
    { lat: 49.033113, lng: 19.653305 },
    { lat: 49.0334816, lng: 19.6537902 },
    { lat: 49.0349323, lng: 19.6547952 },
    { lat: 49.0351008, lng: 19.6572386 },
    { lat: 49.0371881, lng: 19.6588239 },
    { lat: 49.0381897, lng: 19.65981 },
    { lat: 49.0384325, lng: 19.659344 },
    { lat: 49.0388516, lng: 19.6588086 },
    { lat: 49.0393298, lng: 19.6593802 },
    { lat: 49.0394213, lng: 19.6592821 },
    { lat: 49.039833, lng: 19.6585851 },
    { lat: 49.0402961, lng: 19.6567893 },
    { lat: 49.0408297, lng: 19.6567253 },
    { lat: 49.0420971, lng: 19.6569288 },
    { lat: 49.0425018, lng: 19.6570319 },
    { lat: 49.0427411, lng: 19.6566352 },
    { lat: 49.0431361, lng: 19.6557922 },
    { lat: 49.0438278, lng: 19.6560934 },
    { lat: 49.044838, lng: 19.6571979 },
    { lat: 49.045685, lng: 19.6569146 },
    { lat: 49.0466342, lng: 19.6570266 },
    { lat: 49.0466072, lng: 19.6574877 },
    { lat: 49.0472578, lng: 19.6582335 },
    { lat: 49.0473007, lng: 19.6589442 },
    { lat: 49.0474016, lng: 19.6592111 },
    { lat: 49.0476404, lng: 19.6587048 },
    { lat: 49.0480247, lng: 19.658856 },
    { lat: 49.0484394, lng: 19.6589606 },
    { lat: 49.0484763, lng: 19.6589827 },
    { lat: 49.0492774, lng: 19.6594623 },
    { lat: 49.0501915, lng: 19.6599289 },
    { lat: 49.0502319, lng: 19.6605074 },
    { lat: 49.0516327, lng: 19.6607906 },
    { lat: 49.0516494, lng: 19.6602325 },
    { lat: 49.0517926, lng: 19.6598518 },
    { lat: 49.0520149, lng: 19.6595792 },
    { lat: 49.0520614, lng: 19.6595899 },
    { lat: 49.0527504, lng: 19.6597773 },
    { lat: 49.0582968, lng: 19.6612943 },
    { lat: 49.0585633, lng: 19.6586023 },
    { lat: 49.0585695, lng: 19.6585607 },
    { lat: 49.0589057, lng: 19.6559133 },
    { lat: 49.0588196, lng: 19.6553732 },
    { lat: 49.059018, lng: 19.65494 },
    { lat: 49.0591027, lng: 19.6547419 },
    { lat: 49.0591909, lng: 19.6540923 },
    { lat: 49.0591767, lng: 19.6523047 },
    { lat: 49.0593678, lng: 19.6524991 },
    { lat: 49.0593688, lng: 19.6526732 },
    { lat: 49.0594211, lng: 19.652989 },
    { lat: 49.0612135, lng: 19.653466 },
    { lat: 49.0613476, lng: 19.6529855 },
    { lat: 49.0614033, lng: 19.6530211 },
    { lat: 49.0618879, lng: 19.653052 },
    { lat: 49.0623662, lng: 19.6531891 },
    { lat: 49.0638163, lng: 19.6536501 },
    { lat: 49.0657821, lng: 19.6542728 },
    { lat: 49.0693121, lng: 19.6553919 },
    { lat: 49.0691697, lng: 19.6549782 },
    { lat: 49.0691247, lng: 19.6548528 },
    { lat: 49.069097, lng: 19.65433 },
    { lat: 49.069185, lng: 19.6536763 },
    { lat: 49.0693064, lng: 19.653345 },
    { lat: 49.0691166, lng: 19.6532004 },
    { lat: 49.069273, lng: 19.6529974 },
    { lat: 49.0692752, lng: 19.6529925 },
    { lat: 49.0694162, lng: 19.6528087 },
    { lat: 49.0696701, lng: 19.6524768 },
    { lat: 49.07015, lng: 19.6518445 },
    { lat: 49.0700086, lng: 19.6502582 },
    { lat: 49.0700283, lng: 19.649917 },
    { lat: 49.0700413, lng: 19.6499067 },
    { lat: 49.0705143, lng: 19.649851 },
    { lat: 49.070899, lng: 19.6498117 },
    { lat: 49.070908, lng: 19.6489538 },
    { lat: 49.0709012, lng: 19.6482972 },
    { lat: 49.0708497, lng: 19.6476963 },
    { lat: 49.0707814, lng: 19.6470979 },
    { lat: 49.0706341, lng: 19.6461706 },
    { lat: 49.070382, lng: 19.6460766 },
    { lat: 49.0686858, lng: 19.6454533 },
    { lat: 49.0680457, lng: 19.645224 },
    { lat: 49.0657825, lng: 19.6443977 },
    { lat: 49.0653521, lng: 19.644293 },
    { lat: 49.0656773, lng: 19.6431995 },
    { lat: 49.0660133, lng: 19.6420398 },
    { lat: 49.0662688, lng: 19.6410243 },
    { lat: 49.0665792, lng: 19.6397023 },
    { lat: 49.0668775, lng: 19.6383341 },
    { lat: 49.0667836, lng: 19.6376891 },
    { lat: 49.066576, lng: 19.6361972 },
    { lat: 49.0663192, lng: 19.6343589 },
    { lat: 49.0668688, lng: 19.632361 },
    { lat: 49.0675584, lng: 19.6317127 },
    { lat: 49.0675687, lng: 19.6316845 },
    { lat: 49.0675654, lng: 19.6316605 },
  ],
  Žiar: [
    { lat: 49.1932701, lng: 19.7506818 },
    { lat: 49.197129, lng: 19.748232 },
    { lat: 49.1973, lng: 19.746102 },
    { lat: 49.196483, lng: 19.741804 },
    { lat: 49.196585, lng: 19.740133 },
    { lat: 49.196331, lng: 19.739002 },
    { lat: 49.196771, lng: 19.737215 },
    { lat: 49.196885, lng: 19.735616 },
    { lat: 49.198072, lng: 19.732933 },
    { lat: 49.199007, lng: 19.731202 },
    { lat: 49.199984, lng: 19.729042 },
    { lat: 49.199968, lng: 19.727066 },
    { lat: 49.200016, lng: 19.726187 },
    { lat: 49.200143, lng: 19.723123 },
    { lat: 49.200154, lng: 19.722858 },
    { lat: 49.199479, lng: 19.718772 },
    { lat: 49.198759, lng: 19.716358 },
    { lat: 49.197795, lng: 19.709945 },
    { lat: 49.1940407, lng: 19.7105657 },
    { lat: 49.1939075, lng: 19.710336 },
    { lat: 49.1923673, lng: 19.7076798 },
    { lat: 49.1903171, lng: 19.7060284 },
    { lat: 49.1901787, lng: 19.7059169 },
    { lat: 49.1894845, lng: 19.7056007 },
    { lat: 49.1874144, lng: 19.702946 },
    { lat: 49.1852197, lng: 19.7001022 },
    { lat: 49.1823276, lng: 19.6972793 },
    { lat: 49.1814347, lng: 19.6963656 },
    { lat: 49.180735, lng: 19.6947246 },
    { lat: 49.1800217, lng: 19.6941099 },
    { lat: 49.176404, lng: 19.6945621 },
    { lat: 49.1745388, lng: 19.6929072 },
    { lat: 49.1736342, lng: 19.6921131 },
    { lat: 49.1734346, lng: 19.6919345 },
    { lat: 49.1724008, lng: 19.6913751 },
    { lat: 49.1719679, lng: 19.6916287 },
    { lat: 49.171236, lng: 19.6912252 },
    { lat: 49.1701332, lng: 19.690849 },
    { lat: 49.1690989, lng: 19.6907657 },
    { lat: 49.168341, lng: 19.6907746 },
    { lat: 49.1676072, lng: 19.690762 },
    { lat: 49.1665526, lng: 19.6908122 },
    { lat: 49.165501, lng: 19.6907235 },
    { lat: 49.1645843, lng: 19.6894286 },
    { lat: 49.1639289, lng: 19.6884907 },
    { lat: 49.163257, lng: 19.6875578 },
    { lat: 49.1626919, lng: 19.6868249 },
    { lat: 49.1620982, lng: 19.6859873 },
    { lat: 49.1618762, lng: 19.6855983 },
    { lat: 49.1618184, lng: 19.6854663 },
    { lat: 49.1616457, lng: 19.6849236 },
    { lat: 49.1613762, lng: 19.6840211 },
    { lat: 49.161162, lng: 19.682641 },
    { lat: 49.1608677, lng: 19.6822039 },
    { lat: 49.1603377, lng: 19.6816803 },
    { lat: 49.1598495, lng: 19.6812632 },
    { lat: 49.1596701, lng: 19.6809277 },
    { lat: 49.1595908, lng: 19.6805313 },
    { lat: 49.1591218, lng: 19.6801702 },
    { lat: 49.1588038, lng: 19.6795243 },
    { lat: 49.1582694, lng: 19.6781909 },
    { lat: 49.1581862, lng: 19.6778147 },
    { lat: 49.1578424, lng: 19.6773752 },
    { lat: 49.1571898, lng: 19.6766047 },
    { lat: 49.156437, lng: 19.6754774 },
    { lat: 49.1561353, lng: 19.6750736 },
    { lat: 49.1558463, lng: 19.6748578 },
    { lat: 49.1556, lng: 19.674638 },
    { lat: 49.1555472, lng: 19.6746125 },
    { lat: 49.1553438, lng: 19.6746784 },
    { lat: 49.1551638, lng: 19.6746077 },
    { lat: 49.1550973, lng: 19.6745029 },
    { lat: 49.1548164, lng: 19.6744447 },
    { lat: 49.1547593, lng: 19.6743005 },
    { lat: 49.1545718, lng: 19.6741877 },
    { lat: 49.1544682, lng: 19.6741613 },
    { lat: 49.1543805, lng: 19.6742189 },
    { lat: 49.1542793, lng: 19.6743481 },
    { lat: 49.1541392, lng: 19.6742124 },
    { lat: 49.153774, lng: 19.6741862 },
    { lat: 49.153633, lng: 19.6742047 },
    { lat: 49.1534576, lng: 19.6739326 },
    { lat: 49.1533652, lng: 19.6738302 },
    { lat: 49.1530962, lng: 19.6739133 },
    { lat: 49.1530275, lng: 19.6737357 },
    { lat: 49.1529726, lng: 19.6735143 },
    { lat: 49.1529051, lng: 19.6732869 },
    { lat: 49.152694, lng: 19.6730707 },
    { lat: 49.1524825, lng: 19.6730025 },
    { lat: 49.1523178, lng: 19.6729194 },
    { lat: 49.1521347, lng: 19.6729321 },
    { lat: 49.1517755, lng: 19.6729402 },
    { lat: 49.1516656, lng: 19.6725697 },
    { lat: 49.1516353, lng: 19.6723651 },
    { lat: 49.1515696, lng: 19.6722085 },
    { lat: 49.1517306, lng: 19.6717971 },
    { lat: 49.1516703, lng: 19.6716835 },
    { lat: 49.1516242, lng: 19.6715547 },
    { lat: 49.1517479, lng: 19.6711702 },
    { lat: 49.1517214, lng: 19.6710293 },
    { lat: 49.1517715, lng: 19.6707859 },
    { lat: 49.1516792, lng: 19.6706845 },
    { lat: 49.1516747, lng: 19.6703312 },
    { lat: 49.1516796, lng: 19.670006 },
    { lat: 49.1515567, lng: 19.6695976 },
    { lat: 49.1514322, lng: 19.6694564 },
    { lat: 49.1514438, lng: 19.6692389 },
    { lat: 49.1513925, lng: 19.6690728 },
    { lat: 49.1514576, lng: 19.6688621 },
    { lat: 49.1514143, lng: 19.6688014 },
    { lat: 49.1514913, lng: 19.6685055 },
    { lat: 49.1513478, lng: 19.6681917 },
    { lat: 49.1512123, lng: 19.6680523 },
    { lat: 49.1512403, lng: 19.6677939 },
    { lat: 49.151107, lng: 19.6675093 },
    { lat: 49.1511504, lng: 19.6674444 },
    { lat: 49.1511291, lng: 19.6671921 },
    { lat: 49.1510008, lng: 19.6671207 },
    { lat: 49.1509544, lng: 19.6669635 },
    { lat: 49.1507833, lng: 19.666789 },
    { lat: 49.1508299, lng: 19.6664919 },
    { lat: 49.1507931, lng: 19.6662747 },
    { lat: 49.1506707, lng: 19.6660808 },
    { lat: 49.150773, lng: 19.6658215 },
    { lat: 49.1507671, lng: 19.6656616 },
    { lat: 49.1508294, lng: 19.6654648 },
    { lat: 49.1507675, lng: 19.6653877 },
    { lat: 49.1506665, lng: 19.6653247 },
    { lat: 49.1505996, lng: 19.6651592 },
    { lat: 49.1504565, lng: 19.6651354 },
    { lat: 49.1503788, lng: 19.66488 },
    { lat: 49.1502825, lng: 19.6648165 },
    { lat: 49.1500508, lng: 19.664877 },
    { lat: 49.1499868, lng: 19.6646796 },
    { lat: 49.1497177, lng: 19.6645579 },
    { lat: 49.1495878, lng: 19.6645994 },
    { lat: 49.1494862, lng: 19.6640873 },
    { lat: 49.1493307, lng: 19.664008 },
    { lat: 49.1492881, lng: 19.6640393 },
    { lat: 49.1491842, lng: 19.663756 },
    { lat: 49.1489758, lng: 19.6636558 },
    { lat: 49.1489223, lng: 19.6637434 },
    { lat: 49.1487723, lng: 19.6636613 },
    { lat: 49.1485104, lng: 19.663548 },
    { lat: 49.1483789, lng: 19.6637864 },
    { lat: 49.1481326, lng: 19.6637157 },
    { lat: 49.1480536, lng: 19.6635641 },
    { lat: 49.1477974, lng: 19.6635243 },
    { lat: 49.1475443, lng: 19.6636104 },
    { lat: 49.1473219, lng: 19.6634966 },
    { lat: 49.1471534, lng: 19.6635093 },
    { lat: 49.1468593, lng: 19.6632553 },
    { lat: 49.1466457, lng: 19.6632129 },
    { lat: 49.146567, lng: 19.6633448 },
    { lat: 49.1463601, lng: 19.6634809 },
    { lat: 49.1460659, lng: 19.6634792 },
    { lat: 49.1458695, lng: 19.663747 },
    { lat: 49.1455119, lng: 19.6638641 },
    { lat: 49.1454457, lng: 19.6636932 },
    { lat: 49.14505, lng: 19.6635105 },
    { lat: 49.1450049, lng: 19.6636116 },
    { lat: 49.1448103, lng: 19.6633869 },
    { lat: 49.144394, lng: 19.6632251 },
    { lat: 49.1440538, lng: 19.6632781 },
    { lat: 49.1440058, lng: 19.6631046 },
    { lat: 49.1438797, lng: 19.6631852 },
    { lat: 49.1437434, lng: 19.6631622 },
    { lat: 49.1435615, lng: 19.6629722 },
    { lat: 49.1433748, lng: 19.6627507 },
    { lat: 49.1431253, lng: 19.6627142 },
    { lat: 49.1430554, lng: 19.6626332 },
    { lat: 49.1430852, lng: 19.6624171 },
    { lat: 49.1430039, lng: 19.6622869 },
    { lat: 49.142975, lng: 19.662268 },
    { lat: 49.1428344, lng: 19.662222 },
    { lat: 49.1426639, lng: 19.6622641 },
    { lat: 49.1424868, lng: 19.6624648 },
    { lat: 49.1422023, lng: 19.6624484 },
    { lat: 49.1420793, lng: 19.6621185 },
    { lat: 49.1417756, lng: 19.6618248 },
    { lat: 49.1416057, lng: 19.661878 },
    { lat: 49.1413278, lng: 19.6621646 },
    { lat: 49.1412339, lng: 19.6620825 },
    { lat: 49.1410893, lng: 19.6619729 },
    { lat: 49.1410868, lng: 19.6618634 },
    { lat: 49.1411101, lng: 19.6617804 },
    { lat: 49.1410723, lng: 19.6616908 },
    { lat: 49.1409636, lng: 19.6616768 },
    { lat: 49.1408392, lng: 19.6614108 },
    { lat: 49.1407782, lng: 19.6614088 },
    { lat: 49.1407056, lng: 19.6615983 },
    { lat: 49.1404794, lng: 19.6618588 },
    { lat: 49.1403208, lng: 19.6617639 },
    { lat: 49.1401863, lng: 19.661754 },
    { lat: 49.1401536, lng: 19.6614753 },
    { lat: 49.1400138, lng: 19.6612929 },
    { lat: 49.1398049, lng: 19.6612363 },
    { lat: 49.1397073, lng: 19.661142 },
    { lat: 49.1396444, lng: 19.6610186 },
    { lat: 49.1395421, lng: 19.6609738 },
    { lat: 49.1393856, lng: 19.661028 },
    { lat: 49.1392438, lng: 19.6611574 },
    { lat: 49.1390692, lng: 19.6610531 },
    { lat: 49.138884, lng: 19.6611992 },
    { lat: 49.1388791, lng: 19.6612109 },
    { lat: 49.1388381, lng: 19.6611287 },
    { lat: 49.1386815, lng: 19.6609232 },
    { lat: 49.1385369, lng: 19.6608158 },
    { lat: 49.1384802, lng: 19.6607295 },
    { lat: 49.1383754, lng: 19.6606558 },
    { lat: 49.1382963, lng: 19.6604948 },
    { lat: 49.1381922, lng: 19.6604375 },
    { lat: 49.1380892, lng: 19.6602711 },
    { lat: 49.1379948, lng: 19.6600473 },
    { lat: 49.137913, lng: 19.6599806 },
    { lat: 49.1378328, lng: 19.6599784 },
    { lat: 49.1377049, lng: 19.6600895 },
    { lat: 49.1373906, lng: 19.6601157 },
    { lat: 49.1372345, lng: 19.6599501 },
    { lat: 49.1370911, lng: 19.6596648 },
    { lat: 49.1369866, lng: 19.6595918 },
    { lat: 49.1368064, lng: 19.6593686 },
    { lat: 49.1365942, lng: 19.6591559 },
    { lat: 49.1364411, lng: 19.6590785 },
    { lat: 49.136341, lng: 19.658956 },
    { lat: 49.136094, lng: 19.658726 },
    { lat: 49.1359548, lng: 19.6585587 },
    { lat: 49.1358807, lng: 19.658485 },
    { lat: 49.1358568, lng: 19.6583281 },
    { lat: 49.1358144, lng: 19.6579852 },
    { lat: 49.1357063, lng: 19.6578043 },
    { lat: 49.1356015, lng: 19.6577057 },
    { lat: 49.1355205, lng: 19.6576079 },
    { lat: 49.1353517, lng: 19.6576361 },
    { lat: 49.135194, lng: 19.6577153 },
    { lat: 49.1349416, lng: 19.6576338 },
    { lat: 49.1347723, lng: 19.6576545 },
    { lat: 49.1346541, lng: 19.6576044 },
    { lat: 49.1345886, lng: 19.6574997 },
    { lat: 49.1344868, lng: 19.6573673 },
    { lat: 49.1343635, lng: 19.6573012 },
    { lat: 49.134136, lng: 19.6572283 },
    { lat: 49.1340846, lng: 19.6571131 },
    { lat: 49.1341198, lng: 19.6568896 },
    { lat: 49.1340573, lng: 19.6567003 },
    { lat: 49.1340669, lng: 19.6564867 },
    { lat: 49.134062, lng: 19.6564764 },
    { lat: 49.1339331, lng: 19.6565138 },
    { lat: 49.1338133, lng: 19.6565326 },
    { lat: 49.133686, lng: 19.6564306 },
    { lat: 49.1335348, lng: 19.6564016 },
    { lat: 49.1334313, lng: 19.6564782 },
    { lat: 49.1333212, lng: 19.6564614 },
    { lat: 49.1331839, lng: 19.6559582 },
    { lat: 49.1331281, lng: 19.6555628 },
    { lat: 49.1330849, lng: 19.6554781 },
    { lat: 49.1329909, lng: 19.6554417 },
    { lat: 49.1329821, lng: 19.6554498 },
    { lat: 49.13291, lng: 19.6556757 },
    { lat: 49.1328313, lng: 19.6557071 },
    { lat: 49.1328235, lng: 19.6557058 },
    { lat: 49.1328152, lng: 19.6556995 },
    { lat: 49.1327575, lng: 19.6555388 },
    { lat: 49.1327657, lng: 19.6554722 },
    { lat: 49.1327639, lng: 19.6554529 },
    { lat: 49.1327609, lng: 19.6554395 },
    { lat: 49.1324159, lng: 19.6552309 },
    { lat: 49.1323115, lng: 19.655442 },
    { lat: 49.1322, lng: 19.6556464 },
    { lat: 49.1320874, lng: 19.6557213 },
    { lat: 49.1319761, lng: 19.6556238 },
    { lat: 49.131686, lng: 19.6554612 },
    { lat: 49.1316124, lng: 19.6552746 },
    { lat: 49.1314877, lng: 19.6551838 },
    { lat: 49.1313186, lng: 19.6552574 },
    { lat: 49.1312968, lng: 19.6552236 },
    { lat: 49.1311784, lng: 19.6551466 },
    { lat: 49.1311311, lng: 19.6550709 },
    { lat: 49.1311216, lng: 19.6547017 },
    { lat: 49.1311005, lng: 19.6546093 },
    { lat: 49.1308796, lng: 19.6543241 },
    { lat: 49.1306944, lng: 19.6541637 },
    { lat: 49.13046, lng: 19.6540091 },
    { lat: 49.1303989, lng: 19.6540046 },
    { lat: 49.1302284, lng: 19.6540722 },
    { lat: 49.1300807, lng: 19.6540957 },
    { lat: 49.1300418, lng: 19.6540339 },
    { lat: 49.130025, lng: 19.6539842 },
    { lat: 49.1300101, lng: 19.6537997 },
    { lat: 49.1299052, lng: 19.6535425 },
    { lat: 49.1298125, lng: 19.6534122 },
    { lat: 49.129715, lng: 19.6533211 },
    { lat: 49.1295361, lng: 19.6533752 },
    { lat: 49.1294045, lng: 19.6533838 },
    { lat: 49.1291365, lng: 19.6535093 },
    { lat: 49.1290716, lng: 19.6535217 },
    { lat: 49.1288885, lng: 19.6534057 },
    { lat: 49.1288238, lng: 19.6532689 },
    { lat: 49.1286403, lng: 19.6529698 },
    { lat: 49.1285435, lng: 19.6526675 },
    { lat: 49.1285125, lng: 19.6526006 },
    { lat: 49.1284382, lng: 19.6525025 },
    { lat: 49.1284233, lng: 19.6524961 },
    { lat: 49.1284099, lng: 19.6524967 },
    { lat: 49.1284006, lng: 19.6525157 },
    { lat: 49.1283854, lng: 19.6526036 },
    { lat: 49.1283363, lng: 19.6526144 },
    { lat: 49.1282503, lng: 19.6528892 },
    { lat: 49.1277344, lng: 19.6543321 },
    { lat: 49.1268111, lng: 19.6566768 },
    { lat: 49.1265264, lng: 19.6563321 },
    { lat: 49.1256917, lng: 19.6551422 },
    { lat: 49.125436, lng: 19.6556995 },
    { lat: 49.1254034, lng: 19.6561353 },
    { lat: 49.1254577, lng: 19.6563693 },
    { lat: 49.1255536, lng: 19.6565723 },
    { lat: 49.1257231, lng: 19.6568376 },
    { lat: 49.1259541, lng: 19.6571915 },
    { lat: 49.1263099, lng: 19.6577451 },
    { lat: 49.1265617, lng: 19.6581408 },
    { lat: 49.1266576, lng: 19.6582967 },
    { lat: 49.1268477, lng: 19.6584401 },
    { lat: 49.1268578, lng: 19.658439 },
    { lat: 49.1270074, lng: 19.6585592 },
    { lat: 49.1270152, lng: 19.6585609 },
    { lat: 49.1273159, lng: 19.6589095 },
    { lat: 49.1269954, lng: 19.6597087 },
    { lat: 49.127316, lng: 19.6602365 },
    { lat: 49.1272864, lng: 19.6603032 },
    { lat: 49.1270484, lng: 19.661058 },
    { lat: 49.1269073, lng: 19.6614848 },
    { lat: 49.1259421, lng: 19.6602216 },
    { lat: 49.1251397, lng: 19.6591766 },
    { lat: 49.1242439, lng: 19.6579826 },
    { lat: 49.1234844, lng: 19.6569883 },
    { lat: 49.1231468, lng: 19.6565425 },
    { lat: 49.1226653, lng: 19.6559231 },
    { lat: 49.1226162, lng: 19.6558548 },
    { lat: 49.1221039, lng: 19.6552069 },
    { lat: 49.1220209, lng: 19.6550853 },
    { lat: 49.1217988, lng: 19.6548053 },
    { lat: 49.1217105, lng: 19.6549972 },
    { lat: 49.1214381, lng: 19.6556361 },
    { lat: 49.1210584, lng: 19.6565464 },
    { lat: 49.1209485, lng: 19.656816 },
    { lat: 49.1207618, lng: 19.6565676 },
    { lat: 49.1204447, lng: 19.6562989 },
    { lat: 49.1202061, lng: 19.6561789 },
    { lat: 49.1200025, lng: 19.6560863 },
    { lat: 49.1199399, lng: 19.6560427 },
    { lat: 49.1194253, lng: 19.6556196 },
    { lat: 49.1192319, lng: 19.6554748 },
    { lat: 49.1191333, lng: 19.6554145 },
    { lat: 49.1186329, lng: 19.6550827 },
    { lat: 49.1182811, lng: 19.6548212 },
    { lat: 49.1185007, lng: 19.6546154 },
    { lat: 49.1183641, lng: 19.6545583 },
    { lat: 49.1182961, lng: 19.6545995 },
    { lat: 49.1180317, lng: 19.6547329 },
    { lat: 49.117751, lng: 19.654883 },
    { lat: 49.1175761, lng: 19.6548617 },
    { lat: 49.1174745, lng: 19.6548493 },
    { lat: 49.1173763, lng: 19.6546638 },
    { lat: 49.1170896, lng: 19.6545028 },
    { lat: 49.1169692, lng: 19.6544019 },
    { lat: 49.1165999, lng: 19.6540282 },
    { lat: 49.1163785, lng: 19.6537849 },
    { lat: 49.1160754, lng: 19.6535852 },
    { lat: 49.1160085, lng: 19.6534224 },
    { lat: 49.1160106, lng: 19.6532693 },
    { lat: 49.116045, lng: 19.6531827 },
    { lat: 49.1160184, lng: 19.6529617 },
    { lat: 49.1159414, lng: 19.6527269 },
    { lat: 49.1158452, lng: 19.6526397 },
    { lat: 49.115572, lng: 19.6524467 },
    { lat: 49.1154006, lng: 19.652267 },
    { lat: 49.1152261, lng: 19.6521451 },
    { lat: 49.1151376, lng: 19.6520067 },
    { lat: 49.1150323, lng: 19.6519165 },
    { lat: 49.1146657, lng: 19.651731 },
    { lat: 49.1144874, lng: 19.6516725 },
    { lat: 49.1143372, lng: 19.6517124 },
    { lat: 49.1142923, lng: 19.6518987 },
    { lat: 49.1139968, lng: 19.6532059 },
    { lat: 49.1138345, lng: 19.6539405 },
    { lat: 49.1134475, lng: 19.6556499 },
    { lat: 49.1133407, lng: 19.6561108 },
    { lat: 49.1130635, lng: 19.6574046 },
    { lat: 49.1128336, lng: 19.6584422 },
    { lat: 49.1124095, lng: 19.6603858 },
    { lat: 49.11213, lng: 19.6616726 },
    { lat: 49.1117897, lng: 19.6632224 },
    { lat: 49.111591, lng: 19.6641513 },
    { lat: 49.1113409, lng: 19.6652922 },
    { lat: 49.1115964, lng: 19.6652612 },
    { lat: 49.1118029, lng: 19.6654989 },
    { lat: 49.111886, lng: 19.6654663 },
    { lat: 49.1119925, lng: 19.6655053 },
    { lat: 49.1121115, lng: 19.6656225 },
    { lat: 49.112149, lng: 19.6656539 },
    { lat: 49.1121912, lng: 19.6655413 },
    { lat: 49.1122351, lng: 19.6655871 },
    { lat: 49.1123201, lng: 19.6657535 },
    { lat: 49.1124341, lng: 19.6657842 },
    { lat: 49.1125806, lng: 19.6659094 },
    { lat: 49.1125559, lng: 19.6660925 },
    { lat: 49.1125612, lng: 19.6661113 },
    { lat: 49.1126809, lng: 19.6660904 },
    { lat: 49.1128434, lng: 19.6661981 },
    { lat: 49.1129059, lng: 19.6662873 },
    { lat: 49.1129333, lng: 19.6663453 },
    { lat: 49.1131309, lng: 19.6666119 },
    { lat: 49.113198, lng: 19.6669014 },
    { lat: 49.1132038, lng: 19.666909 },
    { lat: 49.1132942, lng: 19.666835 },
    { lat: 49.1134412, lng: 19.6669015 },
    { lat: 49.1134547, lng: 19.6670515 },
    { lat: 49.1135072, lng: 19.6672173 },
    { lat: 49.1136357, lng: 19.6672947 },
    { lat: 49.1137912, lng: 19.6671964 },
    { lat: 49.1139542, lng: 19.6672163 },
    { lat: 49.1141827, lng: 19.667183 },
    { lat: 49.1142007, lng: 19.6672855 },
    { lat: 49.1141322, lng: 19.667417 },
    { lat: 49.1140958, lng: 19.667561 },
    { lat: 49.1141409, lng: 19.6676872 },
    { lat: 49.1143406, lng: 19.6675922 },
    { lat: 49.114409, lng: 19.6676866 },
    { lat: 49.1144152, lng: 19.6678262 },
    { lat: 49.114364, lng: 19.6680444 },
    { lat: 49.1144117, lng: 19.6681291 },
    { lat: 49.1145482, lng: 19.6681319 },
    { lat: 49.1146551, lng: 19.6681839 },
    { lat: 49.1147498, lng: 19.6681565 },
    { lat: 49.1148245, lng: 19.6681165 },
    { lat: 49.1148525, lng: 19.6681618 },
    { lat: 49.114853, lng: 19.6683027 },
    { lat: 49.1148776, lng: 19.6683976 },
    { lat: 49.1150187, lng: 19.6683752 },
    { lat: 49.1150762, lng: 19.6684042 },
    { lat: 49.1151301, lng: 19.6686525 },
    { lat: 49.1150886, lng: 19.6688611 },
    { lat: 49.1150932, lng: 19.6689369 },
    { lat: 49.1152632, lng: 19.6691143 },
    { lat: 49.1152836, lng: 19.6693452 },
    { lat: 49.1153685, lng: 19.6694347 },
    { lat: 49.1154569, lng: 19.6694472 },
    { lat: 49.1155047, lng: 19.6693808 },
    { lat: 49.1154965, lng: 19.6692705 },
    { lat: 49.1155719, lng: 19.6692447 },
    { lat: 49.1156168, lng: 19.6693142 },
    { lat: 49.1156935, lng: 19.6695699 },
    { lat: 49.1158485, lng: 19.6699147 },
    { lat: 49.1159317, lng: 19.670013 },
    { lat: 49.1159077, lng: 19.6701309 },
    { lat: 49.1159654, lng: 19.6703418 },
    { lat: 49.1160011, lng: 19.6704382 },
    { lat: 49.1161298, lng: 19.6704163 },
    { lat: 49.1162109, lng: 19.6704957 },
    { lat: 49.1162122, lng: 19.6706732 },
    { lat: 49.1161863, lng: 19.6707486 },
    { lat: 49.1161856, lng: 19.6708357 },
    { lat: 49.1162515, lng: 19.6708738 },
    { lat: 49.1163474, lng: 19.6709053 },
    { lat: 49.1163624, lng: 19.6709095 },
    { lat: 49.1164182, lng: 19.6709747 },
    { lat: 49.1164347, lng: 19.6710708 },
    { lat: 49.1164204, lng: 19.6711525 },
    { lat: 49.1164376, lng: 19.6713366 },
    { lat: 49.1165275, lng: 19.6714335 },
    { lat: 49.1166443, lng: 19.6716545 },
    { lat: 49.116611, lng: 19.6717655 },
    { lat: 49.1166207, lng: 19.6719105 },
    { lat: 49.1168422, lng: 19.6723589 },
    { lat: 49.1170188, lng: 19.6728342 },
    { lat: 49.1170753, lng: 19.6728892 },
    { lat: 49.1171743, lng: 19.6729876 },
    { lat: 49.1172557, lng: 19.6731997 },
    { lat: 49.1173252, lng: 19.6733408 },
    { lat: 49.1175617, lng: 19.6735952 },
    { lat: 49.1177627, lng: 19.6737835 },
    { lat: 49.1177692, lng: 19.6739026 },
    { lat: 49.1178195, lng: 19.6740003 },
    { lat: 49.1178806, lng: 19.6740041 },
    { lat: 49.1179239, lng: 19.6740374 },
    { lat: 49.1179659, lng: 19.6741766 },
    { lat: 49.1180437, lng: 19.6743017 },
    { lat: 49.1181306, lng: 19.6742539 },
    { lat: 49.1182274, lng: 19.6743296 },
    { lat: 49.1182557, lng: 19.6744079 },
    { lat: 49.1182443, lng: 19.6746098 },
    { lat: 49.1182744, lng: 19.6747013 },
    { lat: 49.1183199, lng: 19.6747642 },
    { lat: 49.118524, lng: 19.6747206 },
    { lat: 49.1185417, lng: 19.6748105 },
    { lat: 49.1184542, lng: 19.6749979 },
    { lat: 49.1184623, lng: 19.6750546 },
    { lat: 49.1185105, lng: 19.6750964 },
    { lat: 49.1185606, lng: 19.6750884 },
    { lat: 49.1185814, lng: 19.6750999 },
    { lat: 49.1186231, lng: 19.6751233 },
    { lat: 49.1186883, lng: 19.6751191 },
    { lat: 49.1187248, lng: 19.6751805 },
    { lat: 49.1187169, lng: 19.675386 },
    { lat: 49.1187525, lng: 19.6754492 },
    { lat: 49.1189, lng: 19.6754484 },
    { lat: 49.1189788, lng: 19.6757029 },
    { lat: 49.1189929, lng: 19.6757122 },
    { lat: 49.1190656, lng: 19.675681 },
    { lat: 49.1191142, lng: 19.6757059 },
    { lat: 49.1191065, lng: 19.6758362 },
    { lat: 49.1191445, lng: 19.6759858 },
    { lat: 49.1192775, lng: 19.6762387 },
    { lat: 49.1192702, lng: 19.676356 },
    { lat: 49.1193857, lng: 19.6764678 },
    { lat: 49.1194958, lng: 19.6766677 },
    { lat: 49.119606, lng: 19.6767362 },
    { lat: 49.1196634, lng: 19.6768393 },
    { lat: 49.1197753, lng: 19.6768961 },
    { lat: 49.1199074, lng: 19.6772832 },
    { lat: 49.119984, lng: 19.677462 },
    { lat: 49.1200085, lng: 19.6776113 },
    { lat: 49.1199959, lng: 19.6777579 },
    { lat: 49.1200223, lng: 19.6778685 },
    { lat: 49.1202709, lng: 19.6781944 },
    { lat: 49.1203595, lng: 19.6782601 },
    { lat: 49.1204402, lng: 19.6784565 },
    { lat: 49.120542, lng: 19.6785172 },
    { lat: 49.1207797, lng: 19.6788942 },
    { lat: 49.1209238, lng: 19.6792765 },
    { lat: 49.121003, lng: 19.6794115 },
    { lat: 49.1211502, lng: 19.6796335 },
    { lat: 49.1212362, lng: 19.6797958 },
    { lat: 49.1213906, lng: 19.6798214 },
    { lat: 49.1214191, lng: 19.6798803 },
    { lat: 49.1214253, lng: 19.6800199 },
    { lat: 49.1213961, lng: 19.680224 },
    { lat: 49.1214109, lng: 19.6802815 },
    { lat: 49.1214163, lng: 19.6803021 },
    { lat: 49.1213961, lng: 19.6802985 },
    { lat: 49.121384, lng: 19.6804371 },
    { lat: 49.121368, lng: 19.6806089 },
    { lat: 49.1213567, lng: 19.680759 },
    { lat: 49.1213316, lng: 19.6811086 },
    { lat: 49.1212147, lng: 19.6826734 },
    { lat: 49.1211424, lng: 19.6847555 },
    { lat: 49.1212593, lng: 19.684955 },
    { lat: 49.1212768, lng: 19.6852003 },
    { lat: 49.121262, lng: 19.6854274 },
    { lat: 49.1212136, lng: 19.6857837 },
    { lat: 49.1211643, lng: 19.6860154 },
    { lat: 49.1210666, lng: 19.6863596 },
    { lat: 49.1209873, lng: 19.6866252 },
    { lat: 49.1208457, lng: 19.6869696 },
    { lat: 49.1208193, lng: 19.6870352 },
    { lat: 49.1207288, lng: 19.6872297 },
    { lat: 49.1205983, lng: 19.6874877 },
    { lat: 49.1216077, lng: 19.6884539 },
    { lat: 49.1216557, lng: 19.6884994 },
    { lat: 49.1225243, lng: 19.6893132 },
    { lat: 49.1235952, lng: 19.6903264 },
    { lat: 49.1246818, lng: 19.6913579 },
    { lat: 49.1251743, lng: 19.6918243 },
    { lat: 49.1249364, lng: 19.69243 },
    { lat: 49.12492, lng: 19.6924712 },
    { lat: 49.124909, lng: 19.6924997 },
    { lat: 49.1249106, lng: 19.69261 },
    { lat: 49.1260054, lng: 19.692582 },
    { lat: 49.1262656, lng: 19.6926046 },
    { lat: 49.1275417, lng: 19.6927247 },
    { lat: 49.1288618, lng: 19.6928262 },
    { lat: 49.1292653, lng: 19.6929166 },
    { lat: 49.1297996, lng: 19.6930363 },
    { lat: 49.1317323, lng: 19.6934121 },
    { lat: 49.1327628, lng: 19.693734 },
    { lat: 49.1333393, lng: 19.6941993 },
    { lat: 49.1334135, lng: 19.6942592 },
    { lat: 49.1342609, lng: 19.6953115 },
    { lat: 49.136703, lng: 19.6987072 },
    { lat: 49.1379161, lng: 19.6998539 },
    { lat: 49.139167, lng: 19.7005542 },
    { lat: 49.1403041, lng: 19.7012032 },
    { lat: 49.1415369, lng: 19.7012902 },
    { lat: 49.1429753, lng: 19.7010538 },
    { lat: 49.1436564, lng: 19.7006574 },
    { lat: 49.1438473, lng: 19.700671 },
    { lat: 49.1442804, lng: 19.7009656 },
    { lat: 49.1450465, lng: 19.701169 },
    { lat: 49.1455773, lng: 19.7016077 },
    { lat: 49.1455752, lng: 19.7014849 },
    { lat: 49.1456576, lng: 19.7012796 },
    { lat: 49.1456766, lng: 19.7012305 },
    { lat: 49.1458679, lng: 19.7007601 },
    { lat: 49.1459125, lng: 19.7006465 },
    { lat: 49.1460201, lng: 19.7011493 },
    { lat: 49.1460718, lng: 19.7012243 },
    { lat: 49.1461766, lng: 19.7013266 },
    { lat: 49.1463565, lng: 19.7013701 },
    { lat: 49.1468393, lng: 19.7020998 },
    { lat: 49.1470933, lng: 19.7027007 },
    { lat: 49.1473304, lng: 19.7030943 },
    { lat: 49.147559, lng: 19.7033257 },
    { lat: 49.1479685, lng: 19.7035929 },
    { lat: 49.1483591, lng: 19.7038697 },
    { lat: 49.1485796, lng: 19.7041444 },
    { lat: 49.1487314, lng: 19.704262 },
    { lat: 49.1490041, lng: 19.704318 },
    { lat: 49.1491688, lng: 19.7046308 },
    { lat: 49.149374, lng: 19.7048639 },
    { lat: 49.149827, lng: 19.7051203 },
    { lat: 49.150337, lng: 19.7058318 },
    { lat: 49.150785, lng: 19.706002 },
    { lat: 49.1510044, lng: 19.7062308 },
    { lat: 49.1511993, lng: 19.7066906 },
    { lat: 49.1513435, lng: 19.7072528 },
    { lat: 49.1514893, lng: 19.7073905 },
    { lat: 49.1523003, lng: 19.7091593 },
    { lat: 49.1522856, lng: 19.709412 },
    { lat: 49.1523602, lng: 19.7097272 },
    { lat: 49.1528294, lng: 19.7112521 },
    { lat: 49.153279, lng: 19.7119757 },
    { lat: 49.1536876, lng: 19.712174 },
    { lat: 49.1539333, lng: 19.7125855 },
    { lat: 49.1541579, lng: 19.7126688 },
    { lat: 49.1544174, lng: 19.7137296 },
    { lat: 49.1547038, lng: 19.7142744 },
    { lat: 49.1554306, lng: 19.7147956 },
    { lat: 49.1558649, lng: 19.7147545 },
    { lat: 49.1561255, lng: 19.7149158 },
    { lat: 49.157031, lng: 19.7145541 },
    { lat: 49.1572092, lng: 19.7144042 },
    { lat: 49.1573755, lng: 19.7144985 },
    { lat: 49.1580119, lng: 19.7146477 },
    { lat: 49.1584423, lng: 19.7144166 },
    { lat: 49.1587381, lng: 19.7147392 },
    { lat: 49.1588353, lng: 19.7147452 },
    { lat: 49.1590429, lng: 19.7147581 },
    { lat: 49.1594541, lng: 19.7137569 },
    { lat: 49.1598426, lng: 19.7135991 },
    { lat: 49.1600474, lng: 19.7133389 },
    { lat: 49.1602192, lng: 19.713321 },
    { lat: 49.1605315, lng: 19.7133532 },
    { lat: 49.160867, lng: 19.7135309 },
    { lat: 49.1609798, lng: 19.7134273 },
    { lat: 49.1613862, lng: 19.7126784 },
    { lat: 49.16172, lng: 19.7125605 },
    { lat: 49.1619587, lng: 19.7125073 },
    { lat: 49.1621546, lng: 19.7123995 },
    { lat: 49.1623644, lng: 19.7125095 },
    { lat: 49.1626745, lng: 19.7125131 },
    { lat: 49.162891, lng: 19.7123895 },
    { lat: 49.1633817, lng: 19.7125615 },
    { lat: 49.1637241, lng: 19.7126111 },
    { lat: 49.1639348, lng: 19.7125327 },
    { lat: 49.1642892, lng: 19.7125736 },
    { lat: 49.1645288, lng: 19.7126153 },
    { lat: 49.1646951, lng: 19.7125588 },
    { lat: 49.1650327, lng: 19.712597 },
    { lat: 49.1655564, lng: 19.7119539 },
    { lat: 49.1656369, lng: 19.7119176 },
    { lat: 49.1657675, lng: 19.7119658 },
    { lat: 49.1658632, lng: 19.7120392 },
    { lat: 49.166028, lng: 19.7122261 },
    { lat: 49.1663451, lng: 19.7122387 },
    { lat: 49.1664117, lng: 19.7122406 },
    { lat: 49.1669859, lng: 19.7123659 },
    { lat: 49.1673688, lng: 19.7124132 },
    { lat: 49.1677184, lng: 19.7122684 },
    { lat: 49.1678214, lng: 19.7123281 },
    { lat: 49.1679358, lng: 19.7123424 },
    { lat: 49.1682431, lng: 19.7123369 },
    { lat: 49.1683191, lng: 19.7124267 },
    { lat: 49.168595, lng: 19.7128342 },
    { lat: 49.1688213, lng: 19.7131664 },
    { lat: 49.1689402, lng: 19.7132298 },
    { lat: 49.1694411, lng: 19.7132442 },
    { lat: 49.1695187, lng: 19.7131891 },
    { lat: 49.1698336, lng: 19.7133611 },
    { lat: 49.1703164, lng: 19.7134 },
    { lat: 49.1706465, lng: 19.7134027 },
    { lat: 49.1710262, lng: 19.7130655 },
    { lat: 49.1717677, lng: 19.7130229 },
    { lat: 49.1723634, lng: 19.7130672 },
    { lat: 49.1725693, lng: 19.7131931 },
    { lat: 49.1727091, lng: 19.7136778 },
    { lat: 49.1729926, lng: 19.714032 },
    { lat: 49.1741704, lng: 19.7141742 },
    { lat: 49.1747852, lng: 19.7150372 },
    { lat: 49.1753494, lng: 19.7150321 },
    { lat: 49.1755843, lng: 19.7152523 },
    { lat: 49.175944, lng: 19.7150784 },
    { lat: 49.1764934, lng: 19.7155869 },
    { lat: 49.176848, lng: 19.7160177 },
    { lat: 49.177066, lng: 19.7161334 },
    { lat: 49.177699, lng: 19.7164368 },
    { lat: 49.1780773, lng: 19.7165116 },
    { lat: 49.1782918, lng: 19.7166496 },
    { lat: 49.178766, lng: 19.7171107 },
    { lat: 49.1791969, lng: 19.7174557 },
    { lat: 49.1793075, lng: 19.7175899 },
    { lat: 49.1793574, lng: 19.7177261 },
    { lat: 49.1795113, lng: 19.7180761 },
    { lat: 49.1798626, lng: 19.7185883 },
    { lat: 49.1801199, lng: 19.7189593 },
    { lat: 49.1803896, lng: 19.7193019 },
    { lat: 49.1805029, lng: 19.7194668 },
    { lat: 49.1807208, lng: 19.7198674 },
    { lat: 49.1807546, lng: 19.7199435 },
    { lat: 49.1809026, lng: 19.7204642 },
    { lat: 49.1812196, lng: 19.720966 },
    { lat: 49.1815935, lng: 19.7215573 },
    { lat: 49.1820447, lng: 19.722444 },
    { lat: 49.1821468, lng: 19.7229212 },
    { lat: 49.1822572, lng: 19.7234638 },
    { lat: 49.1824697, lng: 19.7241494 },
    { lat: 49.1827313, lng: 19.7250569 },
    { lat: 49.1829795, lng: 19.7255754 },
    { lat: 49.1833245, lng: 19.7262802 },
    { lat: 49.183362, lng: 19.7264144 },
    { lat: 49.1835428, lng: 19.7270431 },
    { lat: 49.1836315, lng: 19.7272213 },
    { lat: 49.1837238, lng: 19.7273455 },
    { lat: 49.183826, lng: 19.7275721 },
    { lat: 49.1838327, lng: 19.7276211 },
    { lat: 49.1837858, lng: 19.7280656 },
    { lat: 49.1836592, lng: 19.7285232 },
    { lat: 49.1837855, lng: 19.7290617 },
    { lat: 49.1839853, lng: 19.7295614 },
    { lat: 49.1843536, lng: 19.7310299 },
    { lat: 49.184371, lng: 19.7312511 },
    { lat: 49.1843703, lng: 19.7313595 },
    { lat: 49.1843133, lng: 19.7322466 },
    { lat: 49.1844009, lng: 19.7329388 },
    { lat: 49.18435, lng: 19.7334968 },
    { lat: 49.1844254, lng: 19.7340401 },
    { lat: 49.1844408, lng: 19.7342892 },
    { lat: 49.1844623, lng: 19.7343436 },
    { lat: 49.1848191, lng: 19.7348752 },
    { lat: 49.1850036, lng: 19.7353876 },
    { lat: 49.1851716, lng: 19.7356712 },
    { lat: 49.185267, lng: 19.7358719 },
    { lat: 49.1854187, lng: 19.7362483 },
    { lat: 49.1858058, lng: 19.7364761 },
    { lat: 49.1858582, lng: 19.7365406 },
    { lat: 49.1863023, lng: 19.7372392 },
    { lat: 49.186357, lng: 19.7374861 },
    { lat: 49.1865441, lng: 19.7376669 },
    { lat: 49.1869082, lng: 19.7384215 },
    { lat: 49.1869315, lng: 19.7385164 },
    { lat: 49.1869589, lng: 19.73855 },
    { lat: 49.1873353, lng: 19.7387646 },
    { lat: 49.1874234, lng: 19.7388935 },
    { lat: 49.1875696, lng: 19.7390688 },
    { lat: 49.1876656, lng: 19.7392082 },
    { lat: 49.1876825, lng: 19.7392598 },
    { lat: 49.1876986, lng: 19.7395477 },
    { lat: 49.1878349, lng: 19.7397564 },
    { lat: 49.1882809, lng: 19.7402202 },
    { lat: 49.1883624, lng: 19.740305 },
    { lat: 49.1886341, lng: 19.7409282 },
    { lat: 49.1888909, lng: 19.7411319 },
    { lat: 49.1889433, lng: 19.7412099 },
    { lat: 49.1889823, lng: 19.7412681 },
    { lat: 49.1890189, lng: 19.7413555 },
    { lat: 49.1890233, lng: 19.7414073 },
    { lat: 49.1891112, lng: 19.7417671 },
    { lat: 49.1892458, lng: 19.7424 },
    { lat: 49.1892587, lng: 19.7426464 },
    { lat: 49.1893335, lng: 19.7428906 },
    { lat: 49.189443, lng: 19.7430477 },
    { lat: 49.1894616, lng: 19.743089 },
    { lat: 49.1895171, lng: 19.7432998 },
    { lat: 49.1896075, lng: 19.7435107 },
    { lat: 49.1898262, lng: 19.7439581 },
    { lat: 49.1903823, lng: 19.7450823 },
    { lat: 49.191684, lng: 19.747582 },
    { lat: 49.1927496, lng: 19.7496202 },
    { lat: 49.1932701, lng: 19.7506818 },
  ],
  Vlachy: [
    { lat: 49.0910609, lng: 19.4655149 },
    { lat: 49.0909166, lng: 19.4653919 },
    { lat: 49.0905523, lng: 19.4655779 },
    { lat: 49.0894041, lng: 19.4658513 },
    { lat: 49.088555, lng: 19.4654678 },
    { lat: 49.0883469, lng: 19.4655533 },
    { lat: 49.0881516, lng: 19.4655167 },
    { lat: 49.088076, lng: 19.4655151 },
    { lat: 49.0877523, lng: 19.4654591 },
    { lat: 49.0871786, lng: 19.4654312 },
    { lat: 49.0864681, lng: 19.4654669 },
    { lat: 49.0864343, lng: 19.4653954 },
    { lat: 49.0858528, lng: 19.4655399 },
    { lat: 49.0853966, lng: 19.4654484 },
    { lat: 49.0848672, lng: 19.4655493 },
    { lat: 49.0847131, lng: 19.465459 },
    { lat: 49.0840515, lng: 19.4656034 },
    { lat: 49.0839372, lng: 19.4656141 },
    { lat: 49.0838807, lng: 19.465664 },
    { lat: 49.0837862, lng: 19.4657148 },
    { lat: 49.083614, lng: 19.4657885 },
    { lat: 49.0834901, lng: 19.4659653 },
    { lat: 49.0833347, lng: 19.4659183 },
    { lat: 49.0827464, lng: 19.4662077 },
    { lat: 49.0825099, lng: 19.4664044 },
    { lat: 49.082368, lng: 19.4666504 },
    { lat: 49.0819091, lng: 19.4670632 },
    { lat: 49.0812556, lng: 19.4676754 },
    { lat: 49.0810785, lng: 19.4680132 },
    { lat: 49.0808158, lng: 19.4683996 },
    { lat: 49.0805209, lng: 19.4689937 },
    { lat: 49.0803041, lng: 19.469716 },
    { lat: 49.0799431, lng: 19.4708078 },
    { lat: 49.0797273, lng: 19.4717758 },
    { lat: 49.0788121, lng: 19.4715989 },
    { lat: 49.0784789, lng: 19.4715104 },
    { lat: 49.077711, lng: 19.4713972 },
    { lat: 49.0771864, lng: 19.4711883 },
    { lat: 49.0767647, lng: 19.4708923 },
    { lat: 49.076502, lng: 19.4706428 },
    { lat: 49.0764996, lng: 19.4707135 },
    { lat: 49.0763982, lng: 19.474921 },
    { lat: 49.076345, lng: 19.4771447 },
    { lat: 49.0763428, lng: 19.4771946 },
    { lat: 49.0762952, lng: 19.4773614 },
    { lat: 49.0763027, lng: 19.4776223 },
    { lat: 49.0763017, lng: 19.4776245 },
    { lat: 49.0762883, lng: 19.4776986 },
    { lat: 49.0762135, lng: 19.4784229 },
    { lat: 49.0761622, lng: 19.4785613 },
    { lat: 49.0761412, lng: 19.4788842 },
    { lat: 49.0758537, lng: 19.4831923 },
    { lat: 49.0724785, lng: 19.4826687 },
    { lat: 49.0712611, lng: 19.4828099 },
    { lat: 49.0687962, lng: 19.4836564 },
    { lat: 49.0684888, lng: 19.4839802 },
    { lat: 49.068436, lng: 19.4847179 },
    { lat: 49.0684239, lng: 19.4849945 },
    { lat: 49.067462, lng: 19.4870148 },
    { lat: 49.0667295, lng: 19.4885553 },
    { lat: 49.0667237, lng: 19.4886048 },
    { lat: 49.0674055, lng: 19.4896082 },
    { lat: 49.0681384, lng: 19.4896434 },
    { lat: 49.0711315, lng: 19.4903119 },
    { lat: 49.0711908, lng: 19.4920986 },
    { lat: 49.0717171, lng: 19.4915064 },
    { lat: 49.0723467, lng: 19.4913535 },
    { lat: 49.0732361, lng: 19.4913104 },
    { lat: 49.0753356, lng: 19.4916841 },
    { lat: 49.0753462, lng: 19.4917699 },
    { lat: 49.0752548, lng: 19.4942438 },
    { lat: 49.0752507, lng: 19.4955352 },
    { lat: 49.07508, lng: 19.4982942 },
    { lat: 49.0749085, lng: 19.4998173 },
    { lat: 49.0747957, lng: 19.5007727 },
    { lat: 49.0745876, lng: 19.5034879 },
    { lat: 49.0744991, lng: 19.5046535 },
    { lat: 49.0744822, lng: 19.5055904 },
    { lat: 49.0744865, lng: 19.5060558 },
    { lat: 49.0745032, lng: 19.5082658 },
    { lat: 49.0744118, lng: 19.5114874 },
    { lat: 49.0756784, lng: 19.5112196 },
    { lat: 49.0783259, lng: 19.5114038 },
    { lat: 49.0802557, lng: 19.5109793 },
    { lat: 49.0802877, lng: 19.5109874 },
    { lat: 49.080321, lng: 19.5109774 },
    { lat: 49.0833045, lng: 19.5104028 },
    { lat: 49.0850483, lng: 19.5105901 },
    { lat: 49.0852274, lng: 19.510148 },
    { lat: 49.0853489, lng: 19.5099194 },
    { lat: 49.0855964, lng: 19.5096667 },
    { lat: 49.0858009, lng: 19.5095789 },
    { lat: 49.0858227, lng: 19.5095147 },
    { lat: 49.0859335, lng: 19.5095199 },
    { lat: 49.0861821, lng: 19.5093127 },
    { lat: 49.0863216, lng: 19.5092857 },
    { lat: 49.086304, lng: 19.5091499 },
    { lat: 49.086495, lng: 19.5089625 },
    { lat: 49.0865077, lng: 19.5087515 },
    { lat: 49.0866867, lng: 19.5088433 },
    { lat: 49.0868205, lng: 19.5087644 },
    { lat: 49.0867723, lng: 19.5085466 },
    { lat: 49.0870443, lng: 19.5084808 },
    { lat: 49.0871434, lng: 19.5083081 },
    { lat: 49.0871988, lng: 19.5083382 },
    { lat: 49.0872984, lng: 19.5081511 },
    { lat: 49.0874086, lng: 19.5080467 },
    { lat: 49.0874039, lng: 19.5079692 },
    { lat: 49.0876877, lng: 19.5079434 },
    { lat: 49.0877146, lng: 19.5080088 },
    { lat: 49.0877901, lng: 19.5079879 },
    { lat: 49.0878862, lng: 19.5080917 },
    { lat: 49.0879983, lng: 19.5079813 },
    { lat: 49.0880966, lng: 19.5080136 },
    { lat: 49.0881443, lng: 19.5078479 },
    { lat: 49.0883474, lng: 19.5078299 },
    { lat: 49.0883048, lng: 19.5075634 },
    { lat: 49.0891208, lng: 19.5071235 },
    { lat: 49.0894409, lng: 19.5070039 },
    { lat: 49.0894607, lng: 19.5069673 },
    { lat: 49.0900699, lng: 19.5067618 },
    { lat: 49.0902076, lng: 19.5067407 },
    { lat: 49.090231, lng: 19.5066138 },
    { lat: 49.0902792, lng: 19.5066342 },
    { lat: 49.0902956, lng: 19.5064765 },
    { lat: 49.0904994, lng: 19.5064207 },
    { lat: 49.0905963, lng: 19.5063249 },
    { lat: 49.0906777, lng: 19.5064787 },
    { lat: 49.0907466, lng: 19.5063848 },
    { lat: 49.0908162, lng: 19.5064504 },
    { lat: 49.0908102, lng: 19.5065919 },
    { lat: 49.0910683, lng: 19.5065146 },
    { lat: 49.0911407, lng: 19.5065954 },
    { lat: 49.0912514, lng: 19.5065541 },
    { lat: 49.0912616, lng: 19.5064046 },
    { lat: 49.0913745, lng: 19.5064893 },
    { lat: 49.0914281, lng: 19.5064006 },
    { lat: 49.0916148, lng: 19.5063416 },
    { lat: 49.0916562, lng: 19.5061901 },
    { lat: 49.0917415, lng: 19.5062832 },
    { lat: 49.0918541, lng: 19.5060613 },
    { lat: 49.0919506, lng: 19.50605 },
    { lat: 49.0919703, lng: 19.5061852 },
    { lat: 49.0920671, lng: 19.5062835 },
    { lat: 49.0921667, lng: 19.5062147 },
    { lat: 49.0922933, lng: 19.506325 },
    { lat: 49.0924117, lng: 19.5061783 },
    { lat: 49.0923427, lng: 19.5059293 },
    { lat: 49.0925782, lng: 19.5058989 },
    { lat: 49.0926457, lng: 19.5060488 },
    { lat: 49.092722, lng: 19.5057707 },
    { lat: 49.0928389, lng: 19.50574 },
    { lat: 49.0928836, lng: 19.5055566 },
    { lat: 49.0929621, lng: 19.5055996 },
    { lat: 49.0929824, lng: 19.5055008 },
    { lat: 49.0931602, lng: 19.505346 },
    { lat: 49.0933254, lng: 19.5054842 },
    { lat: 49.0933716, lng: 19.5054365 },
    { lat: 49.0934272, lng: 19.505541 },
    { lat: 49.0934975, lng: 19.5055569 },
    { lat: 49.0935201, lng: 19.5053848 },
    { lat: 49.0937207, lng: 19.5054336 },
    { lat: 49.0937716, lng: 19.5051416 },
    { lat: 49.0938186, lng: 19.5051316 },
    { lat: 49.0938647, lng: 19.5052572 },
    { lat: 49.0940005, lng: 19.5051961 },
    { lat: 49.0940741, lng: 19.505226 },
    { lat: 49.0941027, lng: 19.5053352 },
    { lat: 49.0940465, lng: 19.5053386 },
    { lat: 49.0940601, lng: 19.5054145 },
    { lat: 49.0943165, lng: 19.5054035 },
    { lat: 49.0943453, lng: 19.5055126 },
    { lat: 49.0946262, lng: 19.5055939 },
    { lat: 49.0947271, lng: 19.5056569 },
    { lat: 49.0948045, lng: 19.5057997 },
    { lat: 49.0948877, lng: 19.5057452 },
    { lat: 49.0948493, lng: 19.505645 },
    { lat: 49.0949197, lng: 19.5054854 },
    { lat: 49.0949578, lng: 19.5054817 },
    { lat: 49.0951024, lng: 19.5056843 },
    { lat: 49.095146, lng: 19.5056002 },
    { lat: 49.0951314, lng: 19.5055032 },
    { lat: 49.0952314, lng: 19.5053761 },
    { lat: 49.0952713, lng: 19.5053866 },
    { lat: 49.0953021, lng: 19.5055642 },
    { lat: 49.0953618, lng: 19.505368 },
    { lat: 49.0954543, lng: 19.5053201 },
    { lat: 49.0955655, lng: 19.5053407 },
    { lat: 49.0957027, lng: 19.5051341 },
    { lat: 49.0958105, lng: 19.5050037 },
    { lat: 49.095851, lng: 19.5050069 },
    { lat: 49.0959313, lng: 19.5047902 },
    { lat: 49.0959703, lng: 19.5048522 },
    { lat: 49.0960108, lng: 19.5048504 },
    { lat: 49.0960782, lng: 19.5047228 },
    { lat: 49.0961929, lng: 19.5047437 },
    { lat: 49.0962949, lng: 19.5045107 },
    { lat: 49.0963886, lng: 19.5045874 },
    { lat: 49.0964447, lng: 19.5044614 },
    { lat: 49.0964887, lng: 19.5044635 },
    { lat: 49.0965122, lng: 19.5041874 },
    { lat: 49.0966784, lng: 19.5041967 },
    { lat: 49.0967341, lng: 19.5041606 },
    { lat: 49.0968487, lng: 19.504275 },
    { lat: 49.0969381, lng: 19.5042332 },
    { lat: 49.0969467, lng: 19.5043484 },
    { lat: 49.0971346, lng: 19.5042954 },
    { lat: 49.0971574, lng: 19.5043753 },
    { lat: 49.0972132, lng: 19.5044075 },
    { lat: 49.0975502, lng: 19.5041937 },
    { lat: 49.097635, lng: 19.5042462 },
    { lat: 49.097758, lng: 19.5042231 },
    { lat: 49.0983572, lng: 19.5041962 },
    { lat: 49.0984687, lng: 19.5039247 },
    { lat: 49.0985391, lng: 19.5039367 },
    { lat: 49.0985992, lng: 19.503871 },
    { lat: 49.0986022, lng: 19.5036884 },
    { lat: 49.0986881, lng: 19.5035965 },
    { lat: 49.0988393, lng: 19.5035776 },
    { lat: 49.0989347, lng: 19.5034948 },
    { lat: 49.0990358, lng: 19.5035111 },
    { lat: 49.099163, lng: 19.5035824 },
    { lat: 49.0992293, lng: 19.5035549 },
    { lat: 49.0995322, lng: 19.5029855 },
    { lat: 49.0995689, lng: 19.5028748 },
    { lat: 49.0995603, lng: 19.5028117 },
    { lat: 49.0994842, lng: 19.5027993 },
    { lat: 49.0994319, lng: 19.5026409 },
    { lat: 49.0994949, lng: 19.5025382 },
    { lat: 49.0996763, lng: 19.5023632 },
    { lat: 49.0997497, lng: 19.5023465 },
    { lat: 49.0997638, lng: 19.5021601 },
    { lat: 49.0998302, lng: 19.5020114 },
    { lat: 49.0997244, lng: 19.5018418 },
    { lat: 49.0995741, lng: 19.5017358 },
    { lat: 49.0994852, lng: 19.5015637 },
    { lat: 49.0994191, lng: 19.5012284 },
    { lat: 49.0993781, lng: 19.5010695 },
    { lat: 49.0993895, lng: 19.5008748 },
    { lat: 49.0993873, lng: 19.5005072 },
    { lat: 49.0993944, lng: 19.500318 },
    { lat: 49.0994595, lng: 19.4999705 },
    { lat: 49.0994957, lng: 19.4998736 },
    { lat: 49.0990888, lng: 19.4998211 },
    { lat: 49.0990659, lng: 19.4995949 },
    { lat: 49.0998148, lng: 19.4996811 },
    { lat: 49.0999226, lng: 19.4994255 },
    { lat: 49.1005993, lng: 19.4986772 },
    { lat: 49.0998526, lng: 19.4922217 },
    { lat: 49.0997911, lng: 19.4909344 },
    { lat: 49.0990726, lng: 19.4887793 },
    { lat: 49.0990601, lng: 19.4886821 },
    { lat: 49.0986465, lng: 19.4856599 },
    { lat: 49.0987893, lng: 19.4855803 },
    { lat: 49.0987335, lng: 19.4853479 },
    { lat: 49.098649, lng: 19.485395 },
    { lat: 49.0985376, lng: 19.4848614 },
    { lat: 49.0985444, lng: 19.4848089 },
    { lat: 49.1016504, lng: 19.483402 },
    { lat: 49.1018186, lng: 19.483086 },
    { lat: 49.1014537, lng: 19.4820867 },
    { lat: 49.1011622, lng: 19.4819444 },
    { lat: 49.1008998, lng: 19.4816038 },
    { lat: 49.1006465, lng: 19.4807275 },
    { lat: 49.1005454, lng: 19.4805157 },
    { lat: 49.0999985, lng: 19.4800896 },
    { lat: 49.0997194, lng: 19.4796307 },
    { lat: 49.0996137, lng: 19.4792957 },
    { lat: 49.0995741, lng: 19.4789478 },
    { lat: 49.0994971, lng: 19.478572 },
    { lat: 49.0998655, lng: 19.4779577 },
    { lat: 49.1002822, lng: 19.4775612 },
    { lat: 49.1010794, lng: 19.4773043 },
    { lat: 49.1026253, lng: 19.4769811 },
    { lat: 49.1035815, lng: 19.4760181 },
    { lat: 49.1051287, lng: 19.4751381 },
    { lat: 49.1048493, lng: 19.4739167 },
    { lat: 49.1045828, lng: 19.4735586 },
    { lat: 49.1043043, lng: 19.470962 },
    { lat: 49.1043157, lng: 19.4705018 },
    { lat: 49.104357, lng: 19.4699019 },
    { lat: 49.1043838, lng: 19.4694333 },
    { lat: 49.1044671, lng: 19.4684264 },
    { lat: 49.1052313, lng: 19.4673579 },
    { lat: 49.105708, lng: 19.4658418 },
    { lat: 49.1057353, lng: 19.4657522 },
    { lat: 49.1057989, lng: 19.4656871 },
    { lat: 49.1058185, lng: 19.4656505 },
    { lat: 49.1058384, lng: 19.4656156 },
    { lat: 49.1059039, lng: 19.4654968 },
    { lat: 49.105984, lng: 19.4653526 },
    { lat: 49.1060327, lng: 19.4652104 },
    { lat: 49.1060752, lng: 19.4650843 },
    { lat: 49.1061046, lng: 19.4649887 },
    { lat: 49.1061351, lng: 19.4648927 },
    { lat: 49.1061645, lng: 19.464695 },
    { lat: 49.1061824, lng: 19.4645718 },
    { lat: 49.1061858, lng: 19.4645485 },
    { lat: 49.1061907, lng: 19.4643627 },
    { lat: 49.1061924, lng: 19.4642766 },
    { lat: 49.1061957, lng: 19.4641788 },
    { lat: 49.1061984, lng: 19.4640882 },
    { lat: 49.1061917, lng: 19.46404 },
    { lat: 49.1061727, lng: 19.4638996 },
    { lat: 49.1061674, lng: 19.463856 },
    { lat: 49.1061003, lng: 19.4634053 },
    { lat: 49.1066035, lng: 19.4630001 },
    { lat: 49.1075894, lng: 19.461657 },
    { lat: 49.1080771, lng: 19.461434 },
    { lat: 49.1082173, lng: 19.4613697 },
    { lat: 49.108745, lng: 19.461121 },
    { lat: 49.1085, lng: 19.45911 },
    { lat: 49.108324, lng: 19.457333 },
    { lat: 49.107995, lng: 19.45674 },
    { lat: 49.107745, lng: 19.455747 },
    { lat: 49.107802, lng: 19.454684 },
    { lat: 49.107657, lng: 19.454427 },
    { lat: 49.107234, lng: 19.453883 },
    { lat: 49.106791, lng: 19.453441 },
    { lat: 49.105653, lng: 19.452152 },
    { lat: 49.105627, lng: 19.452123 },
    { lat: 49.105571, lng: 19.452044 },
    { lat: 49.105566, lng: 19.452033 },
    { lat: 49.105372, lng: 19.451672 },
    { lat: 49.104571, lng: 19.450978 },
    { lat: 49.104043, lng: 19.450793 },
    { lat: 49.103032, lng: 19.450204 },
    { lat: 49.103212, lng: 19.449202 },
    { lat: 49.101437, lng: 19.448794 },
    { lat: 49.101309, lng: 19.448803 },
    { lat: 49.100953, lng: 19.448918 },
    { lat: 49.100607, lng: 19.449035 },
    { lat: 49.100248, lng: 19.449116 },
    { lat: 49.099899, lng: 19.449156 },
    { lat: 49.099535, lng: 19.449155 },
    { lat: 49.099178, lng: 19.449118 },
    { lat: 49.098824, lng: 19.449037 },
    { lat: 49.098644, lng: 19.448943 },
    { lat: 49.098128, lng: 19.448759 },
    { lat: 49.097705, lng: 19.448528 },
    { lat: 49.097472, lng: 19.44834 },
    { lat: 49.097149, lng: 19.448075 },
    { lat: 49.096849, lng: 19.447771 },
    { lat: 49.096557, lng: 19.447444 },
    { lat: 49.096286, lng: 19.447088 },
    { lat: 49.096035, lng: 19.446685 },
    { lat: 49.095803, lng: 19.446267 },
    { lat: 49.0957576, lng: 19.4461753 },
    { lat: 49.095586, lng: 19.445829 },
    { lat: 49.095402, lng: 19.44536 },
    { lat: 49.095229, lng: 19.444876 },
    { lat: 49.095122, lng: 19.444655 },
    { lat: 49.095046, lng: 19.444565 },
    { lat: 49.0950128, lng: 19.4445492 },
    { lat: 49.094966, lng: 19.444527 },
    { lat: 49.094867, lng: 19.444518 },
    { lat: 49.094736, lng: 19.44456 },
    { lat: 49.094496, lng: 19.444731 },
    { lat: 49.0943621, lng: 19.4449926 },
    { lat: 49.0943182, lng: 19.4450185 },
    { lat: 49.0942449, lng: 19.4450872 },
    { lat: 49.094153, lng: 19.4451733 },
    { lat: 49.0940557, lng: 19.4453815 },
    { lat: 49.0940758, lng: 19.4454807 },
    { lat: 49.0942357, lng: 19.4455009 },
    { lat: 49.0943007, lng: 19.4456407 },
    { lat: 49.0945459, lng: 19.4461886 },
    { lat: 49.094554, lng: 19.4462916 },
    { lat: 49.0946013, lng: 19.4463135 },
    { lat: 49.0946276, lng: 19.4463722 },
    { lat: 49.0937, lng: 19.4478751 },
    { lat: 49.0931887, lng: 19.4495648 },
    { lat: 49.0929469, lng: 19.4506461 },
    { lat: 49.092887, lng: 19.4506881 },
    { lat: 49.0925194, lng: 19.4507828 },
    { lat: 49.092071, lng: 19.451124 },
    { lat: 49.092187, lng: 19.4515128 },
    { lat: 49.0922386, lng: 19.4515112 },
    { lat: 49.0924293, lng: 19.4520506 },
    { lat: 49.0919802, lng: 19.4544512 },
    { lat: 49.0919652, lng: 19.4552474 },
    { lat: 49.0919572, lng: 19.4557326 },
    { lat: 49.091847, lng: 19.456859 },
    { lat: 49.0916423, lng: 19.4574332 },
    { lat: 49.0912059, lng: 19.4586944 },
    { lat: 49.0913024, lng: 19.4600989 },
    { lat: 49.0913132, lng: 19.4614593 },
    { lat: 49.0910428, lng: 19.4630968 },
    { lat: 49.0908845, lng: 19.4634477 },
    { lat: 49.0900622, lng: 19.463526 },
    { lat: 49.0900898, lng: 19.4639559 },
    { lat: 49.0900736, lng: 19.4643883 },
    { lat: 49.0900547, lng: 19.4644907 },
    { lat: 49.0900715, lng: 19.464609 },
    { lat: 49.0908307, lng: 19.4650653 },
    { lat: 49.0909994, lng: 19.4652579 },
    { lat: 49.0910609, lng: 19.4655149 },
  ],
  UhorskáVes: [
    { lat: 49.0716396, lng: 19.6943619 },
    { lat: 49.0716705, lng: 19.6941165 },
    { lat: 49.0717064, lng: 19.6937054 },
    { lat: 49.0717108, lng: 19.6933169 },
    { lat: 49.0717752, lng: 19.6929916 },
    { lat: 49.0718498, lng: 19.6928732 },
    { lat: 49.0718318, lng: 19.6926893 },
    { lat: 49.0719002, lng: 19.6925601 },
    { lat: 49.071864, lng: 19.6923231 },
    { lat: 49.0718857, lng: 19.6921254 },
    { lat: 49.0719131, lng: 19.6920087 },
    { lat: 49.0719091, lng: 19.6919441 },
    { lat: 49.0718877, lng: 19.6918931 },
    { lat: 49.0718738, lng: 19.6916534 },
    { lat: 49.0719172, lng: 19.6914901 },
    { lat: 49.071938, lng: 19.6911438 },
    { lat: 49.071964, lng: 19.6910965 },
    { lat: 49.071927, lng: 19.6909429 },
    { lat: 49.0719783, lng: 19.6905769 },
    { lat: 49.0719571, lng: 19.6905029 },
    { lat: 49.0719657, lng: 19.6903648 },
    { lat: 49.0719468, lng: 19.6902697 },
    { lat: 49.0719711, lng: 19.6902108 },
    { lat: 49.0719558, lng: 19.6900665 },
    { lat: 49.0719862, lng: 19.6899164 },
    { lat: 49.0720226, lng: 19.6895379 },
    { lat: 49.0719617, lng: 19.689336 },
    { lat: 49.0720161, lng: 19.6892137 },
    { lat: 49.0719966, lng: 19.6891046 },
    { lat: 49.0720167, lng: 19.6890462 },
    { lat: 49.0720091, lng: 19.6890031 },
    { lat: 49.0720293, lng: 19.6889522 },
    { lat: 49.0720415, lng: 19.6888694 },
    { lat: 49.0719934, lng: 19.6887751 },
    { lat: 49.0719847, lng: 19.6885791 },
    { lat: 49.0720126, lng: 19.6884196 },
    { lat: 49.0719931, lng: 19.6883084 },
    { lat: 49.0720129, lng: 19.6881482 },
    { lat: 49.0720206, lng: 19.6878359 },
    { lat: 49.0720007, lng: 19.6877452 },
    { lat: 49.0720785, lng: 19.687517 },
    { lat: 49.0720687, lng: 19.6873965 },
    { lat: 49.0721557, lng: 19.6868925 },
    { lat: 49.0721273, lng: 19.6868372 },
    { lat: 49.0721194, lng: 19.6866583 },
    { lat: 49.0721684, lng: 19.6865926 },
    { lat: 49.0721603, lng: 19.6864371 },
    { lat: 49.0722138, lng: 19.6861186 },
    { lat: 49.0722225, lng: 19.6859797 },
    { lat: 49.0723495, lng: 19.6857982 },
    { lat: 49.0723678, lng: 19.6856982 },
    { lat: 49.0724009, lng: 19.6856366 },
    { lat: 49.0724258, lng: 19.6853818 },
    { lat: 49.0724918, lng: 19.6853212 },
    { lat: 49.0725112, lng: 19.6851722 },
    { lat: 49.0725135, lng: 19.685048 },
    { lat: 49.0725806, lng: 19.6847562 },
    { lat: 49.072563, lng: 19.6846883 },
    { lat: 49.0725662, lng: 19.6846064 },
    { lat: 49.0725982, lng: 19.6845398 },
    { lat: 49.0725673, lng: 19.6844564 },
    { lat: 49.072642, lng: 19.6844089 },
    { lat: 49.072644, lng: 19.6843314 },
    { lat: 49.0726192, lng: 19.6843271 },
    { lat: 49.0726068, lng: 19.684254 },
    { lat: 49.0726621, lng: 19.683898 },
    { lat: 49.0726452, lng: 19.6837459 },
    { lat: 49.0726601, lng: 19.6836977 },
    { lat: 49.0727511, lng: 19.6835665 },
    { lat: 49.0727332, lng: 19.6835472 },
    { lat: 49.0727319, lng: 19.6834639 },
    { lat: 49.0727767, lng: 19.683431 },
    { lat: 49.0727826, lng: 19.6833348 },
    { lat: 49.0726946, lng: 19.6829521 },
    { lat: 49.0727013, lng: 19.6828742 },
    { lat: 49.0725306, lng: 19.6827339 },
    { lat: 49.0703956, lng: 19.681025 },
    { lat: 49.0708166, lng: 19.6797942 },
    { lat: 49.0708411, lng: 19.6797403 },
    { lat: 49.070563, lng: 19.6795159 },
    { lat: 49.0705447, lng: 19.6795623 },
    { lat: 49.0702414, lng: 19.68014 },
    { lat: 49.0700723, lng: 19.6801113 },
    { lat: 49.0700186, lng: 19.6795606 },
    { lat: 49.0700459, lng: 19.6793875 },
    { lat: 49.0700477, lng: 19.6792996 },
    { lat: 49.0699112, lng: 19.6792992 },
    { lat: 49.0697386, lng: 19.6791391 },
    { lat: 49.0694706, lng: 19.6790692 },
    { lat: 49.0691878, lng: 19.6787852 },
    { lat: 49.0690189, lng: 19.6786358 },
    { lat: 49.0686621, lng: 19.6784145 },
    { lat: 49.0686188, lng: 19.6783805 },
    { lat: 49.0685288, lng: 19.6780753 },
    { lat: 49.0684167, lng: 19.6777092 },
    { lat: 49.0682314, lng: 19.6774702 },
    { lat: 49.0680806, lng: 19.677214 },
    { lat: 49.0680508, lng: 19.6770015 },
    { lat: 49.0680044, lng: 19.6766135 },
    { lat: 49.0680066, lng: 19.6764853 },
    { lat: 49.0680106, lng: 19.6762486 },
    { lat: 49.0679835, lng: 19.6761546 },
    { lat: 49.0678988, lng: 19.6758605 },
    { lat: 49.0676915, lng: 19.6754775 },
    { lat: 49.0675653, lng: 19.6752976 },
    { lat: 49.0674017, lng: 19.6751171 },
    { lat: 49.0673917, lng: 19.6749672 },
    { lat: 49.067394, lng: 19.6747625 },
    { lat: 49.0674039, lng: 19.6746084 },
    { lat: 49.0673257, lng: 19.6744677 },
    { lat: 49.0673132, lng: 19.6738819 },
    { lat: 49.0671966, lng: 19.6733876 },
    { lat: 49.0670635, lng: 19.6732568 },
    { lat: 49.0668431, lng: 19.673089 },
    { lat: 49.0667798, lng: 19.6728537 },
    { lat: 49.0667799, lng: 19.6724247 },
    { lat: 49.0666644, lng: 19.6720298 },
    { lat: 49.0664549, lng: 19.6715476 },
    { lat: 49.066415, lng: 19.6716164 },
    { lat: 49.06633, lng: 19.6716539 },
    { lat: 49.0661539, lng: 19.6721279 },
    { lat: 49.0659435, lng: 19.6726436 },
    { lat: 49.0658511, lng: 19.6730246 },
    { lat: 49.0655975, lng: 19.6757402 },
    { lat: 49.0656248, lng: 19.6761801 },
    { lat: 49.0652582, lng: 19.6760959 },
    { lat: 49.064668, lng: 19.6759431 },
    { lat: 49.0640514, lng: 19.6757567 },
    { lat: 49.0639677, lng: 19.6757286 },
    { lat: 49.0636393, lng: 19.676607 },
    { lat: 49.0634973, lng: 19.6768587 },
    { lat: 49.0625208, lng: 19.6765881 },
    { lat: 49.0620566, lng: 19.6764575 },
    { lat: 49.0619164, lng: 19.6764216 },
    { lat: 49.0609529, lng: 19.6761608 },
    { lat: 49.0604302, lng: 19.6760101 },
    { lat: 49.0590286, lng: 19.675628 },
    { lat: 49.0589785, lng: 19.6756173 },
    { lat: 49.0566011, lng: 19.6749779 },
    { lat: 49.0565117, lng: 19.6751983 },
    { lat: 49.056444, lng: 19.6757531 },
    { lat: 49.056333, lng: 19.6764499 },
    { lat: 49.0563678, lng: 19.6768555 },
    { lat: 49.0564008, lng: 19.6771423 },
    { lat: 49.0565179, lng: 19.6780797 },
    { lat: 49.056458, lng: 19.6787117 },
    { lat: 49.0564457, lng: 19.6788642 },
    { lat: 49.0563525, lng: 19.6790519 },
    { lat: 49.0562363, lng: 19.6792553 },
    { lat: 49.0561401, lng: 19.6793431 },
    { lat: 49.0560235, lng: 19.6795889 },
    { lat: 49.0560177, lng: 19.679809 },
    { lat: 49.0561545, lng: 19.6798212 },
    { lat: 49.0561637, lng: 19.6801263 },
    { lat: 49.0561694, lng: 19.6804882 },
    { lat: 49.0561678, lng: 19.6807843 },
    { lat: 49.056125, lng: 19.6810905 },
    { lat: 49.0560837, lng: 19.6813264 },
    { lat: 49.0561667, lng: 19.6817242 },
    { lat: 49.0560941, lng: 19.6824788 },
    { lat: 49.0561173, lng: 19.6828485 },
    { lat: 49.0561377, lng: 19.6832348 },
    { lat: 49.0560912, lng: 19.6834317 },
    { lat: 49.0560653, lng: 19.6836352 },
    { lat: 49.0560655, lng: 19.6836395 },
    { lat: 49.0560533, lng: 19.6836946 },
    { lat: 49.0559695, lng: 19.6842995 },
    { lat: 49.055887, lng: 19.6848522 },
    { lat: 49.0558258, lng: 19.6852981 },
    { lat: 49.0558216, lng: 19.6853108 },
    { lat: 49.0558211, lng: 19.6853198 },
    { lat: 49.0558182, lng: 19.6853341 },
    { lat: 49.0558182, lng: 19.6853549 },
    { lat: 49.0558097, lng: 19.6854194 },
    { lat: 49.0558098, lng: 19.685423 },
    { lat: 49.0556305, lng: 19.6866954 },
    { lat: 49.055616, lng: 19.6868204 },
    { lat: 49.0555299, lng: 19.6873468 },
    { lat: 49.0550838, lng: 19.687552 },
    { lat: 49.0549942, lng: 19.6875899 },
    { lat: 49.0551389, lng: 19.6879332 },
    { lat: 49.0551877, lng: 19.6885296 },
    { lat: 49.0552436, lng: 19.6887196 },
    { lat: 49.0551714, lng: 19.6887698 },
    { lat: 49.0551756, lng: 19.688917 },
    { lat: 49.0548167, lng: 19.6891024 },
    { lat: 49.0545213, lng: 19.6892501 },
    { lat: 49.0543741, lng: 19.6892102 },
    { lat: 49.0540156, lng: 19.6891273 },
    { lat: 49.0540176, lng: 19.6890994 },
    { lat: 49.0538961, lng: 19.6890748 },
    { lat: 49.0538921, lng: 19.68909 },
    { lat: 49.0538119, lng: 19.6890817 },
    { lat: 49.0537636, lng: 19.6896488 },
    { lat: 49.0537352, lng: 19.6898995 },
    { lat: 49.0541005, lng: 19.6899814 },
    { lat: 49.0543638, lng: 19.6900468 },
    { lat: 49.0546408, lng: 19.6900684 },
    { lat: 49.0551009, lng: 19.6902598 },
    { lat: 49.0562427, lng: 19.6905518 },
    { lat: 49.0565225, lng: 19.6905576 },
    { lat: 49.0568275, lng: 19.6905526 },
    { lat: 49.0573, lng: 19.6904357 },
    { lat: 49.0575977, lng: 19.6903395 },
    { lat: 49.0576996, lng: 19.690301 },
    { lat: 49.0578452, lng: 19.6902031 },
    { lat: 49.0579676, lng: 19.6900929 },
    { lat: 49.0581456, lng: 19.6899112 },
    { lat: 49.0583427, lng: 19.6896594 },
    { lat: 49.0587222, lng: 19.6890914 },
    { lat: 49.059533, lng: 19.6876303 },
    { lat: 49.0596153, lng: 19.6874775 },
    { lat: 49.0598012, lng: 19.6871768 },
    { lat: 49.0606498, lng: 19.6859831 },
    { lat: 49.0615437, lng: 19.6846923 },
    { lat: 49.0619256, lng: 19.6841362 },
    { lat: 49.0620684, lng: 19.6839229 },
    { lat: 49.0622154, lng: 19.6840911 },
    { lat: 49.0627572, lng: 19.6847002 },
    { lat: 49.062886, lng: 19.6848354 },
    { lat: 49.0632031, lng: 19.6852002 },
    { lat: 49.0636143, lng: 19.685507 },
    { lat: 49.0640016, lng: 19.685883 },
    { lat: 49.0647686, lng: 19.6867971 },
    { lat: 49.0649027, lng: 19.6869242 },
    { lat: 49.0649649, lng: 19.6870066 },
    { lat: 49.0650945, lng: 19.6871338 },
    { lat: 49.0657897, lng: 19.6884089 },
    { lat: 49.0659899, lng: 19.6888098 },
    { lat: 49.066321, lng: 19.6900787 },
    { lat: 49.0662924, lng: 19.6901729 },
    { lat: 49.0665915, lng: 19.6912044 },
    { lat: 49.0668763, lng: 19.6917634 },
    { lat: 49.0675707, lng: 19.6926085 },
    { lat: 49.0689287, lng: 19.6931279 },
    { lat: 49.0694735, lng: 19.6932978 },
    { lat: 49.0694281, lng: 19.693905 },
    { lat: 49.070746, lng: 19.6941259 },
    { lat: 49.071512, lng: 19.6943537 },
    { lat: 49.0716396, lng: 19.6943619 },
  ],
  UhorskáVesExt: [
    { lat: 49.0350691, lng: 19.7030605 },
    { lat: 49.0350961, lng: 19.7030089 },
    { lat: 49.0349061, lng: 19.702764 },
    { lat: 49.0349296, lng: 19.7025526 },
    { lat: 49.0353504, lng: 19.698835 },
    { lat: 49.0355082, lng: 19.6972537 },
    { lat: 49.0354144, lng: 19.6952863 },
    { lat: 49.0355724, lng: 19.6944012 },
    { lat: 49.0360492, lng: 19.6925718 },
    { lat: 49.0358023, lng: 19.6917496 },
    { lat: 49.0351701, lng: 19.690778 },
    { lat: 49.0343993, lng: 19.6902647 },
    { lat: 49.0340236, lng: 19.6900974 },
    { lat: 49.0329117, lng: 19.6896395 },
    { lat: 49.0326169, lng: 19.6894984 },
    { lat: 49.0323438, lng: 19.6893113 },
    { lat: 49.0319433, lng: 19.6889889 },
    { lat: 49.0314348, lng: 19.6885271 },
    { lat: 49.0313724, lng: 19.6884649 },
    { lat: 49.0310101, lng: 19.6885537 },
    { lat: 49.030717, lng: 19.6887262 },
    { lat: 49.0304619, lng: 19.6889429 },
    { lat: 49.030251, lng: 19.6892923 },
    { lat: 49.0301199, lng: 19.6896167 },
    { lat: 49.0297735, lng: 19.6894015 },
    { lat: 49.0290304, lng: 19.688903 },
    { lat: 49.0286805, lng: 19.68861 },
    { lat: 49.0283272, lng: 19.6883402 },
    { lat: 49.0280699, lng: 19.6881759 },
    { lat: 49.0279174, lng: 19.6881438 },
    { lat: 49.0270404, lng: 19.6880813 },
    { lat: 49.0265174, lng: 19.6881279 },
    { lat: 49.0257889, lng: 19.6881601 },
    { lat: 49.0247579, lng: 19.687498 },
    { lat: 49.0242381, lng: 19.68718 },
    { lat: 49.0236663, lng: 19.6867381 },
    { lat: 49.0234313, lng: 19.6866182 },
    { lat: 49.0231124, lng: 19.6864642 },
    { lat: 49.0226443, lng: 19.6862478 },
    { lat: 49.0222039, lng: 19.6855143 },
    { lat: 49.0210237, lng: 19.6860849 },
    { lat: 49.020789, lng: 19.6862004 },
    { lat: 49.0204908, lng: 19.6863874 },
    { lat: 49.0194696, lng: 19.6871951 },
    { lat: 49.0191741, lng: 19.6871866 },
    { lat: 49.0188338, lng: 19.6872656 },
    { lat: 49.0187618, lng: 19.6872905 },
    { lat: 49.0183915, lng: 19.687449 },
    { lat: 49.0182429, lng: 19.6875791 },
    { lat: 49.0177871, lng: 19.6879986 },
    { lat: 49.0171385, lng: 19.6885406 },
    { lat: 49.0168334, lng: 19.6887516 },
    { lat: 49.01633, lng: 19.6891893 },
    { lat: 49.0158991, lng: 19.6895568 },
    { lat: 49.0158271, lng: 19.6898398 },
    { lat: 49.0156638, lng: 19.6904017 },
    { lat: 49.0148942, lng: 19.6922865 },
    { lat: 49.0149021, lng: 19.6932282 },
    { lat: 49.0147326, lng: 19.6946966 },
    { lat: 49.0140438, lng: 19.6961894 },
    { lat: 49.0141988, lng: 19.6964614 },
    { lat: 49.0145638, lng: 19.6970433 },
    { lat: 49.0148399, lng: 19.6973783 },
    { lat: 49.015048, lng: 19.6974759 },
    { lat: 49.015943, lng: 19.6979674 },
    { lat: 49.0171391, lng: 19.6990068 },
    { lat: 49.0180934, lng: 19.7000289 },
    { lat: 49.0182074, lng: 19.7001616 },
    { lat: 49.0184387, lng: 19.7004496 },
    { lat: 49.0185124, lng: 19.700483 },
    { lat: 49.0195611, lng: 19.7012956 },
    { lat: 49.0200514, lng: 19.7019649 },
    { lat: 49.0204207, lng: 19.7024729 },
    { lat: 49.0206755, lng: 19.7026296 },
    { lat: 49.0211775, lng: 19.7029474 },
    { lat: 49.0216552, lng: 19.7036898 },
    { lat: 49.0223702, lng: 19.7037835 },
    { lat: 49.0229894, lng: 19.7040545 },
    { lat: 49.0233426, lng: 19.7041668 },
    { lat: 49.0238062, lng: 19.7043101 },
    { lat: 49.0242671, lng: 19.7046968 },
    { lat: 49.0244194, lng: 19.7048274 },
    { lat: 49.0248228, lng: 19.7051625 },
    { lat: 49.0253162, lng: 19.7056966 },
    { lat: 49.0256227, lng: 19.7061062 },
    { lat: 49.0262526, lng: 19.7065456 },
    { lat: 49.0266443, lng: 19.7068145 },
    { lat: 49.0266918, lng: 19.7068746 },
    { lat: 49.0277418, lng: 19.7079437 },
    { lat: 49.0277617, lng: 19.7079603 },
    { lat: 49.028109, lng: 19.7080958 },
    { lat: 49.0296855, lng: 19.7087697 },
    { lat: 49.0300026, lng: 19.7089096 },
    { lat: 49.0304672, lng: 19.7090213 },
    { lat: 49.0309338, lng: 19.709126 },
    { lat: 49.0312062, lng: 19.7091463 },
    { lat: 49.0316818, lng: 19.709026 },
    { lat: 49.0319973, lng: 19.708847 },
    { lat: 49.0322321, lng: 19.7085646 },
    { lat: 49.0325912, lng: 19.7081327 },
    { lat: 49.0332092, lng: 19.7075085 },
    { lat: 49.0336081, lng: 19.7072826 },
    { lat: 49.033895, lng: 19.7073736 },
    { lat: 49.0341271, lng: 19.7075029 },
    { lat: 49.0345691, lng: 19.7075645 },
    { lat: 49.0348439, lng: 19.7075857 },
    { lat: 49.0347293, lng: 19.7064161 },
    { lat: 49.0346001, lng: 19.7059384 },
    { lat: 49.0347106, lng: 19.7045606 },
    { lat: 49.0348334, lng: 19.7039473 },
    { lat: 49.0348794, lng: 19.7035344 },
    { lat: 49.0349342, lng: 19.7033191 },
    { lat: 49.0350691, lng: 19.7030605 },
  ],
  Kvačany: [
    { lat: 49.1749963, lng: 19.5134484 },
    { lat: 49.174411, lng: 19.5141134 },
    { lat: 49.1742273, lng: 19.514233 },
    { lat: 49.173309, lng: 19.5144587 },
    { lat: 49.1728289, lng: 19.514556 },
    { lat: 49.1720892, lng: 19.5146911 },
    { lat: 49.1712061, lng: 19.5148193 },
    { lat: 49.1703252, lng: 19.5145502 },
    { lat: 49.1692117, lng: 19.5142754 },
    { lat: 49.1684677, lng: 19.5132316 },
    { lat: 49.1666066, lng: 19.5131612 },
    { lat: 49.1642745, lng: 19.5129687 },
    { lat: 49.1632908, lng: 19.5124502 },
    { lat: 49.1623093, lng: 19.5124244 },
    { lat: 49.1609898, lng: 19.5130907 },
    { lat: 49.1604728, lng: 19.5135892 },
    { lat: 49.1569022, lng: 19.5158104 },
    { lat: 49.156966, lng: 19.5158526 },
    { lat: 49.1571449, lng: 19.5160425 },
    { lat: 49.157125, lng: 19.516105 },
    { lat: 49.1575636, lng: 19.5165258 },
    { lat: 49.1579532, lng: 19.5168226 },
    { lat: 49.1575595, lng: 19.5171566 },
    { lat: 49.1570288, lng: 19.5174523 },
    { lat: 49.1561021, lng: 19.5179357 },
    { lat: 49.1552232, lng: 19.518404 },
    { lat: 49.1548825, lng: 19.5186903 },
    { lat: 49.1546892, lng: 19.5186759 },
    { lat: 49.1544616, lng: 19.5188001 },
    { lat: 49.1542433, lng: 19.5190824 },
    { lat: 49.1540402, lng: 19.5195171 },
    { lat: 49.1541594, lng: 19.5198116 },
    { lat: 49.1541384, lng: 19.5201158 },
    { lat: 49.1541404, lng: 19.5203834 },
    { lat: 49.1541225, lng: 19.5211766 },
    { lat: 49.1538612, lng: 19.5214274 },
    { lat: 49.1538524, lng: 19.5217981 },
    { lat: 49.153978, lng: 19.5230008 },
    { lat: 49.1537519, lng: 19.5235791 },
    { lat: 49.1536634, lng: 19.524083 },
    { lat: 49.1539308, lng: 19.5246858 },
    { lat: 49.1542747, lng: 19.5257322 },
    { lat: 49.1540029, lng: 19.5263945 },
    { lat: 49.1537808, lng: 19.5269562 },
    { lat: 49.1537449, lng: 19.527059 },
    { lat: 49.1537014, lng: 19.5271665 },
    { lat: 49.1534005, lng: 19.5283326 },
    { lat: 49.1532369, lng: 19.5289723 },
    { lat: 49.1529023, lng: 19.5302568 },
    { lat: 49.1528491, lng: 19.5312948 },
    { lat: 49.1527213, lng: 19.5324494 },
    { lat: 49.1527876, lng: 19.5335864 },
    { lat: 49.1528037, lng: 19.5338417 },
    { lat: 49.1528503, lng: 19.5346654 },
    { lat: 49.1533135, lng: 19.5367263 },
    { lat: 49.1527645, lng: 19.5370164 },
    { lat: 49.1526245, lng: 19.5370848 },
    { lat: 49.1525943, lng: 19.5370902 },
    { lat: 49.1532646, lng: 19.5374373 },
    { lat: 49.1533283, lng: 19.5378204 },
    { lat: 49.1533285, lng: 19.5382718 },
    { lat: 49.1534604, lng: 19.5383742 },
    { lat: 49.1537601, lng: 19.538246 },
    { lat: 49.153963, lng: 19.5383002 },
    { lat: 49.1539518, lng: 19.5386257 },
    { lat: 49.153901, lng: 19.5390663 },
    { lat: 49.1540146, lng: 19.5392397 },
    { lat: 49.1542959, lng: 19.5394555 },
    { lat: 49.1545105, lng: 19.5395894 },
    { lat: 49.1547107, lng: 19.5398545 },
    { lat: 49.1547974, lng: 19.5401261 },
    { lat: 49.1548661, lng: 19.5406973 },
    { lat: 49.1547234, lng: 19.5408298 },
    { lat: 49.1544094, lng: 19.5410861 },
    { lat: 49.1543462, lng: 19.5413796 },
    { lat: 49.1545052, lng: 19.541631 },
    { lat: 49.1547672, lng: 19.5416927 },
    { lat: 49.1548513, lng: 19.5419577 },
    { lat: 49.1548561, lng: 19.5424123 },
    { lat: 49.1549962, lng: 19.5428665 },
    { lat: 49.1553852, lng: 19.5431196 },
    { lat: 49.155547, lng: 19.543163 },
    { lat: 49.1558581, lng: 19.5429433 },
    { lat: 49.1559958, lng: 19.5429432 },
    { lat: 49.1560174, lng: 19.5430241 },
    { lat: 49.1558661, lng: 19.543364 },
    { lat: 49.1560019, lng: 19.543551 },
    { lat: 49.1564565, lng: 19.5434904 },
    { lat: 49.1565704, lng: 19.5435932 },
    { lat: 49.156685, lng: 19.5441038 },
    { lat: 49.1569914, lng: 19.5439308 },
    { lat: 49.1571669, lng: 19.5440206 },
    { lat: 49.1572936, lng: 19.5442578 },
    { lat: 49.1575933, lng: 19.5445581 },
    { lat: 49.1576634, lng: 19.5448848 },
    { lat: 49.1575324, lng: 19.5455264 },
    { lat: 49.1574496, lng: 19.5457613 },
    { lat: 49.1575255, lng: 19.5461179 },
    { lat: 49.1580547, lng: 19.5470259 },
    { lat: 49.1581041, lng: 19.5474966 },
    { lat: 49.1581483, lng: 19.548024 },
    { lat: 49.1582931, lng: 19.5483118 },
    { lat: 49.158232, lng: 19.5486765 },
    { lat: 49.1582759, lng: 19.5488735 },
    { lat: 49.1587474, lng: 19.5493843 },
    { lat: 49.1591435, lng: 19.5499938 },
    { lat: 49.1594598, lng: 19.5510585 },
    { lat: 49.15948, lng: 19.5510308 },
    { lat: 49.1603316, lng: 19.5506553 },
    { lat: 49.1604811, lng: 19.5513642 },
    { lat: 49.1607525, lng: 19.5523102 },
    { lat: 49.1609463, lng: 19.5529054 },
    { lat: 49.1611688, lng: 19.5533396 },
    { lat: 49.1613413, lng: 19.5533437 },
    { lat: 49.1615304, lng: 19.5534631 },
    { lat: 49.161591, lng: 19.5536587 },
    { lat: 49.1615821, lng: 19.5539578 },
    { lat: 49.1616615, lng: 19.5542205 },
    { lat: 49.1618883, lng: 19.5547763 },
    { lat: 49.161929, lng: 19.5549239 },
    { lat: 49.1622735, lng: 19.5550961 },
    { lat: 49.1624328, lng: 19.5553044 },
    { lat: 49.1625651, lng: 19.5557585 },
    { lat: 49.1626682, lng: 19.5559712 },
    { lat: 49.1632807, lng: 19.5561397 },
    { lat: 49.163797, lng: 19.5570888 },
    { lat: 49.1639189, lng: 19.5572452 },
    { lat: 49.164444, lng: 19.5578196 },
    { lat: 49.1644565, lng: 19.5578716 },
    { lat: 49.1650824, lng: 19.5587534 },
    { lat: 49.1655981, lng: 19.559147 },
    { lat: 49.1660712, lng: 19.55984 },
    { lat: 49.1664682, lng: 19.5601252 },
    { lat: 49.1667371, lng: 19.5603918 },
    { lat: 49.1673382, lng: 19.5610279 },
    { lat: 49.1675123, lng: 19.5612854 },
    { lat: 49.1676078, lng: 19.5615547 },
    { lat: 49.1677832, lng: 19.562323 },
    { lat: 49.1681043, lng: 19.5633198 },
    { lat: 49.1681691, lng: 19.5637767 },
    { lat: 49.1683893, lng: 19.5640618 },
    { lat: 49.1685183, lng: 19.5643003 },
    { lat: 49.1687607, lng: 19.5651033 },
    { lat: 49.1693603, lng: 19.5662815 },
    { lat: 49.1696179, lng: 19.5663455 },
    { lat: 49.1696293, lng: 19.5664001 },
    { lat: 49.1702108, lng: 19.5689198 },
    { lat: 49.1717632, lng: 19.5713382 },
    { lat: 49.1718128, lng: 19.5728411 },
    { lat: 49.1719304, lng: 19.5738453 },
    { lat: 49.1720023, lng: 19.5743923 },
    { lat: 49.1720561, lng: 19.5749109 },
    { lat: 49.1719302, lng: 19.5760148 },
    { lat: 49.1719246, lng: 19.5760939 },
    { lat: 49.1718826, lng: 19.576085 },
    { lat: 49.1718379, lng: 19.577247 },
    { lat: 49.171868, lng: 19.5779147 },
    { lat: 49.1721717, lng: 19.578675 },
    { lat: 49.1722562, lng: 19.5788275 },
    { lat: 49.1728075, lng: 19.5796811 },
    { lat: 49.1737354, lng: 19.5804748 },
    { lat: 49.1739706, lng: 19.5806684 },
    { lat: 49.1744127, lng: 19.581003 },
    { lat: 49.1747237, lng: 19.5813032 },
    { lat: 49.1751621, lng: 19.58168 },
    { lat: 49.1755852, lng: 19.5816699 },
    { lat: 49.1759128, lng: 19.5817404 },
    { lat: 49.1769578, lng: 19.5815539 },
    { lat: 49.1775029, lng: 19.5816203 },
    { lat: 49.177968, lng: 19.5820441 },
    { lat: 49.1785053, lng: 19.5823162 },
    { lat: 49.1786117, lng: 19.5823789 },
    { lat: 49.1787044, lng: 19.5824607 },
    { lat: 49.1788663, lng: 19.5826526 },
    { lat: 49.1788844, lng: 19.5827504 },
    { lat: 49.1789561, lng: 19.5830433 },
    { lat: 49.1800596, lng: 19.5843334 },
    { lat: 49.1807665, lng: 19.5843915 },
    { lat: 49.1815608, lng: 19.5846919 },
    { lat: 49.1824907, lng: 19.5847757 },
    { lat: 49.1829305, lng: 19.5850621 },
    { lat: 49.1838444, lng: 19.584862 },
    { lat: 49.1844707, lng: 19.584988 },
    { lat: 49.1854815, lng: 19.585819 },
    { lat: 49.1860383, lng: 19.5862981 },
    { lat: 49.1867719, lng: 19.5869028 },
    { lat: 49.1874763, lng: 19.5876571 },
    { lat: 49.1875726, lng: 19.5877468 },
    { lat: 49.18798, lng: 19.5884102 },
    { lat: 49.1889132, lng: 19.5888975 },
    { lat: 49.1895068, lng: 19.5892205 },
    { lat: 49.1900078, lng: 19.5898409 },
    { lat: 49.1901522, lng: 19.5907941 },
    { lat: 49.1920906, lng: 19.5926356 },
    { lat: 49.1925623, lng: 19.5925773 },
    { lat: 49.1930302, lng: 19.5926887 },
    { lat: 49.1936625, lng: 19.5935466 },
    { lat: 49.1938431, lng: 19.5934765 },
    { lat: 49.1939192, lng: 19.5934905 },
    { lat: 49.1939887, lng: 19.5935341 },
    { lat: 49.194445, lng: 19.5937583 },
    { lat: 49.1947115, lng: 19.5941246 },
    { lat: 49.1954821, lng: 19.5944259 },
    { lat: 49.1961323, lng: 19.5951292 },
    { lat: 49.1968693, lng: 19.5956113 },
    { lat: 49.1971502, lng: 19.5960694 },
    { lat: 49.1976621, lng: 19.5959802 },
    { lat: 49.1980274, lng: 19.5962883 },
    { lat: 49.1987035, lng: 19.5961866 },
    { lat: 49.1988072, lng: 19.5966193 },
    { lat: 49.1989661, lng: 19.5966391 },
    { lat: 49.1991558, lng: 19.5964006 },
    { lat: 49.1999938, lng: 19.5967979 },
    { lat: 49.2002289, lng: 19.5968915 },
    { lat: 49.2017166, lng: 19.5968251 },
    { lat: 49.2021934, lng: 19.5970541 },
    { lat: 49.202335, lng: 19.5970015 },
    { lat: 49.2024812, lng: 19.5968453 },
    { lat: 49.2024902, lng: 19.5968188 },
    { lat: 49.202513, lng: 19.5967754 },
    { lat: 49.2027854, lng: 19.5963423 },
    { lat: 49.2033017, lng: 19.5963463 },
    { lat: 49.2048438, lng: 19.5958612 },
    { lat: 49.2053302, lng: 19.5959837 },
    { lat: 49.2057044, lng: 19.5959111 },
    { lat: 49.2066222, lng: 19.5963769 },
    { lat: 49.2075357, lng: 19.5968259 },
    { lat: 49.2082762, lng: 19.5978594 },
    { lat: 49.2087085, lng: 19.5981818 },
    { lat: 49.2089191, lng: 19.5991598 },
    { lat: 49.2091066, lng: 19.5994735 },
    { lat: 49.2093607, lng: 19.5998372 },
    { lat: 49.2098965, lng: 19.5995967 },
    { lat: 49.2107565, lng: 19.6004399 },
    { lat: 49.2113354, lng: 19.6015603 },
    { lat: 49.2122558, lng: 19.60287 },
    { lat: 49.2123824, lng: 19.6035087 },
    { lat: 49.2124428, lng: 19.6036462 },
    { lat: 49.2121777, lng: 19.6059287 },
    { lat: 49.2123905, lng: 19.6064791 },
    { lat: 49.2124786, lng: 19.6065856 },
    { lat: 49.2126627, lng: 19.6068745 },
    { lat: 49.2127688, lng: 19.6070036 },
    { lat: 49.212863, lng: 19.6071707 },
    { lat: 49.2129718, lng: 19.607714 },
    { lat: 49.2130984, lng: 19.6076954 },
    { lat: 49.2143979, lng: 19.6075325 },
    { lat: 49.2151283, lng: 19.6074148 },
    { lat: 49.2161093, lng: 19.6071799 },
    { lat: 49.216304, lng: 19.607043 },
    { lat: 49.216139, lng: 19.606531 },
    { lat: 49.216434, lng: 19.605219 },
    { lat: 49.216388, lng: 19.604843 },
    { lat: 49.216575, lng: 19.6042 },
    { lat: 49.216492, lng: 19.6034199 },
    { lat: 49.2163307, lng: 19.6021335 },
    { lat: 49.2152497, lng: 19.6013451 },
    { lat: 49.2149324, lng: 19.6005028 },
    { lat: 49.2146397, lng: 19.5997225 },
    { lat: 49.2141558, lng: 19.5977301 },
    { lat: 49.2133263, lng: 19.5959107 },
    { lat: 49.2124208, lng: 19.5952147 },
    { lat: 49.2109257, lng: 19.5933321 },
    { lat: 49.210771, lng: 19.5931515 },
    { lat: 49.2104014, lng: 19.5927231 },
    { lat: 49.2102392, lng: 19.5928496 },
    { lat: 49.2099696, lng: 19.5927395 },
    { lat: 49.209544, lng: 19.5918673 },
    { lat: 49.20933, lng: 19.5915444 },
    { lat: 49.2085748, lng: 19.5904058 },
    { lat: 49.2083785, lng: 19.5904019 },
    { lat: 49.207572, lng: 19.5903825 },
    { lat: 49.2068715, lng: 19.5900345 },
    { lat: 49.2064257, lng: 19.5892402 },
    { lat: 49.2055414, lng: 19.5883649 },
    { lat: 49.2049918, lng: 19.5879176 },
    { lat: 49.2049782, lng: 19.5878938 },
    { lat: 49.2045349, lng: 19.5871273 },
    { lat: 49.203941, lng: 19.5869528 },
    { lat: 49.2039191, lng: 19.5825538 },
    { lat: 49.2032054, lng: 19.5792381 },
    { lat: 49.2024711, lng: 19.5761642 },
    { lat: 49.2022565, lng: 19.5746828 },
    { lat: 49.2022672, lng: 19.5745959 },
    { lat: 49.2023042, lng: 19.5742641 },
    { lat: 49.2019459, lng: 19.5724887 },
    { lat: 49.2009916, lng: 19.5706404 },
    { lat: 49.200963, lng: 19.5702045 },
    { lat: 49.200571, lng: 19.5644121 },
    { lat: 49.2004752, lng: 19.5637906 },
    { lat: 49.2004119, lng: 19.5632885 },
    { lat: 49.2011029, lng: 19.5619227 },
    { lat: 49.2011278, lng: 19.5618722 },
    { lat: 49.2032491, lng: 19.5575305 },
    { lat: 49.2033197, lng: 19.5573956 },
    { lat: 49.2033642, lng: 19.5573091 },
    { lat: 49.2034151, lng: 19.5572118 },
    { lat: 49.2071622, lng: 19.5496135 },
    { lat: 49.2072884, lng: 19.5494124 },
    { lat: 49.2073692, lng: 19.5492828 },
    { lat: 49.2073019, lng: 19.5490128 },
    { lat: 49.2072841, lng: 19.5489678 },
    { lat: 49.2072763, lng: 19.5489467 },
    { lat: 49.2072499, lng: 19.5488601 },
    { lat: 49.2072287, lng: 19.5487935 },
    { lat: 49.2071992, lng: 19.5487091 },
    { lat: 49.2070539, lng: 19.5482898 },
    { lat: 49.2070407, lng: 19.5482706 },
    { lat: 49.2070008, lng: 19.5482129 },
    { lat: 49.2069586, lng: 19.5481514 },
    { lat: 49.2069332, lng: 19.5481123 },
    { lat: 49.206863, lng: 19.5480051 },
    { lat: 49.2068533, lng: 19.5479902 },
    { lat: 49.2068163, lng: 19.5478922 },
    { lat: 49.2067754, lng: 19.5477855 },
    { lat: 49.2067306, lng: 19.5476673 },
    { lat: 49.2065472, lng: 19.5472773 },
    { lat: 49.2064573, lng: 19.5469813 },
    { lat: 49.2062744, lng: 19.5466506 },
    { lat: 49.206179, lng: 19.5463847 },
    { lat: 49.2062626, lng: 19.5461179 },
    { lat: 49.2062751, lng: 19.5457692 },
    { lat: 49.2062034, lng: 19.545683 },
    { lat: 49.2060536, lng: 19.545238 },
    { lat: 49.2058619, lng: 19.5448301 },
    { lat: 49.2058348, lng: 19.5446085 },
    { lat: 49.2057161, lng: 19.5443493 },
    { lat: 49.2053911, lng: 19.5440647 },
    { lat: 49.2052232, lng: 19.5439643 },
    { lat: 49.2050761, lng: 19.5439016 },
    { lat: 49.2048568, lng: 19.5437374 },
    { lat: 49.2047421, lng: 19.543592 },
    { lat: 49.2045916, lng: 19.5432822 },
    { lat: 49.2045125, lng: 19.543026 },
    { lat: 49.2043695, lng: 19.5429813 },
    { lat: 49.2042676, lng: 19.5427189 },
    { lat: 49.2042675, lng: 19.5427153 },
    { lat: 49.2040406, lng: 19.5422363 },
    { lat: 49.2038087, lng: 19.5417931 },
    { lat: 49.2038247, lng: 19.5415737 },
    { lat: 49.2033711, lng: 19.5414627 },
    { lat: 49.2015325, lng: 19.5403007 },
    { lat: 49.201201, lng: 19.5399206 },
    { lat: 49.1982865, lng: 19.5382375 },
    { lat: 49.1980866, lng: 19.5380994 },
    { lat: 49.1977393, lng: 19.5378483 },
    { lat: 49.197132, lng: 19.538071 },
    { lat: 49.1970003, lng: 19.5380986 },
    { lat: 49.1969605, lng: 19.5381165 },
    { lat: 49.1963906, lng: 19.5382473 },
    { lat: 49.1956159, lng: 19.5384019 },
    { lat: 49.1948772, lng: 19.5381183 },
    { lat: 49.1944902, lng: 19.5377323 },
    { lat: 49.1936956, lng: 19.5370791 },
    { lat: 49.19368, lng: 19.5370325 },
    { lat: 49.1935198, lng: 19.5368787 },
    { lat: 49.1932145, lng: 19.5361616 },
    { lat: 49.1928871, lng: 19.535752 },
    { lat: 49.1922208, lng: 19.5353195 },
    { lat: 49.190803, lng: 19.5353869 },
    { lat: 49.1902124, lng: 19.5346367 },
    { lat: 49.1897549, lng: 19.5343371 },
    { lat: 49.1894323, lng: 19.5333164 },
    { lat: 49.1889517, lng: 19.5324555 },
    { lat: 49.1883942, lng: 19.5313225 },
    { lat: 49.1878934, lng: 19.5301205 },
    { lat: 49.1879212, lng: 19.5299644 },
    { lat: 49.1874853, lng: 19.528207 },
    { lat: 49.1873925, lng: 19.5279012 },
    { lat: 49.1871327, lng: 19.5266219 },
    { lat: 49.1869899, lng: 19.5261348 },
    { lat: 49.185916, lng: 19.5244007 },
    { lat: 49.1856351, lng: 19.523598 },
    { lat: 49.1854124, lng: 19.5227142 },
    { lat: 49.1851195, lng: 19.522246 },
    { lat: 49.1846668, lng: 19.5217296 },
    { lat: 49.1844554, lng: 19.5214217 },
    { lat: 49.1840729, lng: 19.520914 },
    { lat: 49.1840235, lng: 19.5208187 },
    { lat: 49.1832556, lng: 19.5199424 },
    { lat: 49.1822308, lng: 19.5191621 },
    { lat: 49.181166, lng: 19.5186144 },
    { lat: 49.1805613, lng: 19.5179816 },
    { lat: 49.1798342, lng: 19.5175022 },
    { lat: 49.1787499, lng: 19.5165566 },
    { lat: 49.1785296, lng: 19.51637 },
    { lat: 49.1761683, lng: 19.5142528 },
    { lat: 49.1757634, lng: 19.5140196 },
    { lat: 49.1749963, lng: 19.5134484 },
  ],
  PavlovaVes: [
    { lat: 49.1942405, lng: 19.6377535 },
    { lat: 49.1922729, lng: 19.6334546 },
    { lat: 49.1922495, lng: 19.6321732 },
    { lat: 49.191809, lng: 19.6303775 },
    { lat: 49.1914883, lng: 19.6299854 },
    { lat: 49.19117, lng: 19.6296132 },
    { lat: 49.1909893, lng: 19.6294029 },
    { lat: 49.1903713, lng: 19.6286807 },
    { lat: 49.1898431, lng: 19.6281275 },
    { lat: 49.1896019, lng: 19.6278745 },
    { lat: 49.1895119, lng: 19.6277797 },
    { lat: 49.1890721, lng: 19.6271122 },
    { lat: 49.1889041, lng: 19.6268563 },
    { lat: 49.1881563, lng: 19.6269106 },
    { lat: 49.1878469, lng: 19.6267709 },
    { lat: 49.1874335, lng: 19.6265689 },
    { lat: 49.1868467, lng: 19.6264498 },
    { lat: 49.1863521, lng: 19.6263491 },
    { lat: 49.1853195, lng: 19.6256525 },
    { lat: 49.185128, lng: 19.6255233 },
    { lat: 49.1848103, lng: 19.6255516 },
    { lat: 49.1838318, lng: 19.6256374 },
    { lat: 49.1824962, lng: 19.6221952 },
    { lat: 49.1809707, lng: 19.6187874 },
    { lat: 49.1805739, lng: 19.617902 },
    { lat: 49.1804549, lng: 19.6176385 },
    { lat: 49.1803851, lng: 19.6170807 },
    { lat: 49.1803109, lng: 19.616482 },
    { lat: 49.1803074, lng: 19.6164514 },
    { lat: 49.1802182, lng: 19.6162471 },
    { lat: 49.1797545, lng: 19.6152002 },
    { lat: 49.1796437, lng: 19.6149675 },
    { lat: 49.1793678, lng: 19.614415 },
    { lat: 49.1789082, lng: 19.6133825 },
    { lat: 49.1789495, lng: 19.6132233 },
    { lat: 49.1790916, lng: 19.6128488 },
    { lat: 49.1790608, lng: 19.6127864 },
    { lat: 49.1786965, lng: 19.612228 },
    { lat: 49.1786405, lng: 19.6120314 },
    { lat: 49.1784191, lng: 19.6115907 },
    { lat: 49.1780332, lng: 19.6109218 },
    { lat: 49.1781121, lng: 19.6109013 },
    { lat: 49.1772756, lng: 19.6106863 },
    { lat: 49.1770974, lng: 19.6104802 },
    { lat: 49.1770037, lng: 19.6103769 },
    { lat: 49.1768146, lng: 19.6101809 },
    { lat: 49.1767114, lng: 19.6100908 },
    { lat: 49.1766413, lng: 19.6098799 },
    { lat: 49.1764677, lng: 19.6097514 },
    { lat: 49.1762942, lng: 19.6094774 },
    { lat: 49.1760696, lng: 19.6091648 },
    { lat: 49.175863, lng: 19.6089807 },
    { lat: 49.1757935, lng: 19.6087154 },
    { lat: 49.1758423, lng: 19.6084924 },
    { lat: 49.1758291, lng: 19.6081974 },
    { lat: 49.1757928, lng: 19.6080201 },
    { lat: 49.1757846, lng: 19.6078574 },
    { lat: 49.1757366, lng: 19.6076187 },
    { lat: 49.1757126, lng: 19.6075365 },
    { lat: 49.1756082, lng: 19.6072662 },
    { lat: 49.1755428, lng: 19.6071664 },
    { lat: 49.1754658, lng: 19.6070801 },
    { lat: 49.1754474, lng: 19.6069981 },
    { lat: 49.1754048, lng: 19.6068272 },
    { lat: 49.1753431, lng: 19.6066082 },
    { lat: 49.1751995, lng: 19.6062177 },
    { lat: 49.1751416, lng: 19.6061312 },
    { lat: 49.1750502, lng: 19.605952 },
    { lat: 49.1749722, lng: 19.605797 },
    { lat: 49.1748842, lng: 19.6056182 },
    { lat: 49.1748558, lng: 19.6055596 },
    { lat: 49.1747517, lng: 19.6053497 },
    { lat: 49.1746569, lng: 19.6051734 },
    { lat: 49.1745851, lng: 19.6052025 },
    { lat: 49.1744945, lng: 19.6049689 },
    { lat: 49.1742814, lng: 19.6048647 },
    { lat: 49.1739863, lng: 19.6046636 },
    { lat: 49.1738577, lng: 19.6045337 },
    { lat: 49.1737296, lng: 19.6044423 },
    { lat: 49.1735706, lng: 19.6042403 },
    { lat: 49.1734075, lng: 19.6041921 },
    { lat: 49.1733058, lng: 19.604162 },
    { lat: 49.1731607, lng: 19.60409 },
    { lat: 49.1730004, lng: 19.6040862 },
    { lat: 49.1729716, lng: 19.6040687 },
    { lat: 49.1729203, lng: 19.6039581 },
    { lat: 49.1728537, lng: 19.6039053 },
    { lat: 49.1726908, lng: 19.6037094 },
    { lat: 49.1725665, lng: 19.6037026 },
    { lat: 49.1724714, lng: 19.6036455 },
    { lat: 49.172448, lng: 19.6035972 },
    { lat: 49.1724064, lng: 19.6035486 },
    { lat: 49.1723666, lng: 19.6035405 },
    { lat: 49.1723024, lng: 19.6035933 },
    { lat: 49.1722664, lng: 19.6035956 },
    { lat: 49.1722162, lng: 19.6033758 },
    { lat: 49.1721214, lng: 19.6033526 },
    { lat: 49.1720864, lng: 19.6033245 },
    { lat: 49.1720524, lng: 19.6031934 },
    { lat: 49.171958, lng: 19.6031467 },
    { lat: 49.1719222, lng: 19.6031011 },
    { lat: 49.1718834, lng: 19.6030145 },
    { lat: 49.1718477, lng: 19.6029771 },
    { lat: 49.1718027, lng: 19.6029271 },
    { lat: 49.1717318, lng: 19.6029042 },
    { lat: 49.1716859, lng: 19.602859 },
    { lat: 49.1716497, lng: 19.6027587 },
    { lat: 49.1715962, lng: 19.602617 },
    { lat: 49.1715213, lng: 19.6025805 },
    { lat: 49.171447, lng: 19.602356 },
    { lat: 49.171272, lng: 19.6021698 },
    { lat: 49.1712659, lng: 19.6019126 },
    { lat: 49.1711783, lng: 19.6018655 },
    { lat: 49.171124, lng: 19.6017337 },
    { lat: 49.1710766, lng: 19.6014545 },
    { lat: 49.1710196, lng: 19.6014425 },
    { lat: 49.1709835, lng: 19.6013422 },
    { lat: 49.1709729, lng: 19.601129 },
    { lat: 49.1708545, lng: 19.6009783 },
    { lat: 49.1706266, lng: 19.6001408 },
    { lat: 49.1705843, lng: 19.5999025 },
    { lat: 49.1704764, lng: 19.5997341 },
    { lat: 49.1704261, lng: 19.5996396 },
    { lat: 49.1704275, lng: 19.5995437 },
    { lat: 49.1704096, lng: 19.5994224 },
    { lat: 49.1703646, lng: 19.5992254 },
    { lat: 49.1703442, lng: 19.5992452 },
    { lat: 49.1703073, lng: 19.5992808 },
    { lat: 49.1694812, lng: 19.6000533 },
    { lat: 49.1693862, lng: 19.6002447 },
    { lat: 49.1689589, lng: 19.6011145 },
    { lat: 49.1681565, lng: 19.6013621 },
    { lat: 49.1681087, lng: 19.6013764 },
    { lat: 49.1674704, lng: 19.601537 },
    { lat: 49.1671213, lng: 19.6016911 },
    { lat: 49.1669549, lng: 19.6017769 },
    { lat: 49.1667976, lng: 19.6018585 },
    { lat: 49.1666281, lng: 19.6019485 },
    { lat: 49.1665277, lng: 19.6020004 },
    { lat: 49.1664542, lng: 19.6020405 },
    { lat: 49.1657256, lng: 19.6024236 },
    { lat: 49.164697, lng: 19.6025766 },
    { lat: 49.1645355, lng: 19.6025665 },
    { lat: 49.1644564, lng: 19.6025614 },
    { lat: 49.1643965, lng: 19.6025576 },
    { lat: 49.1640715, lng: 19.6024698 },
    { lat: 49.1640498, lng: 19.6024645 },
    { lat: 49.1640292, lng: 19.6024587 },
    { lat: 49.1638061, lng: 19.6026595 },
    { lat: 49.1637612, lng: 19.6026112 },
    { lat: 49.1636666, lng: 19.602512 },
    { lat: 49.1635919, lng: 19.6024582 },
    { lat: 49.1634931, lng: 19.6023382 },
    { lat: 49.1632899, lng: 19.6025049 },
    { lat: 49.163192, lng: 19.6024056 },
    { lat: 49.1631143, lng: 19.6024044 },
    { lat: 49.1629033, lng: 19.6021225 },
    { lat: 49.1628407, lng: 19.6020578 },
    { lat: 49.1628153, lng: 19.6020449 },
    { lat: 49.1627188, lng: 19.6021029 },
    { lat: 49.1626697, lng: 19.6020893 },
    { lat: 49.1626292, lng: 19.6020424 },
    { lat: 49.1624093, lng: 19.6019368 },
    { lat: 49.1623323, lng: 19.601951 },
    { lat: 49.1622818, lng: 19.6020088 },
    { lat: 49.1621094, lng: 19.6020077 },
    { lat: 49.1620182, lng: 19.6018858 },
    { lat: 49.1619487, lng: 19.6018409 },
    { lat: 49.1618874, lng: 19.6018534 },
    { lat: 49.1618372, lng: 19.6018201 },
    { lat: 49.1617131, lng: 19.6016897 },
    { lat: 49.1615935, lng: 19.6016331 },
    { lat: 49.1615315, lng: 19.6015557 },
    { lat: 49.1614873, lng: 19.6015236 },
    { lat: 49.1614411, lng: 19.6014251 },
    { lat: 49.1613902, lng: 19.6013447 },
    { lat: 49.1613516, lng: 19.6012404 },
    { lat: 49.1612663, lng: 19.6008918 },
    { lat: 49.1612564, lng: 19.6007503 },
    { lat: 49.1612464, lng: 19.6006015 },
    { lat: 49.1611626, lng: 19.6004911 },
    { lat: 49.1611242, lng: 19.6004404 },
    { lat: 49.1610288, lng: 19.6002246 },
    { lat: 49.1609829, lng: 19.6001272 },
    { lat: 49.1609109, lng: 19.6000069 },
    { lat: 49.1608922, lng: 19.5998908 },
    { lat: 49.1609613, lng: 19.599745 },
    { lat: 49.1609683, lng: 19.5997288 },
    { lat: 49.1609849, lng: 19.5996943 },
    { lat: 49.1609942, lng: 19.5996008 },
    { lat: 49.1610109, lng: 19.5995209 },
    { lat: 49.1609931, lng: 19.5991023 },
    { lat: 49.1608657, lng: 19.599 },
    { lat: 49.1607174, lng: 19.5988841 },
    { lat: 49.1603174, lng: 19.5986562 },
    { lat: 49.1602734, lng: 19.598627 },
    { lat: 49.1601808, lng: 19.5984746 },
    { lat: 49.1600024, lng: 19.5984126 },
    { lat: 49.159896, lng: 19.5983784 },
    { lat: 49.1597828, lng: 19.5983423 },
    { lat: 49.1596915, lng: 19.5981916 },
    { lat: 49.1596645, lng: 19.5981635 },
    { lat: 49.1595638, lng: 19.5981301 },
    { lat: 49.1595166, lng: 19.5981365 },
    { lat: 49.1593015, lng: 19.5982342 },
    { lat: 49.1592483, lng: 19.5981065 },
    { lat: 49.1592072, lng: 19.5980694 },
    { lat: 49.1590986, lng: 19.5980062 },
    { lat: 49.1590483, lng: 19.5979906 },
    { lat: 49.1589331, lng: 19.5979864 },
    { lat: 49.1587743, lng: 19.597936 },
    { lat: 49.1586319, lng: 19.5980035 },
    { lat: 49.1585791, lng: 19.5979571 },
    { lat: 49.1583974, lng: 19.5977957 },
    { lat: 49.1583441, lng: 19.5978128 },
    { lat: 49.1583129, lng: 19.5978233 },
    { lat: 49.1582419, lng: 19.5978447 },
    { lat: 49.1581606, lng: 19.5978446 },
    { lat: 49.1581502, lng: 19.5977647 },
    { lat: 49.1579795, lng: 19.5977742 },
    { lat: 49.1579269, lng: 19.5977819 },
    { lat: 49.1577862, lng: 19.5979125 },
    { lat: 49.1576709, lng: 19.5980789 },
    { lat: 49.1576406, lng: 19.5980821 },
    { lat: 49.1575272, lng: 19.5979665 },
    { lat: 49.1574927, lng: 19.5979499 },
    { lat: 49.1573669, lng: 19.5980336 },
    { lat: 49.1573243, lng: 19.5980359 },
    { lat: 49.1570473, lng: 19.5979925 },
    { lat: 49.1568533, lng: 19.5979645 },
    { lat: 49.1567017, lng: 19.5979994 },
    { lat: 49.1566132, lng: 19.5980322 },
    { lat: 49.1564425, lng: 19.5980211 },
    { lat: 49.1564015, lng: 19.5980397 },
    { lat: 49.1562794, lng: 19.5981536 },
    { lat: 49.1560762, lng: 19.5981928 },
    { lat: 49.1559681, lng: 19.5983452 },
    { lat: 49.1559184, lng: 19.5983947 },
    { lat: 49.1557723, lng: 19.598474 },
    { lat: 49.155669, lng: 19.5985355 },
    { lat: 49.1555747, lng: 19.5987912 },
    { lat: 49.1554044, lng: 19.5987417 },
    { lat: 49.1553504, lng: 19.5987437 },
    { lat: 49.1552945, lng: 19.5990487 },
    { lat: 49.1551229, lng: 19.5990177 },
    { lat: 49.1550745, lng: 19.5990983 },
    { lat: 49.1550214, lng: 19.5991672 },
    { lat: 49.1548981, lng: 19.5992145 },
    { lat: 49.1549186, lng: 19.5993104 },
    { lat: 49.1547955, lng: 19.5993034 },
    { lat: 49.1547263, lng: 19.5992711 },
    { lat: 49.1546414, lng: 19.5991842 },
    { lat: 49.1541002, lng: 19.5997557 },
    { lat: 49.1539048, lng: 19.5998953 },
    { lat: 49.1538116, lng: 19.5999018 },
    { lat: 49.1535153, lng: 19.5998488 },
    { lat: 49.153126, lng: 19.6002112 },
    { lat: 49.1531135, lng: 19.6003129 },
    { lat: 49.1528872, lng: 19.6005144 },
    { lat: 49.1526624, lng: 19.6006764 },
    { lat: 49.1525017, lng: 19.6006626 },
    { lat: 49.1522678, lng: 19.6009227 },
    { lat: 49.152145, lng: 19.6011468 },
    { lat: 49.1521184, lng: 19.6012853 },
    { lat: 49.1519542, lng: 19.6012412 },
    { lat: 49.1517536, lng: 19.6012902 },
    { lat: 49.1515697, lng: 19.6020649 },
    { lat: 49.1514155, lng: 19.6033465 },
    { lat: 49.1516253, lng: 19.6037828 },
    { lat: 49.1522671, lng: 19.6050534 },
    { lat: 49.1524967, lng: 19.6055248 },
    { lat: 49.1532435, lng: 19.6059458 },
    { lat: 49.1536244, lng: 19.6061477 },
    { lat: 49.1537987, lng: 19.6063915 },
    { lat: 49.1546412, lng: 19.6059114 },
    { lat: 49.1554751, lng: 19.6053375 },
    { lat: 49.1562235, lng: 19.60469 },
    { lat: 49.156709, lng: 19.6044594 },
    { lat: 49.1573604, lng: 19.6041391 },
    { lat: 49.157981, lng: 19.6039589 },
    { lat: 49.1584481, lng: 19.6037705 },
    { lat: 49.1591819, lng: 19.6035291 },
    { lat: 49.1596748, lng: 19.6043701 },
    { lat: 49.1599685, lng: 19.6050933 },
    { lat: 49.1600334, lng: 19.6053076 },
    { lat: 49.1602468, lng: 19.6057238 },
    { lat: 49.1608498, lng: 19.6067302 },
    { lat: 49.1611543, lng: 19.6075189 },
    { lat: 49.1612096, lng: 19.6076733 },
    { lat: 49.1612459, lng: 19.6078034 },
    { lat: 49.1619483, lng: 19.6096484 },
    { lat: 49.1623951, lng: 19.6108197 },
    { lat: 49.1629021, lng: 19.6113717 },
    { lat: 49.1634111, lng: 19.6120993 },
    { lat: 49.1635971, lng: 19.6123513 },
    { lat: 49.1638097, lng: 19.612924 },
    { lat: 49.164334, lng: 19.61308 },
    { lat: 49.1648099, lng: 19.6131687 },
    { lat: 49.1652111, lng: 19.6136234 },
    { lat: 49.1658531, lng: 19.6140207 },
    { lat: 49.1661244, lng: 19.6142185 },
    { lat: 49.166211, lng: 19.6142632 },
    { lat: 49.166499, lng: 19.6148607 },
    { lat: 49.1668912, lng: 19.6152667 },
    { lat: 49.1671367, lng: 19.6153395 },
    { lat: 49.167421, lng: 19.6155007 },
    { lat: 49.1695261, lng: 19.6170506 },
    { lat: 49.1695411, lng: 19.617861 },
    { lat: 49.1695574, lng: 19.6218311 },
    { lat: 49.1701692, lng: 19.6229643 },
    { lat: 49.1730142, lng: 19.6292304 },
    { lat: 49.1748735, lng: 19.6306243 },
    { lat: 49.1764653, lng: 19.6318142 },
    { lat: 49.1769236, lng: 19.6321333 },
    { lat: 49.1780115, lng: 19.6328332 },
    { lat: 49.1794693, lng: 19.6331876 },
    { lat: 49.1799554, lng: 19.6333993 },
    { lat: 49.1803019, lng: 19.6335143 },
    { lat: 49.181284, lng: 19.6337621 },
    { lat: 49.1817775, lng: 19.6341935 },
    { lat: 49.1830378, lng: 19.6352714 },
    { lat: 49.1832299, lng: 19.6354611 },
    { lat: 49.1835557, lng: 19.635794 },
    { lat: 49.1841064, lng: 19.6361421 },
    { lat: 49.1859681, lng: 19.6372918 },
    { lat: 49.1869925, lng: 19.6377532 },
    { lat: 49.1888141, lng: 19.6387138 },
    { lat: 49.1894644, lng: 19.6390732 },
    { lat: 49.1905827, lng: 19.6396719 },
    { lat: 49.1909327, lng: 19.6398914 },
    { lat: 49.1929286, lng: 19.6385881 },
    { lat: 49.1942405, lng: 19.6377535 },
  ],
  PavlovaVesExt: [
    { lat: 49.1325031, lng: 19.5987273 },
    { lat: 49.132549, lng: 19.5986714 },
    { lat: 49.1325776, lng: 19.5986033 },
    { lat: 49.1325764, lng: 19.598557 },
    { lat: 49.1325526, lng: 19.5984519 },
    { lat: 49.1324732, lng: 19.5983149 },
    { lat: 49.1324241, lng: 19.5981977 },
    { lat: 49.1323889, lng: 19.5981923 },
    { lat: 49.132321, lng: 19.5981364 },
    { lat: 49.1322792, lng: 19.5980321 },
    { lat: 49.1321778, lng: 19.5978808 },
    { lat: 49.1321162, lng: 19.5978401 },
    { lat: 49.1320901, lng: 19.597759 },
    { lat: 49.132093, lng: 19.597677 },
    { lat: 49.1320424, lng: 19.5976017 },
    { lat: 49.1319945, lng: 19.5974901 },
    { lat: 49.1319693, lng: 19.5973298 },
    { lat: 49.1318541, lng: 19.5971454 },
    { lat: 49.1318299, lng: 19.5969122 },
    { lat: 49.1317032, lng: 19.5966981 },
    { lat: 49.1317543, lng: 19.5964329 },
    { lat: 49.1317977, lng: 19.5962946 },
    { lat: 49.1318671, lng: 19.5963111 },
    { lat: 49.1321526, lng: 19.5963446 },
    { lat: 49.1322085, lng: 19.5956537 },
    { lat: 49.1322962, lng: 19.5953258 },
    { lat: 49.1324205, lng: 19.5950599 },
    { lat: 49.1325664, lng: 19.594845 },
    { lat: 49.1328589, lng: 19.5944874 },
    { lat: 49.132985, lng: 19.5943558 },
    { lat: 49.1331913, lng: 19.5941864 },
    { lat: 49.1334058, lng: 19.5941212 },
    { lat: 49.1336608, lng: 19.5941791 },
    { lat: 49.1338573, lng: 19.5936624 },
    { lat: 49.1343585, lng: 19.5926573 },
    { lat: 49.1343088, lng: 19.592329 },
    { lat: 49.1340627, lng: 19.5907369 },
    { lat: 49.1345543, lng: 19.5897673 },
    { lat: 49.1346099, lng: 19.5896802 },
    { lat: 49.1347, lng: 19.5896003 },
    { lat: 49.1347465, lng: 19.5895846 },
    { lat: 49.1348388, lng: 19.5897308 },
    { lat: 49.1351671, lng: 19.5901167 },
    { lat: 49.1351887, lng: 19.5900454 },
    { lat: 49.1359165, lng: 19.5886171 },
    { lat: 49.1364814, lng: 19.587475 },
    { lat: 49.1366795, lng: 19.5865943 },
    { lat: 49.1369301, lng: 19.5858066 },
    { lat: 49.1358476, lng: 19.5845572 },
    { lat: 49.1355301, lng: 19.5842126 },
    { lat: 49.1348742, lng: 19.5836094 },
    { lat: 49.1346337, lng: 19.5833489 },
    { lat: 49.1343515, lng: 19.582888 },
    { lat: 49.1337253, lng: 19.5815434 },
    { lat: 49.1333605, lng: 19.5809001 },
    { lat: 49.1329503, lng: 19.5801947 },
    { lat: 49.1325475, lng: 19.5796604 },
    { lat: 49.1323847, lng: 19.5793234 },
    { lat: 49.1321058, lng: 19.578882 },
    { lat: 49.1319708, lng: 19.578165 },
    { lat: 49.1318102, lng: 19.5776763 },
    { lat: 49.1315629, lng: 19.5771417 },
    { lat: 49.1310631, lng: 19.5760006 },
    { lat: 49.1301398, lng: 19.5741135 },
    { lat: 49.1299629, lng: 19.5736402 },
    { lat: 49.1299466, lng: 19.5736505 },
    { lat: 49.1299003, lng: 19.5734776 },
    { lat: 49.1297153, lng: 19.5728465 },
    { lat: 49.1293473, lng: 19.5715827 },
    { lat: 49.1292486, lng: 19.5716682 },
    { lat: 49.1285931, lng: 19.570703 },
    { lat: 49.1278277, lng: 19.5695971 },
    { lat: 49.1271993, lng: 19.5686794 },
    { lat: 49.1270163, lng: 19.5681981 },
    { lat: 49.1264712, lng: 19.566731 },
    { lat: 49.126268, lng: 19.5661746 },
    { lat: 49.1259908, lng: 19.5654262 },
    { lat: 49.125277, lng: 19.563466 },
    { lat: 49.1249763, lng: 19.5635213 },
    { lat: 49.1245725, lng: 19.5641354 },
    { lat: 49.124512, lng: 19.564218 },
    { lat: 49.1232046, lng: 19.5632347 },
    { lat: 49.1222759, lng: 19.5661205 },
    { lat: 49.1211952, lng: 19.5683588 },
    { lat: 49.1208631, lng: 19.5694383 },
    { lat: 49.1205981, lng: 19.5705542 },
    { lat: 49.1204761, lng: 19.571498 },
    { lat: 49.1204574, lng: 19.5716578 },
    { lat: 49.1202743, lng: 19.5747875 },
    { lat: 49.1203049, lng: 19.5758642 },
    { lat: 49.1203202, lng: 19.5760824 },
    { lat: 49.1204554, lng: 19.5768819 },
    { lat: 49.1204919, lng: 19.5771619 },
    { lat: 49.1205537, lng: 19.5778619 },
    { lat: 49.120555, lng: 19.578561 },
    { lat: 49.1205232, lng: 19.578954 },
    { lat: 49.1206055, lng: 19.5796076 },
    { lat: 49.1210169, lng: 19.5805358 },
    { lat: 49.121881, lng: 19.5818083 },
    { lat: 49.1218535, lng: 19.5844264 },
    { lat: 49.1219527, lng: 19.5845743 },
    { lat: 49.122411, lng: 19.58577 },
    { lat: 49.1231189, lng: 19.5871025 },
    { lat: 49.1238116, lng: 19.5889007 },
    { lat: 49.1257998, lng: 19.5891901 },
    { lat: 49.1275047, lng: 19.5899726 },
    { lat: 49.1275779, lng: 19.589794 },
    { lat: 49.1284411, lng: 19.5905497 },
    { lat: 49.1292859, lng: 19.592452 },
    { lat: 49.1292746, lng: 19.5928508 },
    { lat: 49.1292114, lng: 19.5949005 },
    { lat: 49.1295477, lng: 19.5958911 },
    { lat: 49.1299618, lng: 19.5963308 },
    { lat: 49.1308429, lng: 19.597362 },
    { lat: 49.1325031, lng: 19.5987273 },
  ],
  PavlovaVesExt1: [
    { lat: 49.1519966, lng: 19.5921239 },
    { lat: 49.1513095, lng: 19.5924541 },
    { lat: 49.151148, lng: 19.5924231 },
    { lat: 49.1506656, lng: 19.5923209 },
    { lat: 49.1499578, lng: 19.592085 },
    { lat: 49.1494694, lng: 19.5916994 },
    { lat: 49.1492368, lng: 19.5915139 },
    { lat: 49.1491242, lng: 19.5914134 },
    { lat: 49.1490184, lng: 19.5912139 },
    { lat: 49.1486428, lng: 19.5905527 },
    { lat: 49.1484454, lng: 19.5901739 },
    { lat: 49.1483096, lng: 19.589912 },
    { lat: 49.1482566, lng: 19.5898089 },
    { lat: 49.1480548, lng: 19.589379 },
    { lat: 49.1477234, lng: 19.5883523 },
    { lat: 49.1474702, lng: 19.5882297 },
    { lat: 49.1474014, lng: 19.588206 },
    { lat: 49.147205, lng: 19.5880966 },
    { lat: 49.1471555, lng: 19.5879265 },
    { lat: 49.1471429, lng: 19.587872 },
    { lat: 49.1471659, lng: 19.5878325 },
    { lat: 49.1470612, lng: 19.5876027 },
    { lat: 49.1470249, lng: 19.587527 },
    { lat: 49.1469635, lng: 19.5873905 },
    { lat: 49.1464943, lng: 19.5864275 },
    { lat: 49.146455, lng: 19.5865033 },
    { lat: 49.1463836, lng: 19.5865701 },
    { lat: 49.1463575, lng: 19.5866153 },
    { lat: 49.1463, lng: 19.5866177 },
    { lat: 49.1461892, lng: 19.5866826 },
    { lat: 49.1461052, lng: 19.5866687 },
    { lat: 49.1457472, lng: 19.5868512 },
    { lat: 49.1456325, lng: 19.5868776 },
    { lat: 49.1455268, lng: 19.5870103 },
    { lat: 49.1453829, lng: 19.5870653 },
    { lat: 49.1452, lng: 19.5872564 },
    { lat: 49.145509, lng: 19.5874911 },
    { lat: 49.145818, lng: 19.5877478 },
    { lat: 49.1463799, lng: 19.5880148 },
    { lat: 49.1466176, lng: 19.5883642 },
    { lat: 49.146864, lng: 19.588504 },
    { lat: 49.1470713, lng: 19.5887048 },
    { lat: 49.1472633, lng: 19.5889165 },
    { lat: 49.147361, lng: 19.5891866 },
    { lat: 49.1474149, lng: 19.5892839 },
    { lat: 49.1476702, lng: 19.5896474 },
    { lat: 49.1481343, lng: 19.5900966 },
    { lat: 49.1478572, lng: 19.5906496 },
    { lat: 49.147638, lng: 19.5911357 },
    { lat: 49.1474156, lng: 19.5917766 },
    { lat: 49.1482953, lng: 19.592602 },
    { lat: 49.1488503, lng: 19.5930163 },
    { lat: 49.1494366, lng: 19.5933736 },
    { lat: 49.1497446, lng: 19.5935092 },
    { lat: 49.1499728, lng: 19.593548 },
    { lat: 49.1507293, lng: 19.5934866 },
    { lat: 49.1509051, lng: 19.5935114 },
    { lat: 49.1511031, lng: 19.5934572 },
    { lat: 49.1512165, lng: 19.5934947 },
    { lat: 49.1513098, lng: 19.5934644 },
    { lat: 49.1514754, lng: 19.5933399 },
    { lat: 49.1516776, lng: 19.5931269 },
    { lat: 49.1517832, lng: 19.5928924 },
    { lat: 49.151757, lng: 19.5928404 },
    { lat: 49.1520114, lng: 19.5922285 },
    { lat: 49.1519966, lng: 19.5921239 },
  ],
  LiptovskýHrádok: [
    { lat: 49.0845821, lng: 19.7938172 },
    { lat: 49.0843637, lng: 19.7936152 },
    { lat: 49.0839227, lng: 19.7932102 },
    { lat: 49.0839114, lng: 19.7933159 },
    { lat: 49.0835997, lng: 19.7931926 },
    { lat: 49.0834339, lng: 19.7931093 },
    { lat: 49.0832755, lng: 19.7931475 },
    { lat: 49.0831591, lng: 19.793267 },
    { lat: 49.0829374, lng: 19.7935322 },
    { lat: 49.0827, lng: 19.7936444 },
    { lat: 49.0821466, lng: 19.7935881 },
    { lat: 49.0815964, lng: 19.7933925 },
    { lat: 49.0809841, lng: 19.7932462 },
    { lat: 49.0807765, lng: 19.7932649 },
    { lat: 49.0804906, lng: 19.7931933 },
    { lat: 49.0803492, lng: 19.7932316 },
    { lat: 49.080004, lng: 19.7931415 },
    { lat: 49.0794582, lng: 19.7925864 },
    { lat: 49.0791386, lng: 19.7925376 },
    { lat: 49.0790578, lng: 19.7926499 },
    { lat: 49.0788545, lng: 19.7926923 },
    { lat: 49.078655, lng: 19.7926645 },
    { lat: 49.078452, lng: 19.7922666 },
    { lat: 49.078094, lng: 19.7921932 },
    { lat: 49.0777437, lng: 19.7919847 },
    { lat: 49.077521, lng: 19.7917591 },
    { lat: 49.0773478, lng: 19.7914783 },
    { lat: 49.0771991, lng: 19.7909876 },
    { lat: 49.0771602, lng: 19.790634 },
    { lat: 49.0771517, lng: 19.7903621 },
    { lat: 49.0766774, lng: 19.7900978 },
    { lat: 49.0763104, lng: 19.7899215 },
    { lat: 49.0758621, lng: 19.7897412 },
    { lat: 49.0756303, lng: 19.7895938 },
    { lat: 49.0753911, lng: 19.7894489 },
    { lat: 49.075141, lng: 19.7892196 },
    { lat: 49.0750404, lng: 19.789054 },
    { lat: 49.0749524, lng: 19.7887398 },
    { lat: 49.0747658, lng: 19.7885678 },
    { lat: 49.0743669, lng: 19.7883825 },
    { lat: 49.0739888, lng: 19.7881068 },
    { lat: 49.0736683, lng: 19.7880649 },
    { lat: 49.0734843, lng: 19.7880841 },
    { lat: 49.0732159, lng: 19.7879975 },
    { lat: 49.0730995, lng: 19.7878874 },
    { lat: 49.0729867, lng: 19.7878068 },
    { lat: 49.072677, lng: 19.7876765 },
    { lat: 49.0724413, lng: 19.7872281 },
    { lat: 49.0724204, lng: 19.7871641 },
    { lat: 49.0722878, lng: 19.7869855 },
    { lat: 49.0721217, lng: 19.7867715 },
    { lat: 49.0719955, lng: 19.7865057 },
    { lat: 49.0717477, lng: 19.7862518 },
    { lat: 49.0716445, lng: 19.7859718 },
    { lat: 49.0715682, lng: 19.7857488 },
    { lat: 49.0715424, lng: 19.7854916 },
    { lat: 49.0712564, lng: 19.7850792 },
    { lat: 49.0711796, lng: 19.7847064 },
    { lat: 49.0710518, lng: 19.7843847 },
    { lat: 49.0706024, lng: 19.783269 },
    { lat: 49.0705384, lng: 19.7826754 },
    { lat: 49.0703815, lng: 19.7822032 },
    { lat: 49.0703364, lng: 19.7817076 },
    { lat: 49.0703329, lng: 19.7813171 },
    { lat: 49.0703156, lng: 19.7810504 },
    { lat: 49.0701634, lng: 19.7809651 },
    { lat: 49.0698376, lng: 19.7806257 },
    { lat: 49.0697544, lng: 19.7804996 },
    { lat: 49.0696424, lng: 19.780305 },
    { lat: 49.0695792, lng: 19.7802737 },
    { lat: 49.0695404, lng: 19.779951 },
    { lat: 49.0696857, lng: 19.7797212 },
    { lat: 49.0696909, lng: 19.7796545 },
    { lat: 49.0696497, lng: 19.7794345 },
    { lat: 49.0695184, lng: 19.779212 },
    { lat: 49.0692016, lng: 19.7788968 },
    { lat: 49.0690107, lng: 19.7787015 },
    { lat: 49.0687897, lng: 19.778021 },
    { lat: 49.0687725, lng: 19.7775204 },
    { lat: 49.0687605, lng: 19.7770125 },
    { lat: 49.0687534, lng: 19.7765656 },
    { lat: 49.0687422, lng: 19.7761291 },
    { lat: 49.0686932, lng: 19.7757776 },
    { lat: 49.0686538, lng: 19.7754636 },
    { lat: 49.0684434, lng: 19.7749006 },
    { lat: 49.0682943, lng: 19.7746864 },
    { lat: 49.068138, lng: 19.774849 },
    { lat: 49.0679947, lng: 19.7748642 },
    { lat: 49.0677131, lng: 19.7746856 },
    { lat: 49.067329, lng: 19.7743003 },
    { lat: 49.0672591, lng: 19.7742714 },
    { lat: 49.0670744, lng: 19.7742746 },
    { lat: 49.0667654, lng: 19.7743186 },
    { lat: 49.0666439, lng: 19.7742942 },
    { lat: 49.066494, lng: 19.7741911 },
    { lat: 49.0664091, lng: 19.7740782 },
    { lat: 49.0663724, lng: 19.7740104 },
    { lat: 49.0663532, lng: 19.7739322 },
    { lat: 49.0662877, lng: 19.7737987 },
    { lat: 49.0663767, lng: 19.7736967 },
    { lat: 49.066257, lng: 19.7735819 },
    { lat: 49.0653714, lng: 19.7727485 },
    { lat: 49.0652477, lng: 19.7726287 },
    { lat: 49.0650209, lng: 19.7723304 },
    { lat: 49.0649768, lng: 19.7718872 },
    { lat: 49.0649286, lng: 19.7714513 },
    { lat: 49.0647679, lng: 19.7708129 },
    { lat: 49.0648813, lng: 19.7708586 },
    { lat: 49.0648534, lng: 19.7706555 },
    { lat: 49.064554, lng: 19.7703009 },
    { lat: 49.0641365, lng: 19.7697948 },
    { lat: 49.0640604, lng: 19.7697266 },
    { lat: 49.0638259, lng: 19.7695408 },
    { lat: 49.0636867, lng: 19.7693684 },
    { lat: 49.0636623, lng: 19.7693288 },
    { lat: 49.0635013, lng: 19.7691219 },
    { lat: 49.0633425, lng: 19.7690488 },
    { lat: 49.0632435, lng: 19.7690229 },
    { lat: 49.0630615, lng: 19.7688286 },
    { lat: 49.0629905, lng: 19.7685911 },
    { lat: 49.0626469, lng: 19.7674819 },
    { lat: 49.0626217, lng: 19.7671568 },
    { lat: 49.0626904, lng: 19.7669038 },
    { lat: 49.0627113, lng: 19.7663466 },
    { lat: 49.0626825, lng: 19.7661735 },
    { lat: 49.0625597, lng: 19.7659445 },
    { lat: 49.0624363, lng: 19.765621 },
    { lat: 49.062408, lng: 19.7658481 },
    { lat: 49.0623459, lng: 19.7658223 },
    { lat: 49.0620867, lng: 19.7655826 },
    { lat: 49.0619431, lng: 19.7654197 },
    { lat: 49.0619444, lng: 19.7651602 },
    { lat: 49.0617233, lng: 19.7649741 },
    { lat: 49.0614376, lng: 19.7649354 },
    { lat: 49.0609342, lng: 19.7647325 },
    { lat: 49.0607785, lng: 19.7646731 },
    { lat: 49.0606246, lng: 19.7645004 },
    { lat: 49.0603894, lng: 19.7642508 },
    { lat: 49.0602381, lng: 19.7640825 },
    { lat: 49.0601777, lng: 19.764044 },
    { lat: 49.0599899, lng: 19.7637652 },
    { lat: 49.0598066, lng: 19.763308 },
    { lat: 49.059595, lng: 19.762612 },
    { lat: 49.0594938, lng: 19.7620985 },
    { lat: 49.0593681, lng: 19.7617771 },
    { lat: 49.05916, lng: 19.7614971 },
    { lat: 49.0587513, lng: 19.7612477 },
    { lat: 49.0583703, lng: 19.7610853 },
    { lat: 49.0580893, lng: 19.7610224 },
    { lat: 49.0579062, lng: 19.7610373 },
    { lat: 49.057683, lng: 19.7611367 },
    { lat: 49.0575861, lng: 19.7611066 },
    { lat: 49.0574681, lng: 19.761037 },
    { lat: 49.0570803, lng: 19.7608218 },
    { lat: 49.0569875, lng: 19.7609177 },
    { lat: 49.0569665, lng: 19.7609037 },
    { lat: 49.0569127, lng: 19.7608326 },
    { lat: 49.0566894, lng: 19.7605678 },
    { lat: 49.0559126, lng: 19.7595693 },
    { lat: 49.0554815, lng: 19.7590101 },
    { lat: 49.0549672, lng: 19.7583512 },
    { lat: 49.0546335, lng: 19.7579305 },
    { lat: 49.0539299, lng: 19.7571169 },
    { lat: 49.0533875, lng: 19.7564856 },
    { lat: 49.0525343, lng: 19.7557266 },
    { lat: 49.0520121, lng: 19.75525 },
    { lat: 49.0517672, lng: 19.7550312 },
    { lat: 49.051604, lng: 19.7548778 },
    { lat: 49.0512965, lng: 19.7545992 },
    { lat: 49.0511731, lng: 19.7544831 },
    { lat: 49.0510331, lng: 19.7542832 },
    { lat: 49.0505966, lng: 19.7536596 },
    { lat: 49.05022, lng: 19.7531119 },
    { lat: 49.0490854, lng: 19.7514574 },
    { lat: 49.049016, lng: 19.7511318 },
    { lat: 49.0487013, lng: 19.7496778 },
    { lat: 49.0485157, lng: 19.7488077 },
    { lat: 49.0484455, lng: 19.7484923 },
    { lat: 49.048384, lng: 19.7481907 },
    { lat: 49.0481544, lng: 19.7471415 },
    { lat: 49.0479206, lng: 19.7460418 },
    { lat: 49.0478827, lng: 19.7458445 },
    { lat: 49.0482737, lng: 19.7458247 },
    { lat: 49.0482014, lng: 19.7435511 },
    { lat: 49.0479088, lng: 19.7419879 },
    { lat: 49.0477546, lng: 19.7411664 },
    { lat: 49.0476709, lng: 19.740722 },
    { lat: 49.0474674, lng: 19.739626 },
    { lat: 49.0474395, lng: 19.7385471 },
    { lat: 49.0473987, lng: 19.7371305 },
    { lat: 49.0473734, lng: 19.7362661 },
    { lat: 49.0473499, lng: 19.7355261 },
    { lat: 49.0472299, lng: 19.7346146 },
    { lat: 49.0471027, lng: 19.7336395 },
    { lat: 49.0470605, lng: 19.7333173 },
    { lat: 49.0468694, lng: 19.7324051 },
    { lat: 49.0467514, lng: 19.7318446 },
    { lat: 49.046686, lng: 19.7315337 },
    { lat: 49.0466607, lng: 19.7314451 },
    { lat: 49.0466402, lng: 19.7313603 },
    { lat: 49.0466083, lng: 19.7311764 },
    { lat: 49.0465872, lng: 19.7310517 },
    { lat: 49.0465841, lng: 19.7310348 },
    { lat: 49.0465658, lng: 19.7309512 },
    { lat: 49.0465619, lng: 19.7309362 },
    { lat: 49.0465566, lng: 19.7309187 },
    { lat: 49.0465388, lng: 19.7308517 },
    { lat: 49.0464242, lng: 19.730419 },
    { lat: 49.0462859, lng: 19.7298875 },
    { lat: 49.0461334, lng: 19.7293104 },
    { lat: 49.0460049, lng: 19.7288203 },
    { lat: 49.0459672, lng: 19.728652 },
    { lat: 49.0459362, lng: 19.7285137 },
    { lat: 49.0458059, lng: 19.7279312 },
    { lat: 49.0456718, lng: 19.7273404 },
    { lat: 49.0455584, lng: 19.7268376 },
    { lat: 49.045476, lng: 19.726471 },
    { lat: 49.0454343, lng: 19.7262907 },
    { lat: 49.0454168, lng: 19.7262038 },
    { lat: 49.0453504, lng: 19.7257408 },
    { lat: 49.0452509, lng: 19.7250445 },
    { lat: 49.0451721, lng: 19.7245012 },
    { lat: 49.0450922, lng: 19.7239397 },
    { lat: 49.0450171, lng: 19.7235897 },
    { lat: 49.0448986, lng: 19.7230203 },
    { lat: 49.0448096, lng: 19.722612 },
    { lat: 49.0447113, lng: 19.7221433 },
    { lat: 49.0446284, lng: 19.7217431 },
    { lat: 49.0446239, lng: 19.7214386 },
    { lat: 49.0446157, lng: 19.720863 },
    { lat: 49.04461, lng: 19.7203271 },
    { lat: 49.0446068, lng: 19.720173 },
    { lat: 49.0446034, lng: 19.7198433 },
    { lat: 49.0441132, lng: 19.7202483 },
    { lat: 49.0439917, lng: 19.7199166 },
    { lat: 49.0440078, lng: 19.7194675 },
    { lat: 49.0440038, lng: 19.7191455 },
    { lat: 49.0434733, lng: 19.7191725 },
    { lat: 49.0433185, lng: 19.7189591 },
    { lat: 49.0431614, lng: 19.718611 },
    { lat: 49.0430256, lng: 19.7183418 },
    { lat: 49.0428539, lng: 19.7180777 },
    { lat: 49.0426018, lng: 19.7176972 },
    { lat: 49.0421873, lng: 19.7173375 },
    { lat: 49.0418262, lng: 19.7169703 },
    { lat: 49.0411286, lng: 19.7162466 },
    { lat: 49.0409043, lng: 19.7159613 },
    { lat: 49.0406876, lng: 19.7155161 },
    { lat: 49.0398716, lng: 19.7147413 },
    { lat: 49.03924, lng: 19.7141104 },
    { lat: 49.0386207, lng: 19.7135041 },
    { lat: 49.0377337, lng: 19.7126285 },
    { lat: 49.0370452, lng: 19.7119557 },
    { lat: 49.036262, lng: 19.7111873 },
    { lat: 49.0360079, lng: 19.7109439 },
    { lat: 49.0358873, lng: 19.7108359 },
    { lat: 49.0353531, lng: 19.7105004 },
    { lat: 49.0347155, lng: 19.7100194 },
    { lat: 49.034699, lng: 19.7102086 },
    { lat: 49.0347179, lng: 19.7101106 },
    { lat: 49.0346617, lng: 19.710969 },
    { lat: 49.0346235, lng: 19.7112013 },
    { lat: 49.0345886, lng: 19.7113578 },
    { lat: 49.0345935, lng: 19.7114155 },
    { lat: 49.0346087, lng: 19.7114814 },
    { lat: 49.0346369, lng: 19.7116875 },
    { lat: 49.0346534, lng: 19.7117505 },
    { lat: 49.034672, lng: 19.7117924 },
    { lat: 49.0348176, lng: 19.7122569 },
    { lat: 49.0348701, lng: 19.7124519 },
    { lat: 49.0349269, lng: 19.7128479 },
    { lat: 49.0349464, lng: 19.7130319 },
    { lat: 49.0349614, lng: 19.7133262 },
    { lat: 49.0349664, lng: 19.7137185 },
    { lat: 49.0349451, lng: 19.714544 },
    { lat: 49.0349327, lng: 19.7148731 },
    { lat: 49.0348529, lng: 19.7153405 },
    { lat: 49.0348094, lng: 19.715504 },
    { lat: 49.0347033, lng: 19.7158304 },
    { lat: 49.0343393, lng: 19.7166466 },
    { lat: 49.0341582, lng: 19.7170912 },
    { lat: 49.0341225, lng: 19.7171961 },
    { lat: 49.0338469, lng: 19.7179045 },
    { lat: 49.0338214, lng: 19.7179624 },
    { lat: 49.0337676, lng: 19.7181718 },
    { lat: 49.0337576, lng: 19.7182504 },
    { lat: 49.0336013, lng: 19.7186167 },
    { lat: 49.0334837, lng: 19.7188409 },
    { lat: 49.0331526, lng: 19.7194286 },
    { lat: 49.0331482, lng: 19.7194577 },
    { lat: 49.0329261, lng: 19.7197866 },
    { lat: 49.032642, lng: 19.7201171 },
    { lat: 49.03248, lng: 19.7204821 },
    { lat: 49.0324097, lng: 19.7207284 },
    { lat: 49.0323806, lng: 19.7208614 },
    { lat: 49.032299, lng: 19.7212836 },
    { lat: 49.0322296, lng: 19.7216802 },
    { lat: 49.0322029, lng: 19.7217397 },
    { lat: 49.0321475, lng: 19.7221445 },
    { lat: 49.0320971, lng: 19.7226904 },
    { lat: 49.0320279, lng: 19.7232683 },
    { lat: 49.0320456, lng: 19.7234887 },
    { lat: 49.0320796, lng: 19.7238059 },
    { lat: 49.0320957, lng: 19.7239141 },
    { lat: 49.0322216, lng: 19.724218 },
    { lat: 49.0323115, lng: 19.7245456 },
    { lat: 49.0324426, lng: 19.7249872 },
    { lat: 49.0326505, lng: 19.7255657 },
    { lat: 49.0328433, lng: 19.7259838 },
    { lat: 49.03299, lng: 19.7263193 },
    { lat: 49.033166, lng: 19.7267628 },
    { lat: 49.0332798, lng: 19.7271724 },
    { lat: 49.0333105, lng: 19.7273075 },
    { lat: 49.0333736, lng: 19.7276954 },
    { lat: 49.0333983, lng: 19.7283057 },
    { lat: 49.0333951, lng: 19.728491 },
    { lat: 49.0333341, lng: 19.7298245 },
    { lat: 49.0333331, lng: 19.7301614 },
    { lat: 49.0333269, lng: 19.730762 },
    { lat: 49.033345, lng: 19.7312783 },
    { lat: 49.0333643, lng: 19.7315887 },
    { lat: 49.0333884, lng: 19.7317823 },
    { lat: 49.0334629, lng: 19.7321424 },
    { lat: 49.0335251, lng: 19.7324089 },
    { lat: 49.033582, lng: 19.7325956 },
    { lat: 49.0336685, lng: 19.7328522 },
    { lat: 49.0339165, lng: 19.7335484 },
    { lat: 49.033946, lng: 19.7336556 },
    { lat: 49.033998, lng: 19.7338898 },
    { lat: 49.0340814, lng: 19.7342253 },
    { lat: 49.0341121, lng: 19.7347687 },
    { lat: 49.0341058, lng: 19.73496 },
    { lat: 49.0340879, lng: 19.7352724 },
    { lat: 49.0340594, lng: 19.7355481 },
    { lat: 49.0340297, lng: 19.7357712 },
    { lat: 49.034013, lng: 19.7358826 },
    { lat: 49.0339719, lng: 19.7360952 },
    { lat: 49.0339415, lng: 19.7362254 },
    { lat: 49.0337672, lng: 19.7366839 },
    { lat: 49.033893, lng: 19.7368439 },
    { lat: 49.0339356, lng: 19.7368604 },
    { lat: 49.0340684, lng: 19.7366279 },
    { lat: 49.0343534, lng: 19.7362666 },
    { lat: 49.0346699, lng: 19.7360341 },
    { lat: 49.0354059, lng: 19.7356069 },
    { lat: 49.0355465, lng: 19.7354972 },
    { lat: 49.0357168, lng: 19.7353485 },
    { lat: 49.0357942, lng: 19.7353152 },
    { lat: 49.035996, lng: 19.73534 },
    { lat: 49.0360329, lng: 19.7353376 },
    { lat: 49.0360912, lng: 19.7353533 },
    { lat: 49.0359445, lng: 19.7368432 },
    { lat: 49.0359695, lng: 19.7371559 },
    { lat: 49.0355188, lng: 19.7413487 },
    { lat: 49.036771, lng: 19.7416682 },
    { lat: 49.0363936, lng: 19.7441674 },
    { lat: 49.0363896, lng: 19.7442001 },
    { lat: 49.0360245, lng: 19.7466212 },
    { lat: 49.0349822, lng: 19.7462311 },
    { lat: 49.0349112, lng: 19.747006 },
    { lat: 49.0346043, lng: 19.746762 },
    { lat: 49.0345417, lng: 19.7467177 },
    { lat: 49.0345146, lng: 19.7466655 },
    { lat: 49.0335665, lng: 19.7457259 },
    { lat: 49.0334952, lng: 19.7457952 },
    { lat: 49.033344, lng: 19.7461491 },
    { lat: 49.0330735, lng: 19.7467646 },
    { lat: 49.0326159, lng: 19.7475347 },
    { lat: 49.0322347, lng: 19.7478881 },
    { lat: 49.0322203, lng: 19.7478928 },
    { lat: 49.0318605, lng: 19.7486806 },
    { lat: 49.0317352, lng: 19.748699 },
    { lat: 49.0314804, lng: 19.7493409 },
    { lat: 49.0314655, lng: 19.7494106 },
    { lat: 49.0312519, lng: 19.7496533 },
    { lat: 49.0311208, lng: 19.7498242 },
    { lat: 49.0310374, lng: 19.7500552 },
    { lat: 49.0309853, lng: 19.7502576 },
    { lat: 49.0309106, lng: 19.7506119 },
    { lat: 49.0308385, lng: 19.7509983 },
    { lat: 49.0307667, lng: 19.7513643 },
    { lat: 49.0307382, lng: 19.7515391 },
    { lat: 49.0306965, lng: 19.7517411 },
    { lat: 49.0305508, lng: 19.752405 },
    { lat: 49.0305348, lng: 19.752893 },
    { lat: 49.0305275, lng: 19.7532661 },
    { lat: 49.0305317, lng: 19.7533073 },
    { lat: 49.0305516, lng: 19.75338 },
    { lat: 49.0306225, lng: 19.7535333 },
    { lat: 49.0307283, lng: 19.7536856 },
    { lat: 49.0308101, lng: 19.7537746 },
    { lat: 49.0310088, lng: 19.7539405 },
    { lat: 49.0310677, lng: 19.7539974 },
    { lat: 49.0310927, lng: 19.7540017 },
    { lat: 49.0312343, lng: 19.7540757 },
    { lat: 49.0315323, lng: 19.7541918 },
    { lat: 49.0317402, lng: 19.7542289 },
    { lat: 49.0318146, lng: 19.7542562 },
    { lat: 49.0319526, lng: 19.7542699 },
    { lat: 49.0320017, lng: 19.7542862 },
    { lat: 49.0321354, lng: 19.7543879 },
    { lat: 49.0322618, lng: 19.7544642 },
    { lat: 49.032309, lng: 19.7545108 },
    { lat: 49.0324305, lng: 19.7546891 },
    { lat: 49.0326128, lng: 19.7549447 },
    { lat: 49.0329545, lng: 19.7555403 },
    { lat: 49.0331725, lng: 19.7559441 },
    { lat: 49.0332739, lng: 19.7561525 },
    { lat: 49.0334491, lng: 19.7565534 },
    { lat: 49.0335231, lng: 19.7566968 },
    { lat: 49.0336856, lng: 19.7570663 },
    { lat: 49.0337324, lng: 19.7571596 },
    { lat: 49.0339474, lng: 19.7575429 },
    { lat: 49.0341176, lng: 19.7578018 },
    { lat: 49.0342489, lng: 19.7579703 },
    { lat: 49.0342738, lng: 19.7580489 },
    { lat: 49.0342912, lng: 19.758166 },
    { lat: 49.034292, lng: 19.7582058 },
    { lat: 49.0343084, lng: 19.7582737 },
    { lat: 49.0344329, lng: 19.7586217 },
    { lat: 49.0344529, lng: 19.7587457 },
    { lat: 49.0344927, lng: 19.7590673 },
    { lat: 49.0344948, lng: 19.7591368 },
    { lat: 49.0344664, lng: 19.7594724 },
    { lat: 49.034451, lng: 19.7598374 },
    { lat: 49.0344732, lng: 19.7601683 },
    { lat: 49.0344946, lng: 19.7606318 },
    { lat: 49.0345118, lng: 19.7611026 },
    { lat: 49.034468, lng: 19.7617267 },
    { lat: 49.034465, lng: 19.7619949 },
    { lat: 49.0344488, lng: 19.7624252 },
    { lat: 49.03443, lng: 19.7627183 },
    { lat: 49.0344284, lng: 19.7628606 },
    { lat: 49.0344162, lng: 19.7630949 },
    { lat: 49.0344222, lng: 19.7631316 },
    { lat: 49.0345726, lng: 19.7635329 },
    { lat: 49.0346547, lng: 19.7637088 },
    { lat: 49.034748, lng: 19.7638621 },
    { lat: 49.0347762, lng: 19.7639407 },
    { lat: 49.0348148, lng: 19.7641555 },
    { lat: 49.0348692, lng: 19.7645252 },
    { lat: 49.034894, lng: 19.7646509 },
    { lat: 49.0348883, lng: 19.7647796 },
    { lat: 49.0349085, lng: 19.7652156 },
    { lat: 49.0349176, lng: 19.7654801 },
    { lat: 49.0348218, lng: 19.7657612 },
    { lat: 49.0346531, lng: 19.7661325 },
    { lat: 49.0345438, lng: 19.7663381 },
    { lat: 49.0343212, lng: 19.76679 },
    { lat: 49.0341959, lng: 19.7670668 },
    { lat: 49.0340812, lng: 19.7673057 },
    { lat: 49.0339273, lng: 19.7675987 },
    { lat: 49.0337881, lng: 19.7679689 },
    { lat: 49.0336353, lng: 19.7684453 },
    { lat: 49.0335902, lng: 19.7686789 },
    { lat: 49.0335383, lng: 19.769167 },
    { lat: 49.0335107, lng: 19.7693895 },
    { lat: 49.0335144, lng: 19.7695518 },
    { lat: 49.0335372, lng: 19.7698421 },
    { lat: 49.0335816, lng: 19.7701141 },
    { lat: 49.0337248, lng: 19.7705018 },
    { lat: 49.0340474, lng: 19.7709537 },
    { lat: 49.0342574, lng: 19.7711957 },
    { lat: 49.0344564, lng: 19.7714464 },
    { lat: 49.0344714, lng: 19.7714818 },
    { lat: 49.0345867, lng: 19.7720856 },
    { lat: 49.0345172, lng: 19.7724032 },
    { lat: 49.0343137, lng: 19.7727504 },
    { lat: 49.0341733, lng: 19.7730446 },
    { lat: 49.0340712, lng: 19.7733144 },
    { lat: 49.0340216, lng: 19.7734932 },
    { lat: 49.0339545, lng: 19.7737897 },
    { lat: 49.0339223, lng: 19.7739836 },
    { lat: 49.0339357, lng: 19.7745258 },
    { lat: 49.0339465, lng: 19.7746152 },
    { lat: 49.0342122, lng: 19.7755952 },
    { lat: 49.0343956, lng: 19.7760069 },
    { lat: 49.034421, lng: 19.7760973 },
    { lat: 49.0344825, lng: 19.7763726 },
    { lat: 49.0344861, lng: 19.7764279 },
    { lat: 49.0344485, lng: 19.777377 },
    { lat: 49.0344125, lng: 19.7779774 },
    { lat: 49.0343267, lng: 19.7795025 },
    { lat: 49.0342804, lng: 19.7802511 },
    { lat: 49.0342549, lng: 19.7804957 },
    { lat: 49.0342367, lng: 19.7806738 },
    { lat: 49.0343953, lng: 19.7807997 },
    { lat: 49.0344847, lng: 19.7809329 },
    { lat: 49.0345034, lng: 19.7809224 },
    { lat: 49.0346734, lng: 19.7811308 },
    { lat: 49.0347408, lng: 19.7812041 },
    { lat: 49.0348195, lng: 19.7812493 },
    { lat: 49.0349849, lng: 19.7811181 },
    { lat: 49.035096, lng: 19.7810553 },
    { lat: 49.0354545, lng: 19.7808024 },
    { lat: 49.0354877, lng: 19.7807956 },
    { lat: 49.0355293, lng: 19.780787 },
    { lat: 49.0355654, lng: 19.780759 },
    { lat: 49.0355862, lng: 19.7806463 },
    { lat: 49.0356457, lng: 19.7805369 },
    { lat: 49.0357769, lng: 19.7804433 },
    { lat: 49.0358396, lng: 19.7804345 },
    { lat: 49.0359205, lng: 19.780482 },
    { lat: 49.0359805, lng: 19.780592 },
    { lat: 49.0360079, lng: 19.7806804 },
    { lat: 49.0360752, lng: 19.7807512 },
    { lat: 49.0361775, lng: 19.7810791 },
    { lat: 49.0362658, lng: 19.7812214 },
    { lat: 49.0363772, lng: 19.7813725 },
    { lat: 49.0364823, lng: 19.7815357 },
    { lat: 49.0365882, lng: 19.7816163 },
    { lat: 49.0367113, lng: 19.7816449 },
    { lat: 49.0367682, lng: 19.781712 },
    { lat: 49.0368239, lng: 19.781961 },
    { lat: 49.0368523, lng: 19.7821467 },
    { lat: 49.0368433, lng: 19.7824847 },
    { lat: 49.0368783, lng: 19.7827185 },
    { lat: 49.036969, lng: 19.783122 },
    { lat: 49.0370539, lng: 19.7833655 },
    { lat: 49.0371034, lng: 19.7835713 },
    { lat: 49.0372009, lng: 19.7838969 },
    { lat: 49.0373228, lng: 19.7842911 },
    { lat: 49.03742, lng: 19.7845564 },
    { lat: 49.0374855, lng: 19.7846406 },
    { lat: 49.0376736, lng: 19.7847162 },
    { lat: 49.0377156, lng: 19.784698 },
    { lat: 49.0377732, lng: 19.7846225 },
    { lat: 49.0378521, lng: 19.7846789 },
    { lat: 49.0379652, lng: 19.7848966 },
    { lat: 49.0380502, lng: 19.7849085 },
    { lat: 49.0381274, lng: 19.7850218 },
    { lat: 49.0381746, lng: 19.7850713 },
    { lat: 49.0382877, lng: 19.7850064 },
    { lat: 49.0383443, lng: 19.7850926 },
    { lat: 49.0384036, lng: 19.7852616 },
    { lat: 49.0383975, lng: 19.7853821 },
    { lat: 49.0383604, lng: 19.7855384 },
    { lat: 49.0383615, lng: 19.7856672 },
    { lat: 49.0384264, lng: 19.7857878 },
    { lat: 49.0385031, lng: 19.785793 },
    { lat: 49.0385982, lng: 19.7856165 },
    { lat: 49.0387396, lng: 19.7856339 },
    { lat: 49.0388133, lng: 19.7857486 },
    { lat: 49.0388946, lng: 19.7857239 },
    { lat: 49.03901, lng: 19.7858919 },
    { lat: 49.0390579, lng: 19.7859072 },
    { lat: 49.0391504, lng: 19.7858853 },
    { lat: 49.039229, lng: 19.7858997 },
    { lat: 49.0394914, lng: 19.7862637 },
    { lat: 49.0396542, lng: 19.7864358 },
    { lat: 49.0397079, lng: 19.7864265 },
    { lat: 49.0398393, lng: 19.7862341 },
    { lat: 49.0399873, lng: 19.7863755 },
    { lat: 49.0401095, lng: 19.7863586 },
    { lat: 49.0402124, lng: 19.7864983 },
    { lat: 49.0404401, lng: 19.7867907 },
    { lat: 49.0405134, lng: 19.7867658 },
    { lat: 49.0406695, lng: 19.7866754 },
    { lat: 49.0407298, lng: 19.7867146 },
    { lat: 49.0407703, lng: 19.7868438 },
    { lat: 49.0409271, lng: 19.7870026 },
    { lat: 49.041059, lng: 19.7871585 },
    { lat: 49.0411095, lng: 19.7871584 },
    { lat: 49.041229, lng: 19.7870832 },
    { lat: 49.0412857, lng: 19.7870897 },
    { lat: 49.0412945, lng: 19.7871345 },
    { lat: 49.0412665, lng: 19.7873484 },
    { lat: 49.0412685, lng: 19.7874229 },
    { lat: 49.0414139, lng: 19.787553 },
    { lat: 49.0415458, lng: 19.7873717 },
    { lat: 49.0415768, lng: 19.7875923 },
    { lat: 49.041798, lng: 19.7873676 },
    { lat: 49.0418969, lng: 19.7876241 },
    { lat: 49.0418051, lng: 19.7878966 },
    { lat: 49.0420122, lng: 19.7881551 },
    { lat: 49.0421805, lng: 19.7884758 },
    { lat: 49.0423644, lng: 19.7888182 },
    { lat: 49.0422952, lng: 19.7888349 },
    { lat: 49.0422927, lng: 19.7888524 },
    { lat: 49.0423393, lng: 19.7889138 },
    { lat: 49.0424189, lng: 19.7889043 },
    { lat: 49.0424409, lng: 19.7888131 },
    { lat: 49.0425965, lng: 19.7887432 },
    { lat: 49.0426587, lng: 19.7891611 },
    { lat: 49.0428763, lng: 19.789452 },
    { lat: 49.0430718, lng: 19.7894418 },
    { lat: 49.0432857, lng: 19.7893079 },
    { lat: 49.0435142, lng: 19.7893008 },
    { lat: 49.0435397, lng: 19.789319 },
    { lat: 49.0433746, lng: 19.7896979 },
    { lat: 49.0434641, lng: 19.7897579 },
    { lat: 49.0434808, lng: 19.7900949 },
    { lat: 49.0435515, lng: 19.7900583 },
    { lat: 49.0436582, lng: 19.7903374 },
    { lat: 49.0436676, lng: 19.7905315 },
    { lat: 49.0438039, lng: 19.7905835 },
    { lat: 49.0438818, lng: 19.7910016 },
    { lat: 49.0439527, lng: 19.7909715 },
    { lat: 49.0440673, lng: 19.7909676 },
    { lat: 49.0443151, lng: 19.7910713 },
    { lat: 49.044405, lng: 19.791402 },
    { lat: 49.0445755, lng: 19.7913877 },
    { lat: 49.0447588, lng: 19.7917435 },
    { lat: 49.0448377, lng: 19.7917662 },
    { lat: 49.0448648, lng: 19.7919229 },
    { lat: 49.0448106, lng: 19.7921061 },
    { lat: 49.0447988, lng: 19.7923295 },
    { lat: 49.0449828, lng: 19.7926217 },
    { lat: 49.0451278, lng: 19.7928729 },
    { lat: 49.0452772, lng: 19.7934128 },
    { lat: 49.0453358, lng: 19.7935161 },
    { lat: 49.0452925, lng: 19.7936619 },
    { lat: 49.045319, lng: 19.7938539 },
    { lat: 49.0454008, lng: 19.7939006 },
    { lat: 49.0454276, lng: 19.7942578 },
    { lat: 49.0454939, lng: 19.7942299 },
    { lat: 49.045571, lng: 19.7944186 },
    { lat: 49.0455349, lng: 19.7945767 },
    { lat: 49.0456888, lng: 19.7946201 },
    { lat: 49.0458095, lng: 19.794779 },
    { lat: 49.0457852, lng: 19.7948669 },
    { lat: 49.0459357, lng: 19.7950116 },
    { lat: 49.0459056, lng: 19.7950962 },
    { lat: 49.0460984, lng: 19.7951537 },
    { lat: 49.0461567, lng: 19.7951496 },
    { lat: 49.0462106, lng: 19.7953744 },
    { lat: 49.0463968, lng: 19.7953299 },
    { lat: 49.0464237, lng: 19.7953563 },
    { lat: 49.0464239, lng: 19.7955692 },
    { lat: 49.046524, lng: 19.7956939 },
    { lat: 49.0465495, lng: 19.7958404 },
    { lat: 49.0466473, lng: 19.795707 },
    { lat: 49.0468517, lng: 19.7958754 },
    { lat: 49.0469572, lng: 19.7958157 },
    { lat: 49.0469632, lng: 19.7958219 },
    { lat: 49.0470126, lng: 19.7961287 },
    { lat: 49.0471461, lng: 19.796246 },
    { lat: 49.0472186, lng: 19.7962036 },
    { lat: 49.0472925, lng: 19.7962482 },
    { lat: 49.0473456, lng: 19.796198 },
    { lat: 49.0475655, lng: 19.7963326 },
    { lat: 49.0475958, lng: 19.7965138 },
    { lat: 49.0476651, lng: 19.7965772 },
    { lat: 49.0477245, lng: 19.7967551 },
    { lat: 49.0477571, lng: 19.7969584 },
    { lat: 49.0479003, lng: 19.7969598 },
    { lat: 49.0480202, lng: 19.7974671 },
    { lat: 49.0480577, lng: 19.7974231 },
    { lat: 49.0481558, lng: 19.7974528 },
    { lat: 49.0482551, lng: 19.7975334 },
    { lat: 49.0482686, lng: 19.7976103 },
    { lat: 49.0483012, lng: 19.7975851 },
    { lat: 49.0482714, lng: 19.7974696 },
    { lat: 49.0483084, lng: 19.7974619 },
    { lat: 49.0484517, lng: 19.7976257 },
    { lat: 49.0483907, lng: 19.7977274 },
    { lat: 49.0482449, lng: 19.7977678 },
    { lat: 49.0483178, lng: 19.797967 },
    { lat: 49.0483135, lng: 19.7981028 },
    { lat: 49.0482933, lng: 19.7981828 },
    { lat: 49.0483996, lng: 19.7982436 },
    { lat: 49.0485182, lng: 19.7983538 },
    { lat: 49.0485481, lng: 19.7981896 },
    { lat: 49.0486467, lng: 19.7981998 },
    { lat: 49.0487506, lng: 19.7987008 },
    { lat: 49.0490006, lng: 19.7989847 },
    { lat: 49.0491517, lng: 19.7991214 },
    { lat: 49.0491728, lng: 19.7989283 },
    { lat: 49.0495104, lng: 19.7992888 },
    { lat: 49.0495052, lng: 19.7994025 },
    { lat: 49.0496223, lng: 19.7993972 },
    { lat: 49.0496737, lng: 19.7991848 },
    { lat: 49.0497206, lng: 19.7991431 },
    { lat: 49.0497494, lng: 19.7992642 },
    { lat: 49.0499779, lng: 19.7993666 },
    { lat: 49.0500977, lng: 19.7992454 },
    { lat: 49.0500977, lng: 19.7994803 },
    { lat: 49.0501933, lng: 19.7995508 },
    { lat: 49.0502126, lng: 19.7996861 },
    { lat: 49.0503892, lng: 19.7996268 },
    { lat: 49.0504838, lng: 19.7995763 },
    { lat: 49.0504958, lng: 19.7998248 },
    { lat: 49.0511104, lng: 19.8000243 },
    { lat: 49.0511295, lng: 19.8000458 },
    { lat: 49.0510844, lng: 19.8001278 },
    { lat: 49.0511471, lng: 19.8003281 },
    { lat: 49.0512714, lng: 19.8003102 },
    { lat: 49.0513083, lng: 19.8003816 },
    { lat: 49.0512382, lng: 19.8006109 },
    { lat: 49.0512483, lng: 19.8007152 },
    { lat: 49.0512978, lng: 19.8008165 },
    { lat: 49.0512522, lng: 19.8008261 },
    { lat: 49.0512592, lng: 19.8009673 },
    { lat: 49.0513357, lng: 19.800988 },
    { lat: 49.0515775, lng: 19.8013459 },
    { lat: 49.0516455, lng: 19.8016667 },
    { lat: 49.0517649, lng: 19.8017154 },
    { lat: 49.0517879, lng: 19.8019638 },
    { lat: 49.0517627, lng: 19.8019811 },
    { lat: 49.0517322, lng: 19.8019252 },
    { lat: 49.0517257, lng: 19.8019327 },
    { lat: 49.051728, lng: 19.8020632 },
    { lat: 49.0517591, lng: 19.8020795 },
    { lat: 49.0517986, lng: 19.8020518 },
    { lat: 49.0520619, lng: 19.801996 },
    { lat: 49.0521368, lng: 19.802269 },
    { lat: 49.0520419, lng: 19.8023133 },
    { lat: 49.0522325, lng: 19.8024738 },
    { lat: 49.0522318, lng: 19.8026442 },
    { lat: 49.0524945, lng: 19.8030363 },
    { lat: 49.0526815, lng: 19.8033254 },
    { lat: 49.0527347, lng: 19.8037762 },
    { lat: 49.0530319, lng: 19.8039501 },
    { lat: 49.0531133, lng: 19.8044243 },
    { lat: 49.0533992, lng: 19.8050972 },
    { lat: 49.0536113, lng: 19.8051058 },
    { lat: 49.0535809, lng: 19.8053706 },
    { lat: 49.0538196, lng: 19.8056539 },
    { lat: 49.0543619, lng: 19.8059523 },
    { lat: 49.0546242, lng: 19.8060504 },
    { lat: 49.0548227, lng: 19.806084 },
    { lat: 49.0549933, lng: 19.8060918 },
    { lat: 49.0552416, lng: 19.8064688 },
    { lat: 49.0552865, lng: 19.8064144 },
    { lat: 49.0552802, lng: 19.8062416 },
    { lat: 49.0555576, lng: 19.8066409 },
    { lat: 49.0559329, lng: 19.8066411 },
    { lat: 49.0561407, lng: 19.8067356 },
    { lat: 49.0562687, lng: 19.8065407 },
    { lat: 49.0564934, lng: 19.806659 },
    { lat: 49.056561, lng: 19.8069957 },
    { lat: 49.0568006, lng: 19.8071208 },
    { lat: 49.0566669, lng: 19.8073385 },
    { lat: 49.0568746, lng: 19.8074234 },
    { lat: 49.0570531, lng: 19.8079073 },
    { lat: 49.0573338, lng: 19.8081964 },
    { lat: 49.0574205, lng: 19.8083788 },
    { lat: 49.0575141, lng: 19.8083551 },
    { lat: 49.0575242, lng: 19.8086925 },
    { lat: 49.0576605, lng: 19.8087438 },
    { lat: 49.0577364, lng: 19.8086544 },
    { lat: 49.0581356, lng: 19.8090257 },
    { lat: 49.0582495, lng: 19.8091602 },
    { lat: 49.0582571, lng: 19.809177 },
    { lat: 49.0583218, lng: 19.8094231 },
    { lat: 49.0584336, lng: 19.8095642 },
    { lat: 49.0584558, lng: 19.8096073 },
    { lat: 49.058752, lng: 19.8096552 },
    { lat: 49.0591386, lng: 19.8100807 },
    { lat: 49.0591026, lng: 19.8102912 },
    { lat: 49.059246, lng: 19.8105362 },
    { lat: 49.0595586, lng: 19.810598 },
    { lat: 49.0595836, lng: 19.81076 },
    { lat: 49.0596743, lng: 19.8109536 },
    { lat: 49.0597386, lng: 19.811191 },
    { lat: 49.059944, lng: 19.8115911 },
    { lat: 49.0600783, lng: 19.8118094 },
    { lat: 49.0601167, lng: 19.8121522 },
    { lat: 49.0602908, lng: 19.8122149 },
    { lat: 49.0604431, lng: 19.8122981 },
    { lat: 49.0604636, lng: 19.8124628 },
    { lat: 49.0606348, lng: 19.812644 },
    { lat: 49.0607106, lng: 19.8128853 },
    { lat: 49.0607919, lng: 19.8128934 },
    { lat: 49.0608582, lng: 19.8131224 },
    { lat: 49.0609539, lng: 19.8131452 },
    { lat: 49.0610487, lng: 19.8131501 },
    { lat: 49.0611395, lng: 19.8132189 },
    { lat: 49.0612642, lng: 19.8132359 },
    { lat: 49.0614922, lng: 19.8135871 },
    { lat: 49.0615608, lng: 19.8135831 },
    { lat: 49.0616552, lng: 19.8136323 },
    { lat: 49.0616565, lng: 19.8137668 },
    { lat: 49.0617239, lng: 19.8138417 },
    { lat: 49.0617885, lng: 19.813851 },
    { lat: 49.0617867, lng: 19.8138354 },
    { lat: 49.0621589, lng: 19.8137695 },
    { lat: 49.0626701, lng: 19.8137332 },
    { lat: 49.0639082, lng: 19.8136081 },
    { lat: 49.0644767, lng: 19.8141193 },
    { lat: 49.0655252, lng: 19.8143249 },
    { lat: 49.0664897, lng: 19.8147197 },
    { lat: 49.0675639, lng: 19.8151262 },
    { lat: 49.0690451, lng: 19.8156458 },
    { lat: 49.0696714, lng: 19.8166669 },
    { lat: 49.0702525, lng: 19.817053 },
    { lat: 49.0704099, lng: 19.8169934 },
    { lat: 49.0707128, lng: 19.8169111 },
    { lat: 49.0710221, lng: 19.8168741 },
    { lat: 49.0711783, lng: 19.8168132 },
    { lat: 49.0714425, lng: 19.816775 },
    { lat: 49.0715475, lng: 19.8168069 },
    { lat: 49.0717576, lng: 19.8168456 },
    { lat: 49.0722781, lng: 19.8169783 },
    { lat: 49.0724305, lng: 19.8170404 },
    { lat: 49.0728365, lng: 19.817177 },
    { lat: 49.0735397, lng: 19.8183385 },
    { lat: 49.0743704, lng: 19.8171187 },
    { lat: 49.0747206, lng: 19.8163044 },
    { lat: 49.0753131, lng: 19.8149183 },
    { lat: 49.0763341, lng: 19.8125486 },
    { lat: 49.0770262, lng: 19.810939 },
    { lat: 49.0773732, lng: 19.8101558 },
    { lat: 49.0781219, lng: 19.8084262 },
    { lat: 49.0792457, lng: 19.8058056 },
    { lat: 49.0799284, lng: 19.8042697 },
    { lat: 49.0798976, lng: 19.8042839 },
    { lat: 49.0813602, lng: 19.8009065 },
    { lat: 49.0814319, lng: 19.8007401 },
    { lat: 49.0819236, lng: 19.7997083 },
    { lat: 49.0827917, lng: 19.7978759 },
    { lat: 49.0833967, lng: 19.7966242 },
    { lat: 49.083759, lng: 19.7958875 },
    { lat: 49.0840194, lng: 19.7952382 },
    { lat: 49.0843985, lng: 19.794288 },
    { lat: 49.0845821, lng: 19.7938172 },
  ],
  Ľubeľa: [
    { lat: 49.061335, lng: 19.4948825 },
    { lat: 49.0613476, lng: 19.4952534 },
    { lat: 49.0614573, lng: 19.4953337 },
    { lat: 49.0615662, lng: 19.495154 },
    { lat: 49.0615855, lng: 19.4949108 },
    { lat: 49.061717, lng: 19.4949062 },
    { lat: 49.0618586, lng: 19.4950187 },
    { lat: 49.0619816, lng: 19.4952446 },
    { lat: 49.0622734, lng: 19.4953142 },
    { lat: 49.0624031, lng: 19.4951003 },
    { lat: 49.0625826, lng: 19.4949364 },
    { lat: 49.0626992, lng: 19.494919 },
    { lat: 49.062798, lng: 19.4948406 },
    { lat: 49.0629358, lng: 19.4948946 },
    { lat: 49.0630751, lng: 19.4948363 },
    { lat: 49.0631368, lng: 19.4949774 },
    { lat: 49.063173, lng: 19.4949592 },
    { lat: 49.0662193, lng: 19.4960506 },
    { lat: 49.065945, lng: 19.4969943 },
    { lat: 49.0660677, lng: 19.4970657 },
    { lat: 49.0660896, lng: 19.4973257 },
    { lat: 49.0662448, lng: 19.497467 },
    { lat: 49.0664568, lng: 19.4973474 },
    { lat: 49.0666528, lng: 19.4974409 },
    { lat: 49.0668091, lng: 19.4975073 },
    { lat: 49.0669665, lng: 19.4977213 },
    { lat: 49.0672456, lng: 19.497664 },
    { lat: 49.0673661, lng: 19.497883 },
    { lat: 49.0676811, lng: 19.4978721 },
    { lat: 49.067789, lng: 19.4978424 },
    { lat: 49.0677204, lng: 19.4972822 },
    { lat: 49.0678329, lng: 19.4967133 },
    { lat: 49.0689496, lng: 19.4963445 },
    { lat: 49.0690257, lng: 19.4964076 },
    { lat: 49.0691473, lng: 19.4973855 },
    { lat: 49.0692907, lng: 19.4972944 },
    { lat: 49.0694849, lng: 19.4972813 },
    { lat: 49.0697129, lng: 19.4971151 },
    { lat: 49.0699624, lng: 19.4968558 },
    { lat: 49.0702338, lng: 19.4969247 },
    { lat: 49.070271, lng: 19.4970253 },
    { lat: 49.0702807, lng: 19.4973089 },
    { lat: 49.0704052, lng: 19.4971505 },
    { lat: 49.0704709, lng: 19.4970842 },
    { lat: 49.0705213, lng: 19.4971037 },
    { lat: 49.0707272, lng: 19.4967274 },
    { lat: 49.0711293, lng: 19.4963314 },
    { lat: 49.071032, lng: 19.4958297 },
    { lat: 49.070463, lng: 19.4953633 },
    { lat: 49.0707089, lng: 19.4942892 },
    { lat: 49.0707149, lng: 19.4933136 },
    { lat: 49.0709134, lng: 19.4928037 },
    { lat: 49.0709873, lng: 19.4923247 },
    { lat: 49.0711908, lng: 19.4920986 },
    { lat: 49.0711315, lng: 19.4903119 },
    { lat: 49.0681384, lng: 19.4896434 },
    { lat: 49.0674055, lng: 19.4896082 },
    { lat: 49.0667237, lng: 19.4886048 },
    { lat: 49.0667295, lng: 19.4885553 },
    { lat: 49.067462, lng: 19.4870148 },
    { lat: 49.0684239, lng: 19.4849945 },
    { lat: 49.068436, lng: 19.4847179 },
    { lat: 49.0684888, lng: 19.4839802 },
    { lat: 49.0687962, lng: 19.4836564 },
    { lat: 49.0712611, lng: 19.4828099 },
    { lat: 49.0724785, lng: 19.4826687 },
    { lat: 49.0758537, lng: 19.4831923 },
    { lat: 49.0761412, lng: 19.4788842 },
    { lat: 49.0761622, lng: 19.4785613 },
    { lat: 49.0762135, lng: 19.4784229 },
    { lat: 49.0762883, lng: 19.4776986 },
    { lat: 49.0763017, lng: 19.4776245 },
    { lat: 49.0763027, lng: 19.4776223 },
    { lat: 49.0762952, lng: 19.4773614 },
    { lat: 49.0763428, lng: 19.4771946 },
    { lat: 49.076345, lng: 19.4771447 },
    { lat: 49.0763982, lng: 19.474921 },
    { lat: 49.0764996, lng: 19.4707135 },
    { lat: 49.076502, lng: 19.4706428 },
    { lat: 49.0760681, lng: 19.4702503 },
    { lat: 49.0759937, lng: 19.4701795 },
    { lat: 49.0754986, lng: 19.4697511 },
    { lat: 49.0751587, lng: 19.4694923 },
    { lat: 49.0746497, lng: 19.4692067 },
    { lat: 49.0734078, lng: 19.4689116 },
    { lat: 49.0727326, lng: 19.4685614 },
    { lat: 49.0722553, lng: 19.4683299 },
    { lat: 49.0719637, lng: 19.468237 },
    { lat: 49.0711757, lng: 19.4680315 },
    { lat: 49.0707176, lng: 19.4678738 },
    { lat: 49.070215, lng: 19.4677726 },
    { lat: 49.0692979, lng: 19.4677279 },
    { lat: 49.0688025, lng: 19.4677125 },
    { lat: 49.068316, lng: 19.4677925 },
    { lat: 49.0678035, lng: 19.4680208 },
    { lat: 49.0667881, lng: 19.4685073 },
    { lat: 49.0658033, lng: 19.4688974 },
    { lat: 49.0651097, lng: 19.4691059 },
    { lat: 49.0645616, lng: 19.4692183 },
    { lat: 49.0640277, lng: 19.4693447 },
    { lat: 49.0639841, lng: 19.4692559 },
    { lat: 49.0627786, lng: 19.4668412 },
    { lat: 49.0626009, lng: 19.4662357 },
    { lat: 49.0634434, lng: 19.4647159 },
    { lat: 49.0633949, lng: 19.4639564 },
    { lat: 49.063324, lng: 19.4632482 },
    { lat: 49.0633163, lng: 19.462765 },
    { lat: 49.0634533, lng: 19.4616554 },
    { lat: 49.0635118, lng: 19.4613635 },
    { lat: 49.063534, lng: 19.4608411 },
    { lat: 49.0636657, lng: 19.4602313 },
    { lat: 49.0636911, lng: 19.4601481 },
    { lat: 49.0637598, lng: 19.4598259 },
    { lat: 49.0636896, lng: 19.4597479 },
    { lat: 49.0635174, lng: 19.4599449 },
    { lat: 49.0632943, lng: 19.4597765 },
    { lat: 49.0631921, lng: 19.4597576 },
    { lat: 49.0631511, lng: 19.4594077 },
    { lat: 49.0631925, lng: 19.4592815 },
    { lat: 49.0631755, lng: 19.4591575 },
    { lat: 49.063208, lng: 19.4588849 },
    { lat: 49.0631908, lng: 19.4588515 },
    { lat: 49.0632128, lng: 19.458791 },
    { lat: 49.0631556, lng: 19.4585286 },
    { lat: 49.0630435, lng: 19.4586628 },
    { lat: 49.0631316, lng: 19.4589128 },
    { lat: 49.0630928, lng: 19.4589526 },
    { lat: 49.0629648, lng: 19.459202 },
    { lat: 49.0628587, lng: 19.4592259 },
    { lat: 49.0627516, lng: 19.4591985 },
    { lat: 49.0626799, lng: 19.4592371 },
    { lat: 49.0626096, lng: 19.4591767 },
    { lat: 49.0625623, lng: 19.4591538 },
    { lat: 49.0625531, lng: 19.4590732 },
    { lat: 49.0625601, lng: 19.45903 },
    { lat: 49.0625605, lng: 19.4589671 },
    { lat: 49.0625166, lng: 19.4589416 },
    { lat: 49.0625076, lng: 19.4588703 },
    { lat: 49.0624448, lng: 19.4589507 },
    { lat: 49.0623788, lng: 19.4590559 },
    { lat: 49.0622778, lng: 19.4590178 },
    { lat: 49.0621832, lng: 19.45907 },
    { lat: 49.0619622, lng: 19.4593556 },
    { lat: 49.0616964, lng: 19.459752 },
    { lat: 49.0616096, lng: 19.4598 },
    { lat: 49.0615921, lng: 19.4598867 },
    { lat: 49.0614065, lng: 19.4599382 },
    { lat: 49.061155, lng: 19.4600596 },
    { lat: 49.0610505, lng: 19.4601584 },
    { lat: 49.0610121, lng: 19.4599876 },
    { lat: 49.0603025, lng: 19.46014 },
    { lat: 49.0602512, lng: 19.4601552 },
    { lat: 49.0600847, lng: 19.4601597 },
    { lat: 49.0597386, lng: 19.4602779 },
    { lat: 49.0588597, lng: 19.460549 },
    { lat: 49.0582224, lng: 19.4608762 },
    { lat: 49.0575256, lng: 19.4609888 },
    { lat: 49.0570469, lng: 19.4611873 },
    { lat: 49.0568119, lng: 19.4607608 },
    { lat: 49.055832, lng: 19.4601129 },
    { lat: 49.0542526, lng: 19.4592423 },
    { lat: 49.0527713, lng: 19.4583765 },
    { lat: 49.0523853, lng: 19.4582672 },
    { lat: 49.0519402, lng: 19.4581173 },
    { lat: 49.0517398, lng: 19.4580284 },
    { lat: 49.050863, lng: 19.4576092 },
    { lat: 49.0506625, lng: 19.4575358 },
    { lat: 49.0499237, lng: 19.457354 },
    { lat: 49.049347, lng: 19.457189 },
    { lat: 49.0490412, lng: 19.4571524 },
    { lat: 49.0489466, lng: 19.4579115 },
    { lat: 49.0488842, lng: 19.4585135 },
    { lat: 49.0488845, lng: 19.4585199 },
    { lat: 49.0487984, lng: 19.4594096 },
    { lat: 49.0487421, lng: 19.4599692 },
    { lat: 49.0486832, lng: 19.460842 },
    { lat: 49.0486682, lng: 19.4610343 },
    { lat: 49.0486326, lng: 19.4619908 },
    { lat: 49.048611, lng: 19.462209 },
    { lat: 49.0482478, lng: 19.462079 },
    { lat: 49.0481494, lng: 19.4619742 },
    { lat: 49.0482072, lng: 19.4632512 },
    { lat: 49.0481943, lng: 19.463555 },
    { lat: 49.0480333, lng: 19.4644868 },
    { lat: 49.0479438, lng: 19.4656722 },
    { lat: 49.0478989, lng: 19.4662147 },
    { lat: 49.0478746, lng: 19.4666663 },
    { lat: 49.0478623, lng: 19.4670793 },
    { lat: 49.0478368, lng: 19.4674064 },
    { lat: 49.0477674, lng: 19.468077 },
    { lat: 49.0477297, lng: 19.4684079 },
    { lat: 49.0477042, lng: 19.4685856 },
    { lat: 49.0476084, lng: 19.4690754 },
    { lat: 49.0474609, lng: 19.4696393 },
    { lat: 49.0473635, lng: 19.4702665 },
    { lat: 49.0472227, lng: 19.4711458 },
    { lat: 49.0471277, lng: 19.4718011 },
    { lat: 49.0470583, lng: 19.472343 },
    { lat: 49.0470092, lng: 19.4728206 },
    { lat: 49.0470093, lng: 19.4728235 },
    { lat: 49.0469768, lng: 19.4730949 },
    { lat: 49.0468714, lng: 19.4742571 },
    { lat: 49.0468591, lng: 19.4744053 },
    { lat: 49.046867, lng: 19.4744278 },
    { lat: 49.0467778, lng: 19.4752797 },
    { lat: 49.0467064, lng: 19.4758376 },
    { lat: 49.0466769, lng: 19.4761752 },
    { lat: 49.0466772, lng: 19.4761824 },
    { lat: 49.0466676, lng: 19.4762915 },
    { lat: 49.0466665, lng: 19.4762941 },
    { lat: 49.0466486, lng: 19.4766111 },
    { lat: 49.0466475, lng: 19.4766137 },
    { lat: 49.0466393, lng: 19.476727 },
    { lat: 49.0466188, lng: 19.4767238 },
    { lat: 49.046569, lng: 19.4774019 },
    { lat: 49.0465782, lng: 19.4774566 },
    { lat: 49.0465049, lng: 19.4783068 },
    { lat: 49.0464974, lng: 19.4783942 },
    { lat: 49.0463396, lng: 19.4783679 },
    { lat: 49.0463285, lng: 19.4785418 },
    { lat: 49.0462256, lng: 19.478436 },
    { lat: 49.0462265, lng: 19.4784087 },
    { lat: 49.046203, lng: 19.4784112 },
    { lat: 49.0453685, lng: 19.4775396 },
    { lat: 49.0439523, lng: 19.4767152 },
    { lat: 49.043963, lng: 19.4764364 },
    { lat: 49.04333, lng: 19.4761702 },
    { lat: 49.0432834, lng: 19.4760656 },
    { lat: 49.0430836, lng: 19.4764246 },
    { lat: 49.0429954, lng: 19.476416 },
    { lat: 49.0428914, lng: 19.4763635 },
    { lat: 49.0427946, lng: 19.4760718 },
    { lat: 49.0424396, lng: 19.475921 },
    { lat: 49.0423001, lng: 19.4757581 },
    { lat: 49.0421816, lng: 19.4756752 },
    { lat: 49.0420559, lng: 19.4759572 },
    { lat: 49.0418271, lng: 19.4762485 },
    { lat: 49.0416706, lng: 19.476379 },
    { lat: 49.0415616, lng: 19.4763091 },
    { lat: 49.0413553, lng: 19.4762837 },
    { lat: 49.0411058, lng: 19.4758103 },
    { lat: 49.0409745, lng: 19.4758197 },
    { lat: 49.0409063, lng: 19.4756114 },
    { lat: 49.0408376, lng: 19.4756116 },
    { lat: 49.040746, lng: 19.4757029 },
    { lat: 49.0405872, lng: 19.4757772 },
    { lat: 49.0405167, lng: 19.4752974 },
    { lat: 49.0402203, lng: 19.4753484 },
    { lat: 49.040166, lng: 19.4754141 },
    { lat: 49.0401242, lng: 19.4753184 },
    { lat: 49.0396798, lng: 19.475035 },
    { lat: 49.0396815, lng: 19.4750517 },
    { lat: 49.0396093, lng: 19.4750737 },
    { lat: 49.0396073, lng: 19.4750563 },
    { lat: 49.0393052, lng: 19.4751981 },
    { lat: 49.0392048, lng: 19.4753453 },
    { lat: 49.0392883, lng: 19.4755881 },
    { lat: 49.0392658, lng: 19.4758603 },
    { lat: 49.039091, lng: 19.4759033 },
    { lat: 49.0388705, lng: 19.4761556 },
    { lat: 49.0386409, lng: 19.4759904 },
    { lat: 49.0381083, lng: 19.4759254 },
    { lat: 49.0382242, lng: 19.4764367 },
    { lat: 49.0380776, lng: 19.4765105 },
    { lat: 49.0376435, lng: 19.4763539 },
    { lat: 49.0375062, lng: 19.4764601 },
    { lat: 49.0373762, lng: 19.4765709 },
    { lat: 49.0372521, lng: 19.4765202 },
    { lat: 49.036791, lng: 19.476834 },
    { lat: 49.0367109, lng: 19.476732 },
    { lat: 49.0365929, lng: 19.4768343 },
    { lat: 49.035928, lng: 19.4769856 },
    { lat: 49.0358414, lng: 19.4769144 },
    { lat: 49.0356168, lng: 19.4769035 },
    { lat: 49.0353896, lng: 19.4769385 },
    { lat: 49.0355531, lng: 19.4775036 },
    { lat: 49.0355553, lng: 19.4776484 },
    { lat: 49.0354718, lng: 19.4778671 },
    { lat: 49.0354868, lng: 19.4779714 },
    { lat: 49.034936, lng: 19.4780509 },
    { lat: 49.0343684, lng: 19.4782511 },
    { lat: 49.0333273, lng: 19.4777443 },
    { lat: 49.0329774, lng: 19.4773858 },
    { lat: 49.0327945, lng: 19.4773069 },
    { lat: 49.0325376, lng: 19.4771587 },
    { lat: 49.0323709, lng: 19.4769692 },
    { lat: 49.0321641, lng: 19.4769536 },
    { lat: 49.0320591, lng: 19.4768013 },
    { lat: 49.0319261, lng: 19.4767552 },
    { lat: 49.0317097, lng: 19.4767269 },
    { lat: 49.0314765, lng: 19.4769758 },
    { lat: 49.0313183, lng: 19.4768164 },
    { lat: 49.0311579, lng: 19.476247 },
    { lat: 49.0308465, lng: 19.4761653 },
    { lat: 49.03048, lng: 19.476135 },
    { lat: 49.0304534, lng: 19.4762894 },
    { lat: 49.0304415, lng: 19.4767184 },
    { lat: 49.0297433, lng: 19.4766067 },
    { lat: 49.0292508, lng: 19.4765558 },
    { lat: 49.0292517, lng: 19.4762329 },
    { lat: 49.0285483, lng: 19.4764698 },
    { lat: 49.0285729, lng: 19.4765422 },
    { lat: 49.0283393, lng: 19.4767032 },
    { lat: 49.0282867, lng: 19.4765167 },
    { lat: 49.0282327, lng: 19.4764653 },
    { lat: 49.0280288, lng: 19.4767154 },
    { lat: 49.0277748, lng: 19.4766811 },
    { lat: 49.0276506, lng: 19.4766244 },
    { lat: 49.0274168, lng: 19.4764442 },
    { lat: 49.0271786, lng: 19.4763126 },
    { lat: 49.0263357, lng: 19.4760883 },
    { lat: 49.0259432, lng: 19.4759599 },
    { lat: 49.0237031, lng: 19.4753767 },
    { lat: 49.0230808, lng: 19.4746843 },
    { lat: 49.0228696, lng: 19.4744562 },
    { lat: 49.0227753, lng: 19.4743665 },
    { lat: 49.0226008, lng: 19.4742235 },
    { lat: 49.0223829, lng: 19.4742342 },
    { lat: 49.0221636, lng: 19.4739538 },
    { lat: 49.0218671, lng: 19.4733628 },
    { lat: 49.021564, lng: 19.4730468 },
    { lat: 49.0214224, lng: 19.4727869 },
    { lat: 49.0213936, lng: 19.472674 },
    { lat: 49.0213773, lng: 19.4724693 },
    { lat: 49.0212656, lng: 19.4722184 },
    { lat: 49.0210574, lng: 19.4719789 },
    { lat: 49.0207302, lng: 19.4714328 },
    { lat: 49.0204833, lng: 19.4710933 },
    { lat: 49.020359, lng: 19.4707163 },
    { lat: 49.0199674, lng: 19.4703875 },
    { lat: 49.0198111, lng: 19.4701989 },
    { lat: 49.0197736, lng: 19.4699242 },
    { lat: 49.0195023, lng: 19.4694635 },
    { lat: 49.01931, lng: 19.4687911 },
    { lat: 49.0192568, lng: 19.4687131 },
    { lat: 49.0190131, lng: 19.4683901 },
    { lat: 49.0187604, lng: 19.4679446 },
    { lat: 49.0186294, lng: 19.4676444 },
    { lat: 49.0184981, lng: 19.467387 },
    { lat: 49.0183422, lng: 19.467254 },
    { lat: 49.018258, lng: 19.4664821 },
    { lat: 49.0181366, lng: 19.4662933 },
    { lat: 49.0179684, lng: 19.4662154 },
    { lat: 49.0177865, lng: 19.4662484 },
    { lat: 49.017645, lng: 19.4661142 },
    { lat: 49.0175191, lng: 19.465853 },
    { lat: 49.017314, lng: 19.4657065 },
    { lat: 49.0171907, lng: 19.4654048 },
    { lat: 49.0171859, lng: 19.4651073 },
    { lat: 49.0170651, lng: 19.464833 },
    { lat: 49.0168219, lng: 19.4646192 },
    { lat: 49.0166351, lng: 19.4645013 },
    { lat: 49.0163212, lng: 19.4638537 },
    { lat: 49.0161384, lng: 19.4633839 },
    { lat: 49.0158674, lng: 19.4629092 },
    { lat: 49.015651, lng: 19.462616 },
    { lat: 49.0155422, lng: 19.4623325 },
    { lat: 49.0154238, lng: 19.4621101 },
    { lat: 49.0151303, lng: 19.4619314 },
    { lat: 49.0149389, lng: 19.461469 },
    { lat: 49.0148749, lng: 19.4613297 },
    { lat: 49.0147603, lng: 19.4614112 },
    { lat: 49.014742, lng: 19.461331 },
    { lat: 49.0145034, lng: 19.4606557 },
    { lat: 49.0140268, lng: 19.459411 },
    { lat: 49.0131744, lng: 19.4582784 },
    { lat: 49.0119504, lng: 19.457838 },
    { lat: 49.0107532, lng: 19.4574609 },
    { lat: 49.0086443, lng: 19.457598 },
    { lat: 49.0075941, lng: 19.4583524 },
    { lat: 49.0065551, lng: 19.459742 },
    { lat: 49.0061094, lng: 19.4602652 },
    { lat: 49.0057739, lng: 19.4606395 },
    { lat: 49.0043915, lng: 19.4629618 },
    { lat: 49.0041873, lng: 19.4632945 },
    { lat: 49.0033064, lng: 19.4638138 },
    { lat: 49.0026614, lng: 19.4637351 },
    { lat: 49.0015866, lng: 19.4645906 },
    { lat: 49.0010157, lng: 19.4651648 },
    { lat: 49.000415, lng: 19.4663558 },
    { lat: 49.0001605, lng: 19.4664343 },
    { lat: 48.9998614, lng: 19.4664443 },
    { lat: 48.9982387, lng: 19.4677644 },
    { lat: 48.9979735, lng: 19.4675856 },
    { lat: 48.9975219, lng: 19.4676129 },
    { lat: 48.997304, lng: 19.4677011 },
    { lat: 48.9968703, lng: 19.4678529 },
    { lat: 48.9961592, lng: 19.4678763 },
    { lat: 48.9956177, lng: 19.4676452 },
    { lat: 48.9945206, lng: 19.4675824 },
    { lat: 48.9941645, lng: 19.467338 },
    { lat: 48.9937892, lng: 19.4672384 },
    { lat: 48.9934668, lng: 19.4674056 },
    { lat: 48.9932869, lng: 19.4674628 },
    { lat: 48.9924307, lng: 19.4692726 },
    { lat: 48.9922887, lng: 19.4697628 },
    { lat: 48.9923402, lng: 19.4707545 },
    { lat: 48.9906695, lng: 19.4710101 },
    { lat: 48.9897286, lng: 19.4706938 },
    { lat: 48.9892612, lng: 19.4707701 },
    { lat: 48.9884664, lng: 19.4705915 },
    { lat: 48.9881185, lng: 19.4706719 },
    { lat: 48.9872275, lng: 19.4709962 },
    { lat: 48.9853476, lng: 19.4714315 },
    { lat: 48.9846927, lng: 19.471695 },
    { lat: 48.9843478, lng: 19.4721827 },
    { lat: 48.9836337, lng: 19.4730682 },
    { lat: 48.9830293, lng: 19.4743542 },
    { lat: 48.9824428, lng: 19.475979 },
    { lat: 48.9821313, lng: 19.4772639 },
    { lat: 48.9815977, lng: 19.4773002 },
    { lat: 48.9802247, lng: 19.4769954 },
    { lat: 48.9783657, lng: 19.4776632 },
    { lat: 48.9771724, lng: 19.4786188 },
    { lat: 48.9767179, lng: 19.4797351 },
    { lat: 48.9756978, lng: 19.480755 },
    { lat: 48.9745573, lng: 19.4820988 },
    { lat: 48.9722531, lng: 19.4847179 },
    { lat: 48.9718799, lng: 19.4857654 },
    { lat: 48.9730056, lng: 19.4873331 },
    { lat: 48.9751878, lng: 19.488817 },
    { lat: 48.9769577, lng: 19.4900818 },
    { lat: 48.9779341, lng: 19.4905335 },
    { lat: 48.9798245, lng: 19.4909624 },
    { lat: 48.9817431, lng: 19.4919085 },
    { lat: 48.983785, lng: 19.4919623 },
    { lat: 48.9849954, lng: 19.4924556 },
    { lat: 48.9858423, lng: 19.4925207 },
    { lat: 48.9874456, lng: 19.4922428 },
    { lat: 48.9895826, lng: 19.4924986 },
    { lat: 48.9915588, lng: 19.4897211 },
    { lat: 48.9919682, lng: 19.4898287 },
    { lat: 48.9924468, lng: 19.4900693 },
    { lat: 48.9940406, lng: 19.4892193 },
    { lat: 48.9946663, lng: 19.4891256 },
    { lat: 48.9966799, lng: 19.4892737 },
    { lat: 48.9970671, lng: 19.4893844 },
    { lat: 48.9974414, lng: 19.4893382 },
    { lat: 48.9977461, lng: 19.4896184 },
    { lat: 48.9981574, lng: 19.4901615 },
    { lat: 48.9988178, lng: 19.4907258 },
    { lat: 48.9989155, lng: 19.4907183 },
    { lat: 48.9992074, lng: 19.4907673 },
    { lat: 48.9997786, lng: 19.4911344 },
    { lat: 49.0005461, lng: 19.4919437 },
    { lat: 49.0009701, lng: 19.4935456 },
    { lat: 49.0015149, lng: 19.4941212 },
    { lat: 49.0023749, lng: 19.4931923 },
    { lat: 49.003616, lng: 19.4920258 },
    { lat: 49.0043595, lng: 19.4913116 },
    { lat: 49.004822, lng: 19.4906613 },
    { lat: 49.0061107, lng: 19.4895704 },
    { lat: 49.0065356, lng: 19.4894495 },
    { lat: 49.0072536, lng: 19.4887953 },
    { lat: 49.0078813, lng: 19.4888187 },
    { lat: 49.0082554, lng: 19.4880083 },
    { lat: 49.008443, lng: 19.4868208 },
    { lat: 49.0085566, lng: 19.4868417 },
    { lat: 49.0100096, lng: 19.4866327 },
    { lat: 49.011399, lng: 19.4876384 },
    { lat: 49.0118069, lng: 19.4873904 },
    { lat: 49.0118158, lng: 19.487388 },
    { lat: 49.0119022, lng: 19.4872848 },
    { lat: 49.0120129, lng: 19.4873412 },
    { lat: 49.0121843, lng: 19.4867285 },
    { lat: 49.0122909, lng: 19.4867201 },
    { lat: 49.0123603, lng: 19.486535 },
    { lat: 49.0124925, lng: 19.4863953 },
    { lat: 49.0126083, lng: 19.4863938 },
    { lat: 49.0127753, lng: 19.4861524 },
    { lat: 49.0130042, lng: 19.4859353 },
    { lat: 49.0132507, lng: 19.485637 },
    { lat: 49.0134432, lng: 19.4853854 },
    { lat: 49.0136274, lng: 19.4852268 },
    { lat: 49.0139311, lng: 19.4849454 },
    { lat: 49.0140749, lng: 19.4847395 },
    { lat: 49.0142744, lng: 19.4844939 },
    { lat: 49.0147935, lng: 19.4842183 },
    { lat: 49.0149199, lng: 19.4842705 },
    { lat: 49.014999, lng: 19.4841788 },
    { lat: 49.0150626, lng: 19.4841652 },
    { lat: 49.0151899, lng: 19.4842123 },
    { lat: 49.0155019, lng: 19.484163 },
    { lat: 49.0155707, lng: 19.4840861 },
    { lat: 49.0158111, lng: 19.4840716 },
    { lat: 49.0160768, lng: 19.4840879 },
    { lat: 49.0163957, lng: 19.484241 },
    { lat: 49.0165242, lng: 19.4842162 },
    { lat: 49.0167207, lng: 19.4843446 },
    { lat: 49.0168596, lng: 19.4844017 },
    { lat: 49.0170051, lng: 19.4844795 },
    { lat: 49.017214, lng: 19.4843909 },
    { lat: 49.0176746, lng: 19.4840927 },
    { lat: 49.0178665, lng: 19.4841929 },
    { lat: 49.0180706, lng: 19.484 },
    { lat: 49.0183766, lng: 19.484041 },
    { lat: 49.018485, lng: 19.4839943 },
    { lat: 49.0187839, lng: 19.4840038 },
    { lat: 49.0189837, lng: 19.4839836 },
    { lat: 49.0190816, lng: 19.4838591 },
    { lat: 49.0195001, lng: 19.483993 },
    { lat: 49.019603, lng: 19.4839519 },
    { lat: 49.0197544, lng: 19.484056 },
    { lat: 49.0198662, lng: 19.4839903 },
    { lat: 49.0202249, lng: 19.4839952 },
    { lat: 49.0204257, lng: 19.4840751 },
    { lat: 49.0206977, lng: 19.4841111 },
    { lat: 49.0208562, lng: 19.4842851 },
    { lat: 49.0209091, lng: 19.4843177 },
    { lat: 49.0210697, lng: 19.4843115 },
    { lat: 49.0213773, lng: 19.4843577 },
    { lat: 49.0215691, lng: 19.4845592 },
    { lat: 49.0220247, lng: 19.4848593 },
    { lat: 49.0224005, lng: 19.4850208 },
    { lat: 49.0228717, lng: 19.4854133 },
    { lat: 49.023315, lng: 19.4856656 },
    { lat: 49.0236781, lng: 19.4859685 },
    { lat: 49.0239033, lng: 19.4861355 },
    { lat: 49.0240529, lng: 19.4864256 },
    { lat: 49.0242092, lng: 19.4865655 },
    { lat: 49.0244963, lng: 19.4869288 },
    { lat: 49.024685, lng: 19.4872571 },
    { lat: 49.0247786, lng: 19.4872812 },
    { lat: 49.024932, lng: 19.4874577 },
    { lat: 49.0255016, lng: 19.4880093 },
    { lat: 49.0258093, lng: 19.4887447 },
    { lat: 49.026097, lng: 19.4891665 },
    { lat: 49.0265244, lng: 19.4893469 },
    { lat: 49.026956, lng: 19.489495 },
    { lat: 49.027088, lng: 19.4896246 },
    { lat: 49.0271659, lng: 19.4895761 },
    { lat: 49.0272822, lng: 19.4896768 },
    { lat: 49.0274076, lng: 19.4895651 },
    { lat: 49.027976, lng: 19.4894006 },
    { lat: 49.0280795, lng: 19.4895213 },
    { lat: 49.0281778, lng: 19.4894559 },
    { lat: 49.0281804, lng: 19.4893382 },
    { lat: 49.0287846, lng: 19.4892449 },
    { lat: 49.0288571, lng: 19.489281 },
    { lat: 49.0291273, lng: 19.4889539 },
    { lat: 49.0294073, lng: 19.4889673 },
    { lat: 49.0295024, lng: 19.4890746 },
    { lat: 49.0296465, lng: 19.488972 },
    { lat: 49.0299228, lng: 19.4889002 },
    { lat: 49.0302436, lng: 19.4886988 },
    { lat: 49.030529, lng: 19.4885334 },
    { lat: 49.030579, lng: 19.4885217 },
    { lat: 49.0307097, lng: 19.4885185 },
    { lat: 49.030796, lng: 19.4883366 },
    { lat: 49.0309069, lng: 19.4882272 },
    { lat: 49.0310428, lng: 19.4881413 },
    { lat: 49.031169, lng: 19.4879706 },
    { lat: 49.0315457, lng: 19.4877093 },
    { lat: 49.0316016, lng: 19.4877691 },
    { lat: 49.0318002, lng: 19.4878266 },
    { lat: 49.031797, lng: 19.4877113 },
    { lat: 49.0318864, lng: 19.4876408 },
    { lat: 49.0321086, lng: 19.4877694 },
    { lat: 49.0323556, lng: 19.4876016 },
    { lat: 49.0324693, lng: 19.487675 },
    { lat: 49.0326108, lng: 19.4876661 },
    { lat: 49.0326778, lng: 19.4878234 },
    { lat: 49.0327906, lng: 19.4877256 },
    { lat: 49.0328672, lng: 19.48785 },
    { lat: 49.0329825, lng: 19.4878058 },
    { lat: 49.0331911, lng: 19.4879114 },
    { lat: 49.033307, lng: 19.4881523 },
    { lat: 49.0334285, lng: 19.4881706 },
    { lat: 49.0336308, lng: 19.4880151 },
    { lat: 49.0336523, lng: 19.4882808 },
    { lat: 49.0338887, lng: 19.4882053 },
    { lat: 49.0339476, lng: 19.4879908 },
    { lat: 49.0340692, lng: 19.4879139 },
    { lat: 49.0341178, lng: 19.4880675 },
    { lat: 49.034211, lng: 19.4879376 },
    { lat: 49.0343264, lng: 19.4879214 },
    { lat: 49.0343727, lng: 19.4881226 },
    { lat: 49.034402, lng: 19.4881421 },
    { lat: 49.0344532, lng: 19.4879568 },
    { lat: 49.0345118, lng: 19.4880342 },
    { lat: 49.0345869, lng: 19.4879731 },
    { lat: 49.0345852, lng: 19.4879108 },
    { lat: 49.0346146, lng: 19.4877956 },
    { lat: 49.0346568, lng: 19.4877311 },
    { lat: 49.0347286, lng: 19.4878201 },
    { lat: 49.0346633, lng: 19.4879449 },
    { lat: 49.0347506, lng: 19.4880307 },
    { lat: 49.0349133, lng: 19.4879179 },
    { lat: 49.034925, lng: 19.4880503 },
    { lat: 49.0349929, lng: 19.4880782 },
    { lat: 49.0350882, lng: 19.4879216 },
    { lat: 49.0351778, lng: 19.4879602 },
    { lat: 49.0352387, lng: 19.487888 },
    { lat: 49.0354047, lng: 19.488042 },
    { lat: 49.0355464, lng: 19.4880381 },
    { lat: 49.0355422, lng: 19.4878945 },
    { lat: 49.0355122, lng: 19.4878528 },
    { lat: 49.0355032, lng: 19.4877805 },
    { lat: 49.0355455, lng: 19.4877006 },
    { lat: 49.0357285, lng: 19.4877303 },
    { lat: 49.0358209, lng: 19.4878807 },
    { lat: 49.035873, lng: 19.4878126 },
    { lat: 49.0359664, lng: 19.4878092 },
    { lat: 49.0360838, lng: 19.4880822 },
    { lat: 49.0361551, lng: 19.488062 },
    { lat: 49.0362507, lng: 19.4882063 },
    { lat: 49.0362985, lng: 19.4881197 },
    { lat: 49.0365876, lng: 19.4881579 },
    { lat: 49.0366723, lng: 19.4882857 },
    { lat: 49.0367387, lng: 19.4881637 },
    { lat: 49.0368814, lng: 19.4881996 },
    { lat: 49.0369688, lng: 19.488386 },
    { lat: 49.0369674, lng: 19.4885043 },
    { lat: 49.0369095, lng: 19.4887141 },
    { lat: 49.0370851, lng: 19.4888574 },
    { lat: 49.0372298, lng: 19.4886452 },
    { lat: 49.037352, lng: 19.4886312 },
    { lat: 49.0374713, lng: 19.4887772 },
    { lat: 49.0375427, lng: 19.4890059 },
    { lat: 49.0376204, lng: 19.4891291 },
    { lat: 49.037712, lng: 19.4891844 },
    { lat: 49.0378919, lng: 19.4891272 },
    { lat: 49.0380721, lng: 19.4891493 },
    { lat: 49.0381886, lng: 19.4892591 },
    { lat: 49.0383004, lng: 19.489235 },
    { lat: 49.0384382, lng: 19.4893212 },
    { lat: 49.0385554, lng: 19.4892201 },
    { lat: 49.0386499, lng: 19.4893379 },
    { lat: 49.0387707, lng: 19.4893926 },
    { lat: 49.0388382, lng: 19.4893678 },
    { lat: 49.0389495, lng: 19.4894357 },
    { lat: 49.0390871, lng: 19.4895575 },
    { lat: 49.0392027, lng: 19.4894781 },
    { lat: 49.0393499, lng: 19.4894639 },
    { lat: 49.039566, lng: 19.4896352 },
    { lat: 49.0396659, lng: 19.4897959 },
    { lat: 49.0397966, lng: 19.4897206 },
    { lat: 49.0399197, lng: 19.4897021 },
    { lat: 49.0400337, lng: 19.4898294 },
    { lat: 49.0400459, lng: 19.4900479 },
    { lat: 49.0402167, lng: 19.4901116 },
    { lat: 49.0403552, lng: 19.4901346 },
    { lat: 49.040508, lng: 19.4902699 },
    { lat: 49.0406407, lng: 19.4903625 },
    { lat: 49.0407038, lng: 19.4903633 },
    { lat: 49.0407726, lng: 19.4904892 },
    { lat: 49.0408612, lng: 19.4905761 },
    { lat: 49.0409854, lng: 19.4905309 },
    { lat: 49.0411468, lng: 19.4905852 },
    { lat: 49.0412526, lng: 19.4906563 },
    { lat: 49.0413762, lng: 19.4905763 },
    { lat: 49.0415316, lng: 19.490572 },
    { lat: 49.0415675, lng: 19.4907424 },
    { lat: 49.0417026, lng: 19.4907636 },
    { lat: 49.0418063, lng: 19.4910819 },
    { lat: 49.0418875, lng: 19.4910356 },
    { lat: 49.0419911, lng: 19.4910601 },
    { lat: 49.04212, lng: 19.4911671 },
    { lat: 49.0422121, lng: 19.491111 },
    { lat: 49.0423456, lng: 19.4911959 },
    { lat: 49.0425331, lng: 19.4912331 },
    { lat: 49.0426073, lng: 19.4913007 },
    { lat: 49.0427106, lng: 19.4913428 },
    { lat: 49.0428509, lng: 19.4914278 },
    { lat: 49.0428929, lng: 19.4915813 },
    { lat: 49.0429685, lng: 19.4915604 },
    { lat: 49.0430931, lng: 19.491647 },
    { lat: 49.0431861, lng: 19.4916612 },
    { lat: 49.0432946, lng: 19.4915911 },
    { lat: 49.0433892, lng: 19.4916407 },
    { lat: 49.04346, lng: 19.4918354 },
    { lat: 49.0435932, lng: 19.4919847 },
    { lat: 49.0436027, lng: 19.4919707 },
    { lat: 49.0436524, lng: 19.4920254 },
    { lat: 49.0436482, lng: 19.4920316 },
    { lat: 49.0436684, lng: 19.4920542 },
    { lat: 49.0437711, lng: 19.4920078 },
    { lat: 49.0438334, lng: 19.4920116 },
    { lat: 49.043997, lng: 19.4919698 },
    { lat: 49.0440255, lng: 19.4919269 },
    { lat: 49.0440935, lng: 19.4919634 },
    { lat: 49.0441252, lng: 19.4919655 },
    { lat: 49.0441394, lng: 19.491905 },
    { lat: 49.0441794, lng: 19.4920394 },
    { lat: 49.0442284, lng: 19.4920791 },
    { lat: 49.0442887, lng: 19.4922609 },
    { lat: 49.0442777, lng: 19.4923397 },
    { lat: 49.0443524, lng: 19.4923005 },
    { lat: 49.0443806, lng: 19.4924721 },
    { lat: 49.0443401, lng: 19.4925461 },
    { lat: 49.044357, lng: 19.4926668 },
    { lat: 49.0443463, lng: 19.4927545 },
    { lat: 49.0444155, lng: 19.4927895 },
    { lat: 49.0444112, lng: 19.4928194 },
    { lat: 49.0444402, lng: 19.4929112 },
    { lat: 49.0445333, lng: 19.4929497 },
    { lat: 49.0446054, lng: 19.4926052 },
    { lat: 49.0447919, lng: 19.4927186 },
    { lat: 49.0448549, lng: 19.4930111 },
    { lat: 49.0449504, lng: 19.4931511 },
    { lat: 49.0451423, lng: 19.4931336 },
    { lat: 49.0453086, lng: 19.4928782 },
    { lat: 49.0453653, lng: 19.4930805 },
    { lat: 49.0454487, lng: 19.4933543 },
    { lat: 49.045567, lng: 19.4935483 },
    { lat: 49.0457519, lng: 19.4934007 },
    { lat: 49.0459101, lng: 19.4934105 },
    { lat: 49.046101, lng: 19.4935731 },
    { lat: 49.0463951, lng: 19.4934007 },
    { lat: 49.0464414, lng: 19.4931281 },
    { lat: 49.0464272, lng: 19.4928699 },
    { lat: 49.0464543, lng: 19.4927966 },
    { lat: 49.046581, lng: 19.4927121 },
    { lat: 49.046677, lng: 19.4924932 },
    { lat: 49.046765, lng: 19.4924985 },
    { lat: 49.0468356, lng: 19.4924159 },
    { lat: 49.0469691, lng: 19.4920821 },
    { lat: 49.0470999, lng: 19.492011 },
    { lat: 49.0471472, lng: 19.4921102 },
    { lat: 49.0469824, lng: 19.4924693 },
    { lat: 49.0470269, lng: 19.492628 },
    { lat: 49.0471606, lng: 19.4925977 },
    { lat: 49.0472778, lng: 19.4925227 },
    { lat: 49.0473009, lng: 19.4926348 },
    { lat: 49.0473082, lng: 19.4927925 },
    { lat: 49.0474282, lng: 19.4928562 },
    { lat: 49.047551, lng: 19.4926097 },
    { lat: 49.0476573, lng: 19.4926411 },
    { lat: 49.0475821, lng: 19.4928693 },
    { lat: 49.0476887, lng: 19.4929812 },
    { lat: 49.0477543, lng: 19.4931341 },
    { lat: 49.0478587, lng: 19.4931956 },
    { lat: 49.0479654, lng: 19.4931393 },
    { lat: 49.0479617, lng: 19.4929612 },
    { lat: 49.048037, lng: 19.4929607 },
    { lat: 49.0481466, lng: 19.4930378 },
    { lat: 49.0482199, lng: 19.4929898 },
    { lat: 49.048344, lng: 19.4930168 },
    { lat: 49.0484509, lng: 19.493017 },
    { lat: 49.0485436, lng: 19.4930445 },
    { lat: 49.0486411, lng: 19.49306 },
    { lat: 49.0485685, lng: 19.4928263 },
    { lat: 49.0485673, lng: 19.4926759 },
    { lat: 49.0486741, lng: 19.4926265 },
    { lat: 49.0487925, lng: 19.4927461 },
    { lat: 49.0488775, lng: 19.4929569 },
    { lat: 49.0490268, lng: 19.492991 },
    { lat: 49.0491244, lng: 19.4929343 },
    { lat: 49.0491366, lng: 19.4927846 },
    { lat: 49.0490671, lng: 19.4926181 },
    { lat: 49.0491397, lng: 19.4925335 },
    { lat: 49.0492452, lng: 19.4924958 },
    { lat: 49.0493345, lng: 19.4923778 },
    { lat: 49.0493116, lng: 19.4922757 },
    { lat: 49.0491916, lng: 19.4922852 },
    { lat: 49.0491287, lng: 19.4921435 },
    { lat: 49.0491993, lng: 19.4919136 },
    { lat: 49.0492849, lng: 19.4917411 },
    { lat: 49.0494273, lng: 19.491653 },
    { lat: 49.0495406, lng: 19.4916421 },
    { lat: 49.0496295, lng: 19.4917882 },
    { lat: 49.0497971, lng: 19.4919529 },
    { lat: 49.0498579, lng: 19.4920998 },
    { lat: 49.0498392, lng: 19.4921082 },
    { lat: 49.0498531, lng: 19.4925084 },
    { lat: 49.0500498, lng: 19.4925701 },
    { lat: 49.0502972, lng: 19.4926372 },
    { lat: 49.0508715, lng: 19.4928478 },
    { lat: 49.0513934, lng: 19.4929015 },
    { lat: 49.0514189, lng: 19.4929157 },
    { lat: 49.0518992, lng: 19.4930712 },
    { lat: 49.052072, lng: 19.4930536 },
    { lat: 49.0530714, lng: 19.4930012 },
    { lat: 49.0530756, lng: 19.4929951 },
    { lat: 49.0531514, lng: 19.492192 },
    { lat: 49.0531481, lng: 19.4921463 },
    { lat: 49.0532479, lng: 19.4921853 },
    { lat: 49.0533417, lng: 19.4923356 },
    { lat: 49.0534878, lng: 19.4924019 },
    { lat: 49.0535731, lng: 19.4922948 },
    { lat: 49.053645, lng: 19.4924154 },
    { lat: 49.0538072, lng: 19.4925117 },
    { lat: 49.0539481, lng: 19.4924127 },
    { lat: 49.0540027, lng: 19.4925477 },
    { lat: 49.0539963, lng: 19.4927524 },
    { lat: 49.053974, lng: 19.4929251 },
    { lat: 49.0541434, lng: 19.492841 },
    { lat: 49.0543001, lng: 19.4929612 },
    { lat: 49.0544229, lng: 19.4929888 },
    { lat: 49.05452, lng: 19.493138 },
    { lat: 49.0545811, lng: 19.4930693 },
    { lat: 49.0547968, lng: 19.4929598 },
    { lat: 49.054964, lng: 19.4931605 },
    { lat: 49.0550703, lng: 19.4929968 },
    { lat: 49.055213, lng: 19.4929896 },
    { lat: 49.0552579, lng: 19.49284 },
    { lat: 49.0554973, lng: 19.4929468 },
    { lat: 49.0556103, lng: 19.493007 },
    { lat: 49.0557251, lng: 19.4928787 },
    { lat: 49.0560663, lng: 19.4928489 },
    { lat: 49.0562333, lng: 19.4927051 },
    { lat: 49.0563892, lng: 19.492718 },
    { lat: 49.056536, lng: 19.492799 },
    { lat: 49.0570902, lng: 19.4930868 },
    { lat: 49.0575102, lng: 19.4932747 },
    { lat: 49.0575792, lng: 19.4933798 },
    { lat: 49.0577569, lng: 19.493371 },
    { lat: 49.0577724, lng: 19.493365 },
    { lat: 49.0579506, lng: 19.4929304 },
    { lat: 49.0581933, lng: 19.4927188 },
    { lat: 49.0584773, lng: 19.4927907 },
    { lat: 49.0587367, lng: 19.4928188 },
    { lat: 49.0588749, lng: 19.4929327 },
    { lat: 49.0589952, lng: 19.4931732 },
    { lat: 49.0591288, lng: 19.4931411 },
    { lat: 49.0592285, lng: 19.4932246 },
    { lat: 49.0593498, lng: 19.4931661 },
    { lat: 49.059476, lng: 19.4931692 },
    { lat: 49.0593808, lng: 19.4936719 },
    { lat: 49.0596486, lng: 19.4936162 },
    { lat: 49.0598179, lng: 19.4937196 },
    { lat: 49.0598295, lng: 19.4939465 },
    { lat: 49.0600071, lng: 19.4939359 },
    { lat: 49.0601962, lng: 19.4938842 },
    { lat: 49.0602208, lng: 19.4941019 },
    { lat: 49.0603597, lng: 19.4941335 },
    { lat: 49.0604907, lng: 19.493942 },
    { lat: 49.0606519, lng: 19.4939917 },
    { lat: 49.0607158, lng: 19.4942519 },
    { lat: 49.0607738, lng: 19.4944606 },
    { lat: 49.0608996, lng: 19.4945773 },
    { lat: 49.0610358, lng: 19.4947217 },
    { lat: 49.0611575, lng: 19.4949405 },
    { lat: 49.0612637, lng: 19.4948979 },
    { lat: 49.061335, lng: 19.4948825 },
  ],
  LiptovskáSielnica: [
    { lat: 49.170993, lng: 19.426239 },
    { lat: 49.170347, lng: 19.427036 },
    { lat: 49.169333, lng: 19.426987 },
    { lat: 49.167989, lng: 19.426074 },
    { lat: 49.166527, lng: 19.42543 },
    { lat: 49.16563, lng: 19.425197 },
    { lat: 49.164399, lng: 19.425324 },
    { lat: 49.163549, lng: 19.425582 },
    { lat: 49.160651, lng: 19.425264 },
    { lat: 49.160233, lng: 19.424833 },
    { lat: 49.159902, lng: 19.424269 },
    { lat: 49.158337, lng: 19.425532 },
    { lat: 49.157351, lng: 19.425923 },
    { lat: 49.154903, lng: 19.426842 },
    { lat: 49.154543, lng: 19.427103 },
    { lat: 49.154224, lng: 19.427334 },
    { lat: 49.153997, lng: 19.427473 },
    { lat: 49.15346, lng: 19.42775 },
    { lat: 49.152988, lng: 19.427888 },
    { lat: 49.152394, lng: 19.427721 },
    { lat: 49.151818, lng: 19.427553 },
    { lat: 49.151055, lng: 19.4276 },
    { lat: 49.149629, lng: 19.42734 },
    { lat: 49.14908, lng: 19.427305 },
    { lat: 49.148671, lng: 19.426889 },
    { lat: 49.148123, lng: 19.427085 },
    { lat: 49.147892, lng: 19.42697 },
    { lat: 49.147478, lng: 19.426478 },
    { lat: 49.147144, lng: 19.426295 },
    { lat: 49.146954, lng: 19.426567 },
    { lat: 49.146486, lng: 19.427475 },
    { lat: 49.146017, lng: 19.427745 },
    { lat: 49.145563, lng: 19.428325 },
    { lat: 49.14503, lng: 19.429385 },
    { lat: 49.14418, lng: 19.431179 },
    { lat: 49.14377, lng: 19.431578 },
    { lat: 49.143487, lng: 19.431909 },
    { lat: 49.143335, lng: 19.432285 },
    { lat: 49.143316, lng: 19.432369 },
    { lat: 49.143094, lng: 19.432941 },
    { lat: 49.142804, lng: 19.433199 },
    { lat: 49.142187, lng: 19.433129 },
    { lat: 49.141138, lng: 19.43236 },
    { lat: 49.140878, lng: 19.43208 },
    { lat: 49.14059, lng: 19.431875 },
    { lat: 49.140183, lng: 19.432037 },
    { lat: 49.13971, lng: 19.43255 },
    { lat: 49.139397, lng: 19.432887 },
    { lat: 49.138804, lng: 19.433819 },
    { lat: 49.138108, lng: 19.435039 },
    { lat: 49.137601, lng: 19.436164 },
    { lat: 49.137267, lng: 19.437439 },
    { lat: 49.136884, lng: 19.438829 },
    { lat: 49.13644, lng: 19.440091 },
    { lat: 49.136415, lng: 19.440117 },
    { lat: 49.136357, lng: 19.440182 },
    { lat: 49.135765, lng: 19.440831 },
    { lat: 49.1381373, lng: 19.4458314 },
    { lat: 49.1383709, lng: 19.4463227 },
    { lat: 49.138659, lng: 19.4466819 },
    { lat: 49.1389011, lng: 19.447899 },
    { lat: 49.1388464, lng: 19.4485699 },
    { lat: 49.1396271, lng: 19.4498076 },
    { lat: 49.1402152, lng: 19.4510009 },
    { lat: 49.1407847, lng: 19.4522789 },
    { lat: 49.1408444, lng: 19.452523 },
    { lat: 49.1409135, lng: 19.4524799 },
    { lat: 49.1412397, lng: 19.4519814 },
    { lat: 49.1413624, lng: 19.4519988 },
    { lat: 49.1414375, lng: 19.4515049 },
    { lat: 49.1417775, lng: 19.4514004 },
    { lat: 49.141856, lng: 19.4510961 },
    { lat: 49.1416095, lng: 19.4506683 },
    { lat: 49.1418753, lng: 19.4501755 },
    { lat: 49.1417664, lng: 19.4497708 },
    { lat: 49.1420352, lng: 19.4494663 },
    { lat: 49.142075, lng: 19.4486918 },
    { lat: 49.1421792, lng: 19.4481659 },
    { lat: 49.1426395, lng: 19.4479333 },
    { lat: 49.1425649, lng: 19.4470041 },
    { lat: 49.1427907, lng: 19.4467444 },
    { lat: 49.1434675, lng: 19.4456172 },
    { lat: 49.1437028, lng: 19.4456815 },
    { lat: 49.1440051, lng: 19.4449576 },
    { lat: 49.144724, lng: 19.4438372 },
    { lat: 49.144888, lng: 19.4437268 },
    { lat: 49.1453275, lng: 19.4428491 },
    { lat: 49.1453793, lng: 19.4428011 },
    { lat: 49.1456648, lng: 19.442688 },
    { lat: 49.1466061, lng: 19.4413579 },
    { lat: 49.1471427, lng: 19.4404125 },
    { lat: 49.1476774, lng: 19.439088 },
    { lat: 49.1485306, lng: 19.4377979 },
    { lat: 49.1492651, lng: 19.4366522 },
    { lat: 49.1496819, lng: 19.4363064 },
    { lat: 49.1505532, lng: 19.4358976 },
    { lat: 49.151159, lng: 19.4353558 },
    { lat: 49.151236, lng: 19.4352377 },
    { lat: 49.1520465, lng: 19.4342526 },
    { lat: 49.1523404, lng: 19.4341731 },
    { lat: 49.1535679, lng: 19.4345218 },
    { lat: 49.153915, lng: 19.434424 },
    { lat: 49.1540734, lng: 19.4343176 },
    { lat: 49.154713, lng: 19.4332853 },
    { lat: 49.1550402, lng: 19.4333916 },
    { lat: 49.155283, lng: 19.4328913 },
    { lat: 49.1555685, lng: 19.4329498 },
    { lat: 49.1558673, lng: 19.4328078 },
    { lat: 49.157196, lng: 19.4328846 },
    { lat: 49.1574286, lng: 19.4330381 },
    { lat: 49.1577717, lng: 19.4332924 },
    { lat: 49.1580184, lng: 19.4336061 },
    { lat: 49.1580849, lng: 19.4336299 },
    { lat: 49.1582479, lng: 19.4337952 },
    { lat: 49.1586416, lng: 19.4340729 },
    { lat: 49.1588925, lng: 19.4342327 },
    { lat: 49.1590893, lng: 19.4341525 },
    { lat: 49.1593932, lng: 19.4337816 },
    { lat: 49.1602706, lng: 19.4330862 },
    { lat: 49.1606786, lng: 19.4328705 },
    { lat: 49.1614831, lng: 19.4328468 },
    { lat: 49.1623738, lng: 19.4332682 },
    { lat: 49.1627506, lng: 19.4333033 },
    { lat: 49.1629394, lng: 19.4332649 },
    { lat: 49.1634506, lng: 19.4328479 },
    { lat: 49.1638959, lng: 19.432755 },
    { lat: 49.1652699, lng: 19.4328348 },
    { lat: 49.1658075, lng: 19.4327369 },
    { lat: 49.1662068, lng: 19.4324566 },
    { lat: 49.1664222, lng: 19.4324564 },
    { lat: 49.1667077, lng: 19.4321977 },
    { lat: 49.1671265, lng: 19.4321454 },
    { lat: 49.1671941, lng: 19.4320697 },
    { lat: 49.1674992, lng: 19.4318661 },
    { lat: 49.1679905, lng: 19.4320112 },
    { lat: 49.16854, lng: 19.432427 },
    { lat: 49.16877, lng: 19.432499 },
    { lat: 49.169018, lng: 19.432625 },
    { lat: 49.169193, lng: 19.432606 },
    { lat: 49.169625, lng: 19.432561 },
    { lat: 49.169743, lng: 19.432629 },
    { lat: 49.169855, lng: 19.432638 },
    { lat: 49.170242, lng: 19.432198 },
    { lat: 49.170329, lng: 19.432178 },
    { lat: 49.170499, lng: 19.432289 },
    { lat: 49.170648, lng: 19.432287 },
    { lat: 49.170858, lng: 19.432095 },
    { lat: 49.171035, lng: 19.432072 },
    { lat: 49.171436, lng: 19.432152 },
    { lat: 49.171758, lng: 19.432122 },
    { lat: 49.172495, lng: 19.431913 },
    { lat: 49.1731725, lng: 19.4315724 },
    { lat: 49.1733568, lng: 19.4315568 },
    { lat: 49.173705, lng: 19.431796 },
    { lat: 49.173874, lng: 19.431916 },
    { lat: 49.174144, lng: 19.431906 },
    { lat: 49.174678, lng: 19.432118 },
    { lat: 49.174811, lng: 19.432178 },
    { lat: 49.174899, lng: 19.432167 },
    { lat: 49.175181, lng: 19.432209 },
    { lat: 49.175563, lng: 19.431883 },
    { lat: 49.175871, lng: 19.431763 },
    { lat: 49.175928, lng: 19.431779 },
    { lat: 49.1760523, lng: 19.4318133 },
    { lat: 49.176327, lng: 19.431889 },
    { lat: 49.176543, lng: 19.43203 },
    { lat: 49.176782, lng: 19.432326 },
    { lat: 49.176795, lng: 19.432315 },
    { lat: 49.176941, lng: 19.432208 },
    { lat: 49.177058, lng: 19.432087 },
    { lat: 49.177188, lng: 19.431811 },
    { lat: 49.177288, lng: 19.431488 },
    { lat: 49.177412, lng: 19.431195 },
    { lat: 49.177499, lng: 19.430766 },
    { lat: 49.177511, lng: 19.430533 },
    { lat: 49.177489, lng: 19.430153 },
    { lat: 49.177486, lng: 19.430036 },
    { lat: 49.177377, lng: 19.429782 },
    { lat: 49.177312, lng: 19.429452 },
    { lat: 49.177305, lng: 19.429319 },
    { lat: 49.177246, lng: 19.429179 },
    { lat: 49.177229, lng: 19.428983 },
    { lat: 49.177167, lng: 19.428676 },
    { lat: 49.17714, lng: 19.428576 },
    { lat: 49.177151, lng: 19.428425 },
    { lat: 49.177091, lng: 19.428175 },
    { lat: 49.177026, lng: 19.428095 },
    { lat: 49.176852, lng: 19.427891 },
    { lat: 49.176655, lng: 19.427696 },
    { lat: 49.176532, lng: 19.427609 },
    { lat: 49.176402, lng: 19.427478 },
    { lat: 49.176275, lng: 19.427381 },
    { lat: 49.176265, lng: 19.427328 },
    { lat: 49.17627, lng: 19.426419 },
    { lat: 49.176263, lng: 19.426103 },
    { lat: 49.176197, lng: 19.425883 },
    { lat: 49.176099, lng: 19.425642 },
    { lat: 49.176066, lng: 19.425349 },
    { lat: 49.175864, lng: 19.425022 },
    { lat: 49.175421, lng: 19.424756 },
    { lat: 49.175108, lng: 19.42456 },
    { lat: 49.174856, lng: 19.424416 },
    { lat: 49.174501, lng: 19.424203 },
    { lat: 49.174245, lng: 19.424055 },
    { lat: 49.174086, lng: 19.42398 },
    { lat: 49.17391, lng: 19.423993 },
    { lat: 49.173231, lng: 19.424315 },
    { lat: 49.17252, lng: 19.424945 },
    { lat: 49.172153, lng: 19.425274 },
    { lat: 49.171668, lng: 19.425685 },
    { lat: 49.170993, lng: 19.426239 },
  ],

  LiptovskáSielnicaExt: [
    { lat: 49.1098481, lng: 19.5011175 },
    { lat: 49.1098797, lng: 19.5006005 },
    { lat: 49.1099628, lng: 19.4990944 },
    { lat: 49.1100919, lng: 19.497272 },
    { lat: 49.1104237, lng: 19.4973859 },
    { lat: 49.1107571, lng: 19.4972752 },
    { lat: 49.1109489, lng: 19.4966662 },
    { lat: 49.110966, lng: 19.4959074 },
    { lat: 49.1102876, lng: 19.4946909 },
    { lat: 49.1102555, lng: 19.4943219 },
    { lat: 49.110568, lng: 19.4939581 },
    { lat: 49.1108062, lng: 19.4934533 },
    { lat: 49.1109915, lng: 19.4929455 },
    { lat: 49.1110747, lng: 19.4926694 },
    { lat: 49.1111292, lng: 19.4924841 },
    { lat: 49.1111722, lng: 19.4923411 },
    { lat: 49.1110618, lng: 19.4921219 },
    { lat: 49.1111645, lng: 19.4902328 },
    { lat: 49.111142, lng: 19.4896432 },
    { lat: 49.1112448, lng: 19.4888629 },
    { lat: 49.1114487, lng: 19.4877553 },
    { lat: 49.1114705, lng: 19.4868103 },
    { lat: 49.1114882, lng: 19.4860192 },
    { lat: 49.1115137, lng: 19.4849033 },
    { lat: 49.1115162, lng: 19.4848074 },
    { lat: 49.1111392, lng: 19.4847742 },
    { lat: 49.111135, lng: 19.4846815 },
    { lat: 49.1110897, lng: 19.483767 },
    { lat: 49.1109012, lng: 19.482312 },
    { lat: 49.110924, lng: 19.4799882 },
    { lat: 49.1101154, lng: 19.4791419 },
    { lat: 49.1092259, lng: 19.4788397 },
    { lat: 49.1085915, lng: 19.4788408 },
    { lat: 49.1080089, lng: 19.4789821 },
    { lat: 49.1067808, lng: 19.4791365 },
    { lat: 49.1056773, lng: 19.4792991 },
    { lat: 49.1056159, lng: 19.4793075 },
    { lat: 49.1049689, lng: 19.4785234 },
    { lat: 49.104984, lng: 19.4772074 },
    { lat: 49.1049946, lng: 19.4765103 },
    { lat: 49.1051287, lng: 19.4751381 },
    { lat: 49.1035815, lng: 19.4760181 },
    { lat: 49.1026253, lng: 19.4769811 },
    { lat: 49.1010794, lng: 19.4773043 },
    { lat: 49.1002822, lng: 19.4775612 },
    { lat: 49.0998655, lng: 19.4779577 },
    { lat: 49.0994971, lng: 19.478572 },
    { lat: 49.0995741, lng: 19.4789478 },
    { lat: 49.0996137, lng: 19.4792957 },
    { lat: 49.0997194, lng: 19.4796307 },
    { lat: 49.0999985, lng: 19.4800896 },
    { lat: 49.1005454, lng: 19.4805157 },
    { lat: 49.1006465, lng: 19.4807275 },
    { lat: 49.1008998, lng: 19.4816038 },
    { lat: 49.1011622, lng: 19.4819444 },
    { lat: 49.1014537, lng: 19.4820867 },
    { lat: 49.1018186, lng: 19.483086 },
    { lat: 49.1016504, lng: 19.483402 },
    { lat: 49.0985444, lng: 19.4848089 },
    { lat: 49.0985376, lng: 19.4848614 },
    { lat: 49.098649, lng: 19.485395 },
    { lat: 49.0987335, lng: 19.4853479 },
    { lat: 49.0987893, lng: 19.4855803 },
    { lat: 49.0986465, lng: 19.4856599 },
    { lat: 49.0990601, lng: 19.4886821 },
    { lat: 49.0990726, lng: 19.4887793 },
    { lat: 49.0997911, lng: 19.4909344 },
    { lat: 49.0998526, lng: 19.4922217 },
    { lat: 49.1005993, lng: 19.4986772 },
    { lat: 49.1042081, lng: 19.5032248 },
    { lat: 49.1054624, lng: 19.5062071 },
    { lat: 49.1054639, lng: 19.5070298 },
    { lat: 49.1055306, lng: 19.5080908 },
    { lat: 49.1056253, lng: 19.5091766 },
    { lat: 49.106778, lng: 19.5113448 },
    { lat: 49.1068305, lng: 19.5112911 },
    { lat: 49.1069605, lng: 19.5112723 },
    { lat: 49.1069914, lng: 19.5114319 },
    { lat: 49.1070645, lng: 19.5118956 },
    { lat: 49.1070423, lng: 19.5134344 },
    { lat: 49.1069382, lng: 19.5135493 },
    { lat: 49.1070318, lng: 19.5137469 },
    { lat: 49.1070991, lng: 19.5139603 },
    { lat: 49.1072017, lng: 19.5139102 },
    { lat: 49.1070868, lng: 19.5145791 },
    { lat: 49.1069815, lng: 19.514988 },
    { lat: 49.1071185, lng: 19.5150781 },
    { lat: 49.1071072, lng: 19.5161126 },
    { lat: 49.1071265, lng: 19.5168553 },
    { lat: 49.1070505, lng: 19.5173108 },
    { lat: 49.1074316, lng: 19.5184033 },
    { lat: 49.1074093, lng: 19.5192026 },
    { lat: 49.1070142, lng: 19.5193833 },
    { lat: 49.1063225, lng: 19.5208188 },
    { lat: 49.1068716, lng: 19.5211476 },
    { lat: 49.1065196, lng: 19.5218516 },
    { lat: 49.1060408, lng: 19.5224223 },
    { lat: 49.1057402, lng: 19.5227012 },
    { lat: 49.1052366, lng: 19.5229519 },
    { lat: 49.1052073, lng: 19.5230949 },
    { lat: 49.1049726, lng: 19.5245251 },
    { lat: 49.1059343, lng: 19.524536 },
    { lat: 49.1063164, lng: 19.524538 },
    { lat: 49.10662, lng: 19.5245264 },
    { lat: 49.1069184, lng: 19.5245197 },
    { lat: 49.107392, lng: 19.5246019 },
    { lat: 49.1079963, lng: 19.5247596 },
    { lat: 49.1082584, lng: 19.5248725 },
    { lat: 49.1088458, lng: 19.5249542 },
    { lat: 49.109078, lng: 19.5249577 },
    { lat: 49.1094731, lng: 19.5249947 },
    { lat: 49.1098738, lng: 19.5250361 },
    { lat: 49.1103393, lng: 19.5250391 },
    { lat: 49.1108806, lng: 19.524903 },
    { lat: 49.1112446, lng: 19.5247818 },
    { lat: 49.1115266, lng: 19.5246642 },
    { lat: 49.1120228, lng: 19.5244481 },
    { lat: 49.1125581, lng: 19.5242063 },
    { lat: 49.1134043, lng: 19.5238067 },
    { lat: 49.1135346, lng: 19.5237501 },
    { lat: 49.1144338, lng: 19.5233306 },
    { lat: 49.1144529, lng: 19.5233311 },
    { lat: 49.1144626, lng: 19.523321 },
    { lat: 49.1145288, lng: 19.5236345 },
    { lat: 49.1145533, lng: 19.5236794 },
    { lat: 49.1145974, lng: 19.5238528 },
    { lat: 49.1146251, lng: 19.5240153 },
    { lat: 49.1146626, lng: 19.5241495 },
    { lat: 49.114715, lng: 19.5242849 },
    { lat: 49.1148669, lng: 19.524452 },
    { lat: 49.1149746, lng: 19.5247157 },
    { lat: 49.1149548, lng: 19.5248009 },
    { lat: 49.1150613, lng: 19.5249875 },
    { lat: 49.115065, lng: 19.5250687 },
    { lat: 49.1151057, lng: 19.5250957 },
    { lat: 49.1152193, lng: 19.5251125 },
    { lat: 49.1153618, lng: 19.5252755 },
    { lat: 49.1154315, lng: 19.5252724 },
    { lat: 49.1154342, lng: 19.5252326 },
    { lat: 49.1156362, lng: 19.5253375 },
    { lat: 49.1156431, lng: 19.5254115 },
    { lat: 49.1156962, lng: 19.5253908 },
    { lat: 49.1157328, lng: 19.5254531 },
    { lat: 49.1157375, lng: 19.5255088 },
    { lat: 49.1157244, lng: 19.5255652 },
    { lat: 49.1157525, lng: 19.5256165 },
    { lat: 49.1158598, lng: 19.5256451 },
    { lat: 49.115942, lng: 19.5256454 },
    { lat: 49.1161968, lng: 19.525744 },
    { lat: 49.1162676, lng: 19.5258153 },
    { lat: 49.1163863, lng: 19.5258498 },
    { lat: 49.1164607, lng: 19.5257995 },
    { lat: 49.1166374, lng: 19.525797 },
    { lat: 49.116645, lng: 19.5257923 },
    { lat: 49.1168167, lng: 19.5258252 },
    { lat: 49.1169524, lng: 19.5256889 },
    { lat: 49.1169954, lng: 19.5256897 },
    { lat: 49.1170087, lng: 19.5257376 },
    { lat: 49.1170741, lng: 19.5257627 },
    { lat: 49.1171054, lng: 19.5258274 },
    { lat: 49.1171553, lng: 19.5258351 },
    { lat: 49.1172443, lng: 19.5258861 },
    { lat: 49.1172787, lng: 19.5258745 },
    { lat: 49.1172963, lng: 19.5258403 },
    { lat: 49.1173151, lng: 19.5257829 },
    { lat: 49.117349, lng: 19.5257851 },
    { lat: 49.1174348, lng: 19.5257616 },
    { lat: 49.1174946, lng: 19.525715 },
    { lat: 49.1175384, lng: 19.5257395 },
    { lat: 49.1176332, lng: 19.5257158 },
    { lat: 49.1176666, lng: 19.5257337 },
    { lat: 49.1177777, lng: 19.525793 },
    { lat: 49.1178126, lng: 19.5257969 },
    { lat: 49.1179353, lng: 19.5258684 },
    { lat: 49.1180574, lng: 19.5259008 },
    { lat: 49.1180899, lng: 19.5259445 },
    { lat: 49.1181202, lng: 19.525942 },
    { lat: 49.1181634, lng: 19.5259785 },
    { lat: 49.1182068, lng: 19.5259861 },
    { lat: 49.1182539, lng: 19.5260358 },
    { lat: 49.1183013, lng: 19.5260344 },
    { lat: 49.118371, lng: 19.526108 },
    { lat: 49.1184382, lng: 19.5261491 },
    { lat: 49.1185365, lng: 19.5261761 },
    { lat: 49.1185445, lng: 19.526231 },
    { lat: 49.1185675, lng: 19.5262642 },
    { lat: 49.1185979, lng: 19.5262642 },
    { lat: 49.1186801, lng: 19.5263897 },
    { lat: 49.1187344, lng: 19.5263879 },
    { lat: 49.1187807, lng: 19.5264402 },
    { lat: 49.1190572, lng: 19.5265764 },
    { lat: 49.1192818, lng: 19.5267333 },
    { lat: 49.119328, lng: 19.5267572 },
    { lat: 49.1193974, lng: 19.5268444 },
    { lat: 49.1194496, lng: 19.5268558 },
    { lat: 49.1194818, lng: 19.5268931 },
    { lat: 49.1195231, lng: 19.5269099 },
    { lat: 49.1195416, lng: 19.5269479 },
    { lat: 49.1195813, lng: 19.5269538 },
    { lat: 49.1196162, lng: 19.526927 },
    { lat: 49.1196384, lng: 19.526993 },
    { lat: 49.1196991, lng: 19.5270154 },
    { lat: 49.1198882, lng: 19.5271594 },
    { lat: 49.119905, lng: 19.5271555 },
    { lat: 49.1200227, lng: 19.5272661 },
    { lat: 49.1200568, lng: 19.5272747 },
    { lat: 49.1200919, lng: 19.527325 },
    { lat: 49.1201443, lng: 19.5273392 },
    { lat: 49.1201959, lng: 19.5274093 },
    { lat: 49.1202613, lng: 19.5274358 },
    { lat: 49.1202721, lng: 19.52747 },
    { lat: 49.1202874, lng: 19.5274849 },
    { lat: 49.1203066, lng: 19.5274854 },
    { lat: 49.1203675, lng: 19.5275897 },
    { lat: 49.120418, lng: 19.5275848 },
    { lat: 49.1204953, lng: 19.5277205 },
    { lat: 49.1205237, lng: 19.5277531 },
    { lat: 49.1205466, lng: 19.5278097 },
    { lat: 49.1206419, lng: 19.5278964 },
    { lat: 49.1206522, lng: 19.5279514 },
    { lat: 49.1206904, lng: 19.5279714 },
    { lat: 49.1207124, lng: 19.528035 },
    { lat: 49.1207747, lng: 19.5281158 },
    { lat: 49.1208619, lng: 19.5282735 },
    { lat: 49.1209281, lng: 19.5284147 },
    { lat: 49.1209683, lng: 19.5284579 },
    { lat: 49.1209946, lng: 19.5285444 },
    { lat: 49.1210428, lng: 19.5286123 },
    { lat: 49.1210713, lng: 19.5286938 },
    { lat: 49.1211873, lng: 19.5289122 },
    { lat: 49.1211906, lng: 19.5289381 },
    { lat: 49.1212075, lng: 19.5289618 },
    { lat: 49.1212292, lng: 19.5290185 },
    { lat: 49.1212601, lng: 19.529053 },
    { lat: 49.1212891, lng: 19.529146 },
    { lat: 49.1213046, lng: 19.529166 },
    { lat: 49.1213224, lng: 19.5292342 },
    { lat: 49.1214308, lng: 19.529458 },
    { lat: 49.1214744, lng: 19.5296017 },
    { lat: 49.1214984, lng: 19.5296106 },
    { lat: 49.1215301, lng: 19.5297105 },
    { lat: 49.1215412, lng: 19.5297094 },
    { lat: 49.1216273, lng: 19.5298632 },
    { lat: 49.1216239, lng: 19.5298938 },
    { lat: 49.1216644, lng: 19.5299158 },
    { lat: 49.1216833, lng: 19.5299328 },
    { lat: 49.1216721, lng: 19.5299829 },
    { lat: 49.1216992, lng: 19.5300128 },
    { lat: 49.1218851, lng: 19.5303299 },
    { lat: 49.1219006, lng: 19.5303495 },
    { lat: 49.1219421, lng: 19.530348 },
    { lat: 49.1219663, lng: 19.5304066 },
    { lat: 49.121999, lng: 19.5304571 },
    { lat: 49.1220156, lng: 19.5304744 },
    { lat: 49.1221016, lng: 19.530506 },
    { lat: 49.1221109, lng: 19.5305661 },
    { lat: 49.1221059, lng: 19.5305803 },
    { lat: 49.1220927, lng: 19.5305605 },
    { lat: 49.1220801, lng: 19.5305784 },
    { lat: 49.1221117, lng: 19.5306549 },
    { lat: 49.122124, lng: 19.5306561 },
    { lat: 49.1221414, lng: 19.5306169 },
    { lat: 49.1221764, lng: 19.5306182 },
    { lat: 49.1221951, lng: 19.5306306 },
    { lat: 49.1221973, lng: 19.5306793 },
    { lat: 49.1222973, lng: 19.5306767 },
    { lat: 49.1223185, lng: 19.5307172 },
    { lat: 49.1223379, lng: 19.5307224 },
    { lat: 49.1223649, lng: 19.5307497 },
    { lat: 49.1223532, lng: 19.5307629 },
    { lat: 49.1223333, lng: 19.5307718 },
    { lat: 49.1223231, lng: 19.5308171 },
    { lat: 49.1223596, lng: 19.5308791 },
    { lat: 49.1226209, lng: 19.5310745 },
    { lat: 49.122735, lng: 19.531178 },
    { lat: 49.1227879, lng: 19.5312058 },
    { lat: 49.122819, lng: 19.531245 },
    { lat: 49.1229466, lng: 19.5312981 },
    { lat: 49.1230285, lng: 19.5312671 },
    { lat: 49.1230442, lng: 19.5313169 },
    { lat: 49.1230591, lng: 19.5313225 },
    { lat: 49.1230939, lng: 19.5312936 },
    { lat: 49.1231271, lng: 19.5313279 },
    { lat: 49.1232555, lng: 19.5313269 },
    { lat: 49.1233363, lng: 19.5313429 },
    { lat: 49.1237442, lng: 19.5315681 },
    { lat: 49.1238181, lng: 19.5316312 },
    { lat: 49.1239565, lng: 19.5316788 },
    { lat: 49.123968, lng: 19.5317082 },
    { lat: 49.1240145, lng: 19.531713 },
    { lat: 49.1240326, lng: 19.5317417 },
    { lat: 49.1241304, lng: 19.531786 },
    { lat: 49.1241915, lng: 19.5318411 },
    { lat: 49.12421, lng: 19.5318744 },
    { lat: 49.1242405, lng: 19.5318553 },
    { lat: 49.1243439, lng: 19.5318966 },
    { lat: 49.1243678, lng: 19.5319484 },
    { lat: 49.1243992, lng: 19.5319753 },
    { lat: 49.1244233, lng: 19.531959 },
    { lat: 49.1246334, lng: 19.5320419 },
    { lat: 49.1246586, lng: 19.5320767 },
    { lat: 49.1247503, lng: 19.5321101 },
    { lat: 49.1247738, lng: 19.532108 },
    { lat: 49.1247838, lng: 19.5321303 },
    { lat: 49.1248682, lng: 19.5321524 },
    { lat: 49.1248886, lng: 19.5322024 },
    { lat: 49.1249088, lng: 19.5322049 },
    { lat: 49.124922, lng: 19.5322197 },
    { lat: 49.1249506, lng: 19.5322314 },
    { lat: 49.125, lng: 19.5322266 },
    { lat: 49.1250313, lng: 19.5322704 },
    { lat: 49.12511, lng: 19.5323139 },
    { lat: 49.1251465, lng: 19.5323011 },
    { lat: 49.1251591, lng: 19.5323303 },
    { lat: 49.1252249, lng: 19.5323381 },
    { lat: 49.1252431, lng: 19.5323901 },
    { lat: 49.125265, lng: 19.5323788 },
    { lat: 49.1253053, lng: 19.5323724 },
    { lat: 49.1253077, lng: 19.5323981 },
    { lat: 49.1253577, lng: 19.5324072 },
    { lat: 49.1253665, lng: 19.5324296 },
    { lat: 49.1253827, lng: 19.5324376 },
    { lat: 49.1254118, lng: 19.5324346 },
    { lat: 49.1254374, lng: 19.532479 },
    { lat: 49.1254755, lng: 19.5324753 },
    { lat: 49.1254961, lng: 19.5325084 },
    { lat: 49.1255184, lng: 19.5324992 },
    { lat: 49.1255387, lng: 19.5325255 },
    { lat: 49.1256139, lng: 19.5325694 },
    { lat: 49.1256369, lng: 19.5325554 },
    { lat: 49.125677, lng: 19.5325915 },
    { lat: 49.1257023, lng: 19.532582 },
    { lat: 49.1257173, lng: 19.532611 },
    { lat: 49.1257324, lng: 19.5325957 },
    { lat: 49.1257378, lng: 19.5326397 },
    { lat: 49.1257699, lng: 19.5326295 },
    { lat: 49.1257912, lng: 19.5326787 },
    { lat: 49.1258362, lng: 19.5326768 },
    { lat: 49.1258944, lng: 19.5327153 },
    { lat: 49.125908, lng: 19.5327444 },
    { lat: 49.1259523, lng: 19.5327264 },
    { lat: 49.1259838, lng: 19.5327487 },
    { lat: 49.1260052, lng: 19.5327471 },
    { lat: 49.1260362, lng: 19.5327859 },
    { lat: 49.1260283, lng: 19.5328076 },
    { lat: 49.1260457, lng: 19.5328227 },
    { lat: 49.1260576, lng: 19.5328092 },
    { lat: 49.1260855, lng: 19.5328045 },
    { lat: 49.1261089, lng: 19.5328258 },
    { lat: 49.1261481, lng: 19.532822 },
    { lat: 49.1261728, lng: 19.532892 },
    { lat: 49.1262671, lng: 19.5329342 },
    { lat: 49.1263546, lng: 19.5329236 },
    { lat: 49.1263675, lng: 19.532983 },
    { lat: 49.1264238, lng: 19.5329591 },
    { lat: 49.1264428, lng: 19.5330038 },
    { lat: 49.126483, lng: 19.5329485 },
    { lat: 49.1265261, lng: 19.532977 },
    { lat: 49.1265597, lng: 19.5329738 },
    { lat: 49.1265718, lng: 19.5330406 },
    { lat: 49.1265916, lng: 19.5330036 },
    { lat: 49.1266041, lng: 19.533035 },
    { lat: 49.1266206, lng: 19.5330243 },
    { lat: 49.126666, lng: 19.5330313 },
    { lat: 49.1266926, lng: 19.5330706 },
    { lat: 49.1267294, lng: 19.533039 },
    { lat: 49.1267236, lng: 19.5330094 },
    { lat: 49.1267389, lng: 19.5329776 },
    { lat: 49.1267733, lng: 19.5330117 },
    { lat: 49.1267985, lng: 19.5330933 },
    { lat: 49.1268252, lng: 19.5330904 },
    { lat: 49.1268484, lng: 19.5331279 },
    { lat: 49.1268705, lng: 19.5330936 },
    { lat: 49.1268889, lng: 19.5331031 },
    { lat: 49.1269005, lng: 19.5330832 },
    { lat: 49.1269323, lng: 19.5330921 },
    { lat: 49.1269585, lng: 19.533071 },
    { lat: 49.1270978, lng: 19.533139 },
    { lat: 49.1271422, lng: 19.5331955 },
    { lat: 49.1272394, lng: 19.5331068 },
    { lat: 49.1272705, lng: 19.5331413 },
    { lat: 49.1272856, lng: 19.5331303 },
    { lat: 49.1273147, lng: 19.5331744 },
    { lat: 49.1273629, lng: 19.5330714 },
    { lat: 49.1274381, lng: 19.5330876 },
    { lat: 49.1274746, lng: 19.533051 },
    { lat: 49.1274774, lng: 19.5331119 },
    { lat: 49.1274921, lng: 19.5331337 },
    { lat: 49.1274983, lng: 19.5331705 },
    { lat: 49.1275398, lng: 19.5331711 },
    { lat: 49.127552, lng: 19.5331162 },
    { lat: 49.1275613, lng: 19.5331246 },
    { lat: 49.1276225, lng: 19.5331044 },
    { lat: 49.1277021, lng: 19.5331716 },
    { lat: 49.1277201, lng: 19.533142 },
    { lat: 49.1277458, lng: 19.5331418 },
    { lat: 49.1277602, lng: 19.5331565 },
    { lat: 49.1277744, lng: 19.5331481 },
    { lat: 49.1277631, lng: 19.5331216 },
    { lat: 49.12778, lng: 19.5331245 },
    { lat: 49.1278681, lng: 19.5330785 },
    { lat: 49.1279448, lng: 19.5331294 },
    { lat: 49.1280461, lng: 19.5331245 },
    { lat: 49.1280735, lng: 19.5330845 },
    { lat: 49.1281071, lng: 19.5331069 },
    { lat: 49.1281849, lng: 19.5330573 },
    { lat: 49.1282663, lng: 19.5330663 },
    { lat: 49.1282976, lng: 19.5330846 },
    { lat: 49.1283255, lng: 19.533127 },
    { lat: 49.1283886, lng: 19.5331548 },
    { lat: 49.1283999, lng: 19.5332004 },
    { lat: 49.1284451, lng: 19.5332277 },
    { lat: 49.1284744, lng: 19.5332023 },
    { lat: 49.1284961, lng: 19.5331608 },
    { lat: 49.1284909, lng: 19.5330983 },
    { lat: 49.1284989, lng: 19.5330766 },
    { lat: 49.1285749, lng: 19.5330873 },
    { lat: 49.1286089, lng: 19.5331129 },
    { lat: 49.1287604, lng: 19.5331526 },
    { lat: 49.1287758, lng: 19.5331704 },
    { lat: 49.1291149, lng: 19.5332422 },
    { lat: 49.1291475, lng: 19.5332377 },
    { lat: 49.1291751, lng: 19.5332006 },
    { lat: 49.1291962, lng: 19.5332458 },
    { lat: 49.1292365, lng: 19.5332121 },
    { lat: 49.1293019, lng: 19.5332376 },
    { lat: 49.1293749, lng: 19.5332086 },
    { lat: 49.1294437, lng: 19.5332348 },
    { lat: 49.1294951, lng: 19.5333272 },
    { lat: 49.1295525, lng: 19.5333744 },
    { lat: 49.1296347, lng: 19.533396 },
    { lat: 49.1296836, lng: 19.5333602 },
    { lat: 49.1297795, lng: 19.5333875 },
    { lat: 49.1297926, lng: 19.5334254 },
    { lat: 49.1298179, lng: 19.5334367 },
    { lat: 49.1298217, lng: 19.533469 },
    { lat: 49.1298343, lng: 19.5334767 },
    { lat: 49.1298601, lng: 19.5334718 },
    { lat: 49.1299283, lng: 19.5335132 },
    { lat: 49.1299838, lng: 19.5335653 },
    { lat: 49.1300356, lng: 19.5335943 },
    { lat: 49.130118, lng: 19.5335925 },
    { lat: 49.1302256, lng: 19.5337067 },
    { lat: 49.1302512, lng: 19.5336971 },
    { lat: 49.1303201, lng: 19.5337546 },
    { lat: 49.1303375, lng: 19.5337618 },
    { lat: 49.1303588, lng: 19.533739 },
    { lat: 49.1303921, lng: 19.5337492 },
    { lat: 49.1304401, lng: 19.5338114 },
    { lat: 49.1304494, lng: 19.5338705 },
    { lat: 49.1304687, lng: 19.5338753 },
    { lat: 49.1305182, lng: 19.5338726 },
    { lat: 49.1306034, lng: 19.53391 },
    { lat: 49.1306404, lng: 19.5339806 },
    { lat: 49.1306497, lng: 19.5340397 },
    { lat: 49.1307396, lng: 19.5340068 },
    { lat: 49.1308109, lng: 19.5339385 },
    { lat: 49.1309726, lng: 19.5343492 },
    { lat: 49.1311009, lng: 19.534544 },
    { lat: 49.1317688, lng: 19.5352589 },
    { lat: 49.1319947, lng: 19.5354189 },
    { lat: 49.1328202, lng: 19.5358035 },
    { lat: 49.1342546, lng: 19.5370467 },
    { lat: 49.1344498, lng: 19.5372722 },
    { lat: 49.1345574, lng: 19.5374858 },
    { lat: 49.1346444, lng: 19.5377623 },
    { lat: 49.1352526, lng: 19.5401169 },
    { lat: 49.1352757, lng: 19.5402502 },
    { lat: 49.1352596, lng: 19.5404167 },
    { lat: 49.1352397, lng: 19.5404979 },
    { lat: 49.1352487, lng: 19.540624 },
    { lat: 49.135241, lng: 19.5406763 },
    { lat: 49.1354455, lng: 19.5406676 },
    { lat: 49.1358352, lng: 19.5407122 },
    { lat: 49.1360895, lng: 19.5407548 },
    { lat: 49.1365537, lng: 19.540853 },
    { lat: 49.1370128, lng: 19.5409384 },
    { lat: 49.1375295, lng: 19.5410023 },
    { lat: 49.1375652, lng: 19.541045 },
    { lat: 49.1375899, lng: 19.5416113 },
    { lat: 49.1376541, lng: 19.5417614 },
    { lat: 49.13777, lng: 19.5420051 },
    { lat: 49.1377943, lng: 19.5420723 },
    { lat: 49.1379133, lng: 19.5423113 },
    { lat: 49.1379598, lng: 19.5424622 },
    { lat: 49.1379992, lng: 19.5426628 },
    { lat: 49.1380409, lng: 19.5427347 },
    { lat: 49.1381096, lng: 19.5429315 },
    { lat: 49.1382769, lng: 19.5433208 },
    { lat: 49.1383088, lng: 19.5433524 },
    { lat: 49.1383744, lng: 19.543506 },
    { lat: 49.1384744, lng: 19.543671 },
    { lat: 49.138537, lng: 19.5437134 },
    { lat: 49.1385679, lng: 19.5437706 },
    { lat: 49.1386053, lng: 19.5438037 },
    { lat: 49.1386249, lng: 19.543834 },
    { lat: 49.1387072, lng: 19.5440348 },
    { lat: 49.1387636, lng: 19.5441127 },
    { lat: 49.1387719, lng: 19.5441446 },
    { lat: 49.1388262, lng: 19.544227 },
    { lat: 49.1388925, lng: 19.5443924 },
    { lat: 49.1390427, lng: 19.5447263 },
    { lat: 49.1391121, lng: 19.5448612 },
    { lat: 49.1391646, lng: 19.5450046 },
    { lat: 49.13934, lng: 19.545441 },
    { lat: 49.1393901, lng: 19.545603 },
    { lat: 49.1394914, lng: 19.5458216 },
    { lat: 49.1397352, lng: 19.5464286 },
    { lat: 49.1398124, lng: 19.5465438 },
    { lat: 49.1401359, lng: 19.5469398 },
    { lat: 49.1403174, lng: 19.547192 },
    { lat: 49.14034, lng: 19.5472433 },
    { lat: 49.1405654, lng: 19.5475437 },
    { lat: 49.1406833, lng: 19.5477804 },
    { lat: 49.1407145, lng: 19.5478015 },
    { lat: 49.1407757, lng: 19.5479067 },
    { lat: 49.1413581, lng: 19.5488483 },
    { lat: 49.14191, lng: 19.5493911 },
    { lat: 49.1424332, lng: 19.5499961 },
    { lat: 49.1425026, lng: 19.5501374 },
    { lat: 49.1426594, lng: 19.5503861 },
    { lat: 49.1427962, lng: 19.5506711 },
    { lat: 49.1428338, lng: 19.5507075 },
    { lat: 49.1430216, lng: 19.5502983 },
    { lat: 49.1432148, lng: 19.5496913 },
    { lat: 49.143357, lng: 19.549374 },
    { lat: 49.1434654, lng: 19.5490783 },
    { lat: 49.1436037, lng: 19.5486038 },
    { lat: 49.1437514, lng: 19.5481535 },
    { lat: 49.1438666, lng: 19.5476897 },
    { lat: 49.1439496, lng: 19.5472343 },
    { lat: 49.1439783, lng: 19.5468725 },
    { lat: 49.1439998, lng: 19.5465283 },
    { lat: 49.1439917, lng: 19.5463506 },
    { lat: 49.1439293, lng: 19.5456673 },
    { lat: 49.1438413, lng: 19.5451212 },
    { lat: 49.1438532, lng: 19.5450351 },
    { lat: 49.143867, lng: 19.5449911 },
    { lat: 49.1439035, lng: 19.5449279 },
    { lat: 49.14433, lng: 19.5446645 },
    { lat: 49.1447709, lng: 19.5442761 },
    { lat: 49.1451245, lng: 19.5438994 },
    { lat: 49.1452458, lng: 19.5437146 },
    { lat: 49.1454022, lng: 19.5434152 },
    { lat: 49.1456181, lng: 19.5429091 },
    { lat: 49.1458534, lng: 19.5424059 },
    { lat: 49.1459175, lng: 19.5422329 },
    { lat: 49.146005, lng: 19.5421261 },
    { lat: 49.1461218, lng: 19.541968 },
    { lat: 49.1471425, lng: 19.5408245 },
    { lat: 49.1473858, lng: 19.5405735 },
    { lat: 49.1483455, lng: 19.5395267 },
    { lat: 49.1487842, lng: 19.5390625 },
    { lat: 49.1490468, lng: 19.538888 },
    { lat: 49.1491939, lng: 19.5387529 },
    { lat: 49.1494006, lng: 19.5385414 },
    { lat: 49.1495219, lng: 19.5384584 },
    { lat: 49.1497755, lng: 19.5382657 },
    { lat: 49.1499804, lng: 19.5381617 },
    { lat: 49.1502083, lng: 19.5380689 },
    { lat: 49.1506743, lng: 19.5378861 },
    { lat: 49.1516984, lng: 19.5375347 },
    { lat: 49.1519828, lng: 19.5373963 },
    { lat: 49.1521156, lng: 19.537316 },
    { lat: 49.1525943, lng: 19.5370902 },
    { lat: 49.1526245, lng: 19.5370848 },
    { lat: 49.1527645, lng: 19.5370164 },
    { lat: 49.1533135, lng: 19.5367263 },
    { lat: 49.1528503, lng: 19.5346654 },
    { lat: 49.1528037, lng: 19.5338417 },
    { lat: 49.1527876, lng: 19.5335864 },
    { lat: 49.1527213, lng: 19.5324494 },
    { lat: 49.1528491, lng: 19.5312948 },
    { lat: 49.1529023, lng: 19.5302568 },
    { lat: 49.1532369, lng: 19.5289723 },
    { lat: 49.1534005, lng: 19.5283326 },
    { lat: 49.1537014, lng: 19.5271665 },
    { lat: 49.1537449, lng: 19.527059 },
    { lat: 49.1537808, lng: 19.5269562 },
    { lat: 49.1540029, lng: 19.5263945 },
    { lat: 49.1542747, lng: 19.5257322 },
    { lat: 49.1539308, lng: 19.5246858 },
    { lat: 49.1536634, lng: 19.524083 },
    { lat: 49.1537519, lng: 19.5235791 },
    { lat: 49.153978, lng: 19.5230008 },
    { lat: 49.1538524, lng: 19.5217981 },
    { lat: 49.1538612, lng: 19.5214274 },
    { lat: 49.1541225, lng: 19.5211766 },
    { lat: 49.1541404, lng: 19.5203834 },
    { lat: 49.1541384, lng: 19.5201158 },
    { lat: 49.1541594, lng: 19.5198116 },
    { lat: 49.1540402, lng: 19.5195171 },
    { lat: 49.1542433, lng: 19.5190824 },
    { lat: 49.1544616, lng: 19.5188001 },
    { lat: 49.1546892, lng: 19.5186759 },
    { lat: 49.1548825, lng: 19.5186903 },
    { lat: 49.1552232, lng: 19.518404 },
    { lat: 49.1561021, lng: 19.5179357 },
    { lat: 49.1570288, lng: 19.5174523 },
    { lat: 49.1575595, lng: 19.5171566 },
    { lat: 49.1579532, lng: 19.5168226 },
    { lat: 49.1575636, lng: 19.5165258 },
    { lat: 49.157125, lng: 19.516105 },
    { lat: 49.1571449, lng: 19.5160425 },
    { lat: 49.156966, lng: 19.5158526 },
    { lat: 49.1569022, lng: 19.5158104 },
    { lat: 49.1564917, lng: 19.5149651 },
    { lat: 49.1561315, lng: 19.51522 },
    { lat: 49.1561146, lng: 19.5152438 },
    { lat: 49.1557245, lng: 19.5148175 },
    { lat: 49.1557064, lng: 19.5148403 },
    { lat: 49.1553132, lng: 19.5152618 },
    { lat: 49.1552574, lng: 19.5158923 },
    { lat: 49.1551528, lng: 19.5171821 },
    { lat: 49.1551386, lng: 19.5178859 },
    { lat: 49.1547898, lng: 19.5184388 },
    { lat: 49.1541795, lng: 19.5182479 },
    { lat: 49.1533444, lng: 19.517678 },
    { lat: 49.1528123, lng: 19.5168571 },
    { lat: 49.1524335, lng: 19.5159121 },
    { lat: 49.1522199, lng: 19.5152051 },
    { lat: 49.1518829, lng: 19.5145885 },
    { lat: 49.1516156, lng: 19.5141374 },
    { lat: 49.1506632, lng: 19.5130675 },
    { lat: 49.1499195, lng: 19.5120824 },
    { lat: 49.149885, lng: 19.5120137 },
    { lat: 49.1491672, lng: 19.5106321 },
    { lat: 49.1483106, lng: 19.5099869 },
    { lat: 49.1473841, lng: 19.5092117 },
    { lat: 49.1467974, lng: 19.5088503 },
    { lat: 49.1461993, lng: 19.5086593 },
    { lat: 49.145376, lng: 19.5084214 },
    { lat: 49.1444605, lng: 19.5080363 },
    { lat: 49.1442541, lng: 19.5079326 },
    { lat: 49.1436882, lng: 19.5076079 },
    { lat: 49.1431924, lng: 19.5073352 },
    { lat: 49.1429829, lng: 19.5072412 },
    { lat: 49.1426774, lng: 19.5073104 },
    { lat: 49.14182, lng: 19.5074835 },
    { lat: 49.1406523, lng: 19.5072803 },
    { lat: 49.1396769, lng: 19.5071402 },
    { lat: 49.1393029, lng: 19.5070929 },
    { lat: 49.1386305, lng: 19.5069026 },
    { lat: 49.1383809, lng: 19.5068399 },
    { lat: 49.1379829, lng: 19.5068587 },
    { lat: 49.1370259, lng: 19.5069258 },
    { lat: 49.1364497, lng: 19.507037 },
    { lat: 49.1360792, lng: 19.5071656 },
    { lat: 49.1360768, lng: 19.5071392 },
    { lat: 49.1360252, lng: 19.5070227 },
    { lat: 49.1356512, lng: 19.5062837 },
    { lat: 49.1350003, lng: 19.5049832 },
    { lat: 49.1342681, lng: 19.503337 },
    { lat: 49.1341521, lng: 19.5027718 },
    { lat: 49.1341371, lng: 19.5027173 },
    { lat: 49.1338536, lng: 19.5029255 },
    { lat: 49.1337479, lng: 19.5029018 },
    { lat: 49.1335719, lng: 19.5027039 },
    { lat: 49.1334299, lng: 19.5026042 },
    { lat: 49.1331285, lng: 19.5024405 },
    { lat: 49.1328514, lng: 19.5024224 },
    { lat: 49.1327152, lng: 19.5025945 },
    { lat: 49.1325282, lng: 19.5024244 },
    { lat: 49.13234, lng: 19.5024743 },
    { lat: 49.1321825, lng: 19.5022589 },
    { lat: 49.1320163, lng: 19.5023208 },
    { lat: 49.1319486, lng: 19.5021934 },
    { lat: 49.1316848, lng: 19.5021664 },
    { lat: 49.1315579, lng: 19.5021029 },
    { lat: 49.1314832, lng: 19.5019712 },
    { lat: 49.1312818, lng: 19.5018066 },
    { lat: 49.1312155, lng: 19.5017608 },
    { lat: 49.1311163, lng: 19.5016371 },
    { lat: 49.1308578, lng: 19.5015285 },
    { lat: 49.1303896, lng: 19.5014167 },
    { lat: 49.1303092, lng: 19.5014077 },
    { lat: 49.1301891, lng: 19.5014913 },
    { lat: 49.1300553, lng: 19.5015707 },
    { lat: 49.1299912, lng: 19.5016253 },
    { lat: 49.1299541, lng: 19.5015742 },
    { lat: 49.1298702, lng: 19.5016133 },
    { lat: 49.1298234, lng: 19.5015503 },
    { lat: 49.129625, lng: 19.5013526 },
    { lat: 49.1293675, lng: 19.5012156 },
    { lat: 49.1284317, lng: 19.5013744 },
    { lat: 49.1280925, lng: 19.5015472 },
    { lat: 49.1277993, lng: 19.5016143 },
    { lat: 49.1276649, lng: 19.5017592 },
    { lat: 49.1276829, lng: 19.5016828 },
    { lat: 49.1274117, lng: 19.5003369 },
    { lat: 49.1279128, lng: 19.5000612 },
    { lat: 49.1282566, lng: 19.4999164 },
    { lat: 49.128722, lng: 19.4996949 },
    { lat: 49.128674, lng: 19.4992866 },
    { lat: 49.1291516, lng: 19.4991607 },
    { lat: 49.1291835, lng: 19.4994149 },
    { lat: 49.1293911, lng: 19.4993162 },
    { lat: 49.1290613, lng: 19.4980491 },
    { lat: 49.1285474, lng: 19.4960533 },
    { lat: 49.1282613, lng: 19.4949968 },
    { lat: 49.1275819, lng: 19.4925185 },
    { lat: 49.1274862, lng: 19.4921509 },
    { lat: 49.1269222, lng: 19.4924052 },
    { lat: 49.1260097, lng: 19.4926549 },
    { lat: 49.1257685, lng: 19.4926564 },
    { lat: 49.1240144, lng: 19.4932295 },
    { lat: 49.1229765, lng: 19.4935181 },
    { lat: 49.1222071, lng: 19.4938665 },
    { lat: 49.1209725, lng: 19.4938522 },
    { lat: 49.1203456, lng: 19.4938945 },
    { lat: 49.1195706, lng: 19.4939445 },
    { lat: 49.1188829, lng: 19.4938634 },
    { lat: 49.1189544, lng: 19.4951044 },
    { lat: 49.1189696, lng: 19.4954111 },
    { lat: 49.1190246, lng: 19.4955293 },
    { lat: 49.1189479, lng: 19.4957519 },
    { lat: 49.1187839, lng: 19.4969435 },
    { lat: 49.1187206, lng: 19.4983398 },
    { lat: 49.1187516, lng: 19.4986002 },
    { lat: 49.1185474, lng: 19.4999918 },
    { lat: 49.1179888, lng: 19.5001486 },
    { lat: 49.1178775, lng: 19.5002288 },
    { lat: 49.1174377, lng: 19.5003187 },
    { lat: 49.1166447, lng: 19.5018745 },
    { lat: 49.116045, lng: 19.5032267 },
    { lat: 49.1156269, lng: 19.5045545 },
    { lat: 49.1154344, lng: 19.5049034 },
    { lat: 49.1142988, lng: 19.5040449 },
    { lat: 49.1142, lng: 19.5036816 },
    { lat: 49.11404, lng: 19.503754 },
    { lat: 49.1139034, lng: 19.5037275 },
    { lat: 49.1136137, lng: 19.5035504 },
    { lat: 49.1132971, lng: 19.5031549 },
    { lat: 49.1130934, lng: 19.5026719 },
    { lat: 49.1130354, lng: 19.5022907 },
    { lat: 49.1128701, lng: 19.5017547 },
    { lat: 49.1125846, lng: 19.5013767 },
    { lat: 49.1123997, lng: 19.5012996 },
    { lat: 49.1121878, lng: 19.5013221 },
    { lat: 49.111673, lng: 19.5016466 },
    { lat: 49.111021, lng: 19.5017781 },
    { lat: 49.1106621, lng: 19.5014702 },
    { lat: 49.1102826, lng: 19.5012062 },
    { lat: 49.1098481, lng: 19.5011175 },
  ],
  LiptovskýTrnovec: [
    { lat: 49.1942405, lng: 19.6377535 },
    { lat: 49.1946311, lng: 19.6375049 },
    { lat: 49.1948834, lng: 19.6373541 },
    { lat: 49.196141, lng: 19.6368783 },
    { lat: 49.1970472, lng: 19.6371143 },
    { lat: 49.1991592, lng: 19.6365743 },
    { lat: 49.2005488, lng: 19.6362083 },
    { lat: 49.2007878, lng: 19.6371959 },
    { lat: 49.2011938, lng: 19.6378639 },
    { lat: 49.2021814, lng: 19.639376 },
    { lat: 49.2025438, lng: 19.63977 },
    { lat: 49.2037641, lng: 19.6410909 },
    { lat: 49.20497, lng: 19.6423711 },
    { lat: 49.2057526, lng: 19.6420789 },
    { lat: 49.2078848, lng: 19.6418695 },
    { lat: 49.209007, lng: 19.6412621 },
    { lat: 49.2102963, lng: 19.6418329 },
    { lat: 49.2110459, lng: 19.6421679 },
    { lat: 49.211119, lng: 19.6421931 },
    { lat: 49.2112881, lng: 19.6413079 },
    { lat: 49.2115109, lng: 19.6408707 },
    { lat: 49.2117867, lng: 19.6393975 },
    { lat: 49.2121224, lng: 19.6355508 },
    { lat: 49.2124337, lng: 19.6350245 },
    { lat: 49.212329, lng: 19.6343179 },
    { lat: 49.2124576, lng: 19.631867 },
    { lat: 49.2129493, lng: 19.6300859 },
    { lat: 49.2131445, lng: 19.628502 },
    { lat: 49.2126999, lng: 19.6272184 },
    { lat: 49.2127995, lng: 19.6259695 },
    { lat: 49.2127979, lng: 19.6258064 },
    { lat: 49.2126305, lng: 19.6246779 },
    { lat: 49.2127028, lng: 19.6237485 },
    { lat: 49.2131895, lng: 19.62034 },
    { lat: 49.2141218, lng: 19.6184652 },
    { lat: 49.2141425, lng: 19.6179719 },
    { lat: 49.2142011, lng: 19.6178696 },
    { lat: 49.2147435, lng: 19.6169226 },
    { lat: 49.2154156, lng: 19.6159502 },
    { lat: 49.2163723, lng: 19.6140418 },
    { lat: 49.2166356, lng: 19.6128229 },
    { lat: 49.2167046, lng: 19.6094045 },
    { lat: 49.216304, lng: 19.607043 },
    { lat: 49.2161093, lng: 19.6071799 },
    { lat: 49.2151283, lng: 19.6074148 },
    { lat: 49.2143979, lng: 19.6075325 },
    { lat: 49.2130984, lng: 19.6076954 },
    { lat: 49.2129718, lng: 19.607714 },
    { lat: 49.212863, lng: 19.6071707 },
    { lat: 49.2127688, lng: 19.6070036 },
    { lat: 49.2126627, lng: 19.6068745 },
    { lat: 49.2124786, lng: 19.6065856 },
    { lat: 49.2123905, lng: 19.6064791 },
    { lat: 49.2121777, lng: 19.6059287 },
    { lat: 49.2124428, lng: 19.6036462 },
    { lat: 49.2123824, lng: 19.6035087 },
    { lat: 49.2122558, lng: 19.60287 },
    { lat: 49.2113354, lng: 19.6015603 },
    { lat: 49.2107565, lng: 19.6004399 },
    { lat: 49.2098965, lng: 19.5995967 },
    { lat: 49.2093607, lng: 19.5998372 },
    { lat: 49.2091066, lng: 19.5994735 },
    { lat: 49.2089191, lng: 19.5991598 },
    { lat: 49.2087085, lng: 19.5981818 },
    { lat: 49.2082762, lng: 19.5978594 },
    { lat: 49.2075357, lng: 19.5968259 },
    { lat: 49.2066222, lng: 19.5963769 },
    { lat: 49.2057044, lng: 19.5959111 },
    { lat: 49.2053302, lng: 19.5959837 },
    { lat: 49.2048438, lng: 19.5958612 },
    { lat: 49.2033017, lng: 19.5963463 },
    { lat: 49.2027854, lng: 19.5963423 },
    { lat: 49.202513, lng: 19.5967754 },
    { lat: 49.2024902, lng: 19.5968188 },
    { lat: 49.2024812, lng: 19.5968453 },
    { lat: 49.202335, lng: 19.5970015 },
    { lat: 49.2021934, lng: 19.5970541 },
    { lat: 49.2017166, lng: 19.5968251 },
    { lat: 49.2002289, lng: 19.5968915 },
    { lat: 49.1999938, lng: 19.5967979 },
    { lat: 49.1991558, lng: 19.5964006 },
    { lat: 49.1989661, lng: 19.5966391 },
    { lat: 49.1988072, lng: 19.5966193 },
    { lat: 49.1987035, lng: 19.5961866 },
    { lat: 49.1980274, lng: 19.5962883 },
    { lat: 49.1976621, lng: 19.5959802 },
    { lat: 49.1971502, lng: 19.5960694 },
    { lat: 49.1968693, lng: 19.5956113 },
    { lat: 49.1961323, lng: 19.5951292 },
    { lat: 49.1954821, lng: 19.5944259 },
    { lat: 49.1947115, lng: 19.5941246 },
    { lat: 49.194445, lng: 19.5937583 },
    { lat: 49.1939887, lng: 19.5935341 },
    { lat: 49.1939192, lng: 19.5934905 },
    { lat: 49.1938431, lng: 19.5934765 },
    { lat: 49.1936625, lng: 19.5935466 },
    { lat: 49.1930302, lng: 19.5926887 },
    { lat: 49.1925623, lng: 19.5925773 },
    { lat: 49.1920906, lng: 19.5926356 },
    { lat: 49.1901522, lng: 19.5907941 },
    { lat: 49.1900078, lng: 19.5898409 },
    { lat: 49.1895068, lng: 19.5892205 },
    { lat: 49.1889132, lng: 19.5888975 },
    { lat: 49.18798, lng: 19.5884102 },
    { lat: 49.1875726, lng: 19.5877468 },
    { lat: 49.1874763, lng: 19.5876571 },
    { lat: 49.1867719, lng: 19.5869028 },
    { lat: 49.1860383, lng: 19.5862981 },
    { lat: 49.1854815, lng: 19.585819 },
    { lat: 49.1844707, lng: 19.584988 },
    { lat: 49.1838444, lng: 19.584862 },
    { lat: 49.1829305, lng: 19.5850621 },
    { lat: 49.1824907, lng: 19.5847757 },
    { lat: 49.1815608, lng: 19.5846919 },
    { lat: 49.1807665, lng: 19.5843915 },
    { lat: 49.1800596, lng: 19.5843334 },
    { lat: 49.1789561, lng: 19.5830433 },
    { lat: 49.1788844, lng: 19.5827504 },
    { lat: 49.1788553, lng: 19.5827535 },
    { lat: 49.1784654, lng: 19.5825527 },
    { lat: 49.1781467, lng: 19.585838 },
    { lat: 49.1775208, lng: 19.5922641 },
    { lat: 49.1768947, lng: 19.5916979 },
    { lat: 49.176362, lng: 19.5912238 },
    { lat: 49.1759565, lng: 19.5910008 },
    { lat: 49.1756523, lng: 19.5907251 },
    { lat: 49.1754759, lng: 19.5906121 },
    { lat: 49.1751678, lng: 19.5902975 },
    { lat: 49.1749945, lng: 19.5899764 },
    { lat: 49.1749047, lng: 19.5898594 },
    { lat: 49.1747912, lng: 19.5898407 },
    { lat: 49.1746703, lng: 19.5901605 },
    { lat: 49.1745824, lng: 19.5905367 },
    { lat: 49.1740317, lng: 19.5910177 },
    { lat: 49.1737819, lng: 19.5919509 },
    { lat: 49.1735995, lng: 19.5924068 },
    { lat: 49.1736614, lng: 19.5925019 },
    { lat: 49.173617, lng: 19.5926703 },
    { lat: 49.1736541, lng: 19.5931436 },
    { lat: 49.1735863, lng: 19.5932158 },
    { lat: 49.1730665, lng: 19.5930278 },
    { lat: 49.1728385, lng: 19.5930711 },
    { lat: 49.1724209, lng: 19.5929776 },
    { lat: 49.1717314, lng: 19.5933319 },
    { lat: 49.1715786, lng: 19.5932636 },
    { lat: 49.1701374, lng: 19.5936339 },
    { lat: 49.1697977, lng: 19.5937767 },
    { lat: 49.1692697, lng: 19.5936075 },
    { lat: 49.1691228, lng: 19.5932747 },
    { lat: 49.1686139, lng: 19.5924533 },
    { lat: 49.168332, lng: 19.5919956 },
    { lat: 49.1681541, lng: 19.5912988 },
    { lat: 49.1678727, lng: 19.5902221 },
    { lat: 49.1677636, lng: 19.5900279 },
    { lat: 49.1681751, lng: 19.5891568 },
    { lat: 49.1681604, lng: 19.5891349 },
    { lat: 49.1679679, lng: 19.5881347 },
    { lat: 49.1675792, lng: 19.587435 },
    { lat: 49.1673063, lng: 19.586952 },
    { lat: 49.1671166, lng: 19.5867126 },
    { lat: 49.1669119, lng: 19.5862249 },
    { lat: 49.1666668, lng: 19.5857839 },
    { lat: 49.1662836, lng: 19.5852325 },
    { lat: 49.1660437, lng: 19.5849 },
    { lat: 49.165993, lng: 19.5848297 },
    { lat: 49.1659895, lng: 19.5848028 },
    { lat: 49.1659577, lng: 19.5847744 },
    { lat: 49.1656458, lng: 19.5844002 },
    { lat: 49.164442, lng: 19.5831124 },
    { lat: 49.1640281, lng: 19.5824569 },
    { lat: 49.1633706, lng: 19.5814122 },
    { lat: 49.1632098, lng: 19.5813437 },
    { lat: 49.163274, lng: 19.5809928 },
    { lat: 49.1631992, lng: 19.5808109 },
    { lat: 49.1632374, lng: 19.5803576 },
    { lat: 49.1633344, lng: 19.5800587 },
    { lat: 49.1631874, lng: 19.5791502 },
    { lat: 49.1632222, lng: 19.5790674 },
    { lat: 49.1631435, lng: 19.5788719 },
    { lat: 49.1630975, lng: 19.57858 },
    { lat: 49.1629866, lng: 19.5783915 },
    { lat: 49.1622554, lng: 19.5776174 },
    { lat: 49.1617894, lng: 19.5769005 },
    { lat: 49.1614874, lng: 19.5764745 },
    { lat: 49.16094, lng: 19.5756287 },
    { lat: 49.1605184, lng: 19.5753535 },
    { lat: 49.1599808, lng: 19.5750506 },
    { lat: 49.1598175, lng: 19.5748262 },
    { lat: 49.1590571, lng: 19.5743036 },
    { lat: 49.1587262, lng: 19.574086 },
    { lat: 49.1582982, lng: 19.5740139 },
    { lat: 49.1582549, lng: 19.5740285 },
    { lat: 49.1575332, lng: 19.5737881 },
    { lat: 49.1570904, lng: 19.5736384 },
    { lat: 49.1568452, lng: 19.5734999 },
    { lat: 49.1563577, lng: 19.5732125 },
    { lat: 49.1559691, lng: 19.5729834 },
    { lat: 49.1556775, lng: 19.5727167 },
    { lat: 49.1555408, lng: 19.5726331 },
    { lat: 49.1549473, lng: 19.5724607 },
    { lat: 49.1546088, lng: 19.5723266 },
    { lat: 49.1541407, lng: 19.5720413 },
    { lat: 49.1536932, lng: 19.5715876 },
    { lat: 49.1530469, lng: 19.5709751 },
    { lat: 49.1523355, lng: 19.5697866 },
    { lat: 49.1520359, lng: 19.5688181 },
    { lat: 49.1518818, lng: 19.5683973 },
    { lat: 49.1520056, lng: 19.5671231 },
    { lat: 49.1516923, lng: 19.5661507 },
    { lat: 49.1513228, lng: 19.5651791 },
    { lat: 49.1512214, lng: 19.5649061 },
    { lat: 49.1506276, lng: 19.5632851 },
    { lat: 49.1520984, lng: 19.5591599 },
    { lat: 49.152084, lng: 19.5566965 },
    { lat: 49.1520756, lng: 19.5554159 },
    { lat: 49.1519082, lng: 19.5555761 },
    { lat: 49.1518703, lng: 19.5555372 },
    { lat: 49.1517967, lng: 19.5555036 },
    { lat: 49.1514554, lng: 19.5550104 },
    { lat: 49.1511952, lng: 19.5548106 },
    { lat: 49.1505009, lng: 19.5544313 },
    { lat: 49.1482125, lng: 19.5535866 },
    { lat: 49.1463033, lng: 19.5521046 },
    { lat: 49.1441845, lng: 19.5516978 },
    { lat: 49.1424642, lng: 19.5517994 },
    { lat: 49.1428657, lng: 19.5507603 },
    { lat: 49.1428338, lng: 19.5507075 },
    { lat: 49.1427962, lng: 19.5506711 },
    { lat: 49.1426594, lng: 19.5503861 },
    { lat: 49.1425026, lng: 19.5501374 },
    { lat: 49.1424332, lng: 19.5499961 },
    { lat: 49.14191, lng: 19.5493911 },
    { lat: 49.1413581, lng: 19.5488483 },
    { lat: 49.1407757, lng: 19.5479067 },
    { lat: 49.1407145, lng: 19.5478015 },
    { lat: 49.1406833, lng: 19.5477804 },
    { lat: 49.1405654, lng: 19.5475437 },
    { lat: 49.14034, lng: 19.5472433 },
    { lat: 49.1403174, lng: 19.547192 },
    { lat: 49.1401359, lng: 19.5469398 },
    { lat: 49.1398124, lng: 19.5465438 },
    { lat: 49.1397352, lng: 19.5464286 },
    { lat: 49.1394914, lng: 19.5458216 },
    { lat: 49.1393901, lng: 19.545603 },
    { lat: 49.13934, lng: 19.545441 },
    { lat: 49.1391646, lng: 19.5450046 },
    { lat: 49.1391121, lng: 19.5448612 },
    { lat: 49.1390427, lng: 19.5447263 },
    { lat: 49.1388925, lng: 19.5443924 },
    { lat: 49.1388262, lng: 19.544227 },
    { lat: 49.1387719, lng: 19.5441446 },
    { lat: 49.1387636, lng: 19.5441127 },
    { lat: 49.1387072, lng: 19.5440348 },
    { lat: 49.1386249, lng: 19.543834 },
    { lat: 49.1386053, lng: 19.5438037 },
    { lat: 49.1385679, lng: 19.5437706 },
    { lat: 49.138537, lng: 19.5437134 },
    { lat: 49.1384744, lng: 19.543671 },
    { lat: 49.1383744, lng: 19.543506 },
    { lat: 49.1383088, lng: 19.5433524 },
    { lat: 49.1382769, lng: 19.5433208 },
    { lat: 49.1381096, lng: 19.5429315 },
    { lat: 49.1380409, lng: 19.5427347 },
    { lat: 49.1379992, lng: 19.5426628 },
    { lat: 49.1379598, lng: 19.5424622 },
    { lat: 49.1379133, lng: 19.5423113 },
    { lat: 49.1377943, lng: 19.5420723 },
    { lat: 49.13777, lng: 19.5420051 },
    { lat: 49.1376541, lng: 19.5417614 },
    { lat: 49.1375899, lng: 19.5416113 },
    { lat: 49.1375652, lng: 19.541045 },
    { lat: 49.1375295, lng: 19.5410023 },
    { lat: 49.1370128, lng: 19.5409384 },
    { lat: 49.1365537, lng: 19.540853 },
    { lat: 49.1360895, lng: 19.5407548 },
    { lat: 49.1358352, lng: 19.5407122 },
    { lat: 49.1354455, lng: 19.5406676 },
    { lat: 49.135241, lng: 19.5406763 },
    { lat: 49.1352487, lng: 19.540624 },
    { lat: 49.1352397, lng: 19.5404979 },
    { lat: 49.1352596, lng: 19.5404167 },
    { lat: 49.1352757, lng: 19.5402502 },
    { lat: 49.1352526, lng: 19.5401169 },
    { lat: 49.1346444, lng: 19.5377623 },
    { lat: 49.1345574, lng: 19.5374858 },
    { lat: 49.1344498, lng: 19.5372722 },
    { lat: 49.1342546, lng: 19.5370467 },
    { lat: 49.1328202, lng: 19.5358035 },
    { lat: 49.1319947, lng: 19.5354189 },
    { lat: 49.1317688, lng: 19.5352589 },
    { lat: 49.1311009, lng: 19.534544 },
    { lat: 49.1309726, lng: 19.5343492 },
    { lat: 49.1308109, lng: 19.5339385 },
    { lat: 49.1307396, lng: 19.5340068 },
    { lat: 49.1306497, lng: 19.5340397 },
    { lat: 49.1306404, lng: 19.5339806 },
    { lat: 49.1306034, lng: 19.53391 },
    { lat: 49.1305182, lng: 19.5338726 },
    { lat: 49.1304687, lng: 19.5338753 },
    { lat: 49.1304494, lng: 19.5338705 },
    { lat: 49.1304401, lng: 19.5338114 },
    { lat: 49.1303921, lng: 19.5337492 },
    { lat: 49.1303588, lng: 19.533739 },
    { lat: 49.1303375, lng: 19.5337618 },
    { lat: 49.1303201, lng: 19.5337546 },
    { lat: 49.1302512, lng: 19.5336971 },
    { lat: 49.1302256, lng: 19.5337067 },
    { lat: 49.130118, lng: 19.5335925 },
    { lat: 49.1300356, lng: 19.5335943 },
    { lat: 49.1299838, lng: 19.5335653 },
    { lat: 49.1299283, lng: 19.5335132 },
    { lat: 49.1298601, lng: 19.5334718 },
    { lat: 49.1298343, lng: 19.5334767 },
    { lat: 49.1298217, lng: 19.533469 },
    { lat: 49.1298179, lng: 19.5334367 },
    { lat: 49.1297926, lng: 19.5334254 },
    { lat: 49.1297795, lng: 19.5333875 },
    { lat: 49.1296836, lng: 19.5333602 },
    { lat: 49.1296347, lng: 19.533396 },
    { lat: 49.1295525, lng: 19.5333744 },
    { lat: 49.1294951, lng: 19.5333272 },
    { lat: 49.1294437, lng: 19.5332348 },
    { lat: 49.1293749, lng: 19.5332086 },
    { lat: 49.1293019, lng: 19.5332376 },
    { lat: 49.1292365, lng: 19.5332121 },
    { lat: 49.1291962, lng: 19.5332458 },
    { lat: 49.1291751, lng: 19.5332006 },
    { lat: 49.1291475, lng: 19.5332377 },
    { lat: 49.1291149, lng: 19.5332422 },
    { lat: 49.1287758, lng: 19.5331704 },
    { lat: 49.1287604, lng: 19.5331526 },
    { lat: 49.1286089, lng: 19.5331129 },
    { lat: 49.1285749, lng: 19.5330873 },
    { lat: 49.1284989, lng: 19.5330766 },
    { lat: 49.1284909, lng: 19.5330983 },
    { lat: 49.1284961, lng: 19.5331608 },
    { lat: 49.1284744, lng: 19.5332023 },
    { lat: 49.1284451, lng: 19.5332277 },
    { lat: 49.1283999, lng: 19.5332004 },
    { lat: 49.1283886, lng: 19.5331548 },
    { lat: 49.1283255, lng: 19.533127 },
    { lat: 49.1282976, lng: 19.5330846 },
    { lat: 49.1282663, lng: 19.5330663 },
    { lat: 49.1281849, lng: 19.5330573 },
    { lat: 49.1281071, lng: 19.5331069 },
    { lat: 49.1280735, lng: 19.5330845 },
    { lat: 49.1280461, lng: 19.5331245 },
    { lat: 49.1279448, lng: 19.5331294 },
    { lat: 49.1278681, lng: 19.5330785 },
    { lat: 49.12778, lng: 19.5331245 },
    { lat: 49.1277631, lng: 19.5331216 },
    { lat: 49.1277744, lng: 19.5331481 },
    { lat: 49.1277602, lng: 19.5331565 },
    { lat: 49.1277458, lng: 19.5331418 },
    { lat: 49.1277201, lng: 19.533142 },
    { lat: 49.1277021, lng: 19.5331716 },
    { lat: 49.1276225, lng: 19.5331044 },
    { lat: 49.1275613, lng: 19.5331246 },
    { lat: 49.127552, lng: 19.5331162 },
    { lat: 49.1275398, lng: 19.5331711 },
    { lat: 49.1274983, lng: 19.5331705 },
    { lat: 49.1274921, lng: 19.5331337 },
    { lat: 49.1274774, lng: 19.5331119 },
    { lat: 49.1274746, lng: 19.533051 },
    { lat: 49.1274381, lng: 19.5330876 },
    { lat: 49.1273629, lng: 19.5330714 },
    { lat: 49.1273147, lng: 19.5331744 },
    { lat: 49.1272856, lng: 19.5331303 },
    { lat: 49.1272705, lng: 19.5331413 },
    { lat: 49.1272394, lng: 19.5331068 },
    { lat: 49.1271422, lng: 19.5331955 },
    { lat: 49.1270978, lng: 19.533139 },
    { lat: 49.1269585, lng: 19.533071 },
    { lat: 49.1269323, lng: 19.5330921 },
    { lat: 49.1269005, lng: 19.5330832 },
    { lat: 49.1268889, lng: 19.5331031 },
    { lat: 49.1268705, lng: 19.5330936 },
    { lat: 49.1268484, lng: 19.5331279 },
    { lat: 49.1268252, lng: 19.5330904 },
    { lat: 49.1267985, lng: 19.5330933 },
    { lat: 49.1267733, lng: 19.5330117 },
    { lat: 49.1267389, lng: 19.5329776 },
    { lat: 49.1267236, lng: 19.5330094 },
    { lat: 49.1267294, lng: 19.533039 },
    { lat: 49.1266926, lng: 19.5330706 },
    { lat: 49.126666, lng: 19.5330313 },
    { lat: 49.1266206, lng: 19.5330243 },
    { lat: 49.1266041, lng: 19.533035 },
    { lat: 49.1265916, lng: 19.5330036 },
    { lat: 49.1265718, lng: 19.5330406 },
    { lat: 49.1265597, lng: 19.5329738 },
    { lat: 49.1265261, lng: 19.532977 },
    { lat: 49.126483, lng: 19.5329485 },
    { lat: 49.1264428, lng: 19.5330038 },
    { lat: 49.1264238, lng: 19.5329591 },
    { lat: 49.1263675, lng: 19.532983 },
    { lat: 49.1263546, lng: 19.5329236 },
    { lat: 49.1262671, lng: 19.5329342 },
    { lat: 49.1261728, lng: 19.532892 },
    { lat: 49.1261481, lng: 19.532822 },
    { lat: 49.1261089, lng: 19.5328258 },
    { lat: 49.1260855, lng: 19.5328045 },
    { lat: 49.1260576, lng: 19.5328092 },
    { lat: 49.1260457, lng: 19.5328227 },
    { lat: 49.1260283, lng: 19.5328076 },
    { lat: 49.1260362, lng: 19.5327859 },
    { lat: 49.1260052, lng: 19.5327471 },
    { lat: 49.1259838, lng: 19.5327487 },
    { lat: 49.1259523, lng: 19.5327264 },
    { lat: 49.125908, lng: 19.5327444 },
    { lat: 49.1258944, lng: 19.5327153 },
    { lat: 49.1258362, lng: 19.5326768 },
    { lat: 49.1257912, lng: 19.5326787 },
    { lat: 49.1257699, lng: 19.5326295 },
    { lat: 49.1257378, lng: 19.5326397 },
    { lat: 49.1257324, lng: 19.5325957 },
    { lat: 49.1257173, lng: 19.532611 },
    { lat: 49.1257023, lng: 19.532582 },
    { lat: 49.125677, lng: 19.5325915 },
    { lat: 49.1256369, lng: 19.5325554 },
    { lat: 49.1256139, lng: 19.5325694 },
    { lat: 49.1255387, lng: 19.5325255 },
    { lat: 49.1255184, lng: 19.5324992 },
    { lat: 49.1254961, lng: 19.5325084 },
    { lat: 49.1254755, lng: 19.5324753 },
    { lat: 49.1254374, lng: 19.532479 },
    { lat: 49.1254118, lng: 19.5324346 },
    { lat: 49.1253827, lng: 19.5324376 },
    { lat: 49.1253665, lng: 19.5324296 },
    { lat: 49.1253577, lng: 19.5324072 },
    { lat: 49.1253077, lng: 19.5323981 },
    { lat: 49.1253053, lng: 19.5323724 },
    { lat: 49.125265, lng: 19.5323788 },
    { lat: 49.1252431, lng: 19.5323901 },
    { lat: 49.1252249, lng: 19.5323381 },
    { lat: 49.1251591, lng: 19.5323303 },
    { lat: 49.1251465, lng: 19.5323011 },
    { lat: 49.12511, lng: 19.5323139 },
    { lat: 49.1250313, lng: 19.5322704 },
    { lat: 49.125, lng: 19.5322266 },
    { lat: 49.1249506, lng: 19.5322314 },
    { lat: 49.124922, lng: 19.5322197 },
    { lat: 49.1249088, lng: 19.5322049 },
    { lat: 49.1248886, lng: 19.5322024 },
    { lat: 49.1248682, lng: 19.5321524 },
    { lat: 49.1247838, lng: 19.5321303 },
    { lat: 49.1247738, lng: 19.532108 },
    { lat: 49.1247503, lng: 19.5321101 },
    { lat: 49.1246586, lng: 19.5320767 },
    { lat: 49.1246334, lng: 19.5320419 },
    { lat: 49.1244233, lng: 19.531959 },
    { lat: 49.1243992, lng: 19.5319753 },
    { lat: 49.1243678, lng: 19.5319484 },
    { lat: 49.1243439, lng: 19.5318966 },
    { lat: 49.1242405, lng: 19.5318553 },
    { lat: 49.12421, lng: 19.5318744 },
    { lat: 49.1241915, lng: 19.5318411 },
    { lat: 49.1241304, lng: 19.531786 },
    { lat: 49.1240326, lng: 19.5317417 },
    { lat: 49.1240145, lng: 19.531713 },
    { lat: 49.123968, lng: 19.5317082 },
    { lat: 49.1239565, lng: 19.5316788 },
    { lat: 49.1238181, lng: 19.5316312 },
    { lat: 49.1237442, lng: 19.5315681 },
    { lat: 49.1233363, lng: 19.5313429 },
    { lat: 49.1232555, lng: 19.5313269 },
    { lat: 49.1231271, lng: 19.5313279 },
    { lat: 49.1230939, lng: 19.5312936 },
    { lat: 49.1230591, lng: 19.5313225 },
    { lat: 49.1230442, lng: 19.5313169 },
    { lat: 49.1230285, lng: 19.5312671 },
    { lat: 49.1229466, lng: 19.5312981 },
    { lat: 49.122819, lng: 19.531245 },
    { lat: 49.1227879, lng: 19.5312058 },
    { lat: 49.122735, lng: 19.531178 },
    { lat: 49.1226209, lng: 19.5310745 },
    { lat: 49.1223596, lng: 19.5308791 },
    { lat: 49.1223231, lng: 19.5308171 },
    { lat: 49.1223333, lng: 19.5307718 },
    { lat: 49.1223532, lng: 19.5307629 },
    { lat: 49.1223649, lng: 19.5307497 },
    { lat: 49.1223379, lng: 19.5307224 },
    { lat: 49.1223185, lng: 19.5307172 },
    { lat: 49.1222973, lng: 19.5306767 },
    { lat: 49.1221973, lng: 19.5306793 },
    { lat: 49.1221951, lng: 19.5306306 },
    { lat: 49.1221764, lng: 19.5306182 },
    { lat: 49.1221414, lng: 19.5306169 },
    { lat: 49.122124, lng: 19.5306561 },
    { lat: 49.1221117, lng: 19.5306549 },
    { lat: 49.1220801, lng: 19.5305784 },
    { lat: 49.1220927, lng: 19.5305605 },
    { lat: 49.1221059, lng: 19.5305803 },
    { lat: 49.1221109, lng: 19.5305661 },
    { lat: 49.1221016, lng: 19.530506 },
    { lat: 49.1220156, lng: 19.5304744 },
    { lat: 49.121999, lng: 19.5304571 },
    { lat: 49.1219663, lng: 19.5304066 },
    { lat: 49.1219421, lng: 19.530348 },
    { lat: 49.1219006, lng: 19.5303495 },
    { lat: 49.1218851, lng: 19.5303299 },
    { lat: 49.1216992, lng: 19.5300128 },
    { lat: 49.1216721, lng: 19.5299829 },
    { lat: 49.1216833, lng: 19.5299328 },
    { lat: 49.1216644, lng: 19.5299158 },
    { lat: 49.1216239, lng: 19.5298938 },
    { lat: 49.1216273, lng: 19.5298632 },
    { lat: 49.1215412, lng: 19.5297094 },
    { lat: 49.1215301, lng: 19.5297105 },
    { lat: 49.1214984, lng: 19.5296106 },
    { lat: 49.1214744, lng: 19.5296017 },
    { lat: 49.1214308, lng: 19.529458 },
    { lat: 49.1213224, lng: 19.5292342 },
    { lat: 49.1213046, lng: 19.529166 },
    { lat: 49.1212891, lng: 19.529146 },
    { lat: 49.1212601, lng: 19.529053 },
    { lat: 49.1212292, lng: 19.5290185 },
    { lat: 49.1212075, lng: 19.5289618 },
    { lat: 49.1211906, lng: 19.5289381 },
    { lat: 49.1211873, lng: 19.5289122 },
    { lat: 49.1210713, lng: 19.5286938 },
    { lat: 49.1210428, lng: 19.5286123 },
    { lat: 49.1209946, lng: 19.5285444 },
    { lat: 49.1209683, lng: 19.5284579 },
    { lat: 49.1209281, lng: 19.5284147 },
    { lat: 49.1208619, lng: 19.5282735 },
    { lat: 49.1207747, lng: 19.5281158 },
    { lat: 49.1207124, lng: 19.528035 },
    { lat: 49.1206904, lng: 19.5279714 },
    { lat: 49.1206522, lng: 19.5279514 },
    { lat: 49.1206419, lng: 19.5278964 },
    { lat: 49.1205466, lng: 19.5278097 },
    { lat: 49.1205237, lng: 19.5277531 },
    { lat: 49.1204953, lng: 19.5277205 },
    { lat: 49.120418, lng: 19.5275848 },
    { lat: 49.1203675, lng: 19.5275897 },
    { lat: 49.1203066, lng: 19.5274854 },
    { lat: 49.1202874, lng: 19.5274849 },
    { lat: 49.1202721, lng: 19.52747 },
    { lat: 49.1202613, lng: 19.5274358 },
    { lat: 49.1201959, lng: 19.5274093 },
    { lat: 49.1201443, lng: 19.5273392 },
    { lat: 49.1200919, lng: 19.527325 },
    { lat: 49.1200568, lng: 19.5272747 },
    { lat: 49.1200227, lng: 19.5272661 },
    { lat: 49.119905, lng: 19.5271555 },
    { lat: 49.1198882, lng: 19.5271594 },
    { lat: 49.1196991, lng: 19.5270154 },
    { lat: 49.1196384, lng: 19.526993 },
    { lat: 49.1196162, lng: 19.526927 },
    { lat: 49.1195813, lng: 19.5269538 },
    { lat: 49.1195416, lng: 19.5269479 },
    { lat: 49.1195231, lng: 19.5269099 },
    { lat: 49.1194818, lng: 19.5268931 },
    { lat: 49.1194496, lng: 19.5268558 },
    { lat: 49.1193974, lng: 19.5268444 },
    { lat: 49.119328, lng: 19.5267572 },
    { lat: 49.1192818, lng: 19.5267333 },
    { lat: 49.1190572, lng: 19.5265764 },
    { lat: 49.1187807, lng: 19.5264402 },
    { lat: 49.1187344, lng: 19.5263879 },
    { lat: 49.1186801, lng: 19.5263897 },
    { lat: 49.1185979, lng: 19.5262642 },
    { lat: 49.1185675, lng: 19.5262642 },
    { lat: 49.1185445, lng: 19.526231 },
    { lat: 49.1185365, lng: 19.5261761 },
    { lat: 49.1184382, lng: 19.5261491 },
    { lat: 49.118371, lng: 19.526108 },
    { lat: 49.1183013, lng: 19.5260344 },
    { lat: 49.1182539, lng: 19.5260358 },
    { lat: 49.1182068, lng: 19.5259861 },
    { lat: 49.1181634, lng: 19.5259785 },
    { lat: 49.1181202, lng: 19.525942 },
    { lat: 49.1180899, lng: 19.5259445 },
    { lat: 49.1180574, lng: 19.5259008 },
    { lat: 49.1179353, lng: 19.5258684 },
    { lat: 49.1178126, lng: 19.5257969 },
    { lat: 49.1177777, lng: 19.525793 },
    { lat: 49.1176666, lng: 19.5257337 },
    { lat: 49.1176332, lng: 19.5257158 },
    { lat: 49.1175384, lng: 19.5257395 },
    { lat: 49.1174946, lng: 19.525715 },
    { lat: 49.1174348, lng: 19.5257616 },
    { lat: 49.117349, lng: 19.5257851 },
    { lat: 49.1173151, lng: 19.5257829 },
    { lat: 49.1172963, lng: 19.5258403 },
    { lat: 49.1172787, lng: 19.5258745 },
    { lat: 49.1172443, lng: 19.5258861 },
    { lat: 49.1171553, lng: 19.5258351 },
    { lat: 49.1171054, lng: 19.5258274 },
    { lat: 49.1170741, lng: 19.5257627 },
    { lat: 49.1170087, lng: 19.5257376 },
    { lat: 49.1169954, lng: 19.5256897 },
    { lat: 49.1169524, lng: 19.5256889 },
    { lat: 49.1168167, lng: 19.5258252 },
    { lat: 49.116645, lng: 19.5257923 },
    { lat: 49.1166374, lng: 19.525797 },
    { lat: 49.1164607, lng: 19.5257995 },
    { lat: 49.1163863, lng: 19.5258498 },
    { lat: 49.1162676, lng: 19.5258153 },
    { lat: 49.1161968, lng: 19.525744 },
    { lat: 49.115942, lng: 19.5256454 },
    { lat: 49.1158598, lng: 19.5256451 },
    { lat: 49.1157525, lng: 19.5256165 },
    { lat: 49.1157244, lng: 19.5255652 },
    { lat: 49.1157375, lng: 19.5255088 },
    { lat: 49.1157328, lng: 19.5254531 },
    { lat: 49.1156962, lng: 19.5253908 },
    { lat: 49.1156431, lng: 19.5254115 },
    { lat: 49.1156362, lng: 19.5253375 },
    { lat: 49.1154342, lng: 19.5252326 },
    { lat: 49.1154315, lng: 19.5252724 },
    { lat: 49.1153618, lng: 19.5252755 },
    { lat: 49.1152193, lng: 19.5251125 },
    { lat: 49.1151057, lng: 19.5250957 },
    { lat: 49.115065, lng: 19.5250687 },
    { lat: 49.1150613, lng: 19.5249875 },
    { lat: 49.1149548, lng: 19.5248009 },
    { lat: 49.1149746, lng: 19.5247157 },
    { lat: 49.1148669, lng: 19.524452 },
    { lat: 49.114715, lng: 19.5242849 },
    { lat: 49.1146626, lng: 19.5241495 },
    { lat: 49.1146251, lng: 19.5240153 },
    { lat: 49.1145974, lng: 19.5238528 },
    { lat: 49.1145533, lng: 19.5236794 },
    { lat: 49.1145288, lng: 19.5236345 },
    { lat: 49.1144626, lng: 19.523321 },
    { lat: 49.1144529, lng: 19.5233311 },
    { lat: 49.1144338, lng: 19.5233306 },
    { lat: 49.1135346, lng: 19.5237501 },
    { lat: 49.1134043, lng: 19.5238067 },
    { lat: 49.1125581, lng: 19.5242063 },
    { lat: 49.1120228, lng: 19.5244481 },
    { lat: 49.1115266, lng: 19.5246642 },
    { lat: 49.1112446, lng: 19.5247818 },
    { lat: 49.1108806, lng: 19.524903 },
    { lat: 49.1103393, lng: 19.5250391 },
    { lat: 49.1098738, lng: 19.5250361 },
    { lat: 49.1094731, lng: 19.5249947 },
    { lat: 49.109078, lng: 19.5249577 },
    { lat: 49.1088458, lng: 19.5249542 },
    { lat: 49.1082584, lng: 19.5248725 },
    { lat: 49.1079963, lng: 19.5247596 },
    { lat: 49.107392, lng: 19.5246019 },
    { lat: 49.1069184, lng: 19.5245197 },
    { lat: 49.10662, lng: 19.5245264 },
    { lat: 49.1063164, lng: 19.524538 },
    { lat: 49.1059343, lng: 19.524536 },
    { lat: 49.1049726, lng: 19.5245251 },
    { lat: 49.1042789, lng: 19.5266598 },
    { lat: 49.1041758, lng: 19.5270404 },
    { lat: 49.1034971, lng: 19.5295812 },
    { lat: 49.1029495, lng: 19.5316852 },
    { lat: 49.1024876, lng: 19.5328725 },
    { lat: 49.1021238, lng: 19.5337441 },
    { lat: 49.1013944, lng: 19.5348499 },
    { lat: 49.101663, lng: 19.5360467 },
    { lat: 49.1012943, lng: 19.537206 },
    { lat: 49.1011343, lng: 19.5384955 },
    { lat: 49.1008524, lng: 19.538937 },
    { lat: 49.1005249, lng: 19.5392185 },
    { lat: 49.1000493, lng: 19.538921 },
    { lat: 49.0991303, lng: 19.5395741 },
    { lat: 49.0986098, lng: 19.5402662 },
    { lat: 49.0985081, lng: 19.5405847 },
    { lat: 49.0984844, lng: 19.5407799 },
    { lat: 49.0984908, lng: 19.541342 },
    { lat: 49.098506, lng: 19.5417284 },
    { lat: 49.0986013, lng: 19.5420635 },
    { lat: 49.0983844, lng: 19.5422751 },
    { lat: 49.0982514, lng: 19.5423517 },
    { lat: 49.0981969, lng: 19.5425656 },
    { lat: 49.0981643, lng: 19.5427622 },
    { lat: 49.0982234, lng: 19.5430436 },
    { lat: 49.0986173, lng: 19.5429888 },
    { lat: 49.0987908, lng: 19.5432366 },
    { lat: 49.0990405, lng: 19.5437227 },
    { lat: 49.0992134, lng: 19.5436829 },
    { lat: 49.0998261, lng: 19.543678 },
    { lat: 49.1007065, lng: 19.5437863 },
    { lat: 49.1010608, lng: 19.5438924 },
    { lat: 49.1020628, lng: 19.5441983 },
    { lat: 49.1024846, lng: 19.5443315 },
    { lat: 49.10288, lng: 19.5444531 },
    { lat: 49.1031991, lng: 19.5445342 },
    { lat: 49.1036125, lng: 19.5446586 },
    { lat: 49.1041511, lng: 19.5449032 },
    { lat: 49.1044473, lng: 19.5450511 },
    { lat: 49.1048267, lng: 19.5452179 },
    { lat: 49.1051507, lng: 19.5454776 },
    { lat: 49.1051313, lng: 19.5455976 },
    { lat: 49.1050953, lng: 19.5459948 },
    { lat: 49.1052843, lng: 19.5461115 },
    { lat: 49.1053858, lng: 19.546017 },
    { lat: 49.1060229, lng: 19.5462013 },
    { lat: 49.1063821, lng: 19.5462969 },
    { lat: 49.1066772, lng: 19.5464421 },
    { lat: 49.1069143, lng: 19.5465527 },
    { lat: 49.107148, lng: 19.5466863 },
    { lat: 49.1070677, lng: 19.5467793 },
    { lat: 49.10718, lng: 19.5468703 },
    { lat: 49.1076853, lng: 19.5474285 },
    { lat: 49.1079363, lng: 19.5476933 },
    { lat: 49.108115, lng: 19.5481043 },
    { lat: 49.1082214, lng: 19.5486912 },
    { lat: 49.1083545, lng: 19.5494389 },
    { lat: 49.1085537, lng: 19.5502012 },
    { lat: 49.1089602, lng: 19.5517358 },
    { lat: 49.109267, lng: 19.5527641 },
    { lat: 49.1092966, lng: 19.5528171 },
    { lat: 49.1097447, lng: 19.5532609 },
    { lat: 49.1100937, lng: 19.5536522 },
    { lat: 49.1101544, lng: 19.5541198 },
    { lat: 49.1101629, lng: 19.5545329 },
    { lat: 49.110157, lng: 19.5546004 },
    { lat: 49.1100608, lng: 19.55541 },
    { lat: 49.1099747, lng: 19.5561682 },
    { lat: 49.1104512, lng: 19.5562929 },
    { lat: 49.1111636, lng: 19.5564727 },
    { lat: 49.1113769, lng: 19.5565067 },
    { lat: 49.1119337, lng: 19.5566122 },
    { lat: 49.1121939, lng: 19.5566334 },
    { lat: 49.1125465, lng: 19.5566283 },
    { lat: 49.1130016, lng: 19.556605 },
    { lat: 49.11339, lng: 19.5565958 },
    { lat: 49.1140735, lng: 19.556589 },
    { lat: 49.1142646, lng: 19.5565758 },
    { lat: 49.1146319, lng: 19.5569228 },
    { lat: 49.1149366, lng: 19.5572605 },
    { lat: 49.1153935, lng: 19.5578717 },
    { lat: 49.1158142, lng: 19.5584005 },
    { lat: 49.1160028, lng: 19.5585821 },
    { lat: 49.1162519, lng: 19.5588117 },
    { lat: 49.1164606, lng: 19.5589901 },
    { lat: 49.1177241, lng: 19.5598465 },
    { lat: 49.1179823, lng: 19.5600489 },
    { lat: 49.1188917, lng: 19.5607775 },
    { lat: 49.1192376, lng: 19.5610998 },
    { lat: 49.1195178, lng: 19.5612862 },
    { lat: 49.1195539, lng: 19.5613169 },
    { lat: 49.1203613, lng: 19.5617544 },
    { lat: 49.1221095, lng: 19.5625924 },
    { lat: 49.1232046, lng: 19.5632347 },
    { lat: 49.124512, lng: 19.564218 },
    { lat: 49.1245725, lng: 19.5641354 },
    { lat: 49.1249763, lng: 19.5635213 },
    { lat: 49.125277, lng: 19.563466 },
    { lat: 49.1259908, lng: 19.5654262 },
    { lat: 49.126268, lng: 19.5661746 },
    { lat: 49.1264712, lng: 19.566731 },
    { lat: 49.1270163, lng: 19.5681981 },
    { lat: 49.1271993, lng: 19.5686794 },
    { lat: 49.1278277, lng: 19.5695971 },
    { lat: 49.1285931, lng: 19.570703 },
    { lat: 49.1292486, lng: 19.5716682 },
    { lat: 49.1293473, lng: 19.5715827 },
    { lat: 49.1293627, lng: 19.5715009 },
    { lat: 49.1293899, lng: 19.5714818 },
    { lat: 49.1294767, lng: 19.5715792 },
    { lat: 49.1295308, lng: 19.5717563 },
    { lat: 49.1295711, lng: 19.5719237 },
    { lat: 49.1296385, lng: 19.5720195 },
    { lat: 49.1297298, lng: 19.5721956 },
    { lat: 49.1297552, lng: 19.5722832 },
    { lat: 49.1298356, lng: 19.5724421 },
    { lat: 49.1299216, lng: 19.5726997 },
    { lat: 49.1300909, lng: 19.5729565 },
    { lat: 49.1301495, lng: 19.5730047 },
    { lat: 49.1302549, lng: 19.5729972 },
    { lat: 49.1302587, lng: 19.5730555 },
    { lat: 49.13023, lng: 19.5731934 },
    { lat: 49.1302379, lng: 19.5733412 },
    { lat: 49.1302843, lng: 19.5734485 },
    { lat: 49.1303683, lng: 19.5735113 },
    { lat: 49.1303936, lng: 19.5735943 },
    { lat: 49.1304, lng: 19.5737149 },
    { lat: 49.1304761, lng: 19.5737544 },
    { lat: 49.1306064, lng: 19.5739671 },
    { lat: 49.1306686, lng: 19.5739501 },
    { lat: 49.1307125, lng: 19.5740743 },
    { lat: 49.1307022, lng: 19.5742434 },
    { lat: 49.1308198, lng: 19.5742293 },
    { lat: 49.1308778, lng: 19.5744157 },
    { lat: 49.1308635, lng: 19.5744697 },
    { lat: 49.1308855, lng: 19.5745128 },
    { lat: 49.1309203, lng: 19.5744836 },
    { lat: 49.1310371, lng: 19.5745498 },
    { lat: 49.1310665, lng: 19.5746251 },
    { lat: 49.1311687, lng: 19.5746936 },
    { lat: 49.1311423, lng: 19.5748359 },
    { lat: 49.1311859, lng: 19.5749026 },
    { lat: 49.1312553, lng: 19.5749637 },
    { lat: 49.1313601, lng: 19.5749646 },
    { lat: 49.1314533, lng: 19.575035 },
    { lat: 49.1314648, lng: 19.5751116 },
    { lat: 49.13151, lng: 19.5751417 },
    { lat: 49.1315389, lng: 19.5751862 },
    { lat: 49.1316208, lng: 19.5751276 },
    { lat: 49.1316949, lng: 19.5751939 },
    { lat: 49.1317746, lng: 19.5751874 },
    { lat: 49.1318691, lng: 19.5753085 },
    { lat: 49.1318784, lng: 19.5753899 },
    { lat: 49.1319151, lng: 19.575381 },
    { lat: 49.1319384, lng: 19.5754469 },
    { lat: 49.1319857, lng: 19.5755211 },
    { lat: 49.1320906, lng: 19.5755763 },
    { lat: 49.1322033, lng: 19.5755724 },
    { lat: 49.1322891, lng: 19.57568 },
    { lat: 49.1323802, lng: 19.5755748 },
    { lat: 49.1324238, lng: 19.575568 },
    { lat: 49.1324644, lng: 19.5756451 },
    { lat: 49.1324949, lng: 19.5756459 },
    { lat: 49.1326271, lng: 19.5757025 },
    { lat: 49.1327692, lng: 19.5757085 },
    { lat: 49.132809, lng: 19.5757623 },
    { lat: 49.1330933, lng: 19.5758217 },
    { lat: 49.1331067, lng: 19.5758462 },
    { lat: 49.1331108, lng: 19.5758584 },
    { lat: 49.1332858, lng: 19.5758447 },
    { lat: 49.1336186, lng: 19.5758012 },
    { lat: 49.133671, lng: 19.5758619 },
    { lat: 49.1339615, lng: 19.5757138 },
    { lat: 49.1341498, lng: 19.5757369 },
    { lat: 49.1342395, lng: 19.5756325 },
    { lat: 49.1342542, lng: 19.5755277 },
    { lat: 49.134449, lng: 19.5755559 },
    { lat: 49.1344869, lng: 19.5754213 },
    { lat: 49.1345957, lng: 19.5753588 },
    { lat: 49.1346841, lng: 19.5753715 },
    { lat: 49.1348026, lng: 19.5752979 },
    { lat: 49.1348595, lng: 19.5753394 },
    { lat: 49.1349474, lng: 19.5753615 },
    { lat: 49.1349669, lng: 19.5752965 },
    { lat: 49.1350012, lng: 19.5752569 },
    { lat: 49.1352763, lng: 19.575462 },
    { lat: 49.1354617, lng: 19.5756498 },
    { lat: 49.1355801, lng: 19.5757 },
    { lat: 49.1356114, lng: 19.575818 },
    { lat: 49.1357099, lng: 19.5758563 },
    { lat: 49.1357544, lng: 19.5758426 },
    { lat: 49.1359149, lng: 19.5759507 },
    { lat: 49.1359187, lng: 19.5761413 },
    { lat: 49.1359909, lng: 19.5761669 },
    { lat: 49.1360554, lng: 19.5762986 },
    { lat: 49.1360759, lng: 19.5764271 },
    { lat: 49.1360893, lng: 19.5764476 },
    { lat: 49.1361737, lng: 19.5765241 },
    { lat: 49.1362539, lng: 19.5766524 },
    { lat: 49.1363302, lng: 19.5766944 },
    { lat: 49.1363553, lng: 19.5766486 },
    { lat: 49.1365487, lng: 19.5761887 },
    { lat: 49.137355, lng: 19.5742586 },
    { lat: 49.1376452, lng: 19.5735743 },
    { lat: 49.1378422, lng: 19.5730949 },
    { lat: 49.1379528, lng: 19.5728979 },
    { lat: 49.1381087, lng: 19.5726333 },
    { lat: 49.1381418, lng: 19.5725935 },
    { lat: 49.138386, lng: 19.5725153 },
    { lat: 49.1385343, lng: 19.5723551 },
    { lat: 49.1385809, lng: 19.5723409 },
    { lat: 49.138644, lng: 19.57229 },
    { lat: 49.138809, lng: 19.572326 },
    { lat: 49.1389425, lng: 19.5720338 },
    { lat: 49.1389701, lng: 19.5719985 },
    { lat: 49.1390669, lng: 19.5717987 },
    { lat: 49.1391132, lng: 19.5715002 },
    { lat: 49.1391571, lng: 19.5713027 },
    { lat: 49.1391982, lng: 19.5712876 },
    { lat: 49.1399836, lng: 19.5707148 },
    { lat: 49.1401285, lng: 19.5704822 },
    { lat: 49.1404138, lng: 19.5701604 },
    { lat: 49.1410658, lng: 19.5694061 },
    { lat: 49.1413107, lng: 19.5691674 },
    { lat: 49.1413233, lng: 19.5691423 },
    { lat: 49.1413446, lng: 19.5691178 },
    { lat: 49.1417049, lng: 19.5688871 },
    { lat: 49.1421558, lng: 19.5685418 },
    { lat: 49.1425897, lng: 19.5685711 },
    { lat: 49.1430975, lng: 19.5686844 },
    { lat: 49.1431381, lng: 19.5686902 },
    { lat: 49.1435668, lng: 19.569301 },
    { lat: 49.1437521, lng: 19.5696346 },
    { lat: 49.1439822, lng: 19.5700401 },
    { lat: 49.144262, lng: 19.5705481 },
    { lat: 49.1445331, lng: 19.5707845 },
    { lat: 49.1448304, lng: 19.5710315 },
    { lat: 49.1456872, lng: 19.5713185 },
    { lat: 49.1465015, lng: 19.5715851 },
    { lat: 49.1467984, lng: 19.5718228 },
    { lat: 49.1471292, lng: 19.572212 },
    { lat: 49.1475672, lng: 19.5725314 },
    { lat: 49.1479334, lng: 19.5727305 },
    { lat: 49.1484111, lng: 19.5730033 },
    { lat: 49.1488651, lng: 19.5734531 },
    { lat: 49.149472, lng: 19.5740683 },
    { lat: 49.1498564, lng: 19.5747234 },
    { lat: 49.1499999, lng: 19.5750103 },
    { lat: 49.1502344, lng: 19.5755091 },
    { lat: 49.1503672, lng: 19.5757582 },
    { lat: 49.1506881, lng: 19.5763764 },
    { lat: 49.1509135, lng: 19.5770299 },
    { lat: 49.1510491, lng: 19.5774386 },
    { lat: 49.1512021, lng: 19.5777807 },
    { lat: 49.1512251, lng: 19.57782 },
    { lat: 49.1516721, lng: 19.5783855 },
    { lat: 49.1519137, lng: 19.578696 },
    { lat: 49.1518765, lng: 19.5788456 },
    { lat: 49.1517847, lng: 19.5791357 },
    { lat: 49.1515076, lng: 19.5798126 },
    { lat: 49.1512621, lng: 19.5801648 },
    { lat: 49.150295, lng: 19.5811547 },
    { lat: 49.1499839, lng: 19.581779 },
    { lat: 49.149745, lng: 19.5823259 },
    { lat: 49.1497212, lng: 19.5824263 },
    { lat: 49.1496619, lng: 19.5825337 },
    { lat: 49.1496414, lng: 19.5826301 },
    { lat: 49.1495919, lng: 19.5826781 },
    { lat: 49.149576, lng: 19.5827759 },
    { lat: 49.1495335, lng: 19.5828606 },
    { lat: 49.1494587, lng: 19.5830498 },
    { lat: 49.1494284, lng: 19.5833269 },
    { lat: 49.1493994, lng: 19.5834588 },
    { lat: 49.1493404, lng: 19.5836276 },
    { lat: 49.1493419, lng: 19.5838596 },
    { lat: 49.1493286, lng: 19.5838887 },
    { lat: 49.1492819, lng: 19.5839523 },
    { lat: 49.1491338, lng: 19.5840938 },
    { lat: 49.1490699, lng: 19.5841739 },
    { lat: 49.1490764, lng: 19.5842182 },
    { lat: 49.1490671, lng: 19.5842843 },
    { lat: 49.1489354, lng: 19.5845155 },
    { lat: 49.1487806, lng: 19.5847275 },
    { lat: 49.1486069, lng: 19.584976 },
    { lat: 49.148511, lng: 19.5850749 },
    { lat: 49.1484666, lng: 19.5850886 },
    { lat: 49.1481639, lng: 19.5852923 },
    { lat: 49.1480513, lng: 19.5853195 },
    { lat: 49.147947, lng: 19.5854093 },
    { lat: 49.1476921, lng: 19.5855014 },
    { lat: 49.1475233, lng: 19.5856325 },
    { lat: 49.1474706, lng: 19.5856891 },
    { lat: 49.1472816, lng: 19.5859428 },
    { lat: 49.147067, lng: 19.5860592 },
    { lat: 49.1469958, lng: 19.5861044 },
    { lat: 49.146935, lng: 19.5861795 },
    { lat: 49.1468807, lng: 19.5861733 },
    { lat: 49.1467656, lng: 19.5861987 },
    { lat: 49.1466133, lng: 19.5863924 },
    { lat: 49.1464943, lng: 19.5864275 },
    { lat: 49.1469635, lng: 19.5873905 },
    { lat: 49.1470249, lng: 19.587527 },
    { lat: 49.1470612, lng: 19.5876027 },
    { lat: 49.1471659, lng: 19.5878325 },
    { lat: 49.1471429, lng: 19.587872 },
    { lat: 49.1471555, lng: 19.5879265 },
    { lat: 49.147205, lng: 19.5880966 },
    { lat: 49.1474014, lng: 19.588206 },
    { lat: 49.1474702, lng: 19.5882297 },
    { lat: 49.1477234, lng: 19.5883523 },
    { lat: 49.1480548, lng: 19.589379 },
    { lat: 49.1482566, lng: 19.5898089 },
    { lat: 49.1483096, lng: 19.589912 },
    { lat: 49.1484454, lng: 19.5901739 },
    { lat: 49.1486428, lng: 19.5905527 },
    { lat: 49.1490184, lng: 19.5912139 },
    { lat: 49.1491242, lng: 19.5914134 },
    { lat: 49.1492368, lng: 19.5915139 },
    { lat: 49.1494694, lng: 19.5916994 },
    { lat: 49.1499578, lng: 19.592085 },
    { lat: 49.1506656, lng: 19.5923209 },
    { lat: 49.151148, lng: 19.5924231 },
    { lat: 49.1513095, lng: 19.5924541 },
    { lat: 49.1519966, lng: 19.5921239 },
    { lat: 49.1521951, lng: 19.5920553 },
    { lat: 49.1523696, lng: 19.5920025 },
    { lat: 49.1525902, lng: 19.5921 },
    { lat: 49.1535816, lng: 19.5924993 },
    { lat: 49.1543702, lng: 19.5927732 },
    { lat: 49.1543741, lng: 19.5929078 },
    { lat: 49.1543892, lng: 19.5930185 },
    { lat: 49.1544411, lng: 19.593196 },
    { lat: 49.1544812, lng: 19.5932631 },
    { lat: 49.1544718, lng: 19.5933792 },
    { lat: 49.1545597, lng: 19.5936289 },
    { lat: 49.1547724, lng: 19.5940008 },
    { lat: 49.1547854, lng: 19.5941636 },
    { lat: 49.1548456, lng: 19.5943747 },
    { lat: 49.1548494, lng: 19.5944593 },
    { lat: 49.1548895, lng: 19.5946728 },
    { lat: 49.1548917, lng: 19.5947234 },
    { lat: 49.1549622, lng: 19.5949143 },
    { lat: 49.1550007, lng: 19.5950421 },
    { lat: 49.1550074, lng: 19.5950903 },
    { lat: 49.1549923, lng: 19.5951599 },
    { lat: 49.1550558, lng: 19.595442 },
    { lat: 49.1551762, lng: 19.5956637 },
    { lat: 49.1551984, lng: 19.5957323 },
    { lat: 49.155217, lng: 19.595847 },
    { lat: 49.1552632, lng: 19.5962706 },
    { lat: 49.1552889, lng: 19.5967225 },
    { lat: 49.1553298, lng: 19.5969007 },
    { lat: 49.1554005, lng: 19.597173 },
    { lat: 49.1554072, lng: 19.5972695 },
    { lat: 49.1553988, lng: 19.5973341 },
    { lat: 49.155357, lng: 19.5974335 },
    { lat: 49.1553614, lng: 19.5976785 },
    { lat: 49.1553115, lng: 19.5978976 },
    { lat: 49.1551665, lng: 19.5980607 },
    { lat: 49.1550732, lng: 19.5983112 },
    { lat: 49.1549065, lng: 19.5985158 },
    { lat: 49.1549265, lng: 19.5987099 },
    { lat: 49.1548599, lng: 19.599035 },
    { lat: 49.1548981, lng: 19.5992145 },
    { lat: 49.1550214, lng: 19.5991672 },
    { lat: 49.1550745, lng: 19.5990983 },
    { lat: 49.1551229, lng: 19.5990177 },
    { lat: 49.1552945, lng: 19.5990487 },
    { lat: 49.1553504, lng: 19.5987437 },
    { lat: 49.1554044, lng: 19.5987417 },
    { lat: 49.1555747, lng: 19.5987912 },
    { lat: 49.155669, lng: 19.5985355 },
    { lat: 49.1557723, lng: 19.598474 },
    { lat: 49.1559184, lng: 19.5983947 },
    { lat: 49.1559681, lng: 19.5983452 },
    { lat: 49.1560762, lng: 19.5981928 },
    { lat: 49.1562794, lng: 19.5981536 },
    { lat: 49.1564015, lng: 19.5980397 },
    { lat: 49.1564425, lng: 19.5980211 },
    { lat: 49.1566132, lng: 19.5980322 },
    { lat: 49.1567017, lng: 19.5979994 },
    { lat: 49.1568533, lng: 19.5979645 },
    { lat: 49.1570473, lng: 19.5979925 },
    { lat: 49.1573243, lng: 19.5980359 },
    { lat: 49.1573669, lng: 19.5980336 },
    { lat: 49.1574927, lng: 19.5979499 },
    { lat: 49.1575272, lng: 19.5979665 },
    { lat: 49.1576406, lng: 19.5980821 },
    { lat: 49.1576709, lng: 19.5980789 },
    { lat: 49.1577862, lng: 19.5979125 },
    { lat: 49.1579269, lng: 19.5977819 },
    { lat: 49.1579795, lng: 19.5977742 },
    { lat: 49.1581502, lng: 19.5977647 },
    { lat: 49.1581606, lng: 19.5978446 },
    { lat: 49.1582419, lng: 19.5978447 },
    { lat: 49.1583129, lng: 19.5978233 },
    { lat: 49.1583441, lng: 19.5978128 },
    { lat: 49.1583974, lng: 19.5977957 },
    { lat: 49.1585791, lng: 19.5979571 },
    { lat: 49.1586319, lng: 19.5980035 },
    { lat: 49.1587743, lng: 19.597936 },
    { lat: 49.1589331, lng: 19.5979864 },
    { lat: 49.1590483, lng: 19.5979906 },
    { lat: 49.1590986, lng: 19.5980062 },
    { lat: 49.1592072, lng: 19.5980694 },
    { lat: 49.1592483, lng: 19.5981065 },
    { lat: 49.1593015, lng: 19.5982342 },
    { lat: 49.1595166, lng: 19.5981365 },
    { lat: 49.1595638, lng: 19.5981301 },
    { lat: 49.1596645, lng: 19.5981635 },
    { lat: 49.1596915, lng: 19.5981916 },
    { lat: 49.1597828, lng: 19.5983423 },
    { lat: 49.159896, lng: 19.5983784 },
    { lat: 49.1600024, lng: 19.5984126 },
    { lat: 49.1601808, lng: 19.5984746 },
    { lat: 49.1602734, lng: 19.598627 },
    { lat: 49.1603174, lng: 19.5986562 },
    { lat: 49.1607174, lng: 19.5988841 },
    { lat: 49.1608657, lng: 19.599 },
    { lat: 49.1609931, lng: 19.5991023 },
    { lat: 49.1610109, lng: 19.5995209 },
    { lat: 49.1609942, lng: 19.5996008 },
    { lat: 49.1609849, lng: 19.5996943 },
    { lat: 49.1609683, lng: 19.5997288 },
    { lat: 49.1609613, lng: 19.599745 },
    { lat: 49.1608922, lng: 19.5998908 },
    { lat: 49.1609109, lng: 19.6000069 },
    { lat: 49.1609829, lng: 19.6001272 },
    { lat: 49.1610288, lng: 19.6002246 },
    { lat: 49.1611242, lng: 19.6004404 },
    { lat: 49.1611626, lng: 19.6004911 },
    { lat: 49.1612464, lng: 19.6006015 },
    { lat: 49.1612564, lng: 19.6007503 },
    { lat: 49.1612663, lng: 19.6008918 },
    { lat: 49.1613516, lng: 19.6012404 },
    { lat: 49.1613902, lng: 19.6013447 },
    { lat: 49.1614411, lng: 19.6014251 },
    { lat: 49.1614873, lng: 19.6015236 },
    { lat: 49.1615315, lng: 19.6015557 },
    { lat: 49.1615935, lng: 19.6016331 },
    { lat: 49.1617131, lng: 19.6016897 },
    { lat: 49.1618372, lng: 19.6018201 },
    { lat: 49.1618874, lng: 19.6018534 },
    { lat: 49.1619487, lng: 19.6018409 },
    { lat: 49.1620182, lng: 19.6018858 },
    { lat: 49.1621094, lng: 19.6020077 },
    { lat: 49.1622818, lng: 19.6020088 },
    { lat: 49.1623323, lng: 19.601951 },
    { lat: 49.1624093, lng: 19.6019368 },
    { lat: 49.1626292, lng: 19.6020424 },
    { lat: 49.1626697, lng: 19.6020893 },
    { lat: 49.1627188, lng: 19.6021029 },
    { lat: 49.1628153, lng: 19.6020449 },
    { lat: 49.1628407, lng: 19.6020578 },
    { lat: 49.1629033, lng: 19.6021225 },
    { lat: 49.1631143, lng: 19.6024044 },
    { lat: 49.163192, lng: 19.6024056 },
    { lat: 49.1632899, lng: 19.6025049 },
    { lat: 49.1634931, lng: 19.6023382 },
    { lat: 49.1635919, lng: 19.6024582 },
    { lat: 49.1636666, lng: 19.602512 },
    { lat: 49.1637612, lng: 19.6026112 },
    { lat: 49.1638061, lng: 19.6026595 },
    { lat: 49.1640292, lng: 19.6024587 },
    { lat: 49.1640498, lng: 19.6024645 },
    { lat: 49.1640715, lng: 19.6024698 },
    { lat: 49.1643965, lng: 19.6025576 },
    { lat: 49.1644564, lng: 19.6025614 },
    { lat: 49.1645355, lng: 19.6025665 },
    { lat: 49.164697, lng: 19.6025766 },
    { lat: 49.1657256, lng: 19.6024236 },
    { lat: 49.1664542, lng: 19.6020405 },
    { lat: 49.1665277, lng: 19.6020004 },
    { lat: 49.1666281, lng: 19.6019485 },
    { lat: 49.1667976, lng: 19.6018585 },
    { lat: 49.1669549, lng: 19.6017769 },
    { lat: 49.1671213, lng: 19.6016911 },
    { lat: 49.1674704, lng: 19.601537 },
    { lat: 49.1681087, lng: 19.6013764 },
    { lat: 49.1681565, lng: 19.6013621 },
    { lat: 49.1689589, lng: 19.6011145 },
    { lat: 49.1693862, lng: 19.6002447 },
    { lat: 49.1694812, lng: 19.6000533 },
    { lat: 49.1703073, lng: 19.5992808 },
    { lat: 49.1703442, lng: 19.5992452 },
    { lat: 49.1703646, lng: 19.5992254 },
    { lat: 49.1704096, lng: 19.5994224 },
    { lat: 49.1704275, lng: 19.5995437 },
    { lat: 49.1704261, lng: 19.5996396 },
    { lat: 49.1704764, lng: 19.5997341 },
    { lat: 49.1705843, lng: 19.5999025 },
    { lat: 49.1706266, lng: 19.6001408 },
    { lat: 49.1708545, lng: 19.6009783 },
    { lat: 49.1709729, lng: 19.601129 },
    { lat: 49.1709835, lng: 19.6013422 },
    { lat: 49.1710196, lng: 19.6014425 },
    { lat: 49.1710766, lng: 19.6014545 },
    { lat: 49.171124, lng: 19.6017337 },
    { lat: 49.1711783, lng: 19.6018655 },
    { lat: 49.1712659, lng: 19.6019126 },
    { lat: 49.171272, lng: 19.6021698 },
    { lat: 49.171447, lng: 19.602356 },
    { lat: 49.1715213, lng: 19.6025805 },
    { lat: 49.1715962, lng: 19.602617 },
    { lat: 49.1716497, lng: 19.6027587 },
    { lat: 49.1716859, lng: 19.602859 },
    { lat: 49.1717318, lng: 19.6029042 },
    { lat: 49.1718027, lng: 19.6029271 },
    { lat: 49.1718477, lng: 19.6029771 },
    { lat: 49.1718834, lng: 19.6030145 },
    { lat: 49.1719222, lng: 19.6031011 },
    { lat: 49.171958, lng: 19.6031467 },
    { lat: 49.1720524, lng: 19.6031934 },
    { lat: 49.1720864, lng: 19.6033245 },
    { lat: 49.1721214, lng: 19.6033526 },
    { lat: 49.1722162, lng: 19.6033758 },
    { lat: 49.1722664, lng: 19.6035956 },
    { lat: 49.1723024, lng: 19.6035933 },
    { lat: 49.1723666, lng: 19.6035405 },
    { lat: 49.1724064, lng: 19.6035486 },
    { lat: 49.172448, lng: 19.6035972 },
    { lat: 49.1724714, lng: 19.6036455 },
    { lat: 49.1725665, lng: 19.6037026 },
    { lat: 49.1726908, lng: 19.6037094 },
    { lat: 49.1728537, lng: 19.6039053 },
    { lat: 49.1729203, lng: 19.6039581 },
    { lat: 49.1729716, lng: 19.6040687 },
    { lat: 49.1730004, lng: 19.6040862 },
    { lat: 49.1731607, lng: 19.60409 },
    { lat: 49.1733058, lng: 19.604162 },
    { lat: 49.1734075, lng: 19.6041921 },
    { lat: 49.1735706, lng: 19.6042403 },
    { lat: 49.1737296, lng: 19.6044423 },
    { lat: 49.1738577, lng: 19.6045337 },
    { lat: 49.1739863, lng: 19.6046636 },
    { lat: 49.1742814, lng: 19.6048647 },
    { lat: 49.1744945, lng: 19.6049689 },
    { lat: 49.1745851, lng: 19.6052025 },
    { lat: 49.1746569, lng: 19.6051734 },
    { lat: 49.1747517, lng: 19.6053497 },
    { lat: 49.1748558, lng: 19.6055596 },
    { lat: 49.1748842, lng: 19.6056182 },
    { lat: 49.1749722, lng: 19.605797 },
    { lat: 49.1750502, lng: 19.605952 },
    { lat: 49.1751416, lng: 19.6061312 },
    { lat: 49.1751995, lng: 19.6062177 },
    { lat: 49.1753431, lng: 19.6066082 },
    { lat: 49.1754048, lng: 19.6068272 },
    { lat: 49.1754474, lng: 19.6069981 },
    { lat: 49.1754658, lng: 19.6070801 },
    { lat: 49.1755428, lng: 19.6071664 },
    { lat: 49.1756082, lng: 19.6072662 },
    { lat: 49.1757126, lng: 19.6075365 },
    { lat: 49.1757366, lng: 19.6076187 },
    { lat: 49.1757846, lng: 19.6078574 },
    { lat: 49.1757928, lng: 19.6080201 },
    { lat: 49.1758291, lng: 19.6081974 },
    { lat: 49.1758423, lng: 19.6084924 },
    { lat: 49.1757935, lng: 19.6087154 },
    { lat: 49.175863, lng: 19.6089807 },
    { lat: 49.1760696, lng: 19.6091648 },
    { lat: 49.1762942, lng: 19.6094774 },
    { lat: 49.1764677, lng: 19.6097514 },
    { lat: 49.1766413, lng: 19.6098799 },
    { lat: 49.1767114, lng: 19.6100908 },
    { lat: 49.1768146, lng: 19.6101809 },
    { lat: 49.1770037, lng: 19.6103769 },
    { lat: 49.1770974, lng: 19.6104802 },
    { lat: 49.1772756, lng: 19.6106863 },
    { lat: 49.1781121, lng: 19.6109013 },
    { lat: 49.1780332, lng: 19.6109218 },
    { lat: 49.1784191, lng: 19.6115907 },
    { lat: 49.1786405, lng: 19.6120314 },
    { lat: 49.1786965, lng: 19.612228 },
    { lat: 49.1790608, lng: 19.6127864 },
    { lat: 49.1790916, lng: 19.6128488 },
    { lat: 49.1789495, lng: 19.6132233 },
    { lat: 49.1789082, lng: 19.6133825 },
    { lat: 49.1793678, lng: 19.614415 },
    { lat: 49.1796437, lng: 19.6149675 },
    { lat: 49.1797545, lng: 19.6152002 },
    { lat: 49.1802182, lng: 19.6162471 },
    { lat: 49.1803074, lng: 19.6164514 },
    { lat: 49.1803109, lng: 19.616482 },
    { lat: 49.1803851, lng: 19.6170807 },
    { lat: 49.1804549, lng: 19.6176385 },
    { lat: 49.1805739, lng: 19.617902 },
    { lat: 49.1809707, lng: 19.6187874 },
    { lat: 49.1824962, lng: 19.6221952 },
    { lat: 49.1838318, lng: 19.6256374 },
    { lat: 49.1848103, lng: 19.6255516 },
    { lat: 49.185128, lng: 19.6255233 },
    { lat: 49.1853195, lng: 19.6256525 },
    { lat: 49.1863521, lng: 19.6263491 },
    { lat: 49.1868467, lng: 19.6264498 },
    { lat: 49.1874335, lng: 19.6265689 },
    { lat: 49.1878469, lng: 19.6267709 },
    { lat: 49.1881563, lng: 19.6269106 },
    { lat: 49.1889041, lng: 19.6268563 },
    { lat: 49.1890721, lng: 19.6271122 },
    { lat: 49.1895119, lng: 19.6277797 },
    { lat: 49.1896019, lng: 19.6278745 },
    { lat: 49.1898431, lng: 19.6281275 },
    { lat: 49.1903713, lng: 19.6286807 },
    { lat: 49.1909893, lng: 19.6294029 },
    { lat: 49.19117, lng: 19.6296132 },
    { lat: 49.1914883, lng: 19.6299854 },
    { lat: 49.191809, lng: 19.6303775 },
    { lat: 49.1922495, lng: 19.6321732 },
    { lat: 49.1922729, lng: 19.6334546 },
    { lat: 49.1942405, lng: 19.6377535 },
  ],
  LiptovskýMikuláš: [
    { lat: 49.0541341, lng: 19.5532933 },
    { lat: 49.0541534, lng: 19.5533431 },
    { lat: 49.0537014, lng: 19.5532892 },
    { lat: 49.0533629, lng: 19.5532296 },
    { lat: 49.0529453, lng: 19.5531635 },
    { lat: 49.0522649, lng: 19.553045 },
    { lat: 49.0519373, lng: 19.553397 },
    { lat: 49.0517952, lng: 19.5535427 },
    { lat: 49.0513489, lng: 19.5535159 },
    { lat: 49.050825, lng: 19.5537193 },
    { lat: 49.0503978, lng: 19.5527724 },
    { lat: 49.0500612, lng: 19.5522323 },
    { lat: 49.0499991, lng: 19.5525301 },
    { lat: 49.0495599, lng: 19.5521326 },
    { lat: 49.048853, lng: 19.5520234 },
    { lat: 49.0481953, lng: 19.5517606 },
    { lat: 49.047908, lng: 19.551762 },
    { lat: 49.0475212, lng: 19.5517768 },
    { lat: 49.0468434, lng: 19.5517169 },
    { lat: 49.046872, lng: 19.551058 },
    { lat: 49.0460013, lng: 19.5509861 },
    { lat: 49.0450809, lng: 19.5505699 },
    { lat: 49.0442624, lng: 19.5504584 },
    { lat: 49.0436024, lng: 19.5504408 },
    { lat: 49.0429042, lng: 19.5503774 },
    { lat: 49.0426536, lng: 19.5503387 },
    { lat: 49.0417535, lng: 19.5503956 },
    { lat: 49.0408648, lng: 19.5504495 },
    { lat: 49.0410805, lng: 19.551403 },
    { lat: 49.0412164, lng: 19.5524611 },
    { lat: 49.0412153, lng: 19.552662 },
    { lat: 49.0410753, lng: 19.5532754 },
    { lat: 49.0408629, lng: 19.5536619 },
    { lat: 49.0408988, lng: 19.5544023 },
    { lat: 49.0409028, lng: 19.5544436 },
    { lat: 49.0408961, lng: 19.5545861 },
    { lat: 49.0408417, lng: 19.555749 },
    { lat: 49.0406955, lng: 19.5557604 },
    { lat: 49.0405616, lng: 19.5563053 },
    { lat: 49.0404072, lng: 19.5568269 },
    { lat: 49.0401708, lng: 19.5570553 },
    { lat: 49.0400283, lng: 19.5576399 },
    { lat: 49.0397417, lng: 19.5579792 },
    { lat: 49.0395142, lng: 19.5592981 },
    { lat: 49.0393149, lng: 19.5603454 },
    { lat: 49.0393958, lng: 19.5610096 },
    { lat: 49.0395249, lng: 19.5613998 },
    { lat: 49.0395163, lng: 19.5615336 },
    { lat: 49.0397125, lng: 19.5615367 },
    { lat: 49.0398558, lng: 19.5618133 },
    { lat: 49.0397732, lng: 19.562105 },
    { lat: 49.0397867, lng: 19.5623506 },
    { lat: 49.0397576, lng: 19.562558 },
    { lat: 49.0396339, lng: 19.562538 },
    { lat: 49.0393944, lng: 19.5639899 },
    { lat: 49.0392325, lng: 19.564992 },
    { lat: 49.0391509, lng: 19.5657031 },
    { lat: 49.0390302, lng: 19.5656256 },
    { lat: 49.0388983, lng: 19.5656994 },
    { lat: 49.0387046, lng: 19.5656245 },
    { lat: 49.0385914, lng: 19.5655656 },
    { lat: 49.0381531, lng: 19.5654894 },
    { lat: 49.0378192, lng: 19.5660523 },
    { lat: 49.0373986, lng: 19.5667953 },
    { lat: 49.0374749, lng: 19.567531 },
    { lat: 49.0375793, lng: 19.5684707 },
    { lat: 49.0374221, lng: 19.5703765 },
    { lat: 49.0372456, lng: 19.5723784 },
    { lat: 49.0371255, lng: 19.5737578 },
    { lat: 49.0370925, lng: 19.574124 },
    { lat: 49.037201, lng: 19.5741327 },
    { lat: 49.0379341, lng: 19.5741681 },
    { lat: 49.0381851, lng: 19.5746392 },
    { lat: 49.0382858, lng: 19.5747468 },
    { lat: 49.0381225, lng: 19.5756739 },
    { lat: 49.0379696, lng: 19.5762028 },
    { lat: 49.0376941, lng: 19.575917 },
    { lat: 49.0374888, lng: 19.5758291 },
    { lat: 49.0374149, lng: 19.5757975 },
    { lat: 49.0372438, lng: 19.5758388 },
    { lat: 49.0371341, lng: 19.5758653 },
    { lat: 49.0368915, lng: 19.575956 },
    { lat: 49.0366263, lng: 19.5759719 },
    { lat: 49.0362889, lng: 19.5758345 },
    { lat: 49.0361494, lng: 19.5758408 },
    { lat: 49.0358256, lng: 19.5757813 },
    { lat: 49.0358165, lng: 19.5758824 },
    { lat: 49.0362656, lng: 19.5763174 },
    { lat: 49.0362094, lng: 19.5764946 },
    { lat: 49.0359783, lng: 19.5771691 },
    { lat: 49.0356869, lng: 19.5780255 },
    { lat: 49.0365595, lng: 19.5796343 },
    { lat: 49.036972, lng: 19.5802298 },
    { lat: 49.0370544, lng: 19.5807853 },
    { lat: 49.0371259, lng: 19.5810447 },
    { lat: 49.0374628, lng: 19.5820172 },
    { lat: 49.037628, lng: 19.582586 },
    { lat: 49.0375833, lng: 19.5827929 },
    { lat: 49.0379134, lng: 19.5835423 },
    { lat: 49.0377511, lng: 19.5837112 },
    { lat: 49.0375916, lng: 19.5840748 },
    { lat: 49.0375741, lng: 19.5848071 },
    { lat: 49.0374501, lng: 19.5855591 },
    { lat: 49.0373722, lng: 19.5859263 },
    { lat: 49.0373327, lng: 19.5862023 },
    { lat: 49.0372345, lng: 19.5866726 },
    { lat: 49.0367011, lng: 19.5880591 },
    { lat: 49.0362192, lng: 19.5887666 },
    { lat: 49.0357393, lng: 19.5891452 },
    { lat: 49.0356712, lng: 19.589185 },
    { lat: 49.0355903, lng: 19.5891403 },
    { lat: 49.0344977, lng: 19.5888233 },
    { lat: 49.0338469, lng: 19.5890313 },
    { lat: 49.0335489, lng: 19.5891449 },
    { lat: 49.0326924, lng: 19.5896645 },
    { lat: 49.0315446, lng: 19.5901974 },
    { lat: 49.0307661, lng: 19.5900488 },
    { lat: 49.0305773, lng: 19.5900089 },
    { lat: 49.0300435, lng: 19.589791 },
    { lat: 49.0291793, lng: 19.5894441 },
    { lat: 49.029027, lng: 19.5893849 },
    { lat: 49.0284512, lng: 19.5891351 },
    { lat: 49.0273007, lng: 19.5891585 },
    { lat: 49.0267733, lng: 19.5895316 },
    { lat: 49.0266719, lng: 19.5895555 },
    { lat: 49.026273, lng: 19.5897565 },
    { lat: 49.0260494, lng: 19.5898922 },
    { lat: 49.0254781, lng: 19.5902189 },
    { lat: 49.0255969, lng: 19.5910054 },
    { lat: 49.0255165, lng: 19.5913688 },
    { lat: 49.0254182, lng: 19.5919388 },
    { lat: 49.0249549, lng: 19.5922621 },
    { lat: 49.0246083, lng: 19.592617 },
    { lat: 49.02436, lng: 19.5927607 },
    { lat: 49.0242669, lng: 19.5927438 },
    { lat: 49.024035, lng: 19.5928218 },
    { lat: 49.0232804, lng: 19.5937542 },
    { lat: 49.0221462, lng: 19.5949397 },
    { lat: 49.0221083, lng: 19.5951727 },
    { lat: 49.0219864, lng: 19.5954956 },
    { lat: 49.0218451, lng: 19.5956356 },
    { lat: 49.0217068, lng: 19.5957146 },
    { lat: 49.02118, lng: 19.596001 },
    { lat: 49.0209148, lng: 19.5961949 },
    { lat: 49.0200965, lng: 19.5970404 },
    { lat: 49.020005, lng: 19.5973838 },
    { lat: 49.0199728, lng: 19.597642 },
    { lat: 49.0197508, lng: 19.5978418 },
    { lat: 49.019561, lng: 19.5977578 },
    { lat: 49.0191072, lng: 19.5981618 },
    { lat: 49.0188855, lng: 19.5983917 },
    { lat: 49.0186945, lng: 19.5983283 },
    { lat: 49.0184211, lng: 19.5987656 },
    { lat: 49.0182915, lng: 19.5986872 },
    { lat: 49.0181531, lng: 19.5988682 },
    { lat: 49.0177258, lng: 19.5992372 },
    { lat: 49.0174505, lng: 19.5994058 },
    { lat: 49.0167717, lng: 19.5994212 },
    { lat: 49.0162193, lng: 19.6001632 },
    { lat: 49.0161149, lng: 19.6001974 },
    { lat: 49.0160948, lng: 19.6003492 },
    { lat: 49.0158418, lng: 19.6006347 },
    { lat: 49.0154059, lng: 19.6007906 },
    { lat: 49.0148658, lng: 19.6006052 },
    { lat: 49.0140575, lng: 19.6008975 },
    { lat: 49.0130564, lng: 19.601059 },
    { lat: 49.012539, lng: 19.6016064 },
    { lat: 49.0113299, lng: 19.6028814 },
    { lat: 49.0102629, lng: 19.6040314 },
    { lat: 49.0097666, lng: 19.6043982 },
    { lat: 49.0087999, lng: 19.6043228 },
    { lat: 49.0076027, lng: 19.6045072 },
    { lat: 49.0071851, lng: 19.6055967 },
    { lat: 49.0067403, lng: 19.6062 },
    { lat: 49.0048761, lng: 19.6091222 },
    { lat: 49.0036013, lng: 19.6111178 },
    { lat: 49.0026562, lng: 19.6109443 },
    { lat: 49.0024987, lng: 19.6109229 },
    { lat: 49.0003706, lng: 19.6107577 },
    { lat: 49.000313, lng: 19.6107522 },
    { lat: 48.9976925, lng: 19.6107856 },
    { lat: 48.9976397, lng: 19.6107857 },
    { lat: 48.997504, lng: 19.6131106 },
    { lat: 48.9974006, lng: 19.6148921 },
    { lat: 48.9973476, lng: 19.6172059 },
    { lat: 48.9973024, lng: 19.619159 },
    { lat: 48.9972939, lng: 19.6195503 },
    { lat: 48.9972414, lng: 19.619531 },
    { lat: 48.9972306, lng: 19.6196699 },
    { lat: 48.997281, lng: 19.6196621 },
    { lat: 48.997032, lng: 19.6204895 },
    { lat: 48.9964422, lng: 19.6209051 },
    { lat: 48.9956822, lng: 19.6211679 },
    { lat: 48.9957019, lng: 19.6213812 },
    { lat: 48.9952025, lng: 19.6219543 },
    { lat: 48.9937652, lng: 19.6225157 },
    { lat: 48.9928082, lng: 19.6228089 },
    { lat: 48.9925548, lng: 19.6228847 },
    { lat: 48.9912174, lng: 19.6230095 },
    { lat: 48.9901309, lng: 19.6236576 },
    { lat: 48.9869561, lng: 19.625724 },
    { lat: 48.9848926, lng: 19.6263949 },
    { lat: 48.9848741, lng: 19.6262824 },
    { lat: 48.9843158, lng: 19.6265676 },
    { lat: 48.9841404, lng: 19.6268795 },
    { lat: 48.9837282, lng: 19.6272586 },
    { lat: 48.9835886, lng: 19.6271899 },
    { lat: 48.9833087, lng: 19.6275818 },
    { lat: 48.9831525, lng: 19.6280417 },
    { lat: 48.9831473, lng: 19.6283267 },
    { lat: 48.9829908, lng: 19.6286801 },
    { lat: 48.9828453, lng: 19.629079 },
    { lat: 48.9828552, lng: 19.6297817 },
    { lat: 48.9849667, lng: 19.6284743 },
    { lat: 48.9858866, lng: 19.6280797 },
    { lat: 48.9868272, lng: 19.6285549 },
    { lat: 48.9872444, lng: 19.6289663 },
    { lat: 48.9880788, lng: 19.6291566 },
    { lat: 48.9889879, lng: 19.6293791 },
    { lat: 48.9894736, lng: 19.6294336 },
    { lat: 48.9904102, lng: 19.6297131 },
    { lat: 48.9905543, lng: 19.6297875 },
    { lat: 48.9920456, lng: 19.6296739 },
    { lat: 48.9926647, lng: 19.629565 },
    { lat: 48.9932004, lng: 19.6296208 },
    { lat: 48.9936843, lng: 19.6295076 },
    { lat: 48.9945637, lng: 19.6292484 },
    { lat: 48.9952439, lng: 19.6287855 },
    { lat: 48.9966264, lng: 19.628813 },
    { lat: 48.9971339, lng: 19.6285958 },
    { lat: 48.9980028, lng: 19.6284744 },
    { lat: 48.9984565, lng: 19.6285408 },
    { lat: 48.9988825, lng: 19.6288222 },
    { lat: 48.9995881, lng: 19.6293065 },
    { lat: 48.9995998, lng: 19.6292927 },
    { lat: 48.9998016, lng: 19.6296964 },
    { lat: 49.0000009, lng: 19.6300202 },
    { lat: 49.0002945, lng: 19.6305662 },
    { lat: 49.0003753, lng: 19.6307821 },
    { lat: 49.0007557, lng: 19.6309258 },
    { lat: 49.0014646, lng: 19.6316372 },
    { lat: 49.001581, lng: 19.6318674 },
    { lat: 49.0018605, lng: 19.6321461 },
    { lat: 49.0019897, lng: 19.6323172 },
    { lat: 49.002336, lng: 19.6325279 },
    { lat: 49.0027035, lng: 19.6324551 },
    { lat: 49.0028461, lng: 19.6326187 },
    { lat: 49.0031416, lng: 19.6327274 },
    { lat: 49.0031828, lng: 19.6326144 },
    { lat: 49.0035912, lng: 19.6325026 },
    { lat: 49.0038767, lng: 19.6318339 },
    { lat: 49.0042623, lng: 19.6313613 },
    { lat: 49.0045057, lng: 19.6313078 },
    { lat: 49.0051647, lng: 19.6299477 },
    { lat: 49.0054649, lng: 19.6297067 },
    { lat: 49.0057407, lng: 19.6296028 },
    { lat: 49.0060819, lng: 19.6290715 },
    { lat: 49.0061806, lng: 19.6287333 },
    { lat: 49.006317, lng: 19.6285573 },
    { lat: 49.0065846, lng: 19.6285702 },
    { lat: 49.0067394, lng: 19.6282779 },
    { lat: 49.0072707, lng: 19.6279634 },
    { lat: 49.0076059, lng: 19.6281789 },
    { lat: 49.0081206, lng: 19.628122 },
    { lat: 49.0083655, lng: 19.6274797 },
    { lat: 49.0083815, lng: 19.6273101 },
    { lat: 49.0087439, lng: 19.6269191 },
    { lat: 49.0089226, lng: 19.6267592 },
    { lat: 49.0093658, lng: 19.6261646 },
    { lat: 49.0093792, lng: 19.6260386 },
    { lat: 49.0095177, lng: 19.6258359 },
    { lat: 49.0095165, lng: 19.6255823 },
    { lat: 49.0096705, lng: 19.6250926 },
    { lat: 49.0097268, lng: 19.6248696 },
    { lat: 49.0097103, lng: 19.624601 },
    { lat: 49.0095655, lng: 19.6236298 },
    { lat: 49.0097068, lng: 19.6232368 },
    { lat: 49.0100766, lng: 19.622943 },
    { lat: 49.0102047, lng: 19.622756 },
    { lat: 49.0103849, lng: 19.6225798 },
    { lat: 49.0105634, lng: 19.6224888 },
    { lat: 49.0106474, lng: 19.6223573 },
    { lat: 49.01094, lng: 19.6220223 },
    { lat: 49.0110194, lng: 19.6218809 },
    { lat: 49.0111523, lng: 19.6217282 },
    { lat: 49.0113755, lng: 19.6216093 },
    { lat: 49.0115496, lng: 19.6210145 },
    { lat: 49.0116406, lng: 19.6207603 },
    { lat: 49.0120002, lng: 19.6200601 },
    { lat: 49.0120211, lng: 19.6195733 },
    { lat: 49.0119962, lng: 19.6192174 },
    { lat: 49.0121163, lng: 19.6190592 },
    { lat: 49.0120941, lng: 19.6187636 },
    { lat: 49.0123238, lng: 19.6180793 },
    { lat: 49.0125019, lng: 19.6179058 },
    { lat: 49.0128699, lng: 19.6178465 },
    { lat: 49.0130027, lng: 19.6177692 },
    { lat: 49.0134549, lng: 19.6177033 },
    { lat: 49.0135439, lng: 19.6176775 },
    { lat: 49.0136214, lng: 19.6175212 },
    { lat: 49.013864, lng: 19.6171483 },
    { lat: 49.0139409, lng: 19.6169573 },
    { lat: 49.0139501, lng: 19.6168572 },
    { lat: 49.014065, lng: 19.6165574 },
    { lat: 49.0144502, lng: 19.6162275 },
    { lat: 49.0147551, lng: 19.6156657 },
    { lat: 49.0148433, lng: 19.6145962 },
    { lat: 49.0149057, lng: 19.6145043 },
    { lat: 49.0149434, lng: 19.6143396 },
    { lat: 49.015099, lng: 19.6141396 },
    { lat: 49.0153155, lng: 19.6141714 },
    { lat: 49.0156935, lng: 19.6135033 },
    { lat: 49.0158782, lng: 19.6133251 },
    { lat: 49.0160232, lng: 19.6129945 },
    { lat: 49.0162248, lng: 19.6127148 },
    { lat: 49.0163308, lng: 19.6126941 },
    { lat: 49.0164453, lng: 19.6127561 },
    { lat: 49.0166434, lng: 19.6127772 },
    { lat: 49.0168079, lng: 19.6125498 },
    { lat: 49.0169993, lng: 19.6124481 },
    { lat: 49.0175621, lng: 19.6123376 },
    { lat: 49.0177292, lng: 19.6124667 },
    { lat: 49.0179609, lng: 19.6123849 },
    { lat: 49.0180784, lng: 19.612167 },
    { lat: 49.0181935, lng: 19.6120445 },
    { lat: 49.0183123, lng: 19.6120088 },
    { lat: 49.0184083, lng: 19.6120645 },
    { lat: 49.0186339, lng: 19.6123671 },
    { lat: 49.0187258, lng: 19.6123306 },
    { lat: 49.018807, lng: 19.612058 },
    { lat: 49.0188488, lng: 19.6118375 },
    { lat: 49.0192469, lng: 19.611065 },
    { lat: 49.0193508, lng: 19.6105911 },
    { lat: 49.0196567, lng: 19.6099548 },
    { lat: 49.0197672, lng: 19.6098324 },
    { lat: 49.020089, lng: 19.6096701 },
    { lat: 49.0202314, lng: 19.6093541 },
    { lat: 49.0202503, lng: 19.609224 },
    { lat: 49.020538, lng: 19.6085285 },
    { lat: 49.0207576, lng: 19.6083503 },
    { lat: 49.0209636, lng: 19.6083203 },
    { lat: 49.0210765, lng: 19.6082744 },
    { lat: 49.0211527, lng: 19.6080411 },
    { lat: 49.0214446, lng: 19.6080172 },
    { lat: 49.0220861, lng: 19.6082797 },
    { lat: 49.0222432, lng: 19.6085144 },
    { lat: 49.0223432, lng: 19.6087327 },
    { lat: 49.0224615, lng: 19.6088844 },
    { lat: 49.0227352, lng: 19.6089063 },
    { lat: 49.0231707, lng: 19.6089939 },
    { lat: 49.0235348, lng: 19.6087995 },
    { lat: 49.023669, lng: 19.6085752 },
    { lat: 49.0239502, lng: 19.6083399 },
    { lat: 49.0242958, lng: 19.6082799 },
    { lat: 49.0244169, lng: 19.6081435 },
    { lat: 49.0244985, lng: 19.6078528 },
    { lat: 49.0247143, lng: 19.6077435 },
    { lat: 49.0250346, lng: 19.6077519 },
    { lat: 49.0250792, lng: 19.6076873 },
    { lat: 49.025125, lng: 19.607377 },
    { lat: 49.0253968, lng: 19.6072112 },
    { lat: 49.0255023, lng: 19.6069723 },
    { lat: 49.0259677, lng: 19.6066482 },
    { lat: 49.0262569, lng: 19.606216 },
    { lat: 49.0265088, lng: 19.6059267 },
    { lat: 49.0266581, lng: 19.6059187 },
    { lat: 49.0268746, lng: 19.6057465 },
    { lat: 49.026998, lng: 19.60558 },
    { lat: 49.0271307, lng: 19.6054768 },
    { lat: 49.0273638, lng: 19.605473 },
    { lat: 49.0276486, lng: 19.6053163 },
    { lat: 49.0279868, lng: 19.605298 },
    { lat: 49.0281041, lng: 19.6051239 },
    { lat: 49.0281306, lng: 19.6049344 },
    { lat: 49.0281944, lng: 19.604852 },
    { lat: 49.0283855, lng: 19.6048181 },
    { lat: 49.0284822, lng: 19.6047165 },
    { lat: 49.0286071, lng: 19.6046915 },
    { lat: 49.0287469, lng: 19.6046635 },
    { lat: 49.029058, lng: 19.6043881 },
    { lat: 49.0296789, lng: 19.604214 },
    { lat: 49.0297482, lng: 19.6041009 },
    { lat: 49.0300365, lng: 19.6038242 },
    { lat: 49.0302176, lng: 19.6037918 },
    { lat: 49.0306269, lng: 19.6038217 },
    { lat: 49.0309894, lng: 19.6035919 },
    { lat: 49.0310795, lng: 19.6035912 },
    { lat: 49.031164, lng: 19.6035167 },
    { lat: 49.0311883, lng: 19.6034262 },
    { lat: 49.0313913, lng: 19.6031311 },
    { lat: 49.0317534, lng: 19.6032187 },
    { lat: 49.0318312, lng: 19.603044 },
    { lat: 49.0319698, lng: 19.6028906 },
    { lat: 49.032096, lng: 19.6028678 },
    { lat: 49.0321979, lng: 19.6028023 },
    { lat: 49.032764, lng: 19.6030152 },
    { lat: 49.0330297, lng: 19.602835 },
    { lat: 49.0332629, lng: 19.6027331 },
    { lat: 49.0336381, lng: 19.602786 },
    { lat: 49.0338335, lng: 19.602946 },
    { lat: 49.0339976, lng: 19.6029368 },
    { lat: 49.034151, lng: 19.6026882 },
    { lat: 49.0343886, lng: 19.6026365 },
    { lat: 49.034568, lng: 19.6027669 },
    { lat: 49.0347187, lng: 19.602637 },
    { lat: 49.0349096, lng: 19.6026189 },
    { lat: 49.0352356, lng: 19.6025545 },
    { lat: 49.035466, lng: 19.602565 },
    { lat: 49.0358133, lng: 19.6027235 },
    { lat: 49.0359328, lng: 19.6026755 },
    { lat: 49.0361576, lng: 19.6026611 },
    { lat: 49.0364311, lng: 19.6026793 },
    { lat: 49.0366996, lng: 19.6027897 },
    { lat: 49.0368711, lng: 19.6031184 },
    { lat: 49.0370874, lng: 19.6034176 },
    { lat: 49.0373309, lng: 19.6033708 },
    { lat: 49.0374863, lng: 19.6032946 },
    { lat: 49.0375514, lng: 19.6032936 },
    { lat: 49.0379589, lng: 19.6036344 },
    { lat: 49.0382504, lng: 19.6038005 },
    { lat: 49.0384067, lng: 19.6039476 },
    { lat: 49.0385289, lng: 19.6039547 },
    { lat: 49.0387904, lng: 19.6039048 },
    { lat: 49.039267, lng: 19.6045595 },
    { lat: 49.0395076, lng: 19.6046228 },
    { lat: 49.0396673, lng: 19.6047926 },
    { lat: 49.0399514, lng: 19.604948 },
    { lat: 49.0400903, lng: 19.6048025 },
    { lat: 49.0402738, lng: 19.6047242 },
    { lat: 49.040438, lng: 19.6049157 },
    { lat: 49.0408002, lng: 19.6050792 },
    { lat: 49.0409566, lng: 19.6051225 },
    { lat: 49.0411955, lng: 19.6052744 },
    { lat: 49.0413253, lng: 19.6055572 },
    { lat: 49.0414694, lng: 19.6057049 },
    { lat: 49.0415397, lng: 19.6059662 },
    { lat: 49.0417595, lng: 19.6064458 },
    { lat: 49.0420234, lng: 19.6067265 },
    { lat: 49.0423762, lng: 19.6072541 },
    { lat: 49.0428543, lng: 19.6076386 },
    { lat: 49.0431324, lng: 19.6081635 },
    { lat: 49.0433561, lng: 19.6081751 },
    { lat: 49.0434206, lng: 19.6082337 },
    { lat: 49.0436362, lng: 19.6084667 },
    { lat: 49.0438006, lng: 19.6085157 },
    { lat: 49.043886, lng: 19.6086616 },
    { lat: 49.0439227, lng: 19.6088481 },
    { lat: 49.044126, lng: 19.6088593 },
    { lat: 49.0443147, lng: 19.6089755 },
    { lat: 49.0445479, lng: 19.6092472 },
    { lat: 49.0448515, lng: 19.6094631 },
    { lat: 49.04529, lng: 19.6100166 },
    { lat: 49.0454702, lng: 19.6103912 },
    { lat: 49.0460756, lng: 19.6109281 },
    { lat: 49.046447, lng: 19.6114423 },
    { lat: 49.046747, lng: 19.6116051 },
    { lat: 49.0468512, lng: 19.6118644 },
    { lat: 49.0470757, lng: 19.6119747 },
    { lat: 49.0472521, lng: 19.6122642 },
    { lat: 49.047556, lng: 19.6125595 },
    { lat: 49.0476646, lng: 19.6125654 },
    { lat: 49.0479064, lng: 19.612833 },
    { lat: 49.0482031, lng: 19.6133216 },
    { lat: 49.0485161, lng: 19.6139989 },
    { lat: 49.0487869, lng: 19.6145647 },
    { lat: 49.0491675, lng: 19.6154036 },
    { lat: 49.0495679, lng: 19.6159137 },
    { lat: 49.0499059, lng: 19.6161919 },
    { lat: 49.0502729, lng: 19.6164613 },
    { lat: 49.0503232, lng: 19.6164787 },
    { lat: 49.050634, lng: 19.6164722 },
    { lat: 49.0507219, lng: 19.6164703 },
    { lat: 49.0509421, lng: 19.6164583 },
    { lat: 49.0509398, lng: 19.6165063 },
    { lat: 49.0509442, lng: 19.6165047 },
    { lat: 49.0509969, lng: 19.6165021 },
    { lat: 49.0510175, lng: 19.6165338 },
    { lat: 49.0509711, lng: 19.6166262 },
    { lat: 49.0509781, lng: 19.6166341 },
    { lat: 49.0508829, lng: 19.6168225 },
    { lat: 49.0504601, lng: 19.6176459 },
    { lat: 49.0505671, lng: 19.617767 },
    { lat: 49.0505723, lng: 19.6177567 },
    { lat: 49.0506934, lng: 19.6178986 },
    { lat: 49.0507243, lng: 19.6179091 },
    { lat: 49.0507838, lng: 19.6179514 },
    { lat: 49.0509375, lng: 19.6181136 },
    { lat: 49.0513077, lng: 19.6185828 },
    { lat: 49.0514055, lng: 19.6187005 },
    { lat: 49.0515601, lng: 19.6189309 },
    { lat: 49.0515726, lng: 19.6189615 },
    { lat: 49.0516126, lng: 19.6190238 },
    { lat: 49.0516335, lng: 19.619036 },
    { lat: 49.0516563, lng: 19.6190703 },
    { lat: 49.0515094, lng: 19.619439 },
    { lat: 49.0519914, lng: 19.6199839 },
    { lat: 49.0522144, lng: 19.620209 },
    { lat: 49.0527511, lng: 19.6207729 },
    { lat: 49.0532824, lng: 19.6212376 },
    { lat: 49.053989, lng: 19.6218702 },
    { lat: 49.0541324, lng: 19.6219308 },
    { lat: 49.0560108, lng: 19.6233171 },
    { lat: 49.0560475, lng: 19.6233028 },
    { lat: 49.0562399, lng: 19.6234496 },
    { lat: 49.0563933, lng: 19.6235533 },
    { lat: 49.0566069, lng: 19.6236425 },
    { lat: 49.0568369, lng: 19.6237491 },
    { lat: 49.0569405, lng: 19.6238483 },
    { lat: 49.0570852, lng: 19.6240118 },
    { lat: 49.0573792, lng: 19.6243873 },
    { lat: 49.057655, lng: 19.6249565 },
    { lat: 49.058038, lng: 19.6254392 },
    { lat: 49.0586777, lng: 19.6259308 },
    { lat: 49.0592481, lng: 19.626416 },
    { lat: 49.0597141, lng: 19.6267636 },
    { lat: 49.0596683, lng: 19.6256396 },
    { lat: 49.0596909, lng: 19.6255654 },
    { lat: 49.059794, lng: 19.6257799 },
    { lat: 49.0600498, lng: 19.626056 },
    { lat: 49.0604215, lng: 19.6261047 },
    { lat: 49.0606114, lng: 19.6264709 },
    { lat: 49.0607444, lng: 19.6264945 },
    { lat: 49.0608874, lng: 19.6264175 },
    { lat: 49.061155, lng: 19.6266848 },
    { lat: 49.061295, lng: 19.6267432 },
    { lat: 49.0613152, lng: 19.6267867 },
    { lat: 49.0612764, lng: 19.6269819 },
    { lat: 49.0614615, lng: 19.6269864 },
    { lat: 49.0614983, lng: 19.62708 },
    { lat: 49.0617086, lng: 19.6271214 },
    { lat: 49.0618003, lng: 19.6272556 },
    { lat: 49.0619667, lng: 19.6273232 },
    { lat: 49.0621207, lng: 19.6274664 },
    { lat: 49.0621261, lng: 19.6275136 },
    { lat: 49.0621076, lng: 19.6277009 },
    { lat: 49.0621988, lng: 19.6278255 },
    { lat: 49.0625122, lng: 19.6281322 },
    { lat: 49.0627884, lng: 19.6282635 },
    { lat: 49.0627977, lng: 19.6285205 },
    { lat: 49.0629584, lng: 19.6284129 },
    { lat: 49.0633033, lng: 19.6282896 },
    { lat: 49.0638174, lng: 19.628799 },
    { lat: 49.0642756, lng: 19.6285916 },
    { lat: 49.0645684, lng: 19.6290898 },
    { lat: 49.0645644, lng: 19.6292264 },
    { lat: 49.0645771, lng: 19.6293106 },
    { lat: 49.0646456, lng: 19.6295062 },
    { lat: 49.0648077, lng: 19.6295307 },
    { lat: 49.0649346, lng: 19.6292912 },
    { lat: 49.0651467, lng: 19.6295258 },
    { lat: 49.0652016, lng: 19.6295179 },
    { lat: 49.0652502, lng: 19.629648 },
    { lat: 49.0654775, lng: 19.6298695 },
    { lat: 49.0654769, lng: 19.6301347 },
    { lat: 49.0657185, lng: 19.630095 },
    { lat: 49.0659164, lng: 19.6299857 },
    { lat: 49.0660924, lng: 19.6303947 },
    { lat: 49.0661021, lng: 19.6308382 },
    { lat: 49.0664119, lng: 19.6309876 },
    { lat: 49.0665284, lng: 19.6308936 },
    { lat: 49.066702, lng: 19.6309748 },
    { lat: 49.0669551, lng: 19.6312184 },
    { lat: 49.0671196, lng: 19.6315445 },
    { lat: 49.0673592, lng: 19.6314105 },
    { lat: 49.0674244, lng: 19.6314544 },
    { lat: 49.0675654, lng: 19.6316605 },
    { lat: 49.0675687, lng: 19.6316845 },
    { lat: 49.0675584, lng: 19.6317127 },
    { lat: 49.0668688, lng: 19.632361 },
    { lat: 49.0663192, lng: 19.6343589 },
    { lat: 49.066576, lng: 19.6361972 },
    { lat: 49.0667836, lng: 19.6376891 },
    { lat: 49.0668775, lng: 19.6383341 },
    { lat: 49.0665792, lng: 19.6397023 },
    { lat: 49.0662688, lng: 19.6410243 },
    { lat: 49.0660133, lng: 19.6420398 },
    { lat: 49.0656773, lng: 19.6431995 },
    { lat: 49.0653521, lng: 19.644293 },
    { lat: 49.0657825, lng: 19.6443977 },
    { lat: 49.0680457, lng: 19.645224 },
    { lat: 49.0686858, lng: 19.6454533 },
    { lat: 49.070382, lng: 19.6460766 },
    { lat: 49.0706341, lng: 19.6461706 },
    { lat: 49.0707814, lng: 19.6470979 },
    { lat: 49.0708497, lng: 19.6476963 },
    { lat: 49.0709012, lng: 19.6482972 },
    { lat: 49.070908, lng: 19.6489538 },
    { lat: 49.070899, lng: 19.6498117 },
    { lat: 49.0705143, lng: 19.649851 },
    { lat: 49.0700413, lng: 19.6499067 },
    { lat: 49.0700283, lng: 19.649917 },
    { lat: 49.0700086, lng: 19.6502582 },
    { lat: 49.07015, lng: 19.6518445 },
    { lat: 49.0696701, lng: 19.6524768 },
    { lat: 49.0694162, lng: 19.6528087 },
    { lat: 49.0692752, lng: 19.6529925 },
    { lat: 49.069273, lng: 19.6529974 },
    { lat: 49.0691166, lng: 19.6532004 },
    { lat: 49.0693064, lng: 19.653345 },
    { lat: 49.069185, lng: 19.6536763 },
    { lat: 49.069097, lng: 19.65433 },
    { lat: 49.0691247, lng: 19.6548528 },
    { lat: 49.0691697, lng: 19.6549782 },
    { lat: 49.0697307, lng: 19.6551058 },
    { lat: 49.0696754, lng: 19.6552794 },
    { lat: 49.069666, lng: 19.6552968 },
    { lat: 49.069436, lng: 19.6555193 },
    { lat: 49.0693066, lng: 19.6559807 },
    { lat: 49.0692256, lng: 19.6565629 },
    { lat: 49.0690518, lng: 19.6581284 },
    { lat: 49.0689594, lng: 19.6589643 },
    { lat: 49.0695355, lng: 19.6590243 },
    { lat: 49.069716, lng: 19.6590538 },
    { lat: 49.069831, lng: 19.6590549 },
    { lat: 49.0698463, lng: 19.6590692 },
    { lat: 49.0705143, lng: 19.6591463 },
    { lat: 49.070491, lng: 19.6593068 },
    { lat: 49.0705104, lng: 19.6595164 },
    { lat: 49.0705133, lng: 19.6597547 },
    { lat: 49.0705619, lng: 19.6600102 },
    { lat: 49.0705858, lng: 19.6600958 },
    { lat: 49.0707153, lng: 19.6604238 },
    { lat: 49.0708218, lng: 19.6605343 },
    { lat: 49.0710508, lng: 19.660897 },
    { lat: 49.0712613, lng: 19.6612781 },
    { lat: 49.0714783, lng: 19.6616709 },
    { lat: 49.0715805, lng: 19.6617915 },
    { lat: 49.0725522, lng: 19.6625615 },
    { lat: 49.0727453, lng: 19.6626997 },
    { lat: 49.0730123, lng: 19.6629281 },
    { lat: 49.0731629, lng: 19.6631976 },
    { lat: 49.0732503, lng: 19.6634383 },
    { lat: 49.0734636, lng: 19.6646421 },
    { lat: 49.0735146, lng: 19.6648223 },
    { lat: 49.0736079, lng: 19.664946 },
    { lat: 49.0736833, lng: 19.6649972 },
    { lat: 49.0737198, lng: 19.665005 },
    { lat: 49.0738549, lng: 19.6651064 },
    { lat: 49.0739051, lng: 19.6651954 },
    { lat: 49.0739327, lng: 19.6653323 },
    { lat: 49.0739313, lng: 19.6654292 },
    { lat: 49.0738445, lng: 19.6660378 },
    { lat: 49.0735409, lng: 19.6660563 },
    { lat: 49.0738306, lng: 19.6661345 },
    { lat: 49.073743, lng: 19.6667458 },
    { lat: 49.0737383, lng: 19.6668192 },
    { lat: 49.0737974, lng: 19.6672623 },
    { lat: 49.0737886, lng: 19.6674961 },
    { lat: 49.0738084, lng: 19.6676863 },
    { lat: 49.0743522, lng: 19.6697377 },
    { lat: 49.0743835, lng: 19.6698584 },
    { lat: 49.0744733, lng: 19.6701043 },
    { lat: 49.0746283, lng: 19.6703202 },
    { lat: 49.0751272, lng: 19.6708256 },
    { lat: 49.0754177, lng: 19.6711274 },
    { lat: 49.0756182, lng: 19.6713799 },
    { lat: 49.0758733, lng: 19.6717764 },
    { lat: 49.0758967, lng: 19.6718128 },
    { lat: 49.0760113, lng: 19.6719117 },
    { lat: 49.0764469, lng: 19.6723589 },
    { lat: 49.0767336, lng: 19.6729522 },
    { lat: 49.0768738, lng: 19.673096 },
    { lat: 49.076989, lng: 19.6732013 },
    { lat: 49.0770987, lng: 19.673264 },
    { lat: 49.0770556, lng: 19.6733835 },
    { lat: 49.077078, lng: 19.6734311 },
    { lat: 49.0771914, lng: 19.6735772 },
    { lat: 49.0772654, lng: 19.6737705 },
    { lat: 49.0773449, lng: 19.6738324 },
    { lat: 49.0774592, lng: 19.6738714 },
    { lat: 49.0775111, lng: 19.673769 },
    { lat: 49.0775382, lng: 19.6737727 },
    { lat: 49.0776344, lng: 19.6736536 },
    { lat: 49.0776551, lng: 19.6736622 },
    { lat: 49.0776939, lng: 19.6737272 },
    { lat: 49.0776592, lng: 19.673807 },
    { lat: 49.0776198, lng: 19.6738351 },
    { lat: 49.077777, lng: 19.6739987 },
    { lat: 49.0778779, lng: 19.6742413 },
    { lat: 49.077916, lng: 19.6743132 },
    { lat: 49.0779295, lng: 19.6743143 },
    { lat: 49.0778968, lng: 19.6744658 },
    { lat: 49.0779022, lng: 19.6744846 },
    { lat: 49.0781667, lng: 19.6745031 },
    { lat: 49.0782974, lng: 19.6745806 },
    { lat: 49.0784585, lng: 19.674609 },
    { lat: 49.0787112, lng: 19.6747394 },
    { lat: 49.0788714, lng: 19.6748983 },
    { lat: 49.0791085, lng: 19.674884 },
    { lat: 49.0791936, lng: 19.6747729 },
    { lat: 49.0791668, lng: 19.6745481 },
    { lat: 49.0791783, lng: 19.6745297 },
    { lat: 49.079266, lng: 19.674548 },
    { lat: 49.0792773, lng: 19.6745537 },
    { lat: 49.0793561, lng: 19.6747001 },
    { lat: 49.0794456, lng: 19.6747391 },
    { lat: 49.0794489, lng: 19.6747603 },
    { lat: 49.0794158, lng: 19.6748022 },
    { lat: 49.0794187, lng: 19.6748191 },
    { lat: 49.0794526, lng: 19.674844 },
    { lat: 49.079582, lng: 19.6747908 },
    { lat: 49.0796667, lng: 19.6748684 },
    { lat: 49.079678, lng: 19.6748734 },
    { lat: 49.0797576, lng: 19.6748634 },
    { lat: 49.0797769, lng: 19.6748682 },
    { lat: 49.0799719, lng: 19.6749948 },
    { lat: 49.080019, lng: 19.6749922 },
    { lat: 49.0802048, lng: 19.6750669 },
    { lat: 49.0803406, lng: 19.6750519 },
    { lat: 49.0804345, lng: 19.6752165 },
    { lat: 49.0804514, lng: 19.6752148 },
    { lat: 49.0806136, lng: 19.6751126 },
    { lat: 49.0806936, lng: 19.6747824 },
    { lat: 49.0807072, lng: 19.6747846 },
    { lat: 49.0807564, lng: 19.6748787 },
    { lat: 49.0808504, lng: 19.6751929 },
    { lat: 49.0808691, lng: 19.6752107 },
    { lat: 49.080927, lng: 19.6751915 },
    { lat: 49.0810559, lng: 19.6752817 },
    { lat: 49.0811643, lng: 19.675234 },
    { lat: 49.0812956, lng: 19.6752981 },
    { lat: 49.0812717, lng: 19.6754192 },
    { lat: 49.0813702, lng: 19.6755338 },
    { lat: 49.0814497, lng: 19.6755486 },
    { lat: 49.0815544, lng: 19.6755184 },
    { lat: 49.0816113, lng: 19.6754332 },
    { lat: 49.0816924, lng: 19.675942 },
    { lat: 49.0817066, lng: 19.6759542 },
    { lat: 49.081784, lng: 19.676075 },
    { lat: 49.0819044, lng: 19.676022 },
    { lat: 49.0821758, lng: 19.6760674 },
    { lat: 49.0826237, lng: 19.6759575 },
    { lat: 49.0825321, lng: 19.6761757 },
    { lat: 49.0834259, lng: 19.6771295 },
    { lat: 49.0835218, lng: 19.67723 },
    { lat: 49.0845279, lng: 19.6783006 },
    { lat: 49.0846429, lng: 19.6784552 },
    { lat: 49.0848965, lng: 19.6785848 },
    { lat: 49.0851943, lng: 19.6786197 },
    { lat: 49.0852447, lng: 19.6786375 },
    { lat: 49.085308, lng: 19.6787435 },
    { lat: 49.0855936, lng: 19.6789611 },
    { lat: 49.0855807, lng: 19.6790214 },
    { lat: 49.0857447, lng: 19.6790905 },
    { lat: 49.0857488, lng: 19.6791282 },
    { lat: 49.0857418, lng: 19.6791522 },
    { lat: 49.0855922, lng: 19.6791805 },
    { lat: 49.0855744, lng: 19.6792381 },
    { lat: 49.0855889, lng: 19.67926 },
    { lat: 49.0857786, lng: 19.6794227 },
    { lat: 49.0858934, lng: 19.679394 },
    { lat: 49.0859357, lng: 19.6794098 },
    { lat: 49.0859033, lng: 19.6795965 },
    { lat: 49.0859709, lng: 19.6797204 },
    { lat: 49.0861067, lng: 19.6797593 },
    { lat: 49.0862659, lng: 19.6796901 },
    { lat: 49.0864013, lng: 19.6795443 },
    { lat: 49.0864372, lng: 19.6794641 },
    { lat: 49.0865185, lng: 19.6795409 },
    { lat: 49.0866947, lng: 19.6796019 },
    { lat: 49.0867933, lng: 19.6797431 },
    { lat: 49.0873267, lng: 19.6798304 },
    { lat: 49.087486, lng: 19.6798676 },
    { lat: 49.0874122, lng: 19.6799341 },
    { lat: 49.0872874, lng: 19.680091 },
    { lat: 49.0872784, lng: 19.6801157 },
    { lat: 49.0873242, lng: 19.680414 },
    { lat: 49.0874285, lng: 19.6806315 },
    { lat: 49.0875, lng: 19.6806167 },
    { lat: 49.0875683, lng: 19.680633 },
    { lat: 49.0875757, lng: 19.6806998 },
    { lat: 49.0875719, lng: 19.680918 },
    { lat: 49.0873433, lng: 19.6814537 },
    { lat: 49.0873502, lng: 19.6814835 },
    { lat: 49.0874053, lng: 19.6815803 },
    { lat: 49.087583, lng: 19.6816531 },
    { lat: 49.0876006, lng: 19.6816401 },
    { lat: 49.0877267, lng: 19.6814388 },
    { lat: 49.0879089, lng: 19.6815822 },
    { lat: 49.0879786, lng: 19.6816581 },
    { lat: 49.0880629, lng: 19.6816995 },
    { lat: 49.0881267, lng: 19.6818989 },
    { lat: 49.0882023, lng: 19.6821043 },
    { lat: 49.0882435, lng: 19.6824203 },
    { lat: 49.0882587, lng: 19.6824328 },
    { lat: 49.0884434, lng: 19.6824358 },
    { lat: 49.0884759, lng: 19.682454 },
    { lat: 49.0885382, lng: 19.6824642 },
    { lat: 49.0885639, lng: 19.6824845 },
    { lat: 49.0885342, lng: 19.6826259 },
    { lat: 49.088457, lng: 19.6827424 },
    { lat: 49.0883239, lng: 19.6828391 },
    { lat: 49.0883308, lng: 19.6828672 },
    { lat: 49.0884707, lng: 19.6829502 },
    { lat: 49.0887229, lng: 19.6831494 },
    { lat: 49.0887571, lng: 19.6831602 },
    { lat: 49.0887299, lng: 19.6832335 },
    { lat: 49.0887168, lng: 19.6835997 },
    { lat: 49.0890074, lng: 19.6840526 },
    { lat: 49.0890073, lng: 19.6842043 },
    { lat: 49.0891613, lng: 19.6844793 },
    { lat: 49.089196, lng: 19.6848324 },
    { lat: 49.0895576, lng: 19.6856958 },
    { lat: 49.0895732, lng: 19.6856898 },
    { lat: 49.0897264, lng: 19.686021 },
    { lat: 49.0897348, lng: 19.6861834 },
    { lat: 49.0897087, lng: 19.6862083 },
    { lat: 49.0896787, lng: 19.6863936 },
    { lat: 49.0897869, lng: 19.6863962 },
    { lat: 49.0899189, lng: 19.6862213 },
    { lat: 49.0900166, lng: 19.6862134 },
    { lat: 49.0900595, lng: 19.6862665 },
    { lat: 49.0900895, lng: 19.6863627 },
    { lat: 49.0899238, lng: 19.6866137 },
    { lat: 49.0900607, lng: 19.6868329 },
    { lat: 49.0901329, lng: 19.6867842 },
    { lat: 49.0901596, lng: 19.6867746 },
    { lat: 49.0901869, lng: 19.686857 },
    { lat: 49.0903101, lng: 19.6869677 },
    { lat: 49.0903922, lng: 19.6869129 },
    { lat: 49.0905145, lng: 19.6869511 },
    { lat: 49.0905164, lng: 19.6869933 },
    { lat: 49.090473, lng: 19.6871613 },
    { lat: 49.0904723, lng: 19.6871977 },
    { lat: 49.0903938, lng: 19.6872535 },
    { lat: 49.0903906, lng: 19.6872826 },
    { lat: 49.0904789, lng: 19.6874713 },
    { lat: 49.0904608, lng: 19.6875997 },
    { lat: 49.090535, lng: 19.6876176 },
    { lat: 49.0905627, lng: 19.6876841 },
    { lat: 49.0905798, lng: 19.6876928 },
    { lat: 49.0906516, lng: 19.6875582 },
    { lat: 49.0906613, lng: 19.6874691 },
    { lat: 49.0907498, lng: 19.6873835 },
    { lat: 49.090774, lng: 19.687395 },
    { lat: 49.0907909, lng: 19.6875752 },
    { lat: 49.0907699, lng: 19.6878106 },
    { lat: 49.0907756, lng: 19.6878384 },
    { lat: 49.0908873, lng: 19.6879183 },
    { lat: 49.0909271, lng: 19.6879279 },
    { lat: 49.0909647, lng: 19.6879607 },
    { lat: 49.091054, lng: 19.6879936 },
    { lat: 49.0910976, lng: 19.6881153 },
    { lat: 49.0910643, lng: 19.6882809 },
    { lat: 49.0910782, lng: 19.6884948 },
    { lat: 49.0911398, lng: 19.6885578 },
    { lat: 49.0911533, lng: 19.6885582 },
    { lat: 49.091147, lng: 19.6886728 },
    { lat: 49.0911648, lng: 19.688717 },
    { lat: 49.0912239, lng: 19.6888037 },
    { lat: 49.0913349, lng: 19.6887679 },
    { lat: 49.0913499, lng: 19.6887757 },
    { lat: 49.091478, lng: 19.6889711 },
    { lat: 49.0915292, lng: 19.6890101 },
    { lat: 49.0915429, lng: 19.6890133 },
    { lat: 49.0916907, lng: 19.6889389 },
    { lat: 49.0918716, lng: 19.6889563 },
    { lat: 49.0920286, lng: 19.6893479 },
    { lat: 49.0920446, lng: 19.689352 },
    { lat: 49.0920647, lng: 19.6893262 },
    { lat: 49.0920854, lng: 19.6892536 },
    { lat: 49.0921986, lng: 19.6892204 },
    { lat: 49.0922031, lng: 19.6892408 },
    { lat: 49.092211, lng: 19.6895215 },
    { lat: 49.0922206, lng: 19.6895356 },
    { lat: 49.0923335, lng: 19.6895967 },
    { lat: 49.0923491, lng: 19.6895918 },
    { lat: 49.0924591, lng: 19.6893771 },
    { lat: 49.0924722, lng: 19.68937 },
    { lat: 49.0926058, lng: 19.689485 },
    { lat: 49.0927362, lng: 19.6896809 },
    { lat: 49.0927927, lng: 19.6896618 },
    { lat: 49.0929284, lng: 19.6898992 },
    { lat: 49.0930611, lng: 19.6899701 },
    { lat: 49.0931079, lng: 19.6900616 },
    { lat: 49.0931922, lng: 19.690085 },
    { lat: 49.0932399, lng: 19.6901973 },
    { lat: 49.0934495, lng: 19.6903721 },
    { lat: 49.0934262, lng: 19.6904856 },
    { lat: 49.0934281, lng: 19.6905023 },
    { lat: 49.0935217, lng: 19.6906332 },
    { lat: 49.0936457, lng: 19.6906554 },
    { lat: 49.0937804, lng: 19.6908789 },
    { lat: 49.0938975, lng: 19.6910542 },
    { lat: 49.0939538, lng: 19.6911534 },
    { lat: 49.0940134, lng: 19.6911509 },
    { lat: 49.0941127, lng: 19.691003 },
    { lat: 49.094126, lng: 19.6910006 },
    { lat: 49.0941517, lng: 19.6910217 },
    { lat: 49.0942545, lng: 19.6912272 },
    { lat: 49.0943816, lng: 19.6911757 },
    { lat: 49.0944398, lng: 19.6912165 },
    { lat: 49.0946028, lng: 19.6911815 },
    { lat: 49.0946531, lng: 19.691277 },
    { lat: 49.0946451, lng: 19.6912997 },
    { lat: 49.094714, lng: 19.6914311 },
    { lat: 49.0946512, lng: 19.6915666 },
    { lat: 49.0946391, lng: 19.6915693 },
    { lat: 49.0947502, lng: 19.6918131 },
    { lat: 49.0948442, lng: 19.6920077 },
    { lat: 49.0950362, lng: 19.6919392 },
    { lat: 49.0950478, lng: 19.6919437 },
    { lat: 49.0953331, lng: 19.6922359 },
    { lat: 49.0954928, lng: 19.6925596 },
    { lat: 49.0956487, lng: 19.6927745 },
    { lat: 49.0959642, lng: 19.6930018 },
    { lat: 49.096028, lng: 19.6929931 },
    { lat: 49.0961565, lng: 19.69315 },
    { lat: 49.0971576, lng: 19.6940358 },
    { lat: 49.0977455, lng: 19.694411 },
    { lat: 49.0981257, lng: 19.6942959 },
    { lat: 49.0984001, lng: 19.6942595 },
    { lat: 49.0984963, lng: 19.6941185 },
    { lat: 49.098584, lng: 19.6940344 },
    { lat: 49.0990007, lng: 19.6935495 },
    { lat: 49.0998134, lng: 19.6926908 },
    { lat: 49.0994979, lng: 19.6901579 },
    { lat: 49.0995252, lng: 19.6878674 },
    { lat: 49.0993896, lng: 19.6865898 },
    { lat: 49.0997718, lng: 19.685723 },
    { lat: 49.0998158, lng: 19.6856509 },
    { lat: 49.1005241, lng: 19.685492 },
    { lat: 49.1006174, lng: 19.6853847 },
    { lat: 49.1006513, lng: 19.6848484 },
    { lat: 49.1006866, lng: 19.6846279 },
    { lat: 49.1008214, lng: 19.6843121 },
    { lat: 49.1007551, lng: 19.6829135 },
    { lat: 49.099427, lng: 19.681444 },
    { lat: 49.0994388, lng: 19.6797728 },
    { lat: 49.0993126, lng: 19.6796469 },
    { lat: 49.0991568, lng: 19.6793861 },
    { lat: 49.0991104, lng: 19.6793556 },
    { lat: 49.0990333, lng: 19.679343 },
    { lat: 49.0988764, lng: 19.6794627 },
    { lat: 49.0987342, lng: 19.6794331 },
    { lat: 49.0986965, lng: 19.6794463 },
    { lat: 49.0986512, lng: 19.6793938 },
    { lat: 49.0986047, lng: 19.6794352 },
    { lat: 49.0985665, lng: 19.6794373 },
    { lat: 49.0985353, lng: 19.6793675 },
    { lat: 49.0984689, lng: 19.6793168 },
    { lat: 49.0984322, lng: 19.6792293 },
    { lat: 49.0983834, lng: 19.6792206 },
    { lat: 49.098316, lng: 19.6793519 },
    { lat: 49.0982105, lng: 19.6793092 },
    { lat: 49.0981309, lng: 19.6793461 },
    { lat: 49.0980694, lng: 19.6793061 },
    { lat: 49.0980186, lng: 19.6793523 },
    { lat: 49.0979011, lng: 19.6793708 },
    { lat: 49.0978498, lng: 19.6795339 },
    { lat: 49.0977885, lng: 19.6795262 },
    { lat: 49.0977417, lng: 19.6795619 },
    { lat: 49.0976939, lng: 19.6795269 },
    { lat: 49.0976517, lng: 19.6794647 },
    { lat: 49.0975479, lng: 19.6794606 },
    { lat: 49.0974875, lng: 19.6794193 },
    { lat: 49.0974374, lng: 19.6794547 },
    { lat: 49.0973759, lng: 19.6794161 },
    { lat: 49.0973131, lng: 19.6795002 },
    { lat: 49.0973623, lng: 19.678858 },
    { lat: 49.0973919, lng: 19.6786077 },
    { lat: 49.0974219, lng: 19.6782152 },
    { lat: 49.0975695, lng: 19.6773516 },
    { lat: 49.0976227, lng: 19.6771772 },
    { lat: 49.097629, lng: 19.6766858 },
    { lat: 49.0976506, lng: 19.6765599 },
    { lat: 49.0977115, lng: 19.6764109 },
    { lat: 49.0977155, lng: 19.6761703 },
    { lat: 49.097801, lng: 19.6756068 },
    { lat: 49.0977908, lng: 19.6754561 },
    { lat: 49.0977274, lng: 19.6752448 },
    { lat: 49.0977927, lng: 19.6750885 },
    { lat: 49.0977884, lng: 19.6747618 },
    { lat: 49.097874, lng: 19.6745358 },
    { lat: 49.0979024, lng: 19.6742601 },
    { lat: 49.0978937, lng: 19.674138 },
    { lat: 49.0978996, lng: 19.6739688 },
    { lat: 49.097881, lng: 19.6737021 },
    { lat: 49.0978914, lng: 19.6734523 },
    { lat: 49.0978869, lng: 19.6732974 },
    { lat: 49.0979078, lng: 19.6728052 },
    { lat: 49.097935, lng: 19.6725037 },
    { lat: 49.0979206, lng: 19.6723865 },
    { lat: 49.0979405, lng: 19.6722493 },
    { lat: 49.0979223, lng: 19.6720894 },
    { lat: 49.0979341, lng: 19.6719505 },
    { lat: 49.0979206, lng: 19.6718742 },
    { lat: 49.0979178, lng: 19.6718116 },
    { lat: 49.0979419, lng: 19.6716944 },
    { lat: 49.0978998, lng: 19.671277 },
    { lat: 49.0979835, lng: 19.6703477 },
    { lat: 49.0984196, lng: 19.6682887 },
    { lat: 49.0979343, lng: 19.6680945 },
    { lat: 49.0976037, lng: 19.6679071 },
    { lat: 49.0973244, lng: 19.6677039 },
    { lat: 49.0969635, lng: 19.6675408 },
    { lat: 49.0968182, lng: 19.6674899 },
    { lat: 49.0966531, lng: 19.6674512 },
    { lat: 49.0963009, lng: 19.6674378 },
    { lat: 49.0960658, lng: 19.6673951 },
    { lat: 49.0957543, lng: 19.6674351 },
    { lat: 49.0956275, lng: 19.6674981 },
    { lat: 49.0952528, lng: 19.6678659 },
    { lat: 49.095008, lng: 19.6679307 },
    { lat: 49.0946626, lng: 19.6678659 },
    { lat: 49.0946718, lng: 19.6678175 },
    { lat: 49.0949946, lng: 19.6652171 },
    { lat: 49.0952134, lng: 19.6640581 },
    { lat: 49.0967347, lng: 19.6647205 },
    { lat: 49.0965456, lng: 19.6641206 },
    { lat: 49.096514, lng: 19.6636104 },
    { lat: 49.0965548, lng: 19.6620154 },
    { lat: 49.0965847, lng: 19.661294 },
    { lat: 49.0966053, lng: 19.6611006 },
    { lat: 49.0966635, lng: 19.6607354 },
    { lat: 49.0967018, lng: 19.6605826 },
    { lat: 49.0967725, lng: 19.6603992 },
    { lat: 49.0968703, lng: 19.6602946 },
    { lat: 49.0969874, lng: 19.6602412 },
    { lat: 49.0973761, lng: 19.660318 },
    { lat: 49.0977458, lng: 19.6602695 },
    { lat: 49.0977858, lng: 19.6602082 },
    { lat: 49.0977862, lng: 19.6601114 },
    { lat: 49.0976692, lng: 19.659811 },
    { lat: 49.0977328, lng: 19.6596481 },
    { lat: 49.0970288, lng: 19.6592712 },
    { lat: 49.0968543, lng: 19.6590958 },
    { lat: 49.0964107, lng: 19.6581913 },
    { lat: 49.0963781, lng: 19.6581173 },
    { lat: 49.0964266, lng: 19.6580929 },
    { lat: 49.0965287, lng: 19.6581586 },
    { lat: 49.096923, lng: 19.6584887 },
    { lat: 49.0969899, lng: 19.6584703 },
    { lat: 49.0969803, lng: 19.6584569 },
    { lat: 49.0971473, lng: 19.6584145 },
    { lat: 49.0972247, lng: 19.6584827 },
    { lat: 49.0972818, lng: 19.6588008 },
    { lat: 49.0974186, lng: 19.6588413 },
    { lat: 49.0976007, lng: 19.6588542 },
    { lat: 49.0977102, lng: 19.6589378 },
    { lat: 49.098181, lng: 19.6596192 },
    { lat: 49.0983874, lng: 19.6596986 },
    { lat: 49.0986842, lng: 19.6596587 },
    { lat: 49.0987607, lng: 19.6597065 },
    { lat: 49.099189, lng: 19.6600147 },
    { lat: 49.0994547, lng: 19.6600118 },
    { lat: 49.099601, lng: 19.6599838 },
    { lat: 49.0998123, lng: 19.6599944 },
    { lat: 49.1003768, lng: 19.6598927 },
    { lat: 49.1006412, lng: 19.6599147 },
    { lat: 49.1009175, lng: 19.6597683 },
    { lat: 49.1009339, lng: 19.6597533 },
    { lat: 49.1009162, lng: 19.6596646 },
    { lat: 49.1006972, lng: 19.658104 },
    { lat: 49.1005267, lng: 19.6567798 },
    { lat: 49.1003054, lng: 19.6551954 },
    { lat: 49.1002235, lng: 19.6546491 },
    { lat: 49.0997313, lng: 19.6515352 },
    { lat: 49.0993976, lng: 19.6494059 },
    { lat: 49.0990954, lng: 19.6475038 },
    { lat: 49.0989378, lng: 19.646439 },
    { lat: 49.0989205, lng: 19.6452456 },
    { lat: 49.0989189, lng: 19.6447543 },
    { lat: 49.0989405, lng: 19.644481 },
    { lat: 49.09914, lng: 19.6430136 },
    { lat: 49.0992032, lng: 19.6427377 },
    { lat: 49.0992351, lng: 19.6423666 },
    { lat: 49.0992385, lng: 19.6403733 },
    { lat: 49.0992373, lng: 19.6390593 },
    { lat: 49.0993096, lng: 19.639013 },
    { lat: 49.0993087, lng: 19.6389667 },
    { lat: 49.0993427, lng: 19.638919 },
    { lat: 49.099539, lng: 19.638345 },
    { lat: 49.0997291, lng: 19.6377051 },
    { lat: 49.0999388, lng: 19.6372318 },
    { lat: 49.1001936, lng: 19.6368038 },
    { lat: 49.1004547, lng: 19.6363701 },
    { lat: 49.1007421, lng: 19.6357888 },
    { lat: 49.1007791, lng: 19.6356606 },
    { lat: 49.1010015, lng: 19.6348138 },
    { lat: 49.1012221, lng: 19.6341768 },
    { lat: 49.1012979, lng: 19.633781 },
    { lat: 49.1013855, lng: 19.633347 },
    { lat: 49.1014477, lng: 19.6330209 },
    { lat: 49.1016698, lng: 19.6321435 },
    { lat: 49.1018263, lng: 19.6316613 },
    { lat: 49.1018595, lng: 19.6315482 },
    { lat: 49.1020132, lng: 19.6311774 },
    { lat: 49.102173, lng: 19.630898 },
    { lat: 49.1024107, lng: 19.6305947 },
    { lat: 49.1026921, lng: 19.6302624 },
    { lat: 49.1028474, lng: 19.6301021 },
    { lat: 49.1028612, lng: 19.6301356 },
    { lat: 49.102866, lng: 19.6301426 },
    { lat: 49.1029585, lng: 19.6300202 },
    { lat: 49.1030457, lng: 19.6300007 },
    { lat: 49.1029586, lng: 19.6296958 },
    { lat: 49.1030722, lng: 19.6295629 },
    { lat: 49.1030004, lng: 19.6294682 },
    { lat: 49.1028373, lng: 19.6294006 },
    { lat: 49.1028066, lng: 19.6293682 },
    { lat: 49.1027787, lng: 19.629325 },
    { lat: 49.1027675, lng: 19.6292755 },
    { lat: 49.1027279, lng: 19.6292706 },
    { lat: 49.1026941, lng: 19.6291961 },
    { lat: 49.1026574, lng: 19.6289773 },
    { lat: 49.1026832, lng: 19.6288531 },
    { lat: 49.1026603, lng: 19.6287418 },
    { lat: 49.1027167, lng: 19.6284127 },
    { lat: 49.1026817, lng: 19.6283373 },
    { lat: 49.1025277, lng: 19.6282932 },
    { lat: 49.1024602, lng: 19.6283214 },
    { lat: 49.1023681, lng: 19.6282245 },
    { lat: 49.1023304, lng: 19.6281162 },
    { lat: 49.1023512, lng: 19.6280734 },
    { lat: 49.1023211, lng: 19.6280079 },
    { lat: 49.1022062, lng: 19.6279831 },
    { lat: 49.1021587, lng: 19.6279291 },
    { lat: 49.1021153, lng: 19.6278142 },
    { lat: 49.1019447, lng: 19.6279541 },
    { lat: 49.1018618, lng: 19.6279153 },
    { lat: 49.1018345, lng: 19.6278343 },
    { lat: 49.1018978, lng: 19.6276868 },
    { lat: 49.1018047, lng: 19.6275695 },
    { lat: 49.1017116, lng: 19.6275817 },
    { lat: 49.1016957, lng: 19.6273475 },
    { lat: 49.1014346, lng: 19.6273336 },
    { lat: 49.1013002, lng: 19.627299 },
    { lat: 49.1012015, lng: 19.6273358 },
    { lat: 49.1011458, lng: 19.627146 },
    { lat: 49.1010867, lng: 19.6270867 },
    { lat: 49.1010922, lng: 19.6268829 },
    { lat: 49.1011164, lng: 19.6268434 },
    { lat: 49.1011514, lng: 19.6267175 },
    { lat: 49.1010397, lng: 19.6266647 },
    { lat: 49.1010112, lng: 19.6265551 },
    { lat: 49.1009326, lng: 19.6263335 },
    { lat: 49.1008822, lng: 19.626214 },
    { lat: 49.100809, lng: 19.6261144 },
    { lat: 49.1006942, lng: 19.6262195 },
    { lat: 49.100619, lng: 19.6261525 },
    { lat: 49.1006726, lng: 19.6260365 },
    { lat: 49.1006044, lng: 19.625926 },
    { lat: 49.1006898, lng: 19.6258194 },
    { lat: 49.1005448, lng: 19.6258499 },
    { lat: 49.1004392, lng: 19.6258054 },
    { lat: 49.1004705, lng: 19.6256501 },
    { lat: 49.1006447, lng: 19.625618 },
    { lat: 49.1004674, lng: 19.6253808 },
    { lat: 49.1004915, lng: 19.6253135 },
    { lat: 49.1006094, lng: 19.6253567 },
    { lat: 49.10062, lng: 19.6252405 },
    { lat: 49.1005382, lng: 19.6249466 },
    { lat: 49.1003883, lng: 19.6248219 },
    { lat: 49.1002428, lng: 19.6250409 },
    { lat: 49.1001148, lng: 19.6248762 },
    { lat: 49.1001594, lng: 19.6247662 },
    { lat: 49.1000476, lng: 19.6244574 },
    { lat: 49.1000639, lng: 19.6243206 },
    { lat: 49.0999599, lng: 19.6240574 },
    { lat: 49.0998078, lng: 19.6239825 },
    { lat: 49.0997816, lng: 19.6241266 },
    { lat: 49.0997071, lng: 19.6242012 },
    { lat: 49.0996562, lng: 19.6240716 },
    { lat: 49.0996815, lng: 19.6239834 },
    { lat: 49.0998778, lng: 19.6238858 },
    { lat: 49.1000206, lng: 19.6236782 },
    { lat: 49.1000519, lng: 19.6236236 },
    { lat: 49.100375, lng: 19.623215 },
    { lat: 49.1007094, lng: 19.6228042 },
    { lat: 49.1010864, lng: 19.6223182 },
    { lat: 49.1016023, lng: 19.6218874 },
    { lat: 49.1022215, lng: 19.6212741 },
    { lat: 49.1023946, lng: 19.6209846 },
    { lat: 49.102448, lng: 19.6207436 },
    { lat: 49.1020838, lng: 19.6206138 },
    { lat: 49.101906, lng: 19.6201645 },
    { lat: 49.1043263, lng: 19.6197399 },
    { lat: 49.1042352, lng: 19.6192171 },
    { lat: 49.1046365, lng: 19.6189148 },
    { lat: 49.1042123, lng: 19.6141996 },
    { lat: 49.1041932, lng: 19.6140269 },
    { lat: 49.1041977, lng: 19.6139254 },
    { lat: 49.1044153, lng: 19.6112166 },
    { lat: 49.1044242, lng: 19.611111 },
    { lat: 49.104515, lng: 19.6104234 },
    { lat: 49.1045976, lng: 19.6099009 },
    { lat: 49.1045137, lng: 19.6097407 },
    { lat: 49.1045357, lng: 19.6097056 },
    { lat: 49.1050349, lng: 19.6090762 },
    { lat: 49.1050966, lng: 19.6091726 },
    { lat: 49.1054125, lng: 19.6089039 },
    { lat: 49.105624, lng: 19.6086977 },
    { lat: 49.1058433, lng: 19.6085401 },
    { lat: 49.1059435, lng: 19.6084368 },
    { lat: 49.1063639, lng: 19.608086 },
    { lat: 49.1074405, lng: 19.6075719 },
    { lat: 49.1066532, lng: 19.6048899 },
    { lat: 49.1059761, lng: 19.6036655 },
    { lat: 49.1056342, lng: 19.6031484 },
    { lat: 49.1030403, lng: 19.59848 },
    { lat: 49.1036793, lng: 19.5968553 },
    { lat: 49.1038552, lng: 19.5964554 },
    { lat: 49.1040284, lng: 19.5960745 },
    { lat: 49.1041016, lng: 19.5959227 },
    { lat: 49.1043011, lng: 19.5956232 },
    { lat: 49.1045705, lng: 19.5952254 },
    { lat: 49.1047594, lng: 19.5948418 },
    { lat: 49.1048427, lng: 19.59459 },
    { lat: 49.1049173, lng: 19.5943427 },
    { lat: 49.1049329, lng: 19.5943386 },
    { lat: 49.1049491, lng: 19.5943516 },
    { lat: 49.1051077, lng: 19.5942919 },
    { lat: 49.1053877, lng: 19.5942296 },
    { lat: 49.1056588, lng: 19.5928453 },
    { lat: 49.1058947, lng: 19.5922266 },
    { lat: 49.1061804, lng: 19.5908629 },
    { lat: 49.1069968, lng: 19.5883017 },
    { lat: 49.1075299, lng: 19.5857287 },
    { lat: 49.1077152, lng: 19.5857381 },
    { lat: 49.1080671, lng: 19.5848968 },
    { lat: 49.1083951, lng: 19.5834794 },
    { lat: 49.1095575, lng: 19.5815417 },
    { lat: 49.1100598, lng: 19.5802879 },
    { lat: 49.1104914, lng: 19.579193 },
    { lat: 49.1106862, lng: 19.5786403 },
    { lat: 49.1108509, lng: 19.5783968 },
    { lat: 49.1105994, lng: 19.5778398 },
    { lat: 49.1106919, lng: 19.5774428 },
    { lat: 49.1105375, lng: 19.5769652 },
    { lat: 49.1105106, lng: 19.5766472 },
    { lat: 49.1100802, lng: 19.5761445 },
    { lat: 49.1096057, lng: 19.5752933 },
    { lat: 49.1091405, lng: 19.5745976 },
    { lat: 49.1092372, lng: 19.5740455 },
    { lat: 49.1092221, lng: 19.5736576 },
    { lat: 49.1088959, lng: 19.5733202 },
    { lat: 49.1086448, lng: 19.5731254 },
    { lat: 49.1085763, lng: 19.572706 },
    { lat: 49.1082984, lng: 19.5725183 },
    { lat: 49.1078179, lng: 19.5723539 },
    { lat: 49.1076255, lng: 19.5722137 },
    { lat: 49.107616, lng: 19.5718522 },
    { lat: 49.107757, lng: 19.5710843 },
    { lat: 49.108367, lng: 19.5693956 },
    { lat: 49.1087446, lng: 19.5691981 },
    { lat: 49.1103792, lng: 19.5676989 },
    { lat: 49.1124285, lng: 19.565227 },
    { lat: 49.1131604, lng: 19.5644449 },
    { lat: 49.1131789, lng: 19.564403 },
    { lat: 49.1140805, lng: 19.5615688 },
    { lat: 49.1156802, lng: 19.5603184 },
    { lat: 49.1164606, lng: 19.5589901 },
    { lat: 49.1162519, lng: 19.5588117 },
    { lat: 49.1160028, lng: 19.5585821 },
    { lat: 49.1158142, lng: 19.5584005 },
    { lat: 49.1153935, lng: 19.5578717 },
    { lat: 49.1149366, lng: 19.5572605 },
    { lat: 49.1146319, lng: 19.5569228 },
    { lat: 49.1142646, lng: 19.5565758 },
    { lat: 49.1140735, lng: 19.556589 },
    { lat: 49.11339, lng: 19.5565958 },
    { lat: 49.1130016, lng: 19.556605 },
    { lat: 49.1125465, lng: 19.5566283 },
    { lat: 49.1121939, lng: 19.5566334 },
    { lat: 49.1119337, lng: 19.5566122 },
    { lat: 49.1113769, lng: 19.5565067 },
    { lat: 49.1111636, lng: 19.5564727 },
    { lat: 49.1104512, lng: 19.5562929 },
    { lat: 49.1099747, lng: 19.5561682 },
    { lat: 49.1100608, lng: 19.55541 },
    { lat: 49.110157, lng: 19.5546004 },
    { lat: 49.1101629, lng: 19.5545329 },
    { lat: 49.1101544, lng: 19.5541198 },
    { lat: 49.1100937, lng: 19.5536522 },
    { lat: 49.1097447, lng: 19.5532609 },
    { lat: 49.1092966, lng: 19.5528171 },
    { lat: 49.109267, lng: 19.5527641 },
    { lat: 49.1089602, lng: 19.5517358 },
    { lat: 49.1085537, lng: 19.5502012 },
    { lat: 49.1083545, lng: 19.5494389 },
    { lat: 49.1082214, lng: 19.5486912 },
    { lat: 49.108115, lng: 19.5481043 },
    { lat: 49.1079363, lng: 19.5476933 },
    { lat: 49.1076853, lng: 19.5474285 },
    { lat: 49.10718, lng: 19.5468703 },
    { lat: 49.1070677, lng: 19.5467793 },
    { lat: 49.107148, lng: 19.5466863 },
    { lat: 49.1069143, lng: 19.5465527 },
    { lat: 49.1066772, lng: 19.5464421 },
    { lat: 49.1063821, lng: 19.5462969 },
    { lat: 49.1060229, lng: 19.5462013 },
    { lat: 49.1053858, lng: 19.546017 },
    { lat: 49.1052843, lng: 19.5461115 },
    { lat: 49.1050953, lng: 19.5459948 },
    { lat: 49.1051313, lng: 19.5455976 },
    { lat: 49.1051507, lng: 19.5454776 },
    { lat: 49.1048267, lng: 19.5452179 },
    { lat: 49.1044473, lng: 19.5450511 },
    { lat: 49.1041511, lng: 19.5449032 },
    { lat: 49.1036125, lng: 19.5446586 },
    { lat: 49.1031991, lng: 19.5445342 },
    { lat: 49.10288, lng: 19.5444531 },
    { lat: 49.1024846, lng: 19.5443315 },
    { lat: 49.1020628, lng: 19.5441983 },
    { lat: 49.1010608, lng: 19.5438924 },
    { lat: 49.1007065, lng: 19.5437863 },
    { lat: 49.0998261, lng: 19.543678 },
    { lat: 49.0992134, lng: 19.5436829 },
    { lat: 49.0990405, lng: 19.5437227 },
    { lat: 49.0991475, lng: 19.5444881 },
    { lat: 49.0991038, lng: 19.54504 },
    { lat: 49.0989923, lng: 19.545713 },
    { lat: 49.0989766, lng: 19.5458879 },
    { lat: 49.0989259, lng: 19.5460867 },
    { lat: 49.0987731, lng: 19.546517 },
    { lat: 49.0985444, lng: 19.5474095 },
    { lat: 49.0984278, lng: 19.5482168 },
    { lat: 49.0984329, lng: 19.5491982 },
    { lat: 49.0984239, lng: 19.5502692 },
    { lat: 49.0982644, lng: 19.5511263 },
    { lat: 49.0978841, lng: 19.5525751 },
    { lat: 49.0976486, lng: 19.5533956 },
    { lat: 49.0961223, lng: 19.5551215 },
    { lat: 49.0955769, lng: 19.5548988 },
    { lat: 49.0953604, lng: 19.5548198 },
    { lat: 49.0947848, lng: 19.5547954 },
    { lat: 49.0946354, lng: 19.5548072 },
    { lat: 49.0945291, lng: 19.5546965 },
    { lat: 49.0942791, lng: 19.5548018 },
    { lat: 49.0937858, lng: 19.5546071 },
    { lat: 49.0936542, lng: 19.5545893 },
    { lat: 49.0928073, lng: 19.5543252 },
    { lat: 49.0921115, lng: 19.5541834 },
    { lat: 49.0919913, lng: 19.5541953 },
    { lat: 49.0918347, lng: 19.5541463 },
    { lat: 49.0916568, lng: 19.554169 },
    { lat: 49.0910399, lng: 19.5541598 },
    { lat: 49.0908685, lng: 19.5540832 },
    { lat: 49.0907234, lng: 19.5541121 },
    { lat: 49.0904192, lng: 19.5540614 },
    { lat: 49.0903179, lng: 19.554089 },
    { lat: 49.09028, lng: 19.5540232 },
    { lat: 49.0900908, lng: 19.5541251 },
    { lat: 49.0898869, lng: 19.5541016 },
    { lat: 49.0894963, lng: 19.5542099 },
    { lat: 49.0893694, lng: 19.5542692 },
    { lat: 49.0891062, lng: 19.5542555 },
    { lat: 49.0886412, lng: 19.5543619 },
    { lat: 49.0885778, lng: 19.5542812 },
    { lat: 49.0885475, lng: 19.5544318 },
    { lat: 49.0883171, lng: 19.55462 },
    { lat: 49.0882298, lng: 19.5546047 },
    { lat: 49.0882091, lng: 19.5545231 },
    { lat: 49.0878422, lng: 19.5545807 },
    { lat: 49.0877441, lng: 19.5547028 },
    { lat: 49.0876659, lng: 19.5545723 },
    { lat: 49.0875663, lng: 19.5546104 },
    { lat: 49.0875803, lng: 19.554747 },
    { lat: 49.087561, lng: 19.5548156 },
    { lat: 49.0875278, lng: 19.5547558 },
    { lat: 49.087524, lng: 19.5546239 },
    { lat: 49.0874415, lng: 19.5546681 },
    { lat: 49.087468, lng: 19.554779 },
    { lat: 49.0872455, lng: 19.5546948 },
    { lat: 49.087267, lng: 19.5544701 },
    { lat: 49.0871662, lng: 19.5544346 },
    { lat: 49.0871589, lng: 19.5542992 },
    { lat: 49.0872469, lng: 19.5542777 },
    { lat: 49.0873189, lng: 19.5541738 },
    { lat: 49.0872649, lng: 19.5541266 },
    { lat: 49.0872081, lng: 19.5541657 },
    { lat: 49.0871912, lng: 19.5540902 },
    { lat: 49.0872652, lng: 19.5540094 },
    { lat: 49.0872669, lng: 19.5539222 },
    { lat: 49.0873537, lng: 19.5538732 },
    { lat: 49.0871895, lng: 19.5536047 },
    { lat: 49.0871318, lng: 19.553651 },
    { lat: 49.0871368, lng: 19.5535113 },
    { lat: 49.0871088, lng: 19.5534406 },
    { lat: 49.0869951, lng: 19.5534942 },
    { lat: 49.0869321, lng: 19.5534229 },
    { lat: 49.087, lng: 19.553254 },
    { lat: 49.0869792, lng: 19.5531965 },
    { lat: 49.0868845, lng: 19.5531902 },
    { lat: 49.0868447, lng: 19.5529841 },
    { lat: 49.0867367, lng: 19.5530642 },
    { lat: 49.0866521, lng: 19.5531097 },
    { lat: 49.0865929, lng: 19.5531213 },
    { lat: 49.0865402, lng: 19.5532243 },
    { lat: 49.0859117, lng: 19.5535064 },
    { lat: 49.0858438, lng: 19.5538464 },
    { lat: 49.0853567, lng: 19.55394 },
    { lat: 49.0843048, lng: 19.5537001 },
    { lat: 49.0840963, lng: 19.5536494 },
    { lat: 49.083799, lng: 19.553626 },
    { lat: 49.083614, lng: 19.5536757 },
    { lat: 49.0834182, lng: 19.5535291 },
    { lat: 49.0829215, lng: 19.5535364 },
    { lat: 49.0822803, lng: 19.5534075 },
    { lat: 49.0821225, lng: 19.5534324 },
    { lat: 49.0820263, lng: 19.5534705 },
    { lat: 49.0818375, lng: 19.5532866 },
    { lat: 49.0817686, lng: 19.5533808 },
    { lat: 49.0815752, lng: 19.5533896 },
    { lat: 49.0814505, lng: 19.5535979 },
    { lat: 49.0813731, lng: 19.5534533 },
    { lat: 49.0811944, lng: 19.553439 },
    { lat: 49.0810603, lng: 19.5535361 },
    { lat: 49.0809319, lng: 19.553366 },
    { lat: 49.0808572, lng: 19.5530873 },
    { lat: 49.0807445, lng: 19.5529367 },
    { lat: 49.0807296, lng: 19.5528042 },
    { lat: 49.0806473, lng: 19.5526313 },
    { lat: 49.0804694, lng: 19.5525854 },
    { lat: 49.0802778, lng: 19.5524588 },
    { lat: 49.0802254, lng: 19.5525474 },
    { lat: 49.0799774, lng: 19.5524412 },
    { lat: 49.0798271, lng: 19.5524854 },
    { lat: 49.0797846, lng: 19.552464 },
    { lat: 49.0797394, lng: 19.5523634 },
    { lat: 49.0796048, lng: 19.5524523 },
    { lat: 49.079466, lng: 19.5523487 },
    { lat: 49.0792119, lng: 19.5519358 },
    { lat: 49.0791126, lng: 19.5519124 },
    { lat: 49.0790911, lng: 19.5516829 },
    { lat: 49.0789155, lng: 19.5514145 },
    { lat: 49.0786341, lng: 19.5510505 },
    { lat: 49.0786899, lng: 19.5508401 },
    { lat: 49.0785462, lng: 19.5507535 },
    { lat: 49.0784734, lng: 19.5505851 },
    { lat: 49.0785794, lng: 19.5504654 },
    { lat: 49.078506, lng: 19.5503135 },
    { lat: 49.0785253, lng: 19.5501943 },
    { lat: 49.0783135, lng: 19.5501404 },
    { lat: 49.0782139, lng: 19.5503043 },
    { lat: 49.0780628, lng: 19.5502509 },
    { lat: 49.0779499, lng: 19.5505938 },
    { lat: 49.0776969, lng: 19.5507587 },
    { lat: 49.0774748, lng: 19.5507282 },
    { lat: 49.0774548, lng: 19.5508349 },
    { lat: 49.0773316, lng: 19.5507253 },
    { lat: 49.0771436, lng: 19.5507034 },
    { lat: 49.0770484, lng: 19.5507702 },
    { lat: 49.0770666, lng: 19.5508707 },
    { lat: 49.076967, lng: 19.5509379 },
    { lat: 49.0766423, lng: 19.5508337 },
    { lat: 49.0761692, lng: 19.5512364 },
    { lat: 49.0757427, lng: 19.5514207 },
    { lat: 49.0754814, lng: 19.5517192 },
    { lat: 49.0754336, lng: 19.5516598 },
    { lat: 49.0753828, lng: 19.5515552 },
    { lat: 49.0752591, lng: 19.55077 },
    { lat: 49.0750483, lng: 19.550817 },
    { lat: 49.0750471, lng: 19.5505878 },
    { lat: 49.0749607, lng: 19.54973 },
    { lat: 49.0712831, lng: 19.5458115 },
    { lat: 49.0710663, lng: 19.5457538 },
    { lat: 49.0704467, lng: 19.5456063 },
    { lat: 49.0701366, lng: 19.5459738 },
    { lat: 49.0686001, lng: 19.5450154 },
    { lat: 49.0672986, lng: 19.5441944 },
    { lat: 49.0671843, lng: 19.5447278 },
    { lat: 49.0671224, lng: 19.5450028 },
    { lat: 49.0669509, lng: 19.545843 },
    { lat: 49.0664978, lng: 19.5447458 },
    { lat: 49.0660687, lng: 19.5437105 },
    { lat: 49.0650063, lng: 19.5448565 },
    { lat: 49.0638924, lng: 19.5462558 },
    { lat: 49.0636348, lng: 19.5465373 },
    { lat: 49.0634577, lng: 19.5448675 },
    { lat: 49.0634703, lng: 19.5414788 },
    { lat: 49.0613126, lng: 19.5425713 },
    { lat: 49.0612936, lng: 19.5407882 },
    { lat: 49.0608952, lng: 19.5409488 },
    { lat: 49.0605592, lng: 19.5411458 },
    { lat: 49.0597248, lng: 19.5415761 },
    { lat: 49.0587221, lng: 19.5420719 },
    { lat: 49.0578489, lng: 19.5425688 },
    { lat: 49.0578659, lng: 19.5425224 },
    { lat: 49.0578388, lng: 19.5425501 },
    { lat: 49.0573757, lng: 19.5428446 },
    { lat: 49.056716, lng: 19.5432543 },
    { lat: 49.0565009, lng: 19.5434052 },
    { lat: 49.0563606, lng: 19.5434929 },
    { lat: 49.0560844, lng: 19.5436858 },
    { lat: 49.056103, lng: 19.5437467 },
    { lat: 49.0550694, lng: 19.5446591 },
    { lat: 49.0551809, lng: 19.5461721 },
    { lat: 49.0550685, lng: 19.5462277 },
    { lat: 49.0548992, lng: 19.5462955 },
    { lat: 49.0549045, lng: 19.5466833 },
    { lat: 49.0547835, lng: 19.5467212 },
    { lat: 49.0546482, lng: 19.546748 },
    { lat: 49.0545219, lng: 19.5467947 },
    { lat: 49.0544547, lng: 19.5472224 },
    { lat: 49.054399, lng: 19.5475358 },
    { lat: 49.0542608, lng: 19.5482617 },
    { lat: 49.0541719, lng: 19.5487632 },
    { lat: 49.0540044, lng: 19.5505598 },
    { lat: 49.0539725, lng: 19.5510956 },
    { lat: 49.0539295, lng: 19.551515 },
    { lat: 49.0539321, lng: 19.5515934 },
    { lat: 49.0539076, lng: 19.5520745 },
    { lat: 49.0539071, lng: 19.5523587 },
    { lat: 49.0539172, lng: 19.5527579 },
    { lat: 49.0539607, lng: 19.5529484 },
    { lat: 49.0541341, lng: 19.5532933 },
  ],
  LiptovskýMikulášExt: [
    { lat: 49.1932701, lng: 19.7506818 },
    { lat: 49.1927496, lng: 19.7496202 },
    { lat: 49.191684, lng: 19.747582 },
    { lat: 49.1903823, lng: 19.7450823 },
    { lat: 49.1898262, lng: 19.7439581 },
    { lat: 49.1896075, lng: 19.7435107 },
    { lat: 49.1895171, lng: 19.7432998 },
    { lat: 49.1894616, lng: 19.743089 },
    { lat: 49.189443, lng: 19.7430477 },
    { lat: 49.1893335, lng: 19.7428906 },
    { lat: 49.1892587, lng: 19.7426464 },
    { lat: 49.1892458, lng: 19.7424 },
    { lat: 49.1891112, lng: 19.7417671 },
    { lat: 49.1890233, lng: 19.7414073 },
    { lat: 49.1890189, lng: 19.7413555 },
    { lat: 49.1889823, lng: 19.7412681 },
    { lat: 49.1889433, lng: 19.7412099 },
    { lat: 49.1888909, lng: 19.7411319 },
    { lat: 49.1886341, lng: 19.7409282 },
    { lat: 49.1883624, lng: 19.740305 },
    { lat: 49.1882809, lng: 19.7402202 },
    { lat: 49.1878349, lng: 19.7397564 },
    { lat: 49.1876986, lng: 19.7395477 },
    { lat: 49.1876825, lng: 19.7392598 },
    { lat: 49.1876656, lng: 19.7392082 },
    { lat: 49.1875696, lng: 19.7390688 },
    { lat: 49.1874234, lng: 19.7388935 },
    { lat: 49.1873353, lng: 19.7387646 },
    { lat: 49.1869589, lng: 19.73855 },
    { lat: 49.1869315, lng: 19.7385164 },
    { lat: 49.1869082, lng: 19.7384215 },
    { lat: 49.1865441, lng: 19.7376669 },
    { lat: 49.186357, lng: 19.7374861 },
    { lat: 49.1863023, lng: 19.7372392 },
    { lat: 49.1858582, lng: 19.7365406 },
    { lat: 49.1858058, lng: 19.7364761 },
    { lat: 49.1854187, lng: 19.7362483 },
    { lat: 49.185267, lng: 19.7358719 },
    { lat: 49.1851716, lng: 19.7356712 },
    { lat: 49.1850036, lng: 19.7353876 },
    { lat: 49.1848191, lng: 19.7348752 },
    { lat: 49.1844623, lng: 19.7343436 },
    { lat: 49.1844408, lng: 19.7342892 },
    { lat: 49.1844254, lng: 19.7340401 },
    { lat: 49.18435, lng: 19.7334968 },
    { lat: 49.1844009, lng: 19.7329388 },
    { lat: 49.1843133, lng: 19.7322466 },
    { lat: 49.1843703, lng: 19.7313595 },
    { lat: 49.184371, lng: 19.7312511 },
    { lat: 49.1843536, lng: 19.7310299 },
    { lat: 49.1839853, lng: 19.7295614 },
    { lat: 49.1837855, lng: 19.7290617 },
    { lat: 49.1836592, lng: 19.7285232 },
    { lat: 49.1837858, lng: 19.7280656 },
    { lat: 49.1838327, lng: 19.7276211 },
    { lat: 49.183826, lng: 19.7275721 },
    { lat: 49.1837238, lng: 19.7273455 },
    { lat: 49.1836315, lng: 19.7272213 },
    { lat: 49.1835428, lng: 19.7270431 },
    { lat: 49.183362, lng: 19.7264144 },
    { lat: 49.1833245, lng: 19.7262802 },
    { lat: 49.1829795, lng: 19.7255754 },
    { lat: 49.1827313, lng: 19.7250569 },
    { lat: 49.1824697, lng: 19.7241494 },
    { lat: 49.1822572, lng: 19.7234638 },
    { lat: 49.1821468, lng: 19.7229212 },
    { lat: 49.1820447, lng: 19.722444 },
    { lat: 49.1815935, lng: 19.7215573 },
    { lat: 49.1812196, lng: 19.720966 },
    { lat: 49.1809026, lng: 19.7204642 },
    { lat: 49.1807546, lng: 19.7199435 },
    { lat: 49.1807208, lng: 19.7198674 },
    { lat: 49.1805029, lng: 19.7194668 },
    { lat: 49.1803896, lng: 19.7193019 },
    { lat: 49.1801199, lng: 19.7189593 },
    { lat: 49.1798626, lng: 19.7185883 },
    { lat: 49.1795113, lng: 19.7180761 },
    { lat: 49.1793574, lng: 19.7177261 },
    { lat: 49.1793075, lng: 19.7175899 },
    { lat: 49.1791969, lng: 19.7174557 },
    { lat: 49.178766, lng: 19.7171107 },
    { lat: 49.1782918, lng: 19.7166496 },
    { lat: 49.1780773, lng: 19.7165116 },
    { lat: 49.177699, lng: 19.7164368 },
    { lat: 49.177066, lng: 19.7161334 },
    { lat: 49.176848, lng: 19.7160177 },
    { lat: 49.1764934, lng: 19.7155869 },
    { lat: 49.175944, lng: 19.7150784 },
    { lat: 49.1755843, lng: 19.7152523 },
    { lat: 49.1753494, lng: 19.7150321 },
    { lat: 49.1747852, lng: 19.7150372 },
    { lat: 49.1741704, lng: 19.7141742 },
    { lat: 49.1729926, lng: 19.714032 },
    { lat: 49.1727091, lng: 19.7136778 },
    { lat: 49.1725693, lng: 19.7131931 },
    { lat: 49.1723634, lng: 19.7130672 },
    { lat: 49.1717677, lng: 19.7130229 },
    { lat: 49.1710262, lng: 19.7130655 },
    { lat: 49.1706465, lng: 19.7134027 },
    { lat: 49.1703164, lng: 19.7134 },
    { lat: 49.1698336, lng: 19.7133611 },
    { lat: 49.1695187, lng: 19.7131891 },
    { lat: 49.1694411, lng: 19.7132442 },
    { lat: 49.1689402, lng: 19.7132298 },
    { lat: 49.1688213, lng: 19.7131664 },
    { lat: 49.168595, lng: 19.7128342 },
    { lat: 49.1683191, lng: 19.7124267 },
    { lat: 49.1682431, lng: 19.7123369 },
    { lat: 49.1679358, lng: 19.7123424 },
    { lat: 49.1678214, lng: 19.7123281 },
    { lat: 49.1677184, lng: 19.7122684 },
    { lat: 49.1673688, lng: 19.7124132 },
    { lat: 49.1669859, lng: 19.7123659 },
    { lat: 49.1664117, lng: 19.7122406 },
    { lat: 49.1663451, lng: 19.7122387 },
    { lat: 49.166028, lng: 19.7122261 },
    { lat: 49.1658632, lng: 19.7120392 },
    { lat: 49.1657675, lng: 19.7119658 },
    { lat: 49.1656369, lng: 19.7119176 },
    { lat: 49.1655564, lng: 19.7119539 },
    { lat: 49.1650327, lng: 19.712597 },
    { lat: 49.1646951, lng: 19.7125588 },
    { lat: 49.1645288, lng: 19.7126153 },
    { lat: 49.1642892, lng: 19.7125736 },
    { lat: 49.1639348, lng: 19.7125327 },
    { lat: 49.1637241, lng: 19.7126111 },
    { lat: 49.1633817, lng: 19.7125615 },
    { lat: 49.162891, lng: 19.7123895 },
    { lat: 49.1626745, lng: 19.7125131 },
    { lat: 49.1623644, lng: 19.7125095 },
    { lat: 49.1621546, lng: 19.7123995 },
    { lat: 49.1619587, lng: 19.7125073 },
    { lat: 49.16172, lng: 19.7125605 },
    { lat: 49.1613862, lng: 19.7126784 },
    { lat: 49.1609798, lng: 19.7134273 },
    { lat: 49.160867, lng: 19.7135309 },
    { lat: 49.1605315, lng: 19.7133532 },
    { lat: 49.1602192, lng: 19.713321 },
    { lat: 49.1600474, lng: 19.7133389 },
    { lat: 49.1598426, lng: 19.7135991 },
    { lat: 49.1594541, lng: 19.7137569 },
    { lat: 49.1590429, lng: 19.7147581 },
    { lat: 49.1588353, lng: 19.7147452 },
    { lat: 49.1587381, lng: 19.7147392 },
    { lat: 49.1584423, lng: 19.7144166 },
    { lat: 49.1580119, lng: 19.7146477 },
    { lat: 49.1573755, lng: 19.7144985 },
    { lat: 49.1572092, lng: 19.7144042 },
    { lat: 49.157031, lng: 19.7145541 },
    { lat: 49.1561255, lng: 19.7149158 },
    { lat: 49.1558649, lng: 19.7147545 },
    { lat: 49.1554306, lng: 19.7147956 },
    { lat: 49.1547038, lng: 19.7142744 },
    { lat: 49.1544174, lng: 19.7137296 },
    { lat: 49.1541579, lng: 19.7126688 },
    { lat: 49.1539333, lng: 19.7125855 },
    { lat: 49.1536876, lng: 19.712174 },
    { lat: 49.153279, lng: 19.7119757 },
    { lat: 49.1528294, lng: 19.7112521 },
    { lat: 49.1523602, lng: 19.7097272 },
    { lat: 49.1522856, lng: 19.709412 },
    { lat: 49.1523003, lng: 19.7091593 },
    { lat: 49.1514893, lng: 19.7073905 },
    { lat: 49.1513435, lng: 19.7072528 },
    { lat: 49.1511993, lng: 19.7066906 },
    { lat: 49.1510044, lng: 19.7062308 },
    { lat: 49.150785, lng: 19.706002 },
    { lat: 49.150337, lng: 19.7058318 },
    { lat: 49.149827, lng: 19.7051203 },
    { lat: 49.149374, lng: 19.7048639 },
    { lat: 49.1491688, lng: 19.7046308 },
    { lat: 49.1490041, lng: 19.704318 },
    { lat: 49.1487314, lng: 19.704262 },
    { lat: 49.1485796, lng: 19.7041444 },
    { lat: 49.1483591, lng: 19.7038697 },
    { lat: 49.1479685, lng: 19.7035929 },
    { lat: 49.147559, lng: 19.7033257 },
    { lat: 49.1473304, lng: 19.7030943 },
    { lat: 49.1470933, lng: 19.7027007 },
    { lat: 49.1468393, lng: 19.7020998 },
    { lat: 49.1463565, lng: 19.7013701 },
    { lat: 49.1461766, lng: 19.7013266 },
    { lat: 49.1460718, lng: 19.7012243 },
    { lat: 49.1460201, lng: 19.7011493 },
    { lat: 49.1459125, lng: 19.7006465 },
    { lat: 49.1458679, lng: 19.7007601 },
    { lat: 49.1456766, lng: 19.7012305 },
    { lat: 49.1456576, lng: 19.7012796 },
    { lat: 49.1455752, lng: 19.7014849 },
    { lat: 49.1455773, lng: 19.7016077 },
    { lat: 49.1450465, lng: 19.701169 },
    { lat: 49.1442804, lng: 19.7009656 },
    { lat: 49.1438473, lng: 19.700671 },
    { lat: 49.1436564, lng: 19.7006574 },
    { lat: 49.1429753, lng: 19.7010538 },
    { lat: 49.1415369, lng: 19.7012902 },
    { lat: 49.1403041, lng: 19.7012032 },
    { lat: 49.139167, lng: 19.7005542 },
    { lat: 49.1379161, lng: 19.6998539 },
    { lat: 49.136703, lng: 19.6987072 },
    { lat: 49.1342609, lng: 19.6953115 },
    { lat: 49.1334135, lng: 19.6942592 },
    { lat: 49.1333393, lng: 19.6941993 },
    { lat: 49.1327628, lng: 19.693734 },
    { lat: 49.1317323, lng: 19.6934121 },
    { lat: 49.1297996, lng: 19.6930363 },
    { lat: 49.1292653, lng: 19.6929166 },
    { lat: 49.1288618, lng: 19.6928262 },
    { lat: 49.1275417, lng: 19.6927247 },
    { lat: 49.1262656, lng: 19.6926046 },
    { lat: 49.1260054, lng: 19.692582 },
    { lat: 49.1249106, lng: 19.69261 },
    { lat: 49.124909, lng: 19.6924997 },
    { lat: 49.12492, lng: 19.6924712 },
    { lat: 49.1249364, lng: 19.69243 },
    { lat: 49.1251743, lng: 19.6918243 },
    { lat: 49.1246818, lng: 19.6913579 },
    { lat: 49.1235952, lng: 19.6903264 },
    { lat: 49.1225243, lng: 19.6893132 },
    { lat: 49.1216557, lng: 19.6884994 },
    { lat: 49.1216077, lng: 19.6884539 },
    { lat: 49.1215671, lng: 19.6886102 },
    { lat: 49.1214208, lng: 19.6887659 },
    { lat: 49.1214032, lng: 19.6889303 },
    { lat: 49.1213388, lng: 19.6889998 },
    { lat: 49.1213188, lng: 19.6891083 },
    { lat: 49.1209488, lng: 19.6895563 },
    { lat: 49.1206086, lng: 19.68984 },
    { lat: 49.1204336, lng: 19.6901364 },
    { lat: 49.1202242, lng: 19.690474 },
    { lat: 49.1200593, lng: 19.6903114 },
    { lat: 49.1197577, lng: 19.690061 },
    { lat: 49.1196515, lng: 19.6899277 },
    { lat: 49.1195415, lng: 19.6896832 },
    { lat: 49.1194379, lng: 19.689428 },
    { lat: 49.1193261, lng: 19.6892161 },
    { lat: 49.1191935, lng: 19.6890459 },
    { lat: 49.1189512, lng: 19.6889161 },
    { lat: 49.1187828, lng: 19.688774 },
    { lat: 49.1182829, lng: 19.6879164 },
    { lat: 49.1172964, lng: 19.6863404 },
    { lat: 49.1166013, lng: 19.6852234 },
    { lat: 49.1156686, lng: 19.6842023 },
    { lat: 49.1145407, lng: 19.6831316 },
    { lat: 49.1145134, lng: 19.6831754 },
    { lat: 49.1142637, lng: 19.6835153 },
    { lat: 49.11411, lng: 19.6837339 },
    { lat: 49.1139795, lng: 19.6836588 },
    { lat: 49.1136988, lng: 19.6841666 },
    { lat: 49.1124036, lng: 19.6861422 },
    { lat: 49.1120503, lng: 19.686765 },
    { lat: 49.1109707, lng: 19.6886679 },
    { lat: 49.1109496, lng: 19.6887039 },
    { lat: 49.1104785, lng: 19.6899184 },
    { lat: 49.1101449, lng: 19.6910145 },
    { lat: 49.110243, lng: 19.6915798 },
    { lat: 49.1102382, lng: 19.6921611 },
    { lat: 49.1100746, lng: 19.6929207 },
    { lat: 49.1099186, lng: 19.6931147 },
    { lat: 49.1097991, lng: 19.6932154 },
    { lat: 49.1101254, lng: 19.6934093 },
    { lat: 49.1104685, lng: 19.6934748 },
    { lat: 49.1107154, lng: 19.6935808 },
    { lat: 49.1110182, lng: 19.6935998 },
    { lat: 49.1112566, lng: 19.6935139 },
    { lat: 49.111646, lng: 19.6935809 },
    { lat: 49.1122221, lng: 19.6932069 },
    { lat: 49.112688, lng: 19.6928348 },
    { lat: 49.1130047, lng: 19.6926358 },
    { lat: 49.113209, lng: 19.6927706 },
    { lat: 49.1132638, lng: 19.69276 },
    { lat: 49.113426, lng: 19.692734 },
    { lat: 49.1135029, lng: 19.6926701 },
    { lat: 49.1137941, lng: 19.6927575 },
    { lat: 49.1143483, lng: 19.6928557 },
    { lat: 49.1144724, lng: 19.6927592 },
    { lat: 49.1146359, lng: 19.6927349 },
    { lat: 49.1151049, lng: 19.6928182 },
    { lat: 49.1152483, lng: 19.6926758 },
    { lat: 49.115304, lng: 19.6927075 },
    { lat: 49.1155956, lng: 19.692933 },
    { lat: 49.1165759, lng: 19.6936059 },
    { lat: 49.116906, lng: 19.6940411 },
    { lat: 49.116925, lng: 19.6940578 },
    { lat: 49.1170365, lng: 19.6941345 },
    { lat: 49.117352, lng: 19.6943418 },
    { lat: 49.1174019, lng: 19.6943744 },
    { lat: 49.1175898, lng: 19.694498 },
    { lat: 49.1176873, lng: 19.6945614 },
    { lat: 49.1194615, lng: 19.6955285 },
    { lat: 49.1213314, lng: 19.696213 },
    { lat: 49.1226405, lng: 19.6965455 },
    { lat: 49.124259, lng: 19.6968194 },
    { lat: 49.1254332, lng: 19.6970525 },
    { lat: 49.1254372, lng: 19.697096 },
    { lat: 49.1254899, lng: 19.6973971 },
    { lat: 49.1295047, lng: 19.698082 },
    { lat: 49.1296269, lng: 19.698272 },
    { lat: 49.1307106, lng: 19.6991375 },
    { lat: 49.1323829, lng: 19.7004566 },
    { lat: 49.1324012, lng: 19.7006137 },
    { lat: 49.1325176, lng: 19.7008501 },
    { lat: 49.1336722, lng: 19.7018483 },
    { lat: 49.1352321, lng: 19.7033219 },
    { lat: 49.1356322, lng: 19.7036573 },
    { lat: 49.1356576, lng: 19.7036727 },
    { lat: 49.1365617, lng: 19.7045337 },
    { lat: 49.1366472, lng: 19.7046326 },
    { lat: 49.1365587, lng: 19.7048471 },
    { lat: 49.1382048, lng: 19.7066178 },
    { lat: 49.1390825, lng: 19.7072366 },
    { lat: 49.1390922, lng: 19.7074555 },
    { lat: 49.1408027, lng: 19.708186 },
    { lat: 49.1408326, lng: 19.7081995 },
    { lat: 49.1424996, lng: 19.7089112 },
    { lat: 49.1430942, lng: 19.7093391 },
    { lat: 49.1436741, lng: 19.7098204 },
    { lat: 49.1451672, lng: 19.7112923 },
    { lat: 49.1457478, lng: 19.7119448 },
    { lat: 49.1482716, lng: 19.714826 },
    { lat: 49.1484396, lng: 19.7149809 },
    { lat: 49.1486485, lng: 19.7155066 },
    { lat: 49.148566, lng: 19.7167317 },
    { lat: 49.1486587, lng: 19.7175644 },
    { lat: 49.1487676, lng: 19.7185022 },
    { lat: 49.1496851, lng: 19.7221133 },
    { lat: 49.1501136, lng: 19.7235362 },
    { lat: 49.1508136, lng: 19.7268349 },
    { lat: 49.151327, lng: 19.7283971 },
    { lat: 49.1522459, lng: 19.7293181 },
    { lat: 49.1532687, lng: 19.731168 },
    { lat: 49.1536648, lng: 19.7316747 },
    { lat: 49.1545415, lng: 19.7317044 },
    { lat: 49.1555307, lng: 19.7324038 },
    { lat: 49.1565065, lng: 19.7332336 },
    { lat: 49.1582667, lng: 19.7344839 },
    { lat: 49.1606383, lng: 19.73516 },
    { lat: 49.1611505, lng: 19.7346357 },
    { lat: 49.1614678, lng: 19.7343662 },
    { lat: 49.1625454, lng: 19.7341757 },
    { lat: 49.1627601, lng: 19.7341902 },
    { lat: 49.1638144, lng: 19.7348339 },
    { lat: 49.1642759, lng: 19.7352399 },
    { lat: 49.1653421, lng: 19.7357392 },
    { lat: 49.1657681, lng: 19.7360285 },
    { lat: 49.1662771, lng: 19.7360466 },
    { lat: 49.1668952, lng: 19.7362214 },
    { lat: 49.1672174, lng: 19.7359608 },
    { lat: 49.1673203, lng: 19.7355078 },
    { lat: 49.1678337, lng: 19.7358095 },
    { lat: 49.1681578, lng: 19.7361651 },
    { lat: 49.1691011, lng: 19.7366348 },
    { lat: 49.1700018, lng: 19.7374764 },
    { lat: 49.17048, lng: 19.7379772 },
    { lat: 49.170916, lng: 19.7383167 },
    { lat: 49.1714743, lng: 19.7386625 },
    { lat: 49.1717598, lng: 19.7392178 },
    { lat: 49.1727582, lng: 19.7398962 },
    { lat: 49.1734975, lng: 19.740187 },
    { lat: 49.1734984, lng: 19.7408644 },
    { lat: 49.173598, lng: 19.7412238 },
    { lat: 49.1736395, lng: 19.7412707 },
    { lat: 49.1738067, lng: 19.7414143 },
    { lat: 49.174257, lng: 19.7415083 },
    { lat: 49.1747292, lng: 19.7417689 },
    { lat: 49.1749777, lng: 19.741811 },
    { lat: 49.1752548, lng: 19.7417072 },
    { lat: 49.1755922, lng: 19.741849 },
    { lat: 49.1763586, lng: 19.7415501 },
    { lat: 49.1770082, lng: 19.7417982 },
    { lat: 49.1780432, lng: 19.7418446 },
    { lat: 49.1783586, lng: 19.7420513 },
    { lat: 49.1787271, lng: 19.742131 },
    { lat: 49.1793791, lng: 19.7424886 },
    { lat: 49.180828, lng: 19.7441988 },
    { lat: 49.1809574, lng: 19.7445782 },
    { lat: 49.1811567, lng: 19.7447578 },
    { lat: 49.1816078, lng: 19.7458802 },
    { lat: 49.1817802, lng: 19.7461944 },
    { lat: 49.1820403, lng: 19.7464964 },
    { lat: 49.182333, lng: 19.7472183 },
    { lat: 49.1828253, lng: 19.7480715 },
    { lat: 49.183087, lng: 19.7484962 },
    { lat: 49.1838738, lng: 19.7489731 },
    { lat: 49.1841714, lng: 19.7488741 },
    { lat: 49.184419, lng: 19.7489715 },
    { lat: 49.1846068, lng: 19.7491966 },
    { lat: 49.1857667, lng: 19.7500668 },
    { lat: 49.1871074, lng: 19.7518533 },
    { lat: 49.1877138, lng: 19.7522488 },
    { lat: 49.1880037, lng: 19.7523354 },
    { lat: 49.1882957, lng: 19.7523134 },
    { lat: 49.1884926, lng: 19.7521793 },
    { lat: 49.1888464, lng: 19.7523908 },
    { lat: 49.189628, lng: 19.752487 },
    { lat: 49.1898912, lng: 19.7524272 },
    { lat: 49.1904169, lng: 19.7520865 },
    { lat: 49.1908612, lng: 19.7520926 },
    { lat: 49.1914821, lng: 19.7518946 },
    { lat: 49.1915983, lng: 19.7518688 },
    { lat: 49.1931579, lng: 19.7507177 },
    { lat: 49.1932701, lng: 19.7506818 },
  ],
};

export default UnitsLiptovskýMikuláš;
