import { React } from 'react';

export default function Tarchy({propList, getList}){
    
    function getTableBodyTarchy(){
        if(getList()?.tarchy instanceof Array){
            return (!propList) ? null :
            <tbody>
                {getList()?.tarchy?.map((item, index) => {
                    return(
                        <tr key={index} className='border border-slate-400'>
                            <td className='text-center w-40'>Poradové č. vlastníka <br/>{item.poradove_c_vlastnika_}</td>
                            <td className='text-center'>{item.tarcha}</td>
                        </tr>
                    )
                })}
            </tbody>
        }if(getList()?.tarchy instanceof Object){
            return(
                <tbody>
                    <tr className='border border-slate-400'>
                        <td className='text-center w-40'>Poradové č. vlastníka <br/>{getList()?.tarchy?.poradove_c_vlastnika_}</td>
                        <td className='text-center'>{getList()?.tarchy?.tarcha}</td>
                    </tr>
                </tbody>
            )
        }
        
    }


    return (
        <>
            <div className="flex flex-col overflow-scroll">
                <table className='w-full'>
                    {getTableBodyTarchy()}
                </table>
            </div>  
        </>
    )
}