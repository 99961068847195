export const activityService = {
  saveActivity: async (request) => {
    return await fetch(process.env.REACT_APP_ACTIVITY_SERVICE + `save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  },
};
