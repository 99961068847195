const UnitsMartin = {
  Benice: [
    { lat: 49.0060057, lng: 18.871411 },
    { lat: 49.0057923, lng: 18.8713372 },
    { lat: 49.0056214, lng: 18.8712396 },
    { lat: 49.0053949, lng: 18.8710884 },
    { lat: 49.0046399, lng: 18.8707883 },
    { lat: 49.004217, lng: 18.8707422 },
    { lat: 49.0040401, lng: 18.8706554 },
    { lat: 49.0032595, lng: 18.8701688 },
    { lat: 49.0022402, lng: 18.8695761 },
    { lat: 49.0019549, lng: 18.8694455 },
    { lat: 49.0011038, lng: 18.86914 },
    { lat: 49.0003387, lng: 18.8689517 },
    { lat: 48.9994753, lng: 18.8688028 },
    { lat: 48.998785, lng: 18.8686675 },
    { lat: 48.9984326, lng: 18.8685448 },
    { lat: 48.9981397, lng: 18.8684177 },
    { lat: 48.9976773, lng: 18.8681102 },
    { lat: 48.9969586, lng: 18.8675725 },
    { lat: 48.9960462, lng: 18.8667605 },
    { lat: 48.9951655, lng: 18.8659217 },
    { lat: 48.9951277, lng: 18.8657154 },
    { lat: 48.9949486, lng: 18.8654351 },
    { lat: 48.9948354, lng: 18.8652044 },
    { lat: 48.9947349, lng: 18.8651341 },
    { lat: 48.9944625, lng: 18.8647951 },
    { lat: 48.9939782, lng: 18.8637949 },
    { lat: 48.9931327, lng: 18.862528 },
    { lat: 48.9925781, lng: 18.8616776 },
    { lat: 48.9915753, lng: 18.8607914 },
    { lat: 48.99136, lng: 18.8604859 },
    { lat: 48.9911616, lng: 18.8603318 },
    { lat: 48.9910157, lng: 18.8601696 },
    { lat: 48.9909762, lng: 18.8599057 },
    { lat: 48.9909904, lng: 18.8597008 },
    { lat: 48.9909789, lng: 18.8595226 },
    { lat: 48.9909912, lng: 18.8590185 },
    { lat: 48.9909631, lng: 18.8589501 },
    { lat: 48.9908402, lng: 18.858229 },
    { lat: 48.9908521, lng: 18.8572111 },
    { lat: 48.9907819, lng: 18.8556957 },
    { lat: 48.990779, lng: 18.8549615 },
    { lat: 48.9908483, lng: 18.8543339 },
    { lat: 48.991119, lng: 18.8526533 },
    { lat: 48.9911172, lng: 18.8520457 },
    { lat: 48.9912136, lng: 18.8511373 },
    { lat: 48.9912246, lng: 18.8509382 },
    { lat: 48.9912821, lng: 18.850587 },
    { lat: 48.9916632, lng: 18.8489676 },
    { lat: 48.9919229, lng: 18.8482947 },
    { lat: 48.9921928, lng: 18.8471685 },
    { lat: 48.9923103, lng: 18.8463183 },
    { lat: 48.9922671, lng: 18.8463145 },
    { lat: 48.9915521, lng: 18.8459609 },
    { lat: 48.9910473, lng: 18.8457258 },
    { lat: 48.9907247, lng: 18.8455923 },
    { lat: 48.9899241, lng: 18.84533 },
    { lat: 48.9897808, lng: 18.846398 },
    { lat: 48.9895228, lng: 18.8481945 },
    { lat: 48.9891898, lng: 18.8490233 },
    { lat: 48.98894, lng: 18.8496644 },
    { lat: 48.988402, lng: 18.8510612 },
    { lat: 48.9883114, lng: 18.8512762 },
    { lat: 48.9881088, lng: 18.851566 },
    { lat: 48.9879493, lng: 18.8517318 },
    { lat: 48.9878665, lng: 18.8517427 },
    { lat: 48.9876931, lng: 18.8528627 },
    { lat: 48.9876509, lng: 18.8530706 },
    { lat: 48.9876369, lng: 18.8531067 },
    { lat: 48.9873415, lng: 18.854463 },
    { lat: 48.9869502, lng: 18.8562378 },
    { lat: 48.9868518, lng: 18.8566674 },
    { lat: 48.9867019, lng: 18.8573731 },
    { lat: 48.9865444, lng: 18.857292 },
    { lat: 48.9862384, lng: 18.8587278 },
    { lat: 48.9860509, lng: 18.8597944 },
    { lat: 48.9859536, lng: 18.8603789 },
    { lat: 48.9860919, lng: 18.8608533 },
    { lat: 48.9858384, lng: 18.8623276 },
    { lat: 48.9855064, lng: 18.8642012 },
    { lat: 48.985183, lng: 18.8658935 },
    { lat: 48.9850891, lng: 18.8665257 },
    { lat: 48.984946, lng: 18.8670966 },
    { lat: 48.9847113, lng: 18.8681479 },
    { lat: 48.984704, lng: 18.8682213 },
    { lat: 48.9846692, lng: 18.8682477 },
    { lat: 48.9844206, lng: 18.8693778 },
    { lat: 48.9841254, lng: 18.8706557 },
    { lat: 48.9839656, lng: 18.8712985 },
    { lat: 48.9838544, lng: 18.871768 },
    { lat: 48.9836133, lng: 18.8730875 },
    { lat: 48.9833665, lng: 18.8744105 },
    { lat: 48.9831627, lng: 18.8755572 },
    { lat: 48.9829617, lng: 18.8766033 },
    { lat: 48.9830225, lng: 18.8771755 },
    { lat: 48.9829766, lng: 18.8772904 },
    { lat: 48.9828872, lng: 18.8780753 },
    { lat: 48.9828681, lng: 18.8783196 },
    { lat: 48.9830498, lng: 18.878676 },
    { lat: 48.9834154, lng: 18.8789443 },
    { lat: 48.9835785, lng: 18.8789928 },
    { lat: 48.9837314, lng: 18.8790383 },
    { lat: 48.9838039, lng: 18.8791062 },
    { lat: 48.9841394, lng: 18.8792528 },
    { lat: 48.9842503, lng: 18.8793251 },
    { lat: 48.9842789, lng: 18.8793238 },
    { lat: 48.9845247, lng: 18.8793123 },
    { lat: 48.9848196, lng: 18.879235 },
    { lat: 48.9849548, lng: 18.8791479 },
    { lat: 48.9853311, lng: 18.8790075 },
    { lat: 48.9856568, lng: 18.8790066 },
    { lat: 48.9859771, lng: 18.8788111 },
    { lat: 48.986258, lng: 18.8786813 },
    { lat: 48.9863548, lng: 18.8786982 },
    { lat: 48.9864307, lng: 18.87864 },
    { lat: 48.9864689, lng: 18.8785052 },
    { lat: 48.9865899, lng: 18.878316 },
    { lat: 48.9868213, lng: 18.8779213 },
    { lat: 48.9869013, lng: 18.8776168 },
    { lat: 48.9870601, lng: 18.877174 },
    { lat: 48.9872083, lng: 18.8768311 },
    { lat: 48.9872468, lng: 18.8767889 },
    { lat: 48.9875501, lng: 18.8760231 },
    { lat: 48.987628, lng: 18.8756724 },
    { lat: 48.9877031, lng: 18.8754417 },
    { lat: 48.9879255, lng: 18.8750323 },
    { lat: 48.9879758, lng: 18.8749759 },
    { lat: 48.9882539, lng: 18.8746641 },
    { lat: 48.9882963, lng: 18.874523 },
    { lat: 48.9884431, lng: 18.8741746 },
    { lat: 48.9884918, lng: 18.8739815 },
    { lat: 48.9885039, lng: 18.87378 },
    { lat: 48.9885175, lng: 18.8732722 },
    { lat: 48.9885755, lng: 18.8729343 },
    { lat: 48.9887304, lng: 18.8725684 },
    { lat: 48.9888666, lng: 18.8723638 },
    { lat: 48.98912, lng: 18.8721647 },
    { lat: 48.989649, lng: 18.8718603 },
    { lat: 48.9899646, lng: 18.8717712 },
    { lat: 48.9900454, lng: 18.871716 },
    { lat: 48.9903596, lng: 18.8719082 },
    { lat: 48.9905423, lng: 18.8719497 },
    { lat: 48.9906403, lng: 18.8719952 },
    { lat: 48.9908053, lng: 18.8720018 },
    { lat: 48.9912548, lng: 18.872105 },
    { lat: 48.9915318, lng: 18.8723185 },
    { lat: 48.991622, lng: 18.8724464 },
    { lat: 48.9918647, lng: 18.8726072 },
    { lat: 48.9920471, lng: 18.8726904 },
    { lat: 48.9921647, lng: 18.872763 },
    { lat: 48.992272, lng: 18.8728741 },
    { lat: 48.9925184, lng: 18.8731088 },
    { lat: 48.9926695, lng: 18.8731932 },
    { lat: 48.9928971, lng: 18.8732126 },
    { lat: 48.9932639, lng: 18.873377 },
    { lat: 48.9934073, lng: 18.8737294 },
    { lat: 48.9934057, lng: 18.8738977 },
    { lat: 48.9934349, lng: 18.8740691 },
    { lat: 48.9937837, lng: 18.8744941 },
    { lat: 48.9938904, lng: 18.8745055 },
    { lat: 48.993913, lng: 18.8745316 },
    { lat: 48.9941952, lng: 18.8744694 },
    { lat: 48.9945559, lng: 18.8742697 },
    { lat: 48.9946026, lng: 18.8741945 },
    { lat: 48.9947576, lng: 18.8741395 },
    { lat: 48.9951445, lng: 18.8741842 },
    { lat: 48.9954292, lng: 18.8742854 },
    { lat: 48.9956095, lng: 18.8742159 },
    { lat: 48.9956772, lng: 18.8741497 },
    { lat: 48.9957817, lng: 18.8739442 },
    { lat: 48.9957702, lng: 18.8738303 },
    { lat: 48.9957971, lng: 18.8734982 },
    { lat: 48.9958646, lng: 18.8734313 },
    { lat: 48.9962282, lng: 18.8734413 },
    { lat: 48.9964982, lng: 18.8734951 },
    { lat: 48.9965944, lng: 18.8735875 },
    { lat: 48.9966221, lng: 18.8738876 },
    { lat: 48.9966145, lng: 18.8742875 },
    { lat: 48.9967241, lng: 18.8751287 },
    { lat: 48.9967475, lng: 18.8753651 },
    { lat: 48.9968195, lng: 18.8757357 },
    { lat: 48.9968215, lng: 18.8759732 },
    { lat: 48.9968431, lng: 18.8761728 },
    { lat: 48.9969501, lng: 18.876656 },
    { lat: 48.9970209, lng: 18.8768652 },
    { lat: 48.9970991, lng: 18.8770965 },
    { lat: 48.9971928, lng: 18.8772323 },
    { lat: 48.9972379, lng: 18.8772499 },
    { lat: 48.997566, lng: 18.8772876 },
    { lat: 48.9976041, lng: 18.877292 },
    { lat: 48.9977312, lng: 18.8772636 },
    { lat: 48.9978579, lng: 18.8772734 },
    { lat: 48.9979451, lng: 18.8772563 },
    { lat: 48.9980633, lng: 18.8772512 },
    { lat: 48.9982881, lng: 18.877305 },
    { lat: 48.9984932, lng: 18.8773242 },
    { lat: 48.9985237, lng: 18.8773062 },
    { lat: 48.9986927, lng: 18.8773246 },
    { lat: 48.9988481, lng: 18.877383 },
    { lat: 48.9989072, lng: 18.8773926 },
    { lat: 48.9989941, lng: 18.8774623 },
    { lat: 48.999163, lng: 18.8775669 },
    { lat: 48.9992365, lng: 18.8776365 },
    { lat: 48.999324, lng: 18.8777396 },
    { lat: 48.9993867, lng: 18.8777957 },
    { lat: 48.9994649, lng: 18.8778461 },
    { lat: 48.9995609, lng: 18.8780038 },
    { lat: 48.9996165, lng: 18.8780342 },
    { lat: 48.9996583, lng: 18.8780987 },
    { lat: 48.9997389, lng: 18.878223 },
    { lat: 48.9998859, lng: 18.8785133 },
    { lat: 48.9999204, lng: 18.8786389 },
    { lat: 48.9999933, lng: 18.8788072 },
    { lat: 49.0000471, lng: 18.8789758 },
    { lat: 49.0001121, lng: 18.8792105 },
    { lat: 49.0001382, lng: 18.8793693 },
    { lat: 49.0002837, lng: 18.8795665 },
    { lat: 49.0003565, lng: 18.8796228 },
    { lat: 49.0004408, lng: 18.8796631 },
    { lat: 49.0007419, lng: 18.8797539 },
    { lat: 49.000829, lng: 18.8797558 },
    { lat: 49.0009485, lng: 18.8797373 },
    { lat: 49.0010627, lng: 18.8795632 },
    { lat: 49.0011745, lng: 18.8792189 },
    { lat: 49.0012974, lng: 18.8789979 },
    { lat: 49.0013714, lng: 18.8788139 },
    { lat: 49.001428, lng: 18.8787055 },
    { lat: 49.0014705, lng: 18.8785917 },
    { lat: 49.0015216, lng: 18.8784862 },
    { lat: 49.001621, lng: 18.8782489 },
    { lat: 49.0016773, lng: 18.8781366 },
    { lat: 49.0017626, lng: 18.8780202 },
    { lat: 49.0017215, lng: 18.8779436 },
    { lat: 49.0017056, lng: 18.8778998 },
    { lat: 49.0016426, lng: 18.8769984 },
    { lat: 49.0010482, lng: 18.8770419 },
    { lat: 49.0004917, lng: 18.877073 },
    { lat: 49.0005269, lng: 18.876593 },
    { lat: 49.0005745, lng: 18.8761816 },
    { lat: 49.000583, lng: 18.8758854 },
    { lat: 49.0005608, lng: 18.8757153 },
    { lat: 49.0006542, lng: 18.8755567 },
    { lat: 49.0009078, lng: 18.8755338 },
    { lat: 49.001009, lng: 18.8754855 },
    { lat: 49.0010997, lng: 18.87532 },
    { lat: 49.0012295, lng: 18.8748148 },
    { lat: 49.0015413, lng: 18.8741041 },
    { lat: 49.0016276, lng: 18.8739154 },
    { lat: 49.0016335, lng: 18.8738788 },
    { lat: 49.0020917, lng: 18.8736227 },
    { lat: 49.0024055, lng: 18.8736099 },
    { lat: 49.0027098, lng: 18.8736043 },
    { lat: 49.0030227, lng: 18.8735916 },
    { lat: 49.0034328, lng: 18.8735671 },
    { lat: 49.0038778, lng: 18.8735606 },
    { lat: 49.0040679, lng: 18.8735726 },
    { lat: 49.004699, lng: 18.8735631 },
    { lat: 49.0048859, lng: 18.8736056 },
    { lat: 49.0049103, lng: 18.8736192 },
    { lat: 49.0049834, lng: 18.8737686 },
    { lat: 49.0050401, lng: 18.8739712 },
    { lat: 49.0051731, lng: 18.8745013 },
    { lat: 49.0052285, lng: 18.8744578 },
    { lat: 49.0059502, lng: 18.8722184 },
    { lat: 49.0061125, lng: 18.8717344 },
    { lat: 49.0060213, lng: 18.8715406 },
    { lat: 49.0060057, lng: 18.871411 },
  ],
  Blatnica: [
    { lat: 48.876764, lng: 19.078754 },
    { lat: 48.877094, lng: 19.078636 },
    { lat: 48.877724, lng: 19.078765 },
    { lat: 48.880252, lng: 19.080019 },
    { lat: 48.885101, lng: 19.081071 },
    { lat: 48.886384, lng: 19.08268 },
    { lat: 48.886971, lng: 19.083432 },
    { lat: 48.888266, lng: 19.082428 },
    { lat: 48.889501, lng: 19.081656 },
    { lat: 48.889988, lng: 19.081277 },
    { lat: 48.890401, lng: 19.080833 },
    { lat: 48.893241, lng: 19.078914 },
    { lat: 48.894455, lng: 19.079621 },
    { lat: 48.896014, lng: 19.08078 },
    { lat: 48.896951, lng: 19.081141 },
    { lat: 48.898723, lng: 19.081386 },
    { lat: 48.899524, lng: 19.081429 },
    { lat: 48.899962, lng: 19.081217 },
    { lat: 48.901992, lng: 19.079376 },
    { lat: 48.902152, lng: 19.07923 },
    { lat: 48.903029, lng: 19.0791 },
    { lat: 48.905809, lng: 19.08032 },
    { lat: 48.906431, lng: 19.080611 },
    { lat: 48.907119, lng: 19.081106 },
    { lat: 48.907742, lng: 19.08161 },
    { lat: 48.908826, lng: 19.082561 },
    { lat: 48.9102612, lng: 19.0816033 },
    { lat: 48.9114279, lng: 19.0808077 },
    { lat: 48.9134707, lng: 19.0794691 },
    { lat: 48.9138229, lng: 19.078821 },
    { lat: 48.9153262, lng: 19.0772894 },
    { lat: 48.9159551, lng: 19.0764906 },
    { lat: 48.9165493, lng: 19.0757227 },
    { lat: 48.9173414, lng: 19.0746715 },
    { lat: 48.9177137, lng: 19.0737924 },
    { lat: 48.9181552, lng: 19.0724895 },
    { lat: 48.9182793, lng: 19.0713587 },
    { lat: 48.9183485, lng: 19.0710745 },
    { lat: 48.9184486, lng: 19.0708675 },
    { lat: 48.9184562, lng: 19.070632 },
    { lat: 48.9184852, lng: 19.0706065 },
    { lat: 48.9186934, lng: 19.0704229 },
    { lat: 48.9187258, lng: 19.0703943 },
    { lat: 48.9188029, lng: 19.0703264 },
    { lat: 48.9189999, lng: 19.0696307 },
    { lat: 48.9191385, lng: 19.0691413 },
    { lat: 48.9195616, lng: 19.0688097 },
    { lat: 48.9200461, lng: 19.0676018 },
    { lat: 48.9204765, lng: 19.0665757 },
    { lat: 48.920755, lng: 19.0659631 },
    { lat: 48.9209845, lng: 19.0654998 },
    { lat: 48.9208969, lng: 19.0648277 },
    { lat: 48.9209439, lng: 19.0639147 },
    { lat: 48.9212523, lng: 19.0627509 },
    { lat: 48.9215818, lng: 19.0618295 },
    { lat: 48.9225206, lng: 19.0610548 },
    { lat: 48.9229287, lng: 19.0599677 },
    { lat: 48.9231657, lng: 19.0594276 },
    { lat: 48.9237793, lng: 19.0587137 },
    { lat: 48.9243134, lng: 19.0577129 },
    { lat: 48.9248026, lng: 19.0573707 },
    { lat: 48.9259486, lng: 19.0562752 },
    { lat: 48.9264797, lng: 19.0558038 },
    { lat: 48.9268301, lng: 19.0555714 },
    { lat: 48.9274093, lng: 19.054661 },
    { lat: 48.9284523, lng: 19.0536009 },
    { lat: 48.9285222, lng: 19.0529934 },
    { lat: 48.9286164, lng: 19.0518726 },
    { lat: 48.9289461, lng: 19.0514318 },
    { lat: 48.929198, lng: 19.051149 },
    { lat: 48.9298525, lng: 19.051005 },
    { lat: 48.9306652, lng: 19.0503477 },
    { lat: 48.9310391, lng: 19.0498161 },
    { lat: 48.9312946, lng: 19.0489947 },
    { lat: 48.9319776, lng: 19.0480209 },
    { lat: 48.9326417, lng: 19.047458 },
    { lat: 48.9329914, lng: 19.0471238 },
    { lat: 48.9333179, lng: 19.046707 },
    { lat: 48.9333682, lng: 19.0463365 },
    { lat: 48.9337123, lng: 19.0454647 },
    { lat: 48.9339303, lng: 19.0451084 },
    { lat: 48.9340553, lng: 19.0449248 },
    { lat: 48.9347141, lng: 19.04455 },
    { lat: 48.9350458, lng: 19.0440159 },
    { lat: 48.9353678, lng: 19.0437788 },
    { lat: 48.9359869, lng: 19.0424289 },
    { lat: 48.9364893, lng: 19.0414496 },
    { lat: 48.9366426, lng: 19.0410654 },
    { lat: 48.9369173, lng: 19.0406294 },
    { lat: 48.9370737, lng: 19.040266 },
    { lat: 48.9373501, lng: 19.0397688 },
    { lat: 48.9375301, lng: 19.0395365 },
    { lat: 48.9375875, lng: 19.0391075 },
    { lat: 48.9378462, lng: 19.0384172 },
    { lat: 48.9380999, lng: 19.037854 },
    { lat: 48.9383275, lng: 19.0374441 },
    { lat: 48.9395085, lng: 19.0365159 },
    { lat: 48.9402961, lng: 19.0358052 },
    { lat: 48.9408375, lng: 19.0353373 },
    { lat: 48.9416219, lng: 19.034048 },
    { lat: 48.9419437, lng: 19.0335157 },
    { lat: 48.9422238, lng: 19.0328052 },
    { lat: 48.9425955, lng: 19.0320573 },
    { lat: 48.9427272, lng: 19.0318223 },
    { lat: 48.9428258, lng: 19.0316306 },
    { lat: 48.9428849, lng: 19.0315287 },
    { lat: 48.9434688, lng: 19.0305817 },
    { lat: 48.9441972, lng: 19.0304044 },
    { lat: 48.9447384, lng: 19.0295487 },
    { lat: 48.9449614, lng: 19.0285098 },
    { lat: 48.9450341, lng: 19.0280226 },
    { lat: 48.945144, lng: 19.0278303 },
    { lat: 48.9455257, lng: 19.0277106 },
    { lat: 48.9456913, lng: 19.0275261 },
    { lat: 48.9460063, lng: 19.0263636 },
    { lat: 48.9460565, lng: 19.0261283 },
    { lat: 48.9461729, lng: 19.0252777 },
    { lat: 48.9460975, lng: 19.0247861 },
    { lat: 48.9460727, lng: 19.0245845 },
    { lat: 48.9461551, lng: 19.0243383 },
    { lat: 48.9460783, lng: 19.0239074 },
    { lat: 48.9459347, lng: 19.0232677 },
    { lat: 48.9459891, lng: 19.0221929 },
    { lat: 48.9459551, lng: 19.0217177 },
    { lat: 48.9463433, lng: 19.0214311 },
    { lat: 48.9466087, lng: 19.0208819 },
    { lat: 48.9468885, lng: 19.020143 },
    { lat: 48.9469941, lng: 19.019506 },
    { lat: 48.9472689, lng: 19.0187766 },
    { lat: 48.9473935, lng: 19.0180965 },
    { lat: 48.9475364, lng: 19.0176611 },
    { lat: 48.9477573, lng: 19.0174118 },
    { lat: 48.9482586, lng: 19.0171806 },
    { lat: 48.9486328, lng: 19.0168611 },
    { lat: 48.9488719, lng: 19.0165028 },
    { lat: 48.9489256, lng: 19.0162032 },
    { lat: 48.949416, lng: 19.0161139 },
    { lat: 48.9498257, lng: 19.0160583 },
    { lat: 48.9500847, lng: 19.0156686 },
    { lat: 48.9508326, lng: 19.0149368 },
    { lat: 48.9514342, lng: 19.0142517 },
    { lat: 48.9520702, lng: 19.0147048 },
    { lat: 48.9525778, lng: 19.0140574 },
    { lat: 48.9526544, lng: 19.013697 },
    { lat: 48.952753, lng: 19.0132136 },
    { lat: 48.952918, lng: 19.0127974 },
    { lat: 48.9531051, lng: 19.0124156 },
    { lat: 48.953222, lng: 19.0119106 },
    { lat: 48.9531774, lng: 19.0114509 },
    { lat: 48.9531337, lng: 19.0110728 },
    { lat: 48.9531913, lng: 19.0105116 },
    { lat: 48.9531874, lng: 19.0100536 },
    { lat: 48.9532287, lng: 19.0093271 },
    { lat: 48.9532186, lng: 19.0084957 },
    { lat: 48.9531723, lng: 19.0078414 },
    { lat: 48.9530784, lng: 19.0071208 },
    { lat: 48.9530468, lng: 19.0064253 },
    { lat: 48.9536189, lng: 19.005694 },
    { lat: 48.9539948, lng: 19.0051633 },
    { lat: 48.9542531, lng: 19.0051234 },
    { lat: 48.9544141, lng: 19.0049369 },
    { lat: 48.9546858, lng: 19.0044204 },
    { lat: 48.9547566, lng: 19.0038303 },
    { lat: 48.9550541, lng: 19.0030422 },
    { lat: 48.9554594, lng: 19.0025425 },
    { lat: 48.9556323, lng: 19.0016066 },
    { lat: 48.9558666, lng: 19.0010646 },
    { lat: 48.9564988, lng: 19.000276 },
    { lat: 48.9565727, lng: 18.9999923 },
    { lat: 48.9568819, lng: 18.9993724 },
    { lat: 48.9570912, lng: 18.9990945 },
    { lat: 48.9576256, lng: 18.9984471 },
    { lat: 48.9577763, lng: 18.9982588 },
    { lat: 48.958203, lng: 18.9979116 },
    { lat: 48.9586314, lng: 18.9975512 },
    { lat: 48.9590674, lng: 18.9971214 },
    { lat: 48.9593089, lng: 18.9968125 },
    { lat: 48.9594351, lng: 18.9967204 },
    { lat: 48.9596011, lng: 18.9963667 },
    { lat: 48.9597339, lng: 18.9958458 },
    { lat: 48.959904, lng: 18.9952869 },
    { lat: 48.9601147, lng: 18.9949898 },
    { lat: 48.9610604, lng: 18.9942479 },
    { lat: 48.961077, lng: 18.9942347 },
    { lat: 48.9620022, lng: 18.9930249 },
    { lat: 48.9623863, lng: 18.9920535 },
    { lat: 48.9626802, lng: 18.9911522 },
    { lat: 48.9626955, lng: 18.9909165 },
    { lat: 48.9626147, lng: 18.9906539 },
    { lat: 48.9625666, lng: 18.9904403 },
    { lat: 48.9626607, lng: 18.9899576 },
    { lat: 48.9627554, lng: 18.9894418 },
    { lat: 48.9628803, lng: 18.9888769 },
    { lat: 48.96312, lng: 18.9880866 },
    { lat: 48.9633129, lng: 18.9874851 },
    { lat: 48.9635623, lng: 18.9870191 },
    { lat: 48.9637782, lng: 18.9866488 },
    { lat: 48.9642777, lng: 18.9855563 },
    { lat: 48.9636541, lng: 18.9845883 },
    { lat: 48.9635332, lng: 18.982841 },
    { lat: 48.9635022, lng: 18.9824044 },
    { lat: 48.9633377, lng: 18.9815796 },
    { lat: 48.9632921, lng: 18.979021 },
    { lat: 48.9629181, lng: 18.9774851 },
    { lat: 48.961597, lng: 18.974606 },
    { lat: 48.9608915, lng: 18.9712417 },
    { lat: 48.9594428, lng: 18.9705742 },
    { lat: 48.9590519, lng: 18.9700238 },
    { lat: 48.9581151, lng: 18.9690635 },
    { lat: 48.9577629, lng: 18.9677624 },
    { lat: 48.957522, lng: 18.96688 },
    { lat: 48.9574027, lng: 18.9664365 },
    { lat: 48.9589232, lng: 18.9646146 },
    { lat: 48.9589346, lng: 18.964601 },
    { lat: 48.9600235, lng: 18.9621674 },
    { lat: 48.96025, lng: 18.9605645 },
    { lat: 48.9604296, lng: 18.9600803 },
    { lat: 48.9606933, lng: 18.959675 },
    { lat: 48.960821, lng: 18.9593472 },
    { lat: 48.960896, lng: 18.9589377 },
    { lat: 48.9612168, lng: 18.9584385 },
    { lat: 48.9614504, lng: 18.9581525 },
    { lat: 48.9617992, lng: 18.9579399 },
    { lat: 48.9620084, lng: 18.9577924 },
    { lat: 48.9624366, lng: 18.9561655 },
    { lat: 48.962696, lng: 18.9552758 },
    { lat: 48.9630665, lng: 18.9532197 },
    { lat: 48.9632833, lng: 18.9526023 },
    { lat: 48.9634211, lng: 18.9512514 },
    { lat: 48.9637969, lng: 18.9495691 },
    { lat: 48.9638436, lng: 18.9489088 },
    { lat: 48.9638916, lng: 18.9477231 },
    { lat: 48.9640136, lng: 18.9465032 },
    { lat: 48.9640047, lng: 18.9457981 },
    { lat: 48.9640117, lng: 18.9454227 },
    { lat: 48.964028, lng: 18.945321 },
    { lat: 48.9640263, lng: 18.9450212 },
    { lat: 48.9643943, lng: 18.9451005 },
    { lat: 48.9647957, lng: 18.9449183 },
    { lat: 48.9652032, lng: 18.94482 },
    { lat: 48.9655856, lng: 18.9449564 },
    { lat: 48.9655246, lng: 18.9447091 },
    { lat: 48.9652055, lng: 18.9437339 },
    { lat: 48.9651338, lng: 18.9433867 },
    { lat: 48.9648483, lng: 18.9429795 },
    { lat: 48.9646593, lng: 18.9422542 },
    { lat: 48.9644933, lng: 18.9418919 },
    { lat: 48.9671846, lng: 18.9401854 },
    { lat: 48.96728, lng: 18.9398466 },
    { lat: 48.9673724, lng: 18.9393991 },
    { lat: 48.9675308, lng: 18.9390335 },
    { lat: 48.9677219, lng: 18.9387782 },
    { lat: 48.9680717, lng: 18.9386504 },
    { lat: 48.9688477, lng: 18.9377689 },
    { lat: 48.9690639, lng: 18.937318 },
    { lat: 48.9693955, lng: 18.9365201 },
    { lat: 48.969581, lng: 18.9363114 },
    { lat: 48.9696238, lng: 18.9361123 },
    { lat: 48.9698457, lng: 18.9355106 },
    { lat: 48.9702047, lng: 18.9342287 },
    { lat: 48.9700111, lng: 18.9337953 },
    { lat: 48.9696963, lng: 18.9339467 },
    { lat: 48.9693266, lng: 18.9343474 },
    { lat: 48.9690589, lng: 18.9345138 },
    { lat: 48.9688365, lng: 18.9347071 },
    { lat: 48.968546, lng: 18.9348029 },
    { lat: 48.9681816, lng: 18.9350189 },
    { lat: 48.9669426, lng: 18.9339596 },
    { lat: 48.9665024, lng: 18.933595 },
    { lat: 48.9646522, lng: 18.9321086 },
    { lat: 48.9646011, lng: 18.9320321 },
    { lat: 48.9644266, lng: 18.9315276 },
    { lat: 48.9642346, lng: 18.9307916 },
    { lat: 48.9622125, lng: 18.9297378 },
    { lat: 48.9621357, lng: 18.9296916 },
    { lat: 48.9615648, lng: 18.9286215 },
    { lat: 48.9615688, lng: 18.9272149 },
    { lat: 48.9615164, lng: 18.9267614 },
    { lat: 48.9614802, lng: 18.9261622 },
    { lat: 48.9613195, lng: 18.9254649 },
    { lat: 48.960403, lng: 18.9254692 },
    { lat: 48.9602029, lng: 18.9255253 },
    { lat: 48.9597662, lng: 18.9256947 },
    { lat: 48.9593512, lng: 18.9238544 },
    { lat: 48.9592921, lng: 18.9235104 },
    { lat: 48.9592678, lng: 18.9233916 },
    { lat: 48.9593321, lng: 18.92328 },
    { lat: 48.9594175, lng: 18.9231617 },
    { lat: 48.9594934, lng: 18.9228797 },
    { lat: 48.9595877, lng: 18.9226074 },
    { lat: 48.9597747, lng: 18.922471 },
    { lat: 48.9598326, lng: 18.9222382 },
    { lat: 48.9597331, lng: 18.9220346 },
    { lat: 48.9594474, lng: 18.9216724 },
    { lat: 48.9599996, lng: 18.9209577 },
    { lat: 48.960229, lng: 18.9211195 },
    { lat: 48.9591671, lng: 18.9177199 },
    { lat: 48.9594886, lng: 18.9175009 },
    { lat: 48.9599757, lng: 18.9172596 },
    { lat: 48.9599437, lng: 18.9171604 },
    { lat: 48.9593875, lng: 18.9153855 },
    { lat: 48.9593596, lng: 18.9153009 },
    { lat: 48.9608095, lng: 18.9145268 },
    { lat: 48.9610074, lng: 18.9151358 },
    { lat: 48.9624161, lng: 18.9144142 },
    { lat: 48.9636841, lng: 18.9136783 },
    { lat: 48.9654809, lng: 18.9119045 },
    { lat: 48.9661922, lng: 18.9110597 },
    { lat: 48.9667714, lng: 18.910502 },
    { lat: 48.9672012, lng: 18.9101281 },
    { lat: 48.9680723, lng: 18.909456 },
    { lat: 48.9673188, lng: 18.9080383 },
    { lat: 48.9667622, lng: 18.9071185 },
    { lat: 48.9665724, lng: 18.9069137 },
    { lat: 48.9666102, lng: 18.9065952 },
    { lat: 48.9667136, lng: 18.9063663 },
    { lat: 48.9661161, lng: 18.9060151 },
    { lat: 48.9659579, lng: 18.9059853 },
    { lat: 48.9651693, lng: 18.9060183 },
    { lat: 48.9647536, lng: 18.9058738 },
    { lat: 48.9645392, lng: 18.9058668 },
    { lat: 48.9643138, lng: 18.9059834 },
    { lat: 48.9642002, lng: 18.9061637 },
    { lat: 48.9640388, lng: 18.9064048 },
    { lat: 48.9638324, lng: 18.9071726 },
    { lat: 48.9637663, lng: 18.9073587 },
    { lat: 48.9637088, lng: 18.9074696 },
    { lat: 48.9632345, lng: 18.9081186 },
    { lat: 48.9631102, lng: 18.9082437 },
    { lat: 48.9629123, lng: 18.9083661 },
    { lat: 48.9619125, lng: 18.908946 },
    { lat: 48.9609994, lng: 18.909502 },
    { lat: 48.9599249, lng: 18.9111552 },
    { lat: 48.9598916, lng: 18.9113687 },
    { lat: 48.9596623, lng: 18.9118438 },
    { lat: 48.9592295, lng: 18.9124638 },
    { lat: 48.9591579, lng: 18.9125203 },
    { lat: 48.9590125, lng: 18.9125643 },
    { lat: 48.9587683, lng: 18.9125981 },
    { lat: 48.9586948, lng: 18.9126814 },
    { lat: 48.9586189, lng: 18.9129156 },
    { lat: 48.9585503, lng: 18.9130116 },
    { lat: 48.9574191, lng: 18.9116572 },
    { lat: 48.9573129, lng: 18.911561 },
    { lat: 48.9568509, lng: 18.9109301 },
    { lat: 48.9567988, lng: 18.9108814 },
    { lat: 48.956155, lng: 18.9100412 },
    { lat: 48.9554636, lng: 18.9090858 },
    { lat: 48.954744, lng: 18.9081997 },
    { lat: 48.9546185, lng: 18.9081058 },
    { lat: 48.9534786, lng: 18.9067803 },
    { lat: 48.9530712, lng: 18.9063077 },
    { lat: 48.9529953, lng: 18.906302 },
    { lat: 48.9525602, lng: 18.9055959 },
    { lat: 48.9522397, lng: 18.9049101 },
    { lat: 48.9518242, lng: 18.9041257 },
    { lat: 48.951285, lng: 18.9031263 },
    { lat: 48.9504254, lng: 18.9015968 },
    { lat: 48.9498695, lng: 18.9005395 },
    { lat: 48.950191, lng: 18.9001231 },
    { lat: 48.9512722, lng: 18.8988893 },
    { lat: 48.9515371, lng: 18.8985585 },
    { lat: 48.9521508, lng: 18.8976595 },
    { lat: 48.9529711, lng: 18.89646 },
    { lat: 48.952492, lng: 18.8960394 },
    { lat: 48.952037, lng: 18.8957465 },
    { lat: 48.9514882, lng: 18.8954421 },
    { lat: 48.9507225, lng: 18.8950877 },
    { lat: 48.9506226, lng: 18.8950288 },
    { lat: 48.9498705, lng: 18.8947893 },
    { lat: 48.9492608, lng: 18.8946618 },
    { lat: 48.9482924, lng: 18.8945982 },
    { lat: 48.9476844, lng: 18.8945706 },
    { lat: 48.9466732, lng: 18.894616 },
    { lat: 48.9458884, lng: 18.8946599 },
    { lat: 48.9449802, lng: 18.8947168 },
    { lat: 48.9446483, lng: 18.8947309 },
    { lat: 48.9440891, lng: 18.8947746 },
    { lat: 48.9433103, lng: 18.8948063 },
    { lat: 48.9428308, lng: 18.8948223 },
    { lat: 48.9419326, lng: 18.8948756 },
    { lat: 48.941012, lng: 18.8949061 },
    { lat: 48.9410071, lng: 18.8946115 },
    { lat: 48.9405716, lng: 18.893858 },
    { lat: 48.940052, lng: 18.8938218 },
    { lat: 48.938979, lng: 18.8936073 },
    { lat: 48.9379445, lng: 18.8933095 },
    { lat: 48.93605, lng: 18.892463 },
    { lat: 48.935737, lng: 18.892325 },
    { lat: 48.93569, lng: 18.892304 },
    { lat: 48.935454, lng: 18.8928 },
    { lat: 48.935086, lng: 18.893955 },
    { lat: 48.935065, lng: 18.894029 },
    { lat: 48.935023, lng: 18.894174 },
    { lat: 48.934999, lng: 18.894256 },
    { lat: 48.934358, lng: 18.895428 },
    { lat: 48.933767, lng: 18.89641 },
    { lat: 48.933671, lng: 18.89647 },
    { lat: 48.933477, lng: 18.896586 },
    { lat: 48.933179, lng: 18.896982 },
    { lat: 48.932339, lng: 18.898194 },
    { lat: 48.932065, lng: 18.898756 },
    { lat: 48.93181, lng: 18.898949 },
    { lat: 48.931603, lng: 18.899155 },
    { lat: 48.931575, lng: 18.899219 },
    { lat: 48.931506, lng: 18.899298 },
    { lat: 48.931006, lng: 18.900052 },
    { lat: 48.929838, lng: 18.901799 },
    { lat: 48.929546, lng: 18.90208 },
    { lat: 48.929077, lng: 18.902965 },
    { lat: 48.927874, lng: 18.905151 },
    { lat: 48.927585, lng: 18.905627 },
    { lat: 48.927563, lng: 18.905663 },
    { lat: 48.927474, lng: 18.905814 },
    { lat: 48.926854, lng: 18.906597 },
    { lat: 48.926407, lng: 18.907662 },
    { lat: 48.925593, lng: 18.909074 },
    { lat: 48.925488, lng: 18.909401 },
    { lat: 48.92531, lng: 18.909632 },
    { lat: 48.925028, lng: 18.909874 },
    { lat: 48.924903, lng: 18.909971 },
    { lat: 48.924361, lng: 18.91039 },
    { lat: 48.924302, lng: 18.910436 },
    { lat: 48.924251, lng: 18.910916 },
    { lat: 48.924226, lng: 18.911161 },
    { lat: 48.924221, lng: 18.911203 },
    { lat: 48.92386, lng: 18.912755 },
    { lat: 48.923138, lng: 18.914475 },
    { lat: 48.92312, lng: 18.914498 },
    { lat: 48.923083, lng: 18.914546 },
    { lat: 48.923046, lng: 18.914593 },
    { lat: 48.922199, lng: 18.917025 },
    { lat: 48.921881, lng: 18.917997 },
    { lat: 48.921777, lng: 18.9185 },
    { lat: 48.921531, lng: 18.919702 },
    { lat: 48.921277, lng: 18.920934 },
    { lat: 48.9212, lng: 18.922458 },
    { lat: 48.921133, lng: 18.923324 },
    { lat: 48.921118, lng: 18.923454 },
    { lat: 48.921148, lng: 18.923701 },
    { lat: 48.921112, lng: 18.924214 },
    { lat: 48.921131, lng: 18.924795 },
    { lat: 48.921171, lng: 18.925495 },
    { lat: 48.921213, lng: 18.925784 },
    { lat: 48.921297, lng: 18.92615 },
    { lat: 48.921356, lng: 18.92645 },
    { lat: 48.921419, lng: 18.926901 },
    { lat: 48.92144, lng: 18.927368 },
    { lat: 48.921435, lng: 18.92778 },
    { lat: 48.921377, lng: 18.928094 },
    { lat: 48.920515, lng: 18.93139 },
    { lat: 48.919699, lng: 18.933485 },
    { lat: 48.919522, lng: 18.933529 },
    { lat: 48.919397, lng: 18.933576 },
    { lat: 48.91931, lng: 18.933809 },
    { lat: 48.919043, lng: 18.934159 },
    { lat: 48.918883, lng: 18.934422 },
    { lat: 48.918776, lng: 18.934637 },
    { lat: 48.918737, lng: 18.934714 },
    { lat: 48.918408, lng: 18.934776 },
    { lat: 48.918025, lng: 18.934715 },
    { lat: 48.917738, lng: 18.934935 },
    { lat: 48.91744, lng: 18.935342 },
    { lat: 48.917119, lng: 18.935832 },
    { lat: 48.916874, lng: 18.936288 },
    { lat: 48.916537, lng: 18.937013 },
    { lat: 48.916487, lng: 18.937182 },
    { lat: 48.916345, lng: 18.9376 },
    { lat: 48.916196, lng: 18.938221 },
    { lat: 48.916019, lng: 18.938852 },
    { lat: 48.916002, lng: 18.939338 },
    { lat: 48.915273, lng: 18.939231 },
    { lat: 48.914976, lng: 18.939436 },
    { lat: 48.914655, lng: 18.939514 },
    { lat: 48.914498, lng: 18.939634 },
    { lat: 48.914353, lng: 18.939954 },
    { lat: 48.914175, lng: 18.940032 },
    { lat: 48.913842, lng: 18.940386 },
    { lat: 48.913588, lng: 18.940756 },
    { lat: 48.912952, lng: 18.941717 },
    { lat: 48.912255, lng: 18.9429 },
    { lat: 48.912048, lng: 18.94319 },
    { lat: 48.911796, lng: 18.943773 },
    { lat: 48.911693, lng: 18.943969 },
    { lat: 48.911641, lng: 18.944045 },
    { lat: 48.911514, lng: 18.944232 },
    { lat: 48.91102, lng: 18.944424 },
    { lat: 48.910538, lng: 18.944531 },
    { lat: 48.909993, lng: 18.944662 },
    { lat: 48.909591, lng: 18.944693 },
    { lat: 48.909191, lng: 18.944824 },
    { lat: 48.908925, lng: 18.944987 },
    { lat: 48.90868, lng: 18.945538 },
    { lat: 48.908417, lng: 18.945875 },
    { lat: 48.90811, lng: 18.94613 },
    { lat: 48.907903, lng: 18.946436 },
    { lat: 48.90768, lng: 18.946719 },
    { lat: 48.907083, lng: 18.946913 },
    { lat: 48.906624, lng: 18.947292 },
    { lat: 48.90628, lng: 18.947903 },
    { lat: 48.905994, lng: 18.948287 },
    { lat: 48.9057, lng: 18.948535 },
    { lat: 48.905535, lng: 18.948644 },
    { lat: 48.905293, lng: 18.948464 },
    { lat: 48.904893, lng: 18.947495 },
    { lat: 48.904693, lng: 18.947828 },
    { lat: 48.904412, lng: 18.94861 },
    { lat: 48.904238, lng: 18.949132 },
    { lat: 48.903955, lng: 18.949621 },
    { lat: 48.903667, lng: 18.949946 },
    { lat: 48.903617, lng: 18.949981 },
    { lat: 48.903295, lng: 18.95021 },
    { lat: 48.903086, lng: 18.950244 },
    { lat: 48.902566, lng: 18.950435 },
    { lat: 48.902332, lng: 18.950122 },
    { lat: 48.90202, lng: 18.949939 },
    { lat: 48.901737, lng: 18.950102 },
    { lat: 48.901515, lng: 18.950312 },
    { lat: 48.901262, lng: 18.950477 },
    { lat: 48.901076, lng: 18.950699 },
    { lat: 48.900659, lng: 18.9508 },
    { lat: 48.900535, lng: 18.950816 },
    { lat: 48.900227, lng: 18.950576 },
    { lat: 48.899844, lng: 18.950373 },
    { lat: 48.899599, lng: 18.950318 },
    { lat: 48.899515, lng: 18.950347 },
    { lat: 48.89945, lng: 18.950401 },
    { lat: 48.899015, lng: 18.950777 },
    { lat: 48.898831, lng: 18.95092 },
    { lat: 48.898646, lng: 18.950925 },
    { lat: 48.898495, lng: 18.950907 },
    { lat: 48.898098, lng: 18.950822 },
    { lat: 48.897821, lng: 18.950798 },
    { lat: 48.897026, lng: 18.950269 },
    { lat: 48.896805, lng: 18.950353 },
    { lat: 48.89665, lng: 18.950429 },
    { lat: 48.896145, lng: 18.950416 },
    { lat: 48.895959, lng: 18.950342 },
    { lat: 48.895618, lng: 18.95065 },
    { lat: 48.895475, lng: 18.950741 },
    { lat: 48.89537, lng: 18.951291 },
    { lat: 48.895354, lng: 18.952381 },
    { lat: 48.895321, lng: 18.953058 },
    { lat: 48.894987, lng: 18.954057 },
    { lat: 48.894922, lng: 18.954535 },
    { lat: 48.894741, lng: 18.955284 },
    { lat: 48.894567, lng: 18.955944 },
    { lat: 48.894465, lng: 18.9567 },
    { lat: 48.894427, lng: 18.956802 },
    { lat: 48.894245, lng: 18.95724 },
    { lat: 48.894042, lng: 18.957627 },
    { lat: 48.893853, lng: 18.958051 },
    { lat: 48.893629, lng: 18.958365 },
    { lat: 48.893474, lng: 18.958753 },
    { lat: 48.89334, lng: 18.958856 },
    { lat: 48.892881, lng: 18.959206 },
    { lat: 48.891824, lng: 18.959806 },
    { lat: 48.89133, lng: 18.959978 },
    { lat: 48.891111, lng: 18.960681 },
    { lat: 48.890825, lng: 18.961081 },
    { lat: 48.89043, lng: 18.961498 },
    { lat: 48.890329, lng: 18.9616 },
    { lat: 48.890199, lng: 18.961666 },
    { lat: 48.889922, lng: 18.96144 },
    { lat: 48.889708, lng: 18.961347 },
    { lat: 48.889325, lng: 18.961369 },
    { lat: 48.889135, lng: 18.961291 },
    { lat: 48.889039, lng: 18.961224 },
    { lat: 48.88872, lng: 18.961233 },
    { lat: 48.888439, lng: 18.961378 },
    { lat: 48.888305, lng: 18.961954 },
    { lat: 48.888202, lng: 18.962557 },
    { lat: 48.888139, lng: 18.963061 },
    { lat: 48.888112, lng: 18.963687 },
    { lat: 48.888033, lng: 18.964039 },
    { lat: 48.887914, lng: 18.964391 },
    { lat: 48.887622, lng: 18.964881 },
    { lat: 48.887329, lng: 18.965336 },
    { lat: 48.887194, lng: 18.965613 },
    { lat: 48.88704, lng: 18.965717 },
    { lat: 48.886983, lng: 18.965976 },
    { lat: 48.88688, lng: 18.966161 },
    { lat: 48.886516, lng: 18.966456 },
    { lat: 48.886146, lng: 18.96648 },
    { lat: 48.885563, lng: 18.966613 },
    { lat: 48.885117, lng: 18.966558 },
    { lat: 48.884798, lng: 18.9665 },
    { lat: 48.884447, lng: 18.966692 },
    { lat: 48.884013, lng: 18.966771 },
    { lat: 48.883784, lng: 18.967761 },
    { lat: 48.8833, lng: 18.96818 },
    { lat: 48.882772, lng: 18.968578 },
    { lat: 48.882699, lng: 18.96868 },
    { lat: 48.882541, lng: 18.969144 },
    { lat: 48.88241, lng: 18.969839 },
    { lat: 48.882307, lng: 18.970533 },
    { lat: 48.88211, lng: 18.971152 },
    { lat: 48.881741, lng: 18.971373 },
    { lat: 48.881212, lng: 18.971476 },
    { lat: 48.881064, lng: 18.971498 },
    { lat: 48.880512, lng: 18.971806 },
    { lat: 48.880376, lng: 18.97207 },
    { lat: 48.880261, lng: 18.972557 },
    { lat: 48.880167, lng: 18.972825 },
    { lat: 48.879945, lng: 18.973171 },
    { lat: 48.879789, lng: 18.973537 },
    { lat: 48.879596, lng: 18.973811 },
    { lat: 48.879408, lng: 18.974077 },
    { lat: 48.87912, lng: 18.974437 },
    { lat: 48.87906, lng: 18.974441 },
    { lat: 48.878639, lng: 18.974466 },
    { lat: 48.878088, lng: 18.974473 },
    { lat: 48.877616, lng: 18.974462 },
    { lat: 48.87753, lng: 18.974473 },
    { lat: 48.877268, lng: 18.974505 },
    { lat: 48.876998, lng: 18.974714 },
    { lat: 48.876656, lng: 18.974853 },
    { lat: 48.876652, lng: 18.975333 },
    { lat: 48.876783, lng: 18.975773 },
    { lat: 48.876774, lng: 18.976112 },
    { lat: 48.876769, lng: 18.976368 },
    { lat: 48.876943, lng: 18.976728 },
    { lat: 48.877155, lng: 18.977141 },
    { lat: 48.877253, lng: 18.977505 },
    { lat: 48.877225, lng: 18.977931 },
    { lat: 48.877195, lng: 18.978197 },
    { lat: 48.877164, lng: 18.978493 },
    { lat: 48.877168, lng: 18.978759 },
    { lat: 48.877169, lng: 18.978836 },
    { lat: 48.877378, lng: 18.979278 },
    { lat: 48.877428, lng: 18.979773 },
    { lat: 48.877444, lng: 18.980212 },
    { lat: 48.877369, lng: 18.980658 },
    { lat: 48.877358, lng: 18.981152 },
    { lat: 48.8773, lng: 18.981621 },
    { lat: 48.877393, lng: 18.982157 },
    { lat: 48.877444, lng: 18.982721 },
    { lat: 48.877433, lng: 18.983098 },
    { lat: 48.877336, lng: 18.983471 },
    { lat: 48.877284, lng: 18.983827 },
    { lat: 48.877236, lng: 18.984134 },
    { lat: 48.877245, lng: 18.984374 },
    { lat: 48.8773, lng: 18.984543 },
    { lat: 48.877487, lng: 18.984879 },
    { lat: 48.877701, lng: 18.985101 },
    { lat: 48.87785, lng: 18.985236 },
    { lat: 48.877973, lng: 18.985895 },
    { lat: 48.878105, lng: 18.986635 },
    { lat: 48.878209, lng: 18.987124 },
    { lat: 48.878346, lng: 18.98754 },
    { lat: 48.878192, lng: 18.987924 },
    { lat: 48.878057, lng: 18.988247 },
    { lat: 48.877869, lng: 18.988498 },
    { lat: 48.877638, lng: 18.988695 },
    { lat: 48.877451, lng: 18.988938 },
    { lat: 48.877273, lng: 18.989248 },
    { lat: 48.877165, lng: 18.989411 },
    { lat: 48.877154, lng: 18.98966 },
    { lat: 48.877216, lng: 18.989951 },
    { lat: 48.87725, lng: 18.990024 },
    { lat: 48.877337, lng: 18.990205 },
    { lat: 48.877454, lng: 18.990468 },
    { lat: 48.877349, lng: 18.991056 },
    { lat: 48.877274, lng: 18.990997 },
    { lat: 48.877248, lng: 18.991254 },
    { lat: 48.877297, lng: 18.991622 },
    { lat: 48.877247, lng: 18.991811 },
    { lat: 48.877134, lng: 18.992341 },
    { lat: 48.877067, lng: 18.992573 },
    { lat: 48.876962, lng: 18.992886 },
    { lat: 48.876995, lng: 18.993171 },
    { lat: 48.877013, lng: 18.993314 },
    { lat: 48.877103, lng: 18.9936 },
    { lat: 48.877124, lng: 18.993673 },
    { lat: 48.877291, lng: 18.994233 },
    { lat: 48.877467, lng: 18.994738 },
    { lat: 48.877704, lng: 18.995237 },
    { lat: 48.877833, lng: 18.99566 },
    { lat: 48.877937, lng: 18.995943 },
    { lat: 48.877964, lng: 18.996285 },
    { lat: 48.877921, lng: 18.996778 },
    { lat: 48.877846, lng: 18.997217 },
    { lat: 48.877822, lng: 18.997701 },
    { lat: 48.877837, lng: 18.998041 },
    { lat: 48.87785, lng: 18.998293 },
    { lat: 48.877931, lng: 18.9986 },
    { lat: 48.877901, lng: 18.998803 },
    { lat: 48.877759, lng: 18.999198 },
    { lat: 48.877536, lng: 18.999735 },
    { lat: 48.877484, lng: 18.999949 },
    { lat: 48.877471, lng: 19.000414 },
    { lat: 48.877475, lng: 19.00067 },
    { lat: 48.877728, lng: 19.00129 },
    { lat: 48.877855, lng: 19.001526 },
    { lat: 48.878085, lng: 19.001836 },
    { lat: 48.878312, lng: 19.002125 },
    { lat: 48.878829, lng: 19.00303 },
    { lat: 48.878878, lng: 19.003146 },
    { lat: 48.87899, lng: 19.003418 },
    { lat: 48.879031, lng: 19.003547 },
    { lat: 48.879075, lng: 19.003921 },
    { lat: 48.879184, lng: 19.004162 },
    { lat: 48.879202, lng: 19.004477 },
    { lat: 48.879382, lng: 19.004747 },
    { lat: 48.879729, lng: 19.005059 },
    { lat: 48.880058, lng: 19.005449 },
    { lat: 48.880396, lng: 19.00585 },
    { lat: 48.880794, lng: 19.006232 },
    { lat: 48.88123, lng: 19.007129 },
    { lat: 48.881475, lng: 19.00759 },
    { lat: 48.88156, lng: 19.008291 },
    { lat: 48.881926, lng: 19.009215 },
    { lat: 48.88231, lng: 19.008979 },
    { lat: 48.882909, lng: 19.009216 },
    { lat: 48.883336, lng: 19.009746 },
    { lat: 48.88389, lng: 19.010156 },
    { lat: 48.884362, lng: 19.010475 },
    { lat: 48.884366, lng: 19.010745 },
    { lat: 48.884381, lng: 19.011839 },
    { lat: 48.884445, lng: 19.012086 },
    { lat: 48.884782, lng: 19.013234 },
    { lat: 48.885084, lng: 19.014392 },
    { lat: 48.885163, lng: 19.014939 },
    { lat: 48.88517, lng: 19.015366 },
    { lat: 48.88522, lng: 19.015843 },
    { lat: 48.885267, lng: 19.01645 },
    { lat: 48.88537, lng: 19.017327 },
    { lat: 48.885527, lng: 19.018334 },
    { lat: 48.88563, lng: 19.019195 },
    { lat: 48.885678, lng: 19.019641 },
    { lat: 48.885672, lng: 19.019863 },
    { lat: 48.885624, lng: 19.020113 },
    { lat: 48.885647, lng: 19.020403 },
    { lat: 48.885695, lng: 19.020767 },
    { lat: 48.885795, lng: 19.021023 },
    { lat: 48.88604, lng: 19.021369 },
    { lat: 48.885998, lng: 19.021844 },
    { lat: 48.885984, lng: 19.022455 },
    { lat: 48.88595, lng: 19.022958 },
    { lat: 48.885933, lng: 19.023213 },
    { lat: 48.885685, lng: 19.023416 },
    { lat: 48.885525, lng: 19.02354 },
    { lat: 48.885364, lng: 19.023595 },
    { lat: 48.885169, lng: 19.023702 },
    { lat: 48.885019, lng: 19.023914 },
    { lat: 48.884814, lng: 19.024252 },
    { lat: 48.883556, lng: 19.023733 },
    { lat: 48.88304, lng: 19.023521 },
    { lat: 48.881974, lng: 19.024531 },
    { lat: 48.880496, lng: 19.026529 },
    { lat: 48.880363, lng: 19.026711 },
    { lat: 48.87941, lng: 19.028152 },
    { lat: 48.878963, lng: 19.028822 },
    { lat: 48.8787, lng: 19.029222 },
    { lat: 48.878002, lng: 19.029292 },
    { lat: 48.878785, lng: 19.030887 },
    { lat: 48.878921, lng: 19.031164 },
    { lat: 48.879068, lng: 19.032148 },
    { lat: 48.879904, lng: 19.033253 },
    { lat: 48.879983, lng: 19.033625 },
    { lat: 48.880296, lng: 19.034141 },
    { lat: 48.880663, lng: 19.034871 },
    { lat: 48.881002, lng: 19.035537 },
    { lat: 48.881042, lng: 19.035625 },
    { lat: 48.88108, lng: 19.035737 },
    { lat: 48.881253, lng: 19.036268 },
    { lat: 48.881592, lng: 19.037278 },
    { lat: 48.881897, lng: 19.038182 },
    { lat: 48.882157, lng: 19.039015 },
    { lat: 48.881701, lng: 19.04037 },
    { lat: 48.881233, lng: 19.041865 },
    { lat: 48.882268, lng: 19.043472 },
    { lat: 48.883052, lng: 19.044412 },
    { lat: 48.883685, lng: 19.04708 },
    { lat: 48.883635, lng: 19.047588 },
    { lat: 48.883404, lng: 19.049565 },
    { lat: 48.881814, lng: 19.051522 },
    { lat: 48.88121, lng: 19.052234 },
    { lat: 48.880708, lng: 19.053398 },
    { lat: 48.880544, lng: 19.053795 },
    { lat: 48.880399, lng: 19.054145 },
    { lat: 48.880328, lng: 19.054429 },
    { lat: 48.880264, lng: 19.054589 },
    { lat: 48.880002, lng: 19.055241 },
    { lat: 48.879331, lng: 19.056901 },
    { lat: 48.878454, lng: 19.0588373 },
    { lat: 48.877519, lng: 19.060201 },
    { lat: 48.877476, lng: 19.061721 },
    { lat: 48.877445, lng: 19.063076 },
    { lat: 48.877421, lng: 19.06396 },
    { lat: 48.877323, lng: 19.06495 },
    { lat: 48.877327, lng: 19.065208 },
    { lat: 48.877338, lng: 19.065933 },
    { lat: 48.877326, lng: 19.067547 },
    { lat: 48.876949, lng: 19.068958 },
    { lat: 48.876839, lng: 19.069587 },
    { lat: 48.876802, lng: 19.070278 },
    { lat: 48.876754, lng: 19.07103 },
    { lat: 48.87686, lng: 19.071551 },
    { lat: 48.87697, lng: 19.072091 },
    { lat: 48.876987, lng: 19.07226 },
    { lat: 48.877264, lng: 19.075049 },
    { lat: 48.877285, lng: 19.077171 },
    { lat: 48.876989, lng: 19.07797 },
    { lat: 48.876988, lng: 19.077974 },
    { lat: 48.876804, lng: 19.078612 },
    { lat: 48.876764, lng: 19.078754 },
  ],
  Bystrička: [
    { lat: 49.078189, lng: 18.806727 },
    { lat: 49.077599, lng: 18.80649 },
    { lat: 49.077388, lng: 18.806405 },
    { lat: 49.077387, lng: 18.806405 },
    { lat: 49.076364, lng: 18.805498 },
    { lat: 49.076283, lng: 18.805425 },
    { lat: 49.076282, lng: 18.805424 },
    { lat: 49.07558, lng: 18.804432 },
    { lat: 49.075359, lng: 18.804173 },
    { lat: 49.075074, lng: 18.803802 },
    { lat: 49.074898, lng: 18.80351 },
    { lat: 49.074532, lng: 18.802783 },
    { lat: 49.074518, lng: 18.802451 },
    { lat: 49.074513, lng: 18.802334 },
    { lat: 49.074337, lng: 18.801945 },
    { lat: 49.074243, lng: 18.801738 },
    { lat: 49.073994, lng: 18.801133 },
    { lat: 49.073482, lng: 18.800191 },
    { lat: 49.072477, lng: 18.799096 },
    { lat: 49.07241, lng: 18.798794 },
    { lat: 49.072169, lng: 18.798377 },
    { lat: 49.072117, lng: 18.798284 },
    { lat: 49.072005, lng: 18.798084 },
    { lat: 49.071672, lng: 18.79766 },
    { lat: 49.071643, lng: 18.797623 },
    { lat: 49.071185, lng: 18.797125 },
    { lat: 49.071184, lng: 18.797124 },
    { lat: 49.071048, lng: 18.796841 },
    { lat: 49.070496, lng: 18.795713 },
    { lat: 49.070202, lng: 18.795172 },
    { lat: 49.06994, lng: 18.794857 },
    { lat: 49.069912, lng: 18.794823 },
    { lat: 49.069638, lng: 18.79475 },
    { lat: 49.06943, lng: 18.794546 },
    { lat: 49.069033, lng: 18.793659 },
    { lat: 49.069033, lng: 18.793658 },
    { lat: 49.068903, lng: 18.793505 },
    { lat: 49.068812, lng: 18.793398 },
    { lat: 49.068812, lng: 18.793397 },
    { lat: 49.068415, lng: 18.792713 },
    { lat: 49.068262, lng: 18.792499 },
    { lat: 49.068182, lng: 18.792387 },
    { lat: 49.068181, lng: 18.792386 },
    { lat: 49.068082, lng: 18.792227 },
    { lat: 49.067859, lng: 18.79187 },
    { lat: 49.067562, lng: 18.791374 },
    { lat: 49.067424, lng: 18.790953 },
    { lat: 49.067456, lng: 18.79019 },
    { lat: 49.06743, lng: 18.789828 },
    { lat: 49.067405, lng: 18.789486 },
    { lat: 49.067299, lng: 18.789079 },
    { lat: 49.067286, lng: 18.789034 },
    { lat: 49.067115, lng: 18.788469 },
    { lat: 49.066847, lng: 18.787019 },
    { lat: 49.066827, lng: 18.786894 },
    { lat: 49.066689, lng: 18.785948 },
    { lat: 49.065872, lng: 18.785308 },
    { lat: 49.065207, lng: 18.784808 },
    { lat: 49.064901, lng: 18.784482 },
    { lat: 49.0646511, lng: 18.7848964 },
    { lat: 49.0644668, lng: 18.7851933 },
    { lat: 49.0640028, lng: 18.7859612 },
    { lat: 49.0636818, lng: 18.7865083 },
    { lat: 49.0634992, lng: 18.7868147 },
    { lat: 49.0631221, lng: 18.7874776 },
    { lat: 49.0629417, lng: 18.7877614 },
    { lat: 49.062402, lng: 18.7886333 },
    { lat: 49.061674, lng: 18.7897062 },
    { lat: 49.061253, lng: 18.7903308 },
    { lat: 49.0614212, lng: 18.7911789 },
    { lat: 49.0614464, lng: 18.7917282 },
    { lat: 49.061432, lng: 18.7925551 },
    { lat: 49.0612275, lng: 18.7939353 },
    { lat: 49.0609282, lng: 18.794406 },
    { lat: 49.0604737, lng: 18.7950993 },
    { lat: 49.0601469, lng: 18.7956686 },
    { lat: 49.0600895, lng: 18.7958405 },
    { lat: 49.0597456, lng: 18.7967984 },
    { lat: 49.0594518, lng: 18.7975485 },
    { lat: 49.0593046, lng: 18.7978398 },
    { lat: 49.0592009, lng: 18.7980346 },
    { lat: 49.0588144, lng: 18.7984737 },
    { lat: 49.0583903, lng: 18.7988202 },
    { lat: 49.0582715, lng: 18.7989208 },
    { lat: 49.058104, lng: 18.7991045 },
    { lat: 49.0578976, lng: 18.7993432 },
    { lat: 49.0574968, lng: 18.7995701 },
    { lat: 49.0569963, lng: 18.7998363 },
    { lat: 49.055917, lng: 18.8001799 },
    { lat: 49.0554302, lng: 18.800361 },
    { lat: 49.0553735, lng: 18.8003757 },
    { lat: 49.0550813, lng: 18.8004166 },
    { lat: 49.054779, lng: 18.8007617 },
    { lat: 49.0544568, lng: 18.8014195 },
    { lat: 49.0543225, lng: 18.8016996 },
    { lat: 49.0538338, lng: 18.8025593 },
    { lat: 49.0532797, lng: 18.8034192 },
    { lat: 49.0529423, lng: 18.8038297 },
    { lat: 49.0526874, lng: 18.804131 },
    { lat: 49.0522862, lng: 18.8048748 },
    { lat: 49.0522352, lng: 18.8049791 },
    { lat: 49.0520855, lng: 18.8054835 },
    { lat: 49.0519218, lng: 18.8059798 },
    { lat: 49.0518567, lng: 18.8061595 },
    { lat: 49.0516513, lng: 18.8068744 },
    { lat: 49.0515466, lng: 18.8072651 },
    { lat: 49.0514195, lng: 18.8076848 },
    { lat: 49.0512397, lng: 18.8081777 },
    { lat: 49.0511225, lng: 18.8084373 },
    { lat: 49.0509001, lng: 18.8089144 },
    { lat: 49.0508175, lng: 18.8093647 },
    { lat: 49.0506398, lng: 18.8097868 },
    { lat: 49.0502849, lng: 18.8104028 },
    { lat: 49.0501691, lng: 18.8109311 },
    { lat: 49.0499994, lng: 18.8112221 },
    { lat: 49.0497454, lng: 18.8115233 },
    { lat: 49.0495395, lng: 18.811837 },
    { lat: 49.0494164, lng: 18.8121602 },
    { lat: 49.0493657, lng: 18.8123129 },
    { lat: 49.0492187, lng: 18.8128043 },
    { lat: 49.0487851, lng: 18.8132733 },
    { lat: 49.0482321, lng: 18.8135459 },
    { lat: 49.0480291, lng: 18.813677 },
    { lat: 49.0479261, lng: 18.8137092 },
    { lat: 49.0472902, lng: 18.8137998 },
    { lat: 49.0464135, lng: 18.8139175 },
    { lat: 49.046299, lng: 18.8140146 },
    { lat: 49.0461236, lng: 18.8142002 },
    { lat: 49.0458911, lng: 18.8145013 },
    { lat: 49.0458293, lng: 18.8147693 },
    { lat: 49.0458238, lng: 18.814835 },
    { lat: 49.045841, lng: 18.8151442 },
    { lat: 49.0459474, lng: 18.8155021 },
    { lat: 49.0459797, lng: 18.8158206 },
    { lat: 49.0459366, lng: 18.8169674 },
    { lat: 49.0458919, lng: 18.8179806 },
    { lat: 49.0457007, lng: 18.8187922 },
    { lat: 49.0456824, lng: 18.8191596 },
    { lat: 49.0457745, lng: 18.8196981 },
    { lat: 49.0457986, lng: 18.820164 },
    { lat: 49.0457925, lng: 18.820352 },
    { lat: 49.0456575, lng: 18.8218793 },
    { lat: 49.0456103, lng: 18.8222336 },
    { lat: 49.0453487, lng: 18.8233694 },
    { lat: 49.0453276, lng: 18.8235682 },
    { lat: 49.0456109, lng: 18.8249484 },
    { lat: 49.0455937, lng: 18.825426 },
    { lat: 49.0455155, lng: 18.8260248 },
    { lat: 49.0455607, lng: 18.8266147 },
    { lat: 49.045543, lng: 18.8271919 },
    { lat: 49.0454889, lng: 18.8274532 },
    { lat: 49.0452142, lng: 18.8279853 },
    { lat: 49.0451731, lng: 18.8281487 },
    { lat: 49.045142, lng: 18.8286409 },
    { lat: 49.04517, lng: 18.8288755 },
    { lat: 49.045206, lng: 18.8290919 },
    { lat: 49.045336, lng: 18.8295131 },
    { lat: 49.0453778, lng: 18.8297967 },
    { lat: 49.0453586, lng: 18.8301657 },
    { lat: 49.0452845, lng: 18.830635 },
    { lat: 49.0450859, lng: 18.8315929 },
    { lat: 49.0450613, lng: 18.8319456 },
    { lat: 49.0452855, lng: 18.8322922 },
    { lat: 49.0454423, lng: 18.832661 },
    { lat: 49.0455568, lng: 18.8329798 },
    { lat: 49.0455887, lng: 18.8332261 },
    { lat: 49.0453891, lng: 18.8341191 },
    { lat: 49.0455998, lng: 18.8349063 },
    { lat: 49.0458019, lng: 18.8354802 },
    { lat: 49.045773, lng: 18.8361648 },
    { lat: 49.0456476, lng: 18.8369217 },
    { lat: 49.0456457, lng: 18.8376731 },
    { lat: 49.0456347, lng: 18.838247 },
    { lat: 49.0456219, lng: 18.8385897 },
    { lat: 49.0455829, lng: 18.8388603 },
    { lat: 49.0454633, lng: 18.8392768 },
    { lat: 49.0453526, lng: 18.8394011 },
    { lat: 49.0452179, lng: 18.8393931 },
    { lat: 49.045141, lng: 18.839367 },
    { lat: 49.0450638, lng: 18.8392914 },
    { lat: 49.0449927, lng: 18.8392 },
    { lat: 49.0448286, lng: 18.8391017 },
    { lat: 49.0447448, lng: 18.8390934 },
    { lat: 49.0446705, lng: 18.8392054 },
    { lat: 49.0446466, lng: 18.8394429 },
    { lat: 49.0447442, lng: 18.8397817 },
    { lat: 49.044813, lng: 18.8408324 },
    { lat: 49.044782, lng: 18.841193 },
    { lat: 49.0446719, lng: 18.8415972 },
    { lat: 49.0445547, lng: 18.8420778 },
    { lat: 49.0445709, lng: 18.842873 },
    { lat: 49.0447031, lng: 18.8434485 },
    { lat: 49.0449218, lng: 18.8439932 },
    { lat: 49.0450048, lng: 18.8442231 },
    { lat: 49.0449236, lng: 18.8442878 },
    { lat: 49.0449176, lng: 18.8444786 },
    { lat: 49.0448133, lng: 18.8452333 },
    { lat: 49.0450335, lng: 18.8461376 },
    { lat: 49.0450227, lng: 18.8469782 },
    { lat: 49.0449207, lng: 18.8474935 },
    { lat: 49.0449125, lng: 18.8483668 },
    { lat: 49.0449841, lng: 18.8489215 },
    { lat: 49.045038, lng: 18.8492472 },
    { lat: 49.045196, lng: 18.8497733 },
    { lat: 49.0454114, lng: 18.850189 },
    { lat: 49.0455223, lng: 18.850637 },
    { lat: 49.0454286, lng: 18.8510521 },
    { lat: 49.045256, lng: 18.851357 },
    { lat: 49.0450695, lng: 18.8518504 },
    { lat: 49.0448426, lng: 18.8521305 },
    { lat: 49.0444973, lng: 18.8525648 },
    { lat: 49.0443166, lng: 18.8529332 },
    { lat: 49.0439607, lng: 18.8534863 },
    { lat: 49.0439184, lng: 18.8535643 },
    { lat: 49.0437042, lng: 18.8540901 },
    { lat: 49.0431503, lng: 18.8550938 },
    { lat: 49.0423733, lng: 18.8556607 },
    { lat: 49.0419665, lng: 18.8561444 },
    { lat: 49.0417251, lng: 18.8571528 },
    { lat: 49.0413059, lng: 18.858912 },
    { lat: 49.0411289, lng: 18.8598761 },
    { lat: 49.041106, lng: 18.8604417 },
    { lat: 49.0411987, lng: 18.861676 },
    { lat: 49.0412233, lng: 18.8620213 },
    { lat: 49.0412086, lng: 18.8626585 },
    { lat: 49.041381, lng: 18.8633561 },
    { lat: 49.0414228, lng: 18.8643668 },
    { lat: 49.0413935, lng: 18.8652055 },
    { lat: 49.0413281, lng: 18.8659281 },
    { lat: 49.0409409, lng: 18.8663159 },
    { lat: 49.0408424, lng: 18.8681699 },
    { lat: 49.0407854, lng: 18.8689932 },
    { lat: 49.0412257, lng: 18.8689409 },
    { lat: 49.0414802, lng: 18.8689559 },
    { lat: 49.04137, lng: 18.8724757 },
    { lat: 49.0416506, lng: 18.8726717 },
    { lat: 49.0422695, lng: 18.8730665 },
    { lat: 49.042503, lng: 18.8732243 },
    { lat: 49.0424172, lng: 18.8741631 },
    { lat: 49.0423747, lng: 18.8744356 },
    { lat: 49.0421999, lng: 18.8752704 },
    { lat: 49.0419787, lng: 18.8757063 },
    { lat: 49.041862, lng: 18.8761773 },
    { lat: 49.0417053, lng: 18.8768396 },
    { lat: 49.0415874, lng: 18.877287 },
    { lat: 49.0414481, lng: 18.8778466 },
    { lat: 49.0414265, lng: 18.8781075 },
    { lat: 49.041426, lng: 18.8782966 },
    { lat: 49.0414499, lng: 18.8785655 },
    { lat: 49.0415263, lng: 18.8790219 },
    { lat: 49.0415559, lng: 18.8794493 },
    { lat: 49.0415941, lng: 18.879775 },
    { lat: 49.0415852, lng: 18.8798871 },
    { lat: 49.0415358, lng: 18.8802006 },
    { lat: 49.0414943, lng: 18.8805333 },
    { lat: 49.0414068, lng: 18.8810476 },
    { lat: 49.0412901, lng: 18.8818524 },
    { lat: 49.0411395, lng: 18.8827853 },
    { lat: 49.0409527, lng: 18.8839822 },
    { lat: 49.0408916, lng: 18.8843546 },
    { lat: 49.0407548, lng: 18.8852269 },
    { lat: 49.0407123, lng: 18.8855176 },
    { lat: 49.040675, lng: 18.8858469 },
    { lat: 49.0406356, lng: 18.8860449 },
    { lat: 49.0406004, lng: 18.8861519 },
    { lat: 49.040594, lng: 18.8865577 },
    { lat: 49.0406013, lng: 18.8876709 },
    { lat: 49.0406009, lng: 18.8883872 },
    { lat: 49.0405985, lng: 18.8890707 },
    { lat: 49.0405946, lng: 18.8895445 },
    { lat: 49.0405975, lng: 18.8901738 },
    { lat: 49.0405989, lng: 18.8913042 },
    { lat: 49.0405898, lng: 18.8919407 },
    { lat: 49.0406079, lng: 18.8926681 },
    { lat: 49.0406072, lng: 18.8933201 },
    { lat: 49.0406153, lng: 18.8935854 },
    { lat: 49.0406069, lng: 18.8942628 },
    { lat: 49.040592, lng: 18.8948079 },
    { lat: 49.0409071, lng: 18.8949489 },
    { lat: 49.041152, lng: 18.8950447 },
    { lat: 49.0416017, lng: 18.8952578 },
    { lat: 49.0422262, lng: 18.8955463 },
    { lat: 49.0426591, lng: 18.8957639 },
    { lat: 49.0426041, lng: 18.8959695 },
    { lat: 49.0425924, lng: 18.896115 },
    { lat: 49.0423798, lng: 18.8968287 },
    { lat: 49.0421866, lng: 18.8974403 },
    { lat: 49.0420308, lng: 18.8979881 },
    { lat: 49.0419194, lng: 18.8983887 },
    { lat: 49.0417399, lng: 18.899005 },
    { lat: 49.0415724, lng: 18.8995409 },
    { lat: 49.0413328, lng: 18.9002808 },
    { lat: 49.0411313, lng: 18.900954 },
    { lat: 49.0411178, lng: 18.9010404 },
    { lat: 49.0410727, lng: 18.9012171 },
    { lat: 49.041001, lng: 18.9013589 },
    { lat: 49.0408827, lng: 18.9015346 },
    { lat: 49.0407877, lng: 18.9016641 },
    { lat: 49.0407057, lng: 18.9018272 },
    { lat: 49.0405762, lng: 18.902006 },
    { lat: 49.0404709, lng: 18.9020871 },
    { lat: 49.0403702, lng: 18.9022101 },
    { lat: 49.0402833, lng: 18.9022691 },
    { lat: 49.0401916, lng: 18.9023314 },
    { lat: 49.0402546, lng: 18.9025989 },
    { lat: 49.0403408, lng: 18.9030929 },
    { lat: 49.0403658, lng: 18.9033192 },
    { lat: 49.0403671, lng: 18.9036968 },
    { lat: 49.0403603, lng: 18.9040498 },
    { lat: 49.0403199, lng: 18.9043173 },
    { lat: 49.0402146, lng: 18.9042862 },
    { lat: 49.0399496, lng: 18.9042834 },
    { lat: 49.0396817, lng: 18.9045479 },
    { lat: 49.0393816, lng: 18.9048442 },
    { lat: 49.0391953, lng: 18.9053463 },
    { lat: 49.0391519, lng: 18.9055149 },
    { lat: 49.0391117, lng: 18.9057403 },
    { lat: 49.0391041, lng: 18.9059665 },
    { lat: 49.0391511, lng: 18.9061611 },
    { lat: 49.0392675, lng: 18.906363 },
    { lat: 49.0395038, lng: 18.906641 },
    { lat: 49.0396912, lng: 18.9068877 },
    { lat: 49.0398266, lng: 18.907157 },
    { lat: 49.0400204, lng: 18.9072891 },
    { lat: 49.0401554, lng: 18.9073048 },
    { lat: 49.0402392, lng: 18.9071551 },
    { lat: 49.0403719, lng: 18.9070596 },
    { lat: 49.040449, lng: 18.907246 },
    { lat: 49.0405805, lng: 18.907394 },
    { lat: 49.0406972, lng: 18.9074716 },
    { lat: 49.0407812, lng: 18.9074829 },
    { lat: 49.0410607, lng: 18.9074793 },
    { lat: 49.0413654, lng: 18.9073521 },
    { lat: 49.0414834, lng: 18.90728 },
    { lat: 49.0416889, lng: 18.9072385 },
    { lat: 49.0419319, lng: 18.9073421 },
    { lat: 49.0427456, lng: 18.9073819 },
    { lat: 49.0431839, lng: 18.9074581 },
    { lat: 49.0435412, lng: 18.9074131 },
    { lat: 49.0438363, lng: 18.9072978 },
    { lat: 49.0441165, lng: 18.9070674 },
    { lat: 49.0453648, lng: 18.905457 },
    { lat: 49.0456796, lng: 18.9052401 },
    { lat: 49.045892, lng: 18.9052285 },
    { lat: 49.0461317, lng: 18.9052155 },
    { lat: 49.0469344, lng: 18.905459 },
    { lat: 49.0479011, lng: 18.9057309 },
    { lat: 49.0481199, lng: 18.9057356 },
    { lat: 49.0482989, lng: 18.9057939 },
    { lat: 49.0483878, lng: 18.9058373 },
    { lat: 49.0493496, lng: 18.9060595 },
    { lat: 49.0497119, lng: 18.9060653 },
    { lat: 49.0504262, lng: 18.9059893 },
    { lat: 49.0518498, lng: 18.9058061 },
    { lat: 49.0517363, lng: 18.9052383 },
    { lat: 49.0516845, lng: 18.9046218 },
    { lat: 49.0516978, lng: 18.9042162 },
    { lat: 49.0516919, lng: 18.9039229 },
    { lat: 49.0517197, lng: 18.9033847 },
    { lat: 49.0517584, lng: 18.9030646 },
    { lat: 49.0518961, lng: 18.9021795 },
    { lat: 49.052042, lng: 18.9012604 },
    { lat: 49.0520893, lng: 18.9009503 },
    { lat: 49.052116, lng: 18.900679 },
    { lat: 49.0521526, lng: 18.9001351 },
    { lat: 49.0522445, lng: 18.8987155 },
    { lat: 49.0522972, lng: 18.8977804 },
    { lat: 49.0523408, lng: 18.8970042 },
    { lat: 49.0523809, lng: 18.8965687 },
    { lat: 49.0523986, lng: 18.8959682 },
    { lat: 49.052416, lng: 18.8955826 },
    { lat: 49.0524168, lng: 18.8953295 },
    { lat: 49.0523594, lng: 18.8949376 },
    { lat: 49.0522914, lng: 18.8946649 },
    { lat: 49.0521607, lng: 18.8942238 },
    { lat: 49.0522094, lng: 18.8941846 },
    { lat: 49.052231, lng: 18.8941451 },
    { lat: 49.0521971, lng: 18.8937436 },
    { lat: 49.0521731, lng: 18.8932982 },
    { lat: 49.0520884, lng: 18.8928093 },
    { lat: 49.0520105, lng: 18.8926542 },
    { lat: 49.0519892, lng: 18.8922379 },
    { lat: 49.052016, lng: 18.8918796 },
    { lat: 49.0520364, lng: 18.8916576 },
    { lat: 49.0520958, lng: 18.891212 },
    { lat: 49.0521323, lng: 18.891043 },
    { lat: 49.0528984, lng: 18.8908097 },
    { lat: 49.0528672, lng: 18.8907077 },
    { lat: 49.0528681, lng: 18.8905922 },
    { lat: 49.0528149, lng: 18.8904335 },
    { lat: 49.0527398, lng: 18.8902691 },
    { lat: 49.0527103, lng: 18.8898898 },
    { lat: 49.052687, lng: 18.8893875 },
    { lat: 49.0527138, lng: 18.8893397 },
    { lat: 49.052663, lng: 18.8889884 },
    { lat: 49.0526856, lng: 18.8888995 },
    { lat: 49.0527981, lng: 18.8889365 },
    { lat: 49.0528393, lng: 18.8888644 },
    { lat: 49.0528573, lng: 18.8887501 },
    { lat: 49.0529179, lng: 18.8886362 },
    { lat: 49.0529233, lng: 18.8885442 },
    { lat: 49.0529551, lng: 18.8884808 },
    { lat: 49.0529463, lng: 18.8883966 },
    { lat: 49.0529712, lng: 18.8883347 },
    { lat: 49.053028, lng: 18.8882766 },
    { lat: 49.0530399, lng: 18.8881767 },
    { lat: 49.0530891, lng: 18.8879688 },
    { lat: 49.0530603, lng: 18.887914 },
    { lat: 49.0531306, lng: 18.887635 },
    { lat: 49.0531029, lng: 18.8874898 },
    { lat: 49.0531528, lng: 18.8873617 },
    { lat: 49.0531027, lng: 18.8872228 },
    { lat: 49.0531045, lng: 18.8871251 },
    { lat: 49.0532421, lng: 18.8870628 },
    { lat: 49.0533411, lng: 18.8869465 },
    { lat: 49.0532872, lng: 18.886794 },
    { lat: 49.0533667, lng: 18.8866753 },
    { lat: 49.0533746, lng: 18.8866086 },
    { lat: 49.0534311, lng: 18.8864563 },
    { lat: 49.0535391, lng: 18.8863881 },
    { lat: 49.0536028, lng: 18.8862652 },
    { lat: 49.0536682, lng: 18.8862423 },
    { lat: 49.0537028, lng: 18.8860139 },
    { lat: 49.0539323, lng: 18.8857614 },
    { lat: 49.0539562, lng: 18.8857891 },
    { lat: 49.0540467, lng: 18.8858175 },
    { lat: 49.0540718, lng: 18.8857995 },
    { lat: 49.0540873, lng: 18.8856866 },
    { lat: 49.0541125, lng: 18.8856721 },
    { lat: 49.0541858, lng: 18.8856918 },
    { lat: 49.0542093, lng: 18.8856886 },
    { lat: 49.0542727, lng: 18.8856312 },
    { lat: 49.0543176, lng: 18.8856492 },
    { lat: 49.0543959, lng: 18.8855677 },
    { lat: 49.0544752, lng: 18.8855101 },
    { lat: 49.054525, lng: 18.8853813 },
    { lat: 49.0545218, lng: 18.8852939 },
    { lat: 49.0545345, lng: 18.8852565 },
    { lat: 49.0546067, lng: 18.8852591 },
    { lat: 49.0546474, lng: 18.885306 },
    { lat: 49.05473, lng: 18.8852441 },
    { lat: 49.0546791, lng: 18.8851099 },
    { lat: 49.0547193, lng: 18.8850351 },
    { lat: 49.0550376, lng: 18.8849992 },
    { lat: 49.055049, lng: 18.8848907 },
    { lat: 49.0551858, lng: 18.8849651 },
    { lat: 49.0551877, lng: 18.8848484 },
    { lat: 49.0552578, lng: 18.8848066 },
    { lat: 49.0553733, lng: 18.8845108 },
    { lat: 49.0553673, lng: 18.8844565 },
    { lat: 49.0554345, lng: 18.8842698 },
    { lat: 49.0554507, lng: 18.8841022 },
    { lat: 49.0554034, lng: 18.8839942 },
    { lat: 49.0554295, lng: 18.8837794 },
    { lat: 49.055471, lng: 18.8837274 },
    { lat: 49.0555686, lng: 18.883701 },
    { lat: 49.0556164, lng: 18.8835311 },
    { lat: 49.0557098, lng: 18.8833946 },
    { lat: 49.0559252, lng: 18.8831983 },
    { lat: 49.056087, lng: 18.8830964 },
    { lat: 49.0561653, lng: 18.8829789 },
    { lat: 49.0561635, lng: 18.8829191 },
    { lat: 49.0562108, lng: 18.8828984 },
    { lat: 49.0562634, lng: 18.8828444 },
    { lat: 49.0564042, lng: 18.8827993 },
    { lat: 49.0564226, lng: 18.8827604 },
    { lat: 49.0564231, lng: 18.882701 },
    { lat: 49.0564489, lng: 18.8826789 },
    { lat: 49.0565079, lng: 18.8827111 },
    { lat: 49.0565936, lng: 18.8825978 },
    { lat: 49.0566155, lng: 18.8824769 },
    { lat: 49.0568635, lng: 18.88223 },
    { lat: 49.0569258, lng: 18.88204 },
    { lat: 49.0570097, lng: 18.8819391 },
    { lat: 49.0570926, lng: 18.8817952 },
    { lat: 49.0571914, lng: 18.8812795 },
    { lat: 49.0572747, lng: 18.8810381 },
    { lat: 49.0572834, lng: 18.8809619 },
    { lat: 49.0574268, lng: 18.8808568 },
    { lat: 49.0573973, lng: 18.8804142 },
    { lat: 49.057438, lng: 18.8800886 },
    { lat: 49.0576828, lng: 18.8796692 },
    { lat: 49.0577483, lng: 18.8795633 },
    { lat: 49.0582114, lng: 18.8792031 },
    { lat: 49.0584786, lng: 18.8778387 },
    { lat: 49.0585043, lng: 18.8777451 },
    { lat: 49.0586186, lng: 18.8772688 },
    { lat: 49.0589591, lng: 18.8767438 },
    { lat: 49.0595051, lng: 18.8756925 },
    { lat: 49.0595069, lng: 18.8755513 },
    { lat: 49.0595926, lng: 18.8754631 },
    { lat: 49.0597274, lng: 18.8750369 },
    { lat: 49.0598651, lng: 18.8747973 },
    { lat: 49.0600515, lng: 18.8744282 },
    { lat: 49.0603038, lng: 18.8740078 },
    { lat: 49.060283, lng: 18.873819 },
    { lat: 49.0607769, lng: 18.8724557 },
    { lat: 49.0609321, lng: 18.8708887 },
    { lat: 49.0611535, lng: 18.8698435 },
    { lat: 49.0610674, lng: 18.8691328 },
    { lat: 49.061163, lng: 18.8685771 },
    { lat: 49.0612208, lng: 18.8678986 },
    { lat: 49.0613237, lng: 18.8673345 },
    { lat: 49.0615821, lng: 18.8665042 },
    { lat: 49.0617547, lng: 18.8662873 },
    { lat: 49.0618887, lng: 18.8661082 },
    { lat: 49.0621273, lng: 18.8656602 },
    { lat: 49.0621766, lng: 18.8655447 },
    { lat: 49.0621532, lng: 18.8655263 },
    { lat: 49.0626441, lng: 18.8643911 },
    { lat: 49.0627051, lng: 18.863582 },
    { lat: 49.0626911, lng: 18.8635732 },
    { lat: 49.062676, lng: 18.8635455 },
    { lat: 49.0626265, lng: 18.8635007 },
    { lat: 49.0626759, lng: 18.8633446 },
    { lat: 49.0627085, lng: 18.8628571 },
    { lat: 49.062732, lng: 18.8623484 },
    { lat: 49.0627221, lng: 18.8618063 },
    { lat: 49.0626175, lng: 18.8611093 },
    { lat: 49.0628322, lng: 18.8605689 },
    { lat: 49.0628159, lng: 18.8600135 },
    { lat: 49.0631644, lng: 18.8596819 },
    { lat: 49.0633683, lng: 18.859484 },
    { lat: 49.0635792, lng: 18.858912 },
    { lat: 49.0635905, lng: 18.8581901 },
    { lat: 49.0637749, lng: 18.8578506 },
    { lat: 49.0638404, lng: 18.8575451 },
    { lat: 49.0640364, lng: 18.8571892 },
    { lat: 49.0640109, lng: 18.8568021 },
    { lat: 49.0641978, lng: 18.8563156 },
    { lat: 49.0642419, lng: 18.8557031 },
    { lat: 49.0644575, lng: 18.8552269 },
    { lat: 49.0645865, lng: 18.8548415 },
    { lat: 49.0646077, lng: 18.854638 },
    { lat: 49.0647058, lng: 18.8534354 },
    { lat: 49.0647859, lng: 18.8525345 },
    { lat: 49.0649695, lng: 18.851326 },
    { lat: 49.0650623, lng: 18.8509636 },
    { lat: 49.0651156, lng: 18.8503956 },
    { lat: 49.0652315, lng: 18.8498658 },
    { lat: 49.0651875, lng: 18.8493418 },
    { lat: 49.0652217, lng: 18.8483625 },
    { lat: 49.065153, lng: 18.8478188 },
    { lat: 49.0650898, lng: 18.8473984 },
    { lat: 49.0651406, lng: 18.8465898 },
    { lat: 49.0651622, lng: 18.8461345 },
    { lat: 49.0651912, lng: 18.8456222 },
    { lat: 49.0652442, lng: 18.8452308 },
    { lat: 49.0652657, lng: 18.8450319 },
    { lat: 49.0653585, lng: 18.8449536 },
    { lat: 49.0654328, lng: 18.8446445 },
    { lat: 49.0657144, lng: 18.8438302 },
    { lat: 49.0657775, lng: 18.8435649 },
    { lat: 49.0664041, lng: 18.8422272 },
    { lat: 49.0665817, lng: 18.8417105 },
    { lat: 49.0669244, lng: 18.8403967 },
    { lat: 49.0669374, lng: 18.8396403 },
    { lat: 49.0670166, lng: 18.8389264 },
    { lat: 49.0671051, lng: 18.8383664 },
    { lat: 49.0677556, lng: 18.8368125 },
    { lat: 49.0681958, lng: 18.8362324 },
    { lat: 49.0687707, lng: 18.8353337 },
    { lat: 49.0716337, lng: 18.8339796 },
    { lat: 49.0720818, lng: 18.8323289 },
    { lat: 49.072683, lng: 18.8302154 },
    { lat: 49.0728051, lng: 18.8297836 },
    { lat: 49.0734226, lng: 18.8280938 },
    { lat: 49.0740892, lng: 18.8263751 },
    { lat: 49.0750867, lng: 18.8253847 },
    { lat: 49.0764542, lng: 18.8235446 },
    { lat: 49.0774618, lng: 18.8222305 },
    { lat: 49.0785015, lng: 18.8205086 },
    { lat: 49.0790021, lng: 18.8199627 },
    { lat: 49.0794205, lng: 18.8195471 },
    { lat: 49.0798097, lng: 18.8188746 },
    { lat: 49.0802568, lng: 18.8180361 },
    { lat: 49.080391, lng: 18.8156191 },
    { lat: 49.0801919, lng: 18.8145811 },
    { lat: 49.0796437, lng: 18.8116816 },
    { lat: 49.079409, lng: 18.8109141 },
    { lat: 49.0793426, lng: 18.8105901 },
    { lat: 49.0791394, lng: 18.8101746 },
    { lat: 49.0790231, lng: 18.809517 },
    { lat: 49.0790167, lng: 18.8090667 },
    { lat: 49.0789801, lng: 18.8085556 },
    { lat: 49.0788725, lng: 18.8080931 },
    { lat: 49.0783749, lng: 18.8073223 },
    { lat: 49.0781179, lng: 18.8070197 },
    { lat: 49.078189, lng: 18.806727 },
  ],
  Diaková: [
    { lat: 49.0692845, lng: 18.9645429 },
    { lat: 49.0691175, lng: 18.9646742 },
    { lat: 49.0689472, lng: 18.9648768 },
    { lat: 49.0687844, lng: 18.9649972 },
    { lat: 49.068609, lng: 18.9650519 },
    { lat: 49.0684422, lng: 18.9650555 },
    { lat: 49.0680574, lng: 18.9649999 },
    { lat: 49.0671576, lng: 18.9651086 },
    { lat: 49.0661253, lng: 18.9648079 },
    { lat: 49.0645706, lng: 18.9645837 },
    { lat: 49.0637666, lng: 18.9644458 },
    { lat: 49.0625434, lng: 18.9638672 },
    { lat: 49.0625571, lng: 18.9633343 },
    { lat: 49.0614385, lng: 18.9629693 },
    { lat: 49.0602956, lng: 18.9626862 },
    { lat: 49.0592947, lng: 18.9624905 },
    { lat: 49.0583282, lng: 18.9623979 },
    { lat: 49.0574799, lng: 18.962474 },
    { lat: 49.0558722, lng: 18.9638806 },
    { lat: 49.053983, lng: 18.9643124 },
    { lat: 49.053333, lng: 18.9638765 },
    { lat: 49.0525068, lng: 18.9634781 },
    { lat: 49.0523751, lng: 18.9635269 },
    { lat: 49.052171, lng: 18.9633258 },
    { lat: 49.0518376, lng: 18.9630164 },
    { lat: 49.0517812, lng: 18.9629749 },
    { lat: 49.0516752, lng: 18.9628607 },
    { lat: 49.0515343, lng: 18.962727 },
    { lat: 49.0505274, lng: 18.9618983 },
    { lat: 49.0498203, lng: 18.9611841 },
    { lat: 49.0497347, lng: 18.961092 },
    { lat: 49.0495846, lng: 18.9611567 },
    { lat: 49.0494178, lng: 18.961316 },
    { lat: 49.0491486, lng: 18.9617195 },
    { lat: 49.0486825, lng: 18.9623389 },
    { lat: 49.048669, lng: 18.9623384 },
    { lat: 49.0486001, lng: 18.9624708 },
    { lat: 49.0485866, lng: 18.9624698 },
    { lat: 49.0484905, lng: 18.9626665 },
    { lat: 49.0482695, lng: 18.9630198 },
    { lat: 49.0481247, lng: 18.9632599 },
    { lat: 49.0480224, lng: 18.9636007 },
    { lat: 49.0478857, lng: 18.9641961 },
    { lat: 49.0475757, lng: 18.9654679 },
    { lat: 49.0471154, lng: 18.9673852 },
    { lat: 49.0469202, lng: 18.9679607 },
    { lat: 49.0466967, lng: 18.9684943 },
    { lat: 49.0462295, lng: 18.9696677 },
    { lat: 49.0460019, lng: 18.9701841 },
    { lat: 49.0453607, lng: 18.9714438 },
    { lat: 49.0453972, lng: 18.9714935 },
    { lat: 49.0453753, lng: 18.9715977 },
    { lat: 49.0452807, lng: 18.9716012 },
    { lat: 49.0454636, lng: 18.9722757 },
    { lat: 49.0455612, lng: 18.9724688 },
    { lat: 49.0457073, lng: 18.9726346 },
    { lat: 49.0463604, lng: 18.9731141 },
    { lat: 49.0467686, lng: 18.9734287 },
    { lat: 49.0469772, lng: 18.9736764 },
    { lat: 49.0470653, lng: 18.9738361 },
    { lat: 49.0479083, lng: 18.9737152 },
    { lat: 49.0483902, lng: 18.9736352 },
    { lat: 49.0483803, lng: 18.974178 },
    { lat: 49.0484822, lng: 18.974973 },
    { lat: 49.0507253, lng: 18.9770841 },
    { lat: 49.0542733, lng: 18.9804235 },
    { lat: 49.0545328, lng: 18.9808095 },
    { lat: 49.0555304, lng: 18.9800247 },
    { lat: 49.0568184, lng: 18.9790485 },
    { lat: 49.0571651, lng: 18.9788118 },
    { lat: 49.0573957, lng: 18.9786249 },
    { lat: 49.0580127, lng: 18.9783603 },
    { lat: 49.0593931, lng: 18.9791315 },
    { lat: 49.0610045, lng: 18.979988 },
    { lat: 49.0610813, lng: 18.9792104 },
    { lat: 49.0612448, lng: 18.9775726 },
    { lat: 49.0618132, lng: 18.9767722 },
    { lat: 49.0621705, lng: 18.9759205 },
    { lat: 49.0623188, lng: 18.9745446 },
    { lat: 49.0622434, lng: 18.9734278 },
    { lat: 49.0631813, lng: 18.9734284 },
    { lat: 49.0634086, lng: 18.9737107 },
    { lat: 49.0640167, lng: 18.9738317 },
    { lat: 49.0651064, lng: 18.9746515 },
    { lat: 49.0654969, lng: 18.975059 },
    { lat: 49.0658466, lng: 18.9745723 },
    { lat: 49.0673891, lng: 18.9729646 },
    { lat: 49.0676161, lng: 18.9729086 },
    { lat: 49.0676834, lng: 18.9729212 },
    { lat: 49.0676648, lng: 18.9726879 },
    { lat: 49.067747, lng: 18.9726844 },
    { lat: 49.067763, lng: 18.9722651 },
    { lat: 49.0677827, lng: 18.9720989 },
    { lat: 49.0678579, lng: 18.9718409 },
    { lat: 49.0680235, lng: 18.9713261 },
    { lat: 49.0683075, lng: 18.9712322 },
    { lat: 49.0684309, lng: 18.9712023 },
    { lat: 49.0686079, lng: 18.9711091 },
    { lat: 49.0687257, lng: 18.9710097 },
    { lat: 49.0689184, lng: 18.9707632 },
    { lat: 49.0689382, lng: 18.9707303 },
    { lat: 49.0690243, lng: 18.9706473 },
    { lat: 49.0691294, lng: 18.9704984 },
    { lat: 49.0692807, lng: 18.9703452 },
    { lat: 49.0694001, lng: 18.9702536 },
    { lat: 49.0697009, lng: 18.970063 },
    { lat: 49.0697847, lng: 18.9700098 },
    { lat: 49.0700557, lng: 18.9698171 },
    { lat: 49.0700476, lng: 18.9695688 },
    { lat: 49.0697839, lng: 18.9674912 },
    { lat: 49.0694711, lng: 18.9652672 },
    { lat: 49.0692908, lng: 18.9645554 },
    { lat: 49.0692845, lng: 18.9645429 },
  ],
  DolnýKalník: [
    { lat: 49.0350515, lng: 18.9812806 },
    { lat: 49.035318, lng: 18.9811784 },
    { lat: 49.0355574, lng: 18.9810496 },
    { lat: 49.0361352, lng: 18.980546 },
    { lat: 49.0367842, lng: 18.9800249 },
    { lat: 49.037143, lng: 18.9798268 },
    { lat: 49.0379226, lng: 18.979391 },
    { lat: 49.0380585, lng: 18.9792246 },
    { lat: 49.0386813, lng: 18.9780704 },
    { lat: 49.0388231, lng: 18.9777527 },
    { lat: 49.0391164, lng: 18.9769485 },
    { lat: 49.0392817, lng: 18.9765167 },
    { lat: 49.0396674, lng: 18.9757836 },
    { lat: 49.0399493, lng: 18.975133 },
    { lat: 49.0404632, lng: 18.9738793 },
    { lat: 49.0407062, lng: 18.9732634 },
    { lat: 49.0409744, lng: 18.9721684 },
    { lat: 49.041091, lng: 18.9716172 },
    { lat: 49.0411986, lng: 18.9711565 },
    { lat: 49.0413294, lng: 18.9707564 },
    { lat: 49.0414977, lng: 18.9703832 },
    { lat: 49.0419916, lng: 18.9693969 },
    { lat: 49.0422537, lng: 18.9689185 },
    { lat: 49.0425545, lng: 18.9685118 },
    { lat: 49.0432791, lng: 18.9675001 },
    { lat: 49.043894, lng: 18.9666391 },
    { lat: 49.0439987, lng: 18.9665028 },
    { lat: 49.0444181, lng: 18.9659195 },
    { lat: 49.0455142, lng: 18.9642514 },
    { lat: 49.045911, lng: 18.9636462 },
    { lat: 49.0463811, lng: 18.9628455 },
    { lat: 49.0465789, lng: 18.962449 },
    { lat: 49.0466541, lng: 18.9622224 },
    { lat: 49.0467972, lng: 18.9617291 },
    { lat: 49.0468585, lng: 18.9614926 },
    { lat: 49.0469702, lng: 18.9609581 },
    { lat: 49.0470153, lng: 18.9606459 },
    { lat: 49.0464369, lng: 18.9607329 },
    { lat: 49.0459245, lng: 18.9608773 },
    { lat: 49.0457772, lng: 18.9609319 },
    { lat: 49.045572, lng: 18.960148 },
    { lat: 49.0456306, lng: 18.9601063 },
    { lat: 49.0456106, lng: 18.9600234 },
    { lat: 49.0456209, lng: 18.9599568 },
    { lat: 49.0456455, lng: 18.9599101 },
    { lat: 49.0457657, lng: 18.9598349 },
    { lat: 49.0455227, lng: 18.9588836 },
    { lat: 49.0454762, lng: 18.9585987 },
    { lat: 49.0458689, lng: 18.9583515 },
    { lat: 49.0462223, lng: 18.9580478 },
    { lat: 49.046742, lng: 18.9578041 },
    { lat: 49.0466706, lng: 18.9577754 },
    { lat: 49.0463079, lng: 18.9568435 },
    { lat: 49.0461256, lng: 18.9565601 },
    { lat: 49.0451871, lng: 18.9549685 },
    { lat: 49.0451314, lng: 18.9548917 },
    { lat: 49.0444501, lng: 18.9538361 },
    { lat: 49.0423956, lng: 18.9532037 },
    { lat: 49.04104, lng: 18.95427 },
    { lat: 49.0404303, lng: 18.9546728 },
    { lat: 49.0398378, lng: 18.9553077 },
    { lat: 49.0389837, lng: 18.9559898 },
    { lat: 49.0385001, lng: 18.9561365 },
    { lat: 49.0378831, lng: 18.9561611 },
    { lat: 49.0373739, lng: 18.9562727 },
    { lat: 49.0371599, lng: 18.9563081 },
    { lat: 49.0367151, lng: 18.9563846 },
    { lat: 49.0363384, lng: 18.9562228 },
    { lat: 49.0358207, lng: 18.9560956 },
    { lat: 49.036003, lng: 18.9569701 },
    { lat: 49.0361353, lng: 18.9575599 },
    { lat: 49.0363157, lng: 18.9588302 },
    { lat: 49.0363351, lng: 18.9604424 },
    { lat: 49.0364346, lng: 18.9611584 },
    { lat: 49.0369338, lng: 18.9617159 },
    { lat: 49.0373226, lng: 18.9621825 },
    { lat: 49.0378504, lng: 18.9627615 },
    { lat: 49.0380788, lng: 18.9632426 },
    { lat: 49.0379311, lng: 18.9634895 },
    { lat: 49.0368853, lng: 18.9645951 },
    { lat: 49.037056, lng: 18.9648695 },
    { lat: 49.0369828, lng: 18.9652057 },
    { lat: 49.0370934, lng: 18.9652952 },
    { lat: 49.0372363, lng: 18.9653792 },
    { lat: 49.0372663, lng: 18.9654414 },
    { lat: 49.0373347, lng: 18.9654999 },
    { lat: 49.0372795, lng: 18.9656795 },
    { lat: 49.037435, lng: 18.9656553 },
    { lat: 49.0373832, lng: 18.9660153 },
    { lat: 49.0375256, lng: 18.9663547 },
    { lat: 49.0376798, lng: 18.9663493 },
    { lat: 49.0375733, lng: 18.9670527 },
    { lat: 49.0375761, lng: 18.9670887 },
    { lat: 49.0375434, lng: 18.9673537 },
    { lat: 49.0370617, lng: 18.9706784 },
    { lat: 49.0364227, lng: 18.9726926 },
    { lat: 49.0360579, lng: 18.973466 },
    { lat: 49.0359111, lng: 18.9740408 },
    { lat: 49.0361177, lng: 18.9746761 },
    { lat: 49.0356008, lng: 18.9760479 },
    { lat: 49.0362606, lng: 18.9761887 },
    { lat: 49.0358714, lng: 18.977325 },
    { lat: 49.0359836, lng: 18.9780761 },
    { lat: 49.0351145, lng: 18.9790182 },
    { lat: 49.0349179, lng: 18.9794543 },
    { lat: 49.0351384, lng: 18.9799626 },
    { lat: 49.0350515, lng: 18.9812806 },
  ],
  Dražkovce: [
    { lat: 49.0484905, lng: 18.9626665 },
    { lat: 49.0485866, lng: 18.9624698 },
    { lat: 49.0486001, lng: 18.9624708 },
    { lat: 49.048669, lng: 18.9623384 },
    { lat: 49.0486825, lng: 18.9623389 },
    { lat: 49.0491486, lng: 18.9617195 },
    { lat: 49.0494178, lng: 18.961316 },
    { lat: 49.0495846, lng: 18.9611567 },
    { lat: 49.0497347, lng: 18.961092 },
    { lat: 49.0498203, lng: 18.9611841 },
    { lat: 49.0505274, lng: 18.9618983 },
    { lat: 49.0515343, lng: 18.962727 },
    { lat: 49.0516752, lng: 18.9628607 },
    { lat: 49.0517812, lng: 18.9629749 },
    { lat: 49.0518376, lng: 18.9630164 },
    { lat: 49.052171, lng: 18.9633258 },
    { lat: 49.0523751, lng: 18.9635269 },
    { lat: 49.0525068, lng: 18.9634781 },
    { lat: 49.053333, lng: 18.9638765 },
    { lat: 49.053983, lng: 18.9643124 },
    { lat: 49.0558722, lng: 18.9638806 },
    { lat: 49.0574799, lng: 18.962474 },
    { lat: 49.0583282, lng: 18.9623979 },
    { lat: 49.0592947, lng: 18.9624905 },
    { lat: 49.0602956, lng: 18.9626862 },
    { lat: 49.0614385, lng: 18.9629693 },
    { lat: 49.0625571, lng: 18.9633343 },
    { lat: 49.0625434, lng: 18.9638672 },
    { lat: 49.0637666, lng: 18.9644458 },
    { lat: 49.0645706, lng: 18.9645837 },
    { lat: 49.0645704, lng: 18.9640635 },
    { lat: 49.0645918, lng: 18.9636188 },
    { lat: 49.0646963, lng: 18.962048 },
    { lat: 49.0647367, lng: 18.9615978 },
    { lat: 49.0647812, lng: 18.9611601 },
    { lat: 49.0648325, lng: 18.9607263 },
    { lat: 49.0648787, lng: 18.9603689 },
    { lat: 49.064973, lng: 18.9597801 },
    { lat: 49.0650712, lng: 18.9592911 },
    { lat: 49.065191, lng: 18.9588065 },
    { lat: 49.0652557, lng: 18.9585016 },
    { lat: 49.065292, lng: 18.9582845 },
    { lat: 49.0655279, lng: 18.9569503 },
    { lat: 49.0656127, lng: 18.9565327 },
    { lat: 49.0657249, lng: 18.955783 },
    { lat: 49.0657543, lng: 18.9556525 },
    { lat: 49.0657619, lng: 18.9554467 },
    { lat: 49.0656902, lng: 18.9548737 },
    { lat: 49.0656128, lng: 18.9545258 },
    { lat: 49.0654092, lng: 18.9538909 },
    { lat: 49.065368, lng: 18.9537875 },
    { lat: 49.0652717, lng: 18.9536428 },
    { lat: 49.0650545, lng: 18.9529826 },
    { lat: 49.0650039, lng: 18.9530097 },
    { lat: 49.0647374, lng: 18.952131 },
    { lat: 49.0646415, lng: 18.9515709 },
    { lat: 49.0646974, lng: 18.9512062 },
    { lat: 49.0638909, lng: 18.9510203 },
    { lat: 49.0635169, lng: 18.9509358 },
    { lat: 49.0639942, lng: 18.9494299 },
    { lat: 49.0642442, lng: 18.9481321 },
    { lat: 49.0641049, lng: 18.9481431 },
    { lat: 49.0634715, lng: 18.9465653 },
    { lat: 49.0631536, lng: 18.9466989 },
    { lat: 49.0629144, lng: 18.946874 },
    { lat: 49.0617432, lng: 18.9477023 },
    { lat: 49.0615048, lng: 18.9472018 },
    { lat: 49.0609095, lng: 18.947758 },
    { lat: 49.0606388, lng: 18.9472882 },
    { lat: 49.0600614, lng: 18.9476201 },
    { lat: 49.0597132, lng: 18.9472888 },
    { lat: 49.0591548, lng: 18.9478371 },
    { lat: 49.0590546, lng: 18.9476626 },
    { lat: 49.0587912, lng: 18.94798 },
    { lat: 49.0587471, lng: 18.9479118 },
    { lat: 49.0586226, lng: 18.9480616 },
    { lat: 49.0585245, lng: 18.9479027 },
    { lat: 49.058104, lng: 18.9482626 },
    { lat: 49.0579619, lng: 18.9480175 },
    { lat: 49.0579371, lng: 18.947794 },
    { lat: 49.0578727, lng: 18.9478321 },
    { lat: 49.0577765, lng: 18.9477794 },
    { lat: 49.0577244, lng: 18.9478243 },
    { lat: 49.0576902, lng: 18.9479049 },
    { lat: 49.0576414, lng: 18.9480752 },
    { lat: 49.0575747, lng: 18.9482506 },
    { lat: 49.057465, lng: 18.9482217 },
    { lat: 49.0574298, lng: 18.948081 },
    { lat: 49.0572949, lng: 18.9477351 },
    { lat: 49.0571052, lng: 18.9477966 },
    { lat: 49.0570481, lng: 18.9478259 },
    { lat: 49.0569756, lng: 18.947953 },
    { lat: 49.0567349, lng: 18.9480093 },
    { lat: 49.0567064, lng: 18.9480665 },
    { lat: 49.0567215, lng: 18.9481223 },
    { lat: 49.0567043, lng: 18.9481617 },
    { lat: 49.0566645, lng: 18.9481541 },
    { lat: 49.0565666, lng: 18.9480031 },
    { lat: 49.0564901, lng: 18.9480045 },
    { lat: 49.0564611, lng: 18.9480338 },
    { lat: 49.0563145, lng: 18.9483432 },
    { lat: 49.0563195, lng: 18.948513 },
    { lat: 49.0562238, lng: 18.9486476 },
    { lat: 49.0561778, lng: 18.9486062 },
    { lat: 49.0561886, lng: 18.9485298 },
    { lat: 49.0562227, lng: 18.9484669 },
    { lat: 49.0562301, lng: 18.9484358 },
    { lat: 49.0562166, lng: 18.948304 },
    { lat: 49.0559496, lng: 18.9480179 },
    { lat: 49.0557942, lng: 18.9480904 },
    { lat: 49.0556948, lng: 18.9480855 },
    { lat: 49.0556814, lng: 18.9481996 },
    { lat: 49.0556318, lng: 18.9482647 },
    { lat: 49.0556052, lng: 18.9482768 },
    { lat: 49.0555632, lng: 18.9482264 },
    { lat: 49.0555763, lng: 18.9481256 },
    { lat: 49.0555755, lng: 18.9480682 },
    { lat: 49.0555583, lng: 18.9480595 },
    { lat: 49.0554862, lng: 18.9480812 },
    { lat: 49.0554267, lng: 18.9479573 },
    { lat: 49.0553764, lng: 18.9479424 },
    { lat: 49.0553156, lng: 18.9480121 },
    { lat: 49.0553057, lng: 18.9481279 },
    { lat: 49.0552085, lng: 18.9481867 },
    { lat: 49.0551289, lng: 18.9483096 },
    { lat: 49.0551008, lng: 18.948332 },
    { lat: 49.0550765, lng: 18.948342 },
    { lat: 49.0549171, lng: 18.9483338 },
    { lat: 49.0549052, lng: 18.9482765 },
    { lat: 49.054869, lng: 18.948252 },
    { lat: 49.054827, lng: 18.948249 },
    { lat: 49.0547872, lng: 18.9481301 },
    { lat: 49.0547447, lng: 18.9481347 },
    { lat: 49.0547204, lng: 18.9483036 },
    { lat: 49.0546678, lng: 18.9482627 },
    { lat: 49.0546416, lng: 18.9482823 },
    { lat: 49.0546434, lng: 18.9483831 },
    { lat: 49.0546271, lng: 18.9484163 },
    { lat: 49.0545607, lng: 18.9484197 },
    { lat: 49.0544835, lng: 18.9482541 },
    { lat: 49.0544935, lng: 18.9482094 },
    { lat: 49.0545711, lng: 18.9481119 },
    { lat: 49.0545394, lng: 18.9480886 },
    { lat: 49.0545567, lng: 18.9480514 },
    { lat: 49.0538277, lng: 18.9456328 },
    { lat: 49.0536522, lng: 18.9457077 },
    { lat: 49.0534907, lng: 18.9457935 },
    { lat: 49.0519055, lng: 18.9428975 },
    { lat: 49.0514041, lng: 18.9419692 },
    { lat: 49.0506797, lng: 18.940757 },
    { lat: 49.0494214, lng: 18.9386216 },
    { lat: 49.0494188, lng: 18.9386118 },
    { lat: 49.0491679, lng: 18.9386679 },
    { lat: 49.0488462, lng: 18.9388379 },
    { lat: 49.0482657, lng: 18.9389772 },
    { lat: 49.0479878, lng: 18.9390961 },
    { lat: 49.0476512, lng: 18.9392621 },
    { lat: 49.0475016, lng: 18.9393156 },
    { lat: 49.0472957, lng: 18.9393645 },
    { lat: 49.0468811, lng: 18.9394161 },
    { lat: 49.0463719, lng: 18.9394905 },
    { lat: 49.0459124, lng: 18.9396102 },
    { lat: 49.0447339, lng: 18.9400709 },
    { lat: 49.0444627, lng: 18.9401512 },
    { lat: 49.0439475, lng: 18.9402408 },
    { lat: 49.0436965, lng: 18.940248 },
    { lat: 49.0433972, lng: 18.9402152 },
    { lat: 49.0431612, lng: 18.9401652 },
    { lat: 49.0427687, lng: 18.940051 },
    { lat: 49.0424375, lng: 18.9398986 },
    { lat: 49.0420576, lng: 18.9396988 },
    { lat: 49.0416818, lng: 18.9394705 },
    { lat: 49.0411279, lng: 18.9391287 },
    { lat: 49.0406288, lng: 18.9387816 },
    { lat: 49.040426, lng: 18.938607 },
    { lat: 49.0402702, lng: 18.9384467 },
    { lat: 49.0400158, lng: 18.9382336 },
    { lat: 49.0397588, lng: 18.9379878 },
    { lat: 49.0393969, lng: 18.9376346 },
    { lat: 49.0392435, lng: 18.9374974 },
    { lat: 49.0390292, lng: 18.9373213 },
    { lat: 49.0389368, lng: 18.9372559 },
    { lat: 49.0387454, lng: 18.9371476 },
    { lat: 49.0385763, lng: 18.9370833 },
    { lat: 49.0383333, lng: 18.9370238 },
    { lat: 49.0379468, lng: 18.9369653 },
    { lat: 49.0377784, lng: 18.9369319 },
    { lat: 49.0375762, lng: 18.9368816 },
    { lat: 49.0369955, lng: 18.9367899 },
    { lat: 49.0369478, lng: 18.9367847 },
    { lat: 49.0365575, lng: 18.9367802 },
    { lat: 49.0362741, lng: 18.9367912 },
    { lat: 49.035971, lng: 18.9368192 },
    { lat: 49.0357629, lng: 18.9368516 },
    { lat: 49.0356599, lng: 18.9368676 },
    { lat: 49.0354743, lng: 18.9369397 },
    { lat: 49.0352236, lng: 18.9370457 },
    { lat: 49.0352262, lng: 18.9370515 },
    { lat: 49.0346168, lng: 18.9403274 },
    { lat: 49.0342513, lng: 18.941791 },
    { lat: 49.0343498, lng: 18.9417985 },
    { lat: 49.0343308, lng: 18.9418723 },
    { lat: 49.0330679, lng: 18.9471197 },
    { lat: 49.0323142, lng: 18.9500896 },
    { lat: 49.0324602, lng: 18.9519886 },
    { lat: 49.0339945, lng: 18.9523338 },
    { lat: 49.034636, lng: 18.953079 },
    { lat: 49.0352034, lng: 18.9548154 },
    { lat: 49.0358207, lng: 18.9560956 },
    { lat: 49.0363384, lng: 18.9562228 },
    { lat: 49.0367151, lng: 18.9563846 },
    { lat: 49.0371599, lng: 18.9563081 },
    { lat: 49.0373739, lng: 18.9562727 },
    { lat: 49.0378831, lng: 18.9561611 },
    { lat: 49.0385001, lng: 18.9561365 },
    { lat: 49.0389837, lng: 18.9559898 },
    { lat: 49.0398378, lng: 18.9553077 },
    { lat: 49.0404303, lng: 18.9546728 },
    { lat: 49.04104, lng: 18.95427 },
    { lat: 49.0423956, lng: 18.9532037 },
    { lat: 49.0444501, lng: 18.9538361 },
    { lat: 49.0451314, lng: 18.9548917 },
    { lat: 49.0451871, lng: 18.9549685 },
    { lat: 49.0461256, lng: 18.9565601 },
    { lat: 49.0463079, lng: 18.9568435 },
    { lat: 49.0466706, lng: 18.9577754 },
    { lat: 49.046742, lng: 18.9578041 },
    { lat: 49.0462223, lng: 18.9580478 },
    { lat: 49.0458689, lng: 18.9583515 },
    { lat: 49.0454762, lng: 18.9585987 },
    { lat: 49.0455227, lng: 18.9588836 },
    { lat: 49.0457657, lng: 18.9598349 },
    { lat: 49.0456455, lng: 18.9599101 },
    { lat: 49.0456209, lng: 18.9599568 },
    { lat: 49.0456106, lng: 18.9600234 },
    { lat: 49.0456306, lng: 18.9601063 },
    { lat: 49.045572, lng: 18.960148 },
    { lat: 49.0457772, lng: 18.9609319 },
    { lat: 49.0459245, lng: 18.9608773 },
    { lat: 49.0464369, lng: 18.9607329 },
    { lat: 49.0470153, lng: 18.9606459 },
    { lat: 49.0469702, lng: 18.9609581 },
    { lat: 49.0468585, lng: 18.9614926 },
    { lat: 49.0467972, lng: 18.9617291 },
    { lat: 49.0466541, lng: 18.9622224 },
    { lat: 49.0465789, lng: 18.962449 },
    { lat: 49.046754, lng: 18.962638 },
    { lat: 49.0472808, lng: 18.9618188 },
    { lat: 49.0474454, lng: 18.9615732 },
    { lat: 49.0478283, lng: 18.9621189 },
    { lat: 49.0484076, lng: 18.9625414 },
    { lat: 49.0484318, lng: 18.9625764 },
    { lat: 49.0484409, lng: 18.9626209 },
    { lat: 49.0484905, lng: 18.9626665 },
  ],
  Folkušová: [
    { lat: 48.9853896, lng: 18.9422917 },
    { lat: 48.9849597, lng: 18.94222 },
    { lat: 48.9845564, lng: 18.9421161 },
    { lat: 48.9834136, lng: 18.9417008 },
    { lat: 48.9833025, lng: 18.9410684 },
    { lat: 48.9828011, lng: 18.9411415 },
    { lat: 48.9826906, lng: 18.9411778 },
    { lat: 48.9825239, lng: 18.9412325 },
    { lat: 48.9822086, lng: 18.9413976 },
    { lat: 48.9816213, lng: 18.9415974 },
    { lat: 48.9813215, lng: 18.9416677 },
    { lat: 48.9809784, lng: 18.941682 },
    { lat: 48.980721, lng: 18.9415209 },
    { lat: 48.9801583, lng: 18.9404978 },
    { lat: 48.9799077, lng: 18.9398021 },
    { lat: 48.9797513, lng: 18.9390297 },
    { lat: 48.9796078, lng: 18.9382261 },
    { lat: 48.9794264, lng: 18.9382513 },
    { lat: 48.9792793, lng: 18.9378852 },
    { lat: 48.9789093, lng: 18.9374386 },
    { lat: 48.9783163, lng: 18.9383914 },
    { lat: 48.9781228, lng: 18.9387127 },
    { lat: 48.9777431, lng: 18.9394485 },
    { lat: 48.9774124, lng: 18.9389744 },
    { lat: 48.9772202, lng: 18.9385867 },
    { lat: 48.9771499, lng: 18.9382669 },
    { lat: 48.9770925, lng: 18.9373839 },
    { lat: 48.9771374, lng: 18.9369558 },
    { lat: 48.9771968, lng: 18.9365942 },
    { lat: 48.9772703, lng: 18.9360462 },
    { lat: 48.9762529, lng: 18.9355934 },
    { lat: 48.9728117, lng: 18.9352587 },
    { lat: 48.9722758, lng: 18.9352946 },
    { lat: 48.9722386, lng: 18.9351396 },
    { lat: 48.9722428, lng: 18.9345574 },
    { lat: 48.9724365, lng: 18.9344396 },
    { lat: 48.9725802, lng: 18.9342077 },
    { lat: 48.9726772, lng: 18.9341644 },
    { lat: 48.9730665, lng: 18.933879 },
    { lat: 48.973229, lng: 18.9335294 },
    { lat: 48.9733817, lng: 18.9332721 },
    { lat: 48.9736078, lng: 18.9327751 },
    { lat: 48.9735036, lng: 18.9324112 },
    { lat: 48.9733342, lng: 18.9325363 },
    { lat: 48.97309, lng: 18.9324135 },
    { lat: 48.9729667, lng: 18.9327631 },
    { lat: 48.9725735, lng: 18.9328311 },
    { lat: 48.972353, lng: 18.9330853 },
    { lat: 48.97226, lng: 18.9329652 },
    { lat: 48.9719939, lng: 18.9329878 },
    { lat: 48.9718529, lng: 18.9328543 },
    { lat: 48.9716162, lng: 18.932919 },
    { lat: 48.9714483, lng: 18.9333446 },
    { lat: 48.971106, lng: 18.9333076 },
    { lat: 48.9709066, lng: 18.9333773 },
    { lat: 48.9707781, lng: 18.9332442 },
    { lat: 48.9703514, lng: 18.9333918 },
    { lat: 48.9700111, lng: 18.9337953 },
    { lat: 48.9702047, lng: 18.9342287 },
    { lat: 48.9698457, lng: 18.9355106 },
    { lat: 48.9696238, lng: 18.9361123 },
    { lat: 48.969581, lng: 18.9363114 },
    { lat: 48.9693955, lng: 18.9365201 },
    { lat: 48.9690639, lng: 18.937318 },
    { lat: 48.9688477, lng: 18.9377689 },
    { lat: 48.9680717, lng: 18.9386504 },
    { lat: 48.9677219, lng: 18.9387782 },
    { lat: 48.9675308, lng: 18.9390335 },
    { lat: 48.9673724, lng: 18.9393991 },
    { lat: 48.96728, lng: 18.9398466 },
    { lat: 48.9671846, lng: 18.9401854 },
    { lat: 48.9644933, lng: 18.9418919 },
    { lat: 48.9646593, lng: 18.9422542 },
    { lat: 48.9648483, lng: 18.9429795 },
    { lat: 48.9651338, lng: 18.9433867 },
    { lat: 48.9652055, lng: 18.9437339 },
    { lat: 48.9655246, lng: 18.9447091 },
    { lat: 48.9655856, lng: 18.9449564 },
    { lat: 48.9652032, lng: 18.94482 },
    { lat: 48.9647957, lng: 18.9449183 },
    { lat: 48.9643943, lng: 18.9451005 },
    { lat: 48.9640263, lng: 18.9450212 },
    { lat: 48.964028, lng: 18.945321 },
    { lat: 48.9640117, lng: 18.9454227 },
    { lat: 48.9640047, lng: 18.9457981 },
    { lat: 48.9640136, lng: 18.9465032 },
    { lat: 48.9638916, lng: 18.9477231 },
    { lat: 48.9638436, lng: 18.9489088 },
    { lat: 48.9637969, lng: 18.9495691 },
    { lat: 48.9634211, lng: 18.9512514 },
    { lat: 48.9632833, lng: 18.9526023 },
    { lat: 48.9630665, lng: 18.9532197 },
    { lat: 48.962696, lng: 18.9552758 },
    { lat: 48.9624366, lng: 18.9561655 },
    { lat: 48.9620084, lng: 18.9577924 },
    { lat: 48.9617992, lng: 18.9579399 },
    { lat: 48.9614504, lng: 18.9581525 },
    { lat: 48.9612168, lng: 18.9584385 },
    { lat: 48.960896, lng: 18.9589377 },
    { lat: 48.960821, lng: 18.9593472 },
    { lat: 48.9606933, lng: 18.959675 },
    { lat: 48.9604296, lng: 18.9600803 },
    { lat: 48.96025, lng: 18.9605645 },
    { lat: 48.9600235, lng: 18.9621674 },
    { lat: 48.9589346, lng: 18.964601 },
    { lat: 48.9589232, lng: 18.9646146 },
    { lat: 48.9574027, lng: 18.9664365 },
    { lat: 48.957522, lng: 18.96688 },
    { lat: 48.9577629, lng: 18.9677624 },
    { lat: 48.9581151, lng: 18.9690635 },
    { lat: 48.9590519, lng: 18.9700238 },
    { lat: 48.9594428, lng: 18.9705742 },
    { lat: 48.9608915, lng: 18.9712417 },
    { lat: 48.961597, lng: 18.974606 },
    { lat: 48.9629181, lng: 18.9774851 },
    { lat: 48.9632921, lng: 18.979021 },
    { lat: 48.9633377, lng: 18.9815796 },
    { lat: 48.9635022, lng: 18.9824044 },
    { lat: 48.9635332, lng: 18.982841 },
    { lat: 48.9636541, lng: 18.9845883 },
    { lat: 48.9642777, lng: 18.9855563 },
    { lat: 48.9649398, lng: 18.9850492 },
    { lat: 48.9652342, lng: 18.9849374 },
    { lat: 48.9658732, lng: 18.9842603 },
    { lat: 48.9659649, lng: 18.9835539 },
    { lat: 48.9660785, lng: 18.9831679 },
    { lat: 48.9660668, lng: 18.9831377 },
    { lat: 48.9661841, lng: 18.9830706 },
    { lat: 48.9662185, lng: 18.9829051 },
    { lat: 48.9662401, lng: 18.9827056 },
    { lat: 48.9665418, lng: 18.981983 },
    { lat: 48.9670404, lng: 18.9812497 },
    { lat: 48.9671183, lng: 18.9809579 },
    { lat: 48.9675331, lng: 18.9806671 },
    { lat: 48.9677292, lng: 18.9807089 },
    { lat: 48.9680234, lng: 18.9799512 },
    { lat: 48.9685883, lng: 18.9794603 },
    { lat: 48.9690916, lng: 18.9783528 },
    { lat: 48.9693989, lng: 18.9771813 },
    { lat: 48.9694673, lng: 18.9764931 },
    { lat: 48.9695694, lng: 18.9754652 },
    { lat: 48.9698652, lng: 18.9749844 },
    { lat: 48.9698747, lng: 18.9747697 },
    { lat: 48.969981, lng: 18.9742826 },
    { lat: 48.9704513, lng: 18.9739544 },
    { lat: 48.9710249, lng: 18.9739263 },
    { lat: 48.9712634, lng: 18.9737119 },
    { lat: 48.9714956, lng: 18.9737351 },
    { lat: 48.9718312, lng: 18.9736573 },
    { lat: 48.972074, lng: 18.973601 },
    { lat: 48.9727162, lng: 18.97357 },
    { lat: 48.9725243, lng: 18.973209 },
    { lat: 48.9724923, lng: 18.9723501 },
    { lat: 48.9727594, lng: 18.9714537 },
    { lat: 48.9727309, lng: 18.970782 },
    { lat: 48.9727712, lng: 18.9697936 },
    { lat: 48.9734858, lng: 18.9689423 },
    { lat: 48.9738205, lng: 18.968224 },
    { lat: 48.974497, lng: 18.9670214 },
    { lat: 48.9747242, lng: 18.9658939 },
    { lat: 48.9747213, lng: 18.9658346 },
    { lat: 48.9755593, lng: 18.9657979 },
    { lat: 48.9761955, lng: 18.9659605 },
    { lat: 48.9772052, lng: 18.9662411 },
    { lat: 48.9773485, lng: 18.9662671 },
    { lat: 48.9776475, lng: 18.9661569 },
    { lat: 48.9779422, lng: 18.9659452 },
    { lat: 48.978162, lng: 18.9658758 },
    { lat: 48.9782257, lng: 18.9657768 },
    { lat: 48.9789233, lng: 18.9648789 },
    { lat: 48.9793995, lng: 18.9641744 },
    { lat: 48.9796263, lng: 18.9637075 },
    { lat: 48.979673, lng: 18.9636321 },
    { lat: 48.9797793, lng: 18.9633236 },
    { lat: 48.9799321, lng: 18.9633359 },
    { lat: 48.9801893, lng: 18.9622689 },
    { lat: 48.9804253, lng: 18.9614941 },
    { lat: 48.9807882, lng: 18.9605375 },
    { lat: 48.981138, lng: 18.9596941 },
    { lat: 48.9811896, lng: 18.95942 },
    { lat: 48.9817301, lng: 18.9580291 },
    { lat: 48.9821688, lng: 18.9570059 },
    { lat: 48.9825659, lng: 18.9561418 },
    { lat: 48.9828365, lng: 18.9555595 },
    { lat: 48.9834566, lng: 18.9544238 },
    { lat: 48.9836532, lng: 18.9539033 },
    { lat: 48.9838263, lng: 18.9531322 },
    { lat: 48.9838577, lng: 18.9526414 },
    { lat: 48.9839404, lng: 18.9518262 },
    { lat: 48.9843405, lng: 18.95077 },
    { lat: 48.9846218, lng: 18.9500272 },
    { lat: 48.984725, lng: 18.9497548 },
    { lat: 48.9847576, lng: 18.9496202 },
    { lat: 48.984865, lng: 18.9491558 },
    { lat: 48.9851312, lng: 18.9478491 },
    { lat: 48.9851639, lng: 18.9476886 },
    { lat: 48.9851404, lng: 18.9473266 },
    { lat: 48.9850166, lng: 18.9454173 },
    { lat: 48.9850036, lng: 18.9452169 },
    { lat: 48.9850394, lng: 18.9449095 },
    { lat: 48.9851882, lng: 18.9436304 },
    { lat: 48.9852234, lng: 18.9433277 },
    { lat: 48.9852813, lng: 18.9429667 },
    { lat: 48.9853896, lng: 18.9422917 },
  ],
  HornýKalník: [
    { lat: 49.0324602, lng: 18.9519886 },
    { lat: 49.0323262, lng: 18.9523751 },
    { lat: 49.0315821, lng: 18.9538487 },
    { lat: 49.031294, lng: 18.9544803 },
    { lat: 49.0311596, lng: 18.9552994 },
    { lat: 49.0310159, lng: 18.9553999 },
    { lat: 49.0309144, lng: 18.9556913 },
    { lat: 49.0305887, lng: 18.9576312 },
    { lat: 49.0305599, lng: 18.9576881 },
    { lat: 49.0301637, lng: 18.957277 },
    { lat: 49.0300081, lng: 18.9572327 },
    { lat: 49.0295251, lng: 18.9574225 },
    { lat: 49.0278133, lng: 18.9587087 },
    { lat: 49.0271775, lng: 18.9590902 },
    { lat: 49.0257905, lng: 18.959376 },
    { lat: 49.0251448, lng: 18.9593336 },
    { lat: 49.025008, lng: 18.9605527 },
    { lat: 49.0249371, lng: 18.9606699 },
    { lat: 49.0247617, lng: 18.9607884 },
    { lat: 49.0243727, lng: 18.9607908 },
    { lat: 49.0242265, lng: 18.9613532 },
    { lat: 49.0241051, lng: 18.9624983 },
    { lat: 49.0240566, lng: 18.9628344 },
    { lat: 49.0239595, lng: 18.9630965 },
    { lat: 49.0233875, lng: 18.963894 },
    { lat: 49.0228861, lng: 18.9644785 },
    { lat: 49.0228949, lng: 18.9650767 },
    { lat: 49.0230031, lng: 18.9658123 },
    { lat: 49.0231267, lng: 18.9663881 },
    { lat: 49.0231135, lng: 18.9664349 },
    { lat: 49.0214813, lng: 18.9669326 },
    { lat: 49.0218177, lng: 18.9685755 },
    { lat: 49.0209134, lng: 18.9691506 },
    { lat: 49.0209695, lng: 18.970019 },
    { lat: 49.0212095, lng: 18.970796 },
    { lat: 49.0220057, lng: 18.9732364 },
    { lat: 49.0221311, lng: 18.9736014 },
    { lat: 49.0229539, lng: 18.9745559 },
    { lat: 49.0238162, lng: 18.975315 },
    { lat: 49.0247312, lng: 18.9751278 },
    { lat: 49.0261414, lng: 18.9732451 },
    { lat: 49.0261452, lng: 18.9731667 },
    { lat: 49.0262388, lng: 18.9730315 },
    { lat: 49.0264293, lng: 18.9730524 },
    { lat: 49.0266376, lng: 18.9731158 },
    { lat: 49.0267111, lng: 18.9729854 },
    { lat: 49.0268682, lng: 18.972989 },
    { lat: 49.0269516, lng: 18.9731672 },
    { lat: 49.0271311, lng: 18.9731297 },
    { lat: 49.0273215, lng: 18.9732365 },
    { lat: 49.0277034, lng: 18.9729441 },
    { lat: 49.0277328, lng: 18.9732126 },
    { lat: 49.0278408, lng: 18.973233 },
    { lat: 49.0279108, lng: 18.9730754 },
    { lat: 49.0280617, lng: 18.9730963 },
    { lat: 49.0282707, lng: 18.9732163 },
    { lat: 49.0284001, lng: 18.9731402 },
    { lat: 49.0284432, lng: 18.9728797 },
    { lat: 49.0284728, lng: 18.9726628 },
    { lat: 49.0286463, lng: 18.97257 },
    { lat: 49.0288121, lng: 18.9726394 },
    { lat: 49.0288567, lng: 18.9728311 },
    { lat: 49.0292199, lng: 18.9726774 },
    { lat: 49.0292916, lng: 18.9728253 },
    { lat: 49.0294753, lng: 18.9730895 },
    { lat: 49.0296639, lng: 18.9727646 },
    { lat: 49.0297818, lng: 18.9725727 },
    { lat: 49.0299569, lng: 18.9726054 },
    { lat: 49.0300911, lng: 18.9728484 },
    { lat: 49.0303094, lng: 18.9728639 },
    { lat: 49.0303962, lng: 18.9723734 },
    { lat: 49.0305373, lng: 18.9722851 },
    { lat: 49.0307173, lng: 18.9726126 },
    { lat: 49.0308644, lng: 18.9725786 },
    { lat: 49.0309762, lng: 18.9727391 },
    { lat: 49.0310941, lng: 18.9726883 },
    { lat: 49.0310903, lng: 18.9728565 },
    { lat: 49.0311779, lng: 18.972893 },
    { lat: 49.0312041, lng: 18.9730567 },
    { lat: 49.0314316, lng: 18.9730744 },
    { lat: 49.0315429, lng: 18.9731372 },
    { lat: 49.0316044, lng: 18.9728986 },
    { lat: 49.0317111, lng: 18.9728269 },
    { lat: 49.0316828, lng: 18.9725985 },
    { lat: 49.0316047, lng: 18.9724369 },
    { lat: 49.0317347, lng: 18.9723528 },
    { lat: 49.0316592, lng: 18.9722196 },
    { lat: 49.0317193, lng: 18.9720481 },
    { lat: 49.0316971, lng: 18.971945 },
    { lat: 49.0315668, lng: 18.9719326 },
    { lat: 49.0316609, lng: 18.971742 },
    { lat: 49.0317385, lng: 18.9717174 },
    { lat: 49.0318089, lng: 18.9718156 },
    { lat: 49.0321931, lng: 18.9728612 },
    { lat: 49.03223, lng: 18.9728982 },
    { lat: 49.0322993, lng: 18.9730231 },
    { lat: 49.033108, lng: 18.9738101 },
    { lat: 49.0335213, lng: 18.97445 },
    { lat: 49.0339981, lng: 18.9758136 },
    { lat: 49.0338987, lng: 18.9769926 },
    { lat: 49.0343324, lng: 18.9787314 },
    { lat: 49.0349179, lng: 18.9794543 },
    { lat: 49.0351145, lng: 18.9790182 },
    { lat: 49.0359836, lng: 18.9780761 },
    { lat: 49.0358714, lng: 18.977325 },
    { lat: 49.0362606, lng: 18.9761887 },
    { lat: 49.0356008, lng: 18.9760479 },
    { lat: 49.0361177, lng: 18.9746761 },
    { lat: 49.0359111, lng: 18.9740408 },
    { lat: 49.0360579, lng: 18.973466 },
    { lat: 49.0364227, lng: 18.9726926 },
    { lat: 49.0370617, lng: 18.9706784 },
    { lat: 49.0375434, lng: 18.9673537 },
    { lat: 49.0375761, lng: 18.9670887 },
    { lat: 49.0375733, lng: 18.9670527 },
    { lat: 49.0376798, lng: 18.9663493 },
    { lat: 49.0375256, lng: 18.9663547 },
    { lat: 49.0373832, lng: 18.9660153 },
    { lat: 49.037435, lng: 18.9656553 },
    { lat: 49.0372795, lng: 18.9656795 },
    { lat: 49.0373347, lng: 18.9654999 },
    { lat: 49.0372663, lng: 18.9654414 },
    { lat: 49.0372363, lng: 18.9653792 },
    { lat: 49.0370934, lng: 18.9652952 },
    { lat: 49.0369828, lng: 18.9652057 },
    { lat: 49.037056, lng: 18.9648695 },
    { lat: 49.0368853, lng: 18.9645951 },
    { lat: 49.0379311, lng: 18.9634895 },
    { lat: 49.0380788, lng: 18.9632426 },
    { lat: 49.0378504, lng: 18.9627615 },
    { lat: 49.0373226, lng: 18.9621825 },
    { lat: 49.0369338, lng: 18.9617159 },
    { lat: 49.0364346, lng: 18.9611584 },
    { lat: 49.0363351, lng: 18.9604424 },
    { lat: 49.0363157, lng: 18.9588302 },
    { lat: 49.0361353, lng: 18.9575599 },
    { lat: 49.036003, lng: 18.9569701 },
    { lat: 49.0358207, lng: 18.9560956 },
    { lat: 49.0352034, lng: 18.9548154 },
    { lat: 49.034636, lng: 18.953079 },
    { lat: 49.0339945, lng: 18.9523338 },
    { lat: 49.0324602, lng: 18.9519886 },
  ],
  Karlová: [
    { lat: 48.9710818, lng: 18.9037223 },
    { lat: 48.9687398, lng: 18.8981618 },
    { lat: 48.9674548, lng: 18.8989397 },
    { lat: 48.9669837, lng: 18.8993821 },
    { lat: 48.9667873, lng: 18.8995345 },
    { lat: 48.9659221, lng: 18.8962652 },
    { lat: 48.9657924, lng: 18.8958919 },
    { lat: 48.9657719, lng: 18.8958189 },
    { lat: 48.9656281, lng: 18.8956299 },
    { lat: 48.9652836, lng: 18.8953577 },
    { lat: 48.9650668, lng: 18.8952161 },
    { lat: 48.963542, lng: 18.89479 },
    { lat: 48.9623872, lng: 18.8954703 },
    { lat: 48.962339, lng: 18.8954107 },
    { lat: 48.9606422, lng: 18.896184 },
    { lat: 48.9600678, lng: 18.8954749 },
    { lat: 48.9596831, lng: 18.8950001 },
    { lat: 48.9587473, lng: 18.8939908 },
    { lat: 48.9579363, lng: 18.8932355 },
    { lat: 48.957753, lng: 18.8931118 },
    { lat: 48.9576852, lng: 18.8929103 },
    { lat: 48.9575522, lng: 18.8923605 },
    { lat: 48.9575058, lng: 18.8923596 },
    { lat: 48.9574875, lng: 18.8925519 },
    { lat: 48.9575746, lng: 18.8927106 },
    { lat: 48.957542, lng: 18.8929975 },
    { lat: 48.9573175, lng: 18.893329 },
    { lat: 48.9573089, lng: 18.893335 },
    { lat: 48.9567294, lng: 18.894943 },
    { lat: 48.9559748, lng: 18.895379 },
    { lat: 48.9554623, lng: 18.8954111 },
    { lat: 48.955075, lng: 18.8955752 },
    { lat: 48.9539319, lng: 18.8967703 },
    { lat: 48.9538835, lng: 18.8969468 },
    { lat: 48.9538436, lng: 18.8970957 },
    { lat: 48.9538142, lng: 18.8972043 },
    { lat: 48.9529711, lng: 18.89646 },
    { lat: 48.9521508, lng: 18.8976595 },
    { lat: 48.9515371, lng: 18.8985585 },
    { lat: 48.9512722, lng: 18.8988893 },
    { lat: 48.950191, lng: 18.9001231 },
    { lat: 48.9498695, lng: 18.9005395 },
    { lat: 48.9504254, lng: 18.9015968 },
    { lat: 48.951285, lng: 18.9031263 },
    { lat: 48.9518242, lng: 18.9041257 },
    { lat: 48.9522397, lng: 18.9049101 },
    { lat: 48.9525602, lng: 18.9055959 },
    { lat: 48.9529953, lng: 18.906302 },
    { lat: 48.9530712, lng: 18.9063077 },
    { lat: 48.9534786, lng: 18.9067803 },
    { lat: 48.9546185, lng: 18.9081058 },
    { lat: 48.954744, lng: 18.9081997 },
    { lat: 48.9554636, lng: 18.9090858 },
    { lat: 48.956155, lng: 18.9100412 },
    { lat: 48.9567988, lng: 18.9108814 },
    { lat: 48.9568509, lng: 18.9109301 },
    { lat: 48.9573129, lng: 18.911561 },
    { lat: 48.9574191, lng: 18.9116572 },
    { lat: 48.9585503, lng: 18.9130116 },
    { lat: 48.9586189, lng: 18.9129156 },
    { lat: 48.9586948, lng: 18.9126814 },
    { lat: 48.9587683, lng: 18.9125981 },
    { lat: 48.9590125, lng: 18.9125643 },
    { lat: 48.9591579, lng: 18.9125203 },
    { lat: 48.9592295, lng: 18.9124638 },
    { lat: 48.9596623, lng: 18.9118438 },
    { lat: 48.9598916, lng: 18.9113687 },
    { lat: 48.9599249, lng: 18.9111552 },
    { lat: 48.9609994, lng: 18.909502 },
    { lat: 48.9619125, lng: 18.908946 },
    { lat: 48.9629123, lng: 18.9083661 },
    { lat: 48.9631102, lng: 18.9082437 },
    { lat: 48.9632345, lng: 18.9081186 },
    { lat: 48.9637088, lng: 18.9074696 },
    { lat: 48.9637663, lng: 18.9073587 },
    { lat: 48.9638324, lng: 18.9071726 },
    { lat: 48.9640388, lng: 18.9064048 },
    { lat: 48.9642002, lng: 18.9061637 },
    { lat: 48.9643138, lng: 18.9059834 },
    { lat: 48.9645392, lng: 18.9058668 },
    { lat: 48.9647536, lng: 18.9058738 },
    { lat: 48.9651693, lng: 18.9060183 },
    { lat: 48.9659579, lng: 18.9059853 },
    { lat: 48.9661161, lng: 18.9060151 },
    { lat: 48.9667136, lng: 18.9063663 },
    { lat: 48.9671028, lng: 18.9065677 },
    { lat: 48.9672199, lng: 18.9065891 },
    { lat: 48.9673139, lng: 18.9065747 },
    { lat: 48.9684451, lng: 18.9056746 },
    { lat: 48.9698215, lng: 18.9045632 },
    { lat: 48.9710818, lng: 18.9037223 },
  ],
  KošťanynadTurcom: [
    { lat: 49.0427456, lng: 18.9073819 },
    { lat: 49.0419319, lng: 18.9073421 },
    { lat: 49.0416889, lng: 18.9072385 },
    { lat: 49.0414834, lng: 18.90728 },
    { lat: 49.0413654, lng: 18.9073521 },
    { lat: 49.0410607, lng: 18.9074793 },
    { lat: 49.0407812, lng: 18.9074829 },
    { lat: 49.0406972, lng: 18.9074716 },
    { lat: 49.0405805, lng: 18.907394 },
    { lat: 49.040449, lng: 18.907246 },
    { lat: 49.0403719, lng: 18.9070596 },
    { lat: 49.0402392, lng: 18.9071551 },
    { lat: 49.0401554, lng: 18.9073048 },
    { lat: 49.0400204, lng: 18.9072891 },
    { lat: 49.0398266, lng: 18.907157 },
    { lat: 49.0396912, lng: 18.9068877 },
    { lat: 49.0395038, lng: 18.906641 },
    { lat: 49.0392675, lng: 18.906363 },
    { lat: 49.0391511, lng: 18.9061611 },
    { lat: 49.0391041, lng: 18.9059665 },
    { lat: 49.0391117, lng: 18.9057403 },
    { lat: 49.0391519, lng: 18.9055149 },
    { lat: 49.0391953, lng: 18.9053463 },
    { lat: 49.0393816, lng: 18.9048442 },
    { lat: 49.0396817, lng: 18.9045479 },
    { lat: 49.0399496, lng: 18.9042834 },
    { lat: 49.0402146, lng: 18.9042862 },
    { lat: 49.0403199, lng: 18.9043173 },
    { lat: 49.0403603, lng: 18.9040498 },
    { lat: 49.0403671, lng: 18.9036968 },
    { lat: 49.0403658, lng: 18.9033192 },
    { lat: 49.0403408, lng: 18.9030929 },
    { lat: 49.0402546, lng: 18.9025989 },
    { lat: 49.0401916, lng: 18.9023314 },
    { lat: 49.0402833, lng: 18.9022691 },
    { lat: 49.039915, lng: 18.901245 },
    { lat: 49.0397098, lng: 18.9006913 },
    { lat: 49.0395944, lng: 18.9000412 },
    { lat: 49.0394195, lng: 18.899226 },
    { lat: 49.0392992, lng: 18.8988786 },
    { lat: 49.0391524, lng: 18.8985427 },
    { lat: 49.0390289, lng: 18.898374 },
    { lat: 49.0387634, lng: 18.8979914 },
    { lat: 49.0385921, lng: 18.8977691 },
    { lat: 49.038282, lng: 18.8973508 },
    { lat: 49.0378651, lng: 18.8969361 },
    { lat: 49.0376604, lng: 18.8966686 },
    { lat: 49.0376598, lng: 18.8966679 },
    { lat: 49.037434, lng: 18.8962903 },
    { lat: 49.0371306, lng: 18.8957989 },
    { lat: 49.0369226, lng: 18.8955899 },
    { lat: 49.0366635, lng: 18.8952443 },
    { lat: 49.0365861, lng: 18.8949635 },
    { lat: 49.0365038, lng: 18.8944579 },
    { lat: 49.0364276, lng: 18.894045 },
    { lat: 49.0361916, lng: 18.8933801 },
    { lat: 49.0358168, lng: 18.8929713 },
    { lat: 49.0355689, lng: 18.8928633 },
    { lat: 49.0353796, lng: 18.8929784 },
    { lat: 49.0350035, lng: 18.8936902 },
    { lat: 49.0349738, lng: 18.894057 },
    { lat: 49.0349827, lng: 18.8944768 },
    { lat: 49.0344134, lng: 18.8945193 },
    { lat: 49.0340619, lng: 18.8944613 },
    { lat: 49.0337945, lng: 18.8942593 },
    { lat: 49.0335213, lng: 18.8942938 },
    { lat: 49.033136, lng: 18.8946559 },
    { lat: 49.03302, lng: 18.8949653 },
    { lat: 49.0329685, lng: 18.8954141 },
    { lat: 49.033029, lng: 18.8960024 },
    { lat: 49.033008, lng: 18.8964975 },
    { lat: 49.0328268, lng: 18.896834 },
    { lat: 49.0325924, lng: 18.8970152 },
    { lat: 49.0322169, lng: 18.8971016 },
    { lat: 49.0316802, lng: 18.8972088 },
    { lat: 49.0314841, lng: 18.8971666 },
    { lat: 49.031155, lng: 18.8972795 },
    { lat: 49.0308348, lng: 18.8976113 },
    { lat: 49.0304857, lng: 18.8978397 },
    { lat: 49.0299848, lng: 18.8979212 },
    { lat: 49.0296421, lng: 18.8981704 },
    { lat: 49.0296115, lng: 18.8980332 },
    { lat: 49.0295443, lng: 18.8980397 },
    { lat: 49.0294917, lng: 18.8980926 },
    { lat: 49.0293848, lng: 18.8983395 },
    { lat: 49.0291256, lng: 18.8984086 },
    { lat: 49.0289942, lng: 18.8984643 },
    { lat: 49.0285086, lng: 18.8983571 },
    { lat: 49.0282858, lng: 18.8983896 },
    { lat: 49.0280942, lng: 18.8984575 },
    { lat: 49.0277816, lng: 18.8985821 },
    { lat: 49.0275672, lng: 18.8988018 },
    { lat: 49.0272991, lng: 18.898827 },
    { lat: 49.0269943, lng: 18.8986463 },
    { lat: 49.0268356, lng: 18.8982727 },
    { lat: 49.026615, lng: 18.8981504 },
    { lat: 49.0261282, lng: 18.8968987 },
    { lat: 49.0261221, lng: 18.8964938 },
    { lat: 49.0262076, lng: 18.8961787 },
    { lat: 49.0263378, lng: 18.8958604 },
    { lat: 49.0264238, lng: 18.8955309 },
    { lat: 49.0263886, lng: 18.8952411 },
    { lat: 49.0263471, lng: 18.8951124 },
    { lat: 49.0260237, lng: 18.8941899 },
    { lat: 49.0257819, lng: 18.8936734 },
    { lat: 49.0255393, lng: 18.8933605 },
    { lat: 49.025335, lng: 18.8932233 },
    { lat: 49.0248593, lng: 18.8929163 },
    { lat: 49.0243915, lng: 18.8927413 },
    { lat: 49.023855, lng: 18.8925467 },
    { lat: 49.0238235, lng: 18.8925352 },
    { lat: 49.0237964, lng: 18.8925254 },
    { lat: 49.0232453, lng: 18.892269 },
    { lat: 49.0223727, lng: 18.8917417 },
    { lat: 49.0218153, lng: 18.8912263 },
    { lat: 49.0209396, lng: 18.8905047 },
    { lat: 49.0207095, lng: 18.8906986 },
    { lat: 49.0207125, lng: 18.8910032 },
    { lat: 49.0201457, lng: 18.8907908 },
    { lat: 49.0199882, lng: 18.8909714 },
    { lat: 49.0198849, lng: 18.8914007 },
    { lat: 49.0197551, lng: 18.8919251 },
    { lat: 49.0194286, lng: 18.8921347 },
    { lat: 49.0192542, lng: 18.8920964 },
    { lat: 49.0191355, lng: 18.8915816 },
    { lat: 49.0187806, lng: 18.8911692 },
    { lat: 49.0183491, lng: 18.8906239 },
    { lat: 49.01801, lng: 18.8902347 },
    { lat: 49.018228, lng: 18.8897107 },
    { lat: 49.01812, lng: 18.889365 },
    { lat: 49.0183416, lng: 18.888935 },
    { lat: 49.0186036, lng: 18.8886156 },
    { lat: 49.0186032, lng: 18.8886077 },
    { lat: 49.0188988, lng: 18.8886956 },
    { lat: 49.0190882, lng: 18.8884774 },
    { lat: 49.0186769, lng: 18.8877314 },
    { lat: 49.0180575, lng: 18.8871089 },
    { lat: 49.0178154, lng: 18.8863458 },
    { lat: 49.0178766, lng: 18.8859298 },
    { lat: 49.0186608, lng: 18.8841181 },
    { lat: 49.0184231, lng: 18.8833706 },
    { lat: 49.0172926, lng: 18.8822357 },
    { lat: 49.0165989, lng: 18.8838593 },
    { lat: 49.0162564, lng: 18.8846779 },
    { lat: 49.016195, lng: 18.8848206 },
    { lat: 49.0161492, lng: 18.885071 },
    { lat: 49.015762, lng: 18.8862939 },
    { lat: 49.0157479, lng: 18.8863746 },
    { lat: 49.0156367, lng: 18.8866888 },
    { lat: 49.0155267, lng: 18.8870937 },
    { lat: 49.0149922, lng: 18.8887895 },
    { lat: 49.0148688, lng: 18.889177 },
    { lat: 49.0144949, lng: 18.8903279 },
    { lat: 49.0139228, lng: 18.8920626 },
    { lat: 49.0135878, lng: 18.8931632 },
    { lat: 49.0130531, lng: 18.8947885 },
    { lat: 49.0126121, lng: 18.8961082 },
    { lat: 49.0125159, lng: 18.8965423 },
    { lat: 49.0120612, lng: 18.897972 },
    { lat: 49.0120253, lng: 18.8982826 },
    { lat: 49.01179, lng: 18.899106 },
    { lat: 49.0115992, lng: 18.8993009 },
    { lat: 49.0115098, lng: 18.8993359 },
    { lat: 49.0112616, lng: 18.8993347 },
    { lat: 49.0110763, lng: 18.8993691 },
    { lat: 49.010899, lng: 18.8994331 },
    { lat: 49.0103557, lng: 18.8997857 },
    { lat: 49.0084483, lng: 18.9010944 },
    { lat: 49.0083331, lng: 18.9011795 },
    { lat: 49.0082417, lng: 18.9012919 },
    { lat: 49.0081412, lng: 18.9014417 },
    { lat: 49.0080189, lng: 18.9016935 },
    { lat: 49.0079203, lng: 18.9019508 },
    { lat: 49.007845, lng: 18.9024388 },
    { lat: 49.0074804, lng: 18.9052135 },
    { lat: 49.0074358, lng: 18.9055567 },
    { lat: 49.0073836, lng: 18.905881 },
    { lat: 49.0073327, lng: 18.9060748 },
    { lat: 49.0072052, lng: 18.9064285 },
    { lat: 49.0062434, lng: 18.9083911 },
    { lat: 49.005498, lng: 18.9099158 },
    { lat: 49.0054052, lng: 18.91013 },
    { lat: 49.0053015, lng: 18.910417 },
    { lat: 49.0051137, lng: 18.9110015 },
    { lat: 49.0053335, lng: 18.9111782 },
    { lat: 49.0053242, lng: 18.9117467 },
    { lat: 49.0053509, lng: 18.9121816 },
    { lat: 49.0053672, lng: 18.9126548 },
    { lat: 49.0058171, lng: 18.9131193 },
    { lat: 49.0059191, lng: 18.9133734 },
    { lat: 49.0060522, lng: 18.913797 },
    { lat: 49.0062256, lng: 18.9145013 },
    { lat: 49.0067095, lng: 18.9163412 },
    { lat: 49.0069679, lng: 18.9161873 },
    { lat: 49.0066748, lng: 18.9149045 },
    { lat: 49.0064093, lng: 18.9137481 },
    { lat: 49.0064849, lng: 18.9137467 },
    { lat: 49.0066005, lng: 18.9138046 },
    { lat: 49.0066707, lng: 18.9138513 },
    { lat: 49.0069173, lng: 18.9141332 },
    { lat: 49.007038, lng: 18.9142476 },
    { lat: 49.0071803, lng: 18.9143375 },
    { lat: 49.0072615, lng: 18.9143646 },
    { lat: 49.007574, lng: 18.9144082 },
    { lat: 49.0075881, lng: 18.9143983 },
    { lat: 49.0084006, lng: 18.9145001 },
    { lat: 49.0088069, lng: 18.914555 },
    { lat: 49.0090993, lng: 18.9145849 },
    { lat: 49.0091372, lng: 18.9148839 },
    { lat: 49.0099661, lng: 18.9151318 },
    { lat: 49.0099004, lng: 18.9170287 },
    { lat: 49.0098688, lng: 18.9176031 },
    { lat: 49.0097323, lng: 18.9190205 },
    { lat: 49.0097055, lng: 18.9194676 },
    { lat: 49.009767, lng: 18.919502 },
    { lat: 49.0107236, lng: 18.9199753 },
    { lat: 49.0104031, lng: 18.9224513 },
    { lat: 49.0108044, lng: 18.9226943 },
    { lat: 49.0108245, lng: 18.9233546 },
    { lat: 49.0106703, lng: 18.9245766 },
    { lat: 49.0117442, lng: 18.9257642 },
    { lat: 49.0121191, lng: 18.9261759 },
    { lat: 49.0129266, lng: 18.9271107 },
    { lat: 49.0138972, lng: 18.9283044 },
    { lat: 49.0144741, lng: 18.928899 },
    { lat: 49.0145011, lng: 18.9289849 },
    { lat: 49.0152252, lng: 18.9299478 },
    { lat: 49.0152414, lng: 18.9299765 },
    { lat: 49.0153843, lng: 18.9300366 },
    { lat: 49.0161827, lng: 18.9292607 },
    { lat: 49.016433, lng: 18.9288646 },
    { lat: 49.0166983, lng: 18.9286495 },
    { lat: 49.0170515, lng: 18.9282552 },
    { lat: 49.0174533, lng: 18.9276861 },
    { lat: 49.017642, lng: 18.9273629 },
    { lat: 49.0178487, lng: 18.9270387 },
    { lat: 49.0183206, lng: 18.9282477 },
    { lat: 49.0188864, lng: 18.9279779 },
    { lat: 49.0203212, lng: 18.9265936 },
    { lat: 49.0203434, lng: 18.9265659 },
    { lat: 49.0202758, lng: 18.9264337 },
    { lat: 49.0202041, lng: 18.9262417 },
    { lat: 49.0201991, lng: 18.9261891 },
    { lat: 49.0202126, lng: 18.926143 },
    { lat: 49.0203026, lng: 18.9261166 },
    { lat: 49.0204079, lng: 18.9259476 },
    { lat: 49.0204245, lng: 18.9258964 },
    { lat: 49.0204503, lng: 18.9258514 },
    { lat: 49.0204742, lng: 18.9257465 },
    { lat: 49.0205061, lng: 18.925662 },
    { lat: 49.0205979, lng: 18.9254748 },
    { lat: 49.0206282, lng: 18.9253125 },
    { lat: 49.0206147, lng: 18.9252235 },
    { lat: 49.0205221, lng: 18.9250659 },
    { lat: 49.020433, lng: 18.9250466 },
    { lat: 49.0203678, lng: 18.9249594 },
    { lat: 49.0203809, lng: 18.9249051 },
    { lat: 49.0204945, lng: 18.9247692 },
    { lat: 49.0206438, lng: 18.924733 },
    { lat: 49.0208113, lng: 18.9246569 },
    { lat: 49.0209704, lng: 18.9245071 },
    { lat: 49.021051, lng: 18.9243812 },
    { lat: 49.0211071, lng: 18.9242216 },
    { lat: 49.0211244, lng: 18.9241204 },
    { lat: 49.0211288, lng: 18.923872 },
    { lat: 49.0211096, lng: 18.9237169 },
    { lat: 49.0211133, lng: 18.9236374 },
    { lat: 49.0211383, lng: 18.9235716 },
    { lat: 49.0211671, lng: 18.9235391 },
    { lat: 49.0212724, lng: 18.9235045 },
    { lat: 49.0213204, lng: 18.9234317 },
    { lat: 49.0214449, lng: 18.9233276 },
    { lat: 49.0215598, lng: 18.9232598 },
    { lat: 49.0217733, lng: 18.9231798 },
    { lat: 49.021808, lng: 18.923152 },
    { lat: 49.021823, lng: 18.9230934 },
    { lat: 49.0218279, lng: 18.922878 },
    { lat: 49.0218935, lng: 18.922681 },
    { lat: 49.0219295, lng: 18.9226139 },
    { lat: 49.0220339, lng: 18.9225811 },
    { lat: 49.0221146, lng: 18.9225735 },
    { lat: 49.0222675, lng: 18.9223654 },
    { lat: 49.0222971, lng: 18.922196 },
    { lat: 49.0222652, lng: 18.9217862 },
    { lat: 49.0222584, lng: 18.9215891 },
    { lat: 49.0222742, lng: 18.9213239 },
    { lat: 49.0222925, lng: 18.9212607 },
    { lat: 49.0223249, lng: 18.9212105 },
    { lat: 49.0223698, lng: 18.9212049 },
    { lat: 49.0224804, lng: 18.9212759 },
    { lat: 49.0226127, lng: 18.9213085 },
    { lat: 49.0227494, lng: 18.9211771 },
    { lat: 49.0227723, lng: 18.9210508 },
    { lat: 49.0228266, lng: 18.9206554 },
    { lat: 49.0228955, lng: 18.9206574 },
    { lat: 49.0230841, lng: 18.9205759 },
    { lat: 49.0233532, lng: 18.9204831 },
    { lat: 49.0233634, lng: 18.9204604 },
    { lat: 49.0233716, lng: 18.9203987 },
    { lat: 49.0233513, lng: 18.9203573 },
    { lat: 49.0233016, lng: 18.9201979 },
    { lat: 49.0232823, lng: 18.9201057 },
    { lat: 49.0232761, lng: 18.9199429 },
    { lat: 49.0234464, lng: 18.9196549 },
    { lat: 49.0235901, lng: 18.9195295 },
    { lat: 49.0236486, lng: 18.919464 },
    { lat: 49.0237512, lng: 18.9193711 },
    { lat: 49.0238203, lng: 18.9192908 },
    { lat: 49.0238537, lng: 18.9189298 },
    { lat: 49.0239142, lng: 18.9187671 },
    { lat: 49.023907, lng: 18.9186454 },
    { lat: 49.0238831, lng: 18.9184866 },
    { lat: 49.0238593, lng: 18.9184182 },
    { lat: 49.0236626, lng: 18.9180311 },
    { lat: 49.0235658, lng: 18.9177246 },
    { lat: 49.0235528, lng: 18.9175357 },
    { lat: 49.0235896, lng: 18.9173708 },
    { lat: 49.0238611, lng: 18.9169505 },
    { lat: 49.0240018, lng: 18.9168136 },
    { lat: 49.0240821, lng: 18.9168594 },
    { lat: 49.0241612, lng: 18.9170621 },
    { lat: 49.0241286, lng: 18.9171963 },
    { lat: 49.02443, lng: 18.9175582 },
    { lat: 49.0245289, lng: 18.9175735 },
    { lat: 49.0245903, lng: 18.9175178 },
    { lat: 49.0246353, lng: 18.9173396 },
    { lat: 49.0246141, lng: 18.9171683 },
    { lat: 49.0246038, lng: 18.9169435 },
    { lat: 49.0246164, lng: 18.9168795 },
    { lat: 49.024642, lng: 18.9168072 },
    { lat: 49.0248476, lng: 18.9165257 },
    { lat: 49.0254311, lng: 18.9154105 },
    { lat: 49.0255524, lng: 18.9153117 },
    { lat: 49.0256583, lng: 18.9152655 },
    { lat: 49.0258195, lng: 18.9153313 },
    { lat: 49.0258452, lng: 18.9152848 },
    { lat: 49.0258919, lng: 18.9151572 },
    { lat: 49.0259111, lng: 18.9147424 },
    { lat: 49.0260042, lng: 18.9146636 },
    { lat: 49.0260956, lng: 18.9146208 },
    { lat: 49.026129, lng: 18.9145483 },
    { lat: 49.0262129, lng: 18.9144892 },
    { lat: 49.0264463, lng: 18.9146456 },
    { lat: 49.0266562, lng: 18.9141847 },
    { lat: 49.0271196, lng: 18.914562 },
    { lat: 49.0272993, lng: 18.9140583 },
    { lat: 49.027422, lng: 18.9141024 },
    { lat: 49.0274372, lng: 18.9141549 },
    { lat: 49.0275799, lng: 18.9140576 },
    { lat: 49.0277582, lng: 18.9139719 },
    { lat: 49.0277928, lng: 18.9139003 },
    { lat: 49.0279535, lng: 18.9137754 },
    { lat: 49.0280836, lng: 18.9136296 },
    { lat: 49.0282808, lng: 18.913365 },
    { lat: 49.0283514, lng: 18.9132428 },
    { lat: 49.0287174, lng: 18.913569 },
    { lat: 49.0289808, lng: 18.9127845 },
    { lat: 49.0291097, lng: 18.9123705 },
    { lat: 49.0291038, lng: 18.9123654 },
    { lat: 49.029161, lng: 18.9121837 },
    { lat: 49.0293261, lng: 18.9116835 },
    { lat: 49.029389, lng: 18.9117681 },
    { lat: 49.0299406, lng: 18.9122812 },
    { lat: 49.0300339, lng: 18.9121003 },
    { lat: 49.0302507, lng: 18.9117743 },
    { lat: 49.0303895, lng: 18.9115992 },
    { lat: 49.0305293, lng: 18.9113991 },
    { lat: 49.03069, lng: 18.9111908 },
    { lat: 49.0308452, lng: 18.9112267 },
    { lat: 49.0310559, lng: 18.9112271 },
    { lat: 49.0312598, lng: 18.9111577 },
    { lat: 49.0314062, lng: 18.9109989 },
    { lat: 49.0318712, lng: 18.9103631 },
    { lat: 49.0320517, lng: 18.9100353 },
    { lat: 49.0326232, lng: 18.9104322 },
    { lat: 49.032762, lng: 18.9103892 },
    { lat: 49.0338209, lng: 18.9115432 },
    { lat: 49.0358335, lng: 18.9137347 },
    { lat: 49.0372382, lng: 18.9091517 },
    { lat: 49.0406162, lng: 18.9104801 },
    { lat: 49.0410568, lng: 18.9099232 },
    { lat: 49.0412279, lng: 18.9096971 },
    { lat: 49.0412642, lng: 18.9096997 },
    { lat: 49.0416288, lng: 18.9097763 },
    { lat: 49.0426122, lng: 18.910005 },
    { lat: 49.0426726, lng: 18.9098178 },
    { lat: 49.0427065, lng: 18.9096385 },
    { lat: 49.0427346, lng: 18.9094389 },
    { lat: 49.0427136, lng: 18.9091844 },
    { lat: 49.0427319, lng: 18.9090579 },
    { lat: 49.0427285, lng: 18.9090317 },
    { lat: 49.0427353, lng: 18.9086554 },
    { lat: 49.0426965, lng: 18.908362 },
    { lat: 49.0427118, lng: 18.9081532 },
    { lat: 49.0427303, lng: 18.9080285 },
    { lat: 49.0427043, lng: 18.9079169 },
    { lat: 49.0427238, lng: 18.9077899 },
    { lat: 49.0427101, lng: 18.9077207 },
    { lat: 49.0427317, lng: 18.9076578 },
    { lat: 49.042747, lng: 18.9075363 },
    { lat: 49.0427456, lng: 18.9073819 },
  ],
  Krpeľany: [
    { lat: 49.136553, lng: 19.14191 },
    { lat: 49.136587, lng: 19.141404 },
    { lat: 49.136656, lng: 19.140499 },
    { lat: 49.136594, lng: 19.140079 },
    { lat: 49.136726, lng: 19.139473 },
    { lat: 49.136873, lng: 19.138936 },
    { lat: 49.136951, lng: 19.13838 },
    { lat: 49.137057, lng: 19.137893 },
    { lat: 49.137069, lng: 19.137818 },
    { lat: 49.136955, lng: 19.137235 },
    { lat: 49.136954, lng: 19.137057 },
    { lat: 49.136917, lng: 19.136572 },
    { lat: 49.136931, lng: 19.136267 },
    { lat: 49.136973, lng: 19.135754 },
    { lat: 49.136878, lng: 19.135578 },
    { lat: 49.136809, lng: 19.135402 },
    { lat: 49.136713, lng: 19.134793 },
    { lat: 49.136853, lng: 19.134002 },
    { lat: 49.136926, lng: 19.13383 },
    { lat: 49.136958, lng: 19.133649 },
    { lat: 49.137084, lng: 19.133287 },
    { lat: 49.137262, lng: 19.133013 },
    { lat: 49.13741, lng: 19.13288 },
    { lat: 49.1375, lng: 19.13249 },
    { lat: 49.137643, lng: 19.132155 },
    { lat: 49.137912, lng: 19.131622 },
    { lat: 49.138197, lng: 19.131059 },
    { lat: 49.138357, lng: 19.130699 },
    { lat: 49.138429, lng: 19.130494 },
    { lat: 49.138599, lng: 19.130166 },
    { lat: 49.138598, lng: 19.130002 },
    { lat: 49.138653, lng: 19.129696 },
    { lat: 49.138727, lng: 19.129413 },
    { lat: 49.138879, lng: 19.12892 },
    { lat: 49.138903, lng: 19.128721 },
    { lat: 49.13913, lng: 19.128453 },
    { lat: 49.139383, lng: 19.128222 },
    { lat: 49.139832, lng: 19.127732 },
    { lat: 49.140028, lng: 19.127425 },
    { lat: 49.140076, lng: 19.127248 },
    { lat: 49.140271, lng: 19.126991 },
    { lat: 49.140359, lng: 19.126729 },
    { lat: 49.140352, lng: 19.126562 },
    { lat: 49.140359, lng: 19.126251 },
    { lat: 49.140498, lng: 19.125716 },
    { lat: 49.140632, lng: 19.125394 },
    { lat: 49.1408, lng: 19.125092 },
    { lat: 49.140814, lng: 19.124886 },
    { lat: 49.140895, lng: 19.124631 },
    { lat: 49.140909, lng: 19.124071 },
    { lat: 49.140935, lng: 19.123744 },
    { lat: 49.141037, lng: 19.123631 },
    { lat: 49.141157, lng: 19.122877 },
    { lat: 49.141216, lng: 19.12262 },
    { lat: 49.141245, lng: 19.122105 },
    { lat: 49.141509, lng: 19.120881 },
    { lat: 49.140922, lng: 19.119652 },
    { lat: 49.140594, lng: 19.119202 },
    { lat: 49.139748, lng: 19.117733 },
    { lat: 49.139469, lng: 19.117332 },
    { lat: 49.139288, lng: 19.11703 },
    { lat: 49.139087, lng: 19.11657 },
    { lat: 49.139024, lng: 19.116073 },
    { lat: 49.138993, lng: 19.115831 },
    { lat: 49.139071, lng: 19.115149 },
    { lat: 49.139024, lng: 19.114054 },
    { lat: 49.139074, lng: 19.113266 },
    { lat: 49.139042, lng: 19.112406 },
    { lat: 49.138932, lng: 19.111669 },
    { lat: 49.139212, lng: 19.110868 },
    { lat: 49.138979, lng: 19.109881 },
    { lat: 49.13895, lng: 19.109389 },
    { lat: 49.139157, lng: 19.10836 },
    { lat: 49.139163, lng: 19.107917 },
    { lat: 49.139214, lng: 19.107242 },
    { lat: 49.139146, lng: 19.106762 },
    { lat: 49.138957, lng: 19.10569 },
    { lat: 49.139007, lng: 19.105254 },
    { lat: 49.139272, lng: 19.10451 },
    { lat: 49.139187, lng: 19.103973 },
    { lat: 49.139043, lng: 19.103592 },
    { lat: 49.138997, lng: 19.103301 },
    { lat: 49.139097, lng: 19.102737 },
    { lat: 49.140175, lng: 19.101498 },
    { lat: 49.141084, lng: 19.100659 },
    { lat: 49.141755, lng: 19.099521 },
    { lat: 49.143094, lng: 19.098432 },
    { lat: 49.143332, lng: 19.098186 },
    { lat: 49.143973, lng: 19.098214 },
    { lat: 49.144794, lng: 19.098257 },
    { lat: 49.145647, lng: 19.098411 },
    { lat: 49.145811, lng: 19.098509 },
    { lat: 49.148433, lng: 19.097309 },
    { lat: 49.149042, lng: 19.097015 },
    { lat: 49.149301, lng: 19.096954 },
    { lat: 49.14992, lng: 19.09682 },
    { lat: 49.149987, lng: 19.097223 },
    { lat: 49.150272, lng: 19.096962 },
    { lat: 49.1500716, lng: 19.0962977 },
    { lat: 49.1497777, lng: 19.0957782 },
    { lat: 49.1493942, lng: 19.0953158 },
    { lat: 49.1488801, lng: 19.0949249 },
    { lat: 49.1472821, lng: 19.0939703 },
    { lat: 49.1464397, lng: 19.093576 },
    { lat: 49.1455799, lng: 19.09326 },
    { lat: 49.1458083, lng: 19.0925443 },
    { lat: 49.1446518, lng: 19.0918587 },
    { lat: 49.1437458, lng: 19.0913637 },
    { lat: 49.1432549, lng: 19.0911232 },
    { lat: 49.1431168, lng: 19.0910426 },
    { lat: 49.1431061, lng: 19.0910517 },
    { lat: 49.1430197, lng: 19.091085 },
    { lat: 49.1428272, lng: 19.0909994 },
    { lat: 49.1427808, lng: 19.090878 },
    { lat: 49.1424436, lng: 19.0907178 },
    { lat: 49.1418711, lng: 19.0905372 },
    { lat: 49.1418789, lng: 19.0904887 },
    { lat: 49.1404656, lng: 19.0900378 },
    { lat: 49.1394037, lng: 19.0897149 },
    { lat: 49.1386967, lng: 19.0895752 },
    { lat: 49.1379351, lng: 19.0894684 },
    { lat: 49.1370406, lng: 19.0894121 },
    { lat: 49.1360964, lng: 19.089373 },
    { lat: 49.1350881, lng: 19.0893405 },
    { lat: 49.1343037, lng: 19.0892983 },
    { lat: 49.1335032, lng: 19.0892038 },
    { lat: 49.1330308, lng: 19.0891557 },
    { lat: 49.1322413, lng: 19.0889859 },
    { lat: 49.1317181, lng: 19.0888432 },
    { lat: 49.1309748, lng: 19.0884953 },
    { lat: 49.1306318, lng: 19.0883024 },
    { lat: 49.1304718, lng: 19.0881904 },
    { lat: 49.1301885, lng: 19.0880213 },
    { lat: 49.1296954, lng: 19.0876948 },
    { lat: 49.1294817, lng: 19.0875415 },
    { lat: 49.1291199, lng: 19.0871809 },
    { lat: 49.1287451, lng: 19.086803 },
    { lat: 49.1280065, lng: 19.0860001 },
    { lat: 49.127306, lng: 19.0851654 },
    { lat: 49.1269758, lng: 19.0847116 },
    { lat: 49.1267258, lng: 19.0844177 },
    { lat: 49.1262607, lng: 19.0838343 },
    { lat: 49.1256916, lng: 19.0829479 },
    { lat: 49.1250675, lng: 19.0818389 },
    { lat: 49.1245908, lng: 19.080676 },
    { lat: 49.123772, lng: 19.0783178 },
    { lat: 49.1233759, lng: 19.0770565 },
    { lat: 49.1229976, lng: 19.0761332 },
    { lat: 49.1229628, lng: 19.0761782 },
    { lat: 49.1184998, lng: 19.078822 },
    { lat: 49.1185201, lng: 19.0797352 },
    { lat: 49.1186017, lng: 19.0808367 },
    { lat: 49.1186788, lng: 19.0819626 },
    { lat: 49.1186972, lng: 19.0820378 },
    { lat: 49.1187863, lng: 19.0826549 },
    { lat: 49.1192179, lng: 19.0837268 },
    { lat: 49.1184626, lng: 19.083452 },
    { lat: 49.1183708, lng: 19.0834212 },
    { lat: 49.117822, lng: 19.0832185 },
    { lat: 49.1143302, lng: 19.0843212 },
    { lat: 49.1142201, lng: 19.0849912 },
    { lat: 49.1140454, lng: 19.0860411 },
    { lat: 49.1137779, lng: 19.0876572 },
    { lat: 49.1134515, lng: 19.0890591 },
    { lat: 49.1134997, lng: 19.0893518 },
    { lat: 49.1126783, lng: 19.090136 },
    { lat: 49.1125888, lng: 19.0922045 },
    { lat: 49.1122699, lng: 19.0923439 },
    { lat: 49.1120942, lng: 19.0926882 },
    { lat: 49.1129752, lng: 19.0947611 },
    { lat: 49.1136346, lng: 19.0966569 },
    { lat: 49.1140746, lng: 19.0990009 },
    { lat: 49.1143083, lng: 19.09951 },
    { lat: 49.1143107, lng: 19.1001768 },
    { lat: 49.1142218, lng: 19.1004551 },
    { lat: 49.1143306, lng: 19.1009036 },
    { lat: 49.1143514, lng: 19.1010052 },
    { lat: 49.113677, lng: 19.1011582 },
    { lat: 49.11244, lng: 19.1019972 },
    { lat: 49.1116639, lng: 19.1029455 },
    { lat: 49.1114306, lng: 19.1030376 },
    { lat: 49.1109214, lng: 19.1031357 },
    { lat: 49.109976, lng: 19.1037143 },
    { lat: 49.1096177, lng: 19.1038585 },
    { lat: 49.1091918, lng: 19.104046 },
    { lat: 49.10848, lng: 19.10449 },
    { lat: 49.1078372, lng: 19.1048059 },
    { lat: 49.1072226, lng: 19.1052383 },
    { lat: 49.1065689, lng: 19.1057868 },
    { lat: 49.1062776, lng: 19.1061467 },
    { lat: 49.1057094, lng: 19.1070617 },
    { lat: 49.1049955, lng: 19.107717 },
    { lat: 49.1046858, lng: 19.1080193 },
    { lat: 49.1043378, lng: 19.108258 },
    { lat: 49.1040882, lng: 19.1084512 },
    { lat: 49.1026166, lng: 19.1096566 },
    { lat: 49.1023206, lng: 19.1099149 },
    { lat: 49.1021518, lng: 19.1099701 },
    { lat: 49.1018263, lng: 19.109999 },
    { lat: 49.1014769, lng: 19.1101816 },
    { lat: 49.1013175, lng: 19.1100815 },
    { lat: 49.1007171, lng: 19.11025 },
    { lat: 49.1003851, lng: 19.1104198 },
    { lat: 49.1001759, lng: 19.1103153 },
    { lat: 49.0998089, lng: 19.1101873 },
    { lat: 49.0995448, lng: 19.1104556 },
    { lat: 49.0995714, lng: 19.110609 },
    { lat: 49.0995598, lng: 19.1108525 },
    { lat: 49.0993808, lng: 19.1108578 },
    { lat: 49.0991795, lng: 19.1108422 },
    { lat: 49.0989205, lng: 19.1107595 },
    { lat: 49.0988341, lng: 19.1108557 },
    { lat: 49.0988888, lng: 19.111166 },
    { lat: 49.0990106, lng: 19.1112615 },
    { lat: 49.0991098, lng: 19.1115581 },
    { lat: 49.09909, lng: 19.1117273 },
    { lat: 49.0990005, lng: 19.111699 },
    { lat: 49.0989777, lng: 19.1115761 },
    { lat: 49.0987742, lng: 19.111314 },
    { lat: 49.098648, lng: 19.1110838 },
    { lat: 49.0986203, lng: 19.1110039 },
    { lat: 49.0982777, lng: 19.1116472 },
    { lat: 49.097872, lng: 19.1121054 },
    { lat: 49.097496, lng: 19.1122306 },
    { lat: 49.0969915, lng: 19.1123799 },
    { lat: 49.0967646, lng: 19.1125126 },
    { lat: 49.096566, lng: 19.1130075 },
    { lat: 49.096338, lng: 19.1133245 },
    { lat: 49.0961827, lng: 19.1134227 },
    { lat: 49.0956184, lng: 19.1136148 },
    { lat: 49.095278, lng: 19.1137733 },
    { lat: 49.0946264, lng: 19.1136606 },
    { lat: 49.0942323, lng: 19.1139411 },
    { lat: 49.0936252, lng: 19.1142063 },
    { lat: 49.0929194, lng: 19.115031 },
    { lat: 49.0928684, lng: 19.1150906 },
    { lat: 49.0926875, lng: 19.1153789 },
    { lat: 49.0926094, lng: 19.1154889 },
    { lat: 49.0922274, lng: 19.1160396 },
    { lat: 49.0920453, lng: 19.1161538 },
    { lat: 49.092475, lng: 19.116282 },
    { lat: 49.092866, lng: 19.116413 },
    { lat: 49.093006, lng: 19.116538 },
    { lat: 49.093192, lng: 19.116588 },
    { lat: 49.093587, lng: 19.116992 },
    { lat: 49.093837, lng: 19.11714 },
    { lat: 49.094017, lng: 19.117336 },
    { lat: 49.094284, lng: 19.117534 },
    { lat: 49.094607, lng: 19.117694 },
    { lat: 49.094994, lng: 19.117951 },
    { lat: 49.095453, lng: 19.118182 },
    { lat: 49.09574, lng: 19.118517 },
    { lat: 49.096028, lng: 19.118644 },
    { lat: 49.096307, lng: 19.118907 },
    { lat: 49.096551, lng: 19.119034 },
    { lat: 49.097137, lng: 19.119521 },
    { lat: 49.097943, lng: 19.120002 },
    { lat: 49.098116, lng: 19.120203 },
    { lat: 49.098476, lng: 19.120362 },
    { lat: 49.098874, lng: 19.120648 },
    { lat: 49.099216, lng: 19.12077 },
    { lat: 49.099905, lng: 19.121174 },
    { lat: 49.100273, lng: 19.121432 },
    { lat: 49.100528, lng: 19.121665 },
    { lat: 49.100878, lng: 19.121819 },
    { lat: 49.1013, lng: 19.121843 },
    { lat: 49.101727, lng: 19.122063 },
    { lat: 49.102156, lng: 19.122241 },
    { lat: 49.102568, lng: 19.122138 },
    { lat: 49.102839, lng: 19.122298 },
    { lat: 49.10311, lng: 19.122355 },
    { lat: 49.103299, lng: 19.12175 },
    { lat: 49.103989, lng: 19.121101 },
    { lat: 49.104188, lng: 19.12107 },
    { lat: 49.104455, lng: 19.120918 },
    { lat: 49.104534, lng: 19.12086 },
    { lat: 49.105354, lng: 19.120586 },
    { lat: 49.105425, lng: 19.12091 },
    { lat: 49.105565, lng: 19.120819 },
    { lat: 49.10648, lng: 19.120592 },
    { lat: 49.10697, lng: 19.120213 },
    { lat: 49.107595, lng: 19.119647 },
    { lat: 49.107784, lng: 19.119541 },
    { lat: 49.107923, lng: 19.119672 },
    { lat: 49.108389, lng: 19.119804 },
    { lat: 49.109225, lng: 19.120081 },
    { lat: 49.10939, lng: 19.120177 },
    { lat: 49.110324, lng: 19.120942 },
    { lat: 49.110381, lng: 19.120978 },
    { lat: 49.110662, lng: 19.121153 },
    { lat: 49.110821, lng: 19.121178 },
    { lat: 49.110949, lng: 19.121199 },
    { lat: 49.111206, lng: 19.121272 },
    { lat: 49.111237, lng: 19.121281 },
    { lat: 49.1113929, lng: 19.1213477 },
    { lat: 49.111459, lng: 19.121376 },
    { lat: 49.111519, lng: 19.121395 },
    { lat: 49.111627, lng: 19.121431 },
    { lat: 49.111667, lng: 19.121445 },
    { lat: 49.11175, lng: 19.121473 },
    { lat: 49.1119255, lng: 19.1215644 },
    { lat: 49.112089, lng: 19.121697 },
    { lat: 49.11212, lng: 19.121728 },
    { lat: 49.11243, lng: 19.122024 },
    { lat: 49.112629, lng: 19.122377 },
    { lat: 49.112757, lng: 19.122759 },
    { lat: 49.112806, lng: 19.122828 },
    { lat: 49.112842, lng: 19.122875 },
    { lat: 49.112999, lng: 19.123216 },
    { lat: 49.113092, lng: 19.123691 },
    { lat: 49.1132036, lng: 19.1241878 },
    { lat: 49.113343, lng: 19.124582 },
    { lat: 49.113365, lng: 19.124637 },
    { lat: 49.113671, lng: 19.125396 },
    { lat: 49.113717, lng: 19.12539 },
    { lat: 49.113935, lng: 19.12575 },
    { lat: 49.114015, lng: 19.125928 },
    { lat: 49.114016, lng: 19.126144 },
    { lat: 49.113979, lng: 19.12644 },
    { lat: 49.11398, lng: 19.126858 },
    { lat: 49.113854, lng: 19.127545 },
    { lat: 49.113594, lng: 19.128341 },
    { lat: 49.113552, lng: 19.128584 },
    { lat: 49.113739, lng: 19.128985 },
    { lat: 49.113984, lng: 19.129418 },
    { lat: 49.114237, lng: 19.129792 },
    { lat: 49.114473, lng: 19.130085 },
    { lat: 49.114499, lng: 19.130315 },
    { lat: 49.11458, lng: 19.130533 },
    { lat: 49.114693, lng: 19.130794 },
    { lat: 49.114805, lng: 19.130914 },
    { lat: 49.114913, lng: 19.131052 },
    { lat: 49.114923, lng: 19.131109 },
    { lat: 49.114985, lng: 19.131145 },
    { lat: 49.11505, lng: 19.131228 },
    { lat: 49.115211, lng: 19.131308 },
    { lat: 49.11547, lng: 19.131546 },
    { lat: 49.115572, lng: 19.131748 },
    { lat: 49.116009, lng: 19.132142 },
    { lat: 49.116261, lng: 19.132539 },
    { lat: 49.116674, lng: 19.132821 },
    { lat: 49.117124, lng: 19.13332 },
    { lat: 49.117248, lng: 19.13353 },
    { lat: 49.117562, lng: 19.13378 },
    { lat: 49.117771, lng: 19.13415 },
    { lat: 49.11858, lng: 19.135049 },
    { lat: 49.118735, lng: 19.135348 },
    { lat: 49.118969, lng: 19.136223 },
    { lat: 49.119173, lng: 19.136511 },
    { lat: 49.119265, lng: 19.136606 },
    { lat: 49.119362, lng: 19.136706 },
    { lat: 49.119915, lng: 19.137106 },
    { lat: 49.120479, lng: 19.137407 },
    { lat: 49.120577, lng: 19.138078 },
    { lat: 49.120744, lng: 19.138931 },
    { lat: 49.120816, lng: 19.13949 },
    { lat: 49.121129, lng: 19.139848 },
    { lat: 49.121255, lng: 19.14002 },
    { lat: 49.121671, lng: 19.140257 },
    { lat: 49.122025, lng: 19.140696 },
    { lat: 49.122204, lng: 19.14105 },
    { lat: 49.122681, lng: 19.141277 },
    { lat: 49.123125, lng: 19.141527 },
    { lat: 49.123347, lng: 19.141609 },
    { lat: 49.123858, lng: 19.142014 },
    { lat: 49.124239, lng: 19.142082 },
    { lat: 49.12456, lng: 19.142067 },
    { lat: 49.124803, lng: 19.142269 },
    { lat: 49.125076, lng: 19.142712 },
    { lat: 49.125675, lng: 19.142992 },
    { lat: 49.126185, lng: 19.143366 },
    { lat: 49.126442, lng: 19.143443 },
    { lat: 49.127075, lng: 19.143692 },
    { lat: 49.12729, lng: 19.143623 },
    { lat: 49.127436, lng: 19.143403 },
    { lat: 49.128289, lng: 19.142267 },
    { lat: 49.128644, lng: 19.142007 },
    { lat: 49.129027, lng: 19.141684 },
    { lat: 49.129984, lng: 19.140688 },
    { lat: 49.130197, lng: 19.140553 },
    { lat: 49.130327, lng: 19.140375 },
    { lat: 49.130775, lng: 19.14007 },
    { lat: 49.131137, lng: 19.139951 },
    { lat: 49.1315, lng: 19.140185 },
    { lat: 49.131804, lng: 19.140387 },
    { lat: 49.132249, lng: 19.140144 },
    { lat: 49.133092, lng: 19.14059 },
    { lat: 49.133633, lng: 19.14097 },
    { lat: 49.133721, lng: 19.14098 },
    { lat: 49.134256, lng: 19.141039 },
    { lat: 49.13464, lng: 19.141175 },
    { lat: 49.135073, lng: 19.141417 },
    { lat: 49.135482, lng: 19.141647 },
    { lat: 49.135786, lng: 19.141724 },
    { lat: 49.13629, lng: 19.141825 },
    { lat: 49.136553, lng: 19.14191 },
  ],
  Ležiachov: [
    { lat: 48.9829617, lng: 18.8766033 },
    { lat: 48.9831627, lng: 18.8755572 },
    { lat: 48.9833665, lng: 18.8744105 },
    { lat: 48.9836133, lng: 18.8730875 },
    { lat: 48.9838544, lng: 18.871768 },
    { lat: 48.9839656, lng: 18.8712985 },
    { lat: 48.9841254, lng: 18.8706557 },
    { lat: 48.9844206, lng: 18.8693778 },
    { lat: 48.9846692, lng: 18.8682477 },
    { lat: 48.984704, lng: 18.8682213 },
    { lat: 48.9847113, lng: 18.8681479 },
    { lat: 48.984946, lng: 18.8670966 },
    { lat: 48.9850891, lng: 18.8665257 },
    { lat: 48.985183, lng: 18.8658935 },
    { lat: 48.9855064, lng: 18.8642012 },
    { lat: 48.9858384, lng: 18.8623276 },
    { lat: 48.9860919, lng: 18.8608533 },
    { lat: 48.9859536, lng: 18.8603789 },
    { lat: 48.9860509, lng: 18.8597944 },
    { lat: 48.9862384, lng: 18.8587278 },
    { lat: 48.9865444, lng: 18.857292 },
    { lat: 48.9867019, lng: 18.8573731 },
    { lat: 48.9868518, lng: 18.8566674 },
    { lat: 48.9869502, lng: 18.8562378 },
    { lat: 48.9873415, lng: 18.854463 },
    { lat: 48.9876369, lng: 18.8531067 },
    { lat: 48.9876509, lng: 18.8530706 },
    { lat: 48.9876931, lng: 18.8528627 },
    { lat: 48.9878665, lng: 18.8517427 },
    { lat: 48.9879493, lng: 18.8517318 },
    { lat: 48.9881088, lng: 18.851566 },
    { lat: 48.9883114, lng: 18.8512762 },
    { lat: 48.988402, lng: 18.8510612 },
    { lat: 48.98894, lng: 18.8496644 },
    { lat: 48.9891898, lng: 18.8490233 },
    { lat: 48.9895228, lng: 18.8481945 },
    { lat: 48.9897808, lng: 18.846398 },
    { lat: 48.9899241, lng: 18.84533 },
    { lat: 48.9899964, lng: 18.8448532 },
    { lat: 48.9896159, lng: 18.8447546 },
    { lat: 48.9896958, lng: 18.8437491 },
    { lat: 48.9898823, lng: 18.8417676 },
    { lat: 48.9895186, lng: 18.8416006 },
    { lat: 48.9898625, lng: 18.839484 },
    { lat: 48.9897326, lng: 18.8391968 },
    { lat: 48.9895568, lng: 18.838653 },
    { lat: 48.9894134, lng: 18.8380353 },
    { lat: 48.9892525, lng: 18.8376064 },
    { lat: 48.9892315, lng: 18.8370707 },
    { lat: 48.9891633, lng: 18.8367522 },
    { lat: 48.9894846, lng: 18.8358364 },
    { lat: 48.9898964, lng: 18.8349219 },
    { lat: 48.9900613, lng: 18.8340987 },
    { lat: 48.99026, lng: 18.8332287 },
    { lat: 48.9903813, lng: 18.832568 },
    { lat: 48.9904633, lng: 18.831709 },
    { lat: 48.9904703, lng: 18.8309086 },
    { lat: 48.9905442, lng: 18.8301803 },
    { lat: 48.990761, lng: 18.8286614 },
    { lat: 48.9908177, lng: 18.8278802 },
    { lat: 48.9911878, lng: 18.8266436 },
    { lat: 48.9912214, lng: 18.8259656 },
    { lat: 48.9913737, lng: 18.8252688 },
    { lat: 48.9912114, lng: 18.8235953 },
    { lat: 48.9912177, lng: 18.8226488 },
    { lat: 48.9913741, lng: 18.8217493 },
    { lat: 48.9910519, lng: 18.8214051 },
    { lat: 48.9907566, lng: 18.8208849 },
    { lat: 48.9901949, lng: 18.8201188 },
    { lat: 48.9895003, lng: 18.8202704 },
    { lat: 48.9893653, lng: 18.8206291 },
    { lat: 48.9890724, lng: 18.8221994 },
    { lat: 48.9887402, lng: 18.8229902 },
    { lat: 48.9883769, lng: 18.8237086 },
    { lat: 48.9882289, lng: 18.8241137 },
    { lat: 48.9881091, lng: 18.8244788 },
    { lat: 48.98811, lng: 18.8248464 },
    { lat: 48.988193, lng: 18.8257753 },
    { lat: 48.9881642, lng: 18.8271549 },
    { lat: 48.9881108, lng: 18.8279346 },
    { lat: 48.9878124, lng: 18.8278985 },
    { lat: 48.9874711, lng: 18.8277095 },
    { lat: 48.987337, lng: 18.8277119 },
    { lat: 48.9869188, lng: 18.8277999 },
    { lat: 48.9867508, lng: 18.8278217 },
    { lat: 48.9862822, lng: 18.8278939 },
    { lat: 48.9863572, lng: 18.8285814 },
    { lat: 48.9851133, lng: 18.8304349 },
    { lat: 48.9841468, lng: 18.8318262 },
    { lat: 48.9841366, lng: 18.8318662 },
    { lat: 48.9844124, lng: 18.8320551 },
    { lat: 48.984247, lng: 18.8328259 },
    { lat: 48.9841941, lng: 18.8335039 },
    { lat: 48.9840988, lng: 18.8337303 },
    { lat: 48.9838841, lng: 18.8345497 },
    { lat: 48.9837162, lng: 18.8352985 },
    { lat: 48.9835015, lng: 18.8358985 },
    { lat: 48.9833536, lng: 18.8358519 },
    { lat: 48.983065, lng: 18.8367019 },
    { lat: 48.9821243, lng: 18.8363481 },
    { lat: 48.9818117, lng: 18.8364712 },
    { lat: 48.9817207, lng: 18.8366551 },
    { lat: 48.9816812, lng: 18.8367628 },
    { lat: 48.9815682, lng: 18.8376314 },
    { lat: 48.9813428, lng: 18.8383996 },
    { lat: 48.9810476, lng: 18.8389474 },
    { lat: 48.9810222, lng: 18.8389816 },
    { lat: 48.980963, lng: 18.8390343 },
    { lat: 48.9809454, lng: 18.8396998 },
    { lat: 48.9803174, lng: 18.8399609 },
    { lat: 48.9796353, lng: 18.8410563 },
    { lat: 48.9797589, lng: 18.8420725 },
    { lat: 48.9796003, lng: 18.8439563 },
    { lat: 48.979216, lng: 18.8445735 },
    { lat: 48.9783301, lng: 18.8448876 },
    { lat: 48.9776646, lng: 18.8452091 },
    { lat: 48.9775922, lng: 18.84573 },
    { lat: 48.9776808, lng: 18.846358 },
    { lat: 48.9776808, lng: 18.8475167 },
    { lat: 48.9776661, lng: 18.8476437 },
    { lat: 48.9776625, lng: 18.8484952 },
    { lat: 48.9777098, lng: 18.8497606 },
    { lat: 48.9764377, lng: 18.849426 },
    { lat: 48.9760588, lng: 18.8495568 },
    { lat: 48.9759525, lng: 18.8496395 },
    { lat: 48.9768715, lng: 18.8519785 },
    { lat: 48.9757855, lng: 18.8516069 },
    { lat: 48.9754465, lng: 18.8515022 },
    { lat: 48.9743366, lng: 18.8514089 },
    { lat: 48.9738094, lng: 18.8513305 },
    { lat: 48.9731915, lng: 18.8511542 },
    { lat: 48.9726235, lng: 18.8510082 },
    { lat: 48.9722902, lng: 18.8509736 },
    { lat: 48.9717934, lng: 18.8510032 },
    { lat: 48.9714191, lng: 18.8510496 },
    { lat: 48.9714323, lng: 18.85124 },
    { lat: 48.9722827, lng: 18.8580963 },
    { lat: 48.9725523, lng: 18.86068 },
    { lat: 48.9726425, lng: 18.8634841 },
    { lat: 48.9724806, lng: 18.8634276 },
    { lat: 48.9723899, lng: 18.8635949 },
    { lat: 48.9722613, lng: 18.8638556 },
    { lat: 48.9726044, lng: 18.8641674 },
    { lat: 48.9726551, lng: 18.8639923 },
    { lat: 48.9732109, lng: 18.8643638 },
    { lat: 48.9737936, lng: 18.8647738 },
    { lat: 48.9737903, lng: 18.8647975 },
    { lat: 48.9742428, lng: 18.8650923 },
    { lat: 48.9745338, lng: 18.8652915 },
    { lat: 48.9747942, lng: 18.8654849 },
    { lat: 48.9750946, lng: 18.8684552 },
    { lat: 48.9753916, lng: 18.8713847 },
    { lat: 48.9752039, lng: 18.8724496 },
    { lat: 48.9751546, lng: 18.8727404 },
    { lat: 48.975727, lng: 18.8740918 },
    { lat: 48.975826, lng: 18.8745287 },
    { lat: 48.976404, lng: 18.8751795 },
    { lat: 48.9767904, lng: 18.8748857 },
    { lat: 48.9773382, lng: 18.8753667 },
    { lat: 48.9773637, lng: 18.8756652 },
    { lat: 48.9765939, lng: 18.8767474 },
    { lat: 48.9766475, lng: 18.8769999 },
    { lat: 48.9768867, lng: 18.8772691 },
    { lat: 48.977289, lng: 18.8776992 },
    { lat: 48.9779792, lng: 18.8783874 },
    { lat: 48.9782652, lng: 18.8784899 },
    { lat: 48.9785065, lng: 18.8784215 },
    { lat: 48.9787104, lng: 18.8783306 },
    { lat: 48.9789921, lng: 18.8781745 },
    { lat: 48.979186, lng: 18.8779971 },
    { lat: 48.9794487, lng: 18.8766799 },
    { lat: 48.9793751, lng: 18.8760152 },
    { lat: 48.9796429, lng: 18.8751245 },
    { lat: 48.979777, lng: 18.8750344 },
    { lat: 48.9796971, lng: 18.8748417 },
    { lat: 48.9797817, lng: 18.8742707 },
    { lat: 48.9799683, lng: 18.8739524 },
    { lat: 48.9806838, lng: 18.8737876 },
    { lat: 48.9810564, lng: 18.8741049 },
    { lat: 48.9813479, lng: 18.8760734 },
    { lat: 48.9814855, lng: 18.8770381 },
    { lat: 48.9819897, lng: 18.8766043 },
    { lat: 48.982284, lng: 18.8763408 },
    { lat: 48.982587, lng: 18.8762446 },
    { lat: 48.9828527, lng: 18.8763905 },
    { lat: 48.9829617, lng: 18.8766033 },
  ],
  Lipovec: [
    { lat: 49.151496, lng: 18.8883932 },
    { lat: 49.1517977, lng: 18.8887623 },
    { lat: 49.1501111, lng: 18.8906429 },
    { lat: 49.1498098, lng: 18.8908818 },
    { lat: 49.1484037, lng: 18.8911432 },
    { lat: 49.1484527, lng: 18.8909977 },
    { lat: 49.1472591, lng: 18.8908946 },
    { lat: 49.1455257, lng: 18.891215 },
    { lat: 49.1449677, lng: 18.8916377 },
    { lat: 49.1428813, lng: 18.8936154 },
    { lat: 49.1415241, lng: 18.8943308 },
    { lat: 49.1404434, lng: 18.8944038 },
    { lat: 49.1403965, lng: 18.8944382 },
    { lat: 49.1397423, lng: 18.8945639 },
    { lat: 49.1391824, lng: 18.8947077 },
    { lat: 49.1388742, lng: 18.8948068 },
    { lat: 49.1374257, lng: 18.8947242 },
    { lat: 49.1373834, lng: 18.8946677 },
    { lat: 49.1369069, lng: 18.8948779 },
    { lat: 49.1367457, lng: 18.8950756 },
    { lat: 49.1358736, lng: 18.8956628 },
    { lat: 49.1352119, lng: 18.8962388 },
    { lat: 49.1350327, lng: 18.8964624 },
    { lat: 49.1346396, lng: 18.8969309 },
    { lat: 49.1343428, lng: 18.8973945 },
    { lat: 49.134289, lng: 18.8974877 },
    { lat: 49.1335636, lng: 18.8987192 },
    { lat: 49.1328268, lng: 18.9000796 },
    { lat: 49.1327929, lng: 18.9001419 },
    { lat: 49.1326373, lng: 18.9004304 },
    { lat: 49.132287, lng: 18.901077 },
    { lat: 49.1321648, lng: 18.9013813 },
    { lat: 49.1316145, lng: 18.9027498 },
    { lat: 49.1310891, lng: 18.9045444 },
    { lat: 49.1307312, lng: 18.9057053 },
    { lat: 49.1301412, lng: 18.9073141 },
    { lat: 49.1300533, lng: 18.9074952 },
    { lat: 49.129559, lng: 18.9085059 },
    { lat: 49.1290458, lng: 18.9093877 },
    { lat: 49.1289868, lng: 18.9093806 },
    { lat: 49.1288744, lng: 18.9094321 },
    { lat: 49.1287907, lng: 18.9095557 },
    { lat: 49.1289413, lng: 18.9099008 },
    { lat: 49.1289566, lng: 18.9100257 },
    { lat: 49.1288594, lng: 18.9102007 },
    { lat: 49.1288172, lng: 18.9102758 },
    { lat: 49.1275672, lng: 18.9111046 },
    { lat: 49.1272727, lng: 18.9113003 },
    { lat: 49.1259941, lng: 18.9123193 },
    { lat: 49.1249074, lng: 18.9135182 },
    { lat: 49.1248713, lng: 18.9135383 },
    { lat: 49.1247856, lng: 18.9135859 },
    { lat: 49.1247748, lng: 18.9135918 },
    { lat: 49.1247184, lng: 18.9136388 },
    { lat: 49.1247053, lng: 18.9136475 },
    { lat: 49.124628, lng: 18.9137013 },
    { lat: 49.1245953, lng: 18.9137228 },
    { lat: 49.1249784, lng: 18.9157151 },
    { lat: 49.1249892, lng: 18.9157689 },
    { lat: 49.125006, lng: 18.915858 },
    { lat: 49.1250168, lng: 18.9159132 },
    { lat: 49.1250292, lng: 18.9159801 },
    { lat: 49.1250351, lng: 18.9160079 },
    { lat: 49.1253371, lng: 18.9175806 },
    { lat: 49.1256837, lng: 18.9195186 },
    { lat: 49.1258954, lng: 18.9209148 },
    { lat: 49.126037, lng: 18.9218373 },
    { lat: 49.1259088, lng: 18.9219968 },
    { lat: 49.1257017, lng: 18.9222282 },
    { lat: 49.1256862, lng: 18.9224341 },
    { lat: 49.1255559, lng: 18.9225525 },
    { lat: 49.1254877, lng: 18.9225868 },
    { lat: 49.1248822, lng: 18.9233637 },
    { lat: 49.1245392, lng: 18.9241561 },
    { lat: 49.1244123, lng: 18.924522 },
    { lat: 49.124369, lng: 18.9246474 },
    { lat: 49.1242182, lng: 18.9252505 },
    { lat: 49.1241411, lng: 18.9258214 },
    { lat: 49.1241186, lng: 18.9261322 },
    { lat: 49.1240929, lng: 18.9263617 },
    { lat: 49.1240842, lng: 18.9264322 },
    { lat: 49.1240386, lng: 18.9265344 },
    { lat: 49.1238469, lng: 18.9269569 },
    { lat: 49.1234571, lng: 18.9278048 },
    { lat: 49.1233982, lng: 18.9279345 },
    { lat: 49.1228741, lng: 18.9287615 },
    { lat: 49.1221386, lng: 18.9299949 },
    { lat: 49.1220872, lng: 18.9302284 },
    { lat: 49.1220977, lng: 18.9303237 },
    { lat: 49.1221342, lng: 18.9306444 },
    { lat: 49.1221525, lng: 18.9308042 },
    { lat: 49.1223407, lng: 18.9310686 },
    { lat: 49.1224148, lng: 18.9311736 },
    { lat: 49.1224734, lng: 18.9311962 },
    { lat: 49.1225782, lng: 18.9312375 },
    { lat: 49.1227944, lng: 18.9313215 },
    { lat: 49.1228151, lng: 18.9313295 },
    { lat: 49.1228381, lng: 18.9312044 },
    { lat: 49.1231649, lng: 18.9314014 },
    { lat: 49.1235382, lng: 18.9316263 },
    { lat: 49.1236696, lng: 18.9317055 },
    { lat: 49.1237058, lng: 18.9317078 },
    { lat: 49.1237691, lng: 18.9317115 },
    { lat: 49.1239115, lng: 18.9317203 },
    { lat: 49.123952, lng: 18.931741 },
    { lat: 49.1239595, lng: 18.9317114 },
    { lat: 49.1242214, lng: 18.9313181 },
    { lat: 49.12425, lng: 18.9312564 },
    { lat: 49.1242631, lng: 18.9312271 },
    { lat: 49.1243979, lng: 18.9309967 },
    { lat: 49.1244832, lng: 18.9309633 },
    { lat: 49.1245151, lng: 18.9309509 },
    { lat: 49.1249111, lng: 18.9309968 },
    { lat: 49.1251559, lng: 18.9310695 },
    { lat: 49.1252682, lng: 18.9311031 },
    { lat: 49.125574, lng: 18.931044 },
    { lat: 49.1259146, lng: 18.9309776 },
    { lat: 49.1260084, lng: 18.9309594 },
    { lat: 49.1260669, lng: 18.930914 },
    { lat: 49.1260816, lng: 18.9310285 },
    { lat: 49.1260951, lng: 18.9311353 },
    { lat: 49.1261082, lng: 18.9312407 },
    { lat: 49.1262287, lng: 18.9315476 },
    { lat: 49.1263154, lng: 18.9317664 },
    { lat: 49.1264004, lng: 18.9318636 },
    { lat: 49.126805, lng: 18.932329 },
    { lat: 49.1268844, lng: 18.9325198 },
    { lat: 49.1269154, lng: 18.932595 },
    { lat: 49.1269514, lng: 18.9326804 },
    { lat: 49.1269796, lng: 18.9327483 },
    { lat: 49.127034, lng: 18.9328002 },
    { lat: 49.1271808, lng: 18.9329367 },
    { lat: 49.1272292, lng: 18.9329828 },
    { lat: 49.127343, lng: 18.9330897 },
    { lat: 49.127376, lng: 18.9331196 },
    { lat: 49.1274058, lng: 18.9331478 },
    { lat: 49.1274412, lng: 18.9331807 },
    { lat: 49.1274601, lng: 18.9331987 },
    { lat: 49.1275097, lng: 18.9332447 },
    { lat: 49.127717, lng: 18.9333495 },
    { lat: 49.1277366, lng: 18.9333598 },
    { lat: 49.1277475, lng: 18.933397 },
    { lat: 49.1277624, lng: 18.9334482 },
    { lat: 49.1277856, lng: 18.9335239 },
    { lat: 49.1280259, lng: 18.9335709 },
    { lat: 49.1281429, lng: 18.9336569 },
    { lat: 49.12835, lng: 18.9338089 },
    { lat: 49.1286372, lng: 18.934021 },
    { lat: 49.1289824, lng: 18.9342748 },
    { lat: 49.1292431, lng: 18.9331861 },
    { lat: 49.1293986, lng: 18.9322502 },
    { lat: 49.1291887, lng: 18.9321363 },
    { lat: 49.1292492, lng: 18.9318609 },
    { lat: 49.1292622, lng: 18.9318058 },
    { lat: 49.1293135, lng: 18.9315729 },
    { lat: 49.1293721, lng: 18.9313065 },
    { lat: 49.1293998, lng: 18.9313169 },
    { lat: 49.129563, lng: 18.9313812 },
    { lat: 49.1296545, lng: 18.9312066 },
    { lat: 49.1298417, lng: 18.9308491 },
    { lat: 49.1298931, lng: 18.9306184 },
    { lat: 49.1299515, lng: 18.9303494 },
    { lat: 49.1300396, lng: 18.9300186 },
    { lat: 49.1307901, lng: 18.9299241 },
    { lat: 49.1309116, lng: 18.9300283 },
    { lat: 49.1312046, lng: 18.9296928 },
    { lat: 49.1326688, lng: 18.9297108 },
    { lat: 49.1327164, lng: 18.9297114 },
    { lat: 49.1329353, lng: 18.9297803 },
    { lat: 49.1337843, lng: 18.929694 },
    { lat: 49.1348513, lng: 18.9297937 },
    { lat: 49.1350344, lng: 18.9297567 },
    { lat: 49.1359735, lng: 18.9298877 },
    { lat: 49.1366558, lng: 18.9301382 },
    { lat: 49.1371745, lng: 18.9301834 },
    { lat: 49.1372873, lng: 18.9302738 },
    { lat: 49.1375035, lng: 18.9304708 },
    { lat: 49.1381305, lng: 18.9309177 },
    { lat: 49.1387508, lng: 18.9313444 },
    { lat: 49.1398748, lng: 18.9321447 },
    { lat: 49.140329, lng: 18.9325629 },
    { lat: 49.1407874, lng: 18.9330433 },
    { lat: 49.1414443, lng: 18.933504 },
    { lat: 49.142003, lng: 18.9340291 },
    { lat: 49.1424744, lng: 18.9346517 },
    { lat: 49.1427538, lng: 18.9350041 },
    { lat: 49.1429468, lng: 18.9352328 },
    { lat: 49.1430444, lng: 18.9353135 },
    { lat: 49.1439618, lng: 18.9349495 },
    { lat: 49.1446959, lng: 18.9345977 },
    { lat: 49.1447953, lng: 18.9346231 },
    { lat: 49.1448599, lng: 18.9345827 },
    { lat: 49.1448834, lng: 18.9345803 },
    { lat: 49.1454861, lng: 18.9341486 },
    { lat: 49.1469045, lng: 18.9334389 },
    { lat: 49.1473135, lng: 18.9330104 },
    { lat: 49.1475956, lng: 18.9328145 },
    { lat: 49.1481963, lng: 18.9327906 },
    { lat: 49.148835, lng: 18.9330254 },
    { lat: 49.1501186, lng: 18.9336609 },
    { lat: 49.1505656, lng: 18.9342479 },
    { lat: 49.1511428, lng: 18.93516 },
    { lat: 49.1515516, lng: 18.9363977 },
    { lat: 49.1516956, lng: 18.936572 },
    { lat: 49.1517762, lng: 18.9366954 },
    { lat: 49.1518153, lng: 18.9367293 },
    { lat: 49.152334, lng: 18.9370447 },
    { lat: 49.1531639, lng: 18.9363113 },
    { lat: 49.1531951, lng: 18.9362839 },
    { lat: 49.1535896, lng: 18.9358124 },
    { lat: 49.1540835, lng: 18.9352782 },
    { lat: 49.1541351, lng: 18.9352289 },
    { lat: 49.1547803, lng: 18.9346789 },
    { lat: 49.1549805, lng: 18.9339162 },
    { lat: 49.1550975, lng: 18.9332869 },
    { lat: 49.1553346, lng: 18.9323567 },
    { lat: 49.1559395, lng: 18.9312344 },
    { lat: 49.1560402, lng: 18.930862 },
    { lat: 49.1563535, lng: 18.9303039 },
    { lat: 49.1563739, lng: 18.930152 },
    { lat: 49.1564294, lng: 18.9304877 },
    { lat: 49.1566144, lng: 18.9308466 },
    { lat: 49.1566814, lng: 18.9309248 },
    { lat: 49.1569885, lng: 18.9315997 },
    { lat: 49.1572264, lng: 18.9318883 },
    { lat: 49.1576285, lng: 18.9324634 },
    { lat: 49.1580306, lng: 18.9327665 },
    { lat: 49.1582286, lng: 18.9330688 },
    { lat: 49.1583321, lng: 18.9335962 },
    { lat: 49.158529, lng: 18.9339433 },
    { lat: 49.1585391, lng: 18.9341442 },
    { lat: 49.158739, lng: 18.9348202 },
    { lat: 49.1589988, lng: 18.9354301 },
    { lat: 49.1590179, lng: 18.9362557 },
    { lat: 49.1590064, lng: 18.9370733 },
    { lat: 49.158679, lng: 18.9384013 },
    { lat: 49.1580127, lng: 18.94036 },
    { lat: 49.1579513, lng: 18.941378 },
    { lat: 49.1581765, lng: 18.9420852 },
    { lat: 49.1582996, lng: 18.9433383 },
    { lat: 49.1583424, lng: 18.9434922 },
    { lat: 49.1583628, lng: 18.9438734 },
    { lat: 49.1585239, lng: 18.9446527 },
    { lat: 49.1581192, lng: 18.9476215 },
    { lat: 49.157799, lng: 18.949963 },
    { lat: 49.1579691, lng: 18.9499808 },
    { lat: 49.1582458, lng: 18.9500146 },
    { lat: 49.1586718, lng: 18.9500773 },
    { lat: 49.1596854, lng: 18.9503697 },
    { lat: 49.1600616, lng: 18.9508342 },
    { lat: 49.1603115, lng: 18.9511146 },
    { lat: 49.1608983, lng: 18.9518617 },
    { lat: 49.1612821, lng: 18.9522301 },
    { lat: 49.1617214, lng: 18.9531177 },
    { lat: 49.1620604, lng: 18.9540912 },
    { lat: 49.1624025, lng: 18.9546631 },
    { lat: 49.1627857, lng: 18.9546816 },
    { lat: 49.1630437, lng: 18.9548138 },
    { lat: 49.1634505, lng: 18.9548313 },
    { lat: 49.163901, lng: 18.955224 },
    { lat: 49.1645203, lng: 18.9554565 },
    { lat: 49.1647743, lng: 18.9555744 },
    { lat: 49.1655355, lng: 18.9556436 },
    { lat: 49.1657986, lng: 18.9557882 },
    { lat: 49.1662333, lng: 18.9559109 },
    { lat: 49.1664444, lng: 18.9559592 },
    { lat: 49.166703, lng: 18.9559916 },
    { lat: 49.1668461, lng: 18.9559662 },
    { lat: 49.1669864, lng: 18.9559558 },
    { lat: 49.1674321, lng: 18.9560772 },
    { lat: 49.1677195, lng: 18.9560741 },
    { lat: 49.168029, lng: 18.956059 },
    { lat: 49.168317, lng: 18.954182 },
    { lat: 49.167808, lng: 18.952867 },
    { lat: 49.167726, lng: 18.952761 },
    { lat: 49.167708, lng: 18.952428 },
    { lat: 49.167604, lng: 18.952144 },
    { lat: 49.167233, lng: 18.951625 },
    { lat: 49.166636, lng: 18.950653 },
    { lat: 49.166453, lng: 18.949924 },
    { lat: 49.166322, lng: 18.9497 },
    { lat: 49.166136, lng: 18.948909 },
    { lat: 49.165704, lng: 18.948173 },
    { lat: 49.165192, lng: 18.947196 },
    { lat: 49.164682, lng: 18.946371 },
    { lat: 49.16453, lng: 18.944738 },
    { lat: 49.164683, lng: 18.942959 },
    { lat: 49.16512, lng: 18.941675 },
    { lat: 49.165236, lng: 18.940918 },
    { lat: 49.165949, lng: 18.939943 },
    { lat: 49.167275, lng: 18.93799 },
    { lat: 49.167943, lng: 18.937399 },
    { lat: 49.168372, lng: 18.936713 },
    { lat: 49.168551, lng: 18.936425 },
    { lat: 49.168749, lng: 18.935647 },
    { lat: 49.169116, lng: 18.934907 },
    { lat: 49.169329, lng: 18.933975 },
    { lat: 49.169438, lng: 18.933863 },
    { lat: 49.169441, lng: 18.933589 },
    { lat: 49.16939, lng: 18.933201 },
    { lat: 49.16931, lng: 18.933124 },
    { lat: 49.169317, lng: 18.932969 },
    { lat: 49.169322, lng: 18.932841 },
    { lat: 49.169333, lng: 18.932744 },
    { lat: 49.169331, lng: 18.932276 },
    { lat: 49.169219, lng: 18.93192 },
    { lat: 49.169063, lng: 18.931696 },
    { lat: 49.169002, lng: 18.931365 },
    { lat: 49.168746, lng: 18.930976 },
    { lat: 49.168627, lng: 18.930647 },
    { lat: 49.168622, lng: 18.93046 },
    { lat: 49.168497, lng: 18.930067 },
    { lat: 49.168281, lng: 18.929897 },
    { lat: 49.168145, lng: 18.929302 },
    { lat: 49.168112, lng: 18.928964 },
    { lat: 49.168037, lng: 18.92859 },
    { lat: 49.16796, lng: 18.928184 },
    { lat: 49.168019, lng: 18.927788 },
    { lat: 49.167996, lng: 18.927342 },
    { lat: 49.167827, lng: 18.92735 },
    { lat: 49.167635, lng: 18.92707 },
    { lat: 49.166864, lng: 18.9267 },
    { lat: 49.166562, lng: 18.926268 },
    { lat: 49.166279, lng: 18.926081 },
    { lat: 49.165997, lng: 18.926293 },
    { lat: 49.165764, lng: 18.92608 },
    { lat: 49.165724, lng: 18.925705 },
    { lat: 49.1654, lng: 18.925126 },
    { lat: 49.165308, lng: 18.924566 },
    { lat: 49.165288, lng: 18.924568 },
    { lat: 49.165295, lng: 18.92387 },
    { lat: 49.165322, lng: 18.923742 },
    { lat: 49.165335, lng: 18.923375 },
    { lat: 49.165391, lng: 18.923123 },
    { lat: 49.165348, lng: 18.923019 },
    { lat: 49.165446, lng: 18.921957 },
    { lat: 49.165414, lng: 18.921606 },
    { lat: 49.165487, lng: 18.921311 },
    { lat: 49.165911, lng: 18.920796 },
    { lat: 49.165982, lng: 18.920299 },
    { lat: 49.165899, lng: 18.919726 },
    { lat: 49.165745, lng: 18.919242 },
    { lat: 49.165756, lng: 18.91909 },
    { lat: 49.165642, lng: 18.918825 },
    { lat: 49.165531, lng: 18.918662 },
    { lat: 49.165437, lng: 18.918344 },
    { lat: 49.165329, lng: 18.918016 },
    { lat: 49.165195, lng: 18.917599 },
    { lat: 49.16517, lng: 18.917528 },
    { lat: 49.165093, lng: 18.91736 },
    { lat: 49.165105, lng: 18.917194 },
    { lat: 49.164996, lng: 18.916964 },
    { lat: 49.164939, lng: 18.916957 },
    { lat: 49.164423, lng: 18.916209 },
    { lat: 49.164409, lng: 18.916215 },
    { lat: 49.164332, lng: 18.916247 },
    { lat: 49.164203, lng: 18.916132 },
    { lat: 49.164114, lng: 18.916114 },
    { lat: 49.164007, lng: 18.915946 },
    { lat: 49.163501, lng: 18.915483 },
    { lat: 49.16345, lng: 18.91543 },
    { lat: 49.163176, lng: 18.91508 },
    { lat: 49.162934, lng: 18.914897 },
    { lat: 49.162738, lng: 18.914881 },
    { lat: 49.162447, lng: 18.914766 },
    { lat: 49.162239, lng: 18.914407 },
    { lat: 49.162129, lng: 18.914419 },
    { lat: 49.161929, lng: 18.914225 },
    { lat: 49.161593, lng: 18.914078 },
    { lat: 49.1615, lng: 18.913924 },
    { lat: 49.16135, lng: 18.913831 },
    { lat: 49.161241, lng: 18.913805 },
    { lat: 49.161097, lng: 18.913613 },
    { lat: 49.160957, lng: 18.913444 },
    { lat: 49.160932, lng: 18.913331 },
    { lat: 49.160799, lng: 18.913129 },
    { lat: 49.16075, lng: 18.913145 },
    { lat: 49.160651, lng: 18.91302 },
    { lat: 49.160622, lng: 18.912895 },
    { lat: 49.160471, lng: 18.912599 },
    { lat: 49.160427, lng: 18.912576 },
    { lat: 49.160412, lng: 18.912548 },
    { lat: 49.1604, lng: 18.9124 },
    { lat: 49.160378, lng: 18.91233 },
    { lat: 49.160314, lng: 18.912201 },
    { lat: 49.160303, lng: 18.912124 },
    { lat: 49.160288, lng: 18.912013 },
    { lat: 49.160266, lng: 18.911866 },
    { lat: 49.160204, lng: 18.911728 },
    { lat: 49.160175, lng: 18.911473 },
    { lat: 49.160125, lng: 18.911464 },
    { lat: 49.160068, lng: 18.911315 },
    { lat: 49.160036, lng: 18.910887 },
    { lat: 49.159957, lng: 18.910587 },
    { lat: 49.159842, lng: 18.910061 },
    { lat: 49.159655, lng: 18.909531 },
    { lat: 49.159486, lng: 18.909478 },
    { lat: 49.159389, lng: 18.909349 },
    { lat: 49.159045, lng: 18.909236 },
    { lat: 49.158981, lng: 18.909123 },
    { lat: 49.158978, lng: 18.908939 },
    { lat: 49.158926, lng: 18.90886 },
    { lat: 49.158866, lng: 18.907898 },
    { lat: 49.158905, lng: 18.907353 },
    { lat: 49.158946, lng: 18.907169 },
    { lat: 49.158914, lng: 18.90691 },
    { lat: 49.158994, lng: 18.906645 },
    { lat: 49.158987, lng: 18.90644 },
    { lat: 49.159058, lng: 18.906169 },
    { lat: 49.159048, lng: 18.906136 },
    { lat: 49.159011, lng: 18.90613 },
    { lat: 49.158993, lng: 18.906094 },
    { lat: 49.159014, lng: 18.905802 },
    { lat: 49.159066, lng: 18.905625 },
    { lat: 49.159087, lng: 18.905438 },
    { lat: 49.159135, lng: 18.90506 },
    { lat: 49.15913, lng: 18.904655 },
    { lat: 49.1592, lng: 18.904344 },
    { lat: 49.159317, lng: 18.904195 },
    { lat: 49.159545, lng: 18.903555 },
    { lat: 49.160066, lng: 18.902778 },
    { lat: 49.160156, lng: 18.90253 },
    { lat: 49.160281, lng: 18.902281 },
    { lat: 49.160328, lng: 18.902096 },
    { lat: 49.160402, lng: 18.902051 },
    { lat: 49.160542, lng: 18.902051 },
    { lat: 49.160587, lng: 18.901909 },
    { lat: 49.160716, lng: 18.901662 },
    { lat: 49.160703, lng: 18.901498 },
    { lat: 49.160641, lng: 18.901177 },
    { lat: 49.160574, lng: 18.901136 },
    { lat: 49.160407, lng: 18.901166 },
    { lat: 49.160173, lng: 18.901073 },
    { lat: 49.160126, lng: 18.90093 },
    { lat: 49.160146, lng: 18.900779 },
    { lat: 49.160033, lng: 18.900576 },
    { lat: 49.160033, lng: 18.900425 },
    { lat: 49.159989, lng: 18.899957 },
    { lat: 49.160003, lng: 18.899637 },
    { lat: 49.16031, lng: 18.898943 },
    { lat: 49.160643, lng: 18.898853 },
    { lat: 49.160729, lng: 18.898775 },
    { lat: 49.160935, lng: 18.898629 },
    { lat: 49.161132, lng: 18.898575 },
    { lat: 49.161463, lng: 18.898174 },
    { lat: 49.161748, lng: 18.89762 },
    { lat: 49.161771, lng: 18.897097 },
    { lat: 49.161749, lng: 18.896877 },
    { lat: 49.161624, lng: 18.896704 },
    { lat: 49.161551, lng: 18.896521 },
    { lat: 49.161354, lng: 18.896277 },
    { lat: 49.161196, lng: 18.896218 },
    { lat: 49.160989, lng: 18.896044 },
    { lat: 49.160685, lng: 18.895989 },
    { lat: 49.160581, lng: 18.895861 },
    { lat: 49.160381, lng: 18.895781 },
    { lat: 49.159817, lng: 18.89535 },
    { lat: 49.159705, lng: 18.895357 },
    { lat: 49.159542, lng: 18.89551 },
    { lat: 49.159426, lng: 18.895406 },
    { lat: 49.159359, lng: 18.895232 },
    { lat: 49.159297, lng: 18.895158 },
    { lat: 49.159145, lng: 18.895104 },
    { lat: 49.158995, lng: 18.895208 },
    { lat: 49.158903, lng: 18.895116 },
    { lat: 49.158763, lng: 18.894726 },
    { lat: 49.158515, lng: 18.894614 },
    { lat: 49.158129, lng: 18.89416 },
    { lat: 49.158011, lng: 18.893908 },
    { lat: 49.157914, lng: 18.893591 },
    { lat: 49.157806, lng: 18.89354 },
    { lat: 49.157567, lng: 18.893109 },
    { lat: 49.157423, lng: 18.892951 },
    { lat: 49.15734, lng: 18.892874 },
    { lat: 49.157078, lng: 18.892663 },
    { lat: 49.156766, lng: 18.892686 },
    { lat: 49.156702, lng: 18.892542 },
    { lat: 49.156556, lng: 18.892472 },
    { lat: 49.156374, lng: 18.892454 },
    { lat: 49.156329, lng: 18.892303 },
    { lat: 49.15633, lng: 18.892085 },
    { lat: 49.156331, lng: 18.892013 },
    { lat: 49.156216, lng: 18.891813 },
    { lat: 49.15605, lng: 18.891671 },
    { lat: 49.155848, lng: 18.891401 },
    { lat: 49.155781, lng: 18.891124 },
    { lat: 49.155777, lng: 18.891029 },
    { lat: 49.155799, lng: 18.890846 },
    { lat: 49.155707, lng: 18.890686 },
    { lat: 49.15568, lng: 18.890627 },
    { lat: 49.155653, lng: 18.890542 },
    { lat: 49.155649, lng: 18.890323 },
    { lat: 49.155625, lng: 18.890223 },
    { lat: 49.155584, lng: 18.890159 },
    { lat: 49.155448, lng: 18.890083 },
    { lat: 49.155379, lng: 18.890088 },
    { lat: 49.155283, lng: 18.890053 },
    { lat: 49.155098, lng: 18.889448 },
    { lat: 49.155077, lng: 18.889384 },
    { lat: 49.155021, lng: 18.889248 },
    { lat: 49.154863, lng: 18.888867 },
    { lat: 49.154804, lng: 18.888755 },
    { lat: 49.154781, lng: 18.888714 },
    { lat: 49.154723, lng: 18.888616 },
    { lat: 49.154652, lng: 18.888547 },
    { lat: 49.154527, lng: 18.888532 },
    { lat: 49.154216, lng: 18.888307 },
    { lat: 49.154265, lng: 18.888122 },
    { lat: 49.15404, lng: 18.887846 },
    { lat: 49.153927, lng: 18.88746 },
    { lat: 49.153922, lng: 18.887442 },
    { lat: 49.153907, lng: 18.887375 },
    { lat: 49.153828, lng: 18.887012 },
    { lat: 49.153718, lng: 18.886934 },
    { lat: 49.15361, lng: 18.886931 },
    { lat: 49.15357, lng: 18.886672 },
    { lat: 49.153552, lng: 18.886571 },
    { lat: 49.153539, lng: 18.886584 },
    { lat: 49.1533586, lng: 18.8859767 },
    { lat: 49.152668, lng: 18.887001 },
    { lat: 49.152228, lng: 18.88745 },
    { lat: 49.151496, lng: 18.8883932 },
  ],
  Necpaly: [
    { lat: 48.9951236, lng: 18.9207879 },
    { lat: 48.9950448, lng: 18.9211717 },
    { lat: 48.9948662, lng: 18.9222005 },
    { lat: 48.9946336, lng: 18.9234343 },
    { lat: 48.9945218, lng: 18.9238086 },
    { lat: 48.9943267, lng: 18.9242516 },
    { lat: 48.9940181, lng: 18.924899 },
    { lat: 48.9939227, lng: 18.9251522 },
    { lat: 48.9938911, lng: 18.9255469 },
    { lat: 48.9939051, lng: 18.9258928 },
    { lat: 48.9940229, lng: 18.9272102 },
    { lat: 48.9950063, lng: 18.9287682 },
    { lat: 48.9955119, lng: 18.9298646 },
    { lat: 48.996157, lng: 18.9312843 },
    { lat: 48.9966708, lng: 18.9324311 },
    { lat: 48.9967424, lng: 18.9329107 },
    { lat: 48.9965667, lng: 18.9333998 },
    { lat: 48.9959995, lng: 18.9343766 },
    { lat: 48.9955883, lng: 18.9347379 },
    { lat: 48.9949122, lng: 18.9352779 },
    { lat: 48.9937585, lng: 18.9362038 },
    { lat: 48.9935786, lng: 18.9363456 },
    { lat: 48.993452, lng: 18.9364541 },
    { lat: 48.9933459, lng: 18.9366796 },
    { lat: 48.993278, lng: 18.9368239 },
    { lat: 48.9930311, lng: 18.937512 },
    { lat: 48.9925508, lng: 18.9382429 },
    { lat: 48.9922189, lng: 18.9387216 },
    { lat: 48.9920105, lng: 18.9389496 },
    { lat: 48.9917601, lng: 18.9391696 },
    { lat: 48.991392, lng: 18.9397132 },
    { lat: 48.9913766, lng: 18.9397642 },
    { lat: 48.9913344, lng: 18.9397796 },
    { lat: 48.9913094, lng: 18.9399304 },
    { lat: 48.9911594, lng: 18.94013 },
    { lat: 48.9909795, lng: 18.9404254 },
    { lat: 48.9909193, lng: 18.9404834 },
    { lat: 48.9908954, lng: 18.940548 },
    { lat: 48.9908715, lng: 18.9405644 },
    { lat: 48.99085, lng: 18.9405422 },
    { lat: 48.9897492, lng: 18.9397109 },
    { lat: 48.9888127, lng: 18.9389626 },
    { lat: 48.9876415, lng: 18.9380122 },
    { lat: 48.9872839, lng: 18.9375437 },
    { lat: 48.9868796, lng: 18.9371107 },
    { lat: 48.986509, lng: 18.936694 },
    { lat: 48.9859945, lng: 18.9361731 },
    { lat: 48.9858872, lng: 18.9361408 },
    { lat: 48.9858429, lng: 18.93624 },
    { lat: 48.9858619, lng: 18.9366385 },
    { lat: 48.9857222, lng: 18.9377863 },
    { lat: 48.9857084, lng: 18.9381344 },
    { lat: 48.9856748, lng: 18.9389854 },
    { lat: 48.985662, lng: 18.9393079 },
    { lat: 48.9856151, lng: 18.9400815 },
    { lat: 48.9855834, lng: 18.9406033 },
    { lat: 48.9855598, lng: 18.9407929 },
    { lat: 48.9854176, lng: 18.9420479 },
    { lat: 48.9853896, lng: 18.9422917 },
    { lat: 48.9852813, lng: 18.9429667 },
    { lat: 48.9852234, lng: 18.9433277 },
    { lat: 48.9851882, lng: 18.9436304 },
    { lat: 48.9850394, lng: 18.9449095 },
    { lat: 48.9850036, lng: 18.9452169 },
    { lat: 48.9850166, lng: 18.9454173 },
    { lat: 48.9851404, lng: 18.9473266 },
    { lat: 48.9851639, lng: 18.9476886 },
    { lat: 48.9851312, lng: 18.9478491 },
    { lat: 48.984865, lng: 18.9491558 },
    { lat: 48.9847576, lng: 18.9496202 },
    { lat: 48.984725, lng: 18.9497548 },
    { lat: 48.9846218, lng: 18.9500272 },
    { lat: 48.9843405, lng: 18.95077 },
    { lat: 48.9839404, lng: 18.9518262 },
    { lat: 48.9838577, lng: 18.9526414 },
    { lat: 48.9838263, lng: 18.9531322 },
    { lat: 48.9836532, lng: 18.9539033 },
    { lat: 48.9834566, lng: 18.9544238 },
    { lat: 48.9828365, lng: 18.9555595 },
    { lat: 48.9825659, lng: 18.9561418 },
    { lat: 48.9821688, lng: 18.9570059 },
    { lat: 48.9817301, lng: 18.9580291 },
    { lat: 48.9811896, lng: 18.95942 },
    { lat: 48.981138, lng: 18.9596941 },
    { lat: 48.9807882, lng: 18.9605375 },
    { lat: 48.9804253, lng: 18.9614941 },
    { lat: 48.9801893, lng: 18.9622689 },
    { lat: 48.9799321, lng: 18.9633359 },
    { lat: 48.9797793, lng: 18.9633236 },
    { lat: 48.979673, lng: 18.9636321 },
    { lat: 48.9796263, lng: 18.9637075 },
    { lat: 48.9793995, lng: 18.9641744 },
    { lat: 48.9789233, lng: 18.9648789 },
    { lat: 48.9782257, lng: 18.9657768 },
    { lat: 48.978162, lng: 18.9658758 },
    { lat: 48.9779422, lng: 18.9659452 },
    { lat: 48.9776475, lng: 18.9661569 },
    { lat: 48.9773485, lng: 18.9662671 },
    { lat: 48.9772052, lng: 18.9662411 },
    { lat: 48.9761955, lng: 18.9659605 },
    { lat: 48.9755593, lng: 18.9657979 },
    { lat: 48.9747213, lng: 18.9658346 },
    { lat: 48.9747242, lng: 18.9658939 },
    { lat: 48.974497, lng: 18.9670214 },
    { lat: 48.9738205, lng: 18.968224 },
    { lat: 48.9734858, lng: 18.9689423 },
    { lat: 48.9727712, lng: 18.9697936 },
    { lat: 48.9727309, lng: 18.970782 },
    { lat: 48.9727594, lng: 18.9714537 },
    { lat: 48.9724923, lng: 18.9723501 },
    { lat: 48.9725243, lng: 18.973209 },
    { lat: 48.9727162, lng: 18.97357 },
    { lat: 48.972074, lng: 18.973601 },
    { lat: 48.9718312, lng: 18.9736573 },
    { lat: 48.9714956, lng: 18.9737351 },
    { lat: 48.9712634, lng: 18.9737119 },
    { lat: 48.9710249, lng: 18.9739263 },
    { lat: 48.9704513, lng: 18.9739544 },
    { lat: 48.969981, lng: 18.9742826 },
    { lat: 48.9698747, lng: 18.9747697 },
    { lat: 48.9698652, lng: 18.9749844 },
    { lat: 48.9695694, lng: 18.9754652 },
    { lat: 48.9694673, lng: 18.9764931 },
    { lat: 48.9693989, lng: 18.9771813 },
    { lat: 48.9690916, lng: 18.9783528 },
    { lat: 48.9685883, lng: 18.9794603 },
    { lat: 48.9680234, lng: 18.9799512 },
    { lat: 48.9677292, lng: 18.9807089 },
    { lat: 48.9675331, lng: 18.9806671 },
    { lat: 48.9671183, lng: 18.9809579 },
    { lat: 48.9670404, lng: 18.9812497 },
    { lat: 48.9665418, lng: 18.981983 },
    { lat: 48.9662401, lng: 18.9827056 },
    { lat: 48.9662185, lng: 18.9829051 },
    { lat: 48.9661841, lng: 18.9830706 },
    { lat: 48.9660668, lng: 18.9831377 },
    { lat: 48.9660785, lng: 18.9831679 },
    { lat: 48.9659649, lng: 18.9835539 },
    { lat: 48.9658732, lng: 18.9842603 },
    { lat: 48.9652342, lng: 18.9849374 },
    { lat: 48.9649398, lng: 18.9850492 },
    { lat: 48.9642777, lng: 18.9855563 },
    { lat: 48.9637782, lng: 18.9866488 },
    { lat: 48.9635623, lng: 18.9870191 },
    { lat: 48.9633129, lng: 18.9874851 },
    { lat: 48.96312, lng: 18.9880866 },
    { lat: 48.9628803, lng: 18.9888769 },
    { lat: 48.9627554, lng: 18.9894418 },
    { lat: 48.9626607, lng: 18.9899576 },
    { lat: 48.9625666, lng: 18.9904403 },
    { lat: 48.9626147, lng: 18.9906539 },
    { lat: 48.9626955, lng: 18.9909165 },
    { lat: 48.9626802, lng: 18.9911522 },
    { lat: 48.9623863, lng: 18.9920535 },
    { lat: 48.9620022, lng: 18.9930249 },
    { lat: 48.961077, lng: 18.9942347 },
    { lat: 48.9610604, lng: 18.9942479 },
    { lat: 48.9601147, lng: 18.9949898 },
    { lat: 48.959904, lng: 18.9952869 },
    { lat: 48.9597339, lng: 18.9958458 },
    { lat: 48.9596011, lng: 18.9963667 },
    { lat: 48.9594351, lng: 18.9967204 },
    { lat: 48.9593089, lng: 18.9968125 },
    { lat: 48.9590674, lng: 18.9971214 },
    { lat: 48.9586314, lng: 18.9975512 },
    { lat: 48.958203, lng: 18.9979116 },
    { lat: 48.9577763, lng: 18.9982588 },
    { lat: 48.9576256, lng: 18.9984471 },
    { lat: 48.9570912, lng: 18.9990945 },
    { lat: 48.9568819, lng: 18.9993724 },
    { lat: 48.9565727, lng: 18.9999923 },
    { lat: 48.9564988, lng: 19.000276 },
    { lat: 48.9558666, lng: 19.0010646 },
    { lat: 48.9556323, lng: 19.0016066 },
    { lat: 48.9554594, lng: 19.0025425 },
    { lat: 48.9550541, lng: 19.0030422 },
    { lat: 48.9547566, lng: 19.0038303 },
    { lat: 48.9546858, lng: 19.0044204 },
    { lat: 48.9544141, lng: 19.0049369 },
    { lat: 48.9542531, lng: 19.0051234 },
    { lat: 48.9539948, lng: 19.0051633 },
    { lat: 48.9536189, lng: 19.005694 },
    { lat: 48.9530468, lng: 19.0064253 },
    { lat: 48.9530784, lng: 19.0071208 },
    { lat: 48.9531723, lng: 19.0078414 },
    { lat: 48.9532186, lng: 19.0084957 },
    { lat: 48.9532287, lng: 19.0093271 },
    { lat: 48.9531874, lng: 19.0100536 },
    { lat: 48.9531913, lng: 19.0105116 },
    { lat: 48.9531337, lng: 19.0110728 },
    { lat: 48.9531774, lng: 19.0114509 },
    { lat: 48.953222, lng: 19.0119106 },
    { lat: 48.9531051, lng: 19.0124156 },
    { lat: 48.952918, lng: 19.0127974 },
    { lat: 48.952753, lng: 19.0132136 },
    { lat: 48.9526544, lng: 19.013697 },
    { lat: 48.9525778, lng: 19.0140574 },
    { lat: 48.9520702, lng: 19.0147048 },
    { lat: 48.9514342, lng: 19.0142517 },
    { lat: 48.9508326, lng: 19.0149368 },
    { lat: 48.9500847, lng: 19.0156686 },
    { lat: 48.9498257, lng: 19.0160583 },
    { lat: 48.949416, lng: 19.0161139 },
    { lat: 48.9489256, lng: 19.0162032 },
    { lat: 48.9488719, lng: 19.0165028 },
    { lat: 48.9486328, lng: 19.0168611 },
    { lat: 48.9482586, lng: 19.0171806 },
    { lat: 48.9477573, lng: 19.0174118 },
    { lat: 48.9475364, lng: 19.0176611 },
    { lat: 48.9473935, lng: 19.0180965 },
    { lat: 48.9472689, lng: 19.0187766 },
    { lat: 48.9469941, lng: 19.019506 },
    { lat: 48.9468885, lng: 19.020143 },
    { lat: 48.9466087, lng: 19.0208819 },
    { lat: 48.9463433, lng: 19.0214311 },
    { lat: 48.9459551, lng: 19.0217177 },
    { lat: 48.9459891, lng: 19.0221929 },
    { lat: 48.9459347, lng: 19.0232677 },
    { lat: 48.9460783, lng: 19.0239074 },
    { lat: 48.9461551, lng: 19.0243383 },
    { lat: 48.9460727, lng: 19.0245845 },
    { lat: 48.9460975, lng: 19.0247861 },
    { lat: 48.9461729, lng: 19.0252777 },
    { lat: 48.9460565, lng: 19.0261283 },
    { lat: 48.9460063, lng: 19.0263636 },
    { lat: 48.9456913, lng: 19.0275261 },
    { lat: 48.9455257, lng: 19.0277106 },
    { lat: 48.945144, lng: 19.0278303 },
    { lat: 48.9450341, lng: 19.0280226 },
    { lat: 48.9449614, lng: 19.0285098 },
    { lat: 48.9447384, lng: 19.0295487 },
    { lat: 48.9441972, lng: 19.0304044 },
    { lat: 48.9434688, lng: 19.0305817 },
    { lat: 48.9428849, lng: 19.0315287 },
    { lat: 48.9428258, lng: 19.0316306 },
    { lat: 48.9427272, lng: 19.0318223 },
    { lat: 48.9425955, lng: 19.0320573 },
    { lat: 48.9422238, lng: 19.0328052 },
    { lat: 48.9419437, lng: 19.0335157 },
    { lat: 48.9416219, lng: 19.034048 },
    { lat: 48.9408375, lng: 19.0353373 },
    { lat: 48.9402961, lng: 19.0358052 },
    { lat: 48.9395085, lng: 19.0365159 },
    { lat: 48.9383275, lng: 19.0374441 },
    { lat: 48.9380999, lng: 19.037854 },
    { lat: 48.9378462, lng: 19.0384172 },
    { lat: 48.9375875, lng: 19.0391075 },
    { lat: 48.9375301, lng: 19.0395365 },
    { lat: 48.9373501, lng: 19.0397688 },
    { lat: 48.9370737, lng: 19.040266 },
    { lat: 48.9369173, lng: 19.0406294 },
    { lat: 48.9366426, lng: 19.0410654 },
    { lat: 48.9364893, lng: 19.0414496 },
    { lat: 48.9359869, lng: 19.0424289 },
    { lat: 48.9353678, lng: 19.0437788 },
    { lat: 48.9350458, lng: 19.0440159 },
    { lat: 48.9347141, lng: 19.04455 },
    { lat: 48.9340553, lng: 19.0449248 },
    { lat: 48.9339303, lng: 19.0451084 },
    { lat: 48.9337123, lng: 19.0454647 },
    { lat: 48.9333682, lng: 19.0463365 },
    { lat: 48.9333179, lng: 19.046707 },
    { lat: 48.9329914, lng: 19.0471238 },
    { lat: 48.9326417, lng: 19.047458 },
    { lat: 48.9319776, lng: 19.0480209 },
    { lat: 48.9312946, lng: 19.0489947 },
    { lat: 48.9310391, lng: 19.0498161 },
    { lat: 48.9306652, lng: 19.0503477 },
    { lat: 48.9298525, lng: 19.051005 },
    { lat: 48.929198, lng: 19.051149 },
    { lat: 48.9289461, lng: 19.0514318 },
    { lat: 48.9286164, lng: 19.0518726 },
    { lat: 48.9285222, lng: 19.0529934 },
    { lat: 48.9284523, lng: 19.0536009 },
    { lat: 48.9274093, lng: 19.054661 },
    { lat: 48.9268301, lng: 19.0555714 },
    { lat: 48.9264797, lng: 19.0558038 },
    { lat: 48.9259486, lng: 19.0562752 },
    { lat: 48.9248026, lng: 19.0573707 },
    { lat: 48.9243134, lng: 19.0577129 },
    { lat: 48.9237793, lng: 19.0587137 },
    { lat: 48.9231657, lng: 19.0594276 },
    { lat: 48.9229287, lng: 19.0599677 },
    { lat: 48.9225206, lng: 19.0610548 },
    { lat: 48.9215818, lng: 19.0618295 },
    { lat: 48.9212523, lng: 19.0627509 },
    { lat: 48.9209439, lng: 19.0639147 },
    { lat: 48.9208969, lng: 19.0648277 },
    { lat: 48.9209845, lng: 19.0654998 },
    { lat: 48.920755, lng: 19.0659631 },
    { lat: 48.9204765, lng: 19.0665757 },
    { lat: 48.9200461, lng: 19.0676018 },
    { lat: 48.9195616, lng: 19.0688097 },
    { lat: 48.9191385, lng: 19.0691413 },
    { lat: 48.9189999, lng: 19.0696307 },
    { lat: 48.9188029, lng: 19.0703264 },
    { lat: 48.9187258, lng: 19.0703943 },
    { lat: 48.9186934, lng: 19.0704229 },
    { lat: 48.9184852, lng: 19.0706065 },
    { lat: 48.9184562, lng: 19.070632 },
    { lat: 48.9184486, lng: 19.0708675 },
    { lat: 48.9183485, lng: 19.0710745 },
    { lat: 48.9182793, lng: 19.0713587 },
    { lat: 48.9181552, lng: 19.0724895 },
    { lat: 48.9177137, lng: 19.0737924 },
    { lat: 48.9173414, lng: 19.0746715 },
    { lat: 48.9165493, lng: 19.0757227 },
    { lat: 48.9159551, lng: 19.0764906 },
    { lat: 48.9153262, lng: 19.0772894 },
    { lat: 48.9138229, lng: 19.078821 },
    { lat: 48.9134707, lng: 19.0794691 },
    { lat: 48.9114279, lng: 19.0808077 },
    { lat: 48.9102612, lng: 19.0816033 },
    { lat: 48.908826, lng: 19.082561 },
    { lat: 48.908704, lng: 19.083286 },
    { lat: 48.908706, lng: 19.084213 },
    { lat: 48.908907, lng: 19.085676 },
    { lat: 48.908991, lng: 19.086128 },
    { lat: 48.909141, lng: 19.087186 },
    { lat: 48.909516, lng: 19.08771 },
    { lat: 48.909842, lng: 19.088204 },
    { lat: 48.910109, lng: 19.088283 },
    { lat: 48.910812, lng: 19.088277 },
    { lat: 48.91192, lng: 19.088227 },
    { lat: 48.913046, lng: 19.088146 },
    { lat: 48.914626, lng: 19.088357 },
    { lat: 48.914708, lng: 19.088405 },
    { lat: 48.915192, lng: 19.088684 },
    { lat: 48.916279, lng: 19.089231 },
    { lat: 48.916627, lng: 19.089724 },
    { lat: 48.917859, lng: 19.091584 },
    { lat: 48.918876, lng: 19.092704 },
    { lat: 48.920238, lng: 19.092894 },
    { lat: 48.921251, lng: 19.092979 },
    { lat: 48.922023, lng: 19.094161 },
    { lat: 48.922806, lng: 19.095429 },
    { lat: 48.923641, lng: 19.097349 },
    { lat: 48.923811, lng: 19.098252 },
    { lat: 48.923831, lng: 19.098919 },
    { lat: 48.923861, lng: 19.099935 },
    { lat: 48.924086, lng: 19.101069 },
    { lat: 48.924313, lng: 19.101519 },
    { lat: 48.924605, lng: 19.102101 },
    { lat: 48.925513, lng: 19.103339 },
    { lat: 48.926171, lng: 19.104254 },
    { lat: 48.9265444, lng: 19.1040202 },
    { lat: 48.9272137, lng: 19.1035343 },
    { lat: 48.9275946, lng: 19.1034878 },
    { lat: 48.9281006, lng: 19.1036894 },
    { lat: 48.9287046, lng: 19.1037752 },
    { lat: 48.9302716, lng: 19.1033685 },
    { lat: 48.9310021, lng: 19.1030925 },
    { lat: 48.9316961, lng: 19.1028145 },
    { lat: 48.9322651, lng: 19.1029007 },
    { lat: 48.9328014, lng: 19.1030311 },
    { lat: 48.9334832, lng: 19.1031367 },
    { lat: 48.9340096, lng: 19.1032536 },
    { lat: 48.9344397, lng: 19.1034018 },
    { lat: 48.9350823, lng: 19.1037231 },
    { lat: 48.9356529, lng: 19.1033631 },
    { lat: 48.936694, lng: 19.1029868 },
    { lat: 48.9371217, lng: 19.1028352 },
    { lat: 48.9381745, lng: 19.1024482 },
    { lat: 48.9390465, lng: 19.102298 },
    { lat: 48.9399624, lng: 19.1022651 },
    { lat: 48.9411577, lng: 19.1020648 },
    { lat: 48.9407341, lng: 19.0995358 },
    { lat: 48.9405313, lng: 19.0983577 },
    { lat: 48.9404501, lng: 19.0973327 },
    { lat: 48.9403114, lng: 19.0955983 },
    { lat: 48.9408777, lng: 19.093555 },
    { lat: 48.9409803, lng: 19.0931841 },
    { lat: 48.9413471, lng: 19.0918576 },
    { lat: 48.9414805, lng: 19.0913633 },
    { lat: 48.9414321, lng: 19.0907729 },
    { lat: 48.9411854, lng: 19.0894541 },
    { lat: 48.9409034, lng: 19.0879256 },
    { lat: 48.9414666, lng: 19.0856427 },
    { lat: 48.9427586, lng: 19.0838233 },
    { lat: 48.944333, lng: 19.082654 },
    { lat: 48.9444432, lng: 19.0820806 },
    { lat: 48.9446618, lng: 19.0808058 },
    { lat: 48.9451367, lng: 19.0793367 },
    { lat: 48.9453801, lng: 19.0773321 },
    { lat: 48.9456996, lng: 19.0755504 },
    { lat: 48.9459685, lng: 19.0751771 },
    { lat: 48.9462365, lng: 19.0746932 },
    { lat: 48.9465782, lng: 19.0739257 },
    { lat: 48.9466335, lng: 19.0734011 },
    { lat: 48.946931, lng: 19.0719723 },
    { lat: 48.9472229, lng: 19.0709472 },
    { lat: 48.947199, lng: 19.0701487 },
    { lat: 48.9478723, lng: 19.0692263 },
    { lat: 48.9482317, lng: 19.0685628 },
    { lat: 48.9483642, lng: 19.0682769 },
    { lat: 48.9487912, lng: 19.0676871 },
    { lat: 48.9492855, lng: 19.0669052 },
    { lat: 48.9493593, lng: 19.0663452 },
    { lat: 48.9493739, lng: 19.0649615 },
    { lat: 48.9493687, lng: 19.0641978 },
    { lat: 48.949692, lng: 19.0639438 },
    { lat: 48.9499182, lng: 19.0637564 },
    { lat: 48.9503184, lng: 19.0633246 },
    { lat: 48.9508795, lng: 19.0626613 },
    { lat: 48.9514994, lng: 19.0623675 },
    { lat: 48.9519419, lng: 19.0623307 },
    { lat: 48.9523879, lng: 19.0620063 },
    { lat: 48.9526137, lng: 19.0618979 },
    { lat: 48.9528573, lng: 19.0616522 },
    { lat: 48.9534646, lng: 19.0608529 },
    { lat: 48.9535132, lng: 19.0608083 },
    { lat: 48.9538225, lng: 19.0602982 },
    { lat: 48.9541822, lng: 19.0595518 },
    { lat: 48.9546745, lng: 19.0591147 },
    { lat: 48.9547261, lng: 19.0582479 },
    { lat: 48.9549666, lng: 19.0573034 },
    { lat: 48.9553337, lng: 19.0562558 },
    { lat: 48.9553055, lng: 19.0560485 },
    { lat: 48.9560506, lng: 19.0551199 },
    { lat: 48.9565948, lng: 19.0545065 },
    { lat: 48.956839, lng: 19.0541782 },
    { lat: 48.9573244, lng: 19.0537174 },
    { lat: 48.9577862, lng: 19.0534846 },
    { lat: 48.9583287, lng: 19.0529653 },
    { lat: 48.9591808, lng: 19.05224 },
    { lat: 48.95917, lng: 19.0512787 },
    { lat: 48.959291, lng: 19.05083 },
    { lat: 48.959557, lng: 19.0502179 },
    { lat: 48.9600721, lng: 19.0491772 },
    { lat: 48.9602888, lng: 19.0484592 },
    { lat: 48.9605098, lng: 19.0474895 },
    { lat: 48.9608078, lng: 19.0469297 },
    { lat: 48.9609818, lng: 19.046689 },
    { lat: 48.9611585, lng: 19.0465474 },
    { lat: 48.9615975, lng: 19.0462178 },
    { lat: 48.9623306, lng: 19.0450486 },
    { lat: 48.9627817, lng: 19.0447161 },
    { lat: 48.9634674, lng: 19.0449451 },
    { lat: 48.963846, lng: 19.0451911 },
    { lat: 48.9644209, lng: 19.0451891 },
    { lat: 48.9649225, lng: 19.0451185 },
    { lat: 48.965473, lng: 19.0451969 },
    { lat: 48.9656495, lng: 19.0449836 },
    { lat: 48.9660573, lng: 19.0446819 },
    { lat: 48.9663964, lng: 19.0446989 },
    { lat: 48.9671459, lng: 19.0447199 },
    { lat: 48.9674482, lng: 19.0441548 },
    { lat: 48.9678722, lng: 19.0437285 },
    { lat: 48.9682044, lng: 19.0433179 },
    { lat: 48.9687896, lng: 19.0428881 },
    { lat: 48.9688402, lng: 19.0425723 },
    { lat: 48.9693246, lng: 19.0419959 },
    { lat: 48.9699072, lng: 19.0413812 },
    { lat: 48.9702006, lng: 19.0411401 },
    { lat: 48.9704024, lng: 19.0409141 },
    { lat: 48.9704683, lng: 19.0407683 },
    { lat: 48.9705663, lng: 19.0404266 },
    { lat: 48.9706455, lng: 19.040007 },
    { lat: 48.9707512, lng: 19.0395502 },
    { lat: 48.9709288, lng: 19.0391085 },
    { lat: 48.9706859, lng: 19.0382419 },
    { lat: 48.9702709, lng: 19.0373101 },
    { lat: 48.9701735, lng: 19.0370077 },
    { lat: 48.9702091, lng: 19.03639 },
    { lat: 48.9703527, lng: 19.0359691 },
    { lat: 48.9707083, lng: 19.0350995 },
    { lat: 48.9707485, lng: 19.0347081 },
    { lat: 48.9708659, lng: 19.0343727 },
    { lat: 48.9709484, lng: 19.034014 },
    { lat: 48.9711972, lng: 19.0334901 },
    { lat: 48.9714826, lng: 19.0327256 },
    { lat: 48.9715906, lng: 19.0320216 },
    { lat: 48.971585, lng: 19.0313717 },
    { lat: 48.97171, lng: 19.030734 },
    { lat: 48.9718529, lng: 19.0302323 },
    { lat: 48.9718789, lng: 19.0298737 },
    { lat: 48.972686, lng: 19.0298703 },
    { lat: 48.9732849, lng: 19.0301027 },
    { lat: 48.9737319, lng: 19.0303177 },
    { lat: 48.9745444, lng: 19.0304938 },
    { lat: 48.975001, lng: 19.0302413 },
    { lat: 48.9754891, lng: 19.029543 },
    { lat: 48.9757092, lng: 19.0287808 },
    { lat: 48.9760036, lng: 19.0281769 },
    { lat: 48.9763565, lng: 19.0276625 },
    { lat: 48.9766123, lng: 19.0272084 },
    { lat: 48.9768958, lng: 19.0268771 },
    { lat: 48.9771642, lng: 19.0268824 },
    { lat: 48.9777013, lng: 19.0266449 },
    { lat: 48.9780765, lng: 19.0265287 },
    { lat: 48.9784747, lng: 19.0263503 },
    { lat: 48.9790981, lng: 19.02626 },
    { lat: 48.9796757, lng: 19.0258642 },
    { lat: 48.9798119, lng: 19.0256543 },
    { lat: 48.9802659, lng: 19.0251299 },
    { lat: 48.9807052, lng: 19.0249658 },
    { lat: 48.9809773, lng: 19.0245686 },
    { lat: 48.9810807, lng: 19.0242229 },
    { lat: 48.9813165, lng: 19.0237564 },
    { lat: 48.9822269, lng: 19.0230665 },
    { lat: 48.983028, lng: 19.0227651 },
    { lat: 48.9832581, lng: 19.0225655 },
    { lat: 48.9835477, lng: 19.0218443 },
    { lat: 48.9838202, lng: 19.021408 },
    { lat: 48.9840982, lng: 19.0208353 },
    { lat: 48.9843552, lng: 19.0203393 },
    { lat: 48.9848772, lng: 19.0192377 },
    { lat: 48.985244, lng: 19.018728 },
    { lat: 48.9854466, lng: 19.0181586 },
    { lat: 48.9853664, lng: 19.0169169 },
    { lat: 48.9853401, lng: 19.0165277 },
    { lat: 48.9853744, lng: 19.0155908 },
    { lat: 48.9854097, lng: 19.0151039 },
    { lat: 48.9856469, lng: 19.0146422 },
    { lat: 48.9859056, lng: 19.0139765 },
    { lat: 48.985976, lng: 19.013854 },
    { lat: 48.9862523, lng: 19.0133734 },
    { lat: 48.9865351, lng: 19.0128814 },
    { lat: 48.986778, lng: 19.0126131 },
    { lat: 48.9868994, lng: 19.0124791 },
    { lat: 48.9870927, lng: 19.0122655 },
    { lat: 48.9872629, lng: 19.0119875 },
    { lat: 48.9874486, lng: 19.0116842 },
    { lat: 48.987585, lng: 19.0114614 },
    { lat: 48.9876767, lng: 19.0113111 },
    { lat: 48.9877479, lng: 19.0111953 },
    { lat: 48.9878171, lng: 19.0111257 },
    { lat: 48.9879977, lng: 19.0109441 },
    { lat: 48.9881972, lng: 19.0107436 },
    { lat: 48.9883511, lng: 19.0105888 },
    { lat: 48.9884315, lng: 19.010232 },
    { lat: 48.9884699, lng: 19.0101632 },
    { lat: 48.9890148, lng: 19.0091866 },
    { lat: 48.9888697, lng: 19.0086298 },
    { lat: 48.9888478, lng: 19.0085502 },
    { lat: 48.9887195, lng: 19.0080844 },
    { lat: 48.98847, lng: 19.0067747 },
    { lat: 48.9884213, lng: 19.0061855 },
    { lat: 48.9883389, lng: 19.0055327 },
    { lat: 48.9880618, lng: 19.0041681 },
    { lat: 48.9878617, lng: 19.0027429 },
    { lat: 48.9878868, lng: 19.0012495 },
    { lat: 48.9878377, lng: 19.000855 },
    { lat: 48.9878517, lng: 19.0008422 },
    { lat: 48.9877443, lng: 19.0004537 },
    { lat: 48.9877051, lng: 19.0002321 },
    { lat: 48.9876991, lng: 18.9999116 },
    { lat: 48.9876789, lng: 18.9999139 },
    { lat: 48.9874365, lng: 18.9983195 },
    { lat: 48.9872346, lng: 18.9979822 },
    { lat: 48.987005, lng: 18.9975178 },
    { lat: 48.9870996, lng: 18.9970035 },
    { lat: 48.9874747, lng: 18.9961659 },
    { lat: 48.9879969, lng: 18.9953824 },
    { lat: 48.9880134, lng: 18.9954203 },
    { lat: 48.9881185, lng: 18.9952455 },
    { lat: 48.9880154, lng: 18.9943166 },
    { lat: 48.988012, lng: 18.9938453 },
    { lat: 48.9880565, lng: 18.9931861 },
    { lat: 48.988009, lng: 18.9927106 },
    { lat: 48.9880045, lng: 18.9924189 },
    { lat: 48.9880381, lng: 18.9921253 },
    { lat: 48.9881118, lng: 18.9917481 },
    { lat: 48.9882433, lng: 18.9908691 },
    { lat: 48.9881758, lng: 18.9897767 },
    { lat: 48.9882161, lng: 18.9894839 },
    { lat: 48.9882805, lng: 18.9892326 },
    { lat: 48.9887172, lng: 18.9882359 },
    { lat: 48.9887464, lng: 18.9879618 },
    { lat: 48.9887873, lng: 18.9874983 },
    { lat: 48.9888265, lng: 18.9873609 },
    { lat: 48.9889755, lng: 18.986915 },
    { lat: 48.9891283, lng: 18.9865002 },
    { lat: 48.9892427, lng: 18.9862191 },
    { lat: 48.989409, lng: 18.9859201 },
    { lat: 48.9895359, lng: 18.9856386 },
    { lat: 48.9896443, lng: 18.9854641 },
    { lat: 48.9898928, lng: 18.9852058 },
    { lat: 48.9901703, lng: 18.9849643 },
    { lat: 48.9902954, lng: 18.9847383 },
    { lat: 48.9904052, lng: 18.9844552 },
    { lat: 48.990542, lng: 18.984263 },
    { lat: 48.9907714, lng: 18.9840719 },
    { lat: 48.9909187, lng: 18.9840163 },
    { lat: 48.9910653, lng: 18.9839964 },
    { lat: 48.9914148, lng: 18.9840408 },
    { lat: 48.9914605, lng: 18.9840326 },
    { lat: 48.9915068, lng: 18.9840097 },
    { lat: 48.9915556, lng: 18.9839509 },
    { lat: 48.9916115, lng: 18.9837857 },
    { lat: 48.9916395, lng: 18.9835821 },
    { lat: 48.9916739, lng: 18.9833871 },
    { lat: 48.9917166, lng: 18.983362 },
    { lat: 48.9919118, lng: 18.9830532 },
    { lat: 48.9925388, lng: 18.9826798 },
    { lat: 48.9925891, lng: 18.9826226 },
    { lat: 48.9931423, lng: 18.9819683 },
    { lat: 48.9935118, lng: 18.9816266 },
    { lat: 48.9936405, lng: 18.9815653 },
    { lat: 48.993815, lng: 18.9813982 },
    { lat: 48.9939446, lng: 18.9812654 },
    { lat: 48.9940553, lng: 18.9811146 },
    { lat: 48.9941669, lng: 18.9809322 },
    { lat: 48.994151, lng: 18.9808432 },
    { lat: 48.9942737, lng: 18.9807032 },
    { lat: 48.994394, lng: 18.980541 },
    { lat: 48.9944924, lng: 18.9803453 },
    { lat: 48.9945672, lng: 18.9801101 },
    { lat: 48.9946754, lng: 18.9799086 },
    { lat: 48.9948999, lng: 18.9795073 },
    { lat: 48.9950699, lng: 18.979256 },
    { lat: 48.9951442, lng: 18.9791173 },
    { lat: 48.9952741, lng: 18.9788132 },
    { lat: 48.9952994, lng: 18.9786627 },
    { lat: 48.9952885, lng: 18.9783581 },
    { lat: 48.995344, lng: 18.9781602 },
    { lat: 48.9957097, lng: 18.9774114 },
    { lat: 48.995893, lng: 18.9770221 },
    { lat: 48.9961263, lng: 18.9764858 },
    { lat: 48.996291, lng: 18.9762189 },
    { lat: 48.9963688, lng: 18.9759481 },
    { lat: 48.9964478, lng: 18.9757737 },
    { lat: 48.9965385, lng: 18.9756282 },
    { lat: 48.9968245, lng: 18.9751952 },
    { lat: 48.9969961, lng: 18.9749289 },
    { lat: 48.9971339, lng: 18.974733 },
    { lat: 48.9972488, lng: 18.97453 },
    { lat: 48.9973078, lng: 18.9743357 },
    { lat: 48.9973207, lng: 18.9742592 },
    { lat: 48.9973032, lng: 18.9740246 },
    { lat: 48.997221, lng: 18.9734863 },
    { lat: 48.9972279, lng: 18.9734449 },
    { lat: 48.9973176, lng: 18.9731024 },
    { lat: 48.9973776, lng: 18.9728397 },
    { lat: 48.9974125, lng: 18.9727502 },
    { lat: 48.9974666, lng: 18.9726606 },
    { lat: 48.9976383, lng: 18.9724205 },
    { lat: 48.9978068, lng: 18.9720519 },
    { lat: 48.9978605, lng: 18.9719088 },
    { lat: 48.9979022, lng: 18.9717054 },
    { lat: 48.9979175, lng: 18.9715402 },
    { lat: 48.9979217, lng: 18.9713573 },
    { lat: 48.9979459, lng: 18.9712087 },
    { lat: 48.9979902, lng: 18.9710801 },
    { lat: 48.9980896, lng: 18.9709069 },
    { lat: 48.9981137, lng: 18.9708495 },
    { lat: 48.9982547, lng: 18.9706051 },
    { lat: 48.9983154, lng: 18.9704465 },
    { lat: 48.9983665, lng: 18.9702736 },
    { lat: 48.9984303, lng: 18.9700166 },
    { lat: 48.9985128, lng: 18.9696432 },
    { lat: 48.9986078, lng: 18.9694243 },
    { lat: 48.9986506, lng: 18.9693607 },
    { lat: 48.9988834, lng: 18.9690586 },
    { lat: 48.9989628, lng: 18.9689315 },
    { lat: 48.9990841, lng: 18.9686761 },
    { lat: 48.9991282, lng: 18.9685435 },
    { lat: 48.9992447, lng: 18.9680394 },
    { lat: 48.999316, lng: 18.9678893 },
    { lat: 48.9994201, lng: 18.9676951 },
    { lat: 48.9995206, lng: 18.967543 },
    { lat: 48.9997665, lng: 18.9671452 },
    { lat: 48.999886, lng: 18.9669653 },
    { lat: 49.0004559, lng: 18.9660823 },
    { lat: 49.0006132, lng: 18.9658485 },
    { lat: 49.0008052, lng: 18.9655198 },
    { lat: 49.0009434, lng: 18.9652663 },
    { lat: 49.0012513, lng: 18.9647316 },
    { lat: 49.0013275, lng: 18.9646114 },
    { lat: 49.001653, lng: 18.964228 },
    { lat: 49.0016791, lng: 18.9642059 },
    { lat: 49.0017989, lng: 18.9640346 },
    { lat: 49.0020054, lng: 18.9637028 },
    { lat: 49.0022247, lng: 18.963335 },
    { lat: 49.0026611, lng: 18.9626255 },
    { lat: 49.003117, lng: 18.9618052 },
    { lat: 49.003185, lng: 18.9616784 },
    { lat: 49.0033636, lng: 18.9611976 },
    { lat: 49.0034374, lng: 18.9609649 },
    { lat: 49.0035572, lng: 18.9605692 },
    { lat: 49.0037354, lng: 18.9599257 },
    { lat: 49.003772, lng: 18.9597325 },
    { lat: 49.0038629, lng: 18.9593219 },
    { lat: 49.0039254, lng: 18.9590862 },
    { lat: 49.004045, lng: 18.9586664 },
    { lat: 49.0041258, lng: 18.9584365 },
    { lat: 49.0042672, lng: 18.9583553 },
    { lat: 49.0043239, lng: 18.9583609 },
    { lat: 49.0044269, lng: 18.9583266 },
    { lat: 49.0045199, lng: 18.9583822 },
    { lat: 49.0046668, lng: 18.9584979 },
    { lat: 49.0048437, lng: 18.9586553 },
    { lat: 49.0054043, lng: 18.9590606 },
    { lat: 49.005593, lng: 18.9591822 },
    { lat: 49.0057275, lng: 18.9592527 },
    { lat: 49.0057399, lng: 18.9592431 },
    { lat: 49.0058198, lng: 18.9590038 },
    { lat: 49.005845, lng: 18.9589689 },
    { lat: 49.0058992, lng: 18.9589895 },
    { lat: 49.0059225, lng: 18.9589846 },
    { lat: 49.0059461, lng: 18.9589592 },
    { lat: 49.0061654, lng: 18.9586384 },
    { lat: 49.0062433, lng: 18.9585485 },
    { lat: 49.0062646, lng: 18.9585485 },
    { lat: 49.0064641, lng: 18.9586584 },
    { lat: 49.0064843, lng: 18.9586561 },
    { lat: 49.0065002, lng: 18.9586352 },
    { lat: 49.0065435, lng: 18.9585324 },
    { lat: 49.0066333, lng: 18.9582591 },
    { lat: 49.0066647, lng: 18.9580981 },
    { lat: 49.0066939, lng: 18.9578311 },
    { lat: 49.0067092, lng: 18.9577963 },
    { lat: 49.0068402, lng: 18.9576912 },
    { lat: 49.0069397, lng: 18.9576321 },
    { lat: 49.0070225, lng: 18.957616 },
    { lat: 49.0072025, lng: 18.9576097 },
    { lat: 49.0072041, lng: 18.9575955 },
    { lat: 49.0078531, lng: 18.9557427 },
    { lat: 49.0084163, lng: 18.9540794 },
    { lat: 49.0087533, lng: 18.9522759 },
    { lat: 49.0089332, lng: 18.951955 },
    { lat: 49.0090479, lng: 18.9516134 },
    { lat: 49.0091411, lng: 18.9512272 },
    { lat: 49.0095722, lng: 18.9496769 },
    { lat: 49.0098915, lng: 18.9488351 },
    { lat: 49.0099283, lng: 18.9488293 },
    { lat: 49.0100201, lng: 18.9484138 },
    { lat: 49.0100591, lng: 18.9484035 },
    { lat: 49.0104126, lng: 18.946792 },
    { lat: 49.0106107, lng: 18.9461891 },
    { lat: 49.0111979, lng: 18.944424 },
    { lat: 49.0114122, lng: 18.9443411 },
    { lat: 49.0115949, lng: 18.9442303 },
    { lat: 49.0117846, lng: 18.9440583 },
    { lat: 49.0119899, lng: 18.9438349 },
    { lat: 49.0121291, lng: 18.943644 },
    { lat: 49.0122605, lng: 18.943435 },
    { lat: 49.0123083, lng: 18.9432883 },
    { lat: 49.0125794, lng: 18.9426097 },
    { lat: 49.0129985, lng: 18.9415136 },
    { lat: 49.0131555, lng: 18.9410326 },
    { lat: 49.0132417, lng: 18.9407294 },
    { lat: 49.0133175, lng: 18.9403549 },
    { lat: 49.0133985, lng: 18.9400666 },
    { lat: 49.0135345, lng: 18.9397009 },
    { lat: 49.0137338, lng: 18.9392529 },
    { lat: 49.0138972, lng: 18.9390035 },
    { lat: 49.0140644, lng: 18.9388352 },
    { lat: 49.014161, lng: 18.9387833 },
    { lat: 49.0143456, lng: 18.9387976 },
    { lat: 49.014436, lng: 18.9388477 },
    { lat: 49.0146127, lng: 18.9387307 },
    { lat: 49.0153484, lng: 18.9380758 },
    { lat: 49.0157862, lng: 18.9378154 },
    { lat: 49.0162036, lng: 18.9373351 },
    { lat: 49.0170601, lng: 18.9365252 },
    { lat: 49.0182995, lng: 18.9366646 },
    { lat: 49.0192553, lng: 18.9357284 },
    { lat: 49.0199553, lng: 18.9343505 },
    { lat: 49.0206389, lng: 18.932934 },
    { lat: 49.0199398, lng: 18.9320674 },
    { lat: 49.0192564, lng: 18.9312884 },
    { lat: 49.0185341, lng: 18.9304511 },
    { lat: 49.0185232, lng: 18.9303708 },
    { lat: 49.0183767, lng: 18.9303514 },
    { lat: 49.0182884, lng: 18.9304088 },
    { lat: 49.0181616, lng: 18.9308182 },
    { lat: 49.0180619, lng: 18.9310095 },
    { lat: 49.0180077, lng: 18.9310083 },
    { lat: 49.017627, lng: 18.9307509 },
    { lat: 49.0174986, lng: 18.9308644 },
    { lat: 49.0174184, lng: 18.9310807 },
    { lat: 49.0174001, lng: 18.9312973 },
    { lat: 49.0172786, lng: 18.9315465 },
    { lat: 49.0171215, lng: 18.9316501 },
    { lat: 49.0169304, lng: 18.9319527 },
    { lat: 49.0168394, lng: 18.932138 },
    { lat: 49.0167115, lng: 18.9323452 },
    { lat: 49.0164792, lng: 18.9323915 },
    { lat: 49.0164296, lng: 18.932615 },
    { lat: 49.0163378, lng: 18.9329372 },
    { lat: 49.0161506, lng: 18.9330245 },
    { lat: 49.0159329, lng: 18.9332451 },
    { lat: 49.0157161, lng: 18.9333729 },
    { lat: 49.0153518, lng: 18.933438 },
    { lat: 49.0150627, lng: 18.9334256 },
    { lat: 49.0147632, lng: 18.9335236 },
    { lat: 49.0145759, lng: 18.9336092 },
    { lat: 49.0143861, lng: 18.9336681 },
    { lat: 49.0143225, lng: 18.9336393 },
    { lat: 49.0143174, lng: 18.9336269 },
    { lat: 49.0141912, lng: 18.9333846 },
    { lat: 49.0140668, lng: 18.9328045 },
    { lat: 49.0140569, lng: 18.9327585 },
    { lat: 49.0140604, lng: 18.9326188 },
    { lat: 49.0140625, lng: 18.9325341 },
    { lat: 49.0142011, lng: 18.9322032 },
    { lat: 49.0144382, lng: 18.9318517 },
    { lat: 49.0148674, lng: 18.93138 },
    { lat: 49.0150987, lng: 18.9312734 },
    { lat: 49.0151957, lng: 18.9311834 },
    { lat: 49.014893, lng: 18.9305314 },
    { lat: 49.0152006, lng: 18.9302148 },
    { lat: 49.0153843, lng: 18.9300366 },
    { lat: 49.0152414, lng: 18.9299765 },
    { lat: 49.0152252, lng: 18.9299478 },
    { lat: 49.0145011, lng: 18.9289849 },
    { lat: 49.0144741, lng: 18.928899 },
    { lat: 49.0138972, lng: 18.9283044 },
    { lat: 49.0129266, lng: 18.9271107 },
    { lat: 49.0121191, lng: 18.9261759 },
    { lat: 49.0117442, lng: 18.9257642 },
    { lat: 49.0106703, lng: 18.9245766 },
    { lat: 49.0108245, lng: 18.9233546 },
    { lat: 49.0108044, lng: 18.9226943 },
    { lat: 49.0104031, lng: 18.9224513 },
    { lat: 49.0107236, lng: 18.9199753 },
    { lat: 49.009767, lng: 18.919502 },
    { lat: 49.0097055, lng: 18.9194676 },
    { lat: 49.0097323, lng: 18.9190205 },
    { lat: 49.0098688, lng: 18.9176031 },
    { lat: 49.0099004, lng: 18.9170287 },
    { lat: 49.0099661, lng: 18.9151318 },
    { lat: 49.0091372, lng: 18.9148839 },
    { lat: 49.0090993, lng: 18.9145849 },
    { lat: 49.0088069, lng: 18.914555 },
    { lat: 49.0084006, lng: 18.9145001 },
    { lat: 49.0075881, lng: 18.9143983 },
    { lat: 49.007574, lng: 18.9144082 },
    { lat: 49.0072615, lng: 18.9143646 },
    { lat: 49.0071803, lng: 18.9143375 },
    { lat: 49.007038, lng: 18.9142476 },
    { lat: 49.0069173, lng: 18.9141332 },
    { lat: 49.0066707, lng: 18.9138513 },
    { lat: 49.0066005, lng: 18.9138046 },
    { lat: 49.0064849, lng: 18.9137467 },
    { lat: 49.0064093, lng: 18.9137481 },
    { lat: 49.0066748, lng: 18.9149045 },
    { lat: 49.0069679, lng: 18.9161873 },
    { lat: 49.0067095, lng: 18.9163412 },
    { lat: 49.0062256, lng: 18.9145013 },
    { lat: 49.0060522, lng: 18.913797 },
    { lat: 49.0059191, lng: 18.9133734 },
    { lat: 49.0058171, lng: 18.9131193 },
    { lat: 49.0053672, lng: 18.9126548 },
    { lat: 49.0053509, lng: 18.9121816 },
    { lat: 49.0053242, lng: 18.9117467 },
    { lat: 49.0053335, lng: 18.9111782 },
    { lat: 49.0051137, lng: 18.9110015 },
    { lat: 49.0047651, lng: 18.9107118 },
    { lat: 49.0038558, lng: 18.9100131 },
    { lat: 49.0032388, lng: 18.909391 },
    { lat: 49.0022841, lng: 18.908558 },
    { lat: 49.0018261, lng: 18.9080647 },
    { lat: 49.0024239, lng: 18.9064364 },
    { lat: 49.0018219, lng: 18.9055985 },
    { lat: 49.0014037, lng: 18.9050252 },
    { lat: 49.0004479, lng: 18.9039716 },
    { lat: 49.0000905, lng: 18.9035526 },
    { lat: 49.0000572, lng: 18.9035203 },
    { lat: 48.9999992, lng: 18.9036611 },
    { lat: 48.9996021, lng: 18.9049396 },
    { lat: 48.999143, lng: 18.9057948 },
    { lat: 48.9988201, lng: 18.9063378 },
    { lat: 48.9985371, lng: 18.9069133 },
    { lat: 48.9985073, lng: 18.9073707 },
    { lat: 48.998624, lng: 18.907953 },
    { lat: 48.9986894, lng: 18.908461 },
    { lat: 48.9985637, lng: 18.9089806 },
    { lat: 48.9972978, lng: 18.9110188 },
    { lat: 48.9968339, lng: 18.9117202 },
    { lat: 48.9966954, lng: 18.9120115 },
    { lat: 48.9964451, lng: 18.9123616 },
    { lat: 48.9962197, lng: 18.9130507 },
    { lat: 48.9961575, lng: 18.9135556 },
    { lat: 48.9960702, lng: 18.9148926 },
    { lat: 48.9960078, lng: 18.9160127 },
    { lat: 48.9959477, lng: 18.9169149 },
    { lat: 48.9958673, lng: 18.9174159 },
    { lat: 48.9957391, lng: 18.9179552 },
    { lat: 48.9954461, lng: 18.9192201 },
    { lat: 48.9951236, lng: 18.9207879 },
  ],
  Nolčovo: [
    { lat: 49.1143302, lng: 19.0843212 },
    { lat: 49.1134778, lng: 19.0814646 },
    { lat: 49.1136558, lng: 19.0785673 },
    { lat: 49.1130638, lng: 19.0783783 },
    { lat: 49.1131079, lng: 19.0781109 },
    { lat: 49.1132351, lng: 19.0773532 },
    { lat: 49.1136854, lng: 19.0753577 },
    { lat: 49.1138877, lng: 19.0726098 },
    { lat: 49.1138044, lng: 19.0710648 },
    { lat: 49.1137931, lng: 19.0708376 },
    { lat: 49.1138441, lng: 19.0696569 },
    { lat: 49.113171, lng: 19.0692462 },
    { lat: 49.1130082, lng: 19.0689195 },
    { lat: 49.1124702, lng: 19.0678446 },
    { lat: 49.1122738, lng: 19.0674519 },
    { lat: 49.1109143, lng: 19.0655863 },
    { lat: 49.1105369, lng: 19.0650684 },
    { lat: 49.1105462, lng: 19.0646841 },
    { lat: 49.109215, lng: 19.0640719 },
    { lat: 49.1089194, lng: 19.063569 },
    { lat: 49.1085831, lng: 19.0628331 },
    { lat: 49.1080658, lng: 19.0613796 },
    { lat: 49.1075992, lng: 19.0600642 },
    { lat: 49.1072293, lng: 19.0577451 },
    { lat: 49.107087, lng: 19.0564885 },
    { lat: 49.1069896, lng: 19.0556394 },
    { lat: 49.1069154, lng: 19.0549863 },
    { lat: 49.1067822, lng: 19.0550718 },
    { lat: 49.1066118, lng: 19.055181 },
    { lat: 49.1064456, lng: 19.0552872 },
    { lat: 49.1054596, lng: 19.0562009 },
    { lat: 49.1035692, lng: 19.0578642 },
    { lat: 49.1029135, lng: 19.0578922 },
    { lat: 49.1018933, lng: 19.0592975 },
    { lat: 49.1014096, lng: 19.0592766 },
    { lat: 49.1008449, lng: 19.0601395 },
    { lat: 49.1002776, lng: 19.0602713 },
    { lat: 49.1007209, lng: 19.0611175 },
    { lat: 49.1011686, lng: 19.0621899 },
    { lat: 49.1008155, lng: 19.0628425 },
    { lat: 49.1006377, lng: 19.0636454 },
    { lat: 49.1005668, lng: 19.0643298 },
    { lat: 49.1001408, lng: 19.0642619 },
    { lat: 49.1001506, lng: 19.0643504 },
    { lat: 49.1006216, lng: 19.0649596 },
    { lat: 49.1010669, lng: 19.0662089 },
    { lat: 49.1017943, lng: 19.0679223 },
    { lat: 49.1016119, lng: 19.0681778 },
    { lat: 49.1022514, lng: 19.0697063 },
    { lat: 49.1023201, lng: 19.0706403 },
    { lat: 49.1022879, lng: 19.0726094 },
    { lat: 49.1023256, lng: 19.0736181 },
    { lat: 49.1023419, lng: 19.0740861 },
    { lat: 49.1023424, lng: 19.0742353 },
    { lat: 49.1021614, lng: 19.0757894 },
    { lat: 49.1017632, lng: 19.076742 },
    { lat: 49.1007653, lng: 19.0783772 },
    { lat: 49.100469, lng: 19.0788626 },
    { lat: 49.1003542, lng: 19.0790503 },
    { lat: 49.1002469, lng: 19.0792259 },
    { lat: 49.0995269, lng: 19.0816471 },
    { lat: 49.0988143, lng: 19.0818058 },
    { lat: 49.0980766, lng: 19.0821371 },
    { lat: 49.097955, lng: 19.0817985 },
    { lat: 49.0945622, lng: 19.08251 },
    { lat: 49.0936893, lng: 19.0828435 },
    { lat: 49.0932876, lng: 19.0827927 },
    { lat: 49.0928772, lng: 19.0825844 },
    { lat: 49.0924284, lng: 19.0824901 },
    { lat: 49.0918546, lng: 19.0824663 },
    { lat: 49.0914441, lng: 19.0826249 },
    { lat: 49.0908743, lng: 19.082843 },
    { lat: 49.0905681, lng: 19.0828954 },
    { lat: 49.0902316, lng: 19.082839 },
    { lat: 49.0900985, lng: 19.0829715 },
    { lat: 49.0900148, lng: 19.0831034 },
    { lat: 49.0898426, lng: 19.0831317 },
    { lat: 49.0897709, lng: 19.0829134 },
    { lat: 49.0893963, lng: 19.0829048 },
    { lat: 49.0890952, lng: 19.0827681 },
    { lat: 49.0888005, lng: 19.0826886 },
    { lat: 49.0884955, lng: 19.0826973 },
    { lat: 49.0880435, lng: 19.0824225 },
    { lat: 49.0876556, lng: 19.0822641 },
    { lat: 49.0861706, lng: 19.0828259 },
    { lat: 49.085463, lng: 19.0830807 },
    { lat: 49.0841652, lng: 19.0830521 },
    { lat: 49.0840688, lng: 19.0830175 },
    { lat: 49.083841, lng: 19.0828774 },
    { lat: 49.0834815, lng: 19.0827693 },
    { lat: 49.0830019, lng: 19.0824366 },
    { lat: 49.0822759, lng: 19.0827359 },
    { lat: 49.0817255, lng: 19.0827504 },
    { lat: 49.0814944, lng: 19.082911 },
    { lat: 49.0803274, lng: 19.0837517 },
    { lat: 49.079891, lng: 19.0842274 },
    { lat: 49.0793435, lng: 19.0835008 },
    { lat: 49.0791031, lng: 19.0834252 },
    { lat: 49.07886, lng: 19.0832542 },
    { lat: 49.0785694, lng: 19.0834863 },
    { lat: 49.0782727, lng: 19.0835958 },
    { lat: 49.0781981, lng: 19.0838172 },
    { lat: 49.0779948, lng: 19.0841289 },
    { lat: 49.0776786, lng: 19.0843949 },
    { lat: 49.0774313, lng: 19.0845433 },
    { lat: 49.0771322, lng: 19.0846944 },
    { lat: 49.0771746, lng: 19.0849161 },
    { lat: 49.0773143, lng: 19.0849138 },
    { lat: 49.0772179, lng: 19.0854257 },
    { lat: 49.0770818, lng: 19.0855923 },
    { lat: 49.0770351, lng: 19.085831 },
    { lat: 49.076937, lng: 19.0859454 },
    { lat: 49.0769319, lng: 19.0859679 },
    { lat: 49.0767703, lng: 19.0866783 },
    { lat: 49.0764364, lng: 19.0866751 },
    { lat: 49.0757856, lng: 19.0870998 },
    { lat: 49.0756208, lng: 19.0875991 },
    { lat: 49.0753442, lng: 19.0882071 },
    { lat: 49.0752829, lng: 19.0884496 },
    { lat: 49.0751405, lng: 19.0890139 },
    { lat: 49.0750727, lng: 19.0892816 },
    { lat: 49.0745779, lng: 19.0898531 },
    { lat: 49.0741339, lng: 19.0904529 },
    { lat: 49.0730756, lng: 19.091576 },
    { lat: 49.0726255, lng: 19.0928697 },
    { lat: 49.0724015, lng: 19.0931697 },
    { lat: 49.0715283, lng: 19.0936607 },
    { lat: 49.0708408, lng: 19.0938011 },
    { lat: 49.0701232, lng: 19.0937871 },
    { lat: 49.0697471, lng: 19.0940221 },
    { lat: 49.0695847, lng: 19.0942053 },
    { lat: 49.0692058, lng: 19.0950241 },
    { lat: 49.0690024, lng: 19.0953771 },
    { lat: 49.0688599, lng: 19.0956881 },
    { lat: 49.0688338, lng: 19.0961649 },
    { lat: 49.0685567, lng: 19.0974042 },
    { lat: 49.0684595, lng: 19.0976281 },
    { lat: 49.068095, lng: 19.0981914 },
    { lat: 49.0679138, lng: 19.0985414 },
    { lat: 49.0676934, lng: 19.0991019 },
    { lat: 49.0671705, lng: 19.1001085 },
    { lat: 49.0667687, lng: 19.1000994 },
    { lat: 49.0662838, lng: 19.1002303 },
    { lat: 49.0657682, lng: 19.1002701 },
    { lat: 49.0656478, lng: 19.1002274 },
    { lat: 49.0650196, lng: 19.1001963 },
    { lat: 49.0647661, lng: 19.0999028 },
    { lat: 49.0645023, lng: 19.0994936 },
    { lat: 49.0640244, lng: 19.0992189 },
    { lat: 49.0639231, lng: 19.0994501 },
    { lat: 49.0637517, lng: 19.0996343 },
    { lat: 49.0636104, lng: 19.0998769 },
    { lat: 49.0633979, lng: 19.1003196 },
    { lat: 49.0634087, lng: 19.100538 },
    { lat: 49.0633007, lng: 19.1007286 },
    { lat: 49.0632157, lng: 19.101013 },
    { lat: 49.0631582, lng: 19.1014038 },
    { lat: 49.0628988, lng: 19.1019068 },
    { lat: 49.0626848, lng: 19.1021372 },
    { lat: 49.0622381, lng: 19.1029564 },
    { lat: 49.0617227, lng: 19.1035645 },
    { lat: 49.0616343, lng: 19.1036019 },
    { lat: 49.0613472, lng: 19.1047324 },
    { lat: 49.0609755, lng: 19.1054233 },
    { lat: 49.0608555, lng: 19.1060099 },
    { lat: 49.0607312, lng: 19.1063192 },
    { lat: 49.0605874, lng: 19.1069736 },
    { lat: 49.0606291, lng: 19.1073599 },
    { lat: 49.0604765, lng: 19.1079262 },
    { lat: 49.0603574, lng: 19.1088934 },
    { lat: 49.0594255, lng: 19.1098436 },
    { lat: 49.0587086, lng: 19.1112153 },
    { lat: 49.0585877, lng: 19.1114142 },
    { lat: 49.0584423, lng: 19.1115749 },
    { lat: 49.058012, lng: 19.1117199 },
    { lat: 49.0577519, lng: 19.111839 },
    { lat: 49.0575204, lng: 19.1117622 },
    { lat: 49.05664, lng: 19.1121508 },
    { lat: 49.0564597, lng: 19.1123359 },
    { lat: 49.056323, lng: 19.1126739 },
    { lat: 49.0561214, lng: 19.1128823 },
    { lat: 49.0554195, lng: 19.1133738 },
    { lat: 49.0546134, lng: 19.114083 },
    { lat: 49.054212, lng: 19.114307 },
    { lat: 49.053414, lng: 19.114552 },
    { lat: 49.053607, lng: 19.115355 },
    { lat: 49.053888, lng: 19.117147 },
    { lat: 49.054164, lng: 19.119094 },
    { lat: 49.05465, lng: 19.119805 },
    { lat: 49.054745, lng: 19.119942 },
    { lat: 49.055206, lng: 19.120518 },
    { lat: 49.055682, lng: 19.121545 },
    { lat: 49.056147, lng: 19.122213 },
    { lat: 49.05617, lng: 19.122476 },
    { lat: 49.057189, lng: 19.122916 },
    { lat: 49.057809, lng: 19.123456 },
    { lat: 49.058637, lng: 19.122771 },
    { lat: 49.059126, lng: 19.122459 },
    { lat: 49.059532, lng: 19.122162 },
    { lat: 49.059856, lng: 19.122079 },
    { lat: 49.060003, lng: 19.121859 },
    { lat: 49.06016, lng: 19.121722 },
    { lat: 49.06031, lng: 19.121807 },
    { lat: 49.060433, lng: 19.121744 },
    { lat: 49.060578, lng: 19.121813 },
    { lat: 49.061074, lng: 19.121741 },
    { lat: 49.061484, lng: 19.121761 },
    { lat: 49.061865, lng: 19.121999 },
    { lat: 49.062415, lng: 19.121639 },
    { lat: 49.063056, lng: 19.121571 },
    { lat: 49.06317, lng: 19.121528 },
    { lat: 49.06364, lng: 19.121349 },
    { lat: 49.063929, lng: 19.12124 },
    { lat: 49.063938, lng: 19.121237 },
    { lat: 49.063985, lng: 19.121219 },
    { lat: 49.064014, lng: 19.121208 },
    { lat: 49.06403, lng: 19.121202 },
    { lat: 49.064073, lng: 19.121186 },
    { lat: 49.064585, lng: 19.12103 },
    { lat: 49.064713, lng: 19.120793 },
    { lat: 49.064785, lng: 19.120338 },
    { lat: 49.065188, lng: 19.120416 },
    { lat: 49.065486, lng: 19.119845 },
    { lat: 49.065588, lng: 19.119564 },
    { lat: 49.065803, lng: 19.119415 },
    { lat: 49.066195, lng: 19.119624 },
    { lat: 49.066572, lng: 19.119728 },
    { lat: 49.066715, lng: 19.120126 },
    { lat: 49.066847, lng: 19.12057 },
    { lat: 49.066991, lng: 19.120568 },
    { lat: 49.067308, lng: 19.120777 },
    { lat: 49.067309, lng: 19.120956 },
    { lat: 49.067597, lng: 19.120923 },
    { lat: 49.067662, lng: 19.120925 },
    { lat: 49.068156, lng: 19.120935 },
    { lat: 49.068573, lng: 19.120894 },
    { lat: 49.068636, lng: 19.120887 },
    { lat: 49.068681, lng: 19.120883 },
    { lat: 49.068726, lng: 19.120879 },
    { lat: 49.068748, lng: 19.120877 },
    { lat: 49.0689928, lng: 19.1208393 },
    { lat: 49.069144, lng: 19.120816 },
    { lat: 49.069551, lng: 19.120646 },
    { lat: 49.070086, lng: 19.120292 },
    { lat: 49.070471, lng: 19.119957 },
    { lat: 49.070584, lng: 19.119782 },
    { lat: 49.070765, lng: 19.119371 },
    { lat: 49.071089, lng: 19.119506 },
    { lat: 49.071691, lng: 19.119566 },
    { lat: 49.071822, lng: 19.119593 },
    { lat: 49.072023, lng: 19.119635 },
    { lat: 49.072224, lng: 19.119608 },
    { lat: 49.072576, lng: 19.119444 },
    { lat: 49.072699, lng: 19.119268 },
    { lat: 49.072934, lng: 19.119652 },
    { lat: 49.073259, lng: 19.119999 },
    { lat: 49.0736, lng: 19.120577 },
    { lat: 49.073828, lng: 19.121033 },
    { lat: 49.074397, lng: 19.121663 },
    { lat: 49.075184, lng: 19.121157 },
    { lat: 49.076017, lng: 19.120992 },
    { lat: 49.076646, lng: 19.120758 },
    { lat: 49.076774, lng: 19.120767 },
    { lat: 49.07697, lng: 19.12095 },
    { lat: 49.077136, lng: 19.121187 },
    { lat: 49.077823, lng: 19.121418 },
    { lat: 49.078009, lng: 19.121555 },
    { lat: 49.078142, lng: 19.121745 },
    { lat: 49.078288, lng: 19.121864 },
    { lat: 49.078592, lng: 19.122056 },
    { lat: 49.078781, lng: 19.121723 },
    { lat: 49.079003, lng: 19.121534 },
    { lat: 49.079403, lng: 19.121507 },
    { lat: 49.080296, lng: 19.121994 },
    { lat: 49.080683, lng: 19.122258 },
    { lat: 49.081063, lng: 19.122489 },
    { lat: 49.081119, lng: 19.122542 },
    { lat: 49.081196, lng: 19.122614 },
    { lat: 49.08132, lng: 19.122671 },
    { lat: 49.081768, lng: 19.122661 },
    { lat: 49.081948, lng: 19.122529 },
    { lat: 49.082204, lng: 19.122423 },
    { lat: 49.082545, lng: 19.122344 },
    { lat: 49.082564, lng: 19.122032 },
    { lat: 49.082726, lng: 19.121602 },
    { lat: 49.082833, lng: 19.120937 },
    { lat: 49.08286, lng: 19.12077 },
    { lat: 49.083236, lng: 19.120427 },
    { lat: 49.083598, lng: 19.120282 },
    { lat: 49.083989, lng: 19.12005 },
    { lat: 49.084174, lng: 19.119889 },
    { lat: 49.084636, lng: 19.119647 },
    { lat: 49.085073, lng: 19.119374 },
    { lat: 49.085406, lng: 19.119165 },
    { lat: 49.085747, lng: 19.119006 },
    { lat: 49.086072, lng: 19.1189 },
    { lat: 49.086424, lng: 19.118765 },
    { lat: 49.086555, lng: 19.118715 },
    { lat: 49.087037, lng: 19.118425 },
    { lat: 49.087387, lng: 19.118399 },
    { lat: 49.087892, lng: 19.11799 },
    { lat: 49.088478, lng: 19.117447 },
    { lat: 49.08877, lng: 19.117281 },
    { lat: 49.089046, lng: 19.117196 },
    { lat: 49.089101, lng: 19.117178 },
    { lat: 49.089593, lng: 19.117114 },
    { lat: 49.089958, lng: 19.117047 },
    { lat: 49.090112, lng: 19.116989 },
    { lat: 49.090326, lng: 19.116886 },
    { lat: 49.090558, lng: 19.116728 },
    { lat: 49.090997, lng: 19.116348 },
    { lat: 49.09124, lng: 19.116196 },
    { lat: 49.091479, lng: 19.11615 },
    { lat: 49.0920453, lng: 19.1161538 },
    { lat: 49.0922274, lng: 19.1160396 },
    { lat: 49.0926094, lng: 19.1154889 },
    { lat: 49.0926875, lng: 19.1153789 },
    { lat: 49.0928684, lng: 19.1150906 },
    { lat: 49.0929194, lng: 19.115031 },
    { lat: 49.0936252, lng: 19.1142063 },
    { lat: 49.0942323, lng: 19.1139411 },
    { lat: 49.0946264, lng: 19.1136606 },
    { lat: 49.095278, lng: 19.1137733 },
    { lat: 49.0956184, lng: 19.1136148 },
    { lat: 49.0961827, lng: 19.1134227 },
    { lat: 49.096338, lng: 19.1133245 },
    { lat: 49.096566, lng: 19.1130075 },
    { lat: 49.0967646, lng: 19.1125126 },
    { lat: 49.0969915, lng: 19.1123799 },
    { lat: 49.097496, lng: 19.1122306 },
    { lat: 49.097872, lng: 19.1121054 },
    { lat: 49.0982777, lng: 19.1116472 },
    { lat: 49.0986203, lng: 19.1110039 },
    { lat: 49.098648, lng: 19.1110838 },
    { lat: 49.0987742, lng: 19.111314 },
    { lat: 49.0989777, lng: 19.1115761 },
    { lat: 49.0990005, lng: 19.111699 },
    { lat: 49.09909, lng: 19.1117273 },
    { lat: 49.0991098, lng: 19.1115581 },
    { lat: 49.0990106, lng: 19.1112615 },
    { lat: 49.0988888, lng: 19.111166 },
    { lat: 49.0988341, lng: 19.1108557 },
    { lat: 49.0989205, lng: 19.1107595 },
    { lat: 49.0991795, lng: 19.1108422 },
    { lat: 49.0993808, lng: 19.1108578 },
    { lat: 49.0995598, lng: 19.1108525 },
    { lat: 49.0995714, lng: 19.110609 },
    { lat: 49.0995448, lng: 19.1104556 },
    { lat: 49.0998089, lng: 19.1101873 },
    { lat: 49.1001759, lng: 19.1103153 },
    { lat: 49.1003851, lng: 19.1104198 },
    { lat: 49.1007171, lng: 19.11025 },
    { lat: 49.1013175, lng: 19.1100815 },
    { lat: 49.1014769, lng: 19.1101816 },
    { lat: 49.1018263, lng: 19.109999 },
    { lat: 49.1021518, lng: 19.1099701 },
    { lat: 49.1023206, lng: 19.1099149 },
    { lat: 49.1026166, lng: 19.1096566 },
    { lat: 49.1040882, lng: 19.1084512 },
    { lat: 49.1043378, lng: 19.108258 },
    { lat: 49.1046858, lng: 19.1080193 },
    { lat: 49.1049955, lng: 19.107717 },
    { lat: 49.1057094, lng: 19.1070617 },
    { lat: 49.1062776, lng: 19.1061467 },
    { lat: 49.1065689, lng: 19.1057868 },
    { lat: 49.1072226, lng: 19.1052383 },
    { lat: 49.1078372, lng: 19.1048059 },
    { lat: 49.10848, lng: 19.10449 },
    { lat: 49.1091918, lng: 19.104046 },
    { lat: 49.1096177, lng: 19.1038585 },
    { lat: 49.109976, lng: 19.1037143 },
    { lat: 49.1109214, lng: 19.1031357 },
    { lat: 49.1114306, lng: 19.1030376 },
    { lat: 49.1116639, lng: 19.1029455 },
    { lat: 49.11244, lng: 19.1019972 },
    { lat: 49.113677, lng: 19.1011582 },
    { lat: 49.1143514, lng: 19.1010052 },
    { lat: 49.1143306, lng: 19.1009036 },
    { lat: 49.1142218, lng: 19.1004551 },
    { lat: 49.1143107, lng: 19.1001768 },
    { lat: 49.1143083, lng: 19.09951 },
    { lat: 49.1140746, lng: 19.0990009 },
    { lat: 49.1136346, lng: 19.0966569 },
    { lat: 49.1129752, lng: 19.0947611 },
    { lat: 49.1120942, lng: 19.0926882 },
    { lat: 49.1122699, lng: 19.0923439 },
    { lat: 49.1125888, lng: 19.0922045 },
    { lat: 49.1126783, lng: 19.090136 },
    { lat: 49.1134997, lng: 19.0893518 },
    { lat: 49.1134515, lng: 19.0890591 },
    { lat: 49.1137779, lng: 19.0876572 },
    { lat: 49.1140454, lng: 19.0860411 },
    { lat: 49.1142201, lng: 19.0849912 },
    { lat: 49.1143302, lng: 19.0843212 },
  ],
  Príbovce: [
    { lat: 48.9827178, lng: 18.906022 },
    { lat: 48.9834104, lng: 18.9069541 },
    { lat: 48.9843641, lng: 18.90841 },
    { lat: 48.9845634, lng: 18.9086895 },
    { lat: 48.9848959, lng: 18.9090847 },
    { lat: 48.9849112, lng: 18.9091199 },
    { lat: 48.9854544, lng: 18.9097128 },
    { lat: 48.98556, lng: 18.9098156 },
    { lat: 48.9858725, lng: 18.9100168 },
    { lat: 48.9861384, lng: 18.9101269 },
    { lat: 48.9863994, lng: 18.9101116 },
    { lat: 48.9864992, lng: 18.9102327 },
    { lat: 48.9869517, lng: 18.9103721 },
    { lat: 48.9870793, lng: 18.9104016 },
    { lat: 48.9873185, lng: 18.9105142 },
    { lat: 48.9875764, lng: 18.9106414 },
    { lat: 48.9879948, lng: 18.9108865 },
    { lat: 48.9883818, lng: 18.9110434 },
    { lat: 48.988616, lng: 18.9110815 },
    { lat: 48.9888098, lng: 18.9111258 },
    { lat: 48.9889757, lng: 18.9111953 },
    { lat: 48.9891024, lng: 18.9113825 },
    { lat: 48.9891276, lng: 18.9113903 },
    { lat: 48.9894587, lng: 18.9123558 },
    { lat: 48.9896686, lng: 18.9130667 },
    { lat: 48.9897354, lng: 18.9133557 },
    { lat: 48.9899192, lng: 18.9138658 },
    { lat: 48.9903674, lng: 18.9156041 },
    { lat: 48.9904993, lng: 18.9156471 },
    { lat: 48.9915193, lng: 18.9159019 },
    { lat: 48.991663, lng: 18.9159816 },
    { lat: 48.9917428, lng: 18.9160655 },
    { lat: 48.9920691, lng: 18.9162501 },
    { lat: 48.9929839, lng: 18.9166766 },
    { lat: 48.9930779, lng: 18.9171959 },
    { lat: 48.9931689, lng: 18.9178314 },
    { lat: 48.9932005, lng: 18.9178995 },
    { lat: 48.9932483, lng: 18.918194 },
    { lat: 48.9933829, lng: 18.9191329 },
    { lat: 48.9935013, lng: 18.9202851 },
    { lat: 48.9937753, lng: 18.9203947 },
    { lat: 48.9939033, lng: 18.9204073 },
    { lat: 48.9942693, lng: 18.9205111 },
    { lat: 48.9944649, lng: 18.920608 },
    { lat: 48.9947111, lng: 18.9207514 },
    { lat: 48.9948777, lng: 18.9208115 },
    { lat: 48.9951003, lng: 18.9207767 },
    { lat: 48.9951236, lng: 18.9207879 },
    { lat: 48.9954461, lng: 18.9192201 },
    { lat: 48.9957391, lng: 18.9179552 },
    { lat: 48.9958673, lng: 18.9174159 },
    { lat: 48.9959477, lng: 18.9169149 },
    { lat: 48.9960078, lng: 18.9160127 },
    { lat: 48.9960702, lng: 18.9148926 },
    { lat: 48.9961575, lng: 18.9135556 },
    { lat: 48.9962197, lng: 18.9130507 },
    { lat: 48.9964451, lng: 18.9123616 },
    { lat: 48.9966954, lng: 18.9120115 },
    { lat: 48.9968339, lng: 18.9117202 },
    { lat: 48.9972978, lng: 18.9110188 },
    { lat: 48.9985637, lng: 18.9089806 },
    { lat: 48.9986894, lng: 18.908461 },
    { lat: 48.998624, lng: 18.907953 },
    { lat: 48.9985073, lng: 18.9073707 },
    { lat: 48.9985371, lng: 18.9069133 },
    { lat: 48.9988201, lng: 18.9063378 },
    { lat: 48.999143, lng: 18.9057948 },
    { lat: 48.9996021, lng: 18.9049396 },
    { lat: 48.9999992, lng: 18.9036611 },
    { lat: 49.0000572, lng: 18.9035203 },
    { lat: 49.0000905, lng: 18.9035526 },
    { lat: 49.0004479, lng: 18.9039716 },
    { lat: 49.0014037, lng: 18.9050252 },
    { lat: 49.0018219, lng: 18.9055985 },
    { lat: 49.0024239, lng: 18.9064364 },
    { lat: 49.0018261, lng: 18.9080647 },
    { lat: 49.0022841, lng: 18.908558 },
    { lat: 49.0032388, lng: 18.909391 },
    { lat: 49.0038558, lng: 18.9100131 },
    { lat: 49.0047651, lng: 18.9107118 },
    { lat: 49.0051137, lng: 18.9110015 },
    { lat: 49.0053015, lng: 18.910417 },
    { lat: 49.0054052, lng: 18.91013 },
    { lat: 49.005498, lng: 18.9099158 },
    { lat: 49.0062434, lng: 18.9083911 },
    { lat: 49.0072052, lng: 18.9064285 },
    { lat: 49.0073327, lng: 18.9060748 },
    { lat: 49.0073836, lng: 18.905881 },
    { lat: 49.0074358, lng: 18.9055567 },
    { lat: 49.0074804, lng: 18.9052135 },
    { lat: 49.007845, lng: 18.9024388 },
    { lat: 49.0079203, lng: 18.9019508 },
    { lat: 49.0080189, lng: 18.9016935 },
    { lat: 49.0081412, lng: 18.9014417 },
    { lat: 49.0082417, lng: 18.9012919 },
    { lat: 49.0083331, lng: 18.9011795 },
    { lat: 49.0084483, lng: 18.9010944 },
    { lat: 49.0103557, lng: 18.8997857 },
    { lat: 49.010899, lng: 18.8994331 },
    { lat: 49.0110763, lng: 18.8993691 },
    { lat: 49.0112616, lng: 18.8993347 },
    { lat: 49.0115098, lng: 18.8993359 },
    { lat: 49.0115992, lng: 18.8993009 },
    { lat: 49.01179, lng: 18.899106 },
    { lat: 49.0120253, lng: 18.8982826 },
    { lat: 49.0120612, lng: 18.897972 },
    { lat: 49.0125159, lng: 18.8965423 },
    { lat: 49.0126121, lng: 18.8961082 },
    { lat: 49.0130531, lng: 18.8947885 },
    { lat: 49.0135878, lng: 18.8931632 },
    { lat: 49.0139228, lng: 18.8920626 },
    { lat: 49.0144949, lng: 18.8903279 },
    { lat: 49.0148688, lng: 18.889177 },
    { lat: 49.0149922, lng: 18.8887895 },
    { lat: 49.0155267, lng: 18.8870937 },
    { lat: 49.0156367, lng: 18.8866888 },
    { lat: 49.0157479, lng: 18.8863746 },
    { lat: 49.015762, lng: 18.8862939 },
    { lat: 49.0161492, lng: 18.885071 },
    { lat: 49.016195, lng: 18.8848206 },
    { lat: 49.0162564, lng: 18.8846779 },
    { lat: 49.0165989, lng: 18.8838593 },
    { lat: 49.0172926, lng: 18.8822357 },
    { lat: 49.0162705, lng: 18.881411 },
    { lat: 49.016129, lng: 18.8802309 },
    { lat: 49.0153346, lng: 18.8796902 },
    { lat: 49.0145869, lng: 18.879467 },
    { lat: 49.0145769, lng: 18.8790965 },
    { lat: 49.013758, lng: 18.8789586 },
    { lat: 49.013092, lng: 18.8797386 },
    { lat: 49.0126063, lng: 18.87915 },
    { lat: 49.0122948, lng: 18.8788773 },
    { lat: 49.0122014, lng: 18.8784875 },
    { lat: 49.012113, lng: 18.8781456 },
    { lat: 49.0117218, lng: 18.8775997 },
    { lat: 49.0110307, lng: 18.8774477 },
    { lat: 49.0108555, lng: 18.8774592 },
    { lat: 49.0106245, lng: 18.8776392 },
    { lat: 49.0102158, lng: 18.8785525 },
    { lat: 49.0102297, lng: 18.8788009 },
    { lat: 49.01032, lng: 18.8790466 },
    { lat: 49.0102086, lng: 18.8797756 },
    { lat: 49.00992, lng: 18.8803037 },
    { lat: 49.0094908, lng: 18.880509 },
    { lat: 49.0094103, lng: 18.8803903 },
    { lat: 49.0092394, lng: 18.8803757 },
    { lat: 49.0091541, lng: 18.8805822 },
    { lat: 49.0090379, lng: 18.8806474 },
    { lat: 49.0089437, lng: 18.8805709 },
    { lat: 49.0089049, lng: 18.8802113 },
    { lat: 49.0087735, lng: 18.8797125 },
    { lat: 49.0085297, lng: 18.8793112 },
    { lat: 49.008288, lng: 18.8789289 },
    { lat: 49.0080243, lng: 18.8788653 },
    { lat: 49.0078901, lng: 18.8790852 },
    { lat: 49.008324, lng: 18.8766005 },
    { lat: 49.0072346, lng: 18.8761769 },
    { lat: 49.0072103, lng: 18.8759008 },
    { lat: 49.0067985, lng: 18.8752798 },
    { lat: 49.006407, lng: 18.874925 },
    { lat: 49.0057591, lng: 18.8742936 },
    { lat: 49.0052285, lng: 18.8744578 },
    { lat: 49.0051731, lng: 18.8745013 },
    { lat: 49.0050401, lng: 18.8739712 },
    { lat: 49.0049834, lng: 18.8737686 },
    { lat: 49.0049103, lng: 18.8736192 },
    { lat: 49.0048859, lng: 18.8736056 },
    { lat: 49.004699, lng: 18.8735631 },
    { lat: 49.0040679, lng: 18.8735726 },
    { lat: 49.0038778, lng: 18.8735606 },
    { lat: 49.0034328, lng: 18.8735671 },
    { lat: 49.0030227, lng: 18.8735916 },
    { lat: 49.0027098, lng: 18.8736043 },
    { lat: 49.0024055, lng: 18.8736099 },
    { lat: 49.0020917, lng: 18.8736227 },
    { lat: 49.0016335, lng: 18.8738788 },
    { lat: 49.0016276, lng: 18.8739154 },
    { lat: 49.0015413, lng: 18.8741041 },
    { lat: 49.0012295, lng: 18.8748148 },
    { lat: 49.0010997, lng: 18.87532 },
    { lat: 49.001009, lng: 18.8754855 },
    { lat: 49.0009078, lng: 18.8755338 },
    { lat: 49.0006542, lng: 18.8755567 },
    { lat: 49.0005608, lng: 18.8757153 },
    { lat: 49.000583, lng: 18.8758854 },
    { lat: 49.0005745, lng: 18.8761816 },
    { lat: 49.0005269, lng: 18.876593 },
    { lat: 49.0004917, lng: 18.877073 },
    { lat: 49.0010482, lng: 18.8770419 },
    { lat: 49.0016426, lng: 18.8769984 },
    { lat: 49.0017056, lng: 18.8778998 },
    { lat: 49.0017215, lng: 18.8779436 },
    { lat: 49.0017626, lng: 18.8780202 },
    { lat: 49.0016773, lng: 18.8781366 },
    { lat: 49.001621, lng: 18.8782489 },
    { lat: 49.0015216, lng: 18.8784862 },
    { lat: 49.0014705, lng: 18.8785917 },
    { lat: 49.001428, lng: 18.8787055 },
    { lat: 49.0013714, lng: 18.8788139 },
    { lat: 49.0012974, lng: 18.8789979 },
    { lat: 49.0011745, lng: 18.8792189 },
    { lat: 49.0010627, lng: 18.8795632 },
    { lat: 49.0009485, lng: 18.8797373 },
    { lat: 49.000829, lng: 18.8797558 },
    { lat: 49.0007419, lng: 18.8797539 },
    { lat: 49.0004408, lng: 18.8796631 },
    { lat: 49.0003565, lng: 18.8796228 },
    { lat: 49.0002837, lng: 18.8795665 },
    { lat: 49.0001382, lng: 18.8793693 },
    { lat: 49.0001121, lng: 18.8792105 },
    { lat: 49.0000471, lng: 18.8789758 },
    { lat: 48.9999933, lng: 18.8788072 },
    { lat: 48.9999204, lng: 18.8786389 },
    { lat: 48.9998859, lng: 18.8785133 },
    { lat: 48.9997389, lng: 18.878223 },
    { lat: 48.9996583, lng: 18.8780987 },
    { lat: 48.9996165, lng: 18.8780342 },
    { lat: 48.9995609, lng: 18.8780038 },
    { lat: 48.9994649, lng: 18.8778461 },
    { lat: 48.9993867, lng: 18.8777957 },
    { lat: 48.999324, lng: 18.8777396 },
    { lat: 48.9992365, lng: 18.8776365 },
    { lat: 48.999163, lng: 18.8775669 },
    { lat: 48.9989941, lng: 18.8774623 },
    { lat: 48.9989072, lng: 18.8773926 },
    { lat: 48.9988481, lng: 18.877383 },
    { lat: 48.9986927, lng: 18.8773246 },
    { lat: 48.9985237, lng: 18.8773062 },
    { lat: 48.9984932, lng: 18.8773242 },
    { lat: 48.9982881, lng: 18.877305 },
    { lat: 48.9980633, lng: 18.8772512 },
    { lat: 48.9979451, lng: 18.8772563 },
    { lat: 48.9978579, lng: 18.8772734 },
    { lat: 48.9977312, lng: 18.8772636 },
    { lat: 48.9976041, lng: 18.877292 },
    { lat: 48.997566, lng: 18.8772876 },
    { lat: 48.9975601, lng: 18.8773631 },
    { lat: 48.997545, lng: 18.87748 },
    { lat: 48.9973889, lng: 18.8779732 },
    { lat: 48.9972543, lng: 18.878011 },
    { lat: 48.9972022, lng: 18.8780969 },
    { lat: 48.9969956, lng: 18.8783121 },
    { lat: 48.9969301, lng: 18.8784401 },
    { lat: 48.9968846, lng: 18.8784731 },
    { lat: 48.9968057, lng: 18.8786553 },
    { lat: 48.9967967, lng: 18.878676 },
    { lat: 48.9967394, lng: 18.8789643 },
    { lat: 48.9966038, lng: 18.8791757 },
    { lat: 48.996456, lng: 18.8794173 },
    { lat: 48.9964218, lng: 18.8794978 },
    { lat: 48.9964218, lng: 18.879529 },
    { lat: 48.996422, lng: 18.8797861 },
    { lat: 48.9964128, lng: 18.8799165 },
    { lat: 48.9963357, lng: 18.8801493 },
    { lat: 48.9960452, lng: 18.8808609 },
    { lat: 48.9959781, lng: 18.8809823 },
    { lat: 48.9958319, lng: 18.8813828 },
    { lat: 48.9958128, lng: 18.8814662 },
    { lat: 48.9957508, lng: 18.8817374 },
    { lat: 48.9954746, lng: 18.8823317 },
    { lat: 48.9953315, lng: 18.8826872 },
    { lat: 48.9952383, lng: 18.8829586 },
    { lat: 48.9950249, lng: 18.8836115 },
    { lat: 48.9945764, lng: 18.8849392 },
    { lat: 48.9944841, lng: 18.8852266 },
    { lat: 48.9941806, lng: 18.8861045 },
    { lat: 48.994039, lng: 18.8865726 },
    { lat: 48.9939701, lng: 18.8867915 },
    { lat: 48.9938026, lng: 18.8872673 },
    { lat: 48.9937261, lng: 18.8875126 },
    { lat: 48.9936488, lng: 18.8877196 },
    { lat: 48.9935764, lng: 18.8879349 },
    { lat: 48.9934471, lng: 18.8883387 },
    { lat: 48.9932784, lng: 18.8888286 },
    { lat: 48.9932084, lng: 18.8890488 },
    { lat: 48.9931919, lng: 18.8891638 },
    { lat: 48.9931994, lng: 18.8892918 },
    { lat: 48.9934157, lng: 18.8898809 },
    { lat: 48.9934311, lng: 18.8900927 },
    { lat: 48.993427, lng: 18.8903693 },
    { lat: 48.9934408, lng: 18.8905932 },
    { lat: 48.9933806, lng: 18.8907601 },
    { lat: 48.9932758, lng: 18.8910015 },
    { lat: 48.9932321, lng: 18.8910763 },
    { lat: 48.9931898, lng: 18.8911962 },
    { lat: 48.9928804, lng: 18.8916734 },
    { lat: 48.9925549, lng: 18.8925789 },
    { lat: 48.9921063, lng: 18.8936189 },
    { lat: 48.9918802, lng: 18.8941131 },
    { lat: 48.9911171, lng: 18.8957959 },
    { lat: 48.9909137, lng: 18.8962033 },
    { lat: 48.9906748, lng: 18.8968031 },
    { lat: 48.990568, lng: 18.8971395 },
    { lat: 48.9904134, lng: 18.8974429 },
    { lat: 48.9901445, lng: 18.8979837 },
    { lat: 48.9901091, lng: 18.8982438 },
    { lat: 48.9896731, lng: 18.8985326 },
    { lat: 48.9895199, lng: 18.8987151 },
    { lat: 48.9896014, lng: 18.8990933 },
    { lat: 48.9890123, lng: 18.9003637 },
    { lat: 48.9889155, lng: 18.900434 },
    { lat: 48.9888816, lng: 18.9006067 },
    { lat: 48.9890458, lng: 18.9008203 },
    { lat: 48.9890722, lng: 18.9010502 },
    { lat: 48.9891395, lng: 18.901397 },
    { lat: 48.9889899, lng: 18.9014929 },
    { lat: 48.988832, lng: 18.9015553 },
    { lat: 48.9885976, lng: 18.9014257 },
    { lat: 48.9882687, lng: 18.9014534 },
    { lat: 48.9881032, lng: 18.9015483 },
    { lat: 48.9878768, lng: 18.9018192 },
    { lat: 48.9877448, lng: 18.9018588 },
    { lat: 48.9874917, lng: 18.9017598 },
    { lat: 48.9870766, lng: 18.9016041 },
    { lat: 48.9869817, lng: 18.9017758 },
    { lat: 48.9870168, lng: 18.9018897 },
    { lat: 48.9871702, lng: 18.901825 },
    { lat: 48.9872484, lng: 18.9020103 },
    { lat: 48.987214, lng: 18.9022395 },
    { lat: 48.9870174, lng: 18.9023639 },
    { lat: 48.9869501, lng: 18.9026371 },
    { lat: 48.9870526, lng: 18.9028993 },
    { lat: 48.9874023, lng: 18.9028997 },
    { lat: 48.9874858, lng: 18.9028845 },
    { lat: 48.9874911, lng: 18.903007 },
    { lat: 48.9874172, lng: 18.9031733 },
    { lat: 48.9873238, lng: 18.9032884 },
    { lat: 48.9871617, lng: 18.9033797 },
    { lat: 48.9866393, lng: 18.9033928 },
    { lat: 48.9864289, lng: 18.9035117 },
    { lat: 48.9863896, lng: 18.9036075 },
    { lat: 48.9862975, lng: 18.9038988 },
    { lat: 48.9859587, lng: 18.9041943 },
    { lat: 48.9858577, lng: 18.9047799 },
    { lat: 48.9857265, lng: 18.9049914 },
    { lat: 48.9854957, lng: 18.9049084 },
    { lat: 48.9854566, lng: 18.9046987 },
    { lat: 48.9852346, lng: 18.9045252 },
    { lat: 48.9848719, lng: 18.9044406 },
    { lat: 48.9846607, lng: 18.904589 },
    { lat: 48.984571, lng: 18.904601 },
    { lat: 48.9844164, lng: 18.9044002 },
    { lat: 48.9837298, lng: 18.904997 },
    { lat: 48.9834557, lng: 18.9047921 },
    { lat: 48.9833628, lng: 18.9049164 },
    { lat: 48.9835107, lng: 18.9055405 },
    { lat: 48.9837758, lng: 18.9053646 },
    { lat: 48.9839279, lng: 18.9054051 },
    { lat: 48.9840548, lng: 18.905906 },
    { lat: 48.9839918, lng: 18.9060423 },
    { lat: 48.983741, lng: 18.9062743 },
    { lat: 48.9835172, lng: 18.9060656 },
    { lat: 48.9832678, lng: 18.905554 },
    { lat: 48.9831497, lng: 18.905625 },
    { lat: 48.9829491, lng: 18.9058454 },
    { lat: 48.9827178, lng: 18.906022 },
  ],
  Rakovo: [
    { lat: 48.9687398, lng: 18.8981618 },
    { lat: 48.9710818, lng: 18.9037223 },
    { lat: 48.9720711, lng: 18.9030257 },
    { lat: 48.9723466, lng: 18.9057269 },
    { lat: 48.9732828, lng: 18.9053994 },
    { lat: 48.9741271, lng: 18.9051341 },
    { lat: 48.9749061, lng: 18.9048657 },
    { lat: 48.9754965, lng: 18.9046378 },
    { lat: 48.9760312, lng: 18.9043752 },
    { lat: 48.9764806, lng: 18.9041266 },
    { lat: 48.9768483, lng: 18.9039049 },
    { lat: 48.977378, lng: 18.9035499 },
    { lat: 48.9779886, lng: 18.9030748 },
    { lat: 48.9781065, lng: 18.9032224 },
    { lat: 48.9784147, lng: 18.9030953 },
    { lat: 48.9826661, lng: 18.9058881 },
    { lat: 48.9827178, lng: 18.906022 },
    { lat: 48.9829491, lng: 18.9058454 },
    { lat: 48.9831497, lng: 18.905625 },
    { lat: 48.9832678, lng: 18.905554 },
    { lat: 48.9835172, lng: 18.9060656 },
    { lat: 48.983741, lng: 18.9062743 },
    { lat: 48.9839918, lng: 18.9060423 },
    { lat: 48.9840548, lng: 18.905906 },
    { lat: 48.9839279, lng: 18.9054051 },
    { lat: 48.9837758, lng: 18.9053646 },
    { lat: 48.9835107, lng: 18.9055405 },
    { lat: 48.9833628, lng: 18.9049164 },
    { lat: 48.9834557, lng: 18.9047921 },
    { lat: 48.9837298, lng: 18.904997 },
    { lat: 48.9844164, lng: 18.9044002 },
    { lat: 48.984571, lng: 18.904601 },
    { lat: 48.9846607, lng: 18.904589 },
    { lat: 48.9848719, lng: 18.9044406 },
    { lat: 48.9852346, lng: 18.9045252 },
    { lat: 48.9854566, lng: 18.9046987 },
    { lat: 48.9854957, lng: 18.9049084 },
    { lat: 48.9857265, lng: 18.9049914 },
    { lat: 48.9858577, lng: 18.9047799 },
    { lat: 48.9859587, lng: 18.9041943 },
    { lat: 48.9862975, lng: 18.9038988 },
    { lat: 48.9863896, lng: 18.9036075 },
    { lat: 48.9864289, lng: 18.9035117 },
    { lat: 48.9866393, lng: 18.9033928 },
    { lat: 48.9871617, lng: 18.9033797 },
    { lat: 48.9873238, lng: 18.9032884 },
    { lat: 48.9874172, lng: 18.9031733 },
    { lat: 48.9874911, lng: 18.903007 },
    { lat: 48.9874858, lng: 18.9028845 },
    { lat: 48.9874023, lng: 18.9028997 },
    { lat: 48.9870526, lng: 18.9028993 },
    { lat: 48.9869501, lng: 18.9026371 },
    { lat: 48.9870174, lng: 18.9023639 },
    { lat: 48.987214, lng: 18.9022395 },
    { lat: 48.9872484, lng: 18.9020103 },
    { lat: 48.9871702, lng: 18.901825 },
    { lat: 48.9870168, lng: 18.9018897 },
    { lat: 48.9869817, lng: 18.9017758 },
    { lat: 48.9870766, lng: 18.9016041 },
    { lat: 48.9874917, lng: 18.9017598 },
    { lat: 48.9877448, lng: 18.9018588 },
    { lat: 48.9878768, lng: 18.9018192 },
    { lat: 48.9881032, lng: 18.9015483 },
    { lat: 48.9882687, lng: 18.9014534 },
    { lat: 48.9885976, lng: 18.9014257 },
    { lat: 48.988832, lng: 18.9015553 },
    { lat: 48.9889899, lng: 18.9014929 },
    { lat: 48.9891395, lng: 18.901397 },
    { lat: 48.9890722, lng: 18.9010502 },
    { lat: 48.9890458, lng: 18.9008203 },
    { lat: 48.9888816, lng: 18.9006067 },
    { lat: 48.9889155, lng: 18.900434 },
    { lat: 48.9890123, lng: 18.9003637 },
    { lat: 48.9896014, lng: 18.8990933 },
    { lat: 48.9895199, lng: 18.8987151 },
    { lat: 48.9896731, lng: 18.8985326 },
    { lat: 48.9901091, lng: 18.8982438 },
    { lat: 48.9901445, lng: 18.8979837 },
    { lat: 48.9904134, lng: 18.8974429 },
    { lat: 48.990568, lng: 18.8971395 },
    { lat: 48.9906748, lng: 18.8968031 },
    { lat: 48.9909137, lng: 18.8962033 },
    { lat: 48.9911171, lng: 18.8957959 },
    { lat: 48.9918802, lng: 18.8941131 },
    { lat: 48.9921063, lng: 18.8936189 },
    { lat: 48.9925549, lng: 18.8925789 },
    { lat: 48.9928804, lng: 18.8916734 },
    { lat: 48.9931898, lng: 18.8911962 },
    { lat: 48.9932321, lng: 18.8910763 },
    { lat: 48.9932758, lng: 18.8910015 },
    { lat: 48.9933806, lng: 18.8907601 },
    { lat: 48.9934408, lng: 18.8905932 },
    { lat: 48.993427, lng: 18.8903693 },
    { lat: 48.9934311, lng: 18.8900927 },
    { lat: 48.9934157, lng: 18.8898809 },
    { lat: 48.9931994, lng: 18.8892918 },
    { lat: 48.9931919, lng: 18.8891638 },
    { lat: 48.9932084, lng: 18.8890488 },
    { lat: 48.9932784, lng: 18.8888286 },
    { lat: 48.9934471, lng: 18.8883387 },
    { lat: 48.9935764, lng: 18.8879349 },
    { lat: 48.9936488, lng: 18.8877196 },
    { lat: 48.9937261, lng: 18.8875126 },
    { lat: 48.9938026, lng: 18.8872673 },
    { lat: 48.9939701, lng: 18.8867915 },
    { lat: 48.994039, lng: 18.8865726 },
    { lat: 48.9941806, lng: 18.8861045 },
    { lat: 48.9944841, lng: 18.8852266 },
    { lat: 48.9945764, lng: 18.8849392 },
    { lat: 48.9950249, lng: 18.8836115 },
    { lat: 48.9952383, lng: 18.8829586 },
    { lat: 48.9953315, lng: 18.8826872 },
    { lat: 48.9954746, lng: 18.8823317 },
    { lat: 48.9957508, lng: 18.8817374 },
    { lat: 48.9958128, lng: 18.8814662 },
    { lat: 48.9958319, lng: 18.8813828 },
    { lat: 48.9959781, lng: 18.8809823 },
    { lat: 48.9960452, lng: 18.8808609 },
    { lat: 48.9963357, lng: 18.8801493 },
    { lat: 48.9964128, lng: 18.8799165 },
    { lat: 48.996422, lng: 18.8797861 },
    { lat: 48.9964218, lng: 18.879529 },
    { lat: 48.9964218, lng: 18.8794978 },
    { lat: 48.996456, lng: 18.8794173 },
    { lat: 48.9966038, lng: 18.8791757 },
    { lat: 48.9967394, lng: 18.8789643 },
    { lat: 48.9967967, lng: 18.878676 },
    { lat: 48.9968057, lng: 18.8786553 },
    { lat: 48.9968846, lng: 18.8784731 },
    { lat: 48.9969301, lng: 18.8784401 },
    { lat: 48.9969956, lng: 18.8783121 },
    { lat: 48.9972022, lng: 18.8780969 },
    { lat: 48.9972543, lng: 18.878011 },
    { lat: 48.9973889, lng: 18.8779732 },
    { lat: 48.997545, lng: 18.87748 },
    { lat: 48.9975601, lng: 18.8773631 },
    { lat: 48.997566, lng: 18.8772876 },
    { lat: 48.9972379, lng: 18.8772499 },
    { lat: 48.9971928, lng: 18.8772323 },
    { lat: 48.9970991, lng: 18.8770965 },
    { lat: 48.9970209, lng: 18.8768652 },
    { lat: 48.9969501, lng: 18.876656 },
    { lat: 48.9968431, lng: 18.8761728 },
    { lat: 48.9968215, lng: 18.8759732 },
    { lat: 48.9968195, lng: 18.8757357 },
    { lat: 48.9967475, lng: 18.8753651 },
    { lat: 48.9967241, lng: 18.8751287 },
    { lat: 48.9966145, lng: 18.8742875 },
    { lat: 48.9966221, lng: 18.8738876 },
    { lat: 48.9965944, lng: 18.8735875 },
    { lat: 48.9964982, lng: 18.8734951 },
    { lat: 48.9962282, lng: 18.8734413 },
    { lat: 48.9958646, lng: 18.8734313 },
    { lat: 48.9957971, lng: 18.8734982 },
    { lat: 48.9957702, lng: 18.8738303 },
    { lat: 48.9957817, lng: 18.8739442 },
    { lat: 48.9956772, lng: 18.8741497 },
    { lat: 48.9956095, lng: 18.8742159 },
    { lat: 48.9954292, lng: 18.8742854 },
    { lat: 48.9951445, lng: 18.8741842 },
    { lat: 48.9947576, lng: 18.8741395 },
    { lat: 48.9946026, lng: 18.8741945 },
    { lat: 48.9945559, lng: 18.8742697 },
    { lat: 48.9941952, lng: 18.8744694 },
    { lat: 48.993913, lng: 18.8745316 },
    { lat: 48.9938904, lng: 18.8745055 },
    { lat: 48.9937837, lng: 18.8744941 },
    { lat: 48.9934349, lng: 18.8740691 },
    { lat: 48.9934057, lng: 18.8738977 },
    { lat: 48.9934073, lng: 18.8737294 },
    { lat: 48.9932639, lng: 18.873377 },
    { lat: 48.9928971, lng: 18.8732126 },
    { lat: 48.9926695, lng: 18.8731932 },
    { lat: 48.9925184, lng: 18.8731088 },
    { lat: 48.992272, lng: 18.8728741 },
    { lat: 48.9921647, lng: 18.872763 },
    { lat: 48.9920471, lng: 18.8726904 },
    { lat: 48.9918647, lng: 18.8726072 },
    { lat: 48.991622, lng: 18.8724464 },
    { lat: 48.9915318, lng: 18.8723185 },
    { lat: 48.9912548, lng: 18.872105 },
    { lat: 48.9908053, lng: 18.8720018 },
    { lat: 48.9906403, lng: 18.8719952 },
    { lat: 48.9905423, lng: 18.8719497 },
    { lat: 48.9903596, lng: 18.8719082 },
    { lat: 48.9900454, lng: 18.871716 },
    { lat: 48.9899646, lng: 18.8717712 },
    { lat: 48.989649, lng: 18.8718603 },
    { lat: 48.98912, lng: 18.8721647 },
    { lat: 48.9888666, lng: 18.8723638 },
    { lat: 48.9887304, lng: 18.8725684 },
    { lat: 48.9885755, lng: 18.8729343 },
    { lat: 48.9885175, lng: 18.8732722 },
    { lat: 48.9885039, lng: 18.87378 },
    { lat: 48.9884918, lng: 18.8739815 },
    { lat: 48.9884431, lng: 18.8741746 },
    { lat: 48.9882963, lng: 18.874523 },
    { lat: 48.9882539, lng: 18.8746641 },
    { lat: 48.9879758, lng: 18.8749759 },
    { lat: 48.9879255, lng: 18.8750323 },
    { lat: 48.9877031, lng: 18.8754417 },
    { lat: 48.987628, lng: 18.8756724 },
    { lat: 48.9875501, lng: 18.8760231 },
    { lat: 48.9872468, lng: 18.8767889 },
    { lat: 48.9872083, lng: 18.8768311 },
    { lat: 48.9870601, lng: 18.877174 },
    { lat: 48.9869013, lng: 18.8776168 },
    { lat: 48.9868213, lng: 18.8779213 },
    { lat: 48.9865899, lng: 18.878316 },
    { lat: 48.9864689, lng: 18.8785052 },
    { lat: 48.9864307, lng: 18.87864 },
    { lat: 48.9863548, lng: 18.8786982 },
    { lat: 48.986258, lng: 18.8786813 },
    { lat: 48.9859771, lng: 18.8788111 },
    { lat: 48.9856568, lng: 18.8790066 },
    { lat: 48.9853311, lng: 18.8790075 },
    { lat: 48.9849548, lng: 18.8791479 },
    { lat: 48.9848196, lng: 18.879235 },
    { lat: 48.9845247, lng: 18.8793123 },
    { lat: 48.9842789, lng: 18.8793238 },
    { lat: 48.9842503, lng: 18.8793251 },
    { lat: 48.9841394, lng: 18.8792528 },
    { lat: 48.9838039, lng: 18.8791062 },
    { lat: 48.9837314, lng: 18.8790383 },
    { lat: 48.9835785, lng: 18.8789928 },
    { lat: 48.9834154, lng: 18.8789443 },
    { lat: 48.9830498, lng: 18.878676 },
    { lat: 48.9828681, lng: 18.8783196 },
    { lat: 48.9828872, lng: 18.8780753 },
    { lat: 48.9829766, lng: 18.8772904 },
    { lat: 48.9830225, lng: 18.8771755 },
    { lat: 48.9829617, lng: 18.8766033 },
    { lat: 48.9828527, lng: 18.8763905 },
    { lat: 48.982587, lng: 18.8762446 },
    { lat: 48.982284, lng: 18.8763408 },
    { lat: 48.9819897, lng: 18.8766043 },
    { lat: 48.9814855, lng: 18.8770381 },
    { lat: 48.9813479, lng: 18.8760734 },
    { lat: 48.9810564, lng: 18.8741049 },
    { lat: 48.9806838, lng: 18.8737876 },
    { lat: 48.9799683, lng: 18.8739524 },
    { lat: 48.9797817, lng: 18.8742707 },
    { lat: 48.9796971, lng: 18.8748417 },
    { lat: 48.979777, lng: 18.8750344 },
    { lat: 48.9796429, lng: 18.8751245 },
    { lat: 48.9793751, lng: 18.8760152 },
    { lat: 48.9794487, lng: 18.8766799 },
    { lat: 48.979186, lng: 18.8779971 },
    { lat: 48.9789921, lng: 18.8781745 },
    { lat: 48.9787104, lng: 18.8783306 },
    { lat: 48.9785065, lng: 18.8784215 },
    { lat: 48.9782652, lng: 18.8784899 },
    { lat: 48.9779792, lng: 18.8783874 },
    { lat: 48.977289, lng: 18.8776992 },
    { lat: 48.9767231, lng: 18.8775297 },
    { lat: 48.9762463, lng: 18.8773126 },
    { lat: 48.9758558, lng: 18.8769311 },
    { lat: 48.9756776, lng: 18.8767325 },
    { lat: 48.975763, lng: 18.8763308 },
    { lat: 48.9756443, lng: 18.8759274 },
    { lat: 48.9754978, lng: 18.8756013 },
    { lat: 48.9752442, lng: 18.8753837 },
    { lat: 48.9746461, lng: 18.8751965 },
    { lat: 48.9743311, lng: 18.8748831 },
    { lat: 48.9742666, lng: 18.8745929 },
    { lat: 48.9742634, lng: 18.8741809 },
    { lat: 48.9740296, lng: 18.8737782 },
    { lat: 48.9738428, lng: 18.8736974 },
    { lat: 48.9735067, lng: 18.8739346 },
    { lat: 48.9728421, lng: 18.8746949 },
    { lat: 48.9727571, lng: 18.8753442 },
    { lat: 48.9727304, lng: 18.8756402 },
    { lat: 48.9727234, lng: 18.87594 },
    { lat: 48.9727639, lng: 18.8762037 },
    { lat: 48.9728245, lng: 18.8764599 },
    { lat: 48.9726441, lng: 18.8764813 },
    { lat: 48.9725064, lng: 18.8767671 },
    { lat: 48.9722712, lng: 18.876884 },
    { lat: 48.9721457, lng: 18.8769208 },
    { lat: 48.9719686, lng: 18.8768298 },
    { lat: 48.9716947, lng: 18.8768977 },
    { lat: 48.9714562, lng: 18.876823 },
    { lat: 48.9711637, lng: 18.8768784 },
    { lat: 48.9709124, lng: 18.8769904 },
    { lat: 48.9711683, lng: 18.8779581 },
    { lat: 48.9713043, lng: 18.878826 },
    { lat: 48.9712383, lng: 18.8792982 },
    { lat: 48.971335, lng: 18.8794235 },
    { lat: 48.9713503, lng: 18.8797221 },
    { lat: 48.9712858, lng: 18.8801367 },
    { lat: 48.9711206, lng: 18.8808949 },
    { lat: 48.9710937, lng: 18.8809845 },
    { lat: 48.9710445, lng: 18.881149 },
    { lat: 48.9709945, lng: 18.8812938 },
    { lat: 48.9708094, lng: 18.8818404 },
    { lat: 48.9705291, lng: 18.8825741 },
    { lat: 48.969996, lng: 18.8835918 },
    { lat: 48.9694283, lng: 18.8847908 },
    { lat: 48.9689677, lng: 18.8857019 },
    { lat: 48.9683232, lng: 18.886657 },
    { lat: 48.9680644, lng: 18.8870418 },
    { lat: 48.9675029, lng: 18.8878744 },
    { lat: 48.9666476, lng: 18.8896919 },
    { lat: 48.9665661, lng: 18.8899279 },
    { lat: 48.9676158, lng: 18.8910473 },
    { lat: 48.9685356, lng: 18.8918129 },
    { lat: 48.969509, lng: 18.8924806 },
    { lat: 48.9694415, lng: 18.8925054 },
    { lat: 48.96898, lng: 18.8932285 },
    { lat: 48.9690426, lng: 18.893285 },
    { lat: 48.9711567, lng: 18.8951983 },
    { lat: 48.9724099, lng: 18.8962885 },
    { lat: 48.9726449, lng: 18.8965647 },
    { lat: 48.9702284, lng: 18.8972394 },
    { lat: 48.9687398, lng: 18.8981618 },
  ],
  Ratkovo: [
    { lat: 49.1301885, lng: 19.0880213 },
    { lat: 49.1304718, lng: 19.0881904 },
    { lat: 49.1306318, lng: 19.0883024 },
    { lat: 49.1309748, lng: 19.0884953 },
    { lat: 49.1317181, lng: 19.0888432 },
    { lat: 49.1322413, lng: 19.0889859 },
    { lat: 49.1330308, lng: 19.0891557 },
    { lat: 49.1335032, lng: 19.0892038 },
    { lat: 49.1343037, lng: 19.0892983 },
    { lat: 49.1350881, lng: 19.0893405 },
    { lat: 49.1360964, lng: 19.089373 },
    { lat: 49.1370406, lng: 19.0894121 },
    { lat: 49.1379351, lng: 19.0894684 },
    { lat: 49.1386967, lng: 19.0895752 },
    { lat: 49.1394037, lng: 19.0897149 },
    { lat: 49.1404656, lng: 19.0900378 },
    { lat: 49.1418789, lng: 19.0904887 },
    { lat: 49.1418711, lng: 19.0905372 },
    { lat: 49.1424436, lng: 19.0907178 },
    { lat: 49.1427808, lng: 19.090878 },
    { lat: 49.1428272, lng: 19.0909994 },
    { lat: 49.1430197, lng: 19.091085 },
    { lat: 49.1431061, lng: 19.0910517 },
    { lat: 49.1431168, lng: 19.0910426 },
    { lat: 49.1432549, lng: 19.0911232 },
    { lat: 49.1437458, lng: 19.0913637 },
    { lat: 49.1446518, lng: 19.0918587 },
    { lat: 49.1458083, lng: 19.0925443 },
    { lat: 49.1458613, lng: 19.0921534 },
    { lat: 49.1456248, lng: 19.0914059 },
    { lat: 49.1454721, lng: 19.0912117 },
    { lat: 49.1454468, lng: 19.0907213 },
    { lat: 49.1452812, lng: 19.0901278 },
    { lat: 49.1453258, lng: 19.0895956 },
    { lat: 49.1454078, lng: 19.0889921 },
    { lat: 49.1450589, lng: 19.0884552 },
    { lat: 49.1440069, lng: 19.0883133 },
    { lat: 49.1436073, lng: 19.0881434 },
    { lat: 49.1433967, lng: 19.0888538 },
    { lat: 49.1432034, lng: 19.0886837 },
    { lat: 49.143286, lng: 19.0877957 },
    { lat: 49.1425478, lng: 19.0872308 },
    { lat: 49.1421417, lng: 19.0870383 },
    { lat: 49.1417913, lng: 19.0869319 },
    { lat: 49.1415397, lng: 19.0869499 },
    { lat: 49.1412942, lng: 19.0868862 },
    { lat: 49.1407982, lng: 19.0869298 },
    { lat: 49.1403842, lng: 19.0870132 },
    { lat: 49.1402799, lng: 19.086479 },
    { lat: 49.1402122, lng: 19.0861552 },
    { lat: 49.1400313, lng: 19.0850696 },
    { lat: 49.1402559, lng: 19.0849628 },
    { lat: 49.1404667, lng: 19.0848926 },
    { lat: 49.1409765, lng: 19.0847844 },
    { lat: 49.1409646, lng: 19.0845921 },
    { lat: 49.1408483, lng: 19.0845849 },
    { lat: 49.1408388, lng: 19.0840524 },
    { lat: 49.1408853, lng: 19.0836687 },
    { lat: 49.1409033, lng: 19.0832335 },
    { lat: 49.1410831, lng: 19.0820579 },
    { lat: 49.1411482, lng: 19.0817541 },
    { lat: 49.1405815, lng: 19.0818541 },
    { lat: 49.1404481, lng: 19.0817995 },
    { lat: 49.140341, lng: 19.0817106 },
    { lat: 49.1395268, lng: 19.0803106 },
    { lat: 49.1386317, lng: 19.0799874 },
    { lat: 49.1374585, lng: 19.0783072 },
    { lat: 49.136824, lng: 19.0770103 },
    { lat: 49.1351463, lng: 19.0773191 },
    { lat: 49.133427, lng: 19.0777955 },
    { lat: 49.1334844, lng: 19.0781813 },
    { lat: 49.1335949, lng: 19.0786164 },
    { lat: 49.1337227, lng: 19.0790366 },
    { lat: 49.1337774, lng: 19.0792308 },
    { lat: 49.1339199, lng: 19.0796716 },
    { lat: 49.133441, lng: 19.0799041 },
    { lat: 49.1325876, lng: 19.0802932 },
    { lat: 49.1312682, lng: 19.0811936 },
    { lat: 49.1316408, lng: 19.0821613 },
    { lat: 49.1320024, lng: 19.0848301 },
    { lat: 49.1306403, lng: 19.0872216 },
    { lat: 49.1301818, lng: 19.0876614 },
    { lat: 49.1301885, lng: 19.0880213 },
  ],
  SklabinskýPodzámok: [
    { lat: 49.0595806, lng: 19.0605764 },
    { lat: 49.0596559, lng: 19.0604369 },
    { lat: 49.059636, lng: 19.0599456 },
    { lat: 49.0594396, lng: 19.0591684 },
    { lat: 49.059886, lng: 19.0584636 },
    { lat: 49.0604019, lng: 19.0577986 },
    { lat: 49.060478, lng: 19.0575364 },
    { lat: 49.060645, lng: 19.0570664 },
    { lat: 49.060698, lng: 19.0567447 },
    { lat: 49.0610138, lng: 19.0551166 },
    { lat: 49.0612965, lng: 19.0539518 },
    { lat: 49.0617833, lng: 19.0529769 },
    { lat: 49.0621758, lng: 19.0519155 },
    { lat: 49.062312, lng: 19.0506639 },
    { lat: 49.0621864, lng: 19.0498577 },
    { lat: 49.0619548, lng: 19.0493746 },
    { lat: 49.0617619, lng: 19.0492114 },
    { lat: 49.0616604, lng: 19.0482352 },
    { lat: 49.061397, lng: 19.0476548 },
    { lat: 49.0604918, lng: 19.0468867 },
    { lat: 49.0599244, lng: 19.0464039 },
    { lat: 49.0594745, lng: 19.0459266 },
    { lat: 49.0591664, lng: 19.0452845 },
    { lat: 49.0589003, lng: 19.0442199 },
    { lat: 49.0583331, lng: 19.0436074 },
    { lat: 49.0575593, lng: 19.0429816 },
    { lat: 49.0573704, lng: 19.0425185 },
    { lat: 49.0575968, lng: 19.0395054 },
    { lat: 49.0577752, lng: 19.0381563 },
    { lat: 49.0581379, lng: 19.0366126 },
    { lat: 49.0583226, lng: 19.03611 },
    { lat: 49.0590623, lng: 19.035278 },
    { lat: 49.0593443, lng: 19.0347601 },
    { lat: 49.0599237, lng: 19.0341285 },
    { lat: 49.0599935, lng: 19.0338081 },
    { lat: 49.0601915, lng: 19.0336619 },
    { lat: 49.0605256, lng: 19.0332664 },
    { lat: 49.0607863, lng: 19.0327527 },
    { lat: 49.0608127, lng: 19.0326055 },
    { lat: 49.061298, lng: 19.0317123 },
    { lat: 49.0614832, lng: 19.0310388 },
    { lat: 49.0614471, lng: 19.0303157 },
    { lat: 49.0615635, lng: 19.0298939 },
    { lat: 49.0616883, lng: 19.0292744 },
    { lat: 49.0617527, lng: 19.0283075 },
    { lat: 49.0616092, lng: 19.0276893 },
    { lat: 49.0614019, lng: 19.0273326 },
    { lat: 49.0610588, lng: 19.0270084 },
    { lat: 49.0604821, lng: 19.0263411 },
    { lat: 49.0595117, lng: 19.0253296 },
    { lat: 49.0597101, lng: 19.0251025 },
    { lat: 49.0597006, lng: 19.0247329 },
    { lat: 49.0605611, lng: 19.0246507 },
    { lat: 49.0607022, lng: 19.0247458 },
    { lat: 49.0606931, lng: 19.0245635 },
    { lat: 49.0611802, lng: 19.0241804 },
    { lat: 49.0612975, lng: 19.0238192 },
    { lat: 49.061086, lng: 19.0234921 },
    { lat: 49.0600381, lng: 19.0229159 },
    { lat: 49.0591201, lng: 19.0207406 },
    { lat: 49.0590238, lng: 19.0198545 },
    { lat: 49.059021, lng: 19.0197758 },
    { lat: 49.0591868, lng: 19.0198637 },
    { lat: 49.0594642, lng: 19.0199369 },
    { lat: 49.0599863, lng: 19.0198934 },
    { lat: 49.061258, lng: 19.0195315 },
    { lat: 49.0606855, lng: 19.0192462 },
    { lat: 49.0606039, lng: 19.0188092 },
    { lat: 49.0606018, lng: 19.018743 },
    { lat: 49.0603906, lng: 19.0186452 },
    { lat: 49.0604001, lng: 19.0185661 },
    { lat: 49.0601252, lng: 19.0186371 },
    { lat: 49.0599459, lng: 19.0188369 },
    { lat: 49.0594147, lng: 19.0191015 },
    { lat: 49.0575425, lng: 19.0176052 },
    { lat: 49.0572103, lng: 19.0173398 },
    { lat: 49.057527, lng: 19.0165242 },
    { lat: 49.0576334, lng: 19.0163095 },
    { lat: 49.0578066, lng: 19.0162121 },
    { lat: 49.0582723, lng: 19.0161417 },
    { lat: 49.0586561, lng: 19.0155684 },
    { lat: 49.059636, lng: 19.0150534 },
    { lat: 49.0598686, lng: 19.0147212 },
    { lat: 49.0614769, lng: 19.0141743 },
    { lat: 49.062984, lng: 19.0141028 },
    { lat: 49.0637187, lng: 19.0139181 },
    { lat: 49.0643586, lng: 19.013681 },
    { lat: 49.0647312, lng: 19.0131299 },
    { lat: 49.0650911, lng: 19.0127552 },
    { lat: 49.066237, lng: 19.0121543 },
    { lat: 49.0676083, lng: 19.0126144 },
    { lat: 49.0689722, lng: 19.0133774 },
    { lat: 49.0700036, lng: 19.0140288 },
    { lat: 49.0706669, lng: 19.0139483 },
    { lat: 49.0716288, lng: 19.0136584 },
    { lat: 49.0728712, lng: 19.0129377 },
    { lat: 49.0737896, lng: 19.0121415 },
    { lat: 49.0739872, lng: 19.0117206 },
    { lat: 49.0737658, lng: 19.0111285 },
    { lat: 49.073338, lng: 19.0093831 },
    { lat: 49.0732315, lng: 19.0087845 },
    { lat: 49.0730551, lng: 19.0084138 },
    { lat: 49.0727038, lng: 19.0075923 },
    { lat: 49.0726662, lng: 19.0068427 },
    { lat: 49.0725669, lng: 19.0061857 },
    { lat: 49.0724023, lng: 19.0054889 },
    { lat: 49.0722444, lng: 19.0049927 },
    { lat: 49.0723502, lng: 19.0035264 },
    { lat: 49.0723835, lng: 19.0025973 },
    { lat: 49.0722534, lng: 19.0014636 },
    { lat: 49.0720958, lng: 19.0007473 },
    { lat: 49.0719493, lng: 19.0003882 },
    { lat: 49.0716676, lng: 19.0001646 },
    { lat: 49.0714728, lng: 18.9998798 },
    { lat: 49.0711972, lng: 18.9995983 },
    { lat: 49.0710804, lng: 18.9994267 },
    { lat: 49.071067, lng: 18.9992736 },
    { lat: 49.0710777, lng: 18.9991512 },
    { lat: 49.0711393, lng: 18.9990286 },
    { lat: 49.0716709, lng: 18.9987946 },
    { lat: 49.0719026, lng: 18.9986462 },
    { lat: 49.0720352, lng: 18.9985071 },
    { lat: 49.072129, lng: 18.9983275 },
    { lat: 49.0721899, lng: 18.9980828 },
    { lat: 49.072108, lng: 18.9977539 },
    { lat: 49.0720976, lng: 18.9973449 },
    { lat: 49.0723527, lng: 18.9963832 },
    { lat: 49.0724244, lng: 18.9961969 },
    { lat: 49.0728996, lng: 18.995489 },
    { lat: 49.0732078, lng: 18.9951396 },
    { lat: 49.0735625, lng: 18.9949718 },
    { lat: 49.073907, lng: 18.9946009 },
    { lat: 49.0741242, lng: 18.9945278 },
    { lat: 49.0740829, lng: 18.994044 },
    { lat: 49.074071, lng: 18.993694 },
    { lat: 49.0741292, lng: 18.9934384 },
    { lat: 49.0742855, lng: 18.9931628 },
    { lat: 49.0743028, lng: 18.9924959 },
    { lat: 49.0745036, lng: 18.9918458 },
    { lat: 49.0745115, lng: 18.9911947 },
    { lat: 49.0745931, lng: 18.9906649 },
    { lat: 49.0748709, lng: 18.9893718 },
    { lat: 49.0750835, lng: 18.9896114 },
    { lat: 49.0758834, lng: 18.9907452 },
    { lat: 49.076024, lng: 18.9898852 },
    { lat: 49.0761056, lng: 18.9895816 },
    { lat: 49.0759486, lng: 18.9891931 },
    { lat: 49.0756882, lng: 18.9885661 },
    { lat: 49.0756942, lng: 18.9882824 },
    { lat: 49.0756664, lng: 18.9881325 },
    { lat: 49.075596, lng: 18.9880522 },
    { lat: 49.0755789, lng: 18.9879618 },
    { lat: 49.075515, lng: 18.9879221 },
    { lat: 49.0753983, lng: 18.9876441 },
    { lat: 49.075304, lng: 18.987559 },
    { lat: 49.0753034, lng: 18.9875048 },
    { lat: 49.0750853, lng: 18.9873339 },
    { lat: 49.0750804, lng: 18.9872373 },
    { lat: 49.0749895, lng: 18.9871587 },
    { lat: 49.0749283, lng: 18.9871544 },
    { lat: 49.0748917, lng: 18.9871396 },
    { lat: 49.0748438, lng: 18.9869524 },
    { lat: 49.0748048, lng: 18.9869375 },
    { lat: 49.0747903, lng: 18.9867163 },
    { lat: 49.0747093, lng: 18.986698 },
    { lat: 49.0747187, lng: 18.9865703 },
    { lat: 49.0746967, lng: 18.9865129 },
    { lat: 49.0746075, lng: 18.98642 },
    { lat: 49.0745321, lng: 18.9864673 },
    { lat: 49.0745111, lng: 18.9861616 },
    { lat: 49.0745817, lng: 18.986085 },
    { lat: 49.0745925, lng: 18.9859432 },
    { lat: 49.0745063, lng: 18.9856199 },
    { lat: 49.0744207, lng: 18.9855105 },
    { lat: 49.0743256, lng: 18.9855021 },
    { lat: 49.0743008, lng: 18.9852073 },
    { lat: 49.0741497, lng: 18.9850537 },
    { lat: 49.0741433, lng: 18.9848563 },
    { lat: 49.0740591, lng: 18.984728 },
    { lat: 49.0740599, lng: 18.9846535 },
    { lat: 49.0740107, lng: 18.9845539 },
    { lat: 49.0739176, lng: 18.9845633 },
    { lat: 49.0739032, lng: 18.9844086 },
    { lat: 49.0739108, lng: 18.9843156 },
    { lat: 49.0738776, lng: 18.984303 },
    { lat: 49.0738276, lng: 18.9844095 },
    { lat: 49.0737915, lng: 18.9842972 },
    { lat: 49.0738045, lng: 18.9841942 },
    { lat: 49.0736721, lng: 18.9841158 },
    { lat: 49.0737019, lng: 18.9839477 },
    { lat: 49.0736672, lng: 18.9838877 },
    { lat: 49.0736024, lng: 18.983919 },
    { lat: 49.0735751, lng: 18.9839157 },
    { lat: 49.0734767, lng: 18.9837549 },
    { lat: 49.0734603, lng: 18.9834943 },
    { lat: 49.0733976, lng: 18.9835006 },
    { lat: 49.0732769, lng: 18.9830328 },
    { lat: 49.0730995, lng: 18.9827981 },
    { lat: 49.0729708, lng: 18.9827046 },
    { lat: 49.0729332, lng: 18.9825396 },
    { lat: 49.0729711, lng: 18.9823532 },
    { lat: 49.0729584, lng: 18.9823216 },
    { lat: 49.0728623, lng: 18.9822954 },
    { lat: 49.072902, lng: 18.9820797 },
    { lat: 49.0727445, lng: 18.9820858 },
    { lat: 49.0726138, lng: 18.9819706 },
    { lat: 49.0725826, lng: 18.9817793 },
    { lat: 49.072517, lng: 18.981728 },
    { lat: 49.0724937, lng: 18.9816271 },
    { lat: 49.0725333, lng: 18.9815589 },
    { lat: 49.07249, lng: 18.9815057 },
    { lat: 49.0724138, lng: 18.9814941 },
    { lat: 49.0723566, lng: 18.9814565 },
    { lat: 49.0723307, lng: 18.9813642 },
    { lat: 49.0722413, lng: 18.9812502 },
    { lat: 49.0721607, lng: 18.9810791 },
    { lat: 49.0721006, lng: 18.981033 },
    { lat: 49.0720363, lng: 18.9809836 },
    { lat: 49.0718113, lng: 18.9808588 },
    { lat: 49.0716684, lng: 18.9806633 },
    { lat: 49.0716217, lng: 18.9806497 },
    { lat: 49.0715324, lng: 18.9804897 },
    { lat: 49.0714526, lng: 18.980426 },
    { lat: 49.071423, lng: 18.9803118 },
    { lat: 49.0713632, lng: 18.9801987 },
    { lat: 49.0712956, lng: 18.9801965 },
    { lat: 49.0712276, lng: 18.9800991 },
    { lat: 49.0711843, lng: 18.9800927 },
    { lat: 49.0709068, lng: 18.9797762 },
    { lat: 49.0708758, lng: 18.9796946 },
    { lat: 49.0708785, lng: 18.9795933 },
    { lat: 49.0708053, lng: 18.9795047 },
    { lat: 49.0707752, lng: 18.9793105 },
    { lat: 49.07072, lng: 18.9791993 },
    { lat: 49.0705878, lng: 18.9790799 },
    { lat: 49.0705191, lng: 18.9787708 },
    { lat: 49.0704668, lng: 18.9785166 },
    { lat: 49.0703489, lng: 18.9781449 },
    { lat: 49.0703168, lng: 18.9777509 },
    { lat: 49.0702356, lng: 18.9769471 },
    { lat: 49.0702427, lng: 18.9767956 },
    { lat: 49.070189, lng: 18.976626 },
    { lat: 49.07008, lng: 18.9764323 },
    { lat: 49.0700118, lng: 18.9759499 },
    { lat: 49.070012, lng: 18.9757046 },
    { lat: 49.0700559, lng: 18.9746305 },
    { lat: 49.0700707, lng: 18.974207 },
    { lat: 49.0697009, lng: 18.970063 },
    { lat: 49.0694001, lng: 18.9702536 },
    { lat: 49.0692807, lng: 18.9703452 },
    { lat: 49.0691294, lng: 18.9704984 },
    { lat: 49.0690243, lng: 18.9706473 },
    { lat: 49.0689382, lng: 18.9707303 },
    { lat: 49.0689184, lng: 18.9707632 },
    { lat: 49.0687257, lng: 18.9710097 },
    { lat: 49.0686079, lng: 18.9711091 },
    { lat: 49.0684309, lng: 18.9712023 },
    { lat: 49.0683075, lng: 18.9712322 },
    { lat: 49.0680235, lng: 18.9713261 },
    { lat: 49.0678579, lng: 18.9718409 },
    { lat: 49.0677827, lng: 18.9720989 },
    { lat: 49.067763, lng: 18.9722651 },
    { lat: 49.067747, lng: 18.9726844 },
    { lat: 49.0676648, lng: 18.9726879 },
    { lat: 49.0676834, lng: 18.9729212 },
    { lat: 49.0676161, lng: 18.9729086 },
    { lat: 49.0673891, lng: 18.9729646 },
    { lat: 49.0658466, lng: 18.9745723 },
    { lat: 49.0654969, lng: 18.975059 },
    { lat: 49.0651064, lng: 18.9746515 },
    { lat: 49.0640167, lng: 18.9738317 },
    { lat: 49.0634086, lng: 18.9737107 },
    { lat: 49.0631813, lng: 18.9734284 },
    { lat: 49.0622434, lng: 18.9734278 },
    { lat: 49.0623188, lng: 18.9745446 },
    { lat: 49.0621705, lng: 18.9759205 },
    { lat: 49.0618132, lng: 18.9767722 },
    { lat: 49.0612448, lng: 18.9775726 },
    { lat: 49.0610813, lng: 18.9792104 },
    { lat: 49.0610045, lng: 18.979988 },
    { lat: 49.0613512, lng: 18.9838597 },
    { lat: 49.0615895, lng: 18.9855675 },
    { lat: 49.0614046, lng: 18.9863497 },
    { lat: 49.0613059, lng: 18.9871676 },
    { lat: 49.0613846, lng: 18.9873899 },
    { lat: 49.0614729, lng: 18.9882015 },
    { lat: 49.0609624, lng: 18.9882943 },
    { lat: 49.0606906, lng: 18.9882501 },
    { lat: 49.0606211, lng: 18.9886817 },
    { lat: 49.0603948, lng: 18.989519 },
    { lat: 49.0602649, lng: 18.9918226 },
    { lat: 49.0596314, lng: 18.9930625 },
    { lat: 49.0595487, lng: 18.9937487 },
    { lat: 49.0595133, lng: 18.9944354 },
    { lat: 49.0593681, lng: 18.9946863 },
    { lat: 49.0591178, lng: 18.9955404 },
    { lat: 49.0587132, lng: 18.9959006 },
    { lat: 49.0580832, lng: 18.9964889 },
    { lat: 49.0579186, lng: 18.996604 },
    { lat: 49.0577918, lng: 18.9968168 },
    { lat: 49.0576846, lng: 18.9971046 },
    { lat: 49.0576801, lng: 18.997781 },
    { lat: 49.0577334, lng: 18.998058 },
    { lat: 49.0578274, lng: 18.998174 },
    { lat: 49.0579884, lng: 18.9983081 },
    { lat: 49.0585329, lng: 19.0000123 },
    { lat: 49.058507, lng: 19.0008482 },
    { lat: 49.0592447, lng: 19.002226 },
    { lat: 49.0592931, lng: 19.0022919 },
    { lat: 49.0589637, lng: 19.0027148 },
    { lat: 49.0581894, lng: 19.0032105 },
    { lat: 49.0578915, lng: 19.003432 },
    { lat: 49.0576627, lng: 19.0037267 },
    { lat: 49.057146, lng: 19.0046383 },
    { lat: 49.0567157, lng: 19.0050516 },
    { lat: 49.0562821, lng: 19.0054646 },
    { lat: 49.0557083, lng: 19.0062116 },
    { lat: 49.0555793, lng: 19.0063327 },
    { lat: 49.0554392, lng: 19.006685 },
    { lat: 49.0552353, lng: 19.0079714 },
    { lat: 49.0549092, lng: 19.0085297 },
    { lat: 49.0535277, lng: 19.0103393 },
    { lat: 49.0530463, lng: 19.0109699 },
    { lat: 49.0527198, lng: 19.0115444 },
    { lat: 49.0511897, lng: 19.0135875 },
    { lat: 49.0510299, lng: 19.0133461 },
    { lat: 49.050314, lng: 19.0145378 },
    { lat: 49.05021, lng: 19.0146218 },
    { lat: 49.0501635, lng: 19.0146606 },
    { lat: 49.0501406, lng: 19.0146787 },
    { lat: 49.050182, lng: 19.0151907 },
    { lat: 49.050355, lng: 19.0161217 },
    { lat: 49.050461, lng: 19.0164167 },
    { lat: 49.0505656, lng: 19.0169311 },
    { lat: 49.0503143, lng: 19.0178346 },
    { lat: 49.0502372, lng: 19.0179363 },
    { lat: 49.0495532, lng: 19.0183501 },
    { lat: 49.0491321, lng: 19.0189893 },
    { lat: 49.0489402, lng: 19.0192994 },
    { lat: 49.0485712, lng: 19.0200602 },
    { lat: 49.0484653, lng: 19.0202191 },
    { lat: 49.0479077, lng: 19.0198919 },
    { lat: 49.0470919, lng: 19.0231881 },
    { lat: 49.0464802, lng: 19.0251387 },
    { lat: 49.0458557, lng: 19.0317522 },
    { lat: 49.0462185, lng: 19.0332858 },
    { lat: 49.0460602, lng: 19.0338212 },
    { lat: 49.046071, lng: 19.0340557 },
    { lat: 49.0456048, lng: 19.0350852 },
    { lat: 49.0455149, lng: 19.035225 },
    { lat: 49.045435, lng: 19.0354329 },
    { lat: 49.0453561, lng: 19.0357018 },
    { lat: 49.0452304, lng: 19.0361231 },
    { lat: 49.0456063, lng: 19.0373519 },
    { lat: 49.0456474, lng: 19.0377687 },
    { lat: 49.0456792, lng: 19.0380892 },
    { lat: 49.0457632, lng: 19.0384849 },
    { lat: 49.0455682, lng: 19.0392354 },
    { lat: 49.0452603, lng: 19.0404277 },
    { lat: 49.0452948, lng: 19.0406601 },
    { lat: 49.045362, lng: 19.0411135 },
    { lat: 49.0453682, lng: 19.0413744 },
    { lat: 49.0455267, lng: 19.0415452 },
    { lat: 49.045549, lng: 19.0419652 },
    { lat: 49.0455715, lng: 19.0423889 },
    { lat: 49.0455947, lng: 19.042826 },
    { lat: 49.045598, lng: 19.0428874 },
    { lat: 49.0455751, lng: 19.0432196 },
    { lat: 49.0455006, lng: 19.043847 },
    { lat: 49.0454407, lng: 19.0443427 },
    { lat: 49.0455064, lng: 19.0449619 },
    { lat: 49.0455189, lng: 19.0450793 },
    { lat: 49.0454872, lng: 19.0451612 },
    { lat: 49.0453841, lng: 19.0454276 },
    { lat: 49.0453738, lng: 19.0457479 },
    { lat: 49.0453394, lng: 19.0468149 },
    { lat: 49.0450804, lng: 19.0476824 },
    { lat: 49.0446942, lng: 19.048304 },
    { lat: 49.044314, lng: 19.0485597 },
    { lat: 49.0442164, lng: 19.0486253 },
    { lat: 49.0440412, lng: 19.0487085 },
    { lat: 49.0437408, lng: 19.0488511 },
    { lat: 49.0434118, lng: 19.0488567 },
    { lat: 49.043291, lng: 19.0488789 },
    { lat: 49.0428757, lng: 19.048955 },
    { lat: 49.0426543, lng: 19.0489917 },
    { lat: 49.042618, lng: 19.0488741 },
    { lat: 49.0424662, lng: 19.0486342 },
    { lat: 49.0423089, lng: 19.0485079 },
    { lat: 49.0421495, lng: 19.0484325 },
    { lat: 49.0419695, lng: 19.0483468 },
    { lat: 49.0415804, lng: 19.0482982 },
    { lat: 49.0410845, lng: 19.0483492 },
    { lat: 49.0407481, lng: 19.0483391 },
    { lat: 49.0405183, lng: 19.0482516 },
    { lat: 49.0406682, lng: 19.0481169 },
    { lat: 49.0398776, lng: 19.0476998 },
    { lat: 49.0397714, lng: 19.0481469 },
    { lat: 49.0395495, lng: 19.0482637 },
    { lat: 49.0393408, lng: 19.0480815 },
    { lat: 49.0388637, lng: 19.0485536 },
    { lat: 49.0386223, lng: 19.0496607 },
    { lat: 49.038502, lng: 19.0504367 },
    { lat: 49.0383713, lng: 19.0508207 },
    { lat: 49.0380241, lng: 19.051976 },
    { lat: 49.0377919, lng: 19.0524321 },
    { lat: 49.0374652, lng: 19.0529346 },
    { lat: 49.0373231, lng: 19.0531604 },
    { lat: 49.0365694, lng: 19.0543502 },
    { lat: 49.0365216, lng: 19.0545432 },
    { lat: 49.0359922, lng: 19.0544141 },
    { lat: 49.0354841, lng: 19.0542404 },
    { lat: 49.0349956, lng: 19.0544166 },
    { lat: 49.0346993, lng: 19.0546238 },
    { lat: 49.0345687, lng: 19.0546949 },
    { lat: 49.0344466, lng: 19.0547348 },
    { lat: 49.0341238, lng: 19.0549976 },
    { lat: 49.0339145, lng: 19.0551165 },
    { lat: 49.0337923, lng: 19.055243 },
    { lat: 49.0334889, lng: 19.0556034 },
    { lat: 49.0333354, lng: 19.0557849 },
    { lat: 49.0332163, lng: 19.0559265 },
    { lat: 49.0329053, lng: 19.0560653 },
    { lat: 49.0327156, lng: 19.0561033 },
    { lat: 49.0322826, lng: 19.0564653 },
    { lat: 49.0319729, lng: 19.0569443 },
    { lat: 49.0314985, lng: 19.0571536 },
    { lat: 49.031228, lng: 19.0574757 },
    { lat: 49.0310677, lng: 19.0576716 },
    { lat: 49.0306293, lng: 19.0579915 },
    { lat: 49.0302591, lng: 19.0586903 },
    { lat: 49.0299141, lng: 19.0592293 },
    { lat: 49.0295692, lng: 19.0600952 },
    { lat: 49.0294913, lng: 19.060457 },
    { lat: 49.0292916, lng: 19.0609972 },
    { lat: 49.029125, lng: 19.0617715 },
    { lat: 49.0291056, lng: 19.0618615 },
    { lat: 49.029058, lng: 19.0620824 },
    { lat: 49.0289323, lng: 19.0627711 },
    { lat: 49.0288059, lng: 19.0632469 },
    { lat: 49.0285438, lng: 19.063938 },
    { lat: 49.0279221, lng: 19.0648568 },
    { lat: 49.0272694, lng: 19.0664713 },
    { lat: 49.0269707, lng: 19.066631 },
    { lat: 49.0267219, lng: 19.0669329 },
    { lat: 49.0263495, lng: 19.0672221 },
    { lat: 49.0255731, lng: 19.0676571 },
    { lat: 49.0251601, lng: 19.0679685 },
    { lat: 49.0249365, lng: 19.0680279 },
    { lat: 49.0246408, lng: 19.0680431 },
    { lat: 49.0243054, lng: 19.06842 },
    { lat: 49.024198, lng: 19.0683224 },
    { lat: 49.0237398, lng: 19.0676537 },
    { lat: 49.0232719, lng: 19.0674926 },
    { lat: 49.0232548, lng: 19.0665096 },
    { lat: 49.0229626, lng: 19.066509 },
    { lat: 49.0227572, lng: 19.0664554 },
    { lat: 49.0223564, lng: 19.0665333 },
    { lat: 49.0220045, lng: 19.0663255 },
    { lat: 49.0217714, lng: 19.0661974 },
    { lat: 49.0216709, lng: 19.0661202 },
    { lat: 49.0212196, lng: 19.0657737 },
    { lat: 49.0208944, lng: 19.0656049 },
    { lat: 49.0204441, lng: 19.065371 },
    { lat: 49.0203865, lng: 19.0658905 },
    { lat: 49.0200954, lng: 19.0662928 },
    { lat: 49.0199511, lng: 19.066541 },
    { lat: 49.0194906, lng: 19.070076 },
    { lat: 49.0192148, lng: 19.0716495 },
    { lat: 49.0189677, lng: 19.0724651 },
    { lat: 49.0187726, lng: 19.0735086 },
    { lat: 49.0181887, lng: 19.0750761 },
    { lat: 49.0177347, lng: 19.0764749 },
    { lat: 49.0174476, lng: 19.0772311 },
    { lat: 49.0170551, lng: 19.0780665 },
    { lat: 49.0167158, lng: 19.0791386 },
    { lat: 49.0165078, lng: 19.080242 },
    { lat: 49.0162297, lng: 19.0808183 },
    { lat: 49.0160435, lng: 19.0812034 },
    { lat: 49.0160041, lng: 19.0812478 },
    { lat: 49.0156419, lng: 19.0820374 },
    { lat: 49.0154924, lng: 19.0825921 },
    { lat: 49.0154474, lng: 19.0847556 },
    { lat: 49.0154802, lng: 19.0860821 },
    { lat: 49.015402, lng: 19.0865985 },
    { lat: 49.0149132, lng: 19.0879042 },
    { lat: 49.0140291, lng: 19.0889238 },
    { lat: 49.0125878, lng: 19.0904169 },
    { lat: 49.0124691, lng: 19.0905913 },
    { lat: 49.0117734, lng: 19.0907714 },
    { lat: 49.0115603, lng: 19.0907243 },
    { lat: 49.0107683, lng: 19.0926497 },
    { lat: 49.0102101, lng: 19.094334 },
    { lat: 49.0098385, lng: 19.095527 },
    { lat: 49.0096028, lng: 19.0959841 },
    { lat: 49.0096834, lng: 19.0968166 },
    { lat: 49.0095948, lng: 19.0977632 },
    { lat: 49.0093989, lng: 19.0988617 },
    { lat: 49.0092028, lng: 19.0997319 },
    { lat: 49.0085753, lng: 19.1006976 },
    { lat: 49.0084488, lng: 19.1010337 },
    { lat: 49.0082411, lng: 19.102033 },
    { lat: 49.0081772, lng: 19.1034813 },
    { lat: 49.0079641, lng: 19.1041903 },
    { lat: 49.0081351, lng: 19.1057608 },
    { lat: 49.0082889, lng: 19.1075534 },
    { lat: 49.0085755, lng: 19.1090195 },
    { lat: 49.00922, lng: 19.111312 },
    { lat: 49.00943, lng: 19.111613 },
    { lat: 49.009821, lng: 19.11153 },
    { lat: 49.011066, lng: 19.111383 },
    { lat: 49.011901, lng: 19.111113 },
    { lat: 49.012554, lng: 19.111041 },
    { lat: 49.012738, lng: 19.111103 },
    { lat: 49.013202, lng: 19.111205 },
    { lat: 49.013918, lng: 19.111568 },
    { lat: 49.014407, lng: 19.111876 },
    { lat: 49.014671, lng: 19.112078 },
    { lat: 49.014913, lng: 19.112317 },
    { lat: 49.015166, lng: 19.112458 },
    { lat: 49.015429, lng: 19.112591 },
    { lat: 49.015865, lng: 19.112634 },
    { lat: 49.016277, lng: 19.112676 },
    { lat: 49.016713, lng: 19.1125 },
    { lat: 49.0173412, lng: 19.1121485 },
    { lat: 49.0176254, lng: 19.1122656 },
    { lat: 49.017951, lng: 19.11232 },
    { lat: 49.018576, lng: 19.112609 },
    { lat: 49.0187108, lng: 19.1126475 },
    { lat: 49.019186, lng: 19.112783 },
    { lat: 49.019716, lng: 19.112865 },
    { lat: 49.0200215, lng: 19.1129934 },
    { lat: 49.0202311, lng: 19.1130275 },
    { lat: 49.020646, lng: 19.113107 },
    { lat: 49.021249, lng: 19.113176 },
    { lat: 49.021808, lng: 19.112985 },
    { lat: 49.022428, lng: 19.112707 },
    { lat: 49.022911, lng: 19.112568 },
    { lat: 49.023339, lng: 19.112474 },
    { lat: 49.024132, lng: 19.112324 },
    { lat: 49.024373, lng: 19.112282 },
    { lat: 49.024859, lng: 19.111978 },
    { lat: 49.02499, lng: 19.1118454 },
    { lat: 49.025546, lng: 19.111283 },
    { lat: 49.026091, lng: 19.111402 },
    { lat: 49.026463, lng: 19.111386 },
    { lat: 49.0265461, lng: 19.1114487 },
    { lat: 49.0268286, lng: 19.1115305 },
    { lat: 49.027999, lng: 19.111993 },
    { lat: 49.028546, lng: 19.112377 },
    { lat: 49.029228, lng: 19.112966 },
    { lat: 49.029834, lng: 19.111782 },
    { lat: 49.030095, lng: 19.111272 },
    { lat: 49.030182, lng: 19.111102 },
    { lat: 49.0303698, lng: 19.1107285 },
    { lat: 49.0304366, lng: 19.1105086 },
    { lat: 49.0305052, lng: 19.1102752 },
    { lat: 49.0306314, lng: 19.1099243 },
    { lat: 49.0307918, lng: 19.1093364 },
    { lat: 49.030844, lng: 19.109377 },
    { lat: 49.0309609, lng: 19.1090018 },
    { lat: 49.0310152, lng: 19.1089019 },
    { lat: 49.0310873, lng: 19.108808 },
    { lat: 49.031107, lng: 19.107969 },
    { lat: 49.031457, lng: 19.108371 },
    { lat: 49.0317046, lng: 19.1085345 },
    { lat: 49.031803, lng: 19.1086578 },
    { lat: 49.0319015, lng: 19.1087195 },
    { lat: 49.0322233, lng: 19.1089421 },
    { lat: 49.032191, lng: 19.108951 },
    { lat: 49.032748, lng: 19.109361 },
    { lat: 49.033205, lng: 19.109282 },
    { lat: 49.034097, lng: 19.110718 },
    { lat: 49.034556, lng: 19.111438 },
    { lat: 49.0347166, lng: 19.1116208 },
    { lat: 49.035078, lng: 19.112135 },
    { lat: 49.035558, lng: 19.112918 },
    { lat: 49.037335, lng: 19.11275 },
    { lat: 49.0379493, lng: 19.1123009 },
    { lat: 49.038309, lng: 19.112038 },
    { lat: 49.038322, lng: 19.112028 },
    { lat: 49.03886, lng: 19.112246 },
    { lat: 49.039813, lng: 19.111587 },
    { lat: 49.040125, lng: 19.111503 },
    { lat: 49.040741, lng: 19.1114607 },
    { lat: 49.0407938, lng: 19.1114634 },
    { lat: 49.0408694, lng: 19.1114366 },
    { lat: 49.0409063, lng: 19.1114178 },
    { lat: 49.0409379, lng: 19.1113722 },
    { lat: 49.0409379, lng: 19.1112596 },
    { lat: 49.041434, lng: 19.111076 },
    { lat: 49.041748, lng: 19.110852 },
    { lat: 49.0422777, lng: 19.1104925 },
    { lat: 49.0424781, lng: 19.1104281 },
    { lat: 49.0426416, lng: 19.1103154 },
    { lat: 49.042684, lng: 19.110185 },
    { lat: 49.0428491, lng: 19.1101652 },
    { lat: 49.042967, lng: 19.109993 },
    { lat: 49.0430882, lng: 19.1102833 },
    { lat: 49.0444273, lng: 19.1115099 },
    { lat: 49.044673, lng: 19.111765 },
    { lat: 49.0447215, lng: 19.1118014 },
    { lat: 49.044887, lng: 19.111991 },
    { lat: 49.0451329, lng: 19.1119677 },
    { lat: 49.04527, lng: 19.112005 },
    { lat: 49.0453755, lng: 19.1120535 },
    { lat: 49.045446, lng: 19.112137 },
    { lat: 49.0457231, lng: 19.112267 },
    { lat: 49.04575, lng: 19.1122796 },
    { lat: 49.0458801, lng: 19.1123995 },
    { lat: 49.0462423, lng: 19.1124853 },
    { lat: 49.0464408, lng: 19.1123307 },
    { lat: 49.046602, lng: 19.1122407 },
    { lat: 49.0465358, lng: 19.1119306 },
    { lat: 49.0468037, lng: 19.1116231 },
    { lat: 49.0469765, lng: 19.1112667 },
    { lat: 49.0469718, lng: 19.1104846 },
    { lat: 49.0469152, lng: 19.1091535 },
    { lat: 49.0474783, lng: 19.1088399 },
    { lat: 49.0476182, lng: 19.108732 },
    { lat: 49.0478066, lng: 19.1085283 },
    { lat: 49.0483883, lng: 19.1078874 },
    { lat: 49.0485113, lng: 19.107753 },
    { lat: 49.049193, lng: 19.1067888 },
    { lat: 49.0493358, lng: 19.1063197 },
    { lat: 49.0493754, lng: 19.1051778 },
    { lat: 49.0495901, lng: 19.1040705 },
    { lat: 49.0496556, lng: 19.1039139 },
    { lat: 49.0496863, lng: 19.1038277 },
    { lat: 49.0498275, lng: 19.1037397 },
    { lat: 49.0500498, lng: 19.1033079 },
    { lat: 49.0502626, lng: 19.1029872 },
    { lat: 49.0503859, lng: 19.1026511 },
    { lat: 49.0504365, lng: 19.1025142 },
    { lat: 49.050744, lng: 19.1011127 },
    { lat: 49.0507703, lng: 19.1007985 },
    { lat: 49.0508274, lng: 19.100102 },
    { lat: 49.0507535, lng: 19.0999551 },
    { lat: 49.0505299, lng: 19.099511 },
    { lat: 49.0506964, lng: 19.0993238 },
    { lat: 49.0510168, lng: 19.0989619 },
    { lat: 49.0511983, lng: 19.0987569 },
    { lat: 49.0514935, lng: 19.0977938 },
    { lat: 49.0514622, lng: 19.0972497 },
    { lat: 49.0514794, lng: 19.0971866 },
    { lat: 49.0514971, lng: 19.0971159 },
    { lat: 49.051712, lng: 19.096281 },
    { lat: 49.0518536, lng: 19.0958612 },
    { lat: 49.0519057, lng: 19.0957791 },
    { lat: 49.0522074, lng: 19.0953112 },
    { lat: 49.0524314, lng: 19.0949628 },
    { lat: 49.0528618, lng: 19.0943893 },
    { lat: 49.0530815, lng: 19.0935843 },
    { lat: 49.0533274, lng: 19.0929053 },
    { lat: 49.0535276, lng: 19.0923236 },
    { lat: 49.0539763, lng: 19.0913651 },
    { lat: 49.0546774, lng: 19.0901564 },
    { lat: 49.0548669, lng: 19.0897261 },
    { lat: 49.0549348, lng: 19.0895717 },
    { lat: 49.0554783, lng: 19.0879533 },
    { lat: 49.0555169, lng: 19.0871628 },
    { lat: 49.0563041, lng: 19.0856638 },
    { lat: 49.0564316, lng: 19.0851625 },
    { lat: 49.0565468, lng: 19.0847101 },
    { lat: 49.0565569, lng: 19.0839829 },
    { lat: 49.0566765, lng: 19.0831645 },
    { lat: 49.0566858, lng: 19.0829413 },
    { lat: 49.0567446, lng: 19.0814641 },
    { lat: 49.0567434, lng: 19.0810071 },
    { lat: 49.0565104, lng: 19.0795522 },
    { lat: 49.056432, lng: 19.0789045 },
    { lat: 49.0564013, lng: 19.0784648 },
    { lat: 49.05636, lng: 19.0778851 },
    { lat: 49.0563689, lng: 19.0770858 },
    { lat: 49.0566897, lng: 19.0765699 },
    { lat: 49.0568442, lng: 19.0765515 },
    { lat: 49.0574385, lng: 19.075464 },
    { lat: 49.0577768, lng: 19.0742571 },
    { lat: 49.0579752, lng: 19.0738278 },
    { lat: 49.058689, lng: 19.0723049 },
    { lat: 49.0587604, lng: 19.0717687 },
    { lat: 49.058604, lng: 19.0709761 },
    { lat: 49.058757, lng: 19.0706551 },
    { lat: 49.058869, lng: 19.0700934 },
    { lat: 49.0590501, lng: 19.069564 },
    { lat: 49.0591894, lng: 19.0693036 },
    { lat: 49.0591628, lng: 19.0686733 },
    { lat: 49.0591505, lng: 19.0682904 },
    { lat: 49.0591806, lng: 19.0678075 },
    { lat: 49.0591936, lng: 19.0675935 },
    { lat: 49.0591897, lng: 19.0673496 },
    { lat: 49.0591771, lng: 19.0665742 },
    { lat: 49.0591412, lng: 19.0664013 },
    { lat: 49.0590688, lng: 19.0660532 },
    { lat: 49.0588528, lng: 19.0651751 },
    { lat: 49.058667, lng: 19.0627684 },
    { lat: 49.0589516, lng: 19.0619866 },
    { lat: 49.059148, lng: 19.0615373 },
    { lat: 49.0593324, lng: 19.0609597 },
    { lat: 49.0595806, lng: 19.0605764 },
  ],
  Sklabiňa: [
    { lat: 49.0610045, lng: 18.979988 },
    { lat: 49.0593931, lng: 18.9791315 },
    { lat: 49.0580127, lng: 18.9783603 },
    { lat: 49.0573957, lng: 18.9786249 },
    { lat: 49.0571651, lng: 18.9788118 },
    { lat: 49.0568184, lng: 18.9790485 },
    { lat: 49.0555304, lng: 18.9800247 },
    { lat: 49.0545328, lng: 18.9808095 },
    { lat: 49.0542733, lng: 18.9804235 },
    { lat: 49.0507253, lng: 18.9770841 },
    { lat: 49.0484822, lng: 18.974973 },
    { lat: 49.0483803, lng: 18.974178 },
    { lat: 49.0483902, lng: 18.9736352 },
    { lat: 49.0479083, lng: 18.9737152 },
    { lat: 49.0470653, lng: 18.9738361 },
    { lat: 49.0469772, lng: 18.9736764 },
    { lat: 49.0467686, lng: 18.9734287 },
    { lat: 49.0463604, lng: 18.9731141 },
    { lat: 49.0457073, lng: 18.9726346 },
    { lat: 49.0455612, lng: 18.9724688 },
    { lat: 49.0454636, lng: 18.9722757 },
    { lat: 49.0452807, lng: 18.9716012 },
    { lat: 49.0452395, lng: 18.9716542 },
    { lat: 49.0452546, lng: 18.9718425 },
    { lat: 49.0452065, lng: 18.9718482 },
    { lat: 49.045209, lng: 18.9717203 },
    { lat: 49.0445384, lng: 18.9729777 },
    { lat: 49.0440205, lng: 18.9740864 },
    { lat: 49.0433253, lng: 18.9755928 },
    { lat: 49.0431085, lng: 18.9761251 },
    { lat: 49.042914, lng: 18.9769858 },
    { lat: 49.0425752, lng: 18.9786971 },
    { lat: 49.0425574, lng: 18.9787887 },
    { lat: 49.0425212, lng: 18.9789668 },
    { lat: 49.0424005, lng: 18.9793693 },
    { lat: 49.042309, lng: 18.9795421 },
    { lat: 49.0422679, lng: 18.9796216 },
    { lat: 49.0422329, lng: 18.9796879 },
    { lat: 49.0417308, lng: 18.9805531 },
    { lat: 49.0416935, lng: 18.9806204 },
    { lat: 49.0413557, lng: 18.9812121 },
    { lat: 49.0406966, lng: 18.9823611 },
    { lat: 49.0404723, lng: 18.9826963 },
    { lat: 49.0404349, lng: 18.982784 },
    { lat: 49.0403472, lng: 18.9829423 },
    { lat: 49.0403027, lng: 18.9830689 },
    { lat: 49.0401251, lng: 18.9836365 },
    { lat: 49.0400817, lng: 18.9845213 },
    { lat: 49.0400584, lng: 18.9851137 },
    { lat: 49.0400313, lng: 18.985852 },
    { lat: 49.0401367, lng: 18.9865103 },
    { lat: 49.0401833, lng: 18.9866767 },
    { lat: 49.0405796, lng: 18.9880557 },
    { lat: 49.040704, lng: 18.9885328 },
    { lat: 49.0407143, lng: 18.9893667 },
    { lat: 49.0406865, lng: 18.9901393 },
    { lat: 49.0406272, lng: 18.9903683 },
    { lat: 49.0405018, lng: 18.9912381 },
    { lat: 49.0404825, lng: 18.9914851 },
    { lat: 49.0405057, lng: 18.9916739 },
    { lat: 49.0405193, lng: 18.9917895 },
    { lat: 49.0405323, lng: 18.9920244 },
    { lat: 49.0406035, lng: 18.9924776 },
    { lat: 49.0408334, lng: 18.9937337 },
    { lat: 49.0409622, lng: 18.9939836 },
    { lat: 49.040937, lng: 18.9940221 },
    { lat: 49.0409222, lng: 18.9940443 },
    { lat: 49.0409172, lng: 18.9940517 },
    { lat: 49.0408961, lng: 18.9940843 },
    { lat: 49.0409445, lng: 18.9947546 },
    { lat: 49.0410052, lng: 18.9955823 },
    { lat: 49.0406675, lng: 18.9959292 },
    { lat: 49.0402031, lng: 18.9971194 },
    { lat: 49.0399716, lng: 18.9975857 },
    { lat: 49.0393645, lng: 18.9981561 },
    { lat: 49.0392173, lng: 18.998705 },
    { lat: 49.039116, lng: 18.9994502 },
    { lat: 49.0389616, lng: 19.0001012 },
    { lat: 49.0392167, lng: 19.0016786 },
    { lat: 49.0392153, lng: 19.0021986 },
    { lat: 49.0392556, lng: 19.0049253 },
    { lat: 49.0393189, lng: 19.005674 },
    { lat: 49.0395928, lng: 19.0070896 },
    { lat: 49.0396596, lng: 19.0083765 },
    { lat: 49.0394507, lng: 19.0096286 },
    { lat: 49.0399081, lng: 19.0111757 },
    { lat: 49.0395331, lng: 19.011632 },
    { lat: 49.0394509, lng: 19.0123103 },
    { lat: 49.0393736, lng: 19.012795 },
    { lat: 49.0392744, lng: 19.0132438 },
    { lat: 49.0389895, lng: 19.0139717 },
    { lat: 49.0382902, lng: 19.0152245 },
    { lat: 49.0379757, lng: 19.015701 },
    { lat: 49.0377362, lng: 19.0167485 },
    { lat: 49.0374236, lng: 19.0175738 },
    { lat: 49.0372231, lng: 19.018053 },
    { lat: 49.0371677, lng: 19.0190593 },
    { lat: 49.0367143, lng: 19.0201581 },
    { lat: 49.0363995, lng: 19.0210824 },
    { lat: 49.0360134, lng: 19.0226674 },
    { lat: 49.0360217, lng: 19.0229269 },
    { lat: 49.0364429, lng: 19.0231405 },
    { lat: 49.0366436, lng: 19.0244942 },
    { lat: 49.0366401, lng: 19.0245136 },
    { lat: 49.0366277, lng: 19.0245833 },
    { lat: 49.0366181, lng: 19.0246836 },
    { lat: 49.036779, lng: 19.0249071 },
    { lat: 49.036954, lng: 19.0255193 },
    { lat: 49.0372233, lng: 19.0263282 },
    { lat: 49.0375086, lng: 19.026811 },
    { lat: 49.0375779, lng: 19.0269283 },
    { lat: 49.0376653, lng: 19.0270522 },
    { lat: 49.0376483, lng: 19.027094 },
    { lat: 49.0375355, lng: 19.0273658 },
    { lat: 49.0373766, lng: 19.0275904 },
    { lat: 49.0371669, lng: 19.0278874 },
    { lat: 49.0371238, lng: 19.0279484 },
    { lat: 49.0370626, lng: 19.0280612 },
    { lat: 49.0368493, lng: 19.0284544 },
    { lat: 49.0367131, lng: 19.0287978 },
    { lat: 49.0365197, lng: 19.0290785 },
    { lat: 49.0363482, lng: 19.0294615 },
    { lat: 49.0362425, lng: 19.0296671 },
    { lat: 49.035759, lng: 19.030596 },
    { lat: 49.0353673, lng: 19.0316469 },
    { lat: 49.0351023, lng: 19.0319836 },
    { lat: 49.0348759, lng: 19.031897 },
    { lat: 49.034787, lng: 19.0328504 },
    { lat: 49.0347384, lng: 19.0333212 },
    { lat: 49.0345527, lng: 19.0342524 },
    { lat: 49.0343094, lng: 19.0349815 },
    { lat: 49.0339012, lng: 19.0355639 },
    { lat: 49.0337995, lng: 19.0356525 },
    { lat: 49.0334798, lng: 19.0358399 },
    { lat: 49.0329552, lng: 19.03606 },
    { lat: 49.0323694, lng: 19.0361369 },
    { lat: 49.0323474, lng: 19.0361599 },
    { lat: 49.0321592, lng: 19.0361935 },
    { lat: 49.0319248, lng: 19.0362881 },
    { lat: 49.0317596, lng: 19.0364402 },
    { lat: 49.0315769, lng: 19.0366082 },
    { lat: 49.0315271, lng: 19.0366541 },
    { lat: 49.0312135, lng: 19.037079 },
    { lat: 49.0307362, lng: 19.0376593 },
    { lat: 49.030544, lng: 19.0379405 },
    { lat: 49.0303726, lng: 19.0382125 },
    { lat: 49.0299866, lng: 19.0390362 },
    { lat: 49.0297294, lng: 19.0395297 },
    { lat: 49.029467, lng: 19.0399188 },
    { lat: 49.029277, lng: 19.0401354 },
    { lat: 49.0290395, lng: 19.0403004 },
    { lat: 49.0286765, lng: 19.0404816 },
    { lat: 49.0281012, lng: 19.0406794 },
    { lat: 49.0273055, lng: 19.0407496 },
    { lat: 49.0265507, lng: 19.0405349 },
    { lat: 49.0260291, lng: 19.0403356 },
    { lat: 49.0253467, lng: 19.040262 },
    { lat: 49.0250869, lng: 19.0403624 },
    { lat: 49.0250342, lng: 19.0404151 },
    { lat: 49.0246296, lng: 19.0408221 },
    { lat: 49.0244598, lng: 19.0411746 },
    { lat: 49.0242937, lng: 19.0415239 },
    { lat: 49.0240185, lng: 19.0422507 },
    { lat: 49.0239163, lng: 19.0431616 },
    { lat: 49.0239883, lng: 19.0453753 },
    { lat: 49.0236598, lng: 19.0454577 },
    { lat: 49.0236153, lng: 19.0454689 },
    { lat: 49.0227288, lng: 19.0464572 },
    { lat: 49.0211714, lng: 19.0484788 },
    { lat: 49.0211649, lng: 19.0484871 },
    { lat: 49.0211324, lng: 19.0485333 },
    { lat: 49.0210748, lng: 19.0486041 },
    { lat: 49.0213437, lng: 19.048616 },
    { lat: 49.0214465, lng: 19.0486206 },
    { lat: 49.02157, lng: 19.0486636 },
    { lat: 49.0218053, lng: 19.0487455 },
    { lat: 49.021953, lng: 19.0487969 },
    { lat: 49.0220329, lng: 19.0488247 },
    { lat: 49.0221718, lng: 19.0489439 },
    { lat: 49.022213, lng: 19.0489792 },
    { lat: 49.0222567, lng: 19.0490392 },
    { lat: 49.022587, lng: 19.0494929 },
    { lat: 49.0229928, lng: 19.0501863 },
    { lat: 49.023113, lng: 19.0503918 },
    { lat: 49.0231993, lng: 19.0505532 },
    { lat: 49.0234437, lng: 19.05101 },
    { lat: 49.0235534, lng: 19.0512803 },
    { lat: 49.0237359, lng: 19.0517298 },
    { lat: 49.0237674, lng: 19.0518713 },
    { lat: 49.0237847, lng: 19.05205 },
    { lat: 49.0237777, lng: 19.0522287 },
    { lat: 49.0237404, lng: 19.052392 },
    { lat: 49.0237234, lng: 19.0525676 },
    { lat: 49.023642, lng: 19.0527304 },
    { lat: 49.0235924, lng: 19.0528649 },
    { lat: 49.0234848, lng: 19.0530852 },
    { lat: 49.0233624, lng: 19.0533337 },
    { lat: 49.0232193, lng: 19.05367 },
    { lat: 49.023039, lng: 19.0540089 },
    { lat: 49.0228366, lng: 19.0543514 },
    { lat: 49.0226065, lng: 19.054872 },
    { lat: 49.0224928, lng: 19.0551105 },
    { lat: 49.0224061, lng: 19.0552512 },
    { lat: 49.022365, lng: 19.0554241 },
    { lat: 49.022597, lng: 19.0563115 },
    { lat: 49.0224301, lng: 19.0568328 },
    { lat: 49.0222852, lng: 19.0570926 },
    { lat: 49.0216811, lng: 19.0590018 },
    { lat: 49.0214822, lng: 19.0594675 },
    { lat: 49.0214114, lng: 19.0605385 },
    { lat: 49.0213573, lng: 19.0609898 },
    { lat: 49.021241, lng: 19.0611216 },
    { lat: 49.0210281, lng: 19.0610822 },
    { lat: 49.0209569, lng: 19.0614833 },
    { lat: 49.020596, lng: 19.0630918 },
    { lat: 49.0206177, lng: 19.063733 },
    { lat: 49.0205588, lng: 19.0645457 },
    { lat: 49.0205544, lng: 19.0650042 },
    { lat: 49.0204441, lng: 19.065371 },
    { lat: 49.0208944, lng: 19.0656049 },
    { lat: 49.0212196, lng: 19.0657737 },
    { lat: 49.0216709, lng: 19.0661202 },
    { lat: 49.0217714, lng: 19.0661974 },
    { lat: 49.0220045, lng: 19.0663255 },
    { lat: 49.0223564, lng: 19.0665333 },
    { lat: 49.0227572, lng: 19.0664554 },
    { lat: 49.0229626, lng: 19.066509 },
    { lat: 49.0232548, lng: 19.0665096 },
    { lat: 49.0232719, lng: 19.0674926 },
    { lat: 49.0237398, lng: 19.0676537 },
    { lat: 49.024198, lng: 19.0683224 },
    { lat: 49.0243054, lng: 19.06842 },
    { lat: 49.0246408, lng: 19.0680431 },
    { lat: 49.0249365, lng: 19.0680279 },
    { lat: 49.0251601, lng: 19.0679685 },
    { lat: 49.0255731, lng: 19.0676571 },
    { lat: 49.0263495, lng: 19.0672221 },
    { lat: 49.0267219, lng: 19.0669329 },
    { lat: 49.0269707, lng: 19.066631 },
    { lat: 49.0272694, lng: 19.0664713 },
    { lat: 49.0279221, lng: 19.0648568 },
    { lat: 49.0285438, lng: 19.063938 },
    { lat: 49.0288059, lng: 19.0632469 },
    { lat: 49.0289323, lng: 19.0627711 },
    { lat: 49.029058, lng: 19.0620824 },
    { lat: 49.0291056, lng: 19.0618615 },
    { lat: 49.029125, lng: 19.0617715 },
    { lat: 49.0292916, lng: 19.0609972 },
    { lat: 49.0294913, lng: 19.060457 },
    { lat: 49.0295692, lng: 19.0600952 },
    { lat: 49.0299141, lng: 19.0592293 },
    { lat: 49.0302591, lng: 19.0586903 },
    { lat: 49.0306293, lng: 19.0579915 },
    { lat: 49.0310677, lng: 19.0576716 },
    { lat: 49.031228, lng: 19.0574757 },
    { lat: 49.0314985, lng: 19.0571536 },
    { lat: 49.0319729, lng: 19.0569443 },
    { lat: 49.0322826, lng: 19.0564653 },
    { lat: 49.0327156, lng: 19.0561033 },
    { lat: 49.0329053, lng: 19.0560653 },
    { lat: 49.0332163, lng: 19.0559265 },
    { lat: 49.0333354, lng: 19.0557849 },
    { lat: 49.0334889, lng: 19.0556034 },
    { lat: 49.0337923, lng: 19.055243 },
    { lat: 49.0339145, lng: 19.0551165 },
    { lat: 49.0341238, lng: 19.0549976 },
    { lat: 49.0344466, lng: 19.0547348 },
    { lat: 49.0345687, lng: 19.0546949 },
    { lat: 49.0346993, lng: 19.0546238 },
    { lat: 49.0349956, lng: 19.0544166 },
    { lat: 49.0354841, lng: 19.0542404 },
    { lat: 49.0359922, lng: 19.0544141 },
    { lat: 49.0365216, lng: 19.0545432 },
    { lat: 49.0365694, lng: 19.0543502 },
    { lat: 49.0373231, lng: 19.0531604 },
    { lat: 49.0374652, lng: 19.0529346 },
    { lat: 49.0377919, lng: 19.0524321 },
    { lat: 49.0380241, lng: 19.051976 },
    { lat: 49.0383713, lng: 19.0508207 },
    { lat: 49.038502, lng: 19.0504367 },
    { lat: 49.0386223, lng: 19.0496607 },
    { lat: 49.0388637, lng: 19.0485536 },
    { lat: 49.0393408, lng: 19.0480815 },
    { lat: 49.0395495, lng: 19.0482637 },
    { lat: 49.0397714, lng: 19.0481469 },
    { lat: 49.0398776, lng: 19.0476998 },
    { lat: 49.0406682, lng: 19.0481169 },
    { lat: 49.0405183, lng: 19.0482516 },
    { lat: 49.0407481, lng: 19.0483391 },
    { lat: 49.0410845, lng: 19.0483492 },
    { lat: 49.0415804, lng: 19.0482982 },
    { lat: 49.0419695, lng: 19.0483468 },
    { lat: 49.0421495, lng: 19.0484325 },
    { lat: 49.0423089, lng: 19.0485079 },
    { lat: 49.0424662, lng: 19.0486342 },
    { lat: 49.042618, lng: 19.0488741 },
    { lat: 49.0426543, lng: 19.0489917 },
    { lat: 49.0428757, lng: 19.048955 },
    { lat: 49.043291, lng: 19.0488789 },
    { lat: 49.0434118, lng: 19.0488567 },
    { lat: 49.0437408, lng: 19.0488511 },
    { lat: 49.0440412, lng: 19.0487085 },
    { lat: 49.0442164, lng: 19.0486253 },
    { lat: 49.044314, lng: 19.0485597 },
    { lat: 49.0446942, lng: 19.048304 },
    { lat: 49.0450804, lng: 19.0476824 },
    { lat: 49.0453394, lng: 19.0468149 },
    { lat: 49.0453738, lng: 19.0457479 },
    { lat: 49.0453841, lng: 19.0454276 },
    { lat: 49.0454872, lng: 19.0451612 },
    { lat: 49.0455189, lng: 19.0450793 },
    { lat: 49.0455064, lng: 19.0449619 },
    { lat: 49.0454407, lng: 19.0443427 },
    { lat: 49.0455006, lng: 19.043847 },
    { lat: 49.0455751, lng: 19.0432196 },
    { lat: 49.045598, lng: 19.0428874 },
    { lat: 49.0455947, lng: 19.042826 },
    { lat: 49.0455715, lng: 19.0423889 },
    { lat: 49.045549, lng: 19.0419652 },
    { lat: 49.0455267, lng: 19.0415452 },
    { lat: 49.0453682, lng: 19.0413744 },
    { lat: 49.045362, lng: 19.0411135 },
    { lat: 49.0452948, lng: 19.0406601 },
    { lat: 49.0452603, lng: 19.0404277 },
    { lat: 49.0455682, lng: 19.0392354 },
    { lat: 49.0457632, lng: 19.0384849 },
    { lat: 49.0456792, lng: 19.0380892 },
    { lat: 49.0456474, lng: 19.0377687 },
    { lat: 49.0456063, lng: 19.0373519 },
    { lat: 49.0452304, lng: 19.0361231 },
    { lat: 49.0453561, lng: 19.0357018 },
    { lat: 49.045435, lng: 19.0354329 },
    { lat: 49.0455149, lng: 19.035225 },
    { lat: 49.0456048, lng: 19.0350852 },
    { lat: 49.046071, lng: 19.0340557 },
    { lat: 49.0460602, lng: 19.0338212 },
    { lat: 49.0462185, lng: 19.0332858 },
    { lat: 49.0458557, lng: 19.0317522 },
    { lat: 49.0464802, lng: 19.0251387 },
    { lat: 49.0470919, lng: 19.0231881 },
    { lat: 49.0479077, lng: 19.0198919 },
    { lat: 49.0484653, lng: 19.0202191 },
    { lat: 49.0485712, lng: 19.0200602 },
    { lat: 49.0489402, lng: 19.0192994 },
    { lat: 49.0491321, lng: 19.0189893 },
    { lat: 49.0495532, lng: 19.0183501 },
    { lat: 49.0502372, lng: 19.0179363 },
    { lat: 49.0503143, lng: 19.0178346 },
    { lat: 49.0505656, lng: 19.0169311 },
    { lat: 49.050461, lng: 19.0164167 },
    { lat: 49.050355, lng: 19.0161217 },
    { lat: 49.050182, lng: 19.0151907 },
    { lat: 49.0501406, lng: 19.0146787 },
    { lat: 49.0501635, lng: 19.0146606 },
    { lat: 49.05021, lng: 19.0146218 },
    { lat: 49.050314, lng: 19.0145378 },
    { lat: 49.0510299, lng: 19.0133461 },
    { lat: 49.0511897, lng: 19.0135875 },
    { lat: 49.0527198, lng: 19.0115444 },
    { lat: 49.0530463, lng: 19.0109699 },
    { lat: 49.0535277, lng: 19.0103393 },
    { lat: 49.0549092, lng: 19.0085297 },
    { lat: 49.0552353, lng: 19.0079714 },
    { lat: 49.0554392, lng: 19.006685 },
    { lat: 49.0555793, lng: 19.0063327 },
    { lat: 49.0557083, lng: 19.0062116 },
    { lat: 49.0562821, lng: 19.0054646 },
    { lat: 49.0567157, lng: 19.0050516 },
    { lat: 49.057146, lng: 19.0046383 },
    { lat: 49.0576627, lng: 19.0037267 },
    { lat: 49.0578915, lng: 19.003432 },
    { lat: 49.0581894, lng: 19.0032105 },
    { lat: 49.0589637, lng: 19.0027148 },
    { lat: 49.0592931, lng: 19.0022919 },
    { lat: 49.0592447, lng: 19.002226 },
    { lat: 49.058507, lng: 19.0008482 },
    { lat: 49.0585329, lng: 19.0000123 },
    { lat: 49.0579884, lng: 18.9983081 },
    { lat: 49.0578274, lng: 18.998174 },
    { lat: 49.0577334, lng: 18.998058 },
    { lat: 49.0576801, lng: 18.997781 },
    { lat: 49.0576846, lng: 18.9971046 },
    { lat: 49.0577918, lng: 18.9968168 },
    { lat: 49.0579186, lng: 18.996604 },
    { lat: 49.0580832, lng: 18.9964889 },
    { lat: 49.0587132, lng: 18.9959006 },
    { lat: 49.0591178, lng: 18.9955404 },
    { lat: 49.0593681, lng: 18.9946863 },
    { lat: 49.0595133, lng: 18.9944354 },
    { lat: 49.0595487, lng: 18.9937487 },
    { lat: 49.0596314, lng: 18.9930625 },
    { lat: 49.0602649, lng: 18.9918226 },
    { lat: 49.0603948, lng: 18.989519 },
    { lat: 49.0606211, lng: 18.9886817 },
    { lat: 49.0606906, lng: 18.9882501 },
    { lat: 49.0609624, lng: 18.9882943 },
    { lat: 49.0614729, lng: 18.9882015 },
    { lat: 49.0613846, lng: 18.9873899 },
    { lat: 49.0613059, lng: 18.9871676 },
    { lat: 49.0614046, lng: 18.9863497 },
    { lat: 49.0615895, lng: 18.9855675 },
    { lat: 49.0613512, lng: 18.9838597 },
    { lat: 49.0610045, lng: 18.979988 },
  ],
  Slovany: [
    { lat: 48.977289, lng: 18.8776992 },
    { lat: 48.9768867, lng: 18.8772691 },
    { lat: 48.9766475, lng: 18.8769999 },
    { lat: 48.9765939, lng: 18.8767474 },
    { lat: 48.9773637, lng: 18.8756652 },
    { lat: 48.9773382, lng: 18.8753667 },
    { lat: 48.9767904, lng: 18.8748857 },
    { lat: 48.976404, lng: 18.8751795 },
    { lat: 48.975826, lng: 18.8745287 },
    { lat: 48.975727, lng: 18.8740918 },
    { lat: 48.9751546, lng: 18.8727404 },
    { lat: 48.9752039, lng: 18.8724496 },
    { lat: 48.9753916, lng: 18.8713847 },
    { lat: 48.9750946, lng: 18.8684552 },
    { lat: 48.9747942, lng: 18.8654849 },
    { lat: 48.9745338, lng: 18.8652915 },
    { lat: 48.9742428, lng: 18.8650923 },
    { lat: 48.9737903, lng: 18.8647975 },
    { lat: 48.9737936, lng: 18.8647738 },
    { lat: 48.9732109, lng: 18.8643638 },
    { lat: 48.9726551, lng: 18.8639923 },
    { lat: 48.9726044, lng: 18.8641674 },
    { lat: 48.9722613, lng: 18.8638556 },
    { lat: 48.9723899, lng: 18.8635949 },
    { lat: 48.9724806, lng: 18.8634276 },
    { lat: 48.9726425, lng: 18.8634841 },
    { lat: 48.9725523, lng: 18.86068 },
    { lat: 48.9722827, lng: 18.8580963 },
    { lat: 48.9714323, lng: 18.85124 },
    { lat: 48.9714191, lng: 18.8510496 },
    { lat: 48.9717934, lng: 18.8510032 },
    { lat: 48.9722902, lng: 18.8509736 },
    { lat: 48.9726235, lng: 18.8510082 },
    { lat: 48.9731915, lng: 18.8511542 },
    { lat: 48.9738094, lng: 18.8513305 },
    { lat: 48.9743366, lng: 18.8514089 },
    { lat: 48.9754465, lng: 18.8515022 },
    { lat: 48.9757855, lng: 18.8516069 },
    { lat: 48.9768715, lng: 18.8519785 },
    { lat: 48.9759525, lng: 18.8496395 },
    { lat: 48.9760588, lng: 18.8495568 },
    { lat: 48.9764377, lng: 18.849426 },
    { lat: 48.9777098, lng: 18.8497606 },
    { lat: 48.9776625, lng: 18.8484952 },
    { lat: 48.9776661, lng: 18.8476437 },
    { lat: 48.9776808, lng: 18.8475167 },
    { lat: 48.9776808, lng: 18.846358 },
    { lat: 48.9775922, lng: 18.84573 },
    { lat: 48.9776646, lng: 18.8452091 },
    { lat: 48.9783301, lng: 18.8448876 },
    { lat: 48.979216, lng: 18.8445735 },
    { lat: 48.9796003, lng: 18.8439563 },
    { lat: 48.9797589, lng: 18.8420725 },
    { lat: 48.9796353, lng: 18.8410563 },
    { lat: 48.9803174, lng: 18.8399609 },
    { lat: 48.9809454, lng: 18.8396998 },
    { lat: 48.980963, lng: 18.8390343 },
    { lat: 48.9810222, lng: 18.8389816 },
    { lat: 48.9810476, lng: 18.8389474 },
    { lat: 48.9813428, lng: 18.8383996 },
    { lat: 48.9815682, lng: 18.8376314 },
    { lat: 48.9816812, lng: 18.8367628 },
    { lat: 48.9817207, lng: 18.8366551 },
    { lat: 48.9818117, lng: 18.8364712 },
    { lat: 48.9821243, lng: 18.8363481 },
    { lat: 48.983065, lng: 18.8367019 },
    { lat: 48.9833536, lng: 18.8358519 },
    { lat: 48.9835015, lng: 18.8358985 },
    { lat: 48.9837162, lng: 18.8352985 },
    { lat: 48.9838841, lng: 18.8345497 },
    { lat: 48.9840988, lng: 18.8337303 },
    { lat: 48.9841941, lng: 18.8335039 },
    { lat: 48.984247, lng: 18.8328259 },
    { lat: 48.9844124, lng: 18.8320551 },
    { lat: 48.9841366, lng: 18.8318662 },
    { lat: 48.9841468, lng: 18.8318262 },
    { lat: 48.9851133, lng: 18.8304349 },
    { lat: 48.9863572, lng: 18.8285814 },
    { lat: 48.9862822, lng: 18.8278939 },
    { lat: 48.9867508, lng: 18.8278217 },
    { lat: 48.9869188, lng: 18.8277999 },
    { lat: 48.987337, lng: 18.8277119 },
    { lat: 48.9874711, lng: 18.8277095 },
    { lat: 48.9878124, lng: 18.8278985 },
    { lat: 48.9881108, lng: 18.8279346 },
    { lat: 48.9881642, lng: 18.8271549 },
    { lat: 48.988193, lng: 18.8257753 },
    { lat: 48.98811, lng: 18.8248464 },
    { lat: 48.9881091, lng: 18.8244788 },
    { lat: 48.9882289, lng: 18.8241137 },
    { lat: 48.9883769, lng: 18.8237086 },
    { lat: 48.9887402, lng: 18.8229902 },
    { lat: 48.9890724, lng: 18.8221994 },
    { lat: 48.9893653, lng: 18.8206291 },
    { lat: 48.9895003, lng: 18.8202704 },
    { lat: 48.9901949, lng: 18.8201188 },
    { lat: 48.9907566, lng: 18.8208849 },
    { lat: 48.9910519, lng: 18.8214051 },
    { lat: 48.9913741, lng: 18.8217493 },
    { lat: 48.9919362, lng: 18.8196049 },
    { lat: 48.9923336, lng: 18.8189829 },
    { lat: 48.9925096, lng: 18.8178746 },
    { lat: 48.992857, lng: 18.8170685 },
    { lat: 48.993527, lng: 18.816356 },
    { lat: 48.9939014, lng: 18.8158343 },
    { lat: 48.9941145, lng: 18.8150314 },
    { lat: 48.9947587, lng: 18.8141029 },
    { lat: 48.995067, lng: 18.8133916 },
    { lat: 48.9952384, lng: 18.8131327 },
    { lat: 48.9955539, lng: 18.8128644 },
    { lat: 48.9960328, lng: 18.8123395 },
    { lat: 48.9966845, lng: 18.8116434 },
    { lat: 48.9973038, lng: 18.8109741 },
    { lat: 48.9974536, lng: 18.8105993 },
    { lat: 48.9981898, lng: 18.809401 },
    { lat: 48.9989145, lng: 18.8093275 },
    { lat: 48.9994262, lng: 18.8079137 },
    { lat: 48.9997123, lng: 18.8071256 },
    { lat: 49.0007016, lng: 18.8068296 },
    { lat: 49.0008781, lng: 18.8066655 },
    { lat: 49.000574, lng: 18.8093882 },
    { lat: 49.0008877, lng: 18.8106524 },
    { lat: 49.00083, lng: 18.8101049 },
    { lat: 49.0007912, lng: 18.8095755 },
    { lat: 49.0007965, lng: 18.8090685 },
    { lat: 49.000829, lng: 18.809066 },
    { lat: 49.0009187, lng: 18.8091411 },
    { lat: 49.0016797, lng: 18.8087708 },
    { lat: 49.0016043, lng: 18.8080376 },
    { lat: 49.001587, lng: 18.807572 },
    { lat: 49.001722, lng: 18.807154 },
    { lat: 49.0018206, lng: 18.8064681 },
    { lat: 49.0020338, lng: 18.8055547 },
    { lat: 49.0021663, lng: 18.8050895 },
    { lat: 49.0021891, lng: 18.804463 },
    { lat: 49.0021437, lng: 18.8038938 },
    { lat: 49.0020507, lng: 18.8031687 },
    { lat: 49.0021108, lng: 18.8026339 },
    { lat: 49.0019818, lng: 18.8024461 },
    { lat: 49.0021073, lng: 18.801716 },
    { lat: 49.0022166, lng: 18.8004585 },
    { lat: 49.0021933, lng: 18.7994456 },
    { lat: 49.0020786, lng: 18.7977808 },
    { lat: 49.0019604, lng: 18.797266 },
    { lat: 49.0017961, lng: 18.7967043 },
    { lat: 49.0016035, lng: 18.7957775 },
    { lat: 49.0013775, lng: 18.7944266 },
    { lat: 49.0013213, lng: 18.7936465 },
    { lat: 49.0013367, lng: 18.7933785 },
    { lat: 49.0013265, lng: 18.7930095 },
    { lat: 49.0014259, lng: 18.7922764 },
    { lat: 49.0014875, lng: 18.7920086 },
    { lat: 49.0016215, lng: 18.791573 },
    { lat: 49.0016586, lng: 18.7912687 },
    { lat: 49.0017753, lng: 18.7911264 },
    { lat: 49.0044004, lng: 18.7881379 },
    { lat: 49.0046765, lng: 18.7877858 },
    { lat: 49.0047638, lng: 18.7876877 },
    { lat: 49.0049268, lng: 18.7870291 },
    { lat: 49.005354, lng: 18.7871106 },
    { lat: 49.0057889, lng: 18.7869943 },
    { lat: 49.0060016, lng: 18.7869001 },
    { lat: 49.0063082, lng: 18.7867041 },
    { lat: 49.0065293, lng: 18.7866376 },
    { lat: 49.006801, lng: 18.7866197 },
    { lat: 49.0070088, lng: 18.7866665 },
    { lat: 49.0075528, lng: 18.7871757 },
    { lat: 49.0078693, lng: 18.7876226 },
    { lat: 49.0083141, lng: 18.7877188 },
    { lat: 49.0092368, lng: 18.7880913 },
    { lat: 49.0093976, lng: 18.7879118 },
    { lat: 49.0094452, lng: 18.7875246 },
    { lat: 49.009656, lng: 18.7868124 },
    { lat: 49.0095549, lng: 18.7866848 },
    { lat: 49.0095115, lng: 18.7867404 },
    { lat: 49.009296, lng: 18.786651 },
    { lat: 49.0092673, lng: 18.7863401 },
    { lat: 49.0093317, lng: 18.7861481 },
    { lat: 49.0092323, lng: 18.7860117 },
    { lat: 49.0093178, lng: 18.785926 },
    { lat: 49.0094772, lng: 18.7848942 },
    { lat: 49.0095252, lng: 18.7834348 },
    { lat: 49.0097948, lng: 18.7835202 },
    { lat: 49.0099299, lng: 18.7831705 },
    { lat: 49.0100781, lng: 18.7831796 },
    { lat: 49.0102508, lng: 18.7830105 },
    { lat: 49.0107105, lng: 18.7831234 },
    { lat: 49.0109533, lng: 18.7831831 },
    { lat: 49.0109879, lng: 18.7829358 },
    { lat: 49.0109328, lng: 18.7827908 },
    { lat: 49.0110764, lng: 18.782535 },
    { lat: 49.0110584, lng: 18.7823658 },
    { lat: 49.011105, lng: 18.782223 },
    { lat: 49.0110877, lng: 18.782085 },
    { lat: 49.0111415, lng: 18.7819258 },
    { lat: 49.01115, lng: 18.7816346 },
    { lat: 49.011236, lng: 18.7813814 },
    { lat: 49.0113148, lng: 18.7809875 },
    { lat: 49.0116037, lng: 18.7806927 },
    { lat: 49.0115681, lng: 18.7803938 },
    { lat: 49.0114228, lng: 18.7803538 },
    { lat: 49.0113677, lng: 18.7800544 },
    { lat: 49.0112261, lng: 18.7795229 },
    { lat: 49.0113231, lng: 18.7794153 },
    { lat: 49.0113946, lng: 18.7790787 },
    { lat: 49.0114574, lng: 18.7791179 },
    { lat: 49.011439, lng: 18.7794139 },
    { lat: 49.0118476, lng: 18.7798536 },
    { lat: 49.0118883, lng: 18.7795509 },
    { lat: 49.0119676, lng: 18.77863 },
    { lat: 49.0120062, lng: 18.7775308 },
    { lat: 49.0121702, lng: 18.7770412 },
    { lat: 49.0123296, lng: 18.7770701 },
    { lat: 49.012505, lng: 18.7763061 },
    { lat: 49.0126375, lng: 18.7750192 },
    { lat: 49.012696, lng: 18.774691 },
    { lat: 49.0127313, lng: 18.7745474 },
    { lat: 49.0128688, lng: 18.774072 },
    { lat: 49.0129433, lng: 18.7738781 },
    { lat: 49.0131467, lng: 18.7733668 },
    { lat: 49.0140961, lng: 18.7712923 },
    { lat: 49.0142568, lng: 18.7703733 },
    { lat: 49.0150123, lng: 18.7690342 },
    { lat: 49.0150738, lng: 18.7684832 },
    { lat: 49.0151823, lng: 18.7681224 },
    { lat: 49.0157748, lng: 18.7664051 },
    { lat: 49.0159592, lng: 18.7655127 },
    { lat: 49.0159984, lng: 18.7651415 },
    { lat: 49.0160797, lng: 18.7649467 },
    { lat: 49.0162061, lng: 18.764819 },
    { lat: 49.0162187, lng: 18.7645225 },
    { lat: 49.0167262, lng: 18.7635311 },
    { lat: 49.0167361, lng: 18.7631778 },
    { lat: 49.0171158, lng: 18.7625902 },
    { lat: 49.0173652, lng: 18.7622938 },
    { lat: 49.0175397, lng: 18.7620518 },
    { lat: 49.0175204, lng: 18.7618774 },
    { lat: 49.0179147, lng: 18.7616088 },
    { lat: 49.0179516, lng: 18.7613877 },
    { lat: 49.0180043, lng: 18.7613605 },
    { lat: 49.0180322, lng: 18.7612482 },
    { lat: 49.0180475, lng: 18.7610431 },
    { lat: 49.0181708, lng: 18.7606865 },
    { lat: 49.0184682, lng: 18.7593187 },
    { lat: 49.0189542, lng: 18.7578771 },
    { lat: 49.0192283, lng: 18.7572723 },
    { lat: 49.0194819, lng: 18.7566679 },
    { lat: 49.0197964, lng: 18.7560158 },
    { lat: 49.0200703, lng: 18.7554984 },
    { lat: 49.0202297, lng: 18.7552021 },
    { lat: 49.0208081, lng: 18.7543621 },
    { lat: 49.0212266, lng: 18.7536299 },
    { lat: 49.0214035, lng: 18.7532862 },
    { lat: 49.0216429, lng: 18.7527542 },
    { lat: 49.0218286, lng: 18.7522072 },
    { lat: 49.021903, lng: 18.7518637 },
    { lat: 49.0219586, lng: 18.7515044 },
    { lat: 49.0221007, lng: 18.7513295 },
    { lat: 49.022237, lng: 18.7510935 },
    { lat: 49.0224553, lng: 18.7506736 },
    { lat: 49.0228854, lng: 18.7496273 },
    { lat: 49.0230185, lng: 18.7491768 },
    { lat: 49.0232245, lng: 18.7482417 },
    { lat: 49.0232861, lng: 18.7480661 },
    { lat: 49.0233134, lng: 18.747789 },
    { lat: 49.0232574, lng: 18.7471073 },
    { lat: 49.023166, lng: 18.7465036 },
    { lat: 49.0229752, lng: 18.7456816 },
    { lat: 49.0229355, lng: 18.7452668 },
    { lat: 49.0229305, lng: 18.7448517 },
    { lat: 49.0229414, lng: 18.7446707 },
    { lat: 49.0229805, lng: 18.7442775 },
    { lat: 49.0229719, lng: 18.7440281 },
    { lat: 49.0229135, lng: 18.7438143 },
    { lat: 49.0211697, lng: 18.7438938 },
    { lat: 49.0185154, lng: 18.7440948 },
    { lat: 49.0174823, lng: 18.7433157 },
    { lat: 49.0174168, lng: 18.7432683 },
    { lat: 49.016731, lng: 18.7427497 },
    { lat: 49.016709, lng: 18.7427182 },
    { lat: 49.0151995, lng: 18.7416334 },
    { lat: 49.013812, lng: 18.7409355 },
    { lat: 49.0137636, lng: 18.7408907 },
    { lat: 49.0134613, lng: 18.740742 },
    { lat: 49.0134666, lng: 18.7406717 },
    { lat: 49.013406, lng: 18.74072 },
    { lat: 49.0133625, lng: 18.7415889 },
    { lat: 49.0132542, lng: 18.7418657 },
    { lat: 49.0132132, lng: 18.7422012 },
    { lat: 49.0130904, lng: 18.7418195 },
    { lat: 49.0129836, lng: 18.7408797 },
    { lat: 49.0129278, lng: 18.7403117 },
    { lat: 49.01293, lng: 18.7402457 },
    { lat: 49.0128652, lng: 18.739802 },
    { lat: 49.0127443, lng: 18.7393902 },
    { lat: 49.0127897, lng: 18.7382783 },
    { lat: 49.0127465, lng: 18.7379282 },
    { lat: 49.0125493, lng: 18.7377409 },
    { lat: 49.0120346, lng: 18.7376004 },
    { lat: 49.0112615, lng: 18.7380617 },
    { lat: 49.0107609, lng: 18.7387712 },
    { lat: 49.0104107, lng: 18.7392756 },
    { lat: 49.0102117, lng: 18.7395454 },
    { lat: 49.0096844, lng: 18.7401155 },
    { lat: 49.0091811, lng: 18.7406881 },
    { lat: 49.0091318, lng: 18.7407336 },
    { lat: 49.0089039, lng: 18.7410112 },
    { lat: 49.0087461, lng: 18.7413375 },
    { lat: 49.0085088, lng: 18.742038 },
    { lat: 49.0081975, lng: 18.7424015 },
    { lat: 49.0080396, lng: 18.7428104 },
    { lat: 49.0078872, lng: 18.7432789 },
    { lat: 49.0077616, lng: 18.7434213 },
    { lat: 49.007543, lng: 18.7434245 },
    { lat: 49.0071085, lng: 18.7440217 },
    { lat: 49.0068183, lng: 18.744142 },
    { lat: 49.006625, lng: 18.7441104 },
    { lat: 49.0064591, lng: 18.7441295 },
    { lat: 49.0061238, lng: 18.744359 },
    { lat: 49.0059843, lng: 18.7447082 },
    { lat: 49.0057764, lng: 18.7446604 },
    { lat: 49.0056476, lng: 18.7448679 },
    { lat: 49.00568, lng: 18.74581 },
    { lat: 49.0056571, lng: 18.7468771 },
    { lat: 49.0056986, lng: 18.7483368 },
    { lat: 49.0056893, lng: 18.7485613 },
    { lat: 49.0056643, lng: 18.749166 },
    { lat: 49.0050858, lng: 18.7503922 },
    { lat: 49.003928, lng: 18.751453 },
    { lat: 49.0035745, lng: 18.7515299 },
    { lat: 49.0029062, lng: 18.7524415 },
    { lat: 49.002568, lng: 18.7525754 },
    { lat: 49.0026605, lng: 18.7527894 },
    { lat: 49.0026237, lng: 18.7531585 },
    { lat: 49.0026155, lng: 18.7532665 },
    { lat: 49.0025785, lng: 18.7533669 },
    { lat: 49.0025001, lng: 18.7535796 },
    { lat: 49.0025583, lng: 18.7537029 },
    { lat: 49.0025234, lng: 18.7538767 },
    { lat: 49.0024778, lng: 18.7538671 },
    { lat: 49.002454, lng: 18.753995 },
    { lat: 49.0025257, lng: 18.7540919 },
    { lat: 49.0025317, lng: 18.7542682 },
    { lat: 49.0026686, lng: 18.7543874 },
    { lat: 49.0026834, lng: 18.7545624 },
    { lat: 49.0025056, lng: 18.7547638 },
    { lat: 49.0024777, lng: 18.75507 },
    { lat: 49.0028029, lng: 18.7549717 },
    { lat: 49.0032155, lng: 18.7557213 },
    { lat: 49.0030519, lng: 18.7559591 },
    { lat: 49.0029061, lng: 18.7565539 },
    { lat: 49.0028542, lng: 18.7569179 },
    { lat: 49.0027706, lng: 18.7568964 },
    { lat: 49.002721, lng: 18.7571136 },
    { lat: 49.0029733, lng: 18.7577935 },
    { lat: 49.0029693, lng: 18.7580831 },
    { lat: 49.0032325, lng: 18.7582862 },
    { lat: 49.0034536, lng: 18.7583967 },
    { lat: 49.0034047, lng: 18.7587136 },
    { lat: 49.0033238, lng: 18.7588689 },
    { lat: 49.0032917, lng: 18.7591823 },
    { lat: 49.0031555, lng: 18.7594473 },
    { lat: 49.0029474, lng: 18.759475 },
    { lat: 49.0028616, lng: 18.7594125 },
    { lat: 49.0017192, lng: 18.7583977 },
    { lat: 49.0013613, lng: 18.759111 },
    { lat: 49.0013507, lng: 18.7591321 },
    { lat: 49.0013145, lng: 18.7591541 },
    { lat: 49.0010397, lng: 18.7595687 },
    { lat: 49.0007369, lng: 18.7605704 },
    { lat: 49.0014065, lng: 18.761318 },
    { lat: 49.0012015, lng: 18.7623832 },
    { lat: 49.0015256, lng: 18.7628228 },
    { lat: 49.0017108, lng: 18.7629129 },
    { lat: 49.0019196, lng: 18.762892 },
    { lat: 49.00195, lng: 18.7629993 },
    { lat: 49.0021798, lng: 18.762974 },
    { lat: 49.0025231, lng: 18.7633948 },
    { lat: 49.002509, lng: 18.7635581 },
    { lat: 49.0022072, lng: 18.7636301 },
    { lat: 49.0021632, lng: 18.7639317 },
    { lat: 49.0018389, lng: 18.7639163 },
    { lat: 49.0017974, lng: 18.7642036 },
    { lat: 49.0016519, lng: 18.7641594 },
    { lat: 49.0015683, lng: 18.7640693 },
    { lat: 49.0013821, lng: 18.7636586 },
    { lat: 49.0011808, lng: 18.7635583 },
    { lat: 49.0010551, lng: 18.7640476 },
    { lat: 49.0012466, lng: 18.764171 },
    { lat: 49.0013004, lng: 18.7643595 },
    { lat: 49.0012312, lng: 18.764546 },
    { lat: 49.001108, lng: 18.764498 },
    { lat: 49.0010748, lng: 18.7646364 },
    { lat: 49.0011914, lng: 18.7647555 },
    { lat: 49.0011783, lng: 18.7648498 },
    { lat: 49.0010354, lng: 18.7651978 },
    { lat: 49.0009989, lng: 18.7659686 },
    { lat: 49.0009161, lng: 18.7661977 },
    { lat: 49.0007775, lng: 18.7662863 },
    { lat: 49.0002928, lng: 18.7657522 },
    { lat: 48.9999568, lng: 18.765147 },
    { lat: 48.999698, lng: 18.765137 },
    { lat: 48.9998148, lng: 18.7655125 },
    { lat: 48.9997992, lng: 18.7657568 },
    { lat: 48.9996268, lng: 18.7656954 },
    { lat: 48.9995124, lng: 18.7658985 },
    { lat: 48.9995346, lng: 18.7662363 },
    { lat: 48.9996277, lng: 18.7665084 },
    { lat: 48.9995016, lng: 18.7664292 },
    { lat: 48.9992285, lng: 18.766832 },
    { lat: 48.9992486, lng: 18.7672178 },
    { lat: 48.9995815, lng: 18.7677411 },
    { lat: 48.9996356, lng: 18.7677633 },
    { lat: 48.9999511, lng: 18.7682119 },
    { lat: 48.9999944, lng: 18.7685647 },
    { lat: 49.0001595, lng: 18.7689851 },
    { lat: 49.0001085, lng: 18.7690624 },
    { lat: 49.000145, lng: 18.7693309 },
    { lat: 49.0001914, lng: 18.7696144 },
    { lat: 49.0003632, lng: 18.7695376 },
    { lat: 49.0003567, lng: 18.7693258 },
    { lat: 49.0004437, lng: 18.7693085 },
    { lat: 49.0008753, lng: 18.7706183 },
    { lat: 49.0013404, lng: 18.7713175 },
    { lat: 49.0011545, lng: 18.771861 },
    { lat: 49.0013164, lng: 18.7722197 },
    { lat: 49.0013588, lng: 18.773032 },
    { lat: 49.0016512, lng: 18.7735354 },
    { lat: 49.0013307, lng: 18.7741131 },
    { lat: 49.0010192, lng: 18.7750184 },
    { lat: 49.0009599, lng: 18.775241 },
    { lat: 49.000834, lng: 18.7752052 },
    { lat: 49.0006095, lng: 18.7749651 },
    { lat: 49.0005455, lng: 18.7751868 },
    { lat: 49.0000713, lng: 18.7758678 },
    { lat: 49.0002249, lng: 18.7758726 },
    { lat: 49.0003453, lng: 18.7757608 },
    { lat: 49.000499, lng: 18.7758942 },
    { lat: 49.0004342, lng: 18.7763383 },
    { lat: 49.0004574, lng: 18.7764602 },
    { lat: 49.0004766, lng: 18.7766331 },
    { lat: 49.0003777, lng: 18.7773507 },
    { lat: 49.0004334, lng: 18.7777085 },
    { lat: 49.0007542, lng: 18.7777608 },
    { lat: 49.0008806, lng: 18.7779592 },
    { lat: 49.0011145, lng: 18.778163 },
    { lat: 49.0012936, lng: 18.778502 },
    { lat: 49.0015347, lng: 18.7788023 },
    { lat: 49.001506, lng: 18.7789455 },
    { lat: 49.0010383, lng: 18.7786053 },
    { lat: 49.0009455, lng: 18.778814 },
    { lat: 49.0009342, lng: 18.7788553 },
    { lat: 49.0008805, lng: 18.7789727 },
    { lat: 49.0007055, lng: 18.7786673 },
    { lat: 49.0005217, lng: 18.7784905 },
    { lat: 49.0004255, lng: 18.7784403 },
    { lat: 49.0004053, lng: 18.7784417 },
    { lat: 49.0003332, lng: 18.7788138 },
    { lat: 49.0003263, lng: 18.7791971 },
    { lat: 49.0004554, lng: 18.7797286 },
    { lat: 49.0005519, lng: 18.7801066 },
    { lat: 49.0003456, lng: 18.7819465 },
    { lat: 49.0004489, lng: 18.7821783 },
    { lat: 49.0004842, lng: 18.7821859 },
    { lat: 49.0006178, lng: 18.7825006 },
    { lat: 49.0007958, lng: 18.7833166 },
    { lat: 49.0005877, lng: 18.7841713 },
    { lat: 49.0007231, lng: 18.7842168 },
    { lat: 49.0006487, lng: 18.7847343 },
    { lat: 49.0005984, lng: 18.7850258 },
    { lat: 49.0007275, lng: 18.7851223 },
    { lat: 49.0007108, lng: 18.7852368 },
    { lat: 49.0005267, lng: 18.7852496 },
    { lat: 49.0003356, lng: 18.7857373 },
    { lat: 49.000165, lng: 18.7863579 },
    { lat: 49.0000933, lng: 18.7868004 },
    { lat: 49.000051, lng: 18.7870274 },
    { lat: 48.9997921, lng: 18.7878181 },
    { lat: 48.999447, lng: 18.7883164 },
    { lat: 48.9988226, lng: 18.7885819 },
    { lat: 48.9987106, lng: 18.7887677 },
    { lat: 48.9986705, lng: 18.7890182 },
    { lat: 48.9986242, lng: 18.7893405 },
    { lat: 48.9991543, lng: 18.7900542 },
    { lat: 48.9994559, lng: 18.7911916 },
    { lat: 48.9996065, lng: 18.7930467 },
    { lat: 48.9997703, lng: 18.7945677 },
    { lat: 48.9997744, lng: 18.7956693 },
    { lat: 48.9997589, lng: 18.7970398 },
    { lat: 48.9996925, lng: 18.797562 },
    { lat: 48.999523, lng: 18.7989876 },
    { lat: 48.9995699, lng: 18.7999128 },
    { lat: 48.9990003, lng: 18.8012741 },
    { lat: 48.9989168, lng: 18.8015132 },
    { lat: 48.9986682, lng: 18.8013717 },
    { lat: 48.9984932, lng: 18.8013909 },
    { lat: 48.9981428, lng: 18.8025247 },
    { lat: 48.998073, lng: 18.8033102 },
    { lat: 48.9973637, lng: 18.8034376 },
    { lat: 48.9971227, lng: 18.8034885 },
    { lat: 48.9964695, lng: 18.8042416 },
    { lat: 48.9963511, lng: 18.8042421 },
    { lat: 48.9962902, lng: 18.8043934 },
    { lat: 48.9953055, lng: 18.8055382 },
    { lat: 48.9950582, lng: 18.8060311 },
    { lat: 48.9948313, lng: 18.8062432 },
    { lat: 48.9943811, lng: 18.80702 },
    { lat: 48.9942952, lng: 18.8072784 },
    { lat: 48.9939823, lng: 18.8070032 },
    { lat: 48.9934686, lng: 18.8066446 },
    { lat: 48.9927682, lng: 18.8082913 },
    { lat: 48.9926198, lng: 18.8087148 },
    { lat: 48.9922566, lng: 18.8089501 },
    { lat: 48.9917066, lng: 18.8091295 },
    { lat: 48.9909351, lng: 18.8095386 },
    { lat: 48.9904903, lng: 18.809965 },
    { lat: 48.990331, lng: 18.8096742 },
    { lat: 48.9900582, lng: 18.8093066 },
    { lat: 48.9895942, lng: 18.8091442 },
    { lat: 48.9890844, lng: 18.8090545 },
    { lat: 48.9885036, lng: 18.8096379 },
    { lat: 48.9879538, lng: 18.8103001 },
    { lat: 48.9873891, lng: 18.8105871 },
    { lat: 48.9873035, lng: 18.8110236 },
    { lat: 48.9869955, lng: 18.8112156 },
    { lat: 48.9868038, lng: 18.8115028 },
    { lat: 48.9863319, lng: 18.8114882 },
    { lat: 48.9854294, lng: 18.8122652 },
    { lat: 48.9843063, lng: 18.811025 },
    { lat: 48.9842739, lng: 18.8112702 },
    { lat: 48.9841102, lng: 18.8114815 },
    { lat: 48.9837935, lng: 18.8111152 },
    { lat: 48.9827414, lng: 18.8102241 },
    { lat: 48.9818029, lng: 18.8099804 },
    { lat: 48.9813688, lng: 18.8107842 },
    { lat: 48.9814688, lng: 18.8110368 },
    { lat: 48.9814385, lng: 18.8112131 },
    { lat: 48.9812149, lng: 18.811317 },
    { lat: 48.9800111, lng: 18.8107163 },
    { lat: 48.9801466, lng: 18.8103095 },
    { lat: 48.9802252, lng: 18.810131 },
    { lat: 48.9803303, lng: 18.8099616 },
    { lat: 48.9805995, lng: 18.8094533 },
    { lat: 48.9808219, lng: 18.8089564 },
    { lat: 48.9811242, lng: 18.8082672 },
    { lat: 48.9797111, lng: 18.8079555 },
    { lat: 48.979723, lng: 18.8077764 },
    { lat: 48.9761488, lng: 18.8069562 },
    { lat: 48.9759973, lng: 18.8074055 },
    { lat: 48.9759336, lng: 18.8079799 },
    { lat: 48.9759167, lng: 18.8092379 },
    { lat: 48.9759641, lng: 18.8098337 },
    { lat: 48.9759846, lng: 18.8100905 },
    { lat: 48.9759838, lng: 18.8104442 },
    { lat: 48.9759734, lng: 18.8106293 },
    { lat: 48.9759574, lng: 18.8109154 },
    { lat: 48.9759707, lng: 18.811217 },
    { lat: 48.9759887, lng: 18.8116274 },
    { lat: 48.9761, lng: 18.8119458 },
    { lat: 48.9760288, lng: 18.8123552 },
    { lat: 48.9760115, lng: 18.8127633 },
    { lat: 48.9759537, lng: 18.8127555 },
    { lat: 48.9759251, lng: 18.8132248 },
    { lat: 48.9755634, lng: 18.8146877 },
    { lat: 48.9755127, lng: 18.8149049 },
    { lat: 48.9754461, lng: 18.8151228 },
    { lat: 48.9751603, lng: 18.8148717 },
    { lat: 48.9749374, lng: 18.8150972 },
    { lat: 48.9746372, lng: 18.8154813 },
    { lat: 48.974549, lng: 18.8156082 },
    { lat: 48.9744521, lng: 18.8158265 },
    { lat: 48.9743168, lng: 18.816174 },
    { lat: 48.9742258, lng: 18.816421 },
    { lat: 48.9741987, lng: 18.816509 },
    { lat: 48.9739158, lng: 18.8168587 },
    { lat: 48.9736694, lng: 18.81711 },
    { lat: 48.9731076, lng: 18.8161234 },
    { lat: 48.972317, lng: 18.8171903 },
    { lat: 48.9722084, lng: 18.8169639 },
    { lat: 48.9718956, lng: 18.8167358 },
    { lat: 48.9717006, lng: 18.8164339 },
    { lat: 48.9715174, lng: 18.8160768 },
    { lat: 48.9713914, lng: 18.8158209 },
    { lat: 48.9711602, lng: 18.8153856 },
    { lat: 48.9710341, lng: 18.815583 },
    { lat: 48.971152, lng: 18.8158797 },
    { lat: 48.9712146, lng: 18.8160208 },
    { lat: 48.9712067, lng: 18.8160192 },
    { lat: 48.9711294, lng: 18.8161821 },
    { lat: 48.9704471, lng: 18.817661 },
    { lat: 48.9703304, lng: 18.8179111 },
    { lat: 48.9695558, lng: 18.8195833 },
    { lat: 48.9691866, lng: 18.8203836 },
    { lat: 48.9684027, lng: 18.8220726 },
    { lat: 48.9680066, lng: 18.8229379 },
    { lat: 48.9671153, lng: 18.8226464 },
    { lat: 48.9665255, lng: 18.8224951 },
    { lat: 48.9662086, lng: 18.8223618 },
    { lat: 48.9657185, lng: 18.822064 },
    { lat: 48.9654824, lng: 18.8219278 },
    { lat: 48.9654045, lng: 18.8219691 },
    { lat: 48.9653688, lng: 18.8228264 },
    { lat: 48.9653108, lng: 18.8233347 },
    { lat: 48.9649724, lng: 18.8247481 },
    { lat: 48.9646579, lng: 18.8258845 },
    { lat: 48.9642938, lng: 18.8271941 },
    { lat: 48.9639263, lng: 18.8283476 },
    { lat: 48.9636881, lng: 18.8290833 },
    { lat: 48.9631507, lng: 18.8307486 },
    { lat: 48.9627256, lng: 18.8320995 },
    { lat: 48.9623449, lng: 18.8332218 },
    { lat: 48.9619812, lng: 18.834346 },
    { lat: 48.9618639, lng: 18.8346901 },
    { lat: 48.9618391, lng: 18.8347778 },
    { lat: 48.9617958, lng: 18.8350736 },
    { lat: 48.9617215, lng: 18.8357757 },
    { lat: 48.9616334, lng: 18.8366902 },
    { lat: 48.9615517, lng: 18.8371384 },
    { lat: 48.9611685, lng: 18.838384 },
    { lat: 48.9607778, lng: 18.8395939 },
    { lat: 48.960365, lng: 18.8408143 },
    { lat: 48.9599445, lng: 18.8421476 },
    { lat: 48.9597837, lng: 18.8426619 },
    { lat: 48.9596233, lng: 18.843267 },
    { lat: 48.9595562, lng: 18.8436915 },
    { lat: 48.9595378, lng: 18.8443195 },
    { lat: 48.95954, lng: 18.8447563 },
    { lat: 48.9595591, lng: 18.8454501 },
    { lat: 48.9596095, lng: 18.8455988 },
    { lat: 48.9600734, lng: 18.845737 },
    { lat: 48.960223, lng: 18.845797 },
    { lat: 48.9603586, lng: 18.8458893 },
    { lat: 48.9624453, lng: 18.8476489 },
    { lat: 48.9635076, lng: 18.8485269 },
    { lat: 48.9635405, lng: 18.848575 },
    { lat: 48.9636363, lng: 18.8486641 },
    { lat: 48.9641956, lng: 18.8491631 },
    { lat: 48.9641342, lng: 18.8492874 },
    { lat: 48.9642709, lng: 18.849377 },
    { lat: 48.9642203, lng: 18.8494261 },
    { lat: 48.964199, lng: 18.8496002 },
    { lat: 48.964259, lng: 18.8516844 },
    { lat: 48.9642674, lng: 18.8522407 },
    { lat: 48.964284, lng: 18.8536131 },
    { lat: 48.9642647, lng: 18.8552277 },
    { lat: 48.9642686, lng: 18.8555014 },
    { lat: 48.9642602, lng: 18.8565216 },
    { lat: 48.9642711, lng: 18.857871 },
    { lat: 48.9647459, lng: 18.8581982 },
    { lat: 48.9647497, lng: 18.8586251 },
    { lat: 48.9647828, lng: 18.8587418 },
    { lat: 48.9647894, lng: 18.859177 },
    { lat: 48.9654266, lng: 18.8595739 },
    { lat: 48.9653573, lng: 18.8606559 },
    { lat: 48.9662538, lng: 18.8609646 },
    { lat: 48.9662273, lng: 18.8623131 },
    { lat: 48.9662312, lng: 18.8627623 },
    { lat: 48.9663047, lng: 18.8635973 },
    { lat: 48.9663776, lng: 18.8639206 },
    { lat: 48.9663288, lng: 18.8645931 },
    { lat: 48.9662354, lng: 18.8653646 },
    { lat: 48.9665425, lng: 18.8657667 },
    { lat: 48.9665816, lng: 18.8659128 },
    { lat: 48.9666308, lng: 18.8659274 },
    { lat: 48.9668779, lng: 18.8661927 },
    { lat: 48.9669752, lng: 18.8662429 },
    { lat: 48.9671768, lng: 18.8662847 },
    { lat: 48.9672187, lng: 18.8662869 },
    { lat: 48.9672658, lng: 18.8663703 },
    { lat: 48.9673088, lng: 18.8664154 },
    { lat: 48.9673508, lng: 18.8665066 },
    { lat: 48.9673463, lng: 18.8665757 },
    { lat: 48.9673987, lng: 18.8667392 },
    { lat: 48.9674343, lng: 18.8667938 },
    { lat: 48.9674878, lng: 18.8670258 },
    { lat: 48.967531, lng: 18.8670975 },
    { lat: 48.9676212, lng: 18.8672938 },
    { lat: 48.9676638, lng: 18.867442 },
    { lat: 48.9677389, lng: 18.8675016 },
    { lat: 48.9677903, lng: 18.8678849 },
    { lat: 48.9677861, lng: 18.8680659 },
    { lat: 48.9677918, lng: 18.8681352 },
    { lat: 48.9677877, lng: 18.8684045 },
    { lat: 48.9677983, lng: 18.8685658 },
    { lat: 48.9677669, lng: 18.8688136 },
    { lat: 48.9678416, lng: 18.8689705 },
    { lat: 48.967878, lng: 18.868935 },
    { lat: 48.9679602, lng: 18.8692834 },
    { lat: 48.9679901, lng: 18.8693832 },
    { lat: 48.9681741, lng: 18.8692516 },
    { lat: 48.9681998, lng: 18.8694265 },
    { lat: 48.9681891, lng: 18.8695444 },
    { lat: 48.9682081, lng: 18.8696053 },
    { lat: 48.9683754, lng: 18.8696156 },
    { lat: 48.9684263, lng: 18.8696405 },
    { lat: 48.9684252, lng: 18.8697518 },
    { lat: 48.9684578, lng: 18.8697943 },
    { lat: 48.9685451, lng: 18.8700215 },
    { lat: 48.9686112, lng: 18.8701019 },
    { lat: 48.968666, lng: 18.8701353 },
    { lat: 48.9687356, lng: 18.8701087 },
    { lat: 48.9689397, lng: 18.8703519 },
    { lat: 48.9690522, lng: 18.8704308 },
    { lat: 48.9692015, lng: 18.8705724 },
    { lat: 48.9691876, lng: 18.870873 },
    { lat: 48.9692804, lng: 18.8711437 },
    { lat: 48.9692805, lng: 18.8713203 },
    { lat: 48.9693415, lng: 18.8714315 },
    { lat: 48.9694814, lng: 18.8713891 },
    { lat: 48.9694619, lng: 18.8711667 },
    { lat: 48.9695006, lng: 18.8710835 },
    { lat: 48.9695989, lng: 18.8711971 },
    { lat: 48.9697109, lng: 18.8711839 },
    { lat: 48.969787, lng: 18.8712814 },
    { lat: 48.9697979, lng: 18.8714937 },
    { lat: 48.9697974, lng: 18.8716581 },
    { lat: 48.9698655, lng: 18.8715804 },
    { lat: 48.9699603, lng: 18.8716273 },
    { lat: 48.970086, lng: 18.8717883 },
    { lat: 48.9700694, lng: 18.8719729 },
    { lat: 48.969973, lng: 18.8720916 },
    { lat: 48.9699986, lng: 18.8721765 },
    { lat: 48.9700763, lng: 18.8721939 },
    { lat: 48.9701887, lng: 18.8723216 },
    { lat: 48.970234, lng: 18.872411 },
    { lat: 48.9701561, lng: 18.8725831 },
    { lat: 48.970168, lng: 18.8726402 },
    { lat: 48.9704201, lng: 18.8729384 },
    { lat: 48.9704313, lng: 18.8730239 },
    { lat: 48.9704802, lng: 18.8729872 },
    { lat: 48.9705161, lng: 18.8727888 },
    { lat: 48.9706692, lng: 18.8726738 },
    { lat: 48.9707518, lng: 18.8729195 },
    { lat: 48.9706758, lng: 18.8731108 },
    { lat: 48.9707227, lng: 18.8731867 },
    { lat: 48.9707818, lng: 18.8731966 },
    { lat: 48.970882, lng: 18.873104 },
    { lat: 48.9709495, lng: 18.873236 },
    { lat: 48.9709291, lng: 18.8732983 },
    { lat: 48.971056, lng: 18.8735287 },
    { lat: 48.9710276, lng: 18.8736154 },
    { lat: 48.971051, lng: 18.8737404 },
    { lat: 48.9710827, lng: 18.873765 },
    { lat: 48.9711865, lng: 18.8737236 },
    { lat: 48.971197, lng: 18.8738368 },
    { lat: 48.9710805, lng: 18.8739185 },
    { lat: 48.9710653, lng: 18.8740821 },
    { lat: 48.9711416, lng: 18.874207 },
    { lat: 48.9713369, lng: 18.8742793 },
    { lat: 48.9714822, lng: 18.8740579 },
    { lat: 48.9715843, lng: 18.8740253 },
    { lat: 48.9716449, lng: 18.8740192 },
    { lat: 48.9716474, lng: 18.8739346 },
    { lat: 48.9716233, lng: 18.8738186 },
    { lat: 48.9717872, lng: 18.8738279 },
    { lat: 48.9718624, lng: 18.8742141 },
    { lat: 48.9718867, lng: 18.8744248 },
    { lat: 48.9718918, lng: 18.8744378 },
    { lat: 48.9720259, lng: 18.8743487 },
    { lat: 48.9721043, lng: 18.8743143 },
    { lat: 48.9722575, lng: 18.8745338 },
    { lat: 48.9721843, lng: 18.874686 },
    { lat: 48.9722282, lng: 18.8747949 },
    { lat: 48.9721285, lng: 18.8748965 },
    { lat: 48.9721784, lng: 18.8750305 },
    { lat: 48.9721374, lng: 18.8751111 },
    { lat: 48.9721961, lng: 18.8752707 },
    { lat: 48.9722429, lng: 18.8753014 },
    { lat: 48.9723267, lng: 18.8752861 },
    { lat: 48.9723487, lng: 18.8750986 },
    { lat: 48.9724236, lng: 18.8751946 },
    { lat: 48.972504, lng: 18.8751369 },
    { lat: 48.9725166, lng: 18.87534 },
    { lat: 48.9726065, lng: 18.8752881 },
    { lat: 48.97266, lng: 18.875322 },
    { lat: 48.9727571, lng: 18.8753442 },
    { lat: 48.9728421, lng: 18.8746949 },
    { lat: 48.9735067, lng: 18.8739346 },
    { lat: 48.9738428, lng: 18.8736974 },
    { lat: 48.9740296, lng: 18.8737782 },
    { lat: 48.9742634, lng: 18.8741809 },
    { lat: 48.9742666, lng: 18.8745929 },
    { lat: 48.9743311, lng: 18.8748831 },
    { lat: 48.9746461, lng: 18.8751965 },
    { lat: 48.9752442, lng: 18.8753837 },
    { lat: 48.9754978, lng: 18.8756013 },
    { lat: 48.9756443, lng: 18.8759274 },
    { lat: 48.975763, lng: 18.8763308 },
    { lat: 48.9756776, lng: 18.8767325 },
    { lat: 48.9758558, lng: 18.8769311 },
    { lat: 48.9762463, lng: 18.8773126 },
    { lat: 48.9767231, lng: 18.8775297 },
    { lat: 48.977289, lng: 18.8776992 },
  ],
  Socovce: [
    { lat: 48.93605, lng: 18.892463 },
    { lat: 48.9379445, lng: 18.8933095 },
    { lat: 48.938979, lng: 18.8936073 },
    { lat: 48.940052, lng: 18.8938218 },
    { lat: 48.9405716, lng: 18.893858 },
    { lat: 48.9411848, lng: 18.8931963 },
    { lat: 48.941533, lng: 18.8930613 },
    { lat: 48.9421629, lng: 18.8930278 },
    { lat: 48.9431604, lng: 18.8930686 },
    { lat: 48.9435173, lng: 18.8926373 },
    { lat: 48.9436429, lng: 18.8925163 },
    { lat: 48.9438962, lng: 18.8923085 },
    { lat: 48.9467676, lng: 18.890827 },
    { lat: 48.9482743, lng: 18.8889838 },
    { lat: 48.9490901, lng: 18.887982 },
    { lat: 48.949398, lng: 18.887522 },
    { lat: 48.9496809, lng: 18.8870577 },
    { lat: 48.9499549, lng: 18.8864198 },
    { lat: 48.9504536, lng: 18.8855698 },
    { lat: 48.9507011, lng: 18.8851212 },
    { lat: 48.9511771, lng: 18.8844185 },
    { lat: 48.9516587, lng: 18.8840071 },
    { lat: 48.9518871, lng: 18.8834251 },
    { lat: 48.9520918, lng: 18.8831723 },
    { lat: 48.9525896, lng: 18.8828544 },
    { lat: 48.9533858, lng: 18.8827886 },
    { lat: 48.954011, lng: 18.8827333 },
    { lat: 48.9541877, lng: 18.8825295 },
    { lat: 48.9544097, lng: 18.8821326 },
    { lat: 48.9545289, lng: 18.8818404 },
    { lat: 48.9551494, lng: 18.8796485 },
    { lat: 48.9552693, lng: 18.8788224 },
    { lat: 48.9553233, lng: 18.8785121 },
    { lat: 48.9553278, lng: 18.878292 },
    { lat: 48.9553807, lng: 18.8781799 },
    { lat: 48.9553358, lng: 18.8780331 },
    { lat: 48.9554612, lng: 18.8776648 },
    { lat: 48.9554071, lng: 18.8775543 },
    { lat: 48.9553822, lng: 18.877441 },
    { lat: 48.955388, lng: 18.8773563 },
    { lat: 48.9554074, lng: 18.8772974 },
    { lat: 48.9554414, lng: 18.8772323 },
    { lat: 48.9554723, lng: 18.8771343 },
    { lat: 48.9554893, lng: 18.8769827 },
    { lat: 48.9555163, lng: 18.8768909 },
    { lat: 48.9555711, lng: 18.876771 },
    { lat: 48.9555957, lng: 18.8767491 },
    { lat: 48.9556343, lng: 18.8765999 },
    { lat: 48.9555549, lng: 18.8763281 },
    { lat: 48.9554857, lng: 18.8759638 },
    { lat: 48.9554504, lng: 18.8758711 },
    { lat: 48.955415, lng: 18.8758208 },
    { lat: 48.9553917, lng: 18.87574 },
    { lat: 48.9553836, lng: 18.8755364 },
    { lat: 48.9553542, lng: 18.8752489 },
    { lat: 48.9553477, lng: 18.8749946 },
    { lat: 48.9552937, lng: 18.8745364 },
    { lat: 48.9553071, lng: 18.8742449 },
    { lat: 48.9552863, lng: 18.87395 },
    { lat: 48.9553456, lng: 18.8738758 },
    { lat: 48.9553153, lng: 18.8736369 },
    { lat: 48.9553815, lng: 18.8731903 },
    { lat: 48.9554592, lng: 18.8730802 },
    { lat: 48.9554158, lng: 18.8728701 },
    { lat: 48.9555096, lng: 18.8727456 },
    { lat: 48.9554054, lng: 18.8724283 },
    { lat: 48.9555102, lng: 18.8720531 },
    { lat: 48.9554684, lng: 18.8718328 },
    { lat: 48.9555188, lng: 18.8715878 },
    { lat: 48.9556445, lng: 18.8715507 },
    { lat: 48.9558859, lng: 18.8716405 },
    { lat: 48.9563336, lng: 18.8712258 },
    { lat: 48.9566225, lng: 18.8711413 },
    { lat: 48.9567184, lng: 18.8710133 },
    { lat: 48.9566936, lng: 18.8708616 },
    { lat: 48.9567258, lng: 18.870785 },
    { lat: 48.956769, lng: 18.8705958 },
    { lat: 48.9567742, lng: 18.8703422 },
    { lat: 48.9567529, lng: 18.8702813 },
    { lat: 48.9567082, lng: 18.870199 },
    { lat: 48.9565315, lng: 18.8702716 },
    { lat: 48.9562987, lng: 18.8703487 },
    { lat: 48.9562022, lng: 18.8702916 },
    { lat: 48.9561794, lng: 18.8702671 },
    { lat: 48.9561052, lng: 18.8700732 },
    { lat: 48.9560698, lng: 18.8698456 },
    { lat: 48.9560458, lng: 18.8695757 },
    { lat: 48.9560624, lng: 18.869352 },
    { lat: 48.9561192, lng: 18.8691182 },
    { lat: 48.9561477, lng: 18.8689064 },
    { lat: 48.956137, lng: 18.8687394 },
    { lat: 48.9561427, lng: 18.8686296 },
    { lat: 48.9561228, lng: 18.8683518 },
    { lat: 48.9560771, lng: 18.8680153 },
    { lat: 48.955974, lng: 18.867325 },
    { lat: 48.9558987, lng: 18.866518 },
    { lat: 48.9559459, lng: 18.8662498 },
    { lat: 48.9560136, lng: 18.8661184 },
    { lat: 48.9561652, lng: 18.8659113 },
    { lat: 48.9561563, lng: 18.8656974 },
    { lat: 48.9563046, lng: 18.8656849 },
    { lat: 48.9564656, lng: 18.8656145 },
    { lat: 48.9565048, lng: 18.8653662 },
    { lat: 48.9566931, lng: 18.8653248 },
    { lat: 48.9567548, lng: 18.8650115 },
    { lat: 48.9568713, lng: 18.8647318 },
    { lat: 48.9567644, lng: 18.864607 },
    { lat: 48.9566882, lng: 18.8645727 },
    { lat: 48.9566073, lng: 18.8644237 },
    { lat: 48.9566184, lng: 18.8643323 },
    { lat: 48.9566884, lng: 18.8641166 },
    { lat: 48.9565466, lng: 18.8639698 },
    { lat: 48.9564854, lng: 18.8638494 },
    { lat: 48.9565057, lng: 18.8636984 },
    { lat: 48.9565869, lng: 18.8634574 },
    { lat: 48.9567879, lng: 18.8633969 },
    { lat: 48.9567858, lng: 18.8632676 },
    { lat: 48.9568061, lng: 18.8630295 },
    { lat: 48.9568936, lng: 18.8630622 },
    { lat: 48.9570301, lng: 18.8629789 },
    { lat: 48.9569643, lng: 18.8626839 },
    { lat: 48.9570513, lng: 18.8624683 },
    { lat: 48.9570114, lng: 18.862327 },
    { lat: 48.9569119, lng: 18.8622341 },
    { lat: 48.9568381, lng: 18.8624215 },
    { lat: 48.9566427, lng: 18.8624163 },
    { lat: 48.9565222, lng: 18.8623331 },
    { lat: 48.9564098, lng: 18.8622061 },
    { lat: 48.9563481, lng: 18.8618625 },
    { lat: 48.9564959, lng: 18.8617772 },
    { lat: 48.9566063, lng: 18.8617738 },
    { lat: 48.9566396, lng: 18.8616758 },
    { lat: 48.9566268, lng: 18.8615618 },
    { lat: 48.9565746, lng: 18.8615529 },
    { lat: 48.956437, lng: 18.8613127 },
    { lat: 48.9563452, lng: 18.8613236 },
    { lat: 48.9562232, lng: 18.8612524 },
    { lat: 48.9562223, lng: 18.8610612 },
    { lat: 48.956243, lng: 18.8608708 },
    { lat: 48.9562279, lng: 18.8608632 },
    { lat: 48.9560913, lng: 18.8603563 },
    { lat: 48.9559674, lng: 18.8599886 },
    { lat: 48.9558344, lng: 18.8597909 },
    { lat: 48.9558172, lng: 18.8596311 },
    { lat: 48.9558412, lng: 18.8595052 },
    { lat: 48.9558409, lng: 18.859215 },
    { lat: 48.9558788, lng: 18.8590106 },
    { lat: 48.9557874, lng: 18.8589243 },
    { lat: 48.9557687, lng: 18.8585167 },
    { lat: 48.9557904, lng: 18.8581934 },
    { lat: 48.9558348, lng: 18.8581336 },
    { lat: 48.9558696, lng: 18.8579156 },
    { lat: 48.9558165, lng: 18.8575822 },
    { lat: 48.9557757, lng: 18.8572526 },
    { lat: 48.9558392, lng: 18.8571468 },
    { lat: 48.9558084, lng: 18.857098 },
    { lat: 48.9556931, lng: 18.8570489 },
    { lat: 48.9556896, lng: 18.8569798 },
    { lat: 48.9556152, lng: 18.8568939 },
    { lat: 48.9554688, lng: 18.8565471 },
    { lat: 48.9554059, lng: 18.8563763 },
    { lat: 48.9553984, lng: 18.8561856 },
    { lat: 48.9553084, lng: 18.8561263 },
    { lat: 48.9552423, lng: 18.8560452 },
    { lat: 48.955168, lng: 18.8559184 },
    { lat: 48.955143, lng: 18.8556953 },
    { lat: 48.9551829, lng: 18.855396 },
    { lat: 48.9551475, lng: 18.8552129 },
    { lat: 48.9552299, lng: 18.85491 },
    { lat: 48.955178, lng: 18.8548832 },
    { lat: 48.9551736, lng: 18.8547578 },
    { lat: 48.9551108, lng: 18.8547225 },
    { lat: 48.9550768, lng: 18.8545845 },
    { lat: 48.9549987, lng: 18.8544729 },
    { lat: 48.9549836, lng: 18.8543308 },
    { lat: 48.9549089, lng: 18.8540821 },
    { lat: 48.9548719, lng: 18.8541112 },
    { lat: 48.9548068, lng: 18.854049 },
    { lat: 48.9547525, lng: 18.8540465 },
    { lat: 48.9531744, lng: 18.8541006 },
    { lat: 48.9529497, lng: 18.8547519 },
    { lat: 48.9527486, lng: 18.8553979 },
    { lat: 48.9521751, lng: 18.8551234 },
    { lat: 48.9519098, lng: 18.8553566 },
    { lat: 48.9516992, lng: 18.8554523 },
    { lat: 48.9513584, lng: 18.8554863 },
    { lat: 48.950561, lng: 18.8552456 },
    { lat: 48.9504437, lng: 18.8549812 },
    { lat: 48.9504624, lng: 18.8546898 },
    { lat: 48.9507169, lng: 18.8536532 },
    { lat: 48.9506283, lng: 18.8530715 },
    { lat: 48.9504845, lng: 18.8526882 },
    { lat: 48.9502926, lng: 18.8524631 },
    { lat: 48.9501086, lng: 18.8523949 },
    { lat: 48.9496761, lng: 18.8524715 },
    { lat: 48.9488089, lng: 18.8517695 },
    { lat: 48.94868, lng: 18.851328 },
    { lat: 48.948419, lng: 18.851383 },
    { lat: 48.948265, lng: 18.851003 },
    { lat: 48.947931, lng: 18.850817 },
    { lat: 48.947495, lng: 18.850581 },
    { lat: 48.946185, lng: 18.850723 },
    { lat: 48.945396, lng: 18.851337 },
    { lat: 48.9447873, lng: 18.851865 },
    { lat: 48.944755, lng: 18.851893 },
    { lat: 48.944627, lng: 18.851799 },
    { lat: 48.944411, lng: 18.85164 },
    { lat: 48.943739, lng: 18.851721 },
    { lat: 48.943259, lng: 18.852806 },
    { lat: 48.942939, lng: 18.853593 },
    { lat: 48.942853, lng: 18.855166 },
    { lat: 48.942845, lng: 18.856154 },
    { lat: 48.942783, lng: 18.856816 },
    { lat: 48.942659, lng: 18.857867 },
    { lat: 48.942074, lng: 18.858066 },
    { lat: 48.942051, lng: 18.858074 },
    { lat: 48.941982, lng: 18.858097 },
    { lat: 48.941952, lng: 18.858108 },
    { lat: 48.941369, lng: 18.858719 },
    { lat: 48.94131, lng: 18.858824 },
    { lat: 48.940333, lng: 18.85971 },
    { lat: 48.939897, lng: 18.860051 },
    { lat: 48.93984, lng: 18.860097 },
    { lat: 48.93951, lng: 18.861154 },
    { lat: 48.939184, lng: 18.862496 },
    { lat: 48.938954, lng: 18.863451 },
    { lat: 48.938685, lng: 18.864537 },
    { lat: 48.937866, lng: 18.864861 },
    { lat: 48.938091, lng: 18.866177 },
    { lat: 48.938558, lng: 18.868932 },
    { lat: 48.938662, lng: 18.869735 },
    { lat: 48.938513, lng: 18.872552 },
    { lat: 48.938546, lng: 18.872709 },
    { lat: 48.938322, lng: 18.873662 },
    { lat: 48.938181, lng: 18.874381 },
    { lat: 48.937907, lng: 18.87578 },
    { lat: 48.937685, lng: 18.876929 },
    { lat: 48.937224, lng: 18.878615 },
    { lat: 48.936865, lng: 18.88012 },
    { lat: 48.936267, lng: 18.882805 },
    { lat: 48.936008, lng: 18.883987 },
    { lat: 48.9361, lng: 18.885638 },
    { lat: 48.936508, lng: 18.886427 },
    { lat: 48.936339, lng: 18.88787 },
    { lat: 48.936294, lng: 18.88825 },
    { lat: 48.936451, lng: 18.888894 },
    { lat: 48.936485, lng: 18.889207 },
    { lat: 48.936219, lng: 18.891194 },
    { lat: 48.93605, lng: 18.892463 },
  ],
  Sučany: [
    { lat: 49.168029, lng: 18.956059 },
    { lat: 49.1677195, lng: 18.9560741 },
    { lat: 49.1674321, lng: 18.9560772 },
    { lat: 49.1669864, lng: 18.9559558 },
    { lat: 49.1668461, lng: 18.9559662 },
    { lat: 49.166703, lng: 18.9559916 },
    { lat: 49.1664444, lng: 18.9559592 },
    { lat: 49.1662333, lng: 18.9559109 },
    { lat: 49.1657986, lng: 18.9557882 },
    { lat: 49.1655355, lng: 18.9556436 },
    { lat: 49.1647743, lng: 18.9555744 },
    { lat: 49.1645203, lng: 18.9554565 },
    { lat: 49.163901, lng: 18.955224 },
    { lat: 49.1634505, lng: 18.9548313 },
    { lat: 49.1630437, lng: 18.9548138 },
    { lat: 49.1627857, lng: 18.9546816 },
    { lat: 49.1624025, lng: 18.9546631 },
    { lat: 49.1620604, lng: 18.9540912 },
    { lat: 49.1617214, lng: 18.9531177 },
    { lat: 49.1612821, lng: 18.9522301 },
    { lat: 49.1608983, lng: 18.9518617 },
    { lat: 49.1603115, lng: 18.9511146 },
    { lat: 49.1600616, lng: 18.9508342 },
    { lat: 49.1596854, lng: 18.9503697 },
    { lat: 49.1586718, lng: 18.9500773 },
    { lat: 49.1582458, lng: 18.9500146 },
    { lat: 49.1579691, lng: 18.9499808 },
    { lat: 49.157799, lng: 18.949963 },
    { lat: 49.1571642, lng: 18.9500278 },
    { lat: 49.1568649, lng: 18.950019 },
    { lat: 49.1561519, lng: 18.9498509 },
    { lat: 49.1554437, lng: 18.9497169 },
    { lat: 49.1552068, lng: 18.9502025 },
    { lat: 49.1548907, lng: 18.9508872 },
    { lat: 49.154522, lng: 18.9514873 },
    { lat: 49.154302, lng: 18.9528937 },
    { lat: 49.1541889, lng: 18.9533543 },
    { lat: 49.1538879, lng: 18.9540685 },
    { lat: 49.1537387, lng: 18.9544411 },
    { lat: 49.1533542, lng: 18.9551083 },
    { lat: 49.153101, lng: 18.9554563 },
    { lat: 49.1526663, lng: 18.9558675 },
    { lat: 49.1519995, lng: 18.9563473 },
    { lat: 49.1514795, lng: 18.9566096 },
    { lat: 49.1509542, lng: 18.9568358 },
    { lat: 49.1503097, lng: 18.9572434 },
    { lat: 49.1500442, lng: 18.9573887 },
    { lat: 49.1497522, lng: 18.9577254 },
    { lat: 49.1494588, lng: 18.9580998 },
    { lat: 49.1489702, lng: 18.958694 },
    { lat: 49.1484727, lng: 18.9592893 },
    { lat: 49.1479745, lng: 18.9597823 },
    { lat: 49.1473469, lng: 18.9604829 },
    { lat: 49.1470201, lng: 18.9608002 },
    { lat: 49.1463324, lng: 18.9613807 },
    { lat: 49.1458783, lng: 18.9620122 },
    { lat: 49.1454594, lng: 18.9625597 },
    { lat: 49.1447593, lng: 18.9630957 },
    { lat: 49.1443575, lng: 18.9637593 },
    { lat: 49.1441784, lng: 18.9641656 },
    { lat: 49.1438872, lng: 18.9654092 },
    { lat: 49.1436745, lng: 18.966558 },
    { lat: 49.1433741, lng: 18.9667937 },
    { lat: 49.1430889, lng: 18.9672419 },
    { lat: 49.142563, lng: 18.9673647 },
    { lat: 49.1421664, lng: 18.9674399 },
    { lat: 49.1413811, lng: 18.9676038 },
    { lat: 49.140791, lng: 18.9676602 },
    { lat: 49.1404538, lng: 18.9676172 },
    { lat: 49.1398536, lng: 18.9677393 },
    { lat: 49.1389785, lng: 18.9680023 },
    { lat: 49.1381934, lng: 18.9680376 },
    { lat: 49.1375555, lng: 18.9685583 },
    { lat: 49.1366841, lng: 18.9691636 },
    { lat: 49.1362277, lng: 18.9692815 },
    { lat: 49.1356833, lng: 18.9692623 },
    { lat: 49.1354444, lng: 18.9692424 },
    { lat: 49.1354261, lng: 18.969282 },
    { lat: 49.134658, lng: 18.9690024 },
    { lat: 49.1340522, lng: 18.9690625 },
    { lat: 49.1333683, lng: 18.9694762 },
    { lat: 49.1322402, lng: 18.971028 },
    { lat: 49.131463, lng: 18.971827 },
    { lat: 49.1309226, lng: 18.9725335 },
    { lat: 49.1307796, lng: 18.972714 },
    { lat: 49.1305984, lng: 18.9729677 },
    { lat: 49.1295179, lng: 18.9731142 },
    { lat: 49.1284282, lng: 18.9732102 },
    { lat: 49.1283398, lng: 18.9732426 },
    { lat: 49.1282794, lng: 18.9732823 },
    { lat: 49.1278809, lng: 18.9734085 },
    { lat: 49.127441, lng: 18.9736715 },
    { lat: 49.1272733, lng: 18.9736994 },
    { lat: 49.1270208, lng: 18.9736774 },
    { lat: 49.1266457, lng: 18.9739084 },
    { lat: 49.1266137, lng: 18.9739208 },
    { lat: 49.1261081, lng: 18.9737776 },
    { lat: 49.1258377, lng: 18.9736015 },
    { lat: 49.1254907, lng: 18.9730668 },
    { lat: 49.125426, lng: 18.9727684 },
    { lat: 49.125204, lng: 18.972253 },
    { lat: 49.124724, lng: 18.9717231 },
    { lat: 49.1245848, lng: 18.9715767 },
    { lat: 49.1243429, lng: 18.9712716 },
    { lat: 49.1243238, lng: 18.9710939 },
    { lat: 49.1241944, lng: 18.9710352 },
    { lat: 49.124035, lng: 18.9708915 },
    { lat: 49.1239438, lng: 18.97058 },
    { lat: 49.1236509, lng: 18.9704508 },
    { lat: 49.1235094, lng: 18.970323 },
    { lat: 49.1233189, lng: 18.9701682 },
    { lat: 49.1231596, lng: 18.9700486 },
    { lat: 49.1231338, lng: 18.9698317 },
    { lat: 49.1229848, lng: 18.9695806 },
    { lat: 49.1226814, lng: 18.9692651 },
    { lat: 49.1224392, lng: 18.9688885 },
    { lat: 49.122021, lng: 18.968733 },
    { lat: 49.1217079, lng: 18.9686465 },
    { lat: 49.1214381, lng: 18.968262 },
    { lat: 49.1209624, lng: 18.968039 },
    { lat: 49.1205482, lng: 18.9681631 },
    { lat: 49.120024, lng: 18.9682118 },
    { lat: 49.1196763, lng: 18.9681563 },
    { lat: 49.1193901, lng: 18.9677781 },
    { lat: 49.1191175, lng: 18.9679625 },
    { lat: 49.1189293, lng: 18.9679633 },
    { lat: 49.1187993, lng: 18.9681131 },
    { lat: 49.1187216, lng: 18.9682907 },
    { lat: 49.1186144, lng: 18.9684047 },
    { lat: 49.1185761, lng: 18.9684048 },
    { lat: 49.1183802, lng: 18.968521 },
    { lat: 49.1182326, lng: 18.9684976 },
    { lat: 49.1181223, lng: 18.9687044 },
    { lat: 49.1179217, lng: 18.968774 },
    { lat: 49.1175216, lng: 18.9690209 },
    { lat: 49.1166741, lng: 18.9691862 },
    { lat: 49.1162917, lng: 18.969268 },
    { lat: 49.1160448, lng: 18.969268 },
    { lat: 49.1157876, lng: 18.969175 },
    { lat: 49.1151744, lng: 18.9688447 },
    { lat: 49.1151788, lng: 18.9687726 },
    { lat: 49.1151507, lng: 18.9685981 },
    { lat: 49.1129543, lng: 18.969128 },
    { lat: 49.1127839, lng: 18.9691494 },
    { lat: 49.1126884, lng: 18.9691534 },
    { lat: 49.1125207, lng: 18.9691395 },
    { lat: 49.1115836, lng: 18.968728 },
    { lat: 49.1115259, lng: 18.9687045 },
    { lat: 49.1114857, lng: 18.9686664 },
    { lat: 49.1116546, lng: 18.9674328 },
    { lat: 49.111691, lng: 18.9669219 },
    { lat: 49.1116815, lng: 18.9659744 },
    { lat: 49.111584, lng: 18.9645985 },
    { lat: 49.1114636, lng: 18.963289 },
    { lat: 49.111275, lng: 18.9619145 },
    { lat: 49.1110976, lng: 18.9606559 },
    { lat: 49.1107842, lng: 18.9584248 },
    { lat: 49.110816, lng: 18.9581368 },
    { lat: 49.110861, lng: 18.9569094 },
    { lat: 49.1109667, lng: 18.955789 },
    { lat: 49.1112213, lng: 18.9544683 },
    { lat: 49.1114836, lng: 18.9533414 },
    { lat: 49.1115611, lng: 18.9528469 },
    { lat: 49.1113399, lng: 18.952929 },
    { lat: 49.1101814, lng: 18.9532947 },
    { lat: 49.1084944, lng: 18.9535573 },
    { lat: 49.1075664, lng: 18.9537301 },
    { lat: 49.1068572, lng: 18.9538654 },
    { lat: 49.1057124, lng: 18.9541899 },
    { lat: 49.1050074, lng: 18.9544049 },
    { lat: 49.1041732, lng: 18.9546088 },
    { lat: 49.1034696, lng: 18.9547402 },
    { lat: 49.102121, lng: 18.9550044 },
    { lat: 49.1014453, lng: 18.955153 },
    { lat: 49.1001516, lng: 18.9554751 },
    { lat: 49.0987242, lng: 18.9558737 },
    { lat: 49.0983016, lng: 18.9559674 },
    { lat: 49.0976503, lng: 18.9560437 },
    { lat: 49.0970815, lng: 18.9561848 },
    { lat: 49.0968071, lng: 18.9562015 },
    { lat: 49.0965347, lng: 18.9561395 },
    { lat: 49.0962623, lng: 18.9561487 },
    { lat: 49.0955485, lng: 18.9563248 },
    { lat: 49.0947463, lng: 18.9565141 },
    { lat: 49.0944888, lng: 18.9566417 },
    { lat: 49.0943467, lng: 18.9567508 },
    { lat: 49.0925769, lng: 18.957179 },
    { lat: 49.0914397, lng: 18.9574521 },
    { lat: 49.0900911, lng: 18.9578941 },
    { lat: 49.0885132, lng: 18.958349 },
    { lat: 49.0873436, lng: 18.9585003 },
    { lat: 49.0863965, lng: 18.9586079 },
    { lat: 49.0861356, lng: 18.958395 },
    { lat: 49.0857804, lng: 18.9584621 },
    { lat: 49.0853892, lng: 18.9586187 },
    { lat: 49.0849231, lng: 18.9587689 },
    { lat: 49.0845296, lng: 18.9588297 },
    { lat: 49.0831956, lng: 18.9588927 },
    { lat: 49.0821579, lng: 18.9589509 },
    { lat: 49.0820619, lng: 18.9590153 },
    { lat: 49.0817363, lng: 18.9591311 },
    { lat: 49.0811928, lng: 18.9593965 },
    { lat: 49.0807056, lng: 18.9597078 },
    { lat: 49.080667, lng: 18.9596544 },
    { lat: 49.0803784, lng: 18.9597446 },
    { lat: 49.0803426, lng: 18.9596873 },
    { lat: 49.0798965, lng: 18.9599113 },
    { lat: 49.0797786, lng: 18.9599705 },
    { lat: 49.079653, lng: 18.9600701 },
    { lat: 49.0793458, lng: 18.9604576 },
    { lat: 49.0789174, lng: 18.9609981 },
    { lat: 49.0788005, lng: 18.9611446 },
    { lat: 49.0786703, lng: 18.9613079 },
    { lat: 49.0785432, lng: 18.9613364 },
    { lat: 49.0777324, lng: 18.9612881 },
    { lat: 49.0776058, lng: 18.9613446 },
    { lat: 49.0771863, lng: 18.9617369 },
    { lat: 49.0768509, lng: 18.9620508 },
    { lat: 49.076478, lng: 18.9621001 },
    { lat: 49.0761948, lng: 18.9621376 },
    { lat: 49.0755019, lng: 18.9619935 },
    { lat: 49.0749593, lng: 18.9618966 },
    { lat: 49.0747244, lng: 18.9619557 },
    { lat: 49.0737176, lng: 18.9624025 },
    { lat: 49.0732686, lng: 18.9622824 },
    { lat: 49.0730869, lng: 18.9624838 },
    { lat: 49.0726706, lng: 18.9631657 },
    { lat: 49.0721417, lng: 18.9636112 },
    { lat: 49.0716945, lng: 18.9644219 },
    { lat: 49.0712572, lng: 18.9650066 },
    { lat: 49.0709006, lng: 18.9645312 },
    { lat: 49.0706049, lng: 18.9646607 },
    { lat: 49.0702252, lng: 18.9647058 },
    { lat: 49.0699031, lng: 18.9646708 },
    { lat: 49.0692871, lng: 18.9645004 },
    { lat: 49.0692845, lng: 18.9645429 },
    { lat: 49.0692908, lng: 18.9645554 },
    { lat: 49.0694711, lng: 18.9652672 },
    { lat: 49.0697839, lng: 18.9674912 },
    { lat: 49.0700476, lng: 18.9695688 },
    { lat: 49.0700557, lng: 18.9698171 },
    { lat: 49.0697847, lng: 18.9700098 },
    { lat: 49.0697009, lng: 18.970063 },
    { lat: 49.0700707, lng: 18.974207 },
    { lat: 49.0700559, lng: 18.9746305 },
    { lat: 49.070012, lng: 18.9757046 },
    { lat: 49.0700118, lng: 18.9759499 },
    { lat: 49.07008, lng: 18.9764323 },
    { lat: 49.070189, lng: 18.976626 },
    { lat: 49.0702427, lng: 18.9767956 },
    { lat: 49.0702356, lng: 18.9769471 },
    { lat: 49.0703168, lng: 18.9777509 },
    { lat: 49.0703489, lng: 18.9781449 },
    { lat: 49.0704668, lng: 18.9785166 },
    { lat: 49.0705191, lng: 18.9787708 },
    { lat: 49.0705878, lng: 18.9790799 },
    { lat: 49.07072, lng: 18.9791993 },
    { lat: 49.0707752, lng: 18.9793105 },
    { lat: 49.0708053, lng: 18.9795047 },
    { lat: 49.0708785, lng: 18.9795933 },
    { lat: 49.0708758, lng: 18.9796946 },
    { lat: 49.0709068, lng: 18.9797762 },
    { lat: 49.0711843, lng: 18.9800927 },
    { lat: 49.0712276, lng: 18.9800991 },
    { lat: 49.0712956, lng: 18.9801965 },
    { lat: 49.0713632, lng: 18.9801987 },
    { lat: 49.071423, lng: 18.9803118 },
    { lat: 49.0714526, lng: 18.980426 },
    { lat: 49.0715324, lng: 18.9804897 },
    { lat: 49.0716217, lng: 18.9806497 },
    { lat: 49.0716684, lng: 18.9806633 },
    { lat: 49.0718113, lng: 18.9808588 },
    { lat: 49.0720363, lng: 18.9809836 },
    { lat: 49.0721006, lng: 18.981033 },
    { lat: 49.0721607, lng: 18.9810791 },
    { lat: 49.0722413, lng: 18.9812502 },
    { lat: 49.0723307, lng: 18.9813642 },
    { lat: 49.0723566, lng: 18.9814565 },
    { lat: 49.0724138, lng: 18.9814941 },
    { lat: 49.07249, lng: 18.9815057 },
    { lat: 49.0725333, lng: 18.9815589 },
    { lat: 49.0724937, lng: 18.9816271 },
    { lat: 49.072517, lng: 18.981728 },
    { lat: 49.0725826, lng: 18.9817793 },
    { lat: 49.0726138, lng: 18.9819706 },
    { lat: 49.0727445, lng: 18.9820858 },
    { lat: 49.072902, lng: 18.9820797 },
    { lat: 49.0728623, lng: 18.9822954 },
    { lat: 49.0729584, lng: 18.9823216 },
    { lat: 49.0729711, lng: 18.9823532 },
    { lat: 49.0729332, lng: 18.9825396 },
    { lat: 49.0729708, lng: 18.9827046 },
    { lat: 49.0730995, lng: 18.9827981 },
    { lat: 49.0732769, lng: 18.9830328 },
    { lat: 49.0733976, lng: 18.9835006 },
    { lat: 49.0734603, lng: 18.9834943 },
    { lat: 49.0734767, lng: 18.9837549 },
    { lat: 49.0735751, lng: 18.9839157 },
    { lat: 49.0736024, lng: 18.983919 },
    { lat: 49.0736672, lng: 18.9838877 },
    { lat: 49.0737019, lng: 18.9839477 },
    { lat: 49.0736721, lng: 18.9841158 },
    { lat: 49.0738045, lng: 18.9841942 },
    { lat: 49.0737915, lng: 18.9842972 },
    { lat: 49.0738276, lng: 18.9844095 },
    { lat: 49.0738776, lng: 18.984303 },
    { lat: 49.0739108, lng: 18.9843156 },
    { lat: 49.0739032, lng: 18.9844086 },
    { lat: 49.0739176, lng: 18.9845633 },
    { lat: 49.0740107, lng: 18.9845539 },
    { lat: 49.0740599, lng: 18.9846535 },
    { lat: 49.0740591, lng: 18.984728 },
    { lat: 49.0741433, lng: 18.9848563 },
    { lat: 49.0741497, lng: 18.9850537 },
    { lat: 49.0743008, lng: 18.9852073 },
    { lat: 49.0743256, lng: 18.9855021 },
    { lat: 49.0744207, lng: 18.9855105 },
    { lat: 49.0745063, lng: 18.9856199 },
    { lat: 49.0745925, lng: 18.9859432 },
    { lat: 49.0745817, lng: 18.986085 },
    { lat: 49.0745111, lng: 18.9861616 },
    { lat: 49.0745321, lng: 18.9864673 },
    { lat: 49.0746075, lng: 18.98642 },
    { lat: 49.0746967, lng: 18.9865129 },
    { lat: 49.0747187, lng: 18.9865703 },
    { lat: 49.0747093, lng: 18.986698 },
    { lat: 49.0747903, lng: 18.9867163 },
    { lat: 49.0748048, lng: 18.9869375 },
    { lat: 49.0748438, lng: 18.9869524 },
    { lat: 49.0748917, lng: 18.9871396 },
    { lat: 49.0749283, lng: 18.9871544 },
    { lat: 49.0749895, lng: 18.9871587 },
    { lat: 49.0750804, lng: 18.9872373 },
    { lat: 49.0750853, lng: 18.9873339 },
    { lat: 49.0753034, lng: 18.9875048 },
    { lat: 49.075304, lng: 18.987559 },
    { lat: 49.0753983, lng: 18.9876441 },
    { lat: 49.075515, lng: 18.9879221 },
    { lat: 49.0755789, lng: 18.9879618 },
    { lat: 49.075596, lng: 18.9880522 },
    { lat: 49.0756664, lng: 18.9881325 },
    { lat: 49.0756942, lng: 18.9882824 },
    { lat: 49.0756882, lng: 18.9885661 },
    { lat: 49.0759486, lng: 18.9891931 },
    { lat: 49.0761056, lng: 18.9895816 },
    { lat: 49.0761441, lng: 18.9896749 },
    { lat: 49.0763363, lng: 18.9897946 },
    { lat: 49.0764053, lng: 18.9898664 },
    { lat: 49.0764598, lng: 18.9900772 },
    { lat: 49.0765935, lng: 18.9902271 },
    { lat: 49.0766779, lng: 18.9903352 },
    { lat: 49.0766742, lng: 18.9904176 },
    { lat: 49.0767602, lng: 18.9904267 },
    { lat: 49.076779, lng: 18.990509 },
    { lat: 49.076744, lng: 18.9905515 },
    { lat: 49.0768097, lng: 18.9906921 },
    { lat: 49.0768189, lng: 18.9908521 },
    { lat: 49.0768864, lng: 18.9911671 },
    { lat: 49.0769585, lng: 18.9912278 },
    { lat: 49.0769502, lng: 18.9913791 },
    { lat: 49.0769967, lng: 18.9914762 },
    { lat: 49.0769631, lng: 18.9915218 },
    { lat: 49.0770341, lng: 18.9917268 },
    { lat: 49.0770623, lng: 18.9918605 },
    { lat: 49.0770837, lng: 18.9920421 },
    { lat: 49.0771272, lng: 18.9922118 },
    { lat: 49.0772131, lng: 18.9922802 },
    { lat: 49.077301, lng: 18.9924174 },
    { lat: 49.0773912, lng: 18.9927328 },
    { lat: 49.0774584, lng: 18.9928332 },
    { lat: 49.0777782, lng: 18.9934753 },
    { lat: 49.0779191, lng: 18.9937937 },
    { lat: 49.0780301, lng: 18.9939804 },
    { lat: 49.0790086, lng: 18.995798 },
    { lat: 49.0786219, lng: 18.9959775 },
    { lat: 49.0792796, lng: 18.9973133 },
    { lat: 49.079835, lng: 18.9984527 },
    { lat: 49.0802825, lng: 18.9993738 },
    { lat: 49.0810906, lng: 19.0010766 },
    { lat: 49.081761, lng: 19.0024373 },
    { lat: 49.0827132, lng: 19.0043871 },
    { lat: 49.0836525, lng: 19.0063743 },
    { lat: 49.0843238, lng: 19.0078008 },
    { lat: 49.0843352, lng: 19.0078281 },
    { lat: 49.0846554, lng: 19.0085921 },
    { lat: 49.0851065, lng: 19.008439 },
    { lat: 49.0873534, lng: 19.0077661 },
    { lat: 49.0885519, lng: 19.0073842 },
    { lat: 49.0888114, lng: 19.0076608 },
    { lat: 49.089046, lng: 19.0079059 },
    { lat: 49.0900009, lng: 19.0089209 },
    { lat: 49.0907329, lng: 19.0096744 },
    { lat: 49.0911264, lng: 19.0099902 },
    { lat: 49.0913486, lng: 19.0104177 },
    { lat: 49.0913969, lng: 19.0105034 },
    { lat: 49.0918006, lng: 19.0098577 },
    { lat: 49.0925923, lng: 19.0111661 },
    { lat: 49.0928727, lng: 19.0115207 },
    { lat: 49.0931628, lng: 19.0117748 },
    { lat: 49.0942205, lng: 19.0120082 },
    { lat: 49.0946847, lng: 19.0118425 },
    { lat: 49.0963115, lng: 19.0115991 },
    { lat: 49.0972212, lng: 19.0123155 },
    { lat: 49.0979059, lng: 19.012752 },
    { lat: 49.097911, lng: 19.0127402 },
    { lat: 49.0981549, lng: 19.0129084 },
    { lat: 49.0983873, lng: 19.0127983 },
    { lat: 49.0985758, lng: 19.0127365 },
    { lat: 49.098783, lng: 19.012733 },
    { lat: 49.0991064, lng: 19.0128653 },
    { lat: 49.0996426, lng: 19.0131715 },
    { lat: 49.0998939, lng: 19.0132826 },
    { lat: 49.1000034, lng: 19.0140502 },
    { lat: 49.1001713, lng: 19.013893 },
    { lat: 49.1003016, lng: 19.0138588 },
    { lat: 49.1005229, lng: 19.0139094 },
    { lat: 49.1007422, lng: 19.0139922 },
    { lat: 49.1008505, lng: 19.0140603 },
    { lat: 49.101003, lng: 19.0142459 },
    { lat: 49.1011232, lng: 19.0144212 },
    { lat: 49.1013369, lng: 19.0148159 },
    { lat: 49.1011793, lng: 19.015359 },
    { lat: 49.101157, lng: 19.015748 },
    { lat: 49.101178, lng: 19.0160787 },
    { lat: 49.1013364, lng: 19.0166288 },
    { lat: 49.1010987, lng: 19.0171539 },
    { lat: 49.1011566, lng: 19.0176124 },
    { lat: 49.1012581, lng: 19.017909 },
    { lat: 49.1012733, lng: 19.018458 },
    { lat: 49.1015642, lng: 19.0194957 },
    { lat: 49.1015592, lng: 19.0195567 },
    { lat: 49.1016946, lng: 19.0195328 },
    { lat: 49.1017723, lng: 19.019491 },
    { lat: 49.1019942, lng: 19.0193495 },
    { lat: 49.1022992, lng: 19.0193418 },
    { lat: 49.1024272, lng: 19.0193072 },
    { lat: 49.1026867, lng: 19.0191537 },
    { lat: 49.1032698, lng: 19.0188195 },
    { lat: 49.1034573, lng: 19.0186233 },
    { lat: 49.1042222, lng: 19.0182504 },
    { lat: 49.1045343, lng: 19.0181801 },
    { lat: 49.104826, lng: 19.0173191 },
    { lat: 49.1047139, lng: 19.0171504 },
    { lat: 49.1046824, lng: 19.0169274 },
    { lat: 49.1044229, lng: 19.0157723 },
    { lat: 49.1043085, lng: 19.0151523 },
    { lat: 49.1042145, lng: 19.0144674 },
    { lat: 49.1041625, lng: 19.0135405 },
    { lat: 49.1041967, lng: 19.0123308 },
    { lat: 49.1041925, lng: 19.0116376 },
    { lat: 49.1043655, lng: 19.0114913 },
    { lat: 49.1043659, lng: 19.0114531 },
    { lat: 49.1044217, lng: 19.0097077 },
    { lat: 49.1044576, lng: 19.008645 },
    { lat: 49.1044928, lng: 19.0067812 },
    { lat: 49.1049851, lng: 19.0066157 },
    { lat: 49.1069917, lng: 19.0060505 },
    { lat: 49.1070218, lng: 19.0059999 },
    { lat: 49.1071034, lng: 19.0060969 },
    { lat: 49.1072409, lng: 19.0061428 },
    { lat: 49.1073399, lng: 19.0062526 },
    { lat: 49.1074053, lng: 19.0061619 },
    { lat: 49.1075342, lng: 19.0063491 },
    { lat: 49.1076093, lng: 19.0063145 },
    { lat: 49.1077297, lng: 19.0064272 },
    { lat: 49.1077341, lng: 19.0065569 },
    { lat: 49.1076876, lng: 19.0067098 },
    { lat: 49.1077971, lng: 19.006759 },
    { lat: 49.1078812, lng: 19.0068388 },
    { lat: 49.1078934, lng: 19.0069497 },
    { lat: 49.1080953, lng: 19.0075156 },
    { lat: 49.1081213, lng: 19.0077181 },
    { lat: 49.1081863, lng: 19.0079368 },
    { lat: 49.1083226, lng: 19.0079318 },
    { lat: 49.1084391, lng: 19.0078719 },
    { lat: 49.1084494, lng: 19.0078548 },
    { lat: 49.1087271, lng: 19.0080892 },
    { lat: 49.1087492, lng: 19.0080532 },
    { lat: 49.1092334, lng: 19.0075706 },
    { lat: 49.1093879, lng: 19.0073921 },
    { lat: 49.1095356, lng: 19.0071238 },
    { lat: 49.1099459, lng: 19.0067833 },
    { lat: 49.1099434, lng: 19.0067321 },
    { lat: 49.1101125, lng: 19.0068456 },
    { lat: 49.1102121, lng: 19.0072353 },
    { lat: 49.1105103, lng: 19.0076083 },
    { lat: 49.1105841, lng: 19.0077761 },
    { lat: 49.1106556, lng: 19.0077645 },
    { lat: 49.110869, lng: 19.0079042 },
    { lat: 49.1109716, lng: 19.007952 },
    { lat: 49.1110654, lng: 19.0077079 },
    { lat: 49.1111052, lng: 19.0076238 },
    { lat: 49.1111847, lng: 19.0075239 },
    { lat: 49.1114008, lng: 19.0073811 },
    { lat: 49.1116508, lng: 19.0073064 },
    { lat: 49.1117306, lng: 19.0072593 },
    { lat: 49.1117979, lng: 19.0071633 },
    { lat: 49.1118837, lng: 19.0071181 },
    { lat: 49.112084, lng: 19.007178 },
    { lat: 49.1122466, lng: 19.0073203 },
    { lat: 49.1123224, lng: 19.0072784 },
    { lat: 49.1124101, lng: 19.0071372 },
    { lat: 49.1123693, lng: 19.0070689 },
    { lat: 49.1123907, lng: 19.0069991 },
    { lat: 49.1124888, lng: 19.0069118 },
    { lat: 49.1125568, lng: 19.0067603 },
    { lat: 49.1126139, lng: 19.0067541 },
    { lat: 49.1126818, lng: 19.0068271 },
    { lat: 49.1127982, lng: 19.006878 },
    { lat: 49.113209, lng: 19.0069286 },
    { lat: 49.113288, lng: 19.0069079 },
    { lat: 49.1134763, lng: 19.006751 },
    { lat: 49.1136812, lng: 19.0063616 },
    { lat: 49.113735, lng: 19.0062427 },
    { lat: 49.1139198, lng: 19.0060842 },
    { lat: 49.1141679, lng: 19.0060186 },
    { lat: 49.1142683, lng: 19.0059757 },
    { lat: 49.1144052, lng: 19.0058514 },
    { lat: 49.1145525, lng: 19.0057735 },
    { lat: 49.11466, lng: 19.0056268 },
    { lat: 49.1147961, lng: 19.0055761 },
    { lat: 49.1150809, lng: 19.0053853 },
    { lat: 49.1151257, lng: 19.0052002 },
    { lat: 49.1151815, lng: 19.005098 },
    { lat: 49.1152368, lng: 19.0050984 },
    { lat: 49.1152877, lng: 19.0050144 },
    { lat: 49.1153141, lng: 19.0048447 },
    { lat: 49.11556, lng: 19.0047535 },
    { lat: 49.1159036, lng: 19.0045711 },
    { lat: 49.1160002, lng: 19.0043831 },
    { lat: 49.116129, lng: 19.0042994 },
    { lat: 49.1164079, lng: 19.0044437 },
    { lat: 49.1166139, lng: 19.0043442 },
    { lat: 49.1166563, lng: 19.0040924 },
    { lat: 49.1167985, lng: 19.004181 },
    { lat: 49.1168839, lng: 19.0041073 },
    { lat: 49.1168319, lng: 19.0039288 },
    { lat: 49.1168186, lng: 19.0036154 },
    { lat: 49.1169021, lng: 19.0034394 },
    { lat: 49.1170056, lng: 19.0034353 },
    { lat: 49.117025, lng: 19.0035713 },
    { lat: 49.1171307, lng: 19.0036349 },
    { lat: 49.1173201, lng: 19.0034779 },
    { lat: 49.1173496, lng: 19.0033212 },
    { lat: 49.1173789, lng: 19.0029878 },
    { lat: 49.1174175, lng: 19.0028336 },
    { lat: 49.1173524, lng: 19.0026849 },
    { lat: 49.1175483, lng: 19.0026111 },
    { lat: 49.1175938, lng: 19.0027314 },
    { lat: 49.1177616, lng: 19.0026806 },
    { lat: 49.1178698, lng: 19.0025683 },
    { lat: 49.1180306, lng: 19.002496 },
    { lat: 49.1181763, lng: 19.0024099 },
    { lat: 49.1182599, lng: 19.0023901 },
    { lat: 49.1182998, lng: 19.0023089 },
    { lat: 49.1185291, lng: 19.0023167 },
    { lat: 49.1186132, lng: 19.0020813 },
    { lat: 49.1188139, lng: 19.0019464 },
    { lat: 49.1188684, lng: 19.0018656 },
    { lat: 49.1190156, lng: 19.0018798 },
    { lat: 49.1190134, lng: 19.0017479 },
    { lat: 49.1190993, lng: 19.0017509 },
    { lat: 49.1191077, lng: 19.0016909 },
    { lat: 49.1191953, lng: 19.0017091 },
    { lat: 49.1191565, lng: 19.0014293 },
    { lat: 49.1192173, lng: 19.0014025 },
    { lat: 49.119265, lng: 19.0011209 },
    { lat: 49.1194864, lng: 19.000814 },
    { lat: 49.1195397, lng: 19.0006851 },
    { lat: 49.1196689, lng: 19.0005859 },
    { lat: 49.1196952, lng: 19.0003471 },
    { lat: 49.119767, lng: 19.000225 },
    { lat: 49.1197775, lng: 19.0000528 },
    { lat: 49.1198189, lng: 18.9999785 },
    { lat: 49.119933, lng: 19.00003 },
    { lat: 49.1200161, lng: 18.9999581 },
    { lat: 49.1199934, lng: 18.9998866 },
    { lat: 49.1202025, lng: 18.9998259 },
    { lat: 49.1202888, lng: 18.9997529 },
    { lat: 49.1204425, lng: 18.9996706 },
    { lat: 49.1204991, lng: 18.9995356 },
    { lat: 49.1207308, lng: 18.9993034 },
    { lat: 49.1208234, lng: 18.9993049 },
    { lat: 49.1209127, lng: 18.9991542 },
    { lat: 49.120948, lng: 18.999023 },
    { lat: 49.1211244, lng: 18.9989892 },
    { lat: 49.1211805, lng: 18.998915 },
    { lat: 49.1212322, lng: 18.9990277 },
    { lat: 49.1213213, lng: 18.9991178 },
    { lat: 49.1214674, lng: 18.998815 },
    { lat: 49.1215089, lng: 18.998699 },
    { lat: 49.1215727, lng: 18.9987146 },
    { lat: 49.1217372, lng: 18.9983711 },
    { lat: 49.1218152, lng: 18.9984431 },
    { lat: 49.1218636, lng: 18.9983536 },
    { lat: 49.1220003, lng: 18.9982707 },
    { lat: 49.1220463, lng: 18.9981768 },
    { lat: 49.1221514, lng: 18.9982045 },
    { lat: 49.1221919, lng: 18.9981545 },
    { lat: 49.1222762, lng: 18.9980169 },
    { lat: 49.1224008, lng: 18.9980558 },
    { lat: 49.1224812, lng: 18.9980809 },
    { lat: 49.1224804, lng: 18.9981781 },
    { lat: 49.1226052, lng: 18.9982136 },
    { lat: 49.1227669, lng: 18.9984266 },
    { lat: 49.1228663, lng: 18.9983845 },
    { lat: 49.1229842, lng: 18.9982847 },
    { lat: 49.1230511, lng: 18.998409 },
    { lat: 49.1232203, lng: 18.9983382 },
    { lat: 49.1232513, lng: 18.9985326 },
    { lat: 49.1233222, lng: 18.9984628 },
    { lat: 49.1235271, lng: 18.9984786 },
    { lat: 49.1237513, lng: 18.9985878 },
    { lat: 49.1238386, lng: 18.9984632 },
    { lat: 49.123942, lng: 18.9984566 },
    { lat: 49.1241614, lng: 18.9986542 },
    { lat: 49.1241998, lng: 18.9985205 },
    { lat: 49.1243398, lng: 18.9984567 },
    { lat: 49.124541, lng: 18.9985776 },
    { lat: 49.1245418, lng: 18.9987492 },
    { lat: 49.124712, lng: 18.9987046 },
    { lat: 49.1251454, lng: 18.9982446 },
    { lat: 49.1253126, lng: 18.9982719 },
    { lat: 49.1253584, lng: 18.9981737 },
    { lat: 49.1254666, lng: 18.9982619 },
    { lat: 49.1256568, lng: 18.9981876 },
    { lat: 49.1257017, lng: 18.9983436 },
    { lat: 49.1258097, lng: 18.9982742 },
    { lat: 49.1258879, lng: 18.9982777 },
    { lat: 49.1262618, lng: 18.9980927 },
    { lat: 49.1263584, lng: 18.9980181 },
    { lat: 49.1265401, lng: 18.9980236 },
    { lat: 49.1266674, lng: 18.9979084 },
    { lat: 49.1267583, lng: 18.9977189 },
    { lat: 49.1268381, lng: 18.9977805 },
    { lat: 49.1269026, lng: 18.9976535 },
    { lat: 49.1270141, lng: 18.9976725 },
    { lat: 49.1270861, lng: 18.9978269 },
    { lat: 49.1273167, lng: 18.9977273 },
    { lat: 49.1274546, lng: 18.9978261 },
    { lat: 49.1276078, lng: 18.9976207 },
    { lat: 49.1276849, lng: 18.9976931 },
    { lat: 49.127838, lng: 18.9977588 },
    { lat: 49.1279544, lng: 18.9977636 },
    { lat: 49.1281272, lng: 18.997836 },
    { lat: 49.1283689, lng: 18.9976905 },
    { lat: 49.1286707, lng: 18.9974816 },
    { lat: 49.1287202, lng: 18.9974549 },
    { lat: 49.1288386, lng: 18.9976582 },
    { lat: 49.1290521, lng: 18.9977334 },
    { lat: 49.1291486, lng: 18.9975883 },
    { lat: 49.1295123, lng: 18.9976723 },
    { lat: 49.1296664, lng: 18.9975323 },
    { lat: 49.1300171, lng: 18.9976431 },
    { lat: 49.1302602, lng: 18.9974542 },
    { lat: 49.1304181, lng: 18.9975043 },
    { lat: 49.1305587, lng: 18.9974058 },
    { lat: 49.1306244, lng: 18.9973913 },
    { lat: 49.1307298, lng: 18.997558 },
    { lat: 49.1309072, lng: 18.9975435 },
    { lat: 49.131071, lng: 18.9976389 },
    { lat: 49.1312441, lng: 18.9977901 },
    { lat: 49.1315042, lng: 18.9978746 },
    { lat: 49.1316863, lng: 18.9977703 },
    { lat: 49.1317383, lng: 18.9978643 },
    { lat: 49.1318027, lng: 18.9977783 },
    { lat: 49.1319182, lng: 18.9977865 },
    { lat: 49.132092, lng: 18.9976108 },
    { lat: 49.1321888, lng: 18.9976769 },
    { lat: 49.1322874, lng: 18.9976629 },
    { lat: 49.1326633, lng: 18.9978758 },
    { lat: 49.1330996, lng: 18.9976076 },
    { lat: 49.1332121, lng: 18.9975805 },
    { lat: 49.1333964, lng: 18.997365 },
    { lat: 49.1335165, lng: 18.997423 },
    { lat: 49.1336807, lng: 18.9973708 },
    { lat: 49.1337401, lng: 18.9974726 },
    { lat: 49.1337701, lng: 18.9973769 },
    { lat: 49.1345123, lng: 18.9974047 },
    { lat: 49.1346938, lng: 18.9973825 },
    { lat: 49.1347662, lng: 18.997319 },
    { lat: 49.1349017, lng: 18.9971246 },
    { lat: 49.1349896, lng: 18.9971011 },
    { lat: 49.1352013, lng: 18.9970725 },
    { lat: 49.1353301, lng: 18.9969426 },
    { lat: 49.1355996, lng: 18.996899 },
    { lat: 49.1356174, lng: 18.9968728 },
    { lat: 49.1357048, lng: 18.9968626 },
    { lat: 49.1357545, lng: 18.9969307 },
    { lat: 49.1358937, lng: 18.996917 },
    { lat: 49.1359489, lng: 18.9968263 },
    { lat: 49.1360759, lng: 18.9968914 },
    { lat: 49.1361304, lng: 18.9968045 },
    { lat: 49.136235, lng: 18.9968042 },
    { lat: 49.136268, lng: 18.9968716 },
    { lat: 49.1364503, lng: 18.9968014 },
    { lat: 49.1365078, lng: 18.996916 },
    { lat: 49.136638, lng: 18.9969923 },
    { lat: 49.1368558, lng: 18.9971744 },
    { lat: 49.1370241, lng: 18.9973618 },
    { lat: 49.1374455, lng: 18.9973797 },
    { lat: 49.1375157, lng: 18.9974083 },
    { lat: 49.1376025, lng: 18.997499 },
    { lat: 49.1384147, lng: 18.9975517 },
    { lat: 49.1386102, lng: 18.9974274 },
    { lat: 49.138595, lng: 18.9969211 },
    { lat: 49.1390943, lng: 18.9965148 },
    { lat: 49.1394315, lng: 18.9962707 },
    { lat: 49.1399194, lng: 18.9958391 },
    { lat: 49.1399507, lng: 18.995677 },
    { lat: 49.1401467, lng: 18.9956744 },
    { lat: 49.1402513, lng: 18.9957824 },
    { lat: 49.1403778, lng: 18.9959877 },
    { lat: 49.1405158, lng: 18.9964682 },
    { lat: 49.1407043, lng: 18.9967879 },
    { lat: 49.1409601, lng: 18.9969429 },
    { lat: 49.1413296, lng: 18.9966467 },
    { lat: 49.1414754, lng: 18.9961339 },
    { lat: 49.1416241, lng: 18.9956406 },
    { lat: 49.1417699, lng: 18.9949975 },
    { lat: 49.1421124, lng: 18.994586 },
    { lat: 49.1428146, lng: 18.9945589 },
    { lat: 49.143133, lng: 18.9942583 },
    { lat: 49.1435183, lng: 18.9939145 },
    { lat: 49.1441968, lng: 18.9926944 },
    { lat: 49.1445525, lng: 18.9921895 },
    { lat: 49.1450954, lng: 18.9919998 },
    { lat: 49.1457425, lng: 18.9921142 },
    { lat: 49.1461008, lng: 18.9921333 },
    { lat: 49.1469327, lng: 18.9919744 },
    { lat: 49.1477019, lng: 18.9922715 },
    { lat: 49.1482326, lng: 18.9921744 },
    { lat: 49.1486236, lng: 18.9915213 },
    { lat: 49.1490254, lng: 18.9912617 },
    { lat: 49.1495238, lng: 18.9910379 },
    { lat: 49.1498755, lng: 18.9904967 },
    { lat: 49.1506081, lng: 18.9900684 },
    { lat: 49.1512862, lng: 18.9899433 },
    { lat: 49.1519839, lng: 18.9899639 },
    { lat: 49.152678, lng: 18.989937 },
    { lat: 49.1534159, lng: 18.9898553 },
    { lat: 49.1534247, lng: 18.9898316 },
    { lat: 49.1535888, lng: 18.9898207 },
    { lat: 49.1537658, lng: 18.9898213 },
    { lat: 49.1539609, lng: 18.9897316 },
    { lat: 49.1541271, lng: 18.9897864 },
    { lat: 49.1542932, lng: 18.9897249 },
    { lat: 49.154434, lng: 18.9896552 },
    { lat: 49.154739, lng: 18.9898955 },
    { lat: 49.1550064, lng: 18.9899428 },
    { lat: 49.155362, lng: 18.989995 },
    { lat: 49.1555462, lng: 18.9902541 },
    { lat: 49.1558344, lng: 18.9902915 },
    { lat: 49.1559445, lng: 18.9902581 },
    { lat: 49.1564114, lng: 18.9904853 },
    { lat: 49.1566496, lng: 18.990879 },
    { lat: 49.1571566, lng: 18.9913637 },
    { lat: 49.1573902, lng: 18.9920475 },
    { lat: 49.1575324, lng: 18.9922078 },
    { lat: 49.1575445, lng: 18.9921743 },
    { lat: 49.157612, lng: 18.9922208 },
    { lat: 49.158155, lng: 18.9917855 },
    { lat: 49.1585323, lng: 18.9909004 },
    { lat: 49.160173, lng: 18.9906386 },
    { lat: 49.1608999, lng: 18.9895784 },
    { lat: 49.1613814, lng: 18.9892438 },
    { lat: 49.162134, lng: 18.988919 },
    { lat: 49.162451, lng: 18.9885834 },
    { lat: 49.1628387, lng: 18.9884067 },
    { lat: 49.1639171, lng: 18.9881944 },
    { lat: 49.1641872, lng: 18.988182 },
    { lat: 49.1645368, lng: 18.9881845 },
    { lat: 49.16489, lng: 18.9881717 },
    { lat: 49.1650368, lng: 18.9880426 },
    { lat: 49.1656322, lng: 18.9880207 },
    { lat: 49.1668363, lng: 18.9875502 },
    { lat: 49.1671256, lng: 18.9876804 },
    { lat: 49.1680907, lng: 18.9874777 },
    { lat: 49.1690459, lng: 18.9867877 },
    { lat: 49.1696426, lng: 18.9873345 },
    { lat: 49.1702487, lng: 18.9872338 },
    { lat: 49.1725998, lng: 18.9872445 },
    { lat: 49.1741996, lng: 18.9869342 },
    { lat: 49.1758494, lng: 18.9862927 },
    { lat: 49.1768514, lng: 18.9884001 },
    { lat: 49.1777031, lng: 18.9901874 },
    { lat: 49.1789551, lng: 18.9915966 },
    { lat: 49.1800621, lng: 18.9928069 },
    { lat: 49.1811042, lng: 18.9929986 },
    { lat: 49.182145, lng: 18.993521 },
    { lat: 49.18216, lng: 18.993423 },
    { lat: 49.182305, lng: 18.992492 },
    { lat: 49.182479, lng: 18.991609 },
    { lat: 49.182617, lng: 18.99086 },
    { lat: 49.182663, lng: 18.990135 },
    { lat: 49.182634, lng: 18.989589 },
    { lat: 49.182553, lng: 18.988526 },
    { lat: 49.182631, lng: 18.988078 },
    { lat: 49.182631, lng: 18.988077 },
    { lat: 49.182611, lng: 18.987977 },
    { lat: 49.182528, lng: 18.987548 },
    { lat: 49.182825, lng: 18.986682 },
    { lat: 49.182863, lng: 18.986569 },
    { lat: 49.182864, lng: 18.986564 },
    { lat: 49.182841, lng: 18.985885 },
    { lat: 49.182916, lng: 18.985202 },
    { lat: 49.182992, lng: 18.984139 },
    { lat: 49.183007, lng: 18.984042 },
    { lat: 49.183138, lng: 18.983128 },
    { lat: 49.183091, lng: 18.982556 },
    { lat: 49.183079, lng: 18.982019 },
    { lat: 49.183073, lng: 18.981966 },
    { lat: 49.18301, lng: 18.981312 },
    { lat: 49.182967, lng: 18.980873 },
    { lat: 49.18291, lng: 18.980273 },
    { lat: 49.182931, lng: 18.979576 },
    { lat: 49.182972, lng: 18.978593 },
    { lat: 49.182981, lng: 18.978345 },
    { lat: 49.183003, lng: 18.977813 },
    { lat: 49.183019, lng: 18.977515 },
    { lat: 49.1830362, lng: 18.9772189 },
    { lat: 49.183053, lng: 18.976928 },
    { lat: 49.182821, lng: 18.975858 },
    { lat: 49.182781, lng: 18.975488 },
    { lat: 49.182709, lng: 18.974821 },
    { lat: 49.182689, lng: 18.9747111 },
    { lat: 49.182589, lng: 18.974163 },
    { lat: 49.182361, lng: 18.973022 },
    { lat: 49.182298, lng: 18.972857 },
    { lat: 49.182134, lng: 18.972424 },
    { lat: 49.182099, lng: 18.972333 },
    { lat: 49.181706, lng: 18.971315 },
    { lat: 49.181636, lng: 18.971092 },
    { lat: 49.181616, lng: 18.971026 },
    { lat: 49.18155, lng: 18.97082 },
    { lat: 49.181548, lng: 18.970813 },
    { lat: 49.181462, lng: 18.970378 },
    { lat: 49.18136, lng: 18.969861 },
    { lat: 49.18105, lng: 18.968869 },
    { lat: 49.180614, lng: 18.96819 },
    { lat: 49.180457, lng: 18.967959 },
    { lat: 49.18029, lng: 18.967714 },
    { lat: 49.17966, lng: 18.967517 },
    { lat: 49.179298, lng: 18.967406 },
    { lat: 49.178862, lng: 18.96702 },
    { lat: 49.178472, lng: 18.966641 },
    { lat: 49.17708, lng: 18.965388 },
    { lat: 49.176782, lng: 18.965218 },
    { lat: 49.176771, lng: 18.965211 },
    { lat: 49.176259, lng: 18.964453 },
    { lat: 49.175661, lng: 18.963951 },
    { lat: 49.174185, lng: 18.962774 },
    { lat: 49.173798, lng: 18.962431 },
    { lat: 49.173462, lng: 18.962131 },
    { lat: 49.173014, lng: 18.962006 },
    { lat: 49.17293, lng: 18.961982 },
    { lat: 49.1723282, lng: 18.9617079 },
    { lat: 49.172038, lng: 18.960317 },
    { lat: 49.171595, lng: 18.957989 },
    { lat: 49.171452, lng: 18.956384 },
    { lat: 49.17145, lng: 18.956382 },
    { lat: 49.171116, lng: 18.95611 },
    { lat: 49.171115, lng: 18.956109 },
    { lat: 49.171111, lng: 18.956105 },
    { lat: 49.170689, lng: 18.955651 },
    { lat: 49.170204, lng: 18.955634 },
    { lat: 49.170031, lng: 18.955628 },
    { lat: 49.169802, lng: 18.955832 },
    { lat: 49.169469, lng: 18.956004 },
    { lat: 49.168922, lng: 18.956047 },
    { lat: 49.168029, lng: 18.956059 },
  ],
  Trebostovo: [
    { lat: 49.060664, lng: 18.773081 },
    { lat: 49.059328, lng: 18.7734127 },
    { lat: 49.0589777, lng: 18.7735057 },
    { lat: 49.0583041, lng: 18.773907 },
    { lat: 49.0579148, lng: 18.7740993 },
    { lat: 49.0573732, lng: 18.7746108 },
    { lat: 49.0566789, lng: 18.7753522 },
    { lat: 49.0559378, lng: 18.7760399 },
    { lat: 49.0557135, lng: 18.7763427 },
    { lat: 49.0553063, lng: 18.7768819 },
    { lat: 49.0551502, lng: 18.7771546 },
    { lat: 49.0547623, lng: 18.7782127 },
    { lat: 49.0554955, lng: 18.7789746 },
    { lat: 49.0557209, lng: 18.7798645 },
    { lat: 49.0558788, lng: 18.780922 },
    { lat: 49.0554081, lng: 18.7811941 },
    { lat: 49.0551527, lng: 18.7814028 },
    { lat: 49.0547064, lng: 18.7821627 },
    { lat: 49.0542166, lng: 18.7825669 },
    { lat: 49.0540307, lng: 18.7827778 },
    { lat: 49.0539056, lng: 18.7829197 },
    { lat: 49.0535953, lng: 18.7832605 },
    { lat: 49.0533426, lng: 18.783562 },
    { lat: 49.0530928, lng: 18.7838987 },
    { lat: 49.0529074, lng: 18.7841487 },
    { lat: 49.0525636, lng: 18.7846302 },
    { lat: 49.0523487, lng: 18.7849401 },
    { lat: 49.0521547, lng: 18.7852333 },
    { lat: 49.0518479, lng: 18.7856971 },
    { lat: 49.0517343, lng: 18.7858747 },
    { lat: 49.0515, lng: 18.7860716 },
    { lat: 49.051468, lng: 18.786152 },
    { lat: 49.0514059, lng: 18.7863352 },
    { lat: 49.0513711, lng: 18.786438 },
    { lat: 49.0511622, lng: 18.7870372 },
    { lat: 49.0508774, lng: 18.7879994 },
    { lat: 49.0507923, lng: 18.788271 },
    { lat: 49.0505862, lng: 18.7887096 },
    { lat: 49.0504817, lng: 18.788977 },
    { lat: 49.050369, lng: 18.7892814 },
    { lat: 49.0502427, lng: 18.7896069 },
    { lat: 49.0500377, lng: 18.7900223 },
    { lat: 49.0497588, lng: 18.7907335 },
    { lat: 49.0496878, lng: 18.7910987 },
    { lat: 49.0495839, lng: 18.7916637 },
    { lat: 49.0494865, lng: 18.792035 },
    { lat: 49.0493281, lng: 18.7923398 },
    { lat: 49.0492274, lng: 18.7925474 },
    { lat: 49.0489737, lng: 18.7929403 },
    { lat: 49.0485482, lng: 18.7933643 },
    { lat: 49.0483935, lng: 18.79371 },
    { lat: 49.048186, lng: 18.7941638 },
    { lat: 49.0479908, lng: 18.7943537 },
    { lat: 49.047783, lng: 18.7945671 },
    { lat: 49.0476559, lng: 18.7947495 },
    { lat: 49.047406, lng: 18.7948878 },
    { lat: 49.0471434, lng: 18.7951893 },
    { lat: 49.0468435, lng: 18.7954533 },
    { lat: 49.0467409, lng: 18.7956062 },
    { lat: 49.0465542, lng: 18.7959604 },
    { lat: 49.0464857, lng: 18.7961606 },
    { lat: 49.0464059, lng: 18.7961899 },
    { lat: 49.0461865, lng: 18.7963062 },
    { lat: 49.0459653, lng: 18.796409 },
    { lat: 49.0457456, lng: 18.7966752 },
    { lat: 49.045524, lng: 18.797012 },
    { lat: 49.0452244, lng: 18.7974751 },
    { lat: 49.0448893, lng: 18.7980351 },
    { lat: 49.0447237, lng: 18.7983408 },
    { lat: 49.0444674, lng: 18.7985136 },
    { lat: 49.0443053, lng: 18.7986229 },
    { lat: 49.0440868, lng: 18.7989123 },
    { lat: 49.043845, lng: 18.7990747 },
    { lat: 49.0436192, lng: 18.7993541 },
    { lat: 49.0432401, lng: 18.7999575 },
    { lat: 49.0429755, lng: 18.8003754 },
    { lat: 49.0424948, lng: 18.8011484 },
    { lat: 49.0424539, lng: 18.8012127 },
    { lat: 49.0421781, lng: 18.8016109 },
    { lat: 49.0419577, lng: 18.8025129 },
    { lat: 49.0417597, lng: 18.8030643 },
    { lat: 49.0415846, lng: 18.8037737 },
    { lat: 49.0414669, lng: 18.8041796 },
    { lat: 49.0412104, lng: 18.8047549 },
    { lat: 49.0409712, lng: 18.8052735 },
    { lat: 49.0408158, lng: 18.8054967 },
    { lat: 49.0406589, lng: 18.805775 },
    { lat: 49.0405262, lng: 18.8060004 },
    { lat: 49.0403505, lng: 18.8060887 },
    { lat: 49.0401005, lng: 18.8062064 },
    { lat: 49.0399158, lng: 18.8064507 },
    { lat: 49.039677, lng: 18.8066929 },
    { lat: 49.0392065, lng: 18.8073154 },
    { lat: 49.0390087, lng: 18.8075709 },
    { lat: 49.038762, lng: 18.8078809 },
    { lat: 49.0384918, lng: 18.8081476 },
    { lat: 49.0377699, lng: 18.8085329 },
    { lat: 49.03753, lng: 18.808668 },
    { lat: 49.0373411, lng: 18.8088524 },
    { lat: 49.0371955, lng: 18.8090036 },
    { lat: 49.0370157, lng: 18.8094266 },
    { lat: 49.0367751, lng: 18.8098763 },
    { lat: 49.0363022, lng: 18.810696 },
    { lat: 49.0360219, lng: 18.8111174 },
    { lat: 49.0357484, lng: 18.8115225 },
    { lat: 49.0355019, lng: 18.8117669 },
    { lat: 49.0352844, lng: 18.8121164 },
    { lat: 49.0351073, lng: 18.8124234 },
    { lat: 49.0349295, lng: 18.8127534 },
    { lat: 49.0347051, lng: 18.8133625 },
    { lat: 49.0344339, lng: 18.8141572 },
    { lat: 49.0343263, lng: 18.8146707 },
    { lat: 49.0342746, lng: 18.8152414 },
    { lat: 49.0341629, lng: 18.8157396 },
    { lat: 49.0340641, lng: 18.8160537 },
    { lat: 49.0338942, lng: 18.8165186 },
    { lat: 49.033714, lng: 18.8170217 },
    { lat: 49.0335442, lng: 18.8175085 },
    { lat: 49.0334062, lng: 18.8178916 },
    { lat: 49.0333013, lng: 18.8181327 },
    { lat: 49.0331404, lng: 18.8183985 },
    { lat: 49.0329006, lng: 18.8188871 },
    { lat: 49.0327479, lng: 18.8192468 },
    { lat: 49.0325897, lng: 18.819481 },
    { lat: 49.03237, lng: 18.819788 },
    { lat: 49.0321126, lng: 18.8201321 },
    { lat: 49.03197, lng: 18.8203814 },
    { lat: 49.0318608, lng: 18.8207326 },
    { lat: 49.0317748, lng: 18.8210566 },
    { lat: 49.0316002, lng: 18.8211486 },
    { lat: 49.0314981, lng: 18.8216373 },
    { lat: 49.0314672, lng: 18.8220216 },
    { lat: 49.0313744, lng: 18.822406 },
    { lat: 49.031311, lng: 18.822746 },
    { lat: 49.0312811, lng: 18.8228445 },
    { lat: 49.031068, lng: 18.8234808 },
    { lat: 49.0309732, lng: 18.8237811 },
    { lat: 49.0308437, lng: 18.8240675 },
    { lat: 49.0306154, lng: 18.824364 },
    { lat: 49.0305002, lng: 18.8244705 },
    { lat: 49.0304427, lng: 18.8245111 },
    { lat: 49.0301929, lng: 18.8246524 },
    { lat: 49.0300436, lng: 18.8247303 },
    { lat: 49.0298937, lng: 18.8248201 },
    { lat: 49.0296822, lng: 18.825006 },
    { lat: 49.0294152, lng: 18.8253128 },
    { lat: 49.0292916, lng: 18.8254756 },
    { lat: 49.0291337, lng: 18.8257586 },
    { lat: 49.028999, lng: 18.8261197 },
    { lat: 49.0289125, lng: 18.826342 },
    { lat: 49.0286954, lng: 18.826681 },
    { lat: 49.028147, lng: 18.8273904 },
    { lat: 49.0277203, lng: 18.8278608 },
    { lat: 49.0275429, lng: 18.8280487 },
    { lat: 49.0273144, lng: 18.8283009 },
    { lat: 49.0270732, lng: 18.828955 },
    { lat: 49.0268514, lng: 18.8291159 },
    { lat: 49.0263569, lng: 18.8297114 },
    { lat: 49.0261139, lng: 18.8302485 },
    { lat: 49.0256228, lng: 18.8310423 },
    { lat: 49.0255772, lng: 18.8311145 },
    { lat: 49.025258, lng: 18.8316198 },
    { lat: 49.0249147, lng: 18.8331609 },
    { lat: 49.0249355, lng: 18.833541 },
    { lat: 49.0244983, lng: 18.8339651 },
    { lat: 49.0245175, lng: 18.8345756 },
    { lat: 49.0245264, lng: 18.8348571 },
    { lat: 49.024579, lng: 18.8356549 },
    { lat: 49.0244951, lng: 18.8362822 },
    { lat: 49.0244493, lng: 18.8368769 },
    { lat: 49.0244073, lng: 18.8373084 },
    { lat: 49.024354, lng: 18.8376539 },
    { lat: 49.0242474, lng: 18.8380639 },
    { lat: 49.0242223, lng: 18.8381604 },
    { lat: 49.024068, lng: 18.838929 },
    { lat: 49.0241693, lng: 18.8392333 },
    { lat: 49.0241069, lng: 18.8398217 },
    { lat: 49.0240247, lng: 18.8399258 },
    { lat: 49.0239661, lng: 18.8400546 },
    { lat: 49.0239645, lng: 18.8401826 },
    { lat: 49.0239617, lng: 18.8404097 },
    { lat: 49.0240058, lng: 18.8405633 },
    { lat: 49.02401, lng: 18.8407765 },
    { lat: 49.0239846, lng: 18.8409405 },
    { lat: 49.0239635, lng: 18.8410033 },
    { lat: 49.0239164, lng: 18.8411436 },
    { lat: 49.023625, lng: 18.8420113 },
    { lat: 49.0233695, lng: 18.8429123 },
    { lat: 49.0232296, lng: 18.843944 },
    { lat: 49.0231815, lng: 18.844739 },
    { lat: 49.023209, lng: 18.8463435 },
    { lat: 49.0232037, lng: 18.8468967 },
    { lat: 49.0232109, lng: 18.8478253 },
    { lat: 49.0228697, lng: 18.8478343 },
    { lat: 49.0227729, lng: 18.849524 },
    { lat: 49.0224963, lng: 18.849493 },
    { lat: 49.0224242, lng: 18.8495832 },
    { lat: 49.0219028, lng: 18.8493736 },
    { lat: 49.0218539, lng: 18.8501124 },
    { lat: 49.0210315, lng: 18.8548541 },
    { lat: 49.0208338, lng: 18.8565126 },
    { lat: 49.0207135, lng: 18.8569569 },
    { lat: 49.0203648, lng: 18.8580291 },
    { lat: 49.0201892, lng: 18.8587113 },
    { lat: 49.0200787, lng: 18.8593481 },
    { lat: 49.0200589, lng: 18.8598818 },
    { lat: 49.0200899, lng: 18.8612738 },
    { lat: 49.0199435, lng: 18.8634499 },
    { lat: 49.0199228, lng: 18.8636629 },
    { lat: 49.0183626, lng: 18.8716565 },
    { lat: 49.0181821, lng: 18.8717451 },
    { lat: 49.0169875, lng: 18.8719752 },
    { lat: 49.0164265, lng: 18.872027 },
    { lat: 49.0155808, lng: 18.8720491 },
    { lat: 49.0148236, lng: 18.8718839 },
    { lat: 49.0146882, lng: 18.8717932 },
    { lat: 49.0146707, lng: 18.8718704 },
    { lat: 49.0143589, lng: 18.8717931 },
    { lat: 49.0139739, lng: 18.8717408 },
    { lat: 49.0137586, lng: 18.8718098 },
    { lat: 49.0132209, lng: 18.8721828 },
    { lat: 49.0131506, lng: 18.8728118 },
    { lat: 49.0130188, lng: 18.873516 },
    { lat: 49.0127559, lng: 18.8744391 },
    { lat: 49.0125333, lng: 18.875065 },
    { lat: 49.0117218, lng: 18.8775997 },
    { lat: 49.012113, lng: 18.8781456 },
    { lat: 49.0122014, lng: 18.8784875 },
    { lat: 49.0122948, lng: 18.8788773 },
    { lat: 49.0126063, lng: 18.87915 },
    { lat: 49.013092, lng: 18.8797386 },
    { lat: 49.013758, lng: 18.8789586 },
    { lat: 49.0145769, lng: 18.8790965 },
    { lat: 49.0145869, lng: 18.879467 },
    { lat: 49.0153346, lng: 18.8796902 },
    { lat: 49.016129, lng: 18.8802309 },
    { lat: 49.0162705, lng: 18.881411 },
    { lat: 49.0172926, lng: 18.8822357 },
    { lat: 49.0184231, lng: 18.8833706 },
    { lat: 49.0186608, lng: 18.8841181 },
    { lat: 49.0178766, lng: 18.8859298 },
    { lat: 49.0178154, lng: 18.8863458 },
    { lat: 49.0180575, lng: 18.8871089 },
    { lat: 49.0186769, lng: 18.8877314 },
    { lat: 49.0190882, lng: 18.8884774 },
    { lat: 49.0188988, lng: 18.8886956 },
    { lat: 49.0186032, lng: 18.8886077 },
    { lat: 49.0186036, lng: 18.8886156 },
    { lat: 49.0183416, lng: 18.888935 },
    { lat: 49.01812, lng: 18.889365 },
    { lat: 49.018228, lng: 18.8897107 },
    { lat: 49.01801, lng: 18.8902347 },
    { lat: 49.0183491, lng: 18.8906239 },
    { lat: 49.0187806, lng: 18.8911692 },
    { lat: 49.0191355, lng: 18.8915816 },
    { lat: 49.0192542, lng: 18.8920964 },
    { lat: 49.0194286, lng: 18.8921347 },
    { lat: 49.0197551, lng: 18.8919251 },
    { lat: 49.0198849, lng: 18.8914007 },
    { lat: 49.0199882, lng: 18.8909714 },
    { lat: 49.0201457, lng: 18.8907908 },
    { lat: 49.0207125, lng: 18.8910032 },
    { lat: 49.0207095, lng: 18.8906986 },
    { lat: 49.0209396, lng: 18.8905047 },
    { lat: 49.0218153, lng: 18.8912263 },
    { lat: 49.0223727, lng: 18.8917417 },
    { lat: 49.0232453, lng: 18.892269 },
    { lat: 49.0237964, lng: 18.8925254 },
    { lat: 49.0238235, lng: 18.8925352 },
    { lat: 49.023855, lng: 18.8925467 },
    { lat: 49.0243915, lng: 18.8927413 },
    { lat: 49.0248593, lng: 18.8929163 },
    { lat: 49.025335, lng: 18.8932233 },
    { lat: 49.0253005, lng: 18.8928588 },
    { lat: 49.0253207, lng: 18.8926566 },
    { lat: 49.0256242, lng: 18.8923312 },
    { lat: 49.0257575, lng: 18.8916946 },
    { lat: 49.0259575, lng: 18.8907551 },
    { lat: 49.0259849, lng: 18.8903897 },
    { lat: 49.0259532, lng: 18.8898118 },
    { lat: 49.0259072, lng: 18.8893987 },
    { lat: 49.0258845, lng: 18.8892411 },
    { lat: 49.0260911, lng: 18.8877936 },
    { lat: 49.0259778, lng: 18.8870798 },
    { lat: 49.0259667, lng: 18.88618 },
    { lat: 49.0259936, lng: 18.8853397 },
    { lat: 49.0259951, lng: 18.8846213 },
    { lat: 49.0258514, lng: 18.8845414 },
    { lat: 49.025859, lng: 18.8842926 },
    { lat: 49.0259689, lng: 18.8838416 },
    { lat: 49.0260063, lng: 18.8819107 },
    { lat: 49.0278092, lng: 18.8815117 },
    { lat: 49.0279648, lng: 18.8814458 },
    { lat: 49.0278671, lng: 18.8809264 },
    { lat: 49.0280157, lng: 18.8798011 },
    { lat: 49.0279215, lng: 18.8796782 },
    { lat: 49.0281555, lng: 18.8791834 },
    { lat: 49.028272, lng: 18.8789482 },
    { lat: 49.0281536, lng: 18.8785972 },
    { lat: 49.0285405, lng: 18.8781155 },
    { lat: 49.0286523, lng: 18.8779467 },
    { lat: 49.0286955, lng: 18.8777968 },
    { lat: 49.0286354, lng: 18.8767598 },
    { lat: 49.0287032, lng: 18.8762742 },
    { lat: 49.0286873, lng: 18.8760526 },
    { lat: 49.0286247, lng: 18.8757345 },
    { lat: 49.028621, lng: 18.8755984 },
    { lat: 49.0286453, lng: 18.8754773 },
    { lat: 49.0288243, lng: 18.8749653 },
    { lat: 49.0288726, lng: 18.8747864 },
    { lat: 49.0288599, lng: 18.8740783 },
    { lat: 49.0289252, lng: 18.8734173 },
    { lat: 49.029012, lng: 18.8726449 },
    { lat: 49.0291065, lng: 18.8721565 },
    { lat: 49.0291262, lng: 18.8714442 },
    { lat: 49.029294, lng: 18.8707373 },
    { lat: 49.0293385, lng: 18.8703055 },
    { lat: 49.0299381, lng: 18.8700381 },
    { lat: 49.0304943, lng: 18.8697799 },
    { lat: 49.031146, lng: 18.8693432 },
    { lat: 49.0318419, lng: 18.8691053 },
    { lat: 49.0322781, lng: 18.8690144 },
    { lat: 49.0330434, lng: 18.8686371 },
    { lat: 49.0333825, lng: 18.8683216 },
    { lat: 49.033664, lng: 18.867853 },
    { lat: 49.0337661, lng: 18.8671179 },
    { lat: 49.0340359, lng: 18.8669942 },
    { lat: 49.0343157, lng: 18.8664705 },
    { lat: 49.0351421, lng: 18.8659623 },
    { lat: 49.0359115, lng: 18.865159 },
    { lat: 49.0358418, lng: 18.8644827 },
    { lat: 49.0359333, lng: 18.8641351 },
    { lat: 49.0360919, lng: 18.8639509 },
    { lat: 49.0365064, lng: 18.863658 },
    { lat: 49.0367413, lng: 18.8627216 },
    { lat: 49.0370724, lng: 18.8609163 },
    { lat: 49.0370812, lng: 18.8608434 },
    { lat: 49.0372513, lng: 18.8595711 },
    { lat: 49.0373755, lng: 18.8585853 },
    { lat: 49.0375006, lng: 18.8577094 },
    { lat: 49.0389573, lng: 18.8528156 },
    { lat: 49.0390768, lng: 18.8524247 },
    { lat: 49.0397562, lng: 18.8515179 },
    { lat: 49.0407174, lng: 18.8505638 },
    { lat: 49.041007, lng: 18.8499931 },
    { lat: 49.041224, lng: 18.849587 },
    { lat: 49.0413126, lng: 18.8493342 },
    { lat: 49.0415908, lng: 18.8481244 },
    { lat: 49.0419429, lng: 18.8474509 },
    { lat: 49.0421178, lng: 18.8469718 },
    { lat: 49.0421013, lng: 18.8464361 },
    { lat: 49.0419721, lng: 18.8458531 },
    { lat: 49.0419008, lng: 18.8448423 },
    { lat: 49.0418919, lng: 18.8447798 },
    { lat: 49.0417903, lng: 18.8443157 },
    { lat: 49.0416364, lng: 18.8435803 },
    { lat: 49.0416028, lng: 18.8434337 },
    { lat: 49.0415193, lng: 18.8418141 },
    { lat: 49.0422478, lng: 18.8404808 },
    { lat: 49.0423345, lng: 18.8403482 },
    { lat: 49.0429821, lng: 18.8393293 },
    { lat: 49.0433583, lng: 18.8383146 },
    { lat: 49.0435407, lng: 18.8376542 },
    { lat: 49.0435067, lng: 18.8372381 },
    { lat: 49.0436049, lng: 18.835991 },
    { lat: 49.0433115, lng: 18.8351598 },
    { lat: 49.0430758, lng: 18.8347394 },
    { lat: 49.0428103, lng: 18.8340785 },
    { lat: 49.0423961, lng: 18.8336981 },
    { lat: 49.0417619, lng: 18.832682 },
    { lat: 49.0418088, lng: 18.8316916 },
    { lat: 49.0417943, lng: 18.8304965 },
    { lat: 49.0417946, lng: 18.8301726 },
    { lat: 49.0416173, lng: 18.8287946 },
    { lat: 49.0414482, lng: 18.8282283 },
    { lat: 49.0411554, lng: 18.8272314 },
    { lat: 49.0412445, lng: 18.8263359 },
    { lat: 49.0412678, lng: 18.825937 },
    { lat: 49.0414492, lng: 18.8248428 },
    { lat: 49.0414403, lng: 18.8247573 },
    { lat: 49.0431237, lng: 18.8257373 },
    { lat: 49.0436678, lng: 18.8262081 },
    { lat: 49.0446515, lng: 18.8267209 },
    { lat: 49.045543, lng: 18.8271919 },
    { lat: 49.0455607, lng: 18.8266147 },
    { lat: 49.0455155, lng: 18.8260248 },
    { lat: 49.0455937, lng: 18.825426 },
    { lat: 49.0456109, lng: 18.8249484 },
    { lat: 49.0453276, lng: 18.8235682 },
    { lat: 49.0453487, lng: 18.8233694 },
    { lat: 49.0456103, lng: 18.8222336 },
    { lat: 49.0456575, lng: 18.8218793 },
    { lat: 49.0457925, lng: 18.820352 },
    { lat: 49.0457986, lng: 18.820164 },
    { lat: 49.0457745, lng: 18.8196981 },
    { lat: 49.0456824, lng: 18.8191596 },
    { lat: 49.0457007, lng: 18.8187922 },
    { lat: 49.0458919, lng: 18.8179806 },
    { lat: 49.0459366, lng: 18.8169674 },
    { lat: 49.0459797, lng: 18.8158206 },
    { lat: 49.0459474, lng: 18.8155021 },
    { lat: 49.045841, lng: 18.8151442 },
    { lat: 49.0458238, lng: 18.814835 },
    { lat: 49.0458293, lng: 18.8147693 },
    { lat: 49.0458911, lng: 18.8145013 },
    { lat: 49.0461236, lng: 18.8142002 },
    { lat: 49.046299, lng: 18.8140146 },
    { lat: 49.0464135, lng: 18.8139175 },
    { lat: 49.0472902, lng: 18.8137998 },
    { lat: 49.0479261, lng: 18.8137092 },
    { lat: 49.0480291, lng: 18.813677 },
    { lat: 49.0482321, lng: 18.8135459 },
    { lat: 49.0487851, lng: 18.8132733 },
    { lat: 49.0492187, lng: 18.8128043 },
    { lat: 49.0493657, lng: 18.8123129 },
    { lat: 49.0494164, lng: 18.8121602 },
    { lat: 49.0495395, lng: 18.811837 },
    { lat: 49.0497454, lng: 18.8115233 },
    { lat: 49.0499994, lng: 18.8112221 },
    { lat: 49.0501691, lng: 18.8109311 },
    { lat: 49.0502849, lng: 18.8104028 },
    { lat: 49.0506398, lng: 18.8097868 },
    { lat: 49.0508175, lng: 18.8093647 },
    { lat: 49.0509001, lng: 18.8089144 },
    { lat: 49.0511225, lng: 18.8084373 },
    { lat: 49.0512397, lng: 18.8081777 },
    { lat: 49.0514195, lng: 18.8076848 },
    { lat: 49.0515466, lng: 18.8072651 },
    { lat: 49.0516513, lng: 18.8068744 },
    { lat: 49.0518567, lng: 18.8061595 },
    { lat: 49.0519218, lng: 18.8059798 },
    { lat: 49.0520855, lng: 18.8054835 },
    { lat: 49.0522352, lng: 18.8049791 },
    { lat: 49.0522862, lng: 18.8048748 },
    { lat: 49.0526874, lng: 18.804131 },
    { lat: 49.0529423, lng: 18.8038297 },
    { lat: 49.0532797, lng: 18.8034192 },
    { lat: 49.0538338, lng: 18.8025593 },
    { lat: 49.0543225, lng: 18.8016996 },
    { lat: 49.0544568, lng: 18.8014195 },
    { lat: 49.054779, lng: 18.8007617 },
    { lat: 49.0550813, lng: 18.8004166 },
    { lat: 49.0553735, lng: 18.8003757 },
    { lat: 49.0554302, lng: 18.800361 },
    { lat: 49.055917, lng: 18.8001799 },
    { lat: 49.0569963, lng: 18.7998363 },
    { lat: 49.0574968, lng: 18.7995701 },
    { lat: 49.0578976, lng: 18.7993432 },
    { lat: 49.058104, lng: 18.7991045 },
    { lat: 49.0582715, lng: 18.7989208 },
    { lat: 49.0583903, lng: 18.7988202 },
    { lat: 49.0588144, lng: 18.7984737 },
    { lat: 49.0592009, lng: 18.7980346 },
    { lat: 49.0593046, lng: 18.7978398 },
    { lat: 49.0594518, lng: 18.7975485 },
    { lat: 49.0597456, lng: 18.7967984 },
    { lat: 49.0600895, lng: 18.7958405 },
    { lat: 49.0601469, lng: 18.7956686 },
    { lat: 49.0604737, lng: 18.7950993 },
    { lat: 49.0609282, lng: 18.794406 },
    { lat: 49.0612275, lng: 18.7939353 },
    { lat: 49.061432, lng: 18.7925551 },
    { lat: 49.0614464, lng: 18.7917282 },
    { lat: 49.0614212, lng: 18.7911789 },
    { lat: 49.061253, lng: 18.7903308 },
    { lat: 49.061674, lng: 18.7897062 },
    { lat: 49.062402, lng: 18.7886333 },
    { lat: 49.0629417, lng: 18.7877614 },
    { lat: 49.0631221, lng: 18.7874776 },
    { lat: 49.0634992, lng: 18.7868147 },
    { lat: 49.0636818, lng: 18.7865083 },
    { lat: 49.0640028, lng: 18.7859612 },
    { lat: 49.0644668, lng: 18.7851933 },
    { lat: 49.0646511, lng: 18.7848964 },
    { lat: 49.064901, lng: 18.784482 },
    { lat: 49.064807, lng: 18.784244 },
    { lat: 49.064628, lng: 18.783445 },
    { lat: 49.064474, lng: 18.782803 },
    { lat: 49.064215, lng: 18.781935 },
    { lat: 49.063946, lng: 18.781196 },
    { lat: 49.063795, lng: 18.780587 },
    { lat: 49.063613, lng: 18.7802 },
    { lat: 49.063549, lng: 18.780066 },
    { lat: 49.063415, lng: 18.779784 },
    { lat: 49.062245, lng: 18.777837 },
    { lat: 49.061921, lng: 18.777352 },
    { lat: 49.061509, lng: 18.776685 },
    { lat: 49.061003, lng: 18.77585 },
    { lat: 49.060841, lng: 18.775656 },
    { lat: 49.060746, lng: 18.775543 },
    { lat: 49.060613, lng: 18.77509 },
    { lat: 49.060539, lng: 18.774529 },
    { lat: 49.060539, lng: 18.774057 },
    { lat: 49.060539, lng: 18.773387 },
    { lat: 49.060664, lng: 18.773081 },
  ],
  Trnovo: [
    { lat: 49.0117218, lng: 18.8775997 },
    { lat: 49.0125333, lng: 18.875065 },
    { lat: 49.0127559, lng: 18.8744391 },
    { lat: 49.0130188, lng: 18.873516 },
    { lat: 49.0131506, lng: 18.8728118 },
    { lat: 49.0132209, lng: 18.8721828 },
    { lat: 49.0137586, lng: 18.8718098 },
    { lat: 49.0139739, lng: 18.8717408 },
    { lat: 49.0143589, lng: 18.8717931 },
    { lat: 49.0146707, lng: 18.8718704 },
    { lat: 49.0146882, lng: 18.8717932 },
    { lat: 49.0148236, lng: 18.8718839 },
    { lat: 49.0155808, lng: 18.8720491 },
    { lat: 49.0164265, lng: 18.872027 },
    { lat: 49.0169875, lng: 18.8719752 },
    { lat: 49.0181821, lng: 18.8717451 },
    { lat: 49.0183626, lng: 18.8716565 },
    { lat: 49.0199228, lng: 18.8636629 },
    { lat: 49.0199435, lng: 18.8634499 },
    { lat: 49.0200899, lng: 18.8612738 },
    { lat: 49.0200589, lng: 18.8598818 },
    { lat: 49.0200787, lng: 18.8593481 },
    { lat: 49.0201892, lng: 18.8587113 },
    { lat: 49.0203648, lng: 18.8580291 },
    { lat: 49.0207135, lng: 18.8569569 },
    { lat: 49.0208338, lng: 18.8565126 },
    { lat: 49.0210315, lng: 18.8548541 },
    { lat: 49.0218539, lng: 18.8501124 },
    { lat: 49.0219028, lng: 18.8493736 },
    { lat: 49.0224242, lng: 18.8495832 },
    { lat: 49.0224963, lng: 18.849493 },
    { lat: 49.0227729, lng: 18.849524 },
    { lat: 49.0228697, lng: 18.8478343 },
    { lat: 49.0232109, lng: 18.8478253 },
    { lat: 49.0232037, lng: 18.8468967 },
    { lat: 49.023209, lng: 18.8463435 },
    { lat: 49.0231815, lng: 18.844739 },
    { lat: 49.0232296, lng: 18.843944 },
    { lat: 49.0233695, lng: 18.8429123 },
    { lat: 49.023625, lng: 18.8420113 },
    { lat: 49.0239164, lng: 18.8411436 },
    { lat: 49.0239635, lng: 18.8410033 },
    { lat: 49.0239846, lng: 18.8409405 },
    { lat: 49.02401, lng: 18.8407765 },
    { lat: 49.0240058, lng: 18.8405633 },
    { lat: 49.0239617, lng: 18.8404097 },
    { lat: 49.0239645, lng: 18.8401826 },
    { lat: 49.0239661, lng: 18.8400546 },
    { lat: 49.0240247, lng: 18.8399258 },
    { lat: 49.0241069, lng: 18.8398217 },
    { lat: 49.0241693, lng: 18.8392333 },
    { lat: 49.024068, lng: 18.838929 },
    { lat: 49.0242223, lng: 18.8381604 },
    { lat: 49.0242474, lng: 18.8380639 },
    { lat: 49.024354, lng: 18.8376539 },
    { lat: 49.0244073, lng: 18.8373084 },
    { lat: 49.0244493, lng: 18.8368769 },
    { lat: 49.0244951, lng: 18.8362822 },
    { lat: 49.024579, lng: 18.8356549 },
    { lat: 49.0245264, lng: 18.8348571 },
    { lat: 49.0245175, lng: 18.8345756 },
    { lat: 49.0244983, lng: 18.8339651 },
    { lat: 49.0249355, lng: 18.833541 },
    { lat: 49.0249147, lng: 18.8331609 },
    { lat: 49.025258, lng: 18.8316198 },
    { lat: 49.0255772, lng: 18.8311145 },
    { lat: 49.0256228, lng: 18.8310423 },
    { lat: 49.0261139, lng: 18.8302485 },
    { lat: 49.0263569, lng: 18.8297114 },
    { lat: 49.0268514, lng: 18.8291159 },
    { lat: 49.0270732, lng: 18.828955 },
    { lat: 49.0273144, lng: 18.8283009 },
    { lat: 49.0275429, lng: 18.8280487 },
    { lat: 49.0277203, lng: 18.8278608 },
    { lat: 49.028147, lng: 18.8273904 },
    { lat: 49.0286954, lng: 18.826681 },
    { lat: 49.0289125, lng: 18.826342 },
    { lat: 49.028999, lng: 18.8261197 },
    { lat: 49.0291337, lng: 18.8257586 },
    { lat: 49.0292916, lng: 18.8254756 },
    { lat: 49.0294152, lng: 18.8253128 },
    { lat: 49.0296822, lng: 18.825006 },
    { lat: 49.0298937, lng: 18.8248201 },
    { lat: 49.0300436, lng: 18.8247303 },
    { lat: 49.0301929, lng: 18.8246524 },
    { lat: 49.0304427, lng: 18.8245111 },
    { lat: 49.0305002, lng: 18.8244705 },
    { lat: 49.0306154, lng: 18.824364 },
    { lat: 49.0308437, lng: 18.8240675 },
    { lat: 49.0309732, lng: 18.8237811 },
    { lat: 49.031068, lng: 18.8234808 },
    { lat: 49.0312811, lng: 18.8228445 },
    { lat: 49.031311, lng: 18.822746 },
    { lat: 49.0313744, lng: 18.822406 },
    { lat: 49.0314672, lng: 18.8220216 },
    { lat: 49.0314981, lng: 18.8216373 },
    { lat: 49.0316002, lng: 18.8211486 },
    { lat: 49.0317748, lng: 18.8210566 },
    { lat: 49.0318608, lng: 18.8207326 },
    { lat: 49.03197, lng: 18.8203814 },
    { lat: 49.0321126, lng: 18.8201321 },
    { lat: 49.03237, lng: 18.819788 },
    { lat: 49.0325897, lng: 18.819481 },
    { lat: 49.0327479, lng: 18.8192468 },
    { lat: 49.0329006, lng: 18.8188871 },
    { lat: 49.0331404, lng: 18.8183985 },
    { lat: 49.0333013, lng: 18.8181327 },
    { lat: 49.0334062, lng: 18.8178916 },
    { lat: 49.0335442, lng: 18.8175085 },
    { lat: 49.033714, lng: 18.8170217 },
    { lat: 49.0338942, lng: 18.8165186 },
    { lat: 49.0340641, lng: 18.8160537 },
    { lat: 49.0341629, lng: 18.8157396 },
    { lat: 49.0342746, lng: 18.8152414 },
    { lat: 49.0343263, lng: 18.8146707 },
    { lat: 49.0344339, lng: 18.8141572 },
    { lat: 49.0347051, lng: 18.8133625 },
    { lat: 49.0349295, lng: 18.8127534 },
    { lat: 49.0351073, lng: 18.8124234 },
    { lat: 49.0352844, lng: 18.8121164 },
    { lat: 49.0355019, lng: 18.8117669 },
    { lat: 49.0357484, lng: 18.8115225 },
    { lat: 49.0360219, lng: 18.8111174 },
    { lat: 49.0363022, lng: 18.810696 },
    { lat: 49.0367751, lng: 18.8098763 },
    { lat: 49.0370157, lng: 18.8094266 },
    { lat: 49.0371955, lng: 18.8090036 },
    { lat: 49.0373411, lng: 18.8088524 },
    { lat: 49.03753, lng: 18.808668 },
    { lat: 49.0377699, lng: 18.8085329 },
    { lat: 49.0384918, lng: 18.8081476 },
    { lat: 49.038762, lng: 18.8078809 },
    { lat: 49.0390087, lng: 18.8075709 },
    { lat: 49.0392065, lng: 18.8073154 },
    { lat: 49.039677, lng: 18.8066929 },
    { lat: 49.0399158, lng: 18.8064507 },
    { lat: 49.0401005, lng: 18.8062064 },
    { lat: 49.0403505, lng: 18.8060887 },
    { lat: 49.0405262, lng: 18.8060004 },
    { lat: 49.0406589, lng: 18.805775 },
    { lat: 49.0408158, lng: 18.8054967 },
    { lat: 49.0409712, lng: 18.8052735 },
    { lat: 49.0412104, lng: 18.8047549 },
    { lat: 49.0414669, lng: 18.8041796 },
    { lat: 49.0415846, lng: 18.8037737 },
    { lat: 49.0417597, lng: 18.8030643 },
    { lat: 49.0419577, lng: 18.8025129 },
    { lat: 49.0421781, lng: 18.8016109 },
    { lat: 49.0424539, lng: 18.8012127 },
    { lat: 49.0424948, lng: 18.8011484 },
    { lat: 49.0429755, lng: 18.8003754 },
    { lat: 49.0432401, lng: 18.7999575 },
    { lat: 49.0436192, lng: 18.7993541 },
    { lat: 49.043845, lng: 18.7990747 },
    { lat: 49.0440868, lng: 18.7989123 },
    { lat: 49.0443053, lng: 18.7986229 },
    { lat: 49.0444674, lng: 18.7985136 },
    { lat: 49.0447237, lng: 18.7983408 },
    { lat: 49.0448893, lng: 18.7980351 },
    { lat: 49.0452244, lng: 18.7974751 },
    { lat: 49.045524, lng: 18.797012 },
    { lat: 49.0457456, lng: 18.7966752 },
    { lat: 49.0459653, lng: 18.796409 },
    { lat: 49.0461865, lng: 18.7963062 },
    { lat: 49.0464059, lng: 18.7961899 },
    { lat: 49.0464857, lng: 18.7961606 },
    { lat: 49.0465542, lng: 18.7959604 },
    { lat: 49.0467409, lng: 18.7956062 },
    { lat: 49.0468435, lng: 18.7954533 },
    { lat: 49.0471434, lng: 18.7951893 },
    { lat: 49.047406, lng: 18.7948878 },
    { lat: 49.0476559, lng: 18.7947495 },
    { lat: 49.047783, lng: 18.7945671 },
    { lat: 49.0479908, lng: 18.7943537 },
    { lat: 49.048186, lng: 18.7941638 },
    { lat: 49.0483935, lng: 18.79371 },
    { lat: 49.0485482, lng: 18.7933643 },
    { lat: 49.0489737, lng: 18.7929403 },
    { lat: 49.0492274, lng: 18.7925474 },
    { lat: 49.0493281, lng: 18.7923398 },
    { lat: 49.0494865, lng: 18.792035 },
    { lat: 49.0495839, lng: 18.7916637 },
    { lat: 49.0496878, lng: 18.7910987 },
    { lat: 49.0497588, lng: 18.7907335 },
    { lat: 49.0500377, lng: 18.7900223 },
    { lat: 49.0502427, lng: 18.7896069 },
    { lat: 49.050369, lng: 18.7892814 },
    { lat: 49.0504817, lng: 18.788977 },
    { lat: 49.0505862, lng: 18.7887096 },
    { lat: 49.0507923, lng: 18.788271 },
    { lat: 49.0508774, lng: 18.7879994 },
    { lat: 49.0511622, lng: 18.7870372 },
    { lat: 49.0513711, lng: 18.786438 },
    { lat: 49.0514059, lng: 18.7863352 },
    { lat: 49.051468, lng: 18.786152 },
    { lat: 49.0515, lng: 18.7860716 },
    { lat: 49.0517343, lng: 18.7858747 },
    { lat: 49.0518479, lng: 18.7856971 },
    { lat: 49.0521547, lng: 18.7852333 },
    { lat: 49.0523487, lng: 18.7849401 },
    { lat: 49.0525636, lng: 18.7846302 },
    { lat: 49.0529074, lng: 18.7841487 },
    { lat: 49.0530928, lng: 18.7838987 },
    { lat: 49.0533426, lng: 18.783562 },
    { lat: 49.0535953, lng: 18.7832605 },
    { lat: 49.0539056, lng: 18.7829197 },
    { lat: 49.0540307, lng: 18.7827778 },
    { lat: 49.0542166, lng: 18.7825669 },
    { lat: 49.0547064, lng: 18.7821627 },
    { lat: 49.0551527, lng: 18.7814028 },
    { lat: 49.0554081, lng: 18.7811941 },
    { lat: 49.0558788, lng: 18.780922 },
    { lat: 49.0557209, lng: 18.7798645 },
    { lat: 49.0554955, lng: 18.7789746 },
    { lat: 49.0547623, lng: 18.7782127 },
    { lat: 49.0547078, lng: 18.7783609 },
    { lat: 49.0546789, lng: 18.7784086 },
    { lat: 49.0544026, lng: 18.7789525 },
    { lat: 49.0537658, lng: 18.7799313 },
    { lat: 49.0533989, lng: 18.7804009 },
    { lat: 49.0524373, lng: 18.7813771 },
    { lat: 49.0519996, lng: 18.7817207 },
    { lat: 49.0515665, lng: 18.7822385 },
    { lat: 49.0503713, lng: 18.7825472 },
    { lat: 49.0501742, lng: 18.7829415 },
    { lat: 49.0501022, lng: 18.7831149 },
    { lat: 49.050024, lng: 18.7834331 },
    { lat: 49.0495099, lng: 18.7844725 },
    { lat: 49.0493276, lng: 18.7849154 },
    { lat: 49.0490499, lng: 18.7852551 },
    { lat: 49.0483798, lng: 18.7861181 },
    { lat: 49.0478984, lng: 18.7867882 },
    { lat: 49.0469596, lng: 18.7869753 },
    { lat: 49.0462742, lng: 18.7873444 },
    { lat: 49.0454528, lng: 18.7878344 },
    { lat: 49.0446376, lng: 18.7879436 },
    { lat: 49.0442163, lng: 18.787975 },
    { lat: 49.0431087, lng: 18.7882057 },
    { lat: 49.0425389, lng: 18.7882445 },
    { lat: 49.0422445, lng: 18.7883083 },
    { lat: 49.0417863, lng: 18.7885795 },
    { lat: 49.0413828, lng: 18.7886496 },
    { lat: 49.0410915, lng: 18.7887091 },
    { lat: 49.0400546, lng: 18.7890212 },
    { lat: 49.0398776, lng: 18.7891298 },
    { lat: 49.0394233, lng: 18.7900234 },
    { lat: 49.0393336, lng: 18.7904414 },
    { lat: 49.0393541, lng: 18.7909903 },
    { lat: 49.0393171, lng: 18.7913379 },
    { lat: 49.0391562, lng: 18.7919748 },
    { lat: 49.0388817, lng: 18.7933523 },
    { lat: 49.0388138, lng: 18.7937602 },
    { lat: 49.0387687, lng: 18.7943018 },
    { lat: 49.0387204, lng: 18.795696 },
    { lat: 49.03861, lng: 18.7966728 },
    { lat: 49.0384862, lng: 18.7973091 },
    { lat: 49.0382759, lng: 18.7981233 },
    { lat: 49.0381557, lng: 18.7992573 },
    { lat: 49.0380392, lng: 18.7997762 },
    { lat: 49.0378509, lng: 18.8000583 },
    { lat: 49.0373551, lng: 18.8001481 },
    { lat: 49.0371683, lng: 18.8000688 },
    { lat: 49.0370128, lng: 18.8000275 },
    { lat: 49.0368402, lng: 18.8000069 },
    { lat: 49.0366986, lng: 18.8000609 },
    { lat: 49.0364046, lng: 18.8003047 },
    { lat: 49.035869, lng: 18.8007381 },
    { lat: 49.0352017, lng: 18.8014162 },
    { lat: 49.0344172, lng: 18.8023575 },
    { lat: 49.0341283, lng: 18.8027638 },
    { lat: 49.0335231, lng: 18.8035318 },
    { lat: 49.0326315, lng: 18.8045786 },
    { lat: 49.0319177, lng: 18.8055342 },
    { lat: 49.0312587, lng: 18.8066558 },
    { lat: 49.0311407, lng: 18.8065535 },
    { lat: 49.0302496, lng: 18.8047028 },
    { lat: 49.0295015, lng: 18.8033236 },
    { lat: 49.0291266, lng: 18.802729 },
    { lat: 49.0288287, lng: 18.8032448 },
    { lat: 49.0287565, lng: 18.8039847 },
    { lat: 49.0286878, lng: 18.8044637 },
    { lat: 49.0283757, lng: 18.8048605 },
    { lat: 49.0279351, lng: 18.8052536 },
    { lat: 49.02759, lng: 18.8057517 },
    { lat: 49.0273088, lng: 18.8060711 },
    { lat: 49.0271738, lng: 18.8060543 },
    { lat: 49.0269658, lng: 18.8062862 },
    { lat: 49.0268137, lng: 18.8066336 },
    { lat: 49.0269028, lng: 18.8068536 },
    { lat: 49.0268065, lng: 18.8071271 },
    { lat: 49.0267488, lng: 18.8073823 },
    { lat: 49.0266559, lng: 18.8082658 },
    { lat: 49.026682, lng: 18.8086729 },
    { lat: 49.026703, lng: 18.8090007 },
    { lat: 49.026698, lng: 18.8090365 },
    { lat: 49.0266406, lng: 18.8094502 },
    { lat: 49.0267255, lng: 18.8105003 },
    { lat: 49.0269521, lng: 18.8112604 },
    { lat: 49.0270125, lng: 18.8117526 },
    { lat: 49.0270349, lng: 18.8121613 },
    { lat: 49.0269967, lng: 18.8125321 },
    { lat: 49.0268195, lng: 18.8129884 },
    { lat: 49.0267779, lng: 18.8132182 },
    { lat: 49.0267451, lng: 18.8133991 },
    { lat: 49.0266582, lng: 18.8141382 },
    { lat: 49.0266125, lng: 18.8149992 },
    { lat: 49.0265697, lng: 18.8152806 },
    { lat: 49.0265268, lng: 18.8155012 },
    { lat: 49.0264406, lng: 18.8159446 },
    { lat: 49.0263575, lng: 18.8162342 },
    { lat: 49.0262998, lng: 18.8164355 },
    { lat: 49.0262821, lng: 18.8164972 },
    { lat: 49.0260972, lng: 18.8172394 },
    { lat: 49.0260581, lng: 18.8173213 },
    { lat: 49.0259581, lng: 18.8175308 },
    { lat: 49.0259457, lng: 18.8175568 },
    { lat: 49.025867, lng: 18.8178487 },
    { lat: 49.0258233, lng: 18.8181377 },
    { lat: 49.0255792, lng: 18.8186598 },
    { lat: 49.025133, lng: 18.8196141 },
    { lat: 49.0249149, lng: 18.8202111 },
    { lat: 49.024816, lng: 18.8208291 },
    { lat: 49.0245731, lng: 18.8215358 },
    { lat: 49.0243462, lng: 18.8227095 },
    { lat: 49.0243362, lng: 18.8233872 },
    { lat: 49.0244389, lng: 18.8241524 },
    { lat: 49.0243325, lng: 18.8242981 },
    { lat: 49.0237782, lng: 18.8246554 },
    { lat: 49.0233321, lng: 18.8250792 },
    { lat: 49.0229153, lng: 18.8255638 },
    { lat: 49.0228438, lng: 18.8257067 },
    { lat: 49.0225304, lng: 18.8264958 },
    { lat: 49.0220746, lng: 18.8271456 },
    { lat: 49.0216688, lng: 18.8276044 },
    { lat: 49.0215517, lng: 18.8277168 },
    { lat: 49.0212386, lng: 18.8281401 },
    { lat: 49.0210835, lng: 18.828431 },
    { lat: 49.0209339, lng: 18.8288099 },
    { lat: 49.0204172, lng: 18.8294135 },
    { lat: 49.0203162, lng: 18.8295531 },
    { lat: 49.0200996, lng: 18.8299659 },
    { lat: 49.0197121, lng: 18.8308719 },
    { lat: 49.0195091, lng: 18.8314145 },
    { lat: 49.0193807, lng: 18.8320485 },
    { lat: 49.0191681, lng: 18.8325621 },
    { lat: 49.0188119, lng: 18.8332646 },
    { lat: 49.018391, lng: 18.8340445 },
    { lat: 49.0181194, lng: 18.8339149 },
    { lat: 49.0180264, lng: 18.8339246 },
    { lat: 49.0176, lng: 18.8340728 },
    { lat: 49.0175055, lng: 18.8341473 },
    { lat: 49.017191, lng: 18.8345635 },
    { lat: 49.0171084, lng: 18.8346887 },
    { lat: 49.0167778, lng: 18.8350299 },
    { lat: 49.0166647, lng: 18.8351325 },
    { lat: 49.016569, lng: 18.8352467 },
    { lat: 49.0162713, lng: 18.8357057 },
    { lat: 49.0159633, lng: 18.8363338 },
    { lat: 49.0155873, lng: 18.8369592 },
    { lat: 49.0152484, lng: 18.8377084 },
    { lat: 49.0151205, lng: 18.8380038 },
    { lat: 49.0149554, lng: 18.8383673 },
    { lat: 49.0147386, lng: 18.8387535 },
    { lat: 49.0146206, lng: 18.8389173 },
    { lat: 49.0143547, lng: 18.8391631 },
    { lat: 49.0141968, lng: 18.8396831 },
    { lat: 49.0140129, lng: 18.8400517 },
    { lat: 49.0135347, lng: 18.8407725 },
    { lat: 49.0130084, lng: 18.8414341 },
    { lat: 49.0124325, lng: 18.8420505 },
    { lat: 49.0110749, lng: 18.8434254 },
    { lat: 49.0107148, lng: 18.84377 },
    { lat: 49.0100684, lng: 18.8445273 },
    { lat: 49.0098558, lng: 18.844867 },
    { lat: 49.0094791, lng: 18.8453005 },
    { lat: 49.0091061, lng: 18.8457644 },
    { lat: 49.0088966, lng: 18.8460961 },
    { lat: 49.0084353, lng: 18.8469704 },
    { lat: 49.0081707, lng: 18.8475431 },
    { lat: 49.00798, lng: 18.8479369 },
    { lat: 49.0075986, lng: 18.848785 },
    { lat: 49.0073926, lng: 18.8491834 },
    { lat: 49.0071603, lng: 18.84984 },
    { lat: 49.0069058, lng: 18.8513558 },
    { lat: 49.0071076, lng: 18.8566789 },
    { lat: 49.0070835, lng: 18.8568904 },
    { lat: 49.0070527, lng: 18.8572967 },
    { lat: 49.0069933, lng: 18.8577412 },
    { lat: 49.0069625, lng: 18.8582408 },
    { lat: 49.0069357, lng: 18.8588158 },
    { lat: 49.0069597, lng: 18.8592831 },
    { lat: 49.0069964, lng: 18.8596892 },
    { lat: 49.0069842, lng: 18.8601317 },
    { lat: 49.0070301, lng: 18.8608679 },
    { lat: 49.0072622, lng: 18.8628624 },
    { lat: 49.0073017, lng: 18.8631669 },
    { lat: 49.0073061, lng: 18.8634965 },
    { lat: 49.0072717, lng: 18.8639211 },
    { lat: 49.0071848, lng: 18.8640561 },
    { lat: 49.0072813, lng: 18.8641761 },
    { lat: 49.0072057, lng: 18.8643287 },
    { lat: 49.0072352, lng: 18.8644836 },
    { lat: 49.0071553, lng: 18.8645983 },
    { lat: 49.007112, lng: 18.8646117 },
    { lat: 49.0071731, lng: 18.8648573 },
    { lat: 49.007141, lng: 18.8649355 },
    { lat: 49.0070499, lng: 18.8651991 },
    { lat: 49.0069094, lng: 18.8656299 },
    { lat: 49.0068679, lng: 18.8662235 },
    { lat: 49.0067693, lng: 18.8666087 },
    { lat: 49.006641, lng: 18.8672295 },
    { lat: 49.0067349, lng: 18.8675617 },
    { lat: 49.0066159, lng: 18.8678807 },
    { lat: 49.0065551, lng: 18.8686073 },
    { lat: 49.0063999, lng: 18.8694489 },
    { lat: 49.0062025, lng: 18.870238 },
    { lat: 49.0060469, lng: 18.8708519 },
    { lat: 49.0059923, lng: 18.8713827 },
    { lat: 49.0060057, lng: 18.871411 },
    { lat: 49.0060213, lng: 18.8715406 },
    { lat: 49.0061125, lng: 18.8717344 },
    { lat: 49.0059502, lng: 18.8722184 },
    { lat: 49.0052285, lng: 18.8744578 },
    { lat: 49.0057591, lng: 18.8742936 },
    { lat: 49.006407, lng: 18.874925 },
    { lat: 49.0067985, lng: 18.8752798 },
    { lat: 49.0072103, lng: 18.8759008 },
    { lat: 49.0072346, lng: 18.8761769 },
    { lat: 49.008324, lng: 18.8766005 },
    { lat: 49.0078901, lng: 18.8790852 },
    { lat: 49.0080243, lng: 18.8788653 },
    { lat: 49.008288, lng: 18.8789289 },
    { lat: 49.0085297, lng: 18.8793112 },
    { lat: 49.0087735, lng: 18.8797125 },
    { lat: 49.0089049, lng: 18.8802113 },
    { lat: 49.0089437, lng: 18.8805709 },
    { lat: 49.0090379, lng: 18.8806474 },
    { lat: 49.0091541, lng: 18.8805822 },
    { lat: 49.0092394, lng: 18.8803757 },
    { lat: 49.0094103, lng: 18.8803903 },
    { lat: 49.0094908, lng: 18.880509 },
    { lat: 49.00992, lng: 18.8803037 },
    { lat: 49.0102086, lng: 18.8797756 },
    { lat: 49.01032, lng: 18.8790466 },
    { lat: 49.0102297, lng: 18.8788009 },
    { lat: 49.0102158, lng: 18.8785525 },
    { lat: 49.0106245, lng: 18.8776392 },
    { lat: 49.0108555, lng: 18.8774592 },
    { lat: 49.0110307, lng: 18.8774477 },
    { lat: 49.0117218, lng: 18.8775997 },
  ],
  Turany: [
    { lat: 49.1143302, lng: 19.0843212 },
    { lat: 49.117822, lng: 19.0832185 },
    { lat: 49.1183708, lng: 19.0834212 },
    { lat: 49.1184626, lng: 19.083452 },
    { lat: 49.1192179, lng: 19.0837268 },
    { lat: 49.1187863, lng: 19.0826549 },
    { lat: 49.1186972, lng: 19.0820378 },
    { lat: 49.1186788, lng: 19.0819626 },
    { lat: 49.1186017, lng: 19.0808367 },
    { lat: 49.1185201, lng: 19.0797352 },
    { lat: 49.1184998, lng: 19.078822 },
    { lat: 49.1229628, lng: 19.0761782 },
    { lat: 49.1229976, lng: 19.0761332 },
    { lat: 49.1233759, lng: 19.0770565 },
    { lat: 49.123772, lng: 19.0783178 },
    { lat: 49.1245908, lng: 19.080676 },
    { lat: 49.1250675, lng: 19.0818389 },
    { lat: 49.1256916, lng: 19.0829479 },
    { lat: 49.1262607, lng: 19.0838343 },
    { lat: 49.1267258, lng: 19.0844177 },
    { lat: 49.1269758, lng: 19.0847116 },
    { lat: 49.127306, lng: 19.0851654 },
    { lat: 49.1280065, lng: 19.0860001 },
    { lat: 49.1287451, lng: 19.086803 },
    { lat: 49.1291199, lng: 19.0871809 },
    { lat: 49.1294817, lng: 19.0875415 },
    { lat: 49.1296954, lng: 19.0876948 },
    { lat: 49.1301885, lng: 19.0880213 },
    { lat: 49.1301818, lng: 19.0876614 },
    { lat: 49.1306403, lng: 19.0872216 },
    { lat: 49.1320024, lng: 19.0848301 },
    { lat: 49.1316408, lng: 19.0821613 },
    { lat: 49.1312682, lng: 19.0811936 },
    { lat: 49.1325876, lng: 19.0802932 },
    { lat: 49.133441, lng: 19.0799041 },
    { lat: 49.1339199, lng: 19.0796716 },
    { lat: 49.1337774, lng: 19.0792308 },
    { lat: 49.1337227, lng: 19.0790366 },
    { lat: 49.1335949, lng: 19.0786164 },
    { lat: 49.1334844, lng: 19.0781813 },
    { lat: 49.133427, lng: 19.0777955 },
    { lat: 49.1351463, lng: 19.0773191 },
    { lat: 49.136824, lng: 19.0770103 },
    { lat: 49.1374585, lng: 19.0783072 },
    { lat: 49.1386317, lng: 19.0799874 },
    { lat: 49.1395268, lng: 19.0803106 },
    { lat: 49.140341, lng: 19.0817106 },
    { lat: 49.1404481, lng: 19.0817995 },
    { lat: 49.1405815, lng: 19.0818541 },
    { lat: 49.1411482, lng: 19.0817541 },
    { lat: 49.1414673, lng: 19.0818511 },
    { lat: 49.1416912, lng: 19.0817055 },
    { lat: 49.1418598, lng: 19.081374 },
    { lat: 49.1421143, lng: 19.081415 },
    { lat: 49.1426578, lng: 19.0815334 },
    { lat: 49.1428825, lng: 19.0815883 },
    { lat: 49.1430522, lng: 19.0816239 },
    { lat: 49.1430793, lng: 19.0815542 },
    { lat: 49.1431071, lng: 19.0815247 },
    { lat: 49.143144, lng: 19.0815399 },
    { lat: 49.1431866, lng: 19.0813313 },
    { lat: 49.1432307, lng: 19.0812632 },
    { lat: 49.1433658, lng: 19.0811901 },
    { lat: 49.1433794, lng: 19.0811705 },
    { lat: 49.1433423, lng: 19.081011 },
    { lat: 49.1432745, lng: 19.0809158 },
    { lat: 49.1433021, lng: 19.0807488 },
    { lat: 49.1432776, lng: 19.0806392 },
    { lat: 49.1434254, lng: 19.0805287 },
    { lat: 49.1434468, lng: 19.0804589 },
    { lat: 49.1434422, lng: 19.0803892 },
    { lat: 49.1435127, lng: 19.0803098 },
    { lat: 49.1435263, lng: 19.0801981 },
    { lat: 49.1434849, lng: 19.0800938 },
    { lat: 49.1434324, lng: 19.0799615 },
    { lat: 49.1433877, lng: 19.0799227 },
    { lat: 49.14338, lng: 19.0797655 },
    { lat: 49.1435588, lng: 19.0797367 },
    { lat: 49.1436536, lng: 19.0796474 },
    { lat: 49.1437664, lng: 19.0796265 },
    { lat: 49.1437791, lng: 19.0795631 },
    { lat: 49.1436223, lng: 19.0793543 },
    { lat: 49.1435818, lng: 19.0791994 },
    { lat: 49.1435192, lng: 19.0791598 },
    { lat: 49.1435233, lng: 19.0790189 },
    { lat: 49.1437026, lng: 19.0790851 },
    { lat: 49.1437536, lng: 19.0789139 },
    { lat: 49.1437585, lng: 19.0788976 },
    { lat: 49.1437979, lng: 19.0788005 },
    { lat: 49.1437773, lng: 19.0787042 },
    { lat: 49.1437151, lng: 19.0785159 },
    { lat: 49.1437298, lng: 19.0783356 },
    { lat: 49.1437045, lng: 19.0783028 },
    { lat: 49.1436907, lng: 19.0782767 },
    { lat: 49.1436984, lng: 19.0782016 },
    { lat: 49.1438109, lng: 19.0781311 },
    { lat: 49.1438665, lng: 19.0778857 },
    { lat: 49.1438147, lng: 19.0777983 },
    { lat: 49.1439001, lng: 19.0774281 },
    { lat: 49.1439019, lng: 19.0772821 },
    { lat: 49.1439233, lng: 19.077123 },
    { lat: 49.1440687, lng: 19.0769619 },
    { lat: 49.1441051, lng: 19.0768285 },
    { lat: 49.1441157, lng: 19.0766336 },
    { lat: 49.144138, lng: 19.0765817 },
    { lat: 49.1442095, lng: 19.0766613 },
    { lat: 49.1444067, lng: 19.0765699 },
    { lat: 49.1445206, lng: 19.0767963 },
    { lat: 49.1445817, lng: 19.0768029 },
    { lat: 49.1446471, lng: 19.0766892 },
    { lat: 49.1446844, lng: 19.0763965 },
    { lat: 49.1447594, lng: 19.0764084 },
    { lat: 49.1447295, lng: 19.0761674 },
    { lat: 49.1448181, lng: 19.0760182 },
    { lat: 49.14498, lng: 19.0759201 },
    { lat: 49.1449707, lng: 19.0758235 },
    { lat: 49.1449498, lng: 19.0758342 },
    { lat: 49.1449231, lng: 19.0758204 },
    { lat: 49.1449848, lng: 19.0756541 },
    { lat: 49.145073, lng: 19.075523 },
    { lat: 49.1451853, lng: 19.0754248 },
    { lat: 49.1453641, lng: 19.0753661 },
    { lat: 49.1454255, lng: 19.0753796 },
    { lat: 49.1454838, lng: 19.0751637 },
    { lat: 49.1453888, lng: 19.0750687 },
    { lat: 49.1454565, lng: 19.0748658 },
    { lat: 49.1456792, lng: 19.074582 },
    { lat: 49.1459104, lng: 19.0743999 },
    { lat: 49.1459773, lng: 19.0743195 },
    { lat: 49.1460692, lng: 19.0740573 },
    { lat: 49.1462674, lng: 19.0737335 },
    { lat: 49.1462934, lng: 19.0737139 },
    { lat: 49.1464318, lng: 19.0737999 },
    { lat: 49.1465275, lng: 19.0735221 },
    { lat: 49.1466504, lng: 19.0733625 },
    { lat: 49.1467791, lng: 19.0730932 },
    { lat: 49.1467952, lng: 19.0728947 },
    { lat: 49.1468166, lng: 19.0725293 },
    { lat: 49.1468051, lng: 19.0723437 },
    { lat: 49.1468807, lng: 19.0720711 },
    { lat: 49.146802, lng: 19.0715512 },
    { lat: 49.1468529, lng: 19.0713952 },
    { lat: 49.1469412, lng: 19.0713562 },
    { lat: 49.1469559, lng: 19.0710384 },
    { lat: 49.1469983, lng: 19.0708481 },
    { lat: 49.1472003, lng: 19.0706679 },
    { lat: 49.1472617, lng: 19.0705427 },
    { lat: 49.1471715, lng: 19.0702247 },
    { lat: 49.1471009, lng: 19.070029 },
    { lat: 49.1469833, lng: 19.0700194 },
    { lat: 49.1469075, lng: 19.0697876 },
    { lat: 49.1468063, lng: 19.06945 },
    { lat: 49.146934, lng: 19.0693676 },
    { lat: 49.1469437, lng: 19.0692873 },
    { lat: 49.1469243, lng: 19.0692384 },
    { lat: 49.1468303, lng: 19.0692283 },
    { lat: 49.1468086, lng: 19.0691102 },
    { lat: 49.1468884, lng: 19.0686937 },
    { lat: 49.1468972, lng: 19.068257 },
    { lat: 49.1468651, lng: 19.0679046 },
    { lat: 49.1468145, lng: 19.0675449 },
    { lat: 49.1467256, lng: 19.0672721 },
    { lat: 49.1466565, lng: 19.0672459 },
    { lat: 49.1465201, lng: 19.0670621 },
    { lat: 49.1464511, lng: 19.06685 },
    { lat: 49.1463084, lng: 19.0667688 },
    { lat: 49.1461645, lng: 19.0664815 },
    { lat: 49.1461195, lng: 19.0661403 },
    { lat: 49.1461385, lng: 19.0657283 },
    { lat: 49.1461146, lng: 19.0653847 },
    { lat: 49.1459713, lng: 19.0651513 },
    { lat: 49.1460397, lng: 19.064801 },
    { lat: 49.1460272, lng: 19.0646638 },
    { lat: 49.1459139, lng: 19.0643783 },
    { lat: 49.1458934, lng: 19.0641455 },
    { lat: 49.1459067, lng: 19.0638941 },
    { lat: 49.1458645, lng: 19.0637927 },
    { lat: 49.1459496, lng: 19.0635316 },
    { lat: 49.1460021, lng: 19.0631788 },
    { lat: 49.1460715, lng: 19.0629454 },
    { lat: 49.1461177, lng: 19.0627381 },
    { lat: 49.146139, lng: 19.0625102 },
    { lat: 49.1461685, lng: 19.0622854 },
    { lat: 49.1462798, lng: 19.0621858 },
    { lat: 49.1464154, lng: 19.0617374 },
    { lat: 49.1465853, lng: 19.0614564 },
    { lat: 49.1467975, lng: 19.0612591 },
    { lat: 49.147001, lng: 19.0610409 },
    { lat: 49.1481752, lng: 19.0601499 },
    { lat: 49.1492643, lng: 19.0593334 },
    { lat: 49.1498935, lng: 19.0588356 },
    { lat: 49.1501647, lng: 19.0586571 },
    { lat: 49.1513132, lng: 19.0579818 },
    { lat: 49.1530444, lng: 19.0574283 },
    { lat: 49.1531397, lng: 19.0572844 },
    { lat: 49.1543922, lng: 19.0569647 },
    { lat: 49.1559341, lng: 19.0564866 },
    { lat: 49.1564608, lng: 19.056127 },
    { lat: 49.1565981, lng: 19.0559394 },
    { lat: 49.1569854, lng: 19.0555882 },
    { lat: 49.1572604, lng: 19.0554996 },
    { lat: 49.1574305, lng: 19.0554944 },
    { lat: 49.1577073, lng: 19.0552558 },
    { lat: 49.1582679, lng: 19.0545382 },
    { lat: 49.1592684, lng: 19.0536625 },
    { lat: 49.1598174, lng: 19.052779 },
    { lat: 49.1599416, lng: 19.0525544 },
    { lat: 49.160124, lng: 19.0523025 },
    { lat: 49.1603074, lng: 19.051951 },
    { lat: 49.1603743, lng: 19.0518709 },
    { lat: 49.1606308, lng: 19.051837 },
    { lat: 49.1607434, lng: 19.0516738 },
    { lat: 49.1613052, lng: 19.0514991 },
    { lat: 49.1614474, lng: 19.0512558 },
    { lat: 49.1621262, lng: 19.0505313 },
    { lat: 49.1623802, lng: 19.0506079 },
    { lat: 49.1625976, lng: 19.0502392 },
    { lat: 49.1628725, lng: 19.0500576 },
    { lat: 49.1629492, lng: 19.0497174 },
    { lat: 49.163292, lng: 19.0496033 },
    { lat: 49.1636992, lng: 19.0491326 },
    { lat: 49.1642466, lng: 19.0484207 },
    { lat: 49.1646085, lng: 19.0482633 },
    { lat: 49.1649634, lng: 19.0479608 },
    { lat: 49.1658265, lng: 19.0473169 },
    { lat: 49.1667294, lng: 19.0469071 },
    { lat: 49.1668593, lng: 19.0468482 },
    { lat: 49.1677577, lng: 19.0469588 },
    { lat: 49.1679839, lng: 19.046999 },
    { lat: 49.1688499, lng: 19.0473896 },
    { lat: 49.1694954, lng: 19.0471076 },
    { lat: 49.1695732, lng: 19.0471274 },
    { lat: 49.1705347, lng: 19.0467621 },
    { lat: 49.1708461, lng: 19.0462696 },
    { lat: 49.1711993, lng: 19.0460743 },
    { lat: 49.1714233, lng: 19.0460664 },
    { lat: 49.1718438, lng: 19.0457718 },
    { lat: 49.1723426, lng: 19.0449 },
    { lat: 49.1726723, lng: 19.0443669 },
    { lat: 49.1728717, lng: 19.0441814 },
    { lat: 49.1734989, lng: 19.0435782 },
    { lat: 49.1737532, lng: 19.043338 },
    { lat: 49.1749969, lng: 19.0419401 },
    { lat: 49.1755876, lng: 19.0416009 },
    { lat: 49.1764093, lng: 19.0414371 },
    { lat: 49.1766035, lng: 19.0415573 },
    { lat: 49.1771903, lng: 19.0416851 },
    { lat: 49.1774456, lng: 19.0418743 },
    { lat: 49.1776109, lng: 19.0419586 },
    { lat: 49.1779966, lng: 19.0424142 },
    { lat: 49.1788787, lng: 19.0428145 },
    { lat: 49.1789329, lng: 19.0428169 },
    { lat: 49.1792171, lng: 19.0430229 },
    { lat: 49.1797402, lng: 19.0430244 },
    { lat: 49.1800023, lng: 19.0431689 },
    { lat: 49.1805677, lng: 19.0434102 },
    { lat: 49.1809067, lng: 19.0436532 },
    { lat: 49.1811935, lng: 19.0435717 },
    { lat: 49.181777, lng: 19.0437011 },
    { lat: 49.1820413, lng: 19.0436201 },
    { lat: 49.1822347, lng: 19.0437008 },
    { lat: 49.1824029, lng: 19.0438615 },
    { lat: 49.1827005, lng: 19.0439327 },
    { lat: 49.1830433, lng: 19.0435053 },
    { lat: 49.1834735, lng: 19.0432686 },
    { lat: 49.184458, lng: 19.0425245 },
    { lat: 49.1848582, lng: 19.042279 },
    { lat: 49.1852331, lng: 19.041952 },
    { lat: 49.1855399, lng: 19.0417735 },
    { lat: 49.1857475, lng: 19.0417304 },
    { lat: 49.1859268, lng: 19.0415969 },
    { lat: 49.1861635, lng: 19.0415745 },
    { lat: 49.186491, lng: 19.0414306 },
    { lat: 49.186563, lng: 19.041399 },
    { lat: 49.1872701, lng: 19.041248 },
    { lat: 49.1877411, lng: 19.0408343 },
    { lat: 49.1880608, lng: 19.0398963 },
    { lat: 49.188274, lng: 19.038877 },
    { lat: 49.1899701, lng: 19.037441 },
    { lat: 49.191977, lng: 19.0365 },
    { lat: 49.190959, lng: 19.0352 },
    { lat: 49.189937, lng: 19.033922 },
    { lat: 49.188599, lng: 19.03223 },
    { lat: 49.188562, lng: 19.032164 },
    { lat: 49.188412, lng: 19.031896 },
    { lat: 49.188093, lng: 19.031332 },
    { lat: 49.18749, lng: 19.030837 },
    { lat: 49.187748, lng: 19.030853 },
    { lat: 49.187812, lng: 19.030773 },
    { lat: 49.187955, lng: 19.030592 },
    { lat: 49.188384, lng: 19.030436 },
    { lat: 49.188851, lng: 19.029614 },
    { lat: 49.18893, lng: 19.029476 },
    { lat: 49.189671, lng: 19.02895 },
    { lat: 49.189934, lng: 19.028763 },
    { lat: 49.190105, lng: 19.028411 },
    { lat: 49.190296, lng: 19.028329 },
    { lat: 49.190349, lng: 19.028069 },
    { lat: 49.190826, lng: 19.026316 },
    { lat: 49.19115, lng: 19.02543 },
    { lat: 49.191031, lng: 19.024595 },
    { lat: 49.191008, lng: 19.024485 },
    { lat: 49.190749, lng: 19.023575 },
    { lat: 49.190749, lng: 19.023574 },
    { lat: 49.190849, lng: 19.023009 },
    { lat: 49.191197, lng: 19.021049 },
    { lat: 49.191191, lng: 19.020745 },
    { lat: 49.191185, lng: 19.020446 },
    { lat: 49.191137, lng: 19.020322 },
    { lat: 49.191088, lng: 19.020197 },
    { lat: 49.191104, lng: 19.020177 },
    { lat: 49.1911, lng: 19.020121 },
    { lat: 49.191088, lng: 19.01997 },
    { lat: 49.190912, lng: 19.019124 },
    { lat: 49.190659, lng: 19.018321 },
    { lat: 49.190639, lng: 19.018297 },
    { lat: 49.19062, lng: 19.018274 },
    { lat: 49.190627, lng: 19.018242 },
    { lat: 49.190726, lng: 19.018066 },
    { lat: 49.190885, lng: 19.017872 },
    { lat: 49.191008, lng: 19.017722 },
    { lat: 49.191047, lng: 19.01767 },
    { lat: 49.191205, lng: 19.017477 },
    { lat: 49.191657, lng: 19.016922 },
    { lat: 49.191698, lng: 19.016876 },
    { lat: 49.191811, lng: 19.016865 },
    { lat: 49.192755, lng: 19.015904 },
    { lat: 49.193007, lng: 19.015262 },
    { lat: 49.193117, lng: 19.014286 },
    { lat: 49.193185, lng: 19.013692 },
    { lat: 49.194003, lng: 19.012133 },
    { lat: 49.193986, lng: 19.012023 },
    { lat: 49.193736, lng: 19.010427 },
    { lat: 49.193538, lng: 19.009344 },
    { lat: 49.19377, lng: 19.008123 },
    { lat: 49.193564, lng: 19.007768 },
    { lat: 49.193332, lng: 19.007366 },
    { lat: 49.192896, lng: 19.00591 },
    { lat: 49.192473, lng: 19.004494 },
    { lat: 49.191845, lng: 19.00407 },
    { lat: 49.191804, lng: 19.004067 },
    { lat: 49.191708, lng: 19.00406 },
    { lat: 49.191684, lng: 19.004073 },
    { lat: 49.191474, lng: 19.004043 },
    { lat: 49.191293, lng: 19.003934 },
    { lat: 49.191277, lng: 19.003925 },
    { lat: 49.191236, lng: 19.0039 },
    { lat: 49.190818, lng: 19.00365 },
    { lat: 49.189328, lng: 19.002795 },
    { lat: 49.187543, lng: 19.001682 },
    { lat: 49.186342, lng: 18.999716 },
    { lat: 49.186173, lng: 18.999184 },
    { lat: 49.186039, lng: 18.99896 },
    { lat: 49.185723, lng: 18.998433 },
    { lat: 49.18524, lng: 18.998241 },
    { lat: 49.185238, lng: 18.998241 },
    { lat: 49.184618, lng: 18.998285 },
    { lat: 49.184052, lng: 18.997912 },
    { lat: 49.183792, lng: 18.997637 },
    { lat: 49.183103, lng: 18.996091 },
    { lat: 49.182765, lng: 18.9951 },
    { lat: 49.182521, lng: 18.994306 },
    { lat: 49.1821749, lng: 18.9935834 },
    { lat: 49.182145, lng: 18.993521 },
    { lat: 49.1811042, lng: 18.9929986 },
    { lat: 49.1800621, lng: 18.9928069 },
    { lat: 49.1789551, lng: 18.9915966 },
    { lat: 49.1777031, lng: 18.9901874 },
    { lat: 49.1768514, lng: 18.9884001 },
    { lat: 49.1758494, lng: 18.9862927 },
    { lat: 49.1741996, lng: 18.9869342 },
    { lat: 49.1725998, lng: 18.9872445 },
    { lat: 49.1702487, lng: 18.9872338 },
    { lat: 49.1696426, lng: 18.9873345 },
    { lat: 49.1690459, lng: 18.9867877 },
    { lat: 49.1680907, lng: 18.9874777 },
    { lat: 49.1671256, lng: 18.9876804 },
    { lat: 49.1668363, lng: 18.9875502 },
    { lat: 49.1656322, lng: 18.9880207 },
    { lat: 49.1650368, lng: 18.9880426 },
    { lat: 49.16489, lng: 18.9881717 },
    { lat: 49.1645368, lng: 18.9881845 },
    { lat: 49.1641872, lng: 18.988182 },
    { lat: 49.1639171, lng: 18.9881944 },
    { lat: 49.1628387, lng: 18.9884067 },
    { lat: 49.162451, lng: 18.9885834 },
    { lat: 49.162134, lng: 18.988919 },
    { lat: 49.1613814, lng: 18.9892438 },
    { lat: 49.1608999, lng: 18.9895784 },
    { lat: 49.160173, lng: 18.9906386 },
    { lat: 49.1585323, lng: 18.9909004 },
    { lat: 49.158155, lng: 18.9917855 },
    { lat: 49.157612, lng: 18.9922208 },
    { lat: 49.1575445, lng: 18.9921743 },
    { lat: 49.1575324, lng: 18.9922078 },
    { lat: 49.1573902, lng: 18.9920475 },
    { lat: 49.1571566, lng: 18.9913637 },
    { lat: 49.1566496, lng: 18.990879 },
    { lat: 49.1564114, lng: 18.9904853 },
    { lat: 49.1559445, lng: 18.9902581 },
    { lat: 49.1558344, lng: 18.9902915 },
    { lat: 49.1555462, lng: 18.9902541 },
    { lat: 49.155362, lng: 18.989995 },
    { lat: 49.1550064, lng: 18.9899428 },
    { lat: 49.154739, lng: 18.9898955 },
    { lat: 49.154434, lng: 18.9896552 },
    { lat: 49.1542932, lng: 18.9897249 },
    { lat: 49.1541271, lng: 18.9897864 },
    { lat: 49.1539609, lng: 18.9897316 },
    { lat: 49.1537658, lng: 18.9898213 },
    { lat: 49.1535888, lng: 18.9898207 },
    { lat: 49.1534247, lng: 18.9898316 },
    { lat: 49.1534159, lng: 18.9898553 },
    { lat: 49.152678, lng: 18.989937 },
    { lat: 49.1519839, lng: 18.9899639 },
    { lat: 49.1512862, lng: 18.9899433 },
    { lat: 49.1506081, lng: 18.9900684 },
    { lat: 49.1498755, lng: 18.9904967 },
    { lat: 49.1495238, lng: 18.9910379 },
    { lat: 49.1490254, lng: 18.9912617 },
    { lat: 49.1486236, lng: 18.9915213 },
    { lat: 49.1482326, lng: 18.9921744 },
    { lat: 49.1477019, lng: 18.9922715 },
    { lat: 49.1469327, lng: 18.9919744 },
    { lat: 49.1461008, lng: 18.9921333 },
    { lat: 49.1457425, lng: 18.9921142 },
    { lat: 49.1450954, lng: 18.9919998 },
    { lat: 49.1445525, lng: 18.9921895 },
    { lat: 49.1441968, lng: 18.9926944 },
    { lat: 49.1435183, lng: 18.9939145 },
    { lat: 49.143133, lng: 18.9942583 },
    { lat: 49.1428146, lng: 18.9945589 },
    { lat: 49.1421124, lng: 18.994586 },
    { lat: 49.1417699, lng: 18.9949975 },
    { lat: 49.1416241, lng: 18.9956406 },
    { lat: 49.1414754, lng: 18.9961339 },
    { lat: 49.1413296, lng: 18.9966467 },
    { lat: 49.1409601, lng: 18.9969429 },
    { lat: 49.1407043, lng: 18.9967879 },
    { lat: 49.1405158, lng: 18.9964682 },
    { lat: 49.1403778, lng: 18.9959877 },
    { lat: 49.1402513, lng: 18.9957824 },
    { lat: 49.1401467, lng: 18.9956744 },
    { lat: 49.1399507, lng: 18.995677 },
    { lat: 49.1399194, lng: 18.9958391 },
    { lat: 49.1394315, lng: 18.9962707 },
    { lat: 49.1390943, lng: 18.9965148 },
    { lat: 49.138595, lng: 18.9969211 },
    { lat: 49.1386102, lng: 18.9974274 },
    { lat: 49.1384147, lng: 18.9975517 },
    { lat: 49.1376025, lng: 18.997499 },
    { lat: 49.1375157, lng: 18.9974083 },
    { lat: 49.1374455, lng: 18.9973797 },
    { lat: 49.1370241, lng: 18.9973618 },
    { lat: 49.1368558, lng: 18.9971744 },
    { lat: 49.136638, lng: 18.9969923 },
    { lat: 49.1365078, lng: 18.996916 },
    { lat: 49.1364503, lng: 18.9968014 },
    { lat: 49.136268, lng: 18.9968716 },
    { lat: 49.136235, lng: 18.9968042 },
    { lat: 49.1361304, lng: 18.9968045 },
    { lat: 49.1360759, lng: 18.9968914 },
    { lat: 49.1359489, lng: 18.9968263 },
    { lat: 49.1358937, lng: 18.996917 },
    { lat: 49.1357545, lng: 18.9969307 },
    { lat: 49.1357048, lng: 18.9968626 },
    { lat: 49.1356174, lng: 18.9968728 },
    { lat: 49.1355996, lng: 18.996899 },
    { lat: 49.1353301, lng: 18.9969426 },
    { lat: 49.1352013, lng: 18.9970725 },
    { lat: 49.1349896, lng: 18.9971011 },
    { lat: 49.1349017, lng: 18.9971246 },
    { lat: 49.1347662, lng: 18.997319 },
    { lat: 49.1346938, lng: 18.9973825 },
    { lat: 49.1345123, lng: 18.9974047 },
    { lat: 49.1337701, lng: 18.9973769 },
    { lat: 49.1337401, lng: 18.9974726 },
    { lat: 49.1336807, lng: 18.9973708 },
    { lat: 49.1335165, lng: 18.997423 },
    { lat: 49.1333964, lng: 18.997365 },
    { lat: 49.1332121, lng: 18.9975805 },
    { lat: 49.1330996, lng: 18.9976076 },
    { lat: 49.1326633, lng: 18.9978758 },
    { lat: 49.1322874, lng: 18.9976629 },
    { lat: 49.1321888, lng: 18.9976769 },
    { lat: 49.132092, lng: 18.9976108 },
    { lat: 49.1319182, lng: 18.9977865 },
    { lat: 49.1318027, lng: 18.9977783 },
    { lat: 49.1317383, lng: 18.9978643 },
    { lat: 49.1316863, lng: 18.9977703 },
    { lat: 49.1315042, lng: 18.9978746 },
    { lat: 49.1312441, lng: 18.9977901 },
    { lat: 49.131071, lng: 18.9976389 },
    { lat: 49.1309072, lng: 18.9975435 },
    { lat: 49.1307298, lng: 18.997558 },
    { lat: 49.1306244, lng: 18.9973913 },
    { lat: 49.1305587, lng: 18.9974058 },
    { lat: 49.1304181, lng: 18.9975043 },
    { lat: 49.1302602, lng: 18.9974542 },
    { lat: 49.1300171, lng: 18.9976431 },
    { lat: 49.1296664, lng: 18.9975323 },
    { lat: 49.1295123, lng: 18.9976723 },
    { lat: 49.1291486, lng: 18.9975883 },
    { lat: 49.1290521, lng: 18.9977334 },
    { lat: 49.1288386, lng: 18.9976582 },
    { lat: 49.1287202, lng: 18.9974549 },
    { lat: 49.1286707, lng: 18.9974816 },
    { lat: 49.1283689, lng: 18.9976905 },
    { lat: 49.1281272, lng: 18.997836 },
    { lat: 49.1279544, lng: 18.9977636 },
    { lat: 49.127838, lng: 18.9977588 },
    { lat: 49.1276849, lng: 18.9976931 },
    { lat: 49.1276078, lng: 18.9976207 },
    { lat: 49.1274546, lng: 18.9978261 },
    { lat: 49.1273167, lng: 18.9977273 },
    { lat: 49.1270861, lng: 18.9978269 },
    { lat: 49.1270141, lng: 18.9976725 },
    { lat: 49.1269026, lng: 18.9976535 },
    { lat: 49.1268381, lng: 18.9977805 },
    { lat: 49.1267583, lng: 18.9977189 },
    { lat: 49.1266674, lng: 18.9979084 },
    { lat: 49.1265401, lng: 18.9980236 },
    { lat: 49.1263584, lng: 18.9980181 },
    { lat: 49.1262618, lng: 18.9980927 },
    { lat: 49.1258879, lng: 18.9982777 },
    { lat: 49.1258097, lng: 18.9982742 },
    { lat: 49.1257017, lng: 18.9983436 },
    { lat: 49.1256568, lng: 18.9981876 },
    { lat: 49.1254666, lng: 18.9982619 },
    { lat: 49.1253584, lng: 18.9981737 },
    { lat: 49.1253126, lng: 18.9982719 },
    { lat: 49.1251454, lng: 18.9982446 },
    { lat: 49.124712, lng: 18.9987046 },
    { lat: 49.1245418, lng: 18.9987492 },
    { lat: 49.124541, lng: 18.9985776 },
    { lat: 49.1243398, lng: 18.9984567 },
    { lat: 49.1241998, lng: 18.9985205 },
    { lat: 49.1241614, lng: 18.9986542 },
    { lat: 49.123942, lng: 18.9984566 },
    { lat: 49.1238386, lng: 18.9984632 },
    { lat: 49.1237513, lng: 18.9985878 },
    { lat: 49.1235271, lng: 18.9984786 },
    { lat: 49.1233222, lng: 18.9984628 },
    { lat: 49.1232513, lng: 18.9985326 },
    { lat: 49.1232203, lng: 18.9983382 },
    { lat: 49.1230511, lng: 18.998409 },
    { lat: 49.1229842, lng: 18.9982847 },
    { lat: 49.1228663, lng: 18.9983845 },
    { lat: 49.1227669, lng: 18.9984266 },
    { lat: 49.1226052, lng: 18.9982136 },
    { lat: 49.1224804, lng: 18.9981781 },
    { lat: 49.1224812, lng: 18.9980809 },
    { lat: 49.1224008, lng: 18.9980558 },
    { lat: 49.1222762, lng: 18.9980169 },
    { lat: 49.1221919, lng: 18.9981545 },
    { lat: 49.1221514, lng: 18.9982045 },
    { lat: 49.1220463, lng: 18.9981768 },
    { lat: 49.1220003, lng: 18.9982707 },
    { lat: 49.1218636, lng: 18.9983536 },
    { lat: 49.1218152, lng: 18.9984431 },
    { lat: 49.1217372, lng: 18.9983711 },
    { lat: 49.1215727, lng: 18.9987146 },
    { lat: 49.1215089, lng: 18.998699 },
    { lat: 49.1214674, lng: 18.998815 },
    { lat: 49.1213213, lng: 18.9991178 },
    { lat: 49.1212322, lng: 18.9990277 },
    { lat: 49.1211805, lng: 18.998915 },
    { lat: 49.1211244, lng: 18.9989892 },
    { lat: 49.120948, lng: 18.999023 },
    { lat: 49.1209127, lng: 18.9991542 },
    { lat: 49.1208234, lng: 18.9993049 },
    { lat: 49.1207308, lng: 18.9993034 },
    { lat: 49.1204991, lng: 18.9995356 },
    { lat: 49.1204425, lng: 18.9996706 },
    { lat: 49.1202888, lng: 18.9997529 },
    { lat: 49.1202025, lng: 18.9998259 },
    { lat: 49.1199934, lng: 18.9998866 },
    { lat: 49.1200161, lng: 18.9999581 },
    { lat: 49.119933, lng: 19.00003 },
    { lat: 49.1198189, lng: 18.9999785 },
    { lat: 49.1197775, lng: 19.0000528 },
    { lat: 49.119767, lng: 19.000225 },
    { lat: 49.1196952, lng: 19.0003471 },
    { lat: 49.1196689, lng: 19.0005859 },
    { lat: 49.1195397, lng: 19.0006851 },
    { lat: 49.1194864, lng: 19.000814 },
    { lat: 49.119265, lng: 19.0011209 },
    { lat: 49.1192173, lng: 19.0014025 },
    { lat: 49.1191565, lng: 19.0014293 },
    { lat: 49.1191953, lng: 19.0017091 },
    { lat: 49.1191077, lng: 19.0016909 },
    { lat: 49.1190993, lng: 19.0017509 },
    { lat: 49.1190134, lng: 19.0017479 },
    { lat: 49.1190156, lng: 19.0018798 },
    { lat: 49.1188684, lng: 19.0018656 },
    { lat: 49.1188139, lng: 19.0019464 },
    { lat: 49.1186132, lng: 19.0020813 },
    { lat: 49.1185291, lng: 19.0023167 },
    { lat: 49.1182998, lng: 19.0023089 },
    { lat: 49.1182599, lng: 19.0023901 },
    { lat: 49.1181763, lng: 19.0024099 },
    { lat: 49.1180306, lng: 19.002496 },
    { lat: 49.1178698, lng: 19.0025683 },
    { lat: 49.1177616, lng: 19.0026806 },
    { lat: 49.1175938, lng: 19.0027314 },
    { lat: 49.1175483, lng: 19.0026111 },
    { lat: 49.1173524, lng: 19.0026849 },
    { lat: 49.1174175, lng: 19.0028336 },
    { lat: 49.1173789, lng: 19.0029878 },
    { lat: 49.1173496, lng: 19.0033212 },
    { lat: 49.1173201, lng: 19.0034779 },
    { lat: 49.1171307, lng: 19.0036349 },
    { lat: 49.117025, lng: 19.0035713 },
    { lat: 49.1170056, lng: 19.0034353 },
    { lat: 49.1169021, lng: 19.0034394 },
    { lat: 49.1168186, lng: 19.0036154 },
    { lat: 49.1168319, lng: 19.0039288 },
    { lat: 49.1168839, lng: 19.0041073 },
    { lat: 49.1167985, lng: 19.004181 },
    { lat: 49.1166563, lng: 19.0040924 },
    { lat: 49.1166139, lng: 19.0043442 },
    { lat: 49.1164079, lng: 19.0044437 },
    { lat: 49.116129, lng: 19.0042994 },
    { lat: 49.1160002, lng: 19.0043831 },
    { lat: 49.1159036, lng: 19.0045711 },
    { lat: 49.11556, lng: 19.0047535 },
    { lat: 49.1153141, lng: 19.0048447 },
    { lat: 49.1152877, lng: 19.0050144 },
    { lat: 49.1152368, lng: 19.0050984 },
    { lat: 49.1151815, lng: 19.005098 },
    { lat: 49.1151257, lng: 19.0052002 },
    { lat: 49.1150809, lng: 19.0053853 },
    { lat: 49.1147961, lng: 19.0055761 },
    { lat: 49.11466, lng: 19.0056268 },
    { lat: 49.1145525, lng: 19.0057735 },
    { lat: 49.1144052, lng: 19.0058514 },
    { lat: 49.1142683, lng: 19.0059757 },
    { lat: 49.1141679, lng: 19.0060186 },
    { lat: 49.1139198, lng: 19.0060842 },
    { lat: 49.113735, lng: 19.0062427 },
    { lat: 49.1136812, lng: 19.0063616 },
    { lat: 49.1134763, lng: 19.006751 },
    { lat: 49.113288, lng: 19.0069079 },
    { lat: 49.113209, lng: 19.0069286 },
    { lat: 49.1127982, lng: 19.006878 },
    { lat: 49.1126818, lng: 19.0068271 },
    { lat: 49.1126139, lng: 19.0067541 },
    { lat: 49.1125568, lng: 19.0067603 },
    { lat: 49.1124888, lng: 19.0069118 },
    { lat: 49.1123907, lng: 19.0069991 },
    { lat: 49.1123693, lng: 19.0070689 },
    { lat: 49.1124101, lng: 19.0071372 },
    { lat: 49.1123224, lng: 19.0072784 },
    { lat: 49.1122466, lng: 19.0073203 },
    { lat: 49.112084, lng: 19.007178 },
    { lat: 49.1118837, lng: 19.0071181 },
    { lat: 49.1117979, lng: 19.0071633 },
    { lat: 49.1117306, lng: 19.0072593 },
    { lat: 49.1116508, lng: 19.0073064 },
    { lat: 49.1114008, lng: 19.0073811 },
    { lat: 49.1111847, lng: 19.0075239 },
    { lat: 49.1111052, lng: 19.0076238 },
    { lat: 49.1110654, lng: 19.0077079 },
    { lat: 49.1109716, lng: 19.007952 },
    { lat: 49.110869, lng: 19.0079042 },
    { lat: 49.1106556, lng: 19.0077645 },
    { lat: 49.1105841, lng: 19.0077761 },
    { lat: 49.1105103, lng: 19.0076083 },
    { lat: 49.1102121, lng: 19.0072353 },
    { lat: 49.1101125, lng: 19.0068456 },
    { lat: 49.1099434, lng: 19.0067321 },
    { lat: 49.1099459, lng: 19.0067833 },
    { lat: 49.1095356, lng: 19.0071238 },
    { lat: 49.1093879, lng: 19.0073921 },
    { lat: 49.1092334, lng: 19.0075706 },
    { lat: 49.1087492, lng: 19.0080532 },
    { lat: 49.1087271, lng: 19.0080892 },
    { lat: 49.1084494, lng: 19.0078548 },
    { lat: 49.1084391, lng: 19.0078719 },
    { lat: 49.1083226, lng: 19.0079318 },
    { lat: 49.1081863, lng: 19.0079368 },
    { lat: 49.1081213, lng: 19.0077181 },
    { lat: 49.1080953, lng: 19.0075156 },
    { lat: 49.1078934, lng: 19.0069497 },
    { lat: 49.1078812, lng: 19.0068388 },
    { lat: 49.1077971, lng: 19.006759 },
    { lat: 49.1076876, lng: 19.0067098 },
    { lat: 49.1077341, lng: 19.0065569 },
    { lat: 49.1077297, lng: 19.0064272 },
    { lat: 49.1076093, lng: 19.0063145 },
    { lat: 49.1075342, lng: 19.0063491 },
    { lat: 49.1074053, lng: 19.0061619 },
    { lat: 49.1073399, lng: 19.0062526 },
    { lat: 49.1072409, lng: 19.0061428 },
    { lat: 49.1071034, lng: 19.0060969 },
    { lat: 49.1070218, lng: 19.0059999 },
    { lat: 49.1069917, lng: 19.0060505 },
    { lat: 49.1049851, lng: 19.0066157 },
    { lat: 49.1044928, lng: 19.0067812 },
    { lat: 49.1044576, lng: 19.008645 },
    { lat: 49.1044217, lng: 19.0097077 },
    { lat: 49.1043659, lng: 19.0114531 },
    { lat: 49.1043655, lng: 19.0114913 },
    { lat: 49.1041925, lng: 19.0116376 },
    { lat: 49.1041967, lng: 19.0123308 },
    { lat: 49.1041625, lng: 19.0135405 },
    { lat: 49.1042145, lng: 19.0144674 },
    { lat: 49.1043085, lng: 19.0151523 },
    { lat: 49.1044229, lng: 19.0157723 },
    { lat: 49.1046824, lng: 19.0169274 },
    { lat: 49.1047139, lng: 19.0171504 },
    { lat: 49.104826, lng: 19.0173191 },
    { lat: 49.1050843, lng: 19.017704 },
    { lat: 49.1054577, lng: 19.0187239 },
    { lat: 49.1057618, lng: 19.0195529 },
    { lat: 49.105963, lng: 19.0199715 },
    { lat: 49.1062384, lng: 19.0205422 },
    { lat: 49.1064779, lng: 19.0207928 },
    { lat: 49.1065763, lng: 19.0215438 },
    { lat: 49.1066268, lng: 19.0226907 },
    { lat: 49.1067416, lng: 19.0231339 },
    { lat: 49.107055, lng: 19.0236707 },
    { lat: 49.1062296, lng: 19.024695 },
    { lat: 49.1052478, lng: 19.0260336 },
    { lat: 49.104313, lng: 19.0252723 },
    { lat: 49.1037747, lng: 19.024828 },
    { lat: 49.1035167, lng: 19.0246782 },
    { lat: 49.1033696, lng: 19.0246416 },
    { lat: 49.1030373, lng: 19.0248405 },
    { lat: 49.1025137, lng: 19.0262772 },
    { lat: 49.1020504, lng: 19.0276336 },
    { lat: 49.1017923, lng: 19.0288309 },
    { lat: 49.1015385, lng: 19.0303195 },
    { lat: 49.1014607, lng: 19.0310905 },
    { lat: 49.1014089, lng: 19.032469 },
    { lat: 49.1017787, lng: 19.0330505 },
    { lat: 49.1017732, lng: 19.034375 },
    { lat: 49.1021429, lng: 19.035322 },
    { lat: 49.1022444, lng: 19.0354578 },
    { lat: 49.1024265, lng: 19.0355148 },
    { lat: 49.1027156, lng: 19.0359549 },
    { lat: 49.1029461, lng: 19.0363765 },
    { lat: 49.103275, lng: 19.0370462 },
    { lat: 49.1039807, lng: 19.0382531 },
    { lat: 49.104182, lng: 19.0387202 },
    { lat: 49.1050291, lng: 19.041034 },
    { lat: 49.1051927, lng: 19.0417394 },
    { lat: 49.1052286, lng: 19.0421648 },
    { lat: 49.1053747, lng: 19.0446457 },
    { lat: 49.1056654, lng: 19.0464107 },
    { lat: 49.105801, lng: 19.0474863 },
    { lat: 49.1062173, lng: 19.0494406 },
    { lat: 49.1062439, lng: 19.0497052 },
    { lat: 49.1063248, lng: 19.0505151 },
    { lat: 49.1063355, lng: 19.0506154 },
    { lat: 49.1064678, lng: 19.0520573 },
    { lat: 49.1065384, lng: 19.0526849 },
    { lat: 49.1066427, lng: 19.0536051 },
    { lat: 49.1069154, lng: 19.0549863 },
    { lat: 49.1069896, lng: 19.0556394 },
    { lat: 49.107087, lng: 19.0564885 },
    { lat: 49.1072293, lng: 19.0577451 },
    { lat: 49.1075992, lng: 19.0600642 },
    { lat: 49.1080658, lng: 19.0613796 },
    { lat: 49.1085831, lng: 19.0628331 },
    { lat: 49.1089194, lng: 19.063569 },
    { lat: 49.109215, lng: 19.0640719 },
    { lat: 49.1105462, lng: 19.0646841 },
    { lat: 49.1105369, lng: 19.0650684 },
    { lat: 49.1109143, lng: 19.0655863 },
    { lat: 49.1122738, lng: 19.0674519 },
    { lat: 49.1124702, lng: 19.0678446 },
    { lat: 49.1130082, lng: 19.0689195 },
    { lat: 49.113171, lng: 19.0692462 },
    { lat: 49.1138441, lng: 19.0696569 },
    { lat: 49.1137931, lng: 19.0708376 },
    { lat: 49.1138044, lng: 19.0710648 },
    { lat: 49.1138877, lng: 19.0726098 },
    { lat: 49.1136854, lng: 19.0753577 },
    { lat: 49.1132351, lng: 19.0773532 },
    { lat: 49.1131079, lng: 19.0781109 },
    { lat: 49.1130638, lng: 19.0783783 },
    { lat: 49.1136558, lng: 19.0785673 },
    { lat: 49.1134778, lng: 19.0814646 },
    { lat: 49.1143302, lng: 19.0843212 },
  ],
  TurčianskaŠtiavnička: [
    { lat: 49.0761056, lng: 18.9895816 },
    { lat: 49.076024, lng: 18.9898852 },
    { lat: 49.0758834, lng: 18.9907452 },
    { lat: 49.0750835, lng: 18.9896114 },
    { lat: 49.0748709, lng: 18.9893718 },
    { lat: 49.0745931, lng: 18.9906649 },
    { lat: 49.0745115, lng: 18.9911947 },
    { lat: 49.0745036, lng: 18.9918458 },
    { lat: 49.0743028, lng: 18.9924959 },
    { lat: 49.0742855, lng: 18.9931628 },
    { lat: 49.0741292, lng: 18.9934384 },
    { lat: 49.074071, lng: 18.993694 },
    { lat: 49.0740829, lng: 18.994044 },
    { lat: 49.0741242, lng: 18.9945278 },
    { lat: 49.073907, lng: 18.9946009 },
    { lat: 49.0735625, lng: 18.9949718 },
    { lat: 49.0732078, lng: 18.9951396 },
    { lat: 49.0728996, lng: 18.995489 },
    { lat: 49.0724244, lng: 18.9961969 },
    { lat: 49.0723527, lng: 18.9963832 },
    { lat: 49.0720976, lng: 18.9973449 },
    { lat: 49.072108, lng: 18.9977539 },
    { lat: 49.0721899, lng: 18.9980828 },
    { lat: 49.072129, lng: 18.9983275 },
    { lat: 49.0720352, lng: 18.9985071 },
    { lat: 49.0719026, lng: 18.9986462 },
    { lat: 49.0716709, lng: 18.9987946 },
    { lat: 49.0711393, lng: 18.9990286 },
    { lat: 49.0710777, lng: 18.9991512 },
    { lat: 49.071067, lng: 18.9992736 },
    { lat: 49.0710804, lng: 18.9994267 },
    { lat: 49.0711972, lng: 18.9995983 },
    { lat: 49.0714728, lng: 18.9998798 },
    { lat: 49.0716676, lng: 19.0001646 },
    { lat: 49.0719493, lng: 19.0003882 },
    { lat: 49.0720958, lng: 19.0007473 },
    { lat: 49.0722534, lng: 19.0014636 },
    { lat: 49.0723835, lng: 19.0025973 },
    { lat: 49.0723502, lng: 19.0035264 },
    { lat: 49.0722444, lng: 19.0049927 },
    { lat: 49.0724023, lng: 19.0054889 },
    { lat: 49.0725669, lng: 19.0061857 },
    { lat: 49.0726662, lng: 19.0068427 },
    { lat: 49.0727038, lng: 19.0075923 },
    { lat: 49.0730551, lng: 19.0084138 },
    { lat: 49.0732315, lng: 19.0087845 },
    { lat: 49.073338, lng: 19.0093831 },
    { lat: 49.0737658, lng: 19.0111285 },
    { lat: 49.0739872, lng: 19.0117206 },
    { lat: 49.0737896, lng: 19.0121415 },
    { lat: 49.0728712, lng: 19.0129377 },
    { lat: 49.0716288, lng: 19.0136584 },
    { lat: 49.0706669, lng: 19.0139483 },
    { lat: 49.0700036, lng: 19.0140288 },
    { lat: 49.0689722, lng: 19.0133774 },
    { lat: 49.0676083, lng: 19.0126144 },
    { lat: 49.066237, lng: 19.0121543 },
    { lat: 49.0650911, lng: 19.0127552 },
    { lat: 49.0647312, lng: 19.0131299 },
    { lat: 49.0643586, lng: 19.013681 },
    { lat: 49.0637187, lng: 19.0139181 },
    { lat: 49.062984, lng: 19.0141028 },
    { lat: 49.0614769, lng: 19.0141743 },
    { lat: 49.0598686, lng: 19.0147212 },
    { lat: 49.059636, lng: 19.0150534 },
    { lat: 49.0586561, lng: 19.0155684 },
    { lat: 49.0582723, lng: 19.0161417 },
    { lat: 49.0578066, lng: 19.0162121 },
    { lat: 49.0576334, lng: 19.0163095 },
    { lat: 49.057527, lng: 19.0165242 },
    { lat: 49.0572103, lng: 19.0173398 },
    { lat: 49.0575425, lng: 19.0176052 },
    { lat: 49.0594147, lng: 19.0191015 },
    { lat: 49.0599459, lng: 19.0188369 },
    { lat: 49.0601252, lng: 19.0186371 },
    { lat: 49.0604001, lng: 19.0185661 },
    { lat: 49.0603906, lng: 19.0186452 },
    { lat: 49.0606018, lng: 19.018743 },
    { lat: 49.0606039, lng: 19.0188092 },
    { lat: 49.0606855, lng: 19.0192462 },
    { lat: 49.061258, lng: 19.0195315 },
    { lat: 49.0599863, lng: 19.0198934 },
    { lat: 49.0594642, lng: 19.0199369 },
    { lat: 49.0591868, lng: 19.0198637 },
    { lat: 49.059021, lng: 19.0197758 },
    { lat: 49.0590238, lng: 19.0198545 },
    { lat: 49.0591201, lng: 19.0207406 },
    { lat: 49.0600381, lng: 19.0229159 },
    { lat: 49.061086, lng: 19.0234921 },
    { lat: 49.0612975, lng: 19.0238192 },
    { lat: 49.0611802, lng: 19.0241804 },
    { lat: 49.0606931, lng: 19.0245635 },
    { lat: 49.0607022, lng: 19.0247458 },
    { lat: 49.0605611, lng: 19.0246507 },
    { lat: 49.0597006, lng: 19.0247329 },
    { lat: 49.0597101, lng: 19.0251025 },
    { lat: 49.0595117, lng: 19.0253296 },
    { lat: 49.0604821, lng: 19.0263411 },
    { lat: 49.0610588, lng: 19.0270084 },
    { lat: 49.0614019, lng: 19.0273326 },
    { lat: 49.0616092, lng: 19.0276893 },
    { lat: 49.0617527, lng: 19.0283075 },
    { lat: 49.0616883, lng: 19.0292744 },
    { lat: 49.0615635, lng: 19.0298939 },
    { lat: 49.0614471, lng: 19.0303157 },
    { lat: 49.0614832, lng: 19.0310388 },
    { lat: 49.061298, lng: 19.0317123 },
    { lat: 49.0608127, lng: 19.0326055 },
    { lat: 49.0607863, lng: 19.0327527 },
    { lat: 49.0605256, lng: 19.0332664 },
    { lat: 49.0601915, lng: 19.0336619 },
    { lat: 49.0599935, lng: 19.0338081 },
    { lat: 49.0599237, lng: 19.0341285 },
    { lat: 49.0593443, lng: 19.0347601 },
    { lat: 49.0590623, lng: 19.035278 },
    { lat: 49.0583226, lng: 19.03611 },
    { lat: 49.0581379, lng: 19.0366126 },
    { lat: 49.0577752, lng: 19.0381563 },
    { lat: 49.0575968, lng: 19.0395054 },
    { lat: 49.0573704, lng: 19.0425185 },
    { lat: 49.0575593, lng: 19.0429816 },
    { lat: 49.0583331, lng: 19.0436074 },
    { lat: 49.0589003, lng: 19.0442199 },
    { lat: 49.0591664, lng: 19.0452845 },
    { lat: 49.0594745, lng: 19.0459266 },
    { lat: 49.0599244, lng: 19.0464039 },
    { lat: 49.0604918, lng: 19.0468867 },
    { lat: 49.061397, lng: 19.0476548 },
    { lat: 49.0616604, lng: 19.0482352 },
    { lat: 49.0617619, lng: 19.0492114 },
    { lat: 49.0619548, lng: 19.0493746 },
    { lat: 49.0621864, lng: 19.0498577 },
    { lat: 49.062312, lng: 19.0506639 },
    { lat: 49.0621758, lng: 19.0519155 },
    { lat: 49.0617833, lng: 19.0529769 },
    { lat: 49.0612965, lng: 19.0539518 },
    { lat: 49.0610138, lng: 19.0551166 },
    { lat: 49.060698, lng: 19.0567447 },
    { lat: 49.060645, lng: 19.0570664 },
    { lat: 49.060478, lng: 19.0575364 },
    { lat: 49.0604019, lng: 19.0577986 },
    { lat: 49.059886, lng: 19.0584636 },
    { lat: 49.0594396, lng: 19.0591684 },
    { lat: 49.059636, lng: 19.0599456 },
    { lat: 49.0596559, lng: 19.0604369 },
    { lat: 49.0595806, lng: 19.0605764 },
    { lat: 49.0596741, lng: 19.0605987 },
    { lat: 49.0602794, lng: 19.06044 },
    { lat: 49.0606168, lng: 19.0603325 },
    { lat: 49.0607902, lng: 19.0603744 },
    { lat: 49.0614764, lng: 19.060659 },
    { lat: 49.0623992, lng: 19.059359 },
    { lat: 49.0631298, lng: 19.0588153 },
    { lat: 49.0636636, lng: 19.0585756 },
    { lat: 49.0640365, lng: 19.0582785 },
    { lat: 49.0643243, lng: 19.0580808 },
    { lat: 49.0644934, lng: 19.0576702 },
    { lat: 49.0649809, lng: 19.0562279 },
    { lat: 49.0656252, lng: 19.0555843 },
    { lat: 49.0661986, lng: 19.0548734 },
    { lat: 49.0667507, lng: 19.0543705 },
    { lat: 49.0673805, lng: 19.0539755 },
    { lat: 49.0682256, lng: 19.0530136 },
    { lat: 49.0686099, lng: 19.0526742 },
    { lat: 49.0689369, lng: 19.0521767 },
    { lat: 49.0692527, lng: 19.0513648 },
    { lat: 49.0698305, lng: 19.0505569 },
    { lat: 49.0710902, lng: 19.0496845 },
    { lat: 49.0712476, lng: 19.049131 },
    { lat: 49.0715842, lng: 19.048646 },
    { lat: 49.0724191, lng: 19.0476646 },
    { lat: 49.0725198, lng: 19.047603 },
    { lat: 49.0725604, lng: 19.0475793 },
    { lat: 49.0726993, lng: 19.047495 },
    { lat: 49.0727792, lng: 19.0474721 },
    { lat: 49.0729193, lng: 19.0474764 },
    { lat: 49.0732145, lng: 19.0475179 },
    { lat: 49.0736701, lng: 19.0475204 },
    { lat: 49.0738523, lng: 19.0473757 },
    { lat: 49.0739912, lng: 19.0472706 },
    { lat: 49.0742684, lng: 19.0471289 },
    { lat: 49.074443, lng: 19.0470125 },
    { lat: 49.0746285, lng: 19.0468472 },
    { lat: 49.0748372, lng: 19.0465967 },
    { lat: 49.0750171, lng: 19.0464109 },
    { lat: 49.0755959, lng: 19.0457127 },
    { lat: 49.0756562, lng: 19.0457471 },
    { lat: 49.0758161, lng: 19.0457905 },
    { lat: 49.0759368, lng: 19.0457698 },
    { lat: 49.0760696, lng: 19.04572 },
    { lat: 49.0762215, lng: 19.0456913 },
    { lat: 49.0763358, lng: 19.0457238 },
    { lat: 49.0764645, lng: 19.0457705 },
    { lat: 49.0767163, lng: 19.0459406 },
    { lat: 49.0772441, lng: 19.0461268 },
    { lat: 49.0774081, lng: 19.0460669 },
    { lat: 49.0774801, lng: 19.0460655 },
    { lat: 49.0776698, lng: 19.0461601 },
    { lat: 49.0778, lng: 19.0461519 },
    { lat: 49.0779538, lng: 19.0462511 },
    { lat: 49.0783921, lng: 19.0462692 },
    { lat: 49.0792192, lng: 19.0465788 },
    { lat: 49.081077, lng: 19.0465332 },
    { lat: 49.0813545, lng: 19.0466512 },
    { lat: 49.0813936, lng: 19.0466676 },
    { lat: 49.0814339, lng: 19.0466845 },
    { lat: 49.0814503, lng: 19.0466959 },
    { lat: 49.0821894, lng: 19.0467758 },
    { lat: 49.0822069, lng: 19.0467896 },
    { lat: 49.082953, lng: 19.0466065 },
    { lat: 49.0829711, lng: 19.0466058 },
    { lat: 49.0833909, lng: 19.0465017 },
    { lat: 49.0834088, lng: 19.0465 },
    { lat: 49.0842278, lng: 19.046466 },
    { lat: 49.0846043, lng: 19.0466887 },
    { lat: 49.084622, lng: 19.0466845 },
    { lat: 49.0854495, lng: 19.0469358 },
    { lat: 49.0854753, lng: 19.0468886 },
    { lat: 49.0855281, lng: 19.0469296 },
    { lat: 49.0858912, lng: 19.0469291 },
    { lat: 49.0859036, lng: 19.0469521 },
    { lat: 49.0859698, lng: 19.0470373 },
    { lat: 49.0870058, lng: 19.0475152 },
    { lat: 49.0876575, lng: 19.0474962 },
    { lat: 49.0877157, lng: 19.0474399 },
    { lat: 49.0877409, lng: 19.0468831 },
    { lat: 49.0877725, lng: 19.0468348 },
    { lat: 49.087805, lng: 19.0467858 },
    { lat: 49.0878263, lng: 19.0468099 },
    { lat: 49.0881377, lng: 19.0467909 },
    { lat: 49.0900057, lng: 19.0466763 },
    { lat: 49.0900994, lng: 19.046727 },
    { lat: 49.0901187, lng: 19.0467488 },
    { lat: 49.0901676, lng: 19.0468072 },
    { lat: 49.0902695, lng: 19.046926 },
    { lat: 49.0907637, lng: 19.0469129 },
    { lat: 49.0925761, lng: 19.0468615 },
    { lat: 49.0932128, lng: 19.0471298 },
    { lat: 49.0955254, lng: 19.0448717 },
    { lat: 49.0963324, lng: 19.0447553 },
    { lat: 49.0963615, lng: 19.0447512 },
    { lat: 49.0963669, lng: 19.0447506 },
    { lat: 49.0967679, lng: 19.0442885 },
    { lat: 49.0986035, lng: 19.0431845 },
    { lat: 49.0985149, lng: 19.0427663 },
    { lat: 49.1020115, lng: 19.0401538 },
    { lat: 49.1025992, lng: 19.0395459 },
    { lat: 49.1026576, lng: 19.0396568 },
    { lat: 49.1036381, lng: 19.0386121 },
    { lat: 49.1037456, lng: 19.0384831 },
    { lat: 49.1039807, lng: 19.0382531 },
    { lat: 49.103275, lng: 19.0370462 },
    { lat: 49.1029461, lng: 19.0363765 },
    { lat: 49.1027156, lng: 19.0359549 },
    { lat: 49.1024265, lng: 19.0355148 },
    { lat: 49.1022444, lng: 19.0354578 },
    { lat: 49.1021429, lng: 19.035322 },
    { lat: 49.1017732, lng: 19.034375 },
    { lat: 49.1017787, lng: 19.0330505 },
    { lat: 49.1014089, lng: 19.032469 },
    { lat: 49.1014607, lng: 19.0310905 },
    { lat: 49.1015385, lng: 19.0303195 },
    { lat: 49.1017923, lng: 19.0288309 },
    { lat: 49.1020504, lng: 19.0276336 },
    { lat: 49.1025137, lng: 19.0262772 },
    { lat: 49.1030373, lng: 19.0248405 },
    { lat: 49.1033696, lng: 19.0246416 },
    { lat: 49.1035167, lng: 19.0246782 },
    { lat: 49.1037747, lng: 19.024828 },
    { lat: 49.104313, lng: 19.0252723 },
    { lat: 49.1052478, lng: 19.0260336 },
    { lat: 49.1062296, lng: 19.024695 },
    { lat: 49.107055, lng: 19.0236707 },
    { lat: 49.1067416, lng: 19.0231339 },
    { lat: 49.1066268, lng: 19.0226907 },
    { lat: 49.1065763, lng: 19.0215438 },
    { lat: 49.1064779, lng: 19.0207928 },
    { lat: 49.1062384, lng: 19.0205422 },
    { lat: 49.105963, lng: 19.0199715 },
    { lat: 49.1057618, lng: 19.0195529 },
    { lat: 49.1054577, lng: 19.0187239 },
    { lat: 49.1050843, lng: 19.017704 },
    { lat: 49.104826, lng: 19.0173191 },
    { lat: 49.1045343, lng: 19.0181801 },
    { lat: 49.1042222, lng: 19.0182504 },
    { lat: 49.1034573, lng: 19.0186233 },
    { lat: 49.1032698, lng: 19.0188195 },
    { lat: 49.1026867, lng: 19.0191537 },
    { lat: 49.1024272, lng: 19.0193072 },
    { lat: 49.1022992, lng: 19.0193418 },
    { lat: 49.1019942, lng: 19.0193495 },
    { lat: 49.1017723, lng: 19.019491 },
    { lat: 49.1016946, lng: 19.0195328 },
    { lat: 49.1015592, lng: 19.0195567 },
    { lat: 49.1015642, lng: 19.0194957 },
    { lat: 49.1012733, lng: 19.018458 },
    { lat: 49.1012581, lng: 19.017909 },
    { lat: 49.1011566, lng: 19.0176124 },
    { lat: 49.1010987, lng: 19.0171539 },
    { lat: 49.1013364, lng: 19.0166288 },
    { lat: 49.101178, lng: 19.0160787 },
    { lat: 49.101157, lng: 19.015748 },
    { lat: 49.1011793, lng: 19.015359 },
    { lat: 49.1013369, lng: 19.0148159 },
    { lat: 49.1011232, lng: 19.0144212 },
    { lat: 49.101003, lng: 19.0142459 },
    { lat: 49.1008505, lng: 19.0140603 },
    { lat: 49.1007422, lng: 19.0139922 },
    { lat: 49.1005229, lng: 19.0139094 },
    { lat: 49.1003016, lng: 19.0138588 },
    { lat: 49.1001713, lng: 19.013893 },
    { lat: 49.1000034, lng: 19.0140502 },
    { lat: 49.0998939, lng: 19.0132826 },
    { lat: 49.0996426, lng: 19.0131715 },
    { lat: 49.0991064, lng: 19.0128653 },
    { lat: 49.098783, lng: 19.012733 },
    { lat: 49.0985758, lng: 19.0127365 },
    { lat: 49.0983873, lng: 19.0127983 },
    { lat: 49.0981549, lng: 19.0129084 },
    { lat: 49.097911, lng: 19.0127402 },
    { lat: 49.0979059, lng: 19.012752 },
    { lat: 49.0972212, lng: 19.0123155 },
    { lat: 49.0963115, lng: 19.0115991 },
    { lat: 49.0946847, lng: 19.0118425 },
    { lat: 49.0942205, lng: 19.0120082 },
    { lat: 49.0931628, lng: 19.0117748 },
    { lat: 49.0928727, lng: 19.0115207 },
    { lat: 49.0925923, lng: 19.0111661 },
    { lat: 49.0918006, lng: 19.0098577 },
    { lat: 49.0913969, lng: 19.0105034 },
    { lat: 49.0913486, lng: 19.0104177 },
    { lat: 49.0911264, lng: 19.0099902 },
    { lat: 49.0907329, lng: 19.0096744 },
    { lat: 49.0900009, lng: 19.0089209 },
    { lat: 49.089046, lng: 19.0079059 },
    { lat: 49.0888114, lng: 19.0076608 },
    { lat: 49.0885519, lng: 19.0073842 },
    { lat: 49.0873534, lng: 19.0077661 },
    { lat: 49.0851065, lng: 19.008439 },
    { lat: 49.0846554, lng: 19.0085921 },
    { lat: 49.0843352, lng: 19.0078281 },
    { lat: 49.0843238, lng: 19.0078008 },
    { lat: 49.0836525, lng: 19.0063743 },
    { lat: 49.0827132, lng: 19.0043871 },
    { lat: 49.081761, lng: 19.0024373 },
    { lat: 49.0810906, lng: 19.0010766 },
    { lat: 49.0802825, lng: 18.9993738 },
    { lat: 49.079835, lng: 18.9984527 },
    { lat: 49.0792796, lng: 18.9973133 },
    { lat: 49.0786219, lng: 18.9959775 },
    { lat: 49.0790086, lng: 18.995798 },
    { lat: 49.0780301, lng: 18.9939804 },
    { lat: 49.0779191, lng: 18.9937937 },
    { lat: 49.0777782, lng: 18.9934753 },
    { lat: 49.0774584, lng: 18.9928332 },
    { lat: 49.0773912, lng: 18.9927328 },
    { lat: 49.077301, lng: 18.9924174 },
    { lat: 49.0772131, lng: 18.9922802 },
    { lat: 49.0771272, lng: 18.9922118 },
    { lat: 49.0770837, lng: 18.9920421 },
    { lat: 49.0770623, lng: 18.9918605 },
    { lat: 49.0770341, lng: 18.9917268 },
    { lat: 49.0769631, lng: 18.9915218 },
    { lat: 49.0769967, lng: 18.9914762 },
    { lat: 49.0769502, lng: 18.9913791 },
    { lat: 49.0769585, lng: 18.9912278 },
    { lat: 49.0768864, lng: 18.9911671 },
    { lat: 49.0768189, lng: 18.9908521 },
    { lat: 49.0768097, lng: 18.9906921 },
    { lat: 49.076744, lng: 18.9905515 },
    { lat: 49.076779, lng: 18.990509 },
    { lat: 49.0767602, lng: 18.9904267 },
    { lat: 49.0766742, lng: 18.9904176 },
    { lat: 49.0766779, lng: 18.9903352 },
    { lat: 49.0765935, lng: 18.9902271 },
    { lat: 49.0764598, lng: 18.9900772 },
    { lat: 49.0764053, lng: 18.9898664 },
    { lat: 49.0763363, lng: 18.9897946 },
    { lat: 49.0761441, lng: 18.9896749 },
    { lat: 49.0761056, lng: 18.9895816 },
  ],
  TurčianskeJaseno: [
    { lat: 49.0261414, lng: 18.9732451 },
    { lat: 49.0262797, lng: 18.9733041 },
    { lat: 49.0262724, lng: 18.9733843 },
    { lat: 49.0262333, lng: 18.9735261 },
    { lat: 49.0263244, lng: 18.9736977 },
    { lat: 49.0262554, lng: 18.9738059 },
    { lat: 49.0263418, lng: 18.9739532 },
    { lat: 49.0263119, lng: 18.9741018 },
    { lat: 49.0263645, lng: 18.9742027 },
    { lat: 49.0263227, lng: 18.9743147 },
    { lat: 49.0263786, lng: 18.9744396 },
    { lat: 49.0262823, lng: 18.9750084 },
    { lat: 49.0261891, lng: 18.975399 },
    { lat: 49.0262284, lng: 18.9755992 },
    { lat: 49.026217, lng: 18.9758172 },
    { lat: 49.0261303, lng: 18.9759121 },
    { lat: 49.0262931, lng: 18.9770606 },
    { lat: 49.0263741, lng: 18.9774805 },
    { lat: 49.0262147, lng: 18.9780315 },
    { lat: 49.0260022, lng: 18.9786242 },
    { lat: 49.0257511, lng: 18.9790975 },
    { lat: 49.025545, lng: 18.979396 },
    { lat: 49.0252878, lng: 18.9796379 },
    { lat: 49.0250637, lng: 18.979798 },
    { lat: 49.0241006, lng: 18.9800866 },
    { lat: 49.0239502, lng: 18.9801447 },
    { lat: 49.0238439, lng: 18.9802472 },
    { lat: 49.023687, lng: 18.9802238 },
    { lat: 49.0231556, lng: 18.980396 },
    { lat: 49.0230314, lng: 18.9803681 },
    { lat: 49.0227787, lng: 18.9804974 },
    { lat: 49.0225441, lng: 18.9804726 },
    { lat: 49.0223507, lng: 18.9807099 },
    { lat: 49.0221099, lng: 18.980784 },
    { lat: 49.0217469, lng: 18.980874 },
    { lat: 49.0216049, lng: 18.9809635 },
    { lat: 49.0215077, lng: 18.981163 },
    { lat: 49.0213613, lng: 18.9812997 },
    { lat: 49.0211509, lng: 18.981372 },
    { lat: 49.020946, lng: 18.9814264 },
    { lat: 49.0208036, lng: 18.9814415 },
    { lat: 49.0206033, lng: 18.9813578 },
    { lat: 49.02014, lng: 18.9809785 },
    { lat: 49.0197986, lng: 18.9806921 },
    { lat: 49.0193564, lng: 18.9807304 },
    { lat: 49.0190914, lng: 18.9807745 },
    { lat: 49.0182478, lng: 18.981127 },
    { lat: 49.0177828, lng: 18.9814528 },
    { lat: 49.0175945, lng: 18.981679 },
    { lat: 49.0171382, lng: 18.9824448 },
    { lat: 49.0167891, lng: 18.982743 },
    { lat: 49.0164356, lng: 18.983023 },
    { lat: 49.0161725, lng: 18.9830565 },
    { lat: 49.0160387, lng: 18.9831119 },
    { lat: 49.0159485, lng: 18.9832427 },
    { lat: 49.0158478, lng: 18.9834581 },
    { lat: 49.0151571, lng: 18.9840263 },
    { lat: 49.0150064, lng: 18.9841253 },
    { lat: 49.014853, lng: 18.9841425 },
    { lat: 49.0142252, lng: 18.984194 },
    { lat: 49.0139573, lng: 18.9843333 },
    { lat: 49.0138463, lng: 18.9844141 },
    { lat: 49.0136643, lng: 18.9848763 },
    { lat: 49.013251, lng: 18.986227 },
    { lat: 49.0132911, lng: 18.9866659 },
    { lat: 49.0128528, lng: 18.986895 },
    { lat: 49.0129369, lng: 18.9876036 },
    { lat: 49.0128115, lng: 18.9883849 },
    { lat: 49.0127333, lng: 18.9890046 },
    { lat: 49.0125929, lng: 18.9896174 },
    { lat: 49.0125803, lng: 18.9899964 },
    { lat: 49.012525, lng: 18.9905078 },
    { lat: 49.0120362, lng: 18.9906763 },
    { lat: 49.012, lng: 18.990943 },
    { lat: 49.0119881, lng: 18.9911304 },
    { lat: 49.0120849, lng: 18.9912646 },
    { lat: 49.0121467, lng: 18.991418 },
    { lat: 49.0121835, lng: 18.9916084 },
    { lat: 49.0122298, lng: 18.9925777 },
    { lat: 49.0122785, lng: 18.9930665 },
    { lat: 49.01228, lng: 18.9930811 },
    { lat: 49.012304, lng: 18.9932951 },
    { lat: 49.0123867, lng: 18.9940341 },
    { lat: 49.0123777, lng: 18.995455 },
    { lat: 49.0123761, lng: 18.9957079 },
    { lat: 49.0126042, lng: 18.9960771 },
    { lat: 49.0130639, lng: 18.99678 },
    { lat: 49.0131638, lng: 18.9969829 },
    { lat: 49.0132047, lng: 18.9971017 },
    { lat: 49.0132582, lng: 18.9971559 },
    { lat: 49.0141503, lng: 18.9982612 },
    { lat: 49.0143713, lng: 18.9985739 },
    { lat: 49.0152135, lng: 18.9994307 },
    { lat: 49.0155189, lng: 18.9997437 },
    { lat: 49.0155002, lng: 18.9997961 },
    { lat: 49.0159685, lng: 19.0004547 },
    { lat: 49.0164681, lng: 19.0021897 },
    { lat: 49.0167411, lng: 19.0034507 },
    { lat: 49.0169637, lng: 19.0044729 },
    { lat: 49.0170206, lng: 19.0060772 },
    { lat: 49.0163837, lng: 19.006233 },
    { lat: 49.0165536, lng: 19.0064191 },
    { lat: 49.0169261, lng: 19.0071801 },
    { lat: 49.0170484, lng: 19.0074601 },
    { lat: 49.01669, lng: 19.0087702 },
    { lat: 49.016352, lng: 19.0100535 },
    { lat: 49.0161499, lng: 19.0111954 },
    { lat: 49.0159675, lng: 19.0116712 },
    { lat: 49.0159596, lng: 19.0117174 },
    { lat: 49.0158472, lng: 19.0120612 },
    { lat: 49.015806, lng: 19.0128802 },
    { lat: 49.0157624, lng: 19.0143054 },
    { lat: 49.0156668, lng: 19.0170098 },
    { lat: 49.0155639, lng: 19.0176358 },
    { lat: 49.0155232, lng: 19.0178604 },
    { lat: 49.0153342, lng: 19.0194894 },
    { lat: 49.0154447, lng: 19.0201853 },
    { lat: 49.0153592, lng: 19.0209135 },
    { lat: 49.0152514, lng: 19.0213034 },
    { lat: 49.0148677, lng: 19.0240465 },
    { lat: 49.0148509, lng: 19.0240452 },
    { lat: 49.0141027, lng: 19.0240774 },
    { lat: 49.0139204, lng: 19.0251673 },
    { lat: 49.0135727, lng: 19.0252908 },
    { lat: 49.0133909, lng: 19.0251969 },
    { lat: 49.0131839, lng: 19.0250025 },
    { lat: 49.0130635, lng: 19.0248486 },
    { lat: 49.0127847, lng: 19.0245921 },
    { lat: 49.0126661, lng: 19.0245218 },
    { lat: 49.0123022, lng: 19.0243915 },
    { lat: 49.0122047, lng: 19.0243797 },
    { lat: 49.012152, lng: 19.0246092 },
    { lat: 49.0121301, lng: 19.0248473 },
    { lat: 49.0120536, lng: 19.0249855 },
    { lat: 49.0120244, lng: 19.0250815 },
    { lat: 49.0119893, lng: 19.0252587 },
    { lat: 49.0118823, lng: 19.025712 },
    { lat: 49.0118363, lng: 19.0259407 },
    { lat: 49.0118518, lng: 19.0262751 },
    { lat: 49.011972, lng: 19.0266221 },
    { lat: 49.0120204, lng: 19.0268916 },
    { lat: 49.0120461, lng: 19.0271357 },
    { lat: 49.0119581, lng: 19.0276548 },
    { lat: 49.0119247, lng: 19.0279237 },
    { lat: 49.0119059, lng: 19.0280756 },
    { lat: 49.011825, lng: 19.0284225 },
    { lat: 49.0117987, lng: 19.0285908 },
    { lat: 49.0117822, lng: 19.0288717 },
    { lat: 49.0117908, lng: 19.0291555 },
    { lat: 49.0117536, lng: 19.0293803 },
    { lat: 49.0117612, lng: 19.0294426 },
    { lat: 49.0117684, lng: 19.0296134 },
    { lat: 49.0117819, lng: 19.0300608 },
    { lat: 49.0117999, lng: 19.0302182 },
    { lat: 49.0118294, lng: 19.0303595 },
    { lat: 49.0118546, lng: 19.0305947 },
    { lat: 49.0116954, lng: 19.0306989 },
    { lat: 49.0116727, lng: 19.0307218 },
    { lat: 49.0115762, lng: 19.030819 },
    { lat: 49.0114613, lng: 19.030978 },
    { lat: 49.0114132, lng: 19.0310321 },
    { lat: 49.0113408, lng: 19.031048 },
    { lat: 49.0112011, lng: 19.0311891 },
    { lat: 49.0110104, lng: 19.0313206 },
    { lat: 49.0109107, lng: 19.0313533 },
    { lat: 49.0107511, lng: 19.0313372 },
    { lat: 49.0106296, lng: 19.0313441 },
    { lat: 49.0105562, lng: 19.0313418 },
    { lat: 49.0104849, lng: 19.0313395 },
    { lat: 49.0103935, lng: 19.0313267 },
    { lat: 49.0100482, lng: 19.0312396 },
    { lat: 49.009893, lng: 19.0313153 },
    { lat: 49.0098165, lng: 19.0312487 },
    { lat: 49.0095634, lng: 19.0313745 },
    { lat: 49.0095479, lng: 19.0314711 },
    { lat: 49.0093023, lng: 19.0315153 },
    { lat: 49.0090313, lng: 19.0316654 },
    { lat: 49.0088662, lng: 19.0317466 },
    { lat: 49.0085294, lng: 19.0318436 },
    { lat: 49.0082556, lng: 19.0319133 },
    { lat: 49.0080318, lng: 19.031993 },
    { lat: 49.0075031, lng: 19.0320835 },
    { lat: 49.0073244, lng: 19.0321842 },
    { lat: 49.0071898, lng: 19.0321297 },
    { lat: 49.0070676, lng: 19.0322116 },
    { lat: 49.0068903, lng: 19.0322949 },
    { lat: 49.0066412, lng: 19.0324307 },
    { lat: 49.0063868, lng: 19.0326672 },
    { lat: 49.0059369, lng: 19.0334973 },
    { lat: 49.0057063, lng: 19.0335307 },
    { lat: 49.0054974, lng: 19.0338417 },
    { lat: 49.0053168, lng: 19.0340852 },
    { lat: 49.0051025, lng: 19.034288 },
    { lat: 49.0049676, lng: 19.034472 },
    { lat: 49.0048425, lng: 19.0345636 },
    { lat: 49.0047671, lng: 19.0346818 },
    { lat: 49.0046149, lng: 19.0348351 },
    { lat: 49.0043642, lng: 19.0352134 },
    { lat: 49.0040876, lng: 19.0359279 },
    { lat: 49.0035484, lng: 19.0369154 },
    { lat: 49.0034222, lng: 19.0371659 },
    { lat: 49.0034857, lng: 19.0379176 },
    { lat: 49.0031137, lng: 19.0390678 },
    { lat: 49.0025274, lng: 19.0403371 },
    { lat: 49.0016698, lng: 19.0421935 },
    { lat: 49.0012692, lng: 19.043612 },
    { lat: 49.0002483, lng: 19.0449783 },
    { lat: 48.9997283, lng: 19.046237 },
    { lat: 48.9990353, lng: 19.0472818 },
    { lat: 48.9971578, lng: 19.0527287 },
    { lat: 48.9967145, lng: 19.0544451 },
    { lat: 48.9962458, lng: 19.0560147 },
    { lat: 48.9958353, lng: 19.0582536 },
    { lat: 48.9957295, lng: 19.0595933 },
    { lat: 48.9959332, lng: 19.0618331 },
    { lat: 48.9958735, lng: 19.0627425 },
    { lat: 48.9959189, lng: 19.0638599 },
    { lat: 48.9961565, lng: 19.0651717 },
    { lat: 48.9961432, lng: 19.0660833 },
    { lat: 48.9961721, lng: 19.0667796 },
    { lat: 48.9961297, lng: 19.0676045 },
    { lat: 48.9963461, lng: 19.0691941 },
    { lat: 48.996277, lng: 19.0695477 },
    { lat: 48.9958272, lng: 19.0702993 },
    { lat: 48.9953773, lng: 19.0709073 },
    { lat: 48.9950651, lng: 19.0714795 },
    { lat: 48.9946609, lng: 19.0723267 },
    { lat: 48.9942574, lng: 19.0731253 },
    { lat: 48.9938307, lng: 19.0740013 },
    { lat: 48.9936369, lng: 19.0742518 },
    { lat: 48.9933584, lng: 19.0746854 },
    { lat: 48.9931838, lng: 19.0751839 },
    { lat: 48.9930887, lng: 19.0755631 },
    { lat: 48.9929822, lng: 19.0763733 },
    { lat: 48.9928627, lng: 19.0767133 },
    { lat: 48.9927728, lng: 19.0773529 },
    { lat: 48.9927913, lng: 19.0775687 },
    { lat: 48.9926421, lng: 19.0783328 },
    { lat: 48.9924724, lng: 19.0797321 },
    { lat: 48.9924451, lng: 19.0800446 },
    { lat: 48.9924661, lng: 19.0804931 },
    { lat: 48.9925676, lng: 19.081334 },
    { lat: 48.9925926, lng: 19.0819059 },
    { lat: 48.9926458, lng: 19.0822738 },
    { lat: 48.9926829, lng: 19.0831387 },
    { lat: 48.9926961, lng: 19.0837244 },
    { lat: 48.992091, lng: 19.0861876 },
    { lat: 48.9921787, lng: 19.0876651 },
    { lat: 48.9920534, lng: 19.0884835 },
    { lat: 48.9920438, lng: 19.089928 },
    { lat: 48.9922818, lng: 19.091822 },
    { lat: 48.9922699, lng: 19.0927706 },
    { lat: 48.9920015, lng: 19.0938828 },
    { lat: 48.9915834, lng: 19.0952067 },
    { lat: 48.9912773, lng: 19.0967237 },
    { lat: 48.9910496, lng: 19.0972052 },
    { lat: 48.9902594, lng: 19.097206 },
    { lat: 48.9897018, lng: 19.0973277 },
    { lat: 48.9868875, lng: 19.0978633 },
    { lat: 48.9856448, lng: 19.0990165 },
    { lat: 48.9851055, lng: 19.0996228 },
    { lat: 48.984928, lng: 19.1004351 },
    { lat: 48.9846976, lng: 19.1013378 },
    { lat: 48.984575, lng: 19.10212 },
    { lat: 48.984769, lng: 19.102886 },
    { lat: 48.985013, lng: 19.103623 },
    { lat: 48.98533, lng: 19.104287 },
    { lat: 48.985595, lng: 19.10447 },
    { lat: 48.985757, lng: 19.104824 },
    { lat: 48.9859133, lng: 19.1053587 },
    { lat: 48.985995, lng: 19.105367 },
    { lat: 48.9861263, lng: 19.1057906 },
    { lat: 48.986429, lng: 19.106627 },
    { lat: 48.986501, lng: 19.106702 },
    { lat: 48.986673, lng: 19.106924 },
    { lat: 48.986799, lng: 19.107051 },
    { lat: 48.986949, lng: 19.107197 },
    { lat: 48.98737, lng: 19.106995 },
    { lat: 48.987748, lng: 19.106716 },
    { lat: 48.988027, lng: 19.106418 },
    { lat: 48.988308, lng: 19.106251 },
    { lat: 48.988639, lng: 19.105775 },
    { lat: 48.989231, lng: 19.105013 },
    { lat: 48.989749, lng: 19.104351 },
    { lat: 48.990146, lng: 19.103994 },
    { lat: 48.990351, lng: 19.103669 },
    { lat: 48.990735, lng: 19.103342 },
    { lat: 48.991067, lng: 19.103041 },
    { lat: 48.99147, lng: 19.102675 },
    { lat: 48.9916687, lng: 19.1026175 },
    { lat: 48.991833, lng: 19.102373 },
    { lat: 48.9921756, lng: 19.1022071 },
    { lat: 48.992319, lng: 19.101983 },
    { lat: 48.992724, lng: 19.101769 },
    { lat: 48.993082, lng: 19.101834 },
    { lat: 48.993408, lng: 19.101418 },
    { lat: 48.9935853, lng: 19.1014132 },
    { lat: 48.993728, lng: 19.101312 },
    { lat: 48.9942453, lng: 19.1010216 },
    { lat: 48.9944882, lng: 19.1009787 },
    { lat: 48.994928, lng: 19.100898 },
    { lat: 48.995631, lng: 19.100923 },
    { lat: 48.995669, lng: 19.1009626 },
    { lat: 48.996198, lng: 19.101048 },
    { lat: 48.99673, lng: 19.101314 },
    { lat: 48.997259, lng: 19.1015 },
    { lat: 48.9974658, lng: 19.1016895 },
    { lat: 48.997562, lng: 19.101731 },
    { lat: 48.997768, lng: 19.101829 },
    { lat: 48.998373, lng: 19.10218 },
    { lat: 48.99887, lng: 19.102537 },
    { lat: 48.999309, lng: 19.102826 },
    { lat: 48.999449, lng: 19.102896 },
    { lat: 48.99955, lng: 19.103069 },
    { lat: 48.999708, lng: 19.103199 },
    { lat: 48.999916, lng: 19.103473 },
    { lat: 49.00011, lng: 19.103726 },
    { lat: 49.00046, lng: 19.104344 },
    { lat: 49.000817, lng: 19.105186 },
    { lat: 49.000863, lng: 19.105433 },
    { lat: 49.001026, lng: 19.105576 },
    { lat: 49.001092, lng: 19.105715 },
    { lat: 49.001391, lng: 19.106403 },
    { lat: 49.001813, lng: 19.106922 },
    { lat: 49.002182, lng: 19.10764 },
    { lat: 49.002497, lng: 19.108193 },
    { lat: 49.002656, lng: 19.108879 },
    { lat: 49.002825, lng: 19.109676 },
    { lat: 49.003267, lng: 19.110369 },
    { lat: 49.003752, lng: 19.110785 },
    { lat: 49.004273, lng: 19.111158 },
    { lat: 49.004845, lng: 19.111381 },
    { lat: 49.0061495, lng: 19.1114688 },
    { lat: 49.006794, lng: 19.111442 },
    { lat: 49.007447, lng: 19.111169 },
    { lat: 49.008036, lng: 19.110727 },
    { lat: 49.008608, lng: 19.110827 },
    { lat: 49.0091126, lng: 19.1112269 },
    { lat: 49.00922, lng: 19.111312 },
    { lat: 49.0085755, lng: 19.1090195 },
    { lat: 49.0082889, lng: 19.1075534 },
    { lat: 49.0081351, lng: 19.1057608 },
    { lat: 49.0079641, lng: 19.1041903 },
    { lat: 49.0081772, lng: 19.1034813 },
    { lat: 49.0082411, lng: 19.102033 },
    { lat: 49.0084488, lng: 19.1010337 },
    { lat: 49.0085753, lng: 19.1006976 },
    { lat: 49.0092028, lng: 19.0997319 },
    { lat: 49.0093989, lng: 19.0988617 },
    { lat: 49.0095948, lng: 19.0977632 },
    { lat: 49.0096834, lng: 19.0968166 },
    { lat: 49.0096028, lng: 19.0959841 },
    { lat: 49.0098385, lng: 19.095527 },
    { lat: 49.0102101, lng: 19.094334 },
    { lat: 49.0107683, lng: 19.0926497 },
    { lat: 49.0115603, lng: 19.0907243 },
    { lat: 49.0117734, lng: 19.0907714 },
    { lat: 49.0124691, lng: 19.0905913 },
    { lat: 49.0125878, lng: 19.0904169 },
    { lat: 49.0140291, lng: 19.0889238 },
    { lat: 49.0149132, lng: 19.0879042 },
    { lat: 49.015402, lng: 19.0865985 },
    { lat: 49.0154802, lng: 19.0860821 },
    { lat: 49.0154474, lng: 19.0847556 },
    { lat: 49.0154924, lng: 19.0825921 },
    { lat: 49.0156419, lng: 19.0820374 },
    { lat: 49.0160041, lng: 19.0812478 },
    { lat: 49.0160435, lng: 19.0812034 },
    { lat: 49.0162297, lng: 19.0808183 },
    { lat: 49.0165078, lng: 19.080242 },
    { lat: 49.0167158, lng: 19.0791386 },
    { lat: 49.0170551, lng: 19.0780665 },
    { lat: 49.0174476, lng: 19.0772311 },
    { lat: 49.0177347, lng: 19.0764749 },
    { lat: 49.0181887, lng: 19.0750761 },
    { lat: 49.0187726, lng: 19.0735086 },
    { lat: 49.0189677, lng: 19.0724651 },
    { lat: 49.0192148, lng: 19.0716495 },
    { lat: 49.0194906, lng: 19.070076 },
    { lat: 49.0199511, lng: 19.066541 },
    { lat: 49.0200954, lng: 19.0662928 },
    { lat: 49.0203865, lng: 19.0658905 },
    { lat: 49.0204441, lng: 19.065371 },
    { lat: 49.0205544, lng: 19.0650042 },
    { lat: 49.0205588, lng: 19.0645457 },
    { lat: 49.0206177, lng: 19.063733 },
    { lat: 49.020596, lng: 19.0630918 },
    { lat: 49.0209569, lng: 19.0614833 },
    { lat: 49.0210281, lng: 19.0610822 },
    { lat: 49.021241, lng: 19.0611216 },
    { lat: 49.0213573, lng: 19.0609898 },
    { lat: 49.0214114, lng: 19.0605385 },
    { lat: 49.0214822, lng: 19.0594675 },
    { lat: 49.0216811, lng: 19.0590018 },
    { lat: 49.0222852, lng: 19.0570926 },
    { lat: 49.0224301, lng: 19.0568328 },
    { lat: 49.022597, lng: 19.0563115 },
    { lat: 49.022365, lng: 19.0554241 },
    { lat: 49.0224061, lng: 19.0552512 },
    { lat: 49.0224928, lng: 19.0551105 },
    { lat: 49.0226065, lng: 19.054872 },
    { lat: 49.0228366, lng: 19.0543514 },
    { lat: 49.023039, lng: 19.0540089 },
    { lat: 49.0232193, lng: 19.05367 },
    { lat: 49.0233624, lng: 19.0533337 },
    { lat: 49.0234848, lng: 19.0530852 },
    { lat: 49.0235924, lng: 19.0528649 },
    { lat: 49.023642, lng: 19.0527304 },
    { lat: 49.0237234, lng: 19.0525676 },
    { lat: 49.0237404, lng: 19.052392 },
    { lat: 49.0237777, lng: 19.0522287 },
    { lat: 49.0237847, lng: 19.05205 },
    { lat: 49.0237674, lng: 19.0518713 },
    { lat: 49.0237359, lng: 19.0517298 },
    { lat: 49.0235534, lng: 19.0512803 },
    { lat: 49.0234437, lng: 19.05101 },
    { lat: 49.0231993, lng: 19.0505532 },
    { lat: 49.023113, lng: 19.0503918 },
    { lat: 49.0229928, lng: 19.0501863 },
    { lat: 49.022587, lng: 19.0494929 },
    { lat: 49.0222567, lng: 19.0490392 },
    { lat: 49.022213, lng: 19.0489792 },
    { lat: 49.0221718, lng: 19.0489439 },
    { lat: 49.0220329, lng: 19.0488247 },
    { lat: 49.021953, lng: 19.0487969 },
    { lat: 49.0218053, lng: 19.0487455 },
    { lat: 49.02157, lng: 19.0486636 },
    { lat: 49.0214465, lng: 19.0486206 },
    { lat: 49.0213437, lng: 19.048616 },
    { lat: 49.0210748, lng: 19.0486041 },
    { lat: 49.0211324, lng: 19.0485333 },
    { lat: 49.0211649, lng: 19.0484871 },
    { lat: 49.0211714, lng: 19.0484788 },
    { lat: 49.0227288, lng: 19.0464572 },
    { lat: 49.0236153, lng: 19.0454689 },
    { lat: 49.0236598, lng: 19.0454577 },
    { lat: 49.0239883, lng: 19.0453753 },
    { lat: 49.0239163, lng: 19.0431616 },
    { lat: 49.0240185, lng: 19.0422507 },
    { lat: 49.0242937, lng: 19.0415239 },
    { lat: 49.0244598, lng: 19.0411746 },
    { lat: 49.0246296, lng: 19.0408221 },
    { lat: 49.0250342, lng: 19.0404151 },
    { lat: 49.0250869, lng: 19.0403624 },
    { lat: 49.0253467, lng: 19.040262 },
    { lat: 49.0253226, lng: 19.0402087 },
    { lat: 49.0253311, lng: 19.0400376 },
    { lat: 49.0253615, lng: 19.0394269 },
    { lat: 49.0253079, lng: 19.0390534 },
    { lat: 49.025201, lng: 19.0386946 },
    { lat: 49.024845, lng: 19.0378897 },
    { lat: 49.0248351, lng: 19.037643 },
    { lat: 49.0249332, lng: 19.0371234 },
    { lat: 49.024934, lng: 19.0367806 },
    { lat: 49.0248699, lng: 19.0365093 },
    { lat: 49.0246619, lng: 19.0360472 },
    { lat: 49.0243136, lng: 19.0353489 },
    { lat: 49.0242979, lng: 19.0353532 },
    { lat: 49.0241485, lng: 19.034952 },
    { lat: 49.0242009, lng: 19.0344933 },
    { lat: 49.0242873, lng: 19.0342538 },
    { lat: 49.0244604, lng: 19.0337704 },
    { lat: 49.0245109, lng: 19.0336317 },
    { lat: 49.0248157, lng: 19.0330333 },
    { lat: 49.0249291, lng: 19.0327299 },
    { lat: 49.0249441, lng: 19.0327127 },
    { lat: 49.0252416, lng: 19.0320771 },
    { lat: 49.0251195, lng: 19.0318692 },
    { lat: 49.0249743, lng: 19.0315587 },
    { lat: 49.0249406, lng: 19.0313995 },
    { lat: 49.0248642, lng: 19.0309097 },
    { lat: 49.0248979, lng: 19.0305017 },
    { lat: 49.0247957, lng: 19.0291592 },
    { lat: 49.0246454, lng: 19.0283554 },
    { lat: 49.0245844, lng: 19.0275883 },
    { lat: 49.0246325, lng: 19.0266071 },
    { lat: 49.0249785, lng: 19.0250987 },
    { lat: 49.0248793, lng: 19.0241262 },
    { lat: 49.0248043, lng: 19.0240242 },
    { lat: 49.0245985, lng: 19.0231356 },
    { lat: 49.0250791, lng: 19.0206617 },
    { lat: 49.0253563, lng: 19.0199834 },
    { lat: 49.0255739, lng: 19.0179541 },
    { lat: 49.0258065, lng: 19.0169957 },
    { lat: 49.0262147, lng: 19.0156024 },
    { lat: 49.0264995, lng: 19.0154362 },
    { lat: 49.0265315, lng: 19.0154181 },
    { lat: 49.0268097, lng: 19.0145158 },
    { lat: 49.0270443, lng: 19.0129647 },
    { lat: 49.0273018, lng: 19.012502 },
    { lat: 49.027546, lng: 19.0116151 },
    { lat: 49.0276256, lng: 19.01102 },
    { lat: 49.027637, lng: 19.010483 },
    { lat: 49.027665, lng: 19.0092885 },
    { lat: 49.0276204, lng: 19.0084182 },
    { lat: 49.0277424, lng: 19.0071204 },
    { lat: 49.0278235, lng: 19.0064662 },
    { lat: 49.0280806, lng: 19.0055012 },
    { lat: 49.0283354, lng: 19.0051189 },
    { lat: 49.0289784, lng: 19.004633 },
    { lat: 49.0295417, lng: 19.0039748 },
    { lat: 49.0296113, lng: 19.0039222 },
    { lat: 49.0298372, lng: 19.0036636 },
    { lat: 49.030233, lng: 19.0034391 },
    { lat: 49.0306227, lng: 19.0027364 },
    { lat: 49.0308836, lng: 19.0023849 },
    { lat: 49.0308927, lng: 19.0021622 },
    { lat: 49.0308486, lng: 19.001734 },
    { lat: 49.0308544, lng: 19.0016662 },
    { lat: 49.0309413, lng: 19.0005696 },
    { lat: 49.0308649, lng: 18.9998998 },
    { lat: 49.0308276, lng: 18.998822 },
    { lat: 49.0308676, lng: 18.9977317 },
    { lat: 49.0310219, lng: 18.9965623 },
    { lat: 49.0311569, lng: 18.9962437 },
    { lat: 49.0313999, lng: 18.9961199 },
    { lat: 49.0320848, lng: 18.9963314 },
    { lat: 49.0329103, lng: 18.9961133 },
    { lat: 49.0329208, lng: 18.9960772 },
    { lat: 49.0333224, lng: 18.995677 },
    { lat: 49.0336861, lng: 18.9949938 },
    { lat: 49.0338942, lng: 18.9942445 },
    { lat: 49.033972, lng: 18.9937943 },
    { lat: 49.0339374, lng: 18.993088 },
    { lat: 49.0338979, lng: 18.9929043 },
    { lat: 49.0338409, lng: 18.9924647 },
    { lat: 49.0338898, lng: 18.9920692 },
    { lat: 49.0339062, lng: 18.9917478 },
    { lat: 49.0339849, lng: 18.9914527 },
    { lat: 49.0338249, lng: 18.9914756 },
    { lat: 49.0338431, lng: 18.9912784 },
    { lat: 49.0338712, lng: 18.9904688 },
    { lat: 49.0339261, lng: 18.9885542 },
    { lat: 49.0337769, lng: 18.9875415 },
    { lat: 49.0335636, lng: 18.9866591 },
    { lat: 49.0335362, lng: 18.9865419 },
    { lat: 49.0334891, lng: 18.9863666 },
    { lat: 49.0334585, lng: 18.9858715 },
    { lat: 49.0335073, lng: 18.9853851 },
    { lat: 49.0337398, lng: 18.9847864 },
    { lat: 49.0339231, lng: 18.9844841 },
    { lat: 49.0339432, lng: 18.9844576 },
    { lat: 49.0341695, lng: 18.9841183 },
    { lat: 49.0346624, lng: 18.9835155 },
    { lat: 49.0348218, lng: 18.9832789 },
    { lat: 49.0349904, lng: 18.982846 },
    { lat: 49.034988, lng: 18.9820796 },
    { lat: 49.0350317, lng: 18.9814223 },
    { lat: 49.0350368, lng: 18.9813926 },
    { lat: 49.0350541, lng: 18.9813087 },
    { lat: 49.0350515, lng: 18.9812806 },
    { lat: 49.0351384, lng: 18.9799626 },
    { lat: 49.0349179, lng: 18.9794543 },
    { lat: 49.0343324, lng: 18.9787314 },
    { lat: 49.0338987, lng: 18.9769926 },
    { lat: 49.0339981, lng: 18.9758136 },
    { lat: 49.0335213, lng: 18.97445 },
    { lat: 49.033108, lng: 18.9738101 },
    { lat: 49.0322993, lng: 18.9730231 },
    { lat: 49.03223, lng: 18.9728982 },
    { lat: 49.0321931, lng: 18.9728612 },
    { lat: 49.0318089, lng: 18.9718156 },
    { lat: 49.0317385, lng: 18.9717174 },
    { lat: 49.0316609, lng: 18.971742 },
    { lat: 49.0315668, lng: 18.9719326 },
    { lat: 49.0316971, lng: 18.971945 },
    { lat: 49.0317193, lng: 18.9720481 },
    { lat: 49.0316592, lng: 18.9722196 },
    { lat: 49.0317347, lng: 18.9723528 },
    { lat: 49.0316047, lng: 18.9724369 },
    { lat: 49.0316828, lng: 18.9725985 },
    { lat: 49.0317111, lng: 18.9728269 },
    { lat: 49.0316044, lng: 18.9728986 },
    { lat: 49.0315429, lng: 18.9731372 },
    { lat: 49.0314316, lng: 18.9730744 },
    { lat: 49.0312041, lng: 18.9730567 },
    { lat: 49.0311779, lng: 18.972893 },
    { lat: 49.0310903, lng: 18.9728565 },
    { lat: 49.0310941, lng: 18.9726883 },
    { lat: 49.0309762, lng: 18.9727391 },
    { lat: 49.0308644, lng: 18.9725786 },
    { lat: 49.0307173, lng: 18.9726126 },
    { lat: 49.0305373, lng: 18.9722851 },
    { lat: 49.0303962, lng: 18.9723734 },
    { lat: 49.0303094, lng: 18.9728639 },
    { lat: 49.0300911, lng: 18.9728484 },
    { lat: 49.0299569, lng: 18.9726054 },
    { lat: 49.0297818, lng: 18.9725727 },
    { lat: 49.0296639, lng: 18.9727646 },
    { lat: 49.0294753, lng: 18.9730895 },
    { lat: 49.0292916, lng: 18.9728253 },
    { lat: 49.0292199, lng: 18.9726774 },
    { lat: 49.0288567, lng: 18.9728311 },
    { lat: 49.0288121, lng: 18.9726394 },
    { lat: 49.0286463, lng: 18.97257 },
    { lat: 49.0284728, lng: 18.9726628 },
    { lat: 49.0284432, lng: 18.9728797 },
    { lat: 49.0284001, lng: 18.9731402 },
    { lat: 49.0282707, lng: 18.9732163 },
    { lat: 49.0280617, lng: 18.9730963 },
    { lat: 49.0279108, lng: 18.9730754 },
    { lat: 49.0278408, lng: 18.973233 },
    { lat: 49.0277328, lng: 18.9732126 },
    { lat: 49.0277034, lng: 18.9729441 },
    { lat: 49.0273215, lng: 18.9732365 },
    { lat: 49.0271311, lng: 18.9731297 },
    { lat: 49.0269516, lng: 18.9731672 },
    { lat: 49.0268682, lng: 18.972989 },
    { lat: 49.0267111, lng: 18.9729854 },
    { lat: 49.0266376, lng: 18.9731158 },
    { lat: 49.0264293, lng: 18.9730524 },
    { lat: 49.0262388, lng: 18.9730315 },
    { lat: 49.0261452, lng: 18.9731667 },
    { lat: 49.0261414, lng: 18.9732451 },
  ],
  TurčianskeKľačany: [
    { lat: 49.157799, lng: 18.949963 },
    { lat: 49.1581192, lng: 18.9476215 },
    { lat: 49.1585239, lng: 18.9446527 },
    { lat: 49.1583628, lng: 18.9438734 },
    { lat: 49.1583424, lng: 18.9434922 },
    { lat: 49.1582996, lng: 18.9433383 },
    { lat: 49.1581765, lng: 18.9420852 },
    { lat: 49.1579513, lng: 18.941378 },
    { lat: 49.1580127, lng: 18.94036 },
    { lat: 49.158679, lng: 18.9384013 },
    { lat: 49.1590064, lng: 18.9370733 },
    { lat: 49.1590179, lng: 18.9362557 },
    { lat: 49.1589988, lng: 18.9354301 },
    { lat: 49.158739, lng: 18.9348202 },
    { lat: 49.1585391, lng: 18.9341442 },
    { lat: 49.158529, lng: 18.9339433 },
    { lat: 49.1583321, lng: 18.9335962 },
    { lat: 49.1582286, lng: 18.9330688 },
    { lat: 49.1580306, lng: 18.9327665 },
    { lat: 49.1576285, lng: 18.9324634 },
    { lat: 49.1572264, lng: 18.9318883 },
    { lat: 49.1569885, lng: 18.9315997 },
    { lat: 49.1566814, lng: 18.9309248 },
    { lat: 49.1566144, lng: 18.9308466 },
    { lat: 49.1564294, lng: 18.9304877 },
    { lat: 49.1563739, lng: 18.930152 },
    { lat: 49.1563535, lng: 18.9303039 },
    { lat: 49.1560402, lng: 18.930862 },
    { lat: 49.1559395, lng: 18.9312344 },
    { lat: 49.1553346, lng: 18.9323567 },
    { lat: 49.1550975, lng: 18.9332869 },
    { lat: 49.1549805, lng: 18.9339162 },
    { lat: 49.1547803, lng: 18.9346789 },
    { lat: 49.1541351, lng: 18.9352289 },
    { lat: 49.1540835, lng: 18.9352782 },
    { lat: 49.1535896, lng: 18.9358124 },
    { lat: 49.1531951, lng: 18.9362839 },
    { lat: 49.1531639, lng: 18.9363113 },
    { lat: 49.152334, lng: 18.9370447 },
    { lat: 49.1518153, lng: 18.9367293 },
    { lat: 49.1517762, lng: 18.9366954 },
    { lat: 49.1516956, lng: 18.936572 },
    { lat: 49.1515516, lng: 18.9363977 },
    { lat: 49.1511428, lng: 18.93516 },
    { lat: 49.1505656, lng: 18.9342479 },
    { lat: 49.1501186, lng: 18.9336609 },
    { lat: 49.148835, lng: 18.9330254 },
    { lat: 49.1481963, lng: 18.9327906 },
    { lat: 49.1475956, lng: 18.9328145 },
    { lat: 49.1473135, lng: 18.9330104 },
    { lat: 49.1469045, lng: 18.9334389 },
    { lat: 49.1454861, lng: 18.9341486 },
    { lat: 49.1448834, lng: 18.9345803 },
    { lat: 49.1448599, lng: 18.9345827 },
    { lat: 49.1447953, lng: 18.9346231 },
    { lat: 49.1446959, lng: 18.9345977 },
    { lat: 49.1439618, lng: 18.9349495 },
    { lat: 49.1430444, lng: 18.9353135 },
    { lat: 49.1429468, lng: 18.9352328 },
    { lat: 49.1427538, lng: 18.9350041 },
    { lat: 49.1424744, lng: 18.9346517 },
    { lat: 49.142003, lng: 18.9340291 },
    { lat: 49.1414443, lng: 18.933504 },
    { lat: 49.1407874, lng: 18.9330433 },
    { lat: 49.140329, lng: 18.9325629 },
    { lat: 49.1398748, lng: 18.9321447 },
    { lat: 49.1387508, lng: 18.9313444 },
    { lat: 49.1381305, lng: 18.9309177 },
    { lat: 49.1375035, lng: 18.9304708 },
    { lat: 49.1372873, lng: 18.9302738 },
    { lat: 49.1371745, lng: 18.9301834 },
    { lat: 49.1366558, lng: 18.9301382 },
    { lat: 49.1359735, lng: 18.9298877 },
    { lat: 49.1350344, lng: 18.9297567 },
    { lat: 49.1348513, lng: 18.9297937 },
    { lat: 49.1337843, lng: 18.929694 },
    { lat: 49.1329353, lng: 18.9297803 },
    { lat: 49.1327164, lng: 18.9297114 },
    { lat: 49.1326688, lng: 18.9297108 },
    { lat: 49.1312046, lng: 18.9296928 },
    { lat: 49.1309116, lng: 18.9300283 },
    { lat: 49.1307901, lng: 18.9299241 },
    { lat: 49.1300396, lng: 18.9300186 },
    { lat: 49.1299515, lng: 18.9303494 },
    { lat: 49.1298931, lng: 18.9306184 },
    { lat: 49.1298417, lng: 18.9308491 },
    { lat: 49.1296545, lng: 18.9312066 },
    { lat: 49.129563, lng: 18.9313812 },
    { lat: 49.1293998, lng: 18.9313169 },
    { lat: 49.1293721, lng: 18.9313065 },
    { lat: 49.1293135, lng: 18.9315729 },
    { lat: 49.1292622, lng: 18.9318058 },
    { lat: 49.1292492, lng: 18.9318609 },
    { lat: 49.1291887, lng: 18.9321363 },
    { lat: 49.1293986, lng: 18.9322502 },
    { lat: 49.1292431, lng: 18.9331861 },
    { lat: 49.1289824, lng: 18.9342748 },
    { lat: 49.1286372, lng: 18.934021 },
    { lat: 49.12835, lng: 18.9338089 },
    { lat: 49.1281429, lng: 18.9336569 },
    { lat: 49.1280259, lng: 18.9335709 },
    { lat: 49.1277856, lng: 18.9335239 },
    { lat: 49.1277624, lng: 18.9334482 },
    { lat: 49.1277475, lng: 18.933397 },
    { lat: 49.1277366, lng: 18.9333598 },
    { lat: 49.127717, lng: 18.9333495 },
    { lat: 49.1275097, lng: 18.9332447 },
    { lat: 49.1274601, lng: 18.9331987 },
    { lat: 49.1274412, lng: 18.9331807 },
    { lat: 49.1274058, lng: 18.9331478 },
    { lat: 49.127376, lng: 18.9331196 },
    { lat: 49.127343, lng: 18.9330897 },
    { lat: 49.1272292, lng: 18.9329828 },
    { lat: 49.1271808, lng: 18.9329367 },
    { lat: 49.127034, lng: 18.9328002 },
    { lat: 49.1269796, lng: 18.9327483 },
    { lat: 49.1269514, lng: 18.9326804 },
    { lat: 49.1269154, lng: 18.932595 },
    { lat: 49.1268844, lng: 18.9325198 },
    { lat: 49.126805, lng: 18.932329 },
    { lat: 49.1264004, lng: 18.9318636 },
    { lat: 49.1263154, lng: 18.9317664 },
    { lat: 49.1262287, lng: 18.9315476 },
    { lat: 49.1261082, lng: 18.9312407 },
    { lat: 49.1260951, lng: 18.9311353 },
    { lat: 49.1260816, lng: 18.9310285 },
    { lat: 49.1260669, lng: 18.930914 },
    { lat: 49.1260084, lng: 18.9309594 },
    { lat: 49.1259146, lng: 18.9309776 },
    { lat: 49.125574, lng: 18.931044 },
    { lat: 49.1252682, lng: 18.9311031 },
    { lat: 49.1251559, lng: 18.9310695 },
    { lat: 49.1249111, lng: 18.9309968 },
    { lat: 49.1245151, lng: 18.9309509 },
    { lat: 49.1244832, lng: 18.9309633 },
    { lat: 49.1243979, lng: 18.9309967 },
    { lat: 49.1242631, lng: 18.9312271 },
    { lat: 49.12425, lng: 18.9312564 },
    { lat: 49.1242214, lng: 18.9313181 },
    { lat: 49.1239595, lng: 18.9317114 },
    { lat: 49.123952, lng: 18.931741 },
    { lat: 49.1239115, lng: 18.9317203 },
    { lat: 49.1237691, lng: 18.9317115 },
    { lat: 49.1237058, lng: 18.9317078 },
    { lat: 49.1236696, lng: 18.9317055 },
    { lat: 49.1235382, lng: 18.9316263 },
    { lat: 49.1231649, lng: 18.9314014 },
    { lat: 49.1228381, lng: 18.9312044 },
    { lat: 49.1228151, lng: 18.9313295 },
    { lat: 49.1226133, lng: 18.9320411 },
    { lat: 49.1225488, lng: 18.9323068 },
    { lat: 49.1223121, lng: 18.9331284 },
    { lat: 49.1219047, lng: 18.9342126 },
    { lat: 49.1213728, lng: 18.9355062 },
    { lat: 49.1206168, lng: 18.9367599 },
    { lat: 49.119347, lng: 18.9385573 },
    { lat: 49.1190129, lng: 18.9391296 },
    { lat: 49.1186637, lng: 18.9388035 },
    { lat: 49.1178442, lng: 18.9374051 },
    { lat: 49.1175032, lng: 18.9368153 },
    { lat: 49.116096, lng: 18.9362077 },
    { lat: 49.1157983, lng: 18.9361409 },
    { lat: 49.1156614, lng: 18.9359752 },
    { lat: 49.1156055, lng: 18.9357871 },
    { lat: 49.115622, lng: 18.9353748 },
    { lat: 49.1153692, lng: 18.9350166 },
    { lat: 49.1148488, lng: 18.9343371 },
    { lat: 49.1139331, lng: 18.9356015 },
    { lat: 49.113244, lng: 18.9367068 },
    { lat: 49.1130145, lng: 18.9371624 },
    { lat: 49.1128069, lng: 18.9376708 },
    { lat: 49.1123881, lng: 18.9388155 },
    { lat: 49.1121973, lng: 18.9395268 },
    { lat: 49.112147, lng: 18.9398429 },
    { lat: 49.1119612, lng: 18.9410088 },
    { lat: 49.1119343, lng: 18.9427458 },
    { lat: 49.1117927, lng: 18.9435559 },
    { lat: 49.1118623, lng: 18.9467607 },
    { lat: 49.1118476, lng: 18.9489649 },
    { lat: 49.1118401, lng: 18.9497988 },
    { lat: 49.1117939, lng: 18.9507158 },
    { lat: 49.1116985, lng: 18.9519711 },
    { lat: 49.1115611, lng: 18.9528469 },
    { lat: 49.1114836, lng: 18.9533414 },
    { lat: 49.1112213, lng: 18.9544683 },
    { lat: 49.1109667, lng: 18.955789 },
    { lat: 49.110861, lng: 18.9569094 },
    { lat: 49.110816, lng: 18.9581368 },
    { lat: 49.1107842, lng: 18.9584248 },
    { lat: 49.1110976, lng: 18.9606559 },
    { lat: 49.111275, lng: 18.9619145 },
    { lat: 49.1114636, lng: 18.963289 },
    { lat: 49.111584, lng: 18.9645985 },
    { lat: 49.1116815, lng: 18.9659744 },
    { lat: 49.111691, lng: 18.9669219 },
    { lat: 49.1116546, lng: 18.9674328 },
    { lat: 49.1114857, lng: 18.9686664 },
    { lat: 49.1115259, lng: 18.9687045 },
    { lat: 49.1115836, lng: 18.968728 },
    { lat: 49.1125207, lng: 18.9691395 },
    { lat: 49.1126884, lng: 18.9691534 },
    { lat: 49.1127839, lng: 18.9691494 },
    { lat: 49.1129543, lng: 18.969128 },
    { lat: 49.1151507, lng: 18.9685981 },
    { lat: 49.1151788, lng: 18.9687726 },
    { lat: 49.1151744, lng: 18.9688447 },
    { lat: 49.1157876, lng: 18.969175 },
    { lat: 49.1160448, lng: 18.969268 },
    { lat: 49.1162917, lng: 18.969268 },
    { lat: 49.1166741, lng: 18.9691862 },
    { lat: 49.1175216, lng: 18.9690209 },
    { lat: 49.1179217, lng: 18.968774 },
    { lat: 49.1181223, lng: 18.9687044 },
    { lat: 49.1182326, lng: 18.9684976 },
    { lat: 49.1183802, lng: 18.968521 },
    { lat: 49.1185761, lng: 18.9684048 },
    { lat: 49.1186144, lng: 18.9684047 },
    { lat: 49.1187216, lng: 18.9682907 },
    { lat: 49.1187993, lng: 18.9681131 },
    { lat: 49.1189293, lng: 18.9679633 },
    { lat: 49.1191175, lng: 18.9679625 },
    { lat: 49.1193901, lng: 18.9677781 },
    { lat: 49.1196763, lng: 18.9681563 },
    { lat: 49.120024, lng: 18.9682118 },
    { lat: 49.1205482, lng: 18.9681631 },
    { lat: 49.1209624, lng: 18.968039 },
    { lat: 49.1214381, lng: 18.968262 },
    { lat: 49.1217079, lng: 18.9686465 },
    { lat: 49.122021, lng: 18.968733 },
    { lat: 49.1224392, lng: 18.9688885 },
    { lat: 49.1226814, lng: 18.9692651 },
    { lat: 49.1229848, lng: 18.9695806 },
    { lat: 49.1231338, lng: 18.9698317 },
    { lat: 49.1231596, lng: 18.9700486 },
    { lat: 49.1233189, lng: 18.9701682 },
    { lat: 49.1235094, lng: 18.970323 },
    { lat: 49.1236509, lng: 18.9704508 },
    { lat: 49.1239438, lng: 18.97058 },
    { lat: 49.124035, lng: 18.9708915 },
    { lat: 49.1241944, lng: 18.9710352 },
    { lat: 49.1243238, lng: 18.9710939 },
    { lat: 49.1243429, lng: 18.9712716 },
    { lat: 49.1245848, lng: 18.9715767 },
    { lat: 49.124724, lng: 18.9717231 },
    { lat: 49.125204, lng: 18.972253 },
    { lat: 49.125426, lng: 18.9727684 },
    { lat: 49.1254907, lng: 18.9730668 },
    { lat: 49.1258377, lng: 18.9736015 },
    { lat: 49.1261081, lng: 18.9737776 },
    { lat: 49.1266137, lng: 18.9739208 },
    { lat: 49.1266457, lng: 18.9739084 },
    { lat: 49.1270208, lng: 18.9736774 },
    { lat: 49.1272733, lng: 18.9736994 },
    { lat: 49.127441, lng: 18.9736715 },
    { lat: 49.1278809, lng: 18.9734085 },
    { lat: 49.1282794, lng: 18.9732823 },
    { lat: 49.1283398, lng: 18.9732426 },
    { lat: 49.1284282, lng: 18.9732102 },
    { lat: 49.1295179, lng: 18.9731142 },
    { lat: 49.1305984, lng: 18.9729677 },
    { lat: 49.1307796, lng: 18.972714 },
    { lat: 49.1309226, lng: 18.9725335 },
    { lat: 49.131463, lng: 18.971827 },
    { lat: 49.1322402, lng: 18.971028 },
    { lat: 49.1333683, lng: 18.9694762 },
    { lat: 49.1340522, lng: 18.9690625 },
    { lat: 49.134658, lng: 18.9690024 },
    { lat: 49.1354261, lng: 18.969282 },
    { lat: 49.1354444, lng: 18.9692424 },
    { lat: 49.1356833, lng: 18.9692623 },
    { lat: 49.1362277, lng: 18.9692815 },
    { lat: 49.1366841, lng: 18.9691636 },
    { lat: 49.1375555, lng: 18.9685583 },
    { lat: 49.1381934, lng: 18.9680376 },
    { lat: 49.1389785, lng: 18.9680023 },
    { lat: 49.1398536, lng: 18.9677393 },
    { lat: 49.1404538, lng: 18.9676172 },
    { lat: 49.140791, lng: 18.9676602 },
    { lat: 49.1413811, lng: 18.9676038 },
    { lat: 49.1421664, lng: 18.9674399 },
    { lat: 49.142563, lng: 18.9673647 },
    { lat: 49.1430889, lng: 18.9672419 },
    { lat: 49.1433741, lng: 18.9667937 },
    { lat: 49.1436745, lng: 18.966558 },
    { lat: 49.1438872, lng: 18.9654092 },
    { lat: 49.1441784, lng: 18.9641656 },
    { lat: 49.1443575, lng: 18.9637593 },
    { lat: 49.1447593, lng: 18.9630957 },
    { lat: 49.1454594, lng: 18.9625597 },
    { lat: 49.1458783, lng: 18.9620122 },
    { lat: 49.1463324, lng: 18.9613807 },
    { lat: 49.1470201, lng: 18.9608002 },
    { lat: 49.1473469, lng: 18.9604829 },
    { lat: 49.1479745, lng: 18.9597823 },
    { lat: 49.1484727, lng: 18.9592893 },
    { lat: 49.1489702, lng: 18.958694 },
    { lat: 49.1494588, lng: 18.9580998 },
    { lat: 49.1497522, lng: 18.9577254 },
    { lat: 49.1500442, lng: 18.9573887 },
    { lat: 49.1503097, lng: 18.9572434 },
    { lat: 49.1509542, lng: 18.9568358 },
    { lat: 49.1514795, lng: 18.9566096 },
    { lat: 49.1519995, lng: 18.9563473 },
    { lat: 49.1526663, lng: 18.9558675 },
    { lat: 49.153101, lng: 18.9554563 },
    { lat: 49.1533542, lng: 18.9551083 },
    { lat: 49.1537387, lng: 18.9544411 },
    { lat: 49.1538879, lng: 18.9540685 },
    { lat: 49.1541889, lng: 18.9533543 },
    { lat: 49.154302, lng: 18.9528937 },
    { lat: 49.154522, lng: 18.9514873 },
    { lat: 49.1548907, lng: 18.9508872 },
    { lat: 49.1552068, lng: 18.9502025 },
    { lat: 49.1554437, lng: 18.9497169 },
    { lat: 49.1561519, lng: 18.9498509 },
    { lat: 49.1568649, lng: 18.950019 },
    { lat: 49.1571642, lng: 18.9500278 },
    { lat: 49.157799, lng: 18.949963 },
  ],
  TurčianskyPeter: [
    { lat: 49.0402833, lng: 18.9022691 },
    { lat: 49.0403702, lng: 18.9022101 },
    { lat: 49.0404709, lng: 18.9020871 },
    { lat: 49.0405762, lng: 18.902006 },
    { lat: 49.0407057, lng: 18.9018272 },
    { lat: 49.0407877, lng: 18.9016641 },
    { lat: 49.0408827, lng: 18.9015346 },
    { lat: 49.041001, lng: 18.9013589 },
    { lat: 49.0410727, lng: 18.9012171 },
    { lat: 49.0411178, lng: 18.9010404 },
    { lat: 49.0411313, lng: 18.900954 },
    { lat: 49.0413328, lng: 18.9002808 },
    { lat: 49.0415724, lng: 18.8995409 },
    { lat: 49.0417399, lng: 18.899005 },
    { lat: 49.0419194, lng: 18.8983887 },
    { lat: 49.0420308, lng: 18.8979881 },
    { lat: 49.0421866, lng: 18.8974403 },
    { lat: 49.0423798, lng: 18.8968287 },
    { lat: 49.0425924, lng: 18.896115 },
    { lat: 49.0426041, lng: 18.8959695 },
    { lat: 49.0426591, lng: 18.8957639 },
    { lat: 49.0422262, lng: 18.8955463 },
    { lat: 49.0416017, lng: 18.8952578 },
    { lat: 49.041152, lng: 18.8950447 },
    { lat: 49.0409071, lng: 18.8949489 },
    { lat: 49.040592, lng: 18.8948079 },
    { lat: 49.0406069, lng: 18.8942628 },
    { lat: 49.0406153, lng: 18.8935854 },
    { lat: 49.0406072, lng: 18.8933201 },
    { lat: 49.0406079, lng: 18.8926681 },
    { lat: 49.0405898, lng: 18.8919407 },
    { lat: 49.0405989, lng: 18.8913042 },
    { lat: 49.0405975, lng: 18.8901738 },
    { lat: 49.0405946, lng: 18.8895445 },
    { lat: 49.0405985, lng: 18.8890707 },
    { lat: 49.0406009, lng: 18.8883872 },
    { lat: 49.0406013, lng: 18.8876709 },
    { lat: 49.040594, lng: 18.8865577 },
    { lat: 49.0406004, lng: 18.8861519 },
    { lat: 49.0406356, lng: 18.8860449 },
    { lat: 49.040675, lng: 18.8858469 },
    { lat: 49.0407123, lng: 18.8855176 },
    { lat: 49.0407548, lng: 18.8852269 },
    { lat: 49.0408916, lng: 18.8843546 },
    { lat: 49.0409527, lng: 18.8839822 },
    { lat: 49.0411395, lng: 18.8827853 },
    { lat: 49.0412901, lng: 18.8818524 },
    { lat: 49.0414068, lng: 18.8810476 },
    { lat: 49.0414943, lng: 18.8805333 },
    { lat: 49.0415358, lng: 18.8802006 },
    { lat: 49.0415852, lng: 18.8798871 },
    { lat: 49.0415941, lng: 18.879775 },
    { lat: 49.0415559, lng: 18.8794493 },
    { lat: 49.0415263, lng: 18.8790219 },
    { lat: 49.0414499, lng: 18.8785655 },
    { lat: 49.041426, lng: 18.8782966 },
    { lat: 49.0414265, lng: 18.8781075 },
    { lat: 49.0414481, lng: 18.8778466 },
    { lat: 49.0415874, lng: 18.877287 },
    { lat: 49.0417053, lng: 18.8768396 },
    { lat: 49.041862, lng: 18.8761773 },
    { lat: 49.0419787, lng: 18.8757063 },
    { lat: 49.0421999, lng: 18.8752704 },
    { lat: 49.0423747, lng: 18.8744356 },
    { lat: 49.0424172, lng: 18.8741631 },
    { lat: 49.042503, lng: 18.8732243 },
    { lat: 49.0422695, lng: 18.8730665 },
    { lat: 49.0416506, lng: 18.8726717 },
    { lat: 49.04137, lng: 18.8724757 },
    { lat: 49.0414802, lng: 18.8689559 },
    { lat: 49.0412257, lng: 18.8689409 },
    { lat: 49.0407854, lng: 18.8689932 },
    { lat: 49.0408424, lng: 18.8681699 },
    { lat: 49.0409409, lng: 18.8663159 },
    { lat: 49.0413281, lng: 18.8659281 },
    { lat: 49.0413935, lng: 18.8652055 },
    { lat: 49.0414228, lng: 18.8643668 },
    { lat: 49.041381, lng: 18.8633561 },
    { lat: 49.0412086, lng: 18.8626585 },
    { lat: 49.0412233, lng: 18.8620213 },
    { lat: 49.0411987, lng: 18.861676 },
    { lat: 49.041106, lng: 18.8604417 },
    { lat: 49.0411289, lng: 18.8598761 },
    { lat: 49.0413059, lng: 18.858912 },
    { lat: 49.0417251, lng: 18.8571528 },
    { lat: 49.0419665, lng: 18.8561444 },
    { lat: 49.0423733, lng: 18.8556607 },
    { lat: 49.0431503, lng: 18.8550938 },
    { lat: 49.0437042, lng: 18.8540901 },
    { lat: 49.0439184, lng: 18.8535643 },
    { lat: 49.0439607, lng: 18.8534863 },
    { lat: 49.0443166, lng: 18.8529332 },
    { lat: 49.0444973, lng: 18.8525648 },
    { lat: 49.0448426, lng: 18.8521305 },
    { lat: 49.0450695, lng: 18.8518504 },
    { lat: 49.045256, lng: 18.851357 },
    { lat: 49.0454286, lng: 18.8510521 },
    { lat: 49.0455223, lng: 18.850637 },
    { lat: 49.0454114, lng: 18.850189 },
    { lat: 49.045196, lng: 18.8497733 },
    { lat: 49.045038, lng: 18.8492472 },
    { lat: 49.0449841, lng: 18.8489215 },
    { lat: 49.0449125, lng: 18.8483668 },
    { lat: 49.0449207, lng: 18.8474935 },
    { lat: 49.0450227, lng: 18.8469782 },
    { lat: 49.0450335, lng: 18.8461376 },
    { lat: 49.0448133, lng: 18.8452333 },
    { lat: 49.0449176, lng: 18.8444786 },
    { lat: 49.0449236, lng: 18.8442878 },
    { lat: 49.0450048, lng: 18.8442231 },
    { lat: 49.0449218, lng: 18.8439932 },
    { lat: 49.0447031, lng: 18.8434485 },
    { lat: 49.0445709, lng: 18.842873 },
    { lat: 49.0445547, lng: 18.8420778 },
    { lat: 49.0446719, lng: 18.8415972 },
    { lat: 49.044782, lng: 18.841193 },
    { lat: 49.044813, lng: 18.8408324 },
    { lat: 49.0447442, lng: 18.8397817 },
    { lat: 49.0446466, lng: 18.8394429 },
    { lat: 49.0446705, lng: 18.8392054 },
    { lat: 49.0447448, lng: 18.8390934 },
    { lat: 49.0448286, lng: 18.8391017 },
    { lat: 49.0449927, lng: 18.8392 },
    { lat: 49.0450638, lng: 18.8392914 },
    { lat: 49.045141, lng: 18.839367 },
    { lat: 49.0452179, lng: 18.8393931 },
    { lat: 49.0453526, lng: 18.8394011 },
    { lat: 49.0454633, lng: 18.8392768 },
    { lat: 49.0455829, lng: 18.8388603 },
    { lat: 49.0456219, lng: 18.8385897 },
    { lat: 49.0456347, lng: 18.838247 },
    { lat: 49.0456457, lng: 18.8376731 },
    { lat: 49.0456476, lng: 18.8369217 },
    { lat: 49.045773, lng: 18.8361648 },
    { lat: 49.0458019, lng: 18.8354802 },
    { lat: 49.0455998, lng: 18.8349063 },
    { lat: 49.0453891, lng: 18.8341191 },
    { lat: 49.0455887, lng: 18.8332261 },
    { lat: 49.0455568, lng: 18.8329798 },
    { lat: 49.0454423, lng: 18.832661 },
    { lat: 49.0452855, lng: 18.8322922 },
    { lat: 49.0450613, lng: 18.8319456 },
    { lat: 49.0450859, lng: 18.8315929 },
    { lat: 49.0452845, lng: 18.830635 },
    { lat: 49.0453586, lng: 18.8301657 },
    { lat: 49.0453778, lng: 18.8297967 },
    { lat: 49.045336, lng: 18.8295131 },
    { lat: 49.045206, lng: 18.8290919 },
    { lat: 49.04517, lng: 18.8288755 },
    { lat: 49.045142, lng: 18.8286409 },
    { lat: 49.0451731, lng: 18.8281487 },
    { lat: 49.0452142, lng: 18.8279853 },
    { lat: 49.0454889, lng: 18.8274532 },
    { lat: 49.045543, lng: 18.8271919 },
    { lat: 49.0446515, lng: 18.8267209 },
    { lat: 49.0436678, lng: 18.8262081 },
    { lat: 49.0431237, lng: 18.8257373 },
    { lat: 49.0414403, lng: 18.8247573 },
    { lat: 49.0414492, lng: 18.8248428 },
    { lat: 49.0412678, lng: 18.825937 },
    { lat: 49.0412445, lng: 18.8263359 },
    { lat: 49.0411554, lng: 18.8272314 },
    { lat: 49.0414482, lng: 18.8282283 },
    { lat: 49.0416173, lng: 18.8287946 },
    { lat: 49.0417946, lng: 18.8301726 },
    { lat: 49.0417943, lng: 18.8304965 },
    { lat: 49.0418088, lng: 18.8316916 },
    { lat: 49.0417619, lng: 18.832682 },
    { lat: 49.0423961, lng: 18.8336981 },
    { lat: 49.0428103, lng: 18.8340785 },
    { lat: 49.0430758, lng: 18.8347394 },
    { lat: 49.0433115, lng: 18.8351598 },
    { lat: 49.0436049, lng: 18.835991 },
    { lat: 49.0435067, lng: 18.8372381 },
    { lat: 49.0435407, lng: 18.8376542 },
    { lat: 49.0433583, lng: 18.8383146 },
    { lat: 49.0429821, lng: 18.8393293 },
    { lat: 49.0423345, lng: 18.8403482 },
    { lat: 49.0422478, lng: 18.8404808 },
    { lat: 49.0415193, lng: 18.8418141 },
    { lat: 49.0416028, lng: 18.8434337 },
    { lat: 49.0416364, lng: 18.8435803 },
    { lat: 49.0417903, lng: 18.8443157 },
    { lat: 49.0418919, lng: 18.8447798 },
    { lat: 49.0419008, lng: 18.8448423 },
    { lat: 49.0419721, lng: 18.8458531 },
    { lat: 49.0421013, lng: 18.8464361 },
    { lat: 49.0421178, lng: 18.8469718 },
    { lat: 49.0419429, lng: 18.8474509 },
    { lat: 49.0415908, lng: 18.8481244 },
    { lat: 49.0413126, lng: 18.8493342 },
    { lat: 49.041224, lng: 18.849587 },
    { lat: 49.041007, lng: 18.8499931 },
    { lat: 49.0407174, lng: 18.8505638 },
    { lat: 49.0397562, lng: 18.8515179 },
    { lat: 49.0390768, lng: 18.8524247 },
    { lat: 49.0389573, lng: 18.8528156 },
    { lat: 49.0375006, lng: 18.8577094 },
    { lat: 49.0373755, lng: 18.8585853 },
    { lat: 49.0372513, lng: 18.8595711 },
    { lat: 49.0370812, lng: 18.8608434 },
    { lat: 49.0370724, lng: 18.8609163 },
    { lat: 49.0367413, lng: 18.8627216 },
    { lat: 49.0365064, lng: 18.863658 },
    { lat: 49.0360919, lng: 18.8639509 },
    { lat: 49.0359333, lng: 18.8641351 },
    { lat: 49.0358418, lng: 18.8644827 },
    { lat: 49.0359115, lng: 18.865159 },
    { lat: 49.0351421, lng: 18.8659623 },
    { lat: 49.0343157, lng: 18.8664705 },
    { lat: 49.0340359, lng: 18.8669942 },
    { lat: 49.0337661, lng: 18.8671179 },
    { lat: 49.033664, lng: 18.867853 },
    { lat: 49.0333825, lng: 18.8683216 },
    { lat: 49.0330434, lng: 18.8686371 },
    { lat: 49.0322781, lng: 18.8690144 },
    { lat: 49.0318419, lng: 18.8691053 },
    { lat: 49.031146, lng: 18.8693432 },
    { lat: 49.0304943, lng: 18.8697799 },
    { lat: 49.0299381, lng: 18.8700381 },
    { lat: 49.0293385, lng: 18.8703055 },
    { lat: 49.029294, lng: 18.8707373 },
    { lat: 49.0291262, lng: 18.8714442 },
    { lat: 49.0291065, lng: 18.8721565 },
    { lat: 49.029012, lng: 18.8726449 },
    { lat: 49.0289252, lng: 18.8734173 },
    { lat: 49.0288599, lng: 18.8740783 },
    { lat: 49.0288726, lng: 18.8747864 },
    { lat: 49.0288243, lng: 18.8749653 },
    { lat: 49.0286453, lng: 18.8754773 },
    { lat: 49.028621, lng: 18.8755984 },
    { lat: 49.0286247, lng: 18.8757345 },
    { lat: 49.0286873, lng: 18.8760526 },
    { lat: 49.0287032, lng: 18.8762742 },
    { lat: 49.0286354, lng: 18.8767598 },
    { lat: 49.0286955, lng: 18.8777968 },
    { lat: 49.0286523, lng: 18.8779467 },
    { lat: 49.0285405, lng: 18.8781155 },
    { lat: 49.0281536, lng: 18.8785972 },
    { lat: 49.028272, lng: 18.8789482 },
    { lat: 49.0281555, lng: 18.8791834 },
    { lat: 49.0279215, lng: 18.8796782 },
    { lat: 49.0280157, lng: 18.8798011 },
    { lat: 49.0278671, lng: 18.8809264 },
    { lat: 49.0279648, lng: 18.8814458 },
    { lat: 49.0278092, lng: 18.8815117 },
    { lat: 49.0260063, lng: 18.8819107 },
    { lat: 49.0259689, lng: 18.8838416 },
    { lat: 49.025859, lng: 18.8842926 },
    { lat: 49.0258514, lng: 18.8845414 },
    { lat: 49.0259951, lng: 18.8846213 },
    { lat: 49.0259936, lng: 18.8853397 },
    { lat: 49.0259667, lng: 18.88618 },
    { lat: 49.0259778, lng: 18.8870798 },
    { lat: 49.0260911, lng: 18.8877936 },
    { lat: 49.0258845, lng: 18.8892411 },
    { lat: 49.0259072, lng: 18.8893987 },
    { lat: 49.0259532, lng: 18.8898118 },
    { lat: 49.0259849, lng: 18.8903897 },
    { lat: 49.0259575, lng: 18.8907551 },
    { lat: 49.0257575, lng: 18.8916946 },
    { lat: 49.0256242, lng: 18.8923312 },
    { lat: 49.0253207, lng: 18.8926566 },
    { lat: 49.0253005, lng: 18.8928588 },
    { lat: 49.025335, lng: 18.8932233 },
    { lat: 49.0255393, lng: 18.8933605 },
    { lat: 49.0257819, lng: 18.8936734 },
    { lat: 49.0260237, lng: 18.8941899 },
    { lat: 49.0263471, lng: 18.8951124 },
    { lat: 49.0263886, lng: 18.8952411 },
    { lat: 49.0264238, lng: 18.8955309 },
    { lat: 49.0263378, lng: 18.8958604 },
    { lat: 49.0262076, lng: 18.8961787 },
    { lat: 49.0261221, lng: 18.8964938 },
    { lat: 49.0261282, lng: 18.8968987 },
    { lat: 49.026615, lng: 18.8981504 },
    { lat: 49.0268356, lng: 18.8982727 },
    { lat: 49.0269943, lng: 18.8986463 },
    { lat: 49.0272991, lng: 18.898827 },
    { lat: 49.0275672, lng: 18.8988018 },
    { lat: 49.0277816, lng: 18.8985821 },
    { lat: 49.0280942, lng: 18.8984575 },
    { lat: 49.0282858, lng: 18.8983896 },
    { lat: 49.0285086, lng: 18.8983571 },
    { lat: 49.0289942, lng: 18.8984643 },
    { lat: 49.0291256, lng: 18.8984086 },
    { lat: 49.0293848, lng: 18.8983395 },
    { lat: 49.0294917, lng: 18.8980926 },
    { lat: 49.0295443, lng: 18.8980397 },
    { lat: 49.0296115, lng: 18.8980332 },
    { lat: 49.0296421, lng: 18.8981704 },
    { lat: 49.0299848, lng: 18.8979212 },
    { lat: 49.0304857, lng: 18.8978397 },
    { lat: 49.0308348, lng: 18.8976113 },
    { lat: 49.031155, lng: 18.8972795 },
    { lat: 49.0314841, lng: 18.8971666 },
    { lat: 49.0316802, lng: 18.8972088 },
    { lat: 49.0322169, lng: 18.8971016 },
    { lat: 49.0325924, lng: 18.8970152 },
    { lat: 49.0328268, lng: 18.896834 },
    { lat: 49.033008, lng: 18.8964975 },
    { lat: 49.033029, lng: 18.8960024 },
    { lat: 49.0329685, lng: 18.8954141 },
    { lat: 49.03302, lng: 18.8949653 },
    { lat: 49.033136, lng: 18.8946559 },
    { lat: 49.0335213, lng: 18.8942938 },
    { lat: 49.0337945, lng: 18.8942593 },
    { lat: 49.0340619, lng: 18.8944613 },
    { lat: 49.0344134, lng: 18.8945193 },
    { lat: 49.0349827, lng: 18.8944768 },
    { lat: 49.0349738, lng: 18.894057 },
    { lat: 49.0350035, lng: 18.8936902 },
    { lat: 49.0353796, lng: 18.8929784 },
    { lat: 49.0355689, lng: 18.8928633 },
    { lat: 49.0358168, lng: 18.8929713 },
    { lat: 49.0361916, lng: 18.8933801 },
    { lat: 49.0364276, lng: 18.894045 },
    { lat: 49.0365038, lng: 18.8944579 },
    { lat: 49.0365861, lng: 18.8949635 },
    { lat: 49.0366635, lng: 18.8952443 },
    { lat: 49.0369226, lng: 18.8955899 },
    { lat: 49.0371306, lng: 18.8957989 },
    { lat: 49.037434, lng: 18.8962903 },
    { lat: 49.0376598, lng: 18.8966679 },
    { lat: 49.0376604, lng: 18.8966686 },
    { lat: 49.0378651, lng: 18.8969361 },
    { lat: 49.038282, lng: 18.8973508 },
    { lat: 49.0385921, lng: 18.8977691 },
    { lat: 49.0387634, lng: 18.8979914 },
    { lat: 49.0390289, lng: 18.898374 },
    { lat: 49.0391524, lng: 18.8985427 },
    { lat: 49.0392992, lng: 18.8988786 },
    { lat: 49.0394195, lng: 18.899226 },
    { lat: 49.0395944, lng: 18.9000412 },
    { lat: 49.0397098, lng: 18.9006913 },
    { lat: 49.039915, lng: 18.901245 },
    { lat: 49.0402833, lng: 18.9022691 },
  ],
  TurčianskyĎur: [
    { lat: 48.94868, lng: 18.851328 },
    { lat: 48.9488089, lng: 18.8517695 },
    { lat: 48.9496761, lng: 18.8524715 },
    { lat: 48.9501086, lng: 18.8523949 },
    { lat: 48.9502926, lng: 18.8524631 },
    { lat: 48.9504845, lng: 18.8526882 },
    { lat: 48.9506283, lng: 18.8530715 },
    { lat: 48.9507169, lng: 18.8536532 },
    { lat: 48.9504624, lng: 18.8546898 },
    { lat: 48.9504437, lng: 18.8549812 },
    { lat: 48.950561, lng: 18.8552456 },
    { lat: 48.9513584, lng: 18.8554863 },
    { lat: 48.9516992, lng: 18.8554523 },
    { lat: 48.9519098, lng: 18.8553566 },
    { lat: 48.9521751, lng: 18.8551234 },
    { lat: 48.9527486, lng: 18.8553979 },
    { lat: 48.9529497, lng: 18.8547519 },
    { lat: 48.9531744, lng: 18.8541006 },
    { lat: 48.9532666, lng: 18.853832 },
    { lat: 48.9536225, lng: 18.852271 },
    { lat: 48.9536229, lng: 18.8521038 },
    { lat: 48.9536026, lng: 18.8517966 },
    { lat: 48.9535903, lng: 18.8512968 },
    { lat: 48.9535918, lng: 18.8511076 },
    { lat: 48.9535821, lng: 18.8509638 },
    { lat: 48.9535567, lng: 18.8509528 },
    { lat: 48.9535763, lng: 18.8508055 },
    { lat: 48.953618, lng: 18.8506115 },
    { lat: 48.9535488, lng: 18.8504472 },
    { lat: 48.9535202, lng: 18.850245 },
    { lat: 48.9534782, lng: 18.8496063 },
    { lat: 48.9534164, lng: 18.8485409 },
    { lat: 48.953446, lng: 18.8485005 },
    { lat: 48.9537365, lng: 18.8484056 },
    { lat: 48.9538927, lng: 18.8483063 },
    { lat: 48.9539886, lng: 18.8482224 },
    { lat: 48.9540621, lng: 18.8481179 },
    { lat: 48.9541106, lng: 18.8480038 },
    { lat: 48.9541759, lng: 18.8478084 },
    { lat: 48.9542681, lng: 18.8473891 },
    { lat: 48.9543888, lng: 18.8469722 },
    { lat: 48.9544595, lng: 18.8467718 },
    { lat: 48.9545264, lng: 18.8466666 },
    { lat: 48.9545792, lng: 18.8464673 },
    { lat: 48.9546031, lng: 18.8462754 },
    { lat: 48.9546273, lng: 18.8458287 },
    { lat: 48.9546126, lng: 18.8454989 },
    { lat: 48.9546235, lng: 18.8454036 },
    { lat: 48.9546354, lng: 18.8453951 },
    { lat: 48.9548145, lng: 18.844297 },
    { lat: 48.9548293, lng: 18.8441037 },
    { lat: 48.9547824, lng: 18.8439406 },
    { lat: 48.9545904, lng: 18.8435405 },
    { lat: 48.9543669, lng: 18.8428136 },
    { lat: 48.9542174, lng: 18.8424289 },
    { lat: 48.9541086, lng: 18.8420253 },
    { lat: 48.9540701, lng: 18.8417834 },
    { lat: 48.954057, lng: 18.841593 },
    { lat: 48.9540859, lng: 18.8414568 },
    { lat: 48.9541925, lng: 18.8412482 },
    { lat: 48.9542229, lng: 18.8410308 },
    { lat: 48.9542297, lng: 18.8407917 },
    { lat: 48.9540747, lng: 18.8405637 },
    { lat: 48.9539234, lng: 18.8401638 },
    { lat: 48.9539877, lng: 18.8399907 },
    { lat: 48.954144, lng: 18.8397242 },
    { lat: 48.9538129, lng: 18.8394486 },
    { lat: 48.9537215, lng: 18.8394207 },
    { lat: 48.9538672, lng: 18.8387529 },
    { lat: 48.953949, lng: 18.8384554 },
    { lat: 48.9541049, lng: 18.838026 },
    { lat: 48.9541308, lng: 18.8378723 },
    { lat: 48.951583, lng: 18.835713 },
    { lat: 48.951332, lng: 18.835663 },
    { lat: 48.951067, lng: 18.835584 },
    { lat: 48.950689, lng: 18.835318 },
    { lat: 48.950273, lng: 18.834984 },
    { lat: 48.94837, lng: 18.833948 },
    { lat: 48.948336, lng: 18.833929 },
    { lat: 48.948203, lng: 18.834433 },
    { lat: 48.94788, lng: 18.835266 },
    { lat: 48.947494, lng: 18.835934 },
    { lat: 48.947456, lng: 18.835893 },
    { lat: 48.947403, lng: 18.835929 },
    { lat: 48.947383, lng: 18.835976 },
    { lat: 48.947489, lng: 18.836359 },
    { lat: 48.947628, lng: 18.836994 },
    { lat: 48.947416, lng: 18.837191 },
    { lat: 48.947569, lng: 18.837716 },
    { lat: 48.94778, lng: 18.838161 },
    { lat: 48.94786, lng: 18.838526 },
    { lat: 48.947861, lng: 18.838877 },
    { lat: 48.947774, lng: 18.839198 },
    { lat: 48.94762, lng: 18.839538 },
    { lat: 48.947628, lng: 18.839729 },
    { lat: 48.947422, lng: 18.840168 },
    { lat: 48.947151, lng: 18.840468 },
    { lat: 48.947067, lng: 18.840745 },
    { lat: 48.946781, lng: 18.841258 },
    { lat: 48.946521, lng: 18.841681 },
    { lat: 48.945865, lng: 18.84137 },
    { lat: 48.945617, lng: 18.841426 },
    { lat: 48.945387, lng: 18.841766 },
    { lat: 48.945521, lng: 18.842415 },
    { lat: 48.94566, lng: 18.843207 },
    { lat: 48.945935, lng: 18.843483 },
    { lat: 48.946198, lng: 18.84297 },
    { lat: 48.946594, lng: 18.842566 },
    { lat: 48.94674, lng: 18.842638 },
    { lat: 48.94699, lng: 18.842826 },
    { lat: 48.947093, lng: 18.843104 },
    { lat: 48.947128, lng: 18.843946 },
    { lat: 48.947057, lng: 18.844203 },
    { lat: 48.94681, lng: 18.844374 },
    { lat: 48.946468, lng: 18.844417 },
    { lat: 48.946457, lng: 18.84443 },
    { lat: 48.946398, lng: 18.844497 },
    { lat: 48.94639, lng: 18.844529 },
    { lat: 48.946372, lng: 18.844603 },
    { lat: 48.946012, lng: 18.845089 },
    { lat: 48.945879, lng: 18.845822 },
    { lat: 48.945894, lng: 18.846549 },
    { lat: 48.946148, lng: 18.846624 },
    { lat: 48.94635, lng: 18.84662 },
    { lat: 48.946935, lng: 18.845593 },
    { lat: 48.947025, lng: 18.845564 },
    { lat: 48.94716, lng: 18.845538 },
    { lat: 48.947274, lng: 18.845595 },
    { lat: 48.947373, lng: 18.845714 },
    { lat: 48.947456, lng: 18.845938 },
    { lat: 48.947571, lng: 18.846641 },
    { lat: 48.947717, lng: 18.846792 },
    { lat: 48.947933, lng: 18.846729 },
    { lat: 48.948231, lng: 18.846661 },
    { lat: 48.948333, lng: 18.846694 },
    { lat: 48.948497, lng: 18.846847 },
    { lat: 48.948865, lng: 18.847744 },
    { lat: 48.949381, lng: 18.848035 },
    { lat: 48.949517, lng: 18.848563 },
    { lat: 48.949598, lng: 18.849034 },
    { lat: 48.949595, lng: 18.849756 },
    { lat: 48.949489, lng: 18.85027 },
    { lat: 48.949394, lng: 18.850481 },
    { lat: 48.949236, lng: 18.850849 },
    { lat: 48.94868, lng: 18.851328 },
  ],
  Valča: [
    { lat: 49.05267, lng: 18.737243 },
    { lat: 49.052119, lng: 18.737319 },
    { lat: 49.052026, lng: 18.737288 },
    { lat: 49.05197, lng: 18.73727 },
    { lat: 49.050751, lng: 18.736871 },
    { lat: 49.050379, lng: 18.736715 },
    { lat: 49.050093, lng: 18.736696 },
    { lat: 49.049687, lng: 18.736921 },
    { lat: 49.048863, lng: 18.737386 },
    { lat: 49.048613, lng: 18.737632 },
    { lat: 49.048298, lng: 18.737805 },
    { lat: 49.047425, lng: 18.738093 },
    { lat: 49.046876, lng: 18.738407 },
    { lat: 49.046703, lng: 18.738506 },
    { lat: 49.046467, lng: 18.738743 },
    { lat: 49.045953, lng: 18.739455 },
    { lat: 49.045544, lng: 18.739792 },
    { lat: 49.045303, lng: 18.740012 },
    { lat: 49.045033, lng: 18.740118 },
    { lat: 49.044589, lng: 18.740259 },
    { lat: 49.044165, lng: 18.74043 },
    { lat: 49.043928, lng: 18.74044 },
    { lat: 49.043478, lng: 18.740522 },
    { lat: 49.042792, lng: 18.74082 },
    { lat: 49.042215, lng: 18.741069 },
    { lat: 49.042101, lng: 18.741118 },
    { lat: 49.041953, lng: 18.741049 },
    { lat: 49.041502, lng: 18.740438 },
    { lat: 49.041174, lng: 18.740171 },
    { lat: 49.040939, lng: 18.739855 },
    { lat: 49.040434, lng: 18.739457 },
    { lat: 49.0404, lng: 18.739439 },
    { lat: 49.039904, lng: 18.739457 },
    { lat: 49.039413, lng: 18.739339 },
    { lat: 49.039215, lng: 18.739289 },
    { lat: 49.038438, lng: 18.739328 },
    { lat: 49.03828, lng: 18.739238 },
    { lat: 49.038169, lng: 18.739142 },
    { lat: 49.038055, lng: 18.738919 },
    { lat: 49.037679, lng: 18.738935 },
    { lat: 49.037263, lng: 18.739381 },
    { lat: 49.037137, lng: 18.739629 },
    { lat: 49.036707, lng: 18.740391 },
    { lat: 49.03646, lng: 18.741034 },
    { lat: 49.036069, lng: 18.741727 },
    { lat: 49.035818, lng: 18.741829 },
    { lat: 49.035699, lng: 18.741833 },
    { lat: 49.035461, lng: 18.741813 },
    { lat: 49.035315, lng: 18.741895 },
    { lat: 49.034871, lng: 18.741837 },
    { lat: 49.034693, lng: 18.741613 },
    { lat: 49.034487, lng: 18.741557 },
    { lat: 49.034301, lng: 18.741559 },
    { lat: 49.03407, lng: 18.741648 },
    { lat: 49.033901, lng: 18.741714 },
    { lat: 49.033627, lng: 18.741758 },
    { lat: 49.032904, lng: 18.74159 },
    { lat: 49.032882, lng: 18.741603 },
    { lat: 49.032265, lng: 18.7423 },
    { lat: 49.031684, lng: 18.743109 },
    { lat: 49.031391, lng: 18.743384 },
    { lat: 49.031334, lng: 18.743437 },
    { lat: 49.031086, lng: 18.74367 },
    { lat: 49.030745, lng: 18.743502 },
    { lat: 49.029506, lng: 18.742092 },
    { lat: 49.029367, lng: 18.741912 },
    { lat: 49.028746, lng: 18.741646 },
    { lat: 49.02824, lng: 18.741423 },
    { lat: 49.027677, lng: 18.741174 },
    { lat: 49.027309, lng: 18.740903 },
    { lat: 49.026755, lng: 18.740243 },
    { lat: 49.026701, lng: 18.740111 },
    { lat: 49.0260324, lng: 18.740629 },
    { lat: 49.025726, lng: 18.7409587 },
    { lat: 49.0254184, lng: 18.7412622 },
    { lat: 49.0246272, lng: 18.7419583 },
    { lat: 49.0242985, lng: 18.7421214 },
    { lat: 49.0240293, lng: 18.7422151 },
    { lat: 49.0238676, lng: 18.742335 },
    { lat: 49.0236086, lng: 18.7424925 },
    { lat: 49.0231554, lng: 18.7428356 },
    { lat: 49.0230185, lng: 18.7431088 },
    { lat: 49.0229135, lng: 18.7438143 },
    { lat: 49.0229719, lng: 18.7440281 },
    { lat: 49.0229805, lng: 18.7442775 },
    { lat: 49.0229414, lng: 18.7446707 },
    { lat: 49.0229305, lng: 18.7448517 },
    { lat: 49.0229355, lng: 18.7452668 },
    { lat: 49.0229752, lng: 18.7456816 },
    { lat: 49.023166, lng: 18.7465036 },
    { lat: 49.0232574, lng: 18.7471073 },
    { lat: 49.0233134, lng: 18.747789 },
    { lat: 49.0232861, lng: 18.7480661 },
    { lat: 49.0232245, lng: 18.7482417 },
    { lat: 49.0230185, lng: 18.7491768 },
    { lat: 49.0228854, lng: 18.7496273 },
    { lat: 49.0224553, lng: 18.7506736 },
    { lat: 49.022237, lng: 18.7510935 },
    { lat: 49.0221007, lng: 18.7513295 },
    { lat: 49.0219586, lng: 18.7515044 },
    { lat: 49.021903, lng: 18.7518637 },
    { lat: 49.0218286, lng: 18.7522072 },
    { lat: 49.0216429, lng: 18.7527542 },
    { lat: 49.0214035, lng: 18.7532862 },
    { lat: 49.0212266, lng: 18.7536299 },
    { lat: 49.0208081, lng: 18.7543621 },
    { lat: 49.0202297, lng: 18.7552021 },
    { lat: 49.0200703, lng: 18.7554984 },
    { lat: 49.0197964, lng: 18.7560158 },
    { lat: 49.0194819, lng: 18.7566679 },
    { lat: 49.0192283, lng: 18.7572723 },
    { lat: 49.0189542, lng: 18.7578771 },
    { lat: 49.0184682, lng: 18.7593187 },
    { lat: 49.0181708, lng: 18.7606865 },
    { lat: 49.0180475, lng: 18.7610431 },
    { lat: 49.0180322, lng: 18.7612482 },
    { lat: 49.0180043, lng: 18.7613605 },
    { lat: 49.0179516, lng: 18.7613877 },
    { lat: 49.0179147, lng: 18.7616088 },
    { lat: 49.0175204, lng: 18.7618774 },
    { lat: 49.0175397, lng: 18.7620518 },
    { lat: 49.0173652, lng: 18.7622938 },
    { lat: 49.0171158, lng: 18.7625902 },
    { lat: 49.0167361, lng: 18.7631778 },
    { lat: 49.0167262, lng: 18.7635311 },
    { lat: 49.0162187, lng: 18.7645225 },
    { lat: 49.0162061, lng: 18.764819 },
    { lat: 49.0160797, lng: 18.7649467 },
    { lat: 49.0159984, lng: 18.7651415 },
    { lat: 49.0159592, lng: 18.7655127 },
    { lat: 49.0157748, lng: 18.7664051 },
    { lat: 49.0151823, lng: 18.7681224 },
    { lat: 49.0150738, lng: 18.7684832 },
    { lat: 49.0150123, lng: 18.7690342 },
    { lat: 49.0142568, lng: 18.7703733 },
    { lat: 49.0140961, lng: 18.7712923 },
    { lat: 49.0131467, lng: 18.7733668 },
    { lat: 49.0129433, lng: 18.7738781 },
    { lat: 49.0128688, lng: 18.774072 },
    { lat: 49.0127313, lng: 18.7745474 },
    { lat: 49.012696, lng: 18.774691 },
    { lat: 49.0126375, lng: 18.7750192 },
    { lat: 49.012505, lng: 18.7763061 },
    { lat: 49.0123296, lng: 18.7770701 },
    { lat: 49.0121702, lng: 18.7770412 },
    { lat: 49.0120062, lng: 18.7775308 },
    { lat: 49.0119676, lng: 18.77863 },
    { lat: 49.0118883, lng: 18.7795509 },
    { lat: 49.0118476, lng: 18.7798536 },
    { lat: 49.011439, lng: 18.7794139 },
    { lat: 49.0114574, lng: 18.7791179 },
    { lat: 49.0113946, lng: 18.7790787 },
    { lat: 49.0113231, lng: 18.7794153 },
    { lat: 49.0112261, lng: 18.7795229 },
    { lat: 49.0113677, lng: 18.7800544 },
    { lat: 49.0114228, lng: 18.7803538 },
    { lat: 49.0115681, lng: 18.7803938 },
    { lat: 49.0116037, lng: 18.7806927 },
    { lat: 49.0113148, lng: 18.7809875 },
    { lat: 49.011236, lng: 18.7813814 },
    { lat: 49.01115, lng: 18.7816346 },
    { lat: 49.0111415, lng: 18.7819258 },
    { lat: 49.0110877, lng: 18.782085 },
    { lat: 49.011105, lng: 18.782223 },
    { lat: 49.0110584, lng: 18.7823658 },
    { lat: 49.0110764, lng: 18.782535 },
    { lat: 49.0109328, lng: 18.7827908 },
    { lat: 49.0109879, lng: 18.7829358 },
    { lat: 49.0109533, lng: 18.7831831 },
    { lat: 49.0107105, lng: 18.7831234 },
    { lat: 49.0102508, lng: 18.7830105 },
    { lat: 49.0100781, lng: 18.7831796 },
    { lat: 49.0099299, lng: 18.7831705 },
    { lat: 49.0097948, lng: 18.7835202 },
    { lat: 49.0095252, lng: 18.7834348 },
    { lat: 49.0094772, lng: 18.7848942 },
    { lat: 49.0093178, lng: 18.785926 },
    { lat: 49.0092323, lng: 18.7860117 },
    { lat: 49.0093317, lng: 18.7861481 },
    { lat: 49.0092673, lng: 18.7863401 },
    { lat: 49.009296, lng: 18.786651 },
    { lat: 49.0095115, lng: 18.7867404 },
    { lat: 49.0095549, lng: 18.7866848 },
    { lat: 49.009656, lng: 18.7868124 },
    { lat: 49.0094452, lng: 18.7875246 },
    { lat: 49.0093976, lng: 18.7879118 },
    { lat: 49.0092368, lng: 18.7880913 },
    { lat: 49.0083141, lng: 18.7877188 },
    { lat: 49.0078693, lng: 18.7876226 },
    { lat: 49.0075528, lng: 18.7871757 },
    { lat: 49.0070088, lng: 18.7866665 },
    { lat: 49.006801, lng: 18.7866197 },
    { lat: 49.0065293, lng: 18.7866376 },
    { lat: 49.0063082, lng: 18.7867041 },
    { lat: 49.0060016, lng: 18.7869001 },
    { lat: 49.0057889, lng: 18.7869943 },
    { lat: 49.005354, lng: 18.7871106 },
    { lat: 49.0049268, lng: 18.7870291 },
    { lat: 49.0047638, lng: 18.7876877 },
    { lat: 49.0046765, lng: 18.7877858 },
    { lat: 49.0044004, lng: 18.7881379 },
    { lat: 49.0017753, lng: 18.7911264 },
    { lat: 49.0016586, lng: 18.7912687 },
    { lat: 49.0016215, lng: 18.791573 },
    { lat: 49.0014875, lng: 18.7920086 },
    { lat: 49.0014259, lng: 18.7922764 },
    { lat: 49.0013265, lng: 18.7930095 },
    { lat: 49.0013367, lng: 18.7933785 },
    { lat: 49.0013213, lng: 18.7936465 },
    { lat: 49.0013775, lng: 18.7944266 },
    { lat: 49.0016035, lng: 18.7957775 },
    { lat: 49.0017961, lng: 18.7967043 },
    { lat: 49.0019604, lng: 18.797266 },
    { lat: 49.0020786, lng: 18.7977808 },
    { lat: 49.0021933, lng: 18.7994456 },
    { lat: 49.0022166, lng: 18.8004585 },
    { lat: 49.0021073, lng: 18.801716 },
    { lat: 49.0019818, lng: 18.8024461 },
    { lat: 49.0021108, lng: 18.8026339 },
    { lat: 49.0020507, lng: 18.8031687 },
    { lat: 49.0021437, lng: 18.8038938 },
    { lat: 49.0021891, lng: 18.804463 },
    { lat: 49.0021663, lng: 18.8050895 },
    { lat: 49.0020338, lng: 18.8055547 },
    { lat: 49.0018206, lng: 18.8064681 },
    { lat: 49.001722, lng: 18.807154 },
    { lat: 49.001587, lng: 18.807572 },
    { lat: 49.0016043, lng: 18.8080376 },
    { lat: 49.0016797, lng: 18.8087708 },
    { lat: 49.0009187, lng: 18.8091411 },
    { lat: 49.000829, lng: 18.809066 },
    { lat: 49.0007965, lng: 18.8090685 },
    { lat: 49.0007912, lng: 18.8095755 },
    { lat: 49.00083, lng: 18.8101049 },
    { lat: 49.0008877, lng: 18.8106524 },
    { lat: 49.000574, lng: 18.8093882 },
    { lat: 49.0008781, lng: 18.8066655 },
    { lat: 49.0007016, lng: 18.8068296 },
    { lat: 48.9997123, lng: 18.8071256 },
    { lat: 48.9994262, lng: 18.8079137 },
    { lat: 48.9989145, lng: 18.8093275 },
    { lat: 48.9981898, lng: 18.809401 },
    { lat: 48.9974536, lng: 18.8105993 },
    { lat: 48.9973038, lng: 18.8109741 },
    { lat: 48.9966845, lng: 18.8116434 },
    { lat: 48.9960328, lng: 18.8123395 },
    { lat: 48.9955539, lng: 18.8128644 },
    { lat: 48.9952384, lng: 18.8131327 },
    { lat: 48.995067, lng: 18.8133916 },
    { lat: 48.9947587, lng: 18.8141029 },
    { lat: 48.9941145, lng: 18.8150314 },
    { lat: 48.9939014, lng: 18.8158343 },
    { lat: 48.993527, lng: 18.816356 },
    { lat: 48.992857, lng: 18.8170685 },
    { lat: 48.9925096, lng: 18.8178746 },
    { lat: 48.9923336, lng: 18.8189829 },
    { lat: 48.9919362, lng: 18.8196049 },
    { lat: 48.9913741, lng: 18.8217493 },
    { lat: 48.9912177, lng: 18.8226488 },
    { lat: 48.9912114, lng: 18.8235953 },
    { lat: 48.9913737, lng: 18.8252688 },
    { lat: 48.9912214, lng: 18.8259656 },
    { lat: 48.9911878, lng: 18.8266436 },
    { lat: 48.9908177, lng: 18.8278802 },
    { lat: 48.990761, lng: 18.8286614 },
    { lat: 48.9905442, lng: 18.8301803 },
    { lat: 48.9904703, lng: 18.8309086 },
    { lat: 48.9904633, lng: 18.831709 },
    { lat: 48.9903813, lng: 18.832568 },
    { lat: 48.99026, lng: 18.8332287 },
    { lat: 48.9900613, lng: 18.8340987 },
    { lat: 48.9898964, lng: 18.8349219 },
    { lat: 48.9894846, lng: 18.8358364 },
    { lat: 48.9891633, lng: 18.8367522 },
    { lat: 48.9892315, lng: 18.8370707 },
    { lat: 48.9892525, lng: 18.8376064 },
    { lat: 48.9894134, lng: 18.8380353 },
    { lat: 48.9895568, lng: 18.838653 },
    { lat: 48.9897326, lng: 18.8391968 },
    { lat: 48.9898625, lng: 18.839484 },
    { lat: 48.9895186, lng: 18.8416006 },
    { lat: 48.9898823, lng: 18.8417676 },
    { lat: 48.9896958, lng: 18.8437491 },
    { lat: 48.9896159, lng: 18.8447546 },
    { lat: 48.9899964, lng: 18.8448532 },
    { lat: 48.9899241, lng: 18.84533 },
    { lat: 48.9907247, lng: 18.8455923 },
    { lat: 48.9910473, lng: 18.8457258 },
    { lat: 48.9915521, lng: 18.8459609 },
    { lat: 48.9922671, lng: 18.8463145 },
    { lat: 48.9923103, lng: 18.8463183 },
    { lat: 48.9921928, lng: 18.8471685 },
    { lat: 48.9919229, lng: 18.8482947 },
    { lat: 48.9916632, lng: 18.8489676 },
    { lat: 48.9912821, lng: 18.850587 },
    { lat: 48.9912246, lng: 18.8509382 },
    { lat: 48.9912136, lng: 18.8511373 },
    { lat: 48.9911172, lng: 18.8520457 },
    { lat: 48.991119, lng: 18.8526533 },
    { lat: 48.9908483, lng: 18.8543339 },
    { lat: 48.990779, lng: 18.8549615 },
    { lat: 48.9907819, lng: 18.8556957 },
    { lat: 48.9908521, lng: 18.8572111 },
    { lat: 48.9908402, lng: 18.858229 },
    { lat: 48.9909631, lng: 18.8589501 },
    { lat: 48.9909912, lng: 18.8590185 },
    { lat: 48.9909789, lng: 18.8595226 },
    { lat: 48.9909904, lng: 18.8597008 },
    { lat: 48.9909762, lng: 18.8599057 },
    { lat: 48.9910157, lng: 18.8601696 },
    { lat: 48.9911616, lng: 18.8603318 },
    { lat: 48.99136, lng: 18.8604859 },
    { lat: 48.9915753, lng: 18.8607914 },
    { lat: 48.9925781, lng: 18.8616776 },
    { lat: 48.9931327, lng: 18.862528 },
    { lat: 48.9939782, lng: 18.8637949 },
    { lat: 48.9944625, lng: 18.8647951 },
    { lat: 48.9947349, lng: 18.8651341 },
    { lat: 48.9948354, lng: 18.8652044 },
    { lat: 48.9949486, lng: 18.8654351 },
    { lat: 48.9951277, lng: 18.8657154 },
    { lat: 48.9951655, lng: 18.8659217 },
    { lat: 48.9960462, lng: 18.8667605 },
    { lat: 48.9969586, lng: 18.8675725 },
    { lat: 48.9976773, lng: 18.8681102 },
    { lat: 48.9981397, lng: 18.8684177 },
    { lat: 48.9984326, lng: 18.8685448 },
    { lat: 48.998785, lng: 18.8686675 },
    { lat: 48.9994753, lng: 18.8688028 },
    { lat: 49.0003387, lng: 18.8689517 },
    { lat: 49.0011038, lng: 18.86914 },
    { lat: 49.0019549, lng: 18.8694455 },
    { lat: 49.0022402, lng: 18.8695761 },
    { lat: 49.0032595, lng: 18.8701688 },
    { lat: 49.0040401, lng: 18.8706554 },
    { lat: 49.004217, lng: 18.8707422 },
    { lat: 49.0046399, lng: 18.8707883 },
    { lat: 49.0053949, lng: 18.8710884 },
    { lat: 49.0056214, lng: 18.8712396 },
    { lat: 49.0057923, lng: 18.8713372 },
    { lat: 49.0060057, lng: 18.871411 },
    { lat: 49.0059923, lng: 18.8713827 },
    { lat: 49.0060469, lng: 18.8708519 },
    { lat: 49.0062025, lng: 18.870238 },
    { lat: 49.0063999, lng: 18.8694489 },
    { lat: 49.0065551, lng: 18.8686073 },
    { lat: 49.0066159, lng: 18.8678807 },
    { lat: 49.0067349, lng: 18.8675617 },
    { lat: 49.006641, lng: 18.8672295 },
    { lat: 49.0067693, lng: 18.8666087 },
    { lat: 49.0068679, lng: 18.8662235 },
    { lat: 49.0069094, lng: 18.8656299 },
    { lat: 49.0070499, lng: 18.8651991 },
    { lat: 49.007141, lng: 18.8649355 },
    { lat: 49.0071731, lng: 18.8648573 },
    { lat: 49.007112, lng: 18.8646117 },
    { lat: 49.0071553, lng: 18.8645983 },
    { lat: 49.0072352, lng: 18.8644836 },
    { lat: 49.0072057, lng: 18.8643287 },
    { lat: 49.0072813, lng: 18.8641761 },
    { lat: 49.0071848, lng: 18.8640561 },
    { lat: 49.0072717, lng: 18.8639211 },
    { lat: 49.0073061, lng: 18.8634965 },
    { lat: 49.0073017, lng: 18.8631669 },
    { lat: 49.0072622, lng: 18.8628624 },
    { lat: 49.0070301, lng: 18.8608679 },
    { lat: 49.0069842, lng: 18.8601317 },
    { lat: 49.0069964, lng: 18.8596892 },
    { lat: 49.0069597, lng: 18.8592831 },
    { lat: 49.0069357, lng: 18.8588158 },
    { lat: 49.0069625, lng: 18.8582408 },
    { lat: 49.0069933, lng: 18.8577412 },
    { lat: 49.0070527, lng: 18.8572967 },
    { lat: 49.0070835, lng: 18.8568904 },
    { lat: 49.0071076, lng: 18.8566789 },
    { lat: 49.0069058, lng: 18.8513558 },
    { lat: 49.0071603, lng: 18.84984 },
    { lat: 49.0073926, lng: 18.8491834 },
    { lat: 49.0075986, lng: 18.848785 },
    { lat: 49.00798, lng: 18.8479369 },
    { lat: 49.0081707, lng: 18.8475431 },
    { lat: 49.0084353, lng: 18.8469704 },
    { lat: 49.0088966, lng: 18.8460961 },
    { lat: 49.0091061, lng: 18.8457644 },
    { lat: 49.0094791, lng: 18.8453005 },
    { lat: 49.0098558, lng: 18.844867 },
    { lat: 49.0100684, lng: 18.8445273 },
    { lat: 49.0107148, lng: 18.84377 },
    { lat: 49.0110749, lng: 18.8434254 },
    { lat: 49.0124325, lng: 18.8420505 },
    { lat: 49.0130084, lng: 18.8414341 },
    { lat: 49.0135347, lng: 18.8407725 },
    { lat: 49.0140129, lng: 18.8400517 },
    { lat: 49.0141968, lng: 18.8396831 },
    { lat: 49.0143547, lng: 18.8391631 },
    { lat: 49.0146206, lng: 18.8389173 },
    { lat: 49.0147386, lng: 18.8387535 },
    { lat: 49.0149554, lng: 18.8383673 },
    { lat: 49.0151205, lng: 18.8380038 },
    { lat: 49.0152484, lng: 18.8377084 },
    { lat: 49.0155873, lng: 18.8369592 },
    { lat: 49.0159633, lng: 18.8363338 },
    { lat: 49.0162713, lng: 18.8357057 },
    { lat: 49.016569, lng: 18.8352467 },
    { lat: 49.0166647, lng: 18.8351325 },
    { lat: 49.0167778, lng: 18.8350299 },
    { lat: 49.0171084, lng: 18.8346887 },
    { lat: 49.017191, lng: 18.8345635 },
    { lat: 49.0175055, lng: 18.8341473 },
    { lat: 49.0176, lng: 18.8340728 },
    { lat: 49.0180264, lng: 18.8339246 },
    { lat: 49.0181194, lng: 18.8339149 },
    { lat: 49.018391, lng: 18.8340445 },
    { lat: 49.0188119, lng: 18.8332646 },
    { lat: 49.0191681, lng: 18.8325621 },
    { lat: 49.0193807, lng: 18.8320485 },
    { lat: 49.0195091, lng: 18.8314145 },
    { lat: 49.0197121, lng: 18.8308719 },
    { lat: 49.0200996, lng: 18.8299659 },
    { lat: 49.0203162, lng: 18.8295531 },
    { lat: 49.0204172, lng: 18.8294135 },
    { lat: 49.0209339, lng: 18.8288099 },
    { lat: 49.0210835, lng: 18.828431 },
    { lat: 49.0212386, lng: 18.8281401 },
    { lat: 49.0215517, lng: 18.8277168 },
    { lat: 49.0216688, lng: 18.8276044 },
    { lat: 49.0220746, lng: 18.8271456 },
    { lat: 49.0225304, lng: 18.8264958 },
    { lat: 49.0228438, lng: 18.8257067 },
    { lat: 49.0229153, lng: 18.8255638 },
    { lat: 49.0233321, lng: 18.8250792 },
    { lat: 49.0237782, lng: 18.8246554 },
    { lat: 49.0243325, lng: 18.8242981 },
    { lat: 49.0244389, lng: 18.8241524 },
    { lat: 49.0243362, lng: 18.8233872 },
    { lat: 49.0243462, lng: 18.8227095 },
    { lat: 49.0245731, lng: 18.8215358 },
    { lat: 49.024816, lng: 18.8208291 },
    { lat: 49.0249149, lng: 18.8202111 },
    { lat: 49.025133, lng: 18.8196141 },
    { lat: 49.0255792, lng: 18.8186598 },
    { lat: 49.0258233, lng: 18.8181377 },
    { lat: 49.025867, lng: 18.8178487 },
    { lat: 49.0259457, lng: 18.8175568 },
    { lat: 49.0259581, lng: 18.8175308 },
    { lat: 49.0260581, lng: 18.8173213 },
    { lat: 49.0260972, lng: 18.8172394 },
    { lat: 49.0262821, lng: 18.8164972 },
    { lat: 49.0262998, lng: 18.8164355 },
    { lat: 49.0263575, lng: 18.8162342 },
    { lat: 49.0264406, lng: 18.8159446 },
    { lat: 49.0265268, lng: 18.8155012 },
    { lat: 49.0265697, lng: 18.8152806 },
    { lat: 49.0266125, lng: 18.8149992 },
    { lat: 49.0266582, lng: 18.8141382 },
    { lat: 49.0267451, lng: 18.8133991 },
    { lat: 49.0267779, lng: 18.8132182 },
    { lat: 49.0268195, lng: 18.8129884 },
    { lat: 49.0269967, lng: 18.8125321 },
    { lat: 49.0270349, lng: 18.8121613 },
    { lat: 49.0270125, lng: 18.8117526 },
    { lat: 49.0269521, lng: 18.8112604 },
    { lat: 49.0267255, lng: 18.8105003 },
    { lat: 49.0266406, lng: 18.8094502 },
    { lat: 49.026698, lng: 18.8090365 },
    { lat: 49.026703, lng: 18.8090007 },
    { lat: 49.026682, lng: 18.8086729 },
    { lat: 49.0266559, lng: 18.8082658 },
    { lat: 49.0267488, lng: 18.8073823 },
    { lat: 49.0268065, lng: 18.8071271 },
    { lat: 49.0269028, lng: 18.8068536 },
    { lat: 49.0268137, lng: 18.8066336 },
    { lat: 49.0269658, lng: 18.8062862 },
    { lat: 49.0271738, lng: 18.8060543 },
    { lat: 49.0273088, lng: 18.8060711 },
    { lat: 49.02759, lng: 18.8057517 },
    { lat: 49.0279351, lng: 18.8052536 },
    { lat: 49.0283757, lng: 18.8048605 },
    { lat: 49.0286878, lng: 18.8044637 },
    { lat: 49.0287565, lng: 18.8039847 },
    { lat: 49.0288287, lng: 18.8032448 },
    { lat: 49.0291266, lng: 18.802729 },
    { lat: 49.0295015, lng: 18.8033236 },
    { lat: 49.0302496, lng: 18.8047028 },
    { lat: 49.0311407, lng: 18.8065535 },
    { lat: 49.0312587, lng: 18.8066558 },
    { lat: 49.0319177, lng: 18.8055342 },
    { lat: 49.0326315, lng: 18.8045786 },
    { lat: 49.0335231, lng: 18.8035318 },
    { lat: 49.0341283, lng: 18.8027638 },
    { lat: 49.0344172, lng: 18.8023575 },
    { lat: 49.0352017, lng: 18.8014162 },
    { lat: 49.035869, lng: 18.8007381 },
    { lat: 49.0364046, lng: 18.8003047 },
    { lat: 49.0366986, lng: 18.8000609 },
    { lat: 49.0368402, lng: 18.8000069 },
    { lat: 49.0370128, lng: 18.8000275 },
    { lat: 49.0371683, lng: 18.8000688 },
    { lat: 49.0373551, lng: 18.8001481 },
    { lat: 49.0378509, lng: 18.8000583 },
    { lat: 49.0380392, lng: 18.7997762 },
    { lat: 49.0381557, lng: 18.7992573 },
    { lat: 49.0382759, lng: 18.7981233 },
    { lat: 49.0384862, lng: 18.7973091 },
    { lat: 49.03861, lng: 18.7966728 },
    { lat: 49.0387204, lng: 18.795696 },
    { lat: 49.0387687, lng: 18.7943018 },
    { lat: 49.0388138, lng: 18.7937602 },
    { lat: 49.0388817, lng: 18.7933523 },
    { lat: 49.0391562, lng: 18.7919748 },
    { lat: 49.0393171, lng: 18.7913379 },
    { lat: 49.0393541, lng: 18.7909903 },
    { lat: 49.0393336, lng: 18.7904414 },
    { lat: 49.0394233, lng: 18.7900234 },
    { lat: 49.0398776, lng: 18.7891298 },
    { lat: 49.0400546, lng: 18.7890212 },
    { lat: 49.0410915, lng: 18.7887091 },
    { lat: 49.0413828, lng: 18.7886496 },
    { lat: 49.0417863, lng: 18.7885795 },
    { lat: 49.0422445, lng: 18.7883083 },
    { lat: 49.0425389, lng: 18.7882445 },
    { lat: 49.0431087, lng: 18.7882057 },
    { lat: 49.0442163, lng: 18.787975 },
    { lat: 49.0446376, lng: 18.7879436 },
    { lat: 49.0454528, lng: 18.7878344 },
    { lat: 49.0462742, lng: 18.7873444 },
    { lat: 49.0469596, lng: 18.7869753 },
    { lat: 49.0478984, lng: 18.7867882 },
    { lat: 49.0483798, lng: 18.7861181 },
    { lat: 49.0490499, lng: 18.7852551 },
    { lat: 49.0493276, lng: 18.7849154 },
    { lat: 49.0495099, lng: 18.7844725 },
    { lat: 49.050024, lng: 18.7834331 },
    { lat: 49.0501022, lng: 18.7831149 },
    { lat: 49.0501742, lng: 18.7829415 },
    { lat: 49.0503713, lng: 18.7825472 },
    { lat: 49.0515665, lng: 18.7822385 },
    { lat: 49.0519996, lng: 18.7817207 },
    { lat: 49.0524373, lng: 18.7813771 },
    { lat: 49.0533989, lng: 18.7804009 },
    { lat: 49.0537658, lng: 18.7799313 },
    { lat: 49.0544026, lng: 18.7789525 },
    { lat: 49.0546789, lng: 18.7784086 },
    { lat: 49.0547078, lng: 18.7783609 },
    { lat: 49.0547623, lng: 18.7782127 },
    { lat: 49.0551502, lng: 18.7771546 },
    { lat: 49.0553063, lng: 18.7768819 },
    { lat: 49.0557135, lng: 18.7763427 },
    { lat: 49.0559378, lng: 18.7760399 },
    { lat: 49.0566789, lng: 18.7753522 },
    { lat: 49.0573732, lng: 18.7746108 },
    { lat: 49.0579148, lng: 18.7740993 },
    { lat: 49.0583041, lng: 18.773907 },
    { lat: 49.0589777, lng: 18.7735057 },
    { lat: 49.059328, lng: 18.7734127 },
    { lat: 49.060664, lng: 18.773081 },
    { lat: 49.06067, lng: 18.773048 },
    { lat: 49.060723, lng: 18.772747 },
    { lat: 49.06089, lng: 18.772196 },
    { lat: 49.061223, lng: 18.771171 },
    { lat: 49.061302, lng: 18.770618 },
    { lat: 49.061474, lng: 18.770199 },
    { lat: 49.061529, lng: 18.769429 },
    { lat: 49.061513, lng: 18.769108 },
    { lat: 49.061416, lng: 18.769049 },
    { lat: 49.061224, lng: 18.768883 },
    { lat: 49.061057, lng: 18.76844 },
    { lat: 49.060543, lng: 18.767061 },
    { lat: 49.060376, lng: 18.766431 },
    { lat: 49.060048, lng: 18.766056 },
    { lat: 49.060015, lng: 18.765991 },
    { lat: 49.059719, lng: 18.765432 },
    { lat: 49.059047, lng: 18.764209 },
    { lat: 49.058926, lng: 18.763988 },
    { lat: 49.058462, lng: 18.763708 },
    { lat: 49.058434, lng: 18.763438 },
    { lat: 49.05843, lng: 18.763401 },
    { lat: 49.058307, lng: 18.762216 },
    { lat: 49.058222, lng: 18.761492 },
    { lat: 49.058076, lng: 18.760855 },
    { lat: 49.057776, lng: 18.760354 },
    { lat: 49.057719, lng: 18.760263 },
    { lat: 49.057512, lng: 18.759921 },
    { lat: 49.057287, lng: 18.759555 },
    { lat: 49.057353, lng: 18.759165 },
    { lat: 49.05776, lng: 18.758455 },
    { lat: 49.057845, lng: 18.7581 },
    { lat: 49.057975, lng: 18.757818 },
    { lat: 49.058052, lng: 18.757438 },
    { lat: 49.0581, lng: 18.756901 },
    { lat: 49.058309, lng: 18.756297 },
    { lat: 49.058208, lng: 18.756142 },
    { lat: 49.057847, lng: 18.755754 },
    { lat: 49.057582, lng: 18.755587 },
    { lat: 49.05704, lng: 18.755368 },
    { lat: 49.056519, lng: 18.754856 },
    { lat: 49.056025, lng: 18.754379 },
    { lat: 49.055537, lng: 18.753853 },
    { lat: 49.055114, lng: 18.753534 },
    { lat: 49.054518, lng: 18.7533102 },
    { lat: 49.054411, lng: 18.75327 },
    { lat: 49.053927, lng: 18.752726 },
    { lat: 49.053368, lng: 18.751834 },
    { lat: 49.053224, lng: 18.751621 },
    { lat: 49.053176, lng: 18.751554 },
    { lat: 49.05322, lng: 18.751155 },
    { lat: 49.053187, lng: 18.750797 },
    { lat: 49.05338, lng: 18.750323 },
    { lat: 49.053403, lng: 18.750244 },
    { lat: 49.053432, lng: 18.749945 },
    { lat: 49.053506, lng: 18.749314 },
    { lat: 49.053471, lng: 18.748508 },
    { lat: 49.053471, lng: 18.748507 },
    { lat: 49.053477, lng: 18.747757 },
    { lat: 49.053393, lng: 18.74729 },
    { lat: 49.053234, lng: 18.746853 },
    { lat: 49.052978, lng: 18.746287 },
    { lat: 49.052691, lng: 18.745867 },
    { lat: 49.052558, lng: 18.745561 },
    { lat: 49.052573, lng: 18.744559 },
    { lat: 49.052723, lng: 18.744062 },
    { lat: 49.052966, lng: 18.743354 },
    { lat: 49.053189, lng: 18.742453 },
    { lat: 49.053209, lng: 18.741698 },
    { lat: 49.053136, lng: 18.740942 },
    { lat: 49.053051, lng: 18.740608 },
    { lat: 49.052983, lng: 18.740336 },
    { lat: 49.052616, lng: 18.739598 },
    { lat: 49.052536, lng: 18.739224 },
    { lat: 49.052572, lng: 18.738103 },
    { lat: 49.052585, lng: 18.738004 },
    { lat: 49.052629, lng: 18.737656 },
    { lat: 49.05267, lng: 18.737243 },
  ],
  Vrícko: [
    { lat: 48.964796, lng: 18.633357 },
    { lat: 48.963701, lng: 18.635207 },
    { lat: 48.962792, lng: 18.637108 },
    { lat: 48.961855, lng: 18.638313 },
    { lat: 48.961189, lng: 18.640114 },
    { lat: 48.960316, lng: 18.642777 },
    { lat: 48.959731, lng: 18.646514 },
    { lat: 48.9593794, lng: 18.6478811 },
    { lat: 48.959338, lng: 18.648042 },
    { lat: 48.959053, lng: 18.648787 },
    { lat: 48.958908, lng: 18.649281 },
    { lat: 48.958783, lng: 18.651927 },
    { lat: 48.958573, lng: 18.65298 },
    { lat: 48.95733, lng: 18.65725 },
    { lat: 48.95738, lng: 18.658114 },
    { lat: 48.957371, lng: 18.658356 },
    { lat: 48.957305, lng: 18.658561 },
    { lat: 48.957499, lng: 18.65949 },
    { lat: 48.957468, lng: 18.659843 },
    { lat: 48.957377, lng: 18.660334 },
    { lat: 48.956862, lng: 18.662281 },
    { lat: 48.956734, lng: 18.66368 },
    { lat: 48.956108, lng: 18.665351 },
    { lat: 48.957166, lng: 18.669936 },
    { lat: 48.957247, lng: 18.669871 },
    { lat: 48.957134, lng: 18.67079 },
    { lat: 48.95726, lng: 18.671208 },
    { lat: 48.957416, lng: 18.672269 },
    { lat: 48.957343, lng: 18.672846 },
    { lat: 48.956757, lng: 18.673713 },
    { lat: 48.95655, lng: 18.674738 },
    { lat: 48.956453, lng: 18.675367 },
    { lat: 48.956259, lng: 18.677843 },
    { lat: 48.956516, lng: 18.678743 },
    { lat: 48.957286, lng: 18.680875 },
    { lat: 48.957696, lng: 18.681551 },
    { lat: 48.958124, lng: 18.682636 },
    { lat: 48.958382, lng: 18.683178 },
    { lat: 48.958533, lng: 18.683497 },
    { lat: 48.958594, lng: 18.684096 },
    { lat: 48.95844, lng: 18.684933 },
    { lat: 48.958319, lng: 18.685592 },
    { lat: 48.958349, lng: 18.685858 },
    { lat: 48.958393, lng: 18.686258 },
    { lat: 48.959241, lng: 18.688349 },
    { lat: 48.959067, lng: 18.68906 },
    { lat: 48.958798, lng: 18.690582 },
    { lat: 48.958903, lng: 18.691079 },
    { lat: 48.959295, lng: 18.691957 },
    { lat: 48.959561, lng: 18.692393 },
    { lat: 48.959471, lng: 18.692565 },
    { lat: 48.959501, lng: 18.69262 },
    { lat: 48.959138, lng: 18.693171 },
    { lat: 48.959175, lng: 18.694557 },
    { lat: 48.958558, lng: 18.695069 },
    { lat: 48.958013, lng: 18.695521 },
    { lat: 48.957687, lng: 18.695852 },
    { lat: 48.957328, lng: 18.69618 },
    { lat: 48.957103, lng: 18.696239 },
    { lat: 48.957009, lng: 18.696332 },
    { lat: 48.956611, lng: 18.69798 },
    { lat: 48.956308, lng: 18.699157 },
    { lat: 48.95614, lng: 18.699264 },
    { lat: 48.9555794, lng: 18.6998817 },
    { lat: 48.955304, lng: 18.700185 },
    { lat: 48.955245, lng: 18.70025 },
    { lat: 48.953632, lng: 18.702028 },
    { lat: 48.953476, lng: 18.702157 },
    { lat: 48.95313, lng: 18.70222 },
    { lat: 48.952502, lng: 18.702283 },
    { lat: 48.952016, lng: 18.702691 },
    { lat: 48.951399, lng: 18.703054 },
    { lat: 48.950966, lng: 18.703025 },
    { lat: 48.950834, lng: 18.703898 },
    { lat: 48.950811, lng: 18.703964 },
    { lat: 48.950709, lng: 18.704258 },
    { lat: 48.95067, lng: 18.704378 },
    { lat: 48.949898, lng: 18.706802 },
    { lat: 48.949882, lng: 18.708294 },
    { lat: 48.949986, lng: 18.708808 },
    { lat: 48.949715, lng: 18.709088 },
    { lat: 48.949349, lng: 18.710112 },
    { lat: 48.94859, lng: 18.710375 },
    { lat: 48.948402, lng: 18.711014 },
    { lat: 48.948024, lng: 18.712225 },
    { lat: 48.94772, lng: 18.712707 },
    { lat: 48.94688, lng: 18.71346 },
    { lat: 48.946424, lng: 18.713495 },
    { lat: 48.945894, lng: 18.713963 },
    { lat: 48.94479, lng: 18.714022 },
    { lat: 48.944627, lng: 18.714788 },
    { lat: 48.94388, lng: 18.715592 },
    { lat: 48.943485, lng: 18.716564 },
    { lat: 48.943046, lng: 18.716885 },
    { lat: 48.942099, lng: 18.716503 },
    { lat: 48.940836, lng: 18.716722 },
    { lat: 48.94029, lng: 18.717007 },
    { lat: 48.937884, lng: 18.719936 },
    { lat: 48.937493, lng: 18.720277 },
    { lat: 48.93653, lng: 18.721148 },
    { lat: 48.937342, lng: 18.723167 },
    { lat: 48.937889, lng: 18.724552 },
    { lat: 48.938512, lng: 18.725577 },
    { lat: 48.939003, lng: 18.726239 },
    { lat: 48.939544, lng: 18.726638 },
    { lat: 48.940142, lng: 18.727164 },
    { lat: 48.940946, lng: 18.727101 },
    { lat: 48.941776, lng: 18.727152 },
    { lat: 48.941592, lng: 18.728504 },
    { lat: 48.94175, lng: 18.729142 },
    { lat: 48.941591, lng: 18.729845 },
    { lat: 48.941881, lng: 18.730996 },
    { lat: 48.942244, lng: 18.731474 },
    { lat: 48.94244, lng: 18.731652 },
    { lat: 48.944154, lng: 18.733341 },
    { lat: 48.945356, lng: 18.734544 },
    { lat: 48.945902, lng: 18.735182 },
    { lat: 48.94643, lng: 18.735999 },
    { lat: 48.946759, lng: 18.736756 },
    { lat: 48.947159, lng: 18.737921 },
    { lat: 48.949154, lng: 18.740551 },
    { lat: 48.949488, lng: 18.74091 },
    { lat: 48.949783, lng: 18.741087 },
    { lat: 48.950267, lng: 18.741539 },
    { lat: 48.9506831, lng: 18.7412626 },
    { lat: 48.9508035, lng: 18.7411702 },
    { lat: 48.9509548, lng: 18.740853 },
    { lat: 48.9511216, lng: 18.7398217 },
    { lat: 48.9515414, lng: 18.7383439 },
    { lat: 48.9524207, lng: 18.7380604 },
    { lat: 48.9537124, lng: 18.7379 },
    { lat: 48.9548664, lng: 18.736985 },
    { lat: 48.9564063, lng: 18.737553 },
    { lat: 48.9581211, lng: 18.7383166 },
    { lat: 48.9586797, lng: 18.7388719 },
    { lat: 48.9594138, lng: 18.7397031 },
    { lat: 48.9595777, lng: 18.7399073 },
    { lat: 48.9599534, lng: 18.7404149 },
    { lat: 48.9609161, lng: 18.7411617 },
    { lat: 48.9616521, lng: 18.7414971 },
    { lat: 48.9619212, lng: 18.7416594 },
    { lat: 48.9627409, lng: 18.7421081 },
    { lat: 48.9647885, lng: 18.7438448 },
    { lat: 48.9653626, lng: 18.7447888 },
    { lat: 48.9660849, lng: 18.7447591 },
    { lat: 48.966728, lng: 18.7436052 },
    { lat: 48.9674388, lng: 18.7410348 },
    { lat: 48.9679211, lng: 18.7410079 },
    { lat: 48.9680267, lng: 18.7407826 },
    { lat: 48.9681424, lng: 18.740344 },
    { lat: 48.9680316, lng: 18.7394803 },
    { lat: 48.9679333, lng: 18.7385536 },
    { lat: 48.9680009, lng: 18.7383164 },
    { lat: 48.9683019, lng: 18.7385707 },
    { lat: 48.9686, lng: 18.7387538 },
    { lat: 48.9688371, lng: 18.7389304 },
    { lat: 48.9691059, lng: 18.7392158 },
    { lat: 48.9693332, lng: 18.7380045 },
    { lat: 48.9694691, lng: 18.7373937 },
    { lat: 48.9699266, lng: 18.736765 },
    { lat: 48.9700461, lng: 18.7361206 },
    { lat: 48.9698839, lng: 18.7356054 },
    { lat: 48.9701558, lng: 18.7354824 },
    { lat: 48.9702701, lng: 18.7352517 },
    { lat: 48.9711435, lng: 18.7354365 },
    { lat: 48.9714064, lng: 18.7352675 },
    { lat: 48.9725451, lng: 18.7358829 },
    { lat: 48.9726907, lng: 18.7358433 },
    { lat: 48.973009, lng: 18.7361938 },
    { lat: 48.973026, lng: 18.7364495 },
    { lat: 48.9737263, lng: 18.7375897 },
    { lat: 48.9747166, lng: 18.7369272 },
    { lat: 48.9751743, lng: 18.7368193 },
    { lat: 48.9759713, lng: 18.7370461 },
    { lat: 48.9768094, lng: 18.7367525 },
    { lat: 48.9772798, lng: 18.7364954 },
    { lat: 48.9772063, lng: 18.7357835 },
    { lat: 48.9772584, lng: 18.7352728 },
    { lat: 48.977216, lng: 18.7344667 },
    { lat: 48.9765777, lng: 18.7327488 },
    { lat: 48.9768229, lng: 18.7322667 },
    { lat: 48.9770309, lng: 18.731674 },
    { lat: 48.9772075, lng: 18.7309787 },
    { lat: 48.9773461, lng: 18.7302489 },
    { lat: 48.9782439, lng: 18.7281529 },
    { lat: 48.9793804, lng: 18.7267347 },
    { lat: 48.9800872, lng: 18.7257193 },
    { lat: 48.9815401, lng: 18.7236511 },
    { lat: 48.9822082, lng: 18.7230386 },
    { lat: 48.9828755, lng: 18.7217264 },
    { lat: 48.9836616, lng: 18.721173 },
    { lat: 48.9848245, lng: 18.7208151 },
    { lat: 48.9860065, lng: 18.7189307 },
    { lat: 48.9873778, lng: 18.7195531 },
    { lat: 48.9883283, lng: 18.7197829 },
    { lat: 48.9907677, lng: 18.7206432 },
    { lat: 48.9907617, lng: 18.7192869 },
    { lat: 48.9907319, lng: 18.7186176 },
    { lat: 48.990513, lng: 18.7177978 },
    { lat: 48.9905017, lng: 18.7177554 },
    { lat: 48.9904289, lng: 18.7167603 },
    { lat: 48.9903494, lng: 18.71613 },
    { lat: 48.9906263, lng: 18.7152866 },
    { lat: 48.9907394, lng: 18.7143489 },
    { lat: 48.9902842, lng: 18.7139299 },
    { lat: 48.9906976, lng: 18.7134078 },
    { lat: 48.9923443, lng: 18.7122122 },
    { lat: 48.9931592, lng: 18.7119894 },
    { lat: 48.9937666, lng: 18.7118745 },
    { lat: 48.9940443, lng: 18.7117318 },
    { lat: 48.9952821, lng: 18.7113609 },
    { lat: 48.9961661, lng: 18.7117219 },
    { lat: 48.996506, lng: 18.711727 },
    { lat: 48.995839, lng: 18.711407 },
    { lat: 48.995228, lng: 18.710885 },
    { lat: 48.994641, lng: 18.710646 },
    { lat: 48.993954, lng: 18.710434 },
    { lat: 48.993942, lng: 18.710271 },
    { lat: 48.993744, lng: 18.709779 },
    { lat: 48.993616, lng: 18.709608 },
    { lat: 48.993246, lng: 18.707344 },
    { lat: 48.992995, lng: 18.706217 },
    { lat: 48.992198, lng: 18.705489 },
    { lat: 48.991995, lng: 18.704747 },
    { lat: 48.992042, lng: 18.703171 },
    { lat: 48.99222, lng: 18.702536 },
    { lat: 48.992514, lng: 18.70194 },
    { lat: 48.992679, lng: 18.701142 },
    { lat: 48.992661, lng: 18.700786 },
    { lat: 48.992502, lng: 18.700414 },
    { lat: 48.991898, lng: 18.699505 },
    { lat: 48.991821, lng: 18.699014 },
    { lat: 48.99182, lng: 18.698112 },
    { lat: 48.992159, lng: 18.695364 },
    { lat: 48.99233, lng: 18.694621 },
    { lat: 48.99264, lng: 18.693707 },
    { lat: 48.992843, lng: 18.69349 },
    { lat: 48.992474, lng: 18.692632 },
    { lat: 48.992387, lng: 18.692492 },
    { lat: 48.991667, lng: 18.691354 },
    { lat: 48.991292, lng: 18.690753 },
    { lat: 48.99093, lng: 18.689923 },
    { lat: 48.99081, lng: 18.688947 },
    { lat: 48.990735, lng: 18.687669 },
    { lat: 48.990939, lng: 18.686775 },
    { lat: 48.991346, lng: 18.686183 },
    { lat: 48.991808, lng: 18.68498 },
    { lat: 48.992217, lng: 18.683389 },
    { lat: 48.992488, lng: 18.68257 },
    { lat: 48.992765, lng: 18.681281 },
    { lat: 48.99282, lng: 18.680129 },
    { lat: 48.992899, lng: 18.680014 },
    { lat: 48.993118, lng: 18.679712 },
    { lat: 48.993668, lng: 18.679202 },
    { lat: 48.995089, lng: 18.678487 },
    { lat: 48.99538, lng: 18.678441 },
    { lat: 48.995978, lng: 18.677889 },
    { lat: 48.996122, lng: 18.67759 },
    { lat: 48.99623, lng: 18.676887 },
    { lat: 48.996228, lng: 18.676434 },
    { lat: 48.996199, lng: 18.676027 },
    { lat: 48.996245, lng: 18.675638 },
    { lat: 48.996673, lng: 18.675007 },
    { lat: 48.997024, lng: 18.674632 },
    { lat: 48.997253, lng: 18.674557 },
    { lat: 48.9974213, lng: 18.6743268 },
    { lat: 48.997704, lng: 18.67394 },
    { lat: 48.997836, lng: 18.673285 },
    { lat: 48.998147, lng: 18.669769 },
    { lat: 48.998132, lng: 18.668745 },
    { lat: 48.998076, lng: 18.66823 },
    { lat: 48.998042, lng: 18.667889 },
    { lat: 48.997893, lng: 18.667243 },
    { lat: 48.997833, lng: 18.66679 },
    { lat: 48.9977581, lng: 18.6665494 },
    { lat: 48.997406, lng: 18.665418 },
    { lat: 48.997253, lng: 18.66509 },
    { lat: 48.996382, lng: 18.663254 },
    { lat: 48.9962735, lng: 18.6630844 },
    { lat: 48.995616, lng: 18.662056 },
    { lat: 48.99482, lng: 18.661587 },
    { lat: 48.994428, lng: 18.661278 },
    { lat: 48.994016, lng: 18.660848 },
    { lat: 48.993576, lng: 18.660497 },
    { lat: 48.992918, lng: 18.659386 },
    { lat: 48.992757, lng: 18.659053 },
    { lat: 48.992145, lng: 18.658646 },
    { lat: 48.991889, lng: 18.658426 },
    { lat: 48.991286, lng: 18.65787 },
    { lat: 48.99094, lng: 18.657544 },
    { lat: 48.990159, lng: 18.656686 },
    { lat: 48.989548, lng: 18.655824 },
    { lat: 48.988835, lng: 18.654386 },
    { lat: 48.988499, lng: 18.653296 },
    { lat: 48.988407, lng: 18.652764 },
    { lat: 48.988338, lng: 18.6511 },
    { lat: 48.988322, lng: 18.65099 },
    { lat: 48.988183, lng: 18.650854 },
    { lat: 48.98748, lng: 18.650212 },
    { lat: 48.987193, lng: 18.650062 },
    { lat: 48.985924, lng: 18.648953 },
    { lat: 48.98556, lng: 18.648455 },
    { lat: 48.984722, lng: 18.646905 },
    { lat: 48.984282, lng: 18.646219 },
    { lat: 48.984202, lng: 18.64614 },
    { lat: 48.983439, lng: 18.645417 },
    { lat: 48.982522, lng: 18.644467 },
    { lat: 48.982044, lng: 18.643674 },
    { lat: 48.981713, lng: 18.64293 },
    { lat: 48.981723, lng: 18.64107 },
    { lat: 48.981034, lng: 18.641799 },
    { lat: 48.980326, lng: 18.642342 },
    { lat: 48.979994, lng: 18.642498 },
    { lat: 48.979812, lng: 18.642592 },
    { lat: 48.979384, lng: 18.643142 },
    { lat: 48.978948, lng: 18.64313 },
    { lat: 48.978355, lng: 18.642816 },
    { lat: 48.9775, lng: 18.642551 },
    { lat: 48.976118, lng: 18.642134 },
    { lat: 48.975598, lng: 18.64225 },
    { lat: 48.974981, lng: 18.642252 },
    { lat: 48.973913, lng: 18.642287 },
    { lat: 48.973633, lng: 18.642118 },
    { lat: 48.972569, lng: 18.64069 },
    { lat: 48.971975, lng: 18.640304 },
    { lat: 48.971877, lng: 18.640242 },
    { lat: 48.971065, lng: 18.639734 },
    { lat: 48.96972, lng: 18.638766 },
    { lat: 48.969341, lng: 18.638288 },
    { lat: 48.968983, lng: 18.638022 },
    { lat: 48.968235, lng: 18.637976 },
    { lat: 48.967764, lng: 18.637735 },
    { lat: 48.967285, lng: 18.637145 },
    { lat: 48.967176, lng: 18.636999 },
    { lat: 48.966183, lng: 18.635609 },
    { lat: 48.965882, lng: 18.635474 },
    { lat: 48.965441, lng: 18.634846 },
    { lat: 48.9652568, lng: 18.6344681 },
    { lat: 48.964954, lng: 18.633847 },
    { lat: 48.964796, lng: 18.633357 },
  ],
  Vrútky: [
    { lat: 49.151496, lng: 18.8883932 },
    { lat: 49.151211, lng: 18.888045 },
    { lat: 49.151187, lng: 18.887992 },
    { lat: 49.1511, lng: 18.887811 },
    { lat: 49.150956, lng: 18.887493 },
    { lat: 49.150928, lng: 18.887414 },
    { lat: 49.150909, lng: 18.887377 },
    { lat: 49.150817, lng: 18.887013 },
    { lat: 49.150762, lng: 18.88694 },
    { lat: 49.150708, lng: 18.88669 },
    { lat: 49.150678, lng: 18.886603 },
    { lat: 49.15064, lng: 18.886464 },
    { lat: 49.15058, lng: 18.886188 },
    { lat: 49.15054, lng: 18.885933 },
    { lat: 49.150533, lng: 18.885876 },
    { lat: 49.150466, lng: 18.885409 },
    { lat: 49.150417, lng: 18.885341 },
    { lat: 49.150359, lng: 18.885164 },
    { lat: 49.150255, lng: 18.884897 },
    { lat: 49.15013, lng: 18.884658 },
    { lat: 49.150029, lng: 18.884108 },
    { lat: 49.150029, lng: 18.883866 },
    { lat: 49.150087, lng: 18.883332 },
    { lat: 49.150275, lng: 18.882463 },
    { lat: 49.150347, lng: 18.882259 },
    { lat: 49.150355, lng: 18.881934 },
    { lat: 49.15034, lng: 18.88122 },
    { lat: 49.149677, lng: 18.880128 },
    { lat: 49.149326, lng: 18.879246 },
    { lat: 49.149183, lng: 18.878749 },
    { lat: 49.148929, lng: 18.876731 },
    { lat: 49.148836, lng: 18.875976 },
    { lat: 49.148731, lng: 18.875247 },
    { lat: 49.148673, lng: 18.874893 },
    { lat: 49.148565, lng: 18.874245 },
    { lat: 49.148557, lng: 18.873167 },
    { lat: 49.148479, lng: 18.871892 },
    { lat: 49.148448, lng: 18.871312 },
    { lat: 49.148409, lng: 18.870705 },
    { lat: 49.148345, lng: 18.870253 },
    { lat: 49.1485, lng: 18.869718 },
    { lat: 49.148966, lng: 18.868566 },
    { lat: 49.149336, lng: 18.867859 },
    { lat: 49.149609, lng: 18.867316 },
    { lat: 49.149846, lng: 18.866919 },
    { lat: 49.149959, lng: 18.866606 },
    { lat: 49.15011, lng: 18.86626 },
    { lat: 49.150298, lng: 18.865802 },
    { lat: 49.150375, lng: 18.865686 },
    { lat: 49.150398, lng: 18.865223 },
    { lat: 49.150549, lng: 18.864675 },
    { lat: 49.150471, lng: 18.864292 },
    { lat: 49.150397, lng: 18.863926 },
    { lat: 49.150344, lng: 18.863667 },
    { lat: 49.150247, lng: 18.86319 },
    { lat: 49.150206, lng: 18.862645 },
    { lat: 49.150217, lng: 18.862429 },
    { lat: 49.150234, lng: 18.862133 },
    { lat: 49.150167, lng: 18.861471 },
    { lat: 49.14999, lng: 18.860587 },
    { lat: 49.149914, lng: 18.859957 },
    { lat: 49.149812, lng: 18.859016 },
    { lat: 49.14978, lng: 18.858597 },
    { lat: 49.149674, lng: 18.857869 },
    { lat: 49.149655, lng: 18.857733 },
    { lat: 49.149619, lng: 18.857265 },
    { lat: 49.149506, lng: 18.856833 },
    { lat: 49.149553, lng: 18.855862 },
    { lat: 49.149563, lng: 18.855669 },
    { lat: 49.149561, lng: 18.855586 },
    { lat: 49.149494, lng: 18.855299 },
    { lat: 49.149461, lng: 18.855231 },
    { lat: 49.149261, lng: 18.854815 },
    { lat: 49.149155, lng: 18.854597 },
    { lat: 49.148937, lng: 18.85416 },
    { lat: 49.148664, lng: 18.853612 },
    { lat: 49.14827, lng: 18.852804 },
    { lat: 49.148255, lng: 18.852773 },
    { lat: 49.14807, lng: 18.852397 },
    { lat: 49.147836, lng: 18.851922 },
    { lat: 49.147306, lng: 18.850844 },
    { lat: 49.147131, lng: 18.850487 },
    { lat: 49.14676, lng: 18.84968 },
    { lat: 49.146369, lng: 18.848837 },
    { lat: 49.146119, lng: 18.848581 },
    { lat: 49.145675, lng: 18.848505 },
    { lat: 49.144882, lng: 18.848288 },
    { lat: 49.144213, lng: 18.8481 },
    { lat: 49.14358, lng: 18.847889 },
    { lat: 49.14283, lng: 18.847652 },
    { lat: 49.14257, lng: 18.847466 },
    { lat: 49.142046, lng: 18.847509 },
    { lat: 49.14144, lng: 18.847716 },
    { lat: 49.141139, lng: 18.847795 },
    { lat: 49.140613, lng: 18.847916 },
    { lat: 49.140373, lng: 18.848087 },
    { lat: 49.14006, lng: 18.847897 },
    { lat: 49.13954, lng: 18.847522 },
    { lat: 49.139269, lng: 18.846688 },
    { lat: 49.13879, lng: 18.846471 },
    { lat: 49.138788, lng: 18.846469 },
    { lat: 49.13878, lng: 18.846468 },
    { lat: 49.137896, lng: 18.846317 },
    { lat: 49.137388, lng: 18.8462 },
    { lat: 49.137005, lng: 18.846106 },
    { lat: 49.136002, lng: 18.846033 },
    { lat: 49.135252, lng: 18.845976 },
    { lat: 49.134597, lng: 18.84592 },
    { lat: 49.134338, lng: 18.845711 },
    { lat: 49.134072, lng: 18.845294 },
    { lat: 49.133852, lng: 18.844919 },
    { lat: 49.133299, lng: 18.843974 },
    { lat: 49.13327, lng: 18.843883 },
    { lat: 49.133074, lng: 18.843293 },
    { lat: 49.133031, lng: 18.843269 },
    { lat: 49.132179, lng: 18.842805 },
    { lat: 49.132167, lng: 18.842774 },
    { lat: 49.131984, lng: 18.842307 },
    { lat: 49.131577, lng: 18.841885 },
    { lat: 49.13073, lng: 18.839596 },
    { lat: 49.130628, lng: 18.839248 },
    { lat: 49.130314, lng: 18.838181 },
    { lat: 49.129874, lng: 18.836706 },
    { lat: 49.129843, lng: 18.836675 },
    { lat: 49.129496, lng: 18.836335 },
    { lat: 49.128573, lng: 18.835936 },
    { lat: 49.127845, lng: 18.835631 },
    { lat: 49.127297, lng: 18.835555 },
    { lat: 49.127108, lng: 18.835295 },
    { lat: 49.126939, lng: 18.835063 },
    { lat: 49.1270172, lng: 18.8368836 },
    { lat: 49.1269609, lng: 18.8379546 },
    { lat: 49.1268367, lng: 18.8390484 },
    { lat: 49.1264065, lng: 18.8407243 },
    { lat: 49.1261095, lng: 18.8413721 },
    { lat: 49.1261031, lng: 18.8418813 },
    { lat: 49.1260079, lng: 18.8423304 },
    { lat: 49.1259238, lng: 18.8428222 },
    { lat: 49.1257565, lng: 18.8435154 },
    { lat: 49.1256112, lng: 18.8441991 },
    { lat: 49.1255453, lng: 18.8444766 },
    { lat: 49.1254497, lng: 18.844937 },
    { lat: 49.1253258, lng: 18.8454673 },
    { lat: 49.1250972, lng: 18.845976 },
    { lat: 49.1247006, lng: 18.8467073 },
    { lat: 49.1243446, lng: 18.8475444 },
    { lat: 49.1242644, lng: 18.8480025 },
    { lat: 49.1241176, lng: 18.8492469 },
    { lat: 49.1239424, lng: 18.8498327 },
    { lat: 49.1239201, lng: 18.8503869 },
    { lat: 49.1237031, lng: 18.8511655 },
    { lat: 49.1234381, lng: 18.8516687 },
    { lat: 49.1231097, lng: 18.8528703 },
    { lat: 49.1231248, lng: 18.853384 },
    { lat: 49.1231888, lng: 18.8543214 },
    { lat: 49.1230504, lng: 18.8551414 },
    { lat: 49.1228028, lng: 18.8562235 },
    { lat: 49.1227682, lng: 18.8562993 },
    { lat: 49.1224826, lng: 18.8575652 },
    { lat: 49.1222234, lng: 18.8591037 },
    { lat: 49.1219688, lng: 18.8601405 },
    { lat: 49.1217933, lng: 18.8605894 },
    { lat: 49.121643, lng: 18.8611084 },
    { lat: 49.1214767, lng: 18.8618464 },
    { lat: 49.1212919, lng: 18.862638 },
    { lat: 49.1211088, lng: 18.8634439 },
    { lat: 49.1208706, lng: 18.8642527 },
    { lat: 49.1207621, lng: 18.8644884 },
    { lat: 49.1204945, lng: 18.8649644 },
    { lat: 49.1199145, lng: 18.8660561 },
    { lat: 49.119689, lng: 18.8669584 },
    { lat: 49.1194731, lng: 18.8674744 },
    { lat: 49.1190177, lng: 18.8682704 },
    { lat: 49.1180865, lng: 18.8691274 },
    { lat: 49.117979, lng: 18.8694486 },
    { lat: 49.1178105, lng: 18.8701684 },
    { lat: 49.117652, lng: 18.8708143 },
    { lat: 49.1174842, lng: 18.8716117 },
    { lat: 49.117387, lng: 18.8723763 },
    { lat: 49.1172569, lng: 18.8740194 },
    { lat: 49.117268, lng: 18.8742135 },
    { lat: 49.1172952, lng: 18.8744396 },
    { lat: 49.1172491, lng: 18.8746837 },
    { lat: 49.1166713, lng: 18.8765232 },
    { lat: 49.1164783, lng: 18.876783 },
    { lat: 49.1160682, lng: 18.877629 },
    { lat: 49.1157852, lng: 18.8782431 },
    { lat: 49.1155126, lng: 18.8788676 },
    { lat: 49.1152931, lng: 18.8794911 },
    { lat: 49.1150583, lng: 18.8806103 },
    { lat: 49.1150843, lng: 18.8812295 },
    { lat: 49.1133015, lng: 18.8868686 },
    { lat: 49.113226, lng: 18.8870884 },
    { lat: 49.1129563, lng: 18.8880112 },
    { lat: 49.112723, lng: 18.8886564 },
    { lat: 49.1121717, lng: 18.8902693 },
    { lat: 49.1119609, lng: 18.890864 },
    { lat: 49.1112627, lng: 18.8928261 },
    { lat: 49.1111734, lng: 18.893197 },
    { lat: 49.1110625, lng: 18.8934728 },
    { lat: 49.1109386, lng: 18.8938956 },
    { lat: 49.1107866, lng: 18.8955158 },
    { lat: 49.1107571, lng: 18.8957525 },
    { lat: 49.110764, lng: 18.8959514 },
    { lat: 49.1107176, lng: 18.8962607 },
    { lat: 49.1106961, lng: 18.8965256 },
    { lat: 49.1106714, lng: 18.896901 },
    { lat: 49.1106014, lng: 18.8976751 },
    { lat: 49.1105425, lng: 18.8983076 },
    { lat: 49.1105398, lng: 18.8984353 },
    { lat: 49.1105134, lng: 18.8986446 },
    { lat: 49.1104927, lng: 18.8987032 },
    { lat: 49.1104623, lng: 18.899103 },
    { lat: 49.1104523, lng: 18.8993925 },
    { lat: 49.1103497, lng: 18.9004526 },
    { lat: 49.1103475, lng: 18.9005198 },
    { lat: 49.1102941, lng: 18.9011091 },
    { lat: 49.1102587, lng: 18.9015646 },
    { lat: 49.1102202, lng: 18.9018758 },
    { lat: 49.1102154, lng: 18.9019793 },
    { lat: 49.1101505, lng: 18.9026959 },
    { lat: 49.1101029, lng: 18.903264 },
    { lat: 49.110014, lng: 18.9042401 },
    { lat: 49.1100216, lng: 18.904372 },
    { lat: 49.1099897, lng: 18.9045838 },
    { lat: 49.1099897, lng: 18.9046719 },
    { lat: 49.1099562, lng: 18.9048357 },
    { lat: 49.1099439, lng: 18.905012 },
    { lat: 49.1099578, lng: 18.905131 },
    { lat: 49.1099205, lng: 18.9051732 },
    { lat: 49.1099134, lng: 18.9054572 },
    { lat: 49.1098759, lng: 18.9056711 },
    { lat: 49.1098874, lng: 18.9058324 },
    { lat: 49.1098029, lng: 18.9066028 },
    { lat: 49.1097571, lng: 18.9069486 },
    { lat: 49.1095096, lng: 18.9097896 },
    { lat: 49.1094174, lng: 18.9108603 },
    { lat: 49.1093255, lng: 18.9118032 },
    { lat: 49.1092566, lng: 18.9127056 },
    { lat: 49.1091718, lng: 18.9136995 },
    { lat: 49.1091457, lng: 18.9139851 },
    { lat: 49.1090931, lng: 18.914634 },
    { lat: 49.109014, lng: 18.915498 },
    { lat: 49.1089605, lng: 18.9161514 },
    { lat: 49.1088885, lng: 18.9169527 },
    { lat: 49.1088567, lng: 18.9173249 },
    { lat: 49.1093596, lng: 18.9171058 },
    { lat: 49.1093198, lng: 18.9173628 },
    { lat: 49.1093522, lng: 18.9177365 },
    { lat: 49.1095341, lng: 18.9186103 },
    { lat: 49.109565, lng: 18.918706 },
    { lat: 49.1096507, lng: 18.9191735 },
    { lat: 49.109652, lng: 18.9191773 },
    { lat: 49.1096775, lng: 18.9193197 },
    { lat: 49.1096764, lng: 18.9199445 },
    { lat: 49.1096728, lng: 18.9201627 },
    { lat: 49.1096072, lng: 18.9203352 },
    { lat: 49.1092118, lng: 18.9209202 },
    { lat: 49.1089717, lng: 18.9212529 },
    { lat: 49.1088436, lng: 18.9214609 },
    { lat: 49.1086487, lng: 18.9218196 },
    { lat: 49.1084897, lng: 18.9220615 },
    { lat: 49.1082897, lng: 18.9219433 },
    { lat: 49.1080295, lng: 18.9217267 },
    { lat: 49.1077565, lng: 18.9214786 },
    { lat: 49.1074722, lng: 18.9211882 },
    { lat: 49.10718, lng: 18.9208268 },
    { lat: 49.1069595, lng: 18.9205495 },
    { lat: 49.1069033, lng: 18.9211743 },
    { lat: 49.1068653, lng: 18.9213565 },
    { lat: 49.1068267, lng: 18.9218199 },
    { lat: 49.106821, lng: 18.9220131 },
    { lat: 49.1067654, lng: 18.9230959 },
    { lat: 49.1067522, lng: 18.9231252 },
    { lat: 49.106737, lng: 18.9231378 },
    { lat: 49.1067351, lng: 18.9231646 },
    { lat: 49.1064768, lng: 18.923122 },
    { lat: 49.1064688, lng: 18.9232935 },
    { lat: 49.1064549, lng: 18.9233326 },
    { lat: 49.106445, lng: 18.9233787 },
    { lat: 49.1063993, lng: 18.9241024 },
    { lat: 49.1063995, lng: 18.92413 },
    { lat: 49.1064212, lng: 18.9241753 },
    { lat: 49.1064456, lng: 18.9241908 },
    { lat: 49.1072003, lng: 18.9242019 },
    { lat: 49.10727, lng: 18.924204 },
    { lat: 49.1075119, lng: 18.9242113 },
    { lat: 49.1075502, lng: 18.9242075 },
    { lat: 49.1075675, lng: 18.924194 },
    { lat: 49.1075811, lng: 18.924174 },
    { lat: 49.1076233, lng: 18.9234929 },
    { lat: 49.1076846, lng: 18.923504 },
    { lat: 49.1076767, lng: 18.9235254 },
    { lat: 49.1076387, lng: 18.9241319 },
    { lat: 49.1079295, lng: 18.9241311 },
    { lat: 49.1079303, lng: 18.9244765 },
    { lat: 49.1080057, lng: 18.9244781 },
    { lat: 49.1079536, lng: 18.9253828 },
    { lat: 49.1079183, lng: 18.9259058 },
    { lat: 49.1078757, lng: 18.9267104 },
    { lat: 49.1082295, lng: 18.926554 },
    { lat: 49.1082927, lng: 18.9269283 },
    { lat: 49.1090126, lng: 18.9267669 },
    { lat: 49.1096074, lng: 18.9267566 },
    { lat: 49.109628, lng: 18.926896 },
    { lat: 49.1096961, lng: 18.9272611 },
    { lat: 49.1097327, lng: 18.9275821 },
    { lat: 49.1097428, lng: 18.927781 },
    { lat: 49.1097319, lng: 18.9279846 },
    { lat: 49.109707, lng: 18.9281833 },
    { lat: 49.1096302, lng: 18.928713 },
    { lat: 49.1096297, lng: 18.9287958 },
    { lat: 49.1094839, lng: 18.9298757 },
    { lat: 49.1093744, lng: 18.9309401 },
    { lat: 49.1093269, lng: 18.931271 },
    { lat: 49.1093083, lng: 18.9312577 },
    { lat: 49.109265, lng: 18.9316957 },
    { lat: 49.1087281, lng: 18.9318452 },
    { lat: 49.1079424, lng: 18.9320705 },
    { lat: 49.1072865, lng: 18.9324758 },
    { lat: 49.1070841, lng: 18.9313769 },
    { lat: 49.1066484, lng: 18.9315678 },
    { lat: 49.1068056, lng: 18.9329113 },
    { lat: 49.1070113, lng: 18.9328957 },
    { lat: 49.1072198, lng: 18.9326503 },
    { lat: 49.107305, lng: 18.9325978 },
    { lat: 49.1073595, lng: 18.9328706 },
    { lat: 49.1076438, lng: 18.9327429 },
    { lat: 49.107605, lng: 18.9325312 },
    { lat: 49.1080071, lng: 18.9323446 },
    { lat: 49.1082033, lng: 18.9322589 },
    { lat: 49.1082391, lng: 18.932453 },
    { lat: 49.108795, lng: 18.9322098 },
    { lat: 49.1088773, lng: 18.9325888 },
    { lat: 49.1091057, lng: 18.9324914 },
    { lat: 49.1090526, lng: 18.9327337 },
    { lat: 49.108991, lng: 18.9330519 },
    { lat: 49.1089782, lng: 18.9331775 },
    { lat: 49.1089329, lng: 18.9334851 },
    { lat: 49.1089391, lng: 18.9336276 },
    { lat: 49.1089591, lng: 18.9338012 },
    { lat: 49.109028, lng: 18.9342476 },
    { lat: 49.1091118, lng: 18.9345868 },
    { lat: 49.1091431, lng: 18.9346735 },
    { lat: 49.109163, lng: 18.9347107 },
    { lat: 49.1091776, lng: 18.9347295 },
    { lat: 49.1091974, lng: 18.9347427 },
    { lat: 49.1093715, lng: 18.934751 },
    { lat: 49.1094011, lng: 18.9348914 },
    { lat: 49.1094457, lng: 18.9351474 },
    { lat: 49.1094557, lng: 18.9351675 },
    { lat: 49.1094656, lng: 18.9352272 },
    { lat: 49.1094663, lng: 18.9352882 },
    { lat: 49.1094803, lng: 18.9353874 },
    { lat: 49.1095419, lng: 18.935555 },
    { lat: 49.1095745, lng: 18.9356894 },
    { lat: 49.1096009, lng: 18.9358971 },
    { lat: 49.1096001, lng: 18.9359048 },
    { lat: 49.1096026, lng: 18.9359311 },
    { lat: 49.1096277, lng: 18.9360264 },
    { lat: 49.1097169, lng: 18.9362293 },
    { lat: 49.10978, lng: 18.9363176 },
    { lat: 49.1103404, lng: 18.9370726 },
    { lat: 49.110571, lng: 18.9375767 },
    { lat: 49.1106237, lng: 18.9376392 },
    { lat: 49.1112568, lng: 18.9387383 },
    { lat: 49.1114521, lng: 18.9392123 },
    { lat: 49.1116417, lng: 18.9397504 },
    { lat: 49.112147, lng: 18.9398429 },
    { lat: 49.1121973, lng: 18.9395268 },
    { lat: 49.1123881, lng: 18.9388155 },
    { lat: 49.1128069, lng: 18.9376708 },
    { lat: 49.1130145, lng: 18.9371624 },
    { lat: 49.113244, lng: 18.9367068 },
    { lat: 49.1139331, lng: 18.9356015 },
    { lat: 49.1148488, lng: 18.9343371 },
    { lat: 49.1153692, lng: 18.9350166 },
    { lat: 49.115622, lng: 18.9353748 },
    { lat: 49.1156055, lng: 18.9357871 },
    { lat: 49.1156614, lng: 18.9359752 },
    { lat: 49.1157983, lng: 18.9361409 },
    { lat: 49.116096, lng: 18.9362077 },
    { lat: 49.1175032, lng: 18.9368153 },
    { lat: 49.1178442, lng: 18.9374051 },
    { lat: 49.1186637, lng: 18.9388035 },
    { lat: 49.1190129, lng: 18.9391296 },
    { lat: 49.119347, lng: 18.9385573 },
    { lat: 49.1206168, lng: 18.9367599 },
    { lat: 49.1213728, lng: 18.9355062 },
    { lat: 49.1219047, lng: 18.9342126 },
    { lat: 49.1223121, lng: 18.9331284 },
    { lat: 49.1225488, lng: 18.9323068 },
    { lat: 49.1226133, lng: 18.9320411 },
    { lat: 49.1228151, lng: 18.9313295 },
    { lat: 49.1227944, lng: 18.9313215 },
    { lat: 49.1225782, lng: 18.9312375 },
    { lat: 49.1224734, lng: 18.9311962 },
    { lat: 49.1224148, lng: 18.9311736 },
    { lat: 49.1223407, lng: 18.9310686 },
    { lat: 49.1221525, lng: 18.9308042 },
    { lat: 49.1221342, lng: 18.9306444 },
    { lat: 49.1220977, lng: 18.9303237 },
    { lat: 49.1220872, lng: 18.9302284 },
    { lat: 49.1221386, lng: 18.9299949 },
    { lat: 49.1228741, lng: 18.9287615 },
    { lat: 49.1233982, lng: 18.9279345 },
    { lat: 49.1234571, lng: 18.9278048 },
    { lat: 49.1238469, lng: 18.9269569 },
    { lat: 49.1240386, lng: 18.9265344 },
    { lat: 49.1240842, lng: 18.9264322 },
    { lat: 49.1240929, lng: 18.9263617 },
    { lat: 49.1241186, lng: 18.9261322 },
    { lat: 49.1241411, lng: 18.9258214 },
    { lat: 49.1242182, lng: 18.9252505 },
    { lat: 49.124369, lng: 18.9246474 },
    { lat: 49.1244123, lng: 18.924522 },
    { lat: 49.1245392, lng: 18.9241561 },
    { lat: 49.1248822, lng: 18.9233637 },
    { lat: 49.1254877, lng: 18.9225868 },
    { lat: 49.1255559, lng: 18.9225525 },
    { lat: 49.1256862, lng: 18.9224341 },
    { lat: 49.1257017, lng: 18.9222282 },
    { lat: 49.1259088, lng: 18.9219968 },
    { lat: 49.126037, lng: 18.9218373 },
    { lat: 49.1258954, lng: 18.9209148 },
    { lat: 49.1256837, lng: 18.9195186 },
    { lat: 49.1253371, lng: 18.9175806 },
    { lat: 49.1250351, lng: 18.9160079 },
    { lat: 49.1250292, lng: 18.9159801 },
    { lat: 49.1250168, lng: 18.9159132 },
    { lat: 49.125006, lng: 18.915858 },
    { lat: 49.1249892, lng: 18.9157689 },
    { lat: 49.1249784, lng: 18.9157151 },
    { lat: 49.1245953, lng: 18.9137228 },
    { lat: 49.124628, lng: 18.9137013 },
    { lat: 49.1247053, lng: 18.9136475 },
    { lat: 49.1247184, lng: 18.9136388 },
    { lat: 49.1247748, lng: 18.9135918 },
    { lat: 49.1247856, lng: 18.9135859 },
    { lat: 49.1248713, lng: 18.9135383 },
    { lat: 49.1249074, lng: 18.9135182 },
    { lat: 49.1259941, lng: 18.9123193 },
    { lat: 49.1272727, lng: 18.9113003 },
    { lat: 49.1275672, lng: 18.9111046 },
    { lat: 49.1288172, lng: 18.9102758 },
    { lat: 49.1288594, lng: 18.9102007 },
    { lat: 49.1289566, lng: 18.9100257 },
    { lat: 49.1289413, lng: 18.9099008 },
    { lat: 49.1287907, lng: 18.9095557 },
    { lat: 49.1288744, lng: 18.9094321 },
    { lat: 49.1289868, lng: 18.9093806 },
    { lat: 49.1290458, lng: 18.9093877 },
    { lat: 49.129559, lng: 18.9085059 },
    { lat: 49.1300533, lng: 18.9074952 },
    { lat: 49.1301412, lng: 18.9073141 },
    { lat: 49.1307312, lng: 18.9057053 },
    { lat: 49.1310891, lng: 18.9045444 },
    { lat: 49.1316145, lng: 18.9027498 },
    { lat: 49.1321648, lng: 18.9013813 },
    { lat: 49.132287, lng: 18.901077 },
    { lat: 49.1326373, lng: 18.9004304 },
    { lat: 49.1327929, lng: 18.9001419 },
    { lat: 49.1328268, lng: 18.9000796 },
    { lat: 49.1335636, lng: 18.8987192 },
    { lat: 49.134289, lng: 18.8974877 },
    { lat: 49.1343428, lng: 18.8973945 },
    { lat: 49.1346396, lng: 18.8969309 },
    { lat: 49.1350327, lng: 18.8964624 },
    { lat: 49.1352119, lng: 18.8962388 },
    { lat: 49.1358736, lng: 18.8956628 },
    { lat: 49.1367457, lng: 18.8950756 },
    { lat: 49.1369069, lng: 18.8948779 },
    { lat: 49.1373834, lng: 18.8946677 },
    { lat: 49.1374257, lng: 18.8947242 },
    { lat: 49.1388742, lng: 18.8948068 },
    { lat: 49.1391824, lng: 18.8947077 },
    { lat: 49.1397423, lng: 18.8945639 },
    { lat: 49.1403965, lng: 18.8944382 },
    { lat: 49.1404434, lng: 18.8944038 },
    { lat: 49.1415241, lng: 18.8943308 },
    { lat: 49.1428813, lng: 18.8936154 },
    { lat: 49.1449677, lng: 18.8916377 },
    { lat: 49.1455257, lng: 18.891215 },
    { lat: 49.1472591, lng: 18.8908946 },
    { lat: 49.1484527, lng: 18.8909977 },
    { lat: 49.1484037, lng: 18.8911432 },
    { lat: 49.1498098, lng: 18.8908818 },
    { lat: 49.1501111, lng: 18.8906429 },
    { lat: 49.1517977, lng: 18.8887623 },
    { lat: 49.151496, lng: 18.8883932 },
  ],
  Záborie: [
    { lat: 49.0465789, lng: 18.962449 },
    { lat: 49.0463811, lng: 18.9628455 },
    { lat: 49.045911, lng: 18.9636462 },
    { lat: 49.0455142, lng: 18.9642514 },
    { lat: 49.0444181, lng: 18.9659195 },
    { lat: 49.0439987, lng: 18.9665028 },
    { lat: 49.043894, lng: 18.9666391 },
    { lat: 49.0432791, lng: 18.9675001 },
    { lat: 49.0425545, lng: 18.9685118 },
    { lat: 49.0422537, lng: 18.9689185 },
    { lat: 49.0419916, lng: 18.9693969 },
    { lat: 49.0414977, lng: 18.9703832 },
    { lat: 49.0413294, lng: 18.9707564 },
    { lat: 49.0411986, lng: 18.9711565 },
    { lat: 49.041091, lng: 18.9716172 },
    { lat: 49.0409744, lng: 18.9721684 },
    { lat: 49.0407062, lng: 18.9732634 },
    { lat: 49.0404632, lng: 18.9738793 },
    { lat: 49.0399493, lng: 18.975133 },
    { lat: 49.0396674, lng: 18.9757836 },
    { lat: 49.0392817, lng: 18.9765167 },
    { lat: 49.0391164, lng: 18.9769485 },
    { lat: 49.0388231, lng: 18.9777527 },
    { lat: 49.0386813, lng: 18.9780704 },
    { lat: 49.0380585, lng: 18.9792246 },
    { lat: 49.0379226, lng: 18.979391 },
    { lat: 49.037143, lng: 18.9798268 },
    { lat: 49.0367842, lng: 18.9800249 },
    { lat: 49.0361352, lng: 18.980546 },
    { lat: 49.0355574, lng: 18.9810496 },
    { lat: 49.035318, lng: 18.9811784 },
    { lat: 49.0350515, lng: 18.9812806 },
    { lat: 49.0350541, lng: 18.9813087 },
    { lat: 49.0350368, lng: 18.9813926 },
    { lat: 49.0350317, lng: 18.9814223 },
    { lat: 49.034988, lng: 18.9820796 },
    { lat: 49.0349904, lng: 18.982846 },
    { lat: 49.0348218, lng: 18.9832789 },
    { lat: 49.0346624, lng: 18.9835155 },
    { lat: 49.0341695, lng: 18.9841183 },
    { lat: 49.0339432, lng: 18.9844576 },
    { lat: 49.0339231, lng: 18.9844841 },
    { lat: 49.0337398, lng: 18.9847864 },
    { lat: 49.0335073, lng: 18.9853851 },
    { lat: 49.0334585, lng: 18.9858715 },
    { lat: 49.0334891, lng: 18.9863666 },
    { lat: 49.0335362, lng: 18.9865419 },
    { lat: 49.0335636, lng: 18.9866591 },
    { lat: 49.0337769, lng: 18.9875415 },
    { lat: 49.0339261, lng: 18.9885542 },
    { lat: 49.0338712, lng: 18.9904688 },
    { lat: 49.0338431, lng: 18.9912784 },
    { lat: 49.0338249, lng: 18.9914756 },
    { lat: 49.0339849, lng: 18.9914527 },
    { lat: 49.0339062, lng: 18.9917478 },
    { lat: 49.0338898, lng: 18.9920692 },
    { lat: 49.0338409, lng: 18.9924647 },
    { lat: 49.0338979, lng: 18.9929043 },
    { lat: 49.0339374, lng: 18.993088 },
    { lat: 49.033972, lng: 18.9937943 },
    { lat: 49.0338942, lng: 18.9942445 },
    { lat: 49.0336861, lng: 18.9949938 },
    { lat: 49.0333224, lng: 18.995677 },
    { lat: 49.0329208, lng: 18.9960772 },
    { lat: 49.0329103, lng: 18.9961133 },
    { lat: 49.0320848, lng: 18.9963314 },
    { lat: 49.0313999, lng: 18.9961199 },
    { lat: 49.0311569, lng: 18.9962437 },
    { lat: 49.0310219, lng: 18.9965623 },
    { lat: 49.0308676, lng: 18.9977317 },
    { lat: 49.0308276, lng: 18.998822 },
    { lat: 49.0308649, lng: 18.9998998 },
    { lat: 49.0309413, lng: 19.0005696 },
    { lat: 49.0308544, lng: 19.0016662 },
    { lat: 49.0308486, lng: 19.001734 },
    { lat: 49.0308927, lng: 19.0021622 },
    { lat: 49.0308836, lng: 19.0023849 },
    { lat: 49.0306227, lng: 19.0027364 },
    { lat: 49.030233, lng: 19.0034391 },
    { lat: 49.0298372, lng: 19.0036636 },
    { lat: 49.0296113, lng: 19.0039222 },
    { lat: 49.0295417, lng: 19.0039748 },
    { lat: 49.0289784, lng: 19.004633 },
    { lat: 49.0283354, lng: 19.0051189 },
    { lat: 49.0280806, lng: 19.0055012 },
    { lat: 49.0278235, lng: 19.0064662 },
    { lat: 49.0277424, lng: 19.0071204 },
    { lat: 49.0276204, lng: 19.0084182 },
    { lat: 49.027665, lng: 19.0092885 },
    { lat: 49.027637, lng: 19.010483 },
    { lat: 49.0276256, lng: 19.01102 },
    { lat: 49.027546, lng: 19.0116151 },
    { lat: 49.0273018, lng: 19.012502 },
    { lat: 49.0270443, lng: 19.0129647 },
    { lat: 49.0268097, lng: 19.0145158 },
    { lat: 49.0265315, lng: 19.0154181 },
    { lat: 49.0264995, lng: 19.0154362 },
    { lat: 49.0262147, lng: 19.0156024 },
    { lat: 49.0258065, lng: 19.0169957 },
    { lat: 49.0255739, lng: 19.0179541 },
    { lat: 49.0253563, lng: 19.0199834 },
    { lat: 49.0250791, lng: 19.0206617 },
    { lat: 49.0245985, lng: 19.0231356 },
    { lat: 49.0248043, lng: 19.0240242 },
    { lat: 49.0248793, lng: 19.0241262 },
    { lat: 49.0249785, lng: 19.0250987 },
    { lat: 49.0246325, lng: 19.0266071 },
    { lat: 49.0245844, lng: 19.0275883 },
    { lat: 49.0246454, lng: 19.0283554 },
    { lat: 49.0247957, lng: 19.0291592 },
    { lat: 49.0248979, lng: 19.0305017 },
    { lat: 49.0248642, lng: 19.0309097 },
    { lat: 49.0249406, lng: 19.0313995 },
    { lat: 49.0249743, lng: 19.0315587 },
    { lat: 49.0251195, lng: 19.0318692 },
    { lat: 49.0252416, lng: 19.0320771 },
    { lat: 49.0249441, lng: 19.0327127 },
    { lat: 49.0249291, lng: 19.0327299 },
    { lat: 49.0248157, lng: 19.0330333 },
    { lat: 49.0245109, lng: 19.0336317 },
    { lat: 49.0244604, lng: 19.0337704 },
    { lat: 49.0242873, lng: 19.0342538 },
    { lat: 49.0242009, lng: 19.0344933 },
    { lat: 49.0241485, lng: 19.034952 },
    { lat: 49.0242979, lng: 19.0353532 },
    { lat: 49.0243136, lng: 19.0353489 },
    { lat: 49.0246619, lng: 19.0360472 },
    { lat: 49.0248699, lng: 19.0365093 },
    { lat: 49.024934, lng: 19.0367806 },
    { lat: 49.0249332, lng: 19.0371234 },
    { lat: 49.0248351, lng: 19.037643 },
    { lat: 49.024845, lng: 19.0378897 },
    { lat: 49.025201, lng: 19.0386946 },
    { lat: 49.0253079, lng: 19.0390534 },
    { lat: 49.0253615, lng: 19.0394269 },
    { lat: 49.0253311, lng: 19.0400376 },
    { lat: 49.0253226, lng: 19.0402087 },
    { lat: 49.0253467, lng: 19.040262 },
    { lat: 49.0260291, lng: 19.0403356 },
    { lat: 49.0265507, lng: 19.0405349 },
    { lat: 49.0273055, lng: 19.0407496 },
    { lat: 49.0281012, lng: 19.0406794 },
    { lat: 49.0286765, lng: 19.0404816 },
    { lat: 49.0290395, lng: 19.0403004 },
    { lat: 49.029277, lng: 19.0401354 },
    { lat: 49.029467, lng: 19.0399188 },
    { lat: 49.0297294, lng: 19.0395297 },
    { lat: 49.0299866, lng: 19.0390362 },
    { lat: 49.0303726, lng: 19.0382125 },
    { lat: 49.030544, lng: 19.0379405 },
    { lat: 49.0307362, lng: 19.0376593 },
    { lat: 49.0312135, lng: 19.037079 },
    { lat: 49.0315271, lng: 19.0366541 },
    { lat: 49.0315769, lng: 19.0366082 },
    { lat: 49.0317596, lng: 19.0364402 },
    { lat: 49.0319248, lng: 19.0362881 },
    { lat: 49.0321592, lng: 19.0361935 },
    { lat: 49.0323474, lng: 19.0361599 },
    { lat: 49.0323694, lng: 19.0361369 },
    { lat: 49.0329552, lng: 19.03606 },
    { lat: 49.0334798, lng: 19.0358399 },
    { lat: 49.0337995, lng: 19.0356525 },
    { lat: 49.0339012, lng: 19.0355639 },
    { lat: 49.0343094, lng: 19.0349815 },
    { lat: 49.0345527, lng: 19.0342524 },
    { lat: 49.0347384, lng: 19.0333212 },
    { lat: 49.034787, lng: 19.0328504 },
    { lat: 49.0348759, lng: 19.031897 },
    { lat: 49.0351023, lng: 19.0319836 },
    { lat: 49.0353673, lng: 19.0316469 },
    { lat: 49.035759, lng: 19.030596 },
    { lat: 49.0362425, lng: 19.0296671 },
    { lat: 49.0363482, lng: 19.0294615 },
    { lat: 49.0365197, lng: 19.0290785 },
    { lat: 49.0367131, lng: 19.0287978 },
    { lat: 49.0368493, lng: 19.0284544 },
    { lat: 49.0370626, lng: 19.0280612 },
    { lat: 49.0371238, lng: 19.0279484 },
    { lat: 49.0371669, lng: 19.0278874 },
    { lat: 49.0373766, lng: 19.0275904 },
    { lat: 49.0375355, lng: 19.0273658 },
    { lat: 49.0376483, lng: 19.027094 },
    { lat: 49.0376653, lng: 19.0270522 },
    { lat: 49.0375779, lng: 19.0269283 },
    { lat: 49.0375086, lng: 19.026811 },
    { lat: 49.0372233, lng: 19.0263282 },
    { lat: 49.036954, lng: 19.0255193 },
    { lat: 49.036779, lng: 19.0249071 },
    { lat: 49.0366181, lng: 19.0246836 },
    { lat: 49.0366277, lng: 19.0245833 },
    { lat: 49.0366401, lng: 19.0245136 },
    { lat: 49.0366436, lng: 19.0244942 },
    { lat: 49.0364429, lng: 19.0231405 },
    { lat: 49.0360217, lng: 19.0229269 },
    { lat: 49.0360134, lng: 19.0226674 },
    { lat: 49.0363995, lng: 19.0210824 },
    { lat: 49.0367143, lng: 19.0201581 },
    { lat: 49.0371677, lng: 19.0190593 },
    { lat: 49.0372231, lng: 19.018053 },
    { lat: 49.0374236, lng: 19.0175738 },
    { lat: 49.0377362, lng: 19.0167485 },
    { lat: 49.0379757, lng: 19.015701 },
    { lat: 49.0382902, lng: 19.0152245 },
    { lat: 49.0389895, lng: 19.0139717 },
    { lat: 49.0392744, lng: 19.0132438 },
    { lat: 49.0393736, lng: 19.012795 },
    { lat: 49.0394509, lng: 19.0123103 },
    { lat: 49.0395331, lng: 19.011632 },
    { lat: 49.0399081, lng: 19.0111757 },
    { lat: 49.0394507, lng: 19.0096286 },
    { lat: 49.0396596, lng: 19.0083765 },
    { lat: 49.0395928, lng: 19.0070896 },
    { lat: 49.0393189, lng: 19.005674 },
    { lat: 49.0392556, lng: 19.0049253 },
    { lat: 49.0392153, lng: 19.0021986 },
    { lat: 49.0392167, lng: 19.0016786 },
    { lat: 49.0389616, lng: 19.0001012 },
    { lat: 49.039116, lng: 18.9994502 },
    { lat: 49.0392173, lng: 18.998705 },
    { lat: 49.0393645, lng: 18.9981561 },
    { lat: 49.0399716, lng: 18.9975857 },
    { lat: 49.0402031, lng: 18.9971194 },
    { lat: 49.0406675, lng: 18.9959292 },
    { lat: 49.0410052, lng: 18.9955823 },
    { lat: 49.0409445, lng: 18.9947546 },
    { lat: 49.0408961, lng: 18.9940843 },
    { lat: 49.0409172, lng: 18.9940517 },
    { lat: 49.0409222, lng: 18.9940443 },
    { lat: 49.040937, lng: 18.9940221 },
    { lat: 49.0409622, lng: 18.9939836 },
    { lat: 49.0408334, lng: 18.9937337 },
    { lat: 49.0406035, lng: 18.9924776 },
    { lat: 49.0405323, lng: 18.9920244 },
    { lat: 49.0405193, lng: 18.9917895 },
    { lat: 49.0405057, lng: 18.9916739 },
    { lat: 49.0404825, lng: 18.9914851 },
    { lat: 49.0405018, lng: 18.9912381 },
    { lat: 49.0406272, lng: 18.9903683 },
    { lat: 49.0406865, lng: 18.9901393 },
    { lat: 49.0407143, lng: 18.9893667 },
    { lat: 49.040704, lng: 18.9885328 },
    { lat: 49.0405796, lng: 18.9880557 },
    { lat: 49.0401833, lng: 18.9866767 },
    { lat: 49.0401367, lng: 18.9865103 },
    { lat: 49.0400313, lng: 18.985852 },
    { lat: 49.0400584, lng: 18.9851137 },
    { lat: 49.0400817, lng: 18.9845213 },
    { lat: 49.0401251, lng: 18.9836365 },
    { lat: 49.0403027, lng: 18.9830689 },
    { lat: 49.0403472, lng: 18.9829423 },
    { lat: 49.0404349, lng: 18.982784 },
    { lat: 49.0404723, lng: 18.9826963 },
    { lat: 49.0406966, lng: 18.9823611 },
    { lat: 49.0413557, lng: 18.9812121 },
    { lat: 49.0416935, lng: 18.9806204 },
    { lat: 49.0417308, lng: 18.9805531 },
    { lat: 49.0422329, lng: 18.9796879 },
    { lat: 49.0422679, lng: 18.9796216 },
    { lat: 49.042309, lng: 18.9795421 },
    { lat: 49.0424005, lng: 18.9793693 },
    { lat: 49.0425212, lng: 18.9789668 },
    { lat: 49.0425574, lng: 18.9787887 },
    { lat: 49.0425752, lng: 18.9786971 },
    { lat: 49.042914, lng: 18.9769858 },
    { lat: 49.0431085, lng: 18.9761251 },
    { lat: 49.0433253, lng: 18.9755928 },
    { lat: 49.0440205, lng: 18.9740864 },
    { lat: 49.0445384, lng: 18.9729777 },
    { lat: 49.045209, lng: 18.9717203 },
    { lat: 49.0452065, lng: 18.9718482 },
    { lat: 49.0452546, lng: 18.9718425 },
    { lat: 49.0452395, lng: 18.9716542 },
    { lat: 49.0452807, lng: 18.9716012 },
    { lat: 49.0453753, lng: 18.9715977 },
    { lat: 49.0453972, lng: 18.9714935 },
    { lat: 49.0453607, lng: 18.9714438 },
    { lat: 49.0460019, lng: 18.9701841 },
    { lat: 49.0462295, lng: 18.9696677 },
    { lat: 49.0466967, lng: 18.9684943 },
    { lat: 49.0469202, lng: 18.9679607 },
    { lat: 49.0471154, lng: 18.9673852 },
    { lat: 49.0475757, lng: 18.9654679 },
    { lat: 49.0478857, lng: 18.9641961 },
    { lat: 49.0480224, lng: 18.9636007 },
    { lat: 49.0481247, lng: 18.9632599 },
    { lat: 49.0482695, lng: 18.9630198 },
    { lat: 49.0484905, lng: 18.9626665 },
    { lat: 49.0484409, lng: 18.9626209 },
    { lat: 49.0484318, lng: 18.9625764 },
    { lat: 49.0484076, lng: 18.9625414 },
    { lat: 49.0478283, lng: 18.9621189 },
    { lat: 49.0474454, lng: 18.9615732 },
    { lat: 49.0472808, lng: 18.9618188 },
    { lat: 49.046754, lng: 18.962638 },
    { lat: 49.0465789, lng: 18.962449 },
  ],
  Ďanová: [
    { lat: 48.9667136, lng: 18.9063663 },
    { lat: 48.9666102, lng: 18.9065952 },
    { lat: 48.9665724, lng: 18.9069137 },
    { lat: 48.9667622, lng: 18.9071185 },
    { lat: 48.9673188, lng: 18.9080383 },
    { lat: 48.9680723, lng: 18.909456 },
    { lat: 48.9672012, lng: 18.9101281 },
    { lat: 48.9667714, lng: 18.910502 },
    { lat: 48.9661922, lng: 18.9110597 },
    { lat: 48.9654809, lng: 18.9119045 },
    { lat: 48.9636841, lng: 18.9136783 },
    { lat: 48.9624161, lng: 18.9144142 },
    { lat: 48.9610074, lng: 18.9151358 },
    { lat: 48.9608095, lng: 18.9145268 },
    { lat: 48.9593596, lng: 18.9153009 },
    { lat: 48.9593875, lng: 18.9153855 },
    { lat: 48.9599437, lng: 18.9171604 },
    { lat: 48.9599757, lng: 18.9172596 },
    { lat: 48.9594886, lng: 18.9175009 },
    { lat: 48.9591671, lng: 18.9177199 },
    { lat: 48.960229, lng: 18.9211195 },
    { lat: 48.9599996, lng: 18.9209577 },
    { lat: 48.9594474, lng: 18.9216724 },
    { lat: 48.9597331, lng: 18.9220346 },
    { lat: 48.9598326, lng: 18.9222382 },
    { lat: 48.9597747, lng: 18.922471 },
    { lat: 48.9595877, lng: 18.9226074 },
    { lat: 48.9594934, lng: 18.9228797 },
    { lat: 48.9594175, lng: 18.9231617 },
    { lat: 48.9593321, lng: 18.92328 },
    { lat: 48.9592678, lng: 18.9233916 },
    { lat: 48.9592921, lng: 18.9235104 },
    { lat: 48.9593512, lng: 18.9238544 },
    { lat: 48.9597662, lng: 18.9256947 },
    { lat: 48.9602029, lng: 18.9255253 },
    { lat: 48.960403, lng: 18.9254692 },
    { lat: 48.9613195, lng: 18.9254649 },
    { lat: 48.9614802, lng: 18.9261622 },
    { lat: 48.9615164, lng: 18.9267614 },
    { lat: 48.9615688, lng: 18.9272149 },
    { lat: 48.9615648, lng: 18.9286215 },
    { lat: 48.9621357, lng: 18.9296916 },
    { lat: 48.9622125, lng: 18.9297378 },
    { lat: 48.9642346, lng: 18.9307916 },
    { lat: 48.9644266, lng: 18.9315276 },
    { lat: 48.9646011, lng: 18.9320321 },
    { lat: 48.9646522, lng: 18.9321086 },
    { lat: 48.9665024, lng: 18.933595 },
    { lat: 48.9669426, lng: 18.9339596 },
    { lat: 48.9681816, lng: 18.9350189 },
    { lat: 48.968546, lng: 18.9348029 },
    { lat: 48.9688365, lng: 18.9347071 },
    { lat: 48.9690589, lng: 18.9345138 },
    { lat: 48.9693266, lng: 18.9343474 },
    { lat: 48.9696963, lng: 18.9339467 },
    { lat: 48.9700111, lng: 18.9337953 },
    { lat: 48.9703514, lng: 18.9333918 },
    { lat: 48.9707781, lng: 18.9332442 },
    { lat: 48.9709066, lng: 18.9333773 },
    { lat: 48.971106, lng: 18.9333076 },
    { lat: 48.9714483, lng: 18.9333446 },
    { lat: 48.9716162, lng: 18.932919 },
    { lat: 48.9718529, lng: 18.9328543 },
    { lat: 48.9719939, lng: 18.9329878 },
    { lat: 48.97226, lng: 18.9329652 },
    { lat: 48.972353, lng: 18.9330853 },
    { lat: 48.9725735, lng: 18.9328311 },
    { lat: 48.9729667, lng: 18.9327631 },
    { lat: 48.97309, lng: 18.9324135 },
    { lat: 48.9733342, lng: 18.9325363 },
    { lat: 48.9735036, lng: 18.9324112 },
    { lat: 48.9736078, lng: 18.9327751 },
    { lat: 48.9733817, lng: 18.9332721 },
    { lat: 48.973229, lng: 18.9335294 },
    { lat: 48.9730665, lng: 18.933879 },
    { lat: 48.9726772, lng: 18.9341644 },
    { lat: 48.9725802, lng: 18.9342077 },
    { lat: 48.9724365, lng: 18.9344396 },
    { lat: 48.9722428, lng: 18.9345574 },
    { lat: 48.9722386, lng: 18.9351396 },
    { lat: 48.9722758, lng: 18.9352946 },
    { lat: 48.9728117, lng: 18.9352587 },
    { lat: 48.9762529, lng: 18.9355934 },
    { lat: 48.9772703, lng: 18.9360462 },
    { lat: 48.9771968, lng: 18.9365942 },
    { lat: 48.9771374, lng: 18.9369558 },
    { lat: 48.9770925, lng: 18.9373839 },
    { lat: 48.9771499, lng: 18.9382669 },
    { lat: 48.9772202, lng: 18.9385867 },
    { lat: 48.9774124, lng: 18.9389744 },
    { lat: 48.9777431, lng: 18.9394485 },
    { lat: 48.9781228, lng: 18.9387127 },
    { lat: 48.9783163, lng: 18.9383914 },
    { lat: 48.9789093, lng: 18.9374386 },
    { lat: 48.9792793, lng: 18.9378852 },
    { lat: 48.9794264, lng: 18.9382513 },
    { lat: 48.9796078, lng: 18.9382261 },
    { lat: 48.9797513, lng: 18.9390297 },
    { lat: 48.9799077, lng: 18.9398021 },
    { lat: 48.9801583, lng: 18.9404978 },
    { lat: 48.980721, lng: 18.9415209 },
    { lat: 48.9809784, lng: 18.941682 },
    { lat: 48.9813215, lng: 18.9416677 },
    { lat: 48.9816213, lng: 18.9415974 },
    { lat: 48.9822086, lng: 18.9413976 },
    { lat: 48.9825239, lng: 18.9412325 },
    { lat: 48.9826906, lng: 18.9411778 },
    { lat: 48.9828011, lng: 18.9411415 },
    { lat: 48.9833025, lng: 18.9410684 },
    { lat: 48.9834136, lng: 18.9417008 },
    { lat: 48.9845564, lng: 18.9421161 },
    { lat: 48.9849597, lng: 18.94222 },
    { lat: 48.9853896, lng: 18.9422917 },
    { lat: 48.9854176, lng: 18.9420479 },
    { lat: 48.9855598, lng: 18.9407929 },
    { lat: 48.9855834, lng: 18.9406033 },
    { lat: 48.9856151, lng: 18.9400815 },
    { lat: 48.985662, lng: 18.9393079 },
    { lat: 48.9856748, lng: 18.9389854 },
    { lat: 48.9857084, lng: 18.9381344 },
    { lat: 48.9857222, lng: 18.9377863 },
    { lat: 48.9858619, lng: 18.9366385 },
    { lat: 48.9858429, lng: 18.93624 },
    { lat: 48.9858872, lng: 18.9361408 },
    { lat: 48.9859945, lng: 18.9361731 },
    { lat: 48.986509, lng: 18.936694 },
    { lat: 48.9868796, lng: 18.9371107 },
    { lat: 48.9872839, lng: 18.9375437 },
    { lat: 48.9876415, lng: 18.9380122 },
    { lat: 48.9888127, lng: 18.9389626 },
    { lat: 48.9897492, lng: 18.9397109 },
    { lat: 48.99085, lng: 18.9405422 },
    { lat: 48.9908715, lng: 18.9405644 },
    { lat: 48.9908954, lng: 18.940548 },
    { lat: 48.9909193, lng: 18.9404834 },
    { lat: 48.9909795, lng: 18.9404254 },
    { lat: 48.9911594, lng: 18.94013 },
    { lat: 48.9913094, lng: 18.9399304 },
    { lat: 48.9913344, lng: 18.9397796 },
    { lat: 48.9913766, lng: 18.9397642 },
    { lat: 48.991392, lng: 18.9397132 },
    { lat: 48.9917601, lng: 18.9391696 },
    { lat: 48.9920105, lng: 18.9389496 },
    { lat: 48.9922189, lng: 18.9387216 },
    { lat: 48.9925508, lng: 18.9382429 },
    { lat: 48.9930311, lng: 18.937512 },
    { lat: 48.993278, lng: 18.9368239 },
    { lat: 48.9933459, lng: 18.9366796 },
    { lat: 48.993452, lng: 18.9364541 },
    { lat: 48.9935786, lng: 18.9363456 },
    { lat: 48.9937585, lng: 18.9362038 },
    { lat: 48.9949122, lng: 18.9352779 },
    { lat: 48.9955883, lng: 18.9347379 },
    { lat: 48.9959995, lng: 18.9343766 },
    { lat: 48.9965667, lng: 18.9333998 },
    { lat: 48.9967424, lng: 18.9329107 },
    { lat: 48.9966708, lng: 18.9324311 },
    { lat: 48.996157, lng: 18.9312843 },
    { lat: 48.9955119, lng: 18.9298646 },
    { lat: 48.9950063, lng: 18.9287682 },
    { lat: 48.9940229, lng: 18.9272102 },
    { lat: 48.9939051, lng: 18.9258928 },
    { lat: 48.9938911, lng: 18.9255469 },
    { lat: 48.9939227, lng: 18.9251522 },
    { lat: 48.9940181, lng: 18.924899 },
    { lat: 48.9943267, lng: 18.9242516 },
    { lat: 48.9945218, lng: 18.9238086 },
    { lat: 48.9946336, lng: 18.9234343 },
    { lat: 48.9948662, lng: 18.9222005 },
    { lat: 48.9950448, lng: 18.9211717 },
    { lat: 48.9951236, lng: 18.9207879 },
    { lat: 48.9951003, lng: 18.9207767 },
    { lat: 48.9948777, lng: 18.9208115 },
    { lat: 48.9947111, lng: 18.9207514 },
    { lat: 48.9944649, lng: 18.920608 },
    { lat: 48.9942693, lng: 18.9205111 },
    { lat: 48.9939033, lng: 18.9204073 },
    { lat: 48.9937753, lng: 18.9203947 },
    { lat: 48.9935013, lng: 18.9202851 },
    { lat: 48.9933829, lng: 18.9191329 },
    { lat: 48.9932483, lng: 18.918194 },
    { lat: 48.9932005, lng: 18.9178995 },
    { lat: 48.9931689, lng: 18.9178314 },
    { lat: 48.9930779, lng: 18.9171959 },
    { lat: 48.9929839, lng: 18.9166766 },
    { lat: 48.9920691, lng: 18.9162501 },
    { lat: 48.9917428, lng: 18.9160655 },
    { lat: 48.991663, lng: 18.9159816 },
    { lat: 48.9915193, lng: 18.9159019 },
    { lat: 48.9904993, lng: 18.9156471 },
    { lat: 48.9903674, lng: 18.9156041 },
    { lat: 48.9899192, lng: 18.9138658 },
    { lat: 48.9897354, lng: 18.9133557 },
    { lat: 48.9896686, lng: 18.9130667 },
    { lat: 48.9894587, lng: 18.9123558 },
    { lat: 48.9891276, lng: 18.9113903 },
    { lat: 48.9891024, lng: 18.9113825 },
    { lat: 48.9889757, lng: 18.9111953 },
    { lat: 48.9888098, lng: 18.9111258 },
    { lat: 48.988616, lng: 18.9110815 },
    { lat: 48.9883818, lng: 18.9110434 },
    { lat: 48.9879948, lng: 18.9108865 },
    { lat: 48.9875764, lng: 18.9106414 },
    { lat: 48.9873185, lng: 18.9105142 },
    { lat: 48.9870793, lng: 18.9104016 },
    { lat: 48.9869517, lng: 18.9103721 },
    { lat: 48.9864992, lng: 18.9102327 },
    { lat: 48.9863994, lng: 18.9101116 },
    { lat: 48.9861384, lng: 18.9101269 },
    { lat: 48.9858725, lng: 18.9100168 },
    { lat: 48.98556, lng: 18.9098156 },
    { lat: 48.9854544, lng: 18.9097128 },
    { lat: 48.9849112, lng: 18.9091199 },
    { lat: 48.9848959, lng: 18.9090847 },
    { lat: 48.9845634, lng: 18.9086895 },
    { lat: 48.9843641, lng: 18.90841 },
    { lat: 48.9834104, lng: 18.9069541 },
    { lat: 48.9827178, lng: 18.906022 },
    { lat: 48.9826661, lng: 18.9058881 },
    { lat: 48.9784147, lng: 18.9030953 },
    { lat: 48.9781065, lng: 18.9032224 },
    { lat: 48.9779886, lng: 18.9030748 },
    { lat: 48.977378, lng: 18.9035499 },
    { lat: 48.9768483, lng: 18.9039049 },
    { lat: 48.9764806, lng: 18.9041266 },
    { lat: 48.9760312, lng: 18.9043752 },
    { lat: 48.9754965, lng: 18.9046378 },
    { lat: 48.9749061, lng: 18.9048657 },
    { lat: 48.9741271, lng: 18.9051341 },
    { lat: 48.9732828, lng: 18.9053994 },
    { lat: 48.9723466, lng: 18.9057269 },
    { lat: 48.9720711, lng: 18.9030257 },
    { lat: 48.9710818, lng: 18.9037223 },
    { lat: 48.9698215, lng: 18.9045632 },
    { lat: 48.9684451, lng: 18.9056746 },
    { lat: 48.9673139, lng: 18.9065747 },
    { lat: 48.9672199, lng: 18.9065891 },
    { lat: 48.9671028, lng: 18.9065677 },
    { lat: 48.9667136, lng: 18.9063663 },
  ],
  Šútovo: [
    { lat: 49.150272, lng: 19.096962 },
    { lat: 49.150337, lng: 19.096903 },
    { lat: 49.150566, lng: 19.096732 },
    { lat: 49.150582, lng: 19.096721 },
    { lat: 49.150615, lng: 19.096695 },
    { lat: 49.150637, lng: 19.096667 },
    { lat: 49.150671, lng: 19.096621 },
    { lat: 49.150797, lng: 19.096455 },
    { lat: 49.150852, lng: 19.096382 },
    { lat: 49.150928, lng: 19.096284 },
    { lat: 49.151086, lng: 19.096149 },
    { lat: 49.151127, lng: 19.096115 },
    { lat: 49.151232, lng: 19.096007 },
    { lat: 49.151318, lng: 19.095885 },
    { lat: 49.151332, lng: 19.095865 },
    { lat: 49.151448, lng: 19.095775 },
    { lat: 49.15156, lng: 19.095722 },
    { lat: 49.151617, lng: 19.095695 },
    { lat: 49.151751, lng: 19.09563 },
    { lat: 49.151752, lng: 19.09563 },
    { lat: 49.151782, lng: 19.095618 },
    { lat: 49.15185, lng: 19.095502 },
    { lat: 49.151905, lng: 19.095325 },
    { lat: 49.151917, lng: 19.095245 },
    { lat: 49.151918, lng: 19.095106 },
    { lat: 49.151923, lng: 19.09502 },
    { lat: 49.151988, lng: 19.094776 },
    { lat: 49.152022, lng: 19.09471 },
    { lat: 49.152055, lng: 19.094665 },
    { lat: 49.152179, lng: 19.094602 },
    { lat: 49.152249, lng: 19.094567 },
    { lat: 49.152281, lng: 19.094555 },
    { lat: 49.152362, lng: 19.094544 },
    { lat: 49.152527, lng: 19.094538 },
    { lat: 49.1527, lng: 19.094499 },
    { lat: 49.152736, lng: 19.09449 },
    { lat: 49.152809, lng: 19.094373 },
    { lat: 49.152883, lng: 19.094202 },
    { lat: 49.15288, lng: 19.094038 },
    { lat: 49.152797, lng: 19.093738 },
    { lat: 49.152801, lng: 19.093647 },
    { lat: 49.152869, lng: 19.09345 },
    { lat: 49.152952, lng: 19.093207 },
    { lat: 49.153137, lng: 19.092744 },
    { lat: 49.153165, lng: 19.092679 },
    { lat: 49.153305, lng: 19.092511 },
    { lat: 49.153347, lng: 19.092503 },
    { lat: 49.153513, lng: 19.092438 },
    { lat: 49.153633, lng: 19.092296 },
    { lat: 49.153744, lng: 19.092125 },
    { lat: 49.154038, lng: 19.091883 },
    { lat: 49.154106, lng: 19.091829 },
    { lat: 49.154273, lng: 19.091554 },
    { lat: 49.154401, lng: 19.091278 },
    { lat: 49.154632, lng: 19.091214 },
    { lat: 49.154791, lng: 19.091129 },
    { lat: 49.154847, lng: 19.091119 },
    { lat: 49.154922, lng: 19.091154 },
    { lat: 49.155004, lng: 19.091188 },
    { lat: 49.155085, lng: 19.091188 },
    { lat: 49.155177, lng: 19.091064 },
    { lat: 49.155338, lng: 19.090748 },
    { lat: 49.155466, lng: 19.090581 },
    { lat: 49.155617, lng: 19.09041 },
    { lat: 49.155749, lng: 19.090322 },
    { lat: 49.156009, lng: 19.090213 },
    { lat: 49.156256, lng: 19.090118 },
    { lat: 49.156385, lng: 19.090014 },
    { lat: 49.156476, lng: 19.089804 },
    { lat: 49.156521, lng: 19.089461 },
    { lat: 49.156793, lng: 19.089133 },
    { lat: 49.156961, lng: 19.088956 },
    { lat: 49.157052, lng: 19.088933 },
    { lat: 49.157146, lng: 19.088896 },
    { lat: 49.157416, lng: 19.08867 },
    { lat: 49.157531, lng: 19.088446 },
    { lat: 49.157566, lng: 19.088366 },
    { lat: 49.157603, lng: 19.088267 },
    { lat: 49.157657, lng: 19.088165 },
    { lat: 49.157701, lng: 19.088035 },
    { lat: 49.157773, lng: 19.087923 },
    { lat: 49.157977, lng: 19.087746 },
    { lat: 49.158196, lng: 19.087647 },
    { lat: 49.158517, lng: 19.087412 },
    { lat: 49.158604, lng: 19.087325 },
    { lat: 49.158675, lng: 19.08734 },
    { lat: 49.158808, lng: 19.087334 },
    { lat: 49.158955, lng: 19.087256 },
    { lat: 49.159003, lng: 19.087199 },
    { lat: 49.159074, lng: 19.087 },
    { lat: 49.159128, lng: 19.086931 },
    { lat: 49.159278, lng: 19.086802 },
    { lat: 49.159422, lng: 19.08672 },
    { lat: 49.159625, lng: 19.086807 },
    { lat: 49.159756, lng: 19.086847 },
    { lat: 49.15992, lng: 19.086894 },
    { lat: 49.159979, lng: 19.086919 },
    { lat: 49.159997, lng: 19.086918 },
    { lat: 49.160128, lng: 19.086833 },
    { lat: 49.160259, lng: 19.086691 },
    { lat: 49.16038, lng: 19.086533 },
    { lat: 49.160481, lng: 19.086384 },
    { lat: 49.16056, lng: 19.08633 },
    { lat: 49.160722, lng: 19.086269 },
    { lat: 49.16077, lng: 19.086273 },
    { lat: 49.160856, lng: 19.086261 },
    { lat: 49.160918, lng: 19.086199 },
    { lat: 49.160966, lng: 19.08614 },
    { lat: 49.161244, lng: 19.085894 },
    { lat: 49.161935, lng: 19.085309 },
    { lat: 49.162174, lng: 19.085146 },
    { lat: 49.162407, lng: 19.085047 },
    { lat: 49.162502, lng: 19.084922 },
    { lat: 49.162569, lng: 19.084826 },
    { lat: 49.162756, lng: 19.084415 },
    { lat: 49.16282, lng: 19.084236 },
    { lat: 49.162836, lng: 19.084058 },
    { lat: 49.162848, lng: 19.084001 },
    { lat: 49.162941, lng: 19.083796 },
    { lat: 49.163117, lng: 19.083569 },
    { lat: 49.163251, lng: 19.083443 },
    { lat: 49.163452, lng: 19.082988 },
    { lat: 49.163558, lng: 19.082811 },
    { lat: 49.163682, lng: 19.0827 },
    { lat: 49.163787, lng: 19.082544 },
    { lat: 49.163871, lng: 19.082364 },
    { lat: 49.163994, lng: 19.082196 },
    { lat: 49.164419, lng: 19.081831 },
    { lat: 49.164626, lng: 19.081482 },
    { lat: 49.164746, lng: 19.081296 },
    { lat: 49.164834, lng: 19.081236 },
    { lat: 49.164853, lng: 19.081232 },
    { lat: 49.164984, lng: 19.081214 },
    { lat: 49.165044, lng: 19.081217 },
    { lat: 49.165092, lng: 19.081184 },
    { lat: 49.16522, lng: 19.081018 },
    { lat: 49.165386, lng: 19.080856 },
    { lat: 49.165648, lng: 19.080799 },
    { lat: 49.165727, lng: 19.080765 },
    { lat: 49.165866, lng: 19.080639 },
    { lat: 49.166025, lng: 19.080465 },
    { lat: 49.166068, lng: 19.080399 },
    { lat: 49.166115, lng: 19.080325 },
    { lat: 49.166205, lng: 19.080235 },
    { lat: 49.166328, lng: 19.080235 },
    { lat: 49.166362, lng: 19.080247 },
    { lat: 49.166541, lng: 19.080248 },
    { lat: 49.166723, lng: 19.080426 },
    { lat: 49.166932, lng: 19.080535 },
    { lat: 49.167003, lng: 19.080552 },
    { lat: 49.167069, lng: 19.08052 },
    { lat: 49.16721, lng: 19.080364 },
    { lat: 49.167281, lng: 19.080042 },
    { lat: 49.167364, lng: 19.079918 },
    { lat: 49.167569, lng: 19.079763 },
    { lat: 49.1677, lng: 19.079676 },
    { lat: 49.167725, lng: 19.079659 },
    { lat: 49.16795, lng: 19.079513 },
    { lat: 49.168005, lng: 19.079502 },
    { lat: 49.168069, lng: 19.07947 },
    { lat: 49.168122, lng: 19.079423 },
    { lat: 49.168152, lng: 19.079413 },
    { lat: 49.168182, lng: 19.07942 },
    { lat: 49.168248, lng: 19.07938 },
    { lat: 49.168539, lng: 19.079156 },
    { lat: 49.168662, lng: 19.078924 },
    { lat: 49.168749, lng: 19.078891 },
    { lat: 49.16881, lng: 19.078909 },
    { lat: 49.168883, lng: 19.078924 },
    { lat: 49.168951, lng: 19.078896 },
    { lat: 49.169204, lng: 19.078792 },
    { lat: 49.169457, lng: 19.078685 },
    { lat: 49.169686, lng: 19.07854 },
    { lat: 49.16974, lng: 19.078506 },
    { lat: 49.169877, lng: 19.078417 },
    { lat: 49.170037, lng: 19.078305 },
    { lat: 49.170307, lng: 19.078211 },
    { lat: 49.170387, lng: 19.078247 },
    { lat: 49.170644, lng: 19.07844 },
    { lat: 49.171152, lng: 19.078466 },
    { lat: 49.171347, lng: 19.078346 },
    { lat: 49.171552, lng: 19.078302 },
    { lat: 49.171657, lng: 19.07834 },
    { lat: 49.171669, lng: 19.078343 },
    { lat: 49.171762, lng: 19.078372 },
    { lat: 49.171881, lng: 19.078365 },
    { lat: 49.172054, lng: 19.078398 },
    { lat: 49.172084, lng: 19.078403 },
    { lat: 49.172181, lng: 19.078543 },
    { lat: 49.172334, lng: 19.078772 },
    { lat: 49.172386, lng: 19.078808 },
    { lat: 49.172564, lng: 19.078981 },
    { lat: 49.172875, lng: 19.079144 },
    { lat: 49.1731, lng: 19.079282 },
    { lat: 49.173456, lng: 19.079442 },
    { lat: 49.17357, lng: 19.079602 },
    { lat: 49.174148, lng: 19.080001 },
    { lat: 49.174265, lng: 19.080005 },
    { lat: 49.174338, lng: 19.080176 },
    { lat: 49.174628, lng: 19.080485 },
    { lat: 49.174731, lng: 19.080554 },
    { lat: 49.174853, lng: 19.080606 },
    { lat: 49.175077, lng: 19.080841 },
    { lat: 49.175121, lng: 19.080864 },
    { lat: 49.175266, lng: 19.080917 },
    { lat: 49.175334, lng: 19.080898 },
    { lat: 49.175456, lng: 19.080865 },
    { lat: 49.175476, lng: 19.080864 },
    { lat: 49.175639, lng: 19.080963 },
    { lat: 49.175722, lng: 19.081021 },
    { lat: 49.175779, lng: 19.081094 },
    { lat: 49.175873, lng: 19.081243 },
    { lat: 49.175959, lng: 19.081358 },
    { lat: 49.176209, lng: 19.08144 },
    { lat: 49.176375, lng: 19.081482 },
    { lat: 49.176544, lng: 19.081588 },
    { lat: 49.176645, lng: 19.081765 },
    { lat: 49.176684, lng: 19.081817 },
    { lat: 49.176747, lng: 19.081912 },
    { lat: 49.176836, lng: 19.081989 },
    { lat: 49.177018, lng: 19.08198 },
    { lat: 49.17726, lng: 19.082135 },
    { lat: 49.177442, lng: 19.082314 },
    { lat: 49.177529, lng: 19.08229 },
    { lat: 49.17764, lng: 19.082239 },
    { lat: 49.177858, lng: 19.082241 },
    { lat: 49.178, lng: 19.082367 },
    { lat: 49.178086, lng: 19.082423 },
    { lat: 49.178238, lng: 19.08246 },
    { lat: 49.178338, lng: 19.082549 },
    { lat: 49.178498, lng: 19.082775 },
    { lat: 49.178629, lng: 19.083013 },
    { lat: 49.1788, lng: 19.083179 },
    { lat: 49.178944, lng: 19.083417 },
    { lat: 49.179014, lng: 19.083556 },
    { lat: 49.179035, lng: 19.083612 },
    { lat: 49.179122, lng: 19.08368 },
    { lat: 49.17921, lng: 19.083757 },
    { lat: 49.179452, lng: 19.08395 },
    { lat: 49.179624, lng: 19.084086 },
    { lat: 49.179706, lng: 19.084172 },
    { lat: 49.179835, lng: 19.084239 },
    { lat: 49.179963, lng: 19.08429 },
    { lat: 49.180163, lng: 19.084379 },
    { lat: 49.180228, lng: 19.084425 },
    { lat: 49.180319, lng: 19.084567 },
    { lat: 49.180507, lng: 19.085001 },
    { lat: 49.180515, lng: 19.085068 },
    { lat: 49.180658, lng: 19.085327 },
    { lat: 49.180689, lng: 19.085363 },
    { lat: 49.18079, lng: 19.085343 },
    { lat: 49.180815, lng: 19.085342 },
    { lat: 49.180938, lng: 19.085445 },
    { lat: 49.18102, lng: 19.085472 },
    { lat: 49.181142, lng: 19.085535 },
    { lat: 49.181312, lng: 19.085563 },
    { lat: 49.181374, lng: 19.08555 },
    { lat: 49.181668, lng: 19.085484 },
    { lat: 49.181864, lng: 19.085355 },
    { lat: 49.182067, lng: 19.085355 },
    { lat: 49.182222, lng: 19.085439 },
    { lat: 49.182458, lng: 19.085431 },
    { lat: 49.182532, lng: 19.085423 },
    { lat: 49.182665, lng: 19.085395 },
    { lat: 49.182898, lng: 19.085321 },
    { lat: 49.183141, lng: 19.085285 },
    { lat: 49.183221, lng: 19.085298 },
    { lat: 49.183316, lng: 19.085232 },
    { lat: 49.1834, lng: 19.085101 },
    { lat: 49.183495, lng: 19.085019 },
    { lat: 49.18357, lng: 19.084997 },
    { lat: 49.183608, lng: 19.085039 },
    { lat: 49.183642, lng: 19.085071 },
    { lat: 49.183835, lng: 19.08506 },
    { lat: 49.183947, lng: 19.085049 },
    { lat: 49.183971, lng: 19.08505 },
    { lat: 49.18401, lng: 19.085091 },
    { lat: 49.184147, lng: 19.085138 },
    { lat: 49.184223, lng: 19.085178 },
    { lat: 49.184345, lng: 19.085179 },
    { lat: 49.18453, lng: 19.085098 },
    { lat: 49.184653, lng: 19.084922 },
    { lat: 49.184775, lng: 19.084828 },
    { lat: 49.184892, lng: 19.084839 },
    { lat: 49.185064, lng: 19.08468 },
    { lat: 49.18511, lng: 19.084622 },
    { lat: 49.185147, lng: 19.084562 },
    { lat: 49.185175, lng: 19.084474 },
    { lat: 49.185309, lng: 19.084394 },
    { lat: 49.185675, lng: 19.08439 },
    { lat: 49.185867, lng: 19.08431 },
    { lat: 49.186178, lng: 19.084114 },
    { lat: 49.186456, lng: 19.084011 },
    { lat: 49.186687, lng: 19.084074 },
    { lat: 49.187281, lng: 19.084153 },
    { lat: 49.187295, lng: 19.084154 },
    { lat: 49.187555, lng: 19.084224 },
    { lat: 49.187741, lng: 19.084372 },
    { lat: 49.18799, lng: 19.084402 },
    { lat: 49.188151, lng: 19.084454 },
    { lat: 49.188256, lng: 19.084456 },
    { lat: 49.188508, lng: 19.084406 },
    { lat: 49.188607, lng: 19.084436 },
    { lat: 49.188937, lng: 19.084018 },
    { lat: 49.189052, lng: 19.083897 },
    { lat: 49.189227, lng: 19.083851 },
    { lat: 49.189766, lng: 19.083792 },
    { lat: 49.189811, lng: 19.083706 },
    { lat: 49.190323, lng: 19.083374 },
    { lat: 49.19037, lng: 19.083353 },
    { lat: 49.190433, lng: 19.083379 },
    { lat: 49.190909, lng: 19.083289 },
    { lat: 49.191116, lng: 19.083235 },
    { lat: 49.191209, lng: 19.083115 },
    { lat: 49.191318, lng: 19.083045 },
    { lat: 49.191845, lng: 19.082868 },
    { lat: 49.192029, lng: 19.082592 },
    { lat: 49.192233, lng: 19.082197 },
    { lat: 49.192498, lng: 19.081894 },
    { lat: 49.192585, lng: 19.081809 },
    { lat: 49.192709, lng: 19.081816 },
    { lat: 49.192858, lng: 19.081844 },
    { lat: 49.193023, lng: 19.081864 },
    { lat: 49.193184, lng: 19.081787 },
    { lat: 49.193379, lng: 19.081739 },
    { lat: 49.193599, lng: 19.081767 },
    { lat: 49.194189, lng: 19.081817 },
    { lat: 49.194501, lng: 19.081888 },
    { lat: 49.194813, lng: 19.081917 },
    { lat: 49.194999, lng: 19.081948 },
    { lat: 49.195345, lng: 19.081951 },
    { lat: 49.195997, lng: 19.082053 },
    { lat: 49.196128, lng: 19.082115 },
    { lat: 49.196448, lng: 19.082214 },
    { lat: 49.196651, lng: 19.082364 },
    { lat: 49.19689, lng: 19.082412 },
    { lat: 49.197219, lng: 19.082536 },
    { lat: 49.197385, lng: 19.082489 },
    { lat: 49.197592, lng: 19.082573 },
    { lat: 49.197749, lng: 19.082554 },
    { lat: 49.19797, lng: 19.082276 },
    { lat: 49.198289, lng: 19.081937 },
    { lat: 49.198504, lng: 19.081704 },
    { lat: 49.198704, lng: 19.081534 },
    { lat: 49.198961, lng: 19.081149 },
    { lat: 49.19912, lng: 19.08092 },
    { lat: 49.199199, lng: 19.080545 },
    { lat: 49.19928, lng: 19.080338 },
    { lat: 49.199553, lng: 19.079875 },
    { lat: 49.199671, lng: 19.079726 },
    { lat: 49.199787, lng: 19.079493 },
    { lat: 49.199862, lng: 19.079387 },
    { lat: 49.199923, lng: 19.079304 },
    { lat: 49.199971, lng: 19.079339 },
    { lat: 49.200211, lng: 19.079362 },
    { lat: 49.200341, lng: 19.079209 },
    { lat: 49.200486, lng: 19.079175 },
    { lat: 49.200646, lng: 19.07922 },
    { lat: 49.200807, lng: 19.079332 },
    { lat: 49.201168, lng: 19.079368 },
    { lat: 49.201477, lng: 19.079293 },
    { lat: 49.201642, lng: 19.079238 },
    { lat: 49.201823, lng: 19.07904 },
    { lat: 49.201982, lng: 19.078927 },
    { lat: 49.202171, lng: 19.078937 },
    { lat: 49.202521, lng: 19.078844 },
    { lat: 49.202676, lng: 19.078829 },
    { lat: 49.20283, lng: 19.078712 },
    { lat: 49.202998, lng: 19.07867 },
    { lat: 49.203244, lng: 19.078614 },
    { lat: 49.203785, lng: 19.078583 },
    { lat: 49.204178, lng: 19.078586 },
    { lat: 49.204489, lng: 19.078496 },
    { lat: 49.204711, lng: 19.078476 },
    { lat: 49.204836, lng: 19.078452 },
    { lat: 49.205062, lng: 19.078337 },
    { lat: 49.205355, lng: 19.078185 },
    { lat: 49.205668, lng: 19.077875 },
    { lat: 49.20579, lng: 19.077748 },
    { lat: 49.205984, lng: 19.077594 },
    { lat: 49.206173, lng: 19.077564 },
    { lat: 49.206702, lng: 19.077641 },
    { lat: 49.207028, lng: 19.077655 },
    { lat: 49.207471, lng: 19.07779 },
    { lat: 49.207645, lng: 19.077799 },
    { lat: 49.207833, lng: 19.077703 },
    { lat: 49.207983, lng: 19.077626 },
    { lat: 49.208208, lng: 19.07775 },
    { lat: 49.208512, lng: 19.077775 },
    { lat: 49.208799, lng: 19.077582 },
    { lat: 49.209089, lng: 19.077649 },
    { lat: 49.20953, lng: 19.077785 },
    { lat: 49.209787, lng: 19.078026 },
    { lat: 49.209886, lng: 19.078226 },
    { lat: 49.210252, lng: 19.078554 },
    { lat: 49.210571, lng: 19.078909 },
    { lat: 49.210834, lng: 19.079032 },
    { lat: 49.211164, lng: 19.078963 },
    { lat: 49.211332, lng: 19.079013 },
    { lat: 49.211506, lng: 19.078999 },
    { lat: 49.211649, lng: 19.079001 },
    { lat: 49.211805, lng: 19.079129 },
    { lat: 49.211954, lng: 19.079156 },
    { lat: 49.212126, lng: 19.079114 },
    { lat: 49.212257, lng: 19.079169 },
    { lat: 49.212434, lng: 19.079136 },
    { lat: 49.212819, lng: 19.079147 },
    { lat: 49.213006, lng: 19.079249 },
    { lat: 49.213135, lng: 19.07926 },
    { lat: 49.213616, lng: 19.079334 },
    { lat: 49.213846, lng: 19.079527 },
    { lat: 49.214154, lng: 19.079926 },
    { lat: 49.214289, lng: 19.080042 },
    { lat: 49.215155, lng: 19.080379 },
    { lat: 49.215427, lng: 19.080518 },
    { lat: 49.216345, lng: 19.081562 },
    { lat: 49.217058, lng: 19.082473 },
    { lat: 49.218092, lng: 19.083273 },
    { lat: 49.21777, lng: 19.081816 },
    { lat: 49.217217, lng: 19.079802 },
    { lat: 49.216426, lng: 19.078026 },
    { lat: 49.215443, lng: 19.076544 },
    { lat: 49.214707, lng: 19.075861 },
    { lat: 49.214174, lng: 19.075172 },
    { lat: 49.21371, lng: 19.074048 },
    { lat: 49.213288, lng: 19.073169 },
    { lat: 49.213109, lng: 19.072814 },
    { lat: 49.212918, lng: 19.072146 },
    { lat: 49.212818, lng: 19.070684 },
    { lat: 49.212649, lng: 19.070205 },
    { lat: 49.212536, lng: 19.069333 },
    { lat: 49.212588, lng: 19.067474 },
    { lat: 49.212375, lng: 19.065738 },
    { lat: 49.211457, lng: 19.064271 },
    { lat: 49.211024, lng: 19.063348 },
    { lat: 49.210635, lng: 19.06344 },
    { lat: 49.209784, lng: 19.063166 },
    { lat: 49.209095, lng: 19.06335 },
    { lat: 49.208629, lng: 19.063552 },
    { lat: 49.208428, lng: 19.063919 },
    { lat: 49.207285, lng: 19.065143 },
    { lat: 49.206751, lng: 19.065641 },
    { lat: 49.206061, lng: 19.065952 },
    { lat: 49.205734, lng: 19.066086 },
    { lat: 49.205488, lng: 19.066039 },
    { lat: 49.205384, lng: 19.066019 },
    { lat: 49.205127, lng: 19.065841 },
    { lat: 49.204888, lng: 19.065533 },
    { lat: 49.203679, lng: 19.065249 },
    { lat: 49.202856, lng: 19.065242 },
    { lat: 49.20151, lng: 19.065454 },
    { lat: 49.200786, lng: 19.065174 },
    { lat: 49.199989, lng: 19.065015 },
    { lat: 49.198394, lng: 19.064222 },
    { lat: 49.197453, lng: 19.063836 },
    { lat: 49.196579, lng: 19.063309 },
    { lat: 49.196075, lng: 19.063131 },
    { lat: 49.194573, lng: 19.061962 },
    { lat: 49.193886, lng: 19.061594 },
    { lat: 49.192212, lng: 19.061401 },
    { lat: 49.191709, lng: 19.060704 },
    { lat: 49.191318, lng: 19.060714 },
    { lat: 49.191162, lng: 19.060463 },
    { lat: 49.19095, lng: 19.059399 },
    { lat: 49.19056, lng: 19.058263 },
    { lat: 49.190071, lng: 19.056126 },
    { lat: 49.190008, lng: 19.055393 },
    { lat: 49.189811, lng: 19.054974 },
    { lat: 49.189092, lng: 19.053972 },
    { lat: 49.189046, lng: 19.053188 },
    { lat: 49.189094, lng: 19.052594 },
    { lat: 49.188181, lng: 19.052104 },
    { lat: 49.187898, lng: 19.051615 },
    { lat: 49.188158, lng: 19.051324 },
    { lat: 49.188628, lng: 19.05019 },
    { lat: 49.189048, lng: 19.049175 },
    { lat: 49.188969, lng: 19.047664 },
    { lat: 49.189199, lng: 19.045139 },
    { lat: 49.189255, lng: 19.044956 },
    { lat: 49.189531, lng: 19.044273 },
    { lat: 49.190048, lng: 19.041988 },
    { lat: 49.190675, lng: 19.040891 },
    { lat: 49.191247, lng: 19.039497 },
    { lat: 49.191626, lng: 19.037941 },
    { lat: 49.191977, lng: 19.0365 },
    { lat: 49.1899701, lng: 19.037441 },
    { lat: 49.188274, lng: 19.038877 },
    { lat: 49.1880608, lng: 19.0398963 },
    { lat: 49.1877411, lng: 19.0408343 },
    { lat: 49.1872701, lng: 19.041248 },
    { lat: 49.186563, lng: 19.041399 },
    { lat: 49.186491, lng: 19.0414306 },
    { lat: 49.1861635, lng: 19.0415745 },
    { lat: 49.1859268, lng: 19.0415969 },
    { lat: 49.1857475, lng: 19.0417304 },
    { lat: 49.1855399, lng: 19.0417735 },
    { lat: 49.1852331, lng: 19.041952 },
    { lat: 49.1848582, lng: 19.042279 },
    { lat: 49.184458, lng: 19.0425245 },
    { lat: 49.1834735, lng: 19.0432686 },
    { lat: 49.1830433, lng: 19.0435053 },
    { lat: 49.1827005, lng: 19.0439327 },
    { lat: 49.1824029, lng: 19.0438615 },
    { lat: 49.1822347, lng: 19.0437008 },
    { lat: 49.1820413, lng: 19.0436201 },
    { lat: 49.181777, lng: 19.0437011 },
    { lat: 49.1811935, lng: 19.0435717 },
    { lat: 49.1809067, lng: 19.0436532 },
    { lat: 49.1805677, lng: 19.0434102 },
    { lat: 49.1800023, lng: 19.0431689 },
    { lat: 49.1797402, lng: 19.0430244 },
    { lat: 49.1792171, lng: 19.0430229 },
    { lat: 49.1789329, lng: 19.0428169 },
    { lat: 49.1788787, lng: 19.0428145 },
    { lat: 49.1779966, lng: 19.0424142 },
    { lat: 49.1776109, lng: 19.0419586 },
    { lat: 49.1774456, lng: 19.0418743 },
    { lat: 49.1771903, lng: 19.0416851 },
    { lat: 49.1766035, lng: 19.0415573 },
    { lat: 49.1764093, lng: 19.0414371 },
    { lat: 49.1755876, lng: 19.0416009 },
    { lat: 49.1749969, lng: 19.0419401 },
    { lat: 49.1737532, lng: 19.043338 },
    { lat: 49.1734989, lng: 19.0435782 },
    { lat: 49.1728717, lng: 19.0441814 },
    { lat: 49.1726723, lng: 19.0443669 },
    { lat: 49.1723426, lng: 19.0449 },
    { lat: 49.1718438, lng: 19.0457718 },
    { lat: 49.1714233, lng: 19.0460664 },
    { lat: 49.1711993, lng: 19.0460743 },
    { lat: 49.1708461, lng: 19.0462696 },
    { lat: 49.1705347, lng: 19.0467621 },
    { lat: 49.1695732, lng: 19.0471274 },
    { lat: 49.1694954, lng: 19.0471076 },
    { lat: 49.1688499, lng: 19.0473896 },
    { lat: 49.1679839, lng: 19.046999 },
    { lat: 49.1677577, lng: 19.0469588 },
    { lat: 49.1668593, lng: 19.0468482 },
    { lat: 49.1667294, lng: 19.0469071 },
    { lat: 49.1658265, lng: 19.0473169 },
    { lat: 49.1649634, lng: 19.0479608 },
    { lat: 49.1646085, lng: 19.0482633 },
    { lat: 49.1642466, lng: 19.0484207 },
    { lat: 49.1636992, lng: 19.0491326 },
    { lat: 49.163292, lng: 19.0496033 },
    { lat: 49.1629492, lng: 19.0497174 },
    { lat: 49.1628725, lng: 19.0500576 },
    { lat: 49.1625976, lng: 19.0502392 },
    { lat: 49.1623802, lng: 19.0506079 },
    { lat: 49.1621262, lng: 19.0505313 },
    { lat: 49.1614474, lng: 19.0512558 },
    { lat: 49.1613052, lng: 19.0514991 },
    { lat: 49.1607434, lng: 19.0516738 },
    { lat: 49.1606308, lng: 19.051837 },
    { lat: 49.1603743, lng: 19.0518709 },
    { lat: 49.1603074, lng: 19.051951 },
    { lat: 49.160124, lng: 19.0523025 },
    { lat: 49.1599416, lng: 19.0525544 },
    { lat: 49.1598174, lng: 19.052779 },
    { lat: 49.1592684, lng: 19.0536625 },
    { lat: 49.1582679, lng: 19.0545382 },
    { lat: 49.1577073, lng: 19.0552558 },
    { lat: 49.1574305, lng: 19.0554944 },
    { lat: 49.1572604, lng: 19.0554996 },
    { lat: 49.1569854, lng: 19.0555882 },
    { lat: 49.1565981, lng: 19.0559394 },
    { lat: 49.1564608, lng: 19.056127 },
    { lat: 49.1559341, lng: 19.0564866 },
    { lat: 49.1543922, lng: 19.0569647 },
    { lat: 49.1531397, lng: 19.0572844 },
    { lat: 49.1530444, lng: 19.0574283 },
    { lat: 49.1513132, lng: 19.0579818 },
    { lat: 49.1501647, lng: 19.0586571 },
    { lat: 49.1498935, lng: 19.0588356 },
    { lat: 49.1492643, lng: 19.0593334 },
    { lat: 49.1481752, lng: 19.0601499 },
    { lat: 49.147001, lng: 19.0610409 },
    { lat: 49.1467975, lng: 19.0612591 },
    { lat: 49.1465853, lng: 19.0614564 },
    { lat: 49.1464154, lng: 19.0617374 },
    { lat: 49.1462798, lng: 19.0621858 },
    { lat: 49.1461685, lng: 19.0622854 },
    { lat: 49.146139, lng: 19.0625102 },
    { lat: 49.1461177, lng: 19.0627381 },
    { lat: 49.1460715, lng: 19.0629454 },
    { lat: 49.1460021, lng: 19.0631788 },
    { lat: 49.1459496, lng: 19.0635316 },
    { lat: 49.1458645, lng: 19.0637927 },
    { lat: 49.1459067, lng: 19.0638941 },
    { lat: 49.1458934, lng: 19.0641455 },
    { lat: 49.1459139, lng: 19.0643783 },
    { lat: 49.1460272, lng: 19.0646638 },
    { lat: 49.1460397, lng: 19.064801 },
    { lat: 49.1459713, lng: 19.0651513 },
    { lat: 49.1461146, lng: 19.0653847 },
    { lat: 49.1461385, lng: 19.0657283 },
    { lat: 49.1461195, lng: 19.0661403 },
    { lat: 49.1461645, lng: 19.0664815 },
    { lat: 49.1463084, lng: 19.0667688 },
    { lat: 49.1464511, lng: 19.06685 },
    { lat: 49.1465201, lng: 19.0670621 },
    { lat: 49.1466565, lng: 19.0672459 },
    { lat: 49.1467256, lng: 19.0672721 },
    { lat: 49.1468145, lng: 19.0675449 },
    { lat: 49.1468651, lng: 19.0679046 },
    { lat: 49.1468972, lng: 19.068257 },
    { lat: 49.1468884, lng: 19.0686937 },
    { lat: 49.1468086, lng: 19.0691102 },
    { lat: 49.1468303, lng: 19.0692283 },
    { lat: 49.1469243, lng: 19.0692384 },
    { lat: 49.1469437, lng: 19.0692873 },
    { lat: 49.146934, lng: 19.0693676 },
    { lat: 49.1468063, lng: 19.06945 },
    { lat: 49.1469075, lng: 19.0697876 },
    { lat: 49.1469833, lng: 19.0700194 },
    { lat: 49.1471009, lng: 19.070029 },
    { lat: 49.1471715, lng: 19.0702247 },
    { lat: 49.1472617, lng: 19.0705427 },
    { lat: 49.1472003, lng: 19.0706679 },
    { lat: 49.1469983, lng: 19.0708481 },
    { lat: 49.1469559, lng: 19.0710384 },
    { lat: 49.1469412, lng: 19.0713562 },
    { lat: 49.1468529, lng: 19.0713952 },
    { lat: 49.146802, lng: 19.0715512 },
    { lat: 49.1468807, lng: 19.0720711 },
    { lat: 49.1468051, lng: 19.0723437 },
    { lat: 49.1468166, lng: 19.0725293 },
    { lat: 49.1467952, lng: 19.0728947 },
    { lat: 49.1467791, lng: 19.0730932 },
    { lat: 49.1466504, lng: 19.0733625 },
    { lat: 49.1465275, lng: 19.0735221 },
    { lat: 49.1464318, lng: 19.0737999 },
    { lat: 49.1462934, lng: 19.0737139 },
    { lat: 49.1462674, lng: 19.0737335 },
    { lat: 49.1460692, lng: 19.0740573 },
    { lat: 49.1459773, lng: 19.0743195 },
    { lat: 49.1459104, lng: 19.0743999 },
    { lat: 49.1456792, lng: 19.074582 },
    { lat: 49.1454565, lng: 19.0748658 },
    { lat: 49.1453888, lng: 19.0750687 },
    { lat: 49.1454838, lng: 19.0751637 },
    { lat: 49.1454255, lng: 19.0753796 },
    { lat: 49.1453641, lng: 19.0753661 },
    { lat: 49.1451853, lng: 19.0754248 },
    { lat: 49.145073, lng: 19.075523 },
    { lat: 49.1449848, lng: 19.0756541 },
    { lat: 49.1449231, lng: 19.0758204 },
    { lat: 49.1449498, lng: 19.0758342 },
    { lat: 49.1449707, lng: 19.0758235 },
    { lat: 49.14498, lng: 19.0759201 },
    { lat: 49.1448181, lng: 19.0760182 },
    { lat: 49.1447295, lng: 19.0761674 },
    { lat: 49.1447594, lng: 19.0764084 },
    { lat: 49.1446844, lng: 19.0763965 },
    { lat: 49.1446471, lng: 19.0766892 },
    { lat: 49.1445817, lng: 19.0768029 },
    { lat: 49.1445206, lng: 19.0767963 },
    { lat: 49.1444067, lng: 19.0765699 },
    { lat: 49.1442095, lng: 19.0766613 },
    { lat: 49.144138, lng: 19.0765817 },
    { lat: 49.1441157, lng: 19.0766336 },
    { lat: 49.1441051, lng: 19.0768285 },
    { lat: 49.1440687, lng: 19.0769619 },
    { lat: 49.1439233, lng: 19.077123 },
    { lat: 49.1439019, lng: 19.0772821 },
    { lat: 49.1439001, lng: 19.0774281 },
    { lat: 49.1438147, lng: 19.0777983 },
    { lat: 49.1438665, lng: 19.0778857 },
    { lat: 49.1438109, lng: 19.0781311 },
    { lat: 49.1436984, lng: 19.0782016 },
    { lat: 49.1436907, lng: 19.0782767 },
    { lat: 49.1437045, lng: 19.0783028 },
    { lat: 49.1437298, lng: 19.0783356 },
    { lat: 49.1437151, lng: 19.0785159 },
    { lat: 49.1437773, lng: 19.0787042 },
    { lat: 49.1437979, lng: 19.0788005 },
    { lat: 49.1437585, lng: 19.0788976 },
    { lat: 49.1437536, lng: 19.0789139 },
    { lat: 49.1437026, lng: 19.0790851 },
    { lat: 49.1435233, lng: 19.0790189 },
    { lat: 49.1435192, lng: 19.0791598 },
    { lat: 49.1435818, lng: 19.0791994 },
    { lat: 49.1436223, lng: 19.0793543 },
    { lat: 49.1437791, lng: 19.0795631 },
    { lat: 49.1437664, lng: 19.0796265 },
    { lat: 49.1436536, lng: 19.0796474 },
    { lat: 49.1435588, lng: 19.0797367 },
    { lat: 49.14338, lng: 19.0797655 },
    { lat: 49.1433877, lng: 19.0799227 },
    { lat: 49.1434324, lng: 19.0799615 },
    { lat: 49.1434849, lng: 19.0800938 },
    { lat: 49.1435263, lng: 19.0801981 },
    { lat: 49.1435127, lng: 19.0803098 },
    { lat: 49.1434422, lng: 19.0803892 },
    { lat: 49.1434468, lng: 19.0804589 },
    { lat: 49.1434254, lng: 19.0805287 },
    { lat: 49.1432776, lng: 19.0806392 },
    { lat: 49.1433021, lng: 19.0807488 },
    { lat: 49.1432745, lng: 19.0809158 },
    { lat: 49.1433423, lng: 19.081011 },
    { lat: 49.1433794, lng: 19.0811705 },
    { lat: 49.1433658, lng: 19.0811901 },
    { lat: 49.1432307, lng: 19.0812632 },
    { lat: 49.1431866, lng: 19.0813313 },
    { lat: 49.143144, lng: 19.0815399 },
    { lat: 49.1431071, lng: 19.0815247 },
    { lat: 49.1430793, lng: 19.0815542 },
    { lat: 49.1430522, lng: 19.0816239 },
    { lat: 49.1428825, lng: 19.0815883 },
    { lat: 49.1426578, lng: 19.0815334 },
    { lat: 49.1421143, lng: 19.081415 },
    { lat: 49.1418598, lng: 19.081374 },
    { lat: 49.1416912, lng: 19.0817055 },
    { lat: 49.1414673, lng: 19.0818511 },
    { lat: 49.1411482, lng: 19.0817541 },
    { lat: 49.1410831, lng: 19.0820579 },
    { lat: 49.1409033, lng: 19.0832335 },
    { lat: 49.1408853, lng: 19.0836687 },
    { lat: 49.1408388, lng: 19.0840524 },
    { lat: 49.1408483, lng: 19.0845849 },
    { lat: 49.1409646, lng: 19.0845921 },
    { lat: 49.1409765, lng: 19.0847844 },
    { lat: 49.1404667, lng: 19.0848926 },
    { lat: 49.1402559, lng: 19.0849628 },
    { lat: 49.1400313, lng: 19.0850696 },
    { lat: 49.1402122, lng: 19.0861552 },
    { lat: 49.1402799, lng: 19.086479 },
    { lat: 49.1403842, lng: 19.0870132 },
    { lat: 49.1407982, lng: 19.0869298 },
    { lat: 49.1412942, lng: 19.0868862 },
    { lat: 49.1415397, lng: 19.0869499 },
    { lat: 49.1417913, lng: 19.0869319 },
    { lat: 49.1421417, lng: 19.0870383 },
    { lat: 49.1425478, lng: 19.0872308 },
    { lat: 49.143286, lng: 19.0877957 },
    { lat: 49.1432034, lng: 19.0886837 },
    { lat: 49.1433967, lng: 19.0888538 },
    { lat: 49.1436073, lng: 19.0881434 },
    { lat: 49.1440069, lng: 19.0883133 },
    { lat: 49.1450589, lng: 19.0884552 },
    { lat: 49.1454078, lng: 19.0889921 },
    { lat: 49.1453258, lng: 19.0895956 },
    { lat: 49.1452812, lng: 19.0901278 },
    { lat: 49.1454468, lng: 19.0907213 },
    { lat: 49.1454721, lng: 19.0912117 },
    { lat: 49.1456248, lng: 19.0914059 },
    { lat: 49.1458613, lng: 19.0921534 },
    { lat: 49.1458083, lng: 19.0925443 },
    { lat: 49.1455799, lng: 19.09326 },
    { lat: 49.1464397, lng: 19.093576 },
    { lat: 49.1472821, lng: 19.0939703 },
    { lat: 49.1488801, lng: 19.0949249 },
    { lat: 49.1493942, lng: 19.0953158 },
    { lat: 49.1497777, lng: 19.0957782 },
    { lat: 49.1500716, lng: 19.0962977 },
    { lat: 49.150272, lng: 19.096962 },
  ],
  Žabokreky: [
    { lat: 49.0153843, lng: 18.9300366 },
    { lat: 49.0152006, lng: 18.9302148 },
    { lat: 49.014893, lng: 18.9305314 },
    { lat: 49.0151957, lng: 18.9311834 },
    { lat: 49.0150987, lng: 18.9312734 },
    { lat: 49.0148674, lng: 18.93138 },
    { lat: 49.0144382, lng: 18.9318517 },
    { lat: 49.0142011, lng: 18.9322032 },
    { lat: 49.0140625, lng: 18.9325341 },
    { lat: 49.0140604, lng: 18.9326188 },
    { lat: 49.0140569, lng: 18.9327585 },
    { lat: 49.0140668, lng: 18.9328045 },
    { lat: 49.0141912, lng: 18.9333846 },
    { lat: 49.0143174, lng: 18.9336269 },
    { lat: 49.0143225, lng: 18.9336393 },
    { lat: 49.0143861, lng: 18.9336681 },
    { lat: 49.0145759, lng: 18.9336092 },
    { lat: 49.0147632, lng: 18.9335236 },
    { lat: 49.0150627, lng: 18.9334256 },
    { lat: 49.0153518, lng: 18.933438 },
    { lat: 49.0157161, lng: 18.9333729 },
    { lat: 49.0159329, lng: 18.9332451 },
    { lat: 49.0161506, lng: 18.9330245 },
    { lat: 49.0163378, lng: 18.9329372 },
    { lat: 49.0164296, lng: 18.932615 },
    { lat: 49.0164792, lng: 18.9323915 },
    { lat: 49.0167115, lng: 18.9323452 },
    { lat: 49.0168394, lng: 18.932138 },
    { lat: 49.0169304, lng: 18.9319527 },
    { lat: 49.0171215, lng: 18.9316501 },
    { lat: 49.0172786, lng: 18.9315465 },
    { lat: 49.0174001, lng: 18.9312973 },
    { lat: 49.0174184, lng: 18.9310807 },
    { lat: 49.0174986, lng: 18.9308644 },
    { lat: 49.017627, lng: 18.9307509 },
    { lat: 49.0180077, lng: 18.9310083 },
    { lat: 49.0180619, lng: 18.9310095 },
    { lat: 49.0181616, lng: 18.9308182 },
    { lat: 49.0182884, lng: 18.9304088 },
    { lat: 49.0183767, lng: 18.9303514 },
    { lat: 49.0185232, lng: 18.9303708 },
    { lat: 49.0185341, lng: 18.9304511 },
    { lat: 49.0192564, lng: 18.9312884 },
    { lat: 49.0199398, lng: 18.9320674 },
    { lat: 49.0206389, lng: 18.932934 },
    { lat: 49.0199553, lng: 18.9343505 },
    { lat: 49.0192553, lng: 18.9357284 },
    { lat: 49.0182995, lng: 18.9366646 },
    { lat: 49.0170601, lng: 18.9365252 },
    { lat: 49.0162036, lng: 18.9373351 },
    { lat: 49.0157862, lng: 18.9378154 },
    { lat: 49.0153484, lng: 18.9380758 },
    { lat: 49.0146127, lng: 18.9387307 },
    { lat: 49.014436, lng: 18.9388477 },
    { lat: 49.0146301, lng: 18.9389402 },
    { lat: 49.0148526, lng: 18.939125 },
    { lat: 49.0152234, lng: 18.93957 },
    { lat: 49.0161853, lng: 18.9408842 },
    { lat: 49.0162994, lng: 18.9409535 },
    { lat: 49.017216, lng: 18.942397 },
    { lat: 49.0181802, lng: 18.9439356 },
    { lat: 49.0188941, lng: 18.945589 },
    { lat: 49.0189861, lng: 18.9461124 },
    { lat: 49.019889, lng: 18.9474876 },
    { lat: 49.0202704, lng: 18.9482724 },
    { lat: 49.0203093, lng: 18.9488638 },
    { lat: 49.0205408, lng: 18.9496503 },
    { lat: 49.0202253, lng: 18.950457 },
    { lat: 49.0213884, lng: 18.9519826 },
    { lat: 49.0221383, lng: 18.9534812 },
    { lat: 49.0225369, lng: 18.9540701 },
    { lat: 49.0233945, lng: 18.9542679 },
    { lat: 49.0238538, lng: 18.9543611 },
    { lat: 49.0243367, lng: 18.9547012 },
    { lat: 49.0247218, lng: 18.9551178 },
    { lat: 49.0253226, lng: 18.9554261 },
    { lat: 49.0264071, lng: 18.9559147 },
    { lat: 49.0270828, lng: 18.9562128 },
    { lat: 49.0275062, lng: 18.9561353 },
    { lat: 49.0284576, lng: 18.9551309 },
    { lat: 49.0292802, lng: 18.9546304 },
    { lat: 49.0300786, lng: 18.954301 },
    { lat: 49.03057, lng: 18.9542309 },
    { lat: 49.0307695, lng: 18.9541623 },
    { lat: 49.0310174, lng: 18.9542069 },
    { lat: 49.031294, lng: 18.9544803 },
    { lat: 49.0315821, lng: 18.9538487 },
    { lat: 49.0323262, lng: 18.9523751 },
    { lat: 49.0324602, lng: 18.9519886 },
    { lat: 49.0323142, lng: 18.9500896 },
    { lat: 49.0330679, lng: 18.9471197 },
    { lat: 49.0343308, lng: 18.9418723 },
    { lat: 49.0343498, lng: 18.9417985 },
    { lat: 49.0342513, lng: 18.941791 },
    { lat: 49.0346168, lng: 18.9403274 },
    { lat: 49.0352262, lng: 18.9370515 },
    { lat: 49.0352236, lng: 18.9370457 },
    { lat: 49.0352216, lng: 18.9370258 },
    { lat: 49.0352168, lng: 18.9370012 },
    { lat: 49.0352233, lng: 18.9368585 },
    { lat: 49.0352014, lng: 18.9363149 },
    { lat: 49.0352087, lng: 18.9359285 },
    { lat: 49.0352697, lng: 18.9352217 },
    { lat: 49.0354062, lng: 18.934266 },
    { lat: 49.0354155, lng: 18.9341374 },
    { lat: 49.0354602, lng: 18.9338181 },
    { lat: 49.035546, lng: 18.933288 },
    { lat: 49.035573, lng: 18.9330897 },
    { lat: 49.0356171, lng: 18.9328693 },
    { lat: 49.0356762, lng: 18.9324786 },
    { lat: 49.0356982, lng: 18.9322881 },
    { lat: 49.0357783, lng: 18.9321109 },
    { lat: 49.0357778, lng: 18.9318792 },
    { lat: 49.0358231, lng: 18.9317053 },
    { lat: 49.035876, lng: 18.9315521 },
    { lat: 49.0360253, lng: 18.9312259 },
    { lat: 49.0360923, lng: 18.9311264 },
    { lat: 49.0363501, lng: 18.9308708 },
    { lat: 49.0364162, lng: 18.9308182 },
    { lat: 49.0364766, lng: 18.9307454 },
    { lat: 49.0365144, lng: 18.9306716 },
    { lat: 49.0363738, lng: 18.9303655 },
    { lat: 49.0362366, lng: 18.9300369 },
    { lat: 49.0361461, lng: 18.9297435 },
    { lat: 49.0361283, lng: 18.929723 },
    { lat: 49.0359821, lng: 18.9293326 },
    { lat: 49.0359025, lng: 18.9291663 },
    { lat: 49.0360723, lng: 18.9290015 },
    { lat: 49.0362719, lng: 18.9287786 },
    { lat: 49.0363015, lng: 18.9287194 },
    { lat: 49.0366766, lng: 18.9279341 },
    { lat: 49.0366608, lng: 18.9278657 },
    { lat: 49.0366681, lng: 18.9278468 },
    { lat: 49.0367802, lng: 18.9276221 },
    { lat: 49.0369049, lng: 18.9272582 },
    { lat: 49.0370362, lng: 18.9269366 },
    { lat: 49.037115, lng: 18.926711 },
    { lat: 49.037102, lng: 18.9266978 },
    { lat: 49.03719, lng: 18.9263659 },
    { lat: 49.0372799, lng: 18.9259395 },
    { lat: 49.0372896, lng: 18.9258425 },
    { lat: 49.0369069, lng: 18.9254994 },
    { lat: 49.0367632, lng: 18.9253362 },
    { lat: 49.0367058, lng: 18.9252251 },
    { lat: 49.0366373, lng: 18.9250111 },
    { lat: 49.0365804, lng: 18.9250203 },
    { lat: 49.0364915, lng: 18.9248899 },
    { lat: 49.0364819, lng: 18.9248591 },
    { lat: 49.0363403, lng: 18.9246439 },
    { lat: 49.0361997, lng: 18.9244535 },
    { lat: 49.0360703, lng: 18.9243013 },
    { lat: 49.035714, lng: 18.9237244 },
    { lat: 49.0355433, lng: 18.9234523 },
    { lat: 49.0354753, lng: 18.9233561 },
    { lat: 49.0353939, lng: 18.9231533 },
    { lat: 49.0352209, lng: 18.922966 },
    { lat: 49.0350889, lng: 18.9227857 },
    { lat: 49.0349437, lng: 18.9225707 },
    { lat: 49.0347464, lng: 18.9222992 },
    { lat: 49.0345464, lng: 18.9220507 },
    { lat: 49.0342325, lng: 18.9216213 },
    { lat: 49.0338497, lng: 18.9211194 },
    { lat: 49.0337062, lng: 18.9209117 },
    { lat: 49.0337757, lng: 18.9207501 },
    { lat: 49.0339012, lng: 18.9205385 },
    { lat: 49.0338487, lng: 18.9203937 },
    { lat: 49.0340636, lng: 18.9201834 },
    { lat: 49.0341162, lng: 18.9201111 },
    { lat: 49.0341609, lng: 18.9200142 },
    { lat: 49.0342391, lng: 18.9198896 },
    { lat: 49.0342576, lng: 18.9198763 },
    { lat: 49.0342966, lng: 18.9197981 },
    { lat: 49.0344087, lng: 18.9196512 },
    { lat: 49.034416, lng: 18.9195533 },
    { lat: 49.0344659, lng: 18.9193829 },
    { lat: 49.0346314, lng: 18.9188887 },
    { lat: 49.0347174, lng: 18.9184984 },
    { lat: 49.0347451, lng: 18.9184855 },
    { lat: 49.0347919, lng: 18.918366 },
    { lat: 49.0348064, lng: 18.9182968 },
    { lat: 49.034921, lng: 18.9180246 },
    { lat: 49.0351796, lng: 18.9182313 },
    { lat: 49.0353432, lng: 18.9183863 },
    { lat: 49.0356078, lng: 18.9179368 },
    { lat: 49.0357651, lng: 18.9177494 },
    { lat: 49.0357886, lng: 18.9176996 },
    { lat: 49.0359103, lng: 18.9175254 },
    { lat: 49.0357622, lng: 18.9173164 },
    { lat: 49.0356662, lng: 18.917115 },
    { lat: 49.035444, lng: 18.9168907 },
    { lat: 49.0354799, lng: 18.9167733 },
    { lat: 49.0354354, lng: 18.9166564 },
    { lat: 49.0359963, lng: 18.9160678 },
    { lat: 49.036229, lng: 18.9158115 },
    { lat: 49.0362222, lng: 18.91579 },
    { lat: 49.0364512, lng: 18.915504 },
    { lat: 49.0367136, lng: 18.9151863 },
    { lat: 49.0373788, lng: 18.9143673 },
    { lat: 49.0380916, lng: 18.9134873 },
    { lat: 49.038574, lng: 18.9129105 },
    { lat: 49.0389901, lng: 18.9124018 },
    { lat: 49.0392094, lng: 18.9121471 },
    { lat: 49.0394897, lng: 18.9118287 },
    { lat: 49.0397858, lng: 18.9114642 },
    { lat: 49.0399875, lng: 18.9112223 },
    { lat: 49.0404022, lng: 18.9107084 },
    { lat: 49.0405796, lng: 18.9104941 },
    { lat: 49.0406162, lng: 18.9104801 },
    { lat: 49.0372382, lng: 18.9091517 },
    { lat: 49.0358335, lng: 18.9137347 },
    { lat: 49.0338209, lng: 18.9115432 },
    { lat: 49.032762, lng: 18.9103892 },
    { lat: 49.0326232, lng: 18.9104322 },
    { lat: 49.0320517, lng: 18.9100353 },
    { lat: 49.0318712, lng: 18.9103631 },
    { lat: 49.0314062, lng: 18.9109989 },
    { lat: 49.0312598, lng: 18.9111577 },
    { lat: 49.0310559, lng: 18.9112271 },
    { lat: 49.0308452, lng: 18.9112267 },
    { lat: 49.03069, lng: 18.9111908 },
    { lat: 49.0305293, lng: 18.9113991 },
    { lat: 49.0303895, lng: 18.9115992 },
    { lat: 49.0302507, lng: 18.9117743 },
    { lat: 49.0300339, lng: 18.9121003 },
    { lat: 49.0299406, lng: 18.9122812 },
    { lat: 49.029389, lng: 18.9117681 },
    { lat: 49.0293261, lng: 18.9116835 },
    { lat: 49.029161, lng: 18.9121837 },
    { lat: 49.0291038, lng: 18.9123654 },
    { lat: 49.0291097, lng: 18.9123705 },
    { lat: 49.0289808, lng: 18.9127845 },
    { lat: 49.0287174, lng: 18.913569 },
    { lat: 49.0283514, lng: 18.9132428 },
    { lat: 49.0282808, lng: 18.913365 },
    { lat: 49.0280836, lng: 18.9136296 },
    { lat: 49.0279535, lng: 18.9137754 },
    { lat: 49.0277928, lng: 18.9139003 },
    { lat: 49.0277582, lng: 18.9139719 },
    { lat: 49.0275799, lng: 18.9140576 },
    { lat: 49.0274372, lng: 18.9141549 },
    { lat: 49.027422, lng: 18.9141024 },
    { lat: 49.0272993, lng: 18.9140583 },
    { lat: 49.0271196, lng: 18.914562 },
    { lat: 49.0266562, lng: 18.9141847 },
    { lat: 49.0264463, lng: 18.9146456 },
    { lat: 49.0262129, lng: 18.9144892 },
    { lat: 49.026129, lng: 18.9145483 },
    { lat: 49.0260956, lng: 18.9146208 },
    { lat: 49.0260042, lng: 18.9146636 },
    { lat: 49.0259111, lng: 18.9147424 },
    { lat: 49.0258919, lng: 18.9151572 },
    { lat: 49.0258452, lng: 18.9152848 },
    { lat: 49.0258195, lng: 18.9153313 },
    { lat: 49.0256583, lng: 18.9152655 },
    { lat: 49.0255524, lng: 18.9153117 },
    { lat: 49.0254311, lng: 18.9154105 },
    { lat: 49.0248476, lng: 18.9165257 },
    { lat: 49.024642, lng: 18.9168072 },
    { lat: 49.0246164, lng: 18.9168795 },
    { lat: 49.0246038, lng: 18.9169435 },
    { lat: 49.0246141, lng: 18.9171683 },
    { lat: 49.0246353, lng: 18.9173396 },
    { lat: 49.0245903, lng: 18.9175178 },
    { lat: 49.0245289, lng: 18.9175735 },
    { lat: 49.02443, lng: 18.9175582 },
    { lat: 49.0241286, lng: 18.9171963 },
    { lat: 49.0241612, lng: 18.9170621 },
    { lat: 49.0240821, lng: 18.9168594 },
    { lat: 49.0240018, lng: 18.9168136 },
    { lat: 49.0238611, lng: 18.9169505 },
    { lat: 49.0235896, lng: 18.9173708 },
    { lat: 49.0235528, lng: 18.9175357 },
    { lat: 49.0235658, lng: 18.9177246 },
    { lat: 49.0236626, lng: 18.9180311 },
    { lat: 49.0238593, lng: 18.9184182 },
    { lat: 49.0238831, lng: 18.9184866 },
    { lat: 49.023907, lng: 18.9186454 },
    { lat: 49.0239142, lng: 18.9187671 },
    { lat: 49.0238537, lng: 18.9189298 },
    { lat: 49.0238203, lng: 18.9192908 },
    { lat: 49.0237512, lng: 18.9193711 },
    { lat: 49.0236486, lng: 18.919464 },
    { lat: 49.0235901, lng: 18.9195295 },
    { lat: 49.0234464, lng: 18.9196549 },
    { lat: 49.0232761, lng: 18.9199429 },
    { lat: 49.0232823, lng: 18.9201057 },
    { lat: 49.0233016, lng: 18.9201979 },
    { lat: 49.0233513, lng: 18.9203573 },
    { lat: 49.0233716, lng: 18.9203987 },
    { lat: 49.0233634, lng: 18.9204604 },
    { lat: 49.0233532, lng: 18.9204831 },
    { lat: 49.0230841, lng: 18.9205759 },
    { lat: 49.0228955, lng: 18.9206574 },
    { lat: 49.0228266, lng: 18.9206554 },
    { lat: 49.0227723, lng: 18.9210508 },
    { lat: 49.0227494, lng: 18.9211771 },
    { lat: 49.0226127, lng: 18.9213085 },
    { lat: 49.0224804, lng: 18.9212759 },
    { lat: 49.0223698, lng: 18.9212049 },
    { lat: 49.0223249, lng: 18.9212105 },
    { lat: 49.0222925, lng: 18.9212607 },
    { lat: 49.0222742, lng: 18.9213239 },
    { lat: 49.0222584, lng: 18.9215891 },
    { lat: 49.0222652, lng: 18.9217862 },
    { lat: 49.0222971, lng: 18.922196 },
    { lat: 49.0222675, lng: 18.9223654 },
    { lat: 49.0221146, lng: 18.9225735 },
    { lat: 49.0220339, lng: 18.9225811 },
    { lat: 49.0219295, lng: 18.9226139 },
    { lat: 49.0218935, lng: 18.922681 },
    { lat: 49.0218279, lng: 18.922878 },
    { lat: 49.021823, lng: 18.9230934 },
    { lat: 49.021808, lng: 18.923152 },
    { lat: 49.0217733, lng: 18.9231798 },
    { lat: 49.0215598, lng: 18.9232598 },
    { lat: 49.0214449, lng: 18.9233276 },
    { lat: 49.0213204, lng: 18.9234317 },
    { lat: 49.0212724, lng: 18.9235045 },
    { lat: 49.0211671, lng: 18.9235391 },
    { lat: 49.0211383, lng: 18.9235716 },
    { lat: 49.0211133, lng: 18.9236374 },
    { lat: 49.0211096, lng: 18.9237169 },
    { lat: 49.0211288, lng: 18.923872 },
    { lat: 49.0211244, lng: 18.9241204 },
    { lat: 49.0211071, lng: 18.9242216 },
    { lat: 49.021051, lng: 18.9243812 },
    { lat: 49.0209704, lng: 18.9245071 },
    { lat: 49.0208113, lng: 18.9246569 },
    { lat: 49.0206438, lng: 18.924733 },
    { lat: 49.0204945, lng: 18.9247692 },
    { lat: 49.0203809, lng: 18.9249051 },
    { lat: 49.0203678, lng: 18.9249594 },
    { lat: 49.020433, lng: 18.9250466 },
    { lat: 49.0205221, lng: 18.9250659 },
    { lat: 49.0206147, lng: 18.9252235 },
    { lat: 49.0206282, lng: 18.9253125 },
    { lat: 49.0205979, lng: 18.9254748 },
    { lat: 49.0205061, lng: 18.925662 },
    { lat: 49.0204742, lng: 18.9257465 },
    { lat: 49.0204503, lng: 18.9258514 },
    { lat: 49.0204245, lng: 18.9258964 },
    { lat: 49.0204079, lng: 18.9259476 },
    { lat: 49.0203026, lng: 18.9261166 },
    { lat: 49.0202126, lng: 18.926143 },
    { lat: 49.0201991, lng: 18.9261891 },
    { lat: 49.0202041, lng: 18.9262417 },
    { lat: 49.0202758, lng: 18.9264337 },
    { lat: 49.0203434, lng: 18.9265659 },
    { lat: 49.0203212, lng: 18.9265936 },
    { lat: 49.0188864, lng: 18.9279779 },
    { lat: 49.0183206, lng: 18.9282477 },
    { lat: 49.0178487, lng: 18.9270387 },
    { lat: 49.017642, lng: 18.9273629 },
    { lat: 49.0174533, lng: 18.9276861 },
    { lat: 49.0170515, lng: 18.9282552 },
    { lat: 49.0166983, lng: 18.9286495 },
    { lat: 49.016433, lng: 18.9288646 },
    { lat: 49.0161827, lng: 18.9292607 },
    { lat: 49.0153843, lng: 18.9300366 },
  ],
  KláštorpodZnievom: [
    { lat: 48.9761488, lng: 18.8069562 },
    { lat: 48.979723, lng: 18.8077764 },
    { lat: 48.9797111, lng: 18.8079555 },
    { lat: 48.9811242, lng: 18.8082672 },
    { lat: 48.9808219, lng: 18.8089564 },
    { lat: 48.9805995, lng: 18.8094533 },
    { lat: 48.9803303, lng: 18.8099616 },
    { lat: 48.9802252, lng: 18.810131 },
    { lat: 48.9801466, lng: 18.8103095 },
    { lat: 48.9800111, lng: 18.8107163 },
    { lat: 48.9812149, lng: 18.811317 },
    { lat: 48.9814385, lng: 18.8112131 },
    { lat: 48.9814688, lng: 18.8110368 },
    { lat: 48.9813688, lng: 18.8107842 },
    { lat: 48.9818029, lng: 18.8099804 },
    { lat: 48.9827414, lng: 18.8102241 },
    { lat: 48.9837935, lng: 18.8111152 },
    { lat: 48.9841102, lng: 18.8114815 },
    { lat: 48.9842739, lng: 18.8112702 },
    { lat: 48.9843063, lng: 18.811025 },
    { lat: 48.9854294, lng: 18.8122652 },
    { lat: 48.9863319, lng: 18.8114882 },
    { lat: 48.9868038, lng: 18.8115028 },
    { lat: 48.9869955, lng: 18.8112156 },
    { lat: 48.9873035, lng: 18.8110236 },
    { lat: 48.9873891, lng: 18.8105871 },
    { lat: 48.9879538, lng: 18.8103001 },
    { lat: 48.9885036, lng: 18.8096379 },
    { lat: 48.9890844, lng: 18.8090545 },
    { lat: 48.9895942, lng: 18.8091442 },
    { lat: 48.9900582, lng: 18.8093066 },
    { lat: 48.990331, lng: 18.8096742 },
    { lat: 48.9904903, lng: 18.809965 },
    { lat: 48.9909351, lng: 18.8095386 },
    { lat: 48.9917066, lng: 18.8091295 },
    { lat: 48.9922566, lng: 18.8089501 },
    { lat: 48.9926198, lng: 18.8087148 },
    { lat: 48.9927682, lng: 18.8082913 },
    { lat: 48.9934686, lng: 18.8066446 },
    { lat: 48.9939823, lng: 18.8070032 },
    { lat: 48.9942952, lng: 18.8072784 },
    { lat: 48.9943811, lng: 18.80702 },
    { lat: 48.9948313, lng: 18.8062432 },
    { lat: 48.9950582, lng: 18.8060311 },
    { lat: 48.9953055, lng: 18.8055382 },
    { lat: 48.9962902, lng: 18.8043934 },
    { lat: 48.9963511, lng: 18.8042421 },
    { lat: 48.9964695, lng: 18.8042416 },
    { lat: 48.9971227, lng: 18.8034885 },
    { lat: 48.9973637, lng: 18.8034376 },
    { lat: 48.998073, lng: 18.8033102 },
    { lat: 48.9981428, lng: 18.8025247 },
    { lat: 48.9984932, lng: 18.8013909 },
    { lat: 48.9986682, lng: 18.8013717 },
    { lat: 48.9989168, lng: 18.8015132 },
    { lat: 48.9990003, lng: 18.8012741 },
    { lat: 48.9995699, lng: 18.7999128 },
    { lat: 48.999523, lng: 18.7989876 },
    { lat: 48.9996925, lng: 18.797562 },
    { lat: 48.9997589, lng: 18.7970398 },
    { lat: 48.9997744, lng: 18.7956693 },
    { lat: 48.9997703, lng: 18.7945677 },
    { lat: 48.9996065, lng: 18.7930467 },
    { lat: 48.9994559, lng: 18.7911916 },
    { lat: 48.9991543, lng: 18.7900542 },
    { lat: 48.9986242, lng: 18.7893405 },
    { lat: 48.9986705, lng: 18.7890182 },
    { lat: 48.9987106, lng: 18.7887677 },
    { lat: 48.9988226, lng: 18.7885819 },
    { lat: 48.999447, lng: 18.7883164 },
    { lat: 48.9997921, lng: 18.7878181 },
    { lat: 49.000051, lng: 18.7870274 },
    { lat: 49.0000933, lng: 18.7868004 },
    { lat: 49.000165, lng: 18.7863579 },
    { lat: 49.0003356, lng: 18.7857373 },
    { lat: 49.0005267, lng: 18.7852496 },
    { lat: 49.0007108, lng: 18.7852368 },
    { lat: 49.0007275, lng: 18.7851223 },
    { lat: 49.0005984, lng: 18.7850258 },
    { lat: 49.0006487, lng: 18.7847343 },
    { lat: 49.0007231, lng: 18.7842168 },
    { lat: 49.0005877, lng: 18.7841713 },
    { lat: 49.0007958, lng: 18.7833166 },
    { lat: 49.0006178, lng: 18.7825006 },
    { lat: 49.0004842, lng: 18.7821859 },
    { lat: 49.0004489, lng: 18.7821783 },
    { lat: 49.0003456, lng: 18.7819465 },
    { lat: 49.0005519, lng: 18.7801066 },
    { lat: 49.0004554, lng: 18.7797286 },
    { lat: 49.0003263, lng: 18.7791971 },
    { lat: 49.0003332, lng: 18.7788138 },
    { lat: 49.0004053, lng: 18.7784417 },
    { lat: 49.0004255, lng: 18.7784403 },
    { lat: 49.0005217, lng: 18.7784905 },
    { lat: 49.0007055, lng: 18.7786673 },
    { lat: 49.0008805, lng: 18.7789727 },
    { lat: 49.0009342, lng: 18.7788553 },
    { lat: 49.0009455, lng: 18.778814 },
    { lat: 49.0010383, lng: 18.7786053 },
    { lat: 49.001506, lng: 18.7789455 },
    { lat: 49.0015347, lng: 18.7788023 },
    { lat: 49.0012936, lng: 18.778502 },
    { lat: 49.0011145, lng: 18.778163 },
    { lat: 49.0008806, lng: 18.7779592 },
    { lat: 49.0007542, lng: 18.7777608 },
    { lat: 49.0004334, lng: 18.7777085 },
    { lat: 49.0003777, lng: 18.7773507 },
    { lat: 49.0004766, lng: 18.7766331 },
    { lat: 49.0004574, lng: 18.7764602 },
    { lat: 49.0004342, lng: 18.7763383 },
    { lat: 49.000499, lng: 18.7758942 },
    { lat: 49.0003453, lng: 18.7757608 },
    { lat: 49.0002249, lng: 18.7758726 },
    { lat: 49.0000713, lng: 18.7758678 },
    { lat: 49.0005455, lng: 18.7751868 },
    { lat: 49.0006095, lng: 18.7749651 },
    { lat: 49.000834, lng: 18.7752052 },
    { lat: 49.0009599, lng: 18.775241 },
    { lat: 49.0010192, lng: 18.7750184 },
    { lat: 49.0013307, lng: 18.7741131 },
    { lat: 49.0016512, lng: 18.7735354 },
    { lat: 49.0013588, lng: 18.773032 },
    { lat: 49.0013164, lng: 18.7722197 },
    { lat: 49.0011545, lng: 18.771861 },
    { lat: 49.0013404, lng: 18.7713175 },
    { lat: 49.0008753, lng: 18.7706183 },
    { lat: 49.0004437, lng: 18.7693085 },
    { lat: 49.0003567, lng: 18.7693258 },
    { lat: 49.0003632, lng: 18.7695376 },
    { lat: 49.0001914, lng: 18.7696144 },
    { lat: 49.000145, lng: 18.7693309 },
    { lat: 49.0001085, lng: 18.7690624 },
    { lat: 49.0001595, lng: 18.7689851 },
    { lat: 48.9999944, lng: 18.7685647 },
    { lat: 48.9999511, lng: 18.7682119 },
    { lat: 48.9996356, lng: 18.7677633 },
    { lat: 48.9995815, lng: 18.7677411 },
    { lat: 48.9992486, lng: 18.7672178 },
    { lat: 48.9992285, lng: 18.766832 },
    { lat: 48.9995016, lng: 18.7664292 },
    { lat: 48.9996277, lng: 18.7665084 },
    { lat: 48.9995346, lng: 18.7662363 },
    { lat: 48.9995124, lng: 18.7658985 },
    { lat: 48.9996268, lng: 18.7656954 },
    { lat: 48.9997992, lng: 18.7657568 },
    { lat: 48.9998148, lng: 18.7655125 },
    { lat: 48.999698, lng: 18.765137 },
    { lat: 48.9999568, lng: 18.765147 },
    { lat: 49.0002928, lng: 18.7657522 },
    { lat: 49.0007775, lng: 18.7662863 },
    { lat: 49.0009161, lng: 18.7661977 },
    { lat: 49.0009989, lng: 18.7659686 },
    { lat: 49.0010354, lng: 18.7651978 },
    { lat: 49.0011783, lng: 18.7648498 },
    { lat: 49.0011914, lng: 18.7647555 },
    { lat: 49.0010748, lng: 18.7646364 },
    { lat: 49.001108, lng: 18.764498 },
    { lat: 49.0012312, lng: 18.764546 },
    { lat: 49.0013004, lng: 18.7643595 },
    { lat: 49.0012466, lng: 18.764171 },
    { lat: 49.0010551, lng: 18.7640476 },
    { lat: 49.0011808, lng: 18.7635583 },
    { lat: 49.0013821, lng: 18.7636586 },
    { lat: 49.0015683, lng: 18.7640693 },
    { lat: 49.0016519, lng: 18.7641594 },
    { lat: 49.0017974, lng: 18.7642036 },
    { lat: 49.0018389, lng: 18.7639163 },
    { lat: 49.0021632, lng: 18.7639317 },
    { lat: 49.0022072, lng: 18.7636301 },
    { lat: 49.002509, lng: 18.7635581 },
    { lat: 49.0025231, lng: 18.7633948 },
    { lat: 49.0021798, lng: 18.762974 },
    { lat: 49.00195, lng: 18.7629993 },
    { lat: 49.0019196, lng: 18.762892 },
    { lat: 49.0017108, lng: 18.7629129 },
    { lat: 49.0015256, lng: 18.7628228 },
    { lat: 49.0012015, lng: 18.7623832 },
    { lat: 49.0014065, lng: 18.761318 },
    { lat: 49.0007369, lng: 18.7605704 },
    { lat: 49.0010397, lng: 18.7595687 },
    { lat: 49.0013145, lng: 18.7591541 },
    { lat: 49.0013507, lng: 18.7591321 },
    { lat: 49.0013613, lng: 18.759111 },
    { lat: 49.0017192, lng: 18.7583977 },
    { lat: 49.0028616, lng: 18.7594125 },
    { lat: 49.0029474, lng: 18.759475 },
    { lat: 49.0031555, lng: 18.7594473 },
    { lat: 49.0032917, lng: 18.7591823 },
    { lat: 49.0033238, lng: 18.7588689 },
    { lat: 49.0034047, lng: 18.7587136 },
    { lat: 49.0034536, lng: 18.7583967 },
    { lat: 49.0032325, lng: 18.7582862 },
    { lat: 49.0029693, lng: 18.7580831 },
    { lat: 49.0029733, lng: 18.7577935 },
    { lat: 49.002721, lng: 18.7571136 },
    { lat: 49.0027706, lng: 18.7568964 },
    { lat: 49.0028542, lng: 18.7569179 },
    { lat: 49.0029061, lng: 18.7565539 },
    { lat: 49.0030519, lng: 18.7559591 },
    { lat: 49.0032155, lng: 18.7557213 },
    { lat: 49.0028029, lng: 18.7549717 },
    { lat: 49.0024777, lng: 18.75507 },
    { lat: 49.0025056, lng: 18.7547638 },
    { lat: 49.0026834, lng: 18.7545624 },
    { lat: 49.0026686, lng: 18.7543874 },
    { lat: 49.0025317, lng: 18.7542682 },
    { lat: 49.0025257, lng: 18.7540919 },
    { lat: 49.002454, lng: 18.753995 },
    { lat: 49.0024778, lng: 18.7538671 },
    { lat: 49.0025234, lng: 18.7538767 },
    { lat: 49.0025583, lng: 18.7537029 },
    { lat: 49.0025001, lng: 18.7535796 },
    { lat: 49.0025785, lng: 18.7533669 },
    { lat: 49.0026155, lng: 18.7532665 },
    { lat: 49.0026237, lng: 18.7531585 },
    { lat: 49.0026605, lng: 18.7527894 },
    { lat: 49.002568, lng: 18.7525754 },
    { lat: 49.0029062, lng: 18.7524415 },
    { lat: 49.0035745, lng: 18.7515299 },
    { lat: 49.003928, lng: 18.751453 },
    { lat: 49.0050858, lng: 18.7503922 },
    { lat: 49.0056643, lng: 18.749166 },
    { lat: 49.0056893, lng: 18.7485613 },
    { lat: 49.0056986, lng: 18.7483368 },
    { lat: 49.0056571, lng: 18.7468771 },
    { lat: 49.00568, lng: 18.74581 },
    { lat: 49.0056476, lng: 18.7448679 },
    { lat: 49.0057764, lng: 18.7446604 },
    { lat: 49.0059843, lng: 18.7447082 },
    { lat: 49.0061238, lng: 18.744359 },
    { lat: 49.0064591, lng: 18.7441295 },
    { lat: 49.006625, lng: 18.7441104 },
    { lat: 49.0068183, lng: 18.744142 },
    { lat: 49.0071085, lng: 18.7440217 },
    { lat: 49.007543, lng: 18.7434245 },
    { lat: 49.0077616, lng: 18.7434213 },
    { lat: 49.0078872, lng: 18.7432789 },
    { lat: 49.0080396, lng: 18.7428104 },
    { lat: 49.0081975, lng: 18.7424015 },
    { lat: 49.0085088, lng: 18.742038 },
    { lat: 49.0087461, lng: 18.7413375 },
    { lat: 49.0089039, lng: 18.7410112 },
    { lat: 49.0091318, lng: 18.7407336 },
    { lat: 49.0091811, lng: 18.7406881 },
    { lat: 49.0096844, lng: 18.7401155 },
    { lat: 49.0102117, lng: 18.7395454 },
    { lat: 49.0104107, lng: 18.7392756 },
    { lat: 49.0107609, lng: 18.7387712 },
    { lat: 49.0112615, lng: 18.7380617 },
    { lat: 49.0120346, lng: 18.7376004 },
    { lat: 49.0125493, lng: 18.7377409 },
    { lat: 49.0127465, lng: 18.7379282 },
    { lat: 49.0127897, lng: 18.7382783 },
    { lat: 49.0127443, lng: 18.7393902 },
    { lat: 49.0128652, lng: 18.739802 },
    { lat: 49.01293, lng: 18.7402457 },
    { lat: 49.0129278, lng: 18.7403117 },
    { lat: 49.0129836, lng: 18.7408797 },
    { lat: 49.0130904, lng: 18.7418195 },
    { lat: 49.0132132, lng: 18.7422012 },
    { lat: 49.0132542, lng: 18.7418657 },
    { lat: 49.0133625, lng: 18.7415889 },
    { lat: 49.013406, lng: 18.74072 },
    { lat: 49.0134666, lng: 18.7406717 },
    { lat: 49.0134613, lng: 18.740742 },
    { lat: 49.0137636, lng: 18.7408907 },
    { lat: 49.013812, lng: 18.7409355 },
    { lat: 49.0151995, lng: 18.7416334 },
    { lat: 49.016709, lng: 18.7427182 },
    { lat: 49.016731, lng: 18.7427497 },
    { lat: 49.0174168, lng: 18.7432683 },
    { lat: 49.0174823, lng: 18.7433157 },
    { lat: 49.0185154, lng: 18.7440948 },
    { lat: 49.0211697, lng: 18.7438938 },
    { lat: 49.0229135, lng: 18.7438143 },
    { lat: 49.0230185, lng: 18.7431088 },
    { lat: 49.0231554, lng: 18.7428356 },
    { lat: 49.0236086, lng: 18.7424925 },
    { lat: 49.0238676, lng: 18.742335 },
    { lat: 49.0240293, lng: 18.7422151 },
    { lat: 49.0242985, lng: 18.7421214 },
    { lat: 49.0246272, lng: 18.7419583 },
    { lat: 49.0254184, lng: 18.7412622 },
    { lat: 49.025726, lng: 18.7409587 },
    { lat: 49.0260324, lng: 18.740629 },
    { lat: 49.026701, lng: 18.740111 },
    { lat: 49.026399, lng: 18.73948 },
    { lat: 49.026182, lng: 18.739027 },
    { lat: 49.025966, lng: 18.738607 },
    { lat: 49.025693, lng: 18.738229 },
    { lat: 49.025521, lng: 18.737948 },
    { lat: 49.025384, lng: 18.737587 },
    { lat: 49.024638, lng: 18.73669 },
    { lat: 49.024413, lng: 18.736394 },
    { lat: 49.023999, lng: 18.735848 },
    { lat: 49.02396, lng: 18.735236 },
    { lat: 49.02395, lng: 18.7350879 },
    { lat: 49.023948, lng: 18.735059 },
    { lat: 49.023889, lng: 18.735053 },
    { lat: 49.0238237, lng: 18.7350458 },
    { lat: 49.02359, lng: 18.73502 },
    { lat: 49.023497, lng: 18.734805 },
    { lat: 49.023352, lng: 18.734359 },
    { lat: 49.023333, lng: 18.734301 },
    { lat: 49.02325, lng: 18.734046 },
    { lat: 49.022992, lng: 18.733429 },
    { lat: 49.022953, lng: 18.733358 },
    { lat: 49.022369, lng: 18.732301 },
    { lat: 49.021759, lng: 18.730914 },
    { lat: 49.021445, lng: 18.729917 },
    { lat: 49.021589, lng: 18.728699 },
    { lat: 49.021315, lng: 18.727875 },
    { lat: 49.021085, lng: 18.727265 },
    { lat: 49.020623, lng: 18.72621 },
    { lat: 49.019968, lng: 18.724891 },
    { lat: 49.019681, lng: 18.724112 },
    { lat: 49.019557, lng: 18.723775 },
    { lat: 49.019228, lng: 18.722777 },
    { lat: 49.0189476, lng: 18.7230189 },
    { lat: 49.018749, lng: 18.723333 },
    { lat: 49.018425, lng: 18.723605 },
    { lat: 49.017884, lng: 18.723863 },
    { lat: 49.017487, lng: 18.724299 },
    { lat: 49.017407, lng: 18.724387 },
    { lat: 49.017403, lng: 18.724509 },
    { lat: 49.017032, lng: 18.724905 },
    { lat: 49.016816, lng: 18.725212 },
    { lat: 49.016507, lng: 18.725559 },
    { lat: 49.016031, lng: 18.72603 },
    { lat: 49.015872, lng: 18.726268 },
    { lat: 49.015815, lng: 18.726285 },
    { lat: 49.015498, lng: 18.72637 },
    { lat: 49.015097, lng: 18.726543 },
    { lat: 49.014679, lng: 18.727076 },
    { lat: 49.014353, lng: 18.727413 },
    { lat: 49.014077, lng: 18.727619 },
    { lat: 49.013801, lng: 18.727833 },
    { lat: 49.013423, lng: 18.728074 },
    { lat: 49.012538, lng: 18.727884 },
    { lat: 49.012112, lng: 18.727635 },
    { lat: 49.012061, lng: 18.727605 },
    { lat: 49.011727, lng: 18.727341 },
    { lat: 49.011283, lng: 18.727244 },
    { lat: 49.010997, lng: 18.727246 },
    { lat: 49.010633, lng: 18.727279 },
    { lat: 49.01006, lng: 18.727232 },
    { lat: 49.009753, lng: 18.727262 },
    { lat: 49.009563, lng: 18.727392 },
    { lat: 49.0090561, lng: 18.7273416 },
    { lat: 49.009009, lng: 18.727378 },
    { lat: 49.008771, lng: 18.727463 },
    { lat: 49.008377, lng: 18.727461 },
    { lat: 49.00805, lng: 18.727394 },
    { lat: 49.00708, lng: 18.727224 },
    { lat: 49.007086, lng: 18.72711 },
    { lat: 49.006996, lng: 18.727011 },
    { lat: 49.00685, lng: 18.72693 },
    { lat: 49.006866, lng: 18.726825 },
    { lat: 49.006595, lng: 18.726779 },
    { lat: 49.006428, lng: 18.726711 },
    { lat: 49.006231, lng: 18.726683 },
    { lat: 49.006198, lng: 18.72673 },
    { lat: 49.005899, lng: 18.726723 },
    { lat: 49.005725, lng: 18.726606 },
    { lat: 49.004862, lng: 18.726497 },
    { lat: 49.005512, lng: 18.724924 },
    { lat: 49.005791, lng: 18.724653 },
    { lat: 49.006435, lng: 18.723563 },
    { lat: 49.006512, lng: 18.723177 },
    { lat: 49.006642, lng: 18.722606 },
    { lat: 49.006739, lng: 18.721833 },
    { lat: 49.006844, lng: 18.720915 },
    { lat: 49.006892, lng: 18.720234 },
    { lat: 49.006922, lng: 18.719483 },
    { lat: 49.006873, lng: 18.718978 },
    { lat: 49.006772, lng: 18.718524 },
    { lat: 49.006565, lng: 18.717932 },
    { lat: 49.006375, lng: 18.717544 },
    { lat: 49.006182, lng: 18.717379 },
    { lat: 49.00561, lng: 18.71714 },
    { lat: 49.005069, lng: 18.716674 },
    { lat: 49.004598, lng: 18.715991 },
    { lat: 49.00389, lng: 18.714857 },
    { lat: 49.003337, lng: 18.713944 },
    { lat: 49.00299, lng: 18.713225 },
    { lat: 49.002834, lng: 18.713042 },
    { lat: 49.002264, lng: 18.713015 },
    { lat: 49.0021851, lng: 18.7130042 },
    { lat: 49.001766, lng: 18.7129467 },
    { lat: 49.001258, lng: 18.712877 },
    { lat: 49.000416, lng: 18.712874 },
    { lat: 48.999986, lng: 18.712806 },
    { lat: 48.999344, lng: 18.71273 },
    { lat: 48.998862, lng: 18.712484 },
    { lat: 48.998215, lng: 18.712199 },
    { lat: 48.997807, lng: 18.712026 },
    { lat: 48.997402, lng: 18.71201 },
    { lat: 48.996506, lng: 18.711727 },
    { lat: 48.9961661, lng: 18.7117219 },
    { lat: 48.9952821, lng: 18.7113609 },
    { lat: 48.9940443, lng: 18.7117318 },
    { lat: 48.9937666, lng: 18.7118745 },
    { lat: 48.9931592, lng: 18.7119894 },
    { lat: 48.9923443, lng: 18.7122122 },
    { lat: 48.9906976, lng: 18.7134078 },
    { lat: 48.9902842, lng: 18.7139299 },
    { lat: 48.9907394, lng: 18.7143489 },
    { lat: 48.9906263, lng: 18.7152866 },
    { lat: 48.9903494, lng: 18.71613 },
    { lat: 48.9904289, lng: 18.7167603 },
    { lat: 48.9905017, lng: 18.7177554 },
    { lat: 48.990513, lng: 18.7177978 },
    { lat: 48.9907319, lng: 18.7186176 },
    { lat: 48.9907617, lng: 18.7192869 },
    { lat: 48.9907677, lng: 18.7206432 },
    { lat: 48.9883283, lng: 18.7197829 },
    { lat: 48.9873778, lng: 18.7195531 },
    { lat: 48.9860065, lng: 18.7189307 },
    { lat: 48.9848245, lng: 18.7208151 },
    { lat: 48.9836616, lng: 18.721173 },
    { lat: 48.9828755, lng: 18.7217264 },
    { lat: 48.9822082, lng: 18.7230386 },
    { lat: 48.9815401, lng: 18.7236511 },
    { lat: 48.9800872, lng: 18.7257193 },
    { lat: 48.9793804, lng: 18.7267347 },
    { lat: 48.9782439, lng: 18.7281529 },
    { lat: 48.9773461, lng: 18.7302489 },
    { lat: 48.9772075, lng: 18.7309787 },
    { lat: 48.9770309, lng: 18.731674 },
    { lat: 48.9768229, lng: 18.7322667 },
    { lat: 48.9765777, lng: 18.7327488 },
    { lat: 48.977216, lng: 18.7344667 },
    { lat: 48.9772584, lng: 18.7352728 },
    { lat: 48.9772063, lng: 18.7357835 },
    { lat: 48.9772798, lng: 18.7364954 },
    { lat: 48.9768094, lng: 18.7367525 },
    { lat: 48.9759713, lng: 18.7370461 },
    { lat: 48.9751743, lng: 18.7368193 },
    { lat: 48.9747166, lng: 18.7369272 },
    { lat: 48.9737263, lng: 18.7375897 },
    { lat: 48.973026, lng: 18.7364495 },
    { lat: 48.973009, lng: 18.7361938 },
    { lat: 48.9726907, lng: 18.7358433 },
    { lat: 48.9725451, lng: 18.7358829 },
    { lat: 48.9714064, lng: 18.7352675 },
    { lat: 48.9711435, lng: 18.7354365 },
    { lat: 48.9702701, lng: 18.7352517 },
    { lat: 48.9701558, lng: 18.7354824 },
    { lat: 48.9698839, lng: 18.7356054 },
    { lat: 48.9700461, lng: 18.7361206 },
    { lat: 48.9699266, lng: 18.736765 },
    { lat: 48.9694691, lng: 18.7373937 },
    { lat: 48.9693332, lng: 18.7380045 },
    { lat: 48.9691059, lng: 18.7392158 },
    { lat: 48.9688371, lng: 18.7389304 },
    { lat: 48.9686, lng: 18.7387538 },
    { lat: 48.9683019, lng: 18.7385707 },
    { lat: 48.9680009, lng: 18.7383164 },
    { lat: 48.9679333, lng: 18.7385536 },
    { lat: 48.9680316, lng: 18.7394803 },
    { lat: 48.9681424, lng: 18.740344 },
    { lat: 48.9680267, lng: 18.7407826 },
    { lat: 48.9679211, lng: 18.7410079 },
    { lat: 48.9674388, lng: 18.7410348 },
    { lat: 48.966728, lng: 18.7436052 },
    { lat: 48.9660849, lng: 18.7447591 },
    { lat: 48.9653626, lng: 18.7447888 },
    { lat: 48.9647885, lng: 18.7438448 },
    { lat: 48.9627409, lng: 18.7421081 },
    { lat: 48.9619212, lng: 18.7416594 },
    { lat: 48.9616521, lng: 18.7414971 },
    { lat: 48.9609161, lng: 18.7411617 },
    { lat: 48.9599534, lng: 18.7404149 },
    { lat: 48.9595777, lng: 18.7399073 },
    { lat: 48.9594138, lng: 18.7397031 },
    { lat: 48.9586797, lng: 18.7388719 },
    { lat: 48.9581211, lng: 18.7383166 },
    { lat: 48.9564063, lng: 18.737553 },
    { lat: 48.9548664, lng: 18.736985 },
    { lat: 48.9537124, lng: 18.7379 },
    { lat: 48.9524207, lng: 18.7380604 },
    { lat: 48.9515414, lng: 18.7383439 },
    { lat: 48.9511216, lng: 18.7398217 },
    { lat: 48.9509548, lng: 18.740853 },
    { lat: 48.9508035, lng: 18.7411702 },
    { lat: 48.9506831, lng: 18.7412626 },
    { lat: 48.950267, lng: 18.741539 },
    { lat: 48.950264, lng: 18.741827 },
    { lat: 48.950267, lng: 18.742313 },
    { lat: 48.950836, lng: 18.743182 },
    { lat: 48.951366, lng: 18.744944 },
    { lat: 48.95177, lng: 18.746289 },
    { lat: 48.952337, lng: 18.748754 },
    { lat: 48.952516, lng: 18.750267 },
    { lat: 48.952774, lng: 18.751071 },
    { lat: 48.953008, lng: 18.751317 },
    { lat: 48.953679, lng: 18.751989 },
    { lat: 48.954252, lng: 18.752331 },
    { lat: 48.955262, lng: 18.753606 },
    { lat: 48.955383, lng: 18.754536 },
    { lat: 48.95574, lng: 18.75637 },
    { lat: 48.955598, lng: 18.7571 },
    { lat: 48.956293, lng: 18.75718 },
    { lat: 48.957188, lng: 18.757134 },
    { lat: 48.957428, lng: 18.75931 },
    { lat: 48.958069, lng: 18.76113 },
    { lat: 48.959049, lng: 18.761998 },
    { lat: 48.959672, lng: 18.763403 },
    { lat: 48.960293, lng: 18.764238 },
    { lat: 48.96097, lng: 18.763643 },
    { lat: 48.961754, lng: 18.763179 },
    { lat: 48.96287, lng: 18.763318 },
    { lat: 48.9638007, lng: 18.7626169 },
    { lat: 48.964289, lng: 18.762249 },
    { lat: 48.965331, lng: 18.762075 },
    { lat: 48.966438, lng: 18.763661 },
    { lat: 48.96663, lng: 18.765179 },
    { lat: 48.966128, lng: 18.766268 },
    { lat: 48.965655, lng: 18.767147 },
    { lat: 48.965182, lng: 18.767499 },
    { lat: 48.964826, lng: 18.769151 },
    { lat: 48.964801, lng: 18.770134 },
    { lat: 48.964761, lng: 18.772311 },
    { lat: 48.964684, lng: 18.773594 },
    { lat: 48.964446, lng: 18.775514 },
    { lat: 48.964042, lng: 18.777973 },
    { lat: 48.963778, lng: 18.778444 },
    { lat: 48.963441, lng: 18.778731 },
    { lat: 48.963145, lng: 18.779006 },
    { lat: 48.962882, lng: 18.779084 },
    { lat: 48.962291, lng: 18.779342 },
    { lat: 48.96211, lng: 18.779501 },
    { lat: 48.961969, lng: 18.77973 },
    { lat: 48.9617984, lng: 18.7800649 },
    { lat: 48.961564, lng: 18.780525 },
    { lat: 48.961127, lng: 18.781311 },
    { lat: 48.960974, lng: 18.781781 },
    { lat: 48.96093, lng: 18.782189 },
    { lat: 48.960847, lng: 18.78287 },
    { lat: 48.960841, lng: 18.782912 },
    { lat: 48.960774, lng: 18.78363 },
    { lat: 48.960781, lng: 18.783943 },
    { lat: 48.96077, lng: 18.784044 },
    { lat: 48.960524, lng: 18.784854 },
    { lat: 48.960429, lng: 18.787223 },
    { lat: 48.960341, lng: 18.788352 },
    { lat: 48.960331, lng: 18.788481 },
    { lat: 48.960261, lng: 18.788671 },
    { lat: 48.96024, lng: 18.788784 },
    { lat: 48.959831, lng: 18.789248 },
    { lat: 48.95975, lng: 18.789313 },
    { lat: 48.959619, lng: 18.789471 },
    { lat: 48.959512, lng: 18.789684 },
    { lat: 48.959413, lng: 18.790059 },
    { lat: 48.95936, lng: 18.790365 },
    { lat: 48.9592, lng: 18.79076 },
    { lat: 48.958931, lng: 18.790675 },
    { lat: 48.958768, lng: 18.790831 },
    { lat: 48.958529, lng: 18.791226 },
    { lat: 48.95841, lng: 18.791195 },
    { lat: 48.958236, lng: 18.791318 },
    { lat: 48.958135, lng: 18.791361 },
    { lat: 48.957971, lng: 18.791471 },
    { lat: 48.957927, lng: 18.791497 },
    { lat: 48.957843, lng: 18.791512 },
    { lat: 48.957812, lng: 18.791566 },
    { lat: 48.957673, lng: 18.7917 },
    { lat: 48.957529, lng: 18.791822 },
    { lat: 48.957455, lng: 18.791857 },
    { lat: 48.957399, lng: 18.791967 },
    { lat: 48.957339, lng: 18.792002 },
    { lat: 48.957262, lng: 18.792052 },
    { lat: 48.957166, lng: 18.792074 },
    { lat: 48.957082, lng: 18.792098 },
    { lat: 48.957016, lng: 18.792146 },
    { lat: 48.957016, lng: 18.792168 },
    { lat: 48.957034, lng: 18.792664 },
    { lat: 48.957031, lng: 18.792971 },
    { lat: 48.957055, lng: 18.79376 },
    { lat: 48.95709, lng: 18.794572 },
    { lat: 48.957096, lng: 18.794979 },
    { lat: 48.9571036, lng: 18.7951642 },
    { lat: 48.957117, lng: 18.795491 },
    { lat: 48.957031, lng: 18.795536 },
    { lat: 48.956866, lng: 18.795721 },
    { lat: 48.956664, lng: 18.796009 },
    { lat: 48.956422, lng: 18.796224 },
    { lat: 48.956269, lng: 18.796258 },
    { lat: 48.956165, lng: 18.796299 },
    { lat: 48.956145, lng: 18.796355 },
    { lat: 48.9561364, lng: 18.796394 },
    { lat: 48.95613, lng: 18.796423 },
    { lat: 48.956126, lng: 18.796483 },
    { lat: 48.956135, lng: 18.796556 },
    { lat: 48.956104, lng: 18.796816 },
    { lat: 48.95618, lng: 18.797092 },
    { lat: 48.956026, lng: 18.797174 },
    { lat: 48.955778, lng: 18.797213 },
    { lat: 48.955204, lng: 18.797301 },
    { lat: 48.955225, lng: 18.797326 },
    { lat: 48.955155, lng: 18.797392 },
    { lat: 48.954974, lng: 18.797613 },
    { lat: 48.954781, lng: 18.797855 },
    { lat: 48.954632, lng: 18.798033 },
    { lat: 48.954488, lng: 18.798209 },
    { lat: 48.954435, lng: 18.798292 },
    { lat: 48.954387, lng: 18.79838 },
    { lat: 48.954336, lng: 18.798566 },
    { lat: 48.954219, lng: 18.799261 },
    { lat: 48.95413, lng: 18.799844 },
    { lat: 48.954079, lng: 18.800083 },
    { lat: 48.954044, lng: 18.800193 },
    { lat: 48.954022, lng: 18.800185 },
    { lat: 48.953993, lng: 18.800284 },
    { lat: 48.954009, lng: 18.800297 },
    { lat: 48.953837, lng: 18.800827 },
    { lat: 48.953706, lng: 18.801234 },
    { lat: 48.953569, lng: 18.801656 },
    { lat: 48.953347, lng: 18.802334 },
    { lat: 48.953289, lng: 18.802514 },
    { lat: 48.953167, lng: 18.802897 },
    { lat: 48.952376, lng: 18.80533 },
    { lat: 48.952334, lng: 18.805495 },
    { lat: 48.95219, lng: 18.806295 },
    { lat: 48.951972, lng: 18.807508 },
    { lat: 48.951949, lng: 18.807621 },
    { lat: 48.951717, lng: 18.808338 },
    { lat: 48.951657, lng: 18.808584 },
    { lat: 48.951422, lng: 18.809332 },
    { lat: 48.95122, lng: 18.809832 },
    { lat: 48.95082, lng: 18.811284 },
    { lat: 48.950759, lng: 18.811495 },
    { lat: 48.950707, lng: 18.811791 },
    { lat: 48.950763, lng: 18.81182 },
    { lat: 48.950762, lng: 18.811893 },
    { lat: 48.950697, lng: 18.811896 },
    { lat: 48.95067, lng: 18.812163 },
    { lat: 48.950668, lng: 18.812186 },
    { lat: 48.95066, lng: 18.812258 },
    { lat: 48.950616, lng: 18.812351 },
    { lat: 48.950574, lng: 18.812486 },
    { lat: 48.95049, lng: 18.812851 },
    { lat: 48.950444, lng: 18.81295 },
    { lat: 48.950437, lng: 18.813056 },
    { lat: 48.950416, lng: 18.813291 },
    { lat: 48.95044, lng: 18.813275 },
    { lat: 48.950419, lng: 18.813388 },
    { lat: 48.950329, lng: 18.81343 },
    { lat: 48.950366, lng: 18.81345 },
    { lat: 48.950351, lng: 18.813494 },
    { lat: 48.950359, lng: 18.813582 },
    { lat: 48.950313, lng: 18.813637 },
    { lat: 48.950229, lng: 18.813806 },
    { lat: 48.950291, lng: 18.813815 },
    { lat: 48.9502, lng: 18.814018 },
    { lat: 48.950249, lng: 18.814071 },
    { lat: 48.950218, lng: 18.814199 },
    { lat: 48.950222, lng: 18.814508 },
    { lat: 48.950199, lng: 18.814631 },
    { lat: 48.950132, lng: 18.814702 },
    { lat: 48.950146, lng: 18.814822 },
    { lat: 48.950143, lng: 18.814962 },
    { lat: 48.95013, lng: 18.815036 },
    { lat: 48.950189, lng: 18.815188 },
    { lat: 48.950187, lng: 18.815271 },
    { lat: 48.950077, lng: 18.8154 },
    { lat: 48.949984, lng: 18.815677 },
    { lat: 48.949882, lng: 18.816035 },
    { lat: 48.949819, lng: 18.816088 },
    { lat: 48.949722, lng: 18.816131 },
    { lat: 48.94969, lng: 18.8163 },
    { lat: 48.949604, lng: 18.816378 },
    { lat: 48.949587, lng: 18.81646 },
    { lat: 48.949545, lng: 18.816552 },
    { lat: 48.949583, lng: 18.816728 },
    { lat: 48.949556, lng: 18.816728 },
    { lat: 48.949549, lng: 18.816867 },
    { lat: 48.949558, lng: 18.817056 },
    { lat: 48.94955, lng: 18.817159 },
    { lat: 48.94954, lng: 18.817355 },
    { lat: 48.949501, lng: 18.817456 },
    { lat: 48.949542, lng: 18.817469 },
    { lat: 48.949539, lng: 18.817504 },
    { lat: 48.949489, lng: 18.817583 },
    { lat: 48.949514, lng: 18.817659 },
    { lat: 48.949466, lng: 18.817686 },
    { lat: 48.949405, lng: 18.817656 },
    { lat: 48.949332, lng: 18.817684 },
    { lat: 48.949386, lng: 18.817764 },
    { lat: 48.949374, lng: 18.817812 },
    { lat: 48.94929, lng: 18.817795 },
    { lat: 48.949244, lng: 18.817884 },
    { lat: 48.949144, lng: 18.818097 },
    { lat: 48.949105, lng: 18.818188 },
    { lat: 48.949088, lng: 18.818308 },
    { lat: 48.949075, lng: 18.818427 },
    { lat: 48.949025, lng: 18.818433 },
    { lat: 48.948927, lng: 18.818539 },
    { lat: 48.948891, lng: 18.818549 },
    { lat: 48.948869, lng: 18.818611 },
    { lat: 48.948847, lng: 18.81857 },
    { lat: 48.94881, lng: 18.818604 },
    { lat: 48.948805, lng: 18.818728 },
    { lat: 48.948832, lng: 18.818793 },
    { lat: 48.948791, lng: 18.818817 },
    { lat: 48.948797, lng: 18.818893 },
    { lat: 48.948704, lng: 18.818932 },
    { lat: 48.948684, lng: 18.818975 },
    { lat: 48.948696, lng: 18.819146 },
    { lat: 48.948732, lng: 18.81919 },
    { lat: 48.948768, lng: 18.81926 },
    { lat: 48.948749, lng: 18.819322 },
    { lat: 48.94864, lng: 18.819423 },
    { lat: 48.948609, lng: 18.81941 },
    { lat: 48.948544, lng: 18.819451 },
    { lat: 48.948509, lng: 18.819523 },
    { lat: 48.948446, lng: 18.819539 },
    { lat: 48.948422, lng: 18.819578 },
    { lat: 48.948374, lng: 18.819564 },
    { lat: 48.948372, lng: 18.819619 },
    { lat: 48.948408, lng: 18.819747 },
    { lat: 48.948394, lng: 18.819841 },
    { lat: 48.948325, lng: 18.81986 },
    { lat: 48.948274, lng: 18.819922 },
    { lat: 48.948197, lng: 18.820036 },
    { lat: 48.948161, lng: 18.82009 },
    { lat: 48.948165, lng: 18.820155 },
    { lat: 48.948175, lng: 18.820185 },
    { lat: 48.948179, lng: 18.820227 },
    { lat: 48.948143, lng: 18.820259 },
    { lat: 48.948105, lng: 18.82036 },
    { lat: 48.948061, lng: 18.820373 },
    { lat: 48.948044, lng: 18.820419 },
    { lat: 48.947977, lng: 18.820468 },
    { lat: 48.947967, lng: 18.820424 },
    { lat: 48.947951, lng: 18.820414 },
    { lat: 48.947943, lng: 18.820349 },
    { lat: 48.947904, lng: 18.820326 },
    { lat: 48.94787, lng: 18.820391 },
    { lat: 48.947814, lng: 18.820475 },
    { lat: 48.947815, lng: 18.820553 },
    { lat: 48.947804, lng: 18.820611 },
    { lat: 48.947776, lng: 18.820609 },
    { lat: 48.947735, lng: 18.820698 },
    { lat: 48.947685, lng: 18.820727 },
    { lat: 48.947613, lng: 18.820881 },
    { lat: 48.947577, lng: 18.820904 },
    { lat: 48.947392, lng: 18.820918 },
    { lat: 48.947267, lng: 18.821207 },
    { lat: 48.947244, lng: 18.821292 },
    { lat: 48.947182, lng: 18.821384 },
    { lat: 48.947133, lng: 18.821393 },
    { lat: 48.947144, lng: 18.821409 },
    { lat: 48.947149, lng: 18.821415 },
    { lat: 48.947159, lng: 18.821429 },
    { lat: 48.947194, lng: 18.821474 },
    { lat: 48.947393, lng: 18.821743 },
    { lat: 48.947666, lng: 18.822065 },
    { lat: 48.947737, lng: 18.822159 },
    { lat: 48.947859, lng: 18.822289 },
    { lat: 48.947952, lng: 18.82239 },
    { lat: 48.948148, lng: 18.8226 },
    { lat: 48.948534, lng: 18.822992 },
    { lat: 48.948561, lng: 18.82304 },
    { lat: 48.948716, lng: 18.822986 },
    { lat: 48.948777, lng: 18.822974 },
    { lat: 48.948968, lng: 18.822958 },
    { lat: 48.949076, lng: 18.82295 },
    { lat: 48.949465, lng: 18.822887 },
    { lat: 48.949673, lng: 18.822812 },
    { lat: 48.949972, lng: 18.822758 },
    { lat: 48.949995, lng: 18.822754 },
    { lat: 48.950643, lng: 18.822658 },
    { lat: 48.950886, lng: 18.822635 },
    { lat: 48.950994, lng: 18.822608 },
    { lat: 48.951056, lng: 18.822617 },
    { lat: 48.95109, lng: 18.822621 },
    { lat: 48.951071, lng: 18.822681 },
    { lat: 48.950941, lng: 18.823099 },
    { lat: 48.950769, lng: 18.823553 },
    { lat: 48.950543, lng: 18.82426 },
    { lat: 48.95046, lng: 18.824471 },
    { lat: 48.950208, lng: 18.825265 },
    { lat: 48.950118, lng: 18.825395 },
    { lat: 48.950183, lng: 18.825674 },
    { lat: 48.950394, lng: 18.826309 },
    { lat: 48.950421, lng: 18.826368 },
    { lat: 48.950613, lng: 18.826833 },
    { lat: 48.950417, lng: 18.827821 },
    { lat: 48.950593, lng: 18.828416 },
    { lat: 48.950748, lng: 18.828656 },
    { lat: 48.950958, lng: 18.828821 },
    { lat: 48.951396, lng: 18.829144 },
    { lat: 48.951496, lng: 18.829209 },
    { lat: 48.951691, lng: 18.82938 },
    { lat: 48.951938, lng: 18.829484 },
    { lat: 48.95189, lng: 18.830145 },
    { lat: 48.951847, lng: 18.830548 },
    { lat: 48.951806, lng: 18.831269 },
    { lat: 48.951823, lng: 18.831859 },
    { lat: 48.951848, lng: 18.832667 },
    { lat: 48.951878, lng: 18.833254 },
    { lat: 48.951872, lng: 18.833484 },
    { lat: 48.951866, lng: 18.833723 },
    { lat: 48.951853, lng: 18.8339 },
    { lat: 48.951758, lng: 18.834559 },
    { lat: 48.951657, lng: 18.835188 },
    { lat: 48.951612, lng: 18.835589 },
    { lat: 48.951583, lng: 18.835713 },
    { lat: 48.9541308, lng: 18.8378723 },
    { lat: 48.9541049, lng: 18.838026 },
    { lat: 48.953949, lng: 18.8384554 },
    { lat: 48.9538672, lng: 18.8387529 },
    { lat: 48.9537215, lng: 18.8394207 },
    { lat: 48.9538129, lng: 18.8394486 },
    { lat: 48.954144, lng: 18.8397242 },
    { lat: 48.9539877, lng: 18.8399907 },
    { lat: 48.9539234, lng: 18.8401638 },
    { lat: 48.9540747, lng: 18.8405637 },
    { lat: 48.9542297, lng: 18.8407917 },
    { lat: 48.9542229, lng: 18.8410308 },
    { lat: 48.9541925, lng: 18.8412482 },
    { lat: 48.9540859, lng: 18.8414568 },
    { lat: 48.954057, lng: 18.841593 },
    { lat: 48.9540701, lng: 18.8417834 },
    { lat: 48.9541086, lng: 18.8420253 },
    { lat: 48.9542174, lng: 18.8424289 },
    { lat: 48.9543669, lng: 18.8428136 },
    { lat: 48.9545904, lng: 18.8435405 },
    { lat: 48.9547824, lng: 18.8439406 },
    { lat: 48.9548293, lng: 18.8441037 },
    { lat: 48.9548145, lng: 18.844297 },
    { lat: 48.9546354, lng: 18.8453951 },
    { lat: 48.9546235, lng: 18.8454036 },
    { lat: 48.9546126, lng: 18.8454989 },
    { lat: 48.9546273, lng: 18.8458287 },
    { lat: 48.9546031, lng: 18.8462754 },
    { lat: 48.9545792, lng: 18.8464673 },
    { lat: 48.9545264, lng: 18.8466666 },
    { lat: 48.9544595, lng: 18.8467718 },
    { lat: 48.9543888, lng: 18.8469722 },
    { lat: 48.9542681, lng: 18.8473891 },
    { lat: 48.9541759, lng: 18.8478084 },
    { lat: 48.9541106, lng: 18.8480038 },
    { lat: 48.9540621, lng: 18.8481179 },
    { lat: 48.9539886, lng: 18.8482224 },
    { lat: 48.9538927, lng: 18.8483063 },
    { lat: 48.9537365, lng: 18.8484056 },
    { lat: 48.953446, lng: 18.8485005 },
    { lat: 48.9534164, lng: 18.8485409 },
    { lat: 48.9534782, lng: 18.8496063 },
    { lat: 48.9535202, lng: 18.850245 },
    { lat: 48.9535488, lng: 18.8504472 },
    { lat: 48.953618, lng: 18.8506115 },
    { lat: 48.9535763, lng: 18.8508055 },
    { lat: 48.9535567, lng: 18.8509528 },
    { lat: 48.9535821, lng: 18.8509638 },
    { lat: 48.9535918, lng: 18.8511076 },
    { lat: 48.9535903, lng: 18.8512968 },
    { lat: 48.9536026, lng: 18.8517966 },
    { lat: 48.9536229, lng: 18.8521038 },
    { lat: 48.9536225, lng: 18.852271 },
    { lat: 48.9532666, lng: 18.853832 },
    { lat: 48.9531744, lng: 18.8541006 },
    { lat: 48.9547525, lng: 18.8540465 },
    { lat: 48.9548068, lng: 18.854049 },
    { lat: 48.9548719, lng: 18.8541112 },
    { lat: 48.9549089, lng: 18.8540821 },
    { lat: 48.9549836, lng: 18.8543308 },
    { lat: 48.9549987, lng: 18.8544729 },
    { lat: 48.9550768, lng: 18.8545845 },
    { lat: 48.9551108, lng: 18.8547225 },
    { lat: 48.9551736, lng: 18.8547578 },
    { lat: 48.955178, lng: 18.8548832 },
    { lat: 48.9552299, lng: 18.85491 },
    { lat: 48.9551475, lng: 18.8552129 },
    { lat: 48.9551829, lng: 18.855396 },
    { lat: 48.955143, lng: 18.8556953 },
    { lat: 48.955168, lng: 18.8559184 },
    { lat: 48.9552423, lng: 18.8560452 },
    { lat: 48.9553084, lng: 18.8561263 },
    { lat: 48.9553984, lng: 18.8561856 },
    { lat: 48.9554059, lng: 18.8563763 },
    { lat: 48.9554688, lng: 18.8565471 },
    { lat: 48.9556152, lng: 18.8568939 },
    { lat: 48.9556896, lng: 18.8569798 },
    { lat: 48.9556931, lng: 18.8570489 },
    { lat: 48.9558084, lng: 18.857098 },
    { lat: 48.9558392, lng: 18.8571468 },
    { lat: 48.9557757, lng: 18.8572526 },
    { lat: 48.9558165, lng: 18.8575822 },
    { lat: 48.9558696, lng: 18.8579156 },
    { lat: 48.9558348, lng: 18.8581336 },
    { lat: 48.9557904, lng: 18.8581934 },
    { lat: 48.9557687, lng: 18.8585167 },
    { lat: 48.9557874, lng: 18.8589243 },
    { lat: 48.9558788, lng: 18.8590106 },
    { lat: 48.9558409, lng: 18.859215 },
    { lat: 48.9558412, lng: 18.8595052 },
    { lat: 48.9558172, lng: 18.8596311 },
    { lat: 48.9558344, lng: 18.8597909 },
    { lat: 48.9559674, lng: 18.8599886 },
    { lat: 48.9560913, lng: 18.8603563 },
    { lat: 48.9562279, lng: 18.8608632 },
    { lat: 48.956243, lng: 18.8608708 },
    { lat: 48.9562223, lng: 18.8610612 },
    { lat: 48.9562232, lng: 18.8612524 },
    { lat: 48.9563452, lng: 18.8613236 },
    { lat: 48.956437, lng: 18.8613127 },
    { lat: 48.9565746, lng: 18.8615529 },
    { lat: 48.9566268, lng: 18.8615618 },
    { lat: 48.9566396, lng: 18.8616758 },
    { lat: 48.9566063, lng: 18.8617738 },
    { lat: 48.9564959, lng: 18.8617772 },
    { lat: 48.9563481, lng: 18.8618625 },
    { lat: 48.9564098, lng: 18.8622061 },
    { lat: 48.9565222, lng: 18.8623331 },
    { lat: 48.9566427, lng: 18.8624163 },
    { lat: 48.9568381, lng: 18.8624215 },
    { lat: 48.9569119, lng: 18.8622341 },
    { lat: 48.9570114, lng: 18.862327 },
    { lat: 48.9570513, lng: 18.8624683 },
    { lat: 48.9569643, lng: 18.8626839 },
    { lat: 48.9570301, lng: 18.8629789 },
    { lat: 48.9568936, lng: 18.8630622 },
    { lat: 48.9568061, lng: 18.8630295 },
    { lat: 48.9567858, lng: 18.8632676 },
    { lat: 48.9567879, lng: 18.8633969 },
    { lat: 48.9565869, lng: 18.8634574 },
    { lat: 48.9565057, lng: 18.8636984 },
    { lat: 48.9564854, lng: 18.8638494 },
    { lat: 48.9565466, lng: 18.8639698 },
    { lat: 48.9566884, lng: 18.8641166 },
    { lat: 48.9566184, lng: 18.8643323 },
    { lat: 48.9566073, lng: 18.8644237 },
    { lat: 48.9566882, lng: 18.8645727 },
    { lat: 48.9567644, lng: 18.864607 },
    { lat: 48.9568713, lng: 18.8647318 },
    { lat: 48.9567548, lng: 18.8650115 },
    { lat: 48.9566931, lng: 18.8653248 },
    { lat: 48.9565048, lng: 18.8653662 },
    { lat: 48.9564656, lng: 18.8656145 },
    { lat: 48.9563046, lng: 18.8656849 },
    { lat: 48.9561563, lng: 18.8656974 },
    { lat: 48.9561652, lng: 18.8659113 },
    { lat: 48.9560136, lng: 18.8661184 },
    { lat: 48.9559459, lng: 18.8662498 },
    { lat: 48.9558987, lng: 18.866518 },
    { lat: 48.955974, lng: 18.867325 },
    { lat: 48.9560771, lng: 18.8680153 },
    { lat: 48.9561228, lng: 18.8683518 },
    { lat: 48.9561427, lng: 18.8686296 },
    { lat: 48.956137, lng: 18.8687394 },
    { lat: 48.9561477, lng: 18.8689064 },
    { lat: 48.9561192, lng: 18.8691182 },
    { lat: 48.9560624, lng: 18.869352 },
    { lat: 48.9560458, lng: 18.8695757 },
    { lat: 48.9560698, lng: 18.8698456 },
    { lat: 48.9561052, lng: 18.8700732 },
    { lat: 48.9561794, lng: 18.8702671 },
    { lat: 48.9562022, lng: 18.8702916 },
    { lat: 48.9562987, lng: 18.8703487 },
    { lat: 48.9565315, lng: 18.8702716 },
    { lat: 48.9567082, lng: 18.870199 },
    { lat: 48.9567529, lng: 18.8702813 },
    { lat: 48.9567742, lng: 18.8703422 },
    { lat: 48.956769, lng: 18.8705958 },
    { lat: 48.9567258, lng: 18.870785 },
    { lat: 48.9566936, lng: 18.8708616 },
    { lat: 48.9567184, lng: 18.8710133 },
    { lat: 48.9568983, lng: 18.8709999 },
    { lat: 48.957068, lng: 18.8709288 },
    { lat: 48.9572201, lng: 18.8708803 },
    { lat: 48.9574549, lng: 18.8709494 },
    { lat: 48.9576843, lng: 18.8711597 },
    { lat: 48.9578846, lng: 18.8714111 },
    { lat: 48.9580151, lng: 18.8716465 },
    { lat: 48.9580522, lng: 18.8719313 },
    { lat: 48.9580771, lng: 18.8721953 },
    { lat: 48.9581524, lng: 18.8722821 },
    { lat: 48.9581943, lng: 18.872284 },
    { lat: 48.9583008, lng: 18.872205 },
    { lat: 48.9584418, lng: 18.8718998 },
    { lat: 48.958649, lng: 18.8718067 },
    { lat: 48.9588235, lng: 18.8718693 },
    { lat: 48.9588862, lng: 18.87199 },
    { lat: 48.9589083, lng: 18.8720717 },
    { lat: 48.9589333, lng: 18.8722499 },
    { lat: 48.958967, lng: 18.8724221 },
    { lat: 48.9591718, lng: 18.8725682 },
    { lat: 48.9593475, lng: 18.8725997 },
    { lat: 48.9595351, lng: 18.8725482 },
    { lat: 48.9596032, lng: 18.8725132 },
    { lat: 48.9598684, lng: 18.8724968 },
    { lat: 48.9600661, lng: 18.8727023 },
    { lat: 48.9602515, lng: 18.8728669 },
    { lat: 48.9603452, lng: 18.8729326 },
    { lat: 48.9605174, lng: 18.8729513 },
    { lat: 48.960585, lng: 18.8729918 },
    { lat: 48.960685, lng: 18.873071 },
    { lat: 48.9607984, lng: 18.873261 },
    { lat: 48.9608392, lng: 18.8733732 },
    { lat: 48.9609704, lng: 18.8736702 },
    { lat: 48.9610295, lng: 18.8738318 },
    { lat: 48.96106, lng: 18.8739839 },
    { lat: 48.9610994, lng: 18.874248 },
    { lat: 48.9610979, lng: 18.874395 },
    { lat: 48.9610711, lng: 18.8747925 },
    { lat: 48.9610451, lng: 18.8749437 },
    { lat: 48.9609272, lng: 18.8751328 },
    { lat: 48.9608617, lng: 18.8752192 },
    { lat: 48.9609417, lng: 18.8755897 },
    { lat: 48.9609526, lng: 18.8759135 },
    { lat: 48.9609169, lng: 18.8760505 },
    { lat: 48.9609013, lng: 18.8762522 },
    { lat: 48.9609145, lng: 18.8763769 },
    { lat: 48.9609887, lng: 18.8765285 },
    { lat: 48.9611496, lng: 18.8767395 },
    { lat: 48.9613171, lng: 18.8769511 },
    { lat: 48.9616306, lng: 18.8771935 },
    { lat: 48.961883, lng: 18.8773423 },
    { lat: 48.9620688, lng: 18.8773389 },
    { lat: 48.9621577, lng: 18.8772452 },
    { lat: 48.9621843, lng: 18.8771272 },
    { lat: 48.9621788, lng: 18.8769779 },
    { lat: 48.9621586, lng: 18.8768019 },
    { lat: 48.962274, lng: 18.876592 },
    { lat: 48.962285, lng: 18.8762767 },
    { lat: 48.9622965, lng: 18.8760859 },
    { lat: 48.9622951, lng: 18.8758381 },
    { lat: 48.9623625, lng: 18.8756787 },
    { lat: 48.962453, lng: 18.8755316 },
    { lat: 48.9626258, lng: 18.8754681 },
    { lat: 48.9628229, lng: 18.875622 },
    { lat: 48.9631608, lng: 18.8755723 },
    { lat: 48.9632457, lng: 18.8755088 },
    { lat: 48.9633705, lng: 18.8753077 },
    { lat: 48.9634065, lng: 18.8751298 },
    { lat: 48.9634526, lng: 18.8750149 },
    { lat: 48.963535, lng: 18.8749527 },
    { lat: 48.9637016, lng: 18.8749671 },
    { lat: 48.9638092, lng: 18.8751302 },
    { lat: 48.9638492, lng: 18.8752281 },
    { lat: 48.9639329, lng: 18.8752336 },
    { lat: 48.9640569, lng: 18.8752803 },
    { lat: 48.964361, lng: 18.8753583 },
    { lat: 48.9645035, lng: 18.8753687 },
    { lat: 48.9646543, lng: 18.8753638 },
    { lat: 48.964781, lng: 18.8753703 },
    { lat: 48.9650483, lng: 18.875372 },
    { lat: 48.9653016, lng: 18.8752972 },
    { lat: 48.9654153, lng: 18.8752475 },
    { lat: 48.965551, lng: 18.8751707 },
    { lat: 48.9656913, lng: 18.8749187 },
    { lat: 48.9658906, lng: 18.8748845 },
    { lat: 48.9659281, lng: 18.8748734 },
    { lat: 48.9662535, lng: 18.8750854 },
    { lat: 48.9667066, lng: 18.8755854 },
    { lat: 48.9667393, lng: 18.8757161 },
    { lat: 48.9668032, lng: 18.8756683 },
    { lat: 48.9674473, lng: 18.8756495 },
    { lat: 48.9675482, lng: 18.875175 },
    { lat: 48.9675963, lng: 18.8751682 },
    { lat: 48.9676552, lng: 18.8752387 },
    { lat: 48.9677759, lng: 18.8749983 },
    { lat: 48.9678893, lng: 18.8748388 },
    { lat: 48.9681297, lng: 18.8748628 },
    { lat: 48.9682613, lng: 18.8749878 },
    { lat: 48.9683004, lng: 18.8751555 },
    { lat: 48.9683103, lng: 18.8755272 },
    { lat: 48.9683605, lng: 18.8757774 },
    { lat: 48.968395, lng: 18.8761408 },
    { lat: 48.9684677, lng: 18.8763755 },
    { lat: 48.9684731, lng: 18.8766957 },
    { lat: 48.968745, lng: 18.8766811 },
    { lat: 48.9689029, lng: 18.8764611 },
    { lat: 48.9692022, lng: 18.8764461 },
    { lat: 48.9693296, lng: 18.8764708 },
    { lat: 48.9697265, lng: 18.8766442 },
    { lat: 48.9699313, lng: 18.876879 },
    { lat: 48.9699128, lng: 18.8771579 },
    { lat: 48.9700526, lng: 18.8771974 },
    { lat: 48.9702571, lng: 18.8772105 },
    { lat: 48.9705013, lng: 18.8771528 },
    { lat: 48.9709124, lng: 18.8769904 },
    { lat: 48.9711637, lng: 18.8768784 },
    { lat: 48.9714562, lng: 18.876823 },
    { lat: 48.9716947, lng: 18.8768977 },
    { lat: 48.9719686, lng: 18.8768298 },
    { lat: 48.9721457, lng: 18.8769208 },
    { lat: 48.9722712, lng: 18.876884 },
    { lat: 48.9725064, lng: 18.8767671 },
    { lat: 48.9726441, lng: 18.8764813 },
    { lat: 48.9728245, lng: 18.8764599 },
    { lat: 48.9727639, lng: 18.8762037 },
    { lat: 48.9727234, lng: 18.87594 },
    { lat: 48.9727304, lng: 18.8756402 },
    { lat: 48.9727571, lng: 18.8753442 },
    { lat: 48.97266, lng: 18.875322 },
    { lat: 48.9726065, lng: 18.8752881 },
    { lat: 48.9725166, lng: 18.87534 },
    { lat: 48.972504, lng: 18.8751369 },
    { lat: 48.9724236, lng: 18.8751946 },
    { lat: 48.9723487, lng: 18.8750986 },
    { lat: 48.9723267, lng: 18.8752861 },
    { lat: 48.9722429, lng: 18.8753014 },
    { lat: 48.9721961, lng: 18.8752707 },
    { lat: 48.9721374, lng: 18.8751111 },
    { lat: 48.9721784, lng: 18.8750305 },
    { lat: 48.9721285, lng: 18.8748965 },
    { lat: 48.9722282, lng: 18.8747949 },
    { lat: 48.9721843, lng: 18.874686 },
    { lat: 48.9722575, lng: 18.8745338 },
    { lat: 48.9721043, lng: 18.8743143 },
    { lat: 48.9720259, lng: 18.8743487 },
    { lat: 48.9718918, lng: 18.8744378 },
    { lat: 48.9718867, lng: 18.8744248 },
    { lat: 48.9718624, lng: 18.8742141 },
    { lat: 48.9717872, lng: 18.8738279 },
    { lat: 48.9716233, lng: 18.8738186 },
    { lat: 48.9716474, lng: 18.8739346 },
    { lat: 48.9716449, lng: 18.8740192 },
    { lat: 48.9715843, lng: 18.8740253 },
    { lat: 48.9714822, lng: 18.8740579 },
    { lat: 48.9713369, lng: 18.8742793 },
    { lat: 48.9711416, lng: 18.874207 },
    { lat: 48.9710653, lng: 18.8740821 },
    { lat: 48.9710805, lng: 18.8739185 },
    { lat: 48.971197, lng: 18.8738368 },
    { lat: 48.9711865, lng: 18.8737236 },
    { lat: 48.9710827, lng: 18.873765 },
    { lat: 48.971051, lng: 18.8737404 },
    { lat: 48.9710276, lng: 18.8736154 },
    { lat: 48.971056, lng: 18.8735287 },
    { lat: 48.9709291, lng: 18.8732983 },
    { lat: 48.9709495, lng: 18.873236 },
    { lat: 48.970882, lng: 18.873104 },
    { lat: 48.9707818, lng: 18.8731966 },
    { lat: 48.9707227, lng: 18.8731867 },
    { lat: 48.9706758, lng: 18.8731108 },
    { lat: 48.9707518, lng: 18.8729195 },
    { lat: 48.9706692, lng: 18.8726738 },
    { lat: 48.9705161, lng: 18.8727888 },
    { lat: 48.9704802, lng: 18.8729872 },
    { lat: 48.9704313, lng: 18.8730239 },
    { lat: 48.9704201, lng: 18.8729384 },
    { lat: 48.970168, lng: 18.8726402 },
    { lat: 48.9701561, lng: 18.8725831 },
    { lat: 48.970234, lng: 18.872411 },
    { lat: 48.9701887, lng: 18.8723216 },
    { lat: 48.9700763, lng: 18.8721939 },
    { lat: 48.9699986, lng: 18.8721765 },
    { lat: 48.969973, lng: 18.8720916 },
    { lat: 48.9700694, lng: 18.8719729 },
    { lat: 48.970086, lng: 18.8717883 },
    { lat: 48.9699603, lng: 18.8716273 },
    { lat: 48.9698655, lng: 18.8715804 },
    { lat: 48.9697974, lng: 18.8716581 },
    { lat: 48.9697979, lng: 18.8714937 },
    { lat: 48.969787, lng: 18.8712814 },
    { lat: 48.9697109, lng: 18.8711839 },
    { lat: 48.9695989, lng: 18.8711971 },
    { lat: 48.9695006, lng: 18.8710835 },
    { lat: 48.9694619, lng: 18.8711667 },
    { lat: 48.9694814, lng: 18.8713891 },
    { lat: 48.9693415, lng: 18.8714315 },
    { lat: 48.9692805, lng: 18.8713203 },
    { lat: 48.9692804, lng: 18.8711437 },
    { lat: 48.9691876, lng: 18.870873 },
    { lat: 48.9692015, lng: 18.8705724 },
    { lat: 48.9690522, lng: 18.8704308 },
    { lat: 48.9689397, lng: 18.8703519 },
    { lat: 48.9687356, lng: 18.8701087 },
    { lat: 48.968666, lng: 18.8701353 },
    { lat: 48.9686112, lng: 18.8701019 },
    { lat: 48.9685451, lng: 18.8700215 },
    { lat: 48.9684578, lng: 18.8697943 },
    { lat: 48.9684252, lng: 18.8697518 },
    { lat: 48.9684263, lng: 18.8696405 },
    { lat: 48.9683754, lng: 18.8696156 },
    { lat: 48.9682081, lng: 18.8696053 },
    { lat: 48.9681891, lng: 18.8695444 },
    { lat: 48.9681998, lng: 18.8694265 },
    { lat: 48.9681741, lng: 18.8692516 },
    { lat: 48.9679901, lng: 18.8693832 },
    { lat: 48.9679602, lng: 18.8692834 },
    { lat: 48.967878, lng: 18.868935 },
    { lat: 48.9678416, lng: 18.8689705 },
    { lat: 48.9677669, lng: 18.8688136 },
    { lat: 48.9677983, lng: 18.8685658 },
    { lat: 48.9677877, lng: 18.8684045 },
    { lat: 48.9677918, lng: 18.8681352 },
    { lat: 48.9677861, lng: 18.8680659 },
    { lat: 48.9677903, lng: 18.8678849 },
    { lat: 48.9677389, lng: 18.8675016 },
    { lat: 48.9676638, lng: 18.867442 },
    { lat: 48.9676212, lng: 18.8672938 },
    { lat: 48.967531, lng: 18.8670975 },
    { lat: 48.9674878, lng: 18.8670258 },
    { lat: 48.9674343, lng: 18.8667938 },
    { lat: 48.9673987, lng: 18.8667392 },
    { lat: 48.9673463, lng: 18.8665757 },
    { lat: 48.9673508, lng: 18.8665066 },
    { lat: 48.9673088, lng: 18.8664154 },
    { lat: 48.9672658, lng: 18.8663703 },
    { lat: 48.9672187, lng: 18.8662869 },
    { lat: 48.9671768, lng: 18.8662847 },
    { lat: 48.9669752, lng: 18.8662429 },
    { lat: 48.9668779, lng: 18.8661927 },
    { lat: 48.9666308, lng: 18.8659274 },
    { lat: 48.9665816, lng: 18.8659128 },
    { lat: 48.9665425, lng: 18.8657667 },
    { lat: 48.9662354, lng: 18.8653646 },
    { lat: 48.9663288, lng: 18.8645931 },
    { lat: 48.9663776, lng: 18.8639206 },
    { lat: 48.9663047, lng: 18.8635973 },
    { lat: 48.9662312, lng: 18.8627623 },
    { lat: 48.9662273, lng: 18.8623131 },
    { lat: 48.9662538, lng: 18.8609646 },
    { lat: 48.9653573, lng: 18.8606559 },
    { lat: 48.9654266, lng: 18.8595739 },
    { lat: 48.9647894, lng: 18.859177 },
    { lat: 48.9647828, lng: 18.8587418 },
    { lat: 48.9647497, lng: 18.8586251 },
    { lat: 48.9647459, lng: 18.8581982 },
    { lat: 48.9642711, lng: 18.857871 },
    { lat: 48.9642602, lng: 18.8565216 },
    { lat: 48.9642686, lng: 18.8555014 },
    { lat: 48.9642647, lng: 18.8552277 },
    { lat: 48.964284, lng: 18.8536131 },
    { lat: 48.9642674, lng: 18.8522407 },
    { lat: 48.964259, lng: 18.8516844 },
    { lat: 48.964199, lng: 18.8496002 },
    { lat: 48.9642203, lng: 18.8494261 },
    { lat: 48.9642709, lng: 18.849377 },
    { lat: 48.9641342, lng: 18.8492874 },
    { lat: 48.9641956, lng: 18.8491631 },
    { lat: 48.9636363, lng: 18.8486641 },
    { lat: 48.9635405, lng: 18.848575 },
    { lat: 48.9635076, lng: 18.8485269 },
    { lat: 48.9624453, lng: 18.8476489 },
    { lat: 48.9603586, lng: 18.8458893 },
    { lat: 48.960223, lng: 18.845797 },
    { lat: 48.9600734, lng: 18.845737 },
    { lat: 48.9596095, lng: 18.8455988 },
    { lat: 48.9595591, lng: 18.8454501 },
    { lat: 48.95954, lng: 18.8447563 },
    { lat: 48.9595378, lng: 18.8443195 },
    { lat: 48.9595562, lng: 18.8436915 },
    { lat: 48.9596233, lng: 18.843267 },
    { lat: 48.9597837, lng: 18.8426619 },
    { lat: 48.9599445, lng: 18.8421476 },
    { lat: 48.960365, lng: 18.8408143 },
    { lat: 48.9607778, lng: 18.8395939 },
    { lat: 48.9611685, lng: 18.838384 },
    { lat: 48.9615517, lng: 18.8371384 },
    { lat: 48.9616334, lng: 18.8366902 },
    { lat: 48.9617215, lng: 18.8357757 },
    { lat: 48.9617958, lng: 18.8350736 },
    { lat: 48.9618391, lng: 18.8347778 },
    { lat: 48.9618639, lng: 18.8346901 },
    { lat: 48.9619812, lng: 18.834346 },
    { lat: 48.9623449, lng: 18.8332218 },
    { lat: 48.9627256, lng: 18.8320995 },
    { lat: 48.9631507, lng: 18.8307486 },
    { lat: 48.9636881, lng: 18.8290833 },
    { lat: 48.9639263, lng: 18.8283476 },
    { lat: 48.9642938, lng: 18.8271941 },
    { lat: 48.9646579, lng: 18.8258845 },
    { lat: 48.9649724, lng: 18.8247481 },
    { lat: 48.9653108, lng: 18.8233347 },
    { lat: 48.9653688, lng: 18.8228264 },
    { lat: 48.9654045, lng: 18.8219691 },
    { lat: 48.9654824, lng: 18.8219278 },
    { lat: 48.9657185, lng: 18.822064 },
    { lat: 48.9662086, lng: 18.8223618 },
    { lat: 48.9665255, lng: 18.8224951 },
    { lat: 48.9671153, lng: 18.8226464 },
    { lat: 48.9680066, lng: 18.8229379 },
    { lat: 48.9684027, lng: 18.8220726 },
    { lat: 48.9691866, lng: 18.8203836 },
    { lat: 48.9695558, lng: 18.8195833 },
    { lat: 48.9703304, lng: 18.8179111 },
    { lat: 48.9704471, lng: 18.817661 },
    { lat: 48.9711294, lng: 18.8161821 },
    { lat: 48.9712067, lng: 18.8160192 },
    { lat: 48.9712146, lng: 18.8160208 },
    { lat: 48.971152, lng: 18.8158797 },
    { lat: 48.9710341, lng: 18.815583 },
    { lat: 48.9711602, lng: 18.8153856 },
    { lat: 48.9713914, lng: 18.8158209 },
    { lat: 48.9715174, lng: 18.8160768 },
    { lat: 48.9717006, lng: 18.8164339 },
    { lat: 48.9718956, lng: 18.8167358 },
    { lat: 48.9722084, lng: 18.8169639 },
    { lat: 48.972317, lng: 18.8171903 },
    { lat: 48.9731076, lng: 18.8161234 },
    { lat: 48.9736694, lng: 18.81711 },
    { lat: 48.9739158, lng: 18.8168587 },
    { lat: 48.9741987, lng: 18.816509 },
    { lat: 48.9742258, lng: 18.816421 },
    { lat: 48.9743168, lng: 18.816174 },
    { lat: 48.9744521, lng: 18.8158265 },
    { lat: 48.974549, lng: 18.8156082 },
    { lat: 48.9746372, lng: 18.8154813 },
    { lat: 48.9749374, lng: 18.8150972 },
    { lat: 48.9751603, lng: 18.8148717 },
    { lat: 48.9754461, lng: 18.8151228 },
    { lat: 48.9755127, lng: 18.8149049 },
    { lat: 48.9755634, lng: 18.8146877 },
    { lat: 48.9759251, lng: 18.8132248 },
    { lat: 48.9759537, lng: 18.8127555 },
    { lat: 48.9760115, lng: 18.8127633 },
    { lat: 48.9760288, lng: 18.8123552 },
    { lat: 48.9761, lng: 18.8119458 },
    { lat: 48.9759887, lng: 18.8116274 },
    { lat: 48.9759707, lng: 18.811217 },
    { lat: 48.9759574, lng: 18.8109154 },
    { lat: 48.9759734, lng: 18.8106293 },
    { lat: 48.9759838, lng: 18.8104442 },
    { lat: 48.9759846, lng: 18.8100905 },
    { lat: 48.9759641, lng: 18.8098337 },
    { lat: 48.9759167, lng: 18.8092379 },
    { lat: 48.9759336, lng: 18.8079799 },
    { lat: 48.9759973, lng: 18.8074055 },
    { lat: 48.9761488, lng: 18.8069562 },
  ],
  Laskár: [
    { lat: 48.9709124, lng: 18.8769904 },
    { lat: 48.9705013, lng: 18.8771528 },
    { lat: 48.9702571, lng: 18.8772105 },
    { lat: 48.9700526, lng: 18.8771974 },
    { lat: 48.9699128, lng: 18.8771579 },
    { lat: 48.9699313, lng: 18.876879 },
    { lat: 48.9697265, lng: 18.8766442 },
    { lat: 48.9693296, lng: 18.8764708 },
    { lat: 48.9692022, lng: 18.8764461 },
    { lat: 48.9689029, lng: 18.8764611 },
    { lat: 48.968745, lng: 18.8766811 },
    { lat: 48.9684731, lng: 18.8766957 },
    { lat: 48.9684677, lng: 18.8763755 },
    { lat: 48.968395, lng: 18.8761408 },
    { lat: 48.9683605, lng: 18.8757774 },
    { lat: 48.9683103, lng: 18.8755272 },
    { lat: 48.9683004, lng: 18.8751555 },
    { lat: 48.9682613, lng: 18.8749878 },
    { lat: 48.9681297, lng: 18.8748628 },
    { lat: 48.9678893, lng: 18.8748388 },
    { lat: 48.9677759, lng: 18.8749983 },
    { lat: 48.9676552, lng: 18.8752387 },
    { lat: 48.9675963, lng: 18.8751682 },
    { lat: 48.9675482, lng: 18.875175 },
    { lat: 48.9674473, lng: 18.8756495 },
    { lat: 48.9668032, lng: 18.8756683 },
    { lat: 48.9667393, lng: 18.8757161 },
    { lat: 48.9667066, lng: 18.8755854 },
    { lat: 48.9662535, lng: 18.8750854 },
    { lat: 48.9659281, lng: 18.8748734 },
    { lat: 48.9658906, lng: 18.8748845 },
    { lat: 48.9656913, lng: 18.8749187 },
    { lat: 48.965551, lng: 18.8751707 },
    { lat: 48.9654153, lng: 18.8752475 },
    { lat: 48.9653016, lng: 18.8752972 },
    { lat: 48.9650483, lng: 18.875372 },
    { lat: 48.964781, lng: 18.8753703 },
    { lat: 48.9646543, lng: 18.8753638 },
    { lat: 48.9645035, lng: 18.8753687 },
    { lat: 48.964361, lng: 18.8753583 },
    { lat: 48.9640569, lng: 18.8752803 },
    { lat: 48.9639329, lng: 18.8752336 },
    { lat: 48.9638492, lng: 18.8752281 },
    { lat: 48.9638092, lng: 18.8751302 },
    { lat: 48.9637016, lng: 18.8749671 },
    { lat: 48.963535, lng: 18.8749527 },
    { lat: 48.9634526, lng: 18.8750149 },
    { lat: 48.9634065, lng: 18.8751298 },
    { lat: 48.9633705, lng: 18.8753077 },
    { lat: 48.9632457, lng: 18.8755088 },
    { lat: 48.9631608, lng: 18.8755723 },
    { lat: 48.9628229, lng: 18.875622 },
    { lat: 48.9626258, lng: 18.8754681 },
    { lat: 48.962453, lng: 18.8755316 },
    { lat: 48.9623625, lng: 18.8756787 },
    { lat: 48.9622951, lng: 18.8758381 },
    { lat: 48.9622965, lng: 18.8760859 },
    { lat: 48.962285, lng: 18.8762767 },
    { lat: 48.962274, lng: 18.876592 },
    { lat: 48.9621586, lng: 18.8768019 },
    { lat: 48.9621788, lng: 18.8769779 },
    { lat: 48.9621843, lng: 18.8771272 },
    { lat: 48.9621577, lng: 18.8772452 },
    { lat: 48.9620688, lng: 18.8773389 },
    { lat: 48.961883, lng: 18.8773423 },
    { lat: 48.9616306, lng: 18.8771935 },
    { lat: 48.9613171, lng: 18.8769511 },
    { lat: 48.9611496, lng: 18.8767395 },
    { lat: 48.9609887, lng: 18.8765285 },
    { lat: 48.9609145, lng: 18.8763769 },
    { lat: 48.9609013, lng: 18.8762522 },
    { lat: 48.9609169, lng: 18.8760505 },
    { lat: 48.9609526, lng: 18.8759135 },
    { lat: 48.9609417, lng: 18.8755897 },
    { lat: 48.9608617, lng: 18.8752192 },
    { lat: 48.9609272, lng: 18.8751328 },
    { lat: 48.9610451, lng: 18.8749437 },
    { lat: 48.9610711, lng: 18.8747925 },
    { lat: 48.9610979, lng: 18.874395 },
    { lat: 48.9610994, lng: 18.874248 },
    { lat: 48.96106, lng: 18.8739839 },
    { lat: 48.9610295, lng: 18.8738318 },
    { lat: 48.9609704, lng: 18.8736702 },
    { lat: 48.9608392, lng: 18.8733732 },
    { lat: 48.9607984, lng: 18.873261 },
    { lat: 48.960685, lng: 18.873071 },
    { lat: 48.960585, lng: 18.8729918 },
    { lat: 48.9605174, lng: 18.8729513 },
    { lat: 48.9603452, lng: 18.8729326 },
    { lat: 48.9602515, lng: 18.8728669 },
    { lat: 48.9600661, lng: 18.8727023 },
    { lat: 48.9598684, lng: 18.8724968 },
    { lat: 48.9596032, lng: 18.8725132 },
    { lat: 48.9595351, lng: 18.8725482 },
    { lat: 48.9593475, lng: 18.8725997 },
    { lat: 48.9591718, lng: 18.8725682 },
    { lat: 48.958967, lng: 18.8724221 },
    { lat: 48.9589333, lng: 18.8722499 },
    { lat: 48.9589083, lng: 18.8720717 },
    { lat: 48.9588862, lng: 18.87199 },
    { lat: 48.9588235, lng: 18.8718693 },
    { lat: 48.958649, lng: 18.8718067 },
    { lat: 48.9584418, lng: 18.8718998 },
    { lat: 48.9583008, lng: 18.872205 },
    { lat: 48.9581943, lng: 18.872284 },
    { lat: 48.9581524, lng: 18.8722821 },
    { lat: 48.9580771, lng: 18.8721953 },
    { lat: 48.9580522, lng: 18.8719313 },
    { lat: 48.9580151, lng: 18.8716465 },
    { lat: 48.9578846, lng: 18.8714111 },
    { lat: 48.9576843, lng: 18.8711597 },
    { lat: 48.9574549, lng: 18.8709494 },
    { lat: 48.9572201, lng: 18.8708803 },
    { lat: 48.957068, lng: 18.8709288 },
    { lat: 48.9568983, lng: 18.8709999 },
    { lat: 48.9567184, lng: 18.8710133 },
    { lat: 48.9566225, lng: 18.8711413 },
    { lat: 48.9563336, lng: 18.8712258 },
    { lat: 48.9558859, lng: 18.8716405 },
    { lat: 48.9556445, lng: 18.8715507 },
    { lat: 48.9555188, lng: 18.8715878 },
    { lat: 48.9554684, lng: 18.8718328 },
    { lat: 48.9555102, lng: 18.8720531 },
    { lat: 48.9554054, lng: 18.8724283 },
    { lat: 48.9555096, lng: 18.8727456 },
    { lat: 48.9554158, lng: 18.8728701 },
    { lat: 48.9554592, lng: 18.8730802 },
    { lat: 48.9553815, lng: 18.8731903 },
    { lat: 48.9553153, lng: 18.8736369 },
    { lat: 48.9553456, lng: 18.8738758 },
    { lat: 48.9552863, lng: 18.87395 },
    { lat: 48.9553071, lng: 18.8742449 },
    { lat: 48.9552937, lng: 18.8745364 },
    { lat: 48.9553477, lng: 18.8749946 },
    { lat: 48.9553542, lng: 18.8752489 },
    { lat: 48.9553836, lng: 18.8755364 },
    { lat: 48.9553917, lng: 18.87574 },
    { lat: 48.955415, lng: 18.8758208 },
    { lat: 48.9554504, lng: 18.8758711 },
    { lat: 48.9554857, lng: 18.8759638 },
    { lat: 48.9555549, lng: 18.8763281 },
    { lat: 48.9556343, lng: 18.8765999 },
    { lat: 48.9555957, lng: 18.8767491 },
    { lat: 48.9555711, lng: 18.876771 },
    { lat: 48.9555163, lng: 18.8768909 },
    { lat: 48.9554893, lng: 18.8769827 },
    { lat: 48.9554723, lng: 18.8771343 },
    { lat: 48.9554414, lng: 18.8772323 },
    { lat: 48.9554074, lng: 18.8772974 },
    { lat: 48.955388, lng: 18.8773563 },
    { lat: 48.9553822, lng: 18.877441 },
    { lat: 48.9554071, lng: 18.8775543 },
    { lat: 48.9554612, lng: 18.8776648 },
    { lat: 48.9553358, lng: 18.8780331 },
    { lat: 48.9553807, lng: 18.8781799 },
    { lat: 48.9553278, lng: 18.878292 },
    { lat: 48.9553233, lng: 18.8785121 },
    { lat: 48.9552693, lng: 18.8788224 },
    { lat: 48.9551494, lng: 18.8796485 },
    { lat: 48.9545289, lng: 18.8818404 },
    { lat: 48.9544097, lng: 18.8821326 },
    { lat: 48.9541877, lng: 18.8825295 },
    { lat: 48.954011, lng: 18.8827333 },
    { lat: 48.9533858, lng: 18.8827886 },
    { lat: 48.9525896, lng: 18.8828544 },
    { lat: 48.9520918, lng: 18.8831723 },
    { lat: 48.9518871, lng: 18.8834251 },
    { lat: 48.9516587, lng: 18.8840071 },
    { lat: 48.9511771, lng: 18.8844185 },
    { lat: 48.9507011, lng: 18.8851212 },
    { lat: 48.9504536, lng: 18.8855698 },
    { lat: 48.9499549, lng: 18.8864198 },
    { lat: 48.9496809, lng: 18.8870577 },
    { lat: 48.949398, lng: 18.887522 },
    { lat: 48.9490901, lng: 18.887982 },
    { lat: 48.9482743, lng: 18.8889838 },
    { lat: 48.9467676, lng: 18.890827 },
    { lat: 48.9438962, lng: 18.8923085 },
    { lat: 48.9436429, lng: 18.8925163 },
    { lat: 48.9435173, lng: 18.8926373 },
    { lat: 48.9431604, lng: 18.8930686 },
    { lat: 48.9421629, lng: 18.8930278 },
    { lat: 48.941533, lng: 18.8930613 },
    { lat: 48.9411848, lng: 18.8931963 },
    { lat: 48.9405716, lng: 18.893858 },
    { lat: 48.9410071, lng: 18.8946115 },
    { lat: 48.941012, lng: 18.8949061 },
    { lat: 48.9419326, lng: 18.8948756 },
    { lat: 48.9428308, lng: 18.8948223 },
    { lat: 48.9433103, lng: 18.8948063 },
    { lat: 48.9440891, lng: 18.8947746 },
    { lat: 48.9446483, lng: 18.8947309 },
    { lat: 48.9449802, lng: 18.8947168 },
    { lat: 48.9458884, lng: 18.8946599 },
    { lat: 48.9466732, lng: 18.894616 },
    { lat: 48.9476844, lng: 18.8945706 },
    { lat: 48.9482924, lng: 18.8945982 },
    { lat: 48.9492608, lng: 18.8946618 },
    { lat: 48.9498705, lng: 18.8947893 },
    { lat: 48.9506226, lng: 18.8950288 },
    { lat: 48.9507225, lng: 18.8950877 },
    { lat: 48.9514882, lng: 18.8954421 },
    { lat: 48.952037, lng: 18.8957465 },
    { lat: 48.952492, lng: 18.8960394 },
    { lat: 48.9529711, lng: 18.89646 },
    { lat: 48.9538142, lng: 18.8972043 },
    { lat: 48.9538436, lng: 18.8970957 },
    { lat: 48.9538835, lng: 18.8969468 },
    { lat: 48.9539319, lng: 18.8967703 },
    { lat: 48.955075, lng: 18.8955752 },
    { lat: 48.9554623, lng: 18.8954111 },
    { lat: 48.9559748, lng: 18.895379 },
    { lat: 48.9567294, lng: 18.894943 },
    { lat: 48.9573089, lng: 18.893335 },
    { lat: 48.9573175, lng: 18.893329 },
    { lat: 48.957542, lng: 18.8929975 },
    { lat: 48.9575746, lng: 18.8927106 },
    { lat: 48.9574875, lng: 18.8925519 },
    { lat: 48.9575058, lng: 18.8923596 },
    { lat: 48.9575522, lng: 18.8923605 },
    { lat: 48.9576852, lng: 18.8929103 },
    { lat: 48.957753, lng: 18.8931118 },
    { lat: 48.9579363, lng: 18.8932355 },
    { lat: 48.9587473, lng: 18.8939908 },
    { lat: 48.9596831, lng: 18.8950001 },
    { lat: 48.9600678, lng: 18.8954749 },
    { lat: 48.9606422, lng: 18.896184 },
    { lat: 48.962339, lng: 18.8954107 },
    { lat: 48.9623872, lng: 18.8954703 },
    { lat: 48.963542, lng: 18.89479 },
    { lat: 48.9650668, lng: 18.8952161 },
    { lat: 48.9652836, lng: 18.8953577 },
    { lat: 48.9656281, lng: 18.8956299 },
    { lat: 48.9657719, lng: 18.8958189 },
    { lat: 48.9657924, lng: 18.8958919 },
    { lat: 48.9659221, lng: 18.8962652 },
    { lat: 48.9667873, lng: 18.8995345 },
    { lat: 48.9669837, lng: 18.8993821 },
    { lat: 48.9674548, lng: 18.8989397 },
    { lat: 48.9687398, lng: 18.8981618 },
    { lat: 48.9702284, lng: 18.8972394 },
    { lat: 48.9726449, lng: 18.8965647 },
    { lat: 48.9724099, lng: 18.8962885 },
    { lat: 48.9711567, lng: 18.8951983 },
    { lat: 48.9690426, lng: 18.893285 },
    { lat: 48.96898, lng: 18.8932285 },
    { lat: 48.9694415, lng: 18.8925054 },
    { lat: 48.969509, lng: 18.8924806 },
    { lat: 48.9685356, lng: 18.8918129 },
    { lat: 48.9676158, lng: 18.8910473 },
    { lat: 48.9665661, lng: 18.8899279 },
    { lat: 48.9666476, lng: 18.8896919 },
    { lat: 48.9675029, lng: 18.8878744 },
    { lat: 48.9680644, lng: 18.8870418 },
    { lat: 48.9683232, lng: 18.886657 },
    { lat: 48.9689677, lng: 18.8857019 },
    { lat: 48.9694283, lng: 18.8847908 },
    { lat: 48.969996, lng: 18.8835918 },
    { lat: 48.9705291, lng: 18.8825741 },
    { lat: 48.9708094, lng: 18.8818404 },
    { lat: 48.9709945, lng: 18.8812938 },
    { lat: 48.9710445, lng: 18.881149 },
    { lat: 48.9710937, lng: 18.8809845 },
    { lat: 48.9711206, lng: 18.8808949 },
    { lat: 48.9712858, lng: 18.8801367 },
    { lat: 48.9713503, lng: 18.8797221 },
    { lat: 48.971335, lng: 18.8794235 },
    { lat: 48.9712383, lng: 18.8792982 },
    { lat: 48.9713043, lng: 18.878826 },
    { lat: 48.9711683, lng: 18.8779581 },
    { lat: 48.9709124, lng: 18.8769904 },
  ],
  Martin: [
    { lat: 49.1115611, lng: 18.9528469 },
    { lat: 49.1116985, lng: 18.9519711 },
    { lat: 49.1117939, lng: 18.9507158 },
    { lat: 49.1118401, lng: 18.9497988 },
    { lat: 49.1118476, lng: 18.9489649 },
    { lat: 49.1118623, lng: 18.9467607 },
    { lat: 49.1117927, lng: 18.9435559 },
    { lat: 49.1119343, lng: 18.9427458 },
    { lat: 49.1119612, lng: 18.9410088 },
    { lat: 49.112147, lng: 18.9398429 },
    { lat: 49.1116417, lng: 18.9397504 },
    { lat: 49.1114521, lng: 18.9392123 },
    { lat: 49.1112568, lng: 18.9387383 },
    { lat: 49.1106237, lng: 18.9376392 },
    { lat: 49.110571, lng: 18.9375767 },
    { lat: 49.1103404, lng: 18.9370726 },
    { lat: 49.10978, lng: 18.9363176 },
    { lat: 49.1097169, lng: 18.9362293 },
    { lat: 49.1096277, lng: 18.9360264 },
    { lat: 49.1096026, lng: 18.9359311 },
    { lat: 49.1096001, lng: 18.9359048 },
    { lat: 49.1096009, lng: 18.9358971 },
    { lat: 49.1095745, lng: 18.9356894 },
    { lat: 49.1095419, lng: 18.935555 },
    { lat: 49.1094803, lng: 18.9353874 },
    { lat: 49.1094663, lng: 18.9352882 },
    { lat: 49.1094656, lng: 18.9352272 },
    { lat: 49.1094557, lng: 18.9351675 },
    { lat: 49.1094457, lng: 18.9351474 },
    { lat: 49.1094011, lng: 18.9348914 },
    { lat: 49.1093715, lng: 18.934751 },
    { lat: 49.1091974, lng: 18.9347427 },
    { lat: 49.1091776, lng: 18.9347295 },
    { lat: 49.109163, lng: 18.9347107 },
    { lat: 49.1091431, lng: 18.9346735 },
    { lat: 49.1091118, lng: 18.9345868 },
    { lat: 49.109028, lng: 18.9342476 },
    { lat: 49.1089591, lng: 18.9338012 },
    { lat: 49.1089391, lng: 18.9336276 },
    { lat: 49.1089329, lng: 18.9334851 },
    { lat: 49.1089782, lng: 18.9331775 },
    { lat: 49.108991, lng: 18.9330519 },
    { lat: 49.1090526, lng: 18.9327337 },
    { lat: 49.1091057, lng: 18.9324914 },
    { lat: 49.1088773, lng: 18.9325888 },
    { lat: 49.108795, lng: 18.9322098 },
    { lat: 49.1082391, lng: 18.932453 },
    { lat: 49.1082033, lng: 18.9322589 },
    { lat: 49.1080071, lng: 18.9323446 },
    { lat: 49.107605, lng: 18.9325312 },
    { lat: 49.1076438, lng: 18.9327429 },
    { lat: 49.1073595, lng: 18.9328706 },
    { lat: 49.107305, lng: 18.9325978 },
    { lat: 49.1072198, lng: 18.9326503 },
    { lat: 49.1070113, lng: 18.9328957 },
    { lat: 49.1068056, lng: 18.9329113 },
    { lat: 49.1066484, lng: 18.9315678 },
    { lat: 49.1070841, lng: 18.9313769 },
    { lat: 49.1072865, lng: 18.9324758 },
    { lat: 49.1079424, lng: 18.9320705 },
    { lat: 49.1087281, lng: 18.9318452 },
    { lat: 49.109265, lng: 18.9316957 },
    { lat: 49.1093083, lng: 18.9312577 },
    { lat: 49.1093269, lng: 18.931271 },
    { lat: 49.1093744, lng: 18.9309401 },
    { lat: 49.1094839, lng: 18.9298757 },
    { lat: 49.1096297, lng: 18.9287958 },
    { lat: 49.1096302, lng: 18.928713 },
    { lat: 49.109707, lng: 18.9281833 },
    { lat: 49.1097319, lng: 18.9279846 },
    { lat: 49.1097428, lng: 18.927781 },
    { lat: 49.1097327, lng: 18.9275821 },
    { lat: 49.1096961, lng: 18.9272611 },
    { lat: 49.109628, lng: 18.926896 },
    { lat: 49.1096074, lng: 18.9267566 },
    { lat: 49.1090126, lng: 18.9267669 },
    { lat: 49.1082927, lng: 18.9269283 },
    { lat: 49.1082295, lng: 18.926554 },
    { lat: 49.1078757, lng: 18.9267104 },
    { lat: 49.1079183, lng: 18.9259058 },
    { lat: 49.1079536, lng: 18.9253828 },
    { lat: 49.1080057, lng: 18.9244781 },
    { lat: 49.1079303, lng: 18.9244765 },
    { lat: 49.1079295, lng: 18.9241311 },
    { lat: 49.1076387, lng: 18.9241319 },
    { lat: 49.1076767, lng: 18.9235254 },
    { lat: 49.1076846, lng: 18.923504 },
    { lat: 49.1076233, lng: 18.9234929 },
    { lat: 49.1075811, lng: 18.924174 },
    { lat: 49.1075675, lng: 18.924194 },
    { lat: 49.1075502, lng: 18.9242075 },
    { lat: 49.1075119, lng: 18.9242113 },
    { lat: 49.10727, lng: 18.924204 },
    { lat: 49.1072003, lng: 18.9242019 },
    { lat: 49.1064456, lng: 18.9241908 },
    { lat: 49.1064212, lng: 18.9241753 },
    { lat: 49.1063995, lng: 18.92413 },
    { lat: 49.1063993, lng: 18.9241024 },
    { lat: 49.106445, lng: 18.9233787 },
    { lat: 49.1064549, lng: 18.9233326 },
    { lat: 49.1064688, lng: 18.9232935 },
    { lat: 49.1064768, lng: 18.923122 },
    { lat: 49.1067351, lng: 18.9231646 },
    { lat: 49.106737, lng: 18.9231378 },
    { lat: 49.1067522, lng: 18.9231252 },
    { lat: 49.1067654, lng: 18.9230959 },
    { lat: 49.106821, lng: 18.9220131 },
    { lat: 49.1068267, lng: 18.9218199 },
    { lat: 49.1068653, lng: 18.9213565 },
    { lat: 49.1069033, lng: 18.9211743 },
    { lat: 49.1069595, lng: 18.9205495 },
    { lat: 49.10718, lng: 18.9208268 },
    { lat: 49.1074722, lng: 18.9211882 },
    { lat: 49.1077565, lng: 18.9214786 },
    { lat: 49.1080295, lng: 18.9217267 },
    { lat: 49.1082897, lng: 18.9219433 },
    { lat: 49.1084897, lng: 18.9220615 },
    { lat: 49.1086487, lng: 18.9218196 },
    { lat: 49.1088436, lng: 18.9214609 },
    { lat: 49.1089717, lng: 18.9212529 },
    { lat: 49.1092118, lng: 18.9209202 },
    { lat: 49.1096072, lng: 18.9203352 },
    { lat: 49.1096728, lng: 18.9201627 },
    { lat: 49.1096764, lng: 18.9199445 },
    { lat: 49.1096775, lng: 18.9193197 },
    { lat: 49.109652, lng: 18.9191773 },
    { lat: 49.1096507, lng: 18.9191735 },
    { lat: 49.109565, lng: 18.918706 },
    { lat: 49.1095341, lng: 18.9186103 },
    { lat: 49.1093522, lng: 18.9177365 },
    { lat: 49.1093198, lng: 18.9173628 },
    { lat: 49.1093596, lng: 18.9171058 },
    { lat: 49.1088567, lng: 18.9173249 },
    { lat: 49.1088885, lng: 18.9169527 },
    { lat: 49.1089605, lng: 18.9161514 },
    { lat: 49.109014, lng: 18.915498 },
    { lat: 49.1090931, lng: 18.914634 },
    { lat: 49.1091457, lng: 18.9139851 },
    { lat: 49.1091718, lng: 18.9136995 },
    { lat: 49.1092566, lng: 18.9127056 },
    { lat: 49.1093255, lng: 18.9118032 },
    { lat: 49.1094174, lng: 18.9108603 },
    { lat: 49.1095096, lng: 18.9097896 },
    { lat: 49.1097571, lng: 18.9069486 },
    { lat: 49.1098029, lng: 18.9066028 },
    { lat: 49.1098874, lng: 18.9058324 },
    { lat: 49.1098759, lng: 18.9056711 },
    { lat: 49.1099134, lng: 18.9054572 },
    { lat: 49.1099205, lng: 18.9051732 },
    { lat: 49.1099578, lng: 18.905131 },
    { lat: 49.1099439, lng: 18.905012 },
    { lat: 49.1099562, lng: 18.9048357 },
    { lat: 49.1099897, lng: 18.9046719 },
    { lat: 49.1099897, lng: 18.9045838 },
    { lat: 49.1100216, lng: 18.904372 },
    { lat: 49.110014, lng: 18.9042401 },
    { lat: 49.1101029, lng: 18.903264 },
    { lat: 49.1101505, lng: 18.9026959 },
    { lat: 49.1102154, lng: 18.9019793 },
    { lat: 49.1102202, lng: 18.9018758 },
    { lat: 49.1102587, lng: 18.9015646 },
    { lat: 49.1102941, lng: 18.9011091 },
    { lat: 49.1103475, lng: 18.9005198 },
    { lat: 49.1103497, lng: 18.9004526 },
    { lat: 49.1104523, lng: 18.8993925 },
    { lat: 49.1104623, lng: 18.899103 },
    { lat: 49.1104927, lng: 18.8987032 },
    { lat: 49.1105134, lng: 18.8986446 },
    { lat: 49.1105398, lng: 18.8984353 },
    { lat: 49.1105425, lng: 18.8983076 },
    { lat: 49.1106014, lng: 18.8976751 },
    { lat: 49.1106714, lng: 18.896901 },
    { lat: 49.1106961, lng: 18.8965256 },
    { lat: 49.1107176, lng: 18.8962607 },
    { lat: 49.110764, lng: 18.8959514 },
    { lat: 49.1107571, lng: 18.8957525 },
    { lat: 49.1107866, lng: 18.8955158 },
    { lat: 49.1109386, lng: 18.8938956 },
    { lat: 49.1110625, lng: 18.8934728 },
    { lat: 49.1111734, lng: 18.893197 },
    { lat: 49.1112627, lng: 18.8928261 },
    { lat: 49.1119609, lng: 18.890864 },
    { lat: 49.1121717, lng: 18.8902693 },
    { lat: 49.112723, lng: 18.8886564 },
    { lat: 49.1129563, lng: 18.8880112 },
    { lat: 49.113226, lng: 18.8870884 },
    { lat: 49.1133015, lng: 18.8868686 },
    { lat: 49.1150843, lng: 18.8812295 },
    { lat: 49.1150583, lng: 18.8806103 },
    { lat: 49.1152931, lng: 18.8794911 },
    { lat: 49.1155126, lng: 18.8788676 },
    { lat: 49.1157852, lng: 18.8782431 },
    { lat: 49.1160682, lng: 18.877629 },
    { lat: 49.1164783, lng: 18.876783 },
    { lat: 49.1166713, lng: 18.8765232 },
    { lat: 49.1172491, lng: 18.8746837 },
    { lat: 49.1172952, lng: 18.8744396 },
    { lat: 49.117268, lng: 18.8742135 },
    { lat: 49.1172569, lng: 18.8740194 },
    { lat: 49.117387, lng: 18.8723763 },
    { lat: 49.1174842, lng: 18.8716117 },
    { lat: 49.117652, lng: 18.8708143 },
    { lat: 49.1178105, lng: 18.8701684 },
    { lat: 49.117979, lng: 18.8694486 },
    { lat: 49.1180865, lng: 18.8691274 },
    { lat: 49.1190177, lng: 18.8682704 },
    { lat: 49.1194731, lng: 18.8674744 },
    { lat: 49.119689, lng: 18.8669584 },
    { lat: 49.1199145, lng: 18.8660561 },
    { lat: 49.1204945, lng: 18.8649644 },
    { lat: 49.1207621, lng: 18.8644884 },
    { lat: 49.1208706, lng: 18.8642527 },
    { lat: 49.1211088, lng: 18.8634439 },
    { lat: 49.1212919, lng: 18.862638 },
    { lat: 49.1214767, lng: 18.8618464 },
    { lat: 49.121643, lng: 18.8611084 },
    { lat: 49.1217933, lng: 18.8605894 },
    { lat: 49.1219688, lng: 18.8601405 },
    { lat: 49.1222234, lng: 18.8591037 },
    { lat: 49.1224826, lng: 18.8575652 },
    { lat: 49.1227682, lng: 18.8562993 },
    { lat: 49.1228028, lng: 18.8562235 },
    { lat: 49.1230504, lng: 18.8551414 },
    { lat: 49.1231888, lng: 18.8543214 },
    { lat: 49.1231248, lng: 18.853384 },
    { lat: 49.1231097, lng: 18.8528703 },
    { lat: 49.1234381, lng: 18.8516687 },
    { lat: 49.1237031, lng: 18.8511655 },
    { lat: 49.1239201, lng: 18.8503869 },
    { lat: 49.1239424, lng: 18.8498327 },
    { lat: 49.1241176, lng: 18.8492469 },
    { lat: 49.1242644, lng: 18.8480025 },
    { lat: 49.1243446, lng: 18.8475444 },
    { lat: 49.1247006, lng: 18.8467073 },
    { lat: 49.1250972, lng: 18.845976 },
    { lat: 49.1253258, lng: 18.8454673 },
    { lat: 49.1254497, lng: 18.844937 },
    { lat: 49.1255453, lng: 18.8444766 },
    { lat: 49.1256112, lng: 18.8441991 },
    { lat: 49.1257565, lng: 18.8435154 },
    { lat: 49.1259238, lng: 18.8428222 },
    { lat: 49.1260079, lng: 18.8423304 },
    { lat: 49.1261031, lng: 18.8418813 },
    { lat: 49.1261095, lng: 18.8413721 },
    { lat: 49.1264065, lng: 18.8407243 },
    { lat: 49.1268367, lng: 18.8390484 },
    { lat: 49.1269609, lng: 18.8379546 },
    { lat: 49.1270172, lng: 18.8368836 },
    { lat: 49.126939, lng: 18.835063 },
    { lat: 49.126368, lng: 18.834044 },
    { lat: 49.125405, lng: 18.832908 },
    { lat: 49.125041, lng: 18.832166 },
    { lat: 49.12477, lng: 18.831026 },
    { lat: 49.124764, lng: 18.830764 },
    { lat: 49.124749, lng: 18.83017 },
    { lat: 49.124558, lng: 18.82935 },
    { lat: 49.124359, lng: 18.828501 },
    { lat: 49.124094, lng: 18.827376 },
    { lat: 49.124061, lng: 18.827237 },
    { lat: 49.123568, lng: 18.82737 },
    { lat: 49.122273, lng: 18.827663 },
    { lat: 49.1217217, lng: 18.8277971 },
    { lat: 49.121085, lng: 18.827952 },
    { lat: 49.120733, lng: 18.828039 },
    { lat: 49.120686, lng: 18.828051 },
    { lat: 49.120315, lng: 18.828142 },
    { lat: 49.119876, lng: 18.828252 },
    { lat: 49.119612, lng: 18.828291 },
    { lat: 49.119013, lng: 18.828379 },
    { lat: 49.118574, lng: 18.828362 },
    { lat: 49.118559, lng: 18.828361 },
    { lat: 49.118243, lng: 18.828348 },
    { lat: 49.118057, lng: 18.828424 },
    { lat: 49.118053, lng: 18.828425 },
    { lat: 49.117804, lng: 18.828525 },
    { lat: 49.11756, lng: 18.828469 },
    { lat: 49.117482, lng: 18.828452 },
    { lat: 49.117447, lng: 18.828444 },
    { lat: 49.117439, lng: 18.828437 },
    { lat: 49.117229, lng: 18.828284 },
    { lat: 49.11683, lng: 18.827994 },
    { lat: 49.116639, lng: 18.827854 },
    { lat: 49.116638, lng: 18.827851 },
    { lat: 49.116638, lng: 18.82785 },
    { lat: 49.1166, lng: 18.827791 },
    { lat: 49.11655, lng: 18.827712 },
    { lat: 49.116491, lng: 18.82762 },
    { lat: 49.116049, lng: 18.826923 },
    { lat: 49.115394, lng: 18.825882 },
    { lat: 49.114579, lng: 18.825078 },
    { lat: 49.114074, lng: 18.82458 },
    { lat: 49.114047, lng: 18.824553 },
    { lat: 49.114015, lng: 18.824521 },
    { lat: 49.113987, lng: 18.824474 },
    { lat: 49.113494, lng: 18.823648 },
    { lat: 49.11284, lng: 18.822938 },
    { lat: 49.112023, lng: 18.822557 },
    { lat: 49.111263, lng: 18.822453 },
    { lat: 49.1109239, lng: 18.8223966 },
    { lat: 49.110145, lng: 18.822267 },
    { lat: 49.109663, lng: 18.822411 },
    { lat: 49.10889, lng: 18.822534 },
    { lat: 49.108883, lng: 18.822535 },
    { lat: 49.108842, lng: 18.822516 },
    { lat: 49.108054, lng: 18.822164 },
    { lat: 49.107202, lng: 18.821494 },
    { lat: 49.10635, lng: 18.820822 },
    { lat: 49.106236, lng: 18.820734 },
    { lat: 49.105509, lng: 18.820134 },
    { lat: 49.10548, lng: 18.820111 },
    { lat: 49.105418, lng: 18.819984 },
    { lat: 49.104837, lng: 18.818774 },
    { lat: 49.104089, lng: 18.817777 },
    { lat: 49.103361, lng: 18.817746 },
    { lat: 49.102221, lng: 18.817683 },
    { lat: 49.102219, lng: 18.817683 },
    { lat: 49.102031, lng: 18.817672 },
    { lat: 49.101841, lng: 18.81767 },
    { lat: 49.101547, lng: 18.817666 },
    { lat: 49.101006, lng: 18.81766 },
    { lat: 49.099941, lng: 18.817355 },
    { lat: 49.09994, lng: 18.817354 },
    { lat: 49.099939, lng: 18.817354 },
    { lat: 49.099726, lng: 18.816811 },
    { lat: 49.099511, lng: 18.816266 },
    { lat: 49.09951, lng: 18.816264 },
    { lat: 49.098621, lng: 18.81707 },
    { lat: 49.098179, lng: 18.81747 },
    { lat: 49.098136, lng: 18.81751 },
    { lat: 49.097857, lng: 18.817762 },
    { lat: 49.096636, lng: 18.818212 },
    { lat: 49.095594, lng: 18.817689 },
    { lat: 49.095408, lng: 18.817596 },
    { lat: 49.09534, lng: 18.817532 },
    { lat: 49.093795, lng: 18.816094 },
    { lat: 49.093588, lng: 18.815879 },
    { lat: 49.092517, lng: 18.814768 },
    { lat: 49.091494, lng: 18.813963 },
    { lat: 49.090676, lng: 18.813806 },
    { lat: 49.088602, lng: 18.814191 },
    { lat: 49.088601, lng: 18.814191 },
    { lat: 49.087632, lng: 18.814587 },
    { lat: 49.086548, lng: 18.815443 },
    { lat: 49.085564, lng: 18.815599 },
    { lat: 49.0854167, lng: 18.815586 },
    { lat: 49.084345, lng: 18.815606 },
    { lat: 49.084344, lng: 18.815606 },
    { lat: 49.083024, lng: 18.812583 },
    { lat: 49.08262, lng: 18.812281 },
    { lat: 49.082145, lng: 18.811906 },
    { lat: 49.081075, lng: 18.81104 },
    { lat: 49.080774, lng: 18.81076 },
    { lat: 49.080773, lng: 18.810759 },
    { lat: 49.080547, lng: 18.809588 },
    { lat: 49.080371, lng: 18.808375 },
    { lat: 49.080045, lng: 18.80652 },
    { lat: 49.079735, lng: 18.806588 },
    { lat: 49.079476, lng: 18.806636 },
    { lat: 49.078509, lng: 18.806816 },
    { lat: 49.078189, lng: 18.806727 },
    { lat: 49.0781179, lng: 18.8070197 },
    { lat: 49.0783749, lng: 18.8073223 },
    { lat: 49.0788725, lng: 18.8080931 },
    { lat: 49.0789801, lng: 18.8085556 },
    { lat: 49.0790167, lng: 18.8090667 },
    { lat: 49.0790231, lng: 18.809517 },
    { lat: 49.0791394, lng: 18.8101746 },
    { lat: 49.0793426, lng: 18.8105901 },
    { lat: 49.079409, lng: 18.8109141 },
    { lat: 49.0796437, lng: 18.8116816 },
    { lat: 49.0801919, lng: 18.8145811 },
    { lat: 49.080391, lng: 18.8156191 },
    { lat: 49.0802568, lng: 18.8180361 },
    { lat: 49.0798097, lng: 18.8188746 },
    { lat: 49.0794205, lng: 18.8195471 },
    { lat: 49.0790021, lng: 18.8199627 },
    { lat: 49.0785015, lng: 18.8205086 },
    { lat: 49.0774618, lng: 18.8222305 },
    { lat: 49.0764542, lng: 18.8235446 },
    { lat: 49.0750867, lng: 18.8253847 },
    { lat: 49.0740892, lng: 18.8263751 },
    { lat: 49.0734226, lng: 18.8280938 },
    { lat: 49.0728051, lng: 18.8297836 },
    { lat: 49.072683, lng: 18.8302154 },
    { lat: 49.0720818, lng: 18.8323289 },
    { lat: 49.0716337, lng: 18.8339796 },
    { lat: 49.0687707, lng: 18.8353337 },
    { lat: 49.0681958, lng: 18.8362324 },
    { lat: 49.0677556, lng: 18.8368125 },
    { lat: 49.0671051, lng: 18.8383664 },
    { lat: 49.0670166, lng: 18.8389264 },
    { lat: 49.0669374, lng: 18.8396403 },
    { lat: 49.0669244, lng: 18.8403967 },
    { lat: 49.0665817, lng: 18.8417105 },
    { lat: 49.0664041, lng: 18.8422272 },
    { lat: 49.0657775, lng: 18.8435649 },
    { lat: 49.0657144, lng: 18.8438302 },
    { lat: 49.0654328, lng: 18.8446445 },
    { lat: 49.0653585, lng: 18.8449536 },
    { lat: 49.0652657, lng: 18.8450319 },
    { lat: 49.0652442, lng: 18.8452308 },
    { lat: 49.0651912, lng: 18.8456222 },
    { lat: 49.0651622, lng: 18.8461345 },
    { lat: 49.0651406, lng: 18.8465898 },
    { lat: 49.0650898, lng: 18.8473984 },
    { lat: 49.065153, lng: 18.8478188 },
    { lat: 49.0652217, lng: 18.8483625 },
    { lat: 49.0651875, lng: 18.8493418 },
    { lat: 49.0652315, lng: 18.8498658 },
    { lat: 49.0651156, lng: 18.8503956 },
    { lat: 49.0650623, lng: 18.8509636 },
    { lat: 49.0649695, lng: 18.851326 },
    { lat: 49.0647859, lng: 18.8525345 },
    { lat: 49.0647058, lng: 18.8534354 },
    { lat: 49.0646077, lng: 18.854638 },
    { lat: 49.0645865, lng: 18.8548415 },
    { lat: 49.0644575, lng: 18.8552269 },
    { lat: 49.0642419, lng: 18.8557031 },
    { lat: 49.0641978, lng: 18.8563156 },
    { lat: 49.0640109, lng: 18.8568021 },
    { lat: 49.0640364, lng: 18.8571892 },
    { lat: 49.0638404, lng: 18.8575451 },
    { lat: 49.0637749, lng: 18.8578506 },
    { lat: 49.0635905, lng: 18.8581901 },
    { lat: 49.0635792, lng: 18.858912 },
    { lat: 49.0633683, lng: 18.859484 },
    { lat: 49.0631644, lng: 18.8596819 },
    { lat: 49.0628159, lng: 18.8600135 },
    { lat: 49.0628322, lng: 18.8605689 },
    { lat: 49.0626175, lng: 18.8611093 },
    { lat: 49.0627221, lng: 18.8618063 },
    { lat: 49.062732, lng: 18.8623484 },
    { lat: 49.0627085, lng: 18.8628571 },
    { lat: 49.0626759, lng: 18.8633446 },
    { lat: 49.0626265, lng: 18.8635007 },
    { lat: 49.062676, lng: 18.8635455 },
    { lat: 49.0626911, lng: 18.8635732 },
    { lat: 49.0627051, lng: 18.863582 },
    { lat: 49.0626441, lng: 18.8643911 },
    { lat: 49.0621532, lng: 18.8655263 },
    { lat: 49.0621766, lng: 18.8655447 },
    { lat: 49.0621273, lng: 18.8656602 },
    { lat: 49.0618887, lng: 18.8661082 },
    { lat: 49.0617547, lng: 18.8662873 },
    { lat: 49.0615821, lng: 18.8665042 },
    { lat: 49.0613237, lng: 18.8673345 },
    { lat: 49.0612208, lng: 18.8678986 },
    { lat: 49.061163, lng: 18.8685771 },
    { lat: 49.0610674, lng: 18.8691328 },
    { lat: 49.0611535, lng: 18.8698435 },
    { lat: 49.0609321, lng: 18.8708887 },
    { lat: 49.0607769, lng: 18.8724557 },
    { lat: 49.060283, lng: 18.873819 },
    { lat: 49.0603038, lng: 18.8740078 },
    { lat: 49.0600515, lng: 18.8744282 },
    { lat: 49.0598651, lng: 18.8747973 },
    { lat: 49.0597274, lng: 18.8750369 },
    { lat: 49.0595926, lng: 18.8754631 },
    { lat: 49.0595069, lng: 18.8755513 },
    { lat: 49.0595051, lng: 18.8756925 },
    { lat: 49.0589591, lng: 18.8767438 },
    { lat: 49.0586186, lng: 18.8772688 },
    { lat: 49.0585043, lng: 18.8777451 },
    { lat: 49.0584786, lng: 18.8778387 },
    { lat: 49.0582114, lng: 18.8792031 },
    { lat: 49.0577483, lng: 18.8795633 },
    { lat: 49.0576828, lng: 18.8796692 },
    { lat: 49.057438, lng: 18.8800886 },
    { lat: 49.0573973, lng: 18.8804142 },
    { lat: 49.0574268, lng: 18.8808568 },
    { lat: 49.0572834, lng: 18.8809619 },
    { lat: 49.0572747, lng: 18.8810381 },
    { lat: 49.0571914, lng: 18.8812795 },
    { lat: 49.0570926, lng: 18.8817952 },
    { lat: 49.0570097, lng: 18.8819391 },
    { lat: 49.0569258, lng: 18.88204 },
    { lat: 49.0568635, lng: 18.88223 },
    { lat: 49.0566155, lng: 18.8824769 },
    { lat: 49.0565936, lng: 18.8825978 },
    { lat: 49.0565079, lng: 18.8827111 },
    { lat: 49.0564489, lng: 18.8826789 },
    { lat: 49.0564231, lng: 18.882701 },
    { lat: 49.0564226, lng: 18.8827604 },
    { lat: 49.0564042, lng: 18.8827993 },
    { lat: 49.0562634, lng: 18.8828444 },
    { lat: 49.0562108, lng: 18.8828984 },
    { lat: 49.0561635, lng: 18.8829191 },
    { lat: 49.0561653, lng: 18.8829789 },
    { lat: 49.056087, lng: 18.8830964 },
    { lat: 49.0559252, lng: 18.8831983 },
    { lat: 49.0557098, lng: 18.8833946 },
    { lat: 49.0556164, lng: 18.8835311 },
    { lat: 49.0555686, lng: 18.883701 },
    { lat: 49.055471, lng: 18.8837274 },
    { lat: 49.0554295, lng: 18.8837794 },
    { lat: 49.0554034, lng: 18.8839942 },
    { lat: 49.0554507, lng: 18.8841022 },
    { lat: 49.0554345, lng: 18.8842698 },
    { lat: 49.0553673, lng: 18.8844565 },
    { lat: 49.0553733, lng: 18.8845108 },
    { lat: 49.0552578, lng: 18.8848066 },
    { lat: 49.0551877, lng: 18.8848484 },
    { lat: 49.0551858, lng: 18.8849651 },
    { lat: 49.055049, lng: 18.8848907 },
    { lat: 49.0550376, lng: 18.8849992 },
    { lat: 49.0547193, lng: 18.8850351 },
    { lat: 49.0546791, lng: 18.8851099 },
    { lat: 49.05473, lng: 18.8852441 },
    { lat: 49.0546474, lng: 18.885306 },
    { lat: 49.0546067, lng: 18.8852591 },
    { lat: 49.0545345, lng: 18.8852565 },
    { lat: 49.0545218, lng: 18.8852939 },
    { lat: 49.054525, lng: 18.8853813 },
    { lat: 49.0544752, lng: 18.8855101 },
    { lat: 49.0543959, lng: 18.8855677 },
    { lat: 49.0543176, lng: 18.8856492 },
    { lat: 49.0542727, lng: 18.8856312 },
    { lat: 49.0542093, lng: 18.8856886 },
    { lat: 49.0541858, lng: 18.8856918 },
    { lat: 49.0541125, lng: 18.8856721 },
    { lat: 49.0540873, lng: 18.8856866 },
    { lat: 49.0540718, lng: 18.8857995 },
    { lat: 49.0540467, lng: 18.8858175 },
    { lat: 49.0539562, lng: 18.8857891 },
    { lat: 49.0539323, lng: 18.8857614 },
    { lat: 49.0537028, lng: 18.8860139 },
    { lat: 49.0536682, lng: 18.8862423 },
    { lat: 49.0536028, lng: 18.8862652 },
    { lat: 49.0535391, lng: 18.8863881 },
    { lat: 49.0534311, lng: 18.8864563 },
    { lat: 49.0533746, lng: 18.8866086 },
    { lat: 49.0533667, lng: 18.8866753 },
    { lat: 49.0532872, lng: 18.886794 },
    { lat: 49.0533411, lng: 18.8869465 },
    { lat: 49.0532421, lng: 18.8870628 },
    { lat: 49.0531045, lng: 18.8871251 },
    { lat: 49.0531027, lng: 18.8872228 },
    { lat: 49.0531528, lng: 18.8873617 },
    { lat: 49.0531029, lng: 18.8874898 },
    { lat: 49.0531306, lng: 18.887635 },
    { lat: 49.0530603, lng: 18.887914 },
    { lat: 49.0530891, lng: 18.8879688 },
    { lat: 49.0530399, lng: 18.8881767 },
    { lat: 49.053028, lng: 18.8882766 },
    { lat: 49.0529712, lng: 18.8883347 },
    { lat: 49.0529463, lng: 18.8883966 },
    { lat: 49.0529551, lng: 18.8884808 },
    { lat: 49.0529233, lng: 18.8885442 },
    { lat: 49.0529179, lng: 18.8886362 },
    { lat: 49.0528573, lng: 18.8887501 },
    { lat: 49.0528393, lng: 18.8888644 },
    { lat: 49.0527981, lng: 18.8889365 },
    { lat: 49.0526856, lng: 18.8888995 },
    { lat: 49.052663, lng: 18.8889884 },
    { lat: 49.0527138, lng: 18.8893397 },
    { lat: 49.052687, lng: 18.8893875 },
    { lat: 49.0527103, lng: 18.8898898 },
    { lat: 49.0527398, lng: 18.8902691 },
    { lat: 49.0528149, lng: 18.8904335 },
    { lat: 49.0528681, lng: 18.8905922 },
    { lat: 49.0528672, lng: 18.8907077 },
    { lat: 49.0528984, lng: 18.8908097 },
    { lat: 49.0521323, lng: 18.891043 },
    { lat: 49.0520958, lng: 18.891212 },
    { lat: 49.0520364, lng: 18.8916576 },
    { lat: 49.052016, lng: 18.8918796 },
    { lat: 49.0519892, lng: 18.8922379 },
    { lat: 49.0520105, lng: 18.8926542 },
    { lat: 49.0520884, lng: 18.8928093 },
    { lat: 49.0521731, lng: 18.8932982 },
    { lat: 49.0521971, lng: 18.8937436 },
    { lat: 49.052231, lng: 18.8941451 },
    { lat: 49.0522094, lng: 18.8941846 },
    { lat: 49.0521607, lng: 18.8942238 },
    { lat: 49.0522914, lng: 18.8946649 },
    { lat: 49.0523594, lng: 18.8949376 },
    { lat: 49.0524168, lng: 18.8953295 },
    { lat: 49.052416, lng: 18.8955826 },
    { lat: 49.0523986, lng: 18.8959682 },
    { lat: 49.0523809, lng: 18.8965687 },
    { lat: 49.0523408, lng: 18.8970042 },
    { lat: 49.0522972, lng: 18.8977804 },
    { lat: 49.0522445, lng: 18.8987155 },
    { lat: 49.0521526, lng: 18.9001351 },
    { lat: 49.052116, lng: 18.900679 },
    { lat: 49.0520893, lng: 18.9009503 },
    { lat: 49.052042, lng: 18.9012604 },
    { lat: 49.0518961, lng: 18.9021795 },
    { lat: 49.0517584, lng: 18.9030646 },
    { lat: 49.0517197, lng: 18.9033847 },
    { lat: 49.0516919, lng: 18.9039229 },
    { lat: 49.0516978, lng: 18.9042162 },
    { lat: 49.0516845, lng: 18.9046218 },
    { lat: 49.0517363, lng: 18.9052383 },
    { lat: 49.0518498, lng: 18.9058061 },
    { lat: 49.0504262, lng: 18.9059893 },
    { lat: 49.0497119, lng: 18.9060653 },
    { lat: 49.0493496, lng: 18.9060595 },
    { lat: 49.0483878, lng: 18.9058373 },
    { lat: 49.0482989, lng: 18.9057939 },
    { lat: 49.0481199, lng: 18.9057356 },
    { lat: 49.0479011, lng: 18.9057309 },
    { lat: 49.0469344, lng: 18.905459 },
    { lat: 49.0461317, lng: 18.9052155 },
    { lat: 49.045892, lng: 18.9052285 },
    { lat: 49.0456796, lng: 18.9052401 },
    { lat: 49.0453648, lng: 18.905457 },
    { lat: 49.0441165, lng: 18.9070674 },
    { lat: 49.0438363, lng: 18.9072978 },
    { lat: 49.0435412, lng: 18.9074131 },
    { lat: 49.0431839, lng: 18.9074581 },
    { lat: 49.0427456, lng: 18.9073819 },
    { lat: 49.042747, lng: 18.9075363 },
    { lat: 49.0427317, lng: 18.9076578 },
    { lat: 49.0427101, lng: 18.9077207 },
    { lat: 49.0427238, lng: 18.9077899 },
    { lat: 49.0427043, lng: 18.9079169 },
    { lat: 49.0427303, lng: 18.9080285 },
    { lat: 49.0427118, lng: 18.9081532 },
    { lat: 49.0426965, lng: 18.908362 },
    { lat: 49.0427353, lng: 18.9086554 },
    { lat: 49.0427285, lng: 18.9090317 },
    { lat: 49.0427319, lng: 18.9090579 },
    { lat: 49.0427136, lng: 18.9091844 },
    { lat: 49.0427346, lng: 18.9094389 },
    { lat: 49.0427065, lng: 18.9096385 },
    { lat: 49.0426726, lng: 18.9098178 },
    { lat: 49.0426122, lng: 18.910005 },
    { lat: 49.0416288, lng: 18.9097763 },
    { lat: 49.0412642, lng: 18.9096997 },
    { lat: 49.0412279, lng: 18.9096971 },
    { lat: 49.0410568, lng: 18.9099232 },
    { lat: 49.0406162, lng: 18.9104801 },
    { lat: 49.0405796, lng: 18.9104941 },
    { lat: 49.0404022, lng: 18.9107084 },
    { lat: 49.0399875, lng: 18.9112223 },
    { lat: 49.0397858, lng: 18.9114642 },
    { lat: 49.0394897, lng: 18.9118287 },
    { lat: 49.0392094, lng: 18.9121471 },
    { lat: 49.0389901, lng: 18.9124018 },
    { lat: 49.038574, lng: 18.9129105 },
    { lat: 49.0380916, lng: 18.9134873 },
    { lat: 49.0373788, lng: 18.9143673 },
    { lat: 49.0367136, lng: 18.9151863 },
    { lat: 49.0364512, lng: 18.915504 },
    { lat: 49.0362222, lng: 18.91579 },
    { lat: 49.036229, lng: 18.9158115 },
    { lat: 49.0359963, lng: 18.9160678 },
    { lat: 49.0354354, lng: 18.9166564 },
    { lat: 49.0354799, lng: 18.9167733 },
    { lat: 49.035444, lng: 18.9168907 },
    { lat: 49.0356662, lng: 18.917115 },
    { lat: 49.0357622, lng: 18.9173164 },
    { lat: 49.0359103, lng: 18.9175254 },
    { lat: 49.0357886, lng: 18.9176996 },
    { lat: 49.0357651, lng: 18.9177494 },
    { lat: 49.0356078, lng: 18.9179368 },
    { lat: 49.0353432, lng: 18.9183863 },
    { lat: 49.0351796, lng: 18.9182313 },
    { lat: 49.034921, lng: 18.9180246 },
    { lat: 49.0348064, lng: 18.9182968 },
    { lat: 49.0347919, lng: 18.918366 },
    { lat: 49.0347451, lng: 18.9184855 },
    { lat: 49.0347174, lng: 18.9184984 },
    { lat: 49.0346314, lng: 18.9188887 },
    { lat: 49.0344659, lng: 18.9193829 },
    { lat: 49.034416, lng: 18.9195533 },
    { lat: 49.0344087, lng: 18.9196512 },
    { lat: 49.0342966, lng: 18.9197981 },
    { lat: 49.0342576, lng: 18.9198763 },
    { lat: 49.0342391, lng: 18.9198896 },
    { lat: 49.0341609, lng: 18.9200142 },
    { lat: 49.0341162, lng: 18.9201111 },
    { lat: 49.0340636, lng: 18.9201834 },
    { lat: 49.0338487, lng: 18.9203937 },
    { lat: 49.0339012, lng: 18.9205385 },
    { lat: 49.0337757, lng: 18.9207501 },
    { lat: 49.0337062, lng: 18.9209117 },
    { lat: 49.0338497, lng: 18.9211194 },
    { lat: 49.0342325, lng: 18.9216213 },
    { lat: 49.0345464, lng: 18.9220507 },
    { lat: 49.0347464, lng: 18.9222992 },
    { lat: 49.0349437, lng: 18.9225707 },
    { lat: 49.0350889, lng: 18.9227857 },
    { lat: 49.0352209, lng: 18.922966 },
    { lat: 49.0353939, lng: 18.9231533 },
    { lat: 49.0354753, lng: 18.9233561 },
    { lat: 49.0355433, lng: 18.9234523 },
    { lat: 49.035714, lng: 18.9237244 },
    { lat: 49.0360703, lng: 18.9243013 },
    { lat: 49.0361997, lng: 18.9244535 },
    { lat: 49.0363403, lng: 18.9246439 },
    { lat: 49.0364819, lng: 18.9248591 },
    { lat: 49.0364915, lng: 18.9248899 },
    { lat: 49.0365804, lng: 18.9250203 },
    { lat: 49.0366373, lng: 18.9250111 },
    { lat: 49.0367058, lng: 18.9252251 },
    { lat: 49.0367632, lng: 18.9253362 },
    { lat: 49.0369069, lng: 18.9254994 },
    { lat: 49.0372896, lng: 18.9258425 },
    { lat: 49.0372799, lng: 18.9259395 },
    { lat: 49.03719, lng: 18.9263659 },
    { lat: 49.037102, lng: 18.9266978 },
    { lat: 49.037115, lng: 18.926711 },
    { lat: 49.0370362, lng: 18.9269366 },
    { lat: 49.0369049, lng: 18.9272582 },
    { lat: 49.0367802, lng: 18.9276221 },
    { lat: 49.0366681, lng: 18.9278468 },
    { lat: 49.0366608, lng: 18.9278657 },
    { lat: 49.0366766, lng: 18.9279341 },
    { lat: 49.0363015, lng: 18.9287194 },
    { lat: 49.0362719, lng: 18.9287786 },
    { lat: 49.0360723, lng: 18.9290015 },
    { lat: 49.0359025, lng: 18.9291663 },
    { lat: 49.0359821, lng: 18.9293326 },
    { lat: 49.0361283, lng: 18.929723 },
    { lat: 49.0361461, lng: 18.9297435 },
    { lat: 49.0362366, lng: 18.9300369 },
    { lat: 49.0363738, lng: 18.9303655 },
    { lat: 49.0365144, lng: 18.9306716 },
    { lat: 49.0364766, lng: 18.9307454 },
    { lat: 49.0364162, lng: 18.9308182 },
    { lat: 49.0363501, lng: 18.9308708 },
    { lat: 49.0360923, lng: 18.9311264 },
    { lat: 49.0360253, lng: 18.9312259 },
    { lat: 49.035876, lng: 18.9315521 },
    { lat: 49.0358231, lng: 18.9317053 },
    { lat: 49.0357778, lng: 18.9318792 },
    { lat: 49.0357783, lng: 18.9321109 },
    { lat: 49.0356982, lng: 18.9322881 },
    { lat: 49.0356762, lng: 18.9324786 },
    { lat: 49.0356171, lng: 18.9328693 },
    { lat: 49.035573, lng: 18.9330897 },
    { lat: 49.035546, lng: 18.933288 },
    { lat: 49.0354602, lng: 18.9338181 },
    { lat: 49.0354155, lng: 18.9341374 },
    { lat: 49.0354062, lng: 18.934266 },
    { lat: 49.0352697, lng: 18.9352217 },
    { lat: 49.0352087, lng: 18.9359285 },
    { lat: 49.0352014, lng: 18.9363149 },
    { lat: 49.0352233, lng: 18.9368585 },
    { lat: 49.0352168, lng: 18.9370012 },
    { lat: 49.0352216, lng: 18.9370258 },
    { lat: 49.0352236, lng: 18.9370457 },
    { lat: 49.0354743, lng: 18.9369397 },
    { lat: 49.0356599, lng: 18.9368676 },
    { lat: 49.0357629, lng: 18.9368516 },
    { lat: 49.035971, lng: 18.9368192 },
    { lat: 49.0362741, lng: 18.9367912 },
    { lat: 49.0365575, lng: 18.9367802 },
    { lat: 49.0369478, lng: 18.9367847 },
    { lat: 49.0369955, lng: 18.9367899 },
    { lat: 49.0375762, lng: 18.9368816 },
    { lat: 49.0377784, lng: 18.9369319 },
    { lat: 49.0379468, lng: 18.9369653 },
    { lat: 49.0383333, lng: 18.9370238 },
    { lat: 49.0385763, lng: 18.9370833 },
    { lat: 49.0387454, lng: 18.9371476 },
    { lat: 49.0389368, lng: 18.9372559 },
    { lat: 49.0390292, lng: 18.9373213 },
    { lat: 49.0392435, lng: 18.9374974 },
    { lat: 49.0393969, lng: 18.9376346 },
    { lat: 49.0397588, lng: 18.9379878 },
    { lat: 49.0400158, lng: 18.9382336 },
    { lat: 49.0402702, lng: 18.9384467 },
    { lat: 49.040426, lng: 18.938607 },
    { lat: 49.0406288, lng: 18.9387816 },
    { lat: 49.0411279, lng: 18.9391287 },
    { lat: 49.0416818, lng: 18.9394705 },
    { lat: 49.0420576, lng: 18.9396988 },
    { lat: 49.0424375, lng: 18.9398986 },
    { lat: 49.0427687, lng: 18.940051 },
    { lat: 49.0431612, lng: 18.9401652 },
    { lat: 49.0433972, lng: 18.9402152 },
    { lat: 49.0436965, lng: 18.940248 },
    { lat: 49.0439475, lng: 18.9402408 },
    { lat: 49.0444627, lng: 18.9401512 },
    { lat: 49.0447339, lng: 18.9400709 },
    { lat: 49.0459124, lng: 18.9396102 },
    { lat: 49.0463719, lng: 18.9394905 },
    { lat: 49.0468811, lng: 18.9394161 },
    { lat: 49.0472957, lng: 18.9393645 },
    { lat: 49.0475016, lng: 18.9393156 },
    { lat: 49.0476512, lng: 18.9392621 },
    { lat: 49.0479878, lng: 18.9390961 },
    { lat: 49.0482657, lng: 18.9389772 },
    { lat: 49.0488462, lng: 18.9388379 },
    { lat: 49.0491679, lng: 18.9386679 },
    { lat: 49.0494188, lng: 18.9386118 },
    { lat: 49.0494214, lng: 18.9386216 },
    { lat: 49.0506797, lng: 18.940757 },
    { lat: 49.0514041, lng: 18.9419692 },
    { lat: 49.0519055, lng: 18.9428975 },
    { lat: 49.0534907, lng: 18.9457935 },
    { lat: 49.0536522, lng: 18.9457077 },
    { lat: 49.0538277, lng: 18.9456328 },
    { lat: 49.0545567, lng: 18.9480514 },
    { lat: 49.0545394, lng: 18.9480886 },
    { lat: 49.0545711, lng: 18.9481119 },
    { lat: 49.0544935, lng: 18.9482094 },
    { lat: 49.0544835, lng: 18.9482541 },
    { lat: 49.0545607, lng: 18.9484197 },
    { lat: 49.0546271, lng: 18.9484163 },
    { lat: 49.0546434, lng: 18.9483831 },
    { lat: 49.0546416, lng: 18.9482823 },
    { lat: 49.0546678, lng: 18.9482627 },
    { lat: 49.0547204, lng: 18.9483036 },
    { lat: 49.0547447, lng: 18.9481347 },
    { lat: 49.0547872, lng: 18.9481301 },
    { lat: 49.054827, lng: 18.948249 },
    { lat: 49.054869, lng: 18.948252 },
    { lat: 49.0549052, lng: 18.9482765 },
    { lat: 49.0549171, lng: 18.9483338 },
    { lat: 49.0550765, lng: 18.948342 },
    { lat: 49.0551008, lng: 18.948332 },
    { lat: 49.0551289, lng: 18.9483096 },
    { lat: 49.0552085, lng: 18.9481867 },
    { lat: 49.0553057, lng: 18.9481279 },
    { lat: 49.0553156, lng: 18.9480121 },
    { lat: 49.0553764, lng: 18.9479424 },
    { lat: 49.0554267, lng: 18.9479573 },
    { lat: 49.0554862, lng: 18.9480812 },
    { lat: 49.0555583, lng: 18.9480595 },
    { lat: 49.0555755, lng: 18.9480682 },
    { lat: 49.0555763, lng: 18.9481256 },
    { lat: 49.0555632, lng: 18.9482264 },
    { lat: 49.0556052, lng: 18.9482768 },
    { lat: 49.0556318, lng: 18.9482647 },
    { lat: 49.0556814, lng: 18.9481996 },
    { lat: 49.0556948, lng: 18.9480855 },
    { lat: 49.0557942, lng: 18.9480904 },
    { lat: 49.0559496, lng: 18.9480179 },
    { lat: 49.0562166, lng: 18.948304 },
    { lat: 49.0562301, lng: 18.9484358 },
    { lat: 49.0562227, lng: 18.9484669 },
    { lat: 49.0561886, lng: 18.9485298 },
    { lat: 49.0561778, lng: 18.9486062 },
    { lat: 49.0562238, lng: 18.9486476 },
    { lat: 49.0563195, lng: 18.948513 },
    { lat: 49.0563145, lng: 18.9483432 },
    { lat: 49.0564611, lng: 18.9480338 },
    { lat: 49.0564901, lng: 18.9480045 },
    { lat: 49.0565666, lng: 18.9480031 },
    { lat: 49.0566645, lng: 18.9481541 },
    { lat: 49.0567043, lng: 18.9481617 },
    { lat: 49.0567215, lng: 18.9481223 },
    { lat: 49.0567064, lng: 18.9480665 },
    { lat: 49.0567349, lng: 18.9480093 },
    { lat: 49.0569756, lng: 18.947953 },
    { lat: 49.0570481, lng: 18.9478259 },
    { lat: 49.0571052, lng: 18.9477966 },
    { lat: 49.0572949, lng: 18.9477351 },
    { lat: 49.0574298, lng: 18.948081 },
    { lat: 49.057465, lng: 18.9482217 },
    { lat: 49.0575747, lng: 18.9482506 },
    { lat: 49.0576414, lng: 18.9480752 },
    { lat: 49.0576902, lng: 18.9479049 },
    { lat: 49.0577244, lng: 18.9478243 },
    { lat: 49.0577765, lng: 18.9477794 },
    { lat: 49.0578727, lng: 18.9478321 },
    { lat: 49.0579371, lng: 18.947794 },
    { lat: 49.0579619, lng: 18.9480175 },
    { lat: 49.058104, lng: 18.9482626 },
    { lat: 49.0585245, lng: 18.9479027 },
    { lat: 49.0586226, lng: 18.9480616 },
    { lat: 49.0587471, lng: 18.9479118 },
    { lat: 49.0587912, lng: 18.94798 },
    { lat: 49.0590546, lng: 18.9476626 },
    { lat: 49.0591548, lng: 18.9478371 },
    { lat: 49.0597132, lng: 18.9472888 },
    { lat: 49.0600614, lng: 18.9476201 },
    { lat: 49.0606388, lng: 18.9472882 },
    { lat: 49.0609095, lng: 18.947758 },
    { lat: 49.0615048, lng: 18.9472018 },
    { lat: 49.0617432, lng: 18.9477023 },
    { lat: 49.0629144, lng: 18.946874 },
    { lat: 49.0631536, lng: 18.9466989 },
    { lat: 49.0634715, lng: 18.9465653 },
    { lat: 49.0641049, lng: 18.9481431 },
    { lat: 49.0642442, lng: 18.9481321 },
    { lat: 49.0639942, lng: 18.9494299 },
    { lat: 49.0635169, lng: 18.9509358 },
    { lat: 49.0638909, lng: 18.9510203 },
    { lat: 49.0646974, lng: 18.9512062 },
    { lat: 49.0646415, lng: 18.9515709 },
    { lat: 49.0647374, lng: 18.952131 },
    { lat: 49.0650039, lng: 18.9530097 },
    { lat: 49.0650545, lng: 18.9529826 },
    { lat: 49.0652717, lng: 18.9536428 },
    { lat: 49.065368, lng: 18.9537875 },
    { lat: 49.0654092, lng: 18.9538909 },
    { lat: 49.0656128, lng: 18.9545258 },
    { lat: 49.0656902, lng: 18.9548737 },
    { lat: 49.0657619, lng: 18.9554467 },
    { lat: 49.0657543, lng: 18.9556525 },
    { lat: 49.0657249, lng: 18.955783 },
    { lat: 49.0656127, lng: 18.9565327 },
    { lat: 49.0655279, lng: 18.9569503 },
    { lat: 49.065292, lng: 18.9582845 },
    { lat: 49.0652557, lng: 18.9585016 },
    { lat: 49.065191, lng: 18.9588065 },
    { lat: 49.0650712, lng: 18.9592911 },
    { lat: 49.064973, lng: 18.9597801 },
    { lat: 49.0648787, lng: 18.9603689 },
    { lat: 49.0648325, lng: 18.9607263 },
    { lat: 49.0647812, lng: 18.9611601 },
    { lat: 49.0647367, lng: 18.9615978 },
    { lat: 49.0646963, lng: 18.962048 },
    { lat: 49.0645918, lng: 18.9636188 },
    { lat: 49.0645704, lng: 18.9640635 },
    { lat: 49.0645706, lng: 18.9645837 },
    { lat: 49.0661253, lng: 18.9648079 },
    { lat: 49.0671576, lng: 18.9651086 },
    { lat: 49.0680574, lng: 18.9649999 },
    { lat: 49.0684422, lng: 18.9650555 },
    { lat: 49.068609, lng: 18.9650519 },
    { lat: 49.0687844, lng: 18.9649972 },
    { lat: 49.0689472, lng: 18.9648768 },
    { lat: 49.0691175, lng: 18.9646742 },
    { lat: 49.0692845, lng: 18.9645429 },
    { lat: 49.0692871, lng: 18.9645004 },
    { lat: 49.0699031, lng: 18.9646708 },
    { lat: 49.0702252, lng: 18.9647058 },
    { lat: 49.0706049, lng: 18.9646607 },
    { lat: 49.0709006, lng: 18.9645312 },
    { lat: 49.0712572, lng: 18.9650066 },
    { lat: 49.0716945, lng: 18.9644219 },
    { lat: 49.0721417, lng: 18.9636112 },
    { lat: 49.0726706, lng: 18.9631657 },
    { lat: 49.0730869, lng: 18.9624838 },
    { lat: 49.0732686, lng: 18.9622824 },
    { lat: 49.0737176, lng: 18.9624025 },
    { lat: 49.0747244, lng: 18.9619557 },
    { lat: 49.0749593, lng: 18.9618966 },
    { lat: 49.0755019, lng: 18.9619935 },
    { lat: 49.0761948, lng: 18.9621376 },
    { lat: 49.076478, lng: 18.9621001 },
    { lat: 49.0768509, lng: 18.9620508 },
    { lat: 49.0771863, lng: 18.9617369 },
    { lat: 49.0776058, lng: 18.9613446 },
    { lat: 49.0777324, lng: 18.9612881 },
    { lat: 49.0785432, lng: 18.9613364 },
    { lat: 49.0786703, lng: 18.9613079 },
    { lat: 49.0788005, lng: 18.9611446 },
    { lat: 49.0789174, lng: 18.9609981 },
    { lat: 49.0793458, lng: 18.9604576 },
    { lat: 49.079653, lng: 18.9600701 },
    { lat: 49.0797786, lng: 18.9599705 },
    { lat: 49.0798965, lng: 18.9599113 },
    { lat: 49.0803426, lng: 18.9596873 },
    { lat: 49.0803784, lng: 18.9597446 },
    { lat: 49.080667, lng: 18.9596544 },
    { lat: 49.0807056, lng: 18.9597078 },
    { lat: 49.0811928, lng: 18.9593965 },
    { lat: 49.0817363, lng: 18.9591311 },
    { lat: 49.0820619, lng: 18.9590153 },
    { lat: 49.0821579, lng: 18.9589509 },
    { lat: 49.0831956, lng: 18.9588927 },
    { lat: 49.0845296, lng: 18.9588297 },
    { lat: 49.0849231, lng: 18.9587689 },
    { lat: 49.0853892, lng: 18.9586187 },
    { lat: 49.0857804, lng: 18.9584621 },
    { lat: 49.0861356, lng: 18.958395 },
    { lat: 49.0863965, lng: 18.9586079 },
    { lat: 49.0873436, lng: 18.9585003 },
    { lat: 49.0885132, lng: 18.958349 },
    { lat: 49.0900911, lng: 18.9578941 },
    { lat: 49.0914397, lng: 18.9574521 },
    { lat: 49.0925769, lng: 18.957179 },
    { lat: 49.0943467, lng: 18.9567508 },
    { lat: 49.0944888, lng: 18.9566417 },
    { lat: 49.0947463, lng: 18.9565141 },
    { lat: 49.0955485, lng: 18.9563248 },
    { lat: 49.0962623, lng: 18.9561487 },
    { lat: 49.0965347, lng: 18.9561395 },
    { lat: 49.0968071, lng: 18.9562015 },
    { lat: 49.0970815, lng: 18.9561848 },
    { lat: 49.0976503, lng: 18.9560437 },
    { lat: 49.0983016, lng: 18.9559674 },
    { lat: 49.0987242, lng: 18.9558737 },
    { lat: 49.1001516, lng: 18.9554751 },
    { lat: 49.1014453, lng: 18.955153 },
    { lat: 49.102121, lng: 18.9550044 },
    { lat: 49.1034696, lng: 18.9547402 },
    { lat: 49.1041732, lng: 18.9546088 },
    { lat: 49.1050074, lng: 18.9544049 },
    { lat: 49.1057124, lng: 18.9541899 },
    { lat: 49.1068572, lng: 18.9538654 },
    { lat: 49.1075664, lng: 18.9537301 },
    { lat: 49.1084944, lng: 18.9535573 },
    { lat: 49.1101814, lng: 18.9532947 },
    { lat: 49.1113399, lng: 18.952929 },
    { lat: 49.1115611, lng: 18.9528469 },
  ],
  Podhradie: [
    { lat: 49.1002776, lng: 19.0602713 },
    { lat: 49.1008449, lng: 19.0601395 },
    { lat: 49.1014096, lng: 19.0592766 },
    { lat: 49.1018933, lng: 19.0592975 },
    { lat: 49.1029135, lng: 19.0578922 },
    { lat: 49.1035692, lng: 19.0578642 },
    { lat: 49.1054596, lng: 19.0562009 },
    { lat: 49.1064456, lng: 19.0552872 },
    { lat: 49.1066118, lng: 19.055181 },
    { lat: 49.1067822, lng: 19.0550718 },
    { lat: 49.1069154, lng: 19.0549863 },
    { lat: 49.1066427, lng: 19.0536051 },
    { lat: 49.1065384, lng: 19.0526849 },
    { lat: 49.1064678, lng: 19.0520573 },
    { lat: 49.1063355, lng: 19.0506154 },
    { lat: 49.1063248, lng: 19.0505151 },
    { lat: 49.1062439, lng: 19.0497052 },
    { lat: 49.1062173, lng: 19.0494406 },
    { lat: 49.105801, lng: 19.0474863 },
    { lat: 49.1056654, lng: 19.0464107 },
    { lat: 49.1053747, lng: 19.0446457 },
    { lat: 49.1052286, lng: 19.0421648 },
    { lat: 49.1051927, lng: 19.0417394 },
    { lat: 49.1050291, lng: 19.041034 },
    { lat: 49.104182, lng: 19.0387202 },
    { lat: 49.1039807, lng: 19.0382531 },
    { lat: 49.1037456, lng: 19.0384831 },
    { lat: 49.1036381, lng: 19.0386121 },
    { lat: 49.1026576, lng: 19.0396568 },
    { lat: 49.1025992, lng: 19.0395459 },
    { lat: 49.1020115, lng: 19.0401538 },
    { lat: 49.0985149, lng: 19.0427663 },
    { lat: 49.0986035, lng: 19.0431845 },
    { lat: 49.0967679, lng: 19.0442885 },
    { lat: 49.0963669, lng: 19.0447506 },
    { lat: 49.0963615, lng: 19.0447512 },
    { lat: 49.0963324, lng: 19.0447553 },
    { lat: 49.0955254, lng: 19.0448717 },
    { lat: 49.0932128, lng: 19.0471298 },
    { lat: 49.0925761, lng: 19.0468615 },
    { lat: 49.0907637, lng: 19.0469129 },
    { lat: 49.0902695, lng: 19.046926 },
    { lat: 49.0901676, lng: 19.0468072 },
    { lat: 49.0901187, lng: 19.0467488 },
    { lat: 49.0900994, lng: 19.046727 },
    { lat: 49.0900057, lng: 19.0466763 },
    { lat: 49.0881377, lng: 19.0467909 },
    { lat: 49.0878263, lng: 19.0468099 },
    { lat: 49.087805, lng: 19.0467858 },
    { lat: 49.0877725, lng: 19.0468348 },
    { lat: 49.0877409, lng: 19.0468831 },
    { lat: 49.0877157, lng: 19.0474399 },
    { lat: 49.0876575, lng: 19.0474962 },
    { lat: 49.0870058, lng: 19.0475152 },
    { lat: 49.0859698, lng: 19.0470373 },
    { lat: 49.0859036, lng: 19.0469521 },
    { lat: 49.0858912, lng: 19.0469291 },
    { lat: 49.0855281, lng: 19.0469296 },
    { lat: 49.0854753, lng: 19.0468886 },
    { lat: 49.0854495, lng: 19.0469358 },
    { lat: 49.084622, lng: 19.0466845 },
    { lat: 49.0846043, lng: 19.0466887 },
    { lat: 49.0842278, lng: 19.046466 },
    { lat: 49.0834088, lng: 19.0465 },
    { lat: 49.0833909, lng: 19.0465017 },
    { lat: 49.0829711, lng: 19.0466058 },
    { lat: 49.082953, lng: 19.0466065 },
    { lat: 49.0822069, lng: 19.0467896 },
    { lat: 49.0821894, lng: 19.0467758 },
    { lat: 49.0814503, lng: 19.0466959 },
    { lat: 49.0814339, lng: 19.0466845 },
    { lat: 49.0813936, lng: 19.0466676 },
    { lat: 49.0813545, lng: 19.0466512 },
    { lat: 49.081077, lng: 19.0465332 },
    { lat: 49.0792192, lng: 19.0465788 },
    { lat: 49.0783921, lng: 19.0462692 },
    { lat: 49.0779538, lng: 19.0462511 },
    { lat: 49.0778, lng: 19.0461519 },
    { lat: 49.0776698, lng: 19.0461601 },
    { lat: 49.0774801, lng: 19.0460655 },
    { lat: 49.0774081, lng: 19.0460669 },
    { lat: 49.0772441, lng: 19.0461268 },
    { lat: 49.0767163, lng: 19.0459406 },
    { lat: 49.0764645, lng: 19.0457705 },
    { lat: 49.0763358, lng: 19.0457238 },
    { lat: 49.0762215, lng: 19.0456913 },
    { lat: 49.0760696, lng: 19.04572 },
    { lat: 49.0759368, lng: 19.0457698 },
    { lat: 49.0758161, lng: 19.0457905 },
    { lat: 49.0756562, lng: 19.0457471 },
    { lat: 49.0755959, lng: 19.0457127 },
    { lat: 49.0750171, lng: 19.0464109 },
    { lat: 49.0748372, lng: 19.0465967 },
    { lat: 49.0746285, lng: 19.0468472 },
    { lat: 49.074443, lng: 19.0470125 },
    { lat: 49.0742684, lng: 19.0471289 },
    { lat: 49.0739912, lng: 19.0472706 },
    { lat: 49.0738523, lng: 19.0473757 },
    { lat: 49.0736701, lng: 19.0475204 },
    { lat: 49.0732145, lng: 19.0475179 },
    { lat: 49.0729193, lng: 19.0474764 },
    { lat: 49.0727792, lng: 19.0474721 },
    { lat: 49.0726993, lng: 19.047495 },
    { lat: 49.0725604, lng: 19.0475793 },
    { lat: 49.0725198, lng: 19.047603 },
    { lat: 49.0724191, lng: 19.0476646 },
    { lat: 49.0715842, lng: 19.048646 },
    { lat: 49.0712476, lng: 19.049131 },
    { lat: 49.0710902, lng: 19.0496845 },
    { lat: 49.0698305, lng: 19.0505569 },
    { lat: 49.0692527, lng: 19.0513648 },
    { lat: 49.0689369, lng: 19.0521767 },
    { lat: 49.0686099, lng: 19.0526742 },
    { lat: 49.0682256, lng: 19.0530136 },
    { lat: 49.0673805, lng: 19.0539755 },
    { lat: 49.0667507, lng: 19.0543705 },
    { lat: 49.0661986, lng: 19.0548734 },
    { lat: 49.0656252, lng: 19.0555843 },
    { lat: 49.0649809, lng: 19.0562279 },
    { lat: 49.0644934, lng: 19.0576702 },
    { lat: 49.0643243, lng: 19.0580808 },
    { lat: 49.0640365, lng: 19.0582785 },
    { lat: 49.0636636, lng: 19.0585756 },
    { lat: 49.0631298, lng: 19.0588153 },
    { lat: 49.0623992, lng: 19.059359 },
    { lat: 49.0614764, lng: 19.060659 },
    { lat: 49.0607902, lng: 19.0603744 },
    { lat: 49.0606168, lng: 19.0603325 },
    { lat: 49.0602794, lng: 19.06044 },
    { lat: 49.0596741, lng: 19.0605987 },
    { lat: 49.0595806, lng: 19.0605764 },
    { lat: 49.0593324, lng: 19.0609597 },
    { lat: 49.059148, lng: 19.0615373 },
    { lat: 49.0589516, lng: 19.0619866 },
    { lat: 49.058667, lng: 19.0627684 },
    { lat: 49.0588528, lng: 19.0651751 },
    { lat: 49.0590688, lng: 19.0660532 },
    { lat: 49.0591412, lng: 19.0664013 },
    { lat: 49.0591771, lng: 19.0665742 },
    { lat: 49.0591897, lng: 19.0673496 },
    { lat: 49.0591936, lng: 19.0675935 },
    { lat: 49.0591806, lng: 19.0678075 },
    { lat: 49.0591505, lng: 19.0682904 },
    { lat: 49.0591628, lng: 19.0686733 },
    { lat: 49.0591894, lng: 19.0693036 },
    { lat: 49.0590501, lng: 19.069564 },
    { lat: 49.058869, lng: 19.0700934 },
    { lat: 49.058757, lng: 19.0706551 },
    { lat: 49.058604, lng: 19.0709761 },
    { lat: 49.0587604, lng: 19.0717687 },
    { lat: 49.058689, lng: 19.0723049 },
    { lat: 49.0579752, lng: 19.0738278 },
    { lat: 49.0577768, lng: 19.0742571 },
    { lat: 49.0574385, lng: 19.075464 },
    { lat: 49.0568442, lng: 19.0765515 },
    { lat: 49.0566897, lng: 19.0765699 },
    { lat: 49.0563689, lng: 19.0770858 },
    { lat: 49.05636, lng: 19.0778851 },
    { lat: 49.0564013, lng: 19.0784648 },
    { lat: 49.056432, lng: 19.0789045 },
    { lat: 49.0565104, lng: 19.0795522 },
    { lat: 49.0567434, lng: 19.0810071 },
    { lat: 49.0567446, lng: 19.0814641 },
    { lat: 49.0566858, lng: 19.0829413 },
    { lat: 49.0566765, lng: 19.0831645 },
    { lat: 49.0565569, lng: 19.0839829 },
    { lat: 49.0565468, lng: 19.0847101 },
    { lat: 49.0564316, lng: 19.0851625 },
    { lat: 49.0563041, lng: 19.0856638 },
    { lat: 49.0555169, lng: 19.0871628 },
    { lat: 49.0554783, lng: 19.0879533 },
    { lat: 49.0549348, lng: 19.0895717 },
    { lat: 49.0548669, lng: 19.0897261 },
    { lat: 49.0546774, lng: 19.0901564 },
    { lat: 49.0539763, lng: 19.0913651 },
    { lat: 49.0535276, lng: 19.0923236 },
    { lat: 49.0533274, lng: 19.0929053 },
    { lat: 49.0530815, lng: 19.0935843 },
    { lat: 49.0528618, lng: 19.0943893 },
    { lat: 49.0524314, lng: 19.0949628 },
    { lat: 49.0522074, lng: 19.0953112 },
    { lat: 49.0519057, lng: 19.0957791 },
    { lat: 49.0518536, lng: 19.0958612 },
    { lat: 49.051712, lng: 19.096281 },
    { lat: 49.0514971, lng: 19.0971159 },
    { lat: 49.0514794, lng: 19.0971866 },
    { lat: 49.0514622, lng: 19.0972497 },
    { lat: 49.0514935, lng: 19.0977938 },
    { lat: 49.0511983, lng: 19.0987569 },
    { lat: 49.0510168, lng: 19.0989619 },
    { lat: 49.0506964, lng: 19.0993238 },
    { lat: 49.0505299, lng: 19.099511 },
    { lat: 49.0507535, lng: 19.0999551 },
    { lat: 49.0508274, lng: 19.100102 },
    { lat: 49.0507703, lng: 19.1007985 },
    { lat: 49.050744, lng: 19.1011127 },
    { lat: 49.0504365, lng: 19.1025142 },
    { lat: 49.0503859, lng: 19.1026511 },
    { lat: 49.0502626, lng: 19.1029872 },
    { lat: 49.0500498, lng: 19.1033079 },
    { lat: 49.0498275, lng: 19.1037397 },
    { lat: 49.0496863, lng: 19.1038277 },
    { lat: 49.0496556, lng: 19.1039139 },
    { lat: 49.0495901, lng: 19.1040705 },
    { lat: 49.0493754, lng: 19.1051778 },
    { lat: 49.0493358, lng: 19.1063197 },
    { lat: 49.049193, lng: 19.1067888 },
    { lat: 49.0485113, lng: 19.107753 },
    { lat: 49.0483883, lng: 19.1078874 },
    { lat: 49.0478066, lng: 19.1085283 },
    { lat: 49.0476182, lng: 19.108732 },
    { lat: 49.0474783, lng: 19.1088399 },
    { lat: 49.0469152, lng: 19.1091535 },
    { lat: 49.0469718, lng: 19.1104846 },
    { lat: 49.0469765, lng: 19.1112667 },
    { lat: 49.0468037, lng: 19.1116231 },
    { lat: 49.0465358, lng: 19.1119306 },
    { lat: 49.046602, lng: 19.1122407 },
    { lat: 49.046613, lng: 19.112234 },
    { lat: 49.046959, lng: 19.112017 },
    { lat: 49.048115, lng: 19.112755 },
    { lat: 49.048682, lng: 19.112945 },
    { lat: 49.04902, lng: 19.113124 },
    { lat: 49.049162, lng: 19.113345 },
    { lat: 49.049906, lng: 19.113581 },
    { lat: 49.051227, lng: 19.113656 },
    { lat: 49.051761, lng: 19.113773 },
    { lat: 49.052, lng: 19.113878 },
    { lat: 49.052935, lng: 19.114488 },
    { lat: 49.053414, lng: 19.114552 },
    { lat: 49.054212, lng: 19.114307 },
    { lat: 49.0546134, lng: 19.114083 },
    { lat: 49.0554195, lng: 19.1133738 },
    { lat: 49.0561214, lng: 19.1128823 },
    { lat: 49.056323, lng: 19.1126739 },
    { lat: 49.0564597, lng: 19.1123359 },
    { lat: 49.05664, lng: 19.1121508 },
    { lat: 49.0575204, lng: 19.1117622 },
    { lat: 49.0577519, lng: 19.111839 },
    { lat: 49.058012, lng: 19.1117199 },
    { lat: 49.0584423, lng: 19.1115749 },
    { lat: 49.0585877, lng: 19.1114142 },
    { lat: 49.0587086, lng: 19.1112153 },
    { lat: 49.0594255, lng: 19.1098436 },
    { lat: 49.0603574, lng: 19.1088934 },
    { lat: 49.0604765, lng: 19.1079262 },
    { lat: 49.0606291, lng: 19.1073599 },
    { lat: 49.0605874, lng: 19.1069736 },
    { lat: 49.0607312, lng: 19.1063192 },
    { lat: 49.0608555, lng: 19.1060099 },
    { lat: 49.0609755, lng: 19.1054233 },
    { lat: 49.0613472, lng: 19.1047324 },
    { lat: 49.0616343, lng: 19.1036019 },
    { lat: 49.0617227, lng: 19.1035645 },
    { lat: 49.0622381, lng: 19.1029564 },
    { lat: 49.0626848, lng: 19.1021372 },
    { lat: 49.0628988, lng: 19.1019068 },
    { lat: 49.0631582, lng: 19.1014038 },
    { lat: 49.0632157, lng: 19.101013 },
    { lat: 49.0633007, lng: 19.1007286 },
    { lat: 49.0634087, lng: 19.100538 },
    { lat: 49.0633979, lng: 19.1003196 },
    { lat: 49.0636104, lng: 19.0998769 },
    { lat: 49.0637517, lng: 19.0996343 },
    { lat: 49.0639231, lng: 19.0994501 },
    { lat: 49.0640244, lng: 19.0992189 },
    { lat: 49.0645023, lng: 19.0994936 },
    { lat: 49.0647661, lng: 19.0999028 },
    { lat: 49.0650196, lng: 19.1001963 },
    { lat: 49.0656478, lng: 19.1002274 },
    { lat: 49.0657682, lng: 19.1002701 },
    { lat: 49.0662838, lng: 19.1002303 },
    { lat: 49.0667687, lng: 19.1000994 },
    { lat: 49.0671705, lng: 19.1001085 },
    { lat: 49.0676934, lng: 19.0991019 },
    { lat: 49.0679138, lng: 19.0985414 },
    { lat: 49.068095, lng: 19.0981914 },
    { lat: 49.0684595, lng: 19.0976281 },
    { lat: 49.0685567, lng: 19.0974042 },
    { lat: 49.0688338, lng: 19.0961649 },
    { lat: 49.0688599, lng: 19.0956881 },
    { lat: 49.0690024, lng: 19.0953771 },
    { lat: 49.0692058, lng: 19.0950241 },
    { lat: 49.0695847, lng: 19.0942053 },
    { lat: 49.0697471, lng: 19.0940221 },
    { lat: 49.0701232, lng: 19.0937871 },
    { lat: 49.0708408, lng: 19.0938011 },
    { lat: 49.0715283, lng: 19.0936607 },
    { lat: 49.0724015, lng: 19.0931697 },
    { lat: 49.0726255, lng: 19.0928697 },
    { lat: 49.0730756, lng: 19.091576 },
    { lat: 49.0741339, lng: 19.0904529 },
    { lat: 49.0745779, lng: 19.0898531 },
    { lat: 49.0750727, lng: 19.0892816 },
    { lat: 49.0751405, lng: 19.0890139 },
    { lat: 49.0752829, lng: 19.0884496 },
    { lat: 49.0753442, lng: 19.0882071 },
    { lat: 49.0756208, lng: 19.0875991 },
    { lat: 49.0757856, lng: 19.0870998 },
    { lat: 49.0764364, lng: 19.0866751 },
    { lat: 49.0767703, lng: 19.0866783 },
    { lat: 49.0769319, lng: 19.0859679 },
    { lat: 49.076937, lng: 19.0859454 },
    { lat: 49.0770351, lng: 19.085831 },
    { lat: 49.0770818, lng: 19.0855923 },
    { lat: 49.0772179, lng: 19.0854257 },
    { lat: 49.0773143, lng: 19.0849138 },
    { lat: 49.0771746, lng: 19.0849161 },
    { lat: 49.0771322, lng: 19.0846944 },
    { lat: 49.0774313, lng: 19.0845433 },
    { lat: 49.0776786, lng: 19.0843949 },
    { lat: 49.0779948, lng: 19.0841289 },
    { lat: 49.0781981, lng: 19.0838172 },
    { lat: 49.0782727, lng: 19.0835958 },
    { lat: 49.0785694, lng: 19.0834863 },
    { lat: 49.07886, lng: 19.0832542 },
    { lat: 49.0791031, lng: 19.0834252 },
    { lat: 49.0793435, lng: 19.0835008 },
    { lat: 49.079891, lng: 19.0842274 },
    { lat: 49.0803274, lng: 19.0837517 },
    { lat: 49.0814944, lng: 19.082911 },
    { lat: 49.0817255, lng: 19.0827504 },
    { lat: 49.0822759, lng: 19.0827359 },
    { lat: 49.0830019, lng: 19.0824366 },
    { lat: 49.0834815, lng: 19.0827693 },
    { lat: 49.083841, lng: 19.0828774 },
    { lat: 49.0840688, lng: 19.0830175 },
    { lat: 49.0841652, lng: 19.0830521 },
    { lat: 49.085463, lng: 19.0830807 },
    { lat: 49.0861706, lng: 19.0828259 },
    { lat: 49.0876556, lng: 19.0822641 },
    { lat: 49.0880435, lng: 19.0824225 },
    { lat: 49.0884955, lng: 19.0826973 },
    { lat: 49.0888005, lng: 19.0826886 },
    { lat: 49.0890952, lng: 19.0827681 },
    { lat: 49.0893963, lng: 19.0829048 },
    { lat: 49.0897709, lng: 19.0829134 },
    { lat: 49.0898426, lng: 19.0831317 },
    { lat: 49.0900148, lng: 19.0831034 },
    { lat: 49.0900985, lng: 19.0829715 },
    { lat: 49.0902316, lng: 19.082839 },
    { lat: 49.0905681, lng: 19.0828954 },
    { lat: 49.0908743, lng: 19.082843 },
    { lat: 49.0914441, lng: 19.0826249 },
    { lat: 49.0918546, lng: 19.0824663 },
    { lat: 49.0924284, lng: 19.0824901 },
    { lat: 49.0928772, lng: 19.0825844 },
    { lat: 49.0932876, lng: 19.0827927 },
    { lat: 49.0936893, lng: 19.0828435 },
    { lat: 49.0945622, lng: 19.08251 },
    { lat: 49.097955, lng: 19.0817985 },
    { lat: 49.0980766, lng: 19.0821371 },
    { lat: 49.0988143, lng: 19.0818058 },
    { lat: 49.0995269, lng: 19.0816471 },
    { lat: 49.1002469, lng: 19.0792259 },
    { lat: 49.1003542, lng: 19.0790503 },
    { lat: 49.100469, lng: 19.0788626 },
    { lat: 49.1007653, lng: 19.0783772 },
    { lat: 49.1017632, lng: 19.076742 },
    { lat: 49.1021614, lng: 19.0757894 },
    { lat: 49.1023424, lng: 19.0742353 },
    { lat: 49.1023419, lng: 19.0740861 },
    { lat: 49.1023256, lng: 19.0736181 },
    { lat: 49.1022879, lng: 19.0726094 },
    { lat: 49.1023201, lng: 19.0706403 },
    { lat: 49.1022514, lng: 19.0697063 },
    { lat: 49.1016119, lng: 19.0681778 },
    { lat: 49.1017943, lng: 19.0679223 },
    { lat: 49.1010669, lng: 19.0662089 },
    { lat: 49.1006216, lng: 19.0649596 },
    { lat: 49.1001506, lng: 19.0643504 },
    { lat: 49.1001408, lng: 19.0642619 },
    { lat: 49.1005668, lng: 19.0643298 },
    { lat: 49.1006377, lng: 19.0636454 },
    { lat: 49.1008155, lng: 19.0628425 },
    { lat: 49.1011686, lng: 19.0621899 },
    { lat: 49.1007209, lng: 19.0611175 },
    { lat: 49.1002776, lng: 19.0602713 },
  ],
  BeláDulice: [
    { lat: 48.926171, lng: 19.104254 },
    { lat: 48.926288, lng: 19.10549 },
    { lat: 48.926729, lng: 19.107685 },
    { lat: 48.928737, lng: 19.112095 },
    { lat: 48.929735, lng: 19.11363 },
    { lat: 48.930797, lng: 19.11475 },
    { lat: 48.931186, lng: 19.11502 },
    { lat: 48.932044, lng: 19.116109 },
    { lat: 48.933902, lng: 19.11703 },
    { lat: 48.932802, lng: 19.117543 },
    { lat: 48.932096, lng: 19.1183 },
    { lat: 48.931171, lng: 19.120043 },
    { lat: 48.930674, lng: 19.121087 },
    { lat: 48.930561, lng: 19.122611 },
    { lat: 48.931115, lng: 19.12428 },
    { lat: 48.931392, lng: 19.124577 },
    { lat: 48.93163, lng: 19.124833 },
    { lat: 48.931705, lng: 19.125082 },
    { lat: 48.931672, lng: 19.125325 },
    { lat: 48.931595, lng: 19.125833 },
    { lat: 48.931585, lng: 19.126071 },
    { lat: 48.93172, lng: 19.126182 },
    { lat: 48.931955, lng: 19.126403 },
    { lat: 48.931996, lng: 19.126685 },
    { lat: 48.932021, lng: 19.126917 },
    { lat: 48.932271, lng: 19.127491 },
    { lat: 48.932358, lng: 19.127605 },
    { lat: 48.932445, lng: 19.12756 },
    { lat: 48.932656, lng: 19.127685 },
    { lat: 48.932835, lng: 19.127926 },
    { lat: 48.932981, lng: 19.128004 },
    { lat: 48.933013, lng: 19.128049 },
    { lat: 48.933148, lng: 19.128151 },
    { lat: 48.933267, lng: 19.12824 },
    { lat: 48.933437, lng: 19.128348 },
    { lat: 48.933647, lng: 19.128405 },
    { lat: 48.93434, lng: 19.128762 },
    { lat: 48.93447, lng: 19.128865 },
    { lat: 48.934694, lng: 19.129113 },
    { lat: 48.934819, lng: 19.129209 },
    { lat: 48.935023, lng: 19.12936 },
    { lat: 48.935144, lng: 19.129387 },
    { lat: 48.935324, lng: 19.129556 },
    { lat: 48.935442, lng: 19.129504 },
    { lat: 48.935587, lng: 19.129585 },
    { lat: 48.935656, lng: 19.129706 },
    { lat: 48.935719, lng: 19.129769 },
    { lat: 48.935807, lng: 19.129862 },
    { lat: 48.936016, lng: 19.130014 },
    { lat: 48.936179, lng: 19.130142 },
    { lat: 48.936553, lng: 19.130299 },
    { lat: 48.93666, lng: 19.130453 },
    { lat: 48.9367, lng: 19.13057 },
    { lat: 48.936736, lng: 19.130686 },
    { lat: 48.936849, lng: 19.130792 },
    { lat: 48.936952, lng: 19.130943 },
    { lat: 48.937066, lng: 19.131015 },
    { lat: 48.937138, lng: 19.131206 },
    { lat: 48.937212, lng: 19.131179 },
    { lat: 48.937287, lng: 19.131202 },
    { lat: 48.93744, lng: 19.131424 },
    { lat: 48.937492, lng: 19.131492 },
    { lat: 48.937567, lng: 19.131606 },
    { lat: 48.937647, lng: 19.131652 },
    { lat: 48.937745, lng: 19.13166 },
    { lat: 48.937903, lng: 19.131816 },
    { lat: 48.937924, lng: 19.1319 },
    { lat: 48.938001, lng: 19.131942 },
    { lat: 48.938043, lng: 19.132048 },
    { lat: 48.938112, lng: 19.132083 },
    { lat: 48.938143, lng: 19.132221 },
    { lat: 48.938238, lng: 19.132328 },
    { lat: 48.938355, lng: 19.132435 },
    { lat: 48.938469, lng: 19.132639 },
    { lat: 48.938855, lng: 19.13328 },
    { lat: 48.938976, lng: 19.133425 },
    { lat: 48.939061, lng: 19.133577 },
    { lat: 48.939123, lng: 19.133759 },
    { lat: 48.939229, lng: 19.13391 },
    { lat: 48.939275, lng: 19.134011 },
    { lat: 48.939381, lng: 19.134177 },
    { lat: 48.939476, lng: 19.134386 },
    { lat: 48.939632, lng: 19.134568 },
    { lat: 48.939833, lng: 19.134668 },
    { lat: 48.939951, lng: 19.134783 },
    { lat: 48.940052, lng: 19.134889 },
    { lat: 48.940119, lng: 19.134981 },
    { lat: 48.940251, lng: 19.135026 },
    { lat: 48.940267, lng: 19.135163 },
    { lat: 48.940357, lng: 19.135242 },
    { lat: 48.940417, lng: 19.135409 },
    { lat: 48.940499, lng: 19.135632 },
    { lat: 48.940486, lng: 19.135712 },
    { lat: 48.940518, lng: 19.135767 },
    { lat: 48.940594, lng: 19.136001 },
    { lat: 48.940654, lng: 19.13615 },
    { lat: 48.940704, lng: 19.136424 },
    { lat: 48.9407, lng: 19.136663 },
    { lat: 48.940811, lng: 19.136866 },
    { lat: 48.94101, lng: 19.137133 },
    { lat: 48.941209, lng: 19.137356 },
    { lat: 48.941277, lng: 19.13747 },
    { lat: 48.94144, lng: 19.137508 },
    { lat: 48.941533, lng: 19.137563 },
    { lat: 48.941663, lng: 19.137627 },
    { lat: 48.941818, lng: 19.137674 },
    { lat: 48.942006, lng: 19.137752 },
    { lat: 48.942169, lng: 19.13778 },
    { lat: 48.942273, lng: 19.137792 },
    { lat: 48.942379, lng: 19.137978 },
    { lat: 48.942495, lng: 19.138036 },
    { lat: 48.942637, lng: 19.138054 },
    { lat: 48.942918, lng: 19.138411 },
    { lat: 48.943003, lng: 19.138461 },
    { lat: 48.943138, lng: 19.138589 },
    { lat: 48.943242, lng: 19.13867 },
    { lat: 48.943465, lng: 19.13891 },
    { lat: 48.943586, lng: 19.138903 },
    { lat: 48.943685, lng: 19.138928 },
    { lat: 48.943821, lng: 19.139001 },
    { lat: 48.943927, lng: 19.138982 },
    { lat: 48.944075, lng: 19.139054 },
    { lat: 48.944211, lng: 19.139094 },
    { lat: 48.944368, lng: 19.139121 },
    { lat: 48.944476, lng: 19.139147 },
    { lat: 48.944639, lng: 19.139186 },
    { lat: 48.944748, lng: 19.139161 },
    { lat: 48.944909, lng: 19.139196 },
    { lat: 48.945181, lng: 19.139188 },
    { lat: 48.94544, lng: 19.139131 },
    { lat: 48.945683, lng: 19.138986 },
    { lat: 48.945823, lng: 19.13894 },
    { lat: 48.946041, lng: 19.138978 },
    { lat: 48.946336, lng: 19.139075 },
    { lat: 48.946495, lng: 19.139028 },
    { lat: 48.946677, lng: 19.139 },
    { lat: 48.946822, lng: 19.139063 },
    { lat: 48.946976, lng: 19.138961 },
    { lat: 48.947285, lng: 19.138907 },
    { lat: 48.947668, lng: 19.138763 },
    { lat: 48.947859, lng: 19.138713 },
    { lat: 48.948163, lng: 19.138577 },
    { lat: 48.948572, lng: 19.138508 },
    { lat: 48.948797, lng: 19.138447 },
    { lat: 48.948957, lng: 19.13835 },
    { lat: 48.949053, lng: 19.138288 },
    { lat: 48.949139, lng: 19.138157 },
    { lat: 48.949275, lng: 19.138011 },
    { lat: 48.949444, lng: 19.138108 },
    { lat: 48.949616, lng: 19.138187 },
    { lat: 48.94983, lng: 19.138292 },
    { lat: 48.950056, lng: 19.138175 },
    { lat: 48.950119, lng: 19.138108 },
    { lat: 48.950189, lng: 19.138095 },
    { lat: 48.950368, lng: 19.138081 },
    { lat: 48.950534, lng: 19.138166 },
    { lat: 48.950722, lng: 19.138165 },
    { lat: 48.950912, lng: 19.13817 },
    { lat: 48.950979, lng: 19.138135 },
    { lat: 48.951252, lng: 19.138205 },
    { lat: 48.951389, lng: 19.138239 },
    { lat: 48.951383, lng: 19.138182 },
    { lat: 48.951445, lng: 19.138126 },
    { lat: 48.951564, lng: 19.138023 },
    { lat: 48.951573, lng: 19.137991 },
    { lat: 48.951583, lng: 19.137948 },
    { lat: 48.951587, lng: 19.13792 },
    { lat: 48.951624, lng: 19.137679 },
    { lat: 48.951637, lng: 19.137595 },
    { lat: 48.951978, lng: 19.135311 },
    { lat: 48.952093, lng: 19.134696 },
    { lat: 48.952288, lng: 19.133815 },
    { lat: 48.952467, lng: 19.133114 },
    { lat: 48.952487, lng: 19.133061 },
    { lat: 48.952603, lng: 19.132694 },
    { lat: 48.952898, lng: 19.131973 },
    { lat: 48.953864, lng: 19.131697 },
    { lat: 48.955096, lng: 19.131829 },
    { lat: 48.955607, lng: 19.131631 },
    { lat: 48.955705, lng: 19.130713 },
    { lat: 48.955691, lng: 19.128992 },
    { lat: 48.955812, lng: 19.128862 },
    { lat: 48.955826, lng: 19.127535 },
    { lat: 48.955965, lng: 19.126903 },
    { lat: 48.955896, lng: 19.125311 },
    { lat: 48.956043, lng: 19.124358 },
    { lat: 48.956348, lng: 19.12388 },
    { lat: 48.956405, lng: 19.123527 },
    { lat: 48.956377, lng: 19.121634 },
    { lat: 48.956092, lng: 19.120314 },
    { lat: 48.9561397, lng: 19.1203102 },
    { lat: 48.956466, lng: 19.1197 },
    { lat: 48.956627, lng: 19.119572 },
    { lat: 48.957288, lng: 19.119217 },
    { lat: 48.958234, lng: 19.118131 },
    { lat: 48.959519, lng: 19.118444 },
    { lat: 48.960015, lng: 19.118095 },
    { lat: 48.960775, lng: 19.1172543 },
    { lat: 48.96076, lng: 19.117256 },
    { lat: 48.961039, lng: 19.11689 },
    { lat: 48.961316, lng: 19.116637 },
    { lat: 48.961696, lng: 19.11651 },
    { lat: 48.962812, lng: 19.11584 },
    { lat: 48.963511, lng: 19.115448 },
    { lat: 48.964155, lng: 19.115125 },
    { lat: 48.965695, lng: 19.114051 },
    { lat: 48.966583, lng: 19.113188 },
    { lat: 48.967079, lng: 19.112729 },
    { lat: 48.967954, lng: 19.111474 },
    { lat: 48.969183, lng: 19.107902 },
    { lat: 48.969613, lng: 19.107131 },
    { lat: 48.970043, lng: 19.106503 },
    { lat: 48.970777, lng: 19.105683 },
    { lat: 48.971289, lng: 19.103981 },
    { lat: 48.971691, lng: 19.102824 },
    { lat: 48.972414, lng: 19.102587 },
    { lat: 48.973615, lng: 19.102302 },
    { lat: 48.974147, lng: 19.101766 },
    { lat: 48.974863, lng: 19.10043 },
    { lat: 48.975676, lng: 19.100897 },
    { lat: 48.97637, lng: 19.101398 },
    { lat: 48.976901, lng: 19.101555 },
    { lat: 48.9775904, lng: 19.1017959 },
    { lat: 48.978623, lng: 19.101607 },
    { lat: 48.979322, lng: 19.101904 },
    { lat: 48.980116, lng: 19.102016 },
    { lat: 48.980868, lng: 19.102184 },
    { lat: 48.981902, lng: 19.102205 },
    { lat: 48.9821938, lng: 19.1021809 },
    { lat: 48.982311, lng: 19.102183 },
    { lat: 48.983307, lng: 19.102194 },
    { lat: 48.983871, lng: 19.102202 },
    { lat: 48.984575, lng: 19.10212 },
    { lat: 48.9846976, lng: 19.1013378 },
    { lat: 48.984928, lng: 19.1004351 },
    { lat: 48.9851055, lng: 19.0996228 },
    { lat: 48.9856448, lng: 19.0990165 },
    { lat: 48.9868875, lng: 19.0978633 },
    { lat: 48.9897018, lng: 19.0973277 },
    { lat: 48.9902594, lng: 19.097206 },
    { lat: 48.9910496, lng: 19.0972052 },
    { lat: 48.9912773, lng: 19.0967237 },
    { lat: 48.9915834, lng: 19.0952067 },
    { lat: 48.9920015, lng: 19.0938828 },
    { lat: 48.9922699, lng: 19.0927706 },
    { lat: 48.9922818, lng: 19.091822 },
    { lat: 48.9920438, lng: 19.089928 },
    { lat: 48.9920534, lng: 19.0884835 },
    { lat: 48.9921787, lng: 19.0876651 },
    { lat: 48.992091, lng: 19.0861876 },
    { lat: 48.9926961, lng: 19.0837244 },
    { lat: 48.9926829, lng: 19.0831387 },
    { lat: 48.9926458, lng: 19.0822738 },
    { lat: 48.9925926, lng: 19.0819059 },
    { lat: 48.9925676, lng: 19.081334 },
    { lat: 48.9924661, lng: 19.0804931 },
    { lat: 48.9924451, lng: 19.0800446 },
    { lat: 48.9924724, lng: 19.0797321 },
    { lat: 48.9926421, lng: 19.0783328 },
    { lat: 48.9927913, lng: 19.0775687 },
    { lat: 48.9927728, lng: 19.0773529 },
    { lat: 48.9928627, lng: 19.0767133 },
    { lat: 48.9929822, lng: 19.0763733 },
    { lat: 48.9930887, lng: 19.0755631 },
    { lat: 48.9931838, lng: 19.0751839 },
    { lat: 48.9933584, lng: 19.0746854 },
    { lat: 48.9936369, lng: 19.0742518 },
    { lat: 48.9938307, lng: 19.0740013 },
    { lat: 48.9942574, lng: 19.0731253 },
    { lat: 48.9946609, lng: 19.0723267 },
    { lat: 48.9950651, lng: 19.0714795 },
    { lat: 48.9953773, lng: 19.0709073 },
    { lat: 48.9958272, lng: 19.0702993 },
    { lat: 48.996277, lng: 19.0695477 },
    { lat: 48.9963461, lng: 19.0691941 },
    { lat: 48.9961297, lng: 19.0676045 },
    { lat: 48.9961721, lng: 19.0667796 },
    { lat: 48.9961432, lng: 19.0660833 },
    { lat: 48.9961565, lng: 19.0651717 },
    { lat: 48.9959189, lng: 19.0638599 },
    { lat: 48.9958735, lng: 19.0627425 },
    { lat: 48.9959332, lng: 19.0618331 },
    { lat: 48.9957295, lng: 19.0595933 },
    { lat: 48.9958353, lng: 19.0582536 },
    { lat: 48.9962458, lng: 19.0560147 },
    { lat: 48.9967145, lng: 19.0544451 },
    { lat: 48.9971578, lng: 19.0527287 },
    { lat: 48.9990353, lng: 19.0472818 },
    { lat: 48.9997283, lng: 19.046237 },
    { lat: 49.0002483, lng: 19.0449783 },
    { lat: 49.0012692, lng: 19.043612 },
    { lat: 49.0016698, lng: 19.0421935 },
    { lat: 49.0025274, lng: 19.0403371 },
    { lat: 49.0031137, lng: 19.0390678 },
    { lat: 49.0034857, lng: 19.0379176 },
    { lat: 49.0034222, lng: 19.0371659 },
    { lat: 49.0035484, lng: 19.0369154 },
    { lat: 49.0040876, lng: 19.0359279 },
    { lat: 49.0043642, lng: 19.0352134 },
    { lat: 49.0046149, lng: 19.0348351 },
    { lat: 49.0047671, lng: 19.0346818 },
    { lat: 49.0048425, lng: 19.0345636 },
    { lat: 49.0049676, lng: 19.034472 },
    { lat: 49.0051025, lng: 19.034288 },
    { lat: 49.0053168, lng: 19.0340852 },
    { lat: 49.0054974, lng: 19.0338417 },
    { lat: 49.0057063, lng: 19.0335307 },
    { lat: 49.0059369, lng: 19.0334973 },
    { lat: 49.0063868, lng: 19.0326672 },
    { lat: 49.0066412, lng: 19.0324307 },
    { lat: 49.0068903, lng: 19.0322949 },
    { lat: 49.0070676, lng: 19.0322116 },
    { lat: 49.0071898, lng: 19.0321297 },
    { lat: 49.0073244, lng: 19.0321842 },
    { lat: 49.0075031, lng: 19.0320835 },
    { lat: 49.0080318, lng: 19.031993 },
    { lat: 49.0082556, lng: 19.0319133 },
    { lat: 49.0085294, lng: 19.0318436 },
    { lat: 49.0088662, lng: 19.0317466 },
    { lat: 49.0090313, lng: 19.0316654 },
    { lat: 49.0093023, lng: 19.0315153 },
    { lat: 49.0095479, lng: 19.0314711 },
    { lat: 49.0095634, lng: 19.0313745 },
    { lat: 49.0098165, lng: 19.0312487 },
    { lat: 49.009893, lng: 19.0313153 },
    { lat: 49.0100482, lng: 19.0312396 },
    { lat: 49.0103935, lng: 19.0313267 },
    { lat: 49.0104849, lng: 19.0313395 },
    { lat: 49.0105562, lng: 19.0313418 },
    { lat: 49.0106296, lng: 19.0313441 },
    { lat: 49.0107511, lng: 19.0313372 },
    { lat: 49.0109107, lng: 19.0313533 },
    { lat: 49.0110104, lng: 19.0313206 },
    { lat: 49.0112011, lng: 19.0311891 },
    { lat: 49.0113408, lng: 19.031048 },
    { lat: 49.0114132, lng: 19.0310321 },
    { lat: 49.0114613, lng: 19.030978 },
    { lat: 49.0115762, lng: 19.030819 },
    { lat: 49.0116727, lng: 19.0307218 },
    { lat: 49.0116954, lng: 19.0306989 },
    { lat: 49.0118546, lng: 19.0305947 },
    { lat: 49.0118294, lng: 19.0303595 },
    { lat: 49.0117999, lng: 19.0302182 },
    { lat: 49.0117819, lng: 19.0300608 },
    { lat: 49.0117684, lng: 19.0296134 },
    { lat: 49.0117612, lng: 19.0294426 },
    { lat: 49.0117536, lng: 19.0293803 },
    { lat: 49.0117908, lng: 19.0291555 },
    { lat: 49.0117822, lng: 19.0288717 },
    { lat: 49.0117987, lng: 19.0285908 },
    { lat: 49.011825, lng: 19.0284225 },
    { lat: 49.0119059, lng: 19.0280756 },
    { lat: 49.0119247, lng: 19.0279237 },
    { lat: 49.0119581, lng: 19.0276548 },
    { lat: 49.0120461, lng: 19.0271357 },
    { lat: 49.0120204, lng: 19.0268916 },
    { lat: 49.011972, lng: 19.0266221 },
    { lat: 49.0118518, lng: 19.0262751 },
    { lat: 49.0118363, lng: 19.0259407 },
    { lat: 49.0118823, lng: 19.025712 },
    { lat: 49.0119893, lng: 19.0252587 },
    { lat: 49.0120244, lng: 19.0250815 },
    { lat: 49.0120536, lng: 19.0249855 },
    { lat: 49.0121301, lng: 19.0248473 },
    { lat: 49.012152, lng: 19.0246092 },
    { lat: 49.0122047, lng: 19.0243797 },
    { lat: 49.0123022, lng: 19.0243915 },
    { lat: 49.0126661, lng: 19.0245218 },
    { lat: 49.0127847, lng: 19.0245921 },
    { lat: 49.0130635, lng: 19.0248486 },
    { lat: 49.0131839, lng: 19.0250025 },
    { lat: 49.0133909, lng: 19.0251969 },
    { lat: 49.0135727, lng: 19.0252908 },
    { lat: 49.0139204, lng: 19.0251673 },
    { lat: 49.0141027, lng: 19.0240774 },
    { lat: 49.0148509, lng: 19.0240452 },
    { lat: 49.0148677, lng: 19.0240465 },
    { lat: 49.0152514, lng: 19.0213034 },
    { lat: 49.0153592, lng: 19.0209135 },
    { lat: 49.0154447, lng: 19.0201853 },
    { lat: 49.0153342, lng: 19.0194894 },
    { lat: 49.0155232, lng: 19.0178604 },
    { lat: 49.0155639, lng: 19.0176358 },
    { lat: 49.0156668, lng: 19.0170098 },
    { lat: 49.0157624, lng: 19.0143054 },
    { lat: 49.015806, lng: 19.0128802 },
    { lat: 49.0158472, lng: 19.0120612 },
    { lat: 49.0159596, lng: 19.0117174 },
    { lat: 49.0159675, lng: 19.0116712 },
    { lat: 49.0161499, lng: 19.0111954 },
    { lat: 49.016352, lng: 19.0100535 },
    { lat: 49.01669, lng: 19.0087702 },
    { lat: 49.0170484, lng: 19.0074601 },
    { lat: 49.0169261, lng: 19.0071801 },
    { lat: 49.0165536, lng: 19.0064191 },
    { lat: 49.0163837, lng: 19.006233 },
    { lat: 49.0170206, lng: 19.0060772 },
    { lat: 49.0169637, lng: 19.0044729 },
    { lat: 49.0167411, lng: 19.0034507 },
    { lat: 49.0164681, lng: 19.0021897 },
    { lat: 49.0159685, lng: 19.0004547 },
    { lat: 49.0155002, lng: 18.9997961 },
    { lat: 49.0155189, lng: 18.9997437 },
    { lat: 49.0152135, lng: 18.9994307 },
    { lat: 49.0143713, lng: 18.9985739 },
    { lat: 49.0141503, lng: 18.9982612 },
    { lat: 49.0132582, lng: 18.9971559 },
    { lat: 49.0132047, lng: 18.9971017 },
    { lat: 49.0131638, lng: 18.9969829 },
    { lat: 49.0130639, lng: 18.99678 },
    { lat: 49.0126042, lng: 18.9960771 },
    { lat: 49.0123761, lng: 18.9957079 },
    { lat: 49.0123777, lng: 18.995455 },
    { lat: 49.0123867, lng: 18.9940341 },
    { lat: 49.012304, lng: 18.9932951 },
    { lat: 49.01228, lng: 18.9930811 },
    { lat: 49.0122785, lng: 18.9930665 },
    { lat: 49.0122298, lng: 18.9925777 },
    { lat: 49.0121835, lng: 18.9916084 },
    { lat: 49.0121467, lng: 18.991418 },
    { lat: 49.0120849, lng: 18.9912646 },
    { lat: 49.0119881, lng: 18.9911304 },
    { lat: 49.012, lng: 18.990943 },
    { lat: 49.0120362, lng: 18.9906763 },
    { lat: 49.012525, lng: 18.9905078 },
    { lat: 49.0125803, lng: 18.9899964 },
    { lat: 49.0125929, lng: 18.9896174 },
    { lat: 49.0127333, lng: 18.9890046 },
    { lat: 49.0128115, lng: 18.9883849 },
    { lat: 49.0129369, lng: 18.9876036 },
    { lat: 49.0128528, lng: 18.986895 },
    { lat: 49.0132911, lng: 18.9866659 },
    { lat: 49.013251, lng: 18.986227 },
    { lat: 49.0136643, lng: 18.9848763 },
    { lat: 49.0138463, lng: 18.9844141 },
    { lat: 49.0139573, lng: 18.9843333 },
    { lat: 49.0142252, lng: 18.984194 },
    { lat: 49.014853, lng: 18.9841425 },
    { lat: 49.0150064, lng: 18.9841253 },
    { lat: 49.0151571, lng: 18.9840263 },
    { lat: 49.0158478, lng: 18.9834581 },
    { lat: 49.0159485, lng: 18.9832427 },
    { lat: 49.0160387, lng: 18.9831119 },
    { lat: 49.0161725, lng: 18.9830565 },
    { lat: 49.0164356, lng: 18.983023 },
    { lat: 49.0167891, lng: 18.982743 },
    { lat: 49.0171382, lng: 18.9824448 },
    { lat: 49.0175945, lng: 18.981679 },
    { lat: 49.0177828, lng: 18.9814528 },
    { lat: 49.0182478, lng: 18.981127 },
    { lat: 49.0190914, lng: 18.9807745 },
    { lat: 49.0193564, lng: 18.9807304 },
    { lat: 49.0197986, lng: 18.9806921 },
    { lat: 49.02014, lng: 18.9809785 },
    { lat: 49.0206033, lng: 18.9813578 },
    { lat: 49.0208036, lng: 18.9814415 },
    { lat: 49.020946, lng: 18.9814264 },
    { lat: 49.0211509, lng: 18.981372 },
    { lat: 49.0213613, lng: 18.9812997 },
    { lat: 49.0215077, lng: 18.981163 },
    { lat: 49.0216049, lng: 18.9809635 },
    { lat: 49.0217469, lng: 18.980874 },
    { lat: 49.0221099, lng: 18.980784 },
    { lat: 49.0223507, lng: 18.9807099 },
    { lat: 49.0225441, lng: 18.9804726 },
    { lat: 49.0227787, lng: 18.9804974 },
    { lat: 49.0230314, lng: 18.9803681 },
    { lat: 49.0231556, lng: 18.980396 },
    { lat: 49.023687, lng: 18.9802238 },
    { lat: 49.0238439, lng: 18.9802472 },
    { lat: 49.0239502, lng: 18.9801447 },
    { lat: 49.0241006, lng: 18.9800866 },
    { lat: 49.0250637, lng: 18.979798 },
    { lat: 49.0252878, lng: 18.9796379 },
    { lat: 49.025545, lng: 18.979396 },
    { lat: 49.0257511, lng: 18.9790975 },
    { lat: 49.0260022, lng: 18.9786242 },
    { lat: 49.0262147, lng: 18.9780315 },
    { lat: 49.0263741, lng: 18.9774805 },
    { lat: 49.0262931, lng: 18.9770606 },
    { lat: 49.0261303, lng: 18.9759121 },
    { lat: 49.026217, lng: 18.9758172 },
    { lat: 49.0262284, lng: 18.9755992 },
    { lat: 49.0261891, lng: 18.975399 },
    { lat: 49.0262823, lng: 18.9750084 },
    { lat: 49.0263786, lng: 18.9744396 },
    { lat: 49.0263227, lng: 18.9743147 },
    { lat: 49.0263645, lng: 18.9742027 },
    { lat: 49.0263119, lng: 18.9741018 },
    { lat: 49.0263418, lng: 18.9739532 },
    { lat: 49.0262554, lng: 18.9738059 },
    { lat: 49.0263244, lng: 18.9736977 },
    { lat: 49.0262333, lng: 18.9735261 },
    { lat: 49.0262724, lng: 18.9733843 },
    { lat: 49.0262797, lng: 18.9733041 },
    { lat: 49.0261414, lng: 18.9732451 },
    { lat: 49.0247312, lng: 18.9751278 },
    { lat: 49.0238162, lng: 18.975315 },
    { lat: 49.0229539, lng: 18.9745559 },
    { lat: 49.0221311, lng: 18.9736014 },
    { lat: 49.0220057, lng: 18.9732364 },
    { lat: 49.0212095, lng: 18.970796 },
    { lat: 49.0209695, lng: 18.970019 },
    { lat: 49.0209134, lng: 18.9691506 },
    { lat: 49.0218177, lng: 18.9685755 },
    { lat: 49.0214813, lng: 18.9669326 },
    { lat: 49.0231135, lng: 18.9664349 },
    { lat: 49.0231267, lng: 18.9663881 },
    { lat: 49.0230031, lng: 18.9658123 },
    { lat: 49.0228949, lng: 18.9650767 },
    { lat: 49.0228861, lng: 18.9644785 },
    { lat: 49.0233875, lng: 18.963894 },
    { lat: 49.0239595, lng: 18.9630965 },
    { lat: 49.0240566, lng: 18.9628344 },
    { lat: 49.0241051, lng: 18.9624983 },
    { lat: 49.0242265, lng: 18.9613532 },
    { lat: 49.0243727, lng: 18.9607908 },
    { lat: 49.0247617, lng: 18.9607884 },
    { lat: 49.0249371, lng: 18.9606699 },
    { lat: 49.025008, lng: 18.9605527 },
    { lat: 49.0251448, lng: 18.9593336 },
    { lat: 49.0257905, lng: 18.959376 },
    { lat: 49.0271775, lng: 18.9590902 },
    { lat: 49.0278133, lng: 18.9587087 },
    { lat: 49.0295251, lng: 18.9574225 },
    { lat: 49.0300081, lng: 18.9572327 },
    { lat: 49.0301637, lng: 18.957277 },
    { lat: 49.0305599, lng: 18.9576881 },
    { lat: 49.0305887, lng: 18.9576312 },
    { lat: 49.0309144, lng: 18.9556913 },
    { lat: 49.0310159, lng: 18.9553999 },
    { lat: 49.0311596, lng: 18.9552994 },
    { lat: 49.031294, lng: 18.9544803 },
    { lat: 49.0310174, lng: 18.9542069 },
    { lat: 49.0307695, lng: 18.9541623 },
    { lat: 49.03057, lng: 18.9542309 },
    { lat: 49.0300786, lng: 18.954301 },
    { lat: 49.0292802, lng: 18.9546304 },
    { lat: 49.0284576, lng: 18.9551309 },
    { lat: 49.0275062, lng: 18.9561353 },
    { lat: 49.0270828, lng: 18.9562128 },
    { lat: 49.0264071, lng: 18.9559147 },
    { lat: 49.0253226, lng: 18.9554261 },
    { lat: 49.0247218, lng: 18.9551178 },
    { lat: 49.0243367, lng: 18.9547012 },
    { lat: 49.0238538, lng: 18.9543611 },
    { lat: 49.0233945, lng: 18.9542679 },
    { lat: 49.0225369, lng: 18.9540701 },
    { lat: 49.0221383, lng: 18.9534812 },
    { lat: 49.0213884, lng: 18.9519826 },
    { lat: 49.0202253, lng: 18.950457 },
    { lat: 49.0205408, lng: 18.9496503 },
    { lat: 49.0203093, lng: 18.9488638 },
    { lat: 49.0202704, lng: 18.9482724 },
    { lat: 49.019889, lng: 18.9474876 },
    { lat: 49.0189861, lng: 18.9461124 },
    { lat: 49.0188941, lng: 18.945589 },
    { lat: 49.0181802, lng: 18.9439356 },
    { lat: 49.017216, lng: 18.942397 },
    { lat: 49.0162994, lng: 18.9409535 },
    { lat: 49.0161853, lng: 18.9408842 },
    { lat: 49.0152234, lng: 18.93957 },
    { lat: 49.0148526, lng: 18.939125 },
    { lat: 49.0146301, lng: 18.9389402 },
    { lat: 49.014436, lng: 18.9388477 },
    { lat: 49.0143456, lng: 18.9387976 },
    { lat: 49.014161, lng: 18.9387833 },
    { lat: 49.0140644, lng: 18.9388352 },
    { lat: 49.0138972, lng: 18.9390035 },
    { lat: 49.0137338, lng: 18.9392529 },
    { lat: 49.0135345, lng: 18.9397009 },
    { lat: 49.0133985, lng: 18.9400666 },
    { lat: 49.0133175, lng: 18.9403549 },
    { lat: 49.0132417, lng: 18.9407294 },
    { lat: 49.0131555, lng: 18.9410326 },
    { lat: 49.0129985, lng: 18.9415136 },
    { lat: 49.0125794, lng: 18.9426097 },
    { lat: 49.0123083, lng: 18.9432883 },
    { lat: 49.0122605, lng: 18.943435 },
    { lat: 49.0121291, lng: 18.943644 },
    { lat: 49.0119899, lng: 18.9438349 },
    { lat: 49.0117846, lng: 18.9440583 },
    { lat: 49.0115949, lng: 18.9442303 },
    { lat: 49.0114122, lng: 18.9443411 },
    { lat: 49.0111979, lng: 18.944424 },
    { lat: 49.0106107, lng: 18.9461891 },
    { lat: 49.0104126, lng: 18.946792 },
    { lat: 49.0100591, lng: 18.9484035 },
    { lat: 49.0100201, lng: 18.9484138 },
    { lat: 49.0099283, lng: 18.9488293 },
    { lat: 49.0098915, lng: 18.9488351 },
    { lat: 49.0095722, lng: 18.9496769 },
    { lat: 49.0091411, lng: 18.9512272 },
    { lat: 49.0090479, lng: 18.9516134 },
    { lat: 49.0089332, lng: 18.951955 },
    { lat: 49.0087533, lng: 18.9522759 },
    { lat: 49.0084163, lng: 18.9540794 },
    { lat: 49.0078531, lng: 18.9557427 },
    { lat: 49.0072041, lng: 18.9575955 },
    { lat: 49.0072025, lng: 18.9576097 },
    { lat: 49.0070225, lng: 18.957616 },
    { lat: 49.0069397, lng: 18.9576321 },
    { lat: 49.0068402, lng: 18.9576912 },
    { lat: 49.0067092, lng: 18.9577963 },
    { lat: 49.0066939, lng: 18.9578311 },
    { lat: 49.0066647, lng: 18.9580981 },
    { lat: 49.0066333, lng: 18.9582591 },
    { lat: 49.0065435, lng: 18.9585324 },
    { lat: 49.0065002, lng: 18.9586352 },
    { lat: 49.0064843, lng: 18.9586561 },
    { lat: 49.0064641, lng: 18.9586584 },
    { lat: 49.0062646, lng: 18.9585485 },
    { lat: 49.0062433, lng: 18.9585485 },
    { lat: 49.0061654, lng: 18.9586384 },
    { lat: 49.0059461, lng: 18.9589592 },
    { lat: 49.0059225, lng: 18.9589846 },
    { lat: 49.0058992, lng: 18.9589895 },
    { lat: 49.005845, lng: 18.9589689 },
    { lat: 49.0058198, lng: 18.9590038 },
    { lat: 49.0057399, lng: 18.9592431 },
    { lat: 49.0057275, lng: 18.9592527 },
    { lat: 49.005593, lng: 18.9591822 },
    { lat: 49.0054043, lng: 18.9590606 },
    { lat: 49.0048437, lng: 18.9586553 },
    { lat: 49.0046668, lng: 18.9584979 },
    { lat: 49.0045199, lng: 18.9583822 },
    { lat: 49.0044269, lng: 18.9583266 },
    { lat: 49.0043239, lng: 18.9583609 },
    { lat: 49.0042672, lng: 18.9583553 },
    { lat: 49.0041258, lng: 18.9584365 },
    { lat: 49.004045, lng: 18.9586664 },
    { lat: 49.0039254, lng: 18.9590862 },
    { lat: 49.0038629, lng: 18.9593219 },
    { lat: 49.003772, lng: 18.9597325 },
    { lat: 49.0037354, lng: 18.9599257 },
    { lat: 49.0035572, lng: 18.9605692 },
    { lat: 49.0034374, lng: 18.9609649 },
    { lat: 49.0033636, lng: 18.9611976 },
    { lat: 49.003185, lng: 18.9616784 },
    { lat: 49.003117, lng: 18.9618052 },
    { lat: 49.0026611, lng: 18.9626255 },
    { lat: 49.0022247, lng: 18.963335 },
    { lat: 49.0020054, lng: 18.9637028 },
    { lat: 49.0017989, lng: 18.9640346 },
    { lat: 49.0016791, lng: 18.9642059 },
    { lat: 49.001653, lng: 18.964228 },
    { lat: 49.0013275, lng: 18.9646114 },
    { lat: 49.0012513, lng: 18.9647316 },
    { lat: 49.0009434, lng: 18.9652663 },
    { lat: 49.0008052, lng: 18.9655198 },
    { lat: 49.0006132, lng: 18.9658485 },
    { lat: 49.0004559, lng: 18.9660823 },
    { lat: 48.999886, lng: 18.9669653 },
    { lat: 48.9997665, lng: 18.9671452 },
    { lat: 48.9995206, lng: 18.967543 },
    { lat: 48.9994201, lng: 18.9676951 },
    { lat: 48.999316, lng: 18.9678893 },
    { lat: 48.9992447, lng: 18.9680394 },
    { lat: 48.9991282, lng: 18.9685435 },
    { lat: 48.9990841, lng: 18.9686761 },
    { lat: 48.9989628, lng: 18.9689315 },
    { lat: 48.9988834, lng: 18.9690586 },
    { lat: 48.9986506, lng: 18.9693607 },
    { lat: 48.9986078, lng: 18.9694243 },
    { lat: 48.9985128, lng: 18.9696432 },
    { lat: 48.9984303, lng: 18.9700166 },
    { lat: 48.9983665, lng: 18.9702736 },
    { lat: 48.9983154, lng: 18.9704465 },
    { lat: 48.9982547, lng: 18.9706051 },
    { lat: 48.9981137, lng: 18.9708495 },
    { lat: 48.9980896, lng: 18.9709069 },
    { lat: 48.9979902, lng: 18.9710801 },
    { lat: 48.9979459, lng: 18.9712087 },
    { lat: 48.9979217, lng: 18.9713573 },
    { lat: 48.9979175, lng: 18.9715402 },
    { lat: 48.9979022, lng: 18.9717054 },
    { lat: 48.9978605, lng: 18.9719088 },
    { lat: 48.9978068, lng: 18.9720519 },
    { lat: 48.9976383, lng: 18.9724205 },
    { lat: 48.9974666, lng: 18.9726606 },
    { lat: 48.9974125, lng: 18.9727502 },
    { lat: 48.9973776, lng: 18.9728397 },
    { lat: 48.9973176, lng: 18.9731024 },
    { lat: 48.9972279, lng: 18.9734449 },
    { lat: 48.997221, lng: 18.9734863 },
    { lat: 48.9973032, lng: 18.9740246 },
    { lat: 48.9973207, lng: 18.9742592 },
    { lat: 48.9973078, lng: 18.9743357 },
    { lat: 48.9972488, lng: 18.97453 },
    { lat: 48.9971339, lng: 18.974733 },
    { lat: 48.9969961, lng: 18.9749289 },
    { lat: 48.9968245, lng: 18.9751952 },
    { lat: 48.9965385, lng: 18.9756282 },
    { lat: 48.9964478, lng: 18.9757737 },
    { lat: 48.9963688, lng: 18.9759481 },
    { lat: 48.996291, lng: 18.9762189 },
    { lat: 48.9961263, lng: 18.9764858 },
    { lat: 48.995893, lng: 18.9770221 },
    { lat: 48.9957097, lng: 18.9774114 },
    { lat: 48.995344, lng: 18.9781602 },
    { lat: 48.9952885, lng: 18.9783581 },
    { lat: 48.9952994, lng: 18.9786627 },
    { lat: 48.9952741, lng: 18.9788132 },
    { lat: 48.9951442, lng: 18.9791173 },
    { lat: 48.9950699, lng: 18.979256 },
    { lat: 48.9948999, lng: 18.9795073 },
    { lat: 48.9946754, lng: 18.9799086 },
    { lat: 48.9945672, lng: 18.9801101 },
    { lat: 48.9944924, lng: 18.9803453 },
    { lat: 48.994394, lng: 18.980541 },
    { lat: 48.9942737, lng: 18.9807032 },
    { lat: 48.994151, lng: 18.9808432 },
    { lat: 48.9941669, lng: 18.9809322 },
    { lat: 48.9940553, lng: 18.9811146 },
    { lat: 48.9939446, lng: 18.9812654 },
    { lat: 48.993815, lng: 18.9813982 },
    { lat: 48.9936405, lng: 18.9815653 },
    { lat: 48.9935118, lng: 18.9816266 },
    { lat: 48.9931423, lng: 18.9819683 },
    { lat: 48.9925891, lng: 18.9826226 },
    { lat: 48.9925388, lng: 18.9826798 },
    { lat: 48.9919118, lng: 18.9830532 },
    { lat: 48.9917166, lng: 18.983362 },
    { lat: 48.9916739, lng: 18.9833871 },
    { lat: 48.9916395, lng: 18.9835821 },
    { lat: 48.9916115, lng: 18.9837857 },
    { lat: 48.9915556, lng: 18.9839509 },
    { lat: 48.9915068, lng: 18.9840097 },
    { lat: 48.9914605, lng: 18.9840326 },
    { lat: 48.9914148, lng: 18.9840408 },
    { lat: 48.9910653, lng: 18.9839964 },
    { lat: 48.9909187, lng: 18.9840163 },
    { lat: 48.9907714, lng: 18.9840719 },
    { lat: 48.990542, lng: 18.984263 },
    { lat: 48.9904052, lng: 18.9844552 },
    { lat: 48.9902954, lng: 18.9847383 },
    { lat: 48.9901703, lng: 18.9849643 },
    { lat: 48.9898928, lng: 18.9852058 },
    { lat: 48.9896443, lng: 18.9854641 },
    { lat: 48.9895359, lng: 18.9856386 },
    { lat: 48.989409, lng: 18.9859201 },
    { lat: 48.9892427, lng: 18.9862191 },
    { lat: 48.9891283, lng: 18.9865002 },
    { lat: 48.9889755, lng: 18.986915 },
    { lat: 48.9888265, lng: 18.9873609 },
    { lat: 48.9887873, lng: 18.9874983 },
    { lat: 48.9887464, lng: 18.9879618 },
    { lat: 48.9887172, lng: 18.9882359 },
    { lat: 48.9882805, lng: 18.9892326 },
    { lat: 48.9882161, lng: 18.9894839 },
    { lat: 48.9881758, lng: 18.9897767 },
    { lat: 48.9882433, lng: 18.9908691 },
    { lat: 48.9881118, lng: 18.9917481 },
    { lat: 48.9880381, lng: 18.9921253 },
    { lat: 48.9880045, lng: 18.9924189 },
    { lat: 48.988009, lng: 18.9927106 },
    { lat: 48.9880565, lng: 18.9931861 },
    { lat: 48.988012, lng: 18.9938453 },
    { lat: 48.9880154, lng: 18.9943166 },
    { lat: 48.9881185, lng: 18.9952455 },
    { lat: 48.9880134, lng: 18.9954203 },
    { lat: 48.9879969, lng: 18.9953824 },
    { lat: 48.9874747, lng: 18.9961659 },
    { lat: 48.9870996, lng: 18.9970035 },
    { lat: 48.987005, lng: 18.9975178 },
    { lat: 48.9872346, lng: 18.9979822 },
    { lat: 48.9874365, lng: 18.9983195 },
    { lat: 48.9876789, lng: 18.9999139 },
    { lat: 48.9876991, lng: 18.9999116 },
    { lat: 48.9877051, lng: 19.0002321 },
    { lat: 48.9877443, lng: 19.0004537 },
    { lat: 48.9878517, lng: 19.0008422 },
    { lat: 48.9878377, lng: 19.000855 },
    { lat: 48.9878868, lng: 19.0012495 },
    { lat: 48.9878617, lng: 19.0027429 },
    { lat: 48.9880618, lng: 19.0041681 },
    { lat: 48.9883389, lng: 19.0055327 },
    { lat: 48.9884213, lng: 19.0061855 },
    { lat: 48.98847, lng: 19.0067747 },
    { lat: 48.9887195, lng: 19.0080844 },
    { lat: 48.9888478, lng: 19.0085502 },
    { lat: 48.9888697, lng: 19.0086298 },
    { lat: 48.9890148, lng: 19.0091866 },
    { lat: 48.9884699, lng: 19.0101632 },
    { lat: 48.9884315, lng: 19.010232 },
    { lat: 48.9883511, lng: 19.0105888 },
    { lat: 48.9881972, lng: 19.0107436 },
    { lat: 48.9879977, lng: 19.0109441 },
    { lat: 48.9878171, lng: 19.0111257 },
    { lat: 48.9877479, lng: 19.0111953 },
    { lat: 48.9876767, lng: 19.0113111 },
    { lat: 48.987585, lng: 19.0114614 },
    { lat: 48.9874486, lng: 19.0116842 },
    { lat: 48.9872629, lng: 19.0119875 },
    { lat: 48.9870927, lng: 19.0122655 },
    { lat: 48.9868994, lng: 19.0124791 },
    { lat: 48.986778, lng: 19.0126131 },
    { lat: 48.9865351, lng: 19.0128814 },
    { lat: 48.9862523, lng: 19.0133734 },
    { lat: 48.985976, lng: 19.013854 },
    { lat: 48.9859056, lng: 19.0139765 },
    { lat: 48.9856469, lng: 19.0146422 },
    { lat: 48.9854097, lng: 19.0151039 },
    { lat: 48.9853744, lng: 19.0155908 },
    { lat: 48.9853401, lng: 19.0165277 },
    { lat: 48.9853664, lng: 19.0169169 },
    { lat: 48.9854466, lng: 19.0181586 },
    { lat: 48.985244, lng: 19.018728 },
    { lat: 48.9848772, lng: 19.0192377 },
    { lat: 48.9843552, lng: 19.0203393 },
    { lat: 48.9840982, lng: 19.0208353 },
    { lat: 48.9838202, lng: 19.021408 },
    { lat: 48.9835477, lng: 19.0218443 },
    { lat: 48.9832581, lng: 19.0225655 },
    { lat: 48.983028, lng: 19.0227651 },
    { lat: 48.9822269, lng: 19.0230665 },
    { lat: 48.9813165, lng: 19.0237564 },
    { lat: 48.9810807, lng: 19.0242229 },
    { lat: 48.9809773, lng: 19.0245686 },
    { lat: 48.9807052, lng: 19.0249658 },
    { lat: 48.9802659, lng: 19.0251299 },
    { lat: 48.9798119, lng: 19.0256543 },
    { lat: 48.9796757, lng: 19.0258642 },
    { lat: 48.9790981, lng: 19.02626 },
    { lat: 48.9784747, lng: 19.0263503 },
    { lat: 48.9780765, lng: 19.0265287 },
    { lat: 48.9777013, lng: 19.0266449 },
    { lat: 48.9771642, lng: 19.0268824 },
    { lat: 48.9768958, lng: 19.0268771 },
    { lat: 48.9766123, lng: 19.0272084 },
    { lat: 48.9763565, lng: 19.0276625 },
    { lat: 48.9760036, lng: 19.0281769 },
    { lat: 48.9757092, lng: 19.0287808 },
    { lat: 48.9754891, lng: 19.029543 },
    { lat: 48.975001, lng: 19.0302413 },
    { lat: 48.9745444, lng: 19.0304938 },
    { lat: 48.9737319, lng: 19.0303177 },
    { lat: 48.9732849, lng: 19.0301027 },
    { lat: 48.972686, lng: 19.0298703 },
    { lat: 48.9718789, lng: 19.0298737 },
    { lat: 48.9718529, lng: 19.0302323 },
    { lat: 48.97171, lng: 19.030734 },
    { lat: 48.971585, lng: 19.0313717 },
    { lat: 48.9715906, lng: 19.0320216 },
    { lat: 48.9714826, lng: 19.0327256 },
    { lat: 48.9711972, lng: 19.0334901 },
    { lat: 48.9709484, lng: 19.034014 },
    { lat: 48.9708659, lng: 19.0343727 },
    { lat: 48.9707485, lng: 19.0347081 },
    { lat: 48.9707083, lng: 19.0350995 },
    { lat: 48.9703527, lng: 19.0359691 },
    { lat: 48.9702091, lng: 19.03639 },
    { lat: 48.9701735, lng: 19.0370077 },
    { lat: 48.9702709, lng: 19.0373101 },
    { lat: 48.9706859, lng: 19.0382419 },
    { lat: 48.9709288, lng: 19.0391085 },
    { lat: 48.9707512, lng: 19.0395502 },
    { lat: 48.9706455, lng: 19.040007 },
    { lat: 48.9705663, lng: 19.0404266 },
    { lat: 48.9704683, lng: 19.0407683 },
    { lat: 48.9704024, lng: 19.0409141 },
    { lat: 48.9702006, lng: 19.0411401 },
    { lat: 48.9699072, lng: 19.0413812 },
    { lat: 48.9693246, lng: 19.0419959 },
    { lat: 48.9688402, lng: 19.0425723 },
    { lat: 48.9687896, lng: 19.0428881 },
    { lat: 48.9682044, lng: 19.0433179 },
    { lat: 48.9678722, lng: 19.0437285 },
    { lat: 48.9674482, lng: 19.0441548 },
    { lat: 48.9671459, lng: 19.0447199 },
    { lat: 48.9663964, lng: 19.0446989 },
    { lat: 48.9660573, lng: 19.0446819 },
    { lat: 48.9656495, lng: 19.0449836 },
    { lat: 48.965473, lng: 19.0451969 },
    { lat: 48.9649225, lng: 19.0451185 },
    { lat: 48.9644209, lng: 19.0451891 },
    { lat: 48.963846, lng: 19.0451911 },
    { lat: 48.9634674, lng: 19.0449451 },
    { lat: 48.9627817, lng: 19.0447161 },
    { lat: 48.9623306, lng: 19.0450486 },
    { lat: 48.9615975, lng: 19.0462178 },
    { lat: 48.9611585, lng: 19.0465474 },
    { lat: 48.9609818, lng: 19.046689 },
    { lat: 48.9608078, lng: 19.0469297 },
    { lat: 48.9605098, lng: 19.0474895 },
    { lat: 48.9602888, lng: 19.0484592 },
    { lat: 48.9600721, lng: 19.0491772 },
    { lat: 48.959557, lng: 19.0502179 },
    { lat: 48.959291, lng: 19.05083 },
    { lat: 48.95917, lng: 19.0512787 },
    { lat: 48.9591808, lng: 19.05224 },
    { lat: 48.9583287, lng: 19.0529653 },
    { lat: 48.9577862, lng: 19.0534846 },
    { lat: 48.9573244, lng: 19.0537174 },
    { lat: 48.956839, lng: 19.0541782 },
    { lat: 48.9565948, lng: 19.0545065 },
    { lat: 48.9560506, lng: 19.0551199 },
    { lat: 48.9553055, lng: 19.0560485 },
    { lat: 48.9553337, lng: 19.0562558 },
    { lat: 48.9549666, lng: 19.0573034 },
    { lat: 48.9547261, lng: 19.0582479 },
    { lat: 48.9546745, lng: 19.0591147 },
    { lat: 48.9541822, lng: 19.0595518 },
    { lat: 48.9538225, lng: 19.0602982 },
    { lat: 48.9535132, lng: 19.0608083 },
    { lat: 48.9534646, lng: 19.0608529 },
    { lat: 48.9528573, lng: 19.0616522 },
    { lat: 48.9526137, lng: 19.0618979 },
    { lat: 48.9523879, lng: 19.0620063 },
    { lat: 48.9519419, lng: 19.0623307 },
    { lat: 48.9514994, lng: 19.0623675 },
    { lat: 48.9508795, lng: 19.0626613 },
    { lat: 48.9503184, lng: 19.0633246 },
    { lat: 48.9499182, lng: 19.0637564 },
    { lat: 48.949692, lng: 19.0639438 },
    { lat: 48.9493687, lng: 19.0641978 },
    { lat: 48.9493739, lng: 19.0649615 },
    { lat: 48.9493593, lng: 19.0663452 },
    { lat: 48.9492855, lng: 19.0669052 },
    { lat: 48.9487912, lng: 19.0676871 },
    { lat: 48.9483642, lng: 19.0682769 },
    { lat: 48.9482317, lng: 19.0685628 },
    { lat: 48.9478723, lng: 19.0692263 },
    { lat: 48.947199, lng: 19.0701487 },
    { lat: 48.9472229, lng: 19.0709472 },
    { lat: 48.946931, lng: 19.0719723 },
    { lat: 48.9466335, lng: 19.0734011 },
    { lat: 48.9465782, lng: 19.0739257 },
    { lat: 48.9462365, lng: 19.0746932 },
    { lat: 48.9459685, lng: 19.0751771 },
    { lat: 48.9456996, lng: 19.0755504 },
    { lat: 48.9453801, lng: 19.0773321 },
    { lat: 48.9451367, lng: 19.0793367 },
    { lat: 48.9446618, lng: 19.0808058 },
    { lat: 48.9444432, lng: 19.0820806 },
    { lat: 48.944333, lng: 19.082654 },
    { lat: 48.9427586, lng: 19.0838233 },
    { lat: 48.9414666, lng: 19.0856427 },
    { lat: 48.9409034, lng: 19.0879256 },
    { lat: 48.9411854, lng: 19.0894541 },
    { lat: 48.9414321, lng: 19.0907729 },
    { lat: 48.9414805, lng: 19.0913633 },
    { lat: 48.9413471, lng: 19.0918576 },
    { lat: 48.9409803, lng: 19.0931841 },
    { lat: 48.9408777, lng: 19.093555 },
    { lat: 48.9403114, lng: 19.0955983 },
    { lat: 48.9404501, lng: 19.0973327 },
    { lat: 48.9405313, lng: 19.0983577 },
    { lat: 48.9407341, lng: 19.0995358 },
    { lat: 48.9411577, lng: 19.1020648 },
    { lat: 48.9399624, lng: 19.1022651 },
    { lat: 48.9390465, lng: 19.102298 },
    { lat: 48.9381745, lng: 19.1024482 },
    { lat: 48.9371217, lng: 19.1028352 },
    { lat: 48.936694, lng: 19.1029868 },
    { lat: 48.9356529, lng: 19.1033631 },
    { lat: 48.9350823, lng: 19.1037231 },
    { lat: 48.9344397, lng: 19.1034018 },
    { lat: 48.9340096, lng: 19.1032536 },
    { lat: 48.9334832, lng: 19.1031367 },
    { lat: 48.9328014, lng: 19.1030311 },
    { lat: 48.9322651, lng: 19.1029007 },
    { lat: 48.9316961, lng: 19.1028145 },
    { lat: 48.9310021, lng: 19.1030925 },
    { lat: 48.9302716, lng: 19.1033685 },
    { lat: 48.9287046, lng: 19.1037752 },
    { lat: 48.9281006, lng: 19.1036894 },
    { lat: 48.9275946, lng: 19.1034878 },
    { lat: 48.9272137, lng: 19.1035343 },
    { lat: 48.9265444, lng: 19.1040202 },
    { lat: 48.926171, lng: 19.104254 },
  ],
};

export default UnitsMartin;
