import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryOwner from "../../History/HistoryOwner";

export default function Owners({ lvSubMenu, setLvSubMenu, historyItem }) {
  return (
    <>
      <div className="">
        <div className="p-2 flex flex-row gap-x-2 font-bold">
          <p className="text-lg uppercase text-sky-500 text-start">
            Časť B: Vlastníci a iné oprávnené osoby z práva k nehnuteľnosti
          </p>
          <button
            onClick={() => setLvSubMenu({ ...lvSubMenu, owner: !lvSubMenu.owner })}
            className={`flex items-start z-10 w-8 p-1 text-black`}
          >
            {lvSubMenu.owner ? (
              <FontAwesomeIcon className="h-6" icon="chevron-up" />
            ) : (
              <FontAwesomeIcon className="h-6" icon="chevron-down" />
            )}{" "}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {lvSubMenu.owner ? (
          <motion.div
            className="flex flex-col overflow-hidden px-2"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <HistoryOwner historyItem={historyItem} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
