import SubjectsHeader from "./SubjectsHeader";
import SubjectsScrollMenu from "./SubjectsScrollMenu";
import { useState } from "react";
import AboutUs from "./AboutUs";
import REBrokers from "./REBrokers";
import FBrokers from "./FBrokers";
import Partners from "./Partners";
import Properties from "./Properties";

export default function REOPreview({
  REOffices,
  indx,
  size,
  subMenu,
  setSubMenu,
  setForSale,
  forSale,
  navSmall,
  setNavSmall,
  viewSale,
  setViewSale,
  indxPrev,
  subjectsMenu,
  setSubjectsMenu,
  subjects,
  setSubjects,
}) {
  //   console.log("REOffices = ", REOffices[indx.current]);

  const [previous, setPrevious] = useState("");

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: false, share: true });
    }
  };
  // console.log("indx = ", indx.current);
  // console.log("indxPrev = ", indxPrev.current);
  return (
    <div className="w-full h-full">
      <SubjectsHeader office={REOffices[indx.current]} />
      <SubjectsScrollMenu size={size} subMenu={subMenu} showMenu={showMenu} subjectsMenu={subjectsMenu} />
      <AboutUs subMenu={subMenu} previous={previous} office={REOffices[indx.current]} />
      <REBrokers
        office={REOffices[indx.current]}
        size={size}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        previous={previous}
        subjects={subjects}
        setSubjects={setSubjects}
        setSubjectsMenu={setSubjectsMenu}
        subjectsMenu={subjectsMenu}
        indx={indx}
        indxPrev={indxPrev}
      />
      <FBrokers
        office={REOffices[indx.current]}
        size={size}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        subjects={subjects}
        setSubjects={setSubjects}
        setSubjectsMenu={setSubjectsMenu}
        subjectsMenu={subjectsMenu}
        indx={indx}
        indxPrev={indxPrev}
      />
      <Partners
        office={REOffices[indx.current]}
        size={size}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        previous={previous}
        subjects={subjects}
        setSubjects={setSubjects}
        setSubjectsMenu={setSubjectsMenu}
        subjectsMenu={subjectsMenu}
        indx={indx}
        indxPrev={indxPrev}
      />
      <Properties
        office={REOffices[indx.current]}
        size={size}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        previous={previous}
        setForSale={setForSale}
        forSale={forSale}
        navSmall={navSmall}
        setNavSmall={setNavSmall}
        viewSale={viewSale}
        setViewSale={setViewSale}
        indx={indx}
        indxPrev={indxPrev}
      />
    </div>
  );
}
