export default function FsTarchy({ lv }) {
  function getTableBodyTarchy() {
    if (lv?.tarchy instanceof Array) {
      return !lv ? null : (
        <tbody>
          {lv?.tarchy?.map((item, index) => {
            return (
              <tr key={index} className="border border-opacity-50 border-sky-500">
                <td className="text-center w-40">
                  Poradové č. vlastníka <br />
                  {item.poradove_c_vlastnika_}
                </td>
                <td className="text-center">{item.tarcha}</td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    if (lv?.tarchy instanceof Object) {
      return (
        <tbody>
          <tr className="border border-opacity-50 border-sky-500">
            <td className="text-center w-40">
              Poradové č. vlastníka <br />
              {lv?.tarchy?.poradove_c_vlastnika_}
            </td>
            <td className="text-center">{lv?.tarchy?.tarcha}</td>
          </tr>
        </tbody>
      );
    }
  }

  return (
    <>
      <div className="flex flex-col overflow-scroll">
        <table className="w-full">{getTableBodyTarchy()}</table>
      </div>
    </>
  );
}
