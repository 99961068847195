const UnitsKošiceI = {
  KošicemestskáčasťKavečany: [
    { lat: 48.798475, lng: 21.202812 },
    { lat: 48.798453, lng: 21.202689 },
    { lat: 48.798493, lng: 21.202598 },
    { lat: 48.798765, lng: 21.202515 },
    { lat: 48.798768, lng: 21.20242 },
    { lat: 48.798786, lng: 21.202314 },
    { lat: 48.798755, lng: 21.202188 },
    { lat: 48.798787, lng: 21.202067 },
    { lat: 48.798781, lng: 21.201943 },
    { lat: 48.798804, lng: 21.201817 },
    { lat: 48.798795, lng: 21.201639 },
    { lat: 48.798895, lng: 21.201374 },
    { lat: 48.798883, lng: 21.201113 },
    { lat: 48.798902, lng: 21.200882 },
    { lat: 48.798845, lng: 21.199932 },
    { lat: 48.798854, lng: 21.199571 },
    { lat: 48.798789, lng: 21.199452 },
    { lat: 48.798782, lng: 21.199306 },
    { lat: 48.798701, lng: 21.199134 },
    { lat: 48.798696, lng: 21.198939 },
    { lat: 48.798731, lng: 21.198677 },
    { lat: 48.798765, lng: 21.198582 },
    { lat: 48.798726, lng: 21.198268 },
    { lat: 48.798693, lng: 21.197682 },
    { lat: 48.798629, lng: 21.197141 },
    { lat: 48.798462, lng: 21.19625 },
    { lat: 48.79836, lng: 21.196103 },
    { lat: 48.798355, lng: 21.195925 },
    { lat: 48.798256, lng: 21.195498 },
    { lat: 48.798199, lng: 21.195404 },
    { lat: 48.798178, lng: 21.195233 },
    { lat: 48.798188, lng: 21.195112 },
    { lat: 48.79805, lng: 21.194547 },
    { lat: 48.79796, lng: 21.194417 },
    { lat: 48.79792, lng: 21.194198 },
    { lat: 48.797962, lng: 21.193919 },
    { lat: 48.798029, lng: 21.193766 },
    { lat: 48.798033, lng: 21.193526 },
    { lat: 48.798067, lng: 21.193275 },
    { lat: 48.798142, lng: 21.193079 },
    { lat: 48.798155, lng: 21.192904 },
    { lat: 48.79822, lng: 21.192527 },
    { lat: 48.79827, lng: 21.191391 },
    { lat: 48.7985, lng: 21.189714 },
    { lat: 48.79853, lng: 21.189519 },
    { lat: 48.798603, lng: 21.189243 },
    { lat: 48.798769, lng: 21.189037 },
    { lat: 48.798796, lng: 21.188928 },
    { lat: 48.798799, lng: 21.188752 },
    { lat: 48.798919, lng: 21.188609 },
    { lat: 48.799109, lng: 21.188261 },
    { lat: 48.799415, lng: 21.18761 },
    { lat: 48.799773, lng: 21.186681 },
    { lat: 48.799999, lng: 21.186049 },
    { lat: 48.800344, lng: 21.185017 },
    { lat: 48.800463, lng: 21.184774 },
    { lat: 48.800681, lng: 21.184092 },
    { lat: 48.801257, lng: 21.182977 },
    { lat: 48.801885, lng: 21.181751 },
    { lat: 48.80211, lng: 21.181335 },
    { lat: 48.8021128, lng: 21.181215 },
    { lat: 48.8019428, lng: 21.1811055 },
    { lat: 48.8018731, lng: 21.1810016 },
    { lat: 48.8016109, lng: 21.1809638 },
    { lat: 48.8013956, lng: 21.1805292 },
    { lat: 48.8011386, lng: 21.1800508 },
    { lat: 48.8009661, lng: 21.1797884 },
    { lat: 48.8008142, lng: 21.179684 },
    { lat: 48.8005878, lng: 21.1797122 },
    { lat: 48.8004716, lng: 21.1798119 },
    { lat: 48.8002317, lng: 21.1802319 },
    { lat: 48.8001581, lng: 21.1802929 },
    { lat: 48.8001285, lng: 21.180314 },
    { lat: 48.7999241, lng: 21.1804355 },
    { lat: 48.7997671, lng: 21.1804526 },
    { lat: 48.7996885, lng: 21.180531 },
    { lat: 48.7996656, lng: 21.1805545 },
    { lat: 48.799594, lng: 21.1806028 },
    { lat: 48.7993867, lng: 21.1805236 },
    { lat: 48.799261, lng: 21.1805348 },
    { lat: 48.7990826, lng: 21.1806962 },
    { lat: 48.7988986, lng: 21.1807841 },
    { lat: 48.7987441, lng: 21.1808859 },
    { lat: 48.7985386, lng: 21.1810053 },
    { lat: 48.7983734, lng: 21.1810472 },
    { lat: 48.7982261, lng: 21.1810539 },
    { lat: 48.7981732, lng: 21.1810167 },
    { lat: 48.798103, lng: 21.1809678 },
    { lat: 48.7978889, lng: 21.1808516 },
    { lat: 48.7972446, lng: 21.1809024 },
    { lat: 48.7970516, lng: 21.1808471 },
    { lat: 48.7967251, lng: 21.1806315 },
    { lat: 48.7965261, lng: 21.1806376 },
    { lat: 48.7964839, lng: 21.180656 },
    { lat: 48.7963963, lng: 21.1806966 },
    { lat: 48.7962693, lng: 21.180775 },
    { lat: 48.7961416, lng: 21.1808999 },
    { lat: 48.7959823, lng: 21.1810228 },
    { lat: 48.7956932, lng: 21.1809212 },
    { lat: 48.7955108, lng: 21.1811318 },
    { lat: 48.7953843, lng: 21.1812979 },
    { lat: 48.7953646, lng: 21.1813154 },
    { lat: 48.7952051, lng: 21.1814344 },
    { lat: 48.7950348, lng: 21.181563 },
    { lat: 48.7947665, lng: 21.1818351 },
    { lat: 48.7946654, lng: 21.1819466 },
    { lat: 48.7943959, lng: 21.182181 },
    { lat: 48.7941467, lng: 21.1823439 },
    { lat: 48.7940151, lng: 21.182489 },
    { lat: 48.7938717, lng: 21.1827256 },
    { lat: 48.7936339, lng: 21.1831404 },
    { lat: 48.7934543, lng: 21.1833347 },
    { lat: 48.7932755, lng: 21.1834468 },
    { lat: 48.7931359, lng: 21.183625 },
    { lat: 48.7929707, lng: 21.1837372 },
    { lat: 48.7926011, lng: 21.1837246 },
    { lat: 48.7923859, lng: 21.1837508 },
    { lat: 48.7919978, lng: 21.1838995 },
    { lat: 48.7916496, lng: 21.1839945 },
    { lat: 48.7915503, lng: 21.1840203 },
    { lat: 48.7914038, lng: 21.1840508 },
    { lat: 48.791142, lng: 21.1841375 },
    { lat: 48.7910586, lng: 21.1842406 },
    { lat: 48.7904803, lng: 21.1845307 },
    { lat: 48.7902473, lng: 21.1846353 },
    { lat: 48.7900101, lng: 21.1846795 },
    { lat: 48.7897513, lng: 21.1847539 },
    { lat: 48.7893162, lng: 21.1847638 },
    { lat: 48.7891286, lng: 21.1848134 },
    { lat: 48.7888721, lng: 21.184778 },
    { lat: 48.7887335, lng: 21.1847737 },
    { lat: 48.788459, lng: 21.1848849 },
    { lat: 48.7883356, lng: 21.1849327 },
    { lat: 48.7881843, lng: 21.1849564 },
    { lat: 48.7881093, lng: 21.1849668 },
    { lat: 48.7880046, lng: 21.1849987 },
    { lat: 48.7879243, lng: 21.1850223 },
    { lat: 48.7878512, lng: 21.1850615 },
    { lat: 48.787637, lng: 21.1850855 },
    { lat: 48.7875084, lng: 21.1850737 },
    { lat: 48.7872323, lng: 21.1849922 },
    { lat: 48.7868131, lng: 21.1850464 },
    { lat: 48.7866931, lng: 21.1850236 },
    { lat: 48.7866795, lng: 21.185021 },
    { lat: 48.7864643, lng: 21.1851215 },
    { lat: 48.786317, lng: 21.1851614 },
    { lat: 48.7862033, lng: 21.1852309 },
    { lat: 48.785988, lng: 21.1853624 },
    { lat: 48.7856173, lng: 21.1855266 },
    { lat: 48.7854376, lng: 21.1856459 },
    { lat: 48.785236, lng: 21.1857503 },
    { lat: 48.7849761, lng: 21.1859304 },
    { lat: 48.7848863, lng: 21.1859729 },
    { lat: 48.7847416, lng: 21.1860243 },
    { lat: 48.7846962, lng: 21.1860465 },
    { lat: 48.784306, lng: 21.1862363 },
    { lat: 48.784137, lng: 21.1863759 },
    { lat: 48.7840212, lng: 21.1864481 },
    { lat: 48.783933, lng: 21.1865048 },
    { lat: 48.7838381, lng: 21.1865327 },
    { lat: 48.7837099, lng: 21.1865705 },
    { lat: 48.7835343, lng: 21.186712 },
    { lat: 48.7833647, lng: 21.1869001 },
    { lat: 48.7830475, lng: 21.1871228 },
    { lat: 48.7828944, lng: 21.187263 },
    { lat: 48.7827374, lng: 21.1873157 },
    { lat: 48.7825499, lng: 21.187402 },
    { lat: 48.7822081, lng: 21.1874488 },
    { lat: 48.7819403, lng: 21.1875576 },
    { lat: 48.7815165, lng: 21.1876435 },
    { lat: 48.781332, lng: 21.1877517 },
    { lat: 48.7811353, lng: 21.1877626 },
    { lat: 48.7807101, lng: 21.1878743 },
    { lat: 48.7806632, lng: 21.1878883 },
    { lat: 48.7806629, lng: 21.187842 },
    { lat: 48.7805786, lng: 21.1878452 },
    { lat: 48.7803416, lng: 21.1878608 },
    { lat: 48.7801274, lng: 21.187917 },
    { lat: 48.7799195, lng: 21.1880379 },
    { lat: 48.7797552, lng: 21.1880729 },
    { lat: 48.7796418, lng: 21.1880443 },
    { lat: 48.7796338, lng: 21.1880417 },
    { lat: 48.7794867, lng: 21.1879085 },
    { lat: 48.779401, lng: 21.1878683 },
    { lat: 48.7793166, lng: 21.1878669 },
    { lat: 48.7792312, lng: 21.1879073 },
    { lat: 48.7790041, lng: 21.1880214 },
    { lat: 48.7789125, lng: 21.1880444 },
    { lat: 48.7786797, lng: 21.188084 },
    { lat: 48.7785505, lng: 21.1881276 },
    { lat: 48.7781561, lng: 21.1883272 },
    { lat: 48.7778472, lng: 21.18856 },
    { lat: 48.7777858, lng: 21.1886108 },
    { lat: 48.7775877, lng: 21.1887882 },
    { lat: 48.7774558, lng: 21.1889582 },
    { lat: 48.7773823, lng: 21.1890923 },
    { lat: 48.776486, lng: 21.1897927 },
    { lat: 48.7763021, lng: 21.1899212 },
    { lat: 48.7763268, lng: 21.1901017 },
    { lat: 48.7762656, lng: 21.1903352 },
    { lat: 48.7761644, lng: 21.1904438 },
    { lat: 48.7762181, lng: 21.1905431 },
    { lat: 48.7761648, lng: 21.1908888 },
    { lat: 48.7760047, lng: 21.191271 },
    { lat: 48.775761, lng: 21.1914666 },
    { lat: 48.7758652, lng: 21.1915615 },
    { lat: 48.7758231, lng: 21.191653 },
    { lat: 48.7755131, lng: 21.1922112 },
    { lat: 48.7752717, lng: 21.1919439 },
    { lat: 48.775214, lng: 21.192004 },
    { lat: 48.7751715, lng: 21.1918683 },
    { lat: 48.7753201, lng: 21.1911554 },
    { lat: 48.7751618, lng: 21.1912375 },
    { lat: 48.7749771, lng: 21.1913414 },
    { lat: 48.774823, lng: 21.1912354 },
    { lat: 48.7747973, lng: 21.1912426 },
    { lat: 48.7744908, lng: 21.1912294 },
    { lat: 48.7743536, lng: 21.1912328 },
    { lat: 48.7740406, lng: 21.1912303 },
    { lat: 48.7736363, lng: 21.1910404 },
    { lat: 48.7736114, lng: 21.1914303 },
    { lat: 48.7734623, lng: 21.1913765 },
    { lat: 48.7733868, lng: 21.1914829 },
    { lat: 48.7732824, lng: 21.1914562 },
    { lat: 48.7733149, lng: 21.1913407 },
    { lat: 48.7732853, lng: 21.1913304 },
    { lat: 48.7731322, lng: 21.1911858 },
    { lat: 48.772946, lng: 21.1912071 },
    { lat: 48.7728787, lng: 21.1913917 },
    { lat: 48.7726196, lng: 21.1914204 },
    { lat: 48.7722543, lng: 21.1911469 },
    { lat: 48.7718774, lng: 21.1912597 },
    { lat: 48.7717779, lng: 21.1912083 },
    { lat: 48.7713744, lng: 21.1914785 },
    { lat: 48.7712303, lng: 21.1914725 },
    { lat: 48.7711368, lng: 21.1916512 },
    { lat: 48.770409, lng: 21.1922704 },
    { lat: 48.7702846, lng: 21.1925716 },
    { lat: 48.7700238, lng: 21.1928362 },
    { lat: 48.7698463, lng: 21.1930227 },
    { lat: 48.769663, lng: 21.1930266 },
    { lat: 48.7694194, lng: 21.1928336 },
    { lat: 48.7692316, lng: 21.1925132 },
    { lat: 48.7690374, lng: 21.1924215 },
    { lat: 48.7689082, lng: 21.1922146 },
    { lat: 48.7684805, lng: 21.1920356 },
    { lat: 48.7682131, lng: 21.1924059 },
    { lat: 48.7678196, lng: 21.1929586 },
    { lat: 48.7676069, lng: 21.1932065 },
    { lat: 48.767468, lng: 21.1932615 },
    { lat: 48.7673183, lng: 21.1933354 },
    { lat: 48.7671778, lng: 21.1935566 },
    { lat: 48.7667959, lng: 21.1935788 },
    { lat: 48.7665947, lng: 21.1935511 },
    { lat: 48.766595, lng: 21.1938458 },
    { lat: 48.7665851, lng: 21.1940327 },
    { lat: 48.7665923, lng: 21.1941546 },
    { lat: 48.7666481, lng: 21.1944616 },
    { lat: 48.7666524, lng: 21.1946375 },
    { lat: 48.7666238, lng: 21.1948012 },
    { lat: 48.7664964, lng: 21.1952556 },
    { lat: 48.7662768, lng: 21.1957936 },
    { lat: 48.7660982, lng: 21.1960201 },
    { lat: 48.7650708, lng: 21.1960173 },
    { lat: 48.7643851, lng: 21.1959395 },
    { lat: 48.7640864, lng: 21.1959225 },
    { lat: 48.7637294, lng: 21.1958461 },
    { lat: 48.7634996, lng: 21.1957627 },
    { lat: 48.7633154, lng: 21.195777 },
    { lat: 48.7632107, lng: 21.1957756 },
    { lat: 48.7631203, lng: 21.1957257 },
    { lat: 48.7629193, lng: 21.1955632 },
    { lat: 48.7626979, lng: 21.1954286 },
    { lat: 48.7622263, lng: 21.1950705 },
    { lat: 48.7621113, lng: 21.1948469 },
    { lat: 48.761883, lng: 21.1947466 },
    { lat: 48.7618139, lng: 21.1948386 },
    { lat: 48.7615391, lng: 21.1956556 },
    { lat: 48.7614307, lng: 21.1963979 },
    { lat: 48.7612797, lng: 21.1968218 },
    { lat: 48.7611963, lng: 21.1978572 },
    { lat: 48.7609632, lng: 21.1978162 },
    { lat: 48.7608908, lng: 21.1978035 },
    { lat: 48.7606757, lng: 21.1977662 },
    { lat: 48.7600852, lng: 21.1977041 },
    { lat: 48.7594637, lng: 21.1977687 },
    { lat: 48.759345, lng: 21.1979302 },
    { lat: 48.7590574, lng: 21.1980158 },
    { lat: 48.7590621, lng: 21.1979848 },
    { lat: 48.7587916, lng: 21.198012 },
    { lat: 48.7587219, lng: 21.1980106 },
    { lat: 48.7586904, lng: 21.19801 },
    { lat: 48.7586847, lng: 21.1980101 },
    { lat: 48.7586534, lng: 21.1980188 },
    { lat: 48.758551, lng: 21.1980557 },
    { lat: 48.7584654, lng: 21.1980872 },
    { lat: 48.7583813, lng: 21.1981325 },
    { lat: 48.7582882, lng: 21.1981812 },
    { lat: 48.7582865, lng: 21.1981589 },
    { lat: 48.7581842, lng: 21.1982718 },
    { lat: 48.758124, lng: 21.1982526 },
    { lat: 48.7580748, lng: 21.1983346 },
    { lat: 48.7580513, lng: 21.1983042 },
    { lat: 48.7580337, lng: 21.1982812 },
    { lat: 48.7579615, lng: 21.198347 },
    { lat: 48.7578551, lng: 21.1983639 },
    { lat: 48.7577555, lng: 21.1983807 },
    { lat: 48.7576511, lng: 21.1983921 },
    { lat: 48.757544, lng: 21.198419 },
    { lat: 48.7574311, lng: 21.1984432 },
    { lat: 48.7573096, lng: 21.1985557 },
    { lat: 48.7571406, lng: 21.1986563 },
    { lat: 48.7568953, lng: 21.198803 },
    { lat: 48.7568453, lng: 21.1988195 },
    { lat: 48.7567718, lng: 21.1989164 },
    { lat: 48.7566247, lng: 21.1993189 },
    { lat: 48.7565646, lng: 21.1998108 },
    { lat: 48.756444, lng: 21.2004504 },
    { lat: 48.7563429, lng: 21.2011382 },
    { lat: 48.7564415, lng: 21.2013409 },
    { lat: 48.7564901, lng: 21.2018495 },
    { lat: 48.756486, lng: 21.2024362 },
    { lat: 48.7567173, lng: 21.2024903 },
    { lat: 48.7569737, lng: 21.2025903 },
    { lat: 48.7574691, lng: 21.2025997 },
    { lat: 48.7576507, lng: 21.2025781 },
    { lat: 48.7576917, lng: 21.2026294 },
    { lat: 48.7578206, lng: 21.2027914 },
    { lat: 48.7578382, lng: 21.2028129 },
    { lat: 48.7578394, lng: 21.2028157 },
    { lat: 48.7579558, lng: 21.2028651 },
    { lat: 48.7581456, lng: 21.2029635 },
    { lat: 48.7581804, lng: 21.203175 },
    { lat: 48.7578561, lng: 21.2038546 },
    { lat: 48.7578877, lng: 21.2039251 },
    { lat: 48.7581367, lng: 21.2058783 },
    { lat: 48.7582798, lng: 21.2069937 },
    { lat: 48.7582693, lng: 21.2076301 },
    { lat: 48.7582682, lng: 21.2077058 },
    { lat: 48.7582517, lng: 21.2079316 },
    { lat: 48.7582607, lng: 21.2081108 },
    { lat: 48.7583861, lng: 21.2083415 },
    { lat: 48.7585014, lng: 21.208432 },
    { lat: 48.758521, lng: 21.2085515 },
    { lat: 48.7584531, lng: 21.2086527 },
    { lat: 48.7580586, lng: 21.2088436 },
    { lat: 48.7578753, lng: 21.2095312 },
    { lat: 48.7578905, lng: 21.210129 },
    { lat: 48.7578063, lng: 21.2107121 },
    { lat: 48.7577968, lng: 21.2109824 },
    { lat: 48.7578454, lng: 21.2111313 },
    { lat: 48.7578614, lng: 21.2111783 },
    { lat: 48.757776, lng: 21.2112158 },
    { lat: 48.7577153, lng: 21.2112565 },
    { lat: 48.7577055, lng: 21.2112665 },
    { lat: 48.7576018, lng: 21.2113666 },
    { lat: 48.7575525, lng: 21.2114465 },
    { lat: 48.7574296, lng: 21.2116564 },
    { lat: 48.7575191, lng: 21.2117852 },
    { lat: 48.7577324, lng: 21.2120885 },
    { lat: 48.7579289, lng: 21.2125449 },
    { lat: 48.7581328, lng: 21.213055 },
    { lat: 48.758305, lng: 21.213486 },
    { lat: 48.7583993, lng: 21.2137692 },
    { lat: 48.7585583, lng: 21.2142444 },
    { lat: 48.7586185, lng: 21.2144109 },
    { lat: 48.758863, lng: 21.2147773 },
    { lat: 48.7588915, lng: 21.2148269 },
    { lat: 48.7589094, lng: 21.2148207 },
    { lat: 48.7589762, lng: 21.2147966 },
    { lat: 48.7590552, lng: 21.2147689 },
    { lat: 48.7591663, lng: 21.2146497 },
    { lat: 48.7591892, lng: 21.2146295 },
    { lat: 48.7593181, lng: 21.214504 },
    { lat: 48.7593606, lng: 21.2144952 },
    { lat: 48.7595339, lng: 21.2142803 },
    { lat: 48.7595484, lng: 21.2143074 },
    { lat: 48.7596442, lng: 21.214134 },
    { lat: 48.7596708, lng: 21.2140868 },
    { lat: 48.759678, lng: 21.2140966 },
    { lat: 48.7597072, lng: 21.2141352 },
    { lat: 48.7597181, lng: 21.2142689 },
    { lat: 48.7597524, lng: 21.2144273 },
    { lat: 48.7597685, lng: 21.2144732 },
    { lat: 48.7598462, lng: 21.2145504 },
    { lat: 48.7599322, lng: 21.2146374 },
    { lat: 48.7600591, lng: 21.2146971 },
    { lat: 48.7601863, lng: 21.2148079 },
    { lat: 48.7601945, lng: 21.2148152 },
    { lat: 48.7602639, lng: 21.2148787 },
    { lat: 48.7602855, lng: 21.214992 },
    { lat: 48.760319, lng: 21.2150574 },
    { lat: 48.7604984, lng: 21.2153948 },
    { lat: 48.7606253, lng: 21.2154928 },
    { lat: 48.7605726, lng: 21.2155729 },
    { lat: 48.7605124, lng: 21.2156656 },
    { lat: 48.7608804, lng: 21.2163114 },
    { lat: 48.7609748, lng: 21.216453 },
    { lat: 48.7611605, lng: 21.2167793 },
    { lat: 48.7615989, lng: 21.2174853 },
    { lat: 48.7616514, lng: 21.217579 },
    { lat: 48.7618398, lng: 21.2178444 },
    { lat: 48.7618692, lng: 21.2178859 },
    { lat: 48.7620221, lng: 21.2180954 },
    { lat: 48.7621827, lng: 21.2183037 },
    { lat: 48.7623962, lng: 21.2185834 },
    { lat: 48.7624398, lng: 21.2186417 },
    { lat: 48.7625791, lng: 21.2188507 },
    { lat: 48.7626747, lng: 21.2189941 },
    { lat: 48.7626971, lng: 21.2190289 },
    { lat: 48.7626994, lng: 21.2190323 },
    { lat: 48.7628399, lng: 21.2192752 },
    { lat: 48.7629007, lng: 21.21935 },
    { lat: 48.7630294, lng: 21.2194732 },
    { lat: 48.7631062, lng: 21.2195875 },
    { lat: 48.7630005, lng: 21.2195887 },
    { lat: 48.7628651, lng: 21.2196511 },
    { lat: 48.7628385, lng: 21.2196634 },
    { lat: 48.7627217, lng: 21.2197459 },
    { lat: 48.7626965, lng: 21.2197645 },
    { lat: 48.7626738, lng: 21.219796 },
    { lat: 48.7625897, lng: 21.219914 },
    { lat: 48.7625705, lng: 21.2199497 },
    { lat: 48.762527, lng: 21.2200327 },
    { lat: 48.7624791, lng: 21.2201228 },
    { lat: 48.7623862, lng: 21.2202089 },
    { lat: 48.7625152, lng: 21.2204862 },
    { lat: 48.7625072, lng: 21.2205488 },
    { lat: 48.7624707, lng: 21.220825 },
    { lat: 48.7624357, lng: 21.2210365 },
    { lat: 48.762417, lng: 21.2211606 },
    { lat: 48.7623869, lng: 21.2213504 },
    { lat: 48.7623629, lng: 21.221483 },
    { lat: 48.7623283, lng: 21.2216713 },
    { lat: 48.7622954, lng: 21.2218424 },
    { lat: 48.7622633, lng: 21.2220055 },
    { lat: 48.7622231, lng: 21.2221967 },
    { lat: 48.7622015, lng: 21.2222282 },
    { lat: 48.7620308, lng: 21.2224898 },
    { lat: 48.7620431, lng: 21.2225263 },
    { lat: 48.7619717, lng: 21.2227604 },
    { lat: 48.7619242, lng: 21.2229693 },
    { lat: 48.7618838, lng: 21.2230813 },
    { lat: 48.7618004, lng: 21.2231507 },
    { lat: 48.7617884, lng: 21.2231591 },
    { lat: 48.7617729, lng: 21.2231705 },
    { lat: 48.7616855, lng: 21.2232553 },
    { lat: 48.7616561, lng: 21.2232845 },
    { lat: 48.7616167, lng: 21.2233229 },
    { lat: 48.7615728, lng: 21.2233578 },
    { lat: 48.7615288, lng: 21.2233927 },
    { lat: 48.7615034, lng: 21.2234049 },
    { lat: 48.7614357, lng: 21.2234371 },
    { lat: 48.761218, lng: 21.2235283 },
    { lat: 48.7610481, lng: 21.223601 },
    { lat: 48.761026, lng: 21.2236525 },
    { lat: 48.7610795, lng: 21.2239962 },
    { lat: 48.7611005, lng: 21.2241281 },
    { lat: 48.7611712, lng: 21.2243727 },
    { lat: 48.7612389, lng: 21.2246704 },
    { lat: 48.7613035, lng: 21.2249718 },
    { lat: 48.7612993, lng: 21.2250552 },
    { lat: 48.7613246, lng: 21.2252906 },
    { lat: 48.7613637, lng: 21.2253849 },
    { lat: 48.7614586, lng: 21.2255443 },
    { lat: 48.7615704, lng: 21.2256997 },
    { lat: 48.7617294, lng: 21.225891 },
    { lat: 48.7620089, lng: 21.2260858 },
    { lat: 48.7621185, lng: 21.2261715 },
    { lat: 48.7621926, lng: 21.2262383 },
    { lat: 48.7622568, lng: 21.2262783 },
    { lat: 48.7624571, lng: 21.2263874 },
    { lat: 48.762471, lng: 21.2263957 },
    { lat: 48.7624871, lng: 21.2264053 },
    { lat: 48.7626005, lng: 21.2264732 },
    { lat: 48.7626309, lng: 21.2264717 },
    { lat: 48.7626499, lng: 21.2264704 },
    { lat: 48.7627126, lng: 21.2264238 },
    { lat: 48.762788, lng: 21.2263499 },
    { lat: 48.7628502, lng: 21.2262877 },
    { lat: 48.7628699, lng: 21.2262685 },
    { lat: 48.7629103, lng: 21.2262285 },
    { lat: 48.7629401, lng: 21.2262103 },
    { lat: 48.7630153, lng: 21.2261319 },
    { lat: 48.763047, lng: 21.2261007 },
    { lat: 48.7630645, lng: 21.2260834 },
    { lat: 48.7631234, lng: 21.2260257 },
    { lat: 48.7632042, lng: 21.2259465 },
    { lat: 48.7633514, lng: 21.2257919 },
    { lat: 48.7634611, lng: 21.2256649 },
    { lat: 48.7634784, lng: 21.2256444 },
    { lat: 48.7635282, lng: 21.2255442 },
    { lat: 48.7635814, lng: 21.2254487 },
    { lat: 48.7636943, lng: 21.2252074 },
    { lat: 48.7637039, lng: 21.2251899 },
    { lat: 48.7638077, lng: 21.2249796 },
    { lat: 48.7638592, lng: 21.2248967 },
    { lat: 48.7639066, lng: 21.2247967 },
    { lat: 48.7639785, lng: 21.2246471 },
    { lat: 48.7639933, lng: 21.224616 },
    { lat: 48.7640079, lng: 21.2246492 },
    { lat: 48.7640187, lng: 21.2246742 },
    { lat: 48.7640431, lng: 21.2247341 },
    { lat: 48.7640479, lng: 21.2247434 },
    { lat: 48.7640613, lng: 21.2247767 },
    { lat: 48.7642491, lng: 21.2250209 },
    { lat: 48.7642719, lng: 21.2250681 },
    { lat: 48.7647859, lng: 21.2248093 },
    { lat: 48.7652204, lng: 21.2247369 },
    { lat: 48.765993, lng: 21.2248656 },
    { lat: 48.7661863, lng: 21.2248172 },
    { lat: 48.7663581, lng: 21.2248445 },
    { lat: 48.7665194, lng: 21.2248901 },
    { lat: 48.7678583, lng: 21.2250269 },
    { lat: 48.7679424, lng: 21.2250167 },
    { lat: 48.768066, lng: 21.2250471 },
    { lat: 48.7681149, lng: 21.2250614 },
    { lat: 48.7681592, lng: 21.2250751 },
    { lat: 48.7682596, lng: 21.2250861 },
    { lat: 48.7686193, lng: 21.2249998 },
    { lat: 48.7688746, lng: 21.2248489 },
    { lat: 48.7691128, lng: 21.2247285 },
    { lat: 48.7692137, lng: 21.2247505 },
    { lat: 48.7694331, lng: 21.2246054 },
    { lat: 48.769487, lng: 21.2246361 },
    { lat: 48.7695044, lng: 21.2247626 },
    { lat: 48.7695039, lng: 21.2248915 },
    { lat: 48.7693829, lng: 21.2253057 },
    { lat: 48.7693499, lng: 21.2254743 },
    { lat: 48.7693688, lng: 21.2256503 },
    { lat: 48.7694191, lng: 21.2258525 },
    { lat: 48.7695151, lng: 21.2261168 },
    { lat: 48.7696309, lng: 21.2263687 },
    { lat: 48.7697787, lng: 21.2265968 },
    { lat: 48.7700372, lng: 21.226766 },
    { lat: 48.770221, lng: 21.2268514 },
    { lat: 48.7704233, lng: 21.2269865 },
    { lat: 48.7706243, lng: 21.2272244 },
    { lat: 48.770876, lng: 21.2276489 },
    { lat: 48.7711588, lng: 21.2279812 },
    { lat: 48.771288, lng: 21.2281226 },
    { lat: 48.7714293, lng: 21.2282124 },
    { lat: 48.7717108, lng: 21.2282184 },
    { lat: 48.7718115, lng: 21.2281624 },
    { lat: 48.7717928, lng: 21.2281037 },
    { lat: 48.7718474, lng: 21.2280178 },
    { lat: 48.7719823, lng: 21.2278641 },
    { lat: 48.7720088, lng: 21.2278122 },
    { lat: 48.7721545, lng: 21.2277576 },
    { lat: 48.7723138, lng: 21.2272346 },
    { lat: 48.7724471, lng: 21.2273236 },
    { lat: 48.7724028, lng: 21.227492 },
    { lat: 48.7725752, lng: 21.2273901 },
    { lat: 48.7727154, lng: 21.2273009 },
    { lat: 48.7729231, lng: 21.227284 },
    { lat: 48.7729963, lng: 21.2270704 },
    { lat: 48.7733293, lng: 21.2269957 },
    { lat: 48.7734486, lng: 21.2268509 },
    { lat: 48.7736083, lng: 21.226847 },
    { lat: 48.773828, lng: 21.2268535 },
    { lat: 48.7740645, lng: 21.2268602 },
    { lat: 48.774373, lng: 21.22687 },
    { lat: 48.774401, lng: 21.226708 },
    { lat: 48.774207, lng: 21.226378 },
    { lat: 48.774247, lng: 21.22619 },
    { lat: 48.77418, lng: 21.225985 },
    { lat: 48.774427, lng: 21.225222 },
    { lat: 48.774209, lng: 21.224978 },
    { lat: 48.774247, lng: 21.224148 },
    { lat: 48.774208, lng: 21.223968 },
    { lat: 48.774328, lng: 21.223794 },
    { lat: 48.77486, lng: 21.224116 },
    { lat: 48.775115, lng: 21.224816 },
    { lat: 48.775011, lng: 21.225227 },
    { lat: 48.775038, lng: 21.225465 },
    { lat: 48.775249, lng: 21.226142 },
    { lat: 48.775225, lng: 21.226431 },
    { lat: 48.77527, lng: 21.226765 },
    { lat: 48.775492, lng: 21.227384 },
    { lat: 48.775909, lng: 21.228227 },
    { lat: 48.775966, lng: 21.228343 },
    { lat: 48.776096, lng: 21.228444 },
    { lat: 48.776315, lng: 21.228833 },
    { lat: 48.776363, lng: 21.229105 },
    { lat: 48.776504, lng: 21.229553 },
    { lat: 48.776542, lng: 21.229546 },
    { lat: 48.776812, lng: 21.229493 },
    { lat: 48.776624, lng: 21.228863 },
    { lat: 48.776848, lng: 21.22869 },
    { lat: 48.777026, lng: 21.228519 },
    { lat: 48.777606, lng: 21.227964 },
    { lat: 48.777634, lng: 21.227905 },
    { lat: 48.777691, lng: 21.227786 },
    { lat: 48.777698, lng: 21.227773 },
    { lat: 48.777709, lng: 21.227767 },
    { lat: 48.777814, lng: 21.227704 },
    { lat: 48.77787, lng: 21.227586 },
    { lat: 48.777857, lng: 21.226992 },
    { lat: 48.777756, lng: 21.226705 },
    { lat: 48.777706, lng: 21.226572 },
    { lat: 48.777613, lng: 21.226422 },
    { lat: 48.77761, lng: 21.226419 },
    { lat: 48.77761, lng: 21.226418 },
    { lat: 48.777615, lng: 21.226274 },
    { lat: 48.777581, lng: 21.22609 },
    { lat: 48.777543, lng: 21.22594 },
    { lat: 48.777474, lng: 21.225629 },
    { lat: 48.777373, lng: 21.225363 },
    { lat: 48.77735, lng: 21.225075 },
    { lat: 48.777419, lng: 21.22503 },
    { lat: 48.777485, lng: 21.224985 },
    { lat: 48.777689, lng: 21.225122 },
    { lat: 48.777719, lng: 21.225204 },
    { lat: 48.777858, lng: 21.225586 },
    { lat: 48.777861, lng: 21.225591 },
    { lat: 48.778025, lng: 21.226129 },
    { lat: 48.778058, lng: 21.226036 },
    { lat: 48.778107, lng: 21.226005 },
    { lat: 48.778177, lng: 21.225961 },
    { lat: 48.778187, lng: 21.225997 },
    { lat: 48.778337, lng: 21.226542 },
    { lat: 48.778412, lng: 21.226499 },
    { lat: 48.778485, lng: 21.226457 },
    { lat: 48.778607, lng: 21.226408 },
    { lat: 48.778709, lng: 21.226368 },
    { lat: 48.778944, lng: 21.226055 },
    { lat: 48.77931, lng: 21.225622 },
    { lat: 48.779546, lng: 21.225777 },
    { lat: 48.779488, lng: 21.225155 },
    { lat: 48.779535, lng: 21.225074 },
    { lat: 48.779648, lng: 21.224881 },
    { lat: 48.779953, lng: 21.224492 },
    { lat: 48.780294, lng: 21.224132 },
    { lat: 48.780811, lng: 21.223624 },
    { lat: 48.780977, lng: 21.223506 },
    { lat: 48.780992, lng: 21.223537 },
    { lat: 48.781078, lng: 21.223708 },
    { lat: 48.781242, lng: 21.223554 },
    { lat: 48.781336, lng: 21.2233 },
    { lat: 48.781427, lng: 21.223821 },
    { lat: 48.781645, lng: 21.22385 },
    { lat: 48.781695, lng: 21.223857 },
    { lat: 48.781667, lng: 21.223923 },
    { lat: 48.781473, lng: 21.224398 },
    { lat: 48.781415, lng: 21.224437 },
    { lat: 48.781202, lng: 21.22458 },
    { lat: 48.78107, lng: 21.224831 },
    { lat: 48.780944, lng: 21.225071 },
    { lat: 48.780821, lng: 21.225303 },
    { lat: 48.780769, lng: 21.225456 },
    { lat: 48.780746, lng: 21.22552 },
    { lat: 48.780694, lng: 21.225757 },
    { lat: 48.780654, lng: 21.226208 },
    { lat: 48.780643, lng: 21.226333 },
    { lat: 48.780616, lng: 21.226367 },
    { lat: 48.780315, lng: 21.226758 },
    { lat: 48.78051, lng: 21.227275 },
    { lat: 48.780481, lng: 21.227321 },
    { lat: 48.78107, lng: 21.228423 },
    { lat: 48.781448, lng: 21.228479 },
    { lat: 48.781664, lng: 21.228511 },
    { lat: 48.781616, lng: 21.228556 },
    { lat: 48.781766, lng: 21.229049 },
    { lat: 48.781785, lng: 21.229111 },
    { lat: 48.782578, lng: 21.228695 },
    { lat: 48.782905, lng: 21.228419 },
    { lat: 48.783205, lng: 21.227922 },
    { lat: 48.78325, lng: 21.227848 },
    { lat: 48.783277, lng: 21.227804 },
    { lat: 48.783298, lng: 21.227768 },
    { lat: 48.78331, lng: 21.22775 },
    { lat: 48.78334, lng: 21.227699 },
    { lat: 48.78323, lng: 21.227382 },
    { lat: 48.78322, lng: 21.227353 },
    { lat: 48.783245, lng: 21.227315 },
    { lat: 48.78381, lng: 21.226456 },
    { lat: 48.783828, lng: 21.226429 },
    { lat: 48.784, lng: 21.22615 },
    { lat: 48.784228, lng: 21.225778 },
    { lat: 48.784222, lng: 21.225745 },
    { lat: 48.78418, lng: 21.22544 },
    { lat: 48.784664, lng: 21.224994 },
    { lat: 48.784788, lng: 21.224879 },
    { lat: 48.784733, lng: 21.224785 },
    { lat: 48.78467, lng: 21.224679 },
    { lat: 48.784682, lng: 21.224654 },
    { lat: 48.784804, lng: 21.224395 },
    { lat: 48.784599, lng: 21.22414 },
    { lat: 48.784597, lng: 21.224136 },
    { lat: 48.784607, lng: 21.224115 },
    { lat: 48.784894, lng: 21.223549 },
    { lat: 48.784686, lng: 21.223148 },
    { lat: 48.785082, lng: 21.222689 },
    { lat: 48.784882, lng: 21.222521 },
    { lat: 48.785162, lng: 21.221715 },
    { lat: 48.785097, lng: 21.221662 },
    { lat: 48.785202, lng: 21.221062 },
    { lat: 48.785194, lng: 21.221034 },
    { lat: 48.785159, lng: 21.220898 },
    { lat: 48.785059, lng: 21.220527 },
    { lat: 48.785254, lng: 21.220321 },
    { lat: 48.785356, lng: 21.220213 },
    { lat: 48.785579, lng: 21.219679 },
    { lat: 48.785584, lng: 21.21946 },
    { lat: 48.785817, lng: 21.219497 },
    { lat: 48.785762, lng: 21.219374 },
    { lat: 48.785979, lng: 21.219244 },
    { lat: 48.786099, lng: 21.218857 },
    { lat: 48.786124, lng: 21.218314 },
    { lat: 48.786038, lng: 21.217782 },
    { lat: 48.786326, lng: 21.217104 },
    { lat: 48.786377, lng: 21.216984 },
    { lat: 48.786379, lng: 21.216977 },
    { lat: 48.786401, lng: 21.217005 },
    { lat: 48.786507, lng: 21.217142 },
    { lat: 48.786601, lng: 21.21718 },
    { lat: 48.786679, lng: 21.21721 },
    { lat: 48.786731, lng: 21.217293 },
    { lat: 48.786795, lng: 21.217392 },
    { lat: 48.786822, lng: 21.217436 },
    { lat: 48.786834, lng: 21.217454 },
    { lat: 48.787046, lng: 21.217473 },
    { lat: 48.787195, lng: 21.217417 },
    { lat: 48.787341, lng: 21.21734 },
    { lat: 48.787482, lng: 21.217264 },
    { lat: 48.787797, lng: 21.216992 },
    { lat: 48.788191, lng: 21.217019 },
    { lat: 48.78825, lng: 21.217315 },
    { lat: 48.788361, lng: 21.217757 },
    { lat: 48.788475, lng: 21.218638 },
    { lat: 48.788633, lng: 21.218931 },
    { lat: 48.788723, lng: 21.219368 },
    { lat: 48.788933, lng: 21.219641 },
    { lat: 48.789129, lng: 21.219568 },
    { lat: 48.78928, lng: 21.219094 },
    { lat: 48.788877, lng: 21.217916 },
    { lat: 48.78883, lng: 21.217675 },
    { lat: 48.788925, lng: 21.217551 },
    { lat: 48.78906, lng: 21.217584 },
    { lat: 48.78944, lng: 21.217743 },
    { lat: 48.78971, lng: 21.218088 },
    { lat: 48.7898, lng: 21.217986 },
    { lat: 48.789897, lng: 21.217812 },
    { lat: 48.78995, lng: 21.217569 },
    { lat: 48.789839, lng: 21.217248 },
    { lat: 48.790009, lng: 21.217222 },
    { lat: 48.790259, lng: 21.21709 },
    { lat: 48.789953, lng: 21.216554 },
    { lat: 48.789726, lng: 21.216559 },
    { lat: 48.789433, lng: 21.216307 },
    { lat: 48.788991, lng: 21.216101 },
    { lat: 48.789012, lng: 21.215998 },
    { lat: 48.789474, lng: 21.215174 },
    { lat: 48.78977, lng: 21.214688 },
    { lat: 48.790195, lng: 21.214333 },
    { lat: 48.790283, lng: 21.214221 },
    { lat: 48.791156, lng: 21.212919 },
    { lat: 48.791184, lng: 21.212877 },
    { lat: 48.791316, lng: 21.212679 },
    { lat: 48.791418, lng: 21.212599 },
    { lat: 48.79148, lng: 21.21234 },
    { lat: 48.791805, lng: 21.211472 },
    { lat: 48.791732, lng: 21.211123 },
    { lat: 48.79185, lng: 21.210233 },
    { lat: 48.792025, lng: 21.209669 },
    { lat: 48.79212, lng: 21.209153 },
    { lat: 48.792667, lng: 21.207372 },
    { lat: 48.793198, lng: 21.207074 },
    { lat: 48.794029, lng: 21.207085 },
    { lat: 48.79418, lng: 21.207134 },
    { lat: 48.794197, lng: 21.207147 },
    { lat: 48.794286, lng: 21.207219 },
    { lat: 48.794587, lng: 21.207462 },
    { lat: 48.794674, lng: 21.207528 },
    { lat: 48.795007, lng: 21.207638 },
    { lat: 48.795041, lng: 21.20765 },
    { lat: 48.795608, lng: 21.206751 },
    { lat: 48.795938, lng: 21.206355 },
    { lat: 48.796154, lng: 21.206258 },
    { lat: 48.796371, lng: 21.206046 },
    { lat: 48.796866, lng: 21.205334 },
    { lat: 48.797083, lng: 21.204605 },
    { lat: 48.797311, lng: 21.204324 },
    { lat: 48.797758, lng: 21.203977 },
    { lat: 48.797878, lng: 21.203747 },
    { lat: 48.79819, lng: 21.203396 },
    { lat: 48.798475, lng: 21.202812 },
  ],
  KošicemestskáčasťDžungľa: [
    { lat: 48.7415314, lng: 21.2583157 },
    { lat: 48.7415259, lng: 21.2583196 },
    { lat: 48.7414386, lng: 21.2583746 },
    { lat: 48.7414331, lng: 21.2583786 },
    { lat: 48.7414221, lng: 21.2583854 },
    { lat: 48.7414078, lng: 21.2583943 },
    { lat: 48.7413823, lng: 21.2583362 },
    { lat: 48.7413582, lng: 21.2583586 },
    { lat: 48.741351, lng: 21.258343 },
    { lat: 48.7413728, lng: 21.2583194 },
    { lat: 48.7413692, lng: 21.2583093 },
    { lat: 48.7413119, lng: 21.2583533 },
    { lat: 48.7410294, lng: 21.2585715 },
    { lat: 48.7408868, lng: 21.2586878 },
    { lat: 48.7406878, lng: 21.2588801 },
    { lat: 48.7405159, lng: 21.2590719 },
    { lat: 48.7405041, lng: 21.2590849 },
    { lat: 48.7403256, lng: 21.2592843 },
    { lat: 48.7401275, lng: 21.2595372 },
    { lat: 48.7397362, lng: 21.2601302 },
    { lat: 48.7395908, lng: 21.2603794 },
    { lat: 48.73943, lng: 21.2607109 },
    { lat: 48.7393182, lng: 21.260989 },
    { lat: 48.7392046, lng: 21.2613211 },
    { lat: 48.7391016, lng: 21.2616653 },
    { lat: 48.7390099, lng: 21.2619431 },
    { lat: 48.7390014, lng: 21.2620321 },
    { lat: 48.7389997, lng: 21.262049 },
    { lat: 48.7389527, lng: 21.26213 },
    { lat: 48.7388843, lng: 21.2622479 },
    { lat: 48.7388852, lng: 21.2622774 },
    { lat: 48.7388964, lng: 21.2622766 },
    { lat: 48.7388967, lng: 21.2622873 },
    { lat: 48.738889, lng: 21.2622886 },
    { lat: 48.7388909, lng: 21.262318 },
    { lat: 48.7388948, lng: 21.2624044 },
    { lat: 48.7388992, lng: 21.2625125 },
    { lat: 48.7389, lng: 21.2625399 },
    { lat: 48.7389067, lng: 21.2625394 },
    { lat: 48.738907, lng: 21.2625468 },
    { lat: 48.7388935, lng: 21.2625478 },
    { lat: 48.7388942, lng: 21.2625677 },
    { lat: 48.7388806, lng: 21.2625676 },
    { lat: 48.7388693, lng: 21.2626379 },
    { lat: 48.738842, lng: 21.262811 },
    { lat: 48.7388523, lng: 21.2631076 },
    { lat: 48.7386399, lng: 21.2644688 },
    { lat: 48.7383271, lng: 21.2655648 },
    { lat: 48.7380766, lng: 21.2661307 },
    { lat: 48.737762, lng: 21.2666599 },
    { lat: 48.7372022, lng: 21.267259 },
    { lat: 48.7365485, lng: 21.2676316 },
    { lat: 48.736055, lng: 21.2677205 },
    { lat: 48.7356618, lng: 21.2677083 },
    { lat: 48.7351185, lng: 21.267677 },
    { lat: 48.7350505, lng: 21.2677021 },
    { lat: 48.7349909, lng: 21.2676988 },
    { lat: 48.7349626, lng: 21.2676976 },
    { lat: 48.7349611, lng: 21.2677594 },
    { lat: 48.7349599, lng: 21.2678261 },
    { lat: 48.7348751, lng: 21.2678143 },
    { lat: 48.7348807, lng: 21.2679618 },
    { lat: 48.7348424, lng: 21.2680686 },
    { lat: 48.7348362, lng: 21.2680876 },
    { lat: 48.7348639, lng: 21.2681088 },
    { lat: 48.734883, lng: 21.2681435 },
    { lat: 48.734957, lng: 21.2683928 },
    { lat: 48.7349872, lng: 21.2684944 },
    { lat: 48.7352201, lng: 21.2691889 },
    { lat: 48.7352374, lng: 21.269239 },
    { lat: 48.7352659, lng: 21.2693247 },
    { lat: 48.7352808, lng: 21.2693686 },
    { lat: 48.7353649, lng: 21.2696144 },
    { lat: 48.7354797, lng: 21.2699429 },
    { lat: 48.7355277, lng: 21.2700792 },
    { lat: 48.7356378, lng: 21.270474 },
    { lat: 48.7356631, lng: 21.270566 },
    { lat: 48.7357388, lng: 21.2708687 },
    { lat: 48.7358592, lng: 21.271344 },
    { lat: 48.73601, lng: 21.2719256 },
    { lat: 48.7361006, lng: 21.2722757 },
    { lat: 48.7361849, lng: 21.2726042 },
    { lat: 48.7362296, lng: 21.272775 },
    { lat: 48.7362792, lng: 21.2729633 },
    { lat: 48.7363202, lng: 21.2731234 },
    { lat: 48.7363353, lng: 21.2731041 },
    { lat: 48.736352, lng: 21.273104 },
    { lat: 48.7363768, lng: 21.2730849 },
    { lat: 48.736443, lng: 21.273034 },
    { lat: 48.736456, lng: 21.273024 },
    { lat: 48.736471, lng: 21.273051 },
    { lat: 48.73648, lng: 21.27308 },
    { lat: 48.736541, lng: 21.273209 },
    { lat: 48.736656, lng: 21.273345 },
    { lat: 48.736898, lng: 21.273259 },
    { lat: 48.737173, lng: 21.273155 },
    { lat: 48.738214, lng: 21.272757 },
    { lat: 48.738292, lng: 21.272727 },
    { lat: 48.738426, lng: 21.272676 },
    { lat: 48.738451, lng: 21.272757 },
    { lat: 48.73885, lng: 21.27247 },
    { lat: 48.739684, lng: 21.27193 },
    { lat: 48.739744, lng: 21.271892 },
    { lat: 48.740052, lng: 21.271709 },
    { lat: 48.740369, lng: 21.271258 },
    { lat: 48.74045, lng: 21.271046 },
    { lat: 48.740584, lng: 21.270674 },
    { lat: 48.740611, lng: 21.270327 },
    { lat: 48.740626, lng: 21.270128 },
    { lat: 48.740687, lng: 21.269901 },
    { lat: 48.740727, lng: 21.269746 },
    { lat: 48.740729, lng: 21.269696 },
    { lat: 48.74073, lng: 21.269558 },
    { lat: 48.740789, lng: 21.269464 },
    { lat: 48.740837, lng: 21.269387 },
    { lat: 48.740877, lng: 21.269322 },
    { lat: 48.740904, lng: 21.269278 },
    { lat: 48.740916, lng: 21.269246 },
    { lat: 48.740931, lng: 21.269211 },
    { lat: 48.740938, lng: 21.26919 },
    { lat: 48.74116, lng: 21.26863 },
    { lat: 48.741187, lng: 21.268547 },
    { lat: 48.741233, lng: 21.268415 },
    { lat: 48.741264, lng: 21.268366 },
    { lat: 48.741342, lng: 21.268244 },
    { lat: 48.741433, lng: 21.268102 },
    { lat: 48.741529, lng: 21.267951 },
    { lat: 48.741743, lng: 21.267616 },
    { lat: 48.741885, lng: 21.267362 },
    { lat: 48.741912, lng: 21.267327 },
    { lat: 48.741972, lng: 21.267208 },
    { lat: 48.741978, lng: 21.267186 },
    { lat: 48.742008, lng: 21.267085 },
    { lat: 48.742045, lng: 21.267142 },
    { lat: 48.742065, lng: 21.267158 },
    { lat: 48.742067, lng: 21.267151 },
    { lat: 48.74208, lng: 21.267164 },
    { lat: 48.742259, lng: 21.267365 },
    { lat: 48.742306, lng: 21.267263 },
    { lat: 48.742329, lng: 21.267086 },
    { lat: 48.742354, lng: 21.26692 },
    { lat: 48.74237, lng: 21.266828 },
    { lat: 48.742413, lng: 21.266403 },
    { lat: 48.742438, lng: 21.266139 },
    { lat: 48.742447, lng: 21.266071 },
    { lat: 48.74249, lng: 21.266013 },
    { lat: 48.742529, lng: 21.265995 },
    { lat: 48.74257, lng: 21.265996 },
    { lat: 48.74258, lng: 21.265996 },
    { lat: 48.742756, lng: 21.265974 },
    { lat: 48.7427314, lng: 21.2658457 },
    { lat: 48.7427261, lng: 21.265819 },
    { lat: 48.7426978, lng: 21.2656705 },
    { lat: 48.742657, lng: 21.2654013 },
    { lat: 48.7426147, lng: 21.2651259 },
    { lat: 48.7425552, lng: 21.2646198 },
    { lat: 48.7425368, lng: 21.2644596 },
    { lat: 48.7425275, lng: 21.2643793 },
    { lat: 48.7424794, lng: 21.2638068 },
    { lat: 48.7424784, lng: 21.2637734 },
    { lat: 48.7424649, lng: 21.2634413 },
    { lat: 48.7424644, lng: 21.2634257 },
    { lat: 48.7424496, lng: 21.2633853 },
    { lat: 48.7424699, lng: 21.2633846 },
    { lat: 48.7424599, lng: 21.2632413 },
    { lat: 48.7424586, lng: 21.2631819 },
    { lat: 48.7424523, lng: 21.2631427 },
    { lat: 48.7424502, lng: 21.263109 },
    { lat: 48.7424417, lng: 21.2629787 },
    { lat: 48.7424326, lng: 21.2628695 },
    { lat: 48.7424006, lng: 21.2624154 },
    { lat: 48.7423985, lng: 21.262382 },
    { lat: 48.7423892, lng: 21.2622645 },
    { lat: 48.7423856, lng: 21.2622189 },
    { lat: 48.74238, lng: 21.2621494 },
    { lat: 48.7423761, lng: 21.2620962 },
    { lat: 48.7423726, lng: 21.2620544 },
    { lat: 48.7423593, lng: 21.261881 },
    { lat: 48.7423474, lng: 21.2617099 },
    { lat: 48.7423453, lng: 21.2616826 },
    { lat: 48.7423367, lng: 21.2615813 },
    { lat: 48.7423332, lng: 21.2615423 },
    { lat: 48.742331, lng: 21.2615086 },
    { lat: 48.742324, lng: 21.2614253 },
    { lat: 48.7423054, lng: 21.2611898 },
    { lat: 48.7422842, lng: 21.2611942 },
    { lat: 48.7422808, lng: 21.261158 },
    { lat: 48.7422786, lng: 21.261124 },
    { lat: 48.7422649, lng: 21.2609709 },
    { lat: 48.7422559, lng: 21.2608617 },
    { lat: 48.7422487, lng: 21.2607759 },
    { lat: 48.7421829, lng: 21.2603541 },
    { lat: 48.7420573, lng: 21.2597141 },
    { lat: 48.7419335, lng: 21.2593869 },
    { lat: 48.7418619, lng: 21.2591777 },
    { lat: 48.7418183, lng: 21.2589365 },
    { lat: 48.7415314, lng: 21.2583157 },
  ],
  KošicemestskáčasťSever: [
    { lat: 48.7281773, lng: 21.240123 },
    { lat: 48.7281847, lng: 21.2400394 },
    { lat: 48.7282166, lng: 21.240014 },
    { lat: 48.7283405, lng: 21.2403784 },
    { lat: 48.7283577, lng: 21.2404289 },
    { lat: 48.7284778, lng: 21.2407786 },
    { lat: 48.7284902, lng: 21.2408137 },
    { lat: 48.7284987, lng: 21.2408388 },
    { lat: 48.7285101, lng: 21.2409856 },
    { lat: 48.7285395, lng: 21.2411011 },
    { lat: 48.728597, lng: 21.2412845 },
    { lat: 48.7286019, lng: 21.2412992 },
    { lat: 48.7286153, lng: 21.2413307 },
    { lat: 48.7286251, lng: 21.241356 },
    { lat: 48.7286736, lng: 21.2413205 },
    { lat: 48.7287466, lng: 21.2415335 },
    { lat: 48.7289716, lng: 21.2421845 },
    { lat: 48.7289779, lng: 21.2422061 },
    { lat: 48.7290029, lng: 21.2422888 },
    { lat: 48.7290116, lng: 21.2423146 },
    { lat: 48.7291854, lng: 21.2428398 },
    { lat: 48.7291966, lng: 21.2428733 },
    { lat: 48.7292029, lng: 21.2428935 },
    { lat: 48.7293581, lng: 21.2433637 },
    { lat: 48.7293718, lng: 21.2434052 },
    { lat: 48.729415, lng: 21.2435308 },
    { lat: 48.7294188, lng: 21.243543 },
    { lat: 48.729446, lng: 21.2436199 },
    { lat: 48.7294497, lng: 21.2436317 },
    { lat: 48.7295004, lng: 21.2437778 },
    { lat: 48.7296338, lng: 21.2441644 },
    { lat: 48.7296499, lng: 21.2442114 },
    { lat: 48.7296686, lng: 21.2442653 },
    { lat: 48.729813, lng: 21.24468 },
    { lat: 48.7298945, lng: 21.244912 },
    { lat: 48.7299046, lng: 21.2449455 },
    { lat: 48.7299232, lng: 21.245002 },
    { lat: 48.7299282, lng: 21.2450166 },
    { lat: 48.7299644, lng: 21.2451309 },
    { lat: 48.7299743, lng: 21.2451627 },
    { lat: 48.7301427, lng: 21.2456502 },
    { lat: 48.7301943, lng: 21.2457984 },
    { lat: 48.7302216, lng: 21.2458788 },
    { lat: 48.7302338, lng: 21.2459165 },
    { lat: 48.7302466, lng: 21.2459579 },
    { lat: 48.730531, lng: 21.2467916 },
    { lat: 48.7305484, lng: 21.2468426 },
    { lat: 48.7306884, lng: 21.2472531 },
    { lat: 48.7307342, lng: 21.2473872 },
    { lat: 48.7307716, lng: 21.2474992 },
    { lat: 48.7307888, lng: 21.2475501 },
    { lat: 48.7309434, lng: 21.2480019 },
    { lat: 48.7309508, lng: 21.2480213 },
    { lat: 48.7309619, lng: 21.2480533 },
    { lat: 48.7309635, lng: 21.2481074 },
    { lat: 48.7310093, lng: 21.2482418 },
    { lat: 48.7309665, lng: 21.2482762 },
    { lat: 48.7309781, lng: 21.2483224 },
    { lat: 48.7310449, lng: 21.2485152 },
    { lat: 48.7310561, lng: 21.2485479 },
    { lat: 48.7312164, lng: 21.2490057 },
    { lat: 48.7312625, lng: 21.2491493 },
    { lat: 48.7312836, lng: 21.2492112 },
    { lat: 48.731316, lng: 21.2493123 },
    { lat: 48.731337, lng: 21.2493743 },
    { lat: 48.7313545, lng: 21.2494294 },
    { lat: 48.7313831, lng: 21.2495165 },
    { lat: 48.7313894, lng: 21.2495374 },
    { lat: 48.7314279, lng: 21.2496534 },
    { lat: 48.7314366, lng: 21.249681 },
    { lat: 48.7314714, lng: 21.2497883 },
    { lat: 48.7314808, lng: 21.2498012 },
    { lat: 48.7314922, lng: 21.2498046 },
    { lat: 48.7315056, lng: 21.249804 },
    { lat: 48.7314639, lng: 21.2498401 },
    { lat: 48.7314948, lng: 21.2499264 },
    { lat: 48.7316529, lng: 21.2503801 },
    { lat: 48.7315629, lng: 21.2504157 },
    { lat: 48.7315439, lng: 21.2504234 },
    { lat: 48.7313995, lng: 21.2505548 },
    { lat: 48.7312385, lng: 21.2507017 },
    { lat: 48.7310481, lng: 21.2508748 },
    { lat: 48.7310293, lng: 21.2508833 },
    { lat: 48.7310203, lng: 21.2508871 },
    { lat: 48.7309404, lng: 21.2509224 },
    { lat: 48.7309305, lng: 21.2509295 },
    { lat: 48.7309151, lng: 21.2509427 },
    { lat: 48.7309528, lng: 21.2510386 },
    { lat: 48.7310837, lng: 21.2513675 },
    { lat: 48.731091, lng: 21.2513862 },
    { lat: 48.7311483, lng: 21.2515287 },
    { lat: 48.7311556, lng: 21.2515481 },
    { lat: 48.7312118, lng: 21.2516896 },
    { lat: 48.7312203, lng: 21.25171 },
    { lat: 48.7312545, lng: 21.2517964 },
    { lat: 48.7313802, lng: 21.2521147 },
    { lat: 48.7313899, lng: 21.252139 },
    { lat: 48.731418, lng: 21.2522094 },
    { lat: 48.7314278, lng: 21.2522347 },
    { lat: 48.7314364, lng: 21.2522566 },
    { lat: 48.7315424, lng: 21.2525218 },
    { lat: 48.7316792, lng: 21.2528693 },
    { lat: 48.7316841, lng: 21.2528811 },
    { lat: 48.7317243, lng: 21.2529816 },
    { lat: 48.7318318, lng: 21.2532542 },
    { lat: 48.7318609, lng: 21.2533217 },
    { lat: 48.7319481, lng: 21.2535219 },
    { lat: 48.731951, lng: 21.2535396 },
    { lat: 48.7319731, lng: 21.2536 },
    { lat: 48.7320099, lng: 21.2537001 },
    { lat: 48.7320333, lng: 21.253764 },
    { lat: 48.7320467, lng: 21.2537994 },
    { lat: 48.7321116, lng: 21.254007 },
    { lat: 48.7321721, lng: 21.2542148 },
    { lat: 48.732213, lng: 21.2544112 },
    { lat: 48.7322419, lng: 21.2545767 },
    { lat: 48.7322542, lng: 21.2546475 },
    { lat: 48.732264, lng: 21.2547088 },
    { lat: 48.7322653, lng: 21.2547159 },
    { lat: 48.7322891, lng: 21.2549751 },
    { lat: 48.732302, lng: 21.255032 },
    { lat: 48.7323032, lng: 21.2554333 },
    { lat: 48.7323204, lng: 21.2556602 },
    { lat: 48.7323439, lng: 21.2558037 },
    { lat: 48.7323502, lng: 21.255825 },
    { lat: 48.7324196, lng: 21.2573466 },
    { lat: 48.7324336, lng: 21.2576504 },
    { lat: 48.7325425, lng: 21.2599745 },
    { lat: 48.7325552, lng: 21.2602417 },
    { lat: 48.7325898, lng: 21.2608888 },
    { lat: 48.7325962, lng: 21.2610238 },
    { lat: 48.732589, lng: 21.2610813 },
    { lat: 48.7325871, lng: 21.2610957 },
    { lat: 48.7325753, lng: 21.2611875 },
    { lat: 48.7325671, lng: 21.2612504 },
    { lat: 48.7326025, lng: 21.261265 },
    { lat: 48.7329245, lng: 21.2616331 },
    { lat: 48.7329338, lng: 21.2616438 },
    { lat: 48.7330422, lng: 21.2617666 },
    { lat: 48.7332101, lng: 21.2619544 },
    { lat: 48.7334572, lng: 21.2622322 },
    { lat: 48.7335272, lng: 21.2623093 },
    { lat: 48.733617, lng: 21.262412 },
    { lat: 48.7336357, lng: 21.2624342 },
    { lat: 48.733666, lng: 21.2624678 },
    { lat: 48.7336774, lng: 21.2625097 },
    { lat: 48.7337346, lng: 21.2625784 },
    { lat: 48.7337688, lng: 21.2625888 },
    { lat: 48.7339188, lng: 21.2627473 },
    { lat: 48.7339405, lng: 21.2627586 },
    { lat: 48.7339444, lng: 21.2627733 },
    { lat: 48.7339507, lng: 21.2627942 },
    { lat: 48.733966, lng: 21.2628559 },
    { lat: 48.7340706, lng: 21.2629975 },
    { lat: 48.7341082, lng: 21.2630487 },
    { lat: 48.7341446, lng: 21.2630985 },
    { lat: 48.7341552, lng: 21.2631128 },
    { lat: 48.7343689, lng: 21.2634022 },
    { lat: 48.7344246, lng: 21.2635665 },
    { lat: 48.7344351, lng: 21.2636146 },
    { lat: 48.7344547, lng: 21.2637034 },
    { lat: 48.7344898, lng: 21.2638599 },
    { lat: 48.7344709, lng: 21.2640107 },
    { lat: 48.7345031, lng: 21.2641799 },
    { lat: 48.7344672, lng: 21.2642544 },
    { lat: 48.7344986, lng: 21.2643976 },
    { lat: 48.7345538, lng: 21.2644344 },
    { lat: 48.7346387, lng: 21.2648908 },
    { lat: 48.7346072, lng: 21.2649633 },
    { lat: 48.7346811, lng: 21.2653516 },
    { lat: 48.7347318, lng: 21.2653891 },
    { lat: 48.7349003, lng: 21.2662603 },
    { lat: 48.7349041, lng: 21.2663103 },
    { lat: 48.7349099, lng: 21.2663879 },
    { lat: 48.7349134, lng: 21.2664283 },
    { lat: 48.7349641, lng: 21.267013 },
    { lat: 48.7349799, lng: 21.2670154 },
    { lat: 48.7350047, lng: 21.2670187 },
    { lat: 48.7350051, lng: 21.2670322 },
    { lat: 48.7350017, lng: 21.2672114 },
    { lat: 48.734996, lng: 21.2674649 },
    { lat: 48.7349918, lng: 21.2676941 },
    { lat: 48.7349909, lng: 21.2676988 },
    { lat: 48.7350505, lng: 21.2677021 },
    { lat: 48.7351185, lng: 21.267677 },
    { lat: 48.7356618, lng: 21.2677083 },
    { lat: 48.736055, lng: 21.2677205 },
    { lat: 48.7365485, lng: 21.2676316 },
    { lat: 48.7372022, lng: 21.267259 },
    { lat: 48.737762, lng: 21.2666599 },
    { lat: 48.7380766, lng: 21.2661307 },
    { lat: 48.7383271, lng: 21.2655648 },
    { lat: 48.7386399, lng: 21.2644688 },
    { lat: 48.7388523, lng: 21.2631076 },
    { lat: 48.738842, lng: 21.262811 },
    { lat: 48.7388693, lng: 21.2626379 },
    { lat: 48.7388806, lng: 21.2625676 },
    { lat: 48.7388942, lng: 21.2625677 },
    { lat: 48.7388935, lng: 21.2625478 },
    { lat: 48.738907, lng: 21.2625468 },
    { lat: 48.7389067, lng: 21.2625394 },
    { lat: 48.7389, lng: 21.2625399 },
    { lat: 48.7388992, lng: 21.2625125 },
    { lat: 48.7388948, lng: 21.2624044 },
    { lat: 48.7388909, lng: 21.262318 },
    { lat: 48.738889, lng: 21.2622886 },
    { lat: 48.7388967, lng: 21.2622873 },
    { lat: 48.7388964, lng: 21.2622766 },
    { lat: 48.7388852, lng: 21.2622774 },
    { lat: 48.7388843, lng: 21.2622479 },
    { lat: 48.7389527, lng: 21.26213 },
    { lat: 48.7389997, lng: 21.262049 },
    { lat: 48.7390014, lng: 21.2620321 },
    { lat: 48.7390099, lng: 21.2619431 },
    { lat: 48.7391016, lng: 21.2616653 },
    { lat: 48.7392046, lng: 21.2613211 },
    { lat: 48.7393182, lng: 21.260989 },
    { lat: 48.73943, lng: 21.2607109 },
    { lat: 48.7395908, lng: 21.2603794 },
    { lat: 48.7397362, lng: 21.2601302 },
    { lat: 48.7401275, lng: 21.2595372 },
    { lat: 48.7403256, lng: 21.2592843 },
    { lat: 48.7405041, lng: 21.2590849 },
    { lat: 48.7405159, lng: 21.2590719 },
    { lat: 48.7406878, lng: 21.2588801 },
    { lat: 48.7408868, lng: 21.2586878 },
    { lat: 48.7410294, lng: 21.2585715 },
    { lat: 48.7413119, lng: 21.2583533 },
    { lat: 48.7413692, lng: 21.2583093 },
    { lat: 48.7413728, lng: 21.2583194 },
    { lat: 48.741351, lng: 21.258343 },
    { lat: 48.7413582, lng: 21.2583586 },
    { lat: 48.7413823, lng: 21.2583362 },
    { lat: 48.7414078, lng: 21.2583943 },
    { lat: 48.7414221, lng: 21.2583854 },
    { lat: 48.7414331, lng: 21.2583786 },
    { lat: 48.7414386, lng: 21.2583746 },
    { lat: 48.7415259, lng: 21.2583196 },
    { lat: 48.7415314, lng: 21.2583157 },
    { lat: 48.7415413, lng: 21.2583078 },
    { lat: 48.7415565, lng: 21.2582939 },
    { lat: 48.7415322, lng: 21.258235 },
    { lat: 48.7415541, lng: 21.2582139 },
    { lat: 48.7421909, lng: 21.2578812 },
    { lat: 48.7423222, lng: 21.2578669 },
    { lat: 48.7423767, lng: 21.2578478 },
    { lat: 48.7429054, lng: 21.2576589 },
    { lat: 48.7430988, lng: 21.257616 },
    { lat: 48.743356, lng: 21.2575287 },
    { lat: 48.7435899, lng: 21.2574487 },
    { lat: 48.7437332, lng: 21.2574229 },
    { lat: 48.7442029, lng: 21.2573266 },
    { lat: 48.7442733, lng: 21.257312 },
    { lat: 48.74425, lng: 21.2572481 },
    { lat: 48.7442401, lng: 21.257222 },
    { lat: 48.7442366, lng: 21.257142 },
    { lat: 48.744234, lng: 21.2570969 },
    { lat: 48.7442324, lng: 21.2570792 },
    { lat: 48.7442184, lng: 21.2568081 },
    { lat: 48.7443205, lng: 21.2567988 },
    { lat: 48.7479807, lng: 21.2556492 },
    { lat: 48.7480008, lng: 21.2556428 },
    { lat: 48.7490171, lng: 21.255321 },
    { lat: 48.750409, lng: 21.2548746 },
    { lat: 48.7522177, lng: 21.2543028 },
    { lat: 48.7539151, lng: 21.2537652 },
    { lat: 48.7540807, lng: 21.2537 },
    { lat: 48.7544248, lng: 21.2535438 },
    { lat: 48.7546048, lng: 21.2534333 },
    { lat: 48.7548815, lng: 21.2532486 },
    { lat: 48.7549519, lng: 21.2531955 },
    { lat: 48.755351, lng: 21.2528534 },
    { lat: 48.756421, lng: 21.2518997 },
    { lat: 48.7564735, lng: 21.2518517 },
    { lat: 48.7572466, lng: 21.2511572 },
    { lat: 48.7577502, lng: 21.2507014 },
    { lat: 48.7603996, lng: 21.2483828 },
    { lat: 48.7604869, lng: 21.2485496 },
    { lat: 48.7605011, lng: 21.2485742 },
    { lat: 48.7623857, lng: 21.2468856 },
    { lat: 48.7628003, lng: 21.2464646 },
    { lat: 48.7631803, lng: 21.2460167 },
    { lat: 48.7636287, lng: 21.2452712 },
    { lat: 48.7638707, lng: 21.2448727 },
    { lat: 48.764601, lng: 21.2436702 },
    { lat: 48.7651086, lng: 21.2429083 },
    { lat: 48.7655656, lng: 21.2423315 },
    { lat: 48.7659078, lng: 21.2418988 },
    { lat: 48.7661735, lng: 21.2412856 },
    { lat: 48.7665047, lng: 21.2407131 },
    { lat: 48.7667338, lng: 21.2402984 },
    { lat: 48.7671019, lng: 21.2397607 },
    { lat: 48.7673693, lng: 21.2394206 },
    { lat: 48.7675826, lng: 21.2392211 },
    { lat: 48.7682344, lng: 21.2389029 },
    { lat: 48.7686165, lng: 21.2391013 },
    { lat: 48.7689378, lng: 21.239447 },
    { lat: 48.7692338, lng: 21.2399183 },
    { lat: 48.7694627, lng: 21.2403723 },
    { lat: 48.7696049, lng: 21.2408209 },
    { lat: 48.7696904, lng: 21.2413614 },
    { lat: 48.7696668, lng: 21.2415807 },
    { lat: 48.769688, lng: 21.2419749 },
    { lat: 48.7697091, lng: 21.2423626 },
    { lat: 48.769778, lng: 21.2427373 },
    { lat: 48.7698863, lng: 21.2430692 },
    { lat: 48.770002, lng: 21.2433532 },
    { lat: 48.7701002, lng: 21.2435799 },
    { lat: 48.7702652, lng: 21.2438201 },
    { lat: 48.770452, lng: 21.2440409 },
    { lat: 48.7707073, lng: 21.2442518 },
    { lat: 48.770782, lng: 21.2443022 },
    { lat: 48.7710898, lng: 21.2443953 },
    { lat: 48.7712901, lng: 21.2444317 },
    { lat: 48.771283, lng: 21.244303 },
    { lat: 48.771275, lng: 21.244144 },
    { lat: 48.771267, lng: 21.244 },
    { lat: 48.771261, lng: 21.243871 },
    { lat: 48.771269, lng: 21.24379 },
    { lat: 48.771226, lng: 21.243789 },
    { lat: 48.77125, lng: 21.243552 },
    { lat: 48.771266, lng: 21.243391 },
    { lat: 48.771271, lng: 21.243336 },
    { lat: 48.771284, lng: 21.243211 },
    { lat: 48.771305, lng: 21.242996 },
    { lat: 48.771312, lng: 21.242928 },
    { lat: 48.77132, lng: 21.242843 },
    { lat: 48.77133, lng: 21.242743 },
    { lat: 48.771349, lng: 21.242541 },
    { lat: 48.771479, lng: 21.242569 },
    { lat: 48.771484, lng: 21.242588 },
    { lat: 48.771493, lng: 21.242566 },
    { lat: 48.771567, lng: 21.242529 },
    { lat: 48.771679, lng: 21.242473 },
    { lat: 48.771716, lng: 21.242359 },
    { lat: 48.771746, lng: 21.242262 },
    { lat: 48.771747, lng: 21.242241 },
    { lat: 48.771749, lng: 21.242202 },
    { lat: 48.77175, lng: 21.242177 },
    { lat: 48.771752, lng: 21.24215 },
    { lat: 48.771734, lng: 21.24209 },
    { lat: 48.771933, lng: 21.241735 },
    { lat: 48.772063, lng: 21.241507 },
    { lat: 48.772168, lng: 21.241366 },
    { lat: 48.77231, lng: 21.241169 },
    { lat: 48.772427, lng: 21.241007 },
    { lat: 48.772513, lng: 21.240769 },
    { lat: 48.772529, lng: 21.240446 },
    { lat: 48.772505, lng: 21.24032 },
    { lat: 48.772539, lng: 21.240246 },
    { lat: 48.772498, lng: 21.24007 },
    { lat: 48.772478, lng: 21.240053 },
    { lat: 48.772382, lng: 21.240104 },
    { lat: 48.77225, lng: 21.239769 },
    { lat: 48.772199, lng: 21.239466 },
    { lat: 48.772197, lng: 21.239452 },
    { lat: 48.772202, lng: 21.239429 },
    { lat: 48.772214, lng: 21.239413 },
    { lat: 48.772318, lng: 21.239022 },
    { lat: 48.772532, lng: 21.238588 },
    { lat: 48.772813, lng: 21.238462 },
    { lat: 48.772992, lng: 21.238296 },
    { lat: 48.773027, lng: 21.238378 },
    { lat: 48.773046, lng: 21.238366 },
    { lat: 48.773255, lng: 21.238221 },
    { lat: 48.773294, lng: 21.238195 },
    { lat: 48.773266, lng: 21.238159 },
    { lat: 48.773233, lng: 21.23806 },
    { lat: 48.773265, lng: 21.238022 },
    { lat: 48.773389, lng: 21.237905 },
    { lat: 48.773429, lng: 21.237902 },
    { lat: 48.773475, lng: 21.237863 },
    { lat: 48.773635, lng: 21.23787 },
    { lat: 48.773698, lng: 21.237951 },
    { lat: 48.773768, lng: 21.237874 },
    { lat: 48.773857, lng: 21.237815 },
    { lat: 48.773941, lng: 21.237771 },
    { lat: 48.773981, lng: 21.237762 },
    { lat: 48.774117, lng: 21.237697 },
    { lat: 48.774139, lng: 21.237701 },
    { lat: 48.774328, lng: 21.237517 },
    { lat: 48.774362, lng: 21.237523 },
    { lat: 48.774454, lng: 21.2374 },
    { lat: 48.774501, lng: 21.237336 },
    { lat: 48.774547, lng: 21.237275 },
    { lat: 48.774596, lng: 21.237209 },
    { lat: 48.774709, lng: 21.237054 },
    { lat: 48.774795, lng: 21.237073 },
    { lat: 48.774831, lng: 21.237119 },
    { lat: 48.774829, lng: 21.237066 },
    { lat: 48.774824, lng: 21.236941 },
    { lat: 48.774959, lng: 21.236847 },
    { lat: 48.775007, lng: 21.236871 },
    { lat: 48.775055, lng: 21.236396 },
    { lat: 48.775171, lng: 21.236386 },
    { lat: 48.775144, lng: 21.236685 },
    { lat: 48.775148, lng: 21.236709 },
    { lat: 48.775581, lng: 21.236434 },
    { lat: 48.775819, lng: 21.236037 },
    { lat: 48.775709, lng: 21.235715 },
    { lat: 48.77585, lng: 21.235242 },
    { lat: 48.775584, lng: 21.234723 },
    { lat: 48.775884, lng: 21.234336 },
    { lat: 48.775882, lng: 21.233983 },
    { lat: 48.775712, lng: 21.233508 },
    { lat: 48.775608, lng: 21.232998 },
    { lat: 48.775547, lng: 21.232267 },
    { lat: 48.775581, lng: 21.231708 },
    { lat: 48.775694, lng: 21.231267 },
    { lat: 48.77584, lng: 21.230812 },
    { lat: 48.775845, lng: 21.230625 },
    { lat: 48.775658, lng: 21.229889 },
    { lat: 48.7755, lng: 21.229417 },
    { lat: 48.775045, lng: 21.228668 },
    { lat: 48.774864, lng: 21.22853 },
    { lat: 48.774874, lng: 21.228423 },
    { lat: 48.774799, lng: 21.228413 },
    { lat: 48.774736, lng: 21.22837 },
    { lat: 48.774759, lng: 21.228165 },
    { lat: 48.774689, lng: 21.22811 },
    { lat: 48.774508, lng: 21.227886 },
    { lat: 48.774531, lng: 21.22774 },
    { lat: 48.774523, lng: 21.227608 },
    { lat: 48.774263, lng: 21.227514 },
    { lat: 48.774373, lng: 21.22687 },
    { lat: 48.7740645, lng: 21.2268602 },
    { lat: 48.773828, lng: 21.2268535 },
    { lat: 48.7736083, lng: 21.226847 },
    { lat: 48.7734486, lng: 21.2268509 },
    { lat: 48.7733293, lng: 21.2269957 },
    { lat: 48.7729963, lng: 21.2270704 },
    { lat: 48.7729231, lng: 21.227284 },
    { lat: 48.7727154, lng: 21.2273009 },
    { lat: 48.7725752, lng: 21.2273901 },
    { lat: 48.7724028, lng: 21.227492 },
    { lat: 48.7724471, lng: 21.2273236 },
    { lat: 48.7723138, lng: 21.2272346 },
    { lat: 48.7721545, lng: 21.2277576 },
    { lat: 48.7720088, lng: 21.2278122 },
    { lat: 48.7719823, lng: 21.2278641 },
    { lat: 48.7718474, lng: 21.2280178 },
    { lat: 48.7717928, lng: 21.2281037 },
    { lat: 48.7718115, lng: 21.2281624 },
    { lat: 48.7717108, lng: 21.2282184 },
    { lat: 48.7714293, lng: 21.2282124 },
    { lat: 48.771288, lng: 21.2281226 },
    { lat: 48.7711588, lng: 21.2279812 },
    { lat: 48.770876, lng: 21.2276489 },
    { lat: 48.7706243, lng: 21.2272244 },
    { lat: 48.7704233, lng: 21.2269865 },
    { lat: 48.770221, lng: 21.2268514 },
    { lat: 48.7700372, lng: 21.226766 },
    { lat: 48.7697787, lng: 21.2265968 },
    { lat: 48.7696309, lng: 21.2263687 },
    { lat: 48.7695151, lng: 21.2261168 },
    { lat: 48.7694191, lng: 21.2258525 },
    { lat: 48.7693688, lng: 21.2256503 },
    { lat: 48.7693499, lng: 21.2254743 },
    { lat: 48.7693829, lng: 21.2253057 },
    { lat: 48.7695039, lng: 21.2248915 },
    { lat: 48.7695044, lng: 21.2247626 },
    { lat: 48.769487, lng: 21.2246361 },
    { lat: 48.7694331, lng: 21.2246054 },
    { lat: 48.7692137, lng: 21.2247505 },
    { lat: 48.7691128, lng: 21.2247285 },
    { lat: 48.7688746, lng: 21.2248489 },
    { lat: 48.7686193, lng: 21.2249998 },
    { lat: 48.7682596, lng: 21.2250861 },
    { lat: 48.7681592, lng: 21.2250751 },
    { lat: 48.7681149, lng: 21.2250614 },
    { lat: 48.768066, lng: 21.2250471 },
    { lat: 48.7679424, lng: 21.2250167 },
    { lat: 48.7678583, lng: 21.2250269 },
    { lat: 48.7665194, lng: 21.2248901 },
    { lat: 48.7663581, lng: 21.2248445 },
    { lat: 48.7661863, lng: 21.2248172 },
    { lat: 48.765993, lng: 21.2248656 },
    { lat: 48.7652204, lng: 21.2247369 },
    { lat: 48.7647859, lng: 21.2248093 },
    { lat: 48.7642719, lng: 21.2250681 },
    { lat: 48.7642491, lng: 21.2250209 },
    { lat: 48.7640613, lng: 21.2247767 },
    { lat: 48.7640479, lng: 21.2247434 },
    { lat: 48.7640431, lng: 21.2247341 },
    { lat: 48.7640187, lng: 21.2246742 },
    { lat: 48.7640079, lng: 21.2246492 },
    { lat: 48.7639933, lng: 21.224616 },
    { lat: 48.7639785, lng: 21.2246471 },
    { lat: 48.7639066, lng: 21.2247967 },
    { lat: 48.7638592, lng: 21.2248967 },
    { lat: 48.7638077, lng: 21.2249796 },
    { lat: 48.7637039, lng: 21.2251899 },
    { lat: 48.7636943, lng: 21.2252074 },
    { lat: 48.7635814, lng: 21.2254487 },
    { lat: 48.7635282, lng: 21.2255442 },
    { lat: 48.7634784, lng: 21.2256444 },
    { lat: 48.7634611, lng: 21.2256649 },
    { lat: 48.7633514, lng: 21.2257919 },
    { lat: 48.7632042, lng: 21.2259465 },
    { lat: 48.7631234, lng: 21.2260257 },
    { lat: 48.7630645, lng: 21.2260834 },
    { lat: 48.763047, lng: 21.2261007 },
    { lat: 48.7630153, lng: 21.2261319 },
    { lat: 48.7629401, lng: 21.2262103 },
    { lat: 48.7629103, lng: 21.2262285 },
    { lat: 48.7628699, lng: 21.2262685 },
    { lat: 48.7628502, lng: 21.2262877 },
    { lat: 48.762788, lng: 21.2263499 },
    { lat: 48.7627126, lng: 21.2264238 },
    { lat: 48.7626499, lng: 21.2264704 },
    { lat: 48.7626309, lng: 21.2264717 },
    { lat: 48.7626005, lng: 21.2264732 },
    { lat: 48.7624871, lng: 21.2264053 },
    { lat: 48.762471, lng: 21.2263957 },
    { lat: 48.7624571, lng: 21.2263874 },
    { lat: 48.7622568, lng: 21.2262783 },
    { lat: 48.7621926, lng: 21.2262383 },
    { lat: 48.7621185, lng: 21.2261715 },
    { lat: 48.7620089, lng: 21.2260858 },
    { lat: 48.7617294, lng: 21.225891 },
    { lat: 48.7615704, lng: 21.2256997 },
    { lat: 48.7614586, lng: 21.2255443 },
    { lat: 48.7613637, lng: 21.2253849 },
    { lat: 48.7613246, lng: 21.2252906 },
    { lat: 48.7612993, lng: 21.2250552 },
    { lat: 48.7613035, lng: 21.2249718 },
    { lat: 48.7612389, lng: 21.2246704 },
    { lat: 48.7611712, lng: 21.2243727 },
    { lat: 48.7611005, lng: 21.2241281 },
    { lat: 48.7610795, lng: 21.2239962 },
    { lat: 48.761026, lng: 21.2236525 },
    { lat: 48.7610481, lng: 21.223601 },
    { lat: 48.761218, lng: 21.2235283 },
    { lat: 48.7614357, lng: 21.2234371 },
    { lat: 48.7615034, lng: 21.2234049 },
    { lat: 48.7615288, lng: 21.2233927 },
    { lat: 48.7615728, lng: 21.2233578 },
    { lat: 48.7616167, lng: 21.2233229 },
    { lat: 48.7616561, lng: 21.2232845 },
    { lat: 48.7616855, lng: 21.2232553 },
    { lat: 48.7617729, lng: 21.2231705 },
    { lat: 48.7617884, lng: 21.2231591 },
    { lat: 48.7618004, lng: 21.2231507 },
    { lat: 48.7618838, lng: 21.2230813 },
    { lat: 48.7619242, lng: 21.2229693 },
    { lat: 48.7619717, lng: 21.2227604 },
    { lat: 48.7620431, lng: 21.2225263 },
    { lat: 48.7620308, lng: 21.2224898 },
    { lat: 48.7622015, lng: 21.2222282 },
    { lat: 48.7622231, lng: 21.2221967 },
    { lat: 48.7622633, lng: 21.2220055 },
    { lat: 48.7622954, lng: 21.2218424 },
    { lat: 48.7623283, lng: 21.2216713 },
    { lat: 48.7623629, lng: 21.221483 },
    { lat: 48.7623869, lng: 21.2213504 },
    { lat: 48.762417, lng: 21.2211606 },
    { lat: 48.7624357, lng: 21.2210365 },
    { lat: 48.7624707, lng: 21.220825 },
    { lat: 48.7625072, lng: 21.2205488 },
    { lat: 48.7625152, lng: 21.2204862 },
    { lat: 48.7623862, lng: 21.2202089 },
    { lat: 48.7624791, lng: 21.2201228 },
    { lat: 48.762527, lng: 21.2200327 },
    { lat: 48.7625705, lng: 21.2199497 },
    { lat: 48.7625897, lng: 21.219914 },
    { lat: 48.7626738, lng: 21.219796 },
    { lat: 48.7626965, lng: 21.2197645 },
    { lat: 48.7627217, lng: 21.2197459 },
    { lat: 48.7628385, lng: 21.2196634 },
    { lat: 48.7628651, lng: 21.2196511 },
    { lat: 48.7630005, lng: 21.2195887 },
    { lat: 48.7631062, lng: 21.2195875 },
    { lat: 48.7630294, lng: 21.2194732 },
    { lat: 48.7629007, lng: 21.21935 },
    { lat: 48.7628399, lng: 21.2192752 },
    { lat: 48.7626994, lng: 21.2190323 },
    { lat: 48.7626971, lng: 21.2190289 },
    { lat: 48.7626747, lng: 21.2189941 },
    { lat: 48.7625791, lng: 21.2188507 },
    { lat: 48.7624398, lng: 21.2186417 },
    { lat: 48.7623962, lng: 21.2185834 },
    { lat: 48.7621827, lng: 21.2183037 },
    { lat: 48.7620221, lng: 21.2180954 },
    { lat: 48.7618692, lng: 21.2178859 },
    { lat: 48.7618398, lng: 21.2178444 },
    { lat: 48.7616514, lng: 21.217579 },
    { lat: 48.7615989, lng: 21.2174853 },
    { lat: 48.7611605, lng: 21.2167793 },
    { lat: 48.7609748, lng: 21.216453 },
    { lat: 48.7608804, lng: 21.2163114 },
    { lat: 48.7605124, lng: 21.2156656 },
    { lat: 48.7605726, lng: 21.2155729 },
    { lat: 48.7606253, lng: 21.2154928 },
    { lat: 48.7604984, lng: 21.2153948 },
    { lat: 48.760319, lng: 21.2150574 },
    { lat: 48.7602855, lng: 21.214992 },
    { lat: 48.7602639, lng: 21.2148787 },
    { lat: 48.7601945, lng: 21.2148152 },
    { lat: 48.7601863, lng: 21.2148079 },
    { lat: 48.7600591, lng: 21.2146971 },
    { lat: 48.7599322, lng: 21.2146374 },
    { lat: 48.7598462, lng: 21.2145504 },
    { lat: 48.7597685, lng: 21.2144732 },
    { lat: 48.7597524, lng: 21.2144273 },
    { lat: 48.7597181, lng: 21.2142689 },
    { lat: 48.7597072, lng: 21.2141352 },
    { lat: 48.759678, lng: 21.2140966 },
    { lat: 48.7596708, lng: 21.2140868 },
    { lat: 48.7596442, lng: 21.214134 },
    { lat: 48.7595484, lng: 21.2143074 },
    { lat: 48.7595339, lng: 21.2142803 },
    { lat: 48.7593606, lng: 21.2144952 },
    { lat: 48.7593181, lng: 21.214504 },
    { lat: 48.7591892, lng: 21.2146295 },
    { lat: 48.7591663, lng: 21.2146497 },
    { lat: 48.7590552, lng: 21.2147689 },
    { lat: 48.7589762, lng: 21.2147966 },
    { lat: 48.7589094, lng: 21.2148207 },
    { lat: 48.7588915, lng: 21.2148269 },
    { lat: 48.758863, lng: 21.2147773 },
    { lat: 48.7586185, lng: 21.2144109 },
    { lat: 48.7585583, lng: 21.2142444 },
    { lat: 48.7583993, lng: 21.2137692 },
    { lat: 48.758305, lng: 21.213486 },
    { lat: 48.7581328, lng: 21.213055 },
    { lat: 48.7579289, lng: 21.2125449 },
    { lat: 48.7577324, lng: 21.2120885 },
    { lat: 48.7575191, lng: 21.2117852 },
    { lat: 48.7574296, lng: 21.2116564 },
    { lat: 48.7575525, lng: 21.2114465 },
    { lat: 48.7576018, lng: 21.2113666 },
    { lat: 48.7577055, lng: 21.2112665 },
    { lat: 48.7577153, lng: 21.2112565 },
    { lat: 48.757776, lng: 21.2112158 },
    { lat: 48.7578614, lng: 21.2111783 },
    { lat: 48.7578454, lng: 21.2111313 },
    { lat: 48.7577968, lng: 21.2109824 },
    { lat: 48.7578063, lng: 21.2107121 },
    { lat: 48.7578905, lng: 21.210129 },
    { lat: 48.7578753, lng: 21.2095312 },
    { lat: 48.7580586, lng: 21.2088436 },
    { lat: 48.7584531, lng: 21.2086527 },
    { lat: 48.758521, lng: 21.2085515 },
    { lat: 48.7585014, lng: 21.208432 },
    { lat: 48.7583861, lng: 21.2083415 },
    { lat: 48.7582607, lng: 21.2081108 },
    { lat: 48.7582517, lng: 21.2079316 },
    { lat: 48.7582682, lng: 21.2077058 },
    { lat: 48.7582693, lng: 21.2076301 },
    { lat: 48.7582798, lng: 21.2069937 },
    { lat: 48.7581367, lng: 21.2058783 },
    { lat: 48.7578877, lng: 21.2039251 },
    { lat: 48.7578561, lng: 21.2038546 },
    { lat: 48.7581804, lng: 21.203175 },
    { lat: 48.7581456, lng: 21.2029635 },
    { lat: 48.7579558, lng: 21.2028651 },
    { lat: 48.7578394, lng: 21.2028157 },
    { lat: 48.7578382, lng: 21.2028129 },
    { lat: 48.7578206, lng: 21.2027914 },
    { lat: 48.7576917, lng: 21.2026294 },
    { lat: 48.7576507, lng: 21.2025781 },
    { lat: 48.7574691, lng: 21.2025997 },
    { lat: 48.7569737, lng: 21.2025903 },
    { lat: 48.7567173, lng: 21.2024903 },
    { lat: 48.756486, lng: 21.2024362 },
    { lat: 48.7564901, lng: 21.2018495 },
    { lat: 48.7564415, lng: 21.2013409 },
    { lat: 48.7563429, lng: 21.2011382 },
    { lat: 48.756444, lng: 21.2004504 },
    { lat: 48.7565646, lng: 21.1998108 },
    { lat: 48.7566247, lng: 21.1993189 },
    { lat: 48.7567718, lng: 21.1989164 },
    { lat: 48.7568453, lng: 21.1988195 },
    { lat: 48.7568953, lng: 21.198803 },
    { lat: 48.7571406, lng: 21.1986563 },
    { lat: 48.7573096, lng: 21.1985557 },
    { lat: 48.7574311, lng: 21.1984432 },
    { lat: 48.757544, lng: 21.198419 },
    { lat: 48.7576511, lng: 21.1983921 },
    { lat: 48.7577555, lng: 21.1983807 },
    { lat: 48.7578551, lng: 21.1983639 },
    { lat: 48.7579615, lng: 21.198347 },
    { lat: 48.7580337, lng: 21.1982812 },
    { lat: 48.7580513, lng: 21.1983042 },
    { lat: 48.7580748, lng: 21.1983346 },
    { lat: 48.758124, lng: 21.1982526 },
    { lat: 48.7581842, lng: 21.1982718 },
    { lat: 48.7582865, lng: 21.1981589 },
    { lat: 48.7582882, lng: 21.1981812 },
    { lat: 48.7583813, lng: 21.1981325 },
    { lat: 48.7584654, lng: 21.1980872 },
    { lat: 48.758551, lng: 21.1980557 },
    { lat: 48.7586534, lng: 21.1980188 },
    { lat: 48.7586847, lng: 21.1980101 },
    { lat: 48.7586904, lng: 21.19801 },
    { lat: 48.7587219, lng: 21.1980106 },
    { lat: 48.7587916, lng: 21.198012 },
    { lat: 48.7590621, lng: 21.1979848 },
    { lat: 48.7590574, lng: 21.1980158 },
    { lat: 48.759345, lng: 21.1979302 },
    { lat: 48.7594637, lng: 21.1977687 },
    { lat: 48.7600852, lng: 21.1977041 },
    { lat: 48.7606757, lng: 21.1977662 },
    { lat: 48.7608908, lng: 21.1978035 },
    { lat: 48.7609632, lng: 21.1978162 },
    { lat: 48.7611963, lng: 21.1978572 },
    { lat: 48.7612797, lng: 21.1968218 },
    { lat: 48.7614307, lng: 21.1963979 },
    { lat: 48.7615391, lng: 21.1956556 },
    { lat: 48.7618139, lng: 21.1948386 },
    { lat: 48.761883, lng: 21.1947466 },
    { lat: 48.7621113, lng: 21.1948469 },
    { lat: 48.7622263, lng: 21.1950705 },
    { lat: 48.7626979, lng: 21.1954286 },
    { lat: 48.7629193, lng: 21.1955632 },
    { lat: 48.7631203, lng: 21.1957257 },
    { lat: 48.7632107, lng: 21.1957756 },
    { lat: 48.7633154, lng: 21.195777 },
    { lat: 48.7634996, lng: 21.1957627 },
    { lat: 48.7637294, lng: 21.1958461 },
    { lat: 48.7640864, lng: 21.1959225 },
    { lat: 48.7643851, lng: 21.1959395 },
    { lat: 48.7650708, lng: 21.1960173 },
    { lat: 48.7660982, lng: 21.1960201 },
    { lat: 48.7662768, lng: 21.1957936 },
    { lat: 48.7664964, lng: 21.1952556 },
    { lat: 48.7666238, lng: 21.1948012 },
    { lat: 48.7666524, lng: 21.1946375 },
    { lat: 48.7666481, lng: 21.1944616 },
    { lat: 48.7665923, lng: 21.1941546 },
    { lat: 48.7665851, lng: 21.1940327 },
    { lat: 48.766595, lng: 21.1938458 },
    { lat: 48.7665947, lng: 21.1935511 },
    { lat: 48.7667959, lng: 21.1935788 },
    { lat: 48.7671778, lng: 21.1935566 },
    { lat: 48.7673183, lng: 21.1933354 },
    { lat: 48.767468, lng: 21.1932615 },
    { lat: 48.7676069, lng: 21.1932065 },
    { lat: 48.7678196, lng: 21.1929586 },
    { lat: 48.7682131, lng: 21.1924059 },
    { lat: 48.7684805, lng: 21.1920356 },
    { lat: 48.7689082, lng: 21.1922146 },
    { lat: 48.7690374, lng: 21.1924215 },
    { lat: 48.7692316, lng: 21.1925132 },
    { lat: 48.7694194, lng: 21.1928336 },
    { lat: 48.769663, lng: 21.1930266 },
    { lat: 48.7698463, lng: 21.1930227 },
    { lat: 48.7700238, lng: 21.1928362 },
    { lat: 48.7702846, lng: 21.1925716 },
    { lat: 48.770409, lng: 21.1922704 },
    { lat: 48.7711368, lng: 21.1916512 },
    { lat: 48.7712303, lng: 21.1914725 },
    { lat: 48.7713744, lng: 21.1914785 },
    { lat: 48.7717779, lng: 21.1912083 },
    { lat: 48.7718774, lng: 21.1912597 },
    { lat: 48.7722543, lng: 21.1911469 },
    { lat: 48.7726196, lng: 21.1914204 },
    { lat: 48.7728787, lng: 21.1913917 },
    { lat: 48.772946, lng: 21.1912071 },
    { lat: 48.7731322, lng: 21.1911858 },
    { lat: 48.7732853, lng: 21.1913304 },
    { lat: 48.7733149, lng: 21.1913407 },
    { lat: 48.7732824, lng: 21.1914562 },
    { lat: 48.7733868, lng: 21.1914829 },
    { lat: 48.7734623, lng: 21.1913765 },
    { lat: 48.7736114, lng: 21.1914303 },
    { lat: 48.7736363, lng: 21.1910404 },
    { lat: 48.7740406, lng: 21.1912303 },
    { lat: 48.7743536, lng: 21.1912328 },
    { lat: 48.7744908, lng: 21.1912294 },
    { lat: 48.7747973, lng: 21.1912426 },
    { lat: 48.774823, lng: 21.1912354 },
    { lat: 48.7749771, lng: 21.1913414 },
    { lat: 48.7751618, lng: 21.1912375 },
    { lat: 48.7753201, lng: 21.1911554 },
    { lat: 48.7751715, lng: 21.1918683 },
    { lat: 48.775214, lng: 21.192004 },
    { lat: 48.7752717, lng: 21.1919439 },
    { lat: 48.7755131, lng: 21.1922112 },
    { lat: 48.7758231, lng: 21.191653 },
    { lat: 48.7758652, lng: 21.1915615 },
    { lat: 48.775761, lng: 21.1914666 },
    { lat: 48.7760047, lng: 21.191271 },
    { lat: 48.7761648, lng: 21.1908888 },
    { lat: 48.7762181, lng: 21.1905431 },
    { lat: 48.7761644, lng: 21.1904438 },
    { lat: 48.7762656, lng: 21.1903352 },
    { lat: 48.7763268, lng: 21.1901017 },
    { lat: 48.7763021, lng: 21.1899212 },
    { lat: 48.776486, lng: 21.1897927 },
    { lat: 48.7773823, lng: 21.1890923 },
    { lat: 48.7774558, lng: 21.1889582 },
    { lat: 48.7775877, lng: 21.1887882 },
    { lat: 48.7777858, lng: 21.1886108 },
    { lat: 48.7778472, lng: 21.18856 },
    { lat: 48.7781561, lng: 21.1883272 },
    { lat: 48.7785505, lng: 21.1881276 },
    { lat: 48.7786797, lng: 21.188084 },
    { lat: 48.7789125, lng: 21.1880444 },
    { lat: 48.7790041, lng: 21.1880214 },
    { lat: 48.7792312, lng: 21.1879073 },
    { lat: 48.7793166, lng: 21.1878669 },
    { lat: 48.779401, lng: 21.1878683 },
    { lat: 48.7794867, lng: 21.1879085 },
    { lat: 48.7796338, lng: 21.1880417 },
    { lat: 48.7796418, lng: 21.1880443 },
    { lat: 48.7797552, lng: 21.1880729 },
    { lat: 48.7799195, lng: 21.1880379 },
    { lat: 48.7801274, lng: 21.187917 },
    { lat: 48.7803416, lng: 21.1878608 },
    { lat: 48.7805786, lng: 21.1878452 },
    { lat: 48.7806629, lng: 21.187842 },
    { lat: 48.7806632, lng: 21.1878883 },
    { lat: 48.7807101, lng: 21.1878743 },
    { lat: 48.7811353, lng: 21.1877626 },
    { lat: 48.781332, lng: 21.1877517 },
    { lat: 48.7815165, lng: 21.1876435 },
    { lat: 48.7819403, lng: 21.1875576 },
    { lat: 48.7822081, lng: 21.1874488 },
    { lat: 48.7825499, lng: 21.187402 },
    { lat: 48.7827374, lng: 21.1873157 },
    { lat: 48.7828944, lng: 21.187263 },
    { lat: 48.7830475, lng: 21.1871228 },
    { lat: 48.7833647, lng: 21.1869001 },
    { lat: 48.7835343, lng: 21.186712 },
    { lat: 48.7837099, lng: 21.1865705 },
    { lat: 48.7838381, lng: 21.1865327 },
    { lat: 48.783933, lng: 21.1865048 },
    { lat: 48.7840212, lng: 21.1864481 },
    { lat: 48.784137, lng: 21.1863759 },
    { lat: 48.784306, lng: 21.1862363 },
    { lat: 48.7846962, lng: 21.1860465 },
    { lat: 48.7847416, lng: 21.1860243 },
    { lat: 48.7848863, lng: 21.1859729 },
    { lat: 48.7849761, lng: 21.1859304 },
    { lat: 48.785236, lng: 21.1857503 },
    { lat: 48.7854376, lng: 21.1856459 },
    { lat: 48.7856173, lng: 21.1855266 },
    { lat: 48.785988, lng: 21.1853624 },
    { lat: 48.7862033, lng: 21.1852309 },
    { lat: 48.786317, lng: 21.1851614 },
    { lat: 48.7864643, lng: 21.1851215 },
    { lat: 48.7866795, lng: 21.185021 },
    { lat: 48.7866931, lng: 21.1850236 },
    { lat: 48.7868131, lng: 21.1850464 },
    { lat: 48.7872323, lng: 21.1849922 },
    { lat: 48.7875084, lng: 21.1850737 },
    { lat: 48.787637, lng: 21.1850855 },
    { lat: 48.7878512, lng: 21.1850615 },
    { lat: 48.7879243, lng: 21.1850223 },
    { lat: 48.7880046, lng: 21.1849987 },
    { lat: 48.7881093, lng: 21.1849668 },
    { lat: 48.7881843, lng: 21.1849564 },
    { lat: 48.7883356, lng: 21.1849327 },
    { lat: 48.788459, lng: 21.1848849 },
    { lat: 48.7887335, lng: 21.1847737 },
    { lat: 48.7888721, lng: 21.184778 },
    { lat: 48.7891286, lng: 21.1848134 },
    { lat: 48.7893162, lng: 21.1847638 },
    { lat: 48.7897513, lng: 21.1847539 },
    { lat: 48.7900101, lng: 21.1846795 },
    { lat: 48.7902473, lng: 21.1846353 },
    { lat: 48.7904803, lng: 21.1845307 },
    { lat: 48.7910586, lng: 21.1842406 },
    { lat: 48.791142, lng: 21.1841375 },
    { lat: 48.7914038, lng: 21.1840508 },
    { lat: 48.7915503, lng: 21.1840203 },
    { lat: 48.7916496, lng: 21.1839945 },
    { lat: 48.7919978, lng: 21.1838995 },
    { lat: 48.7923859, lng: 21.1837508 },
    { lat: 48.7926011, lng: 21.1837246 },
    { lat: 48.7929707, lng: 21.1837372 },
    { lat: 48.7931359, lng: 21.183625 },
    { lat: 48.7932755, lng: 21.1834468 },
    { lat: 48.7934543, lng: 21.1833347 },
    { lat: 48.7936339, lng: 21.1831404 },
    { lat: 48.7938717, lng: 21.1827256 },
    { lat: 48.7940151, lng: 21.182489 },
    { lat: 48.7941467, lng: 21.1823439 },
    { lat: 48.7943959, lng: 21.182181 },
    { lat: 48.7946654, lng: 21.1819466 },
    { lat: 48.7947665, lng: 21.1818351 },
    { lat: 48.7950348, lng: 21.181563 },
    { lat: 48.7952051, lng: 21.1814344 },
    { lat: 48.7953646, lng: 21.1813154 },
    { lat: 48.7953843, lng: 21.1812979 },
    { lat: 48.7955108, lng: 21.1811318 },
    { lat: 48.7956932, lng: 21.1809212 },
    { lat: 48.7959823, lng: 21.1810228 },
    { lat: 48.7961416, lng: 21.1808999 },
    { lat: 48.7962693, lng: 21.180775 },
    { lat: 48.7963963, lng: 21.1806966 },
    { lat: 48.7964839, lng: 21.180656 },
    { lat: 48.7965261, lng: 21.1806376 },
    { lat: 48.7967251, lng: 21.1806315 },
    { lat: 48.7970516, lng: 21.1808471 },
    { lat: 48.7972446, lng: 21.1809024 },
    { lat: 48.7978889, lng: 21.1808516 },
    { lat: 48.798103, lng: 21.1809678 },
    { lat: 48.7981732, lng: 21.1810167 },
    { lat: 48.7982261, lng: 21.1810539 },
    { lat: 48.7983734, lng: 21.1810472 },
    { lat: 48.7985386, lng: 21.1810053 },
    { lat: 48.7987441, lng: 21.1808859 },
    { lat: 48.7988986, lng: 21.1807841 },
    { lat: 48.7990826, lng: 21.1806962 },
    { lat: 48.799261, lng: 21.1805348 },
    { lat: 48.7993867, lng: 21.1805236 },
    { lat: 48.799594, lng: 21.1806028 },
    { lat: 48.7996656, lng: 21.1805545 },
    { lat: 48.7996885, lng: 21.180531 },
    { lat: 48.7997671, lng: 21.1804526 },
    { lat: 48.7999241, lng: 21.1804355 },
    { lat: 48.8001285, lng: 21.180314 },
    { lat: 48.8001581, lng: 21.1802929 },
    { lat: 48.8002317, lng: 21.1802319 },
    { lat: 48.8004716, lng: 21.1798119 },
    { lat: 48.8005878, lng: 21.1797122 },
    { lat: 48.8008142, lng: 21.179684 },
    { lat: 48.8009661, lng: 21.1797884 },
    { lat: 48.8011386, lng: 21.1800508 },
    { lat: 48.8013956, lng: 21.1805292 },
    { lat: 48.8016109, lng: 21.1809638 },
    { lat: 48.8018731, lng: 21.1810016 },
    { lat: 48.8019428, lng: 21.1811055 },
    { lat: 48.8021128, lng: 21.181215 },
    { lat: 48.80211, lng: 21.181335 },
    { lat: 48.802275, lng: 21.181335 },
    { lat: 48.802926, lng: 21.181024 },
    { lat: 48.802936, lng: 21.18027 },
    { lat: 48.803077, lng: 21.180012 },
    { lat: 48.803211, lng: 21.179952 },
    { lat: 48.80375, lng: 21.179134 },
    { lat: 48.803961, lng: 21.178213 },
    { lat: 48.804008, lng: 21.177462 },
    { lat: 48.804145, lng: 21.177123 },
    { lat: 48.804425, lng: 21.176837 },
    { lat: 48.804857, lng: 21.175991 },
    { lat: 48.805282, lng: 21.175549 },
    { lat: 48.805418, lng: 21.175409 },
    { lat: 48.805977, lng: 21.174668 },
    { lat: 48.806118, lng: 21.174469 },
    { lat: 48.806437, lng: 21.174205 },
    { lat: 48.806754, lng: 21.173942 },
    { lat: 48.807074, lng: 21.173438 },
    { lat: 48.807399, lng: 21.17218 },
    { lat: 48.807452, lng: 21.172073 },
    { lat: 48.807587, lng: 21.1719649 },
    { lat: 48.807693, lng: 21.17188 },
    { lat: 48.808783, lng: 21.172575 },
    { lat: 48.809683, lng: 21.17252 },
    { lat: 48.810007, lng: 21.172581 },
    { lat: 48.810919, lng: 21.172473 },
    { lat: 48.811237, lng: 21.172115 },
    { lat: 48.811578, lng: 21.171703 },
    { lat: 48.812214, lng: 21.170894 },
    { lat: 48.812482, lng: 21.169572 },
    { lat: 48.8126568, lng: 21.1691486 },
    { lat: 48.812719, lng: 21.168998 },
    { lat: 48.812902, lng: 21.168887 },
    { lat: 48.813132, lng: 21.168795 },
    { lat: 48.81335, lng: 21.168787 },
    { lat: 48.81352, lng: 21.168571 },
    { lat: 48.813685, lng: 21.168025 },
    { lat: 48.813918, lng: 21.167101 },
    { lat: 48.814165, lng: 21.166398 },
    { lat: 48.814227, lng: 21.16592 },
    { lat: 48.814279, lng: 21.165657 },
    { lat: 48.814471, lng: 21.165374 },
    { lat: 48.815233, lng: 21.165059 },
    { lat: 48.815701, lng: 21.164845 },
    { lat: 48.816395, lng: 21.164492 },
    { lat: 48.816905, lng: 21.164106 },
    { lat: 48.817429, lng: 21.163907 },
    { lat: 48.817615, lng: 21.164408 },
    { lat: 48.817938, lng: 21.164184 },
    { lat: 48.818045, lng: 21.163844 },
    { lat: 48.818177, lng: 21.163734 },
    { lat: 48.818585, lng: 21.163735 },
    { lat: 48.818733, lng: 21.163617 },
    { lat: 48.819278, lng: 21.163336 },
    { lat: 48.819844, lng: 21.162891 },
    { lat: 48.820159, lng: 21.162647 },
    { lat: 48.820274, lng: 21.162539 },
    { lat: 48.820562, lng: 21.162379 },
    { lat: 48.821131, lng: 21.162022 },
    { lat: 48.821449, lng: 21.161728 },
    { lat: 48.821649, lng: 21.161369 },
    { lat: 48.821731, lng: 21.161114 },
    { lat: 48.821964, lng: 21.160848 },
    { lat: 48.822229, lng: 21.160436 },
    { lat: 48.822602, lng: 21.160042 },
    { lat: 48.822815, lng: 21.159866 },
    { lat: 48.823071, lng: 21.159144 },
    { lat: 48.82331, lng: 21.157303 },
    { lat: 48.823291, lng: 21.156553 },
    { lat: 48.823578, lng: 21.155522 },
    { lat: 48.824125, lng: 21.15431 },
    { lat: 48.824195, lng: 21.153923 },
    { lat: 48.824241, lng: 21.153428 },
    { lat: 48.824417, lng: 21.152963 },
    { lat: 48.824512, lng: 21.152288 },
    { lat: 48.824497, lng: 21.151748 },
    { lat: 48.82442, lng: 21.151106 },
    { lat: 48.824281, lng: 21.150203 },
    { lat: 48.824241, lng: 21.149718 },
    { lat: 48.823862, lng: 21.148674 },
    { lat: 48.823637, lng: 21.148288 },
    { lat: 48.823586, lng: 21.148214 },
    { lat: 48.822877, lng: 21.148034 },
    { lat: 48.822067, lng: 21.147828 },
    { lat: 48.821561, lng: 21.1477 },
    { lat: 48.821189, lng: 21.147605 },
    { lat: 48.820172, lng: 21.144835 },
    { lat: 48.819361, lng: 21.142651 },
    { lat: 48.819206, lng: 21.142235 },
    { lat: 48.819125, lng: 21.142015 },
    { lat: 48.818663, lng: 21.141483 },
    { lat: 48.81858, lng: 21.14124 },
    { lat: 48.818197, lng: 21.140789 },
    { lat: 48.818154, lng: 21.140611 },
    { lat: 48.81807, lng: 21.140266 },
    { lat: 48.817945, lng: 21.139755 },
    { lat: 48.818008, lng: 21.139468 },
    { lat: 48.817794, lng: 21.139356 },
    { lat: 48.817661, lng: 21.139065 },
    { lat: 48.817614, lng: 21.138698 },
    { lat: 48.817567, lng: 21.138331 },
    { lat: 48.816931, lng: 21.137434 },
    { lat: 48.816718, lng: 21.137086 },
    { lat: 48.81652, lng: 21.136828 },
    { lat: 48.816362, lng: 21.136632 },
    { lat: 48.816147, lng: 21.136443 },
    { lat: 48.81595, lng: 21.13616 },
    { lat: 48.815756, lng: 21.135878 },
    { lat: 48.815435, lng: 21.135755 },
    { lat: 48.814711, lng: 21.135601 },
    { lat: 48.814493, lng: 21.135375 },
    { lat: 48.814344, lng: 21.135308 },
    { lat: 48.814068, lng: 21.134914 },
    { lat: 48.813635, lng: 21.134409 },
    { lat: 48.812762, lng: 21.134104 },
    { lat: 48.812527, lng: 21.133895 },
    { lat: 48.812363, lng: 21.133285 },
    { lat: 48.812046, lng: 21.132654 },
    { lat: 48.811867, lng: 21.132439 },
    { lat: 48.81172, lng: 21.132405 },
    { lat: 48.811224, lng: 21.132439 },
    { lat: 48.811017, lng: 21.132346 },
    { lat: 48.810207, lng: 21.131163 },
    { lat: 48.809887, lng: 21.131017 },
    { lat: 48.809787, lng: 21.131042 },
    { lat: 48.809447, lng: 21.13086 },
    { lat: 48.809123, lng: 21.130827 },
    { lat: 48.808906, lng: 21.130755 },
    { lat: 48.808649, lng: 21.13049 },
    { lat: 48.808309, lng: 21.130638 },
    { lat: 48.80795, lng: 21.130746 },
    { lat: 48.807665, lng: 21.13078 },
    { lat: 48.807497, lng: 21.130892 },
    { lat: 48.80731, lng: 21.130857 },
    { lat: 48.807004, lng: 21.131048 },
    { lat: 48.806778, lng: 21.131282 },
    { lat: 48.80663, lng: 21.131443 },
    { lat: 48.80627, lng: 21.131386 },
    { lat: 48.806053, lng: 21.131708 },
    { lat: 48.805608, lng: 21.131734 },
    { lat: 48.805202, lng: 21.131709 },
    { lat: 48.805098, lng: 21.131933 },
    { lat: 48.804959, lng: 21.132147 },
    { lat: 48.804806, lng: 21.132423 },
    { lat: 48.804555, lng: 21.132654 },
    { lat: 48.804059, lng: 21.132699 },
    { lat: 48.803657, lng: 21.133317 },
    { lat: 48.803143, lng: 21.133493 },
    { lat: 48.802875, lng: 21.13353 },
    { lat: 48.80261, lng: 21.133575 },
    { lat: 48.802272, lng: 21.133526 },
    { lat: 48.801811, lng: 21.133568 },
    { lat: 48.801209, lng: 21.13385 },
    { lat: 48.800676, lng: 21.134296 },
    { lat: 48.800259, lng: 21.134534 },
    { lat: 48.799431, lng: 21.134957 },
    { lat: 48.799091, lng: 21.135375 },
    { lat: 48.79902, lng: 21.136135 },
    { lat: 48.798944, lng: 21.136304 },
    { lat: 48.798496, lng: 21.137302 },
    { lat: 48.798163, lng: 21.137586 },
    { lat: 48.798023, lng: 21.137706 },
    { lat: 48.797597, lng: 21.138512 },
    { lat: 48.797446, lng: 21.138782 },
    { lat: 48.797259, lng: 21.138911 },
    { lat: 48.796948, lng: 21.139052 },
    { lat: 48.796791, lng: 21.139099 },
    { lat: 48.796461, lng: 21.139195 },
    { lat: 48.796436, lng: 21.139203 },
    { lat: 48.795914, lng: 21.139553 },
    { lat: 48.795402, lng: 21.139586 },
    { lat: 48.795315, lng: 21.139559 },
    { lat: 48.79481, lng: 21.139402 },
    { lat: 48.794517, lng: 21.139625 },
    { lat: 48.794355, lng: 21.139862 },
    { lat: 48.794009, lng: 21.14037 },
    { lat: 48.793542, lng: 21.140568 },
    { lat: 48.792991, lng: 21.141411 },
    { lat: 48.792941, lng: 21.141426 },
    { lat: 48.792347, lng: 21.141821 },
    { lat: 48.791643, lng: 21.142367 },
    { lat: 48.791477, lng: 21.142543 },
    { lat: 48.791097, lng: 21.142942 },
    { lat: 48.790303, lng: 21.143701 },
    { lat: 48.789965, lng: 21.143858 },
    { lat: 48.789609, lng: 21.144185 },
    { lat: 48.789425, lng: 21.144124 },
    { lat: 48.789213, lng: 21.14392 },
    { lat: 48.78898, lng: 21.143425 },
    { lat: 48.788722, lng: 21.143079 },
    { lat: 48.78856, lng: 21.142566 },
    { lat: 48.787983, lng: 21.142616 },
    { lat: 48.787077, lng: 21.141415 },
    { lat: 48.783919, lng: 21.142165 },
    { lat: 48.782541, lng: 21.142491 },
    { lat: 48.782423, lng: 21.14228 },
    { lat: 48.782179, lng: 21.141844 },
    { lat: 48.782234, lng: 21.141559 },
    { lat: 48.782107, lng: 21.141647 },
    { lat: 48.782084, lng: 21.141452 },
    { lat: 48.781882, lng: 21.141251 },
    { lat: 48.781655, lng: 21.141114 },
    { lat: 48.7815384, lng: 21.1410518 },
    { lat: 48.781543, lng: 21.140981 },
    { lat: 48.781448, lng: 21.14095 },
    { lat: 48.78118, lng: 21.141049 },
    { lat: 48.780743, lng: 21.14091 },
    { lat: 48.780904, lng: 21.140754 },
    { lat: 48.781648, lng: 21.140036 },
    { lat: 48.782691, lng: 21.139208 },
    { lat: 48.783243, lng: 21.138883 },
    { lat: 48.783294, lng: 21.13881 },
    { lat: 48.783437, lng: 21.138337 },
    { lat: 48.783412, lng: 21.137915 },
    { lat: 48.783396, lng: 21.137539 },
    { lat: 48.783419, lng: 21.137272 },
    { lat: 48.783414, lng: 21.13702 },
    { lat: 48.783414, lng: 21.137018 },
    { lat: 48.783091, lng: 21.135031 },
    { lat: 48.783099, lng: 21.134812 },
    { lat: 48.783109, lng: 21.134505 },
    { lat: 48.783233, lng: 21.134211 },
    { lat: 48.784301, lng: 21.132629 },
    { lat: 48.784268, lng: 21.132583 },
    { lat: 48.783386, lng: 21.132842 },
    { lat: 48.78332, lng: 21.132913 },
    { lat: 48.783247, lng: 21.132858 },
    { lat: 48.783264, lng: 21.132574 },
    { lat: 48.783392, lng: 21.132285 },
    { lat: 48.783617, lng: 21.131988 },
    { lat: 48.783946, lng: 21.131578 },
    { lat: 48.783924, lng: 21.131435 },
    { lat: 48.783506, lng: 21.131268 },
    { lat: 48.783372, lng: 21.131154 },
    { lat: 48.783308, lng: 21.131015 },
    { lat: 48.783324, lng: 21.130807 },
    { lat: 48.783384, lng: 21.130753 },
    { lat: 48.783478, lng: 21.130539 },
    { lat: 48.78362, lng: 21.130448 },
    { lat: 48.784149, lng: 21.129925 },
    { lat: 48.783906, lng: 21.129599 },
    { lat: 48.783888, lng: 21.129488 },
    { lat: 48.783805, lng: 21.129395 },
    { lat: 48.783712, lng: 21.129177 },
    { lat: 48.783626, lng: 21.129038 },
    { lat: 48.783567, lng: 21.128765 },
    { lat: 48.783475, lng: 21.128645 },
    { lat: 48.783448, lng: 21.128473 },
    { lat: 48.783503, lng: 21.128252 },
    { lat: 48.783472, lng: 21.12826 },
    { lat: 48.783392, lng: 21.128096 },
    { lat: 48.783339, lng: 21.12813 },
    { lat: 48.783322, lng: 21.128098 },
    { lat: 48.784081, lng: 21.127406 },
    { lat: 48.784209, lng: 21.127289 },
    { lat: 48.784103, lng: 21.126961 },
    { lat: 48.784446, lng: 21.12646 },
    { lat: 48.784521, lng: 21.126355 },
    { lat: 48.784556, lng: 21.126305 },
    { lat: 48.784718, lng: 21.126051 },
    { lat: 48.784698, lng: 21.125959 },
    { lat: 48.784693, lng: 21.12594 },
    { lat: 48.7846668, lng: 21.1258242 },
    { lat: 48.784416, lng: 21.125799 },
    { lat: 48.784388, lng: 21.125797 },
    { lat: 48.784264, lng: 21.125779 },
    { lat: 48.784098, lng: 21.125515 },
    { lat: 48.783878, lng: 21.12557 },
    { lat: 48.783534, lng: 21.125506 },
    { lat: 48.783425, lng: 21.125485 },
    { lat: 48.783357, lng: 21.125456 },
    { lat: 48.783037, lng: 21.125315 },
    { lat: 48.782972, lng: 21.125286 },
    { lat: 48.782785, lng: 21.125138 },
    { lat: 48.782578, lng: 21.124939 },
    { lat: 48.782562, lng: 21.124923 },
    { lat: 48.782404, lng: 21.124761 },
    { lat: 48.782229, lng: 21.124582 },
    { lat: 48.782088, lng: 21.124337 },
    { lat: 48.781988, lng: 21.12414 },
    { lat: 48.781932, lng: 21.12403 },
    { lat: 48.781882, lng: 21.12393 },
    { lat: 48.781685, lng: 21.123538 },
    { lat: 48.781527, lng: 21.12329 },
    { lat: 48.781508, lng: 21.12326 },
    { lat: 48.781429, lng: 21.123134 },
    { lat: 48.781317, lng: 21.123124 },
    { lat: 48.781254, lng: 21.12306 },
    { lat: 48.781149, lng: 21.122956 },
    { lat: 48.781159, lng: 21.122907 },
    { lat: 48.781211, lng: 21.122661 },
    { lat: 48.780981, lng: 21.122353 },
    { lat: 48.780739, lng: 21.122256 },
    { lat: 48.780533, lng: 21.122514 },
    { lat: 48.780044, lng: 21.122591 },
    { lat: 48.779978, lng: 21.122866 },
    { lat: 48.779747, lng: 21.122289 },
    { lat: 48.779553, lng: 21.122279 },
    { lat: 48.77944, lng: 21.122435 },
    { lat: 48.779329, lng: 21.122694 },
    { lat: 48.779078, lng: 21.122867 },
    { lat: 48.778775, lng: 21.12295 },
    { lat: 48.778154, lng: 21.123016 },
    { lat: 48.777247, lng: 21.122844 },
    { lat: 48.776287, lng: 21.123249 },
    { lat: 48.774923, lng: 21.123224 },
    { lat: 48.774338, lng: 21.123117 },
    { lat: 48.774172, lng: 21.123157 },
    { lat: 48.774245, lng: 21.123022 },
    { lat: 48.774068, lng: 21.122708 },
    { lat: 48.773826, lng: 21.122732 },
    { lat: 48.773086, lng: 21.123331 },
    { lat: 48.771492, lng: 21.123341 },
    { lat: 48.771119, lng: 21.123672 },
    { lat: 48.771007, lng: 21.123708 },
    { lat: 48.770527, lng: 21.124287 },
    { lat: 48.770063, lng: 21.12457 },
    { lat: 48.769451, lng: 21.124848 },
    { lat: 48.768791, lng: 21.124826 },
    { lat: 48.768552, lng: 21.124894 },
    { lat: 48.768096, lng: 21.125233 },
    { lat: 48.767548, lng: 21.125493 },
    { lat: 48.767496, lng: 21.125518 },
    { lat: 48.767262, lng: 21.125144 },
    { lat: 48.766959, lng: 21.125063 },
    { lat: 48.766664, lng: 21.125324 },
    { lat: 48.766776, lng: 21.125672 },
    { lat: 48.766638, lng: 21.125761 },
    { lat: 48.766512, lng: 21.125843 },
    { lat: 48.766413, lng: 21.125908 },
    { lat: 48.766153, lng: 21.125778 },
    { lat: 48.766128, lng: 21.125766 },
    { lat: 48.765969, lng: 21.125197 },
    { lat: 48.765937, lng: 21.125082 },
    { lat: 48.765854, lng: 21.124805 },
    { lat: 48.765808, lng: 21.124651 },
    { lat: 48.7656888, lng: 21.1242551 },
    { lat: 48.765612, lng: 21.124 },
    { lat: 48.765585, lng: 21.123905 },
    { lat: 48.765491, lng: 21.123595 },
    { lat: 48.764453, lng: 21.121754 },
    { lat: 48.76378, lng: 21.12056 },
    { lat: 48.763439, lng: 21.119956 },
    { lat: 48.761786, lng: 21.121009 },
    { lat: 48.761407, lng: 21.121375 },
    { lat: 48.761307, lng: 21.121546 },
    { lat: 48.761287, lng: 21.121579 },
    { lat: 48.761148, lng: 21.121818 },
    { lat: 48.76071, lng: 21.122183 },
    { lat: 48.759864, lng: 21.123638 },
    { lat: 48.759789, lng: 21.124038 },
    { lat: 48.7598, lng: 21.124497 },
    { lat: 48.75973, lng: 21.124901 },
    { lat: 48.759573, lng: 21.125364 },
    { lat: 48.759456, lng: 21.125914 },
    { lat: 48.75918, lng: 21.126484 },
    { lat: 48.75895, lng: 21.126852 },
    { lat: 48.758661, lng: 21.126996 },
    { lat: 48.758369, lng: 21.127211 },
    { lat: 48.758069, lng: 21.127686 },
    { lat: 48.757852, lng: 21.1281 },
    { lat: 48.757833, lng: 21.128189 },
    { lat: 48.757577, lng: 21.128508 },
    { lat: 48.757491, lng: 21.129097 },
    { lat: 48.757466, lng: 21.129301 },
    { lat: 48.757211, lng: 21.129706 },
    { lat: 48.757024, lng: 21.129796 },
    { lat: 48.756922, lng: 21.129982 },
    { lat: 48.756572, lng: 21.130391 },
    { lat: 48.756398, lng: 21.130468 },
    { lat: 48.756249, lng: 21.130636 },
    { lat: 48.7561, lng: 21.13089 },
    { lat: 48.755911, lng: 21.131262 },
    { lat: 48.75581, lng: 21.131307 },
    { lat: 48.755745, lng: 21.131458 },
    { lat: 48.755713, lng: 21.131939 },
    { lat: 48.755779, lng: 21.132454 },
    { lat: 48.755954, lng: 21.132888 },
    { lat: 48.755896, lng: 21.133146 },
    { lat: 48.755957, lng: 21.133683 },
    { lat: 48.755873, lng: 21.134086 },
    { lat: 48.755963, lng: 21.134521 },
    { lat: 48.756063, lng: 21.13479 },
    { lat: 48.756295, lng: 21.135002 },
    { lat: 48.755963, lng: 21.13559 },
    { lat: 48.755913, lng: 21.136257 },
    { lat: 48.756112, lng: 21.136705 },
    { lat: 48.756161, lng: 21.136787 },
    { lat: 48.756372, lng: 21.137103 },
    { lat: 48.756634, lng: 21.137494 },
    { lat: 48.757147, lng: 21.138233 },
    { lat: 48.756659, lng: 21.138649 },
    { lat: 48.756223, lng: 21.138915 },
    { lat: 48.755606, lng: 21.13951 },
    { lat: 48.754847, lng: 21.140358 },
    { lat: 48.754437, lng: 21.140737 },
    { lat: 48.753936, lng: 21.140256 },
    { lat: 48.753566, lng: 21.140344 },
    { lat: 48.752825, lng: 21.141868 },
    { lat: 48.752521, lng: 21.142383 },
    { lat: 48.751962, lng: 21.142965 },
    { lat: 48.751436, lng: 21.143725 },
    { lat: 48.751227, lng: 21.143847 },
    { lat: 48.751079, lng: 21.144509 },
    { lat: 48.750846, lng: 21.144906 },
    { lat: 48.750685, lng: 21.145246 },
    { lat: 48.750469, lng: 21.145661 },
    { lat: 48.750397, lng: 21.145964 },
    { lat: 48.750212, lng: 21.146187 },
    { lat: 48.750157, lng: 21.146537 },
    { lat: 48.749995, lng: 21.146601 },
    { lat: 48.750008, lng: 21.146778 },
    { lat: 48.749864, lng: 21.146877 },
    { lat: 48.749784, lng: 21.147017 },
    { lat: 48.749739, lng: 21.147214 },
    { lat: 48.749554, lng: 21.14753 },
    { lat: 48.749356, lng: 21.147637 },
    { lat: 48.749304, lng: 21.147738 },
    { lat: 48.749255, lng: 21.147979 },
    { lat: 48.749174, lng: 21.148193 },
    { lat: 48.749055, lng: 21.148227 },
    { lat: 48.74903, lng: 21.148322 },
    { lat: 48.748981, lng: 21.148389 },
    { lat: 48.748795, lng: 21.148532 },
    { lat: 48.748694, lng: 21.148768 },
    { lat: 48.748507, lng: 21.149003 },
    { lat: 48.748241, lng: 21.14932 },
    { lat: 48.748048, lng: 21.149562 },
    { lat: 48.747774, lng: 21.150138 },
    { lat: 48.747593, lng: 21.150715 },
    { lat: 48.747495, lng: 21.151227 },
    { lat: 48.747471, lng: 21.151301 },
    { lat: 48.747249, lng: 21.151509 },
    { lat: 48.747141, lng: 21.151731 },
    { lat: 48.746988, lng: 21.151976 },
    { lat: 48.746826, lng: 21.152249 },
    { lat: 48.746685, lng: 21.152414 },
    { lat: 48.746496, lng: 21.152558 },
    { lat: 48.746279, lng: 21.152718 },
    { lat: 48.74604, lng: 21.15313 },
    { lat: 48.745788, lng: 21.153395 },
    { lat: 48.745446, lng: 21.153713 },
    { lat: 48.744948, lng: 21.154168 },
    { lat: 48.744557, lng: 21.15451 },
    { lat: 48.744302, lng: 21.154813 },
    { lat: 48.743981, lng: 21.155365 },
    { lat: 48.743719, lng: 21.155686 },
    { lat: 48.743351, lng: 21.155874 },
    { lat: 48.743185, lng: 21.155959 },
    { lat: 48.74285, lng: 21.156195 },
    { lat: 48.742556, lng: 21.156402 },
    { lat: 48.742146, lng: 21.156551 },
    { lat: 48.742134, lng: 21.156771 },
    { lat: 48.742165, lng: 21.157048 },
    { lat: 48.742081, lng: 21.157459 },
    { lat: 48.741997, lng: 21.157855 },
    { lat: 48.741904, lng: 21.158 },
    { lat: 48.741634, lng: 21.158258 },
    { lat: 48.741385, lng: 21.158643 },
    { lat: 48.741026, lng: 21.159503 },
    { lat: 48.740587, lng: 21.160226 },
    { lat: 48.740371, lng: 21.160556 },
    { lat: 48.740011, lng: 21.161061 },
    { lat: 48.739798, lng: 21.161294 },
    { lat: 48.739647, lng: 21.161461 },
    { lat: 48.739258, lng: 21.16184 },
    { lat: 48.739223, lng: 21.161879 },
    { lat: 48.739196, lng: 21.161887 },
    { lat: 48.73902, lng: 21.162041 },
    { lat: 48.738855, lng: 21.162155 },
    { lat: 48.73856, lng: 21.162269 },
    { lat: 48.738157, lng: 21.162462 },
    { lat: 48.737962, lng: 21.162506 },
    { lat: 48.737748, lng: 21.162528 },
    { lat: 48.737589, lng: 21.162515 },
    { lat: 48.73739, lng: 21.162552 },
    { lat: 48.737221, lng: 21.16267 },
    { lat: 48.737005, lng: 21.162851 },
    { lat: 48.736851, lng: 21.162928 },
    { lat: 48.736335, lng: 21.16321 },
    { lat: 48.736194, lng: 21.163338 },
    { lat: 48.736067, lng: 21.163574 },
    { lat: 48.735752, lng: 21.163936 },
    { lat: 48.735449, lng: 21.164277 },
    { lat: 48.73514, lng: 21.164621 },
    { lat: 48.735217, lng: 21.164786 },
    { lat: 48.735364, lng: 21.165107 },
    { lat: 48.735596, lng: 21.165542 },
    { lat: 48.735221, lng: 21.165978 },
    { lat: 48.734257, lng: 21.167097 },
    { lat: 48.73338, lng: 21.167232 },
    { lat: 48.732818, lng: 21.167319 },
    { lat: 48.732247, lng: 21.168477 },
    { lat: 48.73215, lng: 21.168675 },
    { lat: 48.732084, lng: 21.1689 },
    { lat: 48.731825, lng: 21.16932 },
    { lat: 48.731713, lng: 21.169709 },
    { lat: 48.731745, lng: 21.169879 },
    { lat: 48.73182, lng: 21.170463 },
    { lat: 48.731956, lng: 21.171192 },
    { lat: 48.732239, lng: 21.171835 },
    { lat: 48.732262, lng: 21.172162 },
    { lat: 48.732519, lng: 21.17229 },
    { lat: 48.732872, lng: 21.172387 },
    { lat: 48.733037, lng: 21.172479 },
    { lat: 48.733237, lng: 21.172529 },
    { lat: 48.733049, lng: 21.173185 },
    { lat: 48.732798, lng: 21.173956 },
    { lat: 48.732606, lng: 21.173881 },
    { lat: 48.732162, lng: 21.173669 },
    { lat: 48.731968, lng: 21.173573 },
    { lat: 48.731774, lng: 21.173483 },
    { lat: 48.731534, lng: 21.174794 },
    { lat: 48.731196, lng: 21.176303 },
    { lat: 48.731192, lng: 21.176642 },
    { lat: 48.731242, lng: 21.176705 },
    { lat: 48.731319, lng: 21.176974 },
    { lat: 48.731623, lng: 21.177529 },
    { lat: 48.731625, lng: 21.177717 },
    { lat: 48.731533, lng: 21.178208 },
    { lat: 48.731536, lng: 21.1785 },
    { lat: 48.731676, lng: 21.178984 },
    { lat: 48.731684, lng: 21.179259 },
    { lat: 48.732705, lng: 21.178618 },
    { lat: 48.732969, lng: 21.178542 },
    { lat: 48.733276, lng: 21.178596 },
    { lat: 48.733108, lng: 21.179326 },
    { lat: 48.733094, lng: 21.179811 },
    { lat: 48.733079, lng: 21.180431 },
    { lat: 48.733, lng: 21.18043 },
    { lat: 48.73303, lng: 21.180789 },
    { lat: 48.733033, lng: 21.180941 },
    { lat: 48.733012, lng: 21.180942 },
    { lat: 48.732858, lng: 21.180937 },
    { lat: 48.732131, lng: 21.181007 },
    { lat: 48.731812, lng: 21.181008 },
    { lat: 48.731628, lng: 21.180785 },
    { lat: 48.731246, lng: 21.180621 },
    { lat: 48.731198, lng: 21.180711 },
    { lat: 48.731103, lng: 21.180833 },
    { lat: 48.731066, lng: 21.182366 },
    { lat: 48.731169, lng: 21.183089 },
    { lat: 48.731267, lng: 21.183461 },
    { lat: 48.731253, lng: 21.1838 },
    { lat: 48.731104, lng: 21.184357 },
    { lat: 48.731142, lng: 21.184531 },
    { lat: 48.731329, lng: 21.184783 },
    { lat: 48.731422, lng: 21.185068 },
    { lat: 48.731907, lng: 21.185671 },
    { lat: 48.732159, lng: 21.18583 },
    { lat: 48.732367, lng: 21.186069 },
    { lat: 48.732345, lng: 21.186156 },
    { lat: 48.732241, lng: 21.186254 },
    { lat: 48.731982, lng: 21.186409 },
    { lat: 48.73167, lng: 21.186497 },
    { lat: 48.731525, lng: 21.186597 },
    { lat: 48.731201, lng: 21.186977 },
    { lat: 48.730913, lng: 21.187245 },
    { lat: 48.730645, lng: 21.187765 },
    { lat: 48.730357, lng: 21.188181 },
    { lat: 48.730248, lng: 21.188395 },
    { lat: 48.730149, lng: 21.188574 },
    { lat: 48.730079, lng: 21.188666 },
    { lat: 48.730063, lng: 21.188787 },
    { lat: 48.729974, lng: 21.188818 },
    { lat: 48.729743, lng: 21.189205 },
    { lat: 48.729521, lng: 21.189931 },
    { lat: 48.729542, lng: 21.190145 },
    { lat: 48.729601, lng: 21.190414 },
    { lat: 48.729701, lng: 21.19097 },
    { lat: 48.729726, lng: 21.191145 },
    { lat: 48.729774, lng: 21.191338 },
    { lat: 48.729697, lng: 21.191374 },
    { lat: 48.729404, lng: 21.191762 },
    { lat: 48.729474, lng: 21.191972 },
    { lat: 48.729252, lng: 21.192113 },
    { lat: 48.729074, lng: 21.192414 },
    { lat: 48.728938, lng: 21.192688 },
    { lat: 48.728828, lng: 21.192846 },
    { lat: 48.728683, lng: 21.193177 },
    { lat: 48.7286297, lng: 21.1932564 },
    { lat: 48.728577, lng: 21.193389 },
    { lat: 48.728517, lng: 21.193794 },
    { lat: 48.728514, lng: 21.194126 },
    { lat: 48.728465, lng: 21.194502 },
    { lat: 48.728418, lng: 21.194639 },
    { lat: 48.728442, lng: 21.194919 },
    { lat: 48.728371, lng: 21.195012 },
    { lat: 48.728344, lng: 21.195103 },
    { lat: 48.72835, lng: 21.195334 },
    { lat: 48.728412, lng: 21.195501 },
    { lat: 48.728471, lng: 21.195538 },
    { lat: 48.728385, lng: 21.19559 },
    { lat: 48.728321, lng: 21.195921 },
    { lat: 48.728243, lng: 21.196218 },
    { lat: 48.728115, lng: 21.196521 },
    { lat: 48.728034, lng: 21.196652 },
    { lat: 48.727917, lng: 21.196878 },
    { lat: 48.727862, lng: 21.197089 },
    { lat: 48.727873, lng: 21.197393 },
    { lat: 48.727836, lng: 21.197588 },
    { lat: 48.727794, lng: 21.197708 },
    { lat: 48.727555, lng: 21.198062 },
    { lat: 48.727519, lng: 21.198239 },
    { lat: 48.727479, lng: 21.198329 },
    { lat: 48.727365, lng: 21.198427 },
    { lat: 48.727293, lng: 21.19854 },
    { lat: 48.726922, lng: 21.199027 },
    { lat: 48.726864, lng: 21.198944 },
    { lat: 48.726544, lng: 21.19955 },
    { lat: 48.726139, lng: 21.200661 },
    { lat: 48.725881, lng: 21.201165 },
    { lat: 48.7255723, lng: 21.2018668 },
    { lat: 48.725579, lng: 21.201932 },
    { lat: 48.725366, lng: 21.202967 },
    { lat: 48.725259, lng: 21.203755 },
    { lat: 48.725168, lng: 21.204229 },
    { lat: 48.725077, lng: 21.204659 },
    { lat: 48.725007, lng: 21.205459 },
    { lat: 48.724977, lng: 21.20636 },
    { lat: 48.724916, lng: 21.20717 },
    { lat: 48.724908, lng: 21.207831 },
    { lat: 48.724857, lng: 21.208546 },
    { lat: 48.724738, lng: 21.209298 },
    { lat: 48.724576, lng: 21.209788 },
    { lat: 48.724179, lng: 21.210351 },
    { lat: 48.723513, lng: 21.211447 },
    { lat: 48.723186, lng: 21.211848 },
    { lat: 48.723281, lng: 21.211959 },
    { lat: 48.722848, lng: 21.21258 },
    { lat: 48.72246, lng: 21.213429 },
    { lat: 48.722084, lng: 21.214718 },
    { lat: 48.722007, lng: 21.215478 },
    { lat: 48.7218, lng: 21.216232 },
    { lat: 48.721837, lng: 21.217021 },
    { lat: 48.722532, lng: 21.216755 },
    { lat: 48.72306, lng: 21.216563 },
    { lat: 48.723678, lng: 21.216323 },
    { lat: 48.724033, lng: 21.216186 },
    { lat: 48.724243, lng: 21.216132 },
    { lat: 48.724342, lng: 21.216098 },
    { lat: 48.724353, lng: 21.216121 },
    { lat: 48.724467, lng: 21.216483 },
    { lat: 48.724513, lng: 21.216773 },
    { lat: 48.724585, lng: 21.216855 },
    { lat: 48.724633, lng: 21.2168998 },
    { lat: 48.724675, lng: 21.216939 },
    { lat: 48.724832, lng: 21.216934 },
    { lat: 48.725096, lng: 21.217024 },
    { lat: 48.72515, lng: 21.217213 },
    { lat: 48.725251, lng: 21.217276 },
    { lat: 48.725253, lng: 21.217278 },
    { lat: 48.725117, lng: 21.217476 },
    { lat: 48.724898, lng: 21.217825 },
    { lat: 48.724886, lng: 21.21809 },
    { lat: 48.724974, lng: 21.218262 },
    { lat: 48.725122, lng: 21.218608 },
    { lat: 48.725176, lng: 21.218756 },
    { lat: 48.72524, lng: 21.219016 },
    { lat: 48.725291, lng: 21.219016 },
    { lat: 48.725556, lng: 21.219913 },
    { lat: 48.7255345, lng: 21.219924 },
    { lat: 48.725565, lng: 21.219989 },
    { lat: 48.725584, lng: 21.220003 },
    { lat: 48.72577, lng: 21.219945 },
    { lat: 48.725937, lng: 21.219845 },
    { lat: 48.726174, lng: 21.219682 },
    { lat: 48.726584, lng: 21.219317 },
    { lat: 48.726887, lng: 21.219108 },
    { lat: 48.726951, lng: 21.219037 },
    { lat: 48.726966, lng: 21.219065 },
    { lat: 48.726927, lng: 21.21919 },
    { lat: 48.726684, lng: 21.220268 },
    { lat: 48.726683, lng: 21.220634 },
    { lat: 48.72669, lng: 21.221278 },
    { lat: 48.726686, lng: 21.221302 },
    { lat: 48.726602, lng: 21.221532 },
    { lat: 48.72645, lng: 21.221887 },
    { lat: 48.726342, lng: 21.222123 },
    { lat: 48.726114, lng: 21.222638 },
    { lat: 48.725704, lng: 21.22359 },
    { lat: 48.725686, lng: 21.223621 },
    { lat: 48.724775, lng: 21.224525 },
    { lat: 48.72454, lng: 21.22475 },
    { lat: 48.72472, lng: 21.225151 },
    { lat: 48.725014, lng: 21.225692 },
    { lat: 48.724853, lng: 21.226265 },
    { lat: 48.72486, lng: 21.226314 },
    { lat: 48.724859, lng: 21.226317 },
    { lat: 48.724794, lng: 21.226441 },
    { lat: 48.724753, lng: 21.226635 },
    { lat: 48.724717, lng: 21.226892 },
    { lat: 48.724728, lng: 21.22699 },
    { lat: 48.724771, lng: 21.227021 },
    { lat: 48.724881, lng: 21.226969 },
    { lat: 48.724888, lng: 21.227376 },
    { lat: 48.72476, lng: 21.227863 },
    { lat: 48.724702, lng: 21.228261 },
    { lat: 48.724704, lng: 21.229047 },
    { lat: 48.724756, lng: 21.229797 },
    { lat: 48.724769, lng: 21.229846 },
    { lat: 48.724373, lng: 21.230046 },
    { lat: 48.724426, lng: 21.230263 },
    { lat: 48.724498, lng: 21.23023 },
    { lat: 48.724569, lng: 21.230436 },
    { lat: 48.724826, lng: 21.231301 },
    { lat: 48.724848, lng: 21.231579 },
    { lat: 48.724889, lng: 21.231833 },
    { lat: 48.725193, lng: 21.231684 },
    { lat: 48.725194, lng: 21.231687 },
    { lat: 48.725256, lng: 21.231794 },
    { lat: 48.725387, lng: 21.231713 },
    { lat: 48.725613, lng: 21.232348 },
    { lat: 48.725617, lng: 21.232355 },
    { lat: 48.725755, lng: 21.232766 },
    { lat: 48.725775, lng: 21.232898 },
    { lat: 48.725786, lng: 21.232896 },
    { lat: 48.725798, lng: 21.233014 },
    { lat: 48.725808, lng: 21.233014 },
    { lat: 48.725889, lng: 21.233934 },
    { lat: 48.725895, lng: 21.233934 },
    { lat: 48.7259, lng: 21.234241 },
    { lat: 48.725866, lng: 21.234242 },
    { lat: 48.725868, lng: 21.234381 },
    { lat: 48.725864, lng: 21.2343825 },
    { lat: 48.725868, lng: 21.23454 },
    { lat: 48.725841, lng: 21.234541 },
    { lat: 48.725835, lng: 21.234899 },
    { lat: 48.725838, lng: 21.234945 },
    { lat: 48.725842, lng: 21.234945 },
    { lat: 48.725854, lng: 21.235199 },
    { lat: 48.725842, lng: 21.2352 },
    { lat: 48.725844, lng: 21.2352475 },
    { lat: 48.725846, lng: 21.235248 },
    { lat: 48.725839, lng: 21.235381 },
    { lat: 48.725815, lng: 21.235378 },
    { lat: 48.725812, lng: 21.235431 },
    { lat: 48.725837, lng: 21.235435 },
    { lat: 48.725828, lng: 21.235585 },
    { lat: 48.725814, lng: 21.235583 },
    { lat: 48.725812, lng: 21.235636 },
    { lat: 48.725821, lng: 21.235636 },
    { lat: 48.725812, lng: 21.235736 },
    { lat: 48.725804, lng: 21.235736 },
    { lat: 48.725801, lng: 21.235849 },
    { lat: 48.725803, lng: 21.235849 },
    { lat: 48.7257955, lng: 21.236002 },
    { lat: 48.725793, lng: 21.236002 },
    { lat: 48.725793, lng: 21.236035 },
    { lat: 48.725797, lng: 21.236035 },
    { lat: 48.725801, lng: 21.236316 },
    { lat: 48.725813, lng: 21.236392 },
    { lat: 48.725808, lng: 21.236393 },
    { lat: 48.725841, lng: 21.236571 },
    { lat: 48.725833, lng: 21.236574 },
    { lat: 48.725846, lng: 21.23665 },
    { lat: 48.72584, lng: 21.2366535 },
    { lat: 48.725929, lng: 21.237089 },
    { lat: 48.725929, lng: 21.237093 },
    { lat: 48.725951, lng: 21.237197 },
    { lat: 48.725996, lng: 21.237178 },
    { lat: 48.726074, lng: 21.237565 },
    { lat: 48.726073, lng: 21.237775 },
    { lat: 48.726112, lng: 21.237908 },
    { lat: 48.726135, lng: 21.237982 },
    { lat: 48.726138, lng: 21.237978 },
    { lat: 48.726152, lng: 21.238019 },
    { lat: 48.726168, lng: 21.238011 },
    { lat: 48.726251, lng: 21.238173 },
    { lat: 48.726345, lng: 21.238916 },
    { lat: 48.7264197, lng: 21.2389747 },
    { lat: 48.7265863, lng: 21.2392306 },
    { lat: 48.7266619, lng: 21.2393465 },
    { lat: 48.7266966, lng: 21.2394107 },
    { lat: 48.7268042, lng: 21.2396137 },
    { lat: 48.7269011, lng: 21.2397608 },
    { lat: 48.726947, lng: 21.2398292 },
    { lat: 48.7270491, lng: 21.2399977 },
    { lat: 48.7270962, lng: 21.2400692 },
    { lat: 48.7271313, lng: 21.2401088 },
    { lat: 48.7271825, lng: 21.2401622 },
    { lat: 48.7272714, lng: 21.2401288 },
    { lat: 48.7274949, lng: 21.2400381 },
    { lat: 48.7277552, lng: 21.2399426 },
    { lat: 48.7279589, lng: 21.2398679 },
    { lat: 48.7280234, lng: 21.2398466 },
    { lat: 48.728039, lng: 21.2398412 },
    { lat: 48.7280404, lng: 21.2398832 },
    { lat: 48.7280748, lng: 21.2398693 },
    { lat: 48.7280837, lng: 21.2398659 },
    { lat: 48.7281348, lng: 21.2398462 },
    { lat: 48.7281773, lng: 21.240123 },
  ],
  KošicemestskáčasťStaréMesto: [
    { lat: 48.7363353, lng: 21.2731041 },
    { lat: 48.7363202, lng: 21.2731234 },
    { lat: 48.7362792, lng: 21.2729633 },
    { lat: 48.7362296, lng: 21.272775 },
    { lat: 48.7361849, lng: 21.2726042 },
    { lat: 48.7361006, lng: 21.2722757 },
    { lat: 48.73601, lng: 21.2719256 },
    { lat: 48.7358592, lng: 21.271344 },
    { lat: 48.7357388, lng: 21.2708687 },
    { lat: 48.7356631, lng: 21.270566 },
    { lat: 48.7356378, lng: 21.270474 },
    { lat: 48.7355277, lng: 21.2700792 },
    { lat: 48.7354797, lng: 21.2699429 },
    { lat: 48.7353649, lng: 21.2696144 },
    { lat: 48.7352808, lng: 21.2693686 },
    { lat: 48.7352659, lng: 21.2693247 },
    { lat: 48.7352374, lng: 21.269239 },
    { lat: 48.7352201, lng: 21.2691889 },
    { lat: 48.7349872, lng: 21.2684944 },
    { lat: 48.734957, lng: 21.2683928 },
    { lat: 48.734883, lng: 21.2681435 },
    { lat: 48.7348639, lng: 21.2681088 },
    { lat: 48.7348362, lng: 21.2680876 },
    { lat: 48.7348424, lng: 21.2680686 },
    { lat: 48.7348807, lng: 21.2679618 },
    { lat: 48.7348751, lng: 21.2678143 },
    { lat: 48.7349599, lng: 21.2678261 },
    { lat: 48.7349611, lng: 21.2677594 },
    { lat: 48.7349626, lng: 21.2676976 },
    { lat: 48.7349909, lng: 21.2676988 },
    { lat: 48.7349918, lng: 21.2676941 },
    { lat: 48.734996, lng: 21.2674649 },
    { lat: 48.7350017, lng: 21.2672114 },
    { lat: 48.7350051, lng: 21.2670322 },
    { lat: 48.7350047, lng: 21.2670187 },
    { lat: 48.7349799, lng: 21.2670154 },
    { lat: 48.7349641, lng: 21.267013 },
    { lat: 48.7349134, lng: 21.2664283 },
    { lat: 48.7349099, lng: 21.2663879 },
    { lat: 48.7349041, lng: 21.2663103 },
    { lat: 48.7349003, lng: 21.2662603 },
    { lat: 48.7347318, lng: 21.2653891 },
    { lat: 48.7346811, lng: 21.2653516 },
    { lat: 48.7346072, lng: 21.2649633 },
    { lat: 48.7346387, lng: 21.2648908 },
    { lat: 48.7345538, lng: 21.2644344 },
    { lat: 48.7344986, lng: 21.2643976 },
    { lat: 48.7344672, lng: 21.2642544 },
    { lat: 48.7345031, lng: 21.2641799 },
    { lat: 48.7344709, lng: 21.2640107 },
    { lat: 48.7344898, lng: 21.2638599 },
    { lat: 48.7344547, lng: 21.2637034 },
    { lat: 48.7344351, lng: 21.2636146 },
    { lat: 48.7344246, lng: 21.2635665 },
    { lat: 48.7343689, lng: 21.2634022 },
    { lat: 48.7341552, lng: 21.2631128 },
    { lat: 48.7341446, lng: 21.2630985 },
    { lat: 48.7341082, lng: 21.2630487 },
    { lat: 48.7340706, lng: 21.2629975 },
    { lat: 48.733966, lng: 21.2628559 },
    { lat: 48.7339507, lng: 21.2627942 },
    { lat: 48.7339444, lng: 21.2627733 },
    { lat: 48.7339405, lng: 21.2627586 },
    { lat: 48.7339188, lng: 21.2627473 },
    { lat: 48.7337688, lng: 21.2625888 },
    { lat: 48.7337346, lng: 21.2625784 },
    { lat: 48.7336774, lng: 21.2625097 },
    { lat: 48.733666, lng: 21.2624678 },
    { lat: 48.7336357, lng: 21.2624342 },
    { lat: 48.733617, lng: 21.262412 },
    { lat: 48.7335272, lng: 21.2623093 },
    { lat: 48.7334572, lng: 21.2622322 },
    { lat: 48.7332101, lng: 21.2619544 },
    { lat: 48.7330422, lng: 21.2617666 },
    { lat: 48.7329338, lng: 21.2616438 },
    { lat: 48.7329245, lng: 21.2616331 },
    { lat: 48.7326025, lng: 21.261265 },
    { lat: 48.7325671, lng: 21.2612504 },
    { lat: 48.7325753, lng: 21.2611875 },
    { lat: 48.7325871, lng: 21.2610957 },
    { lat: 48.732589, lng: 21.2610813 },
    { lat: 48.7325962, lng: 21.2610238 },
    { lat: 48.7325898, lng: 21.2608888 },
    { lat: 48.7325552, lng: 21.2602417 },
    { lat: 48.7325425, lng: 21.2599745 },
    { lat: 48.7324336, lng: 21.2576504 },
    { lat: 48.7324196, lng: 21.2573466 },
    { lat: 48.7323502, lng: 21.255825 },
    { lat: 48.7323439, lng: 21.2558037 },
    { lat: 48.7323204, lng: 21.2556602 },
    { lat: 48.7323032, lng: 21.2554333 },
    { lat: 48.732302, lng: 21.255032 },
    { lat: 48.7322891, lng: 21.2549751 },
    { lat: 48.7322653, lng: 21.2547159 },
    { lat: 48.732264, lng: 21.2547088 },
    { lat: 48.7322542, lng: 21.2546475 },
    { lat: 48.7322419, lng: 21.2545767 },
    { lat: 48.732213, lng: 21.2544112 },
    { lat: 48.7321721, lng: 21.2542148 },
    { lat: 48.7321116, lng: 21.254007 },
    { lat: 48.7320467, lng: 21.2537994 },
    { lat: 48.7320333, lng: 21.253764 },
    { lat: 48.7320099, lng: 21.2537001 },
    { lat: 48.7319731, lng: 21.2536 },
    { lat: 48.731951, lng: 21.2535396 },
    { lat: 48.7319481, lng: 21.2535219 },
    { lat: 48.7318609, lng: 21.2533217 },
    { lat: 48.7318318, lng: 21.2532542 },
    { lat: 48.7317243, lng: 21.2529816 },
    { lat: 48.7316841, lng: 21.2528811 },
    { lat: 48.7316792, lng: 21.2528693 },
    { lat: 48.7315424, lng: 21.2525218 },
    { lat: 48.7314364, lng: 21.2522566 },
    { lat: 48.7314278, lng: 21.2522347 },
    { lat: 48.731418, lng: 21.2522094 },
    { lat: 48.7313899, lng: 21.252139 },
    { lat: 48.7313802, lng: 21.2521147 },
    { lat: 48.7312545, lng: 21.2517964 },
    { lat: 48.7312203, lng: 21.25171 },
    { lat: 48.7312118, lng: 21.2516896 },
    { lat: 48.7311556, lng: 21.2515481 },
    { lat: 48.7311483, lng: 21.2515287 },
    { lat: 48.731091, lng: 21.2513862 },
    { lat: 48.7310837, lng: 21.2513675 },
    { lat: 48.7309528, lng: 21.2510386 },
    { lat: 48.7309151, lng: 21.2509427 },
    { lat: 48.7309305, lng: 21.2509295 },
    { lat: 48.7309404, lng: 21.2509224 },
    { lat: 48.7310203, lng: 21.2508871 },
    { lat: 48.7310293, lng: 21.2508833 },
    { lat: 48.7310481, lng: 21.2508748 },
    { lat: 48.7312385, lng: 21.2507017 },
    { lat: 48.7313995, lng: 21.2505548 },
    { lat: 48.7315439, lng: 21.2504234 },
    { lat: 48.7315629, lng: 21.2504157 },
    { lat: 48.7316529, lng: 21.2503801 },
    { lat: 48.7314948, lng: 21.2499264 },
    { lat: 48.7314639, lng: 21.2498401 },
    { lat: 48.7315056, lng: 21.249804 },
    { lat: 48.7314922, lng: 21.2498046 },
    { lat: 48.7314808, lng: 21.2498012 },
    { lat: 48.7314714, lng: 21.2497883 },
    { lat: 48.7314366, lng: 21.249681 },
    { lat: 48.7314279, lng: 21.2496534 },
    { lat: 48.7313894, lng: 21.2495374 },
    { lat: 48.7313831, lng: 21.2495165 },
    { lat: 48.7313545, lng: 21.2494294 },
    { lat: 48.731337, lng: 21.2493743 },
    { lat: 48.731316, lng: 21.2493123 },
    { lat: 48.7312836, lng: 21.2492112 },
    { lat: 48.7312625, lng: 21.2491493 },
    { lat: 48.7312164, lng: 21.2490057 },
    { lat: 48.7310561, lng: 21.2485479 },
    { lat: 48.7310449, lng: 21.2485152 },
    { lat: 48.7309781, lng: 21.2483224 },
    { lat: 48.7309665, lng: 21.2482762 },
    { lat: 48.7310093, lng: 21.2482418 },
    { lat: 48.7309635, lng: 21.2481074 },
    { lat: 48.7309619, lng: 21.2480533 },
    { lat: 48.7309508, lng: 21.2480213 },
    { lat: 48.7309434, lng: 21.2480019 },
    { lat: 48.7307888, lng: 21.2475501 },
    { lat: 48.7307716, lng: 21.2474992 },
    { lat: 48.7307342, lng: 21.2473872 },
    { lat: 48.7306884, lng: 21.2472531 },
    { lat: 48.7305484, lng: 21.2468426 },
    { lat: 48.730531, lng: 21.2467916 },
    { lat: 48.7302466, lng: 21.2459579 },
    { lat: 48.7302338, lng: 21.2459165 },
    { lat: 48.7302216, lng: 21.2458788 },
    { lat: 48.7301943, lng: 21.2457984 },
    { lat: 48.7301427, lng: 21.2456502 },
    { lat: 48.7299743, lng: 21.2451627 },
    { lat: 48.7299644, lng: 21.2451309 },
    { lat: 48.7299282, lng: 21.2450166 },
    { lat: 48.7299232, lng: 21.245002 },
    { lat: 48.7299046, lng: 21.2449455 },
    { lat: 48.7298945, lng: 21.244912 },
    { lat: 48.729813, lng: 21.24468 },
    { lat: 48.7296686, lng: 21.2442653 },
    { lat: 48.7296499, lng: 21.2442114 },
    { lat: 48.7296338, lng: 21.2441644 },
    { lat: 48.7295004, lng: 21.2437778 },
    { lat: 48.7294497, lng: 21.2436317 },
    { lat: 48.729446, lng: 21.2436199 },
    { lat: 48.7294188, lng: 21.243543 },
    { lat: 48.729415, lng: 21.2435308 },
    { lat: 48.7293718, lng: 21.2434052 },
    { lat: 48.7293581, lng: 21.2433637 },
    { lat: 48.7292029, lng: 21.2428935 },
    { lat: 48.7291966, lng: 21.2428733 },
    { lat: 48.7291854, lng: 21.2428398 },
    { lat: 48.7290116, lng: 21.2423146 },
    { lat: 48.7290029, lng: 21.2422888 },
    { lat: 48.7289779, lng: 21.2422061 },
    { lat: 48.7289716, lng: 21.2421845 },
    { lat: 48.7287466, lng: 21.2415335 },
    { lat: 48.7286736, lng: 21.2413205 },
    { lat: 48.7286251, lng: 21.241356 },
    { lat: 48.7286153, lng: 21.2413307 },
    { lat: 48.7286019, lng: 21.2412992 },
    { lat: 48.728597, lng: 21.2412845 },
    { lat: 48.7285395, lng: 21.2411011 },
    { lat: 48.7285101, lng: 21.2409856 },
    { lat: 48.7284987, lng: 21.2408388 },
    { lat: 48.7284902, lng: 21.2408137 },
    { lat: 48.7284778, lng: 21.2407786 },
    { lat: 48.7283577, lng: 21.2404289 },
    { lat: 48.7283405, lng: 21.2403784 },
    { lat: 48.7282166, lng: 21.240014 },
    { lat: 48.7281847, lng: 21.2400394 },
    { lat: 48.7281773, lng: 21.240123 },
    { lat: 48.7281348, lng: 21.2398462 },
    { lat: 48.7280837, lng: 21.2398659 },
    { lat: 48.7280748, lng: 21.2398693 },
    { lat: 48.7280404, lng: 21.2398832 },
    { lat: 48.728039, lng: 21.2398412 },
    { lat: 48.7280234, lng: 21.2398466 },
    { lat: 48.7279589, lng: 21.2398679 },
    { lat: 48.7277552, lng: 21.2399426 },
    { lat: 48.7274949, lng: 21.2400381 },
    { lat: 48.7272714, lng: 21.2401288 },
    { lat: 48.7271825, lng: 21.2401622 },
    { lat: 48.7271313, lng: 21.2401088 },
    { lat: 48.7270962, lng: 21.2400692 },
    { lat: 48.7270491, lng: 21.2399977 },
    { lat: 48.726947, lng: 21.2398292 },
    { lat: 48.7269011, lng: 21.2397608 },
    { lat: 48.7268042, lng: 21.2396137 },
    { lat: 48.7266966, lng: 21.2394107 },
    { lat: 48.7266619, lng: 21.2393465 },
    { lat: 48.7265863, lng: 21.2392306 },
    { lat: 48.7264197, lng: 21.2389747 },
    { lat: 48.726345, lng: 21.238916 },
    { lat: 48.7261664, lng: 21.2391815 },
    { lat: 48.726059, lng: 21.2398845 },
    { lat: 48.725248, lng: 21.240528 },
    { lat: 48.724658, lng: 21.240983 },
    { lat: 48.7245876, lng: 21.2410197 },
    { lat: 48.7243674, lng: 21.2411344 },
    { lat: 48.7241702, lng: 21.2412372 },
    { lat: 48.724138, lng: 21.241254 },
    { lat: 48.7241187, lng: 21.2412768 },
    { lat: 48.723652, lng: 21.241828 },
    { lat: 48.723301, lng: 21.2421237 },
    { lat: 48.7232942, lng: 21.2421295 },
    { lat: 48.7232874, lng: 21.2421352 },
    { lat: 48.7230745, lng: 21.2423146 },
    { lat: 48.722853, lng: 21.242464 },
    { lat: 48.722549, lng: 21.242615 },
    { lat: 48.722278, lng: 21.242681 },
    { lat: 48.721135, lng: 21.243121 },
    { lat: 48.720701, lng: 21.243295 },
    { lat: 48.720485, lng: 21.243388 },
    { lat: 48.720447, lng: 21.244026 },
    { lat: 48.719649, lng: 21.243921 },
    { lat: 48.7195654, lng: 21.2439429 },
    { lat: 48.719586, lng: 21.24412 },
    { lat: 48.71955, lng: 21.244286 },
    { lat: 48.719543, lng: 21.244346 },
    { lat: 48.719421, lng: 21.244313 },
    { lat: 48.719411, lng: 21.244435 },
    { lat: 48.719363, lng: 21.244419 },
    { lat: 48.7192105, lng: 21.244146 },
    { lat: 48.7191452, lng: 21.2440985 },
    { lat: 48.7188712, lng: 21.2442531 },
    { lat: 48.718756, lng: 21.244408 },
    { lat: 48.718375, lng: 21.244793 },
    { lat: 48.718222, lng: 21.244421 },
    { lat: 48.717864, lng: 21.244739 },
    { lat: 48.717695, lng: 21.243939 },
    { lat: 48.717675, lng: 21.243743 },
    { lat: 48.717518, lng: 21.243195 },
    { lat: 48.717363, lng: 21.2428585 },
    { lat: 48.717211, lng: 21.242888 },
    { lat: 48.717171, lng: 21.242914 },
    { lat: 48.717142, lng: 21.242858 },
    { lat: 48.716986, lng: 21.243079 },
    { lat: 48.71671, lng: 21.243683 },
    { lat: 48.716706, lng: 21.24368 },
    { lat: 48.716657, lng: 21.243793 },
    { lat: 48.716391, lng: 21.243518 },
    { lat: 48.716319, lng: 21.2434185 },
    { lat: 48.716243, lng: 21.24354 },
    { lat: 48.716225, lng: 21.243669 },
    { lat: 48.716605, lng: 21.244519 },
    { lat: 48.716609, lng: 21.244525 },
    { lat: 48.7167212, lng: 21.2447437 },
    { lat: 48.7167959, lng: 21.2448486 },
    { lat: 48.716906, lng: 21.245001 },
    { lat: 48.716977, lng: 21.245094 },
    { lat: 48.717118, lng: 21.245238 },
    { lat: 48.717247, lng: 21.245382 },
    { lat: 48.717262, lng: 21.245434 },
    { lat: 48.7168649, lng: 21.2457432 },
    { lat: 48.7167976, lng: 21.2455874 },
    { lat: 48.7165799, lng: 21.245084 },
    { lat: 48.7162789, lng: 21.2452252 },
    { lat: 48.716257, lng: 21.245231 },
    { lat: 48.716331, lng: 21.245635 },
    { lat: 48.716346, lng: 21.246037 },
    { lat: 48.71635, lng: 21.246303 },
    { lat: 48.716333, lng: 21.246379 },
    { lat: 48.71631, lng: 21.246401 },
    { lat: 48.716311, lng: 21.24642 },
    { lat: 48.716308, lng: 21.24643 },
    { lat: 48.716134, lng: 21.246327 },
    { lat: 48.716066, lng: 21.246295 },
    { lat: 48.716056, lng: 21.246325 },
    { lat: 48.71602, lng: 21.24648 },
    { lat: 48.7159905, lng: 21.2462085 },
    { lat: 48.715945, lng: 21.24612 },
    { lat: 48.715907, lng: 21.246097 },
    { lat: 48.715759, lng: 21.246158 },
    { lat: 48.715332, lng: 21.246516 },
    { lat: 48.7151307, lng: 21.2466673 },
    { lat: 48.7150726, lng: 21.2467444 },
    { lat: 48.715017, lng: 21.246996 },
    { lat: 48.714863, lng: 21.247556 },
    { lat: 48.715305, lng: 21.247713 },
    { lat: 48.71548, lng: 21.247774 },
    { lat: 48.715482, lng: 21.247767 },
    { lat: 48.715502, lng: 21.247774 },
    { lat: 48.715501, lng: 21.247784 },
    { lat: 48.715665, lng: 21.247841 },
    { lat: 48.715665, lng: 21.247842 },
    { lat: 48.715666, lng: 21.247837 },
    { lat: 48.715671, lng: 21.247798 },
    { lat: 48.715693, lng: 21.247812 },
    { lat: 48.715708, lng: 21.247824 },
    { lat: 48.715717, lng: 21.247832 },
    { lat: 48.715732, lng: 21.247843 },
    { lat: 48.715754, lng: 21.247861 },
    { lat: 48.715778, lng: 21.247886 },
    { lat: 48.715796, lng: 21.247907 },
    { lat: 48.7158391, lng: 21.247964 },
    { lat: 48.7159084, lng: 21.2480725 },
    { lat: 48.715923, lng: 21.248111 },
    { lat: 48.715934, lng: 21.248137 },
    { lat: 48.715957, lng: 21.248204 },
    { lat: 48.715974, lng: 21.248253 },
    { lat: 48.71599, lng: 21.248313 },
    { lat: 48.715991, lng: 21.24832 },
    { lat: 48.716113, lng: 21.248843 },
    { lat: 48.716129, lng: 21.248908 },
    { lat: 48.71612, lng: 21.248909 },
    { lat: 48.716123, lng: 21.248949 },
    { lat: 48.716374, lng: 21.250029 },
    { lat: 48.71638, lng: 21.250075 },
    { lat: 48.71638, lng: 21.250118 },
    { lat: 48.716372, lng: 21.25015 },
    { lat: 48.716363, lng: 21.250168 },
    { lat: 48.716341, lng: 21.250191 },
    { lat: 48.716373, lng: 21.250174 },
    { lat: 48.71638, lng: 21.25017 },
    { lat: 48.716388, lng: 21.250212 },
    { lat: 48.716403, lng: 21.250348 },
    { lat: 48.716405, lng: 21.250368 },
    { lat: 48.716413, lng: 21.25042 },
    { lat: 48.716453, lng: 21.25068 },
    { lat: 48.716464, lng: 21.250681 },
    { lat: 48.7164315, lng: 21.2510238 },
    { lat: 48.7164043, lng: 21.2514103 },
    { lat: 48.716396, lng: 21.251505 },
    { lat: 48.716377, lng: 21.251735 },
    { lat: 48.716369, lng: 21.251832 },
    { lat: 48.716351, lng: 21.252061 },
    { lat: 48.716312, lng: 21.252565 },
    { lat: 48.716277, lng: 21.252962 },
    { lat: 48.716272, lng: 21.253015 },
    { lat: 48.716265, lng: 21.253093 },
    { lat: 48.716262, lng: 21.253138 },
    { lat: 48.716258, lng: 21.253192 },
    { lat: 48.71624, lng: 21.253406 },
    { lat: 48.716234, lng: 21.25348 },
    { lat: 48.716222, lng: 21.253632 },
    { lat: 48.716214, lng: 21.253728 },
    { lat: 48.716209, lng: 21.253793 },
    { lat: 48.716205, lng: 21.253844 },
    { lat: 48.716198, lng: 21.253928 },
    { lat: 48.716184, lng: 21.254101 },
    { lat: 48.716178, lng: 21.254167 },
    { lat: 48.716169, lng: 21.254277 },
    { lat: 48.716164, lng: 21.254336 },
    { lat: 48.716161, lng: 21.254371 },
    { lat: 48.716153, lng: 21.25446 },
    { lat: 48.716122, lng: 21.254837 },
    { lat: 48.716127, lng: 21.254838 },
    { lat: 48.71612, lng: 21.254923 },
    { lat: 48.716116, lng: 21.254922 },
    { lat: 48.716098, lng: 21.255143 },
    { lat: 48.716093, lng: 21.255207 },
    { lat: 48.716091, lng: 21.255227 },
    { lat: 48.716055, lng: 21.255665 },
    { lat: 48.716024, lng: 21.256051 },
    { lat: 48.716017, lng: 21.256089 },
    { lat: 48.716014, lng: 21.256106 },
    { lat: 48.715997, lng: 21.256207 },
    { lat: 48.716004, lng: 21.256352 },
    { lat: 48.716006, lng: 21.256383 },
    { lat: 48.716021, lng: 21.256611 },
    { lat: 48.716036, lng: 21.256843 },
    { lat: 48.716051, lng: 21.25708 },
    { lat: 48.716076, lng: 21.25749 },
    { lat: 48.7161, lng: 21.257709 },
    { lat: 48.716108, lng: 21.257789 },
    { lat: 48.716123, lng: 21.257944 },
    { lat: 48.716135, lng: 21.258065 },
    { lat: 48.716137, lng: 21.258083 },
    { lat: 48.71614, lng: 21.25812 },
    { lat: 48.716148, lng: 21.258196 },
    { lat: 48.716156, lng: 21.258273 },
    { lat: 48.716156, lng: 21.258278 },
    { lat: 48.716159, lng: 21.258278 },
    { lat: 48.716167, lng: 21.258357 },
    { lat: 48.716163, lng: 21.258358 },
    { lat: 48.716181, lng: 21.258518 },
    { lat: 48.716183, lng: 21.258517 },
    { lat: 48.716183, lng: 21.258518 },
    { lat: 48.71619, lng: 21.258601 },
    { lat: 48.716188, lng: 21.258602 },
    { lat: 48.716188, lng: 21.258603 },
    { lat: 48.716189, lng: 21.25861 },
    { lat: 48.716189, lng: 21.258611 },
    { lat: 48.716189, lng: 21.258615 },
    { lat: 48.716192, lng: 21.258615 },
    { lat: 48.716197, lng: 21.258675 },
    { lat: 48.716196, lng: 21.258676 },
    { lat: 48.716194, lng: 21.258676 },
    { lat: 48.716215, lng: 21.258913 },
    { lat: 48.71622, lng: 21.258913 },
    { lat: 48.716227, lng: 21.258992 },
    { lat: 48.716232, lng: 21.259044 },
    { lat: 48.716233, lng: 21.259058 },
    { lat: 48.716248, lng: 21.259204 },
    { lat: 48.71625, lng: 21.259221 },
    { lat: 48.716253, lng: 21.259254 },
    { lat: 48.716263, lng: 21.259403 },
    { lat: 48.716265, lng: 21.259434 },
    { lat: 48.716281, lng: 21.259647 },
    { lat: 48.716295, lng: 21.259831 },
    { lat: 48.71631, lng: 21.260028 },
    { lat: 48.716348, lng: 21.260523 },
    { lat: 48.716326, lng: 21.260568 },
    { lat: 48.716329, lng: 21.260631 },
    { lat: 48.716459, lng: 21.26097 },
    { lat: 48.716474, lng: 21.261008 },
    { lat: 48.716494, lng: 21.261007 },
    { lat: 48.716508, lng: 21.261012 },
    { lat: 48.716524, lng: 21.261018 },
    { lat: 48.716525, lng: 21.261018 },
    { lat: 48.716526, lng: 21.261019 },
    { lat: 48.716527, lng: 21.261019 },
    { lat: 48.716557, lng: 21.261038 },
    { lat: 48.716581, lng: 21.261069 },
    { lat: 48.716606, lng: 21.261111 },
    { lat: 48.716634, lng: 21.261188 },
    { lat: 48.716673, lng: 21.26128 },
    { lat: 48.716724, lng: 21.261404 },
    { lat: 48.716738, lng: 21.261437 },
    { lat: 48.716973, lng: 21.262001 },
    { lat: 48.716976, lng: 21.262011 },
    { lat: 48.716986, lng: 21.262035 },
    { lat: 48.717174, lng: 21.262488 },
    { lat: 48.717179, lng: 21.262523 },
    { lat: 48.717173, lng: 21.262539 },
    { lat: 48.717169, lng: 21.26255 },
    { lat: 48.717165, lng: 21.262554 },
    { lat: 48.717156, lng: 21.262561 },
    { lat: 48.717143, lng: 21.262565 },
    { lat: 48.717137, lng: 21.262558 },
    { lat: 48.717122, lng: 21.262585 },
    { lat: 48.717159, lng: 21.262645 },
    { lat: 48.717209, lng: 21.262725 },
    { lat: 48.717243, lng: 21.262873 },
    { lat: 48.717118, lng: 21.262971 },
    { lat: 48.71719, lng: 21.262999 },
    { lat: 48.717236, lng: 21.262936 },
    { lat: 48.717417, lng: 21.263291 },
    { lat: 48.7175423, lng: 21.2635755 },
    { lat: 48.7175554, lng: 21.2636099 },
    { lat: 48.717569, lng: 21.2636467 },
    { lat: 48.717651, lng: 21.263867 },
    { lat: 48.717711, lng: 21.26406 },
    { lat: 48.717731, lng: 21.264148 },
    { lat: 48.71785, lng: 21.264688 },
    { lat: 48.717872, lng: 21.264787 },
    { lat: 48.717921, lng: 21.265071 },
    { lat: 48.717924, lng: 21.26509 },
    { lat: 48.717976, lng: 21.265395 },
    { lat: 48.717989, lng: 21.265465 },
    { lat: 48.718011, lng: 21.265596 },
    { lat: 48.718089, lng: 21.26607 },
    { lat: 48.718086, lng: 21.266114 },
    { lat: 48.718093, lng: 21.266122 },
    { lat: 48.7181, lng: 21.266134 },
    { lat: 48.718104, lng: 21.266148 },
    { lat: 48.718129, lng: 21.266296 },
    { lat: 48.718147, lng: 21.266404 },
    { lat: 48.718169, lng: 21.266537 },
    { lat: 48.718209, lng: 21.266784 },
    { lat: 48.71817, lng: 21.266801 },
    { lat: 48.718188, lng: 21.266911 },
    { lat: 48.718191, lng: 21.26691 },
    { lat: 48.718198, lng: 21.266953 },
    { lat: 48.718194, lng: 21.266955 },
    { lat: 48.718215, lng: 21.267067 },
    { lat: 48.718231, lng: 21.267174 },
    { lat: 48.718235, lng: 21.267173 },
    { lat: 48.718242, lng: 21.267218 },
    { lat: 48.71824, lng: 21.26722 },
    { lat: 48.718258, lng: 21.267329 },
    { lat: 48.718276, lng: 21.267441 },
    { lat: 48.718279, lng: 21.267439 },
    { lat: 48.718287, lng: 21.267488 },
    { lat: 48.718284, lng: 21.26749 },
    { lat: 48.718303, lng: 21.267606 },
    { lat: 48.718343, lng: 21.267838 },
    { lat: 48.7184, lng: 21.268167 },
    { lat: 48.718439, lng: 21.268406 },
    { lat: 48.718475, lng: 21.268629 },
    { lat: 48.718488, lng: 21.268665 },
    { lat: 48.718543, lng: 21.268823 },
    { lat: 48.718555, lng: 21.268805 },
    { lat: 48.718587, lng: 21.268996 },
    { lat: 48.718611, lng: 21.26915 },
    { lat: 48.718628, lng: 21.269258 },
    { lat: 48.718629, lng: 21.269266 },
    { lat: 48.718617, lng: 21.269273 },
    { lat: 48.718683, lng: 21.269663 },
    { lat: 48.7186872, lng: 21.2696987 },
    { lat: 48.718762, lng: 21.270143 },
    { lat: 48.71877, lng: 21.270188 },
    { lat: 48.71878, lng: 21.270251 },
    { lat: 48.718798, lng: 21.270357 },
    { lat: 48.718861, lng: 21.270721 },
    { lat: 48.718875, lng: 21.270941 },
    { lat: 48.718878, lng: 21.270983 },
    { lat: 48.718919, lng: 21.271113 },
    { lat: 48.718978, lng: 21.271278 },
    { lat: 48.718936, lng: 21.271296 },
    { lat: 48.719038, lng: 21.271418 },
    { lat: 48.719066, lng: 21.271482 },
    { lat: 48.71908, lng: 21.271509 },
    { lat: 48.719184, lng: 21.271669 },
    { lat: 48.719273, lng: 21.271864 },
    { lat: 48.719314, lng: 21.271928 },
    { lat: 48.719392, lng: 21.272048 },
    { lat: 48.719568, lng: 21.272298 },
    { lat: 48.719836, lng: 21.2726 },
    { lat: 48.719965, lng: 21.272745 },
    { lat: 48.719983, lng: 21.272764 },
    { lat: 48.720005, lng: 21.272789 },
    { lat: 48.720058, lng: 21.27285 },
    { lat: 48.720071, lng: 21.272864 },
    { lat: 48.720127, lng: 21.272928 },
    { lat: 48.720158, lng: 21.272961 },
    { lat: 48.720244, lng: 21.273054 },
    { lat: 48.720341, lng: 21.273163 },
    { lat: 48.720471, lng: 21.273317 },
    { lat: 48.720638, lng: 21.273508 },
    { lat: 48.720693, lng: 21.273572 },
    { lat: 48.720711, lng: 21.273592 },
    { lat: 48.7208, lng: 21.273694 },
    { lat: 48.720836, lng: 21.273737 },
    { lat: 48.720983, lng: 21.273906 },
    { lat: 48.721004, lng: 21.273931 },
    { lat: 48.721064, lng: 21.273998 },
    { lat: 48.721253, lng: 21.274218 },
    { lat: 48.721318, lng: 21.274294 },
    { lat: 48.721332, lng: 21.274311 },
    { lat: 48.721459, lng: 21.274459 },
    { lat: 48.721506, lng: 21.274512 },
    { lat: 48.721509, lng: 21.274516 },
    { lat: 48.72152, lng: 21.274527 },
    { lat: 48.721616, lng: 21.274635 },
    { lat: 48.721633, lng: 21.274656 },
    { lat: 48.721718, lng: 21.274752 },
    { lat: 48.721736, lng: 21.274772 },
    { lat: 48.721777, lng: 21.274802 },
    { lat: 48.721804, lng: 21.27482 },
    { lat: 48.721813, lng: 21.274827 },
    { lat: 48.721821, lng: 21.274807 },
    { lat: 48.721856, lng: 21.274725 },
    { lat: 48.721865, lng: 21.274702 },
    { lat: 48.721872, lng: 21.274685 },
    { lat: 48.721881, lng: 21.274693 },
    { lat: 48.721928, lng: 21.274736 },
    { lat: 48.722061, lng: 21.27486 },
    { lat: 48.722079, lng: 21.274853 },
    { lat: 48.722078, lng: 21.274874 },
    { lat: 48.722221, lng: 21.275006 },
    { lat: 48.722238, lng: 21.275001 },
    { lat: 48.722234, lng: 21.27502 },
    { lat: 48.722362, lng: 21.275141 },
    { lat: 48.722378, lng: 21.275155 },
    { lat: 48.722367, lng: 21.275174 },
    { lat: 48.722463, lng: 21.275307 },
    { lat: 48.722721, lng: 21.275535 },
    { lat: 48.722933, lng: 21.275716 },
    { lat: 48.72318, lng: 21.275841 },
    { lat: 48.723439, lng: 21.275952 },
    { lat: 48.723986, lng: 21.276157 },
    { lat: 48.724045, lng: 21.276136 },
    { lat: 48.724073, lng: 21.276126 },
    { lat: 48.724266, lng: 21.276229 },
    { lat: 48.724539, lng: 21.276321 },
    { lat: 48.724645, lng: 21.276363 },
    { lat: 48.724892, lng: 21.276462 },
    { lat: 48.724923, lng: 21.276474 },
    { lat: 48.725768, lng: 21.276791 },
    { lat: 48.726233, lng: 21.276952 },
    { lat: 48.726211, lng: 21.276823 },
    { lat: 48.726447, lng: 21.276874 },
    { lat: 48.726731, lng: 21.276932 },
    { lat: 48.726732, lng: 21.276948 },
    { lat: 48.726765, lng: 21.276946 },
    { lat: 48.726765, lng: 21.276941 },
    { lat: 48.727626, lng: 21.277052 },
    { lat: 48.727954, lng: 21.277089 },
    { lat: 48.728131, lng: 21.27705 },
    { lat: 48.728485, lng: 21.27706 },
    { lat: 48.728824, lng: 21.277084 },
    { lat: 48.729059, lng: 21.277097 },
    { lat: 48.729099, lng: 21.277095 },
    { lat: 48.729142, lng: 21.277094 },
    { lat: 48.729169, lng: 21.277115 },
    { lat: 48.729209, lng: 21.277146 },
    { lat: 48.729307, lng: 21.277221 },
    { lat: 48.729368, lng: 21.277269 },
    { lat: 48.729333, lng: 21.277214 },
    { lat: 48.729304, lng: 21.277118 },
    { lat: 48.729327, lng: 21.277096 },
    { lat: 48.729338, lng: 21.277106 },
    { lat: 48.729358, lng: 21.277113 },
    { lat: 48.729411, lng: 21.277111 },
    { lat: 48.729504, lng: 21.277107 },
    { lat: 48.7296, lng: 21.277102 },
    { lat: 48.729645, lng: 21.277098 },
    { lat: 48.729706, lng: 21.277093 },
    { lat: 48.729816, lng: 21.277081 },
    { lat: 48.72988, lng: 21.277074 },
    { lat: 48.73005, lng: 21.277052 },
    { lat: 48.730084, lng: 21.277047 },
    { lat: 48.730173, lng: 21.277032 },
    { lat: 48.730269, lng: 21.277014 },
    { lat: 48.730404, lng: 21.276984 },
    { lat: 48.730549, lng: 21.276948 },
    { lat: 48.730639, lng: 21.276923 },
    { lat: 48.730689, lng: 21.276908 },
    { lat: 48.730766, lng: 21.276885 },
    { lat: 48.730888, lng: 21.276844 },
    { lat: 48.730927, lng: 21.27683 },
    { lat: 48.73099, lng: 21.276809 },
    { lat: 48.731073, lng: 21.27678 },
    { lat: 48.731065, lng: 21.276749 },
    { lat: 48.731161, lng: 21.276717 },
    { lat: 48.731266, lng: 21.276665 },
    { lat: 48.731377, lng: 21.276611 },
    { lat: 48.731516, lng: 21.276545 },
    { lat: 48.731568, lng: 21.276521 },
    { lat: 48.731661, lng: 21.276477 },
    { lat: 48.731736, lng: 21.276441 },
    { lat: 48.73182, lng: 21.276408 },
    { lat: 48.731984, lng: 21.27631 },
    { lat: 48.732139, lng: 21.2762 },
    { lat: 48.732186, lng: 21.27618 },
    { lat: 48.732294, lng: 21.276135 },
    { lat: 48.732358, lng: 21.276221 },
    { lat: 48.732466, lng: 21.276152 },
    { lat: 48.732445, lng: 21.27614 },
    { lat: 48.732431, lng: 21.276117 },
    { lat: 48.73244, lng: 21.27611 },
    { lat: 48.732808, lng: 21.27582 },
    { lat: 48.733103, lng: 21.275579 },
    { lat: 48.733708, lng: 21.275013 },
    { lat: 48.733733, lng: 21.274976 },
    { lat: 48.73375, lng: 21.274925 },
    { lat: 48.733747, lng: 21.27487 },
    { lat: 48.733747, lng: 21.274864 },
    { lat: 48.73374, lng: 21.274797 },
    { lat: 48.733757, lng: 21.274784 },
    { lat: 48.733803, lng: 21.27474 },
    { lat: 48.733857, lng: 21.274767 },
    { lat: 48.733881, lng: 21.274779 },
    { lat: 48.734169, lng: 21.274525 },
    { lat: 48.73442, lng: 21.274345 },
    { lat: 48.734794, lng: 21.274089 },
    { lat: 48.735555, lng: 21.273815 },
    { lat: 48.735819, lng: 21.273724 },
    { lat: 48.736124, lng: 21.27363 },
    { lat: 48.7362, lng: 21.273601 },
    { lat: 48.736244, lng: 21.273572 },
    { lat: 48.736308, lng: 21.27348 },
    { lat: 48.736345, lng: 21.273379 },
    { lat: 48.736356, lng: 21.273259 },
    { lat: 48.736353, lng: 21.27323 },
    { lat: 48.736347, lng: 21.273162 },
    { lat: 48.7363353, lng: 21.2731041 },
  ],
  KošicemestskáčasťSídliskoŤahanovce: [
    { lat: 48.7442733, lng: 21.257312 },
    { lat: 48.7442029, lng: 21.2573266 },
    { lat: 48.7437332, lng: 21.2574229 },
    { lat: 48.7435899, lng: 21.2574487 },
    { lat: 48.743356, lng: 21.2575287 },
    { lat: 48.7430988, lng: 21.257616 },
    { lat: 48.7429054, lng: 21.2576589 },
    { lat: 48.7423767, lng: 21.2578478 },
    { lat: 48.7423222, lng: 21.2578669 },
    { lat: 48.7421909, lng: 21.2578812 },
    { lat: 48.7415541, lng: 21.2582139 },
    { lat: 48.7415322, lng: 21.258235 },
    { lat: 48.7415565, lng: 21.2582939 },
    { lat: 48.7415413, lng: 21.2583078 },
    { lat: 48.7415314, lng: 21.2583157 },
    { lat: 48.7418183, lng: 21.2589365 },
    { lat: 48.7418619, lng: 21.2591777 },
    { lat: 48.7419335, lng: 21.2593869 },
    { lat: 48.7420573, lng: 21.2597141 },
    { lat: 48.7421829, lng: 21.2603541 },
    { lat: 48.7422487, lng: 21.2607759 },
    { lat: 48.7422559, lng: 21.2608617 },
    { lat: 48.7422649, lng: 21.2609709 },
    { lat: 48.7422786, lng: 21.261124 },
    { lat: 48.7422808, lng: 21.261158 },
    { lat: 48.7422842, lng: 21.2611942 },
    { lat: 48.7423054, lng: 21.2611898 },
    { lat: 48.742324, lng: 21.2614253 },
    { lat: 48.742331, lng: 21.2615086 },
    { lat: 48.7423332, lng: 21.2615423 },
    { lat: 48.7423367, lng: 21.2615813 },
    { lat: 48.7423453, lng: 21.2616826 },
    { lat: 48.7423474, lng: 21.2617099 },
    { lat: 48.7423593, lng: 21.261881 },
    { lat: 48.7423726, lng: 21.2620544 },
    { lat: 48.7423761, lng: 21.2620962 },
    { lat: 48.74238, lng: 21.2621494 },
    { lat: 48.7423856, lng: 21.2622189 },
    { lat: 48.7423892, lng: 21.2622645 },
    { lat: 48.7423985, lng: 21.262382 },
    { lat: 48.7424006, lng: 21.2624154 },
    { lat: 48.7424326, lng: 21.2628695 },
    { lat: 48.7424417, lng: 21.2629787 },
    { lat: 48.7424502, lng: 21.263109 },
    { lat: 48.7424523, lng: 21.2631427 },
    { lat: 48.7424586, lng: 21.2631819 },
    { lat: 48.7424599, lng: 21.2632413 },
    { lat: 48.7424699, lng: 21.2633846 },
    { lat: 48.7424496, lng: 21.2633853 },
    { lat: 48.7424644, lng: 21.2634257 },
    { lat: 48.7424649, lng: 21.2634413 },
    { lat: 48.7424784, lng: 21.2637734 },
    { lat: 48.7424794, lng: 21.2638068 },
    { lat: 48.7425275, lng: 21.2643793 },
    { lat: 48.7425368, lng: 21.2644596 },
    { lat: 48.7425552, lng: 21.2646198 },
    { lat: 48.7426147, lng: 21.2651259 },
    { lat: 48.742657, lng: 21.2654013 },
    { lat: 48.7426978, lng: 21.2656705 },
    { lat: 48.7427261, lng: 21.265819 },
    { lat: 48.7427314, lng: 21.2658457 },
    { lat: 48.742756, lng: 21.265974 },
    { lat: 48.742757, lng: 21.266034 },
    { lat: 48.742822, lng: 21.266397 },
    { lat: 48.742898, lng: 21.266771 },
    { lat: 48.743002, lng: 21.267211 },
    { lat: 48.743017, lng: 21.267274 },
    { lat: 48.7431596, lng: 21.2677879 },
    { lat: 48.743304, lng: 21.268308 },
    { lat: 48.743481, lng: 21.268809 },
    { lat: 48.74364, lng: 21.269253 },
    { lat: 48.743812, lng: 21.269678 },
    { lat: 48.744024, lng: 21.270151 },
    { lat: 48.744115, lng: 21.270361 },
    { lat: 48.744214, lng: 21.270569 },
    { lat: 48.744307, lng: 21.270711 },
    { lat: 48.744452, lng: 21.271002 },
    { lat: 48.744494, lng: 21.27107 },
    { lat: 48.744675, lng: 21.271347 },
    { lat: 48.74489, lng: 21.271623 },
    { lat: 48.74511, lng: 21.271901 },
    { lat: 48.745281, lng: 21.272137 },
    { lat: 48.745599, lng: 21.272598 },
    { lat: 48.74566, lng: 21.272688 },
    { lat: 48.745679, lng: 21.272716 },
    { lat: 48.745741, lng: 21.27281 },
    { lat: 48.745748, lng: 21.272818 },
    { lat: 48.74582, lng: 21.272926 },
    { lat: 48.745846, lng: 21.272963 },
    { lat: 48.745927, lng: 21.273083 },
    { lat: 48.746191, lng: 21.273455 },
    { lat: 48.746381, lng: 21.273714 },
    { lat: 48.746635, lng: 21.273944 },
    { lat: 48.746662, lng: 21.273961 },
    { lat: 48.746943, lng: 21.274128 },
    { lat: 48.747301, lng: 21.27442 },
    { lat: 48.747619, lng: 21.274695 },
    { lat: 48.747708, lng: 21.274754 },
    { lat: 48.747912, lng: 21.274891 },
    { lat: 48.748595, lng: 21.275272 },
    { lat: 48.749635, lng: 21.275762 },
    { lat: 48.749902, lng: 21.27593 },
    { lat: 48.750049, lng: 21.276002 },
    { lat: 48.750213, lng: 21.276051 },
    { lat: 48.750325, lng: 21.276144 },
    { lat: 48.751322, lng: 21.27666 },
    { lat: 48.752015, lng: 21.277045 },
    { lat: 48.752366, lng: 21.277268 },
    { lat: 48.752751, lng: 21.277475 },
    { lat: 48.753774, lng: 21.277899 },
    { lat: 48.75416, lng: 21.278409 },
    { lat: 48.754704, lng: 21.278823 },
    { lat: 48.755326, lng: 21.279385 },
    { lat: 48.755653, lng: 21.279645 },
    { lat: 48.755985, lng: 21.279821 },
    { lat: 48.756328, lng: 21.280161 },
    { lat: 48.756614, lng: 21.280376 },
    { lat: 48.756965, lng: 21.280695 },
    { lat: 48.757517, lng: 21.281248 },
    { lat: 48.758071, lng: 21.281755 },
    { lat: 48.758128, lng: 21.281808 },
    { lat: 48.758874, lng: 21.282573 },
    { lat: 48.759017, lng: 21.282696 },
    { lat: 48.759318, lng: 21.282781 },
    { lat: 48.760018, lng: 21.2834 },
    { lat: 48.760291, lng: 21.283679 },
    { lat: 48.760494, lng: 21.283925 },
    { lat: 48.760518, lng: 21.283953 },
    { lat: 48.760564, lng: 21.284033 },
    { lat: 48.760594, lng: 21.284084 },
    { lat: 48.761147, lng: 21.284773 },
    { lat: 48.761337, lng: 21.285088 },
    { lat: 48.761574, lng: 21.285412 },
    { lat: 48.761657, lng: 21.285526 },
    { lat: 48.761818, lng: 21.285759 },
    { lat: 48.763241, lng: 21.286961 },
    { lat: 48.763612, lng: 21.287502 },
    { lat: 48.76392, lng: 21.288025 },
    { lat: 48.764158, lng: 21.288535 },
    { lat: 48.764367, lng: 21.289289 },
    { lat: 48.764645, lng: 21.289966 },
    { lat: 48.764892, lng: 21.290568 },
    { lat: 48.765043, lng: 21.290909 },
    { lat: 48.76522, lng: 21.2912 },
    { lat: 48.765595, lng: 21.291688 },
    { lat: 48.765816, lng: 21.291914 },
    { lat: 48.766123, lng: 21.29217 },
    { lat: 48.766446, lng: 21.29236 },
    { lat: 48.766981, lng: 21.292587 },
    { lat: 48.770426, lng: 21.293809 },
    { lat: 48.770517, lng: 21.293842 },
    { lat: 48.772064, lng: 21.294393 },
    { lat: 48.772074, lng: 21.294397 },
    { lat: 48.772215, lng: 21.294447 },
    { lat: 48.773383, lng: 21.294863 },
    { lat: 48.773427, lng: 21.294872 },
    { lat: 48.773665, lng: 21.294935 },
    { lat: 48.77393, lng: 21.294961 },
    { lat: 48.774211, lng: 21.294946 },
    { lat: 48.774484, lng: 21.294888 },
    { lat: 48.774886, lng: 21.294737 },
    { lat: 48.775254, lng: 21.294513 },
    { lat: 48.775533, lng: 21.294332 },
    { lat: 48.775936, lng: 21.293987 },
    { lat: 48.776832, lng: 21.293228 },
    { lat: 48.777976, lng: 21.292261 },
    { lat: 48.778373, lng: 21.291705 },
    { lat: 48.778463, lng: 21.291578 },
    { lat: 48.779389, lng: 21.291199 },
    { lat: 48.780619, lng: 21.291049 },
    { lat: 48.780702, lng: 21.291506 },
    { lat: 48.781606, lng: 21.292409 },
    { lat: 48.782313, lng: 21.292845 },
    { lat: 48.782772, lng: 21.293446 },
    { lat: 48.783557, lng: 21.294007 },
    { lat: 48.784394, lng: 21.295103 },
    { lat: 48.784596, lng: 21.295254 },
    { lat: 48.78463, lng: 21.295429 },
    { lat: 48.784717, lng: 21.295727 },
    { lat: 48.784908, lng: 21.296124 },
    { lat: 48.785029, lng: 21.296324 },
    { lat: 48.785126, lng: 21.296601 },
    { lat: 48.785267, lng: 21.297013 },
    { lat: 48.785544, lng: 21.298019 },
    { lat: 48.78565, lng: 21.298586 },
    { lat: 48.785656, lng: 21.298648 },
    { lat: 48.7857255, lng: 21.2989737 },
    { lat: 48.78583, lng: 21.299463 },
    { lat: 48.785927, lng: 21.299415 },
    { lat: 48.786585, lng: 21.299091 },
    { lat: 48.787671, lng: 21.298556 },
    { lat: 48.788126, lng: 21.299643 },
    { lat: 48.788528, lng: 21.298913 },
    { lat: 48.788569, lng: 21.298838 },
    { lat: 48.788864, lng: 21.29874 },
    { lat: 48.789213, lng: 21.298623 },
    { lat: 48.791091, lng: 21.297995 },
    { lat: 48.791758, lng: 21.297384 },
    { lat: 48.792087, lng: 21.297083 },
    { lat: 48.793146, lng: 21.296113 },
    { lat: 48.793218, lng: 21.296046 },
    { lat: 48.793247, lng: 21.29602 },
    { lat: 48.793403, lng: 21.295877 },
    { lat: 48.794487, lng: 21.292872 },
    { lat: 48.794497, lng: 21.292842 },
    { lat: 48.794502, lng: 21.292832 },
    { lat: 48.7945242, lng: 21.2927693 },
    { lat: 48.79458, lng: 21.292612 },
    { lat: 48.794601, lng: 21.292554 },
    { lat: 48.794638, lng: 21.292451 },
    { lat: 48.795437, lng: 21.290721 },
    { lat: 48.795424, lng: 21.289391 },
    { lat: 48.795421, lng: 21.289164 },
    { lat: 48.79542, lng: 21.289005 },
    { lat: 48.795419, lng: 21.288951 },
    { lat: 48.795409, lng: 21.287957 },
    { lat: 48.79541, lng: 21.287929 },
    { lat: 48.795407, lng: 21.287711 },
    { lat: 48.795406, lng: 21.287679 },
    { lat: 48.795405, lng: 21.287472 },
    { lat: 48.795404, lng: 21.287392 },
    { lat: 48.7954, lng: 21.286983 },
    { lat: 48.7954, lng: 21.286956 },
    { lat: 48.795398, lng: 21.28681 },
    { lat: 48.795414, lng: 21.286804 },
    { lat: 48.795433, lng: 21.286797 },
    { lat: 48.796257, lng: 21.286488 },
    { lat: 48.796269, lng: 21.286484 },
    { lat: 48.796349, lng: 21.286454 },
    { lat: 48.796389, lng: 21.286439 },
    { lat: 48.796631, lng: 21.286349 },
    { lat: 48.796879, lng: 21.286189 },
    { lat: 48.796886, lng: 21.286138 },
    { lat: 48.796899, lng: 21.286061 },
    { lat: 48.796904, lng: 21.286027 },
    { lat: 48.796553, lng: 21.284881 },
    { lat: 48.796525, lng: 21.28479 },
    { lat: 48.796514, lng: 21.284756 },
    { lat: 48.796469, lng: 21.284607 },
    { lat: 48.796213, lng: 21.283769 },
    { lat: 48.795979, lng: 21.283151 },
    { lat: 48.795243, lng: 21.282098 },
    { lat: 48.794539, lng: 21.2805 },
    { lat: 48.793985, lng: 21.279275 },
    { lat: 48.793431, lng: 21.278077 },
    { lat: 48.792932, lng: 21.276925 },
    { lat: 48.792541, lng: 21.275995 },
    { lat: 48.792182, lng: 21.275224 },
    { lat: 48.793056, lng: 21.274911 },
    { lat: 48.793632, lng: 21.27465 },
    { lat: 48.793699, lng: 21.274519 },
    { lat: 48.794078, lng: 21.273773 },
    { lat: 48.794104, lng: 21.273653 },
    { lat: 48.794111, lng: 21.273621 },
    { lat: 48.794118, lng: 21.273589 },
    { lat: 48.794133, lng: 21.273521 },
    { lat: 48.7941417, lng: 21.2734816 },
    { lat: 48.7940528, lng: 21.273444 },
    { lat: 48.7939321, lng: 21.2733607 },
    { lat: 48.7938195, lng: 21.273283 },
    { lat: 48.7937252, lng: 21.2732197 },
    { lat: 48.7936318, lng: 21.2731834 },
    { lat: 48.7934588, lng: 21.2731549 },
    { lat: 48.7933651, lng: 21.2731458 },
    { lat: 48.7932236, lng: 21.2731564 },
    { lat: 48.7928902, lng: 21.2732199 },
    { lat: 48.7926192, lng: 21.2732625 },
    { lat: 48.7923195, lng: 21.2732861 },
    { lat: 48.7920646, lng: 21.2733029 },
    { lat: 48.7919011, lng: 21.2733316 },
    { lat: 48.7917762, lng: 21.27337 },
    { lat: 48.7916288, lng: 21.2734414 },
    { lat: 48.791475, lng: 21.2735614 },
    { lat: 48.7913643, lng: 21.2736581 },
    { lat: 48.7912693, lng: 21.2737165 },
    { lat: 48.7912006, lng: 21.273752 },
    { lat: 48.7911022, lng: 21.2737739 },
    { lat: 48.7909891, lng: 21.2737919 },
    { lat: 48.7906937, lng: 21.2738091 },
    { lat: 48.7902828, lng: 21.2738412 },
    { lat: 48.7897222, lng: 21.2739081 },
    { lat: 48.7891948, lng: 21.2739584 },
    { lat: 48.7889291, lng: 21.2739917 },
    { lat: 48.7887798, lng: 21.2740393 },
    { lat: 48.7886638, lng: 21.2741089 },
    { lat: 48.7884208, lng: 21.2742991 },
    { lat: 48.7883477, lng: 21.2743699 },
    { lat: 48.78823, lng: 21.2744977 },
    { lat: 48.7880388, lng: 21.274756 },
    { lat: 48.7879355, lng: 21.2748742 },
    { lat: 48.7878009, lng: 21.2750007 },
    { lat: 48.7876876, lng: 21.2750826 },
    { lat: 48.7874395, lng: 21.2752474 },
    { lat: 48.7872558, lng: 21.2753513 },
    { lat: 48.7871269, lng: 21.2754039 },
    { lat: 48.787016, lng: 21.2754221 },
    { lat: 48.7867836, lng: 21.2754017 },
    { lat: 48.7867255, lng: 21.2753422 },
    { lat: 48.7865767, lng: 21.2753352 },
    { lat: 48.7865441, lng: 21.27537 },
    { lat: 48.7860557, lng: 21.275375 },
    { lat: 48.7858254, lng: 21.2753844 },
    { lat: 48.7856853, lng: 21.2754032 },
    { lat: 48.785386, lng: 21.2755135 },
    { lat: 48.7850254, lng: 21.2756823 },
    { lat: 48.7849412, lng: 21.2757253 },
    { lat: 48.7848613, lng: 21.2757616 },
    { lat: 48.7846341, lng: 21.2758011 },
    { lat: 48.7843874, lng: 21.2757963 },
    { lat: 48.7841591, lng: 21.2757981 },
    { lat: 48.7837065, lng: 21.2758271 },
    { lat: 48.7835006, lng: 21.2757944 },
    { lat: 48.7831744, lng: 21.2756882 },
    { lat: 48.7829927, lng: 21.2756364 },
    { lat: 48.7827553, lng: 21.2755992 },
    { lat: 48.7825764, lng: 21.2755625 },
    { lat: 48.7824911, lng: 21.2754964 },
    { lat: 48.7824087, lng: 21.2754883 },
    { lat: 48.7824042, lng: 21.2754883 },
    { lat: 48.7823299, lng: 21.2754895 },
    { lat: 48.7822386, lng: 21.275522 },
    { lat: 48.7819135, lng: 21.2754517 },
    { lat: 48.7817263, lng: 21.2754035 },
    { lat: 48.7816095, lng: 21.2753743 },
    { lat: 48.7813652, lng: 21.2753361 },
    { lat: 48.7809917, lng: 21.2753021 },
    { lat: 48.7805158, lng: 21.2753441 },
    { lat: 48.7801971, lng: 21.2753718 },
    { lat: 48.7797551, lng: 21.2753783 },
    { lat: 48.7794928, lng: 21.275376 },
    { lat: 48.7794085, lng: 21.275382 },
    { lat: 48.7790358, lng: 21.2754071 },
    { lat: 48.7785633, lng: 21.275416 },
    { lat: 48.7782007, lng: 21.2753712 },
    { lat: 48.7781059, lng: 21.2753593 },
    { lat: 48.7778628, lng: 21.275289 },
    { lat: 48.7777488, lng: 21.2753509 },
    { lat: 48.7776628, lng: 21.2754069 },
    { lat: 48.7776213, lng: 21.2754869 },
    { lat: 48.777496, lng: 21.275658 },
    { lat: 48.7769762, lng: 21.2750363 },
    { lat: 48.7769188, lng: 21.2749989 },
    { lat: 48.776913, lng: 21.274995 },
    { lat: 48.7768934, lng: 21.2749771 },
    { lat: 48.7769286, lng: 21.2748798 },
    { lat: 48.776826, lng: 21.274837 },
    { lat: 48.7767976, lng: 21.2748251 },
    { lat: 48.7766387, lng: 21.2747481 },
    { lat: 48.7763282, lng: 21.2748231 },
    { lat: 48.7762255, lng: 21.2748478 },
    { lat: 48.775823, lng: 21.2748258 },
    { lat: 48.7757276, lng: 21.2750184 },
    { lat: 48.7755595, lng: 21.2754819 },
    { lat: 48.7754701, lng: 21.2756837 },
    { lat: 48.7752776, lng: 21.2761226 },
    { lat: 48.7752706, lng: 21.2761473 },
    { lat: 48.7751823, lng: 21.2761335 },
    { lat: 48.7750093, lng: 21.2761057 },
    { lat: 48.7749811, lng: 21.2760314 },
    { lat: 48.7749484, lng: 21.2758071 },
    { lat: 48.7748899, lng: 21.2755868 },
    { lat: 48.7748634, lng: 21.2752747 },
    { lat: 48.7747479, lng: 21.2749217 },
    { lat: 48.774716, lng: 21.2747285 },
    { lat: 48.7746444, lng: 21.2744866 },
    { lat: 48.7745605, lng: 21.2743198 },
    { lat: 48.7745075, lng: 21.2740927 },
    { lat: 48.7744332, lng: 21.2738007 },
    { lat: 48.7743607, lng: 21.2734169 },
    { lat: 48.7743121, lng: 21.2733037 },
    { lat: 48.7742283, lng: 21.2731372 },
    { lat: 48.7741537, lng: 21.273089 },
    { lat: 48.7740313, lng: 21.2730633 },
    { lat: 48.7736379, lng: 21.2729668 },
    { lat: 48.7734953, lng: 21.272867 },
    { lat: 48.7733156, lng: 21.2728054 },
    { lat: 48.7732408, lng: 21.2727529 },
    { lat: 48.7730525, lng: 21.272486 },
    { lat: 48.773017, lng: 21.272394 },
    { lat: 48.7729104, lng: 21.2719234 },
    { lat: 48.7728913, lng: 21.2717799 },
    { lat: 48.7729007, lng: 21.2715738 },
    { lat: 48.7728809, lng: 21.2714795 },
    { lat: 48.7727873, lng: 21.2712535 },
    { lat: 48.7726147, lng: 21.2708732 },
    { lat: 48.7724905, lng: 21.2707114 },
    { lat: 48.7722951, lng: 21.2705403 },
    { lat: 48.7721211, lng: 21.2703735 },
    { lat: 48.7719768, lng: 21.2702959 },
    { lat: 48.7717277, lng: 21.2702085 },
    { lat: 48.7714457, lng: 21.2700743 },
    { lat: 48.771143, lng: 21.2698933 },
    { lat: 48.7708668, lng: 21.2697672 },
    { lat: 48.7707625, lng: 21.269671 },
    { lat: 48.7706679, lng: 21.2695596 },
    { lat: 48.770607, lng: 21.2695154 },
    { lat: 48.770346, lng: 21.2693696 },
    { lat: 48.7701457, lng: 21.2692632 },
    { lat: 48.7700297, lng: 21.2692207 },
    { lat: 48.7699029, lng: 21.2691982 },
    { lat: 48.7696876, lng: 21.269187 },
    { lat: 48.7695639, lng: 21.2691165 },
    { lat: 48.7694569, lng: 21.2690416 },
    { lat: 48.7693601, lng: 21.2689646 },
    { lat: 48.7692232, lng: 21.2688707 },
    { lat: 48.7691911, lng: 21.2688488 },
    { lat: 48.7691019, lng: 21.2688764 },
    { lat: 48.76881, lng: 21.2689384 },
    { lat: 48.7686781, lng: 21.2689309 },
    { lat: 48.7685073, lng: 21.2689358 },
    { lat: 48.7683689, lng: 21.2689766 },
    { lat: 48.7682253, lng: 21.2690266 },
    { lat: 48.7680208, lng: 21.2691077 },
    { lat: 48.7680141, lng: 21.2690743 },
    { lat: 48.7680177, lng: 21.2689 },
    { lat: 48.7681628, lng: 21.2687503 },
    { lat: 48.7681755, lng: 21.2686513 },
    { lat: 48.7679792, lng: 21.2686002 },
    { lat: 48.7678646, lng: 21.2682379 },
    { lat: 48.7676156, lng: 21.2680457 },
    { lat: 48.767681, lng: 21.2679055 },
    { lat: 48.7674743, lng: 21.2678438 },
    { lat: 48.7673925, lng: 21.2676733 },
    { lat: 48.7671214, lng: 21.2675633 },
    { lat: 48.7669655, lng: 21.2672924 },
    { lat: 48.7666884, lng: 21.2671329 },
    { lat: 48.7665923, lng: 21.2669391 },
    { lat: 48.7664867, lng: 21.2667604 },
    { lat: 48.766418, lng: 21.2665041 },
    { lat: 48.7664612, lng: 21.2663348 },
    { lat: 48.7664846, lng: 21.2661812 },
    { lat: 48.7664339, lng: 21.2660713 },
    { lat: 48.7663581, lng: 21.2659461 },
    { lat: 48.7662796, lng: 21.265662 },
    { lat: 48.7663843, lng: 21.2653721 },
    { lat: 48.7662551, lng: 21.2650134 },
    { lat: 48.7663577, lng: 21.2648738 },
    { lat: 48.7662076, lng: 21.2646019 },
    { lat: 48.7661566, lng: 21.2634967 },
    { lat: 48.7660384, lng: 21.2634212 },
    { lat: 48.7658331, lng: 21.2630034 },
    { lat: 48.7656499, lng: 21.26261 },
    { lat: 48.7654534, lng: 21.2621865 },
    { lat: 48.7652336, lng: 21.2617718 },
    { lat: 48.7649522, lng: 21.2612608 },
    { lat: 48.7648325, lng: 21.2608095 },
    { lat: 48.7646163, lng: 21.2604405 },
    { lat: 48.7640765, lng: 21.2601234 },
    { lat: 48.7636996, lng: 21.2607489 },
    { lat: 48.7637788, lng: 21.2614157 },
    { lat: 48.7637548, lng: 21.2614067 },
    { lat: 48.7636626, lng: 21.2613718 },
    { lat: 48.7635749, lng: 21.261338 },
    { lat: 48.7635055, lng: 21.2613123 },
    { lat: 48.7634531, lng: 21.2612924 },
    { lat: 48.7634393, lng: 21.2612848 },
    { lat: 48.763465, lng: 21.2611681 },
    { lat: 48.7634909, lng: 21.2610243 },
    { lat: 48.7634953, lng: 21.2608385 },
    { lat: 48.7634671, lng: 21.2606165 },
    { lat: 48.7634317, lng: 21.2604542 },
    { lat: 48.7634405, lng: 21.2604486 },
    { lat: 48.7634072, lng: 21.2603507 },
    { lat: 48.7633347, lng: 21.2601928 },
    { lat: 48.7632505, lng: 21.2600518 },
    { lat: 48.7631759, lng: 21.2599665 },
    { lat: 48.7630995, lng: 21.2598969 },
    { lat: 48.7629691, lng: 21.2598277 },
    { lat: 48.7628072, lng: 21.2597999 },
    { lat: 48.7627142, lng: 21.259801 },
    { lat: 48.7626723, lng: 21.2598015 },
    { lat: 48.7625354, lng: 21.2598504 },
    { lat: 48.7624111, lng: 21.2599455 },
    { lat: 48.7623228, lng: 21.2600395 },
    { lat: 48.762248, lng: 21.2601329 },
    { lat: 48.7621185, lng: 21.2603122 },
    { lat: 48.7621245, lng: 21.2603221 },
    { lat: 48.7620263, lng: 21.2604614 },
    { lat: 48.7618786, lng: 21.2606673 },
    { lat: 48.7615364, lng: 21.2609974 },
    { lat: 48.7613742, lng: 21.2610271 },
    { lat: 48.761238, lng: 21.2610249 },
    { lat: 48.7611432, lng: 21.2610177 },
    { lat: 48.7610332, lng: 21.2609887 },
    { lat: 48.7609599, lng: 21.2609475 },
    { lat: 48.7607269, lng: 21.2607656 },
    { lat: 48.7606358, lng: 21.2606544 },
    { lat: 48.7604936, lng: 21.2604225 },
    { lat: 48.7604776, lng: 21.2603431 },
    { lat: 48.7604981, lng: 21.2602781 },
    { lat: 48.7605133, lng: 21.2602574 },
    { lat: 48.7604765, lng: 21.260198 },
    { lat: 48.7604079, lng: 21.2602346 },
    { lat: 48.7603607, lng: 21.2602008 },
    { lat: 48.7602955, lng: 21.2600916 },
    { lat: 48.7602432, lng: 21.2600029 },
    { lat: 48.7602252, lng: 21.2599726 },
    { lat: 48.7601209, lng: 21.2597972 },
    { lat: 48.760031, lng: 21.2596569 },
    { lat: 48.7599558, lng: 21.2595884 },
    { lat: 48.7598524, lng: 21.2595575 },
    { lat: 48.7598211, lng: 21.259564 },
    { lat: 48.7597842, lng: 21.2595709 },
    { lat: 48.7597377, lng: 21.2595984 },
    { lat: 48.7596899, lng: 21.2596535 },
    { lat: 48.7596851, lng: 21.2596442 },
    { lat: 48.7596797, lng: 21.2596518 },
    { lat: 48.7596749, lng: 21.2596749 },
    { lat: 48.7594546, lng: 21.2599769 },
    { lat: 48.7594487, lng: 21.259968 },
    { lat: 48.7594433, lng: 21.2599752 },
    { lat: 48.7594384, lng: 21.2599984 },
    { lat: 48.7592205, lng: 21.2603016 },
    { lat: 48.7592145, lng: 21.2602924 },
    { lat: 48.7592091, lng: 21.2603002 },
    { lat: 48.7592042, lng: 21.2603234 },
    { lat: 48.7589991, lng: 21.2606047 },
    { lat: 48.7589943, lng: 21.2605961 },
    { lat: 48.7589889, lng: 21.2606043 },
    { lat: 48.7589839, lng: 21.2606268 },
    { lat: 48.7586308, lng: 21.2611086 },
    { lat: 48.7586153, lng: 21.261115 },
    { lat: 48.7586099, lng: 21.2611219 },
    { lat: 48.7586146, lng: 21.2611308 },
    { lat: 48.7584214, lng: 21.2613945 },
    { lat: 48.7584057, lng: 21.2614002 },
    { lat: 48.7584004, lng: 21.2614073 },
    { lat: 48.7584052, lng: 21.2614166 },
    { lat: 48.7583339, lng: 21.2615148 },
    { lat: 48.7582432, lng: 21.2616381 },
    { lat: 48.7582275, lng: 21.2616439 },
    { lat: 48.7582222, lng: 21.2616514 },
    { lat: 48.758227, lng: 21.2616603 },
    { lat: 48.7580423, lng: 21.2619109 },
    { lat: 48.758007, lng: 21.2619376 },
    { lat: 48.7580005, lng: 21.2619442 },
    { lat: 48.7580053, lng: 21.2619535 },
    { lat: 48.7579662, lng: 21.2619983 },
    { lat: 48.7579466, lng: 21.2620204 },
    { lat: 48.757852, lng: 21.2621265 },
    { lat: 48.7578389, lng: 21.2621414 },
    { lat: 48.7577808, lng: 21.262046 },
    { lat: 48.7577441, lng: 21.262096 },
    { lat: 48.7576979, lng: 21.2621299 },
    { lat: 48.7576925, lng: 21.2621367 },
    { lat: 48.757673, lng: 21.2621602 },
    { lat: 48.7576611, lng: 21.2621775 },
    { lat: 48.7576293, lng: 21.2622025 },
    { lat: 48.7576213, lng: 21.2622377 },
    { lat: 48.7576561, lng: 21.2622677 },
    { lat: 48.7576961, lng: 21.2623284 },
    { lat: 48.7575578, lng: 21.26251 },
    { lat: 48.7574928, lng: 21.2625931 },
    { lat: 48.7573898, lng: 21.2627965 },
    { lat: 48.7573743, lng: 21.2628062 },
    { lat: 48.7573712, lng: 21.2628121 },
    { lat: 48.7573771, lng: 21.2628195 },
    { lat: 48.7573567, lng: 21.2628538 },
    { lat: 48.7573536, lng: 21.262859 },
    { lat: 48.7573525, lng: 21.2628619 },
    { lat: 48.7573136, lng: 21.2629153 },
    { lat: 48.7572353, lng: 21.2630036 },
    { lat: 48.7571682, lng: 21.2630532 },
    { lat: 48.7571558, lng: 21.2630541 },
    { lat: 48.7571493, lng: 21.2630588 },
    { lat: 48.7571506, lng: 21.2630666 },
    { lat: 48.7571139, lng: 21.2630813 },
    { lat: 48.7570566, lng: 21.2630839 },
    { lat: 48.7569882, lng: 21.2630538 },
    { lat: 48.7569736, lng: 21.2630227 },
    { lat: 48.7569684, lng: 21.2630313 },
    { lat: 48.756893, lng: 21.2631404 },
    { lat: 48.7569126, lng: 21.2631957 },
    { lat: 48.756923, lng: 21.2632767 },
    { lat: 48.7569239, lng: 21.2633426 },
    { lat: 48.7568764, lng: 21.2634069 },
    { lat: 48.7564143, lng: 21.2640405 },
    { lat: 48.7559964, lng: 21.2646145 },
    { lat: 48.7558128, lng: 21.2648657 },
    { lat: 48.7557362, lng: 21.2649717 },
    { lat: 48.7556519, lng: 21.2650853 },
    { lat: 48.7556022, lng: 21.2648577 },
    { lat: 48.7555928, lng: 21.2648102 },
    { lat: 48.7555652, lng: 21.2646827 },
    { lat: 48.7555456, lng: 21.2645928 },
    { lat: 48.7554984, lng: 21.2643736 },
    { lat: 48.7554841, lng: 21.2643115 },
    { lat: 48.7554708, lng: 21.2642478 },
    { lat: 48.7554368, lng: 21.2640933 },
    { lat: 48.7552911, lng: 21.2634185 },
    { lat: 48.7552832, lng: 21.2633844 },
    { lat: 48.7550709, lng: 21.2636144 },
    { lat: 48.7547626, lng: 21.2639461 },
    { lat: 48.7543884, lng: 21.2626839 },
    { lat: 48.754347, lng: 21.2625445 },
    { lat: 48.7542017, lng: 21.2620676 },
    { lat: 48.7541783, lng: 21.2620365 },
    { lat: 48.7541561, lng: 21.2620498 },
    { lat: 48.7541065, lng: 21.2620797 },
    { lat: 48.7539879, lng: 21.2615178 },
    { lat: 48.7537703, lng: 21.2611044 },
    { lat: 48.7533545, lng: 21.2604294 },
    { lat: 48.7532729, lng: 21.2603036 },
    { lat: 48.7531247, lng: 21.260533 },
    { lat: 48.7531129, lng: 21.2605124 },
    { lat: 48.7531084, lng: 21.2605177 },
    { lat: 48.7530772, lng: 21.2605606 },
    { lat: 48.7530307, lng: 21.2606252 },
    { lat: 48.7525809, lng: 21.2612568 },
    { lat: 48.7522371, lng: 21.2617472 },
    { lat: 48.752068, lng: 21.2619927 },
    { lat: 48.7519969, lng: 21.2620975 },
    { lat: 48.7516284, lng: 21.2635764 },
    { lat: 48.7518977, lng: 21.2640239 },
    { lat: 48.7521729, lng: 21.2644831 },
    { lat: 48.7519126, lng: 21.2648381 },
    { lat: 48.7518597, lng: 21.264911 },
    { lat: 48.7518057, lng: 21.264984 },
    { lat: 48.7514957, lng: 21.2654074 },
    { lat: 48.7511624, lng: 21.2648496 },
    { lat: 48.7511408, lng: 21.26488 },
    { lat: 48.7509691, lng: 21.2645308 },
    { lat: 48.7509523, lng: 21.2644981 },
    { lat: 48.7509589, lng: 21.2645284 },
    { lat: 48.7509856, lng: 21.2646659 },
    { lat: 48.7510614, lng: 21.2650443 },
    { lat: 48.7511107, lng: 21.2652937 },
    { lat: 48.7511307, lng: 21.2653925 },
    { lat: 48.750254, lng: 21.2664888 },
    { lat: 48.7501337, lng: 21.2666381 },
    { lat: 48.7501707, lng: 21.2666687 },
    { lat: 48.750028, lng: 21.2668613 },
    { lat: 48.749987, lng: 21.2669173 },
    { lat: 48.7499297, lng: 21.2669948 },
    { lat: 48.7498865, lng: 21.2670528 },
    { lat: 48.7498109, lng: 21.2671554 },
    { lat: 48.749772, lng: 21.2672081 },
    { lat: 48.7496196, lng: 21.2674139 },
    { lat: 48.74957, lng: 21.2674816 },
    { lat: 48.7495181, lng: 21.2675509 },
    { lat: 48.7493604, lng: 21.2677649 },
    { lat: 48.7476931, lng: 21.2705977 },
    { lat: 48.7473438, lng: 21.2688643 },
    { lat: 48.7469418, lng: 21.2693326 },
    { lat: 48.7468224, lng: 21.2695118 },
    { lat: 48.7470377, lng: 21.2691205 },
    { lat: 48.7470126, lng: 21.2690716 },
    { lat: 48.7469839, lng: 21.2690173 },
    { lat: 48.7469803, lng: 21.2690104 },
    { lat: 48.7469527, lng: 21.2689567 },
    { lat: 48.7469023, lng: 21.2687424 },
    { lat: 48.7468854, lng: 21.2686705 },
    { lat: 48.7459707, lng: 21.2652061 },
    { lat: 48.7459607, lng: 21.2651704 },
    { lat: 48.7457065, lng: 21.2641885 },
    { lat: 48.7453948, lng: 21.2629859 },
    { lat: 48.7453653, lng: 21.2628696 },
    { lat: 48.745746, lng: 21.2629155 },
    { lat: 48.7462844, lng: 21.2627135 },
    { lat: 48.746255, lng: 21.2626336 },
    { lat: 48.7462464, lng: 21.2626121 },
    { lat: 48.7462452, lng: 21.2626079 },
    { lat: 48.7462365, lng: 21.2625825 },
    { lat: 48.7461248, lng: 21.2622826 },
    { lat: 48.7461052, lng: 21.262228 },
    { lat: 48.7457984, lng: 21.2614021 },
    { lat: 48.7457922, lng: 21.2613854 },
    { lat: 48.7457909, lng: 21.2613798 },
    { lat: 48.7457725, lng: 21.2613301 },
    { lat: 48.74577, lng: 21.2613231 },
    { lat: 48.7457504, lng: 21.2612714 },
    { lat: 48.7457492, lng: 21.2612665 },
    { lat: 48.7457382, lng: 21.2612376 },
    { lat: 48.7456044, lng: 21.2608769 },
    { lat: 48.7455073, lng: 21.2606139 },
    { lat: 48.7454902, lng: 21.2605676 },
    { lat: 48.7454362, lng: 21.2604242 },
    { lat: 48.7453849, lng: 21.2602851 },
    { lat: 48.745333, lng: 21.2601444 },
    { lat: 48.7445197, lng: 21.25798 },
    { lat: 48.7445066, lng: 21.2579452 },
    { lat: 48.744449, lng: 21.2577881 },
    { lat: 48.7443101, lng: 21.2574121 },
    { lat: 48.7442893, lng: 21.2573554 },
    { lat: 48.7442733, lng: 21.257312 },
  ],
  KošicemestskáčasťŤahanovce: [
    { lat: 48.780702, lng: 21.244561 },
    { lat: 48.780538, lng: 21.244354 },
    { lat: 48.780165, lng: 21.243883 },
    { lat: 48.779789, lng: 21.243783 },
    { lat: 48.779723, lng: 21.243744 },
    { lat: 48.779674, lng: 21.243737 },
    { lat: 48.779669, lng: 21.243448 },
    { lat: 48.779689, lng: 21.243346 },
    { lat: 48.779686, lng: 21.243193 },
    { lat: 48.779686, lng: 21.243112 },
    { lat: 48.779684, lng: 21.243085 },
    { lat: 48.779683, lng: 21.243067 },
    { lat: 48.779681, lng: 21.243037 },
    { lat: 48.779642, lng: 21.242555 },
    { lat: 48.779546, lng: 21.241386 },
    { lat: 48.779542, lng: 21.241337 },
    { lat: 48.779541, lng: 21.241321 },
    { lat: 48.779537, lng: 21.241266 },
    { lat: 48.779547, lng: 21.241088 },
    { lat: 48.779558, lng: 21.240895 },
    { lat: 48.77874, lng: 21.240488 },
    { lat: 48.778057, lng: 21.240406 },
    { lat: 48.777801, lng: 21.240551 },
    { lat: 48.77745, lng: 21.240799 },
    { lat: 48.777237, lng: 21.240959 },
    { lat: 48.776436, lng: 21.241347 },
    { lat: 48.775915, lng: 21.241532 },
    { lat: 48.775278, lng: 21.241795 },
    { lat: 48.774887, lng: 21.241924 },
    { lat: 48.773956, lng: 21.2424 },
    { lat: 48.773336, lng: 21.242797 },
    { lat: 48.773249, lng: 21.242853 },
    { lat: 48.773229, lng: 21.242866 },
    { lat: 48.772708, lng: 21.243379 },
    { lat: 48.772044, lng: 21.244135 },
    { lat: 48.771952, lng: 21.244262 },
    { lat: 48.771585, lng: 21.24438 },
    { lat: 48.7712901, lng: 21.2444317 },
    { lat: 48.7710898, lng: 21.2443953 },
    { lat: 48.770782, lng: 21.2443022 },
    { lat: 48.7707073, lng: 21.2442518 },
    { lat: 48.770452, lng: 21.2440409 },
    { lat: 48.7702652, lng: 21.2438201 },
    { lat: 48.7701002, lng: 21.2435799 },
    { lat: 48.770002, lng: 21.2433532 },
    { lat: 48.7698863, lng: 21.2430692 },
    { lat: 48.769778, lng: 21.2427373 },
    { lat: 48.7697091, lng: 21.2423626 },
    { lat: 48.769688, lng: 21.2419749 },
    { lat: 48.7696668, lng: 21.2415807 },
    { lat: 48.7696904, lng: 21.2413614 },
    { lat: 48.7696049, lng: 21.2408209 },
    { lat: 48.7694627, lng: 21.2403723 },
    { lat: 48.7692338, lng: 21.2399183 },
    { lat: 48.7689378, lng: 21.239447 },
    { lat: 48.7686165, lng: 21.2391013 },
    { lat: 48.7682344, lng: 21.2389029 },
    { lat: 48.7675826, lng: 21.2392211 },
    { lat: 48.7673693, lng: 21.2394206 },
    { lat: 48.7671019, lng: 21.2397607 },
    { lat: 48.7667338, lng: 21.2402984 },
    { lat: 48.7665047, lng: 21.2407131 },
    { lat: 48.7661735, lng: 21.2412856 },
    { lat: 48.7659078, lng: 21.2418988 },
    { lat: 48.7655656, lng: 21.2423315 },
    { lat: 48.7651086, lng: 21.2429083 },
    { lat: 48.764601, lng: 21.2436702 },
    { lat: 48.7638707, lng: 21.2448727 },
    { lat: 48.7636287, lng: 21.2452712 },
    { lat: 48.7631803, lng: 21.2460167 },
    { lat: 48.7628003, lng: 21.2464646 },
    { lat: 48.7623857, lng: 21.2468856 },
    { lat: 48.7605011, lng: 21.2485742 },
    { lat: 48.7604869, lng: 21.2485496 },
    { lat: 48.7603996, lng: 21.2483828 },
    { lat: 48.7577502, lng: 21.2507014 },
    { lat: 48.7572466, lng: 21.2511572 },
    { lat: 48.7564735, lng: 21.2518517 },
    { lat: 48.756421, lng: 21.2518997 },
    { lat: 48.755351, lng: 21.2528534 },
    { lat: 48.7549519, lng: 21.2531955 },
    { lat: 48.7548815, lng: 21.2532486 },
    { lat: 48.7546048, lng: 21.2534333 },
    { lat: 48.7544248, lng: 21.2535438 },
    { lat: 48.7540807, lng: 21.2537 },
    { lat: 48.7539151, lng: 21.2537652 },
    { lat: 48.7522177, lng: 21.2543028 },
    { lat: 48.750409, lng: 21.2548746 },
    { lat: 48.7490171, lng: 21.255321 },
    { lat: 48.7480008, lng: 21.2556428 },
    { lat: 48.7479807, lng: 21.2556492 },
    { lat: 48.7443205, lng: 21.2567988 },
    { lat: 48.7442184, lng: 21.2568081 },
    { lat: 48.7442324, lng: 21.2570792 },
    { lat: 48.744234, lng: 21.2570969 },
    { lat: 48.7442366, lng: 21.257142 },
    { lat: 48.7442401, lng: 21.257222 },
    { lat: 48.74425, lng: 21.2572481 },
    { lat: 48.7442733, lng: 21.257312 },
    { lat: 48.7442893, lng: 21.2573554 },
    { lat: 48.7443101, lng: 21.2574121 },
    { lat: 48.744449, lng: 21.2577881 },
    { lat: 48.7445066, lng: 21.2579452 },
    { lat: 48.7445197, lng: 21.25798 },
    { lat: 48.745333, lng: 21.2601444 },
    { lat: 48.7453849, lng: 21.2602851 },
    { lat: 48.7454362, lng: 21.2604242 },
    { lat: 48.7454902, lng: 21.2605676 },
    { lat: 48.7455073, lng: 21.2606139 },
    { lat: 48.7456044, lng: 21.2608769 },
    { lat: 48.7457382, lng: 21.2612376 },
    { lat: 48.7457492, lng: 21.2612665 },
    { lat: 48.7457504, lng: 21.2612714 },
    { lat: 48.74577, lng: 21.2613231 },
    { lat: 48.7457725, lng: 21.2613301 },
    { lat: 48.7457909, lng: 21.2613798 },
    { lat: 48.7457922, lng: 21.2613854 },
    { lat: 48.7457984, lng: 21.2614021 },
    { lat: 48.7461052, lng: 21.262228 },
    { lat: 48.7461248, lng: 21.2622826 },
    { lat: 48.7462365, lng: 21.2625825 },
    { lat: 48.7462452, lng: 21.2626079 },
    { lat: 48.7462464, lng: 21.2626121 },
    { lat: 48.746255, lng: 21.2626336 },
    { lat: 48.7462844, lng: 21.2627135 },
    { lat: 48.745746, lng: 21.2629155 },
    { lat: 48.7453653, lng: 21.2628696 },
    { lat: 48.7453948, lng: 21.2629859 },
    { lat: 48.7457065, lng: 21.2641885 },
    { lat: 48.7459607, lng: 21.2651704 },
    { lat: 48.7459707, lng: 21.2652061 },
    { lat: 48.7468854, lng: 21.2686705 },
    { lat: 48.7469023, lng: 21.2687424 },
    { lat: 48.7469527, lng: 21.2689567 },
    { lat: 48.7469803, lng: 21.2690104 },
    { lat: 48.7469839, lng: 21.2690173 },
    { lat: 48.7470126, lng: 21.2690716 },
    { lat: 48.7470377, lng: 21.2691205 },
    { lat: 48.7468224, lng: 21.2695118 },
    { lat: 48.7469418, lng: 21.2693326 },
    { lat: 48.7473438, lng: 21.2688643 },
    { lat: 48.7476931, lng: 21.2705977 },
    { lat: 48.7493604, lng: 21.2677649 },
    { lat: 48.7495181, lng: 21.2675509 },
    { lat: 48.74957, lng: 21.2674816 },
    { lat: 48.7496196, lng: 21.2674139 },
    { lat: 48.749772, lng: 21.2672081 },
    { lat: 48.7498109, lng: 21.2671554 },
    { lat: 48.7498865, lng: 21.2670528 },
    { lat: 48.7499297, lng: 21.2669948 },
    { lat: 48.749987, lng: 21.2669173 },
    { lat: 48.750028, lng: 21.2668613 },
    { lat: 48.7501707, lng: 21.2666687 },
    { lat: 48.7501337, lng: 21.2666381 },
    { lat: 48.750254, lng: 21.2664888 },
    { lat: 48.7511307, lng: 21.2653925 },
    { lat: 48.7511107, lng: 21.2652937 },
    { lat: 48.7510614, lng: 21.2650443 },
    { lat: 48.7509856, lng: 21.2646659 },
    { lat: 48.7509589, lng: 21.2645284 },
    { lat: 48.7509523, lng: 21.2644981 },
    { lat: 48.7509691, lng: 21.2645308 },
    { lat: 48.7511408, lng: 21.26488 },
    { lat: 48.7511624, lng: 21.2648496 },
    { lat: 48.7514957, lng: 21.2654074 },
    { lat: 48.7518057, lng: 21.264984 },
    { lat: 48.7518597, lng: 21.264911 },
    { lat: 48.7519126, lng: 21.2648381 },
    { lat: 48.7521729, lng: 21.2644831 },
    { lat: 48.7518977, lng: 21.2640239 },
    { lat: 48.7516284, lng: 21.2635764 },
    { lat: 48.7519969, lng: 21.2620975 },
    { lat: 48.752068, lng: 21.2619927 },
    { lat: 48.7522371, lng: 21.2617472 },
    { lat: 48.7525809, lng: 21.2612568 },
    { lat: 48.7530307, lng: 21.2606252 },
    { lat: 48.7530772, lng: 21.2605606 },
    { lat: 48.7531084, lng: 21.2605177 },
    { lat: 48.7531129, lng: 21.2605124 },
    { lat: 48.7531247, lng: 21.260533 },
    { lat: 48.7532729, lng: 21.2603036 },
    { lat: 48.7533545, lng: 21.2604294 },
    { lat: 48.7537703, lng: 21.2611044 },
    { lat: 48.7539879, lng: 21.2615178 },
    { lat: 48.7541065, lng: 21.2620797 },
    { lat: 48.7541561, lng: 21.2620498 },
    { lat: 48.7541783, lng: 21.2620365 },
    { lat: 48.7542017, lng: 21.2620676 },
    { lat: 48.754347, lng: 21.2625445 },
    { lat: 48.7543884, lng: 21.2626839 },
    { lat: 48.7547626, lng: 21.2639461 },
    { lat: 48.7550709, lng: 21.2636144 },
    { lat: 48.7552832, lng: 21.2633844 },
    { lat: 48.7552911, lng: 21.2634185 },
    { lat: 48.7554368, lng: 21.2640933 },
    { lat: 48.7554708, lng: 21.2642478 },
    { lat: 48.7554841, lng: 21.2643115 },
    { lat: 48.7554984, lng: 21.2643736 },
    { lat: 48.7555456, lng: 21.2645928 },
    { lat: 48.7555652, lng: 21.2646827 },
    { lat: 48.7555928, lng: 21.2648102 },
    { lat: 48.7556022, lng: 21.2648577 },
    { lat: 48.7556519, lng: 21.2650853 },
    { lat: 48.7557362, lng: 21.2649717 },
    { lat: 48.7558128, lng: 21.2648657 },
    { lat: 48.7559964, lng: 21.2646145 },
    { lat: 48.7564143, lng: 21.2640405 },
    { lat: 48.7568764, lng: 21.2634069 },
    { lat: 48.7569239, lng: 21.2633426 },
    { lat: 48.756923, lng: 21.2632767 },
    { lat: 48.7569126, lng: 21.2631957 },
    { lat: 48.756893, lng: 21.2631404 },
    { lat: 48.7569684, lng: 21.2630313 },
    { lat: 48.7569736, lng: 21.2630227 },
    { lat: 48.7569882, lng: 21.2630538 },
    { lat: 48.7570566, lng: 21.2630839 },
    { lat: 48.7571139, lng: 21.2630813 },
    { lat: 48.7571506, lng: 21.2630666 },
    { lat: 48.7571493, lng: 21.2630588 },
    { lat: 48.7571558, lng: 21.2630541 },
    { lat: 48.7571682, lng: 21.2630532 },
    { lat: 48.7572353, lng: 21.2630036 },
    { lat: 48.7573136, lng: 21.2629153 },
    { lat: 48.7573525, lng: 21.2628619 },
    { lat: 48.7573536, lng: 21.262859 },
    { lat: 48.7573567, lng: 21.2628538 },
    { lat: 48.7573771, lng: 21.2628195 },
    { lat: 48.7573712, lng: 21.2628121 },
    { lat: 48.7573743, lng: 21.2628062 },
    { lat: 48.7573898, lng: 21.2627965 },
    { lat: 48.7574928, lng: 21.2625931 },
    { lat: 48.7575578, lng: 21.26251 },
    { lat: 48.7576961, lng: 21.2623284 },
    { lat: 48.7576561, lng: 21.2622677 },
    { lat: 48.7576213, lng: 21.2622377 },
    { lat: 48.7576293, lng: 21.2622025 },
    { lat: 48.7576611, lng: 21.2621775 },
    { lat: 48.757673, lng: 21.2621602 },
    { lat: 48.7576925, lng: 21.2621367 },
    { lat: 48.7576979, lng: 21.2621299 },
    { lat: 48.7577441, lng: 21.262096 },
    { lat: 48.7577808, lng: 21.262046 },
    { lat: 48.7578389, lng: 21.2621414 },
    { lat: 48.757852, lng: 21.2621265 },
    { lat: 48.7579466, lng: 21.2620204 },
    { lat: 48.7579662, lng: 21.2619983 },
    { lat: 48.7580053, lng: 21.2619535 },
    { lat: 48.7580005, lng: 21.2619442 },
    { lat: 48.758007, lng: 21.2619376 },
    { lat: 48.7580423, lng: 21.2619109 },
    { lat: 48.758227, lng: 21.2616603 },
    { lat: 48.7582222, lng: 21.2616514 },
    { lat: 48.7582275, lng: 21.2616439 },
    { lat: 48.7582432, lng: 21.2616381 },
    { lat: 48.7583339, lng: 21.2615148 },
    { lat: 48.7584052, lng: 21.2614166 },
    { lat: 48.7584004, lng: 21.2614073 },
    { lat: 48.7584057, lng: 21.2614002 },
    { lat: 48.7584214, lng: 21.2613945 },
    { lat: 48.7586146, lng: 21.2611308 },
    { lat: 48.7586099, lng: 21.2611219 },
    { lat: 48.7586153, lng: 21.261115 },
    { lat: 48.7586308, lng: 21.2611086 },
    { lat: 48.7589839, lng: 21.2606268 },
    { lat: 48.7589889, lng: 21.2606043 },
    { lat: 48.7589943, lng: 21.2605961 },
    { lat: 48.7589991, lng: 21.2606047 },
    { lat: 48.7592042, lng: 21.2603234 },
    { lat: 48.7592091, lng: 21.2603002 },
    { lat: 48.7592145, lng: 21.2602924 },
    { lat: 48.7592205, lng: 21.2603016 },
    { lat: 48.7594384, lng: 21.2599984 },
    { lat: 48.7594433, lng: 21.2599752 },
    { lat: 48.7594487, lng: 21.259968 },
    { lat: 48.7594546, lng: 21.2599769 },
    { lat: 48.7596749, lng: 21.2596749 },
    { lat: 48.7596797, lng: 21.2596518 },
    { lat: 48.7596851, lng: 21.2596442 },
    { lat: 48.7596899, lng: 21.2596535 },
    { lat: 48.7597377, lng: 21.2595984 },
    { lat: 48.7597842, lng: 21.2595709 },
    { lat: 48.7598211, lng: 21.259564 },
    { lat: 48.7598524, lng: 21.2595575 },
    { lat: 48.7599558, lng: 21.2595884 },
    { lat: 48.760031, lng: 21.2596569 },
    { lat: 48.7601209, lng: 21.2597972 },
    { lat: 48.7602252, lng: 21.2599726 },
    { lat: 48.7602432, lng: 21.2600029 },
    { lat: 48.7602955, lng: 21.2600916 },
    { lat: 48.7603607, lng: 21.2602008 },
    { lat: 48.7604079, lng: 21.2602346 },
    { lat: 48.7604765, lng: 21.260198 },
    { lat: 48.7605133, lng: 21.2602574 },
    { lat: 48.7604981, lng: 21.2602781 },
    { lat: 48.7604776, lng: 21.2603431 },
    { lat: 48.7604936, lng: 21.2604225 },
    { lat: 48.7606358, lng: 21.2606544 },
    { lat: 48.7607269, lng: 21.2607656 },
    { lat: 48.7609599, lng: 21.2609475 },
    { lat: 48.7610332, lng: 21.2609887 },
    { lat: 48.7611432, lng: 21.2610177 },
    { lat: 48.761238, lng: 21.2610249 },
    { lat: 48.7613742, lng: 21.2610271 },
    { lat: 48.7615364, lng: 21.2609974 },
    { lat: 48.7618786, lng: 21.2606673 },
    { lat: 48.7620263, lng: 21.2604614 },
    { lat: 48.7621245, lng: 21.2603221 },
    { lat: 48.7621185, lng: 21.2603122 },
    { lat: 48.762248, lng: 21.2601329 },
    { lat: 48.7623228, lng: 21.2600395 },
    { lat: 48.7624111, lng: 21.2599455 },
    { lat: 48.7625354, lng: 21.2598504 },
    { lat: 48.7626723, lng: 21.2598015 },
    { lat: 48.7627142, lng: 21.259801 },
    { lat: 48.7628072, lng: 21.2597999 },
    { lat: 48.7629691, lng: 21.2598277 },
    { lat: 48.7630995, lng: 21.2598969 },
    { lat: 48.7631759, lng: 21.2599665 },
    { lat: 48.7632505, lng: 21.2600518 },
    { lat: 48.7633347, lng: 21.2601928 },
    { lat: 48.7634072, lng: 21.2603507 },
    { lat: 48.7634405, lng: 21.2604486 },
    { lat: 48.7634317, lng: 21.2604542 },
    { lat: 48.7634671, lng: 21.2606165 },
    { lat: 48.7634953, lng: 21.2608385 },
    { lat: 48.7634909, lng: 21.2610243 },
    { lat: 48.763465, lng: 21.2611681 },
    { lat: 48.7634393, lng: 21.2612848 },
    { lat: 48.7634531, lng: 21.2612924 },
    { lat: 48.7635055, lng: 21.2613123 },
    { lat: 48.7635749, lng: 21.261338 },
    { lat: 48.7636626, lng: 21.2613718 },
    { lat: 48.7637548, lng: 21.2614067 },
    { lat: 48.7637788, lng: 21.2614157 },
    { lat: 48.7636996, lng: 21.2607489 },
    { lat: 48.7640765, lng: 21.2601234 },
    { lat: 48.7646163, lng: 21.2604405 },
    { lat: 48.7648325, lng: 21.2608095 },
    { lat: 48.7649522, lng: 21.2612608 },
    { lat: 48.7652336, lng: 21.2617718 },
    { lat: 48.7654534, lng: 21.2621865 },
    { lat: 48.7656499, lng: 21.26261 },
    { lat: 48.7658331, lng: 21.2630034 },
    { lat: 48.7660384, lng: 21.2634212 },
    { lat: 48.7661566, lng: 21.2634967 },
    { lat: 48.7662076, lng: 21.2646019 },
    { lat: 48.7663577, lng: 21.2648738 },
    { lat: 48.7662551, lng: 21.2650134 },
    { lat: 48.7663843, lng: 21.2653721 },
    { lat: 48.7662796, lng: 21.265662 },
    { lat: 48.7663581, lng: 21.2659461 },
    { lat: 48.7664339, lng: 21.2660713 },
    { lat: 48.7664846, lng: 21.2661812 },
    { lat: 48.7664612, lng: 21.2663348 },
    { lat: 48.766418, lng: 21.2665041 },
    { lat: 48.7664867, lng: 21.2667604 },
    { lat: 48.7665923, lng: 21.2669391 },
    { lat: 48.7666884, lng: 21.2671329 },
    { lat: 48.7669655, lng: 21.2672924 },
    { lat: 48.7671214, lng: 21.2675633 },
    { lat: 48.7673925, lng: 21.2676733 },
    { lat: 48.7674743, lng: 21.2678438 },
    { lat: 48.767681, lng: 21.2679055 },
    { lat: 48.7676156, lng: 21.2680457 },
    { lat: 48.7678646, lng: 21.2682379 },
    { lat: 48.7679792, lng: 21.2686002 },
    { lat: 48.7681755, lng: 21.2686513 },
    { lat: 48.7681628, lng: 21.2687503 },
    { lat: 48.7680177, lng: 21.2689 },
    { lat: 48.7680141, lng: 21.2690743 },
    { lat: 48.7680208, lng: 21.2691077 },
    { lat: 48.7682253, lng: 21.2690266 },
    { lat: 48.7683689, lng: 21.2689766 },
    { lat: 48.7685073, lng: 21.2689358 },
    { lat: 48.7686781, lng: 21.2689309 },
    { lat: 48.76881, lng: 21.2689384 },
    { lat: 48.7691019, lng: 21.2688764 },
    { lat: 48.7691911, lng: 21.2688488 },
    { lat: 48.7692232, lng: 21.2688707 },
    { lat: 48.7693601, lng: 21.2689646 },
    { lat: 48.7694569, lng: 21.2690416 },
    { lat: 48.7695639, lng: 21.2691165 },
    { lat: 48.7696876, lng: 21.269187 },
    { lat: 48.7699029, lng: 21.2691982 },
    { lat: 48.7700297, lng: 21.2692207 },
    { lat: 48.7701457, lng: 21.2692632 },
    { lat: 48.770346, lng: 21.2693696 },
    { lat: 48.770607, lng: 21.2695154 },
    { lat: 48.7706679, lng: 21.2695596 },
    { lat: 48.7707625, lng: 21.269671 },
    { lat: 48.7708668, lng: 21.2697672 },
    { lat: 48.771143, lng: 21.2698933 },
    { lat: 48.7714457, lng: 21.2700743 },
    { lat: 48.7717277, lng: 21.2702085 },
    { lat: 48.7719768, lng: 21.2702959 },
    { lat: 48.7721211, lng: 21.2703735 },
    { lat: 48.7722951, lng: 21.2705403 },
    { lat: 48.7724905, lng: 21.2707114 },
    { lat: 48.7726147, lng: 21.2708732 },
    { lat: 48.7727873, lng: 21.2712535 },
    { lat: 48.7728809, lng: 21.2714795 },
    { lat: 48.7729007, lng: 21.2715738 },
    { lat: 48.7728913, lng: 21.2717799 },
    { lat: 48.7729104, lng: 21.2719234 },
    { lat: 48.773017, lng: 21.272394 },
    { lat: 48.7730525, lng: 21.272486 },
    { lat: 48.7732408, lng: 21.2727529 },
    { lat: 48.7733156, lng: 21.2728054 },
    { lat: 48.7734953, lng: 21.272867 },
    { lat: 48.7736379, lng: 21.2729668 },
    { lat: 48.7740313, lng: 21.2730633 },
    { lat: 48.7741537, lng: 21.273089 },
    { lat: 48.7742283, lng: 21.2731372 },
    { lat: 48.7743121, lng: 21.2733037 },
    { lat: 48.7743607, lng: 21.2734169 },
    { lat: 48.7744332, lng: 21.2738007 },
    { lat: 48.7745075, lng: 21.2740927 },
    { lat: 48.7745605, lng: 21.2743198 },
    { lat: 48.7746444, lng: 21.2744866 },
    { lat: 48.774716, lng: 21.2747285 },
    { lat: 48.7747479, lng: 21.2749217 },
    { lat: 48.7748634, lng: 21.2752747 },
    { lat: 48.7748899, lng: 21.2755868 },
    { lat: 48.7749484, lng: 21.2758071 },
    { lat: 48.7749811, lng: 21.2760314 },
    { lat: 48.7750093, lng: 21.2761057 },
    { lat: 48.7751823, lng: 21.2761335 },
    { lat: 48.7752706, lng: 21.2761473 },
    { lat: 48.7752776, lng: 21.2761226 },
    { lat: 48.7754701, lng: 21.2756837 },
    { lat: 48.7755595, lng: 21.2754819 },
    { lat: 48.7757276, lng: 21.2750184 },
    { lat: 48.775823, lng: 21.2748258 },
    { lat: 48.7762255, lng: 21.2748478 },
    { lat: 48.7763282, lng: 21.2748231 },
    { lat: 48.7766387, lng: 21.2747481 },
    { lat: 48.7767976, lng: 21.2748251 },
    { lat: 48.776826, lng: 21.274837 },
    { lat: 48.7769286, lng: 21.2748798 },
    { lat: 48.7768934, lng: 21.2749771 },
    { lat: 48.776913, lng: 21.274995 },
    { lat: 48.7769188, lng: 21.2749989 },
    { lat: 48.7769762, lng: 21.2750363 },
    { lat: 48.777496, lng: 21.275658 },
    { lat: 48.7776213, lng: 21.2754869 },
    { lat: 48.7776628, lng: 21.2754069 },
    { lat: 48.7777488, lng: 21.2753509 },
    { lat: 48.7778628, lng: 21.275289 },
    { lat: 48.7781059, lng: 21.2753593 },
    { lat: 48.7782007, lng: 21.2753712 },
    { lat: 48.7785633, lng: 21.275416 },
    { lat: 48.7790358, lng: 21.2754071 },
    { lat: 48.7794085, lng: 21.275382 },
    { lat: 48.7794928, lng: 21.275376 },
    { lat: 48.7797551, lng: 21.2753783 },
    { lat: 48.7801971, lng: 21.2753718 },
    { lat: 48.7805158, lng: 21.2753441 },
    { lat: 48.7809917, lng: 21.2753021 },
    { lat: 48.7813652, lng: 21.2753361 },
    { lat: 48.7816095, lng: 21.2753743 },
    { lat: 48.7817263, lng: 21.2754035 },
    { lat: 48.7819135, lng: 21.2754517 },
    { lat: 48.7822386, lng: 21.275522 },
    { lat: 48.7823299, lng: 21.2754895 },
    { lat: 48.7824042, lng: 21.2754883 },
    { lat: 48.7824087, lng: 21.2754883 },
    { lat: 48.7824911, lng: 21.2754964 },
    { lat: 48.7825764, lng: 21.2755625 },
    { lat: 48.7827553, lng: 21.2755992 },
    { lat: 48.7829927, lng: 21.2756364 },
    { lat: 48.7831744, lng: 21.2756882 },
    { lat: 48.7835006, lng: 21.2757944 },
    { lat: 48.7837065, lng: 21.2758271 },
    { lat: 48.7841591, lng: 21.2757981 },
    { lat: 48.7843874, lng: 21.2757963 },
    { lat: 48.7846341, lng: 21.2758011 },
    { lat: 48.7848613, lng: 21.2757616 },
    { lat: 48.7849412, lng: 21.2757253 },
    { lat: 48.7850254, lng: 21.2756823 },
    { lat: 48.785386, lng: 21.2755135 },
    { lat: 48.7856853, lng: 21.2754032 },
    { lat: 48.7858254, lng: 21.2753844 },
    { lat: 48.7860557, lng: 21.275375 },
    { lat: 48.7865441, lng: 21.27537 },
    { lat: 48.7865767, lng: 21.2753352 },
    { lat: 48.7867255, lng: 21.2753422 },
    { lat: 48.7867836, lng: 21.2754017 },
    { lat: 48.787016, lng: 21.2754221 },
    { lat: 48.7871269, lng: 21.2754039 },
    { lat: 48.7872558, lng: 21.2753513 },
    { lat: 48.7874395, lng: 21.2752474 },
    { lat: 48.7876876, lng: 21.2750826 },
    { lat: 48.7878009, lng: 21.2750007 },
    { lat: 48.7879355, lng: 21.2748742 },
    { lat: 48.7880388, lng: 21.274756 },
    { lat: 48.78823, lng: 21.2744977 },
    { lat: 48.7883477, lng: 21.2743699 },
    { lat: 48.7884208, lng: 21.2742991 },
    { lat: 48.7886638, lng: 21.2741089 },
    { lat: 48.7887798, lng: 21.2740393 },
    { lat: 48.7889291, lng: 21.2739917 },
    { lat: 48.7891948, lng: 21.2739584 },
    { lat: 48.7897222, lng: 21.2739081 },
    { lat: 48.7902828, lng: 21.2738412 },
    { lat: 48.7906937, lng: 21.2738091 },
    { lat: 48.7909891, lng: 21.2737919 },
    { lat: 48.7911022, lng: 21.2737739 },
    { lat: 48.7912006, lng: 21.273752 },
    { lat: 48.7912693, lng: 21.2737165 },
    { lat: 48.7913643, lng: 21.2736581 },
    { lat: 48.791475, lng: 21.2735614 },
    { lat: 48.7916288, lng: 21.2734414 },
    { lat: 48.7917762, lng: 21.27337 },
    { lat: 48.7919011, lng: 21.2733316 },
    { lat: 48.7920646, lng: 21.2733029 },
    { lat: 48.7923195, lng: 21.2732861 },
    { lat: 48.7926192, lng: 21.2732625 },
    { lat: 48.7928902, lng: 21.2732199 },
    { lat: 48.7932236, lng: 21.2731564 },
    { lat: 48.7933651, lng: 21.2731458 },
    { lat: 48.7934588, lng: 21.2731549 },
    { lat: 48.7936318, lng: 21.2731834 },
    { lat: 48.7937252, lng: 21.2732197 },
    { lat: 48.7938195, lng: 21.273283 },
    { lat: 48.7939321, lng: 21.2733607 },
    { lat: 48.7940528, lng: 21.273444 },
    { lat: 48.7941417, lng: 21.2734816 },
    { lat: 48.794144, lng: 21.27347 },
    { lat: 48.794792, lng: 21.273545 },
    { lat: 48.795258, lng: 21.273299 },
    { lat: 48.79563, lng: 21.273178 },
    { lat: 48.796085, lng: 21.273265 },
    { lat: 48.797282, lng: 21.272793 },
    { lat: 48.797845, lng: 21.272882 },
    { lat: 48.798575, lng: 21.273241 },
    { lat: 48.79867, lng: 21.273288 },
    { lat: 48.798994, lng: 21.27367 },
    { lat: 48.799106, lng: 21.273752 },
    { lat: 48.799137, lng: 21.273775 },
    { lat: 48.799257, lng: 21.273863 },
    { lat: 48.799907, lng: 21.274113 },
    { lat: 48.800474, lng: 21.274545 },
    { lat: 48.801016, lng: 21.274586 },
    { lat: 48.800937, lng: 21.273453 },
    { lat: 48.800703, lng: 21.272698 },
    { lat: 48.800665, lng: 21.271941 },
    { lat: 48.79906, lng: 21.270933 },
    { lat: 48.799312, lng: 21.270367 },
    { lat: 48.798544, lng: 21.269966 },
    { lat: 48.798502, lng: 21.269961 },
    { lat: 48.797976, lng: 21.269799 },
    { lat: 48.796472, lng: 21.269686 },
    { lat: 48.796387, lng: 21.26968 },
    { lat: 48.79633, lng: 21.269685 },
    { lat: 48.79608, lng: 21.269707 },
    { lat: 48.795911, lng: 21.26979 },
    { lat: 48.795302, lng: 21.270093 },
    { lat: 48.79496, lng: 21.270719 },
    { lat: 48.794661, lng: 21.269474 },
    { lat: 48.794301, lng: 21.267907 },
    { lat: 48.793874, lng: 21.266566 },
    { lat: 48.793151, lng: 21.26562 },
    { lat: 48.79318, lng: 21.265381 },
    { lat: 48.792682, lng: 21.265569 },
    { lat: 48.791593, lng: 21.26598 },
    { lat: 48.791357, lng: 21.265998 },
    { lat: 48.791303, lng: 21.266002 },
    { lat: 48.78937, lng: 21.266149 },
    { lat: 48.788059, lng: 21.264017 },
    { lat: 48.787447, lng: 21.262795 },
    { lat: 48.786754, lng: 21.262869 },
    { lat: 48.786433, lng: 21.264133 },
    { lat: 48.786308, lng: 21.26448 },
    { lat: 48.785691, lng: 21.264286 },
    { lat: 48.785033, lng: 21.263703 },
    { lat: 48.784059, lng: 21.263515 },
    { lat: 48.784031, lng: 21.263374 },
    { lat: 48.783829, lng: 21.262351 },
    { lat: 48.784763, lng: 21.261714 },
    { lat: 48.785506, lng: 21.261069 },
    { lat: 48.785726, lng: 21.260232 },
    { lat: 48.785632, lng: 21.260024 },
    { lat: 48.784988, lng: 21.25957 },
    { lat: 48.784725, lng: 21.259082 },
    { lat: 48.784556, lng: 21.258767 },
    { lat: 48.784438, lng: 21.258227 },
    { lat: 48.784133, lng: 21.256577 },
    { lat: 48.784072, lng: 21.256357 },
    { lat: 48.783769, lng: 21.255257 },
    { lat: 48.783763, lng: 21.255233 },
    { lat: 48.783755, lng: 21.255204 },
    { lat: 48.782734, lng: 21.251493 },
    { lat: 48.782465, lng: 21.251432 },
    { lat: 48.782443, lng: 21.251426 },
    { lat: 48.782241, lng: 21.25138 },
    { lat: 48.781746, lng: 21.251243 },
    { lat: 48.780739, lng: 21.251174 },
    { lat: 48.780518, lng: 21.25198 },
    { lat: 48.779784, lng: 21.251823 },
    { lat: 48.780208, lng: 21.250322 },
    { lat: 48.780312, lng: 21.249793 },
    { lat: 48.78017, lng: 21.249604 },
    { lat: 48.780448, lng: 21.247811 },
    { lat: 48.780477, lng: 21.247301 },
    { lat: 48.779927, lng: 21.247362 },
    { lat: 48.780171, lng: 21.246313 },
    { lat: 48.780225, lng: 21.246081 },
    { lat: 48.780271, lng: 21.245928 },
    { lat: 48.780357, lng: 21.245646 },
    { lat: 48.780672, lng: 21.24464 },
    { lat: 48.780702, lng: 21.244561 },
  ],
};

export default UnitsKošiceI;
