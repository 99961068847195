import { motion } from "framer-motion";
import PropertyEssenceFS from "./PropertyEssenceFS";
import FsOwner from "./FsOwner";
import FsTarcha from "./FsTarcha";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

export default function Lv({ subMenu, previous, forSale, lvSubMenu, setLvSubMenu }) {
  const lv = forSale?.lv ? JSON.parse(forSale?.lv) : "";
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return subMenu?.lv ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll text-xs"
      initial={{ x: previous !== "ow" ? -500 : 500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: previous === "" ? null : 0.5 }}
    >
      <>
        <div
          className={clsx("flex flex-row justify-between", {
            "w-full": !isSmallDevice,
          })}
        >
          <div className="flex flex-col p-3 w-full items-center">
            <p>Okres</p>
            <p className="font-bold">
              {lv?.List instanceof Array ? lv?.List[0]?.nazov_okresu : lv?.List?.nazov_okresu}
            </p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Obec</p>
            <p className="font-bold">{lv?.List instanceof Array ? lv?.List[0]?.nazov_obce : lv?.List?.nazov_obce}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Katastrálne územie</p>
            <p className="font-bold">
              {lv?.List instanceof Array ? lv?.List[0]?.kod_katastralneho_uzemia : lv?.List?.kod_katastralneho_uzemia}
            </p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Údaje platné k</p>
            <p className="font-bold">{lv?.List instanceof Array ? lv?.List[0]?.stav_ku_dnu : lv?.List?.stav_ku_dnu}</p>
          </div>
        </div>
        <div
          className={clsx("flex justify-center text-center py-8", {
            "w-full py-5": !isSmallDevice,
          })}
        >
          <h1 className="text-xl font-medium uppercase">
            Náhľad listu vlastníctva č.{" "}
            {lv?.List instanceof Array ? lv?.List[0]?.cislo_listu_vlastnictva : lv?.List?.cislo_listu_vlastnictva}
          </h1>
        </div>
        <PropertyEssenceFS
          setLvSubMenu={setLvSubMenu}
          lvSubMenu={lvSubMenu}
          lv={lv?.List instanceof Array ? lv?.List[0] : lv?.List}
        />
        <FsOwner
          setLvSubMenu={setLvSubMenu}
          lvSubMenu={lvSubMenu}
          lv={lv?.List instanceof Array ? lv?.List[0] : lv?.List}
        />
        <FsTarcha
          setLvSubMenu={setLvSubMenu}
          lvSubMenu={lvSubMenu}
          lv={lv?.List instanceof Array ? lv?.List[0] : lv?.List}
        />
      </>
    </motion.div>
  ) : null;
}
