export const registrationService = {
  setRegistration: async (request) => {
    return await fetch(process.env.REACT_APP_AUTH_SERVICE + `signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  },
};
