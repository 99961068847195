const UnitsMedzilaborce = {
  Roškovce: [
    { lat: 49.261047, lng: 21.827694 },
    { lat: 49.260889, lng: 21.827406 },
    { lat: 49.260624, lng: 21.827428 },
    { lat: 49.260296, lng: 21.827203 },
    { lat: 49.259924, lng: 21.826886 },
    { lat: 49.259528, lng: 21.826752 },
    { lat: 49.259247, lng: 21.826771 },
    { lat: 49.258979, lng: 21.826927 },
    { lat: 49.258983, lng: 21.826978 },
    { lat: 49.25807, lng: 21.82768 },
    { lat: 49.257869, lng: 21.827747 },
    { lat: 49.257571, lng: 21.827755 },
    { lat: 49.257398, lng: 21.827601 },
    { lat: 49.256978, lng: 21.827538 },
    { lat: 49.256465, lng: 21.827625 },
    { lat: 49.256266, lng: 21.827592 },
    { lat: 49.25593, lng: 21.827344 },
    { lat: 49.255838, lng: 21.827207 },
    { lat: 49.25567, lng: 21.827087 },
    { lat: 49.255156, lng: 21.826636 },
    { lat: 49.254617, lng: 21.826442 },
    { lat: 49.254268, lng: 21.826338 },
    { lat: 49.254092, lng: 21.826233 },
    { lat: 49.253815, lng: 21.826008 },
    { lat: 49.253429, lng: 21.825995 },
    { lat: 49.253395, lng: 21.82601 },
    { lat: 49.252921, lng: 21.826466 },
    { lat: 49.252934, lng: 21.826557 },
    { lat: 49.252346, lng: 21.827095 },
    { lat: 49.252083, lng: 21.827136 },
    { lat: 49.251797, lng: 21.8275 },
    { lat: 49.251291, lng: 21.828045 },
    { lat: 49.250793, lng: 21.828516 },
    { lat: 49.250605, lng: 21.828707 },
    { lat: 49.250621, lng: 21.829029 },
    { lat: 49.250374, lng: 21.829307 },
    { lat: 49.249689, lng: 21.830624 },
    { lat: 49.249423, lng: 21.831062 },
    { lat: 49.249271, lng: 21.831254 },
    { lat: 49.249051, lng: 21.831451 },
    { lat: 49.248844, lng: 21.831776 },
    { lat: 49.248529, lng: 21.831989 },
    { lat: 49.248097, lng: 21.832673 },
    { lat: 49.247882, lng: 21.833148 },
    { lat: 49.247702, lng: 21.833056 },
    { lat: 49.247675, lng: 21.832952 },
    { lat: 49.247698, lng: 21.832752 },
    { lat: 49.247597, lng: 21.83243 },
    { lat: 49.247439, lng: 21.832353 },
    { lat: 49.24689, lng: 21.832327 },
    { lat: 49.246761, lng: 21.831962 },
    { lat: 49.246471, lng: 21.83187 },
    { lat: 49.246063, lng: 21.831799 },
    { lat: 49.245794, lng: 21.832203 },
    { lat: 49.245384, lng: 21.831785 },
    { lat: 49.244584, lng: 21.831533 },
    { lat: 49.244398, lng: 21.831568 },
    { lat: 49.244069, lng: 21.831428 },
    { lat: 49.242909, lng: 21.831777 },
    { lat: 49.242047, lng: 21.830882 },
    { lat: 49.241734, lng: 21.829648 },
    { lat: 49.241756, lng: 21.828219 },
    { lat: 49.241589, lng: 21.827691 },
    { lat: 49.241561, lng: 21.827336 },
    { lat: 49.241399, lng: 21.826881 },
    { lat: 49.240955, lng: 21.826841 },
    { lat: 49.24077, lng: 21.826729 },
    { lat: 49.240311, lng: 21.826522 },
    { lat: 49.239698, lng: 21.826097 },
    { lat: 49.239405, lng: 21.825885 },
    { lat: 49.239259, lng: 21.825658 },
    { lat: 49.238994, lng: 21.825706 },
    { lat: 49.238761, lng: 21.825656 },
    { lat: 49.238405, lng: 21.825558 },
    { lat: 49.238288, lng: 21.825389 },
    { lat: 49.238108, lng: 21.825325 },
    { lat: 49.237734, lng: 21.825499 },
    { lat: 49.237317, lng: 21.825561 },
    { lat: 49.237103, lng: 21.825304 },
    { lat: 49.237032, lng: 21.825281 },
    { lat: 49.236897, lng: 21.825246 },
    { lat: 49.236805, lng: 21.825028 },
    { lat: 49.23659, lng: 21.825112 },
    { lat: 49.236413, lng: 21.824751 },
    { lat: 49.236145, lng: 21.825087 },
    { lat: 49.235831, lng: 21.82512 },
    { lat: 49.235669, lng: 21.825369 },
    { lat: 49.235435, lng: 21.825083 },
    { lat: 49.235044, lng: 21.825085 },
    { lat: 49.234937, lng: 21.825083 },
    { lat: 49.234445, lng: 21.825024 },
    { lat: 49.234227, lng: 21.825079 },
    { lat: 49.233654, lng: 21.825081 },
    { lat: 49.23351, lng: 21.8251 },
    { lat: 49.233292, lng: 21.824889 },
    { lat: 49.233026, lng: 21.824752 },
    { lat: 49.232656, lng: 21.824863 },
    { lat: 49.232386, lng: 21.824964 },
    { lat: 49.232503, lng: 21.824641 },
    { lat: 49.23223, lng: 21.824547 },
    { lat: 49.232195, lng: 21.824403 },
    { lat: 49.232091, lng: 21.824526 },
    { lat: 49.232116, lng: 21.824381 },
    { lat: 49.232077, lng: 21.824262 },
    { lat: 49.231783, lng: 21.824308 },
    { lat: 49.231403, lng: 21.824438 },
    { lat: 49.231284, lng: 21.824341 },
    { lat: 49.230975, lng: 21.82445 },
    { lat: 49.230912, lng: 21.82461 },
    { lat: 49.230485, lng: 21.824752 },
    { lat: 49.230289, lng: 21.824631 },
    { lat: 49.230074, lng: 21.824461 },
    { lat: 49.2300454, lng: 21.8254904 },
    { lat: 49.2302881, lng: 21.8274024 },
    { lat: 49.2301621, lng: 21.8280603 },
    { lat: 49.2305171, lng: 21.8305865 },
    { lat: 49.2302188, lng: 21.8310099 },
    { lat: 49.2301213, lng: 21.8315088 },
    { lat: 49.229777, lng: 21.8321578 },
    { lat: 49.2291716, lng: 21.8347806 },
    { lat: 49.2289339, lng: 21.8362207 },
    { lat: 49.2282453, lng: 21.8368693 },
    { lat: 49.2267582, lng: 21.8369055 },
    { lat: 49.2261646, lng: 21.8377946 },
    { lat: 49.2257595, lng: 21.8380091 },
    { lat: 49.2249781, lng: 21.8380248 },
    { lat: 49.2239466, lng: 21.838769 },
    { lat: 49.2235522, lng: 21.8391348 },
    { lat: 49.2228907, lng: 21.8392696 },
    { lat: 49.2223598, lng: 21.8392671 },
    { lat: 49.2216866, lng: 21.8399477 },
    { lat: 49.2214317, lng: 21.8404934 },
    { lat: 49.221184, lng: 21.8408363 },
    { lat: 49.2210875, lng: 21.8415901 },
    { lat: 49.2202583, lng: 21.8425456 },
    { lat: 49.2196745, lng: 21.8429899 },
    { lat: 49.2193053, lng: 21.8431551 },
    { lat: 49.2188478, lng: 21.8435213 },
    { lat: 49.2184213, lng: 21.8436489 },
    { lat: 49.2181519, lng: 21.8438416 },
    { lat: 49.2175529, lng: 21.8438186 },
    { lat: 49.2171117, lng: 21.8441173 },
    { lat: 49.2168766, lng: 21.8451272 },
    { lat: 49.2164639, lng: 21.8475085 },
    { lat: 49.2162454, lng: 21.8485181 },
    { lat: 49.2173271, lng: 21.8484991 },
    { lat: 49.2193909, lng: 21.8480026 },
    { lat: 49.2208291, lng: 21.8471934 },
    { lat: 49.2228206, lng: 21.8471688 },
    { lat: 49.2233915, lng: 21.8488683 },
    { lat: 49.2236799, lng: 21.8503924 },
    { lat: 49.2245751, lng: 21.8508239 },
    { lat: 49.2248018, lng: 21.8514726 },
    { lat: 49.2252162, lng: 21.8514465 },
    { lat: 49.2254677, lng: 21.8532784 },
    { lat: 49.2257547, lng: 21.8537285 },
    { lat: 49.2265078, lng: 21.8537097 },
    { lat: 49.2271701, lng: 21.8535689 },
    { lat: 49.2280258, lng: 21.8539553 },
    { lat: 49.2287787, lng: 21.8544544 },
    { lat: 49.2301467, lng: 21.8541976 },
    { lat: 49.231027, lng: 21.854222 },
    { lat: 49.2314326, lng: 21.8551373 },
    { lat: 49.2319198, lng: 21.8548798 },
    { lat: 49.2320128, lng: 21.8559663 },
    { lat: 49.2326784, lng: 21.8576339 },
    { lat: 49.2337227, lng: 21.8588069 },
    { lat: 49.2338941, lng: 21.8598876 },
    { lat: 49.2340264, lng: 21.8639462 },
    { lat: 49.2342748, lng: 21.8646046 },
    { lat: 49.2358087, lng: 21.8675579 },
    { lat: 49.236576, lng: 21.8687709 },
    { lat: 49.2375196, lng: 21.8705451 },
    { lat: 49.2397733, lng: 21.8720805 },
    { lat: 49.2438978, lng: 21.8732835 },
    { lat: 49.2445539, lng: 21.8734748 },
    { lat: 49.2460933, lng: 21.8717255 },
    { lat: 49.2470071, lng: 21.870945 },
    { lat: 49.2470822, lng: 21.8723569 },
    { lat: 49.2478897, lng: 21.8711453 },
    { lat: 49.2489643, lng: 21.8705875 },
    { lat: 49.2496619, lng: 21.8704192 },
    { lat: 49.2515135, lng: 21.869404 },
    { lat: 49.2527898, lng: 21.8685465 },
    { lat: 49.2556415, lng: 21.8681655 },
    { lat: 49.2561602, lng: 21.868087 },
    { lat: 49.2594738, lng: 21.8652871 },
    { lat: 49.2620875, lng: 21.8625262 },
    { lat: 49.2623455, lng: 21.8630266 },
    { lat: 49.2645376, lng: 21.8618326 },
    { lat: 49.2653678, lng: 21.8611301 },
    { lat: 49.265796, lng: 21.8591224 },
    { lat: 49.2659338, lng: 21.8581274 },
    { lat: 49.2655658, lng: 21.8570094 },
    { lat: 49.2657154, lng: 21.8567008 },
    { lat: 49.2667129, lng: 21.8556249 },
    { lat: 49.2680667, lng: 21.8539288 },
    { lat: 49.2684224, lng: 21.8535875 },
    { lat: 49.2697902, lng: 21.8519149 },
    { lat: 49.270269, lng: 21.8506564 },
    { lat: 49.2714883, lng: 21.8497349 },
    { lat: 49.2719101, lng: 21.8498225 },
    { lat: 49.2722672, lng: 21.8499799 },
    { lat: 49.27265, lng: 21.849588 },
    { lat: 49.27257, lng: 21.848933 },
    { lat: 49.272435, lng: 21.848606 },
    { lat: 49.272204, lng: 21.848425 },
    { lat: 49.272069, lng: 21.848258 },
    { lat: 49.271993, lng: 21.847473 },
    { lat: 49.271668, lng: 21.847077 },
    { lat: 49.27147, lng: 21.847133 },
    { lat: 49.271338, lng: 21.847317 },
    { lat: 49.271171, lng: 21.847496 },
    { lat: 49.270663, lng: 21.847615 },
    { lat: 49.270046, lng: 21.847514 },
    { lat: 49.269782, lng: 21.84749 },
    { lat: 49.26938, lng: 21.847524 },
    { lat: 49.26895, lng: 21.847346 },
    { lat: 49.268596, lng: 21.847466 },
    { lat: 49.268211, lng: 21.847428 },
    { lat: 49.267563, lng: 21.847175 },
    { lat: 49.266733, lng: 21.846434 },
    { lat: 49.266344, lng: 21.845992 },
    { lat: 49.266183, lng: 21.845824 },
    { lat: 49.265818, lng: 21.845358 },
    { lat: 49.26527, lng: 21.844686 },
    { lat: 49.264807, lng: 21.844045 },
    { lat: 49.264425, lng: 21.843637 },
    { lat: 49.263786, lng: 21.842737 },
    { lat: 49.263564, lng: 21.842293 },
    { lat: 49.263135, lng: 21.841822 },
    { lat: 49.2627, lng: 21.840995 },
    { lat: 49.262866, lng: 21.84042 },
    { lat: 49.263241, lng: 21.839014 },
    { lat: 49.263595, lng: 21.837961 },
    { lat: 49.263986, lng: 21.836375 },
    { lat: 49.264067, lng: 21.835449 },
    { lat: 49.264425, lng: 21.834926 },
    { lat: 49.264677, lng: 21.834353 },
    { lat: 49.264896, lng: 21.833831 },
    { lat: 49.264849, lng: 21.8335 },
    { lat: 49.264663, lng: 21.833084 },
    { lat: 49.26424, lng: 21.832434 },
    { lat: 49.264, lng: 21.832027 },
    { lat: 49.263703, lng: 21.831278 },
    { lat: 49.263391, lng: 21.831058 },
    { lat: 49.262963, lng: 21.830932 },
    { lat: 49.262559, lng: 21.830515 },
    { lat: 49.262377, lng: 21.830267 },
    { lat: 49.261944, lng: 21.829456 },
    { lat: 49.261565, lng: 21.828613 },
    { lat: 49.261077, lng: 21.82773 },
    { lat: 49.261047, lng: 21.827694 },
  ],
  Medzilaborce: [
    { lat: 49.3198345, lng: 21.9779751 },
    { lat: 49.3201281, lng: 21.9777343 },
    { lat: 49.3204703, lng: 21.9779012 },
    { lat: 49.3208549, lng: 21.9782179 },
    { lat: 49.321185, lng: 21.978516 },
    { lat: 49.321632, lng: 21.978493 },
    { lat: 49.32264, lng: 21.978488 },
    { lat: 49.3233233, lng: 21.9784582 },
    { lat: 49.3239181, lng: 21.9781774 },
    { lat: 49.3247561, lng: 21.9779932 },
    { lat: 49.3250201, lng: 21.9780225 },
    { lat: 49.3255462, lng: 21.977846 },
    { lat: 49.325912, lng: 21.97764 },
    { lat: 49.3263907, lng: 21.9773731 },
    { lat: 49.3269349, lng: 21.9769957 },
    { lat: 49.3278029, lng: 21.9763765 },
    { lat: 49.328268, lng: 21.9760689 },
    { lat: 49.3287578, lng: 21.9758438 },
    { lat: 49.329236, lng: 21.975663 },
    { lat: 49.329685, lng: 21.975503 },
    { lat: 49.3300251, lng: 21.9753825 },
    { lat: 49.3307669, lng: 21.974761 },
    { lat: 49.331297, lng: 21.974521 },
    { lat: 49.3316344, lng: 21.9743764 },
    { lat: 49.3319982, lng: 21.9740956 },
    { lat: 49.3324609, lng: 21.9737179 },
    { lat: 49.332703, lng: 21.973465 },
    { lat: 49.3329671, lng: 21.9731909 },
    { lat: 49.3334588, lng: 21.9728254 },
    { lat: 49.3338431, lng: 21.9700482 },
    { lat: 49.3339995, lng: 21.9683952 },
    { lat: 49.3337928, lng: 21.9665224 },
    { lat: 49.3337802, lng: 21.9653579 },
    { lat: 49.333523, lng: 21.9641819 },
    { lat: 49.3328928, lng: 21.9628324 },
    { lat: 49.3327441, lng: 21.9622643 },
    { lat: 49.3327258, lng: 21.9618806 },
    { lat: 49.332637, lng: 21.9613826 },
    { lat: 49.3326005, lng: 21.9604907 },
    { lat: 49.3326628, lng: 21.9591734 },
    { lat: 49.3325487, lng: 21.9580956 },
    { lat: 49.3326098, lng: 21.9572908 },
    { lat: 49.3323959, lng: 21.9555379 },
    { lat: 49.3321274, lng: 21.9546773 },
    { lat: 49.3315829, lng: 21.954079 },
    { lat: 49.3313203, lng: 21.9535027 },
    { lat: 49.3312057, lng: 21.9529125 },
    { lat: 49.3309247, lng: 21.9526906 },
    { lat: 49.330839, lng: 21.9524518 },
    { lat: 49.3303623, lng: 21.9518633 },
    { lat: 49.3299543, lng: 21.9515507 },
    { lat: 49.3297619, lng: 21.9511366 },
    { lat: 49.3296733, lng: 21.9502351 },
    { lat: 49.3292029, lng: 21.9498637 },
    { lat: 49.3280286, lng: 21.9486664 },
    { lat: 49.3275919, lng: 21.9480565 },
    { lat: 49.327256, lng: 21.947756 },
    { lat: 49.326807, lng: 21.9475612 },
    { lat: 49.3259476, lng: 21.9473291 },
    { lat: 49.3249754, lng: 21.9471648 },
    { lat: 49.3232528, lng: 21.9475571 },
    { lat: 49.3228542, lng: 21.9474845 },
    { lat: 49.3224381, lng: 21.9467131 },
    { lat: 49.322213, lng: 21.945833 },
    { lat: 49.3226201, lng: 21.94556 },
    { lat: 49.3224296, lng: 21.944626 },
    { lat: 49.3222356, lng: 21.9441465 },
    { lat: 49.3219963, lng: 21.9438005 },
    { lat: 49.3220343, lng: 21.9436944 },
    { lat: 49.3220226, lng: 21.9435379 },
    { lat: 49.3219219, lng: 21.9433339 },
    { lat: 49.3212592, lng: 21.9426831 },
    { lat: 49.320713, lng: 21.9426115 },
    { lat: 49.3204405, lng: 21.942456 },
    { lat: 49.3192501, lng: 21.9421717 },
    { lat: 49.317053, lng: 21.9409537 },
    { lat: 49.3165471, lng: 21.9406055 },
    { lat: 49.3164972, lng: 21.9406239 },
    { lat: 49.3164485, lng: 21.9403894 },
    { lat: 49.316115, lng: 21.9396812 },
    { lat: 49.3157497, lng: 21.9393659 },
    { lat: 49.3155331, lng: 21.9383846 },
    { lat: 49.3151017, lng: 21.9372743 },
    { lat: 49.3149287, lng: 21.9369591 },
    { lat: 49.314813, lng: 21.9358706 },
    { lat: 49.3147527, lng: 21.9357476 },
    { lat: 49.3146598, lng: 21.9349916 },
    { lat: 49.3143659, lng: 21.9345713 },
    { lat: 49.3140745, lng: 21.9346181 },
    { lat: 49.3140478, lng: 21.9345371 },
    { lat: 49.3139251, lng: 21.934485 },
    { lat: 49.3138095, lng: 21.9342711 },
    { lat: 49.3137316, lng: 21.9342536 },
    { lat: 49.3137152, lng: 21.9340977 },
    { lat: 49.3135404, lng: 21.933711 },
    { lat: 49.3135517, lng: 21.9335744 },
    { lat: 49.3135106, lng: 21.9334564 },
    { lat: 49.3134326, lng: 21.9334025 },
    { lat: 49.3134005, lng: 21.9331888 },
    { lat: 49.3133253, lng: 21.9331127 },
    { lat: 49.3132743, lng: 21.9328619 },
    { lat: 49.3131867, lng: 21.9328717 },
    { lat: 49.3128677, lng: 21.9325718 },
    { lat: 49.3127175, lng: 21.93218 },
    { lat: 49.3123833, lng: 21.9319903 },
    { lat: 49.3122838, lng: 21.9318335 },
    { lat: 49.3120812, lng: 21.9318758 },
    { lat: 49.3120113, lng: 21.9319581 },
    { lat: 49.3117096, lng: 21.931764 },
    { lat: 49.3115792, lng: 21.9315801 },
    { lat: 49.3114331, lng: 21.9315457 },
    { lat: 49.3114228, lng: 21.9314407 },
    { lat: 49.3112926, lng: 21.9314093 },
    { lat: 49.3108287, lng: 21.9309401 },
    { lat: 49.3104268, lng: 21.930241 },
    { lat: 49.3103248, lng: 21.9302048 },
    { lat: 49.30994, lng: 21.9309721 },
    { lat: 49.3095576, lng: 21.9312513 },
    { lat: 49.3089616, lng: 21.9295188 },
    { lat: 49.3087048, lng: 21.9297345 },
    { lat: 49.3084789, lng: 21.9298326 },
    { lat: 49.3084074, lng: 21.9296281 },
    { lat: 49.3082356, lng: 21.9294131 },
    { lat: 49.3080392, lng: 21.9287894 },
    { lat: 49.3079891, lng: 21.928319 },
    { lat: 49.3086381, lng: 21.9267289 },
    { lat: 49.3078875, lng: 21.9250773 },
    { lat: 49.3072118, lng: 21.9228644 },
    { lat: 49.30674, lng: 21.9217179 },
    { lat: 49.3066641, lng: 21.9215089 },
    { lat: 49.3069941, lng: 21.9213967 },
    { lat: 49.3073907, lng: 21.9209657 },
    { lat: 49.3076628, lng: 21.9205519 },
    { lat: 49.3075405, lng: 21.9196637 },
    { lat: 49.3081301, lng: 21.9183621 },
    { lat: 49.308957, lng: 21.9177394 },
    { lat: 49.3097087, lng: 21.9169398 },
    { lat: 49.3109075, lng: 21.9159379 },
    { lat: 49.3114909, lng: 21.9157915 },
    { lat: 49.3122583, lng: 21.914629 },
    { lat: 49.3139953, lng: 21.9125278 },
    { lat: 49.3140802, lng: 21.9123689 },
    { lat: 49.3140743, lng: 21.9117723 },
    { lat: 49.3138076, lng: 21.9113621 },
    { lat: 49.3141986, lng: 21.9111637 },
    { lat: 49.3142951, lng: 21.9108809 },
    { lat: 49.3146121, lng: 21.9106944 },
    { lat: 49.3148915, lng: 21.9106244 },
    { lat: 49.3157333, lng: 21.9100995 },
    { lat: 49.316298, lng: 21.9099681 },
    { lat: 49.3166996, lng: 21.90979 },
    { lat: 49.3176058, lng: 21.9095976 },
    { lat: 49.3183291, lng: 21.9090121 },
    { lat: 49.3192396, lng: 21.9086363 },
    { lat: 49.3206195, lng: 21.9089964 },
    { lat: 49.3214807, lng: 21.9091077 },
    { lat: 49.3218817, lng: 21.9087345 },
    { lat: 49.3252242, lng: 21.9088593 },
    { lat: 49.3294674, lng: 21.9087151 },
    { lat: 49.3299931, lng: 21.9087782 },
    { lat: 49.3312689, lng: 21.9087066 },
    { lat: 49.332215, lng: 21.9083495 },
    { lat: 49.332588, lng: 21.9084663 },
    { lat: 49.3331917, lng: 21.9085003 },
    { lat: 49.3336485, lng: 21.9088256 },
    { lat: 49.3338745, lng: 21.9088712 },
    { lat: 49.3345588, lng: 21.9086119 },
    { lat: 49.3348407, lng: 21.9086402 },
    { lat: 49.3361429, lng: 21.9082277 },
    { lat: 49.3370705, lng: 21.9082637 },
    { lat: 49.3375086, lng: 21.9084206 },
    { lat: 49.3376465, lng: 21.9084036 },
    { lat: 49.3353123, lng: 21.9057697 },
    { lat: 49.3345455, lng: 21.9031663 },
    { lat: 49.3342828, lng: 21.9026901 },
    { lat: 49.3342375, lng: 21.9023191 },
    { lat: 49.3341208, lng: 21.9020158 },
    { lat: 49.3337177, lng: 21.9015987 },
    { lat: 49.3335999, lng: 21.9013376 },
    { lat: 49.3333566, lng: 21.9012278 },
    { lat: 49.3332742, lng: 21.9009993 },
    { lat: 49.3331298, lng: 21.9008866 },
    { lat: 49.3327162, lng: 21.9008978 },
    { lat: 49.3326189, lng: 21.9007874 },
    { lat: 49.3324623, lng: 21.900736 },
    { lat: 49.3324544, lng: 21.900634 },
    { lat: 49.3322793, lng: 21.9004283 },
    { lat: 49.331844, lng: 21.9001551 },
    { lat: 49.3315754, lng: 21.899811 },
    { lat: 49.3315067, lng: 21.8996257 },
    { lat: 49.3315168, lng: 21.8995331 },
    { lat: 49.3313851, lng: 21.8994402 },
    { lat: 49.3310729, lng: 21.8984235 },
    { lat: 49.3309799, lng: 21.8983424 },
    { lat: 49.3307789, lng: 21.89796 },
    { lat: 49.3307467, lng: 21.8976987 },
    { lat: 49.3306209, lng: 21.8975795 },
    { lat: 49.3303859, lng: 21.8968983 },
    { lat: 49.3301103, lng: 21.8963148 },
    { lat: 49.330021, lng: 21.8962125 },
    { lat: 49.3299283, lng: 21.8959248 },
    { lat: 49.329741, lng: 21.8958546 },
    { lat: 49.3295726, lng: 21.8955926 },
    { lat: 49.3294722, lng: 21.8956324 },
    { lat: 49.3293139, lng: 21.8954635 },
    { lat: 49.3291903, lng: 21.8955548 },
    { lat: 49.3290573, lng: 21.8954133 },
    { lat: 49.3289517, lng: 21.8955529 },
    { lat: 49.3288654, lng: 21.8954736 },
    { lat: 49.3286522, lng: 21.8955373 },
    { lat: 49.3284196, lng: 21.8953209 },
    { lat: 49.3282522, lng: 21.8954194 },
    { lat: 49.328052, lng: 21.8950579 },
    { lat: 49.3277722, lng: 21.8951147 },
    { lat: 49.327478, lng: 21.894903 },
    { lat: 49.3273748, lng: 21.8946044 },
    { lat: 49.3272471, lng: 21.894671 },
    { lat: 49.327092, lng: 21.8945402 },
    { lat: 49.3269113, lng: 21.8946474 },
    { lat: 49.3268055, lng: 21.8944574 },
    { lat: 49.3265477, lng: 21.8943186 },
    { lat: 49.3265391, lng: 21.8945124 },
    { lat: 49.3264293, lng: 21.8944838 },
    { lat: 49.3263892, lng: 21.8946355 },
    { lat: 49.3263193, lng: 21.8945426 },
    { lat: 49.3262406, lng: 21.8942259 },
    { lat: 49.3262824, lng: 21.8940972 },
    { lat: 49.3261499, lng: 21.894153 },
    { lat: 49.3260244, lng: 21.894081 },
    { lat: 49.3259597, lng: 21.8941515 },
    { lat: 49.3257612, lng: 21.8938556 },
    { lat: 49.3255253, lng: 21.8939621 },
    { lat: 49.3254323, lng: 21.8939282 },
    { lat: 49.3254771, lng: 21.8937392 },
    { lat: 49.3254364, lng: 21.8936864 },
    { lat: 49.3252551, lng: 21.8936769 },
    { lat: 49.3251852, lng: 21.8938035 },
    { lat: 49.3235821, lng: 21.8947348 },
    { lat: 49.3222701, lng: 21.8944458 },
    { lat: 49.3205283, lng: 21.8920349 },
    { lat: 49.3204662, lng: 21.8901886 },
    { lat: 49.3200187, lng: 21.8885384 },
    { lat: 49.3190077, lng: 21.8871535 },
    { lat: 49.3183856, lng: 21.8854458 },
    { lat: 49.3178268, lng: 21.8842068 },
    { lat: 49.3168589, lng: 21.8823465 },
    { lat: 49.3166041, lng: 21.8820543 },
    { lat: 49.3149453, lng: 21.879339 },
    { lat: 49.3145891, lng: 21.8782817 },
    { lat: 49.3134713, lng: 21.8766835 },
    { lat: 49.3121449, lng: 21.8744817 },
    { lat: 49.3113576, lng: 21.8730581 },
    { lat: 49.3112402, lng: 21.872732 },
    { lat: 49.3091373, lng: 21.8683825 },
    { lat: 49.3089155, lng: 21.8672626 },
    { lat: 49.3083542, lng: 21.8658446 },
    { lat: 49.3076905, lng: 21.8642781 },
    { lat: 49.3064986, lng: 21.8619427 },
    { lat: 49.3052602, lng: 21.8598488 },
    { lat: 49.3044557, lng: 21.8591306 },
    { lat: 49.3039471, lng: 21.8583256 },
    { lat: 49.302457, lng: 21.856745 },
    { lat: 49.302131, lng: 21.857233 },
    { lat: 49.301731, lng: 21.857819 },
    { lat: 49.301267, lng: 21.858421 },
    { lat: 49.300377, lng: 21.860264 },
    { lat: 49.300008, lng: 21.86079 },
    { lat: 49.299876, lng: 21.861078 },
    { lat: 49.299685, lng: 21.861628 },
    { lat: 49.299283, lng: 21.862396 },
    { lat: 49.298795, lng: 21.862727 },
    { lat: 49.298163, lng: 21.863495 },
    { lat: 49.29804, lng: 21.863728 },
    { lat: 49.297816, lng: 21.863964 },
    { lat: 49.297533, lng: 21.864383 },
    { lat: 49.297392, lng: 21.864558 },
    { lat: 49.29718, lng: 21.864906 },
    { lat: 49.296956, lng: 21.865412 },
    { lat: 49.296768, lng: 21.865574 },
    { lat: 49.296602, lng: 21.865729 },
    { lat: 49.29622, lng: 21.866339 },
    { lat: 49.295788, lng: 21.866681 },
    { lat: 49.295391, lng: 21.867064 },
    { lat: 49.29502, lng: 21.867472 },
    { lat: 49.294647, lng: 21.867694 },
    { lat: 49.294249, lng: 21.868094 },
    { lat: 49.293885, lng: 21.868459 },
    { lat: 49.293738, lng: 21.868662 },
    { lat: 49.29362, lng: 21.868724 },
    { lat: 49.293489, lng: 21.868875 },
    { lat: 49.293347, lng: 21.868905 },
    { lat: 49.292662, lng: 21.869347 },
    { lat: 49.292261, lng: 21.869842 },
    { lat: 49.292055, lng: 21.870023 },
    { lat: 49.291462, lng: 21.870096 },
    { lat: 49.291206, lng: 21.870098 },
    { lat: 49.290944, lng: 21.870352 },
    { lat: 49.2898264, lng: 21.8715877 },
    { lat: 49.289314, lng: 21.8720421 },
    { lat: 49.2891895, lng: 21.8730392 },
    { lat: 49.2884723, lng: 21.8744378 },
    { lat: 49.2873135, lng: 21.8773663 },
    { lat: 49.2855007, lng: 21.8779128 },
    { lat: 49.2847769, lng: 21.8771754 },
    { lat: 49.2840088, lng: 21.8779805 },
    { lat: 49.2826849, lng: 21.8786523 },
    { lat: 49.2814765, lng: 21.8801454 },
    { lat: 49.2800307, lng: 21.8811514 },
    { lat: 49.2794334, lng: 21.8813267 },
    { lat: 49.2785672, lng: 21.8822621 },
    { lat: 49.2772422, lng: 21.8833325 },
    { lat: 49.2762899, lng: 21.8843283 },
    { lat: 49.275625, lng: 21.8853955 },
    { lat: 49.2743516, lng: 21.8860143 },
    { lat: 49.2734422, lng: 21.886304 },
    { lat: 49.2726756, lng: 21.8871783 },
    { lat: 49.2720893, lng: 21.8873863 },
    { lat: 49.2713908, lng: 21.8872044 },
    { lat: 49.270146, lng: 21.8871248 },
    { lat: 49.2692549, lng: 21.8873407 },
    { lat: 49.2677127, lng: 21.8884091 },
    { lat: 49.2639557, lng: 21.8902334 },
    { lat: 49.2625577, lng: 21.89113 },
    { lat: 49.2610392, lng: 21.8918446 },
    { lat: 49.2598643, lng: 21.8927097 },
    { lat: 49.258903, lng: 21.8931729 },
    { lat: 49.2569653, lng: 21.8945684 },
    { lat: 49.2566026, lng: 21.8949573 },
    { lat: 49.2568397, lng: 21.8982644 },
    { lat: 49.2572067, lng: 21.9011038 },
    { lat: 49.2576468, lng: 21.9038112 },
    { lat: 49.25887, lng: 21.9067599 },
    { lat: 49.2594844, lng: 21.9072188 },
    { lat: 49.2597419, lng: 21.9078091 },
    { lat: 49.2598513, lng: 21.9078668 },
    { lat: 49.2600202, lng: 21.9080979 },
    { lat: 49.2602118, lng: 21.9084659 },
    { lat: 49.260404, lng: 21.9090474 },
    { lat: 49.2609217, lng: 21.9097857 },
    { lat: 49.2611224, lng: 21.9104824 },
    { lat: 49.2610794, lng: 21.9113754 },
    { lat: 49.2612106, lng: 21.912034 },
    { lat: 49.2613343, lng: 21.9121615 },
    { lat: 49.2612554, lng: 21.9125615 },
    { lat: 49.2612707, lng: 21.9126855 },
    { lat: 49.2614542, lng: 21.9129525 },
    { lat: 49.2609544, lng: 21.913799 },
    { lat: 49.2608217, lng: 21.9137999 },
    { lat: 49.2607474, lng: 21.9138921 },
    { lat: 49.2607488, lng: 21.914847 },
    { lat: 49.2605914, lng: 21.9148479 },
    { lat: 49.260663, lng: 21.9170869 },
    { lat: 49.2614029, lng: 21.9170327 },
    { lat: 49.2622013, lng: 21.916624 },
    { lat: 49.2622295, lng: 21.9174899 },
    { lat: 49.2621719, lng: 21.9179768 },
    { lat: 49.2621982, lng: 21.9183534 },
    { lat: 49.2623363, lng: 21.9190617 },
    { lat: 49.263144, lng: 21.9190252 },
    { lat: 49.2647115, lng: 21.9197779 },
    { lat: 49.2652561, lng: 21.9205534 },
    { lat: 49.2656607, lng: 21.9212725 },
    { lat: 49.2657779, lng: 21.9216799 },
    { lat: 49.2664257, lng: 21.9207772 },
    { lat: 49.2667057, lng: 21.9217711 },
    { lat: 49.2667682, lng: 21.9218882 },
    { lat: 49.2669041, lng: 21.9217902 },
    { lat: 49.2669467, lng: 21.9219181 },
    { lat: 49.266946, lng: 21.9222967 },
    { lat: 49.2670747, lng: 21.9226768 },
    { lat: 49.2672874, lng: 21.9229118 },
    { lat: 49.2671631, lng: 21.9233444 },
    { lat: 49.267266, lng: 21.9235398 },
    { lat: 49.2673096, lng: 21.9237569 },
    { lat: 49.2672706, lng: 21.9241298 },
    { lat: 49.2673647, lng: 21.9242572 },
    { lat: 49.2673935, lng: 21.9246503 },
    { lat: 49.2674963, lng: 21.9248885 },
    { lat: 49.2675165, lng: 21.9251585 },
    { lat: 49.267583, lng: 21.9250283 },
    { lat: 49.2675513, lng: 21.92489 },
    { lat: 49.2676442, lng: 21.9248634 },
    { lat: 49.2676832, lng: 21.9252591 },
    { lat: 49.2675761, lng: 21.925432 },
    { lat: 49.2676237, lng: 21.9258125 },
    { lat: 49.2676058, lng: 21.9259932 },
    { lat: 49.2677039, lng: 21.9260542 },
    { lat: 49.2678473, lng: 21.9265688 },
    { lat: 49.2679893, lng: 21.927439 },
    { lat: 49.2678409, lng: 21.9277671 },
    { lat: 49.2681106, lng: 21.9278937 },
    { lat: 49.2681669, lng: 21.928072 },
    { lat: 49.2680223, lng: 21.9281448 },
    { lat: 49.2681337, lng: 21.92855 },
    { lat: 49.2681008, lng: 21.929264 },
    { lat: 49.2680486, lng: 21.9294701 },
    { lat: 49.268058, lng: 21.9297635 },
    { lat: 49.2681799, lng: 21.9301828 },
    { lat: 49.2685714, lng: 21.9307822 },
    { lat: 49.2686078, lng: 21.9309796 },
    { lat: 49.2688455, lng: 21.9311722 },
    { lat: 49.2689268, lng: 21.9311448 },
    { lat: 49.2691435, lng: 21.9317683 },
    { lat: 49.2692904, lng: 21.9317426 },
    { lat: 49.2693886, lng: 21.9318982 },
    { lat: 49.2695073, lng: 21.9322854 },
    { lat: 49.2696184, lng: 21.9321864 },
    { lat: 49.2696398, lng: 21.9323652 },
    { lat: 49.2697546, lng: 21.9323655 },
    { lat: 49.2699019, lng: 21.9324999 },
    { lat: 49.2702206, lng: 21.9330156 },
    { lat: 49.2703568, lng: 21.9330218 },
    { lat: 49.2704022, lng: 21.9330876 },
    { lat: 49.2705026, lng: 21.9334564 },
    { lat: 49.2706333, lng: 21.933558 },
    { lat: 49.2709561, lng: 21.934421 },
    { lat: 49.271173, lng: 21.9345086 },
    { lat: 49.2714414, lng: 21.9350788 },
    { lat: 49.2715562, lng: 21.9354979 },
    { lat: 49.2714649, lng: 21.9356205 },
    { lat: 49.2718268, lng: 21.9361583 },
    { lat: 49.2717371, lng: 21.9364484 },
    { lat: 49.2717462, lng: 21.9365865 },
    { lat: 49.2719252, lng: 21.9367725 },
    { lat: 49.271935, lng: 21.9370331 },
    { lat: 49.2721891, lng: 21.9374823 },
    { lat: 49.2722894, lng: 21.9375388 },
    { lat: 49.2724349, lng: 21.9377775 },
    { lat: 49.2724237, lng: 21.9379665 },
    { lat: 49.2726006, lng: 21.9380637 },
    { lat: 49.2725842, lng: 21.9382706 },
    { lat: 49.2727275, lng: 21.9382894 },
    { lat: 49.2727098, lng: 21.9384392 },
    { lat: 49.272858, lng: 21.9385628 },
    { lat: 49.2730691, lng: 21.9391403 },
    { lat: 49.2729706, lng: 21.9394291 },
    { lat: 49.2730834, lng: 21.939719 },
    { lat: 49.2730775, lng: 21.9407981 },
    { lat: 49.2731867, lng: 21.9410807 },
    { lat: 49.2739186, lng: 21.9420301 },
    { lat: 49.2748515, lng: 21.9426809 },
    { lat: 49.2750234, lng: 21.9431335 },
    { lat: 49.2753736, lng: 21.9435142 },
    { lat: 49.2767083, lng: 21.9456855 },
    { lat: 49.2769348, lng: 21.9465751 },
    { lat: 49.2750822, lng: 21.9500367 },
    { lat: 49.2741593, lng: 21.9511981 },
    { lat: 49.2737739, lng: 21.9519014 },
    { lat: 49.2734499, lng: 21.9527669 },
    { lat: 49.2730489, lng: 21.9536583 },
    { lat: 49.2727517, lng: 21.955199 },
    { lat: 49.2728313, lng: 21.955832 },
    { lat: 49.2724899, lng: 21.9566818 },
    { lat: 49.2721305, lng: 21.9571631 },
    { lat: 49.2716478, lng: 21.9575138 },
    { lat: 49.271288, lng: 21.9582581 },
    { lat: 49.2699356, lng: 21.959746 },
    { lat: 49.2697002, lng: 21.96024 },
    { lat: 49.2693069, lng: 21.9605294 },
    { lat: 49.2690933, lng: 21.9608034 },
    { lat: 49.2689779, lng: 21.9612898 },
    { lat: 49.268671, lng: 21.9617957 },
    { lat: 49.2686842, lng: 21.9623849 },
    { lat: 49.2682167, lng: 21.9629937 },
    { lat: 49.273688, lng: 21.9647701 },
    { lat: 49.273767, lng: 21.9650036 },
    { lat: 49.2739123, lng: 21.9663958 },
    { lat: 49.2740342, lng: 21.9665505 },
    { lat: 49.274063, lng: 21.9664383 },
    { lat: 49.2746337, lng: 21.966048 },
    { lat: 49.2749317, lng: 21.9664582 },
    { lat: 49.2751566, lng: 21.967058 },
    { lat: 49.2757673, lng: 21.9664497 },
    { lat: 49.2764265, lng: 21.9659131 },
    { lat: 49.2765452, lng: 21.965339 },
    { lat: 49.2768576, lng: 21.9653276 },
    { lat: 49.2776123, lng: 21.9655607 },
    { lat: 49.2782257, lng: 21.9670487 },
    { lat: 49.2796192, lng: 21.9714143 },
    { lat: 49.2812644, lng: 21.9731095 },
    { lat: 49.2824158, lng: 21.972177 },
    { lat: 49.2839563, lng: 21.9733609 },
    { lat: 49.2847007, lng: 21.9746503 },
    { lat: 49.2855059, lng: 21.9752929 },
    { lat: 49.2878071, lng: 21.9784725 },
    { lat: 49.2905736, lng: 21.9818396 },
    { lat: 49.2908964, lng: 21.9823012 },
    { lat: 49.2921081, lng: 21.9836624 },
    { lat: 49.2927399, lng: 21.9845324 },
    { lat: 49.2934594, lng: 21.9856759 },
    { lat: 49.2941208, lng: 21.9865504 },
    { lat: 49.2993735, lng: 21.9960381 },
    { lat: 49.2998601, lng: 21.996483 },
    { lat: 49.3002229, lng: 21.9969843 },
    { lat: 49.3009016, lng: 21.9977294 },
    { lat: 49.3015842, lng: 21.9955946 },
    { lat: 49.3024241, lng: 21.9941819 },
    { lat: 49.3033682, lng: 21.9930384 },
    { lat: 49.3039222, lng: 21.9919507 },
    { lat: 49.3043054, lng: 21.9914853 },
    { lat: 49.3051451, lng: 21.9907076 },
    { lat: 49.3061579, lng: 21.9899713 },
    { lat: 49.3071109, lng: 21.9895409 },
    { lat: 49.3076143, lng: 21.9894135 },
    { lat: 49.3080948, lng: 21.9891694 },
    { lat: 49.3085083, lng: 21.9888702 },
    { lat: 49.3087524, lng: 21.9880468 },
    { lat: 49.3088498, lng: 21.9873529 },
    { lat: 49.3090214, lng: 21.9860221 },
    { lat: 49.3094166, lng: 21.9849398 },
    { lat: 49.3099521, lng: 21.9836959 },
    { lat: 49.310366, lng: 21.9831803 },
    { lat: 49.3107775, lng: 21.9829291 },
    { lat: 49.3113361, lng: 21.9826624 },
    { lat: 49.3119332, lng: 21.9827946 },
    { lat: 49.3124295, lng: 21.9826413 },
    { lat: 49.313096, lng: 21.9825833 },
    { lat: 49.313846, lng: 21.982118 },
    { lat: 49.3146334, lng: 21.9815775 },
    { lat: 49.3152491, lng: 21.9804008 },
    { lat: 49.315994, lng: 21.979553 },
    { lat: 49.316374, lng: 21.979135 },
    { lat: 49.316496, lng: 21.978998 },
    { lat: 49.3169236, lng: 21.9785402 },
    { lat: 49.3178899, lng: 21.97832 },
    { lat: 49.318298, lng: 21.978289 },
    { lat: 49.318954, lng: 21.978243 },
    { lat: 49.3195, lng: 21.978078 },
    { lat: 49.3198345, lng: 21.9779751 },
  ],
  Čertižné: [
    { lat: 49.370424, lng: 21.856382 },
    { lat: 49.3710065, lng: 21.8556602 },
    { lat: 49.3715531, lng: 21.8550798 },
    { lat: 49.3719994, lng: 21.8546486 },
    { lat: 49.3724658, lng: 21.8542668 },
    { lat: 49.3732141, lng: 21.8534788 },
    { lat: 49.3738957, lng: 21.8528494 },
    { lat: 49.3745782, lng: 21.8523229 },
    { lat: 49.3753973, lng: 21.851616 },
    { lat: 49.3763114, lng: 21.8502735 },
    { lat: 49.3768433, lng: 21.8496347 },
    { lat: 49.3775735, lng: 21.8489327 },
    { lat: 49.3780606, lng: 21.8482394 },
    { lat: 49.3781121, lng: 21.8479249 },
    { lat: 49.378588, lng: 21.8476328 },
    { lat: 49.379734, lng: 21.847852 },
    { lat: 49.380293, lng: 21.847802 },
    { lat: 49.3806567, lng: 21.8477765 },
    { lat: 49.3811587, lng: 21.8483222 },
    { lat: 49.3813084, lng: 21.8483161 },
    { lat: 49.381606, lng: 21.84878 },
    { lat: 49.382469, lng: 21.8491464 },
    { lat: 49.383364, lng: 21.849224 },
    { lat: 49.3841035, lng: 21.8492802 },
    { lat: 49.38521, lng: 21.84849 },
    { lat: 49.3859009, lng: 21.8479989 },
    { lat: 49.3866646, lng: 21.8473159 },
    { lat: 49.387044, lng: 21.8460351 },
    { lat: 49.3871508, lng: 21.8446521 },
    { lat: 49.3876243, lng: 21.8435185 },
    { lat: 49.388424, lng: 21.84295 },
    { lat: 49.389308, lng: 21.842694 },
    { lat: 49.3893505, lng: 21.8426561 },
    { lat: 49.389882, lng: 21.842138 },
    { lat: 49.390551, lng: 21.841317 },
    { lat: 49.391091, lng: 21.8406505 },
    { lat: 49.3912526, lng: 21.8407339 },
    { lat: 49.3915102, lng: 21.8402411 },
    { lat: 49.3915883, lng: 21.838782 },
    { lat: 49.3916729, lng: 21.8383186 },
    { lat: 49.3916855, lng: 21.8376307 },
    { lat: 49.391605, lng: 21.836758 },
    { lat: 49.3915851, lng: 21.8355571 },
    { lat: 49.391362, lng: 21.834416 },
    { lat: 49.3912641, lng: 21.8339759 },
    { lat: 49.3912262, lng: 21.8324079 },
    { lat: 49.3912789, lng: 21.8310778 },
    { lat: 49.391, lng: 21.8300171 },
    { lat: 49.3908707, lng: 21.8290841 },
    { lat: 49.39086, lng: 21.829021 },
    { lat: 49.390778, lng: 21.828211 },
    { lat: 49.3907343, lng: 21.8278587 },
    { lat: 49.390726, lng: 21.827789 },
    { lat: 49.390768, lng: 21.827059 },
    { lat: 49.390768, lng: 21.826375 },
    { lat: 49.3907837, lng: 21.8258407 },
    { lat: 49.390482, lng: 21.825245 },
    { lat: 49.390103, lng: 21.824535 },
    { lat: 49.3897397, lng: 21.8232498 },
    { lat: 49.3891051, lng: 21.822079 },
    { lat: 49.3886832, lng: 21.8219984 },
    { lat: 49.3877969, lng: 21.8211255 },
    { lat: 49.3871494, lng: 21.8204149 },
    { lat: 49.3868541, lng: 21.8202037 },
    { lat: 49.3864856, lng: 21.8196525 },
    { lat: 49.3858878, lng: 21.8188853 },
    { lat: 49.3856872, lng: 21.8187198 },
    { lat: 49.3851157, lng: 21.8182494 },
    { lat: 49.3843405, lng: 21.8173796 },
    { lat: 49.384161, lng: 21.8158921 },
    { lat: 49.3839259, lng: 21.8150973 },
    { lat: 49.3836185, lng: 21.8146811 },
    { lat: 49.3830263, lng: 21.8136035 },
    { lat: 49.3825343, lng: 21.8125511 },
    { lat: 49.382306, lng: 21.811172 },
    { lat: 49.381758, lng: 21.810038 },
    { lat: 49.38151, lng: 21.808517 },
    { lat: 49.381494, lng: 21.806341 },
    { lat: 49.381728, lng: 21.805536 },
    { lat: 49.381915, lng: 21.804894 },
    { lat: 49.382218, lng: 21.804227 },
    { lat: 49.382176, lng: 21.802993 },
    { lat: 49.381513, lng: 21.802682 },
    { lat: 49.380966, lng: 21.802387 },
    { lat: 49.380422, lng: 21.80206 },
    { lat: 49.380046, lng: 21.800766 },
    { lat: 49.379841, lng: 21.799812 },
    { lat: 49.379657, lng: 21.799366 },
    { lat: 49.379342, lng: 21.798631 },
    { lat: 49.37904, lng: 21.797927 },
    { lat: 49.378462, lng: 21.796949 },
    { lat: 49.37799, lng: 21.796517 },
    { lat: 49.37756, lng: 21.796193 },
    { lat: 49.376463, lng: 21.796041 },
    { lat: 49.376125, lng: 21.795857 },
    { lat: 49.375654, lng: 21.795597 },
    { lat: 49.37508, lng: 21.795433 },
    { lat: 49.374564, lng: 21.795286 },
    { lat: 49.3739948, lng: 21.7951145 },
    { lat: 49.373524, lng: 21.794089 },
    { lat: 49.373442, lng: 21.793843 },
    { lat: 49.373436, lng: 21.79376 },
    { lat: 49.3734979, lng: 21.7931571 },
    { lat: 49.373452, lng: 21.792959 },
    { lat: 49.372949, lng: 21.792357 },
    { lat: 49.372864, lng: 21.792088 },
    { lat: 49.3727322, lng: 21.791762 },
    { lat: 49.3725745, lng: 21.7916665 },
    { lat: 49.3722166, lng: 21.7915912 },
    { lat: 49.3721244, lng: 21.791506 },
    { lat: 49.3715125, lng: 21.790973 },
    { lat: 49.3711111, lng: 21.7905833 },
    { lat: 49.3706488, lng: 21.7901854 },
    { lat: 49.3703469, lng: 21.7897462 },
    { lat: 49.3698734, lng: 21.7893339 },
    { lat: 49.3695141, lng: 21.7890689 },
    { lat: 49.3691829, lng: 21.7887891 },
    { lat: 49.3690143, lng: 21.7887196 },
    { lat: 49.3686253, lng: 21.7886099 },
    { lat: 49.367822, lng: 21.787912 },
    { lat: 49.3677174, lng: 21.7878417 },
    { lat: 49.3671373, lng: 21.7877918 },
    { lat: 49.3668275, lng: 21.7876801 },
    { lat: 49.3660191, lng: 21.7876795 },
    { lat: 49.365962, lng: 21.787724 },
    { lat: 49.365165, lng: 21.7883273 },
    { lat: 49.365151, lng: 21.788328 },
    { lat: 49.364, lng: 21.789312 },
    { lat: 49.363239, lng: 21.789835 },
    { lat: 49.36227, lng: 21.790062 },
    { lat: 49.361121, lng: 21.790432 },
    { lat: 49.360834, lng: 21.790515 },
    { lat: 49.360555, lng: 21.790838 },
    { lat: 49.360147, lng: 21.790374 },
    { lat: 49.359471, lng: 21.790553 },
    { lat: 49.3589013, lng: 21.7898754 },
    { lat: 49.358242, lng: 21.788513 },
    { lat: 49.357952, lng: 21.788531 },
    { lat: 49.357778, lng: 21.788542 },
    { lat: 49.357603, lng: 21.788583 },
    { lat: 49.356621, lng: 21.788908 },
    { lat: 49.3554882, lng: 21.7892157 },
    { lat: 49.354602, lng: 21.78982 },
    { lat: 49.353794, lng: 21.790299 },
    { lat: 49.352554, lng: 21.791356 },
    { lat: 49.35129, lng: 21.791381 },
    { lat: 49.350737, lng: 21.790947 },
    { lat: 49.350587, lng: 21.790908 },
    { lat: 49.349249, lng: 21.790347 },
    { lat: 49.348809, lng: 21.790229 },
    { lat: 49.347737, lng: 21.791344 },
    { lat: 49.347562, lng: 21.791527 },
    { lat: 49.347259, lng: 21.791722 },
    { lat: 49.344788, lng: 21.793794 },
    { lat: 49.344297, lng: 21.794372 },
    { lat: 49.342802, lng: 21.795025 },
    { lat: 49.340997, lng: 21.794996 },
    { lat: 49.340276, lng: 21.795121 },
    { lat: 49.339416, lng: 21.795977 },
    { lat: 49.339289, lng: 21.796103 },
    { lat: 49.338911, lng: 21.796501 },
    { lat: 49.339045, lng: 21.796893 },
    { lat: 49.338663, lng: 21.797157 },
    { lat: 49.338226, lng: 21.79745 },
    { lat: 49.337151, lng: 21.798071 },
    { lat: 49.336479, lng: 21.799435 },
    { lat: 49.336173, lng: 21.799965 },
    { lat: 49.336131, lng: 21.80003 },
    { lat: 49.335878, lng: 21.800511 },
    { lat: 49.335875, lng: 21.801034 },
    { lat: 49.335883, lng: 21.801605 },
    { lat: 49.335928, lng: 21.801776 },
    { lat: 49.336342, lng: 21.803349 },
    { lat: 49.3365, lng: 21.804017 },
    { lat: 49.33684, lng: 21.805457 },
    { lat: 49.337079, lng: 21.806859 },
    { lat: 49.337456, lng: 21.808109 },
    { lat: 49.337486, lng: 21.8082 },
    { lat: 49.337784, lng: 21.809245 },
    { lat: 49.33786, lng: 21.809509 },
    { lat: 49.338633, lng: 21.812447 },
    { lat: 49.338991, lng: 21.813615 },
    { lat: 49.339211, lng: 21.814289 },
    { lat: 49.338803, lng: 21.814716 },
    { lat: 49.33827, lng: 21.815295 },
    { lat: 49.33789, lng: 21.815619 },
    { lat: 49.337457, lng: 21.815796 },
    { lat: 49.337, lng: 21.816034 },
    { lat: 49.336477, lng: 21.816485 },
    { lat: 49.335805, lng: 21.817492 },
    { lat: 49.335603, lng: 21.817965 },
    { lat: 49.33532, lng: 21.81863 },
    { lat: 49.335098, lng: 21.819135 },
    { lat: 49.334726, lng: 21.81968 },
    { lat: 49.334218, lng: 21.820177 },
    { lat: 49.334002, lng: 21.820292 },
    { lat: 49.332559, lng: 21.821219 },
    { lat: 49.332449, lng: 21.820936 },
    { lat: 49.331682, lng: 21.821223 },
    { lat: 49.331019, lng: 21.821753 },
    { lat: 49.3324747, lng: 21.8256163 },
    { lat: 49.333114, lng: 21.825944 },
    { lat: 49.3333001, lng: 21.8257896 },
    { lat: 49.3334359, lng: 21.8255171 },
    { lat: 49.334246, lng: 21.8261447 },
    { lat: 49.3345604, lng: 21.8273925 },
    { lat: 49.3345415, lng: 21.828367 },
    { lat: 49.3345884, lng: 21.8287072 },
    { lat: 49.3343046, lng: 21.8298737 },
    { lat: 49.3342038, lng: 21.8300258 },
    { lat: 49.3341584, lng: 21.8303618 },
    { lat: 49.3342572, lng: 21.8304453 },
    { lat: 49.3343118, lng: 21.8306433 },
    { lat: 49.3343079, lng: 21.8308801 },
    { lat: 49.3342443, lng: 21.8309914 },
    { lat: 49.3342951, lng: 21.831217 },
    { lat: 49.3346722, lng: 21.8321097 },
    { lat: 49.3351066, lng: 21.8329594 },
    { lat: 49.3354561, lng: 21.8334755 },
    { lat: 49.3361816, lng: 21.834794 },
    { lat: 49.3361693, lng: 21.8354115 },
    { lat: 49.3363627, lng: 21.8358944 },
    { lat: 49.3370092, lng: 21.8368555 },
    { lat: 49.3378902, lng: 21.8377871 },
    { lat: 49.3381129, lng: 21.8381442 },
    { lat: 49.3387267, lng: 21.8387466 },
    { lat: 49.3391535, lng: 21.8390335 },
    { lat: 49.3398823, lng: 21.8399115 },
    { lat: 49.3407205, lng: 21.8406723 },
    { lat: 49.340577, lng: 21.8410444 },
    { lat: 49.3405103, lng: 21.8414232 },
    { lat: 49.3422188, lng: 21.8439457 },
    { lat: 49.3428601, lng: 21.8444349 },
    { lat: 49.3433362, lng: 21.8447149 },
    { lat: 49.3437054, lng: 21.8453375 },
    { lat: 49.3439107, lng: 21.8455427 },
    { lat: 49.3441248, lng: 21.8460934 },
    { lat: 49.3438111, lng: 21.8470183 },
    { lat: 49.3445915, lng: 21.8479454 },
    { lat: 49.3450511, lng: 21.8483628 },
    { lat: 49.3458486, lng: 21.8489433 },
    { lat: 49.3468403, lng: 21.8495055 },
    { lat: 49.3473823, lng: 21.8499855 },
    { lat: 49.34753, lng: 21.8496487 },
    { lat: 49.3482255, lng: 21.8494435 },
    { lat: 49.3489896, lng: 21.8501224 },
    { lat: 49.3493261, lng: 21.8505267 },
    { lat: 49.3497299, lng: 21.8507999 },
    { lat: 49.3505423, lng: 21.8509846 },
    { lat: 49.3508182, lng: 21.8511388 },
    { lat: 49.3514885, lng: 21.8512201 },
    { lat: 49.3518127, lng: 21.8513636 },
    { lat: 49.3525642, lng: 21.8514617 },
    { lat: 49.3528618, lng: 21.8527692 },
    { lat: 49.3544124, lng: 21.855545 },
    { lat: 49.3551767, lng: 21.8567267 },
    { lat: 49.3554356, lng: 21.8568697 },
    { lat: 49.3561282, lng: 21.8569859 },
    { lat: 49.3562553, lng: 21.85739 },
    { lat: 49.3572738, lng: 21.8575886 },
    { lat: 49.3575407, lng: 21.8578249 },
    { lat: 49.357964, lng: 21.8580199 },
    { lat: 49.3581302, lng: 21.8582276 },
    { lat: 49.3584313, lng: 21.85813 },
    { lat: 49.3587427, lng: 21.8582132 },
    { lat: 49.3589986, lng: 21.8581031 },
    { lat: 49.3594016, lng: 21.8576743 },
    { lat: 49.3596272, lng: 21.8576581 },
    { lat: 49.3598247, lng: 21.8571902 },
    { lat: 49.3600174, lng: 21.8570756 },
    { lat: 49.3600813, lng: 21.8568899 },
    { lat: 49.3601746, lng: 21.8568446 },
    { lat: 49.3602589, lng: 21.8566632 },
    { lat: 49.3614109, lng: 21.8553407 },
    { lat: 49.3616384, lng: 21.8550024 },
    { lat: 49.3618185, lng: 21.854957 },
    { lat: 49.3620529, lng: 21.8546649 },
    { lat: 49.3622142, lng: 21.854556 },
    { lat: 49.3623754, lng: 21.8542585 },
    { lat: 49.3626374, lng: 21.85421 },
    { lat: 49.3636017, lng: 21.8534677 },
    { lat: 49.3640303, lng: 21.8533913 },
    { lat: 49.3645234, lng: 21.8534955 },
    { lat: 49.3650378, lng: 21.8539102 },
    { lat: 49.3662417, lng: 21.8545492 },
    { lat: 49.366987, lng: 21.8551952 },
    { lat: 49.3671732, lng: 21.8552682 },
    { lat: 49.3673487, lng: 21.8554866 },
    { lat: 49.367765, lng: 21.8566066 },
    { lat: 49.368982, lng: 21.858379 },
    { lat: 49.369231, lng: 21.858015 },
    { lat: 49.3699242, lng: 21.8570161 },
    { lat: 49.370424, lng: 21.856382 },
  ],
  Rokytovce: [
    { lat: 49.290944, lng: 21.870352 },
    { lat: 49.290733, lng: 21.870118 },
    { lat: 49.290337, lng: 21.86979 },
    { lat: 49.290011, lng: 21.86961 },
    { lat: 49.289749, lng: 21.869276 },
    { lat: 49.289407, lng: 21.868848 },
    { lat: 49.289144, lng: 21.868744 },
    { lat: 49.288655, lng: 21.868854 },
    { lat: 49.288154, lng: 21.868786 },
    { lat: 49.287835, lng: 21.868618 },
    { lat: 49.287187, lng: 21.867767 },
    { lat: 49.287023, lng: 21.867456 },
    { lat: 49.286822, lng: 21.867037 },
    { lat: 49.286248, lng: 21.866336 },
    { lat: 49.286014, lng: 21.866037 },
    { lat: 49.285801, lng: 21.865716 },
    { lat: 49.285462, lng: 21.865462 },
    { lat: 49.285601, lng: 21.865286 },
    { lat: 49.285465, lng: 21.864975 },
    { lat: 49.28543, lng: 21.864475 },
    { lat: 49.285157, lng: 21.864051 },
    { lat: 49.284997, lng: 21.8638 },
    { lat: 49.284855, lng: 21.864059 },
    { lat: 49.284848, lng: 21.864069 },
    { lat: 49.284823, lng: 21.864111 },
    { lat: 49.283565, lng: 21.862666 },
    { lat: 49.283523, lng: 21.86261 },
    { lat: 49.283472, lng: 21.862544 },
    { lat: 49.282597, lng: 21.861433 },
    { lat: 49.282497, lng: 21.861305 },
    { lat: 49.280082, lng: 21.858234 },
    { lat: 49.279733, lng: 21.858634 },
    { lat: 49.278748, lng: 21.857509 },
    { lat: 49.278729, lng: 21.857485 },
    { lat: 49.27853, lng: 21.85725 },
    { lat: 49.278497, lng: 21.857218 },
    { lat: 49.27824, lng: 21.857525 },
    { lat: 49.277848, lng: 21.856755 },
    { lat: 49.27783, lng: 21.85672 },
    { lat: 49.27782, lng: 21.856701 },
    { lat: 49.277798, lng: 21.856656 },
    { lat: 49.277105, lng: 21.855272 },
    { lat: 49.276768, lng: 21.854687 },
    { lat: 49.276612, lng: 21.854309 },
    { lat: 49.276522, lng: 21.853609 },
    { lat: 49.276385, lng: 21.852983 },
    { lat: 49.276211, lng: 21.852587 },
    { lat: 49.276077, lng: 21.851709 },
    { lat: 49.275582, lng: 21.85237 },
    { lat: 49.275424, lng: 21.85207 },
    { lat: 49.275121, lng: 21.852125 },
    { lat: 49.274765, lng: 21.852144 },
    { lat: 49.274644, lng: 21.852369 },
    { lat: 49.274304, lng: 21.851822 },
    { lat: 49.273937, lng: 21.851281 },
    { lat: 49.273393, lng: 21.850843 },
    { lat: 49.273172, lng: 21.850696 },
    { lat: 49.273118, lng: 21.850647 },
    { lat: 49.272946, lng: 21.850513 },
    { lat: 49.272639, lng: 21.850174 },
    { lat: 49.27265, lng: 21.849588 },
    { lat: 49.2722672, lng: 21.8499799 },
    { lat: 49.2719101, lng: 21.8498225 },
    { lat: 49.2714883, lng: 21.8497349 },
    { lat: 49.270269, lng: 21.8506564 },
    { lat: 49.2697902, lng: 21.8519149 },
    { lat: 49.2684224, lng: 21.8535875 },
    { lat: 49.2680667, lng: 21.8539288 },
    { lat: 49.2667129, lng: 21.8556249 },
    { lat: 49.2657154, lng: 21.8567008 },
    { lat: 49.2655658, lng: 21.8570094 },
    { lat: 49.2659338, lng: 21.8581274 },
    { lat: 49.265796, lng: 21.8591224 },
    { lat: 49.2653678, lng: 21.8611301 },
    { lat: 49.2645376, lng: 21.8618326 },
    { lat: 49.2623455, lng: 21.8630266 },
    { lat: 49.2620875, lng: 21.8625262 },
    { lat: 49.2594738, lng: 21.8652871 },
    { lat: 49.2561602, lng: 21.868087 },
    { lat: 49.2561868, lng: 21.8687397 },
    { lat: 49.2557539, lng: 21.8690493 },
    { lat: 49.2556979, lng: 21.8694138 },
    { lat: 49.2554214, lng: 21.8703527 },
    { lat: 49.2546184, lng: 21.8707195 },
    { lat: 49.2532353, lng: 21.8753109 },
    { lat: 49.2524469, lng: 21.8776269 },
    { lat: 49.2524179, lng: 21.880221 },
    { lat: 49.2536898, lng: 21.8802617 },
    { lat: 49.2553826, lng: 21.8836504 },
    { lat: 49.2549188, lng: 21.884081 },
    { lat: 49.2542134, lng: 21.88488 },
    { lat: 49.253102, lng: 21.886392 },
    { lat: 49.2533391, lng: 21.8867832 },
    { lat: 49.2547855, lng: 21.8905384 },
    { lat: 49.2561659, lng: 21.8926114 },
    { lat: 49.2569275, lng: 21.8944543 },
    { lat: 49.2569653, lng: 21.8945684 },
    { lat: 49.258903, lng: 21.8931729 },
    { lat: 49.2598643, lng: 21.8927097 },
    { lat: 49.2610392, lng: 21.8918446 },
    { lat: 49.2625577, lng: 21.89113 },
    { lat: 49.2639557, lng: 21.8902334 },
    { lat: 49.2677127, lng: 21.8884091 },
    { lat: 49.2692549, lng: 21.8873407 },
    { lat: 49.270146, lng: 21.8871248 },
    { lat: 49.2713908, lng: 21.8872044 },
    { lat: 49.2720893, lng: 21.8873863 },
    { lat: 49.2726756, lng: 21.8871783 },
    { lat: 49.2734422, lng: 21.886304 },
    { lat: 49.2743516, lng: 21.8860143 },
    { lat: 49.275625, lng: 21.8853955 },
    { lat: 49.2762899, lng: 21.8843283 },
    { lat: 49.2772422, lng: 21.8833325 },
    { lat: 49.2785672, lng: 21.8822621 },
    { lat: 49.2794334, lng: 21.8813267 },
    { lat: 49.2800307, lng: 21.8811514 },
    { lat: 49.2814765, lng: 21.8801454 },
    { lat: 49.2826849, lng: 21.8786523 },
    { lat: 49.2840088, lng: 21.8779805 },
    { lat: 49.2847769, lng: 21.8771754 },
    { lat: 49.2855007, lng: 21.8779128 },
    { lat: 49.2873135, lng: 21.8773663 },
    { lat: 49.2884723, lng: 21.8744378 },
    { lat: 49.2891895, lng: 21.8730392 },
    { lat: 49.289314, lng: 21.8720421 },
    { lat: 49.2898264, lng: 21.8715877 },
    { lat: 49.290944, lng: 21.870352 },
  ],
  Výrava: [
    { lat: 49.2379766, lng: 22.0067013 },
    { lat: 49.2377912, lng: 22.0066642 },
    { lat: 49.237393, lng: 22.0063836 },
    { lat: 49.2370531, lng: 22.0057203 },
    { lat: 49.2366562, lng: 22.00531 },
    { lat: 49.2362338, lng: 22.0053277 },
    { lat: 49.2352356, lng: 22.0057212 },
    { lat: 49.2348606, lng: 22.0054695 },
    { lat: 49.2343862, lng: 22.0050582 },
    { lat: 49.2340519, lng: 22.0043058 },
    { lat: 49.2337056, lng: 22.0040748 },
    { lat: 49.2333251, lng: 22.0039268 },
    { lat: 49.2330217, lng: 22.003377 },
    { lat: 49.2322939, lng: 22.0025284 },
    { lat: 49.2319794, lng: 22.0014798 },
    { lat: 49.2315033, lng: 22.0008178 },
    { lat: 49.2308126, lng: 22.0002554 },
    { lat: 49.230358, lng: 22.0002379 },
    { lat: 49.2297913, lng: 22.0001083 },
    { lat: 49.2292113, lng: 22.0001256 },
    { lat: 49.2285462, lng: 21.9999699 },
    { lat: 49.228124, lng: 21.9993042 },
    { lat: 49.2276475, lng: 21.9988485 },
    { lat: 49.2271638, lng: 21.9988003 },
    { lat: 49.2267617, lng: 21.9985938 },
    { lat: 49.2261416, lng: 21.9978828 },
    { lat: 49.2252691, lng: 21.9962196 },
    { lat: 49.2247328, lng: 21.9963194 },
    { lat: 49.2240399, lng: 21.9962714 },
    { lat: 49.2228277, lng: 21.9964241 },
    { lat: 49.2223227, lng: 21.9963807 },
    { lat: 49.2220816, lng: 21.9959921 },
    { lat: 49.2215265, lng: 21.9957734 },
    { lat: 49.2213886, lng: 21.9956088 },
    { lat: 49.2212019, lng: 21.9952372 },
    { lat: 49.221166, lng: 21.9946877 },
    { lat: 49.2210482, lng: 21.9943723 },
    { lat: 49.2209539, lng: 21.9941941 },
    { lat: 49.2207072, lng: 21.993944 },
    { lat: 49.2204151, lng: 21.9939605 },
    { lat: 49.2199585, lng: 21.9942389 },
    { lat: 49.2185302, lng: 21.9928967 },
    { lat: 49.2179953, lng: 21.9928219 },
    { lat: 49.2173311, lng: 21.9920161 },
    { lat: 49.2164058, lng: 21.9919393 },
    { lat: 49.2154294, lng: 21.9908153 },
    { lat: 49.2148433, lng: 21.9909995 },
    { lat: 49.2148852, lng: 21.9908748 },
    { lat: 49.2139866, lng: 21.9908279 },
    { lat: 49.2134067, lng: 21.991037 },
    { lat: 49.2130471, lng: 21.99128 },
    { lat: 49.212761, lng: 21.9916808 },
    { lat: 49.2117905, lng: 21.9909864 },
    { lat: 49.211405, lng: 21.9899782 },
    { lat: 49.210868, lng: 21.9879286 },
    { lat: 49.2097702, lng: 21.9874552 },
    { lat: 49.2094927, lng: 21.9865507 },
    { lat: 49.2090253, lng: 21.9870316 },
    { lat: 49.2084277, lng: 21.9859992 },
    { lat: 49.2077844, lng: 21.9853098 },
    { lat: 49.2066982, lng: 21.9825901 },
    { lat: 49.2057949, lng: 21.9819849 },
    { lat: 49.2057672, lng: 21.9806653 },
    { lat: 49.20566, lng: 21.9789963 },
    { lat: 49.2052812, lng: 21.9784056 },
    { lat: 49.2051994, lng: 21.9771678 },
    { lat: 49.2045265, lng: 21.9746275 },
    { lat: 49.2038127, lng: 21.9723844 },
    { lat: 49.2029317, lng: 21.968695 },
    { lat: 49.2023335, lng: 21.9687025 },
    { lat: 49.2017975, lng: 21.9688561 },
    { lat: 49.2012061, lng: 21.9687776 },
    { lat: 49.2006629, lng: 21.9681852 },
    { lat: 49.1997793, lng: 21.9684712 },
    { lat: 49.198065, lng: 21.9701723 },
    { lat: 49.1972715, lng: 21.9702445 },
    { lat: 49.194316, lng: 21.9722801 },
    { lat: 49.1932099, lng: 21.9730904 },
    { lat: 49.1920638, lng: 21.9740565 },
    { lat: 49.1916753, lng: 21.974501 },
    { lat: 49.1912696, lng: 21.9749651 },
    { lat: 49.1907807, lng: 21.9756294 },
    { lat: 49.1902936, lng: 21.9759894 },
    { lat: 49.1897118, lng: 21.9768135 },
    { lat: 49.1889231, lng: 21.9781842 },
    { lat: 49.1880467, lng: 21.9778503 },
    { lat: 49.1876124, lng: 21.9782594 },
    { lat: 49.1867165, lng: 21.9778227 },
    { lat: 49.1870479, lng: 21.9791853 },
    { lat: 49.1874736, lng: 21.9800382 },
    { lat: 49.1870762, lng: 21.9818744 },
    { lat: 49.1861973, lng: 21.9843902 },
    { lat: 49.1849071, lng: 21.9843867 },
    { lat: 49.1846605, lng: 21.9844984 },
    { lat: 49.1844041, lng: 21.9846139 },
    { lat: 49.1841269, lng: 21.9847391 },
    { lat: 49.1838247, lng: 21.9848756 },
    { lat: 49.1837709, lng: 21.9848731 },
    { lat: 49.1831118, lng: 21.984842 },
    { lat: 49.1829374, lng: 21.9848041 },
    { lat: 49.1826328, lng: 21.9847396 },
    { lat: 49.1823838, lng: 21.9846862 },
    { lat: 49.1812206, lng: 21.9846389 },
    { lat: 49.1792297, lng: 21.9845904 },
    { lat: 49.1790117, lng: 21.9846854 },
    { lat: 49.1789536, lng: 21.987014 },
    { lat: 49.1772261, lng: 21.9890553 },
    { lat: 49.1767711, lng: 21.9901337 },
    { lat: 49.1767068, lng: 21.9909616 },
    { lat: 49.1764116, lng: 21.9909837 },
    { lat: 49.1750199, lng: 21.9926377 },
    { lat: 49.1747717, lng: 21.9930719 },
    { lat: 49.1746148, lng: 21.9938803 },
    { lat: 49.1758298, lng: 21.9958823 },
    { lat: 49.1762013, lng: 21.9967765 },
    { lat: 49.1764823, lng: 21.9980728 },
    { lat: 49.1745505, lng: 22.0018036 },
    { lat: 49.1734753, lng: 22.002738 },
    { lat: 49.1732532, lng: 22.0023842 },
    { lat: 49.171657, lng: 22.0038207 },
    { lat: 49.1708327, lng: 22.0044832 },
    { lat: 49.1695141, lng: 22.0059064 },
    { lat: 49.1697042, lng: 22.0067404 },
    { lat: 49.1702175, lng: 22.0078696 },
    { lat: 49.1697445, lng: 22.0085461 },
    { lat: 49.1696243, lng: 22.0113935 },
    { lat: 49.1692658, lng: 22.0128971 },
    { lat: 49.1691567, lng: 22.0138765 },
    { lat: 49.1693039, lng: 22.0147597 },
    { lat: 49.169214, lng: 22.0156446 },
    { lat: 49.1692899, lng: 22.0167421 },
    { lat: 49.1690186, lng: 22.0180408 },
    { lat: 49.1684265, lng: 22.0196551 },
    { lat: 49.1679605, lng: 22.0213683 },
    { lat: 49.1676949, lng: 22.0215047 },
    { lat: 49.1669839, lng: 22.0215872 },
    { lat: 49.166898, lng: 22.0217089 },
    { lat: 49.1665572, lng: 22.021761 },
    { lat: 49.1669001, lng: 22.0223643 },
    { lat: 49.1678418, lng: 22.023359 },
    { lat: 49.1685884, lng: 22.024613 },
    { lat: 49.169749, lng: 22.0258741 },
    { lat: 49.1710818, lng: 22.0258737 },
    { lat: 49.1721372, lng: 22.0251846 },
    { lat: 49.1729531, lng: 22.0248317 },
    { lat: 49.172867, lng: 22.0248052 },
    { lat: 49.1730545, lng: 22.0246086 },
    { lat: 49.1741485, lng: 22.0242131 },
    { lat: 49.1745852, lng: 22.0234748 },
    { lat: 49.1755194, lng: 22.022434 },
    { lat: 49.1770056, lng: 22.0214607 },
    { lat: 49.1775881, lng: 22.0209039 },
    { lat: 49.1778608, lng: 22.0206432 },
    { lat: 49.1779379, lng: 22.0204913 },
    { lat: 49.1784301, lng: 22.0195213 },
    { lat: 49.1789922, lng: 22.0174148 },
    { lat: 49.180263, lng: 22.0160054 },
    { lat: 49.1802096, lng: 22.0147185 },
    { lat: 49.1806493, lng: 22.0136016 },
    { lat: 49.1817524, lng: 22.0131259 },
    { lat: 49.1825683, lng: 22.0126724 },
    { lat: 49.1847415, lng: 22.0127762 },
    { lat: 49.1855898, lng: 22.0123686 },
    { lat: 49.1870067, lng: 22.0109123 },
    { lat: 49.1879966, lng: 22.0090948 },
    { lat: 49.1883291, lng: 22.009641 },
    { lat: 49.1884182, lng: 22.0095126 },
    { lat: 49.1885, lng: 22.0096795 },
    { lat: 49.1891058, lng: 22.0087861 },
    { lat: 49.1895413, lng: 22.0098569 },
    { lat: 49.190078, lng: 22.0090763 },
    { lat: 49.1896826, lng: 22.0079389 },
    { lat: 49.1901855, lng: 22.0071961 },
    { lat: 49.1908371, lng: 22.0088427 },
    { lat: 49.1924812, lng: 22.0137243 },
    { lat: 49.1931498, lng: 22.0152888 },
    { lat: 49.1940882, lng: 22.0158612 },
    { lat: 49.1951699, lng: 22.0157566 },
    { lat: 49.1967582, lng: 22.016796 },
    { lat: 49.196798, lng: 22.0165798 },
    { lat: 49.1977485, lng: 22.0171473 },
    { lat: 49.1989219, lng: 22.0181324 },
    { lat: 49.2020097, lng: 22.0211445 },
    { lat: 49.2021668, lng: 22.0211807 },
    { lat: 49.2034767, lng: 22.0223346 },
    { lat: 49.2044598, lng: 22.0229471 },
    { lat: 49.2056868, lng: 22.0235558 },
    { lat: 49.2076535, lng: 22.0243767 },
    { lat: 49.207978, lng: 22.0243046 },
    { lat: 49.2091945, lng: 22.0252268 },
    { lat: 49.2099593, lng: 22.0266305 },
    { lat: 49.2115267, lng: 22.0267519 },
    { lat: 49.2123337, lng: 22.0265863 },
    { lat: 49.213273, lng: 22.0272102 },
    { lat: 49.2139857, lng: 22.0275236 },
    { lat: 49.2141293, lng: 22.0275059 },
    { lat: 49.2151977, lng: 22.027875 },
    { lat: 49.2162442, lng: 22.0280432 },
    { lat: 49.2175841, lng: 22.0304954 },
    { lat: 49.2184155, lng: 22.0316352 },
    { lat: 49.2201338, lng: 22.032794 },
    { lat: 49.2209087, lng: 22.0335509 },
    { lat: 49.221448, lng: 22.0341833 },
    { lat: 49.2224773, lng: 22.0348796 },
    { lat: 49.2228145, lng: 22.0345927 },
    { lat: 49.223584, lng: 22.033681 },
    { lat: 49.2243266, lng: 22.0324994 },
    { lat: 49.22475, lng: 22.031483 },
    { lat: 49.22517, lng: 22.030419 },
    { lat: 49.2255364, lng: 22.0302283 },
    { lat: 49.2259142, lng: 22.0302523 },
    { lat: 49.226516, lng: 22.030487 },
    { lat: 49.2271649, lng: 22.0307412 },
    { lat: 49.2272082, lng: 22.0307496 },
    { lat: 49.22755, lng: 22.0308601 },
    { lat: 49.2279472, lng: 22.0311135 },
    { lat: 49.2284201, lng: 22.0317629 },
    { lat: 49.2288001, lng: 22.0321514 },
    { lat: 49.229334, lng: 22.0324886 },
    { lat: 49.2297712, lng: 22.0330338 },
    { lat: 49.2304841, lng: 22.0337821 },
    { lat: 49.230745, lng: 22.03394 },
    { lat: 49.231365, lng: 22.034293 },
    { lat: 49.2321601, lng: 22.0347516 },
    { lat: 49.232236, lng: 22.0347705 },
    { lat: 49.2327688, lng: 22.0348085 },
    { lat: 49.2333181, lng: 22.0349243 },
    { lat: 49.2338231, lng: 22.0350573 },
    { lat: 49.2349925, lng: 22.0346808 },
    { lat: 49.2360228, lng: 22.0344243 },
    { lat: 49.2367507, lng: 22.0343399 },
    { lat: 49.237811, lng: 22.034373 },
    { lat: 49.238722, lng: 22.034396 },
    { lat: 49.239128, lng: 22.03426 },
    { lat: 49.240231, lng: 22.033883 },
    { lat: 49.2414265, lng: 22.0334773 },
    { lat: 49.2417508, lng: 22.0333672 },
    { lat: 49.2416088, lng: 22.0326096 },
    { lat: 49.2420177, lng: 22.0312301 },
    { lat: 49.2421061, lng: 22.0301284 },
    { lat: 49.2421264, lng: 22.0276396 },
    { lat: 49.2420639, lng: 22.0256392 },
    { lat: 49.2420766, lng: 22.0239694 },
    { lat: 49.2415679, lng: 22.0231581 },
    { lat: 49.2407689, lng: 22.0223429 },
    { lat: 49.2400931, lng: 22.0212182 },
    { lat: 49.2398773, lng: 22.0194946 },
    { lat: 49.2394873, lng: 22.0174022 },
    { lat: 49.2391436, lng: 22.0159288 },
    { lat: 49.2389777, lng: 22.0141262 },
    { lat: 49.2385936, lng: 22.0128855 },
    { lat: 49.2386094, lng: 22.0113999 },
    { lat: 49.2388655, lng: 22.010311 },
    { lat: 49.2389549, lng: 22.0091585 },
    { lat: 49.2391093, lng: 22.0079214 },
    { lat: 49.2379766, lng: 22.0067013 },
  ],
  Volica: [
    { lat: 49.1486052, lng: 21.8992326 },
    { lat: 49.1483718, lng: 21.9040501 },
    { lat: 49.1479779, lng: 21.9079905 },
    { lat: 49.1490823, lng: 21.9134679 },
    { lat: 49.1493273, lng: 21.9157098 },
    { lat: 49.1484706, lng: 21.9161375 },
    { lat: 49.1485251, lng: 21.9164218 },
    { lat: 49.1488308, lng: 21.9171794 },
    { lat: 49.1488751, lng: 21.9177338 },
    { lat: 49.1475793, lng: 21.9182308 },
    { lat: 49.1471982, lng: 21.9185137 },
    { lat: 49.1471092, lng: 21.9189148 },
    { lat: 49.1469625, lng: 21.9190717 },
    { lat: 49.1464634, lng: 21.9199845 },
    { lat: 49.1461615, lng: 21.9199297 },
    { lat: 49.1455379, lng: 21.9201771 },
    { lat: 49.1444282, lng: 21.9211878 },
    { lat: 49.1440986, lng: 21.9220454 },
    { lat: 49.144096, lng: 21.9233881 },
    { lat: 49.1440145, lng: 21.9235067 },
    { lat: 49.1417282, lng: 21.9246978 },
    { lat: 49.1409458, lng: 21.9240324 },
    { lat: 49.1401932, lng: 21.9242602 },
    { lat: 49.1405647, lng: 21.926752 },
    { lat: 49.1409026, lng: 21.9274104 },
    { lat: 49.1409881, lng: 21.9280456 },
    { lat: 49.1408283, lng: 21.9288488 },
    { lat: 49.1409686, lng: 21.9294795 },
    { lat: 49.1409473, lng: 21.9303375 },
    { lat: 49.1408451, lng: 21.9307968 },
    { lat: 49.1411492, lng: 21.9309053 },
    { lat: 49.1418347, lng: 21.9325959 },
    { lat: 49.1425293, lng: 21.9345698 },
    { lat: 49.1419338, lng: 21.9360983 },
    { lat: 49.1431373, lng: 21.9358209 },
    { lat: 49.1457466, lng: 21.9354047 },
    { lat: 49.1489438, lng: 21.9337498 },
    { lat: 49.1523879, lng: 21.9331045 },
    { lat: 49.1546713, lng: 21.9334234 },
    { lat: 49.1551627, lng: 21.9332894 },
    { lat: 49.1558832, lng: 21.9329061 },
    { lat: 49.1565665, lng: 21.932792 },
    { lat: 49.156964, lng: 21.9324007 },
    { lat: 49.1571378, lng: 21.931471 },
    { lat: 49.1581267, lng: 21.9325693 },
    { lat: 49.1586774, lng: 21.9335909 },
    { lat: 49.1588726, lng: 21.9341136 },
    { lat: 49.1592582, lng: 21.9346937 },
    { lat: 49.1602338, lng: 21.9358921 },
    { lat: 49.1605173, lng: 21.9361665 },
    { lat: 49.1609851, lng: 21.9362966 },
    { lat: 49.1611777, lng: 21.9364404 },
    { lat: 49.1616023, lng: 21.936418 },
    { lat: 49.1618421, lng: 21.9365257 },
    { lat: 49.1622521, lng: 21.9363601 },
    { lat: 49.1626649, lng: 21.9365441 },
    { lat: 49.1630282, lng: 21.9364027 },
    { lat: 49.1632359, lng: 21.9365769 },
    { lat: 49.1637179, lng: 21.9361383 },
    { lat: 49.1638368, lng: 21.9361707 },
    { lat: 49.1637902, lng: 21.9344356 },
    { lat: 49.1650648, lng: 21.9342597 },
    { lat: 49.1667748, lng: 21.9343192 },
    { lat: 49.1668218, lng: 21.9330401 },
    { lat: 49.1667986, lng: 21.9327381 },
    { lat: 49.1673383, lng: 21.9325937 },
    { lat: 49.1677323, lng: 21.9322396 },
    { lat: 49.169017, lng: 21.9319782 },
    { lat: 49.1694387, lng: 21.9317895 },
    { lat: 49.1705302, lng: 21.9318559 },
    { lat: 49.1707373, lng: 21.9314558 },
    { lat: 49.1710954, lng: 21.9314671 },
    { lat: 49.171302, lng: 21.9310055 },
    { lat: 49.1714936, lng: 21.9316497 },
    { lat: 49.1715366, lng: 21.9316645 },
    { lat: 49.1723751, lng: 21.9333128 },
    { lat: 49.1737597, lng: 21.9332688 },
    { lat: 49.1739504, lng: 21.9340188 },
    { lat: 49.1769485, lng: 21.9349912 },
    { lat: 49.1769643, lng: 21.9349508 },
    { lat: 49.17699, lng: 21.9348479 },
    { lat: 49.1768515, lng: 21.9328466 },
    { lat: 49.17525, lng: 21.9333539 },
    { lat: 49.1731532, lng: 21.931217 },
    { lat: 49.1730053, lng: 21.9302029 },
    { lat: 49.1723318, lng: 21.9298507 },
    { lat: 49.173435, lng: 21.92789 },
    { lat: 49.1710178, lng: 21.9282547 },
    { lat: 49.1707611, lng: 21.9269266 },
    { lat: 49.1705005, lng: 21.9246864 },
    { lat: 49.1706012, lng: 21.9237611 },
    { lat: 49.1705393, lng: 21.9220857 },
    { lat: 49.1697224, lng: 21.9200467 },
    { lat: 49.1681636, lng: 21.9182431 },
    { lat: 49.1681876, lng: 21.9180329 },
    { lat: 49.1679042, lng: 21.9174088 },
    { lat: 49.1677244, lng: 21.9172313 },
    { lat: 49.1669008, lng: 21.9156903 },
    { lat: 49.165781, lng: 21.9148589 },
    { lat: 49.1666328, lng: 21.9129336 },
    { lat: 49.1672972, lng: 21.9119299 },
    { lat: 49.1668465, lng: 21.9113562 },
    { lat: 49.1658599, lng: 21.909368 },
    { lat: 49.1657157, lng: 21.9092283 },
    { lat: 49.164718, lng: 21.9089108 },
    { lat: 49.1640983, lng: 21.909275 },
    { lat: 49.1639953, lng: 21.9101069 },
    { lat: 49.1638495, lng: 21.9103925 },
    { lat: 49.1637145, lng: 21.9108852 },
    { lat: 49.1631135, lng: 21.9110085 },
    { lat: 49.1629531, lng: 21.9108446 },
    { lat: 49.1625096, lng: 21.9105653 },
    { lat: 49.1621748, lng: 21.910436 },
    { lat: 49.1615927, lng: 21.9105068 },
    { lat: 49.1609123, lng: 21.9103782 },
    { lat: 49.1602484, lng: 21.9116725 },
    { lat: 49.159277, lng: 21.9123205 },
    { lat: 49.1576506, lng: 21.9115568 },
    { lat: 49.1564098, lng: 21.9114601 },
    { lat: 49.1554532, lng: 21.9065465 },
    { lat: 49.1546768, lng: 21.9035275 },
    { lat: 49.1533244, lng: 21.9002357 },
    { lat: 49.151295, lng: 21.900722 },
    { lat: 49.1486052, lng: 21.8992326 },
  ],
  Oľšinkov: [
    { lat: 49.2084478, lng: 22.0619273 },
    { lat: 49.2103206, lng: 22.0621675 },
    { lat: 49.2112025, lng: 22.0623422 },
    { lat: 49.2116131, lng: 22.0621528 },
    { lat: 49.2122335, lng: 22.0614701 },
    { lat: 49.2128664, lng: 22.0608888 },
    { lat: 49.2132724, lng: 22.0603233 },
    { lat: 49.2135796, lng: 22.0601415 },
    { lat: 49.2140554, lng: 22.0601653 },
    { lat: 49.2144633, lng: 22.0600074 },
    { lat: 49.214668, lng: 22.059127 },
    { lat: 49.214925, lng: 22.058157 },
    { lat: 49.2152729, lng: 22.0570749 },
    { lat: 49.2154178, lng: 22.056236 },
    { lat: 49.2155135, lng: 22.0548754 },
    { lat: 49.2157298, lng: 22.0537955 },
    { lat: 49.215998, lng: 22.0530055 },
    { lat: 49.2160157, lng: 22.0518501 },
    { lat: 49.2162936, lng: 22.0506766 },
    { lat: 49.2163322, lng: 22.0506156 },
    { lat: 49.2169076, lng: 22.0499176 },
    { lat: 49.217503, lng: 22.048632 },
    { lat: 49.2180427, lng: 22.0475439 },
    { lat: 49.2184811, lng: 22.0458967 },
    { lat: 49.2190695, lng: 22.0448167 },
    { lat: 49.2197982, lng: 22.0432121 },
    { lat: 49.2202812, lng: 22.0418573 },
    { lat: 49.2206594, lng: 22.0411636 },
    { lat: 49.2208118, lng: 22.0402001 },
    { lat: 49.2210006, lng: 22.0386572 },
    { lat: 49.2214024, lng: 22.0378429 },
    { lat: 49.2222336, lng: 22.0365823 },
    { lat: 49.2224773, lng: 22.0348796 },
    { lat: 49.221448, lng: 22.0341833 },
    { lat: 49.2209087, lng: 22.0335509 },
    { lat: 49.2201338, lng: 22.032794 },
    { lat: 49.2184155, lng: 22.0316352 },
    { lat: 49.2175841, lng: 22.0304954 },
    { lat: 49.2162442, lng: 22.0280432 },
    { lat: 49.2151977, lng: 22.027875 },
    { lat: 49.2141293, lng: 22.0275059 },
    { lat: 49.2139857, lng: 22.0275236 },
    { lat: 49.213273, lng: 22.0272102 },
    { lat: 49.2123337, lng: 22.0265863 },
    { lat: 49.2115267, lng: 22.0267519 },
    { lat: 49.2099593, lng: 22.0266305 },
    { lat: 49.2091945, lng: 22.0252268 },
    { lat: 49.207978, lng: 22.0243046 },
    { lat: 49.2076535, lng: 22.0243767 },
    { lat: 49.2056868, lng: 22.0235558 },
    { lat: 49.2044598, lng: 22.0229471 },
    { lat: 49.2034767, lng: 22.0223346 },
    { lat: 49.2021668, lng: 22.0211807 },
    { lat: 49.2020097, lng: 22.0211445 },
    { lat: 49.1989219, lng: 22.0181324 },
    { lat: 49.1977485, lng: 22.0171473 },
    { lat: 49.196798, lng: 22.0165798 },
    { lat: 49.1967582, lng: 22.016796 },
    { lat: 49.1951699, lng: 22.0157566 },
    { lat: 49.1945755, lng: 22.0183026 },
    { lat: 49.1946089, lng: 22.0192217 },
    { lat: 49.1940108, lng: 22.0223216 },
    { lat: 49.1931988, lng: 22.024291 },
    { lat: 49.1926566, lng: 22.0252282 },
    { lat: 49.1927664, lng: 22.0261876 },
    { lat: 49.1924349, lng: 22.0267617 },
    { lat: 49.1915322, lng: 22.0278607 },
    { lat: 49.1913373, lng: 22.0281758 },
    { lat: 49.1910154, lng: 22.028918 },
    { lat: 49.1902503, lng: 22.0316285 },
    { lat: 49.1890339, lng: 22.0339976 },
    { lat: 49.1896435, lng: 22.0348914 },
    { lat: 49.1899253, lng: 22.0361004 },
    { lat: 49.1906004, lng: 22.0397334 },
    { lat: 49.1907695, lng: 22.0410207 },
    { lat: 49.1907542, lng: 22.0418803 },
    { lat: 49.1905802, lng: 22.0430719 },
    { lat: 49.1906642, lng: 22.043717 },
    { lat: 49.1913268, lng: 22.0449578 },
    { lat: 49.1920678, lng: 22.0450371 },
    { lat: 49.192409, lng: 22.045768 },
    { lat: 49.1923334, lng: 22.0465604 },
    { lat: 49.1925376, lng: 22.0476743 },
    { lat: 49.1925514, lng: 22.0487649 },
    { lat: 49.1930795, lng: 22.0487928 },
    { lat: 49.1936551, lng: 22.0491115 },
    { lat: 49.1941737, lng: 22.0507715 },
    { lat: 49.1967601, lng: 22.0534473 },
    { lat: 49.1985093, lng: 22.0550264 },
    { lat: 49.199079, lng: 22.0561904 },
    { lat: 49.1999122, lng: 22.0569352 },
    { lat: 49.2006177, lng: 22.0574292 },
    { lat: 49.2011787, lng: 22.0582664 },
    { lat: 49.2017257, lng: 22.0586641 },
    { lat: 49.2020648, lng: 22.0590673 },
    { lat: 49.2021408, lng: 22.0592419 },
    { lat: 49.2038799, lng: 22.0615323 },
    { lat: 49.2063405, lng: 22.0612364 },
    { lat: 49.2071081, lng: 22.0614451 },
    { lat: 49.2084478, lng: 22.0619273 },
  ],
  Kalinov: [
    { lat: 49.30674, lng: 21.9217179 },
    { lat: 49.3072118, lng: 21.9228644 },
    { lat: 49.3078875, lng: 21.9250773 },
    { lat: 49.3086381, lng: 21.9267289 },
    { lat: 49.3079891, lng: 21.928319 },
    { lat: 49.3080392, lng: 21.9287894 },
    { lat: 49.3082356, lng: 21.9294131 },
    { lat: 49.3084074, lng: 21.9296281 },
    { lat: 49.3084789, lng: 21.9298326 },
    { lat: 49.3087048, lng: 21.9297345 },
    { lat: 49.3089616, lng: 21.9295188 },
    { lat: 49.3095576, lng: 21.9312513 },
    { lat: 49.30994, lng: 21.9309721 },
    { lat: 49.3103248, lng: 21.9302048 },
    { lat: 49.3104268, lng: 21.930241 },
    { lat: 49.3108287, lng: 21.9309401 },
    { lat: 49.3112926, lng: 21.9314093 },
    { lat: 49.3114228, lng: 21.9314407 },
    { lat: 49.3114331, lng: 21.9315457 },
    { lat: 49.3115792, lng: 21.9315801 },
    { lat: 49.3117096, lng: 21.931764 },
    { lat: 49.3120113, lng: 21.9319581 },
    { lat: 49.3120812, lng: 21.9318758 },
    { lat: 49.3122838, lng: 21.9318335 },
    { lat: 49.3123833, lng: 21.9319903 },
    { lat: 49.3127175, lng: 21.93218 },
    { lat: 49.3128677, lng: 21.9325718 },
    { lat: 49.3131867, lng: 21.9328717 },
    { lat: 49.3132743, lng: 21.9328619 },
    { lat: 49.3133253, lng: 21.9331127 },
    { lat: 49.3134005, lng: 21.9331888 },
    { lat: 49.3134326, lng: 21.9334025 },
    { lat: 49.3135106, lng: 21.9334564 },
    { lat: 49.3135517, lng: 21.9335744 },
    { lat: 49.3135404, lng: 21.933711 },
    { lat: 49.3137152, lng: 21.9340977 },
    { lat: 49.3137316, lng: 21.9342536 },
    { lat: 49.3138095, lng: 21.9342711 },
    { lat: 49.3139251, lng: 21.934485 },
    { lat: 49.3140478, lng: 21.9345371 },
    { lat: 49.3140745, lng: 21.9346181 },
    { lat: 49.3143659, lng: 21.9345713 },
    { lat: 49.3146598, lng: 21.9349916 },
    { lat: 49.3147527, lng: 21.9357476 },
    { lat: 49.314813, lng: 21.9358706 },
    { lat: 49.3149287, lng: 21.9369591 },
    { lat: 49.3151017, lng: 21.9372743 },
    { lat: 49.3155331, lng: 21.9383846 },
    { lat: 49.3157497, lng: 21.9393659 },
    { lat: 49.316115, lng: 21.9396812 },
    { lat: 49.3164485, lng: 21.9403894 },
    { lat: 49.3164972, lng: 21.9406239 },
    { lat: 49.3165471, lng: 21.9406055 },
    { lat: 49.317053, lng: 21.9409537 },
    { lat: 49.3192501, lng: 21.9421717 },
    { lat: 49.3204405, lng: 21.942456 },
    { lat: 49.320713, lng: 21.9426115 },
    { lat: 49.3212592, lng: 21.9426831 },
    { lat: 49.3219219, lng: 21.9433339 },
    { lat: 49.3220226, lng: 21.9435379 },
    { lat: 49.3220343, lng: 21.9436944 },
    { lat: 49.3219963, lng: 21.9438005 },
    { lat: 49.3222356, lng: 21.9441465 },
    { lat: 49.3224296, lng: 21.944626 },
    { lat: 49.3226201, lng: 21.94556 },
    { lat: 49.322213, lng: 21.945833 },
    { lat: 49.3224381, lng: 21.9467131 },
    { lat: 49.3228542, lng: 21.9474845 },
    { lat: 49.3232528, lng: 21.9475571 },
    { lat: 49.3249754, lng: 21.9471648 },
    { lat: 49.3259476, lng: 21.9473291 },
    { lat: 49.326807, lng: 21.9475612 },
    { lat: 49.327256, lng: 21.947756 },
    { lat: 49.3275919, lng: 21.9480565 },
    { lat: 49.3280286, lng: 21.9486664 },
    { lat: 49.3292029, lng: 21.9498637 },
    { lat: 49.3296733, lng: 21.9502351 },
    { lat: 49.3297619, lng: 21.9511366 },
    { lat: 49.3299543, lng: 21.9515507 },
    { lat: 49.3303623, lng: 21.9518633 },
    { lat: 49.330839, lng: 21.9524518 },
    { lat: 49.3309247, lng: 21.9526906 },
    { lat: 49.3312057, lng: 21.9529125 },
    { lat: 49.3313203, lng: 21.9535027 },
    { lat: 49.3315829, lng: 21.954079 },
    { lat: 49.3321274, lng: 21.9546773 },
    { lat: 49.3323959, lng: 21.9555379 },
    { lat: 49.3326098, lng: 21.9572908 },
    { lat: 49.3325487, lng: 21.9580956 },
    { lat: 49.3326628, lng: 21.9591734 },
    { lat: 49.3326005, lng: 21.9604907 },
    { lat: 49.332637, lng: 21.9613826 },
    { lat: 49.3327258, lng: 21.9618806 },
    { lat: 49.3327441, lng: 21.9622643 },
    { lat: 49.3328928, lng: 21.9628324 },
    { lat: 49.333523, lng: 21.9641819 },
    { lat: 49.3337802, lng: 21.9653579 },
    { lat: 49.3337928, lng: 21.9665224 },
    { lat: 49.3339995, lng: 21.9683952 },
    { lat: 49.3338431, lng: 21.9700482 },
    { lat: 49.3334588, lng: 21.9728254 },
    { lat: 49.3341375, lng: 21.9727652 },
    { lat: 49.3347562, lng: 21.9723343 },
    { lat: 49.3358438, lng: 21.9714161 },
    { lat: 49.3364513, lng: 21.9710059 },
    { lat: 49.3372186, lng: 21.9701873 },
    { lat: 49.3379124, lng: 21.9695472 },
    { lat: 49.338855, lng: 21.968794 },
    { lat: 49.3389053, lng: 21.968762 },
    { lat: 49.3399243, lng: 21.9672503 },
    { lat: 49.3401687, lng: 21.9668005 },
    { lat: 49.3407711, lng: 21.9659862 },
    { lat: 49.3412983, lng: 21.9655764 },
    { lat: 49.3416858, lng: 21.9652742 },
    { lat: 49.342596, lng: 21.96549 },
    { lat: 49.343454, lng: 21.965693 },
    { lat: 49.344474, lng: 21.965939 },
    { lat: 49.3451219, lng: 21.9660895 },
    { lat: 49.34531, lng: 21.96575 },
    { lat: 49.345402, lng: 21.965756 },
    { lat: 49.345515, lng: 21.965555 },
    { lat: 49.345939, lng: 21.964675 },
    { lat: 49.346069, lng: 21.964304 },
    { lat: 49.346344, lng: 21.964152 },
    { lat: 49.346534, lng: 21.964006 },
    { lat: 49.34677, lng: 21.963682 },
    { lat: 49.347158, lng: 21.963389 },
    { lat: 49.347519, lng: 21.963145 },
    { lat: 49.347908, lng: 21.96278 },
    { lat: 49.348295, lng: 21.96229 },
    { lat: 49.348548, lng: 21.961952 },
    { lat: 49.348804, lng: 21.961421 },
    { lat: 49.349051, lng: 21.961011 },
    { lat: 49.349209, lng: 21.960508 },
    { lat: 49.349381, lng: 21.959879 },
    { lat: 49.349425, lng: 21.959311 },
    { lat: 49.349335, lng: 21.958652 },
    { lat: 49.349393, lng: 21.957489 },
    { lat: 49.349516, lng: 21.956926 },
    { lat: 49.349561, lng: 21.956202 },
    { lat: 49.349665, lng: 21.95498 },
    { lat: 49.349802, lng: 21.95394 },
    { lat: 49.349981, lng: 21.952992 },
    { lat: 49.350065, lng: 21.952505 },
    { lat: 49.35007, lng: 21.95239 },
    { lat: 49.3500216, lng: 21.9522781 },
    { lat: 49.3495918, lng: 21.9517543 },
    { lat: 49.349385, lng: 21.9512338 },
    { lat: 49.3492416, lng: 21.9508932 },
    { lat: 49.3490163, lng: 21.950704 },
    { lat: 49.3486984, lng: 21.9503223 },
    { lat: 49.348437, lng: 21.950111 },
    { lat: 49.348074, lng: 21.949997 },
    { lat: 49.347922, lng: 21.94992 },
    { lat: 49.3478752, lng: 21.9498742 },
    { lat: 49.347756, lng: 21.949366 },
    { lat: 49.347717, lng: 21.948864 },
    { lat: 49.347642, lng: 21.948287 },
    { lat: 49.347779, lng: 21.947635 },
    { lat: 49.347933, lng: 21.947254 },
    { lat: 49.348137, lng: 21.946878 },
    { lat: 49.3482761, lng: 21.9463216 },
    { lat: 49.348427, lng: 21.945844 },
    { lat: 49.3486242, lng: 21.9454184 },
    { lat: 49.348671, lng: 21.945088 },
    { lat: 49.3486719, lng: 21.9448186 },
    { lat: 49.348565, lng: 21.944085 },
    { lat: 49.348506, lng: 21.943879 },
    { lat: 49.348161, lng: 21.943178 },
    { lat: 49.348051, lng: 21.942942 },
    { lat: 49.3479795, lng: 21.9426641 },
    { lat: 49.3479515, lng: 21.9422637 },
    { lat: 49.347894, lng: 21.94187 },
    { lat: 49.3477365, lng: 21.9407593 },
    { lat: 49.3476173, lng: 21.9398894 },
    { lat: 49.347524, lng: 21.939684 },
    { lat: 49.3471223, lng: 21.9391087 },
    { lat: 49.3469837, lng: 21.9388631 },
    { lat: 49.3468821, lng: 21.9384919 },
    { lat: 49.3465724, lng: 21.9377703 },
    { lat: 49.346407, lng: 21.937209 },
    { lat: 49.346266, lng: 21.93656 },
    { lat: 49.346231, lng: 21.936177 },
    { lat: 49.346189, lng: 21.935852 },
    { lat: 49.346099, lng: 21.935065 },
    { lat: 49.346115, lng: 21.934027 },
    { lat: 49.346247, lng: 21.933249 },
    { lat: 49.346351, lng: 21.932583 },
    { lat: 49.346445, lng: 21.931965 },
    { lat: 49.346583, lng: 21.931323 },
    { lat: 49.34667, lng: 21.930976 },
    { lat: 49.346926, lng: 21.930345 },
    { lat: 49.347157, lng: 21.930082 },
    { lat: 49.3473828, lng: 21.9296291 },
    { lat: 49.3480232, lng: 21.9285813 },
    { lat: 49.348447, lng: 21.927734 },
    { lat: 49.348659, lng: 21.927394 },
    { lat: 49.348885, lng: 21.927176 },
    { lat: 49.34919, lng: 21.926934 },
    { lat: 49.349563, lng: 21.926517 },
    { lat: 49.349935, lng: 21.926132 },
    { lat: 49.3501787, lng: 21.925836 },
    { lat: 49.350422, lng: 21.925517 },
    { lat: 49.3509915, lng: 21.9248286 },
    { lat: 49.351332, lng: 21.924484 },
    { lat: 49.3517579, lng: 21.9237852 },
    { lat: 49.352176, lng: 21.92288 },
    { lat: 49.352488, lng: 21.922533 },
    { lat: 49.35274, lng: 21.921972 },
    { lat: 49.3532551, lng: 21.9207311 },
    { lat: 49.3537916, lng: 21.9193109 },
    { lat: 49.3539347, lng: 21.9187987 },
    { lat: 49.35403, lng: 21.9180581 },
    { lat: 49.3541933, lng: 21.9180331 },
    { lat: 49.3540853, lng: 21.9170679 },
    { lat: 49.354073, lng: 21.916968 },
    { lat: 49.353885, lng: 21.915619 },
    { lat: 49.353481, lng: 21.914144 },
    { lat: 49.353385, lng: 21.913037 },
    { lat: 49.353259, lng: 21.911798 },
    { lat: 49.3535473, lng: 21.9105177 },
    { lat: 49.3537903, lng: 21.909633 },
    { lat: 49.353571, lng: 21.9090104 },
    { lat: 49.3528662, lng: 21.9097234 },
    { lat: 49.3522679, lng: 21.9107205 },
    { lat: 49.3520197, lng: 21.9108706 },
    { lat: 49.3519477, lng: 21.9110476 },
    { lat: 49.35158, lng: 21.9111856 },
    { lat: 49.3512402, lng: 21.9114919 },
    { lat: 49.350858, lng: 21.9116379 },
    { lat: 49.3506106, lng: 21.9119965 },
    { lat: 49.3501289, lng: 21.9124806 },
    { lat: 49.3495254, lng: 21.9132239 },
    { lat: 49.3495128, lng: 21.9134837 },
    { lat: 49.3491083, lng: 21.9136418 },
    { lat: 49.3485587, lng: 21.9140613 },
    { lat: 49.3484021, lng: 21.9144521 },
    { lat: 49.3472116, lng: 21.9150662 },
    { lat: 49.3471127, lng: 21.9152108 },
    { lat: 49.3470351, lng: 21.9154725 },
    { lat: 49.3465015, lng: 21.9155036 },
    { lat: 49.3458774, lng: 21.9158725 },
    { lat: 49.3456875, lng: 21.9160524 },
    { lat: 49.3456533, lng: 21.9162705 },
    { lat: 49.344526, lng: 21.9169782 },
    { lat: 49.3441207, lng: 21.9168698 },
    { lat: 49.343865, lng: 21.9169936 },
    { lat: 49.3437701, lng: 21.9172419 },
    { lat: 49.3433194, lng: 21.9174849 },
    { lat: 49.3431402, lng: 21.9174632 },
    { lat: 49.3429649, lng: 21.9175607 },
    { lat: 49.3428995, lng: 21.9176882 },
    { lat: 49.3427386, lng: 21.9177748 },
    { lat: 49.3427675, lng: 21.9178593 },
    { lat: 49.3426912, lng: 21.9180856 },
    { lat: 49.3424275, lng: 21.9181183 },
    { lat: 49.3423397, lng: 21.9181959 },
    { lat: 49.3422437, lng: 21.9170093 },
    { lat: 49.3420732, lng: 21.9120525 },
    { lat: 49.3415141, lng: 21.9083985 },
    { lat: 49.3409678, lng: 21.9084062 },
    { lat: 49.3405942, lng: 21.908489 },
    { lat: 49.3395311, lng: 21.9083891 },
    { lat: 49.3386256, lng: 21.9086054 },
    { lat: 49.3376465, lng: 21.9084036 },
    { lat: 49.3375086, lng: 21.9084206 },
    { lat: 49.3370705, lng: 21.9082637 },
    { lat: 49.3361429, lng: 21.9082277 },
    { lat: 49.3348407, lng: 21.9086402 },
    { lat: 49.3345588, lng: 21.9086119 },
    { lat: 49.3338745, lng: 21.9088712 },
    { lat: 49.3336485, lng: 21.9088256 },
    { lat: 49.3331917, lng: 21.9085003 },
    { lat: 49.332588, lng: 21.9084663 },
    { lat: 49.332215, lng: 21.9083495 },
    { lat: 49.3312689, lng: 21.9087066 },
    { lat: 49.3299931, lng: 21.9087782 },
    { lat: 49.3294674, lng: 21.9087151 },
    { lat: 49.3252242, lng: 21.9088593 },
    { lat: 49.3218817, lng: 21.9087345 },
    { lat: 49.3214807, lng: 21.9091077 },
    { lat: 49.3206195, lng: 21.9089964 },
    { lat: 49.3192396, lng: 21.9086363 },
    { lat: 49.3183291, lng: 21.9090121 },
    { lat: 49.3176058, lng: 21.9095976 },
    { lat: 49.3166996, lng: 21.90979 },
    { lat: 49.316298, lng: 21.9099681 },
    { lat: 49.3157333, lng: 21.9100995 },
    { lat: 49.3148915, lng: 21.9106244 },
    { lat: 49.3146121, lng: 21.9106944 },
    { lat: 49.3142951, lng: 21.9108809 },
    { lat: 49.3141986, lng: 21.9111637 },
    { lat: 49.3138076, lng: 21.9113621 },
    { lat: 49.3140743, lng: 21.9117723 },
    { lat: 49.3140802, lng: 21.9123689 },
    { lat: 49.3139953, lng: 21.9125278 },
    { lat: 49.3122583, lng: 21.914629 },
    { lat: 49.3114909, lng: 21.9157915 },
    { lat: 49.3109075, lng: 21.9159379 },
    { lat: 49.3097087, lng: 21.9169398 },
    { lat: 49.308957, lng: 21.9177394 },
    { lat: 49.3081301, lng: 21.9183621 },
    { lat: 49.3075405, lng: 21.9196637 },
    { lat: 49.3076628, lng: 21.9205519 },
    { lat: 49.3073907, lng: 21.9209657 },
    { lat: 49.3069941, lng: 21.9213967 },
    { lat: 49.3066641, lng: 21.9215089 },
    { lat: 49.30674, lng: 21.9217179 },
  ],
  KrásnyBrod: [
    { lat: 49.2623363, lng: 21.9190617 },
    { lat: 49.2621982, lng: 21.9183534 },
    { lat: 49.2621719, lng: 21.9179768 },
    { lat: 49.2622295, lng: 21.9174899 },
    { lat: 49.2622013, lng: 21.916624 },
    { lat: 49.2614029, lng: 21.9170327 },
    { lat: 49.260663, lng: 21.9170869 },
    { lat: 49.2605914, lng: 21.9148479 },
    { lat: 49.2607488, lng: 21.914847 },
    { lat: 49.2607474, lng: 21.9138921 },
    { lat: 49.2608217, lng: 21.9137999 },
    { lat: 49.2609544, lng: 21.913799 },
    { lat: 49.2614542, lng: 21.9129525 },
    { lat: 49.2612707, lng: 21.9126855 },
    { lat: 49.2612554, lng: 21.9125615 },
    { lat: 49.2613343, lng: 21.9121615 },
    { lat: 49.2612106, lng: 21.912034 },
    { lat: 49.2610794, lng: 21.9113754 },
    { lat: 49.2611224, lng: 21.9104824 },
    { lat: 49.2609217, lng: 21.9097857 },
    { lat: 49.260404, lng: 21.9090474 },
    { lat: 49.2602118, lng: 21.9084659 },
    { lat: 49.2600202, lng: 21.9080979 },
    { lat: 49.2598513, lng: 21.9078668 },
    { lat: 49.2597419, lng: 21.9078091 },
    { lat: 49.2594844, lng: 21.9072188 },
    { lat: 49.25887, lng: 21.9067599 },
    { lat: 49.2576468, lng: 21.9038112 },
    { lat: 49.2572067, lng: 21.9011038 },
    { lat: 49.2568397, lng: 21.8982644 },
    { lat: 49.2566026, lng: 21.8949573 },
    { lat: 49.2569653, lng: 21.8945684 },
    { lat: 49.2569275, lng: 21.8944543 },
    { lat: 49.2561659, lng: 21.8926114 },
    { lat: 49.2547855, lng: 21.8905384 },
    { lat: 49.2533391, lng: 21.8867832 },
    { lat: 49.253102, lng: 21.886392 },
    { lat: 49.2542134, lng: 21.88488 },
    { lat: 49.2549188, lng: 21.884081 },
    { lat: 49.2553826, lng: 21.8836504 },
    { lat: 49.2536898, lng: 21.8802617 },
    { lat: 49.2524179, lng: 21.880221 },
    { lat: 49.2524469, lng: 21.8776269 },
    { lat: 49.2532353, lng: 21.8753109 },
    { lat: 49.2546184, lng: 21.8707195 },
    { lat: 49.2554214, lng: 21.8703527 },
    { lat: 49.2556979, lng: 21.8694138 },
    { lat: 49.2557539, lng: 21.8690493 },
    { lat: 49.2561868, lng: 21.8687397 },
    { lat: 49.2561602, lng: 21.868087 },
    { lat: 49.2556415, lng: 21.8681655 },
    { lat: 49.2527898, lng: 21.8685465 },
    { lat: 49.2515135, lng: 21.869404 },
    { lat: 49.2496619, lng: 21.8704192 },
    { lat: 49.2489643, lng: 21.8705875 },
    { lat: 49.2478897, lng: 21.8711453 },
    { lat: 49.2470822, lng: 21.8723569 },
    { lat: 49.2470071, lng: 21.870945 },
    { lat: 49.2460933, lng: 21.8717255 },
    { lat: 49.2445539, lng: 21.8734748 },
    { lat: 49.244024, lng: 21.8743114 },
    { lat: 49.2436081, lng: 21.8762856 },
    { lat: 49.2427984, lng: 21.8759259 },
    { lat: 49.2423542, lng: 21.8758916 },
    { lat: 49.2418497, lng: 21.8761294 },
    { lat: 49.2411926, lng: 21.8761706 },
    { lat: 49.2402861, lng: 21.8768417 },
    { lat: 49.2402535, lng: 21.8769315 },
    { lat: 49.2385064, lng: 21.8781222 },
    { lat: 49.2381203, lng: 21.8784642 },
    { lat: 49.2375641, lng: 21.8791107 },
    { lat: 49.2359975, lng: 21.8804969 },
    { lat: 49.234657, lng: 21.8813518 },
    { lat: 49.2344701, lng: 21.8821935 },
    { lat: 49.2330729, lng: 21.8830811 },
    { lat: 49.2314794, lng: 21.8824694 },
    { lat: 49.2302879, lng: 21.8837476 },
    { lat: 49.2284968, lng: 21.883914 },
    { lat: 49.226841, lng: 21.8852385 },
    { lat: 49.2261645, lng: 21.8868739 },
    { lat: 49.2250737, lng: 21.8865295 },
    { lat: 49.224383, lng: 21.8869759 },
    { lat: 49.2242427, lng: 21.8870755 },
    { lat: 49.22424, lng: 21.8871009 },
    { lat: 49.2239787, lng: 21.8900325 },
    { lat: 49.2235425, lng: 21.893844 },
    { lat: 49.2213638, lng: 21.8929917 },
    { lat: 49.220156, lng: 21.8923788 },
    { lat: 49.2204373, lng: 21.8965874 },
    { lat: 49.2207415, lng: 21.9030244 },
    { lat: 49.2210919, lng: 21.9055613 },
    { lat: 49.2216938, lng: 21.9088568 },
    { lat: 49.2215514, lng: 21.9181986 },
    { lat: 49.2220718, lng: 21.9228393 },
    { lat: 49.222456, lng: 21.9285316 },
    { lat: 49.2225379, lng: 21.9330089 },
    { lat: 49.2227025, lng: 21.9358395 },
    { lat: 49.223062, lng: 21.9360537 },
    { lat: 49.2244808, lng: 21.9365739 },
    { lat: 49.2248945, lng: 21.9370613 },
    { lat: 49.2258098, lng: 21.93555 },
    { lat: 49.2263339, lng: 21.9356803 },
    { lat: 49.2276725, lng: 21.9373728 },
    { lat: 49.2282671, lng: 21.9370366 },
    { lat: 49.2289357, lng: 21.9368724 },
    { lat: 49.2294521, lng: 21.936604 },
    { lat: 49.2305543, lng: 21.9358408 },
    { lat: 49.2315675, lng: 21.9355044 },
    { lat: 49.2320437, lng: 21.9347086 },
    { lat: 49.2333942, lng: 21.9331467 },
    { lat: 49.2340728, lng: 21.9342163 },
    { lat: 49.2346827, lng: 21.9356794 },
    { lat: 49.2349374, lng: 21.9364648 },
    { lat: 49.2355193, lng: 21.9369427 },
    { lat: 49.2359354, lng: 21.9374391 },
    { lat: 49.2366219, lng: 21.9372753 },
    { lat: 49.2370376, lng: 21.9366625 },
    { lat: 49.237053, lng: 21.9356473 },
    { lat: 49.2384812, lng: 21.9349583 },
    { lat: 49.2393806, lng: 21.9330368 },
    { lat: 49.2400045, lng: 21.9322473 },
    { lat: 49.2408688, lng: 21.9317751 },
    { lat: 49.2414743, lng: 21.9316044 },
    { lat: 49.242037, lng: 21.9311122 },
    { lat: 49.2421754, lng: 21.93085 },
    { lat: 49.2422194, lng: 21.9304965 },
    { lat: 49.2425078, lng: 21.9298718 },
    { lat: 49.242612, lng: 21.9290379 },
    { lat: 49.2436091, lng: 21.9282447 },
    { lat: 49.2437805, lng: 21.9279914 },
    { lat: 49.2442542, lng: 21.9276368 },
    { lat: 49.2448109, lng: 21.9273644 },
    { lat: 49.2451698, lng: 21.9267331 },
    { lat: 49.2454308, lng: 21.9264631 },
    { lat: 49.2455531, lng: 21.9261849 },
    { lat: 49.2458794, lng: 21.9259644 },
    { lat: 49.2457644, lng: 21.9256294 },
    { lat: 49.2455181, lng: 21.9254961 },
    { lat: 49.2454834, lng: 21.9243281 },
    { lat: 49.2452149, lng: 21.9242501 },
    { lat: 49.245519, lng: 21.9234081 },
    { lat: 49.2468946, lng: 21.9216888 },
    { lat: 49.2489487, lng: 21.9208856 },
    { lat: 49.2486904, lng: 21.9225257 },
    { lat: 49.2487978, lng: 21.9231373 },
    { lat: 49.2489179, lng: 21.9232653 },
    { lat: 49.2489635, lng: 21.9234221 },
    { lat: 49.2494577, lng: 21.9231274 },
    { lat: 49.250235, lng: 21.9236785 },
    { lat: 49.250414, lng: 21.9229607 },
    { lat: 49.2509918, lng: 21.9218093 },
    { lat: 49.2519753, lng: 21.9227844 },
    { lat: 49.2522005, lng: 21.9231116 },
    { lat: 49.2528194, lng: 21.923754 },
    { lat: 49.2536181, lng: 21.9244383 },
    { lat: 49.2542274, lng: 21.9234329 },
    { lat: 49.2554189, lng: 21.9209157 },
    { lat: 49.2557082, lng: 21.9209659 },
    { lat: 49.2561254, lng: 21.9210508 },
    { lat: 49.2562526, lng: 21.9218273 },
    { lat: 49.2565543, lng: 21.9221104 },
    { lat: 49.2567199, lng: 21.9221185 },
    { lat: 49.2569839, lng: 21.9225532 },
    { lat: 49.2572566, lng: 21.9228021 },
    { lat: 49.257474, lng: 21.9231391 },
    { lat: 49.2576745, lng: 21.9231458 },
    { lat: 49.2579899, lng: 21.9234873 },
    { lat: 49.2586538, lng: 21.9236763 },
    { lat: 49.2592736, lng: 21.9237668 },
    { lat: 49.260392, lng: 21.9242352 },
    { lat: 49.2614006, lng: 21.9244958 },
    { lat: 49.2618659, lng: 21.9242113 },
    { lat: 49.2619347, lng: 21.9242225 },
    { lat: 49.2618873, lng: 21.923622 },
    { lat: 49.2628873, lng: 21.9228628 },
    { lat: 49.2628681, lng: 21.9228085 },
    { lat: 49.2630041, lng: 21.9227149 },
    { lat: 49.2625534, lng: 21.9206448 },
    { lat: 49.2624233, lng: 21.9198014 },
    { lat: 49.2623363, lng: 21.9190617 },
  ],
  Ňagov: [
    { lat: 49.2623363, lng: 21.9190617 },
    { lat: 49.2624233, lng: 21.9198014 },
    { lat: 49.2625534, lng: 21.9206448 },
    { lat: 49.2630041, lng: 21.9227149 },
    { lat: 49.2628681, lng: 21.9228085 },
    { lat: 49.2628873, lng: 21.9228628 },
    { lat: 49.2618873, lng: 21.923622 },
    { lat: 49.2619347, lng: 21.9242225 },
    { lat: 49.2618659, lng: 21.9242113 },
    { lat: 49.2614006, lng: 21.9244958 },
    { lat: 49.260392, lng: 21.9242352 },
    { lat: 49.2592736, lng: 21.9237668 },
    { lat: 49.2586538, lng: 21.9236763 },
    { lat: 49.2579899, lng: 21.9234873 },
    { lat: 49.2576745, lng: 21.9231458 },
    { lat: 49.257474, lng: 21.9231391 },
    { lat: 49.2572566, lng: 21.9228021 },
    { lat: 49.2569839, lng: 21.9225532 },
    { lat: 49.2567199, lng: 21.9221185 },
    { lat: 49.2565543, lng: 21.9221104 },
    { lat: 49.2562526, lng: 21.9218273 },
    { lat: 49.2561254, lng: 21.9210508 },
    { lat: 49.2557082, lng: 21.9209659 },
    { lat: 49.2554189, lng: 21.9209157 },
    { lat: 49.2542274, lng: 21.9234329 },
    { lat: 49.2536181, lng: 21.9244383 },
    { lat: 49.2528194, lng: 21.923754 },
    { lat: 49.2522005, lng: 21.9231116 },
    { lat: 49.2519753, lng: 21.9227844 },
    { lat: 49.2509918, lng: 21.9218093 },
    { lat: 49.250414, lng: 21.9229607 },
    { lat: 49.250235, lng: 21.9236785 },
    { lat: 49.2494577, lng: 21.9231274 },
    { lat: 49.2489635, lng: 21.9234221 },
    { lat: 49.2489179, lng: 21.9232653 },
    { lat: 49.2487978, lng: 21.9231373 },
    { lat: 49.2486904, lng: 21.9225257 },
    { lat: 49.2489487, lng: 21.9208856 },
    { lat: 49.2468946, lng: 21.9216888 },
    { lat: 49.245519, lng: 21.9234081 },
    { lat: 49.2452149, lng: 21.9242501 },
    { lat: 49.2454834, lng: 21.9243281 },
    { lat: 49.2455181, lng: 21.9254961 },
    { lat: 49.2457644, lng: 21.9256294 },
    { lat: 49.2458794, lng: 21.9259644 },
    { lat: 49.2455531, lng: 21.9261849 },
    { lat: 49.2454308, lng: 21.9264631 },
    { lat: 49.2451698, lng: 21.9267331 },
    { lat: 49.2448109, lng: 21.9273644 },
    { lat: 49.2442542, lng: 21.9276368 },
    { lat: 49.2437805, lng: 21.9279914 },
    { lat: 49.2436091, lng: 21.9282447 },
    { lat: 49.242612, lng: 21.9290379 },
    { lat: 49.2425078, lng: 21.9298718 },
    { lat: 49.2422194, lng: 21.9304965 },
    { lat: 49.2421754, lng: 21.93085 },
    { lat: 49.242037, lng: 21.9311122 },
    { lat: 49.2414743, lng: 21.9316044 },
    { lat: 49.2408688, lng: 21.9317751 },
    { lat: 49.2400045, lng: 21.9322473 },
    { lat: 49.2393806, lng: 21.9330368 },
    { lat: 49.2384812, lng: 21.9349583 },
    { lat: 49.237053, lng: 21.9356473 },
    { lat: 49.2370376, lng: 21.9366625 },
    { lat: 49.2366219, lng: 21.9372753 },
    { lat: 49.2359354, lng: 21.9374391 },
    { lat: 49.2365505, lng: 21.9379353 },
    { lat: 49.2370634, lng: 21.9383477 },
    { lat: 49.2372253, lng: 21.9379741 },
    { lat: 49.2392948, lng: 21.9365707 },
    { lat: 49.2400216, lng: 21.9374319 },
    { lat: 49.2399536, lng: 21.9376853 },
    { lat: 49.2406455, lng: 21.93815 },
    { lat: 49.240941, lng: 21.9388226 },
    { lat: 49.2410665, lng: 21.9388942 },
    { lat: 49.2416299, lng: 21.9396141 },
    { lat: 49.2421881, lng: 21.9394937 },
    { lat: 49.2437296, lng: 21.9429798 },
    { lat: 49.244223, lng: 21.943919 },
    { lat: 49.2449677, lng: 21.9455699 },
    { lat: 49.2449778, lng: 21.9465272 },
    { lat: 49.2446735, lng: 21.9468669 },
    { lat: 49.2447831, lng: 21.9473855 },
    { lat: 49.2449306, lng: 21.9489048 },
    { lat: 49.2454319, lng: 21.9514399 },
    { lat: 49.2468349, lng: 21.9524749 },
    { lat: 49.2474116, lng: 21.9527795 },
    { lat: 49.2478033, lng: 21.9531988 },
    { lat: 49.2482192, lng: 21.9539741 },
    { lat: 49.2483381, lng: 21.9548691 },
    { lat: 49.2485366, lng: 21.9553034 },
    { lat: 49.2489486, lng: 21.9557345 },
    { lat: 49.2492934, lng: 21.9558515 },
    { lat: 49.2495691, lng: 21.9561274 },
    { lat: 49.2499143, lng: 21.9563535 },
    { lat: 49.2507985, lng: 21.9577785 },
    { lat: 49.2510964, lng: 21.9579606 },
    { lat: 49.2515764, lng: 21.9580324 },
    { lat: 49.2519046, lng: 21.958671 },
    { lat: 49.2522518, lng: 21.9591156 },
    { lat: 49.2526103, lng: 21.9597428 },
    { lat: 49.2529531, lng: 21.960651 },
    { lat: 49.2528202, lng: 21.9613816 },
    { lat: 49.252505, lng: 21.9626076 },
    { lat: 49.2521822, lng: 21.963161 },
    { lat: 49.2519856, lng: 21.9643597 },
    { lat: 49.2525184, lng: 21.9661395 },
    { lat: 49.2528624, lng: 21.9669995 },
    { lat: 49.2530659, lng: 21.9679664 },
    { lat: 49.2532242, lng: 21.968321 },
    { lat: 49.2536303, lng: 21.9685517 },
    { lat: 49.2542104, lng: 21.9685459 },
    { lat: 49.255073, lng: 21.9689115 },
    { lat: 49.2554447, lng: 21.9695772 },
    { lat: 49.2558551, lng: 21.9706329 },
    { lat: 49.2570408, lng: 21.9727556 },
    { lat: 49.2571784, lng: 21.9738806 },
    { lat: 49.2576672, lng: 21.9745459 },
    { lat: 49.2585004, lng: 21.9758354 },
    { lat: 49.2593917, lng: 21.9797784 },
    { lat: 49.2596883, lng: 21.979715 },
    { lat: 49.2599572, lng: 21.9792583 },
    { lat: 49.2600896, lng: 21.9785503 },
    { lat: 49.2615479, lng: 21.9770104 },
    { lat: 49.2607097, lng: 21.9763105 },
    { lat: 49.2610764, lng: 21.9757081 },
    { lat: 49.2613313, lng: 21.9750457 },
    { lat: 49.2613031, lng: 21.9741726 },
    { lat: 49.261014, lng: 21.9735269 },
    { lat: 49.2608678, lng: 21.9728319 },
    { lat: 49.2606168, lng: 21.9719521 },
    { lat: 49.2607972, lng: 21.9703542 },
    { lat: 49.2613886, lng: 21.968141 },
    { lat: 49.261952, lng: 21.967819 },
    { lat: 49.2622385, lng: 21.9675264 },
    { lat: 49.2626366, lng: 21.9672469 },
    { lat: 49.2633545, lng: 21.9664849 },
    { lat: 49.2642518, lng: 21.9661509 },
    { lat: 49.2645726, lng: 21.9663469 },
    { lat: 49.2648805, lng: 21.96611 },
    { lat: 49.2651, lng: 21.9657938 },
    { lat: 49.2654087, lng: 21.9655785 },
    { lat: 49.265516, lng: 21.9650029 },
    { lat: 49.2658745, lng: 21.964256 },
    { lat: 49.266233, lng: 21.9639669 },
    { lat: 49.2669075, lng: 21.9640036 },
    { lat: 49.2678752, lng: 21.96356 },
    { lat: 49.2682167, lng: 21.9629937 },
    { lat: 49.2686842, lng: 21.9623849 },
    { lat: 49.268671, lng: 21.9617957 },
    { lat: 49.2689779, lng: 21.9612898 },
    { lat: 49.2690933, lng: 21.9608034 },
    { lat: 49.2693069, lng: 21.9605294 },
    { lat: 49.2697002, lng: 21.96024 },
    { lat: 49.2699356, lng: 21.959746 },
    { lat: 49.271288, lng: 21.9582581 },
    { lat: 49.2716478, lng: 21.9575138 },
    { lat: 49.2721305, lng: 21.9571631 },
    { lat: 49.2724899, lng: 21.9566818 },
    { lat: 49.2728313, lng: 21.955832 },
    { lat: 49.2727517, lng: 21.955199 },
    { lat: 49.2730489, lng: 21.9536583 },
    { lat: 49.2734499, lng: 21.9527669 },
    { lat: 49.2737739, lng: 21.9519014 },
    { lat: 49.2741593, lng: 21.9511981 },
    { lat: 49.2750822, lng: 21.9500367 },
    { lat: 49.2769348, lng: 21.9465751 },
    { lat: 49.2767083, lng: 21.9456855 },
    { lat: 49.2753736, lng: 21.9435142 },
    { lat: 49.2750234, lng: 21.9431335 },
    { lat: 49.2748515, lng: 21.9426809 },
    { lat: 49.2739186, lng: 21.9420301 },
    { lat: 49.2731867, lng: 21.9410807 },
    { lat: 49.2730775, lng: 21.9407981 },
    { lat: 49.2730834, lng: 21.939719 },
    { lat: 49.2729706, lng: 21.9394291 },
    { lat: 49.2730691, lng: 21.9391403 },
    { lat: 49.272858, lng: 21.9385628 },
    { lat: 49.2727098, lng: 21.9384392 },
    { lat: 49.2727275, lng: 21.9382894 },
    { lat: 49.2725842, lng: 21.9382706 },
    { lat: 49.2726006, lng: 21.9380637 },
    { lat: 49.2724237, lng: 21.9379665 },
    { lat: 49.2724349, lng: 21.9377775 },
    { lat: 49.2722894, lng: 21.9375388 },
    { lat: 49.2721891, lng: 21.9374823 },
    { lat: 49.271935, lng: 21.9370331 },
    { lat: 49.2719252, lng: 21.9367725 },
    { lat: 49.2717462, lng: 21.9365865 },
    { lat: 49.2717371, lng: 21.9364484 },
    { lat: 49.2718268, lng: 21.9361583 },
    { lat: 49.2714649, lng: 21.9356205 },
    { lat: 49.2715562, lng: 21.9354979 },
    { lat: 49.2714414, lng: 21.9350788 },
    { lat: 49.271173, lng: 21.9345086 },
    { lat: 49.2709561, lng: 21.934421 },
    { lat: 49.2706333, lng: 21.933558 },
    { lat: 49.2705026, lng: 21.9334564 },
    { lat: 49.2704022, lng: 21.9330876 },
    { lat: 49.2703568, lng: 21.9330218 },
    { lat: 49.2702206, lng: 21.9330156 },
    { lat: 49.2699019, lng: 21.9324999 },
    { lat: 49.2697546, lng: 21.9323655 },
    { lat: 49.2696398, lng: 21.9323652 },
    { lat: 49.2696184, lng: 21.9321864 },
    { lat: 49.2695073, lng: 21.9322854 },
    { lat: 49.2693886, lng: 21.9318982 },
    { lat: 49.2692904, lng: 21.9317426 },
    { lat: 49.2691435, lng: 21.9317683 },
    { lat: 49.2689268, lng: 21.9311448 },
    { lat: 49.2688455, lng: 21.9311722 },
    { lat: 49.2686078, lng: 21.9309796 },
    { lat: 49.2685714, lng: 21.9307822 },
    { lat: 49.2681799, lng: 21.9301828 },
    { lat: 49.268058, lng: 21.9297635 },
    { lat: 49.2680486, lng: 21.9294701 },
    { lat: 49.2681008, lng: 21.929264 },
    { lat: 49.2681337, lng: 21.92855 },
    { lat: 49.2680223, lng: 21.9281448 },
    { lat: 49.2681669, lng: 21.928072 },
    { lat: 49.2681106, lng: 21.9278937 },
    { lat: 49.2678409, lng: 21.9277671 },
    { lat: 49.2679893, lng: 21.927439 },
    { lat: 49.2678473, lng: 21.9265688 },
    { lat: 49.2677039, lng: 21.9260542 },
    { lat: 49.2676058, lng: 21.9259932 },
    { lat: 49.2676237, lng: 21.9258125 },
    { lat: 49.2675761, lng: 21.925432 },
    { lat: 49.2676832, lng: 21.9252591 },
    { lat: 49.2676442, lng: 21.9248634 },
    { lat: 49.2675513, lng: 21.92489 },
    { lat: 49.267583, lng: 21.9250283 },
    { lat: 49.2675165, lng: 21.9251585 },
    { lat: 49.2674963, lng: 21.9248885 },
    { lat: 49.2673935, lng: 21.9246503 },
    { lat: 49.2673647, lng: 21.9242572 },
    { lat: 49.2672706, lng: 21.9241298 },
    { lat: 49.2673096, lng: 21.9237569 },
    { lat: 49.267266, lng: 21.9235398 },
    { lat: 49.2671631, lng: 21.9233444 },
    { lat: 49.2672874, lng: 21.9229118 },
    { lat: 49.2670747, lng: 21.9226768 },
    { lat: 49.266946, lng: 21.9222967 },
    { lat: 49.2669467, lng: 21.9219181 },
    { lat: 49.2669041, lng: 21.9217902 },
    { lat: 49.2667682, lng: 21.9218882 },
    { lat: 49.2667057, lng: 21.9217711 },
    { lat: 49.2664257, lng: 21.9207772 },
    { lat: 49.2657779, lng: 21.9216799 },
    { lat: 49.2656607, lng: 21.9212725 },
    { lat: 49.2652561, lng: 21.9205534 },
    { lat: 49.2647115, lng: 21.9197779 },
    { lat: 49.263144, lng: 21.9190252 },
    { lat: 49.2623363, lng: 21.9190617 },
  ],
  BrestovnadLaborcom: [
    { lat: 49.113378, lng: 21.931913 },
    { lat: 49.11159, lng: 21.93246 },
    { lat: 49.1111, lng: 21.934682 },
    { lat: 49.111022, lng: 21.934715 },
    { lat: 49.107404, lng: 21.936258 },
    { lat: 49.106529, lng: 21.936658 },
    { lat: 49.105285, lng: 21.93793 },
    { lat: 49.105365, lng: 21.939726 },
    { lat: 49.106207, lng: 21.941107 },
    { lat: 49.103415, lng: 21.940172 },
    { lat: 49.102943, lng: 21.940143 },
    { lat: 49.102738, lng: 21.940248 },
    { lat: 49.102472, lng: 21.9404 },
    { lat: 49.101565, lng: 21.941571 },
    { lat: 49.100939, lng: 21.945941 },
    { lat: 49.099343, lng: 21.946848 },
    { lat: 49.098861, lng: 21.947255 },
    { lat: 49.099104, lng: 21.948645 },
    { lat: 49.099091, lng: 21.948647 },
    { lat: 49.099121, lng: 21.948993 },
    { lat: 49.099046, lng: 21.949502 },
    { lat: 49.09919, lng: 21.949956 },
    { lat: 49.099469, lng: 21.950477 },
    { lat: 49.099535, lng: 21.950536 },
    { lat: 49.099553, lng: 21.950645 },
    { lat: 49.099534, lng: 21.95066 },
    { lat: 49.099447, lng: 21.950765 },
    { lat: 49.099558, lng: 21.950961 },
    { lat: 49.099764, lng: 21.951253 },
    { lat: 49.099822, lng: 21.951564 },
    { lat: 49.099886, lng: 21.951724 },
    { lat: 49.099891, lng: 21.951907 },
    { lat: 49.100222, lng: 21.952625 },
    { lat: 49.100245, lng: 21.953535 },
    { lat: 49.100284, lng: 21.95484 },
    { lat: 49.100292, lng: 21.956141 },
    { lat: 49.100402, lng: 21.956637 },
    { lat: 49.100694, lng: 21.957106 },
    { lat: 49.100808, lng: 21.958162 },
    { lat: 49.100798, lng: 21.958332 },
    { lat: 49.100596, lng: 21.958477 },
    { lat: 49.100558, lng: 21.959144 },
    { lat: 49.100921, lng: 21.958849 },
    { lat: 49.101011, lng: 21.959294 },
    { lat: 49.101402, lng: 21.96033 },
    { lat: 49.101257, lng: 21.960869 },
    { lat: 49.101381, lng: 21.961065 },
    { lat: 49.101176, lng: 21.961639 },
    { lat: 49.101144, lng: 21.961992 },
    { lat: 49.101391, lng: 21.962217 },
    { lat: 49.101633, lng: 21.962181 },
    { lat: 49.10155, lng: 21.962719 },
    { lat: 49.101718, lng: 21.963008 },
    { lat: 49.102254, lng: 21.963475 },
    { lat: 49.102889, lng: 21.964073 },
    { lat: 49.103164, lng: 21.964071 },
    { lat: 49.103308, lng: 21.964413 },
    { lat: 49.103854, lng: 21.964249 },
    { lat: 49.104159, lng: 21.963996 },
    { lat: 49.104683, lng: 21.963956 },
    { lat: 49.105183, lng: 21.964068 },
    { lat: 49.105745, lng: 21.964187 },
    { lat: 49.105945, lng: 21.964529 },
    { lat: 49.106083, lng: 21.965788 },
    { lat: 49.106093, lng: 21.96589 },
    { lat: 49.106138, lng: 21.966295 },
    { lat: 49.106588, lng: 21.967227 },
    { lat: 49.107682, lng: 21.967249 },
    { lat: 49.108863, lng: 21.968213 },
    { lat: 49.109813, lng: 21.968864 },
    { lat: 49.110345, lng: 21.968888 },
    { lat: 49.110851, lng: 21.968681 },
    { lat: 49.110887, lng: 21.968426 },
    { lat: 49.111319, lng: 21.968239 },
    { lat: 49.111803, lng: 21.968284 },
    { lat: 49.114312, lng: 21.969048 },
    { lat: 49.114842, lng: 21.968875 },
    { lat: 49.11628, lng: 21.969082 },
    { lat: 49.116491, lng: 21.969631 },
    { lat: 49.118043, lng: 21.96879 },
    { lat: 49.119626, lng: 21.967917 },
    { lat: 49.120096, lng: 21.967709 },
    { lat: 49.121375, lng: 21.967551 },
    { lat: 49.122202, lng: 21.966718 },
    { lat: 49.122703, lng: 21.966215 },
    { lat: 49.123008, lng: 21.965879 },
    { lat: 49.123509, lng: 21.965485 },
    { lat: 49.124491, lng: 21.965067 },
    { lat: 49.124911, lng: 21.964994 },
    { lat: 49.1239661, lng: 21.9574395 },
    { lat: 49.1239078, lng: 21.956076 },
    { lat: 49.1236458, lng: 21.9538418 },
    { lat: 49.1237666, lng: 21.9530416 },
    { lat: 49.1243382, lng: 21.9504921 },
    { lat: 49.1247964, lng: 21.9489173 },
    { lat: 49.1249224, lng: 21.9477282 },
    { lat: 49.1247342, lng: 21.9474883 },
    { lat: 49.12442, lng: 21.945971 },
    { lat: 49.1241118, lng: 21.9449257 },
    { lat: 49.1241458, lng: 21.9445277 },
    { lat: 49.124105, lng: 21.9435618 },
    { lat: 49.1238625, lng: 21.9422603 },
    { lat: 49.1238526, lng: 21.9414499 },
    { lat: 49.123534, lng: 21.9403941 },
    { lat: 49.1234576, lng: 21.9398045 },
    { lat: 49.1234701, lng: 21.9388992 },
    { lat: 49.1233392, lng: 21.9385278 },
    { lat: 49.1224173, lng: 21.9375456 },
    { lat: 49.1223292, lng: 21.9370332 },
    { lat: 49.1220785, lng: 21.9366281 },
    { lat: 49.121866, lng: 21.9353192 },
    { lat: 49.1216107, lng: 21.9351379 },
    { lat: 49.1215907, lng: 21.9350079 },
    { lat: 49.121451, lng: 21.9350494 },
    { lat: 49.1209507, lng: 21.9339215 },
    { lat: 49.1206811, lng: 21.9337514 },
    { lat: 49.1201165, lng: 21.9320779 },
    { lat: 49.1190681, lng: 21.9327614 },
    { lat: 49.1185825, lng: 21.933273 },
    { lat: 49.1182358, lng: 21.9333498 },
    { lat: 49.1174446, lng: 21.9304775 },
    { lat: 49.1167518, lng: 21.9326069 },
    { lat: 49.1168611, lng: 21.9339328 },
    { lat: 49.1160923, lng: 21.9318223 },
    { lat: 49.1149036, lng: 21.9326539 },
    { lat: 49.113378, lng: 21.931913 },
  ],
  Palota: [
    { lat: 49.2593917, lng: 21.9797784 },
    { lat: 49.2589126, lng: 21.9800137 },
    { lat: 49.258403, lng: 21.9800082 },
    { lat: 49.2578163, lng: 21.9806627 },
    { lat: 49.2575086, lng: 21.9806413 },
    { lat: 49.2572642, lng: 21.981317 },
    { lat: 49.2553216, lng: 21.983811 },
    { lat: 49.2544119, lng: 21.9845093 },
    { lat: 49.253774, lng: 21.9845455 },
    { lat: 49.2530488, lng: 21.9848344 },
    { lat: 49.2522306, lng: 21.9847703 },
    { lat: 49.2516912, lng: 21.984464 },
    { lat: 49.2511668, lng: 21.9843573 },
    { lat: 49.2507895, lng: 21.984522 },
    { lat: 49.2503279, lng: 21.9848276 },
    { lat: 49.2500015, lng: 21.9853206 },
    { lat: 49.2496659, lng: 21.9855848 },
    { lat: 49.2494071, lng: 21.9859988 },
    { lat: 49.2490746, lng: 21.9862938 },
    { lat: 49.2489336, lng: 21.9867363 },
    { lat: 49.2487502, lng: 21.9879877 },
    { lat: 49.2479882, lng: 21.9887923 },
    { lat: 49.2477968, lng: 21.9897115 },
    { lat: 49.2460922, lng: 21.9923298 },
    { lat: 49.2443749, lng: 21.9947921 },
    { lat: 49.2436122, lng: 21.9952474 },
    { lat: 49.243325, lng: 21.9952798 },
    { lat: 49.243053, lng: 21.9957948 },
    { lat: 49.2425496, lng: 21.9962337 },
    { lat: 49.24204, lng: 21.9988294 },
    { lat: 49.2421023, lng: 22.0002934 },
    { lat: 49.2419907, lng: 22.0007489 },
    { lat: 49.2418501, lng: 22.0011618 },
    { lat: 49.2409117, lng: 22.0021712 },
    { lat: 49.2406742, lng: 22.0034188 },
    { lat: 49.2406592, lng: 22.003911 },
    { lat: 49.2400441, lng: 22.0053678 },
    { lat: 49.2387248, lng: 22.0066188 },
    { lat: 49.2379766, lng: 22.0067013 },
    { lat: 49.2391093, lng: 22.0079214 },
    { lat: 49.2389549, lng: 22.0091585 },
    { lat: 49.2388655, lng: 22.010311 },
    { lat: 49.2386094, lng: 22.0113999 },
    { lat: 49.2385936, lng: 22.0128855 },
    { lat: 49.2389777, lng: 22.0141262 },
    { lat: 49.2391436, lng: 22.0159288 },
    { lat: 49.2394873, lng: 22.0174022 },
    { lat: 49.2398773, lng: 22.0194946 },
    { lat: 49.2400931, lng: 22.0212182 },
    { lat: 49.2407689, lng: 22.0223429 },
    { lat: 49.2415679, lng: 22.0231581 },
    { lat: 49.2420766, lng: 22.0239694 },
    { lat: 49.2420639, lng: 22.0256392 },
    { lat: 49.2421264, lng: 22.0276396 },
    { lat: 49.2421061, lng: 22.0301284 },
    { lat: 49.2420177, lng: 22.0312301 },
    { lat: 49.2416088, lng: 22.0326096 },
    { lat: 49.2417508, lng: 22.0333672 },
    { lat: 49.2417751, lng: 22.0341925 },
    { lat: 49.241972, lng: 22.0347619 },
    { lat: 49.2419975, lng: 22.0348167 },
    { lat: 49.2421377, lng: 22.035118 },
    { lat: 49.2422576, lng: 22.0353756 },
    { lat: 49.2424671, lng: 22.0362766 },
    { lat: 49.2427223, lng: 22.0367617 },
    { lat: 49.2435199, lng: 22.0374965 },
    { lat: 49.244043, lng: 22.0379468 },
    { lat: 49.2443563, lng: 22.0384737 },
    { lat: 49.2450188, lng: 22.0389694 },
    { lat: 49.245642, lng: 22.038874 },
    { lat: 49.246143, lng: 22.03826 },
    { lat: 49.246637, lng: 22.03769 },
    { lat: 49.247354, lng: 22.037518 },
    { lat: 49.247902, lng: 22.037919 },
    { lat: 49.248903, lng: 22.038084 },
    { lat: 49.249245, lng: 22.038212 },
    { lat: 49.2497508, lng: 22.03817 },
    { lat: 49.250161, lng: 22.038136 },
    { lat: 49.2507111, lng: 22.0384946 },
    { lat: 49.2507786, lng: 22.0384488 },
    { lat: 49.2508204, lng: 22.0384205 },
    { lat: 49.2508604, lng: 22.0384648 },
    { lat: 49.251288, lng: 22.0389205 },
    { lat: 49.251698, lng: 22.039263 },
    { lat: 49.252094, lng: 22.039589 },
    { lat: 49.25287, lng: 22.0401708 },
    { lat: 49.2531485, lng: 22.0404193 },
    { lat: 49.253545, lng: 22.040686 },
    { lat: 49.254085, lng: 22.041088 },
    { lat: 49.254377, lng: 22.041267 },
    { lat: 49.254453, lng: 22.041349 },
    { lat: 49.254549, lng: 22.041394 },
    { lat: 49.254645, lng: 22.041384 },
    { lat: 49.255121, lng: 22.041384 },
    { lat: 49.255303, lng: 22.041354 },
    { lat: 49.255477, lng: 22.041445 },
    { lat: 49.255648, lng: 22.041636 },
    { lat: 49.255882, lng: 22.041783 },
    { lat: 49.2562301, lng: 22.0418195 },
    { lat: 49.256857, lng: 22.041843 },
    { lat: 49.257161, lng: 22.041885 },
    { lat: 49.257244, lng: 22.04188 },
    { lat: 49.257332, lng: 22.041831 },
    { lat: 49.2579315, lng: 22.0413091 },
    { lat: 49.2584, lng: 22.040772 },
    { lat: 49.258659, lng: 22.039949 },
    { lat: 49.258747, lng: 22.039405 },
    { lat: 49.259069, lng: 22.038511 },
    { lat: 49.25926, lng: 22.037821 },
    { lat: 49.259689, lng: 22.037508 },
    { lat: 49.260001, lng: 22.036824 },
    { lat: 49.260118, lng: 22.035828 },
    { lat: 49.260138, lng: 22.035803 },
    { lat: 49.260155, lng: 22.035775 },
    { lat: 49.260651, lng: 22.035367 },
    { lat: 49.260673, lng: 22.035317 },
    { lat: 49.260841, lng: 22.034372 },
    { lat: 49.261031, lng: 22.033626 },
    { lat: 49.261485, lng: 22.032255 },
    { lat: 49.262023, lng: 22.031167 },
    { lat: 49.262671, lng: 22.030318 },
    { lat: 49.263223, lng: 22.031251 },
    { lat: 49.263696, lng: 22.031808 },
    { lat: 49.264186, lng: 22.032427 },
    { lat: 49.264716, lng: 22.033063 },
    { lat: 49.265268, lng: 22.033326 },
    { lat: 49.265712, lng: 22.033375 },
    { lat: 49.266052, lng: 22.033337 },
    { lat: 49.266501, lng: 22.033266 },
    { lat: 49.266908, lng: 22.033371 },
    { lat: 49.267381, lng: 22.033508 },
    { lat: 49.267921, lng: 22.033489 },
    { lat: 49.268352, lng: 22.033515 },
    { lat: 49.268762, lng: 22.033396 },
    { lat: 49.26925, lng: 22.033271 },
    { lat: 49.269707, lng: 22.033389 },
    { lat: 49.270313, lng: 22.033868 },
    { lat: 49.270942, lng: 22.033997 },
    { lat: 49.271533, lng: 22.034134 },
    { lat: 49.272008, lng: 22.034176 },
    { lat: 49.272055, lng: 22.034204 },
    { lat: 49.272597, lng: 22.034395 },
    { lat: 49.273045, lng: 22.034629 },
    { lat: 49.2736799, lng: 22.0345612 },
    { lat: 49.274284, lng: 22.034532 },
    { lat: 49.274575, lng: 22.034605 },
    { lat: 49.274937, lng: 22.034716 },
    { lat: 49.275264, lng: 22.034876 },
    { lat: 49.2756363, lng: 22.0350041 },
    { lat: 49.276082, lng: 22.034933 },
    { lat: 49.276616, lng: 22.034798 },
    { lat: 49.277085, lng: 22.034587 },
    { lat: 49.277214, lng: 22.034521 },
    { lat: 49.277638, lng: 22.034429 },
    { lat: 49.277962, lng: 22.034263 },
    { lat: 49.278546, lng: 22.03402 },
    { lat: 49.279103, lng: 22.033559 },
    { lat: 49.279564, lng: 22.032914 },
    { lat: 49.280223, lng: 22.032307 },
    { lat: 49.280513, lng: 22.032065 },
    { lat: 49.280934, lng: 22.031601 },
    { lat: 49.281442, lng: 22.030998 },
    { lat: 49.281808, lng: 22.03044 },
    { lat: 49.282179, lng: 22.030014 },
    { lat: 49.282562, lng: 22.029444 },
    { lat: 49.282978, lng: 22.028538 },
    { lat: 49.283443, lng: 22.027632 },
    { lat: 49.283479, lng: 22.027567 },
    { lat: 49.283661, lng: 22.02706 },
    { lat: 49.283994, lng: 22.026106 },
    { lat: 49.284213, lng: 22.025745 },
    { lat: 49.284764, lng: 22.024845 },
    { lat: 49.285427, lng: 22.024318 },
    { lat: 49.285811, lng: 22.023919 },
    { lat: 49.286135, lng: 22.023379 },
    { lat: 49.286252, lng: 22.022811 },
    { lat: 49.286352, lng: 22.022156 },
    { lat: 49.286495, lng: 22.02087 },
    { lat: 49.286768, lng: 22.019916 },
    { lat: 49.287218, lng: 22.018839 },
    { lat: 49.287409, lng: 22.018526 },
    { lat: 49.287841, lng: 22.017766 },
    { lat: 49.28774, lng: 22.017117 },
    { lat: 49.28776, lng: 22.016635 },
    { lat: 49.287794, lng: 22.016521 },
    { lat: 49.2878433, lng: 22.0164325 },
    { lat: 49.2879216, lng: 22.0162954 },
    { lat: 49.288321, lng: 22.015385 },
    { lat: 49.288584, lng: 22.01285 },
    { lat: 49.2895274, lng: 22.0106878 },
    { lat: 49.2907666, lng: 22.0094152 },
    { lat: 49.2914952, lng: 22.0085228 },
    { lat: 49.2925492, lng: 22.0068374 },
    { lat: 49.2935675, lng: 22.0056978 },
    { lat: 49.2945676, lng: 22.0047789 },
    { lat: 49.295102, lng: 22.004245 },
    { lat: 49.295598, lng: 22.0038065 },
    { lat: 49.2960027, lng: 22.0032661 },
    { lat: 49.2964273, lng: 22.0029776 },
    { lat: 49.2965795, lng: 22.0023857 },
    { lat: 49.2971677, lng: 22.0014514 },
    { lat: 49.2975641, lng: 22.0015466 },
    { lat: 49.297891, lng: 22.0014783 },
    { lat: 49.2982229, lng: 22.0013864 },
    { lat: 49.2988425, lng: 22.0013212 },
    { lat: 49.2993239, lng: 22.0007276 },
    { lat: 49.29983, lng: 21.9998937 },
    { lat: 49.3002762, lng: 21.9989038 },
    { lat: 49.3009016, lng: 21.9977294 },
    { lat: 49.3002229, lng: 21.9969843 },
    { lat: 49.2998601, lng: 21.996483 },
    { lat: 49.2993735, lng: 21.9960381 },
    { lat: 49.2941208, lng: 21.9865504 },
    { lat: 49.2934594, lng: 21.9856759 },
    { lat: 49.2927399, lng: 21.9845324 },
    { lat: 49.2921081, lng: 21.9836624 },
    { lat: 49.2908964, lng: 21.9823012 },
    { lat: 49.2905736, lng: 21.9818396 },
    { lat: 49.2878071, lng: 21.9784725 },
    { lat: 49.2855059, lng: 21.9752929 },
    { lat: 49.2847007, lng: 21.9746503 },
    { lat: 49.2839563, lng: 21.9733609 },
    { lat: 49.2824158, lng: 21.972177 },
    { lat: 49.2812644, lng: 21.9731095 },
    { lat: 49.2796192, lng: 21.9714143 },
    { lat: 49.2782257, lng: 21.9670487 },
    { lat: 49.2776123, lng: 21.9655607 },
    { lat: 49.2768576, lng: 21.9653276 },
    { lat: 49.2765452, lng: 21.965339 },
    { lat: 49.2764265, lng: 21.9659131 },
    { lat: 49.2757673, lng: 21.9664497 },
    { lat: 49.2751566, lng: 21.967058 },
    { lat: 49.2749317, lng: 21.9664582 },
    { lat: 49.2746337, lng: 21.966048 },
    { lat: 49.274063, lng: 21.9664383 },
    { lat: 49.2740342, lng: 21.9665505 },
    { lat: 49.2739123, lng: 21.9663958 },
    { lat: 49.273767, lng: 21.9650036 },
    { lat: 49.273688, lng: 21.9647701 },
    { lat: 49.2682167, lng: 21.9629937 },
    { lat: 49.2678752, lng: 21.96356 },
    { lat: 49.2669075, lng: 21.9640036 },
    { lat: 49.266233, lng: 21.9639669 },
    { lat: 49.2658745, lng: 21.964256 },
    { lat: 49.265516, lng: 21.9650029 },
    { lat: 49.2654087, lng: 21.9655785 },
    { lat: 49.2651, lng: 21.9657938 },
    { lat: 49.2648805, lng: 21.96611 },
    { lat: 49.2645726, lng: 21.9663469 },
    { lat: 49.2642518, lng: 21.9661509 },
    { lat: 49.2633545, lng: 21.9664849 },
    { lat: 49.2626366, lng: 21.9672469 },
    { lat: 49.2622385, lng: 21.9675264 },
    { lat: 49.261952, lng: 21.967819 },
    { lat: 49.2613886, lng: 21.968141 },
    { lat: 49.2607972, lng: 21.9703542 },
    { lat: 49.2606168, lng: 21.9719521 },
    { lat: 49.2608678, lng: 21.9728319 },
    { lat: 49.261014, lng: 21.9735269 },
    { lat: 49.2613031, lng: 21.9741726 },
    { lat: 49.2613313, lng: 21.9750457 },
    { lat: 49.2610764, lng: 21.9757081 },
    { lat: 49.2607097, lng: 21.9763105 },
    { lat: 49.2615479, lng: 21.9770104 },
    { lat: 49.2600896, lng: 21.9785503 },
    { lat: 49.2599572, lng: 21.9792583 },
    { lat: 49.2596883, lng: 21.979715 },
    { lat: 49.2593917, lng: 21.9797784 },
  ],
  Valentovce: [
    { lat: 49.18106, lng: 21.9433228 },
    { lat: 49.1806635, lng: 21.9432536 },
    { lat: 49.1801406, lng: 21.9430402 },
    { lat: 49.1796168, lng: 21.9430463 },
    { lat: 49.1788577, lng: 21.9427811 },
    { lat: 49.1781076, lng: 21.9427031 },
    { lat: 49.1776917, lng: 21.9427533 },
    { lat: 49.1770968, lng: 21.943078 },
    { lat: 49.1770572, lng: 21.9439377 },
    { lat: 49.1769776, lng: 21.9438991 },
    { lat: 49.1769191, lng: 21.9443483 },
    { lat: 49.1764179, lng: 21.9441434 },
    { lat: 49.1764211, lng: 21.9442733 },
    { lat: 49.1762148, lng: 21.944214 },
    { lat: 49.1761674, lng: 21.9445173 },
    { lat: 49.1752108, lng: 21.9443605 },
    { lat: 49.1752152, lng: 21.9459496 },
    { lat: 49.1750184, lng: 21.9465059 },
    { lat: 49.17482, lng: 21.9463447 },
    { lat: 49.1747634, lng: 21.9465159 },
    { lat: 49.1745918, lng: 21.9464855 },
    { lat: 49.174422, lng: 21.9470815 },
    { lat: 49.1734704, lng: 21.9462591 },
    { lat: 49.173061, lng: 21.9470781 },
    { lat: 49.1730827, lng: 21.9473206 },
    { lat: 49.1733263, lng: 21.9476186 },
    { lat: 49.173256, lng: 21.9477409 },
    { lat: 49.1731216, lng: 21.9477094 },
    { lat: 49.1730842, lng: 21.9477903 },
    { lat: 49.1731808, lng: 21.9479728 },
    { lat: 49.172984, lng: 21.9484305 },
    { lat: 49.1730604, lng: 21.949015 },
    { lat: 49.1729432, lng: 21.9493747 },
    { lat: 49.1729147, lng: 21.9499957 },
    { lat: 49.1728126, lng: 21.9505965 },
    { lat: 49.1728129, lng: 21.9512528 },
    { lat: 49.1727083, lng: 21.9517995 },
    { lat: 49.1728265, lng: 21.9523963 },
    { lat: 49.1728246, lng: 21.953322 },
    { lat: 49.1726523, lng: 21.9537251 },
    { lat: 49.1725468, lng: 21.9543319 },
    { lat: 49.1725763, lng: 21.9553967 },
    { lat: 49.172776, lng: 21.9560607 },
    { lat: 49.1726829, lng: 21.9567113 },
    { lat: 49.1732707, lng: 21.9570087 },
    { lat: 49.1738774, lng: 21.9575729 },
    { lat: 49.1739629, lng: 21.9571215 },
    { lat: 49.1742453, lng: 21.9568451 },
    { lat: 49.1746967, lng: 21.9570943 },
    { lat: 49.1750609, lng: 21.9574516 },
    { lat: 49.1758176, lng: 21.9574389 },
    { lat: 49.1756768, lng: 21.9567919 },
    { lat: 49.1759983, lng: 21.9565514 },
    { lat: 49.1764819, lng: 21.9564564 },
    { lat: 49.176975, lng: 21.956206 },
    { lat: 49.1773648, lng: 21.9562254 },
    { lat: 49.1774795, lng: 21.9569605 },
    { lat: 49.1779222, lng: 21.9571724 },
    { lat: 49.1779317, lng: 21.9576914 },
    { lat: 49.1780334, lng: 21.9583125 },
    { lat: 49.1783013, lng: 21.9587764 },
    { lat: 49.178396, lng: 21.959237 },
    { lat: 49.1785181, lng: 21.9593605 },
    { lat: 49.1784282, lng: 21.9599998 },
    { lat: 49.177918, lng: 21.9603874 },
    { lat: 49.177855, lng: 21.9608939 },
    { lat: 49.1778792, lng: 21.960958 },
    { lat: 49.177927, lng: 21.9608895 },
    { lat: 49.1779842, lng: 21.9613449 },
    { lat: 49.1783602, lng: 21.9620381 },
    { lat: 49.1789331, lng: 21.961693 },
    { lat: 49.1791128, lng: 21.9622712 },
    { lat: 49.1792817, lng: 21.9621023 },
    { lat: 49.1793198, lng: 21.9621408 },
    { lat: 49.1795916, lng: 21.9620735 },
    { lat: 49.1799488, lng: 21.9624248 },
    { lat: 49.1801226, lng: 21.9629545 },
    { lat: 49.180225, lng: 21.9638719 },
    { lat: 49.1799712, lng: 21.9649423 },
    { lat: 49.1795758, lng: 21.9655124 },
    { lat: 49.1795393, lng: 21.9659589 },
    { lat: 49.179767, lng: 21.9665247 },
    { lat: 49.1797456, lng: 21.9667985 },
    { lat: 49.1798169, lng: 21.966954 },
    { lat: 49.1797784, lng: 21.9673542 },
    { lat: 49.1799753, lng: 21.9675411 },
    { lat: 49.180014, lng: 21.9676978 },
    { lat: 49.1803377, lng: 21.9678274 },
    { lat: 49.1809294, lng: 21.9675028 },
    { lat: 49.1813004, lng: 21.9675047 },
    { lat: 49.1825724, lng: 21.9676777 },
    { lat: 49.1832203, lng: 21.9678683 },
    { lat: 49.1835389, lng: 21.9681051 },
    { lat: 49.1838703, lng: 21.9681829 },
    { lat: 49.1843634, lng: 21.9686168 },
    { lat: 49.184775, lng: 21.9687954 },
    { lat: 49.1853766, lng: 21.9693393 },
    { lat: 49.1857933, lng: 21.9694529 },
    { lat: 49.1862789, lng: 21.9698991 },
    { lat: 49.1866225, lng: 21.9700136 },
    { lat: 49.187753, lng: 21.971079 },
    { lat: 49.1883756, lng: 21.9721161 },
    { lat: 49.1888605, lng: 21.9712503 },
    { lat: 49.1890329, lng: 21.9711679 },
    { lat: 49.1892293, lng: 21.9707363 },
    { lat: 49.1894509, lng: 21.9707891 },
    { lat: 49.1896262, lng: 21.9703135 },
    { lat: 49.189964, lng: 21.9696894 },
    { lat: 49.1902374, lng: 21.96969 },
    { lat: 49.1904201, lng: 21.9694415 },
    { lat: 49.1903388, lng: 21.9689208 },
    { lat: 49.1900273, lng: 21.9680018 },
    { lat: 49.1898373, lng: 21.9668558 },
    { lat: 49.1895757, lng: 21.9666917 },
    { lat: 49.1893502, lng: 21.9657502 },
    { lat: 49.1893629, lng: 21.9648459 },
    { lat: 49.1891515, lng: 21.9639802 },
    { lat: 49.1891535, lng: 21.9629606 },
    { lat: 49.1889883, lng: 21.9621412 },
    { lat: 49.188957, lng: 21.9600898 },
    { lat: 49.1887922, lng: 21.9599716 },
    { lat: 49.1887126, lng: 21.9597543 },
    { lat: 49.1883124, lng: 21.9597171 },
    { lat: 49.1880606, lng: 21.9592622 },
    { lat: 49.1877381, lng: 21.959184 },
    { lat: 49.1876597, lng: 21.9593402 },
    { lat: 49.187477, lng: 21.9591356 },
    { lat: 49.1872587, lng: 21.9591718 },
    { lat: 49.1871964, lng: 21.9590661 },
    { lat: 49.1872097, lng: 21.9588766 },
    { lat: 49.1870291, lng: 21.9586654 },
    { lat: 49.1869516, lng: 21.9587133 },
    { lat: 49.1869369, lng: 21.9588041 },
    { lat: 49.1867872, lng: 21.9586145 },
    { lat: 49.1865165, lng: 21.9584438 },
    { lat: 49.1865008, lng: 21.9582656 },
    { lat: 49.1863829, lng: 21.9580048 },
    { lat: 49.1863132, lng: 21.9579577 },
    { lat: 49.1862016, lng: 21.95804 },
    { lat: 49.1860561, lng: 21.9576564 },
    { lat: 49.1858951, lng: 21.9575117 },
    { lat: 49.1858103, lng: 21.9575885 },
    { lat: 49.1857307, lng: 21.9569983 },
    { lat: 49.1853669, lng: 21.9564759 },
    { lat: 49.1853392, lng: 21.9562668 },
    { lat: 49.1850165, lng: 21.9561317 },
    { lat: 49.1848788, lng: 21.9559785 },
    { lat: 49.1850267, lng: 21.9555893 },
    { lat: 49.1851376, lng: 21.9547405 },
    { lat: 49.185478, lng: 21.9540446 },
    { lat: 49.1852967, lng: 21.9538982 },
    { lat: 49.1854401, lng: 21.9534632 },
    { lat: 49.1857288, lng: 21.9536822 },
    { lat: 49.1859686, lng: 21.953141 },
    { lat: 49.1864232, lng: 21.9523246 },
    { lat: 49.1869183, lng: 21.9516881 },
    { lat: 49.1874757, lng: 21.9522631 },
    { lat: 49.1881401, lng: 21.9513793 },
    { lat: 49.1877897, lng: 21.9511815 },
    { lat: 49.1876153, lng: 21.9510416 },
    { lat: 49.1873489, lng: 21.9506829 },
    { lat: 49.1869212, lng: 21.9498455 },
    { lat: 49.1867111, lng: 21.9497177 },
    { lat: 49.1863203, lng: 21.948874 },
    { lat: 49.1852749, lng: 21.9475792 },
    { lat: 49.18106, lng: 21.9433228 },
  ],
  ZbudskáBelá: [
    { lat: 49.1997793, lng: 21.9684712 },
    { lat: 49.1987724, lng: 21.965846 },
    { lat: 49.1987016, lng: 21.965849 },
    { lat: 49.1985167, lng: 21.9641318 },
    { lat: 49.1985342, lng: 21.9633751 },
    { lat: 49.1984821, lng: 21.96304 },
    { lat: 49.1985534, lng: 21.9625537 },
    { lat: 49.1983862, lng: 21.9617928 },
    { lat: 49.198253, lng: 21.9616854 },
    { lat: 49.1980622, lng: 21.9611503 },
    { lat: 49.1974077, lng: 21.9614808 },
    { lat: 49.1969928, lng: 21.9609306 },
    { lat: 49.1964042, lng: 21.9603687 },
    { lat: 49.1962659, lng: 21.9601385 },
    { lat: 49.1957304, lng: 21.9582613 },
    { lat: 49.1954224, lng: 21.957483 },
    { lat: 49.195245, lng: 21.9573163 },
    { lat: 49.1951259, lng: 21.9573702 },
    { lat: 49.1949171, lng: 21.9568798 },
    { lat: 49.1946097, lng: 21.9564575 },
    { lat: 49.1947515, lng: 21.9562714 },
    { lat: 49.1942042, lng: 21.9560018 },
    { lat: 49.1940057, lng: 21.9559041 },
    { lat: 49.1937863, lng: 21.9556758 },
    { lat: 49.1934977, lng: 21.9552702 },
    { lat: 49.193232, lng: 21.9548968 },
    { lat: 49.19305, lng: 21.9546409 },
    { lat: 49.1928957, lng: 21.9545408 },
    { lat: 49.1922017, lng: 21.9543515 },
    { lat: 49.1910497, lng: 21.9542423 },
    { lat: 49.190887, lng: 21.9542269 },
    { lat: 49.1906417, lng: 21.9539892 },
    { lat: 49.1904677, lng: 21.9537288 },
    { lat: 49.1900652, lng: 21.9525936 },
    { lat: 49.1897698, lng: 21.9519798 },
    { lat: 49.189498, lng: 21.9523593 },
    { lat: 49.1893203, lng: 21.9521279 },
    { lat: 49.1888414, lng: 21.9517763 },
    { lat: 49.1881401, lng: 21.9513793 },
    { lat: 49.1874757, lng: 21.9522631 },
    { lat: 49.1869183, lng: 21.9516881 },
    { lat: 49.1864232, lng: 21.9523246 },
    { lat: 49.1859686, lng: 21.953141 },
    { lat: 49.1857288, lng: 21.9536822 },
    { lat: 49.1854401, lng: 21.9534632 },
    { lat: 49.1852967, lng: 21.9538982 },
    { lat: 49.185478, lng: 21.9540446 },
    { lat: 49.1851376, lng: 21.9547405 },
    { lat: 49.1850267, lng: 21.9555893 },
    { lat: 49.1848788, lng: 21.9559785 },
    { lat: 49.1850165, lng: 21.9561317 },
    { lat: 49.1853392, lng: 21.9562668 },
    { lat: 49.1853669, lng: 21.9564759 },
    { lat: 49.1857307, lng: 21.9569983 },
    { lat: 49.1858103, lng: 21.9575885 },
    { lat: 49.1858951, lng: 21.9575117 },
    { lat: 49.1860561, lng: 21.9576564 },
    { lat: 49.1862016, lng: 21.95804 },
    { lat: 49.1863132, lng: 21.9579577 },
    { lat: 49.1863829, lng: 21.9580048 },
    { lat: 49.1865008, lng: 21.9582656 },
    { lat: 49.1865165, lng: 21.9584438 },
    { lat: 49.1867872, lng: 21.9586145 },
    { lat: 49.1869369, lng: 21.9588041 },
    { lat: 49.1869516, lng: 21.9587133 },
    { lat: 49.1870291, lng: 21.9586654 },
    { lat: 49.1872097, lng: 21.9588766 },
    { lat: 49.1871964, lng: 21.9590661 },
    { lat: 49.1872587, lng: 21.9591718 },
    { lat: 49.187477, lng: 21.9591356 },
    { lat: 49.1876597, lng: 21.9593402 },
    { lat: 49.1877381, lng: 21.959184 },
    { lat: 49.1880606, lng: 21.9592622 },
    { lat: 49.1883124, lng: 21.9597171 },
    { lat: 49.1887126, lng: 21.9597543 },
    { lat: 49.1887922, lng: 21.9599716 },
    { lat: 49.188957, lng: 21.9600898 },
    { lat: 49.1889883, lng: 21.9621412 },
    { lat: 49.1891535, lng: 21.9629606 },
    { lat: 49.1891515, lng: 21.9639802 },
    { lat: 49.1893629, lng: 21.9648459 },
    { lat: 49.1893502, lng: 21.9657502 },
    { lat: 49.1895757, lng: 21.9666917 },
    { lat: 49.1898373, lng: 21.9668558 },
    { lat: 49.1900273, lng: 21.9680018 },
    { lat: 49.1903388, lng: 21.9689208 },
    { lat: 49.1904201, lng: 21.9694415 },
    { lat: 49.1902374, lng: 21.96969 },
    { lat: 49.189964, lng: 21.9696894 },
    { lat: 49.1896262, lng: 21.9703135 },
    { lat: 49.1894509, lng: 21.9707891 },
    { lat: 49.1892293, lng: 21.9707363 },
    { lat: 49.1890329, lng: 21.9711679 },
    { lat: 49.1888605, lng: 21.9712503 },
    { lat: 49.1883756, lng: 21.9721161 },
    { lat: 49.187753, lng: 21.971079 },
    { lat: 49.1866225, lng: 21.9700136 },
    { lat: 49.1862789, lng: 21.9698991 },
    { lat: 49.1857933, lng: 21.9694529 },
    { lat: 49.1853766, lng: 21.9693393 },
    { lat: 49.184775, lng: 21.9687954 },
    { lat: 49.1843634, lng: 21.9686168 },
    { lat: 49.1838703, lng: 21.9681829 },
    { lat: 49.1835389, lng: 21.9681051 },
    { lat: 49.1832203, lng: 21.9678683 },
    { lat: 49.1825724, lng: 21.9676777 },
    { lat: 49.1813004, lng: 21.9675047 },
    { lat: 49.1809294, lng: 21.9675028 },
    { lat: 49.1803377, lng: 21.9678274 },
    { lat: 49.180014, lng: 21.9676978 },
    { lat: 49.1799753, lng: 21.9675411 },
    { lat: 49.1797784, lng: 21.9673542 },
    { lat: 49.1798169, lng: 21.966954 },
    { lat: 49.1797456, lng: 21.9667985 },
    { lat: 49.179767, lng: 21.9665247 },
    { lat: 49.1795393, lng: 21.9659589 },
    { lat: 49.1795758, lng: 21.9655124 },
    { lat: 49.1799712, lng: 21.9649423 },
    { lat: 49.180225, lng: 21.9638719 },
    { lat: 49.1801226, lng: 21.9629545 },
    { lat: 49.1799488, lng: 21.9624248 },
    { lat: 49.1795916, lng: 21.9620735 },
    { lat: 49.1793198, lng: 21.9621408 },
    { lat: 49.1792817, lng: 21.9621023 },
    { lat: 49.1791128, lng: 21.9622712 },
    { lat: 49.1789331, lng: 21.961693 },
    { lat: 49.1783602, lng: 21.9620381 },
    { lat: 49.1779842, lng: 21.9613449 },
    { lat: 49.177927, lng: 21.9608895 },
    { lat: 49.1778792, lng: 21.960958 },
    { lat: 49.177855, lng: 21.9608939 },
    { lat: 49.177918, lng: 21.9603874 },
    { lat: 49.1784282, lng: 21.9599998 },
    { lat: 49.1785181, lng: 21.9593605 },
    { lat: 49.178396, lng: 21.959237 },
    { lat: 49.1783013, lng: 21.9587764 },
    { lat: 49.1780334, lng: 21.9583125 },
    { lat: 49.1779317, lng: 21.9576914 },
    { lat: 49.1779222, lng: 21.9571724 },
    { lat: 49.1774795, lng: 21.9569605 },
    { lat: 49.1773648, lng: 21.9562254 },
    { lat: 49.176975, lng: 21.956206 },
    { lat: 49.1764819, lng: 21.9564564 },
    { lat: 49.1759983, lng: 21.9565514 },
    { lat: 49.1756768, lng: 21.9567919 },
    { lat: 49.1758176, lng: 21.9574389 },
    { lat: 49.1750609, lng: 21.9574516 },
    { lat: 49.1746967, lng: 21.9570943 },
    { lat: 49.1742453, lng: 21.9568451 },
    { lat: 49.1739629, lng: 21.9571215 },
    { lat: 49.1738774, lng: 21.9575729 },
    { lat: 49.1732707, lng: 21.9570087 },
    { lat: 49.1726829, lng: 21.9567113 },
    { lat: 49.172776, lng: 21.9560607 },
    { lat: 49.1725763, lng: 21.9553967 },
    { lat: 49.1725468, lng: 21.9543319 },
    { lat: 49.1726523, lng: 21.9537251 },
    { lat: 49.1728246, lng: 21.953322 },
    { lat: 49.1728265, lng: 21.9523963 },
    { lat: 49.1727083, lng: 21.9517995 },
    { lat: 49.1728129, lng: 21.9512528 },
    { lat: 49.1728126, lng: 21.9505965 },
    { lat: 49.1729147, lng: 21.9499957 },
    { lat: 49.1729432, lng: 21.9493747 },
    { lat: 49.1730604, lng: 21.949015 },
    { lat: 49.172984, lng: 21.9484305 },
    { lat: 49.1731808, lng: 21.9479728 },
    { lat: 49.1730842, lng: 21.9477903 },
    { lat: 49.1731216, lng: 21.9477094 },
    { lat: 49.173256, lng: 21.9477409 },
    { lat: 49.1733263, lng: 21.9476186 },
    { lat: 49.1730827, lng: 21.9473206 },
    { lat: 49.173061, lng: 21.9470781 },
    { lat: 49.1734704, lng: 21.9462591 },
    { lat: 49.174422, lng: 21.9470815 },
    { lat: 49.1745918, lng: 21.9464855 },
    { lat: 49.1747634, lng: 21.9465159 },
    { lat: 49.17482, lng: 21.9463447 },
    { lat: 49.1750184, lng: 21.9465059 },
    { lat: 49.1752152, lng: 21.9459496 },
    { lat: 49.1752108, lng: 21.9443605 },
    { lat: 49.1761674, lng: 21.9445173 },
    { lat: 49.1762148, lng: 21.944214 },
    { lat: 49.1764211, lng: 21.9442733 },
    { lat: 49.1764179, lng: 21.9441434 },
    { lat: 49.1769191, lng: 21.9443483 },
    { lat: 49.1769776, lng: 21.9438991 },
    { lat: 49.1770572, lng: 21.9439377 },
    { lat: 49.1770968, lng: 21.943078 },
    { lat: 49.1776917, lng: 21.9427533 },
    { lat: 49.1781076, lng: 21.9427031 },
    { lat: 49.1788577, lng: 21.9427811 },
    { lat: 49.1796168, lng: 21.9430463 },
    { lat: 49.1801406, lng: 21.9430402 },
    { lat: 49.1806635, lng: 21.9432536 },
    { lat: 49.18106, lng: 21.9433228 },
    { lat: 49.1810354, lng: 21.9432897 },
    { lat: 49.1808433, lng: 21.9430235 },
    { lat: 49.1807736, lng: 21.9427105 },
    { lat: 49.1807399, lng: 21.9422062 },
    { lat: 49.1798727, lng: 21.9396211 },
    { lat: 49.1794852, lng: 21.9383251 },
    { lat: 49.1788547, lng: 21.9366566 },
    { lat: 49.1775255, lng: 21.9352197 },
    { lat: 49.1769485, lng: 21.9349912 },
    { lat: 49.1739504, lng: 21.9340188 },
    { lat: 49.1737597, lng: 21.9332688 },
    { lat: 49.1723751, lng: 21.9333128 },
    { lat: 49.1715366, lng: 21.9316645 },
    { lat: 49.1714936, lng: 21.9316497 },
    { lat: 49.171302, lng: 21.9310055 },
    { lat: 49.1710954, lng: 21.9314671 },
    { lat: 49.1707373, lng: 21.9314558 },
    { lat: 49.1705302, lng: 21.9318559 },
    { lat: 49.1694387, lng: 21.9317895 },
    { lat: 49.169017, lng: 21.9319782 },
    { lat: 49.1677323, lng: 21.9322396 },
    { lat: 49.1673383, lng: 21.9325937 },
    { lat: 49.1667986, lng: 21.9327381 },
    { lat: 49.1668218, lng: 21.9330401 },
    { lat: 49.1667748, lng: 21.9343192 },
    { lat: 49.1650648, lng: 21.9342597 },
    { lat: 49.1637902, lng: 21.9344356 },
    { lat: 49.1638368, lng: 21.9361707 },
    { lat: 49.1637179, lng: 21.9361383 },
    { lat: 49.1632359, lng: 21.9365769 },
    { lat: 49.1630282, lng: 21.9364027 },
    { lat: 49.1626649, lng: 21.9365441 },
    { lat: 49.1622521, lng: 21.9363601 },
    { lat: 49.1618421, lng: 21.9365257 },
    { lat: 49.1616023, lng: 21.936418 },
    { lat: 49.1611777, lng: 21.9364404 },
    { lat: 49.1609851, lng: 21.9362966 },
    { lat: 49.1605173, lng: 21.9361665 },
    { lat: 49.1602338, lng: 21.9358921 },
    { lat: 49.1592582, lng: 21.9346937 },
    { lat: 49.1588726, lng: 21.9341136 },
    { lat: 49.1586774, lng: 21.9335909 },
    { lat: 49.1581267, lng: 21.9325693 },
    { lat: 49.1571378, lng: 21.931471 },
    { lat: 49.156964, lng: 21.9324007 },
    { lat: 49.1565665, lng: 21.932792 },
    { lat: 49.1558832, lng: 21.9329061 },
    { lat: 49.1551627, lng: 21.9332894 },
    { lat: 49.1546713, lng: 21.9334234 },
    { lat: 49.1523879, lng: 21.9331045 },
    { lat: 49.1489438, lng: 21.9337498 },
    { lat: 49.1457466, lng: 21.9354047 },
    { lat: 49.1431373, lng: 21.9358209 },
    { lat: 49.1419338, lng: 21.9360983 },
    { lat: 49.139298, lng: 21.9409418 },
    { lat: 49.138541, lng: 21.942849 },
    { lat: 49.1376138, lng: 21.9454254 },
    { lat: 49.1374334, lng: 21.947455 },
    { lat: 49.1375535, lng: 21.9476341 },
    { lat: 49.1376044, lng: 21.9487308 },
    { lat: 49.1379565, lng: 21.9495095 },
    { lat: 49.1378352, lng: 21.9497938 },
    { lat: 49.1377317, lng: 21.9504801 },
    { lat: 49.1378965, lng: 21.9508144 },
    { lat: 49.1378315, lng: 21.9513236 },
    { lat: 49.1381172, lng: 21.952146 },
    { lat: 49.1380037, lng: 21.9527053 },
    { lat: 49.1378417, lng: 21.9531528 },
    { lat: 49.1377953, lng: 21.9538348 },
    { lat: 49.1369812, lng: 21.9548003 },
    { lat: 49.1366474, lng: 21.9559078 },
    { lat: 49.1364412, lng: 21.9563995 },
    { lat: 49.135829, lng: 21.957062 },
    { lat: 49.1356113, lng: 21.9574513 },
    { lat: 49.1354407, lng: 21.9580178 },
    { lat: 49.1352922, lng: 21.9588424 },
    { lat: 49.1350032, lng: 21.9592048 },
    { lat: 49.1344467, lng: 21.9595373 },
    { lat: 49.1342807, lng: 21.9602824 },
    { lat: 49.1341088, lng: 21.9614898 },
    { lat: 49.1336766, lng: 21.9619793 },
    { lat: 49.1333825, lng: 21.9625485 },
    { lat: 49.1329598, lng: 21.9630618 },
    { lat: 49.1325826, lng: 21.9636922 },
    { lat: 49.1324422, lng: 21.9643388 },
    { lat: 49.132369, lng: 21.9647952 },
    { lat: 49.1330792, lng: 21.9658642 },
    { lat: 49.1342499, lng: 21.967967 },
    { lat: 49.1350789, lng: 21.9670928 },
    { lat: 49.1362821, lng: 21.9661704 },
    { lat: 49.1369002, lng: 21.9655992 },
    { lat: 49.1378473, lng: 21.9649105 },
    { lat: 49.1387701, lng: 21.9637396 },
    { lat: 49.1401048, lng: 21.964457 },
    { lat: 49.1412612, lng: 21.9648257 },
    { lat: 49.1425053, lng: 21.9648298 },
    { lat: 49.143462, lng: 21.9646764 },
    { lat: 49.1450956, lng: 21.9634088 },
    { lat: 49.1457222, lng: 21.964919 },
    { lat: 49.1462699, lng: 21.9652811 },
    { lat: 49.1468307, lng: 21.9654482 },
    { lat: 49.1482579, lng: 21.9656711 },
    { lat: 49.1505269, lng: 21.9668783 },
    { lat: 49.152997, lng: 21.968072 },
    { lat: 49.1535866, lng: 21.9685875 },
    { lat: 49.1542165, lng: 21.9688225 },
    { lat: 49.1551317, lng: 21.9691207 },
    { lat: 49.1554259, lng: 21.9685071 },
    { lat: 49.1560638, lng: 21.969519 },
    { lat: 49.1567689, lng: 21.9711218 },
    { lat: 49.1579258, lng: 21.9728042 },
    { lat: 49.1586338, lng: 21.9741119 },
    { lat: 49.1606495, lng: 21.9761398 },
    { lat: 49.1608298, lng: 21.9765246 },
    { lat: 49.1611169, lng: 21.9790246 },
    { lat: 49.1618551, lng: 21.9800095 },
    { lat: 49.1630667, lng: 21.9810709 },
    { lat: 49.1643572, lng: 21.9822015 },
    { lat: 49.1650302, lng: 21.9826251 },
    { lat: 49.1651397, lng: 21.982694 },
    { lat: 49.1663025, lng: 21.9835586 },
    { lat: 49.1669657, lng: 21.9837919 },
    { lat: 49.1691729, lng: 21.9845684 },
    { lat: 49.1702993, lng: 21.9845491 },
    { lat: 49.1714971, lng: 21.9846376 },
    { lat: 49.1723445, lng: 21.9844655 },
    { lat: 49.1751419, lng: 21.9867895 },
    { lat: 49.1766128, lng: 21.9875133 },
    { lat: 49.1771075, lng: 21.9884318 },
    { lat: 49.1772261, lng: 21.9890553 },
    { lat: 49.1789536, lng: 21.987014 },
    { lat: 49.1790117, lng: 21.9846854 },
    { lat: 49.1792297, lng: 21.9845904 },
    { lat: 49.1812206, lng: 21.9846389 },
    { lat: 49.1823838, lng: 21.9846862 },
    { lat: 49.1826328, lng: 21.9847396 },
    { lat: 49.1829374, lng: 21.9848041 },
    { lat: 49.1831118, lng: 21.984842 },
    { lat: 49.1837709, lng: 21.9848731 },
    { lat: 49.1838247, lng: 21.9848756 },
    { lat: 49.1841269, lng: 21.9847391 },
    { lat: 49.1844041, lng: 21.9846139 },
    { lat: 49.1846605, lng: 21.9844984 },
    { lat: 49.1849071, lng: 21.9843867 },
    { lat: 49.1861973, lng: 21.9843902 },
    { lat: 49.1870762, lng: 21.9818744 },
    { lat: 49.1874736, lng: 21.9800382 },
    { lat: 49.1870479, lng: 21.9791853 },
    { lat: 49.1867165, lng: 21.9778227 },
    { lat: 49.1876124, lng: 21.9782594 },
    { lat: 49.1880467, lng: 21.9778503 },
    { lat: 49.1889231, lng: 21.9781842 },
    { lat: 49.1897118, lng: 21.9768135 },
    { lat: 49.1902936, lng: 21.9759894 },
    { lat: 49.1907807, lng: 21.9756294 },
    { lat: 49.1912696, lng: 21.9749651 },
    { lat: 49.1916753, lng: 21.974501 },
    { lat: 49.1920638, lng: 21.9740565 },
    { lat: 49.1932099, lng: 21.9730904 },
    { lat: 49.194316, lng: 21.9722801 },
    { lat: 49.1972715, lng: 21.9702445 },
    { lat: 49.198065, lng: 21.9701723 },
    { lat: 49.1997793, lng: 21.9684712 },
  ],
  Čabiny: [
    { lat: 49.2242427, lng: 21.8870755 },
    { lat: 49.223246, lng: 21.8872467 },
    { lat: 49.2215373, lng: 21.8870649 },
    { lat: 49.2204604, lng: 21.8862418 },
    { lat: 49.2197643, lng: 21.8859736 },
    { lat: 49.2171908, lng: 21.8862387 },
    { lat: 49.2147612, lng: 21.8886088 },
    { lat: 49.2123775, lng: 21.8880136 },
    { lat: 49.210302, lng: 21.8883152 },
    { lat: 49.2102588, lng: 21.887136 },
    { lat: 49.2100055, lng: 21.8867245 },
    { lat: 49.2095985, lng: 21.8857087 },
    { lat: 49.2099278, lng: 21.8840925 },
    { lat: 49.2091123, lng: 21.8811987 },
    { lat: 49.2087817, lng: 21.8793709 },
    { lat: 49.2086799, lng: 21.8779721 },
    { lat: 49.2072564, lng: 21.8738467 },
    { lat: 49.2056848, lng: 21.8714842 },
    { lat: 49.2060853, lng: 21.8696278 },
    { lat: 49.2066452, lng: 21.8655331 },
    { lat: 49.2067766, lng: 21.8613136 },
    { lat: 49.2069132, lng: 21.8591669 },
    { lat: 49.2061786, lng: 21.8584118 },
    { lat: 49.2054718, lng: 21.8572008 },
    { lat: 49.2053661, lng: 21.8567124 },
    { lat: 49.2043482, lng: 21.85659 },
    { lat: 49.2038781, lng: 21.8565014 },
    { lat: 49.2032148, lng: 21.8566936 },
    { lat: 49.2022783, lng: 21.8567102 },
    { lat: 49.2019283, lng: 21.8565748 },
    { lat: 49.2015921, lng: 21.8562164 },
    { lat: 49.2013279, lng: 21.8557828 },
    { lat: 49.2011329, lng: 21.8559487 },
    { lat: 49.2004506, lng: 21.8559672 },
    { lat: 49.2002693, lng: 21.8557815 },
    { lat: 49.2003408, lng: 21.8550522 },
    { lat: 49.1996667, lng: 21.8542494 },
    { lat: 49.1987835, lng: 21.8549899 },
    { lat: 49.1981692, lng: 21.855692 },
    { lat: 49.1974934, lng: 21.8557799 },
    { lat: 49.1968092, lng: 21.8560791 },
    { lat: 49.1954378, lng: 21.8577069 },
    { lat: 49.1947967, lng: 21.8582814 },
    { lat: 49.1943752, lng: 21.8589189 },
    { lat: 49.1936432, lng: 21.8592363 },
    { lat: 49.1932178, lng: 21.8592658 },
    { lat: 49.1926974, lng: 21.8594185 },
    { lat: 49.1917909, lng: 21.8594251 },
    { lat: 49.1916068, lng: 21.8593511 },
    { lat: 49.1912367, lng: 21.8594812 },
    { lat: 49.1911376, lng: 21.8593846 },
    { lat: 49.1907989, lng: 21.8592917 },
    { lat: 49.1900931, lng: 21.8594062 },
    { lat: 49.1895788, lng: 21.859351 },
    { lat: 49.1890881, lng: 21.8591582 },
    { lat: 49.1887195, lng: 21.8588625 },
    { lat: 49.1884592, lng: 21.8585377 },
    { lat: 49.1882636, lng: 21.8581505 },
    { lat: 49.1883314, lng: 21.8578513 },
    { lat: 49.1882622, lng: 21.8575202 },
    { lat: 49.1882662, lng: 21.8567425 },
    { lat: 49.1881806, lng: 21.8567872 },
    { lat: 49.1881576, lng: 21.8569374 },
    { lat: 49.1878197, lng: 21.8571009 },
    { lat: 49.1877689, lng: 21.8571773 },
    { lat: 49.1877254, lng: 21.8575056 },
    { lat: 49.1876397, lng: 21.857537 },
    { lat: 49.1875803, lng: 21.8576419 },
    { lat: 49.1874855, lng: 21.8575821 },
    { lat: 49.1872177, lng: 21.8577953 },
    { lat: 49.1871034, lng: 21.8577665 },
    { lat: 49.1870888, lng: 21.8578591 },
    { lat: 49.1869136, lng: 21.858051 },
    { lat: 49.1868051, lng: 21.8579859 },
    { lat: 49.1856317, lng: 21.8585487 },
    { lat: 49.1851646, lng: 21.8586253 },
    { lat: 49.1844486, lng: 21.8592675 },
    { lat: 49.1839963, lng: 21.8593982 },
    { lat: 49.1837237, lng: 21.8596426 },
    { lat: 49.1831733, lng: 21.8596791 },
    { lat: 49.1819666, lng: 21.8602086 },
    { lat: 49.1814292, lng: 21.8603595 },
    { lat: 49.1809791, lng: 21.8605669 },
    { lat: 49.1807868, lng: 21.8609673 },
    { lat: 49.1804505, lng: 21.8611454 },
    { lat: 49.1800827, lng: 21.8612323 },
    { lat: 49.1795037, lng: 21.8612467 },
    { lat: 49.178893, lng: 21.8613886 },
    { lat: 49.1783648, lng: 21.8618872 },
    { lat: 49.1773637, lng: 21.8622024 },
    { lat: 49.1768496, lng: 21.8622842 },
    { lat: 49.1766694, lng: 21.8625843 },
    { lat: 49.1762041, lng: 21.8628278 },
    { lat: 49.1761358, lng: 21.8630149 },
    { lat: 49.1757288, lng: 21.8628861 },
    { lat: 49.1749863, lng: 21.8630159 },
    { lat: 49.1743964, lng: 21.863928 },
    { lat: 49.1739516, lng: 21.864262 },
    { lat: 49.1739198, lng: 21.8643803 },
    { lat: 49.1737948, lng: 21.8645096 },
    { lat: 49.1734965, lng: 21.8647167 },
    { lat: 49.1730104, lng: 21.8652447 },
    { lat: 49.1726579, lng: 21.8658055 },
    { lat: 49.1720587, lng: 21.8663028 },
    { lat: 49.171884, lng: 21.8656707 },
    { lat: 49.1700613, lng: 21.8667568 },
    { lat: 49.1699584, lng: 21.8669588 },
    { lat: 49.1695821, lng: 21.8670659 },
    { lat: 49.169526, lng: 21.8671576 },
    { lat: 49.169226, lng: 21.8671789 },
    { lat: 49.1689678, lng: 21.8675101 },
    { lat: 49.1687854, lng: 21.8675831 },
    { lat: 49.1684886, lng: 21.8680391 },
    { lat: 49.168376, lng: 21.8680324 },
    { lat: 49.1683845, lng: 21.868097 },
    { lat: 49.1680149, lng: 21.8684786 },
    { lat: 49.1678402, lng: 21.8685047 },
    { lat: 49.167723, lng: 21.8686321 },
    { lat: 49.1675203, lng: 21.8686221 },
    { lat: 49.1674758, lng: 21.8687268 },
    { lat: 49.1673058, lng: 21.8685834 },
    { lat: 49.1669795, lng: 21.8685451 },
    { lat: 49.1667187, lng: 21.8683254 },
    { lat: 49.1666587, lng: 21.8683947 },
    { lat: 49.1665488, lng: 21.86832 },
    { lat: 49.1664094, lng: 21.868372 },
    { lat: 49.166378, lng: 21.8682384 },
    { lat: 49.1662251, lng: 21.8684656 },
    { lat: 49.166165, lng: 21.8683534 },
    { lat: 49.165997, lng: 21.8683011 },
    { lat: 49.1659389, lng: 21.8684918 },
    { lat: 49.1657748, lng: 21.8683218 },
    { lat: 49.1657687, lng: 21.8685184 },
    { lat: 49.1655776, lng: 21.868658 },
    { lat: 49.1654958, lng: 21.8689374 },
    { lat: 49.1654608, lng: 21.8692015 },
    { lat: 49.1655222, lng: 21.8693107 },
    { lat: 49.1654314, lng: 21.8696342 },
    { lat: 49.1653016, lng: 21.8696631 },
    { lat: 49.1653165, lng: 21.8698509 },
    { lat: 49.1652379, lng: 21.8699044 },
    { lat: 49.1650862, lng: 21.8703544 },
    { lat: 49.1651264, lng: 21.8707391 },
    { lat: 49.1650993, lng: 21.8710451 },
    { lat: 49.1648562, lng: 21.8713499 },
    { lat: 49.1640539, lng: 21.8720021 },
    { lat: 49.1624831, lng: 21.873051 },
    { lat: 49.1623413, lng: 21.873316 },
    { lat: 49.1623383, lng: 21.8734096 },
    { lat: 49.1622229, lng: 21.87348 },
    { lat: 49.1622203, lng: 21.8735991 },
    { lat: 49.1621214, lng: 21.8738195 },
    { lat: 49.1619223, lng: 21.8740918 },
    { lat: 49.1613784, lng: 21.8745975 },
    { lat: 49.1612935, lng: 21.8748394 },
    { lat: 49.1611608, lng: 21.8749766 },
    { lat: 49.1610659, lng: 21.8753186 },
    { lat: 49.1608512, lng: 21.8754951 },
    { lat: 49.1608796, lng: 21.8756408 },
    { lat: 49.1606567, lng: 21.8759971 },
    { lat: 49.1604431, lng: 21.8765714 },
    { lat: 49.1602684, lng: 21.876609 },
    { lat: 49.159492, lng: 21.8775302 },
    { lat: 49.1578091, lng: 21.8789502 },
    { lat: 49.1560416, lng: 21.8803122 },
    { lat: 49.1556246, lng: 21.8804154 },
    { lat: 49.155254, lng: 21.8808321 },
    { lat: 49.1550342, lng: 21.880863 },
    { lat: 49.1549567, lng: 21.8807328 },
    { lat: 49.1540892, lng: 21.8810751 },
    { lat: 49.1529977, lng: 21.8811062 },
    { lat: 49.1528755, lng: 21.8827239 },
    { lat: 49.15197, lng: 21.8836147 },
    { lat: 49.1510468, lng: 21.8841126 },
    { lat: 49.1508452, lng: 21.8838841 },
    { lat: 49.1502435, lng: 21.8842902 },
    { lat: 49.1502247, lng: 21.8847419 },
    { lat: 49.1499381, lng: 21.8855158 },
    { lat: 49.1492182, lng: 21.8867563 },
    { lat: 49.1485873, lng: 21.88761 },
    { lat: 49.1473718, lng: 21.8921875 },
    { lat: 49.1472669, lng: 21.8926618 },
    { lat: 49.1473029, lng: 21.8928839 },
    { lat: 49.1486052, lng: 21.8992326 },
    { lat: 49.151295, lng: 21.900722 },
    { lat: 49.1533244, lng: 21.9002357 },
    { lat: 49.1546768, lng: 21.9035275 },
    { lat: 49.1554532, lng: 21.9065465 },
    { lat: 49.1564098, lng: 21.9114601 },
    { lat: 49.1576506, lng: 21.9115568 },
    { lat: 49.159277, lng: 21.9123205 },
    { lat: 49.1602484, lng: 21.9116725 },
    { lat: 49.1609123, lng: 21.9103782 },
    { lat: 49.1615927, lng: 21.9105068 },
    { lat: 49.1621748, lng: 21.910436 },
    { lat: 49.1625096, lng: 21.9105653 },
    { lat: 49.1629531, lng: 21.9108446 },
    { lat: 49.1631135, lng: 21.9110085 },
    { lat: 49.1637145, lng: 21.9108852 },
    { lat: 49.1638495, lng: 21.9103925 },
    { lat: 49.1639953, lng: 21.9101069 },
    { lat: 49.1640983, lng: 21.909275 },
    { lat: 49.164718, lng: 21.9089108 },
    { lat: 49.1657157, lng: 21.9092283 },
    { lat: 49.1658599, lng: 21.909368 },
    { lat: 49.1668465, lng: 21.9113562 },
    { lat: 49.1672972, lng: 21.9119299 },
    { lat: 49.1666328, lng: 21.9129336 },
    { lat: 49.165781, lng: 21.9148589 },
    { lat: 49.1669008, lng: 21.9156903 },
    { lat: 49.1677244, lng: 21.9172313 },
    { lat: 49.1679042, lng: 21.9174088 },
    { lat: 49.1681876, lng: 21.9180329 },
    { lat: 49.1681636, lng: 21.9182431 },
    { lat: 49.1697224, lng: 21.9200467 },
    { lat: 49.1705393, lng: 21.9220857 },
    { lat: 49.1706012, lng: 21.9237611 },
    { lat: 49.1705005, lng: 21.9246864 },
    { lat: 49.1707611, lng: 21.9269266 },
    { lat: 49.1710178, lng: 21.9282547 },
    { lat: 49.173435, lng: 21.92789 },
    { lat: 49.1723318, lng: 21.9298507 },
    { lat: 49.1730053, lng: 21.9302029 },
    { lat: 49.1731532, lng: 21.931217 },
    { lat: 49.17525, lng: 21.9333539 },
    { lat: 49.1768515, lng: 21.9328466 },
    { lat: 49.17699, lng: 21.9348479 },
    { lat: 49.1769643, lng: 21.9349508 },
    { lat: 49.1769485, lng: 21.9349912 },
    { lat: 49.1775255, lng: 21.9352197 },
    { lat: 49.1788547, lng: 21.9366566 },
    { lat: 49.1794852, lng: 21.9383251 },
    { lat: 49.1798727, lng: 21.9396211 },
    { lat: 49.1807399, lng: 21.9422062 },
    { lat: 49.1807736, lng: 21.9427105 },
    { lat: 49.1808433, lng: 21.9430235 },
    { lat: 49.1810354, lng: 21.9432897 },
    { lat: 49.18106, lng: 21.9433228 },
    { lat: 49.1852749, lng: 21.9475792 },
    { lat: 49.1863203, lng: 21.948874 },
    { lat: 49.1867111, lng: 21.9497177 },
    { lat: 49.1869212, lng: 21.9498455 },
    { lat: 49.1873489, lng: 21.9506829 },
    { lat: 49.1876153, lng: 21.9510416 },
    { lat: 49.1877897, lng: 21.9511815 },
    { lat: 49.1881401, lng: 21.9513793 },
    { lat: 49.1888414, lng: 21.9517763 },
    { lat: 49.1893203, lng: 21.9521279 },
    { lat: 49.189498, lng: 21.9523593 },
    { lat: 49.1897698, lng: 21.9519798 },
    { lat: 49.1900652, lng: 21.9525936 },
    { lat: 49.1904677, lng: 21.9537288 },
    { lat: 49.1906417, lng: 21.9539892 },
    { lat: 49.190887, lng: 21.9542269 },
    { lat: 49.1910497, lng: 21.9542423 },
    { lat: 49.1922017, lng: 21.9543515 },
    { lat: 49.1928957, lng: 21.9545408 },
    { lat: 49.19305, lng: 21.9546409 },
    { lat: 49.193232, lng: 21.9548968 },
    { lat: 49.1934977, lng: 21.9552702 },
    { lat: 49.1937863, lng: 21.9556758 },
    { lat: 49.1940057, lng: 21.9559041 },
    { lat: 49.1942042, lng: 21.9560018 },
    { lat: 49.1947515, lng: 21.9562714 },
    { lat: 49.1946097, lng: 21.9564575 },
    { lat: 49.1949171, lng: 21.9568798 },
    { lat: 49.1951259, lng: 21.9573702 },
    { lat: 49.195245, lng: 21.9573163 },
    { lat: 49.1954224, lng: 21.957483 },
    { lat: 49.1957304, lng: 21.9582613 },
    { lat: 49.1962659, lng: 21.9601385 },
    { lat: 49.1964042, lng: 21.9603687 },
    { lat: 49.1969928, lng: 21.9609306 },
    { lat: 49.1974077, lng: 21.9614808 },
    { lat: 49.1980622, lng: 21.9611503 },
    { lat: 49.198253, lng: 21.9616854 },
    { lat: 49.1983862, lng: 21.9617928 },
    { lat: 49.1985534, lng: 21.9625537 },
    { lat: 49.1984821, lng: 21.96304 },
    { lat: 49.1985342, lng: 21.9633751 },
    { lat: 49.1985167, lng: 21.9641318 },
    { lat: 49.1987016, lng: 21.965849 },
    { lat: 49.1987724, lng: 21.965846 },
    { lat: 49.1993669, lng: 21.9656937 },
    { lat: 49.1997571, lng: 21.9654585 },
    { lat: 49.1997165, lng: 21.9644862 },
    { lat: 49.2000722, lng: 21.9639008 },
    { lat: 49.2006764, lng: 21.9633185 },
    { lat: 49.2010436, lng: 21.9626084 },
    { lat: 49.2016694, lng: 21.9621209 },
    { lat: 49.202336, lng: 21.9609998 },
    { lat: 49.2027696, lng: 21.9607092 },
    { lat: 49.2032566, lng: 21.960199 },
    { lat: 49.2036748, lng: 21.9595995 },
    { lat: 49.203888, lng: 21.9591176 },
    { lat: 49.2040404, lng: 21.957491 },
    { lat: 49.2045144, lng: 21.9557732 },
    { lat: 49.2059603, lng: 21.9531472 },
    { lat: 49.206214, lng: 21.9528068 },
    { lat: 49.2066379, lng: 21.9518817 },
    { lat: 49.2067525, lng: 21.9512809 },
    { lat: 49.2079761, lng: 21.9495923 },
    { lat: 49.2081467, lng: 21.9488936 },
    { lat: 49.2085379, lng: 21.947651 },
    { lat: 49.2091742, lng: 21.9471859 },
    { lat: 49.2092672, lng: 21.94685 },
    { lat: 49.2096897, lng: 21.9466552 },
    { lat: 49.209864, lng: 21.9463362 },
    { lat: 49.2102432, lng: 21.9461989 },
    { lat: 49.210493, lng: 21.9455686 },
    { lat: 49.2106094, lng: 21.9456343 },
    { lat: 49.2113971, lng: 21.9449191 },
    { lat: 49.2115225, lng: 21.9445349 },
    { lat: 49.2117122, lng: 21.9442887 },
    { lat: 49.2118617, lng: 21.9431929 },
    { lat: 49.2120156, lng: 21.9430488 },
    { lat: 49.2123536, lng: 21.9430685 },
    { lat: 49.2122086, lng: 21.9425693 },
    { lat: 49.2121516, lng: 21.9420383 },
    { lat: 49.2121474, lng: 21.9400552 },
    { lat: 49.2122462, lng: 21.9397718 },
    { lat: 49.2126242, lng: 21.9394539 },
    { lat: 49.2130512, lng: 21.938985 },
    { lat: 49.2132269, lng: 21.9389497 },
    { lat: 49.2133143, lng: 21.9387504 },
    { lat: 49.2137076, lng: 21.9383276 },
    { lat: 49.2147245, lng: 21.9378369 },
    { lat: 49.2149881, lng: 21.9376199 },
    { lat: 49.2151849, lng: 21.9376708 },
    { lat: 49.2153017, lng: 21.9374726 },
    { lat: 49.2155181, lng: 21.9373612 },
    { lat: 49.2161867, lng: 21.9371503 },
    { lat: 49.2163091, lng: 21.9370591 },
    { lat: 49.2165806, lng: 21.9371132 },
    { lat: 49.2167496, lng: 21.9369897 },
    { lat: 49.2169822, lng: 21.9369778 },
    { lat: 49.2175495, lng: 21.937407 },
    { lat: 49.2178377, lng: 21.9367388 },
    { lat: 49.2180046, lng: 21.9366176 },
    { lat: 49.219141, lng: 21.9364992 },
    { lat: 49.2197596, lng: 21.9362749 },
    { lat: 49.2201067, lng: 21.9363481 },
    { lat: 49.2208615, lng: 21.9363074 },
    { lat: 49.2219122, lng: 21.936218 },
    { lat: 49.2224646, lng: 21.9360454 },
    { lat: 49.2227025, lng: 21.9358395 },
    { lat: 49.2225379, lng: 21.9330089 },
    { lat: 49.222456, lng: 21.9285316 },
    { lat: 49.2220718, lng: 21.9228393 },
    { lat: 49.2215514, lng: 21.9181986 },
    { lat: 49.2216938, lng: 21.9088568 },
    { lat: 49.2210919, lng: 21.9055613 },
    { lat: 49.2207415, lng: 21.9030244 },
    { lat: 49.2204373, lng: 21.8965874 },
    { lat: 49.220156, lng: 21.8923788 },
    { lat: 49.2213638, lng: 21.8929917 },
    { lat: 49.2235425, lng: 21.893844 },
    { lat: 49.2239787, lng: 21.8900325 },
    { lat: 49.22424, lng: 21.8871009 },
    { lat: 49.2242427, lng: 21.8870755 },
  ],
  Oľka: [
    { lat: 49.1882662, lng: 21.8567425 },
    { lat: 49.1879984, lng: 21.8556839 },
    { lat: 49.1878732, lng: 21.8553591 },
    { lat: 49.1882971, lng: 21.8544306 },
    { lat: 49.1881589, lng: 21.8534996 },
    { lat: 49.1879834, lng: 21.8531917 },
    { lat: 49.1879614, lng: 21.8524667 },
    { lat: 49.1877502, lng: 21.8515525 },
    { lat: 49.1877349, lng: 21.8509518 },
    { lat: 49.187566, lng: 21.8503653 },
    { lat: 49.1860729, lng: 21.8475726 },
    { lat: 49.18525, lng: 21.846405 },
    { lat: 49.185086, lng: 21.8456593 },
    { lat: 49.1848171, lng: 21.8453103 },
    { lat: 49.1846797, lng: 21.8437195 },
    { lat: 49.1843355, lng: 21.8432351 },
    { lat: 49.1840129, lng: 21.8423644 },
    { lat: 49.1848576, lng: 21.8408682 },
    { lat: 49.1847203, lng: 21.8384539 },
    { lat: 49.1841548, lng: 21.8363094 },
    { lat: 49.1832887, lng: 21.8336751 },
    { lat: 49.1824563, lng: 21.8318824 },
    { lat: 49.1822928, lng: 21.8317357 },
    { lat: 49.1825134, lng: 21.831084 },
    { lat: 49.1823411, lng: 21.830725 },
    { lat: 49.1822963, lng: 21.830422 },
    { lat: 49.1821283, lng: 21.8306284 },
    { lat: 49.181941, lng: 21.8310887 },
    { lat: 49.1816912, lng: 21.8314765 },
    { lat: 49.1817571, lng: 21.8317231 },
    { lat: 49.1816882, lng: 21.8319308 },
    { lat: 49.18116, lng: 21.8315995 },
    { lat: 49.181008, lng: 21.8307205 },
    { lat: 49.1807812, lng: 21.8305615 },
    { lat: 49.18065, lng: 21.830272 },
    { lat: 49.1805656, lng: 21.8297417 },
    { lat: 49.1806177, lng: 21.8293197 },
    { lat: 49.1804929, lng: 21.8291506 },
    { lat: 49.1803607, lng: 21.8287292 },
    { lat: 49.1801285, lng: 21.8285335 },
    { lat: 49.1801145, lng: 21.8273741 },
    { lat: 49.180056, lng: 21.8271625 },
    { lat: 49.1802558, lng: 21.8267019 },
    { lat: 49.180383, lng: 21.8260931 },
    { lat: 49.1801243, lng: 21.8254395 },
    { lat: 49.1798954, lng: 21.8252921 },
    { lat: 49.1799193, lng: 21.8249085 },
    { lat: 49.1798806, lng: 21.8241834 },
    { lat: 49.179579, lng: 21.8237053 },
    { lat: 49.1794788, lng: 21.8233083 },
    { lat: 49.1792083, lng: 21.822591 },
    { lat: 49.179191, lng: 21.8223929 },
    { lat: 49.1792847, lng: 21.8219702 },
    { lat: 49.179076, lng: 21.8205931 },
    { lat: 49.1790506, lng: 21.8196066 },
    { lat: 49.178881, lng: 21.8193579 },
    { lat: 49.1786842, lng: 21.8173088 },
    { lat: 49.178564, lng: 21.8170093 },
    { lat: 49.1783513, lng: 21.8159173 },
    { lat: 49.1780511, lng: 21.8149171 },
    { lat: 49.1778762, lng: 21.8136415 },
    { lat: 49.1776895, lng: 21.8131206 },
    { lat: 49.177728, lng: 21.812693 },
    { lat: 49.1776195, lng: 21.811969 },
    { lat: 49.1770549, lng: 21.8116743 },
    { lat: 49.1768657, lng: 21.8116619 },
    { lat: 49.1766924, lng: 21.8115311 },
    { lat: 49.1763771, lng: 21.8109978 },
    { lat: 49.1763711, lng: 21.8108907 },
    { lat: 49.1761092, lng: 21.8102877 },
    { lat: 49.1760617, lng: 21.8099292 },
    { lat: 49.1758572, lng: 21.8091114 },
    { lat: 49.175581, lng: 21.8087423 },
    { lat: 49.1755544, lng: 21.8056743 },
    { lat: 49.175681, lng: 21.805135 },
    { lat: 49.175165, lng: 21.805387 },
    { lat: 49.174258, lng: 21.804756 },
    { lat: 49.173267, lng: 21.804047 },
    { lat: 49.172916, lng: 21.803949 },
    { lat: 49.172269, lng: 21.8037 },
    { lat: 49.171797, lng: 21.803628 },
    { lat: 49.171436, lng: 21.803504 },
    { lat: 49.171254, lng: 21.803506 },
    { lat: 49.170442, lng: 21.803578 },
    { lat: 49.170043, lng: 21.803424 },
    { lat: 49.169316, lng: 21.803198 },
    { lat: 49.168737, lng: 21.803124 },
    { lat: 49.168194, lng: 21.803339 },
    { lat: 49.167726, lng: 21.803621 },
    { lat: 49.167579, lng: 21.803576 },
    { lat: 49.167519, lng: 21.803558 },
    { lat: 49.167278, lng: 21.803485 },
    { lat: 49.166903, lng: 21.803222 },
    { lat: 49.166562, lng: 21.803045 },
    { lat: 49.166511, lng: 21.803018 },
    { lat: 49.166052, lng: 21.803187 },
    { lat: 49.165147, lng: 21.803607 },
    { lat: 49.164709, lng: 21.803916 },
    { lat: 49.164216, lng: 21.804171 },
    { lat: 49.163154, lng: 21.804452 },
    { lat: 49.162581, lng: 21.804526 },
    { lat: 49.162306, lng: 21.804494 },
    { lat: 49.161944, lng: 21.804421 },
    { lat: 49.161717, lng: 21.804254 },
    { lat: 49.161385, lng: 21.804188 },
    { lat: 49.160692, lng: 21.803695 },
    { lat: 49.16058, lng: 21.803916 },
    { lat: 49.15957, lng: 21.804599 },
    { lat: 49.15921, lng: 21.805064 },
    { lat: 49.158805, lng: 21.805508 },
    { lat: 49.15853, lng: 21.805772 },
    { lat: 49.158434, lng: 21.805996 },
    { lat: 49.158386, lng: 21.806111 },
    { lat: 49.158324, lng: 21.806255 },
    { lat: 49.158007, lng: 21.806581 },
    { lat: 49.157942, lng: 21.806642 },
    { lat: 49.157747, lng: 21.806427 },
    { lat: 49.157489, lng: 21.806306 },
    { lat: 49.157271, lng: 21.806066 },
    { lat: 49.156964, lng: 21.805621 },
    { lat: 49.156648, lng: 21.804931 },
    { lat: 49.156583, lng: 21.804553 },
    { lat: 49.156391, lng: 21.804525 },
    { lat: 49.156259, lng: 21.804188 },
    { lat: 49.15591, lng: 21.803864 },
    { lat: 49.155173, lng: 21.803426 },
    { lat: 49.155141, lng: 21.803705 },
    { lat: 49.154796, lng: 21.804263 },
    { lat: 49.154678, lng: 21.804492 },
    { lat: 49.154505, lng: 21.80469 },
    { lat: 49.154458, lng: 21.804818 },
    { lat: 49.154342, lng: 21.804983 },
    { lat: 49.154178, lng: 21.80529 },
    { lat: 49.154014, lng: 21.805532 },
    { lat: 49.153775, lng: 21.805674 },
    { lat: 49.15361, lng: 21.805946 },
    { lat: 49.153513, lng: 21.806012 },
    { lat: 49.153313, lng: 21.806512 },
    { lat: 49.15286, lng: 21.806801 },
    { lat: 49.152461, lng: 21.807338 },
    { lat: 49.152285, lng: 21.807671 },
    { lat: 49.152036, lng: 21.807801 },
    { lat: 49.15192, lng: 21.808042 },
    { lat: 49.151604, lng: 21.808434 },
    { lat: 49.151498, lng: 21.808707 },
    { lat: 49.151243, lng: 21.809002 },
    { lat: 49.150937, lng: 21.808824 },
    { lat: 49.150866, lng: 21.808766 },
    { lat: 49.15082, lng: 21.808719 },
    { lat: 49.150508, lng: 21.808397 },
    { lat: 49.15002, lng: 21.807999 },
    { lat: 49.149606, lng: 21.80775 },
    { lat: 49.149443, lng: 21.807683 },
    { lat: 49.149291, lng: 21.807702 },
    { lat: 49.14907, lng: 21.807842 },
    { lat: 49.148769, lng: 21.808293 },
    { lat: 49.148478, lng: 21.808639 },
    { lat: 49.148019, lng: 21.808888 },
    { lat: 49.147569, lng: 21.809039 },
    { lat: 49.147114, lng: 21.809447 },
    { lat: 49.146735, lng: 21.809691 },
    { lat: 49.146161, lng: 21.809956 },
    { lat: 49.145832, lng: 21.810054 },
    { lat: 49.145535, lng: 21.810042 },
    { lat: 49.145305, lng: 21.810119 },
    { lat: 49.144934, lng: 21.810183 },
    { lat: 49.144434, lng: 21.810388 },
    { lat: 49.144213, lng: 21.81062 },
    { lat: 49.143869, lng: 21.810863 },
    { lat: 49.143617, lng: 21.811286 },
    { lat: 49.143097, lng: 21.812335 },
    { lat: 49.142721, lng: 21.812953 },
    { lat: 49.142246, lng: 21.813076 },
    { lat: 49.141713, lng: 21.813095 },
    { lat: 49.141491, lng: 21.813277 },
    { lat: 49.141307, lng: 21.813484 },
    { lat: 49.140975, lng: 21.813769 },
    { lat: 49.140321, lng: 21.814183 },
    { lat: 49.139225, lng: 21.815638 },
    { lat: 49.139213, lng: 21.815663 },
    { lat: 49.139307, lng: 21.816424 },
    { lat: 49.139193, lng: 21.817096 },
    { lat: 49.139138, lng: 21.817708 },
    { lat: 49.139235, lng: 21.818125 },
    { lat: 49.139396, lng: 21.818465 },
    { lat: 49.139244, lng: 21.819357 },
    { lat: 49.139189, lng: 21.819425 },
    { lat: 49.139158, lng: 21.819463 },
    { lat: 49.138878, lng: 21.819812 },
    { lat: 49.138154, lng: 21.820163 },
    { lat: 49.137857, lng: 21.820382 },
    { lat: 49.137145, lng: 21.8215 },
    { lat: 49.136851, lng: 21.821834 },
    { lat: 49.136672, lng: 21.82233 },
    { lat: 49.136421, lng: 21.822379 },
    { lat: 49.136124, lng: 21.822531 },
    { lat: 49.135864, lng: 21.822723 },
    { lat: 49.135659, lng: 21.823509 },
    { lat: 49.135489, lng: 21.823881 },
    { lat: 49.135251, lng: 21.824179 },
    { lat: 49.135221, lng: 21.824339 },
    { lat: 49.135064, lng: 21.825193 },
    { lat: 49.135159, lng: 21.826014 },
    { lat: 49.135169, lng: 21.826426 },
    { lat: 49.135114, lng: 21.827081 },
    { lat: 49.135108, lng: 21.827155 },
    { lat: 49.135105, lng: 21.827191 },
    { lat: 49.135328, lng: 21.828268 },
    { lat: 49.135354, lng: 21.828697 },
    { lat: 49.13583, lng: 21.829151 },
    { lat: 49.135403, lng: 21.829671 },
    { lat: 49.135334, lng: 21.829845 },
    { lat: 49.13525, lng: 21.829891 },
    { lat: 49.135103, lng: 21.830162 },
    { lat: 49.134931, lng: 21.830237 },
    { lat: 49.134556, lng: 21.830986 },
    { lat: 49.134257, lng: 21.831187 },
    { lat: 49.133994, lng: 21.831465 },
    { lat: 49.133803, lng: 21.831434 },
    { lat: 49.133484, lng: 21.831634 },
    { lat: 49.133218, lng: 21.831665 },
    { lat: 49.132774, lng: 21.832005 },
    { lat: 49.132497, lng: 21.832004 },
    { lat: 49.132442, lng: 21.831999 },
    { lat: 49.132359, lng: 21.832113 },
    { lat: 49.132091, lng: 21.832041 },
    { lat: 49.131929, lng: 21.832281 },
    { lat: 49.131788, lng: 21.832345 },
    { lat: 49.131603, lng: 21.832454 },
    { lat: 49.131478, lng: 21.832687 },
    { lat: 49.131399, lng: 21.832846 },
    { lat: 49.131342, lng: 21.832958 },
    { lat: 49.131242, lng: 21.833158 },
    { lat: 49.131216, lng: 21.833211 },
    { lat: 49.131158, lng: 21.833331 },
    { lat: 49.131014, lng: 21.833846 },
    { lat: 49.130741, lng: 21.835037 },
    { lat: 49.130579, lng: 21.835646 },
    { lat: 49.130406, lng: 21.836166 },
    { lat: 49.130366, lng: 21.836395 },
    { lat: 49.130012, lng: 21.837006 },
    { lat: 49.1298, lng: 21.837804 },
    { lat: 49.129637, lng: 21.838176 },
    { lat: 49.12955, lng: 21.838417 },
    { lat: 49.129427, lng: 21.83866 },
    { lat: 49.129326, lng: 21.838891 },
    { lat: 49.129084, lng: 21.839137 },
    { lat: 49.128694, lng: 21.839426 },
    { lat: 49.128475, lng: 21.839468 },
    { lat: 49.128121, lng: 21.839694 },
    { lat: 49.127956, lng: 21.839799 },
    { lat: 49.127584, lng: 21.840451 },
    { lat: 49.127296, lng: 21.840776 },
    { lat: 49.126915, lng: 21.840765 },
    { lat: 49.126734, lng: 21.840806 },
    { lat: 49.126685, lng: 21.840817 },
    { lat: 49.126397, lng: 21.840883 },
    { lat: 49.126001, lng: 21.84106 },
    { lat: 49.125313, lng: 21.841655 },
    { lat: 49.125147, lng: 21.841739 },
    { lat: 49.124795, lng: 21.842182 },
    { lat: 49.12425, lng: 21.842539 },
    { lat: 49.124197, lng: 21.842509 },
    { lat: 49.124166, lng: 21.842492 },
    { lat: 49.123866, lng: 21.842323 },
    { lat: 49.123678, lng: 21.842246 },
    { lat: 49.123552, lng: 21.842194 },
    { lat: 49.123513, lng: 21.842178 },
    { lat: 49.123436, lng: 21.842245 },
    { lat: 49.122848, lng: 21.843477 },
    { lat: 49.122589, lng: 21.84402 },
    { lat: 49.12241, lng: 21.844396 },
    { lat: 49.121972, lng: 21.845301 },
    { lat: 49.121944, lng: 21.845371 },
    { lat: 49.121874, lng: 21.845548 },
    { lat: 49.121774, lng: 21.845911 },
    { lat: 49.121717, lng: 21.846063 },
    { lat: 49.12128, lng: 21.847756 },
    { lat: 49.120621, lng: 21.849014 },
    { lat: 49.119643, lng: 21.850624 },
    { lat: 49.119066, lng: 21.850824 },
    { lat: 49.118779, lng: 21.851134 },
    { lat: 49.118504, lng: 21.851721 },
    { lat: 49.118549, lng: 21.851781 },
    { lat: 49.118644, lng: 21.851836 },
    { lat: 49.118609, lng: 21.852395 },
    { lat: 49.118927, lng: 21.852607 },
    { lat: 49.119177, lng: 21.852932 },
    { lat: 49.119366, lng: 21.853315 },
    { lat: 49.119924, lng: 21.854584 },
    { lat: 49.119936, lng: 21.855053 },
    { lat: 49.120157, lng: 21.855752 },
    { lat: 49.120508, lng: 21.856103 },
    { lat: 49.12109, lng: 21.857077 },
    { lat: 49.121063, lng: 21.85737 },
    { lat: 49.120824, lng: 21.857836 },
    { lat: 49.120481, lng: 21.859314 },
    { lat: 49.120295, lng: 21.860458 },
    { lat: 49.120247, lng: 21.86118 },
    { lat: 49.120188, lng: 21.861604 },
    { lat: 49.120175, lng: 21.861744 },
    { lat: 49.120046, lng: 21.862868 },
    { lat: 49.11984, lng: 21.863688 },
    { lat: 49.119053, lng: 21.864636 },
    { lat: 49.119167, lng: 21.865554 },
    { lat: 49.11917, lng: 21.865584 },
    { lat: 49.119095, lng: 21.865806 },
    { lat: 49.118915, lng: 21.866342 },
    { lat: 49.11819, lng: 21.867017 },
    { lat: 49.11727, lng: 21.866993 },
    { lat: 49.117147, lng: 21.867508 },
    { lat: 49.116918, lng: 21.868302 },
    { lat: 49.116572, lng: 21.868611 },
    { lat: 49.116507, lng: 21.869285 },
    { lat: 49.116607, lng: 21.869852 },
    { lat: 49.116436, lng: 21.871967 },
    { lat: 49.1188319, lng: 21.8762099 },
    { lat: 49.119912, lng: 21.8764456 },
    { lat: 49.1203711, lng: 21.8770689 },
    { lat: 49.1231072, lng: 21.8775786 },
    { lat: 49.1241937, lng: 21.8775759 },
    { lat: 49.1248331, lng: 21.8778134 },
    { lat: 49.1269219, lng: 21.8799092 },
    { lat: 49.1280416, lng: 21.8811688 },
    { lat: 49.1292575, lng: 21.881032 },
    { lat: 49.12997, lng: 21.8822531 },
    { lat: 49.1306152, lng: 21.8847273 },
    { lat: 49.1312671, lng: 21.8846227 },
    { lat: 49.1335908, lng: 21.8844785 },
    { lat: 49.1344968, lng: 21.8837451 },
    { lat: 49.1360843, lng: 21.8846068 },
    { lat: 49.1361965, lng: 21.8847263 },
    { lat: 49.1368278, lng: 21.8866999 },
    { lat: 49.1376674, lng: 21.8879633 },
    { lat: 49.1379866, lng: 21.8888524 },
    { lat: 49.1387165, lng: 21.8894656 },
    { lat: 49.1417707, lng: 21.8882751 },
    { lat: 49.1428255, lng: 21.8878178 },
    { lat: 49.1440971, lng: 21.8871703 },
    { lat: 49.1447737, lng: 21.886167 },
    { lat: 49.1485873, lng: 21.88761 },
    { lat: 49.1492182, lng: 21.8867563 },
    { lat: 49.1499381, lng: 21.8855158 },
    { lat: 49.1502247, lng: 21.8847419 },
    { lat: 49.1502435, lng: 21.8842902 },
    { lat: 49.1508452, lng: 21.8838841 },
    { lat: 49.1510468, lng: 21.8841126 },
    { lat: 49.15197, lng: 21.8836147 },
    { lat: 49.1528755, lng: 21.8827239 },
    { lat: 49.1529977, lng: 21.8811062 },
    { lat: 49.1540892, lng: 21.8810751 },
    { lat: 49.1549567, lng: 21.8807328 },
    { lat: 49.1550342, lng: 21.880863 },
    { lat: 49.155254, lng: 21.8808321 },
    { lat: 49.1556246, lng: 21.8804154 },
    { lat: 49.1560416, lng: 21.8803122 },
    { lat: 49.1578091, lng: 21.8789502 },
    { lat: 49.159492, lng: 21.8775302 },
    { lat: 49.1602684, lng: 21.876609 },
    { lat: 49.1604431, lng: 21.8765714 },
    { lat: 49.1606567, lng: 21.8759971 },
    { lat: 49.1608796, lng: 21.8756408 },
    { lat: 49.1608512, lng: 21.8754951 },
    { lat: 49.1610659, lng: 21.8753186 },
    { lat: 49.1611608, lng: 21.8749766 },
    { lat: 49.1612935, lng: 21.8748394 },
    { lat: 49.1613784, lng: 21.8745975 },
    { lat: 49.1619223, lng: 21.8740918 },
    { lat: 49.1621214, lng: 21.8738195 },
    { lat: 49.1622203, lng: 21.8735991 },
    { lat: 49.1622229, lng: 21.87348 },
    { lat: 49.1623383, lng: 21.8734096 },
    { lat: 49.1623413, lng: 21.873316 },
    { lat: 49.1624831, lng: 21.873051 },
    { lat: 49.1640539, lng: 21.8720021 },
    { lat: 49.1648562, lng: 21.8713499 },
    { lat: 49.1650993, lng: 21.8710451 },
    { lat: 49.1651264, lng: 21.8707391 },
    { lat: 49.1650862, lng: 21.8703544 },
    { lat: 49.1652379, lng: 21.8699044 },
    { lat: 49.1653165, lng: 21.8698509 },
    { lat: 49.1653016, lng: 21.8696631 },
    { lat: 49.1654314, lng: 21.8696342 },
    { lat: 49.1655222, lng: 21.8693107 },
    { lat: 49.1654608, lng: 21.8692015 },
    { lat: 49.1654958, lng: 21.8689374 },
    { lat: 49.1655776, lng: 21.868658 },
    { lat: 49.1657687, lng: 21.8685184 },
    { lat: 49.1657748, lng: 21.8683218 },
    { lat: 49.1659389, lng: 21.8684918 },
    { lat: 49.165997, lng: 21.8683011 },
    { lat: 49.166165, lng: 21.8683534 },
    { lat: 49.1662251, lng: 21.8684656 },
    { lat: 49.166378, lng: 21.8682384 },
    { lat: 49.1664094, lng: 21.868372 },
    { lat: 49.1665488, lng: 21.86832 },
    { lat: 49.1666587, lng: 21.8683947 },
    { lat: 49.1667187, lng: 21.8683254 },
    { lat: 49.1669795, lng: 21.8685451 },
    { lat: 49.1673058, lng: 21.8685834 },
    { lat: 49.1674758, lng: 21.8687268 },
    { lat: 49.1675203, lng: 21.8686221 },
    { lat: 49.167723, lng: 21.8686321 },
    { lat: 49.1678402, lng: 21.8685047 },
    { lat: 49.1680149, lng: 21.8684786 },
    { lat: 49.1683845, lng: 21.868097 },
    { lat: 49.168376, lng: 21.8680324 },
    { lat: 49.1684886, lng: 21.8680391 },
    { lat: 49.1687854, lng: 21.8675831 },
    { lat: 49.1689678, lng: 21.8675101 },
    { lat: 49.169226, lng: 21.8671789 },
    { lat: 49.169526, lng: 21.8671576 },
    { lat: 49.1695821, lng: 21.8670659 },
    { lat: 49.1699584, lng: 21.8669588 },
    { lat: 49.1700613, lng: 21.8667568 },
    { lat: 49.171884, lng: 21.8656707 },
    { lat: 49.1720587, lng: 21.8663028 },
    { lat: 49.1726579, lng: 21.8658055 },
    { lat: 49.1730104, lng: 21.8652447 },
    { lat: 49.1734965, lng: 21.8647167 },
    { lat: 49.1737948, lng: 21.8645096 },
    { lat: 49.1739198, lng: 21.8643803 },
    { lat: 49.1739516, lng: 21.864262 },
    { lat: 49.1743964, lng: 21.863928 },
    { lat: 49.1749863, lng: 21.8630159 },
    { lat: 49.1757288, lng: 21.8628861 },
    { lat: 49.1761358, lng: 21.8630149 },
    { lat: 49.1762041, lng: 21.8628278 },
    { lat: 49.1766694, lng: 21.8625843 },
    { lat: 49.1768496, lng: 21.8622842 },
    { lat: 49.1773637, lng: 21.8622024 },
    { lat: 49.1783648, lng: 21.8618872 },
    { lat: 49.178893, lng: 21.8613886 },
    { lat: 49.1795037, lng: 21.8612467 },
    { lat: 49.1800827, lng: 21.8612323 },
    { lat: 49.1804505, lng: 21.8611454 },
    { lat: 49.1807868, lng: 21.8609673 },
    { lat: 49.1809791, lng: 21.8605669 },
    { lat: 49.1814292, lng: 21.8603595 },
    { lat: 49.1819666, lng: 21.8602086 },
    { lat: 49.1831733, lng: 21.8596791 },
    { lat: 49.1837237, lng: 21.8596426 },
    { lat: 49.1839963, lng: 21.8593982 },
    { lat: 49.1844486, lng: 21.8592675 },
    { lat: 49.1851646, lng: 21.8586253 },
    { lat: 49.1856317, lng: 21.8585487 },
    { lat: 49.1868051, lng: 21.8579859 },
    { lat: 49.1869136, lng: 21.858051 },
    { lat: 49.1870888, lng: 21.8578591 },
    { lat: 49.1871034, lng: 21.8577665 },
    { lat: 49.1872177, lng: 21.8577953 },
    { lat: 49.1874855, lng: 21.8575821 },
    { lat: 49.1875803, lng: 21.8576419 },
    { lat: 49.1876397, lng: 21.857537 },
    { lat: 49.1877254, lng: 21.8575056 },
    { lat: 49.1877689, lng: 21.8571773 },
    { lat: 49.1878197, lng: 21.8571009 },
    { lat: 49.1881576, lng: 21.8569374 },
    { lat: 49.1881806, lng: 21.8567872 },
    { lat: 49.1882662, lng: 21.8567425 },
  ],
  RadvaňnadLaborcom: [
    { lat: 49.130099, lng: 21.970578 },
    { lat: 49.1305645, lng: 21.9696391 },
    { lat: 49.1314716, lng: 21.9668667 },
    { lat: 49.1322044, lng: 21.9650036 },
    { lat: 49.132369, lng: 21.9647952 },
    { lat: 49.1324422, lng: 21.9643388 },
    { lat: 49.1325826, lng: 21.9636922 },
    { lat: 49.1329598, lng: 21.9630618 },
    { lat: 49.1333825, lng: 21.9625485 },
    { lat: 49.1336766, lng: 21.9619793 },
    { lat: 49.1341088, lng: 21.9614898 },
    { lat: 49.1342807, lng: 21.9602824 },
    { lat: 49.1344467, lng: 21.9595373 },
    { lat: 49.1350032, lng: 21.9592048 },
    { lat: 49.1352922, lng: 21.9588424 },
    { lat: 49.1354407, lng: 21.9580178 },
    { lat: 49.1356113, lng: 21.9574513 },
    { lat: 49.135829, lng: 21.957062 },
    { lat: 49.1364412, lng: 21.9563995 },
    { lat: 49.1366474, lng: 21.9559078 },
    { lat: 49.1369812, lng: 21.9548003 },
    { lat: 49.1377953, lng: 21.9538348 },
    { lat: 49.1378417, lng: 21.9531528 },
    { lat: 49.1380037, lng: 21.9527053 },
    { lat: 49.1381172, lng: 21.952146 },
    { lat: 49.1378315, lng: 21.9513236 },
    { lat: 49.1378965, lng: 21.9508144 },
    { lat: 49.1377317, lng: 21.9504801 },
    { lat: 49.1378352, lng: 21.9497938 },
    { lat: 49.1379565, lng: 21.9495095 },
    { lat: 49.1376044, lng: 21.9487308 },
    { lat: 49.1375535, lng: 21.9476341 },
    { lat: 49.1374334, lng: 21.947455 },
    { lat: 49.1376138, lng: 21.9454254 },
    { lat: 49.138541, lng: 21.942849 },
    { lat: 49.139298, lng: 21.9409418 },
    { lat: 49.1419338, lng: 21.9360983 },
    { lat: 49.1425293, lng: 21.9345698 },
    { lat: 49.1418347, lng: 21.9325959 },
    { lat: 49.1411492, lng: 21.9309053 },
    { lat: 49.1408451, lng: 21.9307968 },
    { lat: 49.1409473, lng: 21.9303375 },
    { lat: 49.1409686, lng: 21.9294795 },
    { lat: 49.1408283, lng: 21.9288488 },
    { lat: 49.1409881, lng: 21.9280456 },
    { lat: 49.1409026, lng: 21.9274104 },
    { lat: 49.1405647, lng: 21.926752 },
    { lat: 49.1401932, lng: 21.9242602 },
    { lat: 49.1409458, lng: 21.9240324 },
    { lat: 49.1417282, lng: 21.9246978 },
    { lat: 49.1440145, lng: 21.9235067 },
    { lat: 49.144096, lng: 21.9233881 },
    { lat: 49.1440986, lng: 21.9220454 },
    { lat: 49.1444282, lng: 21.9211878 },
    { lat: 49.1455379, lng: 21.9201771 },
    { lat: 49.1461615, lng: 21.9199297 },
    { lat: 49.1464634, lng: 21.9199845 },
    { lat: 49.1469625, lng: 21.9190717 },
    { lat: 49.1471092, lng: 21.9189148 },
    { lat: 49.1471982, lng: 21.9185137 },
    { lat: 49.1475793, lng: 21.9182308 },
    { lat: 49.1488751, lng: 21.9177338 },
    { lat: 49.1488308, lng: 21.9171794 },
    { lat: 49.1485251, lng: 21.9164218 },
    { lat: 49.1484706, lng: 21.9161375 },
    { lat: 49.1493273, lng: 21.9157098 },
    { lat: 49.1490823, lng: 21.9134679 },
    { lat: 49.1479779, lng: 21.9079905 },
    { lat: 49.1483718, lng: 21.9040501 },
    { lat: 49.1486052, lng: 21.8992326 },
    { lat: 49.1473029, lng: 21.8928839 },
    { lat: 49.1472669, lng: 21.8926618 },
    { lat: 49.1473718, lng: 21.8921875 },
    { lat: 49.1485873, lng: 21.88761 },
    { lat: 49.1447737, lng: 21.886167 },
    { lat: 49.1440971, lng: 21.8871703 },
    { lat: 49.1428255, lng: 21.8878178 },
    { lat: 49.1417707, lng: 21.8882751 },
    { lat: 49.1387165, lng: 21.8894656 },
    { lat: 49.1379866, lng: 21.8888524 },
    { lat: 49.1376674, lng: 21.8879633 },
    { lat: 49.1368278, lng: 21.8866999 },
    { lat: 49.1361965, lng: 21.8847263 },
    { lat: 49.1360843, lng: 21.8846068 },
    { lat: 49.1344968, lng: 21.8837451 },
    { lat: 49.1335908, lng: 21.8844785 },
    { lat: 49.1312671, lng: 21.8846227 },
    { lat: 49.1306152, lng: 21.8847273 },
    { lat: 49.12997, lng: 21.8822531 },
    { lat: 49.1292575, lng: 21.881032 },
    { lat: 49.1280416, lng: 21.8811688 },
    { lat: 49.1269219, lng: 21.8799092 },
    { lat: 49.1248331, lng: 21.8778134 },
    { lat: 49.1241937, lng: 21.8775759 },
    { lat: 49.1231072, lng: 21.8775786 },
    { lat: 49.1203711, lng: 21.8770689 },
    { lat: 49.119912, lng: 21.8764456 },
    { lat: 49.1188319, lng: 21.8762099 },
    { lat: 49.116436, lng: 21.871967 },
    { lat: 49.115947, lng: 21.871916 },
    { lat: 49.115467, lng: 21.871866 },
    { lat: 49.114437, lng: 21.871955 },
    { lat: 49.113009, lng: 21.872222 },
    { lat: 49.112974, lng: 21.872244 },
    { lat: 49.11216, lng: 21.872767 },
    { lat: 49.111617, lng: 21.874708 },
    { lat: 49.111367, lng: 21.875364 },
    { lat: 49.111256, lng: 21.875647 },
    { lat: 49.109766, lng: 21.879053 },
    { lat: 49.109749, lng: 21.879093 },
    { lat: 49.107799, lng: 21.883545 },
    { lat: 49.107763, lng: 21.883628 },
    { lat: 49.107331, lng: 21.884614 },
    { lat: 49.107293, lng: 21.884701 },
    { lat: 49.106903, lng: 21.885594 },
    { lat: 49.10315, lng: 21.889505 },
    { lat: 49.10046, lng: 21.894745 },
    { lat: 49.10042, lng: 21.894822 },
    { lat: 49.100385, lng: 21.894891 },
    { lat: 49.100231, lng: 21.895098 },
    { lat: 49.100078, lng: 21.895783 },
    { lat: 49.100258, lng: 21.897374 },
    { lat: 49.100356, lng: 21.897998 },
    { lat: 49.100446, lng: 21.898627 },
    { lat: 49.100482, lng: 21.89906 },
    { lat: 49.100549, lng: 21.899641 },
    { lat: 49.1006, lng: 21.900283 },
    { lat: 49.10068, lng: 21.90073 },
    { lat: 49.1009, lng: 21.901017 },
    { lat: 49.101545, lng: 21.901528 },
    { lat: 49.101848, lng: 21.90205 },
    { lat: 49.102239, lng: 21.902302 },
    { lat: 49.102424, lng: 21.90258 },
    { lat: 49.102587, lng: 21.903154 },
    { lat: 49.102775, lng: 21.903364 },
    { lat: 49.103329, lng: 21.903124 },
    { lat: 49.103656, lng: 21.903251 },
    { lat: 49.103752, lng: 21.903272 },
    { lat: 49.104349, lng: 21.903759 },
    { lat: 49.104421, lng: 21.903846 },
    { lat: 49.104945, lng: 21.904365 },
    { lat: 49.105073, lng: 21.904714 },
    { lat: 49.105272, lng: 21.905034 },
    { lat: 49.105504, lng: 21.905264 },
    { lat: 49.105717, lng: 21.905477 },
    { lat: 49.10612, lng: 21.90612 },
    { lat: 49.106367, lng: 21.906198 },
    { lat: 49.106713, lng: 21.906719 },
    { lat: 49.106808, lng: 21.907037 },
    { lat: 49.107011, lng: 21.907243 },
    { lat: 49.107325, lng: 21.907595 },
    { lat: 49.1077, lng: 21.907864 },
    { lat: 49.107883, lng: 21.907806 },
    { lat: 49.107973, lng: 21.907895 },
    { lat: 49.108121, lng: 21.907905 },
    { lat: 49.108281, lng: 21.907803 },
    { lat: 49.108911, lng: 21.908029 },
    { lat: 49.109255, lng: 21.909415 },
    { lat: 49.110456, lng: 21.911812 },
    { lat: 49.110828, lng: 21.91236 },
    { lat: 49.111204, lng: 21.912882 },
    { lat: 49.112127, lng: 21.913864 },
    { lat: 49.11289, lng: 21.916614 },
    { lat: 49.112943, lng: 21.917301 },
    { lat: 49.112116, lng: 21.920335 },
    { lat: 49.112075, lng: 21.920615 },
    { lat: 49.112038, lng: 21.920995 },
    { lat: 49.112004, lng: 21.921421 },
    { lat: 49.112139, lng: 21.922518 },
    { lat: 49.112098, lng: 21.92291 },
    { lat: 49.112051, lng: 21.923252 },
    { lat: 49.112239, lng: 21.923856 },
    { lat: 49.112279, lng: 21.924241 },
    { lat: 49.112524, lng: 21.925511 },
    { lat: 49.112611, lng: 21.925838 },
    { lat: 49.112708, lng: 21.926137 },
    { lat: 49.112742, lng: 21.926442 },
    { lat: 49.112957, lng: 21.92707 },
    { lat: 49.112972, lng: 21.927115 },
    { lat: 49.113434, lng: 21.929198 },
    { lat: 49.112852, lng: 21.929651 },
    { lat: 49.113001, lng: 21.929864 },
    { lat: 49.113378, lng: 21.931913 },
    { lat: 49.1149036, lng: 21.9326539 },
    { lat: 49.1160923, lng: 21.9318223 },
    { lat: 49.1168611, lng: 21.9339328 },
    { lat: 49.1167518, lng: 21.9326069 },
    { lat: 49.1174446, lng: 21.9304775 },
    { lat: 49.1182358, lng: 21.9333498 },
    { lat: 49.1185825, lng: 21.933273 },
    { lat: 49.1190681, lng: 21.9327614 },
    { lat: 49.1201165, lng: 21.9320779 },
    { lat: 49.1206811, lng: 21.9337514 },
    { lat: 49.1209507, lng: 21.9339215 },
    { lat: 49.121451, lng: 21.9350494 },
    { lat: 49.1215907, lng: 21.9350079 },
    { lat: 49.1216107, lng: 21.9351379 },
    { lat: 49.121866, lng: 21.9353192 },
    { lat: 49.1220785, lng: 21.9366281 },
    { lat: 49.1223292, lng: 21.9370332 },
    { lat: 49.1224173, lng: 21.9375456 },
    { lat: 49.1233392, lng: 21.9385278 },
    { lat: 49.1234701, lng: 21.9388992 },
    { lat: 49.1234576, lng: 21.9398045 },
    { lat: 49.123534, lng: 21.9403941 },
    { lat: 49.1238526, lng: 21.9414499 },
    { lat: 49.1238625, lng: 21.9422603 },
    { lat: 49.124105, lng: 21.9435618 },
    { lat: 49.1241458, lng: 21.9445277 },
    { lat: 49.1241118, lng: 21.9449257 },
    { lat: 49.12442, lng: 21.945971 },
    { lat: 49.1247342, lng: 21.9474883 },
    { lat: 49.1249224, lng: 21.9477282 },
    { lat: 49.1247964, lng: 21.9489173 },
    { lat: 49.1243382, lng: 21.9504921 },
    { lat: 49.1237666, lng: 21.9530416 },
    { lat: 49.1236458, lng: 21.9538418 },
    { lat: 49.1239078, lng: 21.956076 },
    { lat: 49.1239661, lng: 21.9574395 },
    { lat: 49.124911, lng: 21.964994 },
    { lat: 49.124939, lng: 21.964991 },
    { lat: 49.124924, lng: 21.965023 },
    { lat: 49.125887, lng: 21.965387 },
    { lat: 49.126181, lng: 21.965623 },
    { lat: 49.126384, lng: 21.965956 },
    { lat: 49.12666, lng: 21.966071 },
    { lat: 49.128369, lng: 21.968721 },
    { lat: 49.128402, lng: 21.969098 },
    { lat: 49.128975, lng: 21.969297 },
    { lat: 49.129441, lng: 21.969915 },
    { lat: 49.129755, lng: 21.9705 },
    { lat: 49.130099, lng: 21.970578 },
  ],
  Repejov: [
    { lat: 49.175681, lng: 21.805135 },
    { lat: 49.1755544, lng: 21.8056743 },
    { lat: 49.175581, lng: 21.8087423 },
    { lat: 49.1758572, lng: 21.8091114 },
    { lat: 49.1760617, lng: 21.8099292 },
    { lat: 49.1761092, lng: 21.8102877 },
    { lat: 49.1763711, lng: 21.8108907 },
    { lat: 49.1763771, lng: 21.8109978 },
    { lat: 49.1766924, lng: 21.8115311 },
    { lat: 49.1768657, lng: 21.8116619 },
    { lat: 49.1770549, lng: 21.8116743 },
    { lat: 49.1776195, lng: 21.811969 },
    { lat: 49.177728, lng: 21.812693 },
    { lat: 49.1776895, lng: 21.8131206 },
    { lat: 49.1778762, lng: 21.8136415 },
    { lat: 49.1780511, lng: 21.8149171 },
    { lat: 49.1783513, lng: 21.8159173 },
    { lat: 49.178564, lng: 21.8170093 },
    { lat: 49.1786842, lng: 21.8173088 },
    { lat: 49.178881, lng: 21.8193579 },
    { lat: 49.1790506, lng: 21.8196066 },
    { lat: 49.179076, lng: 21.8205931 },
    { lat: 49.1792847, lng: 21.8219702 },
    { lat: 49.179191, lng: 21.8223929 },
    { lat: 49.1792083, lng: 21.822591 },
    { lat: 49.1794788, lng: 21.8233083 },
    { lat: 49.179579, lng: 21.8237053 },
    { lat: 49.1798806, lng: 21.8241834 },
    { lat: 49.1799193, lng: 21.8249085 },
    { lat: 49.1798954, lng: 21.8252921 },
    { lat: 49.1801243, lng: 21.8254395 },
    { lat: 49.180383, lng: 21.8260931 },
    { lat: 49.1802558, lng: 21.8267019 },
    { lat: 49.180056, lng: 21.8271625 },
    { lat: 49.1801145, lng: 21.8273741 },
    { lat: 49.1801285, lng: 21.8285335 },
    { lat: 49.1803607, lng: 21.8287292 },
    { lat: 49.1804929, lng: 21.8291506 },
    { lat: 49.1806177, lng: 21.8293197 },
    { lat: 49.1805656, lng: 21.8297417 },
    { lat: 49.18065, lng: 21.830272 },
    { lat: 49.1807812, lng: 21.8305615 },
    { lat: 49.181008, lng: 21.8307205 },
    { lat: 49.18116, lng: 21.8315995 },
    { lat: 49.1816882, lng: 21.8319308 },
    { lat: 49.1817571, lng: 21.8317231 },
    { lat: 49.1816912, lng: 21.8314765 },
    { lat: 49.181941, lng: 21.8310887 },
    { lat: 49.1821283, lng: 21.8306284 },
    { lat: 49.1822963, lng: 21.830422 },
    { lat: 49.1823411, lng: 21.830725 },
    { lat: 49.1825134, lng: 21.831084 },
    { lat: 49.1822928, lng: 21.8317357 },
    { lat: 49.1824563, lng: 21.8318824 },
    { lat: 49.1832887, lng: 21.8336751 },
    { lat: 49.1841548, lng: 21.8363094 },
    { lat: 49.1847203, lng: 21.8384539 },
    { lat: 49.1848576, lng: 21.8408682 },
    { lat: 49.1840129, lng: 21.8423644 },
    { lat: 49.1843355, lng: 21.8432351 },
    { lat: 49.1846797, lng: 21.8437195 },
    { lat: 49.1848171, lng: 21.8453103 },
    { lat: 49.185086, lng: 21.8456593 },
    { lat: 49.18525, lng: 21.846405 },
    { lat: 49.1860729, lng: 21.8475726 },
    { lat: 49.187566, lng: 21.8503653 },
    { lat: 49.1877349, lng: 21.8509518 },
    { lat: 49.1877502, lng: 21.8515525 },
    { lat: 49.1879614, lng: 21.8524667 },
    { lat: 49.1879834, lng: 21.8531917 },
    { lat: 49.1881589, lng: 21.8534996 },
    { lat: 49.1882971, lng: 21.8544306 },
    { lat: 49.1878732, lng: 21.8553591 },
    { lat: 49.1879984, lng: 21.8556839 },
    { lat: 49.1882662, lng: 21.8567425 },
    { lat: 49.1882622, lng: 21.8575202 },
    { lat: 49.1883314, lng: 21.8578513 },
    { lat: 49.1882636, lng: 21.8581505 },
    { lat: 49.1884592, lng: 21.8585377 },
    { lat: 49.1887195, lng: 21.8588625 },
    { lat: 49.1890881, lng: 21.8591582 },
    { lat: 49.1895788, lng: 21.859351 },
    { lat: 49.1900931, lng: 21.8594062 },
    { lat: 49.1907989, lng: 21.8592917 },
    { lat: 49.1911376, lng: 21.8593846 },
    { lat: 49.1912367, lng: 21.8594812 },
    { lat: 49.1916068, lng: 21.8593511 },
    { lat: 49.1917909, lng: 21.8594251 },
    { lat: 49.1926974, lng: 21.8594185 },
    { lat: 49.1932178, lng: 21.8592658 },
    { lat: 49.1936432, lng: 21.8592363 },
    { lat: 49.1943752, lng: 21.8589189 },
    { lat: 49.1947967, lng: 21.8582814 },
    { lat: 49.1954378, lng: 21.8577069 },
    { lat: 49.1968092, lng: 21.8560791 },
    { lat: 49.1974934, lng: 21.8557799 },
    { lat: 49.1981692, lng: 21.855692 },
    { lat: 49.1987835, lng: 21.8549899 },
    { lat: 49.1996667, lng: 21.8542494 },
    { lat: 49.2003408, lng: 21.8550522 },
    { lat: 49.2002693, lng: 21.8557815 },
    { lat: 49.2004506, lng: 21.8559672 },
    { lat: 49.2011329, lng: 21.8559487 },
    { lat: 49.2013279, lng: 21.8557828 },
    { lat: 49.2015921, lng: 21.8562164 },
    { lat: 49.2019283, lng: 21.8565748 },
    { lat: 49.2022783, lng: 21.8567102 },
    { lat: 49.2032148, lng: 21.8566936 },
    { lat: 49.2038781, lng: 21.8565014 },
    { lat: 49.2043482, lng: 21.85659 },
    { lat: 49.2053661, lng: 21.8567124 },
    { lat: 49.2068045, lng: 21.8563602 },
    { lat: 49.2069493, lng: 21.8559462 },
    { lat: 49.2071044, lng: 21.8546578 },
    { lat: 49.2072258, lng: 21.8524937 },
    { lat: 49.2079443, lng: 21.8503613 },
    { lat: 49.2094575, lng: 21.8502887 },
    { lat: 49.2099927, lng: 21.8507274 },
    { lat: 49.2111239, lng: 21.8499144 },
    { lat: 49.2145981, lng: 21.8488423 },
    { lat: 49.2162454, lng: 21.8485181 },
    { lat: 49.2164639, lng: 21.8475085 },
    { lat: 49.2168766, lng: 21.8451272 },
    { lat: 49.2171117, lng: 21.8441173 },
    { lat: 49.2175529, lng: 21.8438186 },
    { lat: 49.2181519, lng: 21.8438416 },
    { lat: 49.2184213, lng: 21.8436489 },
    { lat: 49.2188478, lng: 21.8435213 },
    { lat: 49.2193053, lng: 21.8431551 },
    { lat: 49.2196745, lng: 21.8429899 },
    { lat: 49.2202583, lng: 21.8425456 },
    { lat: 49.2210875, lng: 21.8415901 },
    { lat: 49.221184, lng: 21.8408363 },
    { lat: 49.2214317, lng: 21.8404934 },
    { lat: 49.2216866, lng: 21.8399477 },
    { lat: 49.2223598, lng: 21.8392671 },
    { lat: 49.2228907, lng: 21.8392696 },
    { lat: 49.2235522, lng: 21.8391348 },
    { lat: 49.2239466, lng: 21.838769 },
    { lat: 49.2249781, lng: 21.8380248 },
    { lat: 49.2257595, lng: 21.8380091 },
    { lat: 49.2261646, lng: 21.8377946 },
    { lat: 49.2267582, lng: 21.8369055 },
    { lat: 49.2282453, lng: 21.8368693 },
    { lat: 49.2289339, lng: 21.8362207 },
    { lat: 49.2291716, lng: 21.8347806 },
    { lat: 49.229777, lng: 21.8321578 },
    { lat: 49.2301213, lng: 21.8315088 },
    { lat: 49.2302188, lng: 21.8310099 },
    { lat: 49.2305171, lng: 21.8305865 },
    { lat: 49.2301621, lng: 21.8280603 },
    { lat: 49.2302881, lng: 21.8274024 },
    { lat: 49.2300454, lng: 21.8254904 },
    { lat: 49.230074, lng: 21.824461 },
    { lat: 49.229661, lng: 21.823894 },
    { lat: 49.229563, lng: 21.824124 },
    { lat: 49.229467, lng: 21.82403 },
    { lat: 49.2295, lng: 21.823846 },
    { lat: 49.229348, lng: 21.823713 },
    { lat: 49.229086, lng: 21.823785 },
    { lat: 49.229043, lng: 21.823672 },
    { lat: 49.228889, lng: 21.823638 },
    { lat: 49.2287, lng: 21.82374 },
    { lat: 49.228642, lng: 21.82356 },
    { lat: 49.228456, lng: 21.823668 },
    { lat: 49.228432, lng: 21.824085 },
    { lat: 49.228366, lng: 21.824095 },
    { lat: 49.228023, lng: 21.823784 },
    { lat: 49.227809, lng: 21.824284 },
    { lat: 49.227631, lng: 21.824256 },
    { lat: 49.227577, lng: 21.824114 },
    { lat: 49.226994, lng: 21.823984 },
    { lat: 49.226973, lng: 21.824437 },
    { lat: 49.226587, lng: 21.824586 },
    { lat: 49.226554, lng: 21.82415 },
    { lat: 49.226343, lng: 21.824029 },
    { lat: 49.225741, lng: 21.824024 },
    { lat: 49.225117, lng: 21.823836 },
    { lat: 49.225086, lng: 21.824185 },
    { lat: 49.224915, lng: 21.82411 },
    { lat: 49.224693, lng: 21.824261 },
    { lat: 49.224539, lng: 21.824167 },
    { lat: 49.224243, lng: 21.823852 },
    { lat: 49.224003, lng: 21.823991 },
    { lat: 49.223541, lng: 21.824071 },
    { lat: 49.223279, lng: 21.824338 },
    { lat: 49.223, lng: 21.824636 },
    { lat: 49.222853, lng: 21.824656 },
    { lat: 49.222742, lng: 21.824139 },
    { lat: 49.22259, lng: 21.824071 },
    { lat: 49.222069, lng: 21.824959 },
    { lat: 49.221866, lng: 21.824908 },
    { lat: 49.221639, lng: 21.824752 },
    { lat: 49.221172, lng: 21.824763 },
    { lat: 49.221006, lng: 21.824416 },
    { lat: 49.221134, lng: 21.823995 },
    { lat: 49.220958, lng: 21.823988 },
    { lat: 49.220738, lng: 21.824099 },
    { lat: 49.220212, lng: 21.823736 },
    { lat: 49.219832, lng: 21.823172 },
    { lat: 49.219569, lng: 21.822226 },
    { lat: 49.219816, lng: 21.822099 },
    { lat: 49.219975, lng: 21.821694 },
    { lat: 49.219981, lng: 21.82122 },
    { lat: 49.220063, lng: 21.821086 },
    { lat: 49.219831, lng: 21.820634 },
    { lat: 49.219866, lng: 21.82048 },
    { lat: 49.219735, lng: 21.820324 },
    { lat: 49.219689, lng: 21.81961 },
    { lat: 49.219817, lng: 21.819251 },
    { lat: 49.219485, lng: 21.818404 },
    { lat: 49.219166, lng: 21.818273 },
    { lat: 49.219075, lng: 21.817858 },
    { lat: 49.218971, lng: 21.817782 },
    { lat: 49.21887, lng: 21.81731 },
    { lat: 49.218762, lng: 21.817237 },
    { lat: 49.218679, lng: 21.817373 },
    { lat: 49.21843, lng: 21.817327 },
    { lat: 49.218402, lng: 21.81716 },
    { lat: 49.218226, lng: 21.817097 },
    { lat: 49.218042, lng: 21.817291 },
    { lat: 49.217926, lng: 21.817171 },
    { lat: 49.217731, lng: 21.817293 },
    { lat: 49.217691, lng: 21.817647 },
    { lat: 49.217568, lng: 21.817751 },
    { lat: 49.217019, lng: 21.817874 },
    { lat: 49.217045, lng: 21.817674 },
    { lat: 49.216773, lng: 21.816283 },
    { lat: 49.213774, lng: 21.817724 },
    { lat: 49.21374, lng: 21.817702 },
    { lat: 49.212542, lng: 21.817715 },
    { lat: 49.212514, lng: 21.818098 },
    { lat: 49.212525, lng: 21.818324 },
    { lat: 49.211403, lng: 21.817633 },
    { lat: 49.211357, lng: 21.817424 },
    { lat: 49.211337, lng: 21.816943 },
    { lat: 49.211244, lng: 21.81662 },
    { lat: 49.209618, lng: 21.816664 },
    { lat: 49.209096, lng: 21.816873 },
    { lat: 49.208995, lng: 21.815237 },
    { lat: 49.208981, lng: 21.815007 },
    { lat: 49.209343, lng: 21.813821 },
    { lat: 49.209353, lng: 21.813689 },
    { lat: 49.209467, lng: 21.813571 },
    { lat: 49.209624, lng: 21.81316 },
    { lat: 49.20992, lng: 21.812559 },
    { lat: 49.209905, lng: 21.812488 },
    { lat: 49.209839, lng: 21.812473 },
    { lat: 49.209828, lng: 21.812382 },
    { lat: 49.209677, lng: 21.812192 },
    { lat: 49.209666, lng: 21.811629 },
    { lat: 49.209651, lng: 21.810802 },
    { lat: 49.209686, lng: 21.810261 },
    { lat: 49.209892, lng: 21.809882 },
    { lat: 49.210026, lng: 21.809333 },
    { lat: 49.209961, lng: 21.808907 },
    { lat: 49.20996, lng: 21.808695 },
    { lat: 49.210057, lng: 21.808618 },
    { lat: 49.210098, lng: 21.808386 },
    { lat: 49.210063, lng: 21.808067 },
    { lat: 49.210129, lng: 21.808013 },
    { lat: 49.210147, lng: 21.807764 },
    { lat: 49.210243, lng: 21.807463 },
    { lat: 49.210215, lng: 21.807238 },
    { lat: 49.210246, lng: 21.807075 },
    { lat: 49.210553, lng: 21.80637 },
    { lat: 49.210556, lng: 21.806349 },
    { lat: 49.21059, lng: 21.806149 },
    { lat: 49.210737, lng: 21.804651 },
    { lat: 49.21037, lng: 21.80369 },
    { lat: 49.209897, lng: 21.80291 },
    { lat: 49.209554, lng: 21.802061 },
    { lat: 49.209221, lng: 21.800942 },
    { lat: 49.209241, lng: 21.800413 },
    { lat: 49.20889, lng: 21.799296 },
    { lat: 49.208734, lng: 21.797484 },
    { lat: 49.20895, lng: 21.797165 },
    { lat: 49.209051, lng: 21.796122 },
    { lat: 49.209106, lng: 21.795886 },
    { lat: 49.209138, lng: 21.795698 },
    { lat: 49.209047, lng: 21.794897 },
    { lat: 49.208905, lng: 21.794012 },
    { lat: 49.208901, lng: 21.793756 },
    { lat: 49.208758, lng: 21.793128 },
    { lat: 49.208574, lng: 21.792776 },
    { lat: 49.208483, lng: 21.792728 },
    { lat: 49.208529, lng: 21.792284 },
    { lat: 49.208588, lng: 21.792016 },
    { lat: 49.208568, lng: 21.791173 },
    { lat: 49.208434, lng: 21.790084 },
    { lat: 49.208365, lng: 21.789322 },
    { lat: 49.208192, lng: 21.788347 },
    { lat: 49.207744, lng: 21.786912 },
    { lat: 49.207394, lng: 21.7856 },
    { lat: 49.206878, lng: 21.785905 },
    { lat: 49.205077, lng: 21.786637 },
    { lat: 49.204422, lng: 21.786771 },
    { lat: 49.203978, lng: 21.786808 },
    { lat: 49.203755, lng: 21.786768 },
    { lat: 49.203441, lng: 21.78692 },
    { lat: 49.203013, lng: 21.787199 },
    { lat: 49.201927, lng: 21.787431 },
    { lat: 49.201244, lng: 21.78784 },
    { lat: 49.200844, lng: 21.787914 },
    { lat: 49.200671, lng: 21.788249 },
    { lat: 49.199687, lng: 21.788702 },
    { lat: 49.199397, lng: 21.788825 },
    { lat: 49.199171, lng: 21.788971 },
    { lat: 49.19875, lng: 21.789429 },
    { lat: 49.198335, lng: 21.78942 },
    { lat: 49.197932, lng: 21.789685 },
    { lat: 49.197647, lng: 21.78983 },
    { lat: 49.197475, lng: 21.790088 },
    { lat: 49.197237, lng: 21.790103 },
    { lat: 49.196839, lng: 21.79025 },
    { lat: 49.196374, lng: 21.790557 },
    { lat: 49.195825, lng: 21.790873 },
    { lat: 49.195165, lng: 21.791163 },
    { lat: 49.194689, lng: 21.791493 },
    { lat: 49.194151, lng: 21.791816 },
    { lat: 49.19331, lng: 21.792131 },
    { lat: 49.193105, lng: 21.792218 },
    { lat: 49.192599, lng: 21.792355 },
    { lat: 49.191973, lng: 21.792278 },
    { lat: 49.191496, lng: 21.792457 },
    { lat: 49.190917, lng: 21.792965 },
    { lat: 49.190306, lng: 21.793408 },
    { lat: 49.189796, lng: 21.794144 },
    { lat: 49.189435, lng: 21.794406 },
    { lat: 49.189282, lng: 21.794448 },
    { lat: 49.188917, lng: 21.794952 },
    { lat: 49.188602, lng: 21.795463 },
    { lat: 49.187794, lng: 21.796309 },
    { lat: 49.187418, lng: 21.796811 },
    { lat: 49.186729, lng: 21.797484 },
    { lat: 49.186402, lng: 21.797829 },
    { lat: 49.186077, lng: 21.79782 },
    { lat: 49.185645, lng: 21.79814 },
    { lat: 49.185292, lng: 21.798211 },
    { lat: 49.18489, lng: 21.798536 },
    { lat: 49.1845, lng: 21.798737 },
    { lat: 49.183804, lng: 21.798947 },
    { lat: 49.183126, lng: 21.799443 },
    { lat: 49.182677, lng: 21.799746 },
    { lat: 49.182156, lng: 21.799997 },
    { lat: 49.181933, lng: 21.800376 },
    { lat: 49.181466, lng: 21.800703 },
    { lat: 49.180872, lng: 21.801073 },
    { lat: 49.180226, lng: 21.801561 },
    { lat: 49.179463, lng: 21.802088 },
    { lat: 49.179002, lng: 21.802394 },
    { lat: 49.178397, lng: 21.803203 },
    { lat: 49.178263, lng: 21.803298 },
    { lat: 49.175681, lng: 21.805135 },
  ],
  Zbojné: [
    { lat: 49.1665572, lng: 22.021761 },
    { lat: 49.166898, lng: 22.0217089 },
    { lat: 49.1669839, lng: 22.0215872 },
    { lat: 49.1676949, lng: 22.0215047 },
    { lat: 49.1679605, lng: 22.0213683 },
    { lat: 49.1684265, lng: 22.0196551 },
    { lat: 49.1690186, lng: 22.0180408 },
    { lat: 49.1692899, lng: 22.0167421 },
    { lat: 49.169214, lng: 22.0156446 },
    { lat: 49.1693039, lng: 22.0147597 },
    { lat: 49.1691567, lng: 22.0138765 },
    { lat: 49.1692658, lng: 22.0128971 },
    { lat: 49.1696243, lng: 22.0113935 },
    { lat: 49.1697445, lng: 22.0085461 },
    { lat: 49.1702175, lng: 22.0078696 },
    { lat: 49.1697042, lng: 22.0067404 },
    { lat: 49.1695141, lng: 22.0059064 },
    { lat: 49.1708327, lng: 22.0044832 },
    { lat: 49.171657, lng: 22.0038207 },
    { lat: 49.1732532, lng: 22.0023842 },
    { lat: 49.1734753, lng: 22.002738 },
    { lat: 49.1745505, lng: 22.0018036 },
    { lat: 49.1764823, lng: 21.9980728 },
    { lat: 49.1762013, lng: 21.9967765 },
    { lat: 49.1758298, lng: 21.9958823 },
    { lat: 49.1746148, lng: 21.9938803 },
    { lat: 49.1747717, lng: 21.9930719 },
    { lat: 49.1750199, lng: 21.9926377 },
    { lat: 49.1764116, lng: 21.9909837 },
    { lat: 49.1767068, lng: 21.9909616 },
    { lat: 49.1767711, lng: 21.9901337 },
    { lat: 49.1772261, lng: 21.9890553 },
    { lat: 49.1771075, lng: 21.9884318 },
    { lat: 49.1766128, lng: 21.9875133 },
    { lat: 49.1751419, lng: 21.9867895 },
    { lat: 49.1723445, lng: 21.9844655 },
    { lat: 49.1714971, lng: 21.9846376 },
    { lat: 49.1702993, lng: 21.9845491 },
    { lat: 49.1691729, lng: 21.9845684 },
    { lat: 49.1669657, lng: 21.9837919 },
    { lat: 49.1663025, lng: 21.9835586 },
    { lat: 49.1651397, lng: 21.982694 },
    { lat: 49.1650302, lng: 21.9826251 },
    { lat: 49.1643572, lng: 21.9822015 },
    { lat: 49.1630667, lng: 21.9810709 },
    { lat: 49.1618551, lng: 21.9800095 },
    { lat: 49.1611169, lng: 21.9790246 },
    { lat: 49.1608298, lng: 21.9765246 },
    { lat: 49.1606495, lng: 21.9761398 },
    { lat: 49.1586338, lng: 21.9741119 },
    { lat: 49.1579258, lng: 21.9728042 },
    { lat: 49.1567689, lng: 21.9711218 },
    { lat: 49.1560638, lng: 21.969519 },
    { lat: 49.1554259, lng: 21.9685071 },
    { lat: 49.1551317, lng: 21.9691207 },
    { lat: 49.1542165, lng: 21.9688225 },
    { lat: 49.1535866, lng: 21.9685875 },
    { lat: 49.152997, lng: 21.968072 },
    { lat: 49.1505269, lng: 21.9668783 },
    { lat: 49.1482579, lng: 21.9656711 },
    { lat: 49.1468307, lng: 21.9654482 },
    { lat: 49.1462699, lng: 21.9652811 },
    { lat: 49.1457222, lng: 21.964919 },
    { lat: 49.1450956, lng: 21.9634088 },
    { lat: 49.143462, lng: 21.9646764 },
    { lat: 49.1425053, lng: 21.9648298 },
    { lat: 49.1412612, lng: 21.9648257 },
    { lat: 49.1401048, lng: 21.964457 },
    { lat: 49.1387701, lng: 21.9637396 },
    { lat: 49.1378473, lng: 21.9649105 },
    { lat: 49.1369002, lng: 21.9655992 },
    { lat: 49.1362821, lng: 21.9661704 },
    { lat: 49.1350789, lng: 21.9670928 },
    { lat: 49.1342499, lng: 21.967967 },
    { lat: 49.1330792, lng: 21.9658642 },
    { lat: 49.132369, lng: 21.9647952 },
    { lat: 49.1322044, lng: 21.9650036 },
    { lat: 49.1314716, lng: 21.9668667 },
    { lat: 49.1305645, lng: 21.9696391 },
    { lat: 49.130099, lng: 21.970578 },
    { lat: 49.130336, lng: 21.970953 },
    { lat: 49.130367, lng: 21.971002 },
    { lat: 49.131098, lng: 21.972158 },
    { lat: 49.130753, lng: 21.973742 },
    { lat: 49.130116, lng: 21.975476 },
    { lat: 49.129632, lng: 21.977339 },
    { lat: 49.129258, lng: 21.979298 },
    { lat: 49.129116, lng: 21.98049 },
    { lat: 49.129058, lng: 21.981224 },
    { lat: 49.128959, lng: 21.98224 },
    { lat: 49.128961, lng: 21.983798 },
    { lat: 49.129712, lng: 21.983559 },
    { lat: 49.130319, lng: 21.983855 },
    { lat: 49.130986, lng: 21.984392 },
    { lat: 49.131399, lng: 21.984682 },
    { lat: 49.131198, lng: 21.985359 },
    { lat: 49.131166, lng: 21.985886 },
    { lat: 49.130991, lng: 21.986609 },
    { lat: 49.130732, lng: 21.986708 },
    { lat: 49.130449, lng: 21.987918 },
    { lat: 49.130144, lng: 21.989062 },
    { lat: 49.129868, lng: 21.990195 },
    { lat: 49.129843, lng: 21.990467 },
    { lat: 49.129274, lng: 21.991645 },
    { lat: 49.129259, lng: 21.991736 },
    { lat: 49.129218, lng: 21.991812 },
    { lat: 49.129216, lng: 21.991825 },
    { lat: 49.129217, lng: 21.991838 },
    { lat: 49.129376, lng: 21.992117 },
    { lat: 49.129575, lng: 21.992481 },
    { lat: 49.129577, lng: 21.992589 },
    { lat: 49.129583, lng: 21.992742 },
    { lat: 49.12964, lng: 21.993121 },
    { lat: 49.129686, lng: 21.99318 },
    { lat: 49.130281, lng: 21.993945 },
    { lat: 49.131418, lng: 21.994826 },
    { lat: 49.1316, lng: 21.995005 },
    { lat: 49.131748, lng: 21.996281 },
    { lat: 49.131779, lng: 21.99655 },
    { lat: 49.131792, lng: 21.996664 },
    { lat: 49.131793, lng: 21.996695 },
    { lat: 49.131795, lng: 21.996778 },
    { lat: 49.131795, lng: 21.996792 },
    { lat: 49.131796, lng: 21.996871 },
    { lat: 49.13175, lng: 21.998742 },
    { lat: 49.131729, lng: 21.999864 },
    { lat: 49.131428, lng: 22.002514 },
    { lat: 49.130742, lng: 22.006344 },
    { lat: 49.130422, lng: 22.008148 },
    { lat: 49.130354, lng: 22.008722 },
    { lat: 49.130264, lng: 22.010011 },
    { lat: 49.13018, lng: 22.010553 },
    { lat: 49.129952, lng: 22.012149 },
    { lat: 49.129456, lng: 22.013434 },
    { lat: 49.128596, lng: 22.015663 },
    { lat: 49.129481, lng: 22.016343 },
    { lat: 49.129722, lng: 22.017421 },
    { lat: 49.130218, lng: 22.018543 },
    { lat: 49.130609, lng: 22.019584 },
    { lat: 49.131519, lng: 22.020901 },
    { lat: 49.132342, lng: 22.022617 },
    { lat: 49.133163, lng: 22.024103 },
    { lat: 49.133628, lng: 22.024871 },
    { lat: 49.134593, lng: 22.025832 },
    { lat: 49.135272, lng: 22.027078 },
    { lat: 49.135364, lng: 22.029039 },
    { lat: 49.135896, lng: 22.031096 },
    { lat: 49.136291, lng: 22.032404 },
    { lat: 49.136583, lng: 22.034767 },
    { lat: 49.137281, lng: 22.036427 },
    { lat: 49.137938, lng: 22.037644 },
    { lat: 49.138372, lng: 22.038656 },
    { lat: 49.138932, lng: 22.039209 },
    { lat: 49.13937, lng: 22.0409 },
    { lat: 49.1399186, lng: 22.0396997 },
    { lat: 49.1414993, lng: 22.0384559 },
    { lat: 49.1427473, lng: 22.0383908 },
    { lat: 49.1438814, lng: 22.0374638 },
    { lat: 49.1443511, lng: 22.036647 },
    { lat: 49.1449204, lng: 22.0359007 },
    { lat: 49.1456677, lng: 22.0352182 },
    { lat: 49.1465333, lng: 22.0351966 },
    { lat: 49.1473415, lng: 22.0349436 },
    { lat: 49.1483796, lng: 22.0316544 },
    { lat: 49.1486989, lng: 22.0302469 },
    { lat: 49.1486441, lng: 22.0291326 },
    { lat: 49.1493002, lng: 22.027648 },
    { lat: 49.1500862, lng: 22.0273613 },
    { lat: 49.1502053, lng: 22.0265117 },
    { lat: 49.1503755, lng: 22.0260603 },
    { lat: 49.1504378, lng: 22.0248582 },
    { lat: 49.1503707, lng: 22.0243611 },
    { lat: 49.1504047, lng: 22.023863 },
    { lat: 49.1506666, lng: 22.023014 },
    { lat: 49.1507925, lng: 22.0223973 },
    { lat: 49.150884, lng: 22.022233 },
    { lat: 49.1508382, lng: 22.0217785 },
    { lat: 49.1511642, lng: 22.0210183 },
    { lat: 49.1514577, lng: 22.0201295 },
    { lat: 49.1519218, lng: 22.0195024 },
    { lat: 49.151866, lng: 22.0192888 },
    { lat: 49.1522075, lng: 22.0181551 },
    { lat: 49.1521305, lng: 22.0179913 },
    { lat: 49.1521562, lng: 22.0178468 },
    { lat: 49.1520566, lng: 22.0175836 },
    { lat: 49.1521991, lng: 22.0172372 },
    { lat: 49.1524349, lng: 22.0168528 },
    { lat: 49.1524941, lng: 22.0165595 },
    { lat: 49.1526221, lng: 22.0165049 },
    { lat: 49.1527089, lng: 22.0163215 },
    { lat: 49.1527178, lng: 22.0160353 },
    { lat: 49.1528812, lng: 22.0156363 },
    { lat: 49.1530352, lng: 22.0155335 },
    { lat: 49.153089, lng: 22.015293 },
    { lat: 49.1532974, lng: 22.0151659 },
    { lat: 49.1533618, lng: 22.0149439 },
    { lat: 49.1534426, lng: 22.0148463 },
    { lat: 49.1534995, lng: 22.01487 },
    { lat: 49.1536379, lng: 22.0146833 },
    { lat: 49.1536675, lng: 22.0145153 },
    { lat: 49.1538516, lng: 22.0144079 },
    { lat: 49.1544678, lng: 22.0132792 },
    { lat: 49.1546681, lng: 22.0131002 },
    { lat: 49.1547728, lng: 22.0129186 },
    { lat: 49.1549136, lng: 22.0128356 },
    { lat: 49.1551272, lng: 22.0128782 },
    { lat: 49.1552737, lng: 22.0126583 },
    { lat: 49.1556374, lng: 22.0125812 },
    { lat: 49.1560463, lng: 22.0120907 },
    { lat: 49.1593804, lng: 22.0143383 },
    { lat: 49.160186, lng: 22.0150029 },
    { lat: 49.1622218, lng: 22.0160844 },
    { lat: 49.162972, lng: 22.0168298 },
    { lat: 49.1636468, lng: 22.0177124 },
    { lat: 49.1640778, lng: 22.0187927 },
    { lat: 49.1643046, lng: 22.0196673 },
    { lat: 49.1649876, lng: 22.0204683 },
    { lat: 49.1651834, lng: 22.0204282 },
    { lat: 49.1657314, lng: 22.0207232 },
    { lat: 49.1665572, lng: 22.021761 },
  ],
  Svetlice: [
    { lat: 49.20527, lng: 22.103437 },
    { lat: 49.205547, lng: 22.102204 },
    { lat: 49.205712, lng: 22.100878 },
    { lat: 49.205839, lng: 22.099792 },
    { lat: 49.206011, lng: 22.09913 },
    { lat: 49.206454, lng: 22.098129 },
    { lat: 49.206398, lng: 22.097162 },
    { lat: 49.206307, lng: 22.095985 },
    { lat: 49.2060736, lng: 22.0951413 },
    { lat: 49.2057938, lng: 22.0943622 },
    { lat: 49.2058511, lng: 22.0936306 },
    { lat: 49.206115, lng: 22.092604 },
    { lat: 49.206333, lng: 22.091759 },
    { lat: 49.2065876, lng: 22.0910035 },
    { lat: 49.2068751, lng: 22.0902183 },
    { lat: 49.207197, lng: 22.08945 },
    { lat: 49.207567, lng: 22.088792 },
    { lat: 49.207907, lng: 22.088179 },
    { lat: 49.2080217, lng: 22.0872734 },
    { lat: 49.2079498, lng: 22.0867689 },
    { lat: 49.2078663, lng: 22.0851864 },
    { lat: 49.2081878, lng: 22.08413 },
    { lat: 49.2081778, lng: 22.083272 },
    { lat: 49.208101, lng: 22.08222 },
    { lat: 49.208276, lng: 22.081441 },
    { lat: 49.208492, lng: 22.080467 },
    { lat: 49.208607, lng: 22.079893 },
    { lat: 49.208676, lng: 22.079233 },
    { lat: 49.208836, lng: 22.07795 },
    { lat: 49.2091415, lng: 22.0771103 },
    { lat: 49.2094102, lng: 22.0763945 },
    { lat: 49.209726, lng: 22.0757272 },
    { lat: 49.2099496, lng: 22.0750903 },
    { lat: 49.2101933, lng: 22.0739058 },
    { lat: 49.2105475, lng: 22.0727188 },
    { lat: 49.2107564, lng: 22.0718377 },
    { lat: 49.2109962, lng: 22.0710974 },
    { lat: 49.2111695, lng: 22.0704322 },
    { lat: 49.2113591, lng: 22.0699872 },
    { lat: 49.2115287, lng: 22.0695338 },
    { lat: 49.2118058, lng: 22.0688811 },
    { lat: 49.2120115, lng: 22.068117 },
    { lat: 49.212216, lng: 22.067642 },
    { lat: 49.212104, lng: 22.066353 },
    { lat: 49.212088, lng: 22.065295 },
    { lat: 49.2121513, lng: 22.0643254 },
    { lat: 49.2117796, lng: 22.0631467 },
    { lat: 49.2116131, lng: 22.0621528 },
    { lat: 49.2112025, lng: 22.0623422 },
    { lat: 49.2103206, lng: 22.0621675 },
    { lat: 49.2084478, lng: 22.0619273 },
    { lat: 49.2071081, lng: 22.0614451 },
    { lat: 49.2063405, lng: 22.0612364 },
    { lat: 49.2038799, lng: 22.0615323 },
    { lat: 49.2021408, lng: 22.0592419 },
    { lat: 49.2020648, lng: 22.0590673 },
    { lat: 49.2017257, lng: 22.0586641 },
    { lat: 49.2011787, lng: 22.0582664 },
    { lat: 49.2006177, lng: 22.0574292 },
    { lat: 49.1999122, lng: 22.0569352 },
    { lat: 49.199079, lng: 22.0561904 },
    { lat: 49.1985093, lng: 22.0550264 },
    { lat: 49.1967601, lng: 22.0534473 },
    { lat: 49.1941737, lng: 22.0507715 },
    { lat: 49.1936551, lng: 22.0491115 },
    { lat: 49.1930795, lng: 22.0487928 },
    { lat: 49.1925514, lng: 22.0487649 },
    { lat: 49.1925376, lng: 22.0476743 },
    { lat: 49.1923334, lng: 22.0465604 },
    { lat: 49.192409, lng: 22.045768 },
    { lat: 49.1920678, lng: 22.0450371 },
    { lat: 49.1913268, lng: 22.0449578 },
    { lat: 49.1906642, lng: 22.043717 },
    { lat: 49.1905802, lng: 22.0430719 },
    { lat: 49.1907542, lng: 22.0418803 },
    { lat: 49.1907695, lng: 22.0410207 },
    { lat: 49.1906004, lng: 22.0397334 },
    { lat: 49.1899253, lng: 22.0361004 },
    { lat: 49.1896435, lng: 22.0348914 },
    { lat: 49.1890339, lng: 22.0339976 },
    { lat: 49.1902503, lng: 22.0316285 },
    { lat: 49.1910154, lng: 22.028918 },
    { lat: 49.1913373, lng: 22.0281758 },
    { lat: 49.1915322, lng: 22.0278607 },
    { lat: 49.1924349, lng: 22.0267617 },
    { lat: 49.1927664, lng: 22.0261876 },
    { lat: 49.1926566, lng: 22.0252282 },
    { lat: 49.1931988, lng: 22.024291 },
    { lat: 49.1940108, lng: 22.0223216 },
    { lat: 49.1946089, lng: 22.0192217 },
    { lat: 49.1945755, lng: 22.0183026 },
    { lat: 49.1951699, lng: 22.0157566 },
    { lat: 49.1940882, lng: 22.0158612 },
    { lat: 49.1931498, lng: 22.0152888 },
    { lat: 49.1924812, lng: 22.0137243 },
    { lat: 49.1908371, lng: 22.0088427 },
    { lat: 49.1901855, lng: 22.0071961 },
    { lat: 49.1896826, lng: 22.0079389 },
    { lat: 49.190078, lng: 22.0090763 },
    { lat: 49.1895413, lng: 22.0098569 },
    { lat: 49.1891058, lng: 22.0087861 },
    { lat: 49.1885, lng: 22.0096795 },
    { lat: 49.1884182, lng: 22.0095126 },
    { lat: 49.1883291, lng: 22.009641 },
    { lat: 49.1879966, lng: 22.0090948 },
    { lat: 49.1870067, lng: 22.0109123 },
    { lat: 49.1855898, lng: 22.0123686 },
    { lat: 49.1847415, lng: 22.0127762 },
    { lat: 49.1825683, lng: 22.0126724 },
    { lat: 49.1817524, lng: 22.0131259 },
    { lat: 49.1806493, lng: 22.0136016 },
    { lat: 49.1802096, lng: 22.0147185 },
    { lat: 49.180263, lng: 22.0160054 },
    { lat: 49.1789922, lng: 22.0174148 },
    { lat: 49.1784301, lng: 22.0195213 },
    { lat: 49.1779379, lng: 22.0204913 },
    { lat: 49.1778608, lng: 22.0206432 },
    { lat: 49.1775881, lng: 22.0209039 },
    { lat: 49.1770056, lng: 22.0214607 },
    { lat: 49.1755194, lng: 22.022434 },
    { lat: 49.1745852, lng: 22.0234748 },
    { lat: 49.1741485, lng: 22.0242131 },
    { lat: 49.1730545, lng: 22.0246086 },
    { lat: 49.172867, lng: 22.0248052 },
    { lat: 49.1729531, lng: 22.0248317 },
    { lat: 49.1721372, lng: 22.0251846 },
    { lat: 49.1710818, lng: 22.0258737 },
    { lat: 49.169749, lng: 22.0258741 },
    { lat: 49.1685884, lng: 22.024613 },
    { lat: 49.1678418, lng: 22.023359 },
    { lat: 49.1669001, lng: 22.0223643 },
    { lat: 49.1665572, lng: 22.021761 },
    { lat: 49.1657314, lng: 22.0207232 },
    { lat: 49.1651834, lng: 22.0204282 },
    { lat: 49.1649876, lng: 22.0204683 },
    { lat: 49.1643046, lng: 22.0196673 },
    { lat: 49.1640778, lng: 22.0187927 },
    { lat: 49.1636468, lng: 22.0177124 },
    { lat: 49.162972, lng: 22.0168298 },
    { lat: 49.1622218, lng: 22.0160844 },
    { lat: 49.160186, lng: 22.0150029 },
    { lat: 49.1593804, lng: 22.0143383 },
    { lat: 49.1560463, lng: 22.0120907 },
    { lat: 49.1556374, lng: 22.0125812 },
    { lat: 49.1552737, lng: 22.0126583 },
    { lat: 49.1551272, lng: 22.0128782 },
    { lat: 49.1549136, lng: 22.0128356 },
    { lat: 49.1547728, lng: 22.0129186 },
    { lat: 49.1546681, lng: 22.0131002 },
    { lat: 49.1544678, lng: 22.0132792 },
    { lat: 49.1538516, lng: 22.0144079 },
    { lat: 49.1536675, lng: 22.0145153 },
    { lat: 49.1536379, lng: 22.0146833 },
    { lat: 49.1534995, lng: 22.01487 },
    { lat: 49.1534426, lng: 22.0148463 },
    { lat: 49.1533618, lng: 22.0149439 },
    { lat: 49.1532974, lng: 22.0151659 },
    { lat: 49.153089, lng: 22.015293 },
    { lat: 49.1530352, lng: 22.0155335 },
    { lat: 49.1528812, lng: 22.0156363 },
    { lat: 49.1527178, lng: 22.0160353 },
    { lat: 49.1527089, lng: 22.0163215 },
    { lat: 49.1526221, lng: 22.0165049 },
    { lat: 49.1524941, lng: 22.0165595 },
    { lat: 49.1524349, lng: 22.0168528 },
    { lat: 49.1521991, lng: 22.0172372 },
    { lat: 49.1520566, lng: 22.0175836 },
    { lat: 49.1521562, lng: 22.0178468 },
    { lat: 49.1521305, lng: 22.0179913 },
    { lat: 49.1522075, lng: 22.0181551 },
    { lat: 49.151866, lng: 22.0192888 },
    { lat: 49.1519218, lng: 22.0195024 },
    { lat: 49.1514577, lng: 22.0201295 },
    { lat: 49.1511642, lng: 22.0210183 },
    { lat: 49.1508382, lng: 22.0217785 },
    { lat: 49.150884, lng: 22.022233 },
    { lat: 49.1507925, lng: 22.0223973 },
    { lat: 49.1506666, lng: 22.023014 },
    { lat: 49.1504047, lng: 22.023863 },
    { lat: 49.1503707, lng: 22.0243611 },
    { lat: 49.1504378, lng: 22.0248582 },
    { lat: 49.1503755, lng: 22.0260603 },
    { lat: 49.1502053, lng: 22.0265117 },
    { lat: 49.1500862, lng: 22.0273613 },
    { lat: 49.1493002, lng: 22.027648 },
    { lat: 49.1486441, lng: 22.0291326 },
    { lat: 49.1486989, lng: 22.0302469 },
    { lat: 49.1483796, lng: 22.0316544 },
    { lat: 49.1473415, lng: 22.0349436 },
    { lat: 49.1465333, lng: 22.0351966 },
    { lat: 49.1456677, lng: 22.0352182 },
    { lat: 49.1449204, lng: 22.0359007 },
    { lat: 49.1443511, lng: 22.036647 },
    { lat: 49.1438814, lng: 22.0374638 },
    { lat: 49.1427473, lng: 22.0383908 },
    { lat: 49.1414993, lng: 22.0384559 },
    { lat: 49.1399186, lng: 22.0396997 },
    { lat: 49.13937, lng: 22.0409 },
    { lat: 49.139138, lng: 22.041781 },
    { lat: 49.13904, lng: 22.042606 },
    { lat: 49.138279, lng: 22.044578 },
    { lat: 49.138818, lng: 22.045421 },
    { lat: 49.139179, lng: 22.046278 },
    { lat: 49.140134, lng: 22.047486 },
    { lat: 49.140567, lng: 22.04853 },
    { lat: 49.140822, lng: 22.049897 },
    { lat: 49.141128, lng: 22.051428 },
    { lat: 49.141173, lng: 22.053208 },
    { lat: 49.14123, lng: 22.054429 },
    { lat: 49.141139, lng: 22.05535 },
    { lat: 49.142154, lng: 22.056031 },
    { lat: 49.141725, lng: 22.057459 },
    { lat: 49.142092, lng: 22.058795 },
    { lat: 49.142496, lng: 22.060203 },
    { lat: 49.1428, lng: 22.060963 },
    { lat: 49.142889, lng: 22.061004 },
    { lat: 49.14367, lng: 22.061361 },
    { lat: 49.144748, lng: 22.06188 },
    { lat: 49.145668, lng: 22.063881 },
    { lat: 49.146198, lng: 22.065034 },
    { lat: 49.147586, lng: 22.066087 },
    { lat: 49.148246, lng: 22.066624 },
    { lat: 49.148452, lng: 22.066899 },
    { lat: 49.149119, lng: 22.067785 },
    { lat: 49.14922, lng: 22.068168 },
    { lat: 49.149358, lng: 22.069066 },
    { lat: 49.14938, lng: 22.069864 },
    { lat: 49.14941, lng: 22.070975 },
    { lat: 49.149629, lng: 22.071944 },
    { lat: 49.150093, lng: 22.072641 },
    { lat: 49.1530132, lng: 22.0752871 },
    { lat: 49.157449, lng: 22.080281 },
    { lat: 49.158476, lng: 22.078913 },
    { lat: 49.15914, lng: 22.077829 },
    { lat: 49.159689, lng: 22.077062 },
    { lat: 49.160101, lng: 22.076365 },
    { lat: 49.160236, lng: 22.076451 },
    { lat: 49.160576, lng: 22.076469 },
    { lat: 49.163221, lng: 22.076642 },
    { lat: 49.164146, lng: 22.077639 },
    { lat: 49.165085, lng: 22.078715 },
    { lat: 49.165853, lng: 22.080643 },
    { lat: 49.166934, lng: 22.081681 },
    { lat: 49.168762, lng: 22.083244 },
    { lat: 49.169611, lng: 22.083828 },
    { lat: 49.172103, lng: 22.08397 },
    { lat: 49.173306, lng: 22.084145 },
    { lat: 49.173338, lng: 22.084149 },
    { lat: 49.173959, lng: 22.085812 },
    { lat: 49.174677, lng: 22.086342 },
    { lat: 49.175131, lng: 22.086599 },
    { lat: 49.17544, lng: 22.086813 },
    { lat: 49.177148, lng: 22.087114 },
    { lat: 49.178037, lng: 22.08755 },
    { lat: 49.178853, lng: 22.088287 },
    { lat: 49.180336, lng: 22.089362 },
    { lat: 49.181737, lng: 22.089829 },
    { lat: 49.182352, lng: 22.090987 },
    { lat: 49.183262, lng: 22.091767 },
    { lat: 49.183457, lng: 22.093361 },
    { lat: 49.18336, lng: 22.094689 },
    { lat: 49.183522, lng: 22.095257 },
    { lat: 49.183887, lng: 22.096135 },
    { lat: 49.183744, lng: 22.097474 },
    { lat: 49.184497, lng: 22.098082 },
    { lat: 49.184992, lng: 22.098426 },
    { lat: 49.185934, lng: 22.098601 },
    { lat: 49.186573, lng: 22.098666 },
    { lat: 49.187442, lng: 22.099465 },
    { lat: 49.18862, lng: 22.10049 },
    { lat: 49.189512, lng: 22.100631 },
    { lat: 49.190429, lng: 22.100766 },
    { lat: 49.191351, lng: 22.10081 },
    { lat: 49.192176, lng: 22.100622 },
    { lat: 49.193128, lng: 22.100068 },
    { lat: 49.193791, lng: 22.100032 },
    { lat: 49.194442, lng: 22.099831 },
    { lat: 49.195105, lng: 22.09937 },
    { lat: 49.195994, lng: 22.099667 },
    { lat: 49.196528, lng: 22.100444 },
    { lat: 49.197624, lng: 22.100653 },
    { lat: 49.198407, lng: 22.100912 },
    { lat: 49.198892, lng: 22.101816 },
    { lat: 49.199626, lng: 22.102698 },
    { lat: 49.200138, lng: 22.10351 },
    { lat: 49.200916, lng: 22.104711 },
    { lat: 49.201467, lng: 22.105316 },
    { lat: 49.20236, lng: 22.10568 },
    { lat: 49.203368, lng: 22.106183 },
    { lat: 49.204087, lng: 22.106737 },
    { lat: 49.204442, lng: 22.106363 },
    { lat: 49.204821, lng: 22.105669 },
    { lat: 49.205193, lng: 22.104888 },
    { lat: 49.20527, lng: 22.103437 },
  ],
  Sukov: [
    { lat: 49.2445539, lng: 21.8734748 },
    { lat: 49.2438978, lng: 21.8732835 },
    { lat: 49.2397733, lng: 21.8720805 },
    { lat: 49.2375196, lng: 21.8705451 },
    { lat: 49.236576, lng: 21.8687709 },
    { lat: 49.2358087, lng: 21.8675579 },
    { lat: 49.2342748, lng: 21.8646046 },
    { lat: 49.2340264, lng: 21.8639462 },
    { lat: 49.2338941, lng: 21.8598876 },
    { lat: 49.2337227, lng: 21.8588069 },
    { lat: 49.2326784, lng: 21.8576339 },
    { lat: 49.2320128, lng: 21.8559663 },
    { lat: 49.2319198, lng: 21.8548798 },
    { lat: 49.2314326, lng: 21.8551373 },
    { lat: 49.231027, lng: 21.854222 },
    { lat: 49.2301467, lng: 21.8541976 },
    { lat: 49.2287787, lng: 21.8544544 },
    { lat: 49.2280258, lng: 21.8539553 },
    { lat: 49.2271701, lng: 21.8535689 },
    { lat: 49.2265078, lng: 21.8537097 },
    { lat: 49.2257547, lng: 21.8537285 },
    { lat: 49.2254677, lng: 21.8532784 },
    { lat: 49.2252162, lng: 21.8514465 },
    { lat: 49.2248018, lng: 21.8514726 },
    { lat: 49.2245751, lng: 21.8508239 },
    { lat: 49.2236799, lng: 21.8503924 },
    { lat: 49.2233915, lng: 21.8488683 },
    { lat: 49.2228206, lng: 21.8471688 },
    { lat: 49.2208291, lng: 21.8471934 },
    { lat: 49.2193909, lng: 21.8480026 },
    { lat: 49.2173271, lng: 21.8484991 },
    { lat: 49.2162454, lng: 21.8485181 },
    { lat: 49.2145981, lng: 21.8488423 },
    { lat: 49.2111239, lng: 21.8499144 },
    { lat: 49.2099927, lng: 21.8507274 },
    { lat: 49.2094575, lng: 21.8502887 },
    { lat: 49.2079443, lng: 21.8503613 },
    { lat: 49.2072258, lng: 21.8524937 },
    { lat: 49.2071044, lng: 21.8546578 },
    { lat: 49.2069493, lng: 21.8559462 },
    { lat: 49.2068045, lng: 21.8563602 },
    { lat: 49.2053661, lng: 21.8567124 },
    { lat: 49.2054718, lng: 21.8572008 },
    { lat: 49.2061786, lng: 21.8584118 },
    { lat: 49.2069132, lng: 21.8591669 },
    { lat: 49.2067766, lng: 21.8613136 },
    { lat: 49.2066452, lng: 21.8655331 },
    { lat: 49.2060853, lng: 21.8696278 },
    { lat: 49.2056848, lng: 21.8714842 },
    { lat: 49.2072564, lng: 21.8738467 },
    { lat: 49.2086799, lng: 21.8779721 },
    { lat: 49.2087817, lng: 21.8793709 },
    { lat: 49.2091123, lng: 21.8811987 },
    { lat: 49.2099278, lng: 21.8840925 },
    { lat: 49.2095985, lng: 21.8857087 },
    { lat: 49.2100055, lng: 21.8867245 },
    { lat: 49.2102588, lng: 21.887136 },
    { lat: 49.210302, lng: 21.8883152 },
    { lat: 49.2123775, lng: 21.8880136 },
    { lat: 49.2147612, lng: 21.8886088 },
    { lat: 49.2171908, lng: 21.8862387 },
    { lat: 49.2197643, lng: 21.8859736 },
    { lat: 49.2204604, lng: 21.8862418 },
    { lat: 49.2215373, lng: 21.8870649 },
    { lat: 49.223246, lng: 21.8872467 },
    { lat: 49.2242427, lng: 21.8870755 },
    { lat: 49.224383, lng: 21.8869759 },
    { lat: 49.2250737, lng: 21.8865295 },
    { lat: 49.2261645, lng: 21.8868739 },
    { lat: 49.226841, lng: 21.8852385 },
    { lat: 49.2284968, lng: 21.883914 },
    { lat: 49.2302879, lng: 21.8837476 },
    { lat: 49.2314794, lng: 21.8824694 },
    { lat: 49.2330729, lng: 21.8830811 },
    { lat: 49.2344701, lng: 21.8821935 },
    { lat: 49.234657, lng: 21.8813518 },
    { lat: 49.2359975, lng: 21.8804969 },
    { lat: 49.2375641, lng: 21.8791107 },
    { lat: 49.2381203, lng: 21.8784642 },
    { lat: 49.2385064, lng: 21.8781222 },
    { lat: 49.2402535, lng: 21.8769315 },
    { lat: 49.2402861, lng: 21.8768417 },
    { lat: 49.2411926, lng: 21.8761706 },
    { lat: 49.2418497, lng: 21.8761294 },
    { lat: 49.2423542, lng: 21.8758916 },
    { lat: 49.2427984, lng: 21.8759259 },
    { lat: 49.2436081, lng: 21.8762856 },
    { lat: 49.244024, lng: 21.8743114 },
    { lat: 49.2445539, lng: 21.8734748 },
  ],
  Habura: [
    { lat: 49.331019, lng: 21.821753 },
    { lat: 49.330658, lng: 21.82187 },
    { lat: 49.330279, lng: 21.821888 },
    { lat: 49.329843, lng: 21.821944 },
    { lat: 49.329358, lng: 21.822065 },
    { lat: 49.329107, lng: 21.822192 },
    { lat: 49.328684, lng: 21.822348 },
    { lat: 49.328282, lng: 21.822569 },
    { lat: 49.327444, lng: 21.822767 },
    { lat: 49.326466, lng: 21.822929 },
    { lat: 49.326202, lng: 21.823026 },
    { lat: 49.325707, lng: 21.823472 },
    { lat: 49.325474, lng: 21.823693 },
    { lat: 49.325395, lng: 21.823886 },
    { lat: 49.325352, lng: 21.823884 },
    { lat: 49.325248, lng: 21.82388 },
    { lat: 49.32477, lng: 21.824246 },
    { lat: 49.324507, lng: 21.824416 },
    { lat: 49.323908, lng: 21.824728 },
    { lat: 49.323027, lng: 21.82578 },
    { lat: 49.322702, lng: 21.826012 },
    { lat: 49.322323, lng: 21.826352 },
    { lat: 49.322093, lng: 21.826522 },
    { lat: 49.321844, lng: 21.827005 },
    { lat: 49.321494, lng: 21.827344 },
    { lat: 49.321058, lng: 21.827676 },
    { lat: 49.320548, lng: 21.828055 },
    { lat: 49.320436, lng: 21.828199 },
    { lat: 49.32014, lng: 21.828469 },
    { lat: 49.319975, lng: 21.828616 },
    { lat: 49.319855, lng: 21.828781 },
    { lat: 49.319729, lng: 21.829306 },
    { lat: 49.31954, lng: 21.830282 },
    { lat: 49.319513, lng: 21.830431 },
    { lat: 49.31948, lng: 21.830623 },
    { lat: 49.319431, lng: 21.830903 },
    { lat: 49.319319, lng: 21.831561 },
    { lat: 49.319264, lng: 21.831908 },
    { lat: 49.319206, lng: 21.832238 },
    { lat: 49.319176, lng: 21.832334 },
    { lat: 49.319126, lng: 21.832638 },
    { lat: 49.319083, lng: 21.832753 },
    { lat: 49.318949, lng: 21.832967 },
    { lat: 49.318668, lng: 21.83347 },
    { lat: 49.318476, lng: 21.833627 },
    { lat: 49.318172, lng: 21.83398 },
    { lat: 49.318039, lng: 21.834203 },
    { lat: 49.317726, lng: 21.834597 },
    { lat: 49.317251, lng: 21.835234 },
    { lat: 49.316778, lng: 21.836005 },
    { lat: 49.316673, lng: 21.836133 },
    { lat: 49.316246, lng: 21.836571 },
    { lat: 49.316004, lng: 21.836848 },
    { lat: 49.315715, lng: 21.837115 },
    { lat: 49.3158, lng: 21.83727 },
    { lat: 49.315564, lng: 21.837572 },
    { lat: 49.314916, lng: 21.838508 },
    { lat: 49.314832, lng: 21.838659 },
    { lat: 49.314801, lng: 21.838743 },
    { lat: 49.314351, lng: 21.83941 },
    { lat: 49.314072, lng: 21.839756 },
    { lat: 49.31415, lng: 21.839885 },
    { lat: 49.313742, lng: 21.840368 },
    { lat: 49.313858, lng: 21.84059 },
    { lat: 49.313974, lng: 21.840854 },
    { lat: 49.313453, lng: 21.841684 },
    { lat: 49.312693, lng: 21.842187 },
    { lat: 49.312074, lng: 21.842569 },
    { lat: 49.311946, lng: 21.842623 },
    { lat: 49.311905, lng: 21.842636 },
    { lat: 49.311629, lng: 21.842728 },
    { lat: 49.311498, lng: 21.84281 },
    { lat: 49.311447, lng: 21.84285 },
    { lat: 49.311383, lng: 21.842904 },
    { lat: 49.31108, lng: 21.843076 },
    { lat: 49.310845, lng: 21.843246 },
    { lat: 49.310788, lng: 21.843291 },
    { lat: 49.310743, lng: 21.843334 },
    { lat: 49.310518, lng: 21.843536 },
    { lat: 49.310419, lng: 21.843546 },
    { lat: 49.310281, lng: 21.843663 },
    { lat: 49.310012, lng: 21.843783 },
    { lat: 49.309634, lng: 21.844062 },
    { lat: 49.309413, lng: 21.844118 },
    { lat: 49.309164, lng: 21.84425 },
    { lat: 49.308781, lng: 21.844399 },
    { lat: 49.308483, lng: 21.844474 },
    { lat: 49.307858, lng: 21.844543 },
    { lat: 49.307528, lng: 21.844579 },
    { lat: 49.307415, lng: 21.844659 },
    { lat: 49.307237, lng: 21.844893 },
    { lat: 49.307013, lng: 21.845223 },
    { lat: 49.306127, lng: 21.846195 },
    { lat: 49.305124, lng: 21.846761 },
    { lat: 49.305004, lng: 21.84815 },
    { lat: 49.305021, lng: 21.84826 },
    { lat: 49.304967, lng: 21.848414 },
    { lat: 49.304885, lng: 21.84901 },
    { lat: 49.304613, lng: 21.849027 },
    { lat: 49.304466, lng: 21.849889 },
    { lat: 49.304494, lng: 21.850507 },
    { lat: 49.304197, lng: 21.850501 },
    { lat: 49.30402, lng: 21.851276 },
    { lat: 49.303898, lng: 21.851219 },
    { lat: 49.303653, lng: 21.851954 },
    { lat: 49.302636, lng: 21.855495 },
    { lat: 49.302457, lng: 21.856745 },
    { lat: 49.3039471, lng: 21.8583256 },
    { lat: 49.3044557, lng: 21.8591306 },
    { lat: 49.3052602, lng: 21.8598488 },
    { lat: 49.3064986, lng: 21.8619427 },
    { lat: 49.3076905, lng: 21.8642781 },
    { lat: 49.3083542, lng: 21.8658446 },
    { lat: 49.3089155, lng: 21.8672626 },
    { lat: 49.3091373, lng: 21.8683825 },
    { lat: 49.3112402, lng: 21.872732 },
    { lat: 49.3113576, lng: 21.8730581 },
    { lat: 49.3121449, lng: 21.8744817 },
    { lat: 49.3134713, lng: 21.8766835 },
    { lat: 49.3145891, lng: 21.8782817 },
    { lat: 49.3149453, lng: 21.879339 },
    { lat: 49.3166041, lng: 21.8820543 },
    { lat: 49.3168589, lng: 21.8823465 },
    { lat: 49.3178268, lng: 21.8842068 },
    { lat: 49.3183856, lng: 21.8854458 },
    { lat: 49.3190077, lng: 21.8871535 },
    { lat: 49.3200187, lng: 21.8885384 },
    { lat: 49.3204662, lng: 21.8901886 },
    { lat: 49.3205283, lng: 21.8920349 },
    { lat: 49.3222701, lng: 21.8944458 },
    { lat: 49.3235821, lng: 21.8947348 },
    { lat: 49.3251852, lng: 21.8938035 },
    { lat: 49.3252551, lng: 21.8936769 },
    { lat: 49.3254364, lng: 21.8936864 },
    { lat: 49.3254771, lng: 21.8937392 },
    { lat: 49.3254323, lng: 21.8939282 },
    { lat: 49.3255253, lng: 21.8939621 },
    { lat: 49.3257612, lng: 21.8938556 },
    { lat: 49.3259597, lng: 21.8941515 },
    { lat: 49.3260244, lng: 21.894081 },
    { lat: 49.3261499, lng: 21.894153 },
    { lat: 49.3262824, lng: 21.8940972 },
    { lat: 49.3262406, lng: 21.8942259 },
    { lat: 49.3263193, lng: 21.8945426 },
    { lat: 49.3263892, lng: 21.8946355 },
    { lat: 49.3264293, lng: 21.8944838 },
    { lat: 49.3265391, lng: 21.8945124 },
    { lat: 49.3265477, lng: 21.8943186 },
    { lat: 49.3268055, lng: 21.8944574 },
    { lat: 49.3269113, lng: 21.8946474 },
    { lat: 49.327092, lng: 21.8945402 },
    { lat: 49.3272471, lng: 21.894671 },
    { lat: 49.3273748, lng: 21.8946044 },
    { lat: 49.327478, lng: 21.894903 },
    { lat: 49.3277722, lng: 21.8951147 },
    { lat: 49.328052, lng: 21.8950579 },
    { lat: 49.3282522, lng: 21.8954194 },
    { lat: 49.3284196, lng: 21.8953209 },
    { lat: 49.3286522, lng: 21.8955373 },
    { lat: 49.3288654, lng: 21.8954736 },
    { lat: 49.3289517, lng: 21.8955529 },
    { lat: 49.3290573, lng: 21.8954133 },
    { lat: 49.3291903, lng: 21.8955548 },
    { lat: 49.3293139, lng: 21.8954635 },
    { lat: 49.3294722, lng: 21.8956324 },
    { lat: 49.3295726, lng: 21.8955926 },
    { lat: 49.329741, lng: 21.8958546 },
    { lat: 49.3299283, lng: 21.8959248 },
    { lat: 49.330021, lng: 21.8962125 },
    { lat: 49.3301103, lng: 21.8963148 },
    { lat: 49.3303859, lng: 21.8968983 },
    { lat: 49.3306209, lng: 21.8975795 },
    { lat: 49.3307467, lng: 21.8976987 },
    { lat: 49.3307789, lng: 21.89796 },
    { lat: 49.3309799, lng: 21.8983424 },
    { lat: 49.3310729, lng: 21.8984235 },
    { lat: 49.3313851, lng: 21.8994402 },
    { lat: 49.3315168, lng: 21.8995331 },
    { lat: 49.3315067, lng: 21.8996257 },
    { lat: 49.3315754, lng: 21.899811 },
    { lat: 49.331844, lng: 21.9001551 },
    { lat: 49.3322793, lng: 21.9004283 },
    { lat: 49.3324544, lng: 21.900634 },
    { lat: 49.3324623, lng: 21.900736 },
    { lat: 49.3326189, lng: 21.9007874 },
    { lat: 49.3327162, lng: 21.9008978 },
    { lat: 49.3331298, lng: 21.9008866 },
    { lat: 49.3332742, lng: 21.9009993 },
    { lat: 49.3333566, lng: 21.9012278 },
    { lat: 49.3335999, lng: 21.9013376 },
    { lat: 49.3337177, lng: 21.9015987 },
    { lat: 49.3341208, lng: 21.9020158 },
    { lat: 49.3342375, lng: 21.9023191 },
    { lat: 49.3342828, lng: 21.9026901 },
    { lat: 49.3345455, lng: 21.9031663 },
    { lat: 49.3353123, lng: 21.9057697 },
    { lat: 49.3376465, lng: 21.9084036 },
    { lat: 49.3386256, lng: 21.9086054 },
    { lat: 49.3395311, lng: 21.9083891 },
    { lat: 49.3405942, lng: 21.908489 },
    { lat: 49.3409678, lng: 21.9084062 },
    { lat: 49.3415141, lng: 21.9083985 },
    { lat: 49.3420732, lng: 21.9120525 },
    { lat: 49.3422437, lng: 21.9170093 },
    { lat: 49.3423397, lng: 21.9181959 },
    { lat: 49.3424275, lng: 21.9181183 },
    { lat: 49.3426912, lng: 21.9180856 },
    { lat: 49.3427675, lng: 21.9178593 },
    { lat: 49.3427386, lng: 21.9177748 },
    { lat: 49.3428995, lng: 21.9176882 },
    { lat: 49.3429649, lng: 21.9175607 },
    { lat: 49.3431402, lng: 21.9174632 },
    { lat: 49.3433194, lng: 21.9174849 },
    { lat: 49.3437701, lng: 21.9172419 },
    { lat: 49.343865, lng: 21.9169936 },
    { lat: 49.3441207, lng: 21.9168698 },
    { lat: 49.344526, lng: 21.9169782 },
    { lat: 49.3456533, lng: 21.9162705 },
    { lat: 49.3456875, lng: 21.9160524 },
    { lat: 49.3458774, lng: 21.9158725 },
    { lat: 49.3465015, lng: 21.9155036 },
    { lat: 49.3470351, lng: 21.9154725 },
    { lat: 49.3471127, lng: 21.9152108 },
    { lat: 49.3472116, lng: 21.9150662 },
    { lat: 49.3484021, lng: 21.9144521 },
    { lat: 49.3485587, lng: 21.9140613 },
    { lat: 49.3491083, lng: 21.9136418 },
    { lat: 49.3495128, lng: 21.9134837 },
    { lat: 49.3495254, lng: 21.9132239 },
    { lat: 49.3501289, lng: 21.9124806 },
    { lat: 49.3506106, lng: 21.9119965 },
    { lat: 49.350858, lng: 21.9116379 },
    { lat: 49.3512402, lng: 21.9114919 },
    { lat: 49.35158, lng: 21.9111856 },
    { lat: 49.3519477, lng: 21.9110476 },
    { lat: 49.3520197, lng: 21.9108706 },
    { lat: 49.3522679, lng: 21.9107205 },
    { lat: 49.3528662, lng: 21.9097234 },
    { lat: 49.353571, lng: 21.9090104 },
    { lat: 49.3532751, lng: 21.9078851 },
    { lat: 49.3529832, lng: 21.9070401 },
    { lat: 49.3527641, lng: 21.9065229 },
    { lat: 49.3524262, lng: 21.9057259 },
    { lat: 49.3519735, lng: 21.9053469 },
    { lat: 49.3516572, lng: 21.9052259 },
    { lat: 49.3512292, lng: 21.9053689 },
    { lat: 49.3501433, lng: 21.9046719 },
    { lat: 49.3499643, lng: 21.9037674 },
    { lat: 49.3505056, lng: 21.9030815 },
    { lat: 49.3509854, lng: 21.9027412 },
    { lat: 49.3520583, lng: 21.9014809 },
    { lat: 49.352537, lng: 21.901231 },
    { lat: 49.3532519, lng: 21.9008992 },
    { lat: 49.3540891, lng: 21.9003663 },
    { lat: 49.3546276, lng: 21.8994886 },
    { lat: 49.3549163, lng: 21.8987895 },
    { lat: 49.355212, lng: 21.89784 },
    { lat: 49.3552286, lng: 21.8977883 },
    { lat: 49.3556044, lng: 21.8964643 },
    { lat: 49.3561699, lng: 21.8952222 },
    { lat: 49.3566852, lng: 21.8943865 },
    { lat: 49.3571921, lng: 21.8933504 },
    { lat: 49.3580086, lng: 21.8924217 },
    { lat: 49.3586234, lng: 21.8918252 },
    { lat: 49.3596434, lng: 21.89121 },
    { lat: 49.3604704, lng: 21.8913876 },
    { lat: 49.3610989, lng: 21.8907017 },
    { lat: 49.3617943, lng: 21.8898088 },
    { lat: 49.3622601, lng: 21.8893232 },
    { lat: 49.3628306, lng: 21.8896451 },
    { lat: 49.3635461, lng: 21.8903027 },
    { lat: 49.3643622, lng: 21.8909948 },
    { lat: 49.364392, lng: 21.890963 },
    { lat: 49.364971, lng: 21.890369 },
    { lat: 49.365257, lng: 21.890086 },
    { lat: 49.3662261, lng: 21.8891131 },
    { lat: 49.366588, lng: 21.889021 },
    { lat: 49.3672128, lng: 21.888864 },
    { lat: 49.367933, lng: 21.88869 },
    { lat: 49.3680266, lng: 21.888669 },
    { lat: 49.3689768, lng: 21.8873284 },
    { lat: 49.3696362, lng: 21.8857172 },
    { lat: 49.3696802, lng: 21.88559 },
    { lat: 49.3703597, lng: 21.8836267 },
    { lat: 49.3712082, lng: 21.8816902 },
    { lat: 49.3715081, lng: 21.8802711 },
    { lat: 49.3718304, lng: 21.8792854 },
    { lat: 49.3721626, lng: 21.8786549 },
    { lat: 49.372784, lng: 21.877307 },
    { lat: 49.3736309, lng: 21.875471 },
    { lat: 49.373544, lng: 21.874271 },
    { lat: 49.3734893, lng: 21.8731226 },
    { lat: 49.373387, lng: 21.871951 },
    { lat: 49.3732833, lng: 21.8707576 },
    { lat: 49.3723666, lng: 21.869924 },
    { lat: 49.372048, lng: 21.869084 },
    { lat: 49.371296, lng: 21.867188 },
    { lat: 49.370912, lng: 21.8661778 },
    { lat: 49.370452, lng: 21.865379 },
    { lat: 49.369878, lng: 21.86424 },
    { lat: 49.369477, lng: 21.863428 },
    { lat: 49.3689864, lng: 21.8619577 },
    { lat: 49.3688066, lng: 21.8604807 },
    { lat: 49.3685554, lng: 21.8589987 },
    { lat: 49.368982, lng: 21.858379 },
    { lat: 49.367765, lng: 21.8566066 },
    { lat: 49.3673487, lng: 21.8554866 },
    { lat: 49.3671732, lng: 21.8552682 },
    { lat: 49.366987, lng: 21.8551952 },
    { lat: 49.3662417, lng: 21.8545492 },
    { lat: 49.3650378, lng: 21.8539102 },
    { lat: 49.3645234, lng: 21.8534955 },
    { lat: 49.3640303, lng: 21.8533913 },
    { lat: 49.3636017, lng: 21.8534677 },
    { lat: 49.3626374, lng: 21.85421 },
    { lat: 49.3623754, lng: 21.8542585 },
    { lat: 49.3622142, lng: 21.854556 },
    { lat: 49.3620529, lng: 21.8546649 },
    { lat: 49.3618185, lng: 21.854957 },
    { lat: 49.3616384, lng: 21.8550024 },
    { lat: 49.3614109, lng: 21.8553407 },
    { lat: 49.3602589, lng: 21.8566632 },
    { lat: 49.3601746, lng: 21.8568446 },
    { lat: 49.3600813, lng: 21.8568899 },
    { lat: 49.3600174, lng: 21.8570756 },
    { lat: 49.3598247, lng: 21.8571902 },
    { lat: 49.3596272, lng: 21.8576581 },
    { lat: 49.3594016, lng: 21.8576743 },
    { lat: 49.3589986, lng: 21.8581031 },
    { lat: 49.3587427, lng: 21.8582132 },
    { lat: 49.3584313, lng: 21.85813 },
    { lat: 49.3581302, lng: 21.8582276 },
    { lat: 49.357964, lng: 21.8580199 },
    { lat: 49.3575407, lng: 21.8578249 },
    { lat: 49.3572738, lng: 21.8575886 },
    { lat: 49.3562553, lng: 21.85739 },
    { lat: 49.3561282, lng: 21.8569859 },
    { lat: 49.3554356, lng: 21.8568697 },
    { lat: 49.3551767, lng: 21.8567267 },
    { lat: 49.3544124, lng: 21.855545 },
    { lat: 49.3528618, lng: 21.8527692 },
    { lat: 49.3525642, lng: 21.8514617 },
    { lat: 49.3518127, lng: 21.8513636 },
    { lat: 49.3514885, lng: 21.8512201 },
    { lat: 49.3508182, lng: 21.8511388 },
    { lat: 49.3505423, lng: 21.8509846 },
    { lat: 49.3497299, lng: 21.8507999 },
    { lat: 49.3493261, lng: 21.8505267 },
    { lat: 49.3489896, lng: 21.8501224 },
    { lat: 49.3482255, lng: 21.8494435 },
    { lat: 49.34753, lng: 21.8496487 },
    { lat: 49.3473823, lng: 21.8499855 },
    { lat: 49.3468403, lng: 21.8495055 },
    { lat: 49.3458486, lng: 21.8489433 },
    { lat: 49.3450511, lng: 21.8483628 },
    { lat: 49.3445915, lng: 21.8479454 },
    { lat: 49.3438111, lng: 21.8470183 },
    { lat: 49.3441248, lng: 21.8460934 },
    { lat: 49.3439107, lng: 21.8455427 },
    { lat: 49.3437054, lng: 21.8453375 },
    { lat: 49.3433362, lng: 21.8447149 },
    { lat: 49.3428601, lng: 21.8444349 },
    { lat: 49.3422188, lng: 21.8439457 },
    { lat: 49.3405103, lng: 21.8414232 },
    { lat: 49.340577, lng: 21.8410444 },
    { lat: 49.3407205, lng: 21.8406723 },
    { lat: 49.3398823, lng: 21.8399115 },
    { lat: 49.3391535, lng: 21.8390335 },
    { lat: 49.3387267, lng: 21.8387466 },
    { lat: 49.3381129, lng: 21.8381442 },
    { lat: 49.3378902, lng: 21.8377871 },
    { lat: 49.3370092, lng: 21.8368555 },
    { lat: 49.3363627, lng: 21.8358944 },
    { lat: 49.3361693, lng: 21.8354115 },
    { lat: 49.3361816, lng: 21.834794 },
    { lat: 49.3354561, lng: 21.8334755 },
    { lat: 49.3351066, lng: 21.8329594 },
    { lat: 49.3346722, lng: 21.8321097 },
    { lat: 49.3342951, lng: 21.831217 },
    { lat: 49.3342443, lng: 21.8309914 },
    { lat: 49.3343079, lng: 21.8308801 },
    { lat: 49.3343118, lng: 21.8306433 },
    { lat: 49.3342572, lng: 21.8304453 },
    { lat: 49.3341584, lng: 21.8303618 },
    { lat: 49.3342038, lng: 21.8300258 },
    { lat: 49.3343046, lng: 21.8298737 },
    { lat: 49.3345884, lng: 21.8287072 },
    { lat: 49.3345415, lng: 21.828367 },
    { lat: 49.3345604, lng: 21.8273925 },
    { lat: 49.334246, lng: 21.8261447 },
    { lat: 49.3334359, lng: 21.8255171 },
    { lat: 49.3333001, lng: 21.8257896 },
    { lat: 49.333114, lng: 21.825944 },
    { lat: 49.3324747, lng: 21.8256163 },
    { lat: 49.331019, lng: 21.821753 },
  ],
  Čabalovce: [
    { lat: 49.1987724, lng: 21.965846 },
    { lat: 49.1997793, lng: 21.9684712 },
    { lat: 49.2006629, lng: 21.9681852 },
    { lat: 49.2012061, lng: 21.9687776 },
    { lat: 49.2017975, lng: 21.9688561 },
    { lat: 49.2023335, lng: 21.9687025 },
    { lat: 49.2029317, lng: 21.968695 },
    { lat: 49.2038127, lng: 21.9723844 },
    { lat: 49.2045265, lng: 21.9746275 },
    { lat: 49.2051994, lng: 21.9771678 },
    { lat: 49.2052812, lng: 21.9784056 },
    { lat: 49.20566, lng: 21.9789963 },
    { lat: 49.2057672, lng: 21.9806653 },
    { lat: 49.2057949, lng: 21.9819849 },
    { lat: 49.2066982, lng: 21.9825901 },
    { lat: 49.2077844, lng: 21.9853098 },
    { lat: 49.2084277, lng: 21.9859992 },
    { lat: 49.2090253, lng: 21.9870316 },
    { lat: 49.2094927, lng: 21.9865507 },
    { lat: 49.2097702, lng: 21.9874552 },
    { lat: 49.210868, lng: 21.9879286 },
    { lat: 49.211405, lng: 21.9899782 },
    { lat: 49.2117905, lng: 21.9909864 },
    { lat: 49.212761, lng: 21.9916808 },
    { lat: 49.2130471, lng: 21.99128 },
    { lat: 49.2134067, lng: 21.991037 },
    { lat: 49.2139866, lng: 21.9908279 },
    { lat: 49.2148852, lng: 21.9908748 },
    { lat: 49.2148433, lng: 21.9909995 },
    { lat: 49.2154294, lng: 21.9908153 },
    { lat: 49.2164058, lng: 21.9919393 },
    { lat: 49.2173311, lng: 21.9920161 },
    { lat: 49.2179953, lng: 21.9928219 },
    { lat: 49.2185302, lng: 21.9928967 },
    { lat: 49.2199585, lng: 21.9942389 },
    { lat: 49.2204151, lng: 21.9939605 },
    { lat: 49.2207072, lng: 21.993944 },
    { lat: 49.2209539, lng: 21.9941941 },
    { lat: 49.2210482, lng: 21.9943723 },
    { lat: 49.221166, lng: 21.9946877 },
    { lat: 49.2212019, lng: 21.9952372 },
    { lat: 49.2213886, lng: 21.9956088 },
    { lat: 49.2215265, lng: 21.9957734 },
    { lat: 49.2220816, lng: 21.9959921 },
    { lat: 49.2223227, lng: 21.9963807 },
    { lat: 49.2228277, lng: 21.9964241 },
    { lat: 49.2240399, lng: 21.9962714 },
    { lat: 49.2247328, lng: 21.9963194 },
    { lat: 49.2252691, lng: 21.9962196 },
    { lat: 49.2261416, lng: 21.9978828 },
    { lat: 49.2267617, lng: 21.9985938 },
    { lat: 49.2271638, lng: 21.9988003 },
    { lat: 49.2276475, lng: 21.9988485 },
    { lat: 49.228124, lng: 21.9993042 },
    { lat: 49.2285462, lng: 21.9999699 },
    { lat: 49.2292113, lng: 22.0001256 },
    { lat: 49.2297913, lng: 22.0001083 },
    { lat: 49.230358, lng: 22.0002379 },
    { lat: 49.2308126, lng: 22.0002554 },
    { lat: 49.2315033, lng: 22.0008178 },
    { lat: 49.2319794, lng: 22.0014798 },
    { lat: 49.2322939, lng: 22.0025284 },
    { lat: 49.2330217, lng: 22.003377 },
    { lat: 49.2333251, lng: 22.0039268 },
    { lat: 49.2337056, lng: 22.0040748 },
    { lat: 49.2340519, lng: 22.0043058 },
    { lat: 49.2343862, lng: 22.0050582 },
    { lat: 49.2348606, lng: 22.0054695 },
    { lat: 49.2352356, lng: 22.0057212 },
    { lat: 49.2362338, lng: 22.0053277 },
    { lat: 49.2366562, lng: 22.00531 },
    { lat: 49.2370531, lng: 22.0057203 },
    { lat: 49.237393, lng: 22.0063836 },
    { lat: 49.2377912, lng: 22.0066642 },
    { lat: 49.2379766, lng: 22.0067013 },
    { lat: 49.2387248, lng: 22.0066188 },
    { lat: 49.2400441, lng: 22.0053678 },
    { lat: 49.2406592, lng: 22.003911 },
    { lat: 49.2406742, lng: 22.0034188 },
    { lat: 49.2409117, lng: 22.0021712 },
    { lat: 49.2418501, lng: 22.0011618 },
    { lat: 49.2419907, lng: 22.0007489 },
    { lat: 49.2421023, lng: 22.0002934 },
    { lat: 49.24204, lng: 21.9988294 },
    { lat: 49.2425496, lng: 21.9962337 },
    { lat: 49.243053, lng: 21.9957948 },
    { lat: 49.243325, lng: 21.9952798 },
    { lat: 49.2436122, lng: 21.9952474 },
    { lat: 49.2443749, lng: 21.9947921 },
    { lat: 49.2460922, lng: 21.9923298 },
    { lat: 49.2477968, lng: 21.9897115 },
    { lat: 49.2479882, lng: 21.9887923 },
    { lat: 49.2487502, lng: 21.9879877 },
    { lat: 49.2489336, lng: 21.9867363 },
    { lat: 49.2490746, lng: 21.9862938 },
    { lat: 49.2494071, lng: 21.9859988 },
    { lat: 49.2496659, lng: 21.9855848 },
    { lat: 49.2500015, lng: 21.9853206 },
    { lat: 49.2503279, lng: 21.9848276 },
    { lat: 49.2507895, lng: 21.984522 },
    { lat: 49.2511668, lng: 21.9843573 },
    { lat: 49.2516912, lng: 21.984464 },
    { lat: 49.2522306, lng: 21.9847703 },
    { lat: 49.2530488, lng: 21.9848344 },
    { lat: 49.253774, lng: 21.9845455 },
    { lat: 49.2544119, lng: 21.9845093 },
    { lat: 49.2553216, lng: 21.983811 },
    { lat: 49.2572642, lng: 21.981317 },
    { lat: 49.2575086, lng: 21.9806413 },
    { lat: 49.2578163, lng: 21.9806627 },
    { lat: 49.258403, lng: 21.9800082 },
    { lat: 49.2589126, lng: 21.9800137 },
    { lat: 49.2593917, lng: 21.9797784 },
    { lat: 49.2585004, lng: 21.9758354 },
    { lat: 49.2576672, lng: 21.9745459 },
    { lat: 49.2571784, lng: 21.9738806 },
    { lat: 49.2570408, lng: 21.9727556 },
    { lat: 49.2558551, lng: 21.9706329 },
    { lat: 49.2554447, lng: 21.9695772 },
    { lat: 49.255073, lng: 21.9689115 },
    { lat: 49.2542104, lng: 21.9685459 },
    { lat: 49.2536303, lng: 21.9685517 },
    { lat: 49.2532242, lng: 21.968321 },
    { lat: 49.2530659, lng: 21.9679664 },
    { lat: 49.2528624, lng: 21.9669995 },
    { lat: 49.2525184, lng: 21.9661395 },
    { lat: 49.2519856, lng: 21.9643597 },
    { lat: 49.2521822, lng: 21.963161 },
    { lat: 49.252505, lng: 21.9626076 },
    { lat: 49.2528202, lng: 21.9613816 },
    { lat: 49.2529531, lng: 21.960651 },
    { lat: 49.2526103, lng: 21.9597428 },
    { lat: 49.2522518, lng: 21.9591156 },
    { lat: 49.2519046, lng: 21.958671 },
    { lat: 49.2515764, lng: 21.9580324 },
    { lat: 49.2510964, lng: 21.9579606 },
    { lat: 49.2507985, lng: 21.9577785 },
    { lat: 49.2499143, lng: 21.9563535 },
    { lat: 49.2495691, lng: 21.9561274 },
    { lat: 49.2492934, lng: 21.9558515 },
    { lat: 49.2489486, lng: 21.9557345 },
    { lat: 49.2485366, lng: 21.9553034 },
    { lat: 49.2483381, lng: 21.9548691 },
    { lat: 49.2482192, lng: 21.9539741 },
    { lat: 49.2478033, lng: 21.9531988 },
    { lat: 49.2474116, lng: 21.9527795 },
    { lat: 49.2468349, lng: 21.9524749 },
    { lat: 49.2454319, lng: 21.9514399 },
    { lat: 49.2449306, lng: 21.9489048 },
    { lat: 49.2447831, lng: 21.9473855 },
    { lat: 49.2446735, lng: 21.9468669 },
    { lat: 49.2449778, lng: 21.9465272 },
    { lat: 49.2449677, lng: 21.9455699 },
    { lat: 49.244223, lng: 21.943919 },
    { lat: 49.2437296, lng: 21.9429798 },
    { lat: 49.2421881, lng: 21.9394937 },
    { lat: 49.2416299, lng: 21.9396141 },
    { lat: 49.2410665, lng: 21.9388942 },
    { lat: 49.240941, lng: 21.9388226 },
    { lat: 49.2406455, lng: 21.93815 },
    { lat: 49.2399536, lng: 21.9376853 },
    { lat: 49.2400216, lng: 21.9374319 },
    { lat: 49.2392948, lng: 21.9365707 },
    { lat: 49.2372253, lng: 21.9379741 },
    { lat: 49.2370634, lng: 21.9383477 },
    { lat: 49.2365505, lng: 21.9379353 },
    { lat: 49.2359354, lng: 21.9374391 },
    { lat: 49.2355193, lng: 21.9369427 },
    { lat: 49.2349374, lng: 21.9364648 },
    { lat: 49.2346827, lng: 21.9356794 },
    { lat: 49.2340728, lng: 21.9342163 },
    { lat: 49.2333942, lng: 21.9331467 },
    { lat: 49.2320437, lng: 21.9347086 },
    { lat: 49.2315675, lng: 21.9355044 },
    { lat: 49.2305543, lng: 21.9358408 },
    { lat: 49.2294521, lng: 21.936604 },
    { lat: 49.2289357, lng: 21.9368724 },
    { lat: 49.2282671, lng: 21.9370366 },
    { lat: 49.2276725, lng: 21.9373728 },
    { lat: 49.2263339, lng: 21.9356803 },
    { lat: 49.2258098, lng: 21.93555 },
    { lat: 49.2248945, lng: 21.9370613 },
    { lat: 49.2244808, lng: 21.9365739 },
    { lat: 49.223062, lng: 21.9360537 },
    { lat: 49.2227025, lng: 21.9358395 },
    { lat: 49.2224646, lng: 21.9360454 },
    { lat: 49.2219122, lng: 21.936218 },
    { lat: 49.2208615, lng: 21.9363074 },
    { lat: 49.2201067, lng: 21.9363481 },
    { lat: 49.2197596, lng: 21.9362749 },
    { lat: 49.219141, lng: 21.9364992 },
    { lat: 49.2180046, lng: 21.9366176 },
    { lat: 49.2178377, lng: 21.9367388 },
    { lat: 49.2175495, lng: 21.937407 },
    { lat: 49.2169822, lng: 21.9369778 },
    { lat: 49.2167496, lng: 21.9369897 },
    { lat: 49.2165806, lng: 21.9371132 },
    { lat: 49.2163091, lng: 21.9370591 },
    { lat: 49.2161867, lng: 21.9371503 },
    { lat: 49.2155181, lng: 21.9373612 },
    { lat: 49.2153017, lng: 21.9374726 },
    { lat: 49.2151849, lng: 21.9376708 },
    { lat: 49.2149881, lng: 21.9376199 },
    { lat: 49.2147245, lng: 21.9378369 },
    { lat: 49.2137076, lng: 21.9383276 },
    { lat: 49.2133143, lng: 21.9387504 },
    { lat: 49.2132269, lng: 21.9389497 },
    { lat: 49.2130512, lng: 21.938985 },
    { lat: 49.2126242, lng: 21.9394539 },
    { lat: 49.2122462, lng: 21.9397718 },
    { lat: 49.2121474, lng: 21.9400552 },
    { lat: 49.2121516, lng: 21.9420383 },
    { lat: 49.2122086, lng: 21.9425693 },
    { lat: 49.2123536, lng: 21.9430685 },
    { lat: 49.2120156, lng: 21.9430488 },
    { lat: 49.2118617, lng: 21.9431929 },
    { lat: 49.2117122, lng: 21.9442887 },
    { lat: 49.2115225, lng: 21.9445349 },
    { lat: 49.2113971, lng: 21.9449191 },
    { lat: 49.2106094, lng: 21.9456343 },
    { lat: 49.210493, lng: 21.9455686 },
    { lat: 49.2102432, lng: 21.9461989 },
    { lat: 49.209864, lng: 21.9463362 },
    { lat: 49.2096897, lng: 21.9466552 },
    { lat: 49.2092672, lng: 21.94685 },
    { lat: 49.2091742, lng: 21.9471859 },
    { lat: 49.2085379, lng: 21.947651 },
    { lat: 49.2081467, lng: 21.9488936 },
    { lat: 49.2079761, lng: 21.9495923 },
    { lat: 49.2067525, lng: 21.9512809 },
    { lat: 49.2066379, lng: 21.9518817 },
    { lat: 49.206214, lng: 21.9528068 },
    { lat: 49.2059603, lng: 21.9531472 },
    { lat: 49.2045144, lng: 21.9557732 },
    { lat: 49.2040404, lng: 21.957491 },
    { lat: 49.203888, lng: 21.9591176 },
    { lat: 49.2036748, lng: 21.9595995 },
    { lat: 49.2032566, lng: 21.960199 },
    { lat: 49.2027696, lng: 21.9607092 },
    { lat: 49.202336, lng: 21.9609998 },
    { lat: 49.2016694, lng: 21.9621209 },
    { lat: 49.2010436, lng: 21.9626084 },
    { lat: 49.2006764, lng: 21.9633185 },
    { lat: 49.2000722, lng: 21.9639008 },
    { lat: 49.1997165, lng: 21.9644862 },
    { lat: 49.1997571, lng: 21.9654585 },
    { lat: 49.1993669, lng: 21.9656937 },
    { lat: 49.1987724, lng: 21.965846 },
  ],
};

export default UnitsMedzilaborce;
