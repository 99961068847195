const UnitsLevoča = {
  SpišskýHrhov: [
    { lat: 49.028685, lng: 20.6355804 },
    { lat: 49.028298, lng: 20.635118 },
    { lat: 49.0281028, lng: 20.6343228 },
    { lat: 49.0269568, lng: 20.6334229 },
    { lat: 49.0242267, lng: 20.6338533 },
    { lat: 49.023446, lng: 20.6334335 },
    { lat: 49.0229838, lng: 20.6328877 },
    { lat: 49.022943, lng: 20.6313534 },
    { lat: 49.0226479, lng: 20.6298485 },
    { lat: 49.022115, lng: 20.6291516 },
    { lat: 49.0210318, lng: 20.6284012 },
    { lat: 49.0204097, lng: 20.625639 },
    { lat: 49.0196047, lng: 20.6251671 },
    { lat: 49.0188567, lng: 20.6242813 },
    { lat: 49.0171604, lng: 20.6237632 },
    { lat: 49.0164941, lng: 20.624015 },
    { lat: 49.0162398, lng: 20.6236741 },
    { lat: 49.0161435, lng: 20.623751 },
    { lat: 49.0155643, lng: 20.624422 },
    { lat: 49.0150635, lng: 20.6244298 },
    { lat: 49.0149802, lng: 20.6246763 },
    { lat: 49.0144785, lng: 20.6251239 },
    { lat: 49.0133053, lng: 20.6258072 },
    { lat: 49.0131136, lng: 20.6258282 },
    { lat: 49.0128588, lng: 20.6256325 },
    { lat: 49.0123649, lng: 20.6261371 },
    { lat: 49.0119872, lng: 20.6261497 },
    { lat: 49.0116123, lng: 20.6259992 },
    { lat: 49.0115061, lng: 20.6261418 },
    { lat: 49.0115049, lng: 20.6264546 },
    { lat: 49.0110216, lng: 20.6268787 },
    { lat: 49.0109691, lng: 20.6268555 },
    { lat: 49.010857, lng: 20.6264044 },
    { lat: 49.0104918, lng: 20.6267049 },
    { lat: 49.0099762, lng: 20.6276079 },
    { lat: 49.0098794, lng: 20.6278524 },
    { lat: 49.0099195, lng: 20.6279702 },
    { lat: 49.0097617, lng: 20.6280565 },
    { lat: 49.0095888, lng: 20.6277576 },
    { lat: 49.0093365, lng: 20.6276621 },
    { lat: 49.0075496, lng: 20.62918 },
    { lat: 49.0070573, lng: 20.6272421 },
    { lat: 49.0070615, lng: 20.6266396 },
    { lat: 49.0069395, lng: 20.6264698 },
    { lat: 49.0071903, lng: 20.6241941 },
    { lat: 49.0069939, lng: 20.6240513 },
    { lat: 49.0066677, lng: 20.6240283 },
    { lat: 49.0073779, lng: 20.6229359 },
    { lat: 49.0077982, lng: 20.6221703 },
    { lat: 49.0077881, lng: 20.6197151 },
    { lat: 49.0084014, lng: 20.6181502 },
    { lat: 49.0081565, lng: 20.6177332 },
    { lat: 49.0081949, lng: 20.6167994 },
    { lat: 49.0080588, lng: 20.6163715 },
    { lat: 49.0080693, lng: 20.616068 },
    { lat: 49.0077117, lng: 20.6153334 },
    { lat: 49.0074769, lng: 20.6150386 },
    { lat: 49.0061279, lng: 20.6155245 },
    { lat: 49.0049968, lng: 20.6158444 },
    { lat: 49.0047062, lng: 20.6158081 },
    { lat: 49.00422, lng: 20.6156262 },
    { lat: 49.0039887, lng: 20.6153942 },
    { lat: 49.003766, lng: 20.6154336 },
    { lat: 49.001989, lng: 20.6168495 },
    { lat: 49.000619, lng: 20.6171341 },
    { lat: 49.0009816, lng: 20.6184093 },
    { lat: 48.9985243, lng: 20.6195008 },
    { lat: 48.9965912, lng: 20.6196557 },
    { lat: 48.9961449, lng: 20.6194535 },
    { lat: 48.9954544, lng: 20.6194087 },
    { lat: 48.9949328, lng: 20.619091 },
    { lat: 48.9949073, lng: 20.6202565 },
    { lat: 48.9943706, lng: 20.62079 },
    { lat: 48.9933895, lng: 20.6194671 },
    { lat: 48.993125, lng: 20.6194397 },
    { lat: 48.9925181, lng: 20.6186617 },
    { lat: 48.9913087, lng: 20.6179996 },
    { lat: 48.9901252, lng: 20.6176509 },
    { lat: 48.9890634, lng: 20.617833 },
    { lat: 48.9889394, lng: 20.6179197 },
    { lat: 48.9881506, lng: 20.6166859 },
    { lat: 48.987763, lng: 20.6163997 },
    { lat: 48.9868769, lng: 20.616366 },
    { lat: 48.9861203, lng: 20.6165802 },
    { lat: 48.9797561, lng: 20.6179435 },
    { lat: 48.9790695, lng: 20.6178823 },
    { lat: 48.978816, lng: 20.617891 },
    { lat: 48.9790868, lng: 20.6184256 },
    { lat: 48.9794944, lng: 20.6189835 },
    { lat: 48.97807, lng: 20.6208143 },
    { lat: 48.9771823, lng: 20.6214221 },
    { lat: 48.9770898, lng: 20.6219735 },
    { lat: 48.9775805, lng: 20.6228722 },
    { lat: 48.9772108, lng: 20.6236363 },
    { lat: 48.9774026, lng: 20.6242424 },
    { lat: 48.9769744, lng: 20.6246649 },
    { lat: 48.9770251, lng: 20.6248197 },
    { lat: 48.9765764, lng: 20.6252052 },
    { lat: 48.9767463, lng: 20.6257941 },
    { lat: 48.9766398, lng: 20.6258693 },
    { lat: 48.977238, lng: 20.6274715 },
    { lat: 48.9773833, lng: 20.6288413 },
    { lat: 48.9795675, lng: 20.6284985 },
    { lat: 48.979808, lng: 20.6297364 },
    { lat: 48.9797711, lng: 20.6297459 },
    { lat: 48.9801526, lng: 20.6316078 },
    { lat: 48.979629, lng: 20.6320085 },
    { lat: 48.9797838, lng: 20.632526 },
    { lat: 48.9800478, lng: 20.6330093 },
    { lat: 48.9804511, lng: 20.635039 },
    { lat: 48.9798674, lng: 20.635436 },
    { lat: 48.9799261, lng: 20.6357812 },
    { lat: 48.9801543, lng: 20.635711 },
    { lat: 48.9803731, lng: 20.6364679 },
    { lat: 48.9806105, lng: 20.6364948 },
    { lat: 48.9807234, lng: 20.6369423 },
    { lat: 48.9813945, lng: 20.6408486 },
    { lat: 48.9817601, lng: 20.6428087 },
    { lat: 48.9818378, lng: 20.6429965 },
    { lat: 48.9819501, lng: 20.643585 },
    { lat: 48.9824915, lng: 20.6466782 },
    { lat: 48.9827237, lng: 20.6484345 },
    { lat: 48.9830395, lng: 20.6497663 },
    { lat: 48.9831021, lng: 20.6503449 },
    { lat: 48.9833722, lng: 20.6512569 },
    { lat: 48.9835516, lng: 20.6516076 },
    { lat: 48.9840542, lng: 20.6530024 },
    { lat: 48.9842445, lng: 20.6537214 },
    { lat: 48.9844483, lng: 20.6540034 },
    { lat: 48.9845169, lng: 20.6558543 },
    { lat: 48.984532, lng: 20.6575305 },
    { lat: 48.9845013, lng: 20.6579552 },
    { lat: 48.9847566, lng: 20.6598039 },
    { lat: 48.9850289, lng: 20.6643771 },
    { lat: 48.9850969, lng: 20.6649926 },
    { lat: 48.9852257, lng: 20.6653808 },
    { lat: 48.9853058, lng: 20.665509 },
    { lat: 48.9861875, lng: 20.6657354 },
    { lat: 48.9878569, lng: 20.6658012 },
    { lat: 48.991918, lng: 20.665499 },
    { lat: 48.9933887, lng: 20.6655426 },
    { lat: 48.9937766, lng: 20.6655905 },
    { lat: 48.9957808, lng: 20.6663532 },
    { lat: 48.9961709, lng: 20.6627309 },
    { lat: 48.9978358, lng: 20.6629774 },
    { lat: 49.0016609, lng: 20.6630042 },
    { lat: 49.0019949, lng: 20.6629317 },
    { lat: 49.0034953, lng: 20.6611962 },
    { lat: 49.0058388, lng: 20.6591196 },
    { lat: 49.0059322, lng: 20.6589609 },
    { lat: 49.0063349, lng: 20.6590139 },
    { lat: 49.0062708, lng: 20.6566605 },
    { lat: 49.0059225, lng: 20.6565209 },
    { lat: 49.0058334, lng: 20.6562077 },
    { lat: 49.0056753, lng: 20.6552208 },
    { lat: 49.0074519, lng: 20.6544079 },
    { lat: 49.0081178, lng: 20.653861 },
    { lat: 49.0082983, lng: 20.6537761 },
    { lat: 49.0094627, lng: 20.6537236 },
    { lat: 49.0098809, lng: 20.6533339 },
    { lat: 49.0102389, lng: 20.6525181 },
    { lat: 49.0103562, lng: 20.6525256 },
    { lat: 49.0103765, lng: 20.652369 },
    { lat: 49.0103045, lng: 20.6518686 },
    { lat: 49.0107384, lng: 20.6508461 },
    { lat: 49.0113091, lng: 20.6489956 },
    { lat: 49.0113846, lng: 20.6479084 },
    { lat: 49.0112846, lng: 20.6473774 },
    { lat: 49.0126713, lng: 20.6467167 },
    { lat: 49.0128181, lng: 20.6465668 },
    { lat: 49.0127445, lng: 20.6454016 },
    { lat: 49.0127403, lng: 20.6444703 },
    { lat: 49.0141957, lng: 20.6445597 },
    { lat: 49.0142748, lng: 20.6443182 },
    { lat: 49.0148935, lng: 20.6443948 },
    { lat: 49.0149297, lng: 20.6442806 },
    { lat: 49.0151282, lng: 20.6441913 },
    { lat: 49.0149238, lng: 20.6433314 },
    { lat: 49.0149102, lng: 20.6420824 },
    { lat: 49.0159791, lng: 20.6417467 },
    { lat: 49.0164053, lng: 20.6412998 },
    { lat: 49.0171587, lng: 20.6406819 },
    { lat: 49.0173325, lng: 20.6406947 },
    { lat: 49.0174194, lng: 20.6404816 },
    { lat: 49.0176279, lng: 20.6408003 },
    { lat: 49.0181873, lng: 20.6404869 },
    { lat: 49.018377, lng: 20.6401874 },
    { lat: 49.0184882, lng: 20.6397452 },
    { lat: 49.0188282, lng: 20.6398055 },
    { lat: 49.0192167, lng: 20.6382456 },
    { lat: 49.0195608, lng: 20.6385182 },
    { lat: 49.0211287, lng: 20.6373829 },
    { lat: 49.0210972, lng: 20.6363859 },
    { lat: 49.0215653, lng: 20.6368845 },
    { lat: 49.022306, lng: 20.6369415 },
    { lat: 49.023757, lng: 20.6377191 },
    { lat: 49.0248064, lng: 20.63807 },
    { lat: 49.0263554, lng: 20.6379413 },
    { lat: 49.0267652, lng: 20.6381637 },
    { lat: 49.0278282, lng: 20.638512 },
    { lat: 49.0284876, lng: 20.6373486 },
    { lat: 49.0284977, lng: 20.6365686 },
    { lat: 49.0287592, lng: 20.6356693 },
    { lat: 49.028685, lng: 20.6355804 },
  ],
  Poľanovce: [
    { lat: 49.0456606, lng: 20.8254444 },
    { lat: 49.0445529, lng: 20.8258006 },
    { lat: 49.0435216, lng: 20.826319 },
    { lat: 49.0432347, lng: 20.8265216 },
    { lat: 49.0421643, lng: 20.8283523 },
    { lat: 49.0414371, lng: 20.8288471 },
    { lat: 49.0410241, lng: 20.8289165 },
    { lat: 49.0404873, lng: 20.8286213 },
    { lat: 49.0400756, lng: 20.8285625 },
    { lat: 49.039714, lng: 20.8286523 },
    { lat: 49.039033, lng: 20.8291782 },
    { lat: 49.0383721, lng: 20.829502 },
    { lat: 49.0380616, lng: 20.8295055 },
    { lat: 49.0370939, lng: 20.8297056 },
    { lat: 49.0366362, lng: 20.8299493 },
    { lat: 49.0359251, lng: 20.8297928 },
    { lat: 49.0355421, lng: 20.8298497 },
    { lat: 49.0343854, lng: 20.8304188 },
    { lat: 49.0340571, lng: 20.830729 },
    { lat: 49.0335993, lng: 20.8312928 },
    { lat: 49.0334651, lng: 20.8313207 },
    { lat: 49.0328967, lng: 20.8318088 },
    { lat: 49.0321811, lng: 20.8318844 },
    { lat: 49.0319139, lng: 20.8316746 },
    { lat: 49.0316649, lng: 20.8319641 },
    { lat: 49.0314531, lng: 20.8320871 },
    { lat: 49.0307585, lng: 20.8327751 },
    { lat: 49.0300981, lng: 20.8327838 },
    { lat: 49.0296481, lng: 20.832661 },
    { lat: 49.0290784, lng: 20.8326862 },
    { lat: 49.0285503, lng: 20.8329756 },
    { lat: 49.0281885, lng: 20.8328663 },
    { lat: 49.0276601, lng: 20.8328509 },
    { lat: 49.0266306, lng: 20.8331543 },
    { lat: 49.0246781, lng: 20.8339227 },
    { lat: 49.0240372, lng: 20.8340052 },
    { lat: 49.0231701, lng: 20.8342243 },
    { lat: 49.0226162, lng: 20.8345853 },
    { lat: 49.0219255, lng: 20.8348227 },
    { lat: 49.0216683, lng: 20.8351021 },
    { lat: 49.0206346, lng: 20.8351859 },
    { lat: 49.0199785, lng: 20.835163 },
    { lat: 49.0197106, lng: 20.8350599 },
    { lat: 49.0193649, lng: 20.8347595 },
    { lat: 49.0188344, lng: 20.834656 },
    { lat: 49.0179194, lng: 20.8348549 },
    { lat: 49.0176635, lng: 20.8348397 },
    { lat: 49.0171551, lng: 20.8349819 },
    { lat: 49.016691, lng: 20.834876 },
    { lat: 49.0164215, lng: 20.8350301 },
    { lat: 49.0162177, lng: 20.835255 },
    { lat: 49.0160783, lng: 20.8379843 },
    { lat: 49.0160441, lng: 20.8407546 },
    { lat: 49.0158315, lng: 20.8425351 },
    { lat: 49.0157702, lng: 20.8427146 },
    { lat: 49.015815, lng: 20.8429416 },
    { lat: 49.0167125, lng: 20.8433804 },
    { lat: 49.0170441, lng: 20.8437931 },
    { lat: 49.0170272, lng: 20.8438583 },
    { lat: 49.0177104, lng: 20.843925 },
    { lat: 49.018031, lng: 20.8437877 },
    { lat: 49.0182832, lng: 20.843818 },
    { lat: 49.0184806, lng: 20.8439608 },
    { lat: 49.0186336, lng: 20.8444207 },
    { lat: 49.0188764, lng: 20.8447063 },
    { lat: 49.0189079, lng: 20.845066 },
    { lat: 49.0190408, lng: 20.8454983 },
    { lat: 49.0190514, lng: 20.845807 },
    { lat: 49.0191873, lng: 20.8461922 },
    { lat: 49.0191957, lng: 20.8463428 },
    { lat: 49.0195898, lng: 20.8469073 },
    { lat: 49.0197088, lng: 20.8471568 },
    { lat: 49.0198936, lng: 20.8482507 },
    { lat: 49.0200086, lng: 20.8486454 },
    { lat: 49.0202939, lng: 20.8492218 },
    { lat: 49.0204386, lng: 20.8493423 },
    { lat: 49.0205169, lng: 20.8495907 },
    { lat: 49.0205012, lng: 20.8497526 },
    { lat: 49.0206691, lng: 20.8500567 },
    { lat: 49.0206931, lng: 20.8503288 },
    { lat: 49.0208037, lng: 20.8506662 },
    { lat: 49.0208962, lng: 20.8512334 },
    { lat: 49.0208664, lng: 20.8521076 },
    { lat: 49.0207763, lng: 20.8521706 },
    { lat: 49.0197023, lng: 20.8520364 },
    { lat: 49.0172345, lng: 20.8564956 },
    { lat: 49.0174374, lng: 20.858486 },
    { lat: 49.017323, lng: 20.858991 },
    { lat: 49.017098, lng: 20.8593229 },
    { lat: 49.016805, lng: 20.8603035 },
    { lat: 49.0166471, lng: 20.8612214 },
    { lat: 49.0166665, lng: 20.8617893 },
    { lat: 49.0167713, lng: 20.8620543 },
    { lat: 49.0170715, lng: 20.8622124 },
    { lat: 49.0179303, lng: 20.8631671 },
    { lat: 49.0186305, lng: 20.8636337 },
    { lat: 49.0188709, lng: 20.8640178 },
    { lat: 49.0193125, lng: 20.8645224 },
    { lat: 49.0193918, lng: 20.864698 },
    { lat: 49.0195039, lng: 20.8653153 },
    { lat: 49.0195107, lng: 20.8655909 },
    { lat: 49.01953, lng: 20.8659157 },
    { lat: 49.0195589, lng: 20.866701 },
    { lat: 49.0196167, lng: 20.8667782 },
    { lat: 49.0195723, lng: 20.867255 },
    { lat: 49.0196521, lng: 20.8677143 },
    { lat: 49.0196488, lng: 20.8687129 },
    { lat: 49.0199359, lng: 20.869906 },
    { lat: 49.0201657, lng: 20.8704689 },
    { lat: 49.0203623, lng: 20.8706597 },
    { lat: 49.0206354, lng: 20.871109 },
    { lat: 49.0210111, lng: 20.8714682 },
    { lat: 49.0214315, lng: 20.8721094 },
    { lat: 49.0221054, lng: 20.8726302 },
    { lat: 49.0223571, lng: 20.8730125 },
    { lat: 49.0224672, lng: 20.8733384 },
    { lat: 49.0228864, lng: 20.8742125 },
    { lat: 49.0229994, lng: 20.8746239 },
    { lat: 49.0232084, lng: 20.8763386 },
    { lat: 49.0232359, lng: 20.8790367 },
    { lat: 49.0232977, lng: 20.8795655 },
    { lat: 49.0238659, lng: 20.880755 },
    { lat: 49.024793, lng: 20.883254 },
    { lat: 49.0248, lng: 20.883253 },
    { lat: 49.025712, lng: 20.882488 },
    { lat: 49.026621, lng: 20.882005 },
    { lat: 49.026689, lng: 20.882003 },
    { lat: 49.026997, lng: 20.882059 },
    { lat: 49.027412, lng: 20.882432 },
    { lat: 49.028103, lng: 20.882897 },
    { lat: 49.028888, lng: 20.883099 },
    { lat: 49.029038, lng: 20.883151 },
    { lat: 49.029029, lng: 20.88318 },
    { lat: 49.029376, lng: 20.883466 },
    { lat: 49.029586, lng: 20.883597 },
    { lat: 49.029674, lng: 20.883689 },
    { lat: 49.029835, lng: 20.883749 },
    { lat: 49.029932, lng: 20.883746 },
    { lat: 49.03019, lng: 20.883591 },
    { lat: 49.030306, lng: 20.883567 },
    { lat: 49.030547, lng: 20.883455 },
    { lat: 49.030694, lng: 20.883426 },
    { lat: 49.030966, lng: 20.883381 },
    { lat: 49.031094, lng: 20.883336 },
    { lat: 49.03131, lng: 20.883292 },
    { lat: 49.031648, lng: 20.883065 },
    { lat: 49.031852, lng: 20.882958 },
    { lat: 49.032074, lng: 20.882904 },
    { lat: 49.032303, lng: 20.882812 },
    { lat: 49.032556, lng: 20.882608 },
    { lat: 49.032685, lng: 20.882523 },
    { lat: 49.032806, lng: 20.882508 },
    { lat: 49.032917, lng: 20.882535 },
    { lat: 49.032995, lng: 20.882579 },
    { lat: 49.033176, lng: 20.882778 },
    { lat: 49.033281, lng: 20.882827 },
    { lat: 49.033373, lng: 20.882788 },
    { lat: 49.033542, lng: 20.882609 },
    { lat: 49.033598, lng: 20.882582 },
    { lat: 49.033683, lng: 20.882589 },
    { lat: 49.033798, lng: 20.882645 },
    { lat: 49.033937, lng: 20.882688 },
    { lat: 49.034055, lng: 20.882767 },
    { lat: 49.034349, lng: 20.882856 },
    { lat: 49.034576, lng: 20.882795 },
    { lat: 49.034793, lng: 20.882884 },
    { lat: 49.035026, lng: 20.882945 },
    { lat: 49.035309, lng: 20.883163 },
    { lat: 49.035556, lng: 20.883412 },
    { lat: 49.035614, lng: 20.883469 },
    { lat: 49.035648, lng: 20.883547 },
    { lat: 49.035719, lng: 20.883679 },
    { lat: 49.035827, lng: 20.883767 },
    { lat: 49.035973, lng: 20.883817 },
    { lat: 49.036092, lng: 20.883844 },
    { lat: 49.036729, lng: 20.883649 },
    { lat: 49.037064, lng: 20.883596 },
    { lat: 49.037206, lng: 20.883505 },
    { lat: 49.037797, lng: 20.883569 },
    { lat: 49.037928, lng: 20.883567 },
    { lat: 49.038049, lng: 20.883554 },
    { lat: 49.038118, lng: 20.883527 },
    { lat: 49.038205, lng: 20.883488 },
    { lat: 49.038347, lng: 20.883441 },
    { lat: 49.038475, lng: 20.88343 },
    { lat: 49.038565, lng: 20.883415 },
    { lat: 49.038816, lng: 20.883307 },
    { lat: 49.039026, lng: 20.883111 },
    { lat: 49.03916, lng: 20.883009 },
    { lat: 49.039277, lng: 20.882932 },
    { lat: 49.039506, lng: 20.882856 },
    { lat: 49.039634, lng: 20.882771 },
    { lat: 49.039811, lng: 20.882614 },
    { lat: 49.039957, lng: 20.882579 },
    { lat: 49.04009, lng: 20.882553 },
    { lat: 49.040447, lng: 20.882629 },
    { lat: 49.04059, lng: 20.882719 },
    { lat: 49.040935, lng: 20.882946 },
    { lat: 49.041226, lng: 20.883226 },
    { lat: 49.041471, lng: 20.883545 },
    { lat: 49.041577, lng: 20.883724 },
    { lat: 49.041744, lng: 20.883969 },
    { lat: 49.041895, lng: 20.884226 },
    { lat: 49.0421, lng: 20.884406 },
    { lat: 49.042252, lng: 20.884501 },
    { lat: 49.042524, lng: 20.884519 },
    { lat: 49.042813, lng: 20.884788 },
    { lat: 49.043056, lng: 20.8842466 },
    { lat: 49.0432399, lng: 20.8840976 },
    { lat: 49.0434224, lng: 20.8835405 },
    { lat: 49.0437979, lng: 20.8827256 },
    { lat: 49.0442981, lng: 20.8813818 },
    { lat: 49.0446849, lng: 20.8806733 },
    { lat: 49.0449869, lng: 20.879918 },
    { lat: 49.045581, lng: 20.8781251 },
    { lat: 49.0461108, lng: 20.8772844 },
    { lat: 49.0460479, lng: 20.8771193 },
    { lat: 49.0460755, lng: 20.876843 },
    { lat: 49.0459674, lng: 20.8732939 },
    { lat: 49.0460494, lng: 20.8726244 },
    { lat: 49.0463611, lng: 20.8715945 },
    { lat: 49.0465558, lng: 20.8707026 },
    { lat: 49.04654, lng: 20.869711 },
    { lat: 49.0464409, lng: 20.868552 },
    { lat: 49.0466633, lng: 20.8676805 },
    { lat: 49.0472766, lng: 20.8664477 },
    { lat: 49.0476831, lng: 20.8643392 },
    { lat: 49.0475669, lng: 20.8626113 },
    { lat: 49.0477206, lng: 20.860255 },
    { lat: 49.0487046, lng: 20.8569996 },
    { lat: 49.0497331, lng: 20.8495109 },
    { lat: 49.0500898, lng: 20.8461131 },
    { lat: 49.0506173, lng: 20.8435741 },
    { lat: 49.0514582, lng: 20.8425562 },
    { lat: 49.0519907, lng: 20.8420629 },
    { lat: 49.0526538, lng: 20.8415771 },
    { lat: 49.0518362, lng: 20.8399844 },
    { lat: 49.0517778, lng: 20.839239 },
    { lat: 49.0515836, lng: 20.8391181 },
    { lat: 49.0512493, lng: 20.8387596 },
    { lat: 49.0509071, lng: 20.8380019 },
    { lat: 49.050683, lng: 20.8373767 },
    { lat: 49.0507204, lng: 20.8365968 },
    { lat: 49.0506527, lng: 20.8364924 },
    { lat: 49.0501444, lng: 20.8361139 },
    { lat: 49.0490826, lng: 20.8344938 },
    { lat: 49.0490143, lng: 20.8343051 },
    { lat: 49.0489239, lng: 20.833445 },
    { lat: 49.0484309, lng: 20.8331827 },
    { lat: 49.0481975, lng: 20.8328148 },
    { lat: 49.0480616, lng: 20.8324894 },
    { lat: 49.0477506, lng: 20.8313318 },
    { lat: 49.0474416, lng: 20.830502 },
    { lat: 49.0465304, lng: 20.8285503 },
    { lat: 49.0461064, lng: 20.8268258 },
    { lat: 49.046077, lng: 20.8261945 },
    { lat: 49.0460143, lng: 20.8258123 },
    { lat: 49.0456606, lng: 20.8254444 },
  ],
  Ordzovany: [
    { lat: 49.0675823, lng: 20.7781553 },
    { lat: 49.0675597, lng: 20.7780882 },
    { lat: 49.0655057, lng: 20.7794239 },
    { lat: 49.0646781, lng: 20.7802265 },
    { lat: 49.0637172, lng: 20.7801675 },
    { lat: 49.0616965, lng: 20.7792588 },
    { lat: 49.0610971, lng: 20.7793745 },
    { lat: 49.0610332, lng: 20.7783115 },
    { lat: 49.0605033, lng: 20.7776424 },
    { lat: 49.0593655, lng: 20.7779692 },
    { lat: 49.0587239, lng: 20.7764847 },
    { lat: 49.0579717, lng: 20.775605 },
    { lat: 49.0569389, lng: 20.7751934 },
    { lat: 49.0555274, lng: 20.7728372 },
    { lat: 49.0551282, lng: 20.7719151 },
    { lat: 49.054742, lng: 20.7691042 },
    { lat: 49.0526594, lng: 20.766935 },
    { lat: 49.0525867, lng: 20.7668853 },
    { lat: 49.0525814, lng: 20.7664439 },
    { lat: 49.0520403, lng: 20.7663541 },
    { lat: 49.0517262, lng: 20.7661961 },
    { lat: 49.0516217, lng: 20.7662031 },
    { lat: 49.0514559, lng: 20.7665754 },
    { lat: 49.0512576, lng: 20.7667985 },
    { lat: 49.0511099, lng: 20.7666285 },
    { lat: 49.0507309, lng: 20.7666035 },
    { lat: 49.0504743, lng: 20.766733 },
    { lat: 49.0501667, lng: 20.767117 },
    { lat: 49.0500118, lng: 20.7671611 },
    { lat: 49.0497674, lng: 20.7673818 },
    { lat: 49.049616, lng: 20.767815 },
    { lat: 49.0492951, lng: 20.7681029 },
    { lat: 49.0491083, lng: 20.7681036 },
    { lat: 49.0488709, lng: 20.7679459 },
    { lat: 49.048705, lng: 20.7679697 },
    { lat: 49.0482549, lng: 20.768456 },
    { lat: 49.0480149, lng: 20.7685475 },
    { lat: 49.0476479, lng: 20.7684788 },
    { lat: 49.0470444, lng: 20.7685703 },
    { lat: 49.0468456, lng: 20.7686818 },
    { lat: 49.0467523, lng: 20.7689739 },
    { lat: 49.0466372, lng: 20.7690635 },
    { lat: 49.0461491, lng: 20.7687847 },
    { lat: 49.0460191, lng: 20.7689301 },
    { lat: 49.0457071, lng: 20.7697651 },
    { lat: 49.0452487, lng: 20.7694376 },
    { lat: 49.045069, lng: 20.7693853 },
    { lat: 49.0450104, lng: 20.7698953 },
    { lat: 49.0444084, lng: 20.7706798 },
    { lat: 49.0439634, lng: 20.7710868 },
    { lat: 49.0437429, lng: 20.7718322 },
    { lat: 49.0436018, lng: 20.7721439 },
    { lat: 49.0434234, lng: 20.7722229 },
    { lat: 49.0432018, lng: 20.7724223 },
    { lat: 49.042677, lng: 20.7734173 },
    { lat: 49.042335, lng: 20.7736125 },
    { lat: 49.0417738, lng: 20.773469 },
    { lat: 49.0412493, lng: 20.773601 },
    { lat: 49.0407702, lng: 20.7736138 },
    { lat: 49.0403574, lng: 20.7733299 },
    { lat: 49.0398951, lng: 20.7734052 },
    { lat: 49.0391511, lng: 20.7739234 },
    { lat: 49.0388596, lng: 20.7737969 },
    { lat: 49.0383759, lng: 20.7740296 },
    { lat: 49.038084, lng: 20.7743115 },
    { lat: 49.0377206, lng: 20.7748933 },
    { lat: 49.0375291, lng: 20.775538 },
    { lat: 49.0369452, lng: 20.7757397 },
    { lat: 49.0366493, lng: 20.7762299 },
    { lat: 49.036205, lng: 20.7764032 },
    { lat: 49.0361222, lng: 20.7762868 },
    { lat: 49.0354795, lng: 20.7764512 },
    { lat: 49.0352462, lng: 20.7768575 },
    { lat: 49.0343075, lng: 20.7768326 },
    { lat: 49.0343129, lng: 20.7769208 },
    { lat: 49.0339654, lng: 20.7768972 },
    { lat: 49.0336485, lng: 20.7770443 },
    { lat: 49.0336332, lng: 20.7771191 },
    { lat: 49.0333974, lng: 20.7772019 },
    { lat: 49.0329547, lng: 20.7771623 },
    { lat: 49.0321991, lng: 20.7768902 },
    { lat: 49.0317912, lng: 20.7766243 },
    { lat: 49.0310933, lng: 20.7758795 },
    { lat: 49.0306805, lng: 20.7756986 },
    { lat: 49.0288898, lng: 20.7743736 },
    { lat: 49.0288331, lng: 20.775007 },
    { lat: 49.0265595, lng: 20.7739183 },
    { lat: 49.0265016, lng: 20.7738864 },
    { lat: 49.0242308, lng: 20.7726362 },
    { lat: 49.0229741, lng: 20.7720367 },
    { lat: 49.0218937, lng: 20.7719193 },
    { lat: 49.0217101, lng: 20.7719783 },
    { lat: 49.0209521, lng: 20.7758038 },
    { lat: 49.0205001, lng: 20.7754981 },
    { lat: 49.0194437, lng: 20.7750606 },
    { lat: 49.0189454, lng: 20.7747856 },
    { lat: 49.0174939, lng: 20.773382 },
    { lat: 49.017109, lng: 20.7752244 },
    { lat: 49.0166226, lng: 20.7770897 },
    { lat: 49.0159762, lng: 20.779282 },
    { lat: 49.0157177, lng: 20.7803951 },
    { lat: 49.015726, lng: 20.7806605 },
    { lat: 49.0157924, lng: 20.780596 },
    { lat: 49.0160923, lng: 20.780484 },
    { lat: 49.0166174, lng: 20.7801111 },
    { lat: 49.0175544, lng: 20.7800605 },
    { lat: 49.0176357, lng: 20.780003 },
    { lat: 49.0177037, lng: 20.7796608 },
    { lat: 49.0178145, lng: 20.7795773 },
    { lat: 49.0179733, lng: 20.7795792 },
    { lat: 49.0182263, lng: 20.779798 },
    { lat: 49.0184922, lng: 20.7796113 },
    { lat: 49.018979, lng: 20.7796564 },
    { lat: 49.0190635, lng: 20.7795908 },
    { lat: 49.0193263, lng: 20.7795796 },
    { lat: 49.0195879, lng: 20.7797211 },
    { lat: 49.0198646, lng: 20.7797497 },
    { lat: 49.0201122, lng: 20.7800041 },
    { lat: 49.0202509, lng: 20.7800712 },
    { lat: 49.0206465, lng: 20.7800239 },
    { lat: 49.0208308, lng: 20.7800822 },
    { lat: 49.0214566, lng: 20.7804336 },
    { lat: 49.0217508, lng: 20.7807355 },
    { lat: 49.0219231, lng: 20.7807722 },
    { lat: 49.0223756, lng: 20.7812903 },
    { lat: 49.0229859, lng: 20.7813922 },
    { lat: 49.0233584, lng: 20.7815509 },
    { lat: 49.0237335, lng: 20.7818159 },
    { lat: 49.0238733, lng: 20.7820525 },
    { lat: 49.0250069, lng: 20.7823085 },
    { lat: 49.0252156, lng: 20.782192 },
    { lat: 49.0253585, lng: 20.7849101 },
    { lat: 49.0253877, lng: 20.7860096 },
    { lat: 49.0253623, lng: 20.7870615 },
    { lat: 49.0253856, lng: 20.7872174 },
    { lat: 49.0254952, lng: 20.7873908 },
    { lat: 49.0276411, lng: 20.789377 },
    { lat: 49.0309469, lng: 20.7926478 },
    { lat: 49.0329696, lng: 20.7950156 },
    { lat: 49.0335954, lng: 20.7959537 },
    { lat: 49.0338976, lng: 20.7961953 },
    { lat: 49.0340127, lng: 20.7964336 },
    { lat: 49.0341692, lng: 20.7965646 },
    { lat: 49.0343204, lng: 20.7965464 },
    { lat: 49.0346996, lng: 20.7968973 },
    { lat: 49.0351057, lng: 20.7973788 },
    { lat: 49.0360092, lng: 20.7978492 },
    { lat: 49.0363795, lng: 20.7972638 },
    { lat: 49.0372374, lng: 20.7973676 },
    { lat: 49.0373027, lng: 20.7974668 },
    { lat: 49.0374234, lng: 20.7974108 },
    { lat: 49.0382167, lng: 20.7995436 },
    { lat: 49.0387336, lng: 20.8000715 },
    { lat: 49.0391438, lng: 20.8003454 },
    { lat: 49.0403137, lng: 20.800651 },
    { lat: 49.0408532, lng: 20.8009871 },
    { lat: 49.0412566, lng: 20.8013552 },
    { lat: 49.0416533, lng: 20.8020227 },
    { lat: 49.0423429, lng: 20.8028856 },
    { lat: 49.0428098, lng: 20.8030721 },
    { lat: 49.0439782, lng: 20.8026872 },
    { lat: 49.0440699, lng: 20.8026666 },
    { lat: 49.0440726, lng: 20.8027166 },
    { lat: 49.0443707, lng: 20.8025787 },
    { lat: 49.0449677, lng: 20.8024917 },
    { lat: 49.0456895, lng: 20.8016869 },
    { lat: 49.0461281, lng: 20.8012897 },
    { lat: 49.0468228, lng: 20.8010991 },
    { lat: 49.0468175, lng: 20.8010414 },
    { lat: 49.0476271, lng: 20.8008876 },
    { lat: 49.0479697, lng: 20.80106 },
    { lat: 49.049474, lng: 20.8022436 },
    { lat: 49.0497938, lng: 20.8028029 },
    { lat: 49.0498136, lng: 20.8030213 },
    { lat: 49.0496562, lng: 20.8037419 },
    { lat: 49.0491245, lng: 20.8050917 },
    { lat: 49.0489689, lng: 20.8058696 },
    { lat: 49.0495551, lng: 20.8055643 },
    { lat: 49.0499368, lng: 20.805438 },
    { lat: 49.0510362, lng: 20.8040925 },
    { lat: 49.0516424, lng: 20.8037261 },
    { lat: 49.0517711, lng: 20.8035051 },
    { lat: 49.0519542, lng: 20.8033986 },
    { lat: 49.0521167, lng: 20.8032122 },
    { lat: 49.0521915, lng: 20.8030372 },
    { lat: 49.0524993, lng: 20.8028249 },
    { lat: 49.0529848, lng: 20.8026356 },
    { lat: 49.0530085, lng: 20.802544 },
    { lat: 49.0531686, lng: 20.8024802 },
    { lat: 49.0533165, lng: 20.8026579 },
    { lat: 49.0534109, lng: 20.8029812 },
    { lat: 49.0536474, lng: 20.8034084 },
    { lat: 49.0539975, lng: 20.8036337 },
    { lat: 49.0540408, lng: 20.8040756 },
    { lat: 49.0541631, lng: 20.8042571 },
    { lat: 49.0544477, lng: 20.8043484 },
    { lat: 49.0546486, lng: 20.8045911 },
    { lat: 49.0547801, lng: 20.8049172 },
    { lat: 49.0548761, lng: 20.8054432 },
    { lat: 49.0549735, lng: 20.8055585 },
    { lat: 49.0550727, lng: 20.8054989 },
    { lat: 49.0551766, lng: 20.8055372 },
    { lat: 49.0552498, lng: 20.8058332 },
    { lat: 49.0553021, lng: 20.8058796 },
    { lat: 49.0558247, lng: 20.8059587 },
    { lat: 49.0564417, lng: 20.8064136 },
    { lat: 49.0567513, lng: 20.8064183 },
    { lat: 49.0569289, lng: 20.8063147 },
    { lat: 49.057101, lng: 20.8060465 },
    { lat: 49.0573106, lng: 20.8060247 },
    { lat: 49.0580526, lng: 20.806289 },
    { lat: 49.0587391, lng: 20.8062589 },
    { lat: 49.0592302, lng: 20.8064902 },
    { lat: 49.0595813, lng: 20.8064876 },
    { lat: 49.0597707, lng: 20.8063719 },
    { lat: 49.0600367, lng: 20.8064102 },
    { lat: 49.0601395, lng: 20.8065183 },
    { lat: 49.0602845, lng: 20.806839 },
    { lat: 49.0607331, lng: 20.8069862 },
    { lat: 49.0613625, lng: 20.8077424 },
    { lat: 49.0616576, lng: 20.8086304 },
    { lat: 49.061616, lng: 20.8089554 },
    { lat: 49.0616475, lng: 20.809315 },
    { lat: 49.0622339, lng: 20.8099038 },
    { lat: 49.0625028, lng: 20.8103529 },
    { lat: 49.06308, lng: 20.8107368 },
    { lat: 49.0636909, lng: 20.8115763 },
    { lat: 49.0639989, lng: 20.8116662 },
    { lat: 49.0642121, lng: 20.8115476 },
    { lat: 49.0643996, lng: 20.8115624 },
    { lat: 49.0647163, lng: 20.8119021 },
    { lat: 49.0648864, lng: 20.8122988 },
    { lat: 49.0651338, lng: 20.8121965 },
    { lat: 49.0652941, lng: 20.8125351 },
    { lat: 49.0652893, lng: 20.8128808 },
    { lat: 49.0653558, lng: 20.8130794 },
    { lat: 49.0658011, lng: 20.8133317 },
    { lat: 49.0659651, lng: 20.8136478 },
    { lat: 49.0659846, lng: 20.8139804 },
    { lat: 49.0662223, lng: 20.8149367 },
    { lat: 49.0666782, lng: 20.8155579 },
    { lat: 49.0668655, lng: 20.816154 },
    { lat: 49.0677319, lng: 20.8167392 },
    { lat: 49.0677439, lng: 20.8169217 },
    { lat: 49.0678708, lng: 20.8171072 },
    { lat: 49.0680728, lng: 20.8170882 },
    { lat: 49.0681932, lng: 20.8172215 },
    { lat: 49.0683943, lng: 20.8176656 },
    { lat: 49.0684711, lng: 20.8177105 },
    { lat: 49.0686869, lng: 20.8175385 },
    { lat: 49.069144, lng: 20.8174758 },
    { lat: 49.0695075, lng: 20.8173061 },
    { lat: 49.0696877, lng: 20.8174077 },
    { lat: 49.0699599, lng: 20.8181545 },
    { lat: 49.0700833, lng: 20.8183411 },
    { lat: 49.0704196, lng: 20.8186265 },
    { lat: 49.0705885, lng: 20.8189832 },
    { lat: 49.0705939, lng: 20.819271 },
    { lat: 49.0708632, lng: 20.8194822 },
    { lat: 49.0708744, lng: 20.8199986 },
    { lat: 49.0710479, lng: 20.820235 },
    { lat: 49.0713779, lng: 20.8210914 },
    { lat: 49.0713562, lng: 20.8214066 },
    { lat: 49.0713928, lng: 20.8215878 },
    { lat: 49.0713503, lng: 20.8218249 },
    { lat: 49.0715159, lng: 20.8221905 },
    { lat: 49.0717703, lng: 20.8223167 },
    { lat: 49.0718907, lng: 20.8225131 },
    { lat: 49.0718933, lng: 20.8226224 },
    { lat: 49.0718173, lng: 20.822669 },
    { lat: 49.0718643, lng: 20.8229234 },
    { lat: 49.0720187, lng: 20.8231265 },
    { lat: 49.0720327, lng: 20.8233702 },
    { lat: 49.0719892, lng: 20.8236394 },
    { lat: 49.0721595, lng: 20.8242795 },
    { lat: 49.072408, lng: 20.824496 },
    { lat: 49.0724419, lng: 20.8247607 },
    { lat: 49.072555, lng: 20.8249427 },
    { lat: 49.0725901, lng: 20.8251754 },
    { lat: 49.072542, lng: 20.8254833 },
    { lat: 49.0726625, lng: 20.8258722 },
    { lat: 49.0729369, lng: 20.8263275 },
    { lat: 49.073198, lng: 20.8264869 },
    { lat: 49.0735994, lng: 20.8266097 },
    { lat: 49.0739717, lng: 20.826565 },
    { lat: 49.0740752, lng: 20.8267287 },
    { lat: 49.074126, lng: 20.8269275 },
    { lat: 49.0743, lng: 20.827049 },
    { lat: 49.0743619, lng: 20.8273747 },
    { lat: 49.0745952, lng: 20.8271235 },
    { lat: 49.0747439, lng: 20.8268639 },
    { lat: 49.074381, lng: 20.8264559 },
    { lat: 49.0743841, lng: 20.8256967 },
    { lat: 49.0747425, lng: 20.8239086 },
    { lat: 49.074899, lng: 20.8211977 },
    { lat: 49.0754813, lng: 20.8191471 },
    { lat: 49.0769346, lng: 20.8159119 },
    { lat: 49.0771564, lng: 20.8151014 },
    { lat: 49.0773659, lng: 20.814089 },
    { lat: 49.0774175, lng: 20.8130404 },
    { lat: 49.0772943, lng: 20.811855 },
    { lat: 49.0773254, lng: 20.8069835 },
    { lat: 49.077121, lng: 20.8057914 },
    { lat: 49.0768228, lng: 20.8047162 },
    { lat: 49.0748016, lng: 20.8003037 },
    { lat: 49.0741063, lng: 20.7993017 },
    { lat: 49.0734192, lng: 20.7987364 },
    { lat: 49.0725425, lng: 20.7977965 },
    { lat: 49.0712853, lng: 20.796047 },
    { lat: 49.0714527, lng: 20.7929806 },
    { lat: 49.0718969, lng: 20.7930368 },
    { lat: 49.0711497, lng: 20.7922287 },
    { lat: 49.0701213, lng: 20.7892577 },
    { lat: 49.0692743, lng: 20.7863569 },
    { lat: 49.0692618, lng: 20.7852816 },
    { lat: 49.0684011, lng: 20.7825048 },
    { lat: 49.0679078, lng: 20.7790653 },
    { lat: 49.0675823, lng: 20.7781553 },
  ],
  Baldovce: [
    { lat: 48.9836324, lng: 20.7258059 },
    { lat: 48.985549, lng: 20.7257655 },
    { lat: 48.9860654, lng: 20.725695 },
    { lat: 48.9867521, lng: 20.7257538 },
    { lat: 48.9890524, lng: 20.72523 },
    { lat: 48.9918796, lng: 20.7248299 },
    { lat: 48.9929838, lng: 20.7248905 },
    { lat: 48.9939711, lng: 20.7199219 },
    { lat: 48.9942762, lng: 20.7199557 },
    { lat: 48.9943578, lng: 20.7193017 },
    { lat: 48.9944781, lng: 20.7189495 },
    { lat: 48.9946088, lng: 20.7186795 },
    { lat: 48.9950048, lng: 20.7178612 },
    { lat: 48.9953724, lng: 20.7179086 },
    { lat: 48.9954561, lng: 20.7174153 },
    { lat: 48.9955371, lng: 20.7172517 },
    { lat: 48.9959671, lng: 20.7168702 },
    { lat: 48.9962333, lng: 20.716119 },
    { lat: 48.9964089, lng: 20.7158578 },
    { lat: 48.9987625, lng: 20.7135992 },
    { lat: 48.9995108, lng: 20.7125159 },
    { lat: 48.9999846, lng: 20.7116217 },
    { lat: 49.000071, lng: 20.7113554 },
    { lat: 49.0002845, lng: 20.71103 },
    { lat: 49.0007374, lng: 20.7109105 },
    { lat: 49.0009521, lng: 20.7109969 },
    { lat: 49.0012053, lng: 20.7103957 },
    { lat: 49.0017175, lng: 20.7086791 },
    { lat: 49.0021776, lng: 20.7074913 },
    { lat: 49.002126, lng: 20.7072126 },
    { lat: 49.0016276, lng: 20.7069351 },
    { lat: 49.0012979, lng: 20.7065914 },
    { lat: 49.0010839, lng: 20.7064895 },
    { lat: 49.0000427, lng: 20.7069699 },
    { lat: 48.9993389, lng: 20.707532 },
    { lat: 48.998882, lng: 20.7076992 },
    { lat: 48.9986821, lng: 20.7077084 },
    { lat: 48.9985109, lng: 20.7078375 },
    { lat: 48.9979185, lng: 20.7080477 },
    { lat: 48.9973679, lng: 20.7081078 },
    { lat: 48.9970912, lng: 20.7068173 },
    { lat: 48.9968652, lng: 20.7051401 },
    { lat: 48.9959955, lng: 20.7054756 },
    { lat: 48.9956025, lng: 20.7057863 },
    { lat: 48.9951741, lng: 20.7043119 },
    { lat: 48.9946704, lng: 20.7048342 },
    { lat: 48.9944038, lng: 20.7049998 },
    { lat: 48.993735, lng: 20.7056619 },
    { lat: 48.9935806, lng: 20.7057201 },
    { lat: 48.9929836, lng: 20.7062447 },
    { lat: 48.9926513, lng: 20.7047179 },
    { lat: 48.9925834, lng: 20.7037893 },
    { lat: 48.9926788, lng: 20.7035263 },
    { lat: 48.9926835, lng: 20.7033743 },
    { lat: 48.9926859, lng: 20.7031884 },
    { lat: 48.9925051, lng: 20.702787 },
    { lat: 48.9924838, lng: 20.7028257 },
    { lat: 48.9919315, lng: 20.7035323 },
    { lat: 48.9902613, lng: 20.7052855 },
    { lat: 48.9892227, lng: 20.7059996 },
    { lat: 48.9875799, lng: 20.7069679 },
    { lat: 48.9868973, lng: 20.7069302 },
    { lat: 48.9853757, lng: 20.7074118 },
    { lat: 48.98535, lng: 20.707767 },
    { lat: 48.9842863, lng: 20.7086279 },
    { lat: 48.9840608, lng: 20.7087155 },
    { lat: 48.9832222, lng: 20.709702 },
    { lat: 48.981951, lng: 20.71105 },
    { lat: 48.981897, lng: 20.711936 },
    { lat: 48.981825, lng: 20.712551 },
    { lat: 48.981732, lng: 20.713047 },
    { lat: 48.98184, lng: 20.714131 },
    { lat: 48.981986, lng: 20.715605 },
    { lat: 48.982, lng: 20.716322 },
    { lat: 48.982006, lng: 20.716643 },
    { lat: 48.9827935, lng: 20.7180976 },
    { lat: 48.9829935, lng: 20.7183415 },
    { lat: 48.9831025, lng: 20.7183411 },
    { lat: 48.9832097, lng: 20.7183407 },
    { lat: 48.9833333, lng: 20.718432 },
    { lat: 48.9833529, lng: 20.7188601 },
    { lat: 48.9835499, lng: 20.7195554 },
    { lat: 48.9835206, lng: 20.7204811 },
    { lat: 48.9836123, lng: 20.7217663 },
    { lat: 48.9838503, lng: 20.7229937 },
    { lat: 48.9838271, lng: 20.7243393 },
    { lat: 48.9839275, lng: 20.7246038 },
    { lat: 48.983853, lng: 20.7252349 },
    { lat: 48.9837409, lng: 20.7256587 },
    { lat: 48.9836324, lng: 20.7258059 },
  ],
  SpišskéPodhradie: [
    { lat: 49.0626264, lng: 20.7439231 },
    { lat: 49.0614526, lng: 20.7434797 },
    { lat: 49.0607874, lng: 20.7431963 },
    { lat: 49.0599676, lng: 20.7426068 },
    { lat: 49.0596993, lng: 20.7426333 },
    { lat: 49.05938, lng: 20.7427694 },
    { lat: 49.0590451, lng: 20.7425289 },
    { lat: 49.0583419, lng: 20.7409691 },
    { lat: 49.0578443, lng: 20.7389178 },
    { lat: 49.0578041, lng: 20.7388937 },
    { lat: 49.0568635, lng: 20.7392599 },
    { lat: 49.0561141, lng: 20.7397426 },
    { lat: 49.0555574, lng: 20.7402724 },
    { lat: 49.053534, lng: 20.7419515 },
    { lat: 49.0526926, lng: 20.7429648 },
    { lat: 49.0522092, lng: 20.7432718 },
    { lat: 49.0514989, lng: 20.7434663 },
    { lat: 49.0507514, lng: 20.7435266 },
    { lat: 49.0504911, lng: 20.7436756 },
    { lat: 49.0503016, lng: 20.7440498 },
    { lat: 49.0499114, lng: 20.7453802 },
    { lat: 49.0497415, lng: 20.7455742 },
    { lat: 49.0495908, lng: 20.745608 },
    { lat: 49.0490406, lng: 20.7449446 },
    { lat: 49.0482653, lng: 20.7443739 },
    { lat: 49.0474028, lng: 20.7441428 },
    { lat: 49.0470441, lng: 20.7439316 },
    { lat: 49.0464635, lng: 20.7437455 },
    { lat: 49.0453026, lng: 20.7431587 },
    { lat: 49.0445404, lng: 20.7428952 },
    { lat: 49.0431548, lng: 20.7426443 },
    { lat: 49.0425122, lng: 20.7424206 },
    { lat: 49.041639, lng: 20.7419178 },
    { lat: 49.0412511, lng: 20.741325 },
    { lat: 49.0409913, lng: 20.7411352 },
    { lat: 49.0404067, lng: 20.7409631 },
    { lat: 49.0388508, lng: 20.7400983 },
    { lat: 49.0381108, lng: 20.739762 },
    { lat: 49.0378318, lng: 20.7397011 },
    { lat: 49.0373955, lng: 20.7397448 },
    { lat: 49.0373336, lng: 20.7397157 },
    { lat: 49.0372319, lng: 20.7393512 },
    { lat: 49.0365388, lng: 20.7390079 },
    { lat: 49.0360711, lng: 20.7388963 },
    { lat: 49.0353336, lng: 20.7384752 },
    { lat: 49.0349515, lng: 20.7383947 },
    { lat: 49.0343439, lng: 20.7377335 },
    { lat: 49.0333105, lng: 20.7362584 },
    { lat: 49.0324101, lng: 20.7352113 },
    { lat: 49.032155, lng: 20.7347729 },
    { lat: 49.0317997, lng: 20.7344363 },
    { lat: 49.0312064, lng: 20.7340792 },
    { lat: 49.0308777, lng: 20.733796 },
    { lat: 49.0305452, lng: 20.7337176 },
    { lat: 49.0303709, lng: 20.7335912 },
    { lat: 49.0299244, lng: 20.7334446 },
    { lat: 49.0296045, lng: 20.7334689 },
    { lat: 49.0281865, lng: 20.7341826 },
    { lat: 49.0275609, lng: 20.7346048 },
    { lat: 49.0268085, lng: 20.7348472 },
    { lat: 49.0261707, lng: 20.7351828 },
    { lat: 49.0256769, lng: 20.7352894 },
    { lat: 49.0249041, lng: 20.7352752 },
    { lat: 49.0245119, lng: 20.7350345 },
    { lat: 49.0242767, lng: 20.7348474 },
    { lat: 49.0242171, lng: 20.7348827 },
    { lat: 49.0237948, lng: 20.7347471 },
    { lat: 49.0233419, lng: 20.7347323 },
    { lat: 49.0228419, lng: 20.7350179 },
    { lat: 49.0218926, lng: 20.7353294 },
    { lat: 49.0210008, lng: 20.7361225 },
    { lat: 49.020239, lng: 20.7365148 },
    { lat: 49.0201529, lng: 20.736595 },
    { lat: 49.0199367, lng: 20.7372015 },
    { lat: 49.0198033, lng: 20.7374386 },
    { lat: 49.0193774, lng: 20.7380033 },
    { lat: 49.0191093, lng: 20.738222 },
    { lat: 49.0187248, lng: 20.7387184 },
    { lat: 49.0185144, lng: 20.7388446 },
    { lat: 49.0178784, lng: 20.7394536 },
    { lat: 49.0175406, lng: 20.7396414 },
    { lat: 49.01644, lng: 20.740711 },
    { lat: 49.0160758, lng: 20.7408503 },
    { lat: 49.0137787, lng: 20.7424 },
    { lat: 49.0135809, lng: 20.7426309 },
    { lat: 49.0127009, lng: 20.743156 },
    { lat: 49.0119933, lng: 20.7409557 },
    { lat: 49.0114263, lng: 20.7399393 },
    { lat: 49.0103076, lng: 20.7397892 },
    { lat: 49.0099812, lng: 20.7395953 },
    { lat: 49.0092666, lng: 20.7389968 },
    { lat: 49.0093112, lng: 20.737609 },
    { lat: 49.0093678, lng: 20.7373943 },
    { lat: 49.0096211, lng: 20.7369775 },
    { lat: 49.0097625, lng: 20.7366107 },
    { lat: 49.0095298, lng: 20.7364012 },
    { lat: 49.009471, lng: 20.7362632 },
    { lat: 49.0094911, lng: 20.7361325 },
    { lat: 49.0096558, lng: 20.7357247 },
    { lat: 49.0099019, lng: 20.7361615 },
    { lat: 49.0101471, lng: 20.7354807 },
    { lat: 49.0103428, lng: 20.7351255 },
    { lat: 49.0103688, lng: 20.7348441 },
    { lat: 49.010317, lng: 20.7341365 },
    { lat: 49.0106635, lng: 20.7333664 },
    { lat: 49.0106943, lng: 20.7328673 },
    { lat: 49.0106704, lng: 20.7321571 },
    { lat: 49.0108576, lng: 20.731879 },
    { lat: 49.0108843, lng: 20.7317413 },
    { lat: 49.0108923, lng: 20.7313913 },
    { lat: 49.0108381, lng: 20.7310386 },
    { lat: 49.0108557, lng: 20.7306079 },
    { lat: 49.0104969, lng: 20.7306537 },
    { lat: 49.0104391, lng: 20.731055 },
    { lat: 49.0104557, lng: 20.7313993 },
    { lat: 49.0103915, lng: 20.7315601 },
    { lat: 49.0101144, lng: 20.7318678 },
    { lat: 49.0093603, lng: 20.7322232 },
    { lat: 49.0089012, lng: 20.7325826 },
    { lat: 49.0077362, lng: 20.7302557 },
    { lat: 49.0077225, lng: 20.7293202 },
    { lat: 49.0078614, lng: 20.7282809 },
    { lat: 49.0079558, lng: 20.7266851 },
    { lat: 49.0079131, lng: 20.7226953 },
    { lat: 49.0078081, lng: 20.7204603 },
    { lat: 49.0078464, lng: 20.7198555 },
    { lat: 49.0079361, lng: 20.7194014 },
    { lat: 49.0083182, lng: 20.7188416 },
    { lat: 49.008529, lng: 20.7175769 },
    { lat: 49.0088399, lng: 20.7162112 },
    { lat: 49.0088359, lng: 20.7158113 },
    { lat: 49.0086314, lng: 20.7134722 },
    { lat: 49.0086691, lng: 20.711166 },
    { lat: 49.0081903, lng: 20.7079458 },
    { lat: 49.0076999, lng: 20.7057047 },
    { lat: 49.0074936, lng: 20.7032935 },
    { lat: 49.0075249, lng: 20.7028752 },
    { lat: 49.0074651, lng: 20.702446 },
    { lat: 49.0074718, lng: 20.7020344 },
    { lat: 49.0070595, lng: 20.69892 },
    { lat: 49.0069912, lng: 20.6979145 },
    { lat: 49.00677, lng: 20.6963836 },
    { lat: 49.0067499, lng: 20.6941417 },
    { lat: 49.0068055, lng: 20.6932375 },
    { lat: 49.0067729, lng: 20.6920361 },
    { lat: 49.0066717, lng: 20.6912807 },
    { lat: 49.0064078, lng: 20.6909162 },
    { lat: 49.0048854, lng: 20.6935908 },
    { lat: 49.0035472, lng: 20.6955609 },
    { lat: 49.0031717, lng: 20.6959875 },
    { lat: 49.0027357, lng: 20.696616 },
    { lat: 49.0018078, lng: 20.6976796 },
    { lat: 48.9990036, lng: 20.7001799 },
    { lat: 48.9981869, lng: 20.7008029 },
    { lat: 48.9967954, lng: 20.7016204 },
    { lat: 48.9965627, lng: 20.7017144 },
    { lat: 48.9957277, lng: 20.7020518 },
    { lat: 48.9947731, lng: 20.7021465 },
    { lat: 48.9926788, lng: 20.7035263 },
    { lat: 48.9925834, lng: 20.7037893 },
    { lat: 48.9926513, lng: 20.7047179 },
    { lat: 48.9929836, lng: 20.7062447 },
    { lat: 48.9935806, lng: 20.7057201 },
    { lat: 48.993735, lng: 20.7056619 },
    { lat: 48.9944038, lng: 20.7049998 },
    { lat: 48.9946704, lng: 20.7048342 },
    { lat: 48.9951741, lng: 20.7043119 },
    { lat: 48.9956025, lng: 20.7057863 },
    { lat: 48.9959955, lng: 20.7054756 },
    { lat: 48.9968652, lng: 20.7051401 },
    { lat: 48.9970912, lng: 20.7068173 },
    { lat: 48.9973679, lng: 20.7081078 },
    { lat: 48.9979185, lng: 20.7080477 },
    { lat: 48.9985109, lng: 20.7078375 },
    { lat: 48.9986821, lng: 20.7077084 },
    { lat: 48.998882, lng: 20.7076992 },
    { lat: 48.9993389, lng: 20.707532 },
    { lat: 49.0000427, lng: 20.7069699 },
    { lat: 49.0010839, lng: 20.7064895 },
    { lat: 49.0012979, lng: 20.7065914 },
    { lat: 49.0016276, lng: 20.7069351 },
    { lat: 49.002126, lng: 20.7072126 },
    { lat: 49.0021776, lng: 20.7074913 },
    { lat: 49.0017175, lng: 20.7086791 },
    { lat: 49.0012053, lng: 20.7103957 },
    { lat: 49.0009521, lng: 20.7109969 },
    { lat: 49.0007374, lng: 20.7109105 },
    { lat: 49.0002845, lng: 20.71103 },
    { lat: 49.000071, lng: 20.7113554 },
    { lat: 48.9999846, lng: 20.7116217 },
    { lat: 48.9995108, lng: 20.7125159 },
    { lat: 48.9987625, lng: 20.7135992 },
    { lat: 48.9964089, lng: 20.7158578 },
    { lat: 48.9962333, lng: 20.716119 },
    { lat: 48.9959671, lng: 20.7168702 },
    { lat: 48.9955371, lng: 20.7172517 },
    { lat: 48.9954561, lng: 20.7174153 },
    { lat: 48.9953724, lng: 20.7179086 },
    { lat: 48.9950048, lng: 20.7178612 },
    { lat: 48.9946088, lng: 20.7186795 },
    { lat: 48.9944781, lng: 20.7189495 },
    { lat: 48.9943578, lng: 20.7193017 },
    { lat: 48.9942762, lng: 20.7199557 },
    { lat: 48.9939711, lng: 20.7199219 },
    { lat: 48.9929838, lng: 20.7248905 },
    { lat: 48.9918796, lng: 20.7248299 },
    { lat: 48.9890524, lng: 20.72523 },
    { lat: 48.9867521, lng: 20.7257538 },
    { lat: 48.9860654, lng: 20.725695 },
    { lat: 48.985549, lng: 20.7257655 },
    { lat: 48.9836324, lng: 20.7258059 },
    { lat: 48.9837409, lng: 20.7256587 },
    { lat: 48.983853, lng: 20.7252349 },
    { lat: 48.9839275, lng: 20.7246038 },
    { lat: 48.9838271, lng: 20.7243393 },
    { lat: 48.9838503, lng: 20.7229937 },
    { lat: 48.9836123, lng: 20.7217663 },
    { lat: 48.9835206, lng: 20.7204811 },
    { lat: 48.9835499, lng: 20.7195554 },
    { lat: 48.9833529, lng: 20.7188601 },
    { lat: 48.9833333, lng: 20.718432 },
    { lat: 48.9832097, lng: 20.7183407 },
    { lat: 48.9831025, lng: 20.7183411 },
    { lat: 48.9829935, lng: 20.7183415 },
    { lat: 48.9827935, lng: 20.7180976 },
    { lat: 48.982006, lng: 20.716643 },
    { lat: 48.981707, lng: 20.716506 },
    { lat: 48.981493, lng: 20.716364 },
    { lat: 48.981374, lng: 20.716344 },
    { lat: 48.981248, lng: 20.716421 },
    { lat: 48.981163, lng: 20.716472 },
    { lat: 48.981005, lng: 20.716648 },
    { lat: 48.98066, lng: 20.717254 },
    { lat: 48.980237, lng: 20.718325 },
    { lat: 48.980237, lng: 20.718563 },
    { lat: 48.980301, lng: 20.718772 },
    { lat: 48.980322, lng: 20.719121 },
    { lat: 48.980426, lng: 20.719791 },
    { lat: 48.980083, lng: 20.720809 },
    { lat: 48.979918, lng: 20.72104 },
    { lat: 48.979628, lng: 20.721533 },
    { lat: 48.979498, lng: 20.721628 },
    { lat: 48.979234, lng: 20.721715 },
    { lat: 48.978864, lng: 20.721836 },
    { lat: 48.978714, lng: 20.721886 },
    { lat: 48.978381, lng: 20.722374 },
    { lat: 48.978272, lng: 20.722837 },
    { lat: 48.978065, lng: 20.723084 },
    { lat: 48.977838, lng: 20.723187 },
    { lat: 48.977426, lng: 20.723212 },
    { lat: 48.977317, lng: 20.723002 },
    { lat: 48.976939, lng: 20.723036 },
    { lat: 48.976781, lng: 20.722991 },
    { lat: 48.976424, lng: 20.723315 },
    { lat: 48.976389, lng: 20.723347 },
    { lat: 48.976135, lng: 20.723578 },
    { lat: 48.975683, lng: 20.723908 },
    { lat: 48.975615, lng: 20.723883 },
    { lat: 48.975556, lng: 20.723969 },
    { lat: 48.975481, lng: 20.724125 },
    { lat: 48.975365, lng: 20.724281 },
    { lat: 48.975163, lng: 20.724434 },
    { lat: 48.975066, lng: 20.724542 },
    { lat: 48.975023, lng: 20.724652 },
    { lat: 48.974963, lng: 20.724905 },
    { lat: 48.974894, lng: 20.725054 },
    { lat: 48.974629, lng: 20.725491 },
    { lat: 48.974237, lng: 20.725925 },
    { lat: 48.974061, lng: 20.726134 },
    { lat: 48.973948, lng: 20.726232 },
    { lat: 48.973819, lng: 20.726343 },
    { lat: 48.9734, lng: 20.726649 },
    { lat: 48.973119, lng: 20.726951 },
    { lat: 48.972804, lng: 20.727125 },
    { lat: 48.972308, lng: 20.727612 },
    { lat: 48.971952, lng: 20.727936 },
    { lat: 48.971746, lng: 20.728184 },
    { lat: 48.971151, lng: 20.728875 },
    { lat: 48.970573, lng: 20.729399 },
    { lat: 48.97031, lng: 20.729765 },
    { lat: 48.970034, lng: 20.730273 },
    { lat: 48.969098, lng: 20.731486 },
    { lat: 48.968039, lng: 20.73283 },
    { lat: 48.967611, lng: 20.733306 },
    { lat: 48.967286, lng: 20.733667 },
    { lat: 48.967218, lng: 20.7338 },
    { lat: 48.967081, lng: 20.733963 },
    { lat: 48.967213, lng: 20.734244 },
    { lat: 48.967419, lng: 20.734495 },
    { lat: 48.967499, lng: 20.734672 },
    { lat: 48.967541, lng: 20.734746 },
    { lat: 48.967675, lng: 20.734994 },
    { lat: 48.9679, lng: 20.735342 },
    { lat: 48.968449, lng: 20.736036 },
    { lat: 48.968445, lng: 20.736073 },
    { lat: 48.968827, lng: 20.736379 },
    { lat: 48.969778, lng: 20.737361 },
    { lat: 48.970172, lng: 20.737801 },
    { lat: 48.970523, lng: 20.738217 },
    { lat: 48.970633, lng: 20.738387 },
    { lat: 48.970752, lng: 20.738605 },
    { lat: 48.970879, lng: 20.738863 },
    { lat: 48.971047, lng: 20.739063 },
    { lat: 48.971068, lng: 20.739091 },
    { lat: 48.971128, lng: 20.739164 },
    { lat: 48.971233, lng: 20.739239 },
    { lat: 48.97122, lng: 20.738929 },
    { lat: 48.9712826, lng: 20.7387662 },
    { lat: 48.971429, lng: 20.738541 },
    { lat: 48.971473, lng: 20.738493 },
    { lat: 48.971493, lng: 20.738537 },
    { lat: 48.971694, lng: 20.738694 },
    { lat: 48.971752, lng: 20.738762 },
    { lat: 48.971611, lng: 20.739299 },
    { lat: 48.971631, lng: 20.739418 },
    { lat: 48.97165, lng: 20.73953 },
    { lat: 48.971811, lng: 20.739757 },
    { lat: 48.971946, lng: 20.739754 },
    { lat: 48.97279, lng: 20.739869 },
    { lat: 48.972908, lng: 20.740047 },
    { lat: 48.973004, lng: 20.74043 },
    { lat: 48.973006, lng: 20.740637 },
    { lat: 48.972976, lng: 20.740746 },
    { lat: 48.973029, lng: 20.740944 },
    { lat: 48.973135, lng: 20.741329 },
    { lat: 48.973321, lng: 20.741819 },
    { lat: 48.973343, lng: 20.741868 },
    { lat: 48.973433, lng: 20.741965 },
    { lat: 48.973582, lng: 20.742128 },
    { lat: 48.973615, lng: 20.742201 },
    { lat: 48.973863, lng: 20.742677 },
    { lat: 48.973993, lng: 20.742942 },
    { lat: 48.97409, lng: 20.74308 },
    { lat: 48.974184, lng: 20.743115 },
    { lat: 48.974355, lng: 20.743319 },
    { lat: 48.974428, lng: 20.743313 },
    { lat: 48.975425, lng: 20.745583 },
    { lat: 48.97543, lng: 20.745614 },
    { lat: 48.975437, lng: 20.745646 },
    { lat: 48.975466, lng: 20.745774 },
    { lat: 48.97576, lng: 20.74733 },
    { lat: 48.975941, lng: 20.747795 },
    { lat: 48.976029, lng: 20.747989 },
    { lat: 48.976114, lng: 20.748237 },
    { lat: 48.976315, lng: 20.74864 },
    { lat: 48.976353, lng: 20.748801 },
    { lat: 48.976374, lng: 20.748896 },
    { lat: 48.976557, lng: 20.748828 },
    { lat: 48.976734, lng: 20.748765 },
    { lat: 48.976747, lng: 20.748814 },
    { lat: 48.976751, lng: 20.748829 },
    { lat: 48.976774, lng: 20.748891 },
    { lat: 48.976787, lng: 20.748917 },
    { lat: 48.976802, lng: 20.748965 },
    { lat: 48.976911, lng: 20.749169 },
    { lat: 48.976919, lng: 20.749187 },
    { lat: 48.97695, lng: 20.749254 },
    { lat: 48.976954, lng: 20.749263 },
    { lat: 48.976995, lng: 20.749332 },
    { lat: 48.976967, lng: 20.749442 },
    { lat: 48.976768, lng: 20.749483 },
    { lat: 48.976736, lng: 20.749542 },
    { lat: 48.976739, lng: 20.749676 },
    { lat: 48.976531, lng: 20.749797 },
    { lat: 48.976495, lng: 20.749772 },
    { lat: 48.976368, lng: 20.749803 },
    { lat: 48.976314, lng: 20.749814 },
    { lat: 48.976274, lng: 20.749844 },
    { lat: 48.976284, lng: 20.749868 },
    { lat: 48.97635, lng: 20.750195 },
    { lat: 48.976428, lng: 20.750563 },
    { lat: 48.976454, lng: 20.750706 },
    { lat: 48.976658, lng: 20.751276 },
    { lat: 48.97684, lng: 20.751884 },
    { lat: 48.97693, lng: 20.75211 },
    { lat: 48.977076, lng: 20.752384 },
    { lat: 48.977203, lng: 20.752618 },
    { lat: 48.977285, lng: 20.752914 },
    { lat: 48.977315, lng: 20.753192 },
    { lat: 48.97735, lng: 20.75332 },
    { lat: 48.977257, lng: 20.753341 },
    { lat: 48.977177, lng: 20.753323 },
    { lat: 48.976308, lng: 20.753834 },
    { lat: 48.976304, lng: 20.753861 },
    { lat: 48.975812, lng: 20.754063 },
    { lat: 48.974731, lng: 20.754433 },
    { lat: 48.974376, lng: 20.754635 },
    { lat: 48.974193, lng: 20.754723 },
    { lat: 48.973978, lng: 20.754867 },
    { lat: 48.973811, lng: 20.754909 },
    { lat: 48.973631, lng: 20.755005 },
    { lat: 48.97333, lng: 20.755193 },
    { lat: 48.97312, lng: 20.755261 },
    { lat: 48.972764, lng: 20.755277 },
    { lat: 48.972468, lng: 20.755358 },
    { lat: 48.971918, lng: 20.755565 },
    { lat: 48.971754, lng: 20.755658 },
    { lat: 48.971552, lng: 20.755739 },
    { lat: 48.971246, lng: 20.755779 },
    { lat: 48.970785, lng: 20.755958 },
    { lat: 48.970655, lng: 20.756061 },
    { lat: 48.970445, lng: 20.756275 },
    { lat: 48.970162, lng: 20.756459 },
    { lat: 48.969961, lng: 20.756789 },
    { lat: 48.969921, lng: 20.75695 },
    { lat: 48.969862, lng: 20.757159 },
    { lat: 48.969419, lng: 20.75812 },
    { lat: 48.969081, lng: 20.7587 },
    { lat: 48.969022, lng: 20.758865 },
    { lat: 48.968966, lng: 20.759045 },
    { lat: 48.968959, lng: 20.759216 },
    { lat: 48.968992, lng: 20.759387 },
    { lat: 48.969121, lng: 20.759936 },
    { lat: 48.969364, lng: 20.760801 },
    { lat: 48.969419, lng: 20.761125 },
    { lat: 48.969439, lng: 20.761598 },
    { lat: 48.969432, lng: 20.761764 },
    { lat: 48.969047, lng: 20.763116 },
    { lat: 48.968886, lng: 20.763619 },
    { lat: 48.968395, lng: 20.7645 },
    { lat: 48.967788, lng: 20.765189 },
    { lat: 48.967633, lng: 20.765276 },
    { lat: 48.967344, lng: 20.765393 },
    { lat: 48.966866, lng: 20.765539 },
    { lat: 48.96673, lng: 20.765617 },
    { lat: 48.9661, lng: 20.766076 },
    { lat: 48.966054, lng: 20.766099 },
    { lat: 48.966045, lng: 20.766103 },
    { lat: 48.966052, lng: 20.766127 },
    { lat: 48.96693, lng: 20.766371 },
    { lat: 48.967047, lng: 20.766724 },
    { lat: 48.967223, lng: 20.766992 },
    { lat: 48.967339, lng: 20.767282 },
    { lat: 48.967403, lng: 20.767491 },
    { lat: 48.967489, lng: 20.767882 },
    { lat: 48.96752, lng: 20.768082 },
    { lat: 48.967519, lng: 20.768288 },
    { lat: 48.967434, lng: 20.768762 },
    { lat: 48.967369, lng: 20.769291 },
    { lat: 48.967419, lng: 20.769611 },
    { lat: 48.967573, lng: 20.770116 },
    { lat: 48.967791, lng: 20.770726 },
    { lat: 48.968155, lng: 20.770778 },
    { lat: 48.968383, lng: 20.770921 },
    { lat: 48.968425, lng: 20.771049 },
    { lat: 48.968447, lng: 20.771225 },
    { lat: 48.968458, lng: 20.771344 },
    { lat: 48.968479, lng: 20.771615 },
    { lat: 48.968501, lng: 20.771727 },
    { lat: 48.968531, lng: 20.771815 },
    { lat: 48.968587, lng: 20.771904 },
    { lat: 48.968674, lng: 20.77205 },
    { lat: 48.968817, lng: 20.772246 },
    { lat: 48.96895, lng: 20.772615 },
    { lat: 48.968943, lng: 20.772845 },
    { lat: 48.968917, lng: 20.773051 },
    { lat: 48.968848, lng: 20.77312 },
    { lat: 48.968909, lng: 20.773432 },
    { lat: 48.968996, lng: 20.773704 },
    { lat: 48.969379, lng: 20.7744 },
    { lat: 48.9693156, lng: 20.774595 },
    { lat: 48.969659, lng: 20.775061 },
    { lat: 48.969835, lng: 20.775472 },
    { lat: 48.970204, lng: 20.776309 },
    { lat: 48.970272, lng: 20.776582 },
    { lat: 48.970429, lng: 20.776801 },
    { lat: 48.970621, lng: 20.777213 },
    { lat: 48.970714, lng: 20.777319 },
    { lat: 48.970739, lng: 20.777582 },
    { lat: 48.97078, lng: 20.777687 },
    { lat: 48.970772, lng: 20.777877 },
    { lat: 48.970769, lng: 20.778305 },
    { lat: 48.971037, lng: 20.77868 },
    { lat: 48.971226, lng: 20.778972 },
    { lat: 48.971293, lng: 20.779204 },
    { lat: 48.971298, lng: 20.779609 },
    { lat: 48.971205, lng: 20.780019 },
    { lat: 48.971192, lng: 20.780193 },
    { lat: 48.971171, lng: 20.780336 },
    { lat: 48.971177, lng: 20.780414 },
    { lat: 48.971185, lng: 20.780503 },
    { lat: 48.971107, lng: 20.780812 },
    { lat: 48.97119, lng: 20.780977 },
    { lat: 48.971287, lng: 20.781367 },
    { lat: 48.971295, lng: 20.7814 },
    { lat: 48.971288, lng: 20.78158 },
    { lat: 48.971285, lng: 20.781615 },
    { lat: 48.971341, lng: 20.781628 },
    { lat: 48.971419, lng: 20.781535 },
    { lat: 48.971513, lng: 20.781424 },
    { lat: 48.971522, lng: 20.781414 },
    { lat: 48.97155, lng: 20.78138 },
    { lat: 48.971593, lng: 20.781328 },
    { lat: 48.971665, lng: 20.781242 },
    { lat: 48.971794, lng: 20.78109 },
    { lat: 48.971819, lng: 20.781059 },
    { lat: 48.971841, lng: 20.781034 },
    { lat: 48.971932, lng: 20.780924 },
    { lat: 48.971954, lng: 20.780898 },
    { lat: 48.972086, lng: 20.780742 },
    { lat: 48.972099, lng: 20.780727 },
    { lat: 48.972341, lng: 20.780437 },
    { lat: 48.972366, lng: 20.780408 },
    { lat: 48.972423, lng: 20.780341 },
    { lat: 48.972434, lng: 20.780327 },
    { lat: 48.972502, lng: 20.780246 },
    { lat: 48.972718, lng: 20.779989 },
    { lat: 48.972813, lng: 20.779875 },
    { lat: 48.973265, lng: 20.779337 },
    { lat: 48.973408, lng: 20.778911 },
    { lat: 48.973412, lng: 20.778876 },
    { lat: 48.97363, lng: 20.778381 },
    { lat: 48.973659, lng: 20.778241 },
    { lat: 48.973655, lng: 20.778086 },
    { lat: 48.974185, lng: 20.776422 },
    { lat: 48.974258, lng: 20.776179 },
    { lat: 48.974581, lng: 20.775103 },
    { lat: 48.974611, lng: 20.775004 },
    { lat: 48.974712, lng: 20.774669 },
    { lat: 48.974749, lng: 20.774545 },
    { lat: 48.974977, lng: 20.773787 },
    { lat: 48.975005, lng: 20.773691 },
    { lat: 48.975025, lng: 20.773629 },
    { lat: 48.975549, lng: 20.771177 },
    { lat: 48.975658, lng: 20.771183 },
    { lat: 48.975673, lng: 20.771185 },
    { lat: 48.97571, lng: 20.771185 },
    { lat: 48.975785, lng: 20.771189 },
    { lat: 48.975805, lng: 20.77119 },
    { lat: 48.975966, lng: 20.77122 },
    { lat: 48.976232, lng: 20.771269 },
    { lat: 48.977767, lng: 20.771346 },
    { lat: 48.977839, lng: 20.771351 },
    { lat: 48.977875, lng: 20.771354 },
    { lat: 48.977902, lng: 20.771355 },
    { lat: 48.977947, lng: 20.771359 },
    { lat: 48.978748, lng: 20.771401 },
    { lat: 48.978904, lng: 20.771418 },
    { lat: 48.979101, lng: 20.771387 },
    { lat: 48.980138, lng: 20.771405 },
    { lat: 48.981241, lng: 20.771425 },
    { lat: 48.982067, lng: 20.771714 },
    { lat: 48.984277, lng: 20.772488 },
    { lat: 48.987042, lng: 20.774666 },
    { lat: 48.98847, lng: 20.774031 },
    { lat: 48.990546, lng: 20.773107 },
    { lat: 48.99152, lng: 20.7727 },
    { lat: 48.991619, lng: 20.772659 },
    { lat: 48.991595, lng: 20.77227 },
    { lat: 48.991956, lng: 20.772254 },
    { lat: 48.992849, lng: 20.772036 },
    { lat: 48.993072, lng: 20.771893 },
    { lat: 48.992599, lng: 20.771478 },
    { lat: 48.992733, lng: 20.771268 },
    { lat: 48.994142, lng: 20.770111 },
    { lat: 48.995089, lng: 20.769367 },
    { lat: 48.994791, lng: 20.768989 },
    { lat: 48.994448, lng: 20.767968 },
    { lat: 48.99462, lng: 20.768123 },
    { lat: 48.99463, lng: 20.768184 },
    { lat: 48.99469, lng: 20.768274 },
    { lat: 48.994912, lng: 20.768339 },
    { lat: 48.99493, lng: 20.768449 },
    { lat: 48.995375, lng: 20.768606 },
    { lat: 48.995353, lng: 20.768753 },
    { lat: 48.995301, lng: 20.769021 },
    { lat: 48.9958, lng: 20.769261 },
    { lat: 48.995818, lng: 20.769513 },
    { lat: 48.996115, lng: 20.769734 },
    { lat: 48.996567, lng: 20.770114 },
    { lat: 48.99661, lng: 20.77003 },
    { lat: 48.996691, lng: 20.769954 },
    { lat: 48.997136, lng: 20.770272 },
    { lat: 48.997284, lng: 20.770636 },
    { lat: 48.997911, lng: 20.770487 },
    { lat: 48.998473, lng: 20.770122 },
    { lat: 48.998485, lng: 20.770023 },
    { lat: 48.998332, lng: 20.769961 },
    { lat: 48.997989, lng: 20.769614 },
    { lat: 48.99797, lng: 20.769576 },
    { lat: 48.998065, lng: 20.769451 },
    { lat: 48.998039, lng: 20.769221 },
    { lat: 48.998034, lng: 20.76918 },
    { lat: 48.997878, lng: 20.768648 },
    { lat: 48.997714, lng: 20.768292 },
    { lat: 48.997567, lng: 20.767934 },
    { lat: 48.997438, lng: 20.767679 },
    { lat: 48.997354, lng: 20.767422 },
    { lat: 48.997242, lng: 20.767187 },
    { lat: 48.9971289, lng: 20.7672132 },
    { lat: 48.99709, lng: 20.766908 },
    { lat: 48.997123, lng: 20.766837 },
    { lat: 48.997105, lng: 20.766561 },
    { lat: 48.99697, lng: 20.765831 },
    { lat: 48.99697, lng: 20.765583 },
    { lat: 48.997042, lng: 20.765279 },
    { lat: 48.997228, lng: 20.764877 },
    { lat: 48.997211, lng: 20.764794 },
    { lat: 48.997262, lng: 20.764633 },
    { lat: 48.997592, lng: 20.764516 },
    { lat: 48.997628, lng: 20.76452 },
    { lat: 48.997751, lng: 20.764532 },
    { lat: 48.997819, lng: 20.764511 },
    { lat: 48.997909, lng: 20.764399 },
    { lat: 48.997857, lng: 20.76427 },
    { lat: 48.998165, lng: 20.764151 },
    { lat: 48.99841, lng: 20.763977 },
    { lat: 48.998448, lng: 20.764019 },
    { lat: 48.998847, lng: 20.764167 },
    { lat: 48.998919, lng: 20.764258 },
    { lat: 48.998934, lng: 20.764353 },
    { lat: 48.99897, lng: 20.764451 },
    { lat: 48.998967, lng: 20.764538 },
    { lat: 48.999028, lng: 20.764668 },
    { lat: 48.999037, lng: 20.764829 },
    { lat: 48.999105, lng: 20.764979 },
    { lat: 48.999286, lng: 20.765012 },
    { lat: 49.000205, lng: 20.765422 },
    { lat: 49.001358, lng: 20.765718 },
    { lat: 49.001624, lng: 20.76585 },
    { lat: 49.002105, lng: 20.766014 },
    { lat: 49.002129, lng: 20.766245 },
    { lat: 49.002153, lng: 20.76641 },
    { lat: 49.002243, lng: 20.767035 },
    { lat: 49.002398, lng: 20.767485 },
    { lat: 49.002583, lng: 20.76749 },
    { lat: 49.002632, lng: 20.767654 },
    { lat: 49.002756, lng: 20.767596 },
    { lat: 49.002796, lng: 20.767976 },
    { lat: 49.002746, lng: 20.768058 },
    { lat: 49.002752, lng: 20.768201 },
    { lat: 49.002849, lng: 20.768474 },
    { lat: 49.002966, lng: 20.768911 },
    { lat: 49.00303, lng: 20.769282 },
    { lat: 49.002565, lng: 20.769109 },
    { lat: 49.00258, lng: 20.769325 },
    { lat: 49.002583, lng: 20.769468 },
    { lat: 49.002503, lng: 20.769804 },
    { lat: 49.0024893, lng: 20.7698569 },
    { lat: 49.002422, lng: 20.770116 },
    { lat: 49.002326, lng: 20.770385 },
    { lat: 49.002235, lng: 20.770604 },
    { lat: 49.00208, lng: 20.770832 },
    { lat: 49.001847, lng: 20.770882 },
    { lat: 49.001825, lng: 20.771034 },
    { lat: 49.001689, lng: 20.771065 },
    { lat: 49.001461, lng: 20.771258 },
    { lat: 49.001472, lng: 20.771307 },
    { lat: 49.00156, lng: 20.771418 },
    { lat: 49.001647, lng: 20.771476 },
    { lat: 49.001674, lng: 20.771543 },
    { lat: 49.001574, lng: 20.771608 },
    { lat: 49.001361, lng: 20.771652 },
    { lat: 49.00108, lng: 20.771717 },
    { lat: 49.000974, lng: 20.771659 },
    { lat: 49.000879, lng: 20.771762 },
    { lat: 49.000758, lng: 20.77179 },
    { lat: 49.000628, lng: 20.771887 },
    { lat: 49.000427, lng: 20.771896 },
    { lat: 49.000309, lng: 20.771888 },
    { lat: 49.000255, lng: 20.771885 },
    { lat: 49.000146, lng: 20.771923 },
    { lat: 49.000011, lng: 20.772004 },
    { lat: 48.999915, lng: 20.772023 },
    { lat: 48.999919, lng: 20.771826 },
    { lat: 48.999976, lng: 20.771545 },
    { lat: 48.999878, lng: 20.771546 },
    { lat: 48.999825, lng: 20.771597 },
    { lat: 48.999621, lng: 20.771468 },
    { lat: 48.999489, lng: 20.771426 },
    { lat: 48.999315, lng: 20.771427 },
    { lat: 48.999289, lng: 20.771469 },
    { lat: 48.999324, lng: 20.771626 },
    { lat: 48.999348, lng: 20.771901 },
    { lat: 48.999327, lng: 20.772263 },
    { lat: 48.99922, lng: 20.772644 },
    { lat: 48.999102, lng: 20.773259 },
    { lat: 48.999096, lng: 20.773257 },
    { lat: 48.999067, lng: 20.773242 },
    { lat: 48.999026, lng: 20.773222 },
    { lat: 48.998963, lng: 20.773277 },
    { lat: 48.99892, lng: 20.773313 },
    { lat: 48.99776, lng: 20.774313 },
    { lat: 48.997417, lng: 20.774178 },
    { lat: 48.996914, lng: 20.774036 },
    { lat: 48.995285, lng: 20.773654 },
    { lat: 48.995145, lng: 20.773889 },
    { lat: 48.994921, lng: 20.773766 },
    { lat: 48.994823, lng: 20.773675 },
    { lat: 48.994751, lng: 20.773576 },
    { lat: 48.994717, lng: 20.773529 },
    { lat: 48.99394, lng: 20.773347 },
    { lat: 48.993587, lng: 20.773239 },
    { lat: 48.993311, lng: 20.773185 },
    { lat: 48.993062, lng: 20.773192 },
    { lat: 48.992752, lng: 20.773106 },
    { lat: 48.992649, lng: 20.773222 },
    { lat: 48.992574, lng: 20.773205 },
    { lat: 48.992565, lng: 20.773097 },
    { lat: 48.992428, lng: 20.773076 },
    { lat: 48.992385, lng: 20.773013 },
    { lat: 48.991947, lng: 20.772947 },
    { lat: 48.991809, lng: 20.772886 },
    { lat: 48.991801, lng: 20.773045 },
    { lat: 48.991749, lng: 20.774442 },
    { lat: 48.991692, lng: 20.775246 },
    { lat: 48.992342, lng: 20.77533 },
    { lat: 48.992762, lng: 20.775394 },
    { lat: 48.992819, lng: 20.775403 },
    { lat: 48.993589, lng: 20.775583 },
    { lat: 48.994434, lng: 20.775806 },
    { lat: 48.994954, lng: 20.775913 },
    { lat: 48.995271, lng: 20.776007 },
    { lat: 48.995538, lng: 20.776067 },
    { lat: 48.996376, lng: 20.776292 },
    { lat: 48.997111, lng: 20.776431 },
    { lat: 48.997438, lng: 20.776474 },
    { lat: 48.997715, lng: 20.776493 },
    { lat: 48.998104, lng: 20.776465 },
    { lat: 48.99883, lng: 20.77655 },
    { lat: 48.998695, lng: 20.778001 },
    { lat: 48.99867, lng: 20.778427 },
    { lat: 48.998678, lng: 20.77885 },
    { lat: 48.998721, lng: 20.779059 },
    { lat: 48.998707, lng: 20.779101 },
    { lat: 48.998698, lng: 20.779125 },
    { lat: 48.998674, lng: 20.779197 },
    { lat: 48.998663, lng: 20.77923 },
    { lat: 48.998713, lng: 20.779533 },
    { lat: 48.999465, lng: 20.783716 },
    { lat: 48.999137, lng: 20.7879 },
    { lat: 48.998889, lng: 20.78865 },
    { lat: 48.998524, lng: 20.789778 },
    { lat: 48.9992246, lng: 20.7901272 },
    { lat: 49.0005224, lng: 20.7905156 },
    { lat: 49.0007356, lng: 20.7906903 },
    { lat: 49.0009718, lng: 20.7911754 },
    { lat: 49.0011597, lng: 20.7913966 },
    { lat: 49.0016624, lng: 20.7916771 },
    { lat: 49.0023082, lng: 20.791892 },
    { lat: 49.004226, lng: 20.7919149 },
    { lat: 49.0052383, lng: 20.7919958 },
    { lat: 49.0062701, lng: 20.7918549 },
    { lat: 49.0070961, lng: 20.7915197 },
    { lat: 49.0072543, lng: 20.7914421 },
    { lat: 49.0072516, lng: 20.791101 },
    { lat: 49.0073287, lng: 20.7906036 },
    { lat: 49.0073083, lng: 20.7902062 },
    { lat: 49.00737, lng: 20.7901371 },
    { lat: 49.0074369, lng: 20.7896315 },
    { lat: 49.0074418, lng: 20.7890926 },
    { lat: 49.0074942, lng: 20.7887212 },
    { lat: 49.0075308, lng: 20.7885792 },
    { lat: 49.007626, lng: 20.7885278 },
    { lat: 49.00764, lng: 20.7884166 },
    { lat: 49.0076128, lng: 20.7880867 },
    { lat: 49.0073776, lng: 20.7873394 },
    { lat: 49.0073553, lng: 20.787121 },
    { lat: 49.007363, lng: 20.7867317 },
    { lat: 49.0074932, lng: 20.7858755 },
    { lat: 49.0074128, lng: 20.7852792 },
    { lat: 49.007459, lng: 20.7847635 },
    { lat: 49.0075099, lng: 20.7847088 },
    { lat: 49.0074136, lng: 20.7845287 },
    { lat: 49.0074862, lng: 20.7844124 },
    { lat: 49.0072733, lng: 20.7841524 },
    { lat: 49.0066371, lng: 20.7829493 },
    { lat: 49.0059884, lng: 20.7810596 },
    { lat: 49.0057517, lng: 20.780662 },
    { lat: 49.0057611, lng: 20.7805114 },
    { lat: 49.0059481, lng: 20.7800643 },
    { lat: 49.0060019, lng: 20.7800901 },
    { lat: 49.0061551, lng: 20.78 },
    { lat: 49.0058844, lng: 20.7792039 },
    { lat: 49.0059427, lng: 20.779007 },
    { lat: 49.0061037, lng: 20.7788768 },
    { lat: 49.0061048, lng: 20.778392 },
    { lat: 49.006169, lng: 20.7782661 },
    { lat: 49.0066765, lng: 20.7785358 },
    { lat: 49.0066802, lng: 20.7780124 },
    { lat: 49.0062329, lng: 20.7777963 },
    { lat: 49.0062908, lng: 20.7775058 },
    { lat: 49.0066699, lng: 20.7776067 },
    { lat: 49.0066677, lng: 20.7773068 },
    { lat: 49.0066558, lng: 20.7768543 },
    { lat: 49.0069876, lng: 20.7767186 },
    { lat: 49.0071192, lng: 20.7767169 },
    { lat: 49.0074103, lng: 20.7768674 },
    { lat: 49.0075495, lng: 20.7768192 },
    { lat: 49.0075977, lng: 20.7766522 },
    { lat: 49.0075804, lng: 20.7763198 },
    { lat: 49.007676, lng: 20.7758693 },
    { lat: 49.0076285, lng: 20.7750542 },
    { lat: 49.0076638, lng: 20.7747734 },
    { lat: 49.0078515, lng: 20.7737296 },
    { lat: 49.0081246, lng: 20.7731407 },
    { lat: 49.0082684, lng: 20.772711 },
    { lat: 49.0081092, lng: 20.7718612 },
    { lat: 49.0081058, lng: 20.7712426 },
    { lat: 49.0080012, lng: 20.7706999 },
    { lat: 49.007978, lng: 20.7702292 },
    { lat: 49.0081921, lng: 20.769493 },
    { lat: 49.0082049, lng: 20.7690194 },
    { lat: 49.0081325, lng: 20.7688786 },
    { lat: 49.0080104, lng: 20.7688673 },
    { lat: 49.0078519, lng: 20.7687122 },
    { lat: 49.0078513, lng: 20.7684391 },
    { lat: 49.0079451, lng: 20.7680909 },
    { lat: 49.0079121, lng: 20.7677828 },
    { lat: 49.0079066, lng: 20.7677315 },
    { lat: 49.007923, lng: 20.7669116 },
    { lat: 49.0080586, lng: 20.766575 },
    { lat: 49.0080772, lng: 20.7661063 },
    { lat: 49.0081926, lng: 20.7657714 },
    { lat: 49.0082305, lng: 20.7658218 },
    { lat: 49.0082304, lng: 20.7657268 },
    { lat: 49.0085927, lng: 20.7652059 },
    { lat: 49.00875, lng: 20.7647741 },
    { lat: 49.0090382, lng: 20.7647436 },
    { lat: 49.0091629, lng: 20.7645733 },
    { lat: 49.0092337, lng: 20.7643449 },
    { lat: 49.0095963, lng: 20.7644124 },
    { lat: 49.0095542, lng: 20.7640454 },
    { lat: 49.0094809, lng: 20.7613079 },
    { lat: 49.0092991, lng: 20.7586544 },
    { lat: 49.0107808, lng: 20.7585363 },
    { lat: 49.0109072, lng: 20.7587036 },
    { lat: 49.0109653, lng: 20.7586932 },
    { lat: 49.0111687, lng: 20.7584938 },
    { lat: 49.0114316, lng: 20.7584779 },
    { lat: 49.0114821, lng: 20.7584086 },
    { lat: 49.0114705, lng: 20.7579161 },
    { lat: 49.0115107, lng: 20.7576174 },
    { lat: 49.0115734, lng: 20.7575757 },
    { lat: 49.0121312, lng: 20.7574657 },
    { lat: 49.0135942, lng: 20.7567136 },
    { lat: 49.0146162, lng: 20.7565818 },
    { lat: 49.0149169, lng: 20.756461 },
    { lat: 49.0155869, lng: 20.756562 },
    { lat: 49.0158866, lng: 20.7565051 },
    { lat: 49.0165072, lng: 20.7561902 },
    { lat: 49.017241, lng: 20.7559666 },
    { lat: 49.0176427, lng: 20.7557035 },
    { lat: 49.0177285, lng: 20.755453 },
    { lat: 49.0178967, lng: 20.7553397 },
    { lat: 49.0192616, lng: 20.7550642 },
    { lat: 49.0194353, lng: 20.7549802 },
    { lat: 49.0203722, lng: 20.7597497 },
    { lat: 49.0208598, lng: 20.7593315 },
    { lat: 49.0220971, lng: 20.7584725 },
    { lat: 49.0224765, lng: 20.758153 },
    { lat: 49.0229849, lng: 20.757561 },
    { lat: 49.0245652, lng: 20.7562706 },
    { lat: 49.0250651, lng: 20.7560504 },
    { lat: 49.0253843, lng: 20.7561326 },
    { lat: 49.0256764, lng: 20.7564045 },
    { lat: 49.0264229, lng: 20.7566044 },
    { lat: 49.0272411, lng: 20.7566648 },
    { lat: 49.0285867, lng: 20.7562922 },
    { lat: 49.0288018, lng: 20.756197 },
    { lat: 49.0296068, lng: 20.75556 },
    { lat: 49.0296541, lng: 20.7553955 },
    { lat: 49.0313448, lng: 20.754046 },
    { lat: 49.032068, lng: 20.7535157 },
    { lat: 49.0339655, lng: 20.7524464 },
    { lat: 49.0345619, lng: 20.7518966 },
    { lat: 49.0362384, lng: 20.7537044 },
    { lat: 49.0373944, lng: 20.7524832 },
    { lat: 49.0389812, lng: 20.7519588 },
    { lat: 49.040411, lng: 20.751769 },
    { lat: 49.0416538, lng: 20.7503607 },
    { lat: 49.0421955, lng: 20.750371 },
    { lat: 49.0442284, lng: 20.7510102 },
    { lat: 49.0452367, lng: 20.7512252 },
    { lat: 49.0459859, lng: 20.7517569 },
    { lat: 49.0465228, lng: 20.7530113 },
    { lat: 49.047591, lng: 20.7534579 },
    { lat: 49.0514295, lng: 20.7543278 },
    { lat: 49.0527402, lng: 20.7545288 },
    { lat: 49.0530544, lng: 20.7551501 },
    { lat: 49.0543106, lng: 20.7545322 },
    { lat: 49.0547913, lng: 20.7549842 },
    { lat: 49.0552833, lng: 20.7550556 },
    { lat: 49.0556854, lng: 20.7553859 },
    { lat: 49.0558719, lng: 20.7552412 },
    { lat: 49.056207, lng: 20.7551728 },
    { lat: 49.0563833, lng: 20.7550598 },
    { lat: 49.0565462, lng: 20.7545728 },
    { lat: 49.0570734, lng: 20.7538442 },
    { lat: 49.0573276, lng: 20.7532228 },
    { lat: 49.0573811, lng: 20.7527572 },
    { lat: 49.0576071, lng: 20.7520764 },
    { lat: 49.0582382, lng: 20.7503637 },
    { lat: 49.0591038, lng: 20.7488883 },
    { lat: 49.0594726, lng: 20.7483939 },
    { lat: 49.0597412, lng: 20.747833 },
    { lat: 49.0598159, lng: 20.7474949 },
    { lat: 49.0612904, lng: 20.746111 },
    { lat: 49.0615943, lng: 20.7457571 },
    { lat: 49.0621732, lng: 20.7449291 },
    { lat: 49.0625214, lng: 20.7441728 },
    { lat: 49.0626264, lng: 20.7439231 },
  ],
  Oľšavica: [
    { lat: 49.0675823, lng: 20.7781553 },
    { lat: 49.0678495, lng: 20.7779101 },
    { lat: 49.0693137, lng: 20.7765439 },
    { lat: 49.0699716, lng: 20.7758152 },
    { lat: 49.0701523, lng: 20.7754143 },
    { lat: 49.0702513, lng: 20.7744395 },
    { lat: 49.0704033, lng: 20.7742787 },
    { lat: 49.0723541, lng: 20.7738969 },
    { lat: 49.072879, lng: 20.7737415 },
    { lat: 49.0744858, lng: 20.773047 },
    { lat: 49.0757424, lng: 20.7727701 },
    { lat: 49.0766413, lng: 20.7724663 },
    { lat: 49.0780259, lng: 20.7723043 },
    { lat: 49.0800829, lng: 20.7722887 },
    { lat: 49.0806615, lng: 20.7721931 },
    { lat: 49.0813654, lng: 20.7719797 },
    { lat: 49.0816574, lng: 20.7717964 },
    { lat: 49.0822794, lng: 20.7712326 },
    { lat: 49.082574, lng: 20.7710634 },
    { lat: 49.0838287, lng: 20.7706612 },
    { lat: 49.0856303, lng: 20.7698068 },
    { lat: 49.0863871, lng: 20.7693646 },
    { lat: 49.0871075, lng: 20.7687828 },
    { lat: 49.0873027, lng: 20.7685717 },
    { lat: 49.0872178, lng: 20.7684966 },
    { lat: 49.0882172, lng: 20.7677791 },
    { lat: 49.089463, lng: 20.7672227 },
    { lat: 49.0926829, lng: 20.7646935 },
    { lat: 49.0955241, lng: 20.7621821 },
    { lat: 49.0970737, lng: 20.7648167 },
    { lat: 49.0968039, lng: 20.76521 },
    { lat: 49.0965364, lng: 20.7661278 },
    { lat: 49.0962393, lng: 20.7669359 },
    { lat: 49.0957464, lng: 20.7679061 },
    { lat: 49.0943891, lng: 20.7690448 },
    { lat: 49.0942794, lng: 20.7698026 },
    { lat: 49.0948061, lng: 20.7696415 },
    { lat: 49.0957809, lng: 20.7695114 },
    { lat: 49.0965518, lng: 20.7691211 },
    { lat: 49.0970768, lng: 20.7686179 },
    { lat: 49.0979001, lng: 20.7674371 },
    { lat: 49.0981273, lng: 20.7669442 },
    { lat: 49.0986218, lng: 20.7663115 },
    { lat: 49.0989706, lng: 20.7661407 },
    { lat: 49.0992399, lng: 20.7660844 },
    { lat: 49.0997535, lng: 20.7656158 },
    { lat: 49.1011136, lng: 20.7709832 },
    { lat: 49.1019907, lng: 20.7740958 },
    { lat: 49.1023199, lng: 20.7736245 },
    { lat: 49.1026161, lng: 20.7727906 },
    { lat: 49.103679, lng: 20.76908 },
    { lat: 49.1047848, lng: 20.7679667 },
    { lat: 49.1059604, lng: 20.7675868 },
    { lat: 49.1071571, lng: 20.7667848 },
    { lat: 49.1075953, lng: 20.7662165 },
    { lat: 49.1076937, lng: 20.764838 },
    { lat: 49.109037, lng: 20.765671 },
    { lat: 49.109127, lng: 20.765586 },
    { lat: 49.109322, lng: 20.76542 },
    { lat: 49.109446, lng: 20.765029 },
    { lat: 49.110826, lng: 20.764331 },
    { lat: 49.111109, lng: 20.764336 },
    { lat: 49.111342, lng: 20.764622 },
    { lat: 49.111749, lng: 20.764182 },
    { lat: 49.111942, lng: 20.76407 },
    { lat: 49.112149, lng: 20.763821 },
    { lat: 49.112582, lng: 20.763567 },
    { lat: 49.112606, lng: 20.763304 },
    { lat: 49.113103, lng: 20.762582 },
    { lat: 49.113295, lng: 20.762171 },
    { lat: 49.113824, lng: 20.761506 },
    { lat: 49.114192, lng: 20.761338 },
    { lat: 49.115076, lng: 20.760648 },
    { lat: 49.115426, lng: 20.7605 },
    { lat: 49.115865, lng: 20.760371 },
    { lat: 49.116177, lng: 20.760203 },
    { lat: 49.116956, lng: 20.759918 },
    { lat: 49.117254, lng: 20.759769 },
    { lat: 49.117487, lng: 20.759728 },
    { lat: 49.117637, lng: 20.759565 },
    { lat: 49.118193, lng: 20.759125 },
    { lat: 49.118472, lng: 20.758816 },
    { lat: 49.118692, lng: 20.758128 },
    { lat: 49.118865, lng: 20.756761 },
    { lat: 49.118984, lng: 20.756256 },
    { lat: 49.119578, lng: 20.756845 },
    { lat: 49.119587, lng: 20.756823 },
    { lat: 49.119811, lng: 20.756562 },
    { lat: 49.120012, lng: 20.756327 },
    { lat: 49.12019, lng: 20.756119 },
    { lat: 49.120391, lng: 20.755883 },
    { lat: 49.120619, lng: 20.755616 },
    { lat: 49.120825, lng: 20.755377 },
    { lat: 49.121084, lng: 20.755072 },
    { lat: 49.121266, lng: 20.75486 },
    { lat: 49.121471, lng: 20.754619 },
    { lat: 49.121504, lng: 20.754581 },
    { lat: 49.121756, lng: 20.754276 },
    { lat: 49.121991, lng: 20.753993 },
    { lat: 49.122214, lng: 20.753723 },
    { lat: 49.122444, lng: 20.753445 },
    { lat: 49.122633, lng: 20.753217 },
    { lat: 49.122812, lng: 20.753 },
    { lat: 49.122904, lng: 20.752888 },
    { lat: 49.122955, lng: 20.752733 },
    { lat: 49.123052, lng: 20.752441 },
    { lat: 49.123184, lng: 20.752043 },
    { lat: 49.123343, lng: 20.75156 },
    { lat: 49.123383, lng: 20.75144 },
    { lat: 49.123664, lng: 20.750588 },
    { lat: 49.123858, lng: 20.750263 },
    { lat: 49.124158, lng: 20.750058 },
    { lat: 49.123735, lng: 20.749475 },
    { lat: 49.123732, lng: 20.749465 },
    { lat: 49.12365, lng: 20.749159 },
    { lat: 49.123585, lng: 20.749022 },
    { lat: 49.123154, lng: 20.748117 },
    { lat: 49.122992, lng: 20.747777 },
    { lat: 49.122834, lng: 20.747974 },
    { lat: 49.12226, lng: 20.747314 },
    { lat: 49.121964, lng: 20.746926 },
    { lat: 49.121463, lng: 20.746268 },
    { lat: 49.121464, lng: 20.746257 },
    { lat: 49.121647, lng: 20.745516 },
    { lat: 49.121809, lng: 20.744854 },
    { lat: 49.121866, lng: 20.744124 },
    { lat: 49.121926, lng: 20.743332 },
    { lat: 49.122069, lng: 20.742338 },
    { lat: 49.12199, lng: 20.74205 },
    { lat: 49.121922, lng: 20.741799 },
    { lat: 49.121799, lng: 20.74135 },
    { lat: 49.121836, lng: 20.740765 },
    { lat: 49.121821, lng: 20.740482 },
    { lat: 49.121948, lng: 20.740014 },
    { lat: 49.121928, lng: 20.73983 },
    { lat: 49.121824, lng: 20.738883 },
    { lat: 49.121834, lng: 20.73857 },
    { lat: 49.121738, lng: 20.738224 },
    { lat: 49.121764, lng: 20.737613 },
    { lat: 49.121822, lng: 20.737151 },
    { lat: 49.121829, lng: 20.736746 },
    { lat: 49.121677, lng: 20.735977 },
    { lat: 49.121712, lng: 20.735766 },
    { lat: 49.121816, lng: 20.735037 },
    { lat: 49.121819, lng: 20.735021 },
    { lat: 49.12182, lng: 20.735018 },
    { lat: 49.121898, lng: 20.734801 },
    { lat: 49.121899, lng: 20.734799 },
    { lat: 49.1219, lng: 20.734795 },
    { lat: 49.121938, lng: 20.734462 },
    { lat: 49.121934, lng: 20.734461 },
    { lat: 49.121916, lng: 20.734455 },
    { lat: 49.1193642, lng: 20.7337489 },
    { lat: 49.1176444, lng: 20.732473 },
    { lat: 49.1169889, lng: 20.7323058 },
    { lat: 49.1133346, lng: 20.7334789 },
    { lat: 49.1129058, lng: 20.7329019 },
    { lat: 49.1118145, lng: 20.7319256 },
    { lat: 49.1115324, lng: 20.7313971 },
    { lat: 49.1112893, lng: 20.731105 },
    { lat: 49.1109098, lng: 20.7308439 },
    { lat: 49.1103743, lng: 20.7306591 },
    { lat: 49.1101261, lng: 20.7307117 },
    { lat: 49.1088172, lng: 20.7314846 },
    { lat: 49.1074732, lng: 20.7313568 },
    { lat: 49.1061903, lng: 20.7301853 },
    { lat: 49.1053928, lng: 20.7297233 },
    { lat: 49.1050101, lng: 20.7296924 },
    { lat: 49.1038475, lng: 20.7302048 },
    { lat: 49.103588, lng: 20.7303837 },
    { lat: 49.1033075, lng: 20.7308251 },
    { lat: 49.1026677, lng: 20.7316414 },
    { lat: 49.1023947, lng: 20.7315631 },
    { lat: 49.1017288, lng: 20.7317981 },
    { lat: 49.1015556, lng: 20.7315482 },
    { lat: 49.1015574, lng: 20.7312499 },
    { lat: 49.1014202, lng: 20.7306446 },
    { lat: 49.1011341, lng: 20.7303838 },
    { lat: 49.1006324, lng: 20.7302027 },
    { lat: 49.0996971, lng: 20.7286999 },
    { lat: 49.099171, lng: 20.7277004 },
    { lat: 49.0982154, lng: 20.7270614 },
    { lat: 49.0973741, lng: 20.7258661 },
    { lat: 49.0966016, lng: 20.7259529 },
    { lat: 49.0958412, lng: 20.7259342 },
    { lat: 49.0958533, lng: 20.7250668 },
    { lat: 49.0954749, lng: 20.7246753 },
    { lat: 49.0952623, lng: 20.7251305 },
    { lat: 49.0951559, lng: 20.7251751 },
    { lat: 49.0950129, lng: 20.7251146 },
    { lat: 49.0948464, lng: 20.7255305 },
    { lat: 49.0945878, lng: 20.7268131 },
    { lat: 49.0946657, lng: 20.7270758 },
    { lat: 49.0944815, lng: 20.7287193 },
    { lat: 49.0936779, lng: 20.7287257 },
    { lat: 49.0934086, lng: 20.7288094 },
    { lat: 49.0928778, lng: 20.728792 },
    { lat: 49.0926347, lng: 20.7288284 },
    { lat: 49.0923397, lng: 20.7289846 },
    { lat: 49.0920086, lng: 20.7293626 },
    { lat: 49.0914616, lng: 20.7304215 },
    { lat: 49.0911549, lng: 20.7312036 },
    { lat: 49.0908572, lng: 20.7323761 },
    { lat: 49.0900435, lng: 20.7319968 },
    { lat: 49.0881264, lng: 20.7300374 },
    { lat: 49.087157, lng: 20.7294571 },
    { lat: 49.0864498, lng: 20.7286173 },
    { lat: 49.0850969, lng: 20.726484 },
    { lat: 49.0821652, lng: 20.7315514 },
    { lat: 49.0797885, lng: 20.7331682 },
    { lat: 49.0759154, lng: 20.7363146 },
    { lat: 49.0751818, lng: 20.7369977 },
    { lat: 49.0744973, lng: 20.7384687 },
    { lat: 49.0742209, lng: 20.7388675 },
    { lat: 49.0709943, lng: 20.7418356 },
    { lat: 49.0707373, lng: 20.7411808 },
    { lat: 49.0683712, lng: 20.7433969 },
    { lat: 49.0657105, lng: 20.7422846 },
    { lat: 49.0656426, lng: 20.7423056 },
    { lat: 49.0655546, lng: 20.7430956 },
    { lat: 49.0656046, lng: 20.7435615 },
    { lat: 49.0659322, lng: 20.7446211 },
    { lat: 49.0662749, lng: 20.7456961 },
    { lat: 49.0665082, lng: 20.7466313 },
    { lat: 49.0663976, lng: 20.747528 },
    { lat: 49.0662079, lng: 20.748568 },
    { lat: 49.0660802, lng: 20.7507063 },
    { lat: 49.065755, lng: 20.7519267 },
    { lat: 49.0650346, lng: 20.753347 },
    { lat: 49.0648571, lng: 20.7539645 },
    { lat: 49.064612, lng: 20.7545221 },
    { lat: 49.0644524, lng: 20.7556227 },
    { lat: 49.0647467, lng: 20.7565986 },
    { lat: 49.0646523, lng: 20.7576031 },
    { lat: 49.0645936, lng: 20.7578272 },
    { lat: 49.0633666, lng: 20.7606528 },
    { lat: 49.0630254, lng: 20.7606155 },
    { lat: 49.0626342, lng: 20.7607268 },
    { lat: 49.0613178, lng: 20.7615193 },
    { lat: 49.0610642, lng: 20.7616044 },
    { lat: 49.060552, lng: 20.7612176 },
    { lat: 49.0597159, lng: 20.7616783 },
    { lat: 49.0595364, lng: 20.7619215 },
    { lat: 49.0590822, lng: 20.7616151 },
    { lat: 49.0587101, lng: 20.7619212 },
    { lat: 49.0584882, lng: 20.7618505 },
    { lat: 49.0566096, lng: 20.762656 },
    { lat: 49.0562482, lng: 20.7636895 },
    { lat: 49.0558034, lng: 20.764163 },
    { lat: 49.0554829, lng: 20.7647879 },
    { lat: 49.0549644, lng: 20.7652867 },
    { lat: 49.053346, lng: 20.7664539 },
    { lat: 49.0530127, lng: 20.7666875 },
    { lat: 49.0526594, lng: 20.766935 },
    { lat: 49.054742, lng: 20.7691042 },
    { lat: 49.0551282, lng: 20.7719151 },
    { lat: 49.0555274, lng: 20.7728372 },
    { lat: 49.0569389, lng: 20.7751934 },
    { lat: 49.0579717, lng: 20.775605 },
    { lat: 49.0587239, lng: 20.7764847 },
    { lat: 49.0593655, lng: 20.7779692 },
    { lat: 49.0605033, lng: 20.7776424 },
    { lat: 49.0610332, lng: 20.7783115 },
    { lat: 49.0610971, lng: 20.7793745 },
    { lat: 49.0616965, lng: 20.7792588 },
    { lat: 49.0637172, lng: 20.7801675 },
    { lat: 49.0646781, lng: 20.7802265 },
    { lat: 49.0655057, lng: 20.7794239 },
    { lat: 49.0675597, lng: 20.7780882 },
    { lat: 49.0675823, lng: 20.7781553 },
  ],
  Harakovce: [
    { lat: 49.002051, lng: 20.877787 },
    { lat: 49.002155, lng: 20.877689 },
    { lat: 49.002201, lng: 20.87757 },
    { lat: 49.002254, lng: 20.877482 },
    { lat: 49.002409, lng: 20.877368 },
    { lat: 49.002625, lng: 20.87713 },
    { lat: 49.002698, lng: 20.87706 },
    { lat: 49.002833, lng: 20.876906 },
    { lat: 49.002916, lng: 20.876852 },
    { lat: 49.00295, lng: 20.876753 },
    { lat: 49.002969, lng: 20.876613 },
    { lat: 49.003087, lng: 20.876457 },
    { lat: 49.003152, lng: 20.876182 },
    { lat: 49.003192, lng: 20.876078 },
    { lat: 49.00322, lng: 20.875901 },
    { lat: 49.00337, lng: 20.875581 },
    { lat: 49.003447, lng: 20.875565 },
    { lat: 49.003492, lng: 20.875507 },
    { lat: 49.003524, lng: 20.875396 },
    { lat: 49.00365, lng: 20.875207 },
    { lat: 49.003672, lng: 20.875192 },
    { lat: 49.003831, lng: 20.875181 },
    { lat: 49.003887, lng: 20.875088 },
    { lat: 49.003912, lng: 20.874939 },
    { lat: 49.004177, lng: 20.874272 },
    { lat: 49.004219, lng: 20.874223 },
    { lat: 49.004225, lng: 20.874139 },
    { lat: 49.004271, lng: 20.874002 },
    { lat: 49.004296, lng: 20.873964 },
    { lat: 49.004389, lng: 20.873917 },
    { lat: 49.004582, lng: 20.873913 },
    { lat: 49.004659, lng: 20.873863 },
    { lat: 49.004776, lng: 20.873911 },
    { lat: 49.004886, lng: 20.873813 },
    { lat: 49.005052, lng: 20.8738 },
    { lat: 49.005119, lng: 20.873738 },
    { lat: 49.005225, lng: 20.873525 },
    { lat: 49.005259, lng: 20.873376 },
    { lat: 49.00533, lng: 20.873303 },
    { lat: 49.005483, lng: 20.873247 },
    { lat: 49.005556, lng: 20.873193 },
    { lat: 49.005615, lng: 20.87307 },
    { lat: 49.00568, lng: 20.872814 },
    { lat: 49.005662, lng: 20.872536 },
    { lat: 49.005698, lng: 20.872429 },
    { lat: 49.005719, lng: 20.872241 },
    { lat: 49.005773, lng: 20.872043 },
    { lat: 49.005843, lng: 20.871933 },
    { lat: 49.005866, lng: 20.871759 },
    { lat: 49.005977, lng: 20.87157 },
    { lat: 49.006131, lng: 20.871454 },
    { lat: 49.006211, lng: 20.871339 },
    { lat: 49.006218, lng: 20.871212 },
    { lat: 49.006265, lng: 20.871102 },
    { lat: 49.006286, lng: 20.870983 },
    { lat: 49.006323, lng: 20.870882 },
    { lat: 49.006343, lng: 20.870764 },
    { lat: 49.006394, lng: 20.870668 },
    { lat: 49.006432, lng: 20.870558 },
    { lat: 49.006441, lng: 20.8705129 },
    { lat: 49.0065062, lng: 20.8704795 },
    { lat: 49.006587, lng: 20.870461 },
    { lat: 49.006744, lng: 20.870472 },
    { lat: 49.0068673, lng: 20.870494 },
    { lat: 49.006901, lng: 20.870515 },
    { lat: 49.0071637, lng: 20.8692344 },
    { lat: 49.0070708, lng: 20.866771 },
    { lat: 49.0071433, lng: 20.8646335 },
    { lat: 49.0063906, lng: 20.8617077 },
    { lat: 49.0065043, lng: 20.8614797 },
    { lat: 49.0070991, lng: 20.8611596 },
    { lat: 49.0076371, lng: 20.8606005 },
    { lat: 49.0080782, lng: 20.8603653 },
    { lat: 49.0084058, lng: 20.8601029 },
    { lat: 49.0085822, lng: 20.8595967 },
    { lat: 49.0085904, lng: 20.8593131 },
    { lat: 49.0082203, lng: 20.8584951 },
    { lat: 49.008306, lng: 20.8580355 },
    { lat: 49.0082681, lng: 20.8578854 },
    { lat: 49.0081264, lng: 20.8576592 },
    { lat: 49.0079805, lng: 20.8575718 },
    { lat: 49.0070372, lng: 20.8566452 },
    { lat: 49.0067698, lng: 20.8561009 },
    { lat: 49.0066896, lng: 20.8554665 },
    { lat: 49.0067392, lng: 20.8545778 },
    { lat: 49.006877, lng: 20.853345 },
    { lat: 49.0069003, lng: 20.8520815 },
    { lat: 49.006836, lng: 20.8508285 },
    { lat: 49.0067646, lng: 20.850382 },
    { lat: 49.0068534, lng: 20.8498903 },
    { lat: 49.0069061, lng: 20.8491558 },
    { lat: 49.0068555, lng: 20.8489307 },
    { lat: 49.0068526, lng: 20.8475645 },
    { lat: 49.0067597, lng: 20.8468218 },
    { lat: 49.0068102, lng: 20.8465543 },
    { lat: 49.0059682, lng: 20.8457974 },
    { lat: 49.006592, lng: 20.8441895 },
    { lat: 49.0065787, lng: 20.8439346 },
    { lat: 49.0055453, lng: 20.8391706 },
    { lat: 49.0048151, lng: 20.8378044 },
    { lat: 49.0047032, lng: 20.8374946 },
    { lat: 49.0050382, lng: 20.8371252 },
    { lat: 49.0049479, lng: 20.8370166 },
    { lat: 49.0050097, lng: 20.8367826 },
    { lat: 49.0043212, lng: 20.8363353 },
    { lat: 49.0039503, lng: 20.835927 },
    { lat: 49.0037732, lng: 20.8359149 },
    { lat: 49.0033518, lng: 20.8357382 },
    { lat: 49.0025957, lng: 20.8357136 },
    { lat: 49.0030284, lng: 20.8350114 },
    { lat: 49.003129, lng: 20.8343609 },
    { lat: 49.0043636, lng: 20.8336341 },
    { lat: 49.0039158, lng: 20.8332527 },
    { lat: 49.0033011, lng: 20.8325261 },
    { lat: 49.0033767, lng: 20.8322702 },
    { lat: 49.0034143, lng: 20.8318251 },
    { lat: 49.0033783, lng: 20.8314021 },
    { lat: 49.0032503, lng: 20.8309516 },
    { lat: 49.003062, lng: 20.8305184 },
    { lat: 49.0025992, lng: 20.8296933 },
    { lat: 49.0025459, lng: 20.8293846 },
    { lat: 49.0026353, lng: 20.8286795 },
    { lat: 49.0025687, lng: 20.8284101 },
    { lat: 49.0024393, lng: 20.8282211 },
    { lat: 49.0021007, lng: 20.8280946 },
    { lat: 49.0012086, lng: 20.8273582 },
    { lat: 49.0011032, lng: 20.8271403 },
    { lat: 49.0006633, lng: 20.826724 },
    { lat: 49.0001777, lng: 20.8259578 },
    { lat: 48.9998366, lng: 20.8251353 },
    { lat: 48.9996832, lng: 20.8241115 },
    { lat: 48.9996611, lng: 20.8235726 },
    { lat: 48.9998281, lng: 20.8224097 },
    { lat: 48.9967183, lng: 20.8221887 },
    { lat: 48.9950751, lng: 20.821932 },
    { lat: 48.9909754, lng: 20.8217011 },
    { lat: 48.9891967, lng: 20.8214283 },
    { lat: 48.9879018, lng: 20.8213884 },
    { lat: 48.98698, lng: 20.821099 },
    { lat: 48.986944, lng: 20.821105 },
    { lat: 48.986959, lng: 20.82131 },
    { lat: 48.986943, lng: 20.821654 },
    { lat: 48.986951, lng: 20.821979 },
    { lat: 48.986989, lng: 20.822278 },
    { lat: 48.987011, lng: 20.822619 },
    { lat: 48.987, lng: 20.823088 },
    { lat: 48.986934, lng: 20.82369 },
    { lat: 48.987277, lng: 20.825097 },
    { lat: 48.987698, lng: 20.826705 },
    { lat: 48.987719, lng: 20.826793 },
    { lat: 48.987661, lng: 20.826948 },
    { lat: 48.987632, lng: 20.827113 },
    { lat: 48.987753, lng: 20.827351 },
    { lat: 48.987817, lng: 20.827783 },
    { lat: 48.987903, lng: 20.828024 },
    { lat: 48.988014, lng: 20.828269 },
    { lat: 48.988249, lng: 20.828589 },
    { lat: 48.988263, lng: 20.828631 },
    { lat: 48.988297, lng: 20.828853 },
    { lat: 48.988325, lng: 20.828963 },
    { lat: 48.98843, lng: 20.829218 },
    { lat: 48.988727, lng: 20.829748 },
    { lat: 48.988876, lng: 20.830037 },
    { lat: 48.989043, lng: 20.830223 },
    { lat: 48.98913, lng: 20.830363 },
    { lat: 48.989436, lng: 20.830669 },
    { lat: 48.989603, lng: 20.830772 },
    { lat: 48.989755, lng: 20.831012 },
    { lat: 48.989893, lng: 20.831153 },
    { lat: 48.989957, lng: 20.831272 },
    { lat: 48.99002, lng: 20.831257 },
    { lat: 48.990249, lng: 20.831757 },
    { lat: 48.990625, lng: 20.832261 },
    { lat: 48.990755, lng: 20.832478 },
    { lat: 48.990849, lng: 20.832784 },
    { lat: 48.990895, lng: 20.832838 },
    { lat: 48.991013, lng: 20.833071 },
    { lat: 48.991, lng: 20.83337 },
    { lat: 48.991007, lng: 20.833397 },
    { lat: 48.991047, lng: 20.833471 },
    { lat: 48.991032, lng: 20.833573 },
    { lat: 48.99105, lng: 20.83369 },
    { lat: 48.991044, lng: 20.834007 },
    { lat: 48.991088, lng: 20.834332 },
    { lat: 48.991095, lng: 20.834641 },
    { lat: 48.991165, lng: 20.834823 },
    { lat: 48.991226, lng: 20.835144 },
    { lat: 48.991122, lng: 20.835645 },
    { lat: 48.991115, lng: 20.835873 },
    { lat: 48.991079, lng: 20.836128 },
    { lat: 48.991106, lng: 20.836311 },
    { lat: 48.991045, lng: 20.836785 },
    { lat: 48.991111, lng: 20.837145 },
    { lat: 48.990993, lng: 20.837489 },
    { lat: 48.990913, lng: 20.837766 },
    { lat: 48.990955, lng: 20.837962 },
    { lat: 48.991039, lng: 20.838074 },
    { lat: 48.991051, lng: 20.838176 },
    { lat: 48.991003, lng: 20.838253 },
    { lat: 48.990859, lng: 20.838278 },
    { lat: 48.990804, lng: 20.838357 },
    { lat: 48.990781, lng: 20.838623 },
    { lat: 48.990799, lng: 20.838628 },
    { lat: 48.990956, lng: 20.838845 },
    { lat: 48.99092, lng: 20.838931 },
    { lat: 48.990835, lng: 20.838989 },
    { lat: 48.990742, lng: 20.839421 },
    { lat: 48.990631, lng: 20.839415 },
    { lat: 48.990608, lng: 20.839414 },
    { lat: 48.990535, lng: 20.839563 },
    { lat: 48.990483, lng: 20.839662 },
    { lat: 48.990444, lng: 20.839938 },
    { lat: 48.990424, lng: 20.839931 },
    { lat: 48.9902524, lng: 20.8408967 },
    { lat: 48.9903503, lng: 20.8411308 },
    { lat: 48.9902874, lng: 20.841657 },
    { lat: 48.990446, lng: 20.8424433 },
    { lat: 48.9904224, lng: 20.8425731 },
    { lat: 48.990597, lng: 20.8428786 },
    { lat: 48.9908416, lng: 20.8430141 },
    { lat: 48.9908121, lng: 20.8431727 },
    { lat: 48.9911339, lng: 20.8433003 },
    { lat: 48.9911336, lng: 20.8433939 },
    { lat: 48.9910846, lng: 20.8434357 },
    { lat: 48.9911085, lng: 20.8435424 },
    { lat: 48.991227, lng: 20.843551 },
    { lat: 48.9915381, lng: 20.8440271 },
    { lat: 48.99152, lng: 20.8441858 },
    { lat: 48.9918722, lng: 20.8454673 },
    { lat: 48.9917889, lng: 20.8456929 },
    { lat: 48.9917976, lng: 20.8460786 },
    { lat: 48.9917597, lng: 20.8462873 },
    { lat: 48.9918217, lng: 20.8463896 },
    { lat: 48.9918582, lng: 20.8467326 },
    { lat: 48.9920561, lng: 20.847447 },
    { lat: 48.9920757, lng: 20.8476634 },
    { lat: 48.992128, lng: 20.8477424 },
    { lat: 48.9921691, lng: 20.8480535 },
    { lat: 48.9922997, lng: 20.8482514 },
    { lat: 48.9924106, lng: 20.8485627 },
    { lat: 48.992379, lng: 20.8490244 },
    { lat: 48.9926262, lng: 20.8497694 },
    { lat: 48.9926611, lng: 20.8497017 },
    { lat: 48.9928233, lng: 20.8498696 },
    { lat: 48.9929091, lng: 20.8498746 },
    { lat: 48.9934613, lng: 20.8510131 },
    { lat: 48.9938005, lng: 20.8528362 },
    { lat: 48.9938554, lng: 20.8536183 },
    { lat: 48.9941934, lng: 20.8545773 },
    { lat: 48.9942304, lng: 20.8550059 },
    { lat: 48.9941564, lng: 20.8559043 },
    { lat: 48.9940355, lng: 20.856383 },
    { lat: 48.9939821, lng: 20.8568647 },
    { lat: 48.9938457, lng: 20.857283 },
    { lat: 48.9938417, lng: 20.8574639 },
    { lat: 48.9941947, lng: 20.8599613 },
    { lat: 48.9958397, lng: 20.8644284 },
    { lat: 49.0006038, lng: 20.8685069 },
    { lat: 49.0004891, lng: 20.8690741 },
    { lat: 49.0005367, lng: 20.8694123 },
    { lat: 49.0005136, lng: 20.8698547 },
    { lat: 49.0006035, lng: 20.8702509 },
    { lat: 49.0008918, lng: 20.8707169 },
    { lat: 49.001532, lng: 20.8712039 },
    { lat: 49.0014897, lng: 20.8720131 },
    { lat: 49.0016626, lng: 20.8726641 },
    { lat: 49.0016838, lng: 20.872988 },
    { lat: 49.0015874, lng: 20.87419 },
    { lat: 49.0016494, lng: 20.8752162 },
    { lat: 49.0015881, lng: 20.8757318 },
    { lat: 49.001634, lng: 20.8758243 },
    { lat: 49.0016694, lng: 20.8763664 },
    { lat: 49.0016079, lng: 20.8765147 },
    { lat: 49.0017302, lng: 20.8766015 },
    { lat: 49.002051, lng: 20.877787 },
  ],
  Studenec: [
    { lat: 49.0069876, lng: 20.7767186 },
    { lat: 49.0070839, lng: 20.7767664 },
    { lat: 49.0071009, lng: 20.7773211 },
    { lat: 49.0075137, lng: 20.7782854 },
    { lat: 49.0078367, lng: 20.7779883 },
    { lat: 49.0078846, lng: 20.7780429 },
    { lat: 49.007924, lng: 20.7782671 },
    { lat: 49.0087246, lng: 20.778369 },
    { lat: 49.0091928, lng: 20.7786257 },
    { lat: 49.0094352, lng: 20.7788346 },
    { lat: 49.0097843, lng: 20.7789682 },
    { lat: 49.0104943, lng: 20.7790839 },
    { lat: 49.0106476, lng: 20.7792261 },
    { lat: 49.0107389, lng: 20.7794231 },
    { lat: 49.0108274, lng: 20.7794701 },
    { lat: 49.0108791, lng: 20.7794721 },
    { lat: 49.0109148, lng: 20.7791694 },
    { lat: 49.0109828, lng: 20.779118 },
    { lat: 49.0112343, lng: 20.7793574 },
    { lat: 49.0114075, lng: 20.7799326 },
    { lat: 49.011597, lng: 20.7801404 },
    { lat: 49.0117919, lng: 20.7801742 },
    { lat: 49.0119121, lng: 20.7800774 },
    { lat: 49.0122119, lng: 20.7800493 },
    { lat: 49.0124787, lng: 20.780248 },
    { lat: 49.0127325, lng: 20.780262 },
    { lat: 49.0130382, lng: 20.780411 },
    { lat: 49.013569, lng: 20.780459 },
    { lat: 49.0145137, lng: 20.7811181 },
    { lat: 49.0147648, lng: 20.7811198 },
    { lat: 49.0151233, lng: 20.7807797 },
    { lat: 49.015726, lng: 20.7806605 },
    { lat: 49.0157177, lng: 20.7803951 },
    { lat: 49.0159762, lng: 20.779282 },
    { lat: 49.0166226, lng: 20.7770897 },
    { lat: 49.017109, lng: 20.7752244 },
    { lat: 49.0174939, lng: 20.773382 },
    { lat: 49.0189454, lng: 20.7747856 },
    { lat: 49.0194437, lng: 20.7750606 },
    { lat: 49.0205001, lng: 20.7754981 },
    { lat: 49.0209521, lng: 20.7758038 },
    { lat: 49.0217101, lng: 20.7719783 },
    { lat: 49.0218937, lng: 20.7719193 },
    { lat: 49.0229741, lng: 20.7720367 },
    { lat: 49.0242308, lng: 20.7726362 },
    { lat: 49.0265016, lng: 20.7738864 },
    { lat: 49.0265595, lng: 20.7739183 },
    { lat: 49.0288331, lng: 20.775007 },
    { lat: 49.0288898, lng: 20.7743736 },
    { lat: 49.0306805, lng: 20.7756986 },
    { lat: 49.0310933, lng: 20.7758795 },
    { lat: 49.0317912, lng: 20.7766243 },
    { lat: 49.0321991, lng: 20.7768902 },
    { lat: 49.0329547, lng: 20.7771623 },
    { lat: 49.0333974, lng: 20.7772019 },
    { lat: 49.0336332, lng: 20.7771191 },
    { lat: 49.0336485, lng: 20.7770443 },
    { lat: 49.0339654, lng: 20.7768972 },
    { lat: 49.0343129, lng: 20.7769208 },
    { lat: 49.0343075, lng: 20.7768326 },
    { lat: 49.0352462, lng: 20.7768575 },
    { lat: 49.0354795, lng: 20.7764512 },
    { lat: 49.0361222, lng: 20.7762868 },
    { lat: 49.036205, lng: 20.7764032 },
    { lat: 49.0366493, lng: 20.7762299 },
    { lat: 49.0369452, lng: 20.7757397 },
    { lat: 49.0375291, lng: 20.775538 },
    { lat: 49.0377206, lng: 20.7748933 },
    { lat: 49.038084, lng: 20.7743115 },
    { lat: 49.0383759, lng: 20.7740296 },
    { lat: 49.0388596, lng: 20.7737969 },
    { lat: 49.0391511, lng: 20.7739234 },
    { lat: 49.0398951, lng: 20.7734052 },
    { lat: 49.0403574, lng: 20.7733299 },
    { lat: 49.0407702, lng: 20.7736138 },
    { lat: 49.0412493, lng: 20.773601 },
    { lat: 49.0417738, lng: 20.773469 },
    { lat: 49.042335, lng: 20.7736125 },
    { lat: 49.042677, lng: 20.7734173 },
    { lat: 49.0432018, lng: 20.7724223 },
    { lat: 49.0434234, lng: 20.7722229 },
    { lat: 49.0436018, lng: 20.7721439 },
    { lat: 49.0437429, lng: 20.7718322 },
    { lat: 49.0439634, lng: 20.7710868 },
    { lat: 49.0444084, lng: 20.7706798 },
    { lat: 49.0450104, lng: 20.7698953 },
    { lat: 49.045069, lng: 20.7693853 },
    { lat: 49.0452487, lng: 20.7694376 },
    { lat: 49.0457071, lng: 20.7697651 },
    { lat: 49.0460191, lng: 20.7689301 },
    { lat: 49.0461491, lng: 20.7687847 },
    { lat: 49.0466372, lng: 20.7690635 },
    { lat: 49.0467523, lng: 20.7689739 },
    { lat: 49.0468456, lng: 20.7686818 },
    { lat: 49.0470444, lng: 20.7685703 },
    { lat: 49.0476479, lng: 20.7684788 },
    { lat: 49.0480149, lng: 20.7685475 },
    { lat: 49.0482549, lng: 20.768456 },
    { lat: 49.048705, lng: 20.7679697 },
    { lat: 49.0488709, lng: 20.7679459 },
    { lat: 49.0491083, lng: 20.7681036 },
    { lat: 49.0492951, lng: 20.7681029 },
    { lat: 49.049616, lng: 20.767815 },
    { lat: 49.0497674, lng: 20.7673818 },
    { lat: 49.0500118, lng: 20.7671611 },
    { lat: 49.0501667, lng: 20.767117 },
    { lat: 49.0504743, lng: 20.766733 },
    { lat: 49.0507309, lng: 20.7666035 },
    { lat: 49.0511099, lng: 20.7666285 },
    { lat: 49.0512576, lng: 20.7667985 },
    { lat: 49.0514559, lng: 20.7665754 },
    { lat: 49.0516217, lng: 20.7662031 },
    { lat: 49.0517262, lng: 20.7661961 },
    { lat: 49.0520403, lng: 20.7663541 },
    { lat: 49.0525814, lng: 20.7664439 },
    { lat: 49.0525867, lng: 20.7668853 },
    { lat: 49.0526594, lng: 20.766935 },
    { lat: 49.0530127, lng: 20.7666875 },
    { lat: 49.053346, lng: 20.7664539 },
    { lat: 49.0549644, lng: 20.7652867 },
    { lat: 49.0554829, lng: 20.7647879 },
    { lat: 49.0558034, lng: 20.764163 },
    { lat: 49.0562482, lng: 20.7636895 },
    { lat: 49.0566096, lng: 20.762656 },
    { lat: 49.0584882, lng: 20.7618505 },
    { lat: 49.0587101, lng: 20.7619212 },
    { lat: 49.0590822, lng: 20.7616151 },
    { lat: 49.0595364, lng: 20.7619215 },
    { lat: 49.0597159, lng: 20.7616783 },
    { lat: 49.060552, lng: 20.7612176 },
    { lat: 49.0610642, lng: 20.7616044 },
    { lat: 49.0613178, lng: 20.7615193 },
    { lat: 49.0626342, lng: 20.7607268 },
    { lat: 49.0630254, lng: 20.7606155 },
    { lat: 49.0633666, lng: 20.7606528 },
    { lat: 49.0645936, lng: 20.7578272 },
    { lat: 49.0646523, lng: 20.7576031 },
    { lat: 49.0647467, lng: 20.7565986 },
    { lat: 49.0644524, lng: 20.7556227 },
    { lat: 49.064612, lng: 20.7545221 },
    { lat: 49.0648571, lng: 20.7539645 },
    { lat: 49.0650346, lng: 20.753347 },
    { lat: 49.065755, lng: 20.7519267 },
    { lat: 49.0660802, lng: 20.7507063 },
    { lat: 49.0662079, lng: 20.748568 },
    { lat: 49.0663976, lng: 20.747528 },
    { lat: 49.0665082, lng: 20.7466313 },
    { lat: 49.0662749, lng: 20.7456961 },
    { lat: 49.0659322, lng: 20.7446211 },
    { lat: 49.0658344, lng: 20.7448534 },
    { lat: 49.0657199, lng: 20.7451183 },
    { lat: 49.0653623, lng: 20.7455422 },
    { lat: 49.0649358, lng: 20.7459319 },
    { lat: 49.0643099, lng: 20.7460617 },
    { lat: 49.0634192, lng: 20.7457938 },
    { lat: 49.0633567, lng: 20.7452615 },
    { lat: 49.0632509, lng: 20.7450364 },
    { lat: 49.0628249, lng: 20.7444439 },
    { lat: 49.0626264, lng: 20.7439231 },
    { lat: 49.0625214, lng: 20.7441728 },
    { lat: 49.0621732, lng: 20.7449291 },
    { lat: 49.0615943, lng: 20.7457571 },
    { lat: 49.0612904, lng: 20.746111 },
    { lat: 49.0598159, lng: 20.7474949 },
    { lat: 49.0597412, lng: 20.747833 },
    { lat: 49.0594726, lng: 20.7483939 },
    { lat: 49.0591038, lng: 20.7488883 },
    { lat: 49.0582382, lng: 20.7503637 },
    { lat: 49.0576071, lng: 20.7520764 },
    { lat: 49.0573811, lng: 20.7527572 },
    { lat: 49.0573276, lng: 20.7532228 },
    { lat: 49.0570734, lng: 20.7538442 },
    { lat: 49.0565462, lng: 20.7545728 },
    { lat: 49.0563833, lng: 20.7550598 },
    { lat: 49.056207, lng: 20.7551728 },
    { lat: 49.0558719, lng: 20.7552412 },
    { lat: 49.0556854, lng: 20.7553859 },
    { lat: 49.0552833, lng: 20.7550556 },
    { lat: 49.0547913, lng: 20.7549842 },
    { lat: 49.0543106, lng: 20.7545322 },
    { lat: 49.0530544, lng: 20.7551501 },
    { lat: 49.0527402, lng: 20.7545288 },
    { lat: 49.0514295, lng: 20.7543278 },
    { lat: 49.047591, lng: 20.7534579 },
    { lat: 49.0465228, lng: 20.7530113 },
    { lat: 49.0459859, lng: 20.7517569 },
    { lat: 49.0452367, lng: 20.7512252 },
    { lat: 49.0442284, lng: 20.7510102 },
    { lat: 49.0421955, lng: 20.750371 },
    { lat: 49.0416538, lng: 20.7503607 },
    { lat: 49.040411, lng: 20.751769 },
    { lat: 49.0389812, lng: 20.7519588 },
    { lat: 49.0373944, lng: 20.7524832 },
    { lat: 49.0362384, lng: 20.7537044 },
    { lat: 49.0345619, lng: 20.7518966 },
    { lat: 49.0339655, lng: 20.7524464 },
    { lat: 49.032068, lng: 20.7535157 },
    { lat: 49.0313448, lng: 20.754046 },
    { lat: 49.0296541, lng: 20.7553955 },
    { lat: 49.0296068, lng: 20.75556 },
    { lat: 49.0288018, lng: 20.756197 },
    { lat: 49.0285867, lng: 20.7562922 },
    { lat: 49.0272411, lng: 20.7566648 },
    { lat: 49.0264229, lng: 20.7566044 },
    { lat: 49.0256764, lng: 20.7564045 },
    { lat: 49.0253843, lng: 20.7561326 },
    { lat: 49.0250651, lng: 20.7560504 },
    { lat: 49.0245652, lng: 20.7562706 },
    { lat: 49.0229849, lng: 20.757561 },
    { lat: 49.0224765, lng: 20.758153 },
    { lat: 49.0220971, lng: 20.7584725 },
    { lat: 49.0208598, lng: 20.7593315 },
    { lat: 49.0203722, lng: 20.7597497 },
    { lat: 49.0194353, lng: 20.7549802 },
    { lat: 49.0192616, lng: 20.7550642 },
    { lat: 49.0178967, lng: 20.7553397 },
    { lat: 49.0177285, lng: 20.755453 },
    { lat: 49.0176427, lng: 20.7557035 },
    { lat: 49.017241, lng: 20.7559666 },
    { lat: 49.0165072, lng: 20.7561902 },
    { lat: 49.0158866, lng: 20.7565051 },
    { lat: 49.0155869, lng: 20.756562 },
    { lat: 49.0149169, lng: 20.756461 },
    { lat: 49.0146162, lng: 20.7565818 },
    { lat: 49.0135942, lng: 20.7567136 },
    { lat: 49.0121312, lng: 20.7574657 },
    { lat: 49.0115734, lng: 20.7575757 },
    { lat: 49.0115107, lng: 20.7576174 },
    { lat: 49.0114705, lng: 20.7579161 },
    { lat: 49.0114821, lng: 20.7584086 },
    { lat: 49.0114316, lng: 20.7584779 },
    { lat: 49.0111687, lng: 20.7584938 },
    { lat: 49.0109653, lng: 20.7586932 },
    { lat: 49.0109072, lng: 20.7587036 },
    { lat: 49.0107808, lng: 20.7585363 },
    { lat: 49.0092991, lng: 20.7586544 },
    { lat: 49.0094809, lng: 20.7613079 },
    { lat: 49.0095542, lng: 20.7640454 },
    { lat: 49.0095963, lng: 20.7644124 },
    { lat: 49.0092337, lng: 20.7643449 },
    { lat: 49.0091629, lng: 20.7645733 },
    { lat: 49.0090382, lng: 20.7647436 },
    { lat: 49.00875, lng: 20.7647741 },
    { lat: 49.0085927, lng: 20.7652059 },
    { lat: 49.0082304, lng: 20.7657268 },
    { lat: 49.0082305, lng: 20.7658218 },
    { lat: 49.0081926, lng: 20.7657714 },
    { lat: 49.0080772, lng: 20.7661063 },
    { lat: 49.0080586, lng: 20.766575 },
    { lat: 49.007923, lng: 20.7669116 },
    { lat: 49.0079066, lng: 20.7677315 },
    { lat: 49.0079121, lng: 20.7677828 },
    { lat: 49.0079451, lng: 20.7680909 },
    { lat: 49.0078513, lng: 20.7684391 },
    { lat: 49.0078519, lng: 20.7687122 },
    { lat: 49.0080104, lng: 20.7688673 },
    { lat: 49.0081325, lng: 20.7688786 },
    { lat: 49.0082049, lng: 20.7690194 },
    { lat: 49.0081921, lng: 20.769493 },
    { lat: 49.007978, lng: 20.7702292 },
    { lat: 49.0080012, lng: 20.7706999 },
    { lat: 49.0081058, lng: 20.7712426 },
    { lat: 49.0081092, lng: 20.7718612 },
    { lat: 49.0082684, lng: 20.772711 },
    { lat: 49.0081246, lng: 20.7731407 },
    { lat: 49.0078515, lng: 20.7737296 },
    { lat: 49.0076638, lng: 20.7747734 },
    { lat: 49.0076285, lng: 20.7750542 },
    { lat: 49.007676, lng: 20.7758693 },
    { lat: 49.0075804, lng: 20.7763198 },
    { lat: 49.0075977, lng: 20.7766522 },
    { lat: 49.0075495, lng: 20.7768192 },
    { lat: 49.0074103, lng: 20.7768674 },
    { lat: 49.0071192, lng: 20.7767169 },
    { lat: 49.0069876, lng: 20.7767186 },
  ],
  SpišskýŠtvrtok: [
    { lat: 49.031746, lng: 20.456574 },
    { lat: 49.031468, lng: 20.456793 },
    { lat: 49.031219, lng: 20.456989 },
    { lat: 49.030881, lng: 20.457255 },
    { lat: 49.030404, lng: 20.456413 },
    { lat: 49.030048, lng: 20.455933 },
    { lat: 49.029704, lng: 20.455394 },
    { lat: 49.029335, lng: 20.454924 },
    { lat: 49.029043, lng: 20.45455 },
    { lat: 49.028767, lng: 20.454155 },
    { lat: 49.028001, lng: 20.453553 },
    { lat: 49.027641, lng: 20.453046 },
    { lat: 49.027513, lng: 20.452949 },
    { lat: 49.02673, lng: 20.45282 },
    { lat: 49.02632, lng: 20.45274 },
    { lat: 49.026089, lng: 20.452695 },
    { lat: 49.025542, lng: 20.452625 },
    { lat: 49.02518, lng: 20.452556 },
    { lat: 49.024324, lng: 20.452548 },
    { lat: 49.024124, lng: 20.452586 },
    { lat: 49.023716, lng: 20.452681 },
    { lat: 49.023037, lng: 20.452825 },
    { lat: 49.022553, lng: 20.452807 },
    { lat: 49.022189, lng: 20.45273 },
    { lat: 49.021719, lng: 20.452618 },
    { lat: 49.021521, lng: 20.452576 },
    { lat: 49.021016, lng: 20.452488 },
    { lat: 49.020548, lng: 20.452369 },
    { lat: 49.020468, lng: 20.452296 },
    { lat: 49.020136, lng: 20.452191 },
    { lat: 49.019798, lng: 20.452115 },
    { lat: 49.019387, lng: 20.452026 },
    { lat: 49.019166, lng: 20.451901 },
    { lat: 49.019112, lng: 20.45183 },
    { lat: 49.01884, lng: 20.451857 },
    { lat: 49.018748, lng: 20.451865 },
    { lat: 49.01835, lng: 20.451904 },
    { lat: 49.017802, lng: 20.452091 },
    { lat: 49.017463, lng: 20.452205 },
    { lat: 49.016791, lng: 20.452436 },
    { lat: 49.016448, lng: 20.452532 },
    { lat: 49.015878, lng: 20.45268 },
    { lat: 49.015654, lng: 20.452753 },
    { lat: 49.01534, lng: 20.452886 },
    { lat: 49.015171, lng: 20.452948 },
    { lat: 49.014815, lng: 20.453078 },
    { lat: 49.014756, lng: 20.452739 },
    { lat: 49.014618, lng: 20.451949 },
    { lat: 49.014604, lng: 20.451867 },
    { lat: 49.014331, lng: 20.450275 },
    { lat: 49.014328, lng: 20.450266 },
    { lat: 49.014318, lng: 20.450206 },
    { lat: 49.014318, lng: 20.450202 },
    { lat: 49.01433, lng: 20.450194 },
    { lat: 49.014323, lng: 20.450152 },
    { lat: 49.014671, lng: 20.449952 },
    { lat: 49.014762, lng: 20.449855 },
    { lat: 49.014843, lng: 20.449738 },
    { lat: 49.015073, lng: 20.449584 },
    { lat: 49.015257, lng: 20.449434 },
    { lat: 49.015647, lng: 20.449286 },
    { lat: 49.015575, lng: 20.449027 },
    { lat: 49.015488, lng: 20.44871 },
    { lat: 49.015409, lng: 20.448287 },
    { lat: 49.015424, lng: 20.448265 },
    { lat: 49.015724, lng: 20.447902 },
    { lat: 49.016071, lng: 20.447577 },
    { lat: 49.016311, lng: 20.44743 },
    { lat: 49.016539, lng: 20.447345 },
    { lat: 49.017015, lng: 20.447079 },
    { lat: 49.017063, lng: 20.447053 },
    { lat: 49.017091, lng: 20.447044 },
    { lat: 49.017177, lng: 20.447017 },
    { lat: 49.017185, lng: 20.447017 },
    { lat: 49.017284, lng: 20.447006 },
    { lat: 49.017305, lng: 20.446996 },
    { lat: 49.017374, lng: 20.446966 },
    { lat: 49.017384, lng: 20.446962 },
    { lat: 49.017409, lng: 20.446951 },
    { lat: 49.017457, lng: 20.446929 },
    { lat: 49.01748, lng: 20.446918 },
    { lat: 49.017738, lng: 20.446897 },
    { lat: 49.017857, lng: 20.446921 },
    { lat: 49.018006, lng: 20.446839 },
    { lat: 49.018342, lng: 20.446602 },
    { lat: 49.018843, lng: 20.446343 },
    { lat: 49.019224, lng: 20.446072 },
    { lat: 49.019686, lng: 20.445217 },
    { lat: 49.019843, lng: 20.445035 },
    { lat: 49.020148, lng: 20.444602 },
    { lat: 49.020538, lng: 20.444045 },
    { lat: 49.020759, lng: 20.443831 },
    { lat: 49.020815, lng: 20.443645 },
    { lat: 49.02097, lng: 20.443499 },
    { lat: 49.021473, lng: 20.443024 },
    { lat: 49.021789, lng: 20.442753 },
    { lat: 49.021932, lng: 20.442659 },
    { lat: 49.022139, lng: 20.442466 },
    { lat: 49.022462, lng: 20.442216 },
    { lat: 49.022699, lng: 20.44198 },
    { lat: 49.022979, lng: 20.441806 },
    { lat: 49.023177, lng: 20.441607 },
    { lat: 49.023284, lng: 20.441458 },
    { lat: 49.02344, lng: 20.441256 },
    { lat: 49.02382, lng: 20.440931 },
    { lat: 49.024394, lng: 20.440532 },
    { lat: 49.024964, lng: 20.440116 },
    { lat: 49.025128, lng: 20.43996 },
    { lat: 49.025047, lng: 20.439908 },
    { lat: 49.024909, lng: 20.439872 },
    { lat: 49.024674, lng: 20.439794 },
    { lat: 49.024671, lng: 20.43978 },
    { lat: 49.024655, lng: 20.439708 },
    { lat: 49.023976, lng: 20.44007 },
    { lat: 49.023685, lng: 20.440179 },
    { lat: 49.022877, lng: 20.440355 },
    { lat: 49.022439, lng: 20.440453 },
    { lat: 49.02138, lng: 20.440658 },
    { lat: 49.020863, lng: 20.440777 },
    { lat: 49.020656, lng: 20.440821 },
    { lat: 49.020427, lng: 20.440865 },
    { lat: 49.0203803, lng: 20.4408755 },
    { lat: 49.020294, lng: 20.440895 },
    { lat: 49.019629, lng: 20.441022 },
    { lat: 49.019484, lng: 20.441047 },
    { lat: 49.018909, lng: 20.441166 },
    { lat: 49.018155, lng: 20.441316 },
    { lat: 49.017601, lng: 20.441436 },
    { lat: 49.0172695, lng: 20.4415013 },
    { lat: 49.017164, lng: 20.441519 },
    { lat: 49.01639, lng: 20.441676 },
    { lat: 49.015956, lng: 20.441771 },
    { lat: 49.01576, lng: 20.441809 },
    { lat: 49.01553, lng: 20.441864 },
    { lat: 49.015445, lng: 20.441877 },
    { lat: 49.015371, lng: 20.441881 },
    { lat: 49.015394, lng: 20.441777 },
    { lat: 49.015398, lng: 20.441759 },
    { lat: 49.015351, lng: 20.441731 },
    { lat: 49.015337, lng: 20.441723 },
    { lat: 49.01524, lng: 20.441666 },
    { lat: 49.01522, lng: 20.441655 },
    { lat: 49.015213, lng: 20.44169 },
    { lat: 49.015193, lng: 20.441799 },
    { lat: 49.015178, lng: 20.441875 },
    { lat: 49.015174, lng: 20.4419 },
    { lat: 49.01516, lng: 20.441971 },
    { lat: 49.01515, lng: 20.44202 },
    { lat: 49.015148, lng: 20.442037 },
    { lat: 49.015062, lng: 20.442013 },
    { lat: 49.015019, lng: 20.441996 },
    { lat: 49.01492, lng: 20.441958 },
    { lat: 49.01487, lng: 20.44194 },
    { lat: 49.014763, lng: 20.441906 },
    { lat: 49.014738, lng: 20.441899 },
    { lat: 49.014574, lng: 20.441855 },
    { lat: 49.014396, lng: 20.441806 },
    { lat: 49.014231, lng: 20.441762 },
    { lat: 49.014214, lng: 20.441757 },
    { lat: 49.014177, lng: 20.441748 },
    { lat: 49.01415, lng: 20.441741 },
    { lat: 49.013978, lng: 20.441699 },
    { lat: 49.013894, lng: 20.441678 },
    { lat: 49.013703, lng: 20.441632 },
    { lat: 49.013512, lng: 20.441585 },
    { lat: 49.013385, lng: 20.441554 },
    { lat: 49.013269, lng: 20.441561 },
    { lat: 49.013108, lng: 20.4416 },
    { lat: 49.01272, lng: 20.441696 },
    { lat: 49.012561, lng: 20.441735 },
    { lat: 49.012345, lng: 20.441748 },
    { lat: 49.012224, lng: 20.441755 },
    { lat: 49.011979, lng: 20.441732 },
    { lat: 49.0115, lng: 20.441667 },
    { lat: 49.011078, lng: 20.441606 },
    { lat: 49.010643, lng: 20.44146 },
    { lat: 49.010461, lng: 20.439931 },
    { lat: 49.01047, lng: 20.439843 },
    { lat: 49.010482, lng: 20.43973 },
    { lat: 49.010468, lng: 20.439618 },
    { lat: 49.010393, lng: 20.439515 },
    { lat: 49.010066, lng: 20.439064 },
    { lat: 49.009313, lng: 20.43909 },
    { lat: 49.008895, lng: 20.438907 },
    { lat: 49.008742, lng: 20.438793 },
    { lat: 49.008676, lng: 20.438743 },
    { lat: 49.008602, lng: 20.438686 },
    { lat: 49.008203, lng: 20.437949 },
    { lat: 49.00816, lng: 20.437871 },
    { lat: 49.007994, lng: 20.437783 },
    { lat: 49.00778, lng: 20.437792 },
    { lat: 49.007539, lng: 20.437848 },
    { lat: 49.007251, lng: 20.437954 },
    { lat: 49.007175, lng: 20.437982 },
    { lat: 49.006991, lng: 20.438077 },
    { lat: 49.0069672, lng: 20.4380918 },
    { lat: 49.006954, lng: 20.4381 },
    { lat: 49.006856, lng: 20.438201 },
    { lat: 49.006822, lng: 20.438236 },
    { lat: 49.006743, lng: 20.43829 },
    { lat: 49.006617, lng: 20.438369 },
    { lat: 49.006354, lng: 20.438437 },
    { lat: 49.006147, lng: 20.438277 },
    { lat: 49.005868, lng: 20.438202 },
    { lat: 49.005651, lng: 20.438124 },
    { lat: 49.005415, lng: 20.438045 },
    { lat: 49.00506, lng: 20.437851 },
    { lat: 49.004704, lng: 20.438058 },
    { lat: 49.004663, lng: 20.438082 },
    { lat: 49.004472, lng: 20.438183 },
    { lat: 49.004386, lng: 20.438228 },
    { lat: 49.00395, lng: 20.438658 },
    { lat: 49.003742, lng: 20.438912 },
    { lat: 49.003672, lng: 20.439 },
    { lat: 49.003533, lng: 20.439242 },
    { lat: 49.003484, lng: 20.439392 },
    { lat: 49.003441, lng: 20.43986 },
    { lat: 49.003344, lng: 20.440422 },
    { lat: 49.003338, lng: 20.440973 },
    { lat: 49.003334, lng: 20.441196 },
    { lat: 49.003336, lng: 20.441284 },
    { lat: 49.003355, lng: 20.442153 },
    { lat: 49.003402, lng: 20.442726 },
    { lat: 49.003407, lng: 20.443104 },
    { lat: 49.003409, lng: 20.44319 },
    { lat: 49.003445, lng: 20.444173 },
    { lat: 49.003427, lng: 20.444169 },
    { lat: 49.002599, lng: 20.444001 },
    { lat: 49.002277, lng: 20.443924 },
    { lat: 49.001445, lng: 20.443844 },
    { lat: 49.001212, lng: 20.443796 },
    { lat: 49.000898, lng: 20.443897 },
    { lat: 49.000614, lng: 20.444019 },
    { lat: 49.00008, lng: 20.444443 },
    { lat: 48.999123, lng: 20.442738 },
    { lat: 48.998804, lng: 20.443341 },
    { lat: 48.998275, lng: 20.444574 },
    { lat: 48.998069, lng: 20.445045 },
    { lat: 48.997894, lng: 20.44477 },
    { lat: 48.997874, lng: 20.444744 },
    { lat: 48.997747, lng: 20.444969 },
    { lat: 48.99771, lng: 20.445068 },
    { lat: 48.997592, lng: 20.445169 },
    { lat: 48.997554, lng: 20.445237 },
    { lat: 48.997456, lng: 20.445324 },
    { lat: 48.997315, lng: 20.445382 },
    { lat: 48.997304, lng: 20.445452 },
    { lat: 48.997264, lng: 20.445485 },
    { lat: 48.997291, lng: 20.445672 },
    { lat: 48.997322, lng: 20.445763 },
    { lat: 48.99737, lng: 20.445837 },
    { lat: 48.997419, lng: 20.445944 },
    { lat: 48.997417, lng: 20.446022 },
    { lat: 48.997398, lng: 20.446079 },
    { lat: 48.997343, lng: 20.446185 },
    { lat: 48.997302, lng: 20.446201 },
    { lat: 48.997252, lng: 20.446211 },
    { lat: 48.997231, lng: 20.446249 },
    { lat: 48.997203, lng: 20.446351 },
    { lat: 48.997149, lng: 20.446449 },
    { lat: 48.99701, lng: 20.446618 },
    { lat: 48.996896, lng: 20.446788 },
    { lat: 48.996814, lng: 20.446845 },
    { lat: 48.996763, lng: 20.446938 },
    { lat: 48.996707, lng: 20.447015 },
    { lat: 48.996664, lng: 20.447104 },
    { lat: 48.996649, lng: 20.44718 },
    { lat: 48.996646, lng: 20.447261 },
    { lat: 48.996598, lng: 20.447301 },
    { lat: 48.996586, lng: 20.447358 },
    { lat: 48.996586, lng: 20.44746 },
    { lat: 48.996558, lng: 20.447504 },
    { lat: 48.996495, lng: 20.447567 },
    { lat: 48.996439, lng: 20.447603 },
    { lat: 48.99642, lng: 20.447651 },
    { lat: 48.996407, lng: 20.447705 },
    { lat: 48.996378, lng: 20.447719 },
    { lat: 48.996304, lng: 20.447731 },
    { lat: 48.996293, lng: 20.447818 },
    { lat: 48.996282, lng: 20.447824 },
    { lat: 48.99621, lng: 20.447861 },
    { lat: 48.996203, lng: 20.447905 },
    { lat: 48.996198, lng: 20.448019 },
    { lat: 48.996183, lng: 20.44806 },
    { lat: 48.996131, lng: 20.448114 },
    { lat: 48.996115, lng: 20.448143 },
    { lat: 48.996089, lng: 20.448236 },
    { lat: 48.99602, lng: 20.448287 },
    { lat: 48.996009, lng: 20.448318 },
    { lat: 48.996002, lng: 20.448338 },
    { lat: 48.995959, lng: 20.448361 },
    { lat: 48.995925, lng: 20.448375 },
    { lat: 48.995857, lng: 20.448381 },
    { lat: 48.995842, lng: 20.448397 },
    { lat: 48.995821, lng: 20.448442 },
    { lat: 48.995744, lng: 20.448466 },
    { lat: 48.995645, lng: 20.448538 },
    { lat: 48.99564, lng: 20.448558 },
    { lat: 48.99566, lng: 20.448703 },
    { lat: 48.995687, lng: 20.448783 },
    { lat: 48.995699, lng: 20.448815 },
    { lat: 48.995701, lng: 20.448853 },
    { lat: 48.995688, lng: 20.448913 },
    { lat: 48.995673, lng: 20.448953 },
    { lat: 48.995637, lng: 20.448964 },
    { lat: 48.995613, lng: 20.448952 },
    { lat: 48.995584, lng: 20.448897 },
    { lat: 48.995527, lng: 20.448946 },
    { lat: 48.995485, lng: 20.448983 },
    { lat: 48.99545, lng: 20.449036 },
    { lat: 48.99543, lng: 20.448982 },
    { lat: 48.995326, lng: 20.44913 },
    { lat: 48.995352, lng: 20.449189 },
    { lat: 48.995345, lng: 20.449197 },
    { lat: 48.995237, lng: 20.449337 },
    { lat: 48.995158, lng: 20.449456 },
    { lat: 48.995036, lng: 20.449625 },
    { lat: 48.994981, lng: 20.449743 },
    { lat: 48.994909, lng: 20.449846 },
    { lat: 48.994825, lng: 20.449937 },
    { lat: 48.994803, lng: 20.449986 },
    { lat: 48.994774, lng: 20.45008 },
    { lat: 48.994745, lng: 20.450121 },
    { lat: 48.994723, lng: 20.45017 },
    { lat: 48.994687, lng: 20.4502 },
    { lat: 48.994651, lng: 20.450256 },
    { lat: 48.994547, lng: 20.450385 },
    { lat: 48.994492, lng: 20.450456 },
    { lat: 48.994428, lng: 20.450583 },
    { lat: 48.994361, lng: 20.450653 },
    { lat: 48.994367, lng: 20.450669 },
    { lat: 48.994332, lng: 20.450735 },
    { lat: 48.994252, lng: 20.450876 },
    { lat: 48.994105, lng: 20.451126 },
    { lat: 48.994025, lng: 20.451243 },
    { lat: 48.993948, lng: 20.451341 },
    { lat: 48.993875, lng: 20.451469 },
    { lat: 48.993874, lng: 20.451469 },
    { lat: 48.9938, lng: 20.451595 },
    { lat: 48.993796, lng: 20.451601 },
    { lat: 48.993713, lng: 20.45172 },
    { lat: 48.993709, lng: 20.451727 },
    { lat: 48.993636, lng: 20.451847 },
    { lat: 48.993632, lng: 20.451856 },
    { lat: 48.993555, lng: 20.451973 },
    { lat: 48.993468, lng: 20.452095 },
    { lat: 48.993467, lng: 20.452096 },
    { lat: 48.993159, lng: 20.452566 },
    { lat: 48.992949, lng: 20.452948 },
    { lat: 48.992845, lng: 20.45314 },
    { lat: 48.992762, lng: 20.45329 },
    { lat: 48.992676, lng: 20.453446 },
    { lat: 48.992527, lng: 20.453718 },
    { lat: 48.992508, lng: 20.453752 },
    { lat: 48.992427, lng: 20.453967 },
    { lat: 48.992371, lng: 20.454033 },
    { lat: 48.992287, lng: 20.454248 },
    { lat: 48.992248, lng: 20.454285 },
    { lat: 48.99222, lng: 20.454377 },
    { lat: 48.992203, lng: 20.454431 },
    { lat: 48.992134, lng: 20.454478 },
    { lat: 48.992085, lng: 20.454605 },
    { lat: 48.992008, lng: 20.454661 },
    { lat: 48.991979, lng: 20.454682 },
    { lat: 48.991789, lng: 20.454857 },
    { lat: 48.991729, lng: 20.454912 },
    { lat: 48.991626, lng: 20.454995 },
    { lat: 48.991583, lng: 20.455037 },
    { lat: 48.991393, lng: 20.455224 },
    { lat: 48.991303, lng: 20.455312 },
    { lat: 48.991207, lng: 20.455409 },
    { lat: 48.991096, lng: 20.455521 },
    { lat: 48.991054, lng: 20.455562 },
    { lat: 48.991027, lng: 20.455714 },
    { lat: 48.991001, lng: 20.455733 },
    { lat: 48.990883, lng: 20.455825 },
    { lat: 48.990614, lng: 20.455828 },
    { lat: 48.990523, lng: 20.455754 },
    { lat: 48.990526, lng: 20.455676 },
    { lat: 48.990528, lng: 20.455634 },
    { lat: 48.990464, lng: 20.455497 },
    { lat: 48.990428, lng: 20.45548 },
    { lat: 48.990358, lng: 20.4555 },
    { lat: 48.990362, lng: 20.455526 },
    { lat: 48.990387, lng: 20.455725 },
    { lat: 48.990251, lng: 20.45591 },
    { lat: 48.990201, lng: 20.456003 },
    { lat: 48.990115, lng: 20.456068 },
    { lat: 48.990003, lng: 20.456061 },
    { lat: 48.989863, lng: 20.455803 },
    { lat: 48.989862, lng: 20.455775 },
    { lat: 48.989776, lng: 20.45576 },
    { lat: 48.989664, lng: 20.455777 },
    { lat: 48.989583, lng: 20.455797 },
    { lat: 48.989527, lng: 20.455766 },
    { lat: 48.989507, lng: 20.455698 },
    { lat: 48.98948, lng: 20.455675 },
    { lat: 48.989474, lng: 20.455575 },
    { lat: 48.989454, lng: 20.455459 },
    { lat: 48.98944, lng: 20.455331 },
    { lat: 48.989429, lng: 20.455339 },
    { lat: 48.989394, lng: 20.455363 },
    { lat: 48.988746, lng: 20.45581 },
    { lat: 48.988733, lng: 20.45582 },
    { lat: 48.988993, lng: 20.456959 },
    { lat: 48.989064, lng: 20.457218 },
    { lat: 48.98919, lng: 20.457363 },
    { lat: 48.989271, lng: 20.457417 },
    { lat: 48.989487, lng: 20.457623 },
    { lat: 48.989838, lng: 20.457984 },
    { lat: 48.989983, lng: 20.458176 },
    { lat: 48.99027, lng: 20.458685 },
    { lat: 48.990435, lng: 20.459004 },
    { lat: 48.990788, lng: 20.459745 },
    { lat: 48.991295, lng: 20.460836 },
    { lat: 48.99173, lng: 20.461813 },
    { lat: 48.992367, lng: 20.463256 },
    { lat: 48.992569, lng: 20.463719 },
    { lat: 48.993085, lng: 20.464627 },
    { lat: 48.99337, lng: 20.465186 },
    { lat: 48.993685, lng: 20.465744 },
    { lat: 48.994099, lng: 20.466549 },
    { lat: 48.994266, lng: 20.466908 },
    { lat: 48.994048, lng: 20.467118 },
    { lat: 48.993884, lng: 20.467276 },
    { lat: 48.993529, lng: 20.467612 },
    { lat: 48.993528, lng: 20.467612 },
    { lat: 48.993271, lng: 20.46785 },
    { lat: 48.992893, lng: 20.46827 },
    { lat: 48.992513, lng: 20.468609 },
    { lat: 48.992503, lng: 20.468622 },
    { lat: 48.992168, lng: 20.469035 },
    { lat: 48.991929, lng: 20.469315 },
    { lat: 48.991619, lng: 20.46968 },
    { lat: 48.99137, lng: 20.46997 },
    { lat: 48.991095, lng: 20.470292 },
    { lat: 48.990876, lng: 20.470551 },
    { lat: 48.990688, lng: 20.470791 },
    { lat: 48.990397, lng: 20.471161 },
    { lat: 48.989875, lng: 20.471828 },
    { lat: 48.989653, lng: 20.472124 },
    { lat: 48.989423, lng: 20.47235 },
    { lat: 48.989394, lng: 20.47245 },
    { lat: 48.989335, lng: 20.472653 },
    { lat: 48.989345, lng: 20.472744 },
    { lat: 48.989317, lng: 20.472829 },
    { lat: 48.989128, lng: 20.472923 },
    { lat: 48.989078, lng: 20.472924 },
    { lat: 48.989019, lng: 20.472898 },
    { lat: 48.988862, lng: 20.473093 },
    { lat: 48.988685, lng: 20.473291 },
    { lat: 48.988436, lng: 20.473569 },
    { lat: 48.988269, lng: 20.473755 },
    { lat: 48.987936, lng: 20.474175 },
    { lat: 48.987877, lng: 20.47425 },
    { lat: 48.987661, lng: 20.474522 },
    { lat: 48.987351, lng: 20.474927 },
    { lat: 48.987107, lng: 20.475296 },
    { lat: 48.986733, lng: 20.475896 },
    { lat: 48.986519, lng: 20.476241 },
    { lat: 48.986435, lng: 20.476375 },
    { lat: 48.985642, lng: 20.477428 },
    { lat: 48.985853, lng: 20.477917 },
    { lat: 48.985633, lng: 20.478057 },
    { lat: 48.985526, lng: 20.478139 },
    { lat: 48.98542, lng: 20.478154 },
    { lat: 48.985089, lng: 20.478257 },
    { lat: 48.984956, lng: 20.478279 },
    { lat: 48.98453, lng: 20.47835 },
    { lat: 48.984016, lng: 20.478449 },
    { lat: 48.983669, lng: 20.478519 },
    { lat: 48.98348, lng: 20.478549 },
    { lat: 48.983218, lng: 20.478613 },
    { lat: 48.982731, lng: 20.478777 },
    { lat: 48.982719, lng: 20.478751 },
    { lat: 48.981963, lng: 20.477248 },
    { lat: 48.981524, lng: 20.476325 },
    { lat: 48.980781, lng: 20.47627 },
    { lat: 48.980376, lng: 20.476241 },
    { lat: 48.979916, lng: 20.47626 },
    { lat: 48.979657, lng: 20.476366 },
    { lat: 48.979452, lng: 20.476423 },
    { lat: 48.979161, lng: 20.476503 },
    { lat: 48.979097, lng: 20.476521 },
    { lat: 48.978971, lng: 20.476557 },
    { lat: 48.978788, lng: 20.476622 },
    { lat: 48.978597, lng: 20.476689 },
    { lat: 48.978469, lng: 20.476734 },
    { lat: 48.978468, lng: 20.476734 },
    { lat: 48.978114, lng: 20.476818 },
    { lat: 48.977913, lng: 20.476861 },
    { lat: 48.977833, lng: 20.476876 },
    { lat: 48.977592, lng: 20.47696 },
    { lat: 48.977347, lng: 20.47705 },
    { lat: 48.977005, lng: 20.477174 },
    { lat: 48.977004, lng: 20.477175 },
    { lat: 48.9768853, lng: 20.4771945 },
    { lat: 48.9765, lng: 20.477258 },
    { lat: 48.975701, lng: 20.477286 },
    { lat: 48.97431, lng: 20.476921 },
    { lat: 48.973968, lng: 20.476542 },
    { lat: 48.973614, lng: 20.477233 },
    { lat: 48.973111, lng: 20.478205 },
    { lat: 48.972619, lng: 20.47932 },
    { lat: 48.972505, lng: 20.47951 },
    { lat: 48.972576, lng: 20.479697 },
    { lat: 48.972614, lng: 20.479731 },
    { lat: 48.972516, lng: 20.479771 },
    { lat: 48.972412, lng: 20.479816 },
    { lat: 48.972367, lng: 20.479859 },
    { lat: 48.972148, lng: 20.480071 },
    { lat: 48.972007, lng: 20.480302 },
    { lat: 48.971932, lng: 20.480432 },
    { lat: 48.971609, lng: 20.481027 },
    { lat: 48.971264, lng: 20.481595 },
    { lat: 48.971017, lng: 20.48216 },
    { lat: 48.970593, lng: 20.482819 },
    { lat: 48.97049, lng: 20.483052 },
    { lat: 48.970439, lng: 20.483176 },
    { lat: 48.970351, lng: 20.483422 },
    { lat: 48.970187, lng: 20.483835 },
    { lat: 48.970081, lng: 20.484077 },
    { lat: 48.970056, lng: 20.484127 },
    { lat: 48.970009, lng: 20.484239 },
    { lat: 48.969932, lng: 20.484358 },
    { lat: 48.969825, lng: 20.484512 },
    { lat: 48.969737, lng: 20.484633 },
    { lat: 48.969619, lng: 20.484783 },
    { lat: 48.969426, lng: 20.485034 },
    { lat: 48.968683, lng: 20.485829 },
    { lat: 48.968381, lng: 20.486246 },
    { lat: 48.968219, lng: 20.486459 },
    { lat: 48.967677, lng: 20.487065 },
    { lat: 48.968205, lng: 20.487994 },
    { lat: 48.968179, lng: 20.488105 },
    { lat: 48.968174, lng: 20.488134 },
    { lat: 48.968163, lng: 20.488199 },
    { lat: 48.968158, lng: 20.488235 },
    { lat: 48.968072, lng: 20.488803 },
    { lat: 48.967799, lng: 20.490142 },
    { lat: 48.967863, lng: 20.490319 },
    { lat: 48.967883, lng: 20.490395 },
    { lat: 48.9679, lng: 20.490456 },
    { lat: 48.967917, lng: 20.490519 },
    { lat: 48.968191, lng: 20.490957 },
    { lat: 48.968798, lng: 20.491566 },
    { lat: 48.96936, lng: 20.492174 },
    { lat: 48.969561, lng: 20.492235 },
    { lat: 48.97004, lng: 20.492251 },
    { lat: 48.970217, lng: 20.492264 },
    { lat: 48.970676, lng: 20.492295 },
    { lat: 48.970789, lng: 20.491307 },
    { lat: 48.970817, lng: 20.490858 },
    { lat: 48.97082, lng: 20.490828 },
    { lat: 48.970871, lng: 20.49016 },
    { lat: 48.970877, lng: 20.490076 },
    { lat: 48.970889, lng: 20.489934 },
    { lat: 48.970891, lng: 20.489901 },
    { lat: 48.970908, lng: 20.489686 },
    { lat: 48.971264, lng: 20.489763 },
    { lat: 48.971265, lng: 20.489764 },
    { lat: 48.971277, lng: 20.489766 },
    { lat: 48.971311, lng: 20.489849 },
    { lat: 48.971385, lng: 20.489915 },
    { lat: 48.97141, lng: 20.489963 },
    { lat: 48.97145, lng: 20.489995 },
    { lat: 48.971528, lng: 20.490037 },
    { lat: 48.971582, lng: 20.4901 },
    { lat: 48.971655, lng: 20.490184 },
    { lat: 48.971702, lng: 20.490208 },
    { lat: 48.971735, lng: 20.490237 },
    { lat: 48.97179, lng: 20.490282 },
    { lat: 48.971912, lng: 20.490421 },
    { lat: 48.972058, lng: 20.49057 },
    { lat: 48.972174, lng: 20.490622 },
    { lat: 48.972259, lng: 20.490681 },
    { lat: 48.972325, lng: 20.490716 },
    { lat: 48.972435, lng: 20.490762 },
    { lat: 48.972556, lng: 20.490824 },
    { lat: 48.972621, lng: 20.490829 },
    { lat: 48.972676, lng: 20.49084 },
    { lat: 48.972741, lng: 20.490884 },
    { lat: 48.97279, lng: 20.490897 },
    { lat: 48.972922, lng: 20.490937 },
    { lat: 48.973068, lng: 20.490924 },
    { lat: 48.973151, lng: 20.490921 },
    { lat: 48.97323, lng: 20.490912 },
    { lat: 48.973283, lng: 20.490908 },
    { lat: 48.973331, lng: 20.490909 },
    { lat: 48.973426, lng: 20.490896 },
    { lat: 48.973589, lng: 20.49086 },
    { lat: 48.97373, lng: 20.490827 },
    { lat: 48.973812, lng: 20.490787 },
    { lat: 48.973946, lng: 20.490721 },
    { lat: 48.974047, lng: 20.490711 },
    { lat: 48.974147, lng: 20.490714 },
    { lat: 48.974256, lng: 20.490659 },
    { lat: 48.974296, lng: 20.490604 },
    { lat: 48.974344, lng: 20.490587 },
    { lat: 48.974365, lng: 20.490576 },
    { lat: 48.974402, lng: 20.490543 },
    { lat: 48.974434, lng: 20.490525 },
    { lat: 48.974484, lng: 20.490519 },
    { lat: 48.974528, lng: 20.490556 },
    { lat: 48.974537, lng: 20.490539 },
    { lat: 48.97455, lng: 20.49051 },
    { lat: 48.974556, lng: 20.490498 },
    { lat: 48.974574, lng: 20.49046 },
    { lat: 48.974597, lng: 20.490381 },
    { lat: 48.974593, lng: 20.489995 },
    { lat: 48.974642, lng: 20.489913 },
    { lat: 48.974645, lng: 20.489907 },
    { lat: 48.974673, lng: 20.489852 },
    { lat: 48.974704, lng: 20.489866 },
    { lat: 48.974767, lng: 20.489546 },
    { lat: 48.974769, lng: 20.489288 },
    { lat: 48.974656, lng: 20.488596 },
    { lat: 48.974559, lng: 20.487952 },
    { lat: 48.974467, lng: 20.487165 },
    { lat: 48.974439, lng: 20.48693 },
    { lat: 48.974327, lng: 20.486021 },
    { lat: 48.974211, lng: 20.485041 },
    { lat: 48.974059, lng: 20.484384 },
    { lat: 48.974086, lng: 20.484365 },
    { lat: 48.974088, lng: 20.484364 },
    { lat: 48.974101, lng: 20.484358 },
    { lat: 48.974749, lng: 20.484044 },
    { lat: 48.97508, lng: 20.483898 },
    { lat: 48.97529, lng: 20.483817 },
    { lat: 48.976024, lng: 20.483576 },
    { lat: 48.97649, lng: 20.483436 },
    { lat: 48.976936, lng: 20.483312 },
    { lat: 48.977399, lng: 20.483197 },
    { lat: 48.978037, lng: 20.483049 },
    { lat: 48.978463, lng: 20.482941 },
    { lat: 48.978635, lng: 20.482895 },
    { lat: 48.979211, lng: 20.482728 },
    { lat: 48.979646, lng: 20.482598 },
    { lat: 48.980236, lng: 20.482438 },
    { lat: 48.980465, lng: 20.482399 },
    { lat: 48.980798, lng: 20.482343 },
    { lat: 48.980951, lng: 20.482309 },
    { lat: 48.9814, lng: 20.482206 },
    { lat: 48.981956, lng: 20.482012 },
    { lat: 48.982011, lng: 20.481966 },
    { lat: 48.982169, lng: 20.481837 },
    { lat: 48.982286, lng: 20.481756 },
    { lat: 48.982388, lng: 20.481876 },
    { lat: 48.982347, lng: 20.481917 },
    { lat: 48.982642, lng: 20.481969 },
    { lat: 48.982869, lng: 20.481981 },
    { lat: 48.982973, lng: 20.481971 },
    { lat: 48.983525, lng: 20.481876 },
    { lat: 48.983635, lng: 20.481883 },
    { lat: 48.98378, lng: 20.481915 },
    { lat: 48.983995, lng: 20.482063 },
    { lat: 48.984373, lng: 20.482468 },
    { lat: 48.984735, lng: 20.482719 },
    { lat: 48.98512, lng: 20.482838 },
    { lat: 48.98556, lng: 20.482984 },
    { lat: 48.985762, lng: 20.483061 },
    { lat: 48.986003, lng: 20.483176 },
    { lat: 48.986242, lng: 20.483254 },
    { lat: 48.986417, lng: 20.483276 },
    { lat: 48.986944, lng: 20.483302 },
    { lat: 48.987704, lng: 20.483328 },
    { lat: 48.988211, lng: 20.483355 },
    { lat: 48.988567, lng: 20.483358 },
    { lat: 48.989004, lng: 20.483344 },
    { lat: 48.989189, lng: 20.483334 },
    { lat: 48.989233, lng: 20.483371 },
    { lat: 48.98937, lng: 20.48357 },
    { lat: 48.989515, lng: 20.483775 },
    { lat: 48.989487, lng: 20.483815 },
    { lat: 48.989078, lng: 20.484179 },
    { lat: 48.98868, lng: 20.484485 },
    { lat: 48.988504, lng: 20.484626 },
    { lat: 48.988339, lng: 20.484769 },
    { lat: 48.98802, lng: 20.485069 },
    { lat: 48.987731, lng: 20.485375 },
    { lat: 48.987648, lng: 20.485478 },
    { lat: 48.987568, lng: 20.485589 },
    { lat: 48.987485, lng: 20.485708 },
    { lat: 48.987255, lng: 20.486057 },
    { lat: 48.98712, lng: 20.486219 },
    { lat: 48.987115, lng: 20.486409 },
    { lat: 48.986848, lng: 20.486578 },
    { lat: 48.986614, lng: 20.486622 },
    { lat: 48.986112, lng: 20.487035 },
    { lat: 48.985953, lng: 20.487279 },
    { lat: 48.985712, lng: 20.487609 },
    { lat: 48.985533, lng: 20.487896 },
    { lat: 48.985283, lng: 20.488206 },
    { lat: 48.98498, lng: 20.488568 },
    { lat: 48.984884, lng: 20.488712 },
    { lat: 48.984856, lng: 20.48879 },
    { lat: 48.98491, lng: 20.488789 },
    { lat: 48.984612, lng: 20.489415 },
    { lat: 48.984578, lng: 20.489482 },
    { lat: 48.984846, lng: 20.489755 },
    { lat: 48.985096, lng: 20.490035 },
    { lat: 48.985344, lng: 20.490356 },
    { lat: 48.985636, lng: 20.4908 },
    { lat: 48.985782, lng: 20.491096 },
    { lat: 48.985866, lng: 20.491313 },
    { lat: 48.986022, lng: 20.491768 },
    { lat: 48.986174, lng: 20.492203 },
    { lat: 48.986457, lng: 20.492874 },
    { lat: 48.986501, lng: 20.492918 },
    { lat: 48.98669, lng: 20.493244 },
    { lat: 48.987446, lng: 20.494524 },
    { lat: 48.987818, lng: 20.495062 },
    { lat: 48.988126, lng: 20.495476 },
    { lat: 48.98837, lng: 20.495778 },
    { lat: 48.988137, lng: 20.496241 },
    { lat: 48.98793, lng: 20.496748 },
    { lat: 48.987752, lng: 20.497267 },
    { lat: 48.987609, lng: 20.497938 },
    { lat: 48.988194, lng: 20.498502 },
    { lat: 48.988081, lng: 20.498885 },
    { lat: 48.987996, lng: 20.499885 },
    { lat: 48.988019, lng: 20.500372 },
    { lat: 48.988073, lng: 20.500915 },
    { lat: 48.988065, lng: 20.501232 },
    { lat: 48.987937, lng: 20.501626 },
    { lat: 48.987963, lng: 20.501665 },
    { lat: 48.988057, lng: 20.501813 },
    { lat: 48.988277, lng: 20.502158 },
    { lat: 48.98819, lng: 20.502392 },
    { lat: 48.988158, lng: 20.502477 },
    { lat: 48.988252, lng: 20.502575 },
    { lat: 48.988949, lng: 20.503299 },
    { lat: 48.988768, lng: 20.503759 },
    { lat: 48.988732, lng: 20.503859 },
    { lat: 48.988526, lng: 20.504425 },
    { lat: 48.988294, lng: 20.505032 },
    { lat: 48.988282, lng: 20.505064 },
    { lat: 48.988382, lng: 20.505304 },
    { lat: 48.98863, lng: 20.505708 },
    { lat: 48.988814, lng: 20.506001 },
    { lat: 48.989006, lng: 20.506295 },
    { lat: 48.989151, lng: 20.506565 },
    { lat: 48.989372, lng: 20.507022 },
    { lat: 48.989442, lng: 20.507161 },
    { lat: 48.989461, lng: 20.507199 },
    { lat: 48.989565, lng: 20.507409 },
    { lat: 48.98971, lng: 20.507699 },
    { lat: 48.990328, lng: 20.509042 },
    { lat: 48.990508, lng: 20.509453 },
    { lat: 48.99068, lng: 20.509926 },
    { lat: 48.990745, lng: 20.510175 },
    { lat: 48.990764, lng: 20.510166 },
    { lat: 48.990808, lng: 20.510144 },
    { lat: 48.991221, lng: 20.510034 },
    { lat: 48.99141, lng: 20.509992 },
    { lat: 48.991512, lng: 20.50997 },
    { lat: 48.991783, lng: 20.509931 },
    { lat: 48.992212, lng: 20.509878 },
    { lat: 48.992718, lng: 20.509899 },
    { lat: 48.993201, lng: 20.509907 },
    { lat: 48.993879, lng: 20.509751 },
    { lat: 48.994197, lng: 20.509663 },
    { lat: 48.994553, lng: 20.509524 },
    { lat: 48.994633, lng: 20.509492 },
    { lat: 48.994891, lng: 20.509386 },
    { lat: 48.994998, lng: 20.509352 },
    { lat: 48.995015, lng: 20.50935 },
    { lat: 48.995038, lng: 20.509352 },
    { lat: 48.995055, lng: 20.508905 },
    { lat: 48.995056, lng: 20.508847 },
    { lat: 48.995062, lng: 20.508687 },
    { lat: 48.99501, lng: 20.508329 },
    { lat: 48.994953, lng: 20.50788 },
    { lat: 48.994947, lng: 20.507822 },
    { lat: 48.994905, lng: 20.507122 },
    { lat: 48.994892, lng: 20.506951 },
    { lat: 48.994866, lng: 20.506708 },
    { lat: 48.994842, lng: 20.506386 },
    { lat: 48.994963, lng: 20.506041 },
    { lat: 48.995064, lng: 20.505812 },
    { lat: 48.995072, lng: 20.505793 },
    { lat: 48.995176, lng: 20.505589 },
    { lat: 48.995425, lng: 20.505124 },
    { lat: 48.995605, lng: 20.504679 },
    { lat: 48.995698, lng: 20.504359 },
    { lat: 48.995845, lng: 20.503837 },
    { lat: 48.995978, lng: 20.503421 },
    { lat: 48.996238, lng: 20.502439 },
    { lat: 48.996303, lng: 20.502109 },
    { lat: 48.996359, lng: 20.501814 },
    { lat: 48.996522, lng: 20.50122 },
    { lat: 48.996598, lng: 20.501017 },
    { lat: 48.996726, lng: 20.500787 },
    { lat: 48.996839, lng: 20.500564 },
    { lat: 48.996942, lng: 20.500227 },
    { lat: 48.996965, lng: 20.500144 },
    { lat: 48.996997, lng: 20.500023 },
    { lat: 48.997019, lng: 20.499939 },
    { lat: 48.997044, lng: 20.499844 },
    { lat: 48.997094, lng: 20.499683 },
    { lat: 48.99715, lng: 20.499505 },
    { lat: 48.9971959, lng: 20.4993745 },
    { lat: 48.9972136, lng: 20.4993912 },
    { lat: 48.997252, lng: 20.499215 },
    { lat: 48.997289, lng: 20.499066 },
    { lat: 48.99747, lng: 20.498393 },
    { lat: 48.997484, lng: 20.498311 },
    { lat: 48.997609, lng: 20.497566 },
    { lat: 48.997684, lng: 20.49721 },
    { lat: 48.997773, lng: 20.496764 },
    { lat: 48.99781, lng: 20.496511 },
    { lat: 48.997864, lng: 20.496265 },
    { lat: 48.998054, lng: 20.495782 },
    { lat: 48.998099, lng: 20.495598 },
    { lat: 48.998226, lng: 20.495044 },
    { lat: 48.998251, lng: 20.49496 },
    { lat: 48.998275, lng: 20.494799 },
    { lat: 48.998443, lng: 20.494407 },
    { lat: 48.99853, lng: 20.494205 },
    { lat: 48.998657, lng: 20.493958 },
    { lat: 48.998756, lng: 20.49365 },
    { lat: 48.9987721, lng: 20.4933469 },
    { lat: 48.998776, lng: 20.493273 },
    { lat: 48.998857, lng: 20.492611 },
    { lat: 48.998938, lng: 20.492221 },
    { lat: 48.999017, lng: 20.491886 },
    { lat: 48.999167, lng: 20.49124 },
    { lat: 48.999299, lng: 20.490662 },
    { lat: 48.999542, lng: 20.489821 },
    { lat: 48.999635, lng: 20.489604 },
    { lat: 48.999761, lng: 20.489429 },
    { lat: 48.999771, lng: 20.489421 },
    { lat: 48.999779, lng: 20.489415 },
    { lat: 48.999889, lng: 20.489334 },
    { lat: 49.000123, lng: 20.489276 },
    { lat: 49.000181, lng: 20.489257 },
    { lat: 49.00018, lng: 20.48922 },
    { lat: 49.00022, lng: 20.489059 },
    { lat: 49.000288, lng: 20.488772 },
    { lat: 49.000347, lng: 20.48852 },
    { lat: 49.000353, lng: 20.488474 },
    { lat: 49.00044, lng: 20.488112 },
    { lat: 49.000463, lng: 20.488009 },
    { lat: 49.000601, lng: 20.487424 },
    { lat: 49.000609, lng: 20.487393 },
    { lat: 49.000755, lng: 20.486795 },
    { lat: 49.000849, lng: 20.486432 },
    { lat: 49.000979, lng: 20.485925 },
    { lat: 49.000981, lng: 20.485922 },
    { lat: 49.000999, lng: 20.485884 },
    { lat: 49.001119, lng: 20.485637 },
    { lat: 49.001138, lng: 20.485606 },
    { lat: 49.001158, lng: 20.485555 },
    { lat: 49.001234, lng: 20.485374 },
    { lat: 49.001271, lng: 20.485289 },
    { lat: 49.001345, lng: 20.485109 },
    { lat: 49.001458, lng: 20.484856 },
    { lat: 49.001462, lng: 20.484845 },
    { lat: 49.001509, lng: 20.484717 },
    { lat: 49.001593, lng: 20.484585 },
    { lat: 49.001764, lng: 20.484261 },
    { lat: 49.001931, lng: 20.483929 },
    { lat: 49.002176, lng: 20.483437 },
    { lat: 49.002293, lng: 20.483159 },
    { lat: 49.002417, lng: 20.48288 },
    { lat: 49.002839, lng: 20.481575 },
    { lat: 49.002915, lng: 20.481585 },
    { lat: 49.0028631, lng: 20.4811959 },
    { lat: 49.0029619, lng: 20.4799534 },
    { lat: 49.0034267, lng: 20.478473 },
    { lat: 49.0037011, lng: 20.4777712 },
    { lat: 49.0038983, lng: 20.4770584 },
    { lat: 49.0040208, lng: 20.4761771 },
    { lat: 49.0055511, lng: 20.4766006 },
    { lat: 49.0057147, lng: 20.4762511 },
    { lat: 49.0061194, lng: 20.4764722 },
    { lat: 49.0062549, lng: 20.476488 },
    { lat: 49.0073886, lng: 20.4760363 },
    { lat: 49.0090062, lng: 20.4756026 },
    { lat: 49.0111023, lng: 20.4761084 },
    { lat: 49.0111574, lng: 20.4758641 },
    { lat: 49.0114889, lng: 20.4758781 },
    { lat: 49.0124096, lng: 20.4757159 },
    { lat: 49.0128152, lng: 20.4755725 },
    { lat: 49.0129859, lng: 20.4753547 },
    { lat: 49.0131741, lng: 20.4753857 },
    { lat: 49.0135468, lng: 20.4752961 },
    { lat: 49.0140509, lng: 20.4753174 },
    { lat: 49.0141205, lng: 20.4754376 },
    { lat: 49.0145964, lng: 20.4757533 },
    { lat: 49.0148006, lng: 20.475793 },
    { lat: 49.0148226, lng: 20.4756957 },
    { lat: 49.0152468, lng: 20.4757458 },
    { lat: 49.0153291, lng: 20.4755683 },
    { lat: 49.0155099, lng: 20.4755562 },
    { lat: 49.0158983, lng: 20.4753745 },
    { lat: 49.0165562, lng: 20.4753527 },
    { lat: 49.017036, lng: 20.4751505 },
    { lat: 49.0173023, lng: 20.4749219 },
    { lat: 49.0194395, lng: 20.4749306 },
    { lat: 49.0215041, lng: 20.4748024 },
    { lat: 49.0222375, lng: 20.4746625 },
    { lat: 49.0234401, lng: 20.4746638 },
    { lat: 49.0237679, lng: 20.4744005 },
    { lat: 49.0247226, lng: 20.4744868 },
    { lat: 49.0262298, lng: 20.4751674 },
    { lat: 49.0268102, lng: 20.4751233 },
    { lat: 49.0270175, lng: 20.4750081 },
    { lat: 49.0276714, lng: 20.4752134 },
    { lat: 49.0285435, lng: 20.4752602 },
    { lat: 49.0288611, lng: 20.4751419 },
    { lat: 49.0307477, lng: 20.4751619 },
    { lat: 49.0311187, lng: 20.4754169 },
    { lat: 49.0317083, lng: 20.4759794 },
    { lat: 49.0319354, lng: 20.476166 },
    { lat: 49.0323666, lng: 20.4759995 },
    { lat: 49.0326241, lng: 20.4756546 },
    { lat: 49.0332893, lng: 20.4755648 },
    { lat: 49.0344705, lng: 20.4754793 },
    { lat: 49.0355079, lng: 20.4755173 },
    { lat: 49.03746, lng: 20.475201 },
    { lat: 49.037454, lng: 20.475171 },
    { lat: 49.037437, lng: 20.475086 },
    { lat: 49.037383, lng: 20.474805 },
    { lat: 49.037286, lng: 20.474455 },
    { lat: 49.037206, lng: 20.474163 },
    { lat: 49.037155, lng: 20.473981 },
    { lat: 49.037053, lng: 20.47359 },
    { lat: 49.037043, lng: 20.473557 },
    { lat: 49.036968, lng: 20.473271 },
    { lat: 49.036919, lng: 20.473079 },
    { lat: 49.036822, lng: 20.4727 },
    { lat: 49.036706, lng: 20.472238 },
    { lat: 49.036531, lng: 20.471713 },
    { lat: 49.036471, lng: 20.471535 },
    { lat: 49.036462, lng: 20.471508 },
    { lat: 49.036452, lng: 20.471479 },
    { lat: 49.036389, lng: 20.471286 },
    { lat: 49.036359, lng: 20.471208 },
    { lat: 49.036331, lng: 20.471135 },
    { lat: 49.036148, lng: 20.470617 },
    { lat: 49.036044, lng: 20.470263 },
    { lat: 49.035932, lng: 20.46976 },
    { lat: 49.035913, lng: 20.469674 },
    { lat: 49.035894, lng: 20.469643 },
    { lat: 49.035695, lng: 20.469309 },
    { lat: 49.035559, lng: 20.469106 },
    { lat: 49.03552, lng: 20.469047 },
    { lat: 49.035084, lng: 20.467953 },
    { lat: 49.034873, lng: 20.467463 },
    { lat: 49.03465, lng: 20.466805 },
    { lat: 49.034446, lng: 20.466204 },
    { lat: 49.03434, lng: 20.465853 },
    { lat: 49.034229, lng: 20.465488 },
    { lat: 49.034228, lng: 20.465482 },
    { lat: 49.034091, lng: 20.464931 },
    { lat: 49.034067, lng: 20.464833 },
    { lat: 49.03405, lng: 20.464761 },
    { lat: 49.033945, lng: 20.464334 },
    { lat: 49.03397, lng: 20.46424 },
    { lat: 49.033931, lng: 20.464204 },
    { lat: 49.033912, lng: 20.464185 },
    { lat: 49.033904, lng: 20.464178 },
    { lat: 49.033892, lng: 20.464166 },
    { lat: 49.033756, lng: 20.463751 },
    { lat: 49.033703, lng: 20.463587 },
    { lat: 49.033698, lng: 20.463573 },
    { lat: 49.033685, lng: 20.463536 },
    { lat: 49.033533, lng: 20.463134 },
    { lat: 49.033474, lng: 20.462971 },
    { lat: 49.03342, lng: 20.462827 },
    { lat: 49.033251, lng: 20.462372 },
    { lat: 49.033245, lng: 20.462356 },
    { lat: 49.033027, lng: 20.461744 },
    { lat: 49.032901, lng: 20.461389 },
    { lat: 49.032631, lng: 20.46069 },
    { lat: 49.032555, lng: 20.460493 },
    { lat: 49.032483, lng: 20.460313 },
    { lat: 49.032167, lng: 20.459517 },
    { lat: 49.03215, lng: 20.459473 },
    { lat: 49.032145, lng: 20.459461 },
    { lat: 49.032138, lng: 20.459452 },
    { lat: 49.032127, lng: 20.459438 },
    { lat: 49.032121, lng: 20.459429 },
    { lat: 49.032113, lng: 20.459416 },
    { lat: 49.031868, lng: 20.459028 },
    { lat: 49.031814, lng: 20.458943 },
    { lat: 49.031974, lng: 20.458504 },
    { lat: 49.032076, lng: 20.458068 },
    { lat: 49.032077, lng: 20.457973 },
    { lat: 49.032078, lng: 20.457799 },
    { lat: 49.032146, lng: 20.457414 },
    { lat: 49.032174, lng: 20.45726 },
    { lat: 49.032168, lng: 20.457004 },
    { lat: 49.032093, lng: 20.457012 },
    { lat: 49.032025, lng: 20.456946 },
    { lat: 49.032011, lng: 20.456916 },
    { lat: 49.031946, lng: 20.456746 },
    { lat: 49.031905, lng: 20.456639 },
    { lat: 49.031746, lng: 20.456574 },
  ],
  Pavľany: [
    { lat: 49.0457181, lng: 20.722024 },
    { lat: 49.0458019, lng: 20.7221391 },
    { lat: 49.0458172, lng: 20.7223116 },
    { lat: 49.0456952, lng: 20.7225576 },
    { lat: 49.0456811, lng: 20.7244251 },
    { lat: 49.045973, lng: 20.7253579 },
    { lat: 49.046351, lng: 20.7249995 },
    { lat: 49.0467174, lng: 20.7247614 },
    { lat: 49.0470592, lng: 20.7247206 },
    { lat: 49.0472707, lng: 20.7253877 },
    { lat: 49.0473988, lng: 20.7255664 },
    { lat: 49.0474673, lng: 20.7254362 },
    { lat: 49.0477428, lng: 20.7255218 },
    { lat: 49.0485215, lng: 20.7252286 },
    { lat: 49.0486869, lng: 20.7252233 },
    { lat: 49.0490281, lng: 20.7254201 },
    { lat: 49.0492529, lng: 20.725475 },
    { lat: 49.0505582, lng: 20.725592 },
    { lat: 49.050474, lng: 20.72643 },
    { lat: 49.0507537, lng: 20.7267302 },
    { lat: 49.0509906, lng: 20.7268056 },
    { lat: 49.0511533, lng: 20.7270511 },
    { lat: 49.0523224, lng: 20.7275816 },
    { lat: 49.0526968, lng: 20.7276637 },
    { lat: 49.052769, lng: 20.7277644 },
    { lat: 49.0531092, lng: 20.7277388 },
    { lat: 49.0536098, lng: 20.7278204 },
    { lat: 49.0545731, lng: 20.7282324 },
    { lat: 49.0545505, lng: 20.7285494 },
    { lat: 49.0552289, lng: 20.7287309 },
    { lat: 49.0556344, lng: 20.7289929 },
    { lat: 49.056138, lng: 20.7294525 },
    { lat: 49.0564595, lng: 20.7298538 },
    { lat: 49.0565458, lng: 20.7301966 },
    { lat: 49.056516, lng: 20.7306276 },
    { lat: 49.0574466, lng: 20.7324393 },
    { lat: 49.0574606, lng: 20.7332185 },
    { lat: 49.0572668, lng: 20.7338236 },
    { lat: 49.0571221, lng: 20.7350213 },
    { lat: 49.0572077, lng: 20.7357239 },
    { lat: 49.0574979, lng: 20.7353707 },
    { lat: 49.0575365, lng: 20.7356989 },
    { lat: 49.0576851, lng: 20.7362743 },
    { lat: 49.0576711, lng: 20.7374811 },
    { lat: 49.057813, lng: 20.7386996 },
    { lat: 49.0578041, lng: 20.7388937 },
    { lat: 49.0578443, lng: 20.7389178 },
    { lat: 49.0583419, lng: 20.7409691 },
    { lat: 49.0590451, lng: 20.7425289 },
    { lat: 49.05938, lng: 20.7427694 },
    { lat: 49.0596993, lng: 20.7426333 },
    { lat: 49.0599676, lng: 20.7426068 },
    { lat: 49.0607874, lng: 20.7431963 },
    { lat: 49.0614526, lng: 20.7434797 },
    { lat: 49.0626264, lng: 20.7439231 },
    { lat: 49.0628249, lng: 20.7444439 },
    { lat: 49.0632509, lng: 20.7450364 },
    { lat: 49.0633567, lng: 20.7452615 },
    { lat: 49.0634192, lng: 20.7457938 },
    { lat: 49.0643099, lng: 20.7460617 },
    { lat: 49.0649358, lng: 20.7459319 },
    { lat: 49.0653623, lng: 20.7455422 },
    { lat: 49.0657199, lng: 20.7451183 },
    { lat: 49.0658344, lng: 20.7448534 },
    { lat: 49.0659322, lng: 20.7446211 },
    { lat: 49.0656046, lng: 20.7435615 },
    { lat: 49.0655546, lng: 20.7430956 },
    { lat: 49.0656426, lng: 20.7423056 },
    { lat: 49.0657105, lng: 20.7422846 },
    { lat: 49.0683712, lng: 20.7433969 },
    { lat: 49.0707373, lng: 20.7411808 },
    { lat: 49.0709943, lng: 20.7418356 },
    { lat: 49.0742209, lng: 20.7388675 },
    { lat: 49.0744973, lng: 20.7384687 },
    { lat: 49.0751818, lng: 20.7369977 },
    { lat: 49.0759154, lng: 20.7363146 },
    { lat: 49.0797885, lng: 20.7331682 },
    { lat: 49.0821652, lng: 20.7315514 },
    { lat: 49.0850969, lng: 20.726484 },
    { lat: 49.0850286, lng: 20.7257569 },
    { lat: 49.0841178, lng: 20.7239359 },
    { lat: 49.083149, lng: 20.7218335 },
    { lat: 49.080876, lng: 20.7207424 },
    { lat: 49.0789723, lng: 20.7190484 },
    { lat: 49.074917, lng: 20.7148549 },
    { lat: 49.0739996, lng: 20.7149817 },
    { lat: 49.0735688, lng: 20.7149592 },
    { lat: 49.0728927, lng: 20.7151966 },
    { lat: 49.0717682, lng: 20.7158982 },
    { lat: 49.0710851, lng: 20.71625 },
    { lat: 49.0706234, lng: 20.7166629 },
    { lat: 49.0698068, lng: 20.7156624 },
    { lat: 49.0687383, lng: 20.7152106 },
    { lat: 49.0678145, lng: 20.7142677 },
    { lat: 49.0659829, lng: 20.7130467 },
    { lat: 49.0644552, lng: 20.7115851 },
    { lat: 49.0639919, lng: 20.7113431 },
    { lat: 49.0619732, lng: 20.7096448 },
    { lat: 49.0610802, lng: 20.7084275 },
    { lat: 49.0604835, lng: 20.7088684 },
    { lat: 49.0598469, lng: 20.7075164 },
    { lat: 49.0592684, lng: 20.7035473 },
    { lat: 49.0589064, lng: 20.7006757 },
    { lat: 49.0589056, lng: 20.6972569 },
    { lat: 49.0588001, lng: 20.6958417 },
    { lat: 49.0578754, lng: 20.6972884 },
    { lat: 49.0561598, lng: 20.6976007 },
    { lat: 49.0559538, lng: 20.6979499 },
    { lat: 49.0554782, lng: 20.6980906 },
    { lat: 49.0547224, lng: 20.69862 },
    { lat: 49.0540527, lng: 20.6989399 },
    { lat: 49.0536475, lng: 20.6995431 },
    { lat: 49.053485, lng: 20.6997851 },
    { lat: 49.0527638, lng: 20.7000148 },
    { lat: 49.0521006, lng: 20.7003639 },
    { lat: 49.0516626, lng: 20.7009324 },
    { lat: 49.0512915, lng: 20.7015755 },
    { lat: 49.0508645, lng: 20.7021047 },
    { lat: 49.0504355, lng: 20.7028322 },
    { lat: 49.0499717, lng: 20.7033042 },
    { lat: 49.0494482, lng: 20.7036561 },
    { lat: 49.0493059, lng: 20.7039247 },
    { lat: 49.0491641, lng: 20.7044033 },
    { lat: 49.0490882, lng: 20.7049901 },
    { lat: 49.0488697, lng: 20.7055621 },
    { lat: 49.048574, lng: 20.7066238 },
    { lat: 49.0484757, lng: 20.7071512 },
    { lat: 49.0485122, lng: 20.70761 },
    { lat: 49.0484405, lng: 20.708475 },
    { lat: 49.0483382, lng: 20.7088645 },
    { lat: 49.0483387, lng: 20.7090719 },
    { lat: 49.0482896, lng: 20.7092105 },
    { lat: 49.0480996, lng: 20.7093054 },
    { lat: 49.0478061, lng: 20.709984 },
    { lat: 49.0477855, lng: 20.7102616 },
    { lat: 49.0476467, lng: 20.710447 },
    { lat: 49.0476429, lng: 20.7107194 },
    { lat: 49.0475617, lng: 20.7109707 },
    { lat: 49.0474466, lng: 20.7111488 },
    { lat: 49.0474676, lng: 20.7114575 },
    { lat: 49.0475221, lng: 20.7115643 },
    { lat: 49.0471848, lng: 20.7121521 },
    { lat: 49.0472158, lng: 20.7124539 },
    { lat: 49.0471643, lng: 20.7126181 },
    { lat: 49.0471296, lng: 20.7131657 },
    { lat: 49.0474797, lng: 20.7141537 },
    { lat: 49.0474574, lng: 20.7144759 },
    { lat: 49.0473181, lng: 20.7153787 },
    { lat: 49.0471008, lng: 20.7161375 },
    { lat: 49.0467638, lng: 20.7168613 },
    { lat: 49.0464935, lng: 20.7180381 },
    { lat: 49.0462669, lng: 20.7193025 },
    { lat: 49.0460733, lng: 20.7195893 },
    { lat: 49.0459371, lng: 20.7212787 },
    { lat: 49.0457674, lng: 20.7219561 },
    { lat: 49.0457181, lng: 20.722024 },
  ],
  Korytné: [
    { lat: 49.0162177, lng: 20.835255 },
    { lat: 49.0162407, lng: 20.8349433 },
    { lat: 49.0163716, lng: 20.8343933 },
    { lat: 49.0163438, lng: 20.83427 },
    { lat: 49.0162458, lng: 20.8343692 },
    { lat: 49.0161068, lng: 20.8343548 },
    { lat: 49.0156796, lng: 20.8338723 },
    { lat: 49.0155512, lng: 20.8338757 },
    { lat: 49.0152912, lng: 20.8336747 },
    { lat: 49.0160034, lng: 20.8335081 },
    { lat: 49.0156262, lng: 20.8333673 },
    { lat: 49.0157311, lng: 20.8331133 },
    { lat: 49.0151784, lng: 20.8325541 },
    { lat: 49.0146068, lng: 20.8326622 },
    { lat: 49.013977, lng: 20.8319209 },
    { lat: 49.0138074, lng: 20.8316396 },
    { lat: 49.0127419, lng: 20.8309289 },
    { lat: 49.0127536, lng: 20.8307476 },
    { lat: 49.0114413, lng: 20.8306543 },
    { lat: 49.0111093, lng: 20.8306922 },
    { lat: 49.0099613, lng: 20.8305611 },
    { lat: 49.0095666, lng: 20.830373 },
    { lat: 49.0090939, lng: 20.8299545 },
    { lat: 49.0085623, lng: 20.8300727 },
    { lat: 49.0085804, lng: 20.8301405 },
    { lat: 49.0084204, lng: 20.8301371 },
    { lat: 49.0077556, lng: 20.831623 },
    { lat: 49.0075412, lng: 20.8319996 },
    { lat: 49.0070208, lng: 20.8321575 },
    { lat: 49.0049837, lng: 20.8325588 },
    { lat: 49.0049219, lng: 20.833114 },
    { lat: 49.0047217, lng: 20.8334164 },
    { lat: 49.0043636, lng: 20.8336341 },
    { lat: 49.003129, lng: 20.8343609 },
    { lat: 49.0030284, lng: 20.8350114 },
    { lat: 49.0025957, lng: 20.8357136 },
    { lat: 49.0033518, lng: 20.8357382 },
    { lat: 49.0037732, lng: 20.8359149 },
    { lat: 49.0039503, lng: 20.835927 },
    { lat: 49.0043212, lng: 20.8363353 },
    { lat: 49.0050097, lng: 20.8367826 },
    { lat: 49.0049479, lng: 20.8370166 },
    { lat: 49.0050382, lng: 20.8371252 },
    { lat: 49.0047032, lng: 20.8374946 },
    { lat: 49.0048151, lng: 20.8378044 },
    { lat: 49.0055453, lng: 20.8391706 },
    { lat: 49.0065787, lng: 20.8439346 },
    { lat: 49.006592, lng: 20.8441895 },
    { lat: 49.0059682, lng: 20.8457974 },
    { lat: 49.0068102, lng: 20.8465543 },
    { lat: 49.0067597, lng: 20.8468218 },
    { lat: 49.0068526, lng: 20.8475645 },
    { lat: 49.0068555, lng: 20.8489307 },
    { lat: 49.0069061, lng: 20.8491558 },
    { lat: 49.0068534, lng: 20.8498903 },
    { lat: 49.0067646, lng: 20.850382 },
    { lat: 49.006836, lng: 20.8508285 },
    { lat: 49.0069003, lng: 20.8520815 },
    { lat: 49.006877, lng: 20.853345 },
    { lat: 49.0067392, lng: 20.8545778 },
    { lat: 49.0066896, lng: 20.8554665 },
    { lat: 49.0067698, lng: 20.8561009 },
    { lat: 49.0070372, lng: 20.8566452 },
    { lat: 49.0079805, lng: 20.8575718 },
    { lat: 49.0081264, lng: 20.8576592 },
    { lat: 49.0082681, lng: 20.8578854 },
    { lat: 49.008306, lng: 20.8580355 },
    { lat: 49.0082203, lng: 20.8584951 },
    { lat: 49.0085904, lng: 20.8593131 },
    { lat: 49.0085822, lng: 20.8595967 },
    { lat: 49.0084058, lng: 20.8601029 },
    { lat: 49.0080782, lng: 20.8603653 },
    { lat: 49.0076371, lng: 20.8606005 },
    { lat: 49.0070991, lng: 20.8611596 },
    { lat: 49.0065043, lng: 20.8614797 },
    { lat: 49.0063906, lng: 20.8617077 },
    { lat: 49.0071433, lng: 20.8646335 },
    { lat: 49.0070708, lng: 20.866771 },
    { lat: 49.0071637, lng: 20.8692344 },
    { lat: 49.006901, lng: 20.870515 },
    { lat: 49.006899, lng: 20.870532 },
    { lat: 49.006865, lng: 20.870573 },
    { lat: 49.006968, lng: 20.870785 },
    { lat: 49.007036, lng: 20.870902 },
    { lat: 49.007069, lng: 20.870916 },
    { lat: 49.00734, lng: 20.871029 },
    { lat: 49.007423, lng: 20.871154 },
    { lat: 49.007717, lng: 20.87128 },
    { lat: 49.007823, lng: 20.871469 },
    { lat: 49.0079, lng: 20.871477 },
    { lat: 49.008034, lng: 20.871581 },
    { lat: 49.0081206, lng: 20.8716746 },
    { lat: 49.008345, lng: 20.871792 },
    { lat: 49.008394, lng: 20.871938 },
    { lat: 49.008495, lng: 20.8721 },
    { lat: 49.008588, lng: 20.872119 },
    { lat: 49.008693, lng: 20.872243 },
    { lat: 49.008813, lng: 20.872289 },
    { lat: 49.009016, lng: 20.872601 },
    { lat: 49.009551, lng: 20.872944 },
    { lat: 49.009588, lng: 20.873045 },
    { lat: 49.009684, lng: 20.873082 },
    { lat: 49.009823, lng: 20.873271 },
    { lat: 49.009882, lng: 20.873479 },
    { lat: 49.010049, lng: 20.873512 },
    { lat: 49.010051, lng: 20.873594 },
    { lat: 49.01016, lng: 20.873714 },
    { lat: 49.0103943, lng: 20.8738601 },
    { lat: 49.010504, lng: 20.87391 },
    { lat: 49.01081, lng: 20.874041 },
    { lat: 49.011076, lng: 20.87397 },
    { lat: 49.0111782, lng: 20.8739025 },
    { lat: 49.011605, lng: 20.873813 },
    { lat: 49.012463, lng: 20.873866 },
    { lat: 49.012807, lng: 20.873954 },
    { lat: 49.013081, lng: 20.874075 },
    { lat: 49.013157, lng: 20.874092 },
    { lat: 49.0134, lng: 20.874049 },
    { lat: 49.013474, lng: 20.87408 },
    { lat: 49.013526, lng: 20.87409 },
    { lat: 49.013592, lng: 20.874136 },
    { lat: 49.013653, lng: 20.874118 },
    { lat: 49.013735, lng: 20.874135 },
    { lat: 49.013907, lng: 20.874157 },
    { lat: 49.014535, lng: 20.873758 },
    { lat: 49.014822, lng: 20.87366 },
    { lat: 49.01501, lng: 20.873686 },
    { lat: 49.015155, lng: 20.873616 },
    { lat: 49.015229, lng: 20.87364 },
    { lat: 49.015334, lng: 20.873772 },
    { lat: 49.015451, lng: 20.873879 },
    { lat: 49.015654, lng: 20.874062 },
    { lat: 49.015793, lng: 20.874128 },
    { lat: 49.015869, lng: 20.874181 },
    { lat: 49.015951, lng: 20.874318 },
    { lat: 49.016119, lng: 20.874491 },
    { lat: 49.01631, lng: 20.87472 },
    { lat: 49.016515, lng: 20.875251 },
    { lat: 49.016636, lng: 20.875439 },
    { lat: 49.016721, lng: 20.875512 },
    { lat: 49.016874, lng: 20.875743 },
    { lat: 49.016952, lng: 20.875966 },
    { lat: 49.01719, lng: 20.876332 },
    { lat: 49.017245, lng: 20.87652 },
    { lat: 49.017367, lng: 20.876613 },
    { lat: 49.017563, lng: 20.876921 },
    { lat: 49.017721, lng: 20.877087 },
    { lat: 49.018124, lng: 20.877616 },
    { lat: 49.018192, lng: 20.877733 },
    { lat: 49.018957, lng: 20.878794 },
    { lat: 49.018996, lng: 20.878843 },
    { lat: 49.019953, lng: 20.880203 },
    { lat: 49.0206, lng: 20.880882 },
    { lat: 49.020921, lng: 20.880529 },
    { lat: 49.021065, lng: 20.880439 },
    { lat: 49.021295, lng: 20.880931 },
    { lat: 49.022159, lng: 20.882692 },
    { lat: 49.022763, lng: 20.883283 },
    { lat: 49.022961, lng: 20.883398 },
    { lat: 49.023519, lng: 20.883642 },
    { lat: 49.023751, lng: 20.883609 },
    { lat: 49.023831, lng: 20.883737 },
    { lat: 49.024371, lng: 20.883568 },
    { lat: 49.024793, lng: 20.883254 },
    { lat: 49.0238659, lng: 20.880755 },
    { lat: 49.0232977, lng: 20.8795655 },
    { lat: 49.0232359, lng: 20.8790367 },
    { lat: 49.0232084, lng: 20.8763386 },
    { lat: 49.0229994, lng: 20.8746239 },
    { lat: 49.0228864, lng: 20.8742125 },
    { lat: 49.0224672, lng: 20.8733384 },
    { lat: 49.0223571, lng: 20.8730125 },
    { lat: 49.0221054, lng: 20.8726302 },
    { lat: 49.0214315, lng: 20.8721094 },
    { lat: 49.0210111, lng: 20.8714682 },
    { lat: 49.0206354, lng: 20.871109 },
    { lat: 49.0203623, lng: 20.8706597 },
    { lat: 49.0201657, lng: 20.8704689 },
    { lat: 49.0199359, lng: 20.869906 },
    { lat: 49.0196488, lng: 20.8687129 },
    { lat: 49.0196521, lng: 20.8677143 },
    { lat: 49.0195723, lng: 20.867255 },
    { lat: 49.0196167, lng: 20.8667782 },
    { lat: 49.0195589, lng: 20.866701 },
    { lat: 49.01953, lng: 20.8659157 },
    { lat: 49.0195107, lng: 20.8655909 },
    { lat: 49.0195039, lng: 20.8653153 },
    { lat: 49.0193918, lng: 20.864698 },
    { lat: 49.0193125, lng: 20.8645224 },
    { lat: 49.0188709, lng: 20.8640178 },
    { lat: 49.0186305, lng: 20.8636337 },
    { lat: 49.0179303, lng: 20.8631671 },
    { lat: 49.0170715, lng: 20.8622124 },
    { lat: 49.0167713, lng: 20.8620543 },
    { lat: 49.0166665, lng: 20.8617893 },
    { lat: 49.0166471, lng: 20.8612214 },
    { lat: 49.016805, lng: 20.8603035 },
    { lat: 49.017098, lng: 20.8593229 },
    { lat: 49.017323, lng: 20.858991 },
    { lat: 49.0174374, lng: 20.858486 },
    { lat: 49.0172345, lng: 20.8564956 },
    { lat: 49.0197023, lng: 20.8520364 },
    { lat: 49.0207763, lng: 20.8521706 },
    { lat: 49.0208664, lng: 20.8521076 },
    { lat: 49.0208962, lng: 20.8512334 },
    { lat: 49.0208037, lng: 20.8506662 },
    { lat: 49.0206931, lng: 20.8503288 },
    { lat: 49.0206691, lng: 20.8500567 },
    { lat: 49.0205012, lng: 20.8497526 },
    { lat: 49.0205169, lng: 20.8495907 },
    { lat: 49.0204386, lng: 20.8493423 },
    { lat: 49.0202939, lng: 20.8492218 },
    { lat: 49.0200086, lng: 20.8486454 },
    { lat: 49.0198936, lng: 20.8482507 },
    { lat: 49.0197088, lng: 20.8471568 },
    { lat: 49.0195898, lng: 20.8469073 },
    { lat: 49.0191957, lng: 20.8463428 },
    { lat: 49.0191873, lng: 20.8461922 },
    { lat: 49.0190514, lng: 20.845807 },
    { lat: 49.0190408, lng: 20.8454983 },
    { lat: 49.0189079, lng: 20.845066 },
    { lat: 49.0188764, lng: 20.8447063 },
    { lat: 49.0186336, lng: 20.8444207 },
    { lat: 49.0184806, lng: 20.8439608 },
    { lat: 49.0182832, lng: 20.843818 },
    { lat: 49.018031, lng: 20.8437877 },
    { lat: 49.0177104, lng: 20.843925 },
    { lat: 49.0170272, lng: 20.8438583 },
    { lat: 49.0170441, lng: 20.8437931 },
    { lat: 49.0167125, lng: 20.8433804 },
    { lat: 49.015815, lng: 20.8429416 },
    { lat: 49.0157702, lng: 20.8427146 },
    { lat: 49.0158315, lng: 20.8425351 },
    { lat: 49.0160441, lng: 20.8407546 },
    { lat: 49.0160783, lng: 20.8379843 },
    { lat: 49.0162177, lng: 20.835255 },
  ],
  Klčov: [
    { lat: 48.9844079, lng: 20.6740727 },
    { lat: 48.986143, lng: 20.6795359 },
    { lat: 48.9854175, lng: 20.6803721 },
    { lat: 48.9844482, lng: 20.6818508 },
    { lat: 48.9846799, lng: 20.683017 },
    { lat: 48.9848753, lng: 20.68354 },
    { lat: 48.9856332, lng: 20.6840475 },
    { lat: 48.9870732, lng: 20.6845253 },
    { lat: 48.987773, lng: 20.6848351 },
    { lat: 48.9885265, lng: 20.6850351 },
    { lat: 48.9889035, lng: 20.6850617 },
    { lat: 48.989546, lng: 20.6852766 },
    { lat: 48.9898894, lng: 20.6856871 },
    { lat: 48.9906716, lng: 20.6864048 },
    { lat: 48.9913251, lng: 20.6867708 },
    { lat: 48.9921047, lng: 20.6874182 },
    { lat: 48.991331, lng: 20.689558 },
    { lat: 48.9906849, lng: 20.6928451 },
    { lat: 48.9907776, lng: 20.6931107 },
    { lat: 48.9910669, lng: 20.6933661 },
    { lat: 48.992498, lng: 20.6947007 },
    { lat: 48.9935967, lng: 20.6917294 },
    { lat: 48.9946947, lng: 20.6890835 },
    { lat: 48.9956752, lng: 20.6868437 },
    { lat: 48.9970984, lng: 20.6839453 },
    { lat: 48.9976051, lng: 20.6831593 },
    { lat: 48.9993138, lng: 20.6812901 },
    { lat: 48.9998729, lng: 20.6808403 },
    { lat: 49.0011661, lng: 20.6804853 },
    { lat: 49.0029505, lng: 20.6796358 },
    { lat: 49.0065325, lng: 20.6775497 },
    { lat: 49.0094699, lng: 20.6757349 },
    { lat: 49.0098989, lng: 20.6753305 },
    { lat: 49.0113556, lng: 20.6743198 },
    { lat: 49.0121688, lng: 20.6738607 },
    { lat: 49.0126166, lng: 20.6737305 },
    { lat: 49.013071, lng: 20.6737195 },
    { lat: 49.0133941, lng: 20.6735591 },
    { lat: 49.0143084, lng: 20.6726526 },
    { lat: 49.0181023, lng: 20.6716817 },
    { lat: 49.0197438, lng: 20.6717789 },
    { lat: 49.0216234, lng: 20.6712035 },
    { lat: 49.0236455, lng: 20.6699815 },
    { lat: 49.0250345, lng: 20.6685584 },
    { lat: 49.0276974, lng: 20.6668487 },
    { lat: 49.0292509, lng: 20.6656512 },
    { lat: 49.0345505, lng: 20.6607041 },
    { lat: 49.0352443, lng: 20.6601319 },
    { lat: 49.0354875, lng: 20.6602408 },
    { lat: 49.0361965, lng: 20.6601156 },
    { lat: 49.0367577, lng: 20.6602854 },
    { lat: 49.038163, lng: 20.6599515 },
    { lat: 49.0383438, lng: 20.6598163 },
    { lat: 49.0388624, lng: 20.6597403 },
    { lat: 49.0391899, lng: 20.6598341 },
    { lat: 49.0397304, lng: 20.6597125 },
    { lat: 49.0403581, lng: 20.6596981 },
    { lat: 49.0413672, lng: 20.6594583 },
    { lat: 49.0415288, lng: 20.6593201 },
    { lat: 49.0418341, lng: 20.6592646 },
    { lat: 49.0420787, lng: 20.659117 },
    { lat: 49.0424183, lng: 20.6591082 },
    { lat: 49.0425395, lng: 20.659036 },
    { lat: 49.0426101, lng: 20.6591167 },
    { lat: 49.0449456, lng: 20.6579153 },
    { lat: 49.0446925, lng: 20.6570403 },
    { lat: 49.0445548, lng: 20.6564643 },
    { lat: 49.0444932, lng: 20.6565013 },
    { lat: 49.0444175, lng: 20.656405 },
    { lat: 49.044009, lng: 20.6551822 },
    { lat: 49.0435883, lng: 20.6547524 },
    { lat: 49.0433773, lng: 20.6543942 },
    { lat: 49.0431497, lng: 20.6541655 },
    { lat: 49.0429564, lng: 20.6541159 },
    { lat: 49.0423595, lng: 20.6535756 },
    { lat: 49.0413017, lng: 20.6530814 },
    { lat: 49.041261, lng: 20.6531113 },
    { lat: 49.0411912, lng: 20.6528631 },
    { lat: 49.040906, lng: 20.65266 },
    { lat: 49.040656, lng: 20.6523768 },
    { lat: 49.0403031, lng: 20.6515177 },
    { lat: 49.0401815, lng: 20.6509149 },
    { lat: 49.0402259, lng: 20.6498011 },
    { lat: 49.0404124, lng: 20.6489183 },
    { lat: 49.0403007, lng: 20.647971 },
    { lat: 49.0401017, lng: 20.6480058 },
    { lat: 49.0397226, lng: 20.647638 },
    { lat: 49.0387223, lng: 20.6473075 },
    { lat: 49.0385888, lng: 20.6471966 },
    { lat: 49.0374673, lng: 20.6466888 },
    { lat: 49.0374981, lng: 20.6464509 },
    { lat: 49.0368778, lng: 20.6462897 },
    { lat: 49.0342204, lng: 20.6481859 },
    { lat: 49.0303548, lng: 20.6531071 },
    { lat: 49.0295911, lng: 20.6538467 },
    { lat: 49.0289441, lng: 20.6543294 },
    { lat: 49.0290124, lng: 20.6541936 },
    { lat: 49.0289913, lng: 20.6541397 },
    { lat: 49.0287416, lng: 20.6543667 },
    { lat: 49.0285815, lng: 20.6540134 },
    { lat: 49.0279205, lng: 20.654479 },
    { lat: 49.0270677, lng: 20.6548331 },
    { lat: 49.026756, lng: 20.6548106 },
    { lat: 49.0267824, lng: 20.6552647 },
    { lat: 49.0269668, lng: 20.6555708 },
    { lat: 49.0274546, lng: 20.6561757 },
    { lat: 49.0268588, lng: 20.6567969 },
    { lat: 49.0265676, lng: 20.6569982 },
    { lat: 49.025676, lng: 20.6568681 },
    { lat: 49.0259538, lng: 20.6585572 },
    { lat: 49.0255037, lng: 20.659313 },
    { lat: 49.0254711, lng: 20.659692 },
    { lat: 49.0253009, lng: 20.6600322 },
    { lat: 49.0248012, lng: 20.6604115 },
    { lat: 49.0245048, lng: 20.6589266 },
    { lat: 49.0244728, lng: 20.6583507 },
    { lat: 49.020076, lng: 20.6588271 },
    { lat: 49.0199219, lng: 20.658332 },
    { lat: 49.0198256, lng: 20.6581617 },
    { lat: 49.0197487, lng: 20.6576151 },
    { lat: 49.0199051, lng: 20.6567675 },
    { lat: 49.019495, lng: 20.6567904 },
    { lat: 49.0188929, lng: 20.657011 },
    { lat: 49.0169374, lng: 20.6574468 },
    { lat: 49.0149307, lng: 20.6580307 },
    { lat: 49.0129383, lng: 20.6580043 },
    { lat: 49.0122839, lng: 20.658239 },
    { lat: 49.0123246, lng: 20.6588732 },
    { lat: 49.0122437, lng: 20.6589072 },
    { lat: 49.0123166, lng: 20.6592486 },
    { lat: 49.0124328, lng: 20.6594771 },
    { lat: 49.0125001, lng: 20.6599058 },
    { lat: 49.0122213, lng: 20.660422 },
    { lat: 49.0122817, lng: 20.6605322 },
    { lat: 49.0117352, lng: 20.6608367 },
    { lat: 49.0111965, lng: 20.660947 },
    { lat: 49.0111349, lng: 20.6602022 },
    { lat: 49.0109767, lng: 20.660059 },
    { lat: 49.0109498, lng: 20.6597768 },
    { lat: 49.0108091, lng: 20.6592459 },
    { lat: 49.0102789, lng: 20.6582123 },
    { lat: 49.009823, lng: 20.6589959 },
    { lat: 49.0094726, lng: 20.6593954 },
    { lat: 49.0089747, lng: 20.6597669 },
    { lat: 49.0086119, lng: 20.6597518 },
    { lat: 49.0082963, lng: 20.6598407 },
    { lat: 49.0078222, lng: 20.6600847 },
    { lat: 49.0062097, lng: 20.6602922 },
    { lat: 49.00628, lng: 20.6599619 },
    { lat: 49.0063349, lng: 20.6590139 },
    { lat: 49.0059322, lng: 20.6589609 },
    { lat: 49.0058388, lng: 20.6591196 },
    { lat: 49.0034953, lng: 20.6611962 },
    { lat: 49.0019949, lng: 20.6629317 },
    { lat: 49.0016609, lng: 20.6630042 },
    { lat: 48.9978358, lng: 20.6629774 },
    { lat: 48.9961709, lng: 20.6627309 },
    { lat: 48.9957808, lng: 20.6663532 },
    { lat: 48.9937766, lng: 20.6655905 },
    { lat: 48.9933887, lng: 20.6655426 },
    { lat: 48.991918, lng: 20.665499 },
    { lat: 48.9878569, lng: 20.6658012 },
    { lat: 48.9861875, lng: 20.6657354 },
    { lat: 48.9853058, lng: 20.665509 },
    { lat: 48.9853699, lng: 20.6656331 },
    { lat: 48.9858941, lng: 20.6683596 },
    { lat: 48.9859467, lng: 20.6689119 },
    { lat: 48.9859452, lng: 20.6712293 },
    { lat: 48.9857891, lng: 20.6715278 },
    { lat: 48.9853342, lng: 20.6733779 },
    { lat: 48.9844079, lng: 20.6740727 },
  ],
  DlhéStráže: [
    { lat: 49.0115573, lng: 20.5385981 },
    { lat: 49.0118641, lng: 20.5387025 },
    { lat: 49.0123085, lng: 20.5388464 },
    { lat: 49.0125034, lng: 20.538393 },
    { lat: 49.0134667, lng: 20.5366108 },
    { lat: 49.013724, lng: 20.5358048 },
    { lat: 49.0141971, lng: 20.5359649 },
    { lat: 49.0144445, lng: 20.5359822 },
    { lat: 49.0144027, lng: 20.5362874 },
    { lat: 49.0148801, lng: 20.5365354 },
    { lat: 49.0149229, lng: 20.5369912 },
    { lat: 49.0147943, lng: 20.5373816 },
    { lat: 49.0152192, lng: 20.5378794 },
    { lat: 49.0149307, lng: 20.5385134 },
    { lat: 49.0153047, lng: 20.5388861 },
    { lat: 49.0151459, lng: 20.5399208 },
    { lat: 49.0152831, lng: 20.5398223 },
    { lat: 49.0158217, lng: 20.5396877 },
    { lat: 49.0160351, lng: 20.5396717 },
    { lat: 49.0159993, lng: 20.5397357 },
    { lat: 49.0161439, lng: 20.5397177 },
    { lat: 49.0161855, lng: 20.5396556 },
    { lat: 49.0166003, lng: 20.5397666 },
    { lat: 49.0171476, lng: 20.5397056 },
    { lat: 49.0177118, lng: 20.5388214 },
    { lat: 49.0180951, lng: 20.5388701 },
    { lat: 49.0192774, lng: 20.5393012 },
    { lat: 49.0192598, lng: 20.538977 },
    { lat: 49.0193787, lng: 20.5387219 },
    { lat: 49.0195347, lng: 20.5385508 },
    { lat: 49.0201487, lng: 20.5384453 },
    { lat: 49.02052, lng: 20.5387695 },
    { lat: 49.0209004, lng: 20.5389217 },
    { lat: 49.0219446, lng: 20.5388438 },
    { lat: 49.0217996, lng: 20.5384827 },
    { lat: 49.0220261, lng: 20.5383364 },
    { lat: 49.0225915, lng: 20.5382502 },
    { lat: 49.0226506, lng: 20.537933 },
    { lat: 49.022871, lng: 20.5377108 },
    { lat: 49.0230471, lng: 20.53742 },
    { lat: 49.02305, lng: 20.5367622 },
    { lat: 49.0230962, lng: 20.5364296 },
    { lat: 49.0230902, lng: 20.536178 },
    { lat: 49.0227128, lng: 20.5361994 },
    { lat: 49.0222213, lng: 20.5360627 },
    { lat: 49.0219968, lng: 20.5357719 },
    { lat: 49.0219178, lng: 20.5351574 },
    { lat: 49.0219482, lng: 20.5349801 },
    { lat: 49.0226635, lng: 20.534014 },
    { lat: 49.0231377, lng: 20.5330429 },
    { lat: 49.0240656, lng: 20.531829 },
    { lat: 49.024347, lng: 20.5315535 },
    { lat: 49.0246986, lng: 20.5309233 },
    { lat: 49.0245652, lng: 20.5299973 },
    { lat: 49.0245969, lng: 20.5292394 },
    { lat: 49.0245308, lng: 20.528848 },
    { lat: 49.0238588, lng: 20.5272128 },
    { lat: 49.0236288, lng: 20.5266532 },
    { lat: 49.0245276, lng: 20.5260508 },
    { lat: 49.0249198, lng: 20.5260022 },
    { lat: 49.0252535, lng: 20.5260769 },
    { lat: 49.0254601, lng: 20.5263344 },
    { lat: 49.0256056, lng: 20.5263715 },
    { lat: 49.0257838, lng: 20.526565 },
    { lat: 49.0260737, lng: 20.526425 },
    { lat: 49.0264863, lng: 20.5263772 },
    { lat: 49.0265117, lng: 20.5259104 },
    { lat: 49.0268832, lng: 20.5258184 },
    { lat: 49.0271544, lng: 20.5256 },
    { lat: 49.0279191, lng: 20.5254241 },
    { lat: 49.0280677, lng: 20.5261224 },
    { lat: 49.0287574, lng: 20.5262033 },
    { lat: 49.0290734, lng: 20.5261677 },
    { lat: 49.0297144, lng: 20.526416 },
    { lat: 49.0298929, lng: 20.526376 },
    { lat: 49.03022, lng: 20.5262705 },
    { lat: 49.0301158, lng: 20.525763 },
    { lat: 49.0300263, lng: 20.5253269 },
    { lat: 49.0302951, lng: 20.5251378 },
    { lat: 49.0308972, lng: 20.5249457 },
    { lat: 49.0316651, lng: 20.5245172 },
    { lat: 49.0328718, lng: 20.5247913 },
    { lat: 49.0336868, lng: 20.525789 },
    { lat: 49.0338478, lng: 20.5263662 },
    { lat: 49.0342329, lng: 20.5262468 },
    { lat: 49.0342888, lng: 20.5264192 },
    { lat: 49.0346177, lng: 20.5263613 },
    { lat: 49.0353482, lng: 20.5260516 },
    { lat: 49.0354631, lng: 20.5259312 },
    { lat: 49.0364806, lng: 20.5257756 },
    { lat: 49.0365613, lng: 20.5257633 },
    { lat: 49.0365319, lng: 20.5253324 },
    { lat: 49.0363982, lng: 20.5253079 },
    { lat: 49.0363301, lng: 20.5250823 },
    { lat: 49.0363552, lng: 20.5247917 },
    { lat: 49.0364505, lng: 20.5246892 },
    { lat: 49.0370305, lng: 20.5246908 },
    { lat: 49.0372477, lng: 20.5246252 },
    { lat: 49.0371386, lng: 20.5244178 },
    { lat: 49.0368983, lng: 20.5241589 },
    { lat: 49.0359993, lng: 20.5238135 },
    { lat: 49.0352063, lng: 20.523223 },
    { lat: 49.035018, lng: 20.5229736 },
    { lat: 49.0347871, lng: 20.5229385 },
    { lat: 49.0339422, lng: 20.5222222 },
    { lat: 49.0334484, lng: 20.5223577 },
    { lat: 49.0332245, lng: 20.5225463 },
    { lat: 49.0332487, lng: 20.522743 },
    { lat: 49.0331198, lng: 20.5228215 },
    { lat: 49.0328197, lng: 20.522741 },
    { lat: 49.0327957, lng: 20.5227606 },
    { lat: 49.0328706, lng: 20.5229289 },
    { lat: 49.0326072, lng: 20.5230853 },
    { lat: 49.032229, lng: 20.5229333 },
    { lat: 49.0320883, lng: 20.5226296 },
    { lat: 49.0318032, lng: 20.5223795 },
    { lat: 49.0313962, lng: 20.5221776 },
    { lat: 49.0311884, lng: 20.5220745 },
    { lat: 49.0311618, lng: 20.5218468 },
    { lat: 49.0319428, lng: 20.521988 },
    { lat: 49.0318683, lng: 20.5214615 },
    { lat: 49.032478, lng: 20.5211137 },
    { lat: 49.0325587, lng: 20.5210127 },
    { lat: 49.0337847, lng: 20.5205252 },
    { lat: 49.0344073, lng: 20.5204363 },
    { lat: 49.0344089, lng: 20.5203888 },
    { lat: 49.0338224, lng: 20.520391 },
    { lat: 49.0335299, lng: 20.5204878 },
    { lat: 49.0333203, lng: 20.5204513 },
    { lat: 49.033296, lng: 20.5201268 },
    { lat: 49.0338399, lng: 20.5200099 },
    { lat: 49.0338415, lng: 20.5199 },
    { lat: 49.0347542, lng: 20.5200126 },
    { lat: 49.0352922, lng: 20.5200064 },
    { lat: 49.0352968, lng: 20.5199536 },
    { lat: 49.0350938, lng: 20.5199125 },
    { lat: 49.0351107, lng: 20.5197323 },
    { lat: 49.0358349, lng: 20.5198246 },
    { lat: 49.0359273, lng: 20.5196186 },
    { lat: 49.0362886, lng: 20.5195822 },
    { lat: 49.0364555, lng: 20.5194721 },
    { lat: 49.0367089, lng: 20.5194396 },
    { lat: 49.0374479, lng: 20.519696 },
    { lat: 49.0383793, lng: 20.5198889 },
    { lat: 49.0390185, lng: 20.5199974 },
    { lat: 49.0394079, lng: 20.5199066 },
    { lat: 49.0395803, lng: 20.520274 },
    { lat: 49.0399951, lng: 20.520134 },
    { lat: 49.04045, lng: 20.5195876 },
    { lat: 49.04062, lng: 20.5192863 },
    { lat: 49.0408748, lng: 20.5190509 },
    { lat: 49.0418232, lng: 20.5185153 },
    { lat: 49.0417472, lng: 20.5183492 },
    { lat: 49.0409554, lng: 20.5186482 },
    { lat: 49.0406636, lng: 20.5188828 },
    { lat: 49.0406142, lng: 20.5185817 },
    { lat: 49.0400019, lng: 20.5190127 },
    { lat: 49.0423448, lng: 20.511905 },
    { lat: 49.0428622, lng: 20.5123185 },
    { lat: 49.0437237, lng: 20.513295 },
    { lat: 49.0439906, lng: 20.5126283 },
    { lat: 49.0442171, lng: 20.5122725 },
    { lat: 49.0445494, lng: 20.5120617 },
    { lat: 49.0446731, lng: 20.5127882 },
    { lat: 49.0448922, lng: 20.5124428 },
    { lat: 49.0450305, lng: 20.5123487 },
    { lat: 49.045177, lng: 20.512133 },
    { lat: 49.045247, lng: 20.512047 },
    { lat: 49.045192, lng: 20.511485 },
    { lat: 49.045159, lng: 20.511096 },
    { lat: 49.045119, lng: 20.510756 },
    { lat: 49.045075, lng: 20.510267 },
    { lat: 49.045055, lng: 20.510063 },
    { lat: 49.045003, lng: 20.510087 },
    { lat: 49.044795, lng: 20.510177 },
    { lat: 49.044755, lng: 20.510194 },
    { lat: 49.044579, lng: 20.510271 },
    { lat: 49.044357, lng: 20.510368 },
    { lat: 49.044323, lng: 20.510384 },
    { lat: 49.044121, lng: 20.510465 },
    { lat: 49.043905, lng: 20.510554 },
    { lat: 49.043582, lng: 20.510686 },
    { lat: 49.043435, lng: 20.510744 },
    { lat: 49.043371, lng: 20.510771 },
    { lat: 49.043283, lng: 20.51081 },
    { lat: 49.043146, lng: 20.510864 },
    { lat: 49.04293, lng: 20.510958 },
    { lat: 49.042659, lng: 20.511071 },
    { lat: 49.042365, lng: 20.511181 },
    { lat: 49.042256, lng: 20.511224 },
    { lat: 49.042193, lng: 20.511251 },
    { lat: 49.041836, lng: 20.510929 },
    { lat: 49.04156, lng: 20.510847 },
    { lat: 49.041227, lng: 20.510733 },
    { lat: 49.040941, lng: 20.510673 },
    { lat: 49.040723, lng: 20.510609 },
    { lat: 49.040384, lng: 20.510472 },
    { lat: 49.040095, lng: 20.510354 },
    { lat: 49.039936, lng: 20.510265 },
    { lat: 49.039679, lng: 20.510025 },
    { lat: 49.039451, lng: 20.509666 },
    { lat: 49.039375, lng: 20.509348 },
    { lat: 49.039364, lng: 20.509073 },
    { lat: 49.039406, lng: 20.508659 },
    { lat: 49.0396138, lng: 20.5082453 },
    { lat: 49.039629, lng: 20.5076583 },
    { lat: 49.0392936, lng: 20.5065363 },
    { lat: 49.0390247, lng: 20.5062074 },
    { lat: 49.0357199, lng: 20.5060172 },
    { lat: 49.0338626, lng: 20.5058439 },
    { lat: 49.0323287, lng: 20.5060174 },
    { lat: 49.0294247, lng: 20.5086737 },
    { lat: 49.0225592, lng: 20.5155596 },
    { lat: 49.0220608, lng: 20.515602 },
    { lat: 49.0218403, lng: 20.5156944 },
    { lat: 49.0214283, lng: 20.5160028 },
    { lat: 49.0214387, lng: 20.5164607 },
    { lat: 49.0213229, lng: 20.5177405 },
    { lat: 49.0215508, lng: 20.5183645 },
    { lat: 49.0213596, lng: 20.5185229 },
    { lat: 49.0213575, lng: 20.5195842 },
    { lat: 49.0211998, lng: 20.5196156 },
    { lat: 49.0209901, lng: 20.5217683 },
    { lat: 49.0208842, lng: 20.5222208 },
    { lat: 49.0206639, lng: 20.5227763 },
    { lat: 49.0197972, lng: 20.5238746 },
    { lat: 49.0197907, lng: 20.524005 },
    { lat: 49.0196074, lng: 20.5239805 },
    { lat: 49.0194719, lng: 20.523849 },
    { lat: 49.0190878, lng: 20.5243026 },
    { lat: 49.0188409, lng: 20.5243166 },
    { lat: 49.0180325, lng: 20.5239826 },
    { lat: 49.018038, lng: 20.52391 },
    { lat: 49.017839, lng: 20.52404 },
    { lat: 49.017795, lng: 20.524113 },
    { lat: 49.017787, lng: 20.524189 },
    { lat: 49.017752, lng: 20.524507 },
    { lat: 49.017712, lng: 20.52475 },
    { lat: 49.0177, lng: 20.525222 },
    { lat: 49.017608, lng: 20.525536 },
    { lat: 49.017586, lng: 20.525582 },
    { lat: 49.017395, lng: 20.525808 },
    { lat: 49.017362, lng: 20.525848 },
    { lat: 49.017288, lng: 20.525797 },
    { lat: 49.017266, lng: 20.525782 },
    { lat: 49.017229, lng: 20.52581 },
    { lat: 49.017233, lng: 20.526075 },
    { lat: 49.017252, lng: 20.526102 },
    { lat: 49.017347, lng: 20.526232 },
    { lat: 49.01736, lng: 20.526502 },
    { lat: 49.017408, lng: 20.52664 },
    { lat: 49.017428, lng: 20.526684 },
    { lat: 49.017449, lng: 20.526718 },
    { lat: 49.017438, lng: 20.527177 },
    { lat: 49.017895, lng: 20.527612 },
    { lat: 49.017996, lng: 20.527878 },
    { lat: 49.017947, lng: 20.52815 },
    { lat: 49.017993, lng: 20.528302 },
    { lat: 49.017996, lng: 20.528314 },
    { lat: 49.018081, lng: 20.528598 },
    { lat: 49.018093, lng: 20.528646 },
    { lat: 49.018183, lng: 20.528761 },
    { lat: 49.018361, lng: 20.528874 },
    { lat: 49.01859, lng: 20.52902 },
    { lat: 49.018737, lng: 20.529087 },
    { lat: 49.018944, lng: 20.529474 },
    { lat: 49.01897, lng: 20.529541 },
    { lat: 49.019182, lng: 20.530186 },
    { lat: 49.019008, lng: 20.530215 },
    { lat: 49.018926, lng: 20.53024 },
    { lat: 49.018715, lng: 20.53032 },
    { lat: 49.018702, lng: 20.530325 },
    { lat: 49.018703, lng: 20.530339 },
    { lat: 49.018777, lng: 20.530944 },
    { lat: 49.018846, lng: 20.531461 },
    { lat: 49.018827, lng: 20.531458 },
    { lat: 49.018771, lng: 20.531452 },
    { lat: 49.018528, lng: 20.531428 },
    { lat: 49.018287, lng: 20.531425 },
    { lat: 49.018257, lng: 20.531425 },
    { lat: 49.01823, lng: 20.531424 },
    { lat: 49.018207, lng: 20.531424 },
    { lat: 49.018174, lng: 20.531423 },
    { lat: 49.018136, lng: 20.531423 },
    { lat: 49.018114, lng: 20.531423 },
    { lat: 49.018039, lng: 20.531422 },
    { lat: 49.018011, lng: 20.531403 },
    { lat: 49.017667, lng: 20.531297 },
    { lat: 49.017122, lng: 20.531204 },
    { lat: 49.016163, lng: 20.531053 },
    { lat: 49.016082, lng: 20.53104 },
    { lat: 49.015183, lng: 20.530933 },
    { lat: 49.014676, lng: 20.530835 },
    { lat: 49.014346, lng: 20.530757 },
    { lat: 49.014162, lng: 20.530713 },
    { lat: 49.014096, lng: 20.530825 },
    { lat: 49.014076, lng: 20.53086 },
    { lat: 49.013967, lng: 20.531023 },
    { lat: 49.013942, lng: 20.531062 },
    { lat: 49.013537, lng: 20.531697 },
    { lat: 49.012785, lng: 20.53275 },
    { lat: 49.011894, lng: 20.533575 },
    { lat: 49.011511, lng: 20.533918 },
    { lat: 49.011482, lng: 20.533944 },
    { lat: 49.011466, lng: 20.533957 },
    { lat: 49.0112972, lng: 20.5340842 },
    { lat: 49.0112844, lng: 20.5347717 },
    { lat: 49.0114542, lng: 20.5358031 },
    { lat: 49.0116924, lng: 20.5362493 },
    { lat: 49.0115952, lng: 20.5378851 },
    { lat: 49.0115573, lng: 20.5385981 },
  ],
  Pongrácovce: [
    { lat: 49.0456606, lng: 20.8254444 },
    { lat: 49.0456224, lng: 20.8253498 },
    { lat: 49.0440339, lng: 20.8253736 },
    { lat: 49.0424878, lng: 20.8258796 },
    { lat: 49.0420138, lng: 20.8258086 },
    { lat: 49.0409846, lng: 20.8259296 },
    { lat: 49.0404424, lng: 20.8259096 },
    { lat: 49.0396693, lng: 20.8257473 },
    { lat: 49.0391012, lng: 20.8253322 },
    { lat: 49.0379478, lng: 20.8255363 },
    { lat: 49.0373884, lng: 20.8257667 },
    { lat: 49.0364622, lng: 20.8258099 },
    { lat: 49.0357624, lng: 20.8257207 },
    { lat: 49.0351537, lng: 20.8254624 },
    { lat: 49.0348627, lng: 20.8250519 },
    { lat: 49.0340316, lng: 20.8251392 },
    { lat: 49.0337156, lng: 20.8249204 },
    { lat: 49.0335551, lng: 20.8247043 },
    { lat: 49.0335909, lng: 20.8240113 },
    { lat: 49.0331195, lng: 20.8236922 },
    { lat: 49.0321602, lng: 20.82352 },
    { lat: 49.0318956, lng: 20.8233226 },
    { lat: 49.0308142, lng: 20.8220248 },
    { lat: 49.0307227, lng: 20.8214908 },
    { lat: 49.0305512, lng: 20.8209898 },
    { lat: 49.0302315, lng: 20.8205278 },
    { lat: 49.0297664, lng: 20.8206853 },
    { lat: 49.029138, lng: 20.8199262 },
    { lat: 49.0287591, lng: 20.8193473 },
    { lat: 49.0281382, lng: 20.8186461 },
    { lat: 49.0277229, lng: 20.8182868 },
    { lat: 49.0274379, lng: 20.8184092 },
    { lat: 49.0267559, lng: 20.8178233 },
    { lat: 49.0263753, lng: 20.8172665 },
    { lat: 49.0261197, lng: 20.8171657 },
    { lat: 49.0252853, lng: 20.8166017 },
    { lat: 49.0243421, lng: 20.8157889 },
    { lat: 49.0224482, lng: 20.8146926 },
    { lat: 49.0216233, lng: 20.8141096 },
    { lat: 49.0213499, lng: 20.8139521 },
    { lat: 49.0210018, lng: 20.8145358 },
    { lat: 49.0204793, lng: 20.8139717 },
    { lat: 49.0202598, lng: 20.8136123 },
    { lat: 49.0195743, lng: 20.8141992 },
    { lat: 49.0183489, lng: 20.8159083 },
    { lat: 49.0169587, lng: 20.8151915 },
    { lat: 49.015131, lng: 20.8136006 },
    { lat: 49.0150342, lng: 20.8135969 },
    { lat: 49.0149795, lng: 20.8136808 },
    { lat: 49.0141093, lng: 20.8153718 },
    { lat: 49.013907, lng: 20.816126 },
    { lat: 49.013667, lng: 20.8189955 },
    { lat: 49.013619, lng: 20.820505 },
    { lat: 49.013487, lng: 20.8220682 },
    { lat: 49.0135118, lng: 20.8224241 },
    { lat: 49.0134697, lng: 20.8237564 },
    { lat: 49.0131488, lng: 20.8260762 },
    { lat: 49.0130117, lng: 20.8273202 },
    { lat: 49.0129652, lng: 20.8281626 },
    { lat: 49.0128509, lng: 20.8289004 },
    { lat: 49.0127536, lng: 20.8307476 },
    { lat: 49.0127419, lng: 20.8309289 },
    { lat: 49.0138074, lng: 20.8316396 },
    { lat: 49.013977, lng: 20.8319209 },
    { lat: 49.0146068, lng: 20.8326622 },
    { lat: 49.0151784, lng: 20.8325541 },
    { lat: 49.0157311, lng: 20.8331133 },
    { lat: 49.0156262, lng: 20.8333673 },
    { lat: 49.0160034, lng: 20.8335081 },
    { lat: 49.0152912, lng: 20.8336747 },
    { lat: 49.0155512, lng: 20.8338757 },
    { lat: 49.0156796, lng: 20.8338723 },
    { lat: 49.0161068, lng: 20.8343548 },
    { lat: 49.0162458, lng: 20.8343692 },
    { lat: 49.0163438, lng: 20.83427 },
    { lat: 49.0163716, lng: 20.8343933 },
    { lat: 49.0162407, lng: 20.8349433 },
    { lat: 49.0162177, lng: 20.835255 },
    { lat: 49.0164215, lng: 20.8350301 },
    { lat: 49.016691, lng: 20.834876 },
    { lat: 49.0171551, lng: 20.8349819 },
    { lat: 49.0176635, lng: 20.8348397 },
    { lat: 49.0179194, lng: 20.8348549 },
    { lat: 49.0188344, lng: 20.834656 },
    { lat: 49.0193649, lng: 20.8347595 },
    { lat: 49.0197106, lng: 20.8350599 },
    { lat: 49.0199785, lng: 20.835163 },
    { lat: 49.0206346, lng: 20.8351859 },
    { lat: 49.0216683, lng: 20.8351021 },
    { lat: 49.0219255, lng: 20.8348227 },
    { lat: 49.0226162, lng: 20.8345853 },
    { lat: 49.0231701, lng: 20.8342243 },
    { lat: 49.0240372, lng: 20.8340052 },
    { lat: 49.0246781, lng: 20.8339227 },
    { lat: 49.0266306, lng: 20.8331543 },
    { lat: 49.0276601, lng: 20.8328509 },
    { lat: 49.0281885, lng: 20.8328663 },
    { lat: 49.0285503, lng: 20.8329756 },
    { lat: 49.0290784, lng: 20.8326862 },
    { lat: 49.0296481, lng: 20.832661 },
    { lat: 49.0300981, lng: 20.8327838 },
    { lat: 49.0307585, lng: 20.8327751 },
    { lat: 49.0314531, lng: 20.8320871 },
    { lat: 49.0316649, lng: 20.8319641 },
    { lat: 49.0319139, lng: 20.8316746 },
    { lat: 49.0321811, lng: 20.8318844 },
    { lat: 49.0328967, lng: 20.8318088 },
    { lat: 49.0334651, lng: 20.8313207 },
    { lat: 49.0335993, lng: 20.8312928 },
    { lat: 49.0340571, lng: 20.830729 },
    { lat: 49.0343854, lng: 20.8304188 },
    { lat: 49.0355421, lng: 20.8298497 },
    { lat: 49.0359251, lng: 20.8297928 },
    { lat: 49.0366362, lng: 20.8299493 },
    { lat: 49.0370939, lng: 20.8297056 },
    { lat: 49.0380616, lng: 20.8295055 },
    { lat: 49.0383721, lng: 20.829502 },
    { lat: 49.039033, lng: 20.8291782 },
    { lat: 49.039714, lng: 20.8286523 },
    { lat: 49.0400756, lng: 20.8285625 },
    { lat: 49.0404873, lng: 20.8286213 },
    { lat: 49.0410241, lng: 20.8289165 },
    { lat: 49.0414371, lng: 20.8288471 },
    { lat: 49.0421643, lng: 20.8283523 },
    { lat: 49.0432347, lng: 20.8265216 },
    { lat: 49.0435216, lng: 20.826319 },
    { lat: 49.0445529, lng: 20.8258006 },
    { lat: 49.0456606, lng: 20.8254444 },
  ],
  Bijacovce: [
    { lat: 49.0456606, lng: 20.8254444 },
    { lat: 49.0460143, lng: 20.8258123 },
    { lat: 49.046077, lng: 20.8261945 },
    { lat: 49.0461064, lng: 20.8268258 },
    { lat: 49.0465304, lng: 20.8285503 },
    { lat: 49.0474416, lng: 20.830502 },
    { lat: 49.0477506, lng: 20.8313318 },
    { lat: 49.0480616, lng: 20.8324894 },
    { lat: 49.0481975, lng: 20.8328148 },
    { lat: 49.0484309, lng: 20.8331827 },
    { lat: 49.0489239, lng: 20.833445 },
    { lat: 49.0490143, lng: 20.8343051 },
    { lat: 49.0490826, lng: 20.8344938 },
    { lat: 49.0501444, lng: 20.8361139 },
    { lat: 49.0506527, lng: 20.8364924 },
    { lat: 49.0507204, lng: 20.8365968 },
    { lat: 49.050683, lng: 20.8373767 },
    { lat: 49.0509071, lng: 20.8380019 },
    { lat: 49.0512493, lng: 20.8387596 },
    { lat: 49.0515836, lng: 20.8391181 },
    { lat: 49.0517778, lng: 20.839239 },
    { lat: 49.0517841, lng: 20.8391521 },
    { lat: 49.0518249, lng: 20.8391671 },
    { lat: 49.0523409, lng: 20.8395077 },
    { lat: 49.0533008, lng: 20.8394986 },
    { lat: 49.0554081, lng: 20.8397714 },
    { lat: 49.0559936, lng: 20.8396772 },
    { lat: 49.0566556, lng: 20.8397151 },
    { lat: 49.0577196, lng: 20.8394609 },
    { lat: 49.0599623, lng: 20.8394448 },
    { lat: 49.0609028, lng: 20.8400169 },
    { lat: 49.0612408, lng: 20.8401297 },
    { lat: 49.0616553, lng: 20.840366 },
    { lat: 49.0628913, lng: 20.8412267 },
    { lat: 49.0640334, lng: 20.841808 },
    { lat: 49.0643149, lng: 20.8418374 },
    { lat: 49.0650685, lng: 20.841633 },
    { lat: 49.0652172, lng: 20.841469 },
    { lat: 49.0660109, lng: 20.8411483 },
    { lat: 49.0662156, lng: 20.8410071 },
    { lat: 49.0668701, lng: 20.8403687 },
    { lat: 49.0679095, lng: 20.8391335 },
    { lat: 49.0685553, lng: 20.8385689 },
    { lat: 49.0690714, lng: 20.8379292 },
    { lat: 49.069113, lng: 20.8380224 },
    { lat: 49.0692422, lng: 20.8379508 },
    { lat: 49.0698415, lng: 20.8379014 },
    { lat: 49.070934, lng: 20.8379504 },
    { lat: 49.071215, lng: 20.8381381 },
    { lat: 49.0714363, lng: 20.8384562 },
    { lat: 49.0719135, lng: 20.8395384 },
    { lat: 49.0725782, lng: 20.8406696 },
    { lat: 49.0732729, lng: 20.8413339 },
    { lat: 49.0737551, lng: 20.8416112 },
    { lat: 49.0742626, lng: 20.8417056 },
    { lat: 49.0761317, lng: 20.8417573 },
    { lat: 49.0768698, lng: 20.8417195 },
    { lat: 49.0770181, lng: 20.8418406 },
    { lat: 49.0775749, lng: 20.8426851 },
    { lat: 49.0779623, lng: 20.8430206 },
    { lat: 49.0784596, lng: 20.8432436 },
    { lat: 49.0787439, lng: 20.8431632 },
    { lat: 49.078923, lng: 20.8433296 },
    { lat: 49.0791158, lng: 20.8437689 },
    { lat: 49.0792729, lng: 20.8444157 },
    { lat: 49.079468, lng: 20.8448181 },
    { lat: 49.0798379, lng: 20.8452681 },
    { lat: 49.0802128, lng: 20.8458646 },
    { lat: 49.0804115, lng: 20.8460463 },
    { lat: 49.0807446, lng: 20.846207 },
    { lat: 49.0809813, lng: 20.846417 },
    { lat: 49.0821028, lng: 20.8463963 },
    { lat: 49.0825723, lng: 20.8462349 },
    { lat: 49.0826521, lng: 20.8460993 },
    { lat: 49.0828163, lng: 20.8461979 },
    { lat: 49.0828665, lng: 20.846051 },
    { lat: 49.082925, lng: 20.846039 },
    { lat: 49.082825, lng: 20.845936 },
    { lat: 49.082651, lng: 20.845834 },
    { lat: 49.082463, lng: 20.845762 },
    { lat: 49.08235, lng: 20.84572 },
    { lat: 49.082341, lng: 20.845525 },
    { lat: 49.082325, lng: 20.84513 },
    { lat: 49.082121, lng: 20.844956 },
    { lat: 49.081984, lng: 20.844839 },
    { lat: 49.081891, lng: 20.844759 },
    { lat: 49.081666, lng: 20.844433 },
    { lat: 49.081263, lng: 20.843844 },
    { lat: 49.08115, lng: 20.843681 },
    { lat: 49.080759, lng: 20.84302 },
    { lat: 49.080738, lng: 20.842956 },
    { lat: 49.080512, lng: 20.842276 },
    { lat: 49.080491, lng: 20.841789 },
    { lat: 49.080456, lng: 20.841665 },
    { lat: 49.080393, lng: 20.841442 },
    { lat: 49.080337, lng: 20.840861 },
    { lat: 49.0803, lng: 20.840478 },
    { lat: 49.080284, lng: 20.840309 },
    { lat: 49.080257, lng: 20.840065 },
    { lat: 49.080241, lng: 20.839919 },
    { lat: 49.080181, lng: 20.839361 },
    { lat: 49.080179, lng: 20.839341 },
    { lat: 49.080037, lng: 20.838538 },
    { lat: 49.080016, lng: 20.838081 },
    { lat: 49.080005, lng: 20.837868 },
    { lat: 49.079932, lng: 20.837479 },
    { lat: 49.079857, lng: 20.83708 },
    { lat: 49.07981, lng: 20.836473 },
    { lat: 49.0798114, lng: 20.8362904 },
    { lat: 49.079813, lng: 20.836089 },
    { lat: 49.079574, lng: 20.835797 },
    { lat: 49.079432, lng: 20.835623 },
    { lat: 49.079457, lng: 20.835555 },
    { lat: 49.07952, lng: 20.835374 },
    { lat: 49.079548, lng: 20.835298 },
    { lat: 49.07962, lng: 20.835062 },
    { lat: 49.079567, lng: 20.834758 },
    { lat: 49.079318, lng: 20.834353 },
    { lat: 49.07923, lng: 20.833935 },
    { lat: 49.079196, lng: 20.833774 },
    { lat: 49.079108, lng: 20.833353 },
    { lat: 49.079085, lng: 20.833245 },
    { lat: 49.079036, lng: 20.833016 },
    { lat: 49.078997, lng: 20.832659 },
    { lat: 49.078972, lng: 20.832439 },
    { lat: 49.078783, lng: 20.832184 },
    { lat: 49.078687, lng: 20.832055 },
    { lat: 49.078703, lng: 20.831973 },
    { lat: 49.078766, lng: 20.831643 },
    { lat: 49.078448, lng: 20.831285 },
    { lat: 49.078401, lng: 20.831232 },
    { lat: 49.078364, lng: 20.831208 },
    { lat: 49.078277, lng: 20.83115 },
    { lat: 49.078144, lng: 20.831062 },
    { lat: 49.077909, lng: 20.830906 },
    { lat: 49.077867, lng: 20.830861 },
    { lat: 49.077613, lng: 20.830592 },
    { lat: 49.077552, lng: 20.830529 },
    { lat: 49.077572, lng: 20.83042 },
    { lat: 49.077619, lng: 20.830156 },
    { lat: 49.077372, lng: 20.829761 },
    { lat: 49.076273, lng: 20.8288748 },
    { lat: 49.0759499, lng: 20.8287821 },
    { lat: 49.0758761, lng: 20.8285276 },
    { lat: 49.0758875, lng: 20.828172 },
    { lat: 49.0757691, lng: 20.8280019 },
    { lat: 49.0755676, lng: 20.8281354 },
    { lat: 49.0752863, lng: 20.8279398 },
    { lat: 49.0750439, lng: 20.8279957 },
    { lat: 49.0748318, lng: 20.8282072 },
    { lat: 49.0744118, lng: 20.8277438 },
    { lat: 49.0744606, lng: 20.8274624 },
    { lat: 49.0743689, lng: 20.827381 },
    { lat: 49.0743619, lng: 20.8273747 },
    { lat: 49.0743, lng: 20.827049 },
    { lat: 49.074126, lng: 20.8269275 },
    { lat: 49.0740752, lng: 20.8267287 },
    { lat: 49.0739717, lng: 20.826565 },
    { lat: 49.0735994, lng: 20.8266097 },
    { lat: 49.073198, lng: 20.8264869 },
    { lat: 49.0729369, lng: 20.8263275 },
    { lat: 49.0726625, lng: 20.8258722 },
    { lat: 49.072542, lng: 20.8254833 },
    { lat: 49.0725901, lng: 20.8251754 },
    { lat: 49.072555, lng: 20.8249427 },
    { lat: 49.0724419, lng: 20.8247607 },
    { lat: 49.072408, lng: 20.824496 },
    { lat: 49.0721595, lng: 20.8242795 },
    { lat: 49.0719892, lng: 20.8236394 },
    { lat: 49.0720327, lng: 20.8233702 },
    { lat: 49.0720187, lng: 20.8231265 },
    { lat: 49.0718643, lng: 20.8229234 },
    { lat: 49.0718173, lng: 20.822669 },
    { lat: 49.0718933, lng: 20.8226224 },
    { lat: 49.0718907, lng: 20.8225131 },
    { lat: 49.0717703, lng: 20.8223167 },
    { lat: 49.0715159, lng: 20.8221905 },
    { lat: 49.0713503, lng: 20.8218249 },
    { lat: 49.0713928, lng: 20.8215878 },
    { lat: 49.0713562, lng: 20.8214066 },
    { lat: 49.0713779, lng: 20.8210914 },
    { lat: 49.0710479, lng: 20.820235 },
    { lat: 49.0708744, lng: 20.8199986 },
    { lat: 49.0708632, lng: 20.8194822 },
    { lat: 49.0705939, lng: 20.819271 },
    { lat: 49.0705885, lng: 20.8189832 },
    { lat: 49.0704196, lng: 20.8186265 },
    { lat: 49.0700833, lng: 20.8183411 },
    { lat: 49.0699599, lng: 20.8181545 },
    { lat: 49.0696877, lng: 20.8174077 },
    { lat: 49.0695075, lng: 20.8173061 },
    { lat: 49.069144, lng: 20.8174758 },
    { lat: 49.0686869, lng: 20.8175385 },
    { lat: 49.0684711, lng: 20.8177105 },
    { lat: 49.0683943, lng: 20.8176656 },
    { lat: 49.0681932, lng: 20.8172215 },
    { lat: 49.0680728, lng: 20.8170882 },
    { lat: 49.0678708, lng: 20.8171072 },
    { lat: 49.0677439, lng: 20.8169217 },
    { lat: 49.0677319, lng: 20.8167392 },
    { lat: 49.0668655, lng: 20.816154 },
    { lat: 49.0666782, lng: 20.8155579 },
    { lat: 49.0662223, lng: 20.8149367 },
    { lat: 49.0659846, lng: 20.8139804 },
    { lat: 49.0659651, lng: 20.8136478 },
    { lat: 49.0658011, lng: 20.8133317 },
    { lat: 49.0653558, lng: 20.8130794 },
    { lat: 49.0652893, lng: 20.8128808 },
    { lat: 49.0652941, lng: 20.8125351 },
    { lat: 49.0651338, lng: 20.8121965 },
    { lat: 49.0648864, lng: 20.8122988 },
    { lat: 49.0647163, lng: 20.8119021 },
    { lat: 49.0643996, lng: 20.8115624 },
    { lat: 49.0642121, lng: 20.8115476 },
    { lat: 49.0639989, lng: 20.8116662 },
    { lat: 49.0636909, lng: 20.8115763 },
    { lat: 49.06308, lng: 20.8107368 },
    { lat: 49.0625028, lng: 20.8103529 },
    { lat: 49.0622339, lng: 20.8099038 },
    { lat: 49.0616475, lng: 20.809315 },
    { lat: 49.061616, lng: 20.8089554 },
    { lat: 49.0616576, lng: 20.8086304 },
    { lat: 49.0613625, lng: 20.8077424 },
    { lat: 49.0607331, lng: 20.8069862 },
    { lat: 49.0602845, lng: 20.806839 },
    { lat: 49.0601395, lng: 20.8065183 },
    { lat: 49.0600367, lng: 20.8064102 },
    { lat: 49.0597707, lng: 20.8063719 },
    { lat: 49.0595813, lng: 20.8064876 },
    { lat: 49.0592302, lng: 20.8064902 },
    { lat: 49.0587391, lng: 20.8062589 },
    { lat: 49.0580526, lng: 20.806289 },
    { lat: 49.0573106, lng: 20.8060247 },
    { lat: 49.057101, lng: 20.8060465 },
    { lat: 49.0569289, lng: 20.8063147 },
    { lat: 49.0567513, lng: 20.8064183 },
    { lat: 49.0564417, lng: 20.8064136 },
    { lat: 49.0558247, lng: 20.8059587 },
    { lat: 49.0553021, lng: 20.8058796 },
    { lat: 49.0552498, lng: 20.8058332 },
    { lat: 49.0551766, lng: 20.8055372 },
    { lat: 49.0550727, lng: 20.8054989 },
    { lat: 49.0549735, lng: 20.8055585 },
    { lat: 49.0548761, lng: 20.8054432 },
    { lat: 49.0547801, lng: 20.8049172 },
    { lat: 49.0546486, lng: 20.8045911 },
    { lat: 49.0544477, lng: 20.8043484 },
    { lat: 49.0541631, lng: 20.8042571 },
    { lat: 49.0540408, lng: 20.8040756 },
    { lat: 49.0539975, lng: 20.8036337 },
    { lat: 49.0536474, lng: 20.8034084 },
    { lat: 49.0534109, lng: 20.8029812 },
    { lat: 49.0533165, lng: 20.8026579 },
    { lat: 49.0531686, lng: 20.8024802 },
    { lat: 49.0530085, lng: 20.802544 },
    { lat: 49.0529848, lng: 20.8026356 },
    { lat: 49.0524993, lng: 20.8028249 },
    { lat: 49.0521915, lng: 20.8030372 },
    { lat: 49.0521167, lng: 20.8032122 },
    { lat: 49.0519542, lng: 20.8033986 },
    { lat: 49.0517711, lng: 20.8035051 },
    { lat: 49.0516424, lng: 20.8037261 },
    { lat: 49.0510362, lng: 20.8040925 },
    { lat: 49.0499368, lng: 20.805438 },
    { lat: 49.0495551, lng: 20.8055643 },
    { lat: 49.0489689, lng: 20.8058696 },
    { lat: 49.0491245, lng: 20.8050917 },
    { lat: 49.0496562, lng: 20.8037419 },
    { lat: 49.0498136, lng: 20.8030213 },
    { lat: 49.0497938, lng: 20.8028029 },
    { lat: 49.049474, lng: 20.8022436 },
    { lat: 49.0479697, lng: 20.80106 },
    { lat: 49.0476271, lng: 20.8008876 },
    { lat: 49.0468175, lng: 20.8010414 },
    { lat: 49.0468228, lng: 20.8010991 },
    { lat: 49.0461281, lng: 20.8012897 },
    { lat: 49.0456895, lng: 20.8016869 },
    { lat: 49.0449677, lng: 20.8024917 },
    { lat: 49.0443707, lng: 20.8025787 },
    { lat: 49.0440726, lng: 20.8027166 },
    { lat: 49.0440699, lng: 20.8026666 },
    { lat: 49.0439782, lng: 20.8026872 },
    { lat: 49.0428098, lng: 20.8030721 },
    { lat: 49.0423429, lng: 20.8028856 },
    { lat: 49.0416533, lng: 20.8020227 },
    { lat: 49.0412566, lng: 20.8013552 },
    { lat: 49.0408532, lng: 20.8009871 },
    { lat: 49.0403137, lng: 20.800651 },
    { lat: 49.0391438, lng: 20.8003454 },
    { lat: 49.0387336, lng: 20.8000715 },
    { lat: 49.0382167, lng: 20.7995436 },
    { lat: 49.0374234, lng: 20.7974108 },
    { lat: 49.0373027, lng: 20.7974668 },
    { lat: 49.0372374, lng: 20.7973676 },
    { lat: 49.0363795, lng: 20.7972638 },
    { lat: 49.0360092, lng: 20.7978492 },
    { lat: 49.0351057, lng: 20.7973788 },
    { lat: 49.0346996, lng: 20.7968973 },
    { lat: 49.0343204, lng: 20.7965464 },
    { lat: 49.0341692, lng: 20.7965646 },
    { lat: 49.0340127, lng: 20.7964336 },
    { lat: 49.0338976, lng: 20.7961953 },
    { lat: 49.0335954, lng: 20.7959537 },
    { lat: 49.0329696, lng: 20.7950156 },
    { lat: 49.0309469, lng: 20.7926478 },
    { lat: 49.0276411, lng: 20.789377 },
    { lat: 49.0254952, lng: 20.7873908 },
    { lat: 49.0253856, lng: 20.7872174 },
    { lat: 49.0253623, lng: 20.7870615 },
    { lat: 49.0253877, lng: 20.7860096 },
    { lat: 49.0253585, lng: 20.7849101 },
    { lat: 49.0252156, lng: 20.782192 },
    { lat: 49.0250069, lng: 20.7823085 },
    { lat: 49.0238733, lng: 20.7820525 },
    { lat: 49.0237335, lng: 20.7818159 },
    { lat: 49.0233584, lng: 20.7815509 },
    { lat: 49.0229859, lng: 20.7813922 },
    { lat: 49.0223756, lng: 20.7812903 },
    { lat: 49.0219231, lng: 20.7807722 },
    { lat: 49.0217508, lng: 20.7807355 },
    { lat: 49.0214566, lng: 20.7804336 },
    { lat: 49.0208308, lng: 20.7800822 },
    { lat: 49.0206465, lng: 20.7800239 },
    { lat: 49.0202509, lng: 20.7800712 },
    { lat: 49.0201122, lng: 20.7800041 },
    { lat: 49.0198646, lng: 20.7797497 },
    { lat: 49.0195879, lng: 20.7797211 },
    { lat: 49.0193263, lng: 20.7795796 },
    { lat: 49.0190635, lng: 20.7795908 },
    { lat: 49.018979, lng: 20.7796564 },
    { lat: 49.0184922, lng: 20.7796113 },
    { lat: 49.0182263, lng: 20.779798 },
    { lat: 49.0179733, lng: 20.7795792 },
    { lat: 49.0178145, lng: 20.7795773 },
    { lat: 49.0177037, lng: 20.7796608 },
    { lat: 49.0176357, lng: 20.780003 },
    { lat: 49.0175544, lng: 20.7800605 },
    { lat: 49.0166174, lng: 20.7801111 },
    { lat: 49.0160923, lng: 20.780484 },
    { lat: 49.0157924, lng: 20.780596 },
    { lat: 49.015726, lng: 20.7806605 },
    { lat: 49.0151233, lng: 20.7807797 },
    { lat: 49.0147648, lng: 20.7811198 },
    { lat: 49.0145137, lng: 20.7811181 },
    { lat: 49.013569, lng: 20.780459 },
    { lat: 49.0130382, lng: 20.780411 },
    { lat: 49.0127325, lng: 20.780262 },
    { lat: 49.0124787, lng: 20.780248 },
    { lat: 49.0122119, lng: 20.7800493 },
    { lat: 49.0119121, lng: 20.7800774 },
    { lat: 49.0117919, lng: 20.7801742 },
    { lat: 49.011597, lng: 20.7801404 },
    { lat: 49.0114075, lng: 20.7799326 },
    { lat: 49.0112343, lng: 20.7793574 },
    { lat: 49.0109828, lng: 20.779118 },
    { lat: 49.0109148, lng: 20.7791694 },
    { lat: 49.0108791, lng: 20.7794721 },
    { lat: 49.0108274, lng: 20.7794701 },
    { lat: 49.0107389, lng: 20.7794231 },
    { lat: 49.0106476, lng: 20.7792261 },
    { lat: 49.0104943, lng: 20.7790839 },
    { lat: 49.0097843, lng: 20.7789682 },
    { lat: 49.0094352, lng: 20.7788346 },
    { lat: 49.0091928, lng: 20.7786257 },
    { lat: 49.0087246, lng: 20.778369 },
    { lat: 49.007924, lng: 20.7782671 },
    { lat: 49.0078846, lng: 20.7780429 },
    { lat: 49.0078367, lng: 20.7779883 },
    { lat: 49.0075137, lng: 20.7782854 },
    { lat: 49.0071009, lng: 20.7773211 },
    { lat: 49.0070839, lng: 20.7767664 },
    { lat: 49.0069876, lng: 20.7767186 },
    { lat: 49.0066558, lng: 20.7768543 },
    { lat: 49.0066677, lng: 20.7773068 },
    { lat: 49.0066699, lng: 20.7776067 },
    { lat: 49.0062908, lng: 20.7775058 },
    { lat: 49.0062329, lng: 20.7777963 },
    { lat: 49.0066802, lng: 20.7780124 },
    { lat: 49.0066765, lng: 20.7785358 },
    { lat: 49.006169, lng: 20.7782661 },
    { lat: 49.0061048, lng: 20.778392 },
    { lat: 49.0061037, lng: 20.7788768 },
    { lat: 49.0059427, lng: 20.779007 },
    { lat: 49.0058844, lng: 20.7792039 },
    { lat: 49.0061551, lng: 20.78 },
    { lat: 49.0060019, lng: 20.7800901 },
    { lat: 49.0059481, lng: 20.7800643 },
    { lat: 49.0057611, lng: 20.7805114 },
    { lat: 49.0057517, lng: 20.780662 },
    { lat: 49.0059884, lng: 20.7810596 },
    { lat: 49.0066371, lng: 20.7829493 },
    { lat: 49.0072733, lng: 20.7841524 },
    { lat: 49.0074862, lng: 20.7844124 },
    { lat: 49.0074136, lng: 20.7845287 },
    { lat: 49.0075099, lng: 20.7847088 },
    { lat: 49.007459, lng: 20.7847635 },
    { lat: 49.0074128, lng: 20.7852792 },
    { lat: 49.0074932, lng: 20.7858755 },
    { lat: 49.007363, lng: 20.7867317 },
    { lat: 49.0073553, lng: 20.787121 },
    { lat: 49.0073776, lng: 20.7873394 },
    { lat: 49.0076128, lng: 20.7880867 },
    { lat: 49.00764, lng: 20.7884166 },
    { lat: 49.007626, lng: 20.7885278 },
    { lat: 49.0075308, lng: 20.7885792 },
    { lat: 49.0074942, lng: 20.7887212 },
    { lat: 49.0074418, lng: 20.7890926 },
    { lat: 49.0074369, lng: 20.7896315 },
    { lat: 49.00737, lng: 20.7901371 },
    { lat: 49.0073083, lng: 20.7902062 },
    { lat: 49.0073287, lng: 20.7906036 },
    { lat: 49.0072516, lng: 20.791101 },
    { lat: 49.0072543, lng: 20.7914421 },
    { lat: 49.0074681, lng: 20.7925734 },
    { lat: 49.0076354, lng: 20.7930186 },
    { lat: 49.0078084, lng: 20.7942367 },
    { lat: 49.0078574, lng: 20.7950994 },
    { lat: 49.0079919, lng: 20.7953113 },
    { lat: 49.0082739, lng: 20.7955536 },
    { lat: 49.0086548, lng: 20.7967017 },
    { lat: 49.0075701, lng: 20.7975265 },
    { lat: 49.0069221, lng: 20.7982188 },
    { lat: 49.006228, lng: 20.799203 },
    { lat: 49.005834, lng: 20.7999832 },
    { lat: 49.0056717, lng: 20.8003705 },
    { lat: 49.0053715, lng: 20.8014805 },
    { lat: 49.0049711, lng: 20.8033113 },
    { lat: 49.0047236, lng: 20.805004 },
    { lat: 49.0045487, lng: 20.8050893 },
    { lat: 49.0045179, lng: 20.8056995 },
    { lat: 49.0044792, lng: 20.806012 },
    { lat: 49.0060557, lng: 20.8057379 },
    { lat: 49.0074817, lng: 20.8060555 },
    { lat: 49.008151, lng: 20.8065962 },
    { lat: 49.0101794, lng: 20.8086137 },
    { lat: 49.0106655, lng: 20.8076365 },
    { lat: 49.0140416, lng: 20.8123679 },
    { lat: 49.0149795, lng: 20.8136808 },
    { lat: 49.0150342, lng: 20.8135969 },
    { lat: 49.015131, lng: 20.8136006 },
    { lat: 49.0169587, lng: 20.8151915 },
    { lat: 49.0183489, lng: 20.8159083 },
    { lat: 49.0195743, lng: 20.8141992 },
    { lat: 49.0202598, lng: 20.8136123 },
    { lat: 49.0204793, lng: 20.8139717 },
    { lat: 49.0210018, lng: 20.8145358 },
    { lat: 49.0213499, lng: 20.8139521 },
    { lat: 49.0216233, lng: 20.8141096 },
    { lat: 49.0224482, lng: 20.8146926 },
    { lat: 49.0243421, lng: 20.8157889 },
    { lat: 49.0252853, lng: 20.8166017 },
    { lat: 49.0261197, lng: 20.8171657 },
    { lat: 49.0263753, lng: 20.8172665 },
    { lat: 49.0267559, lng: 20.8178233 },
    { lat: 49.0274379, lng: 20.8184092 },
    { lat: 49.0277229, lng: 20.8182868 },
    { lat: 49.0281382, lng: 20.8186461 },
    { lat: 49.0287591, lng: 20.8193473 },
    { lat: 49.029138, lng: 20.8199262 },
    { lat: 49.0297664, lng: 20.8206853 },
    { lat: 49.0302315, lng: 20.8205278 },
    { lat: 49.0305512, lng: 20.8209898 },
    { lat: 49.0307227, lng: 20.8214908 },
    { lat: 49.0308142, lng: 20.8220248 },
    { lat: 49.0318956, lng: 20.8233226 },
    { lat: 49.0321602, lng: 20.82352 },
    { lat: 49.0331195, lng: 20.8236922 },
    { lat: 49.0335909, lng: 20.8240113 },
    { lat: 49.0335551, lng: 20.8247043 },
    { lat: 49.0337156, lng: 20.8249204 },
    { lat: 49.0340316, lng: 20.8251392 },
    { lat: 49.0348627, lng: 20.8250519 },
    { lat: 49.0351537, lng: 20.8254624 },
    { lat: 49.0357624, lng: 20.8257207 },
    { lat: 49.0364622, lng: 20.8258099 },
    { lat: 49.0373884, lng: 20.8257667 },
    { lat: 49.0379478, lng: 20.8255363 },
    { lat: 49.0391012, lng: 20.8253322 },
    { lat: 49.0396693, lng: 20.8257473 },
    { lat: 49.0404424, lng: 20.8259096 },
    { lat: 49.0409846, lng: 20.8259296 },
    { lat: 49.0420138, lng: 20.8258086 },
    { lat: 49.0424878, lng: 20.8258796 },
    { lat: 49.0440339, lng: 20.8253736 },
    { lat: 49.0456224, lng: 20.8253498 },
    { lat: 49.0456606, lng: 20.8254444 },
  ],
  Lúčka: [
    { lat: 49.0578041, lng: 20.7388937 },
    { lat: 49.057813, lng: 20.7386996 },
    { lat: 49.0576711, lng: 20.7374811 },
    { lat: 49.0576851, lng: 20.7362743 },
    { lat: 49.0575365, lng: 20.7356989 },
    { lat: 49.0574979, lng: 20.7353707 },
    { lat: 49.0572077, lng: 20.7357239 },
    { lat: 49.0571221, lng: 20.7350213 },
    { lat: 49.0572668, lng: 20.7338236 },
    { lat: 49.0574606, lng: 20.7332185 },
    { lat: 49.0574466, lng: 20.7324393 },
    { lat: 49.056516, lng: 20.7306276 },
    { lat: 49.0565458, lng: 20.7301966 },
    { lat: 49.0564595, lng: 20.7298538 },
    { lat: 49.056138, lng: 20.7294525 },
    { lat: 49.0556344, lng: 20.7289929 },
    { lat: 49.0552289, lng: 20.7287309 },
    { lat: 49.0545505, lng: 20.7285494 },
    { lat: 49.0545731, lng: 20.7282324 },
    { lat: 49.0536098, lng: 20.7278204 },
    { lat: 49.0531092, lng: 20.7277388 },
    { lat: 49.052769, lng: 20.7277644 },
    { lat: 49.0526968, lng: 20.7276637 },
    { lat: 49.0523224, lng: 20.7275816 },
    { lat: 49.0511533, lng: 20.7270511 },
    { lat: 49.0509906, lng: 20.7268056 },
    { lat: 49.0507537, lng: 20.7267302 },
    { lat: 49.050474, lng: 20.72643 },
    { lat: 49.0505582, lng: 20.725592 },
    { lat: 49.0492529, lng: 20.725475 },
    { lat: 49.0490281, lng: 20.7254201 },
    { lat: 49.0486869, lng: 20.7252233 },
    { lat: 49.0485215, lng: 20.7252286 },
    { lat: 49.0477428, lng: 20.7255218 },
    { lat: 49.0474673, lng: 20.7254362 },
    { lat: 49.0473988, lng: 20.7255664 },
    { lat: 49.0472707, lng: 20.7253877 },
    { lat: 49.0470592, lng: 20.7247206 },
    { lat: 49.0467174, lng: 20.7247614 },
    { lat: 49.046351, lng: 20.7249995 },
    { lat: 49.045973, lng: 20.7253579 },
    { lat: 49.0456811, lng: 20.7244251 },
    { lat: 49.0456952, lng: 20.7225576 },
    { lat: 49.0458172, lng: 20.7223116 },
    { lat: 49.0458019, lng: 20.7221391 },
    { lat: 49.0457181, lng: 20.722024 },
    { lat: 49.0456925, lng: 20.7219992 },
    { lat: 49.0454659, lng: 20.7220557 },
    { lat: 49.0449222, lng: 20.7224704 },
    { lat: 49.0442906, lng: 20.722695 },
    { lat: 49.0421953, lng: 20.7238269 },
    { lat: 49.0414447, lng: 20.7241516 },
    { lat: 49.0412061, lng: 20.7244107 },
    { lat: 49.0407918, lng: 20.7254302 },
    { lat: 49.0405147, lng: 20.7259662 },
    { lat: 49.040083, lng: 20.7273574 },
    { lat: 49.0399301, lng: 20.7276784 },
    { lat: 49.0398202, lng: 20.7277268 },
    { lat: 49.0396465, lng: 20.7276151 },
    { lat: 49.0398637, lng: 20.7271058 },
    { lat: 49.0399158, lng: 20.7267305 },
    { lat: 49.0398937, lng: 20.7264909 },
    { lat: 49.0397161, lng: 20.7262122 },
    { lat: 49.0394713, lng: 20.7264199 },
    { lat: 49.0391726, lng: 20.7264722 },
    { lat: 49.0391438, lng: 20.7261976 },
    { lat: 49.0382664, lng: 20.7260245 },
    { lat: 49.0372511, lng: 20.7253216 },
    { lat: 49.0364876, lng: 20.724549 },
    { lat: 49.0350684, lng: 20.7248449 },
    { lat: 49.0344567, lng: 20.7242889 },
    { lat: 49.0339497, lng: 20.7241125 },
    { lat: 49.0330778, lng: 20.7235917 },
    { lat: 49.0324838, lng: 20.7238243 },
    { lat: 49.0318823, lng: 20.7238777 },
    { lat: 49.0319249, lng: 20.7212213 },
    { lat: 49.0319622, lng: 20.7210679 },
    { lat: 49.0315878, lng: 20.7211735 },
    { lat: 49.0312742, lng: 20.7216052 },
    { lat: 49.0310054, lng: 20.7218618 },
    { lat: 49.0307518, lng: 20.721669 },
    { lat: 49.0284859, lng: 20.7249849 },
    { lat: 49.0283294, lng: 20.7251135 },
    { lat: 49.027905, lng: 20.7252999 },
    { lat: 49.0268961, lng: 20.7253277 },
    { lat: 49.0265427, lng: 20.7253935 },
    { lat: 49.0262393, lng: 20.725632 },
    { lat: 49.0251731, lng: 20.7260723 },
    { lat: 49.0243395, lng: 20.7266288 },
    { lat: 49.0238555, lng: 20.7268569 },
    { lat: 49.0235514, lng: 20.7274915 },
    { lat: 49.0234819, lng: 20.7280689 },
    { lat: 49.0231147, lng: 20.7279033 },
    { lat: 49.0227351, lng: 20.7279278 },
    { lat: 49.0227082, lng: 20.7290186 },
    { lat: 49.0224899, lng: 20.73065 },
    { lat: 49.022215, lng: 20.7317918 },
    { lat: 49.0228167, lng: 20.732293 },
    { lat: 49.0231988, lng: 20.7327196 },
    { lat: 49.0233156, lng: 20.7329956 },
    { lat: 49.0239506, lng: 20.7338997 },
    { lat: 49.0242767, lng: 20.7348474 },
    { lat: 49.0245119, lng: 20.7350345 },
    { lat: 49.0249041, lng: 20.7352752 },
    { lat: 49.0256769, lng: 20.7352894 },
    { lat: 49.0261707, lng: 20.7351828 },
    { lat: 49.0268085, lng: 20.7348472 },
    { lat: 49.0275609, lng: 20.7346048 },
    { lat: 49.0281865, lng: 20.7341826 },
    { lat: 49.0296045, lng: 20.7334689 },
    { lat: 49.0299244, lng: 20.7334446 },
    { lat: 49.0303709, lng: 20.7335912 },
    { lat: 49.0305452, lng: 20.7337176 },
    { lat: 49.0308777, lng: 20.733796 },
    { lat: 49.0312064, lng: 20.7340792 },
    { lat: 49.0317997, lng: 20.7344363 },
    { lat: 49.032155, lng: 20.7347729 },
    { lat: 49.0324101, lng: 20.7352113 },
    { lat: 49.0333105, lng: 20.7362584 },
    { lat: 49.0343439, lng: 20.7377335 },
    { lat: 49.0349515, lng: 20.7383947 },
    { lat: 49.0353336, lng: 20.7384752 },
    { lat: 49.0360711, lng: 20.7388963 },
    { lat: 49.0365388, lng: 20.7390079 },
    { lat: 49.0372319, lng: 20.7393512 },
    { lat: 49.0373336, lng: 20.7397157 },
    { lat: 49.0373955, lng: 20.7397448 },
    { lat: 49.0378318, lng: 20.7397011 },
    { lat: 49.0381108, lng: 20.739762 },
    { lat: 49.0388508, lng: 20.7400983 },
    { lat: 49.0404067, lng: 20.7409631 },
    { lat: 49.0409913, lng: 20.7411352 },
    { lat: 49.0412511, lng: 20.741325 },
    { lat: 49.041639, lng: 20.7419178 },
    { lat: 49.0425122, lng: 20.7424206 },
    { lat: 49.0431548, lng: 20.7426443 },
    { lat: 49.0445404, lng: 20.7428952 },
    { lat: 49.0453026, lng: 20.7431587 },
    { lat: 49.0464635, lng: 20.7437455 },
    { lat: 49.0470441, lng: 20.7439316 },
    { lat: 49.0474028, lng: 20.7441428 },
    { lat: 49.0482653, lng: 20.7443739 },
    { lat: 49.0490406, lng: 20.7449446 },
    { lat: 49.0495908, lng: 20.745608 },
    { lat: 49.0497415, lng: 20.7455742 },
    { lat: 49.0499114, lng: 20.7453802 },
    { lat: 49.0503016, lng: 20.7440498 },
    { lat: 49.0504911, lng: 20.7436756 },
    { lat: 49.0507514, lng: 20.7435266 },
    { lat: 49.0514989, lng: 20.7434663 },
    { lat: 49.0522092, lng: 20.7432718 },
    { lat: 49.0526926, lng: 20.7429648 },
    { lat: 49.053534, lng: 20.7419515 },
    { lat: 49.0555574, lng: 20.7402724 },
    { lat: 49.0561141, lng: 20.7397426 },
    { lat: 49.0568635, lng: 20.7392599 },
    { lat: 49.0578041, lng: 20.7388937 },
  ],
  Levoča: [
    { lat: 49.1078717, lng: 20.5817741 },
    { lat: 49.1075765, lng: 20.5813945 },
    { lat: 49.1070172, lng: 20.5807872 },
    { lat: 49.1065067, lng: 20.5806148 },
    { lat: 49.1063482, lng: 20.5805613 },
    { lat: 49.1060013, lng: 20.5796938 },
    { lat: 49.1058392, lng: 20.5792904 },
    { lat: 49.1055776, lng: 20.5789992 },
    { lat: 49.1053337, lng: 20.578735 },
    { lat: 49.1049191, lng: 20.578571 },
    { lat: 49.1046563, lng: 20.5784675 },
    { lat: 49.1042178, lng: 20.5782951 },
    { lat: 49.1032142, lng: 20.5779752 },
    { lat: 49.1031595, lng: 20.5778918 },
    { lat: 49.1031412, lng: 20.577857 },
    { lat: 49.1031292, lng: 20.5778315 },
    { lat: 49.1022289, lng: 20.5763631 },
    { lat: 49.1022754, lng: 20.5733197 },
    { lat: 49.1011834, lng: 20.5720304 },
    { lat: 49.1001206, lng: 20.5707003 },
    { lat: 49.0991664, lng: 20.5691455 },
    { lat: 49.0983884, lng: 20.5678507 },
    { lat: 49.0977908, lng: 20.5666253 },
    { lat: 49.0974094, lng: 20.5658598 },
    { lat: 49.0965916, lng: 20.5641869 },
    { lat: 49.0959743, lng: 20.5629446 },
    { lat: 49.0957561, lng: 20.562495 },
    { lat: 49.0957477, lng: 20.5624789 },
    { lat: 49.0955162, lng: 20.5619996 },
    { lat: 49.0952411, lng: 20.5614316 },
    { lat: 49.0949287, lng: 20.5608065 },
    { lat: 49.0945242, lng: 20.5599978 },
    { lat: 49.0945085, lng: 20.5599675 },
    { lat: 49.0939828, lng: 20.5589539 },
    { lat: 49.0939431, lng: 20.5588815 },
    { lat: 49.0937569, lng: 20.5585331 },
    { lat: 49.0934237, lng: 20.5565742 },
    { lat: 49.093362, lng: 20.5562126 },
    { lat: 49.0932804, lng: 20.5557371 },
    { lat: 49.0932513, lng: 20.5555862 },
    { lat: 49.0932236, lng: 20.5554154 },
    { lat: 49.0931791, lng: 20.5551559 },
    { lat: 49.0931452, lng: 20.5549648 },
    { lat: 49.0931209, lng: 20.5548243 },
    { lat: 49.0930823, lng: 20.5545995 },
    { lat: 49.0930469, lng: 20.554402 },
    { lat: 49.0929994, lng: 20.5541543 },
    { lat: 49.0929797, lng: 20.5541074 },
    { lat: 49.0929513, lng: 20.5540402 },
    { lat: 49.0928865, lng: 20.5539002 },
    { lat: 49.0928362, lng: 20.5537845 },
    { lat: 49.0927826, lng: 20.5536727 },
    { lat: 49.0927323, lng: 20.5535609 },
    { lat: 49.0926907, lng: 20.5534689 },
    { lat: 49.0926598, lng: 20.5534001 },
    { lat: 49.0926086, lng: 20.5532888 },
    { lat: 49.0925464, lng: 20.5531561 },
    { lat: 49.0924851, lng: 20.5530237 },
    { lat: 49.0924289, lng: 20.552901 },
    { lat: 49.0923849, lng: 20.5528063 },
    { lat: 49.0923373, lng: 20.5527048 },
    { lat: 49.0922833, lng: 20.552617 },
    { lat: 49.0922405, lng: 20.552555 },
    { lat: 49.0922203, lng: 20.5525261 },
    { lat: 49.0922095, lng: 20.5525084 },
    { lat: 49.092175, lng: 20.5524553 },
    { lat: 49.0921523, lng: 20.5524202 },
    { lat: 49.0921249, lng: 20.5523827 },
    { lat: 49.0920716, lng: 20.5523107 },
    { lat: 49.0919967, lng: 20.5522 },
    { lat: 49.0919254, lng: 20.5521022 },
    { lat: 49.0918582, lng: 20.5520182 },
    { lat: 49.0917911, lng: 20.5519387 },
    { lat: 49.0916984, lng: 20.5518367 },
    { lat: 49.0915992, lng: 20.551802 },
    { lat: 49.0915161, lng: 20.5517736 },
    { lat: 49.0915013, lng: 20.5517687 },
    { lat: 49.0914761, lng: 20.5517576 },
    { lat: 49.0914085, lng: 20.5517278 },
    { lat: 49.0913981, lng: 20.5517215 },
    { lat: 49.0913142, lng: 20.5516715 },
    { lat: 49.0912262, lng: 20.5516086 },
    { lat: 49.0910737, lng: 20.5515932 },
    { lat: 49.090973, lng: 20.5515152 },
    { lat: 49.0909545, lng: 20.5515006 },
    { lat: 49.0908605, lng: 20.5514274 },
    { lat: 49.0908593, lng: 20.5514272 },
    { lat: 49.0907834, lng: 20.5512623 },
    { lat: 49.0906083, lng: 20.5509381 },
    { lat: 49.0905903, lng: 20.5509052 },
    { lat: 49.0905233, lng: 20.550649 },
    { lat: 49.0904761, lng: 20.5502823 },
    { lat: 49.0904399, lng: 20.5499952 },
    { lat: 49.0903832, lng: 20.5496789 },
    { lat: 49.0903426, lng: 20.5493691 },
    { lat: 49.090341, lng: 20.5493578 },
    { lat: 49.0902872, lng: 20.5490326 },
    { lat: 49.0902287, lng: 20.5486913 },
    { lat: 49.0902127, lng: 20.5486532 },
    { lat: 49.0901462, lng: 20.5485015 },
    { lat: 49.0900492, lng: 20.5483122 },
    { lat: 49.0899726, lng: 20.5482185 },
    { lat: 49.0899432, lng: 20.5481822 },
    { lat: 49.0898148, lng: 20.5480922 },
    { lat: 49.0896947, lng: 20.5479495 },
    { lat: 49.08952, lng: 20.5477926 },
    { lat: 49.0893431, lng: 20.5476651 },
    { lat: 49.0892405, lng: 20.5474799 },
    { lat: 49.0891973, lng: 20.5474006 },
    { lat: 49.0891218, lng: 20.5473363 },
    { lat: 49.0891029, lng: 20.5473185 },
    { lat: 49.0888957, lng: 20.5471296 },
    { lat: 49.0887526, lng: 20.5469148 },
    { lat: 49.0884478, lng: 20.546522 },
    { lat: 49.0882759, lng: 20.5463186 },
    { lat: 49.0882188, lng: 20.5462692 },
    { lat: 49.0880132, lng: 20.5460907 },
    { lat: 49.0876661, lng: 20.5457826 },
    { lat: 49.0874507, lng: 20.5457033 },
    { lat: 49.0872995, lng: 20.5455779 },
    { lat: 49.0872715, lng: 20.5453917 },
    { lat: 49.0870823, lng: 20.5453938 },
    { lat: 49.086842, lng: 20.5453177 },
    { lat: 49.0867822, lng: 20.5452826 },
    { lat: 49.0867061, lng: 20.5452356 },
    { lat: 49.0864576, lng: 20.5452129 },
    { lat: 49.0864532, lng: 20.5452165 },
    { lat: 49.0862944, lng: 20.5453375 },
    { lat: 49.0861324, lng: 20.5454601 },
    { lat: 49.0858793, lng: 20.545435 },
    { lat: 49.0857388, lng: 20.5455673 },
    { lat: 49.0857345, lng: 20.5455709 },
    { lat: 49.0855942, lng: 20.5457086 },
    { lat: 49.0855725, lng: 20.5457288 },
    { lat: 49.08534, lng: 20.5458341 },
    { lat: 49.085313, lng: 20.5458698 },
    { lat: 49.0851854, lng: 20.5460362 },
    { lat: 49.0851665, lng: 20.5460478 },
    { lat: 49.084925, lng: 20.546211 },
    { lat: 49.0846552, lng: 20.5464366 },
    { lat: 49.0845717, lng: 20.5463094 },
    { lat: 49.0843897, lng: 20.5464409 },
    { lat: 49.0842874, lng: 20.5464762 },
    { lat: 49.0841795, lng: 20.5465127 },
    { lat: 49.0840817, lng: 20.5465458 },
    { lat: 49.0840227, lng: 20.5465659 },
    { lat: 49.0839851, lng: 20.5465569 },
    { lat: 49.0839542, lng: 20.546543 },
    { lat: 49.083585, lng: 20.5466331 },
    { lat: 49.0835228, lng: 20.5466869 },
    { lat: 49.083401, lng: 20.5468984 },
    { lat: 49.0832648, lng: 20.5468949 },
    { lat: 49.0831481, lng: 20.5468125 },
    { lat: 49.0831374, lng: 20.5469524 },
    { lat: 49.0832633, lng: 20.5471629 },
    { lat: 49.0832563, lng: 20.5473108 },
    { lat: 49.08331, lng: 20.5474258 },
    { lat: 49.0833236, lng: 20.5474555 },
    { lat: 49.0835015, lng: 20.5477952 },
    { lat: 49.0835069, lng: 20.5481546 },
    { lat: 49.083598, lng: 20.5484884 },
    { lat: 49.0837087, lng: 20.5488371 },
    { lat: 49.0837774, lng: 20.5490489 },
    { lat: 49.0837572, lng: 20.5493272 },
    { lat: 49.0836777, lng: 20.5495544 },
    { lat: 49.0836198, lng: 20.5499103 },
    { lat: 49.0835776, lng: 20.5501671 },
    { lat: 49.083445, lng: 20.5506022 },
    { lat: 49.0833988, lng: 20.5511233 },
    { lat: 49.0833477, lng: 20.5512031 },
    { lat: 49.0831219, lng: 20.5514029 },
    { lat: 49.0830434, lng: 20.5514736 },
    { lat: 49.0829225, lng: 20.5515815 },
    { lat: 49.0827755, lng: 20.5517137 },
    { lat: 49.0826412, lng: 20.5519237 },
    { lat: 49.0825582, lng: 20.5520536 },
    { lat: 49.0824802, lng: 20.5521407 },
    { lat: 49.0823721, lng: 20.5522605 },
    { lat: 49.0822788, lng: 20.5523216 },
    { lat: 49.0821401, lng: 20.5525026 },
    { lat: 49.0820513, lng: 20.5526926 },
    { lat: 49.0820172, lng: 20.5531706 },
    { lat: 49.0819415, lng: 20.5536845 },
    { lat: 49.0818586, lng: 20.5538173 },
    { lat: 49.0818256, lng: 20.5538697 },
    { lat: 49.0816926, lng: 20.5540825 },
    { lat: 49.081433, lng: 20.5543689 },
    { lat: 49.0814189, lng: 20.5543837 },
    { lat: 49.0812262, lng: 20.5541336 },
    { lat: 49.0809006, lng: 20.5536395 },
    { lat: 49.080701, lng: 20.5533232 },
    { lat: 49.0803011, lng: 20.5526092 },
    { lat: 49.0801767, lng: 20.552409 },
    { lat: 49.0799732, lng: 20.5521415 },
    { lat: 49.0796366, lng: 20.5518656 },
    { lat: 49.0792946, lng: 20.5515999 },
    { lat: 49.0779745, lng: 20.5506321 },
    { lat: 49.0777303, lng: 20.5505412 },
    { lat: 49.0776743, lng: 20.5505205 },
    { lat: 49.077442, lng: 20.5504767 },
    { lat: 49.0771047, lng: 20.5504918 },
    { lat: 49.0765371, lng: 20.5505735 },
    { lat: 49.0762774, lng: 20.5505891 },
    { lat: 49.0761459, lng: 20.5505935 },
    { lat: 49.0758966, lng: 20.550548 },
    { lat: 49.075592, lng: 20.5504701 },
    { lat: 49.0752597, lng: 20.5503713 },
    { lat: 49.0743903, lng: 20.5500934 },
    { lat: 49.0732984, lng: 20.5497246 },
    { lat: 49.073012, lng: 20.5495005 },
    { lat: 49.0727477, lng: 20.5492929 },
    { lat: 49.0725933, lng: 20.5491678 },
    { lat: 49.0725359, lng: 20.5491095 },
    { lat: 49.0724996, lng: 20.5490641 },
    { lat: 49.0724617, lng: 20.5490145 },
    { lat: 49.0724616, lng: 20.5490135 },
    { lat: 49.072362, lng: 20.5488769 },
    { lat: 49.0722471, lng: 20.5487229 },
    { lat: 49.0721875, lng: 20.5486364 },
    { lat: 49.0721237, lng: 20.5485492 },
    { lat: 49.072069, lng: 20.548472 },
    { lat: 49.0719968, lng: 20.5483758 },
    { lat: 49.0719087, lng: 20.5482771 },
    { lat: 49.0717061, lng: 20.5480595 },
    { lat: 49.0715556, lng: 20.5478881 },
    { lat: 49.0710505, lng: 20.5472855 },
    { lat: 49.0706947, lng: 20.5468613 },
    { lat: 49.0706203, lng: 20.5467617 },
    { lat: 49.0705014, lng: 20.5465894 },
    { lat: 49.0704631, lng: 20.5465277 },
    { lat: 49.0703444, lng: 20.5461181 },
    { lat: 49.0703043, lng: 20.5459757 },
    { lat: 49.0702937, lng: 20.5459059 },
    { lat: 49.070268, lng: 20.5456636 },
    { lat: 49.0702663, lng: 20.545649 },
    { lat: 49.070256, lng: 20.5455558 },
    { lat: 49.070249, lng: 20.5454853 },
    { lat: 49.0702439, lng: 20.545438 },
    { lat: 49.0702342, lng: 20.5453598 },
    { lat: 49.0702298, lng: 20.5452072 },
    { lat: 49.0702219, lng: 20.5449626 },
    { lat: 49.0702119, lng: 20.5445678 },
    { lat: 49.0701964, lng: 20.5442363 },
    { lat: 49.0701765, lng: 20.5438179 },
    { lat: 49.0701705, lng: 20.5436859 },
    { lat: 49.0700176, lng: 20.5434498 },
    { lat: 49.0698966, lng: 20.5432188 },
    { lat: 49.0698541, lng: 20.5431359 },
    { lat: 49.069807, lng: 20.5430465 },
    { lat: 49.0697559, lng: 20.5429464 },
    { lat: 49.0696321, lng: 20.542706 },
    { lat: 49.0695362, lng: 20.5425174 },
    { lat: 49.0694671, lng: 20.5423826 },
    { lat: 49.069409, lng: 20.5422723 },
    { lat: 49.0693619, lng: 20.5421851 },
    { lat: 49.069316, lng: 20.5420974 },
    { lat: 49.0692034, lng: 20.5418848 },
    { lat: 49.0691841, lng: 20.5418487 },
    { lat: 49.0691392, lng: 20.541761 },
    { lat: 49.0690954, lng: 20.5416746 },
    { lat: 49.0690555, lng: 20.5415969 },
    { lat: 49.0689938, lng: 20.5414786 },
    { lat: 49.0689187, lng: 20.5413357 },
    { lat: 49.0688932, lng: 20.541289 },
    { lat: 49.0688296, lng: 20.541177 },
    { lat: 49.0687488, lng: 20.5410335 },
    { lat: 49.0684241, lng: 20.5419649 },
    { lat: 49.0683693, lng: 20.5421308 },
    { lat: 49.0682681, lng: 20.5423495 },
    { lat: 49.0680309, lng: 20.5428781 },
    { lat: 49.0679402, lng: 20.5431059 },
    { lat: 49.0678473, lng: 20.5433365 },
    { lat: 49.0677518, lng: 20.5435809 },
    { lat: 49.0675991, lng: 20.5439648 },
    { lat: 49.0675694, lng: 20.5440416 },
    { lat: 49.0675025, lng: 20.5442434 },
    { lat: 49.0674105, lng: 20.5445252 },
    { lat: 49.0674046, lng: 20.5445519 },
    { lat: 49.0673761, lng: 20.5447848 },
    { lat: 49.0673514, lng: 20.5450357 },
    { lat: 49.0672738, lng: 20.5459845 },
    { lat: 49.0672417, lng: 20.546273 },
    { lat: 49.0668952, lng: 20.547473 },
    { lat: 49.0668548, lng: 20.5475992 },
    { lat: 49.0667175, lng: 20.5477212 },
    { lat: 49.0664945, lng: 20.5473549 },
    { lat: 49.0663074, lng: 20.5472574 },
    { lat: 49.0659127, lng: 20.5472713 },
    { lat: 49.0653771, lng: 20.5471862 },
    { lat: 49.0651381, lng: 20.5471477 },
    { lat: 49.065239, lng: 20.546839 },
    { lat: 49.065258, lng: 20.546774 },
    { lat: 49.06535, lng: 20.546464 },
    { lat: 49.065066, lng: 20.546186 },
    { lat: 49.065064, lng: 20.546185 },
    { lat: 49.064858, lng: 20.546107 },
    { lat: 49.064641, lng: 20.545933 },
    { lat: 49.064953, lng: 20.545675 },
    { lat: 49.065599, lng: 20.545392 },
    { lat: 49.0656, lng: 20.545392 },
    { lat: 49.065652, lng: 20.54536 },
    { lat: 49.065755, lng: 20.545299 },
    { lat: 49.065756, lng: 20.545298 },
    { lat: 49.065997, lng: 20.545025 },
    { lat: 49.065974, lng: 20.544742 },
    { lat: 49.065964, lng: 20.544618 },
    { lat: 49.066138, lng: 20.544271 },
    { lat: 49.066138, lng: 20.544269 },
    { lat: 49.066237, lng: 20.544035 },
    { lat: 49.066404, lng: 20.543279 },
    { lat: 49.066492, lng: 20.543058 },
    { lat: 49.066574, lng: 20.542834 },
    { lat: 49.066671, lng: 20.542628 },
    { lat: 49.066761, lng: 20.542495 },
    { lat: 49.066897, lng: 20.542265 },
    { lat: 49.066975, lng: 20.542051 },
    { lat: 49.066693, lng: 20.541687 },
    { lat: 49.066686, lng: 20.541678 },
    { lat: 49.066694, lng: 20.541493 },
    { lat: 49.066694, lng: 20.541484 },
    { lat: 49.066617, lng: 20.541293 },
    { lat: 49.066701, lng: 20.540932 },
    { lat: 49.066786, lng: 20.540703 },
    { lat: 49.066881, lng: 20.540449 },
    { lat: 49.066404, lng: 20.540494 },
    { lat: 49.066404, lng: 20.540493 },
    { lat: 49.066049, lng: 20.540391 },
    { lat: 49.065341, lng: 20.540179 },
    { lat: 49.064833, lng: 20.540026 },
    { lat: 49.064342, lng: 20.539855 },
    { lat: 49.06392, lng: 20.539703 },
    { lat: 49.063526, lng: 20.539558 },
    { lat: 49.063116, lng: 20.539428 },
    { lat: 49.062595, lng: 20.539256 },
    { lat: 49.062322, lng: 20.539168 },
    { lat: 49.062023, lng: 20.53907 },
    { lat: 49.061892, lng: 20.539186 },
    { lat: 49.061774, lng: 20.539289 },
    { lat: 49.061167, lng: 20.539142 },
    { lat: 49.061166, lng: 20.539142 },
    { lat: 49.060534, lng: 20.538724 },
    { lat: 49.060475, lng: 20.538685 },
    { lat: 49.060053, lng: 20.538543 },
    { lat: 49.059755, lng: 20.538443 },
    { lat: 49.059746, lng: 20.53844 },
    { lat: 49.0595, lng: 20.53838 },
    { lat: 49.059099, lng: 20.538279 },
    { lat: 49.059086, lng: 20.538515 },
    { lat: 49.059172, lng: 20.538798 },
    { lat: 49.059315, lng: 20.539021 },
    { lat: 49.059403, lng: 20.539181 },
    { lat: 49.059623, lng: 20.539664 },
    { lat: 49.059603, lng: 20.540078 },
    { lat: 49.059684, lng: 20.540897 },
    { lat: 49.059524, lng: 20.541452 },
    { lat: 49.058982, lng: 20.541495 },
    { lat: 49.058647, lng: 20.541526 },
    { lat: 49.058551, lng: 20.541735 },
    { lat: 49.058635, lng: 20.541871 },
    { lat: 49.058831, lng: 20.542579 },
    { lat: 49.058928, lng: 20.542682 },
    { lat: 49.058915, lng: 20.542938 },
    { lat: 49.058707, lng: 20.543289 },
    { lat: 49.058741, lng: 20.543968 },
    { lat: 49.058635, lng: 20.544006 },
    { lat: 49.058521, lng: 20.544216 },
    { lat: 49.058487, lng: 20.544423 },
    { lat: 49.058485, lng: 20.544627 },
    { lat: 49.058268, lng: 20.54473 },
    { lat: 49.058137, lng: 20.544949 },
    { lat: 49.058123, lng: 20.545203 },
    { lat: 49.058062, lng: 20.545531 },
    { lat: 49.057973, lng: 20.546061 },
    { lat: 49.057967, lng: 20.546435 },
    { lat: 49.058438, lng: 20.546552 },
    { lat: 49.058999, lng: 20.546686 },
    { lat: 49.058879, lng: 20.547116 },
    { lat: 49.058865, lng: 20.54718 },
    { lat: 49.058809, lng: 20.547427 },
    { lat: 49.058133, lng: 20.547507 },
    { lat: 49.057802, lng: 20.547745 },
    { lat: 49.057436, lng: 20.547791 },
    { lat: 49.05734, lng: 20.547806 },
    { lat: 49.057163, lng: 20.548002 },
    { lat: 49.056925, lng: 20.548522 },
    { lat: 49.056789, lng: 20.548372 },
    { lat: 49.056831, lng: 20.548125 },
    { lat: 49.056744, lng: 20.548017 },
    { lat: 49.05657, lng: 20.547983 },
    { lat: 49.056474, lng: 20.548081 },
    { lat: 49.056031, lng: 20.547636 },
    { lat: 49.055832, lng: 20.547616 },
    { lat: 49.055494, lng: 20.548023 },
    { lat: 49.055366, lng: 20.547953 },
    { lat: 49.055523, lng: 20.547364 },
    { lat: 49.055482, lng: 20.546712 },
    { lat: 49.055292, lng: 20.546305 },
    { lat: 49.055144, lng: 20.545713 },
    { lat: 49.05508, lng: 20.545547 },
    { lat: 49.054875, lng: 20.545431 },
    { lat: 49.054785, lng: 20.545408 },
    { lat: 49.05455, lng: 20.544926 },
    { lat: 49.054574, lng: 20.544727 },
    { lat: 49.054756, lng: 20.544008 },
    { lat: 49.05487, lng: 20.543576 },
    { lat: 49.054917, lng: 20.542894 },
    { lat: 49.054719, lng: 20.542293 },
    { lat: 49.054686, lng: 20.542025 },
    { lat: 49.054405, lng: 20.541578 },
    { lat: 49.054263, lng: 20.541284 },
    { lat: 49.054112, lng: 20.540976 },
    { lat: 49.054157, lng: 20.540889 },
    { lat: 49.053936, lng: 20.540743 },
    { lat: 49.05373, lng: 20.540547 },
    { lat: 49.053535, lng: 20.540222 },
    { lat: 49.053331, lng: 20.539926 },
    { lat: 49.053181, lng: 20.53961 },
    { lat: 49.053121, lng: 20.53936 },
    { lat: 49.053057, lng: 20.539131 },
    { lat: 49.052953, lng: 20.538971 },
    { lat: 49.052926, lng: 20.538877 },
    { lat: 49.052896, lng: 20.538782 },
    { lat: 49.052802, lng: 20.538729 },
    { lat: 49.052769, lng: 20.538707 },
    { lat: 49.052729, lng: 20.538677 },
    { lat: 49.0526, lng: 20.538685 },
    { lat: 49.052575, lng: 20.538687 },
    { lat: 49.052476, lng: 20.53839 },
    { lat: 49.052395, lng: 20.538349 },
    { lat: 49.052376, lng: 20.538475 },
    { lat: 49.05233, lng: 20.538534 },
    { lat: 49.052266, lng: 20.538462 },
    { lat: 49.052199, lng: 20.538655 },
    { lat: 49.052151, lng: 20.538654 },
    { lat: 49.05212, lng: 20.538243 },
    { lat: 49.051983, lng: 20.538197 },
    { lat: 49.051931, lng: 20.53819 },
    { lat: 49.051864, lng: 20.538199 },
    { lat: 49.051639, lng: 20.538131 },
    { lat: 49.051695, lng: 20.537959 },
    { lat: 49.051695, lng: 20.537861 },
    { lat: 49.051654, lng: 20.537603 },
    { lat: 49.051607, lng: 20.537056 },
    { lat: 49.051546, lng: 20.536727 },
    { lat: 49.05158, lng: 20.536145 },
    { lat: 49.051581, lng: 20.535639 },
    { lat: 49.051662, lng: 20.535332 },
    { lat: 49.051851, lng: 20.535471 },
    { lat: 49.051745, lng: 20.534864 },
    { lat: 49.05178, lng: 20.534675 },
    { lat: 49.051788, lng: 20.534478 },
    { lat: 49.051732, lng: 20.534023 },
    { lat: 49.051659, lng: 20.533795 },
    { lat: 49.051628, lng: 20.533658 },
    { lat: 49.051514, lng: 20.533457 },
    { lat: 49.051284, lng: 20.532998 },
    { lat: 49.051231, lng: 20.532983 },
    { lat: 49.051048, lng: 20.533244 },
    { lat: 49.051057, lng: 20.533299 },
    { lat: 49.051081, lng: 20.533485 },
    { lat: 49.051019, lng: 20.533534 },
    { lat: 49.051146, lng: 20.53389 },
    { lat: 49.051223, lng: 20.534387 },
    { lat: 49.051321, lng: 20.534642 },
    { lat: 49.0513, lng: 20.534799 },
    { lat: 49.051231, lng: 20.534905 },
    { lat: 49.051124, lng: 20.534691 },
    { lat: 49.051083, lng: 20.534704 },
    { lat: 49.05104, lng: 20.534749 },
    { lat: 49.050994, lng: 20.534891 },
    { lat: 49.050956, lng: 20.535148 },
    { lat: 49.050901, lng: 20.534931 },
    { lat: 49.050897, lng: 20.534691 },
    { lat: 49.050749, lng: 20.534152 },
    { lat: 49.050639, lng: 20.533934 },
    { lat: 49.050567, lng: 20.533713 },
    { lat: 49.050554, lng: 20.533517 },
    { lat: 49.050503, lng: 20.533384 },
    { lat: 49.050434, lng: 20.533108 },
    { lat: 49.050203, lng: 20.53305 },
    { lat: 49.050169, lng: 20.533042 },
    { lat: 49.050058, lng: 20.5329 },
    { lat: 49.049926, lng: 20.532606 },
    { lat: 49.049713, lng: 20.532062 },
    { lat: 49.049675, lng: 20.531798 },
    { lat: 49.049646, lng: 20.531517 },
    { lat: 49.04962, lng: 20.531207 },
    { lat: 49.049556, lng: 20.531176 },
    { lat: 49.049435, lng: 20.531119 },
    { lat: 49.049356, lng: 20.531079 },
    { lat: 49.049336, lng: 20.531069 },
    { lat: 49.049324, lng: 20.530862 },
    { lat: 49.049219, lng: 20.530356 },
    { lat: 49.04914, lng: 20.53008 },
    { lat: 49.049178, lng: 20.529832 },
    { lat: 49.049301, lng: 20.529551 },
    { lat: 49.049328, lng: 20.529384 },
    { lat: 49.049387, lng: 20.529171 },
    { lat: 49.049427, lng: 20.52905 },
    { lat: 49.049514, lng: 20.52887 },
    { lat: 49.049424, lng: 20.528347 },
    { lat: 49.04931, lng: 20.528283 },
    { lat: 49.049188, lng: 20.528206 },
    { lat: 49.049081, lng: 20.528074 },
    { lat: 49.04912, lng: 20.527906 },
    { lat: 49.048955, lng: 20.527737 },
    { lat: 49.048895, lng: 20.527587 },
    { lat: 49.048877, lng: 20.527363 },
    { lat: 49.048949, lng: 20.527185 },
    { lat: 49.048845, lng: 20.527004 },
    { lat: 49.04874, lng: 20.526831 },
    { lat: 49.048511, lng: 20.526582 },
    { lat: 49.048244, lng: 20.526096 },
    { lat: 49.048142, lng: 20.525911 },
    { lat: 49.048064, lng: 20.52578 },
    { lat: 49.048021, lng: 20.525765 },
    { lat: 49.047943, lng: 20.52572 },
    { lat: 49.047884, lng: 20.525582 },
    { lat: 49.047801, lng: 20.525372 },
    { lat: 49.047789, lng: 20.525164 },
    { lat: 49.047721, lng: 20.525171 },
    { lat: 49.047609, lng: 20.525095 },
    { lat: 49.047626, lng: 20.524917 },
    { lat: 49.047686, lng: 20.524856 },
    { lat: 49.047676, lng: 20.524734 },
    { lat: 49.047598, lng: 20.524452 },
    { lat: 49.04756, lng: 20.524186 },
    { lat: 49.047492, lng: 20.523616 },
    { lat: 49.047467, lng: 20.523446 },
    { lat: 49.047432, lng: 20.523198 },
    { lat: 49.047383, lng: 20.52294 },
    { lat: 49.047436, lng: 20.522312 },
    { lat: 49.047378, lng: 20.521894 },
    { lat: 49.04739, lng: 20.521668 },
    { lat: 49.047325, lng: 20.521428 },
    { lat: 49.047465, lng: 20.52145 },
    { lat: 49.047683, lng: 20.521369 },
    { lat: 49.047846, lng: 20.521308 },
    { lat: 49.047975, lng: 20.520917 },
    { lat: 49.048101, lng: 20.520125 },
    { lat: 49.048069, lng: 20.519741 },
    { lat: 49.048028, lng: 20.519335 },
    { lat: 49.047984, lng: 20.518944 },
    { lat: 49.047967, lng: 20.518874 },
    { lat: 49.047873, lng: 20.518619 },
    { lat: 49.047764, lng: 20.518325 },
    { lat: 49.047675, lng: 20.518079 },
    { lat: 49.047663, lng: 20.51803 },
    { lat: 49.047544, lng: 20.517894 },
    { lat: 49.047454, lng: 20.51767 },
    { lat: 49.047261, lng: 20.517206 },
    { lat: 49.047091, lng: 20.516788 },
    { lat: 49.046913, lng: 20.516358 },
    { lat: 49.046846, lng: 20.516178 },
    { lat: 49.046705, lng: 20.515775 },
    { lat: 49.046618, lng: 20.515564 },
    { lat: 49.046249, lng: 20.514691 },
    { lat: 49.046108, lng: 20.514351 },
    { lat: 49.045915, lng: 20.513879 },
    { lat: 49.045574, lng: 20.513057 },
    { lat: 49.045385, lng: 20.512619 },
    { lat: 49.045177, lng: 20.512133 },
    { lat: 49.0450305, lng: 20.5123487 },
    { lat: 49.0448922, lng: 20.5124428 },
    { lat: 49.0446731, lng: 20.5127882 },
    { lat: 49.0445494, lng: 20.5120617 },
    { lat: 49.0442171, lng: 20.5122725 },
    { lat: 49.0439906, lng: 20.5126283 },
    { lat: 49.0437237, lng: 20.513295 },
    { lat: 49.0428622, lng: 20.5123185 },
    { lat: 49.0423448, lng: 20.511905 },
    { lat: 49.0400019, lng: 20.5190127 },
    { lat: 49.0406142, lng: 20.5185817 },
    { lat: 49.0406636, lng: 20.5188828 },
    { lat: 49.0409554, lng: 20.5186482 },
    { lat: 49.0417472, lng: 20.5183492 },
    { lat: 49.0418232, lng: 20.5185153 },
    { lat: 49.0408748, lng: 20.5190509 },
    { lat: 49.04062, lng: 20.5192863 },
    { lat: 49.04045, lng: 20.5195876 },
    { lat: 49.0399951, lng: 20.520134 },
    { lat: 49.0395803, lng: 20.520274 },
    { lat: 49.0394079, lng: 20.5199066 },
    { lat: 49.0390185, lng: 20.5199974 },
    { lat: 49.0383793, lng: 20.5198889 },
    { lat: 49.0374479, lng: 20.519696 },
    { lat: 49.0367089, lng: 20.5194396 },
    { lat: 49.0364555, lng: 20.5194721 },
    { lat: 49.0362886, lng: 20.5195822 },
    { lat: 49.0359273, lng: 20.5196186 },
    { lat: 49.0358349, lng: 20.5198246 },
    { lat: 49.0351107, lng: 20.5197323 },
    { lat: 49.0350938, lng: 20.5199125 },
    { lat: 49.0352968, lng: 20.5199536 },
    { lat: 49.0352922, lng: 20.5200064 },
    { lat: 49.0347542, lng: 20.5200126 },
    { lat: 49.0338415, lng: 20.5199 },
    { lat: 49.0338399, lng: 20.5200099 },
    { lat: 49.033296, lng: 20.5201268 },
    { lat: 49.0333203, lng: 20.5204513 },
    { lat: 49.0335299, lng: 20.5204878 },
    { lat: 49.0338224, lng: 20.520391 },
    { lat: 49.0344089, lng: 20.5203888 },
    { lat: 49.0344073, lng: 20.5204363 },
    { lat: 49.0337847, lng: 20.5205252 },
    { lat: 49.0325587, lng: 20.5210127 },
    { lat: 49.032478, lng: 20.5211137 },
    { lat: 49.0318683, lng: 20.5214615 },
    { lat: 49.0319428, lng: 20.521988 },
    { lat: 49.0311618, lng: 20.5218468 },
    { lat: 49.0311884, lng: 20.5220745 },
    { lat: 49.0313962, lng: 20.5221776 },
    { lat: 49.0318032, lng: 20.5223795 },
    { lat: 49.0320883, lng: 20.5226296 },
    { lat: 49.032229, lng: 20.5229333 },
    { lat: 49.0326072, lng: 20.5230853 },
    { lat: 49.0328706, lng: 20.5229289 },
    { lat: 49.0327957, lng: 20.5227606 },
    { lat: 49.0328197, lng: 20.522741 },
    { lat: 49.0331198, lng: 20.5228215 },
    { lat: 49.0332487, lng: 20.522743 },
    { lat: 49.0332245, lng: 20.5225463 },
    { lat: 49.0334484, lng: 20.5223577 },
    { lat: 49.0339422, lng: 20.5222222 },
    { lat: 49.0347871, lng: 20.5229385 },
    { lat: 49.035018, lng: 20.5229736 },
    { lat: 49.0352063, lng: 20.523223 },
    { lat: 49.0359993, lng: 20.5238135 },
    { lat: 49.0368983, lng: 20.5241589 },
    { lat: 49.0371386, lng: 20.5244178 },
    { lat: 49.0372477, lng: 20.5246252 },
    { lat: 49.0370305, lng: 20.5246908 },
    { lat: 49.0364505, lng: 20.5246892 },
    { lat: 49.0363552, lng: 20.5247917 },
    { lat: 49.0363301, lng: 20.5250823 },
    { lat: 49.0363982, lng: 20.5253079 },
    { lat: 49.0365319, lng: 20.5253324 },
    { lat: 49.0365613, lng: 20.5257633 },
    { lat: 49.0364806, lng: 20.5257756 },
    { lat: 49.0354631, lng: 20.5259312 },
    { lat: 49.0353482, lng: 20.5260516 },
    { lat: 49.0346177, lng: 20.5263613 },
    { lat: 49.0342888, lng: 20.5264192 },
    { lat: 49.0342329, lng: 20.5262468 },
    { lat: 49.0338478, lng: 20.5263662 },
    { lat: 49.0336868, lng: 20.525789 },
    { lat: 49.0328718, lng: 20.5247913 },
    { lat: 49.0316651, lng: 20.5245172 },
    { lat: 49.0308972, lng: 20.5249457 },
    { lat: 49.0302951, lng: 20.5251378 },
    { lat: 49.0300263, lng: 20.5253269 },
    { lat: 49.0301158, lng: 20.525763 },
    { lat: 49.03022, lng: 20.5262705 },
    { lat: 49.0298929, lng: 20.526376 },
    { lat: 49.0297144, lng: 20.526416 },
    { lat: 49.0290734, lng: 20.5261677 },
    { lat: 49.0287574, lng: 20.5262033 },
    { lat: 49.0280677, lng: 20.5261224 },
    { lat: 49.0279191, lng: 20.5254241 },
    { lat: 49.0271544, lng: 20.5256 },
    { lat: 49.0268832, lng: 20.5258184 },
    { lat: 49.0265117, lng: 20.5259104 },
    { lat: 49.0264863, lng: 20.5263772 },
    { lat: 49.0260737, lng: 20.526425 },
    { lat: 49.0257838, lng: 20.526565 },
    { lat: 49.0256056, lng: 20.5263715 },
    { lat: 49.0254601, lng: 20.5263344 },
    { lat: 49.0252535, lng: 20.5260769 },
    { lat: 49.0249198, lng: 20.5260022 },
    { lat: 49.0245276, lng: 20.5260508 },
    { lat: 49.0236288, lng: 20.5266532 },
    { lat: 49.0238588, lng: 20.5272128 },
    { lat: 49.0245308, lng: 20.528848 },
    { lat: 49.0245969, lng: 20.5292394 },
    { lat: 49.0245652, lng: 20.5299973 },
    { lat: 49.0246986, lng: 20.5309233 },
    { lat: 49.024347, lng: 20.5315535 },
    { lat: 49.0240656, lng: 20.531829 },
    { lat: 49.0231377, lng: 20.5330429 },
    { lat: 49.0226635, lng: 20.534014 },
    { lat: 49.0219482, lng: 20.5349801 },
    { lat: 49.0219178, lng: 20.5351574 },
    { lat: 49.0219968, lng: 20.5357719 },
    { lat: 49.0222213, lng: 20.5360627 },
    { lat: 49.0227128, lng: 20.5361994 },
    { lat: 49.0230902, lng: 20.536178 },
    { lat: 49.0230962, lng: 20.5364296 },
    { lat: 49.02305, lng: 20.5367622 },
    { lat: 49.0230471, lng: 20.53742 },
    { lat: 49.022871, lng: 20.5377108 },
    { lat: 49.0226506, lng: 20.537933 },
    { lat: 49.0225915, lng: 20.5382502 },
    { lat: 49.0220261, lng: 20.5383364 },
    { lat: 49.0217996, lng: 20.5384827 },
    { lat: 49.0219446, lng: 20.5388438 },
    { lat: 49.0209004, lng: 20.5389217 },
    { lat: 49.02052, lng: 20.5387695 },
    { lat: 49.0201487, lng: 20.5384453 },
    { lat: 49.0195347, lng: 20.5385508 },
    { lat: 49.0193787, lng: 20.5387219 },
    { lat: 49.0192598, lng: 20.538977 },
    { lat: 49.0192774, lng: 20.5393012 },
    { lat: 49.0180951, lng: 20.5388701 },
    { lat: 49.0177118, lng: 20.5388214 },
    { lat: 49.0171476, lng: 20.5397056 },
    { lat: 49.0166003, lng: 20.5397666 },
    { lat: 49.0161855, lng: 20.5396556 },
    { lat: 49.0161439, lng: 20.5397177 },
    { lat: 49.0159993, lng: 20.5397357 },
    { lat: 49.0160351, lng: 20.5396717 },
    { lat: 49.0158217, lng: 20.5396877 },
    { lat: 49.0152831, lng: 20.5398223 },
    { lat: 49.0151459, lng: 20.5399208 },
    { lat: 49.0153047, lng: 20.5388861 },
    { lat: 49.0149307, lng: 20.5385134 },
    { lat: 49.0152192, lng: 20.5378794 },
    { lat: 49.0147943, lng: 20.5373816 },
    { lat: 49.0149229, lng: 20.5369912 },
    { lat: 49.0148801, lng: 20.5365354 },
    { lat: 49.0144027, lng: 20.5362874 },
    { lat: 49.0144445, lng: 20.5359822 },
    { lat: 49.0141971, lng: 20.5359649 },
    { lat: 49.013724, lng: 20.5358048 },
    { lat: 49.0134667, lng: 20.5366108 },
    { lat: 49.0125034, lng: 20.538393 },
    { lat: 49.0123085, lng: 20.5388464 },
    { lat: 49.0118641, lng: 20.5387025 },
    { lat: 49.0115573, lng: 20.5385981 },
    { lat: 49.0111674, lng: 20.5384657 },
    { lat: 49.0087573, lng: 20.5383521 },
    { lat: 49.008565, lng: 20.5385123 },
    { lat: 49.0085213, lng: 20.5396155 },
    { lat: 49.0069976, lng: 20.5401496 },
    { lat: 49.0066811, lng: 20.5440542 },
    { lat: 49.0065208, lng: 20.543924 },
    { lat: 49.0063762, lng: 20.5439378 },
    { lat: 49.0055849, lng: 20.5440955 },
    { lat: 49.0050562, lng: 20.5443525 },
    { lat: 49.0047937, lng: 20.5444053 },
    { lat: 49.0039679, lng: 20.5443666 },
    { lat: 49.0035031, lng: 20.5444292 },
    { lat: 49.0024192, lng: 20.5450461 },
    { lat: 49.0019754, lng: 20.5454367 },
    { lat: 49.0015214, lng: 20.5459529 },
    { lat: 49.0007124, lng: 20.546086 },
    { lat: 48.9987151, lng: 20.5471807 },
    { lat: 48.9976371, lng: 20.5472616 },
    { lat: 48.997376, lng: 20.5473531 },
    { lat: 48.9970377, lng: 20.5477067 },
    { lat: 48.9965584, lng: 20.5477166 },
    { lat: 48.9962195, lng: 20.5482965 },
    { lat: 48.9962668, lng: 20.5485987 },
    { lat: 48.9959014, lng: 20.5487104 },
    { lat: 48.9958824, lng: 20.5489867 },
    { lat: 48.9959679, lng: 20.5500528 },
    { lat: 48.994268, lng: 20.5510395 },
    { lat: 48.9932795, lng: 20.5519556 },
    { lat: 48.9926441, lng: 20.5524011 },
    { lat: 48.9912422, lng: 20.552702 },
    { lat: 48.990223, lng: 20.552734 },
    { lat: 48.989919, lng: 20.552723 },
    { lat: 48.989672, lng: 20.552715 },
    { lat: 48.989205, lng: 20.552699 },
    { lat: 48.989172, lng: 20.552909 },
    { lat: 48.989133, lng: 20.553087 },
    { lat: 48.989058, lng: 20.553367 },
    { lat: 48.988972, lng: 20.553357 },
    { lat: 48.988716, lng: 20.553309 },
    { lat: 48.988369, lng: 20.553273 },
    { lat: 48.988024, lng: 20.553199 },
    { lat: 48.988041, lng: 20.553384 },
    { lat: 48.988081, lng: 20.553846 },
    { lat: 48.988206, lng: 20.55518 },
    { lat: 48.988407, lng: 20.556379 },
    { lat: 48.988167, lng: 20.556568 },
    { lat: 48.98781, lng: 20.556855 },
    { lat: 48.987554, lng: 20.557058 },
    { lat: 48.987367, lng: 20.557209 },
    { lat: 48.987151, lng: 20.557384 },
    { lat: 48.986861, lng: 20.557619 },
    { lat: 48.98624, lng: 20.557837 },
    { lat: 48.986097, lng: 20.558062 },
    { lat: 48.985752, lng: 20.558585 },
    { lat: 48.985382, lng: 20.559145 },
    { lat: 48.985018, lng: 20.559711 },
    { lat: 48.984957, lng: 20.560265 },
    { lat: 48.984837, lng: 20.561351 },
    { lat: 48.984731, lng: 20.562266 },
    { lat: 48.984273, lng: 20.563734 },
    { lat: 48.984043, lng: 20.56448 },
    { lat: 48.984034, lng: 20.564503 },
    { lat: 48.984045, lng: 20.564514 },
    { lat: 48.983802, lng: 20.564927 },
    { lat: 48.983634, lng: 20.565216 },
    { lat: 48.983543, lng: 20.56537 },
    { lat: 48.98349, lng: 20.56546 },
    { lat: 48.983392, lng: 20.565631 },
    { lat: 48.983178, lng: 20.565994 },
    { lat: 48.982937, lng: 20.566271 },
    { lat: 48.982729, lng: 20.566511 },
    { lat: 48.98251, lng: 20.566766 },
    { lat: 48.982292, lng: 20.567015 },
    { lat: 48.982283, lng: 20.567025 },
    { lat: 48.982043, lng: 20.567302 },
    { lat: 48.981832, lng: 20.567545 },
    { lat: 48.981629, lng: 20.567777 },
    { lat: 48.98156, lng: 20.567831 },
    { lat: 48.981437, lng: 20.5679 },
    { lat: 48.98131, lng: 20.567975 },
    { lat: 48.981234, lng: 20.568019 },
    { lat: 48.981084, lng: 20.56811 },
    { lat: 48.980712, lng: 20.568333 },
    { lat: 48.980522, lng: 20.568495 },
    { lat: 48.980416, lng: 20.568587 },
    { lat: 48.980241, lng: 20.568743 },
    { lat: 48.980102, lng: 20.56886 },
    { lat: 48.979826, lng: 20.569093 },
    { lat: 48.97958, lng: 20.569266 },
    { lat: 48.979434, lng: 20.569319 },
    { lat: 48.979226, lng: 20.569396 },
    { lat: 48.979059, lng: 20.569459 },
    { lat: 48.978652, lng: 20.569615 },
    { lat: 48.97829, lng: 20.569797 },
    { lat: 48.978124, lng: 20.569858 },
    { lat: 48.977956, lng: 20.569936 },
    { lat: 48.977513, lng: 20.570146 },
    { lat: 48.977274, lng: 20.570267 },
    { lat: 48.977084, lng: 20.570364 },
    { lat: 48.976891, lng: 20.570463 },
    { lat: 48.976745, lng: 20.570539 },
    { lat: 48.976613, lng: 20.570607 },
    { lat: 48.976434, lng: 20.570738 },
    { lat: 48.976307, lng: 20.570829 },
    { lat: 48.976181, lng: 20.570918 },
    { lat: 48.975992, lng: 20.571053 },
    { lat: 48.975817, lng: 20.571178 },
    { lat: 48.975661, lng: 20.571286 },
    { lat: 48.975514, lng: 20.571394 },
    { lat: 48.975336, lng: 20.571515 },
    { lat: 48.975112, lng: 20.571671 },
    { lat: 48.975009, lng: 20.57177 },
    { lat: 48.974944, lng: 20.571849 },
    { lat: 48.974771, lng: 20.572047 },
    { lat: 48.974652, lng: 20.572209 },
    { lat: 48.974534, lng: 20.572416 },
    { lat: 48.97437, lng: 20.572666 },
    { lat: 48.974297, lng: 20.57281 },
    { lat: 48.97417, lng: 20.573067 },
    { lat: 48.974077, lng: 20.57326 },
    { lat: 48.973939, lng: 20.573536 },
    { lat: 48.973808, lng: 20.573781 },
    { lat: 48.973679, lng: 20.573983 },
    { lat: 48.973567, lng: 20.57417 },
    { lat: 48.973433, lng: 20.574407 },
    { lat: 48.973364, lng: 20.574532 },
    { lat: 48.973269, lng: 20.574742 },
    { lat: 48.973225, lng: 20.574831 },
    { lat: 48.973132, lng: 20.575039 },
    { lat: 48.973053, lng: 20.575207 },
    { lat: 48.973014, lng: 20.575282 },
    { lat: 48.972922, lng: 20.575421 },
    { lat: 48.972719, lng: 20.575387 },
    { lat: 48.972383, lng: 20.575261 },
    { lat: 48.972188, lng: 20.575232 },
    { lat: 48.9721328, lng: 20.5752101 },
    { lat: 48.972012, lng: 20.575273 },
    { lat: 48.972002, lng: 20.575277 },
    { lat: 48.972001, lng: 20.575278 },
    { lat: 48.971769, lng: 20.575533 },
    { lat: 48.971644, lng: 20.57563 },
    { lat: 48.971545, lng: 20.575702 },
    { lat: 48.971475, lng: 20.57574 },
    { lat: 48.971435, lng: 20.575762 },
    { lat: 48.971352, lng: 20.575799 },
    { lat: 48.971209, lng: 20.575928 },
    { lat: 48.971127, lng: 20.575999 },
    { lat: 48.971005, lng: 20.576093 },
    { lat: 48.970883, lng: 20.576187 },
    { lat: 48.970782, lng: 20.576325 },
    { lat: 48.970775, lng: 20.576335 },
    { lat: 48.97078, lng: 20.576353 },
    { lat: 48.970742, lng: 20.576628 },
    { lat: 48.970715, lng: 20.576816 },
    { lat: 48.970696, lng: 20.576956 },
    { lat: 48.970696, lng: 20.576966 },
    { lat: 48.970691, lng: 20.577022 },
    { lat: 48.970691, lng: 20.577026 },
    { lat: 48.970682, lng: 20.577139 },
    { lat: 48.970675, lng: 20.577222 },
    { lat: 48.970662, lng: 20.577397 },
    { lat: 48.970653, lng: 20.57752 },
    { lat: 48.970653, lng: 20.577525 },
    { lat: 48.970712, lng: 20.577556 },
    { lat: 48.970822, lng: 20.577612 },
    { lat: 48.97088, lng: 20.577642 },
    { lat: 48.970922, lng: 20.577663 },
    { lat: 48.970973, lng: 20.577689 },
    { lat: 48.97101, lng: 20.577708 },
    { lat: 48.971054, lng: 20.577731 },
    { lat: 48.971098, lng: 20.577754 },
    { lat: 48.971102, lng: 20.577756 },
    { lat: 48.971174, lng: 20.577793 },
    { lat: 48.971222, lng: 20.577818 },
    { lat: 48.971267, lng: 20.577842 },
    { lat: 48.971315, lng: 20.577867 },
    { lat: 48.971357, lng: 20.577889 },
    { lat: 48.971439, lng: 20.577932 },
    { lat: 48.971485, lng: 20.577956 },
    { lat: 48.971518, lng: 20.577973 },
    { lat: 48.971593, lng: 20.578013 },
    { lat: 48.971611, lng: 20.578023 },
    { lat: 48.971637, lng: 20.578036 },
    { lat: 48.971677, lng: 20.578057 },
    { lat: 48.971742, lng: 20.578093 },
    { lat: 48.971751, lng: 20.578097 },
    { lat: 48.971758, lng: 20.578205 },
    { lat: 48.97176, lng: 20.578257 },
    { lat: 48.971763, lng: 20.578307 },
    { lat: 48.971764, lng: 20.578352 },
    { lat: 48.971767, lng: 20.578401 },
    { lat: 48.97177, lng: 20.578466 },
    { lat: 48.971774, lng: 20.578534 },
    { lat: 48.971779, lng: 20.578628 },
    { lat: 48.971782, lng: 20.578696 },
    { lat: 48.971785, lng: 20.578754 },
    { lat: 48.971787, lng: 20.578782 },
    { lat: 48.971788, lng: 20.57881 },
    { lat: 48.971792, lng: 20.578903 },
    { lat: 48.971795, lng: 20.578975 },
    { lat: 48.971799, lng: 20.579051 },
    { lat: 48.971803, lng: 20.579133 },
    { lat: 48.971808, lng: 20.579234 },
    { lat: 48.97181, lng: 20.579311 },
    { lat: 48.971777, lng: 20.579388 },
    { lat: 48.971778, lng: 20.579505 },
    { lat: 48.971779, lng: 20.579594 },
    { lat: 48.971769, lng: 20.579669 },
    { lat: 48.971797, lng: 20.579763 },
    { lat: 48.971836, lng: 20.579877 },
    { lat: 48.971878, lng: 20.579989 },
    { lat: 48.971872, lng: 20.580107 },
    { lat: 48.97188, lng: 20.580125 },
    { lat: 48.971892, lng: 20.580147 },
    { lat: 48.971898, lng: 20.580159 },
    { lat: 48.971903, lng: 20.580168 },
    { lat: 48.971843, lng: 20.580295 },
    { lat: 48.971834, lng: 20.580333 },
    { lat: 48.971817, lng: 20.580435 },
    { lat: 48.971808, lng: 20.580497 },
    { lat: 48.971808, lng: 20.58057 },
    { lat: 48.971814, lng: 20.580636 },
    { lat: 48.971826, lng: 20.580691 },
    { lat: 48.97184, lng: 20.58077 },
    { lat: 48.971865, lng: 20.58089 },
    { lat: 48.971889, lng: 20.581005 },
    { lat: 48.971844, lng: 20.581195 },
    { lat: 48.971868, lng: 20.5812 },
    { lat: 48.971913, lng: 20.581214 },
    { lat: 48.972037, lng: 20.581251 },
    { lat: 48.972058, lng: 20.581254 },
    { lat: 48.972073, lng: 20.581332 },
    { lat: 48.972174, lng: 20.58182 },
    { lat: 48.972241, lng: 20.582147 },
    { lat: 48.972244, lng: 20.582164 },
    { lat: 48.97225, lng: 20.58219 },
    { lat: 48.972263, lng: 20.582239 },
    { lat: 48.972275, lng: 20.582281 },
    { lat: 48.972285, lng: 20.582318 },
    { lat: 48.972288, lng: 20.582327 },
    { lat: 48.972361, lng: 20.582319 },
    { lat: 48.972775, lng: 20.582285 },
    { lat: 48.972921, lng: 20.582269 },
    { lat: 48.973182, lng: 20.582247 },
    { lat: 48.973309, lng: 20.582234 },
    { lat: 48.97331, lng: 20.58229 },
    { lat: 48.97331, lng: 20.582297 },
    { lat: 48.973332, lng: 20.583412 },
    { lat: 48.973359, lng: 20.584801 },
    { lat: 48.973256, lng: 20.584574 },
    { lat: 48.973213, lng: 20.584527 },
    { lat: 48.973151, lng: 20.584457 },
    { lat: 48.973068, lng: 20.584389 },
    { lat: 48.973015, lng: 20.584358 },
    { lat: 48.97292, lng: 20.584302 },
    { lat: 48.97288, lng: 20.584294 },
    { lat: 48.972854, lng: 20.584548 },
    { lat: 48.972898, lng: 20.585144 },
    { lat: 48.972915, lng: 20.585352 },
    { lat: 48.972946, lng: 20.58577 },
    { lat: 48.972978, lng: 20.586205 },
    { lat: 48.972993, lng: 20.586391 },
    { lat: 48.9729988, lng: 20.5864442 },
    { lat: 48.973018, lng: 20.58662 },
    { lat: 48.973021, lng: 20.586648 },
    { lat: 48.973073, lng: 20.587127 },
    { lat: 48.973101, lng: 20.587384 },
    { lat: 48.973119, lng: 20.587576 },
    { lat: 48.973133, lng: 20.587702 },
    { lat: 48.973149, lng: 20.58783 },
    { lat: 48.973149, lng: 20.587838 },
    { lat: 48.973165, lng: 20.587896 },
    { lat: 48.973175, lng: 20.587929 },
    { lat: 48.973185, lng: 20.587992 },
    { lat: 48.973184, lng: 20.58807 },
    { lat: 48.973183, lng: 20.588206 },
    { lat: 48.973164, lng: 20.588363 },
    { lat: 48.973133, lng: 20.588578 },
    { lat: 48.973097, lng: 20.58879 },
    { lat: 48.973077, lng: 20.58893 },
    { lat: 48.973073, lng: 20.588988 },
    { lat: 48.973077, lng: 20.589073 },
    { lat: 48.973083, lng: 20.589146 },
    { lat: 48.973099, lng: 20.589262 },
    { lat: 48.973123, lng: 20.589381 },
    { lat: 48.97316, lng: 20.589556 },
    { lat: 48.973228, lng: 20.589713 },
    { lat: 48.973282, lng: 20.589803 },
    { lat: 48.973362, lng: 20.589926 },
    { lat: 48.973459, lng: 20.590077 },
    { lat: 48.973544, lng: 20.59018 },
    { lat: 48.973642, lng: 20.590284 },
    { lat: 48.973691, lng: 20.590334 },
    { lat: 48.973896, lng: 20.590461 },
    { lat: 48.974061, lng: 20.590582 },
    { lat: 48.974208, lng: 20.590689 },
    { lat: 48.974309, lng: 20.590766 },
    { lat: 48.974395, lng: 20.590857 },
    { lat: 48.974488, lng: 20.590957 },
    { lat: 48.974519, lng: 20.591049 },
    { lat: 48.974538, lng: 20.591127 },
    { lat: 48.974546, lng: 20.591236 },
    { lat: 48.97455, lng: 20.591283 },
    { lat: 48.974555, lng: 20.591356 },
    { lat: 48.974621, lng: 20.59159 },
    { lat: 48.974675, lng: 20.591832 },
    { lat: 48.974703, lng: 20.592027 },
    { lat: 48.974723, lng: 20.592173 },
    { lat: 48.974767, lng: 20.592403 },
    { lat: 48.974779, lng: 20.592473 },
    { lat: 48.974807, lng: 20.592579 },
    { lat: 48.974854, lng: 20.592762 },
    { lat: 48.974867, lng: 20.592985 },
    { lat: 48.97487, lng: 20.593111 },
    { lat: 48.974873, lng: 20.59328 },
    { lat: 48.974886, lng: 20.593418 },
    { lat: 48.974912, lng: 20.593556 },
    { lat: 48.97489, lng: 20.593792 },
    { lat: 48.9749, lng: 20.593938 },
    { lat: 48.974938, lng: 20.594097 },
    { lat: 48.974938, lng: 20.594205 },
    { lat: 48.974881, lng: 20.594326 },
    { lat: 48.974874, lng: 20.594451 },
    { lat: 48.974909, lng: 20.594621 },
    { lat: 48.974846, lng: 20.594846 },
    { lat: 48.97483, lng: 20.595135 },
    { lat: 48.974819, lng: 20.595302 },
    { lat: 48.974792, lng: 20.595557 },
    { lat: 48.974746, lng: 20.595736 },
    { lat: 48.974776, lng: 20.5958 },
    { lat: 48.974766, lng: 20.595882 },
    { lat: 48.974729, lng: 20.595969 },
    { lat: 48.974659, lng: 20.596115 },
    { lat: 48.974577, lng: 20.596292 },
    { lat: 48.974494, lng: 20.596475 },
    { lat: 48.974408, lng: 20.596653 },
    { lat: 48.974364, lng: 20.59705 },
    { lat: 48.974271, lng: 20.597323 },
    { lat: 48.974174, lng: 20.597523 },
    { lat: 48.974136, lng: 20.597659 },
    { lat: 48.974064, lng: 20.597812 },
    { lat: 48.97399, lng: 20.597993 },
    { lat: 48.973883, lng: 20.59825 },
    { lat: 48.973787, lng: 20.598465 },
    { lat: 48.973675, lng: 20.598659 },
    { lat: 48.973854, lng: 20.598889 },
    { lat: 48.973869, lng: 20.598913 },
    { lat: 48.973887, lng: 20.598944 },
    { lat: 48.974128, lng: 20.599342 },
    { lat: 48.974341, lng: 20.599697 },
    { lat: 48.97462, lng: 20.600163 },
    { lat: 48.97487, lng: 20.600578 },
    { lat: 48.975118, lng: 20.600997 },
    { lat: 48.975276, lng: 20.601263 },
    { lat: 48.975502, lng: 20.602046 },
    { lat: 48.975808, lng: 20.603088 },
    { lat: 48.976058, lng: 20.603953 },
    { lat: 48.976337, lng: 20.604916 },
    { lat: 48.97654, lng: 20.605621 },
    { lat: 48.97654, lng: 20.605625 },
    { lat: 48.976541, lng: 20.605638 },
    { lat: 48.976595, lng: 20.606378 },
    { lat: 48.976676, lng: 20.606866 },
    { lat: 48.976769, lng: 20.60747 },
    { lat: 48.976859, lng: 20.608081 },
    { lat: 48.97694, lng: 20.608594 },
    { lat: 48.977027, lng: 20.609172 },
    { lat: 48.977138, lng: 20.609872 },
    { lat: 48.977173, lng: 20.61009 },
    { lat: 48.977207, lng: 20.610304 },
    { lat: 48.977241, lng: 20.610512 },
    { lat: 48.97743, lng: 20.611694 },
    { lat: 48.977667, lng: 20.613079 },
    { lat: 48.977818, lng: 20.613964 },
    { lat: 48.977944, lng: 20.614664 },
    { lat: 48.978115, lng: 20.615647 },
    { lat: 48.978438, lng: 20.616361 },
    { lat: 48.978545, lng: 20.616598 },
    { lat: 48.978559, lng: 20.616671 },
    { lat: 48.978565, lng: 20.616714 },
    { lat: 48.978582, lng: 20.616838 },
    { lat: 48.978807, lng: 20.616907 },
    { lat: 48.97877, lng: 20.617075 },
    { lat: 48.978744, lng: 20.61719 },
    { lat: 48.978691, lng: 20.617427 },
    { lat: 48.978677, lng: 20.617495 },
    { lat: 48.978664, lng: 20.617547 },
    { lat: 48.97865, lng: 20.617615 },
    { lat: 48.978816, lng: 20.617891 },
    { lat: 48.9790695, lng: 20.6178823 },
    { lat: 48.9797561, lng: 20.6179435 },
    { lat: 48.9861203, lng: 20.6165802 },
    { lat: 48.9868769, lng: 20.616366 },
    { lat: 48.987763, lng: 20.6163997 },
    { lat: 48.9881506, lng: 20.6166859 },
    { lat: 48.9889394, lng: 20.6179197 },
    { lat: 48.9890634, lng: 20.617833 },
    { lat: 48.9901252, lng: 20.6176509 },
    { lat: 48.9913087, lng: 20.6179996 },
    { lat: 48.9925181, lng: 20.6186617 },
    { lat: 48.993125, lng: 20.6194397 },
    { lat: 48.9933895, lng: 20.6194671 },
    { lat: 48.9943706, lng: 20.62079 },
    { lat: 48.9949073, lng: 20.6202565 },
    { lat: 48.9949328, lng: 20.619091 },
    { lat: 48.9954544, lng: 20.6194087 },
    { lat: 48.9961449, lng: 20.6194535 },
    { lat: 48.9965912, lng: 20.6196557 },
    { lat: 48.9985243, lng: 20.6195008 },
    { lat: 49.0009816, lng: 20.6184093 },
    { lat: 49.000619, lng: 20.6171341 },
    { lat: 49.001989, lng: 20.6168495 },
    { lat: 49.003766, lng: 20.6154336 },
    { lat: 49.0039887, lng: 20.6153942 },
    { lat: 49.00422, lng: 20.6156262 },
    { lat: 49.0047062, lng: 20.6158081 },
    { lat: 49.0049968, lng: 20.6158444 },
    { lat: 49.0061279, lng: 20.6155245 },
    { lat: 49.0074769, lng: 20.6150386 },
    { lat: 49.0077117, lng: 20.6153334 },
    { lat: 49.0080693, lng: 20.616068 },
    { lat: 49.0080588, lng: 20.6163715 },
    { lat: 49.0081949, lng: 20.6167994 },
    { lat: 49.0081565, lng: 20.6177332 },
    { lat: 49.0084014, lng: 20.6181502 },
    { lat: 49.0077881, lng: 20.6197151 },
    { lat: 49.0077982, lng: 20.6221703 },
    { lat: 49.0073779, lng: 20.6229359 },
    { lat: 49.0066677, lng: 20.6240283 },
    { lat: 49.0069939, lng: 20.6240513 },
    { lat: 49.0071903, lng: 20.6241941 },
    { lat: 49.0069395, lng: 20.6264698 },
    { lat: 49.0070615, lng: 20.6266396 },
    { lat: 49.0070573, lng: 20.6272421 },
    { lat: 49.0075496, lng: 20.62918 },
    { lat: 49.0093365, lng: 20.6276621 },
    { lat: 49.0095888, lng: 20.6277576 },
    { lat: 49.0097617, lng: 20.6280565 },
    { lat: 49.0099195, lng: 20.6279702 },
    { lat: 49.0098794, lng: 20.6278524 },
    { lat: 49.0099762, lng: 20.6276079 },
    { lat: 49.0104918, lng: 20.6267049 },
    { lat: 49.010857, lng: 20.6264044 },
    { lat: 49.0109691, lng: 20.6268555 },
    { lat: 49.0110216, lng: 20.6268787 },
    { lat: 49.0115049, lng: 20.6264546 },
    { lat: 49.0115061, lng: 20.6261418 },
    { lat: 49.0116123, lng: 20.6259992 },
    { lat: 49.0119872, lng: 20.6261497 },
    { lat: 49.0123649, lng: 20.6261371 },
    { lat: 49.0128588, lng: 20.6256325 },
    { lat: 49.0131136, lng: 20.6258282 },
    { lat: 49.0133053, lng: 20.6258072 },
    { lat: 49.0144785, lng: 20.6251239 },
    { lat: 49.0149802, lng: 20.6246763 },
    { lat: 49.0150635, lng: 20.6244298 },
    { lat: 49.0155643, lng: 20.624422 },
    { lat: 49.0161435, lng: 20.623751 },
    { lat: 49.0161349, lng: 20.6237352 },
    { lat: 49.0161996, lng: 20.623685 },
    { lat: 49.0162227, lng: 20.6235145 },
    { lat: 49.0160501, lng: 20.623035 },
    { lat: 49.0160626, lng: 20.6229773 },
    { lat: 49.0164378, lng: 20.6229276 },
    { lat: 49.0165154, lng: 20.6228297 },
    { lat: 49.0166731, lng: 20.6223033 },
    { lat: 49.0166979, lng: 20.6219343 },
    { lat: 49.0172625, lng: 20.6216037 },
    { lat: 49.0173108, lng: 20.621137 },
    { lat: 49.0174856, lng: 20.6207727 },
    { lat: 49.0177745, lng: 20.6205086 },
    { lat: 49.0180968, lng: 20.6206093 },
    { lat: 49.0182511, lng: 20.6205846 },
    { lat: 49.0185387, lng: 20.6200684 },
    { lat: 49.0188449, lng: 20.6200044 },
    { lat: 49.0189499, lng: 20.6200437 },
    { lat: 49.0194172, lng: 20.6204859 },
    { lat: 49.01988, lng: 20.6205502 },
    { lat: 49.020092, lng: 20.6206512 },
    { lat: 49.0201505, lng: 20.6206204 },
    { lat: 49.0201925, lng: 20.6203777 },
    { lat: 49.0202814, lng: 20.6202852 },
    { lat: 49.020359, lng: 20.6202856 },
    { lat: 49.0204655, lng: 20.6203925 },
    { lat: 49.0206358, lng: 20.6202828 },
    { lat: 49.0207328, lng: 20.6203468 },
    { lat: 49.0208476, lng: 20.6206256 },
    { lat: 49.0210169, lng: 20.6207352 },
    { lat: 49.0211312, lng: 20.6207192 },
    { lat: 49.0214775, lng: 20.6203914 },
    { lat: 49.0217564, lng: 20.6203885 },
    { lat: 49.0220758, lng: 20.620068 },
    { lat: 49.0226143, lng: 20.6199814 },
    { lat: 49.0227734, lng: 20.6198411 },
    { lat: 49.0228407, lng: 20.6195815 },
    { lat: 49.023012, lng: 20.619217 },
    { lat: 49.0231925, lng: 20.6191001 },
    { lat: 49.0235037, lng: 20.6190181 },
    { lat: 49.0238394, lng: 20.6185947 },
    { lat: 49.0241286, lng: 20.6184639 },
    { lat: 49.0243209, lng: 20.6187703 },
    { lat: 49.0244511, lng: 20.618697 },
    { lat: 49.024511, lng: 20.6185808 },
    { lat: 49.0246844, lng: 20.6185817 },
    { lat: 49.0247893, lng: 20.6184621 },
    { lat: 49.0249153, lng: 20.6181165 },
    { lat: 49.0250855, lng: 20.6179057 },
    { lat: 49.0252239, lng: 20.6179938 },
    { lat: 49.0253035, lng: 20.6182637 },
    { lat: 49.0255354, lng: 20.6185442 },
    { lat: 49.02561, lng: 20.6185846 },
    { lat: 49.0257261, lng: 20.6184626 },
    { lat: 49.0259937, lng: 20.6186708 },
    { lat: 49.0262759, lng: 20.6184797 },
    { lat: 49.0266032, lng: 20.6185922 },
    { lat: 49.0267683, lng: 20.6184872 },
    { lat: 49.026876, lng: 20.6185084 },
    { lat: 49.0270094, lng: 20.6186497 },
    { lat: 49.0274133, lng: 20.6183303 },
    { lat: 49.0276858, lng: 20.6184876 },
    { lat: 49.0285165, lng: 20.6183599 },
    { lat: 49.0288019, lng: 20.6181889 },
    { lat: 49.0291407, lng: 20.6184052 },
    { lat: 49.0293364, lng: 20.6183038 },
    { lat: 49.0296839, lng: 20.6184218 },
    { lat: 49.0298467, lng: 20.6182209 },
    { lat: 49.0300764, lng: 20.6181611 },
    { lat: 49.030373, lng: 20.6184237 },
    { lat: 49.030471, lng: 20.6186139 },
    { lat: 49.0308037, lng: 20.6186635 },
    { lat: 49.0309063, lng: 20.6188842 },
    { lat: 49.0310865, lng: 20.6189814 },
    { lat: 49.0312839, lng: 20.6188619 },
    { lat: 49.0313564, lng: 20.6188773 },
    { lat: 49.0314893, lng: 20.6190033 },
    { lat: 49.0316438, lng: 20.6194122 },
    { lat: 49.0315517, lng: 20.619449 },
    { lat: 49.0316006, lng: 20.6195568 },
    { lat: 49.0315725, lng: 20.6196191 },
    { lat: 49.0303146, lng: 20.6217887 },
    { lat: 49.0303217, lng: 20.622013 },
    { lat: 49.0302688, lng: 20.6222004 },
    { lat: 49.0303542, lng: 20.6224483 },
    { lat: 49.030599, lng: 20.6227396 },
    { lat: 49.0307136, lng: 20.6236068 },
    { lat: 49.0306948, lng: 20.6238958 },
    { lat: 49.0304457, lng: 20.6237986 },
    { lat: 49.0303408, lng: 20.6238177 },
    { lat: 49.0303195, lng: 20.6240061 },
    { lat: 49.030642, lng: 20.6243658 },
    { lat: 49.0312046, lng: 20.6247952 },
    { lat: 49.0313663, lng: 20.62519 },
    { lat: 49.0316658, lng: 20.6254355 },
    { lat: 49.0318631, lng: 20.625758 },
    { lat: 49.0321308, lng: 20.6269637 },
    { lat: 49.0320104, lng: 20.6272486 },
    { lat: 49.0319636, lng: 20.6275391 },
    { lat: 49.0319952, lng: 20.6278544 },
    { lat: 49.0321707, lng: 20.6284148 },
    { lat: 49.0325494, lng: 20.6288284 },
    { lat: 49.032812, lng: 20.6284054 },
    { lat: 49.0332411, lng: 20.6279724 },
    { lat: 49.0337621, lng: 20.6283106 },
    { lat: 49.0339963, lng: 20.628249 },
    { lat: 49.0342335, lng: 20.6283311 },
    { lat: 49.0345326, lng: 20.6288866 },
    { lat: 49.0348055, lng: 20.629585 },
    { lat: 49.0348924, lng: 20.6294949 },
    { lat: 49.0355753, lng: 20.6303563 },
    { lat: 49.0356711, lng: 20.6301729 },
    { lat: 49.0363737, lng: 20.6309219 },
    { lat: 49.0367479, lng: 20.6310944 },
    { lat: 49.0379978, lng: 20.6313496 },
    { lat: 49.0380432, lng: 20.6316404 },
    { lat: 49.0379303, lng: 20.6319114 },
    { lat: 49.0376985, lng: 20.6319799 },
    { lat: 49.0376652, lng: 20.6321499 },
    { lat: 49.0376908, lng: 20.6326368 },
    { lat: 49.0378827, lng: 20.6328411 },
    { lat: 49.0383561, lng: 20.6337628 },
    { lat: 49.0391766, lng: 20.6344755 },
    { lat: 49.0410757, lng: 20.6317913 },
    { lat: 49.0410804, lng: 20.6319477 },
    { lat: 49.0417116, lng: 20.6324107 },
    { lat: 49.0419086, lng: 20.6324373 },
    { lat: 49.0418483, lng: 20.6321108 },
    { lat: 49.0418672, lng: 20.6318519 },
    { lat: 49.0427613, lng: 20.6323292 },
    { lat: 49.0431142, lng: 20.6327153 },
    { lat: 49.0430995, lng: 20.6331834 },
    { lat: 49.0433343, lng: 20.6333295 },
    { lat: 49.0435107, lng: 20.6331336 },
    { lat: 49.0436858, lng: 20.6331143 },
    { lat: 49.0441076, lng: 20.6332316 },
    { lat: 49.0444893, lng: 20.6334478 },
    { lat: 49.0445371, lng: 20.6335324 },
    { lat: 49.0445736, lng: 20.6339819 },
    { lat: 49.0443038, lng: 20.6339882 },
    { lat: 49.0442709, lng: 20.6341029 },
    { lat: 49.0443657, lng: 20.6344234 },
    { lat: 49.0448067, lng: 20.6342639 },
    { lat: 49.0451839, lng: 20.6342959 },
    { lat: 49.0451305, lng: 20.6340912 },
    { lat: 49.0454608, lng: 20.6339793 },
    { lat: 49.0454125, lng: 20.6338319 },
    { lat: 49.0452159, lng: 20.6338992 },
    { lat: 49.0451931, lng: 20.6338308 },
    { lat: 49.0453226, lng: 20.6336732 },
    { lat: 49.045499, lng: 20.6335401 },
    { lat: 49.045867, lng: 20.6335076 },
    { lat: 49.046175, lng: 20.6331844 },
    { lat: 49.0462474, lng: 20.6334127 },
    { lat: 49.0463451, lng: 20.6334354 },
    { lat: 49.0464049, lng: 20.6335628 },
    { lat: 49.0467292, lng: 20.6334414 },
    { lat: 49.0469176, lng: 20.6331719 },
    { lat: 49.0468998, lng: 20.6329602 },
    { lat: 49.0466337, lng: 20.6327571 },
    { lat: 49.046081, lng: 20.6325075 },
    { lat: 49.0459503, lng: 20.6323508 },
    { lat: 49.045968, lng: 20.6323134 },
    { lat: 49.0467441, lng: 20.6326646 },
    { lat: 49.046835, lng: 20.6325942 },
    { lat: 49.0470983, lng: 20.6329688 },
    { lat: 49.0472102, lng: 20.6327628 },
    { lat: 49.0472805, lng: 20.6329313 },
    { lat: 49.0474197, lng: 20.6328268 },
    { lat: 49.0473962, lng: 20.6323909 },
    { lat: 49.0472306, lng: 20.6318302 },
    { lat: 49.0469257, lng: 20.6315527 },
    { lat: 49.0469796, lng: 20.631456 },
    { lat: 49.047243, lng: 20.6314534 },
    { lat: 49.0477821, lng: 20.6316395 },
    { lat: 49.0479691, lng: 20.6319566 },
    { lat: 49.0480148, lng: 20.6317263 },
    { lat: 49.0480801, lng: 20.6316606 },
    { lat: 49.0490132, lng: 20.6315658 },
    { lat: 49.0490765, lng: 20.6314442 },
    { lat: 49.0488679, lng: 20.6312521 },
    { lat: 49.0488889, lng: 20.6300549 },
    { lat: 49.0487721, lng: 20.6287533 },
    { lat: 49.048817, lng: 20.6285026 },
    { lat: 49.0486478, lng: 20.628362 },
    { lat: 49.048698, lng: 20.6278848 },
    { lat: 49.0488094, lng: 20.6276638 },
    { lat: 49.0488216, lng: 20.6275052 },
    { lat: 49.048715, lng: 20.6267346 },
    { lat: 49.0487495, lng: 20.6266571 },
    { lat: 49.0486839, lng: 20.6265599 },
    { lat: 49.0476985, lng: 20.6264223 },
    { lat: 49.0472524, lng: 20.626226 },
    { lat: 49.0471714, lng: 20.6261384 },
    { lat: 49.047292, lng: 20.6259248 },
    { lat: 49.047266, lng: 20.625791 },
    { lat: 49.0471408, lng: 20.6257548 },
    { lat: 49.0470968, lng: 20.6255693 },
    { lat: 49.0466869, lng: 20.6255634 },
    { lat: 49.0466905, lng: 20.6253826 },
    { lat: 49.0463025, lng: 20.6254577 },
    { lat: 49.04641, lng: 20.6251283 },
    { lat: 49.0465763, lng: 20.6250243 },
    { lat: 49.0466712, lng: 20.624692 },
    { lat: 49.0464535, lng: 20.6247719 },
    { lat: 49.0459996, lng: 20.6245177 },
    { lat: 49.0454854, lng: 20.6247459 },
    { lat: 49.0452329, lng: 20.6250789 },
    { lat: 49.0450255, lng: 20.6252275 },
    { lat: 49.0448692, lng: 20.6250734 },
    { lat: 49.0448492, lng: 20.6249896 },
    { lat: 49.0451961, lng: 20.6246201 },
    { lat: 49.0456469, lng: 20.6243193 },
    { lat: 49.0456368, lng: 20.6242312 },
    { lat: 49.045529, lng: 20.6241451 },
    { lat: 49.0455887, lng: 20.6240816 },
    { lat: 49.045715, lng: 20.6240244 },
    { lat: 49.0460587, lng: 20.6240689 },
    { lat: 49.0462113, lng: 20.6232465 },
    { lat: 49.046062, lng: 20.6230986 },
    { lat: 49.0457866, lng: 20.6230161 },
    { lat: 49.0457985, lng: 20.6229735 },
    { lat: 49.0458888, lng: 20.6229785 },
    { lat: 49.0458999, lng: 20.6229133 },
    { lat: 49.0465813, lng: 20.6232033 },
    { lat: 49.0482988, lng: 20.6243753 },
    { lat: 49.0489861, lng: 20.6249786 },
    { lat: 49.0491821, lng: 20.6250171 },
    { lat: 49.0492632, lng: 20.6248021 },
    { lat: 49.0497645, lng: 20.6252117 },
    { lat: 49.0500366, lng: 20.6256727 },
    { lat: 49.0504707, lng: 20.6261888 },
    { lat: 49.0507534, lng: 20.6255392 },
    { lat: 49.0509196, lng: 20.6248434 },
    { lat: 49.0509939, lng: 20.624876 },
    { lat: 49.051225, lng: 20.6252855 },
    { lat: 49.0513086, lng: 20.6260129 },
    { lat: 49.0510608, lng: 20.6264467 },
    { lat: 49.0510086, lng: 20.6268383 },
    { lat: 49.0514259, lng: 20.6271121 },
    { lat: 49.0515926, lng: 20.6268024 },
    { lat: 49.0517631, lng: 20.6266231 },
    { lat: 49.0521392, lng: 20.6273765 },
    { lat: 49.052341, lng: 20.6280072 },
    { lat: 49.052571, lng: 20.6282356 },
    { lat: 49.0529707, lng: 20.6280479 },
    { lat: 49.0529963, lng: 20.6274844 },
    { lat: 49.0532674, lng: 20.6256686 },
    { lat: 49.0536635, lng: 20.6248534 },
    { lat: 49.0540288, lng: 20.6242747 },
    { lat: 49.0546984, lng: 20.6234302 },
    { lat: 49.0561866, lng: 20.6209341 },
    { lat: 49.0565531, lng: 20.6210804 },
    { lat: 49.0569893, lng: 20.6206907 },
    { lat: 49.0572722, lng: 20.6203264 },
    { lat: 49.0573533, lng: 20.6203257 },
    { lat: 49.0577354, lng: 20.6198084 },
    { lat: 49.0580096, lng: 20.6195772 },
    { lat: 49.0584216, lng: 20.6203558 },
    { lat: 49.0583338, lng: 20.6207005 },
    { lat: 49.0584411, lng: 20.6209611 },
    { lat: 49.0589143, lng: 20.6214948 },
    { lat: 49.059055, lng: 20.6215552 },
    { lat: 49.059197, lng: 20.6215296 },
    { lat: 49.0595201, lng: 20.6211871 },
    { lat: 49.0600218, lng: 20.6208335 },
    { lat: 49.0601109, lng: 20.6207129 },
    { lat: 49.0603381, lng: 20.621549 },
    { lat: 49.0602589, lng: 20.6218184 },
    { lat: 49.0600965, lng: 20.6219669 },
    { lat: 49.0594615, lng: 20.6222157 },
    { lat: 49.0596294, lng: 20.6223442 },
    { lat: 49.0596973, lng: 20.6225733 },
    { lat: 49.0598026, lng: 20.6225867 },
    { lat: 49.0598813, lng: 20.6227732 },
    { lat: 49.0598451, lng: 20.6229202 },
    { lat: 49.0595091, lng: 20.6231612 },
    { lat: 49.0596249, lng: 20.6236838 },
    { lat: 49.0594331, lng: 20.6238262 },
    { lat: 49.0592878, lng: 20.6237361 },
    { lat: 49.059232, lng: 20.6235627 },
    { lat: 49.0590804, lng: 20.6233816 },
    { lat: 49.0587964, lng: 20.6231544 },
    { lat: 49.0584673, lng: 20.6232376 },
    { lat: 49.0583253, lng: 20.6233622 },
    { lat: 49.0583235, lng: 20.6235278 },
    { lat: 49.0581206, lng: 20.62358 },
    { lat: 49.0580661, lng: 20.62382 },
    { lat: 49.0580526, lng: 20.624034 },
    { lat: 49.0582942, lng: 20.62418 },
    { lat: 49.0582501, lng: 20.6242971 },
    { lat: 49.0580569, lng: 20.6242178 },
    { lat: 49.0576707, lng: 20.624655 },
    { lat: 49.0578931, lng: 20.624949 },
    { lat: 49.0579651, lng: 20.6263544 },
    { lat: 49.0580494, lng: 20.6268459 },
    { lat: 49.058162, lng: 20.6270634 },
    { lat: 49.0581492, lng: 20.6274941 },
    { lat: 49.0580324, lng: 20.6276241 },
    { lat: 49.0582526, lng: 20.6280185 },
    { lat: 49.0581151, lng: 20.6281703 },
    { lat: 49.0585568, lng: 20.6299232 },
    { lat: 49.058803, lng: 20.6302605 },
    { lat: 49.0589339, lng: 20.6301426 },
    { lat: 49.0592164, lng: 20.6305178 },
    { lat: 49.0593363, lng: 20.6304087 },
    { lat: 49.059425, lng: 20.6304296 },
    { lat: 49.0595262, lng: 20.6303052 },
    { lat: 49.0595278, lng: 20.6302244 },
    { lat: 49.059772, lng: 20.6300618 },
    { lat: 49.0598683, lng: 20.6302088 },
    { lat: 49.0599665, lng: 20.6301518 },
    { lat: 49.0600505, lng: 20.6304524 },
    { lat: 49.0600589, lng: 20.6307446 },
    { lat: 49.0600394, lng: 20.6308324 },
    { lat: 49.0599592, lng: 20.6308847 },
    { lat: 49.0600155, lng: 20.6310455 },
    { lat: 49.0599244, lng: 20.6311353 },
    { lat: 49.0598267, lng: 20.6310157 },
    { lat: 49.0597088, lng: 20.6310316 },
    { lat: 49.059504, lng: 20.6309062 },
    { lat: 49.0631369, lng: 20.6357826 },
    { lat: 49.0629148, lng: 20.636304 },
    { lat: 49.0626875, lng: 20.6365861 },
    { lat: 49.0627146, lng: 20.6372146 },
    { lat: 49.0625062, lng: 20.6377758 },
    { lat: 49.061837, lng: 20.639041 },
    { lat: 49.0618252, lng: 20.6394024 },
    { lat: 49.0609971, lng: 20.6396358 },
    { lat: 49.060288, lng: 20.6405723 },
    { lat: 49.0594917, lng: 20.6411602 },
    { lat: 49.0590949, lng: 20.6417029 },
    { lat: 49.0590523, lng: 20.6418946 },
    { lat: 49.0590571, lng: 20.6424096 },
    { lat: 49.0588556, lng: 20.6430629 },
    { lat: 49.0589367, lng: 20.6434438 },
    { lat: 49.0589468, lng: 20.6437567 },
    { lat: 49.0587149, lng: 20.6442544 },
    { lat: 49.0587816, lng: 20.6447636 },
    { lat: 49.0587096, lng: 20.6450116 },
    { lat: 49.0583527, lng: 20.6455768 },
    { lat: 49.0581832, lng: 20.6459664 },
    { lat: 49.0581927, lng: 20.646107 },
    { lat: 49.0589405, lng: 20.6471195 },
    { lat: 49.0590664, lng: 20.6477734 },
    { lat: 49.0591828, lng: 20.6478747 },
    { lat: 49.0592312, lng: 20.6482497 },
    { lat: 49.0594643, lng: 20.6487558 },
    { lat: 49.0594383, lng: 20.6489708 },
    { lat: 49.059517, lng: 20.6493441 },
    { lat: 49.0598028, lng: 20.6496882 },
    { lat: 49.0600934, lng: 20.6509768 },
    { lat: 49.0602952, lng: 20.6516097 },
    { lat: 49.0603169, lng: 20.6519974 },
    { lat: 49.0602294, lng: 20.6524767 },
    { lat: 49.0598952, lng: 20.6529201 },
    { lat: 49.0588965, lng: 20.6537892 },
    { lat: 49.0581325, lng: 20.6540889 },
    { lat: 49.0577033, lng: 20.6546123 },
    { lat: 49.0574048, lng: 20.65514 },
    { lat: 49.0554194, lng: 20.6551835 },
    { lat: 49.0548864, lng: 20.6556086 },
    { lat: 49.0548426, lng: 20.6555788 },
    { lat: 49.0548384, lng: 20.6556789 },
    { lat: 49.0547015, lng: 20.6556889 },
    { lat: 49.0546547, lng: 20.6557972 },
    { lat: 49.0545208, lng: 20.6558001 },
    { lat: 49.0539488, lng: 20.6563952 },
    { lat: 49.0537719, lng: 20.6564875 },
    { lat: 49.0537132, lng: 20.6566646 },
    { lat: 49.0535097, lng: 20.6569234 },
    { lat: 49.0533728, lng: 20.6572833 },
    { lat: 49.0533502, lng: 20.6575629 },
    { lat: 49.0531695, lng: 20.6579899 },
    { lat: 49.053114, lng: 20.6582655 },
    { lat: 49.0531375, lng: 20.6586957 },
    { lat: 49.0530144, lng: 20.6594083 },
    { lat: 49.0529637, lng: 20.6602243 },
    { lat: 49.0528705, lng: 20.6606735 },
    { lat: 49.0526929, lng: 20.6610213 },
    { lat: 49.052735, lng: 20.6610641 },
    { lat: 49.0526931, lng: 20.6611857 },
    { lat: 49.0525036, lng: 20.6614282 },
    { lat: 49.0524722, lng: 20.6615266 },
    { lat: 49.0554175, lng: 20.6624195 },
    { lat: 49.055912, lng: 20.6622702 },
    { lat: 49.0565931, lng: 20.6626459 },
    { lat: 49.0574352, lng: 20.6629271 },
    { lat: 49.0580377, lng: 20.662936 },
    { lat: 49.0581994, lng: 20.6628598 },
    { lat: 49.0583271, lng: 20.6627161 },
    { lat: 49.0585638, lng: 20.6623185 },
    { lat: 49.059073, lng: 20.6620781 },
    { lat: 49.0590971, lng: 20.6619943 },
    { lat: 49.0599901, lng: 20.6612526 },
    { lat: 49.060633, lng: 20.6621046 },
    { lat: 49.063035, lng: 20.6610282 },
    { lat: 49.0646247, lng: 20.6612191 },
    { lat: 49.0654248, lng: 20.6611197 },
    { lat: 49.066868, lng: 20.6619948 },
    { lat: 49.0681523, lng: 20.662433 },
    { lat: 49.0681484, lng: 20.6626404 },
    { lat: 49.0687215, lng: 20.663493 },
    { lat: 49.0691874, lng: 20.6639304 },
    { lat: 49.0694587, lng: 20.664873 },
    { lat: 49.0705468, lng: 20.6664929 },
    { lat: 49.0727501, lng: 20.6675448 },
    { lat: 49.0730001, lng: 20.6676096 },
    { lat: 49.0732186, lng: 20.6675168 },
    { lat: 49.0735878, lng: 20.6675175 },
    { lat: 49.0737933, lng: 20.6676257 },
    { lat: 49.0742849, lng: 20.6676507 },
    { lat: 49.0744918, lng: 20.6677381 },
    { lat: 49.0749013, lng: 20.6676062 },
    { lat: 49.0751319, lng: 20.6676614 },
    { lat: 49.0759007, lng: 20.6676304 },
    { lat: 49.0767098, lng: 20.6677483 },
    { lat: 49.0769466, lng: 20.6676361 },
    { lat: 49.0772006, lng: 20.6678118 },
    { lat: 49.0772744, lng: 20.6677971 },
    { lat: 49.0774069, lng: 20.6679405 },
    { lat: 49.0777687, lng: 20.6681102 },
    { lat: 49.0779175, lng: 20.6682742 },
    { lat: 49.0780428, lng: 20.6682904 },
    { lat: 49.0783274, lng: 20.6685045 },
    { lat: 49.078358, lng: 20.6685422 },
    { lat: 49.0785115, lng: 20.6682027 },
    { lat: 49.078478, lng: 20.6680511 },
    { lat: 49.0785461, lng: 20.6678501 },
    { lat: 49.0786581, lng: 20.6676771 },
    { lat: 49.0787966, lng: 20.6676466 },
    { lat: 49.0792261, lng: 20.6671898 },
    { lat: 49.079324, lng: 20.6671893 },
    { lat: 49.0794455, lng: 20.6670905 },
    { lat: 49.0797667, lng: 20.6670409 },
    { lat: 49.0804353, lng: 20.6672559 },
    { lat: 49.0807191, lng: 20.6670701 },
    { lat: 49.0808414, lng: 20.6671523 },
    { lat: 49.0811124, lng: 20.6669873 },
    { lat: 49.0812994, lng: 20.6670256 },
    { lat: 49.0822464, lng: 20.6669088 },
    { lat: 49.0823023, lng: 20.6666793 },
    { lat: 49.0819606, lng: 20.6657786 },
    { lat: 49.0818391, lng: 20.6651164 },
    { lat: 49.08169, lng: 20.6647553 },
    { lat: 49.0825072, lng: 20.6638131 },
    { lat: 49.0829067, lng: 20.663269 },
    { lat: 49.0830131, lng: 20.663039 },
    { lat: 49.0831929, lng: 20.6636593 },
    { lat: 49.0852165, lng: 20.660816 },
    { lat: 49.0860903, lng: 20.6596891 },
    { lat: 49.0864763, lng: 20.6590849 },
    { lat: 49.0868084, lng: 20.6588357 },
    { lat: 49.0884554, lng: 20.6582752 },
    { lat: 49.0883669, lng: 20.6580617 },
    { lat: 49.0886536, lng: 20.6579592 },
    { lat: 49.0892151, lng: 20.6573552 },
    { lat: 49.0896575, lng: 20.6574179 },
    { lat: 49.0894648, lng: 20.6569785 },
    { lat: 49.0896448, lng: 20.6564963 },
    { lat: 49.0898955, lng: 20.6562711 },
    { lat: 49.0903493, lng: 20.6565875 },
    { lat: 49.0903147, lng: 20.6562524 },
    { lat: 49.0915081, lng: 20.6557187 },
    { lat: 49.0914314, lng: 20.6555613 },
    { lat: 49.0914824, lng: 20.6549439 },
    { lat: 49.0916949, lng: 20.6531117 },
    { lat: 49.091754, lng: 20.6530055 },
    { lat: 49.0915188, lng: 20.6522212 },
    { lat: 49.0914814, lng: 20.6512369 },
    { lat: 49.0915713, lng: 20.6508558 },
    { lat: 49.0916862, lng: 20.6507073 },
    { lat: 49.0917875, lng: 20.6502021 },
    { lat: 49.0920657, lng: 20.6504211 },
    { lat: 49.092349, lng: 20.6503828 },
    { lat: 49.0924696, lng: 20.650515 },
    { lat: 49.0925888, lng: 20.6504461 },
    { lat: 49.0929103, lng: 20.6497412 },
    { lat: 49.0933749, lng: 20.649139 },
    { lat: 49.0935284, lng: 20.6487764 },
    { lat: 49.0941514, lng: 20.6490974 },
    { lat: 49.0950912, lng: 20.6511371 },
    { lat: 49.0956641, lng: 20.6525414 },
    { lat: 49.0959021, lng: 20.6533435 },
    { lat: 49.0960648, lng: 20.6536435 },
    { lat: 49.0959645, lng: 20.6540463 },
    { lat: 49.0962224, lng: 20.654304 },
    { lat: 49.0962592, lng: 20.6550793 },
    { lat: 49.0964876, lng: 20.6564247 },
    { lat: 49.0967364, lng: 20.6564547 },
    { lat: 49.0968285, lng: 20.6569201 },
    { lat: 49.0969422, lng: 20.6593437 },
    { lat: 49.0994639, lng: 20.6608526 },
    { lat: 49.1016798, lng: 20.6626334 },
    { lat: 49.1023579, lng: 20.6591215 },
    { lat: 49.1026211, lng: 20.6590493 },
    { lat: 49.1026863, lng: 20.658703 },
    { lat: 49.1028028, lng: 20.6584034 },
    { lat: 49.1027918, lng: 20.6580048 },
    { lat: 49.1029587, lng: 20.6579496 },
    { lat: 49.1031029, lng: 20.6574497 },
    { lat: 49.1035614, lng: 20.6571444 },
    { lat: 49.1040574, lng: 20.6566296 },
    { lat: 49.103998, lng: 20.6564776 },
    { lat: 49.1040661, lng: 20.6561816 },
    { lat: 49.1045472, lng: 20.6553085 },
    { lat: 49.1050246, lng: 20.6550949 },
    { lat: 49.1053267, lng: 20.6547565 },
    { lat: 49.1058899, lng: 20.6539836 },
    { lat: 49.1061121, lng: 20.6534305 },
    { lat: 49.1062903, lng: 20.6531577 },
    { lat: 49.1063081, lng: 20.6529949 },
    { lat: 49.1066687, lng: 20.6524084 },
    { lat: 49.1075432, lng: 20.6531268 },
    { lat: 49.1078341, lng: 20.6539585 },
    { lat: 49.1094567, lng: 20.6544344 },
    { lat: 49.1106841, lng: 20.6542591 },
    { lat: 49.1109867, lng: 20.6546906 },
    { lat: 49.111678, lng: 20.6554797 },
    { lat: 49.1140373, lng: 20.6565431 },
    { lat: 49.1161405, lng: 20.6568395 },
    { lat: 49.1162008, lng: 20.657049 },
    { lat: 49.1175311, lng: 20.6572544 },
    { lat: 49.1184562, lng: 20.6572795 },
    { lat: 49.1213012, lng: 20.6610033 },
    { lat: 49.122513, lng: 20.6604556 },
    { lat: 49.1240901, lng: 20.6598553 },
    { lat: 49.1246821, lng: 20.6602608 },
    { lat: 49.1283481, lng: 20.6596483 },
    { lat: 49.129231, lng: 20.6590918 },
    { lat: 49.13064, lng: 20.6579805 },
    { lat: 49.1314122, lng: 20.6586759 },
    { lat: 49.1328013, lng: 20.6608752 },
    { lat: 49.1349072, lng: 20.6632628 },
    { lat: 49.1350372, lng: 20.6648535 },
    { lat: 49.1349035, lng: 20.6649872 },
    { lat: 49.1347325, lng: 20.6666576 },
    { lat: 49.1344782, lng: 20.6672923 },
    { lat: 49.1349322, lng: 20.6679984 },
    { lat: 49.1359702, lng: 20.670174 },
    { lat: 49.1374465, lng: 20.6718312 },
    { lat: 49.1377108, lng: 20.6719528 },
    { lat: 49.1381913, lng: 20.6724217 },
    { lat: 49.1385446, lng: 20.67295 },
    { lat: 49.1391934, lng: 20.6737218 },
    { lat: 49.1394512, lng: 20.6733441 },
    { lat: 49.1397489, lng: 20.6729132 },
    { lat: 49.1404927, lng: 20.6718622 },
    { lat: 49.1407569, lng: 20.6714797 },
    { lat: 49.1414091, lng: 20.6705401 },
    { lat: 49.1419484, lng: 20.6697551 },
    { lat: 49.1424645, lng: 20.669012 },
    { lat: 49.1431153, lng: 20.6680728 },
    { lat: 49.1436513, lng: 20.6672895 },
    { lat: 49.1440975, lng: 20.6666387 },
    { lat: 49.1445695, lng: 20.6659506 },
    { lat: 49.1451633, lng: 20.665088 },
    { lat: 49.1453292, lng: 20.6648469 },
    { lat: 49.1459238, lng: 20.6640982 },
    { lat: 49.1464411, lng: 20.6634487 },
    { lat: 49.1467502, lng: 20.6630617 },
    { lat: 49.1470268, lng: 20.6627047 },
    { lat: 49.1479005, lng: 20.6615739 },
    { lat: 49.1487568, lng: 20.6604675 },
    { lat: 49.1493293, lng: 20.6597259 },
    { lat: 49.149493, lng: 20.6595149 },
    { lat: 49.1506658, lng: 20.6579725 },
    { lat: 49.1509679, lng: 20.657573 },
    { lat: 49.1516525, lng: 20.6566663 },
    { lat: 49.1521199, lng: 20.6560413 },
    { lat: 49.1527603, lng: 20.6551911 },
    { lat: 49.1534707, lng: 20.654252 },
    { lat: 49.1537233, lng: 20.6539159 },
    { lat: 49.1544454, lng: 20.6529542 },
    { lat: 49.1548574, lng: 20.6524247 },
    { lat: 49.1550282, lng: 20.6521951 },
    { lat: 49.1532754, lng: 20.6497747 },
    { lat: 49.1527505, lng: 20.649069 },
    { lat: 49.1526513, lng: 20.6489413 },
    { lat: 49.1526169, lng: 20.648896 },
    { lat: 49.1520316, lng: 20.6469794 },
    { lat: 49.1516322, lng: 20.6456688 },
    { lat: 49.1523248, lng: 20.6453949 },
    { lat: 49.152967, lng: 20.6452508 },
    { lat: 49.1524785, lng: 20.6435581 },
    { lat: 49.1520748, lng: 20.6422525 },
    { lat: 49.1513346, lng: 20.6418285 },
    { lat: 49.1496718, lng: 20.6408208 },
    { lat: 49.1474679, lng: 20.6395416 },
    { lat: 49.1429954, lng: 20.636977 },
    { lat: 49.1428518, lng: 20.6368021 },
    { lat: 49.1428152, lng: 20.6367562 },
    { lat: 49.1426963, lng: 20.6366098 },
    { lat: 49.1426576, lng: 20.6365637 },
    { lat: 49.140659, lng: 20.6341143 },
    { lat: 49.1380096, lng: 20.6309084 },
    { lat: 49.1368103, lng: 20.6294614 },
    { lat: 49.134573, lng: 20.6266957 },
    { lat: 49.1340178, lng: 20.6262212 },
    { lat: 49.1338289, lng: 20.6258833 },
    { lat: 49.132905, lng: 20.624238 },
    { lat: 49.1304482, lng: 20.6198609 },
    { lat: 49.1304122, lng: 20.6197967 },
    { lat: 49.1269348, lng: 20.6155586 },
    { lat: 49.1264632, lng: 20.6143498 },
    { lat: 49.1261951, lng: 20.6136617 },
    { lat: 49.1260025, lng: 20.6134057 },
    { lat: 49.1253387, lng: 20.6115529 },
    { lat: 49.124598, lng: 20.6098733 },
    { lat: 49.124457, lng: 20.6100836 },
    { lat: 49.1244088, lng: 20.6100876 },
    { lat: 49.124369, lng: 20.6100766 },
    { lat: 49.1243362, lng: 20.6101013 },
    { lat: 49.1241355, lng: 20.6097835 },
    { lat: 49.1239351, lng: 20.6091936 },
    { lat: 49.1230497, lng: 20.6076884 },
    { lat: 49.1223999, lng: 20.6069613 },
    { lat: 49.1220202, lng: 20.6067697 },
    { lat: 49.1217865, lng: 20.6059127 },
    { lat: 49.1217055, lng: 20.6056363 },
    { lat: 49.1215303, lng: 20.6053064 },
    { lat: 49.121064, lng: 20.6046441 },
    { lat: 49.1206248, lng: 20.6040485 },
    { lat: 49.1198052, lng: 20.6025282 },
    { lat: 49.1195823, lng: 20.601872 },
    { lat: 49.1194522, lng: 20.6016436 },
    { lat: 49.1191926, lng: 20.6009689 },
    { lat: 49.1186889, lng: 20.5996884 },
    { lat: 49.1183229, lng: 20.5994051 },
    { lat: 49.118209, lng: 20.5993666 },
    { lat: 49.1179501, lng: 20.5993701 },
    { lat: 49.1175162, lng: 20.5994472 },
    { lat: 49.1172778, lng: 20.5994231 },
    { lat: 49.1170015, lng: 20.5993767 },
    { lat: 49.116786, lng: 20.5993984 },
    { lat: 49.1159619, lng: 20.5993945 },
    { lat: 49.1159419, lng: 20.5993703 },
    { lat: 49.1157971, lng: 20.599196 },
    { lat: 49.1142208, lng: 20.597702 },
    { lat: 49.113806, lng: 20.596881 },
    { lat: 49.1132824, lng: 20.5958578 },
    { lat: 49.1123394, lng: 20.5940047 },
    { lat: 49.1117582, lng: 20.5923907 },
    { lat: 49.1109373, lng: 20.5899672 },
    { lat: 49.1102272, lng: 20.5881838 },
    { lat: 49.1097279, lng: 20.5867739 },
    { lat: 49.1090805, lng: 20.5849195 },
    { lat: 49.1085519, lng: 20.5834198 },
    { lat: 49.1081052, lng: 20.5820939 },
    { lat: 49.1078717, lng: 20.5817741 },
  ],
  Beharovce: [
    { lat: 49.0044792, lng: 20.806012 },
    { lat: 49.0024932, lng: 20.8063561 },
    { lat: 49.0013955, lng: 20.8064471 },
    { lat: 49.0011397, lng: 20.8064073 },
    { lat: 49.0009457, lng: 20.8065275 },
    { lat: 49.0006475, lng: 20.8069826 },
    { lat: 49.0006945, lng: 20.8070408 },
    { lat: 49.0005004, lng: 20.8077414 },
    { lat: 49.0001775, lng: 20.8085276 },
    { lat: 49.000032, lng: 20.8094634 },
    { lat: 49.0000035, lng: 20.8101386 },
    { lat: 48.9997738, lng: 20.8118956 },
    { lat: 48.9995883, lng: 20.812617 },
    { lat: 48.9994568, lng: 20.8129189 },
    { lat: 48.9993894, lng: 20.8133455 },
    { lat: 48.9990567, lng: 20.8163253 },
    { lat: 48.9988541, lng: 20.8191989 },
    { lat: 48.9989157, lng: 20.8196084 },
    { lat: 48.9998281, lng: 20.8224097 },
    { lat: 48.9996611, lng: 20.8235726 },
    { lat: 48.9996832, lng: 20.8241115 },
    { lat: 48.9998366, lng: 20.8251353 },
    { lat: 49.0001777, lng: 20.8259578 },
    { lat: 49.0006633, lng: 20.826724 },
    { lat: 49.0011032, lng: 20.8271403 },
    { lat: 49.0012086, lng: 20.8273582 },
    { lat: 49.0021007, lng: 20.8280946 },
    { lat: 49.0024393, lng: 20.8282211 },
    { lat: 49.0025687, lng: 20.8284101 },
    { lat: 49.0026353, lng: 20.8286795 },
    { lat: 49.0025459, lng: 20.8293846 },
    { lat: 49.0025992, lng: 20.8296933 },
    { lat: 49.003062, lng: 20.8305184 },
    { lat: 49.0032503, lng: 20.8309516 },
    { lat: 49.0033783, lng: 20.8314021 },
    { lat: 49.0034143, lng: 20.8318251 },
    { lat: 49.0033767, lng: 20.8322702 },
    { lat: 49.0033011, lng: 20.8325261 },
    { lat: 49.0039158, lng: 20.8332527 },
    { lat: 49.0043636, lng: 20.8336341 },
    { lat: 49.0047217, lng: 20.8334164 },
    { lat: 49.0049219, lng: 20.833114 },
    { lat: 49.0049837, lng: 20.8325588 },
    { lat: 49.0070208, lng: 20.8321575 },
    { lat: 49.0075412, lng: 20.8319996 },
    { lat: 49.0077556, lng: 20.831623 },
    { lat: 49.0084204, lng: 20.8301371 },
    { lat: 49.0085804, lng: 20.8301405 },
    { lat: 49.0085623, lng: 20.8300727 },
    { lat: 49.0090939, lng: 20.8299545 },
    { lat: 49.0095666, lng: 20.830373 },
    { lat: 49.0099613, lng: 20.8305611 },
    { lat: 49.0111093, lng: 20.8306922 },
    { lat: 49.0114413, lng: 20.8306543 },
    { lat: 49.0127536, lng: 20.8307476 },
    { lat: 49.0128509, lng: 20.8289004 },
    { lat: 49.0129652, lng: 20.8281626 },
    { lat: 49.0130117, lng: 20.8273202 },
    { lat: 49.0131488, lng: 20.8260762 },
    { lat: 49.0134697, lng: 20.8237564 },
    { lat: 49.0135118, lng: 20.8224241 },
    { lat: 49.013487, lng: 20.8220682 },
    { lat: 49.013619, lng: 20.820505 },
    { lat: 49.013667, lng: 20.8189955 },
    { lat: 49.013907, lng: 20.816126 },
    { lat: 49.0141093, lng: 20.8153718 },
    { lat: 49.0149795, lng: 20.8136808 },
    { lat: 49.0140416, lng: 20.8123679 },
    { lat: 49.0106655, lng: 20.8076365 },
    { lat: 49.0101794, lng: 20.8086137 },
    { lat: 49.008151, lng: 20.8065962 },
    { lat: 49.0074817, lng: 20.8060555 },
    { lat: 49.0060557, lng: 20.8057379 },
    { lat: 49.0044792, lng: 20.806012 },
  ],
  Uloža: [
    { lat: 49.0161435, lng: 20.623751 },
    { lat: 49.0162398, lng: 20.6236741 },
    { lat: 49.0164941, lng: 20.624015 },
    { lat: 49.0171604, lng: 20.6237632 },
    { lat: 49.0188567, lng: 20.6242813 },
    { lat: 49.0196047, lng: 20.6251671 },
    { lat: 49.0204097, lng: 20.625639 },
    { lat: 49.0210318, lng: 20.6284012 },
    { lat: 49.022115, lng: 20.6291516 },
    { lat: 49.0226479, lng: 20.6298485 },
    { lat: 49.022943, lng: 20.6313534 },
    { lat: 49.0229838, lng: 20.6328877 },
    { lat: 49.023446, lng: 20.6334335 },
    { lat: 49.0242267, lng: 20.6338533 },
    { lat: 49.0269568, lng: 20.6334229 },
    { lat: 49.0281028, lng: 20.6343228 },
    { lat: 49.028298, lng: 20.635118 },
    { lat: 49.028685, lng: 20.6355804 },
    { lat: 49.0287154, lng: 20.6355212 },
    { lat: 49.0287914, lng: 20.6352867 },
    { lat: 49.0289017, lng: 20.635284 },
    { lat: 49.0291816, lng: 20.6354892 },
    { lat: 49.0293107, lng: 20.6355103 },
    { lat: 49.0294156, lng: 20.63539 },
    { lat: 49.0296669, lng: 20.6352758 },
    { lat: 49.029674, lng: 20.6351912 },
    { lat: 49.0299591, lng: 20.6351686 },
    { lat: 49.0302231, lng: 20.6347419 },
    { lat: 49.0303621, lng: 20.6346334 },
    { lat: 49.0304671, lng: 20.6345805 },
    { lat: 49.0306032, lng: 20.6346718 },
    { lat: 49.0306478, lng: 20.6345034 },
    { lat: 49.0308979, lng: 20.634132 },
    { lat: 49.0313531, lng: 20.6346778 },
    { lat: 49.0317201, lng: 20.6350156 },
    { lat: 49.0319702, lng: 20.6346191 },
    { lat: 49.0324937, lng: 20.6349913 },
    { lat: 49.0326562, lng: 20.6349401 },
    { lat: 49.0329214, lng: 20.6346776 },
    { lat: 49.0333393, lng: 20.6340945 },
    { lat: 49.0354504, lng: 20.6360704 },
    { lat: 49.0353161, lng: 20.6377439 },
    { lat: 49.0353374, lng: 20.6379932 },
    { lat: 49.0353709, lng: 20.6380192 },
    { lat: 49.0351895, lng: 20.6389176 },
    { lat: 49.0352505, lng: 20.6391432 },
    { lat: 49.0354011, lng: 20.6392652 },
    { lat: 49.0355078, lng: 20.6394755 },
    { lat: 49.035005, lng: 20.6406401 },
    { lat: 49.0347973, lng: 20.6413103 },
    { lat: 49.0348231, lng: 20.6414635 },
    { lat: 49.0355206, lng: 20.6420208 },
    { lat: 49.0359042, lng: 20.6427932 },
    { lat: 49.0360566, lng: 20.6435229 },
    { lat: 49.0360445, lng: 20.643873 },
    { lat: 49.0362204, lng: 20.6453534 },
    { lat: 49.0363761, lng: 20.6456415 },
    { lat: 49.0368534, lng: 20.6461432 },
    { lat: 49.0368778, lng: 20.6462897 },
    { lat: 49.0374981, lng: 20.6464509 },
    { lat: 49.0374673, lng: 20.6466888 },
    { lat: 49.0385888, lng: 20.6471966 },
    { lat: 49.0387223, lng: 20.6473075 },
    { lat: 49.0397226, lng: 20.647638 },
    { lat: 49.0401017, lng: 20.6480058 },
    { lat: 49.0403007, lng: 20.647971 },
    { lat: 49.0404124, lng: 20.6489183 },
    { lat: 49.0402259, lng: 20.6498011 },
    { lat: 49.0401815, lng: 20.6509149 },
    { lat: 49.0403031, lng: 20.6515177 },
    { lat: 49.040656, lng: 20.6523768 },
    { lat: 49.040906, lng: 20.65266 },
    { lat: 49.0411912, lng: 20.6528631 },
    { lat: 49.041261, lng: 20.6531113 },
    { lat: 49.0413017, lng: 20.6530814 },
    { lat: 49.0423595, lng: 20.6535756 },
    { lat: 49.0429564, lng: 20.6541159 },
    { lat: 49.0431497, lng: 20.6541655 },
    { lat: 49.0433773, lng: 20.6543942 },
    { lat: 49.0435883, lng: 20.6547524 },
    { lat: 49.044009, lng: 20.6551822 },
    { lat: 49.0444175, lng: 20.656405 },
    { lat: 49.0444932, lng: 20.6565013 },
    { lat: 49.0445548, lng: 20.6564643 },
    { lat: 49.0446925, lng: 20.6570403 },
    { lat: 49.0449456, lng: 20.6579153 },
    { lat: 49.045418, lng: 20.6591307 },
    { lat: 49.0465754, lng: 20.6601978 },
    { lat: 49.0469246, lng: 20.6599237 },
    { lat: 49.0470399, lng: 20.6597767 },
    { lat: 49.0486139, lng: 20.6594068 },
    { lat: 49.0508219, lng: 20.6607088 },
    { lat: 49.0524722, lng: 20.6615266 },
    { lat: 49.0525036, lng: 20.6614282 },
    { lat: 49.0526931, lng: 20.6611857 },
    { lat: 49.052735, lng: 20.6610641 },
    { lat: 49.0526929, lng: 20.6610213 },
    { lat: 49.0528705, lng: 20.6606735 },
    { lat: 49.0529637, lng: 20.6602243 },
    { lat: 49.0530144, lng: 20.6594083 },
    { lat: 49.0531375, lng: 20.6586957 },
    { lat: 49.053114, lng: 20.6582655 },
    { lat: 49.0531695, lng: 20.6579899 },
    { lat: 49.0533502, lng: 20.6575629 },
    { lat: 49.0533728, lng: 20.6572833 },
    { lat: 49.0535097, lng: 20.6569234 },
    { lat: 49.0537132, lng: 20.6566646 },
    { lat: 49.0537719, lng: 20.6564875 },
    { lat: 49.0539488, lng: 20.6563952 },
    { lat: 49.0545208, lng: 20.6558001 },
    { lat: 49.0546547, lng: 20.6557972 },
    { lat: 49.0547015, lng: 20.6556889 },
    { lat: 49.0548384, lng: 20.6556789 },
    { lat: 49.0548426, lng: 20.6555788 },
    { lat: 49.0548864, lng: 20.6556086 },
    { lat: 49.0554194, lng: 20.6551835 },
    { lat: 49.0574048, lng: 20.65514 },
    { lat: 49.0577033, lng: 20.6546123 },
    { lat: 49.0581325, lng: 20.6540889 },
    { lat: 49.0588965, lng: 20.6537892 },
    { lat: 49.0598952, lng: 20.6529201 },
    { lat: 49.0602294, lng: 20.6524767 },
    { lat: 49.0603169, lng: 20.6519974 },
    { lat: 49.0602952, lng: 20.6516097 },
    { lat: 49.0600934, lng: 20.6509768 },
    { lat: 49.0598028, lng: 20.6496882 },
    { lat: 49.059517, lng: 20.6493441 },
    { lat: 49.0594383, lng: 20.6489708 },
    { lat: 49.0594643, lng: 20.6487558 },
    { lat: 49.0592312, lng: 20.6482497 },
    { lat: 49.0591828, lng: 20.6478747 },
    { lat: 49.0590664, lng: 20.6477734 },
    { lat: 49.0589405, lng: 20.6471195 },
    { lat: 49.0581927, lng: 20.646107 },
    { lat: 49.0581832, lng: 20.6459664 },
    { lat: 49.0583527, lng: 20.6455768 },
    { lat: 49.0587096, lng: 20.6450116 },
    { lat: 49.0587816, lng: 20.6447636 },
    { lat: 49.0587149, lng: 20.6442544 },
    { lat: 49.0589468, lng: 20.6437567 },
    { lat: 49.0589367, lng: 20.6434438 },
    { lat: 49.0588556, lng: 20.6430629 },
    { lat: 49.0590571, lng: 20.6424096 },
    { lat: 49.0590523, lng: 20.6418946 },
    { lat: 49.0590949, lng: 20.6417029 },
    { lat: 49.0594917, lng: 20.6411602 },
    { lat: 49.060288, lng: 20.6405723 },
    { lat: 49.0609971, lng: 20.6396358 },
    { lat: 49.0618252, lng: 20.6394024 },
    { lat: 49.061837, lng: 20.639041 },
    { lat: 49.0625062, lng: 20.6377758 },
    { lat: 49.0627146, lng: 20.6372146 },
    { lat: 49.0626875, lng: 20.6365861 },
    { lat: 49.0629148, lng: 20.636304 },
    { lat: 49.0631369, lng: 20.6357826 },
    { lat: 49.059504, lng: 20.6309062 },
    { lat: 49.0597088, lng: 20.6310316 },
    { lat: 49.0598267, lng: 20.6310157 },
    { lat: 49.0599244, lng: 20.6311353 },
    { lat: 49.0600155, lng: 20.6310455 },
    { lat: 49.0599592, lng: 20.6308847 },
    { lat: 49.0600394, lng: 20.6308324 },
    { lat: 49.0600589, lng: 20.6307446 },
    { lat: 49.0600505, lng: 20.6304524 },
    { lat: 49.0599665, lng: 20.6301518 },
    { lat: 49.0598683, lng: 20.6302088 },
    { lat: 49.059772, lng: 20.6300618 },
    { lat: 49.0595278, lng: 20.6302244 },
    { lat: 49.0595262, lng: 20.6303052 },
    { lat: 49.059425, lng: 20.6304296 },
    { lat: 49.0593363, lng: 20.6304087 },
    { lat: 49.0592164, lng: 20.6305178 },
    { lat: 49.0589339, lng: 20.6301426 },
    { lat: 49.058803, lng: 20.6302605 },
    { lat: 49.0585568, lng: 20.6299232 },
    { lat: 49.0581151, lng: 20.6281703 },
    { lat: 49.0582526, lng: 20.6280185 },
    { lat: 49.0580324, lng: 20.6276241 },
    { lat: 49.0581492, lng: 20.6274941 },
    { lat: 49.058162, lng: 20.6270634 },
    { lat: 49.0580494, lng: 20.6268459 },
    { lat: 49.0579651, lng: 20.6263544 },
    { lat: 49.0578931, lng: 20.624949 },
    { lat: 49.0576707, lng: 20.624655 },
    { lat: 49.0580569, lng: 20.6242178 },
    { lat: 49.0582501, lng: 20.6242971 },
    { lat: 49.0582942, lng: 20.62418 },
    { lat: 49.0580526, lng: 20.624034 },
    { lat: 49.0580661, lng: 20.62382 },
    { lat: 49.0581206, lng: 20.62358 },
    { lat: 49.0583235, lng: 20.6235278 },
    { lat: 49.0583253, lng: 20.6233622 },
    { lat: 49.0584673, lng: 20.6232376 },
    { lat: 49.0587964, lng: 20.6231544 },
    { lat: 49.0590804, lng: 20.6233816 },
    { lat: 49.059232, lng: 20.6235627 },
    { lat: 49.0592878, lng: 20.6237361 },
    { lat: 49.0594331, lng: 20.6238262 },
    { lat: 49.0596249, lng: 20.6236838 },
    { lat: 49.0595091, lng: 20.6231612 },
    { lat: 49.0598451, lng: 20.6229202 },
    { lat: 49.0598813, lng: 20.6227732 },
    { lat: 49.0598026, lng: 20.6225867 },
    { lat: 49.0596973, lng: 20.6225733 },
    { lat: 49.0596294, lng: 20.6223442 },
    { lat: 49.0594615, lng: 20.6222157 },
    { lat: 49.0600965, lng: 20.6219669 },
    { lat: 49.0602589, lng: 20.6218184 },
    { lat: 49.0603381, lng: 20.621549 },
    { lat: 49.0601109, lng: 20.6207129 },
    { lat: 49.0600218, lng: 20.6208335 },
    { lat: 49.0595201, lng: 20.6211871 },
    { lat: 49.059197, lng: 20.6215296 },
    { lat: 49.059055, lng: 20.6215552 },
    { lat: 49.0589143, lng: 20.6214948 },
    { lat: 49.0584411, lng: 20.6209611 },
    { lat: 49.0583338, lng: 20.6207005 },
    { lat: 49.0584216, lng: 20.6203558 },
    { lat: 49.0580096, lng: 20.6195772 },
    { lat: 49.0577354, lng: 20.6198084 },
    { lat: 49.0573533, lng: 20.6203257 },
    { lat: 49.0572722, lng: 20.6203264 },
    { lat: 49.0569893, lng: 20.6206907 },
    { lat: 49.0565531, lng: 20.6210804 },
    { lat: 49.0561866, lng: 20.6209341 },
    { lat: 49.0546984, lng: 20.6234302 },
    { lat: 49.0540288, lng: 20.6242747 },
    { lat: 49.0536635, lng: 20.6248534 },
    { lat: 49.0532674, lng: 20.6256686 },
    { lat: 49.0529963, lng: 20.6274844 },
    { lat: 49.0529707, lng: 20.6280479 },
    { lat: 49.052571, lng: 20.6282356 },
    { lat: 49.052341, lng: 20.6280072 },
    { lat: 49.0521392, lng: 20.6273765 },
    { lat: 49.0517631, lng: 20.6266231 },
    { lat: 49.0515926, lng: 20.6268024 },
    { lat: 49.0514259, lng: 20.6271121 },
    { lat: 49.0510086, lng: 20.6268383 },
    { lat: 49.0510608, lng: 20.6264467 },
    { lat: 49.0513086, lng: 20.6260129 },
    { lat: 49.051225, lng: 20.6252855 },
    { lat: 49.0509939, lng: 20.624876 },
    { lat: 49.0509196, lng: 20.6248434 },
    { lat: 49.0507534, lng: 20.6255392 },
    { lat: 49.0504707, lng: 20.6261888 },
    { lat: 49.0500366, lng: 20.6256727 },
    { lat: 49.0497645, lng: 20.6252117 },
    { lat: 49.0492632, lng: 20.6248021 },
    { lat: 49.0491821, lng: 20.6250171 },
    { lat: 49.0489861, lng: 20.6249786 },
    { lat: 49.0482988, lng: 20.6243753 },
    { lat: 49.0465813, lng: 20.6232033 },
    { lat: 49.0458999, lng: 20.6229133 },
    { lat: 49.0458888, lng: 20.6229785 },
    { lat: 49.0457985, lng: 20.6229735 },
    { lat: 49.0457866, lng: 20.6230161 },
    { lat: 49.046062, lng: 20.6230986 },
    { lat: 49.0462113, lng: 20.6232465 },
    { lat: 49.0460587, lng: 20.6240689 },
    { lat: 49.045715, lng: 20.6240244 },
    { lat: 49.0455887, lng: 20.6240816 },
    { lat: 49.045529, lng: 20.6241451 },
    { lat: 49.0456368, lng: 20.6242312 },
    { lat: 49.0456469, lng: 20.6243193 },
    { lat: 49.0451961, lng: 20.6246201 },
    { lat: 49.0448492, lng: 20.6249896 },
    { lat: 49.0448692, lng: 20.6250734 },
    { lat: 49.0450255, lng: 20.6252275 },
    { lat: 49.0452329, lng: 20.6250789 },
    { lat: 49.0454854, lng: 20.6247459 },
    { lat: 49.0459996, lng: 20.6245177 },
    { lat: 49.0464535, lng: 20.6247719 },
    { lat: 49.0466712, lng: 20.624692 },
    { lat: 49.0465763, lng: 20.6250243 },
    { lat: 49.04641, lng: 20.6251283 },
    { lat: 49.0463025, lng: 20.6254577 },
    { lat: 49.0466905, lng: 20.6253826 },
    { lat: 49.0466869, lng: 20.6255634 },
    { lat: 49.0470968, lng: 20.6255693 },
    { lat: 49.0471408, lng: 20.6257548 },
    { lat: 49.047266, lng: 20.625791 },
    { lat: 49.047292, lng: 20.6259248 },
    { lat: 49.0471714, lng: 20.6261384 },
    { lat: 49.0472524, lng: 20.626226 },
    { lat: 49.0476985, lng: 20.6264223 },
    { lat: 49.0486839, lng: 20.6265599 },
    { lat: 49.0487495, lng: 20.6266571 },
    { lat: 49.048715, lng: 20.6267346 },
    { lat: 49.0488216, lng: 20.6275052 },
    { lat: 49.0488094, lng: 20.6276638 },
    { lat: 49.048698, lng: 20.6278848 },
    { lat: 49.0486478, lng: 20.628362 },
    { lat: 49.048817, lng: 20.6285026 },
    { lat: 49.0487721, lng: 20.6287533 },
    { lat: 49.0488889, lng: 20.6300549 },
    { lat: 49.0488679, lng: 20.6312521 },
    { lat: 49.0490765, lng: 20.6314442 },
    { lat: 49.0490132, lng: 20.6315658 },
    { lat: 49.0480801, lng: 20.6316606 },
    { lat: 49.0480148, lng: 20.6317263 },
    { lat: 49.0479691, lng: 20.6319566 },
    { lat: 49.0477821, lng: 20.6316395 },
    { lat: 49.047243, lng: 20.6314534 },
    { lat: 49.0469796, lng: 20.631456 },
    { lat: 49.0469257, lng: 20.6315527 },
    { lat: 49.0472306, lng: 20.6318302 },
    { lat: 49.0473962, lng: 20.6323909 },
    { lat: 49.0474197, lng: 20.6328268 },
    { lat: 49.0472805, lng: 20.6329313 },
    { lat: 49.0472102, lng: 20.6327628 },
    { lat: 49.0470983, lng: 20.6329688 },
    { lat: 49.046835, lng: 20.6325942 },
    { lat: 49.0467441, lng: 20.6326646 },
    { lat: 49.045968, lng: 20.6323134 },
    { lat: 49.0459503, lng: 20.6323508 },
    { lat: 49.046081, lng: 20.6325075 },
    { lat: 49.0466337, lng: 20.6327571 },
    { lat: 49.0468998, lng: 20.6329602 },
    { lat: 49.0469176, lng: 20.6331719 },
    { lat: 49.0467292, lng: 20.6334414 },
    { lat: 49.0464049, lng: 20.6335628 },
    { lat: 49.0463451, lng: 20.6334354 },
    { lat: 49.0462474, lng: 20.6334127 },
    { lat: 49.046175, lng: 20.6331844 },
    { lat: 49.045867, lng: 20.6335076 },
    { lat: 49.045499, lng: 20.6335401 },
    { lat: 49.0453226, lng: 20.6336732 },
    { lat: 49.0451931, lng: 20.6338308 },
    { lat: 49.0452159, lng: 20.6338992 },
    { lat: 49.0454125, lng: 20.6338319 },
    { lat: 49.0454608, lng: 20.6339793 },
    { lat: 49.0451305, lng: 20.6340912 },
    { lat: 49.0451839, lng: 20.6342959 },
    { lat: 49.0448067, lng: 20.6342639 },
    { lat: 49.0443657, lng: 20.6344234 },
    { lat: 49.0442709, lng: 20.6341029 },
    { lat: 49.0443038, lng: 20.6339882 },
    { lat: 49.0445736, lng: 20.6339819 },
    { lat: 49.0445371, lng: 20.6335324 },
    { lat: 49.0444893, lng: 20.6334478 },
    { lat: 49.0441076, lng: 20.6332316 },
    { lat: 49.0436858, lng: 20.6331143 },
    { lat: 49.0435107, lng: 20.6331336 },
    { lat: 49.0433343, lng: 20.6333295 },
    { lat: 49.0430995, lng: 20.6331834 },
    { lat: 49.0431142, lng: 20.6327153 },
    { lat: 49.0427613, lng: 20.6323292 },
    { lat: 49.0418672, lng: 20.6318519 },
    { lat: 49.0418483, lng: 20.6321108 },
    { lat: 49.0419086, lng: 20.6324373 },
    { lat: 49.0417116, lng: 20.6324107 },
    { lat: 49.0410804, lng: 20.6319477 },
    { lat: 49.0410757, lng: 20.6317913 },
    { lat: 49.0391766, lng: 20.6344755 },
    { lat: 49.0383561, lng: 20.6337628 },
    { lat: 49.0378827, lng: 20.6328411 },
    { lat: 49.0376908, lng: 20.6326368 },
    { lat: 49.0376652, lng: 20.6321499 },
    { lat: 49.0376985, lng: 20.6319799 },
    { lat: 49.0379303, lng: 20.6319114 },
    { lat: 49.0380432, lng: 20.6316404 },
    { lat: 49.0379978, lng: 20.6313496 },
    { lat: 49.0367479, lng: 20.6310944 },
    { lat: 49.0363737, lng: 20.6309219 },
    { lat: 49.0356711, lng: 20.6301729 },
    { lat: 49.0355753, lng: 20.6303563 },
    { lat: 49.0348924, lng: 20.6294949 },
    { lat: 49.0348055, lng: 20.629585 },
    { lat: 49.0345326, lng: 20.6288866 },
    { lat: 49.0342335, lng: 20.6283311 },
    { lat: 49.0339963, lng: 20.628249 },
    { lat: 49.0337621, lng: 20.6283106 },
    { lat: 49.0332411, lng: 20.6279724 },
    { lat: 49.032812, lng: 20.6284054 },
    { lat: 49.0325494, lng: 20.6288284 },
    { lat: 49.0321707, lng: 20.6284148 },
    { lat: 49.0319952, lng: 20.6278544 },
    { lat: 49.0319636, lng: 20.6275391 },
    { lat: 49.0320104, lng: 20.6272486 },
    { lat: 49.0321308, lng: 20.6269637 },
    { lat: 49.0318631, lng: 20.625758 },
    { lat: 49.0316658, lng: 20.6254355 },
    { lat: 49.0313663, lng: 20.62519 },
    { lat: 49.0312046, lng: 20.6247952 },
    { lat: 49.030642, lng: 20.6243658 },
    { lat: 49.0303195, lng: 20.6240061 },
    { lat: 49.0303408, lng: 20.6238177 },
    { lat: 49.0304457, lng: 20.6237986 },
    { lat: 49.0306948, lng: 20.6238958 },
    { lat: 49.0307136, lng: 20.6236068 },
    { lat: 49.030599, lng: 20.6227396 },
    { lat: 49.0303542, lng: 20.6224483 },
    { lat: 49.0302688, lng: 20.6222004 },
    { lat: 49.0303217, lng: 20.622013 },
    { lat: 49.0303146, lng: 20.6217887 },
    { lat: 49.0315725, lng: 20.6196191 },
    { lat: 49.0316006, lng: 20.6195568 },
    { lat: 49.0315517, lng: 20.619449 },
    { lat: 49.0316438, lng: 20.6194122 },
    { lat: 49.0314893, lng: 20.6190033 },
    { lat: 49.0313564, lng: 20.6188773 },
    { lat: 49.0312839, lng: 20.6188619 },
    { lat: 49.0310865, lng: 20.6189814 },
    { lat: 49.0309063, lng: 20.6188842 },
    { lat: 49.0308037, lng: 20.6186635 },
    { lat: 49.030471, lng: 20.6186139 },
    { lat: 49.030373, lng: 20.6184237 },
    { lat: 49.0300764, lng: 20.6181611 },
    { lat: 49.0298467, lng: 20.6182209 },
    { lat: 49.0296839, lng: 20.6184218 },
    { lat: 49.0293364, lng: 20.6183038 },
    { lat: 49.0291407, lng: 20.6184052 },
    { lat: 49.0288019, lng: 20.6181889 },
    { lat: 49.0285165, lng: 20.6183599 },
    { lat: 49.0276858, lng: 20.6184876 },
    { lat: 49.0274133, lng: 20.6183303 },
    { lat: 49.0270094, lng: 20.6186497 },
    { lat: 49.026876, lng: 20.6185084 },
    { lat: 49.0267683, lng: 20.6184872 },
    { lat: 49.0266032, lng: 20.6185922 },
    { lat: 49.0262759, lng: 20.6184797 },
    { lat: 49.0259937, lng: 20.6186708 },
    { lat: 49.0257261, lng: 20.6184626 },
    { lat: 49.02561, lng: 20.6185846 },
    { lat: 49.0255354, lng: 20.6185442 },
    { lat: 49.0253035, lng: 20.6182637 },
    { lat: 49.0252239, lng: 20.6179938 },
    { lat: 49.0250855, lng: 20.6179057 },
    { lat: 49.0249153, lng: 20.6181165 },
    { lat: 49.0247893, lng: 20.6184621 },
    { lat: 49.0246844, lng: 20.6185817 },
    { lat: 49.024511, lng: 20.6185808 },
    { lat: 49.0244511, lng: 20.618697 },
    { lat: 49.0243209, lng: 20.6187703 },
    { lat: 49.0241286, lng: 20.6184639 },
    { lat: 49.0238394, lng: 20.6185947 },
    { lat: 49.0235037, lng: 20.6190181 },
    { lat: 49.0231925, lng: 20.6191001 },
    { lat: 49.023012, lng: 20.619217 },
    { lat: 49.0228407, lng: 20.6195815 },
    { lat: 49.0227734, lng: 20.6198411 },
    { lat: 49.0226143, lng: 20.6199814 },
    { lat: 49.0220758, lng: 20.620068 },
    { lat: 49.0217564, lng: 20.6203885 },
    { lat: 49.0214775, lng: 20.6203914 },
    { lat: 49.0211312, lng: 20.6207192 },
    { lat: 49.0210169, lng: 20.6207352 },
    { lat: 49.0208476, lng: 20.6206256 },
    { lat: 49.0207328, lng: 20.6203468 },
    { lat: 49.0206358, lng: 20.6202828 },
    { lat: 49.0204655, lng: 20.6203925 },
    { lat: 49.020359, lng: 20.6202856 },
    { lat: 49.0202814, lng: 20.6202852 },
    { lat: 49.0201925, lng: 20.6203777 },
    { lat: 49.0201505, lng: 20.6206204 },
    { lat: 49.020092, lng: 20.6206512 },
    { lat: 49.01988, lng: 20.6205502 },
    { lat: 49.0194172, lng: 20.6204859 },
    { lat: 49.0189499, lng: 20.6200437 },
    { lat: 49.0188449, lng: 20.6200044 },
    { lat: 49.0185387, lng: 20.6200684 },
    { lat: 49.0182511, lng: 20.6205846 },
    { lat: 49.0180968, lng: 20.6206093 },
    { lat: 49.0177745, lng: 20.6205086 },
    { lat: 49.0174856, lng: 20.6207727 },
    { lat: 49.0173108, lng: 20.621137 },
    { lat: 49.0172625, lng: 20.6216037 },
    { lat: 49.0166979, lng: 20.6219343 },
    { lat: 49.0166731, lng: 20.6223033 },
    { lat: 49.0165154, lng: 20.6228297 },
    { lat: 49.0164378, lng: 20.6229276 },
    { lat: 49.0160626, lng: 20.6229773 },
    { lat: 49.0160501, lng: 20.623035 },
    { lat: 49.0162227, lng: 20.6235145 },
    { lat: 49.0161996, lng: 20.623685 },
    { lat: 49.0161349, lng: 20.6237352 },
    { lat: 49.0161435, lng: 20.623751 },
  ],
  Nemešany: [
    { lat: 48.9907776, lng: 20.6931107 },
    { lat: 48.9907502, lng: 20.6931946 },
    { lat: 48.9905696, lng: 20.6942601 },
    { lat: 48.9905092, lng: 20.6952189 },
    { lat: 48.990569, lng: 20.6960782 },
    { lat: 48.9905124, lng: 20.6967037 },
    { lat: 48.9907137, lng: 20.696665 },
    { lat: 48.9911681, lng: 20.6968133 },
    { lat: 48.9941677, lng: 20.6968929 },
    { lat: 48.9942256, lng: 20.696967 },
    { lat: 48.9940214, lng: 20.6974355 },
    { lat: 48.9939054, lng: 20.6979081 },
    { lat: 48.9938022, lng: 20.6990587 },
    { lat: 48.9938433, lng: 20.699545 },
    { lat: 48.9937951, lng: 20.6998415 },
    { lat: 48.9936159, lng: 20.7004815 },
    { lat: 48.9935097, lng: 20.7006779 },
    { lat: 48.9928713, lng: 20.7018128 },
    { lat: 48.9926938, lng: 20.7019815 },
    { lat: 48.9924128, lng: 20.7025057 },
    { lat: 48.9925051, lng: 20.702787 },
    { lat: 48.9926859, lng: 20.7031884 },
    { lat: 48.9926835, lng: 20.7033743 },
    { lat: 48.9926788, lng: 20.7035263 },
    { lat: 48.9947731, lng: 20.7021465 },
    { lat: 48.9957277, lng: 20.7020518 },
    { lat: 48.9965627, lng: 20.7017144 },
    { lat: 48.9967954, lng: 20.7016204 },
    { lat: 48.9981869, lng: 20.7008029 },
    { lat: 48.9990036, lng: 20.7001799 },
    { lat: 49.0018078, lng: 20.6976796 },
    { lat: 49.0027357, lng: 20.696616 },
    { lat: 49.0031717, lng: 20.6959875 },
    { lat: 49.0035472, lng: 20.6955609 },
    { lat: 49.0048854, lng: 20.6935908 },
    { lat: 49.0064078, lng: 20.6909162 },
    { lat: 49.0066717, lng: 20.6912807 },
    { lat: 49.0067729, lng: 20.6920361 },
    { lat: 49.0068055, lng: 20.6932375 },
    { lat: 49.007688, lng: 20.6923155 },
    { lat: 49.0082199, lng: 20.6918562 },
    { lat: 49.0096411, lng: 20.6906704 },
    { lat: 49.0108268, lng: 20.689856 },
    { lat: 49.0111491, lng: 20.6896739 },
    { lat: 49.0130279, lng: 20.6889495 },
    { lat: 49.0132925, lng: 20.688794 },
    { lat: 49.0142029, lng: 20.6881212 },
    { lat: 49.0156816, lng: 20.6868709 },
    { lat: 49.0163725, lng: 20.6865145 },
    { lat: 49.0173177, lng: 20.6865059 },
    { lat: 49.0177255, lng: 20.6864175 },
    { lat: 49.018607, lng: 20.6859149 },
    { lat: 49.018644, lng: 20.6852182 },
    { lat: 49.0187549, lng: 20.6845387 },
    { lat: 49.0187389, lng: 20.6840888 },
    { lat: 49.0188632, lng: 20.6828696 },
    { lat: 49.0191622, lng: 20.681722 },
    { lat: 49.0193856, lng: 20.6816103 },
    { lat: 49.0208536, lng: 20.6796224 },
    { lat: 49.0216546, lng: 20.6779071 },
    { lat: 49.0219608, lng: 20.6765451 },
    { lat: 49.0228698, lng: 20.675843 },
    { lat: 49.0235191, lng: 20.6754218 },
    { lat: 49.0243577, lng: 20.674695 },
    { lat: 49.0250388, lng: 20.6738741 },
    { lat: 49.026119, lng: 20.672572 },
    { lat: 49.0275888, lng: 20.6711455 },
    { lat: 49.0282629, lng: 20.6705679 },
    { lat: 49.0286432, lng: 20.6704704 },
    { lat: 49.0295645, lng: 20.669638 },
    { lat: 49.0313638, lng: 20.6682878 },
    { lat: 49.0320851, lng: 20.6673366 },
    { lat: 49.0323551, lng: 20.6661063 },
    { lat: 49.0330011, lng: 20.6649705 },
    { lat: 49.0363112, lng: 20.6615599 },
    { lat: 49.0376277, lng: 20.6608473 },
    { lat: 49.0381769, lng: 20.6599647 },
    { lat: 49.038163, lng: 20.6599515 },
    { lat: 49.0367577, lng: 20.6602854 },
    { lat: 49.0361965, lng: 20.6601156 },
    { lat: 49.0354875, lng: 20.6602408 },
    { lat: 49.0352443, lng: 20.6601319 },
    { lat: 49.0345505, lng: 20.6607041 },
    { lat: 49.0292509, lng: 20.6656512 },
    { lat: 49.0276974, lng: 20.6668487 },
    { lat: 49.0250345, lng: 20.6685584 },
    { lat: 49.0236455, lng: 20.6699815 },
    { lat: 49.0216234, lng: 20.6712035 },
    { lat: 49.0197438, lng: 20.6717789 },
    { lat: 49.0181023, lng: 20.6716817 },
    { lat: 49.0143084, lng: 20.6726526 },
    { lat: 49.0133941, lng: 20.6735591 },
    { lat: 49.013071, lng: 20.6737195 },
    { lat: 49.0126166, lng: 20.6737305 },
    { lat: 49.0121688, lng: 20.6738607 },
    { lat: 49.0113556, lng: 20.6743198 },
    { lat: 49.0098989, lng: 20.6753305 },
    { lat: 49.0094699, lng: 20.6757349 },
    { lat: 49.0065325, lng: 20.6775497 },
    { lat: 49.0029505, lng: 20.6796358 },
    { lat: 49.0011661, lng: 20.6804853 },
    { lat: 48.9998729, lng: 20.6808403 },
    { lat: 48.9993138, lng: 20.6812901 },
    { lat: 48.9976051, lng: 20.6831593 },
    { lat: 48.9970984, lng: 20.6839453 },
    { lat: 48.9956752, lng: 20.6868437 },
    { lat: 48.9946947, lng: 20.6890835 },
    { lat: 48.9935967, lng: 20.6917294 },
    { lat: 48.992498, lng: 20.6947007 },
    { lat: 48.9910669, lng: 20.6933661 },
    { lat: 48.9907776, lng: 20.6931107 },
  ],
  GrančPetrovce: [
    { lat: 49.0044792, lng: 20.806012 },
    { lat: 49.0045179, lng: 20.8056995 },
    { lat: 49.0045487, lng: 20.8050893 },
    { lat: 49.0047236, lng: 20.805004 },
    { lat: 49.0049711, lng: 20.8033113 },
    { lat: 49.0053715, lng: 20.8014805 },
    { lat: 49.0056717, lng: 20.8003705 },
    { lat: 49.005834, lng: 20.7999832 },
    { lat: 49.006228, lng: 20.799203 },
    { lat: 49.0069221, lng: 20.7982188 },
    { lat: 49.0075701, lng: 20.7975265 },
    { lat: 49.0086548, lng: 20.7967017 },
    { lat: 49.0082739, lng: 20.7955536 },
    { lat: 49.0079919, lng: 20.7953113 },
    { lat: 49.0078574, lng: 20.7950994 },
    { lat: 49.0078084, lng: 20.7942367 },
    { lat: 49.0076354, lng: 20.7930186 },
    { lat: 49.0074681, lng: 20.7925734 },
    { lat: 49.0072543, lng: 20.7914421 },
    { lat: 49.0070961, lng: 20.7915197 },
    { lat: 49.0062701, lng: 20.7918549 },
    { lat: 49.0052383, lng: 20.7919958 },
    { lat: 49.004226, lng: 20.7919149 },
    { lat: 49.0023082, lng: 20.791892 },
    { lat: 49.0016624, lng: 20.7916771 },
    { lat: 49.0011597, lng: 20.7913966 },
    { lat: 49.0009718, lng: 20.7911754 },
    { lat: 49.0007356, lng: 20.7906903 },
    { lat: 49.0005224, lng: 20.7905156 },
    { lat: 48.9992246, lng: 20.7901272 },
    { lat: 48.998524, lng: 20.789778 },
    { lat: 48.998338, lng: 20.789773 },
    { lat: 48.998059, lng: 20.789586 },
    { lat: 48.997931, lng: 20.789549 },
    { lat: 48.997839, lng: 20.789611 },
    { lat: 48.997773, lng: 20.789603 },
    { lat: 48.997635, lng: 20.789515 },
    { lat: 48.997532, lng: 20.789405 },
    { lat: 48.997483, lng: 20.789377 },
    { lat: 48.997117, lng: 20.790675 },
    { lat: 48.996946, lng: 20.791055 },
    { lat: 48.996932, lng: 20.791095 },
    { lat: 48.996823, lng: 20.791425 },
    { lat: 48.996649, lng: 20.791912 },
    { lat: 48.996339, lng: 20.792647 },
    { lat: 48.996039, lng: 20.79334 },
    { lat: 48.99584, lng: 20.793765 },
    { lat: 48.995581, lng: 20.794307 },
    { lat: 48.995446, lng: 20.794604 },
    { lat: 48.995207, lng: 20.795131 },
    { lat: 48.994986, lng: 20.795586 },
    { lat: 48.994848, lng: 20.795947 },
    { lat: 48.99475, lng: 20.796276 },
    { lat: 48.99456, lng: 20.796787 },
    { lat: 48.994342, lng: 20.797335 },
    { lat: 48.994175, lng: 20.797807 },
    { lat: 48.994096, lng: 20.798026 },
    { lat: 48.993774, lng: 20.798812 },
    { lat: 48.993764, lng: 20.798834 },
    { lat: 48.993661, lng: 20.799097 },
    { lat: 48.993596, lng: 20.79934 },
    { lat: 48.993535, lng: 20.799576 },
    { lat: 48.993509, lng: 20.799673 },
    { lat: 48.993459, lng: 20.799799 },
    { lat: 48.99347, lng: 20.799843 },
    { lat: 48.993332, lng: 20.799803 },
    { lat: 48.993231, lng: 20.799704 },
    { lat: 48.993211, lng: 20.799551 },
    { lat: 48.993189, lng: 20.799513 },
    { lat: 48.993131, lng: 20.799488 },
    { lat: 48.993071, lng: 20.799492 },
    { lat: 48.992931, lng: 20.79939 },
    { lat: 48.992825, lng: 20.7994 },
    { lat: 48.992662, lng: 20.799345 },
    { lat: 48.992617, lng: 20.799287 },
    { lat: 48.992583, lng: 20.79927 },
    { lat: 48.992568, lng: 20.799285 },
    { lat: 48.992397, lng: 20.799302 },
    { lat: 48.992174, lng: 20.799248 },
    { lat: 48.992077, lng: 20.799181 },
    { lat: 48.99196, lng: 20.799175 },
    { lat: 48.99189, lng: 20.799127 },
    { lat: 48.991839, lng: 20.799003 },
    { lat: 48.991797, lng: 20.798942 },
    { lat: 48.991731, lng: 20.798896 },
    { lat: 48.991667, lng: 20.798766 },
    { lat: 48.991647, lng: 20.798782 },
    { lat: 48.991544, lng: 20.798794 },
    { lat: 48.991472, lng: 20.798773 },
    { lat: 48.991364, lng: 20.798754 },
    { lat: 48.991044, lng: 20.798625 },
    { lat: 48.991085, lng: 20.798896 },
    { lat: 48.991087, lng: 20.799054 },
    { lat: 48.991078, lng: 20.799224 },
    { lat: 48.990941, lng: 20.799811 },
    { lat: 48.990907, lng: 20.799947 },
    { lat: 48.990778, lng: 20.800384 },
    { lat: 48.990682, lng: 20.800678 },
    { lat: 48.990504, lng: 20.801212 },
    { lat: 48.9904, lng: 20.801558 },
    { lat: 48.990241, lng: 20.802144 },
    { lat: 48.990041, lng: 20.802796 },
    { lat: 48.989844, lng: 20.803419 },
    { lat: 48.989662, lng: 20.804093 },
    { lat: 48.989416, lng: 20.805066 },
    { lat: 48.989292, lng: 20.805453 },
    { lat: 48.989195, lng: 20.805837 },
    { lat: 48.989074, lng: 20.806249 },
    { lat: 48.989011, lng: 20.806441 },
    { lat: 48.988939, lng: 20.806641 },
    { lat: 48.988851, lng: 20.80709 },
    { lat: 48.988819, lng: 20.807576 },
    { lat: 48.988811, lng: 20.808104 },
    { lat: 48.988819, lng: 20.808646 },
    { lat: 48.988765, lng: 20.809198 },
    { lat: 48.988697, lng: 20.809722 },
    { lat: 48.988531, lng: 20.810278 },
    { lat: 48.988411, lng: 20.81067 },
    { lat: 48.98832, lng: 20.810941 },
    { lat: 48.988069, lng: 20.811638 },
    { lat: 48.987804, lng: 20.812612 },
    { lat: 48.98774, lng: 20.813083 },
    { lat: 48.987716, lng: 20.813866 },
    { lat: 48.987552, lng: 20.815756 },
    { lat: 48.987523, lng: 20.815845 },
    { lat: 48.987056, lng: 20.814949 },
    { lat: 48.987043, lng: 20.814927 },
    { lat: 48.98698, lng: 20.815054 },
    { lat: 48.98694, lng: 20.815175 },
    { lat: 48.986922, lng: 20.815274 },
    { lat: 48.986911, lng: 20.815379 },
    { lat: 48.986873, lng: 20.815774 },
    { lat: 48.98682, lng: 20.81594 },
    { lat: 48.986806, lng: 20.81605 },
    { lat: 48.986979, lng: 20.816138 },
    { lat: 48.987013, lng: 20.816189 },
    { lat: 48.987005, lng: 20.816335 },
    { lat: 48.98714, lng: 20.816853 },
    { lat: 48.987115, lng: 20.81696 },
    { lat: 48.986894, lng: 20.817257 },
    { lat: 48.986864, lng: 20.817363 },
    { lat: 48.986868, lng: 20.817435 },
    { lat: 48.986898, lng: 20.817535 },
    { lat: 48.986919, lng: 20.817598 },
    { lat: 48.986911, lng: 20.817652 },
    { lat: 48.986801, lng: 20.81784 },
    { lat: 48.986769, lng: 20.817974 },
    { lat: 48.986814, lng: 20.818173 },
    { lat: 48.986775, lng: 20.81844 },
    { lat: 48.986804, lng: 20.818595 },
    { lat: 48.986908, lng: 20.818711 },
    { lat: 48.98696, lng: 20.818886 },
    { lat: 48.986886, lng: 20.819512 },
    { lat: 48.986901, lng: 20.819741 },
    { lat: 48.986864, lng: 20.820098 },
    { lat: 48.986869, lng: 20.820097 },
    { lat: 48.986839, lng: 20.820353 },
    { lat: 48.986866, lng: 20.820698 },
    { lat: 48.98698, lng: 20.821099 },
    { lat: 48.9879018, lng: 20.8213884 },
    { lat: 48.9891967, lng: 20.8214283 },
    { lat: 48.9909754, lng: 20.8217011 },
    { lat: 48.9950751, lng: 20.821932 },
    { lat: 48.9967183, lng: 20.8221887 },
    { lat: 48.9998281, lng: 20.8224097 },
    { lat: 48.9989157, lng: 20.8196084 },
    { lat: 48.9988541, lng: 20.8191989 },
    { lat: 48.9990567, lng: 20.8163253 },
    { lat: 48.9993894, lng: 20.8133455 },
    { lat: 48.9994568, lng: 20.8129189 },
    { lat: 48.9995883, lng: 20.812617 },
    { lat: 48.9997738, lng: 20.8118956 },
    { lat: 49.0000035, lng: 20.8101386 },
    { lat: 49.000032, lng: 20.8094634 },
    { lat: 49.0001775, lng: 20.8085276 },
    { lat: 49.0005004, lng: 20.8077414 },
    { lat: 49.0006945, lng: 20.8070408 },
    { lat: 49.0006475, lng: 20.8069826 },
    { lat: 49.0009457, lng: 20.8065275 },
    { lat: 49.0011397, lng: 20.8064073 },
    { lat: 49.0013955, lng: 20.8064471 },
    { lat: 49.0024932, lng: 20.8063561 },
    { lat: 49.0044792, lng: 20.806012 },
  ],
  Torysky: [
    { lat: 49.0830131, lng: 20.663039 },
    { lat: 49.0829067, lng: 20.663269 },
    { lat: 49.0825072, lng: 20.6638131 },
    { lat: 49.08169, lng: 20.6647553 },
    { lat: 49.0818391, lng: 20.6651164 },
    { lat: 49.0819606, lng: 20.6657786 },
    { lat: 49.0823023, lng: 20.6666793 },
    { lat: 49.0822464, lng: 20.6669088 },
    { lat: 49.0812994, lng: 20.6670256 },
    { lat: 49.0811124, lng: 20.6669873 },
    { lat: 49.0808414, lng: 20.6671523 },
    { lat: 49.0807191, lng: 20.6670701 },
    { lat: 49.0804353, lng: 20.6672559 },
    { lat: 49.0797667, lng: 20.6670409 },
    { lat: 49.0794455, lng: 20.6670905 },
    { lat: 49.079324, lng: 20.6671893 },
    { lat: 49.0792261, lng: 20.6671898 },
    { lat: 49.0787966, lng: 20.6676466 },
    { lat: 49.0786581, lng: 20.6676771 },
    { lat: 49.0785461, lng: 20.6678501 },
    { lat: 49.078478, lng: 20.6680511 },
    { lat: 49.0785115, lng: 20.6682027 },
    { lat: 49.078358, lng: 20.6685422 },
    { lat: 49.0784972, lng: 20.6687196 },
    { lat: 49.0784716, lng: 20.6689738 },
    { lat: 49.0785613, lng: 20.6694113 },
    { lat: 49.0787636, lng: 20.6695888 },
    { lat: 49.0788752, lng: 20.6699386 },
    { lat: 49.0792591, lng: 20.670508 },
    { lat: 49.0792855, lng: 20.6706458 },
    { lat: 49.0794335, lng: 20.6706957 },
    { lat: 49.0796344, lng: 20.6709308 },
    { lat: 49.0805042, lng: 20.6725265 },
    { lat: 49.0811251, lng: 20.673332 },
    { lat: 49.0816017, lng: 20.6746704 },
    { lat: 49.0815479, lng: 20.6747119 },
    { lat: 49.0818658, lng: 20.675416 },
    { lat: 49.0820754, lng: 20.675677 },
    { lat: 49.0824637, lng: 20.6763326 },
    { lat: 49.0826112, lng: 20.6769033 },
    { lat: 49.0830076, lng: 20.6779202 },
    { lat: 49.0832788, lng: 20.6791464 },
    { lat: 49.0833679, lng: 20.6798462 },
    { lat: 49.0833984, lng: 20.6806434 },
    { lat: 49.0835693, lng: 20.6817056 },
    { lat: 49.0845613, lng: 20.6839613 },
    { lat: 49.085881, lng: 20.6860586 },
    { lat: 49.0863993, lng: 20.6866705 },
    { lat: 49.087028, lng: 20.6872892 },
    { lat: 49.0870852, lng: 20.6874124 },
    { lat: 49.0871605, lng: 20.6875056 },
    { lat: 49.0892425, lng: 20.6897968 },
    { lat: 49.0900658, lng: 20.691617 },
    { lat: 49.090645, lng: 20.6918585 },
    { lat: 49.0908834, lng: 20.6918173 },
    { lat: 49.0911336, lng: 20.6916677 },
    { lat: 49.0914769, lng: 20.6919178 },
    { lat: 49.0916626, lng: 20.692363 },
    { lat: 49.0923177, lng: 20.6925838 },
    { lat: 49.0925693, lng: 20.692761 },
    { lat: 49.0931176, lng: 20.6929504 },
    { lat: 49.0936815, lng: 20.6935182 },
    { lat: 49.093827, lng: 20.6933033 },
    { lat: 49.0941131, lng: 20.693848 },
    { lat: 49.0943291, lng: 20.6936524 },
    { lat: 49.0948314, lng: 20.6947066 },
    { lat: 49.0952089, lng: 20.6951328 },
    { lat: 49.0955948, lng: 20.6958593 },
    { lat: 49.0957892, lng: 20.6960984 },
    { lat: 49.0964755, lng: 20.6965994 },
    { lat: 49.0967669, lng: 20.6967231 },
    { lat: 49.0975424, lng: 20.6973842 },
    { lat: 49.0981995, lng: 20.6975011 },
    { lat: 49.0987899, lng: 20.6980855 },
    { lat: 49.1006886, lng: 20.6990333 },
    { lat: 49.1020086, lng: 20.7000959 },
    { lat: 49.1022341, lng: 20.7008081 },
    { lat: 49.1027915, lng: 20.7009742 },
    { lat: 49.1031871, lng: 20.7010164 },
    { lat: 49.1034807, lng: 20.7016461 },
    { lat: 49.1037488, lng: 20.7013705 },
    { lat: 49.1053242, lng: 20.7032256 },
    { lat: 49.1061365, lng: 20.7041012 },
    { lat: 49.106854, lng: 20.7041479 },
    { lat: 49.1067854, lng: 20.7044342 },
    { lat: 49.1086436, lng: 20.7052843 },
    { lat: 49.1100744, lng: 20.7063091 },
    { lat: 49.1103269, lng: 20.7066322 },
    { lat: 49.1111979, lng: 20.7064357 },
    { lat: 49.1123961, lng: 20.7065805 },
    { lat: 49.1136438, lng: 20.7077031 },
    { lat: 49.1156389, lng: 20.70975 },
    { lat: 49.1176547, lng: 20.7114037 },
    { lat: 49.1195496, lng: 20.7096645 },
    { lat: 49.1196397, lng: 20.7095968 },
    { lat: 49.1196504, lng: 20.7095801 },
    { lat: 49.1198988, lng: 20.7091939 },
    { lat: 49.1200747, lng: 20.7089128 },
    { lat: 49.1201886, lng: 20.7087313 },
    { lat: 49.1203272, lng: 20.7086006 },
    { lat: 49.1209884, lng: 20.70798 },
    { lat: 49.1213549, lng: 20.7076185 },
    { lat: 49.1222218, lng: 20.7067607 },
    { lat: 49.1232309, lng: 20.705761 },
    { lat: 49.1235323, lng: 20.7054642 },
    { lat: 49.1238555, lng: 20.7051454 },
    { lat: 49.1239413, lng: 20.7049652 },
    { lat: 49.1242345, lng: 20.7043448 },
    { lat: 49.124245, lng: 20.7043224 },
    { lat: 49.1244893, lng: 20.7038124 },
    { lat: 49.1246841, lng: 20.7034047 },
    { lat: 49.1252344, lng: 20.7022507 },
    { lat: 49.1253148, lng: 20.7020766 },
    { lat: 49.1254205, lng: 20.7018512 },
    { lat: 49.125567, lng: 20.7015397 },
    { lat: 49.1256289, lng: 20.7014099 },
    { lat: 49.1257136, lng: 20.7012283 },
    { lat: 49.1257879, lng: 20.7010691 },
    { lat: 49.1258592, lng: 20.7009176 },
    { lat: 49.1262336, lng: 20.7001137 },
    { lat: 49.126315, lng: 20.6999345 },
    { lat: 49.1264721, lng: 20.699601 },
    { lat: 49.1265494, lng: 20.6994347 },
    { lat: 49.1267509, lng: 20.6990145 },
    { lat: 49.1267676, lng: 20.6989797 },
    { lat: 49.1269726, lng: 20.6985419 },
    { lat: 49.1271318, lng: 20.6982036 },
    { lat: 49.1274908, lng: 20.6974426 },
    { lat: 49.1277515, lng: 20.6968848 },
    { lat: 49.1279724, lng: 20.6964174 },
    { lat: 49.1282142, lng: 20.695905 },
    { lat: 49.1283996, lng: 20.6955127 },
    { lat: 49.1285954, lng: 20.6950991 },
    { lat: 49.1287302, lng: 20.6948029 },
    { lat: 49.1288253, lng: 20.6945981 },
    { lat: 49.128955, lng: 20.6943175 },
    { lat: 49.1290113, lng: 20.6941964 },
    { lat: 49.1291953, lng: 20.6937988 },
    { lat: 49.1292854, lng: 20.6936059 },
    { lat: 49.1295278, lng: 20.6930784 },
    { lat: 49.1296428, lng: 20.6928295 },
    { lat: 49.12981, lng: 20.6924689 },
    { lat: 49.1299001, lng: 20.6922749 },
    { lat: 49.1299652, lng: 20.6921419 },
    { lat: 49.1301108, lng: 20.6918395 },
    { lat: 49.1303123, lng: 20.6914289 },
    { lat: 49.1305842, lng: 20.6908745 },
    { lat: 49.1308475, lng: 20.6903298 },
    { lat: 49.1311487, lng: 20.6897133 },
    { lat: 49.1314981, lng: 20.6889965 },
    { lat: 49.1318357, lng: 20.6883008 },
    { lat: 49.1320058, lng: 20.6879546 },
    { lat: 49.1322135, lng: 20.6875291 },
    { lat: 49.1325309, lng: 20.6868623 },
    { lat: 49.1329052, lng: 20.6860834 },
    { lat: 49.1333391, lng: 20.6851759 },
    { lat: 49.1334174, lng: 20.6850116 },
    { lat: 49.1334931, lng: 20.6848569 },
    { lat: 49.1335538, lng: 20.6847257 },
    { lat: 49.1335757, lng: 20.6846782 },
    { lat: 49.1336764, lng: 20.6844718 },
    { lat: 49.1338288, lng: 20.684135 },
    { lat: 49.1341651, lng: 20.6834303 },
    { lat: 49.1343052, lng: 20.6831322 },
    { lat: 49.1350696, lng: 20.6815168 },
    { lat: 49.1357419, lng: 20.6800999 },
    { lat: 49.1358427, lng: 20.6798967 },
    { lat: 49.1366063, lng: 20.6785182 },
    { lat: 49.136725, lng: 20.6783081 },
    { lat: 49.1373025, lng: 20.6772357 },
    { lat: 49.1373384, lng: 20.6771386 },
    { lat: 49.1374737, lng: 20.6767646 },
    { lat: 49.1375764, lng: 20.676488 },
    { lat: 49.1377941, lng: 20.6761173 },
    { lat: 49.1381791, lng: 20.6754548 },
    { lat: 49.1387232, lng: 20.6745196 },
    { lat: 49.1390149, lng: 20.6740202 },
    { lat: 49.1391934, lng: 20.6737218 },
    { lat: 49.1385446, lng: 20.67295 },
    { lat: 49.1381913, lng: 20.6724217 },
    { lat: 49.1377108, lng: 20.6719528 },
    { lat: 49.1374465, lng: 20.6718312 },
    { lat: 49.1359702, lng: 20.670174 },
    { lat: 49.1349322, lng: 20.6679984 },
    { lat: 49.1344782, lng: 20.6672923 },
    { lat: 49.1347325, lng: 20.6666576 },
    { lat: 49.1349035, lng: 20.6649872 },
    { lat: 49.1350372, lng: 20.6648535 },
    { lat: 49.1349072, lng: 20.6632628 },
    { lat: 49.1328013, lng: 20.6608752 },
    { lat: 49.1314122, lng: 20.6586759 },
    { lat: 49.13064, lng: 20.6579805 },
    { lat: 49.129231, lng: 20.6590918 },
    { lat: 49.1283481, lng: 20.6596483 },
    { lat: 49.1246821, lng: 20.6602608 },
    { lat: 49.1240901, lng: 20.6598553 },
    { lat: 49.122513, lng: 20.6604556 },
    { lat: 49.1213012, lng: 20.6610033 },
    { lat: 49.1184562, lng: 20.6572795 },
    { lat: 49.1175311, lng: 20.6572544 },
    { lat: 49.1162008, lng: 20.657049 },
    { lat: 49.1161405, lng: 20.6568395 },
    { lat: 49.1140373, lng: 20.6565431 },
    { lat: 49.111678, lng: 20.6554797 },
    { lat: 49.1109867, lng: 20.6546906 },
    { lat: 49.1106841, lng: 20.6542591 },
    { lat: 49.1094567, lng: 20.6544344 },
    { lat: 49.1078341, lng: 20.6539585 },
    { lat: 49.1075432, lng: 20.6531268 },
    { lat: 49.1066687, lng: 20.6524084 },
    { lat: 49.1063081, lng: 20.6529949 },
    { lat: 49.1062903, lng: 20.6531577 },
    { lat: 49.1061121, lng: 20.6534305 },
    { lat: 49.1058899, lng: 20.6539836 },
    { lat: 49.1053267, lng: 20.6547565 },
    { lat: 49.1050246, lng: 20.6550949 },
    { lat: 49.1045472, lng: 20.6553085 },
    { lat: 49.1040661, lng: 20.6561816 },
    { lat: 49.103998, lng: 20.6564776 },
    { lat: 49.1040574, lng: 20.6566296 },
    { lat: 49.1035614, lng: 20.6571444 },
    { lat: 49.1031029, lng: 20.6574497 },
    { lat: 49.1029587, lng: 20.6579496 },
    { lat: 49.1027918, lng: 20.6580048 },
    { lat: 49.1028028, lng: 20.6584034 },
    { lat: 49.1026863, lng: 20.658703 },
    { lat: 49.1026211, lng: 20.6590493 },
    { lat: 49.1023579, lng: 20.6591215 },
    { lat: 49.1016798, lng: 20.6626334 },
    { lat: 49.0994639, lng: 20.6608526 },
    { lat: 49.0969422, lng: 20.6593437 },
    { lat: 49.0968285, lng: 20.6569201 },
    { lat: 49.0967364, lng: 20.6564547 },
    { lat: 49.0964876, lng: 20.6564247 },
    { lat: 49.0962592, lng: 20.6550793 },
    { lat: 49.0962224, lng: 20.654304 },
    { lat: 49.0959645, lng: 20.6540463 },
    { lat: 49.0960648, lng: 20.6536435 },
    { lat: 49.0959021, lng: 20.6533435 },
    { lat: 49.0956641, lng: 20.6525414 },
    { lat: 49.0950912, lng: 20.6511371 },
    { lat: 49.0941514, lng: 20.6490974 },
    { lat: 49.0935284, lng: 20.6487764 },
    { lat: 49.0933749, lng: 20.649139 },
    { lat: 49.0929103, lng: 20.6497412 },
    { lat: 49.0925888, lng: 20.6504461 },
    { lat: 49.0924696, lng: 20.650515 },
    { lat: 49.092349, lng: 20.6503828 },
    { lat: 49.0920657, lng: 20.6504211 },
    { lat: 49.0917875, lng: 20.6502021 },
    { lat: 49.0916862, lng: 20.6507073 },
    { lat: 49.0915713, lng: 20.6508558 },
    { lat: 49.0914814, lng: 20.6512369 },
    { lat: 49.0915188, lng: 20.6522212 },
    { lat: 49.091754, lng: 20.6530055 },
    { lat: 49.0916949, lng: 20.6531117 },
    { lat: 49.0914824, lng: 20.6549439 },
    { lat: 49.0914314, lng: 20.6555613 },
    { lat: 49.0915081, lng: 20.6557187 },
    { lat: 49.0903147, lng: 20.6562524 },
    { lat: 49.0903493, lng: 20.6565875 },
    { lat: 49.0898955, lng: 20.6562711 },
    { lat: 49.0896448, lng: 20.6564963 },
    { lat: 49.0894648, lng: 20.6569785 },
    { lat: 49.0896575, lng: 20.6574179 },
    { lat: 49.0892151, lng: 20.6573552 },
    { lat: 49.0886536, lng: 20.6579592 },
    { lat: 49.0883669, lng: 20.6580617 },
    { lat: 49.0884554, lng: 20.6582752 },
    { lat: 49.0868084, lng: 20.6588357 },
    { lat: 49.0864763, lng: 20.6590849 },
    { lat: 49.0860903, lng: 20.6596891 },
    { lat: 49.0852165, lng: 20.660816 },
    { lat: 49.0831929, lng: 20.6636593 },
    { lat: 49.0830131, lng: 20.663039 },
  ],
  Domaňovce: [
    { lat: 48.9853058, lng: 20.665509 },
    { lat: 48.9852257, lng: 20.6653808 },
    { lat: 48.9850969, lng: 20.6649926 },
    { lat: 48.9850289, lng: 20.6643771 },
    { lat: 48.9847566, lng: 20.6598039 },
    { lat: 48.9845013, lng: 20.6579552 },
    { lat: 48.984532, lng: 20.6575305 },
    { lat: 48.9845169, lng: 20.6558543 },
    { lat: 48.9844483, lng: 20.6540034 },
    { lat: 48.9842445, lng: 20.6537214 },
    { lat: 48.9840542, lng: 20.6530024 },
    { lat: 48.9835516, lng: 20.6516076 },
    { lat: 48.9833722, lng: 20.6512569 },
    { lat: 48.9831021, lng: 20.6503449 },
    { lat: 48.9830395, lng: 20.6497663 },
    { lat: 48.9827237, lng: 20.6484345 },
    { lat: 48.9824915, lng: 20.6466782 },
    { lat: 48.9819501, lng: 20.643585 },
    { lat: 48.9818378, lng: 20.6429965 },
    { lat: 48.9817601, lng: 20.6428087 },
    { lat: 48.9813945, lng: 20.6408486 },
    { lat: 48.9807234, lng: 20.6369423 },
    { lat: 48.9806105, lng: 20.6364948 },
    { lat: 48.9803731, lng: 20.6364679 },
    { lat: 48.9801543, lng: 20.635711 },
    { lat: 48.9799261, lng: 20.6357812 },
    { lat: 48.9798674, lng: 20.635436 },
    { lat: 48.9804511, lng: 20.635039 },
    { lat: 48.9800478, lng: 20.6330093 },
    { lat: 48.9797838, lng: 20.632526 },
    { lat: 48.979629, lng: 20.6320085 },
    { lat: 48.9801526, lng: 20.6316078 },
    { lat: 48.9797711, lng: 20.6297459 },
    { lat: 48.979808, lng: 20.6297364 },
    { lat: 48.9795675, lng: 20.6284985 },
    { lat: 48.9773833, lng: 20.6288413 },
    { lat: 48.977238, lng: 20.6274715 },
    { lat: 48.9766398, lng: 20.6258693 },
    { lat: 48.9767463, lng: 20.6257941 },
    { lat: 48.9765764, lng: 20.6252052 },
    { lat: 48.9770251, lng: 20.6248197 },
    { lat: 48.9769744, lng: 20.6246649 },
    { lat: 48.9774026, lng: 20.6242424 },
    { lat: 48.9772108, lng: 20.6236363 },
    { lat: 48.9775805, lng: 20.6228722 },
    { lat: 48.9770898, lng: 20.6219735 },
    { lat: 48.9771823, lng: 20.6214221 },
    { lat: 48.97807, lng: 20.6208143 },
    { lat: 48.9794944, lng: 20.6189835 },
    { lat: 48.9790868, lng: 20.6184256 },
    { lat: 48.978816, lng: 20.617891 },
    { lat: 48.978798, lng: 20.617906 },
    { lat: 48.978641, lng: 20.617684 },
    { lat: 48.978637, lng: 20.617256 },
    { lat: 48.978352, lng: 20.616943 },
    { lat: 48.977918, lng: 20.617094 },
    { lat: 48.977712, lng: 20.617211 },
    { lat: 48.975702, lng: 20.616956 },
    { lat: 48.97567, lng: 20.617146 },
    { lat: 48.974963, lng: 20.617163 },
    { lat: 48.974834, lng: 20.617187 },
    { lat: 48.974325, lng: 20.617088 },
    { lat: 48.973948, lng: 20.617207 },
    { lat: 48.973432, lng: 20.616904 },
    { lat: 48.97141, lng: 20.617616 },
    { lat: 48.971307, lng: 20.617653 },
    { lat: 48.970922, lng: 20.618207 },
    { lat: 48.970702, lng: 20.618311 },
    { lat: 48.970335, lng: 20.618612 },
    { lat: 48.96995, lng: 20.618659 },
    { lat: 48.969785, lng: 20.619061 },
    { lat: 48.969451, lng: 20.619057 },
    { lat: 48.969312, lng: 20.61905 },
    { lat: 48.969099, lng: 20.619407 },
    { lat: 48.968472, lng: 20.619547 },
    { lat: 48.968355, lng: 20.619704 },
    { lat: 48.968186, lng: 20.619531 },
    { lat: 48.967867, lng: 20.619801 },
    { lat: 48.967638, lng: 20.61998 },
    { lat: 48.96756, lng: 20.620041 },
    { lat: 48.966927, lng: 20.620117 },
    { lat: 48.966874, lng: 20.620124 },
    { lat: 48.966911, lng: 20.620471 },
    { lat: 48.966676, lng: 20.620658 },
    { lat: 48.966471, lng: 20.620324 },
    { lat: 48.966259, lng: 20.620405 },
    { lat: 48.965958, lng: 20.620502 },
    { lat: 48.965748, lng: 20.620277 },
    { lat: 48.965601, lng: 20.620397 },
    { lat: 48.965467, lng: 20.619991 },
    { lat: 48.965408, lng: 20.619947 },
    { lat: 48.965141, lng: 20.619753 },
    { lat: 48.965086, lng: 20.619539 },
    { lat: 48.965081, lng: 20.619521 },
    { lat: 48.965078, lng: 20.619513 },
    { lat: 48.965055, lng: 20.619511 },
    { lat: 48.965001, lng: 20.619507 },
    { lat: 48.965, lng: 20.619547 },
    { lat: 48.964999, lng: 20.619611 },
    { lat: 48.965001, lng: 20.620268 },
    { lat: 48.964964, lng: 20.620794 },
    { lat: 48.964958, lng: 20.620883 },
    { lat: 48.964945, lng: 20.620909 },
    { lat: 48.964923, lng: 20.62095 },
    { lat: 48.964906, lng: 20.620984 },
    { lat: 48.964905, lng: 20.620989 },
    { lat: 48.964887, lng: 20.621155 },
    { lat: 48.964469, lng: 20.6251 },
    { lat: 48.963295, lng: 20.625565 },
    { lat: 48.959699, lng: 20.627151 },
    { lat: 48.956617, lng: 20.628499 },
    { lat: 48.956552, lng: 20.628539 },
    { lat: 48.95628, lng: 20.628703 },
    { lat: 48.955998, lng: 20.628875 },
    { lat: 48.955959, lng: 20.628907 },
    { lat: 48.955933, lng: 20.628922 },
    { lat: 48.955583, lng: 20.6293 },
    { lat: 48.955513, lng: 20.629353 },
    { lat: 48.955479, lng: 20.629379 },
    { lat: 48.955407, lng: 20.629433 },
    { lat: 48.955269, lng: 20.629537 },
    { lat: 48.955209, lng: 20.629583 },
    { lat: 48.95517, lng: 20.629611 },
    { lat: 48.955002, lng: 20.629737 },
    { lat: 48.954078, lng: 20.630236 },
    { lat: 48.952473, lng: 20.630982 },
    { lat: 48.952533, lng: 20.631183 },
    { lat: 48.952733, lng: 20.632103 },
    { lat: 48.95224, lng: 20.632311 },
    { lat: 48.952981, lng: 20.634939 },
    { lat: 48.953225, lng: 20.636997 },
    { lat: 48.954224, lng: 20.638702 },
    { lat: 48.954246, lng: 20.63872 },
    { lat: 48.954564, lng: 20.639224 },
    { lat: 48.954942, lng: 20.640046 },
    { lat: 48.954997, lng: 20.640383 },
    { lat: 48.955045, lng: 20.640713 },
    { lat: 48.956133, lng: 20.642168 },
    { lat: 48.956951, lng: 20.643088 },
    { lat: 48.957042, lng: 20.643298 },
    { lat: 48.9572, lng: 20.643652 },
    { lat: 48.957263, lng: 20.64463 },
    { lat: 48.956882, lng: 20.64625 },
    { lat: 48.955875, lng: 20.648976 },
    { lat: 48.955119, lng: 20.650781 },
    { lat: 48.954773, lng: 20.651887 },
    { lat: 48.954642, lng: 20.652368 },
    { lat: 48.954629, lng: 20.652572 },
    { lat: 48.954077, lng: 20.655209 },
    { lat: 48.953917, lng: 20.65597 },
    { lat: 48.953827, lng: 20.656765 },
    { lat: 48.953923, lng: 20.657077 },
    { lat: 48.954079, lng: 20.657403 },
    { lat: 48.954121, lng: 20.658096 },
    { lat: 48.954238, lng: 20.659151 },
    { lat: 48.954257, lng: 20.659608 },
    { lat: 48.954374, lng: 20.661571 },
    { lat: 48.954447, lng: 20.662565 },
    { lat: 48.954453, lng: 20.662811 },
    { lat: 48.954487, lng: 20.662807 },
    { lat: 48.954459, lng: 20.664078 },
    { lat: 48.954623, lng: 20.66457 },
    { lat: 48.955364, lng: 20.666155 },
    { lat: 48.955917, lng: 20.667233 },
    { lat: 48.955994, lng: 20.667378 },
    { lat: 48.955699, lng: 20.668311 },
    { lat: 48.956204, lng: 20.670014 },
    { lat: 48.956679, lng: 20.671584 },
    { lat: 48.956986, lng: 20.672442 },
    { lat: 48.957284, lng: 20.673201 },
    { lat: 48.9574, lng: 20.673585 },
    { lat: 48.957558, lng: 20.67418 },
    { lat: 48.957746, lng: 20.674624 },
    { lat: 48.957917, lng: 20.674918 },
    { lat: 48.958098, lng: 20.675122 },
    { lat: 48.95843, lng: 20.675314 },
    { lat: 48.958792, lng: 20.675655 },
    { lat: 48.959067, lng: 20.676062 },
    { lat: 48.959198, lng: 20.676366 },
    { lat: 48.959495, lng: 20.676842 },
    { lat: 48.959896, lng: 20.677359 },
    { lat: 48.960418, lng: 20.677806 },
    { lat: 48.961044, lng: 20.678134 },
    { lat: 48.961308, lng: 20.678269 },
    { lat: 48.961768, lng: 20.678439 },
    { lat: 48.96246, lng: 20.678688 },
    { lat: 48.962885, lng: 20.67881 },
    { lat: 48.963431, lng: 20.678987 },
    { lat: 48.963795, lng: 20.679123 },
    { lat: 48.964148, lng: 20.679366 },
    { lat: 48.964684, lng: 20.680403 },
    { lat: 48.965215, lng: 20.681279 },
    { lat: 48.965593, lng: 20.682069 },
    { lat: 48.968329, lng: 20.679822 },
    { lat: 48.96967, lng: 20.679105 },
    { lat: 48.970308, lng: 20.678807 },
    { lat: 48.971639, lng: 20.678438 },
    { lat: 48.971854, lng: 20.678465 },
    { lat: 48.972244, lng: 20.678816 },
    { lat: 48.97312, lng: 20.678286 },
    { lat: 48.973589, lng: 20.67996 },
    { lat: 48.974178, lng: 20.683008 },
    { lat: 48.974166, lng: 20.683329 },
    { lat: 48.974247, lng: 20.683787 },
    { lat: 48.974443, lng: 20.684612 },
    { lat: 48.974842, lng: 20.685664 },
    { lat: 48.97564, lng: 20.68699 },
    { lat: 48.976051, lng: 20.68756 },
    { lat: 48.9767047, lng: 20.6866607 },
    { lat: 48.9773978, lng: 20.6861202 },
    { lat: 48.9773649, lng: 20.6860172 },
    { lat: 48.9778257, lng: 20.6852644 },
    { lat: 48.9782305, lng: 20.6850673 },
    { lat: 48.9786994, lng: 20.6847341 },
    { lat: 48.9788207, lng: 20.6836845 },
    { lat: 48.9788553, lng: 20.6827968 },
    { lat: 48.9785942, lng: 20.6818532 },
    { lat: 48.9784519, lng: 20.6805107 },
    { lat: 48.9787606, lng: 20.6793843 },
    { lat: 48.9796797, lng: 20.6780119 },
    { lat: 48.9804271, lng: 20.6762455 },
    { lat: 48.9823179, lng: 20.6749727 },
    { lat: 48.9844079, lng: 20.6740727 },
    { lat: 48.9853342, lng: 20.6733779 },
    { lat: 48.9857891, lng: 20.6715278 },
    { lat: 48.9859452, lng: 20.6712293 },
    { lat: 48.9859467, lng: 20.6689119 },
    { lat: 48.9858941, lng: 20.6683596 },
    { lat: 48.9853699, lng: 20.6656331 },
    { lat: 48.9853058, lng: 20.665509 },
  ],
  Jablonov: [
    { lat: 49.0465754, lng: 20.6601978 },
    { lat: 49.045418, lng: 20.6591307 },
    { lat: 49.0449456, lng: 20.6579153 },
    { lat: 49.0426101, lng: 20.6591167 },
    { lat: 49.0425395, lng: 20.659036 },
    { lat: 49.0424183, lng: 20.6591082 },
    { lat: 49.0420787, lng: 20.659117 },
    { lat: 49.0418341, lng: 20.6592646 },
    { lat: 49.0415288, lng: 20.6593201 },
    { lat: 49.0413672, lng: 20.6594583 },
    { lat: 49.0403581, lng: 20.6596981 },
    { lat: 49.0397304, lng: 20.6597125 },
    { lat: 49.0391899, lng: 20.6598341 },
    { lat: 49.0388624, lng: 20.6597403 },
    { lat: 49.0383438, lng: 20.6598163 },
    { lat: 49.038163, lng: 20.6599515 },
    { lat: 49.0381769, lng: 20.6599647 },
    { lat: 49.0376277, lng: 20.6608473 },
    { lat: 49.0363112, lng: 20.6615599 },
    { lat: 49.0330011, lng: 20.6649705 },
    { lat: 49.0323551, lng: 20.6661063 },
    { lat: 49.0320851, lng: 20.6673366 },
    { lat: 49.0313638, lng: 20.6682878 },
    { lat: 49.0295645, lng: 20.669638 },
    { lat: 49.0286432, lng: 20.6704704 },
    { lat: 49.0282629, lng: 20.6705679 },
    { lat: 49.0275888, lng: 20.6711455 },
    { lat: 49.026119, lng: 20.672572 },
    { lat: 49.0250388, lng: 20.6738741 },
    { lat: 49.0243577, lng: 20.674695 },
    { lat: 49.0235191, lng: 20.6754218 },
    { lat: 49.0228698, lng: 20.675843 },
    { lat: 49.0219608, lng: 20.6765451 },
    { lat: 49.0216546, lng: 20.6779071 },
    { lat: 49.0208536, lng: 20.6796224 },
    { lat: 49.0193856, lng: 20.6816103 },
    { lat: 49.0191622, lng: 20.681722 },
    { lat: 49.0188632, lng: 20.6828696 },
    { lat: 49.0187389, lng: 20.6840888 },
    { lat: 49.0187549, lng: 20.6845387 },
    { lat: 49.018644, lng: 20.6852182 },
    { lat: 49.018607, lng: 20.6859149 },
    { lat: 49.0177255, lng: 20.6864175 },
    { lat: 49.0173177, lng: 20.6865059 },
    { lat: 49.0163725, lng: 20.6865145 },
    { lat: 49.0156816, lng: 20.6868709 },
    { lat: 49.0142029, lng: 20.6881212 },
    { lat: 49.0132925, lng: 20.688794 },
    { lat: 49.0130279, lng: 20.6889495 },
    { lat: 49.0111491, lng: 20.6896739 },
    { lat: 49.0108268, lng: 20.689856 },
    { lat: 49.0096411, lng: 20.6906704 },
    { lat: 49.0082199, lng: 20.6918562 },
    { lat: 49.007688, lng: 20.6923155 },
    { lat: 49.0068055, lng: 20.6932375 },
    { lat: 49.0067499, lng: 20.6941417 },
    { lat: 49.00677, lng: 20.6963836 },
    { lat: 49.0069912, lng: 20.6979145 },
    { lat: 49.0070595, lng: 20.69892 },
    { lat: 49.0074718, lng: 20.7020344 },
    { lat: 49.0074651, lng: 20.702446 },
    { lat: 49.0075249, lng: 20.7028752 },
    { lat: 49.0074936, lng: 20.7032935 },
    { lat: 49.0076999, lng: 20.7057047 },
    { lat: 49.0081903, lng: 20.7079458 },
    { lat: 49.0086691, lng: 20.711166 },
    { lat: 49.0086314, lng: 20.7134722 },
    { lat: 49.0088359, lng: 20.7158113 },
    { lat: 49.0088399, lng: 20.7162112 },
    { lat: 49.008529, lng: 20.7175769 },
    { lat: 49.0083182, lng: 20.7188416 },
    { lat: 49.0079361, lng: 20.7194014 },
    { lat: 49.0078464, lng: 20.7198555 },
    { lat: 49.0078081, lng: 20.7204603 },
    { lat: 49.0079131, lng: 20.7226953 },
    { lat: 49.0079558, lng: 20.7266851 },
    { lat: 49.0078614, lng: 20.7282809 },
    { lat: 49.0077225, lng: 20.7293202 },
    { lat: 49.0077362, lng: 20.7302557 },
    { lat: 49.0089012, lng: 20.7325826 },
    { lat: 49.0093603, lng: 20.7322232 },
    { lat: 49.0101144, lng: 20.7318678 },
    { lat: 49.0103915, lng: 20.7315601 },
    { lat: 49.0104557, lng: 20.7313993 },
    { lat: 49.0104391, lng: 20.731055 },
    { lat: 49.0104969, lng: 20.7306537 },
    { lat: 49.0108557, lng: 20.7306079 },
    { lat: 49.0108381, lng: 20.7310386 },
    { lat: 49.0108923, lng: 20.7313913 },
    { lat: 49.0108843, lng: 20.7317413 },
    { lat: 49.0108576, lng: 20.731879 },
    { lat: 49.0106704, lng: 20.7321571 },
    { lat: 49.0106943, lng: 20.7328673 },
    { lat: 49.0106635, lng: 20.7333664 },
    { lat: 49.010317, lng: 20.7341365 },
    { lat: 49.0103688, lng: 20.7348441 },
    { lat: 49.0103428, lng: 20.7351255 },
    { lat: 49.0101471, lng: 20.7354807 },
    { lat: 49.0099019, lng: 20.7361615 },
    { lat: 49.0096558, lng: 20.7357247 },
    { lat: 49.0094911, lng: 20.7361325 },
    { lat: 49.009471, lng: 20.7362632 },
    { lat: 49.0095298, lng: 20.7364012 },
    { lat: 49.0097625, lng: 20.7366107 },
    { lat: 49.0096211, lng: 20.7369775 },
    { lat: 49.0093678, lng: 20.7373943 },
    { lat: 49.0093112, lng: 20.737609 },
    { lat: 49.0092666, lng: 20.7389968 },
    { lat: 49.0099812, lng: 20.7395953 },
    { lat: 49.0103076, lng: 20.7397892 },
    { lat: 49.0114263, lng: 20.7399393 },
    { lat: 49.0119933, lng: 20.7409557 },
    { lat: 49.0127009, lng: 20.743156 },
    { lat: 49.0135809, lng: 20.7426309 },
    { lat: 49.0137787, lng: 20.7424 },
    { lat: 49.0160758, lng: 20.7408503 },
    { lat: 49.01644, lng: 20.740711 },
    { lat: 49.0175406, lng: 20.7396414 },
    { lat: 49.0178784, lng: 20.7394536 },
    { lat: 49.0185144, lng: 20.7388446 },
    { lat: 49.0187248, lng: 20.7387184 },
    { lat: 49.0191093, lng: 20.738222 },
    { lat: 49.0193774, lng: 20.7380033 },
    { lat: 49.0198033, lng: 20.7374386 },
    { lat: 49.0199367, lng: 20.7372015 },
    { lat: 49.0201529, lng: 20.736595 },
    { lat: 49.020239, lng: 20.7365148 },
    { lat: 49.0210008, lng: 20.7361225 },
    { lat: 49.0218926, lng: 20.7353294 },
    { lat: 49.0228419, lng: 20.7350179 },
    { lat: 49.0233419, lng: 20.7347323 },
    { lat: 49.0237948, lng: 20.7347471 },
    { lat: 49.0242171, lng: 20.7348827 },
    { lat: 49.0242767, lng: 20.7348474 },
    { lat: 49.0239506, lng: 20.7338997 },
    { lat: 49.0233156, lng: 20.7329956 },
    { lat: 49.0231988, lng: 20.7327196 },
    { lat: 49.0228167, lng: 20.732293 },
    { lat: 49.022215, lng: 20.7317918 },
    { lat: 49.0224899, lng: 20.73065 },
    { lat: 49.0227082, lng: 20.7290186 },
    { lat: 49.0227351, lng: 20.7279278 },
    { lat: 49.0231147, lng: 20.7279033 },
    { lat: 49.0234819, lng: 20.7280689 },
    { lat: 49.0235514, lng: 20.7274915 },
    { lat: 49.0238555, lng: 20.7268569 },
    { lat: 49.0243395, lng: 20.7266288 },
    { lat: 49.0251731, lng: 20.7260723 },
    { lat: 49.0262393, lng: 20.725632 },
    { lat: 49.0265427, lng: 20.7253935 },
    { lat: 49.0268961, lng: 20.7253277 },
    { lat: 49.027905, lng: 20.7252999 },
    { lat: 49.0283294, lng: 20.7251135 },
    { lat: 49.0284859, lng: 20.7249849 },
    { lat: 49.0307518, lng: 20.721669 },
    { lat: 49.0310054, lng: 20.7218618 },
    { lat: 49.0312742, lng: 20.7216052 },
    { lat: 49.0315878, lng: 20.7211735 },
    { lat: 49.0319622, lng: 20.7210679 },
    { lat: 49.0319249, lng: 20.7212213 },
    { lat: 49.0318823, lng: 20.7238777 },
    { lat: 49.0324838, lng: 20.7238243 },
    { lat: 49.0330778, lng: 20.7235917 },
    { lat: 49.0339497, lng: 20.7241125 },
    { lat: 49.0344567, lng: 20.7242889 },
    { lat: 49.0350684, lng: 20.7248449 },
    { lat: 49.0364876, lng: 20.724549 },
    { lat: 49.0372511, lng: 20.7253216 },
    { lat: 49.0382664, lng: 20.7260245 },
    { lat: 49.0391438, lng: 20.7261976 },
    { lat: 49.0391726, lng: 20.7264722 },
    { lat: 49.0394713, lng: 20.7264199 },
    { lat: 49.0397161, lng: 20.7262122 },
    { lat: 49.0398937, lng: 20.7264909 },
    { lat: 49.0399158, lng: 20.7267305 },
    { lat: 49.0398637, lng: 20.7271058 },
    { lat: 49.0396465, lng: 20.7276151 },
    { lat: 49.0398202, lng: 20.7277268 },
    { lat: 49.0399301, lng: 20.7276784 },
    { lat: 49.040083, lng: 20.7273574 },
    { lat: 49.0405147, lng: 20.7259662 },
    { lat: 49.0407918, lng: 20.7254302 },
    { lat: 49.0412061, lng: 20.7244107 },
    { lat: 49.0414447, lng: 20.7241516 },
    { lat: 49.0421953, lng: 20.7238269 },
    { lat: 49.0442906, lng: 20.722695 },
    { lat: 49.0449222, lng: 20.7224704 },
    { lat: 49.0454659, lng: 20.7220557 },
    { lat: 49.0456925, lng: 20.7219992 },
    { lat: 49.0457181, lng: 20.722024 },
    { lat: 49.0457674, lng: 20.7219561 },
    { lat: 49.0459371, lng: 20.7212787 },
    { lat: 49.0460733, lng: 20.7195893 },
    { lat: 49.0462669, lng: 20.7193025 },
    { lat: 49.0464935, lng: 20.7180381 },
    { lat: 49.0467638, lng: 20.7168613 },
    { lat: 49.0471008, lng: 20.7161375 },
    { lat: 49.0473181, lng: 20.7153787 },
    { lat: 49.0474574, lng: 20.7144759 },
    { lat: 49.0474797, lng: 20.7141537 },
    { lat: 49.0471296, lng: 20.7131657 },
    { lat: 49.0471643, lng: 20.7126181 },
    { lat: 49.0472158, lng: 20.7124539 },
    { lat: 49.0471848, lng: 20.7121521 },
    { lat: 49.0475221, lng: 20.7115643 },
    { lat: 49.0474676, lng: 20.7114575 },
    { lat: 49.0474466, lng: 20.7111488 },
    { lat: 49.0475617, lng: 20.7109707 },
    { lat: 49.0476429, lng: 20.7107194 },
    { lat: 49.0476467, lng: 20.710447 },
    { lat: 49.0477855, lng: 20.7102616 },
    { lat: 49.0478061, lng: 20.709984 },
    { lat: 49.0480996, lng: 20.7093054 },
    { lat: 49.0482896, lng: 20.7092105 },
    { lat: 49.0483387, lng: 20.7090719 },
    { lat: 49.0483382, lng: 20.7088645 },
    { lat: 49.0484405, lng: 20.708475 },
    { lat: 49.0485122, lng: 20.70761 },
    { lat: 49.0484757, lng: 20.7071512 },
    { lat: 49.048574, lng: 20.7066238 },
    { lat: 49.0488697, lng: 20.7055621 },
    { lat: 49.0490882, lng: 20.7049901 },
    { lat: 49.0491641, lng: 20.7044033 },
    { lat: 49.0493059, lng: 20.7039247 },
    { lat: 49.0494482, lng: 20.7036561 },
    { lat: 49.0499717, lng: 20.7033042 },
    { lat: 49.0504355, lng: 20.7028322 },
    { lat: 49.0508645, lng: 20.7021047 },
    { lat: 49.0512915, lng: 20.7015755 },
    { lat: 49.0516626, lng: 20.7009324 },
    { lat: 49.0521006, lng: 20.7003639 },
    { lat: 49.0527638, lng: 20.7000148 },
    { lat: 49.053485, lng: 20.6997851 },
    { lat: 49.0536475, lng: 20.6995431 },
    { lat: 49.0540527, lng: 20.6989399 },
    { lat: 49.0547224, lng: 20.69862 },
    { lat: 49.0554782, lng: 20.6980906 },
    { lat: 49.0559538, lng: 20.6979499 },
    { lat: 49.0561598, lng: 20.6976007 },
    { lat: 49.0578754, lng: 20.6972884 },
    { lat: 49.0588001, lng: 20.6958417 },
    { lat: 49.0587603, lng: 20.6956095 },
    { lat: 49.0585839, lng: 20.6949801 },
    { lat: 49.0580814, lng: 20.6938972 },
    { lat: 49.0573736, lng: 20.6926062 },
    { lat: 49.0560728, lng: 20.6907173 },
    { lat: 49.0554125, lng: 20.6899077 },
    { lat: 49.0549281, lng: 20.6890769 },
    { lat: 49.0544592, lng: 20.6865583 },
    { lat: 49.0546026, lng: 20.6850882 },
    { lat: 49.0548782, lng: 20.6833076 },
    { lat: 49.054299, lng: 20.6805135 },
    { lat: 49.0543031, lng: 20.6796227 },
    { lat: 49.0545187, lng: 20.6775846 },
    { lat: 49.0543677, lng: 20.6762892 },
    { lat: 49.0542296, lng: 20.6743413 },
    { lat: 49.0537527, lng: 20.6726802 },
    { lat: 49.0535976, lng: 20.672281 },
    { lat: 49.0530026, lng: 20.6711878 },
    { lat: 49.0524116, lng: 20.6693636 },
    { lat: 49.0523513, lng: 20.6689971 },
    { lat: 49.052151, lng: 20.6684351 },
    { lat: 49.0520801, lng: 20.6679303 },
    { lat: 49.0518423, lng: 20.6675477 },
    { lat: 49.0516897, lng: 20.6674354 },
    { lat: 49.0514591, lng: 20.6670967 },
    { lat: 49.0511896, lng: 20.6665788 },
    { lat: 49.0510309, lng: 20.666456 },
    { lat: 49.0502229, lng: 20.6654835 },
    { lat: 49.0500091, lng: 20.6651406 },
    { lat: 49.049283, lng: 20.6626882 },
    { lat: 49.0486393, lng: 20.6612468 },
    { lat: 49.0483951, lng: 20.6609032 },
    { lat: 49.0474801, lng: 20.6604116 },
    { lat: 49.0465754, lng: 20.6601978 },
  ],
  Kurimany: [
    { lat: 49.0115573, lng: 20.5385981 },
    { lat: 49.0115952, lng: 20.5378851 },
    { lat: 49.0116924, lng: 20.5362493 },
    { lat: 49.0114542, lng: 20.5358031 },
    { lat: 49.0112844, lng: 20.5347717 },
    { lat: 49.0112972, lng: 20.5340842 },
    { lat: 49.011466, lng: 20.533957 },
    { lat: 49.011361, lng: 20.533133 },
    { lat: 49.011235, lng: 20.532129 },
    { lat: 49.011077, lng: 20.531269 },
    { lat: 49.010622, lng: 20.529726 },
    { lat: 49.010345, lng: 20.528852 },
    { lat: 49.010151, lng: 20.528273 },
    { lat: 49.010013, lng: 20.527877 },
    { lat: 49.009895, lng: 20.527761 },
    { lat: 49.009785, lng: 20.527699 },
    { lat: 49.009385, lng: 20.527465 },
    { lat: 49.008867, lng: 20.52728 },
    { lat: 49.007636, lng: 20.526966 },
    { lat: 49.007661, lng: 20.526647 },
    { lat: 49.007702, lng: 20.526058 },
    { lat: 49.007708, lng: 20.525964 },
    { lat: 49.007542, lng: 20.525841 },
    { lat: 49.007354, lng: 20.5257 },
    { lat: 49.007009, lng: 20.525446 },
    { lat: 49.006947, lng: 20.525399 },
    { lat: 49.00664, lng: 20.525172 },
    { lat: 49.006478, lng: 20.525455 },
    { lat: 49.006038, lng: 20.526224 },
    { lat: 49.005861, lng: 20.52652 },
    { lat: 49.004946, lng: 20.526713 },
    { lat: 49.004801, lng: 20.526746 },
    { lat: 49.003006, lng: 20.527146 },
    { lat: 49.002639, lng: 20.527203 },
    { lat: 49.001582, lng: 20.526721 },
    { lat: 49.001003, lng: 20.526441 },
    { lat: 49.000232, lng: 20.526068 },
    { lat: 48.999401, lng: 20.526176 },
    { lat: 48.999387, lng: 20.526178 },
    { lat: 48.999266, lng: 20.526155 },
    { lat: 48.999346, lng: 20.526273 },
    { lat: 48.99946, lng: 20.52667 },
    { lat: 48.999538, lng: 20.526885 },
    { lat: 48.999645, lng: 20.526989 },
    { lat: 48.999804, lng: 20.527184 },
    { lat: 48.999794, lng: 20.527213 },
    { lat: 48.999789, lng: 20.527228 },
    { lat: 48.999778, lng: 20.527252 },
    { lat: 48.999611, lng: 20.527698 },
    { lat: 48.999218, lng: 20.528744 },
    { lat: 48.9988, lng: 20.529853 },
    { lat: 48.998624, lng: 20.53033 },
    { lat: 48.998648, lng: 20.530368 },
    { lat: 48.998666, lng: 20.530396 },
    { lat: 48.998469, lng: 20.530824 },
    { lat: 48.998421, lng: 20.530901 },
    { lat: 48.998376, lng: 20.53096 },
    { lat: 48.998301, lng: 20.531002 },
    { lat: 48.998201, lng: 20.531018 },
    { lat: 48.998148, lng: 20.531019 },
    { lat: 48.997973, lng: 20.530988 },
    { lat: 48.997798, lng: 20.530951 },
    { lat: 48.997496, lng: 20.530856 },
    { lat: 48.997375, lng: 20.530818 },
    { lat: 48.997371, lng: 20.530826 },
    { lat: 48.99735, lng: 20.530874 },
    { lat: 48.997344, lng: 20.530886 },
    { lat: 48.997237, lng: 20.531178 },
    { lat: 48.997209, lng: 20.531257 },
    { lat: 48.997052, lng: 20.531684 },
    { lat: 48.996728, lng: 20.532564 },
    { lat: 48.99651, lng: 20.533253 },
    { lat: 48.99648, lng: 20.533307 },
    { lat: 48.996443, lng: 20.533373 },
    { lat: 48.996409, lng: 20.533386 },
    { lat: 48.996367, lng: 20.5334 },
    { lat: 48.996352, lng: 20.533404 },
    { lat: 48.996295, lng: 20.533717 },
    { lat: 48.996076, lng: 20.53429 },
    { lat: 48.995901, lng: 20.535144 },
    { lat: 48.995877, lng: 20.535232 },
    { lat: 48.995864, lng: 20.535279 },
    { lat: 48.995805, lng: 20.535374 },
    { lat: 48.995648, lng: 20.535553 },
    { lat: 48.995412, lng: 20.535818 },
    { lat: 48.99538, lng: 20.535856 },
    { lat: 48.995105, lng: 20.536214 },
    { lat: 48.995047, lng: 20.536279 },
    { lat: 48.994935, lng: 20.536405 },
    { lat: 48.99482, lng: 20.536518 },
    { lat: 48.994504, lng: 20.536901 },
    { lat: 48.994277, lng: 20.537116 },
    { lat: 48.994129, lng: 20.537249 },
    { lat: 48.993969, lng: 20.537394 },
    { lat: 48.99384, lng: 20.537467 },
    { lat: 48.993616, lng: 20.537565 },
    { lat: 48.993413, lng: 20.537618 },
    { lat: 48.992852, lng: 20.537877 },
    { lat: 48.992755, lng: 20.537907 },
    { lat: 48.992449, lng: 20.538017 },
    { lat: 48.991853, lng: 20.538349 },
    { lat: 48.99169, lng: 20.538443 },
    { lat: 48.991498, lng: 20.538599 },
    { lat: 48.991309, lng: 20.538729 },
    { lat: 48.991075, lng: 20.538924 },
    { lat: 48.991067, lng: 20.538956 },
    { lat: 48.991226, lng: 20.539653 },
    { lat: 48.991272, lng: 20.539855 },
    { lat: 48.991371, lng: 20.540618 },
    { lat: 48.99135, lng: 20.540951 },
    { lat: 48.991306, lng: 20.541208 },
    { lat: 48.991249, lng: 20.541481 },
    { lat: 48.991236, lng: 20.541542 },
    { lat: 48.991055, lng: 20.542419 },
    { lat: 48.990962, lng: 20.542862 },
    { lat: 48.990954, lng: 20.543008 },
    { lat: 48.990937, lng: 20.543077 },
    { lat: 48.990875, lng: 20.543266 },
    { lat: 48.990797, lng: 20.543473 },
    { lat: 48.990766, lng: 20.543556 },
    { lat: 48.990722, lng: 20.543775 },
    { lat: 48.990701, lng: 20.543883 },
    { lat: 48.990698, lng: 20.543906 },
    { lat: 48.990681, lng: 20.544102 },
    { lat: 48.990687, lng: 20.544628 },
    { lat: 48.990693, lng: 20.545131 },
    { lat: 48.990628, lng: 20.545969 },
    { lat: 48.990624, lng: 20.546006 },
    { lat: 48.990614, lng: 20.546005 },
    { lat: 48.9906, lng: 20.546005 },
    { lat: 48.9906, lng: 20.546032 },
    { lat: 48.9906, lng: 20.546085 },
    { lat: 48.990602, lng: 20.546541 },
    { lat: 48.990606, lng: 20.547633 },
    { lat: 48.990646, lng: 20.548524 },
    { lat: 48.990648, lng: 20.549016 },
    { lat: 48.99064, lng: 20.549285 },
    { lat: 48.990634, lng: 20.549422 },
    { lat: 48.990601, lng: 20.550137 },
    { lat: 48.990598, lng: 20.550211 },
    { lat: 48.990585, lng: 20.550318 },
    { lat: 48.990578, lng: 20.550369 },
    { lat: 48.990386, lng: 20.551576 },
    { lat: 48.990381, lng: 20.551605 },
    { lat: 48.990341, lng: 20.551892 },
    { lat: 48.990341, lng: 20.551893 },
    { lat: 48.990225, lng: 20.552724 },
    { lat: 48.990223, lng: 20.552734 },
    { lat: 48.9912422, lng: 20.552702 },
    { lat: 48.9926441, lng: 20.5524011 },
    { lat: 48.9932795, lng: 20.5519556 },
    { lat: 48.994268, lng: 20.5510395 },
    { lat: 48.9959679, lng: 20.5500528 },
    { lat: 48.9958824, lng: 20.5489867 },
    { lat: 48.9959014, lng: 20.5487104 },
    { lat: 48.9962668, lng: 20.5485987 },
    { lat: 48.9962195, lng: 20.5482965 },
    { lat: 48.9965584, lng: 20.5477166 },
    { lat: 48.9970377, lng: 20.5477067 },
    { lat: 48.997376, lng: 20.5473531 },
    { lat: 48.9976371, lng: 20.5472616 },
    { lat: 48.9987151, lng: 20.5471807 },
    { lat: 49.0007124, lng: 20.546086 },
    { lat: 49.0015214, lng: 20.5459529 },
    { lat: 49.0019754, lng: 20.5454367 },
    { lat: 49.0024192, lng: 20.5450461 },
    { lat: 49.0035031, lng: 20.5444292 },
    { lat: 49.0039679, lng: 20.5443666 },
    { lat: 49.0047937, lng: 20.5444053 },
    { lat: 49.0050562, lng: 20.5443525 },
    { lat: 49.0055849, lng: 20.5440955 },
    { lat: 49.0063762, lng: 20.5439378 },
    { lat: 49.0065208, lng: 20.543924 },
    { lat: 49.0066811, lng: 20.5440542 },
    { lat: 49.0069976, lng: 20.5401496 },
    { lat: 49.0085213, lng: 20.5396155 },
    { lat: 49.008565, lng: 20.5385123 },
    { lat: 49.0087573, lng: 20.5383521 },
    { lat: 49.0111674, lng: 20.5384657 },
    { lat: 49.0115573, lng: 20.5385981 },
  ],
  VyšnýSlavkov: [
    { lat: 49.042813, lng: 20.884788 },
    { lat: 49.042829, lng: 20.884803 },
    { lat: 49.042852, lng: 20.884832 },
    { lat: 49.042869, lng: 20.884823 },
    { lat: 49.043396, lng: 20.885445 },
    { lat: 49.043541, lng: 20.885409 },
    { lat: 49.044073, lng: 20.886074 },
    { lat: 49.044187, lng: 20.886282 },
    { lat: 49.044442, lng: 20.88677 },
    { lat: 49.044988, lng: 20.88946 },
    { lat: 49.045216, lng: 20.891431 },
    { lat: 49.046749, lng: 20.892501 },
    { lat: 49.047363, lng: 20.892744 },
    { lat: 49.048871, lng: 20.894085 },
    { lat: 49.049529, lng: 20.895003 },
    { lat: 49.049931, lng: 20.895421 },
    { lat: 49.050787, lng: 20.895586 },
    { lat: 49.051626, lng: 20.895497 },
    { lat: 49.051781, lng: 20.895438 },
    { lat: 49.053082, lng: 20.895188 },
    { lat: 49.053374, lng: 20.895204 },
    { lat: 49.054136, lng: 20.895353 },
    { lat: 49.060278, lng: 20.89727 },
    { lat: 49.060642, lng: 20.897612 },
    { lat: 49.061526, lng: 20.898176 },
    { lat: 49.064237, lng: 20.900046 },
    { lat: 49.065443, lng: 20.900422 },
    { lat: 49.067496, lng: 20.901064 },
    { lat: 49.069778, lng: 20.901618 },
    { lat: 49.071462, lng: 20.902044 },
    { lat: 49.071965, lng: 20.902158 },
    { lat: 49.071969, lng: 20.902158 },
    { lat: 49.072123, lng: 20.902145 },
    { lat: 49.072721, lng: 20.902096 },
    { lat: 49.073436, lng: 20.902262 },
    { lat: 49.073742, lng: 20.902333 },
    { lat: 49.075558, lng: 20.902754 },
    { lat: 49.075865, lng: 20.900299 },
    { lat: 49.076433, lng: 20.898531 },
    { lat: 49.078794, lng: 20.896147 },
    { lat: 49.079834, lng: 20.893894 },
    { lat: 49.082637, lng: 20.8909 },
    { lat: 49.083176, lng: 20.889016 },
    { lat: 49.08321, lng: 20.8889 },
    { lat: 49.083361, lng: 20.88778 },
    { lat: 49.083398, lng: 20.887585 },
    { lat: 49.08403, lng: 20.885727 },
    { lat: 49.084446, lng: 20.884498 },
    { lat: 49.085101, lng: 20.882032 },
    { lat: 49.0860465, lng: 20.8803182 },
    { lat: 49.085863, lng: 20.880232 },
    { lat: 49.08615, lng: 20.879654 },
    { lat: 49.086477, lng: 20.87881 },
    { lat: 49.086054, lng: 20.875862 },
    { lat: 49.086045, lng: 20.874593 },
    { lat: 49.086126, lng: 20.874249 },
    { lat: 49.086056, lng: 20.87098 },
    { lat: 49.08586, lng: 20.870217 },
    { lat: 49.086008, lng: 20.869878 },
    { lat: 49.086718, lng: 20.868811 },
    { lat: 49.086995, lng: 20.86733 },
    { lat: 49.087168, lng: 20.866275 },
    { lat: 49.087195, lng: 20.865821 },
    { lat: 49.0874688, lng: 20.8657115 },
    { lat: 49.08765, lng: 20.865639 },
    { lat: 49.087983, lng: 20.864872 },
    { lat: 49.086654, lng: 20.863952 },
    { lat: 49.085788, lng: 20.862822 },
    { lat: 49.085496, lng: 20.862165 },
    { lat: 49.085105, lng: 20.861499 },
    { lat: 49.08478, lng: 20.860727 },
    { lat: 49.084491, lng: 20.859444 },
    { lat: 49.083341, lng: 20.856974 },
    { lat: 49.083313, lng: 20.856572 },
    { lat: 49.08318, lng: 20.854344 },
    { lat: 49.082957, lng: 20.850759 },
    { lat: 49.083014, lng: 20.850198 },
    { lat: 49.083197, lng: 20.849307 },
    { lat: 49.083235, lng: 20.849126 },
    { lat: 49.083362, lng: 20.848094 },
    { lat: 49.08339, lng: 20.847613 },
    { lat: 49.083396, lng: 20.847286 },
    { lat: 49.08342, lng: 20.847029 },
    { lat: 49.083353, lng: 20.846688 },
    { lat: 49.08337, lng: 20.846652 },
    { lat: 49.082975, lng: 20.846092 },
    { lat: 49.082925, lng: 20.846039 },
    { lat: 49.0828665, lng: 20.846051 },
    { lat: 49.0828163, lng: 20.8461979 },
    { lat: 49.0826521, lng: 20.8460993 },
    { lat: 49.0825723, lng: 20.8462349 },
    { lat: 49.0821028, lng: 20.8463963 },
    { lat: 49.0809813, lng: 20.846417 },
    { lat: 49.0807446, lng: 20.846207 },
    { lat: 49.0804115, lng: 20.8460463 },
    { lat: 49.0802128, lng: 20.8458646 },
    { lat: 49.0798379, lng: 20.8452681 },
    { lat: 49.079468, lng: 20.8448181 },
    { lat: 49.0792729, lng: 20.8444157 },
    { lat: 49.0791158, lng: 20.8437689 },
    { lat: 49.078923, lng: 20.8433296 },
    { lat: 49.0787439, lng: 20.8431632 },
    { lat: 49.0784596, lng: 20.8432436 },
    { lat: 49.0779623, lng: 20.8430206 },
    { lat: 49.0775749, lng: 20.8426851 },
    { lat: 49.0770181, lng: 20.8418406 },
    { lat: 49.0768698, lng: 20.8417195 },
    { lat: 49.0761317, lng: 20.8417573 },
    { lat: 49.0742626, lng: 20.8417056 },
    { lat: 49.0737551, lng: 20.8416112 },
    { lat: 49.0732729, lng: 20.8413339 },
    { lat: 49.0725782, lng: 20.8406696 },
    { lat: 49.0719135, lng: 20.8395384 },
    { lat: 49.0714363, lng: 20.8384562 },
    { lat: 49.071215, lng: 20.8381381 },
    { lat: 49.070934, lng: 20.8379504 },
    { lat: 49.0698415, lng: 20.8379014 },
    { lat: 49.0692422, lng: 20.8379508 },
    { lat: 49.069113, lng: 20.8380224 },
    { lat: 49.0690714, lng: 20.8379292 },
    { lat: 49.0685553, lng: 20.8385689 },
    { lat: 49.0679095, lng: 20.8391335 },
    { lat: 49.0668701, lng: 20.8403687 },
    { lat: 49.0662156, lng: 20.8410071 },
    { lat: 49.0660109, lng: 20.8411483 },
    { lat: 49.0652172, lng: 20.841469 },
    { lat: 49.0650685, lng: 20.841633 },
    { lat: 49.0643149, lng: 20.8418374 },
    { lat: 49.0640334, lng: 20.841808 },
    { lat: 49.0628913, lng: 20.8412267 },
    { lat: 49.0616553, lng: 20.840366 },
    { lat: 49.0612408, lng: 20.8401297 },
    { lat: 49.0609028, lng: 20.8400169 },
    { lat: 49.0599623, lng: 20.8394448 },
    { lat: 49.0577196, lng: 20.8394609 },
    { lat: 49.0566556, lng: 20.8397151 },
    { lat: 49.0559936, lng: 20.8396772 },
    { lat: 49.0554081, lng: 20.8397714 },
    { lat: 49.0533008, lng: 20.8394986 },
    { lat: 49.0523409, lng: 20.8395077 },
    { lat: 49.0518249, lng: 20.8391671 },
    { lat: 49.0517841, lng: 20.8391521 },
    { lat: 49.0517778, lng: 20.839239 },
    { lat: 49.0518362, lng: 20.8399844 },
    { lat: 49.0526538, lng: 20.8415771 },
    { lat: 49.0519907, lng: 20.8420629 },
    { lat: 49.0514582, lng: 20.8425562 },
    { lat: 49.0506173, lng: 20.8435741 },
    { lat: 49.0500898, lng: 20.8461131 },
    { lat: 49.0497331, lng: 20.8495109 },
    { lat: 49.0487046, lng: 20.8569996 },
    { lat: 49.0477206, lng: 20.860255 },
    { lat: 49.0475669, lng: 20.8626113 },
    { lat: 49.0476831, lng: 20.8643392 },
    { lat: 49.0472766, lng: 20.8664477 },
    { lat: 49.0466633, lng: 20.8676805 },
    { lat: 49.0464409, lng: 20.868552 },
    { lat: 49.04654, lng: 20.869711 },
    { lat: 49.0465558, lng: 20.8707026 },
    { lat: 49.0463611, lng: 20.8715945 },
    { lat: 49.0460494, lng: 20.8726244 },
    { lat: 49.0459674, lng: 20.8732939 },
    { lat: 49.0460755, lng: 20.876843 },
    { lat: 49.0460479, lng: 20.8771193 },
    { lat: 49.0461108, lng: 20.8772844 },
    { lat: 49.045581, lng: 20.8781251 },
    { lat: 49.0449869, lng: 20.879918 },
    { lat: 49.0446849, lng: 20.8806733 },
    { lat: 49.0442981, lng: 20.8813818 },
    { lat: 49.0437979, lng: 20.8827256 },
    { lat: 49.0434224, lng: 20.8835405 },
    { lat: 49.0432399, lng: 20.8840976 },
    { lat: 49.043056, lng: 20.8842466 },
    { lat: 49.042813, lng: 20.884788 },
  ],
  Dravce: [
    { lat: 49.039406, lng: 20.508659 },
    { lat: 49.039566, lng: 20.508442 },
    { lat: 49.039683, lng: 20.508347 },
    { lat: 49.039791, lng: 20.508258 },
    { lat: 49.039832, lng: 20.508095 },
    { lat: 49.039966, lng: 20.507488 },
    { lat: 49.040082, lng: 20.507094 },
    { lat: 49.04016, lng: 20.50686 },
    { lat: 49.040205, lng: 20.506707 },
    { lat: 49.040298, lng: 20.506521 },
    { lat: 49.040382, lng: 20.506413 },
    { lat: 49.040605, lng: 20.506189 },
    { lat: 49.040752, lng: 20.506099 },
    { lat: 49.040851, lng: 20.506062 },
    { lat: 49.04093, lng: 20.506033 },
    { lat: 49.041013, lng: 20.506058 },
    { lat: 49.041136, lng: 20.506095 },
    { lat: 49.041217, lng: 20.506121 },
    { lat: 49.041412, lng: 20.506047 },
    { lat: 49.041703, lng: 20.505885 },
    { lat: 49.041882, lng: 20.505747 },
    { lat: 49.04206, lng: 20.505558 },
    { lat: 49.042231, lng: 20.505386 },
    { lat: 49.042387, lng: 20.505221 },
    { lat: 49.042555, lng: 20.505037 },
    { lat: 49.042633, lng: 20.504925 },
    { lat: 49.042707, lng: 20.504817 },
    { lat: 49.042819, lng: 20.505004 },
    { lat: 49.042906, lng: 20.50467 },
    { lat: 49.042947, lng: 20.504374 },
    { lat: 49.042965, lng: 20.504243 },
    { lat: 49.043127, lng: 20.503984 },
    { lat: 49.043293, lng: 20.503778 },
    { lat: 49.0435, lng: 20.503643 },
    { lat: 49.043733, lng: 20.503543 },
    { lat: 49.043784, lng: 20.503523 },
    { lat: 49.043963, lng: 20.503397 },
    { lat: 49.044056, lng: 20.50333 },
    { lat: 49.044183, lng: 20.503179 },
    { lat: 49.04427, lng: 20.503078 },
    { lat: 49.044508, lng: 20.502711 },
    { lat: 49.04458, lng: 20.502601 },
    { lat: 49.044727, lng: 20.502108 },
    { lat: 49.044744, lng: 20.501861 },
    { lat: 49.044725, lng: 20.501617 },
    { lat: 49.04474, lng: 20.501464 },
    { lat: 49.044768, lng: 20.501179 },
    { lat: 49.044744, lng: 20.500989 },
    { lat: 49.044696, lng: 20.500792 },
    { lat: 49.044665, lng: 20.500594 },
    { lat: 49.044698, lng: 20.500434 },
    { lat: 49.044764, lng: 20.500313 },
    { lat: 49.044999, lng: 20.499893 },
    { lat: 49.045179, lng: 20.499657 },
    { lat: 49.0455, lng: 20.499109 },
    { lat: 49.045596, lng: 20.498889 },
    { lat: 49.045716, lng: 20.498734 },
    { lat: 49.04593, lng: 20.498261 },
    { lat: 49.045983, lng: 20.498136 },
    { lat: 49.046016, lng: 20.498057 },
    { lat: 49.046085, lng: 20.497775 },
    { lat: 49.046215, lng: 20.496586 },
    { lat: 49.046063, lng: 20.496445 },
    { lat: 49.046054, lng: 20.496437 },
    { lat: 49.046014, lng: 20.496401 },
    { lat: 49.045978, lng: 20.496298 },
    { lat: 49.045976, lng: 20.496145 },
    { lat: 49.046149, lng: 20.495842 },
    { lat: 49.046144, lng: 20.495433 },
    { lat: 49.046375, lng: 20.494878 },
    { lat: 49.046422, lng: 20.49482 },
    { lat: 49.046542, lng: 20.494646 },
    { lat: 49.046567, lng: 20.494609 },
    { lat: 49.046975, lng: 20.494021 },
    { lat: 49.047017, lng: 20.493966 },
    { lat: 49.047232, lng: 20.493663 },
    { lat: 49.04745, lng: 20.493579 },
    { lat: 49.047816, lng: 20.493439 },
    { lat: 49.048638, lng: 20.4931 },
    { lat: 49.048823, lng: 20.493053 },
    { lat: 49.048987, lng: 20.492935 },
    { lat: 49.0492, lng: 20.492836 },
    { lat: 49.049568, lng: 20.492685 },
    { lat: 49.049794, lng: 20.492514 },
    { lat: 49.049982, lng: 20.492427 },
    { lat: 49.050174, lng: 20.492387 },
    { lat: 49.050261, lng: 20.492347 },
    { lat: 49.050327, lng: 20.492718 },
    { lat: 49.050527, lng: 20.492719 },
    { lat: 49.050577, lng: 20.492732 },
    { lat: 49.050647, lng: 20.492749 },
    { lat: 49.050736, lng: 20.4928 },
    { lat: 49.050778, lng: 20.492825 },
    { lat: 49.050934, lng: 20.492818 },
    { lat: 49.051048, lng: 20.492812 },
    { lat: 49.051164, lng: 20.49278 },
    { lat: 49.051259, lng: 20.492752 },
    { lat: 49.051403, lng: 20.492644 },
    { lat: 49.051471, lng: 20.492589 },
    { lat: 49.05157, lng: 20.492546 },
    { lat: 49.051649, lng: 20.492507 },
    { lat: 49.051625, lng: 20.491848 },
    { lat: 49.05167, lng: 20.491725 },
    { lat: 49.051864, lng: 20.491471 },
    { lat: 49.052192, lng: 20.491293 },
    { lat: 49.052205, lng: 20.490894 },
    { lat: 49.052018, lng: 20.490835 },
    { lat: 49.051915, lng: 20.490766 },
    { lat: 49.051823, lng: 20.490668 },
    { lat: 49.051751, lng: 20.490575 },
    { lat: 49.051695, lng: 20.490431 },
    { lat: 49.051673, lng: 20.490263 },
    { lat: 49.051692, lng: 20.490093 },
    { lat: 49.051774, lng: 20.489805 },
    { lat: 49.051843, lng: 20.489692 },
    { lat: 49.051932, lng: 20.489412 },
    { lat: 49.052003, lng: 20.48922 },
    { lat: 49.052083, lng: 20.48905 },
    { lat: 49.052116, lng: 20.488907 },
    { lat: 49.052294, lng: 20.487798 },
    { lat: 49.052169, lng: 20.487348 },
    { lat: 49.05224, lng: 20.487102 },
    { lat: 49.052228, lng: 20.486947 },
    { lat: 49.052096, lng: 20.486572 },
    { lat: 49.052044, lng: 20.486381 },
    { lat: 49.052117, lng: 20.486275 },
    { lat: 49.052145, lng: 20.486243 },
    { lat: 49.052368, lng: 20.485938 },
    { lat: 49.052727, lng: 20.485694 },
    { lat: 49.053015, lng: 20.485695 },
    { lat: 49.053031, lng: 20.485693 },
    { lat: 49.053152, lng: 20.485668 },
    { lat: 49.052994, lng: 20.484472 },
    { lat: 49.052843, lng: 20.483909 },
    { lat: 49.052749, lng: 20.483688 },
    { lat: 49.052524, lng: 20.483338 },
    { lat: 49.052497, lng: 20.483306 },
    { lat: 49.052156, lng: 20.482901 },
    { lat: 49.051865, lng: 20.482695 },
    { lat: 49.051601, lng: 20.48256 },
    { lat: 49.051293, lng: 20.482403 },
    { lat: 49.051266, lng: 20.482321 },
    { lat: 49.051168, lng: 20.481968 },
    { lat: 49.051151, lng: 20.481909 },
    { lat: 49.051117, lng: 20.481767 },
    { lat: 49.050773, lng: 20.480767 },
    { lat: 49.050768, lng: 20.480752 },
    { lat: 49.050648, lng: 20.480391 },
    { lat: 49.050585, lng: 20.479899 },
    { lat: 49.050582, lng: 20.479872 },
    { lat: 49.050517, lng: 20.479622 },
    { lat: 49.050502, lng: 20.479561 },
    { lat: 49.050492, lng: 20.479544 },
    { lat: 49.05028, lng: 20.479097 },
    { lat: 49.050259, lng: 20.479084 },
    { lat: 49.05006, lng: 20.478862 },
    { lat: 49.049796, lng: 20.478613 },
    { lat: 49.049446, lng: 20.478118 },
    { lat: 49.049398, lng: 20.478058 },
    { lat: 49.049261, lng: 20.477834 },
    { lat: 49.049258, lng: 20.477829 },
    { lat: 49.048904, lng: 20.477173 },
    { lat: 49.048616, lng: 20.476643 },
    { lat: 49.048567, lng: 20.476538 },
    { lat: 49.048521, lng: 20.476472 },
    { lat: 49.048443, lng: 20.476359 },
    { lat: 49.048396, lng: 20.476291 },
    { lat: 49.048389, lng: 20.476283 },
    { lat: 49.048349, lng: 20.476233 },
    { lat: 49.048255, lng: 20.476134 },
    { lat: 49.048034, lng: 20.475965 },
    { lat: 49.047821, lng: 20.475799 },
    { lat: 49.04762, lng: 20.47569 },
    { lat: 49.047235, lng: 20.475479 },
    { lat: 49.047201, lng: 20.475467 },
    { lat: 49.047193, lng: 20.475464 },
    { lat: 49.047026, lng: 20.47539 },
    { lat: 49.047018, lng: 20.475387 },
    { lat: 49.04692, lng: 20.475333 },
    { lat: 49.046844, lng: 20.475291 },
    { lat: 49.046802, lng: 20.475266 },
    { lat: 49.046685, lng: 20.475195 },
    { lat: 49.046346, lng: 20.474945 },
    { lat: 49.046325, lng: 20.474929 },
    { lat: 49.046268, lng: 20.474882 },
    { lat: 49.045983, lng: 20.474604 },
    { lat: 49.045772, lng: 20.474305 },
    { lat: 49.045688, lng: 20.474158 },
    { lat: 49.045647, lng: 20.474086 },
    { lat: 49.045601, lng: 20.474007 },
    { lat: 49.045536, lng: 20.473828 },
    { lat: 49.045433, lng: 20.473548 },
    { lat: 49.045359, lng: 20.473284 },
    { lat: 49.045251, lng: 20.472922 },
    { lat: 49.045179, lng: 20.472765 },
    { lat: 49.045174, lng: 20.472769 },
    { lat: 49.044985, lng: 20.472895 },
    { lat: 49.044279, lng: 20.473083 },
    { lat: 49.04399, lng: 20.473104 },
    { lat: 49.043526, lng: 20.473269 },
    { lat: 49.043409, lng: 20.473314 },
    { lat: 49.043097, lng: 20.473432 },
    { lat: 49.041214, lng: 20.474136 },
    { lat: 49.041217, lng: 20.474162 },
    { lat: 49.040728, lng: 20.474274 },
    { lat: 49.040483, lng: 20.474313 },
    { lat: 49.040461, lng: 20.474322 },
    { lat: 49.040154, lng: 20.474442 },
    { lat: 49.039912, lng: 20.47451 },
    { lat: 49.039909, lng: 20.474511 },
    { lat: 49.039461, lng: 20.474697 },
    { lat: 49.039265, lng: 20.47476 },
    { lat: 49.039129, lng: 20.474776 },
    { lat: 49.039059, lng: 20.474793 },
    { lat: 49.038806, lng: 20.474816 },
    { lat: 49.03862, lng: 20.474885 },
    { lat: 49.038335, lng: 20.474958 },
    { lat: 49.03806, lng: 20.475013 },
    { lat: 49.03746, lng: 20.475201 },
    { lat: 49.0355079, lng: 20.4755173 },
    { lat: 49.0344705, lng: 20.4754793 },
    { lat: 49.0332893, lng: 20.4755648 },
    { lat: 49.0326241, lng: 20.4756546 },
    { lat: 49.0323666, lng: 20.4759995 },
    { lat: 49.0319354, lng: 20.476166 },
    { lat: 49.0317083, lng: 20.4759794 },
    { lat: 49.0311187, lng: 20.4754169 },
    { lat: 49.0307477, lng: 20.4751619 },
    { lat: 49.0288611, lng: 20.4751419 },
    { lat: 49.0285435, lng: 20.4752602 },
    { lat: 49.0276714, lng: 20.4752134 },
    { lat: 49.0270175, lng: 20.4750081 },
    { lat: 49.0268102, lng: 20.4751233 },
    { lat: 49.0262298, lng: 20.4751674 },
    { lat: 49.0247226, lng: 20.4744868 },
    { lat: 49.0237679, lng: 20.4744005 },
    { lat: 49.0234401, lng: 20.4746638 },
    { lat: 49.0222375, lng: 20.4746625 },
    { lat: 49.0215041, lng: 20.4748024 },
    { lat: 49.0194395, lng: 20.4749306 },
    { lat: 49.0173023, lng: 20.4749219 },
    { lat: 49.017036, lng: 20.4751505 },
    { lat: 49.0165562, lng: 20.4753527 },
    { lat: 49.0158983, lng: 20.4753745 },
    { lat: 49.0155099, lng: 20.4755562 },
    { lat: 49.0153291, lng: 20.4755683 },
    { lat: 49.0152468, lng: 20.4757458 },
    { lat: 49.0148226, lng: 20.4756957 },
    { lat: 49.0148006, lng: 20.475793 },
    { lat: 49.0145964, lng: 20.4757533 },
    { lat: 49.0141205, lng: 20.4754376 },
    { lat: 49.0140509, lng: 20.4753174 },
    { lat: 49.0135468, lng: 20.4752961 },
    { lat: 49.0131741, lng: 20.4753857 },
    { lat: 49.0129859, lng: 20.4753547 },
    { lat: 49.0128152, lng: 20.4755725 },
    { lat: 49.0124096, lng: 20.4757159 },
    { lat: 49.0114889, lng: 20.4758781 },
    { lat: 49.0111574, lng: 20.4758641 },
    { lat: 49.0111023, lng: 20.4761084 },
    { lat: 49.0090062, lng: 20.4756026 },
    { lat: 49.0073886, lng: 20.4760363 },
    { lat: 49.0062549, lng: 20.476488 },
    { lat: 49.0061194, lng: 20.4764722 },
    { lat: 49.0057147, lng: 20.4762511 },
    { lat: 49.0055511, lng: 20.4766006 },
    { lat: 49.0040208, lng: 20.4761771 },
    { lat: 49.0038983, lng: 20.4770584 },
    { lat: 49.0037011, lng: 20.4777712 },
    { lat: 49.0034267, lng: 20.478473 },
    { lat: 49.0029619, lng: 20.4799534 },
    { lat: 49.0028631, lng: 20.4811959 },
    { lat: 49.002915, lng: 20.481585 },
    { lat: 49.002951, lng: 20.48214 },
    { lat: 49.002954, lng: 20.482187 },
    { lat: 49.002959, lng: 20.482241 },
    { lat: 49.002991, lng: 20.482507 },
    { lat: 49.003002, lng: 20.482614 },
    { lat: 49.003029, lng: 20.482891 },
    { lat: 49.003134, lng: 20.483836 },
    { lat: 49.003138, lng: 20.484063 },
    { lat: 49.003141, lng: 20.484472 },
    { lat: 49.003209, lng: 20.485048 },
    { lat: 49.003224, lng: 20.485073 },
    { lat: 49.00327, lng: 20.485142 },
    { lat: 49.00328, lng: 20.485158 },
    { lat: 49.003326, lng: 20.485227 },
    { lat: 49.003387, lng: 20.48562 },
    { lat: 49.003425, lng: 20.486205 },
    { lat: 49.003419, lng: 20.486539 },
    { lat: 49.003419, lng: 20.487173 },
    { lat: 49.003407, lng: 20.487448 },
    { lat: 49.003395, lng: 20.487726 },
    { lat: 49.003353, lng: 20.488307 },
    { lat: 49.003322, lng: 20.488727 },
    { lat: 49.003294, lng: 20.489047 },
    { lat: 49.003282, lng: 20.489118 },
    { lat: 49.003262, lng: 20.489234 },
    { lat: 49.003239, lng: 20.489418 },
    { lat: 49.003171, lng: 20.490018 },
    { lat: 49.003128, lng: 20.490403 },
    { lat: 49.00309, lng: 20.490718 },
    { lat: 49.003056, lng: 20.490993 },
    { lat: 49.003073, lng: 20.491205 },
    { lat: 49.003085, lng: 20.491395 },
    { lat: 49.003169, lng: 20.492219 },
    { lat: 49.003179, lng: 20.492315 },
    { lat: 49.003174, lng: 20.492534 },
    { lat: 49.003078, lng: 20.492967 },
    { lat: 49.002881, lng: 20.493487 },
    { lat: 49.002811, lng: 20.493812 },
    { lat: 49.002767, lng: 20.494037 },
    { lat: 49.002735, lng: 20.494253 },
    { lat: 49.002711, lng: 20.494457 },
    { lat: 49.002563, lng: 20.495814 },
    { lat: 49.002522, lng: 20.4962 },
    { lat: 49.002503, lng: 20.496366 },
    { lat: 49.0025694, lng: 20.4969496 },
    { lat: 49.0025639, lng: 20.4976655 },
    { lat: 49.0025263, lng: 20.4985721 },
    { lat: 49.0024672, lng: 20.4993553 },
    { lat: 49.0024363, lng: 20.5001514 },
    { lat: 49.0024107, lng: 20.5013822 },
    { lat: 49.002384, lng: 20.501379 },
    { lat: 49.0019035, lng: 20.500719 },
    { lat: 49.0018774, lng: 20.5015009 },
    { lat: 49.001846, lng: 20.502581 },
    { lat: 49.001809, lng: 20.502913 },
    { lat: 49.001791, lng: 20.50354 },
    { lat: 49.00179, lng: 20.503705 },
    { lat: 49.0018, lng: 20.503926 },
    { lat: 49.0017857, lng: 20.5045646 },
    { lat: 49.0016489, lng: 20.5053973 },
    { lat: 49.0024258, lng: 20.5054732 },
    { lat: 49.002441, lng: 20.505822 },
    { lat: 49.002716, lng: 20.505708 },
    { lat: 49.00314, lng: 20.505577 },
    { lat: 49.003692, lng: 20.505443 },
    { lat: 49.003957, lng: 20.505363 },
    { lat: 49.004094, lng: 20.505295 },
    { lat: 49.004201, lng: 20.505243 },
    { lat: 49.004239, lng: 20.505224 },
    { lat: 49.00439, lng: 20.5051 },
    { lat: 49.004401, lng: 20.505124 },
    { lat: 49.004773, lng: 20.504974 },
    { lat: 49.004961, lng: 20.504898 },
    { lat: 49.004998, lng: 20.504883 },
    { lat: 49.005161, lng: 20.505033 },
    { lat: 49.005432, lng: 20.505284 },
    { lat: 49.005909, lng: 20.505345 },
    { lat: 49.00618, lng: 20.505379 },
    { lat: 49.006204, lng: 20.505389 },
    { lat: 49.006747, lng: 20.505592 },
    { lat: 49.007088, lng: 20.505771 },
    { lat: 49.007176, lng: 20.505896 },
    { lat: 49.007275, lng: 20.506038 },
    { lat: 49.007439, lng: 20.506402 },
    { lat: 49.007618, lng: 20.506538 },
    { lat: 49.007726, lng: 20.50719 },
    { lat: 49.008012, lng: 20.508884 },
    { lat: 49.008484, lng: 20.509387 },
    { lat: 49.008755, lng: 20.509675 },
    { lat: 49.009809, lng: 20.510867 },
    { lat: 49.010817, lng: 20.512026 },
    { lat: 49.010996, lng: 20.511635 },
    { lat: 49.011789, lng: 20.512128 },
    { lat: 49.012149, lng: 20.512352 },
    { lat: 49.013007, lng: 20.512886 },
    { lat: 49.013925, lng: 20.513629 },
    { lat: 49.014999, lng: 20.514987 },
    { lat: 49.015017, lng: 20.515004 },
    { lat: 49.015589, lng: 20.515548 },
    { lat: 49.016772, lng: 20.516675 },
    { lat: 49.01807, lng: 20.517113 },
    { lat: 49.018206, lng: 20.517141 },
    { lat: 49.018235, lng: 20.517148 },
    { lat: 49.018329, lng: 20.517159 },
    { lat: 49.018396, lng: 20.517479 },
    { lat: 49.018448, lng: 20.51773 },
    { lat: 49.018596, lng: 20.518278 },
    { lat: 49.018713, lng: 20.518429 },
    { lat: 49.018934, lng: 20.518712 },
    { lat: 49.019204, lng: 20.519225 },
    { lat: 49.019688, lng: 20.519528 },
    { lat: 49.019762, lng: 20.519643 },
    { lat: 49.01973, lng: 20.519791 },
    { lat: 49.019783, lng: 20.520021 },
    { lat: 49.019738, lng: 20.520271 },
    { lat: 49.019689, lng: 20.520589 },
    { lat: 49.019773, lng: 20.521359 },
    { lat: 49.019823, lng: 20.521629 },
    { lat: 49.019877, lng: 20.521922 },
    { lat: 49.019884, lng: 20.522189 },
    { lat: 49.019797, lng: 20.522558 },
    { lat: 49.019733, lng: 20.522489 },
    { lat: 49.019658, lng: 20.522406 },
    { lat: 49.019133, lng: 20.523171 },
    { lat: 49.019031, lng: 20.523157 },
    { lat: 49.019, lng: 20.523173 },
    { lat: 49.018991, lng: 20.523177 },
    { lat: 49.018949, lng: 20.52319 },
    { lat: 49.018858, lng: 20.523211 },
    { lat: 49.018809, lng: 20.523224 },
    { lat: 49.01874, lng: 20.523275 },
    { lat: 49.018687, lng: 20.523317 },
    { lat: 49.018417, lng: 20.523584 },
    { lat: 49.018021, lng: 20.523815 },
    { lat: 49.018038, lng: 20.52391 },
    { lat: 49.0180325, lng: 20.5239826 },
    { lat: 49.0188409, lng: 20.5243166 },
    { lat: 49.0190878, lng: 20.5243026 },
    { lat: 49.0194719, lng: 20.523849 },
    { lat: 49.0196074, lng: 20.5239805 },
    { lat: 49.0197907, lng: 20.524005 },
    { lat: 49.0197972, lng: 20.5238746 },
    { lat: 49.0206639, lng: 20.5227763 },
    { lat: 49.0208842, lng: 20.5222208 },
    { lat: 49.0209901, lng: 20.5217683 },
    { lat: 49.0211998, lng: 20.5196156 },
    { lat: 49.0213575, lng: 20.5195842 },
    { lat: 49.0213596, lng: 20.5185229 },
    { lat: 49.0215508, lng: 20.5183645 },
    { lat: 49.0213229, lng: 20.5177405 },
    { lat: 49.0214387, lng: 20.5164607 },
    { lat: 49.0214283, lng: 20.5160028 },
    { lat: 49.0218403, lng: 20.5156944 },
    { lat: 49.0220608, lng: 20.515602 },
    { lat: 49.0225592, lng: 20.5155596 },
    { lat: 49.0294247, lng: 20.5086737 },
    { lat: 49.0323287, lng: 20.5060174 },
    { lat: 49.0338626, lng: 20.5058439 },
    { lat: 49.0357199, lng: 20.5060172 },
    { lat: 49.0390247, lng: 20.5062074 },
    { lat: 49.0392936, lng: 20.5065363 },
    { lat: 49.039629, lng: 20.5076583 },
    { lat: 49.0396138, lng: 20.5082453 },
    { lat: 49.039406, lng: 20.508659 },
  ],
  Doľany: [
    { lat: 49.0368778, lng: 20.6462897 },
    { lat: 49.0368534, lng: 20.6461432 },
    { lat: 49.0363761, lng: 20.6456415 },
    { lat: 49.0362204, lng: 20.6453534 },
    { lat: 49.0360445, lng: 20.643873 },
    { lat: 49.0360566, lng: 20.6435229 },
    { lat: 49.0359042, lng: 20.6427932 },
    { lat: 49.0355206, lng: 20.6420208 },
    { lat: 49.0348231, lng: 20.6414635 },
    { lat: 49.0347973, lng: 20.6413103 },
    { lat: 49.035005, lng: 20.6406401 },
    { lat: 49.0355078, lng: 20.6394755 },
    { lat: 49.0354011, lng: 20.6392652 },
    { lat: 49.0352505, lng: 20.6391432 },
    { lat: 49.0351895, lng: 20.6389176 },
    { lat: 49.0353709, lng: 20.6380192 },
    { lat: 49.0353374, lng: 20.6379932 },
    { lat: 49.0353161, lng: 20.6377439 },
    { lat: 49.0354504, lng: 20.6360704 },
    { lat: 49.0333393, lng: 20.6340945 },
    { lat: 49.0329214, lng: 20.6346776 },
    { lat: 49.0326562, lng: 20.6349401 },
    { lat: 49.0324937, lng: 20.6349913 },
    { lat: 49.0319702, lng: 20.6346191 },
    { lat: 49.0317201, lng: 20.6350156 },
    { lat: 49.0313531, lng: 20.6346778 },
    { lat: 49.0308979, lng: 20.634132 },
    { lat: 49.0306478, lng: 20.6345034 },
    { lat: 49.0306032, lng: 20.6346718 },
    { lat: 49.0304671, lng: 20.6345805 },
    { lat: 49.0303621, lng: 20.6346334 },
    { lat: 49.0302231, lng: 20.6347419 },
    { lat: 49.0299591, lng: 20.6351686 },
    { lat: 49.029674, lng: 20.6351912 },
    { lat: 49.0296669, lng: 20.6352758 },
    { lat: 49.0294156, lng: 20.63539 },
    { lat: 49.0293107, lng: 20.6355103 },
    { lat: 49.0291816, lng: 20.6354892 },
    { lat: 49.0289017, lng: 20.635284 },
    { lat: 49.0287914, lng: 20.6352867 },
    { lat: 49.0287154, lng: 20.6355212 },
    { lat: 49.028685, lng: 20.6355804 },
    { lat: 49.0287592, lng: 20.6356693 },
    { lat: 49.0284977, lng: 20.6365686 },
    { lat: 49.0284876, lng: 20.6373486 },
    { lat: 49.0278282, lng: 20.638512 },
    { lat: 49.0267652, lng: 20.6381637 },
    { lat: 49.0263554, lng: 20.6379413 },
    { lat: 49.0248064, lng: 20.63807 },
    { lat: 49.023757, lng: 20.6377191 },
    { lat: 49.022306, lng: 20.6369415 },
    { lat: 49.0215653, lng: 20.6368845 },
    { lat: 49.0210972, lng: 20.6363859 },
    { lat: 49.0211287, lng: 20.6373829 },
    { lat: 49.0195608, lng: 20.6385182 },
    { lat: 49.0192167, lng: 20.6382456 },
    { lat: 49.0188282, lng: 20.6398055 },
    { lat: 49.0184882, lng: 20.6397452 },
    { lat: 49.018377, lng: 20.6401874 },
    { lat: 49.0181873, lng: 20.6404869 },
    { lat: 49.0176279, lng: 20.6408003 },
    { lat: 49.0174194, lng: 20.6404816 },
    { lat: 49.0173325, lng: 20.6406947 },
    { lat: 49.0171587, lng: 20.6406819 },
    { lat: 49.0164053, lng: 20.6412998 },
    { lat: 49.0159791, lng: 20.6417467 },
    { lat: 49.0149102, lng: 20.6420824 },
    { lat: 49.0149238, lng: 20.6433314 },
    { lat: 49.0151282, lng: 20.6441913 },
    { lat: 49.0149297, lng: 20.6442806 },
    { lat: 49.0148935, lng: 20.6443948 },
    { lat: 49.0142748, lng: 20.6443182 },
    { lat: 49.0141957, lng: 20.6445597 },
    { lat: 49.0127403, lng: 20.6444703 },
    { lat: 49.0127445, lng: 20.6454016 },
    { lat: 49.0128181, lng: 20.6465668 },
    { lat: 49.0126713, lng: 20.6467167 },
    { lat: 49.0112846, lng: 20.6473774 },
    { lat: 49.0113846, lng: 20.6479084 },
    { lat: 49.0113091, lng: 20.6489956 },
    { lat: 49.0107384, lng: 20.6508461 },
    { lat: 49.0103045, lng: 20.6518686 },
    { lat: 49.0103765, lng: 20.652369 },
    { lat: 49.0103562, lng: 20.6525256 },
    { lat: 49.0102389, lng: 20.6525181 },
    { lat: 49.0098809, lng: 20.6533339 },
    { lat: 49.0094627, lng: 20.6537236 },
    { lat: 49.0082983, lng: 20.6537761 },
    { lat: 49.0081178, lng: 20.653861 },
    { lat: 49.0074519, lng: 20.6544079 },
    { lat: 49.0056753, lng: 20.6552208 },
    { lat: 49.0058334, lng: 20.6562077 },
    { lat: 49.0059225, lng: 20.6565209 },
    { lat: 49.0062708, lng: 20.6566605 },
    { lat: 49.0063349, lng: 20.6590139 },
    { lat: 49.00628, lng: 20.6599619 },
    { lat: 49.0062097, lng: 20.6602922 },
    { lat: 49.0078222, lng: 20.6600847 },
    { lat: 49.0082963, lng: 20.6598407 },
    { lat: 49.0086119, lng: 20.6597518 },
    { lat: 49.0089747, lng: 20.6597669 },
    { lat: 49.0094726, lng: 20.6593954 },
    { lat: 49.009823, lng: 20.6589959 },
    { lat: 49.0102789, lng: 20.6582123 },
    { lat: 49.0108091, lng: 20.6592459 },
    { lat: 49.0109498, lng: 20.6597768 },
    { lat: 49.0109767, lng: 20.660059 },
    { lat: 49.0111349, lng: 20.6602022 },
    { lat: 49.0111965, lng: 20.660947 },
    { lat: 49.0117352, lng: 20.6608367 },
    { lat: 49.0122817, lng: 20.6605322 },
    { lat: 49.0122213, lng: 20.660422 },
    { lat: 49.0125001, lng: 20.6599058 },
    { lat: 49.0124328, lng: 20.6594771 },
    { lat: 49.0123166, lng: 20.6592486 },
    { lat: 49.0122437, lng: 20.6589072 },
    { lat: 49.0123246, lng: 20.6588732 },
    { lat: 49.0122839, lng: 20.658239 },
    { lat: 49.0129383, lng: 20.6580043 },
    { lat: 49.0149307, lng: 20.6580307 },
    { lat: 49.0169374, lng: 20.6574468 },
    { lat: 49.0188929, lng: 20.657011 },
    { lat: 49.019495, lng: 20.6567904 },
    { lat: 49.0199051, lng: 20.6567675 },
    { lat: 49.0197487, lng: 20.6576151 },
    { lat: 49.0198256, lng: 20.6581617 },
    { lat: 49.0199219, lng: 20.658332 },
    { lat: 49.020076, lng: 20.6588271 },
    { lat: 49.0244728, lng: 20.6583507 },
    { lat: 49.0245048, lng: 20.6589266 },
    { lat: 49.0248012, lng: 20.6604115 },
    { lat: 49.0253009, lng: 20.6600322 },
    { lat: 49.0254711, lng: 20.659692 },
    { lat: 49.0255037, lng: 20.659313 },
    { lat: 49.0259538, lng: 20.6585572 },
    { lat: 49.025676, lng: 20.6568681 },
    { lat: 49.0265676, lng: 20.6569982 },
    { lat: 49.0268588, lng: 20.6567969 },
    { lat: 49.0274546, lng: 20.6561757 },
    { lat: 49.0269668, lng: 20.6555708 },
    { lat: 49.0267824, lng: 20.6552647 },
    { lat: 49.026756, lng: 20.6548106 },
    { lat: 49.0270677, lng: 20.6548331 },
    { lat: 49.0279205, lng: 20.654479 },
    { lat: 49.0285815, lng: 20.6540134 },
    { lat: 49.0287416, lng: 20.6543667 },
    { lat: 49.0289913, lng: 20.6541397 },
    { lat: 49.0290124, lng: 20.6541936 },
    { lat: 49.0289441, lng: 20.6543294 },
    { lat: 49.0295911, lng: 20.6538467 },
    { lat: 49.0303548, lng: 20.6531071 },
    { lat: 49.0342204, lng: 20.6481859 },
    { lat: 49.0368778, lng: 20.6462897 },
  ],
  NižnéRepaše: [
    { lat: 49.0870852, lng: 20.6874124 },
    { lat: 49.0868936, lng: 20.6880663 },
    { lat: 49.086276, lng: 20.6892877 },
    { lat: 49.0850178, lng: 20.6915429 },
    { lat: 49.0845185, lng: 20.693107 },
    { lat: 49.0836777, lng: 20.6926986 },
    { lat: 49.0836949, lng: 20.6924533 },
    { lat: 49.0838688, lng: 20.692281 },
    { lat: 49.083974, lng: 20.6920411 },
    { lat: 49.0840731, lng: 20.6920081 },
    { lat: 49.0840853, lng: 20.6918811 },
    { lat: 49.0839918, lng: 20.6916525 },
    { lat: 49.083997, lng: 20.6913874 },
    { lat: 49.0841554, lng: 20.6910673 },
    { lat: 49.08489, lng: 20.6900993 },
    { lat: 49.0850017, lng: 20.6899152 },
    { lat: 49.0850124, lng: 20.6898048 },
    { lat: 49.085126, lng: 20.689678 },
    { lat: 49.085224, lng: 20.6894289 },
    { lat: 49.085047, lng: 20.6891673 },
    { lat: 49.084079, lng: 20.6906563 },
    { lat: 49.0837692, lng: 20.6914442 },
    { lat: 49.0832773, lng: 20.692172 },
    { lat: 49.082961, lng: 20.6922038 },
    { lat: 49.0824376, lng: 20.6918221 },
    { lat: 49.082567, lng: 20.6921357 },
    { lat: 49.0827398, lng: 20.6923797 },
    { lat: 49.0824522, lng: 20.6924572 },
    { lat: 49.0820769, lng: 20.69178 },
    { lat: 49.081888, lng: 20.6916275 },
    { lat: 49.0818171, lng: 20.6917511 },
    { lat: 49.0820814, lng: 20.6918733 },
    { lat: 49.0823445, lng: 20.6925006 },
    { lat: 49.0822175, lng: 20.6926637 },
    { lat: 49.0821206, lng: 20.6923718 },
    { lat: 49.0817187, lng: 20.6925672 },
    { lat: 49.0816487, lng: 20.6924954 },
    { lat: 49.0815205, lng: 20.6927857 },
    { lat: 49.0817383, lng: 20.6933366 },
    { lat: 49.0816039, lng: 20.6934859 },
    { lat: 49.0808629, lng: 20.6936018 },
    { lat: 49.0809092, lng: 20.6943356 },
    { lat: 49.0808227, lng: 20.6952929 },
    { lat: 49.0807393, lng: 20.6959558 },
    { lat: 49.0805739, lng: 20.6965913 },
    { lat: 49.0803509, lng: 20.6971271 },
    { lat: 49.0800935, lng: 20.6974571 },
    { lat: 49.0796532, lng: 20.6975482 },
    { lat: 49.0792752, lng: 20.6977772 },
    { lat: 49.0794362, lng: 20.6989838 },
    { lat: 49.0789647, lng: 20.7003809 },
    { lat: 49.0783544, lng: 20.7019632 },
    { lat: 49.0778969, lng: 20.7033498 },
    { lat: 49.0774084, lng: 20.70341 },
    { lat: 49.0771369, lng: 20.7046431 },
    { lat: 49.0770291, lng: 20.7056681 },
    { lat: 49.076809, lng: 20.7067001 },
    { lat: 49.0765623, lng: 20.7082518 },
    { lat: 49.0764532, lng: 20.7092428 },
    { lat: 49.0763728, lng: 20.7092641 },
    { lat: 49.0763346, lng: 20.709358 },
    { lat: 49.0762063, lng: 20.7098989 },
    { lat: 49.0757143, lng: 20.7107582 },
    { lat: 49.075653, lng: 20.7110925 },
    { lat: 49.0751531, lng: 20.7123937 },
    { lat: 49.0749851, lng: 20.7140462 },
    { lat: 49.0749729, lng: 20.7145545 },
    { lat: 49.074917, lng: 20.7148549 },
    { lat: 49.0789723, lng: 20.7190484 },
    { lat: 49.080876, lng: 20.7207424 },
    { lat: 49.083149, lng: 20.7218335 },
    { lat: 49.0841178, lng: 20.7239359 },
    { lat: 49.0850286, lng: 20.7257569 },
    { lat: 49.0850969, lng: 20.726484 },
    { lat: 49.0864498, lng: 20.7286173 },
    { lat: 49.087157, lng: 20.7294571 },
    { lat: 49.0881264, lng: 20.7300374 },
    { lat: 49.0900435, lng: 20.7319968 },
    { lat: 49.0908572, lng: 20.7323761 },
    { lat: 49.0911549, lng: 20.7312036 },
    { lat: 49.0914616, lng: 20.7304215 },
    { lat: 49.0920086, lng: 20.7293626 },
    { lat: 49.0923397, lng: 20.7289846 },
    { lat: 49.0926347, lng: 20.7288284 },
    { lat: 49.0928778, lng: 20.728792 },
    { lat: 49.0934086, lng: 20.7288094 },
    { lat: 49.0936779, lng: 20.7287257 },
    { lat: 49.0944815, lng: 20.7287193 },
    { lat: 49.0946657, lng: 20.7270758 },
    { lat: 49.0945878, lng: 20.7268131 },
    { lat: 49.0948464, lng: 20.7255305 },
    { lat: 49.0950129, lng: 20.7251146 },
    { lat: 49.0951559, lng: 20.7251751 },
    { lat: 49.0952623, lng: 20.7251305 },
    { lat: 49.0954749, lng: 20.7246753 },
    { lat: 49.0958533, lng: 20.7250668 },
    { lat: 49.0958412, lng: 20.7259342 },
    { lat: 49.0966016, lng: 20.7259529 },
    { lat: 49.0973741, lng: 20.7258661 },
    { lat: 49.0982154, lng: 20.7270614 },
    { lat: 49.099171, lng: 20.7277004 },
    { lat: 49.0996971, lng: 20.7286999 },
    { lat: 49.1006324, lng: 20.7302027 },
    { lat: 49.1011341, lng: 20.7303838 },
    { lat: 49.1014202, lng: 20.7306446 },
    { lat: 49.1015574, lng: 20.7312499 },
    { lat: 49.1015556, lng: 20.7315482 },
    { lat: 49.1017288, lng: 20.7317981 },
    { lat: 49.1023947, lng: 20.7315631 },
    { lat: 49.1026677, lng: 20.7316414 },
    { lat: 49.1033075, lng: 20.7308251 },
    { lat: 49.103588, lng: 20.7303837 },
    { lat: 49.1038475, lng: 20.7302048 },
    { lat: 49.1050101, lng: 20.7296924 },
    { lat: 49.1053928, lng: 20.7297233 },
    { lat: 49.1061903, lng: 20.7301853 },
    { lat: 49.1074732, lng: 20.7313568 },
    { lat: 49.1088172, lng: 20.7314846 },
    { lat: 49.1101261, lng: 20.7307117 },
    { lat: 49.1103743, lng: 20.7306591 },
    { lat: 49.1109098, lng: 20.7308439 },
    { lat: 49.1112893, lng: 20.731105 },
    { lat: 49.1115324, lng: 20.7313971 },
    { lat: 49.1118145, lng: 20.7319256 },
    { lat: 49.1129058, lng: 20.7329019 },
    { lat: 49.1133346, lng: 20.7334789 },
    { lat: 49.1169889, lng: 20.7323058 },
    { lat: 49.1176444, lng: 20.732473 },
    { lat: 49.1193642, lng: 20.7337489 },
    { lat: 49.121916, lng: 20.734455 },
    { lat: 49.121901, lng: 20.734242 },
    { lat: 49.121932, lng: 20.733781 },
    { lat: 49.121973, lng: 20.733674 },
    { lat: 49.122049, lng: 20.733545 },
    { lat: 49.122062, lng: 20.733522 },
    { lat: 49.12208, lng: 20.733373 },
    { lat: 49.122188, lng: 20.73261 },
    { lat: 49.122207, lng: 20.732486 },
    { lat: 49.122288, lng: 20.731966 },
    { lat: 49.12235, lng: 20.731753 },
    { lat: 49.12246, lng: 20.731451 },
    { lat: 49.122492, lng: 20.731126 },
    { lat: 49.12261, lng: 20.730743 },
    { lat: 49.122638, lng: 20.730653 },
    { lat: 49.122576, lng: 20.730408 },
    { lat: 49.122477, lng: 20.730194 },
    { lat: 49.122188, lng: 20.729131 },
    { lat: 49.122178, lng: 20.729096 },
    { lat: 49.122083, lng: 20.72878 },
    { lat: 49.122109, lng: 20.728559 },
    { lat: 49.12211, lng: 20.728538 },
    { lat: 49.122106, lng: 20.728028 },
    { lat: 49.122098, lng: 20.727846 },
    { lat: 49.122082, lng: 20.727525 },
    { lat: 49.121967, lng: 20.727034 },
    { lat: 49.121857, lng: 20.726692 },
    { lat: 49.121723, lng: 20.726534 },
    { lat: 49.121534, lng: 20.726354 },
    { lat: 49.121467, lng: 20.726294 },
    { lat: 49.121353, lng: 20.72619 },
    { lat: 49.121178, lng: 20.726124 },
    { lat: 49.120998, lng: 20.725889 },
    { lat: 49.120904, lng: 20.725772 },
    { lat: 49.120624, lng: 20.72543 },
    { lat: 49.12057, lng: 20.72531 },
    { lat: 49.120572, lng: 20.725183 },
    { lat: 49.120574, lng: 20.725017 },
    { lat: 49.120541, lng: 20.724912 },
    { lat: 49.120496, lng: 20.724856 },
    { lat: 49.120493, lng: 20.724881 },
    { lat: 49.120394, lng: 20.724693 },
    { lat: 49.12039, lng: 20.724789 },
    { lat: 49.120371, lng: 20.72512 },
    { lat: 49.120324, lng: 20.725454 },
    { lat: 49.120261, lng: 20.725869 },
    { lat: 49.11996, lng: 20.726234 },
    { lat: 49.119643, lng: 20.726439 },
    { lat: 49.11927, lng: 20.726514 },
    { lat: 49.118638, lng: 20.726318 },
    { lat: 49.118516, lng: 20.726151 },
    { lat: 49.118427, lng: 20.725582 },
    { lat: 49.1184, lng: 20.725536 },
    { lat: 49.118316, lng: 20.725397 },
    { lat: 49.118164, lng: 20.725175 },
    { lat: 49.117856, lng: 20.724726 },
    { lat: 49.117864, lng: 20.72471 },
    { lat: 49.117893, lng: 20.724657 },
    { lat: 49.117909, lng: 20.724628 },
    { lat: 49.1182909, lng: 20.7239129 },
    { lat: 49.1184824, lng: 20.7230901 },
    { lat: 49.1183601, lng: 20.7220429 },
    { lat: 49.1184248, lng: 20.7210069 },
    { lat: 49.1185897, lng: 20.720352 },
    { lat: 49.1187544, lng: 20.7200108 },
    { lat: 49.1188744, lng: 20.7197419 },
    { lat: 49.1190319, lng: 20.7193851 },
    { lat: 49.1193425, lng: 20.7189701 },
    { lat: 49.1196339, lng: 20.7186454 },
    { lat: 49.1198873, lng: 20.7184256 },
    { lat: 49.1200301, lng: 20.718296 },
    { lat: 49.1202099, lng: 20.7179349 },
    { lat: 49.1203543, lng: 20.7174695 },
    { lat: 49.120499, lng: 20.717163 },
    { lat: 49.1205993, lng: 20.7169791 },
    { lat: 49.1206803, lng: 20.7168147 },
    { lat: 49.1207659, lng: 20.7165924 },
    { lat: 49.1208284, lng: 20.7164201 },
    { lat: 49.1209589, lng: 20.7159998 },
    { lat: 49.1208815, lng: 20.7153704 },
    { lat: 49.1208125, lng: 20.7149193 },
    { lat: 49.120718, lng: 20.7147268 },
    { lat: 49.1206141, lng: 20.7145567 },
    { lat: 49.1205449, lng: 20.7144485 },
    { lat: 49.1201682, lng: 20.7141415 },
    { lat: 49.1198958, lng: 20.7138555 },
    { lat: 49.1195275, lng: 20.7136244 },
    { lat: 49.119383, lng: 20.7131353 },
    { lat: 49.1193056, lng: 20.7123446 },
    { lat: 49.1193722, lng: 20.712033 },
    { lat: 49.1193539, lng: 20.7118027 },
    { lat: 49.1193914, lng: 20.7115563 },
    { lat: 49.1194726, lng: 20.7113035 },
    { lat: 49.1194624, lng: 20.7112073 },
    { lat: 49.1194719, lng: 20.7109625 },
    { lat: 49.1194623, lng: 20.7107243 },
    { lat: 49.1195737, lng: 20.7102807 },
    { lat: 49.1194934, lng: 20.7098564 },
    { lat: 49.1195496, lng: 20.7096645 },
    { lat: 49.1176547, lng: 20.7114037 },
    { lat: 49.1156389, lng: 20.70975 },
    { lat: 49.1136438, lng: 20.7077031 },
    { lat: 49.1123961, lng: 20.7065805 },
    { lat: 49.1111979, lng: 20.7064357 },
    { lat: 49.1103269, lng: 20.7066322 },
    { lat: 49.1100744, lng: 20.7063091 },
    { lat: 49.1086436, lng: 20.7052843 },
    { lat: 49.1067854, lng: 20.7044342 },
    { lat: 49.106854, lng: 20.7041479 },
    { lat: 49.1061365, lng: 20.7041012 },
    { lat: 49.1053242, lng: 20.7032256 },
    { lat: 49.1037488, lng: 20.7013705 },
    { lat: 49.1034807, lng: 20.7016461 },
    { lat: 49.1031871, lng: 20.7010164 },
    { lat: 49.1027915, lng: 20.7009742 },
    { lat: 49.1022341, lng: 20.7008081 },
    { lat: 49.1020086, lng: 20.7000959 },
    { lat: 49.1006886, lng: 20.6990333 },
    { lat: 49.0987899, lng: 20.6980855 },
    { lat: 49.0981995, lng: 20.6975011 },
    { lat: 49.0975424, lng: 20.6973842 },
    { lat: 49.0967669, lng: 20.6967231 },
    { lat: 49.0964755, lng: 20.6965994 },
    { lat: 49.0957892, lng: 20.6960984 },
    { lat: 49.0955948, lng: 20.6958593 },
    { lat: 49.0952089, lng: 20.6951328 },
    { lat: 49.0948314, lng: 20.6947066 },
    { lat: 49.0943291, lng: 20.6936524 },
    { lat: 49.0941131, lng: 20.693848 },
    { lat: 49.093827, lng: 20.6933033 },
    { lat: 49.0936815, lng: 20.6935182 },
    { lat: 49.0931176, lng: 20.6929504 },
    { lat: 49.0925693, lng: 20.692761 },
    { lat: 49.0923177, lng: 20.6925838 },
    { lat: 49.0916626, lng: 20.692363 },
    { lat: 49.0914769, lng: 20.6919178 },
    { lat: 49.0911336, lng: 20.6916677 },
    { lat: 49.0908834, lng: 20.6918173 },
    { lat: 49.090645, lng: 20.6918585 },
    { lat: 49.0900658, lng: 20.691617 },
    { lat: 49.0892425, lng: 20.6897968 },
    { lat: 49.0871605, lng: 20.6875056 },
    { lat: 49.0870852, lng: 20.6874124 },
  ],
  Dúbrava: [
    { lat: 49.002051, lng: 20.877787 },
    { lat: 49.0017302, lng: 20.8766015 },
    { lat: 49.0016079, lng: 20.8765147 },
    { lat: 49.0016694, lng: 20.8763664 },
    { lat: 49.001634, lng: 20.8758243 },
    { lat: 49.0015881, lng: 20.8757318 },
    { lat: 49.0016494, lng: 20.8752162 },
    { lat: 49.0015874, lng: 20.87419 },
    { lat: 49.0016838, lng: 20.872988 },
    { lat: 49.0016626, lng: 20.8726641 },
    { lat: 49.0014897, lng: 20.8720131 },
    { lat: 49.001532, lng: 20.8712039 },
    { lat: 49.0008918, lng: 20.8707169 },
    { lat: 49.0006035, lng: 20.8702509 },
    { lat: 49.0005136, lng: 20.8698547 },
    { lat: 49.0005367, lng: 20.8694123 },
    { lat: 49.0004891, lng: 20.8690741 },
    { lat: 49.0006038, lng: 20.8685069 },
    { lat: 48.9958397, lng: 20.8644284 },
    { lat: 48.9941947, lng: 20.8599613 },
    { lat: 48.9938417, lng: 20.8574639 },
    { lat: 48.9938457, lng: 20.857283 },
    { lat: 48.9939821, lng: 20.8568647 },
    { lat: 48.9940355, lng: 20.856383 },
    { lat: 48.9941564, lng: 20.8559043 },
    { lat: 48.9942304, lng: 20.8550059 },
    { lat: 48.9941934, lng: 20.8545773 },
    { lat: 48.9938554, lng: 20.8536183 },
    { lat: 48.9938005, lng: 20.8528362 },
    { lat: 48.9934613, lng: 20.8510131 },
    { lat: 48.9929091, lng: 20.8498746 },
    { lat: 48.9928233, lng: 20.8498696 },
    { lat: 48.9926611, lng: 20.8497017 },
    { lat: 48.9926262, lng: 20.8497694 },
    { lat: 48.992379, lng: 20.8490244 },
    { lat: 48.9924106, lng: 20.8485627 },
    { lat: 48.9922997, lng: 20.8482514 },
    { lat: 48.9921691, lng: 20.8480535 },
    { lat: 48.992128, lng: 20.8477424 },
    { lat: 48.9920757, lng: 20.8476634 },
    { lat: 48.9920561, lng: 20.847447 },
    { lat: 48.9918582, lng: 20.8467326 },
    { lat: 48.9918217, lng: 20.8463896 },
    { lat: 48.9917597, lng: 20.8462873 },
    { lat: 48.9917976, lng: 20.8460786 },
    { lat: 48.9917889, lng: 20.8456929 },
    { lat: 48.9918722, lng: 20.8454673 },
    { lat: 48.99152, lng: 20.8441858 },
    { lat: 48.9915381, lng: 20.8440271 },
    { lat: 48.991227, lng: 20.843551 },
    { lat: 48.9911085, lng: 20.8435424 },
    { lat: 48.9910846, lng: 20.8434357 },
    { lat: 48.9911336, lng: 20.8433939 },
    { lat: 48.9911339, lng: 20.8433003 },
    { lat: 48.9908121, lng: 20.8431727 },
    { lat: 48.9908416, lng: 20.8430141 },
    { lat: 48.990597, lng: 20.8428786 },
    { lat: 48.9904224, lng: 20.8425731 },
    { lat: 48.990446, lng: 20.8424433 },
    { lat: 48.9902874, lng: 20.841657 },
    { lat: 48.9903503, lng: 20.8411308 },
    { lat: 48.9902524, lng: 20.8408967 },
    { lat: 48.990424, lng: 20.839931 },
    { lat: 48.99007, lng: 20.840052 },
    { lat: 48.989678, lng: 20.83918 },
    { lat: 48.989529, lng: 20.838674 },
    { lat: 48.989486, lng: 20.838513 },
    { lat: 48.989415, lng: 20.838278 },
    { lat: 48.989301, lng: 20.83787 },
    { lat: 48.989158, lng: 20.837548 },
    { lat: 48.989262, lng: 20.836822 },
    { lat: 48.989195, lng: 20.835971 },
    { lat: 48.989148, lng: 20.835039 },
    { lat: 48.988898, lng: 20.834426 },
    { lat: 48.988623, lng: 20.833634 },
    { lat: 48.988005, lng: 20.832605 },
    { lat: 48.987324, lng: 20.832013 },
    { lat: 48.986223, lng: 20.833198 },
    { lat: 48.98593, lng: 20.832591 },
    { lat: 48.986102, lng: 20.831493 },
    { lat: 48.985943, lng: 20.830866 },
    { lat: 48.985711, lng: 20.831363 },
    { lat: 48.985602, lng: 20.828731 },
    { lat: 48.985192, lng: 20.82814 },
    { lat: 48.984174, lng: 20.826747 },
    { lat: 48.984167, lng: 20.826187 },
    { lat: 48.983923, lng: 20.824729 },
    { lat: 48.983734, lng: 20.824772 },
    { lat: 48.983385, lng: 20.823264 },
    { lat: 48.98278, lng: 20.822204 },
    { lat: 48.982199, lng: 20.821639 },
    { lat: 48.981796, lng: 20.821277 },
    { lat: 48.981339, lng: 20.819768 },
    { lat: 48.980956, lng: 20.818504 },
    { lat: 48.98061, lng: 20.817728 },
    { lat: 48.979958, lng: 20.818044 },
    { lat: 48.979112, lng: 20.818476 },
    { lat: 48.97877, lng: 20.817426 },
    { lat: 48.977237, lng: 20.819946 },
    { lat: 48.977213, lng: 20.820042 },
    { lat: 48.977509, lng: 20.820409 },
    { lat: 48.977432, lng: 20.820486 },
    { lat: 48.97742, lng: 20.820498 },
    { lat: 48.977407, lng: 20.820511 },
    { lat: 48.976216, lng: 20.8217 },
    { lat: 48.975214, lng: 20.822768 },
    { lat: 48.975051, lng: 20.823888 },
    { lat: 48.974695, lng: 20.825063 },
    { lat: 48.974643, lng: 20.825234 },
    { lat: 48.974524, lng: 20.825624 },
    { lat: 48.974269, lng: 20.826386 },
    { lat: 48.973892, lng: 20.827136 },
    { lat: 48.973454, lng: 20.828855 },
    { lat: 48.973441, lng: 20.828886 },
    { lat: 48.973677, lng: 20.830646 },
    { lat: 48.9736719, lng: 20.831946 },
    { lat: 48.973671, lng: 20.832188 },
    { lat: 48.973189, lng: 20.832879 },
    { lat: 48.973317, lng: 20.83426 },
    { lat: 48.97476, lng: 20.835273 },
    { lat: 48.975093, lng: 20.835944 },
    { lat: 48.975383, lng: 20.837019 },
    { lat: 48.975609, lng: 20.838891 },
    { lat: 48.975479, lng: 20.839989 },
    { lat: 48.974799, lng: 20.841849 },
    { lat: 48.974259, lng: 20.843649 },
    { lat: 48.974018, lng: 20.84465 },
    { lat: 48.974277, lng: 20.846436 },
    { lat: 48.974559, lng: 20.847247 },
    { lat: 48.9747981, lng: 20.8475109 },
    { lat: 48.974902, lng: 20.849994 },
    { lat: 48.9745737, lng: 20.8478308 },
    { lat: 48.9744008, lng: 20.8473971 },
    { lat: 48.974027, lng: 20.846739 },
    { lat: 48.973763, lng: 20.845722 },
    { lat: 48.973652, lng: 20.844683 },
    { lat: 48.973681, lng: 20.844247 },
    { lat: 48.973821, lng: 20.843677 },
    { lat: 48.973741, lng: 20.843139 },
    { lat: 48.97113, lng: 20.84241 },
    { lat: 48.970487, lng: 20.841257 },
    { lat: 48.969809, lng: 20.841718 },
    { lat: 48.969992, lng: 20.842573 },
    { lat: 48.97038, lng: 20.84387 },
    { lat: 48.970397, lng: 20.843933 },
    { lat: 48.970428, lng: 20.844676 },
    { lat: 48.97045, lng: 20.845749 },
    { lat: 48.97057, lng: 20.846477 },
    { lat: 48.970661, lng: 20.847028 },
    { lat: 48.970243, lng: 20.848368 },
    { lat: 48.970596, lng: 20.849692 },
    { lat: 48.970636, lng: 20.85075 },
    { lat: 48.970699, lng: 20.850965 },
    { lat: 48.970714, lng: 20.851017 },
    { lat: 48.971081, lng: 20.852277 },
    { lat: 48.972381, lng: 20.854837 },
    { lat: 48.972838, lng: 20.855872 },
    { lat: 48.973126, lng: 20.856493 },
    { lat: 48.972834, lng: 20.856911 },
    { lat: 48.973475, lng: 20.859272 },
    { lat: 48.973684, lng: 20.859723 },
    { lat: 48.973883, lng: 20.860369 },
    { lat: 48.974093, lng: 20.861202 },
    { lat: 48.974388, lng: 20.862812 },
    { lat: 48.974454, lng: 20.863538 },
    { lat: 48.97446, lng: 20.863721 },
    { lat: 48.97446, lng: 20.863773 },
    { lat: 48.974395, lng: 20.865018 },
    { lat: 48.974508, lng: 20.865766 },
    { lat: 48.974713, lng: 20.866063 },
    { lat: 48.974757, lng: 20.866127 },
    { lat: 48.975076, lng: 20.86659 },
    { lat: 48.975268, lng: 20.867107 },
    { lat: 48.975369, lng: 20.867438 },
    { lat: 48.975408, lng: 20.867593 },
    { lat: 48.975679, lng: 20.868625 },
    { lat: 48.975763, lng: 20.868995 },
    { lat: 48.976098, lng: 20.870034 },
    { lat: 48.976143, lng: 20.870796 },
    { lat: 48.976148, lng: 20.870877 },
    { lat: 48.97625, lng: 20.872586 },
    { lat: 48.973898, lng: 20.872807 },
    { lat: 48.975151, lng: 20.876552 },
    { lat: 48.975171, lng: 20.876613 },
    { lat: 48.97538, lng: 20.877237 },
    { lat: 48.975779, lng: 20.878037 },
    { lat: 48.9771, lng: 20.88194 },
    { lat: 48.977867, lng: 20.883148 },
    { lat: 48.978283, lng: 20.886111 },
    { lat: 48.978509, lng: 20.886877 },
    { lat: 48.979737, lng: 20.886057 },
    { lat: 48.980033, lng: 20.885812 },
    { lat: 48.980374, lng: 20.884611 },
    { lat: 48.980826, lng: 20.882961 },
    { lat: 48.980959, lng: 20.883 },
    { lat: 48.98129, lng: 20.883071 },
    { lat: 48.981683, lng: 20.883001 },
    { lat: 48.98203, lng: 20.883145 },
    { lat: 48.982247, lng: 20.883236 },
    { lat: 48.982793, lng: 20.883464 },
    { lat: 48.982838, lng: 20.883475 },
    { lat: 48.983236, lng: 20.883576 },
    { lat: 48.983857, lng: 20.883732 },
    { lat: 48.985022, lng: 20.884378 },
    { lat: 48.985783, lng: 20.882727 },
    { lat: 48.986546, lng: 20.881151 },
    { lat: 48.987018, lng: 20.879929 },
    { lat: 48.98785, lng: 20.8784 },
    { lat: 48.988255, lng: 20.877592 },
    { lat: 48.988589, lng: 20.877161 },
    { lat: 48.988645, lng: 20.876996 },
    { lat: 48.990103, lng: 20.87745 },
    { lat: 48.991097, lng: 20.877922 },
    { lat: 48.991462, lng: 20.878628 },
    { lat: 48.992294, lng: 20.879481 },
    { lat: 48.992534, lng: 20.879594 },
    { lat: 48.99261, lng: 20.879579 },
    { lat: 48.992747, lng: 20.879659 },
    { lat: 48.992893, lng: 20.879674 },
    { lat: 48.992957, lng: 20.879746 },
    { lat: 48.993059, lng: 20.87986 },
    { lat: 48.993201, lng: 20.879852 },
    { lat: 48.993358, lng: 20.880057 },
    { lat: 48.993374, lng: 20.880161 },
    { lat: 48.993435, lng: 20.880179 },
    { lat: 48.993461, lng: 20.880327 },
    { lat: 48.993505, lng: 20.880339 },
    { lat: 48.993543, lng: 20.880411 },
    { lat: 48.993618, lng: 20.880426 },
    { lat: 48.993637, lng: 20.880501 },
    { lat: 48.993706, lng: 20.88057 },
    { lat: 48.993763, lng: 20.88077 },
    { lat: 48.993817, lng: 20.880786 },
    { lat: 48.993882, lng: 20.880906 },
    { lat: 48.993915, lng: 20.880912 },
    { lat: 48.994097, lng: 20.881305 },
    { lat: 48.99417, lng: 20.881377 },
    { lat: 48.994226, lng: 20.881519 },
    { lat: 48.994291, lng: 20.8815 },
    { lat: 48.994428, lng: 20.881611 },
    { lat: 48.995052, lng: 20.882322 },
    { lat: 48.995163, lng: 20.882512 },
    { lat: 48.995193, lng: 20.882519 },
    { lat: 48.995319, lng: 20.882702 },
    { lat: 48.995412, lng: 20.882789 },
    { lat: 48.995454, lng: 20.882962 },
    { lat: 48.995503, lng: 20.883011 },
    { lat: 48.995539, lng: 20.883174 },
    { lat: 48.995577, lng: 20.883214 },
    { lat: 48.995602, lng: 20.883309 },
    { lat: 48.995669, lng: 20.883345 },
    { lat: 48.99578, lng: 20.883639 },
    { lat: 48.995808, lng: 20.883858 },
    { lat: 48.995881, lng: 20.884093 },
    { lat: 48.995855, lng: 20.884199 },
    { lat: 48.995913, lng: 20.884233 },
    { lat: 48.995954, lng: 20.884435 },
    { lat: 48.995941, lng: 20.884506 },
    { lat: 48.996069, lng: 20.884523 },
    { lat: 48.996157, lng: 20.884684 },
    { lat: 48.996202, lng: 20.88469 },
    { lat: 48.996289, lng: 20.884831 },
    { lat: 48.996352, lng: 20.884845 },
    { lat: 48.996407, lng: 20.885004 },
    { lat: 48.996443, lng: 20.885109 },
    { lat: 48.99646, lng: 20.88516 },
    { lat: 48.99658, lng: 20.885222 },
    { lat: 48.99673, lng: 20.885449 },
    { lat: 48.99684, lng: 20.885496 },
    { lat: 48.996949, lng: 20.885635 },
    { lat: 48.99701, lng: 20.885647 },
    { lat: 48.997043, lng: 20.885706 },
    { lat: 48.99712, lng: 20.885684 },
    { lat: 48.99725, lng: 20.885702 },
    { lat: 48.997286, lng: 20.885774 },
    { lat: 48.997643, lng: 20.886015 },
    { lat: 48.997999, lng: 20.886126 },
    { lat: 48.998071, lng: 20.886259 },
    { lat: 48.998093, lng: 20.886313 },
    { lat: 48.998247, lng: 20.88641 },
    { lat: 48.998393, lng: 20.886433 },
    { lat: 48.998406, lng: 20.88673 },
    { lat: 48.99842, lng: 20.886756 },
    { lat: 48.998464, lng: 20.8866187 },
    { lat: 48.998495, lng: 20.886476 },
    { lat: 48.99856, lng: 20.886296 },
    { lat: 48.99855, lng: 20.886106 },
    { lat: 48.998502, lng: 20.885947 },
    { lat: 48.998475, lng: 20.885782 },
    { lat: 48.998403, lng: 20.885596 },
    { lat: 48.998398, lng: 20.885534 },
    { lat: 48.99847, lng: 20.885389 },
    { lat: 48.998662, lng: 20.885152 },
    { lat: 48.998667, lng: 20.884578 },
    { lat: 48.998693, lng: 20.88439 },
    { lat: 48.998678, lng: 20.884206 },
    { lat: 48.998685, lng: 20.883972 },
    { lat: 48.998618, lng: 20.883755 },
    { lat: 48.998577, lng: 20.883576 },
    { lat: 48.998481, lng: 20.883338 },
    { lat: 48.998435, lng: 20.883126 },
    { lat: 48.998433, lng: 20.883116 },
    { lat: 48.998444, lng: 20.883021 },
    { lat: 48.998444, lng: 20.882739 },
    { lat: 48.998475, lng: 20.882575 },
    { lat: 48.998487, lng: 20.882315 },
    { lat: 48.998511, lng: 20.882249 },
    { lat: 48.9984734, lng: 20.8820931 },
    { lat: 48.9984894, lng: 20.8819009 },
    { lat: 48.998514, lng: 20.881858 },
    { lat: 48.998528, lng: 20.881771 },
    { lat: 48.998512, lng: 20.881705 },
    { lat: 48.998513, lng: 20.881675 },
    { lat: 48.998576, lng: 20.881583 },
    { lat: 48.998725, lng: 20.881574 },
    { lat: 48.998831, lng: 20.881459 },
    { lat: 48.998958, lng: 20.881379 },
    { lat: 48.999002, lng: 20.881317 },
    { lat: 48.99911, lng: 20.881283 },
    { lat: 48.999244, lng: 20.881194 },
    { lat: 48.999286, lng: 20.881091 },
    { lat: 48.999369, lng: 20.881065 },
    { lat: 48.999435, lng: 20.881012 },
    { lat: 48.999491, lng: 20.880844 },
    { lat: 48.999554, lng: 20.880763 },
    { lat: 48.9996, lng: 20.880671 },
    { lat: 48.999684, lng: 20.880574 },
    { lat: 48.999795, lng: 20.880568 },
    { lat: 48.999828, lng: 20.880508 },
    { lat: 48.999908, lng: 20.880277 },
    { lat: 48.999952, lng: 20.880195 },
    { lat: 49.000052, lng: 20.880108 },
    { lat: 49.000145, lng: 20.880108 },
    { lat: 49.000194, lng: 20.880124 },
    { lat: 49.000313, lng: 20.879987 },
    { lat: 49.000398, lng: 20.879931 },
    { lat: 49.000463, lng: 20.879803 },
    { lat: 49.000474, lng: 20.8795842 },
    { lat: 49.0005274, lng: 20.8794802 },
    { lat: 49.0005736, lng: 20.879416 },
    { lat: 49.000719, lng: 20.879322 },
    { lat: 49.000783, lng: 20.879327 },
    { lat: 49.000869, lng: 20.879274 },
    { lat: 49.000953, lng: 20.879221 },
    { lat: 49.001112, lng: 20.879095 },
    { lat: 49.001127, lng: 20.878951 },
    { lat: 49.001182, lng: 20.878891 },
    { lat: 49.001227, lng: 20.878799 },
    { lat: 49.001292, lng: 20.87873 },
    { lat: 49.001391, lng: 20.878672 },
    { lat: 49.001469, lng: 20.878598 },
    { lat: 49.001551, lng: 20.878392 },
    { lat: 49.001632, lng: 20.878261 },
    { lat: 49.00169, lng: 20.87813 },
    { lat: 49.00175, lng: 20.878079 },
    { lat: 49.001815, lng: 20.878045 },
    { lat: 49.001901, lng: 20.878047 },
    { lat: 49.001957, lng: 20.877973 },
    { lat: 49.002051, lng: 20.877787 },
  ],
  Brutovce: [
    { lat: 49.0675823, lng: 20.7781553 },
    { lat: 49.0679078, lng: 20.7790653 },
    { lat: 49.0684011, lng: 20.7825048 },
    { lat: 49.0692618, lng: 20.7852816 },
    { lat: 49.0692743, lng: 20.7863569 },
    { lat: 49.0701213, lng: 20.7892577 },
    { lat: 49.0711497, lng: 20.7922287 },
    { lat: 49.0718969, lng: 20.7930368 },
    { lat: 49.0714527, lng: 20.7929806 },
    { lat: 49.0712853, lng: 20.796047 },
    { lat: 49.0725425, lng: 20.7977965 },
    { lat: 49.0734192, lng: 20.7987364 },
    { lat: 49.0741063, lng: 20.7993017 },
    { lat: 49.0748016, lng: 20.8003037 },
    { lat: 49.0768228, lng: 20.8047162 },
    { lat: 49.077121, lng: 20.8057914 },
    { lat: 49.0773254, lng: 20.8069835 },
    { lat: 49.0772943, lng: 20.811855 },
    { lat: 49.0774175, lng: 20.8130404 },
    { lat: 49.0773659, lng: 20.814089 },
    { lat: 49.0771564, lng: 20.8151014 },
    { lat: 49.0769346, lng: 20.8159119 },
    { lat: 49.0754813, lng: 20.8191471 },
    { lat: 49.074899, lng: 20.8211977 },
    { lat: 49.0747425, lng: 20.8239086 },
    { lat: 49.0743841, lng: 20.8256967 },
    { lat: 49.074381, lng: 20.8264559 },
    { lat: 49.0747439, lng: 20.8268639 },
    { lat: 49.0745952, lng: 20.8271235 },
    { lat: 49.0743619, lng: 20.8273747 },
    { lat: 49.0743689, lng: 20.827381 },
    { lat: 49.0744606, lng: 20.8274624 },
    { lat: 49.0744118, lng: 20.8277438 },
    { lat: 49.0748318, lng: 20.8282072 },
    { lat: 49.0750439, lng: 20.8279957 },
    { lat: 49.0752863, lng: 20.8279398 },
    { lat: 49.0755676, lng: 20.8281354 },
    { lat: 49.0757691, lng: 20.8280019 },
    { lat: 49.0758875, lng: 20.828172 },
    { lat: 49.0758761, lng: 20.8285276 },
    { lat: 49.0759499, lng: 20.8287821 },
    { lat: 49.076273, lng: 20.8288748 },
    { lat: 49.077372, lng: 20.829761 },
    { lat: 49.077456, lng: 20.82957 },
    { lat: 49.07732, lng: 20.829453 },
    { lat: 49.077356, lng: 20.829366 },
    { lat: 49.077384, lng: 20.829296 },
    { lat: 49.07741, lng: 20.829233 },
    { lat: 49.077436, lng: 20.829181 },
    { lat: 49.077836, lng: 20.828671 },
    { lat: 49.077834, lng: 20.828558 },
    { lat: 49.078255, lng: 20.828308 },
    { lat: 49.080868, lng: 20.825283 },
    { lat: 49.08116, lng: 20.825048 },
    { lat: 49.082144, lng: 20.824252 },
    { lat: 49.08288, lng: 20.824444 },
    { lat: 49.083476, lng: 20.825438 },
    { lat: 49.083869, lng: 20.824831 },
    { lat: 49.085022, lng: 20.822352 },
    { lat: 49.088199, lng: 20.817822 },
    { lat: 49.088961, lng: 20.815859 },
    { lat: 49.091026, lng: 20.813175 },
    { lat: 49.093092, lng: 20.811336 },
    { lat: 49.093307, lng: 20.810465 },
    { lat: 49.093418, lng: 20.810357 },
    { lat: 49.093662, lng: 20.809907 },
    { lat: 49.093964, lng: 20.809553 },
    { lat: 49.093966, lng: 20.809558 },
    { lat: 49.09419, lng: 20.809413 },
    { lat: 49.095029, lng: 20.808812 },
    { lat: 49.096822, lng: 20.807293 },
    { lat: 49.09897, lng: 20.805443 },
    { lat: 49.09965, lng: 20.804798 },
    { lat: 49.100509, lng: 20.80402 },
    { lat: 49.101104, lng: 20.803325 },
    { lat: 49.101846, lng: 20.802997 },
    { lat: 49.101979, lng: 20.802997 },
    { lat: 49.10216, lng: 20.802878 },
    { lat: 49.102475, lng: 20.802737 },
    { lat: 49.102709, lng: 20.802684 },
    { lat: 49.10315, lng: 20.802501 },
    { lat: 49.103421, lng: 20.802191 },
    { lat: 49.104101, lng: 20.801563 },
    { lat: 49.1043, lng: 20.800726 },
    { lat: 49.104769, lng: 20.800235 },
    { lat: 49.104892, lng: 20.80011 },
    { lat: 49.105022, lng: 20.799979 },
    { lat: 49.105166, lng: 20.799858 },
    { lat: 49.10528, lng: 20.799716 },
    { lat: 49.105567, lng: 20.799435 },
    { lat: 49.105693, lng: 20.799226 },
    { lat: 49.105961, lng: 20.798956 },
    { lat: 49.106145, lng: 20.798943 },
    { lat: 49.106273, lng: 20.798862 },
    { lat: 49.106385, lng: 20.798624 },
    { lat: 49.106472, lng: 20.798544 },
    { lat: 49.106546, lng: 20.798398 },
    { lat: 49.106709, lng: 20.798133 },
    { lat: 49.106877, lng: 20.797459 },
    { lat: 49.106903, lng: 20.796898 },
    { lat: 49.107158, lng: 20.796334 },
    { lat: 49.107385, lng: 20.795925 },
    { lat: 49.107661, lng: 20.795603 },
    { lat: 49.108341, lng: 20.794841 },
    { lat: 49.108864, lng: 20.793973 },
    { lat: 49.109028, lng: 20.793624 },
    { lat: 49.109085, lng: 20.79336 },
    { lat: 49.109573, lng: 20.79257 },
    { lat: 49.110045, lng: 20.791516 },
    { lat: 49.1107, lng: 20.790183 },
    { lat: 49.111413, lng: 20.788433 },
    { lat: 49.111879, lng: 20.788043 },
    { lat: 49.11215, lng: 20.78806 },
    { lat: 49.112453, lng: 20.787901 },
    { lat: 49.113072, lng: 20.786978 },
    { lat: 49.1132, lng: 20.786083 },
    { lat: 49.113646, lng: 20.785443 },
    { lat: 49.113768, lng: 20.784581 },
    { lat: 49.11386, lng: 20.783743 },
    { lat: 49.114062, lng: 20.781905 },
    { lat: 49.11335, lng: 20.781255 },
    { lat: 49.113088, lng: 20.780848 },
    { lat: 49.112429, lng: 20.780264 },
    { lat: 49.112239, lng: 20.779992 },
    { lat: 49.111832, lng: 20.779144 },
    { lat: 49.112843, lng: 20.777232 },
    { lat: 49.112519, lng: 20.776531 },
    { lat: 49.11243, lng: 20.775966 },
    { lat: 49.112228, lng: 20.775283 },
    { lat: 49.112213, lng: 20.774965 },
    { lat: 49.111826, lng: 20.774104 },
    { lat: 49.111735, lng: 20.773847 },
    { lat: 49.111235, lng: 20.772956 },
    { lat: 49.111127, lng: 20.772317 },
    { lat: 49.110953, lng: 20.770198 },
    { lat: 49.110706, lng: 20.770097 },
    { lat: 49.110648, lng: 20.769813 },
    { lat: 49.109993, lng: 20.769416 },
    { lat: 49.109445, lng: 20.768909 },
    { lat: 49.108522, lng: 20.768238 },
    { lat: 49.109037, lng: 20.765671 },
    { lat: 49.1076937, lng: 20.764838 },
    { lat: 49.1075953, lng: 20.7662165 },
    { lat: 49.1071571, lng: 20.7667848 },
    { lat: 49.1059604, lng: 20.7675868 },
    { lat: 49.1047848, lng: 20.7679667 },
    { lat: 49.103679, lng: 20.76908 },
    { lat: 49.1026161, lng: 20.7727906 },
    { lat: 49.1023199, lng: 20.7736245 },
    { lat: 49.1019907, lng: 20.7740958 },
    { lat: 49.1011136, lng: 20.7709832 },
    { lat: 49.0997535, lng: 20.7656158 },
    { lat: 49.0992399, lng: 20.7660844 },
    { lat: 49.0989706, lng: 20.7661407 },
    { lat: 49.0986218, lng: 20.7663115 },
    { lat: 49.0981273, lng: 20.7669442 },
    { lat: 49.0979001, lng: 20.7674371 },
    { lat: 49.0970768, lng: 20.7686179 },
    { lat: 49.0965518, lng: 20.7691211 },
    { lat: 49.0957809, lng: 20.7695114 },
    { lat: 49.0948061, lng: 20.7696415 },
    { lat: 49.0942794, lng: 20.7698026 },
    { lat: 49.0943891, lng: 20.7690448 },
    { lat: 49.0957464, lng: 20.7679061 },
    { lat: 49.0962393, lng: 20.7669359 },
    { lat: 49.0965364, lng: 20.7661278 },
    { lat: 49.0968039, lng: 20.76521 },
    { lat: 49.0970737, lng: 20.7648167 },
    { lat: 49.0955241, lng: 20.7621821 },
    { lat: 49.0926829, lng: 20.7646935 },
    { lat: 49.089463, lng: 20.7672227 },
    { lat: 49.0882172, lng: 20.7677791 },
    { lat: 49.0872178, lng: 20.7684966 },
    { lat: 49.0873027, lng: 20.7685717 },
    { lat: 49.0871075, lng: 20.7687828 },
    { lat: 49.0863871, lng: 20.7693646 },
    { lat: 49.0856303, lng: 20.7698068 },
    { lat: 49.0838287, lng: 20.7706612 },
    { lat: 49.082574, lng: 20.7710634 },
    { lat: 49.0822794, lng: 20.7712326 },
    { lat: 49.0816574, lng: 20.7717964 },
    { lat: 49.0813654, lng: 20.7719797 },
    { lat: 49.0806615, lng: 20.7721931 },
    { lat: 49.0800829, lng: 20.7722887 },
    { lat: 49.0780259, lng: 20.7723043 },
    { lat: 49.0766413, lng: 20.7724663 },
    { lat: 49.0757424, lng: 20.7727701 },
    { lat: 49.0744858, lng: 20.773047 },
    { lat: 49.072879, lng: 20.7737415 },
    { lat: 49.0723541, lng: 20.7738969 },
    { lat: 49.0704033, lng: 20.7742787 },
    { lat: 49.0702513, lng: 20.7744395 },
    { lat: 49.0701523, lng: 20.7754143 },
    { lat: 49.0699716, lng: 20.7758152 },
    { lat: 49.0693137, lng: 20.7765439 },
    { lat: 49.0678495, lng: 20.7779101 },
    { lat: 49.0675823, lng: 20.7781553 },
  ],
  VyšnéRepaše: [
    { lat: 49.078358, lng: 20.6685422 },
    { lat: 49.0783274, lng: 20.6685045 },
    { lat: 49.0780428, lng: 20.6682904 },
    { lat: 49.0779175, lng: 20.6682742 },
    { lat: 49.0777687, lng: 20.6681102 },
    { lat: 49.0774069, lng: 20.6679405 },
    { lat: 49.0772744, lng: 20.6677971 },
    { lat: 49.0772006, lng: 20.6678118 },
    { lat: 49.0769466, lng: 20.6676361 },
    { lat: 49.0767098, lng: 20.6677483 },
    { lat: 49.0759007, lng: 20.6676304 },
    { lat: 49.0751319, lng: 20.6676614 },
    { lat: 49.0749013, lng: 20.6676062 },
    { lat: 49.0744918, lng: 20.6677381 },
    { lat: 49.0742849, lng: 20.6676507 },
    { lat: 49.0737933, lng: 20.6676257 },
    { lat: 49.0735878, lng: 20.6675175 },
    { lat: 49.0732186, lng: 20.6675168 },
    { lat: 49.0730001, lng: 20.6676096 },
    { lat: 49.0727501, lng: 20.6675448 },
    { lat: 49.0705468, lng: 20.6664929 },
    { lat: 49.0694587, lng: 20.664873 },
    { lat: 49.0691874, lng: 20.6639304 },
    { lat: 49.0687215, lng: 20.663493 },
    { lat: 49.0681484, lng: 20.6626404 },
    { lat: 49.0681523, lng: 20.662433 },
    { lat: 49.066868, lng: 20.6619948 },
    { lat: 49.0654248, lng: 20.6611197 },
    { lat: 49.0646247, lng: 20.6612191 },
    { lat: 49.063035, lng: 20.6610282 },
    { lat: 49.060633, lng: 20.6621046 },
    { lat: 49.0599901, lng: 20.6612526 },
    { lat: 49.0590971, lng: 20.6619943 },
    { lat: 49.059073, lng: 20.6620781 },
    { lat: 49.0585638, lng: 20.6623185 },
    { lat: 49.0583271, lng: 20.6627161 },
    { lat: 49.0581994, lng: 20.6628598 },
    { lat: 49.0580377, lng: 20.662936 },
    { lat: 49.0574352, lng: 20.6629271 },
    { lat: 49.0565931, lng: 20.6626459 },
    { lat: 49.055912, lng: 20.6622702 },
    { lat: 49.0554175, lng: 20.6624195 },
    { lat: 49.0524722, lng: 20.6615266 },
    { lat: 49.0508219, lng: 20.6607088 },
    { lat: 49.0486139, lng: 20.6594068 },
    { lat: 49.0470399, lng: 20.6597767 },
    { lat: 49.0469246, lng: 20.6599237 },
    { lat: 49.0465754, lng: 20.6601978 },
    { lat: 49.0474801, lng: 20.6604116 },
    { lat: 49.0483951, lng: 20.6609032 },
    { lat: 49.0486393, lng: 20.6612468 },
    { lat: 49.049283, lng: 20.6626882 },
    { lat: 49.0500091, lng: 20.6651406 },
    { lat: 49.0502229, lng: 20.6654835 },
    { lat: 49.0510309, lng: 20.666456 },
    { lat: 49.0511896, lng: 20.6665788 },
    { lat: 49.0514591, lng: 20.6670967 },
    { lat: 49.0516897, lng: 20.6674354 },
    { lat: 49.0518423, lng: 20.6675477 },
    { lat: 49.0520801, lng: 20.6679303 },
    { lat: 49.052151, lng: 20.6684351 },
    { lat: 49.0523513, lng: 20.6689971 },
    { lat: 49.0524116, lng: 20.6693636 },
    { lat: 49.0530026, lng: 20.6711878 },
    { lat: 49.0535976, lng: 20.672281 },
    { lat: 49.0537527, lng: 20.6726802 },
    { lat: 49.0542296, lng: 20.6743413 },
    { lat: 49.0543677, lng: 20.6762892 },
    { lat: 49.0545187, lng: 20.6775846 },
    { lat: 49.0543031, lng: 20.6796227 },
    { lat: 49.054299, lng: 20.6805135 },
    { lat: 49.0548782, lng: 20.6833076 },
    { lat: 49.0546026, lng: 20.6850882 },
    { lat: 49.0544592, lng: 20.6865583 },
    { lat: 49.0549281, lng: 20.6890769 },
    { lat: 49.0554125, lng: 20.6899077 },
    { lat: 49.0560728, lng: 20.6907173 },
    { lat: 49.0573736, lng: 20.6926062 },
    { lat: 49.0580814, lng: 20.6938972 },
    { lat: 49.0585839, lng: 20.6949801 },
    { lat: 49.0587603, lng: 20.6956095 },
    { lat: 49.0588001, lng: 20.6958417 },
    { lat: 49.0589056, lng: 20.6972569 },
    { lat: 49.0589064, lng: 20.7006757 },
    { lat: 49.0592684, lng: 20.7035473 },
    { lat: 49.0598469, lng: 20.7075164 },
    { lat: 49.0604835, lng: 20.7088684 },
    { lat: 49.0610802, lng: 20.7084275 },
    { lat: 49.0619732, lng: 20.7096448 },
    { lat: 49.0639919, lng: 20.7113431 },
    { lat: 49.0644552, lng: 20.7115851 },
    { lat: 49.0659829, lng: 20.7130467 },
    { lat: 49.0678145, lng: 20.7142677 },
    { lat: 49.0687383, lng: 20.7152106 },
    { lat: 49.0698068, lng: 20.7156624 },
    { lat: 49.0706234, lng: 20.7166629 },
    { lat: 49.0710851, lng: 20.71625 },
    { lat: 49.0717682, lng: 20.7158982 },
    { lat: 49.0728927, lng: 20.7151966 },
    { lat: 49.0735688, lng: 20.7149592 },
    { lat: 49.0739996, lng: 20.7149817 },
    { lat: 49.074917, lng: 20.7148549 },
    { lat: 49.0749729, lng: 20.7145545 },
    { lat: 49.0749851, lng: 20.7140462 },
    { lat: 49.0751531, lng: 20.7123937 },
    { lat: 49.075653, lng: 20.7110925 },
    { lat: 49.0757143, lng: 20.7107582 },
    { lat: 49.0762063, lng: 20.7098989 },
    { lat: 49.0763346, lng: 20.709358 },
    { lat: 49.0763728, lng: 20.7092641 },
    { lat: 49.0764532, lng: 20.7092428 },
    { lat: 49.0765623, lng: 20.7082518 },
    { lat: 49.076809, lng: 20.7067001 },
    { lat: 49.0770291, lng: 20.7056681 },
    { lat: 49.0771369, lng: 20.7046431 },
    { lat: 49.0774084, lng: 20.70341 },
    { lat: 49.0778969, lng: 20.7033498 },
    { lat: 49.0783544, lng: 20.7019632 },
    { lat: 49.0789647, lng: 20.7003809 },
    { lat: 49.0794362, lng: 20.6989838 },
    { lat: 49.0792752, lng: 20.6977772 },
    { lat: 49.0796532, lng: 20.6975482 },
    { lat: 49.0800935, lng: 20.6974571 },
    { lat: 49.0803509, lng: 20.6971271 },
    { lat: 49.0805739, lng: 20.6965913 },
    { lat: 49.0807393, lng: 20.6959558 },
    { lat: 49.0808227, lng: 20.6952929 },
    { lat: 49.0809092, lng: 20.6943356 },
    { lat: 49.0808629, lng: 20.6936018 },
    { lat: 49.0816039, lng: 20.6934859 },
    { lat: 49.0817383, lng: 20.6933366 },
    { lat: 49.0815205, lng: 20.6927857 },
    { lat: 49.0816487, lng: 20.6924954 },
    { lat: 49.0817187, lng: 20.6925672 },
    { lat: 49.0821206, lng: 20.6923718 },
    { lat: 49.0822175, lng: 20.6926637 },
    { lat: 49.0823445, lng: 20.6925006 },
    { lat: 49.0820814, lng: 20.6918733 },
    { lat: 49.0818171, lng: 20.6917511 },
    { lat: 49.081888, lng: 20.6916275 },
    { lat: 49.0820769, lng: 20.69178 },
    { lat: 49.0824522, lng: 20.6924572 },
    { lat: 49.0827398, lng: 20.6923797 },
    { lat: 49.082567, lng: 20.6921357 },
    { lat: 49.0824376, lng: 20.6918221 },
    { lat: 49.082961, lng: 20.6922038 },
    { lat: 49.0832773, lng: 20.692172 },
    { lat: 49.0837692, lng: 20.6914442 },
    { lat: 49.084079, lng: 20.6906563 },
    { lat: 49.085047, lng: 20.6891673 },
    { lat: 49.085224, lng: 20.6894289 },
    { lat: 49.085126, lng: 20.689678 },
    { lat: 49.0850124, lng: 20.6898048 },
    { lat: 49.0850017, lng: 20.6899152 },
    { lat: 49.08489, lng: 20.6900993 },
    { lat: 49.0841554, lng: 20.6910673 },
    { lat: 49.083997, lng: 20.6913874 },
    { lat: 49.0839918, lng: 20.6916525 },
    { lat: 49.0840853, lng: 20.6918811 },
    { lat: 49.0840731, lng: 20.6920081 },
    { lat: 49.083974, lng: 20.6920411 },
    { lat: 49.0838688, lng: 20.692281 },
    { lat: 49.0836949, lng: 20.6924533 },
    { lat: 49.0836777, lng: 20.6926986 },
    { lat: 49.0845185, lng: 20.693107 },
    { lat: 49.0850178, lng: 20.6915429 },
    { lat: 49.086276, lng: 20.6892877 },
    { lat: 49.0868936, lng: 20.6880663 },
    { lat: 49.0870852, lng: 20.6874124 },
    { lat: 49.087028, lng: 20.6872892 },
    { lat: 49.0863993, lng: 20.6866705 },
    { lat: 49.085881, lng: 20.6860586 },
    { lat: 49.0845613, lng: 20.6839613 },
    { lat: 49.0835693, lng: 20.6817056 },
    { lat: 49.0833984, lng: 20.6806434 },
    { lat: 49.0833679, lng: 20.6798462 },
    { lat: 49.0832788, lng: 20.6791464 },
    { lat: 49.0830076, lng: 20.6779202 },
    { lat: 49.0826112, lng: 20.6769033 },
    { lat: 49.0824637, lng: 20.6763326 },
    { lat: 49.0820754, lng: 20.675677 },
    { lat: 49.0818658, lng: 20.675416 },
    { lat: 49.0815479, lng: 20.6747119 },
    { lat: 49.0816017, lng: 20.6746704 },
    { lat: 49.0811251, lng: 20.673332 },
    { lat: 49.0805042, lng: 20.6725265 },
    { lat: 49.0796344, lng: 20.6709308 },
    { lat: 49.0794335, lng: 20.6706957 },
    { lat: 49.0792855, lng: 20.6706458 },
    { lat: 49.0792591, lng: 20.670508 },
    { lat: 49.0788752, lng: 20.6699386 },
    { lat: 49.0787636, lng: 20.6695888 },
    { lat: 49.0785613, lng: 20.6694113 },
    { lat: 49.0784716, lng: 20.6689738 },
    { lat: 49.0784972, lng: 20.6687196 },
    { lat: 49.078358, lng: 20.6685422 },
  ],
  Buglovce: [
    { lat: 48.9925051, lng: 20.702787 },
    { lat: 48.9924128, lng: 20.7025057 },
    { lat: 48.9926938, lng: 20.7019815 },
    { lat: 48.9928713, lng: 20.7018128 },
    { lat: 48.9935097, lng: 20.7006779 },
    { lat: 48.9936159, lng: 20.7004815 },
    { lat: 48.9937951, lng: 20.6998415 },
    { lat: 48.9938433, lng: 20.699545 },
    { lat: 48.9938022, lng: 20.6990587 },
    { lat: 48.9939054, lng: 20.6979081 },
    { lat: 48.9940214, lng: 20.6974355 },
    { lat: 48.9942256, lng: 20.696967 },
    { lat: 48.9941677, lng: 20.6968929 },
    { lat: 48.9911681, lng: 20.6968133 },
    { lat: 48.9907137, lng: 20.696665 },
    { lat: 48.9905124, lng: 20.6967037 },
    { lat: 48.990569, lng: 20.6960782 },
    { lat: 48.9905092, lng: 20.6952189 },
    { lat: 48.9905696, lng: 20.6942601 },
    { lat: 48.9907502, lng: 20.6931946 },
    { lat: 48.9907776, lng: 20.6931107 },
    { lat: 48.9906849, lng: 20.6928451 },
    { lat: 48.991331, lng: 20.689558 },
    { lat: 48.9921047, lng: 20.6874182 },
    { lat: 48.9913251, lng: 20.6867708 },
    { lat: 48.9906716, lng: 20.6864048 },
    { lat: 48.9898894, lng: 20.6856871 },
    { lat: 48.989546, lng: 20.6852766 },
    { lat: 48.9889035, lng: 20.6850617 },
    { lat: 48.9885265, lng: 20.6850351 },
    { lat: 48.987773, lng: 20.6848351 },
    { lat: 48.9870732, lng: 20.6845253 },
    { lat: 48.9856332, lng: 20.6840475 },
    { lat: 48.9848753, lng: 20.68354 },
    { lat: 48.9846799, lng: 20.683017 },
    { lat: 48.9844482, lng: 20.6818508 },
    { lat: 48.9854175, lng: 20.6803721 },
    { lat: 48.986143, lng: 20.6795359 },
    { lat: 48.9844079, lng: 20.6740727 },
    { lat: 48.9823179, lng: 20.6749727 },
    { lat: 48.9804271, lng: 20.6762455 },
    { lat: 48.9796797, lng: 20.6780119 },
    { lat: 48.9787606, lng: 20.6793843 },
    { lat: 48.9784519, lng: 20.6805107 },
    { lat: 48.9785942, lng: 20.6818532 },
    { lat: 48.9788553, lng: 20.6827968 },
    { lat: 48.9788207, lng: 20.6836845 },
    { lat: 48.9786994, lng: 20.6847341 },
    { lat: 48.9782305, lng: 20.6850673 },
    { lat: 48.9778257, lng: 20.6852644 },
    { lat: 48.9773649, lng: 20.6860172 },
    { lat: 48.9773978, lng: 20.6861202 },
    { lat: 48.9767047, lng: 20.6866607 },
    { lat: 48.976051, lng: 20.68756 },
    { lat: 48.976061, lng: 20.687585 },
    { lat: 48.976763, lng: 20.688403 },
    { lat: 48.977568, lng: 20.689102 },
    { lat: 48.977332, lng: 20.690053 },
    { lat: 48.977161, lng: 20.690559 },
    { lat: 48.977065, lng: 20.690891 },
    { lat: 48.976895, lng: 20.691405 },
    { lat: 48.976806, lng: 20.691569 },
    { lat: 48.97674, lng: 20.691688 },
    { lat: 48.976643, lng: 20.691877 },
    { lat: 48.976381, lng: 20.692875 },
    { lat: 48.976218, lng: 20.69331 },
    { lat: 48.975847, lng: 20.694002 },
    { lat: 48.975602, lng: 20.694355 },
    { lat: 48.975404, lng: 20.69447 },
    { lat: 48.97541, lng: 20.694473 },
    { lat: 48.976842, lng: 20.695498 },
    { lat: 48.977931, lng: 20.697462 },
    { lat: 48.978106, lng: 20.697776 },
    { lat: 48.978327, lng: 20.698386 },
    { lat: 48.978688, lng: 20.699103 },
    { lat: 48.978815, lng: 20.699329 },
    { lat: 48.978842, lng: 20.699523 },
    { lat: 48.978869, lng: 20.699715 },
    { lat: 48.978896, lng: 20.699911 },
    { lat: 48.979231, lng: 20.700453 },
    { lat: 48.979175, lng: 20.700555 },
    { lat: 48.979217, lng: 20.700723 },
    { lat: 48.979293, lng: 20.700812 },
    { lat: 48.9793, lng: 20.700948 },
    { lat: 48.979568, lng: 20.701956 },
    { lat: 48.979677, lng: 20.703165 },
    { lat: 48.979682, lng: 20.703943 },
    { lat: 48.979796, lng: 20.704415 },
    { lat: 48.98011, lng: 20.705575 },
    { lat: 48.980182, lng: 20.705807 },
    { lat: 48.980253, lng: 20.706237 },
    { lat: 48.980292, lng: 20.706444 },
    { lat: 48.980617, lng: 20.707666 },
    { lat: 48.980567, lng: 20.708092 },
    { lat: 48.980501, lng: 20.708296 },
    { lat: 48.980826, lng: 20.709042 },
    { lat: 48.980901, lng: 20.709211 },
    { lat: 48.980953, lng: 20.7093 },
    { lat: 48.98114, lng: 20.709591 },
    { lat: 48.981398, lng: 20.710098 },
    { lat: 48.981567, lng: 20.710381 },
    { lat: 48.98155, lng: 20.71042 },
    { lat: 48.981873, lng: 20.710977 },
    { lat: 48.981951, lng: 20.71105 },
    { lat: 48.9832222, lng: 20.709702 },
    { lat: 48.9840608, lng: 20.7087155 },
    { lat: 48.9842863, lng: 20.7086279 },
    { lat: 48.98535, lng: 20.707767 },
    { lat: 48.9853757, lng: 20.7074118 },
    { lat: 48.9868973, lng: 20.7069302 },
    { lat: 48.9875799, lng: 20.7069679 },
    { lat: 48.9892227, lng: 20.7059996 },
    { lat: 48.9902613, lng: 20.7052855 },
    { lat: 48.9919315, lng: 20.7035323 },
    { lat: 48.9924838, lng: 20.7028257 },
    { lat: 48.9925051, lng: 20.702787 },
  ],
};

export default UnitsLevoča;
