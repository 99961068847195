const UnitsSvidník = {
  JurkovaVoľa: [
    { lat: 49.3290325, lng: 21.5050824 },
    { lat: 49.3288794, lng: 21.5050042 },
    { lat: 49.3288024, lng: 21.5048258 },
    { lat: 49.3286977, lng: 21.5044255 },
    { lat: 49.3286361, lng: 21.5038832 },
    { lat: 49.3286243, lng: 21.5031864 },
    { lat: 49.3287825, lng: 21.5014349 },
    { lat: 49.328746, lng: 21.4996382 },
    { lat: 49.3287688, lng: 21.4983408 },
    { lat: 49.3286472, lng: 21.4963529 },
    { lat: 49.3282788, lng: 21.4936878 },
    { lat: 49.3277027, lng: 21.4922662 },
    { lat: 49.3269566, lng: 21.4906789 },
    { lat: 49.3264613, lng: 21.4893226 },
    { lat: 49.3262793, lng: 21.4885853 },
    { lat: 49.3259131, lng: 21.4864775 },
    { lat: 49.3253266, lng: 21.4846845 },
    { lat: 49.3239916, lng: 21.4828443 },
    { lat: 49.3218958, lng: 21.4809216 },
    { lat: 49.3220039, lng: 21.4803734 },
    { lat: 49.3223166, lng: 21.4798245 },
    { lat: 49.3226568, lng: 21.4794469 },
    { lat: 49.3222878, lng: 21.4785786 },
    { lat: 49.3220887, lng: 21.4778739 },
    { lat: 49.32173, lng: 21.4769701 },
    { lat: 49.3217733, lng: 21.4763245 },
    { lat: 49.3217525, lng: 21.4757968 },
    { lat: 49.3216639, lng: 21.4753693 },
    { lat: 49.3216871, lng: 21.47512 },
    { lat: 49.3216081, lng: 21.4747953 },
    { lat: 49.3217241, lng: 21.4737442 },
    { lat: 49.3215681, lng: 21.4732844 },
    { lat: 49.321604, lng: 21.472736 },
    { lat: 49.3219502, lng: 21.4720906 },
    { lat: 49.3221266, lng: 21.4716161 },
    { lat: 49.3222115, lng: 21.4708116 },
    { lat: 49.3221771, lng: 21.4705488 },
    { lat: 49.3222558, lng: 21.4700393 },
    { lat: 49.322195, lng: 21.469646 },
    { lat: 49.322264, lng: 21.4691487 },
    { lat: 49.3223512, lng: 21.4689366 },
    { lat: 49.3215161, lng: 21.4685711 },
    { lat: 49.3206331, lng: 21.4677934 },
    { lat: 49.3196864, lng: 21.4638937 },
    { lat: 49.3194448, lng: 21.4670733 },
    { lat: 49.3188835, lng: 21.4700966 },
    { lat: 49.3179522, lng: 21.4742589 },
    { lat: 49.3175686, lng: 21.4757891 },
    { lat: 49.317158, lng: 21.4762699 },
    { lat: 49.3162349, lng: 21.476987 },
    { lat: 49.3159152, lng: 21.4776138 },
    { lat: 49.3151307, lng: 21.4785762 },
    { lat: 49.3140756, lng: 21.4801223 },
    { lat: 49.3136068, lng: 21.4813126 },
    { lat: 49.3133111, lng: 21.4817863 },
    { lat: 49.3131499, lng: 21.4822089 },
    { lat: 49.3128933, lng: 21.4832688 },
    { lat: 49.312752, lng: 21.4840748 },
    { lat: 49.312705, lng: 21.4853013 },
    { lat: 49.3124106, lng: 21.4862992 },
    { lat: 49.3122851, lng: 21.4869094 },
    { lat: 49.3119097, lng: 21.4877468 },
    { lat: 49.3110344, lng: 21.4907402 },
    { lat: 49.3102565, lng: 21.4919077 },
    { lat: 49.3102599, lng: 21.4922673 },
    { lat: 49.3096239, lng: 21.4928437 },
    { lat: 49.3097395, lng: 21.4940964 },
    { lat: 49.3094046, lng: 21.4944248 },
    { lat: 49.3093671, lng: 21.4948881 },
    { lat: 49.3089339, lng: 21.4952364 },
    { lat: 49.3090265, lng: 21.4959978 },
    { lat: 49.3089211, lng: 21.4961689 },
    { lat: 49.3086132, lng: 21.496418 },
    { lat: 49.3086576, lng: 21.4969515 },
    { lat: 49.3083312, lng: 21.4978973 },
    { lat: 49.3084785, lng: 21.4987993 },
    { lat: 49.3075371, lng: 21.5004292 },
    { lat: 49.3073794, lng: 21.5011348 },
    { lat: 49.3073277, lng: 21.5018128 },
    { lat: 49.3071864, lng: 21.5027365 },
    { lat: 49.3069465, lng: 21.503679 },
    { lat: 49.3065106, lng: 21.5039365 },
    { lat: 49.3057943, lng: 21.5041034 },
    { lat: 49.3053205, lng: 21.5044543 },
    { lat: 49.3048963, lng: 21.5051242 },
    { lat: 49.3047584, lng: 21.5053789 },
    { lat: 49.3043208, lng: 21.5058182 },
    { lat: 49.3035445, lng: 21.5069228 },
    { lat: 49.3028959, lng: 21.5076915 },
    { lat: 49.3028764, lng: 21.5079592 },
    { lat: 49.3027043, lng: 21.5085907 },
    { lat: 49.3026149, lng: 21.5092549 },
    { lat: 49.3026081, lng: 21.5098036 },
    { lat: 49.3024117, lng: 21.5119241 },
    { lat: 49.3025637, lng: 21.5143102 },
    { lat: 49.3023075, lng: 21.5151931 },
    { lat: 49.3022999, lng: 21.5162725 },
    { lat: 49.3023462, lng: 21.5164846 },
    { lat: 49.3027405, lng: 21.5170577 },
    { lat: 49.3028847, lng: 21.517462 },
    { lat: 49.3036984, lng: 21.517318 },
    { lat: 49.3043045, lng: 21.5174293 },
    { lat: 49.3050237, lng: 21.517244 },
    { lat: 49.3057511, lng: 21.5168656 },
    { lat: 49.3070042, lng: 21.5171061 },
    { lat: 49.3082788, lng: 21.5171385 },
    { lat: 49.3095096, lng: 21.5167474 },
    { lat: 49.3110464, lng: 21.5174648 },
    { lat: 49.311651, lng: 21.5181611 },
    { lat: 49.3132748, lng: 21.5203663 },
    { lat: 49.315694, lng: 21.5254856 },
    { lat: 49.3162936, lng: 21.5262404 },
    { lat: 49.3171371, lng: 21.5269528 },
    { lat: 49.3176996, lng: 21.5272737 },
    { lat: 49.31861, lng: 21.5274819 },
    { lat: 49.3194786, lng: 21.5272861 },
    { lat: 49.3209664, lng: 21.5285424 },
    { lat: 49.3220355, lng: 21.5288401 },
    { lat: 49.3232566, lng: 21.5288122 },
    { lat: 49.3233482, lng: 21.528633 },
    { lat: 49.3247606, lng: 21.52694 },
    { lat: 49.3264414, lng: 21.5254733 },
    { lat: 49.3269799, lng: 21.5217922 },
    { lat: 49.3272268, lng: 21.5175492 },
    { lat: 49.3269079, lng: 21.5151838 },
    { lat: 49.3278265, lng: 21.5104381 },
    { lat: 49.3289768, lng: 21.505304 },
    { lat: 49.3290325, lng: 21.5050824 },
  ],
  Svidnička: [
    { lat: 49.3819938, lng: 21.5444341 },
    { lat: 49.3817723, lng: 21.5449982 },
    { lat: 49.3808144, lng: 21.5466584 },
    { lat: 49.3805792, lng: 21.5469829 },
    { lat: 49.3810917, lng: 21.5476067 },
    { lat: 49.3815587, lng: 21.5490979 },
    { lat: 49.3800459, lng: 21.5504323 },
    { lat: 49.3796785, lng: 21.5519341 },
    { lat: 49.378421, lng: 21.5529087 },
    { lat: 49.377505, lng: 21.5544677 },
    { lat: 49.3764432, lng: 21.5553604 },
    { lat: 49.3760598, lng: 21.55577 },
    { lat: 49.3755099, lng: 21.5560905 },
    { lat: 49.3748865, lng: 21.5562028 },
    { lat: 49.3755516, lng: 21.5575509 },
    { lat: 49.3761251, lng: 21.5583441 },
    { lat: 49.3766343, lng: 21.5595021 },
    { lat: 49.376588, lng: 21.5596582 },
    { lat: 49.3766433, lng: 21.5615142 },
    { lat: 49.3766851, lng: 21.5622114 },
    { lat: 49.3768711, lng: 21.563791 },
    { lat: 49.3760207, lng: 21.5654541 },
    { lat: 49.3753937, lng: 21.5670568 },
    { lat: 49.3751391, lng: 21.5681734 },
    { lat: 49.3747125, lng: 21.5706329 },
    { lat: 49.3745235, lng: 21.5724055 },
    { lat: 49.3749894, lng: 21.5740711 },
    { lat: 49.3749811, lng: 21.5742236 },
    { lat: 49.375168, lng: 21.5753605 },
    { lat: 49.3746981, lng: 21.5779618 },
    { lat: 49.3745926, lng: 21.5787477 },
    { lat: 49.3754984, lng: 21.5811927 },
    { lat: 49.3761929, lng: 21.5818927 },
    { lat: 49.3767566, lng: 21.5830806 },
    { lat: 49.3774022, lng: 21.5848239 },
    { lat: 49.3776645, lng: 21.5860473 },
    { lat: 49.378077, lng: 21.587539 },
    { lat: 49.3792345, lng: 21.5870123 },
    { lat: 49.379799, lng: 21.5865597 },
    { lat: 49.3815167, lng: 21.5857868 },
    { lat: 49.3850441, lng: 21.5846729 },
    { lat: 49.3855956, lng: 21.5846458 },
    { lat: 49.3861158, lng: 21.5847026 },
    { lat: 49.3871815, lng: 21.5844392 },
    { lat: 49.3885372, lng: 21.5837947 },
    { lat: 49.3885686, lng: 21.5839172 },
    { lat: 49.3894344, lng: 21.5832533 },
    { lat: 49.3896553, lng: 21.5830255 },
    { lat: 49.3896995, lng: 21.5829107 },
    { lat: 49.3909708, lng: 21.5816592 },
    { lat: 49.3916897, lng: 21.58081 },
    { lat: 49.392778, lng: 21.5805035 },
    { lat: 49.3930408, lng: 21.5802025 },
    { lat: 49.3935889, lng: 21.5799766 },
    { lat: 49.39382, lng: 21.5806011 },
    { lat: 49.3944719, lng: 21.5814291 },
    { lat: 49.3950069, lng: 21.5822282 },
    { lat: 49.3952633, lng: 21.5828807 },
    { lat: 49.3956478, lng: 21.583275 },
    { lat: 49.3971738, lng: 21.5852063 },
    { lat: 49.3978812, lng: 21.5839032 },
    { lat: 49.3974917, lng: 21.5834821 },
    { lat: 49.3978484, lng: 21.5830002 },
    { lat: 49.3979213, lng: 21.5826267 },
    { lat: 49.3978804, lng: 21.5822903 },
    { lat: 49.3955505, lng: 21.5774922 },
    { lat: 49.3957741, lng: 21.5766722 },
    { lat: 49.396883, lng: 21.5744768 },
    { lat: 49.3973098, lng: 21.5722979 },
    { lat: 49.3972901, lng: 21.5715151 },
    { lat: 49.3969198, lng: 21.5715826 },
    { lat: 49.3972699, lng: 21.5699374 },
    { lat: 49.3973351, lng: 21.5669009 },
    { lat: 49.3972194, lng: 21.5646822 },
    { lat: 49.3973178, lng: 21.5637324 },
    { lat: 49.3974353, lng: 21.5610099 },
    { lat: 49.3973956, lng: 21.5596209 },
    { lat: 49.3971563, lng: 21.5580232 },
    { lat: 49.3971901, lng: 21.5576212 },
    { lat: 49.3971477, lng: 21.5571064 },
    { lat: 49.3971392, lng: 21.5543878 },
    { lat: 49.396907, lng: 21.5526405 },
    { lat: 49.3964393, lng: 21.5533722 },
    { lat: 49.3962997, lng: 21.553126 },
    { lat: 49.3958345, lng: 21.5533082 },
    { lat: 49.3951401, lng: 21.5533353 },
    { lat: 49.3930222, lng: 21.5549989 },
    { lat: 49.3911483, lng: 21.5527917 },
    { lat: 49.3907112, lng: 21.551997 },
    { lat: 49.389576, lng: 21.5513922 },
    { lat: 49.3887374, lng: 21.5513266 },
    { lat: 49.3886975, lng: 21.5511913 },
    { lat: 49.3882957, lng: 21.5507815 },
    { lat: 49.3880527, lng: 21.5506652 },
    { lat: 49.3870897, lng: 21.5498098 },
    { lat: 49.3869929, lng: 21.5496107 },
    { lat: 49.3820449, lng: 21.5444878 },
    { lat: 49.3819938, lng: 21.5444341 },
  ],
  NižnáPisaná: [
    { lat: 49.4146418, lng: 21.5893143 },
    { lat: 49.4140356, lng: 21.5885823 },
    { lat: 49.4137437, lng: 21.5886497 },
    { lat: 49.4133965, lng: 21.5885822 },
    { lat: 49.4129266, lng: 21.5887883 },
    { lat: 49.412385, lng: 21.5886482 },
    { lat: 49.412143, lng: 21.5885237 },
    { lat: 49.4119005, lng: 21.5886197 },
    { lat: 49.4109047, lng: 21.5885701 },
    { lat: 49.4104895, lng: 21.5888059 },
    { lat: 49.4099313, lng: 21.5887951 },
    { lat: 49.4094212, lng: 21.588661 },
    { lat: 49.4089082, lng: 21.5886986 },
    { lat: 49.4082929, lng: 21.5890611 },
    { lat: 49.4078479, lng: 21.5889967 },
    { lat: 49.4077172, lng: 21.58911 },
    { lat: 49.4075771, lng: 21.5890053 },
    { lat: 49.4074178, lng: 21.5891872 },
    { lat: 49.4072839, lng: 21.5890662 },
    { lat: 49.4069417, lng: 21.5892972 },
    { lat: 49.406729, lng: 21.5895783 },
    { lat: 49.4066021, lng: 21.5894633 },
    { lat: 49.405994, lng: 21.5896856 },
    { lat: 49.4059292, lng: 21.5895424 },
    { lat: 49.4058009, lng: 21.5896157 },
    { lat: 49.4056921, lng: 21.5894664 },
    { lat: 49.4054679, lng: 21.5896608 },
    { lat: 49.4041229, lng: 21.5884079 },
    { lat: 49.4035352, lng: 21.5893263 },
    { lat: 49.4023024, lng: 21.590548 },
    { lat: 49.4017798, lng: 21.5906057 },
    { lat: 49.4014025, lng: 21.590507 },
    { lat: 49.4005045, lng: 21.5905779 },
    { lat: 49.4003329, lng: 21.5903134 },
    { lat: 49.3999019, lng: 21.5893279 },
    { lat: 49.3996188, lng: 21.5888919 },
    { lat: 49.3994618, lng: 21.5884647 },
    { lat: 49.397763, lng: 21.5862169 },
    { lat: 49.3971738, lng: 21.5852063 },
    { lat: 49.3956478, lng: 21.583275 },
    { lat: 49.3952633, lng: 21.5828807 },
    { lat: 49.3950069, lng: 21.5822282 },
    { lat: 49.3944719, lng: 21.5814291 },
    { lat: 49.39382, lng: 21.5806011 },
    { lat: 49.3935889, lng: 21.5799766 },
    { lat: 49.3930408, lng: 21.5802025 },
    { lat: 49.392778, lng: 21.5805035 },
    { lat: 49.3916897, lng: 21.58081 },
    { lat: 49.3909708, lng: 21.5816592 },
    { lat: 49.3896995, lng: 21.5829107 },
    { lat: 49.3896553, lng: 21.5830255 },
    { lat: 49.3894344, lng: 21.5832533 },
    { lat: 49.3885686, lng: 21.5839172 },
    { lat: 49.3885372, lng: 21.5837947 },
    { lat: 49.3871815, lng: 21.5844392 },
    { lat: 49.3861158, lng: 21.5847026 },
    { lat: 49.3855956, lng: 21.5846458 },
    { lat: 49.3850441, lng: 21.5846729 },
    { lat: 49.3815167, lng: 21.5857868 },
    { lat: 49.379799, lng: 21.5865597 },
    { lat: 49.3792345, lng: 21.5870123 },
    { lat: 49.378077, lng: 21.587539 },
    { lat: 49.3767362, lng: 21.5887653 },
    { lat: 49.3763341, lng: 21.5898376 },
    { lat: 49.3735877, lng: 21.5905985 },
    { lat: 49.3742749, lng: 21.5916056 },
    { lat: 49.3725478, lng: 21.5929812 },
    { lat: 49.3716339, lng: 21.5949933 },
    { lat: 49.3716154, lng: 21.5952211 },
    { lat: 49.3711693, lng: 21.5954063 },
    { lat: 49.3711126, lng: 21.5967773 },
    { lat: 49.3713855, lng: 21.6008004 },
    { lat: 49.3714114, lng: 21.6023927 },
    { lat: 49.3715362, lng: 21.6023103 },
    { lat: 49.37159, lng: 21.6027934 },
    { lat: 49.3715622, lng: 21.6039066 },
    { lat: 49.3717487, lng: 21.6043093 },
    { lat: 49.3724037, lng: 21.6048143 },
    { lat: 49.3728732, lng: 21.6053605 },
    { lat: 49.3729927, lng: 21.6056495 },
    { lat: 49.3729596, lng: 21.6058004 },
    { lat: 49.3736026, lng: 21.6069668 },
    { lat: 49.3746048, lng: 21.6081122 },
    { lat: 49.3753529, lng: 21.6088123 },
    { lat: 49.3756673, lng: 21.609774 },
    { lat: 49.3758297, lng: 21.6104422 },
    { lat: 49.3759134, lng: 21.6110361 },
    { lat: 49.3762109, lng: 21.612118 },
    { lat: 49.376552, lng: 21.6128646 },
    { lat: 49.3769789, lng: 21.6141967 },
    { lat: 49.3771084, lng: 21.6155161 },
    { lat: 49.3777449, lng: 21.6164135 },
    { lat: 49.3782042, lng: 21.6169143 },
    { lat: 49.3784043, lng: 21.6172411 },
    { lat: 49.3798589, lng: 21.6187639 },
    { lat: 49.3801692, lng: 21.6194126 },
    { lat: 49.3805577, lng: 21.6204155 },
    { lat: 49.3817409, lng: 21.6218498 },
    { lat: 49.3818955, lng: 21.6219547 },
    { lat: 49.3822313, lng: 21.6220194 },
    { lat: 49.3824792, lng: 21.6222735 },
    { lat: 49.3826226, lng: 21.6223369 },
    { lat: 49.3828325, lng: 21.6226105 },
    { lat: 49.3836228, lng: 21.6229591 },
    { lat: 49.383959, lng: 21.6234881 },
    { lat: 49.3841385, lng: 21.6235196 },
    { lat: 49.3846262, lng: 21.624401 },
    { lat: 49.3850363, lng: 21.6246296 },
    { lat: 49.3852909, lng: 21.6247711 },
    { lat: 49.3864904, lng: 21.623715 },
    { lat: 49.3868497, lng: 21.6233655 },
    { lat: 49.3877967, lng: 21.622249 },
    { lat: 49.388441, lng: 21.6217866 },
    { lat: 49.3890443, lng: 21.6216706 },
    { lat: 49.3894748, lng: 21.62121 },
    { lat: 49.3902832, lng: 21.6200441 },
    { lat: 49.3907066, lng: 21.6196912 },
    { lat: 49.3913678, lng: 21.6187041 },
    { lat: 49.3916615, lng: 21.6179623 },
    { lat: 49.3918732, lng: 21.6176446 },
    { lat: 49.3919549, lng: 21.6169262 },
    { lat: 49.3921523, lng: 21.6164574 },
    { lat: 49.3927719, lng: 21.6153038 },
    { lat: 49.3931047, lng: 21.6149665 },
    { lat: 49.3932603, lng: 21.6146502 },
    { lat: 49.3934141, lng: 21.6141896 },
    { lat: 49.3935003, lng: 21.6136009 },
    { lat: 49.3938173, lng: 21.612362 },
    { lat: 49.3943393, lng: 21.6109688 },
    { lat: 49.3945266, lng: 21.6102937 },
    { lat: 49.3945461, lng: 21.6100244 },
    { lat: 49.3947265, lng: 21.6095488 },
    { lat: 49.3948695, lng: 21.6075443 },
    { lat: 49.395292, lng: 21.6067088 },
    { lat: 49.3952777, lng: 21.6062298 },
    { lat: 49.3955877, lng: 21.6058898 },
    { lat: 49.3960282, lng: 21.6048845 },
    { lat: 49.3966112, lng: 21.6044496 },
    { lat: 49.3969452, lng: 21.6037469 },
    { lat: 49.3974508, lng: 21.6034806 },
    { lat: 49.3979897, lng: 21.6027896 },
    { lat: 49.3984717, lng: 21.6024041 },
    { lat: 49.3987489, lng: 21.6023783 },
    { lat: 49.3989802, lng: 21.602242 },
    { lat: 49.3994189, lng: 21.601712 },
    { lat: 49.3997065, lng: 21.6016967 },
    { lat: 49.4000095, lng: 21.6015478 },
    { lat: 49.4003225, lng: 21.6012697 },
    { lat: 49.4012599, lng: 21.6003452 },
    { lat: 49.4019234, lng: 21.5994448 },
    { lat: 49.4022985, lng: 21.5991381 },
    { lat: 49.4043779, lng: 21.5983409 },
    { lat: 49.4048036, lng: 21.5979904 },
    { lat: 49.4050029, lng: 21.5974285 },
    { lat: 49.4054359, lng: 21.5971823 },
    { lat: 49.4055805, lng: 21.5965116 },
    { lat: 49.4055984, lng: 21.5958169 },
    { lat: 49.4054147, lng: 21.5955557 },
    { lat: 49.405592, lng: 21.5945606 },
    { lat: 49.4057436, lng: 21.5941842 },
    { lat: 49.40639, lng: 21.593681 },
    { lat: 49.4070021, lng: 21.5936894 },
    { lat: 49.4075666, lng: 21.593884 },
    { lat: 49.4077476, lng: 21.5938831 },
    { lat: 49.4083722, lng: 21.5936086 },
    { lat: 49.4089109, lng: 21.5934834 },
    { lat: 49.4092899, lng: 21.5935586 },
    { lat: 49.4103671, lng: 21.5940281 },
    { lat: 49.4107589, lng: 21.5937228 },
    { lat: 49.4113157, lng: 21.5936798 },
    { lat: 49.411608, lng: 21.5938365 },
    { lat: 49.4117654, lng: 21.5937894 },
    { lat: 49.4126083, lng: 21.5932334 },
    { lat: 49.4127727, lng: 21.5930009 },
    { lat: 49.4129209, lng: 21.5925368 },
    { lat: 49.4135691, lng: 21.5917752 },
    { lat: 49.4139401, lng: 21.5909476 },
    { lat: 49.4143912, lng: 21.5905448 },
    { lat: 49.4146118, lng: 21.5898181 },
    { lat: 49.4146488, lng: 21.5894829 },
    { lat: 49.4146418, lng: 21.5893143 },
  ],
  Rakovčík: [
    { lat: 49.2550407, lng: 21.5594437 },
    { lat: 49.2550108, lng: 21.5582143 },
    { lat: 49.2539644, lng: 21.555999 },
    { lat: 49.2532661, lng: 21.5540024 },
    { lat: 49.2527273, lng: 21.5520796 },
    { lat: 49.2519651, lng: 21.5516134 },
    { lat: 49.2512597, lng: 21.5516534 },
    { lat: 49.2497524, lng: 21.5510588 },
    { lat: 49.2487076, lng: 21.5511502 },
    { lat: 49.2465985, lng: 21.5531199 },
    { lat: 49.2451653, lng: 21.5548123 },
    { lat: 49.2445481, lng: 21.5555097 },
    { lat: 49.243995, lng: 21.5560064 },
    { lat: 49.2437839, lng: 21.5560922 },
    { lat: 49.2432479, lng: 21.5568466 },
    { lat: 49.242974, lng: 21.5575867 },
    { lat: 49.2430974, lng: 21.5578185 },
    { lat: 49.2427113, lng: 21.5583355 },
    { lat: 49.2428977, lng: 21.5591662 },
    { lat: 49.2424663, lng: 21.5595043 },
    { lat: 49.242114, lng: 21.5604264 },
    { lat: 49.2415633, lng: 21.5622981 },
    { lat: 49.2413751, lng: 21.5624033 },
    { lat: 49.240928, lng: 21.5628724 },
    { lat: 49.2407291, lng: 21.5631935 },
    { lat: 49.2402584, lng: 21.5632656 },
    { lat: 49.2398394, lng: 21.5634474 },
    { lat: 49.2396458, lng: 21.5633585 },
    { lat: 49.239422, lng: 21.5636027 },
    { lat: 49.2392305, lng: 21.5636728 },
    { lat: 49.2390388, lng: 21.5642642 },
    { lat: 49.2388492, lng: 21.564566 },
    { lat: 49.2386859, lng: 21.5645572 },
    { lat: 49.2385155, lng: 21.5646616 },
    { lat: 49.2383258, lng: 21.5650347 },
    { lat: 49.2383271, lng: 21.5652081 },
    { lat: 49.2381779, lng: 21.5654626 },
    { lat: 49.2381335, lng: 21.5657222 },
    { lat: 49.2380467, lng: 21.5657963 },
    { lat: 49.2380432, lng: 21.5659574 },
    { lat: 49.2378292, lng: 21.5663461 },
    { lat: 49.2376665, lng: 21.5664032 },
    { lat: 49.2374612, lng: 21.5666667 },
    { lat: 49.2369017, lng: 21.5675776 },
    { lat: 49.2366502, lng: 21.568404 },
    { lat: 49.2365706, lng: 21.5694233 },
    { lat: 49.2365126, lng: 21.5696845 },
    { lat: 49.2360932, lng: 21.57102 },
    { lat: 49.2355583, lng: 21.5716847 },
    { lat: 49.2352435, lng: 21.5723445 },
    { lat: 49.2346829, lng: 21.5739008 },
    { lat: 49.2345444, lng: 21.5745463 },
    { lat: 49.2344055, lng: 21.574893 },
    { lat: 49.2334927, lng: 21.576448 },
    { lat: 49.2325166, lng: 21.5788846 },
    { lat: 49.2322781, lng: 21.5792517 },
    { lat: 49.2315513, lng: 21.5799353 },
    { lat: 49.2314404, lng: 21.5801538 },
    { lat: 49.2310764, lng: 21.5804149 },
    { lat: 49.2310805, lng: 21.5808074 },
    { lat: 49.2309142, lng: 21.5812195 },
    { lat: 49.2306466, lng: 21.5814233 },
    { lat: 49.2301593, lng: 21.5819462 },
    { lat: 49.2300722, lng: 21.5821704 },
    { lat: 49.2291274, lng: 21.5832347 },
    { lat: 49.2288538, lng: 21.583345 },
    { lat: 49.2287666, lng: 21.5835248 },
    { lat: 49.2284991, lng: 21.5837732 },
    { lat: 49.2281617, lng: 21.5838973 },
    { lat: 49.2273509, lng: 21.5839901 },
    { lat: 49.2272898, lng: 21.5843007 },
    { lat: 49.2267897, lng: 21.5844828 },
    { lat: 49.2265253, lng: 21.5846835 },
    { lat: 49.225438, lng: 21.5860816 },
    { lat: 49.2256483, lng: 21.5865233 },
    { lat: 49.2253873, lng: 21.5868112 },
    { lat: 49.22533, lng: 21.5870125 },
    { lat: 49.2254042, lng: 21.5873788 },
    { lat: 49.2249977, lng: 21.5887425 },
    { lat: 49.2238575, lng: 21.5900629 },
    { lat: 49.225137, lng: 21.593536 },
    { lat: 49.225437, lng: 21.59323 },
    { lat: 49.22602, lng: 21.592988 },
    { lat: 49.226142, lng: 21.593029 },
    { lat: 49.226221, lng: 21.593033 },
    { lat: 49.226433, lng: 21.593049 },
    { lat: 49.2266, lng: 21.593025 },
    { lat: 49.226765, lng: 21.593102 },
    { lat: 49.226978, lng: 21.593364 },
    { lat: 49.227073, lng: 21.59349 },
    { lat: 49.227235, lng: 21.593544 },
    { lat: 49.227442, lng: 21.593721 },
    { lat: 49.227556, lng: 21.593818 },
    { lat: 49.227972, lng: 21.593983 },
    { lat: 49.228117, lng: 21.59403 },
    { lat: 49.228116, lng: 21.594006 },
    { lat: 49.228297, lng: 21.594007 },
    { lat: 49.228479, lng: 21.594015 },
    { lat: 49.229047, lng: 21.593886 },
    { lat: 49.229378, lng: 21.593653 },
    { lat: 49.229637, lng: 21.593491 },
    { lat: 49.23002, lng: 21.593571 },
    { lat: 49.230159, lng: 21.593646 },
    { lat: 49.230393, lng: 21.593678 },
    { lat: 49.230608, lng: 21.593777 },
    { lat: 49.230839, lng: 21.594069 },
    { lat: 49.230831, lng: 21.594088 },
    { lat: 49.230935, lng: 21.594133 },
    { lat: 49.23131, lng: 21.594157 },
    { lat: 49.231652, lng: 21.594112 },
    { lat: 49.2317234, lng: 21.5939639 },
    { lat: 49.2327878, lng: 21.5937285 },
    { lat: 49.2335343, lng: 21.5931033 },
    { lat: 49.2336711, lng: 21.5930908 },
    { lat: 49.2339522, lng: 21.5929136 },
    { lat: 49.2342426, lng: 21.5926004 },
    { lat: 49.2345356, lng: 21.5924551 },
    { lat: 49.2348513, lng: 21.5923955 },
    { lat: 49.2353671, lng: 21.5920133 },
    { lat: 49.2354642, lng: 21.5918674 },
    { lat: 49.2360639, lng: 21.5916224 },
    { lat: 49.2362924, lng: 21.591428 },
    { lat: 49.2364652, lng: 21.5914107 },
    { lat: 49.2365512, lng: 21.5915891 },
    { lat: 49.2367556, lng: 21.5915381 },
    { lat: 49.2371616, lng: 21.5912934 },
    { lat: 49.2374014, lng: 21.5910511 },
    { lat: 49.2377679, lng: 21.5908837 },
    { lat: 49.2381372, lng: 21.5909772 },
    { lat: 49.2387985, lng: 21.5905605 },
    { lat: 49.2389467, lng: 21.5902679 },
    { lat: 49.2392016, lng: 21.590128 },
    { lat: 49.2393687, lng: 21.589666 },
    { lat: 49.2396016, lng: 21.5893763 },
    { lat: 49.2399379, lng: 21.589253 },
    { lat: 49.240142, lng: 21.5890757 },
    { lat: 49.2409495, lng: 21.5878026 },
    { lat: 49.2414646, lng: 21.5871918 },
    { lat: 49.2416727, lng: 21.5867477 },
    { lat: 49.241901, lng: 21.5864615 },
    { lat: 49.2424566, lng: 21.5853987 },
    { lat: 49.2431617, lng: 21.5843834 },
    { lat: 49.2432297, lng: 21.5841904 },
    { lat: 49.2440394, lng: 21.5833736 },
    { lat: 49.2442427, lng: 21.5830788 },
    { lat: 49.2446851, lng: 21.5821601 },
    { lat: 49.2467885, lng: 21.5797157 },
    { lat: 49.2477064, lng: 21.5789098 },
    { lat: 49.2485635, lng: 21.5778142 },
    { lat: 49.2488793, lng: 21.5773173 },
    { lat: 49.2491328, lng: 21.5768036 },
    { lat: 49.2496401, lng: 21.575349 },
    { lat: 49.2503035, lng: 21.574037 },
    { lat: 49.2506377, lng: 21.5731971 },
    { lat: 49.2510778, lng: 21.5724339 },
    { lat: 49.2512373, lng: 21.5719827 },
    { lat: 49.2516446, lng: 21.5711334 },
    { lat: 49.251917, lng: 21.5701315 },
    { lat: 49.2520865, lng: 21.5696765 },
    { lat: 49.2520056, lng: 21.5695989 },
    { lat: 49.252126, lng: 21.5691146 },
    { lat: 49.2520844, lng: 21.5689592 },
    { lat: 49.2521245, lng: 21.5684622 },
    { lat: 49.2523459, lng: 21.5678882 },
    { lat: 49.2523752, lng: 21.5674422 },
    { lat: 49.2523105, lng: 21.5669398 },
    { lat: 49.2528993, lng: 21.5663119 },
    { lat: 49.253223, lng: 21.5658569 },
    { lat: 49.2533126, lng: 21.5656389 },
    { lat: 49.2534558, lng: 21.564766 },
    { lat: 49.254105, lng: 21.5633221 },
    { lat: 49.2542608, lng: 21.5628553 },
    { lat: 49.2543154, lng: 21.5617904 },
    { lat: 49.2545145, lng: 21.5613042 },
    { lat: 49.2548277, lng: 21.5600799 },
    { lat: 49.2550407, lng: 21.5594437 },
  ],
  LužanypriTopli: [
    { lat: 49.1173885, lng: 21.4875023 },
    { lat: 49.1162357, lng: 21.4880261 },
    { lat: 49.1160812, lng: 21.4880957 },
    { lat: 49.1163747, lng: 21.4893868 },
    { lat: 49.1163069, lng: 21.4894143 },
    { lat: 49.11523, lng: 21.4932203 },
    { lat: 49.1151901, lng: 21.4934004 },
    { lat: 49.1153392, lng: 21.4939623 },
    { lat: 49.1161493, lng: 21.494912 },
    { lat: 49.1172228, lng: 21.4977511 },
    { lat: 49.1173668, lng: 21.4979492 },
    { lat: 49.1205667, lng: 21.5071406 },
    { lat: 49.1207699, lng: 21.5078786 },
    { lat: 49.1209866, lng: 21.5095169 },
    { lat: 49.1210598, lng: 21.5096798 },
    { lat: 49.1215362, lng: 21.5117888 },
    { lat: 49.1217659, lng: 21.5130283 },
    { lat: 49.1221962, lng: 21.514113 },
    { lat: 49.1227709, lng: 21.5168331 },
    { lat: 49.1234305, lng: 21.5194635 },
    { lat: 49.1237466, lng: 21.5210473 },
    { lat: 49.1268008, lng: 21.5194516 },
    { lat: 49.1267605, lng: 21.5200937 },
    { lat: 49.1268648, lng: 21.5207935 },
    { lat: 49.1269408, lng: 21.5210112 },
    { lat: 49.1271109, lng: 21.52193 },
    { lat: 49.12757, lng: 21.52294 },
    { lat: 49.127587, lng: 21.523014 },
    { lat: 49.127811, lng: 21.522682 },
    { lat: 49.128008, lng: 21.522478 },
    { lat: 49.128, lng: 21.522431 },
    { lat: 49.128169, lng: 21.521209 },
    { lat: 49.128875, lng: 21.52078 },
    { lat: 49.129259, lng: 21.520545 },
    { lat: 49.130619, lng: 21.520098 },
    { lat: 49.131206, lng: 21.519702 },
    { lat: 49.131534, lng: 21.519207 },
    { lat: 49.132087, lng: 21.518839 },
    { lat: 49.132378, lng: 21.519014 },
    { lat: 49.132478, lng: 21.518896 },
    { lat: 49.132604, lng: 21.518335 },
    { lat: 49.132935, lng: 21.518323 },
    { lat: 49.133341, lng: 21.517766 },
    { lat: 49.133553, lng: 21.517217 },
    { lat: 49.133989, lng: 21.517131 },
    { lat: 49.134211, lng: 21.517209 },
    { lat: 49.134576, lng: 21.516609 },
    { lat: 49.134724, lng: 21.516572 },
    { lat: 49.135183, lng: 21.516848 },
    { lat: 49.135541, lng: 21.516422 },
    { lat: 49.135508, lng: 21.516296 },
    { lat: 49.135167, lng: 21.515626 },
    { lat: 49.134641, lng: 21.514111 },
    { lat: 49.13403, lng: 21.512699 },
    { lat: 49.134509, lng: 21.512855 },
    { lat: 49.134503, lng: 21.512766 },
    { lat: 49.134314, lng: 21.510063 },
    { lat: 49.133766, lng: 21.508085 },
    { lat: 49.133553, lng: 21.507295 },
    { lat: 49.132596, lng: 21.503765 },
    { lat: 49.13257, lng: 21.503634 },
    { lat: 49.132274, lng: 21.502022 },
    { lat: 49.13224, lng: 21.501836 },
    { lat: 49.130902, lng: 21.498004 },
    { lat: 49.130114, lng: 21.495471 },
    { lat: 49.130092, lng: 21.495396 },
    { lat: 49.1296904, lng: 21.4955934 },
    { lat: 49.1294831, lng: 21.4956066 },
    { lat: 49.1293093, lng: 21.4954832 },
    { lat: 49.1288785, lng: 21.4951481 },
    { lat: 49.1285991, lng: 21.4948038 },
    { lat: 49.12852, lng: 21.493977 },
    { lat: 49.12843, lng: 21.493606 },
    { lat: 49.128394, lng: 21.492612 },
    { lat: 49.128485, lng: 21.491884 },
    { lat: 49.1270557, lng: 21.4918778 },
    { lat: 49.1256626, lng: 21.4923077 },
    { lat: 49.1256122, lng: 21.4919604 },
    { lat: 49.1246622, lng: 21.4909602 },
    { lat: 49.1238551, lng: 21.4905145 },
    { lat: 49.1237724, lng: 21.4901813 },
    { lat: 49.1237115, lng: 21.4899357 },
    { lat: 49.1246234, lng: 21.4887848 },
    { lat: 49.1246205, lng: 21.4886399 },
    { lat: 49.1238362, lng: 21.4869067 },
    { lat: 49.1235023, lng: 21.4862895 },
    { lat: 49.1228813, lng: 21.4853509 },
    { lat: 49.1222355, lng: 21.4859011 },
    { lat: 49.1218795, lng: 21.4864683 },
    { lat: 49.1216906, lng: 21.4871087 },
    { lat: 49.1217063, lng: 21.4881968 },
    { lat: 49.1215327, lng: 21.4884666 },
    { lat: 49.1210536, lng: 21.4885473 },
    { lat: 49.1206329, lng: 21.4883971 },
    { lat: 49.1203874, lng: 21.488499 },
    { lat: 49.1201254, lng: 21.48871 },
    { lat: 49.1199373, lng: 21.4884261 },
    { lat: 49.118856, lng: 21.4869238 },
    { lat: 49.1174238, lng: 21.4875172 },
    { lat: 49.1173885, lng: 21.4875023 },
  ],
  Kružlová: [
    { lat: 49.3748865, lng: 21.5562028 },
    { lat: 49.3743954, lng: 21.5560667 },
    { lat: 49.3735961, lng: 21.5558375 },
    { lat: 49.3725658, lng: 21.5544328 },
    { lat: 49.3710684, lng: 21.5526986 },
    { lat: 49.3701588, lng: 21.5522309 },
    { lat: 49.3695025, lng: 21.5512924 },
    { lat: 49.3688654, lng: 21.5520356 },
    { lat: 49.3674804, lng: 21.5533991 },
    { lat: 49.3662701, lng: 21.554367 },
    { lat: 49.3651883, lng: 21.5553507 },
    { lat: 49.3634886, lng: 21.5551866 },
    { lat: 49.3634683, lng: 21.5546621 },
    { lat: 49.3624437, lng: 21.555111 },
    { lat: 49.3613994, lng: 21.5554663 },
    { lat: 49.3606873, lng: 21.5559064 },
    { lat: 49.3563137, lng: 21.5589284 },
    { lat: 49.3557919, lng: 21.559541 },
    { lat: 49.3547095, lng: 21.5602177 },
    { lat: 49.3539576, lng: 21.561209 },
    { lat: 49.3537567, lng: 21.5618659 },
    { lat: 49.3534646, lng: 21.5624894 },
    { lat: 49.3532282, lng: 21.5632085 },
    { lat: 49.3520333, lng: 21.5644506 },
    { lat: 49.3508032, lng: 21.5650332 },
    { lat: 49.3504328, lng: 21.5668384 },
    { lat: 49.35007, lng: 21.5679128 },
    { lat: 49.3494556, lng: 21.5706132 },
    { lat: 49.3504596, lng: 21.5709945 },
    { lat: 49.3506144, lng: 21.5713066 },
    { lat: 49.3506113, lng: 21.5726119 },
    { lat: 49.3507281, lng: 21.5732083 },
    { lat: 49.351608, lng: 21.5739001 },
    { lat: 49.3515316, lng: 21.5781192 },
    { lat: 49.3516111, lng: 21.5791222 },
    { lat: 49.3515754, lng: 21.5814396 },
    { lat: 49.3511871, lng: 21.5833415 },
    { lat: 49.3508572, lng: 21.5842206 },
    { lat: 49.3506552, lng: 21.5850903 },
    { lat: 49.3504964, lng: 21.5862686 },
    { lat: 49.3505708, lng: 21.5867591 },
    { lat: 49.3516986, lng: 21.5907237 },
    { lat: 49.3517861, lng: 21.591816 },
    { lat: 49.3519559, lng: 21.5920168 },
    { lat: 49.3521039, lng: 21.5926885 },
    { lat: 49.3523886, lng: 21.5933116 },
    { lat: 49.3523374, lng: 21.5943404 },
    { lat: 49.3526765, lng: 21.5942438 },
    { lat: 49.3528315, lng: 21.5939079 },
    { lat: 49.3528801, lng: 21.5932611 },
    { lat: 49.353583, lng: 21.5928078 },
    { lat: 49.3540574, lng: 21.593571 },
    { lat: 49.3546629, lng: 21.5936628 },
    { lat: 49.3551726, lng: 21.5941588 },
    { lat: 49.3551846, lng: 21.5942703 },
    { lat: 49.3550059, lng: 21.5943962 },
    { lat: 49.3548934, lng: 21.5945576 },
    { lat: 49.3546118, lng: 21.594705 },
    { lat: 49.3544866, lng: 21.5950645 },
    { lat: 49.3545465, lng: 21.5952765 },
    { lat: 49.3555619, lng: 21.5951368 },
    { lat: 49.3557207, lng: 21.5952294 },
    { lat: 49.3559563, lng: 21.595487 },
    { lat: 49.3559728, lng: 21.5962484 },
    { lat: 49.3560579, lng: 21.5963154 },
    { lat: 49.3560036, lng: 21.5967173 },
    { lat: 49.3560613, lng: 21.5971336 },
    { lat: 49.3562765, lng: 21.5973445 },
    { lat: 49.3563017, lng: 21.5978138 },
    { lat: 49.3563725, lng: 21.5979713 },
    { lat: 49.3566002, lng: 21.5984827 },
    { lat: 49.3566868, lng: 21.5985644 },
    { lat: 49.3569904, lng: 21.5988476 },
    { lat: 49.357635, lng: 21.5988095 },
    { lat: 49.3578306, lng: 21.5986789 },
    { lat: 49.3583711, lng: 21.5988569 },
    { lat: 49.3590985, lng: 21.5989257 },
    { lat: 49.3592886, lng: 21.5987677 },
    { lat: 49.3600579, lng: 21.5984725 },
    { lat: 49.360934, lng: 21.5987945 },
    { lat: 49.3614319, lng: 21.5988998 },
    { lat: 49.3626814, lng: 21.599625 },
    { lat: 49.3641054, lng: 21.6012936 },
    { lat: 49.3646303, lng: 21.6016487 },
    { lat: 49.3651615, lng: 21.6019035 },
    { lat: 49.366887, lng: 21.6023076 },
    { lat: 49.3680924, lng: 21.6029466 },
    { lat: 49.3696392, lng: 21.6031532 },
    { lat: 49.3708475, lng: 21.6027926 },
    { lat: 49.3714114, lng: 21.6023927 },
    { lat: 49.3713855, lng: 21.6008004 },
    { lat: 49.3711126, lng: 21.5967773 },
    { lat: 49.3711693, lng: 21.5954063 },
    { lat: 49.3716154, lng: 21.5952211 },
    { lat: 49.3716339, lng: 21.5949933 },
    { lat: 49.3725478, lng: 21.5929812 },
    { lat: 49.3742749, lng: 21.5916056 },
    { lat: 49.3735877, lng: 21.5905985 },
    { lat: 49.3763341, lng: 21.5898376 },
    { lat: 49.3767362, lng: 21.5887653 },
    { lat: 49.378077, lng: 21.587539 },
    { lat: 49.3776645, lng: 21.5860473 },
    { lat: 49.3774022, lng: 21.5848239 },
    { lat: 49.3767566, lng: 21.5830806 },
    { lat: 49.3761929, lng: 21.5818927 },
    { lat: 49.3754984, lng: 21.5811927 },
    { lat: 49.3745926, lng: 21.5787477 },
    { lat: 49.3746981, lng: 21.5779618 },
    { lat: 49.375168, lng: 21.5753605 },
    { lat: 49.3749811, lng: 21.5742236 },
    { lat: 49.3749894, lng: 21.5740711 },
    { lat: 49.3745235, lng: 21.5724055 },
    { lat: 49.3747125, lng: 21.5706329 },
    { lat: 49.3751391, lng: 21.5681734 },
    { lat: 49.3753937, lng: 21.5670568 },
    { lat: 49.3760207, lng: 21.5654541 },
    { lat: 49.3768711, lng: 21.563791 },
    { lat: 49.3766851, lng: 21.5622114 },
    { lat: 49.3766433, lng: 21.5615142 },
    { lat: 49.376588, lng: 21.5596582 },
    { lat: 49.3766343, lng: 21.5595021 },
    { lat: 49.3761251, lng: 21.5583441 },
    { lat: 49.3755516, lng: 21.5575509 },
    { lat: 49.3748865, lng: 21.5562028 },
  ],
  Hrabovčík: [
    { lat: 49.2653682, lng: 21.5476909 },
    { lat: 49.265528, lng: 21.5479776 },
    { lat: 49.2657185, lng: 21.5479107 },
    { lat: 49.2659994, lng: 21.5477723 },
    { lat: 49.2667774, lng: 21.547641 },
    { lat: 49.2672976, lng: 21.5473703 },
    { lat: 49.2674071, lng: 21.547464 },
    { lat: 49.2678145, lng: 21.5481152 },
    { lat: 49.2684913, lng: 21.5498191 },
    { lat: 49.2688194, lng: 21.5511291 },
    { lat: 49.2689312, lng: 21.5520735 },
    { lat: 49.2691028, lng: 21.5527723 },
    { lat: 49.2701056, lng: 21.5540339 },
    { lat: 49.2704688, lng: 21.5546701 },
    { lat: 49.2715373, lng: 21.557483 },
    { lat: 49.2722012, lng: 21.55938 },
    { lat: 49.272453, lng: 21.5602924 },
    { lat: 49.2724387, lng: 21.561114 },
    { lat: 49.272556, lng: 21.5618099 },
    { lat: 49.2727802, lng: 21.5626646 },
    { lat: 49.2731693, lng: 21.5637067 },
    { lat: 49.2735905, lng: 21.5645345 },
    { lat: 49.2735882, lng: 21.5652941 },
    { lat: 49.2738611, lng: 21.5658859 },
    { lat: 49.2743907, lng: 21.5664376 },
    { lat: 49.2747184, lng: 21.5669401 },
    { lat: 49.2759922, lng: 21.567957 },
    { lat: 49.2767204, lng: 21.5688983 },
    { lat: 49.2773472, lng: 21.5699106 },
    { lat: 49.2785414, lng: 21.571994 },
    { lat: 49.278581, lng: 21.5722465 },
    { lat: 49.279031, lng: 21.5732203 },
    { lat: 49.2792256, lng: 21.5738584 },
    { lat: 49.279494, lng: 21.5743317 },
    { lat: 49.2796441, lng: 21.5750407 },
    { lat: 49.2799397, lng: 21.575793 },
    { lat: 49.2802871, lng: 21.5761887 },
    { lat: 49.2809821, lng: 21.5760243 },
    { lat: 49.28139, lng: 21.5760086 },
    { lat: 49.282061, lng: 21.5761502 },
    { lat: 49.2826352, lng: 21.5761227 },
    { lat: 49.2826931, lng: 21.5759834 },
    { lat: 49.2826936, lng: 21.5755946 },
    { lat: 49.2825624, lng: 21.5750795 },
    { lat: 49.2826112, lng: 21.5747773 },
    { lat: 49.2828015, lng: 21.5744986 },
    { lat: 49.2829181, lng: 21.5741999 },
    { lat: 49.2830392, lng: 21.5730955 },
    { lat: 49.2831147, lng: 21.5729782 },
    { lat: 49.2852456, lng: 21.5716213 },
    { lat: 49.285902, lng: 21.5709887 },
    { lat: 49.2864724, lng: 21.5701061 },
    { lat: 49.2870781, lng: 21.5689528 },
    { lat: 49.288082, lng: 21.5682809 },
    { lat: 49.2887731, lng: 21.5655479 },
    { lat: 49.2894008, lng: 21.5652241 },
    { lat: 49.2897202, lng: 21.564658 },
    { lat: 49.2898883, lng: 21.5645565 },
    { lat: 49.2902728, lng: 21.5646266 },
    { lat: 49.2904757, lng: 21.5645637 },
    { lat: 49.2912666, lng: 21.5641174 },
    { lat: 49.2917033, lng: 21.5639704 },
    { lat: 49.2917872, lng: 21.5623395 },
    { lat: 49.2918747, lng: 21.5618415 },
    { lat: 49.2921006, lng: 21.5617936 },
    { lat: 49.2923434, lng: 21.56159 },
    { lat: 49.2924667, lng: 21.5612871 },
    { lat: 49.29261, lng: 21.5612287 },
    { lat: 49.2930026, lng: 21.5596101 },
    { lat: 49.2935423, lng: 21.5586374 },
    { lat: 49.2935254, lng: 21.5585989 },
    { lat: 49.2936709, lng: 21.5582873 },
    { lat: 49.2943358, lng: 21.5557555 },
    { lat: 49.2947343, lng: 21.5549257 },
    { lat: 49.2949013, lng: 21.5540595 },
    { lat: 49.295048, lng: 21.5535918 },
    { lat: 49.2954795, lng: 21.5526531 },
    { lat: 49.2958411, lng: 21.5516235 },
    { lat: 49.2964564, lng: 21.5505311 },
    { lat: 49.2967441, lng: 21.5496025 },
    { lat: 49.2971143, lng: 21.5479588 },
    { lat: 49.2969984, lng: 21.546875 },
    { lat: 49.297025, lng: 21.5455799 },
    { lat: 49.2969566, lng: 21.5449072 },
    { lat: 49.2969244, lng: 21.5436748 },
    { lat: 49.2970402, lng: 21.5431139 },
    { lat: 49.2970352, lng: 21.542575 },
    { lat: 49.2968744, lng: 21.5414588 },
    { lat: 49.2969241, lng: 21.5403414 },
    { lat: 49.2984403, lng: 21.5386125 },
    { lat: 49.2980417, lng: 21.5374391 },
    { lat: 49.2980441, lng: 21.536846 },
    { lat: 49.2981182, lng: 21.5365658 },
    { lat: 49.297862, lng: 21.5354101 },
    { lat: 49.2974914, lng: 21.534196 },
    { lat: 49.2969229, lng: 21.5345419 },
    { lat: 49.296418, lng: 21.5347118 },
    { lat: 49.295916, lng: 21.5345822 },
    { lat: 49.2956641, lng: 21.5342254 },
    { lat: 49.2955275, lng: 21.5338091 },
    { lat: 49.2953434, lng: 21.5335422 },
    { lat: 49.2937808, lng: 21.5327837 },
    { lat: 49.2932671, lng: 21.5329945 },
    { lat: 49.2924568, lng: 21.5330233 },
    { lat: 49.2920242, lng: 21.5332403 },
    { lat: 49.2915638, lng: 21.5336325 },
    { lat: 49.2913232, lng: 21.5337128 },
    { lat: 49.2911279, lng: 21.5336493 },
    { lat: 49.290722, lng: 21.5333426 },
    { lat: 49.2901704, lng: 21.532807 },
    { lat: 49.2893545, lng: 21.5329962 },
    { lat: 49.2890905, lng: 21.5327307 },
    { lat: 49.2886551, lng: 21.532932 },
    { lat: 49.2881366, lng: 21.532653 },
    { lat: 49.2876247, lng: 21.5325317 },
    { lat: 49.2846277, lng: 21.5302948 },
    { lat: 49.2840538, lng: 21.5297312 },
    { lat: 49.2825757, lng: 21.5290517 },
    { lat: 49.2819936, lng: 21.5290014 },
    { lat: 49.2816564, lng: 21.5284536 },
    { lat: 49.2804815, lng: 21.5287138 },
    { lat: 49.28005, lng: 21.5286928 },
    { lat: 49.2794604, lng: 21.5289255 },
    { lat: 49.2787244, lng: 21.5290349 },
    { lat: 49.2786267, lng: 21.5291621 },
    { lat: 49.27907, lng: 21.5305651 },
    { lat: 49.2791519, lng: 21.5313532 },
    { lat: 49.2790763, lng: 21.531984 },
    { lat: 49.2789044, lng: 21.5319074 },
    { lat: 49.2786478, lng: 21.5323044 },
    { lat: 49.2783553, lng: 21.5329409 },
    { lat: 49.2782605, lng: 21.5334905 },
    { lat: 49.2782949, lng: 21.5345966 },
    { lat: 49.2781468, lng: 21.5361275 },
    { lat: 49.2777754, lng: 21.5363973 },
    { lat: 49.2772927, lng: 21.5371122 },
    { lat: 49.2765989, lng: 21.5377664 },
    { lat: 49.2764392, lng: 21.537093 },
    { lat: 49.2759331, lng: 21.5374956 },
    { lat: 49.2755814, lng: 21.5379046 },
    { lat: 49.2749485, lng: 21.5384037 },
    { lat: 49.2742791, lng: 21.5393592 },
    { lat: 49.2738359, lng: 21.5405268 },
    { lat: 49.2728771, lng: 21.5421076 },
    { lat: 49.271814, lng: 21.5434692 },
    { lat: 49.2710167, lng: 21.5440057 },
    { lat: 49.2701382, lng: 21.5434066 },
    { lat: 49.2690029, lng: 21.5449674 },
    { lat: 49.2679774, lng: 21.5455772 },
    { lat: 49.2667705, lng: 21.5461098 },
    { lat: 49.2653682, lng: 21.5476909 },
  ],
  VyšnýKomárnik: [
    { lat: 49.391405, lng: 21.690589 },
    { lat: 49.3916664, lng: 21.6912326 },
    { lat: 49.3922257, lng: 21.6920865 },
    { lat: 49.3924224, lng: 21.6927978 },
    { lat: 49.3925864, lng: 21.6931291 },
    { lat: 49.3926233, lng: 21.6935832 },
    { lat: 49.3927667, lng: 21.6939754 },
    { lat: 49.3928167, lng: 21.6943366 },
    { lat: 49.3929895, lng: 21.694658 },
    { lat: 49.3931384, lng: 21.6977408 },
    { lat: 49.3930833, lng: 21.6999 },
    { lat: 49.3932077, lng: 21.7003378 },
    { lat: 49.39316, lng: 21.7008752 },
    { lat: 49.3928634, lng: 21.701603 },
    { lat: 49.3926031, lng: 21.7033165 },
    { lat: 49.392437, lng: 21.7047618 },
    { lat: 49.3913464, lng: 21.7084507 },
    { lat: 49.3909776, lng: 21.7098541 },
    { lat: 49.391472, lng: 21.7106379 },
    { lat: 49.3917362, lng: 21.7114006 },
    { lat: 49.3915607, lng: 21.7117766 },
    { lat: 49.3917015, lng: 21.7121264 },
    { lat: 49.3922083, lng: 21.7122331 },
    { lat: 49.3929927, lng: 21.7133873 },
    { lat: 49.3931673, lng: 21.7141662 },
    { lat: 49.3934374, lng: 21.7142086 },
    { lat: 49.3936883, lng: 21.7146768 },
    { lat: 49.3936328, lng: 21.7157282 },
    { lat: 49.3939363, lng: 21.7166481 },
    { lat: 49.3943144, lng: 21.7173313 },
    { lat: 49.3946199, lng: 21.7189694 },
    { lat: 49.3951423, lng: 21.7201372 },
    { lat: 49.3954548, lng: 21.7205085 },
    { lat: 49.3955846, lng: 21.7211648 },
    { lat: 49.3955707, lng: 21.7214839 },
    { lat: 49.3957899, lng: 21.7235578 },
    { lat: 49.3958525, lng: 21.7237576 },
    { lat: 49.3953946, lng: 21.7249456 },
    { lat: 49.3953578, lng: 21.7260218 },
    { lat: 49.3962364, lng: 21.7277313 },
    { lat: 49.3969632, lng: 21.728753 },
    { lat: 49.3971963, lng: 21.7291874 },
    { lat: 49.3976606, lng: 21.7294221 },
    { lat: 49.3976381, lng: 21.7281741 },
    { lat: 49.3981061, lng: 21.7268958 },
    { lat: 49.3991631, lng: 21.7258393 },
    { lat: 49.3999583, lng: 21.7254914 },
    { lat: 49.400707, lng: 21.7252313 },
    { lat: 49.4007527, lng: 21.7251767 },
    { lat: 49.4014116, lng: 21.7249484 },
    { lat: 49.4020596, lng: 21.7246571 },
    { lat: 49.4031129, lng: 21.7242957 },
    { lat: 49.4041333, lng: 21.7243446 },
    { lat: 49.404223, lng: 21.72444 },
    { lat: 49.4047882, lng: 21.7251167 },
    { lat: 49.4055563, lng: 21.72504 },
    { lat: 49.4063791, lng: 21.7245037 },
    { lat: 49.4073581, lng: 21.7239498 },
    { lat: 49.4080811, lng: 21.723683 },
    { lat: 49.4093858, lng: 21.7236211 },
    { lat: 49.4100816, lng: 21.7237731 },
    { lat: 49.4107809, lng: 21.7235142 },
    { lat: 49.4117015, lng: 21.7225959 },
    { lat: 49.4122839, lng: 21.7217713 },
    { lat: 49.4127641, lng: 21.7204899 },
    { lat: 49.4128276, lng: 21.7201233 },
    { lat: 49.412857, lng: 21.719641 },
    { lat: 49.412907, lng: 21.719048 },
    { lat: 49.412925, lng: 21.718762 },
    { lat: 49.412942, lng: 21.718561 },
    { lat: 49.413002, lng: 21.7183 },
    { lat: 49.4131383, lng: 21.7177217 },
    { lat: 49.413346, lng: 21.717039 },
    { lat: 49.413422, lng: 21.716822 },
    { lat: 49.413519, lng: 21.716574 },
    { lat: 49.413723, lng: 21.7161691 },
    { lat: 49.414066, lng: 21.715174 },
    { lat: 49.414067, lng: 21.714542 },
    { lat: 49.413737, lng: 21.7132343 },
    { lat: 49.4135032, lng: 21.712053 },
    { lat: 49.4133805, lng: 21.7113251 },
    { lat: 49.4133804, lng: 21.7101935 },
    { lat: 49.4137307, lng: 21.7096357 },
    { lat: 49.4140074, lng: 21.7089749 },
    { lat: 49.4145027, lng: 21.7086587 },
    { lat: 49.4146505, lng: 21.708595 },
    { lat: 49.414687, lng: 21.708568 },
    { lat: 49.4147391, lng: 21.7085372 },
    { lat: 49.4150111, lng: 21.7082875 },
    { lat: 49.4151065, lng: 21.7081341 },
    { lat: 49.4151186, lng: 21.7081633 },
    { lat: 49.415619, lng: 21.706886 },
    { lat: 49.416989, lng: 21.703689 },
    { lat: 49.4183741, lng: 21.7005395 },
    { lat: 49.4185136, lng: 21.6993628 },
    { lat: 49.4182812, lng: 21.6974835 },
    { lat: 49.4177676, lng: 21.6964117 },
    { lat: 49.4176352, lng: 21.6961356 },
    { lat: 49.4175576, lng: 21.6959737 },
    { lat: 49.416803, lng: 21.694401 },
    { lat: 49.415954, lng: 21.692703 },
    { lat: 49.415359, lng: 21.691487 },
    { lat: 49.415351, lng: 21.69147 },
    { lat: 49.4149601, lng: 21.6905586 },
    { lat: 49.414953, lng: 21.690544 },
    { lat: 49.4146405, lng: 21.6899734 },
    { lat: 49.414635, lng: 21.68996 },
    { lat: 49.4144799, lng: 21.6895297 },
    { lat: 49.414473, lng: 21.689515 },
    { lat: 49.414118, lng: 21.688901 },
    { lat: 49.41341, lng: 21.687668 },
    { lat: 49.413393, lng: 21.6876378 },
    { lat: 49.41286, lng: 21.68678 },
    { lat: 49.412851, lng: 21.686765 },
    { lat: 49.412328, lng: 21.685843 },
    { lat: 49.412276, lng: 21.685759 },
    { lat: 49.412269, lng: 21.68575 },
    { lat: 49.4117328, lng: 21.685144 },
    { lat: 49.411729, lng: 21.685139 },
    { lat: 49.411408, lng: 21.684658 },
    { lat: 49.4114009, lng: 21.6846495 },
    { lat: 49.4110064, lng: 21.6856446 },
    { lat: 49.4103717, lng: 21.6870207 },
    { lat: 49.4095131, lng: 21.6876371 },
    { lat: 49.4091559, lng: 21.6876603 },
    { lat: 49.4084384, lng: 21.6880392 },
    { lat: 49.4072771, lng: 21.6883557 },
    { lat: 49.4059644, lng: 21.6884837 },
    { lat: 49.4046186, lng: 21.688128 },
    { lat: 49.4043954, lng: 21.6882335 },
    { lat: 49.4040596, lng: 21.6882546 },
    { lat: 49.4030992, lng: 21.6878422 },
    { lat: 49.4027391, lng: 21.6879594 },
    { lat: 49.4016036, lng: 21.6880261 },
    { lat: 49.4002913, lng: 21.687949 },
    { lat: 49.3996382, lng: 21.6874657 },
    { lat: 49.3986083, lng: 21.6872704 },
    { lat: 49.3963365, lng: 21.687618 },
    { lat: 49.3960737, lng: 21.6875049 },
    { lat: 49.3957954, lng: 21.6879099 },
    { lat: 49.3955358, lng: 21.6887174 },
    { lat: 49.3948881, lng: 21.6893092 },
    { lat: 49.3943279, lng: 21.689647 },
    { lat: 49.3941715, lng: 21.6898175 },
    { lat: 49.3938288, lng: 21.6897463 },
    { lat: 49.393575, lng: 21.6895029 },
    { lat: 49.3933555, lng: 21.6889541 },
    { lat: 49.3933156, lng: 21.6886786 },
    { lat: 49.3917212, lng: 21.6896711 },
    { lat: 49.3914741, lng: 21.6897303 },
    { lat: 49.39123, lng: 21.6901016 },
    { lat: 49.391405, lng: 21.690589 },
  ],
  Stročín: [
    { lat: 49.2944976, lng: 21.6018816 },
    { lat: 49.2946102, lng: 21.6007841 },
    { lat: 49.2949479, lng: 21.5998062 },
    { lat: 49.2944901, lng: 21.5993639 },
    { lat: 49.2943664, lng: 21.5991182 },
    { lat: 49.2938929, lng: 21.5977348 },
    { lat: 49.2935933, lng: 21.597361 },
    { lat: 49.2926506, lng: 21.5968329 },
    { lat: 49.2920175, lng: 21.5968349 },
    { lat: 49.2915866, lng: 21.596551 },
    { lat: 49.2911551, lng: 21.5964473 },
    { lat: 49.2909168, lng: 21.595885 },
    { lat: 49.290745, lng: 21.5956463 },
    { lat: 49.290432, lng: 21.595478 },
    { lat: 49.2894307, lng: 21.5946169 },
    { lat: 49.2892541, lng: 21.5942063 },
    { lat: 49.2889637, lng: 21.5938409 },
    { lat: 49.2887298, lng: 21.5938056 },
    { lat: 49.2883708, lng: 21.593472 },
    { lat: 49.2880619, lng: 21.5934884 },
    { lat: 49.2873769, lng: 21.5932501 },
    { lat: 49.2868211, lng: 21.5932432 },
    { lat: 49.2863662, lng: 21.5933475 },
    { lat: 49.28618, lng: 21.5933322 },
    { lat: 49.2860948, lng: 21.5932605 },
    { lat: 49.2856069, lng: 21.5932712 },
    { lat: 49.2848141, lng: 21.5935242 },
    { lat: 49.2843236, lng: 21.5934824 },
    { lat: 49.283971, lng: 21.593543 },
    { lat: 49.2833719, lng: 21.5934819 },
    { lat: 49.2824802, lng: 21.5923598 },
    { lat: 49.2823438, lng: 21.5921045 },
    { lat: 49.2804981, lng: 21.5916225 },
    { lat: 49.280539, lng: 21.5915146 },
    { lat: 49.2806196, lng: 21.5913029 },
    { lat: 49.2800032, lng: 21.5913024 },
    { lat: 49.2786918, lng: 21.5918808 },
    { lat: 49.278102, lng: 21.5922713 },
    { lat: 49.2773026, lng: 21.5921677 },
    { lat: 49.2754692, lng: 21.5920964 },
    { lat: 49.2741889, lng: 21.5917288 },
    { lat: 49.2729794, lng: 21.5900161 },
    { lat: 49.2711184, lng: 21.5879717 },
    { lat: 49.2700236, lng: 21.5879579 },
    { lat: 49.2695589, lng: 21.5880827 },
    { lat: 49.2676627, lng: 21.5897605 },
    { lat: 49.2666136, lng: 21.5898154 },
    { lat: 49.2645677, lng: 21.5890159 },
    { lat: 49.2637419, lng: 21.5888532 },
    { lat: 49.2635672, lng: 21.5888799 },
    { lat: 49.2626572, lng: 21.589576 },
    { lat: 49.2616982, lng: 21.5917501 },
    { lat: 49.2614052, lng: 21.5922614 },
    { lat: 49.2605011, lng: 21.5929238 },
    { lat: 49.2580338, lng: 21.5931912 },
    { lat: 49.2576869, lng: 21.5923684 },
    { lat: 49.2575279, lng: 21.5914646 },
    { lat: 49.2573444, lng: 21.5909319 },
    { lat: 49.2573026, lng: 21.5909198 },
    { lat: 49.2571519, lng: 21.5910012 },
    { lat: 49.2568461, lng: 21.591375 },
    { lat: 49.2565831, lng: 21.592359 },
    { lat: 49.2565374, lng: 21.5930228 },
    { lat: 49.2565928, lng: 21.5963711 },
    { lat: 49.2564433, lng: 21.5967787 },
    { lat: 49.2547643, lng: 21.5980621 },
    { lat: 49.2524342, lng: 21.5982523 },
    { lat: 49.2522551, lng: 21.6005671 },
    { lat: 49.2522802, lng: 21.6011107 },
    { lat: 49.2516466, lng: 21.602359 },
    { lat: 49.2512412, lng: 21.6033682 },
    { lat: 49.2504286, lng: 21.6045397 },
    { lat: 49.2502474, lng: 21.6046954 },
    { lat: 49.2496814, lng: 21.6048499 },
    { lat: 49.2493981, lng: 21.6051846 },
    { lat: 49.247663, lng: 21.6047598 },
    { lat: 49.2470049, lng: 21.6055745 },
    { lat: 49.2463713, lng: 21.6065492 },
    { lat: 49.2449901, lng: 21.6093727 },
    { lat: 49.244808, lng: 21.6099914 },
    { lat: 49.244376, lng: 21.609987 },
    { lat: 49.244376, lng: 21.609993 },
    { lat: 49.24416, lng: 21.613136 },
    { lat: 49.24527, lng: 21.61266 },
    { lat: 49.245708, lng: 21.612814 },
    { lat: 49.246298, lng: 21.613022 },
    { lat: 49.246558, lng: 21.613114 },
    { lat: 49.2466677, lng: 21.6131707 },
    { lat: 49.24678, lng: 21.613236 },
    { lat: 49.246745, lng: 21.613326 },
    { lat: 49.246525, lng: 21.613887 },
    { lat: 49.246647, lng: 21.613993 },
    { lat: 49.246788, lng: 21.614114 },
    { lat: 49.24712, lng: 21.614401 },
    { lat: 49.247973, lng: 21.615912 },
    { lat: 49.247746, lng: 21.616521 },
    { lat: 49.247712, lng: 21.617547 },
    { lat: 49.248717, lng: 21.619801 },
    { lat: 49.248893, lng: 21.620196 },
    { lat: 49.249147, lng: 21.62077 },
    { lat: 49.249857, lng: 21.619924 },
    { lat: 49.249874, lng: 21.619905 },
    { lat: 49.249977, lng: 21.619891 },
    { lat: 49.251164, lng: 21.619724 },
    { lat: 49.251217, lng: 21.619717 },
    { lat: 49.252309, lng: 21.619564 },
    { lat: 49.253254, lng: 21.619432 },
    { lat: 49.253715, lng: 21.619393 },
    { lat: 49.255073, lng: 21.619278 },
    { lat: 49.255128, lng: 21.619252 },
    { lat: 49.255557, lng: 21.619047 },
    { lat: 49.256684, lng: 21.618511 },
    { lat: 49.257051, lng: 21.618641 },
    { lat: 49.257235, lng: 21.618706 },
    { lat: 49.257488, lng: 21.618796 },
    { lat: 49.257522, lng: 21.618803 },
    { lat: 49.257701, lng: 21.61875 },
    { lat: 49.260539, lng: 21.617927 },
    { lat: 49.26065, lng: 21.617894 },
    { lat: 49.261329, lng: 21.617782 },
    { lat: 49.261923, lng: 21.617684 },
    { lat: 49.26241, lng: 21.617604 },
    { lat: 49.262904, lng: 21.617521 },
    { lat: 49.263688, lng: 21.617565 },
    { lat: 49.264176, lng: 21.617562 },
    { lat: 49.264377, lng: 21.618122 },
    { lat: 49.264482, lng: 21.618198 },
    { lat: 49.264988, lng: 21.618564 },
    { lat: 49.265074, lng: 21.618567 },
    { lat: 49.26559, lng: 21.61859 },
    { lat: 49.265892, lng: 21.618539 },
    { lat: 49.266179, lng: 21.618491 },
    { lat: 49.266469, lng: 21.618871 },
    { lat: 49.266631, lng: 21.619156 },
    { lat: 49.266732, lng: 21.619327 },
    { lat: 49.267014, lng: 21.619809 },
    { lat: 49.267048, lng: 21.619872 },
    { lat: 49.267594, lng: 21.620338 },
    { lat: 49.267723, lng: 21.620449 },
    { lat: 49.267884, lng: 21.620748 },
    { lat: 49.268018, lng: 21.621164 },
    { lat: 49.268037, lng: 21.621369 },
    { lat: 49.268235, lng: 21.621608 },
    { lat: 49.268468, lng: 21.621819 },
    { lat: 49.268691, lng: 21.621856 },
    { lat: 49.268982, lng: 21.622044 },
    { lat: 49.269093, lng: 21.622115 },
    { lat: 49.269389, lng: 21.622153 },
    { lat: 49.269666, lng: 21.622275 },
    { lat: 49.269664, lng: 21.622292 },
    { lat: 49.269938, lng: 21.622364 },
    { lat: 49.270217, lng: 21.622314 },
    { lat: 49.270311, lng: 21.622265 },
    { lat: 49.270562, lng: 21.622053 },
    { lat: 49.27073, lng: 21.622027 },
    { lat: 49.270909, lng: 21.622039 },
    { lat: 49.271071, lng: 21.622056 },
    { lat: 49.271381, lng: 21.621999 },
    { lat: 49.271383, lng: 21.621969 },
    { lat: 49.271433, lng: 21.621558 },
    { lat: 49.272595, lng: 21.62123 },
    { lat: 49.274182, lng: 21.6209 },
    { lat: 49.275378, lng: 21.623098 },
    { lat: 49.275424, lng: 21.623183 },
    { lat: 49.275569, lng: 21.623448 },
    { lat: 49.2759236, lng: 21.6224003 },
    { lat: 49.277047, lng: 21.6200081 },
    { lat: 49.2771048, lng: 21.6190813 },
    { lat: 49.2781557, lng: 21.6182649 },
    { lat: 49.2793178, lng: 21.6177768 },
    { lat: 49.280538, lng: 21.617727 },
    { lat: 49.2805412, lng: 21.6173892 },
    { lat: 49.281827, lng: 21.6150169 },
    { lat: 49.2821287, lng: 21.614861 },
    { lat: 49.2832553, lng: 21.611443 },
    { lat: 49.283378, lng: 21.6112838 },
    { lat: 49.283596, lng: 21.6107855 },
    { lat: 49.2840282, lng: 21.6101783 },
    { lat: 49.2842252, lng: 21.6100582 },
    { lat: 49.2845623, lng: 21.6092258 },
    { lat: 49.2861723, lng: 21.60642 },
    { lat: 49.286573, lng: 21.6059787 },
    { lat: 49.2875724, lng: 21.6052145 },
    { lat: 49.2880696, lng: 21.6049364 },
    { lat: 49.288608, lng: 21.6048772 },
    { lat: 49.2889035, lng: 21.6047443 },
    { lat: 49.2892076, lng: 21.6045042 },
    { lat: 49.2893512, lng: 21.6042499 },
    { lat: 49.2894578, lng: 21.6041583 },
    { lat: 49.2897262, lng: 21.6041032 },
    { lat: 49.2907022, lng: 21.6035603 },
    { lat: 49.2915457, lng: 21.6034681 },
    { lat: 49.2918192, lng: 21.6030584 },
    { lat: 49.2930313, lng: 21.6027918 },
    { lat: 49.2931817, lng: 21.6026656 },
    { lat: 49.2944415, lng: 21.6022482 },
    { lat: 49.2944828, lng: 21.6019096 },
    { lat: 49.2944976, lng: 21.6018816 },
  ],
  Svidník: [
    { lat: 49.3014859, lng: 21.5277205 },
    { lat: 49.3013401, lng: 21.5279759 },
    { lat: 49.3006124, lng: 21.5307361 },
    { lat: 49.2998574, lng: 21.5325706 },
    { lat: 49.2995934, lng: 21.5324611 },
    { lat: 49.2992286, lng: 21.5333675 },
    { lat: 49.2991691, lng: 21.5337679 },
    { lat: 49.2986977, lng: 21.5334533 },
    { lat: 49.2981063, lng: 21.5338632 },
    { lat: 49.2974914, lng: 21.534196 },
    { lat: 49.297862, lng: 21.5354101 },
    { lat: 49.2981182, lng: 21.5365658 },
    { lat: 49.2980441, lng: 21.536846 },
    { lat: 49.2980417, lng: 21.5374391 },
    { lat: 49.2984403, lng: 21.5386125 },
    { lat: 49.2969241, lng: 21.5403414 },
    { lat: 49.2968744, lng: 21.5414588 },
    { lat: 49.2970352, lng: 21.542575 },
    { lat: 49.2970402, lng: 21.5431139 },
    { lat: 49.2969244, lng: 21.5436748 },
    { lat: 49.2969566, lng: 21.5449072 },
    { lat: 49.297025, lng: 21.5455799 },
    { lat: 49.2969984, lng: 21.546875 },
    { lat: 49.2971143, lng: 21.5479588 },
    { lat: 49.2967441, lng: 21.5496025 },
    { lat: 49.2964564, lng: 21.5505311 },
    { lat: 49.2958411, lng: 21.5516235 },
    { lat: 49.2954795, lng: 21.5526531 },
    { lat: 49.295048, lng: 21.5535918 },
    { lat: 49.2949013, lng: 21.5540595 },
    { lat: 49.2947343, lng: 21.5549257 },
    { lat: 49.2943358, lng: 21.5557555 },
    { lat: 49.2936709, lng: 21.5582873 },
    { lat: 49.2935254, lng: 21.5585989 },
    { lat: 49.2935423, lng: 21.5586374 },
    { lat: 49.2930026, lng: 21.5596101 },
    { lat: 49.29261, lng: 21.5612287 },
    { lat: 49.2924667, lng: 21.5612871 },
    { lat: 49.2923434, lng: 21.56159 },
    { lat: 49.2921006, lng: 21.5617936 },
    { lat: 49.2918747, lng: 21.5618415 },
    { lat: 49.2917872, lng: 21.5623395 },
    { lat: 49.2917033, lng: 21.5639704 },
    { lat: 49.2912666, lng: 21.5641174 },
    { lat: 49.2904757, lng: 21.5645637 },
    { lat: 49.2902728, lng: 21.5646266 },
    { lat: 49.2898883, lng: 21.5645565 },
    { lat: 49.2897202, lng: 21.564658 },
    { lat: 49.2894008, lng: 21.5652241 },
    { lat: 49.2887731, lng: 21.5655479 },
    { lat: 49.288082, lng: 21.5682809 },
    { lat: 49.2870781, lng: 21.5689528 },
    { lat: 49.2864724, lng: 21.5701061 },
    { lat: 49.285902, lng: 21.5709887 },
    { lat: 49.2852456, lng: 21.5716213 },
    { lat: 49.2831147, lng: 21.5729782 },
    { lat: 49.2830392, lng: 21.5730955 },
    { lat: 49.2829181, lng: 21.5741999 },
    { lat: 49.2828015, lng: 21.5744986 },
    { lat: 49.2826112, lng: 21.5747773 },
    { lat: 49.2825624, lng: 21.5750795 },
    { lat: 49.2826936, lng: 21.5755946 },
    { lat: 49.2826931, lng: 21.5759834 },
    { lat: 49.2826352, lng: 21.5761227 },
    { lat: 49.282061, lng: 21.5761502 },
    { lat: 49.28139, lng: 21.5760086 },
    { lat: 49.2809821, lng: 21.5760243 },
    { lat: 49.2809604, lng: 21.5761753 },
    { lat: 49.280978, lng: 21.5763997 },
    { lat: 49.2813827, lng: 21.5778071 },
    { lat: 49.2816303, lng: 21.5781751 },
    { lat: 49.2816604, lng: 21.5791381 },
    { lat: 49.2814784, lng: 21.5809307 },
    { lat: 49.2811063, lng: 21.5822343 },
    { lat: 49.2809572, lng: 21.5829415 },
    { lat: 49.2808987, lng: 21.5839939 },
    { lat: 49.2810711, lng: 21.5857129 },
    { lat: 49.2817267, lng: 21.5883452 },
    { lat: 49.2816306, lng: 21.5887733 },
    { lat: 49.2810936, lng: 21.5902191 },
    { lat: 49.2806196, lng: 21.5913029 },
    { lat: 49.280539, lng: 21.5915146 },
    { lat: 49.2804981, lng: 21.5916225 },
    { lat: 49.2823438, lng: 21.5921045 },
    { lat: 49.2824802, lng: 21.5923598 },
    { lat: 49.2833719, lng: 21.5934819 },
    { lat: 49.283971, lng: 21.593543 },
    { lat: 49.2843236, lng: 21.5934824 },
    { lat: 49.2848141, lng: 21.5935242 },
    { lat: 49.2856069, lng: 21.5932712 },
    { lat: 49.2860948, lng: 21.5932605 },
    { lat: 49.28618, lng: 21.5933322 },
    { lat: 49.2863662, lng: 21.5933475 },
    { lat: 49.2868211, lng: 21.5932432 },
    { lat: 49.2873769, lng: 21.5932501 },
    { lat: 49.2880619, lng: 21.5934884 },
    { lat: 49.2883708, lng: 21.593472 },
    { lat: 49.2887298, lng: 21.5938056 },
    { lat: 49.2889637, lng: 21.5938409 },
    { lat: 49.2892541, lng: 21.5942063 },
    { lat: 49.2894307, lng: 21.5946169 },
    { lat: 49.290432, lng: 21.595478 },
    { lat: 49.290745, lng: 21.5956463 },
    { lat: 49.2909168, lng: 21.595885 },
    { lat: 49.2911551, lng: 21.5964473 },
    { lat: 49.2915866, lng: 21.596551 },
    { lat: 49.2920175, lng: 21.5968349 },
    { lat: 49.2926506, lng: 21.5968329 },
    { lat: 49.2935933, lng: 21.597361 },
    { lat: 49.2938929, lng: 21.5977348 },
    { lat: 49.2943664, lng: 21.5991182 },
    { lat: 49.2944901, lng: 21.5993639 },
    { lat: 49.2949479, lng: 21.5998062 },
    { lat: 49.2946102, lng: 21.6007841 },
    { lat: 49.2944976, lng: 21.6018816 },
    { lat: 49.2945237, lng: 21.6018879 },
    { lat: 49.2952552, lng: 21.6020617 },
    { lat: 49.2959846, lng: 21.6020795 },
    { lat: 49.2965537, lng: 21.6017466 },
    { lat: 49.2969349, lng: 21.6016978 },
    { lat: 49.2973582, lng: 21.6013018 },
    { lat: 49.2975826, lng: 21.6012841 },
    { lat: 49.2976389, lng: 21.6011208 },
    { lat: 49.2980585, lng: 21.6010447 },
    { lat: 49.2987832, lng: 21.6012107 },
    { lat: 49.2999169, lng: 21.6017345 },
    { lat: 49.300087, lng: 21.602072 },
    { lat: 49.3001921, lng: 21.6021305 },
    { lat: 49.3007002, lng: 21.6019896 },
    { lat: 49.3011216, lng: 21.6019797 },
    { lat: 49.3021028, lng: 21.6030844 },
    { lat: 49.3026006, lng: 21.603889 },
    { lat: 49.3025794, lng: 21.6044184 },
    { lat: 49.3026859, lng: 21.6049829 },
    { lat: 49.3025837, lng: 21.6051041 },
    { lat: 49.3033383, lng: 21.6081646 },
    { lat: 49.3036211, lng: 21.6088364 },
    { lat: 49.3042404, lng: 21.6100034 },
    { lat: 49.3044436, lng: 21.6115415 },
    { lat: 49.3045579, lng: 21.6120623 },
    { lat: 49.3046242, lng: 21.613284 },
    { lat: 49.3057172, lng: 21.6116319 },
    { lat: 49.3064705, lng: 21.6108834 },
    { lat: 49.3068728, lng: 21.6106575 },
    { lat: 49.3063827, lng: 21.6099806 },
    { lat: 49.3060498, lng: 21.60986 },
    { lat: 49.3055928, lng: 21.6093112 },
    { lat: 49.3058149, lng: 21.6086801 },
    { lat: 49.305892, lng: 21.6085781 },
    { lat: 49.306606, lng: 21.6079943 },
    { lat: 49.3067124, lng: 21.6080142 },
    { lat: 49.3075534, lng: 21.6075166 },
    { lat: 49.3078285, lng: 21.6074127 },
    { lat: 49.3084401, lng: 21.6069919 },
    { lat: 49.3086587, lng: 21.6069252 },
    { lat: 49.30898, lng: 21.6066608 },
    { lat: 49.3090949, lng: 21.6064238 },
    { lat: 49.3094791, lng: 21.6062444 },
    { lat: 49.3097463, lng: 21.6062218 },
    { lat: 49.3106561, lng: 21.6056804 },
    { lat: 49.3113334, lng: 21.6053542 },
    { lat: 49.3116868, lng: 21.60488 },
    { lat: 49.312163, lng: 21.6050491 },
    { lat: 49.312754, lng: 21.605025 },
    { lat: 49.3130723, lng: 21.6048571 },
    { lat: 49.3139601, lng: 21.6039713 },
    { lat: 49.3142085, lng: 21.6038399 },
    { lat: 49.3147585, lng: 21.6031054 },
    { lat: 49.3162971, lng: 21.6023979 },
    { lat: 49.3171296, lng: 21.600974 },
    { lat: 49.3173543, lng: 21.6003094 },
    { lat: 49.31728, lng: 21.5998981 },
    { lat: 49.3181483, lng: 21.5977766 },
    { lat: 49.3183403, lng: 21.5975154 },
    { lat: 49.3182526, lng: 21.5970289 },
    { lat: 49.3186475, lng: 21.5962602 },
    { lat: 49.3186511, lng: 21.5960606 },
    { lat: 49.3184023, lng: 21.5958077 },
    { lat: 49.3190929, lng: 21.5939792 },
    { lat: 49.3192645, lng: 21.5933486 },
    { lat: 49.3210398, lng: 21.5933153 },
    { lat: 49.3214616, lng: 21.5937604 },
    { lat: 49.3217293, lng: 21.5938343 },
    { lat: 49.3219238, lng: 21.5939988 },
    { lat: 49.3223806, lng: 21.5941665 },
    { lat: 49.322694, lng: 21.5944279 },
    { lat: 49.3231675, lng: 21.5944698 },
    { lat: 49.3232792, lng: 21.5943551 },
    { lat: 49.3239018, lng: 21.5945016 },
    { lat: 49.325962, lng: 21.5945632 },
    { lat: 49.3263556, lng: 21.5946934 },
    { lat: 49.3268884, lng: 21.5948697 },
    { lat: 49.3283343, lng: 21.5952083 },
    { lat: 49.3295182, lng: 21.5956749 },
    { lat: 49.3300203, lng: 21.5956046 },
    { lat: 49.3307009, lng: 21.5957702 },
    { lat: 49.3313407, lng: 21.5946616 },
    { lat: 49.3314664, lng: 21.5944854 },
    { lat: 49.3320099, lng: 21.593226 },
    { lat: 49.3323877, lng: 21.591673 },
    { lat: 49.3329739, lng: 21.5887903 },
    { lat: 49.3329215, lng: 21.5876312 },
    { lat: 49.3331878, lng: 21.5869286 },
    { lat: 49.3336224, lng: 21.5833284 },
    { lat: 49.3338792, lng: 21.5820035 },
    { lat: 49.3337355, lng: 21.5803493 },
    { lat: 49.3338826, lng: 21.5800176 },
    { lat: 49.3340001, lng: 21.5797099 },
    { lat: 49.3340636, lng: 21.5784718 },
    { lat: 49.3339378, lng: 21.5781189 },
    { lat: 49.3339386, lng: 21.577655 },
    { lat: 49.3343865, lng: 21.5760438 },
    { lat: 49.3343659, lng: 21.5758287 },
    { lat: 49.334201, lng: 21.5754416 },
    { lat: 49.3341502, lng: 21.574989 },
    { lat: 49.3337845, lng: 21.5743397 },
    { lat: 49.3334415, lng: 21.5734061 },
    { lat: 49.3331065, lng: 21.572972 },
    { lat: 49.3323713, lng: 21.5723039 },
    { lat: 49.3315017, lng: 21.5709297 },
    { lat: 49.3305478, lng: 21.5700804 },
    { lat: 49.3298328, lng: 21.5691301 },
    { lat: 49.3285845, lng: 21.5678456 },
    { lat: 49.3276164, lng: 21.5666529 },
    { lat: 49.3273529, lng: 21.5660793 },
    { lat: 49.3272581, lng: 21.5656227 },
    { lat: 49.3268084, lng: 21.5646174 },
    { lat: 49.3262246, lng: 21.5639818 },
    { lat: 49.325861, lng: 21.5628792 },
    { lat: 49.3256731, lng: 21.5617955 },
    { lat: 49.3251536, lng: 21.5600229 },
    { lat: 49.3249846, lng: 21.5592146 },
    { lat: 49.3249541, lng: 21.5588051 },
    { lat: 49.3248739, lng: 21.5584663 },
    { lat: 49.3248921, lng: 21.5577517 },
    { lat: 49.3247322, lng: 21.5571412 },
    { lat: 49.324706, lng: 21.5568537 },
    { lat: 49.3245917, lng: 21.5567017 },
    { lat: 49.3245975, lng: 21.5563101 },
    { lat: 49.3247294, lng: 21.5559202 },
    { lat: 49.3246589, lng: 21.555725 },
    { lat: 49.3246584, lng: 21.5552696 },
    { lat: 49.3248422, lng: 21.5547592 },
    { lat: 49.3249986, lng: 21.5545628 },
    { lat: 49.325088, lng: 21.5543366 },
    { lat: 49.3243413, lng: 21.5533021 },
    { lat: 49.3244215, lng: 21.5522669 },
    { lat: 49.3247496, lng: 21.5508093 },
    { lat: 49.3244924, lng: 21.5505391 },
    { lat: 49.3237116, lng: 21.5496959 },
    { lat: 49.3231014, lng: 21.5492696 },
    { lat: 49.3219835, lng: 21.5482838 },
    { lat: 49.321458, lng: 21.5476356 },
    { lat: 49.3203693, lng: 21.546117 },
    { lat: 49.3190966, lng: 21.5474284 },
    { lat: 49.319071, lng: 21.5470803 },
    { lat: 49.3186061, lng: 21.5477101 },
    { lat: 49.3168888, lng: 21.5454822 },
    { lat: 49.3153938, lng: 21.5434385 },
    { lat: 49.315178, lng: 21.543082 },
    { lat: 49.3145832, lng: 21.5418139 },
    { lat: 49.314768, lng: 21.5415025 },
    { lat: 49.3146239, lng: 21.5411723 },
    { lat: 49.3142629, lng: 21.540785 },
    { lat: 49.3139056, lng: 21.5400329 },
    { lat: 49.3134842, lng: 21.5396921 },
    { lat: 49.3133137, lng: 21.5391838 },
    { lat: 49.312775, lng: 21.5386286 },
    { lat: 49.3125603, lng: 21.5382775 },
    { lat: 49.3118525, lng: 21.5370657 },
    { lat: 49.3114572, lng: 21.5358518 },
    { lat: 49.310888, lng: 21.5350185 },
    { lat: 49.3102537, lng: 21.5345858 },
    { lat: 49.3098883, lng: 21.5341491 },
    { lat: 49.3091664, lng: 21.5330072 },
    { lat: 49.3083779, lng: 21.5321279 },
    { lat: 49.3078209, lng: 21.5316047 },
    { lat: 49.3073831, lng: 21.5310765 },
    { lat: 49.3067831, lng: 21.5307887 },
    { lat: 49.3062353, lng: 21.5301882 },
    { lat: 49.305441, lng: 21.5295069 },
    { lat: 49.3031576, lng: 21.5279985 },
    { lat: 49.3028558, lng: 21.5279041 },
    { lat: 49.3014859, lng: 21.5277205 },
  ],
  Dubová: [
    { lat: 49.3698394, lng: 21.4545809 },
    { lat: 49.3714187, lng: 21.4525769 },
    { lat: 49.372465, lng: 21.4514087 },
    { lat: 49.3734787, lng: 21.4486799 },
    { lat: 49.374288, lng: 21.4463175 },
    { lat: 49.3749825, lng: 21.4436772 },
    { lat: 49.3754323, lng: 21.44149 },
    { lat: 49.3760557, lng: 21.4393584 },
    { lat: 49.3770507, lng: 21.4363003 },
    { lat: 49.3771296, lng: 21.4351407 },
    { lat: 49.377624, lng: 21.4301849 },
    { lat: 49.378424, lng: 21.4281332 },
    { lat: 49.3797088, lng: 21.4261989 },
    { lat: 49.3817725, lng: 21.4215629 },
    { lat: 49.381457, lng: 21.420055 },
    { lat: 49.381521, lng: 21.418279 },
    { lat: 49.381272, lng: 21.417421 },
    { lat: 49.380607, lng: 21.41707 },
    { lat: 49.380418, lng: 21.417025 },
    { lat: 49.380376, lng: 21.417016 },
    { lat: 49.379864, lng: 21.416895 },
    { lat: 49.379048, lng: 21.416673 },
    { lat: 49.378513, lng: 21.415932 },
    { lat: 49.378483, lng: 21.415892 },
    { lat: 49.377952, lng: 21.415835 },
    { lat: 49.377158, lng: 21.415388 },
    { lat: 49.37632, lng: 21.41534 },
    { lat: 49.375779, lng: 21.415044 },
    { lat: 49.375077, lng: 21.414501 },
    { lat: 49.374504, lng: 21.41442 },
    { lat: 49.374025, lng: 21.413912 },
    { lat: 49.373008, lng: 21.413327 },
    { lat: 49.372907, lng: 21.413203 },
    { lat: 49.372401, lng: 21.412283 },
    { lat: 49.371805, lng: 21.411754 },
    { lat: 49.371802, lng: 21.411246 },
    { lat: 49.372018, lng: 21.410462 },
    { lat: 49.3693525, lng: 21.4103946 },
    { lat: 49.3679997, lng: 21.4102611 },
    { lat: 49.367351, lng: 21.4109354 },
    { lat: 49.3670594, lng: 21.4111535 },
    { lat: 49.3661803, lng: 21.411105 },
    { lat: 49.3650332, lng: 21.4123107 },
    { lat: 49.3645212, lng: 21.4124655 },
    { lat: 49.3632794, lng: 21.4126641 },
    { lat: 49.3622584, lng: 21.41264 },
    { lat: 49.3611669, lng: 21.4129395 },
    { lat: 49.3609546, lng: 21.4130735 },
    { lat: 49.3608651, lng: 21.4130096 },
    { lat: 49.3596845, lng: 21.4131501 },
    { lat: 49.3597299, lng: 21.4133817 },
    { lat: 49.3597055, lng: 21.4135627 },
    { lat: 49.359456, lng: 21.4137624 },
    { lat: 49.3592019, lng: 21.4136909 },
    { lat: 49.3580966, lng: 21.4142522 },
    { lat: 49.3580705, lng: 21.4140938 },
    { lat: 49.357929, lng: 21.4139865 },
    { lat: 49.3568623, lng: 21.4136653 },
    { lat: 49.3550677, lng: 21.4129668 },
    { lat: 49.3544938, lng: 21.4130349 },
    { lat: 49.3535846, lng: 21.4123323 },
    { lat: 49.3527771, lng: 21.4118859 },
    { lat: 49.350065, lng: 21.4118208 },
    { lat: 49.34925, lng: 21.4113738 },
    { lat: 49.3482788, lng: 21.4119042 },
    { lat: 49.347805, lng: 21.411693 },
    { lat: 49.347801, lng: 21.412118 },
    { lat: 49.347447, lng: 21.4127 },
    { lat: 49.347264, lng: 21.413884 },
    { lat: 49.34676, lng: 21.41526 },
    { lat: 49.346335, lng: 21.416405 },
    { lat: 49.345972, lng: 21.417239 },
    { lat: 49.345812, lng: 21.417976 },
    { lat: 49.345661, lng: 21.418634 },
    { lat: 49.345618, lng: 21.418812 },
    { lat: 49.345232, lng: 21.420425 },
    { lat: 49.345001, lng: 21.421395 },
    { lat: 49.344914, lng: 21.422268 },
    { lat: 49.344817, lng: 21.422902 },
    { lat: 49.34456, lng: 21.42389 },
    { lat: 49.3441041, lng: 21.4250539 },
    { lat: 49.3438564, lng: 21.4264437 },
    { lat: 49.3434563, lng: 21.4277009 },
    { lat: 49.3431734, lng: 21.4299675 },
    { lat: 49.343385, lng: 21.4304356 },
    { lat: 49.3435764, lng: 21.4319531 },
    { lat: 49.342094, lng: 21.4335128 },
    { lat: 49.3412668, lng: 21.4355663 },
    { lat: 49.3409442, lng: 21.4368153 },
    { lat: 49.3405407, lng: 21.4375281 },
    { lat: 49.3405015, lng: 21.4380777 },
    { lat: 49.340852, lng: 21.4396991 },
    { lat: 49.3408158, lng: 21.4410473 },
    { lat: 49.3409799, lng: 21.4414695 },
    { lat: 49.3411347, lng: 21.4422744 },
    { lat: 49.3401487, lng: 21.4438939 },
    { lat: 49.3397904, lng: 21.4446549 },
    { lat: 49.339581, lng: 21.4461637 },
    { lat: 49.3397038, lng: 21.4471425 },
    { lat: 49.3396377, lng: 21.4494162 },
    { lat: 49.3396849, lng: 21.4503933 },
    { lat: 49.3394267, lng: 21.4514616 },
    { lat: 49.3392381, lng: 21.4517147 },
    { lat: 49.3389674, lng: 21.4528549 },
    { lat: 49.3385201, lng: 21.4539238 },
    { lat: 49.338333, lng: 21.4552452 },
    { lat: 49.3384011, lng: 21.4559643 },
    { lat: 49.3390951, lng: 21.4583469 },
    { lat: 49.339117, lng: 21.4583206 },
    { lat: 49.3400053, lng: 21.4572152 },
    { lat: 49.3433716, lng: 21.4579667 },
    { lat: 49.3445393, lng: 21.458519 },
    { lat: 49.3453301, lng: 21.459336 },
    { lat: 49.3467254, lng: 21.4599744 },
    { lat: 49.3494482, lng: 21.4608822 },
    { lat: 49.3520575, lng: 21.4623494 },
    { lat: 49.3526445, lng: 21.4613629 },
    { lat: 49.352862, lng: 21.4612938 },
    { lat: 49.3531973, lng: 21.461452 },
    { lat: 49.3533652, lng: 21.462087 },
    { lat: 49.3537215, lng: 21.4618028 },
    { lat: 49.3551537, lng: 21.4616093 },
    { lat: 49.3554435, lng: 21.4617912 },
    { lat: 49.3554879, lng: 21.4614165 },
    { lat: 49.3571728, lng: 21.4622651 },
    { lat: 49.3583591, lng: 21.4617413 },
    { lat: 49.3590783, lng: 21.4613219 },
    { lat: 49.3604867, lng: 21.4601435 },
    { lat: 49.3618271, lng: 21.4583669 },
    { lat: 49.3619032, lng: 21.4579958 },
    { lat: 49.3633582, lng: 21.4552354 },
    { lat: 49.3639152, lng: 21.4555107 },
    { lat: 49.3651036, lng: 21.4563065 },
    { lat: 49.3658516, lng: 21.4557998 },
    { lat: 49.3667935, lng: 21.4549497 },
    { lat: 49.3678634, lng: 21.4544064 },
    { lat: 49.368928, lng: 21.4546118 },
    { lat: 49.3698394, lng: 21.4545809 },
  ],
  Lúčka: [
    { lat: 49.1013754, lng: 21.4619073 },
    { lat: 49.1014873, lng: 21.4639931 },
    { lat: 49.1013461, lng: 21.464209 },
    { lat: 49.101096, lng: 21.4653274 },
    { lat: 49.1013068, lng: 21.4655341 },
    { lat: 49.1013363, lng: 21.4656962 },
    { lat: 49.1012644, lng: 21.4658195 },
    { lat: 49.1012206, lng: 21.4660931 },
    { lat: 49.1012654, lng: 21.466632 },
    { lat: 49.1010572, lng: 21.467726 },
    { lat: 49.1008141, lng: 21.467758 },
    { lat: 49.1006457, lng: 21.4684755 },
    { lat: 49.1003003, lng: 21.4688667 },
    { lat: 49.1002474, lng: 21.4693319 },
    { lat: 49.1004127, lng: 21.4698386 },
    { lat: 49.1002256, lng: 21.4701782 },
    { lat: 49.1001467, lng: 21.4704428 },
    { lat: 49.1002563, lng: 21.4709324 },
    { lat: 49.1001269, lng: 21.4712846 },
    { lat: 49.100196, lng: 21.4725036 },
    { lat: 49.1001242, lng: 21.47287 },
    { lat: 49.0999705, lng: 21.4731962 },
    { lat: 49.1000335, lng: 21.4739345 },
    { lat: 49.0997591, lng: 21.475467 },
    { lat: 49.1002454, lng: 21.4757447 },
    { lat: 49.0997785, lng: 21.4780939 },
    { lat: 49.0998973, lng: 21.4781976 },
    { lat: 49.099688, lng: 21.4792916 },
    { lat: 49.0995519, lng: 21.4803466 },
    { lat: 49.0998534, lng: 21.4805031 },
    { lat: 49.1004433, lng: 21.4806735 },
    { lat: 49.10014, lng: 21.4816323 },
    { lat: 49.0999285, lng: 21.4830829 },
    { lat: 49.0999113, lng: 21.4846779 },
    { lat: 49.0999718, lng: 21.485258 },
    { lat: 49.0998725, lng: 21.4858319 },
    { lat: 49.0999028, lng: 21.4859516 },
    { lat: 49.1000445, lng: 21.4861052 },
    { lat: 49.1001843, lng: 21.4861157 },
    { lat: 49.100242, lng: 21.4866307 },
    { lat: 49.1001704, lng: 21.4868465 },
    { lat: 49.1001228, lng: 21.4872648 },
    { lat: 49.1005324, lng: 21.4871855 },
    { lat: 49.1005378, lng: 21.4872939 },
    { lat: 49.1022063, lng: 21.4868264 },
    { lat: 49.1025843, lng: 21.4906695 },
    { lat: 49.1048304, lng: 21.4899791 },
    { lat: 49.105216, lng: 21.4920154 },
    { lat: 49.1049167, lng: 21.4920097 },
    { lat: 49.1049126, lng: 21.4923368 },
    { lat: 49.1047499, lng: 21.4924359 },
    { lat: 49.104796, lng: 21.4932221 },
    { lat: 49.1049465, lng: 21.4932078 },
    { lat: 49.1049492, lng: 21.4933441 },
    { lat: 49.1049555, lng: 21.4936374 },
    { lat: 49.1059385, lng: 21.4947715 },
    { lat: 49.1061225, lng: 21.4948304 },
    { lat: 49.1065271, lng: 21.494536 },
    { lat: 49.1062402, lng: 21.4934412 },
    { lat: 49.106433, lng: 21.4929034 },
    { lat: 49.1065197, lng: 21.4925141 },
    { lat: 49.106463, lng: 21.4924205 },
    { lat: 49.1067672, lng: 21.4920438 },
    { lat: 49.1073502, lng: 21.4918539 },
    { lat: 49.1075344, lng: 21.4922776 },
    { lat: 49.1077816, lng: 21.4930052 },
    { lat: 49.107897, lng: 21.4935028 },
    { lat: 49.1080312, lng: 21.4938275 },
    { lat: 49.1081714, lng: 21.493889 },
    { lat: 49.1084303, lng: 21.4930663 },
    { lat: 49.108646, lng: 21.4927866 },
    { lat: 49.1087681, lng: 21.4927275 },
    { lat: 49.1091473, lng: 21.4927321 },
    { lat: 49.1093711, lng: 21.4928817 },
    { lat: 49.1093821, lng: 21.4937361 },
    { lat: 49.1094718, lng: 21.4939603 },
    { lat: 49.1101038, lng: 21.4944215 },
    { lat: 49.1104791, lng: 21.4942885 },
    { lat: 49.1106681, lng: 21.4940949 },
    { lat: 49.110954, lng: 21.4934658 },
    { lat: 49.1114608, lng: 21.4929358 },
    { lat: 49.1116381, lng: 21.4929194 },
    { lat: 49.1121524, lng: 21.4925706 },
    { lat: 49.1122853, lng: 21.4921459 },
    { lat: 49.1123747, lng: 21.4915306 },
    { lat: 49.1126139, lng: 21.4907289 },
    { lat: 49.1129106, lng: 21.4906014 },
    { lat: 49.1135021, lng: 21.4905111 },
    { lat: 49.1137085, lng: 21.4910016 },
    { lat: 49.1137545, lng: 21.491273 },
    { lat: 49.1137951, lng: 21.4912757 },
    { lat: 49.1137867, lng: 21.4917704 },
    { lat: 49.1136481, lng: 21.4923506 },
    { lat: 49.1133694, lng: 21.4928325 },
    { lat: 49.1133065, lng: 21.493115 },
    { lat: 49.1133059, lng: 21.493365 },
    { lat: 49.1134824, lng: 21.4934362 },
    { lat: 49.1136905, lng: 21.4934008 },
    { lat: 49.1138726, lng: 21.4931183 },
    { lat: 49.114386, lng: 21.4929312 },
    { lat: 49.1146924, lng: 21.4929134 },
    { lat: 49.1149265, lng: 21.4929887 },
    { lat: 49.11523, lng: 21.4932203 },
    { lat: 49.1163069, lng: 21.4894143 },
    { lat: 49.1163747, lng: 21.4893868 },
    { lat: 49.1160812, lng: 21.4880957 },
    { lat: 49.1162357, lng: 21.4880261 },
    { lat: 49.1173885, lng: 21.4875023 },
    { lat: 49.1173587, lng: 21.4873689 },
    { lat: 49.117974, lng: 21.4815995 },
    { lat: 49.117976, lng: 21.4810042 },
    { lat: 49.1170624, lng: 21.4756807 },
    { lat: 49.1162607, lng: 21.4713197 },
    { lat: 49.1162457, lng: 21.4712643 },
    { lat: 49.1161658, lng: 21.4707564 },
    { lat: 49.1159536, lng: 21.4708485 },
    { lat: 49.1155965, lng: 21.4707912 },
    { lat: 49.1154904, lng: 21.4708572 },
    { lat: 49.1148466, lng: 21.4708126 },
    { lat: 49.1147895, lng: 21.4709009 },
    { lat: 49.1143613, lng: 21.4708005 },
    { lat: 49.1141911, lng: 21.4708263 },
    { lat: 49.1135707, lng: 21.4705761 },
    { lat: 49.1134169, lng: 21.4698441 },
    { lat: 49.1139783, lng: 21.4683642 },
    { lat: 49.1140984, lng: 21.4674543 },
    { lat: 49.1112423, lng: 21.4655222 },
    { lat: 49.1106047, lng: 21.4661233 },
    { lat: 49.1081354, lng: 21.4648834 },
    { lat: 49.1066629, lng: 21.464072 },
    { lat: 49.1026888, lng: 21.4624703 },
    { lat: 49.1013754, lng: 21.4619073 },
  ],
  Fijaš: [
    { lat: 49.137367, lng: 21.594256 },
    { lat: 49.137547, lng: 21.593989 },
    { lat: 49.137894, lng: 21.593478 },
    { lat: 49.13826, lng: 21.593141 },
    { lat: 49.138294, lng: 21.593089 },
    { lat: 49.138486, lng: 21.592766 },
    { lat: 49.138573, lng: 21.592736 },
    { lat: 49.138871, lng: 21.592326 },
    { lat: 49.139375, lng: 21.591613 },
    { lat: 49.140311, lng: 21.590425 },
    { lat: 49.140504, lng: 21.590053 },
    { lat: 49.141155, lng: 21.588573 },
    { lat: 49.1401384, lng: 21.5880711 },
    { lat: 49.139803, lng: 21.5880317 },
    { lat: 49.1396174, lng: 21.5877936 },
    { lat: 49.1390226, lng: 21.5878848 },
    { lat: 49.1379213, lng: 21.5871583 },
    { lat: 49.1375434, lng: 21.5866768 },
    { lat: 49.1371877, lng: 21.5863807 },
    { lat: 49.1369598, lng: 21.585915 },
    { lat: 49.1370062, lng: 21.5851935 },
    { lat: 49.1369696, lng: 21.5848947 },
    { lat: 49.1368402, lng: 21.5848894 },
    { lat: 49.136532, lng: 21.584282 },
    { lat: 49.136083, lng: 21.5839992 },
    { lat: 49.1358249, lng: 21.5834572 },
    { lat: 49.135362, lng: 21.582674 },
    { lat: 49.1352459, lng: 21.5823834 },
    { lat: 49.1349812, lng: 21.5811146 },
    { lat: 49.1350607, lng: 21.5798132 },
    { lat: 49.1352561, lng: 21.579031 },
    { lat: 49.1353277, lng: 21.5789707 },
    { lat: 49.1353535, lng: 21.5786869 },
    { lat: 49.1353168, lng: 21.5780552 },
    { lat: 49.1353577, lng: 21.577062 },
    { lat: 49.1352782, lng: 21.5768336 },
    { lat: 49.1352726, lng: 21.5764722 },
    { lat: 49.1353825, lng: 21.5762596 },
    { lat: 49.1352744, lng: 21.5759788 },
    { lat: 49.1352877, lng: 21.5754397 },
    { lat: 49.1350592, lng: 21.5738255 },
    { lat: 49.1348941, lng: 21.5736778 },
    { lat: 49.1349306, lng: 21.5734908 },
    { lat: 49.1346429, lng: 21.5728958 },
    { lat: 49.1345898, lng: 21.5725213 },
    { lat: 49.1344336, lng: 21.5722134 },
    { lat: 49.1356469, lng: 21.5676814 },
    { lat: 49.1352423, lng: 21.567375 },
    { lat: 49.1353771, lng: 21.5671209 },
    { lat: 49.1345498, lng: 21.5659045 },
    { lat: 49.1340201, lng: 21.5653832 },
    { lat: 49.1339786, lng: 21.5655091 },
    { lat: 49.1338471, lng: 21.5653606 },
    { lat: 49.1334496, lng: 21.5651813 },
    { lat: 49.1332314, lng: 21.5649853 },
    { lat: 49.1330773, lng: 21.5650288 },
    { lat: 49.1330829, lng: 21.564866 },
    { lat: 49.1326461, lng: 21.5646903 },
    { lat: 49.1321485, lng: 21.5647194 },
    { lat: 49.1315021, lng: 21.5646619 },
    { lat: 49.1310832, lng: 21.5644858 },
    { lat: 49.1308702, lng: 21.5641516 },
    { lat: 49.1307332, lng: 21.5635939 },
    { lat: 49.1306142, lng: 21.5634443 },
    { lat: 49.1302157, lng: 21.5625075 },
    { lat: 49.1303253, lng: 21.5618012 },
    { lat: 49.1304158, lng: 21.5610998 },
    { lat: 49.1302564, lng: 21.5612762 },
    { lat: 49.1294349, lng: 21.5613896 },
    { lat: 49.1284359, lng: 21.5613081 },
    { lat: 49.1282549, lng: 21.561193 },
    { lat: 49.1280701, lng: 21.5612596 },
    { lat: 49.1277795, lng: 21.5609283 },
    { lat: 49.1274848, lng: 21.5609288 },
    { lat: 49.1273893, lng: 21.5608506 },
    { lat: 49.1273408, lng: 21.5606863 },
    { lat: 49.1266758, lng: 21.5608478 },
    { lat: 49.1264636, lng: 21.5607014 },
    { lat: 49.126238, lng: 21.5604355 },
    { lat: 49.126153, lng: 21.560205 },
    { lat: 49.126091, lng: 21.560298 },
    { lat: 49.125587, lng: 21.561101 },
    { lat: 49.125036, lng: 21.561916 },
    { lat: 49.125266, lng: 21.563768 },
    { lat: 49.124397, lng: 21.564371 },
    { lat: 49.12403, lng: 21.56447 },
    { lat: 49.123033, lng: 21.564815 },
    { lat: 49.123944, lng: 21.566597 },
    { lat: 49.123427, lng: 21.567779 },
    { lat: 49.120685, lng: 21.567402 },
    { lat: 49.119749, lng: 21.56815 },
    { lat: 49.119521, lng: 21.569554 },
    { lat: 49.119465, lng: 21.569948 },
    { lat: 49.119338, lng: 21.570572 },
    { lat: 49.119292, lng: 21.570886 },
    { lat: 49.119163, lng: 21.57082 },
    { lat: 49.119013, lng: 21.570747 },
    { lat: 49.118436, lng: 21.570777 },
    { lat: 49.118066, lng: 21.570688 },
    { lat: 49.118005, lng: 21.570788 },
    { lat: 49.117684, lng: 21.570755 },
    { lat: 49.117482, lng: 21.570868 },
    { lat: 49.117374, lng: 21.570777 },
    { lat: 49.117299, lng: 21.570955 },
    { lat: 49.116911, lng: 21.571206 },
    { lat: 49.116655, lng: 21.571303 },
    { lat: 49.116609, lng: 21.571429 },
    { lat: 49.116483, lng: 21.571543 },
    { lat: 49.116413, lng: 21.571707 },
    { lat: 49.116234, lng: 21.571785 },
    { lat: 49.116004, lng: 21.571932 },
    { lat: 49.115792, lng: 21.572221 },
    { lat: 49.115595, lng: 21.572327 },
    { lat: 49.115472, lng: 21.57234 },
    { lat: 49.115381, lng: 21.572454 },
    { lat: 49.115353, lng: 21.572508 },
    { lat: 49.115248, lng: 21.572708 },
    { lat: 49.115207, lng: 21.572678 },
    { lat: 49.11503, lng: 21.573638 },
    { lat: 49.114577, lng: 21.574985 },
    { lat: 49.114494, lng: 21.575119 },
    { lat: 49.114245, lng: 21.575255 },
    { lat: 49.113894, lng: 21.575686 },
    { lat: 49.113649, lng: 21.576034 },
    { lat: 49.113464, lng: 21.576418 },
    { lat: 49.11319, lng: 21.577194 },
    { lat: 49.113224, lng: 21.577238 },
    { lat: 49.114002, lng: 21.578372 },
    { lat: 49.11424, lng: 21.578806 },
    { lat: 49.114693, lng: 21.579619 },
    { lat: 49.114861, lng: 21.579887 },
    { lat: 49.115096, lng: 21.580043 },
    { lat: 49.115454, lng: 21.580443 },
    { lat: 49.115688, lng: 21.580759 },
    { lat: 49.116044, lng: 21.581145 },
    { lat: 49.116331, lng: 21.581395 },
    { lat: 49.116559, lng: 21.581754 },
    { lat: 49.116716, lng: 21.582069 },
    { lat: 49.116931, lng: 21.58242 },
    { lat: 49.117086, lng: 21.582779 },
    { lat: 49.117181, lng: 21.583155 },
    { lat: 49.117341, lng: 21.583545 },
    { lat: 49.11749, lng: 21.583821 },
    { lat: 49.117587, lng: 21.58408 },
    { lat: 49.117852, lng: 21.584649 },
    { lat: 49.117873, lng: 21.585013 },
    { lat: 49.117925, lng: 21.58531 },
    { lat: 49.117899, lng: 21.585599 },
    { lat: 49.117808, lng: 21.585827 },
    { lat: 49.117889, lng: 21.586666 },
    { lat: 49.117844, lng: 21.586712 },
    { lat: 49.117951, lng: 21.587171 },
    { lat: 49.118256, lng: 21.5872 },
    { lat: 49.118376, lng: 21.587197 },
    { lat: 49.118564, lng: 21.586927 },
    { lat: 49.118621, lng: 21.586898 },
    { lat: 49.118847, lng: 21.587031 },
    { lat: 49.118874, lng: 21.587049 },
    { lat: 49.119018, lng: 21.586754 },
    { lat: 49.119191, lng: 21.586799 },
    { lat: 49.1195, lng: 21.58721 },
    { lat: 49.119587, lng: 21.587306 },
    { lat: 49.119679, lng: 21.587888 },
    { lat: 49.119694, lng: 21.587982 },
    { lat: 49.119712, lng: 21.588085 },
    { lat: 49.119727, lng: 21.588206 },
    { lat: 49.119785, lng: 21.588598 },
    { lat: 49.119823, lng: 21.58886 },
    { lat: 49.119655, lng: 21.589775 },
    { lat: 49.119967, lng: 21.590002 },
    { lat: 49.120317, lng: 21.590156 },
    { lat: 49.120578, lng: 21.590418 },
    { lat: 49.120747, lng: 21.590587 },
    { lat: 49.121542, lng: 21.590997 },
    { lat: 49.121926, lng: 21.591217 },
    { lat: 49.121945, lng: 21.591619 },
    { lat: 49.121973, lng: 21.591888 },
    { lat: 49.122282, lng: 21.592081 },
    { lat: 49.122479, lng: 21.592161 },
    { lat: 49.122757, lng: 21.592212 },
    { lat: 49.123124, lng: 21.591941 },
    { lat: 49.123182, lng: 21.591934 },
    { lat: 49.123315, lng: 21.592126 },
    { lat: 49.123479, lng: 21.592098 },
    { lat: 49.123626, lng: 21.591874 },
    { lat: 49.123701, lng: 21.591856 },
    { lat: 49.123781, lng: 21.591919 },
    { lat: 49.123974, lng: 21.592017 },
    { lat: 49.124237, lng: 21.592011 },
    { lat: 49.124555, lng: 21.592215 },
    { lat: 49.125912, lng: 21.592889 },
    { lat: 49.125983, lng: 21.593054 },
    { lat: 49.126605, lng: 21.593439 },
    { lat: 49.126813, lng: 21.593624 },
    { lat: 49.127324, lng: 21.593721 },
    { lat: 49.128098, lng: 21.593513 },
    { lat: 49.128556, lng: 21.593373 },
    { lat: 49.128858, lng: 21.593399 },
    { lat: 49.129226, lng: 21.593498 },
    { lat: 49.129603, lng: 21.593503 },
    { lat: 49.129701, lng: 21.593504 },
    { lat: 49.129748, lng: 21.59353 },
    { lat: 49.129891, lng: 21.593609 },
    { lat: 49.129921, lng: 21.593716 },
    { lat: 49.130087, lng: 21.593781 },
    { lat: 49.130616, lng: 21.593743 },
    { lat: 49.131002, lng: 21.593666 },
    { lat: 49.131167, lng: 21.593583 },
    { lat: 49.13163, lng: 21.593539 },
    { lat: 49.13175, lng: 21.593663 },
    { lat: 49.132326, lng: 21.593671 },
    { lat: 49.132451, lng: 21.593615 },
    { lat: 49.13291, lng: 21.593567 },
    { lat: 49.133307, lng: 21.593777 },
    { lat: 49.133374, lng: 21.593817 },
    { lat: 49.133741, lng: 21.593896 },
    { lat: 49.133902, lng: 21.59381 },
    { lat: 49.133927, lng: 21.593774 },
    { lat: 49.134045, lng: 21.593696 },
    { lat: 49.134074, lng: 21.593557 },
    { lat: 49.134337, lng: 21.59328 },
    { lat: 49.134428, lng: 21.593343 },
    { lat: 49.134705, lng: 21.593165 },
    { lat: 49.135161, lng: 21.592652 },
    { lat: 49.135306, lng: 21.592882 },
    { lat: 49.135464, lng: 21.593888 },
    { lat: 49.135456, lng: 21.59393 },
    { lat: 49.135971, lng: 21.594229 },
    { lat: 49.136852, lng: 21.594107 },
    { lat: 49.137367, lng: 21.594256 },
  ],
  Valkovce: [
    { lat: 49.1775161, lng: 21.5221288 },
    { lat: 49.1774991, lng: 21.5221209 },
    { lat: 49.1771301, lng: 21.5219586 },
    { lat: 49.177375, lng: 21.5205203 },
    { lat: 49.178085, lng: 21.5191757 },
    { lat: 49.179103, lng: 21.5161127 },
    { lat: 49.1794585, lng: 21.5147673 },
    { lat: 49.1799762, lng: 21.5135863 },
    { lat: 49.179521, lng: 21.51269 },
    { lat: 49.1793838, lng: 21.5113477 },
    { lat: 49.1781212, lng: 21.5108623 },
    { lat: 49.177715, lng: 21.51102 },
    { lat: 49.1773917, lng: 21.5102129 },
    { lat: 49.1759546, lng: 21.5112245 },
    { lat: 49.1751384, lng: 21.5106935 },
    { lat: 49.1742068, lng: 21.5092621 },
    { lat: 49.1740153, lng: 21.5080681 },
    { lat: 49.1743472, lng: 21.5067628 },
    { lat: 49.1744792, lng: 21.5055552 },
    { lat: 49.174725, lng: 21.504342 },
    { lat: 49.174706, lng: 21.504353 },
    { lat: 49.174423, lng: 21.504731 },
    { lat: 49.173919, lng: 21.50452 },
    { lat: 49.173717, lng: 21.504337 },
    { lat: 49.173575, lng: 21.504204 },
    { lat: 49.173496, lng: 21.50401 },
    { lat: 49.173386, lng: 21.503544 },
    { lat: 49.173054, lng: 21.502949 },
    { lat: 49.172855, lng: 21.502416 },
    { lat: 49.172762, lng: 21.501904 },
    { lat: 49.171653, lng: 21.501493 },
    { lat: 49.171297, lng: 21.501242 },
    { lat: 49.171203, lng: 21.501448 },
    { lat: 49.170135, lng: 21.500921 },
    { lat: 49.168246, lng: 21.501452 },
    { lat: 49.168052, lng: 21.501442 },
    { lat: 49.167954, lng: 21.501437 },
    { lat: 49.167612, lng: 21.501269 },
    { lat: 49.16674, lng: 21.500832 },
    { lat: 49.166695, lng: 21.500809 },
    { lat: 49.166414, lng: 21.500668 },
    { lat: 49.16621, lng: 21.499858 },
    { lat: 49.166098, lng: 21.499894 },
    { lat: 49.166013, lng: 21.499769 },
    { lat: 49.165812, lng: 21.498884 },
    { lat: 49.165739, lng: 21.498874 },
    { lat: 49.165564, lng: 21.498239 },
    { lat: 49.165634, lng: 21.497862 },
    { lat: 49.165585, lng: 21.497525 },
    { lat: 49.165313, lng: 21.497479 },
    { lat: 49.16491, lng: 21.497546 },
    { lat: 49.164667, lng: 21.497493 },
    { lat: 49.164518, lng: 21.497527 },
    { lat: 49.164352, lng: 21.497487 },
    { lat: 49.164166, lng: 21.497516 },
    { lat: 49.164035, lng: 21.497411 },
    { lat: 49.163924, lng: 21.49746 },
    { lat: 49.163743, lng: 21.497355 },
    { lat: 49.163519, lng: 21.497349 },
    { lat: 49.163464, lng: 21.497392 },
    { lat: 49.163453, lng: 21.497401 },
    { lat: 49.163361, lng: 21.497472 },
    { lat: 49.16323, lng: 21.497622 },
    { lat: 49.16308, lng: 21.497711 },
    { lat: 49.162821, lng: 21.497786 },
    { lat: 49.162657, lng: 21.497488 },
    { lat: 49.162475, lng: 21.497486 },
    { lat: 49.162189, lng: 21.497436 },
    { lat: 49.161963, lng: 21.497379 },
    { lat: 49.161707, lng: 21.497248 },
    { lat: 49.161385, lng: 21.497324 },
    { lat: 49.161216, lng: 21.49751 },
    { lat: 49.161031, lng: 21.497601 },
    { lat: 49.160934, lng: 21.497745 },
    { lat: 49.160757, lng: 21.497855 },
    { lat: 49.160466, lng: 21.497981 },
    { lat: 49.160415, lng: 21.498032 },
    { lat: 49.160248, lng: 21.49815 },
    { lat: 49.160217, lng: 21.498378 },
    { lat: 49.160014, lng: 21.498601 },
    { lat: 49.159899, lng: 21.498571 },
    { lat: 49.159753, lng: 21.498587 },
    { lat: 49.159552, lng: 21.498556 },
    { lat: 49.159427, lng: 21.498533 },
    { lat: 49.159305, lng: 21.498647 },
    { lat: 49.159087, lng: 21.498771 },
    { lat: 49.158913, lng: 21.498899 },
    { lat: 49.15879, lng: 21.499134 },
    { lat: 49.15873, lng: 21.499313 },
    { lat: 49.158696, lng: 21.499325 },
    { lat: 49.15857, lng: 21.49932 },
    { lat: 49.158436, lng: 21.499356 },
    { lat: 49.158354, lng: 21.499317 },
    { lat: 49.158284, lng: 21.499391 },
    { lat: 49.1583, lng: 21.499475 },
    { lat: 49.158378, lng: 21.499912 },
    { lat: 49.158607, lng: 21.500464 },
    { lat: 49.158882, lng: 21.500841 },
    { lat: 49.158926, lng: 21.501037 },
    { lat: 49.159109, lng: 21.50111 },
    { lat: 49.159169, lng: 21.501189 },
    { lat: 49.159299, lng: 21.501475 },
    { lat: 49.159164, lng: 21.501968 },
    { lat: 49.159029, lng: 21.502056 },
    { lat: 49.158889, lng: 21.502329 },
    { lat: 49.158847, lng: 21.502462 },
    { lat: 49.15889, lng: 21.502689 },
    { lat: 49.159016, lng: 21.502966 },
    { lat: 49.159039, lng: 21.503466 },
    { lat: 49.158869, lng: 21.503881 },
    { lat: 49.158979, lng: 21.504574 },
    { lat: 49.158943, lng: 21.504587 },
    { lat: 49.158783, lng: 21.504927 },
    { lat: 49.158714, lng: 21.505389 },
    { lat: 49.158528, lng: 21.505688 },
    { lat: 49.158497, lng: 21.506152 },
    { lat: 49.158067, lng: 21.506777 },
    { lat: 49.157838, lng: 21.50739 },
    { lat: 49.157557, lng: 21.50765 },
    { lat: 49.15731, lng: 21.508004 },
    { lat: 49.157126, lng: 21.508449 },
    { lat: 49.156734, lng: 21.50898 },
    { lat: 49.156442, lng: 21.509589 },
    { lat: 49.155997, lng: 21.510299 },
    { lat: 49.155648, lng: 21.510621 },
    { lat: 49.15561, lng: 21.510656 },
    { lat: 49.154851, lng: 21.510705 },
    { lat: 49.154065, lng: 21.51112 },
    { lat: 49.153868, lng: 21.511539 },
    { lat: 49.153832, lng: 21.512178 },
    { lat: 49.153836, lng: 21.512255 },
    { lat: 49.15398, lng: 21.512978 },
    { lat: 49.153294, lng: 21.514585 },
    { lat: 49.153387, lng: 21.51479 },
    { lat: 49.153462, lng: 21.51573 },
    { lat: 49.153442, lng: 21.516194 },
    { lat: 49.153506, lng: 21.517092 },
    { lat: 49.153593, lng: 21.517283 },
    { lat: 49.153522, lng: 21.517486 },
    { lat: 49.1535759, lng: 21.5179855 },
    { lat: 49.1536573, lng: 21.518194 },
    { lat: 49.1538033, lng: 21.5185029 },
    { lat: 49.1541358, lng: 21.5187649 },
    { lat: 49.1542209, lng: 21.518907 },
    { lat: 49.1543767, lng: 21.519718 },
    { lat: 49.1549224, lng: 21.5208732 },
    { lat: 49.1550771, lng: 21.5216056 },
    { lat: 49.1550375, lng: 21.5216812 },
    { lat: 49.1550961, lng: 21.5221604 },
    { lat: 49.1552957, lng: 21.5228895 },
    { lat: 49.1553696, lng: 21.5228756 },
    { lat: 49.1553679, lng: 21.5230562 },
    { lat: 49.1552942, lng: 21.5232328 },
    { lat: 49.1554151, lng: 21.5235652 },
    { lat: 49.1561187, lng: 21.5244923 },
    { lat: 49.1567654, lng: 21.5252038 },
    { lat: 49.1568014, lng: 21.525558 },
    { lat: 49.1567089, lng: 21.5273024 },
    { lat: 49.1567563, lng: 21.527746 },
    { lat: 49.1570628, lng: 21.5286378 },
    { lat: 49.1572347, lng: 21.5296664 },
    { lat: 49.1575574, lng: 21.5306139 },
    { lat: 49.1575889, lng: 21.5308519 },
    { lat: 49.1576567, lng: 21.5309837 },
    { lat: 49.1576557, lng: 21.5311484 },
    { lat: 49.1578785, lng: 21.531665 },
    { lat: 49.1579507, lng: 21.5320675 },
    { lat: 49.1578995, lng: 21.5324835 },
    { lat: 49.1578031, lng: 21.532661 },
    { lat: 49.1578504, lng: 21.5332571 },
    { lat: 49.1580073, lng: 21.5334351 },
    { lat: 49.1579438, lng: 21.5336169 },
    { lat: 49.1580244, lng: 21.5337162 },
    { lat: 49.1580747, lng: 21.5340285 },
    { lat: 49.1580638, lng: 21.5342783 },
    { lat: 49.1583159, lng: 21.5351133 },
    { lat: 49.1584808, lng: 21.5353027 },
    { lat: 49.1584531, lng: 21.5360324 },
    { lat: 49.1592323, lng: 21.537454 },
    { lat: 49.1591347, lng: 21.5384589 },
    { lat: 49.1590795, lng: 21.5386585 },
    { lat: 49.1591032, lng: 21.5388974 },
    { lat: 49.1592936, lng: 21.5393871 },
    { lat: 49.1592481, lng: 21.5396126 },
    { lat: 49.1593141, lng: 21.5402297 },
    { lat: 49.1594755, lng: 21.5405689 },
    { lat: 49.1594108, lng: 21.5408341 },
    { lat: 49.1594345, lng: 21.5411158 },
    { lat: 49.159371, lng: 21.5414115 },
    { lat: 49.1594786, lng: 21.5416 },
    { lat: 49.1593793, lng: 21.5419494 },
    { lat: 49.1593846, lng: 21.5421813 },
    { lat: 49.1594182, lng: 21.5421676 },
    { lat: 49.1593852, lng: 21.5424789 },
    { lat: 49.1596009, lng: 21.5431887 },
    { lat: 49.1598094, lng: 21.5433616 },
    { lat: 49.1599611, lng: 21.5434718 },
    { lat: 49.1603237, lng: 21.5433661 },
    { lat: 49.1606302, lng: 21.5439025 },
    { lat: 49.1605814, lng: 21.5439689 },
    { lat: 49.1607361, lng: 21.5444214 },
    { lat: 49.1608878, lng: 21.5445434 },
    { lat: 49.1610645, lng: 21.5445071 },
    { lat: 49.1614104, lng: 21.5443572 },
    { lat: 49.1617625, lng: 21.5438762 },
    { lat: 49.1619125, lng: 21.5438534 },
    { lat: 49.162049, lng: 21.5439861 },
    { lat: 49.1620185, lng: 21.5440593 },
    { lat: 49.1620464, lng: 21.5444155 },
    { lat: 49.1623001, lng: 21.544433 },
    { lat: 49.1628, lng: 21.5446868 },
    { lat: 49.1631739, lng: 21.5449406 },
    { lat: 49.1637338, lng: 21.545491 },
    { lat: 49.1638726, lng: 21.5455416 },
    { lat: 49.1640926, lng: 21.5454086 },
    { lat: 49.1646903, lng: 21.5460993 },
    { lat: 49.1648827, lng: 21.5460579 },
    { lat: 49.165113, lng: 21.5462054 },
    { lat: 49.1654316, lng: 21.5467302 },
    { lat: 49.1655111, lng: 21.5474368 },
    { lat: 49.1667122, lng: 21.5472271 },
    { lat: 49.166313, lng: 21.5463933 },
    { lat: 49.1663127, lng: 21.5462563 },
    { lat: 49.1663844, lng: 21.5461732 },
    { lat: 49.1665911, lng: 21.5461967 },
    { lat: 49.1666826, lng: 21.5460879 },
    { lat: 49.1668087, lng: 21.5460552 },
    { lat: 49.166892, lng: 21.5461411 },
    { lat: 49.1670381, lng: 21.5466068 },
    { lat: 49.1669018, lng: 21.5472449 },
    { lat: 49.167123, lng: 21.5473897 },
    { lat: 49.1671772, lng: 21.5473534 },
    { lat: 49.1673421, lng: 21.5471413 },
    { lat: 49.1674445, lng: 21.5469013 },
    { lat: 49.1672851, lng: 21.546673 },
    { lat: 49.1677664, lng: 21.5453886 },
    { lat: 49.1677952, lng: 21.5450887 },
    { lat: 49.16816, lng: 21.5450256 },
    { lat: 49.1683283, lng: 21.5455266 },
    { lat: 49.1683114, lng: 21.5456862 },
    { lat: 49.1680278, lng: 21.5454042 },
    { lat: 49.1679611, lng: 21.5456315 },
    { lat: 49.16794, lng: 21.546197 },
    { lat: 49.168463, lng: 21.5462339 },
    { lat: 49.1686065, lng: 21.5461383 },
    { lat: 49.1686989, lng: 21.5460208 },
    { lat: 49.1686599, lng: 21.545557 },
    { lat: 49.16885, lng: 21.5455172 },
    { lat: 49.1689521, lng: 21.5461796 },
    { lat: 49.1692057, lng: 21.5463633 },
    { lat: 49.1694322, lng: 21.5461748 },
    { lat: 49.1694322, lng: 21.546049 },
    { lat: 49.1692349, lng: 21.5458825 },
    { lat: 49.1690722, lng: 21.5455707 },
    { lat: 49.1690647, lng: 21.5453903 },
    { lat: 49.1691377, lng: 21.5451856 },
    { lat: 49.1695264, lng: 21.5450067 },
    { lat: 49.1696794, lng: 21.5450064 },
    { lat: 49.170088, lng: 21.545327 },
    { lat: 49.1703543, lng: 21.5450816 },
    { lat: 49.1704272, lng: 21.5449492 },
    { lat: 49.1704924, lng: 21.5446709 },
    { lat: 49.1704534, lng: 21.5446397 },
    { lat: 49.1703963, lng: 21.5441706 },
    { lat: 49.170624, lng: 21.5441863 },
    { lat: 49.1708817, lng: 21.5443546 },
    { lat: 49.1710498, lng: 21.544171 },
    { lat: 49.1712089, lng: 21.543064 },
    { lat: 49.1714701, lng: 21.5424342 },
    { lat: 49.1717271, lng: 21.5414495 },
    { lat: 49.1718826, lng: 21.5403837 },
    { lat: 49.1718957, lng: 21.5402117 },
    { lat: 49.1718625, lng: 21.5401876 },
    { lat: 49.1720359, lng: 21.5395143 },
    { lat: 49.1720803, lng: 21.5389732 },
    { lat: 49.1724738, lng: 21.5372531 },
    { lat: 49.1726187, lng: 21.5368424 },
    { lat: 49.1726243, lng: 21.5366051 },
    { lat: 49.172815, lng: 21.536028 },
    { lat: 49.1729877, lng: 21.5352872 },
    { lat: 49.1731718, lng: 21.535356 },
    { lat: 49.1732132, lng: 21.5352278 },
    { lat: 49.1731479, lng: 21.5350246 },
    { lat: 49.1731485, lng: 21.5348063 },
    { lat: 49.173248, lng: 21.5345046 },
    { lat: 49.1734018, lng: 21.5333407 },
    { lat: 49.173341, lng: 21.5328974 },
    { lat: 49.1733613, lng: 21.5326217 },
    { lat: 49.1733039, lng: 21.532345 },
    { lat: 49.1732223, lng: 21.5322824 },
    { lat: 49.1731931, lng: 21.5320431 },
    { lat: 49.1732401, lng: 21.5313949 },
    { lat: 49.1733765, lng: 21.5311663 },
    { lat: 49.1742826, lng: 21.5303117 },
    { lat: 49.174498, lng: 21.529965 },
    { lat: 49.1747388, lng: 21.5292947 },
    { lat: 49.1750213, lng: 21.5288213 },
    { lat: 49.1752453, lng: 21.5278734 },
    { lat: 49.1757123, lng: 21.5269963 },
    { lat: 49.1759358, lng: 21.5263494 },
    { lat: 49.1765159, lng: 21.5255022 },
    { lat: 49.1767254, lng: 21.5249504 },
    { lat: 49.1770728, lng: 21.5231156 },
    { lat: 49.1771786, lng: 21.5229224 },
    { lat: 49.1773996, lng: 21.5227345 },
    { lat: 49.1774002, lng: 21.5223662 },
    { lat: 49.1775161, lng: 21.5221288 },
  ],
  Matovce: [
    { lat: 49.1596009, lng: 21.5431887 },
    { lat: 49.1593852, lng: 21.5424789 },
    { lat: 49.1594182, lng: 21.5421676 },
    { lat: 49.1593846, lng: 21.5421813 },
    { lat: 49.1593793, lng: 21.5419494 },
    { lat: 49.1594786, lng: 21.5416 },
    { lat: 49.159371, lng: 21.5414115 },
    { lat: 49.1594345, lng: 21.5411158 },
    { lat: 49.1594108, lng: 21.5408341 },
    { lat: 49.1594755, lng: 21.5405689 },
    { lat: 49.1593141, lng: 21.5402297 },
    { lat: 49.1592481, lng: 21.5396126 },
    { lat: 49.1592936, lng: 21.5393871 },
    { lat: 49.1591032, lng: 21.5388974 },
    { lat: 49.1590795, lng: 21.5386585 },
    { lat: 49.1591347, lng: 21.5384589 },
    { lat: 49.1592323, lng: 21.537454 },
    { lat: 49.1584531, lng: 21.5360324 },
    { lat: 49.1584808, lng: 21.5353027 },
    { lat: 49.1583159, lng: 21.5351133 },
    { lat: 49.1580638, lng: 21.5342783 },
    { lat: 49.1580747, lng: 21.5340285 },
    { lat: 49.1580244, lng: 21.5337162 },
    { lat: 49.1579438, lng: 21.5336169 },
    { lat: 49.1580073, lng: 21.5334351 },
    { lat: 49.1578504, lng: 21.5332571 },
    { lat: 49.1578031, lng: 21.532661 },
    { lat: 49.1578995, lng: 21.5324835 },
    { lat: 49.1579507, lng: 21.5320675 },
    { lat: 49.1578785, lng: 21.531665 },
    { lat: 49.1576557, lng: 21.5311484 },
    { lat: 49.1576567, lng: 21.5309837 },
    { lat: 49.1575889, lng: 21.5308519 },
    { lat: 49.1575574, lng: 21.5306139 },
    { lat: 49.1572347, lng: 21.5296664 },
    { lat: 49.1570628, lng: 21.5286378 },
    { lat: 49.1567563, lng: 21.527746 },
    { lat: 49.1567089, lng: 21.5273024 },
    { lat: 49.1568014, lng: 21.525558 },
    { lat: 49.1567654, lng: 21.5252038 },
    { lat: 49.1561187, lng: 21.5244923 },
    { lat: 49.1554151, lng: 21.5235652 },
    { lat: 49.1552942, lng: 21.5232328 },
    { lat: 49.1553679, lng: 21.5230562 },
    { lat: 49.1553696, lng: 21.5228756 },
    { lat: 49.1552957, lng: 21.5228895 },
    { lat: 49.1550961, lng: 21.5221604 },
    { lat: 49.1550375, lng: 21.5216812 },
    { lat: 49.1550771, lng: 21.5216056 },
    { lat: 49.1549224, lng: 21.5208732 },
    { lat: 49.1543767, lng: 21.519718 },
    { lat: 49.1542209, lng: 21.518907 },
    { lat: 49.1541358, lng: 21.5187649 },
    { lat: 49.1538033, lng: 21.5185029 },
    { lat: 49.1536573, lng: 21.518194 },
    { lat: 49.1535759, lng: 21.5179855 },
    { lat: 49.153522, lng: 21.517486 },
    { lat: 49.153479, lng: 21.517486 },
    { lat: 49.153271, lng: 21.518019 },
    { lat: 49.153249, lng: 21.518596 },
    { lat: 49.153045, lng: 21.519043 },
    { lat: 49.152874, lng: 21.51931 },
    { lat: 49.152779, lng: 21.519691 },
    { lat: 49.152564, lng: 21.519852 },
    { lat: 49.152232, lng: 21.520756 },
    { lat: 49.152208, lng: 21.521197 },
    { lat: 49.152176, lng: 21.521777 },
    { lat: 49.151845, lng: 21.522734 },
    { lat: 49.151731, lng: 21.52364 },
    { lat: 49.151485, lng: 21.524386 },
    { lat: 49.151511, lng: 21.525488 },
    { lat: 49.150584, lng: 21.52518 },
    { lat: 49.150114, lng: 21.524941 },
    { lat: 49.149161, lng: 21.525202 },
    { lat: 49.149074, lng: 21.525059 },
    { lat: 49.148443, lng: 21.525341 },
    { lat: 49.148316, lng: 21.525339 },
    { lat: 49.147207, lng: 21.525327 },
    { lat: 49.147057, lng: 21.525871 },
    { lat: 49.146756, lng: 21.525817 },
    { lat: 49.14641, lng: 21.526485 },
    { lat: 49.146318, lng: 21.527344 },
    { lat: 49.146491, lng: 21.527562 },
    { lat: 49.146274, lng: 21.528517 },
    { lat: 49.146269, lng: 21.528746 },
    { lat: 49.146134, lng: 21.528825 },
    { lat: 49.146036, lng: 21.529051 },
    { lat: 49.146044, lng: 21.52918 },
    { lat: 49.145588, lng: 21.529071 },
    { lat: 49.14524, lng: 21.529006 },
    { lat: 49.14496, lng: 21.529163 },
    { lat: 49.143873, lng: 21.529284 },
    { lat: 49.143529, lng: 21.529253 },
    { lat: 49.143171, lng: 21.529018 },
    { lat: 49.1427568, lng: 21.5290761 },
    { lat: 49.1420269, lng: 21.5295182 },
    { lat: 49.1412293, lng: 21.5295653 },
    { lat: 49.1409392, lng: 21.5294104 },
    { lat: 49.1403358, lng: 21.5306642 },
    { lat: 49.1398251, lng: 21.5303974 },
    { lat: 49.139783, lng: 21.5306186 },
    { lat: 49.1393992, lng: 21.531642 },
    { lat: 49.1391297, lng: 21.5319845 },
    { lat: 49.1384911, lng: 21.5325931 },
    { lat: 49.1381759, lng: 21.5325847 },
    { lat: 49.1380153, lng: 21.5335919 },
    { lat: 49.1378537, lng: 21.5341663 },
    { lat: 49.137693, lng: 21.5345261 },
    { lat: 49.13746, lng: 21.5345658 },
    { lat: 49.1372384, lng: 21.5345229 },
    { lat: 49.1370941, lng: 21.5348751 },
    { lat: 49.137045, lng: 21.535195 },
    { lat: 49.136856, lng: 21.536229 },
    { lat: 49.136871, lng: 21.53629 },
    { lat: 49.136465, lng: 21.536629 },
    { lat: 49.136429, lng: 21.536742 },
    { lat: 49.136021, lng: 21.536793 },
    { lat: 49.135827, lng: 21.536714 },
    { lat: 49.135406, lng: 21.536623 },
    { lat: 49.135177, lng: 21.536451 },
    { lat: 49.134975, lng: 21.536404 },
    { lat: 49.134635, lng: 21.536481 },
    { lat: 49.134113, lng: 21.536307 },
    { lat: 49.133903, lng: 21.536315 },
    { lat: 49.133859, lng: 21.536351 },
    { lat: 49.133749, lng: 21.536384 },
    { lat: 49.1337329, lng: 21.5369584 },
    { lat: 49.1336831, lng: 21.5372315 },
    { lat: 49.1335545, lng: 21.5374523 },
    { lat: 49.1334982, lng: 21.5379306 },
    { lat: 49.1333733, lng: 21.5380914 },
    { lat: 49.1329044, lng: 21.5392115 },
    { lat: 49.1325605, lng: 21.5397905 },
    { lat: 49.1324239, lng: 21.5399281 },
    { lat: 49.1324627, lng: 21.5404249 },
    { lat: 49.1324585, lng: 21.5414742 },
    { lat: 49.1325045, lng: 21.5418671 },
    { lat: 49.1323549, lng: 21.542589 },
    { lat: 49.1321666, lng: 21.5426922 },
    { lat: 49.1321408, lng: 21.5429731 },
    { lat: 49.1323054, lng: 21.5430661 },
    { lat: 49.1324147, lng: 21.5432314 },
    { lat: 49.1324707, lng: 21.543545 },
    { lat: 49.1321227, lng: 21.5436533 },
    { lat: 49.1314911, lng: 21.5436763 },
    { lat: 49.1314159, lng: 21.5446835 },
    { lat: 49.131492, lng: 21.5453845 },
    { lat: 49.1314881, lng: 21.5457224 },
    { lat: 49.1315416, lng: 21.5458648 },
    { lat: 49.1317219, lng: 21.5459978 },
    { lat: 49.1320579, lng: 21.5461055 },
    { lat: 49.1321636, lng: 21.5468229 },
    { lat: 49.1323092, lng: 21.5472359 },
    { lat: 49.1323054, lng: 21.5474215 },
    { lat: 49.1334987, lng: 21.5483322 },
    { lat: 49.1335306, lng: 21.5483406 },
    { lat: 49.1335716, lng: 21.5483573 },
    { lat: 49.1337171, lng: 21.5486521 },
    { lat: 49.1337762, lng: 21.5493952 },
    { lat: 49.1338585, lng: 21.5494828 },
    { lat: 49.1342698, lng: 21.5493863 },
    { lat: 49.1343704, lng: 21.5492414 },
    { lat: 49.1344491, lng: 21.5489963 },
    { lat: 49.1344309, lng: 21.548793 },
    { lat: 49.1345334, lng: 21.548717 },
    { lat: 49.1347424, lng: 21.548989 },
    { lat: 49.1348626, lng: 21.54898 },
    { lat: 49.1349771, lng: 21.5487336 },
    { lat: 49.1351226, lng: 21.5485829 },
    { lat: 49.1354338, lng: 21.5484922 },
    { lat: 49.1356575, lng: 21.5479634 },
    { lat: 49.1355263, lng: 21.5476594 },
    { lat: 49.1358038, lng: 21.5472471 },
    { lat: 49.1361404, lng: 21.5478173 },
    { lat: 49.136426, lng: 21.548012 },
    { lat: 49.1367619, lng: 21.5485463 },
    { lat: 49.1369603, lng: 21.548603 },
    { lat: 49.1372154, lng: 21.5484344 },
    { lat: 49.137697, lng: 21.5486357 },
    { lat: 49.137691, lng: 21.5478277 },
    { lat: 49.1378678, lng: 21.5476677 },
    { lat: 49.1378809, lng: 21.5475372 },
    { lat: 49.138185, lng: 21.5477473 },
    { lat: 49.1389887, lng: 21.5477228 },
    { lat: 49.1389869, lng: 21.5483776 },
    { lat: 49.1391311, lng: 21.5489459 },
    { lat: 49.1391326, lng: 21.549243 },
    { lat: 49.1393018, lng: 21.5493005 },
    { lat: 49.1395668, lng: 21.5496009 },
    { lat: 49.139814, lng: 21.549588 },
    { lat: 49.1404083, lng: 21.5497554 },
    { lat: 49.1408613, lng: 21.550019 },
    { lat: 49.1413524, lng: 21.5501637 },
    { lat: 49.1418814, lng: 21.5501727 },
    { lat: 49.1425777, lng: 21.5500108 },
    { lat: 49.1426457, lng: 21.5496693 },
    { lat: 49.1428879, lng: 21.5497983 },
    { lat: 49.1428537, lng: 21.5491839 },
    { lat: 49.1433285, lng: 21.548984 },
    { lat: 49.143324, lng: 21.5492599 },
    { lat: 49.1433983, lng: 21.5493442 },
    { lat: 49.1435775, lng: 21.5494809 },
    { lat: 49.1437674, lng: 21.5494275 },
    { lat: 49.1440522, lng: 21.5491581 },
    { lat: 49.1439434, lng: 21.5487692 },
    { lat: 49.144505, lng: 21.5483742 },
    { lat: 49.1447127, lng: 21.5486352 },
    { lat: 49.1451108, lng: 21.5487098 },
    { lat: 49.1451387, lng: 21.5482674 },
    { lat: 49.1450676, lng: 21.5479371 },
    { lat: 49.1452032, lng: 21.5473181 },
    { lat: 49.1453865, lng: 21.5471954 },
    { lat: 49.1457323, lng: 21.5467678 },
    { lat: 49.1459037, lng: 21.5469007 },
    { lat: 49.1459371, lng: 21.5470484 },
    { lat: 49.1460511, lng: 21.5470215 },
    { lat: 49.1461596, lng: 21.5474417 },
    { lat: 49.1462845, lng: 21.5474411 },
    { lat: 49.1464059, lng: 21.5473552 },
    { lat: 49.1464697, lng: 21.5466305 },
    { lat: 49.1467457, lng: 21.5464353 },
    { lat: 49.1466995, lng: 21.5461623 },
    { lat: 49.1468006, lng: 21.5462782 },
    { lat: 49.1475548, lng: 21.5462864 },
    { lat: 49.1475856, lng: 21.5468195 },
    { lat: 49.1479148, lng: 21.54689 },
    { lat: 49.1486777, lng: 21.5466151 },
    { lat: 49.1486928, lng: 21.5466745 },
    { lat: 49.1488901, lng: 21.5464478 },
    { lat: 49.1491261, lng: 21.5463975 },
    { lat: 49.1496499, lng: 21.546419 },
    { lat: 49.1496741, lng: 21.5461989 },
    { lat: 49.1503945, lng: 21.5460127 },
    { lat: 49.1506206, lng: 21.5462894 },
    { lat: 49.150828, lng: 21.5461867 },
    { lat: 49.1510822, lng: 21.5462243 },
    { lat: 49.1511042, lng: 21.5465693 },
    { lat: 49.1516105, lng: 21.546685 },
    { lat: 49.1515699, lng: 21.5470417 },
    { lat: 49.1518092, lng: 21.5471943 },
    { lat: 49.1519902, lng: 21.5469919 },
    { lat: 49.1519985, lng: 21.5468404 },
    { lat: 49.1521188, lng: 21.5465587 },
    { lat: 49.1522387, lng: 21.5465404 },
    { lat: 49.1522453, lng: 21.5467391 },
    { lat: 49.1527363, lng: 21.5469561 },
    { lat: 49.1527918, lng: 21.5471688 },
    { lat: 49.1529859, lng: 21.5473071 },
    { lat: 49.1533534, lng: 21.5473701 },
    { lat: 49.153783, lng: 21.5472895 },
    { lat: 49.1540151, lng: 21.5473444 },
    { lat: 49.1542121, lng: 21.5472255 },
    { lat: 49.1542897, lng: 21.5471094 },
    { lat: 49.1545279, lng: 21.5463307 },
    { lat: 49.1542251, lng: 21.5456518 },
    { lat: 49.1544723, lng: 21.5452349 },
    { lat: 49.1549363, lng: 21.5446927 },
    { lat: 49.1550905, lng: 21.5446183 },
    { lat: 49.1551667, lng: 21.5446888 },
    { lat: 49.1552778, lng: 21.5451161 },
    { lat: 49.1555382, lng: 21.5449391 },
    { lat: 49.155472, lng: 21.5444625 },
    { lat: 49.1558712, lng: 21.5442958 },
    { lat: 49.1561568, lng: 21.5446955 },
    { lat: 49.1565154, lng: 21.5446796 },
    { lat: 49.1567042, lng: 21.5444333 },
    { lat: 49.1566548, lng: 21.5443201 },
    { lat: 49.1567464, lng: 21.5437727 },
    { lat: 49.157266, lng: 21.5436072 },
    { lat: 49.1574388, lng: 21.543832 },
    { lat: 49.1574928, lng: 21.5440744 },
    { lat: 49.1577902, lng: 21.5445667 },
    { lat: 49.1579223, lng: 21.544543 },
    { lat: 49.1581362, lng: 21.5443126 },
    { lat: 49.1582518, lng: 21.5442961 },
    { lat: 49.1584826, lng: 21.5445419 },
    { lat: 49.1586543, lng: 21.5445663 },
    { lat: 49.1589711, lng: 21.544433 },
    { lat: 49.159064, lng: 21.5444909 },
    { lat: 49.1596009, lng: 21.5431887 },
  ],
  Kobylnice: [
    { lat: 49.1063949, lng: 21.5307761 },
    { lat: 49.1064012, lng: 21.530799 },
    { lat: 49.1066009, lng: 21.5313751 },
    { lat: 49.1065086, lng: 21.5314943 },
    { lat: 49.1062786, lng: 21.5315586 },
    { lat: 49.1061857, lng: 21.5317331 },
    { lat: 49.106345, lng: 21.532071 },
    { lat: 49.1064535, lng: 21.5331634 },
    { lat: 49.106448, lng: 21.5337616 },
    { lat: 49.1063361, lng: 21.5341492 },
    { lat: 49.106383, lng: 21.5347727 },
    { lat: 49.1063591, lng: 21.5349951 },
    { lat: 49.1060723, lng: 21.5359163 },
    { lat: 49.1059913, lng: 21.5364703 },
    { lat: 49.1058682, lng: 21.5367365 },
    { lat: 49.1055235, lng: 21.5371218 },
    { lat: 49.1053801, lng: 21.5373771 },
    { lat: 49.1052616, lng: 21.5378901 },
    { lat: 49.1050424, lng: 21.537893 },
    { lat: 49.1045971, lng: 21.538101 },
    { lat: 49.1041655, lng: 21.5381522 },
    { lat: 49.1035745, lng: 21.53802 },
    { lat: 49.1033426, lng: 21.5375806 },
    { lat: 49.1029296, lng: 21.5371746 },
    { lat: 49.1025593, lng: 21.5366524 },
    { lat: 49.1019384, lng: 21.5362626 },
    { lat: 49.1013853, lng: 21.5361182 },
    { lat: 49.1010146, lng: 21.5360987 },
    { lat: 49.1002925, lng: 21.536183 },
    { lat: 49.0996802, lng: 21.5360569 },
    { lat: 49.0988851, lng: 21.5357852 },
    { lat: 49.0983753, lng: 21.5353806 },
    { lat: 49.097326, lng: 21.5348404 },
    { lat: 49.0968656, lng: 21.5346739 },
    { lat: 49.0968764, lng: 21.5343411 },
    { lat: 49.0956354, lng: 21.5340164 },
    { lat: 49.0956354, lng: 21.5338997 },
    { lat: 49.0952933, lng: 21.5338568 },
    { lat: 49.0952962, lng: 21.5336003 },
    { lat: 49.0947494, lng: 21.5335561 },
    { lat: 49.0946857, lng: 21.5336547 },
    { lat: 49.0939288, lng: 21.5337004 },
    { lat: 49.0935853, lng: 21.5336493 },
    { lat: 49.0932679, lng: 21.5334457 },
    { lat: 49.0923465, lng: 21.5330513 },
    { lat: 49.0920685, lng: 21.5327253 },
    { lat: 49.0913057, lng: 21.5322135 },
    { lat: 49.0910018, lng: 21.5322464 },
    { lat: 49.0898003, lng: 21.5327667 },
    { lat: 49.0897078, lng: 21.5323973 },
    { lat: 49.0891602, lng: 21.5325254 },
    { lat: 49.0888123, lng: 21.5318458 },
    { lat: 49.088058, lng: 21.5309487 },
    { lat: 49.0876376, lng: 21.5308503 },
    { lat: 49.0869514, lng: 21.5311678 },
    { lat: 49.0864016, lng: 21.5308582 },
    { lat: 49.0854515, lng: 21.530086 },
    { lat: 49.0853691, lng: 21.5303541 },
    { lat: 49.0850273, lng: 21.5308399 },
    { lat: 49.0848991, lng: 21.5307238 },
    { lat: 49.0847107, lng: 21.5306647 },
    { lat: 49.0843057, lng: 21.5312584 },
    { lat: 49.0846139, lng: 21.5321341 },
    { lat: 49.0845897, lng: 21.5326705 },
    { lat: 49.0837851, lng: 21.5332652 },
    { lat: 49.0836967, lng: 21.533472 },
    { lat: 49.0829134, lng: 21.533743 },
    { lat: 49.0825745, lng: 21.5336974 },
    { lat: 49.081607, lng: 21.5341732 },
    { lat: 49.081061, lng: 21.5341659 },
    { lat: 49.0804894, lng: 21.5346366 },
    { lat: 49.080354, lng: 21.534859 },
    { lat: 49.0804878, lng: 21.5350566 },
    { lat: 49.0807342, lng: 21.5357313 },
    { lat: 49.0807358, lng: 21.5361486 },
    { lat: 49.0805906, lng: 21.5366225 },
    { lat: 49.0802736, lng: 21.5380301 },
    { lat: 49.0802645, lng: 21.538541 },
    { lat: 49.0801434, lng: 21.5387621 },
    { lat: 49.0799134, lng: 21.5394164 },
    { lat: 49.0796414, lng: 21.5398688 },
    { lat: 49.0793628, lng: 21.5400812 },
    { lat: 49.0789686, lng: 21.5402642 },
    { lat: 49.0787161, lng: 21.5418422 },
    { lat: 49.077932, lng: 21.5435536 },
    { lat: 49.0777405, lng: 21.5437104 },
    { lat: 49.0775607, lng: 21.5440739 },
    { lat: 49.0773975, lng: 21.544274 },
    { lat: 49.0763233, lng: 21.5454729 },
    { lat: 49.0759003, lng: 21.5461974 },
    { lat: 49.0754421, lng: 21.5466644 },
    { lat: 49.0751101, lng: 21.5472197 },
    { lat: 49.075016, lng: 21.547572 },
    { lat: 49.075021, lng: 21.547587 },
    { lat: 49.075314, lng: 21.548435 },
    { lat: 49.075459, lng: 21.548964 },
    { lat: 49.074944, lng: 21.54925 },
    { lat: 49.074807, lng: 21.549376 },
    { lat: 49.074572, lng: 21.549537 },
    { lat: 49.074511, lng: 21.549646 },
    { lat: 49.074506, lng: 21.549913 },
    { lat: 49.074448, lng: 21.550159 },
    { lat: 49.074563, lng: 21.550335 },
    { lat: 49.074484, lng: 21.550444 },
    { lat: 49.074281, lng: 21.550601 },
    { lat: 49.074262, lng: 21.550688 },
    { lat: 49.074609, lng: 21.552334 },
    { lat: 49.07466, lng: 21.553055 },
    { lat: 49.074618, lng: 21.55315 },
    { lat: 49.074641, lng: 21.553331 },
    { lat: 49.074751, lng: 21.553516 },
    { lat: 49.075054, lng: 21.55379 },
    { lat: 49.075095, lng: 21.553882 },
    { lat: 49.07523, lng: 21.554075 },
    { lat: 49.075411, lng: 21.554158 },
    { lat: 49.075628, lng: 21.554562 },
    { lat: 49.075723, lng: 21.554855 },
    { lat: 49.076172, lng: 21.554781 },
    { lat: 49.076461, lng: 21.554547 },
    { lat: 49.076841, lng: 21.554412 },
    { lat: 49.077024, lng: 21.554354 },
    { lat: 49.077474, lng: 21.554305 },
    { lat: 49.07766, lng: 21.554351 },
    { lat: 49.077842, lng: 21.554227 },
    { lat: 49.07801, lng: 21.554256 },
    { lat: 49.078183, lng: 21.554033 },
    { lat: 49.078476, lng: 21.554012 },
    { lat: 49.078877, lng: 21.553916 },
    { lat: 49.078949, lng: 21.553853 },
    { lat: 49.07931, lng: 21.553701 },
    { lat: 49.079369, lng: 21.55359 },
    { lat: 49.079583, lng: 21.553589 },
    { lat: 49.079601, lng: 21.554037 },
    { lat: 49.079425, lng: 21.554345 },
    { lat: 49.079224, lng: 21.555 },
    { lat: 49.079112, lng: 21.555271 },
    { lat: 49.07905, lng: 21.555338 },
    { lat: 49.078799, lng: 21.55593 },
    { lat: 49.078355, lng: 21.556596 },
    { lat: 49.078303, lng: 21.556836 },
    { lat: 49.078287, lng: 21.556999 },
    { lat: 49.078196, lng: 21.557389 },
    { lat: 49.078066, lng: 21.557362 },
    { lat: 49.077746, lng: 21.557872 },
    { lat: 49.077701, lng: 21.558059 },
    { lat: 49.07915, lng: 21.558138 },
    { lat: 49.079179, lng: 21.55866 },
    { lat: 49.079153, lng: 21.559136 },
    { lat: 49.0792, lng: 21.559968 },
    { lat: 49.079462, lng: 21.559946 },
    { lat: 49.079797, lng: 21.560027 },
    { lat: 49.080196, lng: 21.560207 },
    { lat: 49.080359, lng: 21.561222 },
    { lat: 49.081118, lng: 21.561299 },
    { lat: 49.081392, lng: 21.561313 },
    { lat: 49.081794, lng: 21.56223 },
    { lat: 49.081291, lng: 21.562589 },
    { lat: 49.080629, lng: 21.563966 },
    { lat: 49.081393, lng: 21.564511 },
    { lat: 49.082099, lng: 21.564883 },
    { lat: 49.082668, lng: 21.565523 },
    { lat: 49.08301, lng: 21.5656 },
    { lat: 49.083197, lng: 21.566105 },
    { lat: 49.083334, lng: 21.566269 },
    { lat: 49.084281, lng: 21.566075 },
    { lat: 49.084559, lng: 21.566799 },
    { lat: 49.084764, lng: 21.567573 },
    { lat: 49.085097, lng: 21.567436 },
    { lat: 49.085236, lng: 21.567308 },
    { lat: 49.085587, lng: 21.566905 },
    { lat: 49.085718, lng: 21.567075 },
    { lat: 49.085956, lng: 21.567368 },
    { lat: 49.085971, lng: 21.567391 },
    { lat: 49.086197, lng: 21.566699 },
    { lat: 49.086287, lng: 21.566462 },
    { lat: 49.08675, lng: 21.565781 },
    { lat: 49.086946, lng: 21.565613 },
    { lat: 49.087073, lng: 21.565302 },
    { lat: 49.087333, lng: 21.565306 },
    { lat: 49.087481, lng: 21.565089 },
    { lat: 49.087618, lng: 21.565187 },
    { lat: 49.088089, lng: 21.565247 },
    { lat: 49.088245, lng: 21.565341 },
    { lat: 49.088538, lng: 21.565294 },
    { lat: 49.088648, lng: 21.565528 },
    { lat: 49.089146, lng: 21.56559 },
    { lat: 49.089499, lng: 21.565588 },
    { lat: 49.089647, lng: 21.56574 },
    { lat: 49.090034, lng: 21.565567 },
    { lat: 49.090244, lng: 21.565678 },
    { lat: 49.090567, lng: 21.565635 },
    { lat: 49.090869, lng: 21.565727 },
    { lat: 49.091433, lng: 21.565553 },
    { lat: 49.091746, lng: 21.565712 },
    { lat: 49.091883, lng: 21.565574 },
    { lat: 49.092063, lng: 21.565653 },
    { lat: 49.092712, lng: 21.565478 },
    { lat: 49.092876, lng: 21.56527 },
    { lat: 49.093139, lng: 21.565213 },
    { lat: 49.093389, lng: 21.565298 },
    { lat: 49.093611, lng: 21.565121 },
    { lat: 49.094115, lng: 21.56563 },
    { lat: 49.094265, lng: 21.565723 },
    { lat: 49.094325, lng: 21.565836 },
    { lat: 49.094529, lng: 21.566053 },
    { lat: 49.095186, lng: 21.566528 },
    { lat: 49.095737, lng: 21.566747 },
    { lat: 49.095906, lng: 21.566805 },
    { lat: 49.096099, lng: 21.566925 },
    { lat: 49.096433, lng: 21.566976 },
    { lat: 49.096525, lng: 21.56713 },
    { lat: 49.097352, lng: 21.567812 },
    { lat: 49.097475, lng: 21.568091 },
    { lat: 49.097736, lng: 21.568362 },
    { lat: 49.097965, lng: 21.568732 },
    { lat: 49.098368, lng: 21.568955 },
    { lat: 49.098716, lng: 21.568965 },
    { lat: 49.099028, lng: 21.569095 },
    { lat: 49.099157, lng: 21.569234 },
    { lat: 49.099631, lng: 21.569263 },
    { lat: 49.100129, lng: 21.569278 },
    { lat: 49.100287, lng: 21.569271 },
    { lat: 49.100425, lng: 21.569331 },
    { lat: 49.100691, lng: 21.569276 },
    { lat: 49.101, lng: 21.569075 },
    { lat: 49.101246, lng: 21.569018 },
    { lat: 49.101465, lng: 21.569087 },
    { lat: 49.101837, lng: 21.56898 },
    { lat: 49.102158, lng: 21.569029 },
    { lat: 49.102539, lng: 21.568943 },
    { lat: 49.102599, lng: 21.56898 },
    { lat: 49.103575, lng: 21.568538 },
    { lat: 49.104138, lng: 21.568215 },
    { lat: 49.104226, lng: 21.568149 },
    { lat: 49.10422, lng: 21.568129 },
    { lat: 49.104526, lng: 21.567905 },
    { lat: 49.104827, lng: 21.567706 },
    { lat: 49.105088, lng: 21.567571 },
    { lat: 49.106186, lng: 21.566829 },
    { lat: 49.106443, lng: 21.566697 },
    { lat: 49.1068, lng: 21.566434 },
    { lat: 49.106803, lng: 21.566452 },
    { lat: 49.107122, lng: 21.566252 },
    { lat: 49.107363, lng: 21.566134 },
    { lat: 49.107778, lng: 21.565967 },
    { lat: 49.108038, lng: 21.566642 },
    { lat: 49.107877, lng: 21.566836 },
    { lat: 49.108078, lng: 21.567145 },
    { lat: 49.108063, lng: 21.567277 },
    { lat: 49.108224, lng: 21.567599 },
    { lat: 49.108352, lng: 21.567722 },
    { lat: 49.108499, lng: 21.567929 },
    { lat: 49.108617, lng: 21.568286 },
    { lat: 49.108714, lng: 21.568535 },
    { lat: 49.108896, lng: 21.568807 },
    { lat: 49.109163, lng: 21.569279 },
    { lat: 49.109225, lng: 21.569594 },
    { lat: 49.10927, lng: 21.56987 },
    { lat: 49.109332, lng: 21.570155 },
    { lat: 49.109461, lng: 21.570173 },
    { lat: 49.109577, lng: 21.570086 },
    { lat: 49.109834, lng: 21.569716 },
    { lat: 49.109997, lng: 21.569576 },
    { lat: 49.11029, lng: 21.56908 },
    { lat: 49.110397, lng: 21.56886 },
    { lat: 49.110599, lng: 21.568607 },
    { lat: 49.111057, lng: 21.567837 },
    { lat: 49.111165, lng: 21.567608 },
    { lat: 49.111205, lng: 21.567568 },
    { lat: 49.111273, lng: 21.56751 },
    { lat: 49.111974, lng: 21.566732 },
    { lat: 49.112319, lng: 21.566517 },
    { lat: 49.112494, lng: 21.566357 },
    { lat: 49.113103, lng: 21.566232 },
    { lat: 49.113263, lng: 21.566163 },
    { lat: 49.113514, lng: 21.565777 },
    { lat: 49.113723, lng: 21.565103 },
    { lat: 49.113747, lng: 21.564712 },
    { lat: 49.113747, lng: 21.563961 },
    { lat: 49.113779, lng: 21.563308 },
    { lat: 49.113817, lng: 21.562925 },
    { lat: 49.113879, lng: 21.562434 },
    { lat: 49.114157, lng: 21.561638 },
    { lat: 49.114083, lng: 21.561293 },
    { lat: 49.114137, lng: 21.56089 },
    { lat: 49.11423, lng: 21.56055 },
    { lat: 49.114329, lng: 21.560141 },
    { lat: 49.114405, lng: 21.559933 },
    { lat: 49.114616, lng: 21.559498 },
    { lat: 49.114827, lng: 21.559174 },
    { lat: 49.114899, lng: 21.558903 },
    { lat: 49.115102, lng: 21.558583 },
    { lat: 49.115316, lng: 21.558445 },
    { lat: 49.115478, lng: 21.558234 },
    { lat: 49.116197, lng: 21.557543 },
    { lat: 49.11676, lng: 21.557323 },
    { lat: 49.11703, lng: 21.556955 },
    { lat: 49.117271, lng: 21.556687 },
    { lat: 49.117536, lng: 21.55651 },
    { lat: 49.117642, lng: 21.556439 },
    { lat: 49.117727, lng: 21.556261 },
    { lat: 49.118275, lng: 21.556328 },
    { lat: 49.118697, lng: 21.556439 },
    { lat: 49.120995, lng: 21.556444 },
    { lat: 49.121011, lng: 21.556449 },
    { lat: 49.1210327, lng: 21.5560754 },
    { lat: 49.1209624, lng: 21.5553329 },
    { lat: 49.120811, lng: 21.5547127 },
    { lat: 49.1207985, lng: 21.5541493 },
    { lat: 49.1207406, lng: 21.5538826 },
    { lat: 49.1206587, lng: 21.5528133 },
    { lat: 49.1209166, lng: 21.5521014 },
    { lat: 49.1209384, lng: 21.5514768 },
    { lat: 49.1211891, lng: 21.5499551 },
    { lat: 49.121188, lng: 21.549628 },
    { lat: 49.1211362, lng: 21.5495074 },
    { lat: 49.1211397, lng: 21.5485169 },
    { lat: 49.1212711, lng: 21.546743 },
    { lat: 49.12139, lng: 21.5462528 },
    { lat: 49.1214984, lng: 21.5451538 },
    { lat: 49.1216147, lng: 21.5447626 },
    { lat: 49.1218887, lng: 21.5433926 },
    { lat: 49.121852, lng: 21.5429708 },
    { lat: 49.1216361, lng: 21.5427709 },
    { lat: 49.1213462, lng: 21.54238 },
    { lat: 49.120762, lng: 21.541419 },
    { lat: 49.1206334, lng: 21.541046 },
    { lat: 49.1198656, lng: 21.5399544 },
    { lat: 49.1193349, lng: 21.538723 },
    { lat: 49.1191413, lng: 21.538522 },
    { lat: 49.1183139, lng: 21.5380227 },
    { lat: 49.1179551, lng: 21.5376756 },
    { lat: 49.1168253, lng: 21.5376648 },
    { lat: 49.1163003, lng: 21.5378389 },
    { lat: 49.115619, lng: 21.5384489 },
    { lat: 49.1152532, lng: 21.5386441 },
    { lat: 49.1149063, lng: 21.538993 },
    { lat: 49.1143305, lng: 21.5393553 },
    { lat: 49.1138608, lng: 21.539454 },
    { lat: 49.1126134, lng: 21.5395037 },
    { lat: 49.1122444, lng: 21.539627 },
    { lat: 49.1114855, lng: 21.5396436 },
    { lat: 49.1113096, lng: 21.5389918 },
    { lat: 49.1110465, lng: 21.5385964 },
    { lat: 49.1103153, lng: 21.5381272 },
    { lat: 49.1100738, lng: 21.5376956 },
    { lat: 49.1098332, lng: 21.5370247 },
    { lat: 49.1097185, lng: 21.5369101 },
    { lat: 49.1093196, lng: 21.5362522 },
    { lat: 49.1091171, lng: 21.5358115 },
    { lat: 49.1087887, lng: 21.535419 },
    { lat: 49.1081745, lng: 21.5345055 },
    { lat: 49.1081295, lng: 21.5340664 },
    { lat: 49.1079323, lng: 21.5333037 },
    { lat: 49.1077702, lng: 21.5327807 },
    { lat: 49.1075349, lng: 21.53238 },
    { lat: 49.107463, lng: 21.532105 },
    { lat: 49.1072397, lng: 21.5316866 },
    { lat: 49.1064132, lng: 21.5307828 },
    { lat: 49.1063949, lng: 21.5307761 },
  ],
  Mestisko: [
    { lat: 49.2809821, lng: 21.5760243 },
    { lat: 49.2802871, lng: 21.5761887 },
    { lat: 49.2799397, lng: 21.575793 },
    { lat: 49.2796441, lng: 21.5750407 },
    { lat: 49.279494, lng: 21.5743317 },
    { lat: 49.2792256, lng: 21.5738584 },
    { lat: 49.279031, lng: 21.5732203 },
    { lat: 49.278581, lng: 21.5722465 },
    { lat: 49.2785414, lng: 21.571994 },
    { lat: 49.2773472, lng: 21.5699106 },
    { lat: 49.2767204, lng: 21.5688983 },
    { lat: 49.2759922, lng: 21.567957 },
    { lat: 49.2747184, lng: 21.5669401 },
    { lat: 49.2743907, lng: 21.5664376 },
    { lat: 49.2738611, lng: 21.5658859 },
    { lat: 49.2735882, lng: 21.5652941 },
    { lat: 49.2735905, lng: 21.5645345 },
    { lat: 49.2731693, lng: 21.5637067 },
    { lat: 49.2727802, lng: 21.5626646 },
    { lat: 49.272556, lng: 21.5618099 },
    { lat: 49.2724387, lng: 21.561114 },
    { lat: 49.272453, lng: 21.5602924 },
    { lat: 49.2722012, lng: 21.55938 },
    { lat: 49.2715373, lng: 21.557483 },
    { lat: 49.2704688, lng: 21.5546701 },
    { lat: 49.2701056, lng: 21.5540339 },
    { lat: 49.2691028, lng: 21.5527723 },
    { lat: 49.2689312, lng: 21.5520735 },
    { lat: 49.2688194, lng: 21.5511291 },
    { lat: 49.2684913, lng: 21.5498191 },
    { lat: 49.2678145, lng: 21.5481152 },
    { lat: 49.2674071, lng: 21.547464 },
    { lat: 49.2672976, lng: 21.5473703 },
    { lat: 49.2667774, lng: 21.547641 },
    { lat: 49.2659994, lng: 21.5477723 },
    { lat: 49.2657185, lng: 21.5479107 },
    { lat: 49.265528, lng: 21.5479776 },
    { lat: 49.2649627, lng: 21.5488341 },
    { lat: 49.2635523, lng: 21.5492441 },
    { lat: 49.2621855, lng: 21.5506501 },
    { lat: 49.2612887, lng: 21.5519324 },
    { lat: 49.2606323, lng: 21.5522339 },
    { lat: 49.2610125, lng: 21.5533155 },
    { lat: 49.260979, lng: 21.5542819 },
    { lat: 49.2606039, lng: 21.555477 },
    { lat: 49.2600805, lng: 21.5563068 },
    { lat: 49.2598572, lng: 21.5568113 },
    { lat: 49.2586178, lng: 21.5580292 },
    { lat: 49.2572484, lng: 21.5585742 },
    { lat: 49.2569992, lng: 21.5585567 },
    { lat: 49.2564598, lng: 21.5591054 },
    { lat: 49.2554966, lng: 21.5594179 },
    { lat: 49.2550556, lng: 21.5594524 },
    { lat: 49.2550407, lng: 21.5594437 },
    { lat: 49.2548277, lng: 21.5600799 },
    { lat: 49.2545145, lng: 21.5613042 },
    { lat: 49.2543154, lng: 21.5617904 },
    { lat: 49.2542608, lng: 21.5628553 },
    { lat: 49.254105, lng: 21.5633221 },
    { lat: 49.2534558, lng: 21.564766 },
    { lat: 49.2533126, lng: 21.5656389 },
    { lat: 49.253223, lng: 21.5658569 },
    { lat: 49.2528993, lng: 21.5663119 },
    { lat: 49.2523105, lng: 21.5669398 },
    { lat: 49.2523752, lng: 21.5674422 },
    { lat: 49.2523459, lng: 21.5678882 },
    { lat: 49.2521245, lng: 21.5684622 },
    { lat: 49.2520844, lng: 21.5689592 },
    { lat: 49.252126, lng: 21.5691146 },
    { lat: 49.2520056, lng: 21.5695989 },
    { lat: 49.2520865, lng: 21.5696765 },
    { lat: 49.251917, lng: 21.5701315 },
    { lat: 49.2516446, lng: 21.5711334 },
    { lat: 49.2512373, lng: 21.5719827 },
    { lat: 49.2510778, lng: 21.5724339 },
    { lat: 49.2506377, lng: 21.5731971 },
    { lat: 49.2503035, lng: 21.574037 },
    { lat: 49.2496401, lng: 21.575349 },
    { lat: 49.2491328, lng: 21.5768036 },
    { lat: 49.2488793, lng: 21.5773173 },
    { lat: 49.2485635, lng: 21.5778142 },
    { lat: 49.2477064, lng: 21.5789098 },
    { lat: 49.2467885, lng: 21.5797157 },
    { lat: 49.2446851, lng: 21.5821601 },
    { lat: 49.2442427, lng: 21.5830788 },
    { lat: 49.2440394, lng: 21.5833736 },
    { lat: 49.2432297, lng: 21.5841904 },
    { lat: 49.2431617, lng: 21.5843834 },
    { lat: 49.2424566, lng: 21.5853987 },
    { lat: 49.241901, lng: 21.5864615 },
    { lat: 49.2416727, lng: 21.5867477 },
    { lat: 49.2414646, lng: 21.5871918 },
    { lat: 49.2409495, lng: 21.5878026 },
    { lat: 49.240142, lng: 21.5890757 },
    { lat: 49.2399379, lng: 21.589253 },
    { lat: 49.2396016, lng: 21.5893763 },
    { lat: 49.2393687, lng: 21.589666 },
    { lat: 49.2392016, lng: 21.590128 },
    { lat: 49.2389467, lng: 21.5902679 },
    { lat: 49.2387985, lng: 21.5905605 },
    { lat: 49.2381372, lng: 21.5909772 },
    { lat: 49.2377679, lng: 21.5908837 },
    { lat: 49.2374014, lng: 21.5910511 },
    { lat: 49.2371616, lng: 21.5912934 },
    { lat: 49.2367556, lng: 21.5915381 },
    { lat: 49.2365512, lng: 21.5915891 },
    { lat: 49.2364652, lng: 21.5914107 },
    { lat: 49.2362924, lng: 21.591428 },
    { lat: 49.2360639, lng: 21.5916224 },
    { lat: 49.2354642, lng: 21.5918674 },
    { lat: 49.2353671, lng: 21.5920133 },
    { lat: 49.2348513, lng: 21.5923955 },
    { lat: 49.2345356, lng: 21.5924551 },
    { lat: 49.2342426, lng: 21.5926004 },
    { lat: 49.2339522, lng: 21.5929136 },
    { lat: 49.2336711, lng: 21.5930908 },
    { lat: 49.2335343, lng: 21.5931033 },
    { lat: 49.2327878, lng: 21.5937285 },
    { lat: 49.2317234, lng: 21.5939639 },
    { lat: 49.231652, lng: 21.594112 },
    { lat: 49.232049, lng: 21.594425 },
    { lat: 49.233014, lng: 21.594859 },
    { lat: 49.233365, lng: 21.594928 },
    { lat: 49.234301, lng: 21.595437 },
    { lat: 49.234865, lng: 21.595733 },
    { lat: 49.235461, lng: 21.596054 },
    { lat: 49.237398, lng: 21.598095 },
    { lat: 49.237406, lng: 21.598104 },
    { lat: 49.237515, lng: 21.598228 },
    { lat: 49.238807, lng: 21.599702 },
    { lat: 49.240039, lng: 21.60098 },
    { lat: 49.241979, lng: 21.603286 },
    { lat: 49.24244, lng: 21.60366 },
    { lat: 49.242905, lng: 21.604038 },
    { lat: 49.242845, lng: 21.604438 },
    { lat: 49.242555, lng: 21.605613 },
    { lat: 49.242544, lng: 21.607849 },
    { lat: 49.242336, lng: 21.609503 },
    { lat: 49.243956, lng: 21.610005 },
    { lat: 49.244206, lng: 21.609994 },
    { lat: 49.244376, lng: 21.609987 },
    { lat: 49.244808, lng: 21.6099914 },
    { lat: 49.2449901, lng: 21.6093727 },
    { lat: 49.2463713, lng: 21.6065492 },
    { lat: 49.2470049, lng: 21.6055745 },
    { lat: 49.247663, lng: 21.6047598 },
    { lat: 49.2493981, lng: 21.6051846 },
    { lat: 49.2496814, lng: 21.6048499 },
    { lat: 49.2502474, lng: 21.6046954 },
    { lat: 49.2504286, lng: 21.6045397 },
    { lat: 49.2512412, lng: 21.6033682 },
    { lat: 49.2516466, lng: 21.602359 },
    { lat: 49.2522802, lng: 21.6011107 },
    { lat: 49.2522551, lng: 21.6005671 },
    { lat: 49.2524342, lng: 21.5982523 },
    { lat: 49.2547643, lng: 21.5980621 },
    { lat: 49.2564433, lng: 21.5967787 },
    { lat: 49.2565928, lng: 21.5963711 },
    { lat: 49.2565374, lng: 21.5930228 },
    { lat: 49.2565831, lng: 21.592359 },
    { lat: 49.2568461, lng: 21.591375 },
    { lat: 49.2571519, lng: 21.5910012 },
    { lat: 49.2573026, lng: 21.5909198 },
    { lat: 49.2573444, lng: 21.5909319 },
    { lat: 49.2575279, lng: 21.5914646 },
    { lat: 49.2576869, lng: 21.5923684 },
    { lat: 49.2580338, lng: 21.5931912 },
    { lat: 49.2605011, lng: 21.5929238 },
    { lat: 49.2614052, lng: 21.5922614 },
    { lat: 49.2616982, lng: 21.5917501 },
    { lat: 49.2626572, lng: 21.589576 },
    { lat: 49.2635672, lng: 21.5888799 },
    { lat: 49.2637419, lng: 21.5888532 },
    { lat: 49.2645677, lng: 21.5890159 },
    { lat: 49.2666136, lng: 21.5898154 },
    { lat: 49.2676627, lng: 21.5897605 },
    { lat: 49.2695589, lng: 21.5880827 },
    { lat: 49.2700236, lng: 21.5879579 },
    { lat: 49.2711184, lng: 21.5879717 },
    { lat: 49.2729794, lng: 21.5900161 },
    { lat: 49.2741889, lng: 21.5917288 },
    { lat: 49.2754692, lng: 21.5920964 },
    { lat: 49.2773026, lng: 21.5921677 },
    { lat: 49.278102, lng: 21.5922713 },
    { lat: 49.2786918, lng: 21.5918808 },
    { lat: 49.2800032, lng: 21.5913024 },
    { lat: 49.2806196, lng: 21.5913029 },
    { lat: 49.2810936, lng: 21.5902191 },
    { lat: 49.2816306, lng: 21.5887733 },
    { lat: 49.2817267, lng: 21.5883452 },
    { lat: 49.2810711, lng: 21.5857129 },
    { lat: 49.2808987, lng: 21.5839939 },
    { lat: 49.2809572, lng: 21.5829415 },
    { lat: 49.2811063, lng: 21.5822343 },
    { lat: 49.2814784, lng: 21.5809307 },
    { lat: 49.2816604, lng: 21.5791381 },
    { lat: 49.2816303, lng: 21.5781751 },
    { lat: 49.2813827, lng: 21.5778071 },
    { lat: 49.280978, lng: 21.5763997 },
    { lat: 49.2809604, lng: 21.5761753 },
    { lat: 49.2809821, lng: 21.5760243 },
  ],
  Želmanovce: [
    { lat: 49.129701, lng: 21.418255 },
    { lat: 49.1294873, lng: 21.4184195 },
    { lat: 49.1292661, lng: 21.4178202 },
    { lat: 49.1286568, lng: 21.4178346 },
    { lat: 49.1282064, lng: 21.4180987 },
    { lat: 49.127653, lng: 21.418524 },
    { lat: 49.1265788, lng: 21.4191121 },
    { lat: 49.1261528, lng: 21.4192452 },
    { lat: 49.1255039, lng: 21.4193291 },
    { lat: 49.1254112, lng: 21.4195075 },
    { lat: 49.1249704, lng: 21.4194763 },
    { lat: 49.1247509, lng: 21.4197032 },
    { lat: 49.1244916, lng: 21.4196874 },
    { lat: 49.1227158, lng: 21.4201732 },
    { lat: 49.122349, lng: 21.4200627 },
    { lat: 49.1219122, lng: 21.4201728 },
    { lat: 49.1216265, lng: 21.4201325 },
    { lat: 49.1214755, lng: 21.4200501 },
    { lat: 49.1212593, lng: 21.4200403 },
    { lat: 49.1213413, lng: 21.4198473 },
    { lat: 49.1211671, lng: 21.419927 },
    { lat: 49.1212287, lng: 21.4198402 },
    { lat: 49.1210223, lng: 21.4197475 },
    { lat: 49.1209212, lng: 21.4194131 },
    { lat: 49.1202698, lng: 21.4194469 },
    { lat: 49.119, lng: 21.4186172 },
    { lat: 49.1184555, lng: 21.4183567 },
    { lat: 49.1179303, lng: 21.4178597 },
    { lat: 49.1168571, lng: 21.4170949 },
    { lat: 49.1161938, lng: 21.4164256 },
    { lat: 49.1152811, lng: 21.4159172 },
    { lat: 49.1144142, lng: 21.4152804 },
    { lat: 49.1142991, lng: 21.41504 },
    { lat: 49.1136148, lng: 21.4144257 },
    { lat: 49.1132013, lng: 21.4144017 },
    { lat: 49.1120212, lng: 21.4150189 },
    { lat: 49.1116597, lng: 21.4154672 },
    { lat: 49.1115204, lng: 21.4157845 },
    { lat: 49.1115817, lng: 21.4166117 },
    { lat: 49.1114629, lng: 21.4170888 },
    { lat: 49.1109736, lng: 21.4174406 },
    { lat: 49.1100595, lng: 21.4171906 },
    { lat: 49.1093932, lng: 21.4166444 },
    { lat: 49.1088643, lng: 21.4165682 },
    { lat: 49.1078079, lng: 21.4157282 },
    { lat: 49.1060512, lng: 21.4156723 },
    { lat: 49.1059015, lng: 21.4155995 },
    { lat: 49.1055349, lng: 21.4151418 },
    { lat: 49.105332, lng: 21.414618 },
    { lat: 49.103764, lng: 21.415727 },
    { lat: 49.103856, lng: 21.416238 },
    { lat: 49.103837, lng: 21.416912 },
    { lat: 49.103875, lng: 21.417055 },
    { lat: 49.103879, lng: 21.41724 },
    { lat: 49.103811, lng: 21.418051 },
    { lat: 49.103648, lng: 21.418616 },
    { lat: 49.10352, lng: 21.419105 },
    { lat: 49.103372, lng: 21.419642 },
    { lat: 49.103277, lng: 21.4201 },
    { lat: 49.103191, lng: 21.420371 },
    { lat: 49.102984, lng: 21.420801 },
    { lat: 49.102812, lng: 21.421204 },
    { lat: 49.101918, lng: 21.421579 },
    { lat: 49.101791, lng: 21.42135 },
    { lat: 49.101696, lng: 21.42118 },
    { lat: 49.101198, lng: 21.420232 },
    { lat: 49.100766, lng: 21.420482 },
    { lat: 49.101076, lng: 21.421185 },
    { lat: 49.100745, lng: 21.421679 },
    { lat: 49.101164, lng: 21.422184 },
    { lat: 49.101078, lng: 21.423703 },
    { lat: 49.101439, lng: 21.424571 },
    { lat: 49.104615, lng: 21.4265053 },
    { lat: 49.1046312, lng: 21.4267538 },
    { lat: 49.1047658, lng: 21.4269325 },
    { lat: 49.104867, lng: 21.4272398 },
    { lat: 49.1049972, lng: 21.4274188 },
    { lat: 49.1050218, lng: 21.4276491 },
    { lat: 49.1051272, lng: 21.4277537 },
    { lat: 49.1050972, lng: 21.4281151 },
    { lat: 49.1051971, lng: 21.4284926 },
    { lat: 49.1053609, lng: 21.4286686 },
    { lat: 49.1054708, lng: 21.4291151 },
    { lat: 49.1055237, lng: 21.4299245 },
    { lat: 49.1054793, lng: 21.4301013 },
    { lat: 49.1056508, lng: 21.4304283 },
    { lat: 49.1055557, lng: 21.4306025 },
    { lat: 49.105656, lng: 21.4308399 },
    { lat: 49.1055023, lng: 21.4315853 },
    { lat: 49.1073113, lng: 21.4318918 },
    { lat: 49.1083907, lng: 21.4322889 },
    { lat: 49.1082498, lng: 21.4333611 },
    { lat: 49.1084306, lng: 21.4338114 },
    { lat: 49.1090837, lng: 21.4347211 },
    { lat: 49.1098356, lng: 21.4351576 },
    { lat: 49.1099493, lng: 21.4351582 },
    { lat: 49.1103759, lng: 21.4354723 },
    { lat: 49.110802, lng: 21.4356195 },
    { lat: 49.1110317, lng: 21.4358888 },
    { lat: 49.111373, lng: 21.4360242 },
    { lat: 49.1114103, lng: 21.4361046 },
    { lat: 49.1115992, lng: 21.4361396 },
    { lat: 49.1119957, lng: 21.4364601 },
    { lat: 49.1134001, lng: 21.4370509 },
    { lat: 49.1145909, lng: 21.4381899 },
    { lat: 49.1153275, lng: 21.4389878 },
    { lat: 49.1158467, lng: 21.4394682 },
    { lat: 49.1159805, lng: 21.4395022 },
    { lat: 49.115963, lng: 21.4414257 },
    { lat: 49.117267, lng: 21.4421926 },
    { lat: 49.1178852, lng: 21.4426423 },
    { lat: 49.1191093, lng: 21.4425608 },
    { lat: 49.1194309, lng: 21.4424777 },
    { lat: 49.1198807, lng: 21.4422736 },
    { lat: 49.1201848, lng: 21.4420537 },
    { lat: 49.1208281, lng: 21.4417758 },
    { lat: 49.1208772, lng: 21.4418738 },
    { lat: 49.1221811, lng: 21.4410559 },
    { lat: 49.1227201, lng: 21.442025 },
    { lat: 49.1229981, lng: 21.4417983 },
    { lat: 49.1236317, lng: 21.4410285 },
    { lat: 49.1242391, lng: 21.4405618 },
    { lat: 49.1249801, lng: 21.4396921 },
    { lat: 49.1252795, lng: 21.4390759 },
    { lat: 49.12567, lng: 21.4386138 },
    { lat: 49.1268316, lng: 21.4379735 },
    { lat: 49.1271044, lng: 21.4376426 },
    { lat: 49.1273836, lng: 21.4369128 },
    { lat: 49.1281903, lng: 21.4378829 },
    { lat: 49.1311718, lng: 21.4352153 },
    { lat: 49.1345432, lng: 21.4321127 },
    { lat: 49.134423, lng: 21.431743 },
    { lat: 49.134247, lng: 21.431314 },
    { lat: 49.133685, lng: 21.4303 },
    { lat: 49.133346, lng: 21.429859 },
    { lat: 49.133123, lng: 21.42938 },
    { lat: 49.132984, lng: 21.429174 },
    { lat: 49.132731, lng: 21.428663 },
    { lat: 49.132408, lng: 21.427889 },
    { lat: 49.132381, lng: 21.427467 },
    { lat: 49.132424, lng: 21.427203 },
    { lat: 49.132395, lng: 21.427168 },
    { lat: 49.131382, lng: 21.425929 },
    { lat: 49.130915, lng: 21.425476 },
    { lat: 49.130378, lng: 21.424334 },
    { lat: 49.131436, lng: 21.423164 },
    { lat: 49.131312, lng: 21.422801 },
    { lat: 49.131148, lng: 21.422431 },
    { lat: 49.131124, lng: 21.422453 },
    { lat: 49.130877, lng: 21.421704 },
    { lat: 49.130407, lng: 21.420322 },
    { lat: 49.130227, lng: 21.419563 },
    { lat: 49.130069, lng: 21.419067 },
    { lat: 49.129701, lng: 21.418255 },
  ],
  ŠarišskýŠtiavnik: [
    { lat: 49.2046802, lng: 21.535997 },
    { lat: 49.2055848, lng: 21.5396269 },
    { lat: 49.2058701, lng: 21.5422501 },
    { lat: 49.2060017, lng: 21.5424106 },
    { lat: 49.2061085, lng: 21.5426871 },
    { lat: 49.2061098, lng: 21.543136 },
    { lat: 49.2062287, lng: 21.5438773 },
    { lat: 49.2065069, lng: 21.5444553 },
    { lat: 49.2066904, lng: 21.5451805 },
    { lat: 49.2070856, lng: 21.5461062 },
    { lat: 49.2068716, lng: 21.5463005 },
    { lat: 49.2076002, lng: 21.5487329 },
    { lat: 49.2079862, lng: 21.5524993 },
    { lat: 49.2083347, lng: 21.5536513 },
    { lat: 49.2084522, lng: 21.5548335 },
    { lat: 49.2086998, lng: 21.5556427 },
    { lat: 49.2087663, lng: 21.5568139 },
    { lat: 49.20876, lng: 21.5578318 },
    { lat: 49.2090865, lng: 21.5581628 },
    { lat: 49.2091632, lng: 21.5585701 },
    { lat: 49.2092339, lng: 21.5598464 },
    { lat: 49.2092156, lng: 21.5604834 },
    { lat: 49.2097434, lng: 21.5640302 },
    { lat: 49.2093141, lng: 21.5655298 },
    { lat: 49.2096424, lng: 21.5667757 },
    { lat: 49.2095937, lng: 21.5668469 },
    { lat: 49.2096635, lng: 21.5672126 },
    { lat: 49.2095928, lng: 21.5672981 },
    { lat: 49.209684, lng: 21.5679791 },
    { lat: 49.2098745, lng: 21.5679552 },
    { lat: 49.2099155, lng: 21.5689841 },
    { lat: 49.2099131, lng: 21.5693811 },
    { lat: 49.2097975, lng: 21.5700366 },
    { lat: 49.2097494, lng: 21.5706115 },
    { lat: 49.2095125, lng: 21.5718787 },
    { lat: 49.2095694, lng: 21.5718984 },
    { lat: 49.2095039, lng: 21.5722959 },
    { lat: 49.2094566, lng: 21.5722907 },
    { lat: 49.2094104, lng: 21.5725693 },
    { lat: 49.2094541, lng: 21.5733678 },
    { lat: 49.2093785, lng: 21.5744528 },
    { lat: 49.2094401, lng: 21.5746435 },
    { lat: 49.2093627, lng: 21.5755441 },
    { lat: 49.2094091, lng: 21.5755957 },
    { lat: 49.2094458, lng: 21.5759053 },
    { lat: 49.2094187, lng: 21.5762611 },
    { lat: 49.2094673, lng: 21.5765926 },
    { lat: 49.2095181, lng: 21.5768426 },
    { lat: 49.2108024, lng: 21.5796972 },
    { lat: 49.2111254, lng: 21.5799422 },
    { lat: 49.2108635, lng: 21.5820961 },
    { lat: 49.2114886, lng: 21.5821186 },
    { lat: 49.21165, lng: 21.5822611 },
    { lat: 49.2122599, lng: 21.5825526 },
    { lat: 49.2124642, lng: 21.5828183 },
    { lat: 49.212647, lng: 21.5833224 },
    { lat: 49.2127663, lng: 21.5833665 },
    { lat: 49.2129428, lng: 21.5835681 },
    { lat: 49.2132602, lng: 21.5841395 },
    { lat: 49.2132282, lng: 21.5844802 },
    { lat: 49.2133918, lng: 21.5850248 },
    { lat: 49.2134184, lng: 21.5856165 },
    { lat: 49.2134968, lng: 21.5858528 },
    { lat: 49.2136771, lng: 21.5875223 },
    { lat: 49.2139261, lng: 21.5878985 },
    { lat: 49.2141148, lng: 21.5884994 },
    { lat: 49.214738, lng: 21.593647 },
    { lat: 49.21507, lng: 21.593975 },
    { lat: 49.215189, lng: 21.594076 },
    { lat: 49.215234, lng: 21.594114 },
    { lat: 49.215754, lng: 21.594934 },
    { lat: 49.216855, lng: 21.596634 },
    { lat: 49.216929, lng: 21.596749 },
    { lat: 49.216994, lng: 21.596849 },
    { lat: 49.217177, lng: 21.597133 },
    { lat: 49.217236, lng: 21.597175 },
    { lat: 49.217867, lng: 21.595508 },
    { lat: 49.218178, lng: 21.595199 },
    { lat: 49.218657, lng: 21.59543 },
    { lat: 49.219029, lng: 21.595291 },
    { lat: 49.219269, lng: 21.595585 },
    { lat: 49.219699, lng: 21.595419 },
    { lat: 49.219963, lng: 21.59534 },
    { lat: 49.220202, lng: 21.595417 },
    { lat: 49.22058, lng: 21.595451 },
    { lat: 49.22102, lng: 21.595615 },
    { lat: 49.221357, lng: 21.595729 },
    { lat: 49.221927, lng: 21.595736 },
    { lat: 49.222124, lng: 21.595844 },
    { lat: 49.22264, lng: 21.595903 },
    { lat: 49.222955, lng: 21.59591 },
    { lat: 49.223073, lng: 21.595836 },
    { lat: 49.223216, lng: 21.595737 },
    { lat: 49.223974, lng: 21.595378 },
    { lat: 49.224216, lng: 21.596069 },
    { lat: 49.223958, lng: 21.595024 },
    { lat: 49.224143, lng: 21.594455 },
    { lat: 49.224242, lng: 21.594268 },
    { lat: 49.224448, lng: 21.594078 },
    { lat: 49.224932, lng: 21.5937 },
    { lat: 49.225137, lng: 21.593536 },
    { lat: 49.2238575, lng: 21.5900629 },
    { lat: 49.2233551, lng: 21.5882923 },
    { lat: 49.2231539, lng: 21.5883402 },
    { lat: 49.2231049, lng: 21.5877463 },
    { lat: 49.2229373, lng: 21.587345 },
    { lat: 49.2228683, lng: 21.5867189 },
    { lat: 49.2230543, lng: 21.585642 },
    { lat: 49.223079, lng: 21.5851538 },
    { lat: 49.2227256, lng: 21.5846634 },
    { lat: 49.2226515, lng: 21.5842191 },
    { lat: 49.222682, lng: 21.5837377 },
    { lat: 49.2229105, lng: 21.5830513 },
    { lat: 49.2229753, lng: 21.5829989 },
    { lat: 49.2231908, lng: 21.582331 },
    { lat: 49.2232916, lng: 21.5821582 },
    { lat: 49.2233703, lng: 21.5817079 },
    { lat: 49.2235825, lng: 21.5811654 },
    { lat: 49.2235939, lng: 21.579227 },
    { lat: 49.2236708, lng: 21.578925 },
    { lat: 49.2242044, lng: 21.5777178 },
    { lat: 49.2241994, lng: 21.5775029 },
    { lat: 49.2240912, lng: 21.5769273 },
    { lat: 49.2238705, lng: 21.5762322 },
    { lat: 49.2235399, lng: 21.5757552 },
    { lat: 49.2234446, lng: 21.5751178 },
    { lat: 49.223081, lng: 21.5747078 },
    { lat: 49.2228446, lng: 21.5740587 },
    { lat: 49.22255, lng: 21.5736953 },
    { lat: 49.2220517, lng: 21.5728088 },
    { lat: 49.222006, lng: 21.5725073 },
    { lat: 49.2216351, lng: 21.5716227 },
    { lat: 49.2213377, lng: 21.5706886 },
    { lat: 49.221377, lng: 21.5706022 },
    { lat: 49.2210239, lng: 21.569033 },
    { lat: 49.2202989, lng: 21.5664346 },
    { lat: 49.2194588, lng: 21.5643106 },
    { lat: 49.2191029, lng: 21.5636913 },
    { lat: 49.2190412, lng: 21.5633384 },
    { lat: 49.2189154, lng: 21.5631454 },
    { lat: 49.2186481, lng: 21.5624733 },
    { lat: 49.2171828, lng: 21.558431 },
    { lat: 49.2169232, lng: 21.5578405 },
    { lat: 49.216693, lng: 21.5570962 },
    { lat: 49.2166652, lng: 21.5568277 },
    { lat: 49.2168551, lng: 21.5558556 },
    { lat: 49.2168363, lng: 21.5555444 },
    { lat: 49.2169195, lng: 21.5553782 },
    { lat: 49.2169034, lng: 21.5538016 },
    { lat: 49.216891, lng: 21.5536034 },
    { lat: 49.2165491, lng: 21.5532799 },
    { lat: 49.2165215, lng: 21.5528182 },
    { lat: 49.2162642, lng: 21.5509486 },
    { lat: 49.2163457, lng: 21.550804 },
    { lat: 49.2163662, lng: 21.5506087 },
    { lat: 49.2162518, lng: 21.5501447 },
    { lat: 49.2163664, lng: 21.5500203 },
    { lat: 49.2163422, lng: 21.5498405 },
    { lat: 49.2164837, lng: 21.5496736 },
    { lat: 49.2163371, lng: 21.5490542 },
    { lat: 49.2162451, lng: 21.5488659 },
    { lat: 49.2162588, lng: 21.5483115 },
    { lat: 49.2160487, lng: 21.5469171 },
    { lat: 49.2150142, lng: 21.5428908 },
    { lat: 49.2144736, lng: 21.5385612 },
    { lat: 49.2149086, lng: 21.537741 },
    { lat: 49.2151823, lng: 21.5366377 },
    { lat: 49.2140963, lng: 21.5328345 },
    { lat: 49.2136988, lng: 21.5308303 },
    { lat: 49.2131401, lng: 21.5249904 },
    { lat: 49.2128735, lng: 21.5255085 },
    { lat: 49.2127268, lng: 21.5259752 },
    { lat: 49.2126351, lng: 21.5265484 },
    { lat: 49.2115301, lng: 21.527973 },
    { lat: 49.2109912, lng: 21.5284917 },
    { lat: 49.2105098, lng: 21.5288894 },
    { lat: 49.209944, lng: 21.5291328 },
    { lat: 49.2098707, lng: 21.529926 },
    { lat: 49.2097886, lng: 21.5301671 },
    { lat: 49.2067235, lng: 21.5332906 },
    { lat: 49.2063735, lng: 21.5344063 },
    { lat: 49.2059649, lng: 21.5349145 },
    { lat: 49.2050002, lng: 21.5358468 },
    { lat: 49.2046802, lng: 21.535997 },
  ],
  Miroľa: [
    { lat: 49.3478599, lng: 21.7338152 },
    { lat: 49.3478365, lng: 21.7338206 },
    { lat: 49.346204, lng: 21.734273 },
    { lat: 49.3459725, lng: 21.7338977 },
    { lat: 49.3456707, lng: 21.7335921 },
    { lat: 49.3455397, lng: 21.7332286 },
    { lat: 49.3446628, lng: 21.7320131 },
    { lat: 49.3442382, lng: 21.7316956 },
    { lat: 49.3439212, lng: 21.7312787 },
    { lat: 49.3436793, lng: 21.7308936 },
    { lat: 49.3437049, lng: 21.7308015 },
    { lat: 49.3436173, lng: 21.7306327 },
    { lat: 49.3435077, lng: 21.7306175 },
    { lat: 49.3433977, lng: 21.7304133 },
    { lat: 49.3434386, lng: 21.7302931 },
    { lat: 49.3433472, lng: 21.7301628 },
    { lat: 49.3432745, lng: 21.730176 },
    { lat: 49.3431445, lng: 21.7299394 },
    { lat: 49.342963, lng: 21.7294922 },
    { lat: 49.3430049, lng: 21.7293399 },
    { lat: 49.3428647, lng: 21.7289691 },
    { lat: 49.342671, lng: 21.7290053 },
    { lat: 49.3420887, lng: 21.7278722 },
    { lat: 49.3421135, lng: 21.7277473 },
    { lat: 49.3418977, lng: 21.7276265 },
    { lat: 49.3415923, lng: 21.7272635 },
    { lat: 49.3414, lng: 21.7266243 },
    { lat: 49.3411424, lng: 21.7261562 },
    { lat: 49.3409747, lng: 21.7260252 },
    { lat: 49.3408894, lng: 21.7258674 },
    { lat: 49.340458, lng: 21.7247334 },
    { lat: 49.3401328, lng: 21.7237595 },
    { lat: 49.3391141, lng: 21.7200003 },
    { lat: 49.338708, lng: 21.718767 },
    { lat: 49.3386137, lng: 21.7181381 },
    { lat: 49.3386913, lng: 21.7172535 },
    { lat: 49.3385518, lng: 21.7165703 },
    { lat: 49.338196, lng: 21.7156258 },
    { lat: 49.3379786, lng: 21.7151985 },
    { lat: 49.3379905, lng: 21.7149648 },
    { lat: 49.3378969, lng: 21.7148747 },
    { lat: 49.3378818, lng: 21.7145989 },
    { lat: 49.3376326, lng: 21.7140723 },
    { lat: 49.3371773, lng: 21.7136614 },
    { lat: 49.3367663, lng: 21.7124148 },
    { lat: 49.3364013, lng: 21.7115856 },
    { lat: 49.3362252, lng: 21.7107168 },
    { lat: 49.335608, lng: 21.709537 },
    { lat: 49.334068, lng: 21.71223 },
    { lat: 49.33337, lng: 21.714011 },
    { lat: 49.332856, lng: 21.715357 },
    { lat: 49.332819, lng: 21.715455 },
    { lat: 49.331458, lng: 21.716496 },
    { lat: 49.330902, lng: 21.716812 },
    { lat: 49.329984, lng: 21.717333 },
    { lat: 49.329183, lng: 21.71791 },
    { lat: 49.327981, lng: 21.719217 },
    { lat: 49.327833, lng: 21.719262 },
    { lat: 49.325676, lng: 21.71991 },
    { lat: 49.325321, lng: 21.720001 },
    { lat: 49.325273, lng: 21.720013 },
    { lat: 49.324803, lng: 21.719823 },
    { lat: 49.324205, lng: 21.71958 },
    { lat: 49.323379, lng: 21.719508 },
    { lat: 49.321406, lng: 21.722717 },
    { lat: 49.321366, lng: 21.722784 },
    { lat: 49.320179, lng: 21.724787 },
    { lat: 49.320096, lng: 21.724969 },
    { lat: 49.320079, lng: 21.724993 },
    { lat: 49.320022, lng: 21.72511 },
    { lat: 49.320007, lng: 21.725149 },
    { lat: 49.319959, lng: 21.725212 },
    { lat: 49.31946, lng: 21.725861 },
    { lat: 49.318927, lng: 21.726253 },
    { lat: 49.321051, lng: 21.7371879 },
    { lat: 49.3214879, lng: 21.7408619 },
    { lat: 49.3220498, lng: 21.7428562 },
    { lat: 49.3222936, lng: 21.7431786 },
    { lat: 49.3227168, lng: 21.7440883 },
    { lat: 49.3232594, lng: 21.7455999 },
    { lat: 49.3235626, lng: 21.746266 },
    { lat: 49.3241686, lng: 21.7483883 },
    { lat: 49.324184, lng: 21.7490191 },
    { lat: 49.324234, lng: 21.7492515 },
    { lat: 49.3244074, lng: 21.7493821 },
    { lat: 49.3246776, lng: 21.750077 },
    { lat: 49.324629, lng: 21.750299 },
    { lat: 49.325423, lng: 21.750428 },
    { lat: 49.325522, lng: 21.750498 },
    { lat: 49.325629, lng: 21.750493 },
    { lat: 49.325774, lng: 21.750558 },
    { lat: 49.325883, lng: 21.750549 },
    { lat: 49.325921, lng: 21.750532 },
    { lat: 49.326295, lng: 21.75036 },
    { lat: 49.326701, lng: 21.749956 },
    { lat: 49.326832, lng: 21.749879 },
    { lat: 49.326955, lng: 21.74986 },
    { lat: 49.32721, lng: 21.749693 },
    { lat: 49.327423, lng: 21.749715 },
    { lat: 49.327518, lng: 21.749698 },
    { lat: 49.327745, lng: 21.74973 },
    { lat: 49.327927, lng: 21.74983 },
    { lat: 49.32804, lng: 21.749833 },
    { lat: 49.328325, lng: 21.749806 },
    { lat: 49.328525, lng: 21.749727 },
    { lat: 49.328699, lng: 21.749753 },
    { lat: 49.328815, lng: 21.749667 },
    { lat: 49.329364, lng: 21.749541 },
    { lat: 49.330249, lng: 21.748726 },
    { lat: 49.330752, lng: 21.748655 },
    { lat: 49.330968, lng: 21.748505 },
    { lat: 49.331065, lng: 21.748456 },
    { lat: 49.331159, lng: 21.748409 },
    { lat: 49.331334, lng: 21.748391 },
    { lat: 49.331914, lng: 21.748489 },
    { lat: 49.332079, lng: 21.74852 },
    { lat: 49.332582, lng: 21.74854 },
    { lat: 49.332998, lng: 21.748601 },
    { lat: 49.333134, lng: 21.748677 },
    { lat: 49.333327, lng: 21.748771 },
    { lat: 49.333348, lng: 21.748771 },
    { lat: 49.333924, lng: 21.748778 },
    { lat: 49.334389, lng: 21.748614 },
    { lat: 49.33451, lng: 21.74866 },
    { lat: 49.334646, lng: 21.748641 },
    { lat: 49.334808, lng: 21.748719 },
    { lat: 49.334849, lng: 21.748663 },
    { lat: 49.335133, lng: 21.748482 },
    { lat: 49.33553, lng: 21.748365 },
    { lat: 49.33584, lng: 21.748397 },
    { lat: 49.335985, lng: 21.748316 },
    { lat: 49.33611, lng: 21.748316 },
    { lat: 49.336569, lng: 21.748195 },
    { lat: 49.336854, lng: 21.748258 },
    { lat: 49.336939, lng: 21.74824 },
    { lat: 49.337211, lng: 21.748437 },
    { lat: 49.337346, lng: 21.748418 },
    { lat: 49.337494, lng: 21.748563 },
    { lat: 49.3376, lng: 21.748591 },
    { lat: 49.337755, lng: 21.748675 },
    { lat: 49.33794, lng: 21.748679 },
    { lat: 49.33814, lng: 21.748769 },
    { lat: 49.338299, lng: 21.748741 },
    { lat: 49.338505, lng: 21.748833 },
    { lat: 49.338688, lng: 21.74901 },
    { lat: 49.338777, lng: 21.749077 },
    { lat: 49.339189, lng: 21.749012 },
    { lat: 49.339382, lng: 21.749088 },
    { lat: 49.339498, lng: 21.749157 },
    { lat: 49.339766, lng: 21.749212 },
    { lat: 49.33991, lng: 21.749265 },
    { lat: 49.340169, lng: 21.749129 },
    { lat: 49.340467, lng: 21.74886 },
    { lat: 49.340489, lng: 21.748922 },
    { lat: 49.340662, lng: 21.748813 },
    { lat: 49.340927, lng: 21.748524 },
    { lat: 49.341138, lng: 21.748182 },
    { lat: 49.341457, lng: 21.747798 },
    { lat: 49.341606, lng: 21.747459 },
    { lat: 49.341748, lng: 21.74746 },
    { lat: 49.341907, lng: 21.747359 },
    { lat: 49.341914, lng: 21.747341 },
    { lat: 49.342033, lng: 21.747409 },
    { lat: 49.342209, lng: 21.747449 },
    { lat: 49.342305, lng: 21.74749 },
    { lat: 49.342565, lng: 21.747468 },
    { lat: 49.342935, lng: 21.747584 },
    { lat: 49.343236, lng: 21.747863 },
    { lat: 49.343406, lng: 21.748155 },
    { lat: 49.343544, lng: 21.748277 },
    { lat: 49.343744, lng: 21.748712 },
    { lat: 49.343867, lng: 21.748889 },
    { lat: 49.344026, lng: 21.749145 },
    { lat: 49.344356, lng: 21.749469 },
    { lat: 49.344423, lng: 21.749615 },
    { lat: 49.344977, lng: 21.750205 },
    { lat: 49.345056, lng: 21.750362 },
    { lat: 49.34516, lng: 21.750735 },
    { lat: 49.345242, lng: 21.750875 },
    { lat: 49.345255, lng: 21.750964 },
    { lat: 49.345616, lng: 21.751256 },
    { lat: 49.346074, lng: 21.751509 },
    { lat: 49.346367, lng: 21.751524 },
    { lat: 49.346823, lng: 21.75122 },
    { lat: 49.3467563, lng: 21.7507107 },
    { lat: 49.346989, lng: 21.7503284 },
    { lat: 49.3473796, lng: 21.7501232 },
    { lat: 49.3471288, lng: 21.7491011 },
    { lat: 49.3472751, lng: 21.748665 },
    { lat: 49.3474046, lng: 21.7484633 },
    { lat: 49.3475664, lng: 21.7484937 },
    { lat: 49.3476503, lng: 21.7483049 },
    { lat: 49.3478481, lng: 21.7481293 },
    { lat: 49.3479084, lng: 21.7478572 },
    { lat: 49.3473226, lng: 21.7465368 },
    { lat: 49.3473106, lng: 21.7463853 },
    { lat: 49.3470497, lng: 21.7459122 },
    { lat: 49.346887, lng: 21.7457599 },
    { lat: 49.3465833, lng: 21.7452537 },
    { lat: 49.3467707, lng: 21.7444196 },
    { lat: 49.3472367, lng: 21.7437062 },
    { lat: 49.3474942, lng: 21.7435759 },
    { lat: 49.3485521, lng: 21.7426996 },
    { lat: 49.3488747, lng: 21.7420967 },
    { lat: 49.3487956, lng: 21.7418263 },
    { lat: 49.3489107, lng: 21.7414523 },
    { lat: 49.3488411, lng: 21.7413332 },
    { lat: 49.34889, lng: 21.7410055 },
    { lat: 49.3488023, lng: 21.740666 },
    { lat: 49.3489499, lng: 21.7400425 },
    { lat: 49.3489361, lng: 21.7397338 },
    { lat: 49.349084, lng: 21.7396662 },
    { lat: 49.3491989, lng: 21.7392854 },
    { lat: 49.3491028, lng: 21.7389327 },
    { lat: 49.3492249, lng: 21.7385665 },
    { lat: 49.3491598, lng: 21.7384901 },
    { lat: 49.3491729, lng: 21.738301 },
    { lat: 49.3491172, lng: 21.738189 },
    { lat: 49.3490322, lng: 21.7377018 },
    { lat: 49.3484122, lng: 21.7360268 },
    { lat: 49.3481788, lng: 21.7357952 },
    { lat: 49.3481045, lng: 21.7350266 },
    { lat: 49.348039, lng: 21.7349376 },
    { lat: 49.3480642, lng: 21.7346521 },
    { lat: 49.3480227, lng: 21.7342763 },
    { lat: 49.3478542, lng: 21.7340717 },
    { lat: 49.3479254, lng: 21.7339547 },
    { lat: 49.3478599, lng: 21.7338152 },
  ],
  Radoma: [
    { lat: 49.2046802, lng: 21.535997 },
    { lat: 49.2039737, lng: 21.5360707 },
    { lat: 49.2036286, lng: 21.5362833 },
    { lat: 49.2030143, lng: 21.5363578 },
    { lat: 49.2022771, lng: 21.5367109 },
    { lat: 49.2018343, lng: 21.5368066 },
    { lat: 49.201425, lng: 21.5370493 },
    { lat: 49.201234, lng: 21.5373396 },
    { lat: 49.2012909, lng: 21.5377611 },
    { lat: 49.2009472, lng: 21.5379466 },
    { lat: 49.2006707, lng: 21.5379892 },
    { lat: 49.2003075, lng: 21.5379155 },
    { lat: 49.1995619, lng: 21.5381249 },
    { lat: 49.1979605, lng: 21.538385 },
    { lat: 49.1973862, lng: 21.538368 },
    { lat: 49.196413, lng: 21.5380074 },
    { lat: 49.1962989, lng: 21.5378728 },
    { lat: 49.1961313, lng: 21.5373877 },
    { lat: 49.1959362, lng: 21.5370171 },
    { lat: 49.1953586, lng: 21.5369578 },
    { lat: 49.1948399, lng: 21.5371138 },
    { lat: 49.1944317, lng: 21.5370438 },
    { lat: 49.1943219, lng: 21.5371791 },
    { lat: 49.1942809, lng: 21.5376394 },
    { lat: 49.1941723, lng: 21.5377753 },
    { lat: 49.1938646, lng: 21.5376832 },
    { lat: 49.1923421, lng: 21.5415387 },
    { lat: 49.1918117, lng: 21.5412033 },
    { lat: 49.1880965, lng: 21.5537997 },
    { lat: 49.1879593, lng: 21.5537555 },
    { lat: 49.1875298, lng: 21.5549682 },
    { lat: 49.1861207, lng: 21.5576719 },
    { lat: 49.1866982, lng: 21.5594534 },
    { lat: 49.186895, lng: 21.5634381 },
    { lat: 49.1852909, lng: 21.5670597 },
    { lat: 49.1840051, lng: 21.568593 },
    { lat: 49.1838852, lng: 21.5685688 },
    { lat: 49.1835731, lng: 21.5686718 },
    { lat: 49.1835033, lng: 21.5689489 },
    { lat: 49.1833638, lng: 21.5691975 },
    { lat: 49.1833545, lng: 21.5693894 },
    { lat: 49.1832427, lng: 21.5697304 },
    { lat: 49.18331, lng: 21.5699289 },
    { lat: 49.1829971, lng: 21.570444 },
    { lat: 49.1829589, lng: 21.5709324 },
    { lat: 49.1828271, lng: 21.57137 },
    { lat: 49.1829062, lng: 21.5720672 },
    { lat: 49.1831995, lng: 21.5729044 },
    { lat: 49.1834398, lng: 21.5732452 },
    { lat: 49.183668, lng: 21.5734397 },
    { lat: 49.18356, lng: 21.5734819 },
    { lat: 49.1828705, lng: 21.5749051 },
    { lat: 49.1830547, lng: 21.5750078 },
    { lat: 49.1831194, lng: 21.5753158 },
    { lat: 49.1831091, lng: 21.5761148 },
    { lat: 49.183319, lng: 21.5768305 },
    { lat: 49.1834219, lng: 21.5781804 },
    { lat: 49.1836233, lng: 21.5782576 },
    { lat: 49.184092, lng: 21.5787256 },
    { lat: 49.1840654, lng: 21.5789457 },
    { lat: 49.1841225, lng: 21.5802704 },
    { lat: 49.184105, lng: 21.5808538 },
    { lat: 49.1848702, lng: 21.5812671 },
    { lat: 49.1841303, lng: 21.5855269 },
    { lat: 49.1842261, lng: 21.5856927 },
    { lat: 49.184254, lng: 21.585969 },
    { lat: 49.184278, lng: 21.585933 },
    { lat: 49.184331, lng: 21.58589 },
    { lat: 49.184445, lng: 21.585934 },
    { lat: 49.184661, lng: 21.586651 },
    { lat: 49.184794, lng: 21.587007 },
    { lat: 49.184802, lng: 21.587021 },
    { lat: 49.184918, lng: 21.586927 },
    { lat: 49.185006, lng: 21.586797 },
    { lat: 49.185074, lng: 21.58668 },
    { lat: 49.185154, lng: 21.586548 },
    { lat: 49.185224, lng: 21.586497 },
    { lat: 49.185395, lng: 21.586374 },
    { lat: 49.185458, lng: 21.586341 },
    { lat: 49.185557, lng: 21.586258 },
    { lat: 49.185609, lng: 21.586235 },
    { lat: 49.185663, lng: 21.586189 },
    { lat: 49.185769, lng: 21.586151 },
    { lat: 49.185846, lng: 21.58605 },
    { lat: 49.185893, lng: 21.586042 },
    { lat: 49.185943, lng: 21.586105 },
    { lat: 49.186091, lng: 21.586222 },
    { lat: 49.186129, lng: 21.58624 },
    { lat: 49.186269, lng: 21.586295 },
    { lat: 49.186299, lng: 21.5863 },
    { lat: 49.186459, lng: 21.586456 },
    { lat: 49.186525, lng: 21.586557 },
    { lat: 49.186568, lng: 21.586586 },
    { lat: 49.186601, lng: 21.586639 },
    { lat: 49.186637, lng: 21.586667 },
    { lat: 49.186692, lng: 21.586746 },
    { lat: 49.186801, lng: 21.586812 },
    { lat: 49.1869, lng: 21.586924 },
    { lat: 49.187054, lng: 21.587077 },
    { lat: 49.187124, lng: 21.587083 },
    { lat: 49.187189, lng: 21.587102 },
    { lat: 49.187226, lng: 21.587139 },
    { lat: 49.187353, lng: 21.587183 },
    { lat: 49.187497, lng: 21.587299 },
    { lat: 49.187623, lng: 21.587431 },
    { lat: 49.187758, lng: 21.587582 },
    { lat: 49.187798, lng: 21.587658 },
    { lat: 49.187917, lng: 21.587754 },
    { lat: 49.188108, lng: 21.588011 },
    { lat: 49.188171, lng: 21.588011 },
    { lat: 49.18824, lng: 21.588041 },
    { lat: 49.188288, lng: 21.588119 },
    { lat: 49.188347, lng: 21.588148 },
    { lat: 49.188616, lng: 21.588428 },
    { lat: 49.188648, lng: 21.58842 },
    { lat: 49.188733, lng: 21.588472 },
    { lat: 49.188741, lng: 21.588562 },
    { lat: 49.188814, lng: 21.588559 },
    { lat: 49.188871, lng: 21.588626 },
    { lat: 49.188893, lng: 21.588694 },
    { lat: 49.188938, lng: 21.588728 },
    { lat: 49.188987, lng: 21.588723 },
    { lat: 49.189086, lng: 21.588808 },
    { lat: 49.1892, lng: 21.588956 },
    { lat: 49.189317, lng: 21.589012 },
    { lat: 49.189376, lng: 21.589234 },
    { lat: 49.189497, lng: 21.589434 },
    { lat: 49.189613, lng: 21.589397 },
    { lat: 49.189673, lng: 21.589407 },
    { lat: 49.190008, lng: 21.589747 },
    { lat: 49.190013, lng: 21.58991 },
    { lat: 49.190023, lng: 21.590381 },
    { lat: 49.190027, lng: 21.590532 },
    { lat: 49.189814, lng: 21.590846 },
    { lat: 49.189835, lng: 21.591478 },
    { lat: 49.189706, lng: 21.592339 },
    { lat: 49.189817, lng: 21.59296 },
    { lat: 49.189862, lng: 21.593093 },
    { lat: 49.190165, lng: 21.593207 },
    { lat: 49.190246, lng: 21.593626 },
    { lat: 49.190445, lng: 21.593837 },
    { lat: 49.190333, lng: 21.594003 },
    { lat: 49.190504, lng: 21.593992 },
    { lat: 49.190595, lng: 21.594219 },
    { lat: 49.190797, lng: 21.594268 },
    { lat: 49.190782, lng: 21.594604 },
    { lat: 49.190897, lng: 21.594744 },
    { lat: 49.191209, lng: 21.594669 },
    { lat: 49.191256, lng: 21.595013 },
    { lat: 49.191617, lng: 21.595086 },
    { lat: 49.191591, lng: 21.595593 },
    { lat: 49.191749, lng: 21.596022 },
    { lat: 49.191994, lng: 21.595902 },
    { lat: 49.192551, lng: 21.596915 },
    { lat: 49.192491, lng: 21.597166 },
    { lat: 49.192708, lng: 21.597391 },
    { lat: 49.192831, lng: 21.597412 },
    { lat: 49.192993, lng: 21.597133 },
    { lat: 49.193401, lng: 21.597267 },
    { lat: 49.193432, lng: 21.597588 },
    { lat: 49.193776, lng: 21.59733 },
    { lat: 49.193921, lng: 21.597508 },
    { lat: 49.194477, lng: 21.597811 },
    { lat: 49.194653, lng: 21.59785 },
    { lat: 49.19481, lng: 21.597725 },
    { lat: 49.195035, lng: 21.59777 },
    { lat: 49.195228, lng: 21.597808 },
    { lat: 49.195629, lng: 21.597911 },
    { lat: 49.195842, lng: 21.597634 },
    { lat: 49.196073, lng: 21.597683 },
    { lat: 49.19627, lng: 21.597304 },
    { lat: 49.196609, lng: 21.597719 },
    { lat: 49.196873, lng: 21.597472 },
    { lat: 49.196917, lng: 21.597488 },
    { lat: 49.197017, lng: 21.597524 },
    { lat: 49.197169, lng: 21.597358 },
    { lat: 49.19753, lng: 21.597498 },
    { lat: 49.197693, lng: 21.597365 },
    { lat: 49.197775, lng: 21.597297 },
    { lat: 49.197818, lng: 21.597195 },
    { lat: 49.197986, lng: 21.596791 },
    { lat: 49.198167, lng: 21.597168 },
    { lat: 49.198196, lng: 21.597225 },
    { lat: 49.198722, lng: 21.597089 },
    { lat: 49.198992, lng: 21.59724 },
    { lat: 49.199105, lng: 21.597341 },
    { lat: 49.199368, lng: 21.59722 },
    { lat: 49.199595, lng: 21.597464 },
    { lat: 49.199821, lng: 21.597348 },
    { lat: 49.200578, lng: 21.597312 },
    { lat: 49.201224, lng: 21.597734 },
    { lat: 49.201779, lng: 21.597722 },
    { lat: 49.204261, lng: 21.598121 },
    { lat: 49.205487, lng: 21.598595 },
    { lat: 49.205796, lng: 21.598665 },
    { lat: 49.205948, lng: 21.598316 },
    { lat: 49.20618, lng: 21.598226 },
    { lat: 49.206567, lng: 21.598208 },
    { lat: 49.206771, lng: 21.598039 },
    { lat: 49.206933, lng: 21.598081 },
    { lat: 49.206976, lng: 21.598099 },
    { lat: 49.207019, lng: 21.598116 },
    { lat: 49.207367, lng: 21.598258 },
    { lat: 49.207673, lng: 21.598382 },
    { lat: 49.207676, lng: 21.598383 },
    { lat: 49.207726, lng: 21.598381 },
    { lat: 49.208581, lng: 21.598752 },
    { lat: 49.208643, lng: 21.598779 },
    { lat: 49.208666, lng: 21.598938 },
    { lat: 49.208701, lng: 21.599416 },
    { lat: 49.209461, lng: 21.598787 },
    { lat: 49.210042, lng: 21.597858 },
    { lat: 49.210272, lng: 21.596439 },
    { lat: 49.210485, lng: 21.596051 },
    { lat: 49.210888, lng: 21.595601 },
    { lat: 49.211103, lng: 21.595398 },
    { lat: 49.211444, lng: 21.59529 },
    { lat: 49.21162, lng: 21.595305 },
    { lat: 49.211977, lng: 21.595238 },
    { lat: 49.211962, lng: 21.595456 },
    { lat: 49.212224, lng: 21.595286 },
    { lat: 49.212547, lng: 21.595205 },
    { lat: 49.213137, lng: 21.59478 },
    { lat: 49.213426, lng: 21.594634 },
    { lat: 49.213623, lng: 21.594238 },
    { lat: 49.21392, lng: 21.593737 },
    { lat: 49.214603, lng: 21.593903 },
    { lat: 49.214738, lng: 21.593647 },
    { lat: 49.2141148, lng: 21.5884994 },
    { lat: 49.2139261, lng: 21.5878985 },
    { lat: 49.2136771, lng: 21.5875223 },
    { lat: 49.2134968, lng: 21.5858528 },
    { lat: 49.2134184, lng: 21.5856165 },
    { lat: 49.2133918, lng: 21.5850248 },
    { lat: 49.2132282, lng: 21.5844802 },
    { lat: 49.2132602, lng: 21.5841395 },
    { lat: 49.2129428, lng: 21.5835681 },
    { lat: 49.2127663, lng: 21.5833665 },
    { lat: 49.212647, lng: 21.5833224 },
    { lat: 49.2124642, lng: 21.5828183 },
    { lat: 49.2122599, lng: 21.5825526 },
    { lat: 49.21165, lng: 21.5822611 },
    { lat: 49.2114886, lng: 21.5821186 },
    { lat: 49.2108635, lng: 21.5820961 },
    { lat: 49.2111254, lng: 21.5799422 },
    { lat: 49.2108024, lng: 21.5796972 },
    { lat: 49.2095181, lng: 21.5768426 },
    { lat: 49.2094673, lng: 21.5765926 },
    { lat: 49.2094187, lng: 21.5762611 },
    { lat: 49.2094458, lng: 21.5759053 },
    { lat: 49.2094091, lng: 21.5755957 },
    { lat: 49.2093627, lng: 21.5755441 },
    { lat: 49.2094401, lng: 21.5746435 },
    { lat: 49.2093785, lng: 21.5744528 },
    { lat: 49.2094541, lng: 21.5733678 },
    { lat: 49.2094104, lng: 21.5725693 },
    { lat: 49.2094566, lng: 21.5722907 },
    { lat: 49.2095039, lng: 21.5722959 },
    { lat: 49.2095694, lng: 21.5718984 },
    { lat: 49.2095125, lng: 21.5718787 },
    { lat: 49.2097494, lng: 21.5706115 },
    { lat: 49.2097975, lng: 21.5700366 },
    { lat: 49.2099131, lng: 21.5693811 },
    { lat: 49.2099155, lng: 21.5689841 },
    { lat: 49.2098745, lng: 21.5679552 },
    { lat: 49.209684, lng: 21.5679791 },
    { lat: 49.2095928, lng: 21.5672981 },
    { lat: 49.2096635, lng: 21.5672126 },
    { lat: 49.2095937, lng: 21.5668469 },
    { lat: 49.2096424, lng: 21.5667757 },
    { lat: 49.2093141, lng: 21.5655298 },
    { lat: 49.2097434, lng: 21.5640302 },
    { lat: 49.2092156, lng: 21.5604834 },
    { lat: 49.2092339, lng: 21.5598464 },
    { lat: 49.2091632, lng: 21.5585701 },
    { lat: 49.2090865, lng: 21.5581628 },
    { lat: 49.20876, lng: 21.5578318 },
    { lat: 49.2087663, lng: 21.5568139 },
    { lat: 49.2086998, lng: 21.5556427 },
    { lat: 49.2084522, lng: 21.5548335 },
    { lat: 49.2083347, lng: 21.5536513 },
    { lat: 49.2079862, lng: 21.5524993 },
    { lat: 49.2076002, lng: 21.5487329 },
    { lat: 49.2068716, lng: 21.5463005 },
    { lat: 49.2070856, lng: 21.5461062 },
    { lat: 49.2066904, lng: 21.5451805 },
    { lat: 49.2065069, lng: 21.5444553 },
    { lat: 49.2062287, lng: 21.5438773 },
    { lat: 49.2061098, lng: 21.543136 },
    { lat: 49.2061085, lng: 21.5426871 },
    { lat: 49.2060017, lng: 21.5424106 },
    { lat: 49.2058701, lng: 21.5422501 },
    { lat: 49.2055848, lng: 21.5396269 },
    { lat: 49.2046802, lng: 21.535997 },
  ],
  Beňadikovce: [
    { lat: 49.2238575, lng: 21.5900629 },
    { lat: 49.2249977, lng: 21.5887425 },
    { lat: 49.2254042, lng: 21.5873788 },
    { lat: 49.22533, lng: 21.5870125 },
    { lat: 49.2253873, lng: 21.5868112 },
    { lat: 49.2256483, lng: 21.5865233 },
    { lat: 49.225438, lng: 21.5860816 },
    { lat: 49.2265253, lng: 21.5846835 },
    { lat: 49.2267897, lng: 21.5844828 },
    { lat: 49.2272898, lng: 21.5843007 },
    { lat: 49.2273509, lng: 21.5839901 },
    { lat: 49.2281617, lng: 21.5838973 },
    { lat: 49.2284991, lng: 21.5837732 },
    { lat: 49.2287666, lng: 21.5835248 },
    { lat: 49.2288538, lng: 21.583345 },
    { lat: 49.2291274, lng: 21.5832347 },
    { lat: 49.2300722, lng: 21.5821704 },
    { lat: 49.2301593, lng: 21.5819462 },
    { lat: 49.2306466, lng: 21.5814233 },
    { lat: 49.2309142, lng: 21.5812195 },
    { lat: 49.2310805, lng: 21.5808074 },
    { lat: 49.2310764, lng: 21.5804149 },
    { lat: 49.2314404, lng: 21.5801538 },
    { lat: 49.2315513, lng: 21.5799353 },
    { lat: 49.2322781, lng: 21.5792517 },
    { lat: 49.2325166, lng: 21.5788846 },
    { lat: 49.2334927, lng: 21.576448 },
    { lat: 49.2344055, lng: 21.574893 },
    { lat: 49.2345444, lng: 21.5745463 },
    { lat: 49.2346829, lng: 21.5739008 },
    { lat: 49.2352435, lng: 21.5723445 },
    { lat: 49.2355583, lng: 21.5716847 },
    { lat: 49.2360932, lng: 21.57102 },
    { lat: 49.2365126, lng: 21.5696845 },
    { lat: 49.2365706, lng: 21.5694233 },
    { lat: 49.2366502, lng: 21.568404 },
    { lat: 49.2369017, lng: 21.5675776 },
    { lat: 49.2374612, lng: 21.5666667 },
    { lat: 49.2376665, lng: 21.5664032 },
    { lat: 49.2378292, lng: 21.5663461 },
    { lat: 49.2380432, lng: 21.5659574 },
    { lat: 49.2380467, lng: 21.5657963 },
    { lat: 49.2381335, lng: 21.5657222 },
    { lat: 49.2381779, lng: 21.5654626 },
    { lat: 49.2383271, lng: 21.5652081 },
    { lat: 49.2383258, lng: 21.5650347 },
    { lat: 49.2385155, lng: 21.5646616 },
    { lat: 49.2386859, lng: 21.5645572 },
    { lat: 49.2388492, lng: 21.564566 },
    { lat: 49.2390388, lng: 21.5642642 },
    { lat: 49.2392305, lng: 21.5636728 },
    { lat: 49.239422, lng: 21.5636027 },
    { lat: 49.2396458, lng: 21.5633585 },
    { lat: 49.2398394, lng: 21.5634474 },
    { lat: 49.2402584, lng: 21.5632656 },
    { lat: 49.2407291, lng: 21.5631935 },
    { lat: 49.240928, lng: 21.5628724 },
    { lat: 49.2413751, lng: 21.5624033 },
    { lat: 49.2415633, lng: 21.5622981 },
    { lat: 49.242114, lng: 21.5604264 },
    { lat: 49.2424663, lng: 21.5595043 },
    { lat: 49.2428977, lng: 21.5591662 },
    { lat: 49.2427113, lng: 21.5583355 },
    { lat: 49.2430974, lng: 21.5578185 },
    { lat: 49.242974, lng: 21.5575867 },
    { lat: 49.2432479, lng: 21.5568466 },
    { lat: 49.2437839, lng: 21.5560922 },
    { lat: 49.243995, lng: 21.5560064 },
    { lat: 49.2445481, lng: 21.5555097 },
    { lat: 49.2451653, lng: 21.5548123 },
    { lat: 49.2465985, lng: 21.5531199 },
    { lat: 49.2461654, lng: 21.5516384 },
    { lat: 49.2454154, lng: 21.5518927 },
    { lat: 49.2447361, lng: 21.5488146 },
    { lat: 49.2444549, lng: 21.5478904 },
    { lat: 49.2440678, lng: 21.5474858 },
    { lat: 49.24391, lng: 21.5464336 },
    { lat: 49.2435693, lng: 21.5451133 },
    { lat: 49.2432953, lng: 21.5450538 },
    { lat: 49.2431602, lng: 21.5448492 },
    { lat: 49.2430526, lng: 21.5443021 },
    { lat: 49.242289, lng: 21.5424313 },
    { lat: 49.2417014, lng: 21.5412586 },
    { lat: 49.2409044, lng: 21.5393602 },
    { lat: 49.2407526, lng: 21.539328 },
    { lat: 49.2397527, lng: 21.5371373 },
    { lat: 49.2395031, lng: 21.536259 },
    { lat: 49.2394157, lng: 21.5357525 },
    { lat: 49.2396342, lng: 21.534487 },
    { lat: 49.2390585, lng: 21.5325796 },
    { lat: 49.239104, lng: 21.5320391 },
    { lat: 49.2388431, lng: 21.532166 },
    { lat: 49.2374572, lng: 21.5261549 },
    { lat: 49.2371815, lng: 21.5237984 },
    { lat: 49.2367506, lng: 21.5226074 },
    { lat: 49.2376741, lng: 21.522086 },
    { lat: 49.2367987, lng: 21.5185022 },
    { lat: 49.2366016, lng: 21.5163155 },
    { lat: 49.2367895, lng: 21.5157562 },
    { lat: 49.2366916, lng: 21.5154815 },
    { lat: 49.2368463, lng: 21.5151405 },
    { lat: 49.2368892, lng: 21.5148651 },
    { lat: 49.2369716, lng: 21.5147624 },
    { lat: 49.2368813, lng: 21.5145463 },
    { lat: 49.2370783, lng: 21.5141196 },
    { lat: 49.235245, lng: 21.510371 },
    { lat: 49.23434, lng: 21.510859 },
    { lat: 49.23386, lng: 21.510677 },
    { lat: 49.233646, lng: 21.510595 },
    { lat: 49.232231, lng: 21.51104 },
    { lat: 49.230306, lng: 21.511638 },
    { lat: 49.229261, lng: 21.511921 },
    { lat: 49.228262, lng: 21.511078 },
    { lat: 49.226398, lng: 21.5111 },
    { lat: 49.225733, lng: 21.510591 },
    { lat: 49.224523, lng: 21.510654 },
    { lat: 49.224433, lng: 21.510658 },
    { lat: 49.223529, lng: 21.511351 },
    { lat: 49.222696, lng: 21.511503 },
    { lat: 49.221521, lng: 21.511119 },
    { lat: 49.2212, lng: 21.510537 },
    { lat: 49.220951, lng: 21.510779 },
    { lat: 49.220676, lng: 21.511223 },
    { lat: 49.22029, lng: 21.511689 },
    { lat: 49.220028, lng: 21.512411 },
    { lat: 49.219845, lng: 21.513727 },
    { lat: 49.219796, lng: 21.514335 },
    { lat: 49.219762, lng: 21.514509 },
    { lat: 49.219696, lng: 21.51469 },
    { lat: 49.219455, lng: 21.515166 },
    { lat: 49.219368, lng: 21.515352 },
    { lat: 49.219343, lng: 21.515437 },
    { lat: 49.219271, lng: 21.515871 },
    { lat: 49.219114, lng: 21.516207 },
    { lat: 49.219012, lng: 21.516452 },
    { lat: 49.218951, lng: 21.516626 },
    { lat: 49.218887, lng: 21.516722 },
    { lat: 49.218748, lng: 21.516854 },
    { lat: 49.218554, lng: 21.517025 },
    { lat: 49.218433, lng: 21.517055 },
    { lat: 49.218312, lng: 21.517152 },
    { lat: 49.218164, lng: 21.517237 },
    { lat: 49.218112, lng: 21.517258 },
    { lat: 49.217903, lng: 21.5174 },
    { lat: 49.217639, lng: 21.517523 },
    { lat: 49.217378, lng: 21.51759 },
    { lat: 49.217238, lng: 21.517746 },
    { lat: 49.217019, lng: 21.517971 },
    { lat: 49.216469, lng: 21.518604 },
    { lat: 49.216242, lng: 21.518909 },
    { lat: 49.216021, lng: 21.519147 },
    { lat: 49.215823, lng: 21.519382 },
    { lat: 49.215653, lng: 21.519493 },
    { lat: 49.215484, lng: 21.519656 },
    { lat: 49.215227, lng: 21.519941 },
    { lat: 49.214992, lng: 21.520255 },
    { lat: 49.214801, lng: 21.520459 },
    { lat: 49.214553, lng: 21.520773 },
    { lat: 49.214294, lng: 21.521042 },
    { lat: 49.2140885, lng: 21.5220074 },
    { lat: 49.2137331, lng: 21.5230121 },
    { lat: 49.2131401, lng: 21.5249904 },
    { lat: 49.2136988, lng: 21.5308303 },
    { lat: 49.2140963, lng: 21.5328345 },
    { lat: 49.2151823, lng: 21.5366377 },
    { lat: 49.2149086, lng: 21.537741 },
    { lat: 49.2144736, lng: 21.5385612 },
    { lat: 49.2150142, lng: 21.5428908 },
    { lat: 49.2160487, lng: 21.5469171 },
    { lat: 49.2162588, lng: 21.5483115 },
    { lat: 49.2162451, lng: 21.5488659 },
    { lat: 49.2163371, lng: 21.5490542 },
    { lat: 49.2164837, lng: 21.5496736 },
    { lat: 49.2163422, lng: 21.5498405 },
    { lat: 49.2163664, lng: 21.5500203 },
    { lat: 49.2162518, lng: 21.5501447 },
    { lat: 49.2163662, lng: 21.5506087 },
    { lat: 49.2163457, lng: 21.550804 },
    { lat: 49.2162642, lng: 21.5509486 },
    { lat: 49.2165215, lng: 21.5528182 },
    { lat: 49.2165491, lng: 21.5532799 },
    { lat: 49.216891, lng: 21.5536034 },
    { lat: 49.2169034, lng: 21.5538016 },
    { lat: 49.2169195, lng: 21.5553782 },
    { lat: 49.2168363, lng: 21.5555444 },
    { lat: 49.2168551, lng: 21.5558556 },
    { lat: 49.2166652, lng: 21.5568277 },
    { lat: 49.216693, lng: 21.5570962 },
    { lat: 49.2169232, lng: 21.5578405 },
    { lat: 49.2171828, lng: 21.558431 },
    { lat: 49.2186481, lng: 21.5624733 },
    { lat: 49.2189154, lng: 21.5631454 },
    { lat: 49.2190412, lng: 21.5633384 },
    { lat: 49.2191029, lng: 21.5636913 },
    { lat: 49.2194588, lng: 21.5643106 },
    { lat: 49.2202989, lng: 21.5664346 },
    { lat: 49.2210239, lng: 21.569033 },
    { lat: 49.221377, lng: 21.5706022 },
    { lat: 49.2213377, lng: 21.5706886 },
    { lat: 49.2216351, lng: 21.5716227 },
    { lat: 49.222006, lng: 21.5725073 },
    { lat: 49.2220517, lng: 21.5728088 },
    { lat: 49.22255, lng: 21.5736953 },
    { lat: 49.2228446, lng: 21.5740587 },
    { lat: 49.223081, lng: 21.5747078 },
    { lat: 49.2234446, lng: 21.5751178 },
    { lat: 49.2235399, lng: 21.5757552 },
    { lat: 49.2238705, lng: 21.5762322 },
    { lat: 49.2240912, lng: 21.5769273 },
    { lat: 49.2241994, lng: 21.5775029 },
    { lat: 49.2242044, lng: 21.5777178 },
    { lat: 49.2236708, lng: 21.578925 },
    { lat: 49.2235939, lng: 21.579227 },
    { lat: 49.2235825, lng: 21.5811654 },
    { lat: 49.2233703, lng: 21.5817079 },
    { lat: 49.2232916, lng: 21.5821582 },
    { lat: 49.2231908, lng: 21.582331 },
    { lat: 49.2229753, lng: 21.5829989 },
    { lat: 49.2229105, lng: 21.5830513 },
    { lat: 49.222682, lng: 21.5837377 },
    { lat: 49.2226515, lng: 21.5842191 },
    { lat: 49.2227256, lng: 21.5846634 },
    { lat: 49.223079, lng: 21.5851538 },
    { lat: 49.2230543, lng: 21.585642 },
    { lat: 49.2228683, lng: 21.5867189 },
    { lat: 49.2229373, lng: 21.587345 },
    { lat: 49.2231049, lng: 21.5877463 },
    { lat: 49.2231539, lng: 21.5883402 },
    { lat: 49.2233551, lng: 21.5882923 },
    { lat: 49.2238575, lng: 21.5900629 },
  ],
  Šemetkovce: [
    { lat: 49.317601, lng: 21.691166 },
    { lat: 49.318658, lng: 21.689895 },
    { lat: 49.319168, lng: 21.689202 },
    { lat: 49.319735, lng: 21.688884 },
    { lat: 49.321705, lng: 21.687177 },
    { lat: 49.323063, lng: 21.685612 },
    { lat: 49.323493, lng: 21.684903 },
    { lat: 49.32446, lng: 21.684368 },
    { lat: 49.325191, lng: 21.684962 },
    { lat: 49.32536, lng: 21.683871 },
    { lat: 49.325535, lng: 21.684266 },
    { lat: 49.326485, lng: 21.683883 },
    { lat: 49.327949, lng: 21.682613 },
    { lat: 49.32826, lng: 21.681776 },
    { lat: 49.3287732, lng: 21.6803684 },
    { lat: 49.3302189, lng: 21.6795452 },
    { lat: 49.3307293, lng: 21.6790767 },
    { lat: 49.3347933, lng: 21.6760561 },
    { lat: 49.3345956, lng: 21.6759889 },
    { lat: 49.3342477, lng: 21.6754719 },
    { lat: 49.3336227, lng: 21.6754847 },
    { lat: 49.3335842, lng: 21.6753515 },
    { lat: 49.3329904, lng: 21.6746059 },
    { lat: 49.3328296, lng: 21.6742705 },
    { lat: 49.3328403, lng: 21.6732967 },
    { lat: 49.332502, lng: 21.6730126 },
    { lat: 49.3323705, lng: 21.6727273 },
    { lat: 49.3322271, lng: 21.6726624 },
    { lat: 49.3324192, lng: 21.6711907 },
    { lat: 49.3323961, lng: 21.6706226 },
    { lat: 49.33228, lng: 21.6705322 },
    { lat: 49.3321379, lng: 21.6699403 },
    { lat: 49.3318984, lng: 21.668191 },
    { lat: 49.3318612, lng: 21.6674775 },
    { lat: 49.3316958, lng: 21.6670158 },
    { lat: 49.33167, lng: 21.6667675 },
    { lat: 49.3311774, lng: 21.6665675 },
    { lat: 49.3310126, lng: 21.6657545 },
    { lat: 49.3309143, lng: 21.6656168 },
    { lat: 49.3307724, lng: 21.665651 },
    { lat: 49.3305423, lng: 21.6650395 },
    { lat: 49.3303361, lng: 21.6651109 },
    { lat: 49.3302056, lng: 21.6648588 },
    { lat: 49.330035, lng: 21.6648811 },
    { lat: 49.3298943, lng: 21.6647043 },
    { lat: 49.3294964, lng: 21.6645134 },
    { lat: 49.3294621, lng: 21.6642805 },
    { lat: 49.329035, lng: 21.6638386 },
    { lat: 49.3287456, lng: 21.6636557 },
    { lat: 49.3283522, lng: 21.6636315 },
    { lat: 49.3277625, lng: 21.6631643 },
    { lat: 49.3275859, lng: 21.6627002 },
    { lat: 49.3273093, lng: 21.6627465 },
    { lat: 49.3271887, lng: 21.662365 },
    { lat: 49.3269823, lng: 21.6622641 },
    { lat: 49.3267487, lng: 21.6615674 },
    { lat: 49.3264233, lng: 21.6614333 },
    { lat: 49.3262966, lng: 21.6614886 },
    { lat: 49.3261969, lng: 21.6613766 },
    { lat: 49.3260794, lng: 21.6610234 },
    { lat: 49.3259211, lng: 21.6608268 },
    { lat: 49.325864, lng: 21.6605473 },
    { lat: 49.325845, lng: 21.6602178 },
    { lat: 49.3259496, lng: 21.6598852 },
    { lat: 49.3256158, lng: 21.6593611 },
    { lat: 49.3255167, lng: 21.6590186 },
    { lat: 49.325422, lng: 21.6589338 },
    { lat: 49.3254433, lng: 21.6587647 },
    { lat: 49.3253461, lng: 21.6587085 },
    { lat: 49.3253479, lng: 21.6585641 },
    { lat: 49.325513, lng: 21.6583416 },
    { lat: 49.3251786, lng: 21.6574995 },
    { lat: 49.3251636, lng: 21.6575307 },
    { lat: 49.3251433, lng: 21.6575717 },
    { lat: 49.3249727, lng: 21.6573762 },
    { lat: 49.3242475, lng: 21.6572639 },
    { lat: 49.3237387, lng: 21.657506 },
    { lat: 49.3231661, lng: 21.6575391 },
    { lat: 49.3223521, lng: 21.6578041 },
    { lat: 49.3212599, lng: 21.6580176 },
    { lat: 49.3207973, lng: 21.6578818 },
    { lat: 49.32059, lng: 21.6579133 },
    { lat: 49.3198499, lng: 21.6567926 },
    { lat: 49.3195379, lng: 21.6564828 },
    { lat: 49.3190491, lng: 21.6556777 },
    { lat: 49.318314, lng: 21.6534574 },
    { lat: 49.3177592, lng: 21.6522865 },
    { lat: 49.3170876, lng: 21.6523259 },
    { lat: 49.3163601, lng: 21.6513866 },
    { lat: 49.3152625, lng: 21.6497787 },
    { lat: 49.3141043, lng: 21.6507872 },
    { lat: 49.3123253, lng: 21.65077 },
    { lat: 49.3114651, lng: 21.6509847 },
    { lat: 49.3115556, lng: 21.6516279 },
    { lat: 49.3114522, lng: 21.651673 },
    { lat: 49.3109757, lng: 21.6507757 },
    { lat: 49.3101076, lng: 21.6510363 },
    { lat: 49.3099572, lng: 21.6505931 },
    { lat: 49.3098687, lng: 21.6501549 },
    { lat: 49.3096077, lng: 21.6505287 },
    { lat: 49.3081287, lng: 21.6494979 },
    { lat: 49.3075335, lng: 21.6493248 },
    { lat: 49.3069475, lng: 21.6492903 },
    { lat: 49.3065723, lng: 21.6494324 },
    { lat: 49.3060444, lng: 21.6490896 },
    { lat: 49.3054661, lng: 21.6490427 },
    { lat: 49.3051157, lng: 21.6486468 },
    { lat: 49.3045468, lng: 21.6482021 },
    { lat: 49.3032917, lng: 21.6465574 },
    { lat: 49.3025548, lng: 21.6460196 },
    { lat: 49.3024477, lng: 21.6458854 },
    { lat: 49.3003253, lng: 21.6423345 },
    { lat: 49.299569, lng: 21.641238 },
    { lat: 49.299309, lng: 21.641668 },
    { lat: 49.298684, lng: 21.642716 },
    { lat: 49.298146, lng: 21.643781 },
    { lat: 49.297449, lng: 21.644747 },
    { lat: 49.297193, lng: 21.645183 },
    { lat: 49.29702, lng: 21.645753 },
    { lat: 49.296678, lng: 21.647212 },
    { lat: 49.296433, lng: 21.648493 },
    { lat: 49.296557, lng: 21.649742 },
    { lat: 49.297082, lng: 21.651072 },
    { lat: 49.297361, lng: 21.651854 },
    { lat: 49.297411, lng: 21.651958 },
    { lat: 49.297421, lng: 21.652023 },
    { lat: 49.297424, lng: 21.652063 },
    { lat: 49.298022, lng: 21.654739 },
    { lat: 49.2982, lng: 21.655348 },
    { lat: 49.298726, lng: 21.65739 },
    { lat: 49.298781, lng: 21.657949 },
    { lat: 49.298815, lng: 21.658553 },
    { lat: 49.298981, lng: 21.65923 },
    { lat: 49.299071, lng: 21.660167 },
    { lat: 49.298927, lng: 21.662177 },
    { lat: 49.299291, lng: 21.662516 },
    { lat: 49.298789, lng: 21.663832 },
    { lat: 49.29904, lng: 21.66411 },
    { lat: 49.299735, lng: 21.664542 },
    { lat: 49.301691, lng: 21.666318 },
    { lat: 49.303237, lng: 21.6673 },
    { lat: 49.304932, lng: 21.668146 },
    { lat: 49.305686, lng: 21.668345 },
    { lat: 49.306548, lng: 21.668803 },
    { lat: 49.30793, lng: 21.669625 },
    { lat: 49.30853, lng: 21.670478 },
    { lat: 49.308545, lng: 21.67052 },
    { lat: 49.309247, lng: 21.672336 },
    { lat: 49.309426, lng: 21.673666 },
    { lat: 49.309864, lng: 21.675378 },
    { lat: 49.309949, lng: 21.675695 },
    { lat: 49.309957, lng: 21.675749 },
    { lat: 49.310539, lng: 21.677371 },
    { lat: 49.311169, lng: 21.678367 },
    { lat: 49.311542, lng: 21.679236 },
    { lat: 49.311831, lng: 21.681137 },
    { lat: 49.311799, lng: 21.681155 },
    { lat: 49.311876, lng: 21.681326 },
    { lat: 49.313733, lng: 21.686205 },
    { lat: 49.313744, lng: 21.686245 },
    { lat: 49.314102, lng: 21.686985 },
    { lat: 49.314971, lng: 21.687758 },
    { lat: 49.314964, lng: 21.687785 },
    { lat: 49.315265, lng: 21.687988 },
    { lat: 49.315376, lng: 21.688121 },
    { lat: 49.315403, lng: 21.688164 },
    { lat: 49.315647, lng: 21.688588 },
    { lat: 49.31609, lng: 21.688937 },
    { lat: 49.316268, lng: 21.689218 },
    { lat: 49.316652, lng: 21.690201 },
    { lat: 49.31684, lng: 21.690528 },
    { lat: 49.316978, lng: 21.690767 },
    { lat: 49.317283, lng: 21.691023 },
    { lat: 49.317581, lng: 21.691165 },
    { lat: 49.317601, lng: 21.691166 },
  ],
  Hunkovce: [
    { lat: 49.3598988, lng: 21.6309708 },
    { lat: 49.3594841, lng: 21.6323845 },
    { lat: 49.3580096, lng: 21.6329771 },
    { lat: 49.3564644, lng: 21.6350054 },
    { lat: 49.3564267, lng: 21.6358558 },
    { lat: 49.3545268, lng: 21.6365548 },
    { lat: 49.3532448, lng: 21.6372764 },
    { lat: 49.3527822, lng: 21.6379637 },
    { lat: 49.3518529, lng: 21.6397752 },
    { lat: 49.351936, lng: 21.6417132 },
    { lat: 49.3517085, lng: 21.6428989 },
    { lat: 49.3514684, lng: 21.6436748 },
    { lat: 49.350161, lng: 21.6451898 },
    { lat: 49.3499898, lng: 21.6457661 },
    { lat: 49.3496465, lng: 21.6457969 },
    { lat: 49.3494671, lng: 21.6463192 },
    { lat: 49.3491415, lng: 21.6473722 },
    { lat: 49.3497766, lng: 21.6483489 },
    { lat: 49.3500601, lng: 21.649511 },
    { lat: 49.3509353, lng: 21.6507182 },
    { lat: 49.3504288, lng: 21.6531519 },
    { lat: 49.34992, lng: 21.6567085 },
    { lat: 49.3506293, lng: 21.6575609 },
    { lat: 49.3515502, lng: 21.6582562 },
    { lat: 49.3529498, lng: 21.6590998 },
    { lat: 49.353186, lng: 21.6598508 },
    { lat: 49.3537381, lng: 21.6611689 },
    { lat: 49.3543962, lng: 21.6624234 },
    { lat: 49.355516, lng: 21.6637287 },
    { lat: 49.3558706, lng: 21.6637438 },
    { lat: 49.356406, lng: 21.6646953 },
    { lat: 49.3570767, lng: 21.6657138 },
    { lat: 49.3573746, lng: 21.6666649 },
    { lat: 49.3575274, lng: 21.6669879 },
    { lat: 49.3581792, lng: 21.667506 },
    { lat: 49.3580724, lng: 21.667716 },
    { lat: 49.358017, lng: 21.6691232 },
    { lat: 49.3575581, lng: 21.6706721 },
    { lat: 49.357332, lng: 21.6708358 },
    { lat: 49.3581197, lng: 21.6720212 },
    { lat: 49.3590638, lng: 21.6727883 },
    { lat: 49.3593985, lng: 21.6728466 },
    { lat: 49.3594061, lng: 21.6730432 },
    { lat: 49.3605441, lng: 21.673662 },
    { lat: 49.360935, lng: 21.6737189 },
    { lat: 49.3623057, lng: 21.6741243 },
    { lat: 49.3626915, lng: 21.674 },
    { lat: 49.3634428, lng: 21.6739057 },
    { lat: 49.3637315, lng: 21.6739845 },
    { lat: 49.3641474, lng: 21.6738828 },
    { lat: 49.3648548, lng: 21.6740941 },
    { lat: 49.3652385, lng: 21.6739717 },
    { lat: 49.3662039, lng: 21.6740194 },
    { lat: 49.3671346, lng: 21.6742884 },
    { lat: 49.3681201, lng: 21.674795 },
    { lat: 49.3682986, lng: 21.674822 },
    { lat: 49.3683797, lng: 21.6747447 },
    { lat: 49.3685596, lng: 21.6747373 },
    { lat: 49.3685603, lng: 21.6746867 },
    { lat: 49.3689207, lng: 21.6747488 },
    { lat: 49.3694735, lng: 21.6750987 },
    { lat: 49.3706279, lng: 21.6756474 },
    { lat: 49.3706227, lng: 21.6757084 },
    { lat: 49.3708878, lng: 21.6758631 },
    { lat: 49.3711924, lng: 21.6763561 },
    { lat: 49.3714161, lng: 21.6764788 },
    { lat: 49.3720781, lng: 21.6766695 },
    { lat: 49.3727623, lng: 21.6766768 },
    { lat: 49.3721327, lng: 21.6738025 },
    { lat: 49.3716569, lng: 21.6700068 },
    { lat: 49.3714337, lng: 21.6695254 },
    { lat: 49.3724825, lng: 21.6684975 },
    { lat: 49.3725313, lng: 21.6683085 },
    { lat: 49.3723083, lng: 21.6677566 },
    { lat: 49.3741864, lng: 21.6650713 },
    { lat: 49.3751605, lng: 21.6641527 },
    { lat: 49.3757953, lng: 21.6634198 },
    { lat: 49.3760479, lng: 21.6629002 },
    { lat: 49.376175, lng: 21.6623174 },
    { lat: 49.3764173, lng: 21.6623346 },
    { lat: 49.3767241, lng: 21.6610322 },
    { lat: 49.3769576, lng: 21.6606459 },
    { lat: 49.3774035, lng: 21.6595798 },
    { lat: 49.3775181, lng: 21.6596577 },
    { lat: 49.3779979, lng: 21.65955 },
    { lat: 49.377528, lng: 21.6591437 },
    { lat: 49.3769211, lng: 21.6587104 },
    { lat: 49.3767993, lng: 21.6587394 },
    { lat: 49.376363, lng: 21.6583314 },
    { lat: 49.3760473, lng: 21.6581778 },
    { lat: 49.3750803, lng: 21.6573981 },
    { lat: 49.3747807, lng: 21.6575546 },
    { lat: 49.3745179, lng: 21.6573558 },
    { lat: 49.3743474, lng: 21.6569172 },
    { lat: 49.3741013, lng: 21.654929 },
    { lat: 49.374147, lng: 21.6514738 },
    { lat: 49.3744939, lng: 21.6508655 },
    { lat: 49.3753524, lng: 21.6486331 },
    { lat: 49.3747556, lng: 21.6442227 },
    { lat: 49.3742058, lng: 21.6410425 },
    { lat: 49.3732819, lng: 21.6379296 },
    { lat: 49.3731211, lng: 21.6363173 },
    { lat: 49.3724634, lng: 21.6342639 },
    { lat: 49.3722837, lng: 21.6339075 },
    { lat: 49.3721593, lng: 21.6337978 },
    { lat: 49.3720491, lng: 21.6335081 },
    { lat: 49.3718587, lng: 21.6332157 },
    { lat: 49.3716286, lng: 21.6330246 },
    { lat: 49.3711695, lng: 21.6321945 },
    { lat: 49.3709662, lng: 21.6319155 },
    { lat: 49.3704498, lng: 21.6317017 },
    { lat: 49.3703562, lng: 21.6315275 },
    { lat: 49.369895, lng: 21.6317797 },
    { lat: 49.3689777, lng: 21.6324177 },
    { lat: 49.3681719, lng: 21.6324898 },
    { lat: 49.3671228, lng: 21.6330369 },
    { lat: 49.365112, lng: 21.6325205 },
    { lat: 49.3643834, lng: 21.6322001 },
    { lat: 49.3640002, lng: 21.6323769 },
    { lat: 49.3632734, lng: 21.6328662 },
    { lat: 49.3622601, lng: 21.6332493 },
    { lat: 49.3616645, lng: 21.6327742 },
    { lat: 49.361624, lng: 21.6322849 },
    { lat: 49.3615429, lng: 21.6322294 },
    { lat: 49.3611722, lng: 21.6311019 },
    { lat: 49.3608682, lng: 21.6303451 },
    { lat: 49.36015, lng: 21.631028 },
    { lat: 49.3598988, lng: 21.6309708 },
  ],
  Cigla: [
    { lat: 49.347805, lng: 21.411693 },
    { lat: 49.3482788, lng: 21.4119042 },
    { lat: 49.34925, lng: 21.4113738 },
    { lat: 49.350065, lng: 21.4118208 },
    { lat: 49.3527771, lng: 21.4118859 },
    { lat: 49.3535846, lng: 21.4123323 },
    { lat: 49.3544938, lng: 21.4130349 },
    { lat: 49.3550677, lng: 21.4129668 },
    { lat: 49.3568623, lng: 21.4136653 },
    { lat: 49.357929, lng: 21.4139865 },
    { lat: 49.3580705, lng: 21.4140938 },
    { lat: 49.3580966, lng: 21.4142522 },
    { lat: 49.3592019, lng: 21.4136909 },
    { lat: 49.359456, lng: 21.4137624 },
    { lat: 49.3597055, lng: 21.4135627 },
    { lat: 49.3597299, lng: 21.4133817 },
    { lat: 49.3596845, lng: 21.4131501 },
    { lat: 49.3608651, lng: 21.4130096 },
    { lat: 49.3609546, lng: 21.4130735 },
    { lat: 49.3611669, lng: 21.4129395 },
    { lat: 49.3622584, lng: 21.41264 },
    { lat: 49.3632794, lng: 21.4126641 },
    { lat: 49.3645212, lng: 21.4124655 },
    { lat: 49.3650332, lng: 21.4123107 },
    { lat: 49.3661803, lng: 21.411105 },
    { lat: 49.3670594, lng: 21.4111535 },
    { lat: 49.367351, lng: 21.4109354 },
    { lat: 49.3679997, lng: 21.4102611 },
    { lat: 49.3693525, lng: 21.4103946 },
    { lat: 49.372018, lng: 21.410462 },
    { lat: 49.372019, lng: 21.409483 },
    { lat: 49.372123, lng: 21.408531 },
    { lat: 49.372313, lng: 21.407514 },
    { lat: 49.372165, lng: 21.406474 },
    { lat: 49.372191, lng: 21.406323 },
    { lat: 49.372328, lng: 21.406193 },
    { lat: 49.372398, lng: 21.405934 },
    { lat: 49.372353, lng: 21.404912 },
    { lat: 49.372325, lng: 21.404671 },
    { lat: 49.372251, lng: 21.404594 },
    { lat: 49.372219, lng: 21.404235 },
    { lat: 49.372086, lng: 21.404203 },
    { lat: 49.372131, lng: 21.403984 },
    { lat: 49.372089, lng: 21.403849 },
    { lat: 49.372087, lng: 21.403786 },
    { lat: 49.372132, lng: 21.403725 },
    { lat: 49.372153, lng: 21.403603 },
    { lat: 49.372252, lng: 21.403521 },
    { lat: 49.372348, lng: 21.403311 },
    { lat: 49.372313, lng: 21.403142 },
    { lat: 49.372193, lng: 21.402913 },
    { lat: 49.37214, lng: 21.402421 },
    { lat: 49.372176, lng: 21.402325 },
    { lat: 49.371948, lng: 21.400188 },
    { lat: 49.371939, lng: 21.399587 },
    { lat: 49.371807, lng: 21.398935 },
    { lat: 49.371832, lng: 21.39879 },
    { lat: 49.371773, lng: 21.398535 },
    { lat: 49.371676, lng: 21.398395 },
    { lat: 49.3711, lng: 21.398162 },
    { lat: 49.370004, lng: 21.398357 },
    { lat: 49.370005, lng: 21.398117 },
    { lat: 49.369724, lng: 21.397577 },
    { lat: 49.36937, lng: 21.397209 },
    { lat: 49.369032, lng: 21.396568 },
    { lat: 49.368878, lng: 21.395985 },
    { lat: 49.368698, lng: 21.395618 },
    { lat: 49.368874, lng: 21.394614 },
    { lat: 49.368273, lng: 21.394291 },
    { lat: 49.368272, lng: 21.394201 },
    { lat: 49.368236, lng: 21.394012 },
    { lat: 49.368033, lng: 21.393902 },
    { lat: 49.368031, lng: 21.393823 },
    { lat: 49.368212, lng: 21.393751 },
    { lat: 49.368249, lng: 21.39335 },
    { lat: 49.368169, lng: 21.392974 },
    { lat: 49.368253, lng: 21.392706 },
    { lat: 49.368408, lng: 21.392579 },
    { lat: 49.368596, lng: 21.392554 },
    { lat: 49.36879, lng: 21.392671 },
    { lat: 49.368955, lng: 21.392693 },
    { lat: 49.36904, lng: 21.392635 },
    { lat: 49.369006, lng: 21.392098 },
    { lat: 49.368785, lng: 21.391687 },
    { lat: 49.369172, lng: 21.391583 },
    { lat: 49.36936, lng: 21.390716 },
    { lat: 49.369075, lng: 21.390066 },
    { lat: 49.368912, lng: 21.389732 },
    { lat: 49.36905, lng: 21.389235 },
    { lat: 49.369108, lng: 21.388729 },
    { lat: 49.369011, lng: 21.388385 },
    { lat: 49.36954, lng: 21.387825 },
    { lat: 49.36935, lng: 21.387234 },
    { lat: 49.369352, lng: 21.386453 },
    { lat: 49.369236, lng: 21.385845 },
    { lat: 49.369302, lng: 21.385527 },
    { lat: 49.369545, lng: 21.385356 },
    { lat: 49.36963, lng: 21.385114 },
    { lat: 49.369544, lng: 21.384419 },
    { lat: 49.369804, lng: 21.383521 },
    { lat: 49.370097, lng: 21.382749 },
    { lat: 49.369905, lng: 21.381899 },
    { lat: 49.369529, lng: 21.381736 },
    { lat: 49.369285, lng: 21.381469 },
    { lat: 49.369139, lng: 21.381218 },
    { lat: 49.369287, lng: 21.381129 },
    { lat: 49.369261, lng: 21.380875 },
    { lat: 49.369342, lng: 21.380337 },
    { lat: 49.369239, lng: 21.380195 },
    { lat: 49.369076, lng: 21.379814 },
    { lat: 49.368921, lng: 21.379228 },
    { lat: 49.368798, lng: 21.379135 },
    { lat: 49.368545, lng: 21.37928 },
    { lat: 49.368294, lng: 21.379189 },
    { lat: 49.368232, lng: 21.378792 },
    { lat: 49.368342, lng: 21.378524 },
    { lat: 49.368321, lng: 21.378369 },
    { lat: 49.368154, lng: 21.378283 },
    { lat: 49.368058, lng: 21.377769 },
    { lat: 49.368205, lng: 21.377264 },
    { lat: 49.368536, lng: 21.377035 },
    { lat: 49.368638, lng: 21.376469 },
    { lat: 49.368608, lng: 21.376296 },
    { lat: 49.368695, lng: 21.376138 },
    { lat: 49.368735, lng: 21.375598 },
    { lat: 49.368617, lng: 21.375306 },
    { lat: 49.368629, lng: 21.37453 },
    { lat: 49.368573, lng: 21.374397 },
    { lat: 49.368596, lng: 21.374236 },
    { lat: 49.368492, lng: 21.373949 },
    { lat: 49.368534, lng: 21.373762 },
    { lat: 49.36843, lng: 21.373247 },
    { lat: 49.368268, lng: 21.373292 },
    { lat: 49.368218, lng: 21.373144 },
    { lat: 49.368515, lng: 21.372373 },
    { lat: 49.368433, lng: 21.372364 },
    { lat: 49.368146, lng: 21.372058 },
    { lat: 49.367859, lng: 21.372614 },
    { lat: 49.367753, lng: 21.372485 },
    { lat: 49.367279, lng: 21.372487 },
    { lat: 49.367055, lng: 21.372583 },
    { lat: 49.366643, lng: 21.372467 },
    { lat: 49.366312, lng: 21.372349 },
    { lat: 49.366169, lng: 21.372237 },
    { lat: 49.365819, lng: 21.371722 },
    { lat: 49.365531, lng: 21.3711 },
    { lat: 49.365285, lng: 21.370449 },
    { lat: 49.365146, lng: 21.370207 },
    { lat: 49.364723, lng: 21.369836 },
    { lat: 49.364561, lng: 21.369743 },
    { lat: 49.364213, lng: 21.369664 },
    { lat: 49.363949, lng: 21.369742 },
    { lat: 49.363748, lng: 21.369848 },
    { lat: 49.363424, lng: 21.369342 },
    { lat: 49.363214, lng: 21.368938 },
    { lat: 49.363081, lng: 21.369982 },
    { lat: 49.362752, lng: 21.371116 },
    { lat: 49.362658, lng: 21.37144 },
    { lat: 49.362474, lng: 21.372052 },
    { lat: 49.361975, lng: 21.373595 },
    { lat: 49.361766, lng: 21.374058 },
    { lat: 49.361289, lng: 21.374901 },
    { lat: 49.360723, lng: 21.375048 },
    { lat: 49.360363, lng: 21.375923 },
    { lat: 49.359637, lng: 21.376902 },
    { lat: 49.35946, lng: 21.37738 },
    { lat: 49.359408, lng: 21.378209 },
    { lat: 49.359393, lng: 21.378438 },
    { lat: 49.359337, lng: 21.379818 },
    { lat: 49.358943, lng: 21.381065 },
    { lat: 49.35803, lng: 21.381349 },
    { lat: 49.357507, lng: 21.382881 },
    { lat: 49.357177, lng: 21.382997 },
    { lat: 49.357279, lng: 21.384927 },
    { lat: 49.356733, lng: 21.38627 },
    { lat: 49.3567, lng: 21.386353 },
    { lat: 49.356214, lng: 21.386598 },
    { lat: 49.356066, lng: 21.386525 },
    { lat: 49.355934, lng: 21.38653 },
    { lat: 49.355667, lng: 21.386633 },
    { lat: 49.355695, lng: 21.387029 },
    { lat: 49.3559, lng: 21.388816 },
    { lat: 49.35607, lng: 21.390315 },
    { lat: 49.355819, lng: 21.390977 },
    { lat: 49.355429, lng: 21.392613 },
    { lat: 49.355112, lng: 21.393752 },
    { lat: 49.355105, lng: 21.393775 },
    { lat: 49.355071, lng: 21.3939 },
    { lat: 49.355064, lng: 21.393921 },
    { lat: 49.355063, lng: 21.393926 },
    { lat: 49.355029, lng: 21.394053 },
    { lat: 49.355025, lng: 21.39407 },
    { lat: 49.354997, lng: 21.39407 },
    { lat: 49.354965, lng: 21.394095 },
    { lat: 49.354938, lng: 21.394132 },
    { lat: 49.354855, lng: 21.394253 },
    { lat: 49.354796, lng: 21.394309 },
    { lat: 49.354737, lng: 21.394354 },
    { lat: 49.354692, lng: 21.394388 },
    { lat: 49.354643, lng: 21.394396 },
    { lat: 49.354639, lng: 21.394388 },
    { lat: 49.354594, lng: 21.394385 },
    { lat: 49.354521, lng: 21.39438 },
    { lat: 49.354465, lng: 21.39442 },
    { lat: 49.354433, lng: 21.394486 },
    { lat: 49.354445, lng: 21.394537 },
    { lat: 49.3546, lng: 21.394706 },
    { lat: 49.354609, lng: 21.394726 },
    { lat: 49.354612, lng: 21.394764 },
    { lat: 49.354611, lng: 21.394802 },
    { lat: 49.354603, lng: 21.394849 },
    { lat: 49.354589, lng: 21.394891 },
    { lat: 49.354453, lng: 21.395089 },
    { lat: 49.354405, lng: 21.395124 },
    { lat: 49.354311, lng: 21.395173 },
    { lat: 49.354265, lng: 21.395192 },
    { lat: 49.354125, lng: 21.395235 },
    { lat: 49.353926, lng: 21.395317 },
    { lat: 49.353918, lng: 21.39534 },
    { lat: 49.353637, lng: 21.396079 },
    { lat: 49.353586, lng: 21.39713 },
    { lat: 49.353332, lng: 21.39909 },
    { lat: 49.353371, lng: 21.400683 },
    { lat: 49.353154, lng: 21.402123 },
    { lat: 49.352998, lng: 21.402646 },
    { lat: 49.352754, lng: 21.403289 },
    { lat: 49.352581, lng: 21.40374 },
    { lat: 49.351191, lng: 21.4049 },
    { lat: 49.351005, lng: 21.405045 },
    { lat: 49.350453, lng: 21.40624 },
    { lat: 49.350277, lng: 21.406424 },
    { lat: 49.349695, lng: 21.407202 },
    { lat: 49.349161, lng: 21.407976 },
    { lat: 49.348391, lng: 21.409148 },
    { lat: 49.348322, lng: 21.410274 },
    { lat: 49.347805, lng: 21.411693 },
  ],
  Medvedie: [
    { lat: 49.4112015, lng: 21.6202354 },
    { lat: 49.4111474, lng: 21.6200994 },
    { lat: 49.4103428, lng: 21.6204586 },
    { lat: 49.4098595, lng: 21.6210439 },
    { lat: 49.409769, lng: 21.6211536 },
    { lat: 49.4084333, lng: 21.6223285 },
    { lat: 49.4069343, lng: 21.6232548 },
    { lat: 49.4066469, lng: 21.6234969 },
    { lat: 49.4063306, lng: 21.6237635 },
    { lat: 49.4042782, lng: 21.625825 },
    { lat: 49.4039536, lng: 21.626151 },
    { lat: 49.4038894, lng: 21.6262155 },
    { lat: 49.4025867, lng: 21.6269517 },
    { lat: 49.4020802, lng: 21.6274375 },
    { lat: 49.4014818, lng: 21.6284249 },
    { lat: 49.4010567, lng: 21.6291265 },
    { lat: 49.4008896, lng: 21.6288624 },
    { lat: 49.4004301, lng: 21.6291685 },
    { lat: 49.3999837, lng: 21.6291797 },
    { lat: 49.3994335, lng: 21.6295819 },
    { lat: 49.3993326, lng: 21.6298065 },
    { lat: 49.3991222, lng: 21.6299211 },
    { lat: 49.3987587, lng: 21.6299567 },
    { lat: 49.3978251, lng: 21.6297163 },
    { lat: 49.3972298, lng: 21.6290871 },
    { lat: 49.397346, lng: 21.6288135 },
    { lat: 49.3972044, lng: 21.6286506 },
    { lat: 49.3967577, lng: 21.6273614 },
    { lat: 49.3969916, lng: 21.6271139 },
    { lat: 49.3969009, lng: 21.6268419 },
    { lat: 49.3969946, lng: 21.6266449 },
    { lat: 49.3968571, lng: 21.6262286 },
    { lat: 49.3967787, lng: 21.6261546 },
    { lat: 49.3966769, lng: 21.6256042 },
    { lat: 49.3965005, lng: 21.6258929 },
    { lat: 49.3963771, lng: 21.6257849 },
    { lat: 49.3963301, lng: 21.6260418 },
    { lat: 49.3956252, lng: 21.6266756 },
    { lat: 49.3954818, lng: 21.6267 },
    { lat: 49.3953579, lng: 21.6265292 },
    { lat: 49.3951042, lng: 21.6268282 },
    { lat: 49.3948462, lng: 21.6265742 },
    { lat: 49.3947812, lng: 21.626703 },
    { lat: 49.3941581, lng: 21.6267003 },
    { lat: 49.3940763, lng: 21.6265098 },
    { lat: 49.3939826, lng: 21.6265389 },
    { lat: 49.3937292, lng: 21.6269799 },
    { lat: 49.3934263, lng: 21.6269697 },
    { lat: 49.3934051, lng: 21.6270588 },
    { lat: 49.3933537, lng: 21.6272744 },
    { lat: 49.3932978, lng: 21.6281089 },
    { lat: 49.3930842, lng: 21.6286513 },
    { lat: 49.3930487, lng: 21.6289244 },
    { lat: 49.3930715, lng: 21.6293426 },
    { lat: 49.3928382, lng: 21.6296554 },
    { lat: 49.3923708, lng: 21.6300472 },
    { lat: 49.3919455, lng: 21.6305352 },
    { lat: 49.391406, lng: 21.6314208 },
    { lat: 49.3910271, lng: 21.6321687 },
    { lat: 49.3909243, lng: 21.6325223 },
    { lat: 49.3906437, lng: 21.6331217 },
    { lat: 49.3903901, lng: 21.6338919 },
    { lat: 49.3903384, lng: 21.634383 },
    { lat: 49.3904967, lng: 21.6361101 },
    { lat: 49.3905215, lng: 21.637142 },
    { lat: 49.39105, lng: 21.6380883 },
    { lat: 49.3910483, lng: 21.6383967 },
    { lat: 49.3911469, lng: 21.6385912 },
    { lat: 49.3913712, lng: 21.6396024 },
    { lat: 49.3916825, lng: 21.6405792 },
    { lat: 49.3919534, lng: 21.6416467 },
    { lat: 49.3919501, lng: 21.6418646 },
    { lat: 49.3920303, lng: 21.6420845 },
    { lat: 49.3917884, lng: 21.6429066 },
    { lat: 49.3911984, lng: 21.6442944 },
    { lat: 49.3909436, lng: 21.6447718 },
    { lat: 49.3905643, lng: 21.6451765 },
    { lat: 49.3901738, lng: 21.6458756 },
    { lat: 49.3899338, lng: 21.6465228 },
    { lat: 49.3898222, lng: 21.6471358 },
    { lat: 49.3892114, lng: 21.6491653 },
    { lat: 49.3882033, lng: 21.651978 },
    { lat: 49.3880287, lng: 21.6522126 },
    { lat: 49.3883114, lng: 21.652356 },
    { lat: 49.3893881, lng: 21.6524876 },
    { lat: 49.3893507, lng: 21.6534274 },
    { lat: 49.3893851, lng: 21.6535422 },
    { lat: 49.3894547, lng: 21.6553623 },
    { lat: 49.3894309, lng: 21.6558174 },
    { lat: 49.3895191, lng: 21.6559594 },
    { lat: 49.3900393, lng: 21.6560976 },
    { lat: 49.3904766, lng: 21.6565436 },
    { lat: 49.3906829, lng: 21.6565207 },
    { lat: 49.3909447, lng: 21.6570493 },
    { lat: 49.3916022, lng: 21.6577396 },
    { lat: 49.3923977, lng: 21.6581253 },
    { lat: 49.3925895, lng: 21.6583135 },
    { lat: 49.3927788, lng: 21.6586969 },
    { lat: 49.3933178, lng: 21.6590802 },
    { lat: 49.3936229, lng: 21.6591359 },
    { lat: 49.3938038, lng: 21.6590436 },
    { lat: 49.3951458, lng: 21.659876 },
    { lat: 49.3957587, lng: 21.6604099 },
    { lat: 49.3957912, lng: 21.6603656 },
    { lat: 49.3959554, lng: 21.6607022 },
    { lat: 49.396442, lng: 21.6610094 },
    { lat: 49.396601, lng: 21.6613204 },
    { lat: 49.3968081, lng: 21.6615373 },
    { lat: 49.3969566, lng: 21.6615809 },
    { lat: 49.3971419, lng: 21.6615212 },
    { lat: 49.3972872, lng: 21.6615769 },
    { lat: 49.3972864, lng: 21.6616265 },
    { lat: 49.3974431, lng: 21.6616411 },
    { lat: 49.3976032, lng: 21.661485 },
    { lat: 49.3981937, lng: 21.6616535 },
    { lat: 49.3984083, lng: 21.6618071 },
    { lat: 49.3992457, lng: 21.6627042 },
    { lat: 49.3995149, lng: 21.6627998 },
    { lat: 49.4000271, lng: 21.6626073 },
    { lat: 49.4005088, lng: 21.6625793 },
    { lat: 49.400892, lng: 21.6626912 },
    { lat: 49.402639, lng: 21.6628174 },
    { lat: 49.4029917, lng: 21.6629282 },
    { lat: 49.4031131, lng: 21.6629622 },
    { lat: 49.4031918, lng: 21.6629615 },
    { lat: 49.4036266, lng: 21.6634042 },
    { lat: 49.4041179, lng: 21.6635478 },
    { lat: 49.4043736, lng: 21.6637795 },
    { lat: 49.4043649, lng: 21.6638335 },
    { lat: 49.4046502, lng: 21.6642759 },
    { lat: 49.4046483, lng: 21.6638871 },
    { lat: 49.4046445, lng: 21.6634416 },
    { lat: 49.4046872, lng: 21.6632709 },
    { lat: 49.4046035, lng: 21.6605012 },
    { lat: 49.4042369, lng: 21.659965 },
    { lat: 49.4042775, lng: 21.6586773 },
    { lat: 49.4037044, lng: 21.6585778 },
    { lat: 49.4033055, lng: 21.6570937 },
    { lat: 49.4042207, lng: 21.65704 },
    { lat: 49.4040233, lng: 21.6557696 },
    { lat: 49.4040697, lng: 21.6552367 },
    { lat: 49.4039418, lng: 21.6548309 },
    { lat: 49.4040785, lng: 21.6532818 },
    { lat: 49.4040059, lng: 21.6530043 },
    { lat: 49.4040074, lng: 21.6528128 },
    { lat: 49.4041171, lng: 21.6525826 },
    { lat: 49.4041815, lng: 21.6521851 },
    { lat: 49.4043758, lng: 21.6517531 },
    { lat: 49.4046221, lng: 21.6510024 },
    { lat: 49.4048591, lng: 21.6504954 },
    { lat: 49.4051053, lng: 21.6501586 },
    { lat: 49.4055476, lng: 21.6487568 },
    { lat: 49.4056284, lng: 21.6484136 },
    { lat: 49.405635, lng: 21.6481202 },
    { lat: 49.4058334, lng: 21.6475589 },
    { lat: 49.4059191, lng: 21.6471063 },
    { lat: 49.4060425, lng: 21.6468488 },
    { lat: 49.4074079, lng: 21.646099 },
    { lat: 49.4087399, lng: 21.6440678 },
    { lat: 49.4095496, lng: 21.6421598 },
    { lat: 49.4097066, lng: 21.6412738 },
    { lat: 49.4107667, lng: 21.6402127 },
    { lat: 49.4112421, lng: 21.6399515 },
    { lat: 49.411483, lng: 21.6392148 },
    { lat: 49.4122665, lng: 21.6384512 },
    { lat: 49.4123452, lng: 21.6382006 },
    { lat: 49.4127484, lng: 21.6374772 },
    { lat: 49.4130274, lng: 21.637683 },
    { lat: 49.4129219, lng: 21.6378169 },
    { lat: 49.413472, lng: 21.6383175 },
    { lat: 49.413667, lng: 21.6381222 },
    { lat: 49.4138852, lng: 21.6383272 },
    { lat: 49.4140948, lng: 21.6377225 },
    { lat: 49.4138892, lng: 21.6373212 },
    { lat: 49.4148626, lng: 21.636712 },
    { lat: 49.4144727, lng: 21.6359053 },
    { lat: 49.4139582, lng: 21.6351803 },
    { lat: 49.413571, lng: 21.6349218 },
    { lat: 49.4133314, lng: 21.6346405 },
    { lat: 49.4131111, lng: 21.6339845 },
    { lat: 49.4130081, lng: 21.6334243 },
    { lat: 49.4128257, lng: 21.6332098 },
    { lat: 49.412716, lng: 21.6328584 },
    { lat: 49.4124587, lng: 21.6327434 },
    { lat: 49.4123142, lng: 21.6323957 },
    { lat: 49.4122934, lng: 21.6320055 },
    { lat: 49.4124027, lng: 21.6318019 },
    { lat: 49.4123834, lng: 21.6317501 },
    { lat: 49.4118174, lng: 21.6315399 },
    { lat: 49.4117545, lng: 21.6313351 },
    { lat: 49.4117296, lng: 21.630879 },
    { lat: 49.4114469, lng: 21.6304091 },
    { lat: 49.4112725, lng: 21.6280183 },
    { lat: 49.4111311, lng: 21.6269577 },
    { lat: 49.4111814, lng: 21.6229891 },
    { lat: 49.4112015, lng: 21.6202354 },
  ],
  VyšnáJedľová: [
    { lat: 49.3674836, lng: 21.5304538 },
    { lat: 49.3674049, lng: 21.529217 },
    { lat: 49.367086, lng: 21.527962 },
    { lat: 49.366446, lng: 21.5270099 },
    { lat: 49.3653027, lng: 21.5279878 },
    { lat: 49.3639321, lng: 21.5296487 },
    { lat: 49.3626216, lng: 21.5310125 },
    { lat: 49.361864, lng: 21.5313147 },
    { lat: 49.3607428, lng: 21.5310862 },
    { lat: 49.3597048, lng: 21.5314171 },
    { lat: 49.3590592, lng: 21.5320227 },
    { lat: 49.3582964, lng: 21.5321401 },
    { lat: 49.3576, lng: 21.532373 },
    { lat: 49.3569891, lng: 21.5320502 },
    { lat: 49.3563153, lng: 21.5320131 },
    { lat: 49.3539514, lng: 21.5315624 },
    { lat: 49.3531295, lng: 21.5315722 },
    { lat: 49.3513726, lng: 21.5310613 },
    { lat: 49.3499233, lng: 21.5308491 },
    { lat: 49.3486748, lng: 21.5308142 },
    { lat: 49.3482209, lng: 21.5312352 },
    { lat: 49.3473849, lng: 21.5323454 },
    { lat: 49.3454587, lng: 21.5346155 },
    { lat: 49.3433075, lng: 21.5373703 },
    { lat: 49.3428958, lng: 21.5380107 },
    { lat: 49.3420068, lng: 21.5387197 },
    { lat: 49.3407145, lng: 21.540088 },
    { lat: 49.3378536, lng: 21.5428471 },
    { lat: 49.3363776, lng: 21.544405 },
    { lat: 49.3386053, lng: 21.5479484 },
    { lat: 49.3386935, lng: 21.5481677 },
    { lat: 49.3407213, lng: 21.5514467 },
    { lat: 49.3412554, lng: 21.5517231 },
    { lat: 49.3425276, lng: 21.5526827 },
    { lat: 49.3436348, lng: 21.5530535 },
    { lat: 49.344979, lng: 21.5538563 },
    { lat: 49.3450458, lng: 21.5539926 },
    { lat: 49.3464538, lng: 21.5555453 },
    { lat: 49.3473041, lng: 21.5568278 },
    { lat: 49.3477763, lng: 21.5573789 },
    { lat: 49.3488809, lng: 21.5582653 },
    { lat: 49.350646, lng: 21.5586702 },
    { lat: 49.3515048, lng: 21.558487 },
    { lat: 49.3526926, lng: 21.5588889 },
    { lat: 49.3537769, lng: 21.5595306 },
    { lat: 49.3547095, lng: 21.5602177 },
    { lat: 49.3557919, lng: 21.559541 },
    { lat: 49.3563137, lng: 21.5589284 },
    { lat: 49.3606873, lng: 21.5559064 },
    { lat: 49.3613994, lng: 21.5554663 },
    { lat: 49.3624437, lng: 21.555111 },
    { lat: 49.3634683, lng: 21.5546621 },
    { lat: 49.3633985, lng: 21.5539354 },
    { lat: 49.3633011, lng: 21.5533106 },
    { lat: 49.3642586, lng: 21.5526768 },
    { lat: 49.3647707, lng: 21.5520398 },
    { lat: 49.3652384, lng: 21.550826 },
    { lat: 49.3652521, lng: 21.5506255 },
    { lat: 49.365973, lng: 21.5496997 },
    { lat: 49.3666808, lng: 21.5490241 },
    { lat: 49.3668883, lng: 21.5480009 },
    { lat: 49.3665203, lng: 21.5469029 },
    { lat: 49.3654589, lng: 21.5465633 },
    { lat: 49.3646277, lng: 21.5452839 },
    { lat: 49.3647242, lng: 21.5446372 },
    { lat: 49.364986, lng: 21.5443407 },
    { lat: 49.365269, lng: 21.542403 },
    { lat: 49.3655799, lng: 21.5413676 },
    { lat: 49.3654918, lng: 21.5406421 },
    { lat: 49.3658607, lng: 21.5377924 },
    { lat: 49.3666862, lng: 21.5362654 },
    { lat: 49.366778, lng: 21.5344419 },
    { lat: 49.366958, lng: 21.5334487 },
    { lat: 49.3672352, lng: 21.5327864 },
    { lat: 49.3678706, lng: 21.5315317 },
    { lat: 49.3674836, lng: 21.5304538 },
  ],
  Kuková: [
    { lat: 49.1013754, lng: 21.4619073 },
    { lat: 49.1026888, lng: 21.4624703 },
    { lat: 49.1066629, lng: 21.464072 },
    { lat: 49.1081354, lng: 21.4648834 },
    { lat: 49.1106047, lng: 21.4661233 },
    { lat: 49.1112423, lng: 21.4655222 },
    { lat: 49.1140984, lng: 21.4674543 },
    { lat: 49.1139783, lng: 21.4683642 },
    { lat: 49.1134169, lng: 21.4698441 },
    { lat: 49.1135707, lng: 21.4705761 },
    { lat: 49.1141911, lng: 21.4708263 },
    { lat: 49.1143613, lng: 21.4708005 },
    { lat: 49.1147895, lng: 21.4709009 },
    { lat: 49.1148466, lng: 21.4708126 },
    { lat: 49.1154904, lng: 21.4708572 },
    { lat: 49.1155965, lng: 21.4707912 },
    { lat: 49.1159536, lng: 21.4708485 },
    { lat: 49.1161658, lng: 21.4707564 },
    { lat: 49.1162457, lng: 21.4712643 },
    { lat: 49.1172697, lng: 21.470646 },
    { lat: 49.1176355, lng: 21.4702961 },
    { lat: 49.1183077, lng: 21.4698097 },
    { lat: 49.1188041, lng: 21.4691487 },
    { lat: 49.1190261, lng: 21.4689327 },
    { lat: 49.1199505, lng: 21.4680993 },
    { lat: 49.1207327, lng: 21.4675578 },
    { lat: 49.1211003, lng: 21.4671172 },
    { lat: 49.121648, lng: 21.4648524 },
    { lat: 49.1217466, lng: 21.4639808 },
    { lat: 49.1222059, lng: 21.4634414 },
    { lat: 49.122589, lng: 21.4632617 },
    { lat: 49.1226147, lng: 21.46283 },
    { lat: 49.12323, lng: 21.4626328 },
    { lat: 49.1233513, lng: 21.4624311 },
    { lat: 49.1235644, lng: 21.4622907 },
    { lat: 49.1237742, lng: 21.4625044 },
    { lat: 49.1241099, lng: 21.4622811 },
    { lat: 49.1245441, lng: 21.4623594 },
    { lat: 49.1247285, lng: 21.4621567 },
    { lat: 49.1255145, lng: 21.4617896 },
    { lat: 49.1262353, lng: 21.4616188 },
    { lat: 49.1270763, lng: 21.4610559 },
    { lat: 49.127249, lng: 21.4610371 },
    { lat: 49.1277258, lng: 21.4612268 },
    { lat: 49.1280941, lng: 21.4610825 },
    { lat: 49.1284823, lng: 21.4607353 },
    { lat: 49.1298415, lng: 21.4609312 },
    { lat: 49.13031, lng: 21.4607112 },
    { lat: 49.1306365, lng: 21.4607209 },
    { lat: 49.1310205, lng: 21.4606107 },
    { lat: 49.1314834, lng: 21.4607859 },
    { lat: 49.1320449, lng: 21.4607928 },
    { lat: 49.1324814, lng: 21.4606737 },
    { lat: 49.1328576, lng: 21.4607264 },
    { lat: 49.1342036, lng: 21.4600009 },
    { lat: 49.1345174, lng: 21.4599529 },
    { lat: 49.1348268, lng: 21.4597601 },
    { lat: 49.1357425, lng: 21.4595226 },
    { lat: 49.1374962, lng: 21.4586504 },
    { lat: 49.1379728, lng: 21.4575127 },
    { lat: 49.1388119, lng: 21.4564549 },
    { lat: 49.1393203, lng: 21.4560162 },
    { lat: 49.1400436, lng: 21.4560887 },
    { lat: 49.1402934, lng: 21.4562491 },
    { lat: 49.1405185, lng: 21.4566389 },
    { lat: 49.1417134, lng: 21.4545025 },
    { lat: 49.1420189, lng: 21.4538585 },
    { lat: 49.1429858, lng: 21.4524322 },
    { lat: 49.1435504, lng: 21.4517287 },
    { lat: 49.1440139, lng: 21.450793 },
    { lat: 49.1451993, lng: 21.4488366 },
    { lat: 49.145774, lng: 21.448098 },
    { lat: 49.145737, lng: 21.447647 },
    { lat: 49.145457, lng: 21.446697 },
    { lat: 49.145377, lng: 21.445857 },
    { lat: 49.145376, lng: 21.445837 },
    { lat: 49.145386, lng: 21.44577 },
    { lat: 49.145475, lng: 21.44522 },
    { lat: 49.145461, lng: 21.445208 },
    { lat: 49.145473, lng: 21.443959 },
    { lat: 49.145302, lng: 21.443596 },
    { lat: 49.14527, lng: 21.44332 },
    { lat: 49.145169, lng: 21.44297 },
    { lat: 49.145174, lng: 21.442401 },
    { lat: 49.145116, lng: 21.442123 },
    { lat: 49.144962, lng: 21.441949 },
    { lat: 49.144667, lng: 21.442135 },
    { lat: 49.143865, lng: 21.441458 },
    { lat: 49.143401, lng: 21.440309 },
    { lat: 49.143265, lng: 21.440107 },
    { lat: 49.143049, lng: 21.439534 },
    { lat: 49.142882, lng: 21.43882 },
    { lat: 49.142646, lng: 21.437742 },
    { lat: 49.142638, lng: 21.437535 },
    { lat: 49.142585, lng: 21.43729 },
    { lat: 49.142177, lng: 21.43626 },
    { lat: 49.141863, lng: 21.436081 },
    { lat: 49.141824, lng: 21.435966 },
    { lat: 49.141706, lng: 21.435984 },
    { lat: 49.141472, lng: 21.435972 },
    { lat: 49.141377, lng: 21.435891 },
    { lat: 49.141337, lng: 21.435917 },
    { lat: 49.141307, lng: 21.436085 },
    { lat: 49.141246, lng: 21.436073 },
    { lat: 49.141221, lng: 21.436184 },
    { lat: 49.141149, lng: 21.436219 },
    { lat: 49.141048, lng: 21.436177 },
    { lat: 49.140965, lng: 21.436334 },
    { lat: 49.140841, lng: 21.436241 },
    { lat: 49.140681, lng: 21.436248 },
    { lat: 49.140653, lng: 21.436375 },
    { lat: 49.140591, lng: 21.436353 },
    { lat: 49.140518, lng: 21.436473 },
    { lat: 49.140501, lng: 21.43647 },
    { lat: 49.140432, lng: 21.436408 },
    { lat: 49.140247, lng: 21.436405 },
    { lat: 49.140135, lng: 21.436286 },
    { lat: 49.140069, lng: 21.436509 },
    { lat: 49.139981, lng: 21.436558 },
    { lat: 49.139959, lng: 21.436563 },
    { lat: 49.139943, lng: 21.436566 },
    { lat: 49.139877, lng: 21.436586 },
    { lat: 49.13958, lng: 21.436466 },
    { lat: 49.139462, lng: 21.436474 },
    { lat: 49.139274, lng: 21.436964 },
    { lat: 49.13905, lng: 21.437182 },
    { lat: 49.138913, lng: 21.437167 },
    { lat: 49.138744, lng: 21.437571 },
    { lat: 49.138558, lng: 21.437808 },
    { lat: 49.138456, lng: 21.437665 },
    { lat: 49.138342, lng: 21.437648 },
    { lat: 49.138269, lng: 21.437898 },
    { lat: 49.13827, lng: 21.438381 },
    { lat: 49.138209, lng: 21.438518 },
    { lat: 49.13808, lng: 21.438625 },
    { lat: 49.137873, lng: 21.438417 },
    { lat: 49.137718, lng: 21.43852 },
    { lat: 49.137755, lng: 21.438768 },
    { lat: 49.13776, lng: 21.43895 },
    { lat: 49.13742, lng: 21.43929 },
    { lat: 49.137393, lng: 21.439232 },
    { lat: 49.136128, lng: 21.436738 },
    { lat: 49.13583, lng: 21.435999 },
    { lat: 49.135572, lng: 21.434859 },
    { lat: 49.135301, lng: 21.434025 },
    { lat: 49.134918, lng: 21.43307 },
    { lat: 49.1345432, lng: 21.4321127 },
    { lat: 49.1311718, lng: 21.4352153 },
    { lat: 49.1281903, lng: 21.4378829 },
    { lat: 49.1273836, lng: 21.4369128 },
    { lat: 49.1271044, lng: 21.4376426 },
    { lat: 49.1268316, lng: 21.4379735 },
    { lat: 49.12567, lng: 21.4386138 },
    { lat: 49.1252795, lng: 21.4390759 },
    { lat: 49.1249801, lng: 21.4396921 },
    { lat: 49.1242391, lng: 21.4405618 },
    { lat: 49.1236317, lng: 21.4410285 },
    { lat: 49.1229981, lng: 21.4417983 },
    { lat: 49.1227201, lng: 21.442025 },
    { lat: 49.1221811, lng: 21.4410559 },
    { lat: 49.1208772, lng: 21.4418738 },
    { lat: 49.1208281, lng: 21.4417758 },
    { lat: 49.1201848, lng: 21.4420537 },
    { lat: 49.1198807, lng: 21.4422736 },
    { lat: 49.1194309, lng: 21.4424777 },
    { lat: 49.1191093, lng: 21.4425608 },
    { lat: 49.1178852, lng: 21.4426423 },
    { lat: 49.117267, lng: 21.4421926 },
    { lat: 49.115963, lng: 21.4414257 },
    { lat: 49.1159805, lng: 21.4395022 },
    { lat: 49.1158467, lng: 21.4394682 },
    { lat: 49.1153275, lng: 21.4389878 },
    { lat: 49.1145909, lng: 21.4381899 },
    { lat: 49.1134001, lng: 21.4370509 },
    { lat: 49.1119957, lng: 21.4364601 },
    { lat: 49.1115992, lng: 21.4361396 },
    { lat: 49.1114103, lng: 21.4361046 },
    { lat: 49.111373, lng: 21.4360242 },
    { lat: 49.1110317, lng: 21.4358888 },
    { lat: 49.110802, lng: 21.4356195 },
    { lat: 49.1103759, lng: 21.4354723 },
    { lat: 49.1099493, lng: 21.4351582 },
    { lat: 49.1098356, lng: 21.4351576 },
    { lat: 49.1090837, lng: 21.4347211 },
    { lat: 49.1084306, lng: 21.4338114 },
    { lat: 49.1082498, lng: 21.4333611 },
    { lat: 49.1083907, lng: 21.4322889 },
    { lat: 49.1073113, lng: 21.4318918 },
    { lat: 49.1055023, lng: 21.4315853 },
    { lat: 49.105656, lng: 21.4308399 },
    { lat: 49.1055557, lng: 21.4306025 },
    { lat: 49.1056508, lng: 21.4304283 },
    { lat: 49.1054793, lng: 21.4301013 },
    { lat: 49.1055237, lng: 21.4299245 },
    { lat: 49.1054708, lng: 21.4291151 },
    { lat: 49.1053609, lng: 21.4286686 },
    { lat: 49.1051971, lng: 21.4284926 },
    { lat: 49.1050972, lng: 21.4281151 },
    { lat: 49.1051272, lng: 21.4277537 },
    { lat: 49.1050218, lng: 21.4276491 },
    { lat: 49.1049972, lng: 21.4274188 },
    { lat: 49.104867, lng: 21.4272398 },
    { lat: 49.1047658, lng: 21.4269325 },
    { lat: 49.1046312, lng: 21.4267538 },
    { lat: 49.104615, lng: 21.4265053 },
    { lat: 49.101439, lng: 21.424571 },
    { lat: 49.10001, lng: 21.425211 },
    { lat: 49.099871, lng: 21.425271 },
    { lat: 49.099654, lng: 21.425526 },
    { lat: 49.099693, lng: 21.425636 },
    { lat: 49.099523, lng: 21.425896 },
    { lat: 49.099525, lng: 21.426141 },
    { lat: 49.099476, lng: 21.426286 },
    { lat: 49.099379, lng: 21.426444 },
    { lat: 49.099228, lng: 21.426547 },
    { lat: 49.09898, lng: 21.426644 },
    { lat: 49.098893, lng: 21.426719 },
    { lat: 49.098819, lng: 21.42695 },
    { lat: 49.098658, lng: 21.426911 },
    { lat: 49.098518, lng: 21.426822 },
    { lat: 49.098365, lng: 21.426665 },
    { lat: 49.097773, lng: 21.427515 },
    { lat: 49.097645, lng: 21.427447 },
    { lat: 49.097568, lng: 21.427638 },
    { lat: 49.097235, lng: 21.428047 },
    { lat: 49.096974, lng: 21.428422 },
    { lat: 49.096873, lng: 21.428479 },
    { lat: 49.096596, lng: 21.428732 },
    { lat: 49.096212, lng: 21.428999 },
    { lat: 49.095916, lng: 21.429039 },
    { lat: 49.095819, lng: 21.429128 },
    { lat: 49.095737, lng: 21.429359 },
    { lat: 49.095722, lng: 21.42946 },
    { lat: 49.095555, lng: 21.429784 },
    { lat: 49.095341, lng: 21.430412 },
    { lat: 49.095255, lng: 21.430909 },
    { lat: 49.095134, lng: 21.431357 },
    { lat: 49.095133, lng: 21.431383 },
    { lat: 49.094843, lng: 21.43202 },
    { lat: 49.094766, lng: 21.432306 },
    { lat: 49.094681, lng: 21.432627 },
    { lat: 49.094696, lng: 21.43292 },
    { lat: 49.094564, lng: 21.433349 },
    { lat: 49.094504, lng: 21.434277 },
    { lat: 49.09447, lng: 21.434624 },
    { lat: 49.094495, lng: 21.434616 },
    { lat: 49.094495, lng: 21.434701 },
    { lat: 49.094504, lng: 21.435794 },
    { lat: 49.094487, lng: 21.435979 },
    { lat: 49.094513, lng: 21.436865 },
    { lat: 49.095214, lng: 21.437944 },
    { lat: 49.095227, lng: 21.437962 },
    { lat: 49.095213, lng: 21.437981 },
    { lat: 49.09499, lng: 21.438291 },
    { lat: 49.094862, lng: 21.43863 },
    { lat: 49.094725, lng: 21.43884 },
    { lat: 49.094671, lng: 21.439079 },
    { lat: 49.094567, lng: 21.439333 },
    { lat: 49.094455, lng: 21.4399 },
    { lat: 49.094465, lng: 21.440006 },
    { lat: 49.094422, lng: 21.440326 },
    { lat: 49.094375, lng: 21.440491 },
    { lat: 49.09431, lng: 21.440793 },
    { lat: 49.094268, lng: 21.441059 },
    { lat: 49.094212, lng: 21.44122 },
    { lat: 49.094192, lng: 21.441529 },
    { lat: 49.094098, lng: 21.441745 },
    { lat: 49.094064, lng: 21.441911 },
    { lat: 49.093988, lng: 21.442146 },
    { lat: 49.093905, lng: 21.44262 },
    { lat: 49.093807, lng: 21.442989 },
    { lat: 49.093765, lng: 21.443204 },
    { lat: 49.093614, lng: 21.443467 },
    { lat: 49.093584, lng: 21.443654 },
    { lat: 49.093409, lng: 21.444045 },
    { lat: 49.093309, lng: 21.444381 },
    { lat: 49.09314, lng: 21.445131 },
    { lat: 49.093149, lng: 21.445363 },
    { lat: 49.093024, lng: 21.445727 },
    { lat: 49.0929106, lng: 21.4459972 },
    { lat: 49.0929446, lng: 21.4462444 },
    { lat: 49.0932953, lng: 21.4467748 },
    { lat: 49.0934377, lng: 21.4471407 },
    { lat: 49.0936585, lng: 21.4482011 },
    { lat: 49.093811, lng: 21.4485664 },
    { lat: 49.0940803, lng: 21.4487798 },
    { lat: 49.0946793, lng: 21.448989 },
    { lat: 49.0953083, lng: 21.4493758 },
    { lat: 49.0957839, lng: 21.4497527 },
    { lat: 49.0962597, lng: 21.4506405 },
    { lat: 49.0964851, lng: 21.4505328 },
    { lat: 49.09671, lng: 21.4508031 },
    { lat: 49.0969063, lng: 21.4509367 },
    { lat: 49.0977305, lng: 21.4518967 },
    { lat: 49.0980022, lng: 21.4519886 },
    { lat: 49.0982834, lng: 21.4524889 },
    { lat: 49.0985414, lng: 21.4531602 },
    { lat: 49.0989783, lng: 21.4531772 },
    { lat: 49.0997172, lng: 21.4539017 },
    { lat: 49.0999899, lng: 21.454739 },
    { lat: 49.1004059, lng: 21.4545696 },
    { lat: 49.100609, lng: 21.4547466 },
    { lat: 49.1007368, lng: 21.4553082 },
    { lat: 49.1007557, lng: 21.4557313 },
    { lat: 49.1014627, lng: 21.4565201 },
    { lat: 49.1015615, lng: 21.4567903 },
    { lat: 49.1014967, lng: 21.4570751 },
    { lat: 49.1015518, lng: 21.4570327 },
    { lat: 49.1015609, lng: 21.458018 },
    { lat: 49.101428, lng: 21.4587433 },
    { lat: 49.1013394, lng: 21.4587557 },
    { lat: 49.1014615, lng: 21.4595938 },
    { lat: 49.1016782, lng: 21.4601987 },
    { lat: 49.1016401, lng: 21.4605919 },
    { lat: 49.1017346, lng: 21.4607141 },
    { lat: 49.1013754, lng: 21.4619073 },
  ],
  Korejovce: [
    { lat: 49.3934263, lng: 21.6269697 },
    { lat: 49.3920849, lng: 21.6250139 },
    { lat: 49.3916769, lng: 21.6245298 },
    { lat: 49.3910984, lng: 21.623692 },
    { lat: 49.3909085, lng: 21.6232893 },
    { lat: 49.3902164, lng: 21.6221666 },
    { lat: 49.3895086, lng: 21.6212566 },
    { lat: 49.3894748, lng: 21.62121 },
    { lat: 49.3890443, lng: 21.6216706 },
    { lat: 49.388441, lng: 21.6217866 },
    { lat: 49.3877967, lng: 21.622249 },
    { lat: 49.3868497, lng: 21.6233655 },
    { lat: 49.3864904, lng: 21.623715 },
    { lat: 49.3852909, lng: 21.6247711 },
    { lat: 49.3851978, lng: 21.6256407 },
    { lat: 49.3850759, lng: 21.6259165 },
    { lat: 49.3848513, lng: 21.6260977 },
    { lat: 49.3844982, lng: 21.6262643 },
    { lat: 49.3842349, lng: 21.626591 },
    { lat: 49.3834798, lng: 21.6271131 },
    { lat: 49.3831277, lng: 21.6271909 },
    { lat: 49.3825217, lng: 21.6276175 },
    { lat: 49.3817408, lng: 21.6288412 },
    { lat: 49.3815053, lng: 21.6290737 },
    { lat: 49.3791713, lng: 21.6301549 },
    { lat: 49.3780753, lng: 21.6302196 },
    { lat: 49.3774311, lng: 21.6303995 },
    { lat: 49.3767559, lng: 21.6303459 },
    { lat: 49.3762249, lng: 21.6304676 },
    { lat: 49.3756048, lng: 21.6304633 },
    { lat: 49.3745298, lng: 21.6301473 },
    { lat: 49.373883, lng: 21.630102 },
    { lat: 49.3734926, lng: 21.6299382 },
    { lat: 49.3730787, lng: 21.6299358 },
    { lat: 49.372494, lng: 21.6298209 },
    { lat: 49.3717346, lng: 21.6295728 },
    { lat: 49.3715548, lng: 21.6296623 },
    { lat: 49.3714748, lng: 21.6299002 },
    { lat: 49.3713381, lng: 21.6300458 },
    { lat: 49.3706859, lng: 21.6305849 },
    { lat: 49.3703738, lng: 21.6314715 },
    { lat: 49.3703562, lng: 21.6315275 },
    { lat: 49.3704498, lng: 21.6317017 },
    { lat: 49.3709662, lng: 21.6319155 },
    { lat: 49.3711695, lng: 21.6321945 },
    { lat: 49.3716286, lng: 21.6330246 },
    { lat: 49.3718587, lng: 21.6332157 },
    { lat: 49.3720491, lng: 21.6335081 },
    { lat: 49.3721593, lng: 21.6337978 },
    { lat: 49.3722837, lng: 21.6339075 },
    { lat: 49.3724634, lng: 21.6342639 },
    { lat: 49.3731211, lng: 21.6363173 },
    { lat: 49.3732819, lng: 21.6379296 },
    { lat: 49.3742058, lng: 21.6410425 },
    { lat: 49.3747556, lng: 21.6442227 },
    { lat: 49.3753524, lng: 21.6486331 },
    { lat: 49.3744939, lng: 21.6508655 },
    { lat: 49.374147, lng: 21.6514738 },
    { lat: 49.3741013, lng: 21.654929 },
    { lat: 49.3743474, lng: 21.6569172 },
    { lat: 49.3745179, lng: 21.6573558 },
    { lat: 49.3747807, lng: 21.6575546 },
    { lat: 49.3750803, lng: 21.6573981 },
    { lat: 49.3760473, lng: 21.6581778 },
    { lat: 49.376363, lng: 21.6583314 },
    { lat: 49.3767993, lng: 21.6587394 },
    { lat: 49.3769211, lng: 21.6587104 },
    { lat: 49.377528, lng: 21.6591437 },
    { lat: 49.3779979, lng: 21.65955 },
    { lat: 49.3788502, lng: 21.6594523 },
    { lat: 49.3792279, lng: 21.6592818 },
    { lat: 49.3795403, lng: 21.6589789 },
    { lat: 49.3809043, lng: 21.6581317 },
    { lat: 49.381584, lng: 21.6576417 },
    { lat: 49.3818311, lng: 21.6573844 },
    { lat: 49.3822786, lng: 21.6572033 },
    { lat: 49.3825929, lng: 21.656974 },
    { lat: 49.3835815, lng: 21.6560501 },
    { lat: 49.3846218, lng: 21.6549219 },
    { lat: 49.3850448, lng: 21.6545982 },
    { lat: 49.3855106, lng: 21.6540151 },
    { lat: 49.3859753, lng: 21.6539842 },
    { lat: 49.386673, lng: 21.6533665 },
    { lat: 49.3873401, lng: 21.652958 },
    { lat: 49.3877012, lng: 21.6525833 },
    { lat: 49.3879011, lng: 21.6522821 },
    { lat: 49.3880287, lng: 21.6522126 },
    { lat: 49.3882033, lng: 21.651978 },
    { lat: 49.3892114, lng: 21.6491653 },
    { lat: 49.3898222, lng: 21.6471358 },
    { lat: 49.3899338, lng: 21.6465228 },
    { lat: 49.3901738, lng: 21.6458756 },
    { lat: 49.3905643, lng: 21.6451765 },
    { lat: 49.3909436, lng: 21.6447718 },
    { lat: 49.3911984, lng: 21.6442944 },
    { lat: 49.3917884, lng: 21.6429066 },
    { lat: 49.3920303, lng: 21.6420845 },
    { lat: 49.3919501, lng: 21.6418646 },
    { lat: 49.3919534, lng: 21.6416467 },
    { lat: 49.3916825, lng: 21.6405792 },
    { lat: 49.3913712, lng: 21.6396024 },
    { lat: 49.3911469, lng: 21.6385912 },
    { lat: 49.3910483, lng: 21.6383967 },
    { lat: 49.39105, lng: 21.6380883 },
    { lat: 49.3905215, lng: 21.637142 },
    { lat: 49.3904967, lng: 21.6361101 },
    { lat: 49.3903384, lng: 21.634383 },
    { lat: 49.3903901, lng: 21.6338919 },
    { lat: 49.3906437, lng: 21.6331217 },
    { lat: 49.3909243, lng: 21.6325223 },
    { lat: 49.3910271, lng: 21.6321687 },
    { lat: 49.391406, lng: 21.6314208 },
    { lat: 49.3919455, lng: 21.6305352 },
    { lat: 49.3923708, lng: 21.6300472 },
    { lat: 49.3928382, lng: 21.6296554 },
    { lat: 49.3930715, lng: 21.6293426 },
    { lat: 49.3930487, lng: 21.6289244 },
    { lat: 49.3930842, lng: 21.6286513 },
    { lat: 49.3932978, lng: 21.6281089 },
    { lat: 49.3933537, lng: 21.6272744 },
    { lat: 49.3934051, lng: 21.6270588 },
    { lat: 49.3934263, lng: 21.6269697 },
  ],
  Železník: [
    { lat: 49.0999662, lng: 21.5050756 },
    { lat: 49.0999017, lng: 21.5051391 },
    { lat: 49.0995334, lng: 21.5041459 },
    { lat: 49.0993712, lng: 21.5038956 },
    { lat: 49.0994109, lng: 21.5034374 },
    { lat: 49.0995834, lng: 21.5031301 },
    { lat: 49.0995327, lng: 21.5027328 },
    { lat: 49.0996622, lng: 21.5018352 },
    { lat: 49.1000978, lng: 21.5010133 },
    { lat: 49.1001078, lng: 21.5006151 },
    { lat: 49.0998737, lng: 21.4999036 },
    { lat: 49.0999426, lng: 21.4986506 },
    { lat: 49.1001295, lng: 21.4979478 },
    { lat: 49.0928582, lng: 21.498303 },
    { lat: 49.09272, lng: 21.4978009 },
    { lat: 49.0928281, lng: 21.4977643 },
    { lat: 49.0928357, lng: 21.4976403 },
    { lat: 49.0927478, lng: 21.495079 },
    { lat: 49.0937515, lng: 21.494998 },
    { lat: 49.093432, lng: 21.4918607 },
    { lat: 49.0917248, lng: 21.4914887 },
    { lat: 49.0918455, lng: 21.4902454 },
    { lat: 49.0915789, lng: 21.4903292 },
    { lat: 49.0906149, lng: 21.4914336 },
    { lat: 49.0896838, lng: 21.4916849 },
    { lat: 49.0886856, lng: 21.4914375 },
    { lat: 49.0886093, lng: 21.4916059 },
    { lat: 49.0884029, lng: 21.4913877 },
    { lat: 49.0877396, lng: 21.4910153 },
    { lat: 49.0870519, lng: 21.4902993 },
    { lat: 49.0866847, lng: 21.4897766 },
    { lat: 49.0863911, lng: 21.4895772 },
    { lat: 49.0860525, lng: 21.4891908 },
    { lat: 49.0855672, lng: 21.4888659 },
    { lat: 49.0847996, lng: 21.4875594 },
    { lat: 49.0846514, lng: 21.4873737 },
    { lat: 49.0842672, lng: 21.4870805 },
    { lat: 49.0839889, lng: 21.4869884 },
    { lat: 49.0834446, lng: 21.4869988 },
    { lat: 49.0832129, lng: 21.4871886 },
    { lat: 49.0830015, lng: 21.4875526 },
    { lat: 49.0826457, lng: 21.4879287 },
    { lat: 49.0821881, lng: 21.4879053 },
    { lat: 49.0817266, lng: 21.4879769 },
    { lat: 49.0813576, lng: 21.4878194 },
    { lat: 49.079725, lng: 21.486514 },
    { lat: 49.079291, lng: 21.487389 },
    { lat: 49.079061, lng: 21.487759 },
    { lat: 49.079006, lng: 21.488411 },
    { lat: 49.079002, lng: 21.488459 },
    { lat: 49.078975, lng: 21.488777 },
    { lat: 49.078911, lng: 21.489535 },
    { lat: 49.078909, lng: 21.48956 },
    { lat: 49.078904, lng: 21.489594 },
    { lat: 49.078878, lng: 21.489862 },
    { lat: 49.078844, lng: 21.490213 },
    { lat: 49.078832, lng: 21.490337 },
    { lat: 49.078812, lng: 21.490541 },
    { lat: 49.07878, lng: 21.490866 },
    { lat: 49.078773, lng: 21.491103 },
    { lat: 49.078766, lng: 21.491299 },
    { lat: 49.078741, lng: 21.491475 },
    { lat: 49.078625, lng: 21.491655 },
    { lat: 49.078585, lng: 21.491717 },
    { lat: 49.078549, lng: 21.491851 },
    { lat: 49.078494, lng: 21.492056 },
    { lat: 49.078469, lng: 21.492152 },
    { lat: 49.078452, lng: 21.492273 },
    { lat: 49.078424, lng: 21.49247 },
    { lat: 49.078393, lng: 21.492688 },
    { lat: 49.078363, lng: 21.492898 },
    { lat: 49.07834, lng: 21.493079 },
    { lat: 49.078337, lng: 21.493099 },
    { lat: 49.078373, lng: 21.493293 },
    { lat: 49.078412, lng: 21.493506 },
    { lat: 49.078452, lng: 21.494218 },
    { lat: 49.07841, lng: 21.494609 },
    { lat: 49.078466, lng: 21.494856 },
    { lat: 49.078483, lng: 21.495474 },
    { lat: 49.078472, lng: 21.495516 },
    { lat: 49.078452, lng: 21.495592 },
    { lat: 49.078372, lng: 21.495756 },
    { lat: 49.077807, lng: 21.496501 },
    { lat: 49.07745, lng: 21.49697 },
    { lat: 49.077409, lng: 21.497206 },
    { lat: 49.077235, lng: 21.497882 },
    { lat: 49.077197, lng: 21.497991 },
    { lat: 49.077118, lng: 21.498384 },
    { lat: 49.07699, lng: 21.498935 },
    { lat: 49.076651, lng: 21.500013 },
    { lat: 49.076311, lng: 21.501116 },
    { lat: 49.076203, lng: 21.501403 },
    { lat: 49.076048, lng: 21.501997 },
    { lat: 49.075756, lng: 21.502364 },
    { lat: 49.075522, lng: 21.502955 },
    { lat: 49.075305, lng: 21.503781 },
    { lat: 49.075164, lng: 21.504179 },
    { lat: 49.075161, lng: 21.504211 },
    { lat: 49.074986, lng: 21.504475 },
    { lat: 49.074755, lng: 21.504821 },
    { lat: 49.074524, lng: 21.505392 },
    { lat: 49.074426, lng: 21.505636 },
    { lat: 49.074282, lng: 21.505865 },
    { lat: 49.073667, lng: 21.50716 },
    { lat: 49.073396, lng: 21.507424 },
    { lat: 49.072913, lng: 21.507805 },
    { lat: 49.072567, lng: 21.508141 },
    { lat: 49.072204, lng: 21.508432 },
    { lat: 49.072207, lng: 21.508453 },
    { lat: 49.072232, lng: 21.508684 },
    { lat: 49.072219, lng: 21.508852 },
    { lat: 49.072237, lng: 21.509877 },
    { lat: 49.072264, lng: 21.510428 },
    { lat: 49.072236, lng: 21.51107 },
    { lat: 49.072261, lng: 21.511069 },
    { lat: 49.072504, lng: 21.511619 },
    { lat: 49.072541, lng: 21.511839 },
    { lat: 49.07271, lng: 21.512285 },
    { lat: 49.073216, lng: 21.513324 },
    { lat: 49.073193, lng: 21.513528 },
    { lat: 49.073208, lng: 21.51362 },
    { lat: 49.073271, lng: 21.514011 },
    { lat: 49.073263, lng: 21.514266 },
    { lat: 49.073299, lng: 21.514814 },
    { lat: 49.07315, lng: 21.515859 },
    { lat: 49.073051, lng: 21.516196 },
    { lat: 49.073029, lng: 21.516407 },
    { lat: 49.072955, lng: 21.516589 },
    { lat: 49.072957, lng: 21.516838 },
    { lat: 49.072794, lng: 21.517172 },
    { lat: 49.072659, lng: 21.517297 },
    { lat: 49.072632, lng: 21.51747 },
    { lat: 49.072492, lng: 21.517501 },
    { lat: 49.072448, lng: 21.517378 },
    { lat: 49.072313, lng: 21.517513 },
    { lat: 49.072278, lng: 21.517754 },
    { lat: 49.072161, lng: 21.517764 },
    { lat: 49.072142, lng: 21.517771 },
    { lat: 49.071747, lng: 21.517927 },
    { lat: 49.071662, lng: 21.518084 },
    { lat: 49.071497, lng: 21.518194 },
    { lat: 49.071487, lng: 21.518336 },
    { lat: 49.071318, lng: 21.518523 },
    { lat: 49.071171, lng: 21.518685 },
    { lat: 49.070918, lng: 21.519057 },
    { lat: 49.070627, lng: 21.519215 },
    { lat: 49.070616, lng: 21.519221 },
    { lat: 49.070246, lng: 21.519744 },
    { lat: 49.070093, lng: 21.520192 },
    { lat: 49.070045, lng: 21.520286 },
    { lat: 49.070024, lng: 21.520325 },
    { lat: 49.069784, lng: 21.520796 },
    { lat: 49.069771, lng: 21.520942 },
    { lat: 49.069755, lng: 21.520999 },
    { lat: 49.069681, lng: 21.521285 },
    { lat: 49.069671, lng: 21.521321 },
    { lat: 49.0707931, lng: 21.5224307 },
    { lat: 49.0714804, lng: 21.5227906 },
    { lat: 49.0751848, lng: 21.5230103 },
    { lat: 49.076292, lng: 21.5228553 },
    { lat: 49.0763638, lng: 21.5231589 },
    { lat: 49.0766021, lng: 21.5234303 },
    { lat: 49.0772744, lng: 21.5232978 },
    { lat: 49.0779848, lng: 21.5232744 },
    { lat: 49.0781865, lng: 21.5231697 },
    { lat: 49.07857, lng: 21.5225681 },
    { lat: 49.0794499, lng: 21.5209451 },
    { lat: 49.0804796, lng: 21.519413 },
    { lat: 49.0809189, lng: 21.5189082 },
    { lat: 49.0827875, lng: 21.5185953 },
    { lat: 49.0833626, lng: 21.5190376 },
    { lat: 49.0833875, lng: 21.5194742 },
    { lat: 49.0837203, lng: 21.5200173 },
    { lat: 49.0843407, lng: 21.5198824 },
    { lat: 49.0847894, lng: 21.5196737 },
    { lat: 49.0853726, lng: 21.5196881 },
    { lat: 49.0862696, lng: 21.5195038 },
    { lat: 49.086767, lng: 21.5204994 },
    { lat: 49.0867934, lng: 21.5202737 },
    { lat: 49.0870653, lng: 21.5194317 },
    { lat: 49.0871987, lng: 21.519098 },
    { lat: 49.0873029, lng: 21.5190064 },
    { lat: 49.0874527, lng: 21.5189689 },
    { lat: 49.0877194, lng: 21.5192098 },
    { lat: 49.088009, lng: 21.5192622 },
    { lat: 49.0881988, lng: 21.5193721 },
    { lat: 49.08829, lng: 21.5192943 },
    { lat: 49.0886707, lng: 21.5192351 },
    { lat: 49.0894386, lng: 21.5188536 },
    { lat: 49.0896666, lng: 21.5189653 },
    { lat: 49.0899405, lng: 21.5195773 },
    { lat: 49.0902632, lng: 21.5197244 },
    { lat: 49.0905407, lng: 21.5197072 },
    { lat: 49.0908707, lng: 21.519605 },
    { lat: 49.0908555, lng: 21.5193849 },
    { lat: 49.0910563, lng: 21.5190109 },
    { lat: 49.0917509, lng: 21.5183901 },
    { lat: 49.092061, lng: 21.51913 },
    { lat: 49.0924797, lng: 21.5193702 },
    { lat: 49.09272, lng: 21.5197557 },
    { lat: 49.0936523, lng: 21.5206482 },
    { lat: 49.0938445, lng: 21.5209572 },
    { lat: 49.0942916, lng: 21.521411 },
    { lat: 49.0945728, lng: 21.5206921 },
    { lat: 49.0948032, lng: 21.5204461 },
    { lat: 49.0949545, lng: 21.5205119 },
    { lat: 49.0951675, lng: 21.5200821 },
    { lat: 49.0955057, lng: 21.519596 },
    { lat: 49.0955929, lng: 21.5194959 },
    { lat: 49.0958746, lng: 21.5195872 },
    { lat: 49.095922, lng: 21.5194383 },
    { lat: 49.0961704, lng: 21.5192701 },
    { lat: 49.0964355, lng: 21.5189424 },
    { lat: 49.096997, lng: 21.5187065 },
    { lat: 49.097567, lng: 21.5186536 },
    { lat: 49.0994466, lng: 21.5180863 },
    { lat: 49.1005871, lng: 21.5178762 },
    { lat: 49.1017843, lng: 21.5173216 },
    { lat: 49.1023287, lng: 21.5169974 },
    { lat: 49.1031715, lng: 21.5163346 },
    { lat: 49.10355, lng: 21.516156 },
    { lat: 49.1027074, lng: 21.5140155 },
    { lat: 49.1017009, lng: 21.5116608 },
    { lat: 49.1006675, lng: 21.5099047 },
    { lat: 49.1002593, lng: 21.5082955 },
    { lat: 49.1001567, lng: 21.5080456 },
    { lat: 49.1001144, lng: 21.5080663 },
    { lat: 49.0998187, lng: 21.507236 },
    { lat: 49.0995713, lng: 21.5070125 },
    { lat: 49.0995457, lng: 21.5067823 },
    { lat: 49.0996501, lng: 21.5065007 },
    { lat: 49.1001094, lng: 21.5062294 },
    { lat: 49.1002555, lng: 21.5059875 },
    { lat: 49.1003168, lng: 21.5054915 },
    { lat: 49.0999662, lng: 21.5050756 },
  ],
  KrajnáBystrá: [
    { lat: 49.3746163, lng: 21.6812507 },
    { lat: 49.374868, lng: 21.6816179 },
    { lat: 49.3756054, lng: 21.6834854 },
    { lat: 49.3763844, lng: 21.6841341 },
    { lat: 49.3767123, lng: 21.6853311 },
    { lat: 49.3779048, lng: 21.6854655 },
    { lat: 49.3800252, lng: 21.6864732 },
    { lat: 49.3802349, lng: 21.6866214 },
    { lat: 49.3803482, lng: 21.686354 },
    { lat: 49.3815931, lng: 21.6873953 },
    { lat: 49.383154, lng: 21.6894828 },
    { lat: 49.383268, lng: 21.6903342 },
    { lat: 49.3839679, lng: 21.6911827 },
    { lat: 49.3844653, lng: 21.6901048 },
    { lat: 49.3848547, lng: 21.6898132 },
    { lat: 49.38516, lng: 21.6904663 },
    { lat: 49.3859159, lng: 21.6904217 },
    { lat: 49.3859296, lng: 21.6905555 },
    { lat: 49.3864064, lng: 21.6906332 },
    { lat: 49.3865865, lng: 21.6902662 },
    { lat: 49.3871822, lng: 21.6903725 },
    { lat: 49.3876888, lng: 21.6905967 },
    { lat: 49.3884486, lng: 21.6912068 },
    { lat: 49.3884291, lng: 21.6913601 },
    { lat: 49.3888187, lng: 21.6917129 },
    { lat: 49.3890745, lng: 21.6909762 },
    { lat: 49.3899069, lng: 21.6904686 },
    { lat: 49.3903971, lng: 21.6907133 },
    { lat: 49.3909504, lng: 21.6904965 },
    { lat: 49.3911165, lng: 21.69074 },
    { lat: 49.391405, lng: 21.690589 },
    { lat: 49.39123, lng: 21.6901016 },
    { lat: 49.3914741, lng: 21.6897303 },
    { lat: 49.3917212, lng: 21.6896711 },
    { lat: 49.3933156, lng: 21.6886786 },
    { lat: 49.3933555, lng: 21.6889541 },
    { lat: 49.393575, lng: 21.6895029 },
    { lat: 49.3938288, lng: 21.6897463 },
    { lat: 49.3941715, lng: 21.6898175 },
    { lat: 49.3943279, lng: 21.689647 },
    { lat: 49.3948881, lng: 21.6893092 },
    { lat: 49.3955358, lng: 21.6887174 },
    { lat: 49.3957954, lng: 21.6879099 },
    { lat: 49.3960737, lng: 21.6875049 },
    { lat: 49.3963365, lng: 21.687618 },
    { lat: 49.3986083, lng: 21.6872704 },
    { lat: 49.3996382, lng: 21.6874657 },
    { lat: 49.4002913, lng: 21.687949 },
    { lat: 49.4016036, lng: 21.6880261 },
    { lat: 49.4027391, lng: 21.6879594 },
    { lat: 49.4030992, lng: 21.6878422 },
    { lat: 49.4040596, lng: 21.6882546 },
    { lat: 49.4043954, lng: 21.6882335 },
    { lat: 49.4046186, lng: 21.688128 },
    { lat: 49.4059644, lng: 21.6884837 },
    { lat: 49.4072771, lng: 21.6883557 },
    { lat: 49.4084384, lng: 21.6880392 },
    { lat: 49.4091559, lng: 21.6876603 },
    { lat: 49.4095131, lng: 21.6876371 },
    { lat: 49.4103717, lng: 21.6870207 },
    { lat: 49.4110064, lng: 21.6856446 },
    { lat: 49.4114009, lng: 21.6846495 },
    { lat: 49.411359, lng: 21.684158 },
    { lat: 49.4112784, lng: 21.6836847 },
    { lat: 49.411268, lng: 21.683662 },
    { lat: 49.41107, lng: 21.683282 },
    { lat: 49.4110621, lng: 21.6832671 },
    { lat: 49.41095, lng: 21.682416 },
    { lat: 49.410869, lng: 21.681795 },
    { lat: 49.410805, lng: 21.681232 },
    { lat: 49.411029, lng: 21.680455 },
    { lat: 49.411183, lng: 21.680026 },
    { lat: 49.411242, lng: 21.679869 },
    { lat: 49.411487, lng: 21.679252 },
    { lat: 49.411768, lng: 21.678748 },
    { lat: 49.412205, lng: 21.677967 },
    { lat: 49.4124986, lng: 21.677571 },
    { lat: 49.4129545, lng: 21.6771766 },
    { lat: 49.41296, lng: 21.677167 },
    { lat: 49.4131913, lng: 21.6768575 },
    { lat: 49.413197, lng: 21.676843 },
    { lat: 49.413457, lng: 21.67617 },
    { lat: 49.4139794, lng: 21.6748411 },
    { lat: 49.413984, lng: 21.674827 },
    { lat: 49.4143011, lng: 21.6744557 },
    { lat: 49.414297, lng: 21.674433 },
    { lat: 49.414279, lng: 21.674277 },
    { lat: 49.414221, lng: 21.673893 },
    { lat: 49.414216, lng: 21.673861 },
    { lat: 49.414094, lng: 21.673213 },
    { lat: 49.414189, lng: 21.672594 },
    { lat: 49.41431, lng: 21.671759 },
    { lat: 49.4143847, lng: 21.6712563 },
    { lat: 49.4142728, lng: 21.6706778 },
    { lat: 49.4145696, lng: 21.6699223 },
    { lat: 49.415264, lng: 21.668424 },
    { lat: 49.4155845, lng: 21.6677115 },
    { lat: 49.4157651, lng: 21.6664492 },
    { lat: 49.4156479, lng: 21.6655629 },
    { lat: 49.4154768, lng: 21.6647027 },
    { lat: 49.4151407, lng: 21.6638921 },
    { lat: 49.4152988, lng: 21.663593 },
    { lat: 49.4154061, lng: 21.6633135 },
    { lat: 49.4148686, lng: 21.6632133 },
    { lat: 49.4136679, lng: 21.6631504 },
    { lat: 49.411769, lng: 21.6635149 },
    { lat: 49.4102207, lng: 21.6636203 },
    { lat: 49.4088352, lng: 21.6643464 },
    { lat: 49.4075987, lng: 21.6650817 },
    { lat: 49.4073119, lng: 21.6651274 },
    { lat: 49.4068792, lng: 21.6655554 },
    { lat: 49.4062782, lng: 21.6658394 },
    { lat: 49.4052798, lng: 21.6664791 },
    { lat: 49.4050109, lng: 21.6655971 },
    { lat: 49.4049169, lng: 21.6649059 },
    { lat: 49.4046502, lng: 21.6642759 },
    { lat: 49.4043649, lng: 21.6638335 },
    { lat: 49.4043736, lng: 21.6637795 },
    { lat: 49.4041179, lng: 21.6635478 },
    { lat: 49.4036266, lng: 21.6634042 },
    { lat: 49.4031918, lng: 21.6629615 },
    { lat: 49.4031131, lng: 21.6629622 },
    { lat: 49.4029917, lng: 21.6629282 },
    { lat: 49.402639, lng: 21.6628174 },
    { lat: 49.400892, lng: 21.6626912 },
    { lat: 49.4005088, lng: 21.6625793 },
    { lat: 49.4000271, lng: 21.6626073 },
    { lat: 49.3995149, lng: 21.6627998 },
    { lat: 49.3992457, lng: 21.6627042 },
    { lat: 49.3984083, lng: 21.6618071 },
    { lat: 49.3981937, lng: 21.6616535 },
    { lat: 49.3976032, lng: 21.661485 },
    { lat: 49.3974431, lng: 21.6616411 },
    { lat: 49.3972864, lng: 21.6616265 },
    { lat: 49.3972872, lng: 21.6615769 },
    { lat: 49.3971419, lng: 21.6615212 },
    { lat: 49.3969566, lng: 21.6615809 },
    { lat: 49.3968081, lng: 21.6615373 },
    { lat: 49.396601, lng: 21.6613204 },
    { lat: 49.396442, lng: 21.6610094 },
    { lat: 49.3959554, lng: 21.6607022 },
    { lat: 49.3957912, lng: 21.6603656 },
    { lat: 49.3957587, lng: 21.6604099 },
    { lat: 49.3951458, lng: 21.659876 },
    { lat: 49.3938038, lng: 21.6590436 },
    { lat: 49.3936229, lng: 21.6591359 },
    { lat: 49.3933178, lng: 21.6590802 },
    { lat: 49.3927788, lng: 21.6586969 },
    { lat: 49.3925895, lng: 21.6583135 },
    { lat: 49.3923977, lng: 21.6581253 },
    { lat: 49.3916022, lng: 21.6577396 },
    { lat: 49.3909447, lng: 21.6570493 },
    { lat: 49.3906829, lng: 21.6565207 },
    { lat: 49.3904766, lng: 21.6565436 },
    { lat: 49.3900393, lng: 21.6560976 },
    { lat: 49.3895191, lng: 21.6559594 },
    { lat: 49.3894309, lng: 21.6558174 },
    { lat: 49.3894547, lng: 21.6553623 },
    { lat: 49.3893851, lng: 21.6535422 },
    { lat: 49.3893507, lng: 21.6534274 },
    { lat: 49.3893881, lng: 21.6524876 },
    { lat: 49.3883114, lng: 21.652356 },
    { lat: 49.3880287, lng: 21.6522126 },
    { lat: 49.3879011, lng: 21.6522821 },
    { lat: 49.3877012, lng: 21.6525833 },
    { lat: 49.3873401, lng: 21.652958 },
    { lat: 49.386673, lng: 21.6533665 },
    { lat: 49.3859753, lng: 21.6539842 },
    { lat: 49.3855106, lng: 21.6540151 },
    { lat: 49.3850448, lng: 21.6545982 },
    { lat: 49.3846218, lng: 21.6549219 },
    { lat: 49.3835815, lng: 21.6560501 },
    { lat: 49.3825929, lng: 21.656974 },
    { lat: 49.3822786, lng: 21.6572033 },
    { lat: 49.3818311, lng: 21.6573844 },
    { lat: 49.381584, lng: 21.6576417 },
    { lat: 49.3809043, lng: 21.6581317 },
    { lat: 49.3795403, lng: 21.6589789 },
    { lat: 49.3792279, lng: 21.6592818 },
    { lat: 49.3788502, lng: 21.6594523 },
    { lat: 49.3779979, lng: 21.65955 },
    { lat: 49.3775181, lng: 21.6596577 },
    { lat: 49.3774035, lng: 21.6595798 },
    { lat: 49.3769576, lng: 21.6606459 },
    { lat: 49.3767241, lng: 21.6610322 },
    { lat: 49.3764173, lng: 21.6623346 },
    { lat: 49.376175, lng: 21.6623174 },
    { lat: 49.3760479, lng: 21.6629002 },
    { lat: 49.3757953, lng: 21.6634198 },
    { lat: 49.3751605, lng: 21.6641527 },
    { lat: 49.3741864, lng: 21.6650713 },
    { lat: 49.3723083, lng: 21.6677566 },
    { lat: 49.3725313, lng: 21.6683085 },
    { lat: 49.3724825, lng: 21.6684975 },
    { lat: 49.3714337, lng: 21.6695254 },
    { lat: 49.3716569, lng: 21.6700068 },
    { lat: 49.3721327, lng: 21.6738025 },
    { lat: 49.3727623, lng: 21.6766768 },
    { lat: 49.3729888, lng: 21.6771127 },
    { lat: 49.3732495, lng: 21.6774828 },
    { lat: 49.373437, lng: 21.6781327 },
    { lat: 49.3740128, lng: 21.6788786 },
    { lat: 49.3745051, lng: 21.6800864 },
    { lat: 49.3746334, lng: 21.6809233 },
    { lat: 49.3746163, lng: 21.6812507 },
  ],
  VyšnýMirošov: [
    { lat: 49.3797679, lng: 21.477823 },
    { lat: 49.3820027, lng: 21.4767438 },
    { lat: 49.3834483, lng: 21.4755188 },
    { lat: 49.3844286, lng: 21.4747889 },
    { lat: 49.3861923, lng: 21.4732131 },
    { lat: 49.3869906, lng: 21.4730364 },
    { lat: 49.3890772, lng: 21.4739355 },
    { lat: 49.3908526, lng: 21.4742193 },
    { lat: 49.3922198, lng: 21.4740683 },
    { lat: 49.3948967, lng: 21.4733483 },
    { lat: 49.3954644, lng: 21.4734932 },
    { lat: 49.3987684, lng: 21.4689352 },
    { lat: 49.4002573, lng: 21.4676618 },
    { lat: 49.4018184, lng: 21.4692906 },
    { lat: 49.4026691, lng: 21.468089 },
    { lat: 49.403365, lng: 21.4672078 },
    { lat: 49.4068434, lng: 21.4678302 },
    { lat: 49.409745, lng: 21.4679257 },
    { lat: 49.4102261, lng: 21.4681878 },
    { lat: 49.4125076, lng: 21.4686618 },
    { lat: 49.4136493, lng: 21.4691721 },
    { lat: 49.4136081, lng: 21.4688797 },
    { lat: 49.4136543, lng: 21.4685628 },
    { lat: 49.4136362, lng: 21.468193 },
    { lat: 49.4131971, lng: 21.4674463 },
    { lat: 49.4132007, lng: 21.4670002 },
    { lat: 49.413515, lng: 21.465947 },
    { lat: 49.413635, lng: 21.465567 },
    { lat: 49.4136401, lng: 21.4655525 },
    { lat: 49.4136931, lng: 21.4651577 },
    { lat: 49.4138288, lng: 21.4646812 },
    { lat: 49.4138188, lng: 21.4644496 },
    { lat: 49.413827, lng: 21.464416 },
    { lat: 49.4138924, lng: 21.4642706 },
    { lat: 49.4142187, lng: 21.4619407 },
    { lat: 49.4142761, lng: 21.4617642 },
    { lat: 49.41428, lng: 21.461742 },
    { lat: 49.414312, lng: 21.461701 },
    { lat: 49.4145343, lng: 21.4613386 },
    { lat: 49.414535, lng: 21.461328 },
    { lat: 49.4152692, lng: 21.459566 },
    { lat: 49.4156163, lng: 21.458845 },
    { lat: 49.4157902, lng: 21.4581416 },
    { lat: 49.4160745, lng: 21.4572356 },
    { lat: 49.4162993, lng: 21.4562309 },
    { lat: 49.41617, lng: 21.455937 },
    { lat: 49.4160337, lng: 21.455634 },
    { lat: 49.41585, lng: 21.4555042 },
    { lat: 49.4158263, lng: 21.4551347 },
    { lat: 49.4157423, lng: 21.4544733 },
    { lat: 49.415738, lng: 21.454461 },
    { lat: 49.415379, lng: 21.453187 },
    { lat: 49.4149646, lng: 21.4517016 },
    { lat: 49.414655, lng: 21.4511911 },
    { lat: 49.4142028, lng: 21.450972 },
    { lat: 49.4141399, lng: 21.4507967 },
    { lat: 49.413768, lng: 21.450321 },
    { lat: 49.4136249, lng: 21.4501292 },
    { lat: 49.413615, lng: 21.450113 },
    { lat: 49.41355, lng: 21.450017 },
    { lat: 49.4135365, lng: 21.4499877 },
    { lat: 49.41352, lng: 21.449943 },
    { lat: 49.4134352, lng: 21.4496529 },
    { lat: 49.413128, lng: 21.4488719 },
    { lat: 49.4125919, lng: 21.4473252 },
    { lat: 49.4125173, lng: 21.4468764 },
    { lat: 49.412514, lng: 21.446864 },
    { lat: 49.4125236, lng: 21.4466039 },
    { lat: 49.4123551, lng: 21.4453815 },
    { lat: 49.4122501, lng: 21.4448414 },
    { lat: 49.411514, lng: 21.443682 },
    { lat: 49.411471, lng: 21.443662 },
    { lat: 49.4113, lng: 21.443579 },
    { lat: 49.410442, lng: 21.443164 },
    { lat: 49.41007, lng: 21.442984 },
    { lat: 49.40977, lng: 21.442978 },
    { lat: 49.40973, lng: 21.442978 },
    { lat: 49.408634, lng: 21.442956 },
    { lat: 49.406293, lng: 21.441808 },
    { lat: 49.404724, lng: 21.4405 },
    { lat: 49.404751, lng: 21.440372 },
    { lat: 49.403547, lng: 21.439992 },
    { lat: 49.40339, lng: 21.440103 },
    { lat: 49.403183, lng: 21.440249 },
    { lat: 49.401085, lng: 21.440148 },
    { lat: 49.400318, lng: 21.439744 },
    { lat: 49.400318, lng: 21.439439 },
    { lat: 49.399367, lng: 21.438767 },
    { lat: 49.398603, lng: 21.43814 },
    { lat: 49.39817, lng: 21.438104 },
    { lat: 49.397725, lng: 21.437364 },
    { lat: 49.397089, lng: 21.436526 },
    { lat: 49.396613, lng: 21.435897 },
    { lat: 49.395555, lng: 21.434666 },
    { lat: 49.395475, lng: 21.434573 },
    { lat: 49.394987, lng: 21.434005 },
    { lat: 49.394968, lng: 21.433983 },
    { lat: 49.394894, lng: 21.433903 },
    { lat: 49.394872, lng: 21.433879 },
    { lat: 49.39486, lng: 21.433865 },
    { lat: 49.394689, lng: 21.433678 },
    { lat: 49.394485, lng: 21.433455 },
    { lat: 49.394211, lng: 21.433154 },
    { lat: 49.393862, lng: 21.432713 },
    { lat: 49.393267, lng: 21.43196 },
    { lat: 49.392196, lng: 21.430139 },
    { lat: 49.391201, lng: 21.428566 },
    { lat: 49.390309, lng: 21.42706 },
    { lat: 49.389711, lng: 21.425168 },
    { lat: 49.389438, lng: 21.424304 },
    { lat: 49.388798, lng: 21.421642 },
    { lat: 49.387982, lng: 21.419568 },
    { lat: 49.387448, lng: 21.418828 },
    { lat: 49.38494, lng: 21.415344 },
    { lat: 49.384228, lng: 21.416181 },
    { lat: 49.382974, lng: 21.417749 },
    { lat: 49.381457, lng: 21.420055 },
    { lat: 49.3817725, lng: 21.4215629 },
    { lat: 49.3797088, lng: 21.4261989 },
    { lat: 49.378424, lng: 21.4281332 },
    { lat: 49.377624, lng: 21.4301849 },
    { lat: 49.3771296, lng: 21.4351407 },
    { lat: 49.3770507, lng: 21.4363003 },
    { lat: 49.3760557, lng: 21.4393584 },
    { lat: 49.3754323, lng: 21.44149 },
    { lat: 49.3749825, lng: 21.4436772 },
    { lat: 49.374288, lng: 21.4463175 },
    { lat: 49.3734787, lng: 21.4486799 },
    { lat: 49.372465, lng: 21.4514087 },
    { lat: 49.3714187, lng: 21.4525769 },
    { lat: 49.3698394, lng: 21.4545809 },
    { lat: 49.3703624, lng: 21.456526 },
    { lat: 49.3711353, lng: 21.4586803 },
    { lat: 49.3717408, lng: 21.4600604 },
    { lat: 49.3719325, lng: 21.462116 },
    { lat: 49.3720447, lng: 21.4626131 },
    { lat: 49.3726886, lng: 21.4642372 },
    { lat: 49.3734365, lng: 21.4657125 },
    { lat: 49.3740546, lng: 21.4667146 },
    { lat: 49.3746269, lng: 21.4674509 },
    { lat: 49.3755622, lng: 21.4690772 },
    { lat: 49.3766449, lng: 21.4722375 },
    { lat: 49.3774478, lng: 21.4738754 },
    { lat: 49.3793497, lng: 21.4763729 },
    { lat: 49.3797679, lng: 21.477823 },
  ],
  Príkra: [
    { lat: 49.3660137, lng: 21.767518 },
    { lat: 49.3665065, lng: 21.7673513 },
    { lat: 49.367004, lng: 21.767041 },
    { lat: 49.367249, lng: 21.766742 },
    { lat: 49.367195, lng: 21.766244 },
    { lat: 49.367974, lng: 21.766066 },
    { lat: 49.368396, lng: 21.766071 },
    { lat: 49.368477, lng: 21.766073 },
    { lat: 49.368853, lng: 21.766099 },
    { lat: 49.37009, lng: 21.766283 },
    { lat: 49.371316, lng: 21.765391 },
    { lat: 49.372174, lng: 21.764699 },
    { lat: 49.372227, lng: 21.764685 },
    { lat: 49.372658, lng: 21.764436 },
    { lat: 49.373398, lng: 21.764075 },
    { lat: 49.373926, lng: 21.763468 },
    { lat: 49.374991, lng: 21.763032 },
    { lat: 49.375858, lng: 21.76332 },
    { lat: 49.376993, lng: 21.762955 },
    { lat: 49.3784997, lng: 21.7427343 },
    { lat: 49.3783701, lng: 21.7386285 },
    { lat: 49.37738, lng: 21.7371627 },
    { lat: 49.3772609, lng: 21.7369106 },
    { lat: 49.3741659, lng: 21.7321154 },
    { lat: 49.3744969, lng: 21.7277883 },
    { lat: 49.3717889, lng: 21.7242425 },
    { lat: 49.3714255, lng: 21.7238522 },
    { lat: 49.3661771, lng: 21.7196933 },
    { lat: 49.3660914, lng: 21.7196051 },
    { lat: 49.3659883, lng: 21.7188576 },
    { lat: 49.3645155, lng: 21.7191827 },
    { lat: 49.3642571, lng: 21.7191439 },
    { lat: 49.3637611, lng: 21.7189373 },
    { lat: 49.3629668, lng: 21.7196912 },
    { lat: 49.3624668, lng: 21.7209413 },
    { lat: 49.3597063, lng: 21.7208116 },
    { lat: 49.3591235, lng: 21.7212231 },
    { lat: 49.358903, lng: 21.7216103 },
    { lat: 49.3579547, lng: 21.7226201 },
    { lat: 49.357677, lng: 21.722799 },
    { lat: 49.3573624, lng: 21.7231462 },
    { lat: 49.3568209, lng: 21.7234732 },
    { lat: 49.3562078, lng: 21.7241925 },
    { lat: 49.355623, lng: 21.7243609 },
    { lat: 49.3551569, lng: 21.7243476 },
    { lat: 49.3547329, lng: 21.7245186 },
    { lat: 49.3540892, lng: 21.7253556 },
    { lat: 49.3534857, lng: 21.7259296 },
    { lat: 49.3532085, lng: 21.7262925 },
    { lat: 49.3526393, lng: 21.7273103 },
    { lat: 49.3522718, lng: 21.7277076 },
    { lat: 49.3519897, lng: 21.7279224 },
    { lat: 49.351588, lng: 21.7285159 },
    { lat: 49.3507582, lng: 21.7300989 },
    { lat: 49.3506559, lng: 21.7309986 },
    { lat: 49.3504018, lng: 21.7321032 },
    { lat: 49.3503036, lng: 21.7329456 },
    { lat: 49.3501877, lng: 21.7332414 },
    { lat: 49.3498323, lng: 21.7337512 },
    { lat: 49.3495372, lng: 21.733733 },
    { lat: 49.3491926, lng: 21.7339744 },
    { lat: 49.3490062, lng: 21.7343344 },
    { lat: 49.3486002, lng: 21.7343747 },
    { lat: 49.3481132, lng: 21.734034 },
    { lat: 49.3479124, lng: 21.7337567 },
    { lat: 49.3478419, lng: 21.7337766 },
    { lat: 49.3478599, lng: 21.7338152 },
    { lat: 49.3479254, lng: 21.7339547 },
    { lat: 49.3478542, lng: 21.7340717 },
    { lat: 49.3480227, lng: 21.7342763 },
    { lat: 49.3480642, lng: 21.7346521 },
    { lat: 49.348039, lng: 21.7349376 },
    { lat: 49.3481045, lng: 21.7350266 },
    { lat: 49.3481788, lng: 21.7357952 },
    { lat: 49.3484122, lng: 21.7360268 },
    { lat: 49.3490322, lng: 21.7377018 },
    { lat: 49.3491172, lng: 21.738189 },
    { lat: 49.3491729, lng: 21.738301 },
    { lat: 49.3491598, lng: 21.7384901 },
    { lat: 49.3492249, lng: 21.7385665 },
    { lat: 49.3491028, lng: 21.7389327 },
    { lat: 49.3491989, lng: 21.7392854 },
    { lat: 49.349084, lng: 21.7396662 },
    { lat: 49.3489361, lng: 21.7397338 },
    { lat: 49.3489499, lng: 21.7400425 },
    { lat: 49.3488023, lng: 21.740666 },
    { lat: 49.34889, lng: 21.7410055 },
    { lat: 49.3488411, lng: 21.7413332 },
    { lat: 49.3489107, lng: 21.7414523 },
    { lat: 49.3487956, lng: 21.7418263 },
    { lat: 49.3488747, lng: 21.7420967 },
    { lat: 49.3485521, lng: 21.7426996 },
    { lat: 49.3474942, lng: 21.7435759 },
    { lat: 49.3472367, lng: 21.7437062 },
    { lat: 49.3467707, lng: 21.7444196 },
    { lat: 49.3465833, lng: 21.7452537 },
    { lat: 49.346887, lng: 21.7457599 },
    { lat: 49.3470497, lng: 21.7459122 },
    { lat: 49.3473106, lng: 21.7463853 },
    { lat: 49.3473226, lng: 21.7465368 },
    { lat: 49.3479084, lng: 21.7478572 },
    { lat: 49.3478481, lng: 21.7481293 },
    { lat: 49.3476503, lng: 21.7483049 },
    { lat: 49.3475664, lng: 21.7484937 },
    { lat: 49.3474046, lng: 21.7484633 },
    { lat: 49.3472751, lng: 21.748665 },
    { lat: 49.3471288, lng: 21.7491011 },
    { lat: 49.3473796, lng: 21.7501232 },
    { lat: 49.346989, lng: 21.7503284 },
    { lat: 49.3467563, lng: 21.7507107 },
    { lat: 49.346823, lng: 21.75122 },
    { lat: 49.346984, lng: 21.751619 },
    { lat: 49.347156, lng: 21.752009 },
    { lat: 49.347329, lng: 21.752399 },
    { lat: 49.347564, lng: 21.752724 },
    { lat: 49.347985, lng: 21.753731 },
    { lat: 49.348023, lng: 21.753816 },
    { lat: 49.348478, lng: 21.754807 },
    { lat: 49.348527, lng: 21.75491 },
    { lat: 49.348722, lng: 21.755328 },
    { lat: 49.34894, lng: 21.756144 },
    { lat: 49.349656, lng: 21.756222 },
    { lat: 49.35016, lng: 21.757796 },
    { lat: 49.350862, lng: 21.758348 },
    { lat: 49.351229, lng: 21.758614 },
    { lat: 49.35142, lng: 21.759002 },
    { lat: 49.352139, lng: 21.760128 },
    { lat: 49.352491, lng: 21.760567 },
    { lat: 49.352873, lng: 21.761675 },
    { lat: 49.352877, lng: 21.761708 },
    { lat: 49.352882, lng: 21.761741 },
    { lat: 49.352918, lng: 21.762086 },
    { lat: 49.353244, lng: 21.761807 },
    { lat: 49.353446, lng: 21.761845 },
    { lat: 49.353653, lng: 21.761728 },
    { lat: 49.353793, lng: 21.761598 },
    { lat: 49.354033, lng: 21.761425 },
    { lat: 49.354248, lng: 21.761169 },
    { lat: 49.354311, lng: 21.761054 },
    { lat: 49.354428, lng: 21.760671 },
    { lat: 49.354644, lng: 21.760537 },
    { lat: 49.354904, lng: 21.760422 },
    { lat: 49.355297, lng: 21.75982 },
    { lat: 49.355457, lng: 21.759808 },
    { lat: 49.355657, lng: 21.759663 },
    { lat: 49.356145, lng: 21.759541 },
    { lat: 49.356363, lng: 21.759579 },
    { lat: 49.358078, lng: 21.762588 },
    { lat: 49.363121, lng: 21.771446 },
    { lat: 49.3631445, lng: 21.7714846 },
    { lat: 49.3634577, lng: 21.7710225 },
    { lat: 49.3636466, lng: 21.7699607 },
    { lat: 49.3645959, lng: 21.7685548 },
    { lat: 49.3649188, lng: 21.7682204 },
    { lat: 49.3653713, lng: 21.7678927 },
    { lat: 49.3660137, lng: 21.767518 },
  ],
  Dukovce: [
    { lat: 49.129086, lng: 21.386367 },
    { lat: 49.128863, lng: 21.386326 },
    { lat: 49.128731, lng: 21.386265 },
    { lat: 49.128664, lng: 21.38605 },
    { lat: 49.128513, lng: 21.385933 },
    { lat: 49.128492, lng: 21.385848 },
    { lat: 49.128282, lng: 21.385805 },
    { lat: 49.126726, lng: 21.385797 },
    { lat: 49.125501, lng: 21.385639 },
    { lat: 49.123454, lng: 21.384907 },
    { lat: 49.123012, lng: 21.385168 },
    { lat: 49.121814, lng: 21.385021 },
    { lat: 49.12144, lng: 21.384808 },
    { lat: 49.121474, lng: 21.384664 },
    { lat: 49.121038, lng: 21.384268 },
    { lat: 49.121223, lng: 21.383292 },
    { lat: 49.121054, lng: 21.38324 },
    { lat: 49.121183, lng: 21.381619 },
    { lat: 49.120765, lng: 21.381013 },
    { lat: 49.12026, lng: 21.379697 },
    { lat: 49.120147, lng: 21.379398 },
    { lat: 49.119736, lng: 21.379752 },
    { lat: 49.119578, lng: 21.379483 },
    { lat: 49.11955, lng: 21.379436 },
    { lat: 49.119505, lng: 21.379358 },
    { lat: 49.119375, lng: 21.378635 },
    { lat: 49.119182, lng: 21.378268 },
    { lat: 49.119006, lng: 21.377819 },
    { lat: 49.118843, lng: 21.377366 },
    { lat: 49.11884, lng: 21.377343 },
    { lat: 49.118788, lng: 21.37736 },
    { lat: 49.11875, lng: 21.377372 },
    { lat: 49.117361, lng: 21.377824 },
    { lat: 49.116792, lng: 21.377745 },
    { lat: 49.116126, lng: 21.377405 },
    { lat: 49.11613, lng: 21.377382 },
    { lat: 49.11634, lng: 21.376487 },
    { lat: 49.116445, lng: 21.374689 },
    { lat: 49.116417, lng: 21.374567 },
    { lat: 49.1163654, lng: 21.3741267 },
    { lat: 49.116205, lng: 21.374224 },
    { lat: 49.115808, lng: 21.374947 },
    { lat: 49.115639, lng: 21.375539 },
    { lat: 49.115508, lng: 21.375948 },
    { lat: 49.115491, lng: 21.376054 },
    { lat: 49.115351, lng: 21.377061 },
    { lat: 49.115041, lng: 21.378272 },
    { lat: 49.11492, lng: 21.378786 },
    { lat: 49.11487, lng: 21.379191 },
    { lat: 49.114513, lng: 21.380574 },
    { lat: 49.114141, lng: 21.381996 },
    { lat: 49.113869, lng: 21.383139 },
    { lat: 49.113426, lng: 21.384873 },
    { lat: 49.113367, lng: 21.385095 },
    { lat: 49.112911, lng: 21.386819 },
    { lat: 49.112652, lng: 21.387773 },
    { lat: 49.112384, lng: 21.389024 },
    { lat: 49.11237, lng: 21.389074 },
    { lat: 49.112176, lng: 21.389748 },
    { lat: 49.111625, lng: 21.392213 },
    { lat: 49.111573, lng: 21.392559 },
    { lat: 49.111237, lng: 21.393848 },
    { lat: 49.111156, lng: 21.394179 },
    { lat: 49.11091, lng: 21.395284 },
    { lat: 49.110621, lng: 21.396417 },
    { lat: 49.110266, lng: 21.398038 },
    { lat: 49.1101, lng: 21.398756 },
    { lat: 49.10989, lng: 21.399626 },
    { lat: 49.109705, lng: 21.400296 },
    { lat: 49.109628, lng: 21.400763 },
    { lat: 49.10944, lng: 21.401423 },
    { lat: 49.109375, lng: 21.401725 },
    { lat: 49.109215, lng: 21.402381 },
    { lat: 49.109149, lng: 21.402747 },
    { lat: 49.10897, lng: 21.40348 },
    { lat: 49.10876, lng: 21.404454 },
    { lat: 49.108454, lng: 21.405699 },
    { lat: 49.108265, lng: 21.40628 },
    { lat: 49.107875, lng: 21.407242 },
    { lat: 49.107765, lng: 21.408771 },
    { lat: 49.107335, lng: 21.410033 },
    { lat: 49.106986, lng: 21.411813 },
    { lat: 49.106628, lng: 21.411878 },
    { lat: 49.106577, lng: 21.412362 },
    { lat: 49.106597, lng: 21.412602 },
    { lat: 49.10603, lng: 21.412661 },
    { lat: 49.106026, lng: 21.413131 },
    { lat: 49.105664, lng: 21.413737 },
    { lat: 49.105538, lng: 21.414072 },
    { lat: 49.105303, lng: 21.414332 },
    { lat: 49.105332, lng: 21.414618 },
    { lat: 49.1055349, lng: 21.4151418 },
    { lat: 49.1059015, lng: 21.4155995 },
    { lat: 49.1060512, lng: 21.4156723 },
    { lat: 49.1078079, lng: 21.4157282 },
    { lat: 49.1088643, lng: 21.4165682 },
    { lat: 49.1093932, lng: 21.4166444 },
    { lat: 49.1100595, lng: 21.4171906 },
    { lat: 49.1109736, lng: 21.4174406 },
    { lat: 49.1114629, lng: 21.4170888 },
    { lat: 49.1115817, lng: 21.4166117 },
    { lat: 49.1115204, lng: 21.4157845 },
    { lat: 49.1116597, lng: 21.4154672 },
    { lat: 49.1120212, lng: 21.4150189 },
    { lat: 49.1132013, lng: 21.4144017 },
    { lat: 49.1136148, lng: 21.4144257 },
    { lat: 49.1142991, lng: 21.41504 },
    { lat: 49.1144142, lng: 21.4152804 },
    { lat: 49.1152811, lng: 21.4159172 },
    { lat: 49.1161938, lng: 21.4164256 },
    { lat: 49.1168571, lng: 21.4170949 },
    { lat: 49.1179303, lng: 21.4178597 },
    { lat: 49.1184555, lng: 21.4183567 },
    { lat: 49.119, lng: 21.4186172 },
    { lat: 49.1202698, lng: 21.4194469 },
    { lat: 49.1209212, lng: 21.4194131 },
    { lat: 49.1210223, lng: 21.4197475 },
    { lat: 49.1212287, lng: 21.4198402 },
    { lat: 49.1211671, lng: 21.419927 },
    { lat: 49.1213413, lng: 21.4198473 },
    { lat: 49.1212593, lng: 21.4200403 },
    { lat: 49.1214755, lng: 21.4200501 },
    { lat: 49.1216265, lng: 21.4201325 },
    { lat: 49.1219122, lng: 21.4201728 },
    { lat: 49.122349, lng: 21.4200627 },
    { lat: 49.1227158, lng: 21.4201732 },
    { lat: 49.1244916, lng: 21.4196874 },
    { lat: 49.1247509, lng: 21.4197032 },
    { lat: 49.1249704, lng: 21.4194763 },
    { lat: 49.1254112, lng: 21.4195075 },
    { lat: 49.1255039, lng: 21.4193291 },
    { lat: 49.1261528, lng: 21.4192452 },
    { lat: 49.1265788, lng: 21.4191121 },
    { lat: 49.127653, lng: 21.418524 },
    { lat: 49.1282064, lng: 21.4180987 },
    { lat: 49.1286568, lng: 21.4178346 },
    { lat: 49.1292661, lng: 21.4178202 },
    { lat: 49.1294873, lng: 21.4184195 },
    { lat: 49.129701, lng: 21.418255 },
    { lat: 49.129768, lng: 21.418172 },
    { lat: 49.129626, lng: 21.4178 },
    { lat: 49.129553, lng: 21.417564 },
    { lat: 49.129527, lng: 21.41748 },
    { lat: 49.129486, lng: 21.417346 },
    { lat: 49.128807, lng: 21.415137 },
    { lat: 49.129077, lng: 21.414955 },
    { lat: 49.128974, lng: 21.41435 },
    { lat: 49.128839, lng: 21.413141 },
    { lat: 49.129144, lng: 21.413185 },
    { lat: 49.129262, lng: 21.411995 },
    { lat: 49.12904, lng: 21.411916 },
    { lat: 49.129388, lng: 21.407635 },
    { lat: 49.129596, lng: 21.406943 },
    { lat: 49.130221, lng: 21.404869 },
    { lat: 49.130048, lng: 21.404792 },
    { lat: 49.130712, lng: 21.402874 },
    { lat: 49.130451, lng: 21.402713 },
    { lat: 49.13071, lng: 21.401578 },
    { lat: 49.130472, lng: 21.401508 },
    { lat: 49.1306, lng: 21.401021 },
    { lat: 49.130039, lng: 21.400834 },
    { lat: 49.129826, lng: 21.398898 },
    { lat: 49.129679, lng: 21.39897 },
    { lat: 49.129473, lng: 21.399008 },
    { lat: 49.129262, lng: 21.398155 },
    { lat: 49.129051, lng: 21.397303 },
    { lat: 49.129209, lng: 21.395073 },
    { lat: 49.129128, lng: 21.394115 },
    { lat: 49.128752, lng: 21.394188 },
    { lat: 49.128771, lng: 21.391178 },
    { lat: 49.128693, lng: 21.391132 },
    { lat: 49.128669, lng: 21.390442 },
    { lat: 49.128681, lng: 21.389635 },
    { lat: 49.128684, lng: 21.389204 },
    { lat: 49.12881, lng: 21.388344 },
    { lat: 49.128415, lng: 21.387763 },
    { lat: 49.128606, lng: 21.387296 },
    { lat: 49.128819, lng: 21.387058 },
    { lat: 49.128886, lng: 21.386804 },
    { lat: 49.12889, lng: 21.386709 },
    { lat: 49.128899, lng: 21.386586 },
    { lat: 49.129086, lng: 21.386367 },
  ],
  VyšnýOrlík: [
    { lat: 49.3735633, lng: 21.4995992 },
    { lat: 49.3722899, lng: 21.4969834 },
    { lat: 49.3715185, lng: 21.494584 },
    { lat: 49.3703653, lng: 21.4921319 },
    { lat: 49.3699239, lng: 21.4903005 },
    { lat: 49.3705589, lng: 21.4892463 },
    { lat: 49.3702677, lng: 21.4876406 },
    { lat: 49.3701053, lng: 21.4875821 },
    { lat: 49.3695623, lng: 21.4871242 },
    { lat: 49.3693536, lng: 21.4866256 },
    { lat: 49.3687348, lng: 21.4863537 },
    { lat: 49.3655754, lng: 21.4854651 },
    { lat: 49.364526, lng: 21.4858638 },
    { lat: 49.3641123, lng: 21.4863462 },
    { lat: 49.3640755, lng: 21.4868731 },
    { lat: 49.3627355, lng: 21.4860012 },
    { lat: 49.3628176, lng: 21.4851688 },
    { lat: 49.362177, lng: 21.4844367 },
    { lat: 49.3606111, lng: 21.4840517 },
    { lat: 49.3601865, lng: 21.4842252 },
    { lat: 49.3585586, lng: 21.4841465 },
    { lat: 49.3557277, lng: 21.4844037 },
    { lat: 49.3547807, lng: 21.484064 },
    { lat: 49.3543833, lng: 21.4834933 },
    { lat: 49.3534099, lng: 21.4828584 },
    { lat: 49.3525985, lng: 21.4806054 },
    { lat: 49.3501558, lng: 21.481058 },
    { lat: 49.3480016, lng: 21.4808262 },
    { lat: 49.3459268, lng: 21.4808995 },
    { lat: 49.3439209, lng: 21.4792406 },
    { lat: 49.3427433, lng: 21.4771281 },
    { lat: 49.3419267, lng: 21.4744914 },
    { lat: 49.3412442, lng: 21.4732885 },
    { lat: 49.3399545, lng: 21.4707226 },
    { lat: 49.3382181, lng: 21.4654885 },
    { lat: 49.3379808, lng: 21.4624319 },
    { lat: 49.3362588, lng: 21.4625254 },
    { lat: 49.3354044, lng: 21.4606703 },
    { lat: 49.3339687, lng: 21.4592072 },
    { lat: 49.3336612, lng: 21.4589689 },
    { lat: 49.333333, lng: 21.4592514 },
    { lat: 49.332802, lng: 21.4595592 },
    { lat: 49.3321501, lng: 21.4597799 },
    { lat: 49.3319762, lng: 21.4597151 },
    { lat: 49.3317576, lng: 21.45982 },
    { lat: 49.33197, lng: 21.4618142 },
    { lat: 49.3299128, lng: 21.4662475 },
    { lat: 49.328793, lng: 21.4669352 },
    { lat: 49.3273753, lng: 21.4670435 },
    { lat: 49.327174, lng: 21.4674401 },
    { lat: 49.3273798, lng: 21.468852 },
    { lat: 49.3270173, lng: 21.4689196 },
    { lat: 49.3266874, lng: 21.4677335 },
    { lat: 49.3262605, lng: 21.4678326 },
    { lat: 49.3251368, lng: 21.4668578 },
    { lat: 49.3248349, lng: 21.4670422 },
    { lat: 49.324511, lng: 21.4667733 },
    { lat: 49.3241831, lng: 21.4668714 },
    { lat: 49.3239431, lng: 21.4672496 },
    { lat: 49.323665, lng: 21.4673166 },
    { lat: 49.3234837, lng: 21.46767 },
    { lat: 49.3234429, lng: 21.4681297 },
    { lat: 49.3229179, lng: 21.4684163 },
    { lat: 49.3227417, lng: 21.4686666 },
    { lat: 49.3223718, lng: 21.468946 },
    { lat: 49.3223512, lng: 21.4689366 },
    { lat: 49.322264, lng: 21.4691487 },
    { lat: 49.322195, lng: 21.469646 },
    { lat: 49.3222558, lng: 21.4700393 },
    { lat: 49.3221771, lng: 21.4705488 },
    { lat: 49.3222115, lng: 21.4708116 },
    { lat: 49.3221266, lng: 21.4716161 },
    { lat: 49.3219502, lng: 21.4720906 },
    { lat: 49.321604, lng: 21.472736 },
    { lat: 49.3215681, lng: 21.4732844 },
    { lat: 49.3217241, lng: 21.4737442 },
    { lat: 49.3216081, lng: 21.4747953 },
    { lat: 49.3216871, lng: 21.47512 },
    { lat: 49.3216639, lng: 21.4753693 },
    { lat: 49.3217525, lng: 21.4757968 },
    { lat: 49.3217733, lng: 21.4763245 },
    { lat: 49.32173, lng: 21.4769701 },
    { lat: 49.3220887, lng: 21.4778739 },
    { lat: 49.3222878, lng: 21.4785786 },
    { lat: 49.3226568, lng: 21.4794469 },
    { lat: 49.3223166, lng: 21.4798245 },
    { lat: 49.3220039, lng: 21.4803734 },
    { lat: 49.3218958, lng: 21.4809216 },
    { lat: 49.3239916, lng: 21.4828443 },
    { lat: 49.3253266, lng: 21.4846845 },
    { lat: 49.3259131, lng: 21.4864775 },
    { lat: 49.3262793, lng: 21.4885853 },
    { lat: 49.3264613, lng: 21.4893226 },
    { lat: 49.3269566, lng: 21.4906789 },
    { lat: 49.3277027, lng: 21.4922662 },
    { lat: 49.3282788, lng: 21.4936878 },
    { lat: 49.3286472, lng: 21.4963529 },
    { lat: 49.3287688, lng: 21.4983408 },
    { lat: 49.328746, lng: 21.4996382 },
    { lat: 49.3287825, lng: 21.5014349 },
    { lat: 49.3286243, lng: 21.5031864 },
    { lat: 49.3286361, lng: 21.5038832 },
    { lat: 49.3286977, lng: 21.5044255 },
    { lat: 49.3288024, lng: 21.5048258 },
    { lat: 49.3288794, lng: 21.5050042 },
    { lat: 49.3290325, lng: 21.5050824 },
    { lat: 49.3304333, lng: 21.5061652 },
    { lat: 49.3308082, lng: 21.506567 },
    { lat: 49.3310294, lng: 21.5069509 },
    { lat: 49.3313987, lng: 21.5071251 },
    { lat: 49.331861, lng: 21.5075557 },
    { lat: 49.3330626, lng: 21.5083568 },
    { lat: 49.3332301, lng: 21.5086285 },
    { lat: 49.3337527, lng: 21.5088095 },
    { lat: 49.334173, lng: 21.5098226 },
    { lat: 49.3344533, lng: 21.5100324 },
    { lat: 49.3345835, lng: 21.5104169 },
    { lat: 49.3346009, lng: 21.5107155 },
    { lat: 49.3349901, lng: 21.5113466 },
    { lat: 49.3353935, lng: 21.5116488 },
    { lat: 49.335852, lng: 21.5127825 },
    { lat: 49.3361418, lng: 21.5132852 },
    { lat: 49.3368929, lng: 21.5137852 },
    { lat: 49.3376046, lng: 21.5146536 },
    { lat: 49.3378241, lng: 21.5144558 },
    { lat: 49.3380488, lng: 21.5140058 },
    { lat: 49.3382491, lng: 21.5136942 },
    { lat: 49.338336, lng: 21.5136585 },
    { lat: 49.3387438, lng: 21.5138825 },
    { lat: 49.3394184, lng: 21.5145063 },
    { lat: 49.3400351, lng: 21.5149481 },
    { lat: 49.3401603, lng: 21.5151141 },
    { lat: 49.3402549, lng: 21.5156017 },
    { lat: 49.3406557, lng: 21.5158883 },
    { lat: 49.3408686, lng: 21.5161794 },
    { lat: 49.3418841, lng: 21.5169625 },
    { lat: 49.3422123, lng: 21.5176738 },
    { lat: 49.342804, lng: 21.5180665 },
    { lat: 49.3434928, lng: 21.5190314 },
    { lat: 49.3439588, lng: 21.5192402 },
    { lat: 49.3441474, lng: 21.5191083 },
    { lat: 49.3441687, lng: 21.5192674 },
    { lat: 49.344059, lng: 21.5195662 },
    { lat: 49.3440688, lng: 21.5197138 },
    { lat: 49.3443358, lng: 21.5200436 },
    { lat: 49.344715, lng: 21.5203248 },
    { lat: 49.3451376, lng: 21.5211483 },
    { lat: 49.3456928, lng: 21.5225281 },
    { lat: 49.3467068, lng: 21.5230982 },
    { lat: 49.3481414, lng: 21.5242663 },
    { lat: 49.3495443, lng: 21.5248292 },
    { lat: 49.3516078, lng: 21.5258363 },
    { lat: 49.3520826, lng: 21.5255199 },
    { lat: 49.352825, lng: 21.5253171 },
    { lat: 49.353302, lng: 21.5249601 },
    { lat: 49.3534954, lng: 21.5249127 },
    { lat: 49.3538294, lng: 21.5251129 },
    { lat: 49.354136, lng: 21.5250197 },
    { lat: 49.3543512, lng: 21.5251105 },
    { lat: 49.3545828, lng: 21.5250227 },
    { lat: 49.3553141, lng: 21.5244312 },
    { lat: 49.3555433, lng: 21.5238975 },
    { lat: 49.3556226, lng: 21.5240016 },
    { lat: 49.3558742, lng: 21.5239034 },
    { lat: 49.3571069, lng: 21.5237739 },
    { lat: 49.357274, lng: 21.5235633 },
    { lat: 49.3582419, lng: 21.5228194 },
    { lat: 49.3584977, lng: 21.5227134 },
    { lat: 49.358631, lng: 21.5224165 },
    { lat: 49.358769, lng: 21.5223172 },
    { lat: 49.3588684, lng: 21.5222569 },
    { lat: 49.3591749, lng: 21.5222741 },
    { lat: 49.3595424, lng: 21.5221423 },
    { lat: 49.3604852, lng: 21.5216688 },
    { lat: 49.3621645, lng: 21.5224972 },
    { lat: 49.3627119, lng: 21.5226375 },
    { lat: 49.3632243, lng: 21.5229782 },
    { lat: 49.3636367, lng: 21.523082 },
    { lat: 49.3642422, lng: 21.523414 },
    { lat: 49.3650307, lng: 21.523647 },
    { lat: 49.3665571, lng: 21.5249192 },
    { lat: 49.3668956, lng: 21.5256378 },
    { lat: 49.3675097, lng: 21.5273457 },
    { lat: 49.3677836, lng: 21.5278496 },
    { lat: 49.3678999, lng: 21.5275412 },
    { lat: 49.3678277, lng: 21.5274565 },
    { lat: 49.3679361, lng: 21.5269093 },
    { lat: 49.3681521, lng: 21.524874 },
    { lat: 49.3675169, lng: 21.5237728 },
    { lat: 49.3672755, lng: 21.5235123 },
    { lat: 49.3672762, lng: 21.5232932 },
    { lat: 49.3671454, lng: 21.5231203 },
    { lat: 49.3671487, lng: 21.5230035 },
    { lat: 49.3670675, lng: 21.5229579 },
    { lat: 49.3670749, lng: 21.5225752 },
    { lat: 49.3669925, lng: 21.5218519 },
    { lat: 49.3667137, lng: 21.5204615 },
    { lat: 49.3667384, lng: 21.5194243 },
    { lat: 49.3665777, lng: 21.5182749 },
    { lat: 49.3664558, lng: 21.5181812 },
    { lat: 49.3662532, lng: 21.5181368 },
    { lat: 49.3660321, lng: 21.5181969 },
    { lat: 49.3658551, lng: 21.5181033 },
    { lat: 49.3657416, lng: 21.5176683 },
    { lat: 49.3656504, lng: 21.5170333 },
    { lat: 49.3654664, lng: 21.516329 },
    { lat: 49.365527, lng: 21.5162874 },
    { lat: 49.3654986, lng: 21.5158757 },
    { lat: 49.3655265, lng: 21.5149949 },
    { lat: 49.3655503, lng: 21.5148847 },
    { lat: 49.3657639, lng: 21.51492 },
    { lat: 49.3662304, lng: 21.5137532 },
    { lat: 49.3661859, lng: 21.5135287 },
    { lat: 49.3666093, lng: 21.5129462 },
    { lat: 49.366595, lng: 21.51284 },
    { lat: 49.3666889, lng: 21.5124183 },
    { lat: 49.3666884, lng: 21.5121281 },
    { lat: 49.3665185, lng: 21.5120029 },
    { lat: 49.3665096, lng: 21.5117732 },
    { lat: 49.3669876, lng: 21.509265 },
    { lat: 49.3676653, lng: 21.5063024 },
    { lat: 49.3678334, lng: 21.5064038 },
    { lat: 49.368081, lng: 21.5063632 },
    { lat: 49.3687688, lng: 21.5058215 },
    { lat: 49.3689619, lng: 21.5058487 },
    { lat: 49.3693075, lng: 21.5062564 },
    { lat: 49.3696233, lng: 21.5064039 },
    { lat: 49.3699596, lng: 21.5062412 },
    { lat: 49.3704707, lng: 21.5061751 },
    { lat: 49.3704711, lng: 21.5056328 },
    { lat: 49.3705542, lng: 21.5052717 },
    { lat: 49.3704777, lng: 21.5049917 },
    { lat: 49.3704613, lng: 21.5046975 },
    { lat: 49.3700549, lng: 21.5040895 },
    { lat: 49.3697395, lng: 21.5037178 },
    { lat: 49.3695144, lng: 21.5007774 },
    { lat: 49.3697555, lng: 21.5004769 },
    { lat: 49.3724431, lng: 21.4992919 },
    { lat: 49.3726763, lng: 21.4993431 },
    { lat: 49.3727632, lng: 21.4992731 },
    { lat: 49.3731034, lng: 21.4995313 },
    { lat: 49.3735173, lng: 21.499647 },
    { lat: 49.3735633, lng: 21.4995992 },
  ],
  Kapišová: [
    { lat: 49.3494556, lng: 21.5706132 },
    { lat: 49.3485779, lng: 21.5709659 },
    { lat: 49.3471445, lng: 21.5717643 },
    { lat: 49.3464924, lng: 21.572506 },
    { lat: 49.345439, lng: 21.5732182 },
    { lat: 49.3439845, lng: 21.5733353 },
    { lat: 49.3431049, lng: 21.5734997 },
    { lat: 49.3429809, lng: 21.5739757 },
    { lat: 49.3423626, lng: 21.5749258 },
    { lat: 49.3412997, lng: 21.5750963 },
    { lat: 49.340956, lng: 21.5754765 },
    { lat: 49.3397375, lng: 21.5771294 },
    { lat: 49.338768, lng: 21.5772941 },
    { lat: 49.3373155, lng: 21.577974 },
    { lat: 49.3362123, lng: 21.5789241 },
    { lat: 49.3349168, lng: 21.5792589 },
    { lat: 49.3338826, lng: 21.5800176 },
    { lat: 49.3337355, lng: 21.5803493 },
    { lat: 49.3338792, lng: 21.5820035 },
    { lat: 49.3336224, lng: 21.5833284 },
    { lat: 49.3331878, lng: 21.5869286 },
    { lat: 49.3329215, lng: 21.5876312 },
    { lat: 49.3329739, lng: 21.5887903 },
    { lat: 49.3323877, lng: 21.591673 },
    { lat: 49.3320099, lng: 21.593226 },
    { lat: 49.3314664, lng: 21.5944854 },
    { lat: 49.3313407, lng: 21.5946616 },
    { lat: 49.3307009, lng: 21.5957702 },
    { lat: 49.3307934, lng: 21.5958165 },
    { lat: 49.3320427, lng: 21.5963258 },
    { lat: 49.3319259, lng: 21.5965447 },
    { lat: 49.332237, lng: 21.5966004 },
    { lat: 49.332257, lng: 21.5970438 },
    { lat: 49.3324369, lng: 21.5975346 },
    { lat: 49.3323499, lng: 21.5977195 },
    { lat: 49.3326016, lng: 21.5981198 },
    { lat: 49.3325549, lng: 21.5984601 },
    { lat: 49.332571, lng: 21.5988825 },
    { lat: 49.3323891, lng: 21.5996297 },
    { lat: 49.3322506, lng: 21.5998283 },
    { lat: 49.3322984, lng: 21.6004182 },
    { lat: 49.3321548, lng: 21.6009267 },
    { lat: 49.3321813, lng: 21.6011554 },
    { lat: 49.3323219, lng: 21.6013538 },
    { lat: 49.3323627, lng: 21.6020588 },
    { lat: 49.3328985, lng: 21.6022796 },
    { lat: 49.3345964, lng: 21.603966 },
    { lat: 49.3359484, lng: 21.6055092 },
    { lat: 49.3373457, lng: 21.6065432 },
    { lat: 49.3376637, lng: 21.6068524 },
    { lat: 49.3385005, lng: 21.6084034 },
    { lat: 49.3391263, lng: 21.6101018 },
    { lat: 49.3397409, lng: 21.611134 },
    { lat: 49.3415329, lng: 21.6157618 },
    { lat: 49.3418704, lng: 21.6170829 },
    { lat: 49.3425479, lng: 21.6166819 },
    { lat: 49.344465, lng: 21.6204662 },
    { lat: 49.3447933, lng: 21.6209527 },
    { lat: 49.3449589, lng: 21.6209649 },
    { lat: 49.3487499, lng: 21.6186669 },
    { lat: 49.3495584, lng: 21.6187784 },
    { lat: 49.3495819, lng: 21.6191439 },
    { lat: 49.3509856, lng: 21.6191783 },
    { lat: 49.3523724, lng: 21.6193339 },
    { lat: 49.3527895, lng: 21.6185053 },
    { lat: 49.3543757, lng: 21.6153522 },
    { lat: 49.3539733, lng: 21.6133559 },
    { lat: 49.355273, lng: 21.6127549 },
    { lat: 49.3556856, lng: 21.6110313 },
    { lat: 49.3556567, lng: 21.6103829 },
    { lat: 49.3558608, lng: 21.608966 },
    { lat: 49.355928, lng: 21.6072776 },
    { lat: 49.3562036, lng: 21.6061758 },
    { lat: 49.3561049, lng: 21.6037792 },
    { lat: 49.3562037, lng: 21.6023836 },
    { lat: 49.3561317, lng: 21.6016026 },
    { lat: 49.356233, lng: 21.6000946 },
    { lat: 49.3563454, lng: 21.5999292 },
    { lat: 49.3555243, lng: 21.5983003 },
    { lat: 49.3566002, lng: 21.5984827 },
    { lat: 49.3563725, lng: 21.5979713 },
    { lat: 49.3563017, lng: 21.5978138 },
    { lat: 49.3562765, lng: 21.5973445 },
    { lat: 49.3560613, lng: 21.5971336 },
    { lat: 49.3560036, lng: 21.5967173 },
    { lat: 49.3560579, lng: 21.5963154 },
    { lat: 49.3559728, lng: 21.5962484 },
    { lat: 49.3559563, lng: 21.595487 },
    { lat: 49.3557207, lng: 21.5952294 },
    { lat: 49.3555619, lng: 21.5951368 },
    { lat: 49.3545465, lng: 21.5952765 },
    { lat: 49.3544866, lng: 21.5950645 },
    { lat: 49.3546118, lng: 21.594705 },
    { lat: 49.3548934, lng: 21.5945576 },
    { lat: 49.3550059, lng: 21.5943962 },
    { lat: 49.3551846, lng: 21.5942703 },
    { lat: 49.3551726, lng: 21.5941588 },
    { lat: 49.3546629, lng: 21.5936628 },
    { lat: 49.3540574, lng: 21.593571 },
    { lat: 49.353583, lng: 21.5928078 },
    { lat: 49.3528801, lng: 21.5932611 },
    { lat: 49.3528315, lng: 21.5939079 },
    { lat: 49.3526765, lng: 21.5942438 },
    { lat: 49.3523374, lng: 21.5943404 },
    { lat: 49.3523886, lng: 21.5933116 },
    { lat: 49.3521039, lng: 21.5926885 },
    { lat: 49.3519559, lng: 21.5920168 },
    { lat: 49.3517861, lng: 21.591816 },
    { lat: 49.3516986, lng: 21.5907237 },
    { lat: 49.3505708, lng: 21.5867591 },
    { lat: 49.3504964, lng: 21.5862686 },
    { lat: 49.3506552, lng: 21.5850903 },
    { lat: 49.3508572, lng: 21.5842206 },
    { lat: 49.3511871, lng: 21.5833415 },
    { lat: 49.3515754, lng: 21.5814396 },
    { lat: 49.3516111, lng: 21.5791222 },
    { lat: 49.3515316, lng: 21.5781192 },
    { lat: 49.351608, lng: 21.5739001 },
    { lat: 49.3507281, lng: 21.5732083 },
    { lat: 49.3506113, lng: 21.5726119 },
    { lat: 49.3506144, lng: 21.5713066 },
    { lat: 49.3504596, lng: 21.5709945 },
    { lat: 49.3494556, lng: 21.5706132 },
  ],
  Soboš: [
    { lat: 49.1304158, lng: 21.5610998 },
    { lat: 49.1303253, lng: 21.5618012 },
    { lat: 49.1302157, lng: 21.5625075 },
    { lat: 49.1306142, lng: 21.5634443 },
    { lat: 49.1307332, lng: 21.5635939 },
    { lat: 49.1308702, lng: 21.5641516 },
    { lat: 49.1310832, lng: 21.5644858 },
    { lat: 49.1315021, lng: 21.5646619 },
    { lat: 49.1321485, lng: 21.5647194 },
    { lat: 49.1326461, lng: 21.5646903 },
    { lat: 49.1330829, lng: 21.564866 },
    { lat: 49.1330773, lng: 21.5650288 },
    { lat: 49.1332314, lng: 21.5649853 },
    { lat: 49.1334496, lng: 21.5651813 },
    { lat: 49.1338471, lng: 21.5653606 },
    { lat: 49.1339786, lng: 21.5655091 },
    { lat: 49.1340201, lng: 21.5653832 },
    { lat: 49.1345498, lng: 21.5659045 },
    { lat: 49.1353771, lng: 21.5671209 },
    { lat: 49.1352423, lng: 21.567375 },
    { lat: 49.1356469, lng: 21.5676814 },
    { lat: 49.1344336, lng: 21.5722134 },
    { lat: 49.1345898, lng: 21.5725213 },
    { lat: 49.1346429, lng: 21.5728958 },
    { lat: 49.1349306, lng: 21.5734908 },
    { lat: 49.1348941, lng: 21.5736778 },
    { lat: 49.1350592, lng: 21.5738255 },
    { lat: 49.1352877, lng: 21.5754397 },
    { lat: 49.1352744, lng: 21.5759788 },
    { lat: 49.1353825, lng: 21.5762596 },
    { lat: 49.1352726, lng: 21.5764722 },
    { lat: 49.1352782, lng: 21.5768336 },
    { lat: 49.1353577, lng: 21.577062 },
    { lat: 49.1353168, lng: 21.5780552 },
    { lat: 49.1353535, lng: 21.5786869 },
    { lat: 49.1353277, lng: 21.5789707 },
    { lat: 49.1352561, lng: 21.579031 },
    { lat: 49.1350607, lng: 21.5798132 },
    { lat: 49.1349812, lng: 21.5811146 },
    { lat: 49.1352459, lng: 21.5823834 },
    { lat: 49.135362, lng: 21.582674 },
    { lat: 49.1358249, lng: 21.5834572 },
    { lat: 49.136083, lng: 21.5839992 },
    { lat: 49.136532, lng: 21.584282 },
    { lat: 49.1368402, lng: 21.5848894 },
    { lat: 49.1369696, lng: 21.5848947 },
    { lat: 49.1370062, lng: 21.5851935 },
    { lat: 49.1369598, lng: 21.585915 },
    { lat: 49.1371877, lng: 21.5863807 },
    { lat: 49.1375434, lng: 21.5866768 },
    { lat: 49.1379213, lng: 21.5871583 },
    { lat: 49.1390226, lng: 21.5878848 },
    { lat: 49.1396174, lng: 21.5877936 },
    { lat: 49.139803, lng: 21.5880317 },
    { lat: 49.1401384, lng: 21.5880711 },
    { lat: 49.141155, lng: 21.588573 },
    { lat: 49.141336, lng: 21.588545 },
    { lat: 49.14127, lng: 21.588482 },
    { lat: 49.141494, lng: 21.587311 },
    { lat: 49.141626, lng: 21.586395 },
    { lat: 49.141694, lng: 21.586423 },
    { lat: 49.141897, lng: 21.586522 },
    { lat: 49.142102, lng: 21.585742 },
    { lat: 49.1429, lng: 21.584152 },
    { lat: 49.143084, lng: 21.583846 },
    { lat: 49.143259, lng: 21.583589 },
    { lat: 49.143354, lng: 21.583398 },
    { lat: 49.143402, lng: 21.583291 },
    { lat: 49.143919, lng: 21.582968 },
    { lat: 49.144519, lng: 21.582701 },
    { lat: 49.14514, lng: 21.582426 },
    { lat: 49.145308, lng: 21.5824 },
    { lat: 49.145313, lng: 21.582354 },
    { lat: 49.145941, lng: 21.581855 },
    { lat: 49.14623, lng: 21.581802 },
    { lat: 49.146442, lng: 21.581616 },
    { lat: 49.146631, lng: 21.581414 },
    { lat: 49.146827, lng: 21.581035 },
    { lat: 49.146845, lng: 21.581045 },
    { lat: 49.147197, lng: 21.580541 },
    { lat: 49.147534, lng: 21.580409 },
    { lat: 49.148232, lng: 21.579719 },
    { lat: 49.148598, lng: 21.579392 },
    { lat: 49.149563, lng: 21.578989 },
    { lat: 49.149624, lng: 21.579098 },
    { lat: 49.149999, lng: 21.578855 },
    { lat: 49.150396, lng: 21.578543 },
    { lat: 49.150552, lng: 21.578426 },
    { lat: 49.151007, lng: 21.578164 },
    { lat: 49.151376, lng: 21.577794 },
    { lat: 49.151686, lng: 21.577389 },
    { lat: 49.152285, lng: 21.576753 },
    { lat: 49.152605, lng: 21.576511 },
    { lat: 49.153303, lng: 21.575773 },
    { lat: 49.153904, lng: 21.575352 },
    { lat: 49.15397, lng: 21.575159 },
    { lat: 49.1541, lng: 21.575042 },
    { lat: 49.154435, lng: 21.57467 },
    { lat: 49.154493, lng: 21.574471 },
    { lat: 49.154622, lng: 21.574283 },
    { lat: 49.15487, lng: 21.57402 },
    { lat: 49.155225, lng: 21.573507 },
    { lat: 49.155291, lng: 21.573353 },
    { lat: 49.155529, lng: 21.573101 },
    { lat: 49.15595, lng: 21.572445 },
    { lat: 49.156045, lng: 21.572361 },
    { lat: 49.156309, lng: 21.571831 },
    { lat: 49.156326, lng: 21.571651 },
    { lat: 49.156689, lng: 21.571224 },
    { lat: 49.157003, lng: 21.570771 },
    { lat: 49.157593, lng: 21.570511 },
    { lat: 49.158135, lng: 21.570282 },
    { lat: 49.158591, lng: 21.570054 },
    { lat: 49.158731, lng: 21.569817 },
    { lat: 49.158821, lng: 21.569664 },
    { lat: 49.158935, lng: 21.569567 },
    { lat: 49.159091, lng: 21.569522 },
    { lat: 49.15917, lng: 21.569418 },
    { lat: 49.1590813, lng: 21.5674528 },
    { lat: 49.1591213, lng: 21.567434 },
    { lat: 49.1590999, lng: 21.5672676 },
    { lat: 49.1592848, lng: 21.5669252 },
    { lat: 49.1593418, lng: 21.5665836 },
    { lat: 49.1592232, lng: 21.5663325 },
    { lat: 49.1593387, lng: 21.5661126 },
    { lat: 49.1594521, lng: 21.5655391 },
    { lat: 49.159469, lng: 21.5652242 },
    { lat: 49.1598889, lng: 21.5640624 },
    { lat: 49.1598002, lng: 21.5638342 },
    { lat: 49.159858, lng: 21.5636399 },
    { lat: 49.1601424, lng: 21.5631124 },
    { lat: 49.1604091, lng: 21.5628395 },
    { lat: 49.1604722, lng: 21.5623968 },
    { lat: 49.1601799, lng: 21.5618414 },
    { lat: 49.1603046, lng: 21.5617158 },
    { lat: 49.1600971, lng: 21.5614953 },
    { lat: 49.1599462, lng: 21.5610892 },
    { lat: 49.1596975, lng: 21.5610824 },
    { lat: 49.1596555, lng: 21.5609557 },
    { lat: 49.1596298, lng: 21.5606788 },
    { lat: 49.1597765, lng: 21.5604532 },
    { lat: 49.1598472, lng: 21.5600489 },
    { lat: 49.1598197, lng: 21.5598652 },
    { lat: 49.1591109, lng: 21.5597018 },
    { lat: 49.1595574, lng: 21.5588179 },
    { lat: 49.1595708, lng: 21.5586129 },
    { lat: 49.1598538, lng: 21.55791 },
    { lat: 49.1597242, lng: 21.5578214 },
    { lat: 49.1601266, lng: 21.5572078 },
    { lat: 49.1603415, lng: 21.5565637 },
    { lat: 49.1603372, lng: 21.5564529 },
    { lat: 49.1606274, lng: 21.5557284 },
    { lat: 49.1606876, lng: 21.555388 },
    { lat: 49.1609888, lng: 21.5546224 },
    { lat: 49.1610742, lng: 21.5538487 },
    { lat: 49.1610654, lng: 21.5531738 },
    { lat: 49.1609955, lng: 21.5527772 },
    { lat: 49.1608979, lng: 21.5526243 },
    { lat: 49.160775, lng: 21.552095 },
    { lat: 49.1606527, lng: 21.5520627 },
    { lat: 49.160719, lng: 21.5513754 },
    { lat: 49.1608365, lng: 21.5513199 },
    { lat: 49.1609038, lng: 21.5511063 },
    { lat: 49.1609611, lng: 21.5505436 },
    { lat: 49.1606934, lng: 21.5504647 },
    { lat: 49.1606461, lng: 21.5506281 },
    { lat: 49.1605815, lng: 21.549931 },
    { lat: 49.160806, lng: 21.5492287 },
    { lat: 49.160586, lng: 21.5490518 },
    { lat: 49.1606966, lng: 21.5485823 },
    { lat: 49.1607689, lng: 21.5478759 },
    { lat: 49.1609435, lng: 21.5475953 },
    { lat: 49.1609644, lng: 21.5466212 },
    { lat: 49.1610633, lng: 21.546179 },
    { lat: 49.1611843, lng: 21.5448849 },
    { lat: 49.1610665, lng: 21.5448118 },
    { lat: 49.1610645, lng: 21.5445071 },
    { lat: 49.1608878, lng: 21.5445434 },
    { lat: 49.1607361, lng: 21.5444214 },
    { lat: 49.1605814, lng: 21.5439689 },
    { lat: 49.1606302, lng: 21.5439025 },
    { lat: 49.1603237, lng: 21.5433661 },
    { lat: 49.1599611, lng: 21.5434718 },
    { lat: 49.1598094, lng: 21.5433616 },
    { lat: 49.1596009, lng: 21.5431887 },
    { lat: 49.159064, lng: 21.5444909 },
    { lat: 49.1589711, lng: 21.544433 },
    { lat: 49.1586543, lng: 21.5445663 },
    { lat: 49.1584826, lng: 21.5445419 },
    { lat: 49.1582518, lng: 21.5442961 },
    { lat: 49.1581362, lng: 21.5443126 },
    { lat: 49.1579223, lng: 21.544543 },
    { lat: 49.1577902, lng: 21.5445667 },
    { lat: 49.1574928, lng: 21.5440744 },
    { lat: 49.1574388, lng: 21.543832 },
    { lat: 49.157266, lng: 21.5436072 },
    { lat: 49.1567464, lng: 21.5437727 },
    { lat: 49.1566548, lng: 21.5443201 },
    { lat: 49.1567042, lng: 21.5444333 },
    { lat: 49.1565154, lng: 21.5446796 },
    { lat: 49.1561568, lng: 21.5446955 },
    { lat: 49.1558712, lng: 21.5442958 },
    { lat: 49.155472, lng: 21.5444625 },
    { lat: 49.1555382, lng: 21.5449391 },
    { lat: 49.1552778, lng: 21.5451161 },
    { lat: 49.1551667, lng: 21.5446888 },
    { lat: 49.1550905, lng: 21.5446183 },
    { lat: 49.1549363, lng: 21.5446927 },
    { lat: 49.1544723, lng: 21.5452349 },
    { lat: 49.1542251, lng: 21.5456518 },
    { lat: 49.1545279, lng: 21.5463307 },
    { lat: 49.1542897, lng: 21.5471094 },
    { lat: 49.1542121, lng: 21.5472255 },
    { lat: 49.1540151, lng: 21.5473444 },
    { lat: 49.153783, lng: 21.5472895 },
    { lat: 49.1533534, lng: 21.5473701 },
    { lat: 49.1529859, lng: 21.5473071 },
    { lat: 49.1527918, lng: 21.5471688 },
    { lat: 49.1527363, lng: 21.5469561 },
    { lat: 49.1522453, lng: 21.5467391 },
    { lat: 49.1522387, lng: 21.5465404 },
    { lat: 49.1521188, lng: 21.5465587 },
    { lat: 49.1519985, lng: 21.5468404 },
    { lat: 49.1519902, lng: 21.5469919 },
    { lat: 49.1518092, lng: 21.5471943 },
    { lat: 49.1515699, lng: 21.5470417 },
    { lat: 49.1516105, lng: 21.546685 },
    { lat: 49.1511042, lng: 21.5465693 },
    { lat: 49.1510822, lng: 21.5462243 },
    { lat: 49.150828, lng: 21.5461867 },
    { lat: 49.1506206, lng: 21.5462894 },
    { lat: 49.1503945, lng: 21.5460127 },
    { lat: 49.1496741, lng: 21.5461989 },
    { lat: 49.1496499, lng: 21.546419 },
    { lat: 49.1491261, lng: 21.5463975 },
    { lat: 49.1488901, lng: 21.5464478 },
    { lat: 49.1486928, lng: 21.5466745 },
    { lat: 49.1486777, lng: 21.5466151 },
    { lat: 49.1479148, lng: 21.54689 },
    { lat: 49.1475856, lng: 21.5468195 },
    { lat: 49.1475548, lng: 21.5462864 },
    { lat: 49.1468006, lng: 21.5462782 },
    { lat: 49.1466995, lng: 21.5461623 },
    { lat: 49.1467457, lng: 21.5464353 },
    { lat: 49.1464697, lng: 21.5466305 },
    { lat: 49.1464059, lng: 21.5473552 },
    { lat: 49.1462845, lng: 21.5474411 },
    { lat: 49.1461596, lng: 21.5474417 },
    { lat: 49.1460511, lng: 21.5470215 },
    { lat: 49.1459371, lng: 21.5470484 },
    { lat: 49.1459037, lng: 21.5469007 },
    { lat: 49.1457323, lng: 21.5467678 },
    { lat: 49.1453865, lng: 21.5471954 },
    { lat: 49.1452032, lng: 21.5473181 },
    { lat: 49.1450676, lng: 21.5479371 },
    { lat: 49.1451387, lng: 21.5482674 },
    { lat: 49.1451108, lng: 21.5487098 },
    { lat: 49.1447127, lng: 21.5486352 },
    { lat: 49.144505, lng: 21.5483742 },
    { lat: 49.1439434, lng: 21.5487692 },
    { lat: 49.1440522, lng: 21.5491581 },
    { lat: 49.1437674, lng: 21.5494275 },
    { lat: 49.1435775, lng: 21.5494809 },
    { lat: 49.1433983, lng: 21.5493442 },
    { lat: 49.143324, lng: 21.5492599 },
    { lat: 49.1433285, lng: 21.548984 },
    { lat: 49.1428537, lng: 21.5491839 },
    { lat: 49.1428879, lng: 21.5497983 },
    { lat: 49.1426457, lng: 21.5496693 },
    { lat: 49.1425777, lng: 21.5500108 },
    { lat: 49.1418814, lng: 21.5501727 },
    { lat: 49.1413524, lng: 21.5501637 },
    { lat: 49.1408613, lng: 21.550019 },
    { lat: 49.1404083, lng: 21.5497554 },
    { lat: 49.139814, lng: 21.549588 },
    { lat: 49.1395668, lng: 21.5496009 },
    { lat: 49.1393018, lng: 21.5493005 },
    { lat: 49.1391326, lng: 21.549243 },
    { lat: 49.1391311, lng: 21.5489459 },
    { lat: 49.1389869, lng: 21.5483776 },
    { lat: 49.1389887, lng: 21.5477228 },
    { lat: 49.138185, lng: 21.5477473 },
    { lat: 49.1378809, lng: 21.5475372 },
    { lat: 49.1378678, lng: 21.5476677 },
    { lat: 49.137691, lng: 21.5478277 },
    { lat: 49.137697, lng: 21.5486357 },
    { lat: 49.1372154, lng: 21.5484344 },
    { lat: 49.1369603, lng: 21.548603 },
    { lat: 49.1367619, lng: 21.5485463 },
    { lat: 49.136426, lng: 21.548012 },
    { lat: 49.1361404, lng: 21.5478173 },
    { lat: 49.1358038, lng: 21.5472471 },
    { lat: 49.1355263, lng: 21.5476594 },
    { lat: 49.1356575, lng: 21.5479634 },
    { lat: 49.1354338, lng: 21.5484922 },
    { lat: 49.1351226, lng: 21.5485829 },
    { lat: 49.1349771, lng: 21.5487336 },
    { lat: 49.1348626, lng: 21.54898 },
    { lat: 49.1347424, lng: 21.548989 },
    { lat: 49.1345334, lng: 21.548717 },
    { lat: 49.1344309, lng: 21.548793 },
    { lat: 49.1344491, lng: 21.5489963 },
    { lat: 49.1343704, lng: 21.5492414 },
    { lat: 49.1342698, lng: 21.5493863 },
    { lat: 49.1338585, lng: 21.5494828 },
    { lat: 49.1337762, lng: 21.5493952 },
    { lat: 49.1337171, lng: 21.5486521 },
    { lat: 49.1335716, lng: 21.5483573 },
    { lat: 49.1335306, lng: 21.5483406 },
    { lat: 49.1335238, lng: 21.5499775 },
    { lat: 49.1328175, lng: 21.5501134 },
    { lat: 49.1325927, lng: 21.5504777 },
    { lat: 49.1320685, lng: 21.551797 },
    { lat: 49.1315574, lng: 21.5529146 },
    { lat: 49.1313133, lng: 21.553792 },
    { lat: 49.1312714, lng: 21.5542205 },
    { lat: 49.1313626, lng: 21.555866 },
    { lat: 49.1312266, lng: 21.5581177 },
    { lat: 49.13087, lng: 21.5590049 },
    { lat: 49.1306523, lng: 21.5593464 },
    { lat: 49.1304166, lng: 21.5601611 },
    { lat: 49.1304, lng: 21.5606116 },
    { lat: 49.1304361, lng: 21.5609846 },
    { lat: 49.1304158, lng: 21.5610998 },
  ],
  NováPolianka: [
    { lat: 49.3046242, lng: 21.613284 },
    { lat: 49.3045579, lng: 21.6120623 },
    { lat: 49.3044436, lng: 21.6115415 },
    { lat: 49.3042404, lng: 21.6100034 },
    { lat: 49.3036211, lng: 21.6088364 },
    { lat: 49.3033383, lng: 21.6081646 },
    { lat: 49.3025837, lng: 21.6051041 },
    { lat: 49.3026859, lng: 21.6049829 },
    { lat: 49.3025794, lng: 21.6044184 },
    { lat: 49.3026006, lng: 21.603889 },
    { lat: 49.3021028, lng: 21.6030844 },
    { lat: 49.3011216, lng: 21.6019797 },
    { lat: 49.3007002, lng: 21.6019896 },
    { lat: 49.3001921, lng: 21.6021305 },
    { lat: 49.300087, lng: 21.602072 },
    { lat: 49.2999169, lng: 21.6017345 },
    { lat: 49.2987832, lng: 21.6012107 },
    { lat: 49.2980585, lng: 21.6010447 },
    { lat: 49.2976389, lng: 21.6011208 },
    { lat: 49.2975826, lng: 21.6012841 },
    { lat: 49.2973582, lng: 21.6013018 },
    { lat: 49.2969349, lng: 21.6016978 },
    { lat: 49.2965537, lng: 21.6017466 },
    { lat: 49.2959846, lng: 21.6020795 },
    { lat: 49.2952552, lng: 21.6020617 },
    { lat: 49.2945237, lng: 21.6018879 },
    { lat: 49.2944976, lng: 21.6018816 },
    { lat: 49.2944828, lng: 21.6019096 },
    { lat: 49.2944415, lng: 21.6022482 },
    { lat: 49.2931817, lng: 21.6026656 },
    { lat: 49.2930313, lng: 21.6027918 },
    { lat: 49.2918192, lng: 21.6030584 },
    { lat: 49.2915457, lng: 21.6034681 },
    { lat: 49.2907022, lng: 21.6035603 },
    { lat: 49.2897262, lng: 21.6041032 },
    { lat: 49.2894578, lng: 21.6041583 },
    { lat: 49.2893512, lng: 21.6042499 },
    { lat: 49.2892076, lng: 21.6045042 },
    { lat: 49.2889035, lng: 21.6047443 },
    { lat: 49.288608, lng: 21.6048772 },
    { lat: 49.2880696, lng: 21.6049364 },
    { lat: 49.2875724, lng: 21.6052145 },
    { lat: 49.286573, lng: 21.6059787 },
    { lat: 49.2861723, lng: 21.60642 },
    { lat: 49.2845623, lng: 21.6092258 },
    { lat: 49.2842252, lng: 21.6100582 },
    { lat: 49.2840282, lng: 21.6101783 },
    { lat: 49.283596, lng: 21.6107855 },
    { lat: 49.283378, lng: 21.6112838 },
    { lat: 49.2832553, lng: 21.611443 },
    { lat: 49.2821287, lng: 21.614861 },
    { lat: 49.281827, lng: 21.6150169 },
    { lat: 49.2805412, lng: 21.6173892 },
    { lat: 49.280538, lng: 21.617727 },
    { lat: 49.2793178, lng: 21.6177768 },
    { lat: 49.2781557, lng: 21.6182649 },
    { lat: 49.2771048, lng: 21.6190813 },
    { lat: 49.277047, lng: 21.6200081 },
    { lat: 49.2759236, lng: 21.6224003 },
    { lat: 49.275569, lng: 21.623448 },
    { lat: 49.275829, lng: 21.624346 },
    { lat: 49.276506, lng: 21.626672 },
    { lat: 49.275926, lng: 21.628889 },
    { lat: 49.2771, lng: 21.63054 },
    { lat: 49.277139, lng: 21.630502 },
    { lat: 49.277891, lng: 21.631841 },
    { lat: 49.277854, lng: 21.63198 },
    { lat: 49.277857, lng: 21.632076 },
    { lat: 49.278653, lng: 21.633109 },
    { lat: 49.27873, lng: 21.633202 },
    { lat: 49.279113, lng: 21.633985 },
    { lat: 49.27976, lng: 21.634675 },
    { lat: 49.280714, lng: 21.635408 },
    { lat: 49.281414, lng: 21.636607 },
    { lat: 49.281768, lng: 21.636229 },
    { lat: 49.284102, lng: 21.635591 },
    { lat: 49.284916, lng: 21.636458 },
    { lat: 49.285382, lng: 21.636147 },
    { lat: 49.286637, lng: 21.635308 },
    { lat: 49.287886, lng: 21.634514 },
    { lat: 49.288911, lng: 21.633826 },
    { lat: 49.289745, lng: 21.633397 },
    { lat: 49.290979, lng: 21.632185 },
    { lat: 49.29133, lng: 21.632048 },
    { lat: 49.291318, lng: 21.632028 },
    { lat: 49.291472, lng: 21.631952 },
    { lat: 49.291696, lng: 21.631893 },
    { lat: 49.291818, lng: 21.631872 },
    { lat: 49.292117, lng: 21.631829 },
    { lat: 49.292493, lng: 21.631703 },
    { lat: 49.293036, lng: 21.631471 },
    { lat: 49.293583, lng: 21.631274 },
    { lat: 49.293883, lng: 21.631166 },
    { lat: 49.294162, lng: 21.631034 },
    { lat: 49.29442, lng: 21.630931 },
    { lat: 49.295186, lng: 21.630399 },
    { lat: 49.295292, lng: 21.630414 },
    { lat: 49.29544, lng: 21.630542 },
    { lat: 49.295856, lng: 21.630668 },
    { lat: 49.296092, lng: 21.630549 },
    { lat: 49.296242, lng: 21.630591 },
    { lat: 49.296641, lng: 21.630543 },
    { lat: 49.296842, lng: 21.630572 },
    { lat: 49.297058, lng: 21.630751 },
    { lat: 49.297372, lng: 21.630863 },
    { lat: 49.2970922, lng: 21.6294706 },
    { lat: 49.2993761, lng: 21.6274629 },
    { lat: 49.3013083, lng: 21.6255237 },
    { lat: 49.3032867, lng: 21.622881 },
    { lat: 49.3039286, lng: 21.6208093 },
    { lat: 49.3046534, lng: 21.6147576 },
    { lat: 49.3046435, lng: 21.613821 },
    { lat: 49.3046242, lng: 21.613284 },
  ],
  Dlhoňa: [
    { lat: 49.3971738, lng: 21.5852063 },
    { lat: 49.397763, lng: 21.5862169 },
    { lat: 49.3994618, lng: 21.5884647 },
    { lat: 49.3996188, lng: 21.5888919 },
    { lat: 49.3999019, lng: 21.5893279 },
    { lat: 49.4003329, lng: 21.5903134 },
    { lat: 49.4005045, lng: 21.5905779 },
    { lat: 49.4014025, lng: 21.590507 },
    { lat: 49.4017798, lng: 21.5906057 },
    { lat: 49.4023024, lng: 21.590548 },
    { lat: 49.4035352, lng: 21.5893263 },
    { lat: 49.4041229, lng: 21.5884079 },
    { lat: 49.4054679, lng: 21.5896608 },
    { lat: 49.4056921, lng: 21.5894664 },
    { lat: 49.4058009, lng: 21.5896157 },
    { lat: 49.4059292, lng: 21.5895424 },
    { lat: 49.405994, lng: 21.5896856 },
    { lat: 49.4066021, lng: 21.5894633 },
    { lat: 49.406729, lng: 21.5895783 },
    { lat: 49.4069417, lng: 21.5892972 },
    { lat: 49.4072839, lng: 21.5890662 },
    { lat: 49.4074178, lng: 21.5891872 },
    { lat: 49.4075771, lng: 21.5890053 },
    { lat: 49.4077172, lng: 21.58911 },
    { lat: 49.4078479, lng: 21.5889967 },
    { lat: 49.4082929, lng: 21.5890611 },
    { lat: 49.4089082, lng: 21.5886986 },
    { lat: 49.4094212, lng: 21.588661 },
    { lat: 49.4099313, lng: 21.5887951 },
    { lat: 49.4104895, lng: 21.5888059 },
    { lat: 49.4109047, lng: 21.5885701 },
    { lat: 49.4119005, lng: 21.5886197 },
    { lat: 49.412143, lng: 21.5885237 },
    { lat: 49.412385, lng: 21.5886482 },
    { lat: 49.4129266, lng: 21.5887883 },
    { lat: 49.4133965, lng: 21.5885822 },
    { lat: 49.4137437, lng: 21.5886497 },
    { lat: 49.4140356, lng: 21.5885823 },
    { lat: 49.4146418, lng: 21.5893143 },
    { lat: 49.4147464, lng: 21.5889466 },
    { lat: 49.4146546, lng: 21.5880631 },
    { lat: 49.4151312, lng: 21.5874046 },
    { lat: 49.4156506, lng: 21.5869847 },
    { lat: 49.4162857, lng: 21.5863571 },
    { lat: 49.4165691, lng: 21.5858628 },
    { lat: 49.4175783, lng: 21.5844525 },
    { lat: 49.4182377, lng: 21.5840581 },
    { lat: 49.4188639, lng: 21.5827828 },
    { lat: 49.419308, lng: 21.5824089 },
    { lat: 49.4196372, lng: 21.5819463 },
    { lat: 49.4203517, lng: 21.5806099 },
    { lat: 49.4209353, lng: 21.5802341 },
    { lat: 49.4220706, lng: 21.5793187 },
    { lat: 49.4222774, lng: 21.5789811 },
    { lat: 49.4237391, lng: 21.5779891 },
    { lat: 49.424323, lng: 21.5766545 },
    { lat: 49.4255451, lng: 21.5749676 },
    { lat: 49.4273078, lng: 21.5739558 },
    { lat: 49.4288626, lng: 21.5735581 },
    { lat: 49.4293812, lng: 21.5732687 },
    { lat: 49.4300237, lng: 21.5735651 },
    { lat: 49.4304454, lng: 21.5735239 },
    { lat: 49.4317007, lng: 21.5740008 },
    { lat: 49.4320353, lng: 21.5745514 },
    { lat: 49.4331055, lng: 21.5754958 },
    { lat: 49.4342353, lng: 21.5756325 },
    { lat: 49.4354, lng: 21.575624 },
    { lat: 49.435406, lng: 21.575602 },
    { lat: 49.43557, lng: 21.575113 },
    { lat: 49.435815, lng: 21.574676 },
    { lat: 49.4359988, lng: 21.5742805 },
    { lat: 49.436203, lng: 21.573841 },
    { lat: 49.436209, lng: 21.573825 },
    { lat: 49.436375, lng: 21.573328 },
    { lat: 49.436683, lng: 21.572587 },
    { lat: 49.437251, lng: 21.571854 },
    { lat: 49.437662, lng: 21.571066 },
    { lat: 49.437844, lng: 21.570854 },
    { lat: 49.438162, lng: 21.570484 },
    { lat: 49.438579, lng: 21.570418 },
    { lat: 49.438592, lng: 21.570412 },
    { lat: 49.438941, lng: 21.570171 },
    { lat: 49.438948, lng: 21.570163 },
    { lat: 49.439391, lng: 21.569654 },
    { lat: 49.439395, lng: 21.569643 },
    { lat: 49.439521, lng: 21.56931 },
    { lat: 49.439905, lng: 21.568588 },
    { lat: 49.440045, lng: 21.568297 },
    { lat: 49.440296, lng: 21.567969 },
    { lat: 49.440386, lng: 21.567477 },
    { lat: 49.440617, lng: 21.567019 },
    { lat: 49.440939, lng: 21.566507 },
    { lat: 49.440992, lng: 21.565939 },
    { lat: 49.44109, lng: 21.565378 },
    { lat: 49.441197, lng: 21.565034 },
    { lat: 49.4412412, lng: 21.5643083 },
    { lat: 49.4412621, lng: 21.5639837 },
    { lat: 49.4410926, lng: 21.5636628 },
    { lat: 49.4409598, lng: 21.563181 },
    { lat: 49.4407342, lng: 21.5620096 },
    { lat: 49.440513, lng: 21.5613105 },
    { lat: 49.4403432, lng: 21.5606704 },
    { lat: 49.4399871, lng: 21.5598895 },
    { lat: 49.4396051, lng: 21.559435 },
    { lat: 49.439009, lng: 21.5581772 },
    { lat: 49.4381213, lng: 21.5565912 },
    { lat: 49.4376016, lng: 21.5558887 },
    { lat: 49.4372157, lng: 21.5555497 },
    { lat: 49.4370403, lng: 21.5556565 },
    { lat: 49.4360528, lng: 21.555204 },
    { lat: 49.4353925, lng: 21.5543667 },
    { lat: 49.4349192, lng: 21.5541342 },
    { lat: 49.4342337, lng: 21.553744 },
    { lat: 49.4339135, lng: 21.553477 },
    { lat: 49.43353, lng: 21.552806 },
    { lat: 49.4331148, lng: 21.5520786 },
    { lat: 49.4329953, lng: 21.5513754 },
    { lat: 49.433138, lng: 21.55101 },
    { lat: 49.4331468, lng: 21.5509949 },
    { lat: 49.433212, lng: 21.550244 },
    { lat: 49.4330983, lng: 21.5504468 },
    { lat: 49.4326231, lng: 21.5512945 },
    { lat: 49.4318581, lng: 21.5519313 },
    { lat: 49.4312767, lng: 21.5519026 },
    { lat: 49.4307648, lng: 21.551534 },
    { lat: 49.4300418, lng: 21.5517491 },
    { lat: 49.4297609, lng: 21.5519967 },
    { lat: 49.4293064, lng: 21.5530438 },
    { lat: 49.4289346, lng: 21.5531098 },
    { lat: 49.4277371, lng: 21.5529206 },
    { lat: 49.4274091, lng: 21.5534242 },
    { lat: 49.4273235, lng: 21.5545461 },
    { lat: 49.4274353, lng: 21.5546844 },
    { lat: 49.4274405, lng: 21.5556725 },
    { lat: 49.4270733, lng: 21.5570253 },
    { lat: 49.4267642, lng: 21.5570738 },
    { lat: 49.4249122, lng: 21.5582774 },
    { lat: 49.4240647, lng: 21.5594235 },
    { lat: 49.4236041, lng: 21.560253 },
    { lat: 49.4220224, lng: 21.5622741 },
    { lat: 49.4216948, lng: 21.5624181 },
    { lat: 49.4214415, lng: 21.5627367 },
    { lat: 49.4205382, lng: 21.5629396 },
    { lat: 49.4196761, lng: 21.5633255 },
    { lat: 49.4187352, lng: 21.5636338 },
    { lat: 49.4182479, lng: 21.5639114 },
    { lat: 49.4178548, lng: 21.5638529 },
    { lat: 49.4174823, lng: 21.5641298 },
    { lat: 49.4162292, lng: 21.5646146 },
    { lat: 49.4145757, lng: 21.5647751 },
    { lat: 49.4145209, lng: 21.5644239 },
    { lat: 49.4131055, lng: 21.5634477 },
    { lat: 49.4108029, lng: 21.5610232 },
    { lat: 49.4100527, lng: 21.5603433 },
    { lat: 49.4090936, lng: 21.5589043 },
    { lat: 49.4090293, lng: 21.5587387 },
    { lat: 49.4082559, lng: 21.5584335 },
    { lat: 49.4074644, lng: 21.5576838 },
    { lat: 49.4073267, lng: 21.5562139 },
    { lat: 49.4074791, lng: 21.5559539 },
    { lat: 49.406992, lng: 21.5546179 },
    { lat: 49.4068678, lng: 21.5538432 },
    { lat: 49.4063393, lng: 21.5529643 },
    { lat: 49.4060555, lng: 21.552021 },
    { lat: 49.4057327, lng: 21.5514176 },
    { lat: 49.405079, lng: 21.5505737 },
    { lat: 49.4045653, lng: 21.5497358 },
    { lat: 49.4042769, lng: 21.5487632 },
    { lat: 49.4034876, lng: 21.5477317 },
    { lat: 49.4030486, lng: 21.5468359 },
    { lat: 49.402842, lng: 21.5466883 },
    { lat: 49.4031673, lng: 21.5452911 },
    { lat: 49.4000313, lng: 21.5485103 },
    { lat: 49.3986273, lng: 21.5501952 },
    { lat: 49.3975417, lng: 21.551683 },
    { lat: 49.396907, lng: 21.5526405 },
    { lat: 49.3971392, lng: 21.5543878 },
    { lat: 49.3971477, lng: 21.5571064 },
    { lat: 49.3971901, lng: 21.5576212 },
    { lat: 49.3971563, lng: 21.5580232 },
    { lat: 49.3973956, lng: 21.5596209 },
    { lat: 49.3974353, lng: 21.5610099 },
    { lat: 49.3973178, lng: 21.5637324 },
    { lat: 49.3972194, lng: 21.5646822 },
    { lat: 49.3973351, lng: 21.5669009 },
    { lat: 49.3972699, lng: 21.5699374 },
    { lat: 49.3969198, lng: 21.5715826 },
    { lat: 49.3972901, lng: 21.5715151 },
    { lat: 49.3973098, lng: 21.5722979 },
    { lat: 49.396883, lng: 21.5744768 },
    { lat: 49.3957741, lng: 21.5766722 },
    { lat: 49.3955505, lng: 21.5774922 },
    { lat: 49.3978804, lng: 21.5822903 },
    { lat: 49.3979213, lng: 21.5826267 },
    { lat: 49.3978484, lng: 21.5830002 },
    { lat: 49.3974917, lng: 21.5834821 },
    { lat: 49.3978812, lng: 21.5839032 },
    { lat: 49.3971738, lng: 21.5852063 },
  ],
  Giraltovce: [
    { lat: 49.11523, lng: 21.4932203 },
    { lat: 49.1149265, lng: 21.4929887 },
    { lat: 49.1146924, lng: 21.4929134 },
    { lat: 49.114386, lng: 21.4929312 },
    { lat: 49.1138726, lng: 21.4931183 },
    { lat: 49.1136905, lng: 21.4934008 },
    { lat: 49.1134824, lng: 21.4934362 },
    { lat: 49.1133059, lng: 21.493365 },
    { lat: 49.1133065, lng: 21.493115 },
    { lat: 49.1133694, lng: 21.4928325 },
    { lat: 49.1136481, lng: 21.4923506 },
    { lat: 49.1137867, lng: 21.4917704 },
    { lat: 49.1137951, lng: 21.4912757 },
    { lat: 49.1137545, lng: 21.491273 },
    { lat: 49.1137085, lng: 21.4910016 },
    { lat: 49.1135021, lng: 21.4905111 },
    { lat: 49.1129106, lng: 21.4906014 },
    { lat: 49.1126139, lng: 21.4907289 },
    { lat: 49.1123747, lng: 21.4915306 },
    { lat: 49.1122853, lng: 21.4921459 },
    { lat: 49.1121524, lng: 21.4925706 },
    { lat: 49.1116381, lng: 21.4929194 },
    { lat: 49.1114608, lng: 21.4929358 },
    { lat: 49.110954, lng: 21.4934658 },
    { lat: 49.1106681, lng: 21.4940949 },
    { lat: 49.1104791, lng: 21.4942885 },
    { lat: 49.1101038, lng: 21.4944215 },
    { lat: 49.1094718, lng: 21.4939603 },
    { lat: 49.1093821, lng: 21.4937361 },
    { lat: 49.1093711, lng: 21.4928817 },
    { lat: 49.1091473, lng: 21.4927321 },
    { lat: 49.1087681, lng: 21.4927275 },
    { lat: 49.108646, lng: 21.4927866 },
    { lat: 49.1084303, lng: 21.4930663 },
    { lat: 49.1081714, lng: 21.493889 },
    { lat: 49.1080312, lng: 21.4938275 },
    { lat: 49.107897, lng: 21.4935028 },
    { lat: 49.1077816, lng: 21.4930052 },
    { lat: 49.1075344, lng: 21.4922776 },
    { lat: 49.1073502, lng: 21.4918539 },
    { lat: 49.1067672, lng: 21.4920438 },
    { lat: 49.106463, lng: 21.4924205 },
    { lat: 49.1065197, lng: 21.4925141 },
    { lat: 49.106433, lng: 21.4929034 },
    { lat: 49.1062402, lng: 21.4934412 },
    { lat: 49.1065271, lng: 21.494536 },
    { lat: 49.1061225, lng: 21.4948304 },
    { lat: 49.1059385, lng: 21.4947715 },
    { lat: 49.1049555, lng: 21.4936374 },
    { lat: 49.1048626, lng: 21.4935782 },
    { lat: 49.1047477, lng: 21.4938501 },
    { lat: 49.104705, lng: 21.4943592 },
    { lat: 49.1047767, lng: 21.4949452 },
    { lat: 49.1047277, lng: 21.4957445 },
    { lat: 49.1047576, lng: 21.4959267 },
    { lat: 49.1050927, lng: 21.4967045 },
    { lat: 49.1050387, lng: 21.4968252 },
    { lat: 49.1046554, lng: 21.4969519 },
    { lat: 49.1039938, lng: 21.496562 },
    { lat: 49.1035146, lng: 21.4967237 },
    { lat: 49.1030884, lng: 21.4963699 },
    { lat: 49.1027301, lng: 21.4965133 },
    { lat: 49.1032547, lng: 21.4982131 },
    { lat: 49.1031807, lng: 21.4996805 },
    { lat: 49.1030839, lng: 21.4998008 },
    { lat: 49.1027239, lng: 21.4991334 },
    { lat: 49.1024194, lng: 21.4978391 },
    { lat: 49.1010697, lng: 21.4983271 },
    { lat: 49.101423, lng: 21.4990422 },
    { lat: 49.1014111, lng: 21.4998786 },
    { lat: 49.1007793, lng: 21.5006496 },
    { lat: 49.1005982, lng: 21.5010419 },
    { lat: 49.1003509, lng: 21.5018349 },
    { lat: 49.1001538, lng: 21.5016716 },
    { lat: 49.0996945, lng: 21.5026104 },
    { lat: 49.0997977, lng: 21.5032682 },
    { lat: 49.1001061, lng: 21.5028424 },
    { lat: 49.10041, lng: 21.5031228 },
    { lat: 49.1006429, lng: 21.5034338 },
    { lat: 49.1010429, lng: 21.5042076 },
    { lat: 49.1004585, lng: 21.5045483 },
    { lat: 49.0999662, lng: 21.5050756 },
    { lat: 49.1003168, lng: 21.5054915 },
    { lat: 49.1002555, lng: 21.5059875 },
    { lat: 49.1001094, lng: 21.5062294 },
    { lat: 49.0996501, lng: 21.5065007 },
    { lat: 49.0995457, lng: 21.5067823 },
    { lat: 49.0995713, lng: 21.5070125 },
    { lat: 49.0998187, lng: 21.507236 },
    { lat: 49.1001144, lng: 21.5080663 },
    { lat: 49.1001567, lng: 21.5080456 },
    { lat: 49.1002593, lng: 21.5082955 },
    { lat: 49.1006675, lng: 21.5099047 },
    { lat: 49.1017009, lng: 21.5116608 },
    { lat: 49.1027074, lng: 21.5140155 },
    { lat: 49.10355, lng: 21.516156 },
    { lat: 49.1031715, lng: 21.5163346 },
    { lat: 49.1023287, lng: 21.5169974 },
    { lat: 49.1017843, lng: 21.5173216 },
    { lat: 49.1005871, lng: 21.5178762 },
    { lat: 49.0994466, lng: 21.5180863 },
    { lat: 49.097567, lng: 21.5186536 },
    { lat: 49.096997, lng: 21.5187065 },
    { lat: 49.0964355, lng: 21.5189424 },
    { lat: 49.0961704, lng: 21.5192701 },
    { lat: 49.095922, lng: 21.5194383 },
    { lat: 49.0958746, lng: 21.5195872 },
    { lat: 49.0955929, lng: 21.5194959 },
    { lat: 49.0955057, lng: 21.519596 },
    { lat: 49.0951675, lng: 21.5200821 },
    { lat: 49.0949545, lng: 21.5205119 },
    { lat: 49.0948032, lng: 21.5204461 },
    { lat: 49.0945728, lng: 21.5206921 },
    { lat: 49.0942916, lng: 21.521411 },
    { lat: 49.0942902, lng: 21.521718 },
    { lat: 49.0948116, lng: 21.5219376 },
    { lat: 49.0950647, lng: 21.5215364 },
    { lat: 49.0951953, lng: 21.5215016 },
    { lat: 49.0952906, lng: 21.522045 },
    { lat: 49.0955246, lng: 21.5220816 },
    { lat: 49.0966203, lng: 21.5218444 },
    { lat: 49.0967857, lng: 21.5216429 },
    { lat: 49.096867, lng: 21.5218903 },
    { lat: 49.0971381, lng: 21.5218502 },
    { lat: 49.0973683, lng: 21.5219179 },
    { lat: 49.097468, lng: 21.5223738 },
    { lat: 49.0974698, lng: 21.5231097 },
    { lat: 49.0975447, lng: 21.5233335 },
    { lat: 49.098193, lng: 21.5240218 },
    { lat: 49.0986379, lng: 21.5242737 },
    { lat: 49.0992435, lng: 21.5251932 },
    { lat: 49.0992821, lng: 21.5255658 },
    { lat: 49.0991328, lng: 21.526168 },
    { lat: 49.0988234, lng: 21.5268494 },
    { lat: 49.0992048, lng: 21.5279186 },
    { lat: 49.0993155, lng: 21.5280912 },
    { lat: 49.0996736, lng: 21.5271497 },
    { lat: 49.1003153, lng: 21.5266084 },
    { lat: 49.1005297, lng: 21.5267961 },
    { lat: 49.1006976, lng: 21.5268454 },
    { lat: 49.1007544, lng: 21.5270607 },
    { lat: 49.1010147, lng: 21.5274698 },
    { lat: 49.1014842, lng: 21.5272515 },
    { lat: 49.1014975, lng: 21.52672 },
    { lat: 49.1018289, lng: 21.5263535 },
    { lat: 49.1018233, lng: 21.5260724 },
    { lat: 49.1017005, lng: 21.5259483 },
    { lat: 49.1022513, lng: 21.5252507 },
    { lat: 49.1024903, lng: 21.5254655 },
    { lat: 49.1027126, lng: 21.5252976 },
    { lat: 49.1027512, lng: 21.5254649 },
    { lat: 49.1029705, lng: 21.5252645 },
    { lat: 49.1033036, lng: 21.5253061 },
    { lat: 49.1036859, lng: 21.5254192 },
    { lat: 49.1041183, lng: 21.5256691 },
    { lat: 49.1043697, lng: 21.5260475 },
    { lat: 49.1046969, lng: 21.5262372 },
    { lat: 49.1052639, lng: 21.526829 },
    { lat: 49.105621, lng: 21.5280404 },
    { lat: 49.1056401, lng: 21.5283566 },
    { lat: 49.1057376, lng: 21.5285455 },
    { lat: 49.1057299, lng: 21.5287877 },
    { lat: 49.1058182, lng: 21.5289262 },
    { lat: 49.1058232, lng: 21.529262 },
    { lat: 49.1060087, lng: 21.5300495 },
    { lat: 49.1060284, lng: 21.5303071 },
    { lat: 49.1062497, lng: 21.5304962 },
    { lat: 49.1062785, lng: 21.5307216 },
    { lat: 49.1063949, lng: 21.5307761 },
    { lat: 49.1064132, lng: 21.5307828 },
    { lat: 49.1072397, lng: 21.5316866 },
    { lat: 49.107463, lng: 21.532105 },
    { lat: 49.1075349, lng: 21.53238 },
    { lat: 49.1077702, lng: 21.5327807 },
    { lat: 49.1079323, lng: 21.5333037 },
    { lat: 49.1081295, lng: 21.5340664 },
    { lat: 49.1081745, lng: 21.5345055 },
    { lat: 49.1087887, lng: 21.535419 },
    { lat: 49.1091171, lng: 21.5358115 },
    { lat: 49.1093196, lng: 21.5362522 },
    { lat: 49.1097185, lng: 21.5369101 },
    { lat: 49.1098332, lng: 21.5370247 },
    { lat: 49.1100738, lng: 21.5376956 },
    { lat: 49.1103153, lng: 21.5381272 },
    { lat: 49.1110465, lng: 21.5385964 },
    { lat: 49.1113096, lng: 21.5389918 },
    { lat: 49.1114855, lng: 21.5396436 },
    { lat: 49.1122444, lng: 21.539627 },
    { lat: 49.1126134, lng: 21.5395037 },
    { lat: 49.1138608, lng: 21.539454 },
    { lat: 49.1143305, lng: 21.5393553 },
    { lat: 49.1149063, lng: 21.538993 },
    { lat: 49.1152532, lng: 21.5386441 },
    { lat: 49.115619, lng: 21.5384489 },
    { lat: 49.1163003, lng: 21.5378389 },
    { lat: 49.1168253, lng: 21.5376648 },
    { lat: 49.1179551, lng: 21.5376756 },
    { lat: 49.1183139, lng: 21.5380227 },
    { lat: 49.1191413, lng: 21.538522 },
    { lat: 49.1193349, lng: 21.538723 },
    { lat: 49.1198656, lng: 21.5399544 },
    { lat: 49.1206334, lng: 21.541046 },
    { lat: 49.120762, lng: 21.541419 },
    { lat: 49.1213462, lng: 21.54238 },
    { lat: 49.1216361, lng: 21.5427709 },
    { lat: 49.121852, lng: 21.5429708 },
    { lat: 49.1218887, lng: 21.5433926 },
    { lat: 49.1216147, lng: 21.5447626 },
    { lat: 49.1214984, lng: 21.5451538 },
    { lat: 49.12139, lng: 21.5462528 },
    { lat: 49.1212711, lng: 21.546743 },
    { lat: 49.1211397, lng: 21.5485169 },
    { lat: 49.1211362, lng: 21.5495074 },
    { lat: 49.121188, lng: 21.549628 },
    { lat: 49.1211891, lng: 21.5499551 },
    { lat: 49.1209384, lng: 21.5514768 },
    { lat: 49.1209166, lng: 21.5521014 },
    { lat: 49.1206587, lng: 21.5528133 },
    { lat: 49.1207406, lng: 21.5538826 },
    { lat: 49.1207985, lng: 21.5541493 },
    { lat: 49.120811, lng: 21.5547127 },
    { lat: 49.1209624, lng: 21.5553329 },
    { lat: 49.1210327, lng: 21.5560754 },
    { lat: 49.121011, lng: 21.556449 },
    { lat: 49.12103, lng: 21.556455 },
    { lat: 49.121192, lng: 21.556524 },
    { lat: 49.121375, lng: 21.557219 },
    { lat: 49.121436, lng: 21.557409 },
    { lat: 49.121606, lng: 21.557797 },
    { lat: 49.121777, lng: 21.558183 },
    { lat: 49.121916, lng: 21.558649 },
    { lat: 49.122218, lng: 21.558712 },
    { lat: 49.122439, lng: 21.558672 },
    { lat: 49.122696, lng: 21.558667 },
    { lat: 49.122894, lng: 21.558413 },
    { lat: 49.123018, lng: 21.558186 },
    { lat: 49.123147, lng: 21.557934 },
    { lat: 49.123543, lng: 21.558024 },
    { lat: 49.123609, lng: 21.558019 },
    { lat: 49.123867, lng: 21.557918 },
    { lat: 49.124019, lng: 21.557823 },
    { lat: 49.12424, lng: 21.558014 },
    { lat: 49.124664, lng: 21.558505 },
    { lat: 49.1249, lng: 21.558667 },
    { lat: 49.124956, lng: 21.558649 },
    { lat: 49.125168, lng: 21.558911 },
    { lat: 49.125349, lng: 21.559174 },
    { lat: 49.125444, lng: 21.559445 },
    { lat: 49.125698, lng: 21.559737 },
    { lat: 49.12604, lng: 21.559881 },
    { lat: 49.126153, lng: 21.560205 },
    { lat: 49.126238, lng: 21.5604355 },
    { lat: 49.1264636, lng: 21.5607014 },
    { lat: 49.1266758, lng: 21.5608478 },
    { lat: 49.1273408, lng: 21.5606863 },
    { lat: 49.1273893, lng: 21.5608506 },
    { lat: 49.1274848, lng: 21.5609288 },
    { lat: 49.1277795, lng: 21.5609283 },
    { lat: 49.1280701, lng: 21.5612596 },
    { lat: 49.1282549, lng: 21.561193 },
    { lat: 49.1284359, lng: 21.5613081 },
    { lat: 49.1294349, lng: 21.5613896 },
    { lat: 49.1302564, lng: 21.5612762 },
    { lat: 49.1304158, lng: 21.5610998 },
    { lat: 49.1304361, lng: 21.5609846 },
    { lat: 49.1304, lng: 21.5606116 },
    { lat: 49.1304166, lng: 21.5601611 },
    { lat: 49.1306523, lng: 21.5593464 },
    { lat: 49.13087, lng: 21.5590049 },
    { lat: 49.1312266, lng: 21.5581177 },
    { lat: 49.1313626, lng: 21.555866 },
    { lat: 49.1312714, lng: 21.5542205 },
    { lat: 49.1313133, lng: 21.553792 },
    { lat: 49.1315574, lng: 21.5529146 },
    { lat: 49.1320685, lng: 21.551797 },
    { lat: 49.1325927, lng: 21.5504777 },
    { lat: 49.1328175, lng: 21.5501134 },
    { lat: 49.1335238, lng: 21.5499775 },
    { lat: 49.1335306, lng: 21.5483406 },
    { lat: 49.1334987, lng: 21.5483322 },
    { lat: 49.1323054, lng: 21.5474215 },
    { lat: 49.1323092, lng: 21.5472359 },
    { lat: 49.1321636, lng: 21.5468229 },
    { lat: 49.1320579, lng: 21.5461055 },
    { lat: 49.1317219, lng: 21.5459978 },
    { lat: 49.1315416, lng: 21.5458648 },
    { lat: 49.1314881, lng: 21.5457224 },
    { lat: 49.131492, lng: 21.5453845 },
    { lat: 49.1314159, lng: 21.5446835 },
    { lat: 49.1314911, lng: 21.5436763 },
    { lat: 49.1321227, lng: 21.5436533 },
    { lat: 49.1324707, lng: 21.543545 },
    { lat: 49.1324147, lng: 21.5432314 },
    { lat: 49.1323054, lng: 21.5430661 },
    { lat: 49.1321408, lng: 21.5429731 },
    { lat: 49.1321666, lng: 21.5426922 },
    { lat: 49.1323549, lng: 21.542589 },
    { lat: 49.1325045, lng: 21.5418671 },
    { lat: 49.1324585, lng: 21.5414742 },
    { lat: 49.1324627, lng: 21.5404249 },
    { lat: 49.1324239, lng: 21.5399281 },
    { lat: 49.1325605, lng: 21.5397905 },
    { lat: 49.1329044, lng: 21.5392115 },
    { lat: 49.1333733, lng: 21.5380914 },
    { lat: 49.1334982, lng: 21.5379306 },
    { lat: 49.1335545, lng: 21.5374523 },
    { lat: 49.1336831, lng: 21.5372315 },
    { lat: 49.1337329, lng: 21.5369584 },
    { lat: 49.133749, lng: 21.536384 },
    { lat: 49.133696, lng: 21.53607 },
    { lat: 49.133723, lng: 21.535775 },
    { lat: 49.1336, lng: 21.535414 },
    { lat: 49.133486, lng: 21.534698 },
    { lat: 49.133485, lng: 21.534193 },
    { lat: 49.132858, lng: 21.532086 },
    { lat: 49.132925, lng: 21.531702 },
    { lat: 49.133033, lng: 21.531435 },
    { lat: 49.133013, lng: 21.531021 },
    { lat: 49.132859, lng: 21.530693 },
    { lat: 49.132767, lng: 21.53065 },
    { lat: 49.13235, lng: 21.530875 },
    { lat: 49.132239, lng: 21.530711 },
    { lat: 49.132166, lng: 21.530383 },
    { lat: 49.132009, lng: 21.530142 },
    { lat: 49.13208, lng: 21.529768 },
    { lat: 49.132064, lng: 21.529508 },
    { lat: 49.131897, lng: 21.529152 },
    { lat: 49.13178, lng: 21.529094 },
    { lat: 49.131638, lng: 21.529246 },
    { lat: 49.131325, lng: 21.528866 },
    { lat: 49.131322, lng: 21.528643 },
    { lat: 49.131396, lng: 21.528357 },
    { lat: 49.131356, lng: 21.528172 },
    { lat: 49.131269, lng: 21.528129 },
    { lat: 49.131026, lng: 21.528346 },
    { lat: 49.13085, lng: 21.528344 },
    { lat: 49.13061, lng: 21.528176 },
    { lat: 49.130478, lng: 21.527963 },
    { lat: 49.130478, lng: 21.527797 },
    { lat: 49.13066, lng: 21.527402 },
    { lat: 49.130611, lng: 21.527237 },
    { lat: 49.130329, lng: 21.527179 },
    { lat: 49.130197, lng: 21.527286 },
    { lat: 49.130111, lng: 21.527552 },
    { lat: 49.129937, lng: 21.527616 },
    { lat: 49.129824, lng: 21.527505 },
    { lat: 49.129689, lng: 21.527099 },
    { lat: 49.129573, lng: 21.52665 },
    { lat: 49.12886, lng: 21.526497 },
    { lat: 49.128448, lng: 21.526839 },
    { lat: 49.128196, lng: 21.526876 },
    { lat: 49.128205, lng: 21.526635 },
    { lat: 49.128235, lng: 21.526041 },
    { lat: 49.128239, lng: 21.525975 },
    { lat: 49.12824, lng: 21.525962 },
    { lat: 49.128219, lng: 21.525938 },
    { lat: 49.12805, lng: 21.525734 },
    { lat: 49.127446, lng: 21.52593 },
    { lat: 49.127439, lng: 21.525956 },
    { lat: 49.12742, lng: 21.526019 },
    { lat: 49.127351, lng: 21.526253 },
    { lat: 49.12735, lng: 21.526506 },
    { lat: 49.127379, lng: 21.526655 },
    { lat: 49.127331, lng: 21.526839 },
    { lat: 49.127006, lng: 21.527017 },
    { lat: 49.126814, lng: 21.526987 },
    { lat: 49.126368, lng: 21.526575 },
    { lat: 49.126289, lng: 21.525917 },
    { lat: 49.126231, lng: 21.526019 },
    { lat: 49.126584, lng: 21.524221 },
    { lat: 49.126644, lng: 21.524141 },
    { lat: 49.126713, lng: 21.524049 },
    { lat: 49.126894, lng: 21.523807 },
    { lat: 49.127247, lng: 21.523338 },
    { lat: 49.12757, lng: 21.52294 },
    { lat: 49.1271109, lng: 21.52193 },
    { lat: 49.1269408, lng: 21.5210112 },
    { lat: 49.1268648, lng: 21.5207935 },
    { lat: 49.1267605, lng: 21.5200937 },
    { lat: 49.1268008, lng: 21.5194516 },
    { lat: 49.1237466, lng: 21.5210473 },
    { lat: 49.1234305, lng: 21.5194635 },
    { lat: 49.1227709, lng: 21.5168331 },
    { lat: 49.1221962, lng: 21.514113 },
    { lat: 49.1217659, lng: 21.5130283 },
    { lat: 49.1215362, lng: 21.5117888 },
    { lat: 49.1210598, lng: 21.5096798 },
    { lat: 49.1209866, lng: 21.5095169 },
    { lat: 49.1207699, lng: 21.5078786 },
    { lat: 49.1205667, lng: 21.5071406 },
    { lat: 49.1173668, lng: 21.4979492 },
    { lat: 49.1172228, lng: 21.4977511 },
    { lat: 49.1161493, lng: 21.494912 },
    { lat: 49.1153392, lng: 21.4939623 },
    { lat: 49.1151901, lng: 21.4934004 },
    { lat: 49.11523, lng: 21.4932203 },
  ],
  Cernina: [
    { lat: 49.310309, lng: 21.445879 },
    { lat: 49.310193, lng: 21.446089 },
    { lat: 49.310104, lng: 21.446186 },
    { lat: 49.309996, lng: 21.446456 },
    { lat: 49.309969, lng: 21.446494 },
    { lat: 49.309875, lng: 21.446666 },
    { lat: 49.309832, lng: 21.446804 },
    { lat: 49.309813, lng: 21.446867 },
    { lat: 49.30969, lng: 21.446987 },
    { lat: 49.309582, lng: 21.447112 },
    { lat: 49.309514, lng: 21.447179 },
    { lat: 49.309379, lng: 21.44734 },
    { lat: 49.309339, lng: 21.447377 },
    { lat: 49.309291, lng: 21.447442 },
    { lat: 49.309024, lng: 21.447738 },
    { lat: 49.308966, lng: 21.447801 },
    { lat: 49.308907, lng: 21.447864 },
    { lat: 49.308799, lng: 21.447939 },
    { lat: 49.308594, lng: 21.448044 },
    { lat: 49.308396, lng: 21.448136 },
    { lat: 49.308212, lng: 21.448214 },
    { lat: 49.307866, lng: 21.448359 },
    { lat: 49.307729, lng: 21.448417 },
    { lat: 49.307458, lng: 21.448549 },
    { lat: 49.307071, lng: 21.448787 },
    { lat: 49.307005, lng: 21.448774 },
    { lat: 49.306982, lng: 21.448814 },
    { lat: 49.306954, lng: 21.44883 },
    { lat: 49.306521, lng: 21.449074 },
    { lat: 49.306515, lng: 21.449064 },
    { lat: 49.306455, lng: 21.448957 },
    { lat: 49.306335, lng: 21.44895 },
    { lat: 49.306315, lng: 21.448912 },
    { lat: 49.306174, lng: 21.44887 },
    { lat: 49.30606, lng: 21.448885 },
    { lat: 49.305756, lng: 21.44891 },
    { lat: 49.305721, lng: 21.448985 },
    { lat: 49.305648, lng: 21.449078 },
    { lat: 49.305455, lng: 21.44904 },
    { lat: 49.305305, lng: 21.449164 },
    { lat: 49.305225, lng: 21.44924 },
    { lat: 49.305206, lng: 21.449273 },
    { lat: 49.305103, lng: 21.44946 },
    { lat: 49.304884, lng: 21.449327 },
    { lat: 49.304664, lng: 21.449365 },
    { lat: 49.304608, lng: 21.449374 },
    { lat: 49.304539, lng: 21.4494 },
    { lat: 49.304489, lng: 21.449451 },
    { lat: 49.304373, lng: 21.449483 },
    { lat: 49.304158, lng: 21.44952 },
    { lat: 49.303848, lng: 21.449489 },
    { lat: 49.303809, lng: 21.449541 },
    { lat: 49.303717, lng: 21.449625 },
    { lat: 49.303646, lng: 21.449635 },
    { lat: 49.303643, lng: 21.449717 },
    { lat: 49.303621, lng: 21.44981 },
    { lat: 49.303574, lng: 21.450013 },
    { lat: 49.303565, lng: 21.450106 },
    { lat: 49.30352, lng: 21.450175 },
    { lat: 49.303544, lng: 21.450244 },
    { lat: 49.303373, lng: 21.450355 },
    { lat: 49.303247, lng: 21.450456 },
    { lat: 49.303174, lng: 21.450498 },
    { lat: 49.303128, lng: 21.450546 },
    { lat: 49.303072, lng: 21.450565 },
    { lat: 49.302952, lng: 21.450637 },
    { lat: 49.302756, lng: 21.450722 },
    { lat: 49.302733, lng: 21.450659 },
    { lat: 49.302576, lng: 21.450714 },
    { lat: 49.302418, lng: 21.450755 },
    { lat: 49.302344, lng: 21.450781 },
    { lat: 49.302217, lng: 21.450804 },
    { lat: 49.30119, lng: 21.451069 },
    { lat: 49.300895, lng: 21.451134 },
    { lat: 49.300622, lng: 21.45122 },
    { lat: 49.300262, lng: 21.451379 },
    { lat: 49.300109, lng: 21.45147 },
    { lat: 49.299996, lng: 21.45155 },
    { lat: 49.299903, lng: 21.451649 },
    { lat: 49.299697, lng: 21.451471 },
    { lat: 49.299627, lng: 21.451407 },
    { lat: 49.299521, lng: 21.451291 },
    { lat: 49.299408, lng: 21.451154 },
    { lat: 49.299283, lng: 21.450997 },
    { lat: 49.299119, lng: 21.450851 },
    { lat: 49.298797, lng: 21.450721 },
    { lat: 49.298649, lng: 21.450688 },
    { lat: 49.298329, lng: 21.450636 },
    { lat: 49.297913, lng: 21.450423 },
    { lat: 49.297498, lng: 21.450109 },
    { lat: 49.297303, lng: 21.449816 },
    { lat: 49.297255, lng: 21.449775 },
    { lat: 49.297076, lng: 21.449341 },
    { lat: 49.296839, lng: 21.448841 },
    { lat: 49.296675, lng: 21.448698 },
    { lat: 49.296545, lng: 21.448562 },
    { lat: 49.296513, lng: 21.448527 },
    { lat: 49.296271, lng: 21.44893 },
    { lat: 49.296217, lng: 21.449048 },
    { lat: 49.296153, lng: 21.449304 },
    { lat: 49.296048, lng: 21.449924 },
    { lat: 49.295875, lng: 21.450492 },
    { lat: 49.295844, lng: 21.450537 },
    { lat: 49.295717, lng: 21.450638 },
    { lat: 49.29543, lng: 21.450693 },
    { lat: 49.295342, lng: 21.450614 },
    { lat: 49.295223, lng: 21.450412 },
    { lat: 49.295146, lng: 21.450121 },
    { lat: 49.295094, lng: 21.45002 },
    { lat: 49.294997, lng: 21.450061 },
    { lat: 49.294895, lng: 21.45017 },
    { lat: 49.294759, lng: 21.450488 },
    { lat: 49.294602, lng: 21.450679 },
    { lat: 49.294479, lng: 21.450797 },
    { lat: 49.294409, lng: 21.45085 },
    { lat: 49.294252, lng: 21.450954 },
    { lat: 49.294236, lng: 21.451001 },
    { lat: 49.294061, lng: 21.451134 },
    { lat: 49.293956, lng: 21.451227 },
    { lat: 49.293891, lng: 21.451244 },
    { lat: 49.293842, lng: 21.451293 },
    { lat: 49.293786, lng: 21.451378 },
    { lat: 49.293751, lng: 21.451404 },
    { lat: 49.29369, lng: 21.451474 },
    { lat: 49.293609, lng: 21.451577 },
    { lat: 49.293532, lng: 21.451664 },
    { lat: 49.293387, lng: 21.451858 },
    { lat: 49.293201, lng: 21.452074 },
    { lat: 49.293186, lng: 21.452092 },
    { lat: 49.293074, lng: 21.452076 },
    { lat: 49.293, lng: 21.452004 },
    { lat: 49.293008, lng: 21.451915 },
    { lat: 49.29301, lng: 21.451858 },
    { lat: 49.293005, lng: 21.451802 },
    { lat: 49.292974, lng: 21.451767 },
    { lat: 49.292899, lng: 21.451794 },
    { lat: 49.292739, lng: 21.451893 },
    { lat: 49.292545, lng: 21.452122 },
    { lat: 49.292506, lng: 21.452197 },
    { lat: 49.292413, lng: 21.452296 },
    { lat: 49.292313, lng: 21.452375 },
    { lat: 49.292248, lng: 21.452444 },
    { lat: 49.292098, lng: 21.452558 },
    { lat: 49.292036, lng: 21.452605 },
    { lat: 49.291922, lng: 21.452713 },
    { lat: 49.291812, lng: 21.452901 },
    { lat: 49.291697, lng: 21.453229 },
    { lat: 49.291627, lng: 21.453513 },
    { lat: 49.291566, lng: 21.453533 },
    { lat: 49.291451, lng: 21.453511 },
    { lat: 49.291363, lng: 21.45344 },
    { lat: 49.291174, lng: 21.453195 },
    { lat: 49.291001, lng: 21.452989 },
    { lat: 49.290687, lng: 21.452596 },
    { lat: 49.290546, lng: 21.45252 },
    { lat: 49.290202, lng: 21.452474 },
    { lat: 49.28983, lng: 21.452369 },
    { lat: 49.289794, lng: 21.452249 },
    { lat: 49.289568, lng: 21.45226 },
    { lat: 49.289272, lng: 21.452283 },
    { lat: 49.288939, lng: 21.452498 },
    { lat: 49.288563, lng: 21.452565 },
    { lat: 49.288379, lng: 21.452479 },
    { lat: 49.288035, lng: 21.452585 },
    { lat: 49.287808, lng: 21.452599 },
    { lat: 49.287718, lng: 21.452681 },
    { lat: 49.287748, lng: 21.452906 },
    { lat: 49.287743, lng: 21.453086 },
    { lat: 49.287987, lng: 21.453741 },
    { lat: 49.288118, lng: 21.454538 },
    { lat: 49.287119, lng: 21.455807 },
    { lat: 49.285966, lng: 21.457403 },
    { lat: 49.285867, lng: 21.457452 },
    { lat: 49.28525, lng: 21.457822 },
    { lat: 49.285128, lng: 21.457817 },
    { lat: 49.284964, lng: 21.457918 },
    { lat: 49.284726, lng: 21.457915 },
    { lat: 49.284559, lng: 21.457943 },
    { lat: 49.284472, lng: 21.458016 },
    { lat: 49.284066, lng: 21.458185 },
    { lat: 49.283765, lng: 21.458285 },
    { lat: 49.283466, lng: 21.458348 },
    { lat: 49.282338, lng: 21.458289 },
    { lat: 49.281946, lng: 21.458561 },
    { lat: 49.281374, lng: 21.459332 },
    { lat: 49.281188, lng: 21.459804 },
    { lat: 49.281076, lng: 21.459959 },
    { lat: 49.280989, lng: 21.460125 },
    { lat: 49.281041, lng: 21.460299 },
    { lat: 49.280884, lng: 21.460546 },
    { lat: 49.280758, lng: 21.460867 },
    { lat: 49.280785, lng: 21.461081 },
    { lat: 49.280808, lng: 21.461419 },
    { lat: 49.280794, lng: 21.461627 },
    { lat: 49.280693, lng: 21.462125 },
    { lat: 49.280626, lng: 21.462346 },
    { lat: 49.280256, lng: 21.462799 },
    { lat: 49.27995, lng: 21.463107 },
    { lat: 49.279898, lng: 21.463542 },
    { lat: 49.279946, lng: 21.463743 },
    { lat: 49.279947, lng: 21.464011 },
    { lat: 49.27988, lng: 21.464263 },
    { lat: 49.279899, lng: 21.464493 },
    { lat: 49.279997, lng: 21.465013 },
    { lat: 49.280002, lng: 21.465258 },
    { lat: 49.279781, lng: 21.465528 },
    { lat: 49.279816, lng: 21.465729 },
    { lat: 49.279919, lng: 21.465715 },
    { lat: 49.279896, lng: 21.466236 },
    { lat: 49.279857, lng: 21.46695 },
    { lat: 49.279851, lng: 21.467186 },
    { lat: 49.279853, lng: 21.467256 },
    { lat: 49.27986, lng: 21.467448 },
    { lat: 49.279863, lng: 21.467515 },
    { lat: 49.279908, lng: 21.468152 },
    { lat: 49.279899, lng: 21.4685 },
    { lat: 49.279917, lng: 21.468697 },
    { lat: 49.279927, lng: 21.46879 },
    { lat: 49.279949, lng: 21.469015 },
    { lat: 49.279935, lng: 21.469174 },
    { lat: 49.279964, lng: 21.469359 },
    { lat: 49.279986, lng: 21.469516 },
    { lat: 49.279997, lng: 21.469719 },
    { lat: 49.280009, lng: 21.470019 },
    { lat: 49.280004, lng: 21.47023 },
    { lat: 49.280042, lng: 21.470591 },
    { lat: 49.280072, lng: 21.470683 },
    { lat: 49.280454, lng: 21.470668 },
    { lat: 49.280584, lng: 21.470754 },
    { lat: 49.280768, lng: 21.470739 },
    { lat: 49.281104, lng: 21.471023 },
    { lat: 49.281578, lng: 21.471414 },
    { lat: 49.281733, lng: 21.471981 },
    { lat: 49.281839, lng: 21.472236 },
    { lat: 49.281705, lng: 21.472866 },
    { lat: 49.281939, lng: 21.47283 },
    { lat: 49.281963, lng: 21.472965 },
    { lat: 49.281947, lng: 21.473134 },
    { lat: 49.281837, lng: 21.473665 },
    { lat: 49.28181, lng: 21.474011 },
    { lat: 49.281788, lng: 21.474298 },
    { lat: 49.281736, lng: 21.474545 },
    { lat: 49.281685, lng: 21.47465 },
    { lat: 49.281611, lng: 21.474895 },
    { lat: 49.281715, lng: 21.47506 },
    { lat: 49.281698, lng: 21.475205 },
    { lat: 49.281528, lng: 21.475469 },
    { lat: 49.281252, lng: 21.476167 },
    { lat: 49.281009, lng: 21.476731 },
    { lat: 49.280911, lng: 21.476839 },
    { lat: 49.280851, lng: 21.477066 },
    { lat: 49.28057, lng: 21.477707 },
    { lat: 49.28048, lng: 21.47785 },
    { lat: 49.280112, lng: 21.478213 },
    { lat: 49.280008, lng: 21.478354 },
    { lat: 49.280036, lng: 21.478542 },
    { lat: 49.279926, lng: 21.478516 },
    { lat: 49.279771, lng: 21.478809 },
    { lat: 49.279669, lng: 21.478945 },
    { lat: 49.279347, lng: 21.479117 },
    { lat: 49.279159, lng: 21.479448 },
    { lat: 49.27887, lng: 21.47988 },
    { lat: 49.27895, lng: 21.480083 },
    { lat: 49.27901, lng: 21.480257 },
    { lat: 49.278974, lng: 21.480423 },
    { lat: 49.278835, lng: 21.480461 },
    { lat: 49.278713, lng: 21.48046 },
    { lat: 49.278711, lng: 21.4807 },
    { lat: 49.278546, lng: 21.480828 },
    { lat: 49.27832, lng: 21.480809 },
    { lat: 49.27812, lng: 21.480889 },
    { lat: 49.277861, lng: 21.481029 },
    { lat: 49.277938, lng: 21.481186 },
    { lat: 49.278061, lng: 21.481249 },
    { lat: 49.278063, lng: 21.481318 },
    { lat: 49.278025, lng: 21.48146 },
    { lat: 49.2776, lng: 21.481768 },
    { lat: 49.277373, lng: 21.481681 },
    { lat: 49.277251, lng: 21.4816 },
    { lat: 49.277136, lng: 21.481687 },
    { lat: 49.276991, lng: 21.482106 },
    { lat: 49.277053, lng: 21.482235 },
    { lat: 49.276747, lng: 21.482603 },
    { lat: 49.27634, lng: 21.482969 },
    { lat: 49.276241, lng: 21.482879 },
    { lat: 49.276125, lng: 21.482731 },
    { lat: 49.276001, lng: 21.482791 },
    { lat: 49.276004, lng: 21.482859 },
    { lat: 49.27604, lng: 21.482851 },
    { lat: 49.276053, lng: 21.482955 },
    { lat: 49.27585, lng: 21.483264 },
    { lat: 49.275744, lng: 21.48308 },
    { lat: 49.275655, lng: 21.48313 },
    { lat: 49.275716, lng: 21.483328 },
    { lat: 49.275468, lng: 21.483461 },
    { lat: 49.275402, lng: 21.483412 },
    { lat: 49.275256, lng: 21.483489 },
    { lat: 49.275193, lng: 21.48356 },
    { lat: 49.275175, lng: 21.483586 },
    { lat: 49.275118, lng: 21.483587 },
    { lat: 49.275095, lng: 21.483528 },
    { lat: 49.275054, lng: 21.483566 },
    { lat: 49.274943, lng: 21.483765 },
    { lat: 49.274768, lng: 21.483592 },
    { lat: 49.274673, lng: 21.483602 },
    { lat: 49.274613, lng: 21.483703 },
    { lat: 49.274537, lng: 21.483691 },
    { lat: 49.274525, lng: 21.483967 },
    { lat: 49.274412, lng: 21.483872 },
    { lat: 49.274093, lng: 21.484001 },
    { lat: 49.273967, lng: 21.484131 },
    { lat: 49.273856, lng: 21.484387 },
    { lat: 49.273463, lng: 21.484458 },
    { lat: 49.273385, lng: 21.484615 },
    { lat: 49.273313, lng: 21.484627 },
    { lat: 49.273265, lng: 21.484689 },
    { lat: 49.272958, lng: 21.48482 },
    { lat: 49.272943, lng: 21.484932 },
    { lat: 49.272766, lng: 21.485045 },
    { lat: 49.272507, lng: 21.485077 },
    { lat: 49.272431, lng: 21.485087 },
    { lat: 49.272443, lng: 21.485372 },
    { lat: 49.272382, lng: 21.485498 },
    { lat: 49.272227, lng: 21.485561 },
    { lat: 49.272199, lng: 21.485932 },
    { lat: 49.272001, lng: 21.485906 },
    { lat: 49.271971, lng: 21.486037 },
    { lat: 49.271795, lng: 21.48612 },
    { lat: 49.271778, lng: 21.486413 },
    { lat: 49.271435, lng: 21.486575 },
    { lat: 49.271443, lng: 21.486868 },
    { lat: 49.271384, lng: 21.486893 },
    { lat: 49.271379, lng: 21.486966 },
    { lat: 49.271136, lng: 21.488304 },
    { lat: 49.2712286, lng: 21.4884155 },
    { lat: 49.2714219, lng: 21.488917 },
    { lat: 49.2722239, lng: 21.4895434 },
    { lat: 49.2728582, lng: 21.4902033 },
    { lat: 49.2732073, lng: 21.4910851 },
    { lat: 49.2734888, lng: 21.4915235 },
    { lat: 49.2735308, lng: 21.4920939 },
    { lat: 49.2781765, lng: 21.4921183 },
    { lat: 49.2796397, lng: 21.490164 },
    { lat: 49.2801588, lng: 21.4897846 },
    { lat: 49.2804892, lng: 21.4896995 },
    { lat: 49.2807552, lng: 21.4897509 },
    { lat: 49.2833071, lng: 21.4897663 },
    { lat: 49.2847639, lng: 21.4898472 },
    { lat: 49.2850989, lng: 21.4895567 },
    { lat: 49.2854576, lng: 21.4897933 },
    { lat: 49.2855048, lng: 21.4899127 },
    { lat: 49.2856462, lng: 21.4900485 },
    { lat: 49.2857159, lng: 21.4900457 },
    { lat: 49.2861566, lng: 21.4903986 },
    { lat: 49.2865703, lng: 21.4903058 },
    { lat: 49.2866871, lng: 21.4900631 },
    { lat: 49.2868119, lng: 21.4900569 },
    { lat: 49.2871254, lng: 21.4902854 },
    { lat: 49.2874992, lng: 21.491244 },
    { lat: 49.2880625, lng: 21.4915441 },
    { lat: 49.2882633, lng: 21.4917635 },
    { lat: 49.2882235, lng: 21.4921803 },
    { lat: 49.2884013, lng: 21.4925385 },
    { lat: 49.2888602, lng: 21.4932042 },
    { lat: 49.2892237, lng: 21.4936002 },
    { lat: 49.2897061, lng: 21.4939491 },
    { lat: 49.2899688, lng: 21.4942509 },
    { lat: 49.2904034, lng: 21.4945362 },
    { lat: 49.2907241, lng: 21.4949383 },
    { lat: 49.2918798, lng: 21.495935 },
    { lat: 49.2941997, lng: 21.4973996 },
    { lat: 49.2948466, lng: 21.498071 },
    { lat: 49.2955229, lng: 21.4985558 },
    { lat: 49.2961424, lng: 21.4984659 },
    { lat: 49.2966968, lng: 21.4978588 },
    { lat: 49.297022, lng: 21.4983429 },
    { lat: 49.297242, lng: 21.4985618 },
    { lat: 49.2976481, lng: 21.4986404 },
    { lat: 49.2983012, lng: 21.4990155 },
    { lat: 49.298676, lng: 21.4996134 },
    { lat: 49.299225, lng: 21.500681 },
    { lat: 49.2997342, lng: 21.5013771 },
    { lat: 49.2999751, lng: 21.5015831 },
    { lat: 49.3009898, lng: 21.5009458 },
    { lat: 49.301946, lng: 21.5009092 },
    { lat: 49.3029342, lng: 21.499979 },
    { lat: 49.3032141, lng: 21.5000469 },
    { lat: 49.3035416, lng: 21.500488 },
    { lat: 49.3038241, lng: 21.5014033 },
    { lat: 49.3040439, lng: 21.5016562 },
    { lat: 49.3042054, lng: 21.5023877 },
    { lat: 49.3045685, lng: 21.5045537 },
    { lat: 49.3047584, lng: 21.5053789 },
    { lat: 49.3048963, lng: 21.5051242 },
    { lat: 49.3053205, lng: 21.5044543 },
    { lat: 49.3057943, lng: 21.5041034 },
    { lat: 49.3065106, lng: 21.5039365 },
    { lat: 49.3069465, lng: 21.503679 },
    { lat: 49.3071864, lng: 21.5027365 },
    { lat: 49.3073277, lng: 21.5018128 },
    { lat: 49.3073794, lng: 21.5011348 },
    { lat: 49.3075371, lng: 21.5004292 },
    { lat: 49.3084785, lng: 21.4987993 },
    { lat: 49.3083312, lng: 21.4978973 },
    { lat: 49.3086576, lng: 21.4969515 },
    { lat: 49.3086132, lng: 21.496418 },
    { lat: 49.3089211, lng: 21.4961689 },
    { lat: 49.3090265, lng: 21.4959978 },
    { lat: 49.3089339, lng: 21.4952364 },
    { lat: 49.3093671, lng: 21.4948881 },
    { lat: 49.3094046, lng: 21.4944248 },
    { lat: 49.3097395, lng: 21.4940964 },
    { lat: 49.3096239, lng: 21.4928437 },
    { lat: 49.3102599, lng: 21.4922673 },
    { lat: 49.3102565, lng: 21.4919077 },
    { lat: 49.3110344, lng: 21.4907402 },
    { lat: 49.3119097, lng: 21.4877468 },
    { lat: 49.3122851, lng: 21.4869094 },
    { lat: 49.3124106, lng: 21.4862992 },
    { lat: 49.312705, lng: 21.4853013 },
    { lat: 49.312752, lng: 21.4840748 },
    { lat: 49.3128933, lng: 21.4832688 },
    { lat: 49.3131499, lng: 21.4822089 },
    { lat: 49.3133111, lng: 21.4817863 },
    { lat: 49.3136068, lng: 21.4813126 },
    { lat: 49.3140756, lng: 21.4801223 },
    { lat: 49.3151307, lng: 21.4785762 },
    { lat: 49.3159152, lng: 21.4776138 },
    { lat: 49.3162349, lng: 21.476987 },
    { lat: 49.317158, lng: 21.4762699 },
    { lat: 49.3175686, lng: 21.4757891 },
    { lat: 49.3179522, lng: 21.4742589 },
    { lat: 49.3188835, lng: 21.4700966 },
    { lat: 49.3194448, lng: 21.4670733 },
    { lat: 49.3196864, lng: 21.4638937 },
    { lat: 49.3187876, lng: 21.4631511 },
    { lat: 49.317718, lng: 21.4614351 },
    { lat: 49.3163261, lng: 21.4569296 },
    { lat: 49.3161827, lng: 21.4567219 },
    { lat: 49.3160898, lng: 21.4564285 },
    { lat: 49.3157596, lng: 21.4558961 },
    { lat: 49.3158108, lng: 21.4558404 },
    { lat: 49.315288, lng: 21.4548288 },
    { lat: 49.3150343, lng: 21.4542315 },
    { lat: 49.3149568, lng: 21.4537997 },
    { lat: 49.314703, lng: 21.4529622 },
    { lat: 49.3147274, lng: 21.452909 },
    { lat: 49.3144111, lng: 21.4521594 },
    { lat: 49.3139804, lng: 21.4516616 },
    { lat: 49.3138416, lng: 21.4513367 },
    { lat: 49.3137259, lng: 21.4512216 },
    { lat: 49.3130997, lng: 21.4501085 },
    { lat: 49.31286, lng: 21.4494822 },
    { lat: 49.3124735, lng: 21.4487583 },
    { lat: 49.3122152, lng: 21.4480755 },
    { lat: 49.3119987, lng: 21.448058 },
    { lat: 49.3114403, lng: 21.4474547 },
    { lat: 49.3111343, lng: 21.4473019 },
    { lat: 49.3105505, lng: 21.4460992 },
    { lat: 49.310309, lng: 21.445879 },
  ],
  Šarbov: [
    { lat: 49.4148626, lng: 21.636712 },
    { lat: 49.4148904, lng: 21.6367041 },
    { lat: 49.4153348, lng: 21.6367491 },
    { lat: 49.4159391, lng: 21.6358878 },
    { lat: 49.4156448, lng: 21.6354073 },
    { lat: 49.4158362, lng: 21.6350804 },
    { lat: 49.4171644, lng: 21.6349492 },
    { lat: 49.418218, lng: 21.6363852 },
    { lat: 49.4187394, lng: 21.6385941 },
    { lat: 49.4201298, lng: 21.6402605 },
    { lat: 49.4208693, lng: 21.6408973 },
    { lat: 49.4220806, lng: 21.6407661 },
    { lat: 49.4222142, lng: 21.6409632 },
    { lat: 49.422372, lng: 21.6407233 },
    { lat: 49.4241779, lng: 21.6421083 },
    { lat: 49.4246521, lng: 21.6438782 },
    { lat: 49.4254005, lng: 21.6443042 },
    { lat: 49.4261564, lng: 21.6444721 },
    { lat: 49.4264281, lng: 21.6450777 },
    { lat: 49.427983, lng: 21.6450028 },
    { lat: 49.4281399, lng: 21.6449078 },
    { lat: 49.428862, lng: 21.6447382 },
    { lat: 49.4291801, lng: 21.6448133 },
    { lat: 49.4301728, lng: 21.6446243 },
    { lat: 49.4306508, lng: 21.6443012 },
    { lat: 49.4312669, lng: 21.6440958 },
    { lat: 49.4318332, lng: 21.6437285 },
    { lat: 49.432041, lng: 21.643528 },
    { lat: 49.4322343, lng: 21.6433408 },
    { lat: 49.4327209, lng: 21.6430309 },
    { lat: 49.4335337, lng: 21.6423826 },
    { lat: 49.433686, lng: 21.642185 },
    { lat: 49.4338538, lng: 21.6419667 },
    { lat: 49.4347817, lng: 21.6416937 },
    { lat: 49.4354107, lng: 21.6412378 },
    { lat: 49.4357224, lng: 21.641011 },
    { lat: 49.4360443, lng: 21.6402671 },
    { lat: 49.436288, lng: 21.640055 },
    { lat: 49.4370661, lng: 21.6393894 },
    { lat: 49.437641, lng: 21.6385432 },
    { lat: 49.437771, lng: 21.638325 },
    { lat: 49.4378538, lng: 21.6381858 },
    { lat: 49.4381195, lng: 21.6380166 },
    { lat: 49.438914, lng: 21.6379802 },
    { lat: 49.4391406, lng: 21.6378726 },
    { lat: 49.4395169, lng: 21.6375711 },
    { lat: 49.4399357, lng: 21.6371075 },
    { lat: 49.439976, lng: 21.63706 },
    { lat: 49.440541, lng: 21.636339 },
    { lat: 49.4407828, lng: 21.6360478 },
    { lat: 49.440805, lng: 21.636027 },
    { lat: 49.441288, lng: 21.6357189 },
    { lat: 49.4414354, lng: 21.6356607 },
    { lat: 49.4421811, lng: 21.635116 },
    { lat: 49.4429931, lng: 21.6344549 },
    { lat: 49.4433682, lng: 21.6340235 },
    { lat: 49.4436434, lng: 21.6337828 },
    { lat: 49.4440612, lng: 21.6332859 },
    { lat: 49.444286, lng: 21.633168 },
    { lat: 49.444827, lng: 21.632889 },
    { lat: 49.4448453, lng: 21.6328831 },
    { lat: 49.444903, lng: 21.632854 },
    { lat: 49.4459382, lng: 21.6323721 },
    { lat: 49.4462998, lng: 21.6320902 },
    { lat: 49.4469761, lng: 21.6314273 },
    { lat: 49.4473085, lng: 21.6309446 },
    { lat: 49.4472393, lng: 21.63072 },
    { lat: 49.447241, lng: 21.6302325 },
    { lat: 49.447115, lng: 21.629822 },
    { lat: 49.446791, lng: 21.628724 },
    { lat: 49.4467662, lng: 21.62864 },
    { lat: 49.446703, lng: 21.62796 },
    { lat: 49.446688, lng: 21.627082 },
    { lat: 49.4466545, lng: 21.6268148 },
    { lat: 49.4465201, lng: 21.6257793 },
    { lat: 49.4462325, lng: 21.6246286 },
    { lat: 49.4460666, lng: 21.6235487 },
    { lat: 49.4458631, lng: 21.6225009 },
    { lat: 49.445582, lng: 21.621677 },
    { lat: 49.4454086, lng: 21.6211654 },
    { lat: 49.4454076, lng: 21.6211103 },
    { lat: 49.4452367, lng: 21.6200836 },
    { lat: 49.4451254, lng: 21.6187186 },
    { lat: 49.4454483, lng: 21.6186344 },
    { lat: 49.4456288, lng: 21.6182023 },
    { lat: 49.4452952, lng: 21.6162977 },
    { lat: 49.4451317, lng: 21.6157785 },
    { lat: 49.4448182, lng: 21.6147752 },
    { lat: 49.4446501, lng: 21.6146899 },
    { lat: 49.4441545, lng: 21.6138655 },
    { lat: 49.4434055, lng: 21.6125675 },
    { lat: 49.443398, lng: 21.612556 },
    { lat: 49.4431727, lng: 21.6122021 },
    { lat: 49.4428664, lng: 21.6118485 },
    { lat: 49.4423205, lng: 21.6108671 },
    { lat: 49.4416919, lng: 21.6094743 },
    { lat: 49.441672, lng: 21.609431 },
    { lat: 49.441487, lng: 21.609082 },
    { lat: 49.441382, lng: 21.608869 },
    { lat: 49.441372, lng: 21.6088515 },
    { lat: 49.441357, lng: 21.608807 },
    { lat: 49.4412046, lng: 21.6083522 },
    { lat: 49.4410515, lng: 21.6081431 },
    { lat: 49.4407935, lng: 21.6077915 },
    { lat: 49.4404665, lng: 21.6074645 },
    { lat: 49.4403606, lng: 21.6073586 },
    { lat: 49.440018, lng: 21.607273 },
    { lat: 49.4399861, lng: 21.6072643 },
    { lat: 49.4396853, lng: 21.6071602 },
    { lat: 49.4392099, lng: 21.6072097 },
    { lat: 49.4388867, lng: 21.6071958 },
    { lat: 49.438872, lng: 21.607196 },
    { lat: 49.4386528, lng: 21.6072316 },
    { lat: 49.4381577, lng: 21.6070894 },
    { lat: 49.4377978, lng: 21.606407 },
    { lat: 49.4376558, lng: 21.605568 },
    { lat: 49.4374326, lng: 21.6052968 },
    { lat: 49.4371913, lng: 21.6047852 },
    { lat: 49.436509, lng: 21.6038837 },
    { lat: 49.4360631, lng: 21.6029587 },
    { lat: 49.436046, lng: 21.602924 },
    { lat: 49.435663, lng: 21.602304 },
    { lat: 49.435648, lng: 21.602277 },
    { lat: 49.4355, lng: 21.602008 },
    { lat: 49.4354738, lng: 21.6019806 },
    { lat: 49.4353486, lng: 21.6018473 },
    { lat: 49.4353, lng: 21.6015762 },
    { lat: 49.4336593, lng: 21.6028875 },
    { lat: 49.4333909, lng: 21.6031904 },
    { lat: 49.4330153, lng: 21.6033596 },
    { lat: 49.432677, lng: 21.6036572 },
    { lat: 49.4317213, lng: 21.6041646 },
    { lat: 49.4313184, lng: 21.6039933 },
    { lat: 49.4305977, lng: 21.6033087 },
    { lat: 49.429837, lng: 21.6032129 },
    { lat: 49.4295579, lng: 21.6034518 },
    { lat: 49.4288568, lng: 21.6034074 },
    { lat: 49.4279826, lng: 21.6030321 },
    { lat: 49.4273788, lng: 21.603122 },
    { lat: 49.4271451, lng: 21.6033731 },
    { lat: 49.4268442, lng: 21.6038822 },
    { lat: 49.4253682, lng: 21.6052296 },
    { lat: 49.4252234, lng: 21.605278 },
    { lat: 49.4245225, lng: 21.6058505 },
    { lat: 49.4236369, lng: 21.6064049 },
    { lat: 49.4233822, lng: 21.6066428 },
    { lat: 49.4220288, lng: 21.6071663 },
    { lat: 49.4217676, lng: 21.6075621 },
    { lat: 49.4215453, lng: 21.6082388 },
    { lat: 49.4208052, lng: 21.6094688 },
    { lat: 49.420219, lng: 21.6106899 },
    { lat: 49.4200945, lng: 21.6113566 },
    { lat: 49.4195371, lng: 21.613017 },
    { lat: 49.4183108, lng: 21.614155 },
    { lat: 49.4174576, lng: 21.6149998 },
    { lat: 49.417183, lng: 21.6152016 },
    { lat: 49.4166059, lng: 21.6154884 },
    { lat: 49.4155094, lng: 21.6165243 },
    { lat: 49.4148971, lng: 21.6172927 },
    { lat: 49.4133981, lng: 21.6189498 },
    { lat: 49.4126994, lng: 21.6193957 },
    { lat: 49.4123069, lng: 21.619526 },
    { lat: 49.4116177, lng: 21.6199027 },
    { lat: 49.4114222, lng: 21.6202006 },
    { lat: 49.4112015, lng: 21.6202354 },
    { lat: 49.4111814, lng: 21.6229891 },
    { lat: 49.4111311, lng: 21.6269577 },
    { lat: 49.4112725, lng: 21.6280183 },
    { lat: 49.4114469, lng: 21.6304091 },
    { lat: 49.4117296, lng: 21.630879 },
    { lat: 49.4117545, lng: 21.6313351 },
    { lat: 49.4118174, lng: 21.6315399 },
    { lat: 49.4123834, lng: 21.6317501 },
    { lat: 49.4124027, lng: 21.6318019 },
    { lat: 49.4122934, lng: 21.6320055 },
    { lat: 49.4123142, lng: 21.6323957 },
    { lat: 49.4124587, lng: 21.6327434 },
    { lat: 49.412716, lng: 21.6328584 },
    { lat: 49.4128257, lng: 21.6332098 },
    { lat: 49.4130081, lng: 21.6334243 },
    { lat: 49.4131111, lng: 21.6339845 },
    { lat: 49.4133314, lng: 21.6346405 },
    { lat: 49.413571, lng: 21.6349218 },
    { lat: 49.4139582, lng: 21.6351803 },
    { lat: 49.4144727, lng: 21.6359053 },
    { lat: 49.4148626, lng: 21.636712 },
  ],
  Vagrinec: [
    { lat: 49.3046435, lng: 21.613821 },
    { lat: 49.3046534, lng: 21.6147576 },
    { lat: 49.3039286, lng: 21.6208093 },
    { lat: 49.3032867, lng: 21.622881 },
    { lat: 49.3013083, lng: 21.6255237 },
    { lat: 49.2993761, lng: 21.6274629 },
    { lat: 49.2970922, lng: 21.6294706 },
    { lat: 49.297372, lng: 21.630863 },
    { lat: 49.297367, lng: 21.630885 },
    { lat: 49.297345, lng: 21.630922 },
    { lat: 49.297211, lng: 21.631033 },
    { lat: 49.297697, lng: 21.631425 },
    { lat: 49.298341, lng: 21.6325 },
    { lat: 49.299023, lng: 21.633033 },
    { lat: 49.299288, lng: 21.633239 },
    { lat: 49.299919, lng: 21.63595 },
    { lat: 49.300364, lng: 21.637847 },
    { lat: 49.300441, lng: 21.638236 },
    { lat: 49.300686, lng: 21.638606 },
    { lat: 49.300345, lng: 21.639455 },
    { lat: 49.299569, lng: 21.641238 },
    { lat: 49.3003253, lng: 21.6423345 },
    { lat: 49.3024477, lng: 21.6458854 },
    { lat: 49.3025548, lng: 21.6460196 },
    { lat: 49.3032917, lng: 21.6465574 },
    { lat: 49.3045468, lng: 21.6482021 },
    { lat: 49.3051157, lng: 21.6486468 },
    { lat: 49.3054661, lng: 21.6490427 },
    { lat: 49.3060444, lng: 21.6490896 },
    { lat: 49.3065723, lng: 21.6494324 },
    { lat: 49.3069475, lng: 21.6492903 },
    { lat: 49.3075335, lng: 21.6493248 },
    { lat: 49.3081287, lng: 21.6494979 },
    { lat: 49.3096077, lng: 21.6505287 },
    { lat: 49.3098687, lng: 21.6501549 },
    { lat: 49.3099572, lng: 21.6505931 },
    { lat: 49.3101076, lng: 21.6510363 },
    { lat: 49.3109757, lng: 21.6507757 },
    { lat: 49.3114522, lng: 21.651673 },
    { lat: 49.3115556, lng: 21.6516279 },
    { lat: 49.3114651, lng: 21.6509847 },
    { lat: 49.3123253, lng: 21.65077 },
    { lat: 49.3141043, lng: 21.6507872 },
    { lat: 49.3152625, lng: 21.6497787 },
    { lat: 49.3163601, lng: 21.6513866 },
    { lat: 49.3170876, lng: 21.6523259 },
    { lat: 49.3177592, lng: 21.6522865 },
    { lat: 49.318314, lng: 21.6534574 },
    { lat: 49.3190491, lng: 21.6556777 },
    { lat: 49.3195379, lng: 21.6564828 },
    { lat: 49.3198499, lng: 21.6567926 },
    { lat: 49.32059, lng: 21.6579133 },
    { lat: 49.3207973, lng: 21.6578818 },
    { lat: 49.3212599, lng: 21.6580176 },
    { lat: 49.3223521, lng: 21.6578041 },
    { lat: 49.3231661, lng: 21.6575391 },
    { lat: 49.3237387, lng: 21.657506 },
    { lat: 49.3242475, lng: 21.6572639 },
    { lat: 49.3249727, lng: 21.6573762 },
    { lat: 49.3251433, lng: 21.6575717 },
    { lat: 49.3251636, lng: 21.6575307 },
    { lat: 49.3251284, lng: 21.6574767 },
    { lat: 49.3217423, lng: 21.6522827 },
    { lat: 49.3204684, lng: 21.6491555 },
    { lat: 49.3195865, lng: 21.645681 },
    { lat: 49.3189489, lng: 21.6422954 },
    { lat: 49.318501, lng: 21.640675 },
    { lat: 49.3180383, lng: 21.6383099 },
    { lat: 49.3184114, lng: 21.6381267 },
    { lat: 49.3186047, lng: 21.6357667 },
    { lat: 49.3196549, lng: 21.6339396 },
    { lat: 49.3196462, lng: 21.6329204 },
    { lat: 49.3201027, lng: 21.6319235 },
    { lat: 49.3197297, lng: 21.6315319 },
    { lat: 49.320067, lng: 21.6306179 },
    { lat: 49.3196025, lng: 21.6292575 },
    { lat: 49.3189892, lng: 21.6281378 },
    { lat: 49.3191727, lng: 21.6277803 },
    { lat: 49.3188855, lng: 21.6271045 },
    { lat: 49.3190301, lng: 21.6267981 },
    { lat: 49.318776, lng: 21.624956 },
    { lat: 49.3184148, lng: 21.6228506 },
    { lat: 49.3183481, lng: 21.6226785 },
    { lat: 49.3182872, lng: 21.6212733 },
    { lat: 49.3182591, lng: 21.6211925 },
    { lat: 49.3181007, lng: 21.6210823 },
    { lat: 49.3184095, lng: 21.6204451 },
    { lat: 49.3189943, lng: 21.6198257 },
    { lat: 49.31713, lng: 21.6167318 },
    { lat: 49.3147492, lng: 21.6135225 },
    { lat: 49.3133068, lng: 21.6123626 },
    { lat: 49.3118879, lng: 21.6113632 },
    { lat: 49.3105376, lng: 21.6111046 },
    { lat: 49.3091938, lng: 21.6112869 },
    { lat: 49.3073752, lng: 21.6116652 },
    { lat: 49.3055638, lng: 21.612432 },
    { lat: 49.3046435, lng: 21.613821 },
  ],
  Štefurov: [
    { lat: 49.182387, lng: 21.5236142 },
    { lat: 49.1846249, lng: 21.5235444 },
    { lat: 49.1846831, lng: 21.5240944 },
    { lat: 49.1855552, lng: 21.5233472 },
    { lat: 49.1866904, lng: 21.5232224 },
    { lat: 49.1889112, lng: 21.5212353 },
    { lat: 49.1897876, lng: 21.5202488 },
    { lat: 49.1912699, lng: 21.5176119 },
    { lat: 49.1920219, lng: 21.5161224 },
    { lat: 49.1929502, lng: 21.5153309 },
    { lat: 49.1934916, lng: 21.513525 },
    { lat: 49.1941036, lng: 21.5130846 },
    { lat: 49.1944868, lng: 21.5136614 },
    { lat: 49.1948749, lng: 21.5131063 },
    { lat: 49.1944779, lng: 21.5121181 },
    { lat: 49.1954787, lng: 21.5107569 },
    { lat: 49.1953113, lng: 21.5096971 },
    { lat: 49.1962218, lng: 21.5090336 },
    { lat: 49.1973068, lng: 21.5078257 },
    { lat: 49.1974557, lng: 21.5075259 },
    { lat: 49.1985748, lng: 21.506092 },
    { lat: 49.1993843, lng: 21.5044493 },
    { lat: 49.19945, lng: 21.5041493 },
    { lat: 49.1996024, lng: 21.5042108 },
    { lat: 49.2001769, lng: 21.5036356 },
    { lat: 49.2006551, lng: 21.503012 },
    { lat: 49.2008102, lng: 21.5026118 },
    { lat: 49.20121, lng: 21.5019924 },
    { lat: 49.2015214, lng: 21.5016274 },
    { lat: 49.2016812, lng: 21.501551 },
    { lat: 49.2018012, lng: 21.5013757 },
    { lat: 49.201865, lng: 21.501359 },
    { lat: 49.201744, lng: 21.501134 },
    { lat: 49.202263, lng: 21.499407 },
    { lat: 49.202289, lng: 21.498387 },
    { lat: 49.202407, lng: 21.498068 },
    { lat: 49.202103, lng: 21.496978 },
    { lat: 49.201998, lng: 21.496423 },
    { lat: 49.20213, lng: 21.49558 },
    { lat: 49.202406, lng: 21.495145 },
    { lat: 49.202585, lng: 21.494227 },
    { lat: 49.202676, lng: 21.493517 },
    { lat: 49.20269, lng: 21.493409 },
    { lat: 49.20294, lng: 21.4922 },
    { lat: 49.2029, lng: 21.491676 },
    { lat: 49.202872, lng: 21.490898 },
    { lat: 49.202938, lng: 21.49058 },
    { lat: 49.203089, lng: 21.489929 },
    { lat: 49.203189, lng: 21.489482 },
    { lat: 49.203509, lng: 21.489015 },
    { lat: 49.204277, lng: 21.488407 },
    { lat: 49.204707, lng: 21.487731 },
    { lat: 49.203559, lng: 21.487826 },
    { lat: 49.203147, lng: 21.487764 },
    { lat: 49.202787, lng: 21.487193 },
    { lat: 49.202577, lng: 21.486932 },
    { lat: 49.202327, lng: 21.486404 },
    { lat: 49.202224, lng: 21.486172 },
    { lat: 49.201808, lng: 21.485303 },
    { lat: 49.201707, lng: 21.484935 },
    { lat: 49.201483, lng: 21.484354 },
    { lat: 49.201391, lng: 21.484117 },
    { lat: 49.201179, lng: 21.482371 },
    { lat: 49.201079, lng: 21.48218 },
    { lat: 49.200964, lng: 21.481958 },
    { lat: 49.200766, lng: 21.481594 },
    { lat: 49.200551, lng: 21.481322 },
    { lat: 49.200451, lng: 21.481153 },
    { lat: 49.200243, lng: 21.480827 },
    { lat: 49.200062, lng: 21.480553 },
    { lat: 49.19981, lng: 21.480128 },
    { lat: 49.199169, lng: 21.478902 },
    { lat: 49.19884, lng: 21.478826 },
    { lat: 49.19782, lng: 21.47868 },
    { lat: 49.197809, lng: 21.478698 },
    { lat: 49.197405, lng: 21.478409 },
    { lat: 49.197177, lng: 21.478233 },
    { lat: 49.196832, lng: 21.477737 },
    { lat: 49.196373, lng: 21.477657 },
    { lat: 49.195653, lng: 21.477739 },
    { lat: 49.195124, lng: 21.477264 },
    { lat: 49.194728, lng: 21.476797 },
    { lat: 49.194152, lng: 21.476489 },
    { lat: 49.194, lng: 21.476405 },
    { lat: 49.193573, lng: 21.476064 },
    { lat: 49.193557, lng: 21.476051 },
    { lat: 49.193184, lng: 21.476337 },
    { lat: 49.193114, lng: 21.476352 },
    { lat: 49.192847, lng: 21.476203 },
    { lat: 49.192652, lng: 21.476281 },
    { lat: 49.192529, lng: 21.476352 },
    { lat: 49.192406, lng: 21.476465 },
    { lat: 49.192153, lng: 21.476588 },
    { lat: 49.192009, lng: 21.476564 },
    { lat: 49.19195, lng: 21.47659 },
    { lat: 49.19183, lng: 21.476757 },
    { lat: 49.19174, lng: 21.476835 },
    { lat: 49.191565, lng: 21.476802 },
    { lat: 49.191422, lng: 21.476873 },
    { lat: 49.191317, lng: 21.476961 },
    { lat: 49.191193, lng: 21.47701 },
    { lat: 49.19087, lng: 21.477121 },
    { lat: 49.1904, lng: 21.477212 },
    { lat: 49.190297, lng: 21.477242 },
    { lat: 49.190117, lng: 21.477295 },
    { lat: 49.190001, lng: 21.477355 },
    { lat: 49.189909, lng: 21.477352 },
    { lat: 49.189576, lng: 21.477082 },
    { lat: 49.189348, lng: 21.477015 },
    { lat: 49.188848, lng: 21.477314 },
    { lat: 49.188769, lng: 21.47734 },
    { lat: 49.188601, lng: 21.477341 },
    { lat: 49.188423, lng: 21.477399 },
    { lat: 49.188327, lng: 21.477377 },
    { lat: 49.188326, lng: 21.477352 },
    { lat: 49.187957, lng: 21.477535 },
    { lat: 49.187842, lng: 21.47764 },
    { lat: 49.187634, lng: 21.477903 },
    { lat: 49.187328, lng: 21.47826 },
    { lat: 49.186804, lng: 21.478841 },
    { lat: 49.186707, lng: 21.478999 },
    { lat: 49.186414, lng: 21.479309 },
    { lat: 49.186192, lng: 21.479506 },
    { lat: 49.185851, lng: 21.478881 },
    { lat: 49.185731, lng: 21.478673 },
    { lat: 49.185418, lng: 21.479085 },
    { lat: 49.185319, lng: 21.478899 },
    { lat: 49.184703, lng: 21.478314 },
    { lat: 49.184684, lng: 21.478261 },
    { lat: 49.184652, lng: 21.478086 },
    { lat: 49.184529, lng: 21.477999 },
    { lat: 49.184461, lng: 21.477869 },
    { lat: 49.184414, lng: 21.47756 },
    { lat: 49.184324, lng: 21.477426 },
    { lat: 49.184239, lng: 21.477334 },
    { lat: 49.184124, lng: 21.477334 },
    { lat: 49.184085, lng: 21.477263 },
    { lat: 49.184039, lng: 21.477298 },
    { lat: 49.183991, lng: 21.477282 },
    { lat: 49.183902, lng: 21.477146 },
    { lat: 49.183841, lng: 21.477116 },
    { lat: 49.18378, lng: 21.476982 },
    { lat: 49.183516, lng: 21.476788 },
    { lat: 49.183328, lng: 21.476569 },
    { lat: 49.183173, lng: 21.476459 },
    { lat: 49.183106, lng: 21.476464 },
    { lat: 49.183002, lng: 21.476378 },
    { lat: 49.182812, lng: 21.476404 },
    { lat: 49.182767, lng: 21.476464 },
    { lat: 49.182721, lng: 21.476412 },
    { lat: 49.182628, lng: 21.476346 },
    { lat: 49.182507, lng: 21.476383 },
    { lat: 49.182245, lng: 21.47637 },
    { lat: 49.182186, lng: 21.47641 },
    { lat: 49.18215, lng: 21.476403 },
    { lat: 49.182116, lng: 21.476456 },
    { lat: 49.182015, lng: 21.476512 },
    { lat: 49.181996, lng: 21.47651 },
    { lat: 49.181971, lng: 21.476547 },
    { lat: 49.18166, lng: 21.476772 },
    { lat: 49.181551, lng: 21.476792 },
    { lat: 49.181497, lng: 21.476819 },
    { lat: 49.181459, lng: 21.476799 },
    { lat: 49.181428, lng: 21.476846 },
    { lat: 49.181338, lng: 21.47684 },
    { lat: 49.181309, lng: 21.476854 },
    { lat: 49.181213, lng: 21.47683 },
    { lat: 49.181071, lng: 21.476819 },
    { lat: 49.180964, lng: 21.476713 },
    { lat: 49.180845, lng: 21.476471 },
    { lat: 49.180745, lng: 21.476426 },
    { lat: 49.180695, lng: 21.476486 },
    { lat: 49.180523, lng: 21.476544 },
    { lat: 49.180305, lng: 21.476382 },
    { lat: 49.180198, lng: 21.476457 },
    { lat: 49.17973, lng: 21.47649 },
    { lat: 49.179349, lng: 21.476423 },
    { lat: 49.178592, lng: 21.476626 },
    { lat: 49.17856, lng: 21.476646 },
    { lat: 49.178421, lng: 21.476548 },
    { lat: 49.178235, lng: 21.476632 },
    { lat: 49.178017, lng: 21.476602 },
    { lat: 49.177917, lng: 21.476763 },
    { lat: 49.177417, lng: 21.477008 },
    { lat: 49.17704, lng: 21.477342 },
    { lat: 49.176974, lng: 21.477384 },
    { lat: 49.176934, lng: 21.477419 },
    { lat: 49.17674, lng: 21.477709 },
    { lat: 49.176658, lng: 21.477696 },
    { lat: 49.176506, lng: 21.477823 },
    { lat: 49.176507, lng: 21.477975 },
    { lat: 49.176441, lng: 21.478079 },
    { lat: 49.176352, lng: 21.478179 },
    { lat: 49.176179, lng: 21.478638 },
    { lat: 49.176069, lng: 21.478727 },
    { lat: 49.175874, lng: 21.478947 },
    { lat: 49.175771, lng: 21.479041 },
    { lat: 49.17568, lng: 21.479031 },
    { lat: 49.175667, lng: 21.479042 },
    { lat: 49.175564, lng: 21.479291 },
    { lat: 49.175473, lng: 21.479349 },
    { lat: 49.175368, lng: 21.479729 },
    { lat: 49.175195, lng: 21.480007 },
    { lat: 49.175137, lng: 21.480327 },
    { lat: 49.175099, lng: 21.480423 },
    { lat: 49.175059, lng: 21.480694 },
    { lat: 49.174975, lng: 21.480922 },
    { lat: 49.17481, lng: 21.481197 },
    { lat: 49.174761, lng: 21.481279 },
    { lat: 49.174685, lng: 21.481447 },
    { lat: 49.17463, lng: 21.481617 },
    { lat: 49.174605, lng: 21.481811 },
    { lat: 49.174445, lng: 21.482086 },
    { lat: 49.174421, lng: 21.482252 },
    { lat: 49.174383, lng: 21.482372 },
    { lat: 49.174364, lng: 21.482485 },
    { lat: 49.174306, lng: 21.482605 },
    { lat: 49.174304, lng: 21.482724 },
    { lat: 49.174261, lng: 21.482817 },
    { lat: 49.174221, lng: 21.482866 },
    { lat: 49.174166, lng: 21.483019 },
    { lat: 49.174189, lng: 21.483222 },
    { lat: 49.174151, lng: 21.483305 },
    { lat: 49.17409, lng: 21.483312 },
    { lat: 49.174062, lng: 21.483342 },
    { lat: 49.174086, lng: 21.483469 },
    { lat: 49.174, lng: 21.483707 },
    { lat: 49.173923, lng: 21.483755 },
    { lat: 49.173824, lng: 21.484048 },
    { lat: 49.173747, lng: 21.484166 },
    { lat: 49.173678, lng: 21.484476 },
    { lat: 49.173775, lng: 21.484534 },
    { lat: 49.17379, lng: 21.484563 },
    { lat: 49.173789, lng: 21.484609 },
    { lat: 49.173735, lng: 21.48487 },
    { lat: 49.173703, lng: 21.484954 },
    { lat: 49.173604, lng: 21.485126 },
    { lat: 49.173523, lng: 21.485243 },
    { lat: 49.173427, lng: 21.485239 },
    { lat: 49.173408, lng: 21.485261 },
    { lat: 49.173364, lng: 21.485592 },
    { lat: 49.173283, lng: 21.485735 },
    { lat: 49.173199, lng: 21.485789 },
    { lat: 49.172934, lng: 21.486183 },
    { lat: 49.172928, lng: 21.486214 },
    { lat: 49.172972, lng: 21.486335 },
    { lat: 49.172927, lng: 21.486518 },
    { lat: 49.172847, lng: 21.486543 },
    { lat: 49.172831, lng: 21.486547 },
    { lat: 49.172549, lng: 21.487119 },
    { lat: 49.172487, lng: 21.487353 },
    { lat: 49.172422, lng: 21.487479 },
    { lat: 49.17231, lng: 21.488014 },
    { lat: 49.172237, lng: 21.488 },
    { lat: 49.17222, lng: 21.488014 },
    { lat: 49.171941, lng: 21.488734 },
    { lat: 49.171762, lng: 21.489031 },
    { lat: 49.171765, lng: 21.489083 },
    { lat: 49.171867, lng: 21.489182 },
    { lat: 49.171885, lng: 21.489232 },
    { lat: 49.171807, lng: 21.489337 },
    { lat: 49.171725, lng: 21.489384 },
    { lat: 49.171715, lng: 21.489465 },
    { lat: 49.171677, lng: 21.48958 },
    { lat: 49.171556, lng: 21.489686 },
    { lat: 49.171525, lng: 21.489988 },
    { lat: 49.171417, lng: 21.490074 },
    { lat: 49.171402, lng: 21.490115 },
    { lat: 49.171381, lng: 21.490266 },
    { lat: 49.171262, lng: 21.490499 },
    { lat: 49.171231, lng: 21.490797 },
    { lat: 49.171153, lng: 21.490946 },
    { lat: 49.171225, lng: 21.491506 },
    { lat: 49.171225, lng: 21.491686 },
    { lat: 49.171204, lng: 21.491889 },
    { lat: 49.171133, lng: 21.492266 },
    { lat: 49.171081, lng: 21.492308 },
    { lat: 49.1709, lng: 21.492106 },
    { lat: 49.170846, lng: 21.492183 },
    { lat: 49.170848, lng: 21.492434 },
    { lat: 49.17083, lng: 21.492487 },
    { lat: 49.17079, lng: 21.492884 },
    { lat: 49.170668, lng: 21.493169 },
    { lat: 49.170726, lng: 21.493445 },
    { lat: 49.170711, lng: 21.493532 },
    { lat: 49.170651, lng: 21.4936 },
    { lat: 49.170642, lng: 21.493639 },
    { lat: 49.170722, lng: 21.493758 },
    { lat: 49.170748, lng: 21.493808 },
    { lat: 49.170781, lng: 21.493955 },
    { lat: 49.170726, lng: 21.49398 },
    { lat: 49.170854, lng: 21.494432 },
    { lat: 49.170897, lng: 21.494617 },
    { lat: 49.170905, lng: 21.494874 },
    { lat: 49.170905, lng: 21.495134 },
    { lat: 49.170916, lng: 21.495294 },
    { lat: 49.170974, lng: 21.495479 },
    { lat: 49.171106, lng: 21.495834 },
    { lat: 49.171172, lng: 21.495872 },
    { lat: 49.171213, lng: 21.495963 },
    { lat: 49.171227, lng: 21.495995 },
    { lat: 49.171319, lng: 21.496165 },
    { lat: 49.171604, lng: 21.496591 },
    { lat: 49.171784, lng: 21.496856 },
    { lat: 49.171844, lng: 21.497105 },
    { lat: 49.171826, lng: 21.49713 },
    { lat: 49.171765, lng: 21.497235 },
    { lat: 49.171788, lng: 21.497395 },
    { lat: 49.171864, lng: 21.4976 },
    { lat: 49.172015, lng: 21.497858 },
    { lat: 49.172036, lng: 21.497833 },
    { lat: 49.172057, lng: 21.497802 },
    { lat: 49.172072, lng: 21.497829 },
    { lat: 49.172227, lng: 21.497932 },
    { lat: 49.172571, lng: 21.498094 },
    { lat: 49.172745, lng: 21.498281 },
    { lat: 49.172759, lng: 21.498263 },
    { lat: 49.173022, lng: 21.497966 },
    { lat: 49.173441, lng: 21.498086 },
    { lat: 49.173609, lng: 21.498226 },
    { lat: 49.173542, lng: 21.498764 },
    { lat: 49.17349, lng: 21.498975 },
    { lat: 49.17349, lng: 21.499108 },
    { lat: 49.173488, lng: 21.499129 },
    { lat: 49.173486, lng: 21.499147 },
    { lat: 49.173483, lng: 21.499176 },
    { lat: 49.173645, lng: 21.499603 },
    { lat: 49.173767, lng: 21.499652 },
    { lat: 49.173902, lng: 21.499753 },
    { lat: 49.174197, lng: 21.500103 },
    { lat: 49.174405, lng: 21.500376 },
    { lat: 49.174538, lng: 21.500675 },
    { lat: 49.174545, lng: 21.500705 },
    { lat: 49.174492, lng: 21.501296 },
    { lat: 49.174529, lng: 21.501416 },
    { lat: 49.1746, lng: 21.501634 },
    { lat: 49.174644, lng: 21.501765 },
    { lat: 49.174685, lng: 21.50181 },
    { lat: 49.17472, lng: 21.501844 },
    { lat: 49.174753, lng: 21.501886 },
    { lat: 49.17476, lng: 21.501965 },
    { lat: 49.174683, lng: 21.502045 },
    { lat: 49.174671, lng: 21.502095 },
    { lat: 49.174686, lng: 21.502195 },
    { lat: 49.17463, lng: 21.502403 },
    { lat: 49.174619, lng: 21.502497 },
    { lat: 49.174689, lng: 21.502765 },
    { lat: 49.174747, lng: 21.502933 },
    { lat: 49.174883, lng: 21.503201 },
    { lat: 49.174886, lng: 21.503239 },
    { lat: 49.174868, lng: 21.503248 },
    { lat: 49.174769, lng: 21.503389 },
    { lat: 49.174757, lng: 21.503627 },
    { lat: 49.174782, lng: 21.503922 },
    { lat: 49.174725, lng: 21.504342 },
    { lat: 49.1744792, lng: 21.5055552 },
    { lat: 49.1743472, lng: 21.5067628 },
    { lat: 49.1740153, lng: 21.5080681 },
    { lat: 49.1742068, lng: 21.5092621 },
    { lat: 49.1751384, lng: 21.5106935 },
    { lat: 49.1759546, lng: 21.5112245 },
    { lat: 49.1773917, lng: 21.5102129 },
    { lat: 49.177715, lng: 21.51102 },
    { lat: 49.1781212, lng: 21.5108623 },
    { lat: 49.1793838, lng: 21.5113477 },
    { lat: 49.179521, lng: 21.51269 },
    { lat: 49.1799762, lng: 21.5135863 },
    { lat: 49.1794585, lng: 21.5147673 },
    { lat: 49.179103, lng: 21.5161127 },
    { lat: 49.178085, lng: 21.5191757 },
    { lat: 49.177375, lng: 21.5205203 },
    { lat: 49.1771301, lng: 21.5219586 },
    { lat: 49.1774991, lng: 21.5221209 },
    { lat: 49.1775161, lng: 21.5221288 },
    { lat: 49.1788173, lng: 21.5225799 },
    { lat: 49.1801184, lng: 21.5228815 },
    { lat: 49.1814327, lng: 21.5233721 },
    { lat: 49.182387, lng: 21.5236142 },
  ],
  VyšnáPisaná: [
    { lat: 49.3934263, lng: 21.6269697 },
    { lat: 49.3937292, lng: 21.6269799 },
    { lat: 49.3939826, lng: 21.6265389 },
    { lat: 49.3940763, lng: 21.6265098 },
    { lat: 49.3941581, lng: 21.6267003 },
    { lat: 49.3947812, lng: 21.626703 },
    { lat: 49.3948462, lng: 21.6265742 },
    { lat: 49.3951042, lng: 21.6268282 },
    { lat: 49.3953579, lng: 21.6265292 },
    { lat: 49.3954818, lng: 21.6267 },
    { lat: 49.3956252, lng: 21.6266756 },
    { lat: 49.3963301, lng: 21.6260418 },
    { lat: 49.3963771, lng: 21.6257849 },
    { lat: 49.3965005, lng: 21.6258929 },
    { lat: 49.3966769, lng: 21.6256042 },
    { lat: 49.3967787, lng: 21.6261546 },
    { lat: 49.3968571, lng: 21.6262286 },
    { lat: 49.3969946, lng: 21.6266449 },
    { lat: 49.3969009, lng: 21.6268419 },
    { lat: 49.3969916, lng: 21.6271139 },
    { lat: 49.3967577, lng: 21.6273614 },
    { lat: 49.3972044, lng: 21.6286506 },
    { lat: 49.397346, lng: 21.6288135 },
    { lat: 49.3972298, lng: 21.6290871 },
    { lat: 49.3978251, lng: 21.6297163 },
    { lat: 49.3987587, lng: 21.6299567 },
    { lat: 49.3991222, lng: 21.6299211 },
    { lat: 49.3993326, lng: 21.6298065 },
    { lat: 49.3994335, lng: 21.6295819 },
    { lat: 49.3999837, lng: 21.6291797 },
    { lat: 49.4004301, lng: 21.6291685 },
    { lat: 49.4008896, lng: 21.6288624 },
    { lat: 49.4010567, lng: 21.6291265 },
    { lat: 49.4014818, lng: 21.6284249 },
    { lat: 49.4020802, lng: 21.6274375 },
    { lat: 49.4025867, lng: 21.6269517 },
    { lat: 49.4038894, lng: 21.6262155 },
    { lat: 49.4039536, lng: 21.626151 },
    { lat: 49.4042782, lng: 21.625825 },
    { lat: 49.4063306, lng: 21.6237635 },
    { lat: 49.4066469, lng: 21.6234969 },
    { lat: 49.4069343, lng: 21.6232548 },
    { lat: 49.4084333, lng: 21.6223285 },
    { lat: 49.409769, lng: 21.6211536 },
    { lat: 49.4098595, lng: 21.6210439 },
    { lat: 49.4103428, lng: 21.6204586 },
    { lat: 49.4111474, lng: 21.6200994 },
    { lat: 49.4112015, lng: 21.6202354 },
    { lat: 49.4114222, lng: 21.6202006 },
    { lat: 49.4116177, lng: 21.6199027 },
    { lat: 49.4123069, lng: 21.619526 },
    { lat: 49.4126994, lng: 21.6193957 },
    { lat: 49.4133981, lng: 21.6189498 },
    { lat: 49.4148971, lng: 21.6172927 },
    { lat: 49.4155094, lng: 21.6165243 },
    { lat: 49.4166059, lng: 21.6154884 },
    { lat: 49.417183, lng: 21.6152016 },
    { lat: 49.4174576, lng: 21.6149998 },
    { lat: 49.4183108, lng: 21.614155 },
    { lat: 49.4195371, lng: 21.613017 },
    { lat: 49.4200945, lng: 21.6113566 },
    { lat: 49.420219, lng: 21.6106899 },
    { lat: 49.4208052, lng: 21.6094688 },
    { lat: 49.4215453, lng: 21.6082388 },
    { lat: 49.4217676, lng: 21.6075621 },
    { lat: 49.4220288, lng: 21.6071663 },
    { lat: 49.4233822, lng: 21.6066428 },
    { lat: 49.4236369, lng: 21.6064049 },
    { lat: 49.4245225, lng: 21.6058505 },
    { lat: 49.4252234, lng: 21.605278 },
    { lat: 49.4253682, lng: 21.6052296 },
    { lat: 49.4268442, lng: 21.6038822 },
    { lat: 49.4271451, lng: 21.6033731 },
    { lat: 49.4273788, lng: 21.603122 },
    { lat: 49.4279826, lng: 21.6030321 },
    { lat: 49.4288568, lng: 21.6034074 },
    { lat: 49.4295579, lng: 21.6034518 },
    { lat: 49.429837, lng: 21.6032129 },
    { lat: 49.4305977, lng: 21.6033087 },
    { lat: 49.4313184, lng: 21.6039933 },
    { lat: 49.4317213, lng: 21.6041646 },
    { lat: 49.432677, lng: 21.6036572 },
    { lat: 49.4330153, lng: 21.6033596 },
    { lat: 49.4333909, lng: 21.6031904 },
    { lat: 49.4336593, lng: 21.6028875 },
    { lat: 49.4353, lng: 21.6015762 },
    { lat: 49.4355669, lng: 21.6009671 },
    { lat: 49.43559, lng: 21.600907 },
    { lat: 49.4356, lng: 21.600881 },
    { lat: 49.435987, lng: 21.599858 },
    { lat: 49.4360116, lng: 21.5998119 },
    { lat: 49.4365066, lng: 21.5986634 },
    { lat: 49.4366959, lng: 21.5980153 },
    { lat: 49.436742, lng: 21.5976592 },
    { lat: 49.4367661, lng: 21.5974725 },
    { lat: 49.4370072, lng: 21.5970753 },
    { lat: 49.4369568, lng: 21.5963941 },
    { lat: 49.43659, lng: 21.595422 },
    { lat: 49.4362829, lng: 21.5946236 },
    { lat: 49.436274, lng: 21.59461 },
    { lat: 49.436264, lng: 21.594591 },
    { lat: 49.4359748, lng: 21.594071 },
    { lat: 49.4354459, lng: 21.5936203 },
    { lat: 49.4349554, lng: 21.5928633 },
    { lat: 49.4343641, lng: 21.5921827 },
    { lat: 49.434356, lng: 21.592162 },
    { lat: 49.4340258, lng: 21.5912112 },
    { lat: 49.43401, lng: 21.591168 },
    { lat: 49.4338166, lng: 21.5905587 },
    { lat: 49.433804, lng: 21.589635 },
    { lat: 49.433803, lng: 21.589615 },
    { lat: 49.4338048, lng: 21.5895704 },
    { lat: 49.433719, lng: 21.58865 },
    { lat: 49.433649, lng: 21.588034 },
    { lat: 49.4336518, lng: 21.58798 },
    { lat: 49.433682, lng: 21.587356 },
    { lat: 49.433724, lng: 21.5866902 },
    { lat: 49.433734, lng: 21.586523 },
    { lat: 49.433736, lng: 21.586505 },
    { lat: 49.433774, lng: 21.5860955 },
    { lat: 49.433773, lng: 21.586081 },
    { lat: 49.433748, lng: 21.5848688 },
    { lat: 49.4338919, lng: 21.584297 },
    { lat: 49.433837, lng: 21.583805 },
    { lat: 49.4338276, lng: 21.5837144 },
    { lat: 49.433883, lng: 21.583482 },
    { lat: 49.4339861, lng: 21.5831174 },
    { lat: 49.433989, lng: 21.583105 },
    { lat: 49.434123, lng: 21.582813 },
    { lat: 49.4347223, lng: 21.5815403 },
    { lat: 49.43473, lng: 21.581517 },
    { lat: 49.435343, lng: 21.5803344 },
    { lat: 49.435441, lng: 21.579125 },
    { lat: 49.435443, lng: 21.579108 },
    { lat: 49.435561, lng: 21.578381 },
    { lat: 49.435573, lng: 21.5783 },
    { lat: 49.43564, lng: 21.577891 },
    { lat: 49.435642, lng: 21.577865 },
    { lat: 49.435604, lng: 21.577451 },
    { lat: 49.435604, lng: 21.577431 },
    { lat: 49.435638, lng: 21.577111 },
    { lat: 49.435628, lng: 21.576651 },
    { lat: 49.435401, lng: 21.576071 },
    { lat: 49.4354, lng: 21.575624 },
    { lat: 49.4342353, lng: 21.5756325 },
    { lat: 49.4331055, lng: 21.5754958 },
    { lat: 49.4320353, lng: 21.5745514 },
    { lat: 49.4317007, lng: 21.5740008 },
    { lat: 49.4304454, lng: 21.5735239 },
    { lat: 49.4300237, lng: 21.5735651 },
    { lat: 49.4293812, lng: 21.5732687 },
    { lat: 49.4288626, lng: 21.5735581 },
    { lat: 49.4273078, lng: 21.5739558 },
    { lat: 49.4255451, lng: 21.5749676 },
    { lat: 49.424323, lng: 21.5766545 },
    { lat: 49.4237391, lng: 21.5779891 },
    { lat: 49.4222774, lng: 21.5789811 },
    { lat: 49.4220706, lng: 21.5793187 },
    { lat: 49.4209353, lng: 21.5802341 },
    { lat: 49.4203517, lng: 21.5806099 },
    { lat: 49.4196372, lng: 21.5819463 },
    { lat: 49.419308, lng: 21.5824089 },
    { lat: 49.4188639, lng: 21.5827828 },
    { lat: 49.4182377, lng: 21.5840581 },
    { lat: 49.4175783, lng: 21.5844525 },
    { lat: 49.4165691, lng: 21.5858628 },
    { lat: 49.4162857, lng: 21.5863571 },
    { lat: 49.4156506, lng: 21.5869847 },
    { lat: 49.4151312, lng: 21.5874046 },
    { lat: 49.4146546, lng: 21.5880631 },
    { lat: 49.4147464, lng: 21.5889466 },
    { lat: 49.4146418, lng: 21.5893143 },
    { lat: 49.4146488, lng: 21.5894829 },
    { lat: 49.4146118, lng: 21.5898181 },
    { lat: 49.4143912, lng: 21.5905448 },
    { lat: 49.4139401, lng: 21.5909476 },
    { lat: 49.4135691, lng: 21.5917752 },
    { lat: 49.4129209, lng: 21.5925368 },
    { lat: 49.4127727, lng: 21.5930009 },
    { lat: 49.4126083, lng: 21.5932334 },
    { lat: 49.4117654, lng: 21.5937894 },
    { lat: 49.411608, lng: 21.5938365 },
    { lat: 49.4113157, lng: 21.5936798 },
    { lat: 49.4107589, lng: 21.5937228 },
    { lat: 49.4103671, lng: 21.5940281 },
    { lat: 49.4092899, lng: 21.5935586 },
    { lat: 49.4089109, lng: 21.5934834 },
    { lat: 49.4083722, lng: 21.5936086 },
    { lat: 49.4077476, lng: 21.5938831 },
    { lat: 49.4075666, lng: 21.593884 },
    { lat: 49.4070021, lng: 21.5936894 },
    { lat: 49.40639, lng: 21.593681 },
    { lat: 49.4057436, lng: 21.5941842 },
    { lat: 49.405592, lng: 21.5945606 },
    { lat: 49.4054147, lng: 21.5955557 },
    { lat: 49.4055984, lng: 21.5958169 },
    { lat: 49.4055805, lng: 21.5965116 },
    { lat: 49.4054359, lng: 21.5971823 },
    { lat: 49.4050029, lng: 21.5974285 },
    { lat: 49.4048036, lng: 21.5979904 },
    { lat: 49.4043779, lng: 21.5983409 },
    { lat: 49.4022985, lng: 21.5991381 },
    { lat: 49.4019234, lng: 21.5994448 },
    { lat: 49.4012599, lng: 21.6003452 },
    { lat: 49.4003225, lng: 21.6012697 },
    { lat: 49.4000095, lng: 21.6015478 },
    { lat: 49.3997065, lng: 21.6016967 },
    { lat: 49.3994189, lng: 21.601712 },
    { lat: 49.3989802, lng: 21.602242 },
    { lat: 49.3987489, lng: 21.6023783 },
    { lat: 49.3984717, lng: 21.6024041 },
    { lat: 49.3979897, lng: 21.6027896 },
    { lat: 49.3974508, lng: 21.6034806 },
    { lat: 49.3969452, lng: 21.6037469 },
    { lat: 49.3966112, lng: 21.6044496 },
    { lat: 49.3960282, lng: 21.6048845 },
    { lat: 49.3955877, lng: 21.6058898 },
    { lat: 49.3952777, lng: 21.6062298 },
    { lat: 49.395292, lng: 21.6067088 },
    { lat: 49.3948695, lng: 21.6075443 },
    { lat: 49.3947265, lng: 21.6095488 },
    { lat: 49.3945461, lng: 21.6100244 },
    { lat: 49.3945266, lng: 21.6102937 },
    { lat: 49.3943393, lng: 21.6109688 },
    { lat: 49.3938173, lng: 21.612362 },
    { lat: 49.3935003, lng: 21.6136009 },
    { lat: 49.3934141, lng: 21.6141896 },
    { lat: 49.3932603, lng: 21.6146502 },
    { lat: 49.3931047, lng: 21.6149665 },
    { lat: 49.3927719, lng: 21.6153038 },
    { lat: 49.3921523, lng: 21.6164574 },
    { lat: 49.3919549, lng: 21.6169262 },
    { lat: 49.3918732, lng: 21.6176446 },
    { lat: 49.3916615, lng: 21.6179623 },
    { lat: 49.3913678, lng: 21.6187041 },
    { lat: 49.3907066, lng: 21.6196912 },
    { lat: 49.3902832, lng: 21.6200441 },
    { lat: 49.3894748, lng: 21.62121 },
    { lat: 49.3895086, lng: 21.6212566 },
    { lat: 49.3902164, lng: 21.6221666 },
    { lat: 49.3909085, lng: 21.6232893 },
    { lat: 49.3910984, lng: 21.623692 },
    { lat: 49.3916769, lng: 21.6245298 },
    { lat: 49.3920849, lng: 21.6250139 },
    { lat: 49.3934263, lng: 21.6269697 },
  ],
  Belejovce: [
    { lat: 49.368066, lng: 21.5283673 },
    { lat: 49.3674836, lng: 21.5304538 },
    { lat: 49.3678706, lng: 21.5315317 },
    { lat: 49.3672352, lng: 21.5327864 },
    { lat: 49.366958, lng: 21.5334487 },
    { lat: 49.366778, lng: 21.5344419 },
    { lat: 49.3666862, lng: 21.5362654 },
    { lat: 49.3658607, lng: 21.5377924 },
    { lat: 49.3654918, lng: 21.5406421 },
    { lat: 49.3655799, lng: 21.5413676 },
    { lat: 49.365269, lng: 21.542403 },
    { lat: 49.364986, lng: 21.5443407 },
    { lat: 49.3647242, lng: 21.5446372 },
    { lat: 49.3646277, lng: 21.5452839 },
    { lat: 49.3654589, lng: 21.5465633 },
    { lat: 49.3665203, lng: 21.5469029 },
    { lat: 49.3668883, lng: 21.5480009 },
    { lat: 49.3666808, lng: 21.5490241 },
    { lat: 49.365973, lng: 21.5496997 },
    { lat: 49.3652521, lng: 21.5506255 },
    { lat: 49.3652384, lng: 21.550826 },
    { lat: 49.3647707, lng: 21.5520398 },
    { lat: 49.3642586, lng: 21.5526768 },
    { lat: 49.3633011, lng: 21.5533106 },
    { lat: 49.3633985, lng: 21.5539354 },
    { lat: 49.3634683, lng: 21.5546621 },
    { lat: 49.3634886, lng: 21.5551866 },
    { lat: 49.3651883, lng: 21.5553507 },
    { lat: 49.3662701, lng: 21.554367 },
    { lat: 49.3674804, lng: 21.5533991 },
    { lat: 49.3688654, lng: 21.5520356 },
    { lat: 49.3695025, lng: 21.5512924 },
    { lat: 49.3701588, lng: 21.5522309 },
    { lat: 49.3710684, lng: 21.5526986 },
    { lat: 49.3725658, lng: 21.5544328 },
    { lat: 49.3735961, lng: 21.5558375 },
    { lat: 49.3743954, lng: 21.5560667 },
    { lat: 49.3748865, lng: 21.5562028 },
    { lat: 49.3755099, lng: 21.5560905 },
    { lat: 49.3760598, lng: 21.55577 },
    { lat: 49.3764432, lng: 21.5553604 },
    { lat: 49.377505, lng: 21.5544677 },
    { lat: 49.378421, lng: 21.5529087 },
    { lat: 49.3796785, lng: 21.5519341 },
    { lat: 49.3800459, lng: 21.5504323 },
    { lat: 49.3815587, lng: 21.5490979 },
    { lat: 49.3810917, lng: 21.5476067 },
    { lat: 49.3805792, lng: 21.5469829 },
    { lat: 49.3808144, lng: 21.5466584 },
    { lat: 49.3817723, lng: 21.5449982 },
    { lat: 49.3819938, lng: 21.5444341 },
    { lat: 49.3834305, lng: 21.5417483 },
    { lat: 49.3843604, lng: 21.5402919 },
    { lat: 49.3855197, lng: 21.5389085 },
    { lat: 49.3851562, lng: 21.5375069 },
    { lat: 49.3846888, lng: 21.5362385 },
    { lat: 49.3838546, lng: 21.5344953 },
    { lat: 49.3835164, lng: 21.5329892 },
    { lat: 49.3830654, lng: 21.5323163 },
    { lat: 49.3817509, lng: 21.5322102 },
    { lat: 49.3785096, lng: 21.5315576 },
    { lat: 49.3769129, lng: 21.5310644 },
    { lat: 49.3757899, lng: 21.5302951 },
    { lat: 49.3750675, lng: 21.5310042 },
    { lat: 49.3738757, lng: 21.5309882 },
    { lat: 49.3725981, lng: 21.5311563 },
    { lat: 49.3716751, lng: 21.5315359 },
    { lat: 49.3705668, lng: 21.5318069 },
    { lat: 49.3696579, lng: 21.5312914 },
    { lat: 49.3692056, lng: 21.5309224 },
    { lat: 49.3684049, lng: 21.530532 },
    { lat: 49.3681459, lng: 21.5296052 },
    { lat: 49.3681253, lng: 21.5292744 },
    { lat: 49.368066, lng: 21.5283673 },
  ],
  KrajnéČierno: [
    { lat: 49.357332, lng: 21.6708358 },
    { lat: 49.3575581, lng: 21.6706721 },
    { lat: 49.358017, lng: 21.6691232 },
    { lat: 49.3580724, lng: 21.667716 },
    { lat: 49.3581792, lng: 21.667506 },
    { lat: 49.3575274, lng: 21.6669879 },
    { lat: 49.3573746, lng: 21.6666649 },
    { lat: 49.3570767, lng: 21.6657138 },
    { lat: 49.356406, lng: 21.6646953 },
    { lat: 49.3558706, lng: 21.6637438 },
    { lat: 49.355516, lng: 21.6637287 },
    { lat: 49.3543962, lng: 21.6624234 },
    { lat: 49.3537381, lng: 21.6611689 },
    { lat: 49.353186, lng: 21.6598508 },
    { lat: 49.3529498, lng: 21.6590998 },
    { lat: 49.3515502, lng: 21.6582562 },
    { lat: 49.3506293, lng: 21.6575609 },
    { lat: 49.34992, lng: 21.6567085 },
    { lat: 49.3504288, lng: 21.6531519 },
    { lat: 49.3509353, lng: 21.6507182 },
    { lat: 49.3500601, lng: 21.649511 },
    { lat: 49.3497766, lng: 21.6483489 },
    { lat: 49.3491415, lng: 21.6473722 },
    { lat: 49.3494671, lng: 21.6463192 },
    { lat: 49.3493976, lng: 21.6460751 },
    { lat: 49.3490686, lng: 21.6457215 },
    { lat: 49.3483436, lng: 21.6437119 },
    { lat: 49.3471234, lng: 21.6427379 },
    { lat: 49.3466259, lng: 21.642635 },
    { lat: 49.3454543, lng: 21.6428688 },
    { lat: 49.3451241, lng: 21.6423923 },
    { lat: 49.3448816, lng: 21.6421964 },
    { lat: 49.344391, lng: 21.6425202 },
    { lat: 49.3441943, lng: 21.6428657 },
    { lat: 49.3437983, lng: 21.6429866 },
    { lat: 49.3423767, lng: 21.6427449 },
    { lat: 49.3419324, lng: 21.6458467 },
    { lat: 49.3408791, lng: 21.6523381 },
    { lat: 49.3400396, lng: 21.6564745 },
    { lat: 49.3412002, lng: 21.6569676 },
    { lat: 49.3411852, lng: 21.6576601 },
    { lat: 49.3412852, lng: 21.6581512 },
    { lat: 49.3420913, lng: 21.6605874 },
    { lat: 49.3423403, lng: 21.6610179 },
    { lat: 49.34219, lng: 21.6621194 },
    { lat: 49.3419619, lng: 21.6653325 },
    { lat: 49.3415194, lng: 21.6668146 },
    { lat: 49.3396786, lng: 21.6694207 },
    { lat: 49.3386447, lng: 21.6705458 },
    { lat: 49.3384001, lng: 21.670733 },
    { lat: 49.338226, lng: 21.6710322 },
    { lat: 49.337409, lng: 21.6720219 },
    { lat: 49.3372132, lng: 21.6725678 },
    { lat: 49.3368419, lng: 21.6728527 },
    { lat: 49.3366763, lng: 21.6728848 },
    { lat: 49.3362148, lng: 21.6733735 },
    { lat: 49.3359946, lng: 21.6737152 },
    { lat: 49.3359356, lng: 21.6739047 },
    { lat: 49.3357801, lng: 21.6740202 },
    { lat: 49.335578, lng: 21.6742883 },
    { lat: 49.3349433, lng: 21.6760326 },
    { lat: 49.3347933, lng: 21.6760561 },
    { lat: 49.3307293, lng: 21.6790767 },
    { lat: 49.3302189, lng: 21.6795452 },
    { lat: 49.3287732, lng: 21.6803684 },
    { lat: 49.32826, lng: 21.681776 },
    { lat: 49.328392, lng: 21.682009 },
    { lat: 49.330202, lng: 21.684769 },
    { lat: 49.331344, lng: 21.686555 },
    { lat: 49.332286, lng: 21.687987 },
    { lat: 49.333214, lng: 21.688696 },
    { lat: 49.334296, lng: 21.689917 },
    { lat: 49.334966, lng: 21.691641 },
    { lat: 49.335643, lng: 21.692084 },
    { lat: 49.335653, lng: 21.69216 },
    { lat: 49.335654, lng: 21.692197 },
    { lat: 49.335826, lng: 21.692401 },
    { lat: 49.337575, lng: 21.695957 },
    { lat: 49.339368, lng: 21.700538 },
    { lat: 49.339565, lng: 21.701427 },
    { lat: 49.339681, lng: 21.702408 },
    { lat: 49.339704, lng: 21.703499 },
    { lat: 49.3398485, lng: 21.7027929 },
    { lat: 49.340113, lng: 21.7021227 },
    { lat: 49.3404295, lng: 21.7015918 },
    { lat: 49.3406343, lng: 21.7013434 },
    { lat: 49.3409201, lng: 21.7010922 },
    { lat: 49.3414048, lng: 21.7009273 },
    { lat: 49.3415449, lng: 21.7007346 },
    { lat: 49.3417812, lng: 21.7006191 },
    { lat: 49.3422259, lng: 21.700631 },
    { lat: 49.3431436, lng: 21.6996909 },
    { lat: 49.3433447, lng: 21.6991368 },
    { lat: 49.3440411, lng: 21.6983479 },
    { lat: 49.344398, lng: 21.6977668 },
    { lat: 49.3449896, lng: 21.6965032 },
    { lat: 49.3453234, lng: 21.6959866 },
    { lat: 49.3468611, lng: 21.6942653 },
    { lat: 49.3471104, lng: 21.6937407 },
    { lat: 49.3472813, lng: 21.6931967 },
    { lat: 49.3472957, lng: 21.6928895 },
    { lat: 49.3481738, lng: 21.6916608 },
    { lat: 49.3487481, lng: 21.6911349 },
    { lat: 49.3492067, lng: 21.6908348 },
    { lat: 49.3496019, lng: 21.6903034 },
    { lat: 49.3500063, lng: 21.6896914 },
    { lat: 49.3504609, lng: 21.6888197 },
    { lat: 49.3515339, lng: 21.68701 },
    { lat: 49.3515544, lng: 21.6866832 },
    { lat: 49.3517612, lng: 21.6862131 },
    { lat: 49.3519385, lng: 21.6861514 },
    { lat: 49.3521555, lng: 21.6859026 },
    { lat: 49.3525104, lng: 21.6850042 },
    { lat: 49.3526001, lng: 21.6850361 },
    { lat: 49.3528489, lng: 21.6835729 },
    { lat: 49.3533259, lng: 21.6826145 },
    { lat: 49.3538679, lng: 21.6805991 },
    { lat: 49.3537955, lng: 21.6803695 },
    { lat: 49.3539295, lng: 21.679582 },
    { lat: 49.353809, lng: 21.6772746 },
    { lat: 49.3539616, lng: 21.6767962 },
    { lat: 49.3538454, lng: 21.6762818 },
    { lat: 49.3541664, lng: 21.6756752 },
    { lat: 49.3543542, lng: 21.6754198 },
    { lat: 49.3544193, lng: 21.6751238 },
    { lat: 49.3547324, lng: 21.6748035 },
    { lat: 49.3554413, lng: 21.6737632 },
    { lat: 49.3554838, lng: 21.6733831 },
    { lat: 49.3560315, lng: 21.6724605 },
    { lat: 49.3559629, lng: 21.6721715 },
    { lat: 49.3563799, lng: 21.6719449 },
    { lat: 49.3567463, lng: 21.6720614 },
    { lat: 49.357332, lng: 21.6708358 },
  ],
  Dobroslava: [
    { lat: 49.3527895, lng: 21.6185053 },
    { lat: 49.3528312, lng: 21.6185914 },
    { lat: 49.3536508, lng: 21.6202589 },
    { lat: 49.3545086, lng: 21.6232039 },
    { lat: 49.3544762, lng: 21.6244866 },
    { lat: 49.3552551, lng: 21.6252825 },
    { lat: 49.3554451, lng: 21.6258173 },
    { lat: 49.3560987, lng: 21.6263063 },
    { lat: 49.3573635, lng: 21.6269845 },
    { lat: 49.3579593, lng: 21.6290715 },
    { lat: 49.3589476, lng: 21.6307411 },
    { lat: 49.3598988, lng: 21.6309708 },
    { lat: 49.36015, lng: 21.631028 },
    { lat: 49.3608682, lng: 21.6303451 },
    { lat: 49.3611722, lng: 21.6311019 },
    { lat: 49.3615429, lng: 21.6322294 },
    { lat: 49.361624, lng: 21.6322849 },
    { lat: 49.3616645, lng: 21.6327742 },
    { lat: 49.3622601, lng: 21.6332493 },
    { lat: 49.3632734, lng: 21.6328662 },
    { lat: 49.3640002, lng: 21.6323769 },
    { lat: 49.3643834, lng: 21.6322001 },
    { lat: 49.365112, lng: 21.6325205 },
    { lat: 49.3671228, lng: 21.6330369 },
    { lat: 49.3681719, lng: 21.6324898 },
    { lat: 49.3689777, lng: 21.6324177 },
    { lat: 49.369895, lng: 21.6317797 },
    { lat: 49.3703562, lng: 21.6315275 },
    { lat: 49.3703738, lng: 21.6314715 },
    { lat: 49.3706859, lng: 21.6305849 },
    { lat: 49.3713381, lng: 21.6300458 },
    { lat: 49.3714748, lng: 21.6299002 },
    { lat: 49.3715548, lng: 21.6296623 },
    { lat: 49.3717346, lng: 21.6295728 },
    { lat: 49.372494, lng: 21.6298209 },
    { lat: 49.3730787, lng: 21.6299358 },
    { lat: 49.3734926, lng: 21.6299382 },
    { lat: 49.373883, lng: 21.630102 },
    { lat: 49.3745298, lng: 21.6301473 },
    { lat: 49.3756048, lng: 21.6304633 },
    { lat: 49.3762249, lng: 21.6304676 },
    { lat: 49.3767559, lng: 21.6303459 },
    { lat: 49.3774311, lng: 21.6303995 },
    { lat: 49.3780753, lng: 21.6302196 },
    { lat: 49.3791713, lng: 21.6301549 },
    { lat: 49.3815053, lng: 21.6290737 },
    { lat: 49.3817408, lng: 21.6288412 },
    { lat: 49.3825217, lng: 21.6276175 },
    { lat: 49.3831277, lng: 21.6271909 },
    { lat: 49.3834798, lng: 21.6271131 },
    { lat: 49.3842349, lng: 21.626591 },
    { lat: 49.3844982, lng: 21.6262643 },
    { lat: 49.3848513, lng: 21.6260977 },
    { lat: 49.3850759, lng: 21.6259165 },
    { lat: 49.3851978, lng: 21.6256407 },
    { lat: 49.3852909, lng: 21.6247711 },
    { lat: 49.3850363, lng: 21.6246296 },
    { lat: 49.3846262, lng: 21.624401 },
    { lat: 49.3841385, lng: 21.6235196 },
    { lat: 49.383959, lng: 21.6234881 },
    { lat: 49.3836228, lng: 21.6229591 },
    { lat: 49.3828325, lng: 21.6226105 },
    { lat: 49.3826226, lng: 21.6223369 },
    { lat: 49.3824792, lng: 21.6222735 },
    { lat: 49.3822313, lng: 21.6220194 },
    { lat: 49.3818955, lng: 21.6219547 },
    { lat: 49.3817409, lng: 21.6218498 },
    { lat: 49.3805577, lng: 21.6204155 },
    { lat: 49.3801692, lng: 21.6194126 },
    { lat: 49.3798589, lng: 21.6187639 },
    { lat: 49.3784043, lng: 21.6172411 },
    { lat: 49.3782042, lng: 21.6169143 },
    { lat: 49.3777449, lng: 21.6164135 },
    { lat: 49.3771084, lng: 21.6155161 },
    { lat: 49.3769789, lng: 21.6141967 },
    { lat: 49.376552, lng: 21.6128646 },
    { lat: 49.3762109, lng: 21.612118 },
    { lat: 49.3759134, lng: 21.6110361 },
    { lat: 49.3758297, lng: 21.6104422 },
    { lat: 49.3756673, lng: 21.609774 },
    { lat: 49.3753529, lng: 21.6088123 },
    { lat: 49.3746048, lng: 21.6081122 },
    { lat: 49.3736026, lng: 21.6069668 },
    { lat: 49.3729596, lng: 21.6058004 },
    { lat: 49.3729927, lng: 21.6056495 },
    { lat: 49.3728732, lng: 21.6053605 },
    { lat: 49.3724037, lng: 21.6048143 },
    { lat: 49.3717487, lng: 21.6043093 },
    { lat: 49.3715622, lng: 21.6039066 },
    { lat: 49.37159, lng: 21.6027934 },
    { lat: 49.3715362, lng: 21.6023103 },
    { lat: 49.3714114, lng: 21.6023927 },
    { lat: 49.3708475, lng: 21.6027926 },
    { lat: 49.3696392, lng: 21.6031532 },
    { lat: 49.3680924, lng: 21.6029466 },
    { lat: 49.366887, lng: 21.6023076 },
    { lat: 49.3651615, lng: 21.6019035 },
    { lat: 49.3646303, lng: 21.6016487 },
    { lat: 49.3641054, lng: 21.6012936 },
    { lat: 49.3626814, lng: 21.599625 },
    { lat: 49.3614319, lng: 21.5988998 },
    { lat: 49.360934, lng: 21.5987945 },
    { lat: 49.3600579, lng: 21.5984725 },
    { lat: 49.3592886, lng: 21.5987677 },
    { lat: 49.3590985, lng: 21.5989257 },
    { lat: 49.3583711, lng: 21.5988569 },
    { lat: 49.3578306, lng: 21.5986789 },
    { lat: 49.357635, lng: 21.5988095 },
    { lat: 49.3569904, lng: 21.5988476 },
    { lat: 49.3566868, lng: 21.5985644 },
    { lat: 49.3566002, lng: 21.5984827 },
    { lat: 49.3555243, lng: 21.5983003 },
    { lat: 49.3563454, lng: 21.5999292 },
    { lat: 49.356233, lng: 21.6000946 },
    { lat: 49.3561317, lng: 21.6016026 },
    { lat: 49.3562037, lng: 21.6023836 },
    { lat: 49.3561049, lng: 21.6037792 },
    { lat: 49.3562036, lng: 21.6061758 },
    { lat: 49.355928, lng: 21.6072776 },
    { lat: 49.3558608, lng: 21.608966 },
    { lat: 49.3556567, lng: 21.6103829 },
    { lat: 49.3556856, lng: 21.6110313 },
    { lat: 49.355273, lng: 21.6127549 },
    { lat: 49.3539733, lng: 21.6133559 },
    { lat: 49.3543757, lng: 21.6153522 },
    { lat: 49.3527895, lng: 21.6185053 },
  ],
  NižnýOrlík: [
    { lat: 49.368066, lng: 21.5283673 },
    { lat: 49.3677836, lng: 21.5278496 },
    { lat: 49.3675097, lng: 21.5273457 },
    { lat: 49.3668956, lng: 21.5256378 },
    { lat: 49.3665571, lng: 21.5249192 },
    { lat: 49.3650307, lng: 21.523647 },
    { lat: 49.3642422, lng: 21.523414 },
    { lat: 49.3636367, lng: 21.523082 },
    { lat: 49.3632243, lng: 21.5229782 },
    { lat: 49.3627119, lng: 21.5226375 },
    { lat: 49.3621645, lng: 21.5224972 },
    { lat: 49.3604852, lng: 21.5216688 },
    { lat: 49.3595424, lng: 21.5221423 },
    { lat: 49.3591749, lng: 21.5222741 },
    { lat: 49.3588684, lng: 21.5222569 },
    { lat: 49.358769, lng: 21.5223172 },
    { lat: 49.358631, lng: 21.5224165 },
    { lat: 49.3584977, lng: 21.5227134 },
    { lat: 49.3582419, lng: 21.5228194 },
    { lat: 49.357274, lng: 21.5235633 },
    { lat: 49.3571069, lng: 21.5237739 },
    { lat: 49.3558742, lng: 21.5239034 },
    { lat: 49.3556226, lng: 21.5240016 },
    { lat: 49.3555433, lng: 21.5238975 },
    { lat: 49.3553141, lng: 21.5244312 },
    { lat: 49.3545828, lng: 21.5250227 },
    { lat: 49.3543512, lng: 21.5251105 },
    { lat: 49.354136, lng: 21.5250197 },
    { lat: 49.3538294, lng: 21.5251129 },
    { lat: 49.3534954, lng: 21.5249127 },
    { lat: 49.353302, lng: 21.5249601 },
    { lat: 49.352825, lng: 21.5253171 },
    { lat: 49.3520826, lng: 21.5255199 },
    { lat: 49.3516078, lng: 21.5258363 },
    { lat: 49.3495443, lng: 21.5248292 },
    { lat: 49.3481414, lng: 21.5242663 },
    { lat: 49.3467068, lng: 21.5230982 },
    { lat: 49.3456928, lng: 21.5225281 },
    { lat: 49.3451376, lng: 21.5211483 },
    { lat: 49.344715, lng: 21.5203248 },
    { lat: 49.3443358, lng: 21.5200436 },
    { lat: 49.3440688, lng: 21.5197138 },
    { lat: 49.344059, lng: 21.5195662 },
    { lat: 49.3441687, lng: 21.5192674 },
    { lat: 49.3441474, lng: 21.5191083 },
    { lat: 49.3439588, lng: 21.5192402 },
    { lat: 49.3434928, lng: 21.5190314 },
    { lat: 49.342804, lng: 21.5180665 },
    { lat: 49.3422123, lng: 21.5176738 },
    { lat: 49.3418841, lng: 21.5169625 },
    { lat: 49.3408686, lng: 21.5161794 },
    { lat: 49.3406557, lng: 21.5158883 },
    { lat: 49.3402549, lng: 21.5156017 },
    { lat: 49.3401603, lng: 21.5151141 },
    { lat: 49.3400351, lng: 21.5149481 },
    { lat: 49.3394184, lng: 21.5145063 },
    { lat: 49.3387438, lng: 21.5138825 },
    { lat: 49.338336, lng: 21.5136585 },
    { lat: 49.3382491, lng: 21.5136942 },
    { lat: 49.3380488, lng: 21.5140058 },
    { lat: 49.3378241, lng: 21.5144558 },
    { lat: 49.3376046, lng: 21.5146536 },
    { lat: 49.3368929, lng: 21.5137852 },
    { lat: 49.3361418, lng: 21.5132852 },
    { lat: 49.335852, lng: 21.5127825 },
    { lat: 49.3353935, lng: 21.5116488 },
    { lat: 49.3349901, lng: 21.5113466 },
    { lat: 49.3346009, lng: 21.5107155 },
    { lat: 49.3345835, lng: 21.5104169 },
    { lat: 49.3344533, lng: 21.5100324 },
    { lat: 49.334173, lng: 21.5098226 },
    { lat: 49.3337527, lng: 21.5088095 },
    { lat: 49.3332301, lng: 21.5086285 },
    { lat: 49.3330626, lng: 21.5083568 },
    { lat: 49.331861, lng: 21.5075557 },
    { lat: 49.3313987, lng: 21.5071251 },
    { lat: 49.3310294, lng: 21.5069509 },
    { lat: 49.3308082, lng: 21.506567 },
    { lat: 49.3304333, lng: 21.5061652 },
    { lat: 49.3290325, lng: 21.5050824 },
    { lat: 49.3289768, lng: 21.505304 },
    { lat: 49.3278265, lng: 21.5104381 },
    { lat: 49.3269079, lng: 21.5151838 },
    { lat: 49.3272268, lng: 21.5175492 },
    { lat: 49.3269799, lng: 21.5217922 },
    { lat: 49.3264414, lng: 21.5254733 },
    { lat: 49.3247606, lng: 21.52694 },
    { lat: 49.3233482, lng: 21.528633 },
    { lat: 49.3232566, lng: 21.5288122 },
    { lat: 49.3220355, lng: 21.5288401 },
    { lat: 49.3209664, lng: 21.5285424 },
    { lat: 49.3194786, lng: 21.5272861 },
    { lat: 49.31861, lng: 21.5274819 },
    { lat: 49.3176996, lng: 21.5272737 },
    { lat: 49.3171371, lng: 21.5269528 },
    { lat: 49.3162936, lng: 21.5262404 },
    { lat: 49.315694, lng: 21.5254856 },
    { lat: 49.3132748, lng: 21.5203663 },
    { lat: 49.311651, lng: 21.5181611 },
    { lat: 49.3110464, lng: 21.5174648 },
    { lat: 49.3095096, lng: 21.5167474 },
    { lat: 49.3082788, lng: 21.5171385 },
    { lat: 49.3070042, lng: 21.5171061 },
    { lat: 49.3057511, lng: 21.5168656 },
    { lat: 49.3050237, lng: 21.517244 },
    { lat: 49.3043045, lng: 21.5174293 },
    { lat: 49.3036984, lng: 21.517318 },
    { lat: 49.3028847, lng: 21.517462 },
    { lat: 49.3030023, lng: 21.5178035 },
    { lat: 49.3036192, lng: 21.5182541 },
    { lat: 49.3036177, lng: 21.5188429 },
    { lat: 49.3034632, lng: 21.5195377 },
    { lat: 49.3031823, lng: 21.5201936 },
    { lat: 49.303292, lng: 21.5211633 },
    { lat: 49.3031057, lng: 21.5213421 },
    { lat: 49.303026, lng: 21.5215085 },
    { lat: 49.3032087, lng: 21.5219611 },
    { lat: 49.3036958, lng: 21.5227127 },
    { lat: 49.3039123, lng: 21.5233675 },
    { lat: 49.3038521, lng: 21.5236743 },
    { lat: 49.3034779, lng: 21.5242047 },
    { lat: 49.3031034, lng: 21.5245683 },
    { lat: 49.3029079, lng: 21.5253332 },
    { lat: 49.3028822, lng: 21.526216 },
    { lat: 49.3022974, lng: 21.5267369 },
    { lat: 49.3017263, lng: 21.5275085 },
    { lat: 49.3014859, lng: 21.5277205 },
    { lat: 49.3028558, lng: 21.5279041 },
    { lat: 49.3031576, lng: 21.5279985 },
    { lat: 49.305441, lng: 21.5295069 },
    { lat: 49.3062353, lng: 21.5301882 },
    { lat: 49.3067831, lng: 21.5307887 },
    { lat: 49.3073831, lng: 21.5310765 },
    { lat: 49.3078209, lng: 21.5316047 },
    { lat: 49.3083779, lng: 21.5321279 },
    { lat: 49.3091664, lng: 21.5330072 },
    { lat: 49.3098883, lng: 21.5341491 },
    { lat: 49.3102537, lng: 21.5345858 },
    { lat: 49.310888, lng: 21.5350185 },
    { lat: 49.3114572, lng: 21.5358518 },
    { lat: 49.3118525, lng: 21.5370657 },
    { lat: 49.3125603, lng: 21.5382775 },
    { lat: 49.312775, lng: 21.5386286 },
    { lat: 49.3133137, lng: 21.5391838 },
    { lat: 49.3134842, lng: 21.5396921 },
    { lat: 49.3139056, lng: 21.5400329 },
    { lat: 49.3142629, lng: 21.540785 },
    { lat: 49.3146239, lng: 21.5411723 },
    { lat: 49.314768, lng: 21.5415025 },
    { lat: 49.3145832, lng: 21.5418139 },
    { lat: 49.315178, lng: 21.543082 },
    { lat: 49.3153938, lng: 21.5434385 },
    { lat: 49.3168888, lng: 21.5454822 },
    { lat: 49.3186061, lng: 21.5477101 },
    { lat: 49.319071, lng: 21.5470803 },
    { lat: 49.3190966, lng: 21.5474284 },
    { lat: 49.3203693, lng: 21.546117 },
    { lat: 49.321458, lng: 21.5476356 },
    { lat: 49.3219835, lng: 21.5482838 },
    { lat: 49.3231014, lng: 21.5492696 },
    { lat: 49.3237116, lng: 21.5496959 },
    { lat: 49.3244924, lng: 21.5505391 },
    { lat: 49.3247496, lng: 21.5508093 },
    { lat: 49.3254359, lng: 21.5493229 },
    { lat: 49.3262181, lng: 21.5485759 },
    { lat: 49.3269087, lng: 21.54865 },
    { lat: 49.3272353, lng: 21.5483407 },
    { lat: 49.3276356, lng: 21.5481002 },
    { lat: 49.3277039, lng: 21.5481207 },
    { lat: 49.3310046, lng: 21.5469624 },
    { lat: 49.3319667, lng: 21.5464942 },
    { lat: 49.3339403, lng: 21.5458672 },
    { lat: 49.3357042, lng: 21.5454279 },
    { lat: 49.3363776, lng: 21.544405 },
    { lat: 49.3378536, lng: 21.5428471 },
    { lat: 49.3407145, lng: 21.540088 },
    { lat: 49.3420068, lng: 21.5387197 },
    { lat: 49.3428958, lng: 21.5380107 },
    { lat: 49.3433075, lng: 21.5373703 },
    { lat: 49.3454587, lng: 21.5346155 },
    { lat: 49.3473849, lng: 21.5323454 },
    { lat: 49.3482209, lng: 21.5312352 },
    { lat: 49.3486748, lng: 21.5308142 },
    { lat: 49.3499233, lng: 21.5308491 },
    { lat: 49.3513726, lng: 21.5310613 },
    { lat: 49.3531295, lng: 21.5315722 },
    { lat: 49.3539514, lng: 21.5315624 },
    { lat: 49.3563153, lng: 21.5320131 },
    { lat: 49.3569891, lng: 21.5320502 },
    { lat: 49.3576, lng: 21.532373 },
    { lat: 49.3582964, lng: 21.5321401 },
    { lat: 49.3590592, lng: 21.5320227 },
    { lat: 49.3597048, lng: 21.5314171 },
    { lat: 49.3607428, lng: 21.5310862 },
    { lat: 49.361864, lng: 21.5313147 },
    { lat: 49.3626216, lng: 21.5310125 },
    { lat: 49.3639321, lng: 21.5296487 },
    { lat: 49.3653027, lng: 21.5279878 },
    { lat: 49.366446, lng: 21.5270099 },
    { lat: 49.367086, lng: 21.527962 },
    { lat: 49.3674049, lng: 21.529217 },
    { lat: 49.3674836, lng: 21.5304538 },
    { lat: 49.368066, lng: 21.5283673 },
  ],
  NižnýMirošov: [
    { lat: 49.3797679, lng: 21.477823 },
    { lat: 49.3793497, lng: 21.4763729 },
    { lat: 49.3774478, lng: 21.4738754 },
    { lat: 49.3766449, lng: 21.4722375 },
    { lat: 49.3755622, lng: 21.4690772 },
    { lat: 49.3746269, lng: 21.4674509 },
    { lat: 49.3740546, lng: 21.4667146 },
    { lat: 49.3734365, lng: 21.4657125 },
    { lat: 49.3726886, lng: 21.4642372 },
    { lat: 49.3720447, lng: 21.4626131 },
    { lat: 49.3719325, lng: 21.462116 },
    { lat: 49.3717408, lng: 21.4600604 },
    { lat: 49.3711353, lng: 21.4586803 },
    { lat: 49.3703624, lng: 21.456526 },
    { lat: 49.3698394, lng: 21.4545809 },
    { lat: 49.368928, lng: 21.4546118 },
    { lat: 49.3678634, lng: 21.4544064 },
    { lat: 49.3667935, lng: 21.4549497 },
    { lat: 49.3658516, lng: 21.4557998 },
    { lat: 49.3651036, lng: 21.4563065 },
    { lat: 49.3639152, lng: 21.4555107 },
    { lat: 49.3633582, lng: 21.4552354 },
    { lat: 49.3619032, lng: 21.4579958 },
    { lat: 49.3618271, lng: 21.4583669 },
    { lat: 49.3604867, lng: 21.4601435 },
    { lat: 49.3590783, lng: 21.4613219 },
    { lat: 49.3583591, lng: 21.4617413 },
    { lat: 49.3571728, lng: 21.4622651 },
    { lat: 49.3554879, lng: 21.4614165 },
    { lat: 49.3554435, lng: 21.4617912 },
    { lat: 49.3551537, lng: 21.4616093 },
    { lat: 49.3537215, lng: 21.4618028 },
    { lat: 49.3533652, lng: 21.462087 },
    { lat: 49.3531973, lng: 21.461452 },
    { lat: 49.352862, lng: 21.4612938 },
    { lat: 49.3526445, lng: 21.4613629 },
    { lat: 49.3520575, lng: 21.4623494 },
    { lat: 49.3494482, lng: 21.4608822 },
    { lat: 49.3467254, lng: 21.4599744 },
    { lat: 49.3453301, lng: 21.459336 },
    { lat: 49.3445393, lng: 21.458519 },
    { lat: 49.3433716, lng: 21.4579667 },
    { lat: 49.3400053, lng: 21.4572152 },
    { lat: 49.339117, lng: 21.4583206 },
    { lat: 49.3390951, lng: 21.4583469 },
    { lat: 49.3379198, lng: 21.456443 },
    { lat: 49.3378504, lng: 21.4576252 },
    { lat: 49.3379006, lng: 21.4578076 },
    { lat: 49.3378371, lng: 21.4579429 },
    { lat: 49.3377767, lng: 21.4586631 },
    { lat: 49.3376903, lng: 21.4589445 },
    { lat: 49.3373702, lng: 21.4590761 },
    { lat: 49.3368984, lng: 21.4609845 },
    { lat: 49.3362588, lng: 21.4625254 },
    { lat: 49.3379808, lng: 21.4624319 },
    { lat: 49.3382181, lng: 21.4654885 },
    { lat: 49.3399545, lng: 21.4707226 },
    { lat: 49.3412442, lng: 21.4732885 },
    { lat: 49.3419267, lng: 21.4744914 },
    { lat: 49.3427433, lng: 21.4771281 },
    { lat: 49.3439209, lng: 21.4792406 },
    { lat: 49.3459268, lng: 21.4808995 },
    { lat: 49.3480016, lng: 21.4808262 },
    { lat: 49.3501558, lng: 21.481058 },
    { lat: 49.3525985, lng: 21.4806054 },
    { lat: 49.3534099, lng: 21.4828584 },
    { lat: 49.3543833, lng: 21.4834933 },
    { lat: 49.3547807, lng: 21.484064 },
    { lat: 49.3557277, lng: 21.4844037 },
    { lat: 49.3585586, lng: 21.4841465 },
    { lat: 49.3601865, lng: 21.4842252 },
    { lat: 49.3606111, lng: 21.4840517 },
    { lat: 49.362177, lng: 21.4844367 },
    { lat: 49.3628176, lng: 21.4851688 },
    { lat: 49.3627355, lng: 21.4860012 },
    { lat: 49.3640755, lng: 21.4868731 },
    { lat: 49.3641123, lng: 21.4863462 },
    { lat: 49.364526, lng: 21.4858638 },
    { lat: 49.3655754, lng: 21.4854651 },
    { lat: 49.3687348, lng: 21.4863537 },
    { lat: 49.3693536, lng: 21.4866256 },
    { lat: 49.3695623, lng: 21.4871242 },
    { lat: 49.37144, lng: 21.485432 },
    { lat: 49.3719628, lng: 21.4838025 },
    { lat: 49.3737086, lng: 21.4819513 },
    { lat: 49.3746695, lng: 21.4817541 },
    { lat: 49.3768001, lng: 21.4810435 },
    { lat: 49.3768604, lng: 21.4807969 },
    { lat: 49.3780173, lng: 21.480767 },
    { lat: 49.3790785, lng: 21.4800709 },
    { lat: 49.3800493, lng: 21.4795636 },
    { lat: 49.3807974, lng: 21.4788466 },
    { lat: 49.3809105, lng: 21.4782768 },
    { lat: 49.3797679, lng: 21.477823 },
  ],
  Havranec: [
    { lat: 49.4031673, lng: 21.5452911 },
    { lat: 49.402842, lng: 21.5466883 },
    { lat: 49.4030486, lng: 21.5468359 },
    { lat: 49.4034876, lng: 21.5477317 },
    { lat: 49.4042769, lng: 21.5487632 },
    { lat: 49.4045653, lng: 21.5497358 },
    { lat: 49.405079, lng: 21.5505737 },
    { lat: 49.4057327, lng: 21.5514176 },
    { lat: 49.4060555, lng: 21.552021 },
    { lat: 49.4063393, lng: 21.5529643 },
    { lat: 49.4068678, lng: 21.5538432 },
    { lat: 49.406992, lng: 21.5546179 },
    { lat: 49.4074791, lng: 21.5559539 },
    { lat: 49.4073267, lng: 21.5562139 },
    { lat: 49.4074644, lng: 21.5576838 },
    { lat: 49.4082559, lng: 21.5584335 },
    { lat: 49.4090293, lng: 21.5587387 },
    { lat: 49.4090936, lng: 21.5589043 },
    { lat: 49.4100527, lng: 21.5603433 },
    { lat: 49.4108029, lng: 21.5610232 },
    { lat: 49.4131055, lng: 21.5634477 },
    { lat: 49.4145209, lng: 21.5644239 },
    { lat: 49.4145757, lng: 21.5647751 },
    { lat: 49.4162292, lng: 21.5646146 },
    { lat: 49.4174823, lng: 21.5641298 },
    { lat: 49.4178548, lng: 21.5638529 },
    { lat: 49.4182479, lng: 21.5639114 },
    { lat: 49.4187352, lng: 21.5636338 },
    { lat: 49.4196761, lng: 21.5633255 },
    { lat: 49.4205382, lng: 21.5629396 },
    { lat: 49.4214415, lng: 21.5627367 },
    { lat: 49.4216948, lng: 21.5624181 },
    { lat: 49.4220224, lng: 21.5622741 },
    { lat: 49.4236041, lng: 21.560253 },
    { lat: 49.4240647, lng: 21.5594235 },
    { lat: 49.4249122, lng: 21.5582774 },
    { lat: 49.4267642, lng: 21.5570738 },
    { lat: 49.4270733, lng: 21.5570253 },
    { lat: 49.4274405, lng: 21.5556725 },
    { lat: 49.4274353, lng: 21.5546844 },
    { lat: 49.4273235, lng: 21.5545461 },
    { lat: 49.4274091, lng: 21.5534242 },
    { lat: 49.4277371, lng: 21.5529206 },
    { lat: 49.4289346, lng: 21.5531098 },
    { lat: 49.4293064, lng: 21.5530438 },
    { lat: 49.4297609, lng: 21.5519967 },
    { lat: 49.4300418, lng: 21.5517491 },
    { lat: 49.4307648, lng: 21.551534 },
    { lat: 49.4312767, lng: 21.5519026 },
    { lat: 49.4318581, lng: 21.5519313 },
    { lat: 49.4326231, lng: 21.5512945 },
    { lat: 49.4330983, lng: 21.5504468 },
    { lat: 49.433212, lng: 21.550244 },
    { lat: 49.433316, lng: 21.54932 },
    { lat: 49.43337, lng: 21.548697 },
    { lat: 49.433441, lng: 21.547766 },
    { lat: 49.433466, lng: 21.547665 },
    { lat: 49.4336872, lng: 21.5467059 },
    { lat: 49.4338011, lng: 21.5458815 },
    { lat: 49.434004, lng: 21.5447913 },
    { lat: 49.4341585, lng: 21.5434198 },
    { lat: 49.4341063, lng: 21.542228 },
    { lat: 49.4341001, lng: 21.5420593 },
    { lat: 49.4341809, lng: 21.5413122 },
    { lat: 49.4343412, lng: 21.5410446 },
    { lat: 49.4343839, lng: 21.5408425 },
    { lat: 49.434383, lng: 21.540832 },
    { lat: 49.4343728, lng: 21.539321 },
    { lat: 49.4345029, lng: 21.5386557 },
    { lat: 49.4347612, lng: 21.5378475 },
    { lat: 49.4349049, lng: 21.5372851 },
    { lat: 49.434935, lng: 21.53698 },
    { lat: 49.4349535, lng: 21.5368001 },
    { lat: 49.435003, lng: 21.536319 },
    { lat: 49.4350231, lng: 21.5361259 },
    { lat: 49.434855, lng: 21.5354652 },
    { lat: 49.4351952, lng: 21.5348443 },
    { lat: 49.4354467, lng: 21.5342255 },
    { lat: 49.435442, lng: 21.533707 },
    { lat: 49.4354391, lng: 21.5331156 },
    { lat: 49.435628, lng: 21.5327767 },
    { lat: 49.4353538, lng: 21.5323256 },
    { lat: 49.4352269, lng: 21.5320187 },
    { lat: 49.4352141, lng: 21.5315907 },
    { lat: 49.435211, lng: 21.531574 },
    { lat: 49.435162, lng: 21.531086 },
    { lat: 49.4350836, lng: 21.5302734 },
    { lat: 49.4347175, lng: 21.5297644 },
    { lat: 49.4345191, lng: 21.5291372 },
    { lat: 49.4341332, lng: 21.5282899 },
    { lat: 49.433789, lng: 21.528308 },
    { lat: 49.4337725, lng: 21.5283109 },
    { lat: 49.433637, lng: 21.527916 },
    { lat: 49.4334176, lng: 21.527303 },
    { lat: 49.43341, lng: 21.527296 },
    { lat: 49.4332466, lng: 21.5271308 },
    { lat: 49.4328519, lng: 21.5269808 },
    { lat: 49.4328413, lng: 21.5269717 },
    { lat: 49.4325402, lng: 21.526421 },
    { lat: 49.432532, lng: 21.526408 },
    { lat: 49.4321252, lng: 21.5257614 },
    { lat: 49.431941, lng: 21.524948 },
    { lat: 49.4319393, lng: 21.5249352 },
    { lat: 49.431928, lng: 21.524899 },
    { lat: 49.431768, lng: 21.524383 },
    { lat: 49.4316735, lng: 21.5240763 },
    { lat: 49.43109, lng: 21.523876 },
    { lat: 49.4310788, lng: 21.5238734 },
    { lat: 49.431069, lng: 21.523868 },
    { lat: 49.4304541, lng: 21.5235794 },
    { lat: 49.4301751, lng: 21.5234181 },
    { lat: 49.4292561, lng: 21.5230263 },
    { lat: 49.4285386, lng: 21.5229265 },
    { lat: 49.4284877, lng: 21.5228837 },
    { lat: 49.428078, lng: 21.522565 },
    { lat: 49.4271329, lng: 21.5218035 },
    { lat: 49.427117, lng: 21.521768 },
    { lat: 49.426686, lng: 21.520483 },
    { lat: 49.4264821, lng: 21.5198998 },
    { lat: 49.4263408, lng: 21.5199073 },
    { lat: 49.4259774, lng: 21.5192826 },
    { lat: 49.4253953, lng: 21.5187157 },
    { lat: 49.424881, lng: 21.5185817 },
    { lat: 49.4242711, lng: 21.518524 },
    { lat: 49.4237913, lng: 21.5189077 },
    { lat: 49.4228478, lng: 21.5195172 },
    { lat: 49.4223895, lng: 21.5199096 },
    { lat: 49.421817, lng: 21.5205807 },
    { lat: 49.4217182, lng: 21.5208649 },
    { lat: 49.4212034, lng: 21.5218581 },
    { lat: 49.42118, lng: 21.521902 },
    { lat: 49.420533, lng: 21.522727 },
    { lat: 49.419937, lng: 21.523541 },
    { lat: 49.4194504, lng: 21.5240972 },
    { lat: 49.4192171, lng: 21.5244841 },
    { lat: 49.4186566, lng: 21.5250737 },
    { lat: 49.4182127, lng: 21.5253657 },
    { lat: 49.4176449, lng: 21.525937 },
    { lat: 49.4174084, lng: 21.5264863 },
    { lat: 49.4171934, lng: 21.527282 },
    { lat: 49.4169065, lng: 21.5279174 },
    { lat: 49.4161994, lng: 21.5282123 },
    { lat: 49.4160848, lng: 21.5284974 },
    { lat: 49.4151817, lng: 21.5292663 },
    { lat: 49.4149667, lng: 21.5295421 },
    { lat: 49.4143889, lng: 21.5291969 },
    { lat: 49.4138076, lng: 21.5289694 },
    { lat: 49.4131461, lng: 21.5286092 },
    { lat: 49.412737, lng: 21.5286214 },
    { lat: 49.4121853, lng: 21.5287591 },
    { lat: 49.4111215, lng: 21.5295331 },
    { lat: 49.4110517, lng: 21.5297303 },
    { lat: 49.4106096, lng: 21.5297262 },
    { lat: 49.410285, lng: 21.5298683 },
    { lat: 49.40987, lng: 21.5303094 },
    { lat: 49.4094733, lng: 21.5306047 },
    { lat: 49.4088646, lng: 21.5308469 },
    { lat: 49.4085257, lng: 21.5307558 },
    { lat: 49.4084787, lng: 21.5315576 },
    { lat: 49.408402, lng: 21.5322762 },
    { lat: 49.4085213, lng: 21.5329959 },
    { lat: 49.4081895, lng: 21.5335556 },
    { lat: 49.4080937, lng: 21.5338687 },
    { lat: 49.4079864, lng: 21.5343793 },
    { lat: 49.4081696, lng: 21.5347776 },
    { lat: 49.407888, lng: 21.5356787 },
    { lat: 49.4075416, lng: 21.5358428 },
    { lat: 49.406527, lng: 21.5374511 },
    { lat: 49.406467, lng: 21.5381204 },
    { lat: 49.4065708, lng: 21.5394097 },
    { lat: 49.4065026, lng: 21.5408245 },
    { lat: 49.4053333, lng: 21.5431009 },
    { lat: 49.4043896, lng: 21.5441076 },
    { lat: 49.4031673, lng: 21.5452911 },
  ],
  Kračúnovce: [
    { lat: 49.1049555, lng: 21.4936374 },
    { lat: 49.1049492, lng: 21.4933441 },
    { lat: 49.1049465, lng: 21.4932078 },
    { lat: 49.104796, lng: 21.4932221 },
    { lat: 49.1047499, lng: 21.4924359 },
    { lat: 49.1049126, lng: 21.4923368 },
    { lat: 49.1049167, lng: 21.4920097 },
    { lat: 49.105216, lng: 21.4920154 },
    { lat: 49.1048304, lng: 21.4899791 },
    { lat: 49.1025843, lng: 21.4906695 },
    { lat: 49.1022063, lng: 21.4868264 },
    { lat: 49.1005378, lng: 21.4872939 },
    { lat: 49.1005324, lng: 21.4871855 },
    { lat: 49.1001228, lng: 21.4872648 },
    { lat: 49.1001704, lng: 21.4868465 },
    { lat: 49.100242, lng: 21.4866307 },
    { lat: 49.1001843, lng: 21.4861157 },
    { lat: 49.1000445, lng: 21.4861052 },
    { lat: 49.0999028, lng: 21.4859516 },
    { lat: 49.0998725, lng: 21.4858319 },
    { lat: 49.0999718, lng: 21.485258 },
    { lat: 49.0999113, lng: 21.4846779 },
    { lat: 49.0999285, lng: 21.4830829 },
    { lat: 49.10014, lng: 21.4816323 },
    { lat: 49.1004433, lng: 21.4806735 },
    { lat: 49.0998534, lng: 21.4805031 },
    { lat: 49.0995519, lng: 21.4803466 },
    { lat: 49.099688, lng: 21.4792916 },
    { lat: 49.0998973, lng: 21.4781976 },
    { lat: 49.0997785, lng: 21.4780939 },
    { lat: 49.1002454, lng: 21.4757447 },
    { lat: 49.0997591, lng: 21.475467 },
    { lat: 49.1000335, lng: 21.4739345 },
    { lat: 49.0999705, lng: 21.4731962 },
    { lat: 49.1001242, lng: 21.47287 },
    { lat: 49.100196, lng: 21.4725036 },
    { lat: 49.1001269, lng: 21.4712846 },
    { lat: 49.1002563, lng: 21.4709324 },
    { lat: 49.1001467, lng: 21.4704428 },
    { lat: 49.1002256, lng: 21.4701782 },
    { lat: 49.1004127, lng: 21.4698386 },
    { lat: 49.1002474, lng: 21.4693319 },
    { lat: 49.1003003, lng: 21.4688667 },
    { lat: 49.1006457, lng: 21.4684755 },
    { lat: 49.1008141, lng: 21.467758 },
    { lat: 49.1010572, lng: 21.467726 },
    { lat: 49.1012654, lng: 21.466632 },
    { lat: 49.1012206, lng: 21.4660931 },
    { lat: 49.1012644, lng: 21.4658195 },
    { lat: 49.1013363, lng: 21.4656962 },
    { lat: 49.1013068, lng: 21.4655341 },
    { lat: 49.101096, lng: 21.4653274 },
    { lat: 49.1013461, lng: 21.464209 },
    { lat: 49.1014873, lng: 21.4639931 },
    { lat: 49.1013754, lng: 21.4619073 },
    { lat: 49.1017346, lng: 21.4607141 },
    { lat: 49.1016401, lng: 21.4605919 },
    { lat: 49.1016782, lng: 21.4601987 },
    { lat: 49.1014615, lng: 21.4595938 },
    { lat: 49.1013394, lng: 21.4587557 },
    { lat: 49.101428, lng: 21.4587433 },
    { lat: 49.1015609, lng: 21.458018 },
    { lat: 49.1015518, lng: 21.4570327 },
    { lat: 49.1014967, lng: 21.4570751 },
    { lat: 49.1015615, lng: 21.4567903 },
    { lat: 49.1014627, lng: 21.4565201 },
    { lat: 49.1007557, lng: 21.4557313 },
    { lat: 49.1007368, lng: 21.4553082 },
    { lat: 49.100609, lng: 21.4547466 },
    { lat: 49.1004059, lng: 21.4545696 },
    { lat: 49.0999899, lng: 21.454739 },
    { lat: 49.0997172, lng: 21.4539017 },
    { lat: 49.0989783, lng: 21.4531772 },
    { lat: 49.0985414, lng: 21.4531602 },
    { lat: 49.0982834, lng: 21.4524889 },
    { lat: 49.0980022, lng: 21.4519886 },
    { lat: 49.0977305, lng: 21.4518967 },
    { lat: 49.0969063, lng: 21.4509367 },
    { lat: 49.09671, lng: 21.4508031 },
    { lat: 49.0964851, lng: 21.4505328 },
    { lat: 49.0962597, lng: 21.4506405 },
    { lat: 49.0957839, lng: 21.4497527 },
    { lat: 49.0953083, lng: 21.4493758 },
    { lat: 49.0946793, lng: 21.448989 },
    { lat: 49.0940803, lng: 21.4487798 },
    { lat: 49.093811, lng: 21.4485664 },
    { lat: 49.0936585, lng: 21.4482011 },
    { lat: 49.0934377, lng: 21.4471407 },
    { lat: 49.0932953, lng: 21.4467748 },
    { lat: 49.0929446, lng: 21.4462444 },
    { lat: 49.0929106, lng: 21.4459972 },
    { lat: 49.093024, lng: 21.445727 },
    { lat: 49.093008, lng: 21.445716 },
    { lat: 49.09245, lng: 21.445351 },
    { lat: 49.091924, lng: 21.445048 },
    { lat: 49.090771, lng: 21.444455 },
    { lat: 49.089726, lng: 21.443849 },
    { lat: 49.089373, lng: 21.443762 },
    { lat: 49.088926, lng: 21.443577 },
    { lat: 49.088842, lng: 21.443655 },
    { lat: 49.088775, lng: 21.443685 },
    { lat: 49.088724, lng: 21.443707 },
    { lat: 49.088709, lng: 21.443713 },
    { lat: 49.08873, lng: 21.443764 },
    { lat: 49.088746, lng: 21.443802 },
    { lat: 49.088757, lng: 21.443831 },
    { lat: 49.088793, lng: 21.443924 },
    { lat: 49.088859, lng: 21.444087 },
    { lat: 49.088733, lng: 21.444207 },
    { lat: 49.088569, lng: 21.444363 },
    { lat: 49.088459, lng: 21.444466 },
    { lat: 49.088419, lng: 21.44447 },
    { lat: 49.088348, lng: 21.444422 },
    { lat: 49.088295, lng: 21.444384 },
    { lat: 49.088108, lng: 21.444405 },
    { lat: 49.087512, lng: 21.444725 },
    { lat: 49.087165, lng: 21.444884 },
    { lat: 49.087095, lng: 21.445021 },
    { lat: 49.087087, lng: 21.445036 },
    { lat: 49.086954, lng: 21.445027 },
    { lat: 49.086908, lng: 21.445025 },
    { lat: 49.086026, lng: 21.444826 },
    { lat: 49.085887, lng: 21.444704 },
    { lat: 49.085844, lng: 21.444666 },
    { lat: 49.085805, lng: 21.444674 },
    { lat: 49.085673, lng: 21.444703 },
    { lat: 49.085364, lng: 21.444535 },
    { lat: 49.08532, lng: 21.444512 },
    { lat: 49.08529, lng: 21.444514 },
    { lat: 49.084837, lng: 21.444546 },
    { lat: 49.084529, lng: 21.444568 },
    { lat: 49.084507, lng: 21.444481 },
    { lat: 49.084474, lng: 21.444346 },
    { lat: 49.084471, lng: 21.444334 },
    { lat: 49.084344, lng: 21.444249 },
    { lat: 49.084205, lng: 21.444319 },
    { lat: 49.08405, lng: 21.444397 },
    { lat: 49.083729, lng: 21.444479 },
    { lat: 49.083389, lng: 21.444431 },
    { lat: 49.083296, lng: 21.444418 },
    { lat: 49.083269, lng: 21.444463 },
    { lat: 49.083136, lng: 21.444682 },
    { lat: 49.08293, lng: 21.444587 },
    { lat: 49.082343, lng: 21.444875 },
    { lat: 49.081818, lng: 21.444851 },
    { lat: 49.081737, lng: 21.444847 },
    { lat: 49.081677, lng: 21.445076 },
    { lat: 49.081679, lng: 21.445128 },
    { lat: 49.081706, lng: 21.445695 },
    { lat: 49.081569, lng: 21.44579 },
    { lat: 49.081425, lng: 21.445778 },
    { lat: 49.081402, lng: 21.446159 },
    { lat: 49.081377, lng: 21.446549 },
    { lat: 49.081366, lng: 21.446737 },
    { lat: 49.081218, lng: 21.446831 },
    { lat: 49.08105, lng: 21.446937 },
    { lat: 49.080957, lng: 21.446995 },
    { lat: 49.080912, lng: 21.447024 },
    { lat: 49.080897, lng: 21.44714 },
    { lat: 49.080875, lng: 21.447315 },
    { lat: 49.080754, lng: 21.447626 },
    { lat: 49.080782, lng: 21.447634 },
    { lat: 49.080916, lng: 21.447788 },
    { lat: 49.080922, lng: 21.447795 },
    { lat: 49.080905, lng: 21.447872 },
    { lat: 49.080853, lng: 21.448107 },
    { lat: 49.08078, lng: 21.448385 },
    { lat: 49.080565, lng: 21.449199 },
    { lat: 49.080552, lng: 21.449249 },
    { lat: 49.080552, lng: 21.449278 },
    { lat: 49.080559, lng: 21.450194 },
    { lat: 49.080602, lng: 21.451259 },
    { lat: 49.080568, lng: 21.451425 },
    { lat: 49.080514, lng: 21.451689 },
    { lat: 49.080636, lng: 21.452223 },
    { lat: 49.080427, lng: 21.452952 },
    { lat: 49.080371, lng: 21.453345 },
    { lat: 49.080297, lng: 21.453698 },
    { lat: 49.080221, lng: 21.453936 },
    { lat: 49.08003, lng: 21.454874 },
    { lat: 49.079976, lng: 21.455138 },
    { lat: 49.079913, lng: 21.455569 },
    { lat: 49.079863, lng: 21.45591 },
    { lat: 49.079851, lng: 21.455997 },
    { lat: 49.079838, lng: 21.456066 },
    { lat: 49.079825, lng: 21.456141 },
    { lat: 49.079674, lng: 21.456964 },
    { lat: 49.079668, lng: 21.456999 },
    { lat: 49.079593, lng: 21.457383 },
    { lat: 49.079488, lng: 21.457924 },
    { lat: 49.079378, lng: 21.45823 },
    { lat: 49.079377, lng: 21.458235 },
    { lat: 49.079248, lng: 21.458594 },
    { lat: 49.079207, lng: 21.458706 },
    { lat: 49.079186, lng: 21.458765 },
    { lat: 49.078985, lng: 21.459353 },
    { lat: 49.07896, lng: 21.459424 },
    { lat: 49.078702, lng: 21.459951 },
    { lat: 49.078629, lng: 21.460126 },
    { lat: 49.078399, lng: 21.460686 },
    { lat: 49.078381, lng: 21.460863 },
    { lat: 49.078321, lng: 21.461416 },
    { lat: 49.078066, lng: 21.462112 },
    { lat: 49.077819, lng: 21.462735 },
    { lat: 49.077746, lng: 21.463077 },
    { lat: 49.077627, lng: 21.463434 },
    { lat: 49.077622, lng: 21.46345 },
    { lat: 49.077634, lng: 21.464248 },
    { lat: 49.077619, lng: 21.464841 },
    { lat: 49.077623, lng: 21.46532 },
    { lat: 49.077611, lng: 21.465332 },
    { lat: 49.077569, lng: 21.465371 },
    { lat: 49.077491, lng: 21.465667 },
    { lat: 49.077492, lng: 21.465701 },
    { lat: 49.077625, lng: 21.465833 },
    { lat: 49.077659, lng: 21.465955 },
    { lat: 49.077555, lng: 21.466597 },
    { lat: 49.077564, lng: 21.466687 },
    { lat: 49.077467, lng: 21.467053 },
    { lat: 49.077543, lng: 21.467233 },
    { lat: 49.077522, lng: 21.467329 },
    { lat: 49.077471, lng: 21.467475 },
    { lat: 49.077597, lng: 21.467856 },
    { lat: 49.07757, lng: 21.467905 },
    { lat: 49.077509, lng: 21.468156 },
    { lat: 49.077704, lng: 21.468324 },
    { lat: 49.077761, lng: 21.468603 },
    { lat: 49.077878, lng: 21.469073 },
    { lat: 49.078008, lng: 21.469398 },
    { lat: 49.078267, lng: 21.469706 },
    { lat: 49.078279, lng: 21.469804 },
    { lat: 49.078323, lng: 21.470109 },
    { lat: 49.078461, lng: 21.470283 },
    { lat: 49.078648, lng: 21.470304 },
    { lat: 49.078545, lng: 21.470418 },
    { lat: 49.078449, lng: 21.47052 },
    { lat: 49.078127, lng: 21.470882 },
    { lat: 49.077916, lng: 21.470833 },
    { lat: 49.077799, lng: 21.471392 },
    { lat: 49.07778, lng: 21.471483 },
    { lat: 49.077777, lng: 21.47168 },
    { lat: 49.078279, lng: 21.471777 },
    { lat: 49.078253, lng: 21.472701 },
    { lat: 49.078547, lng: 21.472763 },
    { lat: 49.078458, lng: 21.473266 },
    { lat: 49.078463, lng: 21.473296 },
    { lat: 49.078575, lng: 21.474145 },
    { lat: 49.078713, lng: 21.474572 },
    { lat: 49.078829, lng: 21.475013 },
    { lat: 49.078947, lng: 21.475481 },
    { lat: 49.079007, lng: 21.475853 },
    { lat: 49.079205, lng: 21.476654 },
    { lat: 49.079231, lng: 21.477051 },
    { lat: 49.079603, lng: 21.478191 },
    { lat: 49.079668, lng: 21.478661 },
    { lat: 49.08002, lng: 21.480256 },
    { lat: 49.079654, lng: 21.480803 },
    { lat: 49.080129, lng: 21.481562 },
    { lat: 49.079723, lng: 21.482807 },
    { lat: 49.079756, lng: 21.482856 },
    { lat: 49.079637, lng: 21.483656 },
    { lat: 49.079555, lng: 21.484063 },
    { lat: 49.079466, lng: 21.484394 },
    { lat: 49.079294, lng: 21.484751 },
    { lat: 49.079077, lng: 21.4852 },
    { lat: 49.079041, lng: 21.485279 },
    { lat: 49.079569, lng: 21.485809 },
    { lat: 49.079673, lng: 21.486003 },
    { lat: 49.079718, lng: 21.486076 },
    { lat: 49.079775, lng: 21.486379 },
    { lat: 49.079737, lng: 21.486486 },
    { lat: 49.079725, lng: 21.486514 },
    { lat: 49.0813576, lng: 21.4878194 },
    { lat: 49.0817266, lng: 21.4879769 },
    { lat: 49.0821881, lng: 21.4879053 },
    { lat: 49.0826457, lng: 21.4879287 },
    { lat: 49.0830015, lng: 21.4875526 },
    { lat: 49.0832129, lng: 21.4871886 },
    { lat: 49.0834446, lng: 21.4869988 },
    { lat: 49.0839889, lng: 21.4869884 },
    { lat: 49.0842672, lng: 21.4870805 },
    { lat: 49.0846514, lng: 21.4873737 },
    { lat: 49.0847996, lng: 21.4875594 },
    { lat: 49.0855672, lng: 21.4888659 },
    { lat: 49.0860525, lng: 21.4891908 },
    { lat: 49.0863911, lng: 21.4895772 },
    { lat: 49.0866847, lng: 21.4897766 },
    { lat: 49.0870519, lng: 21.4902993 },
    { lat: 49.0877396, lng: 21.4910153 },
    { lat: 49.0884029, lng: 21.4913877 },
    { lat: 49.0886093, lng: 21.4916059 },
    { lat: 49.0886856, lng: 21.4914375 },
    { lat: 49.0896838, lng: 21.4916849 },
    { lat: 49.0906149, lng: 21.4914336 },
    { lat: 49.0915789, lng: 21.4903292 },
    { lat: 49.0918455, lng: 21.4902454 },
    { lat: 49.0917248, lng: 21.4914887 },
    { lat: 49.093432, lng: 21.4918607 },
    { lat: 49.0937515, lng: 21.494998 },
    { lat: 49.0927478, lng: 21.495079 },
    { lat: 49.0928357, lng: 21.4976403 },
    { lat: 49.0928281, lng: 21.4977643 },
    { lat: 49.09272, lng: 21.4978009 },
    { lat: 49.0928582, lng: 21.498303 },
    { lat: 49.1001295, lng: 21.4979478 },
    { lat: 49.0999426, lng: 21.4986506 },
    { lat: 49.0998737, lng: 21.4999036 },
    { lat: 49.1001078, lng: 21.5006151 },
    { lat: 49.1000978, lng: 21.5010133 },
    { lat: 49.0996622, lng: 21.5018352 },
    { lat: 49.0995327, lng: 21.5027328 },
    { lat: 49.0995834, lng: 21.5031301 },
    { lat: 49.0994109, lng: 21.5034374 },
    { lat: 49.0993712, lng: 21.5038956 },
    { lat: 49.0995334, lng: 21.5041459 },
    { lat: 49.0999017, lng: 21.5051391 },
    { lat: 49.0999662, lng: 21.5050756 },
    { lat: 49.1004585, lng: 21.5045483 },
    { lat: 49.1010429, lng: 21.5042076 },
    { lat: 49.1006429, lng: 21.5034338 },
    { lat: 49.10041, lng: 21.5031228 },
    { lat: 49.1001061, lng: 21.5028424 },
    { lat: 49.0997977, lng: 21.5032682 },
    { lat: 49.0996945, lng: 21.5026104 },
    { lat: 49.1001538, lng: 21.5016716 },
    { lat: 49.1003509, lng: 21.5018349 },
    { lat: 49.1005982, lng: 21.5010419 },
    { lat: 49.1007793, lng: 21.5006496 },
    { lat: 49.1014111, lng: 21.4998786 },
    { lat: 49.101423, lng: 21.4990422 },
    { lat: 49.1010697, lng: 21.4983271 },
    { lat: 49.1024194, lng: 21.4978391 },
    { lat: 49.1027239, lng: 21.4991334 },
    { lat: 49.1030839, lng: 21.4998008 },
    { lat: 49.1031807, lng: 21.4996805 },
    { lat: 49.1032547, lng: 21.4982131 },
    { lat: 49.1027301, lng: 21.4965133 },
    { lat: 49.1030884, lng: 21.4963699 },
    { lat: 49.1035146, lng: 21.4967237 },
    { lat: 49.1039938, lng: 21.496562 },
    { lat: 49.1046554, lng: 21.4969519 },
    { lat: 49.1050387, lng: 21.4968252 },
    { lat: 49.1050927, lng: 21.4967045 },
    { lat: 49.1047576, lng: 21.4959267 },
    { lat: 49.1047277, lng: 21.4957445 },
    { lat: 49.1047767, lng: 21.4949452 },
    { lat: 49.104705, lng: 21.4943592 },
    { lat: 49.1047477, lng: 21.4938501 },
    { lat: 49.1048626, lng: 21.4935782 },
    { lat: 49.1049555, lng: 21.4936374 },
  ],
  KrajnáPorúbka: [
    { lat: 49.4148626, lng: 21.636712 },
    { lat: 49.4138892, lng: 21.6373212 },
    { lat: 49.4140948, lng: 21.6377225 },
    { lat: 49.4138852, lng: 21.6383272 },
    { lat: 49.413667, lng: 21.6381222 },
    { lat: 49.413472, lng: 21.6383175 },
    { lat: 49.4129219, lng: 21.6378169 },
    { lat: 49.4130274, lng: 21.637683 },
    { lat: 49.4127484, lng: 21.6374772 },
    { lat: 49.4123452, lng: 21.6382006 },
    { lat: 49.4122665, lng: 21.6384512 },
    { lat: 49.411483, lng: 21.6392148 },
    { lat: 49.4112421, lng: 21.6399515 },
    { lat: 49.4107667, lng: 21.6402127 },
    { lat: 49.4097066, lng: 21.6412738 },
    { lat: 49.4095496, lng: 21.6421598 },
    { lat: 49.4087399, lng: 21.6440678 },
    { lat: 49.4074079, lng: 21.646099 },
    { lat: 49.4060425, lng: 21.6468488 },
    { lat: 49.4059191, lng: 21.6471063 },
    { lat: 49.4058334, lng: 21.6475589 },
    { lat: 49.405635, lng: 21.6481202 },
    { lat: 49.4056284, lng: 21.6484136 },
    { lat: 49.4055476, lng: 21.6487568 },
    { lat: 49.4051053, lng: 21.6501586 },
    { lat: 49.4048591, lng: 21.6504954 },
    { lat: 49.4046221, lng: 21.6510024 },
    { lat: 49.4043758, lng: 21.6517531 },
    { lat: 49.4041815, lng: 21.6521851 },
    { lat: 49.4041171, lng: 21.6525826 },
    { lat: 49.4040074, lng: 21.6528128 },
    { lat: 49.4040059, lng: 21.6530043 },
    { lat: 49.4040785, lng: 21.6532818 },
    { lat: 49.4039418, lng: 21.6548309 },
    { lat: 49.4040697, lng: 21.6552367 },
    { lat: 49.4040233, lng: 21.6557696 },
    { lat: 49.4042207, lng: 21.65704 },
    { lat: 49.4033055, lng: 21.6570937 },
    { lat: 49.4037044, lng: 21.6585778 },
    { lat: 49.4042775, lng: 21.6586773 },
    { lat: 49.4042369, lng: 21.659965 },
    { lat: 49.4046035, lng: 21.6605012 },
    { lat: 49.4046872, lng: 21.6632709 },
    { lat: 49.4046445, lng: 21.6634416 },
    { lat: 49.4046483, lng: 21.6638871 },
    { lat: 49.4046502, lng: 21.6642759 },
    { lat: 49.4049169, lng: 21.6649059 },
    { lat: 49.4050109, lng: 21.6655971 },
    { lat: 49.4052798, lng: 21.6664791 },
    { lat: 49.4062782, lng: 21.6658394 },
    { lat: 49.4068792, lng: 21.6655554 },
    { lat: 49.4073119, lng: 21.6651274 },
    { lat: 49.4075987, lng: 21.6650817 },
    { lat: 49.4088352, lng: 21.6643464 },
    { lat: 49.4102207, lng: 21.6636203 },
    { lat: 49.411769, lng: 21.6635149 },
    { lat: 49.4136679, lng: 21.6631504 },
    { lat: 49.4148686, lng: 21.6632133 },
    { lat: 49.4154061, lng: 21.6633135 },
    { lat: 49.4159657, lng: 21.6625144 },
    { lat: 49.4162663, lng: 21.6620052 },
    { lat: 49.416384, lng: 21.661245 },
    { lat: 49.4165414, lng: 21.6600291 },
    { lat: 49.4168007, lng: 21.6589052 },
    { lat: 49.417189, lng: 21.65848 },
    { lat: 49.4176499, lng: 21.6580604 },
    { lat: 49.4182607, lng: 21.6576764 },
    { lat: 49.4183653, lng: 21.6575451 },
    { lat: 49.4189837, lng: 21.65705 },
    { lat: 49.41967, lng: 21.6567963 },
    { lat: 49.4205086, lng: 21.6564337 },
    { lat: 49.4211735, lng: 21.6566307 },
    { lat: 49.4217316, lng: 21.6564332 },
    { lat: 49.4221832, lng: 21.6562225 },
    { lat: 49.422189, lng: 21.656218 },
    { lat: 49.4227593, lng: 21.6560805 },
    { lat: 49.4229845, lng: 21.6560795 },
    { lat: 49.4233119, lng: 21.6559936 },
    { lat: 49.4235215, lng: 21.6560645 },
    { lat: 49.4244128, lng: 21.656617 },
    { lat: 49.4246388, lng: 21.6564726 },
    { lat: 49.4248782, lng: 21.6558887 },
    { lat: 49.4254722, lng: 21.6552987 },
    { lat: 49.425775, lng: 21.654818 },
    { lat: 49.4260503, lng: 21.654386 },
    { lat: 49.4263092, lng: 21.6541502 },
    { lat: 49.4266077, lng: 21.6540565 },
    { lat: 49.426985, lng: 21.653504 },
    { lat: 49.427445, lng: 21.652829 },
    { lat: 49.4274588, lng: 21.6528125 },
    { lat: 49.4279697, lng: 21.6521656 },
    { lat: 49.4282803, lng: 21.6519202 },
    { lat: 49.4282945, lng: 21.651907 },
    { lat: 49.4285449, lng: 21.651434 },
    { lat: 49.428947, lng: 21.6510485 },
    { lat: 49.4293207, lng: 21.6503538 },
    { lat: 49.4297563, lng: 21.6499656 },
    { lat: 49.4301528, lng: 21.6494184 },
    { lat: 49.4304534, lng: 21.6482412 },
    { lat: 49.4303752, lng: 21.6477174 },
    { lat: 49.43045, lng: 21.647316 },
    { lat: 49.4305418, lng: 21.6468776 },
    { lat: 49.4307209, lng: 21.6465016 },
    { lat: 49.430831, lng: 21.645843 },
    { lat: 49.430752, lng: 21.644921 },
    { lat: 49.430733, lng: 21.644806 },
    { lat: 49.4306508, lng: 21.6443012 },
    { lat: 49.4301728, lng: 21.6446243 },
    { lat: 49.4291801, lng: 21.6448133 },
    { lat: 49.428862, lng: 21.6447382 },
    { lat: 49.4281399, lng: 21.6449078 },
    { lat: 49.427983, lng: 21.6450028 },
    { lat: 49.4264281, lng: 21.6450777 },
    { lat: 49.4261564, lng: 21.6444721 },
    { lat: 49.4254005, lng: 21.6443042 },
    { lat: 49.4246521, lng: 21.6438782 },
    { lat: 49.4241779, lng: 21.6421083 },
    { lat: 49.422372, lng: 21.6407233 },
    { lat: 49.4222142, lng: 21.6409632 },
    { lat: 49.4220806, lng: 21.6407661 },
    { lat: 49.4208693, lng: 21.6408973 },
    { lat: 49.4201298, lng: 21.6402605 },
    { lat: 49.4187394, lng: 21.6385941 },
    { lat: 49.418218, lng: 21.6363852 },
    { lat: 49.4171644, lng: 21.6349492 },
    { lat: 49.4158362, lng: 21.6350804 },
    { lat: 49.4156448, lng: 21.6354073 },
    { lat: 49.4159391, lng: 21.6358878 },
    { lat: 49.4153348, lng: 21.6367491 },
    { lat: 49.4148904, lng: 21.6367041 },
    { lat: 49.4148626, lng: 21.636712 },
  ],
  Kalnište: [
    { lat: 49.128485, lng: 21.491884 },
    { lat: 49.129362, lng: 21.491868 },
    { lat: 49.131574, lng: 21.491966 },
    { lat: 49.131622, lng: 21.491954 },
    { lat: 49.134292, lng: 21.492019 },
    { lat: 49.135629, lng: 21.4919641 },
    { lat: 49.1357332, lng: 21.4916628 },
    { lat: 49.135882, lng: 21.491218 },
    { lat: 49.135946, lng: 21.489835 },
    { lat: 49.136252, lng: 21.489894 },
    { lat: 49.138166, lng: 21.489597 },
    { lat: 49.138203, lng: 21.489606 },
    { lat: 49.138489, lng: 21.489597 },
    { lat: 49.138974, lng: 21.490089 },
    { lat: 49.139505, lng: 21.48885 },
    { lat: 49.139355, lng: 21.488656 },
    { lat: 49.140363, lng: 21.486693 },
    { lat: 49.140755, lng: 21.485835 },
    { lat: 49.141219, lng: 21.48628 },
    { lat: 49.14233, lng: 21.48646 },
    { lat: 49.142814, lng: 21.486075 },
    { lat: 49.143283, lng: 21.485395 },
    { lat: 49.143639, lng: 21.484754 },
    { lat: 49.144054, lng: 21.483899 },
    { lat: 49.144461, lng: 21.482665 },
    { lat: 49.144541, lng: 21.482421 },
    { lat: 49.144582, lng: 21.482459 },
    { lat: 49.144825, lng: 21.482765 },
    { lat: 49.146217, lng: 21.484388 },
    { lat: 49.146226, lng: 21.484373 },
    { lat: 49.146314, lng: 21.484319 },
    { lat: 49.146632, lng: 21.48427 },
    { lat: 49.147236, lng: 21.48397 },
    { lat: 49.147628, lng: 21.483512 },
    { lat: 49.147925, lng: 21.482747 },
    { lat: 49.148239, lng: 21.481129 },
    { lat: 49.147011, lng: 21.479529 },
    { lat: 49.146706, lng: 21.479136 },
    { lat: 49.147492, lng: 21.478213 },
    { lat: 49.148264, lng: 21.477226 },
    { lat: 49.148822, lng: 21.476082 },
    { lat: 49.149121, lng: 21.475304 },
    { lat: 49.149428, lng: 21.474118 },
    { lat: 49.14982, lng: 21.472667 },
    { lat: 49.149871, lng: 21.472536 },
    { lat: 49.149998, lng: 21.472721 },
    { lat: 49.150659, lng: 21.473677 },
    { lat: 49.151642, lng: 21.47218 },
    { lat: 49.151254, lng: 21.471565 },
    { lat: 49.151137, lng: 21.47136 },
    { lat: 49.151334, lng: 21.471076 },
    { lat: 49.151724, lng: 21.470467 },
    { lat: 49.152186, lng: 21.469584 },
    { lat: 49.152609, lng: 21.468647 },
    { lat: 49.15309, lng: 21.467571 },
    { lat: 49.153429, lng: 21.467001 },
    { lat: 49.153959, lng: 21.466335 },
    { lat: 49.154665, lng: 21.465428 },
    { lat: 49.154896, lng: 21.465018 },
    { lat: 49.155279, lng: 21.463948 },
    { lat: 49.155467, lng: 21.463784 },
    { lat: 49.15842, lng: 21.463437 },
    { lat: 49.159132, lng: 21.461888 },
    { lat: 49.158631, lng: 21.461193 },
    { lat: 49.157498, lng: 21.460564 },
    { lat: 49.156223, lng: 21.459987 },
    { lat: 49.156247, lng: 21.459119 },
    { lat: 49.156271, lng: 21.458251 },
    { lat: 49.156242, lng: 21.45832 },
    { lat: 49.156138, lng: 21.458434 },
    { lat: 49.155733, lng: 21.457956 },
    { lat: 49.155684, lng: 21.457789 },
    { lat: 49.15561, lng: 21.45772 },
    { lat: 49.155494, lng: 21.45774 },
    { lat: 49.15547, lng: 21.457731 },
    { lat: 49.155409, lng: 21.457592 },
    { lat: 49.15518, lng: 21.457506 },
    { lat: 49.155088, lng: 21.457431 },
    { lat: 49.155047, lng: 21.457273 },
    { lat: 49.155127, lng: 21.457171 },
    { lat: 49.155147, lng: 21.457007 },
    { lat: 49.155075, lng: 21.456859 },
    { lat: 49.154982, lng: 21.456532 },
    { lat: 49.154665, lng: 21.456207 },
    { lat: 49.154623, lng: 21.456057 },
    { lat: 49.154679, lng: 21.455489 },
    { lat: 49.154643, lng: 21.455432 },
    { lat: 49.154605, lng: 21.45534 },
    { lat: 49.154597, lng: 21.455213 },
    { lat: 49.154643, lng: 21.455053 },
    { lat: 49.154593, lng: 21.454776 },
    { lat: 49.154613, lng: 21.454619 },
    { lat: 49.154565, lng: 21.454409 },
    { lat: 49.154656, lng: 21.45417 },
    { lat: 49.154589, lng: 21.454097 },
    { lat: 49.154565, lng: 21.453955 },
    { lat: 49.154569, lng: 21.453766 },
    { lat: 49.15456, lng: 21.453543 },
    { lat: 49.154521, lng: 21.453316 },
    { lat: 49.154497, lng: 21.453056 },
    { lat: 49.154474, lng: 21.452881 },
    { lat: 49.154497, lng: 21.452726 },
    { lat: 49.154462, lng: 21.452747 },
    { lat: 49.15444, lng: 21.452743 },
    { lat: 49.154432, lng: 21.452685 },
    { lat: 49.154477, lng: 21.45257 },
    { lat: 49.154397, lng: 21.452435 },
    { lat: 49.154356, lng: 21.452174 },
    { lat: 49.154316, lng: 21.45211 },
    { lat: 49.154282, lng: 21.451903 },
    { lat: 49.154351, lng: 21.451762 },
    { lat: 49.154326, lng: 21.451668 },
    { lat: 49.154314, lng: 21.451462 },
    { lat: 49.154241, lng: 21.451374 },
    { lat: 49.154138, lng: 21.451309 },
    { lat: 49.154111, lng: 21.451146 },
    { lat: 49.154052, lng: 21.45111 },
    { lat: 49.153933, lng: 21.451056 },
    { lat: 49.153841, lng: 21.450768 },
    { lat: 49.15377, lng: 21.450739 },
    { lat: 49.153729, lng: 21.450557 },
    { lat: 49.153494, lng: 21.450305 },
    { lat: 49.153412, lng: 21.450288 },
    { lat: 49.152972, lng: 21.449715 },
    { lat: 49.152905, lng: 21.449538 },
    { lat: 49.152748, lng: 21.449439 },
    { lat: 49.152421, lng: 21.44953 },
    { lat: 49.151981, lng: 21.449369 },
    { lat: 49.151597, lng: 21.449273 },
    { lat: 49.151518, lng: 21.449328 },
    { lat: 49.151355, lng: 21.449206 },
    { lat: 49.151132, lng: 21.44919 },
    { lat: 49.150946, lng: 21.449033 },
    { lat: 49.15086, lng: 21.448717 },
    { lat: 49.15067, lng: 21.448555 },
    { lat: 49.15028, lng: 21.448361 },
    { lat: 49.149762, lng: 21.448365 },
    { lat: 49.149633, lng: 21.448325 },
    { lat: 49.149499, lng: 21.448339 },
    { lat: 49.149317, lng: 21.44826 },
    { lat: 49.149253, lng: 21.44815 },
    { lat: 49.149027, lng: 21.448129 },
    { lat: 49.148949, lng: 21.448171 },
    { lat: 49.148875, lng: 21.448161 },
    { lat: 49.148733, lng: 21.44806 },
    { lat: 49.148471, lng: 21.448087 },
    { lat: 49.148254, lng: 21.44795 },
    { lat: 49.148176, lng: 21.447753 },
    { lat: 49.147835, lng: 21.44744 },
    { lat: 49.147507, lng: 21.447365 },
    { lat: 49.147062, lng: 21.447408 },
    { lat: 49.146884, lng: 21.447387 },
    { lat: 49.146807, lng: 21.447445 },
    { lat: 49.146622, lng: 21.447446 },
    { lat: 49.14621, lng: 21.447762 },
    { lat: 49.145774, lng: 21.448098 },
    { lat: 49.1451993, lng: 21.4488366 },
    { lat: 49.1440139, lng: 21.450793 },
    { lat: 49.1435504, lng: 21.4517287 },
    { lat: 49.1429858, lng: 21.4524322 },
    { lat: 49.1420189, lng: 21.4538585 },
    { lat: 49.1417134, lng: 21.4545025 },
    { lat: 49.1405185, lng: 21.4566389 },
    { lat: 49.1402934, lng: 21.4562491 },
    { lat: 49.1400436, lng: 21.4560887 },
    { lat: 49.1393203, lng: 21.4560162 },
    { lat: 49.1388119, lng: 21.4564549 },
    { lat: 49.1379728, lng: 21.4575127 },
    { lat: 49.1374962, lng: 21.4586504 },
    { lat: 49.1357425, lng: 21.4595226 },
    { lat: 49.1348268, lng: 21.4597601 },
    { lat: 49.1345174, lng: 21.4599529 },
    { lat: 49.1342036, lng: 21.4600009 },
    { lat: 49.1328576, lng: 21.4607264 },
    { lat: 49.1324814, lng: 21.4606737 },
    { lat: 49.1320449, lng: 21.4607928 },
    { lat: 49.1314834, lng: 21.4607859 },
    { lat: 49.1310205, lng: 21.4606107 },
    { lat: 49.1306365, lng: 21.4607209 },
    { lat: 49.13031, lng: 21.4607112 },
    { lat: 49.1298415, lng: 21.4609312 },
    { lat: 49.1284823, lng: 21.4607353 },
    { lat: 49.1280941, lng: 21.4610825 },
    { lat: 49.1277258, lng: 21.4612268 },
    { lat: 49.127249, lng: 21.4610371 },
    { lat: 49.1270763, lng: 21.4610559 },
    { lat: 49.1262353, lng: 21.4616188 },
    { lat: 49.1255145, lng: 21.4617896 },
    { lat: 49.1247285, lng: 21.4621567 },
    { lat: 49.1245441, lng: 21.4623594 },
    { lat: 49.1241099, lng: 21.4622811 },
    { lat: 49.1237742, lng: 21.4625044 },
    { lat: 49.1235644, lng: 21.4622907 },
    { lat: 49.1233513, lng: 21.4624311 },
    { lat: 49.12323, lng: 21.4626328 },
    { lat: 49.1226147, lng: 21.46283 },
    { lat: 49.122589, lng: 21.4632617 },
    { lat: 49.1222059, lng: 21.4634414 },
    { lat: 49.1217466, lng: 21.4639808 },
    { lat: 49.121648, lng: 21.4648524 },
    { lat: 49.1211003, lng: 21.4671172 },
    { lat: 49.1207327, lng: 21.4675578 },
    { lat: 49.1199505, lng: 21.4680993 },
    { lat: 49.1190261, lng: 21.4689327 },
    { lat: 49.1188041, lng: 21.4691487 },
    { lat: 49.1183077, lng: 21.4698097 },
    { lat: 49.1176355, lng: 21.4702961 },
    { lat: 49.1172697, lng: 21.470646 },
    { lat: 49.1162457, lng: 21.4712643 },
    { lat: 49.1162607, lng: 21.4713197 },
    { lat: 49.1170624, lng: 21.4756807 },
    { lat: 49.117976, lng: 21.4810042 },
    { lat: 49.117974, lng: 21.4815995 },
    { lat: 49.1173587, lng: 21.4873689 },
    { lat: 49.1173885, lng: 21.4875023 },
    { lat: 49.1174238, lng: 21.4875172 },
    { lat: 49.118856, lng: 21.4869238 },
    { lat: 49.1199373, lng: 21.4884261 },
    { lat: 49.1201254, lng: 21.48871 },
    { lat: 49.1203874, lng: 21.488499 },
    { lat: 49.1206329, lng: 21.4883971 },
    { lat: 49.1210536, lng: 21.4885473 },
    { lat: 49.1215327, lng: 21.4884666 },
    { lat: 49.1217063, lng: 21.4881968 },
    { lat: 49.1216906, lng: 21.4871087 },
    { lat: 49.1218795, lng: 21.4864683 },
    { lat: 49.1222355, lng: 21.4859011 },
    { lat: 49.1228813, lng: 21.4853509 },
    { lat: 49.1235023, lng: 21.4862895 },
    { lat: 49.1238362, lng: 21.4869067 },
    { lat: 49.1246205, lng: 21.4886399 },
    { lat: 49.1246234, lng: 21.4887848 },
    { lat: 49.1237115, lng: 21.4899357 },
    { lat: 49.1237724, lng: 21.4901813 },
    { lat: 49.1238551, lng: 21.4905145 },
    { lat: 49.1246622, lng: 21.4909602 },
    { lat: 49.1256122, lng: 21.4919604 },
    { lat: 49.1256626, lng: 21.4923077 },
    { lat: 49.1270557, lng: 21.4918778 },
    { lat: 49.128485, lng: 21.491884 },
  ],
  NižnáJedľová: [
    { lat: 49.3494556, lng: 21.5706132 },
    { lat: 49.35007, lng: 21.5679128 },
    { lat: 49.3504328, lng: 21.5668384 },
    { lat: 49.3508032, lng: 21.5650332 },
    { lat: 49.3520333, lng: 21.5644506 },
    { lat: 49.3532282, lng: 21.5632085 },
    { lat: 49.3534646, lng: 21.5624894 },
    { lat: 49.3537567, lng: 21.5618659 },
    { lat: 49.3539576, lng: 21.561209 },
    { lat: 49.3547095, lng: 21.5602177 },
    { lat: 49.3537769, lng: 21.5595306 },
    { lat: 49.3526926, lng: 21.5588889 },
    { lat: 49.3515048, lng: 21.558487 },
    { lat: 49.350646, lng: 21.5586702 },
    { lat: 49.3488809, lng: 21.5582653 },
    { lat: 49.3477763, lng: 21.5573789 },
    { lat: 49.3473041, lng: 21.5568278 },
    { lat: 49.3464538, lng: 21.5555453 },
    { lat: 49.3450458, lng: 21.5539926 },
    { lat: 49.344979, lng: 21.5538563 },
    { lat: 49.3436348, lng: 21.5530535 },
    { lat: 49.3425276, lng: 21.5526827 },
    { lat: 49.3412554, lng: 21.5517231 },
    { lat: 49.3407213, lng: 21.5514467 },
    { lat: 49.3386935, lng: 21.5481677 },
    { lat: 49.3386053, lng: 21.5479484 },
    { lat: 49.3363776, lng: 21.544405 },
    { lat: 49.3357042, lng: 21.5454279 },
    { lat: 49.3339403, lng: 21.5458672 },
    { lat: 49.3319667, lng: 21.5464942 },
    { lat: 49.3310046, lng: 21.5469624 },
    { lat: 49.3277039, lng: 21.5481207 },
    { lat: 49.3276356, lng: 21.5481002 },
    { lat: 49.3272353, lng: 21.5483407 },
    { lat: 49.3269087, lng: 21.54865 },
    { lat: 49.3262181, lng: 21.5485759 },
    { lat: 49.3254359, lng: 21.5493229 },
    { lat: 49.3247496, lng: 21.5508093 },
    { lat: 49.3244215, lng: 21.5522669 },
    { lat: 49.3243413, lng: 21.5533021 },
    { lat: 49.325088, lng: 21.5543366 },
    { lat: 49.3249986, lng: 21.5545628 },
    { lat: 49.3248422, lng: 21.5547592 },
    { lat: 49.3246584, lng: 21.5552696 },
    { lat: 49.3246589, lng: 21.555725 },
    { lat: 49.3247294, lng: 21.5559202 },
    { lat: 49.3245975, lng: 21.5563101 },
    { lat: 49.3245917, lng: 21.5567017 },
    { lat: 49.324706, lng: 21.5568537 },
    { lat: 49.3247322, lng: 21.5571412 },
    { lat: 49.3248921, lng: 21.5577517 },
    { lat: 49.3248739, lng: 21.5584663 },
    { lat: 49.3249541, lng: 21.5588051 },
    { lat: 49.3249846, lng: 21.5592146 },
    { lat: 49.3251536, lng: 21.5600229 },
    { lat: 49.3256731, lng: 21.5617955 },
    { lat: 49.325861, lng: 21.5628792 },
    { lat: 49.3262246, lng: 21.5639818 },
    { lat: 49.3268084, lng: 21.5646174 },
    { lat: 49.3272581, lng: 21.5656227 },
    { lat: 49.3273529, lng: 21.5660793 },
    { lat: 49.3276164, lng: 21.5666529 },
    { lat: 49.3285845, lng: 21.5678456 },
    { lat: 49.3298328, lng: 21.5691301 },
    { lat: 49.3305478, lng: 21.5700804 },
    { lat: 49.3315017, lng: 21.5709297 },
    { lat: 49.3323713, lng: 21.5723039 },
    { lat: 49.3331065, lng: 21.572972 },
    { lat: 49.3334415, lng: 21.5734061 },
    { lat: 49.3337845, lng: 21.5743397 },
    { lat: 49.3341502, lng: 21.574989 },
    { lat: 49.334201, lng: 21.5754416 },
    { lat: 49.3343659, lng: 21.5758287 },
    { lat: 49.3343865, lng: 21.5760438 },
    { lat: 49.3339386, lng: 21.577655 },
    { lat: 49.3339378, lng: 21.5781189 },
    { lat: 49.3340636, lng: 21.5784718 },
    { lat: 49.3340001, lng: 21.5797099 },
    { lat: 49.3338826, lng: 21.5800176 },
    { lat: 49.3349168, lng: 21.5792589 },
    { lat: 49.3362123, lng: 21.5789241 },
    { lat: 49.3373155, lng: 21.577974 },
    { lat: 49.338768, lng: 21.5772941 },
    { lat: 49.3397375, lng: 21.5771294 },
    { lat: 49.340956, lng: 21.5754765 },
    { lat: 49.3412997, lng: 21.5750963 },
    { lat: 49.3423626, lng: 21.5749258 },
    { lat: 49.3429809, lng: 21.5739757 },
    { lat: 49.3431049, lng: 21.5734997 },
    { lat: 49.3439845, lng: 21.5733353 },
    { lat: 49.345439, lng: 21.5732182 },
    { lat: 49.3464924, lng: 21.572506 },
    { lat: 49.3471445, lng: 21.5717643 },
    { lat: 49.3485779, lng: 21.5709659 },
    { lat: 49.3494556, lng: 21.5706132 },
  ],
  Rovné: [
    { lat: 49.2974914, lng: 21.534196 },
    { lat: 49.2981063, lng: 21.5338632 },
    { lat: 49.2986977, lng: 21.5334533 },
    { lat: 49.2991691, lng: 21.5337679 },
    { lat: 49.2992286, lng: 21.5333675 },
    { lat: 49.2995934, lng: 21.5324611 },
    { lat: 49.2998574, lng: 21.5325706 },
    { lat: 49.3006124, lng: 21.5307361 },
    { lat: 49.3013401, lng: 21.5279759 },
    { lat: 49.3014859, lng: 21.5277205 },
    { lat: 49.3017263, lng: 21.5275085 },
    { lat: 49.3022974, lng: 21.5267369 },
    { lat: 49.3028822, lng: 21.526216 },
    { lat: 49.3029079, lng: 21.5253332 },
    { lat: 49.3031034, lng: 21.5245683 },
    { lat: 49.3034779, lng: 21.5242047 },
    { lat: 49.3038521, lng: 21.5236743 },
    { lat: 49.3039123, lng: 21.5233675 },
    { lat: 49.3036958, lng: 21.5227127 },
    { lat: 49.3032087, lng: 21.5219611 },
    { lat: 49.303026, lng: 21.5215085 },
    { lat: 49.3031057, lng: 21.5213421 },
    { lat: 49.303292, lng: 21.5211633 },
    { lat: 49.3031823, lng: 21.5201936 },
    { lat: 49.3034632, lng: 21.5195377 },
    { lat: 49.3036177, lng: 21.5188429 },
    { lat: 49.3036192, lng: 21.5182541 },
    { lat: 49.3030023, lng: 21.5178035 },
    { lat: 49.3028847, lng: 21.517462 },
    { lat: 49.3027405, lng: 21.5170577 },
    { lat: 49.3023462, lng: 21.5164846 },
    { lat: 49.3022999, lng: 21.5162725 },
    { lat: 49.3023075, lng: 21.5151931 },
    { lat: 49.3025637, lng: 21.5143102 },
    { lat: 49.3024117, lng: 21.5119241 },
    { lat: 49.3026081, lng: 21.5098036 },
    { lat: 49.3026149, lng: 21.5092549 },
    { lat: 49.3027043, lng: 21.5085907 },
    { lat: 49.3028764, lng: 21.5079592 },
    { lat: 49.3028959, lng: 21.5076915 },
    { lat: 49.3035445, lng: 21.5069228 },
    { lat: 49.3043208, lng: 21.5058182 },
    { lat: 49.3047584, lng: 21.5053789 },
    { lat: 49.3045685, lng: 21.5045537 },
    { lat: 49.3042054, lng: 21.5023877 },
    { lat: 49.3040439, lng: 21.5016562 },
    { lat: 49.3038241, lng: 21.5014033 },
    { lat: 49.3035416, lng: 21.500488 },
    { lat: 49.3032141, lng: 21.5000469 },
    { lat: 49.3029342, lng: 21.499979 },
    { lat: 49.301946, lng: 21.5009092 },
    { lat: 49.3009898, lng: 21.5009458 },
    { lat: 49.2999751, lng: 21.5015831 },
    { lat: 49.2997342, lng: 21.5013771 },
    { lat: 49.299225, lng: 21.500681 },
    { lat: 49.298676, lng: 21.4996134 },
    { lat: 49.2983012, lng: 21.4990155 },
    { lat: 49.2976481, lng: 21.4986404 },
    { lat: 49.297242, lng: 21.4985618 },
    { lat: 49.297022, lng: 21.4983429 },
    { lat: 49.2966968, lng: 21.4978588 },
    { lat: 49.2961424, lng: 21.4984659 },
    { lat: 49.2955229, lng: 21.4985558 },
    { lat: 49.2948466, lng: 21.498071 },
    { lat: 49.2941997, lng: 21.4973996 },
    { lat: 49.2918798, lng: 21.495935 },
    { lat: 49.2907241, lng: 21.4949383 },
    { lat: 49.2904034, lng: 21.4945362 },
    { lat: 49.2899688, lng: 21.4942509 },
    { lat: 49.2897061, lng: 21.4939491 },
    { lat: 49.2892237, lng: 21.4936002 },
    { lat: 49.2888602, lng: 21.4932042 },
    { lat: 49.2884013, lng: 21.4925385 },
    { lat: 49.2882235, lng: 21.4921803 },
    { lat: 49.2882633, lng: 21.4917635 },
    { lat: 49.2880625, lng: 21.4915441 },
    { lat: 49.2874992, lng: 21.491244 },
    { lat: 49.2871254, lng: 21.4902854 },
    { lat: 49.2868119, lng: 21.4900569 },
    { lat: 49.2866871, lng: 21.4900631 },
    { lat: 49.2865703, lng: 21.4903058 },
    { lat: 49.2861566, lng: 21.4903986 },
    { lat: 49.2857159, lng: 21.4900457 },
    { lat: 49.2856462, lng: 21.4900485 },
    { lat: 49.2855048, lng: 21.4899127 },
    { lat: 49.2854576, lng: 21.4897933 },
    { lat: 49.2850989, lng: 21.4895567 },
    { lat: 49.2847639, lng: 21.4898472 },
    { lat: 49.2833071, lng: 21.4897663 },
    { lat: 49.2807552, lng: 21.4897509 },
    { lat: 49.2804892, lng: 21.4896995 },
    { lat: 49.2801588, lng: 21.4897846 },
    { lat: 49.2796397, lng: 21.490164 },
    { lat: 49.2781765, lng: 21.4921183 },
    { lat: 49.2735308, lng: 21.4920939 },
    { lat: 49.2734888, lng: 21.4915235 },
    { lat: 49.2732073, lng: 21.4910851 },
    { lat: 49.2728582, lng: 21.4902033 },
    { lat: 49.2722239, lng: 21.4895434 },
    { lat: 49.2714219, lng: 21.488917 },
    { lat: 49.2712286, lng: 21.4884155 },
    { lat: 49.271136, lng: 21.488304 },
    { lat: 49.271109, lng: 21.488333 },
    { lat: 49.271157, lng: 21.48837 },
    { lat: 49.271149, lng: 21.488552 },
    { lat: 49.271185, lng: 21.488931 },
    { lat: 49.271191, lng: 21.489304 },
    { lat: 49.271188, lng: 21.489644 },
    { lat: 49.271312, lng: 21.489732 },
    { lat: 49.271208, lng: 21.490282 },
    { lat: 49.27119, lng: 21.490463 },
    { lat: 49.271279, lng: 21.491535 },
    { lat: 49.271319, lng: 21.491778 },
    { lat: 49.27127, lng: 21.491796 },
    { lat: 49.27106, lng: 21.491869 },
    { lat: 49.270712, lng: 21.492227 },
    { lat: 49.270686, lng: 21.492382 },
    { lat: 49.269519, lng: 21.493903 },
    { lat: 49.269668, lng: 21.494402 },
    { lat: 49.270003, lng: 21.495036 },
    { lat: 49.27024, lng: 21.49559 },
    { lat: 49.269854, lng: 21.495955 },
    { lat: 49.26902, lng: 21.496501 },
    { lat: 49.268123, lng: 21.497165 },
    { lat: 49.267338, lng: 21.497663 },
    { lat: 49.266824, lng: 21.498085 },
    { lat: 49.265979, lng: 21.498772 },
    { lat: 49.265249, lng: 21.499504 },
    { lat: 49.2649354, lng: 21.500486 },
    { lat: 49.2645057, lng: 21.5015977 },
    { lat: 49.2642879, lng: 21.502561 },
    { lat: 49.2638201, lng: 21.5035193 },
    { lat: 49.2640291, lng: 21.5037482 },
    { lat: 49.2643002, lng: 21.5038645 },
    { lat: 49.2648496, lng: 21.50372 },
    { lat: 49.2651322, lng: 21.5062196 },
    { lat: 49.2655565, lng: 21.5085264 },
    { lat: 49.2651348, lng: 21.5103576 },
    { lat: 49.2649451, lng: 21.5116793 },
    { lat: 49.264281, lng: 21.5131746 },
    { lat: 49.2626458, lng: 21.5157329 },
    { lat: 49.2625645, lng: 21.5164756 },
    { lat: 49.2630914, lng: 21.5190336 },
    { lat: 49.2627383, lng: 21.5198324 },
    { lat: 49.2625246, lng: 21.5219036 },
    { lat: 49.2621765, lng: 21.5240331 },
    { lat: 49.2620499, lng: 21.5253248 },
    { lat: 49.2623809, lng: 21.5262546 },
    { lat: 49.262483, lng: 21.5270821 },
    { lat: 49.2624671, lng: 21.5277956 },
    { lat: 49.2628863, lng: 21.5298107 },
    { lat: 49.2632003, lng: 21.530335 },
    { lat: 49.2631956, lng: 21.5314826 },
    { lat: 49.2635528, lng: 21.5340601 },
    { lat: 49.2651629, lng: 21.5391863 },
    { lat: 49.2649945, lng: 21.5412369 },
    { lat: 49.2647858, lng: 21.5424159 },
    { lat: 49.2647481, lng: 21.5431103 },
    { lat: 49.2648786, lng: 21.5448355 },
    { lat: 49.2652302, lng: 21.5472248 },
    { lat: 49.2653682, lng: 21.5476909 },
    { lat: 49.2667705, lng: 21.5461098 },
    { lat: 49.2679774, lng: 21.5455772 },
    { lat: 49.2690029, lng: 21.5449674 },
    { lat: 49.2701382, lng: 21.5434066 },
    { lat: 49.2710167, lng: 21.5440057 },
    { lat: 49.271814, lng: 21.5434692 },
    { lat: 49.2728771, lng: 21.5421076 },
    { lat: 49.2738359, lng: 21.5405268 },
    { lat: 49.2742791, lng: 21.5393592 },
    { lat: 49.2749485, lng: 21.5384037 },
    { lat: 49.2755814, lng: 21.5379046 },
    { lat: 49.2759331, lng: 21.5374956 },
    { lat: 49.2764392, lng: 21.537093 },
    { lat: 49.2765989, lng: 21.5377664 },
    { lat: 49.2772927, lng: 21.5371122 },
    { lat: 49.2777754, lng: 21.5363973 },
    { lat: 49.2781468, lng: 21.5361275 },
    { lat: 49.2782949, lng: 21.5345966 },
    { lat: 49.2782605, lng: 21.5334905 },
    { lat: 49.2783553, lng: 21.5329409 },
    { lat: 49.2786478, lng: 21.5323044 },
    { lat: 49.2789044, lng: 21.5319074 },
    { lat: 49.2790763, lng: 21.531984 },
    { lat: 49.2791519, lng: 21.5313532 },
    { lat: 49.27907, lng: 21.5305651 },
    { lat: 49.2786267, lng: 21.5291621 },
    { lat: 49.2787244, lng: 21.5290349 },
    { lat: 49.2794604, lng: 21.5289255 },
    { lat: 49.28005, lng: 21.5286928 },
    { lat: 49.2804815, lng: 21.5287138 },
    { lat: 49.2816564, lng: 21.5284536 },
    { lat: 49.2819936, lng: 21.5290014 },
    { lat: 49.2825757, lng: 21.5290517 },
    { lat: 49.2840538, lng: 21.5297312 },
    { lat: 49.2846277, lng: 21.5302948 },
    { lat: 49.2876247, lng: 21.5325317 },
    { lat: 49.2881366, lng: 21.532653 },
    { lat: 49.2886551, lng: 21.532932 },
    { lat: 49.2890905, lng: 21.5327307 },
    { lat: 49.2893545, lng: 21.5329962 },
    { lat: 49.2901704, lng: 21.532807 },
    { lat: 49.290722, lng: 21.5333426 },
    { lat: 49.2911279, lng: 21.5336493 },
    { lat: 49.2913232, lng: 21.5337128 },
    { lat: 49.2915638, lng: 21.5336325 },
    { lat: 49.2920242, lng: 21.5332403 },
    { lat: 49.2924568, lng: 21.5330233 },
    { lat: 49.2932671, lng: 21.5329945 },
    { lat: 49.2937808, lng: 21.5327837 },
    { lat: 49.2953434, lng: 21.5335422 },
    { lat: 49.2955275, lng: 21.5338091 },
    { lat: 49.2956641, lng: 21.5342254 },
    { lat: 49.295916, lng: 21.5345822 },
    { lat: 49.296418, lng: 21.5347118 },
    { lat: 49.2969229, lng: 21.5345419 },
    { lat: 49.2974914, lng: 21.534196 },
  ],
  Roztoky: [
    { lat: 49.4211939, lng: 21.4826542 },
    { lat: 49.4201437, lng: 21.4816088 },
    { lat: 49.4197961, lng: 21.4812584 },
    { lat: 49.4193323, lng: 21.4810331 },
    { lat: 49.4189884, lng: 21.480625 },
    { lat: 49.4186149, lng: 21.480527 },
    { lat: 49.4182265, lng: 21.4808369 },
    { lat: 49.4172891, lng: 21.480246 },
    { lat: 49.4168565, lng: 21.4797629 },
    { lat: 49.4163758, lng: 21.4796044 },
    { lat: 49.4160735, lng: 21.4792799 },
    { lat: 49.4157787, lng: 21.4790484 },
    { lat: 49.415581, lng: 21.4783344 },
    { lat: 49.4154817, lng: 21.4774586 },
    { lat: 49.4152241, lng: 21.4764539 },
    { lat: 49.41506, lng: 21.4762596 },
    { lat: 49.414446, lng: 21.475195 },
    { lat: 49.4135854, lng: 21.4736964 },
    { lat: 49.4136921, lng: 21.4729381 },
    { lat: 49.4137154, lng: 21.4718605 },
    { lat: 49.4140961, lng: 21.470147 },
    { lat: 49.4136493, lng: 21.4691721 },
    { lat: 49.4125076, lng: 21.4686618 },
    { lat: 49.4102261, lng: 21.4681878 },
    { lat: 49.409745, lng: 21.4679257 },
    { lat: 49.4068434, lng: 21.4678302 },
    { lat: 49.403365, lng: 21.4672078 },
    { lat: 49.4026691, lng: 21.468089 },
    { lat: 49.4018184, lng: 21.4692906 },
    { lat: 49.4002573, lng: 21.4676618 },
    { lat: 49.3987684, lng: 21.4689352 },
    { lat: 49.3954644, lng: 21.4734932 },
    { lat: 49.3948967, lng: 21.4733483 },
    { lat: 49.3922198, lng: 21.4740683 },
    { lat: 49.3908526, lng: 21.4742193 },
    { lat: 49.3890772, lng: 21.4739355 },
    { lat: 49.3869906, lng: 21.4730364 },
    { lat: 49.3861923, lng: 21.4732131 },
    { lat: 49.3844286, lng: 21.4747889 },
    { lat: 49.3834483, lng: 21.4755188 },
    { lat: 49.3820027, lng: 21.4767438 },
    { lat: 49.3797679, lng: 21.477823 },
    { lat: 49.3809105, lng: 21.4782768 },
    { lat: 49.3807974, lng: 21.4788466 },
    { lat: 49.3800493, lng: 21.4795636 },
    { lat: 49.3790785, lng: 21.4800709 },
    { lat: 49.3780173, lng: 21.480767 },
    { lat: 49.3768604, lng: 21.4807969 },
    { lat: 49.3768001, lng: 21.4810435 },
    { lat: 49.3746695, lng: 21.4817541 },
    { lat: 49.3737086, lng: 21.4819513 },
    { lat: 49.3719628, lng: 21.4838025 },
    { lat: 49.37144, lng: 21.485432 },
    { lat: 49.3695623, lng: 21.4871242 },
    { lat: 49.3701053, lng: 21.4875821 },
    { lat: 49.3702677, lng: 21.4876406 },
    { lat: 49.3705589, lng: 21.4892463 },
    { lat: 49.3699239, lng: 21.4903005 },
    { lat: 49.3703653, lng: 21.4921319 },
    { lat: 49.3715185, lng: 21.494584 },
    { lat: 49.3722899, lng: 21.4969834 },
    { lat: 49.3735633, lng: 21.4995992 },
    { lat: 49.3774506, lng: 21.5002572 },
    { lat: 49.3819067, lng: 21.501288 },
    { lat: 49.3826647, lng: 21.5025569 },
    { lat: 49.3839574, lng: 21.5037094 },
    { lat: 49.3861813, lng: 21.5047732 },
    { lat: 49.3869533, lng: 21.504624 },
    { lat: 49.3883763, lng: 21.5046304 },
    { lat: 49.3887789, lng: 21.5047766 },
    { lat: 49.3906387, lng: 21.5049085 },
    { lat: 49.3918016, lng: 21.50427 },
    { lat: 49.3932468, lng: 21.5040918 },
    { lat: 49.3944479, lng: 21.5042015 },
    { lat: 49.3954004, lng: 21.5041482 },
    { lat: 49.3973438, lng: 21.5041767 },
    { lat: 49.3977972, lng: 21.5028257 },
    { lat: 49.3987004, lng: 21.5019879 },
    { lat: 49.399871, lng: 21.5019291 },
    { lat: 49.4011578, lng: 21.5014974 },
    { lat: 49.4025973, lng: 21.5005631 },
    { lat: 49.4045746, lng: 21.4988914 },
    { lat: 49.4062707, lng: 21.4957876 },
    { lat: 49.4067618, lng: 21.4952524 },
    { lat: 49.4088154, lng: 21.4961434 },
    { lat: 49.4110233, lng: 21.4967281 },
    { lat: 49.4117822, lng: 21.4959245 },
    { lat: 49.4133173, lng: 21.4971296 },
    { lat: 49.413519, lng: 21.4971855 },
    { lat: 49.4141116, lng: 21.4976507 },
    { lat: 49.4151379, lng: 21.4987767 },
    { lat: 49.4160046, lng: 21.4989892 },
    { lat: 49.4175545, lng: 21.4989046 },
    { lat: 49.4180621, lng: 21.4987927 },
    { lat: 49.4194016, lng: 21.5005245 },
    { lat: 49.4214934, lng: 21.4995569 },
    { lat: 49.42352, lng: 21.4980308 },
    { lat: 49.4228632, lng: 21.4973967 },
    { lat: 49.4229226, lng: 21.4967586 },
    { lat: 49.4223977, lng: 21.495823 },
    { lat: 49.4249172, lng: 21.4948189 },
    { lat: 49.4261931, lng: 21.4931639 },
    { lat: 49.4265591, lng: 21.4943309 },
    { lat: 49.4273078, lng: 21.4935596 },
    { lat: 49.4279372, lng: 21.4925092 },
    { lat: 49.4283023, lng: 21.491461 },
    { lat: 49.429237, lng: 21.49046 },
    { lat: 49.4291131, lng: 21.4896426 },
    { lat: 49.4290792, lng: 21.4888535 },
    { lat: 49.4284635, lng: 21.4872739 },
    { lat: 49.427451, lng: 21.4862591 },
    { lat: 49.4273731, lng: 21.4860608 },
    { lat: 49.4262953, lng: 21.4854025 },
    { lat: 49.4252267, lng: 21.4851208 },
    { lat: 49.4248258, lng: 21.4849296 },
    { lat: 49.4247771, lng: 21.4848998 },
    { lat: 49.4244053, lng: 21.4846729 },
    { lat: 49.4242622, lng: 21.4845984 },
    { lat: 49.4236432, lng: 21.4842079 },
    { lat: 49.422429, lng: 21.4837538 },
    { lat: 49.4218885, lng: 21.4834693 },
    { lat: 49.4214675, lng: 21.4829341 },
    { lat: 49.421451, lng: 21.482916 },
    { lat: 49.4211939, lng: 21.4826542 },
  ],
  Pstriná: [
    { lat: 49.324629, lng: 21.750299 },
    { lat: 49.3246776, lng: 21.750077 },
    { lat: 49.3244074, lng: 21.7493821 },
    { lat: 49.324234, lng: 21.7492515 },
    { lat: 49.324184, lng: 21.7490191 },
    { lat: 49.3241686, lng: 21.7483883 },
    { lat: 49.3235626, lng: 21.746266 },
    { lat: 49.3232594, lng: 21.7455999 },
    { lat: 49.3227168, lng: 21.7440883 },
    { lat: 49.3222936, lng: 21.7431786 },
    { lat: 49.3220498, lng: 21.7428562 },
    { lat: 49.3214879, lng: 21.7408619 },
    { lat: 49.321051, lng: 21.7371879 },
    { lat: 49.318927, lng: 21.726253 },
    { lat: 49.31885, lng: 21.726237 },
    { lat: 49.31782, lng: 21.726748 },
    { lat: 49.317617, lng: 21.727235 },
    { lat: 49.316939, lng: 21.727277 },
    { lat: 49.316353, lng: 21.728078 },
    { lat: 49.315534, lng: 21.728438 },
    { lat: 49.315461, lng: 21.728324 },
    { lat: 49.314456, lng: 21.729311 },
    { lat: 49.313557, lng: 21.729896 },
    { lat: 49.312915, lng: 21.730438 },
    { lat: 49.312677, lng: 21.730824 },
    { lat: 49.312357, lng: 21.731291 },
    { lat: 49.310489, lng: 21.731779 },
    { lat: 49.309851, lng: 21.732176 },
    { lat: 49.308514, lng: 21.732458 },
    { lat: 49.308235, lng: 21.732985 },
    { lat: 49.308007, lng: 21.733377 },
    { lat: 49.30692, lng: 21.733961 },
    { lat: 49.306562, lng: 21.734203 },
    { lat: 49.305389, lng: 21.735087 },
    { lat: 49.304326, lng: 21.736426 },
    { lat: 49.301554, lng: 21.738627 },
    { lat: 49.299934, lng: 21.740653 },
    { lat: 49.29871, lng: 21.740977 },
    { lat: 49.298036, lng: 21.741103 },
    { lat: 49.297505, lng: 21.741202 },
    { lat: 49.297422, lng: 21.741207 },
    { lat: 49.296854, lng: 21.741242 },
    { lat: 49.296531, lng: 21.741297 },
    { lat: 49.296064, lng: 21.741498 },
    { lat: 49.295815, lng: 21.741451 },
    { lat: 49.295705, lng: 21.741398 },
    { lat: 49.29402, lng: 21.740592 },
    { lat: 49.293032, lng: 21.739642 },
    { lat: 49.293369, lng: 21.743707 },
    { lat: 49.293434, lng: 21.744803 },
    { lat: 49.29363, lng: 21.745947 },
    { lat: 49.29341, lng: 21.748461 },
    { lat: 49.293314, lng: 21.749539 },
    { lat: 49.293289, lng: 21.749839 },
    { lat: 49.29378, lng: 21.750569 },
    { lat: 49.29395, lng: 21.75282 },
    { lat: 49.293779, lng: 21.754276 },
    { lat: 49.293233, lng: 21.758941 },
    { lat: 49.293538, lng: 21.759403 },
    { lat: 49.293566, lng: 21.759537 },
    { lat: 49.293582, lng: 21.759608 },
    { lat: 49.293597, lng: 21.75968 },
    { lat: 49.293633, lng: 21.759902 },
    { lat: 49.293904, lng: 21.760445 },
    { lat: 49.293585, lng: 21.764957 },
    { lat: 49.293441, lng: 21.765589 },
    { lat: 49.293834, lng: 21.76579 },
    { lat: 49.294035, lng: 21.76598 },
    { lat: 49.294492, lng: 21.766206 },
    { lat: 49.295461, lng: 21.766337 },
    { lat: 49.295968, lng: 21.766446 },
    { lat: 49.296178, lng: 21.766455 },
    { lat: 49.296372, lng: 21.766461 },
    { lat: 49.296531, lng: 21.76644 },
    { lat: 49.297017, lng: 21.766534 },
    { lat: 49.297541, lng: 21.76655 },
    { lat: 49.298129, lng: 21.766393 },
    { lat: 49.298224, lng: 21.766275 },
    { lat: 49.298352, lng: 21.766336 },
    { lat: 49.298482, lng: 21.766305 },
    { lat: 49.298651, lng: 21.766195 },
    { lat: 49.299425, lng: 21.765942 },
    { lat: 49.299705, lng: 21.765798 },
    { lat: 49.299881, lng: 21.765642 },
    { lat: 49.300095, lng: 21.765691 },
    { lat: 49.300931, lng: 21.765702 },
    { lat: 49.301788, lng: 21.765888 },
    { lat: 49.302192, lng: 21.765751 },
    { lat: 49.302544, lng: 21.765502 },
    { lat: 49.302852, lng: 21.765431 },
    { lat: 49.303249, lng: 21.765139 },
    { lat: 49.303557, lng: 21.76483 },
    { lat: 49.303751, lng: 21.764684 },
    { lat: 49.30421, lng: 21.764505 },
    { lat: 49.304724, lng: 21.764428 },
    { lat: 49.304954, lng: 21.764348 },
    { lat: 49.304947, lng: 21.764372 },
    { lat: 49.305305, lng: 21.764207 },
    { lat: 49.305644, lng: 21.764161 },
    { lat: 49.305992, lng: 21.763994 },
    { lat: 49.306509, lng: 21.763896 },
    { lat: 49.306823, lng: 21.763755 },
    { lat: 49.30704, lng: 21.763696 },
    { lat: 49.307193, lng: 21.763668 },
    { lat: 49.307413, lng: 21.763608 },
    { lat: 49.307716, lng: 21.76346 },
    { lat: 49.307917, lng: 21.763336 },
    { lat: 49.308271, lng: 21.762985 },
    { lat: 49.30868, lng: 21.762956 },
    { lat: 49.30932, lng: 21.76275 },
    { lat: 49.309804, lng: 21.762431 },
    { lat: 49.310088, lng: 21.761891 },
    { lat: 49.310634, lng: 21.761142 },
    { lat: 49.310676, lng: 21.761094 },
    { lat: 49.310875, lng: 21.760486 },
    { lat: 49.31192, lng: 21.759048 },
    { lat: 49.312137, lng: 21.758763 },
    { lat: 49.312504, lng: 21.758111 },
    { lat: 49.312786, lng: 21.757915 },
    { lat: 49.313337, lng: 21.757775 },
    { lat: 49.31363, lng: 21.757644 },
    { lat: 49.313928, lng: 21.757806 },
    { lat: 49.314154, lng: 21.757709 },
    { lat: 49.314338, lng: 21.757386 },
    { lat: 49.314587, lng: 21.757341 },
    { lat: 49.314745, lng: 21.757077 },
    { lat: 49.315001, lng: 21.7567 },
    { lat: 49.315372, lng: 21.756548 },
    { lat: 49.315942, lng: 21.756189 },
    { lat: 49.316673, lng: 21.755799 },
    { lat: 49.317123, lng: 21.755495 },
    { lat: 49.317333, lng: 21.755289 },
    { lat: 49.317657, lng: 21.755144 },
    { lat: 49.318022, lng: 21.754804 },
    { lat: 49.31827, lng: 21.754625 },
    { lat: 49.318481, lng: 21.754541 },
    { lat: 49.318759, lng: 21.754334 },
    { lat: 49.319297, lng: 21.753859 },
    { lat: 49.319681, lng: 21.753538 },
    { lat: 49.320409, lng: 21.752853 },
    { lat: 49.320939, lng: 21.752274 },
    { lat: 49.321693, lng: 21.752021 },
    { lat: 49.32229, lng: 21.751909 },
    { lat: 49.322494, lng: 21.751815 },
    { lat: 49.322726, lng: 21.751694 },
    { lat: 49.32294, lng: 21.751568 },
    { lat: 49.323435, lng: 21.751166 },
    { lat: 49.323549, lng: 21.751032 },
    { lat: 49.323981, lng: 21.750804 },
    { lat: 49.324495, lng: 21.750495 },
    { lat: 49.324629, lng: 21.750299 },
  ],
  Mičakovce: [
    { lat: 49.1063949, lng: 21.5307761 },
    { lat: 49.1062785, lng: 21.5307216 },
    { lat: 49.1062497, lng: 21.5304962 },
    { lat: 49.1060284, lng: 21.5303071 },
    { lat: 49.1060087, lng: 21.5300495 },
    { lat: 49.1058232, lng: 21.529262 },
    { lat: 49.1058182, lng: 21.5289262 },
    { lat: 49.1057299, lng: 21.5287877 },
    { lat: 49.1057376, lng: 21.5285455 },
    { lat: 49.1056401, lng: 21.5283566 },
    { lat: 49.105621, lng: 21.5280404 },
    { lat: 49.1052639, lng: 21.526829 },
    { lat: 49.1046969, lng: 21.5262372 },
    { lat: 49.1043697, lng: 21.5260475 },
    { lat: 49.1041183, lng: 21.5256691 },
    { lat: 49.1036859, lng: 21.5254192 },
    { lat: 49.1033036, lng: 21.5253061 },
    { lat: 49.1029705, lng: 21.5252645 },
    { lat: 49.1027512, lng: 21.5254649 },
    { lat: 49.1027126, lng: 21.5252976 },
    { lat: 49.1024903, lng: 21.5254655 },
    { lat: 49.1022513, lng: 21.5252507 },
    { lat: 49.1017005, lng: 21.5259483 },
    { lat: 49.1018233, lng: 21.5260724 },
    { lat: 49.1018289, lng: 21.5263535 },
    { lat: 49.1014975, lng: 21.52672 },
    { lat: 49.1014842, lng: 21.5272515 },
    { lat: 49.1010147, lng: 21.5274698 },
    { lat: 49.1007544, lng: 21.5270607 },
    { lat: 49.1006976, lng: 21.5268454 },
    { lat: 49.1005297, lng: 21.5267961 },
    { lat: 49.1003153, lng: 21.5266084 },
    { lat: 49.0996736, lng: 21.5271497 },
    { lat: 49.0993155, lng: 21.5280912 },
    { lat: 49.0992048, lng: 21.5279186 },
    { lat: 49.0988234, lng: 21.5268494 },
    { lat: 49.0991328, lng: 21.526168 },
    { lat: 49.0992821, lng: 21.5255658 },
    { lat: 49.0992435, lng: 21.5251932 },
    { lat: 49.0986379, lng: 21.5242737 },
    { lat: 49.098193, lng: 21.5240218 },
    { lat: 49.0975447, lng: 21.5233335 },
    { lat: 49.0974698, lng: 21.5231097 },
    { lat: 49.097468, lng: 21.5223738 },
    { lat: 49.0973683, lng: 21.5219179 },
    { lat: 49.0971381, lng: 21.5218502 },
    { lat: 49.096867, lng: 21.5218903 },
    { lat: 49.0967857, lng: 21.5216429 },
    { lat: 49.0966203, lng: 21.5218444 },
    { lat: 49.0955246, lng: 21.5220816 },
    { lat: 49.0952906, lng: 21.522045 },
    { lat: 49.0951953, lng: 21.5215016 },
    { lat: 49.0950647, lng: 21.5215364 },
    { lat: 49.0948116, lng: 21.5219376 },
    { lat: 49.0942902, lng: 21.521718 },
    { lat: 49.0942916, lng: 21.521411 },
    { lat: 49.0938445, lng: 21.5209572 },
    { lat: 49.0936523, lng: 21.5206482 },
    { lat: 49.09272, lng: 21.5197557 },
    { lat: 49.0924797, lng: 21.5193702 },
    { lat: 49.092061, lng: 21.51913 },
    { lat: 49.0917509, lng: 21.5183901 },
    { lat: 49.0910563, lng: 21.5190109 },
    { lat: 49.0908555, lng: 21.5193849 },
    { lat: 49.0908707, lng: 21.519605 },
    { lat: 49.0905407, lng: 21.5197072 },
    { lat: 49.0902632, lng: 21.5197244 },
    { lat: 49.0899405, lng: 21.5195773 },
    { lat: 49.0896666, lng: 21.5189653 },
    { lat: 49.0894386, lng: 21.5188536 },
    { lat: 49.0886707, lng: 21.5192351 },
    { lat: 49.08829, lng: 21.5192943 },
    { lat: 49.0881988, lng: 21.5193721 },
    { lat: 49.088009, lng: 21.5192622 },
    { lat: 49.0877194, lng: 21.5192098 },
    { lat: 49.0874527, lng: 21.5189689 },
    { lat: 49.0873029, lng: 21.5190064 },
    { lat: 49.0871987, lng: 21.519098 },
    { lat: 49.0870653, lng: 21.5194317 },
    { lat: 49.0867934, lng: 21.5202737 },
    { lat: 49.086767, lng: 21.5204994 },
    { lat: 49.0862696, lng: 21.5195038 },
    { lat: 49.0853726, lng: 21.5196881 },
    { lat: 49.0847894, lng: 21.5196737 },
    { lat: 49.0843407, lng: 21.5198824 },
    { lat: 49.0837203, lng: 21.5200173 },
    { lat: 49.0833875, lng: 21.5194742 },
    { lat: 49.0833626, lng: 21.5190376 },
    { lat: 49.0827875, lng: 21.5185953 },
    { lat: 49.0809189, lng: 21.5189082 },
    { lat: 49.0804796, lng: 21.519413 },
    { lat: 49.0794499, lng: 21.5209451 },
    { lat: 49.07857, lng: 21.5225681 },
    { lat: 49.0781865, lng: 21.5231697 },
    { lat: 49.0779848, lng: 21.5232744 },
    { lat: 49.0772744, lng: 21.5232978 },
    { lat: 49.0766021, lng: 21.5234303 },
    { lat: 49.0763638, lng: 21.5231589 },
    { lat: 49.076292, lng: 21.5228553 },
    { lat: 49.0751848, lng: 21.5230103 },
    { lat: 49.0714804, lng: 21.5227906 },
    { lat: 49.0707931, lng: 21.5224307 },
    { lat: 49.069671, lng: 21.521321 },
    { lat: 49.068391, lng: 21.519931 },
    { lat: 49.067349, lng: 21.519598 },
    { lat: 49.066732, lng: 21.519494 },
    { lat: 49.06648, lng: 21.519532 },
    { lat: 49.066209, lng: 21.519642 },
    { lat: 49.065891, lng: 21.519843 },
    { lat: 49.065675, lng: 21.520025 },
    { lat: 49.065284, lng: 21.520463 },
    { lat: 49.06486, lng: 21.520972 },
    { lat: 49.064214, lng: 21.522172 },
    { lat: 49.064343, lng: 21.522323 },
    { lat: 49.064412, lng: 21.522411 },
    { lat: 49.065448, lng: 21.523746 },
    { lat: 49.065472, lng: 21.523777 },
    { lat: 49.065867, lng: 21.522682 },
    { lat: 49.066284, lng: 21.522984 },
    { lat: 49.066159, lng: 21.523324 },
    { lat: 49.065605, lng: 21.524485 },
    { lat: 49.065753, lng: 21.524616 },
    { lat: 49.065897, lng: 21.524672 },
    { lat: 49.066028, lng: 21.524684 },
    { lat: 49.06622, lng: 21.524659 },
    { lat: 49.066428, lng: 21.524671 },
    { lat: 49.066564, lng: 21.524727 },
    { lat: 49.06663, lng: 21.5248 },
    { lat: 49.066782, lng: 21.524967 },
    { lat: 49.067125, lng: 21.525237 },
    { lat: 49.067219, lng: 21.525295 },
    { lat: 49.067426, lng: 21.525447 },
    { lat: 49.067635, lng: 21.525879 },
    { lat: 49.067688, lng: 21.526273 },
    { lat: 49.068092, lng: 21.526955 },
    { lat: 49.068177, lng: 21.527175 },
    { lat: 49.068215, lng: 21.527431 },
    { lat: 49.068412, lng: 21.528296 },
    { lat: 49.068425, lng: 21.528629 },
    { lat: 49.068445, lng: 21.528967 },
    { lat: 49.068482, lng: 21.529199 },
    { lat: 49.068494, lng: 21.529624 },
    { lat: 49.068334, lng: 21.530291 },
    { lat: 49.067973, lng: 21.530789 },
    { lat: 49.067664, lng: 21.531688 },
    { lat: 49.067672, lng: 21.531911 },
    { lat: 49.067677, lng: 21.532076 },
    { lat: 49.067663, lng: 21.532547 },
    { lat: 49.067679, lng: 21.532738 },
    { lat: 49.067907, lng: 21.533577 },
    { lat: 49.06799, lng: 21.534189 },
    { lat: 49.068104, lng: 21.534892 },
    { lat: 49.068233, lng: 21.535322 },
    { lat: 49.068355, lng: 21.535675 },
    { lat: 49.068651, lng: 21.536289 },
    { lat: 49.068879, lng: 21.536781 },
    { lat: 49.068837, lng: 21.536849 },
    { lat: 49.069069, lng: 21.537232 },
    { lat: 49.069269, lng: 21.537641 },
    { lat: 49.069366, lng: 21.537905 },
    { lat: 49.069585, lng: 21.538505 },
    { lat: 49.069662, lng: 21.538977 },
    { lat: 49.06967, lng: 21.539435 },
    { lat: 49.069763, lng: 21.539425 },
    { lat: 49.069953, lng: 21.54127 },
    { lat: 49.070204, lng: 21.541361 },
    { lat: 49.070443, lng: 21.54138 },
    { lat: 49.070645, lng: 21.541432 },
    { lat: 49.070961, lng: 21.541649 },
    { lat: 49.071112, lng: 21.5418 },
    { lat: 49.071515, lng: 21.542084 },
    { lat: 49.071804, lng: 21.542265 },
    { lat: 49.072062, lng: 21.542502 },
    { lat: 49.072239, lng: 21.542597 },
    { lat: 49.072784, lng: 21.542892 },
    { lat: 49.072748, lng: 21.543477 },
    { lat: 49.072867, lng: 21.543551 },
    { lat: 49.072967, lng: 21.543524 },
    { lat: 49.07312, lng: 21.543606 },
    { lat: 49.073152, lng: 21.543765 },
    { lat: 49.073262, lng: 21.54391 },
    { lat: 49.073361, lng: 21.544256 },
    { lat: 49.073407, lng: 21.544838 },
    { lat: 49.073755, lng: 21.545014 },
    { lat: 49.073675, lng: 21.545694 },
    { lat: 49.074009, lng: 21.54587 },
    { lat: 49.074334, lng: 21.545957 },
    { lat: 49.07451, lng: 21.54647 },
    { lat: 49.074622, lng: 21.547003 },
    { lat: 49.074798, lng: 21.547398 },
    { lat: 49.074995, lng: 21.547553 },
    { lat: 49.075016, lng: 21.547572 },
    { lat: 49.0751101, lng: 21.5472197 },
    { lat: 49.0754421, lng: 21.5466644 },
    { lat: 49.0759003, lng: 21.5461974 },
    { lat: 49.0763233, lng: 21.5454729 },
    { lat: 49.0773975, lng: 21.544274 },
    { lat: 49.0775607, lng: 21.5440739 },
    { lat: 49.0777405, lng: 21.5437104 },
    { lat: 49.077932, lng: 21.5435536 },
    { lat: 49.0787161, lng: 21.5418422 },
    { lat: 49.0789686, lng: 21.5402642 },
    { lat: 49.0793628, lng: 21.5400812 },
    { lat: 49.0796414, lng: 21.5398688 },
    { lat: 49.0799134, lng: 21.5394164 },
    { lat: 49.0801434, lng: 21.5387621 },
    { lat: 49.0802645, lng: 21.538541 },
    { lat: 49.0802736, lng: 21.5380301 },
    { lat: 49.0805906, lng: 21.5366225 },
    { lat: 49.0807358, lng: 21.5361486 },
    { lat: 49.0807342, lng: 21.5357313 },
    { lat: 49.0804878, lng: 21.5350566 },
    { lat: 49.080354, lng: 21.534859 },
    { lat: 49.0804894, lng: 21.5346366 },
    { lat: 49.081061, lng: 21.5341659 },
    { lat: 49.081607, lng: 21.5341732 },
    { lat: 49.0825745, lng: 21.5336974 },
    { lat: 49.0829134, lng: 21.533743 },
    { lat: 49.0836967, lng: 21.533472 },
    { lat: 49.0837851, lng: 21.5332652 },
    { lat: 49.0845897, lng: 21.5326705 },
    { lat: 49.0846139, lng: 21.5321341 },
    { lat: 49.0843057, lng: 21.5312584 },
    { lat: 49.0847107, lng: 21.5306647 },
    { lat: 49.0848991, lng: 21.5307238 },
    { lat: 49.0850273, lng: 21.5308399 },
    { lat: 49.0853691, lng: 21.5303541 },
    { lat: 49.0854515, lng: 21.530086 },
    { lat: 49.0864016, lng: 21.5308582 },
    { lat: 49.0869514, lng: 21.5311678 },
    { lat: 49.0876376, lng: 21.5308503 },
    { lat: 49.088058, lng: 21.5309487 },
    { lat: 49.0888123, lng: 21.5318458 },
    { lat: 49.0891602, lng: 21.5325254 },
    { lat: 49.0897078, lng: 21.5323973 },
    { lat: 49.0898003, lng: 21.5327667 },
    { lat: 49.0910018, lng: 21.5322464 },
    { lat: 49.0913057, lng: 21.5322135 },
    { lat: 49.0920685, lng: 21.5327253 },
    { lat: 49.0923465, lng: 21.5330513 },
    { lat: 49.0932679, lng: 21.5334457 },
    { lat: 49.0935853, lng: 21.5336493 },
    { lat: 49.0939288, lng: 21.5337004 },
    { lat: 49.0946857, lng: 21.5336547 },
    { lat: 49.0947494, lng: 21.5335561 },
    { lat: 49.0952962, lng: 21.5336003 },
    { lat: 49.0952933, lng: 21.5338568 },
    { lat: 49.0956354, lng: 21.5338997 },
    { lat: 49.0956354, lng: 21.5340164 },
    { lat: 49.0968764, lng: 21.5343411 },
    { lat: 49.0968656, lng: 21.5346739 },
    { lat: 49.097326, lng: 21.5348404 },
    { lat: 49.0983753, lng: 21.5353806 },
    { lat: 49.0988851, lng: 21.5357852 },
    { lat: 49.0996802, lng: 21.5360569 },
    { lat: 49.1002925, lng: 21.536183 },
    { lat: 49.1010146, lng: 21.5360987 },
    { lat: 49.1013853, lng: 21.5361182 },
    { lat: 49.1019384, lng: 21.5362626 },
    { lat: 49.1025593, lng: 21.5366524 },
    { lat: 49.1029296, lng: 21.5371746 },
    { lat: 49.1033426, lng: 21.5375806 },
    { lat: 49.1035745, lng: 21.53802 },
    { lat: 49.1041655, lng: 21.5381522 },
    { lat: 49.1045971, lng: 21.538101 },
    { lat: 49.1050424, lng: 21.537893 },
    { lat: 49.1052616, lng: 21.5378901 },
    { lat: 49.1053801, lng: 21.5373771 },
    { lat: 49.1055235, lng: 21.5371218 },
    { lat: 49.1058682, lng: 21.5367365 },
    { lat: 49.1059913, lng: 21.5364703 },
    { lat: 49.1060723, lng: 21.5359163 },
    { lat: 49.1063591, lng: 21.5349951 },
    { lat: 49.106383, lng: 21.5347727 },
    { lat: 49.1063361, lng: 21.5341492 },
    { lat: 49.106448, lng: 21.5337616 },
    { lat: 49.1064535, lng: 21.5331634 },
    { lat: 49.106345, lng: 21.532071 },
    { lat: 49.1061857, lng: 21.5317331 },
    { lat: 49.1062786, lng: 21.5315586 },
    { lat: 49.1065086, lng: 21.5314943 },
    { lat: 49.1066009, lng: 21.5313751 },
    { lat: 49.1064012, lng: 21.530799 },
    { lat: 49.1063949, lng: 21.5307761 },
  ],
  Mlynárovce: [
    { lat: 49.2550407, lng: 21.5594437 },
    { lat: 49.2550556, lng: 21.5594524 },
    { lat: 49.2554966, lng: 21.5594179 },
    { lat: 49.2564598, lng: 21.5591054 },
    { lat: 49.2569992, lng: 21.5585567 },
    { lat: 49.2572484, lng: 21.5585742 },
    { lat: 49.2586178, lng: 21.5580292 },
    { lat: 49.2598572, lng: 21.5568113 },
    { lat: 49.2600805, lng: 21.5563068 },
    { lat: 49.2606039, lng: 21.555477 },
    { lat: 49.260979, lng: 21.5542819 },
    { lat: 49.2610125, lng: 21.5533155 },
    { lat: 49.2606323, lng: 21.5522339 },
    { lat: 49.2612887, lng: 21.5519324 },
    { lat: 49.2621855, lng: 21.5506501 },
    { lat: 49.2635523, lng: 21.5492441 },
    { lat: 49.2649627, lng: 21.5488341 },
    { lat: 49.265528, lng: 21.5479776 },
    { lat: 49.2653682, lng: 21.5476909 },
    { lat: 49.2652302, lng: 21.5472248 },
    { lat: 49.2648786, lng: 21.5448355 },
    { lat: 49.2647481, lng: 21.5431103 },
    { lat: 49.2647858, lng: 21.5424159 },
    { lat: 49.2649945, lng: 21.5412369 },
    { lat: 49.2651629, lng: 21.5391863 },
    { lat: 49.2635528, lng: 21.5340601 },
    { lat: 49.2631956, lng: 21.5314826 },
    { lat: 49.2632003, lng: 21.530335 },
    { lat: 49.2628863, lng: 21.5298107 },
    { lat: 49.2624671, lng: 21.5277956 },
    { lat: 49.262483, lng: 21.5270821 },
    { lat: 49.2623809, lng: 21.5262546 },
    { lat: 49.2620499, lng: 21.5253248 },
    { lat: 49.2621765, lng: 21.5240331 },
    { lat: 49.2625246, lng: 21.5219036 },
    { lat: 49.2627383, lng: 21.5198324 },
    { lat: 49.2630914, lng: 21.5190336 },
    { lat: 49.2625645, lng: 21.5164756 },
    { lat: 49.2626458, lng: 21.5157329 },
    { lat: 49.264281, lng: 21.5131746 },
    { lat: 49.2649451, lng: 21.5116793 },
    { lat: 49.2651348, lng: 21.5103576 },
    { lat: 49.2655565, lng: 21.5085264 },
    { lat: 49.2651322, lng: 21.5062196 },
    { lat: 49.2648496, lng: 21.50372 },
    { lat: 49.2643002, lng: 21.5038645 },
    { lat: 49.2640291, lng: 21.5037482 },
    { lat: 49.2638201, lng: 21.5035193 },
    { lat: 49.2642879, lng: 21.502561 },
    { lat: 49.2645057, lng: 21.5015977 },
    { lat: 49.2649354, lng: 21.500486 },
    { lat: 49.265249, lng: 21.499504 },
    { lat: 49.263922, lng: 21.499644 },
    { lat: 49.262917, lng: 21.499748 },
    { lat: 49.262886, lng: 21.499672 },
    { lat: 49.261206, lng: 21.499176 },
    { lat: 49.260385, lng: 21.498993 },
    { lat: 49.259345, lng: 21.498582 },
    { lat: 49.258876, lng: 21.498404 },
    { lat: 49.258181, lng: 21.498094 },
    { lat: 49.257445, lng: 21.49722 },
    { lat: 49.257295, lng: 21.496981 },
    { lat: 49.256866, lng: 21.496795 },
    { lat: 49.256149, lng: 21.496441 },
    { lat: 49.255929, lng: 21.496464 },
    { lat: 49.255567, lng: 21.496815 },
    { lat: 49.255074, lng: 21.497401 },
    { lat: 49.25485, lng: 21.497093 },
    { lat: 49.254212, lng: 21.496184 },
    { lat: 49.253645, lng: 21.495401 },
    { lat: 49.253452, lng: 21.49513 },
    { lat: 49.253841, lng: 21.494418 },
    { lat: 49.253311, lng: 21.493808 },
    { lat: 49.253294, lng: 21.49379 },
    { lat: 49.252494, lng: 21.492871 },
    { lat: 49.252454, lng: 21.492828 },
    { lat: 49.252209, lng: 21.492948 },
    { lat: 49.252014, lng: 21.492819 },
    { lat: 49.251615, lng: 21.493044 },
    { lat: 49.251473, lng: 21.493048 },
    { lat: 49.251367, lng: 21.493112 },
    { lat: 49.251209, lng: 21.493057 },
    { lat: 49.250635, lng: 21.492438 },
    { lat: 49.250154, lng: 21.491652 },
    { lat: 49.249884, lng: 21.491097 },
    { lat: 49.249554, lng: 21.490424 },
    { lat: 49.249317, lng: 21.489995 },
    { lat: 49.249011, lng: 21.491354 },
    { lat: 49.248354, lng: 21.492844 },
    { lat: 49.247634, lng: 21.493848 },
    { lat: 49.247164, lng: 21.494727 },
    { lat: 49.24667, lng: 21.495413 },
    { lat: 49.245821, lng: 21.496086 },
    { lat: 49.245473, lng: 21.496415 },
    { lat: 49.244883, lng: 21.496992 },
    { lat: 49.244876, lng: 21.498055 },
    { lat: 49.244684, lng: 21.499166 },
    { lat: 49.244602, lng: 21.499562 },
    { lat: 49.244543, lng: 21.499877 },
    { lat: 49.244128, lng: 21.500522 },
    { lat: 49.244047, lng: 21.50048 },
    { lat: 49.243633, lng: 21.500884 },
    { lat: 49.243212, lng: 21.501585 },
    { lat: 49.242627, lng: 21.501787 },
    { lat: 49.242314, lng: 21.502163 },
    { lat: 49.241752, lng: 21.502028 },
    { lat: 49.240743, lng: 21.502584 },
    { lat: 49.240474, lng: 21.502756 },
    { lat: 49.239885, lng: 21.503483 },
    { lat: 49.23919, lng: 21.504732 },
    { lat: 49.239091, lng: 21.505251 },
    { lat: 49.238796, lng: 21.505632 },
    { lat: 49.238604, lng: 21.506015 },
    { lat: 49.237721, lng: 21.506687 },
    { lat: 49.237198, lng: 21.507245 },
    { lat: 49.236853, lng: 21.507735 },
    { lat: 49.236324, lng: 21.508628 },
    { lat: 49.235226, lng: 21.509685 },
    { lat: 49.235006, lng: 21.509889 },
    { lat: 49.235245, lng: 21.510371 },
    { lat: 49.2370783, lng: 21.5141196 },
    { lat: 49.2368813, lng: 21.5145463 },
    { lat: 49.2369716, lng: 21.5147624 },
    { lat: 49.2368892, lng: 21.5148651 },
    { lat: 49.2368463, lng: 21.5151405 },
    { lat: 49.2366916, lng: 21.5154815 },
    { lat: 49.2367895, lng: 21.5157562 },
    { lat: 49.2366016, lng: 21.5163155 },
    { lat: 49.2367987, lng: 21.5185022 },
    { lat: 49.2376741, lng: 21.522086 },
    { lat: 49.2367506, lng: 21.5226074 },
    { lat: 49.2371815, lng: 21.5237984 },
    { lat: 49.2374572, lng: 21.5261549 },
    { lat: 49.2388431, lng: 21.532166 },
    { lat: 49.239104, lng: 21.5320391 },
    { lat: 49.2390585, lng: 21.5325796 },
    { lat: 49.2396342, lng: 21.534487 },
    { lat: 49.2394157, lng: 21.5357525 },
    { lat: 49.2395031, lng: 21.536259 },
    { lat: 49.2397527, lng: 21.5371373 },
    { lat: 49.2407526, lng: 21.539328 },
    { lat: 49.2409044, lng: 21.5393602 },
    { lat: 49.2417014, lng: 21.5412586 },
    { lat: 49.242289, lng: 21.5424313 },
    { lat: 49.2430526, lng: 21.5443021 },
    { lat: 49.2431602, lng: 21.5448492 },
    { lat: 49.2432953, lng: 21.5450538 },
    { lat: 49.2435693, lng: 21.5451133 },
    { lat: 49.24391, lng: 21.5464336 },
    { lat: 49.2440678, lng: 21.5474858 },
    { lat: 49.2444549, lng: 21.5478904 },
    { lat: 49.2447361, lng: 21.5488146 },
    { lat: 49.2454154, lng: 21.5518927 },
    { lat: 49.2461654, lng: 21.5516384 },
    { lat: 49.2465985, lng: 21.5531199 },
    { lat: 49.2487076, lng: 21.5511502 },
    { lat: 49.2497524, lng: 21.5510588 },
    { lat: 49.2512597, lng: 21.5516534 },
    { lat: 49.2519651, lng: 21.5516134 },
    { lat: 49.2527273, lng: 21.5520796 },
    { lat: 49.2532661, lng: 21.5540024 },
    { lat: 49.2539644, lng: 21.555999 },
    { lat: 49.2550108, lng: 21.5582143 },
    { lat: 49.2550407, lng: 21.5594437 },
  ],
  Ladomirová: [
    { lat: 49.3251636, lng: 21.6575307 },
    { lat: 49.3251786, lng: 21.6574995 },
    { lat: 49.325513, lng: 21.6583416 },
    { lat: 49.3253479, lng: 21.6585641 },
    { lat: 49.3253461, lng: 21.6587085 },
    { lat: 49.3254433, lng: 21.6587647 },
    { lat: 49.325422, lng: 21.6589338 },
    { lat: 49.3255167, lng: 21.6590186 },
    { lat: 49.3256158, lng: 21.6593611 },
    { lat: 49.3259496, lng: 21.6598852 },
    { lat: 49.325845, lng: 21.6602178 },
    { lat: 49.325864, lng: 21.6605473 },
    { lat: 49.3259211, lng: 21.6608268 },
    { lat: 49.3260794, lng: 21.6610234 },
    { lat: 49.3261969, lng: 21.6613766 },
    { lat: 49.3262966, lng: 21.6614886 },
    { lat: 49.3264233, lng: 21.6614333 },
    { lat: 49.3267487, lng: 21.6615674 },
    { lat: 49.3269823, lng: 21.6622641 },
    { lat: 49.3271887, lng: 21.662365 },
    { lat: 49.3273093, lng: 21.6627465 },
    { lat: 49.3275859, lng: 21.6627002 },
    { lat: 49.3277625, lng: 21.6631643 },
    { lat: 49.3283522, lng: 21.6636315 },
    { lat: 49.3287456, lng: 21.6636557 },
    { lat: 49.329035, lng: 21.6638386 },
    { lat: 49.3294621, lng: 21.6642805 },
    { lat: 49.3294964, lng: 21.6645134 },
    { lat: 49.3298943, lng: 21.6647043 },
    { lat: 49.330035, lng: 21.6648811 },
    { lat: 49.3302056, lng: 21.6648588 },
    { lat: 49.3303361, lng: 21.6651109 },
    { lat: 49.3305423, lng: 21.6650395 },
    { lat: 49.3307724, lng: 21.665651 },
    { lat: 49.3309143, lng: 21.6656168 },
    { lat: 49.3310126, lng: 21.6657545 },
    { lat: 49.3311774, lng: 21.6665675 },
    { lat: 49.33167, lng: 21.6667675 },
    { lat: 49.3316958, lng: 21.6670158 },
    { lat: 49.3318612, lng: 21.6674775 },
    { lat: 49.3318984, lng: 21.668191 },
    { lat: 49.3321379, lng: 21.6699403 },
    { lat: 49.33228, lng: 21.6705322 },
    { lat: 49.3323961, lng: 21.6706226 },
    { lat: 49.3324192, lng: 21.6711907 },
    { lat: 49.3322271, lng: 21.6726624 },
    { lat: 49.3323705, lng: 21.6727273 },
    { lat: 49.332502, lng: 21.6730126 },
    { lat: 49.3328403, lng: 21.6732967 },
    { lat: 49.3328296, lng: 21.6742705 },
    { lat: 49.3329904, lng: 21.6746059 },
    { lat: 49.3335842, lng: 21.6753515 },
    { lat: 49.3336227, lng: 21.6754847 },
    { lat: 49.3342477, lng: 21.6754719 },
    { lat: 49.3345956, lng: 21.6759889 },
    { lat: 49.3347933, lng: 21.6760561 },
    { lat: 49.3349433, lng: 21.6760326 },
    { lat: 49.335578, lng: 21.6742883 },
    { lat: 49.3357801, lng: 21.6740202 },
    { lat: 49.3359356, lng: 21.6739047 },
    { lat: 49.3359946, lng: 21.6737152 },
    { lat: 49.3362148, lng: 21.6733735 },
    { lat: 49.3366763, lng: 21.6728848 },
    { lat: 49.3368419, lng: 21.6728527 },
    { lat: 49.3372132, lng: 21.6725678 },
    { lat: 49.337409, lng: 21.6720219 },
    { lat: 49.338226, lng: 21.6710322 },
    { lat: 49.3384001, lng: 21.670733 },
    { lat: 49.3386447, lng: 21.6705458 },
    { lat: 49.3396786, lng: 21.6694207 },
    { lat: 49.3415194, lng: 21.6668146 },
    { lat: 49.3419619, lng: 21.6653325 },
    { lat: 49.34219, lng: 21.6621194 },
    { lat: 49.3423403, lng: 21.6610179 },
    { lat: 49.3420913, lng: 21.6605874 },
    { lat: 49.3412852, lng: 21.6581512 },
    { lat: 49.3411852, lng: 21.6576601 },
    { lat: 49.3412002, lng: 21.6569676 },
    { lat: 49.3400396, lng: 21.6564745 },
    { lat: 49.3408791, lng: 21.6523381 },
    { lat: 49.3419324, lng: 21.6458467 },
    { lat: 49.3423767, lng: 21.6427449 },
    { lat: 49.3437983, lng: 21.6429866 },
    { lat: 49.3441943, lng: 21.6428657 },
    { lat: 49.344391, lng: 21.6425202 },
    { lat: 49.3448816, lng: 21.6421964 },
    { lat: 49.3451241, lng: 21.6423923 },
    { lat: 49.3454543, lng: 21.6428688 },
    { lat: 49.3466259, lng: 21.642635 },
    { lat: 49.3471234, lng: 21.6427379 },
    { lat: 49.3483436, lng: 21.6437119 },
    { lat: 49.3490686, lng: 21.6457215 },
    { lat: 49.3493976, lng: 21.6460751 },
    { lat: 49.3494671, lng: 21.6463192 },
    { lat: 49.3496465, lng: 21.6457969 },
    { lat: 49.3499898, lng: 21.6457661 },
    { lat: 49.350161, lng: 21.6451898 },
    { lat: 49.3514684, lng: 21.6436748 },
    { lat: 49.3517085, lng: 21.6428989 },
    { lat: 49.351936, lng: 21.6417132 },
    { lat: 49.3518529, lng: 21.6397752 },
    { lat: 49.3527822, lng: 21.6379637 },
    { lat: 49.3532448, lng: 21.6372764 },
    { lat: 49.3545268, lng: 21.6365548 },
    { lat: 49.3564267, lng: 21.6358558 },
    { lat: 49.3564644, lng: 21.6350054 },
    { lat: 49.3580096, lng: 21.6329771 },
    { lat: 49.3594841, lng: 21.6323845 },
    { lat: 49.3598988, lng: 21.6309708 },
    { lat: 49.3589476, lng: 21.6307411 },
    { lat: 49.3579593, lng: 21.6290715 },
    { lat: 49.3573635, lng: 21.6269845 },
    { lat: 49.3560987, lng: 21.6263063 },
    { lat: 49.3554451, lng: 21.6258173 },
    { lat: 49.3552551, lng: 21.6252825 },
    { lat: 49.3544762, lng: 21.6244866 },
    { lat: 49.3545086, lng: 21.6232039 },
    { lat: 49.3536508, lng: 21.6202589 },
    { lat: 49.3528312, lng: 21.6185914 },
    { lat: 49.3527895, lng: 21.6185053 },
    { lat: 49.3523724, lng: 21.6193339 },
    { lat: 49.3509856, lng: 21.6191783 },
    { lat: 49.3495819, lng: 21.6191439 },
    { lat: 49.3495584, lng: 21.6187784 },
    { lat: 49.3487499, lng: 21.6186669 },
    { lat: 49.3449589, lng: 21.6209649 },
    { lat: 49.3447933, lng: 21.6209527 },
    { lat: 49.344465, lng: 21.6204662 },
    { lat: 49.3425479, lng: 21.6166819 },
    { lat: 49.3418704, lng: 21.6170829 },
    { lat: 49.3415329, lng: 21.6157618 },
    { lat: 49.3397409, lng: 21.611134 },
    { lat: 49.3391263, lng: 21.6101018 },
    { lat: 49.3385005, lng: 21.6084034 },
    { lat: 49.3376637, lng: 21.6068524 },
    { lat: 49.3373457, lng: 21.6065432 },
    { lat: 49.3359484, lng: 21.6055092 },
    { lat: 49.3345964, lng: 21.603966 },
    { lat: 49.3328985, lng: 21.6022796 },
    { lat: 49.3323627, lng: 21.6020588 },
    { lat: 49.3323219, lng: 21.6013538 },
    { lat: 49.3321813, lng: 21.6011554 },
    { lat: 49.3321548, lng: 21.6009267 },
    { lat: 49.3322984, lng: 21.6004182 },
    { lat: 49.3322506, lng: 21.5998283 },
    { lat: 49.3323891, lng: 21.5996297 },
    { lat: 49.332571, lng: 21.5988825 },
    { lat: 49.3325549, lng: 21.5984601 },
    { lat: 49.3326016, lng: 21.5981198 },
    { lat: 49.3323499, lng: 21.5977195 },
    { lat: 49.3324369, lng: 21.5975346 },
    { lat: 49.332257, lng: 21.5970438 },
    { lat: 49.332237, lng: 21.5966004 },
    { lat: 49.3319259, lng: 21.5965447 },
    { lat: 49.3320427, lng: 21.5963258 },
    { lat: 49.3307934, lng: 21.5958165 },
    { lat: 49.3307009, lng: 21.5957702 },
    { lat: 49.3300203, lng: 21.5956046 },
    { lat: 49.3295182, lng: 21.5956749 },
    { lat: 49.3283343, lng: 21.5952083 },
    { lat: 49.3268884, lng: 21.5948697 },
    { lat: 49.3263556, lng: 21.5946934 },
    { lat: 49.325962, lng: 21.5945632 },
    { lat: 49.3239018, lng: 21.5945016 },
    { lat: 49.3232792, lng: 21.5943551 },
    { lat: 49.3231675, lng: 21.5944698 },
    { lat: 49.322694, lng: 21.5944279 },
    { lat: 49.3223806, lng: 21.5941665 },
    { lat: 49.3219238, lng: 21.5939988 },
    { lat: 49.3217293, lng: 21.5938343 },
    { lat: 49.3214616, lng: 21.5937604 },
    { lat: 49.3210398, lng: 21.5933153 },
    { lat: 49.3192645, lng: 21.5933486 },
    { lat: 49.3190929, lng: 21.5939792 },
    { lat: 49.3184023, lng: 21.5958077 },
    { lat: 49.3186511, lng: 21.5960606 },
    { lat: 49.3186475, lng: 21.5962602 },
    { lat: 49.3182526, lng: 21.5970289 },
    { lat: 49.3183403, lng: 21.5975154 },
    { lat: 49.3181483, lng: 21.5977766 },
    { lat: 49.31728, lng: 21.5998981 },
    { lat: 49.3173543, lng: 21.6003094 },
    { lat: 49.3171296, lng: 21.600974 },
    { lat: 49.3162971, lng: 21.6023979 },
    { lat: 49.3147585, lng: 21.6031054 },
    { lat: 49.3142085, lng: 21.6038399 },
    { lat: 49.3139601, lng: 21.6039713 },
    { lat: 49.3130723, lng: 21.6048571 },
    { lat: 49.312754, lng: 21.605025 },
    { lat: 49.312163, lng: 21.6050491 },
    { lat: 49.3116868, lng: 21.60488 },
    { lat: 49.3113334, lng: 21.6053542 },
    { lat: 49.3106561, lng: 21.6056804 },
    { lat: 49.3097463, lng: 21.6062218 },
    { lat: 49.3094791, lng: 21.6062444 },
    { lat: 49.3090949, lng: 21.6064238 },
    { lat: 49.30898, lng: 21.6066608 },
    { lat: 49.3086587, lng: 21.6069252 },
    { lat: 49.3084401, lng: 21.6069919 },
    { lat: 49.3078285, lng: 21.6074127 },
    { lat: 49.3075534, lng: 21.6075166 },
    { lat: 49.3067124, lng: 21.6080142 },
    { lat: 49.306606, lng: 21.6079943 },
    { lat: 49.305892, lng: 21.6085781 },
    { lat: 49.3058149, lng: 21.6086801 },
    { lat: 49.3055928, lng: 21.6093112 },
    { lat: 49.3060498, lng: 21.60986 },
    { lat: 49.3063827, lng: 21.6099806 },
    { lat: 49.3068728, lng: 21.6106575 },
    { lat: 49.3064705, lng: 21.6108834 },
    { lat: 49.3057172, lng: 21.6116319 },
    { lat: 49.3046242, lng: 21.613284 },
    { lat: 49.3046435, lng: 21.613821 },
    { lat: 49.3055638, lng: 21.612432 },
    { lat: 49.3073752, lng: 21.6116652 },
    { lat: 49.3091938, lng: 21.6112869 },
    { lat: 49.3105376, lng: 21.6111046 },
    { lat: 49.3118879, lng: 21.6113632 },
    { lat: 49.3133068, lng: 21.6123626 },
    { lat: 49.3147492, lng: 21.6135225 },
    { lat: 49.31713, lng: 21.6167318 },
    { lat: 49.3189943, lng: 21.6198257 },
    { lat: 49.3184095, lng: 21.6204451 },
    { lat: 49.3181007, lng: 21.6210823 },
    { lat: 49.3182591, lng: 21.6211925 },
    { lat: 49.3182872, lng: 21.6212733 },
    { lat: 49.3183481, lng: 21.6226785 },
    { lat: 49.3184148, lng: 21.6228506 },
    { lat: 49.318776, lng: 21.624956 },
    { lat: 49.3190301, lng: 21.6267981 },
    { lat: 49.3188855, lng: 21.6271045 },
    { lat: 49.3191727, lng: 21.6277803 },
    { lat: 49.3189892, lng: 21.6281378 },
    { lat: 49.3196025, lng: 21.6292575 },
    { lat: 49.320067, lng: 21.6306179 },
    { lat: 49.3197297, lng: 21.6315319 },
    { lat: 49.3201027, lng: 21.6319235 },
    { lat: 49.3196462, lng: 21.6329204 },
    { lat: 49.3196549, lng: 21.6339396 },
    { lat: 49.3186047, lng: 21.6357667 },
    { lat: 49.3184114, lng: 21.6381267 },
    { lat: 49.3180383, lng: 21.6383099 },
    { lat: 49.318501, lng: 21.640675 },
    { lat: 49.3189489, lng: 21.6422954 },
    { lat: 49.3195865, lng: 21.645681 },
    { lat: 49.3204684, lng: 21.6491555 },
    { lat: 49.3217423, lng: 21.6522827 },
    { lat: 49.3251284, lng: 21.6574767 },
    { lat: 49.3251636, lng: 21.6575307 },
  ],
  Vápeník: [
    { lat: 49.3819938, lng: 21.5444341 },
    { lat: 49.3820449, lng: 21.5444878 },
    { lat: 49.3869929, lng: 21.5496107 },
    { lat: 49.3870897, lng: 21.5498098 },
    { lat: 49.3880527, lng: 21.5506652 },
    { lat: 49.3882957, lng: 21.5507815 },
    { lat: 49.3886975, lng: 21.5511913 },
    { lat: 49.3887374, lng: 21.5513266 },
    { lat: 49.389576, lng: 21.5513922 },
    { lat: 49.3907112, lng: 21.551997 },
    { lat: 49.3911483, lng: 21.5527917 },
    { lat: 49.3930222, lng: 21.5549989 },
    { lat: 49.3951401, lng: 21.5533353 },
    { lat: 49.3958345, lng: 21.5533082 },
    { lat: 49.3962997, lng: 21.553126 },
    { lat: 49.3964393, lng: 21.5533722 },
    { lat: 49.396907, lng: 21.5526405 },
    { lat: 49.3975417, lng: 21.551683 },
    { lat: 49.3986273, lng: 21.5501952 },
    { lat: 49.4000313, lng: 21.5485103 },
    { lat: 49.4031673, lng: 21.5452911 },
    { lat: 49.4043896, lng: 21.5441076 },
    { lat: 49.4053333, lng: 21.5431009 },
    { lat: 49.4065026, lng: 21.5408245 },
    { lat: 49.4065708, lng: 21.5394097 },
    { lat: 49.406467, lng: 21.5381204 },
    { lat: 49.406527, lng: 21.5374511 },
    { lat: 49.4075416, lng: 21.5358428 },
    { lat: 49.407888, lng: 21.5356787 },
    { lat: 49.4081696, lng: 21.5347776 },
    { lat: 49.4079864, lng: 21.5343793 },
    { lat: 49.4080937, lng: 21.5338687 },
    { lat: 49.4081895, lng: 21.5335556 },
    { lat: 49.4085213, lng: 21.5329959 },
    { lat: 49.408402, lng: 21.5322762 },
    { lat: 49.4084787, lng: 21.5315576 },
    { lat: 49.4085257, lng: 21.5307558 },
    { lat: 49.4091707, lng: 21.5276086 },
    { lat: 49.4091913, lng: 21.5275429 },
    { lat: 49.4090256, lng: 21.5275676 },
    { lat: 49.4088318, lng: 21.5276754 },
    { lat: 49.40817, lng: 21.5291815 },
    { lat: 49.4077276, lng: 21.5285384 },
    { lat: 49.4075697, lng: 21.5271552 },
    { lat: 49.4070659, lng: 21.5273236 },
    { lat: 49.406425, lng: 21.5270557 },
    { lat: 49.4059016, lng: 21.5263255 },
    { lat: 49.4057492, lng: 21.5255069 },
    { lat: 49.4055253, lng: 21.5252248 },
    { lat: 49.405034, lng: 21.5253166 },
    { lat: 49.4038652, lng: 21.5253548 },
    { lat: 49.4033369, lng: 21.5255697 },
    { lat: 49.4020397, lng: 21.5248091 },
    { lat: 49.4013198, lng: 21.5238895 },
    { lat: 49.4011631, lng: 21.5241184 },
    { lat: 49.4005492, lng: 21.5239307 },
    { lat: 49.4005489, lng: 21.5239975 },
    { lat: 49.4003238, lng: 21.5240731 },
    { lat: 49.4001349, lng: 21.5240368 },
    { lat: 49.3987946, lng: 21.5244961 },
    { lat: 49.3981715, lng: 21.5249035 },
    { lat: 49.3980252, lng: 21.5251017 },
    { lat: 49.3970578, lng: 21.5260203 },
    { lat: 49.3968571, lng: 21.5263192 },
    { lat: 49.3959568, lng: 21.5272289 },
    { lat: 49.3940777, lng: 21.5289262 },
    { lat: 49.3928624, lng: 21.5304695 },
    { lat: 49.3924132, lng: 21.5311008 },
    { lat: 49.3918945, lng: 21.5319786 },
    { lat: 49.3910718, lng: 21.5329668 },
    { lat: 49.3908079, lng: 21.5335432 },
    { lat: 49.3902367, lng: 21.5342298 },
    { lat: 49.3897164, lng: 21.5346108 },
    { lat: 49.3891722, lng: 21.5352627 },
    { lat: 49.3889512, lng: 21.5358027 },
    { lat: 49.3888099, lng: 21.5359008 },
    { lat: 49.3881217, lng: 21.5360625 },
    { lat: 49.387117, lng: 21.536827 },
    { lat: 49.3861333, lng: 21.5377664 },
    { lat: 49.3855197, lng: 21.5389085 },
    { lat: 49.3843604, lng: 21.5402919 },
    { lat: 49.3834305, lng: 21.5417483 },
    { lat: 49.3819938, lng: 21.5444341 },
  ],
  NižnýKomárnik: [
    { lat: 49.391405, lng: 21.690589 },
    { lat: 49.3911165, lng: 21.69074 },
    { lat: 49.3909504, lng: 21.6904965 },
    { lat: 49.3903971, lng: 21.6907133 },
    { lat: 49.3899069, lng: 21.6904686 },
    { lat: 49.3890745, lng: 21.6909762 },
    { lat: 49.3888187, lng: 21.6917129 },
    { lat: 49.3884291, lng: 21.6913601 },
    { lat: 49.3884486, lng: 21.6912068 },
    { lat: 49.3876888, lng: 21.6905967 },
    { lat: 49.3871822, lng: 21.6903725 },
    { lat: 49.3865865, lng: 21.6902662 },
    { lat: 49.3864064, lng: 21.6906332 },
    { lat: 49.3859296, lng: 21.6905555 },
    { lat: 49.3859159, lng: 21.6904217 },
    { lat: 49.38516, lng: 21.6904663 },
    { lat: 49.3848547, lng: 21.6898132 },
    { lat: 49.3844653, lng: 21.6901048 },
    { lat: 49.3839679, lng: 21.6911827 },
    { lat: 49.383268, lng: 21.6903342 },
    { lat: 49.383154, lng: 21.6894828 },
    { lat: 49.3815931, lng: 21.6873953 },
    { lat: 49.3803482, lng: 21.686354 },
    { lat: 49.3802349, lng: 21.6866214 },
    { lat: 49.3800252, lng: 21.6864732 },
    { lat: 49.3779048, lng: 21.6854655 },
    { lat: 49.3767123, lng: 21.6853311 },
    { lat: 49.3763844, lng: 21.6841341 },
    { lat: 49.3756054, lng: 21.6834854 },
    { lat: 49.374868, lng: 21.6816179 },
    { lat: 49.3746163, lng: 21.6812507 },
    { lat: 49.3744814, lng: 21.6820021 },
    { lat: 49.3742392, lng: 21.6826653 },
    { lat: 49.3739146, lng: 21.6830586 },
    { lat: 49.3736288, lng: 21.6839765 },
    { lat: 49.3734891, lng: 21.6845953 },
    { lat: 49.3728944, lng: 21.6854492 },
    { lat: 49.3725773, lng: 21.6852432 },
    { lat: 49.3723753, lng: 21.6855584 },
    { lat: 49.3719377, lng: 21.6853885 },
    { lat: 49.3710967, lng: 21.6857384 },
    { lat: 49.3709309, lng: 21.68593 },
    { lat: 49.3708578, lng: 21.6861396 },
    { lat: 49.370691, lng: 21.6863793 },
    { lat: 49.3704726, lng: 21.6864548 },
    { lat: 49.3697194, lng: 21.6871439 },
    { lat: 49.3693147, lng: 21.687697 },
    { lat: 49.3687294, lng: 21.6881026 },
    { lat: 49.3684372, lng: 21.6889125 },
    { lat: 49.36822, lng: 21.6891102 },
    { lat: 49.368051, lng: 21.6905725 },
    { lat: 49.3679692, lng: 21.6908346 },
    { lat: 49.3680097, lng: 21.6911771 },
    { lat: 49.3679922, lng: 21.6920747 },
    { lat: 49.3683468, lng: 21.6932226 },
    { lat: 49.3683812, lng: 21.6936633 },
    { lat: 49.3686811, lng: 21.6947843 },
    { lat: 49.3686366, lng: 21.6952613 },
    { lat: 49.3684303, lng: 21.6961769 },
    { lat: 49.3681479, lng: 21.6968912 },
    { lat: 49.3683009, lng: 21.6975641 },
    { lat: 49.3682972, lng: 21.6983071 },
    { lat: 49.3683891, lng: 21.6985082 },
    { lat: 49.368226, lng: 21.699939 },
    { lat: 49.3682404, lng: 21.7005679 },
    { lat: 49.3681455, lng: 21.7013022 },
    { lat: 49.3677931, lng: 21.7031032 },
    { lat: 49.367919, lng: 21.7032743 },
    { lat: 49.3680137, lng: 21.7042537 },
    { lat: 49.3677549, lng: 21.7057212 },
    { lat: 49.3675939, lng: 21.7063939 },
    { lat: 49.3667747, lng: 21.7076898 },
    { lat: 49.3664398, lng: 21.7084607 },
    { lat: 49.3661956, lng: 21.7088744 },
    { lat: 49.3647261, lng: 21.7110593 },
    { lat: 49.3646247, lng: 21.7121909 },
    { lat: 49.3645026, lng: 21.7125536 },
    { lat: 49.3645935, lng: 21.7128803 },
    { lat: 49.3644102, lng: 21.7135218 },
    { lat: 49.3644328, lng: 21.7138225 },
    { lat: 49.3646413, lng: 21.7148922 },
    { lat: 49.3650182, lng: 21.7157872 },
    { lat: 49.3655579, lng: 21.7176396 },
    { lat: 49.3659883, lng: 21.7188576 },
    { lat: 49.3660914, lng: 21.7196051 },
    { lat: 49.3661771, lng: 21.7196933 },
    { lat: 49.3714255, lng: 21.7238522 },
    { lat: 49.3717889, lng: 21.7242425 },
    { lat: 49.3744969, lng: 21.7277883 },
    { lat: 49.3741659, lng: 21.7321154 },
    { lat: 49.3772609, lng: 21.7369106 },
    { lat: 49.37738, lng: 21.7371627 },
    { lat: 49.3783701, lng: 21.7386285 },
    { lat: 49.3784997, lng: 21.7427343 },
    { lat: 49.376993, lng: 21.762955 },
    { lat: 49.377317, lng: 21.764008 },
    { lat: 49.37811, lng: 21.765149 },
    { lat: 49.379235, lng: 21.764755 },
    { lat: 49.379472, lng: 21.765125 },
    { lat: 49.380762, lng: 21.764869 },
    { lat: 49.381537, lng: 21.76477 },
    { lat: 49.382284, lng: 21.764071 },
    { lat: 49.383467, lng: 21.763385 },
    { lat: 49.384498, lng: 21.761377 },
    { lat: 49.385021, lng: 21.759732 },
    { lat: 49.385566, lng: 21.758478 },
    { lat: 49.386311, lng: 21.757204 },
    { lat: 49.386604, lng: 21.756449 },
    { lat: 49.386739, lng: 21.755481 },
    { lat: 49.387294, lng: 21.754262 },
    { lat: 49.387224, lng: 21.753145 },
    { lat: 49.387363, lng: 21.752115 },
    { lat: 49.388247, lng: 21.751235 },
    { lat: 49.388611, lng: 21.750728 },
    { lat: 49.3891289, lng: 21.7503747 },
    { lat: 49.3893938, lng: 21.7489895 },
    { lat: 49.389999, lng: 21.7479717 },
    { lat: 49.3900359, lng: 21.7465604 },
    { lat: 49.3901577, lng: 21.7452026 },
    { lat: 49.3907736, lng: 21.7441542 },
    { lat: 49.3914864, lng: 21.7433456 },
    { lat: 49.3918356, lng: 21.7421004 },
    { lat: 49.3925326, lng: 21.7405013 },
    { lat: 49.3931369, lng: 21.7394127 },
    { lat: 49.394004, lng: 21.739003 },
    { lat: 49.394845, lng: 21.7385968 },
    { lat: 49.3952224, lng: 21.7381991 },
    { lat: 49.3956052, lng: 21.737757 },
    { lat: 49.3958773, lng: 21.7371256 },
    { lat: 49.3962058, lng: 21.7366091 },
    { lat: 49.3967927, lng: 21.7363971 },
    { lat: 49.3974699, lng: 21.7358853 },
    { lat: 49.397929, lng: 21.7355267 },
    { lat: 49.3982087, lng: 21.7353864 },
    { lat: 49.3984034, lng: 21.7353955 },
    { lat: 49.3989566, lng: 21.7346556 },
    { lat: 49.3992767, lng: 21.7328913 },
    { lat: 49.3990735, lng: 21.7321309 },
    { lat: 49.3987286, lng: 21.7311327 },
    { lat: 49.3984407, lng: 21.7304916 },
    { lat: 49.3981809, lng: 21.7304053 },
    { lat: 49.3978018, lng: 21.7299372 },
    { lat: 49.3976606, lng: 21.7294221 },
    { lat: 49.3971963, lng: 21.7291874 },
    { lat: 49.3969632, lng: 21.728753 },
    { lat: 49.3962364, lng: 21.7277313 },
    { lat: 49.3953578, lng: 21.7260218 },
    { lat: 49.3953946, lng: 21.7249456 },
    { lat: 49.3958525, lng: 21.7237576 },
    { lat: 49.3957899, lng: 21.7235578 },
    { lat: 49.3955707, lng: 21.7214839 },
    { lat: 49.3955846, lng: 21.7211648 },
    { lat: 49.3954548, lng: 21.7205085 },
    { lat: 49.3951423, lng: 21.7201372 },
    { lat: 49.3946199, lng: 21.7189694 },
    { lat: 49.3943144, lng: 21.7173313 },
    { lat: 49.3939363, lng: 21.7166481 },
    { lat: 49.3936328, lng: 21.7157282 },
    { lat: 49.3936883, lng: 21.7146768 },
    { lat: 49.3934374, lng: 21.7142086 },
    { lat: 49.3931673, lng: 21.7141662 },
    { lat: 49.3929927, lng: 21.7133873 },
    { lat: 49.3922083, lng: 21.7122331 },
    { lat: 49.3917015, lng: 21.7121264 },
    { lat: 49.3915607, lng: 21.7117766 },
    { lat: 49.3917362, lng: 21.7114006 },
    { lat: 49.391472, lng: 21.7106379 },
    { lat: 49.3909776, lng: 21.7098541 },
    { lat: 49.3913464, lng: 21.7084507 },
    { lat: 49.392437, lng: 21.7047618 },
    { lat: 49.3926031, lng: 21.7033165 },
    { lat: 49.3928634, lng: 21.701603 },
    { lat: 49.39316, lng: 21.7008752 },
    { lat: 49.3932077, lng: 21.7003378 },
    { lat: 49.3930833, lng: 21.6999 },
    { lat: 49.3931384, lng: 21.6977408 },
    { lat: 49.3929895, lng: 21.694658 },
    { lat: 49.3928167, lng: 21.6943366 },
    { lat: 49.3927667, lng: 21.6939754 },
    { lat: 49.3926233, lng: 21.6935832 },
    { lat: 49.3925864, lng: 21.6931291 },
    { lat: 49.3924224, lng: 21.6927978 },
    { lat: 49.3922257, lng: 21.6920865 },
    { lat: 49.3916664, lng: 21.6912326 },
    { lat: 49.391405, lng: 21.690589 },
  ],
  Okrúhle: [
    { lat: 49.214294, lng: 21.521042 },
    { lat: 49.214266, lng: 21.520975 },
    { lat: 49.214209, lng: 21.520871 },
    { lat: 49.214117, lng: 21.520685 },
    { lat: 49.214018, lng: 21.52044 },
    { lat: 49.213979, lng: 21.520335 },
    { lat: 49.213808, lng: 21.519827 },
    { lat: 49.213834, lng: 21.519499 },
    { lat: 49.213815, lng: 21.519191 },
    { lat: 49.213768, lng: 21.518959 },
    { lat: 49.213716, lng: 21.518785 },
    { lat: 49.213671, lng: 21.51863 },
    { lat: 49.213638, lng: 21.518599 },
    { lat: 49.213413, lng: 21.518467 },
    { lat: 49.213502, lng: 21.518114 },
    { lat: 49.213576, lng: 21.517702 },
    { lat: 49.213563, lng: 21.517294 },
    { lat: 49.213553, lng: 21.517237 },
    { lat: 49.213509, lng: 21.517139 },
    { lat: 49.213031, lng: 21.516883 },
    { lat: 49.212828, lng: 21.516788 },
    { lat: 49.212624, lng: 21.516623 },
    { lat: 49.21247, lng: 21.516407 },
    { lat: 49.212281, lng: 21.516208 },
    { lat: 49.21222, lng: 21.516186 },
    { lat: 49.212019, lng: 21.516209 },
    { lat: 49.211588, lng: 21.516276 },
    { lat: 49.211279, lng: 21.516297 },
    { lat: 49.210945, lng: 21.516293 },
    { lat: 49.210743, lng: 21.516221 },
    { lat: 49.210486, lng: 21.516142 },
    { lat: 49.210387, lng: 21.516088 },
    { lat: 49.208566, lng: 21.514997 },
    { lat: 49.208273, lng: 21.514475 },
    { lat: 49.208131, lng: 21.514338 },
    { lat: 49.20766, lng: 21.513308 },
    { lat: 49.20755, lng: 21.513151 },
    { lat: 49.207495, lng: 21.512981 },
    { lat: 49.206852, lng: 21.512133 },
    { lat: 49.206369, lng: 21.5113 },
    { lat: 49.206344, lng: 21.511254 },
    { lat: 49.206207, lng: 21.510649 },
    { lat: 49.205849, lng: 21.510705 },
    { lat: 49.205818, lng: 21.510538 },
    { lat: 49.205786, lng: 21.510374 },
    { lat: 49.205704, lng: 21.510031 },
    { lat: 49.205668, lng: 21.50989 },
    { lat: 49.205641, lng: 21.509771 },
    { lat: 49.205617, lng: 21.509648 },
    { lat: 49.205592, lng: 21.509521 },
    { lat: 49.205551, lng: 21.509319 },
    { lat: 49.205492, lng: 21.50907 },
    { lat: 49.205466, lng: 21.508917 },
    { lat: 49.205433, lng: 21.508753 },
    { lat: 49.20539, lng: 21.508536 },
    { lat: 49.205366, lng: 21.508418 },
    { lat: 49.205317, lng: 21.508321 },
    { lat: 49.205255, lng: 21.508195 },
    { lat: 49.205188, lng: 21.508063 },
    { lat: 49.205104, lng: 21.507912 },
    { lat: 49.205035, lng: 21.507735 },
    { lat: 49.205011, lng: 21.507623 },
    { lat: 49.204797, lng: 21.50699 },
    { lat: 49.204774, lng: 21.506916 },
    { lat: 49.204731, lng: 21.506829 },
    { lat: 49.204711, lng: 21.506737 },
    { lat: 49.204954, lng: 21.50649 },
    { lat: 49.204951, lng: 21.506389 },
    { lat: 49.205065, lng: 21.506158 },
    { lat: 49.205152, lng: 21.506021 },
    { lat: 49.205228, lng: 21.505956 },
    { lat: 49.205207, lng: 21.505885 },
    { lat: 49.205159, lng: 21.505748 },
    { lat: 49.20495, lng: 21.505968 },
    { lat: 49.204858, lng: 21.50598 },
    { lat: 49.204786, lng: 21.505931 },
    { lat: 49.204814, lng: 21.505554 },
    { lat: 49.20483, lng: 21.505414 },
    { lat: 49.204728, lng: 21.505295 },
    { lat: 49.204625, lng: 21.505196 },
    { lat: 49.204532, lng: 21.505108 },
    { lat: 49.204503, lng: 21.505047 },
    { lat: 49.204316, lng: 21.505225 },
    { lat: 49.20429, lng: 21.505157 },
    { lat: 49.204263, lng: 21.505102 },
    { lat: 49.204183, lng: 21.505017 },
    { lat: 49.204135, lng: 21.504873 },
    { lat: 49.204091, lng: 21.504627 },
    { lat: 49.20403, lng: 21.504457 },
    { lat: 49.203964, lng: 21.504308 },
    { lat: 49.203869, lng: 21.504379 },
    { lat: 49.203854, lng: 21.504277 },
    { lat: 49.203855, lng: 21.504202 },
    { lat: 49.203678, lng: 21.504107 },
    { lat: 49.203753, lng: 21.504036 },
    { lat: 49.203712, lng: 21.50395 },
    { lat: 49.203792, lng: 21.503884 },
    { lat: 49.203918, lng: 21.503759 },
    { lat: 49.20367, lng: 21.503651 },
    { lat: 49.203606, lng: 21.503656 },
    { lat: 49.203519, lng: 21.503585 },
    { lat: 49.203443, lng: 21.503452 },
    { lat: 49.20342, lng: 21.503296 },
    { lat: 49.203413, lng: 21.503119 },
    { lat: 49.203405, lng: 21.502989 },
    { lat: 49.203387, lng: 21.502663 },
    { lat: 49.203319, lng: 21.502565 },
    { lat: 49.203351, lng: 21.502431 },
    { lat: 49.20336, lng: 21.5023 },
    { lat: 49.20338, lng: 21.502158 },
    { lat: 49.20335, lng: 21.501752 },
    { lat: 49.203345, lng: 21.501619 },
    { lat: 49.203442, lng: 21.501772 },
    { lat: 49.203557, lng: 21.501391 },
    { lat: 49.20352, lng: 21.501152 },
    { lat: 49.203355, lng: 21.501013 },
    { lat: 49.203336, lng: 21.500861 },
    { lat: 49.203246, lng: 21.500733 },
    { lat: 49.203209, lng: 21.500471 },
    { lat: 49.201865, lng: 21.501359 },
    { lat: 49.2018012, lng: 21.5013757 },
    { lat: 49.2016812, lng: 21.501551 },
    { lat: 49.2015214, lng: 21.5016274 },
    { lat: 49.20121, lng: 21.5019924 },
    { lat: 49.2008102, lng: 21.5026118 },
    { lat: 49.2006551, lng: 21.503012 },
    { lat: 49.2001769, lng: 21.5036356 },
    { lat: 49.1996024, lng: 21.5042108 },
    { lat: 49.19945, lng: 21.5041493 },
    { lat: 49.1993843, lng: 21.5044493 },
    { lat: 49.1985748, lng: 21.506092 },
    { lat: 49.1974557, lng: 21.5075259 },
    { lat: 49.1973068, lng: 21.5078257 },
    { lat: 49.1962218, lng: 21.5090336 },
    { lat: 49.1953113, lng: 21.5096971 },
    { lat: 49.1954787, lng: 21.5107569 },
    { lat: 49.1944779, lng: 21.5121181 },
    { lat: 49.1948749, lng: 21.5131063 },
    { lat: 49.1944868, lng: 21.5136614 },
    { lat: 49.1941036, lng: 21.5130846 },
    { lat: 49.1934916, lng: 21.513525 },
    { lat: 49.1929502, lng: 21.5153309 },
    { lat: 49.1920219, lng: 21.5161224 },
    { lat: 49.1912699, lng: 21.5176119 },
    { lat: 49.1897876, lng: 21.5202488 },
    { lat: 49.1889112, lng: 21.5212353 },
    { lat: 49.1866904, lng: 21.5232224 },
    { lat: 49.1855552, lng: 21.5233472 },
    { lat: 49.1846831, lng: 21.5240944 },
    { lat: 49.1846249, lng: 21.5235444 },
    { lat: 49.182387, lng: 21.5236142 },
    { lat: 49.1814327, lng: 21.5233721 },
    { lat: 49.1801184, lng: 21.5228815 },
    { lat: 49.1788173, lng: 21.5225799 },
    { lat: 49.1775161, lng: 21.5221288 },
    { lat: 49.1774002, lng: 21.5223662 },
    { lat: 49.1773996, lng: 21.5227345 },
    { lat: 49.1771786, lng: 21.5229224 },
    { lat: 49.1770728, lng: 21.5231156 },
    { lat: 49.1767254, lng: 21.5249504 },
    { lat: 49.1765159, lng: 21.5255022 },
    { lat: 49.1759358, lng: 21.5263494 },
    { lat: 49.1757123, lng: 21.5269963 },
    { lat: 49.1752453, lng: 21.5278734 },
    { lat: 49.1750213, lng: 21.5288213 },
    { lat: 49.1747388, lng: 21.5292947 },
    { lat: 49.174498, lng: 21.529965 },
    { lat: 49.1742826, lng: 21.5303117 },
    { lat: 49.1733765, lng: 21.5311663 },
    { lat: 49.1732401, lng: 21.5313949 },
    { lat: 49.1731931, lng: 21.5320431 },
    { lat: 49.1732223, lng: 21.5322824 },
    { lat: 49.1733039, lng: 21.532345 },
    { lat: 49.1733613, lng: 21.5326217 },
    { lat: 49.173341, lng: 21.5328974 },
    { lat: 49.1734018, lng: 21.5333407 },
    { lat: 49.173248, lng: 21.5345046 },
    { lat: 49.1731485, lng: 21.5348063 },
    { lat: 49.1731479, lng: 21.5350246 },
    { lat: 49.1732132, lng: 21.5352278 },
    { lat: 49.1731718, lng: 21.535356 },
    { lat: 49.1729877, lng: 21.5352872 },
    { lat: 49.172815, lng: 21.536028 },
    { lat: 49.1726243, lng: 21.5366051 },
    { lat: 49.1726187, lng: 21.5368424 },
    { lat: 49.1724738, lng: 21.5372531 },
    { lat: 49.1720803, lng: 21.5389732 },
    { lat: 49.1720359, lng: 21.5395143 },
    { lat: 49.1718625, lng: 21.5401876 },
    { lat: 49.1718957, lng: 21.5402117 },
    { lat: 49.1718826, lng: 21.5403837 },
    { lat: 49.1717271, lng: 21.5414495 },
    { lat: 49.1714701, lng: 21.5424342 },
    { lat: 49.1712089, lng: 21.543064 },
    { lat: 49.1710498, lng: 21.544171 },
    { lat: 49.1708817, lng: 21.5443546 },
    { lat: 49.170624, lng: 21.5441863 },
    { lat: 49.1703963, lng: 21.5441706 },
    { lat: 49.1704534, lng: 21.5446397 },
    { lat: 49.1704924, lng: 21.5446709 },
    { lat: 49.1704272, lng: 21.5449492 },
    { lat: 49.1703543, lng: 21.5450816 },
    { lat: 49.170088, lng: 21.545327 },
    { lat: 49.1696794, lng: 21.5450064 },
    { lat: 49.1695264, lng: 21.5450067 },
    { lat: 49.1691377, lng: 21.5451856 },
    { lat: 49.1690647, lng: 21.5453903 },
    { lat: 49.1690722, lng: 21.5455707 },
    { lat: 49.1692349, lng: 21.5458825 },
    { lat: 49.1694322, lng: 21.546049 },
    { lat: 49.1694322, lng: 21.5461748 },
    { lat: 49.1692057, lng: 21.5463633 },
    { lat: 49.1689521, lng: 21.5461796 },
    { lat: 49.16885, lng: 21.5455172 },
    { lat: 49.1686599, lng: 21.545557 },
    { lat: 49.1686989, lng: 21.5460208 },
    { lat: 49.1686065, lng: 21.5461383 },
    { lat: 49.168463, lng: 21.5462339 },
    { lat: 49.16794, lng: 21.546197 },
    { lat: 49.1679611, lng: 21.5456315 },
    { lat: 49.1680278, lng: 21.5454042 },
    { lat: 49.1683114, lng: 21.5456862 },
    { lat: 49.1683283, lng: 21.5455266 },
    { lat: 49.16816, lng: 21.5450256 },
    { lat: 49.1677952, lng: 21.5450887 },
    { lat: 49.1677664, lng: 21.5453886 },
    { lat: 49.1672851, lng: 21.546673 },
    { lat: 49.1674445, lng: 21.5469013 },
    { lat: 49.1673421, lng: 21.5471413 },
    { lat: 49.1671772, lng: 21.5473534 },
    { lat: 49.167123, lng: 21.5473897 },
    { lat: 49.1669018, lng: 21.5472449 },
    { lat: 49.1670381, lng: 21.5466068 },
    { lat: 49.166892, lng: 21.5461411 },
    { lat: 49.1668087, lng: 21.5460552 },
    { lat: 49.1666826, lng: 21.5460879 },
    { lat: 49.1665911, lng: 21.5461967 },
    { lat: 49.1663844, lng: 21.5461732 },
    { lat: 49.1663127, lng: 21.5462563 },
    { lat: 49.166313, lng: 21.5463933 },
    { lat: 49.1667122, lng: 21.5472271 },
    { lat: 49.1655111, lng: 21.5474368 },
    { lat: 49.1654316, lng: 21.5467302 },
    { lat: 49.165113, lng: 21.5462054 },
    { lat: 49.1648827, lng: 21.5460579 },
    { lat: 49.1646903, lng: 21.5460993 },
    { lat: 49.1640926, lng: 21.5454086 },
    { lat: 49.1638726, lng: 21.5455416 },
    { lat: 49.1637338, lng: 21.545491 },
    { lat: 49.1631739, lng: 21.5449406 },
    { lat: 49.1628, lng: 21.5446868 },
    { lat: 49.1623001, lng: 21.544433 },
    { lat: 49.1620464, lng: 21.5444155 },
    { lat: 49.1620185, lng: 21.5440593 },
    { lat: 49.162049, lng: 21.5439861 },
    { lat: 49.1619125, lng: 21.5438534 },
    { lat: 49.1617625, lng: 21.5438762 },
    { lat: 49.1614104, lng: 21.5443572 },
    { lat: 49.1610645, lng: 21.5445071 },
    { lat: 49.1610665, lng: 21.5448118 },
    { lat: 49.1611843, lng: 21.5448849 },
    { lat: 49.1610633, lng: 21.546179 },
    { lat: 49.1609644, lng: 21.5466212 },
    { lat: 49.1609435, lng: 21.5475953 },
    { lat: 49.1607689, lng: 21.5478759 },
    { lat: 49.1606966, lng: 21.5485823 },
    { lat: 49.160586, lng: 21.5490518 },
    { lat: 49.160806, lng: 21.5492287 },
    { lat: 49.1605815, lng: 21.549931 },
    { lat: 49.1606461, lng: 21.5506281 },
    { lat: 49.1606934, lng: 21.5504647 },
    { lat: 49.1609611, lng: 21.5505436 },
    { lat: 49.1609038, lng: 21.5511063 },
    { lat: 49.1608365, lng: 21.5513199 },
    { lat: 49.160719, lng: 21.5513754 },
    { lat: 49.1606527, lng: 21.5520627 },
    { lat: 49.160775, lng: 21.552095 },
    { lat: 49.1608979, lng: 21.5526243 },
    { lat: 49.1609955, lng: 21.5527772 },
    { lat: 49.1610654, lng: 21.5531738 },
    { lat: 49.1610742, lng: 21.5538487 },
    { lat: 49.1609888, lng: 21.5546224 },
    { lat: 49.1606876, lng: 21.555388 },
    { lat: 49.1606274, lng: 21.5557284 },
    { lat: 49.1603372, lng: 21.5564529 },
    { lat: 49.1603415, lng: 21.5565637 },
    { lat: 49.1601266, lng: 21.5572078 },
    { lat: 49.1597242, lng: 21.5578214 },
    { lat: 49.1598538, lng: 21.55791 },
    { lat: 49.1595708, lng: 21.5586129 },
    { lat: 49.1595574, lng: 21.5588179 },
    { lat: 49.1591109, lng: 21.5597018 },
    { lat: 49.1598197, lng: 21.5598652 },
    { lat: 49.1598472, lng: 21.5600489 },
    { lat: 49.1597765, lng: 21.5604532 },
    { lat: 49.1596298, lng: 21.5606788 },
    { lat: 49.1596555, lng: 21.5609557 },
    { lat: 49.1596975, lng: 21.5610824 },
    { lat: 49.1599462, lng: 21.5610892 },
    { lat: 49.1600971, lng: 21.5614953 },
    { lat: 49.1603046, lng: 21.5617158 },
    { lat: 49.1601799, lng: 21.5618414 },
    { lat: 49.1604722, lng: 21.5623968 },
    { lat: 49.1604091, lng: 21.5628395 },
    { lat: 49.1601424, lng: 21.5631124 },
    { lat: 49.159858, lng: 21.5636399 },
    { lat: 49.1598002, lng: 21.5638342 },
    { lat: 49.1598889, lng: 21.5640624 },
    { lat: 49.159469, lng: 21.5652242 },
    { lat: 49.1594521, lng: 21.5655391 },
    { lat: 49.1593387, lng: 21.5661126 },
    { lat: 49.1592232, lng: 21.5663325 },
    { lat: 49.1593418, lng: 21.5665836 },
    { lat: 49.1592848, lng: 21.5669252 },
    { lat: 49.1590999, lng: 21.5672676 },
    { lat: 49.1591213, lng: 21.567434 },
    { lat: 49.1590813, lng: 21.5674528 },
    { lat: 49.15917, lng: 21.569418 },
    { lat: 49.159285, lng: 21.569336 },
    { lat: 49.159591, lng: 21.569232 },
    { lat: 49.160012, lng: 21.569299 },
    { lat: 49.16031, lng: 21.569528 },
    { lat: 49.160671, lng: 21.569702 },
    { lat: 49.160858, lng: 21.569895 },
    { lat: 49.16114, lng: 21.570157 },
    { lat: 49.161203, lng: 21.570276 },
    { lat: 49.161447, lng: 21.570579 },
    { lat: 49.161545, lng: 21.570631 },
    { lat: 49.16169, lng: 21.570785 },
    { lat: 49.16182, lng: 21.571123 },
    { lat: 49.162041, lng: 21.571466 },
    { lat: 49.162174, lng: 21.571339 },
    { lat: 49.162235, lng: 21.571548 },
    { lat: 49.162344, lng: 21.571736 },
    { lat: 49.162458, lng: 21.571866 },
    { lat: 49.162472, lng: 21.571986 },
    { lat: 49.162665, lng: 21.572031 },
    { lat: 49.162794, lng: 21.572174 },
    { lat: 49.162926, lng: 21.572478 },
    { lat: 49.163013, lng: 21.572627 },
    { lat: 49.163004, lng: 21.572641 },
    { lat: 49.163056, lng: 21.572715 },
    { lat: 49.163636, lng: 21.572797 },
    { lat: 49.164528, lng: 21.572763 },
    { lat: 49.164761, lng: 21.572924 },
    { lat: 49.164942, lng: 21.572956 },
    { lat: 49.165258, lng: 21.573015 },
    { lat: 49.165407, lng: 21.572935 },
    { lat: 49.1656, lng: 21.572797 },
    { lat: 49.165883, lng: 21.572784 },
    { lat: 49.166145, lng: 21.57289 },
    { lat: 49.166542, lng: 21.573109 },
    { lat: 49.166547, lng: 21.573094 },
    { lat: 49.166588, lng: 21.57313 },
    { lat: 49.166842, lng: 21.573337 },
    { lat: 49.166995, lng: 21.573464 },
    { lat: 49.167399, lng: 21.573853 },
    { lat: 49.167804, lng: 21.574241 },
    { lat: 49.167958, lng: 21.574403 },
    { lat: 49.168073, lng: 21.574549 },
    { lat: 49.168433, lng: 21.574897 },
    { lat: 49.168617, lng: 21.575277 },
    { lat: 49.168737, lng: 21.57547 },
    { lat: 49.16906, lng: 21.575775 },
    { lat: 49.169771, lng: 21.575785 },
    { lat: 49.16992, lng: 21.575709 },
    { lat: 49.169927, lng: 21.575706 },
    { lat: 49.169969, lng: 21.575683 },
    { lat: 49.170004, lng: 21.575665 },
    { lat: 49.17006, lng: 21.575644 },
    { lat: 49.170171, lng: 21.575956 },
    { lat: 49.17019, lng: 21.576017 },
    { lat: 49.170237, lng: 21.576184 },
    { lat: 49.17023, lng: 21.576389 },
    { lat: 49.170362, lng: 21.57664 },
    { lat: 49.1704, lng: 21.576771 },
    { lat: 49.170568, lng: 21.577744 },
    { lat: 49.170554, lng: 21.57797 },
    { lat: 49.170666, lng: 21.57816 },
    { lat: 49.17071, lng: 21.578335 },
    { lat: 49.170773, lng: 21.578477 },
    { lat: 49.170911, lng: 21.57855 },
    { lat: 49.170939, lng: 21.578657 },
    { lat: 49.171028, lng: 21.578741 },
    { lat: 49.171135, lng: 21.578886 },
    { lat: 49.171355, lng: 21.578991 },
    { lat: 49.171453, lng: 21.578931 },
    { lat: 49.171597, lng: 21.579065 },
    { lat: 49.171701, lng: 21.57924 },
    { lat: 49.172073, lng: 21.579346 },
    { lat: 49.172189, lng: 21.579529 },
    { lat: 49.17226, lng: 21.579842 },
    { lat: 49.172452, lng: 21.580009 },
    { lat: 49.172529, lng: 21.580229 },
    { lat: 49.172702, lng: 21.580328 },
    { lat: 49.172874, lng: 21.580472 },
    { lat: 49.17301, lng: 21.580503 },
    { lat: 49.173132, lng: 21.580672 },
    { lat: 49.173356, lng: 21.580791 },
    { lat: 49.173448, lng: 21.581135 },
    { lat: 49.17357, lng: 21.581363 },
    { lat: 49.1736, lng: 21.581684 },
    { lat: 49.173593, lng: 21.582046 },
    { lat: 49.173667, lng: 21.582133 },
    { lat: 49.173653, lng: 21.582344 },
    { lat: 49.173499, lng: 21.582497 },
    { lat: 49.173628, lng: 21.582932 },
    { lat: 49.173874, lng: 21.583105 },
    { lat: 49.174087, lng: 21.583302 },
    { lat: 49.174135, lng: 21.583548 },
    { lat: 49.174166, lng: 21.583814 },
    { lat: 49.174176, lng: 21.583902 },
    { lat: 49.17422, lng: 21.584172 },
    { lat: 49.174295, lng: 21.584545 },
    { lat: 49.174314, lng: 21.584707 },
    { lat: 49.174418, lng: 21.584799 },
    { lat: 49.174421, lng: 21.584706 },
    { lat: 49.174524, lng: 21.584805 },
    { lat: 49.174502, lng: 21.584864 },
    { lat: 49.174458, lng: 21.5849 },
    { lat: 49.174487, lng: 21.584928 },
    { lat: 49.174544, lng: 21.584888 },
    { lat: 49.174567, lng: 21.584766 },
    { lat: 49.174678, lng: 21.584828 },
    { lat: 49.17467, lng: 21.584713 },
    { lat: 49.174781, lng: 21.584643 },
    { lat: 49.174779, lng: 21.584583 },
    { lat: 49.174829, lng: 21.584613 },
    { lat: 49.174839, lng: 21.584685 },
    { lat: 49.174939, lng: 21.584708 },
    { lat: 49.175014, lng: 21.584677 },
    { lat: 49.175078, lng: 21.584628 },
    { lat: 49.175125, lng: 21.58471 },
    { lat: 49.175162, lng: 21.584686 },
    { lat: 49.175189, lng: 21.584537 },
    { lat: 49.175228, lng: 21.584494 },
    { lat: 49.175325, lng: 21.584447 },
    { lat: 49.175336, lng: 21.584386 },
    { lat: 49.175311, lng: 21.584332 },
    { lat: 49.175391, lng: 21.584217 },
    { lat: 49.175437, lng: 21.584196 },
    { lat: 49.175491, lng: 21.584135 },
    { lat: 49.175601, lng: 21.5841 },
    { lat: 49.175673, lng: 21.584101 },
    { lat: 49.175718, lng: 21.584059 },
    { lat: 49.175755, lng: 21.584078 },
    { lat: 49.175766, lng: 21.584144 },
    { lat: 49.175816, lng: 21.584135 },
    { lat: 49.175995, lng: 21.584348 },
    { lat: 49.176055, lng: 21.584433 },
    { lat: 49.176123, lng: 21.584514 },
    { lat: 49.176252, lng: 21.584588 },
    { lat: 49.17631, lng: 21.584595 },
    { lat: 49.176342, lng: 21.58461 },
    { lat: 49.176401, lng: 21.584702 },
    { lat: 49.176506, lng: 21.584791 },
    { lat: 49.17657, lng: 21.584819 },
    { lat: 49.176622, lng: 21.584859 },
    { lat: 49.17665, lng: 21.584844 },
    { lat: 49.176683, lng: 21.584781 },
    { lat: 49.176727, lng: 21.584747 },
    { lat: 49.176847, lng: 21.584733 },
    { lat: 49.176927, lng: 21.584748 },
    { lat: 49.176993, lng: 21.584776 },
    { lat: 49.177038, lng: 21.584779 },
    { lat: 49.177093, lng: 21.584742 },
    { lat: 49.177147, lng: 21.584732 },
    { lat: 49.177204, lng: 21.584774 },
    { lat: 49.177256, lng: 21.584848 },
    { lat: 49.177307, lng: 21.584865 },
    { lat: 49.177336, lng: 21.584847 },
    { lat: 49.177405, lng: 21.584856 },
    { lat: 49.177487, lng: 21.584877 },
    { lat: 49.177734, lng: 21.584988 },
    { lat: 49.177785, lng: 21.584991 },
    { lat: 49.177911, lng: 21.584921 },
    { lat: 49.17796, lng: 21.584928 },
    { lat: 49.178025, lng: 21.585048 },
    { lat: 49.17817, lng: 21.585053 },
    { lat: 49.178178, lng: 21.585054 },
    { lat: 49.178217, lng: 21.585071 },
    { lat: 49.17833, lng: 21.585091 },
    { lat: 49.178421, lng: 21.585064 },
    { lat: 49.178676, lng: 21.585123 },
    { lat: 49.178725, lng: 21.585138 },
    { lat: 49.178784, lng: 21.58509 },
    { lat: 49.178903, lng: 21.585107 },
    { lat: 49.178962, lng: 21.585063 },
    { lat: 49.179068, lng: 21.585098 },
    { lat: 49.179134, lng: 21.585162 },
    { lat: 49.179344, lng: 21.585121 },
    { lat: 49.179388, lng: 21.585063 },
    { lat: 49.179459, lng: 21.585013 },
    { lat: 49.179554, lng: 21.585053 },
    { lat: 49.179646, lng: 21.585119 },
    { lat: 49.179786, lng: 21.585149 },
    { lat: 49.179885, lng: 21.585143 },
    { lat: 49.180522, lng: 21.585302 },
    { lat: 49.180741, lng: 21.585321 },
    { lat: 49.180753, lng: 21.585465 },
    { lat: 49.180849, lng: 21.585804 },
    { lat: 49.180877, lng: 21.585937 },
    { lat: 49.180958, lng: 21.586498 },
    { lat: 49.181081, lng: 21.58704 },
    { lat: 49.181263, lng: 21.587292 },
    { lat: 49.181286, lng: 21.587439 },
    { lat: 49.181414, lng: 21.587652 },
    { lat: 49.181688, lng: 21.587886 },
    { lat: 49.181922, lng: 21.588308 },
    { lat: 49.182072, lng: 21.588369 },
    { lat: 49.182219, lng: 21.588592 },
    { lat: 49.182438, lng: 21.588687 },
    { lat: 49.182797, lng: 21.588977 },
    { lat: 49.182982, lng: 21.589074 },
    { lat: 49.183129, lng: 21.589299 },
    { lat: 49.183207, lng: 21.58929 },
    { lat: 49.18329, lng: 21.588695 },
    { lat: 49.183573, lng: 21.588309 },
    { lat: 49.183725, lng: 21.588282 },
    { lat: 49.183822, lng: 21.588362 },
    { lat: 49.183848, lng: 21.588453 },
    { lat: 49.183857, lng: 21.588366 },
    { lat: 49.183855, lng: 21.588172 },
    { lat: 49.183847, lng: 21.58798 },
    { lat: 49.183863, lng: 21.587292 },
    { lat: 49.183959, lng: 21.586976 },
    { lat: 49.184016, lng: 21.586618 },
    { lat: 49.18412, lng: 21.586427 },
    { lat: 49.184254, lng: 21.585969 },
    { lat: 49.1842261, lng: 21.5856927 },
    { lat: 49.1841303, lng: 21.5855269 },
    { lat: 49.1848702, lng: 21.5812671 },
    { lat: 49.184105, lng: 21.5808538 },
    { lat: 49.1841225, lng: 21.5802704 },
    { lat: 49.1840654, lng: 21.5789457 },
    { lat: 49.184092, lng: 21.5787256 },
    { lat: 49.1836233, lng: 21.5782576 },
    { lat: 49.1834219, lng: 21.5781804 },
    { lat: 49.183319, lng: 21.5768305 },
    { lat: 49.1831091, lng: 21.5761148 },
    { lat: 49.1831194, lng: 21.5753158 },
    { lat: 49.1830547, lng: 21.5750078 },
    { lat: 49.1828705, lng: 21.5749051 },
    { lat: 49.18356, lng: 21.5734819 },
    { lat: 49.183668, lng: 21.5734397 },
    { lat: 49.1834398, lng: 21.5732452 },
    { lat: 49.1831995, lng: 21.5729044 },
    { lat: 49.1829062, lng: 21.5720672 },
    { lat: 49.1828271, lng: 21.57137 },
    { lat: 49.1829589, lng: 21.5709324 },
    { lat: 49.1829971, lng: 21.570444 },
    { lat: 49.18331, lng: 21.5699289 },
    { lat: 49.1832427, lng: 21.5697304 },
    { lat: 49.1833545, lng: 21.5693894 },
    { lat: 49.1833638, lng: 21.5691975 },
    { lat: 49.1835033, lng: 21.5689489 },
    { lat: 49.1835731, lng: 21.5686718 },
    { lat: 49.1838852, lng: 21.5685688 },
    { lat: 49.1840051, lng: 21.568593 },
    { lat: 49.1852909, lng: 21.5670597 },
    { lat: 49.186895, lng: 21.5634381 },
    { lat: 49.1866982, lng: 21.5594534 },
    { lat: 49.1861207, lng: 21.5576719 },
    { lat: 49.1875298, lng: 21.5549682 },
    { lat: 49.1879593, lng: 21.5537555 },
    { lat: 49.1880965, lng: 21.5537997 },
    { lat: 49.1918117, lng: 21.5412033 },
    { lat: 49.1923421, lng: 21.5415387 },
    { lat: 49.1938646, lng: 21.5376832 },
    { lat: 49.1941723, lng: 21.5377753 },
    { lat: 49.1942809, lng: 21.5376394 },
    { lat: 49.1943219, lng: 21.5371791 },
    { lat: 49.1944317, lng: 21.5370438 },
    { lat: 49.1948399, lng: 21.5371138 },
    { lat: 49.1953586, lng: 21.5369578 },
    { lat: 49.1959362, lng: 21.5370171 },
    { lat: 49.1961313, lng: 21.5373877 },
    { lat: 49.1962989, lng: 21.5378728 },
    { lat: 49.196413, lng: 21.5380074 },
    { lat: 49.1973862, lng: 21.538368 },
    { lat: 49.1979605, lng: 21.538385 },
    { lat: 49.1995619, lng: 21.5381249 },
    { lat: 49.2003075, lng: 21.5379155 },
    { lat: 49.2006707, lng: 21.5379892 },
    { lat: 49.2009472, lng: 21.5379466 },
    { lat: 49.2012909, lng: 21.5377611 },
    { lat: 49.201234, lng: 21.5373396 },
    { lat: 49.201425, lng: 21.5370493 },
    { lat: 49.2018343, lng: 21.5368066 },
    { lat: 49.2022771, lng: 21.5367109 },
    { lat: 49.2030143, lng: 21.5363578 },
    { lat: 49.2036286, lng: 21.5362833 },
    { lat: 49.2039737, lng: 21.5360707 },
    { lat: 49.2046802, lng: 21.535997 },
    { lat: 49.2050002, lng: 21.5358468 },
    { lat: 49.2059649, lng: 21.5349145 },
    { lat: 49.2063735, lng: 21.5344063 },
    { lat: 49.2067235, lng: 21.5332906 },
    { lat: 49.2097886, lng: 21.5301671 },
    { lat: 49.2098707, lng: 21.529926 },
    { lat: 49.209944, lng: 21.5291328 },
    { lat: 49.2105098, lng: 21.5288894 },
    { lat: 49.2109912, lng: 21.5284917 },
    { lat: 49.2115301, lng: 21.527973 },
    { lat: 49.2126351, lng: 21.5265484 },
    { lat: 49.2127268, lng: 21.5259752 },
    { lat: 49.2128735, lng: 21.5255085 },
    { lat: 49.2131401, lng: 21.5249904 },
    { lat: 49.2137331, lng: 21.5230121 },
    { lat: 49.2140885, lng: 21.5220074 },
    { lat: 49.214294, lng: 21.521042 },
  ],
  Kurimka: [
    { lat: 49.3362588, lng: 21.4625254 },
    { lat: 49.3368984, lng: 21.4609845 },
    { lat: 49.3373702, lng: 21.4590761 },
    { lat: 49.3376903, lng: 21.4589445 },
    { lat: 49.3377767, lng: 21.4586631 },
    { lat: 49.3378371, lng: 21.4579429 },
    { lat: 49.3379006, lng: 21.4578076 },
    { lat: 49.3378504, lng: 21.4576252 },
    { lat: 49.3379198, lng: 21.456443 },
    { lat: 49.3390951, lng: 21.4583469 },
    { lat: 49.3384011, lng: 21.4559643 },
    { lat: 49.338333, lng: 21.4552452 },
    { lat: 49.3385201, lng: 21.4539238 },
    { lat: 49.3389674, lng: 21.4528549 },
    { lat: 49.3392381, lng: 21.4517147 },
    { lat: 49.3394267, lng: 21.4514616 },
    { lat: 49.3396849, lng: 21.4503933 },
    { lat: 49.3396377, lng: 21.4494162 },
    { lat: 49.3397038, lng: 21.4471425 },
    { lat: 49.339581, lng: 21.4461637 },
    { lat: 49.3397904, lng: 21.4446549 },
    { lat: 49.3401487, lng: 21.4438939 },
    { lat: 49.3411347, lng: 21.4422744 },
    { lat: 49.3409799, lng: 21.4414695 },
    { lat: 49.3408158, lng: 21.4410473 },
    { lat: 49.340852, lng: 21.4396991 },
    { lat: 49.3405015, lng: 21.4380777 },
    { lat: 49.3405407, lng: 21.4375281 },
    { lat: 49.3409442, lng: 21.4368153 },
    { lat: 49.3412668, lng: 21.4355663 },
    { lat: 49.342094, lng: 21.4335128 },
    { lat: 49.3435764, lng: 21.4319531 },
    { lat: 49.343385, lng: 21.4304356 },
    { lat: 49.3431734, lng: 21.4299675 },
    { lat: 49.3434563, lng: 21.4277009 },
    { lat: 49.3438564, lng: 21.4264437 },
    { lat: 49.3441041, lng: 21.4250539 },
    { lat: 49.34456, lng: 21.42389 },
    { lat: 49.344527, lng: 21.423897 },
    { lat: 49.343422, lng: 21.423691 },
    { lat: 49.343415, lng: 21.423689 },
    { lat: 49.343391, lng: 21.423679 },
    { lat: 49.342922, lng: 21.423494 },
    { lat: 49.34275, lng: 21.423426 },
    { lat: 49.341527, lng: 21.42374 },
    { lat: 49.341366, lng: 21.423606 },
    { lat: 49.341276, lng: 21.423531 },
    { lat: 49.340049, lng: 21.422511 },
    { lat: 49.339039, lng: 21.422174 },
    { lat: 49.338719, lng: 21.420866 },
    { lat: 49.338216, lng: 21.420294 },
    { lat: 49.337932, lng: 21.420022 },
    { lat: 49.337288, lng: 21.419393 },
    { lat: 49.337371, lng: 21.418367 },
    { lat: 49.337687, lng: 21.417184 },
    { lat: 49.337725, lng: 21.417106 },
    { lat: 49.337304, lng: 21.416852 },
    { lat: 49.336356, lng: 21.416277 },
    { lat: 49.333851, lng: 21.413894 },
    { lat: 49.3336, lng: 21.413703 },
    { lat: 49.333119, lng: 21.413798 },
    { lat: 49.332686, lng: 21.413647 },
    { lat: 49.332565, lng: 21.413868 },
    { lat: 49.33231, lng: 21.413636 },
    { lat: 49.332262, lng: 21.413531 },
    { lat: 49.332221, lng: 21.413373 },
    { lat: 49.332173, lng: 21.413237 },
    { lat: 49.332157, lng: 21.413209 },
    { lat: 49.332119, lng: 21.413148 },
    { lat: 49.332035, lng: 21.413038 },
    { lat: 49.33202, lng: 21.413018 },
    { lat: 49.332001, lng: 21.412993 },
    { lat: 49.331687, lng: 21.412529 },
    { lat: 49.331535, lng: 21.412362 },
    { lat: 49.330172, lng: 21.410872 },
    { lat: 49.330175, lng: 21.410678 },
    { lat: 49.330108, lng: 21.410462 },
    { lat: 49.330041, lng: 21.410468 },
    { lat: 49.329991, lng: 21.410397 },
    { lat: 49.329961, lng: 21.410317 },
    { lat: 49.329909, lng: 21.410153 },
    { lat: 49.329832, lng: 21.409989 },
    { lat: 49.329813, lng: 21.409516 },
    { lat: 49.329773, lng: 21.409424 },
    { lat: 49.329735, lng: 21.409427 },
    { lat: 49.329652, lng: 21.409411 },
    { lat: 49.329622, lng: 21.409366 },
    { lat: 49.329591, lng: 21.409149 },
    { lat: 49.329715, lng: 21.408874 },
    { lat: 49.32963, lng: 21.408709 },
    { lat: 49.329715, lng: 21.408396 },
    { lat: 49.329895, lng: 21.408475 },
    { lat: 49.32994, lng: 21.408528 },
    { lat: 49.329946, lng: 21.408439 },
    { lat: 49.329948, lng: 21.408414 },
    { lat: 49.330116, lng: 21.408235 },
    { lat: 49.33023, lng: 21.407932 },
    { lat: 49.330179, lng: 21.407799 },
    { lat: 49.330094, lng: 21.407681 },
    { lat: 49.330061, lng: 21.407484 },
    { lat: 49.330018, lng: 21.407376 },
    { lat: 49.329991, lng: 21.407222 },
    { lat: 49.330016, lng: 21.407132 },
    { lat: 49.329996, lng: 21.406974 },
    { lat: 49.330027, lng: 21.406846 },
    { lat: 49.329973, lng: 21.406775 },
    { lat: 49.329998, lng: 21.406663 },
    { lat: 49.32997, lng: 21.406593 },
    { lat: 49.329898, lng: 21.406508 },
    { lat: 49.329831, lng: 21.406369 },
    { lat: 49.329887, lng: 21.406288 },
    { lat: 49.330023, lng: 21.406248 },
    { lat: 49.33008, lng: 21.406193 },
    { lat: 49.330049, lng: 21.40614 },
    { lat: 49.329822, lng: 21.405939 },
    { lat: 49.329779, lng: 21.405633 },
    { lat: 49.329643, lng: 21.404562 },
    { lat: 49.329631, lng: 21.404462 },
    { lat: 49.329098, lng: 21.403459 },
    { lat: 49.328932, lng: 21.402681 },
    { lat: 49.328707, lng: 21.401628 },
    { lat: 49.32832, lng: 21.39918 },
    { lat: 49.328455, lng: 21.398415 },
    { lat: 49.328462, lng: 21.398384 },
    { lat: 49.328486, lng: 21.398278 },
    { lat: 49.328272, lng: 21.398228 },
    { lat: 49.327981, lng: 21.397981 },
    { lat: 49.327049, lng: 21.397318 },
    { lat: 49.326481, lng: 21.397077 },
    { lat: 49.325842, lng: 21.396777 },
    { lat: 49.325303, lng: 21.396503 },
    { lat: 49.324737, lng: 21.395453 },
    { lat: 49.323848, lng: 21.394503 },
    { lat: 49.323785, lng: 21.394891 },
    { lat: 49.323692, lng: 21.395626 },
    { lat: 49.32331, lng: 21.396293 },
    { lat: 49.323072, lng: 21.396612 },
    { lat: 49.322641, lng: 21.397605 },
    { lat: 49.322044, lng: 21.398929 },
    { lat: 49.321625, lng: 21.399911 },
    { lat: 49.321303, lng: 21.400819 },
    { lat: 49.3211, lng: 21.402138 },
    { lat: 49.320701, lng: 21.403984 },
    { lat: 49.320507, lng: 21.406351 },
    { lat: 49.319894, lng: 21.407967 },
    { lat: 49.319533, lng: 21.408462 },
    { lat: 49.319018, lng: 21.409168 },
    { lat: 49.31791, lng: 21.41162 },
    { lat: 49.317679, lng: 21.412213 },
    { lat: 49.317455, lng: 21.412971 },
    { lat: 49.317177, lng: 21.415643 },
    { lat: 49.316586, lng: 21.4175 },
    { lat: 49.31609, lng: 21.419498 },
    { lat: 49.315733, lng: 21.420608 },
    { lat: 49.315467, lng: 21.421276 },
    { lat: 49.314907, lng: 21.422751 },
    { lat: 49.314756, lng: 21.423169 },
    { lat: 49.314675, lng: 21.423095 },
    { lat: 49.314294, lng: 21.424192 },
    { lat: 49.31406, lng: 21.424529 },
    { lat: 49.312528, lng: 21.426365 },
    { lat: 49.3124, lng: 21.426862 },
    { lat: 49.312149, lng: 21.427352 },
    { lat: 49.311942, lng: 21.42778 },
    { lat: 49.311884, lng: 21.427935 },
    { lat: 49.311762, lng: 21.428258 },
    { lat: 49.311298, lng: 21.429653 },
    { lat: 49.31117, lng: 21.429891 },
    { lat: 49.311146, lng: 21.429941 },
    { lat: 49.311035, lng: 21.430197 },
    { lat: 49.310558, lng: 21.430758 },
    { lat: 49.310292, lng: 21.430947 },
    { lat: 49.310004, lng: 21.431108 },
    { lat: 49.309787, lng: 21.431215 },
    { lat: 49.309539, lng: 21.431395 },
    { lat: 49.308973, lng: 21.431851 },
    { lat: 49.308914, lng: 21.431885 },
    { lat: 49.308659, lng: 21.432033 },
    { lat: 49.308366, lng: 21.432208 },
    { lat: 49.30804, lng: 21.43237 },
    { lat: 49.307833, lng: 21.432544 },
    { lat: 49.307669, lng: 21.432786 },
    { lat: 49.307651, lng: 21.432812 },
    { lat: 49.307364, lng: 21.433192 },
    { lat: 49.307087, lng: 21.433614 },
    { lat: 49.307028, lng: 21.433703 },
    { lat: 49.306703, lng: 21.434201 },
    { lat: 49.306693, lng: 21.434214 },
    { lat: 49.306692, lng: 21.434216 },
    { lat: 49.306682, lng: 21.434228 },
    { lat: 49.306563, lng: 21.434376 },
    { lat: 49.306345, lng: 21.434624 },
    { lat: 49.306064, lng: 21.434912 },
    { lat: 49.306, lng: 21.435037 },
    { lat: 49.305943, lng: 21.435243 },
    { lat: 49.305965, lng: 21.435327 },
    { lat: 49.305982, lng: 21.435384 },
    { lat: 49.305987, lng: 21.435441 },
    { lat: 49.305995, lng: 21.435563 },
    { lat: 49.30601, lng: 21.435779 },
    { lat: 49.306019, lng: 21.435822 },
    { lat: 49.306043, lng: 21.435944 },
    { lat: 49.306065, lng: 21.436021 },
    { lat: 49.306116, lng: 21.436195 },
    { lat: 49.306136, lng: 21.436283 },
    { lat: 49.306206, lng: 21.436522 },
    { lat: 49.306237, lng: 21.436616 },
    { lat: 49.306179, lng: 21.436659 },
    { lat: 49.306179, lng: 21.436724 },
    { lat: 49.306224, lng: 21.436851 },
    { lat: 49.30628, lng: 21.436975 },
    { lat: 49.306327, lng: 21.437107 },
    { lat: 49.306369, lng: 21.437218 },
    { lat: 49.306392, lng: 21.437265 },
    { lat: 49.306457, lng: 21.437434 },
    { lat: 49.30649, lng: 21.437505 },
    { lat: 49.306536, lng: 21.437585 },
    { lat: 49.306616, lng: 21.437651 },
    { lat: 49.306604, lng: 21.43782 },
    { lat: 49.306672, lng: 21.437797 },
    { lat: 49.306696, lng: 21.437953 },
    { lat: 49.306702, lng: 21.438065 },
    { lat: 49.306768, lng: 21.438041 },
    { lat: 49.30685, lng: 21.438197 },
    { lat: 49.306753, lng: 21.438333 },
    { lat: 49.306791, lng: 21.438504 },
    { lat: 49.306835, lng: 21.438622 },
    { lat: 49.306842, lng: 21.438633 },
    { lat: 49.306881, lng: 21.438721 },
    { lat: 49.30692, lng: 21.438777 },
    { lat: 49.306961, lng: 21.438867 },
    { lat: 49.306971, lng: 21.438903 },
    { lat: 49.307041, lng: 21.439509 },
    { lat: 49.30743, lng: 21.439836 },
    { lat: 49.307519, lng: 21.439974 },
    { lat: 49.307533, lng: 21.440144 },
    { lat: 49.307625, lng: 21.440329 },
    { lat: 49.307675, lng: 21.440459 },
    { lat: 49.307764, lng: 21.440688 },
    { lat: 49.307771, lng: 21.440704 },
    { lat: 49.307779, lng: 21.440715 },
    { lat: 49.307914, lng: 21.440886 },
    { lat: 49.308248, lng: 21.441256 },
    { lat: 49.308414, lng: 21.44146 },
    { lat: 49.308521, lng: 21.441627 },
    { lat: 49.308549, lng: 21.441681 },
    { lat: 49.308613, lng: 21.441782 },
    { lat: 49.308782, lng: 21.442137 },
    { lat: 49.309038, lng: 21.442624 },
    { lat: 49.308907, lng: 21.442834 },
    { lat: 49.308868, lng: 21.442898 },
    { lat: 49.308818, lng: 21.442971 },
    { lat: 49.308743, lng: 21.442975 },
    { lat: 49.308684, lng: 21.443015 },
    { lat: 49.308645, lng: 21.443113 },
    { lat: 49.308694, lng: 21.443205 },
    { lat: 49.308756, lng: 21.443302 },
    { lat: 49.308803, lng: 21.443359 },
    { lat: 49.308821, lng: 21.443383 },
    { lat: 49.308855, lng: 21.443445 },
    { lat: 49.308909, lng: 21.443524 },
    { lat: 49.308949, lng: 21.443583 },
    { lat: 49.309011, lng: 21.44366 },
    { lat: 49.309, lng: 21.443706 },
    { lat: 49.309019, lng: 21.443728 },
    { lat: 49.309076, lng: 21.443846 },
    { lat: 49.309127, lng: 21.443952 },
    { lat: 49.309167, lng: 21.444009 },
    { lat: 49.309194, lng: 21.444061 },
    { lat: 49.309277, lng: 21.444218 },
    { lat: 49.309321, lng: 21.444287 },
    { lat: 49.309366, lng: 21.444367 },
    { lat: 49.309393, lng: 21.444427 },
    { lat: 49.309426, lng: 21.444475 },
    { lat: 49.309481, lng: 21.444571 },
    { lat: 49.30951, lng: 21.444627 },
    { lat: 49.309523, lng: 21.444671 },
    { lat: 49.309545, lng: 21.444722 },
    { lat: 49.309576, lng: 21.444766 },
    { lat: 49.309612, lng: 21.444839 },
    { lat: 49.30963, lng: 21.444891 },
    { lat: 49.309696, lng: 21.445004 },
    { lat: 49.309763, lng: 21.445121 },
    { lat: 49.309814, lng: 21.44506 },
    { lat: 49.309877, lng: 21.445084 },
    { lat: 49.30991, lng: 21.445156 },
    { lat: 49.309969, lng: 21.445172 },
    { lat: 49.310001, lng: 21.445193 },
    { lat: 49.310032, lng: 21.445264 },
    { lat: 49.310101, lng: 21.44529 },
    { lat: 49.310181, lng: 21.445355 },
    { lat: 49.310224, lng: 21.445428 },
    { lat: 49.310256, lng: 21.445451 },
    { lat: 49.310336, lng: 21.445582 },
    { lat: 49.31041, lng: 21.445652 },
    { lat: 49.310427, lng: 21.44567 },
    { lat: 49.310309, lng: 21.445879 },
    { lat: 49.3105505, lng: 21.4460992 },
    { lat: 49.3111343, lng: 21.4473019 },
    { lat: 49.3114403, lng: 21.4474547 },
    { lat: 49.3119987, lng: 21.448058 },
    { lat: 49.3122152, lng: 21.4480755 },
    { lat: 49.3124735, lng: 21.4487583 },
    { lat: 49.31286, lng: 21.4494822 },
    { lat: 49.3130997, lng: 21.4501085 },
    { lat: 49.3137259, lng: 21.4512216 },
    { lat: 49.3138416, lng: 21.4513367 },
    { lat: 49.3139804, lng: 21.4516616 },
    { lat: 49.3144111, lng: 21.4521594 },
    { lat: 49.3147274, lng: 21.452909 },
    { lat: 49.314703, lng: 21.4529622 },
    { lat: 49.3149568, lng: 21.4537997 },
    { lat: 49.3150343, lng: 21.4542315 },
    { lat: 49.315288, lng: 21.4548288 },
    { lat: 49.3158108, lng: 21.4558404 },
    { lat: 49.3157596, lng: 21.4558961 },
    { lat: 49.3160898, lng: 21.4564285 },
    { lat: 49.3161827, lng: 21.4567219 },
    { lat: 49.3163261, lng: 21.4569296 },
    { lat: 49.317718, lng: 21.4614351 },
    { lat: 49.3187876, lng: 21.4631511 },
    { lat: 49.3196864, lng: 21.4638937 },
    { lat: 49.3206331, lng: 21.4677934 },
    { lat: 49.3215161, lng: 21.4685711 },
    { lat: 49.3223512, lng: 21.4689366 },
    { lat: 49.3223718, lng: 21.468946 },
    { lat: 49.3227417, lng: 21.4686666 },
    { lat: 49.3229179, lng: 21.4684163 },
    { lat: 49.3234429, lng: 21.4681297 },
    { lat: 49.3234837, lng: 21.46767 },
    { lat: 49.323665, lng: 21.4673166 },
    { lat: 49.3239431, lng: 21.4672496 },
    { lat: 49.3241831, lng: 21.4668714 },
    { lat: 49.324511, lng: 21.4667733 },
    { lat: 49.3248349, lng: 21.4670422 },
    { lat: 49.3251368, lng: 21.4668578 },
    { lat: 49.3262605, lng: 21.4678326 },
    { lat: 49.3266874, lng: 21.4677335 },
    { lat: 49.3270173, lng: 21.4689196 },
    { lat: 49.3273798, lng: 21.468852 },
    { lat: 49.327174, lng: 21.4674401 },
    { lat: 49.3273753, lng: 21.4670435 },
    { lat: 49.328793, lng: 21.4669352 },
    { lat: 49.3299128, lng: 21.4662475 },
    { lat: 49.33197, lng: 21.4618142 },
    { lat: 49.3317576, lng: 21.45982 },
    { lat: 49.3319762, lng: 21.4597151 },
    { lat: 49.3321501, lng: 21.4597799 },
    { lat: 49.332802, lng: 21.4595592 },
    { lat: 49.333333, lng: 21.4592514 },
    { lat: 49.3336612, lng: 21.4589689 },
    { lat: 49.3339687, lng: 21.4592072 },
    { lat: 49.3354044, lng: 21.4606703 },
    { lat: 49.3362588, lng: 21.4625254 },
  ],
  Kečkovce: [
    { lat: 49.3855197, lng: 21.5389085 },
    { lat: 49.3861333, lng: 21.5377664 },
    { lat: 49.387117, lng: 21.536827 },
    { lat: 49.3881217, lng: 21.5360625 },
    { lat: 49.3888099, lng: 21.5359008 },
    { lat: 49.3889512, lng: 21.5358027 },
    { lat: 49.3891722, lng: 21.5352627 },
    { lat: 49.3897164, lng: 21.5346108 },
    { lat: 49.3902367, lng: 21.5342298 },
    { lat: 49.3908079, lng: 21.5335432 },
    { lat: 49.3910718, lng: 21.5329668 },
    { lat: 49.3918945, lng: 21.5319786 },
    { lat: 49.3924132, lng: 21.5311008 },
    { lat: 49.3928624, lng: 21.5304695 },
    { lat: 49.3940777, lng: 21.5289262 },
    { lat: 49.3959568, lng: 21.5272289 },
    { lat: 49.3968571, lng: 21.5263192 },
    { lat: 49.3970578, lng: 21.5260203 },
    { lat: 49.3980252, lng: 21.5251017 },
    { lat: 49.3981715, lng: 21.5249035 },
    { lat: 49.3987946, lng: 21.5244961 },
    { lat: 49.4001349, lng: 21.5240368 },
    { lat: 49.4003238, lng: 21.5240731 },
    { lat: 49.4005489, lng: 21.5239975 },
    { lat: 49.4005492, lng: 21.5239307 },
    { lat: 49.4011631, lng: 21.5241184 },
    { lat: 49.4013198, lng: 21.5238895 },
    { lat: 49.4020397, lng: 21.5248091 },
    { lat: 49.4033369, lng: 21.5255697 },
    { lat: 49.4038652, lng: 21.5253548 },
    { lat: 49.405034, lng: 21.5253166 },
    { lat: 49.4055253, lng: 21.5252248 },
    { lat: 49.4057492, lng: 21.5255069 },
    { lat: 49.4059016, lng: 21.5263255 },
    { lat: 49.406425, lng: 21.5270557 },
    { lat: 49.4070659, lng: 21.5273236 },
    { lat: 49.4075697, lng: 21.5271552 },
    { lat: 49.4077276, lng: 21.5285384 },
    { lat: 49.40817, lng: 21.5291815 },
    { lat: 49.4088318, lng: 21.5276754 },
    { lat: 49.4090256, lng: 21.5275676 },
    { lat: 49.4091913, lng: 21.5275429 },
    { lat: 49.4091707, lng: 21.5276086 },
    { lat: 49.4085257, lng: 21.5307558 },
    { lat: 49.4088646, lng: 21.5308469 },
    { lat: 49.4094733, lng: 21.5306047 },
    { lat: 49.40987, lng: 21.5303094 },
    { lat: 49.410285, lng: 21.5298683 },
    { lat: 49.4106096, lng: 21.5297262 },
    { lat: 49.4110517, lng: 21.5297303 },
    { lat: 49.4111215, lng: 21.5295331 },
    { lat: 49.4121853, lng: 21.5287591 },
    { lat: 49.412737, lng: 21.5286214 },
    { lat: 49.4131461, lng: 21.5286092 },
    { lat: 49.4138076, lng: 21.5289694 },
    { lat: 49.4143889, lng: 21.5291969 },
    { lat: 49.4149667, lng: 21.5295421 },
    { lat: 49.4151817, lng: 21.5292663 },
    { lat: 49.4160848, lng: 21.5284974 },
    { lat: 49.4161994, lng: 21.5282123 },
    { lat: 49.4169065, lng: 21.5279174 },
    { lat: 49.4171934, lng: 21.527282 },
    { lat: 49.4174084, lng: 21.5264863 },
    { lat: 49.4176449, lng: 21.525937 },
    { lat: 49.4182127, lng: 21.5253657 },
    { lat: 49.4186566, lng: 21.5250737 },
    { lat: 49.4192171, lng: 21.5244841 },
    { lat: 49.4194504, lng: 21.5240972 },
    { lat: 49.419937, lng: 21.523541 },
    { lat: 49.4199333, lng: 21.523531 },
    { lat: 49.419745, lng: 21.523217 },
    { lat: 49.419046, lng: 21.522035 },
    { lat: 49.4190415, lng: 21.5220242 },
    { lat: 49.418977, lng: 21.521268 },
    { lat: 49.418972, lng: 21.521203 },
    { lat: 49.418965, lng: 21.52113 },
    { lat: 49.4189, lng: 21.520417 },
    { lat: 49.418866, lng: 21.51973 },
    { lat: 49.4188478, lng: 21.5196543 },
    { lat: 49.4186138, lng: 21.5187524 },
    { lat: 49.418361, lng: 21.518227 },
    { lat: 49.4178027, lng: 21.5170484 },
    { lat: 49.418011, lng: 21.5163069 },
    { lat: 49.4181083, lng: 21.5157677 },
    { lat: 49.418592, lng: 21.5140676 },
    { lat: 49.4191411, lng: 21.5130196 },
    { lat: 49.4195153, lng: 21.5120798 },
    { lat: 49.4197476, lng: 21.5111627 },
    { lat: 49.42039, lng: 21.5104733 },
    { lat: 49.421011, lng: 21.509612 },
    { lat: 49.4210249, lng: 21.5095966 },
    { lat: 49.421312, lng: 21.509153 },
    { lat: 49.4216295, lng: 21.5086656 },
    { lat: 49.42167, lng: 21.508595 },
    { lat: 49.4223579, lng: 21.5074036 },
    { lat: 49.4232759, lng: 21.5061357 },
    { lat: 49.4238392, lng: 21.5051522 },
    { lat: 49.4244607, lng: 21.5042432 },
    { lat: 49.4248446, lng: 21.5030708 },
    { lat: 49.424847, lng: 21.503057 },
    { lat: 49.425146, lng: 21.5022941 },
    { lat: 49.425728, lng: 21.501104 },
    { lat: 49.4264064, lng: 21.4997182 },
    { lat: 49.4270113, lng: 21.4991101 },
    { lat: 49.4274704, lng: 21.4988931 },
    { lat: 49.428236, lng: 21.497721 },
    { lat: 49.4282458, lng: 21.4977089 },
    { lat: 49.428936, lng: 21.496693 },
    { lat: 49.4296084, lng: 21.4957048 },
    { lat: 49.429561, lng: 21.49526 },
    { lat: 49.429497, lng: 21.494675 },
    { lat: 49.429433, lng: 21.493492 },
    { lat: 49.429493, lng: 21.49267 },
    { lat: 49.4294959, lng: 21.4926584 },
    { lat: 49.429357, lng: 21.491459 },
    { lat: 49.4292458, lng: 21.4905344 },
    { lat: 49.429237, lng: 21.49046 },
    { lat: 49.4283023, lng: 21.491461 },
    { lat: 49.4279372, lng: 21.4925092 },
    { lat: 49.4273078, lng: 21.4935596 },
    { lat: 49.4265591, lng: 21.4943309 },
    { lat: 49.4261931, lng: 21.4931639 },
    { lat: 49.4249172, lng: 21.4948189 },
    { lat: 49.4223977, lng: 21.495823 },
    { lat: 49.4229226, lng: 21.4967586 },
    { lat: 49.4228632, lng: 21.4973967 },
    { lat: 49.42352, lng: 21.4980308 },
    { lat: 49.4214934, lng: 21.4995569 },
    { lat: 49.4194016, lng: 21.5005245 },
    { lat: 49.4180621, lng: 21.4987927 },
    { lat: 49.4175545, lng: 21.4989046 },
    { lat: 49.4160046, lng: 21.4989892 },
    { lat: 49.4151379, lng: 21.4987767 },
    { lat: 49.4141116, lng: 21.4976507 },
    { lat: 49.413519, lng: 21.4971855 },
    { lat: 49.4133173, lng: 21.4971296 },
    { lat: 49.4117822, lng: 21.4959245 },
    { lat: 49.4110233, lng: 21.4967281 },
    { lat: 49.4088154, lng: 21.4961434 },
    { lat: 49.4067618, lng: 21.4952524 },
    { lat: 49.4062707, lng: 21.4957876 },
    { lat: 49.4045746, lng: 21.4988914 },
    { lat: 49.4025973, lng: 21.5005631 },
    { lat: 49.4011578, lng: 21.5014974 },
    { lat: 49.399871, lng: 21.5019291 },
    { lat: 49.3987004, lng: 21.5019879 },
    { lat: 49.3977972, lng: 21.5028257 },
    { lat: 49.3973438, lng: 21.5041767 },
    { lat: 49.3954004, lng: 21.5041482 },
    { lat: 49.3944479, lng: 21.5042015 },
    { lat: 49.3932468, lng: 21.5040918 },
    { lat: 49.3918016, lng: 21.50427 },
    { lat: 49.3906387, lng: 21.5049085 },
    { lat: 49.3887789, lng: 21.5047766 },
    { lat: 49.3883763, lng: 21.5046304 },
    { lat: 49.3869533, lng: 21.504624 },
    { lat: 49.3861813, lng: 21.5047732 },
    { lat: 49.3839574, lng: 21.5037094 },
    { lat: 49.3826647, lng: 21.5025569 },
    { lat: 49.3819067, lng: 21.501288 },
    { lat: 49.3774506, lng: 21.5002572 },
    { lat: 49.3735633, lng: 21.4995992 },
    { lat: 49.3735173, lng: 21.499647 },
    { lat: 49.3731034, lng: 21.4995313 },
    { lat: 49.3727632, lng: 21.4992731 },
    { lat: 49.3726763, lng: 21.4993431 },
    { lat: 49.3724431, lng: 21.4992919 },
    { lat: 49.3697555, lng: 21.5004769 },
    { lat: 49.3695144, lng: 21.5007774 },
    { lat: 49.3697395, lng: 21.5037178 },
    { lat: 49.3700549, lng: 21.5040895 },
    { lat: 49.3704613, lng: 21.5046975 },
    { lat: 49.3704777, lng: 21.5049917 },
    { lat: 49.3705542, lng: 21.5052717 },
    { lat: 49.3704711, lng: 21.5056328 },
    { lat: 49.3704707, lng: 21.5061751 },
    { lat: 49.3699596, lng: 21.5062412 },
    { lat: 49.3696233, lng: 21.5064039 },
    { lat: 49.3693075, lng: 21.5062564 },
    { lat: 49.3689619, lng: 21.5058487 },
    { lat: 49.3687688, lng: 21.5058215 },
    { lat: 49.368081, lng: 21.5063632 },
    { lat: 49.3678334, lng: 21.5064038 },
    { lat: 49.3676653, lng: 21.5063024 },
    { lat: 49.3669876, lng: 21.509265 },
    { lat: 49.3665096, lng: 21.5117732 },
    { lat: 49.3665185, lng: 21.5120029 },
    { lat: 49.3666884, lng: 21.5121281 },
    { lat: 49.3666889, lng: 21.5124183 },
    { lat: 49.366595, lng: 21.51284 },
    { lat: 49.3666093, lng: 21.5129462 },
    { lat: 49.3661859, lng: 21.5135287 },
    { lat: 49.3662304, lng: 21.5137532 },
    { lat: 49.3657639, lng: 21.51492 },
    { lat: 49.3655503, lng: 21.5148847 },
    { lat: 49.3655265, lng: 21.5149949 },
    { lat: 49.3654986, lng: 21.5158757 },
    { lat: 49.365527, lng: 21.5162874 },
    { lat: 49.3654664, lng: 21.516329 },
    { lat: 49.3656504, lng: 21.5170333 },
    { lat: 49.3657416, lng: 21.5176683 },
    { lat: 49.3658551, lng: 21.5181033 },
    { lat: 49.3660321, lng: 21.5181969 },
    { lat: 49.3662532, lng: 21.5181368 },
    { lat: 49.3664558, lng: 21.5181812 },
    { lat: 49.3665777, lng: 21.5182749 },
    { lat: 49.3667384, lng: 21.5194243 },
    { lat: 49.3667137, lng: 21.5204615 },
    { lat: 49.3669925, lng: 21.5218519 },
    { lat: 49.3670749, lng: 21.5225752 },
    { lat: 49.3670675, lng: 21.5229579 },
    { lat: 49.3671487, lng: 21.5230035 },
    { lat: 49.3671454, lng: 21.5231203 },
    { lat: 49.3672762, lng: 21.5232932 },
    { lat: 49.3672755, lng: 21.5235123 },
    { lat: 49.3675169, lng: 21.5237728 },
    { lat: 49.3681521, lng: 21.524874 },
    { lat: 49.3679361, lng: 21.5269093 },
    { lat: 49.3678277, lng: 21.5274565 },
    { lat: 49.3678999, lng: 21.5275412 },
    { lat: 49.3677836, lng: 21.5278496 },
    { lat: 49.368066, lng: 21.5283673 },
    { lat: 49.3681253, lng: 21.5292744 },
    { lat: 49.3681459, lng: 21.5296052 },
    { lat: 49.3684049, lng: 21.530532 },
    { lat: 49.3692056, lng: 21.5309224 },
    { lat: 49.3696579, lng: 21.5312914 },
    { lat: 49.3705668, lng: 21.5318069 },
    { lat: 49.3716751, lng: 21.5315359 },
    { lat: 49.3725981, lng: 21.5311563 },
    { lat: 49.3738757, lng: 21.5309882 },
    { lat: 49.3750675, lng: 21.5310042 },
    { lat: 49.3757899, lng: 21.5302951 },
    { lat: 49.3769129, lng: 21.5310644 },
    { lat: 49.3785096, lng: 21.5315576 },
    { lat: 49.3817509, lng: 21.5322102 },
    { lat: 49.3830654, lng: 21.5323163 },
    { lat: 49.3835164, lng: 21.5329892 },
    { lat: 49.3838546, lng: 21.5344953 },
    { lat: 49.3846888, lng: 21.5362385 },
    { lat: 49.3851562, lng: 21.5375069 },
    { lat: 49.3855197, lng: 21.5389085 },
  ],
  Bodružal: [
    { lat: 49.3478599, lng: 21.7338152 },
    { lat: 49.3478419, lng: 21.7337766 },
    { lat: 49.3479124, lng: 21.7337567 },
    { lat: 49.3481132, lng: 21.734034 },
    { lat: 49.3486002, lng: 21.7343747 },
    { lat: 49.3490062, lng: 21.7343344 },
    { lat: 49.3491926, lng: 21.7339744 },
    { lat: 49.3495372, lng: 21.733733 },
    { lat: 49.3498323, lng: 21.7337512 },
    { lat: 49.3501877, lng: 21.7332414 },
    { lat: 49.3503036, lng: 21.7329456 },
    { lat: 49.3504018, lng: 21.7321032 },
    { lat: 49.3506559, lng: 21.7309986 },
    { lat: 49.3507582, lng: 21.7300989 },
    { lat: 49.351588, lng: 21.7285159 },
    { lat: 49.3519897, lng: 21.7279224 },
    { lat: 49.3522718, lng: 21.7277076 },
    { lat: 49.3526393, lng: 21.7273103 },
    { lat: 49.3532085, lng: 21.7262925 },
    { lat: 49.3534857, lng: 21.7259296 },
    { lat: 49.3540892, lng: 21.7253556 },
    { lat: 49.3547329, lng: 21.7245186 },
    { lat: 49.3551569, lng: 21.7243476 },
    { lat: 49.355623, lng: 21.7243609 },
    { lat: 49.3562078, lng: 21.7241925 },
    { lat: 49.3568209, lng: 21.7234732 },
    { lat: 49.3573624, lng: 21.7231462 },
    { lat: 49.357677, lng: 21.722799 },
    { lat: 49.3579547, lng: 21.7226201 },
    { lat: 49.358903, lng: 21.7216103 },
    { lat: 49.3591235, lng: 21.7212231 },
    { lat: 49.3597063, lng: 21.7208116 },
    { lat: 49.3624668, lng: 21.7209413 },
    { lat: 49.3629668, lng: 21.7196912 },
    { lat: 49.3637611, lng: 21.7189373 },
    { lat: 49.3642571, lng: 21.7191439 },
    { lat: 49.3645155, lng: 21.7191827 },
    { lat: 49.3659883, lng: 21.7188576 },
    { lat: 49.3655579, lng: 21.7176396 },
    { lat: 49.3650182, lng: 21.7157872 },
    { lat: 49.3646413, lng: 21.7148922 },
    { lat: 49.3644328, lng: 21.7138225 },
    { lat: 49.3644102, lng: 21.7135218 },
    { lat: 49.3645935, lng: 21.7128803 },
    { lat: 49.3645026, lng: 21.7125536 },
    { lat: 49.3646247, lng: 21.7121909 },
    { lat: 49.3647261, lng: 21.7110593 },
    { lat: 49.3661956, lng: 21.7088744 },
    { lat: 49.3664398, lng: 21.7084607 },
    { lat: 49.3667747, lng: 21.7076898 },
    { lat: 49.3675939, lng: 21.7063939 },
    { lat: 49.3677549, lng: 21.7057212 },
    { lat: 49.3680137, lng: 21.7042537 },
    { lat: 49.367919, lng: 21.7032743 },
    { lat: 49.3677931, lng: 21.7031032 },
    { lat: 49.3681455, lng: 21.7013022 },
    { lat: 49.3682404, lng: 21.7005679 },
    { lat: 49.3679111, lng: 21.7000266 },
    { lat: 49.366841, lng: 21.6987019 },
    { lat: 49.3660974, lng: 21.6985308 },
    { lat: 49.3656646, lng: 21.698502 },
    { lat: 49.3654389, lng: 21.6983457 },
    { lat: 49.3652448, lng: 21.6981118 },
    { lat: 49.3648552, lng: 21.6978839 },
    { lat: 49.3646775, lng: 21.697672 },
    { lat: 49.3643424, lng: 21.6975071 },
    { lat: 49.3639237, lng: 21.6970801 },
    { lat: 49.3629967, lng: 21.6965261 },
    { lat: 49.3624279, lng: 21.6960756 },
    { lat: 49.3618732, lng: 21.6954052 },
    { lat: 49.360565, lng: 21.6940113 },
    { lat: 49.3604964, lng: 21.6937998 },
    { lat: 49.3601192, lng: 21.6934958 },
    { lat: 49.3594905, lng: 21.6932905 },
    { lat: 49.3593579, lng: 21.693082 },
    { lat: 49.3593986, lng: 21.692834 },
    { lat: 49.3593713, lng: 21.6927029 },
    { lat: 49.3592338, lng: 21.6924799 },
    { lat: 49.3590877, lng: 21.6920239 },
    { lat: 49.3587094, lng: 21.6916291 },
    { lat: 49.3586652, lng: 21.6914471 },
    { lat: 49.3585217, lng: 21.6914232 },
    { lat: 49.3584563, lng: 21.6912967 },
    { lat: 49.357853, lng: 21.6908168 },
    { lat: 49.3576663, lng: 21.6905672 },
    { lat: 49.357155, lng: 21.6904604 },
    { lat: 49.3568033, lng: 21.6900105 },
    { lat: 49.356616, lng: 21.689612 },
    { lat: 49.3556566, lng: 21.6886911 },
    { lat: 49.3550132, lng: 21.6883191 },
    { lat: 49.354649, lng: 21.6884495 },
    { lat: 49.3542494, lng: 21.6884367 },
    { lat: 49.3541644, lng: 21.6883367 },
    { lat: 49.353777, lng: 21.6885248 },
    { lat: 49.3530871, lng: 21.6880948 },
    { lat: 49.3522888, lng: 21.6877267 },
    { lat: 49.3518791, lng: 21.6873823 },
    { lat: 49.3515339, lng: 21.68701 },
    { lat: 49.3504609, lng: 21.6888197 },
    { lat: 49.3500063, lng: 21.6896914 },
    { lat: 49.3496019, lng: 21.6903034 },
    { lat: 49.3492067, lng: 21.6908348 },
    { lat: 49.3487481, lng: 21.6911349 },
    { lat: 49.3481738, lng: 21.6916608 },
    { lat: 49.3472957, lng: 21.6928895 },
    { lat: 49.3472813, lng: 21.6931967 },
    { lat: 49.3471104, lng: 21.6937407 },
    { lat: 49.3468611, lng: 21.6942653 },
    { lat: 49.3453234, lng: 21.6959866 },
    { lat: 49.3449896, lng: 21.6965032 },
    { lat: 49.344398, lng: 21.6977668 },
    { lat: 49.3440411, lng: 21.6983479 },
    { lat: 49.3433447, lng: 21.6991368 },
    { lat: 49.3431436, lng: 21.6996909 },
    { lat: 49.3422259, lng: 21.700631 },
    { lat: 49.3417812, lng: 21.7006191 },
    { lat: 49.3415449, lng: 21.7007346 },
    { lat: 49.3414048, lng: 21.7009273 },
    { lat: 49.3409201, lng: 21.7010922 },
    { lat: 49.3406343, lng: 21.7013434 },
    { lat: 49.3404295, lng: 21.7015918 },
    { lat: 49.340113, lng: 21.7021227 },
    { lat: 49.3398485, lng: 21.7027929 },
    { lat: 49.339704, lng: 21.703499 },
    { lat: 49.337765, lng: 21.706552 },
    { lat: 49.33773, lng: 21.706608 },
    { lat: 49.33695, lng: 21.707835 },
    { lat: 49.336314, lng: 21.708731 },
    { lat: 49.335608, lng: 21.709537 },
    { lat: 49.3362252, lng: 21.7107168 },
    { lat: 49.3364013, lng: 21.7115856 },
    { lat: 49.3367663, lng: 21.7124148 },
    { lat: 49.3371773, lng: 21.7136614 },
    { lat: 49.3376326, lng: 21.7140723 },
    { lat: 49.3378818, lng: 21.7145989 },
    { lat: 49.3378969, lng: 21.7148747 },
    { lat: 49.3379905, lng: 21.7149648 },
    { lat: 49.3379786, lng: 21.7151985 },
    { lat: 49.338196, lng: 21.7156258 },
    { lat: 49.3385518, lng: 21.7165703 },
    { lat: 49.3386913, lng: 21.7172535 },
    { lat: 49.3386137, lng: 21.7181381 },
    { lat: 49.338708, lng: 21.718767 },
    { lat: 49.3391141, lng: 21.7200003 },
    { lat: 49.3401328, lng: 21.7237595 },
    { lat: 49.340458, lng: 21.7247334 },
    { lat: 49.3408894, lng: 21.7258674 },
    { lat: 49.3409747, lng: 21.7260252 },
    { lat: 49.3411424, lng: 21.7261562 },
    { lat: 49.3414, lng: 21.7266243 },
    { lat: 49.3415923, lng: 21.7272635 },
    { lat: 49.3418977, lng: 21.7276265 },
    { lat: 49.3421135, lng: 21.7277473 },
    { lat: 49.3420887, lng: 21.7278722 },
    { lat: 49.342671, lng: 21.7290053 },
    { lat: 49.3428647, lng: 21.7289691 },
    { lat: 49.3430049, lng: 21.7293399 },
    { lat: 49.342963, lng: 21.7294922 },
    { lat: 49.3431445, lng: 21.7299394 },
    { lat: 49.3432745, lng: 21.730176 },
    { lat: 49.3433472, lng: 21.7301628 },
    { lat: 49.3434386, lng: 21.7302931 },
    { lat: 49.3433977, lng: 21.7304133 },
    { lat: 49.3435077, lng: 21.7306175 },
    { lat: 49.3436173, lng: 21.7306327 },
    { lat: 49.3437049, lng: 21.7308015 },
    { lat: 49.3436793, lng: 21.7308936 },
    { lat: 49.3439212, lng: 21.7312787 },
    { lat: 49.3442382, lng: 21.7316956 },
    { lat: 49.3446628, lng: 21.7320131 },
    { lat: 49.3455397, lng: 21.7332286 },
    { lat: 49.3456707, lng: 21.7335921 },
    { lat: 49.3459725, lng: 21.7338977 },
    { lat: 49.346204, lng: 21.734273 },
    { lat: 49.3478365, lng: 21.7338206 },
    { lat: 49.3478599, lng: 21.7338152 },
  ],
  KrajnáPoľana: [
    { lat: 49.3727623, lng: 21.6766768 },
    { lat: 49.3720781, lng: 21.6766695 },
    { lat: 49.3714161, lng: 21.6764788 },
    { lat: 49.3711924, lng: 21.6763561 },
    { lat: 49.3708878, lng: 21.6758631 },
    { lat: 49.3706227, lng: 21.6757084 },
    { lat: 49.3706279, lng: 21.6756474 },
    { lat: 49.3694735, lng: 21.6750987 },
    { lat: 49.3689207, lng: 21.6747488 },
    { lat: 49.3685603, lng: 21.6746867 },
    { lat: 49.3685596, lng: 21.6747373 },
    { lat: 49.3683797, lng: 21.6747447 },
    { lat: 49.3682986, lng: 21.674822 },
    { lat: 49.3681201, lng: 21.674795 },
    { lat: 49.3671346, lng: 21.6742884 },
    { lat: 49.3662039, lng: 21.6740194 },
    { lat: 49.3652385, lng: 21.6739717 },
    { lat: 49.3648548, lng: 21.6740941 },
    { lat: 49.3641474, lng: 21.6738828 },
    { lat: 49.3637315, lng: 21.6739845 },
    { lat: 49.3634428, lng: 21.6739057 },
    { lat: 49.3626915, lng: 21.674 },
    { lat: 49.3623057, lng: 21.6741243 },
    { lat: 49.360935, lng: 21.6737189 },
    { lat: 49.3605441, lng: 21.673662 },
    { lat: 49.3594061, lng: 21.6730432 },
    { lat: 49.3593985, lng: 21.6728466 },
    { lat: 49.3590638, lng: 21.6727883 },
    { lat: 49.3581197, lng: 21.6720212 },
    { lat: 49.357332, lng: 21.6708358 },
    { lat: 49.3567463, lng: 21.6720614 },
    { lat: 49.3563799, lng: 21.6719449 },
    { lat: 49.3559629, lng: 21.6721715 },
    { lat: 49.3560315, lng: 21.6724605 },
    { lat: 49.3554838, lng: 21.6733831 },
    { lat: 49.3554413, lng: 21.6737632 },
    { lat: 49.3547324, lng: 21.6748035 },
    { lat: 49.3544193, lng: 21.6751238 },
    { lat: 49.3543542, lng: 21.6754198 },
    { lat: 49.3541664, lng: 21.6756752 },
    { lat: 49.3538454, lng: 21.6762818 },
    { lat: 49.3539616, lng: 21.6767962 },
    { lat: 49.353809, lng: 21.6772746 },
    { lat: 49.3539295, lng: 21.679582 },
    { lat: 49.3537955, lng: 21.6803695 },
    { lat: 49.3538679, lng: 21.6805991 },
    { lat: 49.3533259, lng: 21.6826145 },
    { lat: 49.3528489, lng: 21.6835729 },
    { lat: 49.3526001, lng: 21.6850361 },
    { lat: 49.3525104, lng: 21.6850042 },
    { lat: 49.3521555, lng: 21.6859026 },
    { lat: 49.3519385, lng: 21.6861514 },
    { lat: 49.3517612, lng: 21.6862131 },
    { lat: 49.3515544, lng: 21.6866832 },
    { lat: 49.3515339, lng: 21.68701 },
    { lat: 49.3518791, lng: 21.6873823 },
    { lat: 49.3522888, lng: 21.6877267 },
    { lat: 49.3530871, lng: 21.6880948 },
    { lat: 49.353777, lng: 21.6885248 },
    { lat: 49.3541644, lng: 21.6883367 },
    { lat: 49.3542494, lng: 21.6884367 },
    { lat: 49.354649, lng: 21.6884495 },
    { lat: 49.3550132, lng: 21.6883191 },
    { lat: 49.3556566, lng: 21.6886911 },
    { lat: 49.356616, lng: 21.689612 },
    { lat: 49.3568033, lng: 21.6900105 },
    { lat: 49.357155, lng: 21.6904604 },
    { lat: 49.3576663, lng: 21.6905672 },
    { lat: 49.357853, lng: 21.6908168 },
    { lat: 49.3584563, lng: 21.6912967 },
    { lat: 49.3585217, lng: 21.6914232 },
    { lat: 49.3586652, lng: 21.6914471 },
    { lat: 49.3587094, lng: 21.6916291 },
    { lat: 49.3590877, lng: 21.6920239 },
    { lat: 49.3592338, lng: 21.6924799 },
    { lat: 49.3593713, lng: 21.6927029 },
    { lat: 49.3593986, lng: 21.692834 },
    { lat: 49.3593579, lng: 21.693082 },
    { lat: 49.3594905, lng: 21.6932905 },
    { lat: 49.3601192, lng: 21.6934958 },
    { lat: 49.3604964, lng: 21.6937998 },
    { lat: 49.360565, lng: 21.6940113 },
    { lat: 49.3618732, lng: 21.6954052 },
    { lat: 49.3624279, lng: 21.6960756 },
    { lat: 49.3629967, lng: 21.6965261 },
    { lat: 49.3639237, lng: 21.6970801 },
    { lat: 49.3643424, lng: 21.6975071 },
    { lat: 49.3646775, lng: 21.697672 },
    { lat: 49.3648552, lng: 21.6978839 },
    { lat: 49.3652448, lng: 21.6981118 },
    { lat: 49.3654389, lng: 21.6983457 },
    { lat: 49.3656646, lng: 21.698502 },
    { lat: 49.3660974, lng: 21.6985308 },
    { lat: 49.366841, lng: 21.6987019 },
    { lat: 49.3679111, lng: 21.7000266 },
    { lat: 49.3682404, lng: 21.7005679 },
    { lat: 49.368226, lng: 21.699939 },
    { lat: 49.3683891, lng: 21.6985082 },
    { lat: 49.3682972, lng: 21.6983071 },
    { lat: 49.3683009, lng: 21.6975641 },
    { lat: 49.3681479, lng: 21.6968912 },
    { lat: 49.3684303, lng: 21.6961769 },
    { lat: 49.3686366, lng: 21.6952613 },
    { lat: 49.3686811, lng: 21.6947843 },
    { lat: 49.3683812, lng: 21.6936633 },
    { lat: 49.3683468, lng: 21.6932226 },
    { lat: 49.3679922, lng: 21.6920747 },
    { lat: 49.3680097, lng: 21.6911771 },
    { lat: 49.3679692, lng: 21.6908346 },
    { lat: 49.368051, lng: 21.6905725 },
    { lat: 49.36822, lng: 21.6891102 },
    { lat: 49.3684372, lng: 21.6889125 },
    { lat: 49.3687294, lng: 21.6881026 },
    { lat: 49.3693147, lng: 21.687697 },
    { lat: 49.3697194, lng: 21.6871439 },
    { lat: 49.3704726, lng: 21.6864548 },
    { lat: 49.370691, lng: 21.6863793 },
    { lat: 49.3708578, lng: 21.6861396 },
    { lat: 49.3709309, lng: 21.68593 },
    { lat: 49.3710967, lng: 21.6857384 },
    { lat: 49.3719377, lng: 21.6853885 },
    { lat: 49.3723753, lng: 21.6855584 },
    { lat: 49.3725773, lng: 21.6852432 },
    { lat: 49.3728944, lng: 21.6854492 },
    { lat: 49.3734891, lng: 21.6845953 },
    { lat: 49.3736288, lng: 21.6839765 },
    { lat: 49.3739146, lng: 21.6830586 },
    { lat: 49.3742392, lng: 21.6826653 },
    { lat: 49.3744814, lng: 21.6820021 },
    { lat: 49.3746163, lng: 21.6812507 },
    { lat: 49.3746334, lng: 21.6809233 },
    { lat: 49.3745051, lng: 21.6800864 },
    { lat: 49.3740128, lng: 21.6788786 },
    { lat: 49.373437, lng: 21.6781327 },
    { lat: 49.3732495, lng: 21.6774828 },
    { lat: 49.3729888, lng: 21.6771127 },
    { lat: 49.3727623, lng: 21.6766768 },
  ],
};

export default UnitsSvidník;
