const UnitsSnina = {
  Pichne: [
    { lat: 49.024633, lng: 22.11511 },
    { lat: 49.0260403, lng: 22.1160026 },
    { lat: 49.0270429, lng: 22.1175711 },
    { lat: 49.0274332, lng: 22.1183411 },
    { lat: 49.0282958, lng: 22.118824 },
    { lat: 49.0286292, lng: 22.1184039 },
    { lat: 49.0300115, lng: 22.1197101 },
    { lat: 49.0298953, lng: 22.1200841 },
    { lat: 49.0298471, lng: 22.1208175 },
    { lat: 49.0296856, lng: 22.1215151 },
    { lat: 49.0293534, lng: 22.1221674 },
    { lat: 49.029126, lng: 22.1233764 },
    { lat: 49.0287943, lng: 22.1238587 },
    { lat: 49.0285389, lng: 22.1244405 },
    { lat: 49.0284755, lng: 22.1250987 },
    { lat: 49.0273379, lng: 22.1267268 },
    { lat: 49.0271337, lng: 22.1273206 },
    { lat: 49.0266399, lng: 22.1281929 },
    { lat: 49.025995, lng: 22.128902 },
    { lat: 49.0254652, lng: 22.1290021 },
    { lat: 49.0250163, lng: 22.1289477 },
    { lat: 49.0246397, lng: 22.1297309 },
    { lat: 49.0239542, lng: 22.1304845 },
    { lat: 49.0236487, lng: 22.1306965 },
    { lat: 49.0226844, lng: 22.1307242 },
    { lat: 49.0229681, lng: 22.1293271 },
    { lat: 49.0230931, lng: 22.128905 },
    { lat: 49.0228281, lng: 22.1286774 },
    { lat: 49.0224725, lng: 22.1290548 },
    { lat: 49.0220219, lng: 22.1309687 },
    { lat: 49.021974, lng: 22.1325796 },
    { lat: 49.0208708, lng: 22.1339575 },
    { lat: 49.0199823, lng: 22.1346293 },
    { lat: 49.0196752, lng: 22.134344 },
    { lat: 49.0198313, lng: 22.1351104 },
    { lat: 49.0192042, lng: 22.1356654 },
    { lat: 49.0187695, lng: 22.1364128 },
    { lat: 49.018314, lng: 22.1357334 },
    { lat: 49.0182447, lng: 22.1353662 },
    { lat: 49.0179834, lng: 22.13477 },
    { lat: 49.0176121, lng: 22.1344201 },
    { lat: 49.0171341, lng: 22.134522 },
    { lat: 49.0177929, lng: 22.1357756 },
    { lat: 49.0174585, lng: 22.1366328 },
    { lat: 49.0175591, lng: 22.1369875 },
    { lat: 49.0170604, lng: 22.1372629 },
    { lat: 49.0160782, lng: 22.1374404 },
    { lat: 49.0157764, lng: 22.1381087 },
    { lat: 49.0159471, lng: 22.1411181 },
    { lat: 49.0170393, lng: 22.1423835 },
    { lat: 49.0182711, lng: 22.146201 },
    { lat: 49.0196687, lng: 22.1479369 },
    { lat: 49.0204604, lng: 22.1486652 },
    { lat: 49.0212729, lng: 22.1491186 },
    { lat: 49.0213707, lng: 22.1491125 },
    { lat: 49.0230379, lng: 22.149009 },
    { lat: 49.0239526, lng: 22.1489522 },
    { lat: 49.025215, lng: 22.1486825 },
    { lat: 49.0255445, lng: 22.1482847 },
    { lat: 49.0261312, lng: 22.1480721 },
    { lat: 49.0273387, lng: 22.14825 },
    { lat: 49.0296146, lng: 22.1479807 },
    { lat: 49.0306178, lng: 22.1482696 },
    { lat: 49.0313263, lng: 22.1491548 },
    { lat: 49.0332724, lng: 22.149801 },
    { lat: 49.0341856, lng: 22.1496957 },
    { lat: 49.0356547, lng: 22.1500064 },
    { lat: 49.0384012, lng: 22.1490296 },
    { lat: 49.0393942, lng: 22.1491136 },
    { lat: 49.0410786, lng: 22.1496864 },
    { lat: 49.0433819, lng: 22.1502671 },
    { lat: 49.0440554, lng: 22.1510845 },
    { lat: 49.0454962, lng: 22.1490918 },
    { lat: 49.0470662, lng: 22.1473931 },
    { lat: 49.0478097, lng: 22.1467745 },
    { lat: 49.0495096, lng: 22.1461719 },
    { lat: 49.0504173, lng: 22.1463182 },
    { lat: 49.0529136, lng: 22.145181 },
    { lat: 49.0540791, lng: 22.144511 },
    { lat: 49.054976, lng: 22.1440869 },
    { lat: 49.0555802, lng: 22.1433662 },
    { lat: 49.0575343, lng: 22.1419277 },
    { lat: 49.0588907, lng: 22.1414392 },
    { lat: 49.0617873, lng: 22.1386362 },
    { lat: 49.0633753, lng: 22.1376054 },
    { lat: 49.0642255, lng: 22.1376496 },
    { lat: 49.065259, lng: 22.1378991 },
    { lat: 49.0660516, lng: 22.1381738 },
    { lat: 49.0672949, lng: 22.1380047 },
    { lat: 49.0690501, lng: 22.1370965 },
    { lat: 49.0717722, lng: 22.1346309 },
    { lat: 49.0723206, lng: 22.1337843 },
    { lat: 49.072962, lng: 22.133537 },
    { lat: 49.072072, lng: 22.131562 },
    { lat: 49.07091, lng: 22.12925 },
    { lat: 49.070464, lng: 22.128035 },
    { lat: 49.07008, lng: 22.12699 },
    { lat: 49.069503, lng: 22.125871 },
    { lat: 49.068865, lng: 22.126799 },
    { lat: 49.068733, lng: 22.126357 },
    { lat: 49.068382, lng: 22.125165 },
    { lat: 49.06793, lng: 22.123781 },
    { lat: 49.067426, lng: 22.122532 },
    { lat: 49.067657, lng: 22.121924 },
    { lat: 49.066831, lng: 22.120306 },
    { lat: 49.066109, lng: 22.118636 },
    { lat: 49.065801, lng: 22.118063 },
    { lat: 49.065592, lng: 22.117566 },
    { lat: 49.065364, lng: 22.117391 },
    { lat: 49.065542, lng: 22.117096 },
    { lat: 49.065793, lng: 22.116683 },
    { lat: 49.066043, lng: 22.116108 },
    { lat: 49.066208, lng: 22.115765 },
    { lat: 49.066482, lng: 22.115051 },
    { lat: 49.06661, lng: 22.114169 },
    { lat: 49.066694, lng: 22.113707 },
    { lat: 49.066714, lng: 22.113514 },
    { lat: 49.066814, lng: 22.113158 },
    { lat: 49.066852, lng: 22.112721 },
    { lat: 49.066985, lng: 22.112387 },
    { lat: 49.066966, lng: 22.111724 },
    { lat: 49.066946, lng: 22.111465 },
    { lat: 49.066802, lng: 22.111159 },
    { lat: 49.066631, lng: 22.110681 },
    { lat: 49.066324, lng: 22.11019 },
    { lat: 49.066209, lng: 22.109916 },
    { lat: 49.066041, lng: 22.109864 },
    { lat: 49.065176, lng: 22.109328 },
    { lat: 49.064477, lng: 22.108972 },
    { lat: 49.063166, lng: 22.107976 },
    { lat: 49.06369, lng: 22.107409 },
    { lat: 49.063559, lng: 22.106951 },
    { lat: 49.063483, lng: 22.106683 },
    { lat: 49.063067, lng: 22.105961 },
    { lat: 49.062048, lng: 22.104069 },
    { lat: 49.061459, lng: 22.102892 },
    { lat: 49.061323, lng: 22.10262 },
    { lat: 49.060808, lng: 22.101725 },
    { lat: 49.060305, lng: 22.100848 },
    { lat: 49.059579, lng: 22.099501 },
    { lat: 49.058676, lng: 22.097994 },
    { lat: 49.057855, lng: 22.096665 },
    { lat: 49.057505, lng: 22.096128 },
    { lat: 49.057324, lng: 22.095852 },
    { lat: 49.056182, lng: 22.095158 },
    { lat: 49.055513, lng: 22.095186 },
    { lat: 49.054654, lng: 22.094625 },
    { lat: 49.054105, lng: 22.09463 },
    { lat: 49.053212, lng: 22.094718 },
    { lat: 49.052933, lng: 22.094665 },
    { lat: 49.052843, lng: 22.094648 },
    { lat: 49.052736, lng: 22.094622 },
    { lat: 49.052409, lng: 22.094676 },
    { lat: 49.052215, lng: 22.094708 },
    { lat: 49.051826, lng: 22.095018 },
    { lat: 49.050791, lng: 22.095673 },
    { lat: 49.050555, lng: 22.09572 },
    { lat: 49.050295, lng: 22.095725 },
    { lat: 49.049883, lng: 22.095396 },
    { lat: 49.049324, lng: 22.095227 },
    { lat: 49.049325, lng: 22.095271 },
    { lat: 49.049223, lng: 22.095282 },
    { lat: 49.048584, lng: 22.095572 },
    { lat: 49.048231, lng: 22.095853 },
    { lat: 49.048054, lng: 22.095873 },
    { lat: 49.047813, lng: 22.095948 },
    { lat: 49.047686, lng: 22.09607 },
    { lat: 49.047526, lng: 22.096179 },
    { lat: 49.046776, lng: 22.09633 },
    { lat: 49.0464582, lng: 22.0961903 },
    { lat: 49.0462402, lng: 22.0964587 },
    { lat: 49.046198, lng: 22.096508 },
    { lat: 49.0459047, lng: 22.0966011 },
    { lat: 49.0456223, lng: 22.0966889 },
    { lat: 49.045403, lng: 22.096757 },
    { lat: 49.0451228, lng: 22.096712 },
    { lat: 49.044617, lng: 22.096627 },
    { lat: 49.043938, lng: 22.096331 },
    { lat: 49.043879, lng: 22.096754 },
    { lat: 49.04385, lng: 22.097519 },
    { lat: 49.043589, lng: 22.098209 },
    { lat: 49.043505, lng: 22.09884 },
    { lat: 49.043302, lng: 22.099364 },
    { lat: 49.04307, lng: 22.100142 },
    { lat: 49.042813, lng: 22.10009 },
    { lat: 49.042578, lng: 22.100002 },
    { lat: 49.042383, lng: 22.099694 },
    { lat: 49.042218, lng: 22.099482 },
    { lat: 49.042048, lng: 22.099368 },
    { lat: 49.041849, lng: 22.099089 },
    { lat: 49.041857, lng: 22.098904 },
    { lat: 49.041825, lng: 22.098766 },
    { lat: 49.04181, lng: 22.09875 },
    { lat: 49.041782, lng: 22.098732 },
    { lat: 49.041557, lng: 22.098783 },
    { lat: 49.040622, lng: 22.097231 },
    { lat: 49.040424, lng: 22.096963 },
    { lat: 49.040115, lng: 22.096414 },
    { lat: 49.039863, lng: 22.096046 },
    { lat: 49.039708, lng: 22.095769 },
    { lat: 49.039622, lng: 22.095448 },
    { lat: 49.039546, lng: 22.095206 },
    { lat: 49.039467, lng: 22.09502 },
    { lat: 49.039282, lng: 22.094802 },
    { lat: 49.039103, lng: 22.094633 },
    { lat: 49.038997, lng: 22.094602 },
    { lat: 49.038844, lng: 22.094451 },
    { lat: 49.038727, lng: 22.094271 },
    { lat: 49.038633, lng: 22.094199 },
    { lat: 49.038258, lng: 22.093863 },
    { lat: 49.037362, lng: 22.09234 },
    { lat: 49.037222, lng: 22.092179 },
    { lat: 49.036401, lng: 22.091304 },
    { lat: 49.036389, lng: 22.091292 },
    { lat: 49.036386, lng: 22.091308 },
    { lat: 49.036305, lng: 22.091553 },
    { lat: 49.03623, lng: 22.091754 },
    { lat: 49.036117, lng: 22.092035 },
    { lat: 49.036052, lng: 22.092215 },
    { lat: 49.03606, lng: 22.092573 },
    { lat: 49.036129, lng: 22.092884 },
    { lat: 49.036199, lng: 22.093326 },
    { lat: 49.036192, lng: 22.093458 },
    { lat: 49.036191, lng: 22.09379 },
    { lat: 49.03628, lng: 22.094161 },
    { lat: 49.036376, lng: 22.094823 },
    { lat: 49.03638, lng: 22.095329 },
    { lat: 49.036351, lng: 22.095942 },
    { lat: 49.036312, lng: 22.096268 },
    { lat: 49.036044, lng: 22.096742 },
    { lat: 49.035602, lng: 22.097417 },
    { lat: 49.035342, lng: 22.097637 },
    { lat: 49.03516, lng: 22.097722 },
    { lat: 49.035043, lng: 22.097895 },
    { lat: 49.03496, lng: 22.098087 },
    { lat: 49.034618, lng: 22.098405 },
    { lat: 49.034232, lng: 22.098563 },
    { lat: 49.033925, lng: 22.098696 },
    { lat: 49.03374, lng: 22.098857 },
    { lat: 49.03357, lng: 22.09908 },
    { lat: 49.033424, lng: 22.099558 },
    { lat: 49.03335, lng: 22.099643 },
    { lat: 49.033099, lng: 22.099796 },
    { lat: 49.032923, lng: 22.099858 },
    { lat: 49.032744, lng: 22.099899 },
    { lat: 49.032595, lng: 22.100028 },
    { lat: 49.031498, lng: 22.100682 },
    { lat: 49.031239, lng: 22.100963 },
    { lat: 49.030987, lng: 22.101292 },
    { lat: 49.030744, lng: 22.101505 },
    { lat: 49.030418, lng: 22.101739 },
    { lat: 49.030248, lng: 22.101834 },
    { lat: 49.030213, lng: 22.101888 },
    { lat: 49.030021, lng: 22.10207 },
    { lat: 49.029746, lng: 22.102218 },
    { lat: 49.029588, lng: 22.102363 },
    { lat: 49.029349, lng: 22.102532 },
    { lat: 49.028811, lng: 22.1032 },
    { lat: 49.028662, lng: 22.103464 },
    { lat: 49.028409, lng: 22.103899 },
    { lat: 49.028122, lng: 22.104282 },
    { lat: 49.027805, lng: 22.10465 },
    { lat: 49.027637, lng: 22.104815 },
    { lat: 49.027323, lng: 22.105095 },
    { lat: 49.027177, lng: 22.105273 },
    { lat: 49.027038, lng: 22.105489 },
    { lat: 49.026866, lng: 22.105899 },
    { lat: 49.026703, lng: 22.106219 },
    { lat: 49.026315, lng: 22.106807 },
    { lat: 49.026025, lng: 22.107238 },
    { lat: 49.025938, lng: 22.107491 },
    { lat: 49.025818, lng: 22.107907 },
    { lat: 49.025706, lng: 22.108083 },
    { lat: 49.025591, lng: 22.108245 },
    { lat: 49.025349, lng: 22.108544 },
    { lat: 49.025134, lng: 22.10896 },
    { lat: 49.025082, lng: 22.109118 },
    { lat: 49.025039, lng: 22.109433 },
    { lat: 49.024977, lng: 22.109738 },
    { lat: 49.024656, lng: 22.111246 },
    { lat: 49.024505, lng: 22.111677 },
    { lat: 49.024291, lng: 22.112367 },
    { lat: 49.024264, lng: 22.112492 },
    { lat: 49.024294, lng: 22.113031 },
    { lat: 49.024353, lng: 22.113419 },
    { lat: 49.024397, lng: 22.113728 },
    { lat: 49.024381, lng: 22.113977 },
    { lat: 49.024434, lng: 22.114177 },
    { lat: 49.024582, lng: 22.114447 },
    { lat: 49.024628, lng: 22.114573 },
    { lat: 49.024646, lng: 22.114776 },
    { lat: 49.024633, lng: 22.11511 },
  ],
  NováSedlica: [
    { lat: 49.0875906, lng: 22.5559701 },
    { lat: 49.087906, lng: 22.555243 },
    { lat: 49.0884234, lng: 22.5539009 },
    { lat: 49.0890686, lng: 22.5529449 },
    { lat: 49.089373, lng: 22.552017 },
    { lat: 49.0895556, lng: 22.5514167 },
    { lat: 49.089862, lng: 22.5502023 },
    { lat: 49.0901261, lng: 22.548999 },
    { lat: 49.090287, lng: 22.5477766 },
    { lat: 49.0908988, lng: 22.5471812 },
    { lat: 49.0915537, lng: 22.5470915 },
    { lat: 49.0917747, lng: 22.5466133 },
    { lat: 49.092004, lng: 22.5455842 },
    { lat: 49.0924534, lng: 22.5446636 },
    { lat: 49.0929357, lng: 22.5439501 },
    { lat: 49.0932209, lng: 22.5432013 },
    { lat: 49.0935571, lng: 22.5425882 },
    { lat: 49.093971, lng: 22.5412887 },
    { lat: 49.0942956, lng: 22.5408635 },
    { lat: 49.0945, lng: 22.5404722 },
    { lat: 49.0946111, lng: 22.5399722 },
    { lat: 49.0946935, lng: 22.5394246 },
    { lat: 49.0945316, lng: 22.538822 },
    { lat: 49.09425, lng: 22.5382222 },
    { lat: 49.0940315, lng: 22.537964 },
    { lat: 49.0938869, lng: 22.5375634 },
    { lat: 49.0934945, lng: 22.5360321 },
    { lat: 49.0933299, lng: 22.5348325 },
    { lat: 49.0931766, lng: 22.5333997 },
    { lat: 49.0931835, lng: 22.5320705 },
    { lat: 49.0931189, lng: 22.531299 },
    { lat: 49.093186, lng: 22.5308068 },
    { lat: 49.0933182, lng: 22.5302919 },
    { lat: 49.0932985, lng: 22.5298397 },
    { lat: 49.093015, lng: 22.529382 },
    { lat: 49.092921, lng: 22.528864 },
    { lat: 49.09275, lng: 22.528 },
    { lat: 49.0927296, lng: 22.5273295 },
    { lat: 49.092772, lng: 22.526377 },
    { lat: 49.092861, lng: 22.52556 },
    { lat: 49.0928391, lng: 22.5246754 },
    { lat: 49.0928333, lng: 22.5244444 },
    { lat: 49.09284, lng: 22.523672 },
    { lat: 49.0929145, lng: 22.5228057 },
    { lat: 49.092899, lng: 22.522107 },
    { lat: 49.0927712, lng: 22.5212071 },
    { lat: 49.0926111, lng: 22.5205278 },
    { lat: 49.0925293, lng: 22.5197633 },
    { lat: 49.092356, lng: 22.5189694 },
    { lat: 49.0923468, lng: 22.5177435 },
    { lat: 49.0925115, lng: 22.5170049 },
    { lat: 49.092522, lng: 22.516328 },
    { lat: 49.092471, lng: 22.515392 },
    { lat: 49.092399, lng: 22.514564 },
    { lat: 49.0921634, lng: 22.5132975 },
    { lat: 49.092156, lng: 22.511912 },
    { lat: 49.0921557, lng: 22.5112986 },
    { lat: 49.091931, lng: 22.510471 },
    { lat: 49.0916914, lng: 22.5096678 },
    { lat: 49.091493, lng: 22.508526 },
    { lat: 49.0913941, lng: 22.5077302 },
    { lat: 49.09108, lng: 22.507015 },
    { lat: 49.0908889, lng: 22.5058333 },
    { lat: 49.09078, lng: 22.504976 },
    { lat: 49.0901478, lng: 22.503753 },
    { lat: 49.0896389, lng: 22.503 },
    { lat: 49.0892986, lng: 22.5024851 },
    { lat: 49.0890201, lng: 22.502211 },
    { lat: 49.0886438, lng: 22.502135 },
    { lat: 49.0881641, lng: 22.5015592 },
    { lat: 49.0876904, lng: 22.5010963 },
    { lat: 49.0876021, lng: 22.50101 },
    { lat: 49.0868758, lng: 22.5008348 },
    { lat: 49.0861778, lng: 22.5002215 },
    { lat: 49.0858635, lng: 22.4997898 },
    { lat: 49.0857944, lng: 22.498712 },
    { lat: 49.085862, lng: 22.497814 },
    { lat: 49.0858903, lng: 22.4970232 },
    { lat: 49.086602, lng: 22.495472 },
    { lat: 49.086694, lng: 22.494579 },
    { lat: 49.086713, lng: 22.493989 },
    { lat: 49.0868373, lng: 22.4933505 },
    { lat: 49.087082, lng: 22.492435 },
    { lat: 49.087304, lng: 22.492062 },
    { lat: 49.087845, lng: 22.491087 },
    { lat: 49.0877382, lng: 22.4910422 },
    { lat: 49.0874288, lng: 22.4913709 },
    { lat: 49.0869405, lng: 22.4911942 },
    { lat: 49.0861631, lng: 22.4912859 },
    { lat: 49.0855147, lng: 22.4911378 },
    { lat: 49.0849113, lng: 22.4908218 },
    { lat: 49.0845361, lng: 22.4907987 },
    { lat: 49.0839065, lng: 22.4903548 },
    { lat: 49.0835637, lng: 22.4898046 },
    { lat: 49.0828734, lng: 22.4894701 },
    { lat: 49.0820653, lng: 22.489164 },
    { lat: 49.0817061, lng: 22.4891922 },
    { lat: 49.080487, lng: 22.4890104 },
    { lat: 49.0800606, lng: 22.4886669 },
    { lat: 49.0798993, lng: 22.487803 },
    { lat: 49.0795226, lng: 22.4876448 },
    { lat: 49.0792712, lng: 22.4874735 },
    { lat: 49.0790232, lng: 22.4871903 },
    { lat: 49.0787226, lng: 22.4869759 },
    { lat: 49.0784749, lng: 22.4866387 },
    { lat: 49.0782263, lng: 22.4865079 },
    { lat: 49.0780418, lng: 22.4864483 },
    { lat: 49.0776423, lng: 22.4862567 },
    { lat: 49.077431, lng: 22.48602 },
    { lat: 49.076945, lng: 22.4857281 },
    { lat: 49.0767617, lng: 22.4852851 },
    { lat: 49.0764398, lng: 22.4852721 },
    { lat: 49.0760635, lng: 22.484955 },
    { lat: 49.075259, lng: 22.4844293 },
    { lat: 49.0749611, lng: 22.4841566 },
    { lat: 49.0748538, lng: 22.4839679 },
    { lat: 49.0746473, lng: 22.483366 },
    { lat: 49.0743094, lng: 22.4830597 },
    { lat: 49.0737282, lng: 22.4821779 },
    { lat: 49.0729967, lng: 22.4823161 },
    { lat: 49.0721685, lng: 22.4825653 },
    { lat: 49.0713008, lng: 22.4827054 },
    { lat: 49.0701885, lng: 22.4831483 },
    { lat: 49.0691482, lng: 22.4831376 },
    { lat: 49.0687661, lng: 22.4828172 },
    { lat: 49.0688027, lng: 22.4825028 },
    { lat: 49.0686198, lng: 22.4821997 },
    { lat: 49.0685567, lng: 22.4817432 },
    { lat: 49.0686145, lng: 22.4815963 },
    { lat: 49.0685758, lng: 22.481568 },
    { lat: 49.0678886, lng: 22.4816245 },
    { lat: 49.0676489, lng: 22.482056 },
    { lat: 49.0673593, lng: 22.4821373 },
    { lat: 49.0673695, lng: 22.4820959 },
    { lat: 49.0671047, lng: 22.4819594 },
    { lat: 49.0669728, lng: 22.4819975 },
    { lat: 49.0665627, lng: 22.4818685 },
    { lat: 49.0663977, lng: 22.4819021 },
    { lat: 49.066171, lng: 22.4823276 },
    { lat: 49.0660662, lng: 22.482481 },
    { lat: 49.065726, lng: 22.4837852 },
    { lat: 49.0655977, lng: 22.4838491 },
    { lat: 49.0654185, lng: 22.4835433 },
    { lat: 49.064894, lng: 22.4830183 },
    { lat: 49.0646833, lng: 22.4830052 },
    { lat: 49.0643105, lng: 22.4833091 },
    { lat: 49.0635936, lng: 22.4832206 },
    { lat: 49.0633272, lng: 22.4833449 },
    { lat: 49.063127, lng: 22.4832219 },
    { lat: 49.06177, lng: 22.483693 },
    { lat: 49.061408, lng: 22.4839121 },
    { lat: 49.0609053, lng: 22.4838631 },
    { lat: 49.0605894, lng: 22.4840334 },
    { lat: 49.0603093, lng: 22.484025 },
    { lat: 49.0601475, lng: 22.4840895 },
    { lat: 49.0595533, lng: 22.4847946 },
    { lat: 49.0590499, lng: 22.485703 },
    { lat: 49.0585109, lng: 22.4863549 },
    { lat: 49.0581695, lng: 22.4872435 },
    { lat: 49.0580033, lng: 22.4882441 },
    { lat: 49.0576803, lng: 22.489108 },
    { lat: 49.057337, lng: 22.4897149 },
    { lat: 49.0569894, lng: 22.4906332 },
    { lat: 49.0566073, lng: 22.4912584 },
    { lat: 49.056098, lng: 22.4916294 },
    { lat: 49.055937, lng: 22.4918219 },
    { lat: 49.055771, lng: 22.4921379 },
    { lat: 49.0553223, lng: 22.4927679 },
    { lat: 49.0549966, lng: 22.4930132 },
    { lat: 49.0546497, lng: 22.4933763 },
    { lat: 49.0543353, lng: 22.493914 },
    { lat: 49.0541208, lng: 22.4947092 },
    { lat: 49.0537778, lng: 22.4949284 },
    { lat: 49.053137, lng: 22.4950508 },
    { lat: 49.0529167, lng: 22.4952696 },
    { lat: 49.0526076, lng: 22.49542 },
    { lat: 49.0518704, lng: 22.495265 },
    { lat: 49.0514909, lng: 22.495245 },
    { lat: 49.0511049, lng: 22.4954098 },
    { lat: 49.0499999, lng: 22.4961396 },
    { lat: 49.0495041, lng: 22.4969558 },
    { lat: 49.0489642, lng: 22.4975403 },
    { lat: 49.0486054, lng: 22.4976542 },
    { lat: 49.0479418, lng: 22.4974225 },
    { lat: 49.0476783, lng: 22.4972895 },
    { lat: 49.0474953, lng: 22.4969069 },
    { lat: 49.0464518, lng: 22.4961831 },
    { lat: 49.0455495, lng: 22.4975133 },
    { lat: 49.0453047, lng: 22.498153 },
    { lat: 49.0453201, lng: 22.4982946 },
    { lat: 49.0451992, lng: 22.4985537 },
    { lat: 49.0450208, lng: 22.4988505 },
    { lat: 49.0448754, lng: 22.4989433 },
    { lat: 49.0446906, lng: 22.4988092 },
    { lat: 49.0445489, lng: 22.4989233 },
    { lat: 49.0443512, lng: 22.4989371 },
    { lat: 49.0442414, lng: 22.4990196 },
    { lat: 49.0441014, lng: 22.4989831 },
    { lat: 49.0439314, lng: 22.4991422 },
    { lat: 49.0437631, lng: 22.4990554 },
    { lat: 49.0431955, lng: 22.4992967 },
    { lat: 49.042945, lng: 22.4994714 },
    { lat: 49.0427992, lng: 22.4994398 },
    { lat: 49.0423887, lng: 22.4996043 },
    { lat: 49.0422481, lng: 22.4995997 },
    { lat: 49.0419998, lng: 22.499446 },
    { lat: 49.041695, lng: 22.4993855 },
    { lat: 49.0415143, lng: 22.4994545 },
    { lat: 49.0413234, lng: 22.4993579 },
    { lat: 49.0409967, lng: 22.4998372 },
    { lat: 49.0407266, lng: 22.4998834 },
    { lat: 49.0406439, lng: 22.4997901 },
    { lat: 49.0403522, lng: 22.4998864 },
    { lat: 49.039988, lng: 22.5001166 },
    { lat: 49.0397762, lng: 22.5003282 },
    { lat: 49.0395868, lng: 22.5003559 },
    { lat: 49.0395081, lng: 22.5005775 },
    { lat: 49.03934, lng: 22.5007749 },
    { lat: 49.0391955, lng: 22.5007515 },
    { lat: 49.0389533, lng: 22.500844 },
    { lat: 49.0385836, lng: 22.501588 },
    { lat: 49.038495, lng: 22.5016021 },
    { lat: 49.0382058, lng: 22.5014797 },
    { lat: 49.0378438, lng: 22.5016556 },
    { lat: 49.037799, lng: 22.5013838 },
    { lat: 49.0378723, lng: 22.5012184 },
    { lat: 49.0378488, lng: 22.5010564 },
    { lat: 49.0376275, lng: 22.5006757 },
    { lat: 49.0374016, lng: 22.5005635 },
    { lat: 49.0372681, lng: 22.5008034 },
    { lat: 49.0370928, lng: 22.5006527 },
    { lat: 49.0363706, lng: 22.5005267 },
    { lat: 49.0361517, lng: 22.5003135 },
    { lat: 49.0360085, lng: 22.5000141 },
    { lat: 49.035359, lng: 22.5005506 },
    { lat: 49.0337725, lng: 22.5020772 },
    { lat: 49.0335257, lng: 22.5021077 },
    { lat: 49.0324982, lng: 22.5026347 },
    { lat: 49.0321321, lng: 22.5025459 },
    { lat: 49.0311917, lng: 22.5034312 },
    { lat: 49.0301429, lng: 22.5050278 },
    { lat: 49.0300547, lng: 22.5063453 },
    { lat: 49.0296436, lng: 22.5074511 },
    { lat: 49.029511, lng: 22.5081292 },
    { lat: 49.0292271, lng: 22.5086623 },
    { lat: 49.0288989, lng: 22.5091257 },
    { lat: 49.0285701, lng: 22.5097841 },
    { lat: 49.0285232, lng: 22.5100812 },
    { lat: 49.0283617, lng: 22.5102656 },
    { lat: 49.0282275, lng: 22.5105288 },
    { lat: 49.0276, lng: 22.5118914 },
    { lat: 49.0273474, lng: 22.5136098 },
    { lat: 49.0273905, lng: 22.5144733 },
    { lat: 49.027684, lng: 22.5158297 },
    { lat: 49.027544, lng: 22.5171588 },
    { lat: 49.0270406, lng: 22.5179738 },
    { lat: 49.026669, lng: 22.5184607 },
    { lat: 49.0251587, lng: 22.519643 },
    { lat: 49.0243815, lng: 22.5209981 },
    { lat: 49.0239039, lng: 22.521235 },
    { lat: 49.0237749, lng: 22.5214803 },
    { lat: 49.0236746, lng: 22.5220926 },
    { lat: 49.0234268, lng: 22.5229754 },
    { lat: 49.0226716, lng: 22.5234066 },
    { lat: 49.0220937, lng: 22.5238054 },
    { lat: 49.0219133, lng: 22.5240143 },
    { lat: 49.0217473, lng: 22.524257 },
    { lat: 49.0213673, lng: 22.5252275 },
    { lat: 49.0211737, lng: 22.5257315 },
    { lat: 49.0211149, lng: 22.5260606 },
    { lat: 49.020753, lng: 22.5267493 },
    { lat: 49.0205086, lng: 22.5271769 },
    { lat: 49.0202929, lng: 22.5274229 },
    { lat: 49.0199994, lng: 22.5274775 },
    { lat: 49.0198753, lng: 22.5276863 },
    { lat: 49.0198412, lng: 22.527829 },
    { lat: 49.0199267, lng: 22.5284589 },
    { lat: 49.0203745, lng: 22.5298355 },
    { lat: 49.0203058, lng: 22.5304588 },
    { lat: 49.0198586, lng: 22.5314438 },
    { lat: 49.0205069, lng: 22.5324482 },
    { lat: 49.0208054, lng: 22.5337216 },
    { lat: 49.0209871, lng: 22.5340429 },
    { lat: 49.0220023, lng: 22.5354567 },
    { lat: 49.0218669, lng: 22.5364084 },
    { lat: 49.0219862, lng: 22.5368631 },
    { lat: 49.0215597, lng: 22.5377607 },
    { lat: 49.021321, lng: 22.53799 },
    { lat: 49.0230634, lng: 22.5439811 },
    { lat: 49.025201, lng: 22.5477 },
    { lat: 49.028003, lng: 22.549984 },
    { lat: 49.030161, lng: 22.551079 },
    { lat: 49.0314667, lng: 22.5510443 },
    { lat: 49.0445826, lng: 22.551163 },
    { lat: 49.050214, lng: 22.551214 },
    { lat: 49.0630172, lng: 22.5513087 },
    { lat: 49.0658482, lng: 22.5515116 },
    { lat: 49.0667527, lng: 22.5515764 },
    { lat: 49.0668537, lng: 22.5515836 },
    { lat: 49.068283, lng: 22.551686 },
    { lat: 49.0684173, lng: 22.5517046 },
    { lat: 49.0716987, lng: 22.5521598 },
    { lat: 49.0719231, lng: 22.55219 },
    { lat: 49.0778105, lng: 22.5530054 },
    { lat: 49.0792918, lng: 22.5532105 },
    { lat: 49.07948, lng: 22.5532366 },
    { lat: 49.079483, lng: 22.553237 },
    { lat: 49.079234, lng: 22.554812 },
    { lat: 49.080148, lng: 22.558112 },
    { lat: 49.081037, lng: 22.562596 },
    { lat: 49.082686, lng: 22.564746 },
    { lat: 49.083657, lng: 22.5655925 },
    { lat: 49.083661, lng: 22.565596 },
    { lat: 49.087466, lng: 22.565545 },
    { lat: 49.0879954, lng: 22.5657103 },
    { lat: 49.0879641, lng: 22.5649469 },
    { lat: 49.0877281, lng: 22.564095 },
    { lat: 49.0877675, lng: 22.5632029 },
    { lat: 49.0877419, lng: 22.5622977 },
    { lat: 49.0878889, lng: 22.5618299 },
    { lat: 49.0876341, lng: 22.5613549 },
    { lat: 49.0873889, lng: 22.5605278 },
    { lat: 49.0873231, lng: 22.5597599 },
    { lat: 49.0875, lng: 22.5589444 },
    { lat: 49.087497, lng: 22.558358 },
    { lat: 49.0874444, lng: 22.5576111 },
    { lat: 49.0874802, lng: 22.5567799 },
    { lat: 49.0875906, lng: 22.5559701 },
  ],
  Runina: [
    { lat: 49.0490882, lng: 22.3931473 },
    { lat: 49.0487665, lng: 22.3940029 },
    { lat: 49.0484327, lng: 22.3945461 },
    { lat: 49.048091, lng: 22.3955259 },
    { lat: 49.0480345, lng: 22.3978195 },
    { lat: 49.0484538, lng: 22.3999112 },
    { lat: 49.0487983, lng: 22.4012297 },
    { lat: 49.0493264, lng: 22.4026125 },
    { lat: 49.0494719, lng: 22.4033289 },
    { lat: 49.0493401, lng: 22.4043441 },
    { lat: 49.0496114, lng: 22.407163 },
    { lat: 49.0495426, lng: 22.4085688 },
    { lat: 49.0498241, lng: 22.4097785 },
    { lat: 49.0501544, lng: 22.410138 },
    { lat: 49.0508054, lng: 22.4110485 },
    { lat: 49.0508339, lng: 22.4116047 },
    { lat: 49.0509571, lng: 22.4122387 },
    { lat: 49.0514368, lng: 22.4127578 },
    { lat: 49.0517226, lng: 22.4133571 },
    { lat: 49.0519226, lng: 22.4141148 },
    { lat: 49.051962, lng: 22.4147077 },
    { lat: 49.0522066, lng: 22.4158207 },
    { lat: 49.0519086, lng: 22.4174957 },
    { lat: 49.0519116, lng: 22.4182424 },
    { lat: 49.0517959, lng: 22.4189055 },
    { lat: 49.0516042, lng: 22.4197973 },
    { lat: 49.0512139, lng: 22.42066 },
    { lat: 49.0509958, lng: 22.4217041 },
    { lat: 49.0509836, lng: 22.4221517 },
    { lat: 49.0507607, lng: 22.4224586 },
    { lat: 49.05057, lng: 22.4234059 },
    { lat: 49.0505376, lng: 22.4245028 },
    { lat: 49.0502384, lng: 22.4255349 },
    { lat: 49.0503282, lng: 22.4266219 },
    { lat: 49.050319, lng: 22.4273147 },
    { lat: 49.0505217, lng: 22.4280934 },
    { lat: 49.0507458, lng: 22.4296975 },
    { lat: 49.0507394, lng: 22.431238 },
    { lat: 49.0508723, lng: 22.4326116 },
    { lat: 49.0505027, lng: 22.4337284 },
    { lat: 49.0498627, lng: 22.4353554 },
    { lat: 49.050125, lng: 22.4354848 },
    { lat: 49.0511918, lng: 22.4362856 },
    { lat: 49.051727, lng: 22.437321 },
    { lat: 49.051747, lng: 22.4374674 },
    { lat: 49.051916, lng: 22.4376519 },
    { lat: 49.0523303, lng: 22.4378968 },
    { lat: 49.0528466, lng: 22.4378934 },
    { lat: 49.0537109, lng: 22.4376512 },
    { lat: 49.0543789, lng: 22.4385319 },
    { lat: 49.0551102, lng: 22.439259 },
    { lat: 49.0562985, lng: 22.4399552 },
    { lat: 49.0566775, lng: 22.4404524 },
    { lat: 49.0570894, lng: 22.4418399 },
    { lat: 49.0579095, lng: 22.4438201 },
    { lat: 49.0581984, lng: 22.444199 },
    { lat: 49.0591119, lng: 22.445046 },
    { lat: 49.0595248, lng: 22.4451638 },
    { lat: 49.0600782, lng: 22.44556 },
    { lat: 49.0605063, lng: 22.4456508 },
    { lat: 49.0614979, lng: 22.4461894 },
    { lat: 49.0619062, lng: 22.4467983 },
    { lat: 49.0625517, lng: 22.4470177 },
    { lat: 49.0636954, lng: 22.4469072 },
    { lat: 49.0648411, lng: 22.4459026 },
    { lat: 49.0663699, lng: 22.4455988 },
    { lat: 49.067427, lng: 22.4458776 },
    { lat: 49.0680038, lng: 22.4457678 },
    { lat: 49.0684955, lng: 22.4457728 },
    { lat: 49.0698156, lng: 22.4449474 },
    { lat: 49.0705603, lng: 22.443693 },
    { lat: 49.0708326, lng: 22.4433759 },
    { lat: 49.0710942, lng: 22.4432986 },
    { lat: 49.071577, lng: 22.4428238 },
    { lat: 49.0720185, lng: 22.4426357 },
    { lat: 49.0724898, lng: 22.4425885 },
    { lat: 49.0729035, lng: 22.4427454 },
    { lat: 49.0731791, lng: 22.4425813 },
    { lat: 49.0743354, lng: 22.4407287 },
    { lat: 49.0751129, lng: 22.4403657 },
    { lat: 49.0754421, lng: 22.440133 },
    { lat: 49.0768419, lng: 22.4399065 },
    { lat: 49.0771365, lng: 22.4397379 },
    { lat: 49.078418, lng: 22.4376833 },
    { lat: 49.0788176, lng: 22.4374271 },
    { lat: 49.0793601, lng: 22.4372908 },
    { lat: 49.0796255, lng: 22.4371236 },
    { lat: 49.0805715, lng: 22.4356013 },
    { lat: 49.0812571, lng: 22.4347709 },
    { lat: 49.0817931, lng: 22.4342485 },
    { lat: 49.082523, lng: 22.4339773 },
    { lat: 49.0837219, lng: 22.4332306 },
    { lat: 49.0842863, lng: 22.433217 },
    { lat: 49.0845921, lng: 22.4330564 },
    { lat: 49.0847559, lng: 22.4328133 },
    { lat: 49.0850788, lng: 22.4329353 },
    { lat: 49.0854451, lng: 22.4329727 },
    { lat: 49.086113, lng: 22.4321989 },
    { lat: 49.0864401, lng: 22.4316459 },
    { lat: 49.0866581, lng: 22.4311468 },
    { lat: 49.086832, lng: 22.4304683 },
    { lat: 49.087222, lng: 22.4296927 },
    { lat: 49.0876815, lng: 22.4292316 },
    { lat: 49.0883913, lng: 22.4287521 },
    { lat: 49.0893249, lng: 22.4288187 },
    { lat: 49.0897616, lng: 22.4287301 },
    { lat: 49.0906132, lng: 22.4279269 },
    { lat: 49.0918516, lng: 22.4278882 },
    { lat: 49.0928824, lng: 22.4281978 },
    { lat: 49.0939609, lng: 22.4286856 },
    { lat: 49.0946241, lng: 22.4287286 },
    { lat: 49.0956243, lng: 22.4289169 },
    { lat: 49.0964311, lng: 22.4289462 },
    { lat: 49.096957, lng: 22.428644 },
    { lat: 49.0984722, lng: 22.4284444 },
    { lat: 49.098678, lng: 22.427283 },
    { lat: 49.099024, lng: 22.426154 },
    { lat: 49.099246, lng: 22.42477 },
    { lat: 49.09949, lng: 22.422654 },
    { lat: 49.099618, lng: 22.42241 },
    { lat: 49.100056, lng: 22.421636 },
    { lat: 49.100575, lng: 22.420543 },
    { lat: 49.101129, lng: 22.419099 },
    { lat: 49.101434, lng: 22.417989 },
    { lat: 49.101784, lng: 22.41731 },
    { lat: 49.10189, lng: 22.417135 },
    { lat: 49.1019489, lng: 22.416961 },
    { lat: 49.102121, lng: 22.416453 },
    { lat: 49.102173, lng: 22.415732 },
    { lat: 49.1021111, lng: 22.4148889 },
    { lat: 49.102038, lng: 22.414453 },
    { lat: 49.102079, lng: 22.41388 },
    { lat: 49.102902, lng: 22.41247 },
    { lat: 49.103205, lng: 22.412066 },
    { lat: 49.103714, lng: 22.411403 },
    { lat: 49.103893, lng: 22.411147 },
    { lat: 49.1042778, lng: 22.4105556 },
    { lat: 49.105141, lng: 22.409453 },
    { lat: 49.105546, lng: 22.409093 },
    { lat: 49.106113, lng: 22.408731 },
    { lat: 49.10675, lng: 22.407917 },
    { lat: 49.1070394, lng: 22.4072205 },
    { lat: 49.1074538, lng: 22.4068058 },
    { lat: 49.1078056, lng: 22.4066944 },
    { lat: 49.1086378, lng: 22.406018 },
    { lat: 49.10925, lng: 22.4053611 },
    { lat: 49.1098611, lng: 22.4046953 },
    { lat: 49.1096361, lng: 22.4045277 },
    { lat: 49.1091874, lng: 22.4041934 },
    { lat: 49.1083476, lng: 22.4032885 },
    { lat: 49.1070621, lng: 22.4024173 },
    { lat: 49.1069247, lng: 22.4021318 },
    { lat: 49.1065076, lng: 22.4017596 },
    { lat: 49.1058851, lng: 22.4014592 },
    { lat: 49.1052487, lng: 22.4008191 },
    { lat: 49.1041414, lng: 22.4002413 },
    { lat: 49.1035121, lng: 22.3993547 },
    { lat: 49.1023621, lng: 22.3986172 },
    { lat: 49.1008105, lng: 22.39831 },
    { lat: 49.1005154, lng: 22.3975286 },
    { lat: 49.1002132, lng: 22.3968926 },
    { lat: 49.099804, lng: 22.3956108 },
    { lat: 49.09907, lng: 22.3944384 },
    { lat: 49.0984018, lng: 22.3935754 },
    { lat: 49.0981584, lng: 22.3916636 },
    { lat: 49.097812, lng: 22.3903763 },
    { lat: 49.0973064, lng: 22.3900244 },
    { lat: 49.0968769, lng: 22.3899136 },
    { lat: 49.0960564, lng: 22.3891284 },
    { lat: 49.0957592, lng: 22.389005 },
    { lat: 49.0954395, lng: 22.3890528 },
    { lat: 49.0951013, lng: 22.3890047 },
    { lat: 49.0945301, lng: 22.3893328 },
    { lat: 49.0942674, lng: 22.3894044 },
    { lat: 49.0938831, lng: 22.3891593 },
    { lat: 49.0930988, lng: 22.3888629 },
    { lat: 49.0928921, lng: 22.3886591 },
    { lat: 49.0924064, lng: 22.3879162 },
    { lat: 49.0921568, lng: 22.387327 },
    { lat: 49.091994, lng: 22.3871264 },
    { lat: 49.0911651, lng: 22.3871211 },
    { lat: 49.0901327, lng: 22.3872211 },
    { lat: 49.0891342, lng: 22.3864767 },
    { lat: 49.0886105, lng: 22.3863841 },
    { lat: 49.0883502, lng: 22.3854474 },
    { lat: 49.0880516, lng: 22.3846682 },
    { lat: 49.0880634, lng: 22.3847473 },
    { lat: 49.0878239, lng: 22.3843273 },
    { lat: 49.0876432, lng: 22.3837039 },
    { lat: 49.0876809, lng: 22.3832407 },
    { lat: 49.0882412, lng: 22.3835202 },
    { lat: 49.0885689, lng: 22.383763 },
    { lat: 49.0884483, lng: 22.3842231 },
    { lat: 49.0887459, lng: 22.3844235 },
    { lat: 49.089217, lng: 22.3843134 },
    { lat: 49.0892226, lng: 22.3840975 },
    { lat: 49.0893542, lng: 22.3836214 },
    { lat: 49.0892042, lng: 22.3831117 },
    { lat: 49.0890576, lng: 22.3822885 },
    { lat: 49.0887346, lng: 22.3817431 },
    { lat: 49.0882099, lng: 22.3810729 },
    { lat: 49.0879309, lng: 22.3805803 },
    { lat: 49.0877274, lng: 22.3785042 },
    { lat: 49.087259, lng: 22.3767119 },
    { lat: 49.0866744, lng: 22.3763468 },
    { lat: 49.0862755, lng: 22.3763035 },
    { lat: 49.085582, lng: 22.3762713 },
    { lat: 49.085111, lng: 22.3766007 },
    { lat: 49.0841356, lng: 22.3765758 },
    { lat: 49.0836424, lng: 22.3764471 },
    { lat: 49.0828596, lng: 22.3763909 },
    { lat: 49.0821982, lng: 22.3764856 },
    { lat: 49.0816431, lng: 22.3764535 },
    { lat: 49.0810978, lng: 22.3769927 },
    { lat: 49.0807202, lng: 22.3772684 },
    { lat: 49.0801493, lng: 22.3771821 },
    { lat: 49.0794126, lng: 22.3772249 },
    { lat: 49.0783755, lng: 22.3775431 },
    { lat: 49.0777973, lng: 22.3776348 },
    { lat: 49.0770959, lng: 22.3780779 },
    { lat: 49.0767395, lng: 22.3784099 },
    { lat: 49.0765206, lng: 22.3787495 },
    { lat: 49.0763355, lng: 22.3788765 },
    { lat: 49.0762565, lng: 22.3790214 },
    { lat: 49.0762557, lng: 22.3791505 },
    { lat: 49.075846, lng: 22.3795041 },
    { lat: 49.0753974, lng: 22.3797745 },
    { lat: 49.0749279, lng: 22.3798595 },
    { lat: 49.0748284, lng: 22.3802054 },
    { lat: 49.0737278, lng: 22.38113 },
    { lat: 49.0732029, lng: 22.380983 },
    { lat: 49.0730927, lng: 22.3815246 },
    { lat: 49.072707, lng: 22.381203 },
    { lat: 49.072016, lng: 22.3809088 },
    { lat: 49.0718324, lng: 22.3811641 },
    { lat: 49.0712993, lng: 22.3815344 },
    { lat: 49.0705024, lng: 22.3818734 },
    { lat: 49.0694118, lng: 22.3819423 },
    { lat: 49.0689284, lng: 22.3817908 },
    { lat: 49.0680613, lng: 22.3812487 },
    { lat: 49.0677826, lng: 22.3812064 },
    { lat: 49.0666846, lng: 22.3818846 },
    { lat: 49.0658209, lng: 22.3819869 },
    { lat: 49.0652442, lng: 22.3824786 },
    { lat: 49.064775, lng: 22.3830609 },
    { lat: 49.0635127, lng: 22.3834598 },
    { lat: 49.062267, lng: 22.3845991 },
    { lat: 49.061745, lng: 22.385238 },
    { lat: 49.0605701, lng: 22.3857846 },
    { lat: 49.0601476, lng: 22.385638 },
    { lat: 49.0593276, lng: 22.3867554 },
    { lat: 49.0587382, lng: 22.3878777 },
    { lat: 49.0583176, lng: 22.388904 },
    { lat: 49.0573089, lng: 22.3906737 },
    { lat: 49.0567255, lng: 22.3915446 },
    { lat: 49.0564183, lng: 22.3917528 },
    { lat: 49.0558376, lng: 22.3918412 },
    { lat: 49.0550343, lng: 22.3922451 },
    { lat: 49.0540375, lng: 22.3932508 },
    { lat: 49.0535918, lng: 22.3935513 },
    { lat: 49.0530469, lng: 22.394166 },
    { lat: 49.0518867, lng: 22.3946145 },
    { lat: 49.0511976, lng: 22.3944642 },
    { lat: 49.0505437, lng: 22.3942883 },
    { lat: 49.0501574, lng: 22.3939937 },
    { lat: 49.0499168, lng: 22.3935643 },
    { lat: 49.0490882, lng: 22.3931473 },
  ],
  Jalová: [
    { lat: 49.0462027, lng: 22.2127677 },
    { lat: 49.0458131, lng: 22.2118461 },
    { lat: 49.0453672, lng: 22.21047 },
    { lat: 49.0443365, lng: 22.2121545 },
    { lat: 49.0437699, lng: 22.212616 },
    { lat: 49.043465, lng: 22.2132155 },
    { lat: 49.0434045, lng: 22.2144243 },
    { lat: 49.043437, lng: 22.2147173 },
    { lat: 49.0435771, lng: 22.2150983 },
    { lat: 49.0429684, lng: 22.2156888 },
    { lat: 49.0428196, lng: 22.216871 },
    { lat: 49.0424634, lng: 22.2169397 },
    { lat: 49.041653, lng: 22.2176568 },
    { lat: 49.0404875, lng: 22.2180439 },
    { lat: 49.0391184, lng: 22.2179769 },
    { lat: 49.0386992, lng: 22.2182944 },
    { lat: 49.0376462, lng: 22.2187355 },
    { lat: 49.0376592, lng: 22.2188642 },
    { lat: 49.0374351, lng: 22.2190447 },
    { lat: 49.0359733, lng: 22.2210139 },
    { lat: 49.0356158, lng: 22.2218769 },
    { lat: 49.0354541, lng: 22.2224462 },
    { lat: 49.0348048, lng: 22.223885 },
    { lat: 49.0345949, lng: 22.2242496 },
    { lat: 49.0347266, lng: 22.2252586 },
    { lat: 49.0346822, lng: 22.2261384 },
    { lat: 49.034377, lng: 22.2275295 },
    { lat: 49.0341958, lng: 22.2277292 },
    { lat: 49.0339719, lng: 22.2286788 },
    { lat: 49.0332081, lng: 22.2294301 },
    { lat: 49.0328885, lng: 22.2303778 },
    { lat: 49.032955, lng: 22.2324475 },
    { lat: 49.0331041, lng: 22.234799 },
    { lat: 49.0330836, lng: 22.2359434 },
    { lat: 49.0331757, lng: 22.2367957 },
    { lat: 49.0333024, lng: 22.2373332 },
    { lat: 49.0331752, lng: 22.2376828 },
    { lat: 49.0329818, lng: 22.238544 },
    { lat: 49.0331106, lng: 22.2386664 },
    { lat: 49.03363, lng: 22.2398229 },
    { lat: 49.0330534, lng: 22.2404482 },
    { lat: 49.032316, lng: 22.2409722 },
    { lat: 49.0316788, lng: 22.2423103 },
    { lat: 49.0317095, lng: 22.2425783 },
    { lat: 49.0314471, lng: 22.2435244 },
    { lat: 49.031086, lng: 22.2444787 },
    { lat: 49.0310939, lng: 22.2462983 },
    { lat: 49.0310442, lng: 22.247308 },
    { lat: 49.0309063, lng: 22.2480405 },
    { lat: 49.0313264, lng: 22.2477086 },
    { lat: 49.0321924, lng: 22.2476075 },
    { lat: 49.0321758, lng: 22.2463084 },
    { lat: 49.0324884, lng: 22.2461526 },
    { lat: 49.0330686, lng: 22.2460862 },
    { lat: 49.0332328, lng: 22.2463498 },
    { lat: 49.0333774, lng: 22.2477887 },
    { lat: 49.0336184, lng: 22.2477508 },
    { lat: 49.0336212, lng: 22.2483371 },
    { lat: 49.0338371, lng: 22.2485934 },
    { lat: 49.0342355, lng: 22.2487558 },
    { lat: 49.0346503, lng: 22.2474291 },
    { lat: 49.0346439, lng: 22.2468326 },
    { lat: 49.0347375, lng: 22.2464945 },
    { lat: 49.0349685, lng: 22.2465589 },
    { lat: 49.0350198, lng: 22.2470044 },
    { lat: 49.0353219, lng: 22.2471286 },
    { lat: 49.0350203, lng: 22.2486919 },
    { lat: 49.0348942, lng: 22.2488933 },
    { lat: 49.0351902, lng: 22.2491072 },
    { lat: 49.0352844, lng: 22.2494432 },
    { lat: 49.035448, lng: 22.2493187 },
    { lat: 49.0355675, lng: 22.248776 },
    { lat: 49.0357224, lng: 22.2488111 },
    { lat: 49.0359684, lng: 22.248493 },
    { lat: 49.036097, lng: 22.2479033 },
    { lat: 49.0364661, lng: 22.247552 },
    { lat: 49.0366306, lng: 22.2476167 },
    { lat: 49.0367511, lng: 22.2474317 },
    { lat: 49.0369126, lng: 22.2469965 },
    { lat: 49.0371835, lng: 22.246685 },
    { lat: 49.0375977, lng: 22.245892 },
    { lat: 49.0379113, lng: 22.2455809 },
    { lat: 49.0383111, lng: 22.2457045 },
    { lat: 49.0387034, lng: 22.2461002 },
    { lat: 49.0387546, lng: 22.2478579 },
    { lat: 49.0391847, lng: 22.2479219 },
    { lat: 49.0391975, lng: 22.2473409 },
    { lat: 49.0396107, lng: 22.2471519 },
    { lat: 49.0397502, lng: 22.2468013 },
    { lat: 49.0400011, lng: 22.2469032 },
    { lat: 49.0401091, lng: 22.246651 },
    { lat: 49.0405003, lng: 22.2466517 },
    { lat: 49.041012, lng: 22.2452707 },
    { lat: 49.0411997, lng: 22.2456278 },
    { lat: 49.0415854, lng: 22.2448176 },
    { lat: 49.0422408, lng: 22.2446448 },
    { lat: 49.0422826, lng: 22.2441512 },
    { lat: 49.0426354, lng: 22.2433232 },
    { lat: 49.0426252, lng: 22.2430678 },
    { lat: 49.0427477, lng: 22.242713 },
    { lat: 49.0430985, lng: 22.2430065 },
    { lat: 49.0434565, lng: 22.2434843 },
    { lat: 49.043594, lng: 22.2433385 },
    { lat: 49.0443634, lng: 22.2431444 },
    { lat: 49.0448518, lng: 22.2427059 },
    { lat: 49.0451276, lng: 22.2417535 },
    { lat: 49.045258, lng: 22.2410477 },
    { lat: 49.0454785, lng: 22.2410432 },
    { lat: 49.045554, lng: 22.240238 },
    { lat: 49.0455316, lng: 22.2391357 },
    { lat: 49.0456736, lng: 22.2377896 },
    { lat: 49.0453678, lng: 22.2366277 },
    { lat: 49.045882, lng: 22.2361828 },
    { lat: 49.0457191, lng: 22.2354311 },
    { lat: 49.0455268, lng: 22.234933 },
    { lat: 49.046128, lng: 22.2342997 },
    { lat: 49.0457386, lng: 22.2342972 },
    { lat: 49.0454851, lng: 22.2340661 },
    { lat: 49.0469579, lng: 22.232327 },
    { lat: 49.046624, lng: 22.2318831 },
    { lat: 49.0460857, lng: 22.2309953 },
    { lat: 49.0463178, lng: 22.2292545 },
    { lat: 49.0460219, lng: 22.2290468 },
    { lat: 49.0454078, lng: 22.2279327 },
    { lat: 49.0455524, lng: 22.2273036 },
    { lat: 49.0457458, lng: 22.2270609 },
    { lat: 49.0460079, lng: 22.2263038 },
    { lat: 49.0459195, lng: 22.2259749 },
    { lat: 49.0454804, lng: 22.225191 },
    { lat: 49.044721, lng: 22.225032 },
    { lat: 49.043647, lng: 22.2239777 },
    { lat: 49.0435655, lng: 22.2233051 },
    { lat: 49.0437315, lng: 22.22309 },
    { lat: 49.0439376, lng: 22.2230517 },
    { lat: 49.0440126, lng: 22.2228847 },
    { lat: 49.0442109, lng: 22.2227959 },
    { lat: 49.0444199, lng: 22.2222874 },
    { lat: 49.0445441, lng: 22.2216059 },
    { lat: 49.0441439, lng: 22.2212126 },
    { lat: 49.0439192, lng: 22.2208191 },
    { lat: 49.0441103, lng: 22.2205651 },
    { lat: 49.0442847, lng: 22.2198616 },
    { lat: 49.0446823, lng: 22.2185923 },
    { lat: 49.044689, lng: 22.2181323 },
    { lat: 49.0448522, lng: 22.2178435 },
    { lat: 49.0449675, lng: 22.2164056 },
    { lat: 49.0451213, lng: 22.2155429 },
    { lat: 49.0455249, lng: 22.2154424 },
    { lat: 49.0458458, lng: 22.2162055 },
    { lat: 49.0459868, lng: 22.2157256 },
    { lat: 49.0462187, lng: 22.214287 },
    { lat: 49.046055, lng: 22.2134551 },
    { lat: 49.0462027, lng: 22.2127677 },
  ],
  StakčínskaRoztoka: [
    { lat: 48.9860231, lng: 22.2825157 },
    { lat: 48.9862627, lng: 22.2833007 },
    { lat: 48.9864763, lng: 22.2840566 },
    { lat: 48.9865271, lng: 22.2845356 },
    { lat: 48.9868466, lng: 22.2861284 },
    { lat: 48.9872018, lng: 22.2869477 },
    { lat: 48.9877638, lng: 22.2888856 },
    { lat: 48.9879191, lng: 22.2896602 },
    { lat: 48.9880775, lng: 22.2917149 },
    { lat: 48.9885512, lng: 22.2927041 },
    { lat: 48.9893544, lng: 22.2934328 },
    { lat: 48.9911625, lng: 22.2963507 },
    { lat: 48.9915862, lng: 22.2963953 },
    { lat: 48.9920448, lng: 22.2965348 },
    { lat: 48.9935751, lng: 22.2978703 },
    { lat: 48.9939711, lng: 22.2984897 },
    { lat: 48.9939313, lng: 22.2985776 },
    { lat: 48.9941568, lng: 22.2989671 },
    { lat: 48.9943638, lng: 22.298861 },
    { lat: 48.9950177, lng: 22.2994524 },
    { lat: 48.9951426, lng: 22.2995081 },
    { lat: 48.9953169, lng: 22.2992404 },
    { lat: 48.9957235, lng: 22.2994814 },
    { lat: 48.9958729, lng: 22.2994195 },
    { lat: 48.995927, lng: 22.2994766 },
    { lat: 48.9961395, lng: 22.2994222 },
    { lat: 48.9963084, lng: 22.2994828 },
    { lat: 48.9964842, lng: 22.2993422 },
    { lat: 48.9966814, lng: 22.2994154 },
    { lat: 48.9967465, lng: 22.2993634 },
    { lat: 48.9967661, lng: 22.2994821 },
    { lat: 48.9970707, lng: 22.299629 },
    { lat: 48.9973135, lng: 22.2993593 },
    { lat: 48.997531, lng: 22.2995432 },
    { lat: 48.9976413, lng: 22.2994879 },
    { lat: 48.9980516, lng: 22.299588 },
    { lat: 48.9983215, lng: 22.2995354 },
    { lat: 48.9984921, lng: 22.299361 },
    { lat: 48.9985387, lng: 22.2991752 },
    { lat: 48.9986149, lng: 22.2991148 },
    { lat: 48.9990054, lng: 22.2992306 },
    { lat: 48.999319, lng: 22.2991181 },
    { lat: 48.9994187, lng: 22.2991554 },
    { lat: 48.9995151, lng: 22.2994442 },
    { lat: 48.9996764, lng: 22.2996772 },
    { lat: 49.0002057, lng: 22.2998641 },
    { lat: 49.0006112, lng: 22.2997914 },
    { lat: 49.000722, lng: 22.2999126 },
    { lat: 49.0007304, lng: 22.3000523 },
    { lat: 49.0008319, lng: 22.3000572 },
    { lat: 49.0010028, lng: 22.2998967 },
    { lat: 49.001135, lng: 22.3001906 },
    { lat: 49.0011226, lng: 22.3002897 },
    { lat: 49.0011697, lng: 22.3003862 },
    { lat: 49.0015748, lng: 22.3007645 },
    { lat: 49.001876, lng: 22.3014705 },
    { lat: 49.0019333, lng: 22.3017849 },
    { lat: 49.0023933, lng: 22.3023038 },
    { lat: 49.0025094, lng: 22.3025749 },
    { lat: 49.0026183, lng: 22.3025612 },
    { lat: 49.0027077, lng: 22.302796 },
    { lat: 49.0028491, lng: 22.3029346 },
    { lat: 49.0029334, lng: 22.303141 },
    { lat: 49.0036883, lng: 22.3036734 },
    { lat: 49.0039505, lng: 22.303623 },
    { lat: 49.0041644, lng: 22.3038501 },
    { lat: 49.0042876, lng: 22.3038239 },
    { lat: 49.0044283, lng: 22.3040837 },
    { lat: 49.0047588, lng: 22.3049559 },
    { lat: 49.0047544, lng: 22.3051656 },
    { lat: 49.0048982, lng: 22.3057352 },
    { lat: 49.0050316, lng: 22.3058205 },
    { lat: 49.0050136, lng: 22.306132 },
    { lat: 49.005269, lng: 22.3063341 },
    { lat: 49.0055277, lng: 22.3066475 },
    { lat: 49.0056111, lng: 22.3068633 },
    { lat: 49.0058788, lng: 22.3070816 },
    { lat: 49.0061739, lng: 22.3077661 },
    { lat: 49.0065219, lng: 22.3092984 },
    { lat: 49.0069062, lng: 22.3103681 },
    { lat: 49.0069921, lng: 22.3104398 },
    { lat: 49.0072204, lng: 22.3104488 },
    { lat: 49.0073824, lng: 22.310397 },
    { lat: 49.0076357, lng: 22.3102002 },
    { lat: 49.0081374, lng: 22.3101579 },
    { lat: 49.0083112, lng: 22.3099754 },
    { lat: 49.0083876, lng: 22.309968 },
    { lat: 49.0086059, lng: 22.310234 },
    { lat: 49.0087149, lng: 22.3101848 },
    { lat: 49.0092021, lng: 22.3102973 },
    { lat: 49.009322, lng: 22.3105331 },
    { lat: 49.0092593, lng: 22.3110661 },
    { lat: 49.0094548, lng: 22.3120924 },
    { lat: 49.0097106, lng: 22.3132102 },
    { lat: 49.009775, lng: 22.3133306 },
    { lat: 49.0099986, lng: 22.3147814 },
    { lat: 49.0101943, lng: 22.3152413 },
    { lat: 49.0103277, lng: 22.3159522 },
    { lat: 49.0104458, lng: 22.3163118 },
    { lat: 49.0111626, lng: 22.3172784 },
    { lat: 49.0118335, lng: 22.3178794 },
    { lat: 49.0123742, lng: 22.3189057 },
    { lat: 49.0125479, lng: 22.3200238 },
    { lat: 49.0128551, lng: 22.3207598 },
    { lat: 49.0133691, lng: 22.3216037 },
    { lat: 49.0137229, lng: 22.3225688 },
    { lat: 49.0139348, lng: 22.3230072 },
    { lat: 49.0140945, lng: 22.3237376 },
    { lat: 49.0139409, lng: 22.3256975 },
    { lat: 49.0140065, lng: 22.3265993 },
    { lat: 49.0150481, lng: 22.3279143 },
    { lat: 49.0151732, lng: 22.3292922 },
    { lat: 49.0150285, lng: 22.3302467 },
    { lat: 49.015211, lng: 22.3312039 },
    { lat: 49.0152137, lng: 22.331748 },
    { lat: 49.015042, lng: 22.3322338 },
    { lat: 49.0141926, lng: 22.3333751 },
    { lat: 49.014222, lng: 22.3353291 },
    { lat: 49.013892, lng: 22.337029 },
    { lat: 49.0135528, lng: 22.3378365 },
    { lat: 49.0129011, lng: 22.338706 },
    { lat: 49.0127709, lng: 22.3391943 },
    { lat: 49.0126479, lng: 22.3400108 },
    { lat: 49.0127447, lng: 22.3409106 },
    { lat: 49.0129747, lng: 22.3417781 },
    { lat: 49.0139053, lng: 22.3406454 },
    { lat: 49.0146744, lng: 22.3401667 },
    { lat: 49.0154751, lng: 22.3392676 },
    { lat: 49.0157155, lng: 22.3387305 },
    { lat: 49.016286, lng: 22.3368019 },
    { lat: 49.017403, lng: 22.3350858 },
    { lat: 49.0180982, lng: 22.335208 },
    { lat: 49.0182485, lng: 22.3352897 },
    { lat: 49.0183286, lng: 22.3347752 },
    { lat: 49.0177835, lng: 22.3348925 },
    { lat: 49.0174564, lng: 22.3347532 },
    { lat: 49.0178795, lng: 22.333973 },
    { lat: 49.0182426, lng: 22.3326013 },
    { lat: 49.0186194, lng: 22.3314013 },
    { lat: 49.0186293, lng: 22.3309178 },
    { lat: 49.0189684, lng: 22.330424 },
    { lat: 49.019609, lng: 22.3292549 },
    { lat: 49.0203592, lng: 22.327468 },
    { lat: 49.0209289, lng: 22.3267746 },
    { lat: 49.0215794, lng: 22.3263273 },
    { lat: 49.0220267, lng: 22.3258869 },
    { lat: 49.0223022, lng: 22.3249469 },
    { lat: 49.0226155, lng: 22.3246665 },
    { lat: 49.0233814, lng: 22.3246115 },
    { lat: 49.0238082, lng: 22.3255335 },
    { lat: 49.0240335, lng: 22.3259164 },
    { lat: 49.0246162, lng: 22.3255481 },
    { lat: 49.0250186, lng: 22.3255456 },
    { lat: 49.0255036, lng: 22.3244042 },
    { lat: 49.025995, lng: 22.3226803 },
    { lat: 49.0268473, lng: 22.3208926 },
    { lat: 49.0277046, lng: 22.3200253 },
    { lat: 49.0286091, lng: 22.3192616 },
    { lat: 49.0287506, lng: 22.3181529 },
    { lat: 49.0290965, lng: 22.3169272 },
    { lat: 49.0296141, lng: 22.3146058 },
    { lat: 49.0308927, lng: 22.312517 },
    { lat: 49.0309666, lng: 22.3117757 },
    { lat: 49.031604, lng: 22.3098876 },
    { lat: 49.0317445, lng: 22.3078083 },
    { lat: 49.0323864, lng: 22.3065038 },
    { lat: 49.0328164, lng: 22.305946 },
    { lat: 49.0329351, lng: 22.3049412 },
    { lat: 49.0331667, lng: 22.303635 },
    { lat: 49.03384, lng: 22.3027987 },
    { lat: 49.0346749, lng: 22.3023947 },
    { lat: 49.0350721, lng: 22.3019439 },
    { lat: 49.0354317, lng: 22.3012585 },
    { lat: 49.0351473, lng: 22.2998634 },
    { lat: 49.0349101, lng: 22.2990968 },
    { lat: 49.0346455, lng: 22.2991345 },
    { lat: 49.0341639, lng: 22.2992243 },
    { lat: 49.0338117, lng: 22.2990549 },
    { lat: 49.0330401, lng: 22.2983834 },
    { lat: 49.0325599, lng: 22.2974011 },
    { lat: 49.0323478, lng: 22.2965473 },
    { lat: 49.0319224, lng: 22.2954945 },
    { lat: 49.0318318, lng: 22.2944386 },
    { lat: 49.0318322, lng: 22.2933662 },
    { lat: 49.0319471, lng: 22.2919339 },
    { lat: 49.0317803, lng: 22.2910417 },
    { lat: 49.0319214, lng: 22.2904984 },
    { lat: 49.03145, lng: 22.2904394 },
    { lat: 49.0311357, lng: 22.2895907 },
    { lat: 49.0310512, lng: 22.2889671 },
    { lat: 49.0305591, lng: 22.2882138 },
    { lat: 49.0303669, lng: 22.2882157 },
    { lat: 49.0300015, lng: 22.2872001 },
    { lat: 49.0297673, lng: 22.2858429 },
    { lat: 49.0293106, lng: 22.2851725 },
    { lat: 49.0286668, lng: 22.2839675 },
    { lat: 49.0274454, lng: 22.2814404 },
    { lat: 49.0267408, lng: 22.2811632 },
    { lat: 49.0263103, lng: 22.2807162 },
    { lat: 49.0263157, lng: 22.2803386 },
    { lat: 49.0251058, lng: 22.2792234 },
    { lat: 49.0247251, lng: 22.2791947 },
    { lat: 49.024123, lng: 22.2786704 },
    { lat: 49.0236157, lng: 22.2781497 },
    { lat: 49.0232912, lng: 22.2779124 },
    { lat: 49.0228286, lng: 22.2772786 },
    { lat: 49.0218323, lng: 22.2763717 },
    { lat: 49.0212562, lng: 22.2763006 },
    { lat: 49.020645, lng: 22.2761273 },
    { lat: 49.0193023, lng: 22.2752631 },
    { lat: 49.0178908, lng: 22.272652 },
    { lat: 49.0170509, lng: 22.271991 },
    { lat: 49.0165901, lng: 22.2717507 },
    { lat: 49.015852, lng: 22.2715384 },
    { lat: 49.0148718, lng: 22.2709895 },
    { lat: 49.0144552, lng: 22.2709702 },
    { lat: 49.013897, lng: 22.2699807 },
    { lat: 49.0133245, lng: 22.2700284 },
    { lat: 49.0124421, lng: 22.2697935 },
    { lat: 49.011362, lng: 22.2690108 },
    { lat: 49.0095891, lng: 22.2669453 },
    { lat: 49.0090811, lng: 22.2661497 },
    { lat: 49.0087153, lng: 22.2658796 },
    { lat: 49.0080825, lng: 22.264467 },
    { lat: 49.0074004, lng: 22.2635266 },
    { lat: 49.0073113, lng: 22.2638683 },
    { lat: 49.006692, lng: 22.2631755 },
    { lat: 49.0057034, lng: 22.2631238 },
    { lat: 49.0055326, lng: 22.263692 },
    { lat: 49.0048881, lng: 22.2634824 },
    { lat: 49.0047536, lng: 22.2636035 },
    { lat: 49.0039196, lng: 22.2632219 },
    { lat: 49.0032734, lng: 22.2636044 },
    { lat: 49.0015723, lng: 22.2642882 },
    { lat: 49.0012083, lng: 22.2627257 },
    { lat: 49.0008284, lng: 22.2627873 },
    { lat: 49.0005955, lng: 22.2628805 },
    { lat: 49.0003741, lng: 22.2630934 },
    { lat: 49.0000766, lng: 22.2635782 },
    { lat: 48.9997923, lng: 22.263842 },
    { lat: 48.9978625, lng: 22.2650227 },
    { lat: 48.9971719, lng: 22.265127 },
    { lat: 48.9966211, lng: 22.2653947 },
    { lat: 48.9963373, lng: 22.2661906 },
    { lat: 48.9963524, lng: 22.2671343 },
    { lat: 48.9959909, lng: 22.2670059 },
    { lat: 48.9955577, lng: 22.2669971 },
    { lat: 48.9949026, lng: 22.2672221 },
    { lat: 48.9937282, lng: 22.2671128 },
    { lat: 48.9923047, lng: 22.266879 },
    { lat: 48.9918024, lng: 22.2669493 },
    { lat: 48.9908808, lng: 22.2680971 },
    { lat: 48.990111, lng: 22.2686831 },
    { lat: 48.9894206, lng: 22.2698111 },
    { lat: 48.9893631, lng: 22.2703723 },
    { lat: 48.9890897, lng: 22.2716435 },
    { lat: 48.988838, lng: 22.2731869 },
    { lat: 48.9885222, lng: 22.2739177 },
    { lat: 48.9877419, lng: 22.2754599 },
    { lat: 48.9867402, lng: 22.2788624 },
    { lat: 48.9861798, lng: 22.2810529 },
    { lat: 48.9860231, lng: 22.2825157 },
  ],
  Ubľa: [
    { lat: 48.8896366, lng: 22.4215572 },
    { lat: 48.893843, lng: 22.421922 },
    { lat: 48.896848, lng: 22.422182 },
    { lat: 48.901512, lng: 22.42258 },
    { lat: 48.902282, lng: 22.421509 },
    { lat: 48.902951, lng: 22.421008 },
    { lat: 48.903483, lng: 22.42074 },
    { lat: 48.903862, lng: 22.421445 },
    { lat: 48.904164, lng: 22.42222 },
    { lat: 48.904584, lng: 22.423431 },
    { lat: 48.905205, lng: 22.423358 },
    { lat: 48.906632, lng: 22.423884 },
    { lat: 48.907586, lng: 22.42385 },
    { lat: 48.908135, lng: 22.423633 },
    { lat: 48.908704, lng: 22.424111 },
    { lat: 48.909691, lng: 22.425103 },
    { lat: 48.910573, lng: 22.425217 },
    { lat: 48.911704, lng: 22.424568 },
    { lat: 48.912571, lng: 22.424806 },
    { lat: 48.913583, lng: 22.426133 },
    { lat: 48.91383, lng: 22.426174 },
    { lat: 48.914229, lng: 22.425988 },
    { lat: 48.915491, lng: 22.427336 },
    { lat: 48.917029, lng: 22.429661 },
    { lat: 48.918083, lng: 22.430843 },
    { lat: 48.918682, lng: 22.431224 },
    { lat: 48.919252, lng: 22.431548 },
    { lat: 48.920787, lng: 22.431338 },
    { lat: 48.921887, lng: 22.431179 },
    { lat: 48.922937, lng: 22.428341 },
    { lat: 48.923509, lng: 22.427744 },
    { lat: 48.92437, lng: 22.426294 },
    { lat: 48.925295, lng: 22.425714 },
    { lat: 48.926168, lng: 22.425286 },
    { lat: 48.929329, lng: 22.424921 },
    { lat: 48.929869, lng: 22.426351 },
    { lat: 48.931736, lng: 22.428291 },
    { lat: 48.9317837, lng: 22.4279156 },
    { lat: 48.9318693, lng: 22.4261843 },
    { lat: 48.9319471, lng: 22.425752 },
    { lat: 48.9319222, lng: 22.4252096 },
    { lat: 48.9323681, lng: 22.4216519 },
    { lat: 48.9326522, lng: 22.4204887 },
    { lat: 48.9328594, lng: 22.4193635 },
    { lat: 48.9333135, lng: 22.4181042 },
    { lat: 48.9335405, lng: 22.417662 },
    { lat: 48.9336067, lng: 22.4166873 },
    { lat: 48.9337702, lng: 22.4160478 },
    { lat: 48.9341004, lng: 22.4151081 },
    { lat: 48.9343871, lng: 22.4148905 },
    { lat: 48.9360444, lng: 22.4146004 },
    { lat: 48.9367319, lng: 22.4142412 },
    { lat: 48.937511, lng: 22.4134702 },
    { lat: 48.9381212, lng: 22.4121463 },
    { lat: 48.9386627, lng: 22.4111466 },
    { lat: 48.9393475, lng: 22.4106029 },
    { lat: 48.9404612, lng: 22.4101928 },
    { lat: 48.9410516, lng: 22.409542 },
    { lat: 48.9413706, lng: 22.4079075 },
    { lat: 48.941591, lng: 22.4066656 },
    { lat: 48.9405558, lng: 22.4058213 },
    { lat: 48.9400205, lng: 22.4042709 },
    { lat: 48.9395652, lng: 22.4036857 },
    { lat: 48.9384111, lng: 22.4025969 },
    { lat: 48.9376488, lng: 22.4020133 },
    { lat: 48.9367107, lng: 22.4017354 },
    { lat: 48.9356597, lng: 22.4011085 },
    { lat: 48.9348741, lng: 22.4005487 },
    { lat: 48.9346053, lng: 22.4004872 },
    { lat: 48.9342636, lng: 22.4000719 },
    { lat: 48.9334378, lng: 22.3994625 },
    { lat: 48.9328661, lng: 22.3991821 },
    { lat: 48.9320203, lng: 22.3986384 },
    { lat: 48.9309438, lng: 22.3977057 },
    { lat: 48.9300531, lng: 22.3962586 },
    { lat: 48.9291981, lng: 22.3951238 },
    { lat: 48.9286292, lng: 22.3941119 },
    { lat: 48.9282543, lng: 22.3927996 },
    { lat: 48.9281561, lng: 22.392084 },
    { lat: 48.9281869, lng: 22.3917892 },
    { lat: 48.9282292, lng: 22.3916044 },
    { lat: 48.9289463, lng: 22.3899735 },
    { lat: 48.9294775, lng: 22.3893576 },
    { lat: 48.9298049, lng: 22.3888221 },
    { lat: 48.9296853, lng: 22.3884327 },
    { lat: 48.9290504, lng: 22.3871246 },
    { lat: 48.9288605, lng: 22.3865412 },
    { lat: 48.9283181, lng: 22.385193 },
    { lat: 48.9281356, lng: 22.384851 },
    { lat: 48.9290239, lng: 22.3831305 },
    { lat: 48.9291049, lng: 22.3827581 },
    { lat: 48.9294567, lng: 22.3822203 },
    { lat: 48.9304137, lng: 22.3813104 },
    { lat: 48.9309416, lng: 22.3802622 },
    { lat: 48.9313013, lng: 22.3797733 },
    { lat: 48.9318851, lng: 22.379252 },
    { lat: 48.933061, lng: 22.3797053 },
    { lat: 48.9328696, lng: 22.379212 },
    { lat: 48.9328125, lng: 22.3790646 },
    { lat: 48.9321261, lng: 22.3784663 },
    { lat: 48.9318026, lng: 22.3778414 },
    { lat: 48.9315102, lng: 22.377632 },
    { lat: 48.9288967, lng: 22.378169 },
    { lat: 48.9285374, lng: 22.3782902 },
    { lat: 48.928153, lng: 22.3784198 },
    { lat: 48.9280219, lng: 22.3780141 },
    { lat: 48.9273637, lng: 22.3773666 },
    { lat: 48.925783, lng: 22.3729697 },
    { lat: 48.9261177, lng: 22.3721584 },
    { lat: 48.9259048, lng: 22.3701749 },
    { lat: 48.9249444, lng: 22.3708619 },
    { lat: 48.9251927, lng: 22.3714899 },
    { lat: 48.9247576, lng: 22.3717662 },
    { lat: 48.9244874, lng: 22.3715372 },
    { lat: 48.9246268, lng: 22.3695176 },
    { lat: 48.9244545, lng: 22.3688147 },
    { lat: 48.9240011, lng: 22.3681183 },
    { lat: 48.9231531, lng: 22.3660469 },
    { lat: 48.9227939, lng: 22.3633808 },
    { lat: 48.9222882, lng: 22.3611751 },
    { lat: 48.9226988, lng: 22.3594402 },
    { lat: 48.9240302, lng: 22.3544621 },
    { lat: 48.9239579, lng: 22.3529168 },
    { lat: 48.9234541, lng: 22.3521272 },
    { lat: 48.9222171, lng: 22.3509678 },
    { lat: 48.9220381, lng: 22.3509626 },
    { lat: 48.9215342, lng: 22.3503841 },
    { lat: 48.9210555, lng: 22.3500282 },
    { lat: 48.9203728, lng: 22.349879 },
    { lat: 48.9195687, lng: 22.3499203 },
    { lat: 48.9186577, lng: 22.3494534 },
    { lat: 48.9183396, lng: 22.3492434 },
    { lat: 48.9178748, lng: 22.3482832 },
    { lat: 48.9177976, lng: 22.3476251 },
    { lat: 48.9174969, lng: 22.3478152 },
    { lat: 48.9171442, lng: 22.3477857 },
    { lat: 48.9167091, lng: 22.347948 },
    { lat: 48.9164682, lng: 22.3482977 },
    { lat: 48.9155767, lng: 22.3487396 },
    { lat: 48.9152122, lng: 22.3492628 },
    { lat: 48.9149921, lng: 22.3505437 },
    { lat: 48.914854, lng: 22.350499 },
    { lat: 48.9145789, lng: 22.3509952 },
    { lat: 48.9143288, lng: 22.3511334 },
    { lat: 48.9140035, lng: 22.3510117 },
    { lat: 48.9110711, lng: 22.3507822 },
    { lat: 48.9102893, lng: 22.3508782 },
    { lat: 48.9101621, lng: 22.3506138 },
    { lat: 48.9095464, lng: 22.350742 },
    { lat: 48.9095022, lng: 22.3508904 },
    { lat: 48.9090419, lng: 22.3508744 },
    { lat: 48.9086404, lng: 22.3506599 },
    { lat: 48.9079666, lng: 22.3504918 },
    { lat: 48.9077973, lng: 22.3502573 },
    { lat: 48.9076007, lng: 22.3501665 },
    { lat: 48.9069972, lng: 22.3501358 },
    { lat: 48.9067681, lng: 22.3504656 },
    { lat: 48.906368, lng: 22.3507909 },
    { lat: 48.9059648, lng: 22.3509798 },
    { lat: 48.9049146, lng: 22.351197 },
    { lat: 48.9032125, lng: 22.3511619 },
    { lat: 48.9027225, lng: 22.3513887 },
    { lat: 48.9021751, lng: 22.3521416 },
    { lat: 48.9018279, lng: 22.3518959 },
    { lat: 48.9012949, lng: 22.3512186 },
    { lat: 48.9011864, lng: 22.350983 },
    { lat: 48.9004727, lng: 22.3504132 },
    { lat: 48.8997186, lng: 22.35055 },
    { lat: 48.899331, lng: 22.3509948 },
    { lat: 48.8991222, lng: 22.351005 },
    { lat: 48.8991226, lng: 22.3510265 },
    { lat: 48.8990558, lng: 22.3517457 },
    { lat: 48.8988624, lng: 22.3520605 },
    { lat: 48.8983374, lng: 22.3532373 },
    { lat: 48.898279, lng: 22.3538117 },
    { lat: 48.8981555, lng: 22.3539901 },
    { lat: 48.8979524, lng: 22.3540558 },
    { lat: 48.8974651, lng: 22.354469 },
    { lat: 48.8966629, lng: 22.3559309 },
    { lat: 48.8961834, lng: 22.3561235 },
    { lat: 48.895639, lng: 22.3564883 },
    { lat: 48.8953441, lng: 22.3568903 },
    { lat: 48.8949542, lng: 22.357596 },
    { lat: 48.8948344, lng: 22.3579453 },
    { lat: 48.8945821, lng: 22.3584048 },
    { lat: 48.8941774, lng: 22.3584018 },
    { lat: 48.8934493, lng: 22.3585433 },
    { lat: 48.8924521, lng: 22.3584602 },
    { lat: 48.8920326, lng: 22.3585619 },
    { lat: 48.8913587, lng: 22.3583443 },
    { lat: 48.8908014, lng: 22.3583702 },
    { lat: 48.8904552, lng: 22.3580058 },
    { lat: 48.88999, lng: 22.3577059 },
    { lat: 48.8892644, lng: 22.3576986 },
    { lat: 48.8889595, lng: 22.357325 },
    { lat: 48.8882313, lng: 22.3573046 },
    { lat: 48.8879671, lng: 22.3570927 },
    { lat: 48.8873886, lng: 22.3575489 },
    { lat: 48.8873851, lng: 22.3583821 },
    { lat: 48.8871859, lng: 22.3587553 },
    { lat: 48.887023, lng: 22.3595502 },
    { lat: 48.8864967, lng: 22.3610326 },
    { lat: 48.8863183, lng: 22.3616874 },
    { lat: 48.8864265, lng: 22.3630716 },
    { lat: 48.8869251, lng: 22.3634124 },
    { lat: 48.8870156, lng: 22.363854 },
    { lat: 48.886896, lng: 22.3643159 },
    { lat: 48.8862843, lng: 22.365698 },
    { lat: 48.8860913, lng: 22.3666216 },
    { lat: 48.8856258, lng: 22.3681599 },
    { lat: 48.8851207, lng: 22.3687913 },
    { lat: 48.8839586, lng: 22.3697821 },
    { lat: 48.8832813, lng: 22.3701367 },
    { lat: 48.8829889, lng: 22.3704668 },
    { lat: 48.8825777, lng: 22.3713341 },
    { lat: 48.8822216, lng: 22.3715666 },
    { lat: 48.8821441, lng: 22.3723704 },
    { lat: 48.8819657, lng: 22.3725507 },
    { lat: 48.8816167, lng: 22.3724747 },
    { lat: 48.8813678, lng: 22.3729946 },
    { lat: 48.8810694, lng: 22.3734501 },
    { lat: 48.8806624, lng: 22.3738851 },
    { lat: 48.8805122, lng: 22.3743266 },
    { lat: 48.8801439, lng: 22.3747847 },
    { lat: 48.8798721, lng: 22.3754961 },
    { lat: 48.8791232, lng: 22.376881 },
    { lat: 48.8789218, lng: 22.3769845 },
    { lat: 48.8785653, lng: 22.3773026 },
    { lat: 48.8780674, lng: 22.3772153 },
    { lat: 48.8775377, lng: 22.3776866 },
    { lat: 48.8774463, lng: 22.3778354 },
    { lat: 48.8775632, lng: 22.3784704 },
    { lat: 48.8773064, lng: 22.3798946 },
    { lat: 48.8771392, lng: 22.3803347 },
    { lat: 48.8770134, lng: 22.3810396 },
    { lat: 48.8770379, lng: 22.3812425 },
    { lat: 48.8767796, lng: 22.3816334 },
    { lat: 48.8766072, lng: 22.3816131 },
    { lat: 48.876253, lng: 22.3817279 },
    { lat: 48.87583, lng: 22.3823097 },
    { lat: 48.8756004, lng: 22.3832008 },
    { lat: 48.8755176, lng: 22.3836983 },
    { lat: 48.8753772, lng: 22.3839852 },
    { lat: 48.8754019, lng: 22.3841355 },
    { lat: 48.8753254, lng: 22.384724 },
    { lat: 48.874788, lng: 22.3856397 },
    { lat: 48.8745729, lng: 22.3862686 },
    { lat: 48.8744577, lng: 22.3864042 },
    { lat: 48.874199, lng: 22.3869873 },
    { lat: 48.8732923, lng: 22.3878379 },
    { lat: 48.8727807, lng: 22.3886454 },
    { lat: 48.8721237, lng: 22.3891163 },
    { lat: 48.8713401, lng: 22.3894535 },
    { lat: 48.8717141, lng: 22.3904397 },
    { lat: 48.8718597, lng: 22.3913233 },
    { lat: 48.871823, lng: 22.3916102 },
    { lat: 48.8715153, lng: 22.3917282 },
    { lat: 48.8733599, lng: 22.3943678 },
    { lat: 48.8772034, lng: 22.3945494 },
    { lat: 48.883524, lng: 22.419314 },
    { lat: 48.8840368, lng: 22.4201651 },
    { lat: 48.8841553, lng: 22.4203617 },
    { lat: 48.884602, lng: 22.421124 },
    { lat: 48.885208, lng: 22.421176 },
    { lat: 48.888885, lng: 22.421492 },
    { lat: 48.8896366, lng: 22.4215572 },
  ],
  HrabováRoztoka: [
    { lat: 48.8893005, lng: 22.2962365 },
    { lat: 48.8894511, lng: 22.2953082 },
    { lat: 48.8896963, lng: 22.2950014 },
    { lat: 48.8898578, lng: 22.2945584 },
    { lat: 48.8898084, lng: 22.2934858 },
    { lat: 48.8896777, lng: 22.292651 },
    { lat: 48.8891763, lng: 22.2913731 },
    { lat: 48.8890272, lng: 22.2908814 },
    { lat: 48.888337, lng: 22.2898775 },
    { lat: 48.8875585, lng: 22.2885297 },
    { lat: 48.886228, lng: 22.2865782 },
    { lat: 48.8850717, lng: 22.2853517 },
    { lat: 48.8844964, lng: 22.2843926 },
    { lat: 48.8833133, lng: 22.282763 },
    { lat: 48.8833132, lng: 22.2821491 },
    { lat: 48.8832406, lng: 22.281299 },
    { lat: 48.8831409, lng: 22.2809035 },
    { lat: 48.8829644, lng: 22.2806572 },
    { lat: 48.8829908, lng: 22.2805708 },
    { lat: 48.8828603, lng: 22.2804656 },
    { lat: 48.8822493, lng: 22.2803563 },
    { lat: 48.882097, lng: 22.2799752 },
    { lat: 48.8817194, lng: 22.2796323 },
    { lat: 48.8812896, lng: 22.2788536 },
    { lat: 48.8811767, lng: 22.2783276 },
    { lat: 48.8810717, lng: 22.2781496 },
    { lat: 48.8809596, lng: 22.2777168 },
    { lat: 48.8808626, lng: 22.2776448 },
    { lat: 48.8808385, lng: 22.2773187 },
    { lat: 48.8805364, lng: 22.2764228 },
    { lat: 48.8803261, lng: 22.2760114 },
    { lat: 48.8801511, lng: 22.2758795 },
    { lat: 48.8801091, lng: 22.2757583 },
    { lat: 48.8797172, lng: 22.275483 },
    { lat: 48.8797116, lng: 22.2751738 },
    { lat: 48.879592, lng: 22.2748061 },
    { lat: 48.8796136, lng: 22.2747596 },
    { lat: 48.8788576, lng: 22.2726568 },
    { lat: 48.8786812, lng: 22.2717928 },
    { lat: 48.8786176, lng: 22.2707465 },
    { lat: 48.8786884, lng: 22.2698353 },
    { lat: 48.8785981, lng: 22.2684446 },
    { lat: 48.8788929, lng: 22.2657989 },
    { lat: 48.8789011, lng: 22.2650996 },
    { lat: 48.8784178, lng: 22.2644009 },
    { lat: 48.8777178, lng: 22.2623026 },
    { lat: 48.8776862, lng: 22.2612783 },
    { lat: 48.877435, lng: 22.2602895 },
    { lat: 48.8768911, lng: 22.2591988 },
    { lat: 48.8761397, lng: 22.2573666 },
    { lat: 48.8754194, lng: 22.2562288 },
    { lat: 48.8749224, lng: 22.2550704 },
    { lat: 48.8749452, lng: 22.253818 },
    { lat: 48.8736991, lng: 22.2531162 },
    { lat: 48.8730321, lng: 22.2524986 },
    { lat: 48.8722308, lng: 22.2523276 },
    { lat: 48.8711541, lng: 22.2524623 },
    { lat: 48.8710519, lng: 22.252221 },
    { lat: 48.8707958, lng: 22.2520772 },
    { lat: 48.8707225, lng: 22.2521217 },
    { lat: 48.8703162, lng: 22.2530777 },
    { lat: 48.8703651, lng: 22.2531438 },
    { lat: 48.8702905, lng: 22.253437 },
    { lat: 48.8696449, lng: 22.2544007 },
    { lat: 48.869087, lng: 22.2555538 },
    { lat: 48.8689007, lng: 22.2554215 },
    { lat: 48.8686691, lng: 22.2560438 },
    { lat: 48.8679534, lng: 22.2568337 },
    { lat: 48.8673633, lng: 22.2572586 },
    { lat: 48.8666498, lng: 22.2573928 },
    { lat: 48.8664262, lng: 22.2573989 },
    { lat: 48.8657738, lng: 22.2575989 },
    { lat: 48.865886, lng: 22.2582484 },
    { lat: 48.8660868, lng: 22.2589805 },
    { lat: 48.866211, lng: 22.2598612 },
    { lat: 48.8662958, lng: 22.2610537 },
    { lat: 48.8662862, lng: 22.261735 },
    { lat: 48.8662355, lng: 22.2623421 },
    { lat: 48.8662233, lng: 22.2634744 },
    { lat: 48.8663079, lng: 22.2643997 },
    { lat: 48.8665465, lng: 22.2652649 },
    { lat: 48.8666858, lng: 22.2657137 },
    { lat: 48.8666648, lng: 22.2657362 },
    { lat: 48.8668943, lng: 22.26615 },
    { lat: 48.8669674, lng: 22.2662978 },
    { lat: 48.8670549, lng: 22.2664941 },
    { lat: 48.8671212, lng: 22.2666469 },
    { lat: 48.867185, lng: 22.2668355 },
    { lat: 48.8672363, lng: 22.2669576 },
    { lat: 48.8672453, lng: 22.2671661 },
    { lat: 48.8672563, lng: 22.2675642 },
    { lat: 48.8672688, lng: 22.2676718 },
    { lat: 48.8674639, lng: 22.2680535 },
    { lat: 48.8674273, lng: 22.2683297 },
    { lat: 48.867475, lng: 22.2685588 },
    { lat: 48.8675107, lng: 22.2687985 },
    { lat: 48.8675674, lng: 22.2691225 },
    { lat: 48.8675627, lng: 22.2693717 },
    { lat: 48.8675669, lng: 22.2696151 },
    { lat: 48.867586, lng: 22.2697646 },
    { lat: 48.8676401, lng: 22.2700237 },
    { lat: 48.8675262, lng: 22.2704189 },
    { lat: 48.8675151, lng: 22.2705248 },
    { lat: 48.8674999, lng: 22.2706481 },
    { lat: 48.8674693, lng: 22.2707468 },
    { lat: 48.8674266, lng: 22.2708505 },
    { lat: 48.8674131, lng: 22.2709444 },
    { lat: 48.8673858, lng: 22.2712866 },
    { lat: 48.8674362, lng: 22.2714323 },
    { lat: 48.8675421, lng: 22.2718017 },
    { lat: 48.8675408, lng: 22.2718375 },
    { lat: 48.8674759, lng: 22.2719097 },
    { lat: 48.867444, lng: 22.2720439 },
    { lat: 48.8674031, lng: 22.2722306 },
    { lat: 48.8673927, lng: 22.272313 },
    { lat: 48.8674729, lng: 22.2724907 },
    { lat: 48.8675126, lng: 22.2726006 },
    { lat: 48.8675301, lng: 22.272679 },
    { lat: 48.8675468, lng: 22.2728818 },
    { lat: 48.8675717, lng: 22.2732395 },
    { lat: 48.8675873, lng: 22.2734895 },
    { lat: 48.8676668, lng: 22.2736852 },
    { lat: 48.8677317, lng: 22.2738677 },
    { lat: 48.867804, lng: 22.2740391 },
    { lat: 48.8678611, lng: 22.2743281 },
    { lat: 48.8678929, lng: 22.2745558 },
    { lat: 48.8679312, lng: 22.2747068 },
    { lat: 48.8681097, lng: 22.2749925 },
    { lat: 48.8680825, lng: 22.275394 },
    { lat: 48.8680804, lng: 22.275447 },
    { lat: 48.8680113, lng: 22.2757863 },
    { lat: 48.867935, lng: 22.2762078 },
    { lat: 48.8679039, lng: 22.2764783 },
    { lat: 48.8678808, lng: 22.2767021 },
    { lat: 48.8678463, lng: 22.2768239 },
    { lat: 48.8678263, lng: 22.2769353 },
    { lat: 48.8678046, lng: 22.2770285 },
    { lat: 48.8677395, lng: 22.2772312 },
    { lat: 48.8677818, lng: 22.2776199 },
    { lat: 48.8678115, lng: 22.2778059 },
    { lat: 48.8679025, lng: 22.2779967 },
    { lat: 48.8679215, lng: 22.278253 },
    { lat: 48.867939, lng: 22.2784797 },
    { lat: 48.8679142, lng: 22.2786381 },
    { lat: 48.8678679, lng: 22.2789313 },
    { lat: 48.8679298, lng: 22.279141 },
    { lat: 48.867911, lng: 22.2793588 },
    { lat: 48.8678926, lng: 22.2799496 },
    { lat: 48.8680287, lng: 22.280156 },
    { lat: 48.8681066, lng: 22.2803782 },
    { lat: 48.86819, lng: 22.2805355 },
    { lat: 48.8682742, lng: 22.2806334 },
    { lat: 48.8682573, lng: 22.28068 },
    { lat: 48.868223, lng: 22.2810629 },
    { lat: 48.868363, lng: 22.2813406 },
    { lat: 48.8687194, lng: 22.2818467 },
    { lat: 48.8689481, lng: 22.2823764 },
    { lat: 48.8691121, lng: 22.2830397 },
    { lat: 48.8691203, lng: 22.2831011 },
    { lat: 48.8691464, lng: 22.2832717 },
    { lat: 48.8691659, lng: 22.2836983 },
    { lat: 48.8692551, lng: 22.2839153 },
    { lat: 48.8691729, lng: 22.2843728 },
    { lat: 48.8691326, lng: 22.2848972 },
    { lat: 48.8690262, lng: 22.2851708 },
    { lat: 48.8690071, lng: 22.28517 },
    { lat: 48.8690106, lng: 22.2854246 },
    { lat: 48.8690506, lng: 22.2855619 },
    { lat: 48.8690833, lng: 22.2856699 },
    { lat: 48.8691205, lng: 22.285772 },
    { lat: 48.869128, lng: 22.2858138 },
    { lat: 48.8691295, lng: 22.2859262 },
    { lat: 48.869134, lng: 22.2860861 },
    { lat: 48.8690604, lng: 22.2863195 },
    { lat: 48.8691122, lng: 22.2864694 },
    { lat: 48.8691188, lng: 22.286523 },
    { lat: 48.8691048, lng: 22.2866463 },
    { lat: 48.8691677, lng: 22.2868503 },
    { lat: 48.8692077, lng: 22.2869758 },
    { lat: 48.8692617, lng: 22.2870314 },
    { lat: 48.8693041, lng: 22.2870746 },
    { lat: 48.8693271, lng: 22.2870931 },
    { lat: 48.8693692, lng: 22.2873257 },
    { lat: 48.8693717, lng: 22.2874381 },
    { lat: 48.8693804, lng: 22.2875745 },
    { lat: 48.8694642, lng: 22.2877021 },
    { lat: 48.8695248, lng: 22.2877994 },
    { lat: 48.8697039, lng: 22.2880137 },
    { lat: 48.8697376, lng: 22.2881217 },
    { lat: 48.869925, lng: 22.288307 },
    { lat: 48.8699754, lng: 22.2885513 },
    { lat: 48.8700764, lng: 22.2887509 },
    { lat: 48.8700587, lng: 22.2888686 },
    { lat: 48.8700826, lng: 22.289035 },
    { lat: 48.8701113, lng: 22.289214 },
    { lat: 48.8701161, lng: 22.2893795 },
    { lat: 48.8701647, lng: 22.2894878 },
    { lat: 48.8701438, lng: 22.2897711 },
    { lat: 48.8701462, lng: 22.2898835 },
    { lat: 48.8701123, lng: 22.2900238 },
    { lat: 48.8701256, lng: 22.2901661 },
    { lat: 48.870099, lng: 22.2903425 },
    { lat: 48.8700501, lng: 22.2906243 },
    { lat: 48.870007, lng: 22.2908648 },
    { lat: 48.8699764, lng: 22.2910585 },
    { lat: 48.869761, lng: 22.2915463 },
    { lat: 48.8697295, lng: 22.2917519 },
    { lat: 48.8696435, lng: 22.2919909 },
    { lat: 48.8696948, lng: 22.2923774 },
    { lat: 48.8696161, lng: 22.2928411 },
    { lat: 48.8695322, lng: 22.2933759 },
    { lat: 48.8695349, lng: 22.2936537 },
    { lat: 48.8694808, lng: 22.2938522 },
    { lat: 48.8694731, lng: 22.2940709 },
    { lat: 48.8694742, lng: 22.2942187 },
    { lat: 48.8694437, lng: 22.2944185 },
    { lat: 48.869497, lng: 22.2946988 },
    { lat: 48.869383, lng: 22.2951965 },
    { lat: 48.8692936, lng: 22.2955832 },
    { lat: 48.8693493, lng: 22.2961706 },
    { lat: 48.8692861, lng: 22.2964282 },
    { lat: 48.8693644, lng: 22.2966146 },
    { lat: 48.8691942, lng: 22.296998 },
    { lat: 48.8692339, lng: 22.2971771 },
    { lat: 48.869296, lng: 22.2973865 },
    { lat: 48.869212, lng: 22.2975129 },
    { lat: 48.8692738, lng: 22.2977638 },
    { lat: 48.8692484, lng: 22.2980881 },
    { lat: 48.8693076, lng: 22.2982327 },
    { lat: 48.8692878, lng: 22.2986634 },
    { lat: 48.8693249, lng: 22.2988188 },
    { lat: 48.8692407, lng: 22.2989216 },
    { lat: 48.8692399, lng: 22.2989924 },
    { lat: 48.8692822, lng: 22.2991835 },
    { lat: 48.8693382, lng: 22.2993218 },
    { lat: 48.8692837, lng: 22.2995561 },
    { lat: 48.8692028, lng: 22.2997123 },
    { lat: 48.8692916, lng: 22.2997636 },
    { lat: 48.8692911, lng: 22.2997986 },
    { lat: 48.8690368, lng: 22.3002494 },
    { lat: 48.8690421, lng: 22.3006457 },
    { lat: 48.8688162, lng: 22.3010146 },
    { lat: 48.868744, lng: 22.301165 },
    { lat: 48.8686966, lng: 22.3012046 },
    { lat: 48.8686557, lng: 22.3012856 },
    { lat: 48.8685848, lng: 22.3013416 },
    { lat: 48.8684955, lng: 22.3015272 },
    { lat: 48.8684024, lng: 22.3014819 },
    { lat: 48.8682523, lng: 22.3019842 },
    { lat: 48.8683227, lng: 22.3020107 },
    { lat: 48.8683134, lng: 22.3020515 },
    { lat: 48.868258, lng: 22.3021379 },
    { lat: 48.8682784, lng: 22.3022926 },
    { lat: 48.8684103, lng: 22.30257 },
    { lat: 48.8684515, lng: 22.3026548 },
    { lat: 48.868485, lng: 22.3027449 },
    { lat: 48.8684909, lng: 22.3028104 },
    { lat: 48.8687378, lng: 22.3029863 },
    { lat: 48.8688304, lng: 22.303475 },
    { lat: 48.8689622, lng: 22.3034863 },
    { lat: 48.8690594, lng: 22.3037093 },
    { lat: 48.8690821, lng: 22.3037754 },
    { lat: 48.8691404, lng: 22.3038132 },
    { lat: 48.869159, lng: 22.3038437 },
    { lat: 48.8691512, lng: 22.3040502 },
    { lat: 48.8691627, lng: 22.3040686 },
    { lat: 48.8692404, lng: 22.3040657 },
    { lat: 48.8692673, lng: 22.3041201 },
    { lat: 48.869175, lng: 22.3042641 },
    { lat: 48.869259, lng: 22.3043563 },
    { lat: 48.869267, lng: 22.3045163 },
    { lat: 48.8692784, lng: 22.3045762 },
    { lat: 48.8693563, lng: 22.304742 },
    { lat: 48.8693486, lng: 22.3050078 },
    { lat: 48.8693738, lng: 22.3052334 },
    { lat: 48.8694064, lng: 22.3054415 },
    { lat: 48.8694301, lng: 22.305655 },
    { lat: 48.869432, lng: 22.3057971 },
    { lat: 48.8695592, lng: 22.3059543 },
    { lat: 48.8696307, lng: 22.3061873 },
    { lat: 48.8697455, lng: 22.3063505 },
    { lat: 48.8697517, lng: 22.3064274 },
    { lat: 48.8697433, lng: 22.30651 },
    { lat: 48.8696953, lng: 22.306621 },
    { lat: 48.8696499, lng: 22.3067676 },
    { lat: 48.8696486, lng: 22.3068562 },
    { lat: 48.869707, lng: 22.3068994 },
    { lat: 48.8697108, lng: 22.3069232 },
    { lat: 48.8696703, lng: 22.3072828 },
    { lat: 48.8696902, lng: 22.3073186 },
    { lat: 48.8697582, lng: 22.3074449 },
    { lat: 48.8695288, lng: 22.3079704 },
    { lat: 48.8694876, lng: 22.3085016 },
    { lat: 48.8695418, lng: 22.3087751 },
    { lat: 48.8695749, lng: 22.3089949 },
    { lat: 48.8695921, lng: 22.309167 },
    { lat: 48.8696772, lng: 22.3091577 },
    { lat: 48.8698204, lng: 22.3096293 },
    { lat: 48.8698217, lng: 22.3098007 },
    { lat: 48.8698436, lng: 22.3108067 },
    { lat: 48.8698299, lng: 22.3110018 },
    { lat: 48.8698046, lng: 22.311137 },
    { lat: 48.8697917, lng: 22.3112191 },
    { lat: 48.8698608, lng: 22.3115997 },
    { lat: 48.8701381, lng: 22.3125072 },
    { lat: 48.8700268, lng: 22.3126103 },
    { lat: 48.8701024, lng: 22.3127841 },
    { lat: 48.8702263, lng: 22.3128883 },
    { lat: 48.8705129, lng: 22.3136064 },
    { lat: 48.8707671, lng: 22.3141761 },
    { lat: 48.8709136, lng: 22.314346 },
    { lat: 48.8710564, lng: 22.3143978 },
    { lat: 48.8714314, lng: 22.3146864 },
    { lat: 48.8714842, lng: 22.3147266 },
    { lat: 48.8715127, lng: 22.3147484 },
    { lat: 48.8718101, lng: 22.315445 },
    { lat: 48.8718456, lng: 22.3155272 },
    { lat: 48.871841, lng: 22.3158346 },
    { lat: 48.8719395, lng: 22.3161156 },
    { lat: 48.8720657, lng: 22.316285 },
    { lat: 48.8720653, lng: 22.3166241 },
    { lat: 48.8720648, lng: 22.3166577 },
    { lat: 48.8721156, lng: 22.317294 },
    { lat: 48.8721187, lng: 22.3173274 },
    { lat: 48.8720409, lng: 22.3175796 },
    { lat: 48.872067, lng: 22.318012 },
    { lat: 48.8720024, lng: 22.3184536 },
    { lat: 48.8718118, lng: 22.319004 },
    { lat: 48.8716432, lng: 22.3194247 },
    { lat: 48.8716124, lng: 22.3197078 },
    { lat: 48.8716169, lng: 22.3198647 },
    { lat: 48.87166, lng: 22.3198837 },
    { lat: 48.8717055, lng: 22.3199036 },
    { lat: 48.8717572, lng: 22.3199539 },
    { lat: 48.8717916, lng: 22.3199868 },
    { lat: 48.8720155, lng: 22.3202043 },
    { lat: 48.872419, lng: 22.3206087 },
    { lat: 48.8727441, lng: 22.3210717 },
    { lat: 48.8732084, lng: 22.3216388 },
    { lat: 48.873379, lng: 22.3220362 },
    { lat: 48.8736131, lng: 22.3224112 },
    { lat: 48.8742042, lng: 22.3229599 },
    { lat: 48.8747329, lng: 22.3231155 },
    { lat: 48.8751063, lng: 22.3232295 },
    { lat: 48.8755437, lng: 22.323325 },
    { lat: 48.8766318, lng: 22.3238709 },
    { lat: 48.8767151, lng: 22.323459 },
    { lat: 48.8768555, lng: 22.322771 },
    { lat: 48.8768087, lng: 22.3219584 },
    { lat: 48.8772268, lng: 22.3208035 },
    { lat: 48.8776626, lng: 22.3188559 },
    { lat: 48.8784798, lng: 22.317377 },
    { lat: 48.8791017, lng: 22.3165378 },
    { lat: 48.8793494, lng: 22.3162989 },
    { lat: 48.8801186, lng: 22.3151575 },
    { lat: 48.8804397, lng: 22.3145523 },
    { lat: 48.8813736, lng: 22.3132251 },
    { lat: 48.8823996, lng: 22.3113714 },
    { lat: 48.8831914, lng: 22.3097206 },
    { lat: 48.8846533, lng: 22.307844 },
    { lat: 48.8849495, lng: 22.3068325 },
    { lat: 48.8850429, lng: 22.3059609 },
    { lat: 48.8857652, lng: 22.3037515 },
    { lat: 48.886455, lng: 22.3022682 },
    { lat: 48.8875233, lng: 22.2995286 },
    { lat: 48.8881112, lng: 22.2983826 },
    { lat: 48.8883535, lng: 22.2981964 },
    { lat: 48.8886877, lng: 22.2976393 },
    { lat: 48.888826, lng: 22.2972242 },
    { lat: 48.8893005, lng: 22.2962365 },
  ],
  Zboj: [
    { lat: 49.087845, lng: 22.491087 },
    { lat: 49.0878808, lng: 22.4910015 },
    { lat: 49.0882324, lng: 22.490301 },
    { lat: 49.0883981, lng: 22.489351 },
    { lat: 49.0884993, lng: 22.4880052 },
    { lat: 49.0884435, lng: 22.4870763 },
    { lat: 49.088891, lng: 22.4859555 },
    { lat: 49.0892991, lng: 22.4852947 },
    { lat: 49.0899623, lng: 22.4851056 },
    { lat: 49.0904384, lng: 22.4851758 },
    { lat: 49.0908439, lng: 22.4850817 },
    { lat: 49.091022, lng: 22.484808 },
    { lat: 49.091342, lng: 22.484216 },
    { lat: 49.0918179, lng: 22.4833578 },
    { lat: 49.0921944, lng: 22.4823611 },
    { lat: 49.0925, lng: 22.4810278 },
    { lat: 49.0926608, lng: 22.4799496 },
    { lat: 49.0926999, lng: 22.4790462 },
    { lat: 49.0930191, lng: 22.4778338 },
    { lat: 49.093353, lng: 22.477001 },
    { lat: 49.0936847, lng: 22.476486 },
    { lat: 49.0944444, lng: 22.47575 },
    { lat: 49.0954128, lng: 22.4746186 },
    { lat: 49.095807, lng: 22.4731684 },
    { lat: 49.096188, lng: 22.472581 },
    { lat: 49.0967461, lng: 22.4717813 },
    { lat: 49.0973784, lng: 22.4706826 },
    { lat: 49.0980349, lng: 22.4698018 },
    { lat: 49.0986408, lng: 22.4694386 },
    { lat: 49.09938, lng: 22.468786 },
    { lat: 49.0998766, lng: 22.4683507 },
    { lat: 49.100254, lng: 22.46775 },
    { lat: 49.1006052, lng: 22.4671418 },
    { lat: 49.1007072, lng: 22.4668259 },
    { lat: 49.100722, lng: 22.465913 },
    { lat: 49.1007222, lng: 22.4651111 },
    { lat: 49.1009212, lng: 22.4643782 },
    { lat: 49.1009381, lng: 22.4634179 },
    { lat: 49.1012222, lng: 22.4620556 },
    { lat: 49.1019232, lng: 22.4608289 },
    { lat: 49.1027474, lng: 22.458901 },
    { lat: 49.1032439, lng: 22.4574211 },
    { lat: 49.1035556, lng: 22.4570278 },
    { lat: 49.1035751, lng: 22.4565191 },
    { lat: 49.10375, lng: 22.4560556 },
    { lat: 49.1037224, lng: 22.4553276 },
    { lat: 49.1034549, lng: 22.4546342 },
    { lat: 49.1032576, lng: 22.4540375 },
    { lat: 49.1031092, lng: 22.4533415 },
    { lat: 49.103034, lng: 22.452537 },
    { lat: 49.10298, lng: 22.450752 },
    { lat: 49.1029682, lng: 22.4500319 },
    { lat: 49.1032967, lng: 22.4482487 },
    { lat: 49.1033612, lng: 22.4470654 },
    { lat: 49.1032778, lng: 22.4446389 },
    { lat: 49.1029444, lng: 22.4418889 },
    { lat: 49.1028889, lng: 22.4413333 },
    { lat: 49.1029313, lng: 22.4409559 },
    { lat: 49.102579, lng: 22.440219 },
    { lat: 49.1020482, lng: 22.4396528 },
    { lat: 49.101783, lng: 22.439171 },
    { lat: 49.1015556, lng: 22.4383889 },
    { lat: 49.10214, lng: 22.436771 },
    { lat: 49.10215, lng: 22.436231 },
    { lat: 49.102116, lng: 22.435655 },
    { lat: 49.101865, lng: 22.43496 },
    { lat: 49.1018611, lng: 22.4342778 },
    { lat: 49.101433, lng: 22.433092 },
    { lat: 49.1011111, lng: 22.4321111 },
    { lat: 49.100826, lng: 22.431362 },
    { lat: 49.100398, lng: 22.430781 },
    { lat: 49.0998333, lng: 22.4300833 },
    { lat: 49.09876, lng: 22.428665 },
    { lat: 49.0984722, lng: 22.4284444 },
    { lat: 49.096957, lng: 22.428644 },
    { lat: 49.0964311, lng: 22.4289462 },
    { lat: 49.0956243, lng: 22.4289169 },
    { lat: 49.0946241, lng: 22.4287286 },
    { lat: 49.0939609, lng: 22.4286856 },
    { lat: 49.0928824, lng: 22.4281978 },
    { lat: 49.0918516, lng: 22.4278882 },
    { lat: 49.0906132, lng: 22.4279269 },
    { lat: 49.0897616, lng: 22.4287301 },
    { lat: 49.0893249, lng: 22.4288187 },
    { lat: 49.0883913, lng: 22.4287521 },
    { lat: 49.0876815, lng: 22.4292316 },
    { lat: 49.087222, lng: 22.4296927 },
    { lat: 49.086832, lng: 22.4304683 },
    { lat: 49.0866581, lng: 22.4311468 },
    { lat: 49.0864401, lng: 22.4316459 },
    { lat: 49.086113, lng: 22.4321989 },
    { lat: 49.0854451, lng: 22.4329727 },
    { lat: 49.0850788, lng: 22.4329353 },
    { lat: 49.0847559, lng: 22.4328133 },
    { lat: 49.0845921, lng: 22.4330564 },
    { lat: 49.0842863, lng: 22.433217 },
    { lat: 49.0837219, lng: 22.4332306 },
    { lat: 49.082523, lng: 22.4339773 },
    { lat: 49.0817931, lng: 22.4342485 },
    { lat: 49.0812571, lng: 22.4347709 },
    { lat: 49.0805715, lng: 22.4356013 },
    { lat: 49.0796255, lng: 22.4371236 },
    { lat: 49.0793601, lng: 22.4372908 },
    { lat: 49.0788176, lng: 22.4374271 },
    { lat: 49.078418, lng: 22.4376833 },
    { lat: 49.0771365, lng: 22.4397379 },
    { lat: 49.0768419, lng: 22.4399065 },
    { lat: 49.0754421, lng: 22.440133 },
    { lat: 49.0751129, lng: 22.4403657 },
    { lat: 49.0743354, lng: 22.4407287 },
    { lat: 49.0731791, lng: 22.4425813 },
    { lat: 49.0729035, lng: 22.4427454 },
    { lat: 49.0724898, lng: 22.4425885 },
    { lat: 49.0720185, lng: 22.4426357 },
    { lat: 49.071577, lng: 22.4428238 },
    { lat: 49.0710942, lng: 22.4432986 },
    { lat: 49.0708326, lng: 22.4433759 },
    { lat: 49.0705603, lng: 22.443693 },
    { lat: 49.0698156, lng: 22.4449474 },
    { lat: 49.0684955, lng: 22.4457728 },
    { lat: 49.0680038, lng: 22.4457678 },
    { lat: 49.067427, lng: 22.4458776 },
    { lat: 49.0663699, lng: 22.4455988 },
    { lat: 49.0648411, lng: 22.4459026 },
    { lat: 49.0636954, lng: 22.4469072 },
    { lat: 49.0625517, lng: 22.4470177 },
    { lat: 49.0619062, lng: 22.4467983 },
    { lat: 49.0614979, lng: 22.4461894 },
    { lat: 49.0605063, lng: 22.4456508 },
    { lat: 49.0600782, lng: 22.44556 },
    { lat: 49.0595248, lng: 22.4451638 },
    { lat: 49.0591119, lng: 22.445046 },
    { lat: 49.0581984, lng: 22.444199 },
    { lat: 49.0579095, lng: 22.4438201 },
    { lat: 49.0570894, lng: 22.4418399 },
    { lat: 49.0566775, lng: 22.4404524 },
    { lat: 49.0562985, lng: 22.4399552 },
    { lat: 49.0551102, lng: 22.439259 },
    { lat: 49.0543789, lng: 22.4385319 },
    { lat: 49.0537109, lng: 22.4376512 },
    { lat: 49.0528466, lng: 22.4378934 },
    { lat: 49.0523303, lng: 22.4378968 },
    { lat: 49.051916, lng: 22.4376519 },
    { lat: 49.051747, lng: 22.4374674 },
    { lat: 49.051727, lng: 22.437321 },
    { lat: 49.0511918, lng: 22.4362856 },
    { lat: 49.050125, lng: 22.4354848 },
    { lat: 49.0498627, lng: 22.4353554 },
    { lat: 49.0494553, lng: 22.4354511 },
    { lat: 49.0489898, lng: 22.43556 },
    { lat: 49.0485383, lng: 22.435536 },
    { lat: 49.047023, lng: 22.4344785 },
    { lat: 49.0460352, lng: 22.4333615 },
    { lat: 49.0457718, lng: 22.4331328 },
    { lat: 49.0456178, lng: 22.4330239 },
    { lat: 49.045195, lng: 22.4329201 },
    { lat: 49.0446577, lng: 22.4333665 },
    { lat: 49.043285, lng: 22.4334892 },
    { lat: 49.0428604, lng: 22.433632 },
    { lat: 49.0421957, lng: 22.4337372 },
    { lat: 49.0418831, lng: 22.4339499 },
    { lat: 49.0411188, lng: 22.4338096 },
    { lat: 49.0399726, lng: 22.4340158 },
    { lat: 49.0383061, lng: 22.4350013 },
    { lat: 49.0377556, lng: 22.4351353 },
    { lat: 49.0368639, lng: 22.4348179 },
    { lat: 49.0364334, lng: 22.4349355 },
    { lat: 49.03602, lng: 22.434895 },
    { lat: 49.0355492, lng: 22.4350798 },
    { lat: 49.0346934, lng: 22.4356348 },
    { lat: 49.0342984, lng: 22.4356658 },
    { lat: 49.0339305, lng: 22.4358263 },
    { lat: 49.0332104, lng: 22.4358616 },
    { lat: 49.0328176, lng: 22.4356222 },
    { lat: 49.0324714, lng: 22.4355305 },
    { lat: 49.0321607, lng: 22.435298 },
    { lat: 49.0320706, lng: 22.4352908 },
    { lat: 49.0318298, lng: 22.4354417 },
    { lat: 49.0317353, lng: 22.4356031 },
    { lat: 49.0304, lng: 22.4356907 },
    { lat: 49.0296576, lng: 22.4356292 },
    { lat: 49.0294374, lng: 22.435477 },
    { lat: 49.02899, lng: 22.4353768 },
    { lat: 49.0287322, lng: 22.4354668 },
    { lat: 49.0282911, lng: 22.4359515 },
    { lat: 49.0279873, lng: 22.4366035 },
    { lat: 49.0282522, lng: 22.4376115 },
    { lat: 49.0278179, lng: 22.4380951 },
    { lat: 49.027336, lng: 22.4383933 },
    { lat: 49.0270094, lng: 22.4387628 },
    { lat: 49.0268434, lng: 22.4388843 },
    { lat: 49.0265646, lng: 22.4391125 },
    { lat: 49.0262668, lng: 22.4395037 },
    { lat: 49.0261474, lng: 22.4400483 },
    { lat: 49.0259462, lng: 22.4405459 },
    { lat: 49.0255583, lng: 22.4409334 },
    { lat: 49.0245707, lng: 22.4417382 },
    { lat: 49.0235681, lng: 22.4433516 },
    { lat: 49.0231846, lng: 22.4445644 },
    { lat: 49.0230951, lng: 22.4451911 },
    { lat: 49.0225579, lng: 22.4462994 },
    { lat: 49.0218007, lng: 22.4473754 },
    { lat: 49.0214639, lng: 22.4481803 },
    { lat: 49.0213028, lng: 22.448437 },
    { lat: 49.0207256, lng: 22.4485359 },
    { lat: 49.0194623, lng: 22.4491145 },
    { lat: 49.018819, lng: 22.4503262 },
    { lat: 49.0184712, lng: 22.4505372 },
    { lat: 49.0179974, lng: 22.4513356 },
    { lat: 49.0173645, lng: 22.4519037 },
    { lat: 49.0166138, lng: 22.4522537 },
    { lat: 49.0159522, lng: 22.4524566 },
    { lat: 49.0153711, lng: 22.4533005 },
    { lat: 49.0139276, lng: 22.455084 },
    { lat: 49.0136824, lng: 22.4551933 },
    { lat: 49.0134108, lng: 22.4549428 },
    { lat: 49.0125, lng: 22.4545634 },
    { lat: 49.0120409, lng: 22.4548842 },
    { lat: 49.0112251, lng: 22.4545831 },
    { lat: 49.0109108, lng: 22.4545499 },
    { lat: 49.0107379, lng: 22.4547422 },
    { lat: 49.0106846, lng: 22.4549901 },
    { lat: 49.0103083, lng: 22.4555008 },
    { lat: 49.0099932, lng: 22.4557223 },
    { lat: 49.0096455, lng: 22.4558645 },
    { lat: 49.0094344, lng: 22.4558329 },
    { lat: 49.0087751, lng: 22.4559847 },
    { lat: 49.0084153, lng: 22.4561514 },
    { lat: 49.0083322, lng: 22.456593 },
    { lat: 49.0081145, lng: 22.4568365 },
    { lat: 49.0078197, lng: 22.4575528 },
    { lat: 49.0085806, lng: 22.4580242 },
    { lat: 49.0089208, lng: 22.4583797 },
    { lat: 49.0103351, lng: 22.461234 },
    { lat: 49.0095144, lng: 22.462789 },
    { lat: 49.0094885, lng: 22.4631731 },
    { lat: 49.0092893, lng: 22.4637249 },
    { lat: 49.0091434, lng: 22.4639108 },
    { lat: 49.0090426, lng: 22.4642623 },
    { lat: 49.0088331, lng: 22.4646906 },
    { lat: 49.0087583, lng: 22.4650423 },
    { lat: 49.0084771, lng: 22.4658167 },
    { lat: 49.0082658, lng: 22.4668787 },
    { lat: 49.0082348, lng: 22.4673368 },
    { lat: 49.0082874, lng: 22.4680443 },
    { lat: 49.0082855, lng: 22.4688368 },
    { lat: 49.008059, lng: 22.469869 },
    { lat: 49.0074494, lng: 22.4697543 },
    { lat: 49.007042, lng: 22.4695241 },
    { lat: 49.0065723, lng: 22.4695318 },
    { lat: 49.0063182, lng: 22.471646 },
    { lat: 49.0057991, lng: 22.4724486 },
    { lat: 49.0051142, lng: 22.4729464 },
    { lat: 49.0046002, lng: 22.4744458 },
    { lat: 49.0044181, lng: 22.4752248 },
    { lat: 49.0044784, lng: 22.4757019 },
    { lat: 49.0044741, lng: 22.4764933 },
    { lat: 49.0044195, lng: 22.4769633 },
    { lat: 49.0042761, lng: 22.4774324 },
    { lat: 49.0040797, lng: 22.4787325 },
    { lat: 49.0008671, lng: 22.4797979 },
    { lat: 48.9996105, lng: 22.4801177 },
    { lat: 48.998311, lng: 22.4797536 },
    { lat: 48.9979279, lng: 22.4797059 },
    { lat: 48.9971297, lng: 22.479389 },
    { lat: 48.9965567, lng: 22.4792456 },
    { lat: 48.9954916, lng: 22.479132 },
    { lat: 48.9933119, lng: 22.4796696 },
    { lat: 48.992838, lng: 22.479692 },
    { lat: 48.993026, lng: 22.484161 },
    { lat: 48.993221, lng: 22.486544 },
    { lat: 48.9937514, lng: 22.4888147 },
    { lat: 48.994107, lng: 22.490337 },
    { lat: 48.99455, lng: 22.491228 },
    { lat: 48.994623, lng: 22.4913109 },
    { lat: 48.996114, lng: 22.493003 },
    { lat: 48.998387, lng: 22.494998 },
    { lat: 48.998886, lng: 22.496606 },
    { lat: 48.999517, lng: 22.500501 },
    { lat: 48.999836, lng: 22.504095 },
    { lat: 48.999917, lng: 22.50546 },
    { lat: 48.9990976, lng: 22.507646 },
    { lat: 48.99803, lng: 22.510498 },
    { lat: 48.997822, lng: 22.51131 },
    { lat: 48.997449, lng: 22.512561 },
    { lat: 48.996627, lng: 22.513875 },
    { lat: 48.996746, lng: 22.520821 },
    { lat: 48.997136, lng: 22.522195 },
    { lat: 48.997829, lng: 22.522661 },
    { lat: 48.99924, lng: 22.524377 },
    { lat: 48.999915, lng: 22.525573 },
    { lat: 49.000399, lng: 22.525933 },
    { lat: 49.0009957, lng: 22.526931 },
    { lat: 49.002317, lng: 22.529975 },
    { lat: 49.002884, lng: 22.53112 },
    { lat: 49.002981, lng: 22.532338 },
    { lat: 49.0037503, lng: 22.5355581 },
    { lat: 49.005098, lng: 22.5412 },
    { lat: 49.0061761, lng: 22.5445273 },
    { lat: 49.006236, lng: 22.544712 },
    { lat: 49.006765, lng: 22.545495 },
    { lat: 49.008112, lng: 22.544989 },
    { lat: 49.0090763, lng: 22.5444099 },
    { lat: 49.010215, lng: 22.543744 },
    { lat: 49.013207, lng: 22.541963 },
    { lat: 49.018092, lng: 22.539036 },
    { lat: 49.019821, lng: 22.53889 },
    { lat: 49.021321, lng: 22.53799 },
    { lat: 49.0215597, lng: 22.5377607 },
    { lat: 49.0219862, lng: 22.5368631 },
    { lat: 49.0218669, lng: 22.5364084 },
    { lat: 49.0220023, lng: 22.5354567 },
    { lat: 49.0209871, lng: 22.5340429 },
    { lat: 49.0208054, lng: 22.5337216 },
    { lat: 49.0205069, lng: 22.5324482 },
    { lat: 49.0198586, lng: 22.5314438 },
    { lat: 49.0203058, lng: 22.5304588 },
    { lat: 49.0203745, lng: 22.5298355 },
    { lat: 49.0199267, lng: 22.5284589 },
    { lat: 49.0198412, lng: 22.527829 },
    { lat: 49.0198753, lng: 22.5276863 },
    { lat: 49.0199994, lng: 22.5274775 },
    { lat: 49.0202929, lng: 22.5274229 },
    { lat: 49.0205086, lng: 22.5271769 },
    { lat: 49.020753, lng: 22.5267493 },
    { lat: 49.0211149, lng: 22.5260606 },
    { lat: 49.0211737, lng: 22.5257315 },
    { lat: 49.0213673, lng: 22.5252275 },
    { lat: 49.0217473, lng: 22.524257 },
    { lat: 49.0219133, lng: 22.5240143 },
    { lat: 49.0220937, lng: 22.5238054 },
    { lat: 49.0226716, lng: 22.5234066 },
    { lat: 49.0234268, lng: 22.5229754 },
    { lat: 49.0236746, lng: 22.5220926 },
    { lat: 49.0237749, lng: 22.5214803 },
    { lat: 49.0239039, lng: 22.521235 },
    { lat: 49.0243815, lng: 22.5209981 },
    { lat: 49.0251587, lng: 22.519643 },
    { lat: 49.026669, lng: 22.5184607 },
    { lat: 49.0270406, lng: 22.5179738 },
    { lat: 49.027544, lng: 22.5171588 },
    { lat: 49.027684, lng: 22.5158297 },
    { lat: 49.0273905, lng: 22.5144733 },
    { lat: 49.0273474, lng: 22.5136098 },
    { lat: 49.0276, lng: 22.5118914 },
    { lat: 49.0282275, lng: 22.5105288 },
    { lat: 49.0283617, lng: 22.5102656 },
    { lat: 49.0285232, lng: 22.5100812 },
    { lat: 49.0285701, lng: 22.5097841 },
    { lat: 49.0288989, lng: 22.5091257 },
    { lat: 49.0292271, lng: 22.5086623 },
    { lat: 49.029511, lng: 22.5081292 },
    { lat: 49.0296436, lng: 22.5074511 },
    { lat: 49.0300547, lng: 22.5063453 },
    { lat: 49.0301429, lng: 22.5050278 },
    { lat: 49.0311917, lng: 22.5034312 },
    { lat: 49.0321321, lng: 22.5025459 },
    { lat: 49.0324982, lng: 22.5026347 },
    { lat: 49.0335257, lng: 22.5021077 },
    { lat: 49.0337725, lng: 22.5020772 },
    { lat: 49.035359, lng: 22.5005506 },
    { lat: 49.0360085, lng: 22.5000141 },
    { lat: 49.0361517, lng: 22.5003135 },
    { lat: 49.0363706, lng: 22.5005267 },
    { lat: 49.0370928, lng: 22.5006527 },
    { lat: 49.0372681, lng: 22.5008034 },
    { lat: 49.0374016, lng: 22.5005635 },
    { lat: 49.0376275, lng: 22.5006757 },
    { lat: 49.0378488, lng: 22.5010564 },
    { lat: 49.0378723, lng: 22.5012184 },
    { lat: 49.037799, lng: 22.5013838 },
    { lat: 49.0378438, lng: 22.5016556 },
    { lat: 49.0382058, lng: 22.5014797 },
    { lat: 49.038495, lng: 22.5016021 },
    { lat: 49.0385836, lng: 22.501588 },
    { lat: 49.0389533, lng: 22.500844 },
    { lat: 49.0391955, lng: 22.5007515 },
    { lat: 49.03934, lng: 22.5007749 },
    { lat: 49.0395081, lng: 22.5005775 },
    { lat: 49.0395868, lng: 22.5003559 },
    { lat: 49.0397762, lng: 22.5003282 },
    { lat: 49.039988, lng: 22.5001166 },
    { lat: 49.0403522, lng: 22.4998864 },
    { lat: 49.0406439, lng: 22.4997901 },
    { lat: 49.0407266, lng: 22.4998834 },
    { lat: 49.0409967, lng: 22.4998372 },
    { lat: 49.0413234, lng: 22.4993579 },
    { lat: 49.0415143, lng: 22.4994545 },
    { lat: 49.041695, lng: 22.4993855 },
    { lat: 49.0419998, lng: 22.499446 },
    { lat: 49.0422481, lng: 22.4995997 },
    { lat: 49.0423887, lng: 22.4996043 },
    { lat: 49.0427992, lng: 22.4994398 },
    { lat: 49.042945, lng: 22.4994714 },
    { lat: 49.0431955, lng: 22.4992967 },
    { lat: 49.0437631, lng: 22.4990554 },
    { lat: 49.0439314, lng: 22.4991422 },
    { lat: 49.0441014, lng: 22.4989831 },
    { lat: 49.0442414, lng: 22.4990196 },
    { lat: 49.0443512, lng: 22.4989371 },
    { lat: 49.0445489, lng: 22.4989233 },
    { lat: 49.0446906, lng: 22.4988092 },
    { lat: 49.0448754, lng: 22.4989433 },
    { lat: 49.0450208, lng: 22.4988505 },
    { lat: 49.0451992, lng: 22.4985537 },
    { lat: 49.0453201, lng: 22.4982946 },
    { lat: 49.0453047, lng: 22.498153 },
    { lat: 49.0455495, lng: 22.4975133 },
    { lat: 49.0464518, lng: 22.4961831 },
    { lat: 49.0474953, lng: 22.4969069 },
    { lat: 49.0476783, lng: 22.4972895 },
    { lat: 49.0479418, lng: 22.4974225 },
    { lat: 49.0486054, lng: 22.4976542 },
    { lat: 49.0489642, lng: 22.4975403 },
    { lat: 49.0495041, lng: 22.4969558 },
    { lat: 49.0499999, lng: 22.4961396 },
    { lat: 49.0511049, lng: 22.4954098 },
    { lat: 49.0514909, lng: 22.495245 },
    { lat: 49.0518704, lng: 22.495265 },
    { lat: 49.0526076, lng: 22.49542 },
    { lat: 49.0529167, lng: 22.4952696 },
    { lat: 49.053137, lng: 22.4950508 },
    { lat: 49.0537778, lng: 22.4949284 },
    { lat: 49.0541208, lng: 22.4947092 },
    { lat: 49.0543353, lng: 22.493914 },
    { lat: 49.0546497, lng: 22.4933763 },
    { lat: 49.0549966, lng: 22.4930132 },
    { lat: 49.0553223, lng: 22.4927679 },
    { lat: 49.055771, lng: 22.4921379 },
    { lat: 49.055937, lng: 22.4918219 },
    { lat: 49.056098, lng: 22.4916294 },
    { lat: 49.0566073, lng: 22.4912584 },
    { lat: 49.0569894, lng: 22.4906332 },
    { lat: 49.057337, lng: 22.4897149 },
    { lat: 49.0576803, lng: 22.489108 },
    { lat: 49.0580033, lng: 22.4882441 },
    { lat: 49.0581695, lng: 22.4872435 },
    { lat: 49.0585109, lng: 22.4863549 },
    { lat: 49.0590499, lng: 22.485703 },
    { lat: 49.0595533, lng: 22.4847946 },
    { lat: 49.0601475, lng: 22.4840895 },
    { lat: 49.0603093, lng: 22.484025 },
    { lat: 49.0605894, lng: 22.4840334 },
    { lat: 49.0609053, lng: 22.4838631 },
    { lat: 49.061408, lng: 22.4839121 },
    { lat: 49.06177, lng: 22.483693 },
    { lat: 49.063127, lng: 22.4832219 },
    { lat: 49.0633272, lng: 22.4833449 },
    { lat: 49.0635936, lng: 22.4832206 },
    { lat: 49.0643105, lng: 22.4833091 },
    { lat: 49.0646833, lng: 22.4830052 },
    { lat: 49.064894, lng: 22.4830183 },
    { lat: 49.0654185, lng: 22.4835433 },
    { lat: 49.0655977, lng: 22.4838491 },
    { lat: 49.065726, lng: 22.4837852 },
    { lat: 49.0660662, lng: 22.482481 },
    { lat: 49.066171, lng: 22.4823276 },
    { lat: 49.0663977, lng: 22.4819021 },
    { lat: 49.0665627, lng: 22.4818685 },
    { lat: 49.0669728, lng: 22.4819975 },
    { lat: 49.0671047, lng: 22.4819594 },
    { lat: 49.0673695, lng: 22.4820959 },
    { lat: 49.0673593, lng: 22.4821373 },
    { lat: 49.0676489, lng: 22.482056 },
    { lat: 49.0678886, lng: 22.4816245 },
    { lat: 49.0685758, lng: 22.481568 },
    { lat: 49.0686145, lng: 22.4815963 },
    { lat: 49.0685567, lng: 22.4817432 },
    { lat: 49.0686198, lng: 22.4821997 },
    { lat: 49.0688027, lng: 22.4825028 },
    { lat: 49.0687661, lng: 22.4828172 },
    { lat: 49.0691482, lng: 22.4831376 },
    { lat: 49.0701885, lng: 22.4831483 },
    { lat: 49.0713008, lng: 22.4827054 },
    { lat: 49.0721685, lng: 22.4825653 },
    { lat: 49.0729967, lng: 22.4823161 },
    { lat: 49.0737282, lng: 22.4821779 },
    { lat: 49.0743094, lng: 22.4830597 },
    { lat: 49.0746473, lng: 22.483366 },
    { lat: 49.0748538, lng: 22.4839679 },
    { lat: 49.0749611, lng: 22.4841566 },
    { lat: 49.075259, lng: 22.4844293 },
    { lat: 49.0760635, lng: 22.484955 },
    { lat: 49.0764398, lng: 22.4852721 },
    { lat: 49.0767617, lng: 22.4852851 },
    { lat: 49.076945, lng: 22.4857281 },
    { lat: 49.077431, lng: 22.48602 },
    { lat: 49.0776423, lng: 22.4862567 },
    { lat: 49.0780418, lng: 22.4864483 },
    { lat: 49.0782263, lng: 22.4865079 },
    { lat: 49.0784749, lng: 22.4866387 },
    { lat: 49.0787226, lng: 22.4869759 },
    { lat: 49.0790232, lng: 22.4871903 },
    { lat: 49.0792712, lng: 22.4874735 },
    { lat: 49.0795226, lng: 22.4876448 },
    { lat: 49.0798993, lng: 22.487803 },
    { lat: 49.0800606, lng: 22.4886669 },
    { lat: 49.080487, lng: 22.4890104 },
    { lat: 49.0817061, lng: 22.4891922 },
    { lat: 49.0820653, lng: 22.489164 },
    { lat: 49.0828734, lng: 22.4894701 },
    { lat: 49.0835637, lng: 22.4898046 },
    { lat: 49.0839065, lng: 22.4903548 },
    { lat: 49.0845361, lng: 22.4907987 },
    { lat: 49.0849113, lng: 22.4908218 },
    { lat: 49.0855147, lng: 22.4911378 },
    { lat: 49.0861631, lng: 22.4912859 },
    { lat: 49.0869405, lng: 22.4911942 },
    { lat: 49.0874288, lng: 22.4913709 },
    { lat: 49.0877382, lng: 22.4910422 },
    { lat: 49.087845, lng: 22.491087 },
  ],
  Dúbrava: [
    { lat: 48.8991222, lng: 22.351005 },
    { lat: 48.8982209, lng: 22.3510479 },
    { lat: 48.8991026, lng: 22.3495719 },
    { lat: 48.8990375, lng: 22.3478922 },
    { lat: 48.8991816, lng: 22.3473113 },
    { lat: 48.8991089, lng: 22.3470197 },
    { lat: 48.8989979, lng: 22.3468199 },
    { lat: 48.8988082, lng: 22.3459507 },
    { lat: 48.8988121, lng: 22.3457049 },
    { lat: 48.8996246, lng: 22.3442029 },
    { lat: 48.8999657, lng: 22.3430761 },
    { lat: 48.9008755, lng: 22.341438 },
    { lat: 48.9008467, lng: 22.3407785 },
    { lat: 48.9006245, lng: 22.3406468 },
    { lat: 48.9005979, lng: 22.340241 },
    { lat: 48.9004644, lng: 22.3397885 },
    { lat: 48.9006841, lng: 22.3393816 },
    { lat: 48.9004064, lng: 22.3392817 },
    { lat: 48.900322, lng: 22.3389727 },
    { lat: 48.900466, lng: 22.3388648 },
    { lat: 48.9004435, lng: 22.3386603 },
    { lat: 48.9001428, lng: 22.338431 },
    { lat: 48.8999109, lng: 22.3382541 },
    { lat: 48.8997311, lng: 22.3385836 },
    { lat: 48.8993163, lng: 22.3382763 },
    { lat: 48.8987214, lng: 22.3379647 },
    { lat: 48.8987018, lng: 22.3379989 },
    { lat: 48.8982741, lng: 22.3377158 },
    { lat: 48.8979622, lng: 22.337852 },
    { lat: 48.8975899, lng: 22.3375344 },
    { lat: 48.8975364, lng: 22.3373476 },
    { lat: 48.8971231, lng: 22.3374295 },
    { lat: 48.896892, lng: 22.3376218 },
    { lat: 48.8956567, lng: 22.3381761 },
    { lat: 48.8950391, lng: 22.3380568 },
    { lat: 48.8944726, lng: 22.3382782 },
    { lat: 48.8937516, lng: 22.3382305 },
    { lat: 48.8936209, lng: 22.3370067 },
    { lat: 48.8936355, lng: 22.3360181 },
    { lat: 48.8932789, lng: 22.3359074 },
    { lat: 48.8933004, lng: 22.3352869 },
    { lat: 48.8932214, lng: 22.3352709 },
    { lat: 48.8925982, lng: 22.3351445 },
    { lat: 48.8924883, lng: 22.3351222 },
    { lat: 48.8924916, lng: 22.3349137 },
    { lat: 48.8912238, lng: 22.3345388 },
    { lat: 48.8912238, lng: 22.3345389 },
    { lat: 48.8912076, lng: 22.3346842 },
    { lat: 48.8911278, lng: 22.3353996 },
    { lat: 48.8911136, lng: 22.3355269 },
    { lat: 48.8906696, lng: 22.3353301 },
    { lat: 48.8904691, lng: 22.3354233 },
    { lat: 48.8902398, lng: 22.3351619 },
    { lat: 48.889946, lng: 22.3350982 },
    { lat: 48.8897238, lng: 22.3349683 },
    { lat: 48.8894153, lng: 22.3350015 },
    { lat: 48.8893643, lng: 22.3349329 },
    { lat: 48.8890522, lng: 22.3351123 },
    { lat: 48.8886173, lng: 22.3348679 },
    { lat: 48.8885741, lng: 22.3348172 },
    { lat: 48.8885087, lng: 22.3347404 },
    { lat: 48.8882304, lng: 22.3347077 },
    { lat: 48.8877767, lng: 22.3343234 },
    { lat: 48.8871189, lng: 22.3342794 },
    { lat: 48.887062, lng: 22.3343016 },
    { lat: 48.8861811, lng: 22.334646 },
    { lat: 48.8859655, lng: 22.3343551 },
    { lat: 48.8857342, lng: 22.3343726 },
    { lat: 48.8853164, lng: 22.3341316 },
    { lat: 48.8846881, lng: 22.3335852 },
    { lat: 48.8839272, lng: 22.333446 },
    { lat: 48.8834642, lng: 22.3328932 },
    { lat: 48.8839014, lng: 22.3315172 },
    { lat: 48.8833916, lng: 22.3311101 },
    { lat: 48.883161, lng: 22.330926 },
    { lat: 48.8830529, lng: 22.3303964 },
    { lat: 48.8830772, lng: 22.3298609 },
    { lat: 48.8831434, lng: 22.3295314 },
    { lat: 48.8832523, lng: 22.3293673 },
    { lat: 48.8830202, lng: 22.3290433 },
    { lat: 48.8821692, lng: 22.3274392 },
    { lat: 48.880767, lng: 22.3256291 },
    { lat: 48.8802099, lng: 22.3238944 },
    { lat: 48.8800153, lng: 22.3237329 },
    { lat: 48.8798379, lng: 22.3238014 },
    { lat: 48.8773725, lng: 22.3231702 },
    { lat: 48.8767056, lng: 22.3237943 },
    { lat: 48.8766318, lng: 22.3238709 },
    { lat: 48.8755437, lng: 22.323325 },
    { lat: 48.8751063, lng: 22.3232295 },
    { lat: 48.8748812, lng: 22.323738 },
    { lat: 48.8746923, lng: 22.3246274 },
    { lat: 48.8748068, lng: 22.3256504 },
    { lat: 48.8750242, lng: 22.3263996 },
    { lat: 48.8751187, lng: 22.3267704 },
    { lat: 48.8747543, lng: 22.3272394 },
    { lat: 48.8747033, lng: 22.3276392 },
    { lat: 48.8744188, lng: 22.3283665 },
    { lat: 48.8740616, lng: 22.3288543 },
    { lat: 48.8739769, lng: 22.3287381 },
    { lat: 48.8733259, lng: 22.3295214 },
    { lat: 48.8729811, lng: 22.3298643 },
    { lat: 48.8724334, lng: 22.3305388 },
    { lat: 48.8721616, lng: 22.3310314 },
    { lat: 48.8723239, lng: 22.3312034 },
    { lat: 48.87234, lng: 22.331214 },
    { lat: 48.8716099, lng: 22.3323566 },
    { lat: 48.8716213, lng: 22.3323714 },
    { lat: 48.8716421, lng: 22.3323965 },
    { lat: 48.8711298, lng: 22.3333625 },
    { lat: 48.8711139, lng: 22.3333533 },
    { lat: 48.8709023, lng: 22.3340251 },
    { lat: 48.870564, lng: 22.3352897 },
    { lat: 48.8703969, lng: 22.3358263 },
    { lat: 48.870186, lng: 22.3363784 },
    { lat: 48.8702009, lng: 22.3363898 },
    { lat: 48.8706565, lng: 22.3367674 },
    { lat: 48.870523, lng: 22.3370984 },
    { lat: 48.8708113, lng: 22.3378905 },
    { lat: 48.8711061, lng: 22.3383186 },
    { lat: 48.8708496, lng: 22.3384806 },
    { lat: 48.870631, lng: 22.3386178 },
    { lat: 48.8704408, lng: 22.3385611 },
    { lat: 48.8701336, lng: 22.3387556 },
    { lat: 48.8699395, lng: 22.3391996 },
    { lat: 48.8697458, lng: 22.3394514 },
    { lat: 48.8689208, lng: 22.3405217 },
    { lat: 48.8687379, lng: 22.3408072 },
    { lat: 48.8690061, lng: 22.3416657 },
    { lat: 48.869013, lng: 22.3418865 },
    { lat: 48.8692049, lng: 22.3418649 },
    { lat: 48.869282, lng: 22.3419986 },
    { lat: 48.8694914, lng: 22.3420072 },
    { lat: 48.8696056, lng: 22.3427718 },
    { lat: 48.8692694, lng: 22.3427741 },
    { lat: 48.8693414, lng: 22.3432457 },
    { lat: 48.8694534, lng: 22.343431 },
    { lat: 48.8694501, lng: 22.3440706 },
    { lat: 48.8694871, lng: 22.344491 },
    { lat: 48.8695646, lng: 22.345002 },
    { lat: 48.8694543, lng: 22.3453654 },
    { lat: 48.8694857, lng: 22.3461505 },
    { lat: 48.8695528, lng: 22.3467096 },
    { lat: 48.8693301, lng: 22.3475032 },
    { lat: 48.8693681, lng: 22.3480658 },
    { lat: 48.8693781, lng: 22.3489563 },
    { lat: 48.8693544, lng: 22.3493222 },
    { lat: 48.8694552, lng: 22.3497178 },
    { lat: 48.8694583, lng: 22.3501263 },
    { lat: 48.8696071, lng: 22.3504609 },
    { lat: 48.8696991, lng: 22.3509715 },
    { lat: 48.8696192, lng: 22.3512369 },
    { lat: 48.8697068, lng: 22.3514999 },
    { lat: 48.86987, lng: 22.3517684 },
    { lat: 48.8698386, lng: 22.3520843 },
    { lat: 48.8699211, lng: 22.352784 },
    { lat: 48.8700752, lng: 22.3529462 },
    { lat: 48.8700551, lng: 22.3534859 },
    { lat: 48.8701362, lng: 22.3539063 },
    { lat: 48.8699919, lng: 22.3546858 },
    { lat: 48.8696903, lng: 22.3556734 },
    { lat: 48.8700144, lng: 22.3556303 },
    { lat: 48.8702241, lng: 22.3554975 },
    { lat: 48.8702305, lng: 22.3552639 },
    { lat: 48.8708201, lng: 22.3549085 },
    { lat: 48.8709541, lng: 22.3559787 },
    { lat: 48.8709152, lng: 22.3565301 },
    { lat: 48.8708521, lng: 22.3569944 },
    { lat: 48.8706438, lng: 22.3575058 },
    { lat: 48.8705162, lng: 22.3575442 },
    { lat: 48.8704779, lng: 22.35754 },
    { lat: 48.8704799, lng: 22.3575778 },
    { lat: 48.8702683, lng: 22.3575681 },
    { lat: 48.8701537, lng: 22.3576323 },
    { lat: 48.8700182, lng: 22.3577094 },
    { lat: 48.8697701, lng: 22.357948 },
    { lat: 48.8695725, lng: 22.3580631 },
    { lat: 48.8693535, lng: 22.3583482 },
    { lat: 48.8692606, lng: 22.3584145 },
    { lat: 48.8691302, lng: 22.3584223 },
    { lat: 48.8689664, lng: 22.3583317 },
    { lat: 48.8689549, lng: 22.3583759 },
    { lat: 48.8689286, lng: 22.3584579 },
    { lat: 48.8687744, lng: 22.3589317 },
    { lat: 48.8686535, lng: 22.3589554 },
    { lat: 48.8685733, lng: 22.359094 },
    { lat: 48.8685772, lng: 22.3592807 },
    { lat: 48.8684225, lng: 22.3599395 },
    { lat: 48.8685635, lng: 22.3605967 },
    { lat: 48.8685219, lng: 22.3609124 },
    { lat: 48.8685726, lng: 22.3613343 },
    { lat: 48.8686092, lng: 22.3618877 },
    { lat: 48.8686106, lng: 22.3623328 },
    { lat: 48.8685369, lng: 22.3626661 },
    { lat: 48.8684322, lng: 22.3628763 },
    { lat: 48.8684563, lng: 22.3631691 },
    { lat: 48.8683051, lng: 22.363727 },
    { lat: 48.8683831, lng: 22.3640008 },
    { lat: 48.8684083, lng: 22.3646111 },
    { lat: 48.8684733, lng: 22.3649227 },
    { lat: 48.8684276, lng: 22.3651564 },
    { lat: 48.8684658, lng: 22.365675 },
    { lat: 48.8682839, lng: 22.3659568 },
    { lat: 48.8682804, lng: 22.3666911 },
    { lat: 48.8684056, lng: 22.3668729 },
    { lat: 48.8686263, lng: 22.3673084 },
    { lat: 48.8687223, lng: 22.367802 },
    { lat: 48.8687679, lng: 22.3682517 },
    { lat: 48.8689429, lng: 22.3685447 },
    { lat: 48.8689211, lng: 22.368853 },
    { lat: 48.8690033, lng: 22.3690158 },
    { lat: 48.8692782, lng: 22.3700452 },
    { lat: 48.8694709, lng: 22.3708075 },
    { lat: 48.8694979, lng: 22.3709123 },
    { lat: 48.8693781, lng: 22.3709863 },
    { lat: 48.8694815, lng: 22.3716769 },
    { lat: 48.8695936, lng: 22.3716582 },
    { lat: 48.8696616, lng: 22.371846 },
    { lat: 48.8697919, lng: 22.3722076 },
    { lat: 48.8698522, lng: 22.3726637 },
    { lat: 48.8697169, lng: 22.3735992 },
    { lat: 48.8695246, lng: 22.3749346 },
    { lat: 48.8699819, lng: 22.375818 },
    { lat: 48.8695867, lng: 22.376432 },
    { lat: 48.8695253, lng: 22.3764057 },
    { lat: 48.8691075, lng: 22.3768547 },
    { lat: 48.8688289, lng: 22.3768175 },
    { lat: 48.8687991, lng: 22.3768936 },
    { lat: 48.8686836, lng: 22.3771811 },
    { lat: 48.8684322, lng: 22.3773648 },
    { lat: 48.8683666, lng: 22.3775609 },
    { lat: 48.8677663, lng: 22.3782142 },
    { lat: 48.8676183, lng: 22.3784493 },
    { lat: 48.8671774, lng: 22.3788215 },
    { lat: 48.8667692, lng: 22.3794532 },
    { lat: 48.8665113, lng: 22.3795504 },
    { lat: 48.8664858, lng: 22.3797306 },
    { lat: 48.8661189, lng: 22.3800327 },
    { lat: 48.8656715, lng: 22.3804628 },
    { lat: 48.8655779, lng: 22.3804059 },
    { lat: 48.8651692, lng: 22.3809183 },
    { lat: 48.8651508, lng: 22.3811607 },
    { lat: 48.8645175, lng: 22.381685 },
    { lat: 48.8643785, lng: 22.3816521 },
    { lat: 48.864098, lng: 22.3817422 },
    { lat: 48.8638185, lng: 22.3817179 },
    { lat: 48.8629022, lng: 22.3814065 },
    { lat: 48.8628944, lng: 22.3817287 },
    { lat: 48.8628159, lng: 22.381904 },
    { lat: 48.8625098, lng: 22.3825911 },
    { lat: 48.862108, lng: 22.382852 },
    { lat: 48.86504, lng: 22.383536 },
    { lat: 48.869196, lng: 22.386377 },
    { lat: 48.869662, lng: 22.386731 },
    { lat: 48.8707624, lng: 22.3906548 },
    { lat: 48.8715153, lng: 22.3917282 },
    { lat: 48.871823, lng: 22.3916102 },
    { lat: 48.8718597, lng: 22.3913233 },
    { lat: 48.8717141, lng: 22.3904397 },
    { lat: 48.8713401, lng: 22.3894535 },
    { lat: 48.8721237, lng: 22.3891163 },
    { lat: 48.8727807, lng: 22.3886454 },
    { lat: 48.8732923, lng: 22.3878379 },
    { lat: 48.874199, lng: 22.3869873 },
    { lat: 48.8744577, lng: 22.3864042 },
    { lat: 48.8745729, lng: 22.3862686 },
    { lat: 48.874788, lng: 22.3856397 },
    { lat: 48.8753254, lng: 22.384724 },
    { lat: 48.8754019, lng: 22.3841355 },
    { lat: 48.8753772, lng: 22.3839852 },
    { lat: 48.8755176, lng: 22.3836983 },
    { lat: 48.8756004, lng: 22.3832008 },
    { lat: 48.87583, lng: 22.3823097 },
    { lat: 48.876253, lng: 22.3817279 },
    { lat: 48.8766072, lng: 22.3816131 },
    { lat: 48.8767796, lng: 22.3816334 },
    { lat: 48.8770379, lng: 22.3812425 },
    { lat: 48.8770134, lng: 22.3810396 },
    { lat: 48.8771392, lng: 22.3803347 },
    { lat: 48.8773064, lng: 22.3798946 },
    { lat: 48.8775632, lng: 22.3784704 },
    { lat: 48.8774463, lng: 22.3778354 },
    { lat: 48.8775377, lng: 22.3776866 },
    { lat: 48.8780674, lng: 22.3772153 },
    { lat: 48.8785653, lng: 22.3773026 },
    { lat: 48.8789218, lng: 22.3769845 },
    { lat: 48.8791232, lng: 22.376881 },
    { lat: 48.8798721, lng: 22.3754961 },
    { lat: 48.8801439, lng: 22.3747847 },
    { lat: 48.8805122, lng: 22.3743266 },
    { lat: 48.8806624, lng: 22.3738851 },
    { lat: 48.8810694, lng: 22.3734501 },
    { lat: 48.8813678, lng: 22.3729946 },
    { lat: 48.8816167, lng: 22.3724747 },
    { lat: 48.8819657, lng: 22.3725507 },
    { lat: 48.8821441, lng: 22.3723704 },
    { lat: 48.8822216, lng: 22.3715666 },
    { lat: 48.8825777, lng: 22.3713341 },
    { lat: 48.8829889, lng: 22.3704668 },
    { lat: 48.8832813, lng: 22.3701367 },
    { lat: 48.8839586, lng: 22.3697821 },
    { lat: 48.8851207, lng: 22.3687913 },
    { lat: 48.8856258, lng: 22.3681599 },
    { lat: 48.8860913, lng: 22.3666216 },
    { lat: 48.8862843, lng: 22.365698 },
    { lat: 48.886896, lng: 22.3643159 },
    { lat: 48.8870156, lng: 22.363854 },
    { lat: 48.8869251, lng: 22.3634124 },
    { lat: 48.8864265, lng: 22.3630716 },
    { lat: 48.8863183, lng: 22.3616874 },
    { lat: 48.8864967, lng: 22.3610326 },
    { lat: 48.887023, lng: 22.3595502 },
    { lat: 48.8871859, lng: 22.3587553 },
    { lat: 48.8873851, lng: 22.3583821 },
    { lat: 48.8873886, lng: 22.3575489 },
    { lat: 48.8879671, lng: 22.3570927 },
    { lat: 48.8882313, lng: 22.3573046 },
    { lat: 48.8889595, lng: 22.357325 },
    { lat: 48.8892644, lng: 22.3576986 },
    { lat: 48.88999, lng: 22.3577059 },
    { lat: 48.8904552, lng: 22.3580058 },
    { lat: 48.8908014, lng: 22.3583702 },
    { lat: 48.8913587, lng: 22.3583443 },
    { lat: 48.8920326, lng: 22.3585619 },
    { lat: 48.8924521, lng: 22.3584602 },
    { lat: 48.8934493, lng: 22.3585433 },
    { lat: 48.8941774, lng: 22.3584018 },
    { lat: 48.8945821, lng: 22.3584048 },
    { lat: 48.8948344, lng: 22.3579453 },
    { lat: 48.8949542, lng: 22.357596 },
    { lat: 48.8953441, lng: 22.3568903 },
    { lat: 48.895639, lng: 22.3564883 },
    { lat: 48.8961834, lng: 22.3561235 },
    { lat: 48.8966629, lng: 22.3559309 },
    { lat: 48.8974651, lng: 22.354469 },
    { lat: 48.8979524, lng: 22.3540558 },
    { lat: 48.8981555, lng: 22.3539901 },
    { lat: 48.898279, lng: 22.3538117 },
    { lat: 48.8983374, lng: 22.3532373 },
    { lat: 48.8988624, lng: 22.3520605 },
    { lat: 48.8990558, lng: 22.3517457 },
    { lat: 48.8991226, lng: 22.3510265 },
    { lat: 48.8991222, lng: 22.351005 },
  ],
  Parihuzovce: [
    { lat: 49.0991763, lng: 22.1935842 },
    { lat: 49.0995253, lng: 22.1956375 },
    { lat: 49.0980641, lng: 22.1988214 },
    { lat: 49.0977016, lng: 22.1994039 },
    { lat: 49.0970691, lng: 22.2015785 },
    { lat: 49.0977384, lng: 22.2043288 },
    { lat: 49.0976093, lng: 22.2058365 },
    { lat: 49.0963975, lng: 22.2076608 },
    { lat: 49.0964135, lng: 22.2079212 },
    { lat: 49.0965299, lng: 22.2079926 },
    { lat: 49.096771, lng: 22.2079097 },
    { lat: 49.096836, lng: 22.2081048 },
    { lat: 49.0970706, lng: 22.2083316 },
    { lat: 49.097187, lng: 22.208258 },
    { lat: 49.0974485, lng: 22.2084285 },
    { lat: 49.0975117, lng: 22.2086398 },
    { lat: 49.0979374, lng: 22.2090666 },
    { lat: 49.0979863, lng: 22.208998 },
    { lat: 49.0981045, lng: 22.2092955 },
    { lat: 49.0983038, lng: 22.2093127 },
    { lat: 49.0984514, lng: 22.209566 },
    { lat: 49.098676, lng: 22.2097563 },
    { lat: 49.0988647, lng: 22.2101434 },
    { lat: 49.0990059, lng: 22.2099763 },
    { lat: 49.0991449, lng: 22.2102052 },
    { lat: 49.0993578, lng: 22.2103104 },
    { lat: 49.0993704, lng: 22.2104325 },
    { lat: 49.0996685, lng: 22.2107353 },
    { lat: 49.0997502, lng: 22.2108599 },
    { lat: 49.099756, lng: 22.2110204 },
    { lat: 49.0998595, lng: 22.2111162 },
    { lat: 49.099932, lng: 22.2113468 },
    { lat: 49.1000452, lng: 22.211431 },
    { lat: 49.1002264, lng: 22.2117345 },
    { lat: 49.1003749, lng: 22.2118822 },
    { lat: 49.1007294, lng: 22.2120005 },
    { lat: 49.1010337, lng: 22.2124247 },
    { lat: 49.101405, lng: 22.2125324 },
    { lat: 49.1013841, lng: 22.212704 },
    { lat: 49.1015676, lng: 22.2127633 },
    { lat: 49.1016467, lng: 22.2128866 },
    { lat: 49.1017419, lng: 22.2128124 },
    { lat: 49.1019088, lng: 22.2129283 },
    { lat: 49.1021018, lng: 22.2129204 },
    { lat: 49.1022646, lng: 22.213054 },
    { lat: 49.1025173, lng: 22.2130872 },
    { lat: 49.102726, lng: 22.2132586 },
    { lat: 49.1028868, lng: 22.2131577 },
    { lat: 49.103065, lng: 22.2132851 },
    { lat: 49.1032622, lng: 22.2133054 },
    { lat: 49.1040301, lng: 22.2137916 },
    { lat: 49.1042248, lng: 22.2137499 },
    { lat: 49.1044225, lng: 22.2138433 },
    { lat: 49.104506, lng: 22.2138049 },
    { lat: 49.104694, lng: 22.2139602 },
    { lat: 49.1048129, lng: 22.2137997 },
    { lat: 49.1050051, lng: 22.2138442 },
    { lat: 49.1054575, lng: 22.2135572 },
    { lat: 49.1057436, lng: 22.2135685 },
    { lat: 49.1058509, lng: 22.2136433 },
    { lat: 49.1059177, lng: 22.2138197 },
    { lat: 49.1060662, lng: 22.2139642 },
    { lat: 49.1062333, lng: 22.2145452 },
    { lat: 49.1060455, lng: 22.2166994 },
    { lat: 49.1073817, lng: 22.2188099 },
    { lat: 49.1078612, lng: 22.2199857 },
    { lat: 49.1089398, lng: 22.2216459 },
    { lat: 49.1100743, lng: 22.2221966 },
    { lat: 49.1113162, lng: 22.2236126 },
    { lat: 49.1115919, lng: 22.2237727 },
    { lat: 49.1116769, lng: 22.2239048 },
    { lat: 49.1116948, lng: 22.2242535 },
    { lat: 49.11187, lng: 22.2244889 },
    { lat: 49.1125096, lng: 22.2246255 },
    { lat: 49.1129528, lng: 22.2248312 },
    { lat: 49.113158, lng: 22.2257601 },
    { lat: 49.1132875, lng: 22.2256701 },
    { lat: 49.1135515, lng: 22.225861 },
    { lat: 49.1137781, lng: 22.225836 },
    { lat: 49.1138604, lng: 22.2260371 },
    { lat: 49.1139946, lng: 22.226009 },
    { lat: 49.11436, lng: 22.2261017 },
    { lat: 49.1148057, lng: 22.2260154 },
    { lat: 49.1150757, lng: 22.2262247 },
    { lat: 49.1153623, lng: 22.2263125 },
    { lat: 49.115595, lng: 22.2265568 },
    { lat: 49.1159851, lng: 22.2267107 },
    { lat: 49.1161221, lng: 22.2266997 },
    { lat: 49.116222, lng: 22.2267861 },
    { lat: 49.1163615, lng: 22.2266873 },
    { lat: 49.1165189, lng: 22.2267391 },
    { lat: 49.1168906, lng: 22.2266168 },
    { lat: 49.1169901, lng: 22.2266414 },
    { lat: 49.1173344, lng: 22.2270954 },
    { lat: 49.1174893, lng: 22.2270848 },
    { lat: 49.1180686, lng: 22.2276458 },
    { lat: 49.118495, lng: 22.2273031 },
    { lat: 49.118588, lng: 22.2265802 },
    { lat: 49.1184482, lng: 22.2255028 },
    { lat: 49.1181319, lng: 22.2246356 },
    { lat: 49.1183888, lng: 22.2243078 },
    { lat: 49.117747, lng: 22.2230589 },
    { lat: 49.1178579, lng: 22.2228887 },
    { lat: 49.1193936, lng: 22.2240194 },
    { lat: 49.1198883, lng: 22.2245151 },
    { lat: 49.122567, lng: 22.2232552 },
    { lat: 49.123166, lng: 22.2227347 },
    { lat: 49.1238712, lng: 22.2218772 },
    { lat: 49.1247951, lng: 22.2214483 },
    { lat: 49.1261663, lng: 22.2185438 },
    { lat: 49.1274342, lng: 22.2164106 },
    { lat: 49.1279787, lng: 22.2166146 },
    { lat: 49.1283542, lng: 22.2165631 },
    { lat: 49.1287921, lng: 22.2163748 },
    { lat: 49.1294514, lng: 22.2165423 },
    { lat: 49.1295743, lng: 22.2164084 },
    { lat: 49.1297657, lng: 22.2163687 },
    { lat: 49.130215, lng: 22.2171397 },
    { lat: 49.1318652, lng: 22.2146002 },
    { lat: 49.1323488, lng: 22.213549 },
    { lat: 49.134217, lng: 22.2103479 },
    { lat: 49.136042, lng: 22.2098354 },
    { lat: 49.1377088, lng: 22.2089823 },
    { lat: 49.1377615, lng: 22.2084297 },
    { lat: 49.1370508, lng: 22.2081808 },
    { lat: 49.1366928, lng: 22.2079635 },
    { lat: 49.1364053, lng: 22.2073313 },
    { lat: 49.1362668, lng: 22.2066766 },
    { lat: 49.1363582, lng: 22.2047349 },
    { lat: 49.1362449, lng: 22.2032531 },
    { lat: 49.1361987, lng: 22.2032534 },
    { lat: 49.1359428, lng: 22.202269 },
    { lat: 49.1356523, lng: 22.2020109 },
    { lat: 49.1355985, lng: 22.2018186 },
    { lat: 49.1349099, lng: 22.2004518 },
    { lat: 49.1345868, lng: 22.1999903 },
    { lat: 49.1340316, lng: 22.1997644 },
    { lat: 49.1332915, lng: 22.1993492 },
    { lat: 49.1335658, lng: 22.1989017 },
    { lat: 49.1327413, lng: 22.1979446 },
    { lat: 49.1323747, lng: 22.1972492 },
    { lat: 49.1321315, lng: 22.1965415 },
    { lat: 49.1313557, lng: 22.1952056 },
    { lat: 49.1311148, lng: 22.1947003 },
    { lat: 49.1310204, lng: 22.1943505 },
    { lat: 49.1307793, lng: 22.1939361 },
    { lat: 49.1305424, lng: 22.1937564 },
    { lat: 49.1304778, lng: 22.1936391 },
    { lat: 49.1301044, lng: 22.1934389 },
    { lat: 49.1299389, lng: 22.1931743 },
    { lat: 49.1297376, lng: 22.1930255 },
    { lat: 49.1297469, lng: 22.1926447 },
    { lat: 49.1295932, lng: 22.1922171 },
    { lat: 49.129322, lng: 22.1919078 },
    { lat: 49.1293173, lng: 22.1917504 },
    { lat: 49.1291588, lng: 22.191652 },
    { lat: 49.1290724, lng: 22.1915201 },
    { lat: 49.1289724, lng: 22.1915164 },
    { lat: 49.1288168, lng: 22.1914551 },
    { lat: 49.1287152, lng: 22.1915845 },
    { lat: 49.12856, lng: 22.191287 },
    { lat: 49.1270753, lng: 22.1914785 },
    { lat: 49.1268277, lng: 22.1913231 },
    { lat: 49.1267402, lng: 22.1895943 },
    { lat: 49.1257831, lng: 22.1885493 },
    { lat: 49.1252958, lng: 22.1900185 },
    { lat: 49.1242964, lng: 22.1907332 },
    { lat: 49.1230648, lng: 22.1902862 },
    { lat: 49.1232006, lng: 22.1899774 },
    { lat: 49.1234039, lng: 22.1897125 },
    { lat: 49.1233567, lng: 22.1892712 },
    { lat: 49.1236893, lng: 22.1872238 },
    { lat: 49.1236416, lng: 22.18586 },
    { lat: 49.1232701, lng: 22.1848034 },
    { lat: 49.1228836, lng: 22.1843674 },
    { lat: 49.1229609, lng: 22.1840154 },
    { lat: 49.1226959, lng: 22.1832821 },
    { lat: 49.1222536, lng: 22.1810461 },
    { lat: 49.1219853, lng: 22.1810197 },
    { lat: 49.1216307, lng: 22.1794129 },
    { lat: 49.1210341, lng: 22.1787962 },
    { lat: 49.1201771, lng: 22.1790544 },
    { lat: 49.1195002, lng: 22.1789037 },
    { lat: 49.1174121, lng: 22.1780352 },
    { lat: 49.1167941, lng: 22.1786526 },
    { lat: 49.1155682, lng: 22.1789471 },
    { lat: 49.115124, lng: 22.1801908 },
    { lat: 49.1149914, lng: 22.1811819 },
    { lat: 49.1146676, lng: 22.1819299 },
    { lat: 49.1140208, lng: 22.1822272 },
    { lat: 49.113439, lng: 22.1819074 },
    { lat: 49.1127532, lng: 22.182595 },
    { lat: 49.1124744, lng: 22.182864 },
    { lat: 49.1127089, lng: 22.1835887 },
    { lat: 49.1116946, lng: 22.1866659 },
    { lat: 49.1101793, lng: 22.187544 },
    { lat: 49.1090142, lng: 22.1885325 },
    { lat: 49.1076605, lng: 22.1895459 },
    { lat: 49.1064834, lng: 22.1885284 },
    { lat: 49.1066514, lng: 22.1875988 },
    { lat: 49.1057809, lng: 22.1876959 },
    { lat: 49.1055125, lng: 22.1871691 },
    { lat: 49.1051615, lng: 22.1871136 },
    { lat: 49.1046041, lng: 22.1867398 },
    { lat: 49.1039947, lng: 22.1849104 },
    { lat: 49.1038429, lng: 22.1845684 },
    { lat: 49.1037262, lng: 22.1846776 },
    { lat: 49.1011994, lng: 22.1895494 },
    { lat: 49.100396, lng: 22.1906306 },
    { lat: 49.0991763, lng: 22.1935842 },
  ],
  ZemplínskeHámre: [
    { lat: 48.929273, lng: 22.1918562 },
    { lat: 48.9421508, lng: 22.1842404 },
    { lat: 48.944631, lng: 22.184989 },
    { lat: 48.9486567, lng: 22.185443 },
    { lat: 48.9496593, lng: 22.1849242 },
    { lat: 48.9504005, lng: 22.1840823 },
    { lat: 48.954817, lng: 22.185253 },
    { lat: 48.9557472, lng: 22.1869925 },
    { lat: 48.9583923, lng: 22.1870332 },
    { lat: 48.9601001, lng: 22.1881315 },
    { lat: 48.9602012, lng: 22.186846 },
    { lat: 48.96016, lng: 22.1858216 },
    { lat: 48.9597608, lng: 22.1845942 },
    { lat: 48.9593718, lng: 22.1839027 },
    { lat: 48.9606842, lng: 22.182813 },
    { lat: 48.9609407, lng: 22.1820783 },
    { lat: 48.9612217, lng: 22.1817221 },
    { lat: 48.9616017, lng: 22.18137 },
    { lat: 48.9611705, lng: 22.1804144 },
    { lat: 48.9602407, lng: 22.1787503 },
    { lat: 48.9591882, lng: 22.1776764 },
    { lat: 48.9599916, lng: 22.1771989 },
    { lat: 48.959651, lng: 22.1744527 },
    { lat: 48.960138, lng: 22.1696345 },
    { lat: 48.95833, lng: 22.1696533 },
    { lat: 48.9582991, lng: 22.1695275 },
    { lat: 48.9583739, lng: 22.1687175 },
    { lat: 48.9583369, lng: 22.1678956 },
    { lat: 48.9584059, lng: 22.1670791 },
    { lat: 48.959705, lng: 22.1667299 },
    { lat: 48.959841, lng: 22.16678 },
    { lat: 48.9608377, lng: 22.1664939 },
    { lat: 48.9611742, lng: 22.1662002 },
    { lat: 48.9618957, lng: 22.1659369 },
    { lat: 48.9623282, lng: 22.1646824 },
    { lat: 48.9629314, lng: 22.1639683 },
    { lat: 48.9632957, lng: 22.1633681 },
    { lat: 48.9633184, lng: 22.1631847 },
    { lat: 48.9651093, lng: 22.1598132 },
    { lat: 48.9655807, lng: 22.1592294 },
    { lat: 48.966121, lng: 22.1588156 },
    { lat: 48.9665257, lng: 22.1587551 },
    { lat: 48.9662129, lng: 22.1584012 },
    { lat: 48.9674774, lng: 22.1579837 },
    { lat: 48.9679288, lng: 22.1580493 },
    { lat: 48.9681444, lng: 22.157788 },
    { lat: 48.9684938, lng: 22.1579188 },
    { lat: 48.9686292, lng: 22.1576359 },
    { lat: 48.9688079, lng: 22.1576365 },
    { lat: 48.9691773, lng: 22.1579491 },
    { lat: 48.9693243, lng: 22.1577398 },
    { lat: 48.9692776, lng: 22.1577598 },
    { lat: 48.9694053, lng: 22.157298 },
    { lat: 48.9699746, lng: 22.1567673 },
    { lat: 48.9700539, lng: 22.1569894 },
    { lat: 48.9703885, lng: 22.1568614 },
    { lat: 48.970429, lng: 22.1572616 },
    { lat: 48.970814, lng: 22.157809 },
    { lat: 48.9712586, lng: 22.1575785 },
    { lat: 48.971742, lng: 22.1572264 },
    { lat: 48.9720693, lng: 22.1568857 },
    { lat: 48.9713997, lng: 22.155593 },
    { lat: 48.9709363, lng: 22.1549593 },
    { lat: 48.9704528, lng: 22.1549226 },
    { lat: 48.9704308, lng: 22.1547949 },
    { lat: 48.9693939, lng: 22.1528463 },
    { lat: 48.9679457, lng: 22.1507646 },
    { lat: 48.9682301, lng: 22.1461723 },
    { lat: 48.9682026, lng: 22.1442586 },
    { lat: 48.9672211, lng: 22.1450003 },
    { lat: 48.9662826, lng: 22.1460505 },
    { lat: 48.9650527, lng: 22.1468044 },
    { lat: 48.9648765, lng: 22.1461963 },
    { lat: 48.9645867, lng: 22.1460159 },
    { lat: 48.964016, lng: 22.1462872 },
    { lat: 48.9635735, lng: 22.1435864 },
    { lat: 48.9636302, lng: 22.1432669 },
    { lat: 48.9636285, lng: 22.1429502 },
    { lat: 48.9637532, lng: 22.1425523 },
    { lat: 48.9637793, lng: 22.1422764 },
    { lat: 48.9640044, lng: 22.1417948 },
    { lat: 48.963845, lng: 22.1416594 },
    { lat: 48.962888, lng: 22.1403513 },
    { lat: 48.9624383, lng: 22.1410439 },
    { lat: 48.9608309, lng: 22.1388053 },
    { lat: 48.9613614, lng: 22.1379591 },
    { lat: 48.9608362, lng: 22.1372347 },
    { lat: 48.9607797, lng: 22.1370735 },
    { lat: 48.9604468, lng: 22.1373744 },
    { lat: 48.9601807, lng: 22.1379223 },
    { lat: 48.9598089, lng: 22.1390596 },
    { lat: 48.9589211, lng: 22.1401199 },
    { lat: 48.9585616, lng: 22.1403273 },
    { lat: 48.9583956, lng: 22.1407317 },
    { lat: 48.9581854, lng: 22.1409376 },
    { lat: 48.9577532, lng: 22.1416024 },
    { lat: 48.9568804, lng: 22.1420927 },
    { lat: 48.956754, lng: 22.1419248 },
    { lat: 48.956623, lng: 22.1419991 },
    { lat: 48.956502, lng: 22.1421165 },
    { lat: 48.9561417, lng: 22.1430286 },
    { lat: 48.9556252, lng: 22.1432569 },
    { lat: 48.9553553, lng: 22.1437452 },
    { lat: 48.9548557, lng: 22.1436437 },
    { lat: 48.9545102, lng: 22.1438725 },
    { lat: 48.9542378, lng: 22.1438237 },
    { lat: 48.9538943, lng: 22.1437969 },
    { lat: 48.9537163, lng: 22.1435927 },
    { lat: 48.9536247, lng: 22.1437698 },
    { lat: 48.9532587, lng: 22.1444735 },
    { lat: 48.9530883, lng: 22.1447396 },
    { lat: 48.9528558, lng: 22.1451005 },
    { lat: 48.952951, lng: 22.1453234 },
    { lat: 48.9528511, lng: 22.145337 },
    { lat: 48.9528172, lng: 22.1453681 },
    { lat: 48.9527521, lng: 22.1454292 },
    { lat: 48.9526, lng: 22.1459061 },
    { lat: 48.9524945, lng: 22.1460098 },
    { lat: 48.9524474, lng: 22.1460631 },
    { lat: 48.952373, lng: 22.1462099 },
    { lat: 48.9523431, lng: 22.1462698 },
    { lat: 48.9523151, lng: 22.1463751 },
    { lat: 48.9523232, lng: 22.1465314 },
    { lat: 48.9523276, lng: 22.1466202 },
    { lat: 48.9523319, lng: 22.1467145 },
    { lat: 48.9523379, lng: 22.1468326 },
    { lat: 48.9523404, lng: 22.1468912 },
    { lat: 48.9522709, lng: 22.1471869 },
    { lat: 48.9522151, lng: 22.1471577 },
    { lat: 48.9518732, lng: 22.147888 },
    { lat: 48.9517104, lng: 22.1481067 },
    { lat: 48.9516083, lng: 22.1483598 },
    { lat: 48.9514808, lng: 22.1484815 },
    { lat: 48.9514762, lng: 22.148578 },
    { lat: 48.9514978, lng: 22.1485847 },
    { lat: 48.951484, lng: 22.1486695 },
    { lat: 48.9514918, lng: 22.1487629 },
    { lat: 48.9514737, lng: 22.148858 },
    { lat: 48.9514877, lng: 22.1488805 },
    { lat: 48.9514847, lng: 22.1488946 },
    { lat: 48.9514953, lng: 22.1489144 },
    { lat: 48.9514917, lng: 22.1490546 },
    { lat: 48.9514882, lng: 22.1491914 },
    { lat: 48.9514874, lng: 22.1492076 },
    { lat: 48.9514857, lng: 22.1492352 },
    { lat: 48.9514534, lng: 22.1492971 },
    { lat: 48.9514477, lng: 22.1492924 },
    { lat: 48.9514187, lng: 22.1493948 },
    { lat: 48.9513888, lng: 22.1495213 },
    { lat: 48.9513389, lng: 22.1495955 },
    { lat: 48.9512793, lng: 22.1496996 },
    { lat: 48.9510875, lng: 22.1498114 },
    { lat: 48.9510732, lng: 22.1498265 },
    { lat: 48.9508976, lng: 22.1500194 },
    { lat: 48.9506858, lng: 22.1503417 },
    { lat: 48.9506485, lng: 22.1506758 },
    { lat: 48.9504583, lng: 22.151063 },
    { lat: 48.950432, lng: 22.1510981 },
    { lat: 48.9504189, lng: 22.1511145 },
    { lat: 48.9502289, lng: 22.1514073 },
    { lat: 48.9501823, lng: 22.1515844 },
    { lat: 48.9501634, lng: 22.151642 },
    { lat: 48.9501206, lng: 22.1516911 },
    { lat: 48.9500977, lng: 22.1517174 },
    { lat: 48.949982, lng: 22.151914 },
    { lat: 48.949922, lng: 22.151888 },
    { lat: 48.949908, lng: 22.151881 },
    { lat: 48.949861, lng: 22.15186 },
    { lat: 48.949728, lng: 22.151799 },
    { lat: 48.949668, lng: 22.151772 },
    { lat: 48.949361, lng: 22.151635 },
    { lat: 48.949324, lng: 22.151619 },
    { lat: 48.949305, lng: 22.151796 },
    { lat: 48.949254, lng: 22.152291 },
    { lat: 48.949157, lng: 22.152504 },
    { lat: 48.94908, lng: 22.152675 },
    { lat: 48.948994, lng: 22.152868 },
    { lat: 48.949079, lng: 22.152965 },
    { lat: 48.94885, lng: 22.153404 },
    { lat: 48.948837, lng: 22.153415 },
    { lat: 48.948813, lng: 22.153437 },
    { lat: 48.948803, lng: 22.153446 },
    { lat: 48.948764, lng: 22.153484 },
    { lat: 48.948321, lng: 22.154327 },
    { lat: 48.948294, lng: 22.154386 },
    { lat: 48.948259, lng: 22.154464 },
    { lat: 48.948197, lng: 22.154599 },
    { lat: 48.948151, lng: 22.1547 },
    { lat: 48.948061, lng: 22.154716 },
    { lat: 48.948038, lng: 22.154761 },
    { lat: 48.94792, lng: 22.154911 },
    { lat: 48.947748, lng: 22.155046 },
    { lat: 48.947734, lng: 22.155057 },
    { lat: 48.947712, lng: 22.155075 },
    { lat: 48.947692, lng: 22.15509 },
    { lat: 48.947686, lng: 22.155106 },
    { lat: 48.947682, lng: 22.155115 },
    { lat: 48.947604, lng: 22.155335 },
    { lat: 48.947606, lng: 22.15536 },
    { lat: 48.947561, lng: 22.155479 },
    { lat: 48.947556, lng: 22.155494 },
    { lat: 48.947516, lng: 22.155603 },
    { lat: 48.947475, lng: 22.155713 },
    { lat: 48.947464, lng: 22.155736 },
    { lat: 48.947451, lng: 22.155763 },
    { lat: 48.947434, lng: 22.155798 },
    { lat: 48.947337, lng: 22.156 },
    { lat: 48.947276, lng: 22.156092 },
    { lat: 48.947244, lng: 22.156145 },
    { lat: 48.947204, lng: 22.156207 },
    { lat: 48.947196, lng: 22.156222 },
    { lat: 48.947123, lng: 22.156269 },
    { lat: 48.947075, lng: 22.156302 },
    { lat: 48.947078, lng: 22.156328 },
    { lat: 48.946887, lng: 22.156402 },
    { lat: 48.946883, lng: 22.156392 },
    { lat: 48.946881, lng: 22.15639 },
    { lat: 48.946794, lng: 22.156437 },
    { lat: 48.946758, lng: 22.156455 },
    { lat: 48.946729, lng: 22.156471 },
    { lat: 48.946706, lng: 22.156483 },
    { lat: 48.946608, lng: 22.156527 },
    { lat: 48.946608, lng: 22.156538 },
    { lat: 48.946508, lng: 22.156596 },
    { lat: 48.946495, lng: 22.156603 },
    { lat: 48.946408, lng: 22.156635 },
    { lat: 48.946397, lng: 22.15664 },
    { lat: 48.94626, lng: 22.156673 },
    { lat: 48.946255, lng: 22.156689 },
    { lat: 48.946254, lng: 22.156692 },
    { lat: 48.946249, lng: 22.156706 },
    { lat: 48.946231, lng: 22.156754 },
    { lat: 48.946206, lng: 22.156818 },
    { lat: 48.946192, lng: 22.156853 },
    { lat: 48.946176, lng: 22.156899 },
    { lat: 48.946153, lng: 22.156892 },
    { lat: 48.946006, lng: 22.156847 },
    { lat: 48.945966, lng: 22.156834 },
    { lat: 48.945905, lng: 22.156837 },
    { lat: 48.945802, lng: 22.156843 },
    { lat: 48.945749, lng: 22.156845 },
    { lat: 48.945697, lng: 22.156848 },
    { lat: 48.945635, lng: 22.156851 },
    { lat: 48.94563, lng: 22.156851 },
    { lat: 48.945332, lng: 22.156866 },
    { lat: 48.945128, lng: 22.156827 },
    { lat: 48.944827, lng: 22.156697 },
    { lat: 48.944564, lng: 22.156584 },
    { lat: 48.944405, lng: 22.156365 },
    { lat: 48.944156, lng: 22.156021 },
    { lat: 48.944096, lng: 22.155889 },
    { lat: 48.943986, lng: 22.155644 },
    { lat: 48.943817, lng: 22.155445 },
    { lat: 48.943596, lng: 22.155364 },
    { lat: 48.943537, lng: 22.155343 },
    { lat: 48.943514, lng: 22.155335 },
    { lat: 48.943501, lng: 22.155298 },
    { lat: 48.94349, lng: 22.155264 },
    { lat: 48.94346, lng: 22.155179 },
    { lat: 48.943108, lng: 22.154633 },
    { lat: 48.94265, lng: 22.153835 },
    { lat: 48.942569, lng: 22.153694 },
    { lat: 48.942492, lng: 22.153591 },
    { lat: 48.941436, lng: 22.152168 },
    { lat: 48.941387, lng: 22.152104 },
    { lat: 48.940962, lng: 22.151936 },
    { lat: 48.940953, lng: 22.151933 },
    { lat: 48.940922, lng: 22.151921 },
    { lat: 48.940734, lng: 22.151847 },
    { lat: 48.940071, lng: 22.15144 },
    { lat: 48.939885, lng: 22.151326 },
    { lat: 48.939529, lng: 22.151055 },
    { lat: 48.938893, lng: 22.150569 },
    { lat: 48.938395, lng: 22.150318 },
    { lat: 48.937842, lng: 22.150308 },
    { lat: 48.937261, lng: 22.150161 },
    { lat: 48.937175, lng: 22.150175 },
    { lat: 48.935985, lng: 22.150369 },
    { lat: 48.935889, lng: 22.150384 },
    { lat: 48.935275, lng: 22.1509 },
    { lat: 48.935094, lng: 22.151069 },
    { lat: 48.934635, lng: 22.151497 },
    { lat: 48.934503, lng: 22.151693 },
    { lat: 48.934291, lng: 22.152008 },
    { lat: 48.93429, lng: 22.152009 },
    { lat: 48.93426, lng: 22.15207 },
    { lat: 48.934242, lng: 22.152108 },
    { lat: 48.934223, lng: 22.152148 },
    { lat: 48.934215, lng: 22.152162 },
    { lat: 48.934193, lng: 22.15221 },
    { lat: 48.934198, lng: 22.152295 },
    { lat: 48.934188, lng: 22.152355 },
    { lat: 48.934301, lng: 22.152327 },
    { lat: 48.934355, lng: 22.152313 },
    { lat: 48.934613, lng: 22.152482 },
    { lat: 48.934829, lng: 22.152622 },
    { lat: 48.934896, lng: 22.152662 },
    { lat: 48.935105, lng: 22.152728 },
    { lat: 48.935207, lng: 22.152711 },
    { lat: 48.93524, lng: 22.152793 },
    { lat: 48.935379, lng: 22.15315 },
    { lat: 48.935552, lng: 22.153227 },
    { lat: 48.935704, lng: 22.153189 },
    { lat: 48.935856, lng: 22.1531 },
    { lat: 48.935905, lng: 22.15307 },
    { lat: 48.936097, lng: 22.153101 },
    { lat: 48.936246, lng: 22.153303 },
    { lat: 48.936318, lng: 22.153401 },
    { lat: 48.936359, lng: 22.153413 },
    { lat: 48.936466, lng: 22.153402 },
    { lat: 48.936474, lng: 22.153433 },
    { lat: 48.936531, lng: 22.153671 },
    { lat: 48.936597, lng: 22.153654 },
    { lat: 48.936789, lng: 22.153787 },
    { lat: 48.936839, lng: 22.153865 },
    { lat: 48.937099, lng: 22.153902 },
    { lat: 48.937244, lng: 22.154177 },
    { lat: 48.937271, lng: 22.154233 },
    { lat: 48.937377, lng: 22.154207 },
    { lat: 48.937532, lng: 22.154329 },
    { lat: 48.937576, lng: 22.154364 },
    { lat: 48.937634, lng: 22.154414 },
    { lat: 48.937702, lng: 22.15442 },
    { lat: 48.937754, lng: 22.154425 },
    { lat: 48.937907, lng: 22.154283 },
    { lat: 48.937996, lng: 22.154201 },
    { lat: 48.938101, lng: 22.154314 },
    { lat: 48.938099, lng: 22.154338 },
    { lat: 48.938095, lng: 22.154394 },
    { lat: 48.938027, lng: 22.154466 },
    { lat: 48.937922, lng: 22.154464 },
    { lat: 48.937856, lng: 22.154509 },
    { lat: 48.937849, lng: 22.154513 },
    { lat: 48.937809, lng: 22.15454 },
    { lat: 48.937642, lng: 22.154587 },
    { lat: 48.937493, lng: 22.154522 },
    { lat: 48.93746, lng: 22.154508 },
    { lat: 48.937205, lng: 22.154607 },
    { lat: 48.936945, lng: 22.154681 },
    { lat: 48.936832, lng: 22.154713 },
    { lat: 48.93682, lng: 22.154732 },
    { lat: 48.936778, lng: 22.154803 },
    { lat: 48.936785, lng: 22.154848 },
    { lat: 48.936819, lng: 22.155046 },
    { lat: 48.936845, lng: 22.155208 },
    { lat: 48.936858, lng: 22.155284 },
    { lat: 48.93687, lng: 22.155353 },
    { lat: 48.936924, lng: 22.155465 },
    { lat: 48.937005, lng: 22.155599 },
    { lat: 48.937084, lng: 22.155677 },
    { lat: 48.937265, lng: 22.155842 },
    { lat: 48.937291, lng: 22.15604 },
    { lat: 48.937353, lng: 22.156155 },
    { lat: 48.937447, lng: 22.156242 },
    { lat: 48.93761, lng: 22.156523 },
    { lat: 48.93782, lng: 22.157047 },
    { lat: 48.937945, lng: 22.157303 },
    { lat: 48.938034, lng: 22.157568 },
    { lat: 48.93822, lng: 22.157715 },
    { lat: 48.938602, lng: 22.157824 },
    { lat: 48.938716, lng: 22.157753 },
    { lat: 48.938764, lng: 22.157758 },
    { lat: 48.938648, lng: 22.158094 },
    { lat: 48.938507, lng: 22.15828 },
    { lat: 48.938473, lng: 22.158415 },
    { lat: 48.938416, lng: 22.158487 },
    { lat: 48.938326, lng: 22.158603 },
    { lat: 48.938263, lng: 22.158793 },
    { lat: 48.93819, lng: 22.158948 },
    { lat: 48.938168, lng: 22.159075 },
    { lat: 48.93814, lng: 22.159141 },
    { lat: 48.938141, lng: 22.159194 },
    { lat: 48.938145, lng: 22.159345 },
    { lat: 48.938146, lng: 22.159416 },
    { lat: 48.938149, lng: 22.15952 },
    { lat: 48.938149, lng: 22.159534 },
    { lat: 48.938149, lng: 22.159542 },
    { lat: 48.938151, lng: 22.159606 },
    { lat: 48.938153, lng: 22.159684 },
    { lat: 48.938153, lng: 22.159707 },
    { lat: 48.938157, lng: 22.159879 },
    { lat: 48.938159, lng: 22.159959 },
    { lat: 48.938166, lng: 22.160201 },
    { lat: 48.938173, lng: 22.160531 },
    { lat: 48.938176, lng: 22.16066 },
    { lat: 48.938181, lng: 22.160847 },
    { lat: 48.938181, lng: 22.160882 },
    { lat: 48.938182, lng: 22.160901 },
    { lat: 48.938184, lng: 22.161 },
    { lat: 48.938082, lng: 22.1613 },
    { lat: 48.938054, lng: 22.161382 },
    { lat: 48.938025, lng: 22.16147 },
    { lat: 48.938049, lng: 22.161647 },
    { lat: 48.938043, lng: 22.161721 },
    { lat: 48.938032, lng: 22.16185 },
    { lat: 48.937909, lng: 22.162023 },
    { lat: 48.937813, lng: 22.16216 },
    { lat: 48.937784, lng: 22.162391 },
    { lat: 48.937833, lng: 22.162391 },
    { lat: 48.937887, lng: 22.162568 },
    { lat: 48.937907, lng: 22.162629 },
    { lat: 48.938, lng: 22.162941 },
    { lat: 48.938082, lng: 22.163288 },
    { lat: 48.938167, lng: 22.163643 },
    { lat: 48.937852, lng: 22.16368 },
    { lat: 48.937673, lng: 22.163629 },
    { lat: 48.937519, lng: 22.163719 },
    { lat: 48.937466, lng: 22.163725 },
    { lat: 48.937245, lng: 22.163748 },
    { lat: 48.93702, lng: 22.164131 },
    { lat: 48.936662, lng: 22.164187 },
    { lat: 48.936396, lng: 22.164229 },
    { lat: 48.936377, lng: 22.164284 },
    { lat: 48.936323, lng: 22.164442 },
    { lat: 48.93624, lng: 22.164689 },
    { lat: 48.936223, lng: 22.164744 },
    { lat: 48.936054, lng: 22.165238 },
    { lat: 48.936083, lng: 22.165265 },
    { lat: 48.93616, lng: 22.165342 },
    { lat: 48.936169, lng: 22.165351 },
    { lat: 48.936174, lng: 22.165355 },
    { lat: 48.936362, lng: 22.16554 },
    { lat: 48.936591, lng: 22.165765 },
    { lat: 48.936664, lng: 22.165835 },
    { lat: 48.937002, lng: 22.166154 },
    { lat: 48.937278, lng: 22.166425 },
    { lat: 48.937734, lng: 22.166462 },
    { lat: 48.937581, lng: 22.165202 },
    { lat: 48.937568, lng: 22.165099 },
    { lat: 48.937504, lng: 22.164567 },
    { lat: 48.937497, lng: 22.164503 },
    { lat: 48.937474, lng: 22.164304 },
    { lat: 48.937469, lng: 22.164261 },
    { lat: 48.937724, lng: 22.163977 },
    { lat: 48.937769, lng: 22.163927 },
    { lat: 48.937903, lng: 22.163909 },
    { lat: 48.937921, lng: 22.163906 },
    { lat: 48.938208, lng: 22.163867 },
    { lat: 48.938218, lng: 22.163956 },
    { lat: 48.93817, lng: 22.164185 },
    { lat: 48.938166, lng: 22.164205 },
    { lat: 48.938176, lng: 22.164232 },
    { lat: 48.938212, lng: 22.164336 },
    { lat: 48.93828, lng: 22.164416 },
    { lat: 48.938345, lng: 22.164742 },
    { lat: 48.938327, lng: 22.165017 },
    { lat: 48.938372, lng: 22.16507 },
    { lat: 48.938313, lng: 22.16546 },
    { lat: 48.938309, lng: 22.165488 },
    { lat: 48.938335, lng: 22.165573 },
    { lat: 48.938364, lng: 22.16567 },
    { lat: 48.938361, lng: 22.165712 },
    { lat: 48.938349, lng: 22.165869 },
    { lat: 48.938298, lng: 22.166036 },
    { lat: 48.938303, lng: 22.166146 },
    { lat: 48.93831, lng: 22.166297 },
    { lat: 48.938224, lng: 22.166503 },
    { lat: 48.938167, lng: 22.166862 },
    { lat: 48.93816, lng: 22.166907 },
    { lat: 48.937958, lng: 22.16817 },
    { lat: 48.938042, lng: 22.168792 },
    { lat: 48.938013, lng: 22.170409 },
    { lat: 48.937943, lng: 22.171009 },
    { lat: 48.937961, lng: 22.171569 },
    { lat: 48.937996, lng: 22.172209 },
    { lat: 48.938032, lng: 22.173235 },
    { lat: 48.938038, lng: 22.173383 },
    { lat: 48.937821, lng: 22.173917 },
    { lat: 48.937808, lng: 22.173947 },
    { lat: 48.937795, lng: 22.17398 },
    { lat: 48.937775, lng: 22.174029 },
    { lat: 48.937516, lng: 22.174552 },
    { lat: 48.937341, lng: 22.174904 },
    { lat: 48.93682, lng: 22.17569 },
    { lat: 48.936632, lng: 22.176542 },
    { lat: 48.936294, lng: 22.177743 },
    { lat: 48.936059, lng: 22.179363 },
    { lat: 48.935689, lng: 22.180381 },
    { lat: 48.935602, lng: 22.180474 },
    { lat: 48.934714, lng: 22.181416 },
    { lat: 48.934117, lng: 22.181862 },
    { lat: 48.933405, lng: 22.182329 },
    { lat: 48.932978, lng: 22.182582 },
    { lat: 48.932167, lng: 22.183155 },
    { lat: 48.932001, lng: 22.183283 },
    { lat: 48.93168, lng: 22.183529 },
    { lat: 48.93125, lng: 22.184137 },
    { lat: 48.93124, lng: 22.184151 },
    { lat: 48.930958, lng: 22.184622 },
    { lat: 48.93092, lng: 22.184834 },
    { lat: 48.930809, lng: 22.185455 },
    { lat: 48.930768, lng: 22.186055 },
    { lat: 48.930507, lng: 22.186905 },
    { lat: 48.930313, lng: 22.187682 },
    { lat: 48.930144, lng: 22.188789 },
    { lat: 48.929955, lng: 22.189673 },
    { lat: 48.929829, lng: 22.189948 },
    { lat: 48.929669, lng: 22.190297 },
    { lat: 48.929633, lng: 22.190429 },
    { lat: 48.929273, lng: 22.1918562 },
  ],
  Snina: [
    { lat: 49.0313295, lng: 22.1868704 },
    { lat: 49.0317691, lng: 22.1852222 },
    { lat: 49.0321652, lng: 22.1842444 },
    { lat: 49.0323286, lng: 22.1834179 },
    { lat: 49.0320962, lng: 22.1800734 },
    { lat: 49.0322964, lng: 22.1783467 },
    { lat: 49.032284, lng: 22.1778957 },
    { lat: 49.0320283, lng: 22.1766981 },
    { lat: 49.0320117, lng: 22.1761667 },
    { lat: 49.0321759, lng: 22.1743451 },
    { lat: 49.032471, lng: 22.1728356 },
    { lat: 49.0325191, lng: 22.1724156 },
    { lat: 49.0326932, lng: 22.1708951 },
    { lat: 49.0327823, lng: 22.1706047 },
    { lat: 49.0332005, lng: 22.1680452 },
    { lat: 49.0331935, lng: 22.1669423 },
    { lat: 49.0335664, lng: 22.1662365 },
    { lat: 49.0339763, lng: 22.1658653 },
    { lat: 49.0344011, lng: 22.1647707 },
    { lat: 49.0351645, lng: 22.1623025 },
    { lat: 49.0368329, lng: 22.1599679 },
    { lat: 49.0377067, lng: 22.1590784 },
    { lat: 49.0381307, lng: 22.1587799 },
    { lat: 49.0382204, lng: 22.1586814 },
    { lat: 49.0394327, lng: 22.1573489 },
    { lat: 49.0405839, lng: 22.1556085 },
    { lat: 49.0416661, lng: 22.1533186 },
    { lat: 49.0421739, lng: 22.152514 },
    { lat: 49.0440554, lng: 22.1510845 },
    { lat: 49.0433819, lng: 22.1502671 },
    { lat: 49.0410786, lng: 22.1496864 },
    { lat: 49.0393942, lng: 22.1491136 },
    { lat: 49.0384012, lng: 22.1490296 },
    { lat: 49.0356547, lng: 22.1500064 },
    { lat: 49.0341856, lng: 22.1496957 },
    { lat: 49.0332724, lng: 22.149801 },
    { lat: 49.0313263, lng: 22.1491548 },
    { lat: 49.0306178, lng: 22.1482696 },
    { lat: 49.0296146, lng: 22.1479807 },
    { lat: 49.0273387, lng: 22.14825 },
    { lat: 49.0261312, lng: 22.1480721 },
    { lat: 49.0255445, lng: 22.1482847 },
    { lat: 49.025215, lng: 22.1486825 },
    { lat: 49.0239526, lng: 22.1489522 },
    { lat: 49.0230379, lng: 22.149009 },
    { lat: 49.0213707, lng: 22.1491125 },
    { lat: 49.0212729, lng: 22.1491186 },
    { lat: 49.0204604, lng: 22.1486652 },
    { lat: 49.0196687, lng: 22.1479369 },
    { lat: 49.0182711, lng: 22.146201 },
    { lat: 49.0170393, lng: 22.1423835 },
    { lat: 49.0159471, lng: 22.1411181 },
    { lat: 49.0157764, lng: 22.1381087 },
    { lat: 49.0160782, lng: 22.1374404 },
    { lat: 49.0170604, lng: 22.1372629 },
    { lat: 49.0175591, lng: 22.1369875 },
    { lat: 49.0174585, lng: 22.1366328 },
    { lat: 49.0177929, lng: 22.1357756 },
    { lat: 49.0171341, lng: 22.134522 },
    { lat: 49.0176121, lng: 22.1344201 },
    { lat: 49.0179834, lng: 22.13477 },
    { lat: 49.0182447, lng: 22.1353662 },
    { lat: 49.018314, lng: 22.1357334 },
    { lat: 49.0187695, lng: 22.1364128 },
    { lat: 49.0192042, lng: 22.1356654 },
    { lat: 49.0198313, lng: 22.1351104 },
    { lat: 49.0196752, lng: 22.134344 },
    { lat: 49.0199823, lng: 22.1346293 },
    { lat: 49.0208708, lng: 22.1339575 },
    { lat: 49.021974, lng: 22.1325796 },
    { lat: 49.0220219, lng: 22.1309687 },
    { lat: 49.0224725, lng: 22.1290548 },
    { lat: 49.0228281, lng: 22.1286774 },
    { lat: 49.0230931, lng: 22.128905 },
    { lat: 49.0229681, lng: 22.1293271 },
    { lat: 49.0226844, lng: 22.1307242 },
    { lat: 49.0236487, lng: 22.1306965 },
    { lat: 49.0239542, lng: 22.1304845 },
    { lat: 49.0246397, lng: 22.1297309 },
    { lat: 49.0250163, lng: 22.1289477 },
    { lat: 49.0254652, lng: 22.1290021 },
    { lat: 49.025995, lng: 22.128902 },
    { lat: 49.0266399, lng: 22.1281929 },
    { lat: 49.0271337, lng: 22.1273206 },
    { lat: 49.0273379, lng: 22.1267268 },
    { lat: 49.0284755, lng: 22.1250987 },
    { lat: 49.0285389, lng: 22.1244405 },
    { lat: 49.0287943, lng: 22.1238587 },
    { lat: 49.029126, lng: 22.1233764 },
    { lat: 49.0293534, lng: 22.1221674 },
    { lat: 49.0296856, lng: 22.1215151 },
    { lat: 49.0298471, lng: 22.1208175 },
    { lat: 49.0298953, lng: 22.1200841 },
    { lat: 49.0300115, lng: 22.1197101 },
    { lat: 49.0286292, lng: 22.1184039 },
    { lat: 49.0282958, lng: 22.118824 },
    { lat: 49.0274332, lng: 22.1183411 },
    { lat: 49.0270429, lng: 22.1175711 },
    { lat: 49.0260403, lng: 22.1160026 },
    { lat: 49.024633, lng: 22.11511 },
    { lat: 49.024614, lng: 22.115109 },
    { lat: 49.023468, lng: 22.115014 },
    { lat: 49.022334, lng: 22.113035 },
    { lat: 49.021653, lng: 22.111248 },
    { lat: 49.021451, lng: 22.111166 },
    { lat: 49.020629, lng: 22.110831 },
    { lat: 49.020621, lng: 22.110827 },
    { lat: 49.020571, lng: 22.110807 },
    { lat: 49.020434, lng: 22.110752 },
    { lat: 49.02048, lng: 22.11049 },
    { lat: 49.020502, lng: 22.110366 },
    { lat: 49.020424, lng: 22.110156 },
    { lat: 49.020573, lng: 22.109379 },
    { lat: 49.020797, lng: 22.109138 },
    { lat: 49.020676, lng: 22.108944 },
    { lat: 49.020746, lng: 22.108691 },
    { lat: 49.02079, lng: 22.108253 },
    { lat: 49.020752, lng: 22.108222 },
    { lat: 49.02073, lng: 22.108204 },
    { lat: 49.020712, lng: 22.10819 },
    { lat: 49.020673, lng: 22.108158 },
    { lat: 49.020772, lng: 22.108 },
    { lat: 49.020786, lng: 22.107977 },
    { lat: 49.020687, lng: 22.107861 },
    { lat: 49.020658, lng: 22.107828 },
    { lat: 49.020681, lng: 22.107748 },
    { lat: 49.020741, lng: 22.107527 },
    { lat: 49.020769, lng: 22.107425 },
    { lat: 49.020789, lng: 22.107354 },
    { lat: 49.020862, lng: 22.107093 },
    { lat: 49.020848, lng: 22.107027 },
    { lat: 49.020802, lng: 22.106796 },
    { lat: 49.021008, lng: 22.106406 },
    { lat: 49.021064, lng: 22.106299 },
    { lat: 49.021063, lng: 22.106265 },
    { lat: 49.021082, lng: 22.106212 },
    { lat: 49.021122, lng: 22.10598 },
    { lat: 49.021144, lng: 22.105903 },
    { lat: 49.021156, lng: 22.105704 },
    { lat: 49.021054, lng: 22.105464 },
    { lat: 49.021039, lng: 22.105427 },
    { lat: 49.02103, lng: 22.105407 },
    { lat: 49.021019, lng: 22.105379 },
    { lat: 49.02114, lng: 22.105278 },
    { lat: 49.02117, lng: 22.105253 },
    { lat: 49.021052, lng: 22.104922 },
    { lat: 49.020907, lng: 22.104516 },
    { lat: 49.021007, lng: 22.10427 },
    { lat: 49.020888, lng: 22.103789 },
    { lat: 49.020866, lng: 22.103696 },
    { lat: 49.021027, lng: 22.103572 },
    { lat: 49.020919, lng: 22.103273 },
    { lat: 49.020903, lng: 22.103231 },
    { lat: 49.020893, lng: 22.103205 },
    { lat: 49.020828, lng: 22.103024 },
    { lat: 49.020788, lng: 22.102912 },
    { lat: 49.020822, lng: 22.102734 },
    { lat: 49.020837, lng: 22.102657 },
    { lat: 49.020846, lng: 22.102612 },
    { lat: 49.020782, lng: 22.102625 },
    { lat: 49.02062, lng: 22.10266 },
    { lat: 49.020676, lng: 22.102393 },
    { lat: 49.020556, lng: 22.102305 },
    { lat: 49.020472, lng: 22.102242 },
    { lat: 49.020392, lng: 22.101982 },
    { lat: 49.020162, lng: 22.101904 },
    { lat: 49.019885, lng: 22.10181 },
    { lat: 49.019694, lng: 22.101131 },
    { lat: 49.019566, lng: 22.100898 },
    { lat: 49.019521, lng: 22.100817 },
    { lat: 49.0195, lng: 22.100778 },
    { lat: 49.019446, lng: 22.10067 },
    { lat: 49.019388, lng: 22.100553 },
    { lat: 49.019276, lng: 22.100327 },
    { lat: 49.019152, lng: 22.100079 },
    { lat: 49.019114, lng: 22.100003 },
    { lat: 49.018846, lng: 22.100169 },
    { lat: 49.017771, lng: 22.098647 },
    { lat: 49.017738, lng: 22.098601 },
    { lat: 49.017731, lng: 22.0986 },
    { lat: 49.017687, lng: 22.098247 },
    { lat: 49.017169, lng: 22.097576 },
    { lat: 49.017111, lng: 22.097204 },
    { lat: 49.01708, lng: 22.097007 },
    { lat: 49.016951, lng: 22.096859 },
    { lat: 49.016718, lng: 22.096591 },
    { lat: 49.016586, lng: 22.096497 },
    { lat: 49.016559, lng: 22.096478 },
    { lat: 49.016532, lng: 22.096459 },
    { lat: 49.016491, lng: 22.09643 },
    { lat: 49.016444, lng: 22.096397 },
    { lat: 49.016331, lng: 22.096317 },
    { lat: 49.016282, lng: 22.096111 },
    { lat: 49.016182, lng: 22.095688 },
    { lat: 49.016143, lng: 22.095523 },
    { lat: 49.015806, lng: 22.095086 },
    { lat: 49.01556, lng: 22.094767 },
    { lat: 49.015349, lng: 22.094559 },
    { lat: 49.015126, lng: 22.094338 },
    { lat: 49.015007, lng: 22.094221 },
    { lat: 49.014957, lng: 22.094182 },
    { lat: 49.014374, lng: 22.093731 },
    { lat: 49.014316, lng: 22.093099 },
    { lat: 49.013971, lng: 22.09303 },
    { lat: 49.013886, lng: 22.09263 },
    { lat: 49.013264, lng: 22.091969 },
    { lat: 49.013233, lng: 22.091638 },
    { lat: 49.013233, lng: 22.091637 },
    { lat: 49.012834, lng: 22.091436 },
    { lat: 49.012648, lng: 22.09098 },
    { lat: 49.012283, lng: 22.09065 },
    { lat: 49.012243, lng: 22.090555 },
    { lat: 49.012103, lng: 22.09022 },
    { lat: 49.01194, lng: 22.089982 },
    { lat: 49.011895, lng: 22.089916 },
    { lat: 49.012068, lng: 22.089544 },
    { lat: 49.012088, lng: 22.089308 },
    { lat: 49.011896, lng: 22.08913 },
    { lat: 49.011868, lng: 22.089103 },
    { lat: 49.011373, lng: 22.088644 },
    { lat: 49.011323, lng: 22.08782 },
    { lat: 49.011305, lng: 22.087517 },
    { lat: 49.011404, lng: 22.087282 },
    { lat: 49.011467, lng: 22.08703 },
    { lat: 49.01128, lng: 22.086896 },
    { lat: 49.011202, lng: 22.086607 },
    { lat: 49.011183, lng: 22.086541 },
    { lat: 49.011104, lng: 22.08606 },
    { lat: 49.011098, lng: 22.086015 },
    { lat: 49.010974, lng: 22.085261 },
    { lat: 49.010759, lng: 22.085165 },
    { lat: 49.010949, lng: 22.083971 },
    { lat: 49.010841, lng: 22.083776 },
    { lat: 49.010837, lng: 22.083768 },
    { lat: 49.010834, lng: 22.083764 },
    { lat: 49.01058, lng: 22.083306 },
    { lat: 49.010513, lng: 22.081786 },
    { lat: 49.010913, lng: 22.080936 },
    { lat: 49.009303, lng: 22.080799 },
    { lat: 49.007235, lng: 22.080262 },
    { lat: 49.006425, lng: 22.079796 },
    { lat: 49.006145, lng: 22.079525 },
    { lat: 49.0047643, lng: 22.0770638 },
    { lat: 49.0039287, lng: 22.0765542 },
    { lat: 49.002534, lng: 22.0759319 },
    { lat: 49.0020645, lng: 22.0750569 },
    { lat: 49.0019484, lng: 22.0752392 },
    { lat: 49.0015723, lng: 22.0760801 },
    { lat: 49.0005109, lng: 22.0765662 },
    { lat: 49.0004134, lng: 22.0779622 },
    { lat: 49.0002883, lng: 22.078425 },
    { lat: 49.0003282, lng: 22.0789251 },
    { lat: 48.9994, lng: 22.0816258 },
    { lat: 48.9993043, lng: 22.082331 },
    { lat: 48.999659, lng: 22.082969 },
    { lat: 48.9996344, lng: 22.0837359 },
    { lat: 48.9998006, lng: 22.0841061 },
    { lat: 49.0003222, lng: 22.0843353 },
    { lat: 49.0007232, lng: 22.0847881 },
    { lat: 49.0008161, lng: 22.0860583 },
    { lat: 49.0014159, lng: 22.0854488 },
    { lat: 49.0019328, lng: 22.085948 },
    { lat: 49.0022065, lng: 22.0866402 },
    { lat: 49.0021346, lng: 22.0868824 },
    { lat: 49.0019026, lng: 22.0870591 },
    { lat: 49.0017235, lng: 22.0883377 },
    { lat: 49.0014239, lng: 22.0892664 },
    { lat: 49.0014427, lng: 22.0903053 },
    { lat: 49.0013462, lng: 22.0916554 },
    { lat: 49.0008594, lng: 22.0916689 },
    { lat: 49.0002509, lng: 22.0916047 },
    { lat: 48.9998628, lng: 22.0915637 },
    { lat: 48.9994382, lng: 22.0915945 },
    { lat: 48.9986388, lng: 22.0916525 },
    { lat: 48.9979281, lng: 22.0919007 },
    { lat: 48.9978881, lng: 22.0918977 },
    { lat: 48.996736, lng: 22.0918111 },
    { lat: 48.9950599, lng: 22.0929004 },
    { lat: 48.9946886, lng: 22.0932644 },
    { lat: 48.9946487, lng: 22.0933035 },
    { lat: 48.9941252, lng: 22.0931837 },
    { lat: 48.993408, lng: 22.0940153 },
    { lat: 48.9929845, lng: 22.0943692 },
    { lat: 48.9926089, lng: 22.094952 },
    { lat: 48.9924019, lng: 22.0951408 },
    { lat: 48.9914613, lng: 22.0968491 },
    { lat: 48.9909643, lng: 22.0974316 },
    { lat: 48.9908516, lng: 22.0974841 },
    { lat: 48.9897271, lng: 22.0980082 },
    { lat: 48.9888327, lng: 22.098425 },
    { lat: 48.98813, lng: 22.0990164 },
    { lat: 48.9879135, lng: 22.0995213 },
    { lat: 48.9877508, lng: 22.0997804 },
    { lat: 48.9869877, lng: 22.0998515 },
    { lat: 48.9860783, lng: 22.1001576 },
    { lat: 48.9855501, lng: 22.1011337 },
    { lat: 48.9849097, lng: 22.1017436 },
    { lat: 48.9842244, lng: 22.10206 },
    { lat: 48.9837413, lng: 22.103188 },
    { lat: 48.9840278, lng: 22.1049949 },
    { lat: 48.9825212, lng: 22.1070853 },
    { lat: 48.9822575, lng: 22.1075493 },
    { lat: 48.9821354, lng: 22.1081417 },
    { lat: 48.9818262, lng: 22.1087299 },
    { lat: 48.9817329, lng: 22.1091124 },
    { lat: 48.981359, lng: 22.1096711 },
    { lat: 48.9812009, lng: 22.1106035 },
    { lat: 48.9804727, lng: 22.1124103 },
    { lat: 48.9804834, lng: 22.1145553 },
    { lat: 48.9809862, lng: 22.1170108 },
    { lat: 48.9809737, lng: 22.1174934 },
    { lat: 48.9814683, lng: 22.119115 },
    { lat: 48.9813105, lng: 22.1191522 },
    { lat: 48.9810832, lng: 22.119342 },
    { lat: 48.9807338, lng: 22.1197389 },
    { lat: 48.9804686, lng: 22.1197568 },
    { lat: 48.9804446, lng: 22.1201244 },
    { lat: 48.980007, lng: 22.1202679 },
    { lat: 48.9799991, lng: 22.121084 },
    { lat: 48.9798434, lng: 22.1216456 },
    { lat: 48.9793594, lng: 22.1219623 },
    { lat: 48.9790572, lng: 22.1219796 },
    { lat: 48.9789265, lng: 22.1216795 },
    { lat: 48.9782466, lng: 22.1219484 },
    { lat: 48.9777709, lng: 22.1224827 },
    { lat: 48.9773705, lng: 22.1230188 },
    { lat: 48.9770178, lng: 22.1239071 },
    { lat: 48.9758299, lng: 22.1254442 },
    { lat: 48.9747786, lng: 22.1265765 },
    { lat: 48.9745971, lng: 22.1273728 },
    { lat: 48.9733317, lng: 22.1287754 },
    { lat: 48.972025, lng: 22.1300949 },
    { lat: 48.9707577, lng: 22.1316056 },
    { lat: 48.969788, lng: 22.1333007 },
    { lat: 48.9698066, lng: 22.1341507 },
    { lat: 48.9689058, lng: 22.1358993 },
    { lat: 48.9684687, lng: 22.1361604 },
    { lat: 48.9676027, lng: 22.1372838 },
    { lat: 48.9667285, lng: 22.1380998 },
    { lat: 48.9665844, lng: 22.1384812 },
    { lat: 48.9663475, lng: 22.1386421 },
    { lat: 48.9659376, lng: 22.1393949 },
    { lat: 48.9643142, lng: 22.1405494 },
    { lat: 48.9640044, lng: 22.1417948 },
    { lat: 48.9637793, lng: 22.1422764 },
    { lat: 48.9637532, lng: 22.1425523 },
    { lat: 48.9636285, lng: 22.1429502 },
    { lat: 48.9636302, lng: 22.1432669 },
    { lat: 48.9635735, lng: 22.1435864 },
    { lat: 48.964016, lng: 22.1462872 },
    { lat: 48.9645867, lng: 22.1460159 },
    { lat: 48.9648765, lng: 22.1461963 },
    { lat: 48.9650527, lng: 22.1468044 },
    { lat: 48.9662826, lng: 22.1460505 },
    { lat: 48.9672211, lng: 22.1450003 },
    { lat: 48.9682026, lng: 22.1442586 },
    { lat: 48.9682301, lng: 22.1461723 },
    { lat: 48.9679457, lng: 22.1507646 },
    { lat: 48.9693939, lng: 22.1528463 },
    { lat: 48.9704308, lng: 22.1547949 },
    { lat: 48.9704528, lng: 22.1549226 },
    { lat: 48.9709363, lng: 22.1549593 },
    { lat: 48.9713997, lng: 22.155593 },
    { lat: 48.9720693, lng: 22.1568857 },
    { lat: 48.971742, lng: 22.1572264 },
    { lat: 48.9712586, lng: 22.1575785 },
    { lat: 48.970814, lng: 22.157809 },
    { lat: 48.970429, lng: 22.1572616 },
    { lat: 48.9703885, lng: 22.1568614 },
    { lat: 48.9700539, lng: 22.1569894 },
    { lat: 48.9699746, lng: 22.1567673 },
    { lat: 48.9694053, lng: 22.157298 },
    { lat: 48.9692776, lng: 22.1577598 },
    { lat: 48.9693243, lng: 22.1577398 },
    { lat: 48.9691773, lng: 22.1579491 },
    { lat: 48.9688079, lng: 22.1576365 },
    { lat: 48.9686292, lng: 22.1576359 },
    { lat: 48.9684938, lng: 22.1579188 },
    { lat: 48.9681444, lng: 22.157788 },
    { lat: 48.9679288, lng: 22.1580493 },
    { lat: 48.9674774, lng: 22.1579837 },
    { lat: 48.9662129, lng: 22.1584012 },
    { lat: 48.9665257, lng: 22.1587551 },
    { lat: 48.966121, lng: 22.1588156 },
    { lat: 48.9655807, lng: 22.1592294 },
    { lat: 48.9651093, lng: 22.1598132 },
    { lat: 48.9633184, lng: 22.1631847 },
    { lat: 48.9632957, lng: 22.1633681 },
    { lat: 48.9629314, lng: 22.1639683 },
    { lat: 48.9623282, lng: 22.1646824 },
    { lat: 48.9618957, lng: 22.1659369 },
    { lat: 48.9611742, lng: 22.1662002 },
    { lat: 48.9608377, lng: 22.1664939 },
    { lat: 48.959841, lng: 22.16678 },
    { lat: 48.959705, lng: 22.1667299 },
    { lat: 48.9584059, lng: 22.1670791 },
    { lat: 48.9583369, lng: 22.1678956 },
    { lat: 48.9583739, lng: 22.1687175 },
    { lat: 48.9582991, lng: 22.1695275 },
    { lat: 48.95833, lng: 22.1696533 },
    { lat: 48.960138, lng: 22.1696345 },
    { lat: 48.959651, lng: 22.1744527 },
    { lat: 48.9599916, lng: 22.1771989 },
    { lat: 48.9591882, lng: 22.1776764 },
    { lat: 48.9602407, lng: 22.1787503 },
    { lat: 48.9611705, lng: 22.1804144 },
    { lat: 48.9616017, lng: 22.18137 },
    { lat: 48.9612217, lng: 22.1817221 },
    { lat: 48.9609407, lng: 22.1820783 },
    { lat: 48.9606842, lng: 22.182813 },
    { lat: 48.9593718, lng: 22.1839027 },
    { lat: 48.9597608, lng: 22.1845942 },
    { lat: 48.96016, lng: 22.1858216 },
    { lat: 48.9602012, lng: 22.186846 },
    { lat: 48.9601001, lng: 22.1881315 },
    { lat: 48.9583923, lng: 22.1870332 },
    { lat: 48.9557472, lng: 22.1869925 },
    { lat: 48.954817, lng: 22.185253 },
    { lat: 48.9504005, lng: 22.1840823 },
    { lat: 48.9496593, lng: 22.1849242 },
    { lat: 48.9486567, lng: 22.185443 },
    { lat: 48.944631, lng: 22.184989 },
    { lat: 48.9421508, lng: 22.1842404 },
    { lat: 48.929273, lng: 22.1918562 },
    { lat: 48.929653, lng: 22.193968 },
    { lat: 48.929778, lng: 22.195293 },
    { lat: 48.929788, lng: 22.196145 },
    { lat: 48.92973, lng: 22.197152 },
    { lat: 48.929873, lng: 22.198215 },
    { lat: 48.93013, lng: 22.199606 },
    { lat: 48.9302, lng: 22.200446 },
    { lat: 48.93007, lng: 22.2015 },
    { lat: 48.929966, lng: 22.202197 },
    { lat: 48.929935, lng: 22.202769 },
    { lat: 48.929541, lng: 22.205856 },
    { lat: 48.929266, lng: 22.206725 },
    { lat: 48.929154, lng: 22.207751 },
    { lat: 48.929021, lng: 22.207714 },
    { lat: 48.9292, lng: 22.209569 },
    { lat: 48.929312, lng: 22.210864 },
    { lat: 48.92965, lng: 22.211791 },
    { lat: 48.929705, lng: 22.213554 },
    { lat: 48.9305684, lng: 22.2130427 },
    { lat: 48.9320585, lng: 22.2127837 },
    { lat: 48.9328277, lng: 22.2123821 },
    { lat: 48.9334763, lng: 22.2117235 },
    { lat: 48.9351062, lng: 22.2105789 },
    { lat: 48.9359249, lng: 22.2105772 },
    { lat: 48.936979, lng: 22.210238 },
    { lat: 48.9381024, lng: 22.2110297 },
    { lat: 48.9389657, lng: 22.211758 },
    { lat: 48.9395025, lng: 22.2120696 },
    { lat: 48.9400994, lng: 22.2127574 },
    { lat: 48.9412039, lng: 22.2131065 },
    { lat: 48.9424678, lng: 22.2133439 },
    { lat: 48.9450542, lng: 22.2135711 },
    { lat: 48.9484383, lng: 22.2141655 },
    { lat: 48.9502332, lng: 22.2146009 },
    { lat: 48.9522259, lng: 22.2152426 },
    { lat: 48.9541568, lng: 22.2155844 },
    { lat: 48.9590003, lng: 22.2166002 },
    { lat: 48.9599461, lng: 22.2169481 },
    { lat: 48.96029, lng: 22.2169921 },
    { lat: 48.9613803, lng: 22.2168025 },
    { lat: 48.9629755, lng: 22.2152766 },
    { lat: 48.9647283, lng: 22.2145439 },
    { lat: 48.9664857, lng: 22.2144698 },
    { lat: 48.9678029, lng: 22.2149825 },
    { lat: 48.9688141, lng: 22.2150803 },
    { lat: 48.9701612, lng: 22.2149736 },
    { lat: 48.9720726, lng: 22.2150498 },
    { lat: 48.9733962, lng: 22.2140829 },
    { lat: 48.9737184, lng: 22.2140135 },
    { lat: 48.9741529, lng: 22.2136933 },
    { lat: 48.9742273, lng: 22.2136384 },
    { lat: 48.9762807, lng: 22.2128701 },
    { lat: 48.9771693, lng: 22.2128716 },
    { lat: 48.9772085, lng: 22.2112652 },
    { lat: 48.9787489, lng: 22.2101571 },
    { lat: 48.9788299, lng: 22.2100989 },
    { lat: 48.9785326, lng: 22.2097402 },
    { lat: 48.9791995, lng: 22.208282 },
    { lat: 48.9818863, lng: 22.2061295 },
    { lat: 48.9814918, lng: 22.2049537 },
    { lat: 48.9832476, lng: 22.2006716 },
    { lat: 48.9836986, lng: 22.2009174 },
    { lat: 48.9846345, lng: 22.2010329 },
    { lat: 48.9844834, lng: 22.2002605 },
    { lat: 48.9859016, lng: 22.2005596 },
    { lat: 48.9863974, lng: 22.2008034 },
    { lat: 48.9864569, lng: 22.2006033 },
    { lat: 48.9875135, lng: 22.1996769 },
    { lat: 48.988763, lng: 22.1980757 },
    { lat: 48.9895746, lng: 22.1986561 },
    { lat: 48.9911011, lng: 22.1984689 },
    { lat: 48.992138, lng: 22.1985175 },
    { lat: 48.9943, lng: 22.1987287 },
    { lat: 48.9964131, lng: 22.1993211 },
    { lat: 48.9984014, lng: 22.1992122 },
    { lat: 48.9995393, lng: 22.199598 },
    { lat: 49.0001056, lng: 22.2001168 },
    { lat: 49.0023031, lng: 22.2001085 },
    { lat: 49.0030959, lng: 22.2004532 },
    { lat: 49.0034789, lng: 22.2003806 },
    { lat: 49.0055026, lng: 22.1996562 },
    { lat: 49.0070147, lng: 22.1989241 },
    { lat: 49.0081488, lng: 22.1975512 },
    { lat: 49.0096849, lng: 22.1956007 },
    { lat: 49.0100001, lng: 22.1951181 },
    { lat: 49.0104648, lng: 22.1945811 },
    { lat: 49.0119273, lng: 22.1946479 },
    { lat: 49.0130023, lng: 22.1937991 },
    { lat: 49.0140319, lng: 22.1928287 },
    { lat: 49.0143968, lng: 22.1919564 },
    { lat: 49.0149031, lng: 22.1914716 },
    { lat: 49.0168312, lng: 22.1913885 },
    { lat: 49.0173044, lng: 22.1916672 },
    { lat: 49.0176183, lng: 22.1913759 },
    { lat: 49.0183262, lng: 22.1911009 },
    { lat: 49.0187031, lng: 22.1907092 },
    { lat: 49.0192994, lng: 22.1906181 },
    { lat: 49.0202069, lng: 22.1909113 },
    { lat: 49.0209483, lng: 22.1905689 },
    { lat: 49.0214186, lng: 22.1901798 },
    { lat: 49.0215821, lng: 22.1895126 },
    { lat: 49.0221111, lng: 22.1884897 },
    { lat: 49.0228359, lng: 22.1884603 },
    { lat: 49.0239497, lng: 22.188271 },
    { lat: 49.0245886, lng: 22.1882317 },
    { lat: 49.0257285, lng: 22.1885987 },
    { lat: 49.0266231, lng: 22.1884762 },
    { lat: 49.0274939, lng: 22.1880377 },
    { lat: 49.0279658, lng: 22.1872707 },
    { lat: 49.0286547, lng: 22.1868062 },
    { lat: 49.0294622, lng: 22.1864548 },
    { lat: 49.0301279, lng: 22.1863066 },
    { lat: 49.0313295, lng: 22.1868704 },
  ],
  Michajlov: [
    { lat: 48.9073791, lng: 22.3138905 },
    { lat: 48.9053806, lng: 22.3165374 },
    { lat: 48.9054154, lng: 22.3175203 },
    { lat: 48.905738, lng: 22.3188145 },
    { lat: 48.9046326, lng: 22.318768 },
    { lat: 48.9038675, lng: 22.3185372 },
    { lat: 48.9035582, lng: 22.3186921 },
    { lat: 48.9028378, lng: 22.3197532 },
    { lat: 48.9027243, lng: 22.3204863 },
    { lat: 48.9028954, lng: 22.3206512 },
    { lat: 48.9026738, lng: 22.3220007 },
    { lat: 48.9027961, lng: 22.3224018 },
    { lat: 48.9014054, lng: 22.3246612 },
    { lat: 48.9010277, lng: 22.3248865 },
    { lat: 48.9001317, lng: 22.3277154 },
    { lat: 48.8999264, lng: 22.3294081 },
    { lat: 48.8999631, lng: 22.3308516 },
    { lat: 48.9004318, lng: 22.3323576 },
    { lat: 48.9007147, lng: 22.3347387 },
    { lat: 48.9004408, lng: 22.3348162 },
    { lat: 48.9003149, lng: 22.3360193 },
    { lat: 48.8999737, lng: 22.3368855 },
    { lat: 48.8997773, lng: 22.3378471 },
    { lat: 48.8999109, lng: 22.3382541 },
    { lat: 48.9001428, lng: 22.338431 },
    { lat: 48.9004435, lng: 22.3386603 },
    { lat: 48.900466, lng: 22.3388648 },
    { lat: 48.900322, lng: 22.3389727 },
    { lat: 48.9004064, lng: 22.3392817 },
    { lat: 48.9006841, lng: 22.3393816 },
    { lat: 48.9004644, lng: 22.3397885 },
    { lat: 48.9005979, lng: 22.340241 },
    { lat: 48.9006245, lng: 22.3406468 },
    { lat: 48.9008467, lng: 22.3407785 },
    { lat: 48.9008755, lng: 22.341438 },
    { lat: 48.8999657, lng: 22.3430761 },
    { lat: 48.8996246, lng: 22.3442029 },
    { lat: 48.8988121, lng: 22.3457049 },
    { lat: 48.8988082, lng: 22.3459507 },
    { lat: 48.8989979, lng: 22.3468199 },
    { lat: 48.8991089, lng: 22.3470197 },
    { lat: 48.8991816, lng: 22.3473113 },
    { lat: 48.8990375, lng: 22.3478922 },
    { lat: 48.8991026, lng: 22.3495719 },
    { lat: 48.8982209, lng: 22.3510479 },
    { lat: 48.8991222, lng: 22.351005 },
    { lat: 48.899331, lng: 22.3509948 },
    { lat: 48.8997186, lng: 22.35055 },
    { lat: 48.9004727, lng: 22.3504132 },
    { lat: 48.9011864, lng: 22.350983 },
    { lat: 48.9012949, lng: 22.3512186 },
    { lat: 48.9018279, lng: 22.3518959 },
    { lat: 48.9021751, lng: 22.3521416 },
    { lat: 48.9027225, lng: 22.3513887 },
    { lat: 48.9032125, lng: 22.3511619 },
    { lat: 48.9049146, lng: 22.351197 },
    { lat: 48.9059648, lng: 22.3509798 },
    { lat: 48.906368, lng: 22.3507909 },
    { lat: 48.9067681, lng: 22.3504656 },
    { lat: 48.9069972, lng: 22.3501358 },
    { lat: 48.9076007, lng: 22.3501665 },
    { lat: 48.9077973, lng: 22.3502573 },
    { lat: 48.9079666, lng: 22.3504918 },
    { lat: 48.9086404, lng: 22.3506599 },
    { lat: 48.9090419, lng: 22.3508744 },
    { lat: 48.9095022, lng: 22.3508904 },
    { lat: 48.9095464, lng: 22.350742 },
    { lat: 48.9101621, lng: 22.3506138 },
    { lat: 48.9102893, lng: 22.3508782 },
    { lat: 48.9110711, lng: 22.3507822 },
    { lat: 48.9140035, lng: 22.3510117 },
    { lat: 48.9143288, lng: 22.3511334 },
    { lat: 48.9145789, lng: 22.3509952 },
    { lat: 48.914854, lng: 22.350499 },
    { lat: 48.9149921, lng: 22.3505437 },
    { lat: 48.9152122, lng: 22.3492628 },
    { lat: 48.9155767, lng: 22.3487396 },
    { lat: 48.9164682, lng: 22.3482977 },
    { lat: 48.9167091, lng: 22.347948 },
    { lat: 48.9171442, lng: 22.3477857 },
    { lat: 48.9174969, lng: 22.3478152 },
    { lat: 48.9177976, lng: 22.3476251 },
    { lat: 48.9177531, lng: 22.347067 },
    { lat: 48.9177065, lng: 22.3465164 },
    { lat: 48.9181229, lng: 22.3452179 },
    { lat: 48.9191839, lng: 22.3429245 },
    { lat: 48.9195354, lng: 22.3418984 },
    { lat: 48.9205515, lng: 22.3396143 },
    { lat: 48.9207777, lng: 22.3373165 },
    { lat: 48.9216781, lng: 22.3333779 },
    { lat: 48.9206459, lng: 22.332281 },
    { lat: 48.9198378, lng: 22.3310555 },
    { lat: 48.9190876, lng: 22.3295449 },
    { lat: 48.9185469, lng: 22.3288161 },
    { lat: 48.9176431, lng: 22.3283689 },
    { lat: 48.9167656, lng: 22.3273175 },
    { lat: 48.9165712, lng: 22.3277852 },
    { lat: 48.9160645, lng: 22.3265077 },
    { lat: 48.9154238, lng: 22.3261086 },
    { lat: 48.9150471, lng: 22.3256989 },
    { lat: 48.9137534, lng: 22.3259441 },
    { lat: 48.9132074, lng: 22.3254455 },
    { lat: 48.9129005, lng: 22.3249291 },
    { lat: 48.9128905, lng: 22.3241072 },
    { lat: 48.9129897, lng: 22.3232763 },
    { lat: 48.9124546, lng: 22.3193894 },
    { lat: 48.9120419, lng: 22.3177838 },
    { lat: 48.9110939, lng: 22.3161805 },
    { lat: 48.9104697, lng: 22.3166943 },
    { lat: 48.9095565, lng: 22.315763 },
    { lat: 48.9091257, lng: 22.3158596 },
    { lat: 48.9086311, lng: 22.3151992 },
    { lat: 48.9090514, lng: 22.3148239 },
    { lat: 48.9089669, lng: 22.3144495 },
    { lat: 48.9091389, lng: 22.313826 },
    { lat: 48.9087263, lng: 22.3131565 },
    { lat: 48.9077697, lng: 22.3132883 },
    { lat: 48.9073791, lng: 22.3138905 },
  ],
  BelánadCirochou: [
    { lat: 49.0020645, lng: 22.0750569 },
    { lat: 49.0020811, lng: 22.0750012 },
    { lat: 49.0016218, lng: 22.0740795 },
    { lat: 49.0018069, lng: 22.0727805 },
    { lat: 49.0017126, lng: 22.0724998 },
    { lat: 49.001613, lng: 22.0723838 },
    { lat: 49.0015765, lng: 22.0721676 },
    { lat: 49.001312, lng: 22.0715892 },
    { lat: 49.0009423, lng: 22.0711742 },
    { lat: 49.0005991, lng: 22.0709351 },
    { lat: 48.9998374, lng: 22.0695092 },
    { lat: 48.9995439, lng: 22.0694175 },
    { lat: 48.9991819, lng: 22.0694663 },
    { lat: 48.9987503, lng: 22.0692972 },
    { lat: 48.9983938, lng: 22.0690127 },
    { lat: 48.9980158, lng: 22.0681518 },
    { lat: 48.9974972, lng: 22.0675846 },
    { lat: 48.9968788, lng: 22.0675974 },
    { lat: 48.9964452, lng: 22.0678074 },
    { lat: 48.9948364, lng: 22.0672645 },
    { lat: 48.9941199, lng: 22.0672673 },
    { lat: 48.9933247, lng: 22.0668882 },
    { lat: 48.9925533, lng: 22.0669022 },
    { lat: 48.9922071, lng: 22.0670538 },
    { lat: 48.9909738, lng: 22.0679283 },
    { lat: 48.990284, lng: 22.0687652 },
    { lat: 48.9899236, lng: 22.068983 },
    { lat: 48.9882954, lng: 22.0706617 },
    { lat: 48.9878426, lng: 22.0712985 },
    { lat: 48.9874603, lng: 22.0721113 },
    { lat: 48.9869433, lng: 22.0727953 },
    { lat: 48.9866424, lng: 22.0730894 },
    { lat: 48.9861783, lng: 22.073543 },
    { lat: 48.9853802, lng: 22.0737095 },
    { lat: 48.9842719, lng: 22.0743052 },
    { lat: 48.9824442, lng: 22.0756921 },
    { lat: 48.9819905, lng: 22.0757614 },
    { lat: 48.9817439, lng: 22.0758987 },
    { lat: 48.9780302, lng: 22.0796602 },
    { lat: 48.9775683, lng: 22.0789173 },
    { lat: 48.9772601, lng: 22.0779663 },
    { lat: 48.9770315, lng: 22.0776268 },
    { lat: 48.9768907, lng: 22.0771443 },
    { lat: 48.9764899, lng: 22.0767018 },
    { lat: 48.9754226, lng: 22.0763731 },
    { lat: 48.9732245, lng: 22.0793983 },
    { lat: 48.9741071, lng: 22.0794339 },
    { lat: 48.9746887, lng: 22.0800975 },
    { lat: 48.9752015, lng: 22.0813319 },
    { lat: 48.9752465, lng: 22.0818583 },
    { lat: 48.9752367, lng: 22.0825399 },
    { lat: 48.9751486, lng: 22.0834702 },
    { lat: 48.9748921, lng: 22.0840857 },
    { lat: 48.9746114, lng: 22.0845367 },
    { lat: 48.9743111, lng: 22.0848771 },
    { lat: 48.9740718, lng: 22.0863194 },
    { lat: 48.973791, lng: 22.0870973 },
    { lat: 48.9734401, lng: 22.0872861 },
    { lat: 48.9729274, lng: 22.0869671 },
    { lat: 48.9725731, lng: 22.0865836 },
    { lat: 48.9721546, lng: 22.0860071 },
    { lat: 48.9718538, lng: 22.0853178 },
    { lat: 48.9706023, lng: 22.0878584 },
    { lat: 48.97032, lng: 22.0882371 },
    { lat: 48.9675927, lng: 22.0911158 },
    { lat: 48.9661221, lng: 22.0922418 },
    { lat: 48.9643304, lng: 22.0939657 },
    { lat: 48.9578165, lng: 22.1004053 },
    { lat: 48.9551573, lng: 22.1031312 },
    { lat: 48.9541361, lng: 22.1027029 },
    { lat: 48.9536011, lng: 22.1020504 },
    { lat: 48.9530139, lng: 22.1028617 },
    { lat: 48.9521814, lng: 22.1054426 },
    { lat: 48.9519394, lng: 22.1055742 },
    { lat: 48.9518267, lng: 22.1058544 },
    { lat: 48.9517464, lng: 22.1058365 },
    { lat: 48.9514805, lng: 22.1061576 },
    { lat: 48.9503284, lng: 22.1068601 },
    { lat: 48.9502764, lng: 22.1069938 },
    { lat: 48.9500166, lng: 22.1071331 },
    { lat: 48.9491062, lng: 22.1079832 },
    { lat: 48.9487889, lng: 22.1080697 },
    { lat: 48.9486095, lng: 22.1084301 },
    { lat: 48.9483992, lng: 22.1085817 },
    { lat: 48.9481166, lng: 22.1090042 },
    { lat: 48.9477842, lng: 22.1096938 },
    { lat: 48.947, lng: 22.1102543 },
    { lat: 48.9460313, lng: 22.1114033 },
    { lat: 48.9437165, lng: 22.111643 },
    { lat: 48.9436319, lng: 22.1117778 },
    { lat: 48.9433774, lng: 22.1118581 },
    { lat: 48.9429471, lng: 22.1124065 },
    { lat: 48.9428484, lng: 22.1124186 },
    { lat: 48.9426488, lng: 22.1127321 },
    { lat: 48.9424389, lng: 22.1127982 },
    { lat: 48.9422071, lng: 22.1129938 },
    { lat: 48.9420785, lng: 22.113262 },
    { lat: 48.9418494, lng: 22.1133294 },
    { lat: 48.9417595, lng: 22.1135175 },
    { lat: 48.941649, lng: 22.1135105 },
    { lat: 48.941075, lng: 22.1140223 },
    { lat: 48.9408955, lng: 22.1142871 },
    { lat: 48.94026, lng: 22.1141421 },
    { lat: 48.9396328, lng: 22.1143522 },
    { lat: 48.9385738, lng: 22.1145667 },
    { lat: 48.9379566, lng: 22.1150618 },
    { lat: 48.9377182, lng: 22.1157344 },
    { lat: 48.9374566, lng: 22.1162408 },
    { lat: 48.9365695, lng: 22.1168752 },
    { lat: 48.9363753, lng: 22.1169386 },
    { lat: 48.9347588, lng: 22.1170353 },
    { lat: 48.934502, lng: 22.116962 },
    { lat: 48.9345573, lng: 22.1173573 },
    { lat: 48.9349372, lng: 22.1174972 },
    { lat: 48.9356963, lng: 22.1177798 },
    { lat: 48.9363792, lng: 22.1180242 },
    { lat: 48.9367842, lng: 22.118167 },
    { lat: 48.937199, lng: 22.1183111 },
    { lat: 48.9374543, lng: 22.1184 },
    { lat: 48.9379227, lng: 22.118568 },
    { lat: 48.9383858, lng: 22.1187456 },
    { lat: 48.9388211, lng: 22.1188979 },
    { lat: 48.939158, lng: 22.11901 },
    { lat: 48.9395726, lng: 22.1191441 },
    { lat: 48.9399763, lng: 22.1192827 },
    { lat: 48.9402531, lng: 22.1193798 },
    { lat: 48.9407474, lng: 22.1195528 },
    { lat: 48.9409325, lng: 22.1196266 },
    { lat: 48.9410209, lng: 22.1196577 },
    { lat: 48.9413319, lng: 22.1197737 },
    { lat: 48.9413589, lng: 22.1197797 },
    { lat: 48.9410635, lng: 22.1207033 },
    { lat: 48.9404317, lng: 22.1226511 },
    { lat: 48.9403883, lng: 22.1240757 },
    { lat: 48.9403539, lng: 22.1248142 },
    { lat: 48.9400554, lng: 22.1276986 },
    { lat: 48.9397111, lng: 22.129433 },
    { lat: 48.9396852, lng: 22.1295349 },
    { lat: 48.9396852, lng: 22.1295352 },
    { lat: 48.9393428, lng: 22.1308673 },
    { lat: 48.9393112, lng: 22.1310061 },
    { lat: 48.9393041, lng: 22.1310369 },
    { lat: 48.9393362, lng: 22.1321747 },
    { lat: 48.9399648, lng: 22.1331926 },
    { lat: 48.9402827, lng: 22.1337051 },
    { lat: 48.9405041, lng: 22.1342289 },
    { lat: 48.940573, lng: 22.1347297 },
    { lat: 48.9405954, lng: 22.1347317 },
    { lat: 48.9406542, lng: 22.1347364 },
    { lat: 48.9407194, lng: 22.1347415 },
    { lat: 48.9408393, lng: 22.1347705 },
    { lat: 48.9420071, lng: 22.1355604 },
    { lat: 48.9435738, lng: 22.1356513 },
    { lat: 48.9438032, lng: 22.1356429 },
    { lat: 48.9442935, lng: 22.1354439 },
    { lat: 48.9450997, lng: 22.1350953 },
    { lat: 48.9456196, lng: 22.1348707 },
    { lat: 48.9457578, lng: 22.1348107 },
    { lat: 48.9460217, lng: 22.1352231 },
    { lat: 48.9463956, lng: 22.1357395 },
    { lat: 48.9468535, lng: 22.1363749 },
    { lat: 48.9473586, lng: 22.1370578 },
    { lat: 48.9475989, lng: 22.1373792 },
    { lat: 48.9480221, lng: 22.1380236 },
    { lat: 48.9485229, lng: 22.1389577 },
    { lat: 48.9488254, lng: 22.1395331 },
    { lat: 48.9494172, lng: 22.1397348 },
    { lat: 48.9496993, lng: 22.1403177 },
    { lat: 48.9500641, lng: 22.1410584 },
    { lat: 48.9504122, lng: 22.1417594 },
    { lat: 48.9507955, lng: 22.1421899 },
    { lat: 48.9511328, lng: 22.1421791 },
    { lat: 48.9518873, lng: 22.141543 },
    { lat: 48.9523395, lng: 22.1411997 },
    { lat: 48.9533819, lng: 22.1407615 },
    { lat: 48.9534466, lng: 22.1407341 },
    { lat: 48.9538083, lng: 22.1417827 },
    { lat: 48.9539712, lng: 22.1423137 },
    { lat: 48.9540361, lng: 22.1425421 },
    { lat: 48.954414, lng: 22.1436024 },
    { lat: 48.9545102, lng: 22.1438725 },
    { lat: 48.9548557, lng: 22.1436437 },
    { lat: 48.9553553, lng: 22.1437452 },
    { lat: 48.9556252, lng: 22.1432569 },
    { lat: 48.9561417, lng: 22.1430286 },
    { lat: 48.956502, lng: 22.1421165 },
    { lat: 48.956623, lng: 22.1419991 },
    { lat: 48.956754, lng: 22.1419248 },
    { lat: 48.9568804, lng: 22.1420927 },
    { lat: 48.9577532, lng: 22.1416024 },
    { lat: 48.9581854, lng: 22.1409376 },
    { lat: 48.9583956, lng: 22.1407317 },
    { lat: 48.9585616, lng: 22.1403273 },
    { lat: 48.9589211, lng: 22.1401199 },
    { lat: 48.9598089, lng: 22.1390596 },
    { lat: 48.9601807, lng: 22.1379223 },
    { lat: 48.9604468, lng: 22.1373744 },
    { lat: 48.9607797, lng: 22.1370735 },
    { lat: 48.9608362, lng: 22.1372347 },
    { lat: 48.9613614, lng: 22.1379591 },
    { lat: 48.9608309, lng: 22.1388053 },
    { lat: 48.9624383, lng: 22.1410439 },
    { lat: 48.962888, lng: 22.1403513 },
    { lat: 48.963845, lng: 22.1416594 },
    { lat: 48.9640044, lng: 22.1417948 },
    { lat: 48.9643142, lng: 22.1405494 },
    { lat: 48.9659376, lng: 22.1393949 },
    { lat: 48.9663475, lng: 22.1386421 },
    { lat: 48.9665844, lng: 22.1384812 },
    { lat: 48.9667285, lng: 22.1380998 },
    { lat: 48.9676027, lng: 22.1372838 },
    { lat: 48.9684687, lng: 22.1361604 },
    { lat: 48.9689058, lng: 22.1358993 },
    { lat: 48.9698066, lng: 22.1341507 },
    { lat: 48.969788, lng: 22.1333007 },
    { lat: 48.9707577, lng: 22.1316056 },
    { lat: 48.972025, lng: 22.1300949 },
    { lat: 48.9733317, lng: 22.1287754 },
    { lat: 48.9745971, lng: 22.1273728 },
    { lat: 48.9747786, lng: 22.1265765 },
    { lat: 48.9758299, lng: 22.1254442 },
    { lat: 48.9770178, lng: 22.1239071 },
    { lat: 48.9773705, lng: 22.1230188 },
    { lat: 48.9777709, lng: 22.1224827 },
    { lat: 48.9782466, lng: 22.1219484 },
    { lat: 48.9789265, lng: 22.1216795 },
    { lat: 48.9790572, lng: 22.1219796 },
    { lat: 48.9793594, lng: 22.1219623 },
    { lat: 48.9798434, lng: 22.1216456 },
    { lat: 48.9799991, lng: 22.121084 },
    { lat: 48.980007, lng: 22.1202679 },
    { lat: 48.9804446, lng: 22.1201244 },
    { lat: 48.9804686, lng: 22.1197568 },
    { lat: 48.9807338, lng: 22.1197389 },
    { lat: 48.9810832, lng: 22.119342 },
    { lat: 48.9813105, lng: 22.1191522 },
    { lat: 48.9814683, lng: 22.119115 },
    { lat: 48.9809737, lng: 22.1174934 },
    { lat: 48.9809862, lng: 22.1170108 },
    { lat: 48.9804834, lng: 22.1145553 },
    { lat: 48.9804727, lng: 22.1124103 },
    { lat: 48.9812009, lng: 22.1106035 },
    { lat: 48.981359, lng: 22.1096711 },
    { lat: 48.9817329, lng: 22.1091124 },
    { lat: 48.9818262, lng: 22.1087299 },
    { lat: 48.9821354, lng: 22.1081417 },
    { lat: 48.9822575, lng: 22.1075493 },
    { lat: 48.9825212, lng: 22.1070853 },
    { lat: 48.9840278, lng: 22.1049949 },
    { lat: 48.9837413, lng: 22.103188 },
    { lat: 48.9842244, lng: 22.10206 },
    { lat: 48.9849097, lng: 22.1017436 },
    { lat: 48.9855501, lng: 22.1011337 },
    { lat: 48.9860783, lng: 22.1001576 },
    { lat: 48.9869877, lng: 22.0998515 },
    { lat: 48.9877508, lng: 22.0997804 },
    { lat: 48.9879135, lng: 22.0995213 },
    { lat: 48.98813, lng: 22.0990164 },
    { lat: 48.9888327, lng: 22.098425 },
    { lat: 48.9897271, lng: 22.0980082 },
    { lat: 48.9908516, lng: 22.0974841 },
    { lat: 48.9909643, lng: 22.0974316 },
    { lat: 48.9914613, lng: 22.0968491 },
    { lat: 48.9924019, lng: 22.0951408 },
    { lat: 48.9926089, lng: 22.094952 },
    { lat: 48.9929845, lng: 22.0943692 },
    { lat: 48.993408, lng: 22.0940153 },
    { lat: 48.9941252, lng: 22.0931837 },
    { lat: 48.9946487, lng: 22.0933035 },
    { lat: 48.9946886, lng: 22.0932644 },
    { lat: 48.9950599, lng: 22.0929004 },
    { lat: 48.996736, lng: 22.0918111 },
    { lat: 48.9978881, lng: 22.0918977 },
    { lat: 48.9979281, lng: 22.0919007 },
    { lat: 48.9986388, lng: 22.0916525 },
    { lat: 48.9994382, lng: 22.0915945 },
    { lat: 48.9998628, lng: 22.0915637 },
    { lat: 49.0002509, lng: 22.0916047 },
    { lat: 49.0008594, lng: 22.0916689 },
    { lat: 49.0013462, lng: 22.0916554 },
    { lat: 49.0014427, lng: 22.0903053 },
    { lat: 49.0014239, lng: 22.0892664 },
    { lat: 49.0017235, lng: 22.0883377 },
    { lat: 49.0019026, lng: 22.0870591 },
    { lat: 49.0021346, lng: 22.0868824 },
    { lat: 49.0022065, lng: 22.0866402 },
    { lat: 49.0019328, lng: 22.085948 },
    { lat: 49.0014159, lng: 22.0854488 },
    { lat: 49.0008161, lng: 22.0860583 },
    { lat: 49.0007232, lng: 22.0847881 },
    { lat: 49.0003222, lng: 22.0843353 },
    { lat: 48.9998006, lng: 22.0841061 },
    { lat: 48.9996344, lng: 22.0837359 },
    { lat: 48.999659, lng: 22.082969 },
    { lat: 48.9993043, lng: 22.082331 },
    { lat: 48.9994, lng: 22.0816258 },
    { lat: 49.0003282, lng: 22.0789251 },
    { lat: 49.0002883, lng: 22.078425 },
    { lat: 49.0004134, lng: 22.0779622 },
    { lat: 49.0005109, lng: 22.0765662 },
    { lat: 49.0015723, lng: 22.0760801 },
    { lat: 49.0019484, lng: 22.0752392 },
    { lat: 49.0020645, lng: 22.0750569 },
  ],
  Topoľa: [
    { lat: 49.0843641, lng: 22.3626318 },
    { lat: 49.0843666, lng: 22.3621176 },
    { lat: 49.0839951, lng: 22.3617785 },
    { lat: 49.083501, lng: 22.3617048 },
    { lat: 49.0822345, lng: 22.3625447 },
    { lat: 49.0818841, lng: 22.3629343 },
    { lat: 49.0812089, lng: 22.3641897 },
    { lat: 49.0806963, lng: 22.3646235 },
    { lat: 49.0805859, lng: 22.3643056 },
    { lat: 49.0804225, lng: 22.3633795 },
    { lat: 49.0804883, lng: 22.362879 },
    { lat: 49.0808457, lng: 22.3619051 },
    { lat: 49.0810045, lng: 22.361225 },
    { lat: 49.0807916, lng: 22.3594112 },
    { lat: 49.080605, lng: 22.3583553 },
    { lat: 49.0808695, lng: 22.3570364 },
    { lat: 49.0809635, lng: 22.3561149 },
    { lat: 49.0809371, lng: 22.3551371 },
    { lat: 49.0807774, lng: 22.3540766 },
    { lat: 49.0807867, lng: 22.3531951 },
    { lat: 49.0810515, lng: 22.3515311 },
    { lat: 49.0811878, lng: 22.3513245 },
    { lat: 49.0802908, lng: 22.3504999 },
    { lat: 49.0800911, lng: 22.3499974 },
    { lat: 49.08026, lng: 22.3497957 },
    { lat: 49.0800305, lng: 22.3485773 },
    { lat: 49.0790401, lng: 22.3476393 },
    { lat: 49.0782565, lng: 22.3460135 },
    { lat: 49.0781994, lng: 22.3453921 },
    { lat: 49.0774278, lng: 22.3442263 },
    { lat: 49.0766676, lng: 22.3439084 },
    { lat: 49.0761002, lng: 22.343779 },
    { lat: 49.0753411, lng: 22.3440446 },
    { lat: 49.0749279, lng: 22.3439743 },
    { lat: 49.0746815, lng: 22.3437587 },
    { lat: 49.07404, lng: 22.343832 },
    { lat: 49.0739435, lng: 22.343528 },
    { lat: 49.0732415, lng: 22.3431979 },
    { lat: 49.0727467, lng: 22.3427833 },
    { lat: 49.0713738, lng: 22.3425858 },
    { lat: 49.0709341, lng: 22.3422151 },
    { lat: 49.0705146, lng: 22.3420551 },
    { lat: 49.0700874, lng: 22.3415314 },
    { lat: 49.0697646, lng: 22.3400539 },
    { lat: 49.0693607, lng: 22.3393178 },
    { lat: 49.068705, lng: 22.3384181 },
    { lat: 49.0683181, lng: 22.3372677 },
    { lat: 49.0677041, lng: 22.3360052 },
    { lat: 49.0671132, lng: 22.3350309 },
    { lat: 49.0668423, lng: 22.3346757 },
    { lat: 49.0657736, lng: 22.3335559 },
    { lat: 49.0648617, lng: 22.3327785 },
    { lat: 49.0642871, lng: 22.3320958 },
    { lat: 49.0629537, lng: 22.3307519 },
    { lat: 49.0625495, lng: 22.3306314 },
    { lat: 49.0617246, lng: 22.3302869 },
    { lat: 49.0612082, lng: 22.3298539 },
    { lat: 49.0605984, lng: 22.3287923 },
    { lat: 49.0583658, lng: 22.3286745 },
    { lat: 49.0565676, lng: 22.3287356 },
    { lat: 49.0552668, lng: 22.3291685 },
    { lat: 49.0534447, lng: 22.3300479 },
    { lat: 49.0529417, lng: 22.3301944 },
    { lat: 49.0524342, lng: 22.3302329 },
    { lat: 49.0513008, lng: 22.330504 },
    { lat: 49.0503378, lng: 22.330597 },
    { lat: 49.0496115, lng: 22.3308073 },
    { lat: 49.0488436, lng: 22.3311344 },
    { lat: 49.0479452, lng: 22.3307775 },
    { lat: 49.0470275, lng: 22.3308317 },
    { lat: 49.0465515, lng: 22.3312841 },
    { lat: 49.0461623, lng: 22.3300261 },
    { lat: 49.0458303, lng: 22.329175 },
    { lat: 49.0456706, lng: 22.3291195 },
    { lat: 49.0452187, lng: 22.3291387 },
    { lat: 49.0448974, lng: 22.3283097 },
    { lat: 49.045144, lng: 22.3272288 },
    { lat: 49.0449189, lng: 22.3270647 },
    { lat: 49.0446503, lng: 22.3264388 },
    { lat: 49.0444695, lng: 22.3264578 },
    { lat: 49.0439343, lng: 22.3265139 },
    { lat: 49.0436314, lng: 22.3276933 },
    { lat: 49.0429864, lng: 22.3285065 },
    { lat: 49.0425467, lng: 22.3296049 },
    { lat: 49.0418805, lng: 22.330013 },
    { lat: 49.0411056, lng: 22.3311649 },
    { lat: 49.0403439, lng: 22.3320852 },
    { lat: 49.0396478, lng: 22.3336145 },
    { lat: 49.0392204, lng: 22.3349692 },
    { lat: 49.0377463, lng: 22.3385002 },
    { lat: 49.0370156, lng: 22.33746 },
    { lat: 49.0337807, lng: 22.3342951 },
    { lat: 49.0331737, lng: 22.3338364 },
    { lat: 49.0331562, lng: 22.3335924 },
    { lat: 49.0327134, lng: 22.3332381 },
    { lat: 49.0320445, lng: 22.3324674 },
    { lat: 49.0302753, lng: 22.330632 },
    { lat: 49.0290661, lng: 22.3285762 },
    { lat: 49.0287408, lng: 22.3281375 },
    { lat: 49.0282754, lng: 22.327106 },
    { lat: 49.0273885, lng: 22.3261846 },
    { lat: 49.0269748, lng: 22.325826 },
    { lat: 49.0263493, lng: 22.3255508 },
    { lat: 49.0258587, lng: 22.3254866 },
    { lat: 49.0250186, lng: 22.3255456 },
    { lat: 49.0246162, lng: 22.3255481 },
    { lat: 49.0240335, lng: 22.3259164 },
    { lat: 49.0238082, lng: 22.3255335 },
    { lat: 49.0233814, lng: 22.3246115 },
    { lat: 49.0226155, lng: 22.3246665 },
    { lat: 49.0223022, lng: 22.3249469 },
    { lat: 49.0220267, lng: 22.3258869 },
    { lat: 49.0215794, lng: 22.3263273 },
    { lat: 49.0209289, lng: 22.3267746 },
    { lat: 49.0203592, lng: 22.327468 },
    { lat: 49.019609, lng: 22.3292549 },
    { lat: 49.0189684, lng: 22.330424 },
    { lat: 49.0186293, lng: 22.3309178 },
    { lat: 49.0186194, lng: 22.3314013 },
    { lat: 49.0182426, lng: 22.3326013 },
    { lat: 49.0178795, lng: 22.333973 },
    { lat: 49.0174564, lng: 22.3347532 },
    { lat: 49.0177835, lng: 22.3348925 },
    { lat: 49.0183286, lng: 22.3347752 },
    { lat: 49.0182485, lng: 22.3352897 },
    { lat: 49.0180982, lng: 22.335208 },
    { lat: 49.017403, lng: 22.3350858 },
    { lat: 49.016286, lng: 22.3368019 },
    { lat: 49.0157155, lng: 22.3387305 },
    { lat: 49.0154751, lng: 22.3392676 },
    { lat: 49.0146744, lng: 22.3401667 },
    { lat: 49.0139053, lng: 22.3406454 },
    { lat: 49.0129747, lng: 22.3417781 },
    { lat: 49.0132997, lng: 22.3423098 },
    { lat: 49.0149106, lng: 22.3436379 },
    { lat: 49.0152103, lng: 22.3437231 },
    { lat: 49.0155042, lng: 22.3438927 },
    { lat: 49.0160261, lng: 22.3438408 },
    { lat: 49.0165167, lng: 22.3439606 },
    { lat: 49.0180931, lng: 22.3458943 },
    { lat: 49.0182649, lng: 22.346889 },
    { lat: 49.0184657, lng: 22.347599 },
    { lat: 49.0188483, lng: 22.3480312 },
    { lat: 49.0194814, lng: 22.3484493 },
    { lat: 49.0200348, lng: 22.3486145 },
    { lat: 49.0205127, lng: 22.3492445 },
    { lat: 49.0210164, lng: 22.350553 },
    { lat: 49.0212049, lng: 22.3512266 },
    { lat: 49.0211487, lng: 22.3517551 },
    { lat: 49.0213333, lng: 22.3523437 },
    { lat: 49.0213814, lng: 22.3527537 },
    { lat: 49.0215711, lng: 22.3534195 },
    { lat: 49.0217627, lng: 22.3537634 },
    { lat: 49.0219163, lng: 22.3538368 },
    { lat: 49.0220621, lng: 22.3542453 },
    { lat: 49.0221232, lng: 22.3546325 },
    { lat: 49.0223294, lng: 22.3549205 },
    { lat: 49.0226967, lng: 22.3541539 },
    { lat: 49.0232437, lng: 22.3533365 },
    { lat: 49.0236682, lng: 22.3528849 },
    { lat: 49.0240837, lng: 22.3525842 },
    { lat: 49.0241155, lng: 22.3526543 },
    { lat: 49.0245748, lng: 22.3546356 },
    { lat: 49.0243713, lng: 22.355316 },
    { lat: 49.0243238, lng: 22.3557839 },
    { lat: 49.0240565, lng: 22.3564947 },
    { lat: 49.0245803, lng: 22.3580618 },
    { lat: 49.0250717, lng: 22.3584867 },
    { lat: 49.0252691, lng: 22.3589948 },
    { lat: 49.0251983, lng: 22.3594097 },
    { lat: 49.024911, lng: 22.3600301 },
    { lat: 49.0249372, lng: 22.3613092 },
    { lat: 49.0250322, lng: 22.3617697 },
    { lat: 49.0247062, lng: 22.3624665 },
    { lat: 49.0246107, lng: 22.3627855 },
    { lat: 49.024536, lng: 22.3633983 },
    { lat: 49.0245585, lng: 22.3639236 },
    { lat: 49.0247264, lng: 22.3649532 },
    { lat: 49.0245912, lng: 22.365679 },
    { lat: 49.0248677, lng: 22.3677525 },
    { lat: 49.0252296, lng: 22.3686414 },
    { lat: 49.0253136, lng: 22.369215 },
    { lat: 49.0256876, lng: 22.370676 },
    { lat: 49.0258003, lng: 22.371437 },
    { lat: 49.026027, lng: 22.3720494 },
    { lat: 49.026114, lng: 22.3727669 },
    { lat: 49.0263139, lng: 22.3734377 },
    { lat: 49.0272122, lng: 22.3752491 },
    { lat: 49.027889, lng: 22.3762983 },
    { lat: 49.0281891, lng: 22.3765041 },
    { lat: 49.0285952, lng: 22.3772548 },
    { lat: 49.0289981, lng: 22.3777534 },
    { lat: 49.0291291, lng: 22.3782573 },
    { lat: 49.0296482, lng: 22.3791377 },
    { lat: 49.0299515, lng: 22.3804149 },
    { lat: 49.0301358, lng: 22.3808317 },
    { lat: 49.030516, lng: 22.3813697 },
    { lat: 49.0314279, lng: 22.3838764 },
    { lat: 49.0319465, lng: 22.3848486 },
    { lat: 49.0322062, lng: 22.3855926 },
    { lat: 49.0323714, lng: 22.3859124 },
    { lat: 49.0325443, lng: 22.3862472 },
    { lat: 49.0327512, lng: 22.3866478 },
    { lat: 49.0334229, lng: 22.3879484 },
    { lat: 49.03362, lng: 22.3886055 },
    { lat: 49.0337956, lng: 22.3888306 },
    { lat: 49.0348117, lng: 22.3909429 },
    { lat: 49.0352603, lng: 22.3916918 },
    { lat: 49.0353433, lng: 22.392539 },
    { lat: 49.0355055, lng: 22.3928211 },
    { lat: 49.036117, lng: 22.3947478 },
    { lat: 49.036416, lng: 22.3951183 },
    { lat: 49.0367094, lng: 22.3952725 },
    { lat: 49.0375682, lng: 22.39487 },
    { lat: 49.0380762, lng: 22.3948576 },
    { lat: 49.0389355, lng: 22.3945935 },
    { lat: 49.0398404, lng: 22.3944199 },
    { lat: 49.0400618, lng: 22.3944035 },
    { lat: 49.0403412, lng: 22.3945893 },
    { lat: 49.04071, lng: 22.3945915 },
    { lat: 49.0412527, lng: 22.3953251 },
    { lat: 49.0413669, lng: 22.3956211 },
    { lat: 49.0422129, lng: 22.3970341 },
    { lat: 49.0428552, lng: 22.3972522 },
    { lat: 49.0430338, lng: 22.3974084 },
    { lat: 49.0433283, lng: 22.3975096 },
    { lat: 49.0440113, lng: 22.3980624 },
    { lat: 49.0449637, lng: 22.3990273 },
    { lat: 49.0456803, lng: 22.3989374 },
    { lat: 49.0462041, lng: 22.398222 },
    { lat: 49.0473104, lng: 22.3961663 },
    { lat: 49.0476081, lng: 22.395175 },
    { lat: 49.0477156, lng: 22.3940312 },
    { lat: 49.0479451, lng: 22.3937595 },
    { lat: 49.0481858, lng: 22.3930656 },
    { lat: 49.0482862, lng: 22.393345 },
    { lat: 49.0485181, lng: 22.393527 },
    { lat: 49.048781, lng: 22.3931378 },
    { lat: 49.0490882, lng: 22.3931473 },
    { lat: 49.0499168, lng: 22.3935643 },
    { lat: 49.0501574, lng: 22.3939937 },
    { lat: 49.0505437, lng: 22.3942883 },
    { lat: 49.0511976, lng: 22.3944642 },
    { lat: 49.0518867, lng: 22.3946145 },
    { lat: 49.0530469, lng: 22.394166 },
    { lat: 49.0535918, lng: 22.3935513 },
    { lat: 49.0540375, lng: 22.3932508 },
    { lat: 49.0550343, lng: 22.3922451 },
    { lat: 49.0558376, lng: 22.3918412 },
    { lat: 49.0564183, lng: 22.3917528 },
    { lat: 49.0567255, lng: 22.3915446 },
    { lat: 49.0573089, lng: 22.3906737 },
    { lat: 49.0583176, lng: 22.388904 },
    { lat: 49.0587382, lng: 22.3878777 },
    { lat: 49.0593276, lng: 22.3867554 },
    { lat: 49.0601476, lng: 22.385638 },
    { lat: 49.0605701, lng: 22.3857846 },
    { lat: 49.061745, lng: 22.385238 },
    { lat: 49.062267, lng: 22.3845991 },
    { lat: 49.0635127, lng: 22.3834598 },
    { lat: 49.064775, lng: 22.3830609 },
    { lat: 49.0652442, lng: 22.3824786 },
    { lat: 49.0658209, lng: 22.3819869 },
    { lat: 49.0666846, lng: 22.3818846 },
    { lat: 49.0677826, lng: 22.3812064 },
    { lat: 49.0680613, lng: 22.3812487 },
    { lat: 49.0689284, lng: 22.3817908 },
    { lat: 49.0694118, lng: 22.3819423 },
    { lat: 49.0705024, lng: 22.3818734 },
    { lat: 49.0712993, lng: 22.3815344 },
    { lat: 49.0718324, lng: 22.3811641 },
    { lat: 49.072016, lng: 22.3809088 },
    { lat: 49.072707, lng: 22.381203 },
    { lat: 49.0730927, lng: 22.3815246 },
    { lat: 49.0732029, lng: 22.380983 },
    { lat: 49.0737278, lng: 22.38113 },
    { lat: 49.0748284, lng: 22.3802054 },
    { lat: 49.0749279, lng: 22.3798595 },
    { lat: 49.0753974, lng: 22.3797745 },
    { lat: 49.075846, lng: 22.3795041 },
    { lat: 49.0762557, lng: 22.3791505 },
    { lat: 49.0762565, lng: 22.3790214 },
    { lat: 49.0763355, lng: 22.3788765 },
    { lat: 49.0765206, lng: 22.3787495 },
    { lat: 49.0767395, lng: 22.3784099 },
    { lat: 49.0770959, lng: 22.3780779 },
    { lat: 49.0777973, lng: 22.3776348 },
    { lat: 49.0783755, lng: 22.3775431 },
    { lat: 49.0794126, lng: 22.3772249 },
    { lat: 49.0801493, lng: 22.3771821 },
    { lat: 49.0807202, lng: 22.3772684 },
    { lat: 49.0810978, lng: 22.3769927 },
    { lat: 49.0816431, lng: 22.3764535 },
    { lat: 49.0821982, lng: 22.3764856 },
    { lat: 49.0828596, lng: 22.3763909 },
    { lat: 49.0836424, lng: 22.3764471 },
    { lat: 49.0841356, lng: 22.3765758 },
    { lat: 49.085111, lng: 22.3766007 },
    { lat: 49.085582, lng: 22.3762713 },
    { lat: 49.0861099, lng: 22.3750659 },
    { lat: 49.0868422, lng: 22.374591 },
    { lat: 49.0871619, lng: 22.3741829 },
    { lat: 49.0873028, lng: 22.3729216 },
    { lat: 49.0879129, lng: 22.3720917 },
    { lat: 49.0881615, lng: 22.3716117 },
    { lat: 49.0882874, lng: 22.3707078 },
    { lat: 49.0882397, lng: 22.3699351 },
    { lat: 49.0881101, lng: 22.3689089 },
    { lat: 49.0877229, lng: 22.368517 },
    { lat: 49.0866942, lng: 22.3678993 },
    { lat: 49.0864344, lng: 22.3673613 },
    { lat: 49.0865535, lng: 22.3665633 },
    { lat: 49.0865529, lng: 22.3658977 },
    { lat: 49.0868559, lng: 22.3652816 },
    { lat: 49.0874132, lng: 22.3647236 },
    { lat: 49.0872009, lng: 22.3640462 },
    { lat: 49.0868516, lng: 22.3634871 },
    { lat: 49.0862901, lng: 22.3630418 },
    { lat: 49.0857012, lng: 22.3628443 },
    { lat: 49.0843641, lng: 22.3626318 },
  ],
  Pčoliné: [
    { lat: 49.072962, lng: 22.133537 },
    { lat: 49.0723206, lng: 22.1337843 },
    { lat: 49.0717722, lng: 22.1346309 },
    { lat: 49.0690501, lng: 22.1370965 },
    { lat: 49.0672949, lng: 22.1380047 },
    { lat: 49.0660516, lng: 22.1381738 },
    { lat: 49.065259, lng: 22.1378991 },
    { lat: 49.0642255, lng: 22.1376496 },
    { lat: 49.0633753, lng: 22.1376054 },
    { lat: 49.0617873, lng: 22.1386362 },
    { lat: 49.0588907, lng: 22.1414392 },
    { lat: 49.0575343, lng: 22.1419277 },
    { lat: 49.0555802, lng: 22.1433662 },
    { lat: 49.054976, lng: 22.1440869 },
    { lat: 49.0540791, lng: 22.144511 },
    { lat: 49.0529136, lng: 22.145181 },
    { lat: 49.0504173, lng: 22.1463182 },
    { lat: 49.0495096, lng: 22.1461719 },
    { lat: 49.0478097, lng: 22.1467745 },
    { lat: 49.0470662, lng: 22.1473931 },
    { lat: 49.0454962, lng: 22.1490918 },
    { lat: 49.0440554, lng: 22.1510845 },
    { lat: 49.0421739, lng: 22.152514 },
    { lat: 49.0416661, lng: 22.1533186 },
    { lat: 49.0405839, lng: 22.1556085 },
    { lat: 49.0394327, lng: 22.1573489 },
    { lat: 49.0382204, lng: 22.1586814 },
    { lat: 49.0381307, lng: 22.1587799 },
    { lat: 49.0377067, lng: 22.1590784 },
    { lat: 49.0368329, lng: 22.1599679 },
    { lat: 49.0351645, lng: 22.1623025 },
    { lat: 49.0344011, lng: 22.1647707 },
    { lat: 49.0339763, lng: 22.1658653 },
    { lat: 49.0335664, lng: 22.1662365 },
    { lat: 49.0331935, lng: 22.1669423 },
    { lat: 49.0332005, lng: 22.1680452 },
    { lat: 49.0327823, lng: 22.1706047 },
    { lat: 49.0326932, lng: 22.1708951 },
    { lat: 49.0325191, lng: 22.1724156 },
    { lat: 49.032471, lng: 22.1728356 },
    { lat: 49.0321759, lng: 22.1743451 },
    { lat: 49.0320117, lng: 22.1761667 },
    { lat: 49.0320283, lng: 22.1766981 },
    { lat: 49.032284, lng: 22.1778957 },
    { lat: 49.0322964, lng: 22.1783467 },
    { lat: 49.0320962, lng: 22.1800734 },
    { lat: 49.0323286, lng: 22.1834179 },
    { lat: 49.0321652, lng: 22.1842444 },
    { lat: 49.0317691, lng: 22.1852222 },
    { lat: 49.0313295, lng: 22.1868704 },
    { lat: 49.0308033, lng: 22.1876123 },
    { lat: 49.0301393, lng: 22.1877956 },
    { lat: 49.0293407, lng: 22.1887866 },
    { lat: 49.0291852, lng: 22.194726 },
    { lat: 49.0297622, lng: 22.19507 },
    { lat: 49.0304596, lng: 22.1951234 },
    { lat: 49.0317798, lng: 22.1963615 },
    { lat: 49.0323672, lng: 22.1982193 },
    { lat: 49.0324723, lng: 22.1993873 },
    { lat: 49.0347074, lng: 22.2010949 },
    { lat: 49.0381701, lng: 22.2011872 },
    { lat: 49.0383253, lng: 22.2025276 },
    { lat: 49.0410255, lng: 22.204315 },
    { lat: 49.0428011, lng: 22.2063955 },
    { lat: 49.0438096, lng: 22.2082283 },
    { lat: 49.0450318, lng: 22.2100203 },
    { lat: 49.0453672, lng: 22.21047 },
    { lat: 49.0458131, lng: 22.2118461 },
    { lat: 49.0462027, lng: 22.2127677 },
    { lat: 49.0476138, lng: 22.2124605 },
    { lat: 49.0492665, lng: 22.2135237 },
    { lat: 49.050745, lng: 22.2135608 },
    { lat: 49.0521636, lng: 22.2147273 },
    { lat: 49.0526988, lng: 22.2170284 },
    { lat: 49.0537792, lng: 22.2189093 },
    { lat: 49.0544329, lng: 22.219674 },
    { lat: 49.0556906, lng: 22.2186419 },
    { lat: 49.0573472, lng: 22.2192832 },
    { lat: 49.0586706, lng: 22.2159181 },
    { lat: 49.0612735, lng: 22.2146851 },
    { lat: 49.0623597, lng: 22.216476 },
    { lat: 49.0629336, lng: 22.2161442 },
    { lat: 49.064269, lng: 22.2160543 },
    { lat: 49.0649788, lng: 22.2163661 },
    { lat: 49.0652943, lng: 22.2165932 },
    { lat: 49.0663858, lng: 22.2170691 },
    { lat: 49.0674707, lng: 22.2173492 },
    { lat: 49.0749457, lng: 22.2230474 },
    { lat: 49.0753573, lng: 22.2198323 },
    { lat: 49.0773584, lng: 22.2126274 },
    { lat: 49.0787602, lng: 22.2122911 },
    { lat: 49.0794516, lng: 22.2133386 },
    { lat: 49.0803381, lng: 22.2159466 },
    { lat: 49.0807666, lng: 22.2162017 },
    { lat: 49.0828575, lng: 22.2147522 },
    { lat: 49.0838209, lng: 22.2133358 },
    { lat: 49.0849663, lng: 22.2131578 },
    { lat: 49.0868908, lng: 22.211017 },
    { lat: 49.0877888, lng: 22.2103398 },
    { lat: 49.0917932, lng: 22.2095168 },
    { lat: 49.093833, lng: 22.2070962 },
    { lat: 49.0948899, lng: 22.2067812 },
    { lat: 49.0963975, lng: 22.2076608 },
    { lat: 49.0976093, lng: 22.2058365 },
    { lat: 49.0977384, lng: 22.2043288 },
    { lat: 49.0970691, lng: 22.2015785 },
    { lat: 49.0977016, lng: 22.1994039 },
    { lat: 49.0980641, lng: 22.1988214 },
    { lat: 49.0995253, lng: 22.1956375 },
    { lat: 49.0991763, lng: 22.1935842 },
    { lat: 49.0989457, lng: 22.1921435 },
    { lat: 49.0979584, lng: 22.1893205 },
    { lat: 49.0971836, lng: 22.1874419 },
    { lat: 49.0964941, lng: 22.1861445 },
    { lat: 49.0957834, lng: 22.1842708 },
    { lat: 49.0948833, lng: 22.1827621 },
    { lat: 49.0932472, lng: 22.1802494 },
    { lat: 49.0911834, lng: 22.1776302 },
    { lat: 49.087724, lng: 22.1744264 },
    { lat: 49.0872368, lng: 22.1717025 },
    { lat: 49.0872518, lng: 22.1707297 },
    { lat: 49.0870013, lng: 22.1684798 },
    { lat: 49.0870995, lng: 22.1653001 },
    { lat: 49.0879335, lng: 22.1654701 },
    { lat: 49.0881473, lng: 22.1640515 },
    { lat: 49.0880197, lng: 22.1639739 },
    { lat: 49.0883153, lng: 22.1625975 },
    { lat: 49.0890871, lng: 22.1608833 },
    { lat: 49.0890227, lng: 22.1608724 },
    { lat: 49.0889396, lng: 22.1605352 },
    { lat: 49.0891736, lng: 22.1587652 },
    { lat: 49.0878109, lng: 22.1558449 },
    { lat: 49.0867418, lng: 22.1537582 },
    { lat: 49.0861314, lng: 22.1531337 },
    { lat: 49.0845333, lng: 22.1524502 },
    { lat: 49.0844729, lng: 22.1522195 },
    { lat: 49.0847922, lng: 22.1513869 },
    { lat: 49.0855245, lng: 22.1491451 },
    { lat: 49.0859853, lng: 22.1468309 },
    { lat: 49.0859041, lng: 22.1455062 },
    { lat: 49.0857546, lng: 22.1455529 },
    { lat: 49.0853753, lng: 22.1443217 },
    { lat: 49.0855424, lng: 22.1429819 },
    { lat: 49.085528, lng: 22.1411936 },
    { lat: 49.0850873, lng: 22.1390481 },
    { lat: 49.0841418, lng: 22.1380004 },
    { lat: 49.0818307, lng: 22.1362538 },
    { lat: 49.0791873, lng: 22.1361453 },
    { lat: 49.0775969, lng: 22.1363755 },
    { lat: 49.0758594, lng: 22.1357532 },
    { lat: 49.0740911, lng: 22.1358443 },
    { lat: 49.073647, lng: 22.13401 },
    { lat: 49.072962, lng: 22.133537 },
  ],
  Hostovice: [
    { lat: 49.140382, lng: 22.127688 },
    { lat: 49.141017, lng: 22.123754 },
    { lat: 49.141014, lng: 22.123647 },
    { lat: 49.140948, lng: 22.121784 },
    { lat: 49.139985, lng: 22.120895 },
    { lat: 49.13925, lng: 22.119264 },
    { lat: 49.139072, lng: 22.116706 },
    { lat: 49.140004, lng: 22.114633 },
    { lat: 49.139285, lng: 22.113832 },
    { lat: 49.138896, lng: 22.113204 },
    { lat: 49.138432, lng: 22.112295 },
    { lat: 49.136737, lng: 22.110817 },
    { lat: 49.136131, lng: 22.109955 },
    { lat: 49.135887, lng: 22.10989 },
    { lat: 49.135566, lng: 22.109671 },
    { lat: 49.135042, lng: 22.10901 },
    { lat: 49.13479, lng: 22.10846 },
    { lat: 49.13467, lng: 22.107865 },
    { lat: 49.134033, lng: 22.106394 },
    { lat: 49.133976, lng: 22.106466 },
    { lat: 49.1336, lng: 22.107514 },
    { lat: 49.133535, lng: 22.107537 },
    { lat: 49.132702, lng: 22.107203 },
    { lat: 49.132026, lng: 22.106714 },
    { lat: 49.132415, lng: 22.105446 },
    { lat: 49.131529, lng: 22.103034 },
    { lat: 49.130513, lng: 22.103338 },
    { lat: 49.130334, lng: 22.102509 },
    { lat: 49.127964, lng: 22.10457 },
    { lat: 49.127961, lng: 22.104573 },
    { lat: 49.127848, lng: 22.10456 },
    { lat: 49.127825, lng: 22.104557 },
    { lat: 49.127223, lng: 22.104487 },
    { lat: 49.12706, lng: 22.104468 },
    { lat: 49.127004, lng: 22.104887 },
    { lat: 49.126501, lng: 22.10598 },
    { lat: 49.125711, lng: 22.106001 },
    { lat: 49.12527, lng: 22.105948 },
    { lat: 49.124516, lng: 22.106177 },
    { lat: 49.124204, lng: 22.106385 },
    { lat: 49.123698, lng: 22.106548 },
    { lat: 49.123273, lng: 22.106509 },
    { lat: 49.122913, lng: 22.106545 },
    { lat: 49.122449, lng: 22.10672 },
    { lat: 49.121978, lng: 22.107024 },
    { lat: 49.121759, lng: 22.107079 },
    { lat: 49.12117, lng: 22.107165 },
    { lat: 49.120684, lng: 22.107275 },
    { lat: 49.120424, lng: 22.107286 },
    { lat: 49.120177, lng: 22.107197 },
    { lat: 49.119947, lng: 22.107019 },
    { lat: 49.119606, lng: 22.106861 },
    { lat: 49.11927, lng: 22.106822 },
    { lat: 49.119082, lng: 22.106928 },
    { lat: 49.118791, lng: 22.107008 },
    { lat: 49.118468, lng: 22.107234 },
    { lat: 49.118248, lng: 22.107335 },
    { lat: 49.117939, lng: 22.107287 },
    { lat: 49.117366, lng: 22.107249 },
    { lat: 49.117159, lng: 22.107193 },
    { lat: 49.116816, lng: 22.107199 },
    { lat: 49.116611, lng: 22.107209 },
    { lat: 49.116137, lng: 22.106877 },
    { lat: 49.115503, lng: 22.106533 },
    { lat: 49.114738, lng: 22.10625 },
    { lat: 49.11373, lng: 22.105984 },
    { lat: 49.113347, lng: 22.105767 },
    { lat: 49.11267, lng: 22.105609 },
    { lat: 49.1125, lng: 22.10554 },
    { lat: 49.112231, lng: 22.105428 },
    { lat: 49.111842, lng: 22.105377 },
    { lat: 49.111432, lng: 22.105285 },
    { lat: 49.111154, lng: 22.105048 },
    { lat: 49.110855, lng: 22.104831 },
    { lat: 49.10977, lng: 22.105093 },
    { lat: 49.109084, lng: 22.104921 },
    { lat: 49.108284, lng: 22.104409 },
    { lat: 49.108165, lng: 22.104305 },
    { lat: 49.107907, lng: 22.104403 },
    { lat: 49.107426, lng: 22.104417 },
    { lat: 49.107231, lng: 22.104581 },
    { lat: 49.107188, lng: 22.104551 },
    { lat: 49.106887, lng: 22.104148 },
    { lat: 49.106231, lng: 22.104103 },
    { lat: 49.106043, lng: 22.104054 },
    { lat: 49.10583, lng: 22.10395 },
    { lat: 49.105591, lng: 22.103723 },
    { lat: 49.105315, lng: 22.103604 },
    { lat: 49.105204, lng: 22.103736 },
    { lat: 49.104961, lng: 22.103629 },
    { lat: 49.104807, lng: 22.103691 },
    { lat: 49.104612, lng: 22.103535 },
    { lat: 49.104254, lng: 22.103642 },
    { lat: 49.10396, lng: 22.103664 },
    { lat: 49.103602, lng: 22.103648 },
    { lat: 49.10325, lng: 22.10342 },
    { lat: 49.103097, lng: 22.103294 },
    { lat: 49.102789, lng: 22.103225 },
    { lat: 49.102621, lng: 22.103202 },
    { lat: 49.102272, lng: 22.103024 },
    { lat: 49.102133, lng: 22.102979 },
    { lat: 49.101138, lng: 22.103293 },
    { lat: 49.100714, lng: 22.10379 },
    { lat: 49.099565, lng: 22.105136 },
    { lat: 49.098805, lng: 22.105956 },
    { lat: 49.098199, lng: 22.107382 },
    { lat: 49.097998, lng: 22.10764 },
    { lat: 49.097689, lng: 22.108407 },
    { lat: 49.097636, lng: 22.108663 },
    { lat: 49.097607, lng: 22.108952 },
    { lat: 49.09773, lng: 22.111152 },
    { lat: 49.098783, lng: 22.113477 },
    { lat: 49.098645, lng: 22.113949 },
    { lat: 49.098599, lng: 22.114301 },
    { lat: 49.098597, lng: 22.11518 },
    { lat: 49.098625, lng: 22.116193 },
    { lat: 49.09859, lng: 22.116463 },
    { lat: 49.098563, lng: 22.116442 },
    { lat: 49.098185, lng: 22.118364 },
    { lat: 49.09808, lng: 22.118737 },
    { lat: 49.097865, lng: 22.119145 },
    { lat: 49.09776, lng: 22.119377 },
    { lat: 49.097584, lng: 22.119834 },
    { lat: 49.097352, lng: 22.120628 },
    { lat: 49.097287, lng: 22.12088 },
    { lat: 49.097248, lng: 22.121137 },
    { lat: 49.097165, lng: 22.121551 },
    { lat: 49.097113, lng: 22.12204 },
    { lat: 49.097144, lng: 22.122052 },
    { lat: 49.095882, lng: 22.124263 },
    { lat: 49.094963, lng: 22.125328 },
    { lat: 49.093731, lng: 22.12662 },
    { lat: 49.093412, lng: 22.127184 },
    { lat: 49.0942803, lng: 22.1288322 },
    { lat: 49.0956173, lng: 22.1308053 },
    { lat: 49.0959609, lng: 22.1314652 },
    { lat: 49.0963688, lng: 22.1327586 },
    { lat: 49.0967384, lng: 22.1335226 },
    { lat: 49.0977477, lng: 22.1348043 },
    { lat: 49.0981528, lng: 22.1354922 },
    { lat: 49.0988543, lng: 22.1363562 },
    { lat: 49.0990833, lng: 22.1367304 },
    { lat: 49.0989291, lng: 22.1382784 },
    { lat: 49.100481, lng: 22.1424127 },
    { lat: 49.101917, lng: 22.1464532 },
    { lat: 49.1018993, lng: 22.1467093 },
    { lat: 49.103354, lng: 22.150262 },
    { lat: 49.1034883, lng: 22.1508102 },
    { lat: 49.1038343, lng: 22.1528047 },
    { lat: 49.1045523, lng: 22.154741 },
    { lat: 49.1080429, lng: 22.1631146 },
    { lat: 49.1124873, lng: 22.1744311 },
    { lat: 49.1140944, lng: 22.1780368 },
    { lat: 49.115124, lng: 22.1801908 },
    { lat: 49.1155682, lng: 22.1789471 },
    { lat: 49.1167941, lng: 22.1786526 },
    { lat: 49.1174121, lng: 22.1780352 },
    { lat: 49.1195002, lng: 22.1789037 },
    { lat: 49.1201771, lng: 22.1790544 },
    { lat: 49.1210341, lng: 22.1787962 },
    { lat: 49.1216307, lng: 22.1794129 },
    { lat: 49.1219853, lng: 22.1810197 },
    { lat: 49.1222536, lng: 22.1810461 },
    { lat: 49.1226959, lng: 22.1832821 },
    { lat: 49.1229609, lng: 22.1840154 },
    { lat: 49.1228836, lng: 22.1843674 },
    { lat: 49.1232701, lng: 22.1848034 },
    { lat: 49.1236416, lng: 22.18586 },
    { lat: 49.1236893, lng: 22.1872238 },
    { lat: 49.1233567, lng: 22.1892712 },
    { lat: 49.1234039, lng: 22.1897125 },
    { lat: 49.1232006, lng: 22.1899774 },
    { lat: 49.1230648, lng: 22.1902862 },
    { lat: 49.1242964, lng: 22.1907332 },
    { lat: 49.1252958, lng: 22.1900185 },
    { lat: 49.1257831, lng: 22.1885493 },
    { lat: 49.1267402, lng: 22.1895943 },
    { lat: 49.1268277, lng: 22.1913231 },
    { lat: 49.1270753, lng: 22.1914785 },
    { lat: 49.12856, lng: 22.191287 },
    { lat: 49.1287152, lng: 22.1915845 },
    { lat: 49.1288168, lng: 22.1914551 },
    { lat: 49.1289724, lng: 22.1915164 },
    { lat: 49.1290724, lng: 22.1915201 },
    { lat: 49.1291588, lng: 22.191652 },
    { lat: 49.1293173, lng: 22.1917504 },
    { lat: 49.129322, lng: 22.1919078 },
    { lat: 49.1295932, lng: 22.1922171 },
    { lat: 49.1297469, lng: 22.1926447 },
    { lat: 49.1297376, lng: 22.1930255 },
    { lat: 49.1299389, lng: 22.1931743 },
    { lat: 49.1301044, lng: 22.1934389 },
    { lat: 49.1304778, lng: 22.1936391 },
    { lat: 49.1305424, lng: 22.1937564 },
    { lat: 49.1307793, lng: 22.1939361 },
    { lat: 49.1310204, lng: 22.1943505 },
    { lat: 49.1311148, lng: 22.1947003 },
    { lat: 49.1313557, lng: 22.1952056 },
    { lat: 49.1321315, lng: 22.1965415 },
    { lat: 49.1323747, lng: 22.1972492 },
    { lat: 49.1327413, lng: 22.1979446 },
    { lat: 49.1335658, lng: 22.1989017 },
    { lat: 49.1332915, lng: 22.1993492 },
    { lat: 49.1340316, lng: 22.1997644 },
    { lat: 49.1345868, lng: 22.1999903 },
    { lat: 49.1349099, lng: 22.2004518 },
    { lat: 49.1355985, lng: 22.2018186 },
    { lat: 49.1356523, lng: 22.2020109 },
    { lat: 49.1359428, lng: 22.202269 },
    { lat: 49.1361987, lng: 22.2032534 },
    { lat: 49.1362449, lng: 22.2032531 },
    { lat: 49.1363582, lng: 22.2047349 },
    { lat: 49.1362668, lng: 22.2066766 },
    { lat: 49.1364053, lng: 22.2073313 },
    { lat: 49.1366928, lng: 22.2079635 },
    { lat: 49.1370508, lng: 22.2081808 },
    { lat: 49.1377615, lng: 22.2084297 },
    { lat: 49.1377088, lng: 22.2089823 },
    { lat: 49.1377689, lng: 22.2089591 },
    { lat: 49.1383348, lng: 22.2077144 },
    { lat: 49.1385242, lng: 22.2069334 },
    { lat: 49.1386242, lng: 22.2068743 },
    { lat: 49.1388284, lng: 22.2065134 },
    { lat: 49.1391936, lng: 22.2061937 },
    { lat: 49.1396759, lng: 22.2062418 },
    { lat: 49.1399817, lng: 22.2061881 },
    { lat: 49.1401498, lng: 22.2063025 },
    { lat: 49.1403556, lng: 22.2062569 },
    { lat: 49.1405988, lng: 22.2063171 },
    { lat: 49.1409419, lng: 22.2061275 },
    { lat: 49.1436899, lng: 22.2072448 },
    { lat: 49.1450847, lng: 22.2081074 },
    { lat: 49.1456497, lng: 22.2083207 },
    { lat: 49.1457894, lng: 22.2084352 },
    { lat: 49.1461194, lng: 22.2084083 },
    { lat: 49.1467092, lng: 22.2086775 },
    { lat: 49.147062, lng: 22.2087618 },
    { lat: 49.147849, lng: 22.2092596 },
    { lat: 49.1488084, lng: 22.2112648 },
    { lat: 49.1495848, lng: 22.212683 },
    { lat: 49.1497641, lng: 22.2130106 },
    { lat: 49.1501513, lng: 22.213871 },
    { lat: 49.1502737, lng: 22.2147171 },
    { lat: 49.1506523, lng: 22.2161543 },
    { lat: 49.1508186, lng: 22.216349 },
    { lat: 49.1509916, lng: 22.2167387 },
    { lat: 49.1511785, lng: 22.2177644 },
    { lat: 49.1513425, lng: 22.218298 },
    { lat: 49.1514947, lng: 22.2193281 },
    { lat: 49.1514886, lng: 22.2192037 },
    { lat: 49.1516313, lng: 22.2196519 },
    { lat: 49.1522684, lng: 22.2196787 },
    { lat: 49.1531684, lng: 22.2193399 },
    { lat: 49.1548044, lng: 22.2199206 },
    { lat: 49.155122, lng: 22.2202407 },
    { lat: 49.1555808, lng: 22.2205471 },
    { lat: 49.1557743, lng: 22.2211051 },
    { lat: 49.156213, lng: 22.2216069 },
    { lat: 49.1564044, lng: 22.222326 },
    { lat: 49.1567584, lng: 22.2232134 },
    { lat: 49.157715, lng: 22.2240509 },
    { lat: 49.1578647, lng: 22.2243579 },
    { lat: 49.1582495, lng: 22.2248719 },
    { lat: 49.1591971, lng: 22.225559 },
    { lat: 49.1596415, lng: 22.2255676 },
    { lat: 49.1600648, lng: 22.2256861 },
    { lat: 49.1604629, lng: 22.2259849 },
    { lat: 49.1604602, lng: 22.226061 },
    { lat: 49.1607049, lng: 22.2260903 },
    { lat: 49.1607172, lng: 22.2260275 },
    { lat: 49.1610523, lng: 22.2259225 },
    { lat: 49.1611088, lng: 22.2258523 },
    { lat: 49.1614189, lng: 22.2260959 },
    { lat: 49.161853, lng: 22.2262507 },
    { lat: 49.161962, lng: 22.226477 },
    { lat: 49.1623369, lng: 22.2269154 },
    { lat: 49.1624552, lng: 22.2269282 },
    { lat: 49.162771, lng: 22.227335 },
    { lat: 49.1634742, lng: 22.2278195 },
    { lat: 49.1639478, lng: 22.2277629 },
    { lat: 49.163911, lng: 22.228139 },
    { lat: 49.163997, lng: 22.228666 },
    { lat: 49.163956, lng: 22.229367 },
    { lat: 49.164045, lng: 22.229721 },
    { lat: 49.164208, lng: 22.229898 },
    { lat: 49.164657, lng: 22.230097 },
    { lat: 49.164666, lng: 22.230251 },
    { lat: 49.164824, lng: 22.23032 },
    { lat: 49.164921, lng: 22.230522 },
    { lat: 49.165147, lng: 22.230814 },
    { lat: 49.165182, lng: 22.231077 },
    { lat: 49.165269, lng: 22.231208 },
    { lat: 49.165434, lng: 22.231355 },
    { lat: 49.165562, lng: 22.231498 },
    { lat: 49.165631, lng: 22.231812 },
    { lat: 49.1657282, lng: 22.231953 },
    { lat: 49.165826, lng: 22.232095 },
    { lat: 49.1659444, lng: 22.2324167 },
    { lat: 49.166105, lng: 22.232546 },
    { lat: 49.166181, lng: 22.232945 },
    { lat: 49.166745, lng: 22.233766 },
    { lat: 49.166771, lng: 22.233776 },
    { lat: 49.1668173, lng: 22.2331657 },
    { lat: 49.1670219, lng: 22.2330192 },
    { lat: 49.1671271, lng: 22.2324823 },
    { lat: 49.1670853, lng: 22.2321179 },
    { lat: 49.167274, lng: 22.2313537 },
    { lat: 49.1672258, lng: 22.2311061 },
    { lat: 49.1672308, lng: 22.2307799 },
    { lat: 49.1674498, lng: 22.2305163 },
    { lat: 49.167552, lng: 22.2302073 },
    { lat: 49.1676709, lng: 22.2300319 },
    { lat: 49.1676143, lng: 22.2298174 },
    { lat: 49.1676162, lng: 22.2293986 },
    { lat: 49.1676848, lng: 22.2291456 },
    { lat: 49.1677495, lng: 22.2289697 },
    { lat: 49.168305, lng: 22.2282642 },
    { lat: 49.1684413, lng: 22.2279197 },
    { lat: 49.1683841, lng: 22.2275715 },
    { lat: 49.168441, lng: 22.2271952 },
    { lat: 49.1684081, lng: 22.2266819 },
    { lat: 49.1685377, lng: 22.2262381 },
    { lat: 49.1685616, lng: 22.2258002 },
    { lat: 49.1683792, lng: 22.2251326 },
    { lat: 49.1683479, lng: 22.2248406 },
    { lat: 49.1688397, lng: 22.2233516 },
    { lat: 49.1689383, lng: 22.2231729 },
    { lat: 49.1689869, lng: 22.2229339 },
    { lat: 49.1691063, lng: 22.2227922 },
    { lat: 49.1692505, lng: 22.2222007 },
    { lat: 49.1692218, lng: 22.2218758 },
    { lat: 49.1692851, lng: 22.2214822 },
    { lat: 49.1690815, lng: 22.2201731 },
    { lat: 49.1690854, lng: 22.2197905 },
    { lat: 49.1691478, lng: 22.2194203 },
    { lat: 49.1694652, lng: 22.2189225 },
    { lat: 49.1695416, lng: 22.2185653 },
    { lat: 49.1695621, lng: 22.2181778 },
    { lat: 49.1695167, lng: 22.2178104 },
    { lat: 49.1694447, lng: 22.2176068 },
    { lat: 49.1691633, lng: 22.2173439 },
    { lat: 49.1689764, lng: 22.2169303 },
    { lat: 49.1688026, lng: 22.2157458 },
    { lat: 49.1687122, lng: 22.2154342 },
    { lat: 49.1685501, lng: 22.2152252 },
    { lat: 49.168613, lng: 22.2148162 },
    { lat: 49.1685971, lng: 22.2145033 },
    { lat: 49.1687623, lng: 22.2137072 },
    { lat: 49.1687835, lng: 22.2128446 },
    { lat: 49.1687333, lng: 22.2122036 },
    { lat: 49.1685676, lng: 22.2117367 },
    { lat: 49.1684864, lng: 22.2113233 },
    { lat: 49.1683802, lng: 22.2111039 },
    { lat: 49.1682396, lng: 22.2109509 },
    { lat: 49.1680259, lng: 22.2103957 },
    { lat: 49.1678981, lng: 22.210214 },
    { lat: 49.1678346, lng: 22.209698 },
    { lat: 49.167397, lng: 22.2085838 },
    { lat: 49.1670027, lng: 22.2077562 },
    { lat: 49.1665899, lng: 22.2071514 },
    { lat: 49.1664802, lng: 22.2068214 },
    { lat: 49.1664717, lng: 22.206493 },
    { lat: 49.1663027, lng: 22.2061374 },
    { lat: 49.1662019, lng: 22.205554 },
    { lat: 49.1659932, lng: 22.2049677 },
    { lat: 49.1657786, lng: 22.2046281 },
    { lat: 49.1656359, lng: 22.2042892 },
    { lat: 49.1653722, lng: 22.2039109 },
    { lat: 49.1653325, lng: 22.203495 },
    { lat: 49.1652309, lng: 22.203275 },
    { lat: 49.1650367, lng: 22.2032952 },
    { lat: 49.1648733, lng: 22.2032283 },
    { lat: 49.164544, lng: 22.2029447 },
    { lat: 49.1644516, lng: 22.2023365 },
    { lat: 49.1644872, lng: 22.2019713 },
    { lat: 49.1646156, lng: 22.2016224 },
    { lat: 49.1646168, lng: 22.2012238 },
    { lat: 49.1645865, lng: 22.2012305 },
    { lat: 49.164387, lng: 22.2004607 },
    { lat: 49.1642447, lng: 22.2002425 },
    { lat: 49.16428, lng: 22.2001594 },
    { lat: 49.1640635, lng: 22.1998373 },
    { lat: 49.163867, lng: 22.1996521 },
    { lat: 49.163818, lng: 22.1993679 },
    { lat: 49.1636272, lng: 22.1991338 },
    { lat: 49.1635152, lng: 22.1987117 },
    { lat: 49.1633317, lng: 22.1985481 },
    { lat: 49.1633072, lng: 22.1982677 },
    { lat: 49.1634528, lng: 22.1975345 },
    { lat: 49.1632713, lng: 22.1973622 },
    { lat: 49.1631746, lng: 22.1971196 },
    { lat: 49.1629731, lng: 22.1968165 },
    { lat: 49.1625768, lng: 22.1952428 },
    { lat: 49.1625263, lng: 22.1949587 },
    { lat: 49.1625726, lng: 22.1940683 },
    { lat: 49.1626074, lng: 22.1940089 },
    { lat: 49.1625065, lng: 22.1937814 },
    { lat: 49.1624058, lng: 22.1933062 },
    { lat: 49.1621617, lng: 22.1930499 },
    { lat: 49.1619379, lng: 22.1922078 },
    { lat: 49.1617262, lng: 22.1919451 },
    { lat: 49.1615714, lng: 22.1915709 },
    { lat: 49.1616574, lng: 22.1911926 },
    { lat: 49.1617963, lng: 22.1909173 },
    { lat: 49.1619634, lng: 22.1908039 },
    { lat: 49.1619959, lng: 22.1905931 },
    { lat: 49.1618654, lng: 22.1900422 },
    { lat: 49.1618653, lng: 22.1897878 },
    { lat: 49.1620884, lng: 22.1897631 },
    { lat: 49.1621639, lng: 22.1896628 },
    { lat: 49.1621778, lng: 22.189435 },
    { lat: 49.1620625, lng: 22.1891094 },
    { lat: 49.1614317, lng: 22.1887153 },
    { lat: 49.1612875, lng: 22.1884149 },
    { lat: 49.1611525, lng: 22.1883113 },
    { lat: 49.1610623, lng: 22.188098 },
    { lat: 49.1611291, lng: 22.1880208 },
    { lat: 49.1612033, lng: 22.1876223 },
    { lat: 49.1611807, lng: 22.1873719 },
    { lat: 49.1610363, lng: 22.1873425 },
    { lat: 49.1609563, lng: 22.1870916 },
    { lat: 49.160989, lng: 22.1861495 },
    { lat: 49.1610881, lng: 22.1852204 },
    { lat: 49.1608779, lng: 22.1846252 },
    { lat: 49.160751, lng: 22.1846323 },
    { lat: 49.1603387, lng: 22.1837122 },
    { lat: 49.1601878, lng: 22.1841998 },
    { lat: 49.1601048, lng: 22.1841126 },
    { lat: 49.1601063, lng: 22.1838764 },
    { lat: 49.1600269, lng: 22.1838493 },
    { lat: 49.159962, lng: 22.1839185 },
    { lat: 49.1599029, lng: 22.1842813 },
    { lat: 49.1599126, lng: 22.1846075 },
    { lat: 49.1597601, lng: 22.1846273 },
    { lat: 49.1593975, lng: 22.1850061 },
    { lat: 49.1594594, lng: 22.1852084 },
    { lat: 49.159423, lng: 22.1857335 },
    { lat: 49.1593605, lng: 22.1859528 },
    { lat: 49.1592418, lng: 22.1860259 },
    { lat: 49.1592591, lng: 22.1861486 },
    { lat: 49.1590099, lng: 22.186216 },
    { lat: 49.1589705, lng: 22.1862633 },
    { lat: 49.1589998, lng: 22.1864655 },
    { lat: 49.1588395, lng: 22.1865368 },
    { lat: 49.1584851, lng: 22.1869357 },
    { lat: 49.1582857, lng: 22.1870698 },
    { lat: 49.1580658, lng: 22.1870907 },
    { lat: 49.1578927, lng: 22.1872899 },
    { lat: 49.1576952, lng: 22.1873624 },
    { lat: 49.1576824, lng: 22.1878935 },
    { lat: 49.1575405, lng: 22.1882254 },
    { lat: 49.1575765, lng: 22.1885764 },
    { lat: 49.1574597, lng: 22.1887367 },
    { lat: 49.1572356, lng: 22.1886636 },
    { lat: 49.1571188, lng: 22.1888742 },
    { lat: 49.1570795, lng: 22.1891692 },
    { lat: 49.1569254, lng: 22.1896167 },
    { lat: 49.1567934, lng: 22.1897534 },
    { lat: 49.1565279, lng: 22.1905916 },
    { lat: 49.1563243, lng: 22.1910399 },
    { lat: 49.1562017, lng: 22.1911402 },
    { lat: 49.1561583, lng: 22.1912538 },
    { lat: 49.1561035, lng: 22.191574 },
    { lat: 49.1561284, lng: 22.1917247 },
    { lat: 49.1560358, lng: 22.1921517 },
    { lat: 49.1556335, lng: 22.1918179 },
    { lat: 49.1551183, lng: 22.1912751 },
    { lat: 49.1483619, lng: 22.1870704 },
    { lat: 49.147498, lng: 22.1855754 },
    { lat: 49.147335, lng: 22.185381 },
    { lat: 49.1472639, lng: 22.1850711 },
    { lat: 49.1468296, lng: 22.1844578 },
    { lat: 49.1467591, lng: 22.1842297 },
    { lat: 49.1466084, lng: 22.18408 },
    { lat: 49.1462342, lng: 22.1834994 },
    { lat: 49.1461061, lng: 22.183213 },
    { lat: 49.1458403, lng: 22.1829975 },
    { lat: 49.1455843, lng: 22.1826596 },
    { lat: 49.1453519, lng: 22.1825332 },
    { lat: 49.1450812, lng: 22.1824986 },
    { lat: 49.1442817, lng: 22.1827006 },
    { lat: 49.1442794, lng: 22.1827525 },
    { lat: 49.1439051, lng: 22.1829612 },
    { lat: 49.143427, lng: 22.1830065 },
    { lat: 49.1423771, lng: 22.1818829 },
    { lat: 49.1417872, lng: 22.1826514 },
    { lat: 49.1414817, lng: 22.1828266 },
    { lat: 49.1408157, lng: 22.1817665 },
    { lat: 49.1400349, lng: 22.181215 },
    { lat: 49.1394995, lng: 22.1809167 },
    { lat: 49.1388172, lng: 22.1802793 },
    { lat: 49.1377153, lng: 22.1789556 },
    { lat: 49.13748, lng: 22.1787579 },
    { lat: 49.1375426, lng: 22.1759124 },
    { lat: 49.1376635, lng: 22.174308 },
    { lat: 49.1351645, lng: 22.1716936 },
    { lat: 49.1351853, lng: 22.1665898 },
    { lat: 49.1320975, lng: 22.1664669 },
    { lat: 49.1311474, lng: 22.165441 },
    { lat: 49.1321013, lng: 22.1638267 },
    { lat: 49.1294086, lng: 22.1621416 },
    { lat: 49.127984, lng: 22.1600414 },
    { lat: 49.1288617, lng: 22.1585389 },
    { lat: 49.1283518, lng: 22.1576922 },
    { lat: 49.1296337, lng: 22.1558632 },
    { lat: 49.1314005, lng: 22.151986 },
    { lat: 49.1321386, lng: 22.1497505 },
    { lat: 49.1328807, lng: 22.1478311 },
    { lat: 49.1338383, lng: 22.1481373 },
    { lat: 49.1338477, lng: 22.1479644 },
    { lat: 49.1340182, lng: 22.1473652 },
    { lat: 49.1331812, lng: 22.1461281 },
    { lat: 49.132343, lng: 22.1453335 },
    { lat: 49.1324209, lng: 22.1445619 },
    { lat: 49.1331999, lng: 22.1437171 },
    { lat: 49.1348592, lng: 22.1429934 },
    { lat: 49.1356763, lng: 22.1398037 },
    { lat: 49.1365915, lng: 22.1369592 },
    { lat: 49.1366458, lng: 22.1354218 },
    { lat: 49.1365787, lng: 22.1347006 },
    { lat: 49.1374968, lng: 22.1332555 },
    { lat: 49.1386444, lng: 22.1320064 },
    { lat: 49.1396791, lng: 22.1303596 },
    { lat: 49.140269, lng: 22.1289248 },
    { lat: 49.140382, lng: 22.127688 },
  ],
  Ladomirov: [
    { lat: 48.908336, lng: 22.2921946 },
    { lat: 48.9091685, lng: 22.2937931 },
    { lat: 48.9102876, lng: 22.2959032 },
    { lat: 48.9092817, lng: 22.2979509 },
    { lat: 48.9057202, lng: 22.3020409 },
    { lat: 48.9059548, lng: 22.3058438 },
    { lat: 48.9070782, lng: 22.3082678 },
    { lat: 48.907714, lng: 22.3110222 },
    { lat: 48.9075039, lng: 22.3117145 },
    { lat: 48.9073791, lng: 22.3138905 },
    { lat: 48.9077697, lng: 22.3132883 },
    { lat: 48.9087263, lng: 22.3131565 },
    { lat: 48.9091389, lng: 22.313826 },
    { lat: 48.9089669, lng: 22.3144495 },
    { lat: 48.9090514, lng: 22.3148239 },
    { lat: 48.9086311, lng: 22.3151992 },
    { lat: 48.9091257, lng: 22.3158596 },
    { lat: 48.9095565, lng: 22.315763 },
    { lat: 48.9104697, lng: 22.3166943 },
    { lat: 48.9110939, lng: 22.3161805 },
    { lat: 48.9120419, lng: 22.3177838 },
    { lat: 48.9124546, lng: 22.3193894 },
    { lat: 48.9129897, lng: 22.3232763 },
    { lat: 48.9128905, lng: 22.3241072 },
    { lat: 48.9129005, lng: 22.3249291 },
    { lat: 48.9132074, lng: 22.3254455 },
    { lat: 48.9137534, lng: 22.3259441 },
    { lat: 48.9150471, lng: 22.3256989 },
    { lat: 48.9154238, lng: 22.3261086 },
    { lat: 48.9160645, lng: 22.3265077 },
    { lat: 48.9165712, lng: 22.3277852 },
    { lat: 48.9167656, lng: 22.3273175 },
    { lat: 48.9176431, lng: 22.3283689 },
    { lat: 48.9185469, lng: 22.3288161 },
    { lat: 48.9190876, lng: 22.3295449 },
    { lat: 48.9198378, lng: 22.3310555 },
    { lat: 48.9206459, lng: 22.332281 },
    { lat: 48.9216781, lng: 22.3333779 },
    { lat: 48.9218572, lng: 22.3329688 },
    { lat: 48.9223814, lng: 22.3318232 },
    { lat: 48.9244824, lng: 22.3284824 },
    { lat: 48.9249569, lng: 22.3286402 },
    { lat: 48.9253762, lng: 22.3275805 },
    { lat: 48.925227, lng: 22.3272828 },
    { lat: 48.9253485, lng: 22.3268186 },
    { lat: 48.9251423, lng: 22.3261786 },
    { lat: 48.92522, lng: 22.3258125 },
    { lat: 48.9252122, lng: 22.3252961 },
    { lat: 48.9257052, lng: 22.325209 },
    { lat: 48.9260001, lng: 22.3242273 },
    { lat: 48.9265589, lng: 22.3237496 },
    { lat: 48.9265575, lng: 22.3229649 },
    { lat: 48.9267942, lng: 22.3227733 },
    { lat: 48.9272285, lng: 22.3219516 },
    { lat: 48.9275281, lng: 22.3220214 },
    { lat: 48.9276306, lng: 22.3218211 },
    { lat: 48.9275222, lng: 22.3216462 },
    { lat: 48.9277899, lng: 22.3217001 },
    { lat: 48.9279388, lng: 22.3215157 },
    { lat: 48.9278231, lng: 22.3211552 },
    { lat: 48.928169, lng: 22.3207141 },
    { lat: 48.9286091, lng: 22.3207838 },
    { lat: 48.9290456, lng: 22.3200582 },
    { lat: 48.9292155, lng: 22.3201212 },
    { lat: 48.9295756, lng: 22.3195529 },
    { lat: 48.9297004, lng: 22.3198088 },
    { lat: 48.9307639, lng: 22.3181288 },
    { lat: 48.9328893, lng: 22.3130812 },
    { lat: 48.9335158, lng: 22.312043 },
    { lat: 48.933697, lng: 22.3112692 },
    { lat: 48.9340425, lng: 22.3101369 },
    { lat: 48.9350182, lng: 22.3079454 },
    { lat: 48.9353707, lng: 22.3069752 },
    { lat: 48.9360066, lng: 22.3056548 },
    { lat: 48.9362568, lng: 22.3047952 },
    { lat: 48.9364118, lng: 22.3039142 },
    { lat: 48.9366731, lng: 22.3037262 },
    { lat: 48.9369377, lng: 22.3032135 },
    { lat: 48.9371898, lng: 22.3025072 },
    { lat: 48.9377478, lng: 22.3015302 },
    { lat: 48.9386364, lng: 22.2992093 },
    { lat: 48.9387923, lng: 22.2985189 },
    { lat: 48.9395273, lng: 22.2972105 },
    { lat: 48.9399345, lng: 22.2956099 },
    { lat: 48.9402595, lng: 22.2950344 },
    { lat: 48.940387, lng: 22.2944956 },
    { lat: 48.9405915, lng: 22.2940199 },
    { lat: 48.9407652, lng: 22.2933163 },
    { lat: 48.9407776, lng: 22.29271 },
    { lat: 48.940724, lng: 22.2920566 },
    { lat: 48.9410246, lng: 22.2913503 },
    { lat: 48.9393503, lng: 22.29044 },
    { lat: 48.9401814, lng: 22.2897685 },
    { lat: 48.9401654, lng: 22.2890362 },
    { lat: 48.9398497, lng: 22.2873088 },
    { lat: 48.9391415, lng: 22.2857246 },
    { lat: 48.9391093, lng: 22.284257 },
    { lat: 48.9392039, lng: 22.2823657 },
    { lat: 48.9394464, lng: 22.2818308 },
    { lat: 48.9389913, lng: 22.2817425 },
    { lat: 48.9385375, lng: 22.2813605 },
    { lat: 48.9374288, lng: 22.2778381 },
    { lat: 48.936748, lng: 22.2761915 },
    { lat: 48.9366237, lng: 22.2760572 },
    { lat: 48.9356392, lng: 22.2742523 },
    { lat: 48.9349041, lng: 22.2722297 },
    { lat: 48.9342765, lng: 22.2690643 },
    { lat: 48.9338325, lng: 22.2678459 },
    { lat: 48.9338328, lng: 22.2672517 },
    { lat: 48.932619, lng: 22.2636175 },
    { lat: 48.9323836, lng: 22.2636553 },
    { lat: 48.9316148, lng: 22.2633743 },
    { lat: 48.9313045, lng: 22.262567 },
    { lat: 48.9301076, lng: 22.2605165 },
    { lat: 48.9295273, lng: 22.2592068 },
    { lat: 48.9289483, lng: 22.258291 },
    { lat: 48.9279582, lng: 22.2572159 },
    { lat: 48.9274604, lng: 22.2568764 },
    { lat: 48.9261954, lng: 22.2563791 },
    { lat: 48.9259649, lng: 22.2561365 },
    { lat: 48.9250645, lng: 22.2535702 },
    { lat: 48.9244025, lng: 22.2528268 },
    { lat: 48.9237097, lng: 22.2518644 },
    { lat: 48.9226424, lng: 22.2515631 },
    { lat: 48.9222836, lng: 22.2513157 },
    { lat: 48.9213412, lng: 22.2511167 },
    { lat: 48.9210494, lng: 22.250637 },
    { lat: 48.9206469, lng: 22.2501312 },
    { lat: 48.9199151, lng: 22.2493894 },
    { lat: 48.9190272, lng: 22.2474422 },
    { lat: 48.9181495, lng: 22.2453021 },
    { lat: 48.9178305, lng: 22.2436044 },
    { lat: 48.9177105, lng: 22.2413491 },
    { lat: 48.9175672, lng: 22.2403648 },
    { lat: 48.9172288, lng: 22.2388691 },
    { lat: 48.9174483, lng: 22.2369075 },
    { lat: 48.917656, lng: 22.2355801 },
    { lat: 48.9175026, lng: 22.2348513 },
    { lat: 48.9155685, lng: 22.2351779 },
    { lat: 48.9157617, lng: 22.2359218 },
    { lat: 48.9157241, lng: 22.2368034 },
    { lat: 48.9157963, lng: 22.2375692 },
    { lat: 48.9160326, lng: 22.238283 },
    { lat: 48.9160543, lng: 22.2391079 },
    { lat: 48.9161607, lng: 22.2398394 },
    { lat: 48.9165689, lng: 22.2412446 },
    { lat: 48.9168854, lng: 22.2429366 },
    { lat: 48.9168009, lng: 22.2438417 },
    { lat: 48.9168925, lng: 22.2444614 },
    { lat: 48.9168259, lng: 22.2454629 },
    { lat: 48.9170613, lng: 22.2462841 },
    { lat: 48.9167874, lng: 22.2476319 },
    { lat: 48.9166237, lng: 22.2487135 },
    { lat: 48.916166, lng: 22.2498293 },
    { lat: 48.915777, lng: 22.2499404 },
    { lat: 48.915552, lng: 22.2505362 },
    { lat: 48.9151858, lng: 22.2512201 },
    { lat: 48.9145583, lng: 22.2517324 },
    { lat: 48.9141252, lng: 22.2523331 },
    { lat: 48.9132329, lng: 22.2533316 },
    { lat: 48.9123878, lng: 22.2552899 },
    { lat: 48.9122233, lng: 22.2558851 },
    { lat: 48.911991, lng: 22.2563639 },
    { lat: 48.911892, lng: 22.2580348 },
    { lat: 48.9117521, lng: 22.2589884 },
    { lat: 48.911639, lng: 22.2608303 },
    { lat: 48.9112921, lng: 22.2632057 },
    { lat: 48.9113082, lng: 22.2641385 },
    { lat: 48.9109697, lng: 22.2680073 },
    { lat: 48.9109161, lng: 22.2692435 },
    { lat: 48.9107353, lng: 22.2715096 },
    { lat: 48.9107523, lng: 22.2722807 },
    { lat: 48.9106126, lng: 22.2731831 },
    { lat: 48.9106173, lng: 22.2741143 },
    { lat: 48.9105432, lng: 22.2750391 },
    { lat: 48.9107766, lng: 22.2773682 },
    { lat: 48.9098654, lng: 22.2803794 },
    { lat: 48.9098515, lng: 22.2822036 },
    { lat: 48.9093287, lng: 22.2849834 },
    { lat: 48.9091226, lng: 22.2868148 },
    { lat: 48.9094183, lng: 22.2877363 },
    { lat: 48.9089247, lng: 22.2903079 },
    { lat: 48.908336, lng: 22.2921946 },
  ],
  RuskáVolová: [
    { lat: 48.9812507, lng: 22.365467 },
    { lat: 48.9813415, lng: 22.3647125 },
    { lat: 48.9811406, lng: 22.3637277 },
    { lat: 48.9810841, lng: 22.3631318 },
    { lat: 48.9805914, lng: 22.362649 },
    { lat: 48.9800755, lng: 22.3620255 },
    { lat: 48.9793042, lng: 22.3608134 },
    { lat: 48.9789297, lng: 22.3604491 },
    { lat: 48.9782261, lng: 22.3600443 },
    { lat: 48.9771095, lng: 22.3589874 },
    { lat: 48.9769697, lng: 22.358763 },
    { lat: 48.9768313, lng: 22.3583405 },
    { lat: 48.9766067, lng: 22.3581965 },
    { lat: 48.9757631, lng: 22.3566033 },
    { lat: 48.9749564, lng: 22.3556272 },
    { lat: 48.9746086, lng: 22.3548761 },
    { lat: 48.9735492, lng: 22.3532445 },
    { lat: 48.9735177, lng: 22.3530284 },
    { lat: 48.9736152, lng: 22.3524265 },
    { lat: 48.9736347, lng: 22.3518191 },
    { lat: 48.9734868, lng: 22.3514795 },
    { lat: 48.9732384, lng: 22.3511713 },
    { lat: 48.9727896, lng: 22.3509009 },
    { lat: 48.9723232, lng: 22.3504914 },
    { lat: 48.9716752, lng: 22.3500619 },
    { lat: 48.9707519, lng: 22.3489486 },
    { lat: 48.9703616, lng: 22.3487362 },
    { lat: 48.9700996, lng: 22.3484316 },
    { lat: 48.9696101, lng: 22.3484077 },
    { lat: 48.9688258, lng: 22.3479649 },
    { lat: 48.9683676, lng: 22.3478436 },
    { lat: 48.9679508, lng: 22.3479157 },
    { lat: 48.9678464, lng: 22.3478682 },
    { lat: 48.9676103, lng: 22.3474098 },
    { lat: 48.9667752, lng: 22.3478526 },
    { lat: 48.9659092, lng: 22.3478487 },
    { lat: 48.9657546, lng: 22.3475045 },
    { lat: 48.9655774, lng: 22.3475279 },
    { lat: 48.9653576, lng: 22.3471887 },
    { lat: 48.9637329, lng: 22.3474212 },
    { lat: 48.9633519, lng: 22.3460119 },
    { lat: 48.962854, lng: 22.3456087 },
    { lat: 48.9624293, lng: 22.3454174 },
    { lat: 48.9618575, lng: 22.345393 },
    { lat: 48.9610011, lng: 22.3458915 },
    { lat: 48.9606899, lng: 22.3457533 },
    { lat: 48.9606412, lng: 22.3459415 },
    { lat: 48.960858, lng: 22.346196 },
    { lat: 48.9601764, lng: 22.34646 },
    { lat: 48.960007, lng: 22.3466813 },
    { lat: 48.9600462, lng: 22.3469374 },
    { lat: 48.9592321, lng: 22.3474206 },
    { lat: 48.95899, lng: 22.3468331 },
    { lat: 48.9577004, lng: 22.3481123 },
    { lat: 48.9562645, lng: 22.3489624 },
    { lat: 48.9560518, lng: 22.348857 },
    { lat: 48.9559922, lng: 22.3491118 },
    { lat: 48.9556359, lng: 22.3493319 },
    { lat: 48.9552222, lng: 22.3495044 },
    { lat: 48.9548043, lng: 22.3493686 },
    { lat: 48.9546404, lng: 22.3493763 },
    { lat: 48.9541725, lng: 22.3496348 },
    { lat: 48.9535471, lng: 22.3497929 },
    { lat: 48.9532608, lng: 22.3496614 },
    { lat: 48.9526935, lng: 22.3500028 },
    { lat: 48.9517531, lng: 22.3499964 },
    { lat: 48.9510848, lng: 22.3503563 },
    { lat: 48.9507546, lng: 22.3511147 },
    { lat: 48.9504805, lng: 22.3513912 },
    { lat: 48.9500853, lng: 22.351158 },
    { lat: 48.9496141, lng: 22.3499968 },
    { lat: 48.949402, lng: 22.3496541 },
    { lat: 48.9486593, lng: 22.3490537 },
    { lat: 48.9484596, lng: 22.3490256 },
    { lat: 48.9480057, lng: 22.3491471 },
    { lat: 48.947837, lng: 22.3496209 },
    { lat: 48.9474255, lng: 22.3500544 },
    { lat: 48.9469653, lng: 22.3501987 },
    { lat: 48.9455722, lng: 22.3515316 },
    { lat: 48.9453624, lng: 22.3520811 },
    { lat: 48.9450586, lng: 22.351763 },
    { lat: 48.9448058, lng: 22.3517743 },
    { lat: 48.9447847, lng: 22.3519342 },
    { lat: 48.944219, lng: 22.3520432 },
    { lat: 48.9433741, lng: 22.3519313 },
    { lat: 48.9434186, lng: 22.3517935 },
    { lat: 48.9430886, lng: 22.3518262 },
    { lat: 48.942999, lng: 22.3524288 },
    { lat: 48.942722, lng: 22.3523619 },
    { lat: 48.9420116, lng: 22.353118 },
    { lat: 48.9409811, lng: 22.354624 },
    { lat: 48.940268, lng: 22.3559419 },
    { lat: 48.9401457, lng: 22.356374 },
    { lat: 48.9398, lng: 22.3564636 },
    { lat: 48.9397989, lng: 22.3567809 },
    { lat: 48.9395681, lng: 22.3568305 },
    { lat: 48.939482, lng: 22.3571142 },
    { lat: 48.9390936, lng: 22.3573554 },
    { lat: 48.938555, lng: 22.3582622 },
    { lat: 48.9382237, lng: 22.3581292 },
    { lat: 48.9378181, lng: 22.3591486 },
    { lat: 48.9374098, lng: 22.3592523 },
    { lat: 48.9374602, lng: 22.359662 },
    { lat: 48.9371341, lng: 22.3598743 },
    { lat: 48.9369448, lng: 22.3595409 },
    { lat: 48.9364482, lng: 22.3600464 },
    { lat: 48.9359834, lng: 22.3602906 },
    { lat: 48.9358212, lng: 22.3602302 },
    { lat: 48.935235, lng: 22.3603283 },
    { lat: 48.934905, lng: 22.3600361 },
    { lat: 48.9346076, lng: 22.3592176 },
    { lat: 48.9343021, lng: 22.3588112 },
    { lat: 48.933325, lng: 22.3601443 },
    { lat: 48.932584, lng: 22.3602611 },
    { lat: 48.931576, lng: 22.3613037 },
    { lat: 48.9314502, lng: 22.3619473 },
    { lat: 48.9318253, lng: 22.3624976 },
    { lat: 48.9315707, lng: 22.3627487 },
    { lat: 48.9314659, lng: 22.3644303 },
    { lat: 48.9308764, lng: 22.3649023 },
    { lat: 48.9301815, lng: 22.3664946 },
    { lat: 48.9300945, lng: 22.367171 },
    { lat: 48.9290729, lng: 22.3708072 },
    { lat: 48.9290786, lng: 22.3709682 },
    { lat: 48.9284571, lng: 22.371422 },
    { lat: 48.9274059, lng: 22.3723424 },
    { lat: 48.9264651, lng: 22.3728421 },
    { lat: 48.925783, lng: 22.3729697 },
    { lat: 48.9273637, lng: 22.3773666 },
    { lat: 48.9280219, lng: 22.3780141 },
    { lat: 48.928153, lng: 22.3784198 },
    { lat: 48.9285374, lng: 22.3782902 },
    { lat: 48.9288967, lng: 22.378169 },
    { lat: 48.9315102, lng: 22.377632 },
    { lat: 48.9318026, lng: 22.3778414 },
    { lat: 48.9321261, lng: 22.3784663 },
    { lat: 48.9328125, lng: 22.3790646 },
    { lat: 48.9336297, lng: 22.3793175 },
    { lat: 48.9356252, lng: 22.378749 },
    { lat: 48.9359129, lng: 22.378837 },
    { lat: 48.9366642, lng: 22.378892 },
    { lat: 48.9386729, lng: 22.3800743 },
    { lat: 48.939793, lng: 22.380607 },
    { lat: 48.9402193, lng: 22.3810889 },
    { lat: 48.9409572, lng: 22.3827988 },
    { lat: 48.9414077, lng: 22.3841178 },
    { lat: 48.9420189, lng: 22.3844116 },
    { lat: 48.9423009, lng: 22.3849335 },
    { lat: 48.9424543, lng: 22.3855389 },
    { lat: 48.9440631, lng: 22.3874426 },
    { lat: 48.9448904, lng: 22.388118 },
    { lat: 48.9451015, lng: 22.3884731 },
    { lat: 48.9456838, lng: 22.3889462 },
    { lat: 48.9462631, lng: 22.389117 },
    { lat: 48.9467662, lng: 22.3893463 },
    { lat: 48.9472453, lng: 22.3900459 },
    { lat: 48.9478688, lng: 22.3908132 },
    { lat: 48.9482741, lng: 22.3911482 },
    { lat: 48.9485194, lng: 22.3912737 },
    { lat: 48.9487416, lng: 22.391292 },
    { lat: 48.949028, lng: 22.3911715 },
    { lat: 48.9493753, lng: 22.3913848 },
    { lat: 48.9496448, lng: 22.3914139 },
    { lat: 48.9501476, lng: 22.3916304 },
    { lat: 48.9518302, lng: 22.3917426 },
    { lat: 48.9526642, lng: 22.3919331 },
    { lat: 48.9530388, lng: 22.3923766 },
    { lat: 48.9533762, lng: 22.393398 },
    { lat: 48.9534056, lng: 22.3938331 },
    { lat: 48.953871, lng: 22.3948419 },
    { lat: 48.9541788, lng: 22.3959446 },
    { lat: 48.9542249, lng: 22.3961084 },
    { lat: 48.9548708, lng: 22.3960189 },
    { lat: 48.9565379, lng: 22.3953454 },
    { lat: 48.957, lng: 22.3951151 },
    { lat: 48.9578444, lng: 22.3944194 },
    { lat: 48.9583687, lng: 22.3941049 },
    { lat: 48.9588888, lng: 22.3940251 },
    { lat: 48.9596582, lng: 22.3937617 },
    { lat: 48.9600458, lng: 22.3934778 },
    { lat: 48.9606559, lng: 22.3932285 },
    { lat: 48.9629709, lng: 22.3916485 },
    { lat: 48.9637566, lng: 22.3912575 },
    { lat: 48.9650624, lng: 22.3908307 },
    { lat: 48.9653349, lng: 22.3906869 },
    { lat: 48.9652354, lng: 22.390123 },
    { lat: 48.9652119, lng: 22.3893832 },
    { lat: 48.9657297, lng: 22.3870978 },
    { lat: 48.9665681, lng: 22.3854691 },
    { lat: 48.9674048, lng: 22.3846084 },
    { lat: 48.9680018, lng: 22.3838514 },
    { lat: 48.9685319, lng: 22.3835519 },
    { lat: 48.9688821, lng: 22.3829357 },
    { lat: 48.9692971, lng: 22.381973 },
    { lat: 48.9699559, lng: 22.3809513 },
    { lat: 48.9702595, lng: 22.3802478 },
    { lat: 48.9703862, lng: 22.3797523 },
    { lat: 48.9708397, lng: 22.3792338 },
    { lat: 48.9712678, lng: 22.3785221 },
    { lat: 48.9717486, lng: 22.3778522 },
    { lat: 48.9720917, lng: 22.3772144 },
    { lat: 48.9730753, lng: 22.3745916 },
    { lat: 48.9732523, lng: 22.3742329 },
    { lat: 48.973436, lng: 22.3728679 },
    { lat: 48.9735964, lng: 22.3723174 },
    { lat: 48.9737984, lng: 22.3718736 },
    { lat: 48.9744204, lng: 22.3716621 },
    { lat: 48.9753226, lng: 22.3708753 },
    { lat: 48.9757569, lng: 22.3706207 },
    { lat: 48.9763016, lng: 22.3700001 },
    { lat: 48.9769196, lng: 22.368858 },
    { lat: 48.977362, lng: 22.368192 },
    { lat: 48.978608, lng: 22.3672819 },
    { lat: 48.9788979, lng: 22.3671585 },
    { lat: 48.9794243, lng: 22.3664765 },
    { lat: 48.979869, lng: 22.3664426 },
    { lat: 48.980916, lng: 22.3657072 },
    { lat: 48.9812507, lng: 22.365467 },
  ],
  Stakčín: [
    { lat: 49.036259, lng: 22.2972515 },
    { lat: 49.0366182, lng: 22.2976886 },
    { lat: 49.0370491, lng: 22.2981566 },
    { lat: 49.0372338, lng: 22.2989466 },
    { lat: 49.037788, lng: 22.2991396 },
    { lat: 49.0388656, lng: 22.2992528 },
    { lat: 49.0391541, lng: 22.2993863 },
    { lat: 49.0396624, lng: 22.2990801 },
    { lat: 49.0407436, lng: 22.3001316 },
    { lat: 49.0416346, lng: 22.3004521 },
    { lat: 49.0422718, lng: 22.3001232 },
    { lat: 49.0425308, lng: 22.3008609 },
    { lat: 49.0429403, lng: 22.3011303 },
    { lat: 49.0432791, lng: 22.3010398 },
    { lat: 49.0437903, lng: 22.3016827 },
    { lat: 49.0439166, lng: 22.3017584 },
    { lat: 49.043691, lng: 22.3022946 },
    { lat: 49.0434011, lng: 22.302825 },
    { lat: 49.0427969, lng: 22.303686 },
    { lat: 49.0431786, lng: 22.3042268 },
    { lat: 49.0442885, lng: 22.3020527 },
    { lat: 49.0446237, lng: 22.3023055 },
    { lat: 49.0444612, lng: 22.3029073 },
    { lat: 49.0448387, lng: 22.3033085 },
    { lat: 49.045265, lng: 22.3021646 },
    { lat: 49.0469897, lng: 22.3037562 },
    { lat: 49.0473331, lng: 22.3039727 },
    { lat: 49.0472699, lng: 22.3052088 },
    { lat: 49.0474942, lng: 22.3051746 },
    { lat: 49.0477418, lng: 22.305553 },
    { lat: 49.0479229, lng: 22.3056561 },
    { lat: 49.0481309, lng: 22.3061175 },
    { lat: 49.0483958, lng: 22.306414 },
    { lat: 49.0489617, lng: 22.3063716 },
    { lat: 49.0494206, lng: 22.3064744 },
    { lat: 49.0496003, lng: 22.3062084 },
    { lat: 49.0498044, lng: 22.3061207 },
    { lat: 49.0502307, lng: 22.3054557 },
    { lat: 49.0504408, lng: 22.3058521 },
    { lat: 49.0506475, lng: 22.3064739 },
    { lat: 49.0507924, lng: 22.3062536 },
    { lat: 49.0508238, lng: 22.3063004 },
    { lat: 49.0512798, lng: 22.3056039 },
    { lat: 49.051559, lng: 22.305872 },
    { lat: 49.0524041, lng: 22.3046554 },
    { lat: 49.0526042, lng: 22.3044969 },
    { lat: 49.0528427, lng: 22.3048105 },
    { lat: 49.0526367, lng: 22.3057319 },
    { lat: 49.0528683, lng: 22.3060337 },
    { lat: 49.0527021, lng: 22.3066741 },
    { lat: 49.05266, lng: 22.307071 },
    { lat: 49.0530643, lng: 22.307661 },
    { lat: 49.0532967, lng: 22.3085253 },
    { lat: 49.0535172, lng: 22.3088922 },
    { lat: 49.0534967, lng: 22.3090861 },
    { lat: 49.0536043, lng: 22.3093342 },
    { lat: 49.0538687, lng: 22.3096032 },
    { lat: 49.0539841, lng: 22.3099391 },
    { lat: 49.0535965, lng: 22.3113114 },
    { lat: 49.0530459, lng: 22.3119094 },
    { lat: 49.0528397, lng: 22.3119939 },
    { lat: 49.0513813, lng: 22.3141841 },
    { lat: 49.0520633, lng: 22.3150439 },
    { lat: 49.0522778, lng: 22.3151895 },
    { lat: 49.0527161, lng: 22.3151766 },
    { lat: 49.0538542, lng: 22.3153326 },
    { lat: 49.0546984, lng: 22.3153148 },
    { lat: 49.0555689, lng: 22.3168186 },
    { lat: 49.0559244, lng: 22.3169324 },
    { lat: 49.0560379, lng: 22.3172868 },
    { lat: 49.0558862, lng: 22.317772 },
    { lat: 49.0569996, lng: 22.3183891 },
    { lat: 49.0566583, lng: 22.3195109 },
    { lat: 49.056633, lng: 22.3194856 },
    { lat: 49.0566194, lng: 22.3196423 },
    { lat: 49.0567504, lng: 22.3206613 },
    { lat: 49.0567153, lng: 22.3221149 },
    { lat: 49.0571863, lng: 22.3229865 },
    { lat: 49.0591671, lng: 22.3258247 },
    { lat: 49.0593773, lng: 22.3255094 },
    { lat: 49.059526, lng: 22.32589 },
    { lat: 49.0599933, lng: 22.3266453 },
    { lat: 49.0600219, lng: 22.3268795 },
    { lat: 49.0600689, lng: 22.3270747 },
    { lat: 49.0605223, lng: 22.3278192 },
    { lat: 49.0609384, lng: 22.3281338 },
    { lat: 49.0618666, lng: 22.3291477 },
    { lat: 49.0626408, lng: 22.3301639 },
    { lat: 49.0625495, lng: 22.3306314 },
    { lat: 49.0629537, lng: 22.3307519 },
    { lat: 49.0642871, lng: 22.3320958 },
    { lat: 49.0648617, lng: 22.3327785 },
    { lat: 49.0657736, lng: 22.3335559 },
    { lat: 49.0668423, lng: 22.3346757 },
    { lat: 49.0671132, lng: 22.3350309 },
    { lat: 49.0677041, lng: 22.3360052 },
    { lat: 49.0683181, lng: 22.3372677 },
    { lat: 49.068705, lng: 22.3384181 },
    { lat: 49.0693607, lng: 22.3393178 },
    { lat: 49.0697646, lng: 22.3400539 },
    { lat: 49.0700874, lng: 22.3415314 },
    { lat: 49.0705146, lng: 22.3420551 },
    { lat: 49.0709341, lng: 22.3422151 },
    { lat: 49.0713738, lng: 22.3425858 },
    { lat: 49.0727467, lng: 22.3427833 },
    { lat: 49.0732415, lng: 22.3431979 },
    { lat: 49.0739435, lng: 22.343528 },
    { lat: 49.07404, lng: 22.343832 },
    { lat: 49.0746815, lng: 22.3437587 },
    { lat: 49.0749279, lng: 22.3439743 },
    { lat: 49.0753411, lng: 22.3440446 },
    { lat: 49.0761002, lng: 22.343779 },
    { lat: 49.0766676, lng: 22.3439084 },
    { lat: 49.0774278, lng: 22.3442263 },
    { lat: 49.0781994, lng: 22.3453921 },
    { lat: 49.0782565, lng: 22.3460135 },
    { lat: 49.0790401, lng: 22.3476393 },
    { lat: 49.0800305, lng: 22.3485773 },
    { lat: 49.08026, lng: 22.3497957 },
    { lat: 49.0800911, lng: 22.3499974 },
    { lat: 49.0802908, lng: 22.3504999 },
    { lat: 49.0811878, lng: 22.3513245 },
    { lat: 49.0810515, lng: 22.3515311 },
    { lat: 49.0807867, lng: 22.3531951 },
    { lat: 49.0807774, lng: 22.3540766 },
    { lat: 49.0809371, lng: 22.3551371 },
    { lat: 49.0809635, lng: 22.3561149 },
    { lat: 49.0808695, lng: 22.3570364 },
    { lat: 49.080605, lng: 22.3583553 },
    { lat: 49.0807916, lng: 22.3594112 },
    { lat: 49.0810045, lng: 22.361225 },
    { lat: 49.0808457, lng: 22.3619051 },
    { lat: 49.0804883, lng: 22.362879 },
    { lat: 49.0804225, lng: 22.3633795 },
    { lat: 49.0805859, lng: 22.3643056 },
    { lat: 49.0806963, lng: 22.3646235 },
    { lat: 49.0812089, lng: 22.3641897 },
    { lat: 49.0818841, lng: 22.3629343 },
    { lat: 49.0822345, lng: 22.3625447 },
    { lat: 49.083501, lng: 22.3617048 },
    { lat: 49.0839951, lng: 22.3617785 },
    { lat: 49.0843666, lng: 22.3621176 },
    { lat: 49.0843641, lng: 22.3626318 },
    { lat: 49.0857012, lng: 22.3628443 },
    { lat: 49.0862901, lng: 22.3630418 },
    { lat: 49.0868516, lng: 22.3634871 },
    { lat: 49.0872009, lng: 22.3640462 },
    { lat: 49.0874132, lng: 22.3647236 },
    { lat: 49.0868559, lng: 22.3652816 },
    { lat: 49.0865529, lng: 22.3658977 },
    { lat: 49.0865535, lng: 22.3665633 },
    { lat: 49.0864344, lng: 22.3673613 },
    { lat: 49.0866942, lng: 22.3678993 },
    { lat: 49.0877229, lng: 22.368517 },
    { lat: 49.0881101, lng: 22.3689089 },
    { lat: 49.0882397, lng: 22.3699351 },
    { lat: 49.0882874, lng: 22.3707078 },
    { lat: 49.0881615, lng: 22.3716117 },
    { lat: 49.0879129, lng: 22.3720917 },
    { lat: 49.0873028, lng: 22.3729216 },
    { lat: 49.0871619, lng: 22.3741829 },
    { lat: 49.0868422, lng: 22.374591 },
    { lat: 49.0861099, lng: 22.3750659 },
    { lat: 49.085582, lng: 22.3762713 },
    { lat: 49.0862755, lng: 22.3763035 },
    { lat: 49.0866744, lng: 22.3763468 },
    { lat: 49.087259, lng: 22.3767119 },
    { lat: 49.0877274, lng: 22.3785042 },
    { lat: 49.0879309, lng: 22.3805803 },
    { lat: 49.0882099, lng: 22.3810729 },
    { lat: 49.0887346, lng: 22.3817431 },
    { lat: 49.0890576, lng: 22.3822885 },
    { lat: 49.0892042, lng: 22.3831117 },
    { lat: 49.0893542, lng: 22.3836214 },
    { lat: 49.0892226, lng: 22.3840975 },
    { lat: 49.089217, lng: 22.3843134 },
    { lat: 49.0887459, lng: 22.3844235 },
    { lat: 49.0884483, lng: 22.3842231 },
    { lat: 49.0885689, lng: 22.383763 },
    { lat: 49.0882412, lng: 22.3835202 },
    { lat: 49.0876809, lng: 22.3832407 },
    { lat: 49.0876432, lng: 22.3837039 },
    { lat: 49.0878239, lng: 22.3843273 },
    { lat: 49.0880634, lng: 22.3847473 },
    { lat: 49.0880516, lng: 22.3846682 },
    { lat: 49.0883502, lng: 22.3854474 },
    { lat: 49.0886105, lng: 22.3863841 },
    { lat: 49.0891342, lng: 22.3864767 },
    { lat: 49.0901327, lng: 22.3872211 },
    { lat: 49.0911651, lng: 22.3871211 },
    { lat: 49.091994, lng: 22.3871264 },
    { lat: 49.0921568, lng: 22.387327 },
    { lat: 49.0924064, lng: 22.3879162 },
    { lat: 49.0928921, lng: 22.3886591 },
    { lat: 49.0930988, lng: 22.3888629 },
    { lat: 49.0938831, lng: 22.3891593 },
    { lat: 49.0942674, lng: 22.3894044 },
    { lat: 49.0945301, lng: 22.3893328 },
    { lat: 49.0951013, lng: 22.3890047 },
    { lat: 49.0954395, lng: 22.3890528 },
    { lat: 49.0957592, lng: 22.389005 },
    { lat: 49.0960564, lng: 22.3891284 },
    { lat: 49.0968769, lng: 22.3899136 },
    { lat: 49.0973064, lng: 22.3900244 },
    { lat: 49.097812, lng: 22.3903763 },
    { lat: 49.0981584, lng: 22.3916636 },
    { lat: 49.0984018, lng: 22.3935754 },
    { lat: 49.09907, lng: 22.3944384 },
    { lat: 49.099804, lng: 22.3956108 },
    { lat: 49.1002132, lng: 22.3968926 },
    { lat: 49.1005154, lng: 22.3975286 },
    { lat: 49.1008105, lng: 22.39831 },
    { lat: 49.1023621, lng: 22.3986172 },
    { lat: 49.1035121, lng: 22.3993547 },
    { lat: 49.1041414, lng: 22.4002413 },
    { lat: 49.1052487, lng: 22.4008191 },
    { lat: 49.1058851, lng: 22.4014592 },
    { lat: 49.1065076, lng: 22.4017596 },
    { lat: 49.1069247, lng: 22.4021318 },
    { lat: 49.1070621, lng: 22.4024173 },
    { lat: 49.1083476, lng: 22.4032885 },
    { lat: 49.1091874, lng: 22.4041934 },
    { lat: 49.1096361, lng: 22.4045277 },
    { lat: 49.1098611, lng: 22.4046953 },
    { lat: 49.1105, lng: 22.403599 },
    { lat: 49.1109724, lng: 22.4028237 },
    { lat: 49.1125674, lng: 22.4023112 },
    { lat: 49.11285, lng: 22.401935 },
    { lat: 49.1130014, lng: 22.4013009 },
    { lat: 49.113368, lng: 22.400824 },
    { lat: 49.1138098, lng: 22.4002048 },
    { lat: 49.11421, lng: 22.39925 },
    { lat: 49.1143333, lng: 22.3988889 },
    { lat: 49.1146163, lng: 22.3979661 },
    { lat: 49.1152148, lng: 22.3974168 },
    { lat: 49.1155556, lng: 22.3971667 },
    { lat: 49.1163101, lng: 22.3969012 },
    { lat: 49.116505, lng: 22.3967926 },
    { lat: 49.1169672, lng: 22.3967567 },
    { lat: 49.1171143, lng: 22.3966366 },
    { lat: 49.1171922, lng: 22.3965207 },
    { lat: 49.117256, lng: 22.396174 },
    { lat: 49.1173402, lng: 22.3959076 },
    { lat: 49.1174993, lng: 22.3956377 },
    { lat: 49.1176367, lng: 22.3953234 },
    { lat: 49.117708, lng: 22.394842 },
    { lat: 49.117646, lng: 22.394274 },
    { lat: 49.117677, lng: 22.393796 },
    { lat: 49.117781, lng: 22.393388 },
    { lat: 49.117933, lng: 22.392507 },
    { lat: 49.1180547, lng: 22.3918239 },
    { lat: 49.119055, lng: 22.390863 },
    { lat: 49.1208386, lng: 22.3906559 },
    { lat: 49.1221907, lng: 22.3904614 },
    { lat: 49.1233611, lng: 22.3898333 },
    { lat: 49.124299, lng: 22.389836 },
    { lat: 49.125128, lng: 22.39014 },
    { lat: 49.125836, lng: 22.390116 },
    { lat: 49.126655, lng: 22.390215 },
    { lat: 49.127393, lng: 22.39023 },
    { lat: 49.1282194, lng: 22.3883042 },
    { lat: 49.128744, lng: 22.387169 },
    { lat: 49.1289933, lng: 22.3866538 },
    { lat: 49.1293611, lng: 22.3853056 },
    { lat: 49.12981, lng: 22.38391 },
    { lat: 49.1299083, lng: 22.3835995 },
    { lat: 49.1299968, lng: 22.3834271 },
    { lat: 49.1301886, lng: 22.3831159 },
    { lat: 49.1302471, lng: 22.3828294 },
    { lat: 49.1303751, lng: 22.3823095 },
    { lat: 49.1304167, lng: 22.3819722 },
    { lat: 49.1305063, lng: 22.3813122 },
    { lat: 49.130767, lng: 22.380793 },
    { lat: 49.1309167, lng: 22.3805278 },
    { lat: 49.131038, lng: 22.380229 },
    { lat: 49.1310623, lng: 22.3799145 },
    { lat: 49.1310909, lng: 22.379568 },
    { lat: 49.131528, lng: 22.379319 },
    { lat: 49.1316944, lng: 22.3789444 },
    { lat: 49.131893, lng: 22.378509 },
    { lat: 49.132284, lng: 22.378269 },
    { lat: 49.133316, lng: 22.377479 },
    { lat: 49.1337, lng: 22.377312 },
    { lat: 49.133995, lng: 22.377648 },
    { lat: 49.134337, lng: 22.377836 },
    { lat: 49.134852, lng: 22.377891 },
    { lat: 49.135419, lng: 22.378032 },
    { lat: 49.136089, lng: 22.377934 },
    { lat: 49.136496, lng: 22.377226 },
    { lat: 49.136898, lng: 22.377006 },
    { lat: 49.13732, lng: 22.37676 },
    { lat: 49.137701, lng: 22.376472 },
    { lat: 49.138101, lng: 22.375703 },
    { lat: 49.138428, lng: 22.375238 },
    { lat: 49.1388889, lng: 22.37475 },
    { lat: 49.139223, lng: 22.374286 },
    { lat: 49.139496, lng: 22.373941 },
    { lat: 49.1398315, lng: 22.3735522 },
    { lat: 49.140047, lng: 22.373357 },
    { lat: 49.1403678, lng: 22.3731877 },
    { lat: 49.140947, lng: 22.373054 },
    { lat: 49.1420278, lng: 22.3723611 },
    { lat: 49.142612, lng: 22.371976 },
    { lat: 49.1432158, lng: 22.3715925 },
    { lat: 49.143504, lng: 22.371302 },
    { lat: 49.1439892, lng: 22.3709597 },
    { lat: 49.1447318, lng: 22.3704034 },
    { lat: 49.1454653, lng: 22.3696677 },
    { lat: 49.14593, lng: 22.36878 },
    { lat: 49.1462222, lng: 22.3680556 },
    { lat: 49.1465833, lng: 22.3672778 },
    { lat: 49.1469214, lng: 22.3661956 },
    { lat: 49.146353, lng: 22.365327 },
    { lat: 49.145701, lng: 22.364628 },
    { lat: 49.145141, lng: 22.36361 },
    { lat: 49.1445833, lng: 22.3624722 },
    { lat: 49.144513, lng: 22.362241 },
    { lat: 49.144577, lng: 22.36125 },
    { lat: 49.144575, lng: 22.360672 },
    { lat: 49.144809, lng: 22.359647 },
    { lat: 49.144994, lng: 22.358548 },
    { lat: 49.144861, lng: 22.357928 },
    { lat: 49.144809, lng: 22.357675 },
    { lat: 49.144571, lng: 22.355278 },
    { lat: 49.1451944, lng: 22.3540556 },
    { lat: 49.145655, lng: 22.352341 },
    { lat: 49.1458333, lng: 22.3514167 },
    { lat: 49.14578, lng: 22.350284 },
    { lat: 49.1457456, lng: 22.3490475 },
    { lat: 49.145839, lng: 22.347931 },
    { lat: 49.1457037, lng: 22.3472169 },
    { lat: 49.145645, lng: 22.346915 },
    { lat: 49.145295, lng: 22.346497 },
    { lat: 49.1455833, lng: 22.3458889 },
    { lat: 49.1456667, lng: 22.3453611 },
    { lat: 49.145757, lng: 22.343042 },
    { lat: 49.145692, lng: 22.34134 },
    { lat: 49.145594, lng: 22.340409 },
    { lat: 49.1455833, lng: 22.3393611 },
    { lat: 49.145737, lng: 22.338224 },
    { lat: 49.1458056, lng: 22.3376389 },
    { lat: 49.146122, lng: 22.336377 },
    { lat: 49.146206, lng: 22.335138 },
    { lat: 49.146472, lng: 22.334605 },
    { lat: 49.146102, lng: 22.333922 },
    { lat: 49.1458889, lng: 22.33375 },
    { lat: 49.1457237, lng: 22.3333228 },
    { lat: 49.145515, lng: 22.333159 },
    { lat: 49.145461, lng: 22.332659 },
    { lat: 49.145211, lng: 22.332439 },
    { lat: 49.14491, lng: 22.331804 },
    { lat: 49.1447778, lng: 22.3313611 },
    { lat: 49.144686, lng: 22.330911 },
    { lat: 49.144346, lng: 22.330286 },
    { lat: 49.1442343, lng: 22.3301543 },
    { lat: 49.1441491, lng: 22.329858 },
    { lat: 49.1441186, lng: 22.3295624 },
    { lat: 49.1437351, lng: 22.3291982 },
    { lat: 49.1433575, lng: 22.3291611 },
    { lat: 49.1429778, lng: 22.3287984 },
    { lat: 49.1426212, lng: 22.3283811 },
    { lat: 49.14239, lng: 22.327995 },
    { lat: 49.141914, lng: 22.327167 },
    { lat: 49.141271, lng: 22.326491 },
    { lat: 49.14112, lng: 22.326275 },
    { lat: 49.140914, lng: 22.326077 },
    { lat: 49.140665, lng: 22.325963 },
    { lat: 49.140517, lng: 22.325675 },
    { lat: 49.140258, lng: 22.325354 },
    { lat: 49.1399, lng: 22.324836 },
    { lat: 49.139352, lng: 22.323752 },
    { lat: 49.138914, lng: 22.323085 },
    { lat: 49.138381, lng: 22.322473 },
    { lat: 49.1377292, lng: 22.3219613 },
    { lat: 49.1368057, lng: 22.3218433 },
    { lat: 49.136211, lng: 22.321116 },
    { lat: 49.1359508, lng: 22.320826 },
    { lat: 49.135577, lng: 22.320531 },
    { lat: 49.135617, lng: 22.319915 },
    { lat: 49.135642, lng: 22.318982 },
    { lat: 49.135608, lng: 22.318277 },
    { lat: 49.135551, lng: 22.31765 },
    { lat: 49.136228, lng: 22.316782 },
    { lat: 49.136334, lng: 22.316211 },
    { lat: 49.136408, lng: 22.315821 },
    { lat: 49.136593, lng: 22.315296 },
    { lat: 49.136809, lng: 22.31506 },
    { lat: 49.136967, lng: 22.314432 },
    { lat: 49.1369979, lng: 22.3136297 },
    { lat: 49.13661, lng: 22.312642 },
    { lat: 49.136687, lng: 22.311937 },
    { lat: 49.137113, lng: 22.311205 },
    { lat: 49.137219, lng: 22.309856 },
    { lat: 49.137001, lng: 22.30926 },
    { lat: 49.136998, lng: 22.308577 },
    { lat: 49.136921, lng: 22.308021 },
    { lat: 49.136933, lng: 22.307507 },
    { lat: 49.136846, lng: 22.307249 },
    { lat: 49.137009, lng: 22.306017 },
    { lat: 49.1377778, lng: 22.3046389 },
    { lat: 49.137925, lng: 22.304396 },
    { lat: 49.137934, lng: 22.304255 },
    { lat: 49.138805, lng: 22.303403 },
    { lat: 49.13899, lng: 22.303353 },
    { lat: 49.139109, lng: 22.303198 },
    { lat: 49.139685, lng: 22.302476 },
    { lat: 49.140149, lng: 22.301187 },
    { lat: 49.140709, lng: 22.299617 },
    { lat: 49.140841, lng: 22.298895 },
    { lat: 49.141039, lng: 22.298258 },
    { lat: 49.141535, lng: 22.297225 },
    { lat: 49.141537, lng: 22.296381 },
    { lat: 49.1415137, lng: 22.2958267 },
    { lat: 49.141453, lng: 22.295325 },
    { lat: 49.141438, lng: 22.294081 },
    { lat: 49.141478, lng: 22.293541 },
    { lat: 49.141527, lng: 22.292839 },
    { lat: 49.141752, lng: 22.292074 },
    { lat: 49.1419001, lng: 22.2914037 },
    { lat: 49.141942, lng: 22.290797 },
    { lat: 49.142074, lng: 22.290186 },
    { lat: 49.142192, lng: 22.289131 },
    { lat: 49.142266, lng: 22.288847 },
    { lat: 49.142314, lng: 22.288236 },
    { lat: 49.142346, lng: 22.288046 },
    { lat: 49.142339, lng: 22.2875 },
    { lat: 49.142274, lng: 22.286977 },
    { lat: 49.142311, lng: 22.286176 },
    { lat: 49.142259, lng: 22.284869 },
    { lat: 49.142185, lng: 22.283303 },
    { lat: 49.14208, lng: 22.281768 },
    { lat: 49.142519, lng: 22.281174 },
    { lat: 49.14288, lng: 22.280515 },
    { lat: 49.143034, lng: 22.280154 },
    { lat: 49.1432081, lng: 22.2795906 },
    { lat: 49.143319, lng: 22.279128 },
    { lat: 49.143805, lng: 22.277973 },
    { lat: 49.14413, lng: 22.277181 },
    { lat: 49.144283, lng: 22.27646 },
    { lat: 49.144395, lng: 22.275552 },
    { lat: 49.1446274, lng: 22.2741533 },
    { lat: 49.144865, lng: 22.273461 },
    { lat: 49.145092, lng: 22.272986 },
    { lat: 49.145376, lng: 22.272517 },
    { lat: 49.145579, lng: 22.271892 },
    { lat: 49.145662, lng: 22.271362 },
    { lat: 49.145776, lng: 22.270436 },
    { lat: 49.1458, lng: 22.269812 },
    { lat: 49.146132, lng: 22.268794 },
    { lat: 49.146444, lng: 22.267846 },
    { lat: 49.146836, lng: 22.267146 },
    { lat: 49.147076, lng: 22.266773 },
    { lat: 49.147367, lng: 22.266239 },
    { lat: 49.147647, lng: 22.265027 },
    { lat: 49.147813, lng: 22.264376 },
    { lat: 49.14786, lng: 22.263804 },
    { lat: 49.148026, lng: 22.26323 },
    { lat: 49.14816, lng: 22.262644 },
    { lat: 49.148192, lng: 22.261877 },
    { lat: 49.148503, lng: 22.261398 },
    { lat: 49.14891, lng: 22.26051 },
    { lat: 49.14934, lng: 22.259709 },
    { lat: 49.149442, lng: 22.259488 },
    { lat: 49.149691, lng: 22.258505 },
    { lat: 49.150053, lng: 22.257545 },
    { lat: 49.15048, lng: 22.256617 },
    { lat: 49.150611, lng: 22.256277 },
    { lat: 49.150738, lng: 22.255122 },
    { lat: 49.150694, lng: 22.254322 },
    { lat: 49.150794, lng: 22.253882 },
    { lat: 49.150774, lng: 22.253223 },
    { lat: 49.150862, lng: 22.252525 },
    { lat: 49.151034, lng: 22.252251 },
    { lat: 49.151431, lng: 22.251797 },
    { lat: 49.151518, lng: 22.251803 },
    { lat: 49.151671, lng: 22.251586 },
    { lat: 49.151987, lng: 22.250921 },
    { lat: 49.152167, lng: 22.250388 },
    { lat: 49.1527, lng: 22.249515 },
    { lat: 49.153044, lng: 22.248492 },
    { lat: 49.153113, lng: 22.248036 },
    { lat: 49.153212, lng: 22.247475 },
    { lat: 49.153278, lng: 22.247031 },
    { lat: 49.153268, lng: 22.246233 },
    { lat: 49.15318, lng: 22.245776 },
    { lat: 49.153072, lng: 22.245517 },
    { lat: 49.152666, lng: 22.244495 },
    { lat: 49.152544, lng: 22.243846 },
    { lat: 49.152394, lng: 22.243012 },
    { lat: 49.15222, lng: 22.242064 },
    { lat: 49.152024, lng: 22.241032 },
    { lat: 49.151883, lng: 22.239859 },
    { lat: 49.151889, lng: 22.239689 },
    { lat: 49.1522093, lng: 22.2392413 },
    { lat: 49.15275, lng: 22.238565 },
    { lat: 49.153173, lng: 22.238054 },
    { lat: 49.153452, lng: 22.237512 },
    { lat: 49.153528, lng: 22.23749 },
    { lat: 49.153723, lng: 22.237154 },
    { lat: 49.15409, lng: 22.236833 },
    { lat: 49.154422, lng: 22.236061 },
    { lat: 49.154327, lng: 22.235264 },
    { lat: 49.154261, lng: 22.234384 },
    { lat: 49.154199, lng: 22.233966 },
    { lat: 49.154147, lng: 22.233375 },
    { lat: 49.153733, lng: 22.232052 },
    { lat: 49.153745, lng: 22.23083 },
    { lat: 49.1535698, lng: 22.230386 },
    { lat: 49.153567, lng: 22.230226 },
    { lat: 49.153423, lng: 22.229591 },
    { lat: 49.1530424, lng: 22.2282795 },
    { lat: 49.1529189, lng: 22.2278471 },
    { lat: 49.152663, lng: 22.227074 },
    { lat: 49.15263, lng: 22.226706 },
    { lat: 49.15257, lng: 22.226157 },
    { lat: 49.152541, lng: 22.225713 },
    { lat: 49.152644, lng: 22.225518 },
    { lat: 49.153037, lng: 22.224595 },
    { lat: 49.15324, lng: 22.224733 },
    { lat: 49.15409, lng: 22.225198 },
    { lat: 49.154626, lng: 22.225292 },
    { lat: 49.1553045, lng: 22.2253318 },
    { lat: 49.1557663, lng: 22.2253097 },
    { lat: 49.156095, lng: 22.225355 },
    { lat: 49.156285, lng: 22.225409 },
    { lat: 49.156749, lng: 22.225637 },
    { lat: 49.15755, lng: 22.226249 },
    { lat: 49.1576435, lng: 22.2262959 },
    { lat: 49.15828, lng: 22.226717 },
    { lat: 49.158384, lng: 22.226797 },
    { lat: 49.158718, lng: 22.226896 },
    { lat: 49.1592222, lng: 22.2269904 },
    { lat: 49.1596404, lng: 22.2266931 },
    { lat: 49.1601249, lng: 22.2266159 },
    { lat: 49.1604602, lng: 22.226061 },
    { lat: 49.1604629, lng: 22.2259849 },
    { lat: 49.1600648, lng: 22.2256861 },
    { lat: 49.1596415, lng: 22.2255676 },
    { lat: 49.1591971, lng: 22.225559 },
    { lat: 49.1582495, lng: 22.2248719 },
    { lat: 49.1578647, lng: 22.2243579 },
    { lat: 49.157715, lng: 22.2240509 },
    { lat: 49.1567584, lng: 22.2232134 },
    { lat: 49.1564044, lng: 22.222326 },
    { lat: 49.156213, lng: 22.2216069 },
    { lat: 49.1557743, lng: 22.2211051 },
    { lat: 49.1555808, lng: 22.2205471 },
    { lat: 49.155122, lng: 22.2202407 },
    { lat: 49.1548044, lng: 22.2199206 },
    { lat: 49.1531684, lng: 22.2193399 },
    { lat: 49.1522684, lng: 22.2196787 },
    { lat: 49.1516313, lng: 22.2196519 },
    { lat: 49.1514886, lng: 22.2192037 },
    { lat: 49.1514947, lng: 22.2193281 },
    { lat: 49.1513425, lng: 22.218298 },
    { lat: 49.1511785, lng: 22.2177644 },
    { lat: 49.1509916, lng: 22.2167387 },
    { lat: 49.1508186, lng: 22.216349 },
    { lat: 49.1506523, lng: 22.2161543 },
    { lat: 49.1502737, lng: 22.2147171 },
    { lat: 49.1501513, lng: 22.213871 },
    { lat: 49.1497641, lng: 22.2130106 },
    { lat: 49.1495848, lng: 22.212683 },
    { lat: 49.1488084, lng: 22.2112648 },
    { lat: 49.147849, lng: 22.2092596 },
    { lat: 49.147062, lng: 22.2087618 },
    { lat: 49.1467092, lng: 22.2086775 },
    { lat: 49.1461194, lng: 22.2084083 },
    { lat: 49.1457894, lng: 22.2084352 },
    { lat: 49.1456497, lng: 22.2083207 },
    { lat: 49.1450847, lng: 22.2081074 },
    { lat: 49.1436899, lng: 22.2072448 },
    { lat: 49.1409419, lng: 22.2061275 },
    { lat: 49.1405988, lng: 22.2063171 },
    { lat: 49.1403556, lng: 22.2062569 },
    { lat: 49.1401498, lng: 22.2063025 },
    { lat: 49.1399817, lng: 22.2061881 },
    { lat: 49.1396759, lng: 22.2062418 },
    { lat: 49.1391936, lng: 22.2061937 },
    { lat: 49.1388284, lng: 22.2065134 },
    { lat: 49.1386242, lng: 22.2068743 },
    { lat: 49.1385242, lng: 22.2069334 },
    { lat: 49.1383348, lng: 22.2077144 },
    { lat: 49.1377689, lng: 22.2089591 },
    { lat: 49.1377088, lng: 22.2089823 },
    { lat: 49.136042, lng: 22.2098354 },
    { lat: 49.134217, lng: 22.2103479 },
    { lat: 49.1323488, lng: 22.213549 },
    { lat: 49.1318652, lng: 22.2146002 },
    { lat: 49.130215, lng: 22.2171397 },
    { lat: 49.1297657, lng: 22.2163687 },
    { lat: 49.1295743, lng: 22.2164084 },
    { lat: 49.1294514, lng: 22.2165423 },
    { lat: 49.1287921, lng: 22.2163748 },
    { lat: 49.1283542, lng: 22.2165631 },
    { lat: 49.1279787, lng: 22.2166146 },
    { lat: 49.1274342, lng: 22.2164106 },
    { lat: 49.1261663, lng: 22.2185438 },
    { lat: 49.1247951, lng: 22.2214483 },
    { lat: 49.1238712, lng: 22.2218772 },
    { lat: 49.123166, lng: 22.2227347 },
    { lat: 49.122567, lng: 22.2232552 },
    { lat: 49.1198883, lng: 22.2245151 },
    { lat: 49.1193936, lng: 22.2240194 },
    { lat: 49.1178579, lng: 22.2228887 },
    { lat: 49.117747, lng: 22.2230589 },
    { lat: 49.1183888, lng: 22.2243078 },
    { lat: 49.1181319, lng: 22.2246356 },
    { lat: 49.1184482, lng: 22.2255028 },
    { lat: 49.118588, lng: 22.2265802 },
    { lat: 49.118495, lng: 22.2273031 },
    { lat: 49.1180686, lng: 22.2276458 },
    { lat: 49.1174893, lng: 22.2270848 },
    { lat: 49.1173344, lng: 22.2270954 },
    { lat: 49.1169901, lng: 22.2266414 },
    { lat: 49.1168906, lng: 22.2266168 },
    { lat: 49.1165189, lng: 22.2267391 },
    { lat: 49.1163615, lng: 22.2266873 },
    { lat: 49.116222, lng: 22.2267861 },
    { lat: 49.1161221, lng: 22.2266997 },
    { lat: 49.1159851, lng: 22.2267107 },
    { lat: 49.115595, lng: 22.2265568 },
    { lat: 49.1153623, lng: 22.2263125 },
    { lat: 49.1150757, lng: 22.2262247 },
    { lat: 49.1148057, lng: 22.2260154 },
    { lat: 49.11436, lng: 22.2261017 },
    { lat: 49.1139946, lng: 22.226009 },
    { lat: 49.1138604, lng: 22.2260371 },
    { lat: 49.1137781, lng: 22.225836 },
    { lat: 49.1135515, lng: 22.225861 },
    { lat: 49.1132875, lng: 22.2256701 },
    { lat: 49.113158, lng: 22.2257601 },
    { lat: 49.1129528, lng: 22.2248312 },
    { lat: 49.1125096, lng: 22.2246255 },
    { lat: 49.11187, lng: 22.2244889 },
    { lat: 49.1116948, lng: 22.2242535 },
    { lat: 49.1116769, lng: 22.2239048 },
    { lat: 49.1115919, lng: 22.2237727 },
    { lat: 49.1113162, lng: 22.2236126 },
    { lat: 49.1100743, lng: 22.2221966 },
    { lat: 49.1089398, lng: 22.2216459 },
    { lat: 49.1078612, lng: 22.2199857 },
    { lat: 49.1073817, lng: 22.2188099 },
    { lat: 49.1060455, lng: 22.2166994 },
    { lat: 49.1062333, lng: 22.2145452 },
    { lat: 49.1060662, lng: 22.2139642 },
    { lat: 49.1059177, lng: 22.2138197 },
    { lat: 49.1058509, lng: 22.2136433 },
    { lat: 49.1057436, lng: 22.2135685 },
    { lat: 49.1054575, lng: 22.2135572 },
    { lat: 49.1050051, lng: 22.2138442 },
    { lat: 49.1048129, lng: 22.2137997 },
    { lat: 49.104694, lng: 22.2139602 },
    { lat: 49.104506, lng: 22.2138049 },
    { lat: 49.1044225, lng: 22.2138433 },
    { lat: 49.1042248, lng: 22.2137499 },
    { lat: 49.1040301, lng: 22.2137916 },
    { lat: 49.1032622, lng: 22.2133054 },
    { lat: 49.103065, lng: 22.2132851 },
    { lat: 49.1028868, lng: 22.2131577 },
    { lat: 49.102726, lng: 22.2132586 },
    { lat: 49.1025173, lng: 22.2130872 },
    { lat: 49.1022646, lng: 22.213054 },
    { lat: 49.1021018, lng: 22.2129204 },
    { lat: 49.1019088, lng: 22.2129283 },
    { lat: 49.1017419, lng: 22.2128124 },
    { lat: 49.1016467, lng: 22.2128866 },
    { lat: 49.1015676, lng: 22.2127633 },
    { lat: 49.1013841, lng: 22.212704 },
    { lat: 49.101405, lng: 22.2125324 },
    { lat: 49.1010337, lng: 22.2124247 },
    { lat: 49.1007294, lng: 22.2120005 },
    { lat: 49.1003749, lng: 22.2118822 },
    { lat: 49.1002264, lng: 22.2117345 },
    { lat: 49.1000452, lng: 22.211431 },
    { lat: 49.099932, lng: 22.2113468 },
    { lat: 49.0998595, lng: 22.2111162 },
    { lat: 49.099756, lng: 22.2110204 },
    { lat: 49.0997502, lng: 22.2108599 },
    { lat: 49.0996685, lng: 22.2107353 },
    { lat: 49.0993704, lng: 22.2104325 },
    { lat: 49.0993578, lng: 22.2103104 },
    { lat: 49.0991449, lng: 22.2102052 },
    { lat: 49.0990059, lng: 22.2099763 },
    { lat: 49.0988647, lng: 22.2101434 },
    { lat: 49.098676, lng: 22.2097563 },
    { lat: 49.0984514, lng: 22.209566 },
    { lat: 49.0983038, lng: 22.2093127 },
    { lat: 49.0981045, lng: 22.2092955 },
    { lat: 49.0979863, lng: 22.208998 },
    { lat: 49.0979374, lng: 22.2090666 },
    { lat: 49.0975117, lng: 22.2086398 },
    { lat: 49.0974485, lng: 22.2084285 },
    { lat: 49.097187, lng: 22.208258 },
    { lat: 49.0970706, lng: 22.2083316 },
    { lat: 49.096836, lng: 22.2081048 },
    { lat: 49.096771, lng: 22.2079097 },
    { lat: 49.0965299, lng: 22.2079926 },
    { lat: 49.0964135, lng: 22.2079212 },
    { lat: 49.0963975, lng: 22.2076608 },
    { lat: 49.0948899, lng: 22.2067812 },
    { lat: 49.093833, lng: 22.2070962 },
    { lat: 49.0917932, lng: 22.2095168 },
    { lat: 49.0877888, lng: 22.2103398 },
    { lat: 49.0868908, lng: 22.211017 },
    { lat: 49.0849663, lng: 22.2131578 },
    { lat: 49.0838209, lng: 22.2133358 },
    { lat: 49.0828575, lng: 22.2147522 },
    { lat: 49.0807666, lng: 22.2162017 },
    { lat: 49.0803381, lng: 22.2159466 },
    { lat: 49.0794516, lng: 22.2133386 },
    { lat: 49.0787602, lng: 22.2122911 },
    { lat: 49.0773584, lng: 22.2126274 },
    { lat: 49.0753573, lng: 22.2198323 },
    { lat: 49.0749457, lng: 22.2230474 },
    { lat: 49.0674707, lng: 22.2173492 },
    { lat: 49.0663858, lng: 22.2170691 },
    { lat: 49.0652943, lng: 22.2165932 },
    { lat: 49.0649788, lng: 22.2163661 },
    { lat: 49.064269, lng: 22.2160543 },
    { lat: 49.0629336, lng: 22.2161442 },
    { lat: 49.0623597, lng: 22.216476 },
    { lat: 49.0612735, lng: 22.2146851 },
    { lat: 49.0586706, lng: 22.2159181 },
    { lat: 49.0573472, lng: 22.2192832 },
    { lat: 49.0556906, lng: 22.2186419 },
    { lat: 49.0544329, lng: 22.219674 },
    { lat: 49.0537792, lng: 22.2189093 },
    { lat: 49.0526988, lng: 22.2170284 },
    { lat: 49.0521636, lng: 22.2147273 },
    { lat: 49.050745, lng: 22.2135608 },
    { lat: 49.0492665, lng: 22.2135237 },
    { lat: 49.0476138, lng: 22.2124605 },
    { lat: 49.0462027, lng: 22.2127677 },
    { lat: 49.046055, lng: 22.2134551 },
    { lat: 49.0462187, lng: 22.214287 },
    { lat: 49.0459868, lng: 22.2157256 },
    { lat: 49.0458458, lng: 22.2162055 },
    { lat: 49.0455249, lng: 22.2154424 },
    { lat: 49.0451213, lng: 22.2155429 },
    { lat: 49.0449675, lng: 22.2164056 },
    { lat: 49.0448522, lng: 22.2178435 },
    { lat: 49.044689, lng: 22.2181323 },
    { lat: 49.0446823, lng: 22.2185923 },
    { lat: 49.0442847, lng: 22.2198616 },
    { lat: 49.0441103, lng: 22.2205651 },
    { lat: 49.0439192, lng: 22.2208191 },
    { lat: 49.0441439, lng: 22.2212126 },
    { lat: 49.0445441, lng: 22.2216059 },
    { lat: 49.0444199, lng: 22.2222874 },
    { lat: 49.0442109, lng: 22.2227959 },
    { lat: 49.0440126, lng: 22.2228847 },
    { lat: 49.0439376, lng: 22.2230517 },
    { lat: 49.0437315, lng: 22.22309 },
    { lat: 49.0435655, lng: 22.2233051 },
    { lat: 49.043647, lng: 22.2239777 },
    { lat: 49.044721, lng: 22.225032 },
    { lat: 49.0454804, lng: 22.225191 },
    { lat: 49.0459195, lng: 22.2259749 },
    { lat: 49.0460079, lng: 22.2263038 },
    { lat: 49.0457458, lng: 22.2270609 },
    { lat: 49.0455524, lng: 22.2273036 },
    { lat: 49.0454078, lng: 22.2279327 },
    { lat: 49.0460219, lng: 22.2290468 },
    { lat: 49.0463178, lng: 22.2292545 },
    { lat: 49.0460857, lng: 22.2309953 },
    { lat: 49.046624, lng: 22.2318831 },
    { lat: 49.0469579, lng: 22.232327 },
    { lat: 49.0454851, lng: 22.2340661 },
    { lat: 49.0457386, lng: 22.2342972 },
    { lat: 49.046128, lng: 22.2342997 },
    { lat: 49.0455268, lng: 22.234933 },
    { lat: 49.0457191, lng: 22.2354311 },
    { lat: 49.045882, lng: 22.2361828 },
    { lat: 49.0453678, lng: 22.2366277 },
    { lat: 49.0456736, lng: 22.2377896 },
    { lat: 49.0455316, lng: 22.2391357 },
    { lat: 49.045554, lng: 22.240238 },
    { lat: 49.0454785, lng: 22.2410432 },
    { lat: 49.045258, lng: 22.2410477 },
    { lat: 49.0451276, lng: 22.2417535 },
    { lat: 49.0448518, lng: 22.2427059 },
    { lat: 49.0443634, lng: 22.2431444 },
    { lat: 49.043594, lng: 22.2433385 },
    { lat: 49.0434565, lng: 22.2434843 },
    { lat: 49.0430985, lng: 22.2430065 },
    { lat: 49.0427477, lng: 22.242713 },
    { lat: 49.0426252, lng: 22.2430678 },
    { lat: 49.0426354, lng: 22.2433232 },
    { lat: 49.0422826, lng: 22.2441512 },
    { lat: 49.0422408, lng: 22.2446448 },
    { lat: 49.0415854, lng: 22.2448176 },
    { lat: 49.0411997, lng: 22.2456278 },
    { lat: 49.041012, lng: 22.2452707 },
    { lat: 49.0405003, lng: 22.2466517 },
    { lat: 49.0401091, lng: 22.246651 },
    { lat: 49.0400011, lng: 22.2469032 },
    { lat: 49.0397502, lng: 22.2468013 },
    { lat: 49.0396107, lng: 22.2471519 },
    { lat: 49.0391975, lng: 22.2473409 },
    { lat: 49.0391847, lng: 22.2479219 },
    { lat: 49.0387546, lng: 22.2478579 },
    { lat: 49.0387034, lng: 22.2461002 },
    { lat: 49.0383111, lng: 22.2457045 },
    { lat: 49.0379113, lng: 22.2455809 },
    { lat: 49.0375977, lng: 22.245892 },
    { lat: 49.0371835, lng: 22.246685 },
    { lat: 49.0369126, lng: 22.2469965 },
    { lat: 49.0367511, lng: 22.2474317 },
    { lat: 49.0366306, lng: 22.2476167 },
    { lat: 49.0364661, lng: 22.247552 },
    { lat: 49.036097, lng: 22.2479033 },
    { lat: 49.0359684, lng: 22.248493 },
    { lat: 49.0357224, lng: 22.2488111 },
    { lat: 49.0355675, lng: 22.248776 },
    { lat: 49.035448, lng: 22.2493187 },
    { lat: 49.0352844, lng: 22.2494432 },
    { lat: 49.0351902, lng: 22.2491072 },
    { lat: 49.0348942, lng: 22.2488933 },
    { lat: 49.0350203, lng: 22.2486919 },
    { lat: 49.0353219, lng: 22.2471286 },
    { lat: 49.0350198, lng: 22.2470044 },
    { lat: 49.0349685, lng: 22.2465589 },
    { lat: 49.0347375, lng: 22.2464945 },
    { lat: 49.0346439, lng: 22.2468326 },
    { lat: 49.0346503, lng: 22.2474291 },
    { lat: 49.0342355, lng: 22.2487558 },
    { lat: 49.0338371, lng: 22.2485934 },
    { lat: 49.0336212, lng: 22.2483371 },
    { lat: 49.0336184, lng: 22.2477508 },
    { lat: 49.0333774, lng: 22.2477887 },
    { lat: 49.0332328, lng: 22.2463498 },
    { lat: 49.0330686, lng: 22.2460862 },
    { lat: 49.0324884, lng: 22.2461526 },
    { lat: 49.0321758, lng: 22.2463084 },
    { lat: 49.0321924, lng: 22.2476075 },
    { lat: 49.0313264, lng: 22.2477086 },
    { lat: 49.0309063, lng: 22.2480405 },
    { lat: 49.0310442, lng: 22.247308 },
    { lat: 49.0310939, lng: 22.2462983 },
    { lat: 49.031086, lng: 22.2444787 },
    { lat: 49.0314471, lng: 22.2435244 },
    { lat: 49.0317095, lng: 22.2425783 },
    { lat: 49.0316788, lng: 22.2423103 },
    { lat: 49.032316, lng: 22.2409722 },
    { lat: 49.0330534, lng: 22.2404482 },
    { lat: 49.03363, lng: 22.2398229 },
    { lat: 49.0331106, lng: 22.2386664 },
    { lat: 49.0329818, lng: 22.238544 },
    { lat: 49.0331752, lng: 22.2376828 },
    { lat: 49.0333024, lng: 22.2373332 },
    { lat: 49.0331757, lng: 22.2367957 },
    { lat: 49.0330836, lng: 22.2359434 },
    { lat: 49.0331041, lng: 22.234799 },
    { lat: 49.032955, lng: 22.2324475 },
    { lat: 49.0328885, lng: 22.2303778 },
    { lat: 49.0332081, lng: 22.2294301 },
    { lat: 49.0339719, lng: 22.2286788 },
    { lat: 49.0341958, lng: 22.2277292 },
    { lat: 49.034377, lng: 22.2275295 },
    { lat: 49.0346822, lng: 22.2261384 },
    { lat: 49.0347266, lng: 22.2252586 },
    { lat: 49.0345949, lng: 22.2242496 },
    { lat: 49.0348048, lng: 22.223885 },
    { lat: 49.0354541, lng: 22.2224462 },
    { lat: 49.0356158, lng: 22.2218769 },
    { lat: 49.0359733, lng: 22.2210139 },
    { lat: 49.0374351, lng: 22.2190447 },
    { lat: 49.0376592, lng: 22.2188642 },
    { lat: 49.0376462, lng: 22.2187355 },
    { lat: 49.0386992, lng: 22.2182944 },
    { lat: 49.0391184, lng: 22.2179769 },
    { lat: 49.0404875, lng: 22.2180439 },
    { lat: 49.041653, lng: 22.2176568 },
    { lat: 49.0424634, lng: 22.2169397 },
    { lat: 49.0428196, lng: 22.216871 },
    { lat: 49.0429684, lng: 22.2156888 },
    { lat: 49.0435771, lng: 22.2150983 },
    { lat: 49.043437, lng: 22.2147173 },
    { lat: 49.0434045, lng: 22.2144243 },
    { lat: 49.043465, lng: 22.2132155 },
    { lat: 49.0437699, lng: 22.212616 },
    { lat: 49.0443365, lng: 22.2121545 },
    { lat: 49.0453672, lng: 22.21047 },
    { lat: 49.0450318, lng: 22.2100203 },
    { lat: 49.0438096, lng: 22.2082283 },
    { lat: 49.0428011, lng: 22.2063955 },
    { lat: 49.0410255, lng: 22.204315 },
    { lat: 49.0383253, lng: 22.2025276 },
    { lat: 49.0381701, lng: 22.2011872 },
    { lat: 49.0347074, lng: 22.2010949 },
    { lat: 49.0324723, lng: 22.1993873 },
    { lat: 49.0323672, lng: 22.1982193 },
    { lat: 49.0317798, lng: 22.1963615 },
    { lat: 49.0304596, lng: 22.1951234 },
    { lat: 49.0297622, lng: 22.19507 },
    { lat: 49.0291852, lng: 22.194726 },
    { lat: 49.0293407, lng: 22.1887866 },
    { lat: 49.0301393, lng: 22.1877956 },
    { lat: 49.0308033, lng: 22.1876123 },
    { lat: 49.0313295, lng: 22.1868704 },
    { lat: 49.0301279, lng: 22.1863066 },
    { lat: 49.0294622, lng: 22.1864548 },
    { lat: 49.0286547, lng: 22.1868062 },
    { lat: 49.0279658, lng: 22.1872707 },
    { lat: 49.0274939, lng: 22.1880377 },
    { lat: 49.0266231, lng: 22.1884762 },
    { lat: 49.0257285, lng: 22.1885987 },
    { lat: 49.0245886, lng: 22.1882317 },
    { lat: 49.0239497, lng: 22.188271 },
    { lat: 49.0228359, lng: 22.1884603 },
    { lat: 49.0221111, lng: 22.1884897 },
    { lat: 49.0215821, lng: 22.1895126 },
    { lat: 49.0214186, lng: 22.1901798 },
    { lat: 49.0209483, lng: 22.1905689 },
    { lat: 49.0202069, lng: 22.1909113 },
    { lat: 49.0192994, lng: 22.1906181 },
    { lat: 49.0187031, lng: 22.1907092 },
    { lat: 49.0183262, lng: 22.1911009 },
    { lat: 49.0176183, lng: 22.1913759 },
    { lat: 49.0173044, lng: 22.1916672 },
    { lat: 49.0168312, lng: 22.1913885 },
    { lat: 49.0149031, lng: 22.1914716 },
    { lat: 49.0143968, lng: 22.1919564 },
    { lat: 49.0140319, lng: 22.1928287 },
    { lat: 49.0130023, lng: 22.1937991 },
    { lat: 49.0119273, lng: 22.1946479 },
    { lat: 49.0104648, lng: 22.1945811 },
    { lat: 49.0100001, lng: 22.1951181 },
    { lat: 49.0096849, lng: 22.1956007 },
    { lat: 49.0081488, lng: 22.1975512 },
    { lat: 49.0070147, lng: 22.1989241 },
    { lat: 49.0055026, lng: 22.1996562 },
    { lat: 49.0034789, lng: 22.2003806 },
    { lat: 49.0030959, lng: 22.2004532 },
    { lat: 49.0023031, lng: 22.2001085 },
    { lat: 49.0001056, lng: 22.2001168 },
    { lat: 48.9995393, lng: 22.199598 },
    { lat: 48.9984014, lng: 22.1992122 },
    { lat: 48.9964131, lng: 22.1993211 },
    { lat: 48.9943, lng: 22.1987287 },
    { lat: 48.992138, lng: 22.1985175 },
    { lat: 48.9911011, lng: 22.1984689 },
    { lat: 48.9895746, lng: 22.1986561 },
    { lat: 48.988763, lng: 22.1980757 },
    { lat: 48.9875135, lng: 22.1996769 },
    { lat: 48.9864569, lng: 22.2006033 },
    { lat: 48.9863974, lng: 22.2008034 },
    { lat: 48.9859016, lng: 22.2005596 },
    { lat: 48.9844834, lng: 22.2002605 },
    { lat: 48.9846345, lng: 22.2010329 },
    { lat: 48.9836986, lng: 22.2009174 },
    { lat: 48.9832476, lng: 22.2006716 },
    { lat: 48.9814918, lng: 22.2049537 },
    { lat: 48.9818863, lng: 22.2061295 },
    { lat: 48.9791995, lng: 22.208282 },
    { lat: 48.9785326, lng: 22.2097402 },
    { lat: 48.9788299, lng: 22.2100989 },
    { lat: 48.9787489, lng: 22.2101571 },
    { lat: 48.9772085, lng: 22.2112652 },
    { lat: 48.9771693, lng: 22.2128716 },
    { lat: 48.9762807, lng: 22.2128701 },
    { lat: 48.9742273, lng: 22.2136384 },
    { lat: 48.9741529, lng: 22.2136933 },
    { lat: 48.9737184, lng: 22.2140135 },
    { lat: 48.9733962, lng: 22.2140829 },
    { lat: 48.9720726, lng: 22.2150498 },
    { lat: 48.9701612, lng: 22.2149736 },
    { lat: 48.9688141, lng: 22.2150803 },
    { lat: 48.9678029, lng: 22.2149825 },
    { lat: 48.9664857, lng: 22.2144698 },
    { lat: 48.9647283, lng: 22.2145439 },
    { lat: 48.9629755, lng: 22.2152766 },
    { lat: 48.9613803, lng: 22.2168025 },
    { lat: 48.96029, lng: 22.2169921 },
    { lat: 48.9599461, lng: 22.2169481 },
    { lat: 48.9590003, lng: 22.2166002 },
    { lat: 48.9541568, lng: 22.2155844 },
    { lat: 48.9522259, lng: 22.2152426 },
    { lat: 48.9502332, lng: 22.2146009 },
    { lat: 48.9484383, lng: 22.2141655 },
    { lat: 48.9450542, lng: 22.2135711 },
    { lat: 48.9424678, lng: 22.2133439 },
    { lat: 48.9412039, lng: 22.2131065 },
    { lat: 48.9400994, lng: 22.2127574 },
    { lat: 48.9395025, lng: 22.2120696 },
    { lat: 48.9389657, lng: 22.211758 },
    { lat: 48.9381024, lng: 22.2110297 },
    { lat: 48.936979, lng: 22.210238 },
    { lat: 48.9359249, lng: 22.2105772 },
    { lat: 48.9351062, lng: 22.2105789 },
    { lat: 48.9334763, lng: 22.2117235 },
    { lat: 48.9328277, lng: 22.2123821 },
    { lat: 48.9320585, lng: 22.2127837 },
    { lat: 48.9305684, lng: 22.2130427 },
    { lat: 48.929705, lng: 22.213554 },
    { lat: 48.929705, lng: 22.213641 },
    { lat: 48.9308765, lng: 22.2135465 },
    { lat: 48.9321074, lng: 22.2136579 },
    { lat: 48.9330258, lng: 22.213646 },
    { lat: 48.9337892, lng: 22.2138239 },
    { lat: 48.9346739, lng: 22.2137587 },
    { lat: 48.9357555, lng: 22.2139884 },
    { lat: 48.9365634, lng: 22.2137061 },
    { lat: 48.9378314, lng: 22.2136775 },
    { lat: 48.9390291, lng: 22.2139156 },
    { lat: 48.9403884, lng: 22.2139509 },
    { lat: 48.9409986, lng: 22.2143694 },
    { lat: 48.9413222, lng: 22.2145071 },
    { lat: 48.9414651, lng: 22.2146665 },
    { lat: 48.9416672, lng: 22.2150495 },
    { lat: 48.9419445, lng: 22.2150749 },
    { lat: 48.9423581, lng: 22.2154105 },
    { lat: 48.9428988, lng: 22.2156529 },
    { lat: 48.9429963, lng: 22.2158787 },
    { lat: 48.9431933, lng: 22.2159418 },
    { lat: 48.9433647, lng: 22.2161118 },
    { lat: 48.9436614, lng: 22.216047 },
    { lat: 48.944296, lng: 22.2163179 },
    { lat: 48.944485, lng: 22.216316 },
    { lat: 48.9448121, lng: 22.2164482 },
    { lat: 48.9450709, lng: 22.2164073 },
    { lat: 48.9454183, lng: 22.2165542 },
    { lat: 48.9455928, lng: 22.2165588 },
    { lat: 48.9460061, lng: 22.2167864 },
    { lat: 48.9461577, lng: 22.2169757 },
    { lat: 48.9464398, lng: 22.2171138 },
    { lat: 48.9466362, lng: 22.217094 },
    { lat: 48.9472057, lng: 22.2176653 },
    { lat: 48.9475267, lng: 22.2176862 },
    { lat: 48.9476853, lng: 22.2177395 },
    { lat: 48.9477606, lng: 22.2178784 },
    { lat: 48.9479588, lng: 22.21789 },
    { lat: 48.9481259, lng: 22.2181203 },
    { lat: 48.948291, lng: 22.2181179 },
    { lat: 48.9483871, lng: 22.218188 },
    { lat: 48.9489733, lng: 22.2181469 },
    { lat: 48.9493358, lng: 22.218262 },
    { lat: 48.9497262, lng: 22.2186143 },
    { lat: 48.9498966, lng: 22.2188455 },
    { lat: 48.9502012, lng: 22.2188806 },
    { lat: 48.9506106, lng: 22.2190827 },
    { lat: 48.9506662, lng: 22.2192054 },
    { lat: 48.9511438, lng: 22.2192841 },
    { lat: 48.9512182, lng: 22.2194495 },
    { lat: 48.9513652, lng: 22.2194358 },
    { lat: 48.9514367, lng: 22.2196586 },
    { lat: 48.9516167, lng: 22.2198669 },
    { lat: 48.9517478, lng: 22.2198357 },
    { lat: 48.9519548, lng: 22.2198941 },
    { lat: 48.9520911, lng: 22.2202553 },
    { lat: 48.9522135, lng: 22.220445 },
    { lat: 48.9524357, lng: 22.2201712 },
    { lat: 48.9525971, lng: 22.2204438 },
    { lat: 48.9526772, lng: 22.2204114 },
    { lat: 48.9527304, lng: 22.2205228 },
    { lat: 48.9530662, lng: 22.2207502 },
    { lat: 48.9533267, lng: 22.2208784 },
    { lat: 48.9536292, lng: 22.2208849 },
    { lat: 48.9539038, lng: 22.2213392 },
    { lat: 48.9540758, lng: 22.2212326 },
    { lat: 48.9543683, lng: 22.2216941 },
    { lat: 48.9544524, lng: 22.2216307 },
    { lat: 48.9545392, lng: 22.2216831 },
    { lat: 48.9551706, lng: 22.2217073 },
    { lat: 48.9560084, lng: 22.2215996 },
    { lat: 48.9560838, lng: 22.2216068 },
    { lat: 48.956161, lng: 22.2217406 },
    { lat: 48.9563262, lng: 22.2216325 },
    { lat: 48.9565971, lng: 22.2218683 },
    { lat: 48.9566197, lng: 22.2220783 },
    { lat: 48.9569637, lng: 22.222325 },
    { lat: 48.9572158, lng: 22.2222289 },
    { lat: 48.9573013, lng: 22.2223311 },
    { lat: 48.9574978, lng: 22.2223693 },
    { lat: 48.9577208, lng: 22.2221849 },
    { lat: 48.9578233, lng: 22.2223388 },
    { lat: 48.958011, lng: 22.2224805 },
    { lat: 48.9580488, lng: 22.2226128 },
    { lat: 48.9581996, lng: 22.222664 },
    { lat: 48.958372, lng: 22.2225846 },
    { lat: 48.9584798, lng: 22.2227229 },
    { lat: 48.9586222, lng: 22.2225574 },
    { lat: 48.9589627, lng: 22.2224932 },
    { lat: 48.9590199, lng: 22.2226352 },
    { lat: 48.9591637, lng: 22.222627 },
    { lat: 48.9592948, lng: 22.2227541 },
    { lat: 48.9593676, lng: 22.2225446 },
    { lat: 48.959572, lng: 22.222529 },
    { lat: 48.9596969, lng: 22.222724 },
    { lat: 48.9600226, lng: 22.2228621 },
    { lat: 48.9601655, lng: 22.2232667 },
    { lat: 48.9602655, lng: 22.223312 },
    { lat: 48.9604225, lng: 22.2231868 },
    { lat: 48.9605117, lng: 22.2229614 },
    { lat: 48.9606937, lng: 22.222792 },
    { lat: 48.9610292, lng: 22.2227585 },
    { lat: 48.9612041, lng: 22.2226317 },
    { lat: 48.9612629, lng: 22.2227951 },
    { lat: 48.961506, lng: 22.222906 },
    { lat: 48.9616019, lng: 22.2232737 },
    { lat: 48.9619894, lng: 22.223453 },
    { lat: 48.9620836, lng: 22.2236894 },
    { lat: 48.962364, lng: 22.2238961 },
    { lat: 48.9624544, lng: 22.224124 },
    { lat: 48.9625394, lng: 22.2241511 },
    { lat: 48.9628969, lng: 22.2239433 },
    { lat: 48.9631045, lng: 22.2236767 },
    { lat: 48.9633715, lng: 22.2235905 },
    { lat: 48.9634915, lng: 22.2236756 },
    { lat: 48.9638145, lng: 22.2236389 },
    { lat: 48.9639177, lng: 22.2237216 },
    { lat: 48.9645841, lng: 22.2236037 },
    { lat: 48.9647249, lng: 22.2237554 },
    { lat: 48.9648001, lng: 22.2237565 },
    { lat: 48.9648755, lng: 22.2236516 },
    { lat: 48.9650333, lng: 22.2237688 },
    { lat: 48.9652359, lng: 22.2237232 },
    { lat: 48.9652713, lng: 22.2238026 },
    { lat: 48.9655595, lng: 22.2237165 },
    { lat: 48.965574, lng: 22.2239098 },
    { lat: 48.965775, lng: 22.2240991 },
    { lat: 48.9659279, lng: 22.2240937 },
    { lat: 48.9661086, lng: 22.2242683 },
    { lat: 48.9661779, lng: 22.2241552 },
    { lat: 48.9662855, lng: 22.2242366 },
    { lat: 48.9664147, lng: 22.2240267 },
    { lat: 48.9666061, lng: 22.2239416 },
    { lat: 48.9666908, lng: 22.2236541 },
    { lat: 48.9669192, lng: 22.2234135 },
    { lat: 48.9671871, lng: 22.2234723 },
    { lat: 48.9673066, lng: 22.2233781 },
    { lat: 48.9675624, lng: 22.2233487 },
    { lat: 48.9676437, lng: 22.2234648 },
    { lat: 48.9677395, lng: 22.223428 },
    { lat: 48.9679775, lng: 22.2236043 },
    { lat: 48.9681915, lng: 22.2235747 },
    { lat: 48.9686195, lng: 22.2233449 },
    { lat: 48.9687455, lng: 22.2231927 },
    { lat: 48.9696843, lng: 22.2234395 },
    { lat: 48.9697984, lng: 22.2233566 },
    { lat: 48.9701393, lng: 22.223509 },
    { lat: 48.9705191, lng: 22.223397 },
    { lat: 48.9705717, lng: 22.2231877 },
    { lat: 48.9711486, lng: 22.2248909 },
    { lat: 48.9714266, lng: 22.2250953 },
    { lat: 48.9723329, lng: 22.2267929 },
    { lat: 48.9726621, lng: 22.2275306 },
    { lat: 48.9731602, lng: 22.2293346 },
    { lat: 48.9732364, lng: 22.2303284 },
    { lat: 48.9731329, lng: 22.2314876 },
    { lat: 48.9738805, lng: 22.2325206 },
    { lat: 48.9742702, lng: 22.2333071 },
    { lat: 48.9746661, lng: 22.2338677 },
    { lat: 48.9750398, lng: 22.2348312 },
    { lat: 48.9766985, lng: 22.2380432 },
    { lat: 48.9775193, lng: 22.2390987 },
    { lat: 48.9782567, lng: 22.2405449 },
    { lat: 48.9787951, lng: 22.2417918 },
    { lat: 48.978917, lng: 22.2427247 },
    { lat: 48.9788523, lng: 22.2445189 },
    { lat: 48.979145, lng: 22.2465977 },
    { lat: 48.9792343, lng: 22.2484428 },
    { lat: 48.9790996, lng: 22.2492245 },
    { lat: 48.9790341, lng: 22.2504771 },
    { lat: 48.9790807, lng: 22.2516655 },
    { lat: 48.9792368, lng: 22.2523067 },
    { lat: 48.9787877, lng: 22.2541198 },
    { lat: 48.9781204, lng: 22.2563895 },
    { lat: 48.978115, lng: 22.2573625 },
    { lat: 48.9781779, lng: 22.2579766 },
    { lat: 48.9781986, lng: 22.2589576 },
    { lat: 48.9782736, lng: 22.2595023 },
    { lat: 48.9784436, lng: 22.2599225 },
    { lat: 48.978622, lng: 22.2611694 },
    { lat: 48.978911, lng: 22.2617265 },
    { lat: 48.9792444, lng: 22.2621624 },
    { lat: 48.9790772, lng: 22.2630428 },
    { lat: 48.9788088, lng: 22.2635648 },
    { lat: 48.978834, lng: 22.2639911 },
    { lat: 48.9782634, lng: 22.2651991 },
    { lat: 48.9777741, lng: 22.2652487 },
    { lat: 48.9773285, lng: 22.2658496 },
    { lat: 48.9765094, lng: 22.2667011 },
    { lat: 48.9760732, lng: 22.2677297 },
    { lat: 48.9753324, lng: 22.2686664 },
    { lat: 48.9743366, lng: 22.2701837 },
    { lat: 48.9738996, lng: 22.2712302 },
    { lat: 48.9738192, lng: 22.2722248 },
    { lat: 48.9736664, lng: 22.2726912 },
    { lat: 48.9733001, lng: 22.2731619 },
    { lat: 48.9728329, lng: 22.2736073 },
    { lat: 48.9722612, lng: 22.2748173 },
    { lat: 48.9722482, lng: 22.2764787 },
    { lat: 48.9718687, lng: 22.2777312 },
    { lat: 48.9717491, lng: 22.2784378 },
    { lat: 48.9711301, lng: 22.2808272 },
    { lat: 48.9707249, lng: 22.281685 },
    { lat: 48.9719858, lng: 22.2847171 },
    { lat: 48.9719242, lng: 22.2854985 },
    { lat: 48.9716413, lng: 22.2864417 },
    { lat: 48.9720139, lng: 22.2873937 },
    { lat: 48.9715884, lng: 22.2883975 },
    { lat: 48.9724563, lng: 22.2889459 },
    { lat: 48.9767476, lng: 22.2887788 },
    { lat: 48.9809931, lng: 22.2875862 },
    { lat: 48.9807806, lng: 22.2853301 },
    { lat: 48.9817759, lng: 22.2843503 },
    { lat: 48.9819979, lng: 22.2843208 },
    { lat: 48.9823972, lng: 22.2840096 },
    { lat: 48.9829252, lng: 22.2837773 },
    { lat: 48.9836107, lng: 22.2838071 },
    { lat: 48.984229, lng: 22.2831235 },
    { lat: 48.9845052, lng: 22.283102 },
    { lat: 48.985713, lng: 22.2824333 },
    { lat: 48.9860231, lng: 22.2825157 },
    { lat: 48.9861798, lng: 22.2810529 },
    { lat: 48.9867402, lng: 22.2788624 },
    { lat: 48.9877419, lng: 22.2754599 },
    { lat: 48.9885222, lng: 22.2739177 },
    { lat: 48.988838, lng: 22.2731869 },
    { lat: 48.9890897, lng: 22.2716435 },
    { lat: 48.9893631, lng: 22.2703723 },
    { lat: 48.9894206, lng: 22.2698111 },
    { lat: 48.990111, lng: 22.2686831 },
    { lat: 48.9908808, lng: 22.2680971 },
    { lat: 48.9918024, lng: 22.2669493 },
    { lat: 48.9923047, lng: 22.266879 },
    { lat: 48.9937282, lng: 22.2671128 },
    { lat: 48.9949026, lng: 22.2672221 },
    { lat: 48.9955577, lng: 22.2669971 },
    { lat: 48.9959909, lng: 22.2670059 },
    { lat: 48.9963524, lng: 22.2671343 },
    { lat: 48.9963373, lng: 22.2661906 },
    { lat: 48.9966211, lng: 22.2653947 },
    { lat: 48.9971719, lng: 22.265127 },
    { lat: 48.9978625, lng: 22.2650227 },
    { lat: 48.9997923, lng: 22.263842 },
    { lat: 49.0000766, lng: 22.2635782 },
    { lat: 49.0003741, lng: 22.2630934 },
    { lat: 49.0005955, lng: 22.2628805 },
    { lat: 49.0008284, lng: 22.2627873 },
    { lat: 49.0012083, lng: 22.2627257 },
    { lat: 49.0015723, lng: 22.2642882 },
    { lat: 49.0032734, lng: 22.2636044 },
    { lat: 49.0039196, lng: 22.2632219 },
    { lat: 49.0047536, lng: 22.2636035 },
    { lat: 49.0048881, lng: 22.2634824 },
    { lat: 49.0055326, lng: 22.263692 },
    { lat: 49.0057034, lng: 22.2631238 },
    { lat: 49.006692, lng: 22.2631755 },
    { lat: 49.0073113, lng: 22.2638683 },
    { lat: 49.0074004, lng: 22.2635266 },
    { lat: 49.0080825, lng: 22.264467 },
    { lat: 49.0087153, lng: 22.2658796 },
    { lat: 49.0090811, lng: 22.2661497 },
    { lat: 49.0095891, lng: 22.2669453 },
    { lat: 49.011362, lng: 22.2690108 },
    { lat: 49.0124421, lng: 22.2697935 },
    { lat: 49.0133245, lng: 22.2700284 },
    { lat: 49.013897, lng: 22.2699807 },
    { lat: 49.0144552, lng: 22.2709702 },
    { lat: 49.0148718, lng: 22.2709895 },
    { lat: 49.015852, lng: 22.2715384 },
    { lat: 49.0165901, lng: 22.2717507 },
    { lat: 49.0170509, lng: 22.271991 },
    { lat: 49.0178908, lng: 22.272652 },
    { lat: 49.0193023, lng: 22.2752631 },
    { lat: 49.020645, lng: 22.2761273 },
    { lat: 49.0212562, lng: 22.2763006 },
    { lat: 49.0218323, lng: 22.2763717 },
    { lat: 49.0228286, lng: 22.2772786 },
    { lat: 49.0232912, lng: 22.2779124 },
    { lat: 49.0236157, lng: 22.2781497 },
    { lat: 49.024123, lng: 22.2786704 },
    { lat: 49.0247251, lng: 22.2791947 },
    { lat: 49.0251058, lng: 22.2792234 },
    { lat: 49.0263157, lng: 22.2803386 },
    { lat: 49.0263103, lng: 22.2807162 },
    { lat: 49.0267408, lng: 22.2811632 },
    { lat: 49.0274454, lng: 22.2814404 },
    { lat: 49.0286668, lng: 22.2839675 },
    { lat: 49.0293106, lng: 22.2851725 },
    { lat: 49.0297673, lng: 22.2858429 },
    { lat: 49.0300015, lng: 22.2872001 },
    { lat: 49.0303669, lng: 22.2882157 },
    { lat: 49.0305591, lng: 22.2882138 },
    { lat: 49.0310512, lng: 22.2889671 },
    { lat: 49.0311357, lng: 22.2895907 },
    { lat: 49.03145, lng: 22.2904394 },
    { lat: 49.0319214, lng: 22.2904984 },
    { lat: 49.0317803, lng: 22.2910417 },
    { lat: 49.0319471, lng: 22.2919339 },
    { lat: 49.0318322, lng: 22.2933662 },
    { lat: 49.0318318, lng: 22.2944386 },
    { lat: 49.0319224, lng: 22.2954945 },
    { lat: 49.0323478, lng: 22.2965473 },
    { lat: 49.0325599, lng: 22.2974011 },
    { lat: 49.0330401, lng: 22.2983834 },
    { lat: 49.0338117, lng: 22.2990549 },
    { lat: 49.0341639, lng: 22.2992243 },
    { lat: 49.0346455, lng: 22.2991345 },
    { lat: 49.0349101, lng: 22.2990968 },
    { lat: 49.035345, lng: 22.2986699 },
    { lat: 49.0360446, lng: 22.2973766 },
    { lat: 49.036259, lng: 22.2972515 },
  ],
  Osadné: [
    { lat: 49.1841032, lng: 22.1697568 },
    { lat: 49.1842576, lng: 22.1684377 },
    { lat: 49.1843516, lng: 22.1672987 },
    { lat: 49.1845056, lng: 22.1665467 },
    { lat: 49.1849058, lng: 22.1656926 },
    { lat: 49.1855179, lng: 22.1649779 },
    { lat: 49.1859689, lng: 22.1637935 },
    { lat: 49.186011, lng: 22.1626835 },
    { lat: 49.1862897, lng: 22.1626276 },
    { lat: 49.1865989, lng: 22.1621717 },
    { lat: 49.186833, lng: 22.1620185 },
    { lat: 49.1868396, lng: 22.1617365 },
    { lat: 49.1872467, lng: 22.1608999 },
    { lat: 49.1879272, lng: 22.1603064 },
    { lat: 49.1881904, lng: 22.1600177 },
    { lat: 49.188205, lng: 22.1597664 },
    { lat: 49.1880614, lng: 22.1587552 },
    { lat: 49.1882148, lng: 22.1580828 },
    { lat: 49.188235, lng: 22.157926 },
    { lat: 49.188232, lng: 22.1566804 },
    { lat: 49.1882243, lng: 22.1564825 },
    { lat: 49.188261, lng: 22.1559 },
    { lat: 49.188311, lng: 22.1551724 },
    { lat: 49.1886156, lng: 22.1538328 },
    { lat: 49.188559, lng: 22.153587 },
    { lat: 49.18838, lng: 22.153351 },
    { lat: 49.188297, lng: 22.153109 },
    { lat: 49.188184, lng: 22.152632 },
    { lat: 49.187951, lng: 22.152041 },
    { lat: 49.187875, lng: 22.151721 },
    { lat: 49.187759, lng: 22.151526 },
    { lat: 49.18743, lng: 22.151381 },
    { lat: 49.187361, lng: 22.151048 },
    { lat: 49.1872, lng: 22.150741 },
    { lat: 49.186977, lng: 22.150649 },
    { lat: 49.186659, lng: 22.150643 },
    { lat: 49.186523, lng: 22.150442 },
    { lat: 49.186494, lng: 22.15042 },
    { lat: 49.186145, lng: 22.150428 },
    { lat: 49.185815, lng: 22.150592 },
    { lat: 49.185508, lng: 22.150791 },
    { lat: 49.185137, lng: 22.150911 },
    { lat: 49.184951, lng: 22.150757 },
    { lat: 49.18457, lng: 22.150774 },
    { lat: 49.184286, lng: 22.150908 },
    { lat: 49.184117, lng: 22.150778 },
    { lat: 49.183823, lng: 22.150267 },
    { lat: 49.183418, lng: 22.150016 },
    { lat: 49.182874, lng: 22.149809 },
    { lat: 49.182732, lng: 22.149602 },
    { lat: 49.182127, lng: 22.149721 },
    { lat: 49.181424, lng: 22.149524 },
    { lat: 49.181079, lng: 22.149271 },
    { lat: 49.180736, lng: 22.14912 },
    { lat: 49.18036, lng: 22.149104 },
    { lat: 49.180043, lng: 22.14892 },
    { lat: 49.179812, lng: 22.148865 },
    { lat: 49.179408, lng: 22.148985 },
    { lat: 49.179367, lng: 22.148552 },
    { lat: 49.179292, lng: 22.148225 },
    { lat: 49.178953, lng: 22.147807 },
    { lat: 49.178942, lng: 22.147856 },
    { lat: 49.178932, lng: 22.147959 },
    { lat: 49.178859, lng: 22.148159 },
    { lat: 49.178753, lng: 22.148284 },
    { lat: 49.178315, lng: 22.147468 },
    { lat: 49.178157, lng: 22.147236 },
    { lat: 49.178034, lng: 22.146955 },
    { lat: 49.177925, lng: 22.146741 },
    { lat: 49.177845, lng: 22.146567 },
    { lat: 49.177751, lng: 22.146386 },
    { lat: 49.177656, lng: 22.146163 },
    { lat: 49.177495, lng: 22.146029 },
    { lat: 49.177467, lng: 22.145386 },
    { lat: 49.177643, lng: 22.144636 },
    { lat: 49.177893, lng: 22.144135 },
    { lat: 49.177769, lng: 22.143243 },
    { lat: 49.177756, lng: 22.142688 },
    { lat: 49.177528, lng: 22.142123 },
    { lat: 49.177109, lng: 22.141616 },
    { lat: 49.176871, lng: 22.141385 },
    { lat: 49.176576, lng: 22.141012 },
    { lat: 49.176234, lng: 22.140466 },
    { lat: 49.175718, lng: 22.139953 },
    { lat: 49.17555, lng: 22.1396 },
    { lat: 49.175514, lng: 22.139286 },
    { lat: 49.175371, lng: 22.138506 },
    { lat: 49.175007, lng: 22.137931 },
    { lat: 49.17457, lng: 22.137709 },
    { lat: 49.174175, lng: 22.137777 },
    { lat: 49.173862, lng: 22.137927 },
    { lat: 49.172919, lng: 22.138304 },
    { lat: 49.172613, lng: 22.138263 },
    { lat: 49.172196, lng: 22.13807 },
    { lat: 49.171693, lng: 22.13796 },
    { lat: 49.171075, lng: 22.137934 },
    { lat: 49.170628, lng: 22.137861 },
    { lat: 49.170003, lng: 22.138034 },
    { lat: 49.169787, lng: 22.138184 },
    { lat: 49.169587, lng: 22.138225 },
    { lat: 49.169172, lng: 22.138263 },
    { lat: 49.168958, lng: 22.138087 },
    { lat: 49.168938, lng: 22.137727 },
    { lat: 49.168948, lng: 22.137242 },
    { lat: 49.168937, lng: 22.13664 },
    { lat: 49.168745, lng: 22.136511 },
    { lat: 49.168773, lng: 22.135617 },
    { lat: 49.168738, lng: 22.135342 },
    { lat: 49.168489, lng: 22.135353 },
    { lat: 49.168134, lng: 22.13528 },
    { lat: 49.167788, lng: 22.135307 },
    { lat: 49.16738, lng: 22.135284 },
    { lat: 49.166956, lng: 22.13543 },
    { lat: 49.166789, lng: 22.135535 },
    { lat: 49.166197, lng: 22.135592 },
    { lat: 49.165541, lng: 22.135238 },
    { lat: 49.165379, lng: 22.13517 },
    { lat: 49.165094, lng: 22.13492 },
    { lat: 49.164945, lng: 22.134811 },
    { lat: 49.164536, lng: 22.134628 },
    { lat: 49.164138, lng: 22.134708 },
    { lat: 49.16349, lng: 22.134433 },
    { lat: 49.163099, lng: 22.134045 },
    { lat: 49.162663, lng: 22.133728 },
    { lat: 49.162273, lng: 22.133747 },
    { lat: 49.1622, lng: 22.133743 },
    { lat: 49.161097, lng: 22.133661 },
    { lat: 49.160704, lng: 22.133922 },
    { lat: 49.160313, lng: 22.134026 },
    { lat: 49.160072, lng: 22.133895 },
    { lat: 49.15996, lng: 22.133493 },
    { lat: 49.15983, lng: 22.133337 },
    { lat: 49.159866, lng: 22.133274 },
    { lat: 49.15984, lng: 22.133161 },
    { lat: 49.159864, lng: 22.132993 },
    { lat: 49.1597, lng: 22.132842 },
    { lat: 49.159582, lng: 22.132662 },
    { lat: 49.159527, lng: 22.13248 },
    { lat: 49.159543, lng: 22.132422 },
    { lat: 49.159482, lng: 22.132341 },
    { lat: 49.159508, lng: 22.132245 },
    { lat: 49.159441, lng: 22.132066 },
    { lat: 49.159415, lng: 22.131969 },
    { lat: 49.159093, lng: 22.131658 },
    { lat: 49.158917, lng: 22.131583 },
    { lat: 49.158861, lng: 22.131505 },
    { lat: 49.158826, lng: 22.131534 },
    { lat: 49.158617, lng: 22.131298 },
    { lat: 49.158227, lng: 22.130305 },
    { lat: 49.158151, lng: 22.129831 },
    { lat: 49.158167, lng: 22.129222 },
    { lat: 49.158075, lng: 22.128842 },
    { lat: 49.157891, lng: 22.128644 },
    { lat: 49.157636, lng: 22.128565 },
    { lat: 49.15759, lng: 22.128379 },
    { lat: 49.157612, lng: 22.127899 },
    { lat: 49.155179, lng: 22.128189 },
    { lat: 49.153898, lng: 22.127433 },
    { lat: 49.152962, lng: 22.126962 },
    { lat: 49.151833, lng: 22.126555 },
    { lat: 49.150014, lng: 22.126978 },
    { lat: 49.14855, lng: 22.128039 },
    { lat: 49.146454, lng: 22.128302 },
    { lat: 49.145144, lng: 22.128323 },
    { lat: 49.143926, lng: 22.12784 },
    { lat: 49.142995, lng: 22.128448 },
    { lat: 49.141103, lng: 22.12828 },
    { lat: 49.140382, lng: 22.127688 },
    { lat: 49.140269, lng: 22.1289248 },
    { lat: 49.1396791, lng: 22.1303596 },
    { lat: 49.1386444, lng: 22.1320064 },
    { lat: 49.1374968, lng: 22.1332555 },
    { lat: 49.1365787, lng: 22.1347006 },
    { lat: 49.1366458, lng: 22.1354218 },
    { lat: 49.1365915, lng: 22.1369592 },
    { lat: 49.1356763, lng: 22.1398037 },
    { lat: 49.1348592, lng: 22.1429934 },
    { lat: 49.1331999, lng: 22.1437171 },
    { lat: 49.1324209, lng: 22.1445619 },
    { lat: 49.132343, lng: 22.1453335 },
    { lat: 49.1331812, lng: 22.1461281 },
    { lat: 49.1340182, lng: 22.1473652 },
    { lat: 49.1338477, lng: 22.1479644 },
    { lat: 49.1338383, lng: 22.1481373 },
    { lat: 49.1328807, lng: 22.1478311 },
    { lat: 49.1321386, lng: 22.1497505 },
    { lat: 49.1314005, lng: 22.151986 },
    { lat: 49.1296337, lng: 22.1558632 },
    { lat: 49.1283518, lng: 22.1576922 },
    { lat: 49.1288617, lng: 22.1585389 },
    { lat: 49.127984, lng: 22.1600414 },
    { lat: 49.1294086, lng: 22.1621416 },
    { lat: 49.1321013, lng: 22.1638267 },
    { lat: 49.1311474, lng: 22.165441 },
    { lat: 49.1320975, lng: 22.1664669 },
    { lat: 49.1351853, lng: 22.1665898 },
    { lat: 49.1351645, lng: 22.1716936 },
    { lat: 49.1376635, lng: 22.174308 },
    { lat: 49.1375426, lng: 22.1759124 },
    { lat: 49.13748, lng: 22.1787579 },
    { lat: 49.1377153, lng: 22.1789556 },
    { lat: 49.1388172, lng: 22.1802793 },
    { lat: 49.1394995, lng: 22.1809167 },
    { lat: 49.1400349, lng: 22.181215 },
    { lat: 49.1408157, lng: 22.1817665 },
    { lat: 49.1414817, lng: 22.1828266 },
    { lat: 49.1417872, lng: 22.1826514 },
    { lat: 49.1423771, lng: 22.1818829 },
    { lat: 49.143427, lng: 22.1830065 },
    { lat: 49.1439051, lng: 22.1829612 },
    { lat: 49.1442794, lng: 22.1827525 },
    { lat: 49.1442817, lng: 22.1827006 },
    { lat: 49.1450812, lng: 22.1824986 },
    { lat: 49.1453519, lng: 22.1825332 },
    { lat: 49.1455843, lng: 22.1826596 },
    { lat: 49.1458403, lng: 22.1829975 },
    { lat: 49.1461061, lng: 22.183213 },
    { lat: 49.1462342, lng: 22.1834994 },
    { lat: 49.1466084, lng: 22.18408 },
    { lat: 49.1467591, lng: 22.1842297 },
    { lat: 49.1468296, lng: 22.1844578 },
    { lat: 49.1472639, lng: 22.1850711 },
    { lat: 49.147335, lng: 22.185381 },
    { lat: 49.147498, lng: 22.1855754 },
    { lat: 49.1483619, lng: 22.1870704 },
    { lat: 49.1551183, lng: 22.1912751 },
    { lat: 49.1556335, lng: 22.1918179 },
    { lat: 49.1560358, lng: 22.1921517 },
    { lat: 49.1561284, lng: 22.1917247 },
    { lat: 49.1561035, lng: 22.191574 },
    { lat: 49.1561583, lng: 22.1912538 },
    { lat: 49.1562017, lng: 22.1911402 },
    { lat: 49.1563243, lng: 22.1910399 },
    { lat: 49.1565279, lng: 22.1905916 },
    { lat: 49.1567934, lng: 22.1897534 },
    { lat: 49.1569254, lng: 22.1896167 },
    { lat: 49.1570795, lng: 22.1891692 },
    { lat: 49.1571188, lng: 22.1888742 },
    { lat: 49.1572356, lng: 22.1886636 },
    { lat: 49.1574597, lng: 22.1887367 },
    { lat: 49.1575765, lng: 22.1885764 },
    { lat: 49.1575405, lng: 22.1882254 },
    { lat: 49.1576824, lng: 22.1878935 },
    { lat: 49.1576952, lng: 22.1873624 },
    { lat: 49.1578927, lng: 22.1872899 },
    { lat: 49.1580658, lng: 22.1870907 },
    { lat: 49.1582857, lng: 22.1870698 },
    { lat: 49.1584851, lng: 22.1869357 },
    { lat: 49.1588395, lng: 22.1865368 },
    { lat: 49.1589998, lng: 22.1864655 },
    { lat: 49.1589705, lng: 22.1862633 },
    { lat: 49.1590099, lng: 22.186216 },
    { lat: 49.1592591, lng: 22.1861486 },
    { lat: 49.1592418, lng: 22.1860259 },
    { lat: 49.1593605, lng: 22.1859528 },
    { lat: 49.159423, lng: 22.1857335 },
    { lat: 49.1594594, lng: 22.1852084 },
    { lat: 49.1593975, lng: 22.1850061 },
    { lat: 49.1597601, lng: 22.1846273 },
    { lat: 49.1599126, lng: 22.1846075 },
    { lat: 49.1599029, lng: 22.1842813 },
    { lat: 49.159962, lng: 22.1839185 },
    { lat: 49.1600269, lng: 22.1838493 },
    { lat: 49.1601063, lng: 22.1838764 },
    { lat: 49.1601048, lng: 22.1841126 },
    { lat: 49.1601878, lng: 22.1841998 },
    { lat: 49.1603387, lng: 22.1837122 },
    { lat: 49.160751, lng: 22.1846323 },
    { lat: 49.1608779, lng: 22.1846252 },
    { lat: 49.1610881, lng: 22.1852204 },
    { lat: 49.160989, lng: 22.1861495 },
    { lat: 49.1609563, lng: 22.1870916 },
    { lat: 49.1610363, lng: 22.1873425 },
    { lat: 49.1611807, lng: 22.1873719 },
    { lat: 49.1612033, lng: 22.1876223 },
    { lat: 49.1611291, lng: 22.1880208 },
    { lat: 49.1610623, lng: 22.188098 },
    { lat: 49.1611525, lng: 22.1883113 },
    { lat: 49.1612875, lng: 22.1884149 },
    { lat: 49.1614317, lng: 22.1887153 },
    { lat: 49.1620625, lng: 22.1891094 },
    { lat: 49.1621778, lng: 22.189435 },
    { lat: 49.1621639, lng: 22.1896628 },
    { lat: 49.1620884, lng: 22.1897631 },
    { lat: 49.1618653, lng: 22.1897878 },
    { lat: 49.1618654, lng: 22.1900422 },
    { lat: 49.1619959, lng: 22.1905931 },
    { lat: 49.1619634, lng: 22.1908039 },
    { lat: 49.1617963, lng: 22.1909173 },
    { lat: 49.1616574, lng: 22.1911926 },
    { lat: 49.1615714, lng: 22.1915709 },
    { lat: 49.1617262, lng: 22.1919451 },
    { lat: 49.1619379, lng: 22.1922078 },
    { lat: 49.1621617, lng: 22.1930499 },
    { lat: 49.1624058, lng: 22.1933062 },
    { lat: 49.1625065, lng: 22.1937814 },
    { lat: 49.1626074, lng: 22.1940089 },
    { lat: 49.1625726, lng: 22.1940683 },
    { lat: 49.1625263, lng: 22.1949587 },
    { lat: 49.1625768, lng: 22.1952428 },
    { lat: 49.1629731, lng: 22.1968165 },
    { lat: 49.1631746, lng: 22.1971196 },
    { lat: 49.1632713, lng: 22.1973622 },
    { lat: 49.1634528, lng: 22.1975345 },
    { lat: 49.1633072, lng: 22.1982677 },
    { lat: 49.1633317, lng: 22.1985481 },
    { lat: 49.1635152, lng: 22.1987117 },
    { lat: 49.1636272, lng: 22.1991338 },
    { lat: 49.163818, lng: 22.1993679 },
    { lat: 49.163867, lng: 22.1996521 },
    { lat: 49.1640635, lng: 22.1998373 },
    { lat: 49.16428, lng: 22.2001594 },
    { lat: 49.1642447, lng: 22.2002425 },
    { lat: 49.164387, lng: 22.2004607 },
    { lat: 49.1645865, lng: 22.2012305 },
    { lat: 49.1646168, lng: 22.2012238 },
    { lat: 49.1646156, lng: 22.2016224 },
    { lat: 49.1644872, lng: 22.2019713 },
    { lat: 49.1644516, lng: 22.2023365 },
    { lat: 49.164544, lng: 22.2029447 },
    { lat: 49.1648733, lng: 22.2032283 },
    { lat: 49.1650367, lng: 22.2032952 },
    { lat: 49.1652309, lng: 22.203275 },
    { lat: 49.1653325, lng: 22.203495 },
    { lat: 49.1653722, lng: 22.2039109 },
    { lat: 49.1656359, lng: 22.2042892 },
    { lat: 49.1657786, lng: 22.2046281 },
    { lat: 49.1659932, lng: 22.2049677 },
    { lat: 49.1662019, lng: 22.205554 },
    { lat: 49.1663027, lng: 22.2061374 },
    { lat: 49.1664717, lng: 22.206493 },
    { lat: 49.1664802, lng: 22.2068214 },
    { lat: 49.1665899, lng: 22.2071514 },
    { lat: 49.1670027, lng: 22.2077562 },
    { lat: 49.167397, lng: 22.2085838 },
    { lat: 49.1678346, lng: 22.209698 },
    { lat: 49.1678981, lng: 22.210214 },
    { lat: 49.1680259, lng: 22.2103957 },
    { lat: 49.1682396, lng: 22.2109509 },
    { lat: 49.1683802, lng: 22.2111039 },
    { lat: 49.1684864, lng: 22.2113233 },
    { lat: 49.1685676, lng: 22.2117367 },
    { lat: 49.1687333, lng: 22.2122036 },
    { lat: 49.1687835, lng: 22.2128446 },
    { lat: 49.1687623, lng: 22.2137072 },
    { lat: 49.1685971, lng: 22.2145033 },
    { lat: 49.168613, lng: 22.2148162 },
    { lat: 49.1685501, lng: 22.2152252 },
    { lat: 49.1687122, lng: 22.2154342 },
    { lat: 49.1688026, lng: 22.2157458 },
    { lat: 49.1689764, lng: 22.2169303 },
    { lat: 49.1691633, lng: 22.2173439 },
    { lat: 49.1694447, lng: 22.2176068 },
    { lat: 49.1695167, lng: 22.2178104 },
    { lat: 49.1695621, lng: 22.2181778 },
    { lat: 49.1695416, lng: 22.2185653 },
    { lat: 49.1694652, lng: 22.2189225 },
    { lat: 49.1691478, lng: 22.2194203 },
    { lat: 49.1690854, lng: 22.2197905 },
    { lat: 49.1690815, lng: 22.2201731 },
    { lat: 49.1692851, lng: 22.2214822 },
    { lat: 49.1692218, lng: 22.2218758 },
    { lat: 49.1692505, lng: 22.2222007 },
    { lat: 49.1691063, lng: 22.2227922 },
    { lat: 49.1689869, lng: 22.2229339 },
    { lat: 49.1689383, lng: 22.2231729 },
    { lat: 49.1688397, lng: 22.2233516 },
    { lat: 49.1683479, lng: 22.2248406 },
    { lat: 49.1683792, lng: 22.2251326 },
    { lat: 49.1685616, lng: 22.2258002 },
    { lat: 49.1685377, lng: 22.2262381 },
    { lat: 49.1684081, lng: 22.2266819 },
    { lat: 49.168441, lng: 22.2271952 },
    { lat: 49.1683841, lng: 22.2275715 },
    { lat: 49.1684413, lng: 22.2279197 },
    { lat: 49.168305, lng: 22.2282642 },
    { lat: 49.1677495, lng: 22.2289697 },
    { lat: 49.1676848, lng: 22.2291456 },
    { lat: 49.1676162, lng: 22.2293986 },
    { lat: 49.1676143, lng: 22.2298174 },
    { lat: 49.1676709, lng: 22.2300319 },
    { lat: 49.167552, lng: 22.2302073 },
    { lat: 49.1674498, lng: 22.2305163 },
    { lat: 49.1672308, lng: 22.2307799 },
    { lat: 49.1672258, lng: 22.2311061 },
    { lat: 49.167274, lng: 22.2313537 },
    { lat: 49.1670853, lng: 22.2321179 },
    { lat: 49.1671271, lng: 22.2324823 },
    { lat: 49.1670219, lng: 22.2330192 },
    { lat: 49.1668173, lng: 22.2331657 },
    { lat: 49.166771, lng: 22.233776 },
    { lat: 49.167102, lng: 22.233908 },
    { lat: 49.167241, lng: 22.234169 },
    { lat: 49.167259, lng: 22.234361 },
    { lat: 49.1675021, lng: 22.2348878 },
    { lat: 49.167686, lng: 22.2350726 },
    { lat: 49.168018, lng: 22.2355366 },
    { lat: 49.168256, lng: 22.235682 },
    { lat: 49.168344, lng: 22.235787 },
    { lat: 49.1685435, lng: 22.2358932 },
    { lat: 49.1688859, lng: 22.2358535 },
    { lat: 49.169009, lng: 22.2357973 },
    { lat: 49.1696611, lng: 22.2357441 },
    { lat: 49.1697365, lng: 22.2358197 },
    { lat: 49.170076, lng: 22.235799 },
    { lat: 49.1705872, lng: 22.2356179 },
    { lat: 49.1707625, lng: 22.2354092 },
    { lat: 49.1710321, lng: 22.2351572 },
    { lat: 49.1713598, lng: 22.2347499 },
    { lat: 49.1718212, lng: 22.2348461 },
    { lat: 49.172383, lng: 22.234724 },
    { lat: 49.1727592, lng: 22.2347225 },
    { lat: 49.172953, lng: 22.234856 },
    { lat: 49.173281, lng: 22.235034 },
    { lat: 49.173728, lng: 22.235157 },
    { lat: 49.174013, lng: 22.2350641 },
    { lat: 49.1743323, lng: 22.2348997 },
    { lat: 49.174618, lng: 22.234795 },
    { lat: 49.174696, lng: 22.234496 },
    { lat: 49.174818, lng: 22.234235 },
    { lat: 49.1750036, lng: 22.2339142 },
    { lat: 49.1751492, lng: 22.2339734 },
    { lat: 49.1754523, lng: 22.2335567 },
    { lat: 49.1758333, lng: 22.2330916 },
    { lat: 49.176284, lng: 22.232543 },
    { lat: 49.1764561, lng: 22.2322838 },
    { lat: 49.1767675, lng: 22.2321285 },
    { lat: 49.1769647, lng: 22.2319777 },
    { lat: 49.1774318, lng: 22.2318469 },
    { lat: 49.177582, lng: 22.231832 },
    { lat: 49.1778615, lng: 22.2315519 },
    { lat: 49.178045, lng: 22.231695 },
    { lat: 49.1782724, lng: 22.2317508 },
    { lat: 49.1786037, lng: 22.2315799 },
    { lat: 49.179205, lng: 22.231793 },
    { lat: 49.179479, lng: 22.231627 },
    { lat: 49.179734, lng: 22.231233 },
    { lat: 49.180174, lng: 22.230814 },
    { lat: 49.180473, lng: 22.230823 },
    { lat: 49.180736, lng: 22.23057 },
    { lat: 49.180889, lng: 22.229837 },
    { lat: 49.18115, lng: 22.228856 },
    { lat: 49.181162, lng: 22.227722 },
    { lat: 49.181599, lng: 22.22732 },
    { lat: 49.181927, lng: 22.227098 },
    { lat: 49.182309, lng: 22.226834 },
    { lat: 49.1825834, lng: 22.2262274 },
    { lat: 49.182866, lng: 22.2257058 },
    { lat: 49.1830332, lng: 22.2253276 },
    { lat: 49.183105, lng: 22.2248149 },
    { lat: 49.1832503, lng: 22.2243078 },
    { lat: 49.1836111, lng: 22.2238611 },
    { lat: 49.184098, lng: 22.2233072 },
    { lat: 49.1843229, lng: 22.2228256 },
    { lat: 49.1844599, lng: 22.222462 },
    { lat: 49.1844267, lng: 22.221925 },
    { lat: 49.1842265, lng: 22.2216952 },
    { lat: 49.1842203, lng: 22.2210136 },
    { lat: 49.1843048, lng: 22.2205347 },
    { lat: 49.1842597, lng: 22.2198116 },
    { lat: 49.184132, lng: 22.219196 },
    { lat: 49.18397, lng: 22.218324 },
    { lat: 49.1838459, lng: 22.2172027 },
    { lat: 49.1842141, lng: 22.2163142 },
    { lat: 49.1845308, lng: 22.2156397 },
    { lat: 49.1847851, lng: 22.2145427 },
    { lat: 49.1851074, lng: 22.2142291 },
    { lat: 49.185191, lng: 22.2139387 },
    { lat: 49.185405, lng: 22.2134027 },
    { lat: 49.185521, lng: 22.2126497 },
    { lat: 49.1855839, lng: 22.2116914 },
    { lat: 49.1855553, lng: 22.2110273 },
    { lat: 49.1848374, lng: 22.2105511 },
    { lat: 49.1841799, lng: 22.2105622 },
    { lat: 49.1837288, lng: 22.2103596 },
    { lat: 49.1835079, lng: 22.2102963 },
    { lat: 49.1833355, lng: 22.2103703 },
    { lat: 49.1832175, lng: 22.2103086 },
    { lat: 49.1826847, lng: 22.2099452 },
    { lat: 49.1824823, lng: 22.2095883 },
    { lat: 49.182454, lng: 22.208477 },
    { lat: 49.18244, lng: 22.2076 },
    { lat: 49.1823624, lng: 22.207359 },
    { lat: 49.1822438, lng: 22.2067808 },
    { lat: 49.1821149, lng: 22.2062922 },
    { lat: 49.1814685, lng: 22.2059791 },
    { lat: 49.1810599, lng: 22.2060513 },
    { lat: 49.1807745, lng: 22.2058784 },
    { lat: 49.1805791, lng: 22.20582 },
    { lat: 49.1801648, lng: 22.205451 },
    { lat: 49.1799773, lng: 22.2051925 },
    { lat: 49.1797638, lng: 22.2049861 },
    { lat: 49.1795924, lng: 22.2045457 },
    { lat: 49.1794249, lng: 22.2038758 },
    { lat: 49.1792566, lng: 22.2033953 },
    { lat: 49.179025, lng: 22.202826 },
    { lat: 49.17876, lng: 22.201969 },
    { lat: 49.1786516, lng: 22.2014465 },
    { lat: 49.1785477, lng: 22.2010437 },
    { lat: 49.1785078, lng: 22.2004885 },
    { lat: 49.1783546, lng: 22.1998879 },
    { lat: 49.1782022, lng: 22.199604 },
    { lat: 49.1779651, lng: 22.1994013 },
    { lat: 49.1775674, lng: 22.1991682 },
    { lat: 49.1774025, lng: 22.1987398 },
    { lat: 49.1772881, lng: 22.1982196 },
    { lat: 49.1771529, lng: 22.1977546 },
    { lat: 49.1770061, lng: 22.1974579 },
    { lat: 49.17679, lng: 22.197088 },
    { lat: 49.176251, lng: 22.196336 },
    { lat: 49.175935, lng: 22.195878 },
    { lat: 49.1757775, lng: 22.195709 },
    { lat: 49.1755547, lng: 22.19507 },
    { lat: 49.1753544, lng: 22.1943071 },
    { lat: 49.1748628, lng: 22.1937773 },
    { lat: 49.1747775, lng: 22.1936941 },
    { lat: 49.1744215, lng: 22.193047 },
    { lat: 49.174254, lng: 22.192264 },
    { lat: 49.17423, lng: 22.192124 },
    { lat: 49.174149, lng: 22.19164 },
    { lat: 49.174099, lng: 22.191534 },
    { lat: 49.1739447, lng: 22.191186 },
    { lat: 49.1738936, lng: 22.1910706 },
    { lat: 49.1738823, lng: 22.1907687 },
    { lat: 49.1740178, lng: 22.1899038 },
    { lat: 49.1742006, lng: 22.1892948 },
    { lat: 49.1744706, lng: 22.1888168 },
    { lat: 49.1743219, lng: 22.1881864 },
    { lat: 49.1744388, lng: 22.1878351 },
    { lat: 49.1747438, lng: 22.1875617 },
    { lat: 49.1749873, lng: 22.1874542 },
    { lat: 49.1752518, lng: 22.1873396 },
    { lat: 49.1755028, lng: 22.1874016 },
    { lat: 49.1758582, lng: 22.1872251 },
    { lat: 49.1762133, lng: 22.1869887 },
    { lat: 49.177017, lng: 22.1867705 },
    { lat: 49.1775065, lng: 22.1866995 },
    { lat: 49.177951, lng: 22.186252 },
    { lat: 49.178148, lng: 22.1860438 },
    { lat: 49.1783177, lng: 22.1858297 },
    { lat: 49.1785146, lng: 22.1855018 },
    { lat: 49.1786657, lng: 22.1847559 },
    { lat: 49.17912, lng: 22.1840675 },
    { lat: 49.179757, lng: 22.1826001 },
    { lat: 49.1800988, lng: 22.1821178 },
    { lat: 49.180356, lng: 22.1815459 },
    { lat: 49.180547, lng: 22.180979 },
    { lat: 49.1808692, lng: 22.1800326 },
    { lat: 49.1811634, lng: 22.1795399 },
    { lat: 49.1818585, lng: 22.1785543 },
    { lat: 49.1819813, lng: 22.1783387 },
    { lat: 49.1822795, lng: 22.1774159 },
    { lat: 49.1826615, lng: 22.1766966 },
    { lat: 49.1831017, lng: 22.1759065 },
    { lat: 49.1829631, lng: 22.1745193 },
    { lat: 49.1830148, lng: 22.1733367 },
    { lat: 49.1832962, lng: 22.1718596 },
    { lat: 49.1839199, lng: 22.1710806 },
    { lat: 49.1841032, lng: 22.1697568 },
  ],
  Čukalovce: [
    { lat: 49.115124, lng: 22.1801908 },
    { lat: 49.1140944, lng: 22.1780368 },
    { lat: 49.1124873, lng: 22.1744311 },
    { lat: 49.1080429, lng: 22.1631146 },
    { lat: 49.1045523, lng: 22.154741 },
    { lat: 49.1038343, lng: 22.1528047 },
    { lat: 49.1034883, lng: 22.1508102 },
    { lat: 49.103354, lng: 22.150262 },
    { lat: 49.1018993, lng: 22.1467093 },
    { lat: 49.101917, lng: 22.1464532 },
    { lat: 49.100481, lng: 22.1424127 },
    { lat: 49.0989291, lng: 22.1382784 },
    { lat: 49.0990833, lng: 22.1367304 },
    { lat: 49.0988543, lng: 22.1363562 },
    { lat: 49.0981528, lng: 22.1354922 },
    { lat: 49.0977477, lng: 22.1348043 },
    { lat: 49.0967384, lng: 22.1335226 },
    { lat: 49.0963688, lng: 22.1327586 },
    { lat: 49.0959609, lng: 22.1314652 },
    { lat: 49.0956173, lng: 22.1308053 },
    { lat: 49.0942803, lng: 22.1288322 },
    { lat: 49.093412, lng: 22.127184 },
    { lat: 49.092226, lng: 22.127519 },
    { lat: 49.091351, lng: 22.127873 },
    { lat: 49.089913, lng: 22.128793 },
    { lat: 49.088868, lng: 22.129174 },
    { lat: 49.087836, lng: 22.129574 },
    { lat: 49.087256, lng: 22.129898 },
    { lat: 49.086753, lng: 22.130072 },
    { lat: 49.086174, lng: 22.130223 },
    { lat: 49.085638, lng: 22.130421 },
    { lat: 49.08518, lng: 22.13055 },
    { lat: 49.084774, lng: 22.130834 },
    { lat: 49.084709, lng: 22.130885 },
    { lat: 49.084306, lng: 22.131275 },
    { lat: 49.084086, lng: 22.131397 },
    { lat: 49.083771, lng: 22.131483 },
    { lat: 49.083304, lng: 22.131683 },
    { lat: 49.082665, lng: 22.131959 },
    { lat: 49.082257, lng: 22.131982 },
    { lat: 49.080817, lng: 22.131861 },
    { lat: 49.080674, lng: 22.131938 },
    { lat: 49.080425, lng: 22.131785 },
    { lat: 49.08029, lng: 22.131768 },
    { lat: 49.079885, lng: 22.13177 },
    { lat: 49.079369, lng: 22.131824 },
    { lat: 49.078367, lng: 22.132046 },
    { lat: 49.077688, lng: 22.132073 },
    { lat: 49.076932, lng: 22.132678 },
    { lat: 49.076173, lng: 22.133108 },
    { lat: 49.074634, lng: 22.133448 },
    { lat: 49.073647, lng: 22.13401 },
    { lat: 49.0740911, lng: 22.1358443 },
    { lat: 49.0758594, lng: 22.1357532 },
    { lat: 49.0775969, lng: 22.1363755 },
    { lat: 49.0791873, lng: 22.1361453 },
    { lat: 49.0818307, lng: 22.1362538 },
    { lat: 49.0841418, lng: 22.1380004 },
    { lat: 49.0850873, lng: 22.1390481 },
    { lat: 49.085528, lng: 22.1411936 },
    { lat: 49.0855424, lng: 22.1429819 },
    { lat: 49.0853753, lng: 22.1443217 },
    { lat: 49.0857546, lng: 22.1455529 },
    { lat: 49.0859041, lng: 22.1455062 },
    { lat: 49.0859853, lng: 22.1468309 },
    { lat: 49.0855245, lng: 22.1491451 },
    { lat: 49.0847922, lng: 22.1513869 },
    { lat: 49.0844729, lng: 22.1522195 },
    { lat: 49.0845333, lng: 22.1524502 },
    { lat: 49.0861314, lng: 22.1531337 },
    { lat: 49.0867418, lng: 22.1537582 },
    { lat: 49.0878109, lng: 22.1558449 },
    { lat: 49.0891736, lng: 22.1587652 },
    { lat: 49.0889396, lng: 22.1605352 },
    { lat: 49.0890227, lng: 22.1608724 },
    { lat: 49.0890871, lng: 22.1608833 },
    { lat: 49.0883153, lng: 22.1625975 },
    { lat: 49.0880197, lng: 22.1639739 },
    { lat: 49.0881473, lng: 22.1640515 },
    { lat: 49.0879335, lng: 22.1654701 },
    { lat: 49.0870995, lng: 22.1653001 },
    { lat: 49.0870013, lng: 22.1684798 },
    { lat: 49.0872518, lng: 22.1707297 },
    { lat: 49.0872368, lng: 22.1717025 },
    { lat: 49.087724, lng: 22.1744264 },
    { lat: 49.0911834, lng: 22.1776302 },
    { lat: 49.0932472, lng: 22.1802494 },
    { lat: 49.0948833, lng: 22.1827621 },
    { lat: 49.0957834, lng: 22.1842708 },
    { lat: 49.0964941, lng: 22.1861445 },
    { lat: 49.0971836, lng: 22.1874419 },
    { lat: 49.0979584, lng: 22.1893205 },
    { lat: 49.0989457, lng: 22.1921435 },
    { lat: 49.0991763, lng: 22.1935842 },
    { lat: 49.100396, lng: 22.1906306 },
    { lat: 49.1011994, lng: 22.1895494 },
    { lat: 49.1037262, lng: 22.1846776 },
    { lat: 49.1038429, lng: 22.1845684 },
    { lat: 49.1039947, lng: 22.1849104 },
    { lat: 49.1046041, lng: 22.1867398 },
    { lat: 49.1051615, lng: 22.1871136 },
    { lat: 49.1055125, lng: 22.1871691 },
    { lat: 49.1057809, lng: 22.1876959 },
    { lat: 49.1066514, lng: 22.1875988 },
    { lat: 49.1064834, lng: 22.1885284 },
    { lat: 49.1076605, lng: 22.1895459 },
    { lat: 49.1090142, lng: 22.1885325 },
    { lat: 49.1101793, lng: 22.187544 },
    { lat: 49.1116946, lng: 22.1866659 },
    { lat: 49.1127089, lng: 22.1835887 },
    { lat: 49.1124744, lng: 22.182864 },
    { lat: 49.1127532, lng: 22.182595 },
    { lat: 49.113439, lng: 22.1819074 },
    { lat: 49.1140208, lng: 22.1822272 },
    { lat: 49.1146676, lng: 22.1819299 },
    { lat: 49.1149914, lng: 22.1811819 },
    { lat: 49.115124, lng: 22.1801908 },
  ],
  Brezovec: [
    { lat: 48.9541788, lng: 22.3959446 },
    { lat: 48.953871, lng: 22.3948419 },
    { lat: 48.9534056, lng: 22.3938331 },
    { lat: 48.9533762, lng: 22.393398 },
    { lat: 48.9530388, lng: 22.3923766 },
    { lat: 48.9526642, lng: 22.3919331 },
    { lat: 48.9518302, lng: 22.3917426 },
    { lat: 48.9501476, lng: 22.3916304 },
    { lat: 48.9496448, lng: 22.3914139 },
    { lat: 48.9493753, lng: 22.3913848 },
    { lat: 48.949028, lng: 22.3911715 },
    { lat: 48.9487416, lng: 22.391292 },
    { lat: 48.9485194, lng: 22.3912737 },
    { lat: 48.9482741, lng: 22.3911482 },
    { lat: 48.9478688, lng: 22.3908132 },
    { lat: 48.9472453, lng: 22.3900459 },
    { lat: 48.9467662, lng: 22.3893463 },
    { lat: 48.9462631, lng: 22.389117 },
    { lat: 48.9456838, lng: 22.3889462 },
    { lat: 48.9451015, lng: 22.3884731 },
    { lat: 48.9448904, lng: 22.388118 },
    { lat: 48.9440631, lng: 22.3874426 },
    { lat: 48.9424543, lng: 22.3855389 },
    { lat: 48.9423009, lng: 22.3849335 },
    { lat: 48.9420189, lng: 22.3844116 },
    { lat: 48.9414077, lng: 22.3841178 },
    { lat: 48.9409572, lng: 22.3827988 },
    { lat: 48.9402193, lng: 22.3810889 },
    { lat: 48.939793, lng: 22.380607 },
    { lat: 48.9386729, lng: 22.3800743 },
    { lat: 48.9366642, lng: 22.378892 },
    { lat: 48.9359129, lng: 22.378837 },
    { lat: 48.9356252, lng: 22.378749 },
    { lat: 48.9336297, lng: 22.3793175 },
    { lat: 48.9328125, lng: 22.3790646 },
    { lat: 48.9328696, lng: 22.379212 },
    { lat: 48.933061, lng: 22.3797053 },
    { lat: 48.9318851, lng: 22.379252 },
    { lat: 48.9313013, lng: 22.3797733 },
    { lat: 48.9309416, lng: 22.3802622 },
    { lat: 48.9304137, lng: 22.3813104 },
    { lat: 48.9294567, lng: 22.3822203 },
    { lat: 48.9291049, lng: 22.3827581 },
    { lat: 48.9290239, lng: 22.3831305 },
    { lat: 48.9281356, lng: 22.384851 },
    { lat: 48.9283181, lng: 22.385193 },
    { lat: 48.9288605, lng: 22.3865412 },
    { lat: 48.9290504, lng: 22.3871246 },
    { lat: 48.9296853, lng: 22.3884327 },
    { lat: 48.9298049, lng: 22.3888221 },
    { lat: 48.9294775, lng: 22.3893576 },
    { lat: 48.9289463, lng: 22.3899735 },
    { lat: 48.9282292, lng: 22.3916044 },
    { lat: 48.9281869, lng: 22.3917892 },
    { lat: 48.9281561, lng: 22.392084 },
    { lat: 48.9282543, lng: 22.3927996 },
    { lat: 48.9286292, lng: 22.3941119 },
    { lat: 48.9291981, lng: 22.3951238 },
    { lat: 48.9300531, lng: 22.3962586 },
    { lat: 48.9309438, lng: 22.3977057 },
    { lat: 48.9320203, lng: 22.3986384 },
    { lat: 48.9328661, lng: 22.3991821 },
    { lat: 48.9334378, lng: 22.3994625 },
    { lat: 48.9342636, lng: 22.4000719 },
    { lat: 48.9346053, lng: 22.4004872 },
    { lat: 48.9348741, lng: 22.4005487 },
    { lat: 48.9356597, lng: 22.4011085 },
    { lat: 48.9367107, lng: 22.4017354 },
    { lat: 48.9376488, lng: 22.4020133 },
    { lat: 48.9384111, lng: 22.4025969 },
    { lat: 48.9395652, lng: 22.4036857 },
    { lat: 48.9400205, lng: 22.4042709 },
    { lat: 48.9405558, lng: 22.4058213 },
    { lat: 48.941591, lng: 22.4066656 },
    { lat: 48.9416057, lng: 22.4066713 },
    { lat: 48.941869, lng: 22.4067738 },
    { lat: 48.9423094, lng: 22.4061044 },
    { lat: 48.9427648, lng: 22.4057838 },
    { lat: 48.9432987, lng: 22.4055077 },
    { lat: 48.943719, lng: 22.4055361 },
    { lat: 48.9442588, lng: 22.4058125 },
    { lat: 48.9446494, lng: 22.4058871 },
    { lat: 48.9448429, lng: 22.4058298 },
    { lat: 48.9455383, lng: 22.4063356 },
    { lat: 48.9461645, lng: 22.4063709 },
    { lat: 48.9463649, lng: 22.4062751 },
    { lat: 48.9472844, lng: 22.4063189 },
    { lat: 48.9478485, lng: 22.4061332 },
    { lat: 48.9480558, lng: 22.4046352 },
    { lat: 48.9480462, lng: 22.4039637 },
    { lat: 48.9483443, lng: 22.4036463 },
    { lat: 48.9482591, lng: 22.4033338 },
    { lat: 48.9486825, lng: 22.4028254 },
    { lat: 48.9490878, lng: 22.4021883 },
    { lat: 48.9494611, lng: 22.4014321 },
    { lat: 48.9493091, lng: 22.4009951 },
    { lat: 48.9492912, lng: 22.4001934 },
    { lat: 48.9494022, lng: 22.3995837 },
    { lat: 48.9496768, lng: 22.3991736 },
    { lat: 48.9504973, lng: 22.398875 },
    { lat: 48.9512166, lng: 22.3984281 },
    { lat: 48.9515704, lng: 22.3975948 },
    { lat: 48.9524921, lng: 22.3969234 },
    { lat: 48.9531328, lng: 22.396378 },
    { lat: 48.9535881, lng: 22.3960948 },
    { lat: 48.9541788, lng: 22.3959446 },
  ],
  KalnáRoztoka: [
    { lat: 48.9860231, lng: 22.2825157 },
    { lat: 48.985713, lng: 22.2824333 },
    { lat: 48.9845052, lng: 22.283102 },
    { lat: 48.984229, lng: 22.2831235 },
    { lat: 48.9836107, lng: 22.2838071 },
    { lat: 48.9829252, lng: 22.2837773 },
    { lat: 48.9823972, lng: 22.2840096 },
    { lat: 48.9819979, lng: 22.2843208 },
    { lat: 48.9817759, lng: 22.2843503 },
    { lat: 48.9807806, lng: 22.2853301 },
    { lat: 48.9809931, lng: 22.2875862 },
    { lat: 48.9767476, lng: 22.2887788 },
    { lat: 48.9724563, lng: 22.2889459 },
    { lat: 48.9715884, lng: 22.2883975 },
    { lat: 48.9720139, lng: 22.2873937 },
    { lat: 48.9716413, lng: 22.2864417 },
    { lat: 48.9719242, lng: 22.2854985 },
    { lat: 48.9719858, lng: 22.2847171 },
    { lat: 48.9707249, lng: 22.281685 },
    { lat: 48.9700172, lng: 22.2828437 },
    { lat: 48.9696855, lng: 22.283464 },
    { lat: 48.9691104, lng: 22.2838589 },
    { lat: 48.9684811, lng: 22.2840966 },
    { lat: 48.968117, lng: 22.2841511 },
    { lat: 48.9677624, lng: 22.2845495 },
    { lat: 48.9672844, lng: 22.2852692 },
    { lat: 48.9664402, lng: 22.2873464 },
    { lat: 48.9661243, lng: 22.2884877 },
    { lat: 48.9654384, lng: 22.2899809 },
    { lat: 48.9651373, lng: 22.2904603 },
    { lat: 48.9639093, lng: 22.2911868 },
    { lat: 48.9629064, lng: 22.2915595 },
    { lat: 48.9620168, lng: 22.2921243 },
    { lat: 48.9616991, lng: 22.2925115 },
    { lat: 48.9607274, lng: 22.2932358 },
    { lat: 48.9598431, lng: 22.293577 },
    { lat: 48.9585386, lng: 22.2937929 },
    { lat: 48.9579983, lng: 22.2938291 },
    { lat: 48.9578227, lng: 22.2937627 },
    { lat: 48.958297, lng: 22.2948809 },
    { lat: 48.9584357, lng: 22.2956223 },
    { lat: 48.958382, lng: 22.296 },
    { lat: 48.9582302, lng: 22.2966146 },
    { lat: 48.9579671, lng: 22.296936 },
    { lat: 48.9577907, lng: 22.2980242 },
    { lat: 48.9575482, lng: 22.2984573 },
    { lat: 48.9575945, lng: 22.2985158 },
    { lat: 48.9565412, lng: 22.2997779 },
    { lat: 48.9557873, lng: 22.3014129 },
    { lat: 48.9557755, lng: 22.3039687 },
    { lat: 48.9554251, lng: 22.3056532 },
    { lat: 48.9548456, lng: 22.3075517 },
    { lat: 48.956011, lng: 22.3091686 },
    { lat: 48.9586056, lng: 22.3093791 },
    { lat: 48.958642, lng: 22.3126695 },
    { lat: 48.9597818, lng: 22.3161772 },
    { lat: 48.9603102, lng: 22.3179894 },
    { lat: 48.9607552, lng: 22.3189607 },
    { lat: 48.9610675, lng: 22.3200339 },
    { lat: 48.9615206, lng: 22.3206516 },
    { lat: 48.961672, lng: 22.3212984 },
    { lat: 48.9610625, lng: 22.3217638 },
    { lat: 48.9604074, lng: 22.3219683 },
    { lat: 48.9601031, lng: 22.3218872 },
    { lat: 48.9593647, lng: 22.3219622 },
    { lat: 48.9594324, lng: 22.3226982 },
    { lat: 48.9595531, lng: 22.3228726 },
    { lat: 48.9599672, lng: 22.3232475 },
    { lat: 48.9602467, lng: 22.3229064 },
    { lat: 48.9605142, lng: 22.3230599 },
    { lat: 48.9610791, lng: 22.322811 },
    { lat: 48.9611323, lng: 22.3232958 },
    { lat: 48.9611608, lng: 22.3244669 },
    { lat: 48.9619655, lng: 22.3245929 },
    { lat: 48.9621508, lng: 22.3277111 },
    { lat: 48.9626793, lng: 22.331837 },
    { lat: 48.9629511, lng: 22.3351235 },
    { lat: 48.9634066, lng: 22.3349131 },
    { lat: 48.9640495, lng: 22.3351103 },
    { lat: 48.9644069, lng: 22.3347751 },
    { lat: 48.9649175, lng: 22.3354063 },
    { lat: 48.9651785, lng: 22.3358304 },
    { lat: 48.9656234, lng: 22.3361803 },
    { lat: 48.9658974, lng: 22.3368901 },
    { lat: 48.966097, lng: 22.3370767 },
    { lat: 48.9672344, lng: 22.337299 },
    { lat: 48.9678977, lng: 22.338073 },
    { lat: 48.9684223, lng: 22.3383602 },
    { lat: 48.9691026, lng: 22.3383541 },
    { lat: 48.9696828, lng: 22.3382463 },
    { lat: 48.9702406, lng: 22.3385059 },
    { lat: 48.9706054, lng: 22.3382104 },
    { lat: 48.9707518, lng: 22.3385844 },
    { lat: 48.9715679, lng: 22.3381993 },
    { lat: 48.9727203, lng: 22.3381355 },
    { lat: 48.9727607, lng: 22.3381818 },
    { lat: 48.9727798, lng: 22.3380714 },
    { lat: 48.9740169, lng: 22.3383406 },
    { lat: 48.9758835, lng: 22.3395357 },
    { lat: 48.9767902, lng: 22.34075 },
    { lat: 48.9773465, lng: 22.3413172 },
    { lat: 48.9778612, lng: 22.3423429 },
    { lat: 48.9788803, lng: 22.343092 },
    { lat: 48.9807089, lng: 22.3435571 },
    { lat: 48.9812043, lng: 22.3437482 },
    { lat: 48.9825913, lng: 22.3446543 },
    { lat: 48.983296, lng: 22.3455322 },
    { lat: 48.9833961, lng: 22.3462678 },
    { lat: 48.9831831, lng: 22.3469317 },
    { lat: 48.9831401, lng: 22.347235 },
    { lat: 48.9831681, lng: 22.3473868 },
    { lat: 48.9834448, lng: 22.3476528 },
    { lat: 48.9840769, lng: 22.3479214 },
    { lat: 48.9842514, lng: 22.3481359 },
    { lat: 48.9847343, lng: 22.3483684 },
    { lat: 48.9850868, lng: 22.3486504 },
    { lat: 48.9853175, lng: 22.3489302 },
    { lat: 48.9860547, lng: 22.3504787 },
    { lat: 48.986137, lng: 22.3515385 },
    { lat: 48.9862465, lng: 22.3517265 },
    { lat: 48.986404, lng: 22.3518276 },
    { lat: 48.9866372, lng: 22.3521083 },
    { lat: 48.986701, lng: 22.3521973 },
    { lat: 48.9867823, lng: 22.3523106 },
    { lat: 48.9869025, lng: 22.3524783 },
    { lat: 48.9870382, lng: 22.3526676 },
    { lat: 48.9870373, lng: 22.3527548 },
    { lat: 48.9870344, lng: 22.3530464 },
    { lat: 48.987031, lng: 22.3533922 },
    { lat: 48.9870284, lng: 22.3536473 },
    { lat: 48.9870267, lng: 22.3538215 },
    { lat: 48.9870109, lng: 22.35399 },
    { lat: 48.9869916, lng: 22.3541962 },
    { lat: 48.9869698, lng: 22.3544284 },
    { lat: 48.9871173, lng: 22.3549003 },
    { lat: 48.9871911, lng: 22.3550379 },
    { lat: 48.9874169, lng: 22.3554588 },
    { lat: 48.9875648, lng: 22.355906 },
    { lat: 48.9877625, lng: 22.356317 },
    { lat: 48.9884173, lng: 22.3570626 },
    { lat: 48.9898151, lng: 22.3580651 },
    { lat: 48.9903438, lng: 22.358813 },
    { lat: 48.9905926, lng: 22.3589877 },
    { lat: 48.9911516, lng: 22.358146 },
    { lat: 48.9916667, lng: 22.357875 },
    { lat: 48.9919098, lng: 22.357626 },
    { lat: 48.9927433, lng: 22.3571067 },
    { lat: 48.9936658, lng: 22.3569099 },
    { lat: 48.9940711, lng: 22.3571425 },
    { lat: 48.9953975, lng: 22.357154 },
    { lat: 48.9959665, lng: 22.3570505 },
    { lat: 48.9965644, lng: 22.3571386 },
    { lat: 48.9975941, lng: 22.3570676 },
    { lat: 48.997839, lng: 22.3566795 },
    { lat: 48.997821, lng: 22.3563674 },
    { lat: 48.9982291, lng: 22.3556658 },
    { lat: 48.9986159, lng: 22.356198 },
    { lat: 48.998985, lng: 22.3557801 },
    { lat: 48.9998773, lng: 22.3549537 },
    { lat: 49.0002211, lng: 22.3544113 },
    { lat: 49.0006388, lng: 22.3539048 },
    { lat: 49.0009531, lng: 22.3529813 },
    { lat: 49.0014106, lng: 22.3523886 },
    { lat: 49.0018164, lng: 22.3515921 },
    { lat: 49.0020476, lng: 22.3512494 },
    { lat: 49.0023873, lng: 22.3500943 },
    { lat: 49.0026133, lng: 22.3496717 },
    { lat: 49.0034135, lng: 22.3487973 },
    { lat: 49.0038959, lng: 22.3484855 },
    { lat: 49.0045046, lng: 22.3489728 },
    { lat: 49.0066556, lng: 22.349316 },
    { lat: 49.007039, lng: 22.3497363 },
    { lat: 49.0082813, lng: 22.3496644 },
    { lat: 49.0099834, lng: 22.3493724 },
    { lat: 49.0107834, lng: 22.3480769 },
    { lat: 49.0115139, lng: 22.3474201 },
    { lat: 49.0119773, lng: 22.3461025 },
    { lat: 49.012221, lng: 22.3450863 },
    { lat: 49.012306, lng: 22.3450047 },
    { lat: 49.0121891, lng: 22.3443337 },
    { lat: 49.0122129, lng: 22.343075 },
    { lat: 49.0125995, lng: 22.3423315 },
    { lat: 49.0129747, lng: 22.3417781 },
    { lat: 49.0127447, lng: 22.3409106 },
    { lat: 49.0126479, lng: 22.3400108 },
    { lat: 49.0127709, lng: 22.3391943 },
    { lat: 49.0129011, lng: 22.338706 },
    { lat: 49.0135528, lng: 22.3378365 },
    { lat: 49.013892, lng: 22.337029 },
    { lat: 49.014222, lng: 22.3353291 },
    { lat: 49.0141926, lng: 22.3333751 },
    { lat: 49.015042, lng: 22.3322338 },
    { lat: 49.0152137, lng: 22.331748 },
    { lat: 49.015211, lng: 22.3312039 },
    { lat: 49.0150285, lng: 22.3302467 },
    { lat: 49.0151732, lng: 22.3292922 },
    { lat: 49.0150481, lng: 22.3279143 },
    { lat: 49.0140065, lng: 22.3265993 },
    { lat: 49.0139409, lng: 22.3256975 },
    { lat: 49.0140945, lng: 22.3237376 },
    { lat: 49.0139348, lng: 22.3230072 },
    { lat: 49.0137229, lng: 22.3225688 },
    { lat: 49.0133691, lng: 22.3216037 },
    { lat: 49.0128551, lng: 22.3207598 },
    { lat: 49.0125479, lng: 22.3200238 },
    { lat: 49.0123742, lng: 22.3189057 },
    { lat: 49.0118335, lng: 22.3178794 },
    { lat: 49.0111626, lng: 22.3172784 },
    { lat: 49.0104458, lng: 22.3163118 },
    { lat: 49.0103277, lng: 22.3159522 },
    { lat: 49.0101943, lng: 22.3152413 },
    { lat: 49.0099986, lng: 22.3147814 },
    { lat: 49.009775, lng: 22.3133306 },
    { lat: 49.0097106, lng: 22.3132102 },
    { lat: 49.0094548, lng: 22.3120924 },
    { lat: 49.0092593, lng: 22.3110661 },
    { lat: 49.009322, lng: 22.3105331 },
    { lat: 49.0092021, lng: 22.3102973 },
    { lat: 49.0087149, lng: 22.3101848 },
    { lat: 49.0086059, lng: 22.310234 },
    { lat: 49.0083876, lng: 22.309968 },
    { lat: 49.0083112, lng: 22.3099754 },
    { lat: 49.0081374, lng: 22.3101579 },
    { lat: 49.0076357, lng: 22.3102002 },
    { lat: 49.0073824, lng: 22.310397 },
    { lat: 49.0072204, lng: 22.3104488 },
    { lat: 49.0069921, lng: 22.3104398 },
    { lat: 49.0069062, lng: 22.3103681 },
    { lat: 49.0065219, lng: 22.3092984 },
    { lat: 49.0061739, lng: 22.3077661 },
    { lat: 49.0058788, lng: 22.3070816 },
    { lat: 49.0056111, lng: 22.3068633 },
    { lat: 49.0055277, lng: 22.3066475 },
    { lat: 49.005269, lng: 22.3063341 },
    { lat: 49.0050136, lng: 22.306132 },
    { lat: 49.0050316, lng: 22.3058205 },
    { lat: 49.0048982, lng: 22.3057352 },
    { lat: 49.0047544, lng: 22.3051656 },
    { lat: 49.0047588, lng: 22.3049559 },
    { lat: 49.0044283, lng: 22.3040837 },
    { lat: 49.0042876, lng: 22.3038239 },
    { lat: 49.0041644, lng: 22.3038501 },
    { lat: 49.0039505, lng: 22.303623 },
    { lat: 49.0036883, lng: 22.3036734 },
    { lat: 49.0029334, lng: 22.303141 },
    { lat: 49.0028491, lng: 22.3029346 },
    { lat: 49.0027077, lng: 22.302796 },
    { lat: 49.0026183, lng: 22.3025612 },
    { lat: 49.0025094, lng: 22.3025749 },
    { lat: 49.0023933, lng: 22.3023038 },
    { lat: 49.0019333, lng: 22.3017849 },
    { lat: 49.001876, lng: 22.3014705 },
    { lat: 49.0015748, lng: 22.3007645 },
    { lat: 49.0011697, lng: 22.3003862 },
    { lat: 49.0011226, lng: 22.3002897 },
    { lat: 49.001135, lng: 22.3001906 },
    { lat: 49.0010028, lng: 22.2998967 },
    { lat: 49.0008319, lng: 22.3000572 },
    { lat: 49.0007304, lng: 22.3000523 },
    { lat: 49.000722, lng: 22.2999126 },
    { lat: 49.0006112, lng: 22.2997914 },
    { lat: 49.0002057, lng: 22.2998641 },
    { lat: 48.9996764, lng: 22.2996772 },
    { lat: 48.9995151, lng: 22.2994442 },
    { lat: 48.9994187, lng: 22.2991554 },
    { lat: 48.999319, lng: 22.2991181 },
    { lat: 48.9990054, lng: 22.2992306 },
    { lat: 48.9986149, lng: 22.2991148 },
    { lat: 48.9985387, lng: 22.2991752 },
    { lat: 48.9984921, lng: 22.299361 },
    { lat: 48.9983215, lng: 22.2995354 },
    { lat: 48.9980516, lng: 22.299588 },
    { lat: 48.9976413, lng: 22.2994879 },
    { lat: 48.997531, lng: 22.2995432 },
    { lat: 48.9973135, lng: 22.2993593 },
    { lat: 48.9970707, lng: 22.299629 },
    { lat: 48.9967661, lng: 22.2994821 },
    { lat: 48.9967465, lng: 22.2993634 },
    { lat: 48.9966814, lng: 22.2994154 },
    { lat: 48.9964842, lng: 22.2993422 },
    { lat: 48.9963084, lng: 22.2994828 },
    { lat: 48.9961395, lng: 22.2994222 },
    { lat: 48.995927, lng: 22.2994766 },
    { lat: 48.9958729, lng: 22.2994195 },
    { lat: 48.9957235, lng: 22.2994814 },
    { lat: 48.9953169, lng: 22.2992404 },
    { lat: 48.9951426, lng: 22.2995081 },
    { lat: 48.9950177, lng: 22.2994524 },
    { lat: 48.9943638, lng: 22.298861 },
    { lat: 48.9941568, lng: 22.2989671 },
    { lat: 48.9939313, lng: 22.2985776 },
    { lat: 48.9939711, lng: 22.2984897 },
    { lat: 48.9935751, lng: 22.2978703 },
    { lat: 48.9920448, lng: 22.2965348 },
    { lat: 48.9915862, lng: 22.2963953 },
    { lat: 48.9911625, lng: 22.2963507 },
    { lat: 48.9893544, lng: 22.2934328 },
    { lat: 48.9885512, lng: 22.2927041 },
    { lat: 48.9880775, lng: 22.2917149 },
    { lat: 48.9879191, lng: 22.2896602 },
    { lat: 48.9877638, lng: 22.2888856 },
    { lat: 48.9872018, lng: 22.2869477 },
    { lat: 48.9868466, lng: 22.2861284 },
    { lat: 48.9865271, lng: 22.2845356 },
    { lat: 48.9864763, lng: 22.2840566 },
    { lat: 48.9862627, lng: 22.2833007 },
    { lat: 48.9860231, lng: 22.2825157 },
  ],
  Šmigovec: [
    { lat: 48.8766318, lng: 22.3238709 },
    { lat: 48.8767056, lng: 22.3237943 },
    { lat: 48.8773725, lng: 22.3231702 },
    { lat: 48.8798379, lng: 22.3238014 },
    { lat: 48.8800153, lng: 22.3237329 },
    { lat: 48.8802099, lng: 22.3238944 },
    { lat: 48.880767, lng: 22.3256291 },
    { lat: 48.8821692, lng: 22.3274392 },
    { lat: 48.8830202, lng: 22.3290433 },
    { lat: 48.8832523, lng: 22.3293673 },
    { lat: 48.8831434, lng: 22.3295314 },
    { lat: 48.8830772, lng: 22.3298609 },
    { lat: 48.8830529, lng: 22.3303964 },
    { lat: 48.883161, lng: 22.330926 },
    { lat: 48.8833916, lng: 22.3311101 },
    { lat: 48.8839014, lng: 22.3315172 },
    { lat: 48.8834642, lng: 22.3328932 },
    { lat: 48.8839272, lng: 22.333446 },
    { lat: 48.8846881, lng: 22.3335852 },
    { lat: 48.8853164, lng: 22.3341316 },
    { lat: 48.8857342, lng: 22.3343726 },
    { lat: 48.8859655, lng: 22.3343551 },
    { lat: 48.8861811, lng: 22.334646 },
    { lat: 48.887062, lng: 22.3343016 },
    { lat: 48.8871189, lng: 22.3342794 },
    { lat: 48.8877767, lng: 22.3343234 },
    { lat: 48.8882304, lng: 22.3347077 },
    { lat: 48.8885087, lng: 22.3347404 },
    { lat: 48.8885741, lng: 22.3348172 },
    { lat: 48.8886173, lng: 22.3348679 },
    { lat: 48.8890522, lng: 22.3351123 },
    { lat: 48.8893643, lng: 22.3349329 },
    { lat: 48.8894153, lng: 22.3350015 },
    { lat: 48.8897238, lng: 22.3349683 },
    { lat: 48.889946, lng: 22.3350982 },
    { lat: 48.8902398, lng: 22.3351619 },
    { lat: 48.8904691, lng: 22.3354233 },
    { lat: 48.8906696, lng: 22.3353301 },
    { lat: 48.8911136, lng: 22.3355269 },
    { lat: 48.8911278, lng: 22.3353996 },
    { lat: 48.8912076, lng: 22.3346842 },
    { lat: 48.8912238, lng: 22.3345389 },
    { lat: 48.8912238, lng: 22.3345388 },
    { lat: 48.8924916, lng: 22.3349137 },
    { lat: 48.8924883, lng: 22.3351222 },
    { lat: 48.8925982, lng: 22.3351445 },
    { lat: 48.8932214, lng: 22.3352709 },
    { lat: 48.8933004, lng: 22.3352869 },
    { lat: 48.8932789, lng: 22.3359074 },
    { lat: 48.8936355, lng: 22.3360181 },
    { lat: 48.8936209, lng: 22.3370067 },
    { lat: 48.8937516, lng: 22.3382305 },
    { lat: 48.8944726, lng: 22.3382782 },
    { lat: 48.8950391, lng: 22.3380568 },
    { lat: 48.8956567, lng: 22.3381761 },
    { lat: 48.896892, lng: 22.3376218 },
    { lat: 48.8971231, lng: 22.3374295 },
    { lat: 48.8975364, lng: 22.3373476 },
    { lat: 48.8975899, lng: 22.3375344 },
    { lat: 48.8979622, lng: 22.337852 },
    { lat: 48.8982741, lng: 22.3377158 },
    { lat: 48.8987018, lng: 22.3379989 },
    { lat: 48.8987214, lng: 22.3379647 },
    { lat: 48.8993163, lng: 22.3382763 },
    { lat: 48.8997311, lng: 22.3385836 },
    { lat: 48.8999109, lng: 22.3382541 },
    { lat: 48.8997773, lng: 22.3378471 },
    { lat: 48.8999737, lng: 22.3368855 },
    { lat: 48.9003149, lng: 22.3360193 },
    { lat: 48.9004408, lng: 22.3348162 },
    { lat: 48.9007147, lng: 22.3347387 },
    { lat: 48.9004318, lng: 22.3323576 },
    { lat: 48.8999631, lng: 22.3308516 },
    { lat: 48.8999264, lng: 22.3294081 },
    { lat: 48.9001317, lng: 22.3277154 },
    { lat: 48.9010277, lng: 22.3248865 },
    { lat: 48.9014054, lng: 22.3246612 },
    { lat: 48.9027961, lng: 22.3224018 },
    { lat: 48.9026738, lng: 22.3220007 },
    { lat: 48.9028954, lng: 22.3206512 },
    { lat: 48.9027243, lng: 22.3204863 },
    { lat: 48.9028378, lng: 22.3197532 },
    { lat: 48.9035582, lng: 22.3186921 },
    { lat: 48.9038675, lng: 22.3185372 },
    { lat: 48.9046326, lng: 22.318768 },
    { lat: 48.905738, lng: 22.3188145 },
    { lat: 48.9054154, lng: 22.3175203 },
    { lat: 48.9053806, lng: 22.3165374 },
    { lat: 48.9073791, lng: 22.3138905 },
    { lat: 48.9075039, lng: 22.3117145 },
    { lat: 48.907714, lng: 22.3110222 },
    { lat: 48.9070782, lng: 22.3082678 },
    { lat: 48.9059548, lng: 22.3058438 },
    { lat: 48.9057202, lng: 22.3020409 },
    { lat: 48.9092817, lng: 22.2979509 },
    { lat: 48.9102876, lng: 22.2959032 },
    { lat: 48.9091685, lng: 22.2937931 },
    { lat: 48.908336, lng: 22.2921946 },
    { lat: 48.9081111, lng: 22.2923968 },
    { lat: 48.9076425, lng: 22.2930289 },
    { lat: 48.9070522, lng: 22.2935073 },
    { lat: 48.9067426, lng: 22.2941567 },
    { lat: 48.9065186, lng: 22.2943564 },
    { lat: 48.9062787, lng: 22.2941857 },
    { lat: 48.9057446, lng: 22.2950674 },
    { lat: 48.905128, lng: 22.294698 },
    { lat: 48.9045834, lng: 22.2957578 },
    { lat: 48.9038253, lng: 22.2960211 },
    { lat: 48.9035104, lng: 22.2959079 },
    { lat: 48.9030222, lng: 22.2955896 },
    { lat: 48.9026979, lng: 22.2956774 },
    { lat: 48.9025157, lng: 22.2959886 },
    { lat: 48.902248, lng: 22.2961407 },
    { lat: 48.9003249, lng: 22.2962952 },
    { lat: 48.8999363, lng: 22.2964245 },
    { lat: 48.8973396, lng: 22.2966414 },
    { lat: 48.8949353, lng: 22.2963442 },
    { lat: 48.8942, lng: 22.2963561 },
    { lat: 48.8937006, lng: 22.2960402 },
    { lat: 48.8932247, lng: 22.2963954 },
    { lat: 48.8928571, lng: 22.29646 },
    { lat: 48.8915796, lng: 22.2958504 },
    { lat: 48.8910305, lng: 22.2958297 },
    { lat: 48.8907135, lng: 22.2957438 },
    { lat: 48.8902751, lng: 22.2958526 },
    { lat: 48.8896939, lng: 22.2964862 },
    { lat: 48.8895307, lng: 22.2962749 },
    { lat: 48.8893185, lng: 22.2962413 },
    { lat: 48.8893005, lng: 22.2962365 },
    { lat: 48.888826, lng: 22.2972242 },
    { lat: 48.8886877, lng: 22.2976393 },
    { lat: 48.8883535, lng: 22.2981964 },
    { lat: 48.8881112, lng: 22.2983826 },
    { lat: 48.8875233, lng: 22.2995286 },
    { lat: 48.886455, lng: 22.3022682 },
    { lat: 48.8857652, lng: 22.3037515 },
    { lat: 48.8850429, lng: 22.3059609 },
    { lat: 48.8849495, lng: 22.3068325 },
    { lat: 48.8846533, lng: 22.307844 },
    { lat: 48.8831914, lng: 22.3097206 },
    { lat: 48.8823996, lng: 22.3113714 },
    { lat: 48.8813736, lng: 22.3132251 },
    { lat: 48.8804397, lng: 22.3145523 },
    { lat: 48.8801186, lng: 22.3151575 },
    { lat: 48.8793494, lng: 22.3162989 },
    { lat: 48.8791017, lng: 22.3165378 },
    { lat: 48.8784798, lng: 22.317377 },
    { lat: 48.8776626, lng: 22.3188559 },
    { lat: 48.8772268, lng: 22.3208035 },
    { lat: 48.8768087, lng: 22.3219584 },
    { lat: 48.8768555, lng: 22.322771 },
    { lat: 48.8767151, lng: 22.323459 },
    { lat: 48.8766318, lng: 22.3238709 },
  ],
  Príslop: [
    { lat: 49.0599933, lng: 22.3266453 },
    { lat: 49.059526, lng: 22.32589 },
    { lat: 49.0593773, lng: 22.3255094 },
    { lat: 49.0591671, lng: 22.3258247 },
    { lat: 49.0571863, lng: 22.3229865 },
    { lat: 49.0567153, lng: 22.3221149 },
    { lat: 49.0567504, lng: 22.3206613 },
    { lat: 49.0566194, lng: 22.3196423 },
    { lat: 49.056633, lng: 22.3194856 },
    { lat: 49.0566583, lng: 22.3195109 },
    { lat: 49.0569996, lng: 22.3183891 },
    { lat: 49.0558862, lng: 22.317772 },
    { lat: 49.0560379, lng: 22.3172868 },
    { lat: 49.0559244, lng: 22.3169324 },
    { lat: 49.0555689, lng: 22.3168186 },
    { lat: 49.0546984, lng: 22.3153148 },
    { lat: 49.0538542, lng: 22.3153326 },
    { lat: 49.0527161, lng: 22.3151766 },
    { lat: 49.0522778, lng: 22.3151895 },
    { lat: 49.0520633, lng: 22.3150439 },
    { lat: 49.0513813, lng: 22.3141841 },
    { lat: 49.0528397, lng: 22.3119939 },
    { lat: 49.0530459, lng: 22.3119094 },
    { lat: 49.0535965, lng: 22.3113114 },
    { lat: 49.0539841, lng: 22.3099391 },
    { lat: 49.0538687, lng: 22.3096032 },
    { lat: 49.0536043, lng: 22.3093342 },
    { lat: 49.0534967, lng: 22.3090861 },
    { lat: 49.0535172, lng: 22.3088922 },
    { lat: 49.0532967, lng: 22.3085253 },
    { lat: 49.0530643, lng: 22.307661 },
    { lat: 49.05266, lng: 22.307071 },
    { lat: 49.0527021, lng: 22.3066741 },
    { lat: 49.0528683, lng: 22.3060337 },
    { lat: 49.0526367, lng: 22.3057319 },
    { lat: 49.0528427, lng: 22.3048105 },
    { lat: 49.0526042, lng: 22.3044969 },
    { lat: 49.0524041, lng: 22.3046554 },
    { lat: 49.051559, lng: 22.305872 },
    { lat: 49.0512798, lng: 22.3056039 },
    { lat: 49.0508238, lng: 22.3063004 },
    { lat: 49.0507924, lng: 22.3062536 },
    { lat: 49.0506475, lng: 22.3064739 },
    { lat: 49.0504408, lng: 22.3058521 },
    { lat: 49.0502307, lng: 22.3054557 },
    { lat: 49.0498044, lng: 22.3061207 },
    { lat: 49.0496003, lng: 22.3062084 },
    { lat: 49.0494206, lng: 22.3064744 },
    { lat: 49.0489617, lng: 22.3063716 },
    { lat: 49.0483958, lng: 22.306414 },
    { lat: 49.0481309, lng: 22.3061175 },
    { lat: 49.0479229, lng: 22.3056561 },
    { lat: 49.0477418, lng: 22.305553 },
    { lat: 49.0474942, lng: 22.3051746 },
    { lat: 49.0472699, lng: 22.3052088 },
    { lat: 49.0473331, lng: 22.3039727 },
    { lat: 49.0469897, lng: 22.3037562 },
    { lat: 49.045265, lng: 22.3021646 },
    { lat: 49.0448387, lng: 22.3033085 },
    { lat: 49.0444612, lng: 22.3029073 },
    { lat: 49.0446237, lng: 22.3023055 },
    { lat: 49.0442885, lng: 22.3020527 },
    { lat: 49.0431786, lng: 22.3042268 },
    { lat: 49.0427969, lng: 22.303686 },
    { lat: 49.0434011, lng: 22.302825 },
    { lat: 49.043691, lng: 22.3022946 },
    { lat: 49.0439166, lng: 22.3017584 },
    { lat: 49.0437903, lng: 22.3016827 },
    { lat: 49.0432791, lng: 22.3010398 },
    { lat: 49.0429403, lng: 22.3011303 },
    { lat: 49.0425308, lng: 22.3008609 },
    { lat: 49.0422718, lng: 22.3001232 },
    { lat: 49.0416346, lng: 22.3004521 },
    { lat: 49.0407436, lng: 22.3001316 },
    { lat: 49.0396624, lng: 22.2990801 },
    { lat: 49.0391541, lng: 22.2993863 },
    { lat: 49.0388656, lng: 22.2992528 },
    { lat: 49.037788, lng: 22.2991396 },
    { lat: 49.0372338, lng: 22.2989466 },
    { lat: 49.0370491, lng: 22.2981566 },
    { lat: 49.0366182, lng: 22.2976886 },
    { lat: 49.036259, lng: 22.2972515 },
    { lat: 49.0360446, lng: 22.2973766 },
    { lat: 49.035345, lng: 22.2986699 },
    { lat: 49.0349101, lng: 22.2990968 },
    { lat: 49.0351473, lng: 22.2998634 },
    { lat: 49.0354317, lng: 22.3012585 },
    { lat: 49.0350721, lng: 22.3019439 },
    { lat: 49.0346749, lng: 22.3023947 },
    { lat: 49.03384, lng: 22.3027987 },
    { lat: 49.0331667, lng: 22.303635 },
    { lat: 49.0329351, lng: 22.3049412 },
    { lat: 49.0328164, lng: 22.305946 },
    { lat: 49.0323864, lng: 22.3065038 },
    { lat: 49.0317445, lng: 22.3078083 },
    { lat: 49.031604, lng: 22.3098876 },
    { lat: 49.0309666, lng: 22.3117757 },
    { lat: 49.0308927, lng: 22.312517 },
    { lat: 49.0296141, lng: 22.3146058 },
    { lat: 49.0290965, lng: 22.3169272 },
    { lat: 49.0287506, lng: 22.3181529 },
    { lat: 49.0286091, lng: 22.3192616 },
    { lat: 49.0277046, lng: 22.3200253 },
    { lat: 49.0268473, lng: 22.3208926 },
    { lat: 49.025995, lng: 22.3226803 },
    { lat: 49.0255036, lng: 22.3244042 },
    { lat: 49.0250186, lng: 22.3255456 },
    { lat: 49.0258587, lng: 22.3254866 },
    { lat: 49.0263493, lng: 22.3255508 },
    { lat: 49.0269748, lng: 22.325826 },
    { lat: 49.0273885, lng: 22.3261846 },
    { lat: 49.0282754, lng: 22.327106 },
    { lat: 49.0287408, lng: 22.3281375 },
    { lat: 49.0290661, lng: 22.3285762 },
    { lat: 49.0302753, lng: 22.330632 },
    { lat: 49.0320445, lng: 22.3324674 },
    { lat: 49.0327134, lng: 22.3332381 },
    { lat: 49.0331562, lng: 22.3335924 },
    { lat: 49.0331737, lng: 22.3338364 },
    { lat: 49.0337807, lng: 22.3342951 },
    { lat: 49.0370156, lng: 22.33746 },
    { lat: 49.0377463, lng: 22.3385002 },
    { lat: 49.0392204, lng: 22.3349692 },
    { lat: 49.0396478, lng: 22.3336145 },
    { lat: 49.0403439, lng: 22.3320852 },
    { lat: 49.0411056, lng: 22.3311649 },
    { lat: 49.0418805, lng: 22.330013 },
    { lat: 49.0425467, lng: 22.3296049 },
    { lat: 49.0429864, lng: 22.3285065 },
    { lat: 49.0436314, lng: 22.3276933 },
    { lat: 49.0439343, lng: 22.3265139 },
    { lat: 49.0444695, lng: 22.3264578 },
    { lat: 49.0446503, lng: 22.3264388 },
    { lat: 49.0449189, lng: 22.3270647 },
    { lat: 49.045144, lng: 22.3272288 },
    { lat: 49.0448974, lng: 22.3283097 },
    { lat: 49.0452187, lng: 22.3291387 },
    { lat: 49.0456706, lng: 22.3291195 },
    { lat: 49.0458303, lng: 22.329175 },
    { lat: 49.0461623, lng: 22.3300261 },
    { lat: 49.0465515, lng: 22.3312841 },
    { lat: 49.0470275, lng: 22.3308317 },
    { lat: 49.0479452, lng: 22.3307775 },
    { lat: 49.0488436, lng: 22.3311344 },
    { lat: 49.0496115, lng: 22.3308073 },
    { lat: 49.0503378, lng: 22.330597 },
    { lat: 49.0513008, lng: 22.330504 },
    { lat: 49.0524342, lng: 22.3302329 },
    { lat: 49.0529417, lng: 22.3301944 },
    { lat: 49.0534447, lng: 22.3300479 },
    { lat: 49.0552668, lng: 22.3291685 },
    { lat: 49.0565676, lng: 22.3287356 },
    { lat: 49.0583658, lng: 22.3286745 },
    { lat: 49.0605984, lng: 22.3287923 },
    { lat: 49.0612082, lng: 22.3298539 },
    { lat: 49.0617246, lng: 22.3302869 },
    { lat: 49.0625495, lng: 22.3306314 },
    { lat: 49.0626408, lng: 22.3301639 },
    { lat: 49.0618666, lng: 22.3291477 },
    { lat: 49.0609384, lng: 22.3281338 },
    { lat: 49.0605223, lng: 22.3278192 },
    { lat: 49.0600689, lng: 22.3270747 },
    { lat: 49.0600219, lng: 22.3268795 },
    { lat: 49.0599933, lng: 22.3266453 },
  ],
  Kolbasov: [
    { lat: 48.9880408, lng: 22.3637068 },
    { lat: 48.987962, lng: 22.3643885 },
    { lat: 48.9877133, lng: 22.3651741 },
    { lat: 48.9875195, lng: 22.3655267 },
    { lat: 48.9871821, lng: 22.3665311 },
    { lat: 48.9870881, lng: 22.3671477 },
    { lat: 48.986921, lng: 22.3678018 },
    { lat: 48.9865975, lng: 22.3686741 },
    { lat: 48.9866943, lng: 22.3693147 },
    { lat: 48.987301, lng: 22.3718976 },
    { lat: 48.9874386, lng: 22.3723424 },
    { lat: 48.9877065, lng: 22.3728858 },
    { lat: 48.9879627, lng: 22.3736661 },
    { lat: 48.9883694, lng: 22.3753031 },
    { lat: 48.9884292, lng: 22.3763204 },
    { lat: 48.988455, lng: 22.3774432 },
    { lat: 48.9881487, lng: 22.3783286 },
    { lat: 48.9876824, lng: 22.3789353 },
    { lat: 48.9873948, lng: 22.3789414 },
    { lat: 48.987178, lng: 22.379063 },
    { lat: 48.9867928, lng: 22.379631 },
    { lat: 48.9868994, lng: 22.3810538 },
    { lat: 48.9871175, lng: 22.3817988 },
    { lat: 48.9870146, lng: 22.3837481 },
    { lat: 48.987339, lng: 22.3851131 },
    { lat: 48.9872802, lng: 22.3856359 },
    { lat: 48.9870291, lng: 22.3866836 },
    { lat: 48.9870171, lng: 22.3882547 },
    { lat: 48.9870934, lng: 22.3898008 },
    { lat: 48.9870164, lng: 22.3904236 },
    { lat: 48.9871018, lng: 22.3914382 },
    { lat: 48.9872796, lng: 22.3927799 },
    { lat: 48.9872597, lng: 22.3932815 },
    { lat: 48.9873035, lng: 22.393651 },
    { lat: 48.9872249, lng: 22.3939263 },
    { lat: 48.9872968, lng: 22.3953218 },
    { lat: 48.9869566, lng: 22.3959337 },
    { lat: 48.9865658, lng: 22.3968761 },
    { lat: 48.9854941, lng: 22.3988746 },
    { lat: 48.9852352, lng: 22.3994997 },
    { lat: 48.9847638, lng: 22.4011667 },
    { lat: 48.9845032, lng: 22.402422 },
    { lat: 48.9843202, lng: 22.4036669 },
    { lat: 48.9848201, lng: 22.4038623 },
    { lat: 48.9856738, lng: 22.4037585 },
    { lat: 48.9860822, lng: 22.4039268 },
    { lat: 48.9865967, lng: 22.4036944 },
    { lat: 48.9869743, lng: 22.4039429 },
    { lat: 48.9873627, lng: 22.4045541 },
    { lat: 48.9876101, lng: 22.4047216 },
    { lat: 48.9883006, lng: 22.4048813 },
    { lat: 48.9888555, lng: 22.4046859 },
    { lat: 48.9895994, lng: 22.4050895 },
    { lat: 48.9898038, lng: 22.4058857 },
    { lat: 48.9902517, lng: 22.4069338 },
    { lat: 48.9905904, lng: 22.4075374 },
    { lat: 48.9909985, lng: 22.4080101 },
    { lat: 48.9912243, lng: 22.4085987 },
    { lat: 48.9916162, lng: 22.4100263 },
    { lat: 48.9915692, lng: 22.4106885 },
    { lat: 48.9917903, lng: 22.4113726 },
    { lat: 48.9923398, lng: 22.4120481 },
    { lat: 48.993332, lng: 22.4119504 },
    { lat: 48.9938182, lng: 22.4118597 },
    { lat: 48.9942118, lng: 22.4120231 },
    { lat: 48.9946859, lng: 22.4120558 },
    { lat: 48.995172, lng: 22.4118599 },
    { lat: 48.9955014, lng: 22.4115819 },
    { lat: 48.9958327, lng: 22.4112859 },
    { lat: 48.9964394, lng: 22.4104435 },
    { lat: 48.9967598, lng: 22.4102795 },
    { lat: 48.9973323, lng: 22.4101714 },
    { lat: 48.9987414, lng: 22.4100607 },
    { lat: 48.999574, lng: 22.4096543 },
    { lat: 49.0002352, lng: 22.4101397 },
    { lat: 49.001291, lng: 22.4106631 },
    { lat: 49.0016857, lng: 22.4107201 },
    { lat: 49.0024024, lng: 22.4104156 },
    { lat: 49.0032819, lng: 22.4106885 },
    { lat: 49.0037429, lng: 22.4111671 },
    { lat: 49.0045959, lng: 22.4116255 },
    { lat: 49.0051015, lng: 22.4117108 },
    { lat: 49.0055929, lng: 22.4111162 },
    { lat: 49.0062408, lng: 22.4108559 },
    { lat: 49.0069803, lng: 22.4096923 },
    { lat: 49.0068966, lng: 22.4087485 },
    { lat: 49.0067026, lng: 22.4082947 },
    { lat: 49.0066501, lng: 22.4076066 },
    { lat: 49.0067675, lng: 22.4063851 },
    { lat: 49.0067197, lng: 22.4043643 },
    { lat: 49.0067985, lng: 22.4037676 },
    { lat: 49.0068309, lng: 22.403007 },
    { lat: 49.0066555, lng: 22.4020888 },
    { lat: 49.0063963, lng: 22.4012072 },
    { lat: 49.0063766, lng: 22.3993967 },
    { lat: 49.0074093, lng: 22.3987217 },
    { lat: 49.0078761, lng: 22.3986154 },
    { lat: 49.0078749, lng: 22.3979599 },
    { lat: 49.0079904, lng: 22.3968148 },
    { lat: 49.0080537, lng: 22.3954888 },
    { lat: 49.0084379, lng: 22.3952585 },
    { lat: 49.0088017, lng: 22.3952223 },
    { lat: 49.0092601, lng: 22.394886 },
    { lat: 49.0097915, lng: 22.3945937 },
    { lat: 49.0100558, lng: 22.394045 },
    { lat: 49.0102672, lng: 22.3937765 },
    { lat: 49.0108766, lng: 22.3935063 },
    { lat: 49.0110246, lng: 22.3929431 },
    { lat: 49.0114105, lng: 22.3925218 },
    { lat: 49.0117683, lng: 22.3917858 },
    { lat: 49.0120793, lng: 22.3912735 },
    { lat: 49.0127333, lng: 22.3905583 },
    { lat: 49.0137323, lng: 22.3897178 },
    { lat: 49.0144925, lng: 22.389232 },
    { lat: 49.0161805, lng: 22.3890183 },
    { lat: 49.0169441, lng: 22.3886943 },
    { lat: 49.0181615, lng: 22.3886697 },
    { lat: 49.0181921, lng: 22.3884654 },
    { lat: 49.0182952, lng: 22.3883393 },
    { lat: 49.0185612, lng: 22.3882547 },
    { lat: 49.0187841, lng: 22.3885907 },
    { lat: 49.0188067, lng: 22.3888143 },
    { lat: 49.0196439, lng: 22.3890457 },
    { lat: 49.0201343, lng: 22.3893264 },
    { lat: 49.0207657, lng: 22.3895531 },
    { lat: 49.021478, lng: 22.3896838 },
    { lat: 49.0226393, lng: 22.3901005 },
    { lat: 49.0239051, lng: 22.3898094 },
    { lat: 49.02443, lng: 22.3897915 },
    { lat: 49.0252864, lng: 22.3906261 },
    { lat: 49.0255579, lng: 22.3904338 },
    { lat: 49.0258303, lng: 22.3905002 },
    { lat: 49.0263455, lng: 22.3909884 },
    { lat: 49.0266731, lng: 22.3907872 },
    { lat: 49.026912, lng: 22.3907613 },
    { lat: 49.0275075, lng: 22.391113 },
    { lat: 49.0283235, lng: 22.3921067 },
    { lat: 49.0289492, lng: 22.392448 },
    { lat: 49.0291353, lng: 22.392459 },
    { lat: 49.0294355, lng: 22.3924768 },
    { lat: 49.0296058, lng: 22.3923373 },
    { lat: 49.0302135, lng: 22.392414 },
    { lat: 49.0304891, lng: 22.3923645 },
    { lat: 49.0310004, lng: 22.3916928 },
    { lat: 49.0315709, lng: 22.3914469 },
    { lat: 49.0333991, lng: 22.3918511 },
    { lat: 49.0345662, lng: 22.3917512 },
    { lat: 49.0352603, lng: 22.3916918 },
    { lat: 49.0348117, lng: 22.3909429 },
    { lat: 49.0337956, lng: 22.3888306 },
    { lat: 49.03362, lng: 22.3886055 },
    { lat: 49.0334229, lng: 22.3879484 },
    { lat: 49.0327512, lng: 22.3866478 },
    { lat: 49.0325443, lng: 22.3862472 },
    { lat: 49.0323714, lng: 22.3859124 },
    { lat: 49.0322062, lng: 22.3855926 },
    { lat: 49.0319465, lng: 22.3848486 },
    { lat: 49.0314279, lng: 22.3838764 },
    { lat: 49.030516, lng: 22.3813697 },
    { lat: 49.0301358, lng: 22.3808317 },
    { lat: 49.0299515, lng: 22.3804149 },
    { lat: 49.0296482, lng: 22.3791377 },
    { lat: 49.0291291, lng: 22.3782573 },
    { lat: 49.0289981, lng: 22.3777534 },
    { lat: 49.0285952, lng: 22.3772548 },
    { lat: 49.0281891, lng: 22.3765041 },
    { lat: 49.027889, lng: 22.3762983 },
    { lat: 49.0272122, lng: 22.3752491 },
    { lat: 49.0263139, lng: 22.3734377 },
    { lat: 49.026114, lng: 22.3727669 },
    { lat: 49.026027, lng: 22.3720494 },
    { lat: 49.0258003, lng: 22.371437 },
    { lat: 49.0256876, lng: 22.370676 },
    { lat: 49.0253136, lng: 22.369215 },
    { lat: 49.0252296, lng: 22.3686414 },
    { lat: 49.0248677, lng: 22.3677525 },
    { lat: 49.0245912, lng: 22.365679 },
    { lat: 49.0247264, lng: 22.3649532 },
    { lat: 49.0245585, lng: 22.3639236 },
    { lat: 49.024536, lng: 22.3633983 },
    { lat: 49.0246107, lng: 22.3627855 },
    { lat: 49.0247062, lng: 22.3624665 },
    { lat: 49.0250322, lng: 22.3617697 },
    { lat: 49.0249372, lng: 22.3613092 },
    { lat: 49.024911, lng: 22.3600301 },
    { lat: 49.0251983, lng: 22.3594097 },
    { lat: 49.0252691, lng: 22.3589948 },
    { lat: 49.0250717, lng: 22.3584867 },
    { lat: 49.0245803, lng: 22.3580618 },
    { lat: 49.0240565, lng: 22.3564947 },
    { lat: 49.0243238, lng: 22.3557839 },
    { lat: 49.0243713, lng: 22.355316 },
    { lat: 49.0245748, lng: 22.3546356 },
    { lat: 49.0241155, lng: 22.3526543 },
    { lat: 49.0240837, lng: 22.3525842 },
    { lat: 49.0236682, lng: 22.3528849 },
    { lat: 49.0232437, lng: 22.3533365 },
    { lat: 49.0226967, lng: 22.3541539 },
    { lat: 49.0223294, lng: 22.3549205 },
    { lat: 49.0221232, lng: 22.3546325 },
    { lat: 49.0220621, lng: 22.3542453 },
    { lat: 49.0219163, lng: 22.3538368 },
    { lat: 49.0217627, lng: 22.3537634 },
    { lat: 49.0215711, lng: 22.3534195 },
    { lat: 49.0213814, lng: 22.3527537 },
    { lat: 49.0213333, lng: 22.3523437 },
    { lat: 49.0211487, lng: 22.3517551 },
    { lat: 49.0212049, lng: 22.3512266 },
    { lat: 49.0210164, lng: 22.350553 },
    { lat: 49.0205127, lng: 22.3492445 },
    { lat: 49.0200348, lng: 22.3486145 },
    { lat: 49.0194814, lng: 22.3484493 },
    { lat: 49.0188483, lng: 22.3480312 },
    { lat: 49.0184657, lng: 22.347599 },
    { lat: 49.0182649, lng: 22.346889 },
    { lat: 49.0180931, lng: 22.3458943 },
    { lat: 49.0165167, lng: 22.3439606 },
    { lat: 49.0160261, lng: 22.3438408 },
    { lat: 49.0155042, lng: 22.3438927 },
    { lat: 49.0152103, lng: 22.3437231 },
    { lat: 49.0149106, lng: 22.3436379 },
    { lat: 49.0132997, lng: 22.3423098 },
    { lat: 49.0129747, lng: 22.3417781 },
    { lat: 49.0125995, lng: 22.3423315 },
    { lat: 49.0122129, lng: 22.343075 },
    { lat: 49.0121891, lng: 22.3443337 },
    { lat: 49.012306, lng: 22.3450047 },
    { lat: 49.012221, lng: 22.3450863 },
    { lat: 49.0119773, lng: 22.3461025 },
    { lat: 49.0115139, lng: 22.3474201 },
    { lat: 49.0107834, lng: 22.3480769 },
    { lat: 49.0099834, lng: 22.3493724 },
    { lat: 49.0082813, lng: 22.3496644 },
    { lat: 49.007039, lng: 22.3497363 },
    { lat: 49.0066556, lng: 22.349316 },
    { lat: 49.0045046, lng: 22.3489728 },
    { lat: 49.0038959, lng: 22.3484855 },
    { lat: 49.0034135, lng: 22.3487973 },
    { lat: 49.0026133, lng: 22.3496717 },
    { lat: 49.0023873, lng: 22.3500943 },
    { lat: 49.0020476, lng: 22.3512494 },
    { lat: 49.0018164, lng: 22.3515921 },
    { lat: 49.0014106, lng: 22.3523886 },
    { lat: 49.0009531, lng: 22.3529813 },
    { lat: 49.0006388, lng: 22.3539048 },
    { lat: 49.0002211, lng: 22.3544113 },
    { lat: 48.9998773, lng: 22.3549537 },
    { lat: 48.998985, lng: 22.3557801 },
    { lat: 48.9986159, lng: 22.356198 },
    { lat: 48.9982291, lng: 22.3556658 },
    { lat: 48.997821, lng: 22.3563674 },
    { lat: 48.997839, lng: 22.3566795 },
    { lat: 48.9975941, lng: 22.3570676 },
    { lat: 48.9965644, lng: 22.3571386 },
    { lat: 48.9959665, lng: 22.3570505 },
    { lat: 48.9953975, lng: 22.357154 },
    { lat: 48.9940711, lng: 22.3571425 },
    { lat: 48.9936658, lng: 22.3569099 },
    { lat: 48.9927433, lng: 22.3571067 },
    { lat: 48.9919098, lng: 22.357626 },
    { lat: 48.9916667, lng: 22.357875 },
    { lat: 48.9911516, lng: 22.358146 },
    { lat: 48.9905926, lng: 22.3589877 },
    { lat: 48.9902771, lng: 22.3595887 },
    { lat: 48.9901133, lng: 22.3603011 },
    { lat: 48.9894076, lng: 22.3609137 },
    { lat: 48.9886443, lng: 22.3618284 },
    { lat: 48.9883305, lng: 22.362507 },
    { lat: 48.9882487, lng: 22.3632204 },
    { lat: 48.9880408, lng: 22.3637068 },
  ],
  Kolonica: [
    { lat: 48.9410246, lng: 22.2913503 },
    { lat: 48.9413216, lng: 22.2914044 },
    { lat: 48.9417498, lng: 22.2916973 },
    { lat: 48.9424029, lng: 22.2923996 },
    { lat: 48.9432561, lng: 22.2928436 },
    { lat: 48.943656, lng: 22.2932417 },
    { lat: 48.9440374, lng: 22.2931907 },
    { lat: 48.9462572, lng: 22.2925624 },
    { lat: 48.9467342, lng: 22.2923036 },
    { lat: 48.9480187, lng: 22.2902044 },
    { lat: 48.9483508, lng: 22.2897522 },
    { lat: 48.9489952, lng: 22.2892929 },
    { lat: 48.9495129, lng: 22.2895166 },
    { lat: 48.9495588, lng: 22.2892448 },
    { lat: 48.9500896, lng: 22.2891941 },
    { lat: 48.950395, lng: 22.2893218 },
    { lat: 48.951317, lng: 22.2893659 },
    { lat: 48.9529726, lng: 22.290293 },
    { lat: 48.9534016, lng: 22.2902106 },
    { lat: 48.9539524, lng: 22.2904018 },
    { lat: 48.9545632, lng: 22.2907661 },
    { lat: 48.9553717, lng: 22.2910213 },
    { lat: 48.9560642, lng: 22.2913186 },
    { lat: 48.9567569, lng: 22.2924969 },
    { lat: 48.9570351, lng: 22.2927173 },
    { lat: 48.9572999, lng: 22.2932608 },
    { lat: 48.957648, lng: 22.2936964 },
    { lat: 48.9578227, lng: 22.2937627 },
    { lat: 48.9579983, lng: 22.2938291 },
    { lat: 48.9585386, lng: 22.2937929 },
    { lat: 48.9598431, lng: 22.293577 },
    { lat: 48.9607274, lng: 22.2932358 },
    { lat: 48.9616991, lng: 22.2925115 },
    { lat: 48.9620168, lng: 22.2921243 },
    { lat: 48.9629064, lng: 22.2915595 },
    { lat: 48.9639093, lng: 22.2911868 },
    { lat: 48.9651373, lng: 22.2904603 },
    { lat: 48.9654384, lng: 22.2899809 },
    { lat: 48.9661243, lng: 22.2884877 },
    { lat: 48.9664402, lng: 22.2873464 },
    { lat: 48.9672844, lng: 22.2852692 },
    { lat: 48.9677624, lng: 22.2845495 },
    { lat: 48.968117, lng: 22.2841511 },
    { lat: 48.9684811, lng: 22.2840966 },
    { lat: 48.9691104, lng: 22.2838589 },
    { lat: 48.9696855, lng: 22.283464 },
    { lat: 48.9700172, lng: 22.2828437 },
    { lat: 48.9707249, lng: 22.281685 },
    { lat: 48.9711301, lng: 22.2808272 },
    { lat: 48.9717491, lng: 22.2784378 },
    { lat: 48.9718687, lng: 22.2777312 },
    { lat: 48.9722482, lng: 22.2764787 },
    { lat: 48.9722612, lng: 22.2748173 },
    { lat: 48.9728329, lng: 22.2736073 },
    { lat: 48.9733001, lng: 22.2731619 },
    { lat: 48.9736664, lng: 22.2726912 },
    { lat: 48.9738192, lng: 22.2722248 },
    { lat: 48.9738996, lng: 22.2712302 },
    { lat: 48.9743366, lng: 22.2701837 },
    { lat: 48.9753324, lng: 22.2686664 },
    { lat: 48.9760732, lng: 22.2677297 },
    { lat: 48.9765094, lng: 22.2667011 },
    { lat: 48.9773285, lng: 22.2658496 },
    { lat: 48.9777741, lng: 22.2652487 },
    { lat: 48.9782634, lng: 22.2651991 },
    { lat: 48.978834, lng: 22.2639911 },
    { lat: 48.9788088, lng: 22.2635648 },
    { lat: 48.9790772, lng: 22.2630428 },
    { lat: 48.9792444, lng: 22.2621624 },
    { lat: 48.978911, lng: 22.2617265 },
    { lat: 48.978622, lng: 22.2611694 },
    { lat: 48.9784436, lng: 22.2599225 },
    { lat: 48.9782736, lng: 22.2595023 },
    { lat: 48.9781986, lng: 22.2589576 },
    { lat: 48.9781779, lng: 22.2579766 },
    { lat: 48.978115, lng: 22.2573625 },
    { lat: 48.9781204, lng: 22.2563895 },
    { lat: 48.9787877, lng: 22.2541198 },
    { lat: 48.9792368, lng: 22.2523067 },
    { lat: 48.9790807, lng: 22.2516655 },
    { lat: 48.9790341, lng: 22.2504771 },
    { lat: 48.9790996, lng: 22.2492245 },
    { lat: 48.9792343, lng: 22.2484428 },
    { lat: 48.979145, lng: 22.2465977 },
    { lat: 48.9788523, lng: 22.2445189 },
    { lat: 48.978917, lng: 22.2427247 },
    { lat: 48.9787951, lng: 22.2417918 },
    { lat: 48.9782567, lng: 22.2405449 },
    { lat: 48.9775193, lng: 22.2390987 },
    { lat: 48.9766985, lng: 22.2380432 },
    { lat: 48.9750398, lng: 22.2348312 },
    { lat: 48.9746661, lng: 22.2338677 },
    { lat: 48.9742702, lng: 22.2333071 },
    { lat: 48.9738805, lng: 22.2325206 },
    { lat: 48.9731329, lng: 22.2314876 },
    { lat: 48.9732364, lng: 22.2303284 },
    { lat: 48.9731602, lng: 22.2293346 },
    { lat: 48.9726621, lng: 22.2275306 },
    { lat: 48.9723329, lng: 22.2267929 },
    { lat: 48.9714266, lng: 22.2250953 },
    { lat: 48.9711486, lng: 22.2248909 },
    { lat: 48.9705717, lng: 22.2231877 },
    { lat: 48.9705191, lng: 22.223397 },
    { lat: 48.9701393, lng: 22.223509 },
    { lat: 48.9697984, lng: 22.2233566 },
    { lat: 48.9696843, lng: 22.2234395 },
    { lat: 48.9687455, lng: 22.2231927 },
    { lat: 48.9686195, lng: 22.2233449 },
    { lat: 48.9681915, lng: 22.2235747 },
    { lat: 48.9679775, lng: 22.2236043 },
    { lat: 48.9677395, lng: 22.223428 },
    { lat: 48.9676437, lng: 22.2234648 },
    { lat: 48.9675624, lng: 22.2233487 },
    { lat: 48.9673066, lng: 22.2233781 },
    { lat: 48.9671871, lng: 22.2234723 },
    { lat: 48.9669192, lng: 22.2234135 },
    { lat: 48.9666908, lng: 22.2236541 },
    { lat: 48.9666061, lng: 22.2239416 },
    { lat: 48.9664147, lng: 22.2240267 },
    { lat: 48.9662855, lng: 22.2242366 },
    { lat: 48.9661779, lng: 22.2241552 },
    { lat: 48.9661086, lng: 22.2242683 },
    { lat: 48.9659279, lng: 22.2240937 },
    { lat: 48.965775, lng: 22.2240991 },
    { lat: 48.965574, lng: 22.2239098 },
    { lat: 48.9655595, lng: 22.2237165 },
    { lat: 48.9652713, lng: 22.2238026 },
    { lat: 48.9652359, lng: 22.2237232 },
    { lat: 48.9650333, lng: 22.2237688 },
    { lat: 48.9648755, lng: 22.2236516 },
    { lat: 48.9648001, lng: 22.2237565 },
    { lat: 48.9647249, lng: 22.2237554 },
    { lat: 48.9645841, lng: 22.2236037 },
    { lat: 48.9639177, lng: 22.2237216 },
    { lat: 48.9638145, lng: 22.2236389 },
    { lat: 48.9634915, lng: 22.2236756 },
    { lat: 48.9633715, lng: 22.2235905 },
    { lat: 48.9631045, lng: 22.2236767 },
    { lat: 48.9628969, lng: 22.2239433 },
    { lat: 48.9625394, lng: 22.2241511 },
    { lat: 48.9624544, lng: 22.224124 },
    { lat: 48.962364, lng: 22.2238961 },
    { lat: 48.9620836, lng: 22.2236894 },
    { lat: 48.9619894, lng: 22.223453 },
    { lat: 48.9616019, lng: 22.2232737 },
    { lat: 48.961506, lng: 22.222906 },
    { lat: 48.9612629, lng: 22.2227951 },
    { lat: 48.9612041, lng: 22.2226317 },
    { lat: 48.9610292, lng: 22.2227585 },
    { lat: 48.9606937, lng: 22.222792 },
    { lat: 48.9605117, lng: 22.2229614 },
    { lat: 48.9604225, lng: 22.2231868 },
    { lat: 48.9602655, lng: 22.223312 },
    { lat: 48.9601655, lng: 22.2232667 },
    { lat: 48.9600226, lng: 22.2228621 },
    { lat: 48.9596969, lng: 22.222724 },
    { lat: 48.959572, lng: 22.222529 },
    { lat: 48.9593676, lng: 22.2225446 },
    { lat: 48.9592948, lng: 22.2227541 },
    { lat: 48.9591637, lng: 22.222627 },
    { lat: 48.9590199, lng: 22.2226352 },
    { lat: 48.9589627, lng: 22.2224932 },
    { lat: 48.9586222, lng: 22.2225574 },
    { lat: 48.9584798, lng: 22.2227229 },
    { lat: 48.958372, lng: 22.2225846 },
    { lat: 48.9581996, lng: 22.222664 },
    { lat: 48.9580488, lng: 22.2226128 },
    { lat: 48.958011, lng: 22.2224805 },
    { lat: 48.9578233, lng: 22.2223388 },
    { lat: 48.9577208, lng: 22.2221849 },
    { lat: 48.9574978, lng: 22.2223693 },
    { lat: 48.9573013, lng: 22.2223311 },
    { lat: 48.9572158, lng: 22.2222289 },
    { lat: 48.9569637, lng: 22.222325 },
    { lat: 48.9566197, lng: 22.2220783 },
    { lat: 48.9565971, lng: 22.2218683 },
    { lat: 48.9563262, lng: 22.2216325 },
    { lat: 48.956161, lng: 22.2217406 },
    { lat: 48.9560838, lng: 22.2216068 },
    { lat: 48.9560084, lng: 22.2215996 },
    { lat: 48.9551706, lng: 22.2217073 },
    { lat: 48.9545392, lng: 22.2216831 },
    { lat: 48.9544524, lng: 22.2216307 },
    { lat: 48.9543683, lng: 22.2216941 },
    { lat: 48.9540758, lng: 22.2212326 },
    { lat: 48.9539038, lng: 22.2213392 },
    { lat: 48.9536292, lng: 22.2208849 },
    { lat: 48.9533267, lng: 22.2208784 },
    { lat: 48.9530662, lng: 22.2207502 },
    { lat: 48.9527304, lng: 22.2205228 },
    { lat: 48.9526772, lng: 22.2204114 },
    { lat: 48.9525971, lng: 22.2204438 },
    { lat: 48.9524357, lng: 22.2201712 },
    { lat: 48.9522135, lng: 22.220445 },
    { lat: 48.9520911, lng: 22.2202553 },
    { lat: 48.9519548, lng: 22.2198941 },
    { lat: 48.9517478, lng: 22.2198357 },
    { lat: 48.9516167, lng: 22.2198669 },
    { lat: 48.9514367, lng: 22.2196586 },
    { lat: 48.9513652, lng: 22.2194358 },
    { lat: 48.9512182, lng: 22.2194495 },
    { lat: 48.9511438, lng: 22.2192841 },
    { lat: 48.9506662, lng: 22.2192054 },
    { lat: 48.9506106, lng: 22.2190827 },
    { lat: 48.9502012, lng: 22.2188806 },
    { lat: 48.9498966, lng: 22.2188455 },
    { lat: 48.9497262, lng: 22.2186143 },
    { lat: 48.9493358, lng: 22.218262 },
    { lat: 48.9489733, lng: 22.2181469 },
    { lat: 48.9483871, lng: 22.218188 },
    { lat: 48.948291, lng: 22.2181179 },
    { lat: 48.9481259, lng: 22.2181203 },
    { lat: 48.9479588, lng: 22.21789 },
    { lat: 48.9477606, lng: 22.2178784 },
    { lat: 48.9476853, lng: 22.2177395 },
    { lat: 48.9475267, lng: 22.2176862 },
    { lat: 48.9472057, lng: 22.2176653 },
    { lat: 48.9466362, lng: 22.217094 },
    { lat: 48.9464398, lng: 22.2171138 },
    { lat: 48.9461577, lng: 22.2169757 },
    { lat: 48.9460061, lng: 22.2167864 },
    { lat: 48.9455928, lng: 22.2165588 },
    { lat: 48.9454183, lng: 22.2165542 },
    { lat: 48.9450709, lng: 22.2164073 },
    { lat: 48.9448121, lng: 22.2164482 },
    { lat: 48.944485, lng: 22.216316 },
    { lat: 48.944296, lng: 22.2163179 },
    { lat: 48.9436614, lng: 22.216047 },
    { lat: 48.9433647, lng: 22.2161118 },
    { lat: 48.9431933, lng: 22.2159418 },
    { lat: 48.9429963, lng: 22.2158787 },
    { lat: 48.9428988, lng: 22.2156529 },
    { lat: 48.9423581, lng: 22.2154105 },
    { lat: 48.9419445, lng: 22.2150749 },
    { lat: 48.9416672, lng: 22.2150495 },
    { lat: 48.9414651, lng: 22.2146665 },
    { lat: 48.9413222, lng: 22.2145071 },
    { lat: 48.9409986, lng: 22.2143694 },
    { lat: 48.9403884, lng: 22.2139509 },
    { lat: 48.9390291, lng: 22.2139156 },
    { lat: 48.9378314, lng: 22.2136775 },
    { lat: 48.9365634, lng: 22.2137061 },
    { lat: 48.9357555, lng: 22.2139884 },
    { lat: 48.9346739, lng: 22.2137587 },
    { lat: 48.9337892, lng: 22.2138239 },
    { lat: 48.9330258, lng: 22.213646 },
    { lat: 48.9321074, lng: 22.2136579 },
    { lat: 48.9308765, lng: 22.2135465 },
    { lat: 48.929705, lng: 22.213641 },
    { lat: 48.929827, lng: 22.214041 },
    { lat: 48.929817, lng: 22.214325 },
    { lat: 48.929667, lng: 22.215253 },
    { lat: 48.928976, lng: 22.216216 },
    { lat: 48.928749, lng: 22.216359 },
    { lat: 48.928447, lng: 22.216535 },
    { lat: 48.927994, lng: 22.216831 },
    { lat: 48.927188, lng: 22.21725 },
    { lat: 48.926902, lng: 22.218219 },
    { lat: 48.926665, lng: 22.219139 },
    { lat: 48.926921, lng: 22.220428 },
    { lat: 48.926963, lng: 22.221218 },
    { lat: 48.926945, lng: 22.22187 },
    { lat: 48.92698, lng: 22.222261 },
    { lat: 48.926988, lng: 22.222878 },
    { lat: 48.927197, lng: 22.223589 },
    { lat: 48.927287, lng: 22.22374 },
    { lat: 48.927414, lng: 22.224385 },
    { lat: 48.92734, lng: 22.2250369 },
    { lat: 48.9271064, lng: 22.2255526 },
    { lat: 48.9270859, lng: 22.2256896 },
    { lat: 48.9271116, lng: 22.2259823 },
    { lat: 48.927449, lng: 22.2267364 },
    { lat: 48.9277105, lng: 22.2272143 },
    { lat: 48.9277273, lng: 22.2274613 },
    { lat: 48.9276877, lng: 22.2288594 },
    { lat: 48.9276222, lng: 22.229145 },
    { lat: 48.9268758, lng: 22.2299221 },
    { lat: 48.925555, lng: 22.231007 },
    { lat: 48.9250827, lng: 22.2307119 },
    { lat: 48.9248092, lng: 22.2308559 },
    { lat: 48.9243694, lng: 22.2310023 },
    { lat: 48.9238808, lng: 22.2313822 },
    { lat: 48.9231255, lng: 22.2316565 },
    { lat: 48.9224651, lng: 22.2317534 },
    { lat: 48.9221794, lng: 22.231947 },
    { lat: 48.9218908, lng: 22.2319236 },
    { lat: 48.9217644, lng: 22.2319526 },
    { lat: 48.921464, lng: 22.23209 },
    { lat: 48.921342, lng: 22.2321686 },
    { lat: 48.9212251, lng: 22.2321292 },
    { lat: 48.9208021, lng: 22.2326763 },
    { lat: 48.9197627, lng: 22.2329716 },
    { lat: 48.9194167, lng: 22.2331382 },
    { lat: 48.9188025, lng: 22.234037 },
    { lat: 48.9185196, lng: 22.2341667 },
    { lat: 48.9180112, lng: 22.234062 },
    { lat: 48.9178286, lng: 22.2340428 },
    { lat: 48.9176886, lng: 22.2342202 },
    { lat: 48.9175026, lng: 22.2348513 },
    { lat: 48.917656, lng: 22.2355801 },
    { lat: 48.9174483, lng: 22.2369075 },
    { lat: 48.9172288, lng: 22.2388691 },
    { lat: 48.9175672, lng: 22.2403648 },
    { lat: 48.9177105, lng: 22.2413491 },
    { lat: 48.9178305, lng: 22.2436044 },
    { lat: 48.9181495, lng: 22.2453021 },
    { lat: 48.9190272, lng: 22.2474422 },
    { lat: 48.9199151, lng: 22.2493894 },
    { lat: 48.9206469, lng: 22.2501312 },
    { lat: 48.9210494, lng: 22.250637 },
    { lat: 48.9213412, lng: 22.2511167 },
    { lat: 48.9222836, lng: 22.2513157 },
    { lat: 48.9226424, lng: 22.2515631 },
    { lat: 48.9237097, lng: 22.2518644 },
    { lat: 48.9244025, lng: 22.2528268 },
    { lat: 48.9250645, lng: 22.2535702 },
    { lat: 48.9259649, lng: 22.2561365 },
    { lat: 48.9261954, lng: 22.2563791 },
    { lat: 48.9274604, lng: 22.2568764 },
    { lat: 48.9279582, lng: 22.2572159 },
    { lat: 48.9289483, lng: 22.258291 },
    { lat: 48.9295273, lng: 22.2592068 },
    { lat: 48.9301076, lng: 22.2605165 },
    { lat: 48.9313045, lng: 22.262567 },
    { lat: 48.9316148, lng: 22.2633743 },
    { lat: 48.9323836, lng: 22.2636553 },
    { lat: 48.932619, lng: 22.2636175 },
    { lat: 48.9338328, lng: 22.2672517 },
    { lat: 48.9338325, lng: 22.2678459 },
    { lat: 48.9342765, lng: 22.2690643 },
    { lat: 48.9349041, lng: 22.2722297 },
    { lat: 48.9356392, lng: 22.2742523 },
    { lat: 48.9366237, lng: 22.2760572 },
    { lat: 48.936748, lng: 22.2761915 },
    { lat: 48.9374288, lng: 22.2778381 },
    { lat: 48.9385375, lng: 22.2813605 },
    { lat: 48.9389913, lng: 22.2817425 },
    { lat: 48.9394464, lng: 22.2818308 },
    { lat: 48.9392039, lng: 22.2823657 },
    { lat: 48.9391093, lng: 22.284257 },
    { lat: 48.9391415, lng: 22.2857246 },
    { lat: 48.9398497, lng: 22.2873088 },
    { lat: 48.9401654, lng: 22.2890362 },
    { lat: 48.9401814, lng: 22.2897685 },
    { lat: 48.9393503, lng: 22.29044 },
    { lat: 48.9410246, lng: 22.2913503 },
  ],
  UličskéKrivé: [
    { lat: 49.0270094, lng: 22.4387628 },
    { lat: 49.0265319, lng: 22.4377972 },
    { lat: 49.0263147, lng: 22.4365852 },
    { lat: 49.0261394, lng: 22.436266 },
    { lat: 49.0257807, lng: 22.4353327 },
    { lat: 49.0248976, lng: 22.4339532 },
    { lat: 49.0245414, lng: 22.4338587 },
    { lat: 49.0242139, lng: 22.4335715 },
    { lat: 49.0240438, lng: 22.4329586 },
    { lat: 49.0237385, lng: 22.4323346 },
    { lat: 49.0231982, lng: 22.4317079 },
    { lat: 49.0226495, lng: 22.4303978 },
    { lat: 49.0224924, lng: 22.4300929 },
    { lat: 49.0220801, lng: 22.4295069 },
    { lat: 49.0216544, lng: 22.4286082 },
    { lat: 49.0199999, lng: 22.4262603 },
    { lat: 49.0191688, lng: 22.4254386 },
    { lat: 49.0190168, lng: 22.4250382 },
    { lat: 49.0181151, lng: 22.4233729 },
    { lat: 49.016775, lng: 22.4207209 },
    { lat: 49.0162663, lng: 22.4198388 },
    { lat: 49.0155626, lng: 22.419726 },
    { lat: 49.0148425, lng: 22.4196106 },
    { lat: 49.0131572, lng: 22.4185488 },
    { lat: 49.0125116, lng: 22.417893 },
    { lat: 49.0115721, lng: 22.416466 },
    { lat: 49.0109359, lng: 22.4152312 },
    { lat: 49.0102375, lng: 22.4143623 },
    { lat: 49.0095898, lng: 22.4133444 },
    { lat: 49.0089161, lng: 22.412574 },
    { lat: 49.0084588, lng: 22.4118437 },
    { lat: 49.0078542, lng: 22.4112716 },
    { lat: 49.0074475, lng: 22.4105812 },
    { lat: 49.0069803, lng: 22.4096923 },
    { lat: 49.0062408, lng: 22.4108559 },
    { lat: 49.0055929, lng: 22.4111162 },
    { lat: 49.0051015, lng: 22.4117108 },
    { lat: 49.0045959, lng: 22.4116255 },
    { lat: 49.0037429, lng: 22.4111671 },
    { lat: 49.0032819, lng: 22.4106885 },
    { lat: 49.0024024, lng: 22.4104156 },
    { lat: 49.0016857, lng: 22.4107201 },
    { lat: 49.001291, lng: 22.4106631 },
    { lat: 49.0002352, lng: 22.4101397 },
    { lat: 48.999574, lng: 22.4096543 },
    { lat: 48.9987414, lng: 22.4100607 },
    { lat: 48.9973323, lng: 22.4101714 },
    { lat: 48.9967598, lng: 22.4102795 },
    { lat: 48.9964394, lng: 22.4104435 },
    { lat: 48.9958327, lng: 22.4112859 },
    { lat: 48.9955014, lng: 22.4115819 },
    { lat: 48.995172, lng: 22.4118599 },
    { lat: 48.9946859, lng: 22.4120558 },
    { lat: 48.9942118, lng: 22.4120231 },
    { lat: 48.9938182, lng: 22.4118597 },
    { lat: 48.993332, lng: 22.4119504 },
    { lat: 48.9923398, lng: 22.4120481 },
    { lat: 48.9922006, lng: 22.4125945 },
    { lat: 48.991192, lng: 22.4121204 },
    { lat: 48.9909356, lng: 22.4132102 },
    { lat: 48.9905731, lng: 22.4139997 },
    { lat: 48.990307, lng: 22.4147247 },
    { lat: 48.9902972, lng: 22.4153894 },
    { lat: 48.9898581, lng: 22.4167712 },
    { lat: 48.9894587, lng: 22.4174185 },
    { lat: 48.9887842, lng: 22.4172105 },
    { lat: 48.9879398, lng: 22.4178135 },
    { lat: 48.9870038, lng: 22.4183349 },
    { lat: 48.9865814, lng: 22.418469 },
    { lat: 48.9861982, lng: 22.418753 },
    { lat: 48.9859605, lng: 22.4193939 },
    { lat: 48.9858082, lng: 22.420128 },
    { lat: 48.9845251, lng: 22.4211551 },
    { lat: 48.9844556, lng: 22.4213836 },
    { lat: 48.9844837, lng: 22.4221496 },
    { lat: 48.984431, lng: 22.4224281 },
    { lat: 48.9842866, lng: 22.4226774 },
    { lat: 48.9842371, lng: 22.4235434 },
    { lat: 48.9839979, lng: 22.4243203 },
    { lat: 48.9835819, lng: 22.4254083 },
    { lat: 48.983534, lng: 22.425977 },
    { lat: 48.9831689, lng: 22.4270327 },
    { lat: 48.9830474, lng: 22.4275734 },
    { lat: 48.9832997, lng: 22.4281976 },
    { lat: 48.9832999, lng: 22.4284697 },
    { lat: 48.9835087, lng: 22.4291086 },
    { lat: 48.9834919, lng: 22.4295508 },
    { lat: 48.9836212, lng: 22.4299249 },
    { lat: 48.9836271, lng: 22.4303818 },
    { lat: 48.9837335, lng: 22.4307427 },
    { lat: 48.9831799, lng: 22.4310047 },
    { lat: 48.9821379, lng: 22.4330961 },
    { lat: 48.9818492, lng: 22.4328911 },
    { lat: 48.9815805, lng: 22.4328985 },
    { lat: 48.9812505, lng: 22.4330904 },
    { lat: 48.9808197, lng: 22.4334689 },
    { lat: 48.9804179, lng: 22.4336144 },
    { lat: 48.9803979, lng: 22.4353199 },
    { lat: 48.9805411, lng: 22.4359364 },
    { lat: 48.9806068, lng: 22.4365079 },
    { lat: 48.9807689, lng: 22.4371214 },
    { lat: 48.9801071, lng: 22.4404766 },
    { lat: 48.9801981, lng: 22.4417419 },
    { lat: 48.980197, lng: 22.4427214 },
    { lat: 48.9800591, lng: 22.4434519 },
    { lat: 48.9798624, lng: 22.4441253 },
    { lat: 48.9795744, lng: 22.446088 },
    { lat: 48.9795665, lng: 22.4464743 },
    { lat: 48.9804076, lng: 22.4464145 },
    { lat: 48.9806365, lng: 22.4473735 },
    { lat: 48.9807109, lng: 22.4480398 },
    { lat: 48.9807044, lng: 22.4486549 },
    { lat: 48.9804591, lng: 22.4490932 },
    { lat: 48.9801922, lng: 22.4494073 },
    { lat: 48.9798151, lng: 22.4495547 },
    { lat: 48.9792815, lng: 22.4503439 },
    { lat: 48.9790432, lng: 22.4505141 },
    { lat: 48.9788141, lng: 22.451309 },
    { lat: 48.9785167, lng: 22.4514455 },
    { lat: 48.9780611, lng: 22.4518257 },
    { lat: 48.9775763, lng: 22.4525359 },
    { lat: 48.9768639, lng: 22.4533299 },
    { lat: 48.9763145, lng: 22.4542931 },
    { lat: 48.9771736, lng: 22.4559405 },
    { lat: 48.9773316, lng: 22.4564664 },
    { lat: 48.9773308, lng: 22.4568048 },
    { lat: 48.9774712, lng: 22.4578435 },
    { lat: 48.9772284, lng: 22.4580701 },
    { lat: 48.9767541, lng: 22.4586978 },
    { lat: 48.9766567, lng: 22.4589079 },
    { lat: 48.9763189, lng: 22.4596365 },
    { lat: 48.9761141, lng: 22.4602841 },
    { lat: 48.9758819, lng: 22.4606498 },
    { lat: 48.9751927, lng: 22.4609911 },
    { lat: 48.9744612, lng: 22.4618457 },
    { lat: 48.9745623, lng: 22.4627276 },
    { lat: 48.9743279, lng: 22.4640284 },
    { lat: 48.9742188, lng: 22.4643034 },
    { lat: 48.9742346, lng: 22.4656286 },
    { lat: 48.9748011, lng: 22.4672247 },
    { lat: 48.9750622, lng: 22.4676217 },
    { lat: 48.9752635, lng: 22.4682258 },
    { lat: 48.9754853, lng: 22.4691225 },
    { lat: 48.9754942, lng: 22.4694565 },
    { lat: 48.9751688, lng: 22.4693285 },
    { lat: 48.9744403, lng: 22.4694443 },
    { lat: 48.974293, lng: 22.469543 },
    { lat: 48.974431, lng: 22.471549 },
    { lat: 48.975135, lng: 22.47406 },
    { lat: 48.976279, lng: 22.475609 },
    { lat: 48.977792, lng: 22.475819 },
    { lat: 48.979387, lng: 22.475685 },
    { lat: 48.98085, lng: 22.476106 },
    { lat: 48.981845, lng: 22.47645 },
    { lat: 48.984139, lng: 22.477412 },
    { lat: 48.985294, lng: 22.477084 },
    { lat: 48.987276, lng: 22.477338 },
    { lat: 48.988599, lng: 22.47732 },
    { lat: 48.989613, lng: 22.477413 },
    { lat: 48.991182, lng: 22.477309 },
    { lat: 48.9915739, lng: 22.4779882 },
    { lat: 48.992066, lng: 22.478841 },
    { lat: 48.992835, lng: 22.47969 },
    { lat: 48.992838, lng: 22.479692 },
    { lat: 48.9933119, lng: 22.4796696 },
    { lat: 48.9954916, lng: 22.479132 },
    { lat: 48.9965567, lng: 22.4792456 },
    { lat: 48.9971297, lng: 22.479389 },
    { lat: 48.9979279, lng: 22.4797059 },
    { lat: 48.998311, lng: 22.4797536 },
    { lat: 48.9996105, lng: 22.4801177 },
    { lat: 49.0008671, lng: 22.4797979 },
    { lat: 49.0040797, lng: 22.4787325 },
    { lat: 49.0042761, lng: 22.4774324 },
    { lat: 49.0044195, lng: 22.4769633 },
    { lat: 49.0044741, lng: 22.4764933 },
    { lat: 49.0044784, lng: 22.4757019 },
    { lat: 49.0044181, lng: 22.4752248 },
    { lat: 49.0046002, lng: 22.4744458 },
    { lat: 49.0051142, lng: 22.4729464 },
    { lat: 49.0057991, lng: 22.4724486 },
    { lat: 49.0063182, lng: 22.471646 },
    { lat: 49.0065723, lng: 22.4695318 },
    { lat: 49.007042, lng: 22.4695241 },
    { lat: 49.0074494, lng: 22.4697543 },
    { lat: 49.008059, lng: 22.469869 },
    { lat: 49.0082855, lng: 22.4688368 },
    { lat: 49.0082874, lng: 22.4680443 },
    { lat: 49.0082348, lng: 22.4673368 },
    { lat: 49.0082658, lng: 22.4668787 },
    { lat: 49.0084771, lng: 22.4658167 },
    { lat: 49.0087583, lng: 22.4650423 },
    { lat: 49.0088331, lng: 22.4646906 },
    { lat: 49.0090426, lng: 22.4642623 },
    { lat: 49.0091434, lng: 22.4639108 },
    { lat: 49.0092893, lng: 22.4637249 },
    { lat: 49.0094885, lng: 22.4631731 },
    { lat: 49.0095144, lng: 22.462789 },
    { lat: 49.0103351, lng: 22.461234 },
    { lat: 49.0089208, lng: 22.4583797 },
    { lat: 49.0085806, lng: 22.4580242 },
    { lat: 49.0078197, lng: 22.4575528 },
    { lat: 49.0081145, lng: 22.4568365 },
    { lat: 49.0083322, lng: 22.456593 },
    { lat: 49.0084153, lng: 22.4561514 },
    { lat: 49.0087751, lng: 22.4559847 },
    { lat: 49.0094344, lng: 22.4558329 },
    { lat: 49.0096455, lng: 22.4558645 },
    { lat: 49.0099932, lng: 22.4557223 },
    { lat: 49.0103083, lng: 22.4555008 },
    { lat: 49.0106846, lng: 22.4549901 },
    { lat: 49.0107379, lng: 22.4547422 },
    { lat: 49.0109108, lng: 22.4545499 },
    { lat: 49.0112251, lng: 22.4545831 },
    { lat: 49.0120409, lng: 22.4548842 },
    { lat: 49.0125, lng: 22.4545634 },
    { lat: 49.0134108, lng: 22.4549428 },
    { lat: 49.0136824, lng: 22.4551933 },
    { lat: 49.0139276, lng: 22.455084 },
    { lat: 49.0153711, lng: 22.4533005 },
    { lat: 49.0159522, lng: 22.4524566 },
    { lat: 49.0166138, lng: 22.4522537 },
    { lat: 49.0173645, lng: 22.4519037 },
    { lat: 49.0179974, lng: 22.4513356 },
    { lat: 49.0184712, lng: 22.4505372 },
    { lat: 49.018819, lng: 22.4503262 },
    { lat: 49.0194623, lng: 22.4491145 },
    { lat: 49.0207256, lng: 22.4485359 },
    { lat: 49.0213028, lng: 22.448437 },
    { lat: 49.0214639, lng: 22.4481803 },
    { lat: 49.0218007, lng: 22.4473754 },
    { lat: 49.0225579, lng: 22.4462994 },
    { lat: 49.0230951, lng: 22.4451911 },
    { lat: 49.0231846, lng: 22.4445644 },
    { lat: 49.0235681, lng: 22.4433516 },
    { lat: 49.0245707, lng: 22.4417382 },
    { lat: 49.0255583, lng: 22.4409334 },
    { lat: 49.0259462, lng: 22.4405459 },
    { lat: 49.0261474, lng: 22.4400483 },
    { lat: 49.0262668, lng: 22.4395037 },
    { lat: 49.0265646, lng: 22.4391125 },
    { lat: 49.0268434, lng: 22.4388843 },
    { lat: 49.0270094, lng: 22.4387628 },
  ],
  Strihovce: [
    { lat: 48.8893005, lng: 22.2962365 },
    { lat: 48.8893185, lng: 22.2962413 },
    { lat: 48.8895307, lng: 22.2962749 },
    { lat: 48.8896939, lng: 22.2964862 },
    { lat: 48.8902751, lng: 22.2958526 },
    { lat: 48.8907135, lng: 22.2957438 },
    { lat: 48.8910305, lng: 22.2958297 },
    { lat: 48.8915796, lng: 22.2958504 },
    { lat: 48.8928571, lng: 22.29646 },
    { lat: 48.8932247, lng: 22.2963954 },
    { lat: 48.8937006, lng: 22.2960402 },
    { lat: 48.8942, lng: 22.2963561 },
    { lat: 48.8949353, lng: 22.2963442 },
    { lat: 48.8973396, lng: 22.2966414 },
    { lat: 48.8999363, lng: 22.2964245 },
    { lat: 48.9003249, lng: 22.2962952 },
    { lat: 48.902248, lng: 22.2961407 },
    { lat: 48.9025157, lng: 22.2959886 },
    { lat: 48.9026979, lng: 22.2956774 },
    { lat: 48.9030222, lng: 22.2955896 },
    { lat: 48.9035104, lng: 22.2959079 },
    { lat: 48.9038253, lng: 22.2960211 },
    { lat: 48.9045834, lng: 22.2957578 },
    { lat: 48.905128, lng: 22.294698 },
    { lat: 48.9057446, lng: 22.2950674 },
    { lat: 48.9062787, lng: 22.2941857 },
    { lat: 48.9065186, lng: 22.2943564 },
    { lat: 48.9067426, lng: 22.2941567 },
    { lat: 48.9070522, lng: 22.2935073 },
    { lat: 48.9076425, lng: 22.2930289 },
    { lat: 48.9081111, lng: 22.2923968 },
    { lat: 48.908336, lng: 22.2921946 },
    { lat: 48.9089247, lng: 22.2903079 },
    { lat: 48.9094183, lng: 22.2877363 },
    { lat: 48.9091226, lng: 22.2868148 },
    { lat: 48.9093287, lng: 22.2849834 },
    { lat: 48.9098515, lng: 22.2822036 },
    { lat: 48.9098654, lng: 22.2803794 },
    { lat: 48.9107766, lng: 22.2773682 },
    { lat: 48.9105432, lng: 22.2750391 },
    { lat: 48.9106173, lng: 22.2741143 },
    { lat: 48.9106126, lng: 22.2731831 },
    { lat: 48.9107523, lng: 22.2722807 },
    { lat: 48.9107353, lng: 22.2715096 },
    { lat: 48.9109161, lng: 22.2692435 },
    { lat: 48.9109697, lng: 22.2680073 },
    { lat: 48.9113082, lng: 22.2641385 },
    { lat: 48.9112921, lng: 22.2632057 },
    { lat: 48.911639, lng: 22.2608303 },
    { lat: 48.9117521, lng: 22.2589884 },
    { lat: 48.911892, lng: 22.2580348 },
    { lat: 48.911991, lng: 22.2563639 },
    { lat: 48.9122233, lng: 22.2558851 },
    { lat: 48.9123878, lng: 22.2552899 },
    { lat: 48.9132329, lng: 22.2533316 },
    { lat: 48.9141252, lng: 22.2523331 },
    { lat: 48.9145583, lng: 22.2517324 },
    { lat: 48.9151858, lng: 22.2512201 },
    { lat: 48.915552, lng: 22.2505362 },
    { lat: 48.915777, lng: 22.2499404 },
    { lat: 48.916166, lng: 22.2498293 },
    { lat: 48.9166237, lng: 22.2487135 },
    { lat: 48.9167874, lng: 22.2476319 },
    { lat: 48.9170613, lng: 22.2462841 },
    { lat: 48.9168259, lng: 22.2454629 },
    { lat: 48.9168925, lng: 22.2444614 },
    { lat: 48.9168009, lng: 22.2438417 },
    { lat: 48.9168854, lng: 22.2429366 },
    { lat: 48.9165689, lng: 22.2412446 },
    { lat: 48.9161607, lng: 22.2398394 },
    { lat: 48.9160543, lng: 22.2391079 },
    { lat: 48.9160326, lng: 22.238283 },
    { lat: 48.9157963, lng: 22.2375692 },
    { lat: 48.9157241, lng: 22.2368034 },
    { lat: 48.9157617, lng: 22.2359218 },
    { lat: 48.9155685, lng: 22.2351779 },
    { lat: 48.9138451, lng: 22.2357636 },
    { lat: 48.9133645, lng: 22.235646 },
    { lat: 48.913046, lng: 22.2357381 },
    { lat: 48.9127455, lng: 22.2357346 },
    { lat: 48.9125335, lng: 22.2357627 },
    { lat: 48.9123088, lng: 22.2357156 },
    { lat: 48.9120364, lng: 22.2357089 },
    { lat: 48.9116846, lng: 22.2355614 },
    { lat: 48.9110201, lng: 22.235832 },
    { lat: 48.910604, lng: 22.2360296 },
    { lat: 48.9101048, lng: 22.2362362 },
    { lat: 48.9096548, lng: 22.2363394 },
    { lat: 48.9092662, lng: 22.2361649 },
    { lat: 48.9090173, lng: 22.2360969 },
    { lat: 48.9083999, lng: 22.2357979 },
    { lat: 48.907823, lng: 22.2350503 },
    { lat: 48.9074545, lng: 22.2346667 },
    { lat: 48.9068277, lng: 22.2345444 },
    { lat: 48.9066289, lng: 22.2348539 },
    { lat: 48.9063324, lng: 22.2349825 },
    { lat: 48.9060605, lng: 22.2351417 },
    { lat: 48.9059457, lng: 22.2353417 },
    { lat: 48.9054587, lng: 22.2356996 },
    { lat: 48.9049164, lng: 22.2360357 },
    { lat: 48.9045309, lng: 22.2366525 },
    { lat: 48.9043271, lng: 22.2367896 },
    { lat: 48.9038306, lng: 22.2373224 },
    { lat: 48.9034919, lng: 22.2377752 },
    { lat: 48.9031392, lng: 22.2379499 },
    { lat: 48.902714, lng: 22.2384448 },
    { lat: 48.9021283, lng: 22.2389003 },
    { lat: 48.9018969, lng: 22.2392711 },
    { lat: 48.9012404, lng: 22.2395822 },
    { lat: 48.9010164, lng: 22.2398815 },
    { lat: 48.900783, lng: 22.2400607 },
    { lat: 48.9007313, lng: 22.2401599 },
    { lat: 48.8992865, lng: 22.240687 },
    { lat: 48.8976425, lng: 22.240747 },
    { lat: 48.8967968, lng: 22.2408466 },
    { lat: 48.8965838, lng: 22.2410823 },
    { lat: 48.8964912, lng: 22.241422 },
    { lat: 48.8960875, lng: 22.241531 },
    { lat: 48.8959381, lng: 22.2416885 },
    { lat: 48.8957124, lng: 22.2417043 },
    { lat: 48.895487, lng: 22.2417852 },
    { lat: 48.8953536, lng: 22.2419522 },
    { lat: 48.8951554, lng: 22.2420485 },
    { lat: 48.8948073, lng: 22.2422183 },
    { lat: 48.894421, lng: 22.2426502 },
    { lat: 48.8942446, lng: 22.2428183 },
    { lat: 48.8938045, lng: 22.2429485 },
    { lat: 48.8931874, lng: 22.242969 },
    { lat: 48.8928303, lng: 22.2433873 },
    { lat: 48.8920155, lng: 22.2437779 },
    { lat: 48.8914012, lng: 22.2441632 },
    { lat: 48.8911272, lng: 22.2442441 },
    { lat: 48.8907277, lng: 22.2445266 },
    { lat: 48.8906761, lng: 22.2451983 },
    { lat: 48.8906646, lng: 22.2456835 },
    { lat: 48.8907577, lng: 22.247043 },
    { lat: 48.8907497, lng: 22.2477933 },
    { lat: 48.8903497, lng: 22.2482141 },
    { lat: 48.8899911, lng: 22.2488775 },
    { lat: 48.8893326, lng: 22.2495649 },
    { lat: 48.8893556, lng: 22.2499454 },
    { lat: 48.8891476, lng: 22.2502445 },
    { lat: 48.8887395, lng: 22.2501027 },
    { lat: 48.8880552, lng: 22.2500859 },
    { lat: 48.8873882, lng: 22.2506386 },
    { lat: 48.8868369, lng: 22.2509301 },
    { lat: 48.8867047, lng: 22.2510069 },
    { lat: 48.8859523, lng: 22.2511068 },
    { lat: 48.8853369, lng: 22.2513534 },
    { lat: 48.8848173, lng: 22.2513557 },
    { lat: 48.8845617, lng: 22.2511382 },
    { lat: 48.8843213, lng: 22.251361 },
    { lat: 48.8838716, lng: 22.2515734 },
    { lat: 48.8834211, lng: 22.2512926 },
    { lat: 48.8832441, lng: 22.2514167 },
    { lat: 48.8829442, lng: 22.2516372 },
    { lat: 48.882771, lng: 22.2518404 },
    { lat: 48.8826733, lng: 22.2520981 },
    { lat: 48.8821626, lng: 22.2526495 },
    { lat: 48.8816192, lng: 22.2531532 },
    { lat: 48.8812761, lng: 22.2534527 },
    { lat: 48.8808964, lng: 22.2543325 },
    { lat: 48.8790323, lng: 22.2553817 },
    { lat: 48.8786269, lng: 22.2553081 },
    { lat: 48.8778492, lng: 22.2552373 },
    { lat: 48.8773728, lng: 22.2553042 },
    { lat: 48.8765591, lng: 22.2552352 },
    { lat: 48.8758583, lng: 22.2547197 },
    { lat: 48.8752304, lng: 22.2540528 },
    { lat: 48.8749452, lng: 22.253818 },
    { lat: 48.8749224, lng: 22.2550704 },
    { lat: 48.8754194, lng: 22.2562288 },
    { lat: 48.8761397, lng: 22.2573666 },
    { lat: 48.8768911, lng: 22.2591988 },
    { lat: 48.877435, lng: 22.2602895 },
    { lat: 48.8776862, lng: 22.2612783 },
    { lat: 48.8777178, lng: 22.2623026 },
    { lat: 48.8784178, lng: 22.2644009 },
    { lat: 48.8789011, lng: 22.2650996 },
    { lat: 48.8788929, lng: 22.2657989 },
    { lat: 48.8785981, lng: 22.2684446 },
    { lat: 48.8786884, lng: 22.2698353 },
    { lat: 48.8786176, lng: 22.2707465 },
    { lat: 48.8786812, lng: 22.2717928 },
    { lat: 48.8788576, lng: 22.2726568 },
    { lat: 48.8796136, lng: 22.2747596 },
    { lat: 48.879592, lng: 22.2748061 },
    { lat: 48.8797116, lng: 22.2751738 },
    { lat: 48.8797172, lng: 22.275483 },
    { lat: 48.8801091, lng: 22.2757583 },
    { lat: 48.8801511, lng: 22.2758795 },
    { lat: 48.8803261, lng: 22.2760114 },
    { lat: 48.8805364, lng: 22.2764228 },
    { lat: 48.8808385, lng: 22.2773187 },
    { lat: 48.8808626, lng: 22.2776448 },
    { lat: 48.8809596, lng: 22.2777168 },
    { lat: 48.8810717, lng: 22.2781496 },
    { lat: 48.8811767, lng: 22.2783276 },
    { lat: 48.8812896, lng: 22.2788536 },
    { lat: 48.8817194, lng: 22.2796323 },
    { lat: 48.882097, lng: 22.2799752 },
    { lat: 48.8822493, lng: 22.2803563 },
    { lat: 48.8828603, lng: 22.2804656 },
    { lat: 48.8829908, lng: 22.2805708 },
    { lat: 48.8829644, lng: 22.2806572 },
    { lat: 48.8831409, lng: 22.2809035 },
    { lat: 48.8832406, lng: 22.281299 },
    { lat: 48.8833132, lng: 22.2821491 },
    { lat: 48.8833133, lng: 22.282763 },
    { lat: 48.8844964, lng: 22.2843926 },
    { lat: 48.8850717, lng: 22.2853517 },
    { lat: 48.886228, lng: 22.2865782 },
    { lat: 48.8875585, lng: 22.2885297 },
    { lat: 48.888337, lng: 22.2898775 },
    { lat: 48.8890272, lng: 22.2908814 },
    { lat: 48.8891763, lng: 22.2913731 },
    { lat: 48.8896777, lng: 22.292651 },
    { lat: 48.8898084, lng: 22.2934858 },
    { lat: 48.8898578, lng: 22.2945584 },
    { lat: 48.8896963, lng: 22.2950014 },
    { lat: 48.8894511, lng: 22.2953082 },
    { lat: 48.8893005, lng: 22.2962365 },
  ],
  Ulič: [
    { lat: 48.9880408, lng: 22.3637068 },
    { lat: 48.9876742, lng: 22.3641344 },
    { lat: 48.987352, lng: 22.3643618 },
    { lat: 48.9868414, lng: 22.3649457 },
    { lat: 48.9859549, lng: 22.3663716 },
    { lat: 48.985652, lng: 22.3667259 },
    { lat: 48.9852146, lng: 22.3667813 },
    { lat: 48.9845706, lng: 22.3667494 },
    { lat: 48.9833597, lng: 22.366437 },
    { lat: 48.9827869, lng: 22.3663726 },
    { lat: 48.9821657, lng: 22.3656618 },
    { lat: 48.9818489, lng: 22.3654163 },
    { lat: 48.9812507, lng: 22.365467 },
    { lat: 48.980916, lng: 22.3657072 },
    { lat: 48.979869, lng: 22.3664426 },
    { lat: 48.9794243, lng: 22.3664765 },
    { lat: 48.9788979, lng: 22.3671585 },
    { lat: 48.978608, lng: 22.3672819 },
    { lat: 48.977362, lng: 22.368192 },
    { lat: 48.9769196, lng: 22.368858 },
    { lat: 48.9763016, lng: 22.3700001 },
    { lat: 48.9757569, lng: 22.3706207 },
    { lat: 48.9753226, lng: 22.3708753 },
    { lat: 48.9744204, lng: 22.3716621 },
    { lat: 48.9737984, lng: 22.3718736 },
    { lat: 48.9735964, lng: 22.3723174 },
    { lat: 48.973436, lng: 22.3728679 },
    { lat: 48.9732523, lng: 22.3742329 },
    { lat: 48.9730753, lng: 22.3745916 },
    { lat: 48.9720917, lng: 22.3772144 },
    { lat: 48.9717486, lng: 22.3778522 },
    { lat: 48.9712678, lng: 22.3785221 },
    { lat: 48.9708397, lng: 22.3792338 },
    { lat: 48.9703862, lng: 22.3797523 },
    { lat: 48.9702595, lng: 22.3802478 },
    { lat: 48.9699559, lng: 22.3809513 },
    { lat: 48.9692971, lng: 22.381973 },
    { lat: 48.9688821, lng: 22.3829357 },
    { lat: 48.9685319, lng: 22.3835519 },
    { lat: 48.9680018, lng: 22.3838514 },
    { lat: 48.9674048, lng: 22.3846084 },
    { lat: 48.9665681, lng: 22.3854691 },
    { lat: 48.9657297, lng: 22.3870978 },
    { lat: 48.9652119, lng: 22.3893832 },
    { lat: 48.9652354, lng: 22.390123 },
    { lat: 48.9653349, lng: 22.3906869 },
    { lat: 48.9650624, lng: 22.3908307 },
    { lat: 48.9637566, lng: 22.3912575 },
    { lat: 48.9629709, lng: 22.3916485 },
    { lat: 48.9606559, lng: 22.3932285 },
    { lat: 48.9600458, lng: 22.3934778 },
    { lat: 48.9596582, lng: 22.3937617 },
    { lat: 48.9588888, lng: 22.3940251 },
    { lat: 48.9583687, lng: 22.3941049 },
    { lat: 48.9578444, lng: 22.3944194 },
    { lat: 48.957, lng: 22.3951151 },
    { lat: 48.9565379, lng: 22.3953454 },
    { lat: 48.9548708, lng: 22.3960189 },
    { lat: 48.9542249, lng: 22.3961084 },
    { lat: 48.9541788, lng: 22.3959446 },
    { lat: 48.9535881, lng: 22.3960948 },
    { lat: 48.9531328, lng: 22.396378 },
    { lat: 48.9524921, lng: 22.3969234 },
    { lat: 48.9515704, lng: 22.3975948 },
    { lat: 48.9512166, lng: 22.3984281 },
    { lat: 48.9504973, lng: 22.398875 },
    { lat: 48.9496768, lng: 22.3991736 },
    { lat: 48.9494022, lng: 22.3995837 },
    { lat: 48.9492912, lng: 22.4001934 },
    { lat: 48.9493091, lng: 22.4009951 },
    { lat: 48.9494611, lng: 22.4014321 },
    { lat: 48.9490878, lng: 22.4021883 },
    { lat: 48.9486825, lng: 22.4028254 },
    { lat: 48.9482591, lng: 22.4033338 },
    { lat: 48.9483443, lng: 22.4036463 },
    { lat: 48.9480462, lng: 22.4039637 },
    { lat: 48.9480558, lng: 22.4046352 },
    { lat: 48.9478485, lng: 22.4061332 },
    { lat: 48.9472844, lng: 22.4063189 },
    { lat: 48.9463649, lng: 22.4062751 },
    { lat: 48.9461645, lng: 22.4063709 },
    { lat: 48.9455383, lng: 22.4063356 },
    { lat: 48.9448429, lng: 22.4058298 },
    { lat: 48.9446494, lng: 22.4058871 },
    { lat: 48.9442588, lng: 22.4058125 },
    { lat: 48.943719, lng: 22.4055361 },
    { lat: 48.9432987, lng: 22.4055077 },
    { lat: 48.9427648, lng: 22.4057838 },
    { lat: 48.9423094, lng: 22.4061044 },
    { lat: 48.941869, lng: 22.4067738 },
    { lat: 48.9416057, lng: 22.4066713 },
    { lat: 48.941591, lng: 22.4066656 },
    { lat: 48.9413706, lng: 22.4079075 },
    { lat: 48.9410516, lng: 22.409542 },
    { lat: 48.9404612, lng: 22.4101928 },
    { lat: 48.9393475, lng: 22.4106029 },
    { lat: 48.9386627, lng: 22.4111466 },
    { lat: 48.9381212, lng: 22.4121463 },
    { lat: 48.937511, lng: 22.4134702 },
    { lat: 48.9367319, lng: 22.4142412 },
    { lat: 48.9360444, lng: 22.4146004 },
    { lat: 48.9343871, lng: 22.4148905 },
    { lat: 48.9341004, lng: 22.4151081 },
    { lat: 48.9337702, lng: 22.4160478 },
    { lat: 48.9336067, lng: 22.4166873 },
    { lat: 48.9335405, lng: 22.417662 },
    { lat: 48.9333135, lng: 22.4181042 },
    { lat: 48.9328594, lng: 22.4193635 },
    { lat: 48.9326522, lng: 22.4204887 },
    { lat: 48.9323681, lng: 22.4216519 },
    { lat: 48.9319222, lng: 22.4252096 },
    { lat: 48.9319471, lng: 22.425752 },
    { lat: 48.9318693, lng: 22.4261843 },
    { lat: 48.9317837, lng: 22.4279156 },
    { lat: 48.931736, lng: 22.428291 },
    { lat: 48.9411368, lng: 22.4369426 },
    { lat: 48.9468255, lng: 22.4421787 },
    { lat: 48.9545349, lng: 22.4492758 },
    { lat: 48.9699749, lng: 22.463489 },
    { lat: 48.972603, lng: 22.465913 },
    { lat: 48.9729103, lng: 22.4661968 },
    { lat: 48.972943, lng: 22.466227 },
    { lat: 48.973719, lng: 22.468629 },
    { lat: 48.974293, lng: 22.469543 },
    { lat: 48.9744403, lng: 22.4694443 },
    { lat: 48.9751688, lng: 22.4693285 },
    { lat: 48.9754942, lng: 22.4694565 },
    { lat: 48.9754853, lng: 22.4691225 },
    { lat: 48.9752635, lng: 22.4682258 },
    { lat: 48.9750622, lng: 22.4676217 },
    { lat: 48.9748011, lng: 22.4672247 },
    { lat: 48.9742346, lng: 22.4656286 },
    { lat: 48.9742188, lng: 22.4643034 },
    { lat: 48.9743279, lng: 22.4640284 },
    { lat: 48.9745623, lng: 22.4627276 },
    { lat: 48.9744612, lng: 22.4618457 },
    { lat: 48.9751927, lng: 22.4609911 },
    { lat: 48.9758819, lng: 22.4606498 },
    { lat: 48.9761141, lng: 22.4602841 },
    { lat: 48.9763189, lng: 22.4596365 },
    { lat: 48.9766567, lng: 22.4589079 },
    { lat: 48.9767541, lng: 22.4586978 },
    { lat: 48.9772284, lng: 22.4580701 },
    { lat: 48.9774712, lng: 22.4578435 },
    { lat: 48.9773308, lng: 22.4568048 },
    { lat: 48.9773316, lng: 22.4564664 },
    { lat: 48.9771736, lng: 22.4559405 },
    { lat: 48.9763145, lng: 22.4542931 },
    { lat: 48.9768639, lng: 22.4533299 },
    { lat: 48.9775763, lng: 22.4525359 },
    { lat: 48.9780611, lng: 22.4518257 },
    { lat: 48.9785167, lng: 22.4514455 },
    { lat: 48.9788141, lng: 22.451309 },
    { lat: 48.9790432, lng: 22.4505141 },
    { lat: 48.9792815, lng: 22.4503439 },
    { lat: 48.9798151, lng: 22.4495547 },
    { lat: 48.9801922, lng: 22.4494073 },
    { lat: 48.9804591, lng: 22.4490932 },
    { lat: 48.9807044, lng: 22.4486549 },
    { lat: 48.9807109, lng: 22.4480398 },
    { lat: 48.9806365, lng: 22.4473735 },
    { lat: 48.9804076, lng: 22.4464145 },
    { lat: 48.9795665, lng: 22.4464743 },
    { lat: 48.9795744, lng: 22.446088 },
    { lat: 48.9798624, lng: 22.4441253 },
    { lat: 48.9800591, lng: 22.4434519 },
    { lat: 48.980197, lng: 22.4427214 },
    { lat: 48.9801981, lng: 22.4417419 },
    { lat: 48.9801071, lng: 22.4404766 },
    { lat: 48.9807689, lng: 22.4371214 },
    { lat: 48.9806068, lng: 22.4365079 },
    { lat: 48.9805411, lng: 22.4359364 },
    { lat: 48.9803979, lng: 22.4353199 },
    { lat: 48.9804179, lng: 22.4336144 },
    { lat: 48.9808197, lng: 22.4334689 },
    { lat: 48.9812505, lng: 22.4330904 },
    { lat: 48.9815805, lng: 22.4328985 },
    { lat: 48.9818492, lng: 22.4328911 },
    { lat: 48.9821379, lng: 22.4330961 },
    { lat: 48.9831799, lng: 22.4310047 },
    { lat: 48.9837335, lng: 22.4307427 },
    { lat: 48.9836271, lng: 22.4303818 },
    { lat: 48.9836212, lng: 22.4299249 },
    { lat: 48.9834919, lng: 22.4295508 },
    { lat: 48.9835087, lng: 22.4291086 },
    { lat: 48.9832999, lng: 22.4284697 },
    { lat: 48.9832997, lng: 22.4281976 },
    { lat: 48.9830474, lng: 22.4275734 },
    { lat: 48.9831689, lng: 22.4270327 },
    { lat: 48.983534, lng: 22.425977 },
    { lat: 48.9835819, lng: 22.4254083 },
    { lat: 48.9839979, lng: 22.4243203 },
    { lat: 48.9842371, lng: 22.4235434 },
    { lat: 48.9842866, lng: 22.4226774 },
    { lat: 48.984431, lng: 22.4224281 },
    { lat: 48.9844837, lng: 22.4221496 },
    { lat: 48.9844556, lng: 22.4213836 },
    { lat: 48.9845251, lng: 22.4211551 },
    { lat: 48.9858082, lng: 22.420128 },
    { lat: 48.9859605, lng: 22.4193939 },
    { lat: 48.9861982, lng: 22.418753 },
    { lat: 48.9865814, lng: 22.418469 },
    { lat: 48.9870038, lng: 22.4183349 },
    { lat: 48.9879398, lng: 22.4178135 },
    { lat: 48.9887842, lng: 22.4172105 },
    { lat: 48.9894587, lng: 22.4174185 },
    { lat: 48.9898581, lng: 22.4167712 },
    { lat: 48.9902972, lng: 22.4153894 },
    { lat: 48.990307, lng: 22.4147247 },
    { lat: 48.9905731, lng: 22.4139997 },
    { lat: 48.9909356, lng: 22.4132102 },
    { lat: 48.991192, lng: 22.4121204 },
    { lat: 48.9922006, lng: 22.4125945 },
    { lat: 48.9923398, lng: 22.4120481 },
    { lat: 48.9917903, lng: 22.4113726 },
    { lat: 48.9915692, lng: 22.4106885 },
    { lat: 48.9916162, lng: 22.4100263 },
    { lat: 48.9912243, lng: 22.4085987 },
    { lat: 48.9909985, lng: 22.4080101 },
    { lat: 48.9905904, lng: 22.4075374 },
    { lat: 48.9902517, lng: 22.4069338 },
    { lat: 48.9898038, lng: 22.4058857 },
    { lat: 48.9895994, lng: 22.4050895 },
    { lat: 48.9888555, lng: 22.4046859 },
    { lat: 48.9883006, lng: 22.4048813 },
    { lat: 48.9876101, lng: 22.4047216 },
    { lat: 48.9873627, lng: 22.4045541 },
    { lat: 48.9869743, lng: 22.4039429 },
    { lat: 48.9865967, lng: 22.4036944 },
    { lat: 48.9860822, lng: 22.4039268 },
    { lat: 48.9856738, lng: 22.4037585 },
    { lat: 48.9848201, lng: 22.4038623 },
    { lat: 48.9843202, lng: 22.4036669 },
    { lat: 48.9845032, lng: 22.402422 },
    { lat: 48.9847638, lng: 22.4011667 },
    { lat: 48.9852352, lng: 22.3994997 },
    { lat: 48.9854941, lng: 22.3988746 },
    { lat: 48.9865658, lng: 22.3968761 },
    { lat: 48.9869566, lng: 22.3959337 },
    { lat: 48.9872968, lng: 22.3953218 },
    { lat: 48.9872249, lng: 22.3939263 },
    { lat: 48.9873035, lng: 22.393651 },
    { lat: 48.9872597, lng: 22.3932815 },
    { lat: 48.9872796, lng: 22.3927799 },
    { lat: 48.9871018, lng: 22.3914382 },
    { lat: 48.9870164, lng: 22.3904236 },
    { lat: 48.9870934, lng: 22.3898008 },
    { lat: 48.9870171, lng: 22.3882547 },
    { lat: 48.9870291, lng: 22.3866836 },
    { lat: 48.9872802, lng: 22.3856359 },
    { lat: 48.987339, lng: 22.3851131 },
    { lat: 48.9870146, lng: 22.3837481 },
    { lat: 48.9871175, lng: 22.3817988 },
    { lat: 48.9868994, lng: 22.3810538 },
    { lat: 48.9867928, lng: 22.379631 },
    { lat: 48.987178, lng: 22.379063 },
    { lat: 48.9873948, lng: 22.3789414 },
    { lat: 48.9876824, lng: 22.3789353 },
    { lat: 48.9881487, lng: 22.3783286 },
    { lat: 48.988455, lng: 22.3774432 },
    { lat: 48.9884292, lng: 22.3763204 },
    { lat: 48.9883694, lng: 22.3753031 },
    { lat: 48.9879627, lng: 22.3736661 },
    { lat: 48.9877065, lng: 22.3728858 },
    { lat: 48.9874386, lng: 22.3723424 },
    { lat: 48.987301, lng: 22.3718976 },
    { lat: 48.9866943, lng: 22.3693147 },
    { lat: 48.9865975, lng: 22.3686741 },
    { lat: 48.986921, lng: 22.3678018 },
    { lat: 48.9870881, lng: 22.3671477 },
    { lat: 48.9871821, lng: 22.3665311 },
    { lat: 48.9875195, lng: 22.3655267 },
    { lat: 48.9877133, lng: 22.3651741 },
    { lat: 48.987962, lng: 22.3643885 },
    { lat: 48.9880408, lng: 22.3637068 },
  ],
  Klenová: [
    { lat: 48.9905926, lng: 22.3589877 },
    { lat: 48.9903438, lng: 22.358813 },
    { lat: 48.9898151, lng: 22.3580651 },
    { lat: 48.9884173, lng: 22.3570626 },
    { lat: 48.9877625, lng: 22.356317 },
    { lat: 48.9875648, lng: 22.355906 },
    { lat: 48.9874169, lng: 22.3554588 },
    { lat: 48.9871911, lng: 22.3550379 },
    { lat: 48.9871173, lng: 22.3549003 },
    { lat: 48.9869698, lng: 22.3544284 },
    { lat: 48.9869916, lng: 22.3541962 },
    { lat: 48.9870109, lng: 22.35399 },
    { lat: 48.9870267, lng: 22.3538215 },
    { lat: 48.9870284, lng: 22.3536473 },
    { lat: 48.987031, lng: 22.3533922 },
    { lat: 48.9870344, lng: 22.3530464 },
    { lat: 48.9870373, lng: 22.3527548 },
    { lat: 48.9870382, lng: 22.3526676 },
    { lat: 48.9869025, lng: 22.3524783 },
    { lat: 48.9867823, lng: 22.3523106 },
    { lat: 48.986701, lng: 22.3521973 },
    { lat: 48.9866372, lng: 22.3521083 },
    { lat: 48.986404, lng: 22.3518276 },
    { lat: 48.9862465, lng: 22.3517265 },
    { lat: 48.986137, lng: 22.3515385 },
    { lat: 48.9860547, lng: 22.3504787 },
    { lat: 48.9853175, lng: 22.3489302 },
    { lat: 48.9850868, lng: 22.3486504 },
    { lat: 48.9847343, lng: 22.3483684 },
    { lat: 48.9842514, lng: 22.3481359 },
    { lat: 48.9840769, lng: 22.3479214 },
    { lat: 48.9834448, lng: 22.3476528 },
    { lat: 48.9831681, lng: 22.3473868 },
    { lat: 48.9831401, lng: 22.347235 },
    { lat: 48.9831831, lng: 22.3469317 },
    { lat: 48.9833961, lng: 22.3462678 },
    { lat: 48.983296, lng: 22.3455322 },
    { lat: 48.9825913, lng: 22.3446543 },
    { lat: 48.9812043, lng: 22.3437482 },
    { lat: 48.9807089, lng: 22.3435571 },
    { lat: 48.9788803, lng: 22.343092 },
    { lat: 48.9778612, lng: 22.3423429 },
    { lat: 48.9773465, lng: 22.3413172 },
    { lat: 48.9767902, lng: 22.34075 },
    { lat: 48.9758835, lng: 22.3395357 },
    { lat: 48.9740169, lng: 22.3383406 },
    { lat: 48.9727798, lng: 22.3380714 },
    { lat: 48.9727607, lng: 22.3381818 },
    { lat: 48.9727203, lng: 22.3381355 },
    { lat: 48.9715679, lng: 22.3381993 },
    { lat: 48.9707518, lng: 22.3385844 },
    { lat: 48.9706054, lng: 22.3382104 },
    { lat: 48.9702406, lng: 22.3385059 },
    { lat: 48.9696828, lng: 22.3382463 },
    { lat: 48.9691026, lng: 22.3383541 },
    { lat: 48.9684223, lng: 22.3383602 },
    { lat: 48.9678977, lng: 22.338073 },
    { lat: 48.9672344, lng: 22.337299 },
    { lat: 48.966097, lng: 22.3370767 },
    { lat: 48.9658974, lng: 22.3368901 },
    { lat: 48.9656234, lng: 22.3361803 },
    { lat: 48.9651785, lng: 22.3358304 },
    { lat: 48.9649175, lng: 22.3354063 },
    { lat: 48.9644069, lng: 22.3347751 },
    { lat: 48.9640495, lng: 22.3351103 },
    { lat: 48.9634066, lng: 22.3349131 },
    { lat: 48.9629511, lng: 22.3351235 },
    { lat: 48.9626793, lng: 22.331837 },
    { lat: 48.9621508, lng: 22.3277111 },
    { lat: 48.9619655, lng: 22.3245929 },
    { lat: 48.9611608, lng: 22.3244669 },
    { lat: 48.9611323, lng: 22.3232958 },
    { lat: 48.9610791, lng: 22.322811 },
    { lat: 48.9605142, lng: 22.3230599 },
    { lat: 48.9602467, lng: 22.3229064 },
    { lat: 48.9599672, lng: 22.3232475 },
    { lat: 48.9595531, lng: 22.3228726 },
    { lat: 48.9594324, lng: 22.3226982 },
    { lat: 48.9593647, lng: 22.3219622 },
    { lat: 48.9601031, lng: 22.3218872 },
    { lat: 48.9604074, lng: 22.3219683 },
    { lat: 48.9610625, lng: 22.3217638 },
    { lat: 48.961672, lng: 22.3212984 },
    { lat: 48.9615206, lng: 22.3206516 },
    { lat: 48.9610675, lng: 22.3200339 },
    { lat: 48.9607552, lng: 22.3189607 },
    { lat: 48.9603102, lng: 22.3179894 },
    { lat: 48.9597818, lng: 22.3161772 },
    { lat: 48.958642, lng: 22.3126695 },
    { lat: 48.9586056, lng: 22.3093791 },
    { lat: 48.956011, lng: 22.3091686 },
    { lat: 48.9548456, lng: 22.3075517 },
    { lat: 48.9554251, lng: 22.3056532 },
    { lat: 48.9557755, lng: 22.3039687 },
    { lat: 48.9557873, lng: 22.3014129 },
    { lat: 48.9565412, lng: 22.2997779 },
    { lat: 48.9575945, lng: 22.2985158 },
    { lat: 48.9575482, lng: 22.2984573 },
    { lat: 48.9577907, lng: 22.2980242 },
    { lat: 48.9579671, lng: 22.296936 },
    { lat: 48.9582302, lng: 22.2966146 },
    { lat: 48.958382, lng: 22.296 },
    { lat: 48.9584357, lng: 22.2956223 },
    { lat: 48.958297, lng: 22.2948809 },
    { lat: 48.9578227, lng: 22.2937627 },
    { lat: 48.957648, lng: 22.2936964 },
    { lat: 48.9572999, lng: 22.2932608 },
    { lat: 48.9570351, lng: 22.2927173 },
    { lat: 48.9567569, lng: 22.2924969 },
    { lat: 48.9560642, lng: 22.2913186 },
    { lat: 48.9553717, lng: 22.2910213 },
    { lat: 48.9545632, lng: 22.2907661 },
    { lat: 48.9539524, lng: 22.2904018 },
    { lat: 48.9534016, lng: 22.2902106 },
    { lat: 48.9529726, lng: 22.290293 },
    { lat: 48.951317, lng: 22.2893659 },
    { lat: 48.950395, lng: 22.2893218 },
    { lat: 48.9500896, lng: 22.2891941 },
    { lat: 48.9495588, lng: 22.2892448 },
    { lat: 48.9495129, lng: 22.2895166 },
    { lat: 48.9489952, lng: 22.2892929 },
    { lat: 48.9483508, lng: 22.2897522 },
    { lat: 48.9480187, lng: 22.2902044 },
    { lat: 48.9467342, lng: 22.2923036 },
    { lat: 48.9462572, lng: 22.2925624 },
    { lat: 48.9440374, lng: 22.2931907 },
    { lat: 48.943656, lng: 22.2932417 },
    { lat: 48.9432561, lng: 22.2928436 },
    { lat: 48.9424029, lng: 22.2923996 },
    { lat: 48.9417498, lng: 22.2916973 },
    { lat: 48.9413216, lng: 22.2914044 },
    { lat: 48.9410246, lng: 22.2913503 },
    { lat: 48.940724, lng: 22.2920566 },
    { lat: 48.9407776, lng: 22.29271 },
    { lat: 48.9407652, lng: 22.2933163 },
    { lat: 48.9405915, lng: 22.2940199 },
    { lat: 48.940387, lng: 22.2944956 },
    { lat: 48.9402595, lng: 22.2950344 },
    { lat: 48.9399345, lng: 22.2956099 },
    { lat: 48.9395273, lng: 22.2972105 },
    { lat: 48.9387923, lng: 22.2985189 },
    { lat: 48.9386364, lng: 22.2992093 },
    { lat: 48.9377478, lng: 22.3015302 },
    { lat: 48.9371898, lng: 22.3025072 },
    { lat: 48.9369377, lng: 22.3032135 },
    { lat: 48.9366731, lng: 22.3037262 },
    { lat: 48.9364118, lng: 22.3039142 },
    { lat: 48.9362568, lng: 22.3047952 },
    { lat: 48.9360066, lng: 22.3056548 },
    { lat: 48.9353707, lng: 22.3069752 },
    { lat: 48.9350182, lng: 22.3079454 },
    { lat: 48.9340425, lng: 22.3101369 },
    { lat: 48.933697, lng: 22.3112692 },
    { lat: 48.9335158, lng: 22.312043 },
    { lat: 48.9328893, lng: 22.3130812 },
    { lat: 48.9307639, lng: 22.3181288 },
    { lat: 48.9297004, lng: 22.3198088 },
    { lat: 48.9295756, lng: 22.3195529 },
    { lat: 48.9292155, lng: 22.3201212 },
    { lat: 48.9290456, lng: 22.3200582 },
    { lat: 48.9286091, lng: 22.3207838 },
    { lat: 48.928169, lng: 22.3207141 },
    { lat: 48.9278231, lng: 22.3211552 },
    { lat: 48.9279388, lng: 22.3215157 },
    { lat: 48.9277899, lng: 22.3217001 },
    { lat: 48.9275222, lng: 22.3216462 },
    { lat: 48.9276306, lng: 22.3218211 },
    { lat: 48.9275281, lng: 22.3220214 },
    { lat: 48.9272285, lng: 22.3219516 },
    { lat: 48.9267942, lng: 22.3227733 },
    { lat: 48.9265575, lng: 22.3229649 },
    { lat: 48.9265589, lng: 22.3237496 },
    { lat: 48.9260001, lng: 22.3242273 },
    { lat: 48.9257052, lng: 22.325209 },
    { lat: 48.9252122, lng: 22.3252961 },
    { lat: 48.92522, lng: 22.3258125 },
    { lat: 48.9251423, lng: 22.3261786 },
    { lat: 48.9253485, lng: 22.3268186 },
    { lat: 48.925227, lng: 22.3272828 },
    { lat: 48.9253762, lng: 22.3275805 },
    { lat: 48.9249569, lng: 22.3286402 },
    { lat: 48.9244824, lng: 22.3284824 },
    { lat: 48.9223814, lng: 22.3318232 },
    { lat: 48.9218572, lng: 22.3329688 },
    { lat: 48.9216781, lng: 22.3333779 },
    { lat: 48.9207777, lng: 22.3373165 },
    { lat: 48.9205515, lng: 22.3396143 },
    { lat: 48.9195354, lng: 22.3418984 },
    { lat: 48.9191839, lng: 22.3429245 },
    { lat: 48.9181229, lng: 22.3452179 },
    { lat: 48.9177065, lng: 22.3465164 },
    { lat: 48.9177531, lng: 22.347067 },
    { lat: 48.9177976, lng: 22.3476251 },
    { lat: 48.9178748, lng: 22.3482832 },
    { lat: 48.9183396, lng: 22.3492434 },
    { lat: 48.9186577, lng: 22.3494534 },
    { lat: 48.9195687, lng: 22.3499203 },
    { lat: 48.9203728, lng: 22.349879 },
    { lat: 48.9210555, lng: 22.3500282 },
    { lat: 48.9215342, lng: 22.3503841 },
    { lat: 48.9220381, lng: 22.3509626 },
    { lat: 48.9222171, lng: 22.3509678 },
    { lat: 48.9234541, lng: 22.3521272 },
    { lat: 48.9239579, lng: 22.3529168 },
    { lat: 48.9240302, lng: 22.3544621 },
    { lat: 48.9226988, lng: 22.3594402 },
    { lat: 48.9222882, lng: 22.3611751 },
    { lat: 48.9227939, lng: 22.3633808 },
    { lat: 48.9231531, lng: 22.3660469 },
    { lat: 48.9240011, lng: 22.3681183 },
    { lat: 48.9244545, lng: 22.3688147 },
    { lat: 48.9246268, lng: 22.3695176 },
    { lat: 48.9244874, lng: 22.3715372 },
    { lat: 48.9247576, lng: 22.3717662 },
    { lat: 48.9251927, lng: 22.3714899 },
    { lat: 48.9249444, lng: 22.3708619 },
    { lat: 48.9259048, lng: 22.3701749 },
    { lat: 48.9261177, lng: 22.3721584 },
    { lat: 48.925783, lng: 22.3729697 },
    { lat: 48.9264651, lng: 22.3728421 },
    { lat: 48.9274059, lng: 22.3723424 },
    { lat: 48.9284571, lng: 22.371422 },
    { lat: 48.9290786, lng: 22.3709682 },
    { lat: 48.9290729, lng: 22.3708072 },
    { lat: 48.9300945, lng: 22.367171 },
    { lat: 48.9301815, lng: 22.3664946 },
    { lat: 48.9308764, lng: 22.3649023 },
    { lat: 48.9314659, lng: 22.3644303 },
    { lat: 48.9315707, lng: 22.3627487 },
    { lat: 48.9318253, lng: 22.3624976 },
    { lat: 48.9314502, lng: 22.3619473 },
    { lat: 48.931576, lng: 22.3613037 },
    { lat: 48.932584, lng: 22.3602611 },
    { lat: 48.933325, lng: 22.3601443 },
    { lat: 48.9343021, lng: 22.3588112 },
    { lat: 48.9346076, lng: 22.3592176 },
    { lat: 48.934905, lng: 22.3600361 },
    { lat: 48.935235, lng: 22.3603283 },
    { lat: 48.9358212, lng: 22.3602302 },
    { lat: 48.9359834, lng: 22.3602906 },
    { lat: 48.9364482, lng: 22.3600464 },
    { lat: 48.9369448, lng: 22.3595409 },
    { lat: 48.9371341, lng: 22.3598743 },
    { lat: 48.9374602, lng: 22.359662 },
    { lat: 48.9374098, lng: 22.3592523 },
    { lat: 48.9378181, lng: 22.3591486 },
    { lat: 48.9382237, lng: 22.3581292 },
    { lat: 48.938555, lng: 22.3582622 },
    { lat: 48.9390936, lng: 22.3573554 },
    { lat: 48.939482, lng: 22.3571142 },
    { lat: 48.9395681, lng: 22.3568305 },
    { lat: 48.9397989, lng: 22.3567809 },
    { lat: 48.9398, lng: 22.3564636 },
    { lat: 48.9401457, lng: 22.356374 },
    { lat: 48.940268, lng: 22.3559419 },
    { lat: 48.9409811, lng: 22.354624 },
    { lat: 48.9420116, lng: 22.353118 },
    { lat: 48.942722, lng: 22.3523619 },
    { lat: 48.942999, lng: 22.3524288 },
    { lat: 48.9430886, lng: 22.3518262 },
    { lat: 48.9434186, lng: 22.3517935 },
    { lat: 48.9433741, lng: 22.3519313 },
    { lat: 48.944219, lng: 22.3520432 },
    { lat: 48.9447847, lng: 22.3519342 },
    { lat: 48.9448058, lng: 22.3517743 },
    { lat: 48.9450586, lng: 22.351763 },
    { lat: 48.9453624, lng: 22.3520811 },
    { lat: 48.9455722, lng: 22.3515316 },
    { lat: 48.9469653, lng: 22.3501987 },
    { lat: 48.9474255, lng: 22.3500544 },
    { lat: 48.947837, lng: 22.3496209 },
    { lat: 48.9480057, lng: 22.3491471 },
    { lat: 48.9484596, lng: 22.3490256 },
    { lat: 48.9486593, lng: 22.3490537 },
    { lat: 48.949402, lng: 22.3496541 },
    { lat: 48.9496141, lng: 22.3499968 },
    { lat: 48.9500853, lng: 22.351158 },
    { lat: 48.9504805, lng: 22.3513912 },
    { lat: 48.9507546, lng: 22.3511147 },
    { lat: 48.9510848, lng: 22.3503563 },
    { lat: 48.9517531, lng: 22.3499964 },
    { lat: 48.9526935, lng: 22.3500028 },
    { lat: 48.9532608, lng: 22.3496614 },
    { lat: 48.9535471, lng: 22.3497929 },
    { lat: 48.9541725, lng: 22.3496348 },
    { lat: 48.9546404, lng: 22.3493763 },
    { lat: 48.9548043, lng: 22.3493686 },
    { lat: 48.9552222, lng: 22.3495044 },
    { lat: 48.9556359, lng: 22.3493319 },
    { lat: 48.9559922, lng: 22.3491118 },
    { lat: 48.9560518, lng: 22.348857 },
    { lat: 48.9562645, lng: 22.3489624 },
    { lat: 48.9577004, lng: 22.3481123 },
    { lat: 48.95899, lng: 22.3468331 },
    { lat: 48.9592321, lng: 22.3474206 },
    { lat: 48.9600462, lng: 22.3469374 },
    { lat: 48.960007, lng: 22.3466813 },
    { lat: 48.9601764, lng: 22.34646 },
    { lat: 48.960858, lng: 22.346196 },
    { lat: 48.9606412, lng: 22.3459415 },
    { lat: 48.9606899, lng: 22.3457533 },
    { lat: 48.9610011, lng: 22.3458915 },
    { lat: 48.9618575, lng: 22.345393 },
    { lat: 48.9624293, lng: 22.3454174 },
    { lat: 48.962854, lng: 22.3456087 },
    { lat: 48.9633519, lng: 22.3460119 },
    { lat: 48.9637329, lng: 22.3474212 },
    { lat: 48.9653576, lng: 22.3471887 },
    { lat: 48.9655774, lng: 22.3475279 },
    { lat: 48.9657546, lng: 22.3475045 },
    { lat: 48.9659092, lng: 22.3478487 },
    { lat: 48.9667752, lng: 22.3478526 },
    { lat: 48.9676103, lng: 22.3474098 },
    { lat: 48.9678464, lng: 22.3478682 },
    { lat: 48.9679508, lng: 22.3479157 },
    { lat: 48.9683676, lng: 22.3478436 },
    { lat: 48.9688258, lng: 22.3479649 },
    { lat: 48.9696101, lng: 22.3484077 },
    { lat: 48.9700996, lng: 22.3484316 },
    { lat: 48.9703616, lng: 22.3487362 },
    { lat: 48.9707519, lng: 22.3489486 },
    { lat: 48.9716752, lng: 22.3500619 },
    { lat: 48.9723232, lng: 22.3504914 },
    { lat: 48.9727896, lng: 22.3509009 },
    { lat: 48.9732384, lng: 22.3511713 },
    { lat: 48.9734868, lng: 22.3514795 },
    { lat: 48.9736347, lng: 22.3518191 },
    { lat: 48.9736152, lng: 22.3524265 },
    { lat: 48.9735177, lng: 22.3530284 },
    { lat: 48.9735492, lng: 22.3532445 },
    { lat: 48.9746086, lng: 22.3548761 },
    { lat: 48.9749564, lng: 22.3556272 },
    { lat: 48.9757631, lng: 22.3566033 },
    { lat: 48.9766067, lng: 22.3581965 },
    { lat: 48.9768313, lng: 22.3583405 },
    { lat: 48.9769697, lng: 22.358763 },
    { lat: 48.9771095, lng: 22.3589874 },
    { lat: 48.9782261, lng: 22.3600443 },
    { lat: 48.9789297, lng: 22.3604491 },
    { lat: 48.9793042, lng: 22.3608134 },
    { lat: 48.9800755, lng: 22.3620255 },
    { lat: 48.9805914, lng: 22.362649 },
    { lat: 48.9810841, lng: 22.3631318 },
    { lat: 48.9811406, lng: 22.3637277 },
    { lat: 48.9813415, lng: 22.3647125 },
    { lat: 48.9812507, lng: 22.365467 },
    { lat: 48.9818489, lng: 22.3654163 },
    { lat: 48.9821657, lng: 22.3656618 },
    { lat: 48.9827869, lng: 22.3663726 },
    { lat: 48.9833597, lng: 22.366437 },
    { lat: 48.9845706, lng: 22.3667494 },
    { lat: 48.9852146, lng: 22.3667813 },
    { lat: 48.985652, lng: 22.3667259 },
    { lat: 48.9859549, lng: 22.3663716 },
    { lat: 48.9868414, lng: 22.3649457 },
    { lat: 48.987352, lng: 22.3643618 },
    { lat: 48.9876742, lng: 22.3641344 },
    { lat: 48.9880408, lng: 22.3637068 },
    { lat: 48.9882487, lng: 22.3632204 },
    { lat: 48.9883305, lng: 22.362507 },
    { lat: 48.9886443, lng: 22.3618284 },
    { lat: 48.9894076, lng: 22.3609137 },
    { lat: 48.9901133, lng: 22.3603011 },
    { lat: 48.9902771, lng: 22.3595887 },
    { lat: 48.9905926, lng: 22.3589877 },
  ],
  DlhénadCirochou: [
    { lat: 49.0020645, lng: 22.0750569 },
    { lat: 49.002534, lng: 22.0759319 },
    { lat: 49.0039287, lng: 22.0765542 },
    { lat: 49.0047643, lng: 22.0770638 },
    { lat: 49.006145, lng: 22.079525 },
    { lat: 49.005906, lng: 22.078405 },
    { lat: 49.005951, lng: 22.078295 },
    { lat: 49.006011, lng: 22.078171 },
    { lat: 49.006252, lng: 22.077472 },
    { lat: 49.006314, lng: 22.077278 },
    { lat: 49.006375, lng: 22.077094 },
    { lat: 49.006429, lng: 22.076848 },
    { lat: 49.006521, lng: 22.076502 },
    { lat: 49.00677, lng: 22.076066 },
    { lat: 49.006867, lng: 22.075894 },
    { lat: 49.006937, lng: 22.07562 },
    { lat: 49.007007, lng: 22.075224 },
    { lat: 49.007158, lng: 22.074991 },
    { lat: 49.007293, lng: 22.074498 },
    { lat: 49.007354, lng: 22.074338 },
    { lat: 49.007453, lng: 22.074099 },
    { lat: 49.007589, lng: 22.073663 },
    { lat: 49.007702, lng: 22.073333 },
    { lat: 49.007838, lng: 22.072925 },
    { lat: 49.007886, lng: 22.072768 },
    { lat: 49.007936, lng: 22.072629 },
    { lat: 49.008025, lng: 22.072322 },
    { lat: 49.008063, lng: 22.072208 },
    { lat: 49.008186, lng: 22.071965 },
    { lat: 49.00823, lng: 22.071833 },
    { lat: 49.008328, lng: 22.071484 },
    { lat: 49.008398, lng: 22.071013 },
    { lat: 49.008574, lng: 22.070678 },
    { lat: 49.00868, lng: 22.070284 },
    { lat: 49.008727, lng: 22.070194 },
    { lat: 49.008941, lng: 22.069733 },
    { lat: 49.008986, lng: 22.069458 },
    { lat: 49.009088, lng: 22.069312 },
    { lat: 49.009345, lng: 22.069026 },
    { lat: 49.009457, lng: 22.068768 },
    { lat: 49.009663, lng: 22.068372 },
    { lat: 49.009749, lng: 22.068273 },
    { lat: 49.00982, lng: 22.068107 },
    { lat: 49.009902, lng: 22.067856 },
    { lat: 49.009858, lng: 22.067296 },
    { lat: 49.009724, lng: 22.066874 },
    { lat: 49.009605, lng: 22.066809 },
    { lat: 49.009415, lng: 22.066526 },
    { lat: 49.009385, lng: 22.066214 },
    { lat: 49.009412, lng: 22.066102 },
    { lat: 49.00942, lng: 22.065825 },
    { lat: 49.009434, lng: 22.06567 },
    { lat: 49.009467, lng: 22.065403 },
    { lat: 49.009527, lng: 22.065173 },
    { lat: 49.009628, lng: 22.064984 },
    { lat: 49.009562, lng: 22.064342 },
    { lat: 49.009621, lng: 22.064159 },
    { lat: 49.009662, lng: 22.063847 },
    { lat: 49.00967, lng: 22.063454 },
    { lat: 49.009687, lng: 22.063458 },
    { lat: 49.009672, lng: 22.06317 },
    { lat: 49.009773, lng: 22.062852 },
    { lat: 49.009921, lng: 22.062438 },
    { lat: 49.00995, lng: 22.062198 },
    { lat: 49.010005, lng: 22.061875 },
    { lat: 49.009995, lng: 22.061589 },
    { lat: 49.009926, lng: 22.061105 },
    { lat: 49.009904, lng: 22.06095 },
    { lat: 49.009772, lng: 22.060807 },
    { lat: 49.00971, lng: 22.06039 },
    { lat: 49.00958, lng: 22.060135 },
    { lat: 49.009344, lng: 22.059937 },
    { lat: 49.009144, lng: 22.05964 },
    { lat: 49.008991, lng: 22.059341 },
    { lat: 49.008899, lng: 22.059197 },
    { lat: 49.008711, lng: 22.05887 },
    { lat: 49.00864, lng: 22.058694 },
    { lat: 49.008389, lng: 22.058148 },
    { lat: 49.008213, lng: 22.057896 },
    { lat: 49.00813, lng: 22.057821 },
    { lat: 49.008034, lng: 22.05763 },
    { lat: 49.007991, lng: 22.057183 },
    { lat: 49.007975, lng: 22.057178 },
    { lat: 49.008074, lng: 22.056338 },
    { lat: 49.008058, lng: 22.056292 },
    { lat: 49.007726, lng: 22.056297 },
    { lat: 49.007469, lng: 22.056258 },
    { lat: 49.007464, lng: 22.056232 },
    { lat: 49.007261, lng: 22.055475 },
    { lat: 49.006935, lng: 22.054975 },
    { lat: 49.006926, lng: 22.054998 },
    { lat: 49.006757, lng: 22.054773 },
    { lat: 49.006463, lng: 22.054408 },
    { lat: 49.006291, lng: 22.0543 },
    { lat: 49.005965, lng: 22.054226 },
    { lat: 49.00572, lng: 22.05424 },
    { lat: 49.00531, lng: 22.053825 },
    { lat: 49.005123, lng: 22.053575 },
    { lat: 49.004875, lng: 22.053383 },
    { lat: 49.004046, lng: 22.052873 },
    { lat: 49.003809, lng: 22.052766 },
    { lat: 49.003398, lng: 22.052595 },
    { lat: 49.003139, lng: 22.052224 },
    { lat: 49.002969, lng: 22.05212 },
    { lat: 49.002655, lng: 22.052014 },
    { lat: 49.002295, lng: 22.051586 },
    { lat: 49.002085, lng: 22.051342 },
    { lat: 49.001961, lng: 22.051237 },
    { lat: 49.001667, lng: 22.051005 },
    { lat: 49.001309, lng: 22.050735 },
    { lat: 49.000923, lng: 22.050519 },
    { lat: 49.000693, lng: 22.050372 },
    { lat: 49.000444, lng: 22.050205 },
    { lat: 48.999888, lng: 22.049907 },
    { lat: 48.999465, lng: 22.049697 },
    { lat: 48.998902, lng: 22.049874 },
    { lat: 48.998367, lng: 22.049655 },
    { lat: 48.998181, lng: 22.049581 },
    { lat: 48.998154, lng: 22.049595 },
    { lat: 48.998148, lng: 22.049609 },
    { lat: 48.998116, lng: 22.049616 },
    { lat: 48.997716, lng: 22.049694 },
    { lat: 48.997206, lng: 22.049896 },
    { lat: 48.996455, lng: 22.049946 },
    { lat: 48.996205, lng: 22.050089 },
    { lat: 48.995847, lng: 22.050097 },
    { lat: 48.995843, lng: 22.050061 },
    { lat: 48.995423, lng: 22.050015 },
    { lat: 48.995112, lng: 22.049854 },
    { lat: 48.995107, lng: 22.049884 },
    { lat: 48.994577, lng: 22.04959 },
    { lat: 48.993391, lng: 22.04898 },
    { lat: 48.993396, lng: 22.048942 },
    { lat: 48.993201, lng: 22.04886 },
    { lat: 48.99231, lng: 22.048628 },
    { lat: 48.991917, lng: 22.04827 },
    { lat: 48.991306, lng: 22.047799 },
    { lat: 48.990843, lng: 22.047443 },
    { lat: 48.990763, lng: 22.047381 },
    { lat: 48.990757, lng: 22.047398 },
    { lat: 48.990494, lng: 22.047178 },
    { lat: 48.990224, lng: 22.047005 },
    { lat: 48.989879, lng: 22.04702 },
    { lat: 48.989497, lng: 22.046969 },
    { lat: 48.989176, lng: 22.046871 },
    { lat: 48.98904, lng: 22.046825 },
    { lat: 48.988549, lng: 22.046894 },
    { lat: 48.988293, lng: 22.046804 },
    { lat: 48.988091, lng: 22.046959 },
    { lat: 48.987793, lng: 22.047041 },
    { lat: 48.987076, lng: 22.047328 },
    { lat: 48.986802, lng: 22.047252 },
    { lat: 48.986462, lng: 22.047344 },
    { lat: 48.986108, lng: 22.047553 },
    { lat: 48.985311, lng: 22.047937 },
    { lat: 48.985078, lng: 22.048081 },
    { lat: 48.984769, lng: 22.048209 },
    { lat: 48.984444, lng: 22.048183 },
    { lat: 48.984031, lng: 22.048352 },
    { lat: 48.983823, lng: 22.0485 },
    { lat: 48.983619, lng: 22.048625 },
    { lat: 48.983381, lng: 22.048846 },
    { lat: 48.983151, lng: 22.04896 },
    { lat: 48.98293, lng: 22.049035 },
    { lat: 48.982688, lng: 22.049199 },
    { lat: 48.982467, lng: 22.049402 },
    { lat: 48.982242, lng: 22.049505 },
    { lat: 48.982213, lng: 22.049513 },
    { lat: 48.9822, lng: 22.049514 },
    { lat: 48.982192, lng: 22.049514 },
    { lat: 48.981595, lng: 22.049636 },
    { lat: 48.980768, lng: 22.049805 },
    { lat: 48.980597, lng: 22.049838 },
    { lat: 48.980226, lng: 22.049883 },
    { lat: 48.97995, lng: 22.049793 },
    { lat: 48.97918, lng: 22.04939 },
    { lat: 48.978878, lng: 22.049488 },
    { lat: 48.978875, lng: 22.04946 },
    { lat: 48.978771, lng: 22.049383 },
    { lat: 48.978637, lng: 22.049098 },
    { lat: 48.978569, lng: 22.048799 },
    { lat: 48.978381, lng: 22.047959 },
    { lat: 48.978251, lng: 22.04765 },
    { lat: 48.978147, lng: 22.047151 },
    { lat: 48.977723, lng: 22.046559 },
    { lat: 48.977281, lng: 22.045635 },
    { lat: 48.976552, lng: 22.044157 },
    { lat: 48.975987, lng: 22.043127 },
    { lat: 48.975818, lng: 22.042725 },
    { lat: 48.975672, lng: 22.040114 },
    { lat: 48.975625, lng: 22.040011 },
    { lat: 48.975402, lng: 22.039506 },
    { lat: 48.975383, lng: 22.039466 },
    { lat: 48.975011, lng: 22.038627 },
    { lat: 48.974555, lng: 22.038097 },
    { lat: 48.974474, lng: 22.038018 },
    { lat: 48.974117, lng: 22.037668 },
    { lat: 48.973929, lng: 22.037086 },
    { lat: 48.973107, lng: 22.035878 },
    { lat: 48.97296, lng: 22.035386 },
    { lat: 48.972717, lng: 22.035148 },
    { lat: 48.972299, lng: 22.034281 },
    { lat: 48.972293, lng: 22.034268 },
    { lat: 48.971786, lng: 22.033316 },
    { lat: 48.97147, lng: 22.032535 },
    { lat: 48.971449, lng: 22.032388 },
    { lat: 48.971383, lng: 22.032257 },
    { lat: 48.971132, lng: 22.031972 },
    { lat: 48.971084, lng: 22.031917 },
    { lat: 48.970967, lng: 22.031784 },
    { lat: 48.970755, lng: 22.031625 },
    { lat: 48.970235, lng: 22.031346 },
    { lat: 48.969929, lng: 22.031151 },
    { lat: 48.969887, lng: 22.031127 },
    { lat: 48.969627, lng: 22.030294 },
    { lat: 48.969311, lng: 22.029546 },
    { lat: 48.969197, lng: 22.028987 },
    { lat: 48.969326, lng: 22.027656 },
    { lat: 48.968949, lng: 22.027536 },
    { lat: 48.968546, lng: 22.027441 },
    { lat: 48.968563, lng: 22.027338 },
    { lat: 48.968474, lng: 22.027296 },
    { lat: 48.968387, lng: 22.027427 },
    { lat: 48.967928, lng: 22.027373 },
    { lat: 48.967742, lng: 22.027427 },
    { lat: 48.967558, lng: 22.027346 },
    { lat: 48.967444, lng: 22.027469 },
    { lat: 48.967439, lng: 22.027471 },
    { lat: 48.9674, lng: 22.027496 },
    { lat: 48.967384, lng: 22.027713 },
    { lat: 48.967019, lng: 22.027811 },
    { lat: 48.966878, lng: 22.027985 },
    { lat: 48.96679, lng: 22.028092 },
    { lat: 48.966811, lng: 22.028262 },
    { lat: 48.966476, lng: 22.028247 },
    { lat: 48.966293, lng: 22.028397 },
    { lat: 48.966096, lng: 22.028242 },
    { lat: 48.96606, lng: 22.028494 },
    { lat: 48.965444, lng: 22.028538 },
    { lat: 48.965094, lng: 22.028681 },
    { lat: 48.964997, lng: 22.02885 },
    { lat: 48.964859, lng: 22.028925 },
    { lat: 48.964953, lng: 22.028575 },
    { lat: 48.964974, lng: 22.028498 },
    { lat: 48.96514, lng: 22.027879 },
    { lat: 48.965222, lng: 22.027572 },
    { lat: 48.965129, lng: 22.026764 },
    { lat: 48.964894, lng: 22.026403 },
    { lat: 48.96486, lng: 22.026208 },
    { lat: 48.964595, lng: 22.025051 },
    { lat: 48.964517, lng: 22.024513 },
    { lat: 48.964447, lng: 22.024237 },
    { lat: 48.964289, lng: 22.023723 },
    { lat: 48.964253, lng: 22.023545 },
    { lat: 48.963941, lng: 22.022569 },
    { lat: 48.96385, lng: 22.022283 },
    { lat: 48.962963, lng: 22.019869 },
    { lat: 48.962667, lng: 22.019043 },
    { lat: 48.962647, lng: 22.018989 },
    { lat: 48.962302, lng: 22.018031 },
    { lat: 48.962379, lng: 22.016218 },
    { lat: 48.962374, lng: 22.016215 },
    { lat: 48.962262, lng: 22.016122 },
    { lat: 48.961817, lng: 22.016195 },
    { lat: 48.961359, lng: 22.016364 },
    { lat: 48.961008, lng: 22.016597 },
    { lat: 48.960203, lng: 22.017212 },
    { lat: 48.960148, lng: 22.017042 },
    { lat: 48.960069, lng: 22.016619 },
    { lat: 48.959896, lng: 22.016471 },
    { lat: 48.959865, lng: 22.016417 },
    { lat: 48.959765, lng: 22.016453 },
    { lat: 48.959676, lng: 22.016485 },
    { lat: 48.959646, lng: 22.016402 },
    { lat: 48.959493, lng: 22.016388 },
    { lat: 48.959161, lng: 22.016453 },
    { lat: 48.959065, lng: 22.016382 },
    { lat: 48.958912, lng: 22.016628 },
    { lat: 48.958766, lng: 22.016741 },
    { lat: 48.958636, lng: 22.016563 },
    { lat: 48.95844, lng: 22.016394 },
    { lat: 48.95831, lng: 22.016393 },
    { lat: 48.958237, lng: 22.016298 },
    { lat: 48.957981, lng: 22.016351 },
    { lat: 48.957935, lng: 22.016304 },
    { lat: 48.957638, lng: 22.016226 },
    { lat: 48.957553, lng: 22.016169 },
    { lat: 48.957159, lng: 22.016473 },
    { lat: 48.957153, lng: 22.016536 },
    { lat: 48.95698, lng: 22.016489 },
    { lat: 48.956958, lng: 22.016605 },
    { lat: 48.956864, lng: 22.016723 },
    { lat: 48.956846, lng: 22.016796 },
    { lat: 48.956712, lng: 22.01678 },
    { lat: 48.95666, lng: 22.016774 },
    { lat: 48.956546, lng: 22.016762 },
    { lat: 48.956424, lng: 22.01678 },
    { lat: 48.956399, lng: 22.016783 },
    { lat: 48.956347, lng: 22.016791 },
    { lat: 48.956257, lng: 22.016858 },
    { lat: 48.956048, lng: 22.01702 },
    { lat: 48.955758, lng: 22.01727 },
    { lat: 48.954544, lng: 22.018319 },
    { lat: 48.954455, lng: 22.018379 },
    { lat: 48.953254, lng: 22.019426 },
    { lat: 48.953262, lng: 22.019465 },
    { lat: 48.953278, lng: 22.019543 },
    { lat: 48.953463, lng: 22.019995 },
    { lat: 48.953854, lng: 22.020298 },
    { lat: 48.954031, lng: 22.020558 },
    { lat: 48.954088, lng: 22.021367 },
    { lat: 48.953984, lng: 22.021675 },
    { lat: 48.953857, lng: 22.02173 },
    { lat: 48.953476, lng: 22.022499 },
    { lat: 48.952982, lng: 22.02292 },
    { lat: 48.952812, lng: 22.022673 },
    { lat: 48.952791, lng: 22.022497 },
    { lat: 48.952744, lng: 22.022278 },
    { lat: 48.952892, lng: 22.022162 },
    { lat: 48.952822, lng: 22.021977 },
    { lat: 48.95276, lng: 22.021851 },
    { lat: 48.952593, lng: 22.021598 },
    { lat: 48.952414, lng: 22.021235 },
    { lat: 48.952167, lng: 22.021366 },
    { lat: 48.952095, lng: 22.021449 },
    { lat: 48.952137, lng: 22.021724 },
    { lat: 48.951816, lng: 22.021979 },
    { lat: 48.951685, lng: 22.021844 },
    { lat: 48.951636, lng: 22.021765 },
    { lat: 48.951522, lng: 22.021689 },
    { lat: 48.951295, lng: 22.02177 },
    { lat: 48.951118, lng: 22.021859 },
    { lat: 48.951033, lng: 22.021979 },
    { lat: 48.950927, lng: 22.022136 },
    { lat: 48.950833, lng: 22.022216 },
    { lat: 48.95071, lng: 22.022248 },
    { lat: 48.950505, lng: 22.022356 },
    { lat: 48.950178, lng: 22.022554 },
    { lat: 48.949338, lng: 22.02307 },
    { lat: 48.948948, lng: 22.023311 },
    { lat: 48.948971, lng: 22.023463 },
    { lat: 48.948992, lng: 22.023681 },
    { lat: 48.949045, lng: 22.023913 },
    { lat: 48.949121, lng: 22.024219 },
    { lat: 48.949157, lng: 22.024353 },
    { lat: 48.94885, lng: 22.024585 },
    { lat: 48.948806, lng: 22.024618 },
    { lat: 48.948292, lng: 22.024939 },
    { lat: 48.94835, lng: 22.025216 },
    { lat: 48.948373, lng: 22.025346 },
    { lat: 48.948462, lng: 22.025804 },
    { lat: 48.948479, lng: 22.02594 },
    { lat: 48.948529, lng: 22.026164 },
    { lat: 48.948564, lng: 22.026383 },
    { lat: 48.948585, lng: 22.026516 },
    { lat: 48.948787, lng: 22.027347 },
    { lat: 48.948844, lng: 22.027657 },
    { lat: 48.94886, lng: 22.027854 },
    { lat: 48.948903, lng: 22.028022 },
    { lat: 48.949042, lng: 22.028566 },
    { lat: 48.948106, lng: 22.029318 },
    { lat: 48.94833, lng: 22.030136 },
    { lat: 48.948471, lng: 22.030429 },
    { lat: 48.948556, lng: 22.030696 },
    { lat: 48.94859, lng: 22.030805 },
    { lat: 48.94862, lng: 22.030882 },
    { lat: 48.948687, lng: 22.031111 },
    { lat: 48.948797, lng: 22.031594 },
    { lat: 48.948688, lng: 22.031749 },
    { lat: 48.948637, lng: 22.031948 },
    { lat: 48.948588, lng: 22.032264 },
    { lat: 48.948602, lng: 22.032564 },
    { lat: 48.948796, lng: 22.033217 },
    { lat: 48.948776, lng: 22.033797 },
    { lat: 48.948744, lng: 22.033968 },
    { lat: 48.948792, lng: 22.034315 },
    { lat: 48.948794, lng: 22.034563 },
    { lat: 48.948843, lng: 22.034828 },
    { lat: 48.948879, lng: 22.035089 },
    { lat: 48.948919, lng: 22.035229 },
    { lat: 48.948937, lng: 22.035433 },
    { lat: 48.948983, lng: 22.035706 },
    { lat: 48.949065, lng: 22.035898 },
    { lat: 48.949182, lng: 22.036239 },
    { lat: 48.949247, lng: 22.036526 },
    { lat: 48.949464, lng: 22.036953 },
    { lat: 48.949547, lng: 22.037299 },
    { lat: 48.949563, lng: 22.037363 },
    { lat: 48.949574, lng: 22.037411 },
    { lat: 48.94999, lng: 22.038023 },
    { lat: 48.949931, lng: 22.038127 },
    { lat: 48.949913, lng: 22.038159 },
    { lat: 48.949866, lng: 22.038245 },
    { lat: 48.949846, lng: 22.038279 },
    { lat: 48.949837, lng: 22.03906 },
    { lat: 48.950092, lng: 22.039925 },
    { lat: 48.950339, lng: 22.040679 },
    { lat: 48.950777, lng: 22.041879 },
    { lat: 48.950907, lng: 22.042184 },
    { lat: 48.951103, lng: 22.042733 },
    { lat: 48.951129, lng: 22.042809 },
    { lat: 48.951028, lng: 22.042999 },
    { lat: 48.950968, lng: 22.043112 },
    { lat: 48.950915, lng: 22.043058 },
    { lat: 48.9507, lng: 22.043353 },
    { lat: 48.950654, lng: 22.043526 },
    { lat: 48.950534, lng: 22.043833 },
    { lat: 48.950468, lng: 22.044091 },
    { lat: 48.950428, lng: 22.044182 },
    { lat: 48.950376, lng: 22.044343 },
    { lat: 48.950341, lng: 22.044509 },
    { lat: 48.950284, lng: 22.044673 },
    { lat: 48.950197, lng: 22.044869 },
    { lat: 48.950034, lng: 22.045468 },
    { lat: 48.950001, lng: 22.045746 },
    { lat: 48.949972, lng: 22.046106 },
    { lat: 48.949944, lng: 22.046245 },
    { lat: 48.949925, lng: 22.046562 },
    { lat: 48.949931, lng: 22.046933 },
    { lat: 48.949888, lng: 22.046965 },
    { lat: 48.949877, lng: 22.047046 },
    { lat: 48.949939, lng: 22.047152 },
    { lat: 48.949906, lng: 22.047252 },
    { lat: 48.949822, lng: 22.047216 },
    { lat: 48.949777, lng: 22.047325 },
    { lat: 48.949708, lng: 22.047235 },
    { lat: 48.949657, lng: 22.047283 },
    { lat: 48.949624, lng: 22.047445 },
    { lat: 48.949492, lng: 22.047378 },
    { lat: 48.949428, lng: 22.047659 },
    { lat: 48.949466, lng: 22.047798 },
    { lat: 48.949409, lng: 22.0482 },
    { lat: 48.949577, lng: 22.048643 },
    { lat: 48.949215, lng: 22.049098 },
    { lat: 48.949162, lng: 22.049157 },
    { lat: 48.948959, lng: 22.049508 },
    { lat: 48.948855, lng: 22.049722 },
    { lat: 48.948579, lng: 22.050249 },
    { lat: 48.948437, lng: 22.050581 },
    { lat: 48.948356, lng: 22.050806 },
    { lat: 48.94832, lng: 22.050958 },
    { lat: 48.948477, lng: 22.051187 },
    { lat: 48.948323, lng: 22.051376 },
    { lat: 48.948224, lng: 22.051497 },
    { lat: 48.948121, lng: 22.051651 },
    { lat: 48.948088, lng: 22.051723 },
    { lat: 48.948077, lng: 22.051791 },
    { lat: 48.948069, lng: 22.052132 },
    { lat: 48.948068, lng: 22.05241 },
    { lat: 48.948089, lng: 22.05259 },
    { lat: 48.948089, lng: 22.052791 },
    { lat: 48.948078, lng: 22.052888 },
    { lat: 48.948081, lng: 22.052996 },
    { lat: 48.948069, lng: 22.053069 },
    { lat: 48.948027, lng: 22.053277 },
    { lat: 48.947986, lng: 22.05337 },
    { lat: 48.947966, lng: 22.053469 },
    { lat: 48.947966, lng: 22.053543 },
    { lat: 48.94795, lng: 22.053645 },
    { lat: 48.947912, lng: 22.0537 },
    { lat: 48.94789, lng: 22.05378 },
    { lat: 48.947893, lng: 22.053821 },
    { lat: 48.947908, lng: 22.05397 },
    { lat: 48.947882, lng: 22.054095 },
    { lat: 48.947862, lng: 22.05439 },
    { lat: 48.947809, lng: 22.054748 },
    { lat: 48.947758, lng: 22.054844 },
    { lat: 48.947714, lng: 22.054892 },
    { lat: 48.947697, lng: 22.05499 },
    { lat: 48.94772, lng: 22.055359 },
    { lat: 48.947708, lng: 22.05558 },
    { lat: 48.947672, lng: 22.055673 },
    { lat: 48.947576, lng: 22.055823 },
    { lat: 48.947422, lng: 22.056205 },
    { lat: 48.947484, lng: 22.05668 },
    { lat: 48.947471, lng: 22.056825 },
    { lat: 48.947444, lng: 22.056991 },
    { lat: 48.947507, lng: 22.057196 },
    { lat: 48.947539, lng: 22.057344 },
    { lat: 48.947592, lng: 22.057793 },
    { lat: 48.947583, lng: 22.057918 },
    { lat: 48.947578, lng: 22.057974 },
    { lat: 48.947629, lng: 22.058166 },
    { lat: 48.947562, lng: 22.05825 },
    { lat: 48.947458, lng: 22.058379 },
    { lat: 48.947232, lng: 22.058523 },
    { lat: 48.947144, lng: 22.058603 },
    { lat: 48.947114, lng: 22.058629 },
    { lat: 48.946998, lng: 22.058711 },
    { lat: 48.946898, lng: 22.058812 },
    { lat: 48.946782, lng: 22.058861 },
    { lat: 48.946684, lng: 22.058851 },
    { lat: 48.946615, lng: 22.058878 },
    { lat: 48.946501, lng: 22.058939 },
    { lat: 48.946375, lng: 22.058997 },
    { lat: 48.946213, lng: 22.059104 },
    { lat: 48.94605, lng: 22.059169 },
    { lat: 48.945898, lng: 22.059201 },
    { lat: 48.945828, lng: 22.059191 },
    { lat: 48.945782, lng: 22.059214 },
    { lat: 48.945697, lng: 22.059189 },
    { lat: 48.94565, lng: 22.059176 },
    { lat: 48.945547, lng: 22.059196 },
    { lat: 48.945467, lng: 22.059247 },
    { lat: 48.94543, lng: 22.059258 },
    { lat: 48.945234, lng: 22.059356 },
    { lat: 48.945169, lng: 22.059413 },
    { lat: 48.945027, lng: 22.059581 },
    { lat: 48.944981, lng: 22.059636 },
    { lat: 48.944935, lng: 22.059736 },
    { lat: 48.944894, lng: 22.059946 },
    { lat: 48.944853, lng: 22.060126 },
    { lat: 48.94483, lng: 22.060289 },
    { lat: 48.944821, lng: 22.060539 },
    { lat: 48.944775, lng: 22.061173 },
    { lat: 48.944752, lng: 22.061601 },
    { lat: 48.944409, lng: 22.061853 },
    { lat: 48.94422, lng: 22.06202 },
    { lat: 48.94407, lng: 22.0622 },
    { lat: 48.943995, lng: 22.06231 },
    { lat: 48.943703, lng: 22.062552 },
    { lat: 48.943565, lng: 22.062625 },
    { lat: 48.943415, lng: 22.062728 },
    { lat: 48.943267, lng: 22.062832 },
    { lat: 48.943071, lng: 22.063013 },
    { lat: 48.942989, lng: 22.063109 },
    { lat: 48.942676, lng: 22.063391 },
    { lat: 48.942368, lng: 22.06362 },
    { lat: 48.941986, lng: 22.063926 },
    { lat: 48.941549, lng: 22.064474 },
    { lat: 48.941359, lng: 22.064592 },
    { lat: 48.941255, lng: 22.064705 },
    { lat: 48.941171, lng: 22.06475 },
    { lat: 48.941087, lng: 22.064803 },
    { lat: 48.941001, lng: 22.064856 },
    { lat: 48.940917, lng: 22.064941 },
    { lat: 48.94084, lng: 22.065059 },
    { lat: 48.940762, lng: 22.065132 },
    { lat: 48.940654, lng: 22.065294 },
    { lat: 48.940541, lng: 22.06564 },
    { lat: 48.940426, lng: 22.065906 },
    { lat: 48.940303, lng: 22.066085 },
    { lat: 48.940162, lng: 22.066405 },
    { lat: 48.940057, lng: 22.066642 },
    { lat: 48.940052, lng: 22.06665 },
    { lat: 48.939834, lng: 22.06703 },
    { lat: 48.939797, lng: 22.067093 },
    { lat: 48.939666, lng: 22.067322 },
    { lat: 48.939489, lng: 22.06764 },
    { lat: 48.939186, lng: 22.068087 },
    { lat: 48.93927, lng: 22.068397 },
    { lat: 48.939815, lng: 22.069766 },
    { lat: 48.939822, lng: 22.069784 },
    { lat: 48.939183, lng: 22.07067 },
    { lat: 48.939079, lng: 22.070814 },
    { lat: 48.938989, lng: 22.07097 },
    { lat: 48.938867, lng: 22.071179 },
    { lat: 48.938859, lng: 22.071196 },
    { lat: 48.938982, lng: 22.071536 },
    { lat: 48.939036, lng: 22.071685 },
    { lat: 48.939218, lng: 22.072186 },
    { lat: 48.939406, lng: 22.072663 },
    { lat: 48.939619, lng: 22.072928 },
    { lat: 48.939644, lng: 22.07296 },
    { lat: 48.9401, lng: 22.073524 },
    { lat: 48.940952, lng: 22.072786 },
    { lat: 48.940956, lng: 22.072777 },
    { lat: 48.940975, lng: 22.072718 },
    { lat: 48.941175, lng: 22.072128 },
    { lat: 48.941818, lng: 22.073592 },
    { lat: 48.942089, lng: 22.074392 },
    { lat: 48.943668, lng: 22.079053 },
    { lat: 48.943101, lng: 22.079558 },
    { lat: 48.939316, lng: 22.082925 },
    { lat: 48.939234, lng: 22.082998 },
    { lat: 48.941396, lng: 22.089244 },
    { lat: 48.941959, lng: 22.090866 },
    { lat: 48.942044, lng: 22.091116 },
    { lat: 48.942176, lng: 22.091494 },
    { lat: 48.942278, lng: 22.091791 },
    { lat: 48.942635, lng: 22.092822 },
    { lat: 48.942637, lng: 22.092828 },
    { lat: 48.942132, lng: 22.093208 },
    { lat: 48.941689, lng: 22.093541 },
    { lat: 48.939007, lng: 22.095551 },
    { lat: 48.938966, lng: 22.095581 },
    { lat: 48.933217, lng: 22.099891 },
    { lat: 48.933198, lng: 22.099906 },
    { lat: 48.933648, lng: 22.100506 },
    { lat: 48.93387, lng: 22.100803 },
    { lat: 48.934033, lng: 22.10102 },
    { lat: 48.93412, lng: 22.101136 },
    { lat: 48.93422, lng: 22.101267 },
    { lat: 48.934357, lng: 22.10145 },
    { lat: 48.934654, lng: 22.101846 },
    { lat: 48.934654, lng: 22.101848 },
    { lat: 48.934211, lng: 22.102908 },
    { lat: 48.934086, lng: 22.103205 },
    { lat: 48.934002, lng: 22.103405 },
    { lat: 48.93397, lng: 22.103481 },
    { lat: 48.933945, lng: 22.103543 },
    { lat: 48.933403, lng: 22.104833 },
    { lat: 48.932943, lng: 22.105665 },
    { lat: 48.932591, lng: 22.107094 },
    { lat: 48.931929, lng: 22.108559 },
    { lat: 48.931761, lng: 22.109515 },
    { lat: 48.932063, lng: 22.111473 },
    { lat: 48.932236, lng: 22.112083 },
    { lat: 48.932209, lng: 22.112866 },
    { lat: 48.932334, lng: 22.113544 },
    { lat: 48.931794, lng: 22.115841 },
    { lat: 48.931724, lng: 22.116901 },
    { lat: 48.932603, lng: 22.116916 },
    { lat: 48.933113, lng: 22.116853 },
    { lat: 48.933198, lng: 22.11686 },
    { lat: 48.934502, lng: 22.116962 },
    { lat: 48.9347588, lng: 22.1170353 },
    { lat: 48.9363753, lng: 22.1169386 },
    { lat: 48.9365695, lng: 22.1168752 },
    { lat: 48.9374566, lng: 22.1162408 },
    { lat: 48.9377182, lng: 22.1157344 },
    { lat: 48.9379566, lng: 22.1150618 },
    { lat: 48.9385738, lng: 22.1145667 },
    { lat: 48.9396328, lng: 22.1143522 },
    { lat: 48.94026, lng: 22.1141421 },
    { lat: 48.9408955, lng: 22.1142871 },
    { lat: 48.941075, lng: 22.1140223 },
    { lat: 48.941649, lng: 22.1135105 },
    { lat: 48.9417595, lng: 22.1135175 },
    { lat: 48.9418494, lng: 22.1133294 },
    { lat: 48.9420785, lng: 22.113262 },
    { lat: 48.9422071, lng: 22.1129938 },
    { lat: 48.9424389, lng: 22.1127982 },
    { lat: 48.9426488, lng: 22.1127321 },
    { lat: 48.9428484, lng: 22.1124186 },
    { lat: 48.9429471, lng: 22.1124065 },
    { lat: 48.9433774, lng: 22.1118581 },
    { lat: 48.9436319, lng: 22.1117778 },
    { lat: 48.9437165, lng: 22.111643 },
    { lat: 48.9460313, lng: 22.1114033 },
    { lat: 48.947, lng: 22.1102543 },
    { lat: 48.9477842, lng: 22.1096938 },
    { lat: 48.9481166, lng: 22.1090042 },
    { lat: 48.9483992, lng: 22.1085817 },
    { lat: 48.9486095, lng: 22.1084301 },
    { lat: 48.9487889, lng: 22.1080697 },
    { lat: 48.9491062, lng: 22.1079832 },
    { lat: 48.9500166, lng: 22.1071331 },
    { lat: 48.9502764, lng: 22.1069938 },
    { lat: 48.9503284, lng: 22.1068601 },
    { lat: 48.9514805, lng: 22.1061576 },
    { lat: 48.9517464, lng: 22.1058365 },
    { lat: 48.9518267, lng: 22.1058544 },
    { lat: 48.9519394, lng: 22.1055742 },
    { lat: 48.9521814, lng: 22.1054426 },
    { lat: 48.9530139, lng: 22.1028617 },
    { lat: 48.9536011, lng: 22.1020504 },
    { lat: 48.9541361, lng: 22.1027029 },
    { lat: 48.9551573, lng: 22.1031312 },
    { lat: 48.9578165, lng: 22.1004053 },
    { lat: 48.9643304, lng: 22.0939657 },
    { lat: 48.9661221, lng: 22.0922418 },
    { lat: 48.9675927, lng: 22.0911158 },
    { lat: 48.97032, lng: 22.0882371 },
    { lat: 48.9706023, lng: 22.0878584 },
    { lat: 48.9718538, lng: 22.0853178 },
    { lat: 48.9721546, lng: 22.0860071 },
    { lat: 48.9725731, lng: 22.0865836 },
    { lat: 48.9729274, lng: 22.0869671 },
    { lat: 48.9734401, lng: 22.0872861 },
    { lat: 48.973791, lng: 22.0870973 },
    { lat: 48.9740718, lng: 22.0863194 },
    { lat: 48.9743111, lng: 22.0848771 },
    { lat: 48.9746114, lng: 22.0845367 },
    { lat: 48.9748921, lng: 22.0840857 },
    { lat: 48.9751486, lng: 22.0834702 },
    { lat: 48.9752367, lng: 22.0825399 },
    { lat: 48.9752465, lng: 22.0818583 },
    { lat: 48.9752015, lng: 22.0813319 },
    { lat: 48.9746887, lng: 22.0800975 },
    { lat: 48.9741071, lng: 22.0794339 },
    { lat: 48.9732245, lng: 22.0793983 },
    { lat: 48.9754226, lng: 22.0763731 },
    { lat: 48.9764899, lng: 22.0767018 },
    { lat: 48.9768907, lng: 22.0771443 },
    { lat: 48.9770315, lng: 22.0776268 },
    { lat: 48.9772601, lng: 22.0779663 },
    { lat: 48.9775683, lng: 22.0789173 },
    { lat: 48.9780302, lng: 22.0796602 },
    { lat: 48.9817439, lng: 22.0758987 },
    { lat: 48.9819905, lng: 22.0757614 },
    { lat: 48.9824442, lng: 22.0756921 },
    { lat: 48.9842719, lng: 22.0743052 },
    { lat: 48.9853802, lng: 22.0737095 },
    { lat: 48.9861783, lng: 22.073543 },
    { lat: 48.9866424, lng: 22.0730894 },
    { lat: 48.9869433, lng: 22.0727953 },
    { lat: 48.9874603, lng: 22.0721113 },
    { lat: 48.9878426, lng: 22.0712985 },
    { lat: 48.9882954, lng: 22.0706617 },
    { lat: 48.9899236, lng: 22.068983 },
    { lat: 48.990284, lng: 22.0687652 },
    { lat: 48.9909738, lng: 22.0679283 },
    { lat: 48.9922071, lng: 22.0670538 },
    { lat: 48.9925533, lng: 22.0669022 },
    { lat: 48.9933247, lng: 22.0668882 },
    { lat: 48.9941199, lng: 22.0672673 },
    { lat: 48.9948364, lng: 22.0672645 },
    { lat: 48.9964452, lng: 22.0678074 },
    { lat: 48.9968788, lng: 22.0675974 },
    { lat: 48.9974972, lng: 22.0675846 },
    { lat: 48.9980158, lng: 22.0681518 },
    { lat: 48.9983938, lng: 22.0690127 },
    { lat: 48.9987503, lng: 22.0692972 },
    { lat: 48.9991819, lng: 22.0694663 },
    { lat: 48.9995439, lng: 22.0694175 },
    { lat: 48.9998374, lng: 22.0695092 },
    { lat: 49.0005991, lng: 22.0709351 },
    { lat: 49.0009423, lng: 22.0711742 },
    { lat: 49.001312, lng: 22.0715892 },
    { lat: 49.0015765, lng: 22.0721676 },
    { lat: 49.001613, lng: 22.0723838 },
    { lat: 49.0017126, lng: 22.0724998 },
    { lat: 49.0018069, lng: 22.0727805 },
    { lat: 49.0016218, lng: 22.0740795 },
    { lat: 49.0020811, lng: 22.0750012 },
    { lat: 49.0020645, lng: 22.0750569 },
  ],
  RuskýPotok: [
    { lat: 49.0069803, lng: 22.4096923 },
    { lat: 49.0074475, lng: 22.4105812 },
    { lat: 49.0078542, lng: 22.4112716 },
    { lat: 49.0084588, lng: 22.4118437 },
    { lat: 49.0089161, lng: 22.412574 },
    { lat: 49.0095898, lng: 22.4133444 },
    { lat: 49.0102375, lng: 22.4143623 },
    { lat: 49.0109359, lng: 22.4152312 },
    { lat: 49.0115721, lng: 22.416466 },
    { lat: 49.0125116, lng: 22.417893 },
    { lat: 49.0131572, lng: 22.4185488 },
    { lat: 49.0148425, lng: 22.4196106 },
    { lat: 49.0155626, lng: 22.419726 },
    { lat: 49.0162663, lng: 22.4198388 },
    { lat: 49.016775, lng: 22.4207209 },
    { lat: 49.0181151, lng: 22.4233729 },
    { lat: 49.0190168, lng: 22.4250382 },
    { lat: 49.0191688, lng: 22.4254386 },
    { lat: 49.0199999, lng: 22.4262603 },
    { lat: 49.0216544, lng: 22.4286082 },
    { lat: 49.0220801, lng: 22.4295069 },
    { lat: 49.0224924, lng: 22.4300929 },
    { lat: 49.0226495, lng: 22.4303978 },
    { lat: 49.0231982, lng: 22.4317079 },
    { lat: 49.0237385, lng: 22.4323346 },
    { lat: 49.0240438, lng: 22.4329586 },
    { lat: 49.0242139, lng: 22.4335715 },
    { lat: 49.0245414, lng: 22.4338587 },
    { lat: 49.0248976, lng: 22.4339532 },
    { lat: 49.0257807, lng: 22.4353327 },
    { lat: 49.0261394, lng: 22.436266 },
    { lat: 49.0263147, lng: 22.4365852 },
    { lat: 49.0265319, lng: 22.4377972 },
    { lat: 49.0270094, lng: 22.4387628 },
    { lat: 49.027336, lng: 22.4383933 },
    { lat: 49.0278179, lng: 22.4380951 },
    { lat: 49.0282522, lng: 22.4376115 },
    { lat: 49.0279873, lng: 22.4366035 },
    { lat: 49.0282911, lng: 22.4359515 },
    { lat: 49.0287322, lng: 22.4354668 },
    { lat: 49.02899, lng: 22.4353768 },
    { lat: 49.0294374, lng: 22.435477 },
    { lat: 49.0296576, lng: 22.4356292 },
    { lat: 49.0304, lng: 22.4356907 },
    { lat: 49.0317353, lng: 22.4356031 },
    { lat: 49.0318298, lng: 22.4354417 },
    { lat: 49.0320706, lng: 22.4352908 },
    { lat: 49.0321607, lng: 22.435298 },
    { lat: 49.0324714, lng: 22.4355305 },
    { lat: 49.0328176, lng: 22.4356222 },
    { lat: 49.0332104, lng: 22.4358616 },
    { lat: 49.0339305, lng: 22.4358263 },
    { lat: 49.0342984, lng: 22.4356658 },
    { lat: 49.0346934, lng: 22.4356348 },
    { lat: 49.0355492, lng: 22.4350798 },
    { lat: 49.03602, lng: 22.434895 },
    { lat: 49.0364334, lng: 22.4349355 },
    { lat: 49.0368639, lng: 22.4348179 },
    { lat: 49.0377556, lng: 22.4351353 },
    { lat: 49.0383061, lng: 22.4350013 },
    { lat: 49.0399726, lng: 22.4340158 },
    { lat: 49.0411188, lng: 22.4338096 },
    { lat: 49.0418831, lng: 22.4339499 },
    { lat: 49.0421957, lng: 22.4337372 },
    { lat: 49.0428604, lng: 22.433632 },
    { lat: 49.043285, lng: 22.4334892 },
    { lat: 49.0446577, lng: 22.4333665 },
    { lat: 49.045195, lng: 22.4329201 },
    { lat: 49.0456178, lng: 22.4330239 },
    { lat: 49.0457718, lng: 22.4331328 },
    { lat: 49.0460352, lng: 22.4333615 },
    { lat: 49.047023, lng: 22.4344785 },
    { lat: 49.0485383, lng: 22.435536 },
    { lat: 49.0489898, lng: 22.43556 },
    { lat: 49.0494553, lng: 22.4354511 },
    { lat: 49.0498627, lng: 22.4353554 },
    { lat: 49.0505027, lng: 22.4337284 },
    { lat: 49.0508723, lng: 22.4326116 },
    { lat: 49.0507394, lng: 22.431238 },
    { lat: 49.0507458, lng: 22.4296975 },
    { lat: 49.0505217, lng: 22.4280934 },
    { lat: 49.050319, lng: 22.4273147 },
    { lat: 49.0503282, lng: 22.4266219 },
    { lat: 49.0502384, lng: 22.4255349 },
    { lat: 49.0505376, lng: 22.4245028 },
    { lat: 49.05057, lng: 22.4234059 },
    { lat: 49.0507607, lng: 22.4224586 },
    { lat: 49.0509836, lng: 22.4221517 },
    { lat: 49.0509958, lng: 22.4217041 },
    { lat: 49.0512139, lng: 22.42066 },
    { lat: 49.0516042, lng: 22.4197973 },
    { lat: 49.0517959, lng: 22.4189055 },
    { lat: 49.0519116, lng: 22.4182424 },
    { lat: 49.0519086, lng: 22.4174957 },
    { lat: 49.0522066, lng: 22.4158207 },
    { lat: 49.051962, lng: 22.4147077 },
    { lat: 49.0519226, lng: 22.4141148 },
    { lat: 49.0517226, lng: 22.4133571 },
    { lat: 49.0514368, lng: 22.4127578 },
    { lat: 49.0509571, lng: 22.4122387 },
    { lat: 49.0508339, lng: 22.4116047 },
    { lat: 49.0508054, lng: 22.4110485 },
    { lat: 49.0501544, lng: 22.410138 },
    { lat: 49.0498241, lng: 22.4097785 },
    { lat: 49.0495426, lng: 22.4085688 },
    { lat: 49.0496114, lng: 22.407163 },
    { lat: 49.0493401, lng: 22.4043441 },
    { lat: 49.0494719, lng: 22.4033289 },
    { lat: 49.0493264, lng: 22.4026125 },
    { lat: 49.0487983, lng: 22.4012297 },
    { lat: 49.0484538, lng: 22.3999112 },
    { lat: 49.0480345, lng: 22.3978195 },
    { lat: 49.048091, lng: 22.3955259 },
    { lat: 49.0484327, lng: 22.3945461 },
    { lat: 49.0487665, lng: 22.3940029 },
    { lat: 49.0490882, lng: 22.3931473 },
    { lat: 49.048781, lng: 22.3931378 },
    { lat: 49.0485181, lng: 22.393527 },
    { lat: 49.0482862, lng: 22.393345 },
    { lat: 49.0481858, lng: 22.3930656 },
    { lat: 49.0479451, lng: 22.3937595 },
    { lat: 49.0477156, lng: 22.3940312 },
    { lat: 49.0476081, lng: 22.395175 },
    { lat: 49.0473104, lng: 22.3961663 },
    { lat: 49.0462041, lng: 22.398222 },
    { lat: 49.0456803, lng: 22.3989374 },
    { lat: 49.0449637, lng: 22.3990273 },
    { lat: 49.0440113, lng: 22.3980624 },
    { lat: 49.0433283, lng: 22.3975096 },
    { lat: 49.0430338, lng: 22.3974084 },
    { lat: 49.0428552, lng: 22.3972522 },
    { lat: 49.0422129, lng: 22.3970341 },
    { lat: 49.0413669, lng: 22.3956211 },
    { lat: 49.0412527, lng: 22.3953251 },
    { lat: 49.04071, lng: 22.3945915 },
    { lat: 49.0403412, lng: 22.3945893 },
    { lat: 49.0400618, lng: 22.3944035 },
    { lat: 49.0398404, lng: 22.3944199 },
    { lat: 49.0389355, lng: 22.3945935 },
    { lat: 49.0380762, lng: 22.3948576 },
    { lat: 49.0375682, lng: 22.39487 },
    { lat: 49.0367094, lng: 22.3952725 },
    { lat: 49.036416, lng: 22.3951183 },
    { lat: 49.036117, lng: 22.3947478 },
    { lat: 49.0355055, lng: 22.3928211 },
    { lat: 49.0353433, lng: 22.392539 },
    { lat: 49.0352603, lng: 22.3916918 },
    { lat: 49.0345662, lng: 22.3917512 },
    { lat: 49.0333991, lng: 22.3918511 },
    { lat: 49.0315709, lng: 22.3914469 },
    { lat: 49.0310004, lng: 22.3916928 },
    { lat: 49.0304891, lng: 22.3923645 },
    { lat: 49.0302135, lng: 22.392414 },
    { lat: 49.0296058, lng: 22.3923373 },
    { lat: 49.0294355, lng: 22.3924768 },
    { lat: 49.0291353, lng: 22.392459 },
    { lat: 49.0289492, lng: 22.392448 },
    { lat: 49.0283235, lng: 22.3921067 },
    { lat: 49.0275075, lng: 22.391113 },
    { lat: 49.026912, lng: 22.3907613 },
    { lat: 49.0266731, lng: 22.3907872 },
    { lat: 49.0263455, lng: 22.3909884 },
    { lat: 49.0258303, lng: 22.3905002 },
    { lat: 49.0255579, lng: 22.3904338 },
    { lat: 49.0252864, lng: 22.3906261 },
    { lat: 49.02443, lng: 22.3897915 },
    { lat: 49.0239051, lng: 22.3898094 },
    { lat: 49.0226393, lng: 22.3901005 },
    { lat: 49.021478, lng: 22.3896838 },
    { lat: 49.0207657, lng: 22.3895531 },
    { lat: 49.0201343, lng: 22.3893264 },
    { lat: 49.0196439, lng: 22.3890457 },
    { lat: 49.0188067, lng: 22.3888143 },
    { lat: 49.0187841, lng: 22.3885907 },
    { lat: 49.0185612, lng: 22.3882547 },
    { lat: 49.0182952, lng: 22.3883393 },
    { lat: 49.0181921, lng: 22.3884654 },
    { lat: 49.0181615, lng: 22.3886697 },
    { lat: 49.0169441, lng: 22.3886943 },
    { lat: 49.0161805, lng: 22.3890183 },
    { lat: 49.0144925, lng: 22.389232 },
    { lat: 49.0137323, lng: 22.3897178 },
    { lat: 49.0127333, lng: 22.3905583 },
    { lat: 49.0120793, lng: 22.3912735 },
    { lat: 49.0117683, lng: 22.3917858 },
    { lat: 49.0114105, lng: 22.3925218 },
    { lat: 49.0110246, lng: 22.3929431 },
    { lat: 49.0108766, lng: 22.3935063 },
    { lat: 49.0102672, lng: 22.3937765 },
    { lat: 49.0100558, lng: 22.394045 },
    { lat: 49.0097915, lng: 22.3945937 },
    { lat: 49.0092601, lng: 22.394886 },
    { lat: 49.0088017, lng: 22.3952223 },
    { lat: 49.0084379, lng: 22.3952585 },
    { lat: 49.0080537, lng: 22.3954888 },
    { lat: 49.0079904, lng: 22.3968148 },
    { lat: 49.0078749, lng: 22.3979599 },
    { lat: 49.0078761, lng: 22.3986154 },
    { lat: 49.0074093, lng: 22.3987217 },
    { lat: 49.0063766, lng: 22.3993967 },
    { lat: 49.0063963, lng: 22.4012072 },
    { lat: 49.0066555, lng: 22.4020888 },
    { lat: 49.0068309, lng: 22.403007 },
    { lat: 49.0067985, lng: 22.4037676 },
    { lat: 49.0067197, lng: 22.4043643 },
    { lat: 49.0067675, lng: 22.4063851 },
    { lat: 49.0066501, lng: 22.4076066 },
    { lat: 49.0067026, lng: 22.4082947 },
    { lat: 49.0068966, lng: 22.4087485 },
    { lat: 49.0069803, lng: 22.4096923 },
  ],
};

export default UnitsSnina;
