const UnitsVranovNadTopľou = {
  Holčíkovce: [
    { lat: 49.0400706, lng: 21.7072996 },
    { lat: 49.0398492, lng: 21.7070531 },
    { lat: 49.0254728, lng: 21.701635 },
    { lat: 49.0007209, lng: 21.6969468 },
    { lat: 49.000646, lng: 21.6969687 },
    { lat: 49.000705, lng: 21.6994495 },
    { lat: 49.0014704, lng: 21.6994639 },
    { lat: 49.0015089, lng: 21.6996428 },
    { lat: 49.0007072, lng: 21.6996994 },
    { lat: 49.0003155, lng: 21.6996482 },
    { lat: 48.9994681, lng: 21.6987571 },
    { lat: 48.9991503, lng: 21.6985318 },
    { lat: 48.9986737, lng: 21.6985069 },
    { lat: 48.9980416, lng: 21.6989328 },
    { lat: 48.9971302, lng: 21.6997981 },
    { lat: 48.9968325, lng: 21.7003608 },
    { lat: 48.9962953, lng: 21.7015908 },
    { lat: 48.9959252, lng: 21.7026423 },
    { lat: 48.9954436, lng: 21.7035131 },
    { lat: 48.9952329, lng: 21.7037175 },
    { lat: 48.9952662, lng: 21.7042008 },
    { lat: 48.996346, lng: 21.7067048 },
    { lat: 48.9974654, lng: 21.7078789 },
    { lat: 48.9977552, lng: 21.7090769 },
    { lat: 48.998859, lng: 21.7108096 },
    { lat: 48.9990586, lng: 21.7107374 },
    { lat: 48.9989816, lng: 21.7108902 },
    { lat: 48.9986621, lng: 21.7120665 },
    { lat: 48.9983161, lng: 21.7147707 },
    { lat: 48.998274, lng: 21.7163515 },
    { lat: 48.9983083, lng: 21.716707 },
    { lat: 48.998363, lng: 21.7167763 },
    { lat: 48.9983688, lng: 21.716829 },
    { lat: 48.9983977, lng: 21.7168494 },
    { lat: 48.9995381, lng: 21.7171365 },
    { lat: 49.0002335, lng: 21.717435 },
    { lat: 49.0021519, lng: 21.7178675 },
    { lat: 49.0037834, lng: 21.7184632 },
    { lat: 49.0048503, lng: 21.7187789 },
    { lat: 49.0060641, lng: 21.7189881 },
    { lat: 49.0060433, lng: 21.7192155 },
    { lat: 49.0053372, lng: 21.7207494 },
    { lat: 49.0061759, lng: 21.7232084 },
    { lat: 49.0064134, lng: 21.7242759 },
    { lat: 49.0067914, lng: 21.7243139 },
    { lat: 49.0072238, lng: 21.7259754 },
    { lat: 49.0073015, lng: 21.7264415 },
    { lat: 49.0077006, lng: 21.7273597 },
    { lat: 49.0079154, lng: 21.7280713 },
    { lat: 49.0084162, lng: 21.729304 },
    { lat: 49.0091012, lng: 21.731542 },
    { lat: 49.0093231, lng: 21.7327729 },
    { lat: 49.0093511, lng: 21.7333227 },
    { lat: 49.0098654, lng: 21.7338763 },
    { lat: 49.0100971, lng: 21.7343358 },
    { lat: 49.0102879, lng: 21.7350067 },
    { lat: 49.0106302, lng: 21.7356082 },
    { lat: 49.0108198, lng: 21.7364716 },
    { lat: 49.011635, lng: 21.7370928 },
    { lat: 49.0126388, lng: 21.7375278 },
    { lat: 49.0127144, lng: 21.7377475 },
    { lat: 49.013079, lng: 21.738348 },
    { lat: 49.013256, lng: 21.7385349 },
    { lat: 49.0143128, lng: 21.7389317 },
    { lat: 49.0147192, lng: 21.7399973 },
    { lat: 49.0149632, lng: 21.7401232 },
    { lat: 49.0153083, lng: 21.7401565 },
    { lat: 49.0162254, lng: 21.7409693 },
    { lat: 49.0168287, lng: 21.7423401 },
    { lat: 49.0169938, lng: 21.7429647 },
    { lat: 49.0172986, lng: 21.7437642 },
    { lat: 49.0184537, lng: 21.744564 },
    { lat: 49.0184954, lng: 21.7447421 },
    { lat: 49.0186649, lng: 21.745064 },
    { lat: 49.0197634, lng: 21.7466085 },
    { lat: 49.0200088, lng: 21.7474296 },
    { lat: 49.0203414, lng: 21.7482645 },
    { lat: 49.0211359, lng: 21.7495076 },
    { lat: 49.0214458, lng: 21.7494833 },
    { lat: 49.0218538, lng: 21.7494297 },
    { lat: 49.0223014, lng: 21.7498457 },
    { lat: 49.0226317, lng: 21.7500004 },
    { lat: 49.0238884, lng: 21.7511494 },
    { lat: 49.0242142, lng: 21.7512653 },
    { lat: 49.0247805, lng: 21.7518723 },
    { lat: 49.0254644, lng: 21.7522137 },
    { lat: 49.026587, lng: 21.752383 },
    { lat: 49.026739, lng: 21.752442 },
    { lat: 49.027054, lng: 21.752388 },
    { lat: 49.027439, lng: 21.752363 },
    { lat: 49.027619, lng: 21.752247 },
    { lat: 49.028093, lng: 21.751946 },
    { lat: 49.028374, lng: 21.751768 },
    { lat: 49.028755, lng: 21.75164 },
    { lat: 49.029073, lng: 21.751462 },
    { lat: 49.029795, lng: 21.751005 },
    { lat: 49.029957, lng: 21.750939 },
    { lat: 49.030117, lng: 21.75084 },
    { lat: 49.03066, lng: 21.750801 },
    { lat: 49.031133, lng: 21.750622 },
    { lat: 49.031507, lng: 21.750432 },
    { lat: 49.032154, lng: 21.749898 },
    { lat: 49.032686, lng: 21.749595 },
    { lat: 49.0328431, lng: 21.7492031 },
    { lat: 49.0330475, lng: 21.7489737 },
    { lat: 49.0330514, lng: 21.7484887 },
    { lat: 49.0331409, lng: 21.7482527 },
    { lat: 49.0331645, lng: 21.7475698 },
    { lat: 49.0335264, lng: 21.7467524 },
    { lat: 49.0337717, lng: 21.7459927 },
    { lat: 49.0340402, lng: 21.7463197 },
    { lat: 49.0346956, lng: 21.7452381 },
    { lat: 49.0347282, lng: 21.7449815 },
    { lat: 49.0346317, lng: 21.7445235 },
    { lat: 49.0343347, lng: 21.7438849 },
    { lat: 49.0343404, lng: 21.7434708 },
    { lat: 49.034015, lng: 21.7429053 },
    { lat: 49.0338651, lng: 21.741874 },
    { lat: 49.0341497, lng: 21.7414952 },
    { lat: 49.0346289, lng: 21.740605 },
    { lat: 49.0346391, lng: 21.7399665 },
    { lat: 49.0346007, lng: 21.7396211 },
    { lat: 49.0347004, lng: 21.7393575 },
    { lat: 49.033936, lng: 21.7386136 },
    { lat: 49.0338858, lng: 21.7383291 },
    { lat: 49.0339905, lng: 21.736724 },
    { lat: 49.0352977, lng: 21.7329149 },
    { lat: 49.0350415, lng: 21.731152 },
    { lat: 49.035201, lng: 21.7297041 },
    { lat: 49.0353189, lng: 21.7294843 },
    { lat: 49.0355127, lng: 21.7283193 },
    { lat: 49.0361594, lng: 21.7276742 },
    { lat: 49.0363571, lng: 21.7273691 },
    { lat: 49.0364067, lng: 21.7269949 },
    { lat: 49.0366106, lng: 21.7268784 },
    { lat: 49.0366546, lng: 21.725823 },
    { lat: 49.0382964, lng: 21.7252975 },
    { lat: 49.0388223, lng: 21.7247653 },
    { lat: 49.0393665, lng: 21.724717 },
    { lat: 49.0395534, lng: 21.72476 },
    { lat: 49.0398171, lng: 21.7246971 },
    { lat: 49.040303, lng: 21.7248206 },
    { lat: 49.0403806, lng: 21.7241424 },
    { lat: 49.041058, lng: 21.7236463 },
    { lat: 49.0409357, lng: 21.722861 },
    { lat: 49.0422191, lng: 21.7228079 },
    { lat: 49.04361, lng: 21.7224836 },
    { lat: 49.043288, lng: 21.7195716 },
    { lat: 49.0433913, lng: 21.7193956 },
    { lat: 49.0434912, lng: 21.7186274 },
    { lat: 49.0434343, lng: 21.7181823 },
    { lat: 49.0433154, lng: 21.7180287 },
    { lat: 49.0432336, lng: 21.7177031 },
    { lat: 49.0430561, lng: 21.7159832 },
    { lat: 49.0430732, lng: 21.7158648 },
    { lat: 49.0429998, lng: 21.7155194 },
    { lat: 49.0428451, lng: 21.7153737 },
    { lat: 49.0424736, lng: 21.7152346 },
    { lat: 49.0421056, lng: 21.7149788 },
    { lat: 49.0415502, lng: 21.7143164 },
    { lat: 49.0414399, lng: 21.713984 },
    { lat: 49.0413193, lng: 21.713255 },
    { lat: 49.0413418, lng: 21.7125027 },
    { lat: 49.0413233, lng: 21.7123565 },
    { lat: 49.0412011, lng: 21.7121611 },
    { lat: 49.0412334, lng: 21.711439 },
    { lat: 49.0404994, lng: 21.7109571 },
    { lat: 49.0404221, lng: 21.709875 },
    { lat: 49.0403094, lng: 21.7094886 },
    { lat: 49.0403738, lng: 21.7089102 },
    { lat: 49.0402052, lng: 21.7083702 },
    { lat: 49.0400071, lng: 21.7074897 },
    { lat: 49.0400179, lng: 21.7073822 },
    { lat: 49.0400706, lng: 21.7072996 },
  ],
  RuskáVoľa: [
    { lat: 49.091455, lng: 21.608386 },
    { lat: 49.091924, lng: 21.608127 },
    { lat: 49.092157, lng: 21.608153 },
    { lat: 49.093217, lng: 21.608054 },
    { lat: 49.092996, lng: 21.607796 },
    { lat: 49.092969, lng: 21.607382 },
    { lat: 49.092932, lng: 21.60717 },
    { lat: 49.093391, lng: 21.607044 },
    { lat: 49.09376, lng: 21.606541 },
    { lat: 49.094522, lng: 21.606203 },
    { lat: 49.094846, lng: 21.605937 },
    { lat: 49.096444, lng: 21.604279 },
    { lat: 49.09669, lng: 21.604122 },
    { lat: 49.09695, lng: 21.603698 },
    { lat: 49.097062, lng: 21.60339 },
    { lat: 49.097144, lng: 21.603018 },
    { lat: 49.09726, lng: 21.602555 },
    { lat: 49.097854, lng: 21.602631 },
    { lat: 49.098014, lng: 21.60266 },
    { lat: 49.098296, lng: 21.602484 },
    { lat: 49.098356, lng: 21.60239 },
    { lat: 49.09833, lng: 21.602103 },
    { lat: 49.098481, lng: 21.60185 },
    { lat: 49.098465, lng: 21.601685 },
    { lat: 49.098659, lng: 21.601155 },
    { lat: 49.098783, lng: 21.600732 },
    { lat: 49.099057, lng: 21.600214 },
    { lat: 49.099335, lng: 21.599723 },
    { lat: 49.099594, lng: 21.599327 },
    { lat: 49.100046, lng: 21.598738 },
    { lat: 49.100122, lng: 21.598886 },
    { lat: 49.100144, lng: 21.59886 },
    { lat: 49.100718, lng: 21.598326 },
    { lat: 49.100676, lng: 21.598005 },
    { lat: 49.100847, lng: 21.597629 },
    { lat: 49.101232, lng: 21.597033 },
    { lat: 49.101578, lng: 21.596787 },
    { lat: 49.101835, lng: 21.596675 },
    { lat: 49.102788, lng: 21.596473 },
    { lat: 49.102981, lng: 21.596391 },
    { lat: 49.102863, lng: 21.59575 },
    { lat: 49.102811, lng: 21.59502 },
    { lat: 49.102766, lng: 21.594904 },
    { lat: 49.102994, lng: 21.594694 },
    { lat: 49.103614, lng: 21.594052 },
    { lat: 49.103687, lng: 21.593803 },
    { lat: 49.103714, lng: 21.593722 },
    { lat: 49.103761, lng: 21.593582 },
    { lat: 49.104355, lng: 21.593144 },
    { lat: 49.104347, lng: 21.592352 },
    { lat: 49.105829, lng: 21.591326 },
    { lat: 49.104865, lng: 21.58896 },
    { lat: 49.105207, lng: 21.588577 },
    { lat: 49.105413, lng: 21.586767 },
    { lat: 49.106593, lng: 21.58616 },
    { lat: 49.10718, lng: 21.585985 },
    { lat: 49.107818, lng: 21.584139 },
    { lat: 49.108148, lng: 21.583715 },
    { lat: 49.108048, lng: 21.583087 },
    { lat: 49.108703, lng: 21.582716 },
    { lat: 49.108867, lng: 21.582516 },
    { lat: 49.10885, lng: 21.582257 },
    { lat: 49.109004, lng: 21.582024 },
    { lat: 49.108603, lng: 21.580931 },
    { lat: 49.108984, lng: 21.580172 },
    { lat: 49.109285, lng: 21.579166 },
    { lat: 49.109437, lng: 21.57927 },
    { lat: 49.109535, lng: 21.579234 },
    { lat: 49.109731, lng: 21.57912 },
    { lat: 49.109888, lng: 21.578897 },
    { lat: 49.109579, lng: 21.578253 },
    { lat: 49.109248, lng: 21.577801 },
    { lat: 49.10926, lng: 21.577015 },
    { lat: 49.110004, lng: 21.576052 },
    { lat: 49.110135, lng: 21.576282 },
    { lat: 49.110647, lng: 21.576685 },
    { lat: 49.110827, lng: 21.576946 },
    { lat: 49.111001, lng: 21.577112 },
    { lat: 49.111419, lng: 21.577422 },
    { lat: 49.111447, lng: 21.577289 },
    { lat: 49.11175, lng: 21.577489 },
    { lat: 49.112014, lng: 21.577471 },
    { lat: 49.112415, lng: 21.577503 },
    { lat: 49.112701, lng: 21.577479 },
    { lat: 49.112841, lng: 21.5773 },
    { lat: 49.113017, lng: 21.577196 },
    { lat: 49.113019, lng: 21.57711 },
    { lat: 49.113153, lng: 21.577222 },
    { lat: 49.11319, lng: 21.577194 },
    { lat: 49.113464, lng: 21.576418 },
    { lat: 49.113649, lng: 21.576034 },
    { lat: 49.113894, lng: 21.575686 },
    { lat: 49.114245, lng: 21.575255 },
    { lat: 49.114494, lng: 21.575119 },
    { lat: 49.114577, lng: 21.574985 },
    { lat: 49.11503, lng: 21.573638 },
    { lat: 49.115207, lng: 21.572678 },
    { lat: 49.115248, lng: 21.572708 },
    { lat: 49.115353, lng: 21.572508 },
    { lat: 49.115381, lng: 21.572454 },
    { lat: 49.115472, lng: 21.57234 },
    { lat: 49.115595, lng: 21.572327 },
    { lat: 49.115792, lng: 21.572221 },
    { lat: 49.116004, lng: 21.571932 },
    { lat: 49.116234, lng: 21.571785 },
    { lat: 49.116413, lng: 21.571707 },
    { lat: 49.116483, lng: 21.571543 },
    { lat: 49.116609, lng: 21.571429 },
    { lat: 49.116655, lng: 21.571303 },
    { lat: 49.116911, lng: 21.571206 },
    { lat: 49.117299, lng: 21.570955 },
    { lat: 49.117374, lng: 21.570777 },
    { lat: 49.117482, lng: 21.570868 },
    { lat: 49.117684, lng: 21.570755 },
    { lat: 49.118005, lng: 21.570788 },
    { lat: 49.118066, lng: 21.570688 },
    { lat: 49.118436, lng: 21.570777 },
    { lat: 49.119013, lng: 21.570747 },
    { lat: 49.119163, lng: 21.57082 },
    { lat: 49.119292, lng: 21.570886 },
    { lat: 49.119338, lng: 21.570572 },
    { lat: 49.119465, lng: 21.569948 },
    { lat: 49.119521, lng: 21.569554 },
    { lat: 49.119749, lng: 21.56815 },
    { lat: 49.120685, lng: 21.567402 },
    { lat: 49.123427, lng: 21.567779 },
    { lat: 49.123944, lng: 21.566597 },
    { lat: 49.123033, lng: 21.564815 },
    { lat: 49.12403, lng: 21.56447 },
    { lat: 49.124397, lng: 21.564371 },
    { lat: 49.125266, lng: 21.563768 },
    { lat: 49.125036, lng: 21.561916 },
    { lat: 49.125587, lng: 21.561101 },
    { lat: 49.126091, lng: 21.560298 },
    { lat: 49.126153, lng: 21.560205 },
    { lat: 49.12604, lng: 21.559881 },
    { lat: 49.125698, lng: 21.559737 },
    { lat: 49.125444, lng: 21.559445 },
    { lat: 49.125349, lng: 21.559174 },
    { lat: 49.125168, lng: 21.558911 },
    { lat: 49.124956, lng: 21.558649 },
    { lat: 49.1249, lng: 21.558667 },
    { lat: 49.124664, lng: 21.558505 },
    { lat: 49.12424, lng: 21.558014 },
    { lat: 49.124019, lng: 21.557823 },
    { lat: 49.123867, lng: 21.557918 },
    { lat: 49.123609, lng: 21.558019 },
    { lat: 49.123543, lng: 21.558024 },
    { lat: 49.123147, lng: 21.557934 },
    { lat: 49.123018, lng: 21.558186 },
    { lat: 49.122894, lng: 21.558413 },
    { lat: 49.122696, lng: 21.558667 },
    { lat: 49.122439, lng: 21.558672 },
    { lat: 49.122218, lng: 21.558712 },
    { lat: 49.121916, lng: 21.558649 },
    { lat: 49.121777, lng: 21.558183 },
    { lat: 49.121606, lng: 21.557797 },
    { lat: 49.121436, lng: 21.557409 },
    { lat: 49.121375, lng: 21.557219 },
    { lat: 49.121192, lng: 21.556524 },
    { lat: 49.12103, lng: 21.556455 },
    { lat: 49.121011, lng: 21.556449 },
    { lat: 49.120995, lng: 21.556444 },
    { lat: 49.118697, lng: 21.556439 },
    { lat: 49.118275, lng: 21.556328 },
    { lat: 49.117727, lng: 21.556261 },
    { lat: 49.117642, lng: 21.556439 },
    { lat: 49.117536, lng: 21.55651 },
    { lat: 49.117271, lng: 21.556687 },
    { lat: 49.11703, lng: 21.556955 },
    { lat: 49.11676, lng: 21.557323 },
    { lat: 49.116197, lng: 21.557543 },
    { lat: 49.115478, lng: 21.558234 },
    { lat: 49.115316, lng: 21.558445 },
    { lat: 49.115102, lng: 21.558583 },
    { lat: 49.114899, lng: 21.558903 },
    { lat: 49.114827, lng: 21.559174 },
    { lat: 49.114616, lng: 21.559498 },
    { lat: 49.114405, lng: 21.559933 },
    { lat: 49.114329, lng: 21.560141 },
    { lat: 49.11423, lng: 21.56055 },
    { lat: 49.114137, lng: 21.56089 },
    { lat: 49.114083, lng: 21.561293 },
    { lat: 49.114157, lng: 21.561638 },
    { lat: 49.113879, lng: 21.562434 },
    { lat: 49.113817, lng: 21.562925 },
    { lat: 49.113779, lng: 21.563308 },
    { lat: 49.113747, lng: 21.563961 },
    { lat: 49.113747, lng: 21.564712 },
    { lat: 49.113723, lng: 21.565103 },
    { lat: 49.113514, lng: 21.565777 },
    { lat: 49.113263, lng: 21.566163 },
    { lat: 49.113103, lng: 21.566232 },
    { lat: 49.112494, lng: 21.566357 },
    { lat: 49.112319, lng: 21.566517 },
    { lat: 49.111974, lng: 21.566732 },
    { lat: 49.111273, lng: 21.56751 },
    { lat: 49.111205, lng: 21.567568 },
    { lat: 49.111165, lng: 21.567608 },
    { lat: 49.111057, lng: 21.567837 },
    { lat: 49.110599, lng: 21.568607 },
    { lat: 49.110397, lng: 21.56886 },
    { lat: 49.11029, lng: 21.56908 },
    { lat: 49.109997, lng: 21.569576 },
    { lat: 49.109834, lng: 21.569716 },
    { lat: 49.109577, lng: 21.570086 },
    { lat: 49.109461, lng: 21.570173 },
    { lat: 49.109332, lng: 21.570155 },
    { lat: 49.10927, lng: 21.56987 },
    { lat: 49.109225, lng: 21.569594 },
    { lat: 49.109163, lng: 21.569279 },
    { lat: 49.108896, lng: 21.568807 },
    { lat: 49.108714, lng: 21.568535 },
    { lat: 49.108617, lng: 21.568286 },
    { lat: 49.108499, lng: 21.567929 },
    { lat: 49.108352, lng: 21.567722 },
    { lat: 49.108224, lng: 21.567599 },
    { lat: 49.108063, lng: 21.567277 },
    { lat: 49.108078, lng: 21.567145 },
    { lat: 49.107877, lng: 21.566836 },
    { lat: 49.108038, lng: 21.566642 },
    { lat: 49.107778, lng: 21.565967 },
    { lat: 49.107363, lng: 21.566134 },
    { lat: 49.107122, lng: 21.566252 },
    { lat: 49.106803, lng: 21.566452 },
    { lat: 49.1068, lng: 21.566434 },
    { lat: 49.106443, lng: 21.566697 },
    { lat: 49.106186, lng: 21.566829 },
    { lat: 49.105088, lng: 21.567571 },
    { lat: 49.104827, lng: 21.567706 },
    { lat: 49.104526, lng: 21.567905 },
    { lat: 49.10422, lng: 21.568129 },
    { lat: 49.104226, lng: 21.568149 },
    { lat: 49.104138, lng: 21.568215 },
    { lat: 49.103575, lng: 21.568538 },
    { lat: 49.102599, lng: 21.56898 },
    { lat: 49.102539, lng: 21.568943 },
    { lat: 49.102158, lng: 21.569029 },
    { lat: 49.101837, lng: 21.56898 },
    { lat: 49.101465, lng: 21.569087 },
    { lat: 49.101246, lng: 21.569018 },
    { lat: 49.101, lng: 21.569075 },
    { lat: 49.100691, lng: 21.569276 },
    { lat: 49.100425, lng: 21.569331 },
    { lat: 49.1004305, lng: 21.5694607 },
    { lat: 49.1006594, lng: 21.5698484 },
    { lat: 49.1008493, lng: 21.5704019 },
    { lat: 49.1008704, lng: 21.5707107 },
    { lat: 49.1001423, lng: 21.5711866 },
    { lat: 49.1001796, lng: 21.5716003 },
    { lat: 49.0995749, lng: 21.5720991 },
    { lat: 49.0990373, lng: 21.5730735 },
    { lat: 49.0987404, lng: 21.5738068 },
    { lat: 49.098709, lng: 21.5738878 },
    { lat: 49.0991194, lng: 21.5746479 },
    { lat: 49.0988517, lng: 21.5757728 },
    { lat: 49.0984274, lng: 21.5766109 },
    { lat: 49.0972679, lng: 21.5782817 },
    { lat: 49.0970691, lng: 21.5784551 },
    { lat: 49.0968559, lng: 21.5787572 },
    { lat: 49.0959708, lng: 21.580184 },
    { lat: 49.0955805, lng: 21.5807104 },
    { lat: 49.0924023, lng: 21.5833324 },
    { lat: 49.0918361, lng: 21.5835632 },
    { lat: 49.0908484, lng: 21.583684 },
    { lat: 49.090413, lng: 21.5838457 },
    { lat: 49.0895174, lng: 21.5845755 },
    { lat: 49.0890906, lng: 21.5851231 },
    { lat: 49.0883416, lng: 21.5858244 },
    { lat: 49.0874977, lng: 21.5862177 },
    { lat: 49.0868384, lng: 21.5863374 },
    { lat: 49.0862116, lng: 21.5862142 },
    { lat: 49.0861782, lng: 21.5865462 },
    { lat: 49.0858489, lng: 21.5866001 },
    { lat: 49.0848989, lng: 21.5876373 },
    { lat: 49.0846801, lng: 21.5877799 },
    { lat: 49.0842547, lng: 21.5885036 },
    { lat: 49.0839775, lng: 21.5891743 },
    { lat: 49.0835346, lng: 21.589831 },
    { lat: 49.0836399, lng: 21.5910323 },
    { lat: 49.0831471, lng: 21.5912341 },
    { lat: 49.0830807, lng: 21.5918363 },
    { lat: 49.0827944, lng: 21.5920644 },
    { lat: 49.0824463, lng: 21.5921693 },
    { lat: 49.0826823, lng: 21.5935377 },
    { lat: 49.0821621, lng: 21.5938027 },
    { lat: 49.0821101, lng: 21.5946911 },
    { lat: 49.082177, lng: 21.5961235 },
    { lat: 49.0822938, lng: 21.5963575 },
    { lat: 49.0825123, lng: 21.5971373 },
    { lat: 49.082543, lng: 21.5978765 },
    { lat: 49.0824791, lng: 21.598134 },
    { lat: 49.0833015, lng: 21.5996761 },
    { lat: 49.0832966, lng: 21.6003511 },
    { lat: 49.0832159, lng: 21.6003653 },
    { lat: 49.08321, lng: 21.601123 },
    { lat: 49.0832602, lng: 21.6016739 },
    { lat: 49.0831671, lng: 21.6018884 },
    { lat: 49.083115, lng: 21.6033762 },
    { lat: 49.083424, lng: 21.605031 },
    { lat: 49.083486, lng: 21.604957 },
    { lat: 49.08365, lng: 21.604891 },
    { lat: 49.083768, lng: 21.604938 },
    { lat: 49.083908, lng: 21.605057 },
    { lat: 49.083946, lng: 21.604957 },
    { lat: 49.083969, lng: 21.604771 },
    { lat: 49.084032, lng: 21.604704 },
    { lat: 49.084325, lng: 21.604729 },
    { lat: 49.084452, lng: 21.60458 },
    { lat: 49.084529, lng: 21.604728 },
    { lat: 49.084547, lng: 21.604741 },
    { lat: 49.084607, lng: 21.604636 },
    { lat: 49.084739, lng: 21.604575 },
    { lat: 49.085052, lng: 21.604688 },
    { lat: 49.085336, lng: 21.604612 },
    { lat: 49.085646, lng: 21.604715 },
    { lat: 49.085849, lng: 21.604911 },
    { lat: 49.085973, lng: 21.604999 },
    { lat: 49.086005, lng: 21.604962 },
    { lat: 49.086095, lng: 21.605105 },
    { lat: 49.086248, lng: 21.605127 },
    { lat: 49.08637, lng: 21.605261 },
    { lat: 49.086581, lng: 21.605087 },
    { lat: 49.086833, lng: 21.604903 },
    { lat: 49.087068, lng: 21.604788 },
    { lat: 49.087213, lng: 21.604601 },
    { lat: 49.087417, lng: 21.604389 },
    { lat: 49.08752, lng: 21.604376 },
    { lat: 49.087643, lng: 21.604228 },
    { lat: 49.088073, lng: 21.603877 },
    { lat: 49.088253, lng: 21.60358 },
    { lat: 49.08866, lng: 21.603669 },
    { lat: 49.089215, lng: 21.603984 },
    { lat: 49.089518, lng: 21.604214 },
    { lat: 49.089717, lng: 21.604539 },
    { lat: 49.089949, lng: 21.604803 },
    { lat: 49.090242, lng: 21.605115 },
    { lat: 49.090378, lng: 21.605507 },
    { lat: 49.0905, lng: 21.60597 },
    { lat: 49.090586, lng: 21.60642 },
    { lat: 49.090701, lng: 21.607289 },
    { lat: 49.091261, lng: 21.60729 },
    { lat: 49.091328, lng: 21.607278 },
    { lat: 49.091381, lng: 21.608346 },
    { lat: 49.091455, lng: 21.608386 },
  ],
  VranovnadTopľou: [
    { lat: 48.8791472, lng: 21.7220522 },
    { lat: 48.879848, lng: 21.7205829 },
    { lat: 48.8800035, lng: 21.7200075 },
    { lat: 48.8800045, lng: 21.7197895 },
    { lat: 48.8802967, lng: 21.7197832 },
    { lat: 48.8802214, lng: 21.7194891 },
    { lat: 48.8802717, lng: 21.7194749 },
    { lat: 48.8802359, lng: 21.7188948 },
    { lat: 48.880377, lng: 21.7186173 },
    { lat: 48.8809279, lng: 21.7179414 },
    { lat: 48.8819157, lng: 21.7169729 },
    { lat: 48.8826095, lng: 21.7161991 },
    { lat: 48.8832192, lng: 21.715324 },
    { lat: 48.8835083, lng: 21.7148172 },
    { lat: 48.8838936, lng: 21.714338 },
    { lat: 48.8851211, lng: 21.7125785 },
    { lat: 48.8854085, lng: 21.7118851 },
    { lat: 48.8856075, lng: 21.7111668 },
    { lat: 48.8859741, lng: 21.7104928 },
    { lat: 48.8861988, lng: 21.7096006 },
    { lat: 48.8859931, lng: 21.7079364 },
    { lat: 48.8859507, lng: 21.707149 },
    { lat: 48.8861277, lng: 21.7071201 },
    { lat: 48.8862883, lng: 21.7069486 },
    { lat: 48.8861326, lng: 21.7067591 },
    { lat: 48.8860613, lng: 21.706415 },
    { lat: 48.8861818, lng: 21.7057955 },
    { lat: 48.8861999, lng: 21.7047052 },
    { lat: 48.8864882, lng: 21.7040481 },
    { lat: 48.8866974, lng: 21.7037124 },
    { lat: 48.8872341, lng: 21.704013 },
    { lat: 48.8876421, lng: 21.704036 },
    { lat: 48.8880148, lng: 21.7039203 },
    { lat: 48.8880144, lng: 21.703698 },
    { lat: 48.8881487, lng: 21.7036332 },
    { lat: 48.8881465, lng: 21.7025869 },
    { lat: 48.8883746, lng: 21.7024516 },
    { lat: 48.8891165, lng: 21.70148 },
    { lat: 48.8895278, lng: 21.7012929 },
    { lat: 48.8899187, lng: 21.7009384 },
    { lat: 48.8905695, lng: 21.700041 },
    { lat: 48.8909679, lng: 21.6999162 },
    { lat: 48.8910805, lng: 21.7001684 },
    { lat: 48.8912985, lng: 21.7000327 },
    { lat: 48.8922994, lng: 21.7016131 },
    { lat: 48.8928222, lng: 21.7025567 },
    { lat: 48.8933584, lng: 21.7032932 },
    { lat: 48.8945394, lng: 21.7042884 },
    { lat: 48.8949962, lng: 21.7050803 },
    { lat: 48.8951167, lng: 21.7051705 },
    { lat: 48.8954473, lng: 21.7056223 },
    { lat: 48.8963611, lng: 21.7061746 },
    { lat: 48.897065, lng: 21.7058314 },
    { lat: 48.8971892, lng: 21.705714 },
    { lat: 48.8974135, lng: 21.705315 },
    { lat: 48.8980642, lng: 21.7049121 },
    { lat: 48.8987997, lng: 21.7045705 },
    { lat: 48.8988591, lng: 21.7044388 },
    { lat: 48.8992048, lng: 21.7042414 },
    { lat: 48.8994105, lng: 21.7035189 },
    { lat: 48.899653, lng: 21.7035865 },
    { lat: 48.899927, lng: 21.7032287 },
    { lat: 48.8999043, lng: 21.7030617 },
    { lat: 48.9000624, lng: 21.7027501 },
    { lat: 48.9000136, lng: 21.7025239 },
    { lat: 48.9001216, lng: 21.702391 },
    { lat: 48.9002612, lng: 21.702028 },
    { lat: 48.900224, lng: 21.7018966 },
    { lat: 48.9003678, lng: 21.7018104 },
    { lat: 48.9004806, lng: 21.7016035 },
    { lat: 48.9005575, lng: 21.7015787 },
    { lat: 48.9007514, lng: 21.701758 },
    { lat: 48.9008024, lng: 21.7019004 },
    { lat: 48.9010249, lng: 21.7019327 },
    { lat: 48.9011048, lng: 21.7018091 },
    { lat: 48.9011135, lng: 21.7015929 },
    { lat: 48.9013499, lng: 21.7016322 },
    { lat: 48.9014131, lng: 21.7014309 },
    { lat: 48.9015274, lng: 21.7012479 },
    { lat: 48.9016073, lng: 21.7012107 },
    { lat: 48.9017147, lng: 21.7012677 },
    { lat: 48.9021637, lng: 21.7011062 },
    { lat: 48.9022351, lng: 21.7009594 },
    { lat: 48.9022302, lng: 21.7007409 },
    { lat: 48.9023053, lng: 21.70064 },
    { lat: 48.9025857, lng: 21.7005321 },
    { lat: 48.902724, lng: 21.7003176 },
    { lat: 48.9028183, lng: 21.7003078 },
    { lat: 48.9029175, lng: 21.7001919 },
    { lat: 48.9048036, lng: 21.7059751 },
    { lat: 48.9048135, lng: 21.7060092 },
    { lat: 48.9048222, lng: 21.7060434 },
    { lat: 48.9053229, lng: 21.7057871 },
    { lat: 48.9067085, lng: 21.7047324 },
    { lat: 48.907157, lng: 21.704342 },
    { lat: 48.9073131, lng: 21.704126 },
    { lat: 48.9077592, lng: 21.7038129 },
    { lat: 48.9096995, lng: 21.7031666 },
    { lat: 48.910181, lng: 21.7027934 },
    { lat: 48.9105413, lng: 21.7024277 },
    { lat: 48.9110945, lng: 21.7017067 },
    { lat: 48.9119878, lng: 21.7007474 },
    { lat: 48.9125991, lng: 21.6999684 },
    { lat: 48.9128988, lng: 21.6995231 },
    { lat: 48.9139827, lng: 21.6975018 },
    { lat: 48.9143167, lng: 21.697113 },
    { lat: 48.9145305, lng: 21.6969495 },
    { lat: 48.914768, lng: 21.6964089 },
    { lat: 48.9177042, lng: 21.693446 },
    { lat: 48.9186705, lng: 21.6921103 },
    { lat: 48.9197647, lng: 21.6911336 },
    { lat: 48.9182112, lng: 21.6883131 },
    { lat: 48.9183532, lng: 21.6881147 },
    { lat: 48.9175363, lng: 21.6857789 },
    { lat: 48.916959, lng: 21.6838665 },
    { lat: 48.9169333, lng: 21.6832471 },
    { lat: 48.9173652, lng: 21.6833254 },
    { lat: 48.9174136, lng: 21.6823283 },
    { lat: 48.9172904, lng: 21.6806887 },
    { lat: 48.9177726, lng: 21.6783953 },
    { lat: 48.9181834, lng: 21.6759526 },
    { lat: 48.9182181, lng: 21.6751194 },
    { lat: 48.9181966, lng: 21.6739546 },
    { lat: 48.9179741, lng: 21.6722641 },
    { lat: 48.9182015, lng: 21.6718188 },
    { lat: 48.9183977, lng: 21.6710513 },
    { lat: 48.9184507, lng: 21.6699394 },
    { lat: 48.9180675, lng: 21.6682114 },
    { lat: 48.9176492, lng: 21.6671094 },
    { lat: 48.9176148, lng: 21.6671273 },
    { lat: 48.9176165, lng: 21.6670252 },
    { lat: 48.9173419, lng: 21.6671397 },
    { lat: 48.9166333, lng: 21.6671073 },
    { lat: 48.9160433, lng: 21.6642791 },
    { lat: 48.9155101, lng: 21.6623871 },
    { lat: 48.9154591, lng: 21.662205 },
    { lat: 48.9151501, lng: 21.6624312 },
    { lat: 48.915047, lng: 21.6622806 },
    { lat: 48.9149605, lng: 21.6623251 },
    { lat: 48.9143178, lng: 21.6613217 },
    { lat: 48.9135805, lng: 21.6597795 },
    { lat: 48.9132633, lng: 21.6601121 },
    { lat: 48.912908, lng: 21.6603297 },
    { lat: 48.9122553, lng: 21.6610654 },
    { lat: 48.9117864, lng: 21.6603337 },
    { lat: 48.9114306, lng: 21.660693 },
    { lat: 48.9110855, lng: 21.6609128 },
    { lat: 48.9096943, lng: 21.6623264 },
    { lat: 48.9092798, lng: 21.6616082 },
    { lat: 48.9070005, lng: 21.6637187 },
    { lat: 48.9071105, lng: 21.6643263 },
    { lat: 48.9060713, lng: 21.66463 },
    { lat: 48.905518, lng: 21.6647166 },
    { lat: 48.905368, lng: 21.6649492 },
    { lat: 48.9044507, lng: 21.6649413 },
    { lat: 48.9039404, lng: 21.6652494 },
    { lat: 48.903701, lng: 21.6655448 },
    { lat: 48.9029843, lng: 21.6656227 },
    { lat: 48.9025263, lng: 21.6657819 },
    { lat: 48.9008892, lng: 21.6659043 },
    { lat: 48.9004851, lng: 21.6642854 },
    { lat: 48.9000498, lng: 21.6632622 },
    { lat: 48.8999116, lng: 21.6633434 },
    { lat: 48.8989803, lng: 21.6634795 },
    { lat: 48.8984997, lng: 21.662197 },
    { lat: 48.8973486, lng: 21.6622263 },
    { lat: 48.8960614, lng: 21.6624695 },
    { lat: 48.8947765, lng: 21.6616477 },
    { lat: 48.8940474, lng: 21.6612705 },
    { lat: 48.891226, lng: 21.6581044 },
    { lat: 48.890087, lng: 21.656895 },
    { lat: 48.8899045, lng: 21.6568103 },
    { lat: 48.8897331, lng: 21.6565859 },
    { lat: 48.8900031, lng: 21.656423 },
    { lat: 48.8904846, lng: 21.6561332 },
    { lat: 48.8911069, lng: 21.6555181 },
    { lat: 48.8915322, lng: 21.6549471 },
    { lat: 48.8921846, lng: 21.6538278 },
    { lat: 48.8927207, lng: 21.6533128 },
    { lat: 48.8935593, lng: 21.6531656 },
    { lat: 48.8939632, lng: 21.6529625 },
    { lat: 48.8943111, lng: 21.6525952 },
    { lat: 48.8945912, lng: 21.6521063 },
    { lat: 48.8947806, lng: 21.6515398 },
    { lat: 48.8948411, lng: 21.6509603 },
    { lat: 48.8948268, lng: 21.6504378 },
    { lat: 48.8944597, lng: 21.6482523 },
    { lat: 48.894486, lng: 21.6475702 },
    { lat: 48.894592, lng: 21.6469982 },
    { lat: 48.8947011, lng: 21.6467568 },
    { lat: 48.8946125, lng: 21.6465589 },
    { lat: 48.8944222, lng: 21.6459402 },
    { lat: 48.8943262, lng: 21.6457575 },
    { lat: 48.8941556, lng: 21.6456578 },
    { lat: 48.893623, lng: 21.6456385 },
    { lat: 48.8932055, lng: 21.6457159 },
    { lat: 48.8925041, lng: 21.6454488 },
    { lat: 48.8918185, lng: 21.645767 },
    { lat: 48.8914159, lng: 21.6458571 },
    { lat: 48.8911229, lng: 21.6460397 },
    { lat: 48.8906348, lng: 21.6459225 },
    { lat: 48.890286, lng: 21.6460478 },
    { lat: 48.8900366, lng: 21.6462201 },
    { lat: 48.8895535, lng: 21.6463746 },
    { lat: 48.889025, lng: 21.6467112 },
    { lat: 48.887971, lng: 21.6475731 },
    { lat: 48.8856867, lng: 21.6419379 },
    { lat: 48.8855886, lng: 21.6419269 },
    { lat: 48.8851548, lng: 21.6421081 },
    { lat: 48.8846772, lng: 21.642376 },
    { lat: 48.8829772, lng: 21.6426202 },
    { lat: 48.8825879, lng: 21.6413879 },
    { lat: 48.8821971, lng: 21.64044 },
    { lat: 48.8814712, lng: 21.638914 },
    { lat: 48.8810738, lng: 21.6382125 },
    { lat: 48.8779678, lng: 21.6401835 },
    { lat: 48.8783848, lng: 21.6414411 },
    { lat: 48.8757967, lng: 21.643066 },
    { lat: 48.8757087, lng: 21.6431394 },
    { lat: 48.8757103, lng: 21.6435264 },
    { lat: 48.8746833, lng: 21.6442278 },
    { lat: 48.874086, lng: 21.6447654 },
    { lat: 48.8740623, lng: 21.6448023 },
    { lat: 48.8742464, lng: 21.6454044 },
    { lat: 48.8741684, lng: 21.6460419 },
    { lat: 48.87384, lng: 21.6464976 },
    { lat: 48.8738022, lng: 21.6464346 },
    { lat: 48.8730851, lng: 21.6468329 },
    { lat: 48.8730328, lng: 21.6466028 },
    { lat: 48.8718592, lng: 21.6470052 },
    { lat: 48.8716805, lng: 21.6454604 },
    { lat: 48.8716584, lng: 21.6448946 },
    { lat: 48.8715566, lng: 21.6444664 },
    { lat: 48.8714169, lng: 21.6445063 },
    { lat: 48.8713433, lng: 21.6439531 },
    { lat: 48.8692939, lng: 21.6446709 },
    { lat: 48.8672504, lng: 21.6452726 },
    { lat: 48.8660616, lng: 21.6458625 },
    { lat: 48.8639806, lng: 21.6471028 },
    { lat: 48.8630634, lng: 21.6474672 },
    { lat: 48.8629154, lng: 21.6471953 },
    { lat: 48.8626436, lng: 21.6474626 },
    { lat: 48.8610809, lng: 21.6484054 },
    { lat: 48.8608263, lng: 21.6486822 },
    { lat: 48.8605067, lng: 21.64888 },
    { lat: 48.8598182, lng: 21.6489658 },
    { lat: 48.8597554, lng: 21.6488483 },
    { lat: 48.8597457, lng: 21.6489447 },
    { lat: 48.8598491, lng: 21.6491472 },
    { lat: 48.8596355, lng: 21.6495247 },
    { lat: 48.8593431, lng: 21.6503031 },
    { lat: 48.8592733, lng: 21.650349 },
    { lat: 48.8591621, lng: 21.6501863 },
    { lat: 48.8590245, lng: 21.6501381 },
    { lat: 48.8589116, lng: 21.650371 },
    { lat: 48.8589391, lng: 21.6507109 },
    { lat: 48.8590714, lng: 21.6511099 },
    { lat: 48.8588572, lng: 21.6516654 },
    { lat: 48.8586774, lng: 21.6515542 },
    { lat: 48.8582283, lng: 21.6517125 },
    { lat: 48.8580622, lng: 21.6519716 },
    { lat: 48.857905, lng: 21.652352 },
    { lat: 48.8577307, lng: 21.6525148 },
    { lat: 48.8575113, lng: 21.6532564 },
    { lat: 48.8575984, lng: 21.653684 },
    { lat: 48.8575037, lng: 21.6537225 },
    { lat: 48.8574776, lng: 21.6540421 },
    { lat: 48.8574084, lng: 21.6541422 },
    { lat: 48.8572285, lng: 21.6542278 },
    { lat: 48.8572884, lng: 21.6543216 },
    { lat: 48.8572621, lng: 21.6544287 },
    { lat: 48.8571298, lng: 21.654532 },
    { lat: 48.8568671, lng: 21.6550012 },
    { lat: 48.856873, lng: 21.6555036 },
    { lat: 48.856982, lng: 21.6556271 },
    { lat: 48.8569677, lng: 21.6558796 },
    { lat: 48.8570396, lng: 21.6560848 },
    { lat: 48.8570016, lng: 21.6562547 },
    { lat: 48.8568429, lng: 21.6563733 },
    { lat: 48.8568063, lng: 21.6565643 },
    { lat: 48.8568506, lng: 21.6568638 },
    { lat: 48.85682, lng: 21.6570197 },
    { lat: 48.8568486, lng: 21.657159 },
    { lat: 48.8569869, lng: 21.6572797 },
    { lat: 48.8569791, lng: 21.6573641 },
    { lat: 48.8567992, lng: 21.6573268 },
    { lat: 48.8566936, lng: 21.6575457 },
    { lat: 48.8566909, lng: 21.6577316 },
    { lat: 48.8565944, lng: 21.6579021 },
    { lat: 48.8566373, lng: 21.658073 },
    { lat: 48.8566958, lng: 21.6580307 },
    { lat: 48.8567139, lng: 21.6583633 },
    { lat: 48.8569411, lng: 21.6586127 },
    { lat: 48.8567624, lng: 21.6589476 },
    { lat: 48.8566904, lng: 21.6588253 },
    { lat: 48.8564889, lng: 21.6590256 },
    { lat: 48.8563206, lng: 21.6589146 },
    { lat: 48.856115, lng: 21.6592162 },
    { lat: 48.8561149, lng: 21.6595828 },
    { lat: 48.8562257, lng: 21.6595984 },
    { lat: 48.8562521, lng: 21.659745 },
    { lat: 48.8562036, lng: 21.6599485 },
    { lat: 48.8562506, lng: 21.6601045 },
    { lat: 48.8564144, lng: 21.6601307 },
    { lat: 48.85636, lng: 21.6604003 },
    { lat: 48.8565142, lng: 21.6604417 },
    { lat: 48.8565242, lng: 21.6607662 },
    { lat: 48.8565173, lng: 21.6608488 },
    { lat: 48.8564368, lng: 21.6608192 },
    { lat: 48.856361, lng: 21.6609297 },
    { lat: 48.8563579, lng: 21.6612714 },
    { lat: 48.8565056, lng: 21.6613286 },
    { lat: 48.8564543, lng: 21.6614276 },
    { lat: 48.8564395, lng: 21.6616597 },
    { lat: 48.8563488, lng: 21.6616818 },
    { lat: 48.8563409, lng: 21.6618413 },
    { lat: 48.8563981, lng: 21.6619235 },
    { lat: 48.8563573, lng: 21.6622322 },
    { lat: 48.8562854, lng: 21.6624518 },
    { lat: 48.8562527, lng: 21.6624024 },
    { lat: 48.8561402, lng: 21.662607 },
    { lat: 48.8561811, lng: 21.662706 },
    { lat: 48.8560859, lng: 21.6628452 },
    { lat: 48.8561567, lng: 21.6629687 },
    { lat: 48.8562321, lng: 21.6629664 },
    { lat: 48.8561777, lng: 21.663246 },
    { lat: 48.8560608, lng: 21.6632033 },
    { lat: 48.8559617, lng: 21.6632892 },
    { lat: 48.8560151, lng: 21.6636714 },
    { lat: 48.8560867, lng: 21.663659 },
    { lat: 48.8561486, lng: 21.6639081 },
    { lat: 48.8560891, lng: 21.6640769 },
    { lat: 48.8561654, lng: 21.6641947 },
    { lat: 48.8560533, lng: 21.6644929 },
    { lat: 48.8559913, lng: 21.6645371 },
    { lat: 48.8561577, lng: 21.6647336 },
    { lat: 48.8560614, lng: 21.6652473 },
    { lat: 48.8560782, lng: 21.6654049 },
    { lat: 48.8562025, lng: 21.6654239 },
    { lat: 48.8563083, lng: 21.6655098 },
    { lat: 48.8563273, lng: 21.6655915 },
    { lat: 48.8562437, lng: 21.6657839 },
    { lat: 48.8561941, lng: 21.6663608 },
    { lat: 48.856258, lng: 21.6666813 },
    { lat: 48.8561003, lng: 21.6666733 },
    { lat: 48.8559762, lng: 21.6668275 },
    { lat: 48.8559756, lng: 21.666969 },
    { lat: 48.8558621, lng: 21.6670587 },
    { lat: 48.855971, lng: 21.6672916 },
    { lat: 48.8559097, lng: 21.6674405 },
    { lat: 48.8558146, lng: 21.6674197 },
    { lat: 48.8556976, lng: 21.6674616 },
    { lat: 48.855624, lng: 21.6675699 },
    { lat: 48.8554972, lng: 21.6675796 },
    { lat: 48.8555147, lng: 21.6676857 },
    { lat: 48.8556441, lng: 21.6677297 },
    { lat: 48.8556554, lng: 21.6680138 },
    { lat: 48.8557665, lng: 21.6679625 },
    { lat: 48.855789, lng: 21.6680444 },
    { lat: 48.8557283, lng: 21.6682132 },
    { lat: 48.8555432, lng: 21.6681105 },
    { lat: 48.8553616, lng: 21.6682187 },
    { lat: 48.8551327, lng: 21.6682373 },
    { lat: 48.8550733, lng: 21.6683768 },
    { lat: 48.855169, lng: 21.6684969 },
    { lat: 48.8552314, lng: 21.6687263 },
    { lat: 48.8552048, lng: 21.6688226 },
    { lat: 48.8551328, lng: 21.668741 },
    { lat: 48.8549956, lng: 21.6687417 },
    { lat: 48.854897, lng: 21.6688808 },
    { lat: 48.8545271, lng: 21.6686374 },
    { lat: 48.8544186, lng: 21.6686285 },
    { lat: 48.8544025, lng: 21.6685724 },
    { lat: 48.854439, lng: 21.6681731 },
    { lat: 48.8545883, lng: 21.6679594 },
    { lat: 48.8547242, lng: 21.6678666 },
    { lat: 48.854798, lng: 21.6677202 },
    { lat: 48.8548094, lng: 21.6674837 },
    { lat: 48.8550264, lng: 21.6668531 },
    { lat: 48.8554983, lng: 21.6657617 },
    { lat: 48.8554922, lng: 21.6654176 },
    { lat: 48.8508651, lng: 21.6667428 },
    { lat: 48.8497479, lng: 21.6671164 },
    { lat: 48.8501506, lng: 21.6687613 },
    { lat: 48.8502207, lng: 21.6692742 },
    { lat: 48.8502269, lng: 21.6697446 },
    { lat: 48.8501888, lng: 21.6702085 },
    { lat: 48.8500284, lng: 21.6710527 },
    { lat: 48.8495619, lng: 21.6726827 },
    { lat: 48.8494981, lng: 21.6740977 },
    { lat: 48.8492086, lng: 21.6742876 },
    { lat: 48.8491956, lng: 21.6743492 },
    { lat: 48.8491407, lng: 21.6734463 },
    { lat: 48.8490748, lng: 21.6734704 },
    { lat: 48.8487256, lng: 21.6732433 },
    { lat: 48.8490635, lng: 21.6706994 },
    { lat: 48.8492107, lng: 21.6703115 },
    { lat: 48.8493036, lng: 21.6702632 },
    { lat: 48.8491885, lng: 21.6691337 },
    { lat: 48.849114, lng: 21.6691609 },
    { lat: 48.8488743, lng: 21.6687482 },
    { lat: 48.842374, lng: 21.670409 },
    { lat: 48.8423226, lng: 21.6703826 },
    { lat: 48.8422171, lng: 21.6706021 },
    { lat: 48.8421574, lng: 21.6714687 },
    { lat: 48.8422361, lng: 21.6727833 },
    { lat: 48.8423306, lng: 21.6731527 },
    { lat: 48.8422726, lng: 21.6733768 },
    { lat: 48.8423217, lng: 21.6737798 },
    { lat: 48.8421803, lng: 21.6741633 },
    { lat: 48.8422111, lng: 21.6747568 },
    { lat: 48.8420203, lng: 21.6759328 },
    { lat: 48.8418538, lng: 21.6762675 },
    { lat: 48.8417571, lng: 21.676805 },
    { lat: 48.8412261, lng: 21.6784651 },
    { lat: 48.8408974, lng: 21.6792035 },
    { lat: 48.8405518, lng: 21.6806437 },
    { lat: 48.8402326, lng: 21.6814855 },
    { lat: 48.8402008, lng: 21.6822611 },
    { lat: 48.8404665, lng: 21.6827203 },
    { lat: 48.8404673, lng: 21.6829577 },
    { lat: 48.8405733, lng: 21.6831368 },
    { lat: 48.8408862, lng: 21.6840935 },
    { lat: 48.8410776, lng: 21.6841391 },
    { lat: 48.8411843, lng: 21.6842989 },
    { lat: 48.8414253, lng: 21.6843867 },
    { lat: 48.8418708, lng: 21.6848016 },
    { lat: 48.8421022, lng: 21.6846675 },
    { lat: 48.8433152, lng: 21.688496 },
    { lat: 48.8434715, lng: 21.6891125 },
    { lat: 48.8434382, lng: 21.6891306 },
    { lat: 48.843685, lng: 21.6898939 },
    { lat: 48.8426627, lng: 21.6906572 },
    { lat: 48.8429701, lng: 21.691825 },
    { lat: 48.8434868, lng: 21.6916311 },
    { lat: 48.8436584, lng: 21.6921955 },
    { lat: 48.8436029, lng: 21.6924715 },
    { lat: 48.8440357, lng: 21.6923299 },
    { lat: 48.8447216, lng: 21.6923588 },
    { lat: 48.8457297, lng: 21.6919858 },
    { lat: 48.8462955, lng: 21.691862 },
    { lat: 48.8466264, lng: 21.6929463 },
    { lat: 48.8469626, lng: 21.6944729 },
    { lat: 48.8470258, lng: 21.6950666 },
    { lat: 48.847375, lng: 21.6960826 },
    { lat: 48.84763, lng: 21.697317 },
    { lat: 48.8482304, lng: 21.6995174 },
    { lat: 48.8480683, lng: 21.7030167 },
    { lat: 48.8480055, lng: 21.7030188 },
    { lat: 48.8479795, lng: 21.703308 },
    { lat: 48.847789, lng: 21.7033762 },
    { lat: 48.8445434, lng: 21.7032967 },
    { lat: 48.842502, lng: 21.7043192 },
    { lat: 48.84256, lng: 21.7044263 },
    { lat: 48.8436693, lng: 21.7093579 },
    { lat: 48.8375793, lng: 21.7122829 },
    { lat: 48.8378901, lng: 21.7137944 },
    { lat: 48.8374923, lng: 21.7146503 },
    { lat: 48.8374087, lng: 21.715478 },
    { lat: 48.8360974, lng: 21.7154509 },
    { lat: 48.8360291, lng: 21.7159705 },
    { lat: 48.8348723, lng: 21.7161646 },
    { lat: 48.8349941, lng: 21.7174795 },
    { lat: 48.8348247, lng: 21.7174978 },
    { lat: 48.8346836, lng: 21.7199545 },
    { lat: 48.8347692, lng: 21.7199992 },
    { lat: 48.8347789, lng: 21.7200622 },
    { lat: 48.834777, lng: 21.7201248 },
    { lat: 48.8356252, lng: 21.7199476 },
    { lat: 48.8367144, lng: 21.7199995 },
    { lat: 48.8369831, lng: 21.7199444 },
    { lat: 48.8383005, lng: 21.7194465 },
    { lat: 48.8398771, lng: 21.7191256 },
    { lat: 48.841099, lng: 21.7186242 },
    { lat: 48.8413619, lng: 21.7184055 },
    { lat: 48.8413482, lng: 21.7183546 },
    { lat: 48.8430219, lng: 21.7171282 },
    { lat: 48.8433099, lng: 21.7170805 },
    { lat: 48.8437019, lng: 21.7171395 },
    { lat: 48.8439242, lng: 21.7170862 },
    { lat: 48.8451886, lng: 21.7163257 },
    { lat: 48.8455393, lng: 21.7162237 },
    { lat: 48.8456172, lng: 21.7162811 },
    { lat: 48.8457088, lng: 21.7160943 },
    { lat: 48.8460581, lng: 21.7160267 },
    { lat: 48.8466903, lng: 21.7156041 },
    { lat: 48.8473465, lng: 21.7149067 },
    { lat: 48.8475915, lng: 21.7145126 },
    { lat: 48.8477428, lng: 21.7149591 },
    { lat: 48.847939, lng: 21.7151799 },
    { lat: 48.8480413, lng: 21.7150932 },
    { lat: 48.8484211, lng: 21.7155853 },
    { lat: 48.8485262, lng: 21.7156431 },
    { lat: 48.8487263, lng: 21.7156318 },
    { lat: 48.8490716, lng: 21.7153769 },
    { lat: 48.849679, lng: 21.7147047 },
    { lat: 48.8512304, lng: 21.7135773 },
    { lat: 48.8513175, lng: 21.7137566 },
    { lat: 48.8516774, lng: 21.7140449 },
    { lat: 48.8518462, lng: 21.7142988 },
    { lat: 48.8522708, lng: 21.7147356 },
    { lat: 48.8527982, lng: 21.7146406 },
    { lat: 48.8530709, lng: 21.7148674 },
    { lat: 48.8531413, lng: 21.714768 },
    { lat: 48.8531016, lng: 21.7144229 },
    { lat: 48.8534146, lng: 21.714559 },
    { lat: 48.8534797, lng: 21.7146311 },
    { lat: 48.8536369, lng: 21.7152101 },
    { lat: 48.8536004, lng: 21.7157807 },
    { lat: 48.8540439, lng: 21.7161178 },
    { lat: 48.8540615, lng: 21.7162271 },
    { lat: 48.8542262, lng: 21.7163309 },
    { lat: 48.8545494, lng: 21.7168465 },
    { lat: 48.854882, lng: 21.7168292 },
    { lat: 48.8556542, lng: 21.7163422 },
    { lat: 48.8561459, lng: 21.7153812 },
    { lat: 48.8566348, lng: 21.7156158 },
    { lat: 48.8567855, lng: 21.7150285 },
    { lat: 48.8579522, lng: 21.7156954 },
    { lat: 48.8580118, lng: 21.7153626 },
    { lat: 48.8583011, lng: 21.7156156 },
    { lat: 48.8584277, lng: 21.7158107 },
    { lat: 48.8584948, lng: 21.7162561 },
    { lat: 48.8586234, lng: 21.7166029 },
    { lat: 48.8589073, lng: 21.7166665 },
    { lat: 48.8592973, lng: 21.7166068 },
    { lat: 48.859523, lng: 21.7171359 },
    { lat: 48.8597975, lng: 21.7171772 },
    { lat: 48.8601447, lng: 21.7178772 },
    { lat: 48.8604173, lng: 21.7182209 },
    { lat: 48.8606799, lng: 21.7176938 },
    { lat: 48.8609399, lng: 21.7181137 },
    { lat: 48.8610563, lng: 21.7181841 },
    { lat: 48.8611264, lng: 21.7180626 },
    { lat: 48.8615628, lng: 21.7186542 },
    { lat: 48.8616736, lng: 21.719338 },
    { lat: 48.861602, lng: 21.7195679 },
    { lat: 48.8619812, lng: 21.7202759 },
    { lat: 48.8625783, lng: 21.718717 },
    { lat: 48.8626942, lng: 21.7188028 },
    { lat: 48.8627951, lng: 21.7185847 },
    { lat: 48.8629315, lng: 21.7187103 },
    { lat: 48.863066, lng: 21.7189432 },
    { lat: 48.863376, lng: 21.7191828 },
    { lat: 48.8640945, lng: 21.7192489 },
    { lat: 48.8641833, lng: 21.7190341 },
    { lat: 48.8644193, lng: 21.7187737 },
    { lat: 48.8646314, lng: 21.7186727 },
    { lat: 48.8648034, lng: 21.7187936 },
    { lat: 48.8651513, lng: 21.7183859 },
    { lat: 48.8652769, lng: 21.7183656 },
    { lat: 48.8653311, lng: 21.7182069 },
    { lat: 48.8656601, lng: 21.7177653 },
    { lat: 48.8658048, lng: 21.7174526 },
    { lat: 48.8661444, lng: 21.7177069 },
    { lat: 48.8660022, lng: 21.7181873 },
    { lat: 48.8665278, lng: 21.718533 },
    { lat: 48.866619, lng: 21.7182865 },
    { lat: 48.8668099, lng: 21.7184774 },
    { lat: 48.8667497, lng: 21.7195467 },
    { lat: 48.8668948, lng: 21.7200959 },
    { lat: 48.8678081, lng: 21.7205013 },
    { lat: 48.8691086, lng: 21.7207175 },
    { lat: 48.8697352, lng: 21.72075 },
    { lat: 48.8699938, lng: 21.7210414 },
    { lat: 48.8706895, lng: 21.7215164 },
    { lat: 48.8708104, lng: 21.7217527 },
    { lat: 48.8716896, lng: 21.7217308 },
    { lat: 48.8725394, lng: 21.7219547 },
    { lat: 48.8725248, lng: 21.7218684 },
    { lat: 48.8730146, lng: 21.721805 },
    { lat: 48.8730445, lng: 21.7222084 },
    { lat: 48.8730794, lng: 21.7222088 },
    { lat: 48.8731559, lng: 21.7227484 },
    { lat: 48.8729355, lng: 21.722758 },
    { lat: 48.8728195, lng: 21.7239277 },
    { lat: 48.8744888, lng: 21.7238325 },
    { lat: 48.8745216, lng: 21.7233748 },
    { lat: 48.8747383, lng: 21.7234451 },
    { lat: 48.8749954, lng: 21.7232127 },
    { lat: 48.875244, lng: 21.7229201 },
    { lat: 48.8753836, lng: 21.7225845 },
    { lat: 48.8763569, lng: 21.7230557 },
    { lat: 48.8771604, lng: 21.7229026 },
    { lat: 48.8772894, lng: 21.7220851 },
    { lat: 48.8779608, lng: 21.7224523 },
    { lat: 48.8787896, lng: 21.7227748 },
    { lat: 48.879054, lng: 21.7219801 },
    { lat: 48.8791472, lng: 21.7220522 },
  ],
  Tovarné: [
    { lat: 48.9403337, lng: 21.7710497 },
    { lat: 48.9400187, lng: 21.771125 },
    { lat: 48.9398932, lng: 21.7714007 },
    { lat: 48.93977, lng: 21.771251 },
    { lat: 48.9394691, lng: 21.771104 },
    { lat: 48.939151, lng: 21.7711998 },
    { lat: 48.9389535, lng: 21.7713886 },
    { lat: 48.9385946, lng: 21.7714315 },
    { lat: 48.9384165, lng: 21.7712004 },
    { lat: 48.9382229, lng: 21.771288 },
    { lat: 48.9380867, lng: 21.7712361 },
    { lat: 48.9381082, lng: 21.7709074 },
    { lat: 48.9380599, lng: 21.7708631 },
    { lat: 48.9379217, lng: 21.7710404 },
    { lat: 48.9379125, lng: 21.7711662 },
    { lat: 48.9377798, lng: 21.7713803 },
    { lat: 48.937586, lng: 21.771327 },
    { lat: 48.9374483, lng: 21.771433 },
    { lat: 48.9368608, lng: 21.7712514 },
    { lat: 48.9367033, lng: 21.7711254 },
    { lat: 48.9367827, lng: 21.7708937 },
    { lat: 48.9368028, lng: 21.770671 },
    { lat: 48.9367134, lng: 21.7704427 },
    { lat: 48.9366004, lng: 21.7703347 },
    { lat: 48.9361758, lng: 21.770529 },
    { lat: 48.9361294, lng: 21.7706481 },
    { lat: 48.9353524, lng: 21.7700197 },
    { lat: 48.9351089, lng: 21.7700463 },
    { lat: 48.9351214, lng: 21.7700026 },
    { lat: 48.9343696, lng: 21.7692224 },
    { lat: 48.9336927, lng: 21.7680455 },
    { lat: 48.9319767, lng: 21.7657188 },
    { lat: 48.9316962, lng: 21.7654535 },
    { lat: 48.9307627, lng: 21.7650794 },
    { lat: 48.930557, lng: 21.764868 },
    { lat: 48.9303937, lng: 21.7648232 },
    { lat: 48.93, lng: 21.7645666 },
    { lat: 48.9291385, lng: 21.7638544 },
    { lat: 48.9291368, lng: 21.7634877 },
    { lat: 48.929023, lng: 21.7628457 },
    { lat: 48.9288291, lng: 21.7621895 },
    { lat: 48.9287194, lng: 21.7621679 },
    { lat: 48.9285135, lng: 21.762628 },
    { lat: 48.9281658, lng: 21.7627185 },
    { lat: 48.9283524, lng: 21.7623659 },
    { lat: 48.9279688, lng: 21.762025 },
    { lat: 48.9279526, lng: 21.7618861 },
    { lat: 48.9275663, lng: 21.7616098 },
    { lat: 48.9273265, lng: 21.7615199 },
    { lat: 48.9276365, lng: 21.7607798 },
    { lat: 48.9275195, lng: 21.7604363 },
    { lat: 48.926476, lng: 21.7575623 },
    { lat: 48.925477, lng: 21.7552652 },
    { lat: 48.9241136, lng: 21.7512747 },
    { lat: 48.9238393, lng: 21.750558 },
    { lat: 48.9236564, lng: 21.7503267 },
    { lat: 48.9231509, lng: 21.7486973 },
    { lat: 48.9218697, lng: 21.7453514 },
    { lat: 48.920366, lng: 21.7411783 },
    { lat: 48.9202837, lng: 21.7409662 },
    { lat: 48.9192379, lng: 21.7409823 },
    { lat: 48.9184329, lng: 21.7412491 },
    { lat: 48.9175907, lng: 21.7417746 },
    { lat: 48.9171111, lng: 21.7422672 },
    { lat: 48.9166208, lng: 21.7421899 },
    { lat: 48.9155028, lng: 21.7416978 },
    { lat: 48.915206, lng: 21.7416627 },
    { lat: 48.9146859, lng: 21.7416899 },
    { lat: 48.9144579, lng: 21.7418745 },
    { lat: 48.9142037, lng: 21.7419143 },
    { lat: 48.9137724, lng: 21.742116 },
    { lat: 48.9120785, lng: 21.7417039 },
    { lat: 48.9109248, lng: 21.7416419 },
    { lat: 48.9100698, lng: 21.7414321 },
    { lat: 48.9090896, lng: 21.7413333 },
    { lat: 48.9085394, lng: 21.7415844 },
    { lat: 48.9081167, lng: 21.7418935 },
    { lat: 48.9076382, lng: 21.7425065 },
    { lat: 48.9074175, lng: 21.7431344 },
    { lat: 48.9072491, lng: 21.7441689 },
    { lat: 48.9070394, lng: 21.7450003 },
    { lat: 48.9067192, lng: 21.7458124 },
    { lat: 48.9064549, lng: 21.746709 },
    { lat: 48.9061892, lng: 21.7471254 },
    { lat: 48.9058045, lng: 21.7472952 },
    { lat: 48.9056899, lng: 21.7472571 },
    { lat: 48.9049435, lng: 21.7463944 },
    { lat: 48.9048945, lng: 21.7466284 },
    { lat: 48.9048505, lng: 21.7467906 },
    { lat: 48.9051012, lng: 21.7472473 },
    { lat: 48.9055058, lng: 21.7476603 },
    { lat: 48.9062135, lng: 21.748166 },
    { lat: 48.9067625, lng: 21.748464 },
    { lat: 48.9069084, lng: 21.7483221 },
    { lat: 48.9073165, lng: 21.7486977 },
    { lat: 48.9079976, lng: 21.7498173 },
    { lat: 48.9081896, lng: 21.7504346 },
    { lat: 48.9082293, lng: 21.7507927 },
    { lat: 48.9081887, lng: 21.7509944 },
    { lat: 48.9081977, lng: 21.7512549 },
    { lat: 48.9084323, lng: 21.751105 },
    { lat: 48.9087826, lng: 21.7516301 },
    { lat: 48.9087008, lng: 21.7520657 },
    { lat: 48.9087285, lng: 21.7523905 },
    { lat: 48.9086474, lng: 21.7526823 },
    { lat: 48.9086634, lng: 21.7528601 },
    { lat: 48.909015, lng: 21.7532271 },
    { lat: 48.9091773, lng: 21.753278 },
    { lat: 48.9092857, lng: 21.7536326 },
    { lat: 48.9092874, lng: 21.7541221 },
    { lat: 48.9092481, lng: 21.7542511 },
    { lat: 48.9088341, lng: 21.7543361 },
    { lat: 48.9087167, lng: 21.7547041 },
    { lat: 48.9087472, lng: 21.7549626 },
    { lat: 48.9087211, lng: 21.7554634 },
    { lat: 48.9086706, lng: 21.7555956 },
    { lat: 48.908483, lng: 21.7556506 },
    { lat: 48.9083599, lng: 21.756103 },
    { lat: 48.9081821, lng: 21.7562723 },
    { lat: 48.9080581, lng: 21.7567255 },
    { lat: 48.9078376, lng: 21.7570686 },
    { lat: 48.9075771, lng: 21.7574278 },
    { lat: 48.9060738, lng: 21.7589426 },
    { lat: 48.905114, lng: 21.7596169 },
    { lat: 48.9040698, lng: 21.7599507 },
    { lat: 48.9036862, lng: 21.7599955 },
    { lat: 48.9035563, lng: 21.7601447 },
    { lat: 48.9031824, lng: 21.7613657 },
    { lat: 48.9023387, lng: 21.7628116 },
    { lat: 48.901812, lng: 21.763444 },
    { lat: 48.9007489, lng: 21.7643854 },
    { lat: 48.899024, lng: 21.7654746 },
    { lat: 48.8986691, lng: 21.765588 },
    { lat: 48.8999135, lng: 21.7697761 },
    { lat: 48.9005688, lng: 21.7711148 },
    { lat: 48.9007011, lng: 21.7710531 },
    { lat: 48.9008102, lng: 21.7713136 },
    { lat: 48.9015017, lng: 21.7707414 },
    { lat: 48.9016222, lng: 21.7707083 },
    { lat: 48.9017011, lng: 21.7723781 },
    { lat: 48.9018027, lng: 21.7728629 },
    { lat: 48.9018817, lng: 21.773677 },
    { lat: 48.9021482, lng: 21.7746116 },
    { lat: 48.9025035, lng: 21.7762372 },
    { lat: 48.903362, lng: 21.779199 },
    { lat: 48.903483, lng: 21.779137 },
    { lat: 48.904686, lng: 21.778534 },
    { lat: 48.904403, lng: 21.777365 },
    { lat: 48.90444, lng: 21.777336 },
    { lat: 48.904493, lng: 21.777295 },
    { lat: 48.90453, lng: 21.777266 },
    { lat: 48.904549, lng: 21.777251 },
    { lat: 48.904558, lng: 21.777244 },
    { lat: 48.904613, lng: 21.777198 },
    { lat: 48.904626, lng: 21.777189 },
    { lat: 48.905635, lng: 21.776455 },
    { lat: 48.905699, lng: 21.776426 },
    { lat: 48.907881, lng: 21.775377 },
    { lat: 48.908678, lng: 21.777503 },
    { lat: 48.908704, lng: 21.777573 },
    { lat: 48.908739, lng: 21.777668 },
    { lat: 48.908761, lng: 21.777738 },
    { lat: 48.910875, lng: 21.77646 },
    { lat: 48.910939, lng: 21.777441 },
    { lat: 48.911577, lng: 21.780355 },
    { lat: 48.912337, lng: 21.780397 },
    { lat: 48.913529, lng: 21.779949 },
    { lat: 48.914198, lng: 21.779924 },
    { lat: 48.915616, lng: 21.780302 },
    { lat: 48.916237, lng: 21.780224 },
    { lat: 48.916591, lng: 21.780188 },
    { lat: 48.917528, lng: 21.780119 },
    { lat: 48.917757, lng: 21.780112 },
    { lat: 48.91855, lng: 21.780083 },
    { lat: 48.919144, lng: 21.780342 },
    { lat: 48.920022, lng: 21.780266 },
    { lat: 48.920992, lng: 21.780149 },
    { lat: 48.921929, lng: 21.780014 },
    { lat: 48.92283, lng: 21.779892 },
    { lat: 48.92378, lng: 21.779735 },
    { lat: 48.92467, lng: 21.77914 },
    { lat: 48.924704, lng: 21.779116 },
    { lat: 48.925385, lng: 21.776467 },
    { lat: 48.927546, lng: 21.775539 },
    { lat: 48.929274, lng: 21.776019 },
    { lat: 48.929842, lng: 21.776656 },
    { lat: 48.930113, lng: 21.776902 },
    { lat: 48.93136, lng: 21.777662 },
    { lat: 48.931876, lng: 21.778257 },
    { lat: 48.933141, lng: 21.779479 },
    { lat: 48.933318, lng: 21.77965 },
    { lat: 48.933887, lng: 21.779943 },
    { lat: 48.936111, lng: 21.780411 },
    { lat: 48.936706, lng: 21.7806 },
    { lat: 48.937436, lng: 21.780833 },
    { lat: 48.938519, lng: 21.781316 },
    { lat: 48.938869, lng: 21.781474 },
    { lat: 48.939555, lng: 21.78221 },
    { lat: 48.940785, lng: 21.782392 },
    { lat: 48.9409104, lng: 21.7813879 },
    { lat: 48.9411568, lng: 21.7811376 },
    { lat: 48.9411174, lng: 21.7808755 },
    { lat: 48.9407895, lng: 21.7808123 },
    { lat: 48.9405273, lng: 21.7808919 },
    { lat: 48.9403749, lng: 21.7806169 },
    { lat: 48.9402983, lng: 21.7799273 },
    { lat: 48.9407121, lng: 21.7788495 },
    { lat: 48.9409074, lng: 21.7787024 },
    { lat: 48.9409298, lng: 21.7785343 },
    { lat: 48.941357, lng: 21.7782215 },
    { lat: 48.9415025, lng: 21.7778004 },
    { lat: 48.9414879, lng: 21.7772441 },
    { lat: 48.9413729, lng: 21.7768528 },
    { lat: 48.9417863, lng: 21.7759349 },
    { lat: 48.9420541, lng: 21.7749463 },
    { lat: 48.9420187, lng: 21.7747563 },
    { lat: 48.9420578, lng: 21.7739297 },
    { lat: 48.9417705, lng: 21.7734057 },
    { lat: 48.9417289, lng: 21.7729337 },
    { lat: 48.9413936, lng: 21.7725901 },
    { lat: 48.9411817, lng: 21.7720618 },
    { lat: 48.9410846, lng: 21.7720105 },
    { lat: 48.9409095, lng: 21.7714639 },
    { lat: 48.9406935, lng: 21.7713818 },
    { lat: 48.9406987, lng: 21.7711954 },
    { lat: 48.9406514, lng: 21.771145 },
    { lat: 48.9403452, lng: 21.7711002 },
    { lat: 48.9403337, lng: 21.7710497 },
  ],
  Rudlov: [
    { lat: 48.9455706, lng: 21.5017134 },
    { lat: 48.9454573, lng: 21.5013694 },
    { lat: 48.9441676, lng: 21.4985381 },
    { lat: 48.9438052, lng: 21.497552 },
    { lat: 48.9420327, lng: 21.4952611 },
    { lat: 48.9416835, lng: 21.4943901 },
    { lat: 48.9414272, lng: 21.49396 },
    { lat: 48.9410584, lng: 21.4931122 },
    { lat: 48.9401431, lng: 21.493837 },
    { lat: 48.9395848, lng: 21.4942541 },
    { lat: 48.9388259, lng: 21.494043 },
    { lat: 48.9382912, lng: 21.4944291 },
    { lat: 48.9380316, lng: 21.4947498 },
    { lat: 48.937649, lng: 21.4957303 },
    { lat: 48.9373962, lng: 21.49613 },
    { lat: 48.9373179, lng: 21.4964672 },
    { lat: 48.9370443, lng: 21.4970884 },
    { lat: 48.9366685, lng: 21.497514 },
    { lat: 48.9360888, lng: 21.4984055 },
    { lat: 48.9358514, lng: 21.4989566 },
    { lat: 48.9352566, lng: 21.4994436 },
    { lat: 48.9344059, lng: 21.4997968 },
    { lat: 48.9342327, lng: 21.500145 },
    { lat: 48.933906, lng: 21.5002875 },
    { lat: 48.9335405, lng: 21.5006903 },
    { lat: 48.9331776, lng: 21.5009422 },
    { lat: 48.9325484, lng: 21.5015223 },
    { lat: 48.9315051, lng: 21.5019079 },
    { lat: 48.9310163, lng: 21.5023599 },
    { lat: 48.9306076, lng: 21.5025858 },
    { lat: 48.929974, lng: 21.5030548 },
    { lat: 48.9289888, lng: 21.5034591 },
    { lat: 48.9285434, lng: 21.5035486 },
    { lat: 48.9283477, lng: 21.5036656 },
    { lat: 48.9278831, lng: 21.503596 },
    { lat: 48.9274222, lng: 21.5036848 },
    { lat: 48.9271626, lng: 21.5035373 },
    { lat: 48.9268661, lng: 21.5038717 },
    { lat: 48.9265665, lng: 21.504333 },
    { lat: 48.926279, lng: 21.5046279 },
    { lat: 48.9258587, lng: 21.5046792 },
    { lat: 48.9255366, lng: 21.5048697 },
    { lat: 48.9247983, lng: 21.5048976 },
    { lat: 48.9245214, lng: 21.5052883 },
    { lat: 48.9239234, lng: 21.5057392 },
    { lat: 48.9238516, lng: 21.5059418 },
    { lat: 48.923587, lng: 21.506366 },
    { lat: 48.9228253, lng: 21.5071955 },
    { lat: 48.9226023, lng: 21.5075422 },
    { lat: 48.9223512, lng: 21.50868 },
    { lat: 48.9220103, lng: 21.5091082 },
    { lat: 48.9218356, lng: 21.509457 },
    { lat: 48.9215558, lng: 21.5097459 },
    { lat: 48.9207967, lng: 21.5108968 },
    { lat: 48.9204958, lng: 21.5111602 },
    { lat: 48.9202132, lng: 21.5121773 },
    { lat: 48.9200044, lng: 21.5124289 },
    { lat: 48.9196139, lng: 21.5134095 },
    { lat: 48.9194119, lng: 21.5137011 },
    { lat: 48.9190568, lng: 21.5137155 },
    { lat: 48.9182648, lng: 21.5141929 },
    { lat: 48.9179966, lng: 21.5144181 },
    { lat: 48.9165438, lng: 21.5160214 },
    { lat: 48.9155007, lng: 21.5173588 },
    { lat: 48.9155858, lng: 21.5180211 },
    { lat: 48.9156906, lng: 21.5182242 },
    { lat: 48.9157794, lng: 21.518609 },
    { lat: 48.9159347, lng: 21.5190131 },
    { lat: 48.9161431, lng: 21.5192924 },
    { lat: 48.9165506, lng: 21.5200891 },
    { lat: 48.9167668, lng: 21.520369 },
    { lat: 48.9173982, lng: 21.5208924 },
    { lat: 48.9176088, lng: 21.5214582 },
    { lat: 48.9176651, lng: 21.5220848 },
    { lat: 48.9179601, lng: 21.5234364 },
    { lat: 48.9180981, lng: 21.5236216 },
    { lat: 48.9185299, lng: 21.5246762 },
    { lat: 48.9186197, lng: 21.5253046 },
    { lat: 48.9186197, lng: 21.525546 },
    { lat: 48.9180716, lng: 21.5272791 },
    { lat: 48.9176632, lng: 21.528065 },
    { lat: 48.9175084, lng: 21.5286432 },
    { lat: 48.9171678, lng: 21.5295999 },
    { lat: 48.9170742, lng: 21.5323645 },
    { lat: 48.9167482, lng: 21.5334454 },
    { lat: 48.9166673, lng: 21.5340825 },
    { lat: 48.9166317, lng: 21.5348568 },
    { lat: 48.9164113, lng: 21.5355741 },
    { lat: 48.9162713, lng: 21.5371033 },
    { lat: 48.9165019, lng: 21.5382859 },
    { lat: 48.9170278, lng: 21.5398975 },
    { lat: 48.9169901, lng: 21.5404298 },
    { lat: 48.917052, lng: 21.5409498 },
    { lat: 48.9173391, lng: 21.5417475 },
    { lat: 48.9175963, lng: 21.5436042 },
    { lat: 48.9176167, lng: 21.5443259 },
    { lat: 48.9171253, lng: 21.5482758 },
    { lat: 48.9178517, lng: 21.5492935 },
    { lat: 48.9172504, lng: 21.5505936 },
    { lat: 48.9170684, lng: 21.5504423 },
    { lat: 48.9167311, lng: 21.5518426 },
    { lat: 48.9170274, lng: 21.5529341 },
    { lat: 48.9174849, lng: 21.5535977 },
    { lat: 48.9174923, lng: 21.5539782 },
    { lat: 48.9171356, lng: 21.5551722 },
    { lat: 48.9162414, lng: 21.5567824 },
    { lat: 48.9161425, lng: 21.5571019 },
    { lat: 48.9160839, lng: 21.5575017 },
    { lat: 48.9156205, lng: 21.5584257 },
    { lat: 48.9155993, lng: 21.5587086 },
    { lat: 48.9153102, lng: 21.5598706 },
    { lat: 48.9147159, lng: 21.5609376 },
    { lat: 48.9145581, lng: 21.561127 },
    { lat: 48.9140691, lng: 21.5613858 },
    { lat: 48.9138186, lng: 21.561641 },
    { lat: 48.913692, lng: 21.5620613 },
    { lat: 48.9132024, lng: 21.5626517 },
    { lat: 48.9127718, lng: 21.5627401 },
    { lat: 48.9122939, lng: 21.5627149 },
    { lat: 48.9119478, lng: 21.5629262 },
    { lat: 48.9116347, lng: 21.5632337 },
    { lat: 48.9110717, lng: 21.5632594 },
    { lat: 48.9106424, lng: 21.5633634 },
    { lat: 48.9106025, lng: 21.5635351 },
    { lat: 48.9105419, lng: 21.5635873 },
    { lat: 48.9105213, lng: 21.5638941 },
    { lat: 48.9104637, lng: 21.5639229 },
    { lat: 48.9104433, lng: 21.5642379 },
    { lat: 48.9102587, lng: 21.5643543 },
    { lat: 48.9102608, lng: 21.5645123 },
    { lat: 48.9100398, lng: 21.5646117 },
    { lat: 48.9100364, lng: 21.5660487 },
    { lat: 48.9098369, lng: 21.5673654 },
    { lat: 48.9098157, lng: 21.5678536 },
    { lat: 48.9096417, lng: 21.5685398 },
    { lat: 48.9094638, lng: 21.5688227 },
    { lat: 48.9093954, lng: 21.5690639 },
    { lat: 48.9094914, lng: 21.570688 },
    { lat: 48.9096432, lng: 21.5712408 },
    { lat: 48.9097295, lng: 21.5713086 },
    { lat: 48.9097122, lng: 21.5714975 },
    { lat: 48.9087339, lng: 21.5717542 },
    { lat: 48.9088195, lng: 21.5724457 },
    { lat: 48.9088145, lng: 21.5733853 },
    { lat: 48.9088681, lng: 21.5736565 },
    { lat: 48.9087531, lng: 21.5736822 },
    { lat: 48.9089556, lng: 21.5749249 },
    { lat: 48.9093835, lng: 21.5748605 },
    { lat: 48.9094756, lng: 21.5755366 },
    { lat: 48.9098104, lng: 21.5766045 },
    { lat: 48.9098762, lng: 21.577145 },
    { lat: 48.9101454, lng: 21.5772779 },
    { lat: 48.9104835, lng: 21.5772954 },
    { lat: 48.9105731, lng: 21.5774814 },
    { lat: 48.9108781, lng: 21.5775293 },
    { lat: 48.910915, lng: 21.5777601 },
    { lat: 48.9111207, lng: 21.5778739 },
    { lat: 48.9112173, lng: 21.577863 },
    { lat: 48.9115288, lng: 21.5783451 },
    { lat: 48.9117023, lng: 21.5783543 },
    { lat: 48.9118734, lng: 21.5781895 },
    { lat: 48.9120841, lng: 21.5781612 },
    { lat: 48.9124085, lng: 21.5785413 },
    { lat: 48.9124852, lng: 21.5788297 },
    { lat: 48.9128702, lng: 21.5795213 },
    { lat: 48.9129391, lng: 21.5799279 },
    { lat: 48.9131351, lng: 21.5803409 },
    { lat: 48.913385, lng: 21.5805434 },
    { lat: 48.9135235, lng: 21.5808353 },
    { lat: 48.9136991, lng: 21.5810339 },
    { lat: 48.9145254, lng: 21.5818593 },
    { lat: 48.9147254, lng: 21.5820041 },
    { lat: 48.9150973, lng: 21.5821104 },
    { lat: 48.9151862, lng: 21.5821941 },
    { lat: 48.9153833, lng: 21.5822047 },
    { lat: 48.9156264, lng: 21.5820434 },
    { lat: 48.9162336, lng: 21.5818698 },
    { lat: 48.9166659, lng: 21.5816399 },
    { lat: 48.9167443, lng: 21.5815018 },
    { lat: 48.9168482, lng: 21.5815151 },
    { lat: 48.9169113, lng: 21.5815976 },
    { lat: 48.917065, lng: 21.5815898 },
    { lat: 48.9174712, lng: 21.5813426 },
    { lat: 48.9175764, lng: 21.5814035 },
    { lat: 48.9178291, lng: 21.5813925 },
    { lat: 48.9180203, lng: 21.5815451 },
    { lat: 48.9182146, lng: 21.5815788 },
    { lat: 48.9182376, lng: 21.5813916 },
    { lat: 48.9184092, lng: 21.5812536 },
    { lat: 48.9184604, lng: 21.581315 },
    { lat: 48.9184363, lng: 21.5816167 },
    { lat: 48.9187711, lng: 21.5815573 },
    { lat: 48.9190942, lng: 21.5816881 },
    { lat: 48.9193558, lng: 21.5816677 },
    { lat: 48.9194534, lng: 21.5818213 },
    { lat: 48.919624, lng: 21.5816469 },
    { lat: 48.9200806, lng: 21.581796 },
    { lat: 48.9202272, lng: 21.5816534 },
    { lat: 48.9203961, lng: 21.5819771 },
    { lat: 48.9207541, lng: 21.5819884 },
    { lat: 48.9210576, lng: 21.581889 },
    { lat: 48.9211405, lng: 21.5820393 },
    { lat: 48.9210508, lng: 21.5821363 },
    { lat: 48.9210834, lng: 21.5822562 },
    { lat: 48.9212311, lng: 21.5822277 },
    { lat: 48.9213591, lng: 21.5822914 },
    { lat: 48.921459, lng: 21.5824828 },
    { lat: 48.9218352, lng: 21.5825827 },
    { lat: 48.9219588, lng: 21.5826943 },
    { lat: 48.9219918, lng: 21.5828299 },
    { lat: 48.9221046, lng: 21.5827987 },
    { lat: 48.9222371, lng: 21.5825869 },
    { lat: 48.9224822, lng: 21.5827443 },
    { lat: 48.9226496, lng: 21.5826201 },
    { lat: 48.9229931, lng: 21.5827121 },
    { lat: 48.9230879, lng: 21.5829232 },
    { lat: 48.9231669, lng: 21.5828909 },
    { lat: 48.9232588, lng: 21.5829147 },
    { lat: 48.9232754, lng: 21.5829848 },
    { lat: 48.9233364, lng: 21.5829558 },
    { lat: 48.9233839, lng: 21.5833224 },
    { lat: 48.9234404, lng: 21.5834568 },
    { lat: 48.9239384, lng: 21.5838892 },
    { lat: 48.9242347, lng: 21.5839778 },
    { lat: 48.9248069, lng: 21.5839624 },
    { lat: 48.9248903, lng: 21.5840433 },
    { lat: 48.9250551, lng: 21.5846338 },
    { lat: 48.9251243, lng: 21.5845316 },
    { lat: 48.9270263, lng: 21.5843892 },
    { lat: 48.9277597, lng: 21.5841423 },
    { lat: 48.9296746, lng: 21.5830154 },
    { lat: 48.9307598, lng: 21.5821544 },
    { lat: 48.9325246, lng: 21.5810119 },
    { lat: 48.9333286, lng: 21.5803461 },
    { lat: 48.9338668, lng: 21.5800019 },
    { lat: 48.9341915, lng: 21.5798682 },
    { lat: 48.934278, lng: 21.5798247 },
    { lat: 48.9344571, lng: 21.5796285 },
    { lat: 48.9361378, lng: 21.5786151 },
    { lat: 48.9378693, lng: 21.5776911 },
    { lat: 48.9375143, lng: 21.5760599 },
    { lat: 48.9373601, lng: 21.5748975 },
    { lat: 48.9373106, lng: 21.5741752 },
    { lat: 48.9373083, lng: 21.5739303 },
    { lat: 48.9374747, lng: 21.5736855 },
    { lat: 48.9380496, lng: 21.5732006 },
    { lat: 48.9379728, lng: 21.5723113 },
    { lat: 48.9378156, lng: 21.5715598 },
    { lat: 48.9376322, lng: 21.5697952 },
    { lat: 48.9375353, lng: 21.5693477 },
    { lat: 48.9374817, lng: 21.568119 },
    { lat: 48.9394592, lng: 21.568025 },
    { lat: 48.9393097, lng: 21.5678286 },
    { lat: 48.9394319, lng: 21.5673708 },
    { lat: 48.9393518, lng: 21.5670471 },
    { lat: 48.9394229, lng: 21.5663278 },
    { lat: 48.939305, lng: 21.5660584 },
    { lat: 48.9393064, lng: 21.5658658 },
    { lat: 48.9393674, lng: 21.5656786 },
    { lat: 48.9392483, lng: 21.5653574 },
    { lat: 48.9391489, lng: 21.5653044 },
    { lat: 48.9391739, lng: 21.5650755 },
    { lat: 48.9389243, lng: 21.5646837 },
    { lat: 48.9389758, lng: 21.564631 },
    { lat: 48.9389647, lng: 21.5644363 },
    { lat: 48.9387095, lng: 21.5641239 },
    { lat: 48.9388547, lng: 21.5636103 },
    { lat: 48.9387533, lng: 21.5632386 },
    { lat: 48.9388218, lng: 21.5628014 },
    { lat: 48.9386227, lng: 21.5620402 },
    { lat: 48.9386466, lng: 21.5617273 },
    { lat: 48.9384052, lng: 21.5615844 },
    { lat: 48.9383694, lng: 21.5615102 },
    { lat: 48.9382844, lng: 21.5610855 },
    { lat: 48.9383676, lng: 21.561011 },
    { lat: 48.9383643, lng: 21.5608924 },
    { lat: 48.9382571, lng: 21.5606768 },
    { lat: 48.9382525, lng: 21.5603979 },
    { lat: 48.9380546, lng: 21.5600844 },
    { lat: 48.9381032, lng: 21.5599695 },
    { lat: 48.9380976, lng: 21.5594088 },
    { lat: 48.9380179, lng: 21.5592582 },
    { lat: 48.9381306, lng: 21.5589074 },
    { lat: 48.9378478, lng: 21.5585671 },
    { lat: 48.9378013, lng: 21.5586417 },
    { lat: 48.9376615, lng: 21.5586236 },
    { lat: 48.9375817, lng: 21.5584262 },
    { lat: 48.9376711, lng: 21.5582866 },
    { lat: 48.9375347, lng: 21.5579569 },
    { lat: 48.9374356, lng: 21.557993 },
    { lat: 48.9374032, lng: 21.5579235 },
    { lat: 48.9374887, lng: 21.5576879 },
    { lat: 48.9373824, lng: 21.5575022 },
    { lat: 48.9374476, lng: 21.5573072 },
    { lat: 48.9373825, lng: 21.5571483 },
    { lat: 48.9373688, lng: 21.5566621 },
    { lat: 48.9372585, lng: 21.5565801 },
    { lat: 48.9371447, lng: 21.5563796 },
    { lat: 48.9374108, lng: 21.5556401 },
    { lat: 48.9374597, lng: 21.5552937 },
    { lat: 48.9376119, lng: 21.5547467 },
    { lat: 48.9377401, lng: 21.5545866 },
    { lat: 48.9378614, lng: 21.5541804 },
    { lat: 48.9380668, lng: 21.5539216 },
    { lat: 48.9380734, lng: 21.553763 },
    { lat: 48.9383097, lng: 21.5535211 },
    { lat: 48.9384832, lng: 21.5532446 },
    { lat: 48.9388666, lng: 21.552923 },
    { lat: 48.9392408, lng: 21.5523468 },
    { lat: 48.9392606, lng: 21.5519282 },
    { lat: 48.9393387, lng: 21.5516705 },
    { lat: 48.9393248, lng: 21.5513371 },
    { lat: 48.9394309, lng: 21.5507873 },
    { lat: 48.9395721, lng: 21.5504936 },
    { lat: 48.9395536, lng: 21.5502704 },
    { lat: 48.939611, lng: 21.549917 },
    { lat: 48.9397568, lng: 21.5496623 },
    { lat: 48.9399552, lng: 21.5489988 },
    { lat: 48.94011, lng: 21.548745 },
    { lat: 48.9401829, lng: 21.5483365 },
    { lat: 48.9401742, lng: 21.5481459 },
    { lat: 48.9401131, lng: 21.5479767 },
    { lat: 48.9401469, lng: 21.5476216 },
    { lat: 48.9400367, lng: 21.5472992 },
    { lat: 48.9400765, lng: 21.5463982 },
    { lat: 48.9399609, lng: 21.54621 },
    { lat: 48.9399257, lng: 21.5459152 },
    { lat: 48.9399493, lng: 21.5457577 },
    { lat: 48.939878, lng: 21.5455486 },
    { lat: 48.9397767, lng: 21.5445925 },
    { lat: 48.9396126, lng: 21.5438398 },
    { lat: 48.939511, lng: 21.5435895 },
    { lat: 48.9396013, lng: 21.5426069 },
    { lat: 48.9394928, lng: 21.5420299 },
    { lat: 48.9391688, lng: 21.540997 },
    { lat: 48.9390678, lng: 21.5405355 },
    { lat: 48.9390227, lng: 21.5402056 },
    { lat: 48.9390167, lng: 21.5394787 },
    { lat: 48.9389225, lng: 21.5388593 },
    { lat: 48.9380472, lng: 21.5381062 },
    { lat: 48.9375528, lng: 21.5367724 },
    { lat: 48.9376314, lng: 21.5365683 },
    { lat: 48.9378696, lng: 21.5364067 },
    { lat: 48.9379777, lng: 21.5360858 },
    { lat: 48.9380636, lng: 21.53598 },
    { lat: 48.9381897, lng: 21.5359886 },
    { lat: 48.9383064, lng: 21.5358134 },
    { lat: 48.9384378, lng: 21.5357666 },
    { lat: 48.9386008, lng: 21.5356009 },
    { lat: 48.9386968, lng: 21.5352944 },
    { lat: 48.9391442, lng: 21.5344932 },
    { lat: 48.9391679, lng: 21.5343317 },
    { lat: 48.9392388, lng: 21.5343399 },
    { lat: 48.9393425, lng: 21.5342218 },
    { lat: 48.9393609, lng: 21.5340785 },
    { lat: 48.9394778, lng: 21.5339213 },
    { lat: 48.9395045, lng: 21.5334722 },
    { lat: 48.9395585, lng: 21.533391 },
    { lat: 48.939684, lng: 21.5324956 },
    { lat: 48.9400289, lng: 21.5322046 },
    { lat: 48.9402526, lng: 21.5316051 },
    { lat: 48.940222, lng: 21.5313577 },
    { lat: 48.9404971, lng: 21.5310226 },
    { lat: 48.9405632, lng: 21.5308515 },
    { lat: 48.9406084, lng: 21.5304258 },
    { lat: 48.9406826, lng: 21.530382 },
    { lat: 48.9407416, lng: 21.5300438 },
    { lat: 48.9410066, lng: 21.5299857 },
    { lat: 48.9411468, lng: 21.5298498 },
    { lat: 48.9415242, lng: 21.5287589 },
    { lat: 48.9415743, lng: 21.5284997 },
    { lat: 48.9416642, lng: 21.5285754 },
    { lat: 48.9417754, lng: 21.5283748 },
    { lat: 48.9419044, lng: 21.5283177 },
    { lat: 48.9421614, lng: 21.5277436 },
    { lat: 48.942376, lng: 21.5264622 },
    { lat: 48.9423449, lng: 21.525335 },
    { lat: 48.9422877, lng: 21.5248251 },
    { lat: 48.9424113, lng: 21.5245772 },
    { lat: 48.9424626, lng: 21.5242074 },
    { lat: 48.9423869, lng: 21.5240767 },
    { lat: 48.942256, lng: 21.5240226 },
    { lat: 48.9422167, lng: 21.5238698 },
    { lat: 48.9422611, lng: 21.523373 },
    { lat: 48.942395, lng: 21.5231337 },
    { lat: 48.9422893, lng: 21.5227004 },
    { lat: 48.9421765, lng: 21.5224569 },
    { lat: 48.9424661, lng: 21.5208541 },
    { lat: 48.9426024, lng: 21.5208212 },
    { lat: 48.942675, lng: 21.5207216 },
    { lat: 48.9428761, lng: 21.5200419 },
    { lat: 48.9429516, lng: 21.5199267 },
    { lat: 48.9430092, lng: 21.5195729 },
    { lat: 48.9429253, lng: 21.5192355 },
    { lat: 48.943204, lng: 21.5180049 },
    { lat: 48.9431658, lng: 21.5162271 },
    { lat: 48.9432587, lng: 21.515027 },
    { lat: 48.9432518, lng: 21.5141147 },
    { lat: 48.9433068, lng: 21.5134833 },
    { lat: 48.9433781, lng: 21.5132964 },
    { lat: 48.9434315, lng: 21.5129185 },
    { lat: 48.9433662, lng: 21.5127646 },
    { lat: 48.9433884, lng: 21.5113705 },
    { lat: 48.943296, lng: 21.5108984 },
    { lat: 48.943404, lng: 21.5103801 },
    { lat: 48.943407, lng: 21.5098574 },
    { lat: 48.9434875, lng: 21.5092508 },
    { lat: 48.9434279, lng: 21.5087407 },
    { lat: 48.9436883, lng: 21.5073742 },
    { lat: 48.9438206, lng: 21.506889 },
    { lat: 48.9438703, lng: 21.5063846 },
    { lat: 48.9438219, lng: 21.5054783 },
    { lat: 48.9439272, lng: 21.5034222 },
    { lat: 48.9444016, lng: 21.5008131 },
    { lat: 48.9450068, lng: 21.5008028 },
    { lat: 48.9455706, lng: 21.5017134 },
  ],
  NižnýKručov: [
    { lat: 48.9198041, lng: 21.6558607 },
    { lat: 48.9195554, lng: 21.6554463 },
    { lat: 48.9190048, lng: 21.654855 },
    { lat: 48.9184047, lng: 21.655292 },
    { lat: 48.9175306, lng: 21.6537781 },
    { lat: 48.9173682, lng: 21.6533898 },
    { lat: 48.9170614, lng: 21.6529474 },
    { lat: 48.9165006, lng: 21.6517325 },
    { lat: 48.9162463, lng: 21.6513243 },
    { lat: 48.9148981, lng: 21.6481825 },
    { lat: 48.9145865, lng: 21.6478588 },
    { lat: 48.9139385, lng: 21.6468391 },
    { lat: 48.9131884, lng: 21.6451235 },
    { lat: 48.9123469, lng: 21.6435649 },
    { lat: 48.9121044, lng: 21.6438317 },
    { lat: 48.9109641, lng: 21.641757 },
    { lat: 48.9108825, lng: 21.6415751 },
    { lat: 48.9103904, lng: 21.6418033 },
    { lat: 48.9093662, lng: 21.641868 },
    { lat: 48.9092868, lng: 21.6419313 },
    { lat: 48.9097931, lng: 21.6434469 },
    { lat: 48.9093968, lng: 21.6438429 },
    { lat: 48.909387, lng: 21.6437777 },
    { lat: 48.9092037, lng: 21.6439473 },
    { lat: 48.908987, lng: 21.6430959 },
    { lat: 48.9083502, lng: 21.6435063 },
    { lat: 48.9080134, lng: 21.6422633 },
    { lat: 48.9073056, lng: 21.6422182 },
    { lat: 48.9070233, lng: 21.6420941 },
    { lat: 48.9068161, lng: 21.6419234 },
    { lat: 48.906654, lng: 21.6415584 },
    { lat: 48.9064586, lng: 21.6408713 },
    { lat: 48.9060423, lng: 21.6409076 },
    { lat: 48.9051014, lng: 21.641878 },
    { lat: 48.9045712, lng: 21.6421207 },
    { lat: 48.903833, lng: 21.6422299 },
    { lat: 48.9019583, lng: 21.6416472 },
    { lat: 48.9016246, lng: 21.6416264 },
    { lat: 48.9008, lng: 21.6424438 },
    { lat: 48.9007184, lng: 21.6414402 },
    { lat: 48.9005183, lng: 21.6408764 },
    { lat: 48.901027, lng: 21.6403067 },
    { lat: 48.9011615, lng: 21.6397536 },
    { lat: 48.9011343, lng: 21.6394993 },
    { lat: 48.9010948, lng: 21.6392912 },
    { lat: 48.9006686, lng: 21.6394207 },
    { lat: 48.9004042, lng: 21.6388946 },
    { lat: 48.9001473, lng: 21.6388787 },
    { lat: 48.8998346, lng: 21.6390809 },
    { lat: 48.8998166, lng: 21.6396527 },
    { lat: 48.8997431, lng: 21.6398083 },
    { lat: 48.8994214, lng: 21.6401758 },
    { lat: 48.899162, lng: 21.6406102 },
    { lat: 48.8986329, lng: 21.6411232 },
    { lat: 48.8982454, lng: 21.6413916 },
    { lat: 48.8978776, lng: 21.6414423 },
    { lat: 48.8972304, lng: 21.6412261 },
    { lat: 48.8969776, lng: 21.6409478 },
    { lat: 48.896306, lng: 21.6418112 },
    { lat: 48.8964273, lng: 21.6421311 },
    { lat: 48.8965922, lng: 21.6423588 },
    { lat: 48.8972221, lng: 21.6424767 },
    { lat: 48.8977339, lng: 21.6428463 },
    { lat: 48.8982864, lng: 21.6427224 },
    { lat: 48.8990866, lng: 21.6429383 },
    { lat: 48.8994227, lng: 21.6438285 },
    { lat: 48.8994275, lng: 21.6442516 },
    { lat: 48.8977486, lng: 21.6457171 },
    { lat: 48.8972108, lng: 21.6459237 },
    { lat: 48.8966867, lng: 21.6460094 },
    { lat: 48.8955375, lng: 21.6460293 },
    { lat: 48.8950327, lng: 21.6462968 },
    { lat: 48.8947011, lng: 21.6467568 },
    { lat: 48.894592, lng: 21.6469982 },
    { lat: 48.894486, lng: 21.6475702 },
    { lat: 48.8944597, lng: 21.6482523 },
    { lat: 48.8948268, lng: 21.6504378 },
    { lat: 48.8948411, lng: 21.6509603 },
    { lat: 48.8947806, lng: 21.6515398 },
    { lat: 48.8945912, lng: 21.6521063 },
    { lat: 48.8943111, lng: 21.6525952 },
    { lat: 48.8939632, lng: 21.6529625 },
    { lat: 48.8935593, lng: 21.6531656 },
    { lat: 48.8927207, lng: 21.6533128 },
    { lat: 48.8921846, lng: 21.6538278 },
    { lat: 48.8915322, lng: 21.6549471 },
    { lat: 48.8911069, lng: 21.6555181 },
    { lat: 48.8904846, lng: 21.6561332 },
    { lat: 48.8900031, lng: 21.656423 },
    { lat: 48.8897331, lng: 21.6565859 },
    { lat: 48.8899045, lng: 21.6568103 },
    { lat: 48.890087, lng: 21.656895 },
    { lat: 48.891226, lng: 21.6581044 },
    { lat: 48.8940474, lng: 21.6612705 },
    { lat: 48.8947765, lng: 21.6616477 },
    { lat: 48.8960614, lng: 21.6624695 },
    { lat: 48.8973486, lng: 21.6622263 },
    { lat: 48.8984997, lng: 21.662197 },
    { lat: 48.8989803, lng: 21.6634795 },
    { lat: 48.8999116, lng: 21.6633434 },
    { lat: 48.9000498, lng: 21.6632622 },
    { lat: 48.9004851, lng: 21.6642854 },
    { lat: 48.9008892, lng: 21.6659043 },
    { lat: 48.9025263, lng: 21.6657819 },
    { lat: 48.9029843, lng: 21.6656227 },
    { lat: 48.903701, lng: 21.6655448 },
    { lat: 48.9039404, lng: 21.6652494 },
    { lat: 48.9044507, lng: 21.6649413 },
    { lat: 48.905368, lng: 21.6649492 },
    { lat: 48.905518, lng: 21.6647166 },
    { lat: 48.9060713, lng: 21.66463 },
    { lat: 48.9071105, lng: 21.6643263 },
    { lat: 48.9070005, lng: 21.6637187 },
    { lat: 48.9092798, lng: 21.6616082 },
    { lat: 48.9096943, lng: 21.6623264 },
    { lat: 48.9110855, lng: 21.6609128 },
    { lat: 48.9114306, lng: 21.660693 },
    { lat: 48.9117864, lng: 21.6603337 },
    { lat: 48.9122553, lng: 21.6610654 },
    { lat: 48.912908, lng: 21.6603297 },
    { lat: 48.9132633, lng: 21.6601121 },
    { lat: 48.9135805, lng: 21.6597795 },
    { lat: 48.9143178, lng: 21.6613217 },
    { lat: 48.9149605, lng: 21.6623251 },
    { lat: 48.915047, lng: 21.6622806 },
    { lat: 48.9151501, lng: 21.6624312 },
    { lat: 48.9154591, lng: 21.662205 },
    { lat: 48.9151333, lng: 21.6613574 },
    { lat: 48.9159402, lng: 21.6598841 },
    { lat: 48.9160139, lng: 21.6594498 },
    { lat: 48.9169931, lng: 21.6581864 },
    { lat: 48.9170516, lng: 21.6579792 },
    { lat: 48.9171856, lng: 21.6579045 },
    { lat: 48.9178213, lng: 21.6570014 },
    { lat: 48.9181531, lng: 21.6567458 },
    { lat: 48.9184733, lng: 21.6566559 },
    { lat: 48.9191962, lng: 21.6566323 },
    { lat: 48.9196007, lng: 21.6562394 },
    { lat: 48.9198041, lng: 21.6558607 },
  ],
  Čičava: [
    { lat: 48.9334786, lng: 21.6461863 },
    { lat: 48.9334422, lng: 21.6460888 },
    { lat: 48.9334228, lng: 21.6460371 },
    { lat: 48.9307234, lng: 21.6487698 },
    { lat: 48.9304537, lng: 21.6491922 },
    { lat: 48.929746, lng: 21.650916 },
    { lat: 48.9294831, lng: 21.6514242 },
    { lat: 48.9287603, lng: 21.652561 },
    { lat: 48.9281096, lng: 21.65427 },
    { lat: 48.9279663, lng: 21.6545446 },
    { lat: 48.9262725, lng: 21.6567836 },
    { lat: 48.9257359, lng: 21.6576971 },
    { lat: 48.9249166, lng: 21.6561151 },
    { lat: 48.9241623, lng: 21.6548689 },
    { lat: 48.9222119, lng: 21.6519202 },
    { lat: 48.921568, lng: 21.6528143 },
    { lat: 48.9210534, lng: 21.6533698 },
    { lat: 48.9200063, lng: 21.6552784 },
    { lat: 48.9198041, lng: 21.6558607 },
    { lat: 48.9196007, lng: 21.6562394 },
    { lat: 48.9191962, lng: 21.6566323 },
    { lat: 48.9184733, lng: 21.6566559 },
    { lat: 48.9181531, lng: 21.6567458 },
    { lat: 48.9178213, lng: 21.6570014 },
    { lat: 48.9171856, lng: 21.6579045 },
    { lat: 48.9170516, lng: 21.6579792 },
    { lat: 48.9169931, lng: 21.6581864 },
    { lat: 48.9160139, lng: 21.6594498 },
    { lat: 48.9159402, lng: 21.6598841 },
    { lat: 48.9151333, lng: 21.6613574 },
    { lat: 48.9154591, lng: 21.662205 },
    { lat: 48.9155101, lng: 21.6623871 },
    { lat: 48.9160433, lng: 21.6642791 },
    { lat: 48.9166333, lng: 21.6671073 },
    { lat: 48.9173419, lng: 21.6671397 },
    { lat: 48.9176165, lng: 21.6670252 },
    { lat: 48.9176148, lng: 21.6671273 },
    { lat: 48.9176492, lng: 21.6671094 },
    { lat: 48.9180675, lng: 21.6682114 },
    { lat: 48.9184507, lng: 21.6699394 },
    { lat: 48.9183977, lng: 21.6710513 },
    { lat: 48.9182015, lng: 21.6718188 },
    { lat: 48.9179741, lng: 21.6722641 },
    { lat: 48.9181966, lng: 21.6739546 },
    { lat: 48.9182181, lng: 21.6751194 },
    { lat: 48.9181834, lng: 21.6759526 },
    { lat: 48.9177726, lng: 21.6783953 },
    { lat: 48.9172904, lng: 21.6806887 },
    { lat: 48.9174136, lng: 21.6823283 },
    { lat: 48.9173652, lng: 21.6833254 },
    { lat: 48.9169333, lng: 21.6832471 },
    { lat: 48.916959, lng: 21.6838665 },
    { lat: 48.9175363, lng: 21.6857789 },
    { lat: 48.9183532, lng: 21.6881147 },
    { lat: 48.9182112, lng: 21.6883131 },
    { lat: 48.9197647, lng: 21.6911336 },
    { lat: 48.9200802, lng: 21.6907784 },
    { lat: 48.9202139, lng: 21.690695 },
    { lat: 48.9203614, lng: 21.690445 },
    { lat: 48.920582, lng: 21.6903232 },
    { lat: 48.9206804, lng: 21.6900914 },
    { lat: 48.9217243, lng: 21.6888859 },
    { lat: 48.9229303, lng: 21.6883847 },
    { lat: 48.9232726, lng: 21.6881426 },
    { lat: 48.9232684, lng: 21.6881104 },
    { lat: 48.9240161, lng: 21.6877228 },
    { lat: 48.9251205, lng: 21.6875778 },
    { lat: 48.9252369, lng: 21.6873942 },
    { lat: 48.925378, lng: 21.6874508 },
    { lat: 48.9260797, lng: 21.6874024 },
    { lat: 48.9263676, lng: 21.6874832 },
    { lat: 48.9265871, lng: 21.6874388 },
    { lat: 48.9268886, lng: 21.6875255 },
    { lat: 48.9270321, lng: 21.6874268 },
    { lat: 48.9276019, lng: 21.6875222 },
    { lat: 48.9277242, lng: 21.687433 },
    { lat: 48.9279308, lng: 21.687496 },
    { lat: 48.9280627, lng: 21.6874237 },
    { lat: 48.9281732, lng: 21.6875174 },
    { lat: 48.9283439, lng: 21.6875518 },
    { lat: 48.9284133, lng: 21.6877461 },
    { lat: 48.9285389, lng: 21.6876413 },
    { lat: 48.9284581, lng: 21.687398 },
    { lat: 48.9283991, lng: 21.6869702 },
    { lat: 48.9284212, lng: 21.6865753 },
    { lat: 48.9283794, lng: 21.6859117 },
    { lat: 48.9282669, lng: 21.6852031 },
    { lat: 48.9283388, lng: 21.684366 },
    { lat: 48.9284977, lng: 21.6839583 },
    { lat: 48.928867, lng: 21.6840941 },
    { lat: 48.9291261, lng: 21.6841098 },
    { lat: 48.9296078, lng: 21.6844096 },
    { lat: 48.9298987, lng: 21.6842615 },
    { lat: 48.9301998, lng: 21.6837934 },
    { lat: 48.9303605, lng: 21.6836595 },
    { lat: 48.9304288, lng: 21.6834807 },
    { lat: 48.9305695, lng: 21.683357 },
    { lat: 48.9309674, lng: 21.68272 },
    { lat: 48.933573, lng: 21.6779939 },
    { lat: 48.9351971, lng: 21.6754934 },
    { lat: 48.9368611, lng: 21.6735864 },
    { lat: 48.9376765, lng: 21.6727939 },
    { lat: 48.9393527, lng: 21.6719621 },
    { lat: 48.9409192, lng: 21.6708994 },
    { lat: 48.9401971, lng: 21.6685863 },
    { lat: 48.9398902, lng: 21.6672353 },
    { lat: 48.9390269, lng: 21.6664316 },
    { lat: 48.9397635, lng: 21.6653876 },
    { lat: 48.9398344, lng: 21.6651438 },
    { lat: 48.9404499, lng: 21.6644835 },
    { lat: 48.940196, lng: 21.6636284 },
    { lat: 48.9412738, lng: 21.6635908 },
    { lat: 48.9412078, lng: 21.6628988 },
    { lat: 48.9412652, lng: 21.6623233 },
    { lat: 48.9415063, lng: 21.6616808 },
    { lat: 48.9414912, lng: 21.660835 },
    { lat: 48.9416809, lng: 21.6593353 },
    { lat: 48.9415761, lng: 21.6580507 },
    { lat: 48.941312, lng: 21.6580601 },
    { lat: 48.9400783, lng: 21.6579549 },
    { lat: 48.9392443, lng: 21.6577751 },
    { lat: 48.9392091, lng: 21.6579294 },
    { lat: 48.9389401, lng: 21.6578859 },
    { lat: 48.9384868, lng: 21.6576385 },
    { lat: 48.9378475, lng: 21.6575456 },
    { lat: 48.9376459, lng: 21.657456 },
    { lat: 48.9374552, lng: 21.6575191 },
    { lat: 48.9370869, lng: 21.6574657 },
    { lat: 48.9369193, lng: 21.6575096 },
    { lat: 48.9364418, lng: 21.6571607 },
    { lat: 48.9363378, lng: 21.6568913 },
    { lat: 48.9361758, lng: 21.6567265 },
    { lat: 48.9359394, lng: 21.6567615 },
    { lat: 48.9357401, lng: 21.6565063 },
    { lat: 48.9355101, lng: 21.6564067 },
    { lat: 48.9351008, lng: 21.6560428 },
    { lat: 48.9348826, lng: 21.6557216 },
    { lat: 48.9347439, lng: 21.6556638 },
    { lat: 48.934714, lng: 21.6554001 },
    { lat: 48.9345192, lng: 21.6551054 },
    { lat: 48.9343648, lng: 21.6547233 },
    { lat: 48.9341846, lng: 21.6547114 },
    { lat: 48.9336654, lng: 21.6543651 },
    { lat: 48.9335209, lng: 21.6543492 },
    { lat: 48.9330528, lng: 21.6540949 },
    { lat: 48.9328342, lng: 21.6540826 },
    { lat: 48.9327728, lng: 21.6539426 },
    { lat: 48.9325884, lng: 21.6537746 },
    { lat: 48.9318104, lng: 21.6534655 },
    { lat: 48.9310622, lng: 21.6534679 },
    { lat: 48.9308734, lng: 21.6535141 },
    { lat: 48.9305293, lng: 21.6537229 },
    { lat: 48.930125, lng: 21.653181 },
    { lat: 48.9302777, lng: 21.6525488 },
    { lat: 48.9302663, lng: 21.6523391 },
    { lat: 48.9306429, lng: 21.6518763 },
    { lat: 48.9308006, lng: 21.651798 },
    { lat: 48.9313801, lng: 21.6511894 },
    { lat: 48.9318581, lng: 21.6502783 },
    { lat: 48.9321206, lng: 21.6499204 },
    { lat: 48.9329366, lng: 21.6490724 },
    { lat: 48.9328794, lng: 21.6488338 },
    { lat: 48.9330046, lng: 21.648015 },
    { lat: 48.9335671, lng: 21.646535 },
    { lat: 48.9335595, lng: 21.6462997 },
    { lat: 48.9334786, lng: 21.6461863 },
  ],
  Štefanovce: [
    { lat: 48.9527891, lng: 21.7640342 },
    { lat: 48.952517, lng: 21.765751 },
    { lat: 48.9519218, lng: 21.7683006 },
    { lat: 48.9515082, lng: 21.7690048 },
    { lat: 48.9513031, lng: 21.770527 },
    { lat: 48.951219, lng: 21.7705375 },
    { lat: 48.9511563, lng: 21.7707617 },
    { lat: 48.9508316, lng: 21.7706504 },
    { lat: 48.9507095, lng: 21.7705444 },
    { lat: 48.950471, lng: 21.7705796 },
    { lat: 48.9502269, lng: 21.7707541 },
    { lat: 48.9500818, lng: 21.7706265 },
    { lat: 48.9499236, lng: 21.7706862 },
    { lat: 48.9498955, lng: 21.7705594 },
    { lat: 48.9497482, lng: 21.77048 },
    { lat: 48.949497, lng: 21.770468 },
    { lat: 48.9493684, lng: 21.7702385 },
    { lat: 48.9492857, lng: 21.7702149 },
    { lat: 48.949267, lng: 21.7703624 },
    { lat: 48.949138, lng: 21.7703688 },
    { lat: 48.9488883, lng: 21.770637 },
    { lat: 48.9484391, lng: 21.7704084 },
    { lat: 48.9481833, lng: 21.770129 },
    { lat: 48.947852, lng: 21.7702421 },
    { lat: 48.947298, lng: 21.7700546 },
    { lat: 48.9471647, lng: 21.7698948 },
    { lat: 48.9470927, lng: 21.7696855 },
    { lat: 48.9464923, lng: 21.7696944 },
    { lat: 48.9463613, lng: 21.7697628 },
    { lat: 48.9460886, lng: 21.7695761 },
    { lat: 48.9460204, lng: 21.769466 },
    { lat: 48.9459174, lng: 21.7695639 },
    { lat: 48.9458386, lng: 21.7694348 },
    { lat: 48.9454056, lng: 21.7696154 },
    { lat: 48.9452703, lng: 21.7692142 },
    { lat: 48.9449022, lng: 21.7693804 },
    { lat: 48.9445971, lng: 21.7692894 },
    { lat: 48.9440166, lng: 21.769578 },
    { lat: 48.9438261, lng: 21.7693988 },
    { lat: 48.9437574, lng: 21.7691804 },
    { lat: 48.9436309, lng: 21.7689866 },
    { lat: 48.943535, lng: 21.7690704 },
    { lat: 48.9427127, lng: 21.769023 },
    { lat: 48.9411421, lng: 21.7691097 },
    { lat: 48.9407697, lng: 21.7693152 },
    { lat: 48.9403229, lng: 21.7702889 },
    { lat: 48.9403337, lng: 21.7710497 },
    { lat: 48.9403452, lng: 21.7711002 },
    { lat: 48.9406514, lng: 21.771145 },
    { lat: 48.9406987, lng: 21.7711954 },
    { lat: 48.9406935, lng: 21.7713818 },
    { lat: 48.9409095, lng: 21.7714639 },
    { lat: 48.9410846, lng: 21.7720105 },
    { lat: 48.9411817, lng: 21.7720618 },
    { lat: 48.9413936, lng: 21.7725901 },
    { lat: 48.9417289, lng: 21.7729337 },
    { lat: 48.9417705, lng: 21.7734057 },
    { lat: 48.9420578, lng: 21.7739297 },
    { lat: 48.9420187, lng: 21.7747563 },
    { lat: 48.9420541, lng: 21.7749463 },
    { lat: 48.9417863, lng: 21.7759349 },
    { lat: 48.9413729, lng: 21.7768528 },
    { lat: 48.9414879, lng: 21.7772441 },
    { lat: 48.9415025, lng: 21.7778004 },
    { lat: 48.941357, lng: 21.7782215 },
    { lat: 48.9409298, lng: 21.7785343 },
    { lat: 48.9409074, lng: 21.7787024 },
    { lat: 48.9407121, lng: 21.7788495 },
    { lat: 48.9402983, lng: 21.7799273 },
    { lat: 48.9403749, lng: 21.7806169 },
    { lat: 48.9405273, lng: 21.7808919 },
    { lat: 48.9407895, lng: 21.7808123 },
    { lat: 48.9411174, lng: 21.7808755 },
    { lat: 48.9411568, lng: 21.7811376 },
    { lat: 48.9409104, lng: 21.7813879 },
    { lat: 48.940785, lng: 21.782392 },
    { lat: 48.940855, lng: 21.783365 },
    { lat: 48.94096, lng: 21.78398 },
    { lat: 48.94187, lng: 21.786137 },
    { lat: 48.942346, lng: 21.78705 },
    { lat: 48.942819, lng: 21.787977 },
    { lat: 48.942843, lng: 21.788024 },
    { lat: 48.942937, lng: 21.788209 },
    { lat: 48.943067, lng: 21.788448 },
    { lat: 48.943177, lng: 21.78865 },
    { lat: 48.943438, lng: 21.78913 },
    { lat: 48.943569, lng: 21.789407 },
    { lat: 48.943646, lng: 21.789681 },
    { lat: 48.943826, lng: 21.790949 },
    { lat: 48.943977, lng: 21.791646 },
    { lat: 48.944072, lng: 21.792276 },
    { lat: 48.944183, lng: 21.79302 },
    { lat: 48.94439, lng: 21.794036 },
    { lat: 48.944546, lng: 21.795215 },
    { lat: 48.944804, lng: 21.796404 },
    { lat: 48.946655, lng: 21.795005 },
    { lat: 48.947619, lng: 21.794264 },
    { lat: 48.9482, lng: 21.793716 },
    { lat: 48.948243, lng: 21.793739 },
    { lat: 48.948639, lng: 21.793949 },
    { lat: 48.950298, lng: 21.793576 },
    { lat: 48.952534, lng: 21.793873 },
    { lat: 48.953282, lng: 21.794075 },
    { lat: 48.955611, lng: 21.795078 },
    { lat: 48.956081, lng: 21.795809 },
    { lat: 48.956229, lng: 21.795865 },
    { lat: 48.95625, lng: 21.795874 },
    { lat: 48.9571, lng: 21.796249 },
    { lat: 48.957426, lng: 21.796469 },
    { lat: 48.958651, lng: 21.797288 },
    { lat: 48.959375, lng: 21.797059 },
    { lat: 48.961267, lng: 21.797423 },
    { lat: 48.963037, lng: 21.798241 },
    { lat: 48.963656, lng: 21.798422 },
    { lat: 48.964252, lng: 21.79882 },
    { lat: 48.965014, lng: 21.799035 },
    { lat: 48.965749, lng: 21.799451 },
    { lat: 48.966505, lng: 21.79974 },
    { lat: 48.966606, lng: 21.799782 },
    { lat: 48.967289, lng: 21.800489 },
    { lat: 48.967813, lng: 21.80112 },
    { lat: 48.967711, lng: 21.800732 },
    { lat: 48.968618, lng: 21.800286 },
    { lat: 48.968871, lng: 21.800587 },
    { lat: 48.969365, lng: 21.800359 },
    { lat: 48.96935, lng: 21.800152 },
    { lat: 48.970047, lng: 21.799744 },
    { lat: 48.970065, lng: 21.799499 },
    { lat: 48.970357, lng: 21.799316 },
    { lat: 48.970425, lng: 21.799451 },
    { lat: 48.970541, lng: 21.799414 },
    { lat: 48.970721, lng: 21.799161 },
    { lat: 48.970898, lng: 21.799276 },
    { lat: 48.971186, lng: 21.798905 },
    { lat: 48.971256, lng: 21.798075 },
    { lat: 48.971596, lng: 21.797836 },
    { lat: 48.971812, lng: 21.798019 },
    { lat: 48.972161, lng: 21.798047 },
    { lat: 48.972195, lng: 21.797865 },
    { lat: 48.972798, lng: 21.79727 },
    { lat: 48.973367, lng: 21.795572 },
    { lat: 48.973721, lng: 21.795194 },
    { lat: 48.973777, lng: 21.794911 },
    { lat: 48.9737, lng: 21.793934 },
    { lat: 48.973914, lng: 21.793642 },
    { lat: 48.974465, lng: 21.792011 },
    { lat: 48.974988, lng: 21.790501 },
    { lat: 48.975514, lng: 21.790182 },
    { lat: 48.97624, lng: 21.79023 },
    { lat: 48.977174, lng: 21.790094 },
    { lat: 48.97792, lng: 21.789812 },
    { lat: 48.978578, lng: 21.789097 },
    { lat: 48.978974, lng: 21.788387 },
    { lat: 48.979424, lng: 21.787717 },
    { lat: 48.980405, lng: 21.786591 },
    { lat: 48.980626, lng: 21.785917 },
    { lat: 48.980786, lng: 21.785347 },
    { lat: 48.9810447, lng: 21.7840646 },
    { lat: 48.9805925, lng: 21.7835955 },
    { lat: 48.9804012, lng: 21.7826459 },
    { lat: 48.9811438, lng: 21.7819298 },
    { lat: 48.9812912, lng: 21.7814542 },
    { lat: 48.9814923, lng: 21.7801979 },
    { lat: 48.981475, lng: 21.7797076 },
    { lat: 48.9819684, lng: 21.7779826 },
    { lat: 48.9820692, lng: 21.7774919 },
    { lat: 48.9818212, lng: 21.7768286 },
    { lat: 48.9814946, lng: 21.7763051 },
    { lat: 48.981682, lng: 21.7756387 },
    { lat: 48.9817039, lng: 21.7753594 },
    { lat: 48.9813467, lng: 21.7751229 },
    { lat: 48.9813718, lng: 21.7744111 },
    { lat: 48.981303, lng: 21.774189 },
    { lat: 48.9813746, lng: 21.7732692 },
    { lat: 48.9808412, lng: 21.77249 },
    { lat: 48.980645, lng: 21.7720518 },
    { lat: 48.9798697, lng: 21.7711018 },
    { lat: 48.9795399, lng: 21.7705012 },
    { lat: 48.9792316, lng: 21.7688259 },
    { lat: 48.9789603, lng: 21.7681745 },
    { lat: 48.9785758, lng: 21.7676367 },
    { lat: 48.9778063, lng: 21.7668204 },
    { lat: 48.9758198, lng: 21.7659703 },
    { lat: 48.9754436, lng: 21.7658733 },
    { lat: 48.9746392, lng: 21.7658706 },
    { lat: 48.9734719, lng: 21.7660959 },
    { lat: 48.9730989, lng: 21.7664491 },
    { lat: 48.9726578, lng: 21.7666203 },
    { lat: 48.9719943, lng: 21.7665442 },
    { lat: 48.9717254, lng: 21.7666702 },
    { lat: 48.9713984, lng: 21.7666865 },
    { lat: 48.9706245, lng: 21.7666071 },
    { lat: 48.9700731, lng: 21.766859 },
    { lat: 48.9695764, lng: 21.7669537 },
    { lat: 48.969003, lng: 21.7668901 },
    { lat: 48.9679159, lng: 21.7669096 },
    { lat: 48.9674441, lng: 21.7670225 },
    { lat: 48.967084, lng: 21.7673233 },
    { lat: 48.9664056, lng: 21.767362 },
    { lat: 48.9661734, lng: 21.7672536 },
    { lat: 48.9658493, lng: 21.7667012 },
    { lat: 48.9655865, lng: 21.7665052 },
    { lat: 48.9646301, lng: 21.7669618 },
    { lat: 48.9635031, lng: 21.767059 },
    { lat: 48.9631174, lng: 21.7667187 },
    { lat: 48.9624948, lng: 21.7663609 },
    { lat: 48.9620572, lng: 21.7661867 },
    { lat: 48.9616549, lng: 21.7663354 },
    { lat: 48.9613737, lng: 21.7662905 },
    { lat: 48.9609364, lng: 21.76605 },
    { lat: 48.960632, lng: 21.7657678 },
    { lat: 48.959793, lng: 21.7655174 },
    { lat: 48.9591909, lng: 21.7654291 },
    { lat: 48.9589176, lng: 21.7655144 },
    { lat: 48.9586463, lng: 21.7657196 },
    { lat: 48.9585204, lng: 21.7657294 },
    { lat: 48.9577337, lng: 21.7653251 },
    { lat: 48.957489, lng: 21.7653067 },
    { lat: 48.9570533, lng: 21.7655476 },
    { lat: 48.9567285, lng: 21.7656028 },
    { lat: 48.9563285, lng: 21.7651553 },
    { lat: 48.9551539, lng: 21.7644599 },
    { lat: 48.9545226, lng: 21.7642485 },
    { lat: 48.9539584, lng: 21.7638411 },
    { lat: 48.9535879, lng: 21.763783 },
    { lat: 48.9530275, lng: 21.7639034 },
    { lat: 48.9527891, lng: 21.7640342 },
  ],
  Ďapalovce: [
    { lat: 49.04549, lng: 21.756425 },
    { lat: 49.045726, lng: 21.756746 },
    { lat: 49.046417, lng: 21.757685 },
    { lat: 49.047115, lng: 21.757954 },
    { lat: 49.047126, lng: 21.758948 },
    { lat: 49.047573, lng: 21.759094 },
    { lat: 49.047871, lng: 21.759042 },
    { lat: 49.048349, lng: 21.759715 },
    { lat: 49.048603, lng: 21.759904 },
    { lat: 49.049248, lng: 21.76012 },
    { lat: 49.050043, lng: 21.760102 },
    { lat: 49.050773, lng: 21.760033 },
    { lat: 49.052152, lng: 21.760208 },
    { lat: 49.052352, lng: 21.760258 },
    { lat: 49.052735, lng: 21.760319 },
    { lat: 49.053023, lng: 21.760403 },
    { lat: 49.053734, lng: 21.76061 },
    { lat: 49.053788, lng: 21.760659 },
    { lat: 49.054354, lng: 21.76131 },
    { lat: 49.054505, lng: 21.761614 },
    { lat: 49.054542, lng: 21.761661 },
    { lat: 49.055054, lng: 21.762082 },
    { lat: 49.055201, lng: 21.762222 },
    { lat: 49.055372, lng: 21.762398 },
    { lat: 49.055789, lng: 21.763112 },
    { lat: 49.056185, lng: 21.763635 },
    { lat: 49.056668, lng: 21.76412 },
    { lat: 49.057063, lng: 21.76465 },
    { lat: 49.057629, lng: 21.765479 },
    { lat: 49.057771, lng: 21.765612 },
    { lat: 49.057984, lng: 21.765812 },
    { lat: 49.05813, lng: 21.76595 },
    { lat: 49.058572, lng: 21.766371 },
    { lat: 49.058957, lng: 21.76684 },
    { lat: 49.059456, lng: 21.76731 },
    { lat: 49.059836, lng: 21.767668 },
    { lat: 49.060148, lng: 21.768061 },
    { lat: 49.06026, lng: 21.768189 },
    { lat: 49.060445, lng: 21.768353 },
    { lat: 49.060866, lng: 21.769197 },
    { lat: 49.061198, lng: 21.770452 },
    { lat: 49.061245, lng: 21.771892 },
    { lat: 49.061483, lng: 21.772176 },
    { lat: 49.061792, lng: 21.772989 },
    { lat: 49.061821, lng: 21.773277 },
    { lat: 49.061836, lng: 21.773433 },
    { lat: 49.061916, lng: 21.77356 },
    { lat: 49.061873, lng: 21.773833 },
    { lat: 49.061926, lng: 21.774069 },
    { lat: 49.06196, lng: 21.774413 },
    { lat: 49.062018, lng: 21.774721 },
    { lat: 49.062116, lng: 21.775032 },
    { lat: 49.062227, lng: 21.775343 },
    { lat: 49.06251, lng: 21.775946 },
    { lat: 49.062916, lng: 21.776136 },
    { lat: 49.063189, lng: 21.775878 },
    { lat: 49.063331, lng: 21.77565 },
    { lat: 49.064075, lng: 21.775529 },
    { lat: 49.064128, lng: 21.775399 },
    { lat: 49.064273, lng: 21.77513 },
    { lat: 49.064453, lng: 21.774529 },
    { lat: 49.064636, lng: 21.774221 },
    { lat: 49.064828, lng: 21.773894 },
    { lat: 49.064976, lng: 21.773482 },
    { lat: 49.065062, lng: 21.773388 },
    { lat: 49.065113, lng: 21.773241 },
    { lat: 49.065571, lng: 21.771814 },
    { lat: 49.065589, lng: 21.771825 },
    { lat: 49.065583, lng: 21.771481 },
    { lat: 49.065776, lng: 21.771251 },
    { lat: 49.065834, lng: 21.771076 },
    { lat: 49.065746, lng: 21.770937 },
    { lat: 49.065872, lng: 21.770665 },
    { lat: 49.065953, lng: 21.770711 },
    { lat: 49.065995, lng: 21.770623 },
    { lat: 49.065957, lng: 21.770336 },
    { lat: 49.065965, lng: 21.770317 },
    { lat: 49.066188, lng: 21.770319 },
    { lat: 49.06631, lng: 21.770212 },
    { lat: 49.066341, lng: 21.770115 },
    { lat: 49.066161, lng: 21.769971 },
    { lat: 49.06621, lng: 21.769617 },
    { lat: 49.066404, lng: 21.769304 },
    { lat: 49.066421, lng: 21.769303 },
    { lat: 49.066579, lng: 21.769474 },
    { lat: 49.066591, lng: 21.769488 },
    { lat: 49.06661, lng: 21.76947 },
    { lat: 49.066712, lng: 21.768998 },
    { lat: 49.066969, lng: 21.768922 },
    { lat: 49.067088, lng: 21.768755 },
    { lat: 49.067128, lng: 21.768766 },
    { lat: 49.067182, lng: 21.768883 },
    { lat: 49.067218, lng: 21.768891 },
    { lat: 49.067391, lng: 21.768648 },
    { lat: 49.067427, lng: 21.768658 },
    { lat: 49.06753, lng: 21.768792 },
    { lat: 49.067566, lng: 21.76879 },
    { lat: 49.068008, lng: 21.768203 },
    { lat: 49.0686, lng: 21.768311 },
    { lat: 49.068657, lng: 21.76822 },
    { lat: 49.068881, lng: 21.768207 },
    { lat: 49.069008, lng: 21.768359 },
    { lat: 49.069018, lng: 21.768335 },
    { lat: 49.069059, lng: 21.768189 },
    { lat: 49.069149, lng: 21.768194 },
    { lat: 49.069291, lng: 21.768273 },
    { lat: 49.069282, lng: 21.768027 },
    { lat: 49.069371, lng: 21.767975 },
    { lat: 49.069587, lng: 21.768002 },
    { lat: 49.069596, lng: 21.768028 },
    { lat: 49.069835, lng: 21.767843 },
    { lat: 49.070054, lng: 21.767742 },
    { lat: 49.07023, lng: 21.767902 },
    { lat: 49.070462, lng: 21.767712 },
    { lat: 49.070517, lng: 21.767797 },
    { lat: 49.070611, lng: 21.767744 },
    { lat: 49.070703, lng: 21.767887 },
    { lat: 49.070801, lng: 21.767855 },
    { lat: 49.070966, lng: 21.768064 },
    { lat: 49.071302, lng: 21.767923 },
    { lat: 49.071376, lng: 21.767821 },
    { lat: 49.071452, lng: 21.767843 },
    { lat: 49.071463, lng: 21.767817 },
    { lat: 49.071469, lng: 21.767715 },
    { lat: 49.071582, lng: 21.767721 },
    { lat: 49.071649, lng: 21.767572 },
    { lat: 49.071626, lng: 21.767426 },
    { lat: 49.071643, lng: 21.767412 },
    { lat: 49.071678, lng: 21.7674 },
    { lat: 49.071756, lng: 21.767532 },
    { lat: 49.071772, lng: 21.767517 },
    { lat: 49.072264, lng: 21.767193 },
    { lat: 49.072508, lng: 21.767222 },
    { lat: 49.072892, lng: 21.767005 },
    { lat: 49.072907, lng: 21.766743 },
    { lat: 49.07303, lng: 21.766597 },
    { lat: 49.073124, lng: 21.766379 },
    { lat: 49.073202, lng: 21.765919 },
    { lat: 49.073273, lng: 21.765817 },
    { lat: 49.073501, lng: 21.765802 },
    { lat: 49.073666, lng: 21.765716 },
    { lat: 49.073808, lng: 21.765487 },
    { lat: 49.07398, lng: 21.765421 },
    { lat: 49.07419, lng: 21.765741 },
    { lat: 49.074378, lng: 21.766149 },
    { lat: 49.074529, lng: 21.766446 },
    { lat: 49.074878, lng: 21.767077 },
    { lat: 49.075149, lng: 21.767374 },
    { lat: 49.075408, lng: 21.767812 },
    { lat: 49.075816, lng: 21.768309 },
    { lat: 49.076456, lng: 21.768585 },
    { lat: 49.077011, lng: 21.768856 },
    { lat: 49.077439, lng: 21.768857 },
    { lat: 49.077719, lng: 21.768922 },
    { lat: 49.077845, lng: 21.768976 },
    { lat: 49.078109, lng: 21.768809 },
    { lat: 49.078225, lng: 21.768658 },
    { lat: 49.078674, lng: 21.768212 },
    { lat: 49.07914, lng: 21.767901 },
    { lat: 49.07969, lng: 21.767459 },
    { lat: 49.080049, lng: 21.767252 },
    { lat: 49.081284, lng: 21.766259 },
    { lat: 49.081815, lng: 21.765794 },
    { lat: 49.082211, lng: 21.765331 },
    { lat: 49.082492, lng: 21.764928 },
    { lat: 49.08264, lng: 21.764644 },
    { lat: 49.08284, lng: 21.76439 },
    { lat: 49.083073, lng: 21.764048 },
    { lat: 49.083702, lng: 21.763308 },
    { lat: 49.083816, lng: 21.763143 },
    { lat: 49.084419, lng: 21.762553 },
    { lat: 49.084549, lng: 21.762504 },
    { lat: 49.084952, lng: 21.762215 },
    { lat: 49.085694, lng: 21.761998 },
    { lat: 49.086254, lng: 21.761719 },
    { lat: 49.086693, lng: 21.761532 },
    { lat: 49.087207, lng: 21.761549 },
    { lat: 49.087592, lng: 21.76166 },
    { lat: 49.087847, lng: 21.761828 },
    { lat: 49.08791, lng: 21.761268 },
    { lat: 49.08798, lng: 21.760226 },
    { lat: 49.088021, lng: 21.759373 },
    { lat: 49.088004, lng: 21.758773 },
    { lat: 49.087804, lng: 21.758066 },
    { lat: 49.087772, lng: 21.756905 },
    { lat: 49.087784, lng: 21.756552 },
    { lat: 49.087803, lng: 21.756278 },
    { lat: 49.087678, lng: 21.755837 },
    { lat: 49.087652, lng: 21.755649 },
    { lat: 49.087962, lng: 21.755503 },
    { lat: 49.088508, lng: 21.755599 },
    { lat: 49.088654, lng: 21.755699 },
    { lat: 49.088806, lng: 21.755697 },
    { lat: 49.088998, lng: 21.755596 },
    { lat: 49.089128, lng: 21.755606 },
    { lat: 49.089295, lng: 21.755757 },
    { lat: 49.089776, lng: 21.75559 },
    { lat: 49.090056, lng: 21.755604 },
    { lat: 49.090219, lng: 21.755469 },
    { lat: 49.090665, lng: 21.755464 },
    { lat: 49.091296, lng: 21.755683 },
    { lat: 49.091471, lng: 21.755554 },
    { lat: 49.092008, lng: 21.755288 },
    { lat: 49.092728, lng: 21.754583 },
    { lat: 49.093236, lng: 21.753872 },
    { lat: 49.093395, lng: 21.75352 },
    { lat: 49.093997, lng: 21.753056 },
    { lat: 49.09424, lng: 21.752929 },
    { lat: 49.094838, lng: 21.752357 },
    { lat: 49.095436, lng: 21.75183 },
    { lat: 49.095769, lng: 21.751489 },
    { lat: 49.095964, lng: 21.751326 },
    { lat: 49.096436, lng: 21.750995 },
    { lat: 49.09658, lng: 21.750898 },
    { lat: 49.097013, lng: 21.750463 },
    { lat: 49.097533, lng: 21.75011 },
    { lat: 49.097602, lng: 21.749946 },
    { lat: 49.097646, lng: 21.749804 },
    { lat: 49.097604, lng: 21.749717 },
    { lat: 49.097638, lng: 21.74947 },
    { lat: 49.097668, lng: 21.749126 },
    { lat: 49.09788, lng: 21.748744 },
    { lat: 49.097839, lng: 21.748671 },
    { lat: 49.097821, lng: 21.748301 },
    { lat: 49.09775, lng: 21.748131 },
    { lat: 49.097824, lng: 21.747663 },
    { lat: 49.097735, lng: 21.747472 },
    { lat: 49.09773, lng: 21.747416 },
    { lat: 49.09776, lng: 21.747308 },
    { lat: 49.097768, lng: 21.747094 },
    { lat: 49.097746, lng: 21.746999 },
    { lat: 49.0976328, lng: 21.746955 },
    { lat: 49.0976136, lng: 21.7468284 },
    { lat: 49.0977535, lng: 21.7465833 },
    { lat: 49.0975522, lng: 21.7461623 },
    { lat: 49.0972081, lng: 21.7460398 },
    { lat: 49.0969408, lng: 21.7457572 },
    { lat: 49.0967286, lng: 21.7453887 },
    { lat: 49.0966585, lng: 21.7451629 },
    { lat: 49.0963098, lng: 21.7452076 },
    { lat: 49.0955, lng: 21.7447497 },
    { lat: 49.09516, lng: 21.7441848 },
    { lat: 49.0945381, lng: 21.7438525 },
    { lat: 49.0944613, lng: 21.7440072 },
    { lat: 49.0941383, lng: 21.7439172 },
    { lat: 49.093662, lng: 21.7429676 },
    { lat: 49.092368, lng: 21.7422323 },
    { lat: 49.0922699, lng: 21.7420527 },
    { lat: 49.0921276, lng: 21.742121 },
    { lat: 49.0920663, lng: 21.7418793 },
    { lat: 49.0918218, lng: 21.7418685 },
    { lat: 49.0917157, lng: 21.7414317 },
    { lat: 49.0915953, lng: 21.7414334 },
    { lat: 49.0914346, lng: 21.7408837 },
    { lat: 49.091244, lng: 21.7406143 },
    { lat: 49.0912506, lng: 21.74048 },
    { lat: 49.0911263, lng: 21.740245 },
    { lat: 49.0910505, lng: 21.7401865 },
    { lat: 49.0908654, lng: 21.7402019 },
    { lat: 49.0907147, lng: 21.7396943 },
    { lat: 49.0899941, lng: 21.7380595 },
    { lat: 49.0900696, lng: 21.7375535 },
    { lat: 49.0909524, lng: 21.7359367 },
    { lat: 49.0915089, lng: 21.7355008 },
    { lat: 49.0919181, lng: 21.7350223 },
    { lat: 49.0941999, lng: 21.7328344 },
    { lat: 49.0943949, lng: 21.732262 },
    { lat: 49.094673, lng: 21.7316749 },
    { lat: 49.0946109, lng: 21.7311991 },
    { lat: 49.0944255, lng: 21.7304485 },
    { lat: 49.0944611, lng: 21.7301394 },
    { lat: 49.0944259, lng: 21.7295784 },
    { lat: 49.0940586, lng: 21.7281153 },
    { lat: 49.094104, lng: 21.7277044 },
    { lat: 49.0940193, lng: 21.727234 },
    { lat: 49.0938885, lng: 21.7269678 },
    { lat: 49.0938359, lng: 21.7264273 },
    { lat: 49.093984, lng: 21.725314 },
    { lat: 49.093893, lng: 21.725015 },
    { lat: 49.093795, lng: 21.724855 },
    { lat: 49.093616, lng: 21.724802 },
    { lat: 49.093264, lng: 21.724542 },
    { lat: 49.092882, lng: 21.724365 },
    { lat: 49.092518, lng: 21.724123 },
    { lat: 49.092226, lng: 21.72382 },
    { lat: 49.091886, lng: 21.723548 },
    { lat: 49.091831, lng: 21.723529 },
    { lat: 49.091497, lng: 21.723389 },
    { lat: 49.091302, lng: 21.723252 },
    { lat: 49.090732, lng: 21.72302 },
    { lat: 49.0907, lng: 21.72298 },
    { lat: 49.090217, lng: 21.722565 },
    { lat: 49.09, lng: 21.722387 },
    { lat: 49.089342, lng: 21.72144 },
    { lat: 49.089224, lng: 21.720969 },
    { lat: 49.089138, lng: 21.719578 },
    { lat: 49.08912, lng: 21.718855 },
    { lat: 49.087344, lng: 21.718175 },
    { lat: 49.0866704, lng: 21.7183359 },
    { lat: 49.086004, lng: 21.7181511 },
    { lat: 49.0850705, lng: 21.7186725 },
    { lat: 49.0846667, lng: 21.7189641 },
    { lat: 49.0837909, lng: 21.7190689 },
    { lat: 49.0832839, lng: 21.7189112 },
    { lat: 49.082968, lng: 21.7186734 },
    { lat: 49.0821198, lng: 21.7191314 },
    { lat: 49.0817153, lng: 21.7191945 },
    { lat: 49.0811056, lng: 21.7192322 },
    { lat: 49.0802497, lng: 21.7191222 },
    { lat: 49.0799778, lng: 21.7192596 },
    { lat: 49.0795603, lng: 21.7192144 },
    { lat: 49.079048, lng: 21.7192748 },
    { lat: 49.0783357, lng: 21.7187626 },
    { lat: 49.0773377, lng: 21.7183367 },
    { lat: 49.0762243, lng: 21.7185534 },
    { lat: 49.0757973, lng: 21.7187908 },
    { lat: 49.0745835, lng: 21.7191687 },
    { lat: 49.0741368, lng: 21.7196291 },
    { lat: 49.0739704, lng: 21.7201313 },
    { lat: 49.073742, lng: 21.7205125 },
    { lat: 49.0732567, lng: 21.7209573 },
    { lat: 49.0725591, lng: 21.7214568 },
    { lat: 49.0721776, lng: 21.7218338 },
    { lat: 49.0717221, lng: 21.7225577 },
    { lat: 49.0713219, lng: 21.7234072 },
    { lat: 49.0709906, lng: 21.7239781 },
    { lat: 49.0709722, lng: 21.7243409 },
    { lat: 49.0706847, lng: 21.7246202 },
    { lat: 49.0698123, lng: 21.7250988 },
    { lat: 49.0696302, lng: 21.725447 },
    { lat: 49.0692158, lng: 21.7255109 },
    { lat: 49.0685899, lng: 21.7258811 },
    { lat: 49.0683232, lng: 21.7261688 },
    { lat: 49.0681403, lng: 21.7261445 },
    { lat: 49.0674569, lng: 21.726294 },
    { lat: 49.0667355, lng: 21.7261146 },
    { lat: 49.0658612, lng: 21.726274 },
    { lat: 49.0657648, lng: 21.7263733 },
    { lat: 49.0655918, lng: 21.7263811 },
    { lat: 49.0647539, lng: 21.7269354 },
    { lat: 49.0646649, lng: 21.7270531 },
    { lat: 49.0640187, lng: 21.7272024 },
    { lat: 49.0639155, lng: 21.727428 },
    { lat: 49.0637785, lng: 21.7275652 },
    { lat: 49.0620253, lng: 21.7283093 },
    { lat: 49.0609612, lng: 21.7283527 },
    { lat: 49.0604282, lng: 21.7282345 },
    { lat: 49.0593356, lng: 21.7281405 },
    { lat: 49.0584645, lng: 21.7274894 },
    { lat: 49.0572695, lng: 21.726507 },
    { lat: 49.0565679, lng: 21.723947 },
    { lat: 49.0560674, lng: 21.7244596 },
    { lat: 49.0552036, lng: 21.7255631 },
    { lat: 49.0547354, lng: 21.7265253 },
    { lat: 49.0547337, lng: 21.7267976 },
    { lat: 49.0550731, lng: 21.7272117 },
    { lat: 49.0555095, lng: 21.7280894 },
    { lat: 49.055655, lng: 21.7302609 },
    { lat: 49.0557831, lng: 21.7307164 },
    { lat: 49.0560395, lng: 21.7313502 },
    { lat: 49.0565559, lng: 21.7324095 },
    { lat: 49.0568231, lng: 21.7338683 },
    { lat: 49.0566415, lng: 21.7340154 },
    { lat: 49.0567398, lng: 21.7343709 },
    { lat: 49.0564981, lng: 21.7345565 },
    { lat: 49.0565538, lng: 21.7347881 },
    { lat: 49.0564882, lng: 21.7349004 },
    { lat: 49.0565228, lng: 21.73514 },
    { lat: 49.056752, lng: 21.7354262 },
    { lat: 49.0566583, lng: 21.7358369 },
    { lat: 49.056385, lng: 21.7357099 },
    { lat: 49.0563667, lng: 21.7360428 },
    { lat: 49.0562108, lng: 21.7362267 },
    { lat: 49.056181, lng: 21.7364157 },
    { lat: 49.0558916, lng: 21.7363679 },
    { lat: 49.0557436, lng: 21.7362601 },
    { lat: 49.0557097, lng: 21.7365487 },
    { lat: 49.0558923, lng: 21.7366849 },
    { lat: 49.055796, lng: 21.7367888 },
    { lat: 49.0554948, lng: 21.7367205 },
    { lat: 49.0553942, lng: 21.736784 },
    { lat: 49.0553637, lng: 21.736947 },
    { lat: 49.0550435, lng: 21.737132 },
    { lat: 49.0550558, lng: 21.737382 },
    { lat: 49.0549389, lng: 21.737515 },
    { lat: 49.0549592, lng: 21.7377311 },
    { lat: 49.0548105, lng: 21.7378465 },
    { lat: 49.0548559, lng: 21.7380295 },
    { lat: 49.0543815, lng: 21.7382587 },
    { lat: 49.0542778, lng: 21.7382052 },
    { lat: 49.0542327, lng: 21.738076 },
    { lat: 49.0540827, lng: 21.7380587 },
    { lat: 49.0538871, lng: 21.7382798 },
    { lat: 49.0532916, lng: 21.7383874 },
    { lat: 49.0531354, lng: 21.7387808 },
    { lat: 49.0530743, lng: 21.7391066 },
    { lat: 49.0528724, lng: 21.7391638 },
    { lat: 49.0528328, lng: 21.7401784 },
    { lat: 49.0528468, lng: 21.7415143 },
    { lat: 49.0525251, lng: 21.74397 },
    { lat: 49.0525458, lng: 21.7443711 },
    { lat: 49.0518986, lng: 21.7455944 },
    { lat: 49.0519189, lng: 21.7459349 },
    { lat: 49.0509011, lng: 21.7468358 },
    { lat: 49.0508016, lng: 21.7471112 },
    { lat: 49.0503127, lng: 21.7476357 },
    { lat: 49.0501394, lng: 21.7479842 },
    { lat: 49.0498151, lng: 21.7482598 },
    { lat: 49.049189, lng: 21.7493837 },
    { lat: 49.0489644, lng: 21.749529 },
    { lat: 49.0488349, lng: 21.7507574 },
    { lat: 49.0487151, lng: 21.7511646 },
    { lat: 49.0485704, lng: 21.7521188 },
    { lat: 49.0491107, lng: 21.7523429 },
    { lat: 49.049455, lng: 21.7525976 },
    { lat: 49.0490042, lng: 21.753459 },
    { lat: 49.048591, lng: 21.7537471 },
    { lat: 49.0481767, lng: 21.7533923 },
    { lat: 49.0478869, lng: 21.7533645 },
    { lat: 49.047698, lng: 21.7535403 },
    { lat: 49.0476915, lng: 21.7537214 },
    { lat: 49.0469651, lng: 21.7545017 },
    { lat: 49.0460528, lng: 21.755181 },
    { lat: 49.0456627, lng: 21.7558317 },
    { lat: 49.04549, lng: 21.756425 },
  ],
  Merník: [
    { lat: 48.9684055, lng: 21.6159227 },
    { lat: 48.9678858, lng: 21.6159598 },
    { lat: 48.9674306, lng: 21.6161013 },
    { lat: 48.9659705, lng: 21.6171286 },
    { lat: 48.9647712, lng: 21.6167209 },
    { lat: 48.9647223, lng: 21.6167853 },
    { lat: 48.9627133, lng: 21.6149298 },
    { lat: 48.9618926, lng: 21.6136485 },
    { lat: 48.9609025, lng: 21.6133221 },
    { lat: 48.9603593, lng: 21.6134463 },
    { lat: 48.9594916, lng: 21.6141329 },
    { lat: 48.9590022, lng: 21.6147401 },
    { lat: 48.9579229, lng: 21.6150542 },
    { lat: 48.9573304, lng: 21.6153523 },
    { lat: 48.9570062, lng: 21.6156332 },
    { lat: 48.9563534, lng: 21.6163579 },
    { lat: 48.9561751, lng: 21.6169858 },
    { lat: 48.9549446, lng: 21.6182194 },
    { lat: 48.9521613, lng: 21.620788 },
    { lat: 48.9515215, lng: 21.6215747 },
    { lat: 48.9506819, lng: 21.6224174 },
    { lat: 48.9490415, lng: 21.6237588 },
    { lat: 48.9481853, lng: 21.6242922 },
    { lat: 48.947214, lng: 21.6244793 },
    { lat: 48.9448192, lng: 21.6256784 },
    { lat: 48.9401989, lng: 21.6274054 },
    { lat: 48.9384945, lng: 21.6279821 },
    { lat: 48.9370193, lng: 21.6284165 },
    { lat: 48.9363076, lng: 21.6290535 },
    { lat: 48.9357526, lng: 21.6297368 },
    { lat: 48.9347006, lng: 21.6314461 },
    { lat: 48.9352725, lng: 21.633748 },
    { lat: 48.9361119, lng: 21.6357585 },
    { lat: 48.9355636, lng: 21.6366427 },
    { lat: 48.9348015, lng: 21.6374038 },
    { lat: 48.9342412, lng: 21.6388806 },
    { lat: 48.9340995, lng: 21.6395752 },
    { lat: 48.9341391, lng: 21.6399105 },
    { lat: 48.934015, lng: 21.6401463 },
    { lat: 48.9339761, lng: 21.641394 },
    { lat: 48.9335424, lng: 21.6435884 },
    { lat: 48.9336478, lng: 21.6452149 },
    { lat: 48.9338049, lng: 21.6458945 },
    { lat: 48.9334786, lng: 21.6461863 },
    { lat: 48.9335595, lng: 21.6462997 },
    { lat: 48.9335671, lng: 21.646535 },
    { lat: 48.9330046, lng: 21.648015 },
    { lat: 48.9328794, lng: 21.6488338 },
    { lat: 48.9329366, lng: 21.6490724 },
    { lat: 48.9321206, lng: 21.6499204 },
    { lat: 48.9318581, lng: 21.6502783 },
    { lat: 48.9313801, lng: 21.6511894 },
    { lat: 48.9308006, lng: 21.651798 },
    { lat: 48.9306429, lng: 21.6518763 },
    { lat: 48.9302663, lng: 21.6523391 },
    { lat: 48.9302777, lng: 21.6525488 },
    { lat: 48.930125, lng: 21.653181 },
    { lat: 48.9305293, lng: 21.6537229 },
    { lat: 48.9308734, lng: 21.6535141 },
    { lat: 48.9310622, lng: 21.6534679 },
    { lat: 48.9318104, lng: 21.6534655 },
    { lat: 48.9325884, lng: 21.6537746 },
    { lat: 48.9327728, lng: 21.6539426 },
    { lat: 48.9328342, lng: 21.6540826 },
    { lat: 48.9330528, lng: 21.6540949 },
    { lat: 48.9335209, lng: 21.6543492 },
    { lat: 48.9336654, lng: 21.6543651 },
    { lat: 48.9341846, lng: 21.6547114 },
    { lat: 48.9343648, lng: 21.6547233 },
    { lat: 48.9345192, lng: 21.6551054 },
    { lat: 48.934714, lng: 21.6554001 },
    { lat: 48.9347439, lng: 21.6556638 },
    { lat: 48.9348826, lng: 21.6557216 },
    { lat: 48.9351008, lng: 21.6560428 },
    { lat: 48.9355101, lng: 21.6564067 },
    { lat: 48.9357401, lng: 21.6565063 },
    { lat: 48.9359394, lng: 21.6567615 },
    { lat: 48.9361758, lng: 21.6567265 },
    { lat: 48.9363378, lng: 21.6568913 },
    { lat: 48.9364418, lng: 21.6571607 },
    { lat: 48.9369193, lng: 21.6575096 },
    { lat: 48.9370869, lng: 21.6574657 },
    { lat: 48.9374552, lng: 21.6575191 },
    { lat: 48.9376459, lng: 21.657456 },
    { lat: 48.9378475, lng: 21.6575456 },
    { lat: 48.9384868, lng: 21.6576385 },
    { lat: 48.9389401, lng: 21.6578859 },
    { lat: 48.9392091, lng: 21.6579294 },
    { lat: 48.9392443, lng: 21.6577751 },
    { lat: 48.9400783, lng: 21.6579549 },
    { lat: 48.941312, lng: 21.6580601 },
    { lat: 48.9415761, lng: 21.6580507 },
    { lat: 48.9418285, lng: 21.6579783 },
    { lat: 48.9422598, lng: 21.6581999 },
    { lat: 48.9423378, lng: 21.6582569 },
    { lat: 48.942517, lng: 21.6586844 },
    { lat: 48.9428716, lng: 21.6587272 },
    { lat: 48.943385, lng: 21.6590346 },
    { lat: 48.9443507, lng: 21.6589273 },
    { lat: 48.9447031, lng: 21.659306 },
    { lat: 48.9449361, lng: 21.6591875 },
    { lat: 48.9457566, lng: 21.6594061 },
    { lat: 48.9458638, lng: 21.6595426 },
    { lat: 48.9459894, lng: 21.659937 },
    { lat: 48.9461244, lng: 21.6601049 },
    { lat: 48.9464717, lng: 21.6602968 },
    { lat: 48.9467872, lng: 21.6599899 },
    { lat: 48.9473559, lng: 21.6607101 },
    { lat: 48.9474527, lng: 21.6607487 },
    { lat: 48.9474761, lng: 21.6610268 },
    { lat: 48.9475923, lng: 21.6611236 },
    { lat: 48.9479434, lng: 21.6611248 },
    { lat: 48.9480088, lng: 21.6610491 },
    { lat: 48.9480709, lng: 21.6610628 },
    { lat: 48.9482545, lng: 21.6614768 },
    { lat: 48.9483317, lng: 21.661506 },
    { lat: 48.9484782, lng: 21.6612659 },
    { lat: 48.9488092, lng: 21.6612318 },
    { lat: 48.9488896, lng: 21.6612937 },
    { lat: 48.9492999, lng: 21.6619806 },
    { lat: 48.9500331, lng: 21.6620498 },
    { lat: 48.9503599, lng: 21.6619498 },
    { lat: 48.9505432, lng: 21.6621505 },
    { lat: 48.9510857, lng: 21.6621601 },
    { lat: 48.9513357, lng: 21.6623391 },
    { lat: 48.9519904, lng: 21.663238 },
    { lat: 48.9527265, lng: 21.6638736 },
    { lat: 48.9529654, lng: 21.6640172 },
    { lat: 48.9531904, lng: 21.6639333 },
    { lat: 48.9532351, lng: 21.6638342 },
    { lat: 48.9534379, lng: 21.6639328 },
    { lat: 48.9537405, lng: 21.6641769 },
    { lat: 48.9537544, lng: 21.6642723 },
    { lat: 48.9539727, lng: 21.6645675 },
    { lat: 48.9540601, lng: 21.6648781 },
    { lat: 48.9542291, lng: 21.6648922 },
    { lat: 48.9543358, lng: 21.6650115 },
    { lat: 48.9548687, lng: 21.665239 },
    { lat: 48.9550613, lng: 21.665039 },
    { lat: 48.955304, lng: 21.6650363 },
    { lat: 48.9553756, lng: 21.6651377 },
    { lat: 48.9559404, lng: 21.6651023 },
    { lat: 48.9559969, lng: 21.6650282 },
    { lat: 48.9559793, lng: 21.6649609 },
    { lat: 48.9562003, lng: 21.6647749 },
    { lat: 48.9562686, lng: 21.6645898 },
    { lat: 48.956334, lng: 21.6646032 },
    { lat: 48.9564168, lng: 21.66438 },
    { lat: 48.9565323, lng: 21.6642428 },
    { lat: 48.9566006, lng: 21.6641873 },
    { lat: 48.9567369, lng: 21.6643517 },
    { lat: 48.956758, lng: 21.6643031 },
    { lat: 48.956693, lng: 21.6640215 },
    { lat: 48.9568888, lng: 21.6637393 },
    { lat: 48.9570634, lng: 21.6637025 },
    { lat: 48.9571037, lng: 21.6638217 },
    { lat: 48.9572363, lng: 21.6637754 },
    { lat: 48.9571877, lng: 21.6635572 },
    { lat: 48.9572265, lng: 21.6634141 },
    { lat: 48.9573872, lng: 21.6635336 },
    { lat: 48.9574497, lng: 21.6633435 },
    { lat: 48.9573484, lng: 21.6632622 },
    { lat: 48.9573276, lng: 21.6630326 },
    { lat: 48.9575502, lng: 21.6629073 },
    { lat: 48.9576835, lng: 21.6626418 },
    { lat: 48.9578924, lng: 21.6626692 },
    { lat: 48.9582575, lng: 21.6624418 },
    { lat: 48.9585474, lng: 21.6624719 },
    { lat: 48.958736, lng: 21.6624128 },
    { lat: 48.9588485, lng: 21.6622926 },
    { lat: 48.9589943, lng: 21.6625204 },
    { lat: 48.9591956, lng: 21.6623914 },
    { lat: 48.9596009, lng: 21.6627745 },
    { lat: 48.9598768, lng: 21.6627833 },
    { lat: 48.9599362, lng: 21.6628601 },
    { lat: 48.9601019, lng: 21.6627491 },
    { lat: 48.9603761, lng: 21.6629409 },
    { lat: 48.960427, lng: 21.6630351 },
    { lat: 48.9608097, lng: 21.6630809 },
    { lat: 48.9612061, lng: 21.6633438 },
    { lat: 48.961467, lng: 21.6633411 },
    { lat: 48.9618192, lng: 21.663755 },
    { lat: 48.9620581, lng: 21.6636906 },
    { lat: 48.9622421, lng: 21.663876 },
    { lat: 48.9623933, lng: 21.6639295 },
    { lat: 48.9625596, lng: 21.6638818 },
    { lat: 48.9626287, lng: 21.6639884 },
    { lat: 48.9635192, lng: 21.6643808 },
    { lat: 48.9640415, lng: 21.6647977 },
    { lat: 48.9649566, lng: 21.6651523 },
    { lat: 48.9653473, lng: 21.665164 },
    { lat: 48.9657717, lng: 21.6650858 },
    { lat: 48.9664703, lng: 21.664797 },
    { lat: 48.9667608, lng: 21.664642 },
    { lat: 48.9672037, lng: 21.6641712 },
    { lat: 48.9677769, lng: 21.6636569 },
    { lat: 48.968003, lng: 21.6616743 },
    { lat: 48.9683122, lng: 21.6619553 },
    { lat: 48.9686031, lng: 21.661816 },
    { lat: 48.9690152, lng: 21.6614116 },
    { lat: 48.9692673, lng: 21.6610495 },
    { lat: 48.969449, lng: 21.660945 },
    { lat: 48.9695169, lng: 21.6607538 },
    { lat: 48.9697819, lng: 21.6605312 },
    { lat: 48.9700555, lng: 21.6600833 },
    { lat: 48.9702401, lng: 21.6594267 },
    { lat: 48.9702046, lng: 21.6587837 },
    { lat: 48.9698956, lng: 21.6577242 },
    { lat: 48.9697692, lng: 21.6577128 },
    { lat: 48.9697304, lng: 21.6574406 },
    { lat: 48.969569, lng: 21.6573412 },
    { lat: 48.9691534, lng: 21.6565384 },
    { lat: 48.9691174, lng: 21.6562113 },
    { lat: 48.9689773, lng: 21.6560282 },
    { lat: 48.9689345, lng: 21.6554873 },
    { lat: 48.9686555, lng: 21.6551561 },
    { lat: 48.9686213, lng: 21.6548947 },
    { lat: 48.9685217, lng: 21.6548738 },
    { lat: 48.9684341, lng: 21.6546265 },
    { lat: 48.9687472, lng: 21.6541868 },
    { lat: 48.9694859, lng: 21.6538817 },
    { lat: 48.9701182, lng: 21.6528102 },
    { lat: 48.9707238, lng: 21.6516358 },
    { lat: 48.9707654, lng: 21.6514702 },
    { lat: 48.9708371, lng: 21.6496495 },
    { lat: 48.9706875, lng: 21.6487845 },
    { lat: 48.9709245, lng: 21.6483978 },
    { lat: 48.9694465, lng: 21.6460261 },
    { lat: 48.9692979, lng: 21.6455696 },
    { lat: 48.9686692, lng: 21.6440556 },
    { lat: 48.9684004, lng: 21.6435731 },
    { lat: 48.9671212, lng: 21.6420073 },
    { lat: 48.9673939, lng: 21.6408764 },
    { lat: 48.9674605, lng: 21.6407637 },
    { lat: 48.9676112, lng: 21.6409264 },
    { lat: 48.9678462, lng: 21.640633 },
    { lat: 48.9679413, lng: 21.6399992 },
    { lat: 48.9684025, lng: 21.6389717 },
    { lat: 48.9686443, lng: 21.6377762 },
    { lat: 48.9692892, lng: 21.6353684 },
    { lat: 48.9694447, lng: 21.6350918 },
    { lat: 48.9697372, lng: 21.6341544 },
    { lat: 48.9699357, lng: 21.633151 },
    { lat: 48.9701726, lng: 21.6327202 },
    { lat: 48.9704653, lng: 21.6326057 },
    { lat: 48.9700351, lng: 21.6272348 },
    { lat: 48.9698582, lng: 21.6258727 },
    { lat: 48.9695977, lng: 21.6245887 },
    { lat: 48.9694789, lng: 21.624283 },
    { lat: 48.9690519, lng: 21.6225877 },
    { lat: 48.9690621, lng: 21.6221796 },
    { lat: 48.9687977, lng: 21.6210742 },
    { lat: 48.9685272, lng: 21.6204035 },
    { lat: 48.9683694, lng: 21.6198292 },
    { lat: 48.9683912, lng: 21.6192703 },
    { lat: 48.9682617, lng: 21.6189011 },
    { lat: 48.9683505, lng: 21.6185735 },
    { lat: 48.9683545, lng: 21.6179416 },
    { lat: 48.9683133, lng: 21.6176693 },
    { lat: 48.9684055, lng: 21.6159227 },
  ],
  Matiaška: [
    { lat: 49.069334, lng: 21.609189 },
    { lat: 49.069491, lng: 21.609336 },
    { lat: 49.069641, lng: 21.6095 },
    { lat: 49.069753, lng: 21.609698 },
    { lat: 49.069852, lng: 21.609955 },
    { lat: 49.069964, lng: 21.610101 },
    { lat: 49.07016, lng: 21.610259 },
    { lat: 49.070288, lng: 21.610398 },
    { lat: 49.07047, lng: 21.610522 },
    { lat: 49.070695, lng: 21.610805 },
    { lat: 49.07081, lng: 21.61092 },
    { lat: 49.071212, lng: 21.611003 },
    { lat: 49.071451, lng: 21.61113 },
    { lat: 49.071835, lng: 21.611459 },
    { lat: 49.072031, lng: 21.611677 },
    { lat: 49.07239, lng: 21.611528 },
    { lat: 49.072886, lng: 21.611652 },
    { lat: 49.073432, lng: 21.611516 },
    { lat: 49.073856, lng: 21.611517 },
    { lat: 49.074303, lng: 21.611323 },
    { lat: 49.074573, lng: 21.611398 },
    { lat: 49.074879, lng: 21.611308 },
    { lat: 49.074987, lng: 21.611148 },
    { lat: 49.075351, lng: 21.611066 },
    { lat: 49.075705, lng: 21.610777 },
    { lat: 49.075806, lng: 21.610731 },
    { lat: 49.075981, lng: 21.610581 },
    { lat: 49.076103, lng: 21.610427 },
    { lat: 49.076366, lng: 21.610402 },
    { lat: 49.076644, lng: 21.610209 },
    { lat: 49.076711, lng: 21.610102 },
    { lat: 49.076828, lng: 21.610015 },
    { lat: 49.077214, lng: 21.610044 },
    { lat: 49.077373, lng: 21.610031 },
    { lat: 49.077407, lng: 21.610031 },
    { lat: 49.077379, lng: 21.610148 },
    { lat: 49.077395, lng: 21.610228 },
    { lat: 49.077744, lng: 21.610294 },
    { lat: 49.077951, lng: 21.610521 },
    { lat: 49.078044, lng: 21.610368 },
    { lat: 49.078074, lng: 21.609878 },
    { lat: 49.078197, lng: 21.609951 },
    { lat: 49.078409, lng: 21.610188 },
    { lat: 49.078569, lng: 21.61024 },
    { lat: 49.078768, lng: 21.610215 },
    { lat: 49.078898, lng: 21.610004 },
    { lat: 49.079065, lng: 21.610228 },
    { lat: 49.079367, lng: 21.610132 },
    { lat: 49.079516, lng: 21.610207 },
    { lat: 49.079687, lng: 21.609947 },
    { lat: 49.079708, lng: 21.609941 },
    { lat: 49.079763, lng: 21.610008 },
    { lat: 49.079832, lng: 21.610219 },
    { lat: 49.079869, lng: 21.610228 },
    { lat: 49.079937, lng: 21.609893 },
    { lat: 49.080133, lng: 21.609754 },
    { lat: 49.080178, lng: 21.609601 },
    { lat: 49.080251, lng: 21.609478 },
    { lat: 49.080267, lng: 21.609475 },
    { lat: 49.080359, lng: 21.609521 },
    { lat: 49.080571, lng: 21.60956 },
    { lat: 49.080778, lng: 21.609403 },
    { lat: 49.080874, lng: 21.60926 },
    { lat: 49.08075, lng: 21.609238 },
    { lat: 49.080739, lng: 21.60919 },
    { lat: 49.08074, lng: 21.609162 },
    { lat: 49.080832, lng: 21.608981 },
    { lat: 49.080832, lng: 21.608875 },
    { lat: 49.080785, lng: 21.608733 },
    { lat: 49.080866, lng: 21.608684 },
    { lat: 49.080976, lng: 21.608577 },
    { lat: 49.080984, lng: 21.608556 },
    { lat: 49.08098, lng: 21.608533 },
    { lat: 49.080914, lng: 21.608446 },
    { lat: 49.080922, lng: 21.608307 },
    { lat: 49.081209, lng: 21.608218 },
    { lat: 49.081218, lng: 21.60803 },
    { lat: 49.081199, lng: 21.607728 },
    { lat: 49.081241, lng: 21.607603 },
    { lat: 49.081511, lng: 21.607307 },
    { lat: 49.081516, lng: 21.607293 },
    { lat: 49.081459, lng: 21.606979 },
    { lat: 49.081461, lng: 21.606942 },
    { lat: 49.081481, lng: 21.606922 },
    { lat: 49.081577, lng: 21.606858 },
    { lat: 49.081899, lng: 21.606676 },
    { lat: 49.081937, lng: 21.606603 },
    { lat: 49.081966, lng: 21.606301 },
    { lat: 49.082116, lng: 21.60619 },
    { lat: 49.082225, lng: 21.60616 },
    { lat: 49.08226, lng: 21.606015 },
    { lat: 49.082266, lng: 21.605878 },
    { lat: 49.082369, lng: 21.605825 },
    { lat: 49.082649, lng: 21.605539 },
    { lat: 49.082766, lng: 21.605351 },
    { lat: 49.082856, lng: 21.605319 },
    { lat: 49.082879, lng: 21.605326 },
    { lat: 49.082891, lng: 21.605449 },
    { lat: 49.082911, lng: 21.6055 },
    { lat: 49.082953, lng: 21.6055 },
    { lat: 49.083111, lng: 21.605248 },
    { lat: 49.083143, lng: 21.604927 },
    { lat: 49.083156, lng: 21.604914 },
    { lat: 49.083253, lng: 21.604953 },
    { lat: 49.083294, lng: 21.605096 },
    { lat: 49.08331, lng: 21.60511 },
    { lat: 49.083365, lng: 21.605099 },
    { lat: 49.083424, lng: 21.605031 },
    { lat: 49.083115, lng: 21.6033762 },
    { lat: 49.0831671, lng: 21.6018884 },
    { lat: 49.0832602, lng: 21.6016739 },
    { lat: 49.08321, lng: 21.601123 },
    { lat: 49.0832159, lng: 21.6003653 },
    { lat: 49.0832966, lng: 21.6003511 },
    { lat: 49.0833015, lng: 21.5996761 },
    { lat: 49.0824791, lng: 21.598134 },
    { lat: 49.082543, lng: 21.5978765 },
    { lat: 49.0825123, lng: 21.5971373 },
    { lat: 49.0822938, lng: 21.5963575 },
    { lat: 49.082177, lng: 21.5961235 },
    { lat: 49.0821101, lng: 21.5946911 },
    { lat: 49.0821621, lng: 21.5938027 },
    { lat: 49.0826823, lng: 21.5935377 },
    { lat: 49.0824463, lng: 21.5921693 },
    { lat: 49.0827944, lng: 21.5920644 },
    { lat: 49.0830807, lng: 21.5918363 },
    { lat: 49.0831471, lng: 21.5912341 },
    { lat: 49.0836399, lng: 21.5910323 },
    { lat: 49.0835346, lng: 21.589831 },
    { lat: 49.0839775, lng: 21.5891743 },
    { lat: 49.0842547, lng: 21.5885036 },
    { lat: 49.0846801, lng: 21.5877799 },
    { lat: 49.0848989, lng: 21.5876373 },
    { lat: 49.0858489, lng: 21.5866001 },
    { lat: 49.0861782, lng: 21.5865462 },
    { lat: 49.0862116, lng: 21.5862142 },
    { lat: 49.0868384, lng: 21.5863374 },
    { lat: 49.0874977, lng: 21.5862177 },
    { lat: 49.0883416, lng: 21.5858244 },
    { lat: 49.0890906, lng: 21.5851231 },
    { lat: 49.0895174, lng: 21.5845755 },
    { lat: 49.090413, lng: 21.5838457 },
    { lat: 49.0908484, lng: 21.583684 },
    { lat: 49.0918361, lng: 21.5835632 },
    { lat: 49.0924023, lng: 21.5833324 },
    { lat: 49.0955805, lng: 21.5807104 },
    { lat: 49.0959708, lng: 21.580184 },
    { lat: 49.0968559, lng: 21.5787572 },
    { lat: 49.0970691, lng: 21.5784551 },
    { lat: 49.0972679, lng: 21.5782817 },
    { lat: 49.0984274, lng: 21.5766109 },
    { lat: 49.0988517, lng: 21.5757728 },
    { lat: 49.0991194, lng: 21.5746479 },
    { lat: 49.098709, lng: 21.5738878 },
    { lat: 49.0987404, lng: 21.5738068 },
    { lat: 49.0990373, lng: 21.5730735 },
    { lat: 49.0995749, lng: 21.5720991 },
    { lat: 49.1001796, lng: 21.5716003 },
    { lat: 49.1001423, lng: 21.5711866 },
    { lat: 49.1008704, lng: 21.5707107 },
    { lat: 49.1008493, lng: 21.5704019 },
    { lat: 49.1006594, lng: 21.5698484 },
    { lat: 49.1004305, lng: 21.5694607 },
    { lat: 49.100425, lng: 21.569331 },
    { lat: 49.100287, lng: 21.569271 },
    { lat: 49.100129, lng: 21.569278 },
    { lat: 49.099631, lng: 21.569263 },
    { lat: 49.099157, lng: 21.569234 },
    { lat: 49.099028, lng: 21.569095 },
    { lat: 49.098716, lng: 21.568965 },
    { lat: 49.098368, lng: 21.568955 },
    { lat: 49.097965, lng: 21.568732 },
    { lat: 49.097736, lng: 21.568362 },
    { lat: 49.097475, lng: 21.568091 },
    { lat: 49.097352, lng: 21.567812 },
    { lat: 49.096525, lng: 21.56713 },
    { lat: 49.096433, lng: 21.566976 },
    { lat: 49.096099, lng: 21.566925 },
    { lat: 49.095906, lng: 21.566805 },
    { lat: 49.095737, lng: 21.566747 },
    { lat: 49.095186, lng: 21.566528 },
    { lat: 49.094529, lng: 21.566053 },
    { lat: 49.094325, lng: 21.565836 },
    { lat: 49.094265, lng: 21.565723 },
    { lat: 49.094115, lng: 21.56563 },
    { lat: 49.093611, lng: 21.565121 },
    { lat: 49.093389, lng: 21.565298 },
    { lat: 49.093139, lng: 21.565213 },
    { lat: 49.092876, lng: 21.56527 },
    { lat: 49.092712, lng: 21.565478 },
    { lat: 49.092063, lng: 21.565653 },
    { lat: 49.091883, lng: 21.565574 },
    { lat: 49.091746, lng: 21.565712 },
    { lat: 49.091433, lng: 21.565553 },
    { lat: 49.090869, lng: 21.565727 },
    { lat: 49.090567, lng: 21.565635 },
    { lat: 49.090244, lng: 21.565678 },
    { lat: 49.090034, lng: 21.565567 },
    { lat: 49.089647, lng: 21.56574 },
    { lat: 49.089499, lng: 21.565588 },
    { lat: 49.089146, lng: 21.56559 },
    { lat: 49.088648, lng: 21.565528 },
    { lat: 49.088538, lng: 21.565294 },
    { lat: 49.088245, lng: 21.565341 },
    { lat: 49.088089, lng: 21.565247 },
    { lat: 49.087618, lng: 21.565187 },
    { lat: 49.087481, lng: 21.565089 },
    { lat: 49.087333, lng: 21.565306 },
    { lat: 49.087073, lng: 21.565302 },
    { lat: 49.086946, lng: 21.565613 },
    { lat: 49.08675, lng: 21.565781 },
    { lat: 49.086287, lng: 21.566462 },
    { lat: 49.086197, lng: 21.566699 },
    { lat: 49.085971, lng: 21.567391 },
    { lat: 49.085956, lng: 21.567368 },
    { lat: 49.085718, lng: 21.567075 },
    { lat: 49.085587, lng: 21.566905 },
    { lat: 49.085236, lng: 21.567308 },
    { lat: 49.085097, lng: 21.567436 },
    { lat: 49.084764, lng: 21.567573 },
    { lat: 49.084559, lng: 21.566799 },
    { lat: 49.084281, lng: 21.566075 },
    { lat: 49.083334, lng: 21.566269 },
    { lat: 49.083197, lng: 21.566105 },
    { lat: 49.08301, lng: 21.5656 },
    { lat: 49.082668, lng: 21.565523 },
    { lat: 49.082099, lng: 21.564883 },
    { lat: 49.081393, lng: 21.564511 },
    { lat: 49.080629, lng: 21.563966 },
    { lat: 49.081291, lng: 21.562589 },
    { lat: 49.081794, lng: 21.56223 },
    { lat: 49.081392, lng: 21.561313 },
    { lat: 49.081118, lng: 21.561299 },
    { lat: 49.080359, lng: 21.561222 },
    { lat: 49.080196, lng: 21.560207 },
    { lat: 49.079797, lng: 21.560027 },
    { lat: 49.079462, lng: 21.559946 },
    { lat: 49.0792, lng: 21.559968 },
    { lat: 49.0788184, lng: 21.5599875 },
    { lat: 49.0779279, lng: 21.5601692 },
    { lat: 49.0768472, lng: 21.5597414 },
    { lat: 49.0762803, lng: 21.5594226 },
    { lat: 49.0759582, lng: 21.5592961 },
    { lat: 49.075539, lng: 21.5592282 },
    { lat: 49.0755416, lng: 21.5591617 },
    { lat: 49.0751039, lng: 21.5591632 },
    { lat: 49.0744344, lng: 21.5588806 },
    { lat: 49.0732242, lng: 21.5587264 },
    { lat: 49.0727455, lng: 21.5583858 },
    { lat: 49.0727156, lng: 21.5582865 },
    { lat: 49.0721644, lng: 21.5583285 },
    { lat: 49.0711747, lng: 21.5587804 },
    { lat: 49.0709498, lng: 21.5589404 },
    { lat: 49.0702804, lng: 21.5596287 },
    { lat: 49.0700897, lng: 21.5592844 },
    { lat: 49.0694413, lng: 21.5600701 },
    { lat: 49.0692836, lng: 21.5601467 },
    { lat: 49.0688616, lng: 21.5587019 },
    { lat: 49.0686008, lng: 21.5588685 },
    { lat: 49.0683544, lng: 21.5589096 },
    { lat: 49.0680409, lng: 21.5589623 },
    { lat: 49.06751, lng: 21.5593298 },
    { lat: 49.0671752, lng: 21.5598998 },
    { lat: 49.0662692, lng: 21.5607 },
    { lat: 49.065858, lng: 21.5609176 },
    { lat: 49.0655068, lng: 21.5614718 },
    { lat: 49.0644885, lng: 21.5626305 },
    { lat: 49.0635822, lng: 21.5632935 },
    { lat: 49.0634077, lng: 21.5637289 },
    { lat: 49.0620944, lng: 21.5646741 },
    { lat: 49.0613087, lng: 21.5653834 },
    { lat: 49.0607287, lng: 21.565608 },
    { lat: 49.059726, lng: 21.5655966 },
    { lat: 49.0596068, lng: 21.5651556 },
    { lat: 49.0592351, lng: 21.5650994 },
    { lat: 49.0591606, lng: 21.5649722 },
    { lat: 49.0589733, lng: 21.5642711 },
    { lat: 49.0588578, lng: 21.5641606 },
    { lat: 49.0583885, lng: 21.5641986 },
    { lat: 49.0578171, lng: 21.5646038 },
    { lat: 49.0576465, lng: 21.5653409 },
    { lat: 49.0576808, lng: 21.5659148 },
    { lat: 49.0579219, lng: 21.5667382 },
    { lat: 49.0578686, lng: 21.5673254 },
    { lat: 49.0577639, lng: 21.5678398 },
    { lat: 49.0570858, lng: 21.5684932 },
    { lat: 49.0570307, lng: 21.5686571 },
    { lat: 49.0570324, lng: 21.5689196 },
    { lat: 49.0567311, lng: 21.5698521 },
    { lat: 49.0565448, lng: 21.5699865 },
    { lat: 49.0564213, lng: 21.5706434 },
    { lat: 49.0562696, lng: 21.5710855 },
    { lat: 49.0561262, lng: 21.5712691 },
    { lat: 49.0554021, lng: 21.5730547 },
    { lat: 49.054469, lng: 21.5738899 },
    { lat: 49.0543971, lng: 21.574089 },
    { lat: 49.0541499, lng: 21.5743475 },
    { lat: 49.0533487, lng: 21.574857 },
    { lat: 49.0532168, lng: 21.5750141 },
    { lat: 49.0523502, lng: 21.5754457 },
    { lat: 49.0520299, lng: 21.5759377 },
    { lat: 49.0519405, lng: 21.5762398 },
    { lat: 49.0524627, lng: 21.5767706 },
    { lat: 49.0526394, lng: 21.5770221 },
    { lat: 49.0525276, lng: 21.5774845 },
    { lat: 49.0522376, lng: 21.5782611 },
    { lat: 49.0519823, lng: 21.5793914 },
    { lat: 49.0518239, lng: 21.5798055 },
    { lat: 49.051742, lng: 21.5804948 },
    { lat: 49.0514497, lng: 21.5805822 },
    { lat: 49.0514009, lng: 21.5809695 },
    { lat: 49.051472, lng: 21.5814251 },
    { lat: 49.0515735, lng: 21.5817987 },
    { lat: 49.051904, lng: 21.5830012 },
    { lat: 49.0528561, lng: 21.5824473 },
    { lat: 49.053355, lng: 21.582305 },
    { lat: 49.0536125, lng: 21.5834229 },
    { lat: 49.0534881, lng: 21.5836028 },
    { lat: 49.0536416, lng: 21.5842285 },
    { lat: 49.0535236, lng: 21.584353 },
    { lat: 49.0534414, lng: 21.5849566 },
    { lat: 49.0536677, lng: 21.5850059 },
    { lat: 49.0538271, lng: 21.5857169 },
    { lat: 49.0535757, lng: 21.5871102 },
    { lat: 49.0532522, lng: 21.5873735 },
    { lat: 49.0532059, lng: 21.5874863 },
    { lat: 49.0534194, lng: 21.5879679 },
    { lat: 49.0534256, lng: 21.5881482 },
    { lat: 49.053742, lng: 21.5884037 },
    { lat: 49.0540436, lng: 21.5883642 },
    { lat: 49.0543114, lng: 21.5886855 },
    { lat: 49.0542599, lng: 21.588696 },
    { lat: 49.0540905, lng: 21.5894053 },
    { lat: 49.0540539, lng: 21.5906305 },
    { lat: 49.0545397, lng: 21.590662 },
    { lat: 49.0553708, lng: 21.5912585 },
    { lat: 49.0549709, lng: 21.5926229 },
    { lat: 49.0556874, lng: 21.5929373 },
    { lat: 49.0562963, lng: 21.5929444 },
    { lat: 49.0570221, lng: 21.5933149 },
    { lat: 49.057084, lng: 21.5933959 },
    { lat: 49.0573964, lng: 21.5944416 },
    { lat: 49.057907, lng: 21.5945939 },
    { lat: 49.0584813, lng: 21.5950168 },
    { lat: 49.058458, lng: 21.5954337 },
    { lat: 49.058565, lng: 21.595561 },
    { lat: 49.0584095, lng: 21.5966077 },
    { lat: 49.0584157, lng: 21.5974353 },
    { lat: 49.059297, lng: 21.5981855 },
    { lat: 49.0598033, lng: 21.5984274 },
    { lat: 49.0606741, lng: 21.5996844 },
    { lat: 49.0611485, lng: 21.6002409 },
    { lat: 49.0619471, lng: 21.6023468 },
    { lat: 49.062267, lng: 21.6019105 },
    { lat: 49.0626129, lng: 21.6022521 },
    { lat: 49.0631941, lng: 21.6024419 },
    { lat: 49.0638472, lng: 21.6025474 },
    { lat: 49.0646452, lng: 21.6028474 },
    { lat: 49.0649516, lng: 21.6039511 },
    { lat: 49.0652898, lng: 21.6043036 },
    { lat: 49.065908, lng: 21.6044836 },
    { lat: 49.0660821, lng: 21.6046005 },
    { lat: 49.0665791, lng: 21.6054021 },
    { lat: 49.0669492, lng: 21.6056055 },
    { lat: 49.0671117, lng: 21.606186 },
    { lat: 49.0676199, lng: 21.6060562 },
    { lat: 49.0681772, lng: 21.6062781 },
    { lat: 49.0684092, lng: 21.6066112 },
    { lat: 49.0689417, lng: 21.6082477 },
    { lat: 49.069334, lng: 21.609189 },
  ],
  Žalobín: [
    { lat: 48.9527891, lng: 21.7640342 },
    { lat: 48.9530275, lng: 21.7639034 },
    { lat: 48.9535879, lng: 21.763783 },
    { lat: 48.9539584, lng: 21.7638411 },
    { lat: 48.9545226, lng: 21.7642485 },
    { lat: 48.9551539, lng: 21.7644599 },
    { lat: 48.9563285, lng: 21.7651553 },
    { lat: 48.9567285, lng: 21.7656028 },
    { lat: 48.9570533, lng: 21.7655476 },
    { lat: 48.957489, lng: 21.7653067 },
    { lat: 48.9577337, lng: 21.7653251 },
    { lat: 48.9585204, lng: 21.7657294 },
    { lat: 48.9586463, lng: 21.7657196 },
    { lat: 48.9589176, lng: 21.7655144 },
    { lat: 48.9591909, lng: 21.7654291 },
    { lat: 48.959793, lng: 21.7655174 },
    { lat: 48.960632, lng: 21.7657678 },
    { lat: 48.9609364, lng: 21.76605 },
    { lat: 48.9613737, lng: 21.7662905 },
    { lat: 48.9616549, lng: 21.7663354 },
    { lat: 48.9620572, lng: 21.7661867 },
    { lat: 48.9624948, lng: 21.7663609 },
    { lat: 48.9631174, lng: 21.7667187 },
    { lat: 48.9635031, lng: 21.767059 },
    { lat: 48.9646301, lng: 21.7669618 },
    { lat: 48.9655865, lng: 21.7665052 },
    { lat: 48.9658493, lng: 21.7667012 },
    { lat: 48.9661734, lng: 21.7672536 },
    { lat: 48.9664056, lng: 21.767362 },
    { lat: 48.967084, lng: 21.7673233 },
    { lat: 48.9674441, lng: 21.7670225 },
    { lat: 48.9679159, lng: 21.7669096 },
    { lat: 48.969003, lng: 21.7668901 },
    { lat: 48.9695764, lng: 21.7669537 },
    { lat: 48.9700731, lng: 21.766859 },
    { lat: 48.9706245, lng: 21.7666071 },
    { lat: 48.9713984, lng: 21.7666865 },
    { lat: 48.9717254, lng: 21.7666702 },
    { lat: 48.9719943, lng: 21.7665442 },
    { lat: 48.9726578, lng: 21.7666203 },
    { lat: 48.9730989, lng: 21.7664491 },
    { lat: 48.9734719, lng: 21.7660959 },
    { lat: 48.9746392, lng: 21.7658706 },
    { lat: 48.9754436, lng: 21.7658733 },
    { lat: 48.9758198, lng: 21.7659703 },
    { lat: 48.9778063, lng: 21.7668204 },
    { lat: 48.9785758, lng: 21.7676367 },
    { lat: 48.9789603, lng: 21.7681745 },
    { lat: 48.9792316, lng: 21.7688259 },
    { lat: 48.9795399, lng: 21.7705012 },
    { lat: 48.9798697, lng: 21.7711018 },
    { lat: 48.980645, lng: 21.7720518 },
    { lat: 48.9808412, lng: 21.77249 },
    { lat: 48.9813746, lng: 21.7732692 },
    { lat: 48.981303, lng: 21.774189 },
    { lat: 48.9813718, lng: 21.7744111 },
    { lat: 48.9813467, lng: 21.7751229 },
    { lat: 48.9817039, lng: 21.7753594 },
    { lat: 48.981682, lng: 21.7756387 },
    { lat: 48.9814946, lng: 21.7763051 },
    { lat: 48.9818212, lng: 21.7768286 },
    { lat: 48.9820692, lng: 21.7774919 },
    { lat: 48.9819684, lng: 21.7779826 },
    { lat: 48.981475, lng: 21.7797076 },
    { lat: 48.9814923, lng: 21.7801979 },
    { lat: 48.9812912, lng: 21.7814542 },
    { lat: 48.9811438, lng: 21.7819298 },
    { lat: 48.9804012, lng: 21.7826459 },
    { lat: 48.9805925, lng: 21.7835955 },
    { lat: 48.9810447, lng: 21.7840646 },
    { lat: 48.980786, lng: 21.785347 },
    { lat: 48.9812, lng: 21.785217 },
    { lat: 48.981366, lng: 21.785081 },
    { lat: 48.981481, lng: 21.784959 },
    { lat: 48.982548, lng: 21.783969 },
    { lat: 48.983235, lng: 21.782 },
    { lat: 48.983671, lng: 21.780952 },
    { lat: 48.984169, lng: 21.779422 },
    { lat: 48.984394, lng: 21.779057 },
    { lat: 48.984835, lng: 21.778584 },
    { lat: 48.986021, lng: 21.77705 },
    { lat: 48.986662, lng: 21.775873 },
    { lat: 48.986971, lng: 21.775232 },
    { lat: 48.987453, lng: 21.774439 },
    { lat: 48.988509, lng: 21.774526 },
    { lat: 48.989879, lng: 21.77454 },
    { lat: 48.991205, lng: 21.774427 },
    { lat: 48.991368, lng: 21.774409 },
    { lat: 48.991828, lng: 21.774448 },
    { lat: 48.9915727, lng: 21.7738016 },
    { lat: 48.9913029, lng: 21.7736793 },
    { lat: 48.9905446, lng: 21.7735924 },
    { lat: 48.9902608, lng: 21.7734474 },
    { lat: 48.9900466, lng: 21.7730925 },
    { lat: 48.9900204, lng: 21.7729032 },
    { lat: 48.9900512, lng: 21.7720656 },
    { lat: 48.989804, lng: 21.7716941 },
    { lat: 48.9889102, lng: 21.7706821 },
    { lat: 48.9887568, lng: 21.7703282 },
    { lat: 48.988201, lng: 21.7694693 },
    { lat: 48.9873182, lng: 21.7685379 },
    { lat: 48.9869053, lng: 21.7682389 },
    { lat: 48.9868354, lng: 21.7675511 },
    { lat: 48.9864899, lng: 21.7659617 },
    { lat: 48.9861112, lng: 21.7647867 },
    { lat: 48.9859381, lng: 21.7639347 },
    { lat: 48.9856204, lng: 21.7616137 },
    { lat: 48.9854293, lng: 21.7609373 },
    { lat: 48.9853528, lng: 21.7604685 },
    { lat: 48.9853783, lng: 21.7589672 },
    { lat: 48.9852614, lng: 21.7571401 },
    { lat: 48.9848769, lng: 21.7569347 },
    { lat: 48.9847702, lng: 21.755919 },
    { lat: 48.9847751, lng: 21.7552496 },
    { lat: 48.9850156, lng: 21.7543055 },
    { lat: 48.9850021, lng: 21.753537 },
    { lat: 48.9854598, lng: 21.7518753 },
    { lat: 48.9856837, lng: 21.7512839 },
    { lat: 48.9859289, lng: 21.7498806 },
    { lat: 48.9863526, lng: 21.7493941 },
    { lat: 48.9864401, lng: 21.748912 },
    { lat: 48.9865063, lng: 21.7478871 },
    { lat: 48.9866912, lng: 21.7474023 },
    { lat: 48.9868873, lng: 21.746647 },
    { lat: 48.9868207, lng: 21.7459988 },
    { lat: 48.9868265, lng: 21.7451152 },
    { lat: 48.9872037, lng: 21.7450455 },
    { lat: 48.9874602, lng: 21.7450929 },
    { lat: 48.9882332, lng: 21.7449223 },
    { lat: 48.9887845, lng: 21.7446271 },
    { lat: 48.9890989, lng: 21.7446963 },
    { lat: 48.9892943, lng: 21.7444633 },
    { lat: 48.9894125, lng: 21.7444675 },
    { lat: 48.9895384, lng: 21.7443427 },
    { lat: 48.989534, lng: 21.7442577 },
    { lat: 48.9896143, lng: 21.7442352 },
    { lat: 48.9898823, lng: 21.7439415 },
    { lat: 48.990034, lng: 21.7436446 },
    { lat: 48.9901004, lng: 21.7436395 },
    { lat: 48.9905428, lng: 21.7426334 },
    { lat: 48.9905879, lng: 21.7416233 },
    { lat: 48.9907755, lng: 21.7411866 },
    { lat: 48.9908797, lng: 21.7407518 },
    { lat: 48.99082, lng: 21.7401572 },
    { lat: 48.9912569, lng: 21.7390221 },
    { lat: 48.9913691, lng: 21.7376991 },
    { lat: 48.9918819, lng: 21.7373144 },
    { lat: 48.9914847, lng: 21.7357873 },
    { lat: 48.991229, lng: 21.7355153 },
    { lat: 48.9910227, lng: 21.7346579 },
    { lat: 48.9912908, lng: 21.7346335 },
    { lat: 48.9915903, lng: 21.7342565 },
    { lat: 48.9911885, lng: 21.7337009 },
    { lat: 48.9908882, lng: 21.7335335 },
    { lat: 48.9906754, lng: 21.7324747 },
    { lat: 48.9907624, lng: 21.7319797 },
    { lat: 48.9903229, lng: 21.7306835 },
    { lat: 48.990348, lng: 21.7303176 },
    { lat: 48.990228, lng: 21.7293166 },
    { lat: 48.9902174, lng: 21.7287087 },
    { lat: 48.9902668, lng: 21.7286684 },
    { lat: 48.9904285, lng: 21.7287394 },
    { lat: 48.9905446, lng: 21.7286631 },
    { lat: 48.9906271, lng: 21.7283807 },
    { lat: 48.990511, lng: 21.7281171 },
    { lat: 48.9902424, lng: 21.7282586 },
    { lat: 48.9898493, lng: 21.7273518 },
    { lat: 48.9896579, lng: 21.7267135 },
    { lat: 48.989453, lng: 21.7265824 },
    { lat: 48.9890751, lng: 21.7257858 },
    { lat: 48.98893, lng: 21.7258255 },
    { lat: 48.9885973, lng: 21.7263876 },
    { lat: 48.9885239, lng: 21.7263739 },
    { lat: 48.9884736, lng: 21.7259203 },
    { lat: 48.988169, lng: 21.725593 },
    { lat: 48.9879101, lng: 21.7250878 },
    { lat: 48.9876894, lng: 21.7251999 },
    { lat: 48.9873586, lng: 21.7251984 },
    { lat: 48.9871579, lng: 21.7248515 },
    { lat: 48.9874817, lng: 21.7243348 },
    { lat: 48.9874712, lng: 21.723686 },
    { lat: 48.9872364, lng: 21.7232799 },
    { lat: 48.9869708, lng: 21.7230173 },
    { lat: 48.9865386, lng: 21.7231045 },
    { lat: 48.9861921, lng: 21.7237731 },
    { lat: 48.9860661, lng: 21.7236924 },
    { lat: 48.9860945, lng: 21.7234489 },
    { lat: 48.9865759, lng: 21.7228135 },
    { lat: 48.986519, lng: 21.7225369 },
    { lat: 48.986, lng: 21.7218855 },
    { lat: 48.9855978, lng: 21.7217373 },
    { lat: 48.9849753, lng: 21.722314 },
    { lat: 48.9848717, lng: 21.7222638 },
    { lat: 48.9843703, lng: 21.7214362 },
    { lat: 48.9836045, lng: 21.7212829 },
    { lat: 48.9831048, lng: 21.7217772 },
    { lat: 48.9826131, lng: 21.721358 },
    { lat: 48.9828702, lng: 21.7207436 },
    { lat: 48.9827667, lng: 21.720408 },
    { lat: 48.9824984, lng: 21.7201778 },
    { lat: 48.9820082, lng: 21.7201478 },
    { lat: 48.9817975, lng: 21.7202995 },
    { lat: 48.9812302, lng: 21.7202528 },
    { lat: 48.9809645, lng: 21.7201195 },
    { lat: 48.9806735, lng: 21.7204698 },
    { lat: 48.9808431, lng: 21.7210016 },
    { lat: 48.9806759, lng: 21.7211845 },
    { lat: 48.9804757, lng: 21.7210698 },
    { lat: 48.9803849, lng: 21.7211808 },
    { lat: 48.9799975, lng: 21.7216544 },
    { lat: 48.9798942, lng: 21.7215279 },
    { lat: 48.9799221, lng: 21.7211433 },
    { lat: 48.9796937, lng: 21.7205554 },
    { lat: 48.9794223, lng: 21.7202104 },
    { lat: 48.9791517, lng: 21.7202719 },
    { lat: 48.9789339, lng: 21.7205815 },
    { lat: 48.9790194, lng: 21.7214594 },
    { lat: 48.9788148, lng: 21.721848 },
    { lat: 48.9784489, lng: 21.7220966 },
    { lat: 48.9782209, lng: 21.7227814 },
    { lat: 48.9776075, lng: 21.7234861 },
    { lat: 48.9765918, lng: 21.72387 },
    { lat: 48.9759445, lng: 21.7237648 },
    { lat: 48.9755256, lng: 21.7246394 },
    { lat: 48.9756211, lng: 21.7250926 },
    { lat: 48.9754392, lng: 21.7252356 },
    { lat: 48.9751742, lng: 21.7249973 },
    { lat: 48.974654, lng: 21.725698 },
    { lat: 48.9747101, lng: 21.7260347 },
    { lat: 48.9739448, lng: 21.7262322 },
    { lat: 48.9735312, lng: 21.7264153 },
    { lat: 48.9730554, lng: 21.7264192 },
    { lat: 48.9729039, lng: 21.72593 },
    { lat: 48.9729644, lng: 21.7258289 },
    { lat: 48.9727835, lng: 21.7249949 },
    { lat: 48.9728294, lng: 21.7245244 },
    { lat: 48.9727938, lng: 21.724329 },
    { lat: 48.9725081, lng: 21.7242087 },
    { lat: 48.9723383, lng: 21.7242926 },
    { lat: 48.9720048, lng: 21.7249065 },
    { lat: 48.971465, lng: 21.7250375 },
    { lat: 48.9715653, lng: 21.7244605 },
    { lat: 48.9715024, lng: 21.7233239 },
    { lat: 48.9707142, lng: 21.7237163 },
    { lat: 48.9707267, lng: 21.7241036 },
    { lat: 48.9705191, lng: 21.7242063 },
    { lat: 48.9704048, lng: 21.7243931 },
    { lat: 48.9706052, lng: 21.7250733 },
    { lat: 48.97041, lng: 21.7255154 },
    { lat: 48.9703325, lng: 21.7254374 },
    { lat: 48.9700546, lng: 21.7248823 },
    { lat: 48.9698918, lng: 21.7246804 },
    { lat: 48.969636, lng: 21.7245411 },
    { lat: 48.969502, lng: 21.7243696 },
    { lat: 48.9693715, lng: 21.7238244 },
    { lat: 48.9693792, lng: 21.7234351 },
    { lat: 48.9692608, lng: 21.7230439 },
    { lat: 48.9690437, lng: 21.7228417 },
    { lat: 48.9689488, lng: 21.7226159 },
    { lat: 48.9686624, lng: 21.7215744 },
    { lat: 48.9687731, lng: 21.7215557 },
    { lat: 48.9687036, lng: 21.7209284 },
    { lat: 48.9677384, lng: 21.7216485 },
    { lat: 48.9675499, lng: 21.7217049 },
    { lat: 48.9674145, lng: 21.7211028 },
    { lat: 48.9668494, lng: 21.7213417 },
    { lat: 48.9668825, lng: 21.7215305 },
    { lat: 48.9664673, lng: 21.7217742 },
    { lat: 48.9663833, lng: 21.7213748 },
    { lat: 48.9657824, lng: 21.7216206 },
    { lat: 48.9658888, lng: 21.7224812 },
    { lat: 48.9654193, lng: 21.7226774 },
    { lat: 48.9652449, lng: 21.7218417 },
    { lat: 48.9649354, lng: 21.7219152 },
    { lat: 48.9648378, lng: 21.7226445 },
    { lat: 48.964834, lng: 21.7233429 },
    { lat: 48.9653201, lng: 21.7230508 },
    { lat: 48.96565, lng: 21.7229396 },
    { lat: 48.9658881, lng: 21.7230011 },
    { lat: 48.9660855, lng: 21.7233646 },
    { lat: 48.9657301, lng: 21.7233689 },
    { lat: 48.9654984, lng: 21.7234179 },
    { lat: 48.9648745, lng: 21.7235499 },
    { lat: 48.9644147, lng: 21.7242373 },
    { lat: 48.9643083, lng: 21.724506 },
    { lat: 48.9621181, lng: 21.7243857 },
    { lat: 48.9623454, lng: 21.7254912 },
    { lat: 48.9620014, lng: 21.7256663 },
    { lat: 48.9618379, lng: 21.7250144 },
    { lat: 48.9616774, lng: 21.7251142 },
    { lat: 48.9617724, lng: 21.7256783 },
    { lat: 48.961477, lng: 21.7258279 },
    { lat: 48.9611459, lng: 21.724931 },
    { lat: 48.9610877, lng: 21.7246407 },
    { lat: 48.960099, lng: 21.7251541 },
    { lat: 48.9602416, lng: 21.7258234 },
    { lat: 48.9604547, lng: 21.7258414 },
    { lat: 48.9605658, lng: 21.7259162 },
    { lat: 48.9606702, lng: 21.7274242 },
    { lat: 48.9597825, lng: 21.7277999 },
    { lat: 48.9596575, lng: 21.7259754 },
    { lat: 48.9595104, lng: 21.7248109 },
    { lat: 48.9592973, lng: 21.7251738 },
    { lat: 48.95906, lng: 21.7254585 },
    { lat: 48.9585408, lng: 21.7260817 },
    { lat: 48.9585986, lng: 21.7265632 },
    { lat: 48.9588345, lng: 21.7292022 },
    { lat: 48.9589743, lng: 21.7292183 },
    { lat: 48.9590749, lng: 21.729612 },
    { lat: 48.9592471, lng: 21.7299093 },
    { lat: 48.95914, lng: 21.730412 },
    { lat: 48.9592045, lng: 21.731476 },
    { lat: 48.9590773, lng: 21.731985 },
    { lat: 48.9590688, lng: 21.7323811 },
    { lat: 48.9589149, lng: 21.7326845 },
    { lat: 48.9589227, lng: 21.7328097 },
    { lat: 48.9592507, lng: 21.7332116 },
    { lat: 48.9593942, lng: 21.7335282 },
    { lat: 48.9596098, lng: 21.7337709 },
    { lat: 48.9596264, lng: 21.7340155 },
    { lat: 48.9594525, lng: 21.7341132 },
    { lat: 48.9595041, lng: 21.7344039 },
    { lat: 48.9596897, lng: 21.7347784 },
    { lat: 48.9596503, lng: 21.7351242 },
    { lat: 48.9598461, lng: 21.7354576 },
    { lat: 48.9598752, lng: 21.7360466 },
    { lat: 48.9599666, lng: 21.7364759 },
    { lat: 48.9603456, lng: 21.7375274 },
    { lat: 48.960327, lng: 21.7378923 },
    { lat: 48.9604373, lng: 21.7387 },
    { lat: 48.9606592, lng: 21.7392173 },
    { lat: 48.960544, lng: 21.7397069 },
    { lat: 48.9602174, lng: 21.740671 },
    { lat: 48.9601499, lng: 21.7411721 },
    { lat: 48.9598312, lng: 21.7415002 },
    { lat: 48.9597248, lng: 21.7416861 },
    { lat: 48.9596885, lng: 21.7420134 },
    { lat: 48.9595618, lng: 21.742329 },
    { lat: 48.9595843, lng: 21.742706 },
    { lat: 48.9596704, lng: 21.7429814 },
    { lat: 48.9596376, lng: 21.7432308 },
    { lat: 48.9597338, lng: 21.7435521 },
    { lat: 48.9597502, lng: 21.7440867 },
    { lat: 48.9601041, lng: 21.7450839 },
    { lat: 48.9603104, lng: 21.7462427 },
    { lat: 48.9603398, lng: 21.7468444 },
    { lat: 48.9602703, lng: 21.7469772 },
    { lat: 48.9599288, lng: 21.7470425 },
    { lat: 48.9569253, lng: 21.750148 },
    { lat: 48.9562938, lng: 21.7500892 },
    { lat: 48.9557375, lng: 21.7503205 },
    { lat: 48.9548022, lng: 21.74891 },
    { lat: 48.9544439, lng: 21.748459 },
    { lat: 48.9535438, lng: 21.7496466 },
    { lat: 48.9529194, lng: 21.7526135 },
    { lat: 48.9525924, lng: 21.753184 },
    { lat: 48.9522523, lng: 21.7539328 },
    { lat: 48.9521513, lng: 21.7546237 },
    { lat: 48.9520335, lng: 21.7550192 },
    { lat: 48.9516422, lng: 21.7555385 },
    { lat: 48.9514023, lng: 21.7559512 },
    { lat: 48.9512261, lng: 21.7563911 },
    { lat: 48.9512931, lng: 21.7565456 },
    { lat: 48.951142, lng: 21.7570004 },
    { lat: 48.9512315, lng: 21.7571499 },
    { lat: 48.9513461, lng: 21.7571873 },
    { lat: 48.9511718, lng: 21.7582972 },
    { lat: 48.9510251, lng: 21.7585824 },
    { lat: 48.9510253, lng: 21.7588033 },
    { lat: 48.9508973, lng: 21.758892 },
    { lat: 48.9508236, lng: 21.7590371 },
    { lat: 48.9505864, lng: 21.7596869 },
    { lat: 48.9500704, lng: 21.7619128 },
    { lat: 48.9495386, lng: 21.7637609 },
    { lat: 48.9508864, lng: 21.7640136 },
    { lat: 48.9517071, lng: 21.7640828 },
    { lat: 48.9527891, lng: 21.7640342 },
  ],
  Sačurov: [
    { lat: 48.8100595, lng: 21.6591628 },
    { lat: 48.810076, lng: 21.6591882 },
    { lat: 48.809657, lng: 21.6603221 },
    { lat: 48.8095629, lng: 21.6603833 },
    { lat: 48.8095015, lng: 21.6607234 },
    { lat: 48.8093952, lng: 21.6608711 },
    { lat: 48.80933, lng: 21.6614113 },
    { lat: 48.80913, lng: 21.6616641 },
    { lat: 48.809062, lng: 21.6619796 },
    { lat: 48.8087828, lng: 21.6625036 },
    { lat: 48.8087888, lng: 21.6626475 },
    { lat: 48.8087312, lng: 21.6628006 },
    { lat: 48.8087783, lng: 21.6629597 },
    { lat: 48.8085407, lng: 21.6634044 },
    { lat: 48.8085906, lng: 21.6635801 },
    { lat: 48.8085554, lng: 21.6639403 },
    { lat: 48.8085756, lng: 21.6644713 },
    { lat: 48.8085066, lng: 21.6648311 },
    { lat: 48.808697, lng: 21.6655845 },
    { lat: 48.8086682, lng: 21.6656805 },
    { lat: 48.8086972, lng: 21.6658343 },
    { lat: 48.8086639, lng: 21.6660231 },
    { lat: 48.8086144, lng: 21.6660562 },
    { lat: 48.8086471, lng: 21.6664364 },
    { lat: 48.8085498, lng: 21.6665421 },
    { lat: 48.8085548, lng: 21.6669244 },
    { lat: 48.8083456, lng: 21.6670925 },
    { lat: 48.8082105, lng: 21.6679565 },
    { lat: 48.8080286, lng: 21.6682966 },
    { lat: 48.8080316, lng: 21.6685356 },
    { lat: 48.8078358, lng: 21.6685742 },
    { lat: 48.8078011, lng: 21.6687024 },
    { lat: 48.807902, lng: 21.669058 },
    { lat: 48.8074037, lng: 21.6696396 },
    { lat: 48.8073544, lng: 21.6698558 },
    { lat: 48.8074126, lng: 21.6702169 },
    { lat: 48.807096, lng: 21.6707803 },
    { lat: 48.8070277, lng: 21.6712393 },
    { lat: 48.8068501, lng: 21.6712461 },
    { lat: 48.8066906, lng: 21.6714685 },
    { lat: 48.8066048, lng: 21.6714489 },
    { lat: 48.8064522, lng: 21.671639 },
    { lat: 48.8062825, lng: 21.6716407 },
    { lat: 48.8063196, lng: 21.6718058 },
    { lat: 48.8060611, lng: 21.6722292 },
    { lat: 48.8060222, lng: 21.6726592 },
    { lat: 48.8059765, lng: 21.6727134 },
    { lat: 48.8056957, lng: 21.6727295 },
    { lat: 48.8056691, lng: 21.6728693 },
    { lat: 48.8055031, lng: 21.6729746 },
    { lat: 48.8054298, lng: 21.6731723 },
    { lat: 48.8053995, lng: 21.6738777 },
    { lat: 48.805483, lng: 21.6742184 },
    { lat: 48.8053856, lng: 21.6743647 },
    { lat: 48.8054099, lng: 21.6745466 },
    { lat: 48.8053312, lng: 21.6747554 },
    { lat: 48.8052346, lng: 21.6747239 },
    { lat: 48.8052159, lng: 21.6749449 },
    { lat: 48.8053191, lng: 21.6749771 },
    { lat: 48.8053864, lng: 21.6751731 },
    { lat: 48.8053414, lng: 21.6753801 },
    { lat: 48.8052229, lng: 21.6753236 },
    { lat: 48.8049624, lng: 21.6758002 },
    { lat: 48.8049794, lng: 21.6758863 },
    { lat: 48.80474, lng: 21.6761874 },
    { lat: 48.8045921, lng: 21.6762463 },
    { lat: 48.8043798, lng: 21.6766746 },
    { lat: 48.8043161, lng: 21.6771486 },
    { lat: 48.8041523, lng: 21.6776957 },
    { lat: 48.8039739, lng: 21.6780091 },
    { lat: 48.8040307, lng: 21.6781055 },
    { lat: 48.804057, lng: 21.6784575 },
    { lat: 48.8041621, lng: 21.6785934 },
    { lat: 48.804148, lng: 21.6799819 },
    { lat: 48.8040989, lng: 21.6801638 },
    { lat: 48.8041154, lng: 21.6803944 },
    { lat: 48.8042269, lng: 21.6805695 },
    { lat: 48.804191, lng: 21.6810268 },
    { lat: 48.8042127, lng: 21.6814073 },
    { lat: 48.8042576, lng: 21.6814909 },
    { lat: 48.8042458, lng: 21.682506 },
    { lat: 48.8040073, lng: 21.6826729 },
    { lat: 48.8039485, lng: 21.6829039 },
    { lat: 48.8041146, lng: 21.6832198 },
    { lat: 48.8040685, lng: 21.6834339 },
    { lat: 48.803961, lng: 21.6834074 },
    { lat: 48.803908, lng: 21.684112 },
    { lat: 48.8023758, lng: 21.6887617 },
    { lat: 48.8021308, lng: 21.6898894 },
    { lat: 48.8020761, lng: 21.690447 },
    { lat: 48.8019966, lng: 21.6927432 },
    { lat: 48.8020431, lng: 21.6932582 },
    { lat: 48.8021505, lng: 21.6937326 },
    { lat: 48.8023571, lng: 21.6942591 },
    { lat: 48.804606, lng: 21.6978693 },
    { lat: 48.8047827, lng: 21.698292 },
    { lat: 48.8048596, lng: 21.6987652 },
    { lat: 48.8048179, lng: 21.6994733 },
    { lat: 48.8048699, lng: 21.6995187 },
    { lat: 48.8047918, lng: 21.6999639 },
    { lat: 48.8047332, lng: 21.7000011 },
    { lat: 48.8043379, lng: 21.7018968 },
    { lat: 48.8041313, lng: 21.7024089 },
    { lat: 48.803822, lng: 21.7028381 },
    { lat: 48.803623, lng: 21.7030028 },
    { lat: 48.8030047, lng: 21.7031909 },
    { lat: 48.8030148, lng: 21.7032753 },
    { lat: 48.8026523, lng: 21.7039362 },
    { lat: 48.8023385, lng: 21.7041433 },
    { lat: 48.8017326, lng: 21.704827 },
    { lat: 48.8014837, lng: 21.7054834 },
    { lat: 48.8018826, lng: 21.7114876 },
    { lat: 48.8018434, lng: 21.7119932 },
    { lat: 48.8026508, lng: 21.724375 },
    { lat: 48.8028969, lng: 21.7244455 },
    { lat: 48.8030115, lng: 21.7246134 },
    { lat: 48.8031079, lng: 21.7250175 },
    { lat: 48.803949, lng: 21.7250058 },
    { lat: 48.8040607, lng: 21.7256468 },
    { lat: 48.8042146, lng: 21.7255916 },
    { lat: 48.8043137, lng: 21.7257633 },
    { lat: 48.8045208, lng: 21.7257217 },
    { lat: 48.8047937, lng: 21.7255439 },
    { lat: 48.8052437, lng: 21.7253743 },
    { lat: 48.8055248, lng: 21.7253227 },
    { lat: 48.8058296, lng: 21.7255339 },
    { lat: 48.8062654, lng: 21.7257152 },
    { lat: 48.8069962, lng: 21.7255269 },
    { lat: 48.8071293, lng: 21.7257107 },
    { lat: 48.8149257, lng: 21.7218871 },
    { lat: 48.8153189, lng: 21.7216488 },
    { lat: 48.8158099, lng: 21.7214612 },
    { lat: 48.8158758, lng: 21.7213611 },
    { lat: 48.81849, lng: 21.7200379 },
    { lat: 48.8187267, lng: 21.7211129 },
    { lat: 48.8193111, lng: 21.721172 },
    { lat: 48.8196607, lng: 21.7217859 },
    { lat: 48.8201441, lng: 21.7223617 },
    { lat: 48.8207654, lng: 21.7217426 },
    { lat: 48.8209288, lng: 21.7217497 },
    { lat: 48.8213849, lng: 21.7215553 },
    { lat: 48.8214305, lng: 21.7213726 },
    { lat: 48.8218962, lng: 21.7214111 },
    { lat: 48.8224531, lng: 21.7217056 },
    { lat: 48.8226842, lng: 21.721559 },
    { lat: 48.8229244, lng: 21.7216174 },
    { lat: 48.8236165, lng: 21.7219583 },
    { lat: 48.8236411, lng: 21.7223738 },
    { lat: 48.8247076, lng: 21.7232969 },
    { lat: 48.8247904, lng: 21.7231918 },
    { lat: 48.8252636, lng: 21.7235987 },
    { lat: 48.8259779, lng: 21.7245595 },
    { lat: 48.8264654, lng: 21.7242825 },
    { lat: 48.8265761, lng: 21.7240415 },
    { lat: 48.8269441, lng: 21.7244277 },
    { lat: 48.8264893, lng: 21.72551 },
    { lat: 48.8267207, lng: 21.72592 },
    { lat: 48.8273609, lng: 21.7249127 },
    { lat: 48.8276973, lng: 21.7245788 },
    { lat: 48.8279113, lng: 21.725041 },
    { lat: 48.8282435, lng: 21.7263181 },
    { lat: 48.8287595, lng: 21.7259647 },
    { lat: 48.8284703, lng: 21.7251241 },
    { lat: 48.828248, lng: 21.7246375 },
    { lat: 48.8286026, lng: 21.7240162 },
    { lat: 48.8295883, lng: 21.722889 },
    { lat: 48.8302704, lng: 21.7222686 },
    { lat: 48.8300461, lng: 21.7211219 },
    { lat: 48.8311911, lng: 21.7206122 },
    { lat: 48.8313907, lng: 21.720581 },
    { lat: 48.8316898, lng: 21.7206233 },
    { lat: 48.8322838, lng: 21.7209539 },
    { lat: 48.832903, lng: 21.7211779 },
    { lat: 48.8328559, lng: 21.7205994 },
    { lat: 48.8331821, lng: 21.7204279 },
    { lat: 48.8331895, lng: 21.7202839 },
    { lat: 48.8341003, lng: 21.7198486 },
    { lat: 48.8342507, lng: 21.7204618 },
    { lat: 48.8347789, lng: 21.7200622 },
    { lat: 48.8347692, lng: 21.7199992 },
    { lat: 48.8346836, lng: 21.7199545 },
    { lat: 48.8348247, lng: 21.7174978 },
    { lat: 48.8349941, lng: 21.7174795 },
    { lat: 48.8348723, lng: 21.7161646 },
    { lat: 48.8360291, lng: 21.7159705 },
    { lat: 48.8360974, lng: 21.7154509 },
    { lat: 48.8374087, lng: 21.715478 },
    { lat: 48.8374923, lng: 21.7146503 },
    { lat: 48.8378901, lng: 21.7137944 },
    { lat: 48.8375793, lng: 21.7122829 },
    { lat: 48.8436693, lng: 21.7093579 },
    { lat: 48.84256, lng: 21.7044263 },
    { lat: 48.842502, lng: 21.7043192 },
    { lat: 48.8445434, lng: 21.7032967 },
    { lat: 48.847789, lng: 21.7033762 },
    { lat: 48.8479795, lng: 21.703308 },
    { lat: 48.8480055, lng: 21.7030188 },
    { lat: 48.8480683, lng: 21.7030167 },
    { lat: 48.8482304, lng: 21.6995174 },
    { lat: 48.84763, lng: 21.697317 },
    { lat: 48.847375, lng: 21.6960826 },
    { lat: 48.8470258, lng: 21.6950666 },
    { lat: 48.8469626, lng: 21.6944729 },
    { lat: 48.8466264, lng: 21.6929463 },
    { lat: 48.8462955, lng: 21.691862 },
    { lat: 48.8457297, lng: 21.6919858 },
    { lat: 48.8447216, lng: 21.6923588 },
    { lat: 48.8440357, lng: 21.6923299 },
    { lat: 48.8436029, lng: 21.6924715 },
    { lat: 48.8436584, lng: 21.6921955 },
    { lat: 48.8434868, lng: 21.6916311 },
    { lat: 48.8429701, lng: 21.691825 },
    { lat: 48.8426627, lng: 21.6906572 },
    { lat: 48.843685, lng: 21.6898939 },
    { lat: 48.8434382, lng: 21.6891306 },
    { lat: 48.8434715, lng: 21.6891125 },
    { lat: 48.8433152, lng: 21.688496 },
    { lat: 48.8421022, lng: 21.6846675 },
    { lat: 48.8418708, lng: 21.6848016 },
    { lat: 48.8414253, lng: 21.6843867 },
    { lat: 48.8411843, lng: 21.6842989 },
    { lat: 48.8410776, lng: 21.6841391 },
    { lat: 48.8408862, lng: 21.6840935 },
    { lat: 48.8405733, lng: 21.6831368 },
    { lat: 48.8404673, lng: 21.6829577 },
    { lat: 48.8404665, lng: 21.6827203 },
    { lat: 48.8402008, lng: 21.6822611 },
    { lat: 48.8402326, lng: 21.6814855 },
    { lat: 48.8405518, lng: 21.6806437 },
    { lat: 48.8408974, lng: 21.6792035 },
    { lat: 48.8412261, lng: 21.6784651 },
    { lat: 48.8417571, lng: 21.676805 },
    { lat: 48.8418538, lng: 21.6762675 },
    { lat: 48.8420203, lng: 21.6759328 },
    { lat: 48.8422111, lng: 21.6747568 },
    { lat: 48.8421803, lng: 21.6741633 },
    { lat: 48.8423217, lng: 21.6737798 },
    { lat: 48.8422726, lng: 21.6733768 },
    { lat: 48.8423306, lng: 21.6731527 },
    { lat: 48.8422361, lng: 21.6727833 },
    { lat: 48.8421574, lng: 21.6714687 },
    { lat: 48.8422171, lng: 21.6706021 },
    { lat: 48.8423226, lng: 21.6703826 },
    { lat: 48.842188, lng: 21.6696995 },
    { lat: 48.8420473, lng: 21.6695293 },
    { lat: 48.8418824, lng: 21.6695061 },
    { lat: 48.841741, lng: 21.6693524 },
    { lat: 48.8416196, lng: 21.6691139 },
    { lat: 48.8408098, lng: 21.6685424 },
    { lat: 48.8405316, lng: 21.6687769 },
    { lat: 48.8401395, lng: 21.667686 },
    { lat: 48.8397841, lng: 21.6678536 },
    { lat: 48.8397448, lng: 21.6676936 },
    { lat: 48.8394721, lng: 21.6678439 },
    { lat: 48.8388503, lng: 21.6662895 },
    { lat: 48.8383357, lng: 21.6642116 },
    { lat: 48.837809, lng: 21.6627621 },
    { lat: 48.8376979, lng: 21.662319 },
    { lat: 48.8374772, lng: 21.6622739 },
    { lat: 48.8363301, lng: 21.6594922 },
    { lat: 48.8360421, lng: 21.6585471 },
    { lat: 48.8355296, lng: 21.65741 },
    { lat: 48.83436, lng: 21.6534006 },
    { lat: 48.8340323, lng: 21.6520755 },
    { lat: 48.8333059, lng: 21.6518516 },
    { lat: 48.8328808, lng: 21.6511523 },
    { lat: 48.8323488, lng: 21.6506658 },
    { lat: 48.8319037, lng: 21.6499463 },
    { lat: 48.8316945, lng: 21.6494599 },
    { lat: 48.8315506, lng: 21.649295 },
    { lat: 48.8313255, lng: 21.6492538 },
    { lat: 48.8311763, lng: 21.6490679 },
    { lat: 48.8309434, lng: 21.6491004 },
    { lat: 48.8308513, lng: 21.6491123 },
    { lat: 48.8289886, lng: 21.6493306 },
    { lat: 48.8283539, lng: 21.6496565 },
    { lat: 48.825292, lng: 21.650333 },
    { lat: 48.8252596, lng: 21.6504651 },
    { lat: 48.8241701, lng: 21.6508596 },
    { lat: 48.8236751, lng: 21.6512681 },
    { lat: 48.8230398, lng: 21.651658 },
    { lat: 48.8229084, lng: 21.6514138 },
    { lat: 48.8230817, lng: 21.650882 },
    { lat: 48.8230574, lng: 21.6506586 },
    { lat: 48.8232579, lng: 21.6502582 },
    { lat: 48.8232705, lng: 21.6499353 },
    { lat: 48.8230901, lng: 21.649098 },
    { lat: 48.8215151, lng: 21.6478753 },
    { lat: 48.8210935, lng: 21.6478461 },
    { lat: 48.8208316, lng: 21.6477294 },
    { lat: 48.8205863, lng: 21.647812 },
    { lat: 48.8201183, lng: 21.6481375 },
    { lat: 48.8198303, lng: 21.6481834 },
    { lat: 48.8193411, lng: 21.6480636 },
    { lat: 48.8188322, lng: 21.6478875 },
    { lat: 48.8183206, lng: 21.6476119 },
    { lat: 48.8150444, lng: 21.6456101 },
    { lat: 48.8143383, lng: 21.6455407 },
    { lat: 48.8141296, lng: 21.6458509 },
    { lat: 48.814074, lng: 21.6462028 },
    { lat: 48.8141629, lng: 21.6464532 },
    { lat: 48.8141408, lng: 21.6466684 },
    { lat: 48.8143175, lng: 21.6472835 },
    { lat: 48.8143739, lng: 21.6479029 },
    { lat: 48.8143477, lng: 21.6480123 },
    { lat: 48.8144717, lng: 21.6481919 },
    { lat: 48.814469, lng: 21.6487908 },
    { lat: 48.8145598, lng: 21.6492278 },
    { lat: 48.8146778, lng: 21.6493885 },
    { lat: 48.8147948, lng: 21.6505753 },
    { lat: 48.8147242, lng: 21.6508293 },
    { lat: 48.8147095, lng: 21.6511954 },
    { lat: 48.8147932, lng: 21.6516621 },
    { lat: 48.81473, lng: 21.6517392 },
    { lat: 48.8147288, lng: 21.6520196 },
    { lat: 48.8146606, lng: 21.652077 },
    { lat: 48.8144603, lng: 21.6529411 },
    { lat: 48.8145478, lng: 21.6532165 },
    { lat: 48.8145175, lng: 21.6533012 },
    { lat: 48.8146403, lng: 21.6535977 },
    { lat: 48.8145988, lng: 21.6536003 },
    { lat: 48.8145541, lng: 21.6539037 },
    { lat: 48.8146145, lng: 21.6542572 },
    { lat: 48.8144866, lng: 21.6544345 },
    { lat: 48.8132021, lng: 21.6544923 },
    { lat: 48.8112022, lng: 21.6543402 },
    { lat: 48.8110291, lng: 21.6545528 },
    { lat: 48.8108904, lng: 21.6558594 },
    { lat: 48.8108626, lng: 21.6566094 },
    { lat: 48.8106874, lng: 21.6573972 },
    { lat: 48.8106404, lng: 21.6580227 },
    { lat: 48.8105508, lng: 21.6582817 },
    { lat: 48.8102093, lng: 21.6586751 },
    { lat: 48.8100595, lng: 21.6591628 },
  ],
  Benkovce: [
    { lat: 48.9592807, lng: 21.6857512 },
    { lat: 48.9585976, lng: 21.6847012 },
    { lat: 48.9583011, lng: 21.6845117 },
    { lat: 48.9575653, lng: 21.6843865 },
    { lat: 48.9560003, lng: 21.684472 },
    { lat: 48.95599, lng: 21.6850812 },
    { lat: 48.9552464, lng: 21.6847142 },
    { lat: 48.9545999, lng: 21.6848497 },
    { lat: 48.9543709, lng: 21.684787 },
    { lat: 48.9542453, lng: 21.6845529 },
    { lat: 48.953824, lng: 21.684325 },
    { lat: 48.9526697, lng: 21.6848198 },
    { lat: 48.9508838, lng: 21.6844677 },
    { lat: 48.9493434, lng: 21.6842578 },
    { lat: 48.9491223, lng: 21.6848951 },
    { lat: 48.9486596, lng: 21.6851337 },
    { lat: 48.9483909, lng: 21.6854304 },
    { lat: 48.9480296, lng: 21.6859644 },
    { lat: 48.9472964, lng: 21.6874383 },
    { lat: 48.9471213, lng: 21.687913 },
    { lat: 48.9470012, lng: 21.6885888 },
    { lat: 48.9469131, lng: 21.6888602 },
    { lat: 48.9466686, lng: 21.6892598 },
    { lat: 48.9460507, lng: 21.6917569 },
    { lat: 48.9457408, lng: 21.6926534 },
    { lat: 48.9453005, lng: 21.6934306 },
    { lat: 48.9448111, lng: 21.6940516 },
    { lat: 48.9444688, lng: 21.6942107 },
    { lat: 48.944153, lng: 21.6944752 },
    { lat: 48.9422938, lng: 21.6970756 },
    { lat: 48.9419153, lng: 21.6974291 },
    { lat: 48.9412524, lng: 21.6978765 },
    { lat: 48.9408447, lng: 21.6983628 },
    { lat: 48.9405162, lng: 21.6984028 },
    { lat: 48.9402523, lng: 21.6986354 },
    { lat: 48.9397835, lng: 21.6984394 },
    { lat: 48.9394839, lng: 21.6981782 },
    { lat: 48.939038, lng: 21.6981994 },
    { lat: 48.9380903, lng: 21.6985575 },
    { lat: 48.9383186, lng: 21.6991831 },
    { lat: 48.9384783, lng: 21.7000643 },
    { lat: 48.9384725, lng: 21.700347 },
    { lat: 48.9382764, lng: 21.700838 },
    { lat: 48.938031, lng: 21.7012501 },
    { lat: 48.9378332, lng: 21.7014595 },
    { lat: 48.9368902, lng: 21.70217 },
    { lat: 48.9364798, lng: 21.7023791 },
    { lat: 48.9360797, lng: 21.7023971 },
    { lat: 48.9355543, lng: 21.7023098 },
    { lat: 48.9349988, lng: 21.7025289 },
    { lat: 48.9344192, lng: 21.7024793 },
    { lat: 48.9336151, lng: 21.7029962 },
    { lat: 48.933237, lng: 21.7034089 },
    { lat: 48.9330869, lng: 21.7039399 },
    { lat: 48.9330989, lng: 21.7041314 },
    { lat: 48.9329842, lng: 21.7043786 },
    { lat: 48.932808, lng: 21.7044024 },
    { lat: 48.9320372, lng: 21.705018 },
    { lat: 48.9314577, lng: 21.7057706 },
    { lat: 48.929225, lng: 21.7082571 },
    { lat: 48.9286223, lng: 21.7086454 },
    { lat: 48.9282539, lng: 21.7088775 },
    { lat: 48.9282831, lng: 21.7089197 },
    { lat: 48.9282454, lng: 21.7089389 },
    { lat: 48.9285286, lng: 21.7093897 },
    { lat: 48.928582, lng: 21.7096558 },
    { lat: 48.9288138, lng: 21.7101574 },
    { lat: 48.9288604, lng: 21.7109359 },
    { lat: 48.9290328, lng: 21.7114057 },
    { lat: 48.9293552, lng: 21.7129812 },
    { lat: 48.9293325, lng: 21.7135171 },
    { lat: 48.9292322, lng: 21.7138894 },
    { lat: 48.9290995, lng: 21.7152707 },
    { lat: 48.9283225, lng: 21.7157896 },
    { lat: 48.9278434, lng: 21.7159641 },
    { lat: 48.9276285, lng: 21.7159666 },
    { lat: 48.927208, lng: 21.7158491 },
    { lat: 48.9268779, lng: 21.7159146 },
    { lat: 48.9264256, lng: 21.7163328 },
    { lat: 48.925776, lng: 21.7164438 },
    { lat: 48.9256463, lng: 21.7165541 },
    { lat: 48.9254485, lng: 21.716942 },
    { lat: 48.9239826, lng: 21.7186996 },
    { lat: 48.9236861, lng: 21.7188867 },
    { lat: 48.92339, lng: 21.7188774 },
    { lat: 48.9232999, lng: 21.7189656 },
    { lat: 48.9235792, lng: 21.7217456 },
    { lat: 48.9238816, lng: 21.722958 },
    { lat: 48.925472, lng: 21.7266934 },
    { lat: 48.9271284, lng: 21.7294858 },
    { lat: 48.9278463, lng: 21.7308828 },
    { lat: 48.9274535, lng: 21.7315087 },
    { lat: 48.9269405, lng: 21.7327717 },
    { lat: 48.9270516, lng: 21.7327198 },
    { lat: 48.9275206, lng: 21.7331868 },
    { lat: 48.9273095, lng: 21.7341264 },
    { lat: 48.9274769, lng: 21.7343319 },
    { lat: 48.9277728, lng: 21.73382 },
    { lat: 48.9286959, lng: 21.7317308 },
    { lat: 48.9294587, lng: 21.7304291 },
    { lat: 48.9301555, lng: 21.7294743 },
    { lat: 48.9305437, lng: 21.7287649 },
    { lat: 48.9315127, lng: 21.7257406 },
    { lat: 48.9318536, lng: 21.725112 },
    { lat: 48.9320945, lng: 21.7249101 },
    { lat: 48.932595, lng: 21.7251118 },
    { lat: 48.9328232, lng: 21.7252713 },
    { lat: 48.9332316, lng: 21.7253202 },
    { lat: 48.9335432, lng: 21.72515 },
    { lat: 48.9341277, lng: 21.7246675 },
    { lat: 48.9348572, lng: 21.7238003 },
    { lat: 48.9349585, lng: 21.7240173 },
    { lat: 48.9363634, lng: 21.7240922 },
    { lat: 48.936797, lng: 21.724448 },
    { lat: 48.9366644, lng: 21.7255436 },
    { lat: 48.9367195, lng: 21.7262122 },
    { lat: 48.93682, lng: 21.7266147 },
    { lat: 48.9369721, lng: 21.7269607 },
    { lat: 48.9377242, lng: 21.7279074 },
    { lat: 48.9383855, lng: 21.7282793 },
    { lat: 48.9391134, lng: 21.7288236 },
    { lat: 48.9394785, lng: 21.7289267 },
    { lat: 48.9400544, lng: 21.7285921 },
    { lat: 48.9404637, lng: 21.7291761 },
    { lat: 48.9405856, lng: 21.7292752 },
    { lat: 48.9410259, lng: 21.7294149 },
    { lat: 48.9415304, lng: 21.7294304 },
    { lat: 48.9419344, lng: 21.7293565 },
    { lat: 48.9424885, lng: 21.7289574 },
    { lat: 48.9426377, lng: 21.7287707 },
    { lat: 48.9428863, lng: 21.7282265 },
    { lat: 48.9429522, lng: 21.7279121 },
    { lat: 48.9429875, lng: 21.7274168 },
    { lat: 48.9429554, lng: 21.726849 },
    { lat: 48.9428933, lng: 21.7267122 },
    { lat: 48.9431703, lng: 21.7263863 },
    { lat: 48.9435304, lng: 21.7261376 },
    { lat: 48.9437178, lng: 21.726082 },
    { lat: 48.9440881, lng: 21.7261237 },
    { lat: 48.9446139, lng: 21.7266503 },
    { lat: 48.9450146, lng: 21.7269098 },
    { lat: 48.9453504, lng: 21.7268 },
    { lat: 48.9454531, lng: 21.7266477 },
    { lat: 48.9456207, lng: 21.7260565 },
    { lat: 48.9460612, lng: 21.7259106 },
    { lat: 48.9463624, lng: 21.7259425 },
    { lat: 48.9464931, lng: 21.7260343 },
    { lat: 48.9467142, lng: 21.726521 },
    { lat: 48.947153, lng: 21.7279081 },
    { lat: 48.9474306, lng: 21.7283624 },
    { lat: 48.9476188, lng: 21.728508 },
    { lat: 48.9479064, lng: 21.7286145 },
    { lat: 48.9481909, lng: 21.7286134 },
    { lat: 48.9488453, lng: 21.7281333 },
    { lat: 48.9491595, lng: 21.7278076 },
    { lat: 48.9494131, lng: 21.7283797 },
    { lat: 48.9497019, lng: 21.728742 },
    { lat: 48.9498999, lng: 21.7288737 },
    { lat: 48.9504274, lng: 21.7288613 },
    { lat: 48.9508444, lng: 21.7286388 },
    { lat: 48.9512828, lng: 21.728158 },
    { lat: 48.9513456, lng: 21.7281981 },
    { lat: 48.9519283, lng: 21.7281075 },
    { lat: 48.9522814, lng: 21.7277715 },
    { lat: 48.9526499, lng: 21.7275437 },
    { lat: 48.9528929, lng: 21.7272499 },
    { lat: 48.9538815, lng: 21.7265193 },
    { lat: 48.9541337, lng: 21.7263599 },
    { lat: 48.9540262, lng: 21.7262094 },
    { lat: 48.9542159, lng: 21.7253948 },
    { lat: 48.9547264, lng: 21.7226009 },
    { lat: 48.9561198, lng: 21.7208207 },
    { lat: 48.9577298, lng: 21.7190155 },
    { lat: 48.9596599, lng: 21.7186673 },
    { lat: 48.9600431, lng: 21.7186028 },
    { lat: 48.9596773, lng: 21.7168329 },
    { lat: 48.9595286, lng: 21.7156904 },
    { lat: 48.9594057, lng: 21.7140859 },
    { lat: 48.9591721, lng: 21.7135508 },
    { lat: 48.9591192, lng: 21.7130134 },
    { lat: 48.9591828, lng: 21.7123594 },
    { lat: 48.9594863, lng: 21.7112659 },
    { lat: 48.9595171, lng: 21.7107359 },
    { lat: 48.9596033, lng: 21.7104664 },
    { lat: 48.9602557, lng: 21.7095417 },
    { lat: 48.9592115, lng: 21.7061412 },
    { lat: 48.9588584, lng: 21.705141 },
    { lat: 48.9583687, lng: 21.7039545 },
    { lat: 48.9576755, lng: 21.7028999 },
    { lat: 48.9574567, lng: 21.702423 },
    { lat: 48.9573463, lng: 21.7020361 },
    { lat: 48.9572564, lng: 21.7011664 },
    { lat: 48.9571081, lng: 21.700712 },
    { lat: 48.9571665, lng: 21.6999541 },
    { lat: 48.9571182, lng: 21.6990023 },
    { lat: 48.9570153, lng: 21.6986919 },
    { lat: 48.9570243, lng: 21.6984372 },
    { lat: 48.9568844, lng: 21.6979636 },
    { lat: 48.95645, lng: 21.6940735 },
    { lat: 48.9564206, lng: 21.6932813 },
    { lat: 48.9566339, lng: 21.6930071 },
    { lat: 48.9569057, lng: 21.692073 },
    { lat: 48.9575457, lng: 21.6906148 },
    { lat: 48.9586898, lng: 21.6900308 },
    { lat: 48.9590661, lng: 21.6889233 },
    { lat: 48.9590244, lng: 21.6884598 },
    { lat: 48.9590877, lng: 21.6881 },
    { lat: 48.9590904, lng: 21.6877808 },
    { lat: 48.9590311, lng: 21.6868004 },
    { lat: 48.9591626, lng: 21.6860826 },
    { lat: 48.9592557, lng: 21.6858638 },
    { lat: 48.9592807, lng: 21.6857512 },
  ],
  Vlača: [
    { lat: 49.0396194, lng: 21.4966397 },
    { lat: 49.0389757, lng: 21.4974605 },
    { lat: 49.0387061, lng: 21.4977542 },
    { lat: 49.0385963, lng: 21.4978052 },
    { lat: 49.0378099, lng: 21.497967 },
    { lat: 49.0375992, lng: 21.4982746 },
    { lat: 49.0374376, lng: 21.4991858 },
    { lat: 49.0371874, lng: 21.50004 },
    { lat: 49.0367894, lng: 21.5011493 },
    { lat: 49.0366356, lng: 21.501436 },
    { lat: 49.0365517, lng: 21.5014544 },
    { lat: 49.0364141, lng: 21.5019103 },
    { lat: 49.0361691, lng: 21.502354 },
    { lat: 49.0356371, lng: 21.5031902 },
    { lat: 49.0351158, lng: 21.5038439 },
    { lat: 49.0349734, lng: 21.5043374 },
    { lat: 49.0340541, lng: 21.5059028 },
    { lat: 49.0336881, lng: 21.5064045 },
    { lat: 49.0331515, lng: 21.5076695 },
    { lat: 49.033024, lng: 21.5080171 },
    { lat: 49.0330202, lng: 21.5081561 },
    { lat: 49.0327672, lng: 21.5087928 },
    { lat: 49.0324204, lng: 21.510005 },
    { lat: 49.0323391, lng: 21.5107849 },
    { lat: 49.0324107, lng: 21.5111211 },
    { lat: 49.0323487, lng: 21.5115179 },
    { lat: 49.0323789, lng: 21.5118257 },
    { lat: 49.0327893, lng: 21.5120553 },
    { lat: 49.0334114, lng: 21.5126813 },
    { lat: 49.0338957, lng: 21.5129376 },
    { lat: 49.034065, lng: 21.5131115 },
    { lat: 49.0349089, lng: 21.5109424 },
    { lat: 49.0361026, lng: 21.5082639 },
    { lat: 49.0373131, lng: 21.5060525 },
    { lat: 49.0381887, lng: 21.5049142 },
    { lat: 49.0394253, lng: 21.5038976 },
    { lat: 49.0400188, lng: 21.5037214 },
    { lat: 49.0408051, lng: 21.5036397 },
    { lat: 49.0407681, lng: 21.5040362 },
    { lat: 49.0419568, lng: 21.5055529 },
    { lat: 49.0416872, lng: 21.5059602 },
    { lat: 49.0418063, lng: 21.506549 },
    { lat: 49.0423586, lng: 21.5081153 },
    { lat: 49.0432357, lng: 21.5090085 },
    { lat: 49.0434302, lng: 21.5095562 },
    { lat: 49.0437454, lng: 21.5095641 },
    { lat: 49.0442067, lng: 21.5116529 },
    { lat: 49.0447869, lng: 21.5122713 },
    { lat: 49.0455814, lng: 21.5132641 },
    { lat: 49.0458917, lng: 21.5134979 },
    { lat: 49.0460377, lng: 21.5135259 },
    { lat: 49.0457924, lng: 21.5150678 },
    { lat: 49.046111, lng: 21.5155439 },
    { lat: 49.0466335, lng: 21.5158033 },
    { lat: 49.0468335, lng: 21.5154307 },
    { lat: 49.0470774, lng: 21.5156255 },
    { lat: 49.0473372, lng: 21.5159423 },
    { lat: 49.0478158, lng: 21.5160338 },
    { lat: 49.048221, lng: 21.5155997 },
    { lat: 49.0484758, lng: 21.5155047 },
    { lat: 49.049164, lng: 21.5155319 },
    { lat: 49.0491812, lng: 21.5168489 },
    { lat: 49.049334, lng: 21.517469 },
    { lat: 49.0497641, lng: 21.5179195 },
    { lat: 49.0499633, lng: 21.5179925 },
    { lat: 49.0508073, lng: 21.5180833 },
    { lat: 49.0511167, lng: 21.5159918 },
    { lat: 49.0519821, lng: 21.5161952 },
    { lat: 49.0529125, lng: 21.5153617 },
    { lat: 49.0537843, lng: 21.5144484 },
    { lat: 49.0543415, lng: 21.5142208 },
    { lat: 49.054464, lng: 21.5143756 },
    { lat: 49.0563191, lng: 21.5141317 },
    { lat: 49.0566716, lng: 21.5142979 },
    { lat: 49.0572366, lng: 21.5146983 },
    { lat: 49.0576155, lng: 21.5137062 },
    { lat: 49.0586654, lng: 21.510829 },
    { lat: 49.0588662, lng: 21.510927 },
    { lat: 49.058909, lng: 21.5105733 },
    { lat: 49.0590354, lng: 21.5102712 },
    { lat: 49.0592887, lng: 21.5100837 },
    { lat: 49.0598218, lng: 21.509322 },
    { lat: 49.0600197, lng: 21.5091185 },
    { lat: 49.0602444, lng: 21.5090706 },
    { lat: 49.0603992, lng: 21.5087371 },
    { lat: 49.0604126, lng: 21.5084227 },
    { lat: 49.0606561, lng: 21.5083636 },
    { lat: 49.0607508, lng: 21.5081693 },
    { lat: 49.0606913, lng: 21.5078927 },
    { lat: 49.0607124, lng: 21.5076918 },
    { lat: 49.0609999, lng: 21.5075548 },
    { lat: 49.0611913, lng: 21.5070087 },
    { lat: 49.0611843, lng: 21.5066048 },
    { lat: 49.0613439, lng: 21.5063223 },
    { lat: 49.0612333, lng: 21.5059913 },
    { lat: 49.0613803, lng: 21.5057402 },
    { lat: 49.0614124, lng: 21.5052541 },
    { lat: 49.0615823, lng: 21.5049379 },
    { lat: 49.0616546, lng: 21.5050264 },
    { lat: 49.061794, lng: 21.5048202 },
    { lat: 49.0616907, lng: 21.5045974 },
    { lat: 49.0625492, lng: 21.5029818 },
    { lat: 49.0629761, lng: 21.5018994 },
    { lat: 49.0635722, lng: 21.501104 },
    { lat: 49.0637437, lng: 21.5007665 },
    { lat: 49.0631226, lng: 21.4991142 },
    { lat: 49.0625722, lng: 21.4980072 },
    { lat: 49.0621396, lng: 21.4974295 },
    { lat: 49.0618979, lng: 21.4972816 },
    { lat: 49.0586548, lng: 21.4944729 },
    { lat: 49.0581903, lng: 21.4938895 },
    { lat: 49.0576484, lng: 21.4934332 },
    { lat: 49.0573961, lng: 21.4931436 },
    { lat: 49.0571905, lng: 21.4935454 },
    { lat: 49.0566249, lng: 21.4928145 },
    { lat: 49.0563084, lng: 21.4920929 },
    { lat: 49.0560317, lng: 21.4912733 },
    { lat: 49.0559592, lng: 21.4909007 },
    { lat: 49.055955, lng: 21.4904084 },
    { lat: 49.0557777, lng: 21.4893225 },
    { lat: 49.0555407, lng: 21.4887058 },
    { lat: 49.0553966, lng: 21.4881619 },
    { lat: 49.0553619, lng: 21.4876175 },
    { lat: 49.0555503, lng: 21.4874371 },
    { lat: 49.0555334, lng: 21.4867677 },
    { lat: 49.0552812, lng: 21.4862923 },
    { lat: 49.0549765, lng: 21.4854365 },
    { lat: 49.0551637, lng: 21.4849843 },
    { lat: 49.0540495, lng: 21.4839105 },
    { lat: 49.0506053, lng: 21.4868871 },
    { lat: 49.0500091, lng: 21.4874716 },
    { lat: 49.047759, lng: 21.4892807 },
    { lat: 49.0450972, lng: 21.4915243 },
    { lat: 49.0440123, lng: 21.4937869 },
    { lat: 49.0412707, lng: 21.4943933 },
    { lat: 49.0412898, lng: 21.495091 },
    { lat: 49.0408778, lng: 21.4965449 },
    { lat: 49.0396194, lng: 21.4966397 },
  ],
  Medzianky: [
    { lat: 49.0396194, lng: 21.4966397 },
    { lat: 49.0408778, lng: 21.4965449 },
    { lat: 49.0412898, lng: 21.495091 },
    { lat: 49.0412707, lng: 21.4943933 },
    { lat: 49.0440123, lng: 21.4937869 },
    { lat: 49.0450972, lng: 21.4915243 },
    { lat: 49.047759, lng: 21.4892807 },
    { lat: 49.0500091, lng: 21.4874716 },
    { lat: 49.0506053, lng: 21.4868871 },
    { lat: 49.0540495, lng: 21.4839105 },
    { lat: 49.0542083, lng: 21.4832198 },
    { lat: 49.0543374, lng: 21.4826587 },
    { lat: 49.0542337, lng: 21.4824949 },
    { lat: 49.0544432, lng: 21.4820315 },
    { lat: 49.0543802, lng: 21.4819553 },
    { lat: 49.0544264, lng: 21.4818016 },
    { lat: 49.0541738, lng: 21.4816666 },
    { lat: 49.054099, lng: 21.4817617 },
    { lat: 49.0540408, lng: 21.4817315 },
    { lat: 49.0538852, lng: 21.4814441 },
    { lat: 49.0538261, lng: 21.4809964 },
    { lat: 49.0534005, lng: 21.479658 },
    { lat: 49.0528337, lng: 21.4790027 },
    { lat: 49.0525815, lng: 21.4789148 },
    { lat: 49.0524048, lng: 21.4784839 },
    { lat: 49.0522589, lng: 21.4784958 },
    { lat: 49.0521701, lng: 21.4783424 },
    { lat: 49.0521772, lng: 21.478079 },
    { lat: 49.0515226, lng: 21.4768403 },
    { lat: 49.0512856, lng: 21.4765749 },
    { lat: 49.0511509, lng: 21.4765914 },
    { lat: 49.0509766, lng: 21.4764416 },
    { lat: 49.0507153, lng: 21.47612 },
    { lat: 49.0507735, lng: 21.4759113 },
    { lat: 49.0508547, lng: 21.4758033 },
    { lat: 49.0506783, lng: 21.4753728 },
    { lat: 49.0503763, lng: 21.474894 },
    { lat: 49.0497548, lng: 21.4741399 },
    { lat: 49.0480484, lng: 21.471772 },
    { lat: 49.0480862, lng: 21.4715209 },
    { lat: 49.0480343, lng: 21.4713978 },
    { lat: 49.0481115, lng: 21.4712276 },
    { lat: 49.048125, lng: 21.4709527 },
    { lat: 49.0483686, lng: 21.4707736 },
    { lat: 49.0484482, lng: 21.4705746 },
    { lat: 49.0484265, lng: 21.4701736 },
    { lat: 49.0482926, lng: 21.4702076 },
    { lat: 49.0484676, lng: 21.4699969 },
    { lat: 49.0484936, lng: 21.4698463 },
    { lat: 49.0484239, lng: 21.4697337 },
    { lat: 49.0485451, lng: 21.4697159 },
    { lat: 49.0486228, lng: 21.4696063 },
    { lat: 49.0488077, lng: 21.4690366 },
    { lat: 49.0486149, lng: 21.468868 },
    { lat: 49.048434, lng: 21.4683664 },
    { lat: 49.0478172, lng: 21.4678922 },
    { lat: 49.0478208, lng: 21.4676678 },
    { lat: 49.0475376, lng: 21.4672488 },
    { lat: 49.0473529, lng: 21.467052 },
    { lat: 49.0472023, lng: 21.4669785 },
    { lat: 49.0467745, lng: 21.4662654 },
    { lat: 49.0463273, lng: 21.4660951 },
    { lat: 49.0460325, lng: 21.4651231 },
    { lat: 49.0456449, lng: 21.4646735 },
    { lat: 49.0453616, lng: 21.4646038 },
    { lat: 49.0452116, lng: 21.464668 },
    { lat: 49.0448072, lng: 21.4643805 },
    { lat: 49.0446576, lng: 21.4643414 },
    { lat: 49.0443553, lng: 21.4646654 },
    { lat: 49.0441262, lng: 21.4644092 },
    { lat: 49.0437458, lng: 21.4644788 },
    { lat: 49.0436462, lng: 21.464106 },
    { lat: 49.0433898, lng: 21.4639983 },
    { lat: 49.042724, lng: 21.4639665 },
    { lat: 49.0421126, lng: 21.4629372 },
    { lat: 49.0418984, lng: 21.4626862 },
    { lat: 49.0416733, lng: 21.4625366 },
    { lat: 49.0415333, lng: 21.4620163 },
    { lat: 49.0409553, lng: 21.4603881 },
    { lat: 49.0408478, lng: 21.4605197 },
    { lat: 49.0406092, lng: 21.4605594 },
    { lat: 49.0404208, lng: 21.4602664 },
    { lat: 49.0402293, lng: 21.4598353 },
    { lat: 49.0395407, lng: 21.4587851 },
    { lat: 49.0395899, lng: 21.4584658 },
    { lat: 49.0394298, lng: 21.4582632 },
    { lat: 49.0393942, lng: 21.4578894 },
    { lat: 49.0390139, lng: 21.4579615 },
    { lat: 49.0385845, lng: 21.4578221 },
    { lat: 49.0382659, lng: 21.4579309 },
    { lat: 49.0379332, lng: 21.4580214 },
    { lat: 49.0374255, lng: 21.4583186 },
    { lat: 49.0370588, lng: 21.4589854 },
    { lat: 49.037068, lng: 21.4594571 },
    { lat: 49.0368472, lng: 21.4592567 },
    { lat: 49.0367542, lng: 21.458994 },
    { lat: 49.0364628, lng: 21.4596945 },
    { lat: 49.036495, lng: 21.4597239 },
    { lat: 49.0360144, lng: 21.4614217 },
    { lat: 49.0358674, lng: 21.4613186 },
    { lat: 49.0357639, lng: 21.4611244 },
    { lat: 49.0350994, lng: 21.4609917 },
    { lat: 49.034548, lng: 21.4607163 },
    { lat: 49.0343273, lng: 21.4608218 },
    { lat: 49.0339482, lng: 21.4617598 },
    { lat: 49.0336559, lng: 21.4619633 },
    { lat: 49.0335063, lng: 21.4619184 },
    { lat: 49.033348, lng: 21.4617383 },
    { lat: 49.0332739, lng: 21.4617421 },
    { lat: 49.0330337, lng: 21.4626249 },
    { lat: 49.0323888, lng: 21.465614 },
    { lat: 49.0326804, lng: 21.4659312 },
    { lat: 49.0326648, lng: 21.4660926 },
    { lat: 49.0324213, lng: 21.4665029 },
    { lat: 49.0320558, lng: 21.4668196 },
    { lat: 49.0322158, lng: 21.4673281 },
    { lat: 49.0320267, lng: 21.4674831 },
    { lat: 49.0320598, lng: 21.4676996 },
    { lat: 49.0321695, lng: 21.4681426 },
    { lat: 49.0323435, lng: 21.4685168 },
    { lat: 49.0322964, lng: 21.4687569 },
    { lat: 49.0323176, lng: 21.4689094 },
    { lat: 49.0325084, lng: 21.4692487 },
    { lat: 49.0324008, lng: 21.4694897 },
    { lat: 49.0326429, lng: 21.4704366 },
    { lat: 49.0325629, lng: 21.4707041 },
    { lat: 49.0326319, lng: 21.4710685 },
    { lat: 49.0328502, lng: 21.4714171 },
    { lat: 49.0328563, lng: 21.4716684 },
    { lat: 49.0329234, lng: 21.4718128 },
    { lat: 49.033186, lng: 21.4719853 },
    { lat: 49.0332265, lng: 21.47222 },
    { lat: 49.0333559, lng: 21.4723716 },
    { lat: 49.0335005, lng: 21.4727446 },
    { lat: 49.0330076, lng: 21.473908 },
    { lat: 49.0326859, lng: 21.475121 },
    { lat: 49.0325597, lng: 21.4753503 },
    { lat: 49.0326265, lng: 21.4755975 },
    { lat: 49.0325992, lng: 21.476058 },
    { lat: 49.0324045, lng: 21.4764435 },
    { lat: 49.0318835, lng: 21.4768712 },
    { lat: 49.0318065, lng: 21.4770545 },
    { lat: 49.0316689, lng: 21.4770802 },
    { lat: 49.0315771, lng: 21.4772917 },
    { lat: 49.0314471, lng: 21.4781319 },
    { lat: 49.0312256, lng: 21.4786017 },
    { lat: 49.0310055, lng: 21.4795561 },
    { lat: 49.0310011, lng: 21.4801082 },
    { lat: 49.0309553, lng: 21.4803102 },
    { lat: 49.0305735, lng: 21.4804941 },
    { lat: 49.030335, lng: 21.4807291 },
    { lat: 49.0302833, lng: 21.480848 },
    { lat: 49.0302873, lng: 21.4814551 },
    { lat: 49.0305918, lng: 21.4819899 },
    { lat: 49.0307433, lng: 21.4820547 },
    { lat: 49.0310933, lng: 21.4825263 },
    { lat: 49.0310253, lng: 21.4830216 },
    { lat: 49.031154, lng: 21.4834631 },
    { lat: 49.0313009, lng: 21.4836426 },
    { lat: 49.0315571, lng: 21.4836317 },
    { lat: 49.0316833, lng: 21.4837165 },
    { lat: 49.0318338, lng: 21.4840246 },
    { lat: 49.0315198, lng: 21.484565 },
    { lat: 49.0313514, lng: 21.4846231 },
    { lat: 49.0311769, lng: 21.4845383 },
    { lat: 49.0310016, lng: 21.4846991 },
    { lat: 49.0308728, lng: 21.4853097 },
    { lat: 49.0310453, lng: 21.4858253 },
    { lat: 49.0311068, lng: 21.485899 },
    { lat: 49.0313011, lng: 21.4857983 },
    { lat: 49.0317535, lng: 21.4857646 },
    { lat: 49.0319404, lng: 21.4859268 },
    { lat: 49.0317876, lng: 21.4863697 },
    { lat: 49.0317539, lng: 21.4867657 },
    { lat: 49.031639, lng: 21.4871057 },
    { lat: 49.0314748, lng: 21.4872658 },
    { lat: 49.0312271, lng: 21.4871793 },
    { lat: 49.0309406, lng: 21.487317 },
    { lat: 49.0308526, lng: 21.487505 },
    { lat: 49.0307647, lng: 21.4879257 },
    { lat: 49.0308832, lng: 21.4885718 },
    { lat: 49.0309303, lng: 21.4886408 },
    { lat: 49.0311733, lng: 21.4887207 },
    { lat: 49.0316595, lng: 21.4893901 },
    { lat: 49.0317236, lng: 21.4896251 },
    { lat: 49.0315186, lng: 21.4900073 },
    { lat: 49.0312668, lng: 21.4902871 },
    { lat: 49.031232, lng: 21.4904135 },
    { lat: 49.0312408, lng: 21.4907951 },
    { lat: 49.0320413, lng: 21.4918005 },
    { lat: 49.0322136, lng: 21.4921921 },
    { lat: 49.0322684, lng: 21.4926564 },
    { lat: 49.0326403, lng: 21.4938512 },
    { lat: 49.0323634, lng: 21.494752 },
    { lat: 49.0323983, lng: 21.4947852 },
    { lat: 49.0325086, lng: 21.4946369 },
    { lat: 49.0326305, lng: 21.4948426 },
    { lat: 49.032755, lng: 21.4946715 },
    { lat: 49.0331608, lng: 21.4938321 },
    { lat: 49.0333426, lng: 21.4941302 },
    { lat: 49.0333565, lng: 21.4942279 },
    { lat: 49.0334075, lng: 21.4942801 },
    { lat: 49.0335061, lng: 21.4940688 },
    { lat: 49.0336988, lng: 21.494277 },
    { lat: 49.0336269, lng: 21.494432 },
    { lat: 49.0338655, lng: 21.4945976 },
    { lat: 49.0336916, lng: 21.4952445 },
    { lat: 49.0334741, lng: 21.4955845 },
    { lat: 49.0335867, lng: 21.4957778 },
    { lat: 49.0328604, lng: 21.4965879 },
    { lat: 49.0324806, lng: 21.4969175 },
    { lat: 49.0316831, lng: 21.4970452 },
    { lat: 49.0314936, lng: 21.4969488 },
    { lat: 49.0314145, lng: 21.4970924 },
    { lat: 49.0294582, lng: 21.4990645 },
    { lat: 49.0298996, lng: 21.4995089 },
    { lat: 49.03034, lng: 21.499725 },
    { lat: 49.0309301, lng: 21.4997824 },
    { lat: 49.032368, lng: 21.5007271 },
    { lat: 49.0324779, lng: 21.5003577 },
    { lat: 49.0333286, lng: 21.5007955 },
    { lat: 49.0338424, lng: 21.4996044 },
    { lat: 49.0341671, lng: 21.5000186 },
    { lat: 49.034616, lng: 21.4993105 },
    { lat: 49.0348867, lng: 21.4990645 },
    { lat: 49.035477, lng: 21.4988465 },
    { lat: 49.0362894, lng: 21.4981437 },
    { lat: 49.0370222, lng: 21.4969018 },
    { lat: 49.0375946, lng: 21.4956328 },
    { lat: 49.0382185, lng: 21.4950274 },
    { lat: 49.0384653, lng: 21.4946058 },
    { lat: 49.0387077, lng: 21.4948633 },
    { lat: 49.0396194, lng: 21.4966397 },
  ],
  NováKelča: [
    { lat: 49.0565679, lng: 21.723947 },
    { lat: 49.0572695, lng: 21.726507 },
    { lat: 49.0584645, lng: 21.7274894 },
    { lat: 49.0593356, lng: 21.7281405 },
    { lat: 49.0604282, lng: 21.7282345 },
    { lat: 49.0609612, lng: 21.7283527 },
    { lat: 49.0620253, lng: 21.7283093 },
    { lat: 49.0637785, lng: 21.7275652 },
    { lat: 49.0639155, lng: 21.727428 },
    { lat: 49.0640187, lng: 21.7272024 },
    { lat: 49.0646649, lng: 21.7270531 },
    { lat: 49.0647539, lng: 21.7269354 },
    { lat: 49.0655918, lng: 21.7263811 },
    { lat: 49.0657648, lng: 21.7263733 },
    { lat: 49.0658612, lng: 21.726274 },
    { lat: 49.0667355, lng: 21.7261146 },
    { lat: 49.0674569, lng: 21.726294 },
    { lat: 49.0681403, lng: 21.7261445 },
    { lat: 49.0683232, lng: 21.7261688 },
    { lat: 49.0685899, lng: 21.7258811 },
    { lat: 49.0692158, lng: 21.7255109 },
    { lat: 49.0696302, lng: 21.725447 },
    { lat: 49.0698123, lng: 21.7250988 },
    { lat: 49.0706847, lng: 21.7246202 },
    { lat: 49.0709722, lng: 21.7243409 },
    { lat: 49.0709906, lng: 21.7239781 },
    { lat: 49.0713219, lng: 21.7234072 },
    { lat: 49.0717221, lng: 21.7225577 },
    { lat: 49.0721776, lng: 21.7218338 },
    { lat: 49.0725591, lng: 21.7214568 },
    { lat: 49.0732567, lng: 21.7209573 },
    { lat: 49.073742, lng: 21.7205125 },
    { lat: 49.0739704, lng: 21.7201313 },
    { lat: 49.0741368, lng: 21.7196291 },
    { lat: 49.0745835, lng: 21.7191687 },
    { lat: 49.0757973, lng: 21.7187908 },
    { lat: 49.0762243, lng: 21.7185534 },
    { lat: 49.0773377, lng: 21.7183367 },
    { lat: 49.0783357, lng: 21.7187626 },
    { lat: 49.079048, lng: 21.7192748 },
    { lat: 49.0795603, lng: 21.7192144 },
    { lat: 49.0799778, lng: 21.7192596 },
    { lat: 49.0802497, lng: 21.7191222 },
    { lat: 49.0811056, lng: 21.7192322 },
    { lat: 49.0817153, lng: 21.7191945 },
    { lat: 49.0821198, lng: 21.7191314 },
    { lat: 49.082968, lng: 21.7186734 },
    { lat: 49.0832839, lng: 21.7189112 },
    { lat: 49.0837909, lng: 21.7190689 },
    { lat: 49.0846667, lng: 21.7189641 },
    { lat: 49.0850705, lng: 21.7186725 },
    { lat: 49.086004, lng: 21.7181511 },
    { lat: 49.0866704, lng: 21.7183359 },
    { lat: 49.087344, lng: 21.718175 },
    { lat: 49.080563, lng: 21.707261 },
    { lat: 49.078004, lng: 21.703141 },
    { lat: 49.078126, lng: 21.701544 },
    { lat: 49.078263, lng: 21.699759 },
    { lat: 49.077719, lng: 21.696257 },
    { lat: 49.076262, lng: 21.694326 },
    { lat: 49.075687, lng: 21.69318 },
    { lat: 49.075299, lng: 21.692353 },
    { lat: 49.074829, lng: 21.691519 },
    { lat: 49.074413, lng: 21.690746 },
    { lat: 49.074265, lng: 21.690213 },
    { lat: 49.073464, lng: 21.688907 },
    { lat: 49.073169, lng: 21.688223 },
    { lat: 49.072793, lng: 21.687402 },
    { lat: 49.072357, lng: 21.687056 },
    { lat: 49.071285, lng: 21.685444 },
    { lat: 49.071015, lng: 21.684842 },
    { lat: 49.071005, lng: 21.684822 },
    { lat: 49.070849, lng: 21.684748 },
    { lat: 49.070669, lng: 21.684172 },
    { lat: 49.069944, lng: 21.683281 },
    { lat: 49.06955, lng: 21.682426 },
    { lat: 49.069429, lng: 21.681788 },
    { lat: 49.068861, lng: 21.681123 },
    { lat: 49.06833, lng: 21.680081 },
    { lat: 49.067817, lng: 21.678752 },
    { lat: 49.069058, lng: 21.676804 },
    { lat: 49.068572, lng: 21.675027 },
    { lat: 49.067749, lng: 21.671753 },
    { lat: 49.067308, lng: 21.669443 },
    { lat: 49.067291, lng: 21.669454 },
    { lat: 49.06542, lng: 21.666388 },
    { lat: 49.058134, lng: 21.675536 },
    { lat: 49.0557382, lng: 21.6859348 },
    { lat: 49.0400024, lng: 21.7069808 },
    { lat: 49.0400706, lng: 21.7072996 },
    { lat: 49.0400179, lng: 21.7073822 },
    { lat: 49.0400071, lng: 21.7074897 },
    { lat: 49.0402052, lng: 21.7083702 },
    { lat: 49.0403738, lng: 21.7089102 },
    { lat: 49.0403094, lng: 21.7094886 },
    { lat: 49.0404221, lng: 21.709875 },
    { lat: 49.0404994, lng: 21.7109571 },
    { lat: 49.0412334, lng: 21.711439 },
    { lat: 49.0412011, lng: 21.7121611 },
    { lat: 49.0413233, lng: 21.7123565 },
    { lat: 49.0413418, lng: 21.7125027 },
    { lat: 49.0413193, lng: 21.713255 },
    { lat: 49.0414399, lng: 21.713984 },
    { lat: 49.0415502, lng: 21.7143164 },
    { lat: 49.0421056, lng: 21.7149788 },
    { lat: 49.0424736, lng: 21.7152346 },
    { lat: 49.0428451, lng: 21.7153737 },
    { lat: 49.0429998, lng: 21.7155194 },
    { lat: 49.0430732, lng: 21.7158648 },
    { lat: 49.0430561, lng: 21.7159832 },
    { lat: 49.0432336, lng: 21.7177031 },
    { lat: 49.0433154, lng: 21.7180287 },
    { lat: 49.0434343, lng: 21.7181823 },
    { lat: 49.0434912, lng: 21.7186274 },
    { lat: 49.0433913, lng: 21.7193956 },
    { lat: 49.043288, lng: 21.7195716 },
    { lat: 49.04361, lng: 21.7224836 },
    { lat: 49.0441739, lng: 21.7228755 },
    { lat: 49.0445023, lng: 21.722875 },
    { lat: 49.045662, lng: 21.7226599 },
    { lat: 49.0463072, lng: 21.7227226 },
    { lat: 49.0468073, lng: 21.7228323 },
    { lat: 49.0480441, lng: 21.7233151 },
    { lat: 49.0483737, lng: 21.7233517 },
    { lat: 49.0506089, lng: 21.7225897 },
    { lat: 49.0515351, lng: 21.7218489 },
    { lat: 49.0527907, lng: 21.7205447 },
    { lat: 49.0541522, lng: 21.7205737 },
    { lat: 49.0542865, lng: 21.7206166 },
    { lat: 49.0552123, lng: 21.7212028 },
    { lat: 49.0559486, lng: 21.7223213 },
    { lat: 49.0565679, lng: 21.723947 },
  ],
  OndavskéMatiašovce: [
    { lat: 48.9202837, lng: 21.7409662 },
    { lat: 48.920366, lng: 21.7411783 },
    { lat: 48.9218697, lng: 21.7453514 },
    { lat: 48.9231509, lng: 21.7486973 },
    { lat: 48.9236564, lng: 21.7503267 },
    { lat: 48.9238393, lng: 21.750558 },
    { lat: 48.9241136, lng: 21.7512747 },
    { lat: 48.925477, lng: 21.7552652 },
    { lat: 48.926476, lng: 21.7575623 },
    { lat: 48.9275195, lng: 21.7604363 },
    { lat: 48.9276365, lng: 21.7607798 },
    { lat: 48.9273265, lng: 21.7615199 },
    { lat: 48.9275663, lng: 21.7616098 },
    { lat: 48.9279526, lng: 21.7618861 },
    { lat: 48.9279688, lng: 21.762025 },
    { lat: 48.9283524, lng: 21.7623659 },
    { lat: 48.9281658, lng: 21.7627185 },
    { lat: 48.9285135, lng: 21.762628 },
    { lat: 48.9287194, lng: 21.7621679 },
    { lat: 48.9288291, lng: 21.7621895 },
    { lat: 48.929023, lng: 21.7628457 },
    { lat: 48.9291368, lng: 21.7634877 },
    { lat: 48.9291385, lng: 21.7638544 },
    { lat: 48.93, lng: 21.7645666 },
    { lat: 48.9303937, lng: 21.7648232 },
    { lat: 48.930557, lng: 21.764868 },
    { lat: 48.9307627, lng: 21.7650794 },
    { lat: 48.9316962, lng: 21.7654535 },
    { lat: 48.9319767, lng: 21.7657188 },
    { lat: 48.9336927, lng: 21.7680455 },
    { lat: 48.9343696, lng: 21.7692224 },
    { lat: 48.9351214, lng: 21.7700026 },
    { lat: 48.9351089, lng: 21.7700463 },
    { lat: 48.9353524, lng: 21.7700197 },
    { lat: 48.9361294, lng: 21.7706481 },
    { lat: 48.9361758, lng: 21.770529 },
    { lat: 48.9366004, lng: 21.7703347 },
    { lat: 48.9367134, lng: 21.7704427 },
    { lat: 48.9368028, lng: 21.770671 },
    { lat: 48.9367827, lng: 21.7708937 },
    { lat: 48.9367033, lng: 21.7711254 },
    { lat: 48.9368608, lng: 21.7712514 },
    { lat: 48.9374483, lng: 21.771433 },
    { lat: 48.937586, lng: 21.771327 },
    { lat: 48.9377798, lng: 21.7713803 },
    { lat: 48.9379125, lng: 21.7711662 },
    { lat: 48.9379217, lng: 21.7710404 },
    { lat: 48.9380599, lng: 21.7708631 },
    { lat: 48.9381082, lng: 21.7709074 },
    { lat: 48.9380867, lng: 21.7712361 },
    { lat: 48.9382229, lng: 21.771288 },
    { lat: 48.9384165, lng: 21.7712004 },
    { lat: 48.9385946, lng: 21.7714315 },
    { lat: 48.9389535, lng: 21.7713886 },
    { lat: 48.939151, lng: 21.7711998 },
    { lat: 48.9394691, lng: 21.771104 },
    { lat: 48.93977, lng: 21.771251 },
    { lat: 48.9398932, lng: 21.7714007 },
    { lat: 48.9400187, lng: 21.771125 },
    { lat: 48.9403337, lng: 21.7710497 },
    { lat: 48.9403229, lng: 21.7702889 },
    { lat: 48.9407697, lng: 21.7693152 },
    { lat: 48.9411421, lng: 21.7691097 },
    { lat: 48.9427127, lng: 21.769023 },
    { lat: 48.943535, lng: 21.7690704 },
    { lat: 48.9436309, lng: 21.7689866 },
    { lat: 48.9437574, lng: 21.7691804 },
    { lat: 48.9438261, lng: 21.7693988 },
    { lat: 48.9440166, lng: 21.769578 },
    { lat: 48.9445971, lng: 21.7692894 },
    { lat: 48.9449022, lng: 21.7693804 },
    { lat: 48.9452703, lng: 21.7692142 },
    { lat: 48.9454056, lng: 21.7696154 },
    { lat: 48.9458386, lng: 21.7694348 },
    { lat: 48.9459174, lng: 21.7695639 },
    { lat: 48.9460204, lng: 21.769466 },
    { lat: 48.9460886, lng: 21.7695761 },
    { lat: 48.9463613, lng: 21.7697628 },
    { lat: 48.9464923, lng: 21.7696944 },
    { lat: 48.9470927, lng: 21.7696855 },
    { lat: 48.9471647, lng: 21.7698948 },
    { lat: 48.947298, lng: 21.7700546 },
    { lat: 48.947852, lng: 21.7702421 },
    { lat: 48.9481833, lng: 21.770129 },
    { lat: 48.9484391, lng: 21.7704084 },
    { lat: 48.9488883, lng: 21.770637 },
    { lat: 48.949138, lng: 21.7703688 },
    { lat: 48.949267, lng: 21.7703624 },
    { lat: 48.9492857, lng: 21.7702149 },
    { lat: 48.9493684, lng: 21.7702385 },
    { lat: 48.949497, lng: 21.770468 },
    { lat: 48.9497482, lng: 21.77048 },
    { lat: 48.9498955, lng: 21.7705594 },
    { lat: 48.9499236, lng: 21.7706862 },
    { lat: 48.9500818, lng: 21.7706265 },
    { lat: 48.9502269, lng: 21.7707541 },
    { lat: 48.950471, lng: 21.7705796 },
    { lat: 48.9507095, lng: 21.7705444 },
    { lat: 48.9508316, lng: 21.7706504 },
    { lat: 48.9511563, lng: 21.7707617 },
    { lat: 48.951219, lng: 21.7705375 },
    { lat: 48.9513031, lng: 21.770527 },
    { lat: 48.9515082, lng: 21.7690048 },
    { lat: 48.9519218, lng: 21.7683006 },
    { lat: 48.952517, lng: 21.765751 },
    { lat: 48.9527891, lng: 21.7640342 },
    { lat: 48.9517071, lng: 21.7640828 },
    { lat: 48.9508864, lng: 21.7640136 },
    { lat: 48.9495386, lng: 21.7637609 },
    { lat: 48.9500704, lng: 21.7619128 },
    { lat: 48.9505864, lng: 21.7596869 },
    { lat: 48.9508236, lng: 21.7590371 },
    { lat: 48.9508973, lng: 21.758892 },
    { lat: 48.9510253, lng: 21.7588033 },
    { lat: 48.9510251, lng: 21.7585824 },
    { lat: 48.9511718, lng: 21.7582972 },
    { lat: 48.9513461, lng: 21.7571873 },
    { lat: 48.9512315, lng: 21.7571499 },
    { lat: 48.951142, lng: 21.7570004 },
    { lat: 48.9512931, lng: 21.7565456 },
    { lat: 48.9512261, lng: 21.7563911 },
    { lat: 48.9514023, lng: 21.7559512 },
    { lat: 48.9516422, lng: 21.7555385 },
    { lat: 48.9520335, lng: 21.7550192 },
    { lat: 48.9521513, lng: 21.7546237 },
    { lat: 48.9522523, lng: 21.7539328 },
    { lat: 48.9525924, lng: 21.753184 },
    { lat: 48.9529194, lng: 21.7526135 },
    { lat: 48.9535438, lng: 21.7496466 },
    { lat: 48.9544439, lng: 21.748459 },
    { lat: 48.9548022, lng: 21.74891 },
    { lat: 48.9557375, lng: 21.7503205 },
    { lat: 48.9562938, lng: 21.7500892 },
    { lat: 48.9569253, lng: 21.750148 },
    { lat: 48.9599288, lng: 21.7470425 },
    { lat: 48.9602703, lng: 21.7469772 },
    { lat: 48.9603398, lng: 21.7468444 },
    { lat: 48.9603104, lng: 21.7462427 },
    { lat: 48.9601041, lng: 21.7450839 },
    { lat: 48.9597502, lng: 21.7440867 },
    { lat: 48.9597338, lng: 21.7435521 },
    { lat: 48.9596376, lng: 21.7432308 },
    { lat: 48.9596704, lng: 21.7429814 },
    { lat: 48.9595843, lng: 21.742706 },
    { lat: 48.9595618, lng: 21.742329 },
    { lat: 48.9596885, lng: 21.7420134 },
    { lat: 48.9597248, lng: 21.7416861 },
    { lat: 48.9598312, lng: 21.7415002 },
    { lat: 48.9601499, lng: 21.7411721 },
    { lat: 48.9602174, lng: 21.740671 },
    { lat: 48.960544, lng: 21.7397069 },
    { lat: 48.9606592, lng: 21.7392173 },
    { lat: 48.9604373, lng: 21.7387 },
    { lat: 48.960327, lng: 21.7378923 },
    { lat: 48.9603456, lng: 21.7375274 },
    { lat: 48.9599666, lng: 21.7364759 },
    { lat: 48.9598752, lng: 21.7360466 },
    { lat: 48.9598461, lng: 21.7354576 },
    { lat: 48.9596503, lng: 21.7351242 },
    { lat: 48.9596897, lng: 21.7347784 },
    { lat: 48.9595041, lng: 21.7344039 },
    { lat: 48.9594525, lng: 21.7341132 },
    { lat: 48.9596264, lng: 21.7340155 },
    { lat: 48.9596098, lng: 21.7337709 },
    { lat: 48.9593942, lng: 21.7335282 },
    { lat: 48.9592507, lng: 21.7332116 },
    { lat: 48.9589227, lng: 21.7328097 },
    { lat: 48.9589149, lng: 21.7326845 },
    { lat: 48.9590688, lng: 21.7323811 },
    { lat: 48.9590773, lng: 21.731985 },
    { lat: 48.9592045, lng: 21.731476 },
    { lat: 48.95914, lng: 21.730412 },
    { lat: 48.9592471, lng: 21.7299093 },
    { lat: 48.9590749, lng: 21.729612 },
    { lat: 48.9589743, lng: 21.7292183 },
    { lat: 48.9588345, lng: 21.7292022 },
    { lat: 48.9585986, lng: 21.7265632 },
    { lat: 48.9585408, lng: 21.7260817 },
    { lat: 48.9584661, lng: 21.7259338 },
    { lat: 48.9559798, lng: 21.7259362 },
    { lat: 48.9559411, lng: 21.7258373 },
    { lat: 48.9552798, lng: 21.725544 },
    { lat: 48.9551523, lng: 21.7253614 },
    { lat: 48.9547854, lng: 21.7254445 },
    { lat: 48.9545677, lng: 21.725972 },
    { lat: 48.9543124, lng: 21.7262265 },
    { lat: 48.9541337, lng: 21.7263599 },
    { lat: 48.9538815, lng: 21.7265193 },
    { lat: 48.9528929, lng: 21.7272499 },
    { lat: 48.9526499, lng: 21.7275437 },
    { lat: 48.9522814, lng: 21.7277715 },
    { lat: 48.9519283, lng: 21.7281075 },
    { lat: 48.9513456, lng: 21.7281981 },
    { lat: 48.9512828, lng: 21.728158 },
    { lat: 48.9508444, lng: 21.7286388 },
    { lat: 48.9504274, lng: 21.7288613 },
    { lat: 48.9498999, lng: 21.7288737 },
    { lat: 48.9497019, lng: 21.728742 },
    { lat: 48.9494131, lng: 21.7283797 },
    { lat: 48.9491595, lng: 21.7278076 },
    { lat: 48.9488453, lng: 21.7281333 },
    { lat: 48.9481909, lng: 21.7286134 },
    { lat: 48.9479064, lng: 21.7286145 },
    { lat: 48.9476188, lng: 21.728508 },
    { lat: 48.9474306, lng: 21.7283624 },
    { lat: 48.947153, lng: 21.7279081 },
    { lat: 48.9467142, lng: 21.726521 },
    { lat: 48.9464931, lng: 21.7260343 },
    { lat: 48.9463624, lng: 21.7259425 },
    { lat: 48.9460612, lng: 21.7259106 },
    { lat: 48.9456207, lng: 21.7260565 },
    { lat: 48.9454531, lng: 21.7266477 },
    { lat: 48.9453504, lng: 21.7268 },
    { lat: 48.9450146, lng: 21.7269098 },
    { lat: 48.9446139, lng: 21.7266503 },
    { lat: 48.9440881, lng: 21.7261237 },
    { lat: 48.9437178, lng: 21.726082 },
    { lat: 48.9435304, lng: 21.7261376 },
    { lat: 48.9431703, lng: 21.7263863 },
    { lat: 48.9428933, lng: 21.7267122 },
    { lat: 48.9429554, lng: 21.726849 },
    { lat: 48.9429875, lng: 21.7274168 },
    { lat: 48.9429522, lng: 21.7279121 },
    { lat: 48.9428863, lng: 21.7282265 },
    { lat: 48.9426377, lng: 21.7287707 },
    { lat: 48.9424885, lng: 21.7289574 },
    { lat: 48.9419344, lng: 21.7293565 },
    { lat: 48.9415304, lng: 21.7294304 },
    { lat: 48.9410259, lng: 21.7294149 },
    { lat: 48.9405856, lng: 21.7292752 },
    { lat: 48.9404637, lng: 21.7291761 },
    { lat: 48.9400544, lng: 21.7285921 },
    { lat: 48.9394785, lng: 21.7289267 },
    { lat: 48.9391134, lng: 21.7288236 },
    { lat: 48.9383855, lng: 21.7282793 },
    { lat: 48.9377242, lng: 21.7279074 },
    { lat: 48.9369721, lng: 21.7269607 },
    { lat: 48.93682, lng: 21.7266147 },
    { lat: 48.9367195, lng: 21.7262122 },
    { lat: 48.9366644, lng: 21.7255436 },
    { lat: 48.936797, lng: 21.724448 },
    { lat: 48.9363634, lng: 21.7240922 },
    { lat: 48.9349585, lng: 21.7240173 },
    { lat: 48.9348572, lng: 21.7238003 },
    { lat: 48.9341277, lng: 21.7246675 },
    { lat: 48.9335432, lng: 21.72515 },
    { lat: 48.9332316, lng: 21.7253202 },
    { lat: 48.9328232, lng: 21.7252713 },
    { lat: 48.932595, lng: 21.7251118 },
    { lat: 48.9320945, lng: 21.7249101 },
    { lat: 48.9318536, lng: 21.725112 },
    { lat: 48.9315127, lng: 21.7257406 },
    { lat: 48.9305437, lng: 21.7287649 },
    { lat: 48.9301555, lng: 21.7294743 },
    { lat: 48.9294587, lng: 21.7304291 },
    { lat: 48.9286959, lng: 21.7317308 },
    { lat: 48.9277728, lng: 21.73382 },
    { lat: 48.9274769, lng: 21.7343319 },
    { lat: 48.9273019, lng: 21.7346821 },
    { lat: 48.9265671, lng: 21.7358173 },
    { lat: 48.9261038, lng: 21.7364153 },
    { lat: 48.9242718, lng: 21.7384255 },
    { lat: 48.923126, lng: 21.7398827 },
    { lat: 48.9227576, lng: 21.7402462 },
    { lat: 48.9219169, lng: 21.7408229 },
    { lat: 48.9202837, lng: 21.7409662 },
  ],
  Bystré: [
    { lat: 49.031607, lng: 21.5330821 },
    { lat: 49.031369, lng: 21.5324695 },
    { lat: 49.0312592, lng: 21.5323207 },
    { lat: 49.0311795, lng: 21.5322141 },
    { lat: 49.031034, lng: 21.5319612 },
    { lat: 49.0305604, lng: 21.5323948 },
    { lat: 49.0295945, lng: 21.5330551 },
    { lat: 49.0285895, lng: 21.5331234 },
    { lat: 49.0278213, lng: 21.5327374 },
    { lat: 49.0254, lng: 21.5295909 },
    { lat: 49.0263766, lng: 21.5290805 },
    { lat: 49.0233246, lng: 21.5272243 },
    { lat: 49.0203732, lng: 21.5237755 },
    { lat: 49.0189705, lng: 21.5220441 },
    { lat: 49.0188134, lng: 21.5216992 },
    { lat: 49.018554, lng: 21.5204459 },
    { lat: 49.0179999, lng: 21.5199572 },
    { lat: 49.0146438, lng: 21.5163619 },
    { lat: 49.0133852, lng: 21.5181964 },
    { lat: 49.0131519, lng: 21.5186157 },
    { lat: 49.0124792, lng: 21.5192925 },
    { lat: 49.0115906, lng: 21.5189843 },
    { lat: 49.011083, lng: 21.518536 },
    { lat: 49.0102437, lng: 21.5184639 },
    { lat: 49.0082432, lng: 21.5157312 },
    { lat: 49.0080508, lng: 21.5149436 },
    { lat: 49.0079854, lng: 21.5143864 },
    { lat: 49.0077162, lng: 21.5134705 },
    { lat: 49.0075918, lng: 21.5132902 },
    { lat: 49.0050598, lng: 21.5118468 },
    { lat: 49.0046803, lng: 21.5113228 },
    { lat: 49.0041988, lng: 21.5112879 },
    { lat: 49.0024593, lng: 21.5124267 },
    { lat: 49.0009656, lng: 21.513786 },
    { lat: 48.9982551, lng: 21.5167031 },
    { lat: 48.9981409, lng: 21.5168812 },
    { lat: 48.9979359, lng: 21.5178642 },
    { lat: 48.9935099, lng: 21.5222118 },
    { lat: 48.9928172, lng: 21.523341 },
    { lat: 48.9927064, lng: 21.5234411 },
    { lat: 48.9910696, lng: 21.5262975 },
    { lat: 48.9896264, lng: 21.529275 },
    { lat: 48.9894496, lng: 21.5304971 },
    { lat: 48.9889898, lng: 21.5305129 },
    { lat: 48.9868273, lng: 21.5332113 },
    { lat: 48.9852388, lng: 21.5359514 },
    { lat: 48.9846582, lng: 21.5367054 },
    { lat: 48.9843804, lng: 21.5371857 },
    { lat: 48.9842312, lng: 21.5376446 },
    { lat: 48.9838529, lng: 21.5381497 },
    { lat: 48.98357, lng: 21.538722 },
    { lat: 48.9832279, lng: 21.5390062 },
    { lat: 48.9831909, lng: 21.5392088 },
    { lat: 48.9828944, lng: 21.5397841 },
    { lat: 48.9825709, lng: 21.5400005 },
    { lat: 48.9821543, lng: 21.540585 },
    { lat: 48.9819931, lng: 21.5410942 },
    { lat: 48.9812142, lng: 21.5423554 },
    { lat: 48.9815016, lng: 21.5432837 },
    { lat: 48.9813003, lng: 21.543766 },
    { lat: 48.9813063, lng: 21.5449308 },
    { lat: 48.9819235, lng: 21.5463499 },
    { lat: 48.9826291, lng: 21.5473498 },
    { lat: 48.9833501, lng: 21.5481851 },
    { lat: 48.9878691, lng: 21.5495623 },
    { lat: 48.9883687, lng: 21.5499632 },
    { lat: 48.989253, lng: 21.5509983 },
    { lat: 48.9913454, lng: 21.5519096 },
    { lat: 48.9913359, lng: 21.5513736 },
    { lat: 48.9919294, lng: 21.5511081 },
    { lat: 48.9929172, lng: 21.5515512 },
    { lat: 48.9937598, lng: 21.5515486 },
    { lat: 48.9937742, lng: 21.5518168 },
    { lat: 48.9951302, lng: 21.5523075 },
    { lat: 48.9958628, lng: 21.5527113 },
    { lat: 48.9960386, lng: 21.5527607 },
    { lat: 48.9968213, lng: 21.552349 },
    { lat: 48.9977473, lng: 21.552431 },
    { lat: 48.9983633, lng: 21.5529721 },
    { lat: 48.9990669, lng: 21.5540647 },
    { lat: 49.0001715, lng: 21.5553376 },
    { lat: 49.0027935, lng: 21.5587961 },
    { lat: 49.0050477, lng: 21.5621379 },
    { lat: 49.0057474, lng: 21.5634206 },
    { lat: 49.0067837, lng: 21.564827 },
    { lat: 49.0070912, lng: 21.5645935 },
    { lat: 49.0071615, lng: 21.5644556 },
    { lat: 49.0073085, lng: 21.5641644 },
    { lat: 49.0078413, lng: 21.5640642 },
    { lat: 49.0080215, lng: 21.5633928 },
    { lat: 49.0083109, lng: 21.5629608 },
    { lat: 49.0097898, lng: 21.5624147 },
    { lat: 49.0107103, lng: 21.5626486 },
    { lat: 49.0111245, lng: 21.5627539 },
    { lat: 49.011862, lng: 21.5630074 },
    { lat: 49.0148605, lng: 21.563019 },
    { lat: 49.0153714, lng: 21.5638285 },
    { lat: 49.0168198, lng: 21.5653615 },
    { lat: 49.0173896, lng: 21.5656626 },
    { lat: 49.0180968, lng: 21.5662469 },
    { lat: 49.0200577, lng: 21.562078 },
    { lat: 49.0208663, lng: 21.5631415 },
    { lat: 49.0213594, lng: 21.562389 },
    { lat: 49.0225879, lng: 21.5633126 },
    { lat: 49.0225281, lng: 21.5615001 },
    { lat: 49.0220529, lng: 21.5608064 },
    { lat: 49.0229281, lng: 21.5594418 },
    { lat: 49.0239702, lng: 21.5572895 },
    { lat: 49.0247628, lng: 21.5557559 },
    { lat: 49.0248417, lng: 21.5556773 },
    { lat: 49.0247834, lng: 21.5554818 },
    { lat: 49.0248544, lng: 21.5552043 },
    { lat: 49.0256923, lng: 21.5537993 },
    { lat: 49.026482, lng: 21.5531604 },
    { lat: 49.0268593, lng: 21.552526 },
    { lat: 49.0268165, lng: 21.5524133 },
    { lat: 49.0272706, lng: 21.5517513 },
    { lat: 49.0274633, lng: 21.5499645 },
    { lat: 49.027295, lng: 21.5494201 },
    { lat: 49.0272008, lng: 21.5480796 },
    { lat: 49.0273378, lng: 21.5467184 },
    { lat: 49.0277696, lng: 21.5457467 },
    { lat: 49.0277765, lng: 21.5455102 },
    { lat: 49.0280195, lng: 21.5453488 },
    { lat: 49.0280782, lng: 21.5450395 },
    { lat: 49.0285166, lng: 21.5444276 },
    { lat: 49.0287871, lng: 21.5442898 },
    { lat: 49.0289114, lng: 21.5439468 },
    { lat: 49.0294093, lng: 21.5430965 },
    { lat: 49.03021, lng: 21.5418872 },
    { lat: 49.0305571, lng: 21.5412697 },
    { lat: 49.0306724, lng: 21.5409309 },
    { lat: 49.030647, lng: 21.5406633 },
    { lat: 49.0307487, lng: 21.5400855 },
    { lat: 49.0311003, lng: 21.5389098 },
    { lat: 49.031343, lng: 21.5375891 },
    { lat: 49.0314754, lng: 21.5373352 },
    { lat: 49.0316406, lng: 21.5368174 },
    { lat: 49.0330069, lng: 21.5344856 },
    { lat: 49.0321509, lng: 21.5335793 },
    { lat: 49.0316952, lng: 21.5332147 },
    { lat: 49.031607, lng: 21.5330821 },
  ],
  Petkovce: [
    { lat: 48.997281, lng: 21.5933917 },
    { lat: 48.9967244, lng: 21.5936905 },
    { lat: 48.9967548, lng: 21.5944621 },
    { lat: 48.9963834, lng: 21.5984199 },
    { lat: 48.996542, lng: 21.5995538 },
    { lat: 48.997022, lng: 21.6013621 },
    { lat: 48.9973112, lng: 21.6019706 },
    { lat: 48.9974003, lng: 21.6036868 },
    { lat: 48.9972046, lng: 21.604331 },
    { lat: 48.9971691, lng: 21.6059341 },
    { lat: 48.9969688, lng: 21.6062626 },
    { lat: 48.9966319, lng: 21.6070097 },
    { lat: 48.9964183, lng: 21.6081158 },
    { lat: 48.9963419, lng: 21.6087727 },
    { lat: 48.9961361, lng: 21.6090937 },
    { lat: 48.9960375, lng: 21.6096808 },
    { lat: 48.9958031, lng: 21.6098734 },
    { lat: 48.9957146, lng: 21.6105362 },
    { lat: 48.9956022, lng: 21.6109891 },
    { lat: 48.9952552, lng: 21.6116622 },
    { lat: 48.9951758, lng: 21.6119615 },
    { lat: 48.9951682, lng: 21.6125416 },
    { lat: 48.994965, lng: 21.6131619 },
    { lat: 48.9947879, lng: 21.6133505 },
    { lat: 48.9946472, lng: 21.6137601 },
    { lat: 48.9945254, lng: 21.613703 },
    { lat: 48.9942481, lng: 21.6152365 },
    { lat: 48.9943939, lng: 21.6156245 },
    { lat: 48.994395, lng: 21.6158702 },
    { lat: 48.9942489, lng: 21.6161945 },
    { lat: 48.9943178, lng: 21.6161625 },
    { lat: 48.9960868, lng: 21.6169192 },
    { lat: 48.9964441, lng: 21.6170168 },
    { lat: 48.9996579, lng: 21.6174979 },
    { lat: 49.0002724, lng: 21.617749 },
    { lat: 49.0015689, lng: 21.6191107 },
    { lat: 49.0023385, lng: 21.6202075 },
    { lat: 49.0027483, lng: 21.6209529 },
    { lat: 49.0033246, lng: 21.6215741 },
    { lat: 49.0039237, lng: 21.6220028 },
    { lat: 49.0062896, lng: 21.6227792 },
    { lat: 49.0088227, lng: 21.6231282 },
    { lat: 49.0095605, lng: 21.623331 },
    { lat: 49.0116524, lng: 21.6241199 },
    { lat: 49.0122005, lng: 21.6242466 },
    { lat: 49.0131619, lng: 21.6241894 },
    { lat: 49.0141427, lng: 21.624304 },
    { lat: 49.0159331, lng: 21.6251373 },
    { lat: 49.0170434, lng: 21.624972 },
    { lat: 49.0169387, lng: 21.6243106 },
    { lat: 49.0168963, lng: 21.6235513 },
    { lat: 49.0169702, lng: 21.6220663 },
    { lat: 49.0170901, lng: 21.6211579 },
    { lat: 49.0171376, lng: 21.6210423 },
    { lat: 49.017133, lng: 21.6203 },
    { lat: 49.0172992, lng: 21.6199689 },
    { lat: 49.0173904, lng: 21.6195993 },
    { lat: 49.0174568, lng: 21.61899 },
    { lat: 49.0174238, lng: 21.6187323 },
    { lat: 49.0176588, lng: 21.6175033 },
    { lat: 49.0179596, lng: 21.6169884 },
    { lat: 49.0182633, lng: 21.6166231 },
    { lat: 49.0186279, lng: 21.6156474 },
    { lat: 49.0187053, lng: 21.6152321 },
    { lat: 49.0189477, lng: 21.6144718 },
    { lat: 49.0192676, lng: 21.6137129 },
    { lat: 49.019383, lng: 21.6131704 },
    { lat: 49.0194043, lng: 21.6125525 },
    { lat: 49.0195591, lng: 21.6120128 },
    { lat: 49.0195603, lng: 21.6107065 },
    { lat: 49.0193684, lng: 21.6103582 },
    { lat: 49.0189726, lng: 21.6101624 },
    { lat: 49.0188525, lng: 21.6100134 },
    { lat: 49.0187266, lng: 21.6095293 },
    { lat: 49.0183914, lng: 21.6093672 },
    { lat: 49.0179288, lng: 21.6093599 },
    { lat: 49.0177591, lng: 21.6090883 },
    { lat: 49.0173709, lng: 21.6089206 },
    { lat: 49.0172941, lng: 21.6089883 },
    { lat: 49.0166663, lng: 21.6081725 },
    { lat: 49.0163299, lng: 21.6076449 },
    { lat: 49.0158151, lng: 21.6070514 },
    { lat: 49.015526, lng: 21.6064046 },
    { lat: 49.0151191, lng: 21.6056895 },
    { lat: 49.0140511, lng: 21.6040945 },
    { lat: 49.0137203, lng: 21.6034071 },
    { lat: 49.0123658, lng: 21.6023491 },
    { lat: 49.0115367, lng: 21.6016192 },
    { lat: 49.0109619, lng: 21.6017453 },
    { lat: 49.0105414, lng: 21.6013898 },
    { lat: 49.0104355, lng: 21.600939 },
    { lat: 49.009761, lng: 21.5996225 },
    { lat: 49.0097705, lng: 21.5995133 },
    { lat: 49.0095424, lng: 21.5989581 },
    { lat: 49.0097981, lng: 21.5978343 },
    { lat: 49.0097935, lng: 21.5977102 },
    { lat: 49.0102125, lng: 21.59672 },
    { lat: 49.0099218, lng: 21.5961359 },
    { lat: 49.0067177, lng: 21.5945573 },
    { lat: 49.0014852, lng: 21.590681 },
    { lat: 49.0001566, lng: 21.5914981 },
    { lat: 48.9984002, lng: 21.5927422 },
    { lat: 48.9979356, lng: 21.5929509 },
    { lat: 48.997281, lng: 21.5933917 },
  ],
  Prosačov: [
    { lat: 49.0680409, lng: 21.5589623 },
    { lat: 49.0683544, lng: 21.5589096 },
    { lat: 49.0686008, lng: 21.5588685 },
    { lat: 49.0688616, lng: 21.5587019 },
    { lat: 49.0692836, lng: 21.5601467 },
    { lat: 49.0694413, lng: 21.5600701 },
    { lat: 49.0700897, lng: 21.5592844 },
    { lat: 49.0702804, lng: 21.5596287 },
    { lat: 49.0709498, lng: 21.5589404 },
    { lat: 49.0711747, lng: 21.5587804 },
    { lat: 49.0721644, lng: 21.5583285 },
    { lat: 49.0727156, lng: 21.5582865 },
    { lat: 49.0727455, lng: 21.5583858 },
    { lat: 49.0732242, lng: 21.5587264 },
    { lat: 49.0744344, lng: 21.5588806 },
    { lat: 49.0751039, lng: 21.5591632 },
    { lat: 49.0755416, lng: 21.5591617 },
    { lat: 49.075539, lng: 21.5592282 },
    { lat: 49.0759582, lng: 21.5592961 },
    { lat: 49.0762803, lng: 21.5594226 },
    { lat: 49.0768472, lng: 21.5597414 },
    { lat: 49.0779279, lng: 21.5601692 },
    { lat: 49.0788184, lng: 21.5599875 },
    { lat: 49.0792, lng: 21.559968 },
    { lat: 49.079153, lng: 21.559136 },
    { lat: 49.079179, lng: 21.55866 },
    { lat: 49.07915, lng: 21.558138 },
    { lat: 49.077701, lng: 21.558059 },
    { lat: 49.077746, lng: 21.557872 },
    { lat: 49.078066, lng: 21.557362 },
    { lat: 49.078196, lng: 21.557389 },
    { lat: 49.078287, lng: 21.556999 },
    { lat: 49.078303, lng: 21.556836 },
    { lat: 49.078355, lng: 21.556596 },
    { lat: 49.078799, lng: 21.55593 },
    { lat: 49.07905, lng: 21.555338 },
    { lat: 49.079112, lng: 21.555271 },
    { lat: 49.079224, lng: 21.555 },
    { lat: 49.079425, lng: 21.554345 },
    { lat: 49.079601, lng: 21.554037 },
    { lat: 49.079583, lng: 21.553589 },
    { lat: 49.079369, lng: 21.55359 },
    { lat: 49.07931, lng: 21.553701 },
    { lat: 49.078949, lng: 21.553853 },
    { lat: 49.078877, lng: 21.553916 },
    { lat: 49.078476, lng: 21.554012 },
    { lat: 49.078183, lng: 21.554033 },
    { lat: 49.07801, lng: 21.554256 },
    { lat: 49.077842, lng: 21.554227 },
    { lat: 49.07766, lng: 21.554351 },
    { lat: 49.077474, lng: 21.554305 },
    { lat: 49.077024, lng: 21.554354 },
    { lat: 49.076841, lng: 21.554412 },
    { lat: 49.076461, lng: 21.554547 },
    { lat: 49.076172, lng: 21.554781 },
    { lat: 49.075723, lng: 21.554855 },
    { lat: 49.075628, lng: 21.554562 },
    { lat: 49.075411, lng: 21.554158 },
    { lat: 49.07523, lng: 21.554075 },
    { lat: 49.075095, lng: 21.553882 },
    { lat: 49.075054, lng: 21.55379 },
    { lat: 49.074751, lng: 21.553516 },
    { lat: 49.074641, lng: 21.553331 },
    { lat: 49.074618, lng: 21.55315 },
    { lat: 49.07466, lng: 21.553055 },
    { lat: 49.074609, lng: 21.552334 },
    { lat: 49.074262, lng: 21.550688 },
    { lat: 49.0737052, lng: 21.5512203 },
    { lat: 49.0735956, lng: 21.5514062 },
    { lat: 49.0733508, lng: 21.5515879 },
    { lat: 49.0719527, lng: 21.5514013 },
    { lat: 49.0711416, lng: 21.5504423 },
    { lat: 49.0710022, lng: 21.5501216 },
    { lat: 49.0703416, lng: 21.5497625 },
    { lat: 49.0702364, lng: 21.5495425 },
    { lat: 49.0701172, lng: 21.5495044 },
    { lat: 49.069961, lng: 21.5493065 },
    { lat: 49.0697803, lng: 21.5493175 },
    { lat: 49.069569, lng: 21.5488918 },
    { lat: 49.0694093, lng: 21.548853 },
    { lat: 49.0692438, lng: 21.5486507 },
    { lat: 49.068918, lng: 21.5485858 },
    { lat: 49.0688511, lng: 21.5483717 },
    { lat: 49.0685449, lng: 21.5482366 },
    { lat: 49.068305, lng: 21.5482343 },
    { lat: 49.0681368, lng: 21.5480964 },
    { lat: 49.0679807, lng: 21.5481016 },
    { lat: 49.0676446, lng: 21.5478702 },
    { lat: 49.0673054, lng: 21.5474546 },
    { lat: 49.0671982, lng: 21.5474831 },
    { lat: 49.0670383, lng: 21.547237 },
    { lat: 49.0669752, lng: 21.5472741 },
    { lat: 49.0665795, lng: 21.5468016 },
    { lat: 49.0665121, lng: 21.5468523 },
    { lat: 49.0660163, lng: 21.5467447 },
    { lat: 49.0658127, lng: 21.546547 },
    { lat: 49.0655852, lng: 21.5466577 },
    { lat: 49.0652257, lng: 21.5463564 },
    { lat: 49.0649757, lng: 21.5464689 },
    { lat: 49.0647996, lng: 21.5460416 },
    { lat: 49.0645053, lng: 21.5459413 },
    { lat: 49.0643357, lng: 21.5456367 },
    { lat: 49.0641914, lng: 21.5456257 },
    { lat: 49.064033, lng: 21.5452334 },
    { lat: 49.0632313, lng: 21.5445284 },
    { lat: 49.0629833, lng: 21.5444373 },
    { lat: 49.0628372, lng: 21.5440045 },
    { lat: 49.0626729, lng: 21.5439654 },
    { lat: 49.0627014, lng: 21.5436948 },
    { lat: 49.062635, lng: 21.543457 },
    { lat: 49.0625152, lng: 21.543355 },
    { lat: 49.0622881, lng: 21.5427998 },
    { lat: 49.0621466, lng: 21.5428499 },
    { lat: 49.0621882, lng: 21.5424102 },
    { lat: 49.0620509, lng: 21.5421311 },
    { lat: 49.0618897, lng: 21.5420817 },
    { lat: 49.0617942, lng: 21.5419646 },
    { lat: 49.0617819, lng: 21.5416887 },
    { lat: 49.0616676, lng: 21.5414243 },
    { lat: 49.0615068, lng: 21.5415073 },
    { lat: 49.0612705, lng: 21.5411108 },
    { lat: 49.0614285, lng: 21.5408135 },
    { lat: 49.061442, lng: 21.5405321 },
    { lat: 49.0611251, lng: 21.5396279 },
    { lat: 49.0610924, lng: 21.5393032 },
    { lat: 49.0606048, lng: 21.5387335 },
    { lat: 49.0606569, lng: 21.5376671 },
    { lat: 49.0607873, lng: 21.5368678 },
    { lat: 49.0609134, lng: 21.5366748 },
    { lat: 49.0601673, lng: 21.536709 },
    { lat: 49.059591, lng: 21.5365393 },
    { lat: 49.0583434, lng: 21.535708 },
    { lat: 49.0574475, lng: 21.5353412 },
    { lat: 49.0569635, lng: 21.5352473 },
    { lat: 49.0564726, lng: 21.5352397 },
    { lat: 49.0559083, lng: 21.5350187 },
    { lat: 49.0556227, lng: 21.5350234 },
    { lat: 49.0550896, lng: 21.5351871 },
    { lat: 49.0542321, lng: 21.5352594 },
    { lat: 49.0536167, lng: 21.5352165 },
    { lat: 49.0527386, lng: 21.535519 },
    { lat: 49.0519648, lng: 21.5354857 },
    { lat: 49.0516335, lng: 21.5353901 },
    { lat: 49.0513012, lng: 21.5351368 },
    { lat: 49.0507702, lng: 21.5345449 },
    { lat: 49.0499589, lng: 21.5343746 },
    { lat: 49.048856, lng: 21.5334164 },
    { lat: 49.0486525, lng: 21.5332991 },
    { lat: 49.0468392, lng: 21.5316795 },
    { lat: 49.0459649, lng: 21.5305608 },
    { lat: 49.0457641, lng: 21.53039 },
    { lat: 49.0454653, lng: 21.5303604 },
    { lat: 49.0452142, lng: 21.5302033 },
    { lat: 49.0449974, lng: 21.530172 },
    { lat: 49.0448998, lng: 21.5300245 },
    { lat: 49.0446933, lng: 21.5294431 },
    { lat: 49.0446342, lng: 21.5295032 },
    { lat: 49.0445741, lng: 21.5293716 },
    { lat: 49.0442213, lng: 21.5284347 },
    { lat: 49.043903, lng: 21.5285183 },
    { lat: 49.0437045, lng: 21.5285023 },
    { lat: 49.0431341, lng: 21.5283005 },
    { lat: 49.0422533, lng: 21.5277934 },
    { lat: 49.0422987, lng: 21.5275211 },
    { lat: 49.0421648, lng: 21.5274051 },
    { lat: 49.0418099, lng: 21.5273449 },
    { lat: 49.0414605, lng: 21.5271279 },
    { lat: 49.0416876, lng: 21.5262092 },
    { lat: 49.0411962, lng: 21.5259829 },
    { lat: 49.0407791, lng: 21.5259153 },
    { lat: 49.0401335, lng: 21.5252458 },
    { lat: 49.0395137, lng: 21.526518 },
    { lat: 49.0392256, lng: 21.5269156 },
    { lat: 49.0390589, lng: 21.5267099 },
    { lat: 49.0387993, lng: 21.5272 },
    { lat: 49.0385413, lng: 21.527536 },
    { lat: 49.0381291, lng: 21.5275262 },
    { lat: 49.0377866, lng: 21.5278258 },
    { lat: 49.0374723, lng: 21.5276076 },
    { lat: 49.0371454, lng: 21.527586 },
    { lat: 49.0363937, lng: 21.5281417 },
    { lat: 49.0359535, lng: 21.5288885 },
    { lat: 49.0358323, lng: 21.529213 },
    { lat: 49.0356745, lng: 21.5301561 },
    { lat: 49.0354883, lng: 21.5305333 },
    { lat: 49.035461, lng: 21.5313573 },
    { lat: 49.0351729, lng: 21.5320672 },
    { lat: 49.0350111, lng: 21.5329462 },
    { lat: 49.0344757, lng: 21.5342607 },
    { lat: 49.0345476, lng: 21.5344604 },
    { lat: 49.0341904, lng: 21.5349596 },
    { lat: 49.0341513, lng: 21.5346531 },
    { lat: 49.0339577, lng: 21.5343674 },
    { lat: 49.0337594, lng: 21.5342334 },
    { lat: 49.0331614, lng: 21.5332643 },
    { lat: 49.0326208, lng: 21.5326901 },
    { lat: 49.0321507, lng: 21.5327001 },
    { lat: 49.031607, lng: 21.5330821 },
    { lat: 49.0316952, lng: 21.5332147 },
    { lat: 49.0321509, lng: 21.5335793 },
    { lat: 49.0330069, lng: 21.5344856 },
    { lat: 49.0332327, lng: 21.5347519 },
    { lat: 49.0336976, lng: 21.5353149 },
    { lat: 49.0343347, lng: 21.5362761 },
    { lat: 49.0347852, lng: 21.5368089 },
    { lat: 49.0356653, lng: 21.5380935 },
    { lat: 49.0364434, lng: 21.5388397 },
    { lat: 49.036898, lng: 21.5395892 },
    { lat: 49.0369964, lng: 21.539534 },
    { lat: 49.0370365, lng: 21.5394349 },
    { lat: 49.0372941, lng: 21.5394414 },
    { lat: 49.0375069, lng: 21.5395228 },
    { lat: 49.037662, lng: 21.5394804 },
    { lat: 49.0389184, lng: 21.5401069 },
    { lat: 49.0390965, lng: 21.540234 },
    { lat: 49.0390532, lng: 21.5402957 },
    { lat: 49.0393124, lng: 21.5409515 },
    { lat: 49.0399309, lng: 21.5406941 },
    { lat: 49.0400325, lng: 21.5410694 },
    { lat: 49.0409297, lng: 21.540651 },
    { lat: 49.0412124, lng: 21.541376 },
    { lat: 49.0412974, lng: 21.5417133 },
    { lat: 49.0416608, lng: 21.541436 },
    { lat: 49.0420823, lng: 21.542343 },
    { lat: 49.0423694, lng: 21.5421894 },
    { lat: 49.0434196, lng: 21.5440004 },
    { lat: 49.043947, lng: 21.5445842 },
    { lat: 49.0452615, lng: 21.5466363 },
    { lat: 49.0456484, lng: 21.5471896 },
    { lat: 49.0461385, lng: 21.5477343 },
    { lat: 49.0463215, lng: 21.5482044 },
    { lat: 49.0470227, lng: 21.5494509 },
    { lat: 49.0485274, lng: 21.5495906 },
    { lat: 49.0500429, lng: 21.5489057 },
    { lat: 49.05091, lng: 21.5484205 },
    { lat: 49.052024, lng: 21.5480272 },
    { lat: 49.0549207, lng: 21.5461778 },
    { lat: 49.0549775, lng: 21.5462347 },
    { lat: 49.055091, lng: 21.5466742 },
    { lat: 49.0552725, lng: 21.5468077 },
    { lat: 49.0551963, lng: 21.5470892 },
    { lat: 49.0552115, lng: 21.5473106 },
    { lat: 49.055494, lng: 21.5480731 },
    { lat: 49.0557341, lng: 21.5484876 },
    { lat: 49.0561537, lng: 21.5489308 },
    { lat: 49.0564747, lng: 21.5495014 },
    { lat: 49.0567042, lng: 21.5501333 },
    { lat: 49.0569469, lng: 21.5498032 },
    { lat: 49.0571479, lng: 21.5496742 },
    { lat: 49.0572683, lng: 21.5492793 },
    { lat: 49.057269, lng: 21.5490615 },
    { lat: 49.0573875, lng: 21.5488335 },
    { lat: 49.057726, lng: 21.5489054 },
    { lat: 49.058177, lng: 21.5487795 },
    { lat: 49.0585524, lng: 21.5501319 },
    { lat: 49.0593142, lng: 21.5508108 },
    { lat: 49.0598192, lng: 21.5521698 },
    { lat: 49.0599688, lng: 21.5527248 },
    { lat: 49.0612958, lng: 21.5536303 },
    { lat: 49.0627283, lng: 21.5543948 },
    { lat: 49.0634231, lng: 21.5548967 },
    { lat: 49.0637242, lng: 21.5554016 },
    { lat: 49.0647492, lng: 21.5556079 },
    { lat: 49.0653634, lng: 21.5558471 },
    { lat: 49.0655019, lng: 21.5560515 },
    { lat: 49.0655076, lng: 21.5562557 },
    { lat: 49.065449, lng: 21.5564088 },
    { lat: 49.0654379, lng: 21.5568533 },
    { lat: 49.0653521, lng: 21.5572055 },
    { lat: 49.0653621, lng: 21.5573182 },
    { lat: 49.0652615, lng: 21.5575407 },
    { lat: 49.0652481, lng: 21.5577404 },
    { lat: 49.0650449, lng: 21.5578422 },
    { lat: 49.0648247, lng: 21.5582964 },
    { lat: 49.0650551, lng: 21.5584407 },
    { lat: 49.0652111, lng: 21.5583534 },
    { lat: 49.065576, lng: 21.5584879 },
    { lat: 49.0659679, lng: 21.5584983 },
    { lat: 49.0660161, lng: 21.5586129 },
    { lat: 49.0664946, lng: 21.5586682 },
    { lat: 49.0669766, lng: 21.5584449 },
    { lat: 49.0675916, lng: 21.5586704 },
    { lat: 49.0680621, lng: 21.5586337 },
    { lat: 49.0680409, lng: 21.5589623 },
  ],
  Poša: [
    { lat: 48.8215595, lng: 21.7517887 },
    { lat: 48.8224739, lng: 21.7525053 },
    { lat: 48.8227026, lng: 21.7534899 },
    { lat: 48.8244461, lng: 21.759799 },
    { lat: 48.8262298, lng: 21.7586352 },
    { lat: 48.8271564, lng: 21.7587821 },
    { lat: 48.8274285, lng: 21.7609932 },
    { lat: 48.8274848, lng: 21.7612044 },
    { lat: 48.8259946, lng: 21.7621122 },
    { lat: 48.8276157, lng: 21.7673828 },
    { lat: 48.8282873, lng: 21.7664902 },
    { lat: 48.8288983, lng: 21.767559 },
    { lat: 48.8290883, lng: 21.7677717 },
    { lat: 48.8293943, lng: 21.7687462 },
    { lat: 48.8284065, lng: 21.7693891 },
    { lat: 48.8288818, lng: 21.7703454 },
    { lat: 48.8289743, lng: 21.7703134 },
    { lat: 48.829276, lng: 21.7704883 },
    { lat: 48.829385, lng: 21.7727441 },
    { lat: 48.8290966, lng: 21.774735 },
    { lat: 48.8301448, lng: 21.7752755 },
    { lat: 48.8302302, lng: 21.7765568 },
    { lat: 48.8301692, lng: 21.7772725 },
    { lat: 48.8297549, lng: 21.7797778 },
    { lat: 48.8294136, lng: 21.7815176 },
    { lat: 48.8292647, lng: 21.78257 },
    { lat: 48.8290237, lng: 21.7834671 },
    { lat: 48.8287472, lng: 21.7848849 },
    { lat: 48.8286907, lng: 21.785435 },
    { lat: 48.828146, lng: 21.7879522 },
    { lat: 48.8273857, lng: 21.791999 },
    { lat: 48.8273941, lng: 21.7923974 },
    { lat: 48.8270048, lng: 21.7940328 },
    { lat: 48.826706, lng: 21.795667 },
    { lat: 48.828, lng: 21.79622 },
    { lat: 48.828812, lng: 21.796184 },
    { lat: 48.829658, lng: 21.796456 },
    { lat: 48.830851, lng: 21.796725 },
    { lat: 48.831723, lng: 21.796966 },
    { lat: 48.832404, lng: 21.796854 },
    { lat: 48.832578, lng: 21.796849 },
    { lat: 48.83281, lng: 21.79684 },
    { lat: 48.832904, lng: 21.796837 },
    { lat: 48.832934, lng: 21.796836 },
    { lat: 48.832947, lng: 21.796835 },
    { lat: 48.833193, lng: 21.796862 },
    { lat: 48.833514, lng: 21.796898 },
    { lat: 48.833745, lng: 21.796923 },
    { lat: 48.834749, lng: 21.797459 },
    { lat: 48.835848, lng: 21.797817 },
    { lat: 48.83644, lng: 21.797757 },
    { lat: 48.83644, lng: 21.797863 },
    { lat: 48.836911, lng: 21.797342 },
    { lat: 48.837165, lng: 21.797061 },
    { lat: 48.83726, lng: 21.796956 },
    { lat: 48.837282, lng: 21.79693 },
    { lat: 48.837411, lng: 21.796781 },
    { lat: 48.837671, lng: 21.796482 },
    { lat: 48.837891, lng: 21.796227 },
    { lat: 48.838001, lng: 21.796101 },
    { lat: 48.838208, lng: 21.795973 },
    { lat: 48.83873, lng: 21.795652 },
    { lat: 48.839489, lng: 21.795545 },
    { lat: 48.84071, lng: 21.7951 },
    { lat: 48.84149, lng: 21.794534 },
    { lat: 48.84238, lng: 21.79426 },
    { lat: 48.843173, lng: 21.794027 },
    { lat: 48.843256, lng: 21.794003 },
    { lat: 48.844034, lng: 21.793643 },
    { lat: 48.844192, lng: 21.793603 },
    { lat: 48.844822, lng: 21.793441 },
    { lat: 48.845214, lng: 21.793131 },
    { lat: 48.845472, lng: 21.792926 },
    { lat: 48.845492, lng: 21.792911 },
    { lat: 48.8446967, lng: 21.7921507 },
    { lat: 48.843782, lng: 21.7918878 },
    { lat: 48.8434268, lng: 21.7911292 },
    { lat: 48.8429595, lng: 21.7902972 },
    { lat: 48.84369, lng: 21.7860269 },
    { lat: 48.8438978, lng: 21.785583 },
    { lat: 48.8443778, lng: 21.7823141 },
    { lat: 48.8446984, lng: 21.7804689 },
    { lat: 48.8447159, lng: 21.7798517 },
    { lat: 48.8448848, lng: 21.7783603 },
    { lat: 48.8447515, lng: 21.7771915 },
    { lat: 48.8447869, lng: 21.775796 },
    { lat: 48.8440208, lng: 21.775707 },
    { lat: 48.8440465, lng: 21.7745528 },
    { lat: 48.8441868, lng: 21.7721945 },
    { lat: 48.8441989, lng: 21.7702658 },
    { lat: 48.8436631, lng: 21.7665019 },
    { lat: 48.8435532, lng: 21.7660903 },
    { lat: 48.8435143, lng: 21.7657237 },
    { lat: 48.8440019, lng: 21.7631503 },
    { lat: 48.8441767, lng: 21.761845 },
    { lat: 48.8442299, lng: 21.7580753 },
    { lat: 48.844146, lng: 21.7543133 },
    { lat: 48.8439137, lng: 21.7541217 },
    { lat: 48.8438433, lng: 21.7536835 },
    { lat: 48.8438497, lng: 21.753282 },
    { lat: 48.844347, lng: 21.7518416 },
    { lat: 48.843776, lng: 21.7508431 },
    { lat: 48.843003, lng: 21.7503291 },
    { lat: 48.8430139, lng: 21.7493462 },
    { lat: 48.8432689, lng: 21.7480563 },
    { lat: 48.8437006, lng: 21.7478367 },
    { lat: 48.8438467, lng: 21.7476667 },
    { lat: 48.8439881, lng: 21.7473541 },
    { lat: 48.8439971, lng: 21.7468085 },
    { lat: 48.8438072, lng: 21.74597 },
    { lat: 48.8431039, lng: 21.7436807 },
    { lat: 48.8411867, lng: 21.7366182 },
    { lat: 48.8408387, lng: 21.7344173 },
    { lat: 48.8418046, lng: 21.7343999 },
    { lat: 48.8423377, lng: 21.7346837 },
    { lat: 48.8410714, lng: 21.7285575 },
    { lat: 48.8408443, lng: 21.7275064 },
    { lat: 48.8395176, lng: 21.728426 },
    { lat: 48.8393151, lng: 21.7273345 },
    { lat: 48.8387052, lng: 21.7282966 },
    { lat: 48.8379252, lng: 21.7291616 },
    { lat: 48.8371243, lng: 21.7306871 },
    { lat: 48.8360621, lng: 21.7331596 },
    { lat: 48.8359553, lng: 21.7336708 },
    { lat: 48.8343586, lng: 21.7317663 },
    { lat: 48.8329352, lng: 21.7339019 },
    { lat: 48.8315301, lng: 21.7358902 },
    { lat: 48.8317822, lng: 21.7363951 },
    { lat: 48.830701, lng: 21.737365 },
    { lat: 48.8303231, lng: 21.7376234 },
    { lat: 48.8289338, lng: 21.7382569 },
    { lat: 48.8294401, lng: 21.7406237 },
    { lat: 48.8281483, lng: 21.7414953 },
    { lat: 48.8245725, lng: 21.7413097 },
    { lat: 48.8270294, lng: 21.7525539 },
    { lat: 48.8266752, lng: 21.752905 },
    { lat: 48.8264351, lng: 21.752708 },
    { lat: 48.8257977, lng: 21.7521851 },
    { lat: 48.825008, lng: 21.7517144 },
    { lat: 48.8238731, lng: 21.7506685 },
    { lat: 48.8231535, lng: 21.7503449 },
    { lat: 48.8225023, lng: 21.7501386 },
    { lat: 48.8215595, lng: 21.7517887 },
  ],
  Radvanovce: [
    { lat: 49.0382659, lng: 21.4579309 },
    { lat: 49.0385845, lng: 21.4578221 },
    { lat: 49.0390139, lng: 21.4579615 },
    { lat: 49.0393942, lng: 21.4578894 },
    { lat: 49.0394298, lng: 21.4582632 },
    { lat: 49.0395899, lng: 21.4584658 },
    { lat: 49.0395407, lng: 21.4587851 },
    { lat: 49.0402293, lng: 21.4598353 },
    { lat: 49.0404208, lng: 21.4602664 },
    { lat: 49.0406092, lng: 21.4605594 },
    { lat: 49.0408478, lng: 21.4605197 },
    { lat: 49.0409553, lng: 21.4603881 },
    { lat: 49.0415333, lng: 21.4620163 },
    { lat: 49.0416733, lng: 21.4625366 },
    { lat: 49.0418984, lng: 21.4626862 },
    { lat: 49.0421126, lng: 21.4629372 },
    { lat: 49.042724, lng: 21.4639665 },
    { lat: 49.0433898, lng: 21.4639983 },
    { lat: 49.0436462, lng: 21.464106 },
    { lat: 49.0437458, lng: 21.4644788 },
    { lat: 49.0441262, lng: 21.4644092 },
    { lat: 49.0443553, lng: 21.4646654 },
    { lat: 49.0446576, lng: 21.4643414 },
    { lat: 49.0448072, lng: 21.4643805 },
    { lat: 49.0452116, lng: 21.464668 },
    { lat: 49.0453616, lng: 21.4646038 },
    { lat: 49.0456449, lng: 21.4646735 },
    { lat: 49.0460325, lng: 21.4651231 },
    { lat: 49.0463273, lng: 21.4660951 },
    { lat: 49.0467745, lng: 21.4662654 },
    { lat: 49.0472023, lng: 21.4669785 },
    { lat: 49.0473529, lng: 21.467052 },
    { lat: 49.0475376, lng: 21.4672488 },
    { lat: 49.0478208, lng: 21.4676678 },
    { lat: 49.0478172, lng: 21.4678922 },
    { lat: 49.048434, lng: 21.4683664 },
    { lat: 49.0486149, lng: 21.468868 },
    { lat: 49.0488077, lng: 21.4690366 },
    { lat: 49.0486228, lng: 21.4696063 },
    { lat: 49.0485451, lng: 21.4697159 },
    { lat: 49.0484239, lng: 21.4697337 },
    { lat: 49.0484936, lng: 21.4698463 },
    { lat: 49.0484676, lng: 21.4699969 },
    { lat: 49.0482926, lng: 21.4702076 },
    { lat: 49.0484265, lng: 21.4701736 },
    { lat: 49.0484482, lng: 21.4705746 },
    { lat: 49.0483686, lng: 21.4707736 },
    { lat: 49.048125, lng: 21.4709527 },
    { lat: 49.0481115, lng: 21.4712276 },
    { lat: 49.0480343, lng: 21.4713978 },
    { lat: 49.0480862, lng: 21.4715209 },
    { lat: 49.0480484, lng: 21.471772 },
    { lat: 49.0497548, lng: 21.4741399 },
    { lat: 49.0503763, lng: 21.474894 },
    { lat: 49.0506783, lng: 21.4753728 },
    { lat: 49.0508547, lng: 21.4758033 },
    { lat: 49.0507735, lng: 21.4759113 },
    { lat: 49.0507153, lng: 21.47612 },
    { lat: 49.0509766, lng: 21.4764416 },
    { lat: 49.0511509, lng: 21.4765914 },
    { lat: 49.0512856, lng: 21.4765749 },
    { lat: 49.0515226, lng: 21.4768403 },
    { lat: 49.0521772, lng: 21.478079 },
    { lat: 49.0521701, lng: 21.4783424 },
    { lat: 49.0522589, lng: 21.4784958 },
    { lat: 49.0524048, lng: 21.4784839 },
    { lat: 49.0525815, lng: 21.4789148 },
    { lat: 49.0528337, lng: 21.4790027 },
    { lat: 49.0534005, lng: 21.479658 },
    { lat: 49.0538261, lng: 21.4809964 },
    { lat: 49.0538852, lng: 21.4814441 },
    { lat: 49.0540408, lng: 21.4817315 },
    { lat: 49.054099, lng: 21.4817617 },
    { lat: 49.0541738, lng: 21.4816666 },
    { lat: 49.0544264, lng: 21.4818016 },
    { lat: 49.0543802, lng: 21.4819553 },
    { lat: 49.0544432, lng: 21.4820315 },
    { lat: 49.0542337, lng: 21.4824949 },
    { lat: 49.0543374, lng: 21.4826587 },
    { lat: 49.0548134, lng: 21.4820757 },
    { lat: 49.0549492, lng: 21.4817058 },
    { lat: 49.0550462, lng: 21.4817998 },
    { lat: 49.0559217, lng: 21.4808271 },
    { lat: 49.0567133, lng: 21.4802582 },
    { lat: 49.0571129, lng: 21.4801821 },
    { lat: 49.0575675, lng: 21.4804241 },
    { lat: 49.0578446, lng: 21.4804756 },
    { lat: 49.0578541, lng: 21.480847 },
    { lat: 49.058473, lng: 21.4808883 },
    { lat: 49.0587032, lng: 21.4808313 },
    { lat: 49.0591485, lng: 21.4804695 },
    { lat: 49.059104, lng: 21.480334 },
    { lat: 49.0594999, lng: 21.4798595 },
    { lat: 49.0601347, lng: 21.4784636 },
    { lat: 49.0605895, lng: 21.477963 },
    { lat: 49.0610594, lng: 21.477593 },
    { lat: 49.061533, lng: 21.4771169 },
    { lat: 49.0619596, lng: 21.476932 },
    { lat: 49.0624087, lng: 21.4764328 },
    { lat: 49.0625581, lng: 21.4761922 },
    { lat: 49.062613, lng: 21.4759047 },
    { lat: 49.0625293, lng: 21.4758152 },
    { lat: 49.0626456, lng: 21.4753156 },
    { lat: 49.0638006, lng: 21.4720582 },
    { lat: 49.0641647, lng: 21.4702898 },
    { lat: 49.0642655, lng: 21.4694905 },
    { lat: 49.0643542, lng: 21.4692966 },
    { lat: 49.0643917, lng: 21.4689949 },
    { lat: 49.0644932, lng: 21.4687287 },
    { lat: 49.0646204, lng: 21.4686104 },
    { lat: 49.0649414, lng: 21.4680435 },
    { lat: 49.0653191, lng: 21.4668199 },
    { lat: 49.0656362, lng: 21.4662716 },
    { lat: 49.0658716, lng: 21.465581 },
    { lat: 49.0659886, lng: 21.4648405 },
    { lat: 49.0662506, lng: 21.4643281 },
    { lat: 49.0664542, lng: 21.4640465 },
    { lat: 49.0666206, lng: 21.4635804 },
    { lat: 49.0668423, lng: 21.4632731 },
    { lat: 49.0669245, lng: 21.4632697 },
    { lat: 49.067178, lng: 21.462822 },
    { lat: 49.066925, lng: 21.462714 },
    { lat: 49.066889, lng: 21.462698 },
    { lat: 49.066626, lng: 21.462587 },
    { lat: 49.066036, lng: 21.462229 },
    { lat: 49.065657, lng: 21.461931 },
    { lat: 49.065726, lng: 21.461759 },
    { lat: 49.065447, lng: 21.461552 },
    { lat: 49.065138, lng: 21.461191 },
    { lat: 49.064989, lng: 21.461066 },
    { lat: 49.064927, lng: 21.461199 },
    { lat: 49.06443, lng: 21.460984 },
    { lat: 49.064017, lng: 21.460615 },
    { lat: 49.063143, lng: 21.460069 },
    { lat: 49.062355, lng: 21.459587 },
    { lat: 49.061943, lng: 21.459472 },
    { lat: 49.061731, lng: 21.45934 },
    { lat: 49.061499, lng: 21.459054 },
    { lat: 49.061412, lng: 21.458847 },
    { lat: 49.061359, lng: 21.458544 },
    { lat: 49.061293, lng: 21.458437 },
    { lat: 49.060867, lng: 21.458417 },
    { lat: 49.060555, lng: 21.458404 },
    { lat: 49.060206, lng: 21.458169 },
    { lat: 49.059298, lng: 21.457514 },
    { lat: 49.058644, lng: 21.456954 },
    { lat: 49.057992, lng: 21.456252 },
    { lat: 49.057955, lng: 21.456224 },
    { lat: 49.0575, lng: 21.456737 },
    { lat: 49.057481, lng: 21.4567 },
    { lat: 49.0569, lng: 21.455655 },
    { lat: 49.056648, lng: 21.456031 },
    { lat: 49.055955, lng: 21.455087 },
    { lat: 49.055119, lng: 21.454171 },
    { lat: 49.05496, lng: 21.453922 },
    { lat: 49.054077, lng: 21.452549 },
    { lat: 49.053609, lng: 21.451733 },
    { lat: 49.053183, lng: 21.450842 },
    { lat: 49.053168, lng: 21.450804 },
    { lat: 49.052994, lng: 21.450354 },
    { lat: 49.053001, lng: 21.450341 },
    { lat: 49.053059, lng: 21.450237 },
    { lat: 49.05253, lng: 21.448447 },
    { lat: 49.052872, lng: 21.448066 },
    { lat: 49.052371, lng: 21.446959 },
    { lat: 49.052122, lng: 21.44646 },
    { lat: 49.051858, lng: 21.445933 },
    { lat: 49.051681, lng: 21.445611 },
    { lat: 49.051454, lng: 21.445169 },
    { lat: 49.051127, lng: 21.444458 },
    { lat: 49.050709, lng: 21.443631 },
    { lat: 49.050134, lng: 21.442664 },
    { lat: 49.050021, lng: 21.442955 },
    { lat: 49.049674, lng: 21.443679 },
    { lat: 49.049062, lng: 21.443039 },
    { lat: 49.048758, lng: 21.442631 },
    { lat: 49.047976, lng: 21.441709 },
    { lat: 49.047784, lng: 21.441463 },
    { lat: 49.047555, lng: 21.441223 },
    { lat: 49.046986, lng: 21.440652 },
    { lat: 49.046469, lng: 21.440291 },
    { lat: 49.046384, lng: 21.440175 },
    { lat: 49.046173, lng: 21.439973 },
    { lat: 49.045984, lng: 21.440022 },
    { lat: 49.045779, lng: 21.440199 },
    { lat: 49.045734, lng: 21.440005 },
    { lat: 49.045721, lng: 21.439946 },
    { lat: 49.045717, lng: 21.439928 },
    { lat: 49.0455692, lng: 21.4399888 },
    { lat: 49.0451819, lng: 21.4399425 },
    { lat: 49.0450071, lng: 21.440158 },
    { lat: 49.0446008, lng: 21.4404335 },
    { lat: 49.0444255, lng: 21.4407047 },
    { lat: 49.0437472, lng: 21.4411 },
    { lat: 49.043562, lng: 21.4414626 },
    { lat: 49.043405, lng: 21.4415592 },
    { lat: 49.0433275, lng: 21.4417574 },
    { lat: 49.0432509, lng: 21.4417905 },
    { lat: 49.0432104, lng: 21.442059 },
    { lat: 49.0429666, lng: 21.4424649 },
    { lat: 49.0428334, lng: 21.4427981 },
    { lat: 49.0427408, lng: 21.4434804 },
    { lat: 49.0424744, lng: 21.4441055 },
    { lat: 49.0422704, lng: 21.444328 },
    { lat: 49.0421124, lng: 21.4448547 },
    { lat: 49.0417861, lng: 21.4444667 },
    { lat: 49.0413179, lng: 21.4451154 },
    { lat: 49.0409964, lng: 21.4474473 },
    { lat: 49.0407441, lng: 21.447285 },
    { lat: 49.0405782, lng: 21.4480037 },
    { lat: 49.0406001, lng: 21.4482526 },
    { lat: 49.0406905, lng: 21.4482727 },
    { lat: 49.0404696, lng: 21.4488749 },
    { lat: 49.0403516, lng: 21.449617 },
    { lat: 49.0399906, lng: 21.4504367 },
    { lat: 49.0396802, lng: 21.4509833 },
    { lat: 49.0396501, lng: 21.4512639 },
    { lat: 49.039581, lng: 21.4512456 },
    { lat: 49.0395961, lng: 21.4516541 },
    { lat: 49.0396464, lng: 21.4517616 },
    { lat: 49.0396324, lng: 21.451968 },
    { lat: 49.0397753, lng: 21.4523213 },
    { lat: 49.0398888, lng: 21.4523969 },
    { lat: 49.0396347, lng: 21.4534454 },
    { lat: 49.0396288, lng: 21.4537893 },
    { lat: 49.0393356, lng: 21.4548899 },
    { lat: 49.0392379, lng: 21.455519 },
    { lat: 49.0391623, lng: 21.4557009 },
    { lat: 49.0390741, lng: 21.4561558 },
    { lat: 49.0390875, lng: 21.4563829 },
    { lat: 49.0388982, lng: 21.4571182 },
    { lat: 49.038384, lng: 21.4574995 },
    { lat: 49.0383899, lng: 21.457588 },
    { lat: 49.0382835, lng: 21.4578813 },
    { lat: 49.0382659, lng: 21.4579309 },
  ],
  Michalok: [
    { lat: 49.0170434, lng: 21.624972 },
    { lat: 49.0159331, lng: 21.6251373 },
    { lat: 49.0141427, lng: 21.624304 },
    { lat: 49.0131619, lng: 21.6241894 },
    { lat: 49.0122005, lng: 21.6242466 },
    { lat: 49.0116524, lng: 21.6241199 },
    { lat: 49.0095605, lng: 21.623331 },
    { lat: 49.0088227, lng: 21.6231282 },
    { lat: 49.0062896, lng: 21.6227792 },
    { lat: 49.0039237, lng: 21.6220028 },
    { lat: 49.0033246, lng: 21.6215741 },
    { lat: 49.0027483, lng: 21.6209529 },
    { lat: 49.0023385, lng: 21.6202075 },
    { lat: 49.0015689, lng: 21.6191107 },
    { lat: 49.0002724, lng: 21.617749 },
    { lat: 48.9996579, lng: 21.6174979 },
    { lat: 48.9964441, lng: 21.6170168 },
    { lat: 48.9960868, lng: 21.6169192 },
    { lat: 48.9943178, lng: 21.6161625 },
    { lat: 48.9942489, lng: 21.6161945 },
    { lat: 48.9942183, lng: 21.6161126 },
    { lat: 48.9941662, lng: 21.6160507 },
    { lat: 48.9934237, lng: 21.6159365 },
    { lat: 48.992871, lng: 21.6154373 },
    { lat: 48.9925665, lng: 21.6154065 },
    { lat: 48.9923141, lng: 21.6154377 },
    { lat: 48.992126, lng: 21.6155901 },
    { lat: 48.9914825, lng: 21.6158336 },
    { lat: 48.9911924, lng: 21.6158371 },
    { lat: 48.9906309, lng: 21.6160803 },
    { lat: 48.9899259, lng: 21.6161783 },
    { lat: 48.9890935, lng: 21.6163851 },
    { lat: 48.9885714, lng: 21.6164212 },
    { lat: 48.9847869, lng: 21.6163907 },
    { lat: 48.9809294, lng: 21.616073 },
    { lat: 48.9805526, lng: 21.6157958 },
    { lat: 48.9797027, lng: 21.6145954 },
    { lat: 48.9762114, lng: 21.6138182 },
    { lat: 48.9747209, lng: 21.614471 },
    { lat: 48.9736089, lng: 21.6147059 },
    { lat: 48.9727312, lng: 21.6150646 },
    { lat: 48.9711538, lng: 21.6163201 },
    { lat: 48.9704642, lng: 21.6167257 },
    { lat: 48.9686434, lng: 21.6161432 },
    { lat: 48.9684055, lng: 21.6159227 },
    { lat: 48.9683133, lng: 21.6176693 },
    { lat: 48.9683545, lng: 21.6179416 },
    { lat: 48.9683505, lng: 21.6185735 },
    { lat: 48.9682617, lng: 21.6189011 },
    { lat: 48.9683912, lng: 21.6192703 },
    { lat: 48.9683694, lng: 21.6198292 },
    { lat: 48.9685272, lng: 21.6204035 },
    { lat: 48.9687977, lng: 21.6210742 },
    { lat: 48.9690621, lng: 21.6221796 },
    { lat: 48.9690519, lng: 21.6225877 },
    { lat: 48.9694789, lng: 21.624283 },
    { lat: 48.9695977, lng: 21.6245887 },
    { lat: 48.9698582, lng: 21.6258727 },
    { lat: 48.9700351, lng: 21.6272348 },
    { lat: 48.9704653, lng: 21.6326057 },
    { lat: 48.9701726, lng: 21.6327202 },
    { lat: 48.9699357, lng: 21.633151 },
    { lat: 48.9697372, lng: 21.6341544 },
    { lat: 48.9694447, lng: 21.6350918 },
    { lat: 48.9692892, lng: 21.6353684 },
    { lat: 48.9686443, lng: 21.6377762 },
    { lat: 48.9684025, lng: 21.6389717 },
    { lat: 48.9679413, lng: 21.6399992 },
    { lat: 48.9678462, lng: 21.640633 },
    { lat: 48.9676112, lng: 21.6409264 },
    { lat: 48.9674605, lng: 21.6407637 },
    { lat: 48.9673939, lng: 21.6408764 },
    { lat: 48.9671212, lng: 21.6420073 },
    { lat: 48.9684004, lng: 21.6435731 },
    { lat: 48.9686692, lng: 21.6440556 },
    { lat: 48.9692979, lng: 21.6455696 },
    { lat: 48.9694465, lng: 21.6460261 },
    { lat: 48.9709245, lng: 21.6483978 },
    { lat: 48.9706875, lng: 21.6487845 },
    { lat: 48.9708371, lng: 21.6496495 },
    { lat: 48.9707654, lng: 21.6514702 },
    { lat: 48.9708849, lng: 21.6513549 },
    { lat: 48.9714404, lng: 21.6516329 },
    { lat: 48.9717802, lng: 21.6521715 },
    { lat: 48.9722811, lng: 21.6522201 },
    { lat: 48.9727627, lng: 21.6525124 },
    { lat: 48.9746244, lng: 21.6531163 },
    { lat: 48.9754091, lng: 21.6533043 },
    { lat: 48.9753606, lng: 21.6517785 },
    { lat: 48.9756297, lng: 21.6520312 },
    { lat: 48.977007, lng: 21.651877 },
    { lat: 48.978613, lng: 21.6518538 },
    { lat: 48.9803306, lng: 21.6524224 },
    { lat: 48.9804674, lng: 21.6531495 },
    { lat: 48.9810763, lng: 21.6529929 },
    { lat: 48.9820523, lng: 21.6530549 },
    { lat: 48.9828429, lng: 21.6533704 },
    { lat: 48.9835501, lng: 21.6534809 },
    { lat: 48.9853685, lng: 21.6532058 },
    { lat: 48.987394, lng: 21.6534281 },
    { lat: 48.9882967, lng: 21.6530316 },
    { lat: 48.9894796, lng: 21.6523122 },
    { lat: 48.9899651, lng: 21.6523317 },
    { lat: 48.9905275, lng: 21.6518298 },
    { lat: 48.9905774, lng: 21.6513517 },
    { lat: 48.9918312, lng: 21.6510859 },
    { lat: 48.9920986, lng: 21.6508208 },
    { lat: 48.9945887, lng: 21.6499292 },
    { lat: 48.9950407, lng: 21.6498792 },
    { lat: 48.9950595, lng: 21.6496634 },
    { lat: 48.995086, lng: 21.6497703 },
    { lat: 48.9977275, lng: 21.6489482 },
    { lat: 48.9983078, lng: 21.6488568 },
    { lat: 48.9989198, lng: 21.6491012 },
    { lat: 48.9997154, lng: 21.6481226 },
    { lat: 49.0001161, lng: 21.6479242 },
    { lat: 49.0006056, lng: 21.6479675 },
    { lat: 49.0024609, lng: 21.646408 },
    { lat: 49.0035772, lng: 21.6451425 },
    { lat: 49.0040843, lng: 21.6449291 },
    { lat: 49.0044186, lng: 21.6446528 },
    { lat: 49.0053898, lng: 21.6442115 },
    { lat: 49.0068876, lng: 21.6437327 },
    { lat: 49.0070077, lng: 21.6435111 },
    { lat: 49.0081156, lng: 21.6447813 },
    { lat: 49.0086675, lng: 21.643447 },
    { lat: 49.0087162, lng: 21.6432131 },
    { lat: 49.0090197, lng: 21.6427517 },
    { lat: 49.0090594, lng: 21.6425542 },
    { lat: 49.0093647, lng: 21.6419963 },
    { lat: 49.0095957, lng: 21.6421769 },
    { lat: 49.0103166, lng: 21.6415128 },
    { lat: 49.0109109, lng: 21.6411512 },
    { lat: 49.0110501, lng: 21.6409721 },
    { lat: 49.0111427, lng: 21.6406971 },
    { lat: 49.0112976, lng: 21.64044 },
    { lat: 49.0114795, lng: 21.6396866 },
    { lat: 49.0117833, lng: 21.6395656 },
    { lat: 49.0121085, lng: 21.6392429 },
    { lat: 49.0123732, lng: 21.6392125 },
    { lat: 49.0128123, lng: 21.6385636 },
    { lat: 49.0127463, lng: 21.6383337 },
    { lat: 49.0135771, lng: 21.6387223 },
    { lat: 49.0137664, lng: 21.6390662 },
    { lat: 49.0140433, lng: 21.6379605 },
    { lat: 49.0145102, lng: 21.636445 },
    { lat: 49.0170544, lng: 21.6367879 },
    { lat: 49.0174344, lng: 21.6351025 },
    { lat: 49.0191034, lng: 21.6354525 },
    { lat: 49.0195632, lng: 21.6353961 },
    { lat: 49.0203459, lng: 21.6346045 },
    { lat: 49.0207197, lng: 21.6340861 },
    { lat: 49.0210307, lng: 21.6339018 },
    { lat: 49.0215577, lng: 21.6333461 },
    { lat: 49.0221241, lng: 21.6329119 },
    { lat: 49.0224894, lng: 21.6324019 },
    { lat: 49.0227773, lng: 21.6323617 },
    { lat: 49.022871, lng: 21.6324518 },
    { lat: 49.0232774, lng: 21.6324673 },
    { lat: 49.0237395, lng: 21.6328229 },
    { lat: 49.0240428, lng: 21.6333396 },
    { lat: 49.0243499, lng: 21.6344464 },
    { lat: 49.0248834, lng: 21.6340006 },
    { lat: 49.0256986, lng: 21.6337428 },
    { lat: 49.0259636, lng: 21.6335198 },
    { lat: 49.0265665, lng: 21.6334718 },
    { lat: 49.0265033, lng: 21.6332217 },
    { lat: 49.0265749, lng: 21.6328741 },
    { lat: 49.0265522, lng: 21.6326246 },
    { lat: 49.0262802, lng: 21.6317331 },
    { lat: 49.0260814, lng: 21.6312513 },
    { lat: 49.0254683, lng: 21.6307644 },
    { lat: 49.0252982, lng: 21.6307935 },
    { lat: 49.0245656, lng: 21.6302159 },
    { lat: 49.0245205, lng: 21.629723 },
    { lat: 49.0243436, lng: 21.6291804 },
    { lat: 49.0240115, lng: 21.6268417 },
    { lat: 49.0236653, lng: 21.6259887 },
    { lat: 49.0234288, lng: 21.6256198 },
    { lat: 49.0233826, lng: 21.6244713 },
    { lat: 49.023531, lng: 21.6224666 },
    { lat: 49.022926, lng: 21.6223094 },
    { lat: 49.0227901, lng: 21.6222356 },
    { lat: 49.0222235, lng: 21.6221728 },
    { lat: 49.0213379, lng: 21.6225296 },
    { lat: 49.0206722, lng: 21.6232757 },
    { lat: 49.0204825, lng: 21.6234125 },
    { lat: 49.0203019, lng: 21.6237226 },
    { lat: 49.0197193, lng: 21.6242171 },
    { lat: 49.0191859, lng: 21.6249048 },
    { lat: 49.0181127, lng: 21.6248251 },
    { lat: 49.017257, lng: 21.6250072 },
    { lat: 49.0170434, lng: 21.624972 },
  ],
  VyšnýŽipov: [
    { lat: 48.997281, lng: 21.5933917 },
    { lat: 48.9969665, lng: 21.5897348 },
    { lat: 48.996866, lng: 21.5890827 },
    { lat: 48.9965752, lng: 21.5883314 },
    { lat: 48.9960791, lng: 21.5851524 },
    { lat: 48.9959472, lng: 21.5845292 },
    { lat: 48.9957886, lng: 21.5842546 },
    { lat: 48.9954367, lng: 21.5841791 },
    { lat: 48.9949796, lng: 21.5838431 },
    { lat: 48.9941913, lng: 21.5828925 },
    { lat: 48.9941797, lng: 21.582478 },
    { lat: 48.9937704, lng: 21.5817589 },
    { lat: 48.9934194, lng: 21.5807991 },
    { lat: 48.9933919, lng: 21.580573 },
    { lat: 48.9933787, lng: 21.5804639 },
    { lat: 48.9933558, lng: 21.5802518 },
    { lat: 48.9934055, lng: 21.5787217 },
    { lat: 48.9936364, lng: 21.5771659 },
    { lat: 48.9938759, lng: 21.5772414 },
    { lat: 48.9938057, lng: 21.5769159 },
    { lat: 48.9937131, lng: 21.576487 },
    { lat: 48.9935356, lng: 21.5760574 },
    { lat: 48.9932477, lng: 21.5757339 },
    { lat: 48.9927687, lng: 21.5754682 },
    { lat: 48.9917578, lng: 21.5752093 },
    { lat: 48.9912755, lng: 21.5751774 },
    { lat: 48.9896532, lng: 21.5755792 },
    { lat: 48.9900786, lng: 21.5781207 },
    { lat: 48.9897735, lng: 21.5781508 },
    { lat: 48.9894908, lng: 21.5793505 },
    { lat: 48.9892064, lng: 21.5803232 },
    { lat: 48.9883618, lng: 21.5814266 },
    { lat: 48.9881074, lng: 21.5816196 },
    { lat: 48.9873908, lng: 21.5811417 },
    { lat: 48.9870674, lng: 21.5812045 },
    { lat: 48.9865849, lng: 21.5810888 },
    { lat: 48.9860823, lng: 21.5815037 },
    { lat: 48.9856701, lng: 21.5811632 },
    { lat: 48.9850289, lng: 21.5797158 },
    { lat: 48.984595, lng: 21.57848 },
    { lat: 48.9843811, lng: 21.5781582 },
    { lat: 48.9839065, lng: 21.5778428 },
    { lat: 48.9829972, lng: 21.5779961 },
    { lat: 48.9825843, lng: 21.5781954 },
    { lat: 48.9811903, lng: 21.5790823 },
    { lat: 48.980371, lng: 21.5794711 },
    { lat: 48.9794359, lng: 21.5796257 },
    { lat: 48.9789184, lng: 21.5796275 },
    { lat: 48.9774822, lng: 21.5800085 },
    { lat: 48.9778252, lng: 21.5823033 },
    { lat: 48.9784749, lng: 21.5827646 },
    { lat: 48.9789278, lng: 21.5837931 },
    { lat: 48.9794442, lng: 21.5843981 },
    { lat: 48.9794291, lng: 21.584701 },
    { lat: 48.9792617, lng: 21.5850305 },
    { lat: 48.9785154, lng: 21.5857434 },
    { lat: 48.9773142, lng: 21.585719 },
    { lat: 48.9769338, lng: 21.5855544 },
    { lat: 48.9763694, lng: 21.5851638 },
    { lat: 48.975309, lng: 21.5840527 },
    { lat: 48.9739734, lng: 21.5833652 },
    { lat: 48.9736862, lng: 21.5837193 },
    { lat: 48.9727965, lng: 21.5842877 },
    { lat: 48.9724028, lng: 21.5844914 },
    { lat: 48.9718616, lng: 21.5846125 },
    { lat: 48.9712692, lng: 21.584386 },
    { lat: 48.9707571, lng: 21.5838947 },
    { lat: 48.9699442, lng: 21.5829512 },
    { lat: 48.9696744, lng: 21.5828794 },
    { lat: 48.9692546, lng: 21.5829932 },
    { lat: 48.9687338, lng: 21.5832806 },
    { lat: 48.9678041, lng: 21.5838662 },
    { lat: 48.9669331, lng: 21.584709 },
    { lat: 48.9661959, lng: 21.584625 },
    { lat: 48.9653811, lng: 21.5840487 },
    { lat: 48.9646064, lng: 21.5831813 },
    { lat: 48.9642367, lng: 21.5829527 },
    { lat: 48.9634837, lng: 21.5828587 },
    { lat: 48.9634825, lng: 21.5831366 },
    { lat: 48.963183, lng: 21.5842984 },
    { lat: 48.9622211, lng: 21.5844599 },
    { lat: 48.9615671, lng: 21.5888018 },
    { lat: 48.9613234, lng: 21.589144 },
    { lat: 48.960697, lng: 21.5896755 },
    { lat: 48.9604238, lng: 21.5897639 },
    { lat: 48.9600619, lng: 21.5897038 },
    { lat: 48.9595242, lng: 21.5890971 },
    { lat: 48.9593963, lng: 21.5891093 },
    { lat: 48.9589163, lng: 21.5886457 },
    { lat: 48.958626, lng: 21.5888781 },
    { lat: 48.9584272, lng: 21.5892557 },
    { lat: 48.9584543, lng: 21.5898655 },
    { lat: 48.9583975, lng: 21.5902884 },
    { lat: 48.9583095, lng: 21.5905246 },
    { lat: 48.9581379, lng: 21.5907032 },
    { lat: 48.9576919, lng: 21.5908074 },
    { lat: 48.9576862, lng: 21.5907297 },
    { lat: 48.9571677, lng: 21.591058 },
    { lat: 48.9564989, lng: 21.5919212 },
    { lat: 48.9536734, lng: 21.5935676 },
    { lat: 48.954021, lng: 21.5945354 },
    { lat: 48.9540359, lng: 21.5946634 },
    { lat: 48.9540244, lng: 21.5967154 },
    { lat: 48.954628, lng: 21.5960469 },
    { lat: 48.955341, lng: 21.5974135 },
    { lat: 48.9560596, lng: 21.5970749 },
    { lat: 48.9567652, lng: 21.5969233 },
    { lat: 48.9570808, lng: 21.596946 },
    { lat: 48.957543, lng: 21.596857 },
    { lat: 48.9577581, lng: 21.5970619 },
    { lat: 48.9582072, lng: 21.5970367 },
    { lat: 48.9587937, lng: 21.5968369 },
    { lat: 48.9590185, lng: 21.5965561 },
    { lat: 48.959297, lng: 21.5964147 },
    { lat: 48.9599327, lng: 21.5964147 },
    { lat: 48.9602941, lng: 21.5963382 },
    { lat: 48.9603098, lng: 21.5966995 },
    { lat: 48.961693, lng: 21.5970046 },
    { lat: 48.9631034, lng: 21.5976786 },
    { lat: 48.9632437, lng: 21.5974154 },
    { lat: 48.9635234, lng: 21.5978518 },
    { lat: 48.9644084, lng: 21.5985642 },
    { lat: 48.9646827, lng: 21.599205 },
    { lat: 48.965098, lng: 21.599896 },
    { lat: 48.9659935, lng: 21.6005436 },
    { lat: 48.9671256, lng: 21.603302 },
    { lat: 48.9677424, lng: 21.6036793 },
    { lat: 48.9677712, lng: 21.604191 },
    { lat: 48.9674296, lng: 21.6045713 },
    { lat: 48.9669306, lng: 21.6068612 },
    { lat: 48.9669094, lng: 21.6075171 },
    { lat: 48.966734, lng: 21.607885 },
    { lat: 48.9664157, lng: 21.6083766 },
    { lat: 48.9662993, lng: 21.6089301 },
    { lat: 48.9663056, lng: 21.6091595 },
    { lat: 48.9665949, lng: 21.610665 },
    { lat: 48.9666082, lng: 21.6111046 },
    { lat: 48.9665077, lng: 21.6115772 },
    { lat: 48.9662239, lng: 21.612175 },
    { lat: 48.9661257, lng: 21.6129791 },
    { lat: 48.9657524, lng: 21.6142032 },
    { lat: 48.9655111, lng: 21.6145473 },
    { lat: 48.9652444, lng: 21.6155708 },
    { lat: 48.9647712, lng: 21.6167209 },
    { lat: 48.9659705, lng: 21.6171286 },
    { lat: 48.9674306, lng: 21.6161013 },
    { lat: 48.9678858, lng: 21.6159598 },
    { lat: 48.9684055, lng: 21.6159227 },
    { lat: 48.9686434, lng: 21.6161432 },
    { lat: 48.9704642, lng: 21.6167257 },
    { lat: 48.9711538, lng: 21.6163201 },
    { lat: 48.9727312, lng: 21.6150646 },
    { lat: 48.9736089, lng: 21.6147059 },
    { lat: 48.9747209, lng: 21.614471 },
    { lat: 48.9762114, lng: 21.6138182 },
    { lat: 48.9797027, lng: 21.6145954 },
    { lat: 48.9805526, lng: 21.6157958 },
    { lat: 48.9809294, lng: 21.616073 },
    { lat: 48.9847869, lng: 21.6163907 },
    { lat: 48.9885714, lng: 21.6164212 },
    { lat: 48.9890935, lng: 21.6163851 },
    { lat: 48.9899259, lng: 21.6161783 },
    { lat: 48.9906309, lng: 21.6160803 },
    { lat: 48.9911924, lng: 21.6158371 },
    { lat: 48.9914825, lng: 21.6158336 },
    { lat: 48.992126, lng: 21.6155901 },
    { lat: 48.9923141, lng: 21.6154377 },
    { lat: 48.9925665, lng: 21.6154065 },
    { lat: 48.992871, lng: 21.6154373 },
    { lat: 48.9934237, lng: 21.6159365 },
    { lat: 48.9941662, lng: 21.6160507 },
    { lat: 48.9942183, lng: 21.6161126 },
    { lat: 48.9942489, lng: 21.6161945 },
    { lat: 48.994395, lng: 21.6158702 },
    { lat: 48.9943939, lng: 21.6156245 },
    { lat: 48.9942481, lng: 21.6152365 },
    { lat: 48.9945254, lng: 21.613703 },
    { lat: 48.9946472, lng: 21.6137601 },
    { lat: 48.9947879, lng: 21.6133505 },
    { lat: 48.994965, lng: 21.6131619 },
    { lat: 48.9951682, lng: 21.6125416 },
    { lat: 48.9951758, lng: 21.6119615 },
    { lat: 48.9952552, lng: 21.6116622 },
    { lat: 48.9956022, lng: 21.6109891 },
    { lat: 48.9957146, lng: 21.6105362 },
    { lat: 48.9958031, lng: 21.6098734 },
    { lat: 48.9960375, lng: 21.6096808 },
    { lat: 48.9961361, lng: 21.6090937 },
    { lat: 48.9963419, lng: 21.6087727 },
    { lat: 48.9964183, lng: 21.6081158 },
    { lat: 48.9966319, lng: 21.6070097 },
    { lat: 48.9969688, lng: 21.6062626 },
    { lat: 48.9971691, lng: 21.6059341 },
    { lat: 48.9972046, lng: 21.604331 },
    { lat: 48.9974003, lng: 21.6036868 },
    { lat: 48.9973112, lng: 21.6019706 },
    { lat: 48.997022, lng: 21.6013621 },
    { lat: 48.996542, lng: 21.5995538 },
    { lat: 48.9963834, lng: 21.5984199 },
    { lat: 48.9967548, lng: 21.5944621 },
    { lat: 48.9967244, lng: 21.5936905 },
    { lat: 48.997281, lng: 21.5933917 },
  ],
  Kvakovce: [
    { lat: 48.9952329, lng: 21.7037175 },
    { lat: 48.9954436, lng: 21.7035131 },
    { lat: 48.9959252, lng: 21.7026423 },
    { lat: 48.9962953, lng: 21.7015908 },
    { lat: 48.9968325, lng: 21.7003608 },
    { lat: 48.9971302, lng: 21.6997981 },
    { lat: 48.9980416, lng: 21.6989328 },
    { lat: 48.9986737, lng: 21.6985069 },
    { lat: 48.9991503, lng: 21.6985318 },
    { lat: 48.9994681, lng: 21.6987571 },
    { lat: 49.0003155, lng: 21.6996482 },
    { lat: 49.0007072, lng: 21.6996994 },
    { lat: 49.0015089, lng: 21.6996428 },
    { lat: 49.0014704, lng: 21.6994639 },
    { lat: 49.000705, lng: 21.6994495 },
    { lat: 49.000646, lng: 21.6969687 },
    { lat: 49.0007209, lng: 21.6969468 },
    { lat: 49.0254728, lng: 21.701635 },
    { lat: 49.0398492, lng: 21.7070531 },
    { lat: 49.0400706, lng: 21.7072996 },
    { lat: 49.0400024, lng: 21.7069808 },
    { lat: 49.0557382, lng: 21.6859348 },
    { lat: 49.058134, lng: 21.675536 },
    { lat: 49.054195, lng: 21.678464 },
    { lat: 49.054168, lng: 21.678473 },
    { lat: 49.054143, lng: 21.678482 },
    { lat: 49.054121, lng: 21.678489 },
    { lat: 49.05408, lng: 21.678502 },
    { lat: 49.054043, lng: 21.678491 },
    { lat: 49.054016, lng: 21.678484 },
    { lat: 49.053971, lng: 21.678471 },
    { lat: 49.053936, lng: 21.678461 },
    { lat: 49.053683, lng: 21.678389 },
    { lat: 49.053545, lng: 21.678376 },
    { lat: 49.053264, lng: 21.677828 },
    { lat: 49.052495, lng: 21.676945 },
    { lat: 49.052076, lng: 21.675858 },
    { lat: 49.050872, lng: 21.6743 },
    { lat: 49.049961, lng: 21.67295 },
    { lat: 49.049163, lng: 21.671769 },
    { lat: 49.048896, lng: 21.671138 },
    { lat: 49.048599, lng: 21.670391 },
    { lat: 49.047985, lng: 21.670266 },
    { lat: 49.047411, lng: 21.669777 },
    { lat: 49.04722, lng: 21.669474 },
    { lat: 49.047152, lng: 21.66937 },
    { lat: 49.047082, lng: 21.668437 },
    { lat: 49.047048, lng: 21.667845 },
    { lat: 49.046989, lng: 21.667529 },
    { lat: 49.046926, lng: 21.666854 },
    { lat: 49.046906, lng: 21.666725 },
    { lat: 49.046897, lng: 21.666671 },
    { lat: 49.046706, lng: 21.665417 },
    { lat: 49.046681, lng: 21.664855 },
    { lat: 49.046739, lng: 21.663388 },
    { lat: 49.046973, lng: 21.662947 },
    { lat: 49.047364, lng: 21.661952 },
    { lat: 49.0474, lng: 21.66195 },
    { lat: 49.048166, lng: 21.660387 },
    { lat: 49.048159, lng: 21.659703 },
    { lat: 49.048041, lng: 21.659325 },
    { lat: 49.047859, lng: 21.658952 },
    { lat: 49.047852, lng: 21.658801 },
    { lat: 49.047788, lng: 21.658667 },
    { lat: 49.047597, lng: 21.658322 },
    { lat: 49.047486, lng: 21.657884 },
    { lat: 49.047322, lng: 21.657501 },
    { lat: 49.047251, lng: 21.65723 },
    { lat: 49.04726, lng: 21.6569 },
    { lat: 49.047008, lng: 21.656627 },
    { lat: 49.046029, lng: 21.656147 },
    { lat: 49.045807, lng: 21.655947 },
    { lat: 49.045595, lng: 21.655933 },
    { lat: 49.045318, lng: 21.655505 },
    { lat: 49.044782, lng: 21.655118 },
    { lat: 49.044544, lng: 21.654385 },
    { lat: 49.043953, lng: 21.653892 },
    { lat: 49.043799, lng: 21.653709 },
    { lat: 49.043628, lng: 21.653253 },
    { lat: 49.043457, lng: 21.653044 },
    { lat: 49.043187, lng: 21.652416 },
    { lat: 49.0434594, lng: 21.6507673 },
    { lat: 49.0437092, lng: 21.6496574 },
    { lat: 49.0433194, lng: 21.6489397 },
    { lat: 49.0431534, lng: 21.6484966 },
    { lat: 49.0427624, lng: 21.6480677 },
    { lat: 49.0424288, lng: 21.6474289 },
    { lat: 49.0420684, lng: 21.6472868 },
    { lat: 49.0416495, lng: 21.6480089 },
    { lat: 49.0415122, lng: 21.6486276 },
    { lat: 49.0413636, lng: 21.6487883 },
    { lat: 49.0410602, lng: 21.6489283 },
    { lat: 49.0407949, lng: 21.6486041 },
    { lat: 49.0407147, lng: 21.6467402 },
    { lat: 49.03997, lng: 21.6470759 },
    { lat: 49.0397208, lng: 21.6468855 },
    { lat: 49.0395374, lng: 21.6464299 },
    { lat: 49.0394728, lng: 21.6460492 },
    { lat: 49.0392183, lng: 21.6453363 },
    { lat: 49.0391127, lng: 21.6448044 },
    { lat: 49.0386626, lng: 21.64419 },
    { lat: 49.0375193, lng: 21.6433552 },
    { lat: 49.0369754, lng: 21.6432931 },
    { lat: 49.0353692, lng: 21.6440409 },
    { lat: 49.0349479, lng: 21.6444799 },
    { lat: 49.0341664, lng: 21.6451081 },
    { lat: 49.0336568, lng: 21.6460596 },
    { lat: 49.0323995, lng: 21.6458229 },
    { lat: 49.0321296, lng: 21.6458676 },
    { lat: 49.0314939, lng: 21.6457429 },
    { lat: 49.0311378, lng: 21.6456005 },
    { lat: 49.0305994, lng: 21.6450849 },
    { lat: 49.0300664, lng: 21.6444314 },
    { lat: 49.0297167, lng: 21.6441925 },
    { lat: 49.0295635, lng: 21.6442298 },
    { lat: 49.0294363, lng: 21.6441827 },
    { lat: 49.0293761, lng: 21.6442829 },
    { lat: 49.0293116, lng: 21.6442321 },
    { lat: 49.0285199, lng: 21.644359 },
    { lat: 49.0282721, lng: 21.6445586 },
    { lat: 49.0281722, lng: 21.644524 },
    { lat: 49.0284241, lng: 21.6441083 },
    { lat: 49.0283993, lng: 21.6432007 },
    { lat: 49.0287233, lng: 21.642551 },
    { lat: 49.02842, lng: 21.6420293 },
    { lat: 49.0284173, lng: 21.6419305 },
    { lat: 49.0284862, lng: 21.6418186 },
    { lat: 49.0283834, lng: 21.6413909 },
    { lat: 49.0285162, lng: 21.6412728 },
    { lat: 49.0283957, lng: 21.640813 },
    { lat: 49.0281209, lng: 21.6401033 },
    { lat: 49.0278642, lng: 21.6400185 },
    { lat: 49.0280105, lng: 21.6397303 },
    { lat: 49.027933, lng: 21.6395269 },
    { lat: 49.027896, lng: 21.6395788 },
    { lat: 49.0278085, lng: 21.6392143 },
    { lat: 49.0278306, lng: 21.6391201 },
    { lat: 49.0277055, lng: 21.6390266 },
    { lat: 49.0277124, lng: 21.6387468 },
    { lat: 49.0275901, lng: 21.638637 },
    { lat: 49.0275864, lng: 21.6377589 },
    { lat: 49.0275071, lng: 21.6376184 },
    { lat: 49.0274981, lng: 21.6372906 },
    { lat: 49.0273632, lng: 21.6369618 },
    { lat: 49.0274129, lng: 21.6366442 },
    { lat: 49.027168, lng: 21.6363289 },
    { lat: 49.0271462, lng: 21.6357803 },
    { lat: 49.0269988, lng: 21.6356936 },
    { lat: 49.0269939, lng: 21.6355154 },
    { lat: 49.0270534, lng: 21.6353879 },
    { lat: 49.0269949, lng: 21.6352267 },
    { lat: 49.0269041, lng: 21.6345453 },
    { lat: 49.0269709, lng: 21.6343489 },
    { lat: 49.0268482, lng: 21.6341505 },
    { lat: 49.0267786, lng: 21.6339076 },
    { lat: 49.0266566, lng: 21.6337644 },
    { lat: 49.0265855, lng: 21.6334663 },
    { lat: 49.0265665, lng: 21.6334718 },
    { lat: 49.0259636, lng: 21.6335198 },
    { lat: 49.0256986, lng: 21.6337428 },
    { lat: 49.0248834, lng: 21.6340006 },
    { lat: 49.0243499, lng: 21.6344464 },
    { lat: 49.0240428, lng: 21.6333396 },
    { lat: 49.0237395, lng: 21.6328229 },
    { lat: 49.0232774, lng: 21.6324673 },
    { lat: 49.022871, lng: 21.6324518 },
    { lat: 49.0227773, lng: 21.6323617 },
    { lat: 49.0224894, lng: 21.6324019 },
    { lat: 49.0221241, lng: 21.6329119 },
    { lat: 49.0215577, lng: 21.6333461 },
    { lat: 49.0210307, lng: 21.6339018 },
    { lat: 49.0207197, lng: 21.6340861 },
    { lat: 49.0203459, lng: 21.6346045 },
    { lat: 49.0195632, lng: 21.6353961 },
    { lat: 49.0191034, lng: 21.6354525 },
    { lat: 49.0174344, lng: 21.6351025 },
    { lat: 49.0170544, lng: 21.6367879 },
    { lat: 49.0145102, lng: 21.636445 },
    { lat: 49.0140433, lng: 21.6379605 },
    { lat: 49.0137664, lng: 21.6390662 },
    { lat: 49.0135771, lng: 21.6387223 },
    { lat: 49.0127463, lng: 21.6383337 },
    { lat: 49.0128123, lng: 21.6385636 },
    { lat: 49.0123732, lng: 21.6392125 },
    { lat: 49.0121085, lng: 21.6392429 },
    { lat: 49.0117833, lng: 21.6395656 },
    { lat: 49.0114795, lng: 21.6396866 },
    { lat: 49.0112976, lng: 21.64044 },
    { lat: 49.0111427, lng: 21.6406971 },
    { lat: 49.0110501, lng: 21.6409721 },
    { lat: 49.0109109, lng: 21.6411512 },
    { lat: 49.0103166, lng: 21.6415128 },
    { lat: 49.0095957, lng: 21.6421769 },
    { lat: 49.0093647, lng: 21.6419963 },
    { lat: 49.0090594, lng: 21.6425542 },
    { lat: 49.0090197, lng: 21.6427517 },
    { lat: 49.0087162, lng: 21.6432131 },
    { lat: 49.0086675, lng: 21.643447 },
    { lat: 49.0081156, lng: 21.6447813 },
    { lat: 49.0070077, lng: 21.6435111 },
    { lat: 49.0068876, lng: 21.6437327 },
    { lat: 49.0053898, lng: 21.6442115 },
    { lat: 49.0044186, lng: 21.6446528 },
    { lat: 49.0040843, lng: 21.6449291 },
    { lat: 49.0035772, lng: 21.6451425 },
    { lat: 49.0024609, lng: 21.646408 },
    { lat: 49.0006056, lng: 21.6479675 },
    { lat: 49.0001161, lng: 21.6479242 },
    { lat: 48.9997154, lng: 21.6481226 },
    { lat: 48.9989198, lng: 21.6491012 },
    { lat: 48.9983078, lng: 21.6488568 },
    { lat: 48.9977275, lng: 21.6489482 },
    { lat: 48.995086, lng: 21.6497703 },
    { lat: 48.9950595, lng: 21.6496634 },
    { lat: 48.9950407, lng: 21.6498792 },
    { lat: 48.9945887, lng: 21.6499292 },
    { lat: 48.9920986, lng: 21.6508208 },
    { lat: 48.9918312, lng: 21.6510859 },
    { lat: 48.9905774, lng: 21.6513517 },
    { lat: 48.9905275, lng: 21.6518298 },
    { lat: 48.9899651, lng: 21.6523317 },
    { lat: 48.9894796, lng: 21.6523122 },
    { lat: 48.9882967, lng: 21.6530316 },
    { lat: 48.987394, lng: 21.6534281 },
    { lat: 48.9853685, lng: 21.6532058 },
    { lat: 48.9835501, lng: 21.6534809 },
    { lat: 48.9828429, lng: 21.6533704 },
    { lat: 48.9820523, lng: 21.6530549 },
    { lat: 48.9810763, lng: 21.6529929 },
    { lat: 48.9804674, lng: 21.6531495 },
    { lat: 48.9803306, lng: 21.6524224 },
    { lat: 48.978613, lng: 21.6518538 },
    { lat: 48.977007, lng: 21.651877 },
    { lat: 48.9756297, lng: 21.6520312 },
    { lat: 48.9753606, lng: 21.6517785 },
    { lat: 48.9754091, lng: 21.6533043 },
    { lat: 48.9746244, lng: 21.6531163 },
    { lat: 48.9727627, lng: 21.6525124 },
    { lat: 48.9722811, lng: 21.6522201 },
    { lat: 48.9717802, lng: 21.6521715 },
    { lat: 48.9714404, lng: 21.6516329 },
    { lat: 48.9708849, lng: 21.6513549 },
    { lat: 48.9707654, lng: 21.6514702 },
    { lat: 48.9707238, lng: 21.6516358 },
    { lat: 48.9701182, lng: 21.6528102 },
    { lat: 48.9694859, lng: 21.6538817 },
    { lat: 48.9687472, lng: 21.6541868 },
    { lat: 48.9684341, lng: 21.6546265 },
    { lat: 48.9685217, lng: 21.6548738 },
    { lat: 48.9686213, lng: 21.6548947 },
    { lat: 48.9686555, lng: 21.6551561 },
    { lat: 48.9689345, lng: 21.6554873 },
    { lat: 48.9689773, lng: 21.6560282 },
    { lat: 48.9691174, lng: 21.6562113 },
    { lat: 48.9691534, lng: 21.6565384 },
    { lat: 48.969569, lng: 21.6573412 },
    { lat: 48.9697304, lng: 21.6574406 },
    { lat: 48.9697692, lng: 21.6577128 },
    { lat: 48.9698956, lng: 21.6577242 },
    { lat: 48.9702046, lng: 21.6587837 },
    { lat: 48.9702401, lng: 21.6594267 },
    { lat: 48.9700555, lng: 21.6600833 },
    { lat: 48.9697819, lng: 21.6605312 },
    { lat: 48.9695169, lng: 21.6607538 },
    { lat: 48.969449, lng: 21.660945 },
    { lat: 48.9692673, lng: 21.6610495 },
    { lat: 48.9690152, lng: 21.6614116 },
    { lat: 48.9686031, lng: 21.661816 },
    { lat: 48.9683122, lng: 21.6619553 },
    { lat: 48.968003, lng: 21.6616743 },
    { lat: 48.9677769, lng: 21.6636569 },
    { lat: 48.9678481, lng: 21.6639149 },
    { lat: 48.9681777, lng: 21.6637932 },
    { lat: 48.9685025, lng: 21.6638179 },
    { lat: 48.9693776, lng: 21.6637325 },
    { lat: 48.9695639, lng: 21.6640012 },
    { lat: 48.9700518, lng: 21.6641975 },
    { lat: 48.970195, lng: 21.6644167 },
    { lat: 48.9705915, lng: 21.6653446 },
    { lat: 48.9706414, lng: 21.66573 },
    { lat: 48.9708345, lng: 21.666043 },
    { lat: 48.9708418, lng: 21.6664762 },
    { lat: 48.970931, lng: 21.6669036 },
    { lat: 48.9714641, lng: 21.6667759 },
    { lat: 48.9716286, lng: 21.6668695 },
    { lat: 48.9716736, lng: 21.667363 },
    { lat: 48.9717935, lng: 21.6678464 },
    { lat: 48.9717689, lng: 21.6680585 },
    { lat: 48.9718436, lng: 21.6688194 },
    { lat: 48.9720729, lng: 21.6693912 },
    { lat: 48.9720866, lng: 21.6696858 },
    { lat: 48.9722574, lng: 21.670088 },
    { lat: 48.9724538, lng: 21.6700732 },
    { lat: 48.9725843, lng: 21.6702412 },
    { lat: 48.9726277, lng: 21.6701414 },
    { lat: 48.9728537, lng: 21.6705101 },
    { lat: 48.9730147, lng: 21.6705082 },
    { lat: 48.9732444, lng: 21.6708491 },
    { lat: 48.9732884, lng: 21.6708123 },
    { lat: 48.9733289, lng: 21.6708985 },
    { lat: 48.9733018, lng: 21.6711409 },
    { lat: 48.9734142, lng: 21.6712184 },
    { lat: 48.9734301, lng: 21.6714283 },
    { lat: 48.9736416, lng: 21.6715955 },
    { lat: 48.9737982, lng: 21.6718153 },
    { lat: 48.9740227, lng: 21.6725038 },
    { lat: 48.9739119, lng: 21.673223 },
    { lat: 48.973444, lng: 21.6745992 },
    { lat: 48.9733368, lng: 21.6750446 },
    { lat: 48.9732352, lng: 21.6763129 },
    { lat: 48.9730614, lng: 21.6773709 },
    { lat: 48.9730881, lng: 21.6779046 },
    { lat: 48.9729601, lng: 21.6787835 },
    { lat: 48.9727916, lng: 21.6794547 },
    { lat: 48.9726077, lng: 21.6800546 },
    { lat: 48.9723188, lng: 21.6805633 },
    { lat: 48.9720413, lng: 21.6812864 },
    { lat: 48.9717461, lng: 21.6812322 },
    { lat: 48.9714641, lng: 21.6813282 },
    { lat: 48.9713135, lng: 21.6815363 },
    { lat: 48.9714714, lng: 21.6819265 },
    { lat: 48.9718102, lng: 21.6824342 },
    { lat: 48.9718838, lng: 21.6823204 },
    { lat: 48.9720899, lng: 21.6826642 },
    { lat: 48.9723454, lng: 21.6828789 },
    { lat: 48.9725837, lng: 21.6832002 },
    { lat: 48.9724358, lng: 21.6838938 },
    { lat: 48.9717699, lng: 21.6857713 },
    { lat: 48.9721052, lng: 21.6866475 },
    { lat: 48.9723327, lng: 21.6863593 },
    { lat: 48.9731058, lng: 21.6869099 },
    { lat: 48.9745844, lng: 21.6865011 },
    { lat: 48.9746617, lng: 21.6872848 },
    { lat: 48.9754945, lng: 21.6874209 },
    { lat: 48.9758515, lng: 21.6893096 },
    { lat: 48.9785788, lng: 21.6887775 },
    { lat: 48.979022, lng: 21.6898594 },
    { lat: 48.9790248, lng: 21.6907589 },
    { lat: 48.9792033, lng: 21.6918266 },
    { lat: 48.9792591, lng: 21.6917686 },
    { lat: 48.9797452, lng: 21.6921882 },
    { lat: 48.9802593, lng: 21.6924739 },
    { lat: 48.9809231, lng: 21.6921851 },
    { lat: 48.9814793, lng: 21.6918295 },
    { lat: 48.9819055, lng: 21.6916962 },
    { lat: 48.9820816, lng: 21.6914707 },
    { lat: 48.9822959, lng: 21.691365 },
    { lat: 48.9826523, lng: 21.6913105 },
    { lat: 48.9838166, lng: 21.692151 },
    { lat: 48.9830914, lng: 21.6922875 },
    { lat: 48.9823344, lng: 21.6925406 },
    { lat: 48.9825181, lng: 21.6939307 },
    { lat: 48.9825766, lng: 21.6941007 },
    { lat: 48.9832398, lng: 21.6943729 },
    { lat: 48.9852606, lng: 21.6951702 },
    { lat: 48.9888284, lng: 21.6950223 },
    { lat: 48.9904127, lng: 21.6950688 },
    { lat: 48.9910896, lng: 21.6949314 },
    { lat: 48.9921958, lng: 21.6948246 },
    { lat: 48.9922763, lng: 21.6958055 },
    { lat: 48.99242, lng: 21.6961286 },
    { lat: 48.9924042, lng: 21.6978846 },
    { lat: 48.9924748, lng: 21.6987049 },
    { lat: 48.9925181, lng: 21.6989809 },
    { lat: 48.9926842, lng: 21.6995029 },
    { lat: 48.9927173, lng: 21.7000673 },
    { lat: 48.9926466, lng: 21.7004557 },
    { lat: 48.9927282, lng: 21.7007612 },
    { lat: 48.9926234, lng: 21.7012216 },
    { lat: 48.9926724, lng: 21.7014948 },
    { lat: 48.9928034, lng: 21.7016837 },
    { lat: 48.9928733, lng: 21.7019788 },
    { lat: 48.9928033, lng: 21.7029791 },
    { lat: 48.9927207, lng: 21.7034546 },
    { lat: 48.9934576, lng: 21.7030868 },
    { lat: 48.994182, lng: 21.7025317 },
    { lat: 48.994678, lng: 21.7019004 },
    { lat: 48.9952943, lng: 21.7007641 },
    { lat: 48.9959414, lng: 21.7015257 },
    { lat: 48.9952329, lng: 21.7037175 },
  ],
  Cabov: [
    { lat: 48.8143383, lng: 21.6455407 },
    { lat: 48.8143338, lng: 21.6450941 },
    { lat: 48.8142949, lng: 21.6449501 },
    { lat: 48.8142863, lng: 21.6433263 },
    { lat: 48.8140462, lng: 21.6423258 },
    { lat: 48.8137504, lng: 21.6414299 },
    { lat: 48.8139592, lng: 21.6413314 },
    { lat: 48.8139343, lng: 21.6407957 },
    { lat: 48.8137239, lng: 21.6389926 },
    { lat: 48.8136275, lng: 21.6387224 },
    { lat: 48.8129665, lng: 21.6377235 },
    { lat: 48.8124217, lng: 21.6366105 },
    { lat: 48.8123871, lng: 21.6361708 },
    { lat: 48.813236, lng: 21.6354999 },
    { lat: 48.813056, lng: 21.6347677 },
    { lat: 48.8128182, lng: 21.634591 },
    { lat: 48.8120115, lng: 21.6331174 },
    { lat: 48.8119505, lng: 21.6328617 },
    { lat: 48.8119518, lng: 21.6323772 },
    { lat: 48.8118497, lng: 21.6320606 },
    { lat: 48.8116093, lng: 21.631631 },
    { lat: 48.8115239, lng: 21.6312245 },
    { lat: 48.8113991, lng: 21.6309482 },
    { lat: 48.8105451, lng: 21.6282582 },
    { lat: 48.8100462, lng: 21.6265257 },
    { lat: 48.8098329, lng: 21.62565 },
    { lat: 48.8095703, lng: 21.6236816 },
    { lat: 48.8093679, lng: 21.6227938 },
    { lat: 48.8092281, lng: 21.6223772 },
    { lat: 48.8095548, lng: 21.6217778 },
    { lat: 48.8095821, lng: 21.6195958 },
    { lat: 48.8094658, lng: 21.6192944 },
    { lat: 48.8093811, lng: 21.6185973 },
    { lat: 48.8094886, lng: 21.6180918 },
    { lat: 48.80969, lng: 21.6175614 },
    { lat: 48.8104144, lng: 21.6158883 },
    { lat: 48.8104036, lng: 21.61538 },
    { lat: 48.8107428, lng: 21.6140187 },
    { lat: 48.8107198, lng: 21.6123417 },
    { lat: 48.8105156, lng: 21.6121202 },
    { lat: 48.8100977, lng: 21.6126665 },
    { lat: 48.8100165, lng: 21.6125785 },
    { lat: 48.8093152, lng: 21.6129287 },
    { lat: 48.8089226, lng: 21.6127766 },
    { lat: 48.8087363, lng: 21.6125097 },
    { lat: 48.8076532, lng: 21.6125201 },
    { lat: 48.8072906, lng: 21.6127145 },
    { lat: 48.8071883, lng: 21.6126753 },
    { lat: 48.8069667, lng: 21.6128005 },
    { lat: 48.8067867, lng: 21.6132042 },
    { lat: 48.8064269, lng: 21.6134948 },
    { lat: 48.8060962, lng: 21.6139459 },
    { lat: 48.8057236, lng: 21.6140196 },
    { lat: 48.8055984, lng: 21.6138841 },
    { lat: 48.8051813, lng: 21.6139003 },
    { lat: 48.8048994, lng: 21.6138347 },
    { lat: 48.8045914, lng: 21.6138821 },
    { lat: 48.8041515, lng: 21.6144102 },
    { lat: 48.8033729, lng: 21.6145782 },
    { lat: 48.8029625, lng: 21.6151918 },
    { lat: 48.8027686, lng: 21.6148575 },
    { lat: 48.8028495, lng: 21.614281 },
    { lat: 48.8028242, lng: 21.6141398 },
    { lat: 48.8026889, lng: 21.6139718 },
    { lat: 48.80252, lng: 21.6129496 },
    { lat: 48.8025013, lng: 21.6124372 },
    { lat: 48.8022513, lng: 21.6117058 },
    { lat: 48.8022753, lng: 21.6112717 },
    { lat: 48.8021916, lng: 21.6106842 },
    { lat: 48.8022757, lng: 21.6103501 },
    { lat: 48.8021222, lng: 21.6097299 },
    { lat: 48.8021691, lng: 21.6092315 },
    { lat: 48.8021363, lng: 21.608942 },
    { lat: 48.8019439, lng: 21.608738 },
    { lat: 48.8020153, lng: 21.6078705 },
    { lat: 48.8019945, lng: 21.6075192 },
    { lat: 48.8018591, lng: 21.607187 },
    { lat: 48.8017831, lng: 21.6064394 },
    { lat: 48.8016668, lng: 21.6062149 },
    { lat: 48.8016983, lng: 21.6058153 },
    { lat: 48.8014142, lng: 21.6053498 },
    { lat: 48.8014412, lng: 21.6045418 },
    { lat: 48.8012459, lng: 21.6040282 },
    { lat: 48.8010402, lng: 21.603112 },
    { lat: 48.801094, lng: 21.6028148 },
    { lat: 48.8010724, lng: 21.6026491 },
    { lat: 48.8009903, lng: 21.6025284 },
    { lat: 48.8009457, lng: 21.6023407 },
    { lat: 48.8009497, lng: 21.6020788 },
    { lat: 48.8008604, lng: 21.6017854 },
    { lat: 48.8008783, lng: 21.6016254 },
    { lat: 48.8004533, lng: 21.6006636 },
    { lat: 48.800363, lng: 21.6001301 },
    { lat: 48.8001511, lng: 21.5999117 },
    { lat: 48.8000335, lng: 21.5996854 },
    { lat: 48.7997131, lng: 21.5996588 },
    { lat: 48.7996089, lng: 21.5995912 },
    { lat: 48.7995651, lng: 21.5994334 },
    { lat: 48.7994612, lng: 21.5993355 },
    { lat: 48.799283, lng: 21.5992844 },
    { lat: 48.798874, lng: 21.5988562 },
    { lat: 48.7986512, lng: 21.5988144 },
    { lat: 48.7985275, lng: 21.5986139 },
    { lat: 48.7981021, lng: 21.5984534 },
    { lat: 48.7978203, lng: 21.5981948 },
    { lat: 48.7976644, lng: 21.5979653 },
    { lat: 48.7975554, lng: 21.5976072 },
    { lat: 48.7974439, lng: 21.597407 },
    { lat: 48.7972526, lng: 21.5971594 },
    { lat: 48.7970732, lng: 21.5971009 },
    { lat: 48.7968852, lng: 21.596775 },
    { lat: 48.7966613, lng: 21.5960509 },
    { lat: 48.7964393, lng: 21.5955559 },
    { lat: 48.796359, lng: 21.5952234 },
    { lat: 48.7961266, lng: 21.5949139 },
    { lat: 48.7958674, lng: 21.5942946 },
    { lat: 48.7957075, lng: 21.5942067 },
    { lat: 48.7955145, lng: 21.5942272 },
    { lat: 48.7953508, lng: 21.5937684 },
    { lat: 48.7951318, lng: 21.5937011 },
    { lat: 48.7947153, lng: 21.5931668 },
    { lat: 48.7947371, lng: 21.592857 },
    { lat: 48.79465, lng: 21.592439 },
    { lat: 48.79443, lng: 21.5918575 },
    { lat: 48.7942227, lng: 21.5915257 },
    { lat: 48.7941463, lng: 21.5912908 },
    { lat: 48.7939532, lng: 21.5910205 },
    { lat: 48.7937205, lng: 21.5903046 },
    { lat: 48.7934898, lng: 21.5901385 },
    { lat: 48.7929874, lng: 21.5893567 },
    { lat: 48.792934, lng: 21.5891749 },
    { lat: 48.7927666, lng: 21.5889037 },
    { lat: 48.7926927, lng: 21.5881948 },
    { lat: 48.7926507, lng: 21.5880983 },
    { lat: 48.7926002, lng: 21.5868583 },
    { lat: 48.7924603, lng: 21.5863595 },
    { lat: 48.7922287, lng: 21.5860871 },
    { lat: 48.79155, lng: 21.5847946 },
    { lat: 48.7912623, lng: 21.5845975 },
    { lat: 48.7909448, lng: 21.5841972 },
    { lat: 48.7901098, lng: 21.5833844 },
    { lat: 48.7899155, lng: 21.5833605 },
    { lat: 48.7896368, lng: 21.5832078 },
    { lat: 48.7895291, lng: 21.5830563 },
    { lat: 48.7891897, lng: 21.5829507 },
    { lat: 48.7888177, lng: 21.5826867 },
    { lat: 48.7883332, lng: 21.5821394 },
    { lat: 48.7880976, lng: 21.5820025 },
    { lat: 48.7879982, lng: 21.581828 },
    { lat: 48.7877454, lng: 21.5817593 },
    { lat: 48.7877267, lng: 21.5816578 },
    { lat: 48.7872485, lng: 21.5816967 },
    { lat: 48.786726, lng: 21.582028 },
    { lat: 48.786588, lng: 21.582289 },
    { lat: 48.786424, lng: 21.582644 },
    { lat: 48.786124, lng: 21.583094 },
    { lat: 48.786039, lng: 21.583263 },
    { lat: 48.785775, lng: 21.583916 },
    { lat: 48.785042, lng: 21.584147 },
    { lat: 48.784732, lng: 21.584027 },
    { lat: 48.78431, lng: 21.583791 },
    { lat: 48.784302, lng: 21.583827 },
    { lat: 48.784015, lng: 21.584064 },
    { lat: 48.783653, lng: 21.583989 },
    { lat: 48.783334, lng: 21.583818 },
    { lat: 48.783313, lng: 21.583783 },
    { lat: 48.782828, lng: 21.583902 },
    { lat: 48.78251, lng: 21.583828 },
    { lat: 48.782086, lng: 21.583322 },
    { lat: 48.781383, lng: 21.582887 },
    { lat: 48.781363, lng: 21.582905 },
    { lat: 48.780807, lng: 21.583232 },
    { lat: 48.780467, lng: 21.583608 },
    { lat: 48.7805079, lng: 21.5839721 },
    { lat: 48.780528, lng: 21.584151 },
    { lat: 48.780536, lng: 21.584219 },
    { lat: 48.780589, lng: 21.584698 },
    { lat: 48.780656, lng: 21.585131 },
    { lat: 48.78063, lng: 21.585593 },
    { lat: 48.780619, lng: 21.585862 },
    { lat: 48.780775, lng: 21.586132 },
    { lat: 48.78077, lng: 21.588761 },
    { lat: 48.780702, lng: 21.58908 },
    { lat: 48.780645, lng: 21.589696 },
    { lat: 48.780566, lng: 21.590128 },
    { lat: 48.78049, lng: 21.590843 },
    { lat: 48.780701, lng: 21.591126 },
    { lat: 48.781209, lng: 21.591633 },
    { lat: 48.781431, lng: 21.592019 },
    { lat: 48.781839, lng: 21.592485 },
    { lat: 48.781949, lng: 21.593268 },
    { lat: 48.78232, lng: 21.594222 },
    { lat: 48.782554, lng: 21.594377 },
    { lat: 48.782578, lng: 21.594383 },
    { lat: 48.782555, lng: 21.594709 },
    { lat: 48.782316, lng: 21.595123 },
    { lat: 48.782029, lng: 21.595397 },
    { lat: 48.781713, lng: 21.595693 },
    { lat: 48.781601, lng: 21.596069 },
    { lat: 48.781465, lng: 21.596327 },
    { lat: 48.781397, lng: 21.596626 },
    { lat: 48.781345, lng: 21.596851 },
    { lat: 48.78132, lng: 21.597153 },
    { lat: 48.781239, lng: 21.597429 },
    { lat: 48.781158, lng: 21.59772 },
    { lat: 48.781069, lng: 21.598077 },
    { lat: 48.780948, lng: 21.598832 },
    { lat: 48.780927, lng: 21.599461 },
    { lat: 48.78098, lng: 21.599947 },
    { lat: 48.781029, lng: 21.600528 },
    { lat: 48.781017, lng: 21.600812 },
    { lat: 48.780999, lng: 21.60132 },
    { lat: 48.780771, lng: 21.601757 },
    { lat: 48.780719, lng: 21.601938 },
    { lat: 48.780435, lng: 21.602431 },
    { lat: 48.780345, lng: 21.602501 },
    { lat: 48.780038, lng: 21.602729 },
    { lat: 48.77991, lng: 21.60282 },
    { lat: 48.779585, lng: 21.603004 },
    { lat: 48.779304, lng: 21.603368 },
    { lat: 48.779196, lng: 21.603826 },
    { lat: 48.779183, lng: 21.604264 },
    { lat: 48.779014, lng: 21.604756 },
    { lat: 48.778861, lng: 21.605074 },
    { lat: 48.778678, lng: 21.605356 },
    { lat: 48.778419, lng: 21.605694 },
    { lat: 48.778111, lng: 21.606009 },
    { lat: 48.777869, lng: 21.606324 },
    { lat: 48.777834, lng: 21.606618 },
    { lat: 48.777741, lng: 21.606864 },
    { lat: 48.77744, lng: 21.607072 },
    { lat: 48.777167, lng: 21.607337 },
    { lat: 48.776727, lng: 21.607747 },
    { lat: 48.776552, lng: 21.607977 },
    { lat: 48.776532, lng: 21.608053 },
    { lat: 48.776464, lng: 21.608495 },
    { lat: 48.776337, lng: 21.608927 },
    { lat: 48.776273, lng: 21.60908 },
    { lat: 48.776122, lng: 21.609401 },
    { lat: 48.776113, lng: 21.609511 },
    { lat: 48.776084, lng: 21.609948 },
    { lat: 48.775897, lng: 21.610426 },
    { lat: 48.775608, lng: 21.611074 },
    { lat: 48.775367, lng: 21.611408 },
    { lat: 48.775056, lng: 21.611623 },
    { lat: 48.774784, lng: 21.611906 },
    { lat: 48.774609, lng: 21.612287 },
    { lat: 48.77432, lng: 21.612724 },
    { lat: 48.774272, lng: 21.612966 },
    { lat: 48.774188, lng: 21.61372 },
    { lat: 48.774162, lng: 21.614127 },
    { lat: 48.77413, lng: 21.614572 },
    { lat: 48.774033, lng: 21.614922 },
    { lat: 48.773843, lng: 21.615309 },
    { lat: 48.773728, lng: 21.615538 },
    { lat: 48.773568, lng: 21.615736 },
    { lat: 48.773568, lng: 21.615737 },
    { lat: 48.773579, lng: 21.615763 },
    { lat: 48.776025, lng: 21.624046 },
    { lat: 48.776039, lng: 21.624093 },
    { lat: 48.776379, lng: 21.625245 },
    { lat: 48.77896, lng: 21.634276 },
    { lat: 48.778988, lng: 21.634374 },
    { lat: 48.779303, lng: 21.635478 },
    { lat: 48.779962, lng: 21.637817 },
    { lat: 48.779976, lng: 21.637864 },
    { lat: 48.779883, lng: 21.637964 },
    { lat: 48.779946, lng: 21.638141 },
    { lat: 48.779945, lng: 21.638181 },
    { lat: 48.779926, lng: 21.638202 },
    { lat: 48.779585, lng: 21.638289 },
    { lat: 48.779559, lng: 21.638504 },
    { lat: 48.779385, lng: 21.638644 },
    { lat: 48.779341, lng: 21.639021 },
    { lat: 48.779155, lng: 21.639211 },
    { lat: 48.779156, lng: 21.639728 },
    { lat: 48.779559, lng: 21.640407 },
    { lat: 48.779737, lng: 21.640928 },
    { lat: 48.779705, lng: 21.641345 },
    { lat: 48.779747, lng: 21.641602 },
    { lat: 48.779701, lng: 21.641818 },
    { lat: 48.779716, lng: 21.641843 },
    { lat: 48.779784, lng: 21.641851 },
    { lat: 48.779832, lng: 21.641872 },
    { lat: 48.779955, lng: 21.642429 },
    { lat: 48.779974, lng: 21.642871 },
    { lat: 48.780103, lng: 21.643245 },
    { lat: 48.780165, lng: 21.643604 },
    { lat: 48.78029, lng: 21.643805 },
    { lat: 48.780379, lng: 21.643844 },
    { lat: 48.780392, lng: 21.643893 },
    { lat: 48.780377, lng: 21.643942 },
    { lat: 48.780158, lng: 21.644274 },
    { lat: 48.780094, lng: 21.644712 },
    { lat: 48.780155, lng: 21.644913 },
    { lat: 48.780091, lng: 21.645273 },
    { lat: 48.780067, lng: 21.645686 },
    { lat: 48.779962, lng: 21.645909 },
    { lat: 48.779918, lng: 21.646268 },
    { lat: 48.779973, lng: 21.64659 },
    { lat: 48.77996, lng: 21.647238 },
    { lat: 48.780055, lng: 21.647579 },
    { lat: 48.780142, lng: 21.647965 },
    { lat: 48.780118, lng: 21.648699 },
    { lat: 48.7801165, lng: 21.6487491 },
    { lat: 48.7801606, lng: 21.6487612 },
    { lat: 48.7801275, lng: 21.6488257 },
    { lat: 48.7803557, lng: 21.6490233 },
    { lat: 48.7809995, lng: 21.649397 },
    { lat: 48.7816624, lng: 21.6494909 },
    { lat: 48.7819458, lng: 21.649609 },
    { lat: 48.7823926, lng: 21.6496112 },
    { lat: 48.7830268, lng: 21.6494732 },
    { lat: 48.7854489, lng: 21.6486088 },
    { lat: 48.7857836, lng: 21.6484193 },
    { lat: 48.7862999, lng: 21.6480065 },
    { lat: 48.7866859, lng: 21.6474399 },
    { lat: 48.7870576, lng: 21.6470864 },
    { lat: 48.7879761, lng: 21.6467304 },
    { lat: 48.789161, lng: 21.6467375 },
    { lat: 48.7903222, lng: 21.6468617 },
    { lat: 48.793624, lng: 21.6466881 },
    { lat: 48.7937042, lng: 21.6469472 },
    { lat: 48.7939776, lng: 21.647224 },
    { lat: 48.7939148, lng: 21.6472815 },
    { lat: 48.7935457, lng: 21.6481376 },
    { lat: 48.793534, lng: 21.648326 },
    { lat: 48.7934122, lng: 21.6484786 },
    { lat: 48.7934076, lng: 21.6486355 },
    { lat: 48.7933018, lng: 21.6488766 },
    { lat: 48.793345, lng: 21.648982 },
    { lat: 48.7934547, lng: 21.6490015 },
    { lat: 48.7934267, lng: 21.6500713 },
    { lat: 48.7933255, lng: 21.6503606 },
    { lat: 48.7933611, lng: 21.6505483 },
    { lat: 48.7931815, lng: 21.6507729 },
    { lat: 48.7931193, lng: 21.651042 },
    { lat: 48.7931431, lng: 21.6513424 },
    { lat: 48.7930769, lng: 21.6513865 },
    { lat: 48.7930542, lng: 21.6516673 },
    { lat: 48.7928909, lng: 21.6518267 },
    { lat: 48.7927594, lng: 21.6518718 },
    { lat: 48.7928073, lng: 21.6519312 },
    { lat: 48.7928376, lng: 21.6521345 },
    { lat: 48.7929237, lng: 21.6520324 },
    { lat: 48.79302, lng: 21.6520955 },
    { lat: 48.7930509, lng: 21.6523652 },
    { lat: 48.793238, lng: 21.6528344 },
    { lat: 48.7930976, lng: 21.6534549 },
    { lat: 48.7929947, lng: 21.653643 },
    { lat: 48.7930155, lng: 21.6538683 },
    { lat: 48.7931292, lng: 21.6539114 },
    { lat: 48.7930794, lng: 21.654554 },
    { lat: 48.7929725, lng: 21.6546803 },
    { lat: 48.7932504, lng: 21.6547995 },
    { lat: 48.7941364, lng: 21.6546193 },
    { lat: 48.7941614, lng: 21.6547466 },
    { lat: 48.7945469, lng: 21.6546528 },
    { lat: 48.7948979, lng: 21.654685 },
    { lat: 48.7949235, lng: 21.6548472 },
    { lat: 48.7951563, lng: 21.6547969 },
    { lat: 48.795275, lng: 21.6549047 },
    { lat: 48.7954142, lng: 21.6549356 },
    { lat: 48.7954127, lng: 21.6550002 },
    { lat: 48.7955868, lng: 21.6551499 },
    { lat: 48.7960475, lng: 21.6552987 },
    { lat: 48.7959566, lng: 21.6555503 },
    { lat: 48.7958394, lng: 21.6556279 },
    { lat: 48.7957817, lng: 21.655906 },
    { lat: 48.7958194, lng: 21.6563779 },
    { lat: 48.7968218, lng: 21.6566586 },
    { lat: 48.7967768, lng: 21.6571939 },
    { lat: 48.7975862, lng: 21.657495 },
    { lat: 48.7979694, lng: 21.6578956 },
    { lat: 48.7987173, lng: 21.6568991 },
    { lat: 48.799003, lng: 21.6571377 },
    { lat: 48.799491, lng: 21.6568812 },
    { lat: 48.8004205, lng: 21.6572208 },
    { lat: 48.8012953, lng: 21.6573615 },
    { lat: 48.8017283, lng: 21.657521 },
    { lat: 48.8028899, lng: 21.6577879 },
    { lat: 48.8033472, lng: 21.6579666 },
    { lat: 48.8033329, lng: 21.6581855 },
    { lat: 48.8035742, lng: 21.6582935 },
    { lat: 48.8074824, lng: 21.659015 },
    { lat: 48.8100595, lng: 21.6591628 },
    { lat: 48.8102093, lng: 21.6586751 },
    { lat: 48.8105508, lng: 21.6582817 },
    { lat: 48.8106404, lng: 21.6580227 },
    { lat: 48.8106874, lng: 21.6573972 },
    { lat: 48.8108626, lng: 21.6566094 },
    { lat: 48.8108904, lng: 21.6558594 },
    { lat: 48.8110291, lng: 21.6545528 },
    { lat: 48.8112022, lng: 21.6543402 },
    { lat: 48.8132021, lng: 21.6544923 },
    { lat: 48.8144866, lng: 21.6544345 },
    { lat: 48.8146145, lng: 21.6542572 },
    { lat: 48.8145541, lng: 21.6539037 },
    { lat: 48.8145988, lng: 21.6536003 },
    { lat: 48.8146403, lng: 21.6535977 },
    { lat: 48.8145175, lng: 21.6533012 },
    { lat: 48.8145478, lng: 21.6532165 },
    { lat: 48.8144603, lng: 21.6529411 },
    { lat: 48.8146606, lng: 21.652077 },
    { lat: 48.8147288, lng: 21.6520196 },
    { lat: 48.81473, lng: 21.6517392 },
    { lat: 48.8147932, lng: 21.6516621 },
    { lat: 48.8147095, lng: 21.6511954 },
    { lat: 48.8147242, lng: 21.6508293 },
    { lat: 48.8147948, lng: 21.6505753 },
    { lat: 48.8146778, lng: 21.6493885 },
    { lat: 48.8145598, lng: 21.6492278 },
    { lat: 48.814469, lng: 21.6487908 },
    { lat: 48.8144717, lng: 21.6481919 },
    { lat: 48.8143477, lng: 21.6480123 },
    { lat: 48.8143739, lng: 21.6479029 },
    { lat: 48.8143175, lng: 21.6472835 },
    { lat: 48.8141408, lng: 21.6466684 },
    { lat: 48.8141629, lng: 21.6464532 },
    { lat: 48.814074, lng: 21.6462028 },
    { lat: 48.8141296, lng: 21.6458509 },
    { lat: 48.8143383, lng: 21.6455407 },
  ],
  KamennáPoruba: [
    { lat: 48.8309434, lng: 21.6491004 },
    { lat: 48.8311763, lng: 21.6490679 },
    { lat: 48.8313255, lng: 21.6492538 },
    { lat: 48.8315506, lng: 21.649295 },
    { lat: 48.8316945, lng: 21.6494599 },
    { lat: 48.8319037, lng: 21.6499463 },
    { lat: 48.8323488, lng: 21.6506658 },
    { lat: 48.8328808, lng: 21.6511523 },
    { lat: 48.8333059, lng: 21.6518516 },
    { lat: 48.8340323, lng: 21.6520755 },
    { lat: 48.83436, lng: 21.6534006 },
    { lat: 48.8355296, lng: 21.65741 },
    { lat: 48.8360421, lng: 21.6585471 },
    { lat: 48.8363301, lng: 21.6594922 },
    { lat: 48.8374772, lng: 21.6622739 },
    { lat: 48.8376979, lng: 21.662319 },
    { lat: 48.837809, lng: 21.6627621 },
    { lat: 48.8383357, lng: 21.6642116 },
    { lat: 48.8388503, lng: 21.6662895 },
    { lat: 48.8394721, lng: 21.6678439 },
    { lat: 48.8397448, lng: 21.6676936 },
    { lat: 48.8397841, lng: 21.6678536 },
    { lat: 48.8401395, lng: 21.667686 },
    { lat: 48.8405316, lng: 21.6687769 },
    { lat: 48.8408098, lng: 21.6685424 },
    { lat: 48.8416196, lng: 21.6691139 },
    { lat: 48.841741, lng: 21.6693524 },
    { lat: 48.8418824, lng: 21.6695061 },
    { lat: 48.8420473, lng: 21.6695293 },
    { lat: 48.842188, lng: 21.6696995 },
    { lat: 48.8423226, lng: 21.6703826 },
    { lat: 48.842374, lng: 21.670409 },
    { lat: 48.8488743, lng: 21.6687482 },
    { lat: 48.849114, lng: 21.6691609 },
    { lat: 48.8491885, lng: 21.6691337 },
    { lat: 48.8493036, lng: 21.6702632 },
    { lat: 48.8492107, lng: 21.6703115 },
    { lat: 48.8490635, lng: 21.6706994 },
    { lat: 48.8487256, lng: 21.6732433 },
    { lat: 48.8490748, lng: 21.6734704 },
    { lat: 48.8491407, lng: 21.6734463 },
    { lat: 48.8491956, lng: 21.6743492 },
    { lat: 48.8492086, lng: 21.6742876 },
    { lat: 48.8494981, lng: 21.6740977 },
    { lat: 48.8495619, lng: 21.6726827 },
    { lat: 48.8500284, lng: 21.6710527 },
    { lat: 48.8501888, lng: 21.6702085 },
    { lat: 48.8502269, lng: 21.6697446 },
    { lat: 48.8502207, lng: 21.6692742 },
    { lat: 48.8501506, lng: 21.6687613 },
    { lat: 48.8497479, lng: 21.6671164 },
    { lat: 48.8508651, lng: 21.6667428 },
    { lat: 48.8554922, lng: 21.6654176 },
    { lat: 48.8554983, lng: 21.6657617 },
    { lat: 48.8550264, lng: 21.6668531 },
    { lat: 48.8548094, lng: 21.6674837 },
    { lat: 48.854798, lng: 21.6677202 },
    { lat: 48.8547242, lng: 21.6678666 },
    { lat: 48.8545883, lng: 21.6679594 },
    { lat: 48.854439, lng: 21.6681731 },
    { lat: 48.8544025, lng: 21.6685724 },
    { lat: 48.8544186, lng: 21.6686285 },
    { lat: 48.8545271, lng: 21.6686374 },
    { lat: 48.854897, lng: 21.6688808 },
    { lat: 48.8549956, lng: 21.6687417 },
    { lat: 48.8551328, lng: 21.668741 },
    { lat: 48.8552048, lng: 21.6688226 },
    { lat: 48.8552314, lng: 21.6687263 },
    { lat: 48.855169, lng: 21.6684969 },
    { lat: 48.8550733, lng: 21.6683768 },
    { lat: 48.8551327, lng: 21.6682373 },
    { lat: 48.8553616, lng: 21.6682187 },
    { lat: 48.8555432, lng: 21.6681105 },
    { lat: 48.8557283, lng: 21.6682132 },
    { lat: 48.855789, lng: 21.6680444 },
    { lat: 48.8557665, lng: 21.6679625 },
    { lat: 48.8556554, lng: 21.6680138 },
    { lat: 48.8556441, lng: 21.6677297 },
    { lat: 48.8555147, lng: 21.6676857 },
    { lat: 48.8554972, lng: 21.6675796 },
    { lat: 48.855624, lng: 21.6675699 },
    { lat: 48.8556976, lng: 21.6674616 },
    { lat: 48.8558146, lng: 21.6674197 },
    { lat: 48.8559097, lng: 21.6674405 },
    { lat: 48.855971, lng: 21.6672916 },
    { lat: 48.8558621, lng: 21.6670587 },
    { lat: 48.8559756, lng: 21.666969 },
    { lat: 48.8559762, lng: 21.6668275 },
    { lat: 48.8561003, lng: 21.6666733 },
    { lat: 48.856258, lng: 21.6666813 },
    { lat: 48.8561941, lng: 21.6663608 },
    { lat: 48.8562437, lng: 21.6657839 },
    { lat: 48.8563273, lng: 21.6655915 },
    { lat: 48.8563083, lng: 21.6655098 },
    { lat: 48.8562025, lng: 21.6654239 },
    { lat: 48.8560782, lng: 21.6654049 },
    { lat: 48.8560614, lng: 21.6652473 },
    { lat: 48.8561577, lng: 21.6647336 },
    { lat: 48.8559913, lng: 21.6645371 },
    { lat: 48.8560533, lng: 21.6644929 },
    { lat: 48.8561654, lng: 21.6641947 },
    { lat: 48.8560891, lng: 21.6640769 },
    { lat: 48.8561486, lng: 21.6639081 },
    { lat: 48.8560867, lng: 21.663659 },
    { lat: 48.8560151, lng: 21.6636714 },
    { lat: 48.8559617, lng: 21.6632892 },
    { lat: 48.8560608, lng: 21.6632033 },
    { lat: 48.8561777, lng: 21.663246 },
    { lat: 48.8562321, lng: 21.6629664 },
    { lat: 48.8561567, lng: 21.6629687 },
    { lat: 48.8560859, lng: 21.6628452 },
    { lat: 48.8561811, lng: 21.662706 },
    { lat: 48.8561402, lng: 21.662607 },
    { lat: 48.8562527, lng: 21.6624024 },
    { lat: 48.8562854, lng: 21.6624518 },
    { lat: 48.8563573, lng: 21.6622322 },
    { lat: 48.8563981, lng: 21.6619235 },
    { lat: 48.8563409, lng: 21.6618413 },
    { lat: 48.8563488, lng: 21.6616818 },
    { lat: 48.8564395, lng: 21.6616597 },
    { lat: 48.8564543, lng: 21.6614276 },
    { lat: 48.8565056, lng: 21.6613286 },
    { lat: 48.8563579, lng: 21.6612714 },
    { lat: 48.856361, lng: 21.6609297 },
    { lat: 48.8564368, lng: 21.6608192 },
    { lat: 48.8565173, lng: 21.6608488 },
    { lat: 48.8565242, lng: 21.6607662 },
    { lat: 48.8565142, lng: 21.6604417 },
    { lat: 48.85636, lng: 21.6604003 },
    { lat: 48.8564144, lng: 21.6601307 },
    { lat: 48.8562506, lng: 21.6601045 },
    { lat: 48.8562036, lng: 21.6599485 },
    { lat: 48.8562521, lng: 21.659745 },
    { lat: 48.8562257, lng: 21.6595984 },
    { lat: 48.8561149, lng: 21.6595828 },
    { lat: 48.856115, lng: 21.6592162 },
    { lat: 48.8563206, lng: 21.6589146 },
    { lat: 48.8564889, lng: 21.6590256 },
    { lat: 48.8566904, lng: 21.6588253 },
    { lat: 48.8567624, lng: 21.6589476 },
    { lat: 48.8569411, lng: 21.6586127 },
    { lat: 48.8567139, lng: 21.6583633 },
    { lat: 48.8566958, lng: 21.6580307 },
    { lat: 48.8566373, lng: 21.658073 },
    { lat: 48.8565944, lng: 21.6579021 },
    { lat: 48.8566909, lng: 21.6577316 },
    { lat: 48.8566936, lng: 21.6575457 },
    { lat: 48.8567992, lng: 21.6573268 },
    { lat: 48.8569791, lng: 21.6573641 },
    { lat: 48.8569869, lng: 21.6572797 },
    { lat: 48.8568486, lng: 21.657159 },
    { lat: 48.85682, lng: 21.6570197 },
    { lat: 48.8568506, lng: 21.6568638 },
    { lat: 48.8568063, lng: 21.6565643 },
    { lat: 48.8568429, lng: 21.6563733 },
    { lat: 48.8570016, lng: 21.6562547 },
    { lat: 48.8570396, lng: 21.6560848 },
    { lat: 48.8569677, lng: 21.6558796 },
    { lat: 48.856982, lng: 21.6556271 },
    { lat: 48.856873, lng: 21.6555036 },
    { lat: 48.8568671, lng: 21.6550012 },
    { lat: 48.8571298, lng: 21.654532 },
    { lat: 48.8572621, lng: 21.6544287 },
    { lat: 48.8572884, lng: 21.6543216 },
    { lat: 48.8572285, lng: 21.6542278 },
    { lat: 48.8574084, lng: 21.6541422 },
    { lat: 48.8574776, lng: 21.6540421 },
    { lat: 48.8575037, lng: 21.6537225 },
    { lat: 48.8575984, lng: 21.653684 },
    { lat: 48.8575113, lng: 21.6532564 },
    { lat: 48.8577307, lng: 21.6525148 },
    { lat: 48.857905, lng: 21.652352 },
    { lat: 48.8580622, lng: 21.6519716 },
    { lat: 48.8582283, lng: 21.6517125 },
    { lat: 48.8586774, lng: 21.6515542 },
    { lat: 48.8588572, lng: 21.6516654 },
    { lat: 48.8590714, lng: 21.6511099 },
    { lat: 48.8589391, lng: 21.6507109 },
    { lat: 48.8589116, lng: 21.650371 },
    { lat: 48.8590245, lng: 21.6501381 },
    { lat: 48.8591621, lng: 21.6501863 },
    { lat: 48.8592733, lng: 21.650349 },
    { lat: 48.8593431, lng: 21.6503031 },
    { lat: 48.8596355, lng: 21.6495247 },
    { lat: 48.8598491, lng: 21.6491472 },
    { lat: 48.8597457, lng: 21.6489447 },
    { lat: 48.8596309, lng: 21.6490298 },
    { lat: 48.8594461, lng: 21.6488063 },
    { lat: 48.859467, lng: 21.6485903 },
    { lat: 48.8593347, lng: 21.6484853 },
    { lat: 48.8591904, lng: 21.6485955 },
    { lat: 48.8590885, lng: 21.648533 },
    { lat: 48.8590913, lng: 21.6480179 },
    { lat: 48.8589691, lng: 21.648031 },
    { lat: 48.8588895, lng: 21.6479249 },
    { lat: 48.8589773, lng: 21.647636 },
    { lat: 48.8590571, lng: 21.6476292 },
    { lat: 48.8590653, lng: 21.6473946 },
    { lat: 48.8589914, lng: 21.6472049 },
    { lat: 48.8588805, lng: 21.6472251 },
    { lat: 48.8587933, lng: 21.6473196 },
    { lat: 48.8587539, lng: 21.6470791 },
    { lat: 48.8588181, lng: 21.6467914 },
    { lat: 48.8589738, lng: 21.6469327 },
    { lat: 48.8590874, lng: 21.6468083 },
    { lat: 48.8591841, lng: 21.6465196 },
    { lat: 48.8591368, lng: 21.6463521 },
    { lat: 48.8591692, lng: 21.645812 },
    { lat: 48.8591364, lng: 21.6455615 },
    { lat: 48.8593138, lng: 21.6455074 },
    { lat: 48.8594222, lng: 21.6454355 },
    { lat: 48.8594871, lng: 21.6453003 },
    { lat: 48.8595624, lng: 21.6453056 },
    { lat: 48.8596511, lng: 21.6450477 },
    { lat: 48.8596723, lng: 21.6448316 },
    { lat: 48.8597655, lng: 21.6447832 },
    { lat: 48.8596389, lng: 21.6444421 },
    { lat: 48.8595872, lng: 21.6440706 },
    { lat: 48.8596291, lng: 21.6437578 },
    { lat: 48.8585438, lng: 21.6445448 },
    { lat: 48.8582126, lng: 21.6437153 },
    { lat: 48.8580538, lng: 21.6429696 },
    { lat: 48.8579979, lng: 21.6423707 },
    { lat: 48.857785, lng: 21.6417442 },
    { lat: 48.857667, lng: 21.6415055 },
    { lat: 48.857456, lng: 21.6412744 },
    { lat: 48.8573614, lng: 21.6412743 },
    { lat: 48.8571036, lng: 21.6406928 },
    { lat: 48.8571306, lng: 21.6404932 },
    { lat: 48.8565603, lng: 21.6390614 },
    { lat: 48.8564579, lng: 21.6391433 },
    { lat: 48.8559869, lng: 21.6378946 },
    { lat: 48.8554969, lng: 21.6382768 },
    { lat: 48.8553267, lng: 21.6379003 },
    { lat: 48.8551682, lng: 21.6380292 },
    { lat: 48.8550465, lng: 21.6377364 },
    { lat: 48.854386, lng: 21.6382598 },
    { lat: 48.85393, lng: 21.6373086 },
    { lat: 48.8537984, lng: 21.6374355 },
    { lat: 48.8535665, lng: 21.6368578 },
    { lat: 48.8533538, lng: 21.6370192 },
    { lat: 48.8531457, lng: 21.6364889 },
    { lat: 48.8524817, lng: 21.6370939 },
    { lat: 48.8523621, lng: 21.6368353 },
    { lat: 48.8520462, lng: 21.6370064 },
    { lat: 48.8519652, lng: 21.6364785 },
    { lat: 48.8516837, lng: 21.6356585 },
    { lat: 48.851744, lng: 21.6355547 },
    { lat: 48.8517693, lng: 21.6351712 },
    { lat: 48.8515609, lng: 21.6346252 },
    { lat: 48.8515789, lng: 21.634508 },
    { lat: 48.8513808, lng: 21.6345041 },
    { lat: 48.8513734, lng: 21.6340748 },
    { lat: 48.8512836, lng: 21.6339983 },
    { lat: 48.8511007, lng: 21.6340498 },
    { lat: 48.8511613, lng: 21.6338045 },
    { lat: 48.851088, lng: 21.633717 },
    { lat: 48.851163, lng: 21.6336583 },
    { lat: 48.8510246, lng: 21.6332919 },
    { lat: 48.8510773, lng: 21.6330374 },
    { lat: 48.8509209, lng: 21.6324275 },
    { lat: 48.8506646, lng: 21.6319936 },
    { lat: 48.8506176, lng: 21.6317143 },
    { lat: 48.8504095, lng: 21.6312645 },
    { lat: 48.8505145, lng: 21.6312007 },
    { lat: 48.8506434, lng: 21.6312236 },
    { lat: 48.8506086, lng: 21.6304048 },
    { lat: 48.8511256, lng: 21.6294479 },
    { lat: 48.8513024, lng: 21.6292516 },
    { lat: 48.8513873, lng: 21.629028 },
    { lat: 48.8514562, lng: 21.6289989 },
    { lat: 48.8515148, lng: 21.6287559 },
    { lat: 48.8515451, lng: 21.6283045 },
    { lat: 48.8518082, lng: 21.6280142 },
    { lat: 48.8518589, lng: 21.6277673 },
    { lat: 48.8522082, lng: 21.6271343 },
    { lat: 48.8522501, lng: 21.626371 },
    { lat: 48.8521638, lng: 21.6263429 },
    { lat: 48.8520093, lng: 21.6258057 },
    { lat: 48.8518856, lng: 21.6257203 },
    { lat: 48.8518915, lng: 21.6252858 },
    { lat: 48.8519712, lng: 21.6250732 },
    { lat: 48.8519749, lng: 21.6248425 },
    { lat: 48.851934, lng: 21.624743 },
    { lat: 48.8519736, lng: 21.6241013 },
    { lat: 48.8519079, lng: 21.6237221 },
    { lat: 48.8520508, lng: 21.6231954 },
    { lat: 48.852022, lng: 21.6230829 },
    { lat: 48.8521373, lng: 21.6227755 },
    { lat: 48.8520961, lng: 21.6225916 },
    { lat: 48.8521661, lng: 21.6222302 },
    { lat: 48.8521018, lng: 21.62215 },
    { lat: 48.852133, lng: 21.6219312 },
    { lat: 48.8520769, lng: 21.6217339 },
    { lat: 48.8521108, lng: 21.6215371 },
    { lat: 48.8520845, lng: 21.6213955 },
    { lat: 48.8521629, lng: 21.6212194 },
    { lat: 48.8520056, lng: 21.6206638 },
    { lat: 48.8519811, lng: 21.6189634 },
    { lat: 48.8518854, lng: 21.6184775 },
    { lat: 48.8518559, lng: 21.618061 },
    { lat: 48.8517663, lng: 21.6178277 },
    { lat: 48.8518821, lng: 21.6174638 },
    { lat: 48.8518303, lng: 21.617296 },
    { lat: 48.8518822, lng: 21.6171008 },
    { lat: 48.8518228, lng: 21.6169052 },
    { lat: 48.8518652, lng: 21.6159626 },
    { lat: 48.8517116, lng: 21.6145205 },
    { lat: 48.8510817, lng: 21.6122516 },
    { lat: 48.8508604, lng: 21.6111239 },
    { lat: 48.8507773, lng: 21.609313 },
    { lat: 48.8505873, lng: 21.6085062 },
    { lat: 48.8503856, lng: 21.6078071 },
    { lat: 48.8500921, lng: 21.6070435 },
    { lat: 48.8494362, lng: 21.6056681 },
    { lat: 48.8494862, lng: 21.6056035 },
    { lat: 48.8492558, lng: 21.6049237 },
    { lat: 48.8490992, lng: 21.6042246 },
    { lat: 48.8490962, lng: 21.6039543 },
    { lat: 48.8461604, lng: 21.6086034 },
    { lat: 48.8457779, lng: 21.6108665 },
    { lat: 48.8454365, lng: 21.6118248 },
    { lat: 48.8450257, lng: 21.6131991 },
    { lat: 48.8441476, lng: 21.6150914 },
    { lat: 48.843225, lng: 21.6186984 },
    { lat: 48.8430344, lng: 21.6192121 },
    { lat: 48.8428841, lng: 21.6193891 },
    { lat: 48.8428528, lng: 21.6196033 },
    { lat: 48.842658, lng: 21.6198804 },
    { lat: 48.8426795, lng: 21.6200454 },
    { lat: 48.8425635, lng: 21.6206918 },
    { lat: 48.8414919, lng: 21.6220633 },
    { lat: 48.8414269, lng: 21.6223967 },
    { lat: 48.8414594, lng: 21.622549 },
    { lat: 48.8413883, lng: 21.6225888 },
    { lat: 48.8411808, lng: 21.6229342 },
    { lat: 48.8411914, lng: 21.6231568 },
    { lat: 48.8411183, lng: 21.6232754 },
    { lat: 48.8411032, lng: 21.623506 },
    { lat: 48.8411214, lng: 21.6238724 },
    { lat: 48.8409817, lng: 21.6243217 },
    { lat: 48.8410794, lng: 21.6243912 },
    { lat: 48.8410236, lng: 21.624738 },
    { lat: 48.8410698, lng: 21.6251472 },
    { lat: 48.8409661, lng: 21.6252588 },
    { lat: 48.8409782, lng: 21.6254559 },
    { lat: 48.8410671, lng: 21.6255357 },
    { lat: 48.8410001, lng: 21.6256778 },
    { lat: 48.8411972, lng: 21.6260536 },
    { lat: 48.841198, lng: 21.6261596 },
    { lat: 48.8410752, lng: 21.6262781 },
    { lat: 48.8412524, lng: 21.6266616 },
    { lat: 48.8412102, lng: 21.6268061 },
    { lat: 48.8411254, lng: 21.6268336 },
    { lat: 48.8411127, lng: 21.6269387 },
    { lat: 48.8412451, lng: 21.6270542 },
    { lat: 48.8413334, lng: 21.6275198 },
    { lat: 48.8414093, lng: 21.627576 },
    { lat: 48.8414097, lng: 21.6278746 },
    { lat: 48.8413329, lng: 21.6282335 },
    { lat: 48.8413962, lng: 21.6285281 },
    { lat: 48.8412709, lng: 21.6287217 },
    { lat: 48.8413885, lng: 21.6294726 },
    { lat: 48.8414839, lng: 21.6297934 },
    { lat: 48.8414317, lng: 21.6299782 },
    { lat: 48.8414493, lng: 21.630087 },
    { lat: 48.8415139, lng: 21.6301448 },
    { lat: 48.8416436, lng: 21.6299915 },
    { lat: 48.8416962, lng: 21.6297782 },
    { lat: 48.8419724, lng: 21.6295982 },
    { lat: 48.8420409, lng: 21.6298739 },
    { lat: 48.8420501, lng: 21.630123 },
    { lat: 48.8420279, lng: 21.6303437 },
    { lat: 48.8418908, lng: 21.6307585 },
    { lat: 48.8419515, lng: 21.630999 },
    { lat: 48.8420411, lng: 21.631104 },
    { lat: 48.8420475, lng: 21.631334 },
    { lat: 48.8421559, lng: 21.6313496 },
    { lat: 48.8423517, lng: 21.6316362 },
    { lat: 48.8423891, lng: 21.6318903 },
    { lat: 48.8423591, lng: 21.6319044 },
    { lat: 48.842391, lng: 21.6326192 },
    { lat: 48.8422339, lng: 21.6334454 },
    { lat: 48.8416737, lng: 21.6341016 },
    { lat: 48.8412016, lng: 21.6344836 },
    { lat: 48.8409815, lng: 21.6349915 },
    { lat: 48.8409111, lng: 21.635011 },
    { lat: 48.8409965, lng: 21.6357 },
    { lat: 48.8405043, lng: 21.6373973 },
    { lat: 48.8403174, lng: 21.6383749 },
    { lat: 48.8397744, lng: 21.6393683 },
    { lat: 48.8396343, lng: 21.6393896 },
    { lat: 48.8395182, lng: 21.6396273 },
    { lat: 48.83936, lng: 21.6396434 },
    { lat: 48.8393026, lng: 21.6398452 },
    { lat: 48.8393213, lng: 21.6403605 },
    { lat: 48.8389129, lng: 21.6431472 },
    { lat: 48.8386766, lng: 21.6432632 },
    { lat: 48.8386334, lng: 21.643452 },
    { lat: 48.8386027, lng: 21.6442651 },
    { lat: 48.8383963, lng: 21.6451003 },
    { lat: 48.8379678, lng: 21.6453095 },
    { lat: 48.8379882, lng: 21.645686 },
    { lat: 48.8374994, lng: 21.6461201 },
    { lat: 48.8364387, lng: 21.6469102 },
    { lat: 48.8359731, lng: 21.6471177 },
    { lat: 48.8346749, lng: 21.647122 },
    { lat: 48.8324168, lng: 21.6479399 },
    { lat: 48.8323902, lng: 21.6478726 },
    { lat: 48.8322973, lng: 21.6479738 },
    { lat: 48.8312246, lng: 21.6485656 },
    { lat: 48.8311106, lng: 21.6488803 },
    { lat: 48.8309784, lng: 21.6490718 },
    { lat: 48.8309434, lng: 21.6491004 },
  ],
  NižnýHrabovec: [
    { lat: 48.87313, lng: 21.7799029 },
    { lat: 48.872431, lng: 21.7800537 },
    { lat: 48.8720332, lng: 21.7802475 },
    { lat: 48.8720446, lng: 21.7795253 },
    { lat: 48.8721454, lng: 21.7788198 },
    { lat: 48.8722826, lng: 21.7771541 },
    { lat: 48.8722593, lng: 21.7764393 },
    { lat: 48.873072, lng: 21.7762458 },
    { lat: 48.8716487, lng: 21.7694061 },
    { lat: 48.8705771, lng: 21.7697652 },
    { lat: 48.869918, lng: 21.7696861 },
    { lat: 48.8697432, lng: 21.7700119 },
    { lat: 48.8694565, lng: 21.7703155 },
    { lat: 48.8689976, lng: 21.7704098 },
    { lat: 48.868669, lng: 21.7707852 },
    { lat: 48.8684249, lng: 21.770914 },
    { lat: 48.8681411, lng: 21.7712825 },
    { lat: 48.8677789, lng: 21.7707341 },
    { lat: 48.8669609, lng: 21.7692332 },
    { lat: 48.8667656, lng: 21.7689531 },
    { lat: 48.8664772, lng: 21.7686801 },
    { lat: 48.8660466, lng: 21.7680498 },
    { lat: 48.8660801, lng: 21.7679613 },
    { lat: 48.8657037, lng: 21.7671712 },
    { lat: 48.8663569, lng: 21.7665538 },
    { lat: 48.8660389, lng: 21.7661421 },
    { lat: 48.8662019, lng: 21.7660136 },
    { lat: 48.8658535, lng: 21.7658525 },
    { lat: 48.8656493, lng: 21.7650663 },
    { lat: 48.8660706, lng: 21.7647002 },
    { lat: 48.8658412, lng: 21.7642378 },
    { lat: 48.8657233, lng: 21.7641191 },
    { lat: 48.8656396, lng: 21.7638502 },
    { lat: 48.86545, lng: 21.763529 },
    { lat: 48.8651118, lng: 21.7631193 },
    { lat: 48.8655717, lng: 21.7626397 },
    { lat: 48.8659596, lng: 21.7620795 },
    { lat: 48.8654319, lng: 21.7607591 },
    { lat: 48.8650316, lng: 21.7608899 },
    { lat: 48.8641401, lng: 21.7614732 },
    { lat: 48.8638504, lng: 21.7615366 },
    { lat: 48.864808, lng: 21.7587299 },
    { lat: 48.864938, lng: 21.7581649 },
    { lat: 48.8646692, lng: 21.7582081 },
    { lat: 48.8641822, lng: 21.7580865 },
    { lat: 48.8630889, lng: 21.7571186 },
    { lat: 48.8632857, lng: 21.7566097 },
    { lat: 48.8632076, lng: 21.7559459 },
    { lat: 48.8629609, lng: 21.7553817 },
    { lat: 48.8622866, lng: 21.7560913 },
    { lat: 48.8618335, lng: 21.7541905 },
    { lat: 48.8625735, lng: 21.7536745 },
    { lat: 48.8606939, lng: 21.7498252 },
    { lat: 48.8608224, lng: 21.7496691 },
    { lat: 48.8599899, lng: 21.7479685 },
    { lat: 48.8596567, lng: 21.7486047 },
    { lat: 48.8590658, lng: 21.7474039 },
    { lat: 48.8588018, lng: 21.7471195 },
    { lat: 48.8590534, lng: 21.7464732 },
    { lat: 48.8588796, lng: 21.7461994 },
    { lat: 48.8603591, lng: 21.7473864 },
    { lat: 48.8610818, lng: 21.7477467 },
    { lat: 48.8614981, lng: 21.7442763 },
    { lat: 48.859004, lng: 21.7430949 },
    { lat: 48.8595621, lng: 21.7349054 },
    { lat: 48.8595731, lng: 21.7347243 },
    { lat: 48.859191, lng: 21.734702 },
    { lat: 48.8590718, lng: 21.7346246 },
    { lat: 48.858777, lng: 21.7342393 },
    { lat: 48.8584192, lng: 21.7338979 },
    { lat: 48.8582208, lng: 21.7331233 },
    { lat: 48.8581504, lng: 21.7330212 },
    { lat: 48.8582448, lng: 21.7322572 },
    { lat: 48.8581634, lng: 21.7321576 },
    { lat: 48.8584552, lng: 21.7303215 },
    { lat: 48.8564712, lng: 21.7297062 },
    { lat: 48.856775, lng: 21.7281134 },
    { lat: 48.853263, lng: 21.7270737 },
    { lat: 48.8532317, lng: 21.7263272 },
    { lat: 48.8527675, lng: 21.7257077 },
    { lat: 48.8525039, lng: 21.7262444 },
    { lat: 48.8520156, lng: 21.7265773 },
    { lat: 48.8516784, lng: 21.7266713 },
    { lat: 48.8517214, lng: 21.7270173 },
    { lat: 48.8518803, lng: 21.7274501 },
    { lat: 48.85246, lng: 21.7283409 },
    { lat: 48.8524046, lng: 21.7285886 },
    { lat: 48.8521207, lng: 21.7287782 },
    { lat: 48.8516222, lng: 21.7278486 },
    { lat: 48.851537, lng: 21.7275727 },
    { lat: 48.8510941, lng: 21.7249303 },
    { lat: 48.8513987, lng: 21.7250913 },
    { lat: 48.8518213, lng: 21.7256233 },
    { lat: 48.852415, lng: 21.7254882 },
    { lat: 48.8524858, lng: 21.7253149 },
    { lat: 48.8524064, lng: 21.7241959 },
    { lat: 48.8520277, lng: 21.7237873 },
    { lat: 48.8516244, lng: 21.723652 },
    { lat: 48.8512374, lng: 21.723857 },
    { lat: 48.8507304, lng: 21.7237031 },
    { lat: 48.8501372, lng: 21.7237356 },
    { lat: 48.8499818, lng: 21.7238127 },
    { lat: 48.8499207, lng: 21.7239254 },
    { lat: 48.850108, lng: 21.7247841 },
    { lat: 48.8503893, lng: 21.7252065 },
    { lat: 48.8505354, lng: 21.7257884 },
    { lat: 48.8504804, lng: 21.726259 },
    { lat: 48.850288, lng: 21.7264266 },
    { lat: 48.8499921, lng: 21.7264262 },
    { lat: 48.8498953, lng: 21.7263393 },
    { lat: 48.849629, lng: 21.7257616 },
    { lat: 48.8494023, lng: 21.7256151 },
    { lat: 48.849112, lng: 21.725534 },
    { lat: 48.8488789, lng: 21.7255612 },
    { lat: 48.8487429, lng: 21.7256514 },
    { lat: 48.8487053, lng: 21.7259277 },
    { lat: 48.8488376, lng: 21.7263725 },
    { lat: 48.8488431, lng: 21.7270423 },
    { lat: 48.849035, lng: 21.7280296 },
    { lat: 48.8490121, lng: 21.7284447 },
    { lat: 48.8489024, lng: 21.7290504 },
    { lat: 48.8483851, lng: 21.7293105 },
    { lat: 48.8477494, lng: 21.727921 },
    { lat: 48.8473489, lng: 21.7268211 },
    { lat: 48.8468196, lng: 21.727104 },
    { lat: 48.8467191, lng: 21.7267109 },
    { lat: 48.8467574, lng: 21.7266646 },
    { lat: 48.8467256, lng: 21.7258968 },
    { lat: 48.8470275, lng: 21.7252853 },
    { lat: 48.8473807, lng: 21.723978 },
    { lat: 48.8474011, lng: 21.7233999 },
    { lat: 48.8472894, lng: 21.7231288 },
    { lat: 48.8462602, lng: 21.7236858 },
    { lat: 48.8451469, lng: 21.7253019 },
    { lat: 48.8451296, lng: 21.7253255 },
    { lat: 48.8441545, lng: 21.7267262 },
    { lat: 48.8441014, lng: 21.7266341 },
    { lat: 48.8434827, lng: 21.7268439 },
    { lat: 48.8431057, lng: 21.7268864 },
    { lat: 48.8424854, lng: 21.7273702 },
    { lat: 48.842379, lng: 21.7275582 },
    { lat: 48.8420433, lng: 21.727876 },
    { lat: 48.8415322, lng: 21.7280791 },
    { lat: 48.8410769, lng: 21.7285543 },
    { lat: 48.8410714, lng: 21.7285575 },
    { lat: 48.8423377, lng: 21.7346837 },
    { lat: 48.8418046, lng: 21.7343999 },
    { lat: 48.8408387, lng: 21.7344173 },
    { lat: 48.8411867, lng: 21.7366182 },
    { lat: 48.8431039, lng: 21.7436807 },
    { lat: 48.8438072, lng: 21.74597 },
    { lat: 48.8439971, lng: 21.7468085 },
    { lat: 48.8439881, lng: 21.7473541 },
    { lat: 48.8438467, lng: 21.7476667 },
    { lat: 48.8437006, lng: 21.7478367 },
    { lat: 48.8432689, lng: 21.7480563 },
    { lat: 48.8430139, lng: 21.7493462 },
    { lat: 48.843003, lng: 21.7503291 },
    { lat: 48.843776, lng: 21.7508431 },
    { lat: 48.844347, lng: 21.7518416 },
    { lat: 48.8438497, lng: 21.753282 },
    { lat: 48.8438433, lng: 21.7536835 },
    { lat: 48.8439137, lng: 21.7541217 },
    { lat: 48.844146, lng: 21.7543133 },
    { lat: 48.8442299, lng: 21.7580753 },
    { lat: 48.8441767, lng: 21.761845 },
    { lat: 48.8440019, lng: 21.7631503 },
    { lat: 48.8435143, lng: 21.7657237 },
    { lat: 48.8435532, lng: 21.7660903 },
    { lat: 48.8436631, lng: 21.7665019 },
    { lat: 48.8441989, lng: 21.7702658 },
    { lat: 48.8441868, lng: 21.7721945 },
    { lat: 48.8440465, lng: 21.7745528 },
    { lat: 48.8440208, lng: 21.775707 },
    { lat: 48.8447869, lng: 21.775796 },
    { lat: 48.8447515, lng: 21.7771915 },
    { lat: 48.8448848, lng: 21.7783603 },
    { lat: 48.8447159, lng: 21.7798517 },
    { lat: 48.8446984, lng: 21.7804689 },
    { lat: 48.8443778, lng: 21.7823141 },
    { lat: 48.8438978, lng: 21.785583 },
    { lat: 48.84369, lng: 21.7860269 },
    { lat: 48.8429595, lng: 21.7902972 },
    { lat: 48.8434268, lng: 21.7911292 },
    { lat: 48.843782, lng: 21.7918878 },
    { lat: 48.8446967, lng: 21.7921507 },
    { lat: 48.845492, lng: 21.792911 },
    { lat: 48.84594, lng: 21.792381 },
    { lat: 48.846025, lng: 21.792328 },
    { lat: 48.846211, lng: 21.792214 },
    { lat: 48.846523, lng: 21.791872 },
    { lat: 48.846746, lng: 21.79156 },
    { lat: 48.846915, lng: 21.791383 },
    { lat: 48.847133, lng: 21.791215 },
    { lat: 48.847383, lng: 21.791105 },
    { lat: 48.84776, lng: 21.791132 },
    { lat: 48.848196, lng: 21.791221 },
    { lat: 48.848572, lng: 21.791199 },
    { lat: 48.848794, lng: 21.79106 },
    { lat: 48.849131, lng: 21.790929 },
    { lat: 48.849275, lng: 21.790831 },
    { lat: 48.849471, lng: 21.790809 },
    { lat: 48.849685, lng: 21.790853 },
    { lat: 48.849861, lng: 21.790769 },
    { lat: 48.849932, lng: 21.790736 },
    { lat: 48.849999, lng: 21.79069 },
    { lat: 48.850068, lng: 21.790645 },
    { lat: 48.850277, lng: 21.790507 },
    { lat: 48.850535, lng: 21.790549 },
    { lat: 48.850656, lng: 21.790564 },
    { lat: 48.850767, lng: 21.790504 },
    { lat: 48.850931, lng: 21.790267 },
    { lat: 48.851283, lng: 21.790185 },
    { lat: 48.851446, lng: 21.790112 },
    { lat: 48.851773, lng: 21.789653 },
    { lat: 48.8519, lng: 21.78962 },
    { lat: 48.852121, lng: 21.789614 },
    { lat: 48.852326, lng: 21.789568 },
    { lat: 48.852518, lng: 21.789467 },
    { lat: 48.852801, lng: 21.789344 },
    { lat: 48.852953, lng: 21.78922 },
    { lat: 48.853037, lng: 21.789073 },
    { lat: 48.853191, lng: 21.788752 },
    { lat: 48.853308, lng: 21.788604 },
    { lat: 48.853503, lng: 21.788418 },
    { lat: 48.853918, lng: 21.788159 },
    { lat: 48.854384, lng: 21.788028 },
    { lat: 48.854761, lng: 21.787921 },
    { lat: 48.855147, lng: 21.787708 },
    { lat: 48.855661, lng: 21.787529 },
    { lat: 48.855965, lng: 21.787417 },
    { lat: 48.856137, lng: 21.787267 },
    { lat: 48.856317, lng: 21.787064 },
    { lat: 48.856501, lng: 21.786978 },
    { lat: 48.856786, lng: 21.786958 },
    { lat: 48.857074, lng: 21.786839 },
    { lat: 48.857422, lng: 21.786752 },
    { lat: 48.857806, lng: 21.786923 },
    { lat: 48.858073, lng: 21.786918 },
    { lat: 48.858319, lng: 21.786997 },
    { lat: 48.858747, lng: 21.786856 },
    { lat: 48.859124, lng: 21.786807 },
    { lat: 48.859979, lng: 21.786397 },
    { lat: 48.860291, lng: 21.786375 },
    { lat: 48.860558, lng: 21.786577 },
    { lat: 48.861036, lng: 21.786758 },
    { lat: 48.861412, lng: 21.786771 },
    { lat: 48.861745, lng: 21.786684 },
    { lat: 48.862063, lng: 21.786994 },
    { lat: 48.862453, lng: 21.787761 },
    { lat: 48.862569, lng: 21.788156 },
    { lat: 48.862788, lng: 21.788393 },
    { lat: 48.862832, lng: 21.78844 },
    { lat: 48.86307, lng: 21.789242 },
    { lat: 48.863319, lng: 21.789842 },
    { lat: 48.863357, lng: 21.789934 },
    { lat: 48.863373, lng: 21.789973 },
    { lat: 48.863393, lng: 21.791176 },
    { lat: 48.863484, lng: 21.791654 },
    { lat: 48.863632, lng: 21.792183 },
    { lat: 48.864381, lng: 21.793621 },
    { lat: 48.865339, lng: 21.793356 },
    { lat: 48.865513, lng: 21.79331 },
    { lat: 48.866164, lng: 21.793086 },
    { lat: 48.866361, lng: 21.792966 },
    { lat: 48.866809, lng: 21.792856 },
    { lat: 48.866961, lng: 21.792939 },
    { lat: 48.867152, lng: 21.792765 },
    { lat: 48.867454, lng: 21.792701 },
    { lat: 48.867764, lng: 21.792553 },
    { lat: 48.868093, lng: 21.792458 },
    { lat: 48.868674, lng: 21.792213 },
    { lat: 48.868795, lng: 21.792198 },
    { lat: 48.869091, lng: 21.791982 },
    { lat: 48.869146, lng: 21.791887 },
    { lat: 48.869191, lng: 21.791855 },
    { lat: 48.869315, lng: 21.791805 },
    { lat: 48.869668, lng: 21.791515 },
    { lat: 48.869752, lng: 21.791437 },
    { lat: 48.870077, lng: 21.791066 },
    { lat: 48.870204, lng: 21.790717 },
    { lat: 48.870447, lng: 21.790306 },
    { lat: 48.870558, lng: 21.790168 },
    { lat: 48.870685, lng: 21.789888 },
    { lat: 48.870842, lng: 21.789458 },
    { lat: 48.871005, lng: 21.789171 },
    { lat: 48.871087, lng: 21.788687 },
    { lat: 48.871094, lng: 21.787922 },
    { lat: 48.871133, lng: 21.787626 },
    { lat: 48.87126, lng: 21.787668 },
    { lat: 48.87146, lng: 21.787736 },
    { lat: 48.871783, lng: 21.787729 },
    { lat: 48.871995, lng: 21.787782 },
    { lat: 48.872299, lng: 21.78794 },
    { lat: 48.872481, lng: 21.788106 },
    { lat: 48.872684, lng: 21.78833 },
    { lat: 48.872732, lng: 21.788362 },
    { lat: 48.872806, lng: 21.788362 },
    { lat: 48.872896, lng: 21.78834 },
    { lat: 48.872984, lng: 21.788319 },
    { lat: 48.87305, lng: 21.788302 },
    { lat: 48.873426, lng: 21.788494 },
    { lat: 48.873621, lng: 21.788499 },
    { lat: 48.873797, lng: 21.788467 },
    { lat: 48.873975, lng: 21.788436 },
    { lat: 48.874288, lng: 21.788317 },
    { lat: 48.874421, lng: 21.788294 },
    { lat: 48.874821, lng: 21.788281 },
    { lat: 48.874946, lng: 21.788238 },
    { lat: 48.875114, lng: 21.788123 },
    { lat: 48.875617, lng: 21.787597 },
    { lat: 48.875838, lng: 21.787139 },
    { lat: 48.8757119, lng: 21.7870162 },
    { lat: 48.8749224, lng: 21.7850114 },
    { lat: 48.874241, lng: 21.7828345 },
    { lat: 48.8740536, lng: 21.7823402 },
    { lat: 48.8738572, lng: 21.7820632 },
    { lat: 48.8736134, lng: 21.781217 },
    { lat: 48.8732381, lng: 21.7803273 },
    { lat: 48.87313, lng: 21.7799029 },
  ],
  Čaklov: [
    { lat: 48.9007187, lng: 21.5865018 },
    { lat: 48.8992121, lng: 21.5867017 },
    { lat: 48.8991557, lng: 21.5865715 },
    { lat: 48.898976, lng: 21.5868674 },
    { lat: 48.8988991, lng: 21.5872957 },
    { lat: 48.8987123, lng: 21.587376 },
    { lat: 48.8986798, lng: 21.587259 },
    { lat: 48.8984987, lng: 21.5872578 },
    { lat: 48.8981182, lng: 21.5875386 },
    { lat: 48.8977761, lng: 21.587615 },
    { lat: 48.8977571, lng: 21.5866546 },
    { lat: 48.8975442, lng: 21.5866047 },
    { lat: 48.8974089, lng: 21.5863545 },
    { lat: 48.8970477, lng: 21.5863934 },
    { lat: 48.8970671, lng: 21.5861597 },
    { lat: 48.8969181, lng: 21.585983 },
    { lat: 48.896772, lng: 21.5856227 },
    { lat: 48.8961265, lng: 21.5856345 },
    { lat: 48.8959192, lng: 21.584501 },
    { lat: 48.8956298, lng: 21.5845315 },
    { lat: 48.8945019, lng: 21.5844298 },
    { lat: 48.893972, lng: 21.5845512 },
    { lat: 48.8937174, lng: 21.5847023 },
    { lat: 48.893409, lng: 21.5846946 },
    { lat: 48.8930144, lng: 21.5848305 },
    { lat: 48.8924195, lng: 21.5849571 },
    { lat: 48.8921282, lng: 21.5853659 },
    { lat: 48.8919534, lng: 21.5854297 },
    { lat: 48.8914202, lng: 21.5860821 },
    { lat: 48.8913321, lng: 21.5862684 },
    { lat: 48.8912821, lng: 21.5865304 },
    { lat: 48.8909578, lng: 21.5871691 },
    { lat: 48.8911231, lng: 21.5874034 },
    { lat: 48.8910499, lng: 21.5879648 },
    { lat: 48.8907337, lng: 21.5883287 },
    { lat: 48.890597, lng: 21.5882281 },
    { lat: 48.8898557, lng: 21.5888356 },
    { lat: 48.889941, lng: 21.588951 },
    { lat: 48.8896599, lng: 21.5898014 },
    { lat: 48.8894298, lng: 21.5900196 },
    { lat: 48.8891084, lng: 21.5901979 },
    { lat: 48.8872702, lng: 21.5909737 },
    { lat: 48.8872967, lng: 21.5912319 },
    { lat: 48.8874055, lng: 21.5915066 },
    { lat: 48.8873681, lng: 21.5917371 },
    { lat: 48.8875057, lng: 21.5919131 },
    { lat: 48.8865685, lng: 21.5923179 },
    { lat: 48.8861047, lng: 21.5925868 },
    { lat: 48.885771, lng: 21.5928781 },
    { lat: 48.8848203, lng: 21.5933634 },
    { lat: 48.8837421, lng: 21.5935909 },
    { lat: 48.8834495, lng: 21.5939951 },
    { lat: 48.8833113, lng: 21.5943257 },
    { lat: 48.8826431, lng: 21.5964619 },
    { lat: 48.882162, lng: 21.5975312 },
    { lat: 48.8820532, lng: 21.5979096 },
    { lat: 48.8822676, lng: 21.5984954 },
    { lat: 48.8824032, lng: 21.5992842 },
    { lat: 48.882792, lng: 21.6004801 },
    { lat: 48.883142, lng: 21.601902 },
    { lat: 48.8836123, lng: 21.6025083 },
    { lat: 48.8837913, lng: 21.6031542 },
    { lat: 48.88407, lng: 21.6050894 },
    { lat: 48.8840937, lng: 21.6056206 },
    { lat: 48.8836983, lng: 21.6059236 },
    { lat: 48.8837651, lng: 21.60626 },
    { lat: 48.8834532, lng: 21.6067768 },
    { lat: 48.8834734, lng: 21.6070197 },
    { lat: 48.8833339, lng: 21.6073868 },
    { lat: 48.883508, lng: 21.607784 },
    { lat: 48.8834964, lng: 21.60813 },
    { lat: 48.8836181, lng: 21.6081823 },
    { lat: 48.8837542, lng: 21.6083803 },
    { lat: 48.883845, lng: 21.6087706 },
    { lat: 48.883931, lng: 21.6088695 },
    { lat: 48.8840064, lng: 21.6095671 },
    { lat: 48.8839714, lng: 21.6096802 },
    { lat: 48.883996, lng: 21.6098366 },
    { lat: 48.8838451, lng: 21.6101584 },
    { lat: 48.8838931, lng: 21.6103072 },
    { lat: 48.8838496, lng: 21.6109252 },
    { lat: 48.8839169, lng: 21.6111236 },
    { lat: 48.88372, lng: 21.6116499 },
    { lat: 48.8835015, lng: 21.6126548 },
    { lat: 48.8833726, lng: 21.6140962 },
    { lat: 48.8832633, lng: 21.6145307 },
    { lat: 48.8833378, lng: 21.6148731 },
    { lat: 48.8832787, lng: 21.6151296 },
    { lat: 48.8831124, lng: 21.6155034 },
    { lat: 48.8831795, lng: 21.6163417 },
    { lat: 48.8831147, lng: 21.6168434 },
    { lat: 48.8829696, lng: 21.6172573 },
    { lat: 48.8826588, lng: 21.6178941 },
    { lat: 48.8824532, lng: 21.6186333 },
    { lat: 48.88225, lng: 21.6190959 },
    { lat: 48.8816037, lng: 21.620183 },
    { lat: 48.8814891, lng: 21.6205506 },
    { lat: 48.8813559, lng: 21.6207392 },
    { lat: 48.8812097, lng: 21.621145 },
    { lat: 48.8806436, lng: 21.6218794 },
    { lat: 48.8804295, lng: 21.622475 },
    { lat: 48.8804887, lng: 21.6226628 },
    { lat: 48.8804661, lng: 21.6232262 },
    { lat: 48.8803208, lng: 21.6244862 },
    { lat: 48.8802894, lng: 21.6251435 },
    { lat: 48.8800081, lng: 21.6253801 },
    { lat: 48.8800857, lng: 21.6260315 },
    { lat: 48.8802699, lng: 21.6264698 },
    { lat: 48.8800512, lng: 21.6268658 },
    { lat: 48.8798843, lng: 21.6277451 },
    { lat: 48.8799522, lng: 21.6281279 },
    { lat: 48.8798517, lng: 21.6285632 },
    { lat: 48.8800472, lng: 21.6290427 },
    { lat: 48.8799249, lng: 21.6298301 },
    { lat: 48.8799734, lng: 21.6301973 },
    { lat: 48.8797703, lng: 21.6304679 },
    { lat: 48.8796776, lng: 21.6312584 },
    { lat: 48.8798372, lng: 21.6322006 },
    { lat: 48.8801315, lng: 21.6327943 },
    { lat: 48.8802497, lng: 21.6331217 },
    { lat: 48.8803397, lng: 21.6336079 },
    { lat: 48.8804872, lng: 21.6339803 },
    { lat: 48.8808115, lng: 21.6344049 },
    { lat: 48.8811722, lng: 21.6345071 },
    { lat: 48.8813535, lng: 21.6348403 },
    { lat: 48.8812812, lng: 21.6349882 },
    { lat: 48.8816461, lng: 21.6353715 },
    { lat: 48.8827781, lng: 21.6359927 },
    { lat: 48.8825425, lng: 21.6364713 },
    { lat: 48.8821583, lng: 21.6369378 },
    { lat: 48.8819456, lng: 21.6371392 },
    { lat: 48.8809137, lng: 21.6378272 },
    { lat: 48.8810738, lng: 21.6382125 },
    { lat: 48.8814712, lng: 21.638914 },
    { lat: 48.8821971, lng: 21.64044 },
    { lat: 48.8825879, lng: 21.6413879 },
    { lat: 48.8829772, lng: 21.6426202 },
    { lat: 48.8846772, lng: 21.642376 },
    { lat: 48.8851548, lng: 21.6421081 },
    { lat: 48.8855886, lng: 21.6419269 },
    { lat: 48.8856867, lng: 21.6419379 },
    { lat: 48.887971, lng: 21.6475731 },
    { lat: 48.889025, lng: 21.6467112 },
    { lat: 48.8895535, lng: 21.6463746 },
    { lat: 48.8900366, lng: 21.6462201 },
    { lat: 48.890286, lng: 21.6460478 },
    { lat: 48.8906348, lng: 21.6459225 },
    { lat: 48.8911229, lng: 21.6460397 },
    { lat: 48.8914159, lng: 21.6458571 },
    { lat: 48.8918185, lng: 21.645767 },
    { lat: 48.8925041, lng: 21.6454488 },
    { lat: 48.8932055, lng: 21.6457159 },
    { lat: 48.893623, lng: 21.6456385 },
    { lat: 48.8941556, lng: 21.6456578 },
    { lat: 48.8943262, lng: 21.6457575 },
    { lat: 48.8944222, lng: 21.6459402 },
    { lat: 48.8946125, lng: 21.6465589 },
    { lat: 48.8947011, lng: 21.6467568 },
    { lat: 48.8950327, lng: 21.6462968 },
    { lat: 48.8955375, lng: 21.6460293 },
    { lat: 48.8966867, lng: 21.6460094 },
    { lat: 48.8972108, lng: 21.6459237 },
    { lat: 48.8977486, lng: 21.6457171 },
    { lat: 48.8994275, lng: 21.6442516 },
    { lat: 48.8994227, lng: 21.6438285 },
    { lat: 48.8990866, lng: 21.6429383 },
    { lat: 48.8982864, lng: 21.6427224 },
    { lat: 48.8977339, lng: 21.6428463 },
    { lat: 48.8972221, lng: 21.6424767 },
    { lat: 48.8965922, lng: 21.6423588 },
    { lat: 48.8964273, lng: 21.6421311 },
    { lat: 48.896306, lng: 21.6418112 },
    { lat: 48.8969776, lng: 21.6409478 },
    { lat: 48.8972304, lng: 21.6412261 },
    { lat: 48.8978776, lng: 21.6414423 },
    { lat: 48.8982454, lng: 21.6413916 },
    { lat: 48.8986329, lng: 21.6411232 },
    { lat: 48.899162, lng: 21.6406102 },
    { lat: 48.8994214, lng: 21.6401758 },
    { lat: 48.8997431, lng: 21.6398083 },
    { lat: 48.8998166, lng: 21.6396527 },
    { lat: 48.8998346, lng: 21.6390809 },
    { lat: 48.9001473, lng: 21.6388787 },
    { lat: 48.9004042, lng: 21.6388946 },
    { lat: 48.9006686, lng: 21.6394207 },
    { lat: 48.9010948, lng: 21.6392912 },
    { lat: 48.9011343, lng: 21.6394993 },
    { lat: 48.9011615, lng: 21.6397536 },
    { lat: 48.901027, lng: 21.6403067 },
    { lat: 48.9005183, lng: 21.6408764 },
    { lat: 48.9007184, lng: 21.6414402 },
    { lat: 48.9008, lng: 21.6424438 },
    { lat: 48.9016246, lng: 21.6416264 },
    { lat: 48.9019583, lng: 21.6416472 },
    { lat: 48.903833, lng: 21.6422299 },
    { lat: 48.9045712, lng: 21.6421207 },
    { lat: 48.9051014, lng: 21.641878 },
    { lat: 48.9060423, lng: 21.6409076 },
    { lat: 48.9064586, lng: 21.6408713 },
    { lat: 48.906654, lng: 21.6415584 },
    { lat: 48.9068161, lng: 21.6419234 },
    { lat: 48.9070233, lng: 21.6420941 },
    { lat: 48.9073056, lng: 21.6422182 },
    { lat: 48.9080134, lng: 21.6422633 },
    { lat: 48.9083502, lng: 21.6435063 },
    { lat: 48.908987, lng: 21.6430959 },
    { lat: 48.9092037, lng: 21.6439473 },
    { lat: 48.909387, lng: 21.6437777 },
    { lat: 48.9093968, lng: 21.6438429 },
    { lat: 48.9097931, lng: 21.6434469 },
    { lat: 48.9092868, lng: 21.6419313 },
    { lat: 48.9093662, lng: 21.641868 },
    { lat: 48.9103904, lng: 21.6418033 },
    { lat: 48.9108825, lng: 21.6415751 },
    { lat: 48.9111696, lng: 21.6412969 },
    { lat: 48.9113061, lng: 21.6407747 },
    { lat: 48.9117669, lng: 21.64162 },
    { lat: 48.9120712, lng: 21.6412238 },
    { lat: 48.9126485, lng: 21.6402491 },
    { lat: 48.9115343, lng: 21.6375297 },
    { lat: 48.9113456, lng: 21.6373392 },
    { lat: 48.9120051, lng: 21.6366129 },
    { lat: 48.9123469, lng: 21.6357009 },
    { lat: 48.9129975, lng: 21.6332249 },
    { lat: 48.9132872, lng: 21.6327097 },
    { lat: 48.9158117, lng: 21.6310319 },
    { lat: 48.9156054, lng: 21.6305696 },
    { lat: 48.9151865, lng: 21.6290799 },
    { lat: 48.9164007, lng: 21.6272107 },
    { lat: 48.9156644, lng: 21.6245857 },
    { lat: 48.9161899, lng: 21.623203 },
    { lat: 48.9159763, lng: 21.6226807 },
    { lat: 48.9150388, lng: 21.6221394 },
    { lat: 48.9150154, lng: 21.6220704 },
    { lat: 48.914955, lng: 21.6222399 },
    { lat: 48.9146015, lng: 21.6217841 },
    { lat: 48.9143911, lng: 21.621379 },
    { lat: 48.913388, lng: 21.6189479 },
    { lat: 48.9124204, lng: 21.6181803 },
    { lat: 48.9123085, lng: 21.618122 },
    { lat: 48.9122806, lng: 21.6181735 },
    { lat: 48.9118751, lng: 21.6177047 },
    { lat: 48.9117096, lng: 21.6174133 },
    { lat: 48.9112756, lng: 21.6170561 },
    { lat: 48.9113257, lng: 21.6168758 },
    { lat: 48.911105, lng: 21.6164224 },
    { lat: 48.9109522, lng: 21.6165552 },
    { lat: 48.9106912, lng: 21.6155002 },
    { lat: 48.9105243, lng: 21.6153206 },
    { lat: 48.910463, lng: 21.6149711 },
    { lat: 48.9105945, lng: 21.6146465 },
    { lat: 48.9104176, lng: 21.6141077 },
    { lat: 48.910444, lng: 21.6138438 },
    { lat: 48.9103335, lng: 21.6130229 },
    { lat: 48.910039, lng: 21.612748 },
    { lat: 48.9098509, lng: 21.6115224 },
    { lat: 48.9096598, lng: 21.6112456 },
    { lat: 48.9094126, lng: 21.6100006 },
    { lat: 48.9093183, lng: 21.6098396 },
    { lat: 48.9089956, lng: 21.6077446 },
    { lat: 48.9088185, lng: 21.6074455 },
    { lat: 48.9088275, lng: 21.6070815 },
    { lat: 48.9087443, lng: 21.6067163 },
    { lat: 48.9088636, lng: 21.6063528 },
    { lat: 48.9088741, lng: 21.6058364 },
    { lat: 48.908691, lng: 21.6056027 },
    { lat: 48.9086264, lng: 21.6053489 },
    { lat: 48.9084866, lng: 21.6051272 },
    { lat: 48.9084614, lng: 21.6048309 },
    { lat: 48.9083617, lng: 21.6046466 },
    { lat: 48.9081115, lng: 21.6034562 },
    { lat: 48.9076893, lng: 21.6027214 },
    { lat: 48.9076041, lng: 21.6024014 },
    { lat: 48.9074967, lng: 21.6022194 },
    { lat: 48.9073926, lng: 21.6018015 },
    { lat: 48.9072322, lng: 21.6014498 },
    { lat: 48.9072196, lng: 21.6007936 },
    { lat: 48.9070878, lng: 21.6003446 },
    { lat: 48.9068502, lng: 21.6000912 },
    { lat: 48.9069119, lng: 21.5998849 },
    { lat: 48.9067887, lng: 21.5996266 },
    { lat: 48.9066049, lng: 21.5988416 },
    { lat: 48.9061484, lng: 21.5982474 },
    { lat: 48.9061006, lng: 21.5978606 },
    { lat: 48.9058808, lng: 21.5974842 },
    { lat: 48.9056597, lng: 21.5966911 },
    { lat: 48.9056828, lng: 21.5961063 },
    { lat: 48.9053371, lng: 21.5946461 },
    { lat: 48.9053202, lng: 21.5943217 },
    { lat: 48.9052603, lng: 21.5941499 },
    { lat: 48.9053377, lng: 21.593983 },
    { lat: 48.9051793, lng: 21.593265 },
    { lat: 48.9051959, lng: 21.5929316 },
    { lat: 48.9050117, lng: 21.5927775 },
    { lat: 48.9049411, lng: 21.5926293 },
    { lat: 48.9049328, lng: 21.5921696 },
    { lat: 48.9048582, lng: 21.5919548 },
    { lat: 48.904841, lng: 21.5916615 },
    { lat: 48.9049495, lng: 21.5911989 },
    { lat: 48.9050832, lng: 21.5911049 },
    { lat: 48.9051363, lng: 21.5909119 },
    { lat: 48.9051309, lng: 21.5902796 },
    { lat: 48.9050669, lng: 21.5898359 },
    { lat: 48.9047408, lng: 21.5884739 },
    { lat: 48.9039593, lng: 21.5888474 },
    { lat: 48.9025154, lng: 21.5889995 },
    { lat: 48.9023534, lng: 21.5880357 },
    { lat: 48.9016875, lng: 21.5878786 },
    { lat: 48.9015988, lng: 21.587158 },
    { lat: 48.9012331, lng: 21.587522 },
    { lat: 48.9011678, lng: 21.5875211 },
    { lat: 48.9009824, lng: 21.5864817 },
    { lat: 48.9007803, lng: 21.5864939 },
    { lat: 48.9007187, lng: 21.5865018 },
  ],
  Pavlovce: [
    { lat: 48.9979285, lng: 21.4518848 },
    { lat: 48.9980824, lng: 21.4515971 },
    { lat: 48.998822, lng: 21.451029 },
    { lat: 48.9989849, lng: 21.4505157 },
    { lat: 48.9990125, lng: 21.4494542 },
    { lat: 48.9996657, lng: 21.4490115 },
    { lat: 49.0005098, lng: 21.4475104 },
    { lat: 49.0009119, lng: 21.4471024 },
    { lat: 49.0012567, lng: 21.4464572 },
    { lat: 49.001747, lng: 21.4458706 },
    { lat: 49.0026298, lng: 21.4455884 },
    { lat: 49.0030144, lng: 21.4455249 },
    { lat: 49.0036492, lng: 21.4460526 },
    { lat: 49.0039112, lng: 21.4461616 },
    { lat: 49.004368, lng: 21.4460844 },
    { lat: 49.0048204, lng: 21.4461231 },
    { lat: 49.005502, lng: 21.4468113 },
    { lat: 49.0055451, lng: 21.4477097 },
    { lat: 49.0067688, lng: 21.4494395 },
    { lat: 49.0073003, lng: 21.4499948 },
    { lat: 49.007803, lng: 21.4501069 },
    { lat: 49.0078997, lng: 21.4508814 },
    { lat: 49.0078152, lng: 21.4516877 },
    { lat: 49.0075288, lng: 21.4528671 },
    { lat: 49.0074314, lng: 21.4536975 },
    { lat: 49.0075601, lng: 21.453834 },
    { lat: 49.0078827, lng: 21.4538931 },
    { lat: 49.008224, lng: 21.4543426 },
    { lat: 49.0083099, lng: 21.4543863 },
    { lat: 49.0106587, lng: 21.4566552 },
    { lat: 49.0111655, lng: 21.4572625 },
    { lat: 49.0121588, lng: 21.4572132 },
    { lat: 49.0126239, lng: 21.4580092 },
    { lat: 49.0135623, lng: 21.4590174 },
    { lat: 49.0140302, lng: 21.4592413 },
    { lat: 49.0142711, lng: 21.4594442 },
    { lat: 49.0148445, lng: 21.4596216 },
    { lat: 49.0165399, lng: 21.4596543 },
    { lat: 49.0169048, lng: 21.4595148 },
    { lat: 49.0173059, lng: 21.4594517 },
    { lat: 49.0176617, lng: 21.4590354 },
    { lat: 49.017808, lng: 21.459155 },
    { lat: 49.0183092, lng: 21.4590566 },
    { lat: 49.0185584, lng: 21.4594217 },
    { lat: 49.0187773, lng: 21.4592464 },
    { lat: 49.0192524, lng: 21.4595297 },
    { lat: 49.0192572, lng: 21.4598111 },
    { lat: 49.0197886, lng: 21.4602161 },
    { lat: 49.0202913, lng: 21.4608614 },
    { lat: 49.0208495, lng: 21.4609875 },
    { lat: 49.0216435, lng: 21.4615134 },
    { lat: 49.0227546, lng: 21.4616172 },
    { lat: 49.0232724, lng: 21.4618966 },
    { lat: 49.0242049, lng: 21.4620432 },
    { lat: 49.0246965, lng: 21.4633626 },
    { lat: 49.0253287, lng: 21.4636685 },
    { lat: 49.0259988, lng: 21.4633422 },
    { lat: 49.0264237, lng: 21.463639 },
    { lat: 49.0269371, lng: 21.4630647 },
    { lat: 49.0273978, lng: 21.4628094 },
    { lat: 49.027652, lng: 21.4625762 },
    { lat: 49.0277254, lng: 21.4625878 },
    { lat: 49.0279559, lng: 21.462429 },
    { lat: 49.0281355, lng: 21.4624499 },
    { lat: 49.0285296, lng: 21.4623009 },
    { lat: 49.0287629, lng: 21.4621244 },
    { lat: 49.0289432, lng: 21.4620911 },
    { lat: 49.0291677, lng: 21.4621901 },
    { lat: 49.0294295, lng: 21.4631127 },
    { lat: 49.0296972, lng: 21.4638123 },
    { lat: 49.0298261, lng: 21.4639474 },
    { lat: 49.0298597, lng: 21.4641402 },
    { lat: 49.0299143, lng: 21.4642004 },
    { lat: 49.0300047, lng: 21.464171 },
    { lat: 49.0303834, lng: 21.4646226 },
    { lat: 49.0305208, lng: 21.4646277 },
    { lat: 49.0306188, lng: 21.4647488 },
    { lat: 49.0307115, lng: 21.4650327 },
    { lat: 49.0310019, lng: 21.4655052 },
    { lat: 49.031227, lng: 21.465748 },
    { lat: 49.031217, lng: 21.4658247 },
    { lat: 49.0313187, lng: 21.4658778 },
    { lat: 49.0314597, lng: 21.4661638 },
    { lat: 49.0315233, lng: 21.4661839 },
    { lat: 49.0318647, lng: 21.4666262 },
    { lat: 49.0320648, lng: 21.4671291 },
    { lat: 49.0320106, lng: 21.4673981 },
    { lat: 49.0320267, lng: 21.4674831 },
    { lat: 49.0322158, lng: 21.4673281 },
    { lat: 49.0320558, lng: 21.4668196 },
    { lat: 49.0324213, lng: 21.4665029 },
    { lat: 49.0326648, lng: 21.4660926 },
    { lat: 49.0326804, lng: 21.4659312 },
    { lat: 49.0323888, lng: 21.465614 },
    { lat: 49.0330337, lng: 21.4626249 },
    { lat: 49.0332739, lng: 21.4617421 },
    { lat: 49.033348, lng: 21.4617383 },
    { lat: 49.0335063, lng: 21.4619184 },
    { lat: 49.0336559, lng: 21.4619633 },
    { lat: 49.0339482, lng: 21.4617598 },
    { lat: 49.0343273, lng: 21.4608218 },
    { lat: 49.034548, lng: 21.4607163 },
    { lat: 49.0350994, lng: 21.4609917 },
    { lat: 49.0357639, lng: 21.4611244 },
    { lat: 49.0358674, lng: 21.4613186 },
    { lat: 49.0360144, lng: 21.4614217 },
    { lat: 49.036495, lng: 21.4597239 },
    { lat: 49.0364628, lng: 21.4596945 },
    { lat: 49.0367542, lng: 21.458994 },
    { lat: 49.0368472, lng: 21.4592567 },
    { lat: 49.037068, lng: 21.4594571 },
    { lat: 49.0370588, lng: 21.4589854 },
    { lat: 49.0374255, lng: 21.4583186 },
    { lat: 49.0379332, lng: 21.4580214 },
    { lat: 49.0382659, lng: 21.4579309 },
    { lat: 49.0382835, lng: 21.4578813 },
    { lat: 49.0383899, lng: 21.457588 },
    { lat: 49.038384, lng: 21.4574995 },
    { lat: 49.0388982, lng: 21.4571182 },
    { lat: 49.0390875, lng: 21.4563829 },
    { lat: 49.0390741, lng: 21.4561558 },
    { lat: 49.0391623, lng: 21.4557009 },
    { lat: 49.0392379, lng: 21.455519 },
    { lat: 49.0393356, lng: 21.4548899 },
    { lat: 49.0396288, lng: 21.4537893 },
    { lat: 49.0396347, lng: 21.4534454 },
    { lat: 49.0398888, lng: 21.4523969 },
    { lat: 49.0397753, lng: 21.4523213 },
    { lat: 49.0396324, lng: 21.451968 },
    { lat: 49.0396464, lng: 21.4517616 },
    { lat: 49.0395961, lng: 21.4516541 },
    { lat: 49.039581, lng: 21.4512456 },
    { lat: 49.0396501, lng: 21.4512639 },
    { lat: 49.0396802, lng: 21.4509833 },
    { lat: 49.0399906, lng: 21.4504367 },
    { lat: 49.0403516, lng: 21.449617 },
    { lat: 49.0404696, lng: 21.4488749 },
    { lat: 49.0406905, lng: 21.4482727 },
    { lat: 49.0406001, lng: 21.4482526 },
    { lat: 49.0405782, lng: 21.4480037 },
    { lat: 49.0407441, lng: 21.447285 },
    { lat: 49.0409964, lng: 21.4474473 },
    { lat: 49.0413179, lng: 21.4451154 },
    { lat: 49.0417861, lng: 21.4444667 },
    { lat: 49.0421124, lng: 21.4448547 },
    { lat: 49.0422704, lng: 21.444328 },
    { lat: 49.0424744, lng: 21.4441055 },
    { lat: 49.0427408, lng: 21.4434804 },
    { lat: 49.0428334, lng: 21.4427981 },
    { lat: 49.0429666, lng: 21.4424649 },
    { lat: 49.0432104, lng: 21.442059 },
    { lat: 49.0432509, lng: 21.4417905 },
    { lat: 49.0433275, lng: 21.4417574 },
    { lat: 49.043405, lng: 21.4415592 },
    { lat: 49.043562, lng: 21.4414626 },
    { lat: 49.0437472, lng: 21.4411 },
    { lat: 49.0444255, lng: 21.4407047 },
    { lat: 49.0446008, lng: 21.4404335 },
    { lat: 49.0450071, lng: 21.440158 },
    { lat: 49.0451819, lng: 21.4399425 },
    { lat: 49.0455692, lng: 21.4399888 },
    { lat: 49.045717, lng: 21.439928 },
    { lat: 49.045927, lng: 21.439784 },
    { lat: 49.045958, lng: 21.439526 },
    { lat: 49.046067, lng: 21.439214 },
    { lat: 49.04613, lng: 21.439116 },
    { lat: 49.046124, lng: 21.439102 },
    { lat: 49.046052, lng: 21.438781 },
    { lat: 49.046081, lng: 21.438039 },
    { lat: 49.046065, lng: 21.438018 },
    { lat: 49.046186, lng: 21.437591 },
    { lat: 49.046135, lng: 21.436427 },
    { lat: 49.046195, lng: 21.436013 },
    { lat: 49.046328, lng: 21.435906 },
    { lat: 49.046445, lng: 21.435738 },
    { lat: 49.046493, lng: 21.435376 },
    { lat: 49.046446, lng: 21.43502 },
    { lat: 49.046414, lng: 21.434657 },
    { lat: 49.04663, lng: 21.434 },
    { lat: 49.04663, lng: 21.433352 },
    { lat: 49.0464, lng: 21.432545 },
    { lat: 49.046131, lng: 21.431844 },
    { lat: 49.045723, lng: 21.431138 },
    { lat: 49.045241, lng: 21.43065 },
    { lat: 49.044704, lng: 21.43057 },
    { lat: 49.044139, lng: 21.430663 },
    { lat: 49.043959, lng: 21.430327 },
    { lat: 49.043779, lng: 21.430058 },
    { lat: 49.043527, lng: 21.429491 },
    { lat: 49.043199, lng: 21.428883 },
    { lat: 49.042666, lng: 21.428278 },
    { lat: 49.042646, lng: 21.428257 },
    { lat: 49.042079, lng: 21.427659 },
    { lat: 49.041959, lng: 21.427007 },
    { lat: 49.041837, lng: 21.426694 },
    { lat: 49.041886, lng: 21.426413 },
    { lat: 49.041874, lng: 21.426394 },
    { lat: 49.041566, lng: 21.426493 },
    { lat: 49.041335, lng: 21.426434 },
    { lat: 49.040981, lng: 21.426423 },
    { lat: 49.04069, lng: 21.426369 },
    { lat: 49.040478, lng: 21.426252 },
    { lat: 49.040292, lng: 21.426112 },
    { lat: 49.039943, lng: 21.425857 },
    { lat: 49.039688, lng: 21.425731 },
    { lat: 49.039482, lng: 21.425602 },
    { lat: 49.039098, lng: 21.425365 },
    { lat: 49.038589, lng: 21.425165 },
    { lat: 49.038428, lng: 21.425058 },
    { lat: 49.038118, lng: 21.424669 },
    { lat: 49.038039, lng: 21.424516 },
    { lat: 49.038012, lng: 21.42443 },
    { lat: 49.037969, lng: 21.424127 },
    { lat: 49.037953, lng: 21.424137 },
    { lat: 49.037738, lng: 21.423541 },
    { lat: 49.037593, lng: 21.423026 },
    { lat: 49.037554, lng: 21.422622 },
    { lat: 49.037452, lng: 21.422287 },
    { lat: 49.037326, lng: 21.421958 },
    { lat: 49.037229, lng: 21.421556 },
    { lat: 49.037231, lng: 21.421324 },
    { lat: 49.037211, lng: 21.421168 },
    { lat: 49.037074, lng: 21.421142 },
    { lat: 49.036971, lng: 21.421336 },
    { lat: 49.036923, lng: 21.421278 },
    { lat: 49.03693, lng: 21.421173 },
    { lat: 49.03683, lng: 21.42129 },
    { lat: 49.036567, lng: 21.421393 },
    { lat: 49.036335, lng: 21.421485 },
    { lat: 49.036262, lng: 21.42144 },
    { lat: 49.035707, lng: 21.421352 },
    { lat: 49.035372, lng: 21.421299 },
    { lat: 49.035382, lng: 21.421233 },
    { lat: 49.035396, lng: 21.421153 },
    { lat: 49.035438, lng: 21.420887 },
    { lat: 49.035469, lng: 21.4207 },
    { lat: 49.035659, lng: 21.420489 },
    { lat: 49.035656, lng: 21.420114 },
    { lat: 49.0358, lng: 21.419969 },
    { lat: 49.035772, lng: 21.419862 },
    { lat: 49.03581, lng: 21.419822 },
    { lat: 49.036167, lng: 21.419439 },
    { lat: 49.036287, lng: 21.419375 },
    { lat: 49.036564, lng: 21.419229 },
    { lat: 49.036609, lng: 21.418938 },
    { lat: 49.036587, lng: 21.417278 },
    { lat: 49.03659, lng: 21.417102 },
    { lat: 49.036589, lng: 21.417034 },
    { lat: 49.036511, lng: 21.417063 },
    { lat: 49.036319, lng: 21.417183 },
    { lat: 49.036122, lng: 21.417189 },
    { lat: 49.036009, lng: 21.417266 },
    { lat: 49.035985, lng: 21.417258 },
    { lat: 49.035889, lng: 21.417128 },
    { lat: 49.035839, lng: 21.417157 },
    { lat: 49.03577, lng: 21.417324 },
    { lat: 49.035747, lng: 21.417334 },
    { lat: 49.035651, lng: 21.417264 },
    { lat: 49.035469, lng: 21.417292 },
    { lat: 49.035246, lng: 21.417118 },
    { lat: 49.03521, lng: 21.417004 },
    { lat: 49.035153, lng: 21.41699 },
    { lat: 49.035022, lng: 21.417141 },
    { lat: 49.034877, lng: 21.417086 },
    { lat: 49.03479, lng: 21.417363 },
    { lat: 49.034761, lng: 21.417391 },
    { lat: 49.034461, lng: 21.417325 },
    { lat: 49.034311, lng: 21.417188 },
    { lat: 49.033933, lng: 21.417059 },
    { lat: 49.033799, lng: 21.417053 },
    { lat: 49.033781, lng: 21.417032 },
    { lat: 49.033712, lng: 21.416631 },
    { lat: 49.033559, lng: 21.416747 },
    { lat: 49.033537, lng: 21.416742 },
    { lat: 49.033384, lng: 21.416442 },
    { lat: 49.033345, lng: 21.41629 },
    { lat: 49.033199, lng: 21.416077 },
    { lat: 49.033169, lng: 21.415972 },
    { lat: 49.033069, lng: 21.415934 },
    { lat: 49.03291, lng: 21.416012 },
    { lat: 49.032796, lng: 21.415839 },
    { lat: 49.032335, lng: 21.415897 },
    { lat: 49.032256, lng: 21.41578 },
    { lat: 49.032201, lng: 21.415794 },
    { lat: 49.031919, lng: 21.416077 },
    { lat: 49.03174, lng: 21.415884 },
    { lat: 49.031577, lng: 21.415877 },
    { lat: 49.031465, lng: 21.41577 },
    { lat: 49.031411, lng: 21.41561 },
    { lat: 49.031221, lng: 21.415416 },
    { lat: 49.030914, lng: 21.415371 },
    { lat: 49.030701, lng: 21.415402 },
    { lat: 49.030383, lng: 21.415317 },
    { lat: 49.030238, lng: 21.415244 },
    { lat: 49.030148, lng: 21.415137 },
    { lat: 49.030029, lng: 21.414933 },
    { lat: 49.029985, lng: 21.414938 },
    { lat: 49.029853, lng: 21.414742 },
    { lat: 49.02978, lng: 21.414602 },
    { lat: 49.029728, lng: 21.414611 },
    { lat: 49.029716, lng: 21.414643 },
    { lat: 49.029609, lng: 21.414661 },
    { lat: 49.029506, lng: 21.414716 },
    { lat: 49.029483, lng: 21.414694 },
    { lat: 49.029476, lng: 21.414594 },
    { lat: 49.029446, lng: 21.414559 },
    { lat: 49.029333, lng: 21.414532 },
    { lat: 49.029139, lng: 21.414652 },
    { lat: 49.029152, lng: 21.414777 },
    { lat: 49.029148, lng: 21.414801 },
    { lat: 49.029136, lng: 21.414809 },
    { lat: 49.029061, lng: 21.414784 },
    { lat: 49.028984, lng: 21.414778 },
    { lat: 49.02896, lng: 21.414523 },
    { lat: 49.028913, lng: 21.414499 },
    { lat: 49.028893, lng: 21.414421 },
    { lat: 49.028753, lng: 21.414518 },
    { lat: 49.028696, lng: 21.414462 },
    { lat: 49.028652, lng: 21.414444 },
    { lat: 49.028645, lng: 21.414279 },
    { lat: 49.028597, lng: 21.414255 },
    { lat: 49.028543, lng: 21.414314 },
    { lat: 49.028363, lng: 21.414214 },
    { lat: 49.028263, lng: 21.414128 },
    { lat: 49.028121, lng: 21.414126 },
    { lat: 49.02796, lng: 21.413981 },
    { lat: 49.027876, lng: 21.413923 },
    { lat: 49.027849, lng: 21.413962 },
    { lat: 49.027829, lng: 21.41395 },
    { lat: 49.027843, lng: 21.413794 },
    { lat: 49.027829, lng: 21.413776 },
    { lat: 49.027799, lng: 21.413772 },
    { lat: 49.027787, lng: 21.413726 },
    { lat: 49.027709, lng: 21.413727 },
    { lat: 49.027611, lng: 21.413749 },
    { lat: 49.027521, lng: 21.413693 },
    { lat: 49.027401, lng: 21.413769 },
    { lat: 49.027322, lng: 21.413726 },
    { lat: 49.027309, lng: 21.413748 },
    { lat: 49.027341, lng: 21.41384 },
    { lat: 49.027333, lng: 21.413867 },
    { lat: 49.027136, lng: 21.413854 },
    { lat: 49.027104, lng: 21.413893 },
    { lat: 49.027021, lng: 21.413834 },
    { lat: 49.026954, lng: 21.413732 },
    { lat: 49.026967, lng: 21.413628 },
    { lat: 49.026907, lng: 21.413535 },
    { lat: 49.026598, lng: 21.413445 },
    { lat: 49.026572, lng: 21.413412 },
    { lat: 49.026433, lng: 21.413421 },
    { lat: 49.026333, lng: 21.413496 },
    { lat: 49.026268, lng: 21.413415 },
    { lat: 49.026122, lng: 21.413445 },
    { lat: 49.025922, lng: 21.413372 },
    { lat: 49.025874, lng: 21.413424 },
    { lat: 49.025695, lng: 21.413494 },
    { lat: 49.025601, lng: 21.413457 },
    { lat: 49.025378, lng: 21.413244 },
    { lat: 49.025309, lng: 21.413252 },
    { lat: 49.025306, lng: 21.413226 },
    { lat: 49.025177, lng: 21.413094 },
    { lat: 49.025069, lng: 21.413089 },
    { lat: 49.025066, lng: 21.413066 },
    { lat: 49.024966, lng: 21.413168 },
    { lat: 49.024748, lng: 21.413175 },
    { lat: 49.024719, lng: 21.413047 },
    { lat: 49.024535, lng: 21.412989 },
    { lat: 49.024435, lng: 21.412883 },
    { lat: 49.024002, lng: 21.412931 },
    { lat: 49.023891, lng: 21.412882 },
    { lat: 49.023844, lng: 21.412828 },
    { lat: 49.023853, lng: 21.412737 },
    { lat: 49.024031, lng: 21.411376 },
    { lat: 49.023649, lng: 21.411598 },
    { lat: 49.023127, lng: 21.411732 },
    { lat: 49.022516, lng: 21.411747 },
    { lat: 49.02245, lng: 21.411888 },
    { lat: 49.022367, lng: 21.411921 },
    { lat: 49.022278, lng: 21.412096 },
    { lat: 49.022194, lng: 21.412178 },
    { lat: 49.022135, lng: 21.412082 },
    { lat: 49.022114, lng: 21.412015 },
    { lat: 49.022039, lng: 21.411962 },
    { lat: 49.02201, lng: 21.411885 },
    { lat: 49.021874, lng: 21.411832 },
    { lat: 49.021847, lng: 21.411759 },
    { lat: 49.021755, lng: 21.411747 },
    { lat: 49.021715, lng: 21.411583 },
    { lat: 49.021666, lng: 21.411489 },
    { lat: 49.021548, lng: 21.4115 },
    { lat: 49.021279, lng: 21.411283 },
    { lat: 49.020966, lng: 21.410718 },
    { lat: 49.02056, lng: 21.410203 },
    { lat: 49.020484, lng: 21.410052 },
    { lat: 49.02, lng: 21.409614 },
    { lat: 49.019596, lng: 21.409107 },
    { lat: 49.019557, lng: 21.408315 },
    { lat: 49.020234, lng: 21.408424 },
    { lat: 49.020637, lng: 21.408587 },
    { lat: 49.020773, lng: 21.408003 },
    { lat: 49.020438, lng: 21.407765 },
    { lat: 49.020362, lng: 21.407453 },
    { lat: 49.020318, lng: 21.406832 },
    { lat: 49.020437, lng: 21.406295 },
    { lat: 49.020288, lng: 21.40612 },
    { lat: 49.020141, lng: 21.40584 },
    { lat: 49.019775, lng: 21.405429 },
    { lat: 49.019731, lng: 21.405247 },
    { lat: 49.019628, lng: 21.404774 },
    { lat: 49.019528, lng: 21.40457 },
    { lat: 49.01947, lng: 21.404373 },
    { lat: 49.01945, lng: 21.404385 },
    { lat: 49.019453, lng: 21.40489 },
    { lat: 49.019194, lng: 21.405479 },
    { lat: 49.018974, lng: 21.405572 },
    { lat: 49.018908, lng: 21.405813 },
    { lat: 49.018778, lng: 21.406195 },
    { lat: 49.018475, lng: 21.406798 },
    { lat: 49.018189, lng: 21.407253 },
    { lat: 49.017883, lng: 21.407686 },
    { lat: 49.017483, lng: 21.408411 },
    { lat: 49.017303, lng: 21.408747 },
    { lat: 49.017201, lng: 21.408937 },
    { lat: 49.016905, lng: 21.409456 },
    { lat: 49.016835, lng: 21.409622 },
    { lat: 49.016484, lng: 21.410461 },
    { lat: 49.016139, lng: 21.411442 },
    { lat: 49.015652, lng: 21.412484 },
    { lat: 49.015468, lng: 21.41285 },
    { lat: 49.01527, lng: 21.413085 },
    { lat: 49.014761, lng: 21.41339 },
    { lat: 49.014712, lng: 21.413486 },
    { lat: 49.014376, lng: 21.41384 },
    { lat: 49.013905, lng: 21.414292 },
    { lat: 49.013843, lng: 21.414354 },
    { lat: 49.013613, lng: 21.414766 },
    { lat: 49.013439, lng: 21.415086 },
    { lat: 49.013142, lng: 21.41568 },
    { lat: 49.012624, lng: 21.416265 },
    { lat: 49.012415, lng: 21.416506 },
    { lat: 49.012285, lng: 21.416851 },
    { lat: 49.012164, lng: 21.41697 },
    { lat: 49.011959, lng: 21.417288 },
    { lat: 49.01185, lng: 21.417493 },
    { lat: 49.011122, lng: 21.418342 },
    { lat: 49.010886, lng: 21.418717 },
    { lat: 49.010554, lng: 21.419089 },
    { lat: 49.009994, lng: 21.419989 },
    { lat: 49.009353, lng: 21.42054 },
    { lat: 49.008864, lng: 21.421019 },
    { lat: 49.008481, lng: 21.421456 },
    { lat: 49.008165, lng: 21.421936 },
    { lat: 49.007814, lng: 21.422582 },
    { lat: 49.00754, lng: 21.422839 },
    { lat: 49.007177, lng: 21.423168 },
    { lat: 49.007084, lng: 21.423184 },
    { lat: 49.007068, lng: 21.423253 },
    { lat: 49.006985, lng: 21.423287 },
    { lat: 49.00673, lng: 21.423469 },
    { lat: 49.006008, lng: 21.423939 },
    { lat: 49.005126, lng: 21.424499 },
    { lat: 49.004382, lng: 21.42476 },
    { lat: 49.00399, lng: 21.424812 },
    { lat: 49.003558, lng: 21.424681 },
    { lat: 49.003031, lng: 21.424473 },
    { lat: 49.002826, lng: 21.424841 },
    { lat: 49.002633, lng: 21.424894 },
    { lat: 49.002623, lng: 21.424897 },
    { lat: 49.002565, lng: 21.42448 },
    { lat: 49.002426, lng: 21.423838 },
    { lat: 49.00247, lng: 21.423721 },
    { lat: 49.002421, lng: 21.423251 },
    { lat: 49.002242, lng: 21.422859 },
    { lat: 49.001757, lng: 21.422432 },
    { lat: 49.001678, lng: 21.422349 },
    { lat: 49.001629, lng: 21.422168 },
    { lat: 49.001504, lng: 21.421745 },
    { lat: 49.001293, lng: 21.421493 },
    { lat: 49.001077, lng: 21.421214 },
    { lat: 49.000982, lng: 21.42087 },
    { lat: 49.001018, lng: 21.420544 },
    { lat: 49.001011, lng: 21.420495 },
    { lat: 49.001049, lng: 21.420393 },
    { lat: 49.000984, lng: 21.420213 },
    { lat: 49.000742, lng: 21.419833 },
    { lat: 49.000572, lng: 21.419517 },
    { lat: 49.000405, lng: 21.419486 },
    { lat: 49.000388, lng: 21.419411 },
    { lat: 49.000196, lng: 21.419231 },
    { lat: 49.000157, lng: 21.419124 },
    { lat: 48.999853, lng: 21.41871 },
    { lat: 48.999783, lng: 21.41834 },
    { lat: 48.999831, lng: 21.418233 },
    { lat: 48.999746, lng: 21.417999 },
    { lat: 48.999622, lng: 21.417389 },
    { lat: 48.999589, lng: 21.416895 },
    { lat: 48.999637, lng: 21.416208 },
    { lat: 48.999598, lng: 21.415718 },
    { lat: 48.999338, lng: 21.414958 },
    { lat: 48.999192, lng: 21.414474 },
    { lat: 48.999114, lng: 21.414243 },
    { lat: 48.998693, lng: 21.413627 },
    { lat: 48.998411, lng: 21.413241 },
    { lat: 48.998333, lng: 21.41313 },
    { lat: 48.998123, lng: 21.412453 },
    { lat: 48.998167, lng: 21.411449 },
    { lat: 48.998119, lng: 21.411129 },
    { lat: 48.997897, lng: 21.410595 },
    { lat: 48.997638, lng: 21.41024 },
    { lat: 48.997504, lng: 21.409822 },
    { lat: 48.997393, lng: 21.409686 },
    { lat: 48.997358, lng: 21.409737 },
    { lat: 48.996978, lng: 21.409487 },
    { lat: 48.996915, lng: 21.40946 },
    { lat: 48.996573, lng: 21.409088 },
    { lat: 48.995898, lng: 21.407671 },
    { lat: 48.995361, lng: 21.407172 },
    { lat: 48.994927, lng: 21.40721 },
    { lat: 48.994661, lng: 21.407173 },
    { lat: 48.994266, lng: 21.408581 },
    { lat: 48.994029, lng: 21.408332 },
    { lat: 48.993392, lng: 21.408031 },
    { lat: 48.993158, lng: 21.407925 },
    { lat: 48.992307, lng: 21.407683 },
    { lat: 48.991965, lng: 21.407798 },
    { lat: 48.991201, lng: 21.408198 },
    { lat: 48.990809, lng: 21.408443 },
    { lat: 48.990449, lng: 21.408961 },
    { lat: 48.990217, lng: 21.409519 },
    { lat: 48.989925, lng: 21.40978 },
    { lat: 48.98982, lng: 21.41015 },
    { lat: 48.989866, lng: 21.410604 },
    { lat: 48.989773, lng: 21.411094 },
    { lat: 48.989674, lng: 21.412202 },
    { lat: 48.989588, lng: 21.412862 },
    { lat: 48.98956, lng: 21.413459 },
    { lat: 48.989546, lng: 21.414036 },
    { lat: 48.989482, lng: 21.414516 },
    { lat: 48.989335, lng: 21.415522 },
    { lat: 48.989211, lng: 21.416044 },
    { lat: 48.98919, lng: 21.416131 },
    { lat: 48.98878, lng: 21.417858 },
    { lat: 48.988361, lng: 21.4188 },
    { lat: 48.988285, lng: 21.418969 },
    { lat: 48.988088, lng: 21.419201 },
    { lat: 48.987557, lng: 21.419384 },
    { lat: 48.987239, lng: 21.419903 },
    { lat: 48.987143, lng: 21.419975 },
    { lat: 48.986506, lng: 21.420701 },
    { lat: 48.986083, lng: 21.421588 },
    { lat: 48.985856, lng: 21.421997 },
    { lat: 48.985612, lng: 21.422292 },
    { lat: 48.98541, lng: 21.422563 },
    { lat: 48.985089, lng: 21.422962 },
    { lat: 48.984519, lng: 21.422994 },
    { lat: 48.984254, lng: 21.423066 },
    { lat: 48.983827, lng: 21.423094 },
    { lat: 48.98361, lng: 21.423311 },
    { lat: 48.983328, lng: 21.423652 },
    { lat: 48.982713, lng: 21.42424 },
    { lat: 48.9840639, lng: 21.4266624 },
    { lat: 48.9849876, lng: 21.4280078 },
    { lat: 48.9853756, lng: 21.4286881 },
    { lat: 48.9856636, lng: 21.4298046 },
    { lat: 48.9861352, lng: 21.430499 },
    { lat: 48.9865851, lng: 21.4313101 },
    { lat: 48.9869562, lng: 21.4318006 },
    { lat: 48.9897935, lng: 21.436644 },
    { lat: 48.990465, lng: 21.4378326 },
    { lat: 48.990833, lng: 21.4387634 },
    { lat: 48.9913418, lng: 21.4397334 },
    { lat: 48.9917716, lng: 21.4402723 },
    { lat: 48.9929567, lng: 21.4422914 },
    { lat: 48.9931603, lng: 21.4428302 },
    { lat: 48.9936379, lng: 21.4436605 },
    { lat: 48.9944867, lng: 21.4449496 },
    { lat: 48.995405, lng: 21.4466192 },
    { lat: 48.9962003, lng: 21.4481997 },
    { lat: 48.9967453, lng: 21.4487977 },
    { lat: 48.9970064, lng: 21.4492639 },
    { lat: 48.9975259, lng: 21.4509934 },
    { lat: 48.9979285, lng: 21.4518848 },
  ],
  MaláDomaša: [
    { lat: 48.9585408, lng: 21.7260817 },
    { lat: 48.95906, lng: 21.7254585 },
    { lat: 48.9592973, lng: 21.7251738 },
    { lat: 48.9595104, lng: 21.7248109 },
    { lat: 48.9596575, lng: 21.7259754 },
    { lat: 48.9597825, lng: 21.7277999 },
    { lat: 48.9606702, lng: 21.7274242 },
    { lat: 48.9605658, lng: 21.7259162 },
    { lat: 48.9604547, lng: 21.7258414 },
    { lat: 48.9602416, lng: 21.7258234 },
    { lat: 48.960099, lng: 21.7251541 },
    { lat: 48.9610877, lng: 21.7246407 },
    { lat: 48.9611459, lng: 21.724931 },
    { lat: 48.961477, lng: 21.7258279 },
    { lat: 48.9617724, lng: 21.7256783 },
    { lat: 48.9616774, lng: 21.7251142 },
    { lat: 48.9618379, lng: 21.7250144 },
    { lat: 48.9620014, lng: 21.7256663 },
    { lat: 48.9623454, lng: 21.7254912 },
    { lat: 48.9621181, lng: 21.7243857 },
    { lat: 48.9643083, lng: 21.724506 },
    { lat: 48.9644147, lng: 21.7242373 },
    { lat: 48.9648745, lng: 21.7235499 },
    { lat: 48.9654984, lng: 21.7234179 },
    { lat: 48.9657301, lng: 21.7233689 },
    { lat: 48.9660855, lng: 21.7233646 },
    { lat: 48.9658881, lng: 21.7230011 },
    { lat: 48.96565, lng: 21.7229396 },
    { lat: 48.9653201, lng: 21.7230508 },
    { lat: 48.964834, lng: 21.7233429 },
    { lat: 48.9648378, lng: 21.7226445 },
    { lat: 48.9649354, lng: 21.7219152 },
    { lat: 48.9652449, lng: 21.7218417 },
    { lat: 48.9654193, lng: 21.7226774 },
    { lat: 48.9658888, lng: 21.7224812 },
    { lat: 48.9657824, lng: 21.7216206 },
    { lat: 48.9663833, lng: 21.7213748 },
    { lat: 48.9664673, lng: 21.7217742 },
    { lat: 48.9668825, lng: 21.7215305 },
    { lat: 48.9668494, lng: 21.7213417 },
    { lat: 48.9674145, lng: 21.7211028 },
    { lat: 48.9675499, lng: 21.7217049 },
    { lat: 48.9677384, lng: 21.7216485 },
    { lat: 48.9687036, lng: 21.7209284 },
    { lat: 48.9687731, lng: 21.7215557 },
    { lat: 48.9686624, lng: 21.7215744 },
    { lat: 48.9689488, lng: 21.7226159 },
    { lat: 48.9690437, lng: 21.7228417 },
    { lat: 48.9692608, lng: 21.7230439 },
    { lat: 48.9693792, lng: 21.7234351 },
    { lat: 48.9693715, lng: 21.7238244 },
    { lat: 48.969502, lng: 21.7243696 },
    { lat: 48.969636, lng: 21.7245411 },
    { lat: 48.9698918, lng: 21.7246804 },
    { lat: 48.9700546, lng: 21.7248823 },
    { lat: 48.9703325, lng: 21.7254374 },
    { lat: 48.97041, lng: 21.7255154 },
    { lat: 48.9706052, lng: 21.7250733 },
    { lat: 48.9704048, lng: 21.7243931 },
    { lat: 48.9705191, lng: 21.7242063 },
    { lat: 48.9707267, lng: 21.7241036 },
    { lat: 48.9707142, lng: 21.7237163 },
    { lat: 48.9715024, lng: 21.7233239 },
    { lat: 48.9715653, lng: 21.7244605 },
    { lat: 48.971465, lng: 21.7250375 },
    { lat: 48.9720048, lng: 21.7249065 },
    { lat: 48.9723383, lng: 21.7242926 },
    { lat: 48.9725081, lng: 21.7242087 },
    { lat: 48.9727938, lng: 21.724329 },
    { lat: 48.9728294, lng: 21.7245244 },
    { lat: 48.9727835, lng: 21.7249949 },
    { lat: 48.9729644, lng: 21.7258289 },
    { lat: 48.9729039, lng: 21.72593 },
    { lat: 48.9730554, lng: 21.7264192 },
    { lat: 48.9735312, lng: 21.7264153 },
    { lat: 48.9739448, lng: 21.7262322 },
    { lat: 48.9747101, lng: 21.7260347 },
    { lat: 48.974654, lng: 21.725698 },
    { lat: 48.9751742, lng: 21.7249973 },
    { lat: 48.9754392, lng: 21.7252356 },
    { lat: 48.9756211, lng: 21.7250926 },
    { lat: 48.9755256, lng: 21.7246394 },
    { lat: 48.9759445, lng: 21.7237648 },
    { lat: 48.9765918, lng: 21.72387 },
    { lat: 48.9776075, lng: 21.7234861 },
    { lat: 48.9782209, lng: 21.7227814 },
    { lat: 48.9784489, lng: 21.7220966 },
    { lat: 48.9788148, lng: 21.721848 },
    { lat: 48.9790194, lng: 21.7214594 },
    { lat: 48.9789339, lng: 21.7205815 },
    { lat: 48.9791517, lng: 21.7202719 },
    { lat: 48.9794223, lng: 21.7202104 },
    { lat: 48.9796937, lng: 21.7205554 },
    { lat: 48.9799221, lng: 21.7211433 },
    { lat: 48.9798942, lng: 21.7215279 },
    { lat: 48.9799975, lng: 21.7216544 },
    { lat: 48.9803849, lng: 21.7211808 },
    { lat: 48.9804757, lng: 21.7210698 },
    { lat: 48.9806759, lng: 21.7211845 },
    { lat: 48.9808431, lng: 21.7210016 },
    { lat: 48.9806735, lng: 21.7204698 },
    { lat: 48.9809645, lng: 21.7201195 },
    { lat: 48.9812302, lng: 21.7202528 },
    { lat: 48.9817975, lng: 21.7202995 },
    { lat: 48.9820082, lng: 21.7201478 },
    { lat: 48.9824984, lng: 21.7201778 },
    { lat: 48.9827667, lng: 21.720408 },
    { lat: 48.9828702, lng: 21.7207436 },
    { lat: 48.9826131, lng: 21.721358 },
    { lat: 48.9831048, lng: 21.7217772 },
    { lat: 48.9836045, lng: 21.7212829 },
    { lat: 48.9843703, lng: 21.7214362 },
    { lat: 48.9848717, lng: 21.7222638 },
    { lat: 48.9849753, lng: 21.722314 },
    { lat: 48.9855978, lng: 21.7217373 },
    { lat: 48.986, lng: 21.7218855 },
    { lat: 48.986519, lng: 21.7225369 },
    { lat: 48.9865759, lng: 21.7228135 },
    { lat: 48.9860945, lng: 21.7234489 },
    { lat: 48.9860661, lng: 21.7236924 },
    { lat: 48.9861921, lng: 21.7237731 },
    { lat: 48.9865386, lng: 21.7231045 },
    { lat: 48.9869708, lng: 21.7230173 },
    { lat: 48.9872364, lng: 21.7232799 },
    { lat: 48.9874712, lng: 21.723686 },
    { lat: 48.9874817, lng: 21.7243348 },
    { lat: 48.9871579, lng: 21.7248515 },
    { lat: 48.9873586, lng: 21.7251984 },
    { lat: 48.9876894, lng: 21.7251999 },
    { lat: 48.9879101, lng: 21.7250878 },
    { lat: 48.988169, lng: 21.725593 },
    { lat: 48.9884736, lng: 21.7259203 },
    { lat: 48.9885239, lng: 21.7263739 },
    { lat: 48.9885973, lng: 21.7263876 },
    { lat: 48.98893, lng: 21.7258255 },
    { lat: 48.9890751, lng: 21.7257858 },
    { lat: 48.989453, lng: 21.7265824 },
    { lat: 48.9896579, lng: 21.7267135 },
    { lat: 48.9898493, lng: 21.7273518 },
    { lat: 48.9902424, lng: 21.7282586 },
    { lat: 48.990511, lng: 21.7281171 },
    { lat: 48.9905231, lng: 21.7281034 },
    { lat: 48.9905911, lng: 21.7279119 },
    { lat: 48.9913623, lng: 21.7266278 },
    { lat: 48.9914859, lng: 21.7268312 },
    { lat: 48.9916028, lng: 21.7264522 },
    { lat: 48.9918204, lng: 21.7264269 },
    { lat: 48.9918081, lng: 21.7259305 },
    { lat: 48.9921001, lng: 21.7254034 },
    { lat: 48.992054, lng: 21.7246396 },
    { lat: 48.9921968, lng: 21.7237123 },
    { lat: 48.9921813, lng: 21.7232651 },
    { lat: 48.9922868, lng: 21.7231254 },
    { lat: 48.9923108, lng: 21.7226736 },
    { lat: 48.9924356, lng: 21.7225885 },
    { lat: 48.9922955, lng: 21.722014 },
    { lat: 48.9926277, lng: 21.7216524 },
    { lat: 48.9927342, lng: 21.7213424 },
    { lat: 48.9925611, lng: 21.7209684 },
    { lat: 48.9927792, lng: 21.7205404 },
    { lat: 48.9927305, lng: 21.7204023 },
    { lat: 48.9930081, lng: 21.720354 },
    { lat: 48.9929205, lng: 21.7202329 },
    { lat: 48.9930649, lng: 21.7199904 },
    { lat: 48.9933844, lng: 21.7196164 },
    { lat: 48.9935047, lng: 21.7196083 },
    { lat: 48.9938857, lng: 21.7192577 },
    { lat: 48.9938532, lng: 21.7187219 },
    { lat: 48.9940358, lng: 21.7184348 },
    { lat: 48.994418, lng: 21.718174 },
    { lat: 48.9946495, lng: 21.7182873 },
    { lat: 48.9949307, lng: 21.7178726 },
    { lat: 48.9951556, lng: 21.717786 },
    { lat: 48.9952174, lng: 21.7176116 },
    { lat: 48.9955944, lng: 21.7170302 },
    { lat: 48.9956319, lng: 21.716493 },
    { lat: 48.9959087, lng: 21.7162383 },
    { lat: 48.9963961, lng: 21.7163759 },
    { lat: 48.9963966, lng: 21.7161946 },
    { lat: 48.9966162, lng: 21.7162408 },
    { lat: 48.9977584, lng: 21.7161724 },
    { lat: 48.9983688, lng: 21.716829 },
    { lat: 48.998363, lng: 21.7167763 },
    { lat: 48.9983083, lng: 21.716707 },
    { lat: 48.998274, lng: 21.7163515 },
    { lat: 48.9983161, lng: 21.7147707 },
    { lat: 48.9986621, lng: 21.7120665 },
    { lat: 48.9989816, lng: 21.7108902 },
    { lat: 48.9990586, lng: 21.7107374 },
    { lat: 48.998859, lng: 21.7108096 },
    { lat: 48.9977552, lng: 21.7090769 },
    { lat: 48.9974654, lng: 21.7078789 },
    { lat: 48.996346, lng: 21.7067048 },
    { lat: 48.9952662, lng: 21.7042008 },
    { lat: 48.9952329, lng: 21.7037175 },
    { lat: 48.9959414, lng: 21.7015257 },
    { lat: 48.9952943, lng: 21.7007641 },
    { lat: 48.994678, lng: 21.7019004 },
    { lat: 48.994182, lng: 21.7025317 },
    { lat: 48.9934576, lng: 21.7030868 },
    { lat: 48.9927207, lng: 21.7034546 },
    { lat: 48.9914184, lng: 21.7036031 },
    { lat: 48.9914499, lng: 21.7031444 },
    { lat: 48.9909766, lng: 21.7029875 },
    { lat: 48.9889107, lng: 21.7015464 },
    { lat: 48.9879206, lng: 21.7018357 },
    { lat: 48.9871127, lng: 21.7016591 },
    { lat: 48.9869042, lng: 21.7015273 },
    { lat: 48.9864636, lng: 21.7009132 },
    { lat: 48.9833593, lng: 21.6979658 },
    { lat: 48.9788171, lng: 21.7011688 },
    { lat: 48.9786245, lng: 21.7014465 },
    { lat: 48.9757705, lng: 21.7033398 },
    { lat: 48.9758597, lng: 21.7035624 },
    { lat: 48.9758499, lng: 21.7036966 },
    { lat: 48.9757494, lng: 21.7036835 },
    { lat: 48.9754869, lng: 21.7034627 },
    { lat: 48.9754622, lng: 21.7033736 },
    { lat: 48.9752891, lng: 21.7033801 },
    { lat: 48.9752425, lng: 21.7036111 },
    { lat: 48.9750931, lng: 21.7036243 },
    { lat: 48.9672864, lng: 21.7068334 },
    { lat: 48.9668098, lng: 21.7069743 },
    { lat: 48.9666042, lng: 21.7071149 },
    { lat: 48.9671012, lng: 21.7075624 },
    { lat: 48.9677121, lng: 21.7084448 },
    { lat: 48.967927, lng: 21.7086979 },
    { lat: 48.9682363, lng: 21.7089419 },
    { lat: 48.9679634, lng: 21.7088754 },
    { lat: 48.9675687, lng: 21.7085486 },
    { lat: 48.9670577, lng: 21.7082863 },
    { lat: 48.9663663, lng: 21.7077175 },
    { lat: 48.9665509, lng: 21.7095265 },
    { lat: 48.9663129, lng: 21.7095834 },
    { lat: 48.9664174, lng: 21.710968 },
    { lat: 48.9659626, lng: 21.7111652 },
    { lat: 48.9662465, lng: 21.712645 },
    { lat: 48.9662625, lng: 21.7127851 },
    { lat: 48.9660769, lng: 21.712905 },
    { lat: 48.9662462, lng: 21.7135547 },
    { lat: 48.9663163, lng: 21.7138237 },
    { lat: 48.9664007, lng: 21.7144944 },
    { lat: 48.966408, lng: 21.7148993 },
    { lat: 48.9663355, lng: 21.715251 },
    { lat: 48.9662887, lng: 21.7154781 },
    { lat: 48.9659113, lng: 21.7163074 },
    { lat: 48.9658471, lng: 21.716673 },
    { lat: 48.9659276, lng: 21.7171199 },
    { lat: 48.9662331, lng: 21.71769 },
    { lat: 48.9662591, lng: 21.7178263 },
    { lat: 48.9659197, lng: 21.7191856 },
    { lat: 48.9657368, lng: 21.7195385 },
    { lat: 48.9655908, lng: 21.7196682 },
    { lat: 48.9653769, lng: 21.7197064 },
    { lat: 48.9647561, lng: 21.719545 },
    { lat: 48.9644397, lng: 21.7181864 },
    { lat: 48.9643526, lng: 21.7174624 },
    { lat: 48.9638577, lng: 21.7175471 },
    { lat: 48.9629153, lng: 21.7175609 },
    { lat: 48.9620926, lng: 21.7172526 },
    { lat: 48.9605431, lng: 21.7176572 },
    { lat: 48.9600431, lng: 21.7186028 },
    { lat: 48.9596599, lng: 21.7186673 },
    { lat: 48.9577298, lng: 21.7190155 },
    { lat: 48.9561198, lng: 21.7208207 },
    { lat: 48.9547264, lng: 21.7226009 },
    { lat: 48.9542159, lng: 21.7253948 },
    { lat: 48.9540262, lng: 21.7262094 },
    { lat: 48.9541337, lng: 21.7263599 },
    { lat: 48.9543124, lng: 21.7262265 },
    { lat: 48.9545677, lng: 21.725972 },
    { lat: 48.9547854, lng: 21.7254445 },
    { lat: 48.9551523, lng: 21.7253614 },
    { lat: 48.9552798, lng: 21.725544 },
    { lat: 48.9559411, lng: 21.7258373 },
    { lat: 48.9559798, lng: 21.7259362 },
    { lat: 48.9584661, lng: 21.7259338 },
    { lat: 48.9585408, lng: 21.7260817 },
  ],
  VyšnýKazimír: [
    { lat: 48.961467, lng: 21.6633411 },
    { lat: 48.9612061, lng: 21.6633438 },
    { lat: 48.9608097, lng: 21.6630809 },
    { lat: 48.960427, lng: 21.6630351 },
    { lat: 48.9603761, lng: 21.6629409 },
    { lat: 48.9601019, lng: 21.6627491 },
    { lat: 48.9599362, lng: 21.6628601 },
    { lat: 48.9598768, lng: 21.6627833 },
    { lat: 48.9596009, lng: 21.6627745 },
    { lat: 48.9591956, lng: 21.6623914 },
    { lat: 48.9589943, lng: 21.6625204 },
    { lat: 48.9588485, lng: 21.6622926 },
    { lat: 48.958736, lng: 21.6624128 },
    { lat: 48.9585474, lng: 21.6624719 },
    { lat: 48.9582575, lng: 21.6624418 },
    { lat: 48.9578924, lng: 21.6626692 },
    { lat: 48.9576835, lng: 21.6626418 },
    { lat: 48.9575502, lng: 21.6629073 },
    { lat: 48.9573276, lng: 21.6630326 },
    { lat: 48.9573484, lng: 21.6632622 },
    { lat: 48.9574497, lng: 21.6633435 },
    { lat: 48.9573872, lng: 21.6635336 },
    { lat: 48.9572265, lng: 21.6634141 },
    { lat: 48.9571877, lng: 21.6635572 },
    { lat: 48.9572363, lng: 21.6637754 },
    { lat: 48.9571037, lng: 21.6638217 },
    { lat: 48.9570634, lng: 21.6637025 },
    { lat: 48.9568888, lng: 21.6637393 },
    { lat: 48.956693, lng: 21.6640215 },
    { lat: 48.956758, lng: 21.6643031 },
    { lat: 48.9567369, lng: 21.6643517 },
    { lat: 48.9566006, lng: 21.6641873 },
    { lat: 48.9565323, lng: 21.6642428 },
    { lat: 48.9564168, lng: 21.66438 },
    { lat: 48.956334, lng: 21.6646032 },
    { lat: 48.9562686, lng: 21.6645898 },
    { lat: 48.9562003, lng: 21.6647749 },
    { lat: 48.9559793, lng: 21.6649609 },
    { lat: 48.9559969, lng: 21.6650282 },
    { lat: 48.9559404, lng: 21.6651023 },
    { lat: 48.9553756, lng: 21.6651377 },
    { lat: 48.955304, lng: 21.6650363 },
    { lat: 48.9550613, lng: 21.665039 },
    { lat: 48.9548687, lng: 21.665239 },
    { lat: 48.9543358, lng: 21.6650115 },
    { lat: 48.9542291, lng: 21.6648922 },
    { lat: 48.9540601, lng: 21.6648781 },
    { lat: 48.9539727, lng: 21.6645675 },
    { lat: 48.9537544, lng: 21.6642723 },
    { lat: 48.9537405, lng: 21.6641769 },
    { lat: 48.9534379, lng: 21.6639328 },
    { lat: 48.9532351, lng: 21.6638342 },
    { lat: 48.9531904, lng: 21.6639333 },
    { lat: 48.9529654, lng: 21.6640172 },
    { lat: 48.9527265, lng: 21.6638736 },
    { lat: 48.9519904, lng: 21.663238 },
    { lat: 48.9513357, lng: 21.6623391 },
    { lat: 48.9510857, lng: 21.6621601 },
    { lat: 48.9505432, lng: 21.6621505 },
    { lat: 48.9503599, lng: 21.6619498 },
    { lat: 48.9500331, lng: 21.6620498 },
    { lat: 48.9492999, lng: 21.6619806 },
    { lat: 48.9488896, lng: 21.6612937 },
    { lat: 48.9488092, lng: 21.6612318 },
    { lat: 48.9484782, lng: 21.6612659 },
    { lat: 48.9483317, lng: 21.661506 },
    { lat: 48.9482545, lng: 21.6614768 },
    { lat: 48.9480709, lng: 21.6610628 },
    { lat: 48.9480088, lng: 21.6610491 },
    { lat: 48.9479434, lng: 21.6611248 },
    { lat: 48.9475923, lng: 21.6611236 },
    { lat: 48.9474761, lng: 21.6610268 },
    { lat: 48.9474527, lng: 21.6607487 },
    { lat: 48.9473559, lng: 21.6607101 },
    { lat: 48.9467872, lng: 21.6599899 },
    { lat: 48.9464717, lng: 21.6602968 },
    { lat: 48.9461244, lng: 21.6601049 },
    { lat: 48.9459894, lng: 21.659937 },
    { lat: 48.9458638, lng: 21.6595426 },
    { lat: 48.9457566, lng: 21.6594061 },
    { lat: 48.9449361, lng: 21.6591875 },
    { lat: 48.9447031, lng: 21.659306 },
    { lat: 48.9443507, lng: 21.6589273 },
    { lat: 48.943385, lng: 21.6590346 },
    { lat: 48.9428716, lng: 21.6587272 },
    { lat: 48.942517, lng: 21.6586844 },
    { lat: 48.9423378, lng: 21.6582569 },
    { lat: 48.9422598, lng: 21.6581999 },
    { lat: 48.9418285, lng: 21.6579783 },
    { lat: 48.9415761, lng: 21.6580507 },
    { lat: 48.9416809, lng: 21.6593353 },
    { lat: 48.9414912, lng: 21.660835 },
    { lat: 48.9415063, lng: 21.6616808 },
    { lat: 48.9412652, lng: 21.6623233 },
    { lat: 48.9412078, lng: 21.6628988 },
    { lat: 48.9412738, lng: 21.6635908 },
    { lat: 48.940196, lng: 21.6636284 },
    { lat: 48.9404499, lng: 21.6644835 },
    { lat: 48.9398344, lng: 21.6651438 },
    { lat: 48.9397635, lng: 21.6653876 },
    { lat: 48.9390269, lng: 21.6664316 },
    { lat: 48.9398902, lng: 21.6672353 },
    { lat: 48.9401971, lng: 21.6685863 },
    { lat: 48.9409192, lng: 21.6708994 },
    { lat: 48.9393527, lng: 21.6719621 },
    { lat: 48.9376765, lng: 21.6727939 },
    { lat: 48.9368611, lng: 21.6735864 },
    { lat: 48.9351971, lng: 21.6754934 },
    { lat: 48.933573, lng: 21.6779939 },
    { lat: 48.9309674, lng: 21.68272 },
    { lat: 48.9305695, lng: 21.683357 },
    { lat: 48.9304288, lng: 21.6834807 },
    { lat: 48.9303605, lng: 21.6836595 },
    { lat: 48.9301998, lng: 21.6837934 },
    { lat: 48.9298987, lng: 21.6842615 },
    { lat: 48.9296078, lng: 21.6844096 },
    { lat: 48.9291261, lng: 21.6841098 },
    { lat: 48.928867, lng: 21.6840941 },
    { lat: 48.9284977, lng: 21.6839583 },
    { lat: 48.9283388, lng: 21.684366 },
    { lat: 48.9282669, lng: 21.6852031 },
    { lat: 48.9283794, lng: 21.6859117 },
    { lat: 48.9284212, lng: 21.6865753 },
    { lat: 48.9283991, lng: 21.6869702 },
    { lat: 48.9284581, lng: 21.687398 },
    { lat: 48.9285389, lng: 21.6876413 },
    { lat: 48.9284133, lng: 21.6877461 },
    { lat: 48.9283439, lng: 21.6875518 },
    { lat: 48.9281732, lng: 21.6875174 },
    { lat: 48.9280627, lng: 21.6874237 },
    { lat: 48.9279308, lng: 21.687496 },
    { lat: 48.9277242, lng: 21.687433 },
    { lat: 48.9276019, lng: 21.6875222 },
    { lat: 48.9270321, lng: 21.6874268 },
    { lat: 48.9268886, lng: 21.6875255 },
    { lat: 48.9265871, lng: 21.6874388 },
    { lat: 48.9263676, lng: 21.6874832 },
    { lat: 48.9260797, lng: 21.6874024 },
    { lat: 48.925378, lng: 21.6874508 },
    { lat: 48.9252369, lng: 21.6873942 },
    { lat: 48.9251205, lng: 21.6875778 },
    { lat: 48.9240161, lng: 21.6877228 },
    { lat: 48.9232684, lng: 21.6881104 },
    { lat: 48.9232726, lng: 21.6881426 },
    { lat: 48.9233022, lng: 21.6881551 },
    { lat: 48.924067, lng: 21.6885228 },
    { lat: 48.9241643, lng: 21.6886234 },
    { lat: 48.9242266, lng: 21.6891397 },
    { lat: 48.9243868, lng: 21.6894857 },
    { lat: 48.9243641, lng: 21.6896488 },
    { lat: 48.925155, lng: 21.6913143 },
    { lat: 48.9254122, lng: 21.6916704 },
    { lat: 48.925631, lng: 21.6922764 },
    { lat: 48.9256716, lng: 21.6942427 },
    { lat: 48.9256378, lng: 21.6945274 },
    { lat: 48.9252953, lng: 21.6957168 },
    { lat: 48.9253207, lng: 21.6966565 },
    { lat: 48.925257, lng: 21.6970139 },
    { lat: 48.9253896, lng: 21.6982185 },
    { lat: 48.9251584, lng: 21.6986915 },
    { lat: 48.9246241, lng: 21.6991941 },
    { lat: 48.92444, lng: 21.7006315 },
    { lat: 48.9244035, lng: 21.7006145 },
    { lat: 48.9242938, lng: 21.7020144 },
    { lat: 48.9241529, lng: 21.7026732 },
    { lat: 48.9243764, lng: 21.7042055 },
    { lat: 48.9241924, lng: 21.7045182 },
    { lat: 48.9239272, lng: 21.7051993 },
    { lat: 48.9233731, lng: 21.7063881 },
    { lat: 48.9228692, lng: 21.70727 },
    { lat: 48.9226699, lng: 21.7079764 },
    { lat: 48.9223655, lng: 21.7087933 },
    { lat: 48.9222657, lng: 21.7091741 },
    { lat: 48.9222809, lng: 21.7093642 },
    { lat: 48.9223511, lng: 21.7095094 },
    { lat: 48.923075, lng: 21.7100337 },
    { lat: 48.9231721, lng: 21.7102985 },
    { lat: 48.9233076, lng: 21.7104447 },
    { lat: 48.9236052, lng: 21.7101319 },
    { lat: 48.9239637, lng: 21.7099448 },
    { lat: 48.9243954, lng: 21.7100619 },
    { lat: 48.9246545, lng: 21.7102813 },
    { lat: 48.9248558, lng: 21.7102785 },
    { lat: 48.9249928, lng: 21.7101398 },
    { lat: 48.9250781, lng: 21.7098848 },
    { lat: 48.9252225, lng: 21.7097732 },
    { lat: 48.9254718, lng: 21.7098854 },
    { lat: 48.9257713, lng: 21.7096855 },
    { lat: 48.926079, lng: 21.7097073 },
    { lat: 48.9261644, lng: 21.709409 },
    { lat: 48.9263723, lng: 21.7092333 },
    { lat: 48.9264232, lng: 21.7091171 },
    { lat: 48.9267402, lng: 21.708939 },
    { lat: 48.9269779, lng: 21.7090739 },
    { lat: 48.9272696, lng: 21.7083306 },
    { lat: 48.9273505, lng: 21.7082887 },
    { lat: 48.9274909, lng: 21.7084078 },
    { lat: 48.9275549, lng: 21.7085684 },
    { lat: 48.9276834, lng: 21.7085805 },
    { lat: 48.9277047, lng: 21.708738 },
    { lat: 48.9282539, lng: 21.7088775 },
    { lat: 48.9286223, lng: 21.7086454 },
    { lat: 48.929225, lng: 21.7082571 },
    { lat: 48.9314577, lng: 21.7057706 },
    { lat: 48.9320372, lng: 21.705018 },
    { lat: 48.932808, lng: 21.7044024 },
    { lat: 48.9329842, lng: 21.7043786 },
    { lat: 48.9330989, lng: 21.7041314 },
    { lat: 48.9330869, lng: 21.7039399 },
    { lat: 48.933237, lng: 21.7034089 },
    { lat: 48.9336151, lng: 21.7029962 },
    { lat: 48.9344192, lng: 21.7024793 },
    { lat: 48.9349988, lng: 21.7025289 },
    { lat: 48.9355543, lng: 21.7023098 },
    { lat: 48.9360797, lng: 21.7023971 },
    { lat: 48.9364798, lng: 21.7023791 },
    { lat: 48.9368902, lng: 21.70217 },
    { lat: 48.9378332, lng: 21.7014595 },
    { lat: 48.938031, lng: 21.7012501 },
    { lat: 48.9382764, lng: 21.700838 },
    { lat: 48.9384725, lng: 21.700347 },
    { lat: 48.9384783, lng: 21.7000643 },
    { lat: 48.9383186, lng: 21.6991831 },
    { lat: 48.9380903, lng: 21.6985575 },
    { lat: 48.939038, lng: 21.6981994 },
    { lat: 48.9394839, lng: 21.6981782 },
    { lat: 48.9397835, lng: 21.6984394 },
    { lat: 48.9402523, lng: 21.6986354 },
    { lat: 48.9405162, lng: 21.6984028 },
    { lat: 48.9408447, lng: 21.6983628 },
    { lat: 48.9412524, lng: 21.6978765 },
    { lat: 48.9419153, lng: 21.6974291 },
    { lat: 48.9422938, lng: 21.6970756 },
    { lat: 48.944153, lng: 21.6944752 },
    { lat: 48.9444688, lng: 21.6942107 },
    { lat: 48.9448111, lng: 21.6940516 },
    { lat: 48.9453005, lng: 21.6934306 },
    { lat: 48.9457408, lng: 21.6926534 },
    { lat: 48.9460507, lng: 21.6917569 },
    { lat: 48.9466686, lng: 21.6892598 },
    { lat: 48.9469131, lng: 21.6888602 },
    { lat: 48.9470012, lng: 21.6885888 },
    { lat: 48.9471213, lng: 21.687913 },
    { lat: 48.9472964, lng: 21.6874383 },
    { lat: 48.9480296, lng: 21.6859644 },
    { lat: 48.9483909, lng: 21.6854304 },
    { lat: 48.9486596, lng: 21.6851337 },
    { lat: 48.9491223, lng: 21.6848951 },
    { lat: 48.9493434, lng: 21.6842578 },
    { lat: 48.9508838, lng: 21.6844677 },
    { lat: 48.9526697, lng: 21.6848198 },
    { lat: 48.953824, lng: 21.684325 },
    { lat: 48.9542453, lng: 21.6845529 },
    { lat: 48.9543709, lng: 21.684787 },
    { lat: 48.9545999, lng: 21.6848497 },
    { lat: 48.9552464, lng: 21.6847142 },
    { lat: 48.95599, lng: 21.6850812 },
    { lat: 48.9560003, lng: 21.684472 },
    { lat: 48.9575653, lng: 21.6843865 },
    { lat: 48.9583011, lng: 21.6845117 },
    { lat: 48.9585976, lng: 21.6847012 },
    { lat: 48.9592807, lng: 21.6857512 },
    { lat: 48.9593835, lng: 21.6856478 },
    { lat: 48.9594095, lng: 21.6854414 },
    { lat: 48.9593744, lng: 21.6851346 },
    { lat: 48.9594178, lng: 21.6848312 },
    { lat: 48.9596315, lng: 21.6841208 },
    { lat: 48.9598681, lng: 21.6836757 },
    { lat: 48.9602181, lng: 21.6822669 },
    { lat: 48.960324, lng: 21.6812346 },
    { lat: 48.9599974, lng: 21.6800986 },
    { lat: 48.9598138, lng: 21.6792218 },
    { lat: 48.9597958, lng: 21.6786011 },
    { lat: 48.9596465, lng: 21.6783659 },
    { lat: 48.9594739, lng: 21.6782234 },
    { lat: 48.9592272, lng: 21.6770034 },
    { lat: 48.9589656, lng: 21.6750335 },
    { lat: 48.9589803, lng: 21.6749932 },
    { lat: 48.9593612, lng: 21.6752236 },
    { lat: 48.9595823, lng: 21.67546 },
    { lat: 48.9598503, lng: 21.6753433 },
    { lat: 48.960134, lng: 21.6750961 },
    { lat: 48.9604375, lng: 21.675211 },
    { lat: 48.9607167, lng: 21.6754681 },
    { lat: 48.9608643, lng: 21.6754764 },
    { lat: 48.9608973, lng: 21.6751969 },
    { lat: 48.9611144, lng: 21.6744927 },
    { lat: 48.9611246, lng: 21.6723876 },
    { lat: 48.9614528, lng: 21.6637286 },
    { lat: 48.961467, lng: 21.6633411 },
  ],
  Hencovce: [
    { lat: 48.8888166, lng: 21.7379741 },
    { lat: 48.8886865, lng: 21.7377347 },
    { lat: 48.8883895, lng: 21.7371888 },
    { lat: 48.88773, lng: 21.7372192 },
    { lat: 48.8877217, lng: 21.7371199 },
    { lat: 48.8873104, lng: 21.7371333 },
    { lat: 48.8868734, lng: 21.7369835 },
    { lat: 48.8867786, lng: 21.7368517 },
    { lat: 48.8864083, lng: 21.7365884 },
    { lat: 48.8861509, lng: 21.7362192 },
    { lat: 48.8860237, lng: 21.7355414 },
    { lat: 48.8858908, lng: 21.7351981 },
    { lat: 48.8856457, lng: 21.734787 },
    { lat: 48.8854772, lng: 21.734284 },
    { lat: 48.8853644, lng: 21.7328396 },
    { lat: 48.8852194, lng: 21.7322551 },
    { lat: 48.8850734, lng: 21.7320142 },
    { lat: 48.8846392, lng: 21.7319268 },
    { lat: 48.8842868, lng: 21.7316668 },
    { lat: 48.8841851, lng: 21.7314389 },
    { lat: 48.8841875, lng: 21.7313183 },
    { lat: 48.8843897, lng: 21.7308841 },
    { lat: 48.884498, lng: 21.7307734 },
    { lat: 48.8851287, lng: 21.7304558 },
    { lat: 48.8855508, lng: 21.7300917 },
    { lat: 48.8856198, lng: 21.729937 },
    { lat: 48.885672, lng: 21.729572 },
    { lat: 48.8854924, lng: 21.7290426 },
    { lat: 48.8844622, lng: 21.7277043 },
    { lat: 48.8841793, lng: 21.727262 },
    { lat: 48.884002, lng: 21.7266512 },
    { lat: 48.8838635, lng: 21.7263488 },
    { lat: 48.8829783, lng: 21.7257226 },
    { lat: 48.8822662, lng: 21.7248924 },
    { lat: 48.8819612, lng: 21.7238789 },
    { lat: 48.8810503, lng: 21.7225881 },
    { lat: 48.8806729, lng: 21.7222371 },
    { lat: 48.880535, lng: 21.7222524 },
    { lat: 48.880289, lng: 21.722646 },
    { lat: 48.8800984, lng: 21.7228347 },
    { lat: 48.8799217, lng: 21.7228306 },
    { lat: 48.8791472, lng: 21.7220522 },
    { lat: 48.879054, lng: 21.7219801 },
    { lat: 48.8787896, lng: 21.7227748 },
    { lat: 48.8779608, lng: 21.7224523 },
    { lat: 48.8772894, lng: 21.7220851 },
    { lat: 48.8771604, lng: 21.7229026 },
    { lat: 48.8763569, lng: 21.7230557 },
    { lat: 48.8753836, lng: 21.7225845 },
    { lat: 48.875244, lng: 21.7229201 },
    { lat: 48.8749954, lng: 21.7232127 },
    { lat: 48.8747383, lng: 21.7234451 },
    { lat: 48.8745216, lng: 21.7233748 },
    { lat: 48.8744888, lng: 21.7238325 },
    { lat: 48.8728195, lng: 21.7239277 },
    { lat: 48.8729355, lng: 21.722758 },
    { lat: 48.8731559, lng: 21.7227484 },
    { lat: 48.8730794, lng: 21.7222088 },
    { lat: 48.8730445, lng: 21.7222084 },
    { lat: 48.8730146, lng: 21.721805 },
    { lat: 48.8725248, lng: 21.7218684 },
    { lat: 48.8725394, lng: 21.7219547 },
    { lat: 48.8716896, lng: 21.7217308 },
    { lat: 48.8708104, lng: 21.7217527 },
    { lat: 48.8706895, lng: 21.7215164 },
    { lat: 48.8699938, lng: 21.7210414 },
    { lat: 48.8697352, lng: 21.72075 },
    { lat: 48.8691086, lng: 21.7207175 },
    { lat: 48.8678081, lng: 21.7205013 },
    { lat: 48.8668948, lng: 21.7200959 },
    { lat: 48.8667497, lng: 21.7195467 },
    { lat: 48.8668099, lng: 21.7184774 },
    { lat: 48.866619, lng: 21.7182865 },
    { lat: 48.8665278, lng: 21.718533 },
    { lat: 48.8660022, lng: 21.7181873 },
    { lat: 48.8661444, lng: 21.7177069 },
    { lat: 48.8658048, lng: 21.7174526 },
    { lat: 48.8656601, lng: 21.7177653 },
    { lat: 48.8653311, lng: 21.7182069 },
    { lat: 48.8652769, lng: 21.7183656 },
    { lat: 48.8651513, lng: 21.7183859 },
    { lat: 48.8648034, lng: 21.7187936 },
    { lat: 48.8646314, lng: 21.7186727 },
    { lat: 48.8644193, lng: 21.7187737 },
    { lat: 48.8641833, lng: 21.7190341 },
    { lat: 48.8640945, lng: 21.7192489 },
    { lat: 48.863376, lng: 21.7191828 },
    { lat: 48.863066, lng: 21.7189432 },
    { lat: 48.8629315, lng: 21.7187103 },
    { lat: 48.8627951, lng: 21.7185847 },
    { lat: 48.8626942, lng: 21.7188028 },
    { lat: 48.8625783, lng: 21.718717 },
    { lat: 48.8619812, lng: 21.7202759 },
    { lat: 48.861602, lng: 21.7195679 },
    { lat: 48.8616736, lng: 21.719338 },
    { lat: 48.8615628, lng: 21.7186542 },
    { lat: 48.8611264, lng: 21.7180626 },
    { lat: 48.8610563, lng: 21.7181841 },
    { lat: 48.8609399, lng: 21.7181137 },
    { lat: 48.8606799, lng: 21.7176938 },
    { lat: 48.8604173, lng: 21.7182209 },
    { lat: 48.8601447, lng: 21.7178772 },
    { lat: 48.8597975, lng: 21.7171772 },
    { lat: 48.859523, lng: 21.7171359 },
    { lat: 48.8592973, lng: 21.7166068 },
    { lat: 48.8589073, lng: 21.7166665 },
    { lat: 48.8586234, lng: 21.7166029 },
    { lat: 48.8584948, lng: 21.7162561 },
    { lat: 48.8584277, lng: 21.7158107 },
    { lat: 48.8583011, lng: 21.7156156 },
    { lat: 48.8580118, lng: 21.7153626 },
    { lat: 48.8579522, lng: 21.7156954 },
    { lat: 48.8567855, lng: 21.7150285 },
    { lat: 48.8566348, lng: 21.7156158 },
    { lat: 48.8561459, lng: 21.7153812 },
    { lat: 48.8556542, lng: 21.7163422 },
    { lat: 48.854882, lng: 21.7168292 },
    { lat: 48.8545494, lng: 21.7168465 },
    { lat: 48.8542262, lng: 21.7163309 },
    { lat: 48.8540615, lng: 21.7162271 },
    { lat: 48.8540439, lng: 21.7161178 },
    { lat: 48.8536004, lng: 21.7157807 },
    { lat: 48.8536369, lng: 21.7152101 },
    { lat: 48.8534797, lng: 21.7146311 },
    { lat: 48.8534146, lng: 21.714559 },
    { lat: 48.8531016, lng: 21.7144229 },
    { lat: 48.8531413, lng: 21.714768 },
    { lat: 48.8530709, lng: 21.7148674 },
    { lat: 48.8527982, lng: 21.7146406 },
    { lat: 48.8522708, lng: 21.7147356 },
    { lat: 48.8518462, lng: 21.7142988 },
    { lat: 48.8516774, lng: 21.7140449 },
    { lat: 48.8513175, lng: 21.7137566 },
    { lat: 48.8512304, lng: 21.7135773 },
    { lat: 48.849679, lng: 21.7147047 },
    { lat: 48.8490716, lng: 21.7153769 },
    { lat: 48.8487263, lng: 21.7156318 },
    { lat: 48.8485262, lng: 21.7156431 },
    { lat: 48.8484211, lng: 21.7155853 },
    { lat: 48.8480413, lng: 21.7150932 },
    { lat: 48.847939, lng: 21.7151799 },
    { lat: 48.8477428, lng: 21.7149591 },
    { lat: 48.8475915, lng: 21.7145126 },
    { lat: 48.8473465, lng: 21.7149067 },
    { lat: 48.8466903, lng: 21.7156041 },
    { lat: 48.8460581, lng: 21.7160267 },
    { lat: 48.8457088, lng: 21.7160943 },
    { lat: 48.8456172, lng: 21.7162811 },
    { lat: 48.8455393, lng: 21.7162237 },
    { lat: 48.8451886, lng: 21.7163257 },
    { lat: 48.8439242, lng: 21.7170862 },
    { lat: 48.8437019, lng: 21.7171395 },
    { lat: 48.8433099, lng: 21.7170805 },
    { lat: 48.8430219, lng: 21.7171282 },
    { lat: 48.8413482, lng: 21.7183546 },
    { lat: 48.8413619, lng: 21.7184055 },
    { lat: 48.8418304, lng: 21.7198482 },
    { lat: 48.8421958, lng: 21.7205083 },
    { lat: 48.8422573, lng: 21.7206998 },
    { lat: 48.8426415, lng: 21.7211912 },
    { lat: 48.84284, lng: 21.7215437 },
    { lat: 48.8429005, lng: 21.7217857 },
    { lat: 48.84302, lng: 21.7233871 },
    { lat: 48.8432868, lng: 21.7236471 },
    { lat: 48.8434244, lng: 21.723998 },
    { lat: 48.8434724, lng: 21.7243186 },
    { lat: 48.8434091, lng: 21.7247701 },
    { lat: 48.8434446, lng: 21.7250061 },
    { lat: 48.8446879, lng: 21.7241242 },
    { lat: 48.8451296, lng: 21.7253255 },
    { lat: 48.8451469, lng: 21.7253019 },
    { lat: 48.8462602, lng: 21.7236858 },
    { lat: 48.8472894, lng: 21.7231288 },
    { lat: 48.8474011, lng: 21.7233999 },
    { lat: 48.8473807, lng: 21.723978 },
    { lat: 48.8470275, lng: 21.7252853 },
    { lat: 48.8467256, lng: 21.7258968 },
    { lat: 48.8467574, lng: 21.7266646 },
    { lat: 48.8467191, lng: 21.7267109 },
    { lat: 48.8468196, lng: 21.727104 },
    { lat: 48.8473489, lng: 21.7268211 },
    { lat: 48.8477494, lng: 21.727921 },
    { lat: 48.8483851, lng: 21.7293105 },
    { lat: 48.8489024, lng: 21.7290504 },
    { lat: 48.8490121, lng: 21.7284447 },
    { lat: 48.849035, lng: 21.7280296 },
    { lat: 48.8488431, lng: 21.7270423 },
    { lat: 48.8488376, lng: 21.7263725 },
    { lat: 48.8487053, lng: 21.7259277 },
    { lat: 48.8487429, lng: 21.7256514 },
    { lat: 48.8488789, lng: 21.7255612 },
    { lat: 48.849112, lng: 21.725534 },
    { lat: 48.8494023, lng: 21.7256151 },
    { lat: 48.849629, lng: 21.7257616 },
    { lat: 48.8498953, lng: 21.7263393 },
    { lat: 48.8499921, lng: 21.7264262 },
    { lat: 48.850288, lng: 21.7264266 },
    { lat: 48.8504804, lng: 21.726259 },
    { lat: 48.8505354, lng: 21.7257884 },
    { lat: 48.8503893, lng: 21.7252065 },
    { lat: 48.850108, lng: 21.7247841 },
    { lat: 48.8499207, lng: 21.7239254 },
    { lat: 48.8499818, lng: 21.7238127 },
    { lat: 48.8501372, lng: 21.7237356 },
    { lat: 48.8507304, lng: 21.7237031 },
    { lat: 48.8512374, lng: 21.723857 },
    { lat: 48.8516244, lng: 21.723652 },
    { lat: 48.8520277, lng: 21.7237873 },
    { lat: 48.8524064, lng: 21.7241959 },
    { lat: 48.8524858, lng: 21.7253149 },
    { lat: 48.852415, lng: 21.7254882 },
    { lat: 48.8518213, lng: 21.7256233 },
    { lat: 48.8513987, lng: 21.7250913 },
    { lat: 48.8510941, lng: 21.7249303 },
    { lat: 48.851537, lng: 21.7275727 },
    { lat: 48.8516222, lng: 21.7278486 },
    { lat: 48.8521207, lng: 21.7287782 },
    { lat: 48.8524046, lng: 21.7285886 },
    { lat: 48.85246, lng: 21.7283409 },
    { lat: 48.8518803, lng: 21.7274501 },
    { lat: 48.8517214, lng: 21.7270173 },
    { lat: 48.8516784, lng: 21.7266713 },
    { lat: 48.8520156, lng: 21.7265773 },
    { lat: 48.8525039, lng: 21.7262444 },
    { lat: 48.8527675, lng: 21.7257077 },
    { lat: 48.8532317, lng: 21.7263272 },
    { lat: 48.853263, lng: 21.7270737 },
    { lat: 48.856775, lng: 21.7281134 },
    { lat: 48.8564712, lng: 21.7297062 },
    { lat: 48.8584552, lng: 21.7303215 },
    { lat: 48.8581634, lng: 21.7321576 },
    { lat: 48.8582448, lng: 21.7322572 },
    { lat: 48.8581504, lng: 21.7330212 },
    { lat: 48.8582208, lng: 21.7331233 },
    { lat: 48.8584192, lng: 21.7338979 },
    { lat: 48.858777, lng: 21.7342393 },
    { lat: 48.8590718, lng: 21.7346246 },
    { lat: 48.859191, lng: 21.734702 },
    { lat: 48.8595731, lng: 21.7347243 },
    { lat: 48.8597795, lng: 21.7344108 },
    { lat: 48.8598325, lng: 21.7343297 },
    { lat: 48.8598829, lng: 21.7333941 },
    { lat: 48.8598359, lng: 21.732771 },
    { lat: 48.8598588, lng: 21.7325355 },
    { lat: 48.8606954, lng: 21.7322674 },
    { lat: 48.8613266, lng: 21.7318467 },
    { lat: 48.8613905, lng: 21.731628 },
    { lat: 48.8614154, lng: 21.7312513 },
    { lat: 48.8615601, lng: 21.7306504 },
    { lat: 48.8616691, lng: 21.7303907 },
    { lat: 48.8619362, lng: 21.7299924 },
    { lat: 48.8622574, lng: 21.7296697 },
    { lat: 48.862419, lng: 21.7297434 },
    { lat: 48.8625475, lng: 21.7296676 },
    { lat: 48.8627922, lng: 21.7293113 },
    { lat: 48.863166, lng: 21.7294513 },
    { lat: 48.8629826, lng: 21.7300789 },
    { lat: 48.8627705, lng: 21.730564 },
    { lat: 48.8621028, lng: 21.7312971 },
    { lat: 48.8619545, lng: 21.7316377 },
    { lat: 48.862085, lng: 21.7319795 },
    { lat: 48.862325, lng: 21.7323757 },
    { lat: 48.8627425, lng: 21.7327553 },
    { lat: 48.8629529, lng: 21.7335535 },
    { lat: 48.8630739, lng: 21.7338316 },
    { lat: 48.8632743, lng: 21.7338389 },
    { lat: 48.8635964, lng: 21.7335598 },
    { lat: 48.864114, lng: 21.7325054 },
    { lat: 48.8642814, lng: 21.7320402 },
    { lat: 48.8648385, lng: 21.7309469 },
    { lat: 48.8652777, lng: 21.7309639 },
    { lat: 48.8656303, lng: 21.7308998 },
    { lat: 48.865727, lng: 21.7307252 },
    { lat: 48.8660478, lng: 21.7308174 },
    { lat: 48.8659393, lng: 21.7313914 },
    { lat: 48.8656955, lng: 21.731822 },
    { lat: 48.8652469, lng: 21.7322551 },
    { lat: 48.8647977, lng: 21.7324999 },
    { lat: 48.8646672, lng: 21.733086 },
    { lat: 48.8646534, lng: 21.7333706 },
    { lat: 48.8651274, lng: 21.7336338 },
    { lat: 48.8654449, lng: 21.7336497 },
    { lat: 48.8656475, lng: 21.7337331 },
    { lat: 48.8660157, lng: 21.7336594 },
    { lat: 48.8660817, lng: 21.7335642 },
    { lat: 48.8662171, lng: 21.7330707 },
    { lat: 48.8664102, lng: 21.7330992 },
    { lat: 48.8668776, lng: 21.7334136 },
    { lat: 48.8670555, lng: 21.7335874 },
    { lat: 48.8671203, lng: 21.7339897 },
    { lat: 48.8670691, lng: 21.7343098 },
    { lat: 48.8669492, lng: 21.7345316 },
    { lat: 48.866979, lng: 21.735446 },
    { lat: 48.867501, lng: 21.7353954 },
    { lat: 48.8679595, lng: 21.733522 },
    { lat: 48.8685686, lng: 21.7337937 },
    { lat: 48.8687444, lng: 21.7339305 },
    { lat: 48.8687575, lng: 21.7341752 },
    { lat: 48.8691564, lng: 21.7342823 },
    { lat: 48.8693793, lng: 21.7352571 },
    { lat: 48.8694059, lng: 21.7349038 },
    { lat: 48.8696871, lng: 21.7342662 },
    { lat: 48.8704053, lng: 21.7336061 },
    { lat: 48.8708829, lng: 21.7335071 },
    { lat: 48.8713138, lng: 21.7335879 },
    { lat: 48.8716597, lng: 21.7340312 },
    { lat: 48.8717789, lng: 21.7336934 },
    { lat: 48.8743553, lng: 21.7361808 },
    { lat: 48.8752245, lng: 21.736719 },
    { lat: 48.8754792, lng: 21.7370344 },
    { lat: 48.87588, lng: 21.737379 },
    { lat: 48.8763842, lng: 21.7381476 },
    { lat: 48.8765482, lng: 21.7398678 },
    { lat: 48.8773288, lng: 21.7402868 },
    { lat: 48.87733, lng: 21.7402871 },
    { lat: 48.8785697, lng: 21.7408345 },
    { lat: 48.8803009, lng: 21.7417137 },
    { lat: 48.8842706, lng: 21.7439837 },
    { lat: 48.8847114, lng: 21.7435471 },
    { lat: 48.8852647, lng: 21.7445575 },
    { lat: 48.8857204, lng: 21.7451076 },
    { lat: 48.8862865, lng: 21.7455806 },
    { lat: 48.8865987, lng: 21.7456913 },
    { lat: 48.887039, lng: 21.7457495 },
    { lat: 48.8873544, lng: 21.7455987 },
    { lat: 48.8877177, lng: 21.7450839 },
    { lat: 48.887824, lng: 21.7445508 },
    { lat: 48.8877733, lng: 21.7440459 },
    { lat: 48.8883043, lng: 21.7437042 },
    { lat: 48.8883799, lng: 21.7437521 },
    { lat: 48.888384, lng: 21.7442892 },
    { lat: 48.8884388, lng: 21.7443631 },
    { lat: 48.8882867, lng: 21.7463414 },
    { lat: 48.893134, lng: 21.7478632 },
    { lat: 48.8931934, lng: 21.7478539 },
    { lat: 48.893287, lng: 21.7460445 },
    { lat: 48.8953625, lng: 21.7452283 },
    { lat: 48.8978946, lng: 21.744333 },
    { lat: 48.8980992, lng: 21.7440752 },
    { lat: 48.8978547, lng: 21.7433364 },
    { lat: 48.8978911, lng: 21.7428436 },
    { lat: 48.898057, lng: 21.7422728 },
    { lat: 48.8981023, lng: 21.7408051 },
    { lat: 48.8984797, lng: 21.7401924 },
    { lat: 48.8985507, lng: 21.739946 },
    { lat: 48.8979341, lng: 21.7390537 },
    { lat: 48.8977646, lng: 21.7385149 },
    { lat: 48.8977826, lng: 21.7382185 },
    { lat: 48.8982985, lng: 21.7372304 },
    { lat: 48.8982949, lng: 21.7365466 },
    { lat: 48.8981789, lng: 21.7359988 },
    { lat: 48.89769, lng: 21.7355021 },
    { lat: 48.8971947, lng: 21.7353701 },
    { lat: 48.8948288, lng: 21.7355388 },
    { lat: 48.8918202, lng: 21.7359449 },
    { lat: 48.8915355, lng: 21.73603 },
    { lat: 48.8910577, lng: 21.736923 },
    { lat: 48.8901114, lng: 21.7376395 },
    { lat: 48.8888252, lng: 21.7382528 },
    { lat: 48.8888166, lng: 21.7379741 },
  ],
  Vechec: [
    { lat: 48.8572406, lng: 21.5666628 },
    { lat: 48.8571669, lng: 21.5676055 },
    { lat: 48.8570836, lng: 21.5678785 },
    { lat: 48.8571151, lng: 21.5684042 },
    { lat: 48.8569389, lng: 21.5688186 },
    { lat: 48.856868, lng: 21.5691744 },
    { lat: 48.8564608, lng: 21.5695741 },
    { lat: 48.8562373, lng: 21.5699948 },
    { lat: 48.855514, lng: 21.5710798 },
    { lat: 48.855069, lng: 21.5712182 },
    { lat: 48.8547375, lng: 21.5716365 },
    { lat: 48.8541861, lng: 21.5727568 },
    { lat: 48.8536955, lng: 21.5747577 },
    { lat: 48.8534918, lng: 21.5753528 },
    { lat: 48.853133, lng: 21.5759179 },
    { lat: 48.853185, lng: 21.5762922 },
    { lat: 48.8531748, lng: 21.5767281 },
    { lat: 48.8530611, lng: 21.5778954 },
    { lat: 48.8532169, lng: 21.5792724 },
    { lat: 48.8533398, lng: 21.5799643 },
    { lat: 48.8532454, lng: 21.5805335 },
    { lat: 48.8530297, lng: 21.5809454 },
    { lat: 48.8529211, lng: 21.5813282 },
    { lat: 48.8525128, lng: 21.5820104 },
    { lat: 48.8521645, lng: 21.5827577 },
    { lat: 48.8518739, lng: 21.5830658 },
    { lat: 48.8515018, lng: 21.5837974 },
    { lat: 48.8508077, lng: 21.5846047 },
    { lat: 48.8505656, lng: 21.5850465 },
    { lat: 48.849537, lng: 21.5862007 },
    { lat: 48.849067, lng: 21.5871326 },
    { lat: 48.8488669, lng: 21.5874219 },
    { lat: 48.8487519, lng: 21.5877373 },
    { lat: 48.8485669, lng: 21.5879235 },
    { lat: 48.8480826, lng: 21.5881096 },
    { lat: 48.8483006, lng: 21.5888994 },
    { lat: 48.8484988, lng: 21.58919 },
    { lat: 48.8487913, lng: 21.5905962 },
    { lat: 48.8489309, lng: 21.5913675 },
    { lat: 48.8493561, lng: 21.5928146 },
    { lat: 48.8497588, lng: 21.5939398 },
    { lat: 48.8499764, lng: 21.5942771 },
    { lat: 48.8500521, lng: 21.5949381 },
    { lat: 48.8503218, lng: 21.5960101 },
    { lat: 48.8507843, lng: 21.5965821 },
    { lat: 48.850714, lng: 21.5975291 },
    { lat: 48.8507814, lng: 21.5980096 },
    { lat: 48.8506831, lng: 21.5983995 },
    { lat: 48.8505412, lng: 21.5995697 },
    { lat: 48.8502782, lng: 21.600062 },
    { lat: 48.8500054, lng: 21.6008514 },
    { lat: 48.8495426, lng: 21.6024079 },
    { lat: 48.8493205, lng: 21.6030019 },
    { lat: 48.849222, lng: 21.6036637 },
    { lat: 48.8490962, lng: 21.6039543 },
    { lat: 48.8490992, lng: 21.6042246 },
    { lat: 48.8492558, lng: 21.6049237 },
    { lat: 48.8494862, lng: 21.6056035 },
    { lat: 48.8494362, lng: 21.6056681 },
    { lat: 48.8500921, lng: 21.6070435 },
    { lat: 48.8503856, lng: 21.6078071 },
    { lat: 48.8505873, lng: 21.6085062 },
    { lat: 48.8507773, lng: 21.609313 },
    { lat: 48.8508604, lng: 21.6111239 },
    { lat: 48.8510817, lng: 21.6122516 },
    { lat: 48.8517116, lng: 21.6145205 },
    { lat: 48.8518652, lng: 21.6159626 },
    { lat: 48.8518228, lng: 21.6169052 },
    { lat: 48.8518822, lng: 21.6171008 },
    { lat: 48.8518303, lng: 21.617296 },
    { lat: 48.8518821, lng: 21.6174638 },
    { lat: 48.8517663, lng: 21.6178277 },
    { lat: 48.8518559, lng: 21.618061 },
    { lat: 48.8518854, lng: 21.6184775 },
    { lat: 48.8519811, lng: 21.6189634 },
    { lat: 48.8520056, lng: 21.6206638 },
    { lat: 48.8521629, lng: 21.6212194 },
    { lat: 48.8520845, lng: 21.6213955 },
    { lat: 48.8521108, lng: 21.6215371 },
    { lat: 48.8520769, lng: 21.6217339 },
    { lat: 48.852133, lng: 21.6219312 },
    { lat: 48.8521018, lng: 21.62215 },
    { lat: 48.8521661, lng: 21.6222302 },
    { lat: 48.8520961, lng: 21.6225916 },
    { lat: 48.8521373, lng: 21.6227755 },
    { lat: 48.852022, lng: 21.6230829 },
    { lat: 48.8520508, lng: 21.6231954 },
    { lat: 48.8519079, lng: 21.6237221 },
    { lat: 48.8519736, lng: 21.6241013 },
    { lat: 48.851934, lng: 21.624743 },
    { lat: 48.8519749, lng: 21.6248425 },
    { lat: 48.8519712, lng: 21.6250732 },
    { lat: 48.8518915, lng: 21.6252858 },
    { lat: 48.8518856, lng: 21.6257203 },
    { lat: 48.8520093, lng: 21.6258057 },
    { lat: 48.8521638, lng: 21.6263429 },
    { lat: 48.8522501, lng: 21.626371 },
    { lat: 48.8522082, lng: 21.6271343 },
    { lat: 48.8518589, lng: 21.6277673 },
    { lat: 48.8518082, lng: 21.6280142 },
    { lat: 48.8515451, lng: 21.6283045 },
    { lat: 48.8515148, lng: 21.6287559 },
    { lat: 48.8514562, lng: 21.6289989 },
    { lat: 48.8513873, lng: 21.629028 },
    { lat: 48.8513024, lng: 21.6292516 },
    { lat: 48.8511256, lng: 21.6294479 },
    { lat: 48.8506086, lng: 21.6304048 },
    { lat: 48.8506434, lng: 21.6312236 },
    { lat: 48.8505145, lng: 21.6312007 },
    { lat: 48.8504095, lng: 21.6312645 },
    { lat: 48.8506176, lng: 21.6317143 },
    { lat: 48.8506646, lng: 21.6319936 },
    { lat: 48.8509209, lng: 21.6324275 },
    { lat: 48.8510773, lng: 21.6330374 },
    { lat: 48.8510246, lng: 21.6332919 },
    { lat: 48.851163, lng: 21.6336583 },
    { lat: 48.851088, lng: 21.633717 },
    { lat: 48.8511613, lng: 21.6338045 },
    { lat: 48.8511007, lng: 21.6340498 },
    { lat: 48.8512836, lng: 21.6339983 },
    { lat: 48.8513734, lng: 21.6340748 },
    { lat: 48.8513808, lng: 21.6345041 },
    { lat: 48.8515789, lng: 21.634508 },
    { lat: 48.8515609, lng: 21.6346252 },
    { lat: 48.8517693, lng: 21.6351712 },
    { lat: 48.851744, lng: 21.6355547 },
    { lat: 48.8516837, lng: 21.6356585 },
    { lat: 48.8519652, lng: 21.6364785 },
    { lat: 48.8520462, lng: 21.6370064 },
    { lat: 48.8523621, lng: 21.6368353 },
    { lat: 48.8524817, lng: 21.6370939 },
    { lat: 48.8531457, lng: 21.6364889 },
    { lat: 48.8533538, lng: 21.6370192 },
    { lat: 48.8535665, lng: 21.6368578 },
    { lat: 48.8537984, lng: 21.6374355 },
    { lat: 48.85393, lng: 21.6373086 },
    { lat: 48.854386, lng: 21.6382598 },
    { lat: 48.8550465, lng: 21.6377364 },
    { lat: 48.8551682, lng: 21.6380292 },
    { lat: 48.8553267, lng: 21.6379003 },
    { lat: 48.8554969, lng: 21.6382768 },
    { lat: 48.8559869, lng: 21.6378946 },
    { lat: 48.8564579, lng: 21.6391433 },
    { lat: 48.8565603, lng: 21.6390614 },
    { lat: 48.8571306, lng: 21.6404932 },
    { lat: 48.8571036, lng: 21.6406928 },
    { lat: 48.8573614, lng: 21.6412743 },
    { lat: 48.857456, lng: 21.6412744 },
    { lat: 48.857667, lng: 21.6415055 },
    { lat: 48.857785, lng: 21.6417442 },
    { lat: 48.8579979, lng: 21.6423707 },
    { lat: 48.8580538, lng: 21.6429696 },
    { lat: 48.8582126, lng: 21.6437153 },
    { lat: 48.8585438, lng: 21.6445448 },
    { lat: 48.8596291, lng: 21.6437578 },
    { lat: 48.8595872, lng: 21.6440706 },
    { lat: 48.8596389, lng: 21.6444421 },
    { lat: 48.8597655, lng: 21.6447832 },
    { lat: 48.8596723, lng: 21.6448316 },
    { lat: 48.8596511, lng: 21.6450477 },
    { lat: 48.8595624, lng: 21.6453056 },
    { lat: 48.8594871, lng: 21.6453003 },
    { lat: 48.8594222, lng: 21.6454355 },
    { lat: 48.8593138, lng: 21.6455074 },
    { lat: 48.8591364, lng: 21.6455615 },
    { lat: 48.8591692, lng: 21.645812 },
    { lat: 48.8591368, lng: 21.6463521 },
    { lat: 48.8591841, lng: 21.6465196 },
    { lat: 48.8590874, lng: 21.6468083 },
    { lat: 48.8589738, lng: 21.6469327 },
    { lat: 48.8588181, lng: 21.6467914 },
    { lat: 48.8587539, lng: 21.6470791 },
    { lat: 48.8587933, lng: 21.6473196 },
    { lat: 48.8588805, lng: 21.6472251 },
    { lat: 48.8589914, lng: 21.6472049 },
    { lat: 48.8590653, lng: 21.6473946 },
    { lat: 48.8590571, lng: 21.6476292 },
    { lat: 48.8589773, lng: 21.647636 },
    { lat: 48.8588895, lng: 21.6479249 },
    { lat: 48.8589691, lng: 21.648031 },
    { lat: 48.8590913, lng: 21.6480179 },
    { lat: 48.8590885, lng: 21.648533 },
    { lat: 48.8591904, lng: 21.6485955 },
    { lat: 48.8593347, lng: 21.6484853 },
    { lat: 48.859467, lng: 21.6485903 },
    { lat: 48.8594461, lng: 21.6488063 },
    { lat: 48.8596309, lng: 21.6490298 },
    { lat: 48.8597457, lng: 21.6489447 },
    { lat: 48.8597554, lng: 21.6488483 },
    { lat: 48.8598182, lng: 21.6489658 },
    { lat: 48.8605067, lng: 21.64888 },
    { lat: 48.8608263, lng: 21.6486822 },
    { lat: 48.8610809, lng: 21.6484054 },
    { lat: 48.8626436, lng: 21.6474626 },
    { lat: 48.8629154, lng: 21.6471953 },
    { lat: 48.8630634, lng: 21.6474672 },
    { lat: 48.8639806, lng: 21.6471028 },
    { lat: 48.8660616, lng: 21.6458625 },
    { lat: 48.8672504, lng: 21.6452726 },
    { lat: 48.8692939, lng: 21.6446709 },
    { lat: 48.8713433, lng: 21.6439531 },
    { lat: 48.8714169, lng: 21.6445063 },
    { lat: 48.8715566, lng: 21.6444664 },
    { lat: 48.8716584, lng: 21.6448946 },
    { lat: 48.8716805, lng: 21.6454604 },
    { lat: 48.8718592, lng: 21.6470052 },
    { lat: 48.8730328, lng: 21.6466028 },
    { lat: 48.8730851, lng: 21.6468329 },
    { lat: 48.8738022, lng: 21.6464346 },
    { lat: 48.87384, lng: 21.6464976 },
    { lat: 48.8741684, lng: 21.6460419 },
    { lat: 48.8742464, lng: 21.6454044 },
    { lat: 48.8740623, lng: 21.6448023 },
    { lat: 48.874086, lng: 21.6447654 },
    { lat: 48.8746833, lng: 21.6442278 },
    { lat: 48.8757103, lng: 21.6435264 },
    { lat: 48.8757087, lng: 21.6431394 },
    { lat: 48.8757967, lng: 21.643066 },
    { lat: 48.8783848, lng: 21.6414411 },
    { lat: 48.8779678, lng: 21.6401835 },
    { lat: 48.8810738, lng: 21.6382125 },
    { lat: 48.8809137, lng: 21.6378272 },
    { lat: 48.8819456, lng: 21.6371392 },
    { lat: 48.8821583, lng: 21.6369378 },
    { lat: 48.8825425, lng: 21.6364713 },
    { lat: 48.8827781, lng: 21.6359927 },
    { lat: 48.8816461, lng: 21.6353715 },
    { lat: 48.8812812, lng: 21.6349882 },
    { lat: 48.8813535, lng: 21.6348403 },
    { lat: 48.8811722, lng: 21.6345071 },
    { lat: 48.8808115, lng: 21.6344049 },
    { lat: 48.8804872, lng: 21.6339803 },
    { lat: 48.8803397, lng: 21.6336079 },
    { lat: 48.8802497, lng: 21.6331217 },
    { lat: 48.8801315, lng: 21.6327943 },
    { lat: 48.8798372, lng: 21.6322006 },
    { lat: 48.8796776, lng: 21.6312584 },
    { lat: 48.8797703, lng: 21.6304679 },
    { lat: 48.8799734, lng: 21.6301973 },
    { lat: 48.8799249, lng: 21.6298301 },
    { lat: 48.8800472, lng: 21.6290427 },
    { lat: 48.8798517, lng: 21.6285632 },
    { lat: 48.8799522, lng: 21.6281279 },
    { lat: 48.8798843, lng: 21.6277451 },
    { lat: 48.8800512, lng: 21.6268658 },
    { lat: 48.8802699, lng: 21.6264698 },
    { lat: 48.8800857, lng: 21.6260315 },
    { lat: 48.8800081, lng: 21.6253801 },
    { lat: 48.8802894, lng: 21.6251435 },
    { lat: 48.8803208, lng: 21.6244862 },
    { lat: 48.8804661, lng: 21.6232262 },
    { lat: 48.8804887, lng: 21.6226628 },
    { lat: 48.8804295, lng: 21.622475 },
    { lat: 48.8806436, lng: 21.6218794 },
    { lat: 48.8812097, lng: 21.621145 },
    { lat: 48.8813559, lng: 21.6207392 },
    { lat: 48.8814891, lng: 21.6205506 },
    { lat: 48.8816037, lng: 21.620183 },
    { lat: 48.88225, lng: 21.6190959 },
    { lat: 48.8824532, lng: 21.6186333 },
    { lat: 48.8826588, lng: 21.6178941 },
    { lat: 48.8829696, lng: 21.6172573 },
    { lat: 48.8831147, lng: 21.6168434 },
    { lat: 48.8831795, lng: 21.6163417 },
    { lat: 48.8831124, lng: 21.6155034 },
    { lat: 48.8832787, lng: 21.6151296 },
    { lat: 48.8833378, lng: 21.6148731 },
    { lat: 48.8832633, lng: 21.6145307 },
    { lat: 48.8833726, lng: 21.6140962 },
    { lat: 48.8835015, lng: 21.6126548 },
    { lat: 48.88372, lng: 21.6116499 },
    { lat: 48.8839169, lng: 21.6111236 },
    { lat: 48.8838496, lng: 21.6109252 },
    { lat: 48.8838931, lng: 21.6103072 },
    { lat: 48.8838451, lng: 21.6101584 },
    { lat: 48.883996, lng: 21.6098366 },
    { lat: 48.8839714, lng: 21.6096802 },
    { lat: 48.8840064, lng: 21.6095671 },
    { lat: 48.883931, lng: 21.6088695 },
    { lat: 48.883845, lng: 21.6087706 },
    { lat: 48.8837542, lng: 21.6083803 },
    { lat: 48.8836181, lng: 21.6081823 },
    { lat: 48.8834964, lng: 21.60813 },
    { lat: 48.883508, lng: 21.607784 },
    { lat: 48.8833339, lng: 21.6073868 },
    { lat: 48.8834734, lng: 21.6070197 },
    { lat: 48.8834532, lng: 21.6067768 },
    { lat: 48.8837651, lng: 21.60626 },
    { lat: 48.8836983, lng: 21.6059236 },
    { lat: 48.8840937, lng: 21.6056206 },
    { lat: 48.88407, lng: 21.6050894 },
    { lat: 48.8837913, lng: 21.6031542 },
    { lat: 48.8836123, lng: 21.6025083 },
    { lat: 48.883142, lng: 21.601902 },
    { lat: 48.882792, lng: 21.6004801 },
    { lat: 48.8824032, lng: 21.5992842 },
    { lat: 48.8822676, lng: 21.5984954 },
    { lat: 48.8820532, lng: 21.5979096 },
    { lat: 48.882162, lng: 21.5975312 },
    { lat: 48.8826431, lng: 21.5964619 },
    { lat: 48.8833113, lng: 21.5943257 },
    { lat: 48.8834495, lng: 21.5939951 },
    { lat: 48.8837421, lng: 21.5935909 },
    { lat: 48.8848203, lng: 21.5933634 },
    { lat: 48.885771, lng: 21.5928781 },
    { lat: 48.8861047, lng: 21.5925868 },
    { lat: 48.8865685, lng: 21.5923179 },
    { lat: 48.8875057, lng: 21.5919131 },
    { lat: 48.8873681, lng: 21.5917371 },
    { lat: 48.8872321, lng: 21.5917827 },
    { lat: 48.8869777, lng: 21.5913342 },
    { lat: 48.8869031, lng: 21.5910845 },
    { lat: 48.8869239, lng: 21.590495 },
    { lat: 48.886986, lng: 21.5903466 },
    { lat: 48.8866197, lng: 21.5901227 },
    { lat: 48.8860967, lng: 21.5896794 },
    { lat: 48.8853956, lng: 21.5892614 },
    { lat: 48.8847734, lng: 21.5896227 },
    { lat: 48.8845743, lng: 21.5892215 },
    { lat: 48.8842658, lng: 21.5890994 },
    { lat: 48.8839912, lng: 21.5891353 },
    { lat: 48.8838728, lng: 21.5890821 },
    { lat: 48.8837671, lng: 21.5891225 },
    { lat: 48.8835329, lng: 21.5893624 },
    { lat: 48.8834691, lng: 21.5892474 },
    { lat: 48.8832385, lng: 21.5892887 },
    { lat: 48.8829934, lng: 21.5894624 },
    { lat: 48.882742, lng: 21.5888369 },
    { lat: 48.882786, lng: 21.5887661 },
    { lat: 48.8824275, lng: 21.5880928 },
    { lat: 48.8823609, lng: 21.5881657 },
    { lat: 48.8815429, lng: 21.5868348 },
    { lat: 48.8811284, lng: 21.5877869 },
    { lat: 48.8809921, lng: 21.5882611 },
    { lat: 48.8808334, lng: 21.588578 },
    { lat: 48.8806898, lng: 21.5886383 },
    { lat: 48.8804896, lng: 21.5885622 },
    { lat: 48.8800758, lng: 21.5885694 },
    { lat: 48.8796183, lng: 21.5882599 },
    { lat: 48.8792196, lng: 21.5877597 },
    { lat: 48.8789, lng: 21.5871994 },
    { lat: 48.8780904, lng: 21.5874523 },
    { lat: 48.8779583, lng: 21.5879226 },
    { lat: 48.8777892, lng: 21.5880721 },
    { lat: 48.8776952, lng: 21.5878901 },
    { lat: 48.8773921, lng: 21.5878771 },
    { lat: 48.8774446, lng: 21.5872153 },
    { lat: 48.8773566, lng: 21.5868424 },
    { lat: 48.8768262, lng: 21.5861802 },
    { lat: 48.8767911, lng: 21.5862135 },
    { lat: 48.8764816, lng: 21.5854056 },
    { lat: 48.8763176, lng: 21.585098 },
    { lat: 48.8762538, lng: 21.5851843 },
    { lat: 48.8762769, lng: 21.5855742 },
    { lat: 48.8759482, lng: 21.5853587 },
    { lat: 48.8758536, lng: 21.5851228 },
    { lat: 48.8759598, lng: 21.5844519 },
    { lat: 48.8759271, lng: 21.5842431 },
    { lat: 48.8762158, lng: 21.583549 },
    { lat: 48.8763096, lng: 21.5832026 },
    { lat: 48.8761792, lng: 21.5830402 },
    { lat: 48.8760366, lng: 21.5825697 },
    { lat: 48.8761442, lng: 21.5817885 },
    { lat: 48.8761554, lng: 21.581188 },
    { lat: 48.8759625, lng: 21.5804034 },
    { lat: 48.8758266, lng: 21.5801726 },
    { lat: 48.8757754, lng: 21.5797927 },
    { lat: 48.875696, lng: 21.5796517 },
    { lat: 48.8755564, lng: 21.5795227 },
    { lat: 48.8754245, lng: 21.579672 },
    { lat: 48.8752348, lng: 21.5800538 },
    { lat: 48.8753899, lng: 21.5786048 },
    { lat: 48.8754384, lng: 21.5784415 },
    { lat: 48.8753096, lng: 21.5780273 },
    { lat: 48.8748342, lng: 21.5771601 },
    { lat: 48.8742775, lng: 21.5770455 },
    { lat: 48.8740686, lng: 21.5771819 },
    { lat: 48.8739541, lng: 21.57715 },
    { lat: 48.8737243, lng: 21.5772616 },
    { lat: 48.8736048, lng: 21.5772118 },
    { lat: 48.8735107, lng: 21.5773043 },
    { lat: 48.8732094, lng: 21.5773995 },
    { lat: 48.8730382, lng: 21.577313 },
    { lat: 48.8728754, lng: 21.5773267 },
    { lat: 48.8726019, lng: 21.5774751 },
    { lat: 48.8724664, lng: 21.5773451 },
    { lat: 48.8722759, lng: 21.5774439 },
    { lat: 48.8720093, lng: 21.5770947 },
    { lat: 48.8717457, lng: 21.577002 },
    { lat: 48.8712175, lng: 21.5765364 },
    { lat: 48.8711136, lng: 21.5765223 },
    { lat: 48.87103, lng: 21.5763472 },
    { lat: 48.8704327, lng: 21.5759929 },
    { lat: 48.8701311, lng: 21.5756282 },
    { lat: 48.8698937, lng: 21.5755963 },
    { lat: 48.8696817, lng: 21.5754162 },
    { lat: 48.8694915, lng: 21.5749735 },
    { lat: 48.8694597, lng: 21.5746382 },
    { lat: 48.8693748, lng: 21.5745025 },
    { lat: 48.8690336, lng: 21.5742915 },
    { lat: 48.8689098, lng: 21.573931 },
    { lat: 48.8688323, lng: 21.5734964 },
    { lat: 48.8687273, lng: 21.5733567 },
    { lat: 48.8687256, lng: 21.573137 },
    { lat: 48.8686687, lng: 21.5729498 },
    { lat: 48.868361, lng: 21.5725383 },
    { lat: 48.8681349, lng: 21.5723799 },
    { lat: 48.8678797, lng: 21.5722999 },
    { lat: 48.8676505, lng: 21.5719955 },
    { lat: 48.8675478, lng: 21.5716648 },
    { lat: 48.8669831, lng: 21.5711416 },
    { lat: 48.8668598, lng: 21.571157 },
    { lat: 48.8666692, lng: 21.5709864 },
    { lat: 48.8662665, lng: 21.5708712 },
    { lat: 48.8659425, lng: 21.570633 },
    { lat: 48.8657073, lng: 21.5706275 },
    { lat: 48.865593, lng: 21.5705616 },
    { lat: 48.8654625, lng: 21.5706851 },
    { lat: 48.8652693, lng: 21.5705796 },
    { lat: 48.8650477, lng: 21.570581 },
    { lat: 48.8648733, lng: 21.5706591 },
    { lat: 48.8639927, lng: 21.5705505 },
    { lat: 48.8638045, lng: 21.5706234 },
    { lat: 48.8635587, lng: 21.570516 },
    { lat: 48.862166, lng: 21.570524 },
    { lat: 48.8591141, lng: 21.5694307 },
    { lat: 48.8579701, lng: 21.5674747 },
    { lat: 48.8572406, lng: 21.5666628 },
  ],
  SlovenskáKajňa: [
    { lat: 48.961467, lng: 21.6633411 },
    { lat: 48.9614528, lng: 21.6637286 },
    { lat: 48.9611246, lng: 21.6723876 },
    { lat: 48.9611144, lng: 21.6744927 },
    { lat: 48.9608973, lng: 21.6751969 },
    { lat: 48.9608643, lng: 21.6754764 },
    { lat: 48.9607167, lng: 21.6754681 },
    { lat: 48.9604375, lng: 21.675211 },
    { lat: 48.960134, lng: 21.6750961 },
    { lat: 48.9598503, lng: 21.6753433 },
    { lat: 48.9595823, lng: 21.67546 },
    { lat: 48.9593612, lng: 21.6752236 },
    { lat: 48.9589803, lng: 21.6749932 },
    { lat: 48.9589656, lng: 21.6750335 },
    { lat: 48.9592272, lng: 21.6770034 },
    { lat: 48.9594739, lng: 21.6782234 },
    { lat: 48.9596465, lng: 21.6783659 },
    { lat: 48.9597958, lng: 21.6786011 },
    { lat: 48.9598138, lng: 21.6792218 },
    { lat: 48.9599974, lng: 21.6800986 },
    { lat: 48.960324, lng: 21.6812346 },
    { lat: 48.9602181, lng: 21.6822669 },
    { lat: 48.9598681, lng: 21.6836757 },
    { lat: 48.9596315, lng: 21.6841208 },
    { lat: 48.9594178, lng: 21.6848312 },
    { lat: 48.9593744, lng: 21.6851346 },
    { lat: 48.9594095, lng: 21.6854414 },
    { lat: 48.9593835, lng: 21.6856478 },
    { lat: 48.9592807, lng: 21.6857512 },
    { lat: 48.9592557, lng: 21.6858638 },
    { lat: 48.9591626, lng: 21.6860826 },
    { lat: 48.9590311, lng: 21.6868004 },
    { lat: 48.9590904, lng: 21.6877808 },
    { lat: 48.9590877, lng: 21.6881 },
    { lat: 48.9590244, lng: 21.6884598 },
    { lat: 48.9590661, lng: 21.6889233 },
    { lat: 48.9586898, lng: 21.6900308 },
    { lat: 48.9575457, lng: 21.6906148 },
    { lat: 48.9569057, lng: 21.692073 },
    { lat: 48.9566339, lng: 21.6930071 },
    { lat: 48.9564206, lng: 21.6932813 },
    { lat: 48.95645, lng: 21.6940735 },
    { lat: 48.9568844, lng: 21.6979636 },
    { lat: 48.9570243, lng: 21.6984372 },
    { lat: 48.9570153, lng: 21.6986919 },
    { lat: 48.9571182, lng: 21.6990023 },
    { lat: 48.9571665, lng: 21.6999541 },
    { lat: 48.9571081, lng: 21.700712 },
    { lat: 48.9572564, lng: 21.7011664 },
    { lat: 48.9573463, lng: 21.7020361 },
    { lat: 48.9574567, lng: 21.702423 },
    { lat: 48.9576755, lng: 21.7028999 },
    { lat: 48.9583687, lng: 21.7039545 },
    { lat: 48.9588584, lng: 21.705141 },
    { lat: 48.9592115, lng: 21.7061412 },
    { lat: 48.9602557, lng: 21.7095417 },
    { lat: 48.9596033, lng: 21.7104664 },
    { lat: 48.9595171, lng: 21.7107359 },
    { lat: 48.9594863, lng: 21.7112659 },
    { lat: 48.9591828, lng: 21.7123594 },
    { lat: 48.9591192, lng: 21.7130134 },
    { lat: 48.9591721, lng: 21.7135508 },
    { lat: 48.9594057, lng: 21.7140859 },
    { lat: 48.9595286, lng: 21.7156904 },
    { lat: 48.9596773, lng: 21.7168329 },
    { lat: 48.9600431, lng: 21.7186028 },
    { lat: 48.9605431, lng: 21.7176572 },
    { lat: 48.9620926, lng: 21.7172526 },
    { lat: 48.9629153, lng: 21.7175609 },
    { lat: 48.9638577, lng: 21.7175471 },
    { lat: 48.9643526, lng: 21.7174624 },
    { lat: 48.9644397, lng: 21.7181864 },
    { lat: 48.9647561, lng: 21.719545 },
    { lat: 48.9653769, lng: 21.7197064 },
    { lat: 48.9655908, lng: 21.7196682 },
    { lat: 48.9657368, lng: 21.7195385 },
    { lat: 48.9659197, lng: 21.7191856 },
    { lat: 48.9662591, lng: 21.7178263 },
    { lat: 48.9662331, lng: 21.71769 },
    { lat: 48.9659276, lng: 21.7171199 },
    { lat: 48.9658471, lng: 21.716673 },
    { lat: 48.9659113, lng: 21.7163074 },
    { lat: 48.9662887, lng: 21.7154781 },
    { lat: 48.9663355, lng: 21.715251 },
    { lat: 48.966408, lng: 21.7148993 },
    { lat: 48.9664007, lng: 21.7144944 },
    { lat: 48.9663163, lng: 21.7138237 },
    { lat: 48.9662462, lng: 21.7135547 },
    { lat: 48.9660769, lng: 21.712905 },
    { lat: 48.9662625, lng: 21.7127851 },
    { lat: 48.9662465, lng: 21.712645 },
    { lat: 48.9659626, lng: 21.7111652 },
    { lat: 48.9664174, lng: 21.710968 },
    { lat: 48.9663129, lng: 21.7095834 },
    { lat: 48.9665509, lng: 21.7095265 },
    { lat: 48.9663663, lng: 21.7077175 },
    { lat: 48.9670577, lng: 21.7082863 },
    { lat: 48.9675687, lng: 21.7085486 },
    { lat: 48.9679634, lng: 21.7088754 },
    { lat: 48.9682363, lng: 21.7089419 },
    { lat: 48.967927, lng: 21.7086979 },
    { lat: 48.9677121, lng: 21.7084448 },
    { lat: 48.9671012, lng: 21.7075624 },
    { lat: 48.9666042, lng: 21.7071149 },
    { lat: 48.9668098, lng: 21.7069743 },
    { lat: 48.9672864, lng: 21.7068334 },
    { lat: 48.9750931, lng: 21.7036243 },
    { lat: 48.9752425, lng: 21.7036111 },
    { lat: 48.9752891, lng: 21.7033801 },
    { lat: 48.9754622, lng: 21.7033736 },
    { lat: 48.9754869, lng: 21.7034627 },
    { lat: 48.9757494, lng: 21.7036835 },
    { lat: 48.9758499, lng: 21.7036966 },
    { lat: 48.9758597, lng: 21.7035624 },
    { lat: 48.9757705, lng: 21.7033398 },
    { lat: 48.9786245, lng: 21.7014465 },
    { lat: 48.9788171, lng: 21.7011688 },
    { lat: 48.9833593, lng: 21.6979658 },
    { lat: 48.9864636, lng: 21.7009132 },
    { lat: 48.9869042, lng: 21.7015273 },
    { lat: 48.9871127, lng: 21.7016591 },
    { lat: 48.9879206, lng: 21.7018357 },
    { lat: 48.9889107, lng: 21.7015464 },
    { lat: 48.9909766, lng: 21.7029875 },
    { lat: 48.9914499, lng: 21.7031444 },
    { lat: 48.9914184, lng: 21.7036031 },
    { lat: 48.9927207, lng: 21.7034546 },
    { lat: 48.9928033, lng: 21.7029791 },
    { lat: 48.9928733, lng: 21.7019788 },
    { lat: 48.9928034, lng: 21.7016837 },
    { lat: 48.9926724, lng: 21.7014948 },
    { lat: 48.9926234, lng: 21.7012216 },
    { lat: 48.9927282, lng: 21.7007612 },
    { lat: 48.9926466, lng: 21.7004557 },
    { lat: 48.9927173, lng: 21.7000673 },
    { lat: 48.9926842, lng: 21.6995029 },
    { lat: 48.9925181, lng: 21.6989809 },
    { lat: 48.9924748, lng: 21.6987049 },
    { lat: 48.9924042, lng: 21.6978846 },
    { lat: 48.99242, lng: 21.6961286 },
    { lat: 48.9922763, lng: 21.6958055 },
    { lat: 48.9921958, lng: 21.6948246 },
    { lat: 48.9910896, lng: 21.6949314 },
    { lat: 48.9904127, lng: 21.6950688 },
    { lat: 48.9888284, lng: 21.6950223 },
    { lat: 48.9852606, lng: 21.6951702 },
    { lat: 48.9832398, lng: 21.6943729 },
    { lat: 48.9825766, lng: 21.6941007 },
    { lat: 48.9825181, lng: 21.6939307 },
    { lat: 48.9823344, lng: 21.6925406 },
    { lat: 48.9830914, lng: 21.6922875 },
    { lat: 48.9838166, lng: 21.692151 },
    { lat: 48.9826523, lng: 21.6913105 },
    { lat: 48.9822959, lng: 21.691365 },
    { lat: 48.9820816, lng: 21.6914707 },
    { lat: 48.9819055, lng: 21.6916962 },
    { lat: 48.9814793, lng: 21.6918295 },
    { lat: 48.9809231, lng: 21.6921851 },
    { lat: 48.9802593, lng: 21.6924739 },
    { lat: 48.9797452, lng: 21.6921882 },
    { lat: 48.9792591, lng: 21.6917686 },
    { lat: 48.9792033, lng: 21.6918266 },
    { lat: 48.9790248, lng: 21.6907589 },
    { lat: 48.979022, lng: 21.6898594 },
    { lat: 48.9785788, lng: 21.6887775 },
    { lat: 48.9758515, lng: 21.6893096 },
    { lat: 48.9754945, lng: 21.6874209 },
    { lat: 48.9746617, lng: 21.6872848 },
    { lat: 48.9745844, lng: 21.6865011 },
    { lat: 48.9731058, lng: 21.6869099 },
    { lat: 48.9723327, lng: 21.6863593 },
    { lat: 48.9721052, lng: 21.6866475 },
    { lat: 48.9717699, lng: 21.6857713 },
    { lat: 48.9724358, lng: 21.6838938 },
    { lat: 48.9725837, lng: 21.6832002 },
    { lat: 48.9723454, lng: 21.6828789 },
    { lat: 48.9720899, lng: 21.6826642 },
    { lat: 48.9718838, lng: 21.6823204 },
    { lat: 48.9718102, lng: 21.6824342 },
    { lat: 48.9714714, lng: 21.6819265 },
    { lat: 48.9713135, lng: 21.6815363 },
    { lat: 48.9714641, lng: 21.6813282 },
    { lat: 48.9717461, lng: 21.6812322 },
    { lat: 48.9720413, lng: 21.6812864 },
    { lat: 48.9723188, lng: 21.6805633 },
    { lat: 48.9726077, lng: 21.6800546 },
    { lat: 48.9727916, lng: 21.6794547 },
    { lat: 48.9729601, lng: 21.6787835 },
    { lat: 48.9730881, lng: 21.6779046 },
    { lat: 48.9730614, lng: 21.6773709 },
    { lat: 48.9732352, lng: 21.6763129 },
    { lat: 48.9733368, lng: 21.6750446 },
    { lat: 48.973444, lng: 21.6745992 },
    { lat: 48.9739119, lng: 21.673223 },
    { lat: 48.9740227, lng: 21.6725038 },
    { lat: 48.9737982, lng: 21.6718153 },
    { lat: 48.9736416, lng: 21.6715955 },
    { lat: 48.9734301, lng: 21.6714283 },
    { lat: 48.9734142, lng: 21.6712184 },
    { lat: 48.9733018, lng: 21.6711409 },
    { lat: 48.9733289, lng: 21.6708985 },
    { lat: 48.9732884, lng: 21.6708123 },
    { lat: 48.9732444, lng: 21.6708491 },
    { lat: 48.9730147, lng: 21.6705082 },
    { lat: 48.9728537, lng: 21.6705101 },
    { lat: 48.9726277, lng: 21.6701414 },
    { lat: 48.9725843, lng: 21.6702412 },
    { lat: 48.9724538, lng: 21.6700732 },
    { lat: 48.9722574, lng: 21.670088 },
    { lat: 48.9720866, lng: 21.6696858 },
    { lat: 48.9720729, lng: 21.6693912 },
    { lat: 48.9718436, lng: 21.6688194 },
    { lat: 48.9717689, lng: 21.6680585 },
    { lat: 48.9717935, lng: 21.6678464 },
    { lat: 48.9716736, lng: 21.667363 },
    { lat: 48.9716286, lng: 21.6668695 },
    { lat: 48.9714641, lng: 21.6667759 },
    { lat: 48.970931, lng: 21.6669036 },
    { lat: 48.9708418, lng: 21.6664762 },
    { lat: 48.9708345, lng: 21.666043 },
    { lat: 48.9706414, lng: 21.66573 },
    { lat: 48.9705915, lng: 21.6653446 },
    { lat: 48.970195, lng: 21.6644167 },
    { lat: 48.9700518, lng: 21.6641975 },
    { lat: 48.9695639, lng: 21.6640012 },
    { lat: 48.9693776, lng: 21.6637325 },
    { lat: 48.9685025, lng: 21.6638179 },
    { lat: 48.9681777, lng: 21.6637932 },
    { lat: 48.9678481, lng: 21.6639149 },
    { lat: 48.9677769, lng: 21.6636569 },
    { lat: 48.9672037, lng: 21.6641712 },
    { lat: 48.9667608, lng: 21.664642 },
    { lat: 48.9664703, lng: 21.664797 },
    { lat: 48.9657717, lng: 21.6650858 },
    { lat: 48.9653473, lng: 21.665164 },
    { lat: 48.9649566, lng: 21.6651523 },
    { lat: 48.9640415, lng: 21.6647977 },
    { lat: 48.9635192, lng: 21.6643808 },
    { lat: 48.9626287, lng: 21.6639884 },
    { lat: 48.9625596, lng: 21.6638818 },
    { lat: 48.9623933, lng: 21.6639295 },
    { lat: 48.9622421, lng: 21.663876 },
    { lat: 48.9620581, lng: 21.6636906 },
    { lat: 48.9618192, lng: 21.663755 },
    { lat: 48.961467, lng: 21.6633411 },
  ],
  Skrabské: [
    { lat: 49.0225879, lng: 21.5633126 },
    { lat: 49.0213594, lng: 21.562389 },
    { lat: 49.0208663, lng: 21.5631415 },
    { lat: 49.0200577, lng: 21.562078 },
    { lat: 49.0180968, lng: 21.5662469 },
    { lat: 49.0173896, lng: 21.5656626 },
    { lat: 49.0168198, lng: 21.5653615 },
    { lat: 49.0153714, lng: 21.5638285 },
    { lat: 49.0148605, lng: 21.563019 },
    { lat: 49.011862, lng: 21.5630074 },
    { lat: 49.0111245, lng: 21.5627539 },
    { lat: 49.0107103, lng: 21.5626486 },
    { lat: 49.0097898, lng: 21.5624147 },
    { lat: 49.0083109, lng: 21.5629608 },
    { lat: 49.0080215, lng: 21.5633928 },
    { lat: 49.0078413, lng: 21.5640642 },
    { lat: 49.0073085, lng: 21.5641644 },
    { lat: 49.0071615, lng: 21.5644556 },
    { lat: 49.0070912, lng: 21.5645935 },
    { lat: 49.007169, lng: 21.5669325 },
    { lat: 49.0052344, lng: 21.5684304 },
    { lat: 49.0048534, lng: 21.5696494 },
    { lat: 49.0042382, lng: 21.5711087 },
    { lat: 49.0034672, lng: 21.5719713 },
    { lat: 49.0034206, lng: 21.5722384 },
    { lat: 49.0034273, lng: 21.5727207 },
    { lat: 49.0030601, lng: 21.573593 },
    { lat: 49.0024934, lng: 21.5732394 },
    { lat: 49.0022012, lng: 21.5731268 },
    { lat: 49.001395, lng: 21.5731058 },
    { lat: 49.000855, lng: 21.5734288 },
    { lat: 49.0003927, lng: 21.5741205 },
    { lat: 49.0005254, lng: 21.5758824 },
    { lat: 49.0006337, lng: 21.5781533 },
    { lat: 48.9998733, lng: 21.5783923 },
    { lat: 48.9998939, lng: 21.5787672 },
    { lat: 48.9990909, lng: 21.5789025 },
    { lat: 48.9985384, lng: 21.5786967 },
    { lat: 48.9972527, lng: 21.5785823 },
    { lat: 48.9948331, lng: 21.5800924 },
    { lat: 48.9938407, lng: 21.5802073 },
    { lat: 48.9933558, lng: 21.5802518 },
    { lat: 48.9933787, lng: 21.5804639 },
    { lat: 48.9933919, lng: 21.580573 },
    { lat: 48.9934194, lng: 21.5807991 },
    { lat: 48.9937704, lng: 21.5817589 },
    { lat: 48.9941797, lng: 21.582478 },
    { lat: 48.9941913, lng: 21.5828925 },
    { lat: 48.9949796, lng: 21.5838431 },
    { lat: 48.9954367, lng: 21.5841791 },
    { lat: 48.9957886, lng: 21.5842546 },
    { lat: 48.9959472, lng: 21.5845292 },
    { lat: 48.9960791, lng: 21.5851524 },
    { lat: 48.9965752, lng: 21.5883314 },
    { lat: 48.996866, lng: 21.5890827 },
    { lat: 48.9969665, lng: 21.5897348 },
    { lat: 48.997281, lng: 21.5933917 },
    { lat: 48.9979356, lng: 21.5929509 },
    { lat: 48.9984002, lng: 21.5927422 },
    { lat: 49.0001566, lng: 21.5914981 },
    { lat: 49.0014852, lng: 21.590681 },
    { lat: 49.0067177, lng: 21.5945573 },
    { lat: 49.0099218, lng: 21.5961359 },
    { lat: 49.0102125, lng: 21.59672 },
    { lat: 49.0097935, lng: 21.5977102 },
    { lat: 49.0097981, lng: 21.5978343 },
    { lat: 49.0095424, lng: 21.5989581 },
    { lat: 49.0097705, lng: 21.5995133 },
    { lat: 49.009761, lng: 21.5996225 },
    { lat: 49.0104355, lng: 21.600939 },
    { lat: 49.0105414, lng: 21.6013898 },
    { lat: 49.0109619, lng: 21.6017453 },
    { lat: 49.0115367, lng: 21.6016192 },
    { lat: 49.0123658, lng: 21.6023491 },
    { lat: 49.0137203, lng: 21.6034071 },
    { lat: 49.0140511, lng: 21.6040945 },
    { lat: 49.0151191, lng: 21.6056895 },
    { lat: 49.015526, lng: 21.6064046 },
    { lat: 49.0158151, lng: 21.6070514 },
    { lat: 49.0163299, lng: 21.6076449 },
    { lat: 49.0166663, lng: 21.6081725 },
    { lat: 49.0172941, lng: 21.6089883 },
    { lat: 49.0173709, lng: 21.6089206 },
    { lat: 49.0177591, lng: 21.6090883 },
    { lat: 49.0179288, lng: 21.6093599 },
    { lat: 49.0183914, lng: 21.6093672 },
    { lat: 49.0187266, lng: 21.6095293 },
    { lat: 49.0188525, lng: 21.6100134 },
    { lat: 49.0189726, lng: 21.6101624 },
    { lat: 49.0193684, lng: 21.6103582 },
    { lat: 49.0195603, lng: 21.6107065 },
    { lat: 49.0195591, lng: 21.6120128 },
    { lat: 49.0194043, lng: 21.6125525 },
    { lat: 49.019383, lng: 21.6131704 },
    { lat: 49.0192676, lng: 21.6137129 },
    { lat: 49.0189477, lng: 21.6144718 },
    { lat: 49.0187053, lng: 21.6152321 },
    { lat: 49.0186279, lng: 21.6156474 },
    { lat: 49.0182633, lng: 21.6166231 },
    { lat: 49.0179596, lng: 21.6169884 },
    { lat: 49.0176588, lng: 21.6175033 },
    { lat: 49.0174238, lng: 21.6187323 },
    { lat: 49.0174568, lng: 21.61899 },
    { lat: 49.0173904, lng: 21.6195993 },
    { lat: 49.0172992, lng: 21.6199689 },
    { lat: 49.017133, lng: 21.6203 },
    { lat: 49.0171376, lng: 21.6210423 },
    { lat: 49.0170901, lng: 21.6211579 },
    { lat: 49.0169702, lng: 21.6220663 },
    { lat: 49.0168963, lng: 21.6235513 },
    { lat: 49.0169387, lng: 21.6243106 },
    { lat: 49.0170434, lng: 21.624972 },
    { lat: 49.017257, lng: 21.6250072 },
    { lat: 49.0181127, lng: 21.6248251 },
    { lat: 49.0191859, lng: 21.6249048 },
    { lat: 49.0197193, lng: 21.6242171 },
    { lat: 49.0203019, lng: 21.6237226 },
    { lat: 49.0204825, lng: 21.6234125 },
    { lat: 49.0206722, lng: 21.6232757 },
    { lat: 49.0213379, lng: 21.6225296 },
    { lat: 49.0222235, lng: 21.6221728 },
    { lat: 49.0227901, lng: 21.6222356 },
    { lat: 49.022926, lng: 21.6223094 },
    { lat: 49.023531, lng: 21.6224666 },
    { lat: 49.0237932, lng: 21.6223467 },
    { lat: 49.0237993, lng: 21.6220373 },
    { lat: 49.0240369, lng: 21.6217981 },
    { lat: 49.0245149, lng: 21.6215873 },
    { lat: 49.0248712, lng: 21.6211738 },
    { lat: 49.0248222, lng: 21.6207816 },
    { lat: 49.0242632, lng: 21.6195571 },
    { lat: 49.0241517, lng: 21.6190695 },
    { lat: 49.0241076, lng: 21.6185314 },
    { lat: 49.0240523, lng: 21.617543 },
    { lat: 49.0241269, lng: 21.6167783 },
    { lat: 49.0240661, lng: 21.6161312 },
    { lat: 49.0241741, lng: 21.6145792 },
    { lat: 49.0243026, lng: 21.6139827 },
    { lat: 49.0246751, lng: 21.6138636 },
    { lat: 49.0252709, lng: 21.613966 },
    { lat: 49.026128, lng: 21.6142042 },
    { lat: 49.0263096, lng: 21.6143406 },
    { lat: 49.0265007, lng: 21.6143736 },
    { lat: 49.0266317, lng: 21.6146789 },
    { lat: 49.0268635, lng: 21.6146025 },
    { lat: 49.0279687, lng: 21.6136811 },
    { lat: 49.028092, lng: 21.613375 },
    { lat: 49.0282956, lng: 21.6131307 },
    { lat: 49.0287683, lng: 21.6122795 },
    { lat: 49.0290694, lng: 21.6119067 },
    { lat: 49.0299999, lng: 21.6113353 },
    { lat: 49.0302096, lng: 21.6113914 },
    { lat: 49.0307124, lng: 21.6112707 },
    { lat: 49.0312116, lng: 21.6107723 },
    { lat: 49.0315204, lng: 21.6101402 },
    { lat: 49.0317152, lng: 21.6096559 },
    { lat: 49.0317026, lng: 21.6091566 },
    { lat: 49.0319229, lng: 21.6085064 },
    { lat: 49.0323297, lng: 21.6077081 },
    { lat: 49.0324627, lng: 21.6068681 },
    { lat: 49.0322166, lng: 21.6063081 },
    { lat: 49.0317292, lng: 21.6057319 },
    { lat: 49.0321139, lng: 21.6049135 },
    { lat: 49.0322417, lng: 21.6041333 },
    { lat: 49.0325128, lng: 21.6010884 },
    { lat: 49.0327217, lng: 21.600221 },
    { lat: 49.0329222, lng: 21.5992977 },
    { lat: 49.032695, lng: 21.5991732 },
    { lat: 49.0326352, lng: 21.5985657 },
    { lat: 49.0328711, lng: 21.5971275 },
    { lat: 49.0331073, lng: 21.5962783 },
    { lat: 49.0334164, lng: 21.5963467 },
    { lat: 49.0341286, lng: 21.595786 },
    { lat: 49.0344739, lng: 21.5952568 },
    { lat: 49.0347768, lng: 21.5950161 },
    { lat: 49.0351186, lng: 21.5944474 },
    { lat: 49.0351251, lng: 21.5940697 },
    { lat: 49.0352045, lng: 21.5934909 },
    { lat: 49.035569, lng: 21.5929999 },
    { lat: 49.0363223, lng: 21.5908423 },
    { lat: 49.036475, lng: 21.5902215 },
    { lat: 49.0364937, lng: 21.5896064 },
    { lat: 49.0363729, lng: 21.588901 },
    { lat: 49.0365766, lng: 21.5886193 },
    { lat: 49.0365145, lng: 21.5883296 },
    { lat: 49.0361375, lng: 21.5872359 },
    { lat: 49.0358889, lng: 21.5866728 },
    { lat: 49.0355565, lng: 21.5861657 },
    { lat: 49.0354268, lng: 21.585833 },
    { lat: 49.0352358, lng: 21.5855965 },
    { lat: 49.0346272, lng: 21.5843051 },
    { lat: 49.0337427, lng: 21.5826522 },
    { lat: 49.0334779, lng: 21.5823527 },
    { lat: 49.0329967, lng: 21.5813557 },
    { lat: 49.0328279, lng: 21.5811931 },
    { lat: 49.0320957, lng: 21.5809582 },
    { lat: 49.0310897, lng: 21.5788995 },
    { lat: 49.0278284, lng: 21.5727465 },
    { lat: 49.0276026, lng: 21.5725535 },
    { lat: 49.0272304, lng: 21.5725245 },
    { lat: 49.0266256, lng: 21.5712558 },
    { lat: 49.0256588, lng: 21.5696345 },
    { lat: 49.025155, lng: 21.5681941 },
    { lat: 49.0250326, lng: 21.5676814 },
    { lat: 49.0239846, lng: 21.5652994 },
    { lat: 49.0235079, lng: 21.564541 },
    { lat: 49.0225879, lng: 21.5633126 },
  ],
  Vavrinec: [
    { lat: 49.0327217, lng: 21.600221 },
    { lat: 49.0332554, lng: 21.6006023 },
    { lat: 49.0330713, lng: 21.6011393 },
    { lat: 49.0336748, lng: 21.6016478 },
    { lat: 49.0334125, lng: 21.6023742 },
    { lat: 49.0339496, lng: 21.6030386 },
    { lat: 49.0336648, lng: 21.6038363 },
    { lat: 49.0332208, lng: 21.6048712 },
    { lat: 49.0331769, lng: 21.6050752 },
    { lat: 49.0330414, lng: 21.6050946 },
    { lat: 49.033024, lng: 21.6063736 },
    { lat: 49.0328174, lng: 21.6063546 },
    { lat: 49.0327808, lng: 21.6065286 },
    { lat: 49.0328196, lng: 21.6077682 },
    { lat: 49.0328735, lng: 21.6080128 },
    { lat: 49.0331191, lng: 21.6078236 },
    { lat: 49.0332189, lng: 21.6082937 },
    { lat: 49.0336925, lng: 21.609395 },
    { lat: 49.0348566, lng: 21.6088135 },
    { lat: 49.0352085, lng: 21.6084349 },
    { lat: 49.0354172, lng: 21.6080551 },
    { lat: 49.0352087, lng: 21.6065338 },
    { lat: 49.0354249, lng: 21.6065031 },
    { lat: 49.0360396, lng: 21.6067183 },
    { lat: 49.0365321, lng: 21.6067535 },
    { lat: 49.0372931, lng: 21.6065218 },
    { lat: 49.037524, lng: 21.6063303 },
    { lat: 49.0381647, lng: 21.6062272 },
    { lat: 49.0383226, lng: 21.6060389 },
    { lat: 49.0388466, lng: 21.6060336 },
    { lat: 49.0393302, lng: 21.606149 },
    { lat: 49.0397108, lng: 21.6063652 },
    { lat: 49.040153, lng: 21.6066947 },
    { lat: 49.0403575, lng: 21.606928 },
    { lat: 49.0405925, lng: 21.6073253 },
    { lat: 49.0407664, lng: 21.6078356 },
    { lat: 49.0412713, lng: 21.6083606 },
    { lat: 49.0413895, lng: 21.6086026 },
    { lat: 49.0415485, lng: 21.6086993 },
    { lat: 49.0416879, lng: 21.6086954 },
    { lat: 49.0417646, lng: 21.6087808 },
    { lat: 49.0418865, lng: 21.6087996 },
    { lat: 49.0420418, lng: 21.6087197 },
    { lat: 49.042411, lng: 21.6092153 },
    { lat: 49.0428174, lng: 21.6093858 },
    { lat: 49.0441604, lng: 21.6095785 },
    { lat: 49.0460761, lng: 21.6093243 },
    { lat: 49.0466013, lng: 21.6090307 },
    { lat: 49.0468059, lng: 21.6090732 },
    { lat: 49.0471243, lng: 21.6087874 },
    { lat: 49.0472538, lng: 21.6089104 },
    { lat: 49.0473857, lng: 21.6088801 },
    { lat: 49.047426, lng: 21.6086336 },
    { lat: 49.047495, lng: 21.6085614 },
    { lat: 49.0475719, lng: 21.608776 },
    { lat: 49.0476658, lng: 21.6088385 },
    { lat: 49.0479242, lng: 21.6084234 },
    { lat: 49.0482375, lng: 21.6085253 },
    { lat: 49.0487273, lng: 21.6082479 },
    { lat: 49.0488496, lng: 21.6082796 },
    { lat: 49.0489558, lng: 21.6084962 },
    { lat: 49.0493898, lng: 21.60809 },
    { lat: 49.0496012, lng: 21.6082038 },
    { lat: 49.0498349, lng: 21.6079091 },
    { lat: 49.0499967, lng: 21.6079393 },
    { lat: 49.0502257, lng: 21.608129 },
    { lat: 49.0515421, lng: 21.6082474 },
    { lat: 49.0518425, lng: 21.6083767 },
    { lat: 49.0517931, lng: 21.608416 },
    { lat: 49.0519559, lng: 21.6090161 },
    { lat: 49.0520889, lng: 21.6100399 },
    { lat: 49.0522583, lng: 21.6102242 },
    { lat: 49.0534776, lng: 21.6099363 },
    { lat: 49.053976, lng: 21.6100231 },
    { lat: 49.0545138, lng: 21.6107481 },
    { lat: 49.0549965, lng: 21.611205 },
    { lat: 49.0556472, lng: 21.6097469 },
    { lat: 49.0563883, lng: 21.6090537 },
    { lat: 49.0573402, lng: 21.6087302 },
    { lat: 49.057974, lng: 21.6086218 },
    { lat: 49.0582082, lng: 21.6085064 },
    { lat: 49.0583118, lng: 21.6098579 },
    { lat: 49.0584889, lng: 21.6110547 },
    { lat: 49.0585081, lng: 21.6118262 },
    { lat: 49.0588852, lng: 21.6126925 },
    { lat: 49.0591207, lng: 21.6130325 },
    { lat: 49.0592586, lng: 21.6131339 },
    { lat: 49.0592969, lng: 21.6132577 },
    { lat: 49.0596111, lng: 21.6133097 },
    { lat: 49.0600978, lng: 21.6135767 },
    { lat: 49.0604228, lng: 21.6138655 },
    { lat: 49.0607338, lng: 21.6142492 },
    { lat: 49.0609676, lng: 21.6143602 },
    { lat: 49.0611137, lng: 21.6145205 },
    { lat: 49.0612879, lng: 21.6149198 },
    { lat: 49.061462, lng: 21.6167881 },
    { lat: 49.0614349, lng: 21.6173933 },
    { lat: 49.061859, lng: 21.617838 },
    { lat: 49.063627, lng: 21.6182697 },
    { lat: 49.0638811, lng: 21.6185089 },
    { lat: 49.0634708, lng: 21.6196261 },
    { lat: 49.0634721, lng: 21.6215468 },
    { lat: 49.0640356, lng: 21.6210548 },
    { lat: 49.064678, lng: 21.6207294 },
    { lat: 49.064855, lng: 21.621138 },
    { lat: 49.064874, lng: 21.621121 },
    { lat: 49.065143, lng: 21.620894 },
    { lat: 49.065185, lng: 21.620858 },
    { lat: 49.065632, lng: 21.620606 },
    { lat: 49.065989, lng: 21.620499 },
    { lat: 49.066037, lng: 21.620606 },
    { lat: 49.066502, lng: 21.620459 },
    { lat: 49.066758, lng: 21.620312 },
    { lat: 49.067217, lng: 21.619943 },
    { lat: 49.068021, lng: 21.619782 },
    { lat: 49.068409, lng: 21.619612 },
    { lat: 49.068304, lng: 21.619184 },
    { lat: 49.068182, lng: 21.618825 },
    { lat: 49.068175, lng: 21.6185 },
    { lat: 49.068149, lng: 21.618299 },
    { lat: 49.068083, lng: 21.61778 },
    { lat: 49.067988, lng: 21.61729 },
    { lat: 49.068073, lng: 21.617048 },
    { lat: 49.068201, lng: 21.615716 },
    { lat: 49.068264, lng: 21.615123 },
    { lat: 49.06828, lng: 21.614865 },
    { lat: 49.068299, lng: 21.614761 },
    { lat: 49.068208, lng: 21.614511 },
    { lat: 49.068081, lng: 21.613755 },
    { lat: 49.067901, lng: 21.613198 },
    { lat: 49.067908, lng: 21.612502 },
    { lat: 49.068037, lng: 21.612112 },
    { lat: 49.068053, lng: 21.611883 },
    { lat: 49.068076, lng: 21.611788 },
    { lat: 49.068452, lng: 21.611048 },
    { lat: 49.0686, lng: 21.610747 },
    { lat: 49.068717, lng: 21.610603 },
    { lat: 49.068974, lng: 21.609963 },
    { lat: 49.068989, lng: 21.609815 },
    { lat: 49.069029, lng: 21.60979 },
    { lat: 49.069126, lng: 21.609795 },
    { lat: 49.069162, lng: 21.609479 },
    { lat: 49.069291, lng: 21.609372 },
    { lat: 49.069334, lng: 21.609189 },
    { lat: 49.0689417, lng: 21.6082477 },
    { lat: 49.0684092, lng: 21.6066112 },
    { lat: 49.0681772, lng: 21.6062781 },
    { lat: 49.0676199, lng: 21.6060562 },
    { lat: 49.0671117, lng: 21.606186 },
    { lat: 49.0669492, lng: 21.6056055 },
    { lat: 49.0665791, lng: 21.6054021 },
    { lat: 49.0660821, lng: 21.6046005 },
    { lat: 49.065908, lng: 21.6044836 },
    { lat: 49.0652898, lng: 21.6043036 },
    { lat: 49.0649516, lng: 21.6039511 },
    { lat: 49.0646452, lng: 21.6028474 },
    { lat: 49.0638472, lng: 21.6025474 },
    { lat: 49.0631941, lng: 21.6024419 },
    { lat: 49.0626129, lng: 21.6022521 },
    { lat: 49.062267, lng: 21.6019105 },
    { lat: 49.0619471, lng: 21.6023468 },
    { lat: 49.0611485, lng: 21.6002409 },
    { lat: 49.0606741, lng: 21.5996844 },
    { lat: 49.0598033, lng: 21.5984274 },
    { lat: 49.059297, lng: 21.5981855 },
    { lat: 49.0584157, lng: 21.5974353 },
    { lat: 49.0584095, lng: 21.5966077 },
    { lat: 49.058565, lng: 21.595561 },
    { lat: 49.058458, lng: 21.5954337 },
    { lat: 49.0584813, lng: 21.5950168 },
    { lat: 49.057907, lng: 21.5945939 },
    { lat: 49.0573964, lng: 21.5944416 },
    { lat: 49.057084, lng: 21.5933959 },
    { lat: 49.0570221, lng: 21.5933149 },
    { lat: 49.0562963, lng: 21.5929444 },
    { lat: 49.0556874, lng: 21.5929373 },
    { lat: 49.0549709, lng: 21.5926229 },
    { lat: 49.0553708, lng: 21.5912585 },
    { lat: 49.0545397, lng: 21.590662 },
    { lat: 49.0540539, lng: 21.5906305 },
    { lat: 49.0540905, lng: 21.5894053 },
    { lat: 49.0542599, lng: 21.588696 },
    { lat: 49.0543114, lng: 21.5886855 },
    { lat: 49.0540436, lng: 21.5883642 },
    { lat: 49.053742, lng: 21.5884037 },
    { lat: 49.0534256, lng: 21.5881482 },
    { lat: 49.0534194, lng: 21.5879679 },
    { lat: 49.0532059, lng: 21.5874863 },
    { lat: 49.0532522, lng: 21.5873735 },
    { lat: 49.0535757, lng: 21.5871102 },
    { lat: 49.0538271, lng: 21.5857169 },
    { lat: 49.0536677, lng: 21.5850059 },
    { lat: 49.0534414, lng: 21.5849566 },
    { lat: 49.0535236, lng: 21.584353 },
    { lat: 49.0536416, lng: 21.5842285 },
    { lat: 49.0534881, lng: 21.5836028 },
    { lat: 49.0536125, lng: 21.5834229 },
    { lat: 49.053355, lng: 21.582305 },
    { lat: 49.0528561, lng: 21.5824473 },
    { lat: 49.051904, lng: 21.5830012 },
    { lat: 49.0515735, lng: 21.5817987 },
    { lat: 49.051472, lng: 21.5814251 },
    { lat: 49.0508839, lng: 21.5819587 },
    { lat: 49.0502486, lng: 21.5832623 },
    { lat: 49.0497452, lng: 21.5829651 },
    { lat: 49.0494996, lng: 21.5827041 },
    { lat: 49.0489595, lng: 21.5819029 },
    { lat: 49.0486857, lng: 21.5818058 },
    { lat: 49.048552, lng: 21.5820512 },
    { lat: 49.0482922, lng: 21.5822189 },
    { lat: 49.0480409, lng: 21.5825253 },
    { lat: 49.0477301, lng: 21.5829933 },
    { lat: 49.0472253, lng: 21.5831701 },
    { lat: 49.0469966, lng: 21.5835657 },
    { lat: 49.0468971, lng: 21.5840323 },
    { lat: 49.0464835, lng: 21.5843681 },
    { lat: 49.0464741, lng: 21.58468 },
    { lat: 49.0461966, lng: 21.5848159 },
    { lat: 49.0461473, lng: 21.5850192 },
    { lat: 49.0454769, lng: 21.5865193 },
    { lat: 49.0454611, lng: 21.5867208 },
    { lat: 49.0452836, lng: 21.5868509 },
    { lat: 49.0449522, lng: 21.5874371 },
    { lat: 49.0444259, lng: 21.5875679 },
    { lat: 49.0440745, lng: 21.5879538 },
    { lat: 49.0436181, lng: 21.5876077 },
    { lat: 49.0428887, lng: 21.5879556 },
    { lat: 49.0421275, lng: 21.5885741 },
    { lat: 49.0412642, lng: 21.5887255 },
    { lat: 49.0409219, lng: 21.5886761 },
    { lat: 49.0397273, lng: 21.5888775 },
    { lat: 49.0387241, lng: 21.5892982 },
    { lat: 49.0384974, lng: 21.5893178 },
    { lat: 49.0382235, lng: 21.5888137 },
    { lat: 49.0379784, lng: 21.5886197 },
    { lat: 49.0370364, lng: 21.5883602 },
    { lat: 49.0367686, lng: 21.5882007 },
    { lat: 49.0365766, lng: 21.5886193 },
    { lat: 49.0363729, lng: 21.588901 },
    { lat: 49.0364937, lng: 21.5896064 },
    { lat: 49.036475, lng: 21.5902215 },
    { lat: 49.0363223, lng: 21.5908423 },
    { lat: 49.035569, lng: 21.5929999 },
    { lat: 49.0352045, lng: 21.5934909 },
    { lat: 49.0351251, lng: 21.5940697 },
    { lat: 49.0351186, lng: 21.5944474 },
    { lat: 49.0347768, lng: 21.5950161 },
    { lat: 49.0344739, lng: 21.5952568 },
    { lat: 49.0341286, lng: 21.595786 },
    { lat: 49.0334164, lng: 21.5963467 },
    { lat: 49.0331073, lng: 21.5962783 },
    { lat: 49.0328711, lng: 21.5971275 },
    { lat: 49.0326352, lng: 21.5985657 },
    { lat: 49.032695, lng: 21.5991732 },
    { lat: 49.0329222, lng: 21.5992977 },
    { lat: 49.0327217, lng: 21.600221 },
  ],
  JuskovaVoľa: [
    { lat: 48.8924195, lng: 21.5849571 },
    { lat: 48.8923943, lng: 21.5849394 },
    { lat: 48.8924197, lng: 21.5844046 },
    { lat: 48.8922934, lng: 21.584308 },
    { lat: 48.8922362, lng: 21.5841161 },
    { lat: 48.892032, lng: 21.5840581 },
    { lat: 48.8917503, lng: 21.5838391 },
    { lat: 48.8915433, lng: 21.5837645 },
    { lat: 48.8913585, lng: 21.5834689 },
    { lat: 48.8912234, lng: 21.5833853 },
    { lat: 48.8909464, lng: 21.5828961 },
    { lat: 48.8904755, lng: 21.582836 },
    { lat: 48.8901649, lng: 21.5821505 },
    { lat: 48.8901427, lng: 21.5819535 },
    { lat: 48.8902425, lng: 21.5814587 },
    { lat: 48.8901979, lng: 21.5804748 },
    { lat: 48.8901585, lng: 21.5802292 },
    { lat: 48.8900359, lng: 21.5800347 },
    { lat: 48.8898414, lng: 21.5792671 },
    { lat: 48.8899196, lng: 21.5786092 },
    { lat: 48.889875, lng: 21.5776553 },
    { lat: 48.8900159, lng: 21.5767459 },
    { lat: 48.8899131, lng: 21.5766507 },
    { lat: 48.8897378, lng: 21.5766545 },
    { lat: 48.8895716, lng: 21.5759519 },
    { lat: 48.8894223, lng: 21.5748796 },
    { lat: 48.889427, lng: 21.5748046 },
    { lat: 48.8895709, lng: 21.5746387 },
    { lat: 48.8897878, lng: 21.5745461 },
    { lat: 48.8899693, lng: 21.573963 },
    { lat: 48.8897586, lng: 21.5738332 },
    { lat: 48.8891922, lng: 21.5736942 },
    { lat: 48.8888479, lng: 21.5739703 },
    { lat: 48.888568, lng: 21.5740591 },
    { lat: 48.888362, lng: 21.5737367 },
    { lat: 48.887943, lng: 21.5738831 },
    { lat: 48.8876878, lng: 21.5742929 },
    { lat: 48.8873197, lng: 21.5747278 },
    { lat: 48.8871792, lng: 21.5750146 },
    { lat: 48.8869151, lng: 21.575298 },
    { lat: 48.8870122, lng: 21.5747937 },
    { lat: 48.8868164, lng: 21.5747127 },
    { lat: 48.8862811, lng: 21.5754397 },
    { lat: 48.8861349, lng: 21.5757255 },
    { lat: 48.8859274, lng: 21.5759516 },
    { lat: 48.8860164, lng: 21.5763527 },
    { lat: 48.8856443, lng: 21.5765261 },
    { lat: 48.8855729, lng: 21.5763083 },
    { lat: 48.8847494, lng: 21.5769084 },
    { lat: 48.8843945, lng: 21.5770052 },
    { lat: 48.884339, lng: 21.5766302 },
    { lat: 48.8838962, lng: 21.5754036 },
    { lat: 48.8841735, lng: 21.5755066 },
    { lat: 48.8842493, lng: 21.5749951 },
    { lat: 48.8841983, lng: 21.5746674 },
    { lat: 48.8840879, lng: 21.574623 },
    { lat: 48.8841372, lng: 21.5742952 },
    { lat: 48.8844964, lng: 21.5737783 },
    { lat: 48.8849331, lng: 21.5734599 },
    { lat: 48.8852342, lng: 21.572605 },
    { lat: 48.8851704, lng: 21.5725397 },
    { lat: 48.8852578, lng: 21.5718069 },
    { lat: 48.8855233, lng: 21.5718502 },
    { lat: 48.8857426, lng: 21.5708872 },
    { lat: 48.8855838, lng: 21.5706812 },
    { lat: 48.8854271, lng: 21.5702301 },
    { lat: 48.8855166, lng: 21.5699652 },
    { lat: 48.8856217, lng: 21.5699898 },
    { lat: 48.8856778, lng: 21.5693427 },
    { lat: 48.8856433, lng: 21.5689123 },
    { lat: 48.8854585, lng: 21.5687051 },
    { lat: 48.8850416, lng: 21.5687521 },
    { lat: 48.8848748, lng: 21.5685798 },
    { lat: 48.884598, lng: 21.5681022 },
    { lat: 48.8844103, lng: 21.5673135 },
    { lat: 48.8844177, lng: 21.5669305 },
    { lat: 48.8844858, lng: 21.5667542 },
    { lat: 48.8845602, lng: 21.5662792 },
    { lat: 48.8844596, lng: 21.5644632 },
    { lat: 48.8845833, lng: 21.5633798 },
    { lat: 48.8846697, lng: 21.5633324 },
    { lat: 48.8847843, lng: 21.5626879 },
    { lat: 48.8849535, lng: 21.562544 },
    { lat: 48.8851892, lng: 21.5613701 },
    { lat: 48.8852435, lng: 21.5609021 },
    { lat: 48.8848106, lng: 21.5605412 },
    { lat: 48.8846921, lng: 21.5602536 },
    { lat: 48.8846644, lng: 21.5600641 },
    { lat: 48.8844515, lng: 21.5596529 },
    { lat: 48.8844228, lng: 21.5589955 },
    { lat: 48.884055, lng: 21.5590032 },
    { lat: 48.8838664, lng: 21.5588617 },
    { lat: 48.8837336, lng: 21.5588592 },
    { lat: 48.8838099, lng: 21.5585304 },
    { lat: 48.8837226, lng: 21.5583108 },
    { lat: 48.8838772, lng: 21.5575222 },
    { lat: 48.8838851, lng: 21.5570827 },
    { lat: 48.8839696, lng: 21.5566932 },
    { lat: 48.8840119, lng: 21.5548426 },
    { lat: 48.8841803, lng: 21.5543094 },
    { lat: 48.8842998, lng: 21.5541583 },
    { lat: 48.8843749, lng: 21.5536264 },
    { lat: 48.8843712, lng: 21.5532202 },
    { lat: 48.8842786, lng: 21.5524533 },
    { lat: 48.8836896, lng: 21.5523929 },
    { lat: 48.8837984, lng: 21.5517037 },
    { lat: 48.883931, lng: 21.5513762 },
    { lat: 48.8840752, lng: 21.550788 },
    { lat: 48.8838486, lng: 21.5505378 },
    { lat: 48.8837675, lng: 21.5502532 },
    { lat: 48.8834452, lng: 21.5497929 },
    { lat: 48.8832908, lng: 21.5498294 },
    { lat: 48.8831033, lng: 21.5501998 },
    { lat: 48.8827982, lng: 21.5505096 },
    { lat: 48.882731, lng: 21.5505164 },
    { lat: 48.8820517, lng: 21.5502892 },
    { lat: 48.8813605, lng: 21.5497171 },
    { lat: 48.88131, lng: 21.5496085 },
    { lat: 48.8810234, lng: 21.5496536 },
    { lat: 48.8808337, lng: 21.5490407 },
    { lat: 48.8799911, lng: 21.5475301 },
    { lat: 48.8792419, lng: 21.5466186 },
    { lat: 48.8784368, lng: 21.5460354 },
    { lat: 48.8775131, lng: 21.5448873 },
    { lat: 48.8770926, lng: 21.5446598 },
    { lat: 48.8758133, lng: 21.5449021 },
    { lat: 48.8748644, lng: 21.5446465 },
    { lat: 48.8734621, lng: 21.543673 },
    { lat: 48.87311, lng: 21.5423716 },
    { lat: 48.8730798, lng: 21.5416223 },
    { lat: 48.8728026, lng: 21.5408093 },
    { lat: 48.8724468, lng: 21.5404415 },
    { lat: 48.8718213, lng: 21.5396112 },
    { lat: 48.8714059, lng: 21.5394871 },
    { lat: 48.8710912, lng: 21.5389429 },
    { lat: 48.8709429, lng: 21.5380012 },
    { lat: 48.8705256, lng: 21.5368564 },
    { lat: 48.8696792, lng: 21.5359359 },
    { lat: 48.8690354, lng: 21.5358457 },
    { lat: 48.868638, lng: 21.535645 },
    { lat: 48.8676833, lng: 21.5346277 },
    { lat: 48.8677428, lng: 21.5347378 },
    { lat: 48.8673603, lng: 21.5346605 },
    { lat: 48.8673567, lng: 21.5345764 },
    { lat: 48.8670373, lng: 21.5342646 },
    { lat: 48.8667708, lng: 21.5335561 },
    { lat: 48.8661744, lng: 21.5337602 },
    { lat: 48.8657209, lng: 21.5329592 },
    { lat: 48.8654166, lng: 21.5327461 },
    { lat: 48.8645649, lng: 21.5318733 },
    { lat: 48.8634994, lng: 21.5311645 },
    { lat: 48.8631983, lng: 21.5306685 },
    { lat: 48.8621508, lng: 21.5294752 },
    { lat: 48.8618199, lng: 21.5285681 },
    { lat: 48.8614189, lng: 21.5271606 },
    { lat: 48.8611959, lng: 21.5268415 },
    { lat: 48.8614395, lng: 21.5257971 },
    { lat: 48.861865, lng: 21.5250081 },
    { lat: 48.8621203, lng: 21.5244125 },
    { lat: 48.8620482, lng: 21.5239427 },
    { lat: 48.8618857, lng: 21.5234108 },
    { lat: 48.8615792, lng: 21.5229574 },
    { lat: 48.861825, lng: 21.5223807 },
    { lat: 48.8619814, lng: 21.5218315 },
    { lat: 48.8620477, lng: 21.5203288 },
    { lat: 48.8619096, lng: 21.5198244 },
    { lat: 48.8616303, lng: 21.5191862 },
    { lat: 48.8611723, lng: 21.5185938 },
    { lat: 48.8607201, lng: 21.5177687 },
    { lat: 48.8605232, lng: 21.5166274 },
    { lat: 48.8604878, lng: 21.5161384 },
    { lat: 48.8601649, lng: 21.5152294 },
    { lat: 48.8599627, lng: 21.5149261 },
    { lat: 48.8597908, lng: 21.5143896 },
    { lat: 48.8596189, lng: 21.5127804 },
    { lat: 48.8596639, lng: 21.5121949 },
    { lat: 48.8596404, lng: 21.511132 },
    { lat: 48.8597809, lng: 21.5098703 },
    { lat: 48.8595399, lng: 21.5090804 },
    { lat: 48.8595302, lng: 21.5086586 },
    { lat: 48.8594006, lng: 21.507633 },
    { lat: 48.8595055, lng: 21.5058698 },
    { lat: 48.8596089, lng: 21.5051566 },
    { lat: 48.8598886, lng: 21.5048298 },
    { lat: 48.8601163, lng: 21.5044507 },
    { lat: 48.8605459, lng: 21.5035055 },
    { lat: 48.8608755, lng: 21.5025607 },
    { lat: 48.8612848, lng: 21.501689 },
    { lat: 48.861476, lng: 21.501153 },
    { lat: 48.861309, lng: 21.500201 },
    { lat: 48.86106, lng: 21.499565 },
    { lat: 48.860525, lng: 21.497851 },
    { lat: 48.859741, lng: 21.496796 },
    { lat: 48.859547, lng: 21.496596 },
    { lat: 48.859213, lng: 21.496353 },
    { lat: 48.859007, lng: 21.496262 },
    { lat: 48.85868, lng: 21.496184 },
    { lat: 48.858348, lng: 21.496018 },
    { lat: 48.857703, lng: 21.495534 },
    { lat: 48.857485, lng: 21.495415 },
    { lat: 48.857129, lng: 21.495528 },
    { lat: 48.856487, lng: 21.4957 },
    { lat: 48.856334, lng: 21.495768 },
    { lat: 48.856096, lng: 21.495828 },
    { lat: 48.855901, lng: 21.49573 },
    { lat: 48.855596, lng: 21.495539 },
    { lat: 48.85526, lng: 21.495351 },
    { lat: 48.855028, lng: 21.494826 },
    { lat: 48.854637, lng: 21.494534 },
    { lat: 48.854252, lng: 21.494362 },
    { lat: 48.853912, lng: 21.494219 },
    { lat: 48.853292, lng: 21.494016 },
    { lat: 48.85265, lng: 21.493835 },
    { lat: 48.851849, lng: 21.493417 },
    { lat: 48.851176, lng: 21.493127 },
    { lat: 48.850329, lng: 21.493348 },
    { lat: 48.849977, lng: 21.493489 },
    { lat: 48.849605, lng: 21.493737 },
    { lat: 48.848995, lng: 21.493729 },
    { lat: 48.848631, lng: 21.493862 },
    { lat: 48.848499, lng: 21.493934 },
    { lat: 48.848318, lng: 21.493953 },
    { lat: 48.84794, lng: 21.494099 },
    { lat: 48.84768, lng: 21.494285 },
    { lat: 48.847384, lng: 21.494397 },
    { lat: 48.847232, lng: 21.494522 },
    { lat: 48.846923, lng: 21.494816 },
    { lat: 48.846566, lng: 21.495138 },
    { lat: 48.84618, lng: 21.495513 },
    { lat: 48.845588, lng: 21.495825 },
    { lat: 48.844895, lng: 21.496286 },
    { lat: 48.844791, lng: 21.496368 },
    { lat: 48.844675, lng: 21.496509 },
    { lat: 48.84412, lng: 21.497303 },
    { lat: 48.843711, lng: 21.497715 },
    { lat: 48.842429, lng: 21.499443 },
    { lat: 48.841965, lng: 21.500153 },
    { lat: 48.841193, lng: 21.500435 },
    { lat: 48.840845, lng: 21.500723 },
    { lat: 48.840277, lng: 21.501329 },
    { lat: 48.839587, lng: 21.501834 },
    { lat: 48.839135, lng: 21.502797 },
    { lat: 48.838993, lng: 21.503272 },
    { lat: 48.838768, lng: 21.503743 },
    { lat: 48.838545, lng: 21.50407 },
    { lat: 48.838401, lng: 21.504587 },
    { lat: 48.837831, lng: 21.505035 },
    { lat: 48.837543, lng: 21.505373 },
    { lat: 48.837175, lng: 21.505966 },
    { lat: 48.836741, lng: 21.506352 },
    { lat: 48.836351, lng: 21.507138 },
    { lat: 48.836026, lng: 21.507713 },
    { lat: 48.835854, lng: 21.508101 },
    { lat: 48.835644, lng: 21.509672 },
    { lat: 48.835505, lng: 21.510948 },
    { lat: 48.835531, lng: 21.511979 },
    { lat: 48.835733, lng: 21.512892 },
    { lat: 48.836127, lng: 21.51359 },
    { lat: 48.836323, lng: 21.514587 },
    { lat: 48.836235, lng: 21.515221 },
    { lat: 48.835665, lng: 21.516069 },
    { lat: 48.83564, lng: 21.517172 },
    { lat: 48.835644, lng: 21.517561 },
    { lat: 48.835223, lng: 21.518364 },
    { lat: 48.83482, lng: 21.519154 },
    { lat: 48.834613, lng: 21.519541 },
    { lat: 48.8348635, lng: 21.5213084 },
    { lat: 48.8353141, lng: 21.5226657 },
    { lat: 48.8353983, lng: 21.5232113 },
    { lat: 48.8355633, lng: 21.5237477 },
    { lat: 48.8358218, lng: 21.5242879 },
    { lat: 48.8358238, lng: 21.5248364 },
    { lat: 48.8358664, lng: 21.5250665 },
    { lat: 48.8359378, lng: 21.525279 },
    { lat: 48.8361249, lng: 21.5255346 },
    { lat: 48.8362723, lng: 21.5259246 },
    { lat: 48.8363071, lng: 21.5262334 },
    { lat: 48.8365701, lng: 21.5267831 },
    { lat: 48.8365913, lng: 21.5271735 },
    { lat: 48.8365384, lng: 21.5274838 },
    { lat: 48.8371005, lng: 21.5279081 },
    { lat: 48.8377422, lng: 21.5292192 },
    { lat: 48.8381087, lng: 21.5296485 },
    { lat: 48.8382789, lng: 21.5296113 },
    { lat: 48.8383937, lng: 21.5303674 },
    { lat: 48.8386913, lng: 21.5310981 },
    { lat: 48.8387656, lng: 21.5314554 },
    { lat: 48.8391281, lng: 21.5318968 },
    { lat: 48.839476, lng: 21.5322581 },
    { lat: 48.8399805, lng: 21.5325555 },
    { lat: 48.8408042, lng: 21.5329165 },
    { lat: 48.8423547, lng: 21.5341051 },
    { lat: 48.8432069, lng: 21.5345972 },
    { lat: 48.8475369, lng: 21.5346451 },
    { lat: 48.8480463, lng: 21.5370908 },
    { lat: 48.849107, lng: 21.5389098 },
    { lat: 48.8493838, lng: 21.5400157 },
    { lat: 48.8497397, lng: 21.5401843 },
    { lat: 48.8499116, lng: 21.5404171 },
    { lat: 48.8499233, lng: 21.5410623 },
    { lat: 48.849809, lng: 21.5410422 },
    { lat: 48.8497823, lng: 21.5411729 },
    { lat: 48.8496104, lng: 21.5411798 },
    { lat: 48.8490059, lng: 21.5423592 },
    { lat: 48.8486718, lng: 21.5425588 },
    { lat: 48.8484843, lng: 21.5429751 },
    { lat: 48.8485401, lng: 21.5449445 },
    { lat: 48.848579, lng: 21.5452432 },
    { lat: 48.8495757, lng: 21.5476613 },
    { lat: 48.8502813, lng: 21.5479502 },
    { lat: 48.8504815, lng: 21.5483863 },
    { lat: 48.8508386, lng: 21.5493909 },
    { lat: 48.8510618, lng: 21.5498441 },
    { lat: 48.8511887, lng: 21.5499158 },
    { lat: 48.8509255, lng: 21.5568042 },
    { lat: 48.852179, lng: 21.5583639 },
    { lat: 48.8526764, lng: 21.5585303 },
    { lat: 48.8534785, lng: 21.5593648 },
    { lat: 48.8536017, lng: 21.5598223 },
    { lat: 48.8539074, lng: 21.560128 },
    { lat: 48.8541628, lng: 21.5602894 },
    { lat: 48.854278, lng: 21.5607032 },
    { lat: 48.8546472, lng: 21.5614639 },
    { lat: 48.8550521, lng: 21.5618593 },
    { lat: 48.8554824, lng: 21.5620794 },
    { lat: 48.8568693, lng: 21.5637401 },
    { lat: 48.8572694, lng: 21.5638497 },
    { lat: 48.8572406, lng: 21.5666628 },
    { lat: 48.8579701, lng: 21.5674747 },
    { lat: 48.8591141, lng: 21.5694307 },
    { lat: 48.862166, lng: 21.570524 },
    { lat: 48.8635587, lng: 21.570516 },
    { lat: 48.8638045, lng: 21.5706234 },
    { lat: 48.8639927, lng: 21.5705505 },
    { lat: 48.8648733, lng: 21.5706591 },
    { lat: 48.8650477, lng: 21.570581 },
    { lat: 48.8652693, lng: 21.5705796 },
    { lat: 48.8654625, lng: 21.5706851 },
    { lat: 48.865593, lng: 21.5705616 },
    { lat: 48.8657073, lng: 21.5706275 },
    { lat: 48.8659425, lng: 21.570633 },
    { lat: 48.8662665, lng: 21.5708712 },
    { lat: 48.8666692, lng: 21.5709864 },
    { lat: 48.8668598, lng: 21.571157 },
    { lat: 48.8669831, lng: 21.5711416 },
    { lat: 48.8675478, lng: 21.5716648 },
    { lat: 48.8676505, lng: 21.5719955 },
    { lat: 48.8678797, lng: 21.5722999 },
    { lat: 48.8681349, lng: 21.5723799 },
    { lat: 48.868361, lng: 21.5725383 },
    { lat: 48.8686687, lng: 21.5729498 },
    { lat: 48.8687256, lng: 21.573137 },
    { lat: 48.8687273, lng: 21.5733567 },
    { lat: 48.8688323, lng: 21.5734964 },
    { lat: 48.8689098, lng: 21.573931 },
    { lat: 48.8690336, lng: 21.5742915 },
    { lat: 48.8693748, lng: 21.5745025 },
    { lat: 48.8694597, lng: 21.5746382 },
    { lat: 48.8694915, lng: 21.5749735 },
    { lat: 48.8696817, lng: 21.5754162 },
    { lat: 48.8698937, lng: 21.5755963 },
    { lat: 48.8701311, lng: 21.5756282 },
    { lat: 48.8704327, lng: 21.5759929 },
    { lat: 48.87103, lng: 21.5763472 },
    { lat: 48.8711136, lng: 21.5765223 },
    { lat: 48.8712175, lng: 21.5765364 },
    { lat: 48.8717457, lng: 21.577002 },
    { lat: 48.8720093, lng: 21.5770947 },
    { lat: 48.8722759, lng: 21.5774439 },
    { lat: 48.8724664, lng: 21.5773451 },
    { lat: 48.8726019, lng: 21.5774751 },
    { lat: 48.8728754, lng: 21.5773267 },
    { lat: 48.8730382, lng: 21.577313 },
    { lat: 48.8732094, lng: 21.5773995 },
    { lat: 48.8735107, lng: 21.5773043 },
    { lat: 48.8736048, lng: 21.5772118 },
    { lat: 48.8737243, lng: 21.5772616 },
    { lat: 48.8739541, lng: 21.57715 },
    { lat: 48.8740686, lng: 21.5771819 },
    { lat: 48.8742775, lng: 21.5770455 },
    { lat: 48.8748342, lng: 21.5771601 },
    { lat: 48.8753096, lng: 21.5780273 },
    { lat: 48.8754384, lng: 21.5784415 },
    { lat: 48.8753899, lng: 21.5786048 },
    { lat: 48.8752348, lng: 21.5800538 },
    { lat: 48.8754245, lng: 21.579672 },
    { lat: 48.8755564, lng: 21.5795227 },
    { lat: 48.875696, lng: 21.5796517 },
    { lat: 48.8757754, lng: 21.5797927 },
    { lat: 48.8758266, lng: 21.5801726 },
    { lat: 48.8759625, lng: 21.5804034 },
    { lat: 48.8761554, lng: 21.581188 },
    { lat: 48.8761442, lng: 21.5817885 },
    { lat: 48.8760366, lng: 21.5825697 },
    { lat: 48.8761792, lng: 21.5830402 },
    { lat: 48.8763096, lng: 21.5832026 },
    { lat: 48.8762158, lng: 21.583549 },
    { lat: 48.8759271, lng: 21.5842431 },
    { lat: 48.8759598, lng: 21.5844519 },
    { lat: 48.8758536, lng: 21.5851228 },
    { lat: 48.8759482, lng: 21.5853587 },
    { lat: 48.8762769, lng: 21.5855742 },
    { lat: 48.8762538, lng: 21.5851843 },
    { lat: 48.8763176, lng: 21.585098 },
    { lat: 48.8764816, lng: 21.5854056 },
    { lat: 48.8767911, lng: 21.5862135 },
    { lat: 48.8768262, lng: 21.5861802 },
    { lat: 48.8773566, lng: 21.5868424 },
    { lat: 48.8774446, lng: 21.5872153 },
    { lat: 48.8773921, lng: 21.5878771 },
    { lat: 48.8776952, lng: 21.5878901 },
    { lat: 48.8777892, lng: 21.5880721 },
    { lat: 48.8779583, lng: 21.5879226 },
    { lat: 48.8780904, lng: 21.5874523 },
    { lat: 48.8789, lng: 21.5871994 },
    { lat: 48.8792196, lng: 21.5877597 },
    { lat: 48.8796183, lng: 21.5882599 },
    { lat: 48.8800758, lng: 21.5885694 },
    { lat: 48.8804896, lng: 21.5885622 },
    { lat: 48.8806898, lng: 21.5886383 },
    { lat: 48.8808334, lng: 21.588578 },
    { lat: 48.8809921, lng: 21.5882611 },
    { lat: 48.8811284, lng: 21.5877869 },
    { lat: 48.8815429, lng: 21.5868348 },
    { lat: 48.8823609, lng: 21.5881657 },
    { lat: 48.8824275, lng: 21.5880928 },
    { lat: 48.882786, lng: 21.5887661 },
    { lat: 48.882742, lng: 21.5888369 },
    { lat: 48.8829934, lng: 21.5894624 },
    { lat: 48.8832385, lng: 21.5892887 },
    { lat: 48.8834691, lng: 21.5892474 },
    { lat: 48.8835329, lng: 21.5893624 },
    { lat: 48.8837671, lng: 21.5891225 },
    { lat: 48.8838728, lng: 21.5890821 },
    { lat: 48.8839912, lng: 21.5891353 },
    { lat: 48.8842658, lng: 21.5890994 },
    { lat: 48.8845743, lng: 21.5892215 },
    { lat: 48.8847734, lng: 21.5896227 },
    { lat: 48.8853956, lng: 21.5892614 },
    { lat: 48.8860967, lng: 21.5896794 },
    { lat: 48.8866197, lng: 21.5901227 },
    { lat: 48.886986, lng: 21.5903466 },
    { lat: 48.8869239, lng: 21.590495 },
    { lat: 48.8869031, lng: 21.5910845 },
    { lat: 48.8869777, lng: 21.5913342 },
    { lat: 48.8872321, lng: 21.5917827 },
    { lat: 48.8873681, lng: 21.5917371 },
    { lat: 48.8874055, lng: 21.5915066 },
    { lat: 48.8872967, lng: 21.5912319 },
    { lat: 48.8872702, lng: 21.5909737 },
    { lat: 48.8891084, lng: 21.5901979 },
    { lat: 48.8894298, lng: 21.5900196 },
    { lat: 48.8896599, lng: 21.5898014 },
    { lat: 48.889941, lng: 21.588951 },
    { lat: 48.8898557, lng: 21.5888356 },
    { lat: 48.890597, lng: 21.5882281 },
    { lat: 48.8907337, lng: 21.5883287 },
    { lat: 48.8910499, lng: 21.5879648 },
    { lat: 48.8911231, lng: 21.5874034 },
    { lat: 48.8909578, lng: 21.5871691 },
    { lat: 48.8912821, lng: 21.5865304 },
    { lat: 48.8913321, lng: 21.5862684 },
    { lat: 48.8914202, lng: 21.5860821 },
    { lat: 48.8919534, lng: 21.5854297 },
    { lat: 48.8921282, lng: 21.5853659 },
    { lat: 48.8924195, lng: 21.5849571 },
  ],
  Zlatník: [
    { lat: 48.9474914, lng: 21.5050638 },
    { lat: 48.9475012, lng: 21.5054043 },
    { lat: 48.9473769, lng: 21.5057003 },
    { lat: 48.9478919, lng: 21.5075016 },
    { lat: 48.9485018, lng: 21.5091166 },
    { lat: 48.9485456, lng: 21.5095837 },
    { lat: 48.9482416, lng: 21.5109916 },
    { lat: 48.9482029, lng: 21.5113392 },
    { lat: 48.948312, lng: 21.5122029 },
    { lat: 48.9485472, lng: 21.5129932 },
    { lat: 48.94888, lng: 21.5137724 },
    { lat: 48.9491409, lng: 21.5151839 },
    { lat: 48.9493287, lng: 21.5155746 },
    { lat: 48.9496563, lng: 21.5160596 },
    { lat: 48.9495627, lng: 21.5167624 },
    { lat: 48.9492049, lng: 21.5174281 },
    { lat: 48.9491046, lng: 21.5178209 },
    { lat: 48.9490965, lng: 21.5183212 },
    { lat: 48.9494617, lng: 21.5191416 },
    { lat: 48.9494674, lng: 21.5195751 },
    { lat: 48.9498435, lng: 21.5197405 },
    { lat: 48.9504897, lng: 21.5201935 },
    { lat: 48.9507018, lng: 21.5205297 },
    { lat: 48.9508748, lng: 21.5211899 },
    { lat: 48.9513643, lng: 21.5220642 },
    { lat: 48.9517146, lng: 21.5229479 },
    { lat: 48.9523725, lng: 21.5238864 },
    { lat: 48.95243, lng: 21.5241278 },
    { lat: 48.9528316, lng: 21.5249181 },
    { lat: 48.952888, lng: 21.5257159 },
    { lat: 48.9533955, lng: 21.5275371 },
    { lat: 48.953518, lng: 21.5285207 },
    { lat: 48.9543021, lng: 21.530777 },
    { lat: 48.9543341, lng: 21.5312632 },
    { lat: 48.9543006, lng: 21.5323111 },
    { lat: 48.9543739, lng: 21.5331054 },
    { lat: 48.9545054, lng: 21.5332626 },
    { lat: 48.9545835, lng: 21.5337898 },
    { lat: 48.9548806, lng: 21.5349872 },
    { lat: 48.9549288, lng: 21.5354975 },
    { lat: 48.9550345, lng: 21.5356928 },
    { lat: 48.9551085, lng: 21.5363553 },
    { lat: 48.9550825, lng: 21.5368303 },
    { lat: 48.9552982, lng: 21.5372533 },
    { lat: 48.9554515, lng: 21.5373879 },
    { lat: 48.9558882, lng: 21.5379892 },
    { lat: 48.9561355, lng: 21.5382551 },
    { lat: 48.9562625, lng: 21.538293 },
    { lat: 48.9569809, lng: 21.5391855 },
    { lat: 48.9571163, lng: 21.5392798 },
    { lat: 48.9572167, lng: 21.5392453 },
    { lat: 48.957552, lng: 21.5393294 },
    { lat: 48.9577817, lng: 21.5399714 },
    { lat: 48.9585677, lng: 21.5409469 },
    { lat: 48.9593823, lng: 21.5415031 },
    { lat: 48.9603414, lng: 21.5423206 },
    { lat: 48.9608433, lng: 21.5431948 },
    { lat: 48.9614841, lng: 21.5440549 },
    { lat: 48.9620383, lng: 21.5457117 },
    { lat: 48.9619426, lng: 21.5457867 },
    { lat: 48.9620941, lng: 21.5463665 },
    { lat: 48.9622215, lng: 21.5464201 },
    { lat: 48.9622674, lng: 21.5466929 },
    { lat: 48.9622953, lng: 21.5469646 },
    { lat: 48.9622653, lng: 21.547528 },
    { lat: 48.9623414, lng: 21.5479932 },
    { lat: 48.9622549, lng: 21.5491166 },
    { lat: 48.9625263, lng: 21.5497596 },
    { lat: 48.9626967, lng: 21.5509372 },
    { lat: 48.9629342, lng: 21.5517836 },
    { lat: 48.9632666, lng: 21.5525945 },
    { lat: 48.9633957, lng: 21.5531415 },
    { lat: 48.9635722, lng: 21.5530226 },
    { lat: 48.9640716, lng: 21.5549709 },
    { lat: 48.9643677, lng: 21.5548973 },
    { lat: 48.9651015, lng: 21.5552259 },
    { lat: 48.9655976, lng: 21.5556631 },
    { lat: 48.9658483, lng: 21.5555319 },
    { lat: 48.9667066, lng: 21.5570101 },
    { lat: 48.9666297, lng: 21.5573926 },
    { lat: 48.966832, lng: 21.5577457 },
    { lat: 48.9668811, lng: 21.5577332 },
    { lat: 48.9676813, lng: 21.5573389 },
    { lat: 48.9681809, lng: 21.5567027 },
    { lat: 48.9686434, lng: 21.5565506 },
    { lat: 48.968987, lng: 21.5563607 },
    { lat: 48.969561, lng: 21.5562059 },
    { lat: 48.9722936, lng: 21.5540003 },
    { lat: 48.9723392, lng: 21.5536682 },
    { lat: 48.9725483, lng: 21.5531848 },
    { lat: 48.9731312, lng: 21.5524252 },
    { lat: 48.9740297, lng: 21.5508536 },
    { lat: 48.9750041, lng: 21.5501337 },
    { lat: 48.9774081, lng: 21.5472896 },
    { lat: 48.9770898, lng: 21.5466646 },
    { lat: 48.9762175, lng: 21.5451717 },
    { lat: 48.9756946, lng: 21.5438709 },
    { lat: 48.9746241, lng: 21.5402712 },
    { lat: 48.9740978, lng: 21.5380539 },
    { lat: 48.9740946, lng: 21.5364686 },
    { lat: 48.9730856, lng: 21.5346888 },
    { lat: 48.9724642, lng: 21.5329384 },
    { lat: 48.9726684, lng: 21.5302143 },
    { lat: 48.9699984, lng: 21.5259314 },
    { lat: 48.9692602, lng: 21.5254172 },
    { lat: 48.9691579, lng: 21.5251031 },
    { lat: 48.9689221, lng: 21.5248016 },
    { lat: 48.9681196, lng: 21.523245 },
    { lat: 48.9659048, lng: 21.5170615 },
    { lat: 48.9652084, lng: 21.5155253 },
    { lat: 48.9642325, lng: 21.5139348 },
    { lat: 48.9637158, lng: 21.5125827 },
    { lat: 48.9631102, lng: 21.5112727 },
    { lat: 48.9627028, lng: 21.5106793 },
    { lat: 48.9616793, lng: 21.5097868 },
    { lat: 48.961553, lng: 21.5097403 },
    { lat: 48.9607406, lng: 21.5089811 },
    { lat: 48.9600323, lng: 21.5081803 },
    { lat: 48.9594682, lng: 21.5068651 },
    { lat: 48.9587945, lng: 21.5065984 },
    { lat: 48.958134, lng: 21.5067906 },
    { lat: 48.9573726, lng: 21.5065658 },
    { lat: 48.9569887, lng: 21.5066384 },
    { lat: 48.9547103, lng: 21.5059315 },
    { lat: 48.95358, lng: 21.5049657 },
    { lat: 48.9528988, lng: 21.5045936 },
    { lat: 48.9521423, lng: 21.503988 },
    { lat: 48.9512893, lng: 21.5036645 },
    { lat: 48.9509312, lng: 21.5034605 },
    { lat: 48.9507519, lng: 21.5034082 },
    { lat: 48.9492417, lng: 21.5043036 },
    { lat: 48.9487425, lng: 21.5042356 },
    { lat: 48.9487128, lng: 21.5043747 },
    { lat: 48.9481138, lng: 21.5047979 },
    { lat: 48.9478429, lng: 21.5051197 },
    { lat: 48.9474914, lng: 21.5050638 },
  ],
  Kladzany: [
    { lat: 48.875838, lng: 21.787139 },
    { lat: 48.875908, lng: 21.786894 },
    { lat: 48.875948, lng: 21.786765 },
    { lat: 48.876011, lng: 21.786627 },
    { lat: 48.876099, lng: 21.786473 },
    { lat: 48.876275, lng: 21.786319 },
    { lat: 48.87648, lng: 21.786264 },
    { lat: 48.876503, lng: 21.786113 },
    { lat: 48.876636, lng: 21.786032 },
    { lat: 48.876675, lng: 21.785892 },
    { lat: 48.87684, lng: 21.785847 },
    { lat: 48.876844, lng: 21.785762 },
    { lat: 48.876923, lng: 21.785632 },
    { lat: 48.876975, lng: 21.785598 },
    { lat: 48.877002, lng: 21.785457 },
    { lat: 48.877027, lng: 21.785284 },
    { lat: 48.877144, lng: 21.785311 },
    { lat: 48.877155, lng: 21.78533 },
    { lat: 48.877198, lng: 21.78529 },
    { lat: 48.877222, lng: 21.78522 },
    { lat: 48.877246, lng: 21.785119 },
    { lat: 48.877317, lng: 21.78514 },
    { lat: 48.877303, lng: 21.78501 },
    { lat: 48.877361, lng: 21.784969 },
    { lat: 48.877418, lng: 21.784824 },
    { lat: 48.877795, lng: 21.784844 },
    { lat: 48.877919, lng: 21.784736 },
    { lat: 48.877982, lng: 21.78482 },
    { lat: 48.878123, lng: 21.784803 },
    { lat: 48.878153, lng: 21.784877 },
    { lat: 48.87825, lng: 21.784783 },
    { lat: 48.878282, lng: 21.784633 },
    { lat: 48.878425, lng: 21.784575 },
    { lat: 48.878481, lng: 21.784506 },
    { lat: 48.87867, lng: 21.784498 },
    { lat: 48.87874, lng: 21.784554 },
    { lat: 48.878776, lng: 21.784441 },
    { lat: 48.87886, lng: 21.7844 },
    { lat: 48.878964, lng: 21.784338 },
    { lat: 48.878967, lng: 21.784245 },
    { lat: 48.879119, lng: 21.78416 },
    { lat: 48.879368, lng: 21.784118 },
    { lat: 48.879475, lng: 21.784028 },
    { lat: 48.8795, lng: 21.784086 },
    { lat: 48.879602, lng: 21.784066 },
    { lat: 48.879702, lng: 21.784068 },
    { lat: 48.879803, lng: 21.784197 },
    { lat: 48.88004, lng: 21.784029 },
    { lat: 48.88028, lng: 21.783785 },
    { lat: 48.880409, lng: 21.783777 },
    { lat: 48.880442, lng: 21.783827 },
    { lat: 48.880526, lng: 21.783832 },
    { lat: 48.880648, lng: 21.783704 },
    { lat: 48.880844, lng: 21.783727 },
    { lat: 48.881033, lng: 21.783569 },
    { lat: 48.88116, lng: 21.783527 },
    { lat: 48.881253, lng: 21.78342 },
    { lat: 48.8815082, lng: 21.7830435 },
    { lat: 48.881691, lng: 21.7828935 },
    { lat: 48.8822236, lng: 21.7828256 },
    { lat: 48.882289, lng: 21.7826565 },
    { lat: 48.8825709, lng: 21.7827292 },
    { lat: 48.8827138, lng: 21.7826841 },
    { lat: 48.8830805, lng: 21.7823825 },
    { lat: 48.8818096, lng: 21.7778972 },
    { lat: 48.8818119, lng: 21.7778134 },
    { lat: 48.8819195, lng: 21.7777665 },
    { lat: 48.8820251, lng: 21.7772794 },
    { lat: 48.8824414, lng: 21.7771188 },
    { lat: 48.8830696, lng: 21.776625 },
    { lat: 48.8837946, lng: 21.7765104 },
    { lat: 48.8837999, lng: 21.7764543 },
    { lat: 48.8841285, lng: 21.7762007 },
    { lat: 48.8840641, lng: 21.7758063 },
    { lat: 48.8843436, lng: 21.774933 },
    { lat: 48.8845053, lng: 21.7747069 },
    { lat: 48.8846095, lng: 21.774691 },
    { lat: 48.8848997, lng: 21.774475 },
    { lat: 48.8847741, lng: 21.7740201 },
    { lat: 48.8846481, lng: 21.7738911 },
    { lat: 48.8846283, lng: 21.7736947 },
    { lat: 48.8845371, lng: 21.7736065 },
    { lat: 48.8844385, lng: 21.7736665 },
    { lat: 48.8843672, lng: 21.7738159 },
    { lat: 48.8843279, lng: 21.7737808 },
    { lat: 48.8844068, lng: 21.7735286 },
    { lat: 48.8843294, lng: 21.7732756 },
    { lat: 48.8840821, lng: 21.7731143 },
    { lat: 48.8865645, lng: 21.7685725 },
    { lat: 48.8868484, lng: 21.7679919 },
    { lat: 48.8873973, lng: 21.7665869 },
    { lat: 48.8882586, lng: 21.7654096 },
    { lat: 48.8892217, lng: 21.7637103 },
    { lat: 48.892012, lng: 21.7628826 },
    { lat: 48.8924175, lng: 21.7629001 },
    { lat: 48.8928606, lng: 21.7626823 },
    { lat: 48.8930724, lng: 21.7623115 },
    { lat: 48.8954075, lng: 21.7573586 },
    { lat: 48.8987686, lng: 21.7550926 },
    { lat: 48.9001692, lng: 21.7550087 },
    { lat: 48.9030874, lng: 21.7550046 },
    { lat: 48.9036388, lng: 21.7549317 },
    { lat: 48.9055064, lng: 21.7535744 },
    { lat: 48.9062047, lng: 21.7533545 },
    { lat: 48.9063249, lng: 21.7531286 },
    { lat: 48.9066476, lng: 21.7527803 },
    { lat: 48.9077351, lng: 21.7517963 },
    { lat: 48.9081977, lng: 21.7512549 },
    { lat: 48.9081887, lng: 21.7509944 },
    { lat: 48.9082293, lng: 21.7507927 },
    { lat: 48.9081896, lng: 21.7504346 },
    { lat: 48.9079976, lng: 21.7498173 },
    { lat: 48.9073165, lng: 21.7486977 },
    { lat: 48.9069084, lng: 21.7483221 },
    { lat: 48.9067625, lng: 21.748464 },
    { lat: 48.9062135, lng: 21.748166 },
    { lat: 48.9055058, lng: 21.7476603 },
    { lat: 48.9051012, lng: 21.7472473 },
    { lat: 48.9048505, lng: 21.7467906 },
    { lat: 48.9048945, lng: 21.7466284 },
    { lat: 48.9047317, lng: 21.7461717 },
    { lat: 48.9045532, lng: 21.746016 },
    { lat: 48.9041765, lng: 21.7455115 },
    { lat: 48.9035159, lng: 21.7452104 },
    { lat: 48.9032909, lng: 21.7449588 },
    { lat: 48.9020035, lng: 21.7439297 },
    { lat: 48.9015432, lng: 21.7434541 },
    { lat: 48.9011251, lng: 21.7434217 },
    { lat: 48.9005278, lng: 21.7425818 },
    { lat: 48.9003625, lng: 21.7420298 },
    { lat: 48.8998292, lng: 21.7413109 },
    { lat: 48.8985507, lng: 21.739946 },
    { lat: 48.8984797, lng: 21.7401924 },
    { lat: 48.8981023, lng: 21.7408051 },
    { lat: 48.898057, lng: 21.7422728 },
    { lat: 48.8978911, lng: 21.7428436 },
    { lat: 48.8978547, lng: 21.7433364 },
    { lat: 48.8980992, lng: 21.7440752 },
    { lat: 48.8978946, lng: 21.744333 },
    { lat: 48.8953625, lng: 21.7452283 },
    { lat: 48.893287, lng: 21.7460445 },
    { lat: 48.8931934, lng: 21.7478539 },
    { lat: 48.893134, lng: 21.7478632 },
    { lat: 48.8882867, lng: 21.7463414 },
    { lat: 48.8884388, lng: 21.7443631 },
    { lat: 48.888384, lng: 21.7442892 },
    { lat: 48.8883799, lng: 21.7437521 },
    { lat: 48.8883043, lng: 21.7437042 },
    { lat: 48.8877733, lng: 21.7440459 },
    { lat: 48.887824, lng: 21.7445508 },
    { lat: 48.8877177, lng: 21.7450839 },
    { lat: 48.8873544, lng: 21.7455987 },
    { lat: 48.887039, lng: 21.7457495 },
    { lat: 48.8865987, lng: 21.7456913 },
    { lat: 48.8862865, lng: 21.7455806 },
    { lat: 48.8857204, lng: 21.7451076 },
    { lat: 48.8852647, lng: 21.7445575 },
    { lat: 48.8847114, lng: 21.7435471 },
    { lat: 48.8842706, lng: 21.7439837 },
    { lat: 48.8803009, lng: 21.7417137 },
    { lat: 48.8785697, lng: 21.7408345 },
    { lat: 48.87733, lng: 21.7402871 },
    { lat: 48.8773288, lng: 21.7402868 },
    { lat: 48.8773517, lng: 21.7411818 },
    { lat: 48.8773825, lng: 21.7421374 },
    { lat: 48.8775904, lng: 21.7450412 },
    { lat: 48.8776128, lng: 21.7465234 },
    { lat: 48.8775239, lng: 21.7471536 },
    { lat: 48.8773996, lng: 21.7476012 },
    { lat: 48.8774444, lng: 21.7476299 },
    { lat: 48.8773665, lng: 21.7477497 },
    { lat: 48.8770401, lng: 21.7491846 },
    { lat: 48.876922, lng: 21.7499924 },
    { lat: 48.8773076, lng: 21.7500665 },
    { lat: 48.8773224, lng: 21.7501999 },
    { lat: 48.8771506, lng: 21.7512669 },
    { lat: 48.8770361, lng: 21.7523379 },
    { lat: 48.8774933, lng: 21.752571 },
    { lat: 48.8774004, lng: 21.7531406 },
    { lat: 48.877249, lng: 21.7531119 },
    { lat: 48.8770888, lng: 21.7540753 },
    { lat: 48.8767475, lng: 21.75414 },
    { lat: 48.8767136, lng: 21.7544802 },
    { lat: 48.8765513, lng: 21.7545119 },
    { lat: 48.8763358, lng: 21.7555432 },
    { lat: 48.8761252, lng: 21.7561361 },
    { lat: 48.8760573, lng: 21.7561953 },
    { lat: 48.8755771, lng: 21.7562513 },
    { lat: 48.875797, lng: 21.758179 },
    { lat: 48.8755549, lng: 21.7584296 },
    { lat: 48.8758756, lng: 21.7599204 },
    { lat: 48.8765741, lng: 21.7599998 },
    { lat: 48.87679, lng: 21.7603391 },
    { lat: 48.8767847, lng: 21.7607719 },
    { lat: 48.8766936, lng: 21.7614526 },
    { lat: 48.8773813, lng: 21.7617196 },
    { lat: 48.8780261, lng: 21.7615978 },
    { lat: 48.8784572, lng: 21.7616931 },
    { lat: 48.878917, lng: 21.7618493 },
    { lat: 48.8793256, lng: 21.7620736 },
    { lat: 48.8797322, lng: 21.7623934 },
    { lat: 48.8800525, lng: 21.7625058 },
    { lat: 48.8796498, lng: 21.7635318 },
    { lat: 48.8794605, lng: 21.763816 },
    { lat: 48.8789662, lng: 21.763925 },
    { lat: 48.8788605, lng: 21.7640122 },
    { lat: 48.8784864, lng: 21.7639928 },
    { lat: 48.878194, lng: 21.764295 },
    { lat: 48.8780752, lng: 21.7643462 },
    { lat: 48.8779303, lng: 21.7646556 },
    { lat: 48.8777911, lng: 21.7647137 },
    { lat: 48.877744, lng: 21.7650189 },
    { lat: 48.8770741, lng: 21.7664864 },
    { lat: 48.876923, lng: 21.7663862 },
    { lat: 48.8766667, lng: 21.7665275 },
    { lat: 48.8762776, lng: 21.7665787 },
    { lat: 48.8762122, lng: 21.7666988 },
    { lat: 48.8762634, lng: 21.7668433 },
    { lat: 48.8762309, lng: 21.7670218 },
    { lat: 48.8760889, lng: 21.767306 },
    { lat: 48.8761476, lng: 21.7687245 },
    { lat: 48.8760543, lng: 21.7694057 },
    { lat: 48.876143, lng: 21.7701233 },
    { lat: 48.8761116, lng: 21.7703021 },
    { lat: 48.8759119, lng: 21.770281 },
    { lat: 48.8760187, lng: 21.7714739 },
    { lat: 48.8759025, lng: 21.772358 },
    { lat: 48.8760244, lng: 21.7725029 },
    { lat: 48.8761643, lng: 21.7731946 },
    { lat: 48.8762687, lng: 21.7732754 },
    { lat: 48.8763704, lng: 21.7750852 },
    { lat: 48.8761836, lng: 21.7752992 },
    { lat: 48.8759623, lng: 21.7757404 },
    { lat: 48.8750863, lng: 21.7768237 },
    { lat: 48.8736181, lng: 21.7789106 },
    { lat: 48.8734307, lng: 21.7794398 },
    { lat: 48.87313, lng: 21.7799029 },
    { lat: 48.8732381, lng: 21.7803273 },
    { lat: 48.8736134, lng: 21.781217 },
    { lat: 48.8738572, lng: 21.7820632 },
    { lat: 48.8740536, lng: 21.7823402 },
    { lat: 48.874241, lng: 21.7828345 },
    { lat: 48.8749224, lng: 21.7850114 },
    { lat: 48.8757119, lng: 21.7870162 },
    { lat: 48.875838, lng: 21.787139 },
  ],
  HanušovcenadTopľou: [
    { lat: 49.0396194, lng: 21.4966397 },
    { lat: 49.0387077, lng: 21.4948633 },
    { lat: 49.0384653, lng: 21.4946058 },
    { lat: 49.0382185, lng: 21.4950274 },
    { lat: 49.0375946, lng: 21.4956328 },
    { lat: 49.0370222, lng: 21.4969018 },
    { lat: 49.0362894, lng: 21.4981437 },
    { lat: 49.035477, lng: 21.4988465 },
    { lat: 49.0348867, lng: 21.4990645 },
    { lat: 49.034616, lng: 21.4993105 },
    { lat: 49.0341671, lng: 21.5000186 },
    { lat: 49.0338424, lng: 21.4996044 },
    { lat: 49.0333286, lng: 21.5007955 },
    { lat: 49.0324779, lng: 21.5003577 },
    { lat: 49.032368, lng: 21.5007271 },
    { lat: 49.0309301, lng: 21.4997824 },
    { lat: 49.03034, lng: 21.499725 },
    { lat: 49.0298996, lng: 21.4995089 },
    { lat: 49.0294582, lng: 21.4990645 },
    { lat: 49.0314145, lng: 21.4970924 },
    { lat: 49.0314936, lng: 21.4969488 },
    { lat: 49.0316831, lng: 21.4970452 },
    { lat: 49.0324806, lng: 21.4969175 },
    { lat: 49.0328604, lng: 21.4965879 },
    { lat: 49.0335867, lng: 21.4957778 },
    { lat: 49.0334741, lng: 21.4955845 },
    { lat: 49.0336916, lng: 21.4952445 },
    { lat: 49.0338655, lng: 21.4945976 },
    { lat: 49.0336269, lng: 21.494432 },
    { lat: 49.0336988, lng: 21.494277 },
    { lat: 49.0335061, lng: 21.4940688 },
    { lat: 49.0334075, lng: 21.4942801 },
    { lat: 49.0333565, lng: 21.4942279 },
    { lat: 49.0333426, lng: 21.4941302 },
    { lat: 49.0331608, lng: 21.4938321 },
    { lat: 49.032755, lng: 21.4946715 },
    { lat: 49.0326305, lng: 21.4948426 },
    { lat: 49.0325086, lng: 21.4946369 },
    { lat: 49.0323983, lng: 21.4947852 },
    { lat: 49.0323634, lng: 21.494752 },
    { lat: 49.0326403, lng: 21.4938512 },
    { lat: 49.0322684, lng: 21.4926564 },
    { lat: 49.0322136, lng: 21.4921921 },
    { lat: 49.0320413, lng: 21.4918005 },
    { lat: 49.0312408, lng: 21.4907951 },
    { lat: 49.031232, lng: 21.4904135 },
    { lat: 49.0312668, lng: 21.4902871 },
    { lat: 49.0315186, lng: 21.4900073 },
    { lat: 49.0317236, lng: 21.4896251 },
    { lat: 49.0316595, lng: 21.4893901 },
    { lat: 49.0311733, lng: 21.4887207 },
    { lat: 49.0309303, lng: 21.4886408 },
    { lat: 49.0308832, lng: 21.4885718 },
    { lat: 49.0307647, lng: 21.4879257 },
    { lat: 49.0308526, lng: 21.487505 },
    { lat: 49.0309406, lng: 21.487317 },
    { lat: 49.0312271, lng: 21.4871793 },
    { lat: 49.0314748, lng: 21.4872658 },
    { lat: 49.031639, lng: 21.4871057 },
    { lat: 49.0317539, lng: 21.4867657 },
    { lat: 49.0317876, lng: 21.4863697 },
    { lat: 49.0319404, lng: 21.4859268 },
    { lat: 49.0317535, lng: 21.4857646 },
    { lat: 49.0313011, lng: 21.4857983 },
    { lat: 49.0311068, lng: 21.485899 },
    { lat: 49.0310453, lng: 21.4858253 },
    { lat: 49.0308728, lng: 21.4853097 },
    { lat: 49.0310016, lng: 21.4846991 },
    { lat: 49.0311769, lng: 21.4845383 },
    { lat: 49.0313514, lng: 21.4846231 },
    { lat: 49.0315198, lng: 21.484565 },
    { lat: 49.0318338, lng: 21.4840246 },
    { lat: 49.0316833, lng: 21.4837165 },
    { lat: 49.0315571, lng: 21.4836317 },
    { lat: 49.0313009, lng: 21.4836426 },
    { lat: 49.031154, lng: 21.4834631 },
    { lat: 49.0310253, lng: 21.4830216 },
    { lat: 49.0310933, lng: 21.4825263 },
    { lat: 49.0307433, lng: 21.4820547 },
    { lat: 49.0305918, lng: 21.4819899 },
    { lat: 49.0302873, lng: 21.4814551 },
    { lat: 49.0302833, lng: 21.480848 },
    { lat: 49.030335, lng: 21.4807291 },
    { lat: 49.0305735, lng: 21.4804941 },
    { lat: 49.0309553, lng: 21.4803102 },
    { lat: 49.0310011, lng: 21.4801082 },
    { lat: 49.0310055, lng: 21.4795561 },
    { lat: 49.0312256, lng: 21.4786017 },
    { lat: 49.0314471, lng: 21.4781319 },
    { lat: 49.0315771, lng: 21.4772917 },
    { lat: 49.0316689, lng: 21.4770802 },
    { lat: 49.0318065, lng: 21.4770545 },
    { lat: 49.0318835, lng: 21.4768712 },
    { lat: 49.0324045, lng: 21.4764435 },
    { lat: 49.0325992, lng: 21.476058 },
    { lat: 49.0326265, lng: 21.4755975 },
    { lat: 49.0325597, lng: 21.4753503 },
    { lat: 49.0326859, lng: 21.475121 },
    { lat: 49.0330076, lng: 21.473908 },
    { lat: 49.0335005, lng: 21.4727446 },
    { lat: 49.0333559, lng: 21.4723716 },
    { lat: 49.0332265, lng: 21.47222 },
    { lat: 49.033186, lng: 21.4719853 },
    { lat: 49.0329234, lng: 21.4718128 },
    { lat: 49.0328563, lng: 21.4716684 },
    { lat: 49.0328502, lng: 21.4714171 },
    { lat: 49.0326319, lng: 21.4710685 },
    { lat: 49.0325629, lng: 21.4707041 },
    { lat: 49.0326429, lng: 21.4704366 },
    { lat: 49.0324008, lng: 21.4694897 },
    { lat: 49.0325084, lng: 21.4692487 },
    { lat: 49.0323176, lng: 21.4689094 },
    { lat: 49.0322964, lng: 21.4687569 },
    { lat: 49.0323435, lng: 21.4685168 },
    { lat: 49.0321695, lng: 21.4681426 },
    { lat: 49.0320598, lng: 21.4676996 },
    { lat: 49.0320267, lng: 21.4674831 },
    { lat: 49.0320106, lng: 21.4673981 },
    { lat: 49.0320648, lng: 21.4671291 },
    { lat: 49.0318647, lng: 21.4666262 },
    { lat: 49.0315233, lng: 21.4661839 },
    { lat: 49.0314597, lng: 21.4661638 },
    { lat: 49.0313187, lng: 21.4658778 },
    { lat: 49.031217, lng: 21.4658247 },
    { lat: 49.031227, lng: 21.465748 },
    { lat: 49.0310019, lng: 21.4655052 },
    { lat: 49.0307115, lng: 21.4650327 },
    { lat: 49.0306188, lng: 21.4647488 },
    { lat: 49.0305208, lng: 21.4646277 },
    { lat: 49.0303834, lng: 21.4646226 },
    { lat: 49.0300047, lng: 21.464171 },
    { lat: 49.0299143, lng: 21.4642004 },
    { lat: 49.0298597, lng: 21.4641402 },
    { lat: 49.0298261, lng: 21.4639474 },
    { lat: 49.0296972, lng: 21.4638123 },
    { lat: 49.0294295, lng: 21.4631127 },
    { lat: 49.0291677, lng: 21.4621901 },
    { lat: 49.0289432, lng: 21.4620911 },
    { lat: 49.0287629, lng: 21.4621244 },
    { lat: 49.0285296, lng: 21.4623009 },
    { lat: 49.0281355, lng: 21.4624499 },
    { lat: 49.0279559, lng: 21.462429 },
    { lat: 49.0277254, lng: 21.4625878 },
    { lat: 49.027652, lng: 21.4625762 },
    { lat: 49.0273978, lng: 21.4628094 },
    { lat: 49.0269371, lng: 21.4630647 },
    { lat: 49.0264237, lng: 21.463639 },
    { lat: 49.0259988, lng: 21.4633422 },
    { lat: 49.0253287, lng: 21.4636685 },
    { lat: 49.0246965, lng: 21.4633626 },
    { lat: 49.0242049, lng: 21.4620432 },
    { lat: 49.0232724, lng: 21.4618966 },
    { lat: 49.0227546, lng: 21.4616172 },
    { lat: 49.0216435, lng: 21.4615134 },
    { lat: 49.0208495, lng: 21.4609875 },
    { lat: 49.0202913, lng: 21.4608614 },
    { lat: 49.0197886, lng: 21.4602161 },
    { lat: 49.0192572, lng: 21.4598111 },
    { lat: 49.0192524, lng: 21.4595297 },
    { lat: 49.0187773, lng: 21.4592464 },
    { lat: 49.0185584, lng: 21.4594217 },
    { lat: 49.0183092, lng: 21.4590566 },
    { lat: 49.017808, lng: 21.459155 },
    { lat: 49.0176617, lng: 21.4590354 },
    { lat: 49.0173059, lng: 21.4594517 },
    { lat: 49.0169048, lng: 21.4595148 },
    { lat: 49.0165399, lng: 21.4596543 },
    { lat: 49.0148445, lng: 21.4596216 },
    { lat: 49.0142711, lng: 21.4594442 },
    { lat: 49.0140302, lng: 21.4592413 },
    { lat: 49.0135623, lng: 21.4590174 },
    { lat: 49.0126239, lng: 21.4580092 },
    { lat: 49.0121588, lng: 21.4572132 },
    { lat: 49.0111655, lng: 21.4572625 },
    { lat: 49.0106587, lng: 21.4566552 },
    { lat: 49.0083099, lng: 21.4543863 },
    { lat: 49.008224, lng: 21.4543426 },
    { lat: 49.0078827, lng: 21.4538931 },
    { lat: 49.0075601, lng: 21.453834 },
    { lat: 49.0074314, lng: 21.4536975 },
    { lat: 49.0075288, lng: 21.4528671 },
    { lat: 49.0078152, lng: 21.4516877 },
    { lat: 49.0078997, lng: 21.4508814 },
    { lat: 49.007803, lng: 21.4501069 },
    { lat: 49.0073003, lng: 21.4499948 },
    { lat: 49.0067688, lng: 21.4494395 },
    { lat: 49.0055451, lng: 21.4477097 },
    { lat: 49.005502, lng: 21.4468113 },
    { lat: 49.0048204, lng: 21.4461231 },
    { lat: 49.004368, lng: 21.4460844 },
    { lat: 49.0039112, lng: 21.4461616 },
    { lat: 49.0036492, lng: 21.4460526 },
    { lat: 49.0030144, lng: 21.4455249 },
    { lat: 49.0026298, lng: 21.4455884 },
    { lat: 49.001747, lng: 21.4458706 },
    { lat: 49.0012567, lng: 21.4464572 },
    { lat: 49.0009119, lng: 21.4471024 },
    { lat: 49.0005098, lng: 21.4475104 },
    { lat: 48.9996657, lng: 21.4490115 },
    { lat: 48.9990125, lng: 21.4494542 },
    { lat: 48.9989849, lng: 21.4505157 },
    { lat: 48.998822, lng: 21.451029 },
    { lat: 48.9980824, lng: 21.4515971 },
    { lat: 48.9979285, lng: 21.4518848 },
    { lat: 48.9977367, lng: 21.4522538 },
    { lat: 48.9972989, lng: 21.452749 },
    { lat: 48.9970557, lng: 21.4528588 },
    { lat: 48.9962394, lng: 21.4529942 },
    { lat: 48.9958974, lng: 21.4531862 },
    { lat: 48.9956558, lng: 21.4532056 },
    { lat: 48.9953869, lng: 21.4533555 },
    { lat: 48.994899, lng: 21.4538404 },
    { lat: 48.9950271, lng: 21.4542546 },
    { lat: 48.9959483, lng: 21.4550607 },
    { lat: 48.9964195, lng: 21.455132 },
    { lat: 48.9968935, lng: 21.4553381 },
    { lat: 48.997342, lng: 21.4557452 },
    { lat: 48.9975714, lng: 21.4560559 },
    { lat: 48.9977087, lng: 21.4564792 },
    { lat: 48.9977322, lng: 21.4569395 },
    { lat: 48.9978617, lng: 21.4575271 },
    { lat: 48.997953, lng: 21.4576521 },
    { lat: 48.9979194, lng: 21.4590128 },
    { lat: 48.9985078, lng: 21.4596696 },
    { lat: 48.9987442, lng: 21.4598319 },
    { lat: 48.9989491, lng: 21.4603037 },
    { lat: 48.9993549, lng: 21.4607524 },
    { lat: 48.9995628, lng: 21.4611735 },
    { lat: 48.9995749, lng: 21.4615913 },
    { lat: 48.9997332, lng: 21.4618476 },
    { lat: 48.9998597, lng: 21.4623305 },
    { lat: 48.9999664, lng: 21.4624007 },
    { lat: 49.0001622, lng: 21.4624041 },
    { lat: 49.000355, lng: 21.4625317 },
    { lat: 49.0004142, lng: 21.4628334 },
    { lat: 49.0006775, lng: 21.4628326 },
    { lat: 49.0009094, lng: 21.4629504 },
    { lat: 49.0011568, lng: 21.462945 },
    { lat: 49.0013173, lng: 21.4633274 },
    { lat: 49.0017178, lng: 21.4637478 },
    { lat: 49.0019047, lng: 21.4638335 },
    { lat: 49.0020959, lng: 21.4636731 },
    { lat: 49.0022702, lng: 21.4637883 },
    { lat: 49.0024886, lng: 21.4637508 },
    { lat: 49.0026642, lng: 21.4640246 },
    { lat: 49.0027307, lng: 21.4643431 },
    { lat: 49.0027996, lng: 21.4644277 },
    { lat: 49.0028769, lng: 21.4644164 },
    { lat: 49.003084, lng: 21.4641532 },
    { lat: 49.0031908, lng: 21.4641038 },
    { lat: 49.0032763, lng: 21.4643758 },
    { lat: 49.0033013, lng: 21.4649321 },
    { lat: 49.0035875, lng: 21.4650602 },
    { lat: 49.0037314, lng: 21.4654473 },
    { lat: 49.0037353, lng: 21.4656212 },
    { lat: 49.0036366, lng: 21.4659009 },
    { lat: 49.0038213, lng: 21.4663802 },
    { lat: 49.0039496, lng: 21.4665745 },
    { lat: 49.0039178, lng: 21.4669927 },
    { lat: 49.0039607, lng: 21.4672299 },
    { lat: 49.0042234, lng: 21.467403 },
    { lat: 49.0044122, lng: 21.4680595 },
    { lat: 49.0046686, lng: 21.4685208 },
    { lat: 49.0044135, lng: 21.4695832 },
    { lat: 49.0046572, lng: 21.4699142 },
    { lat: 49.0050906, lng: 21.4713707 },
    { lat: 49.0054016, lng: 21.4720466 },
    { lat: 49.0057348, lng: 21.4725552 },
    { lat: 49.0062452, lng: 21.4728161 },
    { lat: 49.0066184, lng: 21.4732313 },
    { lat: 49.0075746, lng: 21.474595 },
    { lat: 49.007935, lng: 21.4755777 },
    { lat: 49.0083487, lng: 21.4761501 },
    { lat: 49.0081772, lng: 21.4765629 },
    { lat: 49.0082162, lng: 21.4767858 },
    { lat: 49.0075611, lng: 21.4771666 },
    { lat: 49.006924, lng: 21.4760604 },
    { lat: 49.0059031, lng: 21.475349 },
    { lat: 49.0052778, lng: 21.4761002 },
    { lat: 49.0044965, lng: 21.4769023 },
    { lat: 49.004191, lng: 21.4773154 },
    { lat: 49.0041024, lng: 21.477203 },
    { lat: 49.0035347, lng: 21.4781199 },
    { lat: 49.0029655, lng: 21.4792149 },
    { lat: 49.004609, lng: 21.480963 },
    { lat: 49.005886, lng: 21.4821772 },
    { lat: 49.0068262, lng: 21.4832984 },
    { lat: 49.0081016, lng: 21.4845669 },
    { lat: 49.0112958, lng: 21.4886015 },
    { lat: 49.0125528, lng: 21.4897077 },
    { lat: 49.0134761, lng: 21.4909056 },
    { lat: 49.0134516, lng: 21.4910732 },
    { lat: 49.0159604, lng: 21.4924352 },
    { lat: 49.0146263, lng: 21.4943316 },
    { lat: 49.013221, lng: 21.4957824 },
    { lat: 49.0120593, lng: 21.4974887 },
    { lat: 49.010931, lng: 21.4995364 },
    { lat: 49.010053, lng: 21.5001511 },
    { lat: 49.0090385, lng: 21.5012636 },
    { lat: 49.0085623, lng: 21.5021986 },
    { lat: 49.0091488, lng: 21.5035486 },
    { lat: 49.0084223, lng: 21.5033203 },
    { lat: 49.0069206, lng: 21.5038858 },
    { lat: 49.0052845, lng: 21.5052173 },
    { lat: 49.0027988, lng: 21.5068607 },
    { lat: 49.002759, lng: 21.5068873 },
    { lat: 49.0038399, lng: 21.5078577 },
    { lat: 49.00406, lng: 21.5083968 },
    { lat: 49.0049809, lng: 21.5095216 },
    { lat: 49.0052657, lng: 21.5103887 },
    { lat: 49.0047777, lng: 21.5108778 },
    { lat: 49.0041988, lng: 21.5112879 },
    { lat: 49.0046803, lng: 21.5113228 },
    { lat: 49.0050598, lng: 21.5118468 },
    { lat: 49.0075918, lng: 21.5132902 },
    { lat: 49.0077162, lng: 21.5134705 },
    { lat: 49.0079854, lng: 21.5143864 },
    { lat: 49.0080508, lng: 21.5149436 },
    { lat: 49.0082432, lng: 21.5157312 },
    { lat: 49.0102437, lng: 21.5184639 },
    { lat: 49.011083, lng: 21.518536 },
    { lat: 49.0115906, lng: 21.5189843 },
    { lat: 49.0124792, lng: 21.5192925 },
    { lat: 49.0131519, lng: 21.5186157 },
    { lat: 49.0133852, lng: 21.5181964 },
    { lat: 49.0146438, lng: 21.5163619 },
    { lat: 49.0179999, lng: 21.5199572 },
    { lat: 49.018554, lng: 21.5204459 },
    { lat: 49.0188134, lng: 21.5216992 },
    { lat: 49.0189705, lng: 21.5220441 },
    { lat: 49.0203732, lng: 21.5237755 },
    { lat: 49.0233246, lng: 21.5272243 },
    { lat: 49.0263766, lng: 21.5290805 },
    { lat: 49.0254, lng: 21.5295909 },
    { lat: 49.0278213, lng: 21.5327374 },
    { lat: 49.0285895, lng: 21.5331234 },
    { lat: 49.0295945, lng: 21.5330551 },
    { lat: 49.0305604, lng: 21.5323948 },
    { lat: 49.031034, lng: 21.5319612 },
    { lat: 49.0311795, lng: 21.5322141 },
    { lat: 49.0319749, lng: 21.5297087 },
    { lat: 49.0321292, lng: 21.5292791 },
    { lat: 49.0327694, lng: 21.5278846 },
    { lat: 49.032997, lng: 21.5271902 },
    { lat: 49.0330781, lng: 21.5267916 },
    { lat: 49.0331895, lng: 21.5258419 },
    { lat: 49.0331127, lng: 21.5251941 },
    { lat: 49.0328406, lng: 21.5242155 },
    { lat: 49.0328011, lng: 21.5228258 },
    { lat: 49.0329382, lng: 21.5218228 },
    { lat: 49.0332176, lng: 21.5205724 },
    { lat: 49.0333078, lng: 21.5197142 },
    { lat: 49.0333397, lng: 21.5189702 },
    { lat: 49.0333017, lng: 21.5172806 },
    { lat: 49.0333981, lng: 21.5164417 },
    { lat: 49.0336143, lng: 21.5153005 },
    { lat: 49.0337744, lng: 21.5138926 },
    { lat: 49.0339166, lng: 21.5135125 },
    { lat: 49.034065, lng: 21.5131115 },
    { lat: 49.0338957, lng: 21.5129376 },
    { lat: 49.0334114, lng: 21.5126813 },
    { lat: 49.0327893, lng: 21.5120553 },
    { lat: 49.0323789, lng: 21.5118257 },
    { lat: 49.0323487, lng: 21.5115179 },
    { lat: 49.0324107, lng: 21.5111211 },
    { lat: 49.0323391, lng: 21.5107849 },
    { lat: 49.0324204, lng: 21.510005 },
    { lat: 49.0327672, lng: 21.5087928 },
    { lat: 49.0330202, lng: 21.5081561 },
    { lat: 49.033024, lng: 21.5080171 },
    { lat: 49.0331515, lng: 21.5076695 },
    { lat: 49.0336881, lng: 21.5064045 },
    { lat: 49.0340541, lng: 21.5059028 },
    { lat: 49.0349734, lng: 21.5043374 },
    { lat: 49.0351158, lng: 21.5038439 },
    { lat: 49.0356371, lng: 21.5031902 },
    { lat: 49.0361691, lng: 21.502354 },
    { lat: 49.0364141, lng: 21.5019103 },
    { lat: 49.0365517, lng: 21.5014544 },
    { lat: 49.0366356, lng: 21.501436 },
    { lat: 49.0367894, lng: 21.5011493 },
    { lat: 49.0371874, lng: 21.50004 },
    { lat: 49.0374376, lng: 21.4991858 },
    { lat: 49.0375992, lng: 21.4982746 },
    { lat: 49.0378099, lng: 21.497967 },
    { lat: 49.0385963, lng: 21.4978052 },
    { lat: 49.0387061, lng: 21.4977542 },
    { lat: 49.0389757, lng: 21.4974605 },
    { lat: 49.0396194, lng: 21.4966397 },
  ],
  Majerovce: [
    { lat: 48.880377, lng: 21.7186173 },
    { lat: 48.8802359, lng: 21.7188948 },
    { lat: 48.8802717, lng: 21.7194749 },
    { lat: 48.8802214, lng: 21.7194891 },
    { lat: 48.8802967, lng: 21.7197832 },
    { lat: 48.8800045, lng: 21.7197895 },
    { lat: 48.8800035, lng: 21.7200075 },
    { lat: 48.879848, lng: 21.7205829 },
    { lat: 48.8791472, lng: 21.7220522 },
    { lat: 48.8799217, lng: 21.7228306 },
    { lat: 48.8800984, lng: 21.7228347 },
    { lat: 48.880289, lng: 21.722646 },
    { lat: 48.880535, lng: 21.7222524 },
    { lat: 48.8806729, lng: 21.7222371 },
    { lat: 48.8810503, lng: 21.7225881 },
    { lat: 48.8819612, lng: 21.7238789 },
    { lat: 48.8822662, lng: 21.7248924 },
    { lat: 48.8829783, lng: 21.7257226 },
    { lat: 48.8838635, lng: 21.7263488 },
    { lat: 48.884002, lng: 21.7266512 },
    { lat: 48.8841793, lng: 21.727262 },
    { lat: 48.8844622, lng: 21.7277043 },
    { lat: 48.8854924, lng: 21.7290426 },
    { lat: 48.885672, lng: 21.729572 },
    { lat: 48.8856198, lng: 21.729937 },
    { lat: 48.8855508, lng: 21.7300917 },
    { lat: 48.8851287, lng: 21.7304558 },
    { lat: 48.884498, lng: 21.7307734 },
    { lat: 48.8843897, lng: 21.7308841 },
    { lat: 48.8841875, lng: 21.7313183 },
    { lat: 48.8841851, lng: 21.7314389 },
    { lat: 48.8842868, lng: 21.7316668 },
    { lat: 48.8846392, lng: 21.7319268 },
    { lat: 48.8850734, lng: 21.7320142 },
    { lat: 48.8852194, lng: 21.7322551 },
    { lat: 48.8853644, lng: 21.7328396 },
    { lat: 48.8854772, lng: 21.734284 },
    { lat: 48.8856457, lng: 21.734787 },
    { lat: 48.8858908, lng: 21.7351981 },
    { lat: 48.8860237, lng: 21.7355414 },
    { lat: 48.8861509, lng: 21.7362192 },
    { lat: 48.8864083, lng: 21.7365884 },
    { lat: 48.8867786, lng: 21.7368517 },
    { lat: 48.8868734, lng: 21.7369835 },
    { lat: 48.8873104, lng: 21.7371333 },
    { lat: 48.8877217, lng: 21.7371199 },
    { lat: 48.88773, lng: 21.7372192 },
    { lat: 48.8883895, lng: 21.7371888 },
    { lat: 48.8886865, lng: 21.7377347 },
    { lat: 48.8888166, lng: 21.7379741 },
    { lat: 48.8896827, lng: 21.7362758 },
    { lat: 48.8908873, lng: 21.7329394 },
    { lat: 48.8921006, lng: 21.729846 },
    { lat: 48.8923464, lng: 21.7260323 },
    { lat: 48.8923134, lng: 21.7256796 },
    { lat: 48.8925253, lng: 21.7255279 },
    { lat: 48.8929668, lng: 21.7255082 },
    { lat: 48.895667, lng: 21.7230728 },
    { lat: 48.8962306, lng: 21.7227824 },
    { lat: 48.8963232, lng: 21.7227878 },
    { lat: 48.8973208, lng: 21.7179101 },
    { lat: 48.8991724, lng: 21.7145859 },
    { lat: 48.8996596, lng: 21.7135829 },
    { lat: 48.8997769, lng: 21.7131766 },
    { lat: 48.8999077, lng: 21.7131817 },
    { lat: 48.9003569, lng: 21.7124034 },
    { lat: 48.9004641, lng: 21.7120753 },
    { lat: 48.9005724, lng: 21.712045 },
    { lat: 48.9006314, lng: 21.711608 },
    { lat: 48.9011523, lng: 21.7109378 },
    { lat: 48.9012943, lng: 21.7106963 },
    { lat: 48.9015334, lng: 21.7100843 },
    { lat: 48.9017806, lng: 21.7097801 },
    { lat: 48.9021933, lng: 21.709103 },
    { lat: 48.9027343, lng: 21.7085564 },
    { lat: 48.9036119, lng: 21.7074758 },
    { lat: 48.90366, lng: 21.7073766 },
    { lat: 48.9035296, lng: 21.7069216 },
    { lat: 48.9038747, lng: 21.706789 },
    { lat: 48.9040882, lng: 21.7066098 },
    { lat: 48.904165, lng: 21.7066176 },
    { lat: 48.9046807, lng: 21.7062173 },
    { lat: 48.9048135, lng: 21.7060092 },
    { lat: 48.9048036, lng: 21.7059751 },
    { lat: 48.9029175, lng: 21.7001919 },
    { lat: 48.9028183, lng: 21.7003078 },
    { lat: 48.902724, lng: 21.7003176 },
    { lat: 48.9025857, lng: 21.7005321 },
    { lat: 48.9023053, lng: 21.70064 },
    { lat: 48.9022302, lng: 21.7007409 },
    { lat: 48.9022351, lng: 21.7009594 },
    { lat: 48.9021637, lng: 21.7011062 },
    { lat: 48.9017147, lng: 21.7012677 },
    { lat: 48.9016073, lng: 21.7012107 },
    { lat: 48.9015274, lng: 21.7012479 },
    { lat: 48.9014131, lng: 21.7014309 },
    { lat: 48.9013499, lng: 21.7016322 },
    { lat: 48.9011135, lng: 21.7015929 },
    { lat: 48.9011048, lng: 21.7018091 },
    { lat: 48.9010249, lng: 21.7019327 },
    { lat: 48.9008024, lng: 21.7019004 },
    { lat: 48.9007514, lng: 21.701758 },
    { lat: 48.9005575, lng: 21.7015787 },
    { lat: 48.9004806, lng: 21.7016035 },
    { lat: 48.9003678, lng: 21.7018104 },
    { lat: 48.900224, lng: 21.7018966 },
    { lat: 48.9002612, lng: 21.702028 },
    { lat: 48.9001216, lng: 21.702391 },
    { lat: 48.9000136, lng: 21.7025239 },
    { lat: 48.9000624, lng: 21.7027501 },
    { lat: 48.8999043, lng: 21.7030617 },
    { lat: 48.899927, lng: 21.7032287 },
    { lat: 48.899653, lng: 21.7035865 },
    { lat: 48.8994105, lng: 21.7035189 },
    { lat: 48.8992048, lng: 21.7042414 },
    { lat: 48.8988591, lng: 21.7044388 },
    { lat: 48.8987997, lng: 21.7045705 },
    { lat: 48.8980642, lng: 21.7049121 },
    { lat: 48.8974135, lng: 21.705315 },
    { lat: 48.8971892, lng: 21.705714 },
    { lat: 48.897065, lng: 21.7058314 },
    { lat: 48.8963611, lng: 21.7061746 },
    { lat: 48.8954473, lng: 21.7056223 },
    { lat: 48.8951167, lng: 21.7051705 },
    { lat: 48.8949962, lng: 21.7050803 },
    { lat: 48.8945394, lng: 21.7042884 },
    { lat: 48.8933584, lng: 21.7032932 },
    { lat: 48.8928222, lng: 21.7025567 },
    { lat: 48.8922994, lng: 21.7016131 },
    { lat: 48.8912985, lng: 21.7000327 },
    { lat: 48.8910805, lng: 21.7001684 },
    { lat: 48.8909679, lng: 21.6999162 },
    { lat: 48.8905695, lng: 21.700041 },
    { lat: 48.8899187, lng: 21.7009384 },
    { lat: 48.8895278, lng: 21.7012929 },
    { lat: 48.8891165, lng: 21.70148 },
    { lat: 48.8883746, lng: 21.7024516 },
    { lat: 48.8881465, lng: 21.7025869 },
    { lat: 48.8881487, lng: 21.7036332 },
    { lat: 48.8880144, lng: 21.703698 },
    { lat: 48.8880148, lng: 21.7039203 },
    { lat: 48.8876421, lng: 21.704036 },
    { lat: 48.8872341, lng: 21.704013 },
    { lat: 48.8866974, lng: 21.7037124 },
    { lat: 48.8864882, lng: 21.7040481 },
    { lat: 48.8861999, lng: 21.7047052 },
    { lat: 48.8861818, lng: 21.7057955 },
    { lat: 48.8860613, lng: 21.706415 },
    { lat: 48.8861326, lng: 21.7067591 },
    { lat: 48.8862883, lng: 21.7069486 },
    { lat: 48.8861277, lng: 21.7071201 },
    { lat: 48.8859507, lng: 21.707149 },
    { lat: 48.8859931, lng: 21.7079364 },
    { lat: 48.8861988, lng: 21.7096006 },
    { lat: 48.8859741, lng: 21.7104928 },
    { lat: 48.8856075, lng: 21.7111668 },
    { lat: 48.8854085, lng: 21.7118851 },
    { lat: 48.8851211, lng: 21.7125785 },
    { lat: 48.8838936, lng: 21.714338 },
    { lat: 48.8835083, lng: 21.7148172 },
    { lat: 48.8832192, lng: 21.715324 },
    { lat: 48.8826095, lng: 21.7161991 },
    { lat: 48.8819157, lng: 21.7169729 },
    { lat: 48.8809279, lng: 21.7179414 },
    { lat: 48.880377, lng: 21.7186173 },
  ],
  Zámutov: [
    { lat: 48.9410584, lng: 21.4931122 },
    { lat: 48.9410004, lng: 21.4928905 },
    { lat: 48.9408956, lng: 21.4924218 },
    { lat: 48.9412042, lng: 21.4907879 },
    { lat: 48.9411146, lng: 21.4899338 },
    { lat: 48.9411422, lng: 21.488705 },
    { lat: 48.9410429, lng: 21.4875623 },
    { lat: 48.941527, lng: 21.4867858 },
    { lat: 48.9418152, lng: 21.4864434 },
    { lat: 48.9423616, lng: 21.4847316 },
    { lat: 48.9426138, lng: 21.4829905 },
    { lat: 48.9426558, lng: 21.4818696 },
    { lat: 48.942254, lng: 21.4799968 },
    { lat: 48.9418325, lng: 21.4791132 },
    { lat: 48.9414267, lng: 21.4788119 },
    { lat: 48.9410273, lng: 21.4788922 },
    { lat: 48.9403952, lng: 21.47914 },
    { lat: 48.9412476, lng: 21.4760775 },
    { lat: 48.9419679, lng: 21.4738624 },
    { lat: 48.9420966, lng: 21.4737221 },
    { lat: 48.9425212, lng: 21.472954 },
    { lat: 48.9428683, lng: 21.4720833 },
    { lat: 48.9434183, lng: 21.4701647 },
    { lat: 48.9441888, lng: 21.4688358 },
    { lat: 48.9444527, lng: 21.4685856 },
    { lat: 48.945305, lng: 21.467197 },
    { lat: 48.945237, lng: 21.467262 },
    { lat: 48.944884, lng: 21.467458 },
    { lat: 48.944654, lng: 21.467724 },
    { lat: 48.943769, lng: 21.468601 },
    { lat: 48.943303, lng: 21.469062 },
    { lat: 48.943117, lng: 21.469084 },
    { lat: 48.941983, lng: 21.469178 },
    { lat: 48.941529, lng: 21.468972 },
    { lat: 48.9414381, lng: 21.4689019 },
    { lat: 48.94066, lng: 21.468302 },
    { lat: 48.940322, lng: 21.467887 },
    { lat: 48.939909, lng: 21.467446 },
    { lat: 48.939609, lng: 21.466875 },
    { lat: 48.939212, lng: 21.46622 },
    { lat: 48.938769, lng: 21.465809 },
    { lat: 48.93816, lng: 21.464944 },
    { lat: 48.937764, lng: 21.464409 },
    { lat: 48.936879, lng: 21.463419 },
    { lat: 48.93629, lng: 21.463628 },
    { lat: 48.935765, lng: 21.464181 },
    { lat: 48.935446, lng: 21.464737 },
    { lat: 48.935118, lng: 21.465213 },
    { lat: 48.934882, lng: 21.465503 },
    { lat: 48.934651, lng: 21.465627 },
    { lat: 48.934231, lng: 21.465679 },
    { lat: 48.933703, lng: 21.465598 },
    { lat: 48.933298, lng: 21.465484 },
    { lat: 48.93262, lng: 21.465072 },
    { lat: 48.932074, lng: 21.464618 },
    { lat: 48.931885, lng: 21.465299 },
    { lat: 48.931576, lng: 21.465673 },
    { lat: 48.931394, lng: 21.466068 },
    { lat: 48.931135, lng: 21.466301 },
    { lat: 48.930185, lng: 21.466843 },
    { lat: 48.92958, lng: 21.467242 },
    { lat: 48.928453, lng: 21.468045 },
    { lat: 48.927792, lng: 21.46887 },
    { lat: 48.927349, lng: 21.469332 },
    { lat: 48.926885, lng: 21.470055 },
    { lat: 48.926504, lng: 21.470536 },
    { lat: 48.926292, lng: 21.470819 },
    { lat: 48.926027, lng: 21.471766 },
    { lat: 48.925633, lng: 21.472888 },
    { lat: 48.925033, lng: 21.473739 },
    { lat: 48.924818, lng: 21.474299 },
    { lat: 48.924784, lng: 21.474701 },
    { lat: 48.924843, lng: 21.475196 },
    { lat: 48.925051, lng: 21.475618 },
    { lat: 48.925222, lng: 21.476164 },
    { lat: 48.925258, lng: 21.476951 },
    { lat: 48.925129, lng: 21.47715 },
    { lat: 48.924646, lng: 21.478173 },
    { lat: 48.924028, lng: 21.479023 },
    { lat: 48.923718, lng: 21.479326 },
    { lat: 48.923422, lng: 21.479956 },
    { lat: 48.92323, lng: 21.480685 },
    { lat: 48.922739, lng: 21.481138 },
    { lat: 48.921851, lng: 21.481382 },
    { lat: 48.921726, lng: 21.48147 },
    { lat: 48.921277, lng: 21.482139 },
    { lat: 48.92003, lng: 21.48203 },
    { lat: 48.919936, lng: 21.482039 },
    { lat: 48.919393, lng: 21.482058 },
    { lat: 48.919051, lng: 21.482165 },
    { lat: 48.917741, lng: 21.481629 },
    { lat: 48.917347, lng: 21.481391 },
    { lat: 48.916517, lng: 21.481098 },
    { lat: 48.916259, lng: 21.481111 },
    { lat: 48.915148, lng: 21.480963 },
    { lat: 48.914656, lng: 21.480492 },
    { lat: 48.91377, lng: 21.479379 },
    { lat: 48.913065, lng: 21.478978 },
    { lat: 48.912567, lng: 21.47877 },
    { lat: 48.911433, lng: 21.478342 },
    { lat: 48.909838, lng: 21.477385 },
    { lat: 48.909479, lng: 21.477431 },
    { lat: 48.909146, lng: 21.47747 },
    { lat: 48.909108, lng: 21.477476 },
    { lat: 48.909056, lng: 21.477481 },
    { lat: 48.909017, lng: 21.477485 },
    { lat: 48.908821, lng: 21.477506 },
    { lat: 48.908367, lng: 21.477569 },
    { lat: 48.907623, lng: 21.477654 },
    { lat: 48.906551, lng: 21.477906 },
    { lat: 48.905341, lng: 21.478046 },
    { lat: 48.904946, lng: 21.478092 },
    { lat: 48.904795, lng: 21.478161 },
    { lat: 48.904044, lng: 21.478567 },
    { lat: 48.903485, lng: 21.479779 },
    { lat: 48.902838, lng: 21.480215 },
    { lat: 48.902112, lng: 21.480294 },
    { lat: 48.901549, lng: 21.480398 },
    { lat: 48.900511, lng: 21.480706 },
    { lat: 48.899912, lng: 21.481088 },
    { lat: 48.899875, lng: 21.481135 },
    { lat: 48.899618, lng: 21.481493 },
    { lat: 48.898864, lng: 21.481702 },
    { lat: 48.896804, lng: 21.48174 },
    { lat: 48.89577, lng: 21.481343 },
    { lat: 48.894973, lng: 21.481033 },
    { lat: 48.894513, lng: 21.480988 },
    { lat: 48.89411, lng: 21.48104 },
    { lat: 48.893765, lng: 21.481162 },
    { lat: 48.893588, lng: 21.481225 },
    { lat: 48.893266, lng: 21.48161 },
    { lat: 48.892995, lng: 21.482128 },
    { lat: 48.892753, lng: 21.482431 },
    { lat: 48.892384, lng: 21.482803 },
    { lat: 48.892102, lng: 21.483153 },
    { lat: 48.892088, lng: 21.48312 },
    { lat: 48.891781, lng: 21.483505 },
    { lat: 48.891616, lng: 21.483736 },
    { lat: 48.891213, lng: 21.484294 },
    { lat: 48.891003, lng: 21.484589 },
    { lat: 48.890778, lng: 21.485067 },
    { lat: 48.890558, lng: 21.485362 },
    { lat: 48.890253, lng: 21.485864 },
    { lat: 48.889741, lng: 21.485823 },
    { lat: 48.888369, lng: 21.485047 },
    { lat: 48.887671, lng: 21.484587 },
    { lat: 48.887265, lng: 21.484213 },
    { lat: 48.885814, lng: 21.484836 },
    { lat: 48.885158, lng: 21.485129 },
    { lat: 48.884573, lng: 21.485393 },
    { lat: 48.884412, lng: 21.484995 },
    { lat: 48.884264, lng: 21.484882 },
    { lat: 48.883955, lng: 21.484521 },
    { lat: 48.883876, lng: 21.484452 },
    { lat: 48.883626, lng: 21.484233 },
    { lat: 48.883005, lng: 21.484121 },
    { lat: 48.882269, lng: 21.483941 },
    { lat: 48.88158, lng: 21.483948 },
    { lat: 48.88131, lng: 21.483908 },
    { lat: 48.88127, lng: 21.483896 },
    { lat: 48.880668, lng: 21.483835 },
    { lat: 48.88009, lng: 21.483784 },
    { lat: 48.879882, lng: 21.483849 },
    { lat: 48.879529, lng: 21.484136 },
    { lat: 48.879442, lng: 21.484124 },
    { lat: 48.87939, lng: 21.484106 },
    { lat: 48.878974, lng: 21.483841 },
    { lat: 48.878666, lng: 21.4838 },
    { lat: 48.878325, lng: 21.483809 },
    { lat: 48.877964, lng: 21.484047 },
    { lat: 48.877708, lng: 21.48427 },
    { lat: 48.876516, lng: 21.48446 },
    { lat: 48.875854, lng: 21.484569 },
    { lat: 48.875403, lng: 21.484979 },
    { lat: 48.874663, lng: 21.485118 },
    { lat: 48.87366, lng: 21.485344 },
    { lat: 48.872921, lng: 21.485235 },
    { lat: 48.872565, lng: 21.485172 },
    { lat: 48.871995, lng: 21.485056 },
    { lat: 48.871722, lng: 21.485015 },
    { lat: 48.871521, lng: 21.484856 },
    { lat: 48.870897, lng: 21.4844 },
    { lat: 48.870632, lng: 21.484381 },
    { lat: 48.869871, lng: 21.484507 },
    { lat: 48.869182, lng: 21.484241 },
    { lat: 48.8688883, lng: 21.484229 },
    { lat: 48.868807, lng: 21.485291 },
    { lat: 48.868475, lng: 21.485963 },
    { lat: 48.868352, lng: 21.486779 },
    { lat: 48.86821, lng: 21.48776 },
    { lat: 48.868154, lng: 21.488828 },
    { lat: 48.867941, lng: 21.489025 },
    { lat: 48.867967, lng: 21.489543 },
    { lat: 48.867974, lng: 21.490182 },
    { lat: 48.867987, lng: 21.490343 },
    { lat: 48.867973, lng: 21.490353 },
    { lat: 48.868029, lng: 21.490745 },
    { lat: 48.868062, lng: 21.491214 },
    { lat: 48.868178, lng: 21.491794 },
    { lat: 48.86815, lng: 21.492109 },
    { lat: 48.868166, lng: 21.49266 },
    { lat: 48.868248, lng: 21.493155 },
    { lat: 48.868247, lng: 21.493618 },
    { lat: 48.868203, lng: 21.494262 },
    { lat: 48.868132, lng: 21.49474 },
    { lat: 48.868054, lng: 21.495044 },
    { lat: 48.868035, lng: 21.495454 },
    { lat: 48.868017, lng: 21.495442 },
    { lat: 48.867949, lng: 21.49594 },
    { lat: 48.867083, lng: 21.497828 },
    { lat: 48.866561, lng: 21.498286 },
    { lat: 48.866444, lng: 21.498439 },
    { lat: 48.866105, lng: 21.498555 },
    { lat: 48.865764, lng: 21.498746 },
    { lat: 48.865318, lng: 21.499035 },
    { lat: 48.865027, lng: 21.499378 },
    { lat: 48.864547, lng: 21.4999 },
    { lat: 48.864331, lng: 21.500211 },
    { lat: 48.863957, lng: 21.500601 },
    { lat: 48.863469, lng: 21.500906 },
    { lat: 48.863252, lng: 21.500999 },
    { lat: 48.863071, lng: 21.501129 },
    { lat: 48.862943, lng: 21.501213 },
    { lat: 48.862839, lng: 21.501324 },
    { lat: 48.86267, lng: 21.501456 },
    { lat: 48.862516, lng: 21.501587 },
    { lat: 48.862091, lng: 21.501966 },
    { lat: 48.861667, lng: 21.501765 },
    { lat: 48.861476, lng: 21.501153 },
    { lat: 48.8612848, lng: 21.501689 },
    { lat: 48.8608755, lng: 21.5025607 },
    { lat: 48.8605459, lng: 21.5035055 },
    { lat: 48.8601163, lng: 21.5044507 },
    { lat: 48.8598886, lng: 21.5048298 },
    { lat: 48.8596089, lng: 21.5051566 },
    { lat: 48.8595055, lng: 21.5058698 },
    { lat: 48.8594006, lng: 21.507633 },
    { lat: 48.8595302, lng: 21.5086586 },
    { lat: 48.8595399, lng: 21.5090804 },
    { lat: 48.8597809, lng: 21.5098703 },
    { lat: 48.8596404, lng: 21.511132 },
    { lat: 48.8596639, lng: 21.5121949 },
    { lat: 48.8596189, lng: 21.5127804 },
    { lat: 48.8597908, lng: 21.5143896 },
    { lat: 48.8599627, lng: 21.5149261 },
    { lat: 48.8601649, lng: 21.5152294 },
    { lat: 48.8604878, lng: 21.5161384 },
    { lat: 48.8605232, lng: 21.5166274 },
    { lat: 48.8607201, lng: 21.5177687 },
    { lat: 48.8611723, lng: 21.5185938 },
    { lat: 48.8616303, lng: 21.5191862 },
    { lat: 48.8619096, lng: 21.5198244 },
    { lat: 48.8620477, lng: 21.5203288 },
    { lat: 48.8619814, lng: 21.5218315 },
    { lat: 48.861825, lng: 21.5223807 },
    { lat: 48.8615792, lng: 21.5229574 },
    { lat: 48.8618857, lng: 21.5234108 },
    { lat: 48.8620482, lng: 21.5239427 },
    { lat: 48.8621203, lng: 21.5244125 },
    { lat: 48.861865, lng: 21.5250081 },
    { lat: 48.8614395, lng: 21.5257971 },
    { lat: 48.8611959, lng: 21.5268415 },
    { lat: 48.8614189, lng: 21.5271606 },
    { lat: 48.8618199, lng: 21.5285681 },
    { lat: 48.8621508, lng: 21.5294752 },
    { lat: 48.8631983, lng: 21.5306685 },
    { lat: 48.8634994, lng: 21.5311645 },
    { lat: 48.8645649, lng: 21.5318733 },
    { lat: 48.8654166, lng: 21.5327461 },
    { lat: 48.8657209, lng: 21.5329592 },
    { lat: 48.8661744, lng: 21.5337602 },
    { lat: 48.8667708, lng: 21.5335561 },
    { lat: 48.8670373, lng: 21.5342646 },
    { lat: 48.8673567, lng: 21.5345764 },
    { lat: 48.8673603, lng: 21.5346605 },
    { lat: 48.8677428, lng: 21.5347378 },
    { lat: 48.8676833, lng: 21.5346277 },
    { lat: 48.868638, lng: 21.535645 },
    { lat: 48.8690354, lng: 21.5358457 },
    { lat: 48.8696792, lng: 21.5359359 },
    { lat: 48.8705256, lng: 21.5368564 },
    { lat: 48.8709429, lng: 21.5380012 },
    { lat: 48.8710912, lng: 21.5389429 },
    { lat: 48.8714059, lng: 21.5394871 },
    { lat: 48.8718213, lng: 21.5396112 },
    { lat: 48.8724468, lng: 21.5404415 },
    { lat: 48.8728026, lng: 21.5408093 },
    { lat: 48.8730798, lng: 21.5416223 },
    { lat: 48.87311, lng: 21.5423716 },
    { lat: 48.8734621, lng: 21.543673 },
    { lat: 48.8748644, lng: 21.5446465 },
    { lat: 48.8758133, lng: 21.5449021 },
    { lat: 48.8770926, lng: 21.5446598 },
    { lat: 48.8775131, lng: 21.5448873 },
    { lat: 48.8784368, lng: 21.5460354 },
    { lat: 48.8792419, lng: 21.5466186 },
    { lat: 48.8799911, lng: 21.5475301 },
    { lat: 48.8808337, lng: 21.5490407 },
    { lat: 48.8810234, lng: 21.5496536 },
    { lat: 48.88131, lng: 21.5496085 },
    { lat: 48.8813605, lng: 21.5497171 },
    { lat: 48.8820517, lng: 21.5502892 },
    { lat: 48.882731, lng: 21.5505164 },
    { lat: 48.8827982, lng: 21.5505096 },
    { lat: 48.8831033, lng: 21.5501998 },
    { lat: 48.8832908, lng: 21.5498294 },
    { lat: 48.8834452, lng: 21.5497929 },
    { lat: 48.8837675, lng: 21.5502532 },
    { lat: 48.8838486, lng: 21.5505378 },
    { lat: 48.8840752, lng: 21.550788 },
    { lat: 48.883931, lng: 21.5513762 },
    { lat: 48.8837984, lng: 21.5517037 },
    { lat: 48.8836896, lng: 21.5523929 },
    { lat: 48.8842786, lng: 21.5524533 },
    { lat: 48.8843712, lng: 21.5532202 },
    { lat: 48.8843749, lng: 21.5536264 },
    { lat: 48.8842998, lng: 21.5541583 },
    { lat: 48.8841803, lng: 21.5543094 },
    { lat: 48.8840119, lng: 21.5548426 },
    { lat: 48.8839696, lng: 21.5566932 },
    { lat: 48.8838851, lng: 21.5570827 },
    { lat: 48.8838772, lng: 21.5575222 },
    { lat: 48.8837226, lng: 21.5583108 },
    { lat: 48.8838099, lng: 21.5585304 },
    { lat: 48.8837336, lng: 21.5588592 },
    { lat: 48.8838664, lng: 21.5588617 },
    { lat: 48.884055, lng: 21.5590032 },
    { lat: 48.8844228, lng: 21.5589955 },
    { lat: 48.8844515, lng: 21.5596529 },
    { lat: 48.8846644, lng: 21.5600641 },
    { lat: 48.8846921, lng: 21.5602536 },
    { lat: 48.8848106, lng: 21.5605412 },
    { lat: 48.8852435, lng: 21.5609021 },
    { lat: 48.8851892, lng: 21.5613701 },
    { lat: 48.8849535, lng: 21.562544 },
    { lat: 48.8847843, lng: 21.5626879 },
    { lat: 48.8846697, lng: 21.5633324 },
    { lat: 48.8845833, lng: 21.5633798 },
    { lat: 48.8844596, lng: 21.5644632 },
    { lat: 48.8845602, lng: 21.5662792 },
    { lat: 48.8844858, lng: 21.5667542 },
    { lat: 48.8844177, lng: 21.5669305 },
    { lat: 48.8844103, lng: 21.5673135 },
    { lat: 48.884598, lng: 21.5681022 },
    { lat: 48.8848748, lng: 21.5685798 },
    { lat: 48.8850416, lng: 21.5687521 },
    { lat: 48.8854585, lng: 21.5687051 },
    { lat: 48.8856433, lng: 21.5689123 },
    { lat: 48.8856778, lng: 21.5693427 },
    { lat: 48.8856217, lng: 21.5699898 },
    { lat: 48.8855166, lng: 21.5699652 },
    { lat: 48.8854271, lng: 21.5702301 },
    { lat: 48.8855838, lng: 21.5706812 },
    { lat: 48.8857426, lng: 21.5708872 },
    { lat: 48.8855233, lng: 21.5718502 },
    { lat: 48.8852578, lng: 21.5718069 },
    { lat: 48.8851704, lng: 21.5725397 },
    { lat: 48.8852342, lng: 21.572605 },
    { lat: 48.8849331, lng: 21.5734599 },
    { lat: 48.8844964, lng: 21.5737783 },
    { lat: 48.8841372, lng: 21.5742952 },
    { lat: 48.8840879, lng: 21.574623 },
    { lat: 48.8841983, lng: 21.5746674 },
    { lat: 48.8842493, lng: 21.5749951 },
    { lat: 48.8841735, lng: 21.5755066 },
    { lat: 48.8838962, lng: 21.5754036 },
    { lat: 48.884339, lng: 21.5766302 },
    { lat: 48.8843945, lng: 21.5770052 },
    { lat: 48.8847494, lng: 21.5769084 },
    { lat: 48.8855729, lng: 21.5763083 },
    { lat: 48.8856443, lng: 21.5765261 },
    { lat: 48.8860164, lng: 21.5763527 },
    { lat: 48.8859274, lng: 21.5759516 },
    { lat: 48.8861349, lng: 21.5757255 },
    { lat: 48.8862811, lng: 21.5754397 },
    { lat: 48.8868164, lng: 21.5747127 },
    { lat: 48.8870122, lng: 21.5747937 },
    { lat: 48.8869151, lng: 21.575298 },
    { lat: 48.8871792, lng: 21.5750146 },
    { lat: 48.8873197, lng: 21.5747278 },
    { lat: 48.8876878, lng: 21.5742929 },
    { lat: 48.887943, lng: 21.5738831 },
    { lat: 48.888362, lng: 21.5737367 },
    { lat: 48.888568, lng: 21.5740591 },
    { lat: 48.8888479, lng: 21.5739703 },
    { lat: 48.8891922, lng: 21.5736942 },
    { lat: 48.8897586, lng: 21.5738332 },
    { lat: 48.8899693, lng: 21.573963 },
    { lat: 48.8897878, lng: 21.5745461 },
    { lat: 48.8895709, lng: 21.5746387 },
    { lat: 48.889427, lng: 21.5748046 },
    { lat: 48.8894223, lng: 21.5748796 },
    { lat: 48.8895716, lng: 21.5759519 },
    { lat: 48.8897378, lng: 21.5766545 },
    { lat: 48.8899131, lng: 21.5766507 },
    { lat: 48.8900159, lng: 21.5767459 },
    { lat: 48.889875, lng: 21.5776553 },
    { lat: 48.8899196, lng: 21.5786092 },
    { lat: 48.8898414, lng: 21.5792671 },
    { lat: 48.8900359, lng: 21.5800347 },
    { lat: 48.8901585, lng: 21.5802292 },
    { lat: 48.8901979, lng: 21.5804748 },
    { lat: 48.8902425, lng: 21.5814587 },
    { lat: 48.8901427, lng: 21.5819535 },
    { lat: 48.8901649, lng: 21.5821505 },
    { lat: 48.8904755, lng: 21.582836 },
    { lat: 48.8909464, lng: 21.5828961 },
    { lat: 48.8912234, lng: 21.5833853 },
    { lat: 48.8913585, lng: 21.5834689 },
    { lat: 48.8915433, lng: 21.5837645 },
    { lat: 48.8917503, lng: 21.5838391 },
    { lat: 48.892032, lng: 21.5840581 },
    { lat: 48.8922362, lng: 21.5841161 },
    { lat: 48.8922934, lng: 21.584308 },
    { lat: 48.8924197, lng: 21.5844046 },
    { lat: 48.8923943, lng: 21.5849394 },
    { lat: 48.8924195, lng: 21.5849571 },
    { lat: 48.8930144, lng: 21.5848305 },
    { lat: 48.893409, lng: 21.5846946 },
    { lat: 48.8937174, lng: 21.5847023 },
    { lat: 48.893972, lng: 21.5845512 },
    { lat: 48.8945019, lng: 21.5844298 },
    { lat: 48.8956298, lng: 21.5845315 },
    { lat: 48.8959192, lng: 21.584501 },
    { lat: 48.8961265, lng: 21.5856345 },
    { lat: 48.896772, lng: 21.5856227 },
    { lat: 48.8969181, lng: 21.585983 },
    { lat: 48.8970671, lng: 21.5861597 },
    { lat: 48.8970477, lng: 21.5863934 },
    { lat: 48.8974089, lng: 21.5863545 },
    { lat: 48.8975442, lng: 21.5866047 },
    { lat: 48.8977571, lng: 21.5866546 },
    { lat: 48.8977761, lng: 21.587615 },
    { lat: 48.8981182, lng: 21.5875386 },
    { lat: 48.8984987, lng: 21.5872578 },
    { lat: 48.8986798, lng: 21.587259 },
    { lat: 48.8987123, lng: 21.587376 },
    { lat: 48.8988991, lng: 21.5872957 },
    { lat: 48.898976, lng: 21.5868674 },
    { lat: 48.8991557, lng: 21.5865715 },
    { lat: 48.8992121, lng: 21.5867017 },
    { lat: 48.9007187, lng: 21.5865018 },
    { lat: 48.9008908, lng: 21.586263 },
    { lat: 48.9009775, lng: 21.5860246 },
    { lat: 48.9010109, lng: 21.5857685 },
    { lat: 48.9011357, lng: 21.5779634 },
    { lat: 48.9017911, lng: 21.5778611 },
    { lat: 48.9023101, lng: 21.577636 },
    { lat: 48.9026165, lng: 21.577692 },
    { lat: 48.902777, lng: 21.577873 },
    { lat: 48.9028616, lng: 21.5776791 },
    { lat: 48.903083, lng: 21.5773927 },
    { lat: 48.9032196, lng: 21.5768081 },
    { lat: 48.9032979, lng: 21.5761436 },
    { lat: 48.9036064, lng: 21.5753176 },
    { lat: 48.9036421, lng: 21.5747809 },
    { lat: 48.9034865, lng: 21.5737653 },
    { lat: 48.90352, lng: 21.5735958 },
    { lat: 48.9039256, lng: 21.5725746 },
    { lat: 48.9041854, lng: 21.5720447 },
    { lat: 48.9043024, lng: 21.5714509 },
    { lat: 48.9044711, lng: 21.5710838 },
    { lat: 48.9046056, lng: 21.5703842 },
    { lat: 48.9064724, lng: 21.5701166 },
    { lat: 48.9079444, lng: 21.5697243 },
    { lat: 48.9080691, lng: 21.5715961 },
    { lat: 48.9082076, lng: 21.5717281 },
    { lat: 48.9084421, lng: 21.571739 },
    { lat: 48.9087339, lng: 21.5717542 },
    { lat: 48.9097122, lng: 21.5714975 },
    { lat: 48.9097295, lng: 21.5713086 },
    { lat: 48.9096432, lng: 21.5712408 },
    { lat: 48.9094914, lng: 21.570688 },
    { lat: 48.9093954, lng: 21.5690639 },
    { lat: 48.9094638, lng: 21.5688227 },
    { lat: 48.9096417, lng: 21.5685398 },
    { lat: 48.9098157, lng: 21.5678536 },
    { lat: 48.9098369, lng: 21.5673654 },
    { lat: 48.9100364, lng: 21.5660487 },
    { lat: 48.9100398, lng: 21.5646117 },
    { lat: 48.9102608, lng: 21.5645123 },
    { lat: 48.9102587, lng: 21.5643543 },
    { lat: 48.9104433, lng: 21.5642379 },
    { lat: 48.9104637, lng: 21.5639229 },
    { lat: 48.9105213, lng: 21.5638941 },
    { lat: 48.9105419, lng: 21.5635873 },
    { lat: 48.9106025, lng: 21.5635351 },
    { lat: 48.9106424, lng: 21.5633634 },
    { lat: 48.9110717, lng: 21.5632594 },
    { lat: 48.9116347, lng: 21.5632337 },
    { lat: 48.9119478, lng: 21.5629262 },
    { lat: 48.9122939, lng: 21.5627149 },
    { lat: 48.9127718, lng: 21.5627401 },
    { lat: 48.9132024, lng: 21.5626517 },
    { lat: 48.913692, lng: 21.5620613 },
    { lat: 48.9138186, lng: 21.561641 },
    { lat: 48.9140691, lng: 21.5613858 },
    { lat: 48.9145581, lng: 21.561127 },
    { lat: 48.9147159, lng: 21.5609376 },
    { lat: 48.9153102, lng: 21.5598706 },
    { lat: 48.9155993, lng: 21.5587086 },
    { lat: 48.9156205, lng: 21.5584257 },
    { lat: 48.9160839, lng: 21.5575017 },
    { lat: 48.9161425, lng: 21.5571019 },
    { lat: 48.9162414, lng: 21.5567824 },
    { lat: 48.9171356, lng: 21.5551722 },
    { lat: 48.9174923, lng: 21.5539782 },
    { lat: 48.9174849, lng: 21.5535977 },
    { lat: 48.9170274, lng: 21.5529341 },
    { lat: 48.9167311, lng: 21.5518426 },
    { lat: 48.9170684, lng: 21.5504423 },
    { lat: 48.9172504, lng: 21.5505936 },
    { lat: 48.9178517, lng: 21.5492935 },
    { lat: 48.9171253, lng: 21.5482758 },
    { lat: 48.9176167, lng: 21.5443259 },
    { lat: 48.9175963, lng: 21.5436042 },
    { lat: 48.9173391, lng: 21.5417475 },
    { lat: 48.917052, lng: 21.5409498 },
    { lat: 48.9169901, lng: 21.5404298 },
    { lat: 48.9170278, lng: 21.5398975 },
    { lat: 48.9165019, lng: 21.5382859 },
    { lat: 48.9162713, lng: 21.5371033 },
    { lat: 48.9164113, lng: 21.5355741 },
    { lat: 48.9166317, lng: 21.5348568 },
    { lat: 48.9166673, lng: 21.5340825 },
    { lat: 48.9167482, lng: 21.5334454 },
    { lat: 48.9170742, lng: 21.5323645 },
    { lat: 48.9171678, lng: 21.5295999 },
    { lat: 48.9175084, lng: 21.5286432 },
    { lat: 48.9176632, lng: 21.528065 },
    { lat: 48.9180716, lng: 21.5272791 },
    { lat: 48.9186197, lng: 21.525546 },
    { lat: 48.9186197, lng: 21.5253046 },
    { lat: 48.9185299, lng: 21.5246762 },
    { lat: 48.9180981, lng: 21.5236216 },
    { lat: 48.9179601, lng: 21.5234364 },
    { lat: 48.9176651, lng: 21.5220848 },
    { lat: 48.9176088, lng: 21.5214582 },
    { lat: 48.9173982, lng: 21.5208924 },
    { lat: 48.9167668, lng: 21.520369 },
    { lat: 48.9165506, lng: 21.5200891 },
    { lat: 48.9161431, lng: 21.5192924 },
    { lat: 48.9159347, lng: 21.5190131 },
    { lat: 48.9157794, lng: 21.518609 },
    { lat: 48.9156906, lng: 21.5182242 },
    { lat: 48.9155858, lng: 21.5180211 },
    { lat: 48.9155007, lng: 21.5173588 },
    { lat: 48.9165438, lng: 21.5160214 },
    { lat: 48.9179966, lng: 21.5144181 },
    { lat: 48.9182648, lng: 21.5141929 },
    { lat: 48.9190568, lng: 21.5137155 },
    { lat: 48.9194119, lng: 21.5137011 },
    { lat: 48.9196139, lng: 21.5134095 },
    { lat: 48.9200044, lng: 21.5124289 },
    { lat: 48.9202132, lng: 21.5121773 },
    { lat: 48.9204958, lng: 21.5111602 },
    { lat: 48.9207967, lng: 21.5108968 },
    { lat: 48.9215558, lng: 21.5097459 },
    { lat: 48.9218356, lng: 21.509457 },
    { lat: 48.9220103, lng: 21.5091082 },
    { lat: 48.9223512, lng: 21.50868 },
    { lat: 48.9226023, lng: 21.5075422 },
    { lat: 48.9228253, lng: 21.5071955 },
    { lat: 48.923587, lng: 21.506366 },
    { lat: 48.9238516, lng: 21.5059418 },
    { lat: 48.9239234, lng: 21.5057392 },
    { lat: 48.9245214, lng: 21.5052883 },
    { lat: 48.9247983, lng: 21.5048976 },
    { lat: 48.9255366, lng: 21.5048697 },
    { lat: 48.9258587, lng: 21.5046792 },
    { lat: 48.926279, lng: 21.5046279 },
    { lat: 48.9265665, lng: 21.504333 },
    { lat: 48.9268661, lng: 21.5038717 },
    { lat: 48.9271626, lng: 21.5035373 },
    { lat: 48.9274222, lng: 21.5036848 },
    { lat: 48.9278831, lng: 21.503596 },
    { lat: 48.9283477, lng: 21.5036656 },
    { lat: 48.9285434, lng: 21.5035486 },
    { lat: 48.9289888, lng: 21.5034591 },
    { lat: 48.929974, lng: 21.5030548 },
    { lat: 48.9306076, lng: 21.5025858 },
    { lat: 48.9310163, lng: 21.5023599 },
    { lat: 48.9315051, lng: 21.5019079 },
    { lat: 48.9325484, lng: 21.5015223 },
    { lat: 48.9331776, lng: 21.5009422 },
    { lat: 48.9335405, lng: 21.5006903 },
    { lat: 48.933906, lng: 21.5002875 },
    { lat: 48.9342327, lng: 21.500145 },
    { lat: 48.9344059, lng: 21.4997968 },
    { lat: 48.9352566, lng: 21.4994436 },
    { lat: 48.9358514, lng: 21.4989566 },
    { lat: 48.9360888, lng: 21.4984055 },
    { lat: 48.9366685, lng: 21.497514 },
    { lat: 48.9370443, lng: 21.4970884 },
    { lat: 48.9373179, lng: 21.4964672 },
    { lat: 48.9373962, lng: 21.49613 },
    { lat: 48.937649, lng: 21.4957303 },
    { lat: 48.9380316, lng: 21.4947498 },
    { lat: 48.9382912, lng: 21.4944291 },
    { lat: 48.9388259, lng: 21.494043 },
    { lat: 48.9395848, lng: 21.4942541 },
    { lat: 48.9401431, lng: 21.493837 },
    { lat: 48.9410584, lng: 21.4931122 },
  ],
  JastrabienadTopľou: [
    { lat: 48.954021, lng: 21.5945354 },
    { lat: 48.9533036, lng: 21.594628 },
    { lat: 48.9528923, lng: 21.5919825 },
    { lat: 48.9511877, lng: 21.5923365 },
    { lat: 48.9509558, lng: 21.5920933 },
    { lat: 48.9504768, lng: 21.5923821 },
    { lat: 48.9497036, lng: 21.5923799 },
    { lat: 48.9492141, lng: 21.5922062 },
    { lat: 48.9493037, lng: 21.5924363 },
    { lat: 48.9494622, lng: 21.5935222 },
    { lat: 48.9472746, lng: 21.5948023 },
    { lat: 48.9475345, lng: 21.5967024 },
    { lat: 48.9474645, lng: 21.5978215 },
    { lat: 48.9472616, lng: 21.5980522 },
    { lat: 48.9469323, lng: 21.5981907 },
    { lat: 48.9455291, lng: 21.597694 },
    { lat: 48.9443868, lng: 21.5970382 },
    { lat: 48.943569, lng: 21.5969986 },
    { lat: 48.9435443, lng: 21.5969984 },
    { lat: 48.9430439, lng: 21.5973755 },
    { lat: 48.9425909, lng: 21.5978305 },
    { lat: 48.942173, lng: 21.5978931 },
    { lat: 48.9417671, lng: 21.5974646 },
    { lat: 48.9414395, lng: 21.5966474 },
    { lat: 48.9408939, lng: 21.5958395 },
    { lat: 48.9407313, lng: 21.5952927 },
    { lat: 48.9403275, lng: 21.5949314 },
    { lat: 48.9398771, lng: 21.5946801 },
    { lat: 48.9393021, lng: 21.5945552 },
    { lat: 48.9386826, lng: 21.5942031 },
    { lat: 48.9380601, lng: 21.5937513 },
    { lat: 48.937537, lng: 21.5935079 },
    { lat: 48.9357647, lng: 21.593175 },
    { lat: 48.9356115, lng: 21.5932078 },
    { lat: 48.9353595, lng: 21.5934884 },
    { lat: 48.9352666, lng: 21.5936762 },
    { lat: 48.9351102, lng: 21.5960931 },
    { lat: 48.935252, lng: 21.5966985 },
    { lat: 48.9354423, lng: 21.596911 },
    { lat: 48.9357768, lng: 21.5970048 },
    { lat: 48.9363882, lng: 21.5969802 },
    { lat: 48.9366655, lng: 21.5972022 },
    { lat: 48.9367228, lng: 21.5983713 },
    { lat: 48.9366293, lng: 21.6014778 },
    { lat: 48.9366713, lng: 21.6031023 },
    { lat: 48.9366781, lng: 21.6033631 },
    { lat: 48.9366289, lng: 21.6036972 },
    { lat: 48.936346, lng: 21.6046884 },
    { lat: 48.9361169, lng: 21.605107 },
    { lat: 48.9358061, lng: 21.605464 },
    { lat: 48.9354995, lng: 21.6056464 },
    { lat: 48.9348576, lng: 21.6057845 },
    { lat: 48.9344839, lng: 21.6056624 },
    { lat: 48.9343162, lng: 21.6056953 },
    { lat: 48.9331863, lng: 21.6053596 },
    { lat: 48.9327271, lng: 21.6053178 },
    { lat: 48.9326134, lng: 21.6053515 },
    { lat: 48.932187, lng: 21.6060844 },
    { lat: 48.932111, lng: 21.6071177 },
    { lat: 48.9325927, lng: 21.6083006 },
    { lat: 48.9327082, lng: 21.6091739 },
    { lat: 48.9328135, lng: 21.6111445 },
    { lat: 48.9328222, lng: 21.6125563 },
    { lat: 48.9327325, lng: 21.6133432 },
    { lat: 48.932617, lng: 21.6139653 },
    { lat: 48.9324024, lng: 21.6142614 },
    { lat: 48.9314486, lng: 21.6149205 },
    { lat: 48.9312169, lng: 21.6153671 },
    { lat: 48.9310083, lng: 21.6162446 },
    { lat: 48.9310043, lng: 21.616262 },
    { lat: 48.932045, lng: 21.618056 },
    { lat: 48.9320829, lng: 21.6179154 },
    { lat: 48.9322803, lng: 21.6183441 },
    { lat: 48.9328055, lng: 21.6211915 },
    { lat: 48.9330655, lng: 21.6219239 },
    { lat: 48.9331908, lng: 21.6226284 },
    { lat: 48.9333514, lng: 21.6230318 },
    { lat: 48.9333521, lng: 21.6233828 },
    { lat: 48.9334216, lng: 21.6235773 },
    { lat: 48.9335363, lng: 21.6236906 },
    { lat: 48.9335653, lng: 21.6238476 },
    { lat: 48.9338971, lng: 21.6245844 },
    { lat: 48.9339714, lng: 21.6246617 },
    { lat: 48.9344196, lng: 21.6242277 },
    { lat: 48.9348759, lng: 21.624495 },
    { lat: 48.9355501, lng: 21.6253224 },
    { lat: 48.9356144, lng: 21.6254905 },
    { lat: 48.9356481, lng: 21.6257814 },
    { lat: 48.9361684, lng: 21.6272706 },
    { lat: 48.9368872, lng: 21.6273463 },
    { lat: 48.9369175, lng: 21.6277512 },
    { lat: 48.9370193, lng: 21.6284165 },
    { lat: 48.9384945, lng: 21.6279821 },
    { lat: 48.9401989, lng: 21.6274054 },
    { lat: 48.9448192, lng: 21.6256784 },
    { lat: 48.947214, lng: 21.6244793 },
    { lat: 48.9481853, lng: 21.6242922 },
    { lat: 48.9490415, lng: 21.6237588 },
    { lat: 48.9506819, lng: 21.6224174 },
    { lat: 48.9515215, lng: 21.6215747 },
    { lat: 48.9521613, lng: 21.620788 },
    { lat: 48.9549446, lng: 21.6182194 },
    { lat: 48.9561751, lng: 21.6169858 },
    { lat: 48.9563534, lng: 21.6163579 },
    { lat: 48.9570062, lng: 21.6156332 },
    { lat: 48.9573304, lng: 21.6153523 },
    { lat: 48.9579229, lng: 21.6150542 },
    { lat: 48.9590022, lng: 21.6147401 },
    { lat: 48.9594916, lng: 21.6141329 },
    { lat: 48.9603593, lng: 21.6134463 },
    { lat: 48.9609025, lng: 21.6133221 },
    { lat: 48.9618926, lng: 21.6136485 },
    { lat: 48.9627133, lng: 21.6149298 },
    { lat: 48.9647223, lng: 21.6167853 },
    { lat: 48.9647712, lng: 21.6167209 },
    { lat: 48.9652444, lng: 21.6155708 },
    { lat: 48.9655111, lng: 21.6145473 },
    { lat: 48.9657524, lng: 21.6142032 },
    { lat: 48.9661257, lng: 21.6129791 },
    { lat: 48.9662239, lng: 21.612175 },
    { lat: 48.9665077, lng: 21.6115772 },
    { lat: 48.9666082, lng: 21.6111046 },
    { lat: 48.9665949, lng: 21.610665 },
    { lat: 48.9663056, lng: 21.6091595 },
    { lat: 48.9662993, lng: 21.6089301 },
    { lat: 48.9664157, lng: 21.6083766 },
    { lat: 48.966734, lng: 21.607885 },
    { lat: 48.9669094, lng: 21.6075171 },
    { lat: 48.9669306, lng: 21.6068612 },
    { lat: 48.9674296, lng: 21.6045713 },
    { lat: 48.9677712, lng: 21.604191 },
    { lat: 48.9677424, lng: 21.6036793 },
    { lat: 48.9671256, lng: 21.603302 },
    { lat: 48.9659935, lng: 21.6005436 },
    { lat: 48.965098, lng: 21.599896 },
    { lat: 48.9646827, lng: 21.599205 },
    { lat: 48.9644084, lng: 21.5985642 },
    { lat: 48.9635234, lng: 21.5978518 },
    { lat: 48.9632437, lng: 21.5974154 },
    { lat: 48.9631034, lng: 21.5976786 },
    { lat: 48.961693, lng: 21.5970046 },
    { lat: 48.9603098, lng: 21.5966995 },
    { lat: 48.9602941, lng: 21.5963382 },
    { lat: 48.9599327, lng: 21.5964147 },
    { lat: 48.959297, lng: 21.5964147 },
    { lat: 48.9590185, lng: 21.5965561 },
    { lat: 48.9587937, lng: 21.5968369 },
    { lat: 48.9582072, lng: 21.5970367 },
    { lat: 48.9577581, lng: 21.5970619 },
    { lat: 48.957543, lng: 21.596857 },
    { lat: 48.9570808, lng: 21.596946 },
    { lat: 48.9567652, lng: 21.5969233 },
    { lat: 48.9560596, lng: 21.5970749 },
    { lat: 48.955341, lng: 21.5974135 },
    { lat: 48.954628, lng: 21.5960469 },
    { lat: 48.9540244, lng: 21.5967154 },
    { lat: 48.9540359, lng: 21.5946634 },
    { lat: 48.954021, lng: 21.5945354 },
  ],
  Davidov: [
    { lat: 48.7975554, lng: 21.5976072 },
    { lat: 48.7976644, lng: 21.5979653 },
    { lat: 48.7978203, lng: 21.5981948 },
    { lat: 48.7981021, lng: 21.5984534 },
    { lat: 48.7985275, lng: 21.5986139 },
    { lat: 48.7986512, lng: 21.5988144 },
    { lat: 48.798874, lng: 21.5988562 },
    { lat: 48.799283, lng: 21.5992844 },
    { lat: 48.7994612, lng: 21.5993355 },
    { lat: 48.7995651, lng: 21.5994334 },
    { lat: 48.7996089, lng: 21.5995912 },
    { lat: 48.7997131, lng: 21.5996588 },
    { lat: 48.8000335, lng: 21.5996854 },
    { lat: 48.8001511, lng: 21.5999117 },
    { lat: 48.800363, lng: 21.6001301 },
    { lat: 48.8004533, lng: 21.6006636 },
    { lat: 48.8008783, lng: 21.6016254 },
    { lat: 48.8008604, lng: 21.6017854 },
    { lat: 48.8009497, lng: 21.6020788 },
    { lat: 48.8009457, lng: 21.6023407 },
    { lat: 48.8009903, lng: 21.6025284 },
    { lat: 48.8010724, lng: 21.6026491 },
    { lat: 48.801094, lng: 21.6028148 },
    { lat: 48.8010402, lng: 21.603112 },
    { lat: 48.8012459, lng: 21.6040282 },
    { lat: 48.8014412, lng: 21.6045418 },
    { lat: 48.8014142, lng: 21.6053498 },
    { lat: 48.8016983, lng: 21.6058153 },
    { lat: 48.8016668, lng: 21.6062149 },
    { lat: 48.8017831, lng: 21.6064394 },
    { lat: 48.8018591, lng: 21.607187 },
    { lat: 48.8019945, lng: 21.6075192 },
    { lat: 48.8020153, lng: 21.6078705 },
    { lat: 48.8019439, lng: 21.608738 },
    { lat: 48.8021363, lng: 21.608942 },
    { lat: 48.8021691, lng: 21.6092315 },
    { lat: 48.8021222, lng: 21.6097299 },
    { lat: 48.8022757, lng: 21.6103501 },
    { lat: 48.8021916, lng: 21.6106842 },
    { lat: 48.8022753, lng: 21.6112717 },
    { lat: 48.8022513, lng: 21.6117058 },
    { lat: 48.8025013, lng: 21.6124372 },
    { lat: 48.80252, lng: 21.6129496 },
    { lat: 48.8026889, lng: 21.6139718 },
    { lat: 48.8028242, lng: 21.6141398 },
    { lat: 48.8028495, lng: 21.614281 },
    { lat: 48.8027686, lng: 21.6148575 },
    { lat: 48.8029625, lng: 21.6151918 },
    { lat: 48.8033729, lng: 21.6145782 },
    { lat: 48.8041515, lng: 21.6144102 },
    { lat: 48.8045914, lng: 21.6138821 },
    { lat: 48.8048994, lng: 21.6138347 },
    { lat: 48.8051813, lng: 21.6139003 },
    { lat: 48.8055984, lng: 21.6138841 },
    { lat: 48.8057236, lng: 21.6140196 },
    { lat: 48.8060962, lng: 21.6139459 },
    { lat: 48.8064269, lng: 21.6134948 },
    { lat: 48.8067867, lng: 21.6132042 },
    { lat: 48.8069667, lng: 21.6128005 },
    { lat: 48.8071883, lng: 21.6126753 },
    { lat: 48.8072906, lng: 21.6127145 },
    { lat: 48.8076532, lng: 21.6125201 },
    { lat: 48.8087363, lng: 21.6125097 },
    { lat: 48.8089226, lng: 21.6127766 },
    { lat: 48.8093152, lng: 21.6129287 },
    { lat: 48.8100165, lng: 21.6125785 },
    { lat: 48.8100977, lng: 21.6126665 },
    { lat: 48.8105156, lng: 21.6121202 },
    { lat: 48.8107198, lng: 21.6123417 },
    { lat: 48.8107428, lng: 21.6140187 },
    { lat: 48.8104036, lng: 21.61538 },
    { lat: 48.8104144, lng: 21.6158883 },
    { lat: 48.80969, lng: 21.6175614 },
    { lat: 48.8094886, lng: 21.6180918 },
    { lat: 48.8093811, lng: 21.6185973 },
    { lat: 48.8094658, lng: 21.6192944 },
    { lat: 48.8095821, lng: 21.6195958 },
    { lat: 48.8095548, lng: 21.6217778 },
    { lat: 48.8092281, lng: 21.6223772 },
    { lat: 48.8093679, lng: 21.6227938 },
    { lat: 48.8095703, lng: 21.6236816 },
    { lat: 48.8098329, lng: 21.62565 },
    { lat: 48.8100462, lng: 21.6265257 },
    { lat: 48.8105451, lng: 21.6282582 },
    { lat: 48.8113991, lng: 21.6309482 },
    { lat: 48.8115239, lng: 21.6312245 },
    { lat: 48.8116093, lng: 21.631631 },
    { lat: 48.8118497, lng: 21.6320606 },
    { lat: 48.8119518, lng: 21.6323772 },
    { lat: 48.8119505, lng: 21.6328617 },
    { lat: 48.8120115, lng: 21.6331174 },
    { lat: 48.8128182, lng: 21.634591 },
    { lat: 48.813056, lng: 21.6347677 },
    { lat: 48.813236, lng: 21.6354999 },
    { lat: 48.8123871, lng: 21.6361708 },
    { lat: 48.8124217, lng: 21.6366105 },
    { lat: 48.8129665, lng: 21.6377235 },
    { lat: 48.8136275, lng: 21.6387224 },
    { lat: 48.8137239, lng: 21.6389926 },
    { lat: 48.8139343, lng: 21.6407957 },
    { lat: 48.8139592, lng: 21.6413314 },
    { lat: 48.8137504, lng: 21.6414299 },
    { lat: 48.8140462, lng: 21.6423258 },
    { lat: 48.8142863, lng: 21.6433263 },
    { lat: 48.8142949, lng: 21.6449501 },
    { lat: 48.8143338, lng: 21.6450941 },
    { lat: 48.8143383, lng: 21.6455407 },
    { lat: 48.8150444, lng: 21.6456101 },
    { lat: 48.8183206, lng: 21.6476119 },
    { lat: 48.8188322, lng: 21.6478875 },
    { lat: 48.8193411, lng: 21.6480636 },
    { lat: 48.8198303, lng: 21.6481834 },
    { lat: 48.8201183, lng: 21.6481375 },
    { lat: 48.8205863, lng: 21.647812 },
    { lat: 48.8208316, lng: 21.6477294 },
    { lat: 48.8210935, lng: 21.6478461 },
    { lat: 48.8215151, lng: 21.6478753 },
    { lat: 48.8230901, lng: 21.649098 },
    { lat: 48.8232705, lng: 21.6499353 },
    { lat: 48.8232579, lng: 21.6502582 },
    { lat: 48.8230574, lng: 21.6506586 },
    { lat: 48.8230817, lng: 21.650882 },
    { lat: 48.8229084, lng: 21.6514138 },
    { lat: 48.8230398, lng: 21.651658 },
    { lat: 48.8236751, lng: 21.6512681 },
    { lat: 48.8241701, lng: 21.6508596 },
    { lat: 48.8252596, lng: 21.6504651 },
    { lat: 48.825292, lng: 21.650333 },
    { lat: 48.8283539, lng: 21.6496565 },
    { lat: 48.8289886, lng: 21.6493306 },
    { lat: 48.8308513, lng: 21.6491123 },
    { lat: 48.8309434, lng: 21.6491004 },
    { lat: 48.8309784, lng: 21.6490718 },
    { lat: 48.8311106, lng: 21.6488803 },
    { lat: 48.8312246, lng: 21.6485656 },
    { lat: 48.8322973, lng: 21.6479738 },
    { lat: 48.8323902, lng: 21.6478726 },
    { lat: 48.8324168, lng: 21.6479399 },
    { lat: 48.8346749, lng: 21.647122 },
    { lat: 48.8359731, lng: 21.6471177 },
    { lat: 48.8364387, lng: 21.6469102 },
    { lat: 48.8374994, lng: 21.6461201 },
    { lat: 48.8379882, lng: 21.645686 },
    { lat: 48.8379678, lng: 21.6453095 },
    { lat: 48.8383963, lng: 21.6451003 },
    { lat: 48.8386027, lng: 21.6442651 },
    { lat: 48.8386334, lng: 21.643452 },
    { lat: 48.8386766, lng: 21.6432632 },
    { lat: 48.8389129, lng: 21.6431472 },
    { lat: 48.8393213, lng: 21.6403605 },
    { lat: 48.8393026, lng: 21.6398452 },
    { lat: 48.83936, lng: 21.6396434 },
    { lat: 48.8395182, lng: 21.6396273 },
    { lat: 48.8396343, lng: 21.6393896 },
    { lat: 48.8397744, lng: 21.6393683 },
    { lat: 48.8403174, lng: 21.6383749 },
    { lat: 48.8405043, lng: 21.6373973 },
    { lat: 48.8409965, lng: 21.6357 },
    { lat: 48.8409111, lng: 21.635011 },
    { lat: 48.8409815, lng: 21.6349915 },
    { lat: 48.8412016, lng: 21.6344836 },
    { lat: 48.8416737, lng: 21.6341016 },
    { lat: 48.8422339, lng: 21.6334454 },
    { lat: 48.842391, lng: 21.6326192 },
    { lat: 48.8423591, lng: 21.6319044 },
    { lat: 48.8423891, lng: 21.6318903 },
    { lat: 48.8423517, lng: 21.6316362 },
    { lat: 48.8421559, lng: 21.6313496 },
    { lat: 48.8420475, lng: 21.631334 },
    { lat: 48.8420411, lng: 21.631104 },
    { lat: 48.8419515, lng: 21.630999 },
    { lat: 48.8418908, lng: 21.6307585 },
    { lat: 48.8420279, lng: 21.6303437 },
    { lat: 48.8420501, lng: 21.630123 },
    { lat: 48.8420409, lng: 21.6298739 },
    { lat: 48.8419724, lng: 21.6295982 },
    { lat: 48.8416962, lng: 21.6297782 },
    { lat: 48.8416436, lng: 21.6299915 },
    { lat: 48.8415139, lng: 21.6301448 },
    { lat: 48.8414493, lng: 21.630087 },
    { lat: 48.8414317, lng: 21.6299782 },
    { lat: 48.8414839, lng: 21.6297934 },
    { lat: 48.8413885, lng: 21.6294726 },
    { lat: 48.8412709, lng: 21.6287217 },
    { lat: 48.8413962, lng: 21.6285281 },
    { lat: 48.8413329, lng: 21.6282335 },
    { lat: 48.8414097, lng: 21.6278746 },
    { lat: 48.8414093, lng: 21.627576 },
    { lat: 48.8413334, lng: 21.6275198 },
    { lat: 48.8412451, lng: 21.6270542 },
    { lat: 48.8411127, lng: 21.6269387 },
    { lat: 48.8411254, lng: 21.6268336 },
    { lat: 48.8412102, lng: 21.6268061 },
    { lat: 48.8412524, lng: 21.6266616 },
    { lat: 48.8410752, lng: 21.6262781 },
    { lat: 48.841198, lng: 21.6261596 },
    { lat: 48.8411972, lng: 21.6260536 },
    { lat: 48.8410001, lng: 21.6256778 },
    { lat: 48.8410671, lng: 21.6255357 },
    { lat: 48.8409782, lng: 21.6254559 },
    { lat: 48.8409661, lng: 21.6252588 },
    { lat: 48.8410698, lng: 21.6251472 },
    { lat: 48.8410236, lng: 21.624738 },
    { lat: 48.8410794, lng: 21.6243912 },
    { lat: 48.8409817, lng: 21.6243217 },
    { lat: 48.8411214, lng: 21.6238724 },
    { lat: 48.8411032, lng: 21.623506 },
    { lat: 48.8411183, lng: 21.6232754 },
    { lat: 48.8411914, lng: 21.6231568 },
    { lat: 48.8411808, lng: 21.6229342 },
    { lat: 48.8413883, lng: 21.6225888 },
    { lat: 48.8414594, lng: 21.622549 },
    { lat: 48.8414269, lng: 21.6223967 },
    { lat: 48.8414919, lng: 21.6220633 },
    { lat: 48.8425635, lng: 21.6206918 },
    { lat: 48.8426795, lng: 21.6200454 },
    { lat: 48.842658, lng: 21.6198804 },
    { lat: 48.8428528, lng: 21.6196033 },
    { lat: 48.8428841, lng: 21.6193891 },
    { lat: 48.8430344, lng: 21.6192121 },
    { lat: 48.843225, lng: 21.6186984 },
    { lat: 48.8441476, lng: 21.6150914 },
    { lat: 48.8450257, lng: 21.6131991 },
    { lat: 48.8454365, lng: 21.6118248 },
    { lat: 48.8457779, lng: 21.6108665 },
    { lat: 48.8461604, lng: 21.6086034 },
    { lat: 48.8490962, lng: 21.6039543 },
    { lat: 48.849222, lng: 21.6036637 },
    { lat: 48.8493205, lng: 21.6030019 },
    { lat: 48.8495426, lng: 21.6024079 },
    { lat: 48.8500054, lng: 21.6008514 },
    { lat: 48.8502782, lng: 21.600062 },
    { lat: 48.8505412, lng: 21.5995697 },
    { lat: 48.8506831, lng: 21.5983995 },
    { lat: 48.8507814, lng: 21.5980096 },
    { lat: 48.850714, lng: 21.5975291 },
    { lat: 48.8507843, lng: 21.5965821 },
    { lat: 48.8503218, lng: 21.5960101 },
    { lat: 48.8500521, lng: 21.5949381 },
    { lat: 48.8499764, lng: 21.5942771 },
    { lat: 48.8497588, lng: 21.5939398 },
    { lat: 48.8493561, lng: 21.5928146 },
    { lat: 48.8489309, lng: 21.5913675 },
    { lat: 48.8484237, lng: 21.5912957 },
    { lat: 48.845992, lng: 21.591119 },
    { lat: 48.84203, lng: 21.5917066 },
    { lat: 48.8406617, lng: 21.5918264 },
    { lat: 48.8380802, lng: 21.5921726 },
    { lat: 48.8375364, lng: 21.5925638 },
    { lat: 48.8370585, lng: 21.5926927 },
    { lat: 48.8368796, lng: 21.5928963 },
    { lat: 48.8361591, lng: 21.593201 },
    { lat: 48.8358497, lng: 21.5930787 },
    { lat: 48.8351954, lng: 21.5934238 },
    { lat: 48.8351624, lng: 21.5932477 },
    { lat: 48.8347006, lng: 21.593148 },
    { lat: 48.8344251, lng: 21.5933528 },
    { lat: 48.8341784, lng: 21.592979 },
    { lat: 48.8327339, lng: 21.5921387 },
    { lat: 48.8322477, lng: 21.5919327 },
    { lat: 48.8305778, lng: 21.5915623 },
    { lat: 48.8301981, lng: 21.5916705 },
    { lat: 48.8294227, lng: 21.5921855 },
    { lat: 48.8290261, lng: 21.5922863 },
    { lat: 48.8289149, lng: 21.5922884 },
    { lat: 48.8286432, lng: 21.5919898 },
    { lat: 48.8284314, lng: 21.5918941 },
    { lat: 48.8273, lng: 21.5917952 },
    { lat: 48.8269416, lng: 21.5920142 },
    { lat: 48.8265871, lng: 21.5925725 },
    { lat: 48.826498, lng: 21.5928121 },
    { lat: 48.8260843, lng: 21.5932271 },
    { lat: 48.8259683, lng: 21.5937858 },
    { lat: 48.8261067, lng: 21.5940287 },
    { lat: 48.8260656, lng: 21.5944488 },
    { lat: 48.8258096, lng: 21.5951083 },
    { lat: 48.8258424, lng: 21.5955593 },
    { lat: 48.8245674, lng: 21.5959158 },
    { lat: 48.8245585, lng: 21.5968455 },
    { lat: 48.8244982, lng: 21.5969083 },
    { lat: 48.8244933, lng: 21.5970957 },
    { lat: 48.8242815, lng: 21.5971603 },
    { lat: 48.823727, lng: 21.5975342 },
    { lat: 48.823364, lng: 21.596422 },
    { lat: 48.8233281, lng: 21.5957352 },
    { lat: 48.8234568, lng: 21.5951853 },
    { lat: 48.8225063, lng: 21.5956025 },
    { lat: 48.8222211, lng: 21.5958578 },
    { lat: 48.8214313, lng: 21.5957398 },
    { lat: 48.8212465, lng: 21.5961308 },
    { lat: 48.8209686, lng: 21.5959315 },
    { lat: 48.8208895, lng: 21.5955553 },
    { lat: 48.8206248, lng: 21.5953901 },
    { lat: 48.8203874, lng: 21.5940546 },
    { lat: 48.8201892, lng: 21.5936442 },
    { lat: 48.8200135, lng: 21.5938012 },
    { lat: 48.819812, lng: 21.5930721 },
    { lat: 48.819695, lng: 21.5928329 },
    { lat: 48.8190617, lng: 21.5933623 },
    { lat: 48.8188481, lng: 21.5933631 },
    { lat: 48.818417, lng: 21.5927184 },
    { lat: 48.8181858, lng: 21.5921288 },
    { lat: 48.8178964, lng: 21.5919249 },
    { lat: 48.8177213, lng: 21.5918911 },
    { lat: 48.8170476, lng: 21.5909364 },
    { lat: 48.816905, lng: 21.5905068 },
    { lat: 48.8165713, lng: 21.5906796 },
    { lat: 48.8155505, lng: 21.5919235 },
    { lat: 48.8152608, lng: 21.5916996 },
    { lat: 48.8151836, lng: 21.5914329 },
    { lat: 48.8152537, lng: 21.591081 },
    { lat: 48.8150864, lng: 21.5906548 },
    { lat: 48.8149336, lng: 21.5904154 },
    { lat: 48.8150512, lng: 21.5901201 },
    { lat: 48.8151647, lng: 21.589306 },
    { lat: 48.8151605, lng: 21.5885584 },
    { lat: 48.815007, lng: 21.588294 },
    { lat: 48.8135157, lng: 21.5877517 },
    { lat: 48.8129137, lng: 21.588197 },
    { lat: 48.8121148, lng: 21.5884706 },
    { lat: 48.8116464, lng: 21.5887844 },
    { lat: 48.8110888, lng: 21.5888434 },
    { lat: 48.8097753, lng: 21.5884225 },
    { lat: 48.809344, lng: 21.5881257 },
    { lat: 48.8079546, lng: 21.5876851 },
    { lat: 48.8065297, lng: 21.5888034 },
    { lat: 48.7985068, lng: 21.5967473 },
    { lat: 48.7975554, lng: 21.5976072 },
  ],
  Detrík: [
    { lat: 49.0265665, lng: 21.6334718 },
    { lat: 49.0265855, lng: 21.6334663 },
    { lat: 49.0266566, lng: 21.6337644 },
    { lat: 49.0267786, lng: 21.6339076 },
    { lat: 49.0268482, lng: 21.6341505 },
    { lat: 49.0269709, lng: 21.6343489 },
    { lat: 49.0269041, lng: 21.6345453 },
    { lat: 49.0269949, lng: 21.6352267 },
    { lat: 49.0270534, lng: 21.6353879 },
    { lat: 49.0269939, lng: 21.6355154 },
    { lat: 49.0269988, lng: 21.6356936 },
    { lat: 49.0271462, lng: 21.6357803 },
    { lat: 49.027168, lng: 21.6363289 },
    { lat: 49.0274129, lng: 21.6366442 },
    { lat: 49.0273632, lng: 21.6369618 },
    { lat: 49.0274981, lng: 21.6372906 },
    { lat: 49.0275071, lng: 21.6376184 },
    { lat: 49.0275864, lng: 21.6377589 },
    { lat: 49.0275901, lng: 21.638637 },
    { lat: 49.0277124, lng: 21.6387468 },
    { lat: 49.0277055, lng: 21.6390266 },
    { lat: 49.0278306, lng: 21.6391201 },
    { lat: 49.0278085, lng: 21.6392143 },
    { lat: 49.027896, lng: 21.6395788 },
    { lat: 49.027933, lng: 21.6395269 },
    { lat: 49.0280105, lng: 21.6397303 },
    { lat: 49.0278642, lng: 21.6400185 },
    { lat: 49.0281209, lng: 21.6401033 },
    { lat: 49.0283957, lng: 21.640813 },
    { lat: 49.0285162, lng: 21.6412728 },
    { lat: 49.0283834, lng: 21.6413909 },
    { lat: 49.0284862, lng: 21.6418186 },
    { lat: 49.0284173, lng: 21.6419305 },
    { lat: 49.02842, lng: 21.6420293 },
    { lat: 49.0287233, lng: 21.642551 },
    { lat: 49.0283993, lng: 21.6432007 },
    { lat: 49.0284241, lng: 21.6441083 },
    { lat: 49.0281722, lng: 21.644524 },
    { lat: 49.0282721, lng: 21.6445586 },
    { lat: 49.0285199, lng: 21.644359 },
    { lat: 49.0293116, lng: 21.6442321 },
    { lat: 49.0293761, lng: 21.6442829 },
    { lat: 49.0294363, lng: 21.6441827 },
    { lat: 49.0295635, lng: 21.6442298 },
    { lat: 49.0297167, lng: 21.6441925 },
    { lat: 49.0300664, lng: 21.6444314 },
    { lat: 49.0305994, lng: 21.6450849 },
    { lat: 49.0311378, lng: 21.6456005 },
    { lat: 49.0314939, lng: 21.6457429 },
    { lat: 49.0321296, lng: 21.6458676 },
    { lat: 49.0323995, lng: 21.6458229 },
    { lat: 49.0336568, lng: 21.6460596 },
    { lat: 49.0341664, lng: 21.6451081 },
    { lat: 49.0349479, lng: 21.6444799 },
    { lat: 49.0353692, lng: 21.6440409 },
    { lat: 49.0369754, lng: 21.6432931 },
    { lat: 49.0375193, lng: 21.6433552 },
    { lat: 49.0386626, lng: 21.64419 },
    { lat: 49.0391127, lng: 21.6448044 },
    { lat: 49.0392183, lng: 21.6453363 },
    { lat: 49.0394728, lng: 21.6460492 },
    { lat: 49.0395374, lng: 21.6464299 },
    { lat: 49.0397208, lng: 21.6468855 },
    { lat: 49.03997, lng: 21.6470759 },
    { lat: 49.0407147, lng: 21.6467402 },
    { lat: 49.0407949, lng: 21.6486041 },
    { lat: 49.0410602, lng: 21.6489283 },
    { lat: 49.0413636, lng: 21.6487883 },
    { lat: 49.0415122, lng: 21.6486276 },
    { lat: 49.0416495, lng: 21.6480089 },
    { lat: 49.0420684, lng: 21.6472868 },
    { lat: 49.0424288, lng: 21.6474289 },
    { lat: 49.0427624, lng: 21.6480677 },
    { lat: 49.0431534, lng: 21.6484966 },
    { lat: 49.0433194, lng: 21.6489397 },
    { lat: 49.0437092, lng: 21.6496574 },
    { lat: 49.0434594, lng: 21.6507673 },
    { lat: 49.043187, lng: 21.652416 },
    { lat: 49.043267, lng: 21.652376 },
    { lat: 49.043682, lng: 21.651791 },
    { lat: 49.044517, lng: 21.650533 },
    { lat: 49.044876, lng: 21.650218 },
    { lat: 49.045112, lng: 21.649843 },
    { lat: 49.045361, lng: 21.649485 },
    { lat: 49.045488, lng: 21.649343 },
    { lat: 49.045664, lng: 21.649212 },
    { lat: 49.045798, lng: 21.649072 },
    { lat: 49.045925, lng: 21.648879 },
    { lat: 49.046016, lng: 21.64866 },
    { lat: 49.046859, lng: 21.647331 },
    { lat: 49.047117, lng: 21.647157 },
    { lat: 49.047232, lng: 21.647032 },
    { lat: 49.047279, lng: 21.646819 },
    { lat: 49.047276, lng: 21.646653 },
    { lat: 49.047206, lng: 21.646434 },
    { lat: 49.047604, lng: 21.644425 },
    { lat: 49.047664, lng: 21.644052 },
    { lat: 49.047735, lng: 21.643929 },
    { lat: 49.048087, lng: 21.643044 },
    { lat: 49.048379, lng: 21.64239 },
    { lat: 49.048698, lng: 21.641933 },
    { lat: 49.049304, lng: 21.641129 },
    { lat: 49.049378, lng: 21.640898 },
    { lat: 49.049417, lng: 21.640448 },
    { lat: 49.049675, lng: 21.640044 },
    { lat: 49.050193, lng: 21.639482 },
    { lat: 49.050318, lng: 21.639351 },
    { lat: 49.0505, lng: 21.639223 },
    { lat: 49.050625, lng: 21.639057 },
    { lat: 49.050934, lng: 21.638698 },
    { lat: 49.051097, lng: 21.638582 },
    { lat: 49.05122, lng: 21.638453 },
    { lat: 49.051569, lng: 21.638073 },
    { lat: 49.052071, lng: 21.638033 },
    { lat: 49.052925, lng: 21.637659 },
    { lat: 49.053248, lng: 21.636899 },
    { lat: 49.053406, lng: 21.636676 },
    { lat: 49.054051, lng: 21.636077 },
    { lat: 49.05401, lng: 21.634748 },
    { lat: 49.054144, lng: 21.634098 },
    { lat: 49.054315, lng: 21.633785 },
    { lat: 49.054892, lng: 21.632793 },
    { lat: 49.05494, lng: 21.632832 },
    { lat: 49.055269, lng: 21.633108 },
    { lat: 49.055811, lng: 21.633264 },
    { lat: 49.056167, lng: 21.633267 },
    { lat: 49.056608, lng: 21.632947 },
    { lat: 49.05689, lng: 21.632544 },
    { lat: 49.057161, lng: 21.632083 },
    { lat: 49.057835, lng: 21.632174 },
    { lat: 49.058138, lng: 21.632348 },
    { lat: 49.058509, lng: 21.632228 },
    { lat: 49.058764, lng: 21.632311 },
    { lat: 49.059105, lng: 21.632327 },
    { lat: 49.059358, lng: 21.63238 },
    { lat: 49.059362, lng: 21.632323 },
    { lat: 49.059313, lng: 21.631793 },
    { lat: 49.059206, lng: 21.630997 },
    { lat: 49.059364, lng: 21.630157 },
    { lat: 49.059468, lng: 21.629527 },
    { lat: 49.059578, lng: 21.628116 },
    { lat: 49.059845, lng: 21.627456 },
    { lat: 49.060026, lng: 21.626744 },
    { lat: 49.060324, lng: 21.62602 },
    { lat: 49.060655, lng: 21.625615 },
    { lat: 49.061601, lng: 21.624581 },
    { lat: 49.062604, lng: 21.623471 },
    { lat: 49.063218, lng: 21.622847 },
    { lat: 49.063855, lng: 21.622159 },
    { lat: 49.064182, lng: 21.621836 },
    { lat: 49.064741, lng: 21.621352 },
    { lat: 49.064829, lng: 21.621302 },
    { lat: 49.064855, lng: 21.621138 },
    { lat: 49.064678, lng: 21.6207294 },
    { lat: 49.0640356, lng: 21.6210548 },
    { lat: 49.0634721, lng: 21.6215468 },
    { lat: 49.0634708, lng: 21.6196261 },
    { lat: 49.0638811, lng: 21.6185089 },
    { lat: 49.063627, lng: 21.6182697 },
    { lat: 49.061859, lng: 21.617838 },
    { lat: 49.0614349, lng: 21.6173933 },
    { lat: 49.061462, lng: 21.6167881 },
    { lat: 49.0612879, lng: 21.6149198 },
    { lat: 49.0611137, lng: 21.6145205 },
    { lat: 49.0609676, lng: 21.6143602 },
    { lat: 49.0607338, lng: 21.6142492 },
    { lat: 49.0604228, lng: 21.6138655 },
    { lat: 49.0600978, lng: 21.6135767 },
    { lat: 49.0596111, lng: 21.6133097 },
    { lat: 49.0592969, lng: 21.6132577 },
    { lat: 49.0592586, lng: 21.6131339 },
    { lat: 49.0591207, lng: 21.6130325 },
    { lat: 49.0588852, lng: 21.6126925 },
    { lat: 49.0585081, lng: 21.6118262 },
    { lat: 49.0584889, lng: 21.6110547 },
    { lat: 49.0583118, lng: 21.6098579 },
    { lat: 49.0582082, lng: 21.6085064 },
    { lat: 49.057974, lng: 21.6086218 },
    { lat: 49.0573402, lng: 21.6087302 },
    { lat: 49.0563883, lng: 21.6090537 },
    { lat: 49.0556472, lng: 21.6097469 },
    { lat: 49.0549965, lng: 21.611205 },
    { lat: 49.0545138, lng: 21.6107481 },
    { lat: 49.053976, lng: 21.6100231 },
    { lat: 49.0534776, lng: 21.6099363 },
    { lat: 49.0522583, lng: 21.6102242 },
    { lat: 49.0520889, lng: 21.6100399 },
    { lat: 49.0519559, lng: 21.6090161 },
    { lat: 49.0517931, lng: 21.608416 },
    { lat: 49.0518425, lng: 21.6083767 },
    { lat: 49.0515421, lng: 21.6082474 },
    { lat: 49.0502257, lng: 21.608129 },
    { lat: 49.0499967, lng: 21.6079393 },
    { lat: 49.0498349, lng: 21.6079091 },
    { lat: 49.0496012, lng: 21.6082038 },
    { lat: 49.0493898, lng: 21.60809 },
    { lat: 49.0489558, lng: 21.6084962 },
    { lat: 49.0488496, lng: 21.6082796 },
    { lat: 49.0487273, lng: 21.6082479 },
    { lat: 49.0482375, lng: 21.6085253 },
    { lat: 49.0479242, lng: 21.6084234 },
    { lat: 49.0476658, lng: 21.6088385 },
    { lat: 49.0475719, lng: 21.608776 },
    { lat: 49.047495, lng: 21.6085614 },
    { lat: 49.047426, lng: 21.6086336 },
    { lat: 49.0473857, lng: 21.6088801 },
    { lat: 49.0472538, lng: 21.6089104 },
    { lat: 49.0471243, lng: 21.6087874 },
    { lat: 49.0468059, lng: 21.6090732 },
    { lat: 49.0466013, lng: 21.6090307 },
    { lat: 49.0460761, lng: 21.6093243 },
    { lat: 49.0441604, lng: 21.6095785 },
    { lat: 49.0428174, lng: 21.6093858 },
    { lat: 49.042411, lng: 21.6092153 },
    { lat: 49.0420418, lng: 21.6087197 },
    { lat: 49.0418865, lng: 21.6087996 },
    { lat: 49.0417646, lng: 21.6087808 },
    { lat: 49.0416879, lng: 21.6086954 },
    { lat: 49.0415485, lng: 21.6086993 },
    { lat: 49.0413895, lng: 21.6086026 },
    { lat: 49.0412713, lng: 21.6083606 },
    { lat: 49.0407664, lng: 21.6078356 },
    { lat: 49.0405925, lng: 21.6073253 },
    { lat: 49.0403575, lng: 21.606928 },
    { lat: 49.040153, lng: 21.6066947 },
    { lat: 49.0397108, lng: 21.6063652 },
    { lat: 49.0393302, lng: 21.606149 },
    { lat: 49.0388466, lng: 21.6060336 },
    { lat: 49.0383226, lng: 21.6060389 },
    { lat: 49.0381647, lng: 21.6062272 },
    { lat: 49.037524, lng: 21.6063303 },
    { lat: 49.0372931, lng: 21.6065218 },
    { lat: 49.0365321, lng: 21.6067535 },
    { lat: 49.0360396, lng: 21.6067183 },
    { lat: 49.0354249, lng: 21.6065031 },
    { lat: 49.0352087, lng: 21.6065338 },
    { lat: 49.0354172, lng: 21.6080551 },
    { lat: 49.0352085, lng: 21.6084349 },
    { lat: 49.0348566, lng: 21.6088135 },
    { lat: 49.0336925, lng: 21.609395 },
    { lat: 49.0332189, lng: 21.6082937 },
    { lat: 49.0331191, lng: 21.6078236 },
    { lat: 49.0328735, lng: 21.6080128 },
    { lat: 49.0328196, lng: 21.6077682 },
    { lat: 49.0327808, lng: 21.6065286 },
    { lat: 49.0328174, lng: 21.6063546 },
    { lat: 49.033024, lng: 21.6063736 },
    { lat: 49.0330414, lng: 21.6050946 },
    { lat: 49.0331769, lng: 21.6050752 },
    { lat: 49.0332208, lng: 21.6048712 },
    { lat: 49.0336648, lng: 21.6038363 },
    { lat: 49.0339496, lng: 21.6030386 },
    { lat: 49.0334125, lng: 21.6023742 },
    { lat: 49.0336748, lng: 21.6016478 },
    { lat: 49.0330713, lng: 21.6011393 },
    { lat: 49.0332554, lng: 21.6006023 },
    { lat: 49.0327217, lng: 21.600221 },
    { lat: 49.0325128, lng: 21.6010884 },
    { lat: 49.0322417, lng: 21.6041333 },
    { lat: 49.0321139, lng: 21.6049135 },
    { lat: 49.0317292, lng: 21.6057319 },
    { lat: 49.0322166, lng: 21.6063081 },
    { lat: 49.0324627, lng: 21.6068681 },
    { lat: 49.0323297, lng: 21.6077081 },
    { lat: 49.0319229, lng: 21.6085064 },
    { lat: 49.0317026, lng: 21.6091566 },
    { lat: 49.0317152, lng: 21.6096559 },
    { lat: 49.0315204, lng: 21.6101402 },
    { lat: 49.0312116, lng: 21.6107723 },
    { lat: 49.0307124, lng: 21.6112707 },
    { lat: 49.0302096, lng: 21.6113914 },
    { lat: 49.0299999, lng: 21.6113353 },
    { lat: 49.0290694, lng: 21.6119067 },
    { lat: 49.0287683, lng: 21.6122795 },
    { lat: 49.0282956, lng: 21.6131307 },
    { lat: 49.028092, lng: 21.613375 },
    { lat: 49.0279687, lng: 21.6136811 },
    { lat: 49.0268635, lng: 21.6146025 },
    { lat: 49.0266317, lng: 21.6146789 },
    { lat: 49.0265007, lng: 21.6143736 },
    { lat: 49.0263096, lng: 21.6143406 },
    { lat: 49.026128, lng: 21.6142042 },
    { lat: 49.0252709, lng: 21.613966 },
    { lat: 49.0246751, lng: 21.6138636 },
    { lat: 49.0243026, lng: 21.6139827 },
    { lat: 49.0241741, lng: 21.6145792 },
    { lat: 49.0240661, lng: 21.6161312 },
    { lat: 49.0241269, lng: 21.6167783 },
    { lat: 49.0240523, lng: 21.617543 },
    { lat: 49.0241076, lng: 21.6185314 },
    { lat: 49.0241517, lng: 21.6190695 },
    { lat: 49.0242632, lng: 21.6195571 },
    { lat: 49.0248222, lng: 21.6207816 },
    { lat: 49.0248712, lng: 21.6211738 },
    { lat: 49.0245149, lng: 21.6215873 },
    { lat: 49.0240369, lng: 21.6217981 },
    { lat: 49.0237993, lng: 21.6220373 },
    { lat: 49.0237932, lng: 21.6223467 },
    { lat: 49.023531, lng: 21.6224666 },
    { lat: 49.0233826, lng: 21.6244713 },
    { lat: 49.0234288, lng: 21.6256198 },
    { lat: 49.0236653, lng: 21.6259887 },
    { lat: 49.0240115, lng: 21.6268417 },
    { lat: 49.0243436, lng: 21.6291804 },
    { lat: 49.0245205, lng: 21.629723 },
    { lat: 49.0245656, lng: 21.6302159 },
    { lat: 49.0252982, lng: 21.6307935 },
    { lat: 49.0254683, lng: 21.6307644 },
    { lat: 49.0260814, lng: 21.6312513 },
    { lat: 49.0262802, lng: 21.6317331 },
    { lat: 49.0265522, lng: 21.6326246 },
    { lat: 49.0265749, lng: 21.6328741 },
    { lat: 49.0265033, lng: 21.6332217 },
    { lat: 49.0265665, lng: 21.6334718 },
  ],
  SečovskáPolianka: [
    { lat: 48.7882274, lng: 21.7317187 },
    { lat: 48.787852, lng: 21.7304705 },
    { lat: 48.7876836, lng: 21.7298511 },
    { lat: 48.7875472, lng: 21.7290014 },
    { lat: 48.7877101, lng: 21.7285358 },
    { lat: 48.7877508, lng: 21.7282871 },
    { lat: 48.7877358, lng: 21.7279826 },
    { lat: 48.7874229, lng: 21.7262111 },
    { lat: 48.7887688, lng: 21.7258031 },
    { lat: 48.7884775, lng: 21.7249633 },
    { lat: 48.7881647, lng: 21.7242514 },
    { lat: 48.7911899, lng: 21.7226106 },
    { lat: 48.7911615, lng: 21.7215173 },
    { lat: 48.791683, lng: 21.7217904 },
    { lat: 48.7918331, lng: 21.7219274 },
    { lat: 48.7919299, lng: 21.7222219 },
    { lat: 48.7922754, lng: 21.7219356 },
    { lat: 48.7926448, lng: 21.7213202 },
    { lat: 48.7927257, lng: 21.7210676 },
    { lat: 48.7927431, lng: 21.7207507 },
    { lat: 48.792679, lng: 21.7204161 },
    { lat: 48.7925523, lng: 21.7202208 },
    { lat: 48.7919922, lng: 21.7196412 },
    { lat: 48.7919059, lng: 21.7193182 },
    { lat: 48.7918493, lng: 21.7185535 },
    { lat: 48.7918576, lng: 21.7180267 },
    { lat: 48.7922814, lng: 21.7175924 },
    { lat: 48.7924608, lng: 21.7175228 },
    { lat: 48.792677, lng: 21.7175784 },
    { lat: 48.7926977, lng: 21.7180574 },
    { lat: 48.7926509, lng: 21.7184489 },
    { lat: 48.792734, lng: 21.7188662 },
    { lat: 48.7931349, lng: 21.7190813 },
    { lat: 48.7935442, lng: 21.7196182 },
    { lat: 48.793942, lng: 21.7198132 },
    { lat: 48.7943117, lng: 21.7207168 },
    { lat: 48.7945233, lng: 21.7208015 },
    { lat: 48.795259, lng: 21.7204662 },
    { lat: 48.7956569, lng: 21.720664 },
    { lat: 48.7958481, lng: 21.721579 },
    { lat: 48.7958322, lng: 21.7217866 },
    { lat: 48.7957017, lng: 21.7220797 },
    { lat: 48.7956983, lng: 21.7223761 },
    { lat: 48.7958038, lng: 21.7229516 },
    { lat: 48.7960022, lng: 21.7234319 },
    { lat: 48.7958984, lng: 21.724719 },
    { lat: 48.7960279, lng: 21.7258925 },
    { lat: 48.7961734, lng: 21.7261591 },
    { lat: 48.7965882, lng: 21.7265229 },
    { lat: 48.7969056, lng: 21.7264464 },
    { lat: 48.7972275, lng: 21.7257821 },
    { lat: 48.7974736, lng: 21.724806 },
    { lat: 48.7972806, lng: 21.7241923 },
    { lat: 48.7972687, lng: 21.7238812 },
    { lat: 48.7973103, lng: 21.7236195 },
    { lat: 48.7974219, lng: 21.7234671 },
    { lat: 48.7983973, lng: 21.7238033 },
    { lat: 48.7989109, lng: 21.7237371 },
    { lat: 48.7991767, lng: 21.7236194 },
    { lat: 48.7994807, lng: 21.7237553 },
    { lat: 48.7994235, lng: 21.7239783 },
    { lat: 48.7994493, lng: 21.7241401 },
    { lat: 48.7997086, lng: 21.7248683 },
    { lat: 48.7998032, lng: 21.7253814 },
    { lat: 48.8000198, lng: 21.7259508 },
    { lat: 48.800791, lng: 21.7260049 },
    { lat: 48.8017409, lng: 21.7266931 },
    { lat: 48.8019591, lng: 21.7263536 },
    { lat: 48.8020539, lng: 21.7256094 },
    { lat: 48.8022081, lng: 21.7251092 },
    { lat: 48.8023223, lng: 21.7250508 },
    { lat: 48.8025398, lng: 21.7251438 },
    { lat: 48.8026483, lng: 21.7250386 },
    { lat: 48.802554, lng: 21.7245394 },
    { lat: 48.8026508, lng: 21.724375 },
    { lat: 48.8018434, lng: 21.7119932 },
    { lat: 48.8018826, lng: 21.7114876 },
    { lat: 48.8014837, lng: 21.7054834 },
    { lat: 48.8017326, lng: 21.704827 },
    { lat: 48.8023385, lng: 21.7041433 },
    { lat: 48.8026523, lng: 21.7039362 },
    { lat: 48.8030148, lng: 21.7032753 },
    { lat: 48.8030047, lng: 21.7031909 },
    { lat: 48.803623, lng: 21.7030028 },
    { lat: 48.803822, lng: 21.7028381 },
    { lat: 48.8041313, lng: 21.7024089 },
    { lat: 48.8043379, lng: 21.7018968 },
    { lat: 48.8047332, lng: 21.7000011 },
    { lat: 48.8047918, lng: 21.6999639 },
    { lat: 48.8048699, lng: 21.6995187 },
    { lat: 48.8048179, lng: 21.6994733 },
    { lat: 48.8048596, lng: 21.6987652 },
    { lat: 48.8047827, lng: 21.698292 },
    { lat: 48.804606, lng: 21.6978693 },
    { lat: 48.8023571, lng: 21.6942591 },
    { lat: 48.8021505, lng: 21.6937326 },
    { lat: 48.8020431, lng: 21.6932582 },
    { lat: 48.8019966, lng: 21.6927432 },
    { lat: 48.8020761, lng: 21.690447 },
    { lat: 48.8021308, lng: 21.6898894 },
    { lat: 48.8023758, lng: 21.6887617 },
    { lat: 48.803908, lng: 21.684112 },
    { lat: 48.803961, lng: 21.6834074 },
    { lat: 48.8040685, lng: 21.6834339 },
    { lat: 48.8041146, lng: 21.6832198 },
    { lat: 48.8039485, lng: 21.6829039 },
    { lat: 48.8040073, lng: 21.6826729 },
    { lat: 48.8042458, lng: 21.682506 },
    { lat: 48.8042576, lng: 21.6814909 },
    { lat: 48.8042127, lng: 21.6814073 },
    { lat: 48.804191, lng: 21.6810268 },
    { lat: 48.8042269, lng: 21.6805695 },
    { lat: 48.8041154, lng: 21.6803944 },
    { lat: 48.8040989, lng: 21.6801638 },
    { lat: 48.804148, lng: 21.6799819 },
    { lat: 48.8041621, lng: 21.6785934 },
    { lat: 48.804057, lng: 21.6784575 },
    { lat: 48.8040307, lng: 21.6781055 },
    { lat: 48.8039739, lng: 21.6780091 },
    { lat: 48.8041523, lng: 21.6776957 },
    { lat: 48.8043161, lng: 21.6771486 },
    { lat: 48.8043798, lng: 21.6766746 },
    { lat: 48.8045921, lng: 21.6762463 },
    { lat: 48.80474, lng: 21.6761874 },
    { lat: 48.8049794, lng: 21.6758863 },
    { lat: 48.8049624, lng: 21.6758002 },
    { lat: 48.8052229, lng: 21.6753236 },
    { lat: 48.8053414, lng: 21.6753801 },
    { lat: 48.8053864, lng: 21.6751731 },
    { lat: 48.8053191, lng: 21.6749771 },
    { lat: 48.8052159, lng: 21.6749449 },
    { lat: 48.8052346, lng: 21.6747239 },
    { lat: 48.8053312, lng: 21.6747554 },
    { lat: 48.8054099, lng: 21.6745466 },
    { lat: 48.8053856, lng: 21.6743647 },
    { lat: 48.805483, lng: 21.6742184 },
    { lat: 48.8053995, lng: 21.6738777 },
    { lat: 48.8054298, lng: 21.6731723 },
    { lat: 48.8055031, lng: 21.6729746 },
    { lat: 48.8056691, lng: 21.6728693 },
    { lat: 48.8056957, lng: 21.6727295 },
    { lat: 48.8059765, lng: 21.6727134 },
    { lat: 48.8060222, lng: 21.6726592 },
    { lat: 48.8060611, lng: 21.6722292 },
    { lat: 48.8063196, lng: 21.6718058 },
    { lat: 48.8062825, lng: 21.6716407 },
    { lat: 48.8064522, lng: 21.671639 },
    { lat: 48.8066048, lng: 21.6714489 },
    { lat: 48.8066906, lng: 21.6714685 },
    { lat: 48.8068501, lng: 21.6712461 },
    { lat: 48.8070277, lng: 21.6712393 },
    { lat: 48.807096, lng: 21.6707803 },
    { lat: 48.8074126, lng: 21.6702169 },
    { lat: 48.8073544, lng: 21.6698558 },
    { lat: 48.8074037, lng: 21.6696396 },
    { lat: 48.807902, lng: 21.669058 },
    { lat: 48.8078011, lng: 21.6687024 },
    { lat: 48.8078358, lng: 21.6685742 },
    { lat: 48.8080316, lng: 21.6685356 },
    { lat: 48.8080286, lng: 21.6682966 },
    { lat: 48.8082105, lng: 21.6679565 },
    { lat: 48.8083456, lng: 21.6670925 },
    { lat: 48.8085548, lng: 21.6669244 },
    { lat: 48.8085498, lng: 21.6665421 },
    { lat: 48.8086471, lng: 21.6664364 },
    { lat: 48.8086144, lng: 21.6660562 },
    { lat: 48.8086639, lng: 21.6660231 },
    { lat: 48.8086972, lng: 21.6658343 },
    { lat: 48.8086682, lng: 21.6656805 },
    { lat: 48.808697, lng: 21.6655845 },
    { lat: 48.8085066, lng: 21.6648311 },
    { lat: 48.8085756, lng: 21.6644713 },
    { lat: 48.8085554, lng: 21.6639403 },
    { lat: 48.8085906, lng: 21.6635801 },
    { lat: 48.8085407, lng: 21.6634044 },
    { lat: 48.8087783, lng: 21.6629597 },
    { lat: 48.8087312, lng: 21.6628006 },
    { lat: 48.8087888, lng: 21.6626475 },
    { lat: 48.8087828, lng: 21.6625036 },
    { lat: 48.809062, lng: 21.6619796 },
    { lat: 48.80913, lng: 21.6616641 },
    { lat: 48.80933, lng: 21.6614113 },
    { lat: 48.8093952, lng: 21.6608711 },
    { lat: 48.8095015, lng: 21.6607234 },
    { lat: 48.8095629, lng: 21.6603833 },
    { lat: 48.809657, lng: 21.6603221 },
    { lat: 48.810076, lng: 21.6591882 },
    { lat: 48.8100595, lng: 21.6591628 },
    { lat: 48.8074824, lng: 21.659015 },
    { lat: 48.8035742, lng: 21.6582935 },
    { lat: 48.8033329, lng: 21.6581855 },
    { lat: 48.8033472, lng: 21.6579666 },
    { lat: 48.8028899, lng: 21.6577879 },
    { lat: 48.8017283, lng: 21.657521 },
    { lat: 48.8012953, lng: 21.6573615 },
    { lat: 48.8004205, lng: 21.6572208 },
    { lat: 48.799491, lng: 21.6568812 },
    { lat: 48.799003, lng: 21.6571377 },
    { lat: 48.7987173, lng: 21.6568991 },
    { lat: 48.7979694, lng: 21.6578956 },
    { lat: 48.7975862, lng: 21.657495 },
    { lat: 48.7967768, lng: 21.6571939 },
    { lat: 48.7968218, lng: 21.6566586 },
    { lat: 48.7958194, lng: 21.6563779 },
    { lat: 48.7957817, lng: 21.655906 },
    { lat: 48.7958394, lng: 21.6556279 },
    { lat: 48.7959566, lng: 21.6555503 },
    { lat: 48.7960475, lng: 21.6552987 },
    { lat: 48.7955868, lng: 21.6551499 },
    { lat: 48.7954127, lng: 21.6550002 },
    { lat: 48.7954142, lng: 21.6549356 },
    { lat: 48.795275, lng: 21.6549047 },
    { lat: 48.7951563, lng: 21.6547969 },
    { lat: 48.7949235, lng: 21.6548472 },
    { lat: 48.7948979, lng: 21.654685 },
    { lat: 48.7945469, lng: 21.6546528 },
    { lat: 48.7941614, lng: 21.6547466 },
    { lat: 48.7941364, lng: 21.6546193 },
    { lat: 48.7932504, lng: 21.6547995 },
    { lat: 48.7929725, lng: 21.6546803 },
    { lat: 48.7930794, lng: 21.654554 },
    { lat: 48.7931292, lng: 21.6539114 },
    { lat: 48.7930155, lng: 21.6538683 },
    { lat: 48.7929947, lng: 21.653643 },
    { lat: 48.7930976, lng: 21.6534549 },
    { lat: 48.793238, lng: 21.6528344 },
    { lat: 48.7930509, lng: 21.6523652 },
    { lat: 48.79302, lng: 21.6520955 },
    { lat: 48.7929237, lng: 21.6520324 },
    { lat: 48.7928376, lng: 21.6521345 },
    { lat: 48.7928073, lng: 21.6519312 },
    { lat: 48.7927594, lng: 21.6518718 },
    { lat: 48.7928909, lng: 21.6518267 },
    { lat: 48.7930542, lng: 21.6516673 },
    { lat: 48.7930769, lng: 21.6513865 },
    { lat: 48.7931431, lng: 21.6513424 },
    { lat: 48.7931193, lng: 21.651042 },
    { lat: 48.7931815, lng: 21.6507729 },
    { lat: 48.7933611, lng: 21.6505483 },
    { lat: 48.7933255, lng: 21.6503606 },
    { lat: 48.7934267, lng: 21.6500713 },
    { lat: 48.7934547, lng: 21.6490015 },
    { lat: 48.793345, lng: 21.648982 },
    { lat: 48.7933018, lng: 21.6488766 },
    { lat: 48.7934076, lng: 21.6486355 },
    { lat: 48.7934122, lng: 21.6484786 },
    { lat: 48.793534, lng: 21.648326 },
    { lat: 48.7935457, lng: 21.6481376 },
    { lat: 48.7939148, lng: 21.6472815 },
    { lat: 48.7939776, lng: 21.647224 },
    { lat: 48.7937042, lng: 21.6469472 },
    { lat: 48.793624, lng: 21.6466881 },
    { lat: 48.7903222, lng: 21.6468617 },
    { lat: 48.789161, lng: 21.6467375 },
    { lat: 48.7879761, lng: 21.6467304 },
    { lat: 48.7870576, lng: 21.6470864 },
    { lat: 48.7866859, lng: 21.6474399 },
    { lat: 48.7862999, lng: 21.6480065 },
    { lat: 48.7857836, lng: 21.6484193 },
    { lat: 48.7854489, lng: 21.6486088 },
    { lat: 48.7830268, lng: 21.6494732 },
    { lat: 48.7823926, lng: 21.6496112 },
    { lat: 48.7819458, lng: 21.649609 },
    { lat: 48.7816624, lng: 21.6494909 },
    { lat: 48.7809995, lng: 21.649397 },
    { lat: 48.7803557, lng: 21.6490233 },
    { lat: 48.7801275, lng: 21.6488257 },
    { lat: 48.7801606, lng: 21.6487612 },
    { lat: 48.7801165, lng: 21.6487491 },
    { lat: 48.780118, lng: 21.648699 },
    { lat: 48.78009, lng: 21.64885 },
    { lat: 48.780008, lng: 21.649224 },
    { lat: 48.78005, lng: 21.649487 },
    { lat: 48.77995, lng: 21.64961 },
    { lat: 48.779963, lng: 21.649637 },
    { lat: 48.779951, lng: 21.649664 },
    { lat: 48.779978, lng: 21.649941 },
    { lat: 48.779953, lng: 21.649959 },
    { lat: 48.779904, lng: 21.649993 },
    { lat: 48.7799, lng: 21.650128 },
    { lat: 48.779898, lng: 21.650184 },
    { lat: 48.780003, lng: 21.650431 },
    { lat: 48.77998, lng: 21.650473 },
    { lat: 48.779965, lng: 21.65051 },
    { lat: 48.780061, lng: 21.650559 },
    { lat: 48.780125, lng: 21.650854 },
    { lat: 48.780113, lng: 21.650959 },
    { lat: 48.7801, lng: 21.651067 },
    { lat: 48.780121, lng: 21.651154 },
    { lat: 48.780128, lng: 21.651192 },
    { lat: 48.78013, lng: 21.651223 },
    { lat: 48.78019, lng: 21.651192 },
    { lat: 48.780287, lng: 21.651353 },
    { lat: 48.780273, lng: 21.651621 },
    { lat: 48.780215, lng: 21.651668 },
    { lat: 48.780212, lng: 21.651892 },
    { lat: 48.780352, lng: 21.65204 },
    { lat: 48.780299, lng: 21.652105 },
    { lat: 48.780276, lng: 21.652116 },
    { lat: 48.780267, lng: 21.652093 },
    { lat: 48.780201, lng: 21.652127 },
    { lat: 48.780177, lng: 21.652107 },
    { lat: 48.780174, lng: 21.652105 },
    { lat: 48.780158, lng: 21.652128 },
    { lat: 48.780078, lng: 21.652242 },
    { lat: 48.780173, lng: 21.652493 },
    { lat: 48.780148, lng: 21.652643 },
    { lat: 48.780105, lng: 21.652902 },
    { lat: 48.780137, lng: 21.653028 },
    { lat: 48.780274, lng: 21.653168 },
    { lat: 48.780315, lng: 21.653211 },
    { lat: 48.780302, lng: 21.65328 },
    { lat: 48.780267, lng: 21.65344 },
    { lat: 48.780306, lng: 21.653522 },
    { lat: 48.780361, lng: 21.65353 },
    { lat: 48.780404, lng: 21.653538 },
    { lat: 48.780541, lng: 21.653588 },
    { lat: 48.780582, lng: 21.653776 },
    { lat: 48.780561, lng: 21.653813 },
    { lat: 48.78046, lng: 21.653819 },
    { lat: 48.780568, lng: 21.654045 },
    { lat: 48.780535, lng: 21.654188 },
    { lat: 48.78053, lng: 21.654208 },
    { lat: 48.780495, lng: 21.654327 },
    { lat: 48.780464, lng: 21.65433 },
    { lat: 48.780402, lng: 21.654337 },
    { lat: 48.780381, lng: 21.654628 },
    { lat: 48.780374, lng: 21.654728 },
    { lat: 48.780278, lng: 21.654845 },
    { lat: 48.78018, lng: 21.655041 },
    { lat: 48.780142, lng: 21.655344 },
    { lat: 48.779982, lng: 21.655417 },
    { lat: 48.779978, lng: 21.655516 },
    { lat: 48.780061, lng: 21.655639 },
    { lat: 48.779903, lng: 21.655806 },
    { lat: 48.77984, lng: 21.655978 },
    { lat: 48.779788, lng: 21.656098 },
    { lat: 48.779861, lng: 21.656405 },
    { lat: 48.779893, lng: 21.656425 },
    { lat: 48.779903, lng: 21.656459 },
    { lat: 48.779795, lng: 21.656798 },
    { lat: 48.779817, lng: 21.657029 },
    { lat: 48.779723, lng: 21.657291 },
    { lat: 48.779704, lng: 21.657353 },
    { lat: 48.779832, lng: 21.657712 },
    { lat: 48.779873, lng: 21.657893 },
    { lat: 48.779877, lng: 21.65806 },
    { lat: 48.779836, lng: 21.658251 },
    { lat: 48.779809, lng: 21.658377 },
    { lat: 48.779805, lng: 21.658551 },
    { lat: 48.779801, lng: 21.65866 },
    { lat: 48.779888, lng: 21.65893 },
    { lat: 48.779881, lng: 21.659109 },
    { lat: 48.779918, lng: 21.659289 },
    { lat: 48.779849, lng: 21.65938 },
    { lat: 48.779835, lng: 21.659466 },
    { lat: 48.779894, lng: 21.659569 },
    { lat: 48.779915, lng: 21.659563 },
    { lat: 48.779858, lng: 21.659667 },
    { lat: 48.779816, lng: 21.65967 },
    { lat: 48.779564, lng: 21.659635 },
    { lat: 48.778977, lng: 21.659739 },
    { lat: 48.777453, lng: 21.659554 },
    { lat: 48.776979, lng: 21.661568 },
    { lat: 48.776766, lng: 21.661545 },
    { lat: 48.776531, lng: 21.66152 },
    { lat: 48.776445, lng: 21.661511 },
    { lat: 48.776359, lng: 21.661512 },
    { lat: 48.776325, lng: 21.661512 },
    { lat: 48.77615, lng: 21.661513 },
    { lat: 48.776108, lng: 21.661516 },
    { lat: 48.775991, lng: 21.661525 },
    { lat: 48.775918, lng: 21.661531 },
    { lat: 48.775702, lng: 21.661579 },
    { lat: 48.775642, lng: 21.66161 },
    { lat: 48.7756, lng: 21.661631 },
    { lat: 48.775654, lng: 21.661658 },
    { lat: 48.775015, lng: 21.662068 },
    { lat: 48.774946, lng: 21.662121 },
    { lat: 48.774868, lng: 21.66217 },
    { lat: 48.77472, lng: 21.66227 },
    { lat: 48.774567, lng: 21.662373 },
    { lat: 48.774437, lng: 21.662462 },
    { lat: 48.774376, lng: 21.662496 },
    { lat: 48.773802, lng: 21.662872 },
    { lat: 48.773701, lng: 21.662942 },
    { lat: 48.773054, lng: 21.663424 },
    { lat: 48.772985, lng: 21.663501 },
    { lat: 48.77267, lng: 21.663521 },
    { lat: 48.772663, lng: 21.663524 },
    { lat: 48.772583, lng: 21.663563 },
    { lat: 48.772467, lng: 21.663661 },
    { lat: 48.772334, lng: 21.663935 },
    { lat: 48.772237, lng: 21.664198 },
    { lat: 48.772155, lng: 21.66437 },
    { lat: 48.772082, lng: 21.664569 },
    { lat: 48.77172, lng: 21.665276 },
    { lat: 48.771559, lng: 21.665578 },
    { lat: 48.771445, lng: 21.665768 },
    { lat: 48.771411, lng: 21.665798 },
    { lat: 48.771393, lng: 21.66582 },
    { lat: 48.771395, lng: 21.665823 },
    { lat: 48.771373, lng: 21.665853 },
    { lat: 48.771361, lng: 21.665833 },
    { lat: 48.771309, lng: 21.665904 },
    { lat: 48.771317, lng: 21.665919 },
    { lat: 48.771293, lng: 21.665964 },
    { lat: 48.770995, lng: 21.666389 },
    { lat: 48.765975, lng: 21.673903 },
    { lat: 48.765899, lng: 21.674016 },
    { lat: 48.765725, lng: 21.674259 },
    { lat: 48.765527, lng: 21.674672 },
    { lat: 48.765359, lng: 21.675195 },
    { lat: 48.765288, lng: 21.675953 },
    { lat: 48.76527, lng: 21.676223 },
    { lat: 48.765298, lng: 21.676276 },
    { lat: 48.765374, lng: 21.676413 },
    { lat: 48.765459, lng: 21.676568 },
    { lat: 48.765432, lng: 21.677152 },
    { lat: 48.765387, lng: 21.677461 },
    { lat: 48.765212, lng: 21.678247 },
    { lat: 48.764999, lng: 21.678617 },
    { lat: 48.764854, lng: 21.678848 },
    { lat: 48.764762, lng: 21.679247 },
    { lat: 48.764163, lng: 21.680319 },
    { lat: 48.763931, lng: 21.680547 },
    { lat: 48.763704, lng: 21.681087 },
    { lat: 48.763679, lng: 21.681149 },
    { lat: 48.763401, lng: 21.681807 },
    { lat: 48.763134, lng: 21.682891 },
    { lat: 48.762681, lng: 21.684399 },
    { lat: 48.76256, lng: 21.685187 },
    { lat: 48.762609, lng: 21.685536 },
    { lat: 48.762679, lng: 21.68605 },
    { lat: 48.762681, lng: 21.686059 },
    { lat: 48.762764, lng: 21.686479 },
    { lat: 48.762797, lng: 21.686474 },
    { lat: 48.762939, lng: 21.687347 },
    { lat: 48.763014, lng: 21.687785 },
    { lat: 48.763129, lng: 21.688372 },
    { lat: 48.763214, lng: 21.688798 },
    { lat: 48.763318, lng: 21.689282 },
    { lat: 48.763406, lng: 21.689797 },
    { lat: 48.763557, lng: 21.690406 },
    { lat: 48.763697, lng: 21.69121 },
    { lat: 48.76391, lng: 21.692093 },
    { lat: 48.763972, lng: 21.692218 },
    { lat: 48.763961, lng: 21.692352 },
    { lat: 48.764034, lng: 21.692699 },
    { lat: 48.764219, lng: 21.693097 },
    { lat: 48.764319, lng: 21.693321 },
    { lat: 48.764564, lng: 21.693914 },
    { lat: 48.764688, lng: 21.69424 },
    { lat: 48.764856, lng: 21.694865 },
    { lat: 48.764958, lng: 21.695311 },
    { lat: 48.765029, lng: 21.695535 },
    { lat: 48.76507, lng: 21.695729 },
    { lat: 48.765083, lng: 21.695952 },
    { lat: 48.765081, lng: 21.696109 },
    { lat: 48.765074, lng: 21.696401 },
    { lat: 48.765071, lng: 21.696535 },
    { lat: 48.765068, lng: 21.696733 },
    { lat: 48.765066, lng: 21.696918 },
    { lat: 48.765053, lng: 21.697122 },
    { lat: 48.765058, lng: 21.697404 },
    { lat: 48.764811, lng: 21.698232 },
    { lat: 48.764748, lng: 21.698416 },
    { lat: 48.764679, lng: 21.698618 },
    { lat: 48.764635, lng: 21.698728 },
    { lat: 48.764555, lng: 21.698946 },
    { lat: 48.764492, lng: 21.699137 },
    { lat: 48.764438, lng: 21.69925 },
    { lat: 48.764368, lng: 21.699469 },
    { lat: 48.764312, lng: 21.699676 },
    { lat: 48.76425, lng: 21.699844 },
    { lat: 48.764162, lng: 21.700151 },
    { lat: 48.764079, lng: 21.700419 },
    { lat: 48.764054, lng: 21.700559 },
    { lat: 48.763951, lng: 21.70077 },
    { lat: 48.76388, lng: 21.700972 },
    { lat: 48.763729, lng: 21.701313 },
    { lat: 48.76363, lng: 21.701481 },
    { lat: 48.763546, lng: 21.70163 },
    { lat: 48.763372, lng: 21.701921 },
    { lat: 48.763241, lng: 21.702224 },
    { lat: 48.76317, lng: 21.702525 },
    { lat: 48.763151, lng: 21.702673 },
    { lat: 48.763312, lng: 21.703101 },
    { lat: 48.763467, lng: 21.703709 },
    { lat: 48.763743, lng: 21.704771 },
    { lat: 48.763857, lng: 21.705175 },
    { lat: 48.763947, lng: 21.705625 },
    { lat: 48.764091, lng: 21.70578 },
    { lat: 48.764197, lng: 21.705935 },
    { lat: 48.764289, lng: 21.706118 },
    { lat: 48.764353, lng: 21.706287 },
    { lat: 48.764357, lng: 21.706455 },
    { lat: 48.76435, lng: 21.706541 },
    { lat: 48.764312, lng: 21.706738 },
    { lat: 48.764278, lng: 21.706834 },
    { lat: 48.764217, lng: 21.707221 },
    { lat: 48.764204, lng: 21.707586 },
    { lat: 48.764217, lng: 21.707741 },
    { lat: 48.764249, lng: 21.707945 },
    { lat: 48.764292, lng: 21.708123 },
    { lat: 48.764348, lng: 21.708301 },
    { lat: 48.764419, lng: 21.708595 },
    { lat: 48.764631, lng: 21.709064 },
    { lat: 48.764716, lng: 21.709349 },
    { lat: 48.764723, lng: 21.709512 },
    { lat: 48.764593, lng: 21.709786 },
    { lat: 48.764362, lng: 21.710045 },
    { lat: 48.764312, lng: 21.710213 },
    { lat: 48.764172, lng: 21.711308 },
    { lat: 48.764163, lng: 21.71138 },
    { lat: 48.764109, lng: 21.711803 },
    { lat: 48.764005, lng: 21.712202 },
    { lat: 48.764013, lng: 21.712457 },
    { lat: 48.764134, lng: 21.712954 },
    { lat: 48.764236, lng: 21.713129 },
    { lat: 48.765051, lng: 21.713685 },
    { lat: 48.765483, lng: 21.714251 },
    { lat: 48.765753, lng: 21.714439 },
    { lat: 48.765871, lng: 21.714552 },
    { lat: 48.7661, lng: 21.714699 },
    { lat: 48.766257, lng: 21.714838 },
    { lat: 48.766324, lng: 21.715118 },
    { lat: 48.76659, lng: 21.715721 },
    { lat: 48.766882, lng: 21.717243 },
    { lat: 48.767095, lng: 21.717997 },
    { lat: 48.766845, lng: 21.718099 },
    { lat: 48.766471, lng: 21.718483 },
    { lat: 48.766497, lng: 21.718607 },
    { lat: 48.766574, lng: 21.718789 },
    { lat: 48.766554, lng: 21.719318 },
    { lat: 48.766615, lng: 21.719583 },
    { lat: 48.766558, lng: 21.720075 },
    { lat: 48.766639, lng: 21.720295 },
    { lat: 48.766859, lng: 21.72029 },
    { lat: 48.767021, lng: 21.720158 },
    { lat: 48.767194, lng: 21.719881 },
    { lat: 48.76728, lng: 21.719652 },
    { lat: 48.767352, lng: 21.719165 },
    { lat: 48.767309, lng: 21.719003 },
    { lat: 48.767354, lng: 21.718803 },
    { lat: 48.767462, lng: 21.718796 },
    { lat: 48.767549, lng: 21.71876 },
    { lat: 48.767665, lng: 21.718835 },
    { lat: 48.768151, lng: 21.71918 },
    { lat: 48.768519, lng: 21.71953 },
    { lat: 48.768624, lng: 21.719658 },
    { lat: 48.768866, lng: 21.719828 },
    { lat: 48.769054, lng: 21.719885 },
    { lat: 48.769381, lng: 21.719916 },
    { lat: 48.769581, lng: 21.719873 },
    { lat: 48.769793, lng: 21.71986 },
    { lat: 48.770076, lng: 21.719934 },
    { lat: 48.770217, lng: 21.719937 },
    { lat: 48.770431, lng: 21.720122 },
    { lat: 48.770516, lng: 21.720483 },
    { lat: 48.770458, lng: 21.7208 },
    { lat: 48.770087, lng: 21.721513 },
    { lat: 48.769895, lng: 21.72174 },
    { lat: 48.769717, lng: 21.721818 },
    { lat: 48.76944, lng: 21.722017 },
    { lat: 48.769368, lng: 21.722169 },
    { lat: 48.769381, lng: 21.722346 },
    { lat: 48.769462, lng: 21.722514 },
    { lat: 48.769498, lng: 21.722799 },
    { lat: 48.769163, lng: 21.72294 },
    { lat: 48.768967, lng: 21.72306 },
    { lat: 48.768884, lng: 21.723936 },
    { lat: 48.769095, lng: 21.724004 },
    { lat: 48.769301, lng: 21.724532 },
    { lat: 48.769261, lng: 21.724744 },
    { lat: 48.769302, lng: 21.724972 },
    { lat: 48.769459, lng: 21.725128 },
    { lat: 48.76987, lng: 21.72529 },
    { lat: 48.769956, lng: 21.725203 },
    { lat: 48.770281, lng: 21.724484 },
    { lat: 48.770345, lng: 21.724278 },
    { lat: 48.770419, lng: 21.723863 },
    { lat: 48.770498, lng: 21.723177 },
    { lat: 48.77056, lng: 21.722804 },
    { lat: 48.770586, lng: 21.722563 },
    { lat: 48.770692, lng: 21.722402 },
    { lat: 48.77085, lng: 21.722323 },
    { lat: 48.771108, lng: 21.722307 },
    { lat: 48.771405, lng: 21.722385 },
    { lat: 48.771593, lng: 21.722555 },
    { lat: 48.77166, lng: 21.722731 },
    { lat: 48.771668, lng: 21.72333 },
    { lat: 48.771698, lng: 21.723988 },
    { lat: 48.771178, lng: 21.724701 },
    { lat: 48.771112, lng: 21.724939 },
    { lat: 48.771163, lng: 21.725602 },
    { lat: 48.771166, lng: 21.726033 },
    { lat: 48.771279, lng: 21.726347 },
    { lat: 48.771617, lng: 21.726495 },
    { lat: 48.771693, lng: 21.726582 },
    { lat: 48.771893, lng: 21.726753 },
    { lat: 48.772066, lng: 21.726831 },
    { lat: 48.772347, lng: 21.72694 },
    { lat: 48.772401, lng: 21.727002 },
    { lat: 48.772458, lng: 21.727045 },
    { lat: 48.772713, lng: 21.726784 },
    { lat: 48.773643, lng: 21.726659 },
    { lat: 48.774575, lng: 21.727333 },
    { lat: 48.775104, lng: 21.727334 },
    { lat: 48.775665, lng: 21.727127 },
    { lat: 48.775781, lng: 21.727035 },
    { lat: 48.776242, lng: 21.726203 },
    { lat: 48.776403, lng: 21.726296 },
    { lat: 48.776345, lng: 21.7267 },
    { lat: 48.776317, lng: 21.7269 },
    { lat: 48.776304, lng: 21.72699 },
    { lat: 48.776149, lng: 21.728078 },
    { lat: 48.776055, lng: 21.729639 },
    { lat: 48.776066, lng: 21.730114 },
    { lat: 48.777096, lng: 21.730025 },
    { lat: 48.777613, lng: 21.72999 },
    { lat: 48.778298, lng: 21.729978 },
    { lat: 48.779285, lng: 21.729884 },
    { lat: 48.780102, lng: 21.729835 },
    { lat: 48.780683, lng: 21.729791 },
    { lat: 48.7856191, lng: 21.7294728 },
    { lat: 48.7858195, lng: 21.7309896 },
    { lat: 48.7855881, lng: 21.7323078 },
    { lat: 48.786513, lng: 21.7327321 },
    { lat: 48.7881819, lng: 21.7317454 },
    { lat: 48.7882274, lng: 21.7317187 },
  ],
  Rafajovce: [
    { lat: 49.0565679, lng: 21.723947 },
    { lat: 49.0559486, lng: 21.7223213 },
    { lat: 49.0552123, lng: 21.7212028 },
    { lat: 49.0542865, lng: 21.7206166 },
    { lat: 49.0541522, lng: 21.7205737 },
    { lat: 49.0527907, lng: 21.7205447 },
    { lat: 49.0515351, lng: 21.7218489 },
    { lat: 49.0506089, lng: 21.7225897 },
    { lat: 49.0483737, lng: 21.7233517 },
    { lat: 49.0480441, lng: 21.7233151 },
    { lat: 49.0468073, lng: 21.7228323 },
    { lat: 49.0463072, lng: 21.7227226 },
    { lat: 49.045662, lng: 21.7226599 },
    { lat: 49.0445023, lng: 21.722875 },
    { lat: 49.0441739, lng: 21.7228755 },
    { lat: 49.04361, lng: 21.7224836 },
    { lat: 49.0422191, lng: 21.7228079 },
    { lat: 49.0409357, lng: 21.722861 },
    { lat: 49.041058, lng: 21.7236463 },
    { lat: 49.0403806, lng: 21.7241424 },
    { lat: 49.040303, lng: 21.7248206 },
    { lat: 49.0398171, lng: 21.7246971 },
    { lat: 49.0395534, lng: 21.72476 },
    { lat: 49.0393665, lng: 21.724717 },
    { lat: 49.0388223, lng: 21.7247653 },
    { lat: 49.0382964, lng: 21.7252975 },
    { lat: 49.0366546, lng: 21.725823 },
    { lat: 49.0366106, lng: 21.7268784 },
    { lat: 49.0364067, lng: 21.7269949 },
    { lat: 49.0363571, lng: 21.7273691 },
    { lat: 49.0361594, lng: 21.7276742 },
    { lat: 49.0355127, lng: 21.7283193 },
    { lat: 49.0353189, lng: 21.7294843 },
    { lat: 49.035201, lng: 21.7297041 },
    { lat: 49.0350415, lng: 21.731152 },
    { lat: 49.0352977, lng: 21.7329149 },
    { lat: 49.0339905, lng: 21.736724 },
    { lat: 49.0338858, lng: 21.7383291 },
    { lat: 49.033936, lng: 21.7386136 },
    { lat: 49.0347004, lng: 21.7393575 },
    { lat: 49.0346007, lng: 21.7396211 },
    { lat: 49.0346391, lng: 21.7399665 },
    { lat: 49.0346289, lng: 21.740605 },
    { lat: 49.0341497, lng: 21.7414952 },
    { lat: 49.0338651, lng: 21.741874 },
    { lat: 49.034015, lng: 21.7429053 },
    { lat: 49.0343404, lng: 21.7434708 },
    { lat: 49.0343347, lng: 21.7438849 },
    { lat: 49.0346317, lng: 21.7445235 },
    { lat: 49.0347282, lng: 21.7449815 },
    { lat: 49.0346956, lng: 21.7452381 },
    { lat: 49.0340402, lng: 21.7463197 },
    { lat: 49.0337717, lng: 21.7459927 },
    { lat: 49.0335264, lng: 21.7467524 },
    { lat: 49.0331645, lng: 21.7475698 },
    { lat: 49.0331409, lng: 21.7482527 },
    { lat: 49.0330514, lng: 21.7484887 },
    { lat: 49.0330475, lng: 21.7489737 },
    { lat: 49.0328431, lng: 21.7492031 },
    { lat: 49.032686, lng: 21.749595 },
    { lat: 49.032865, lng: 21.749892 },
    { lat: 49.032902, lng: 21.750247 },
    { lat: 49.032668, lng: 21.751224 },
    { lat: 49.032374, lng: 21.752259 },
    { lat: 49.032601, lng: 21.752577 },
    { lat: 49.032834, lng: 21.75281 },
    { lat: 49.032843, lng: 21.753028 },
    { lat: 49.033023, lng: 21.753318 },
    { lat: 49.033301, lng: 21.753302 },
    { lat: 49.033491, lng: 21.75325 },
    { lat: 49.033874, lng: 21.752966 },
    { lat: 49.034128, lng: 21.752879 },
    { lat: 49.034352, lng: 21.75272 },
    { lat: 49.034875, lng: 21.752511 },
    { lat: 49.035278, lng: 21.752492 },
    { lat: 49.035552, lng: 21.752265 },
    { lat: 49.035791, lng: 21.751925 },
    { lat: 49.036391, lng: 21.751965 },
    { lat: 49.036596, lng: 21.7518 },
    { lat: 49.037177, lng: 21.752116 },
    { lat: 49.037622, lng: 21.752243 },
    { lat: 49.038831, lng: 21.751482 },
    { lat: 49.03896, lng: 21.751276 },
    { lat: 49.039244, lng: 21.752185 },
    { lat: 49.039253, lng: 21.75273 },
    { lat: 49.039528, lng: 21.753986 },
    { lat: 49.039762, lng: 21.754286 },
    { lat: 49.040005, lng: 21.754401 },
    { lat: 49.040717, lng: 21.755142 },
    { lat: 49.041146, lng: 21.755149 },
    { lat: 49.041607, lng: 21.75422 },
    { lat: 49.041992, lng: 21.754191 },
    { lat: 49.042489, lng: 21.754236 },
    { lat: 49.042836, lng: 21.754298 },
    { lat: 49.043518, lng: 21.754549 },
    { lat: 49.044081, lng: 21.754618 },
    { lat: 49.044189, lng: 21.755047 },
    { lat: 49.044912, lng: 21.754992 },
    { lat: 49.045106, lng: 21.754864 },
    { lat: 49.045221, lng: 21.754858 },
    { lat: 49.045303, lng: 21.755497 },
    { lat: 49.045325, lng: 21.756041 },
    { lat: 49.04549, lng: 21.756425 },
    { lat: 49.0456627, lng: 21.7558317 },
    { lat: 49.0460528, lng: 21.755181 },
    { lat: 49.0469651, lng: 21.7545017 },
    { lat: 49.0476915, lng: 21.7537214 },
    { lat: 49.047698, lng: 21.7535403 },
    { lat: 49.0478869, lng: 21.7533645 },
    { lat: 49.0481767, lng: 21.7533923 },
    { lat: 49.048591, lng: 21.7537471 },
    { lat: 49.0490042, lng: 21.753459 },
    { lat: 49.049455, lng: 21.7525976 },
    { lat: 49.0491107, lng: 21.7523429 },
    { lat: 49.0485704, lng: 21.7521188 },
    { lat: 49.0487151, lng: 21.7511646 },
    { lat: 49.0488349, lng: 21.7507574 },
    { lat: 49.0489644, lng: 21.749529 },
    { lat: 49.049189, lng: 21.7493837 },
    { lat: 49.0498151, lng: 21.7482598 },
    { lat: 49.0501394, lng: 21.7479842 },
    { lat: 49.0503127, lng: 21.7476357 },
    { lat: 49.0508016, lng: 21.7471112 },
    { lat: 49.0509011, lng: 21.7468358 },
    { lat: 49.0519189, lng: 21.7459349 },
    { lat: 49.0518986, lng: 21.7455944 },
    { lat: 49.0525458, lng: 21.7443711 },
    { lat: 49.0525251, lng: 21.74397 },
    { lat: 49.0528468, lng: 21.7415143 },
    { lat: 49.0528328, lng: 21.7401784 },
    { lat: 49.0528724, lng: 21.7391638 },
    { lat: 49.0530743, lng: 21.7391066 },
    { lat: 49.0531354, lng: 21.7387808 },
    { lat: 49.0532916, lng: 21.7383874 },
    { lat: 49.0538871, lng: 21.7382798 },
    { lat: 49.0540827, lng: 21.7380587 },
    { lat: 49.0542327, lng: 21.738076 },
    { lat: 49.0542778, lng: 21.7382052 },
    { lat: 49.0543815, lng: 21.7382587 },
    { lat: 49.0548559, lng: 21.7380295 },
    { lat: 49.0548105, lng: 21.7378465 },
    { lat: 49.0549592, lng: 21.7377311 },
    { lat: 49.0549389, lng: 21.737515 },
    { lat: 49.0550558, lng: 21.737382 },
    { lat: 49.0550435, lng: 21.737132 },
    { lat: 49.0553637, lng: 21.736947 },
    { lat: 49.0553942, lng: 21.736784 },
    { lat: 49.0554948, lng: 21.7367205 },
    { lat: 49.055796, lng: 21.7367888 },
    { lat: 49.0558923, lng: 21.7366849 },
    { lat: 49.0557097, lng: 21.7365487 },
    { lat: 49.0557436, lng: 21.7362601 },
    { lat: 49.0558916, lng: 21.7363679 },
    { lat: 49.056181, lng: 21.7364157 },
    { lat: 49.0562108, lng: 21.7362267 },
    { lat: 49.0563667, lng: 21.7360428 },
    { lat: 49.056385, lng: 21.7357099 },
    { lat: 49.0566583, lng: 21.7358369 },
    { lat: 49.056752, lng: 21.7354262 },
    { lat: 49.0565228, lng: 21.73514 },
    { lat: 49.0564882, lng: 21.7349004 },
    { lat: 49.0565538, lng: 21.7347881 },
    { lat: 49.0564981, lng: 21.7345565 },
    { lat: 49.0567398, lng: 21.7343709 },
    { lat: 49.0566415, lng: 21.7340154 },
    { lat: 49.0568231, lng: 21.7338683 },
    { lat: 49.0565559, lng: 21.7324095 },
    { lat: 49.0560395, lng: 21.7313502 },
    { lat: 49.0557831, lng: 21.7307164 },
    { lat: 49.055655, lng: 21.7302609 },
    { lat: 49.0555095, lng: 21.7280894 },
    { lat: 49.0550731, lng: 21.7272117 },
    { lat: 49.0547337, lng: 21.7267976 },
    { lat: 49.0547354, lng: 21.7265253 },
    { lat: 49.0552036, lng: 21.7255631 },
    { lat: 49.0560674, lng: 21.7244596 },
    { lat: 49.0565679, lng: 21.723947 },
  ],
  ČiernenadTopľou: [
    { lat: 48.9812142, lng: 21.5423554 },
    { lat: 48.9803608, lng: 21.5435691 },
    { lat: 48.9787352, lng: 21.5458807 },
    { lat: 48.9774081, lng: 21.5472896 },
    { lat: 48.9750041, lng: 21.5501337 },
    { lat: 48.9740297, lng: 21.5508536 },
    { lat: 48.9731312, lng: 21.5524252 },
    { lat: 48.9725483, lng: 21.5531848 },
    { lat: 48.9723392, lng: 21.5536682 },
    { lat: 48.9722936, lng: 21.5540003 },
    { lat: 48.969561, lng: 21.5562059 },
    { lat: 48.968987, lng: 21.5563607 },
    { lat: 48.9686434, lng: 21.5565506 },
    { lat: 48.9681809, lng: 21.5567027 },
    { lat: 48.9676813, lng: 21.5573389 },
    { lat: 48.9668811, lng: 21.5577332 },
    { lat: 48.966832, lng: 21.5577457 },
    { lat: 48.9671244, lng: 21.5588623 },
    { lat: 48.967102, lng: 21.5591061 },
    { lat: 48.9667572, lng: 21.5594039 },
    { lat: 48.9667169, lng: 21.560133 },
    { lat: 48.9668262, lng: 21.5603387 },
    { lat: 48.967325, lng: 21.5607539 },
    { lat: 48.9673182, lng: 21.5609928 },
    { lat: 48.967102, lng: 21.5612672 },
    { lat: 48.9671542, lng: 21.561363 },
    { lat: 48.9675912, lng: 21.5614593 },
    { lat: 48.9676267, lng: 21.5615157 },
    { lat: 48.9675688, lng: 21.5620992 },
    { lat: 48.9676, lng: 21.5625706 },
    { lat: 48.9677033, lng: 21.5629234 },
    { lat: 48.9681667, lng: 21.563154 },
    { lat: 48.9679733, lng: 21.5636801 },
    { lat: 48.9685598, lng: 21.5638548 },
    { lat: 48.9686383, lng: 21.5639614 },
    { lat: 48.9684236, lng: 21.5642085 },
    { lat: 48.9685828, lng: 21.564514 },
    { lat: 48.9690438, lng: 21.5648619 },
    { lat: 48.9689913, lng: 21.565434 },
    { lat: 48.9691148, lng: 21.5661121 },
    { lat: 48.9691211, lng: 21.566497 },
    { lat: 48.9693686, lng: 21.5670901 },
    { lat: 48.9693048, lng: 21.5673034 },
    { lat: 48.9693367, lng: 21.5677196 },
    { lat: 48.9695597, lng: 21.5682555 },
    { lat: 48.9696519, lng: 21.5686521 },
    { lat: 48.9695125, lng: 21.5688161 },
    { lat: 48.9692771, lng: 21.568966 },
    { lat: 48.9692965, lng: 21.5692595 },
    { lat: 48.9694313, lng: 21.5693671 },
    { lat: 48.9696457, lng: 21.5697511 },
    { lat: 48.9698585, lng: 21.5699572 },
    { lat: 48.9698793, lng: 21.570218 },
    { lat: 48.969785, lng: 21.5701872 },
    { lat: 48.969638, lng: 21.5702809 },
    { lat: 48.9695399, lng: 21.5706751 },
    { lat: 48.9699137, lng: 21.571563 },
    { lat: 48.970267, lng: 21.5720902 },
    { lat: 48.9702799, lng: 21.5725098 },
    { lat: 48.9700479, lng: 21.5735837 },
    { lat: 48.9698591, lng: 21.5739146 },
    { lat: 48.9697889, lng: 21.5737412 },
    { lat: 48.9695046, lng: 21.5737921 },
    { lat: 48.969438, lng: 21.573862 },
    { lat: 48.9694579, lng: 21.5741357 },
    { lat: 48.9696589, lng: 21.5742749 },
    { lat: 48.9696857, lng: 21.5745926 },
    { lat: 48.9694309, lng: 21.5746538 },
    { lat: 48.9691422, lng: 21.5750696 },
    { lat: 48.9693306, lng: 21.5754095 },
    { lat: 48.9692289, lng: 21.5755145 },
    { lat: 48.9688663, lng: 21.5753471 },
    { lat: 48.9686836, lng: 21.575448 },
    { lat: 48.9687513, lng: 21.5771099 },
    { lat: 48.9685941, lng: 21.5777177 },
    { lat: 48.9684109, lng: 21.5777657 },
    { lat: 48.9681747, lng: 21.5776506 },
    { lat: 48.9683074, lng: 21.5792574 },
    { lat: 48.9684779, lng: 21.5803246 },
    { lat: 48.9685614, lng: 21.5815807 },
    { lat: 48.9683994, lng: 21.5817398 },
    { lat: 48.9684635, lng: 21.5825851 },
    { lat: 48.9686881, lng: 21.5829759 },
    { lat: 48.9687338, lng: 21.5832806 },
    { lat: 48.9692546, lng: 21.5829932 },
    { lat: 48.9696744, lng: 21.5828794 },
    { lat: 48.9699442, lng: 21.5829512 },
    { lat: 48.9707571, lng: 21.5838947 },
    { lat: 48.9712692, lng: 21.584386 },
    { lat: 48.9718616, lng: 21.5846125 },
    { lat: 48.9724028, lng: 21.5844914 },
    { lat: 48.9727965, lng: 21.5842877 },
    { lat: 48.9736862, lng: 21.5837193 },
    { lat: 48.9739734, lng: 21.5833652 },
    { lat: 48.975309, lng: 21.5840527 },
    { lat: 48.9763694, lng: 21.5851638 },
    { lat: 48.9769338, lng: 21.5855544 },
    { lat: 48.9773142, lng: 21.585719 },
    { lat: 48.9785154, lng: 21.5857434 },
    { lat: 48.9792617, lng: 21.5850305 },
    { lat: 48.9794291, lng: 21.584701 },
    { lat: 48.9794442, lng: 21.5843981 },
    { lat: 48.9789278, lng: 21.5837931 },
    { lat: 48.9784749, lng: 21.5827646 },
    { lat: 48.9778252, lng: 21.5823033 },
    { lat: 48.9774822, lng: 21.5800085 },
    { lat: 48.9789184, lng: 21.5796275 },
    { lat: 48.9794359, lng: 21.5796257 },
    { lat: 48.980371, lng: 21.5794711 },
    { lat: 48.9811903, lng: 21.5790823 },
    { lat: 48.9825843, lng: 21.5781954 },
    { lat: 48.9829972, lng: 21.5779961 },
    { lat: 48.9839065, lng: 21.5778428 },
    { lat: 48.9843811, lng: 21.5781582 },
    { lat: 48.984595, lng: 21.57848 },
    { lat: 48.9850289, lng: 21.5797158 },
    { lat: 48.9856701, lng: 21.5811632 },
    { lat: 48.9860823, lng: 21.5815037 },
    { lat: 48.9865849, lng: 21.5810888 },
    { lat: 48.9870674, lng: 21.5812045 },
    { lat: 48.9873908, lng: 21.5811417 },
    { lat: 48.9881074, lng: 21.5816196 },
    { lat: 48.9883618, lng: 21.5814266 },
    { lat: 48.9892064, lng: 21.5803232 },
    { lat: 48.9894908, lng: 21.5793505 },
    { lat: 48.9897735, lng: 21.5781508 },
    { lat: 48.9900786, lng: 21.5781207 },
    { lat: 48.9896532, lng: 21.5755792 },
    { lat: 48.9912755, lng: 21.5751774 },
    { lat: 48.9917578, lng: 21.5752093 },
    { lat: 48.9927687, lng: 21.5754682 },
    { lat: 48.9932477, lng: 21.5757339 },
    { lat: 48.9935356, lng: 21.5760574 },
    { lat: 48.9937131, lng: 21.576487 },
    { lat: 48.9938057, lng: 21.5769159 },
    { lat: 48.9938759, lng: 21.5772414 },
    { lat: 48.9936364, lng: 21.5771659 },
    { lat: 48.9934055, lng: 21.5787217 },
    { lat: 48.9933558, lng: 21.5802518 },
    { lat: 48.9938407, lng: 21.5802073 },
    { lat: 48.9948331, lng: 21.5800924 },
    { lat: 48.9972527, lng: 21.5785823 },
    { lat: 48.9985384, lng: 21.5786967 },
    { lat: 48.9990909, lng: 21.5789025 },
    { lat: 48.9998939, lng: 21.5787672 },
    { lat: 48.9998733, lng: 21.5783923 },
    { lat: 49.0006337, lng: 21.5781533 },
    { lat: 49.0005254, lng: 21.5758824 },
    { lat: 49.0003927, lng: 21.5741205 },
    { lat: 49.000855, lng: 21.5734288 },
    { lat: 49.001395, lng: 21.5731058 },
    { lat: 49.0022012, lng: 21.5731268 },
    { lat: 49.0024934, lng: 21.5732394 },
    { lat: 49.0030601, lng: 21.573593 },
    { lat: 49.0034273, lng: 21.5727207 },
    { lat: 49.0034206, lng: 21.5722384 },
    { lat: 49.0034672, lng: 21.5719713 },
    { lat: 49.0042382, lng: 21.5711087 },
    { lat: 49.0048534, lng: 21.5696494 },
    { lat: 49.0052344, lng: 21.5684304 },
    { lat: 49.007169, lng: 21.5669325 },
    { lat: 49.0070912, lng: 21.5645935 },
    { lat: 49.0067837, lng: 21.564827 },
    { lat: 49.0057474, lng: 21.5634206 },
    { lat: 49.0050477, lng: 21.5621379 },
    { lat: 49.0027935, lng: 21.5587961 },
    { lat: 49.0001715, lng: 21.5553376 },
    { lat: 48.9990669, lng: 21.5540647 },
    { lat: 48.9983633, lng: 21.5529721 },
    { lat: 48.9977473, lng: 21.552431 },
    { lat: 48.9968213, lng: 21.552349 },
    { lat: 48.9960386, lng: 21.5527607 },
    { lat: 48.9958628, lng: 21.5527113 },
    { lat: 48.9951302, lng: 21.5523075 },
    { lat: 48.9937742, lng: 21.5518168 },
    { lat: 48.9937598, lng: 21.5515486 },
    { lat: 48.9929172, lng: 21.5515512 },
    { lat: 48.9919294, lng: 21.5511081 },
    { lat: 48.9913359, lng: 21.5513736 },
    { lat: 48.9913454, lng: 21.5519096 },
    { lat: 48.989253, lng: 21.5509983 },
    { lat: 48.9883687, lng: 21.5499632 },
    { lat: 48.9878691, lng: 21.5495623 },
    { lat: 48.9833501, lng: 21.5481851 },
    { lat: 48.9826291, lng: 21.5473498 },
    { lat: 48.9819235, lng: 21.5463499 },
    { lat: 48.9813063, lng: 21.5449308 },
    { lat: 48.9813003, lng: 21.543766 },
    { lat: 48.9815016, lng: 21.5432837 },
    { lat: 48.9812142, lng: 21.5423554 },
  ],
  Kučín: [
    { lat: 48.8773288, lng: 21.7402868 },
    { lat: 48.8765482, lng: 21.7398678 },
    { lat: 48.8763842, lng: 21.7381476 },
    { lat: 48.87588, lng: 21.737379 },
    { lat: 48.8754792, lng: 21.7370344 },
    { lat: 48.8752245, lng: 21.736719 },
    { lat: 48.8743553, lng: 21.7361808 },
    { lat: 48.8717789, lng: 21.7336934 },
    { lat: 48.8716597, lng: 21.7340312 },
    { lat: 48.8713138, lng: 21.7335879 },
    { lat: 48.8708829, lng: 21.7335071 },
    { lat: 48.8704053, lng: 21.7336061 },
    { lat: 48.8696871, lng: 21.7342662 },
    { lat: 48.8694059, lng: 21.7349038 },
    { lat: 48.8693793, lng: 21.7352571 },
    { lat: 48.8691564, lng: 21.7342823 },
    { lat: 48.8687575, lng: 21.7341752 },
    { lat: 48.8687444, lng: 21.7339305 },
    { lat: 48.8685686, lng: 21.7337937 },
    { lat: 48.8679595, lng: 21.733522 },
    { lat: 48.867501, lng: 21.7353954 },
    { lat: 48.866979, lng: 21.735446 },
    { lat: 48.8669492, lng: 21.7345316 },
    { lat: 48.8670691, lng: 21.7343098 },
    { lat: 48.8671203, lng: 21.7339897 },
    { lat: 48.8670555, lng: 21.7335874 },
    { lat: 48.8668776, lng: 21.7334136 },
    { lat: 48.8664102, lng: 21.7330992 },
    { lat: 48.8662171, lng: 21.7330707 },
    { lat: 48.8660817, lng: 21.7335642 },
    { lat: 48.8660157, lng: 21.7336594 },
    { lat: 48.8656475, lng: 21.7337331 },
    { lat: 48.8654449, lng: 21.7336497 },
    { lat: 48.8651274, lng: 21.7336338 },
    { lat: 48.8646534, lng: 21.7333706 },
    { lat: 48.8646672, lng: 21.733086 },
    { lat: 48.8647977, lng: 21.7324999 },
    { lat: 48.8652469, lng: 21.7322551 },
    { lat: 48.8656955, lng: 21.731822 },
    { lat: 48.8659393, lng: 21.7313914 },
    { lat: 48.8660478, lng: 21.7308174 },
    { lat: 48.865727, lng: 21.7307252 },
    { lat: 48.8656303, lng: 21.7308998 },
    { lat: 48.8652777, lng: 21.7309639 },
    { lat: 48.8648385, lng: 21.7309469 },
    { lat: 48.8642814, lng: 21.7320402 },
    { lat: 48.864114, lng: 21.7325054 },
    { lat: 48.8635964, lng: 21.7335598 },
    { lat: 48.8632743, lng: 21.7338389 },
    { lat: 48.8630739, lng: 21.7338316 },
    { lat: 48.8629529, lng: 21.7335535 },
    { lat: 48.8627425, lng: 21.7327553 },
    { lat: 48.862325, lng: 21.7323757 },
    { lat: 48.862085, lng: 21.7319795 },
    { lat: 48.8619545, lng: 21.7316377 },
    { lat: 48.8621028, lng: 21.7312971 },
    { lat: 48.8627705, lng: 21.730564 },
    { lat: 48.8629826, lng: 21.7300789 },
    { lat: 48.863166, lng: 21.7294513 },
    { lat: 48.8627922, lng: 21.7293113 },
    { lat: 48.8625475, lng: 21.7296676 },
    { lat: 48.862419, lng: 21.7297434 },
    { lat: 48.8622574, lng: 21.7296697 },
    { lat: 48.8619362, lng: 21.7299924 },
    { lat: 48.8616691, lng: 21.7303907 },
    { lat: 48.8615601, lng: 21.7306504 },
    { lat: 48.8614154, lng: 21.7312513 },
    { lat: 48.8613905, lng: 21.731628 },
    { lat: 48.8613266, lng: 21.7318467 },
    { lat: 48.8606954, lng: 21.7322674 },
    { lat: 48.8598588, lng: 21.7325355 },
    { lat: 48.8598359, lng: 21.732771 },
    { lat: 48.8598829, lng: 21.7333941 },
    { lat: 48.8598325, lng: 21.7343297 },
    { lat: 48.8597795, lng: 21.7344108 },
    { lat: 48.8595731, lng: 21.7347243 },
    { lat: 48.8595621, lng: 21.7349054 },
    { lat: 48.859004, lng: 21.7430949 },
    { lat: 48.8614981, lng: 21.7442763 },
    { lat: 48.8610818, lng: 21.7477467 },
    { lat: 48.8603591, lng: 21.7473864 },
    { lat: 48.8588796, lng: 21.7461994 },
    { lat: 48.8590534, lng: 21.7464732 },
    { lat: 48.8588018, lng: 21.7471195 },
    { lat: 48.8590658, lng: 21.7474039 },
    { lat: 48.8596567, lng: 21.7486047 },
    { lat: 48.8599899, lng: 21.7479685 },
    { lat: 48.8608224, lng: 21.7496691 },
    { lat: 48.8606939, lng: 21.7498252 },
    { lat: 48.8625735, lng: 21.7536745 },
    { lat: 48.8618335, lng: 21.7541905 },
    { lat: 48.8622866, lng: 21.7560913 },
    { lat: 48.8629609, lng: 21.7553817 },
    { lat: 48.8632076, lng: 21.7559459 },
    { lat: 48.8632857, lng: 21.7566097 },
    { lat: 48.8630889, lng: 21.7571186 },
    { lat: 48.8641822, lng: 21.7580865 },
    { lat: 48.8646692, lng: 21.7582081 },
    { lat: 48.864938, lng: 21.7581649 },
    { lat: 48.864808, lng: 21.7587299 },
    { lat: 48.8638504, lng: 21.7615366 },
    { lat: 48.8641401, lng: 21.7614732 },
    { lat: 48.8650316, lng: 21.7608899 },
    { lat: 48.8654319, lng: 21.7607591 },
    { lat: 48.8659596, lng: 21.7620795 },
    { lat: 48.8655717, lng: 21.7626397 },
    { lat: 48.8651118, lng: 21.7631193 },
    { lat: 48.86545, lng: 21.763529 },
    { lat: 48.8656396, lng: 21.7638502 },
    { lat: 48.8657233, lng: 21.7641191 },
    { lat: 48.8658412, lng: 21.7642378 },
    { lat: 48.8660706, lng: 21.7647002 },
    { lat: 48.8656493, lng: 21.7650663 },
    { lat: 48.8658535, lng: 21.7658525 },
    { lat: 48.8662019, lng: 21.7660136 },
    { lat: 48.8660389, lng: 21.7661421 },
    { lat: 48.8663569, lng: 21.7665538 },
    { lat: 48.8657037, lng: 21.7671712 },
    { lat: 48.8660801, lng: 21.7679613 },
    { lat: 48.8660466, lng: 21.7680498 },
    { lat: 48.8664772, lng: 21.7686801 },
    { lat: 48.8667656, lng: 21.7689531 },
    { lat: 48.8669609, lng: 21.7692332 },
    { lat: 48.8677789, lng: 21.7707341 },
    { lat: 48.8681411, lng: 21.7712825 },
    { lat: 48.8684249, lng: 21.770914 },
    { lat: 48.868669, lng: 21.7707852 },
    { lat: 48.8689976, lng: 21.7704098 },
    { lat: 48.8694565, lng: 21.7703155 },
    { lat: 48.8697432, lng: 21.7700119 },
    { lat: 48.869918, lng: 21.7696861 },
    { lat: 48.8705771, lng: 21.7697652 },
    { lat: 48.8716487, lng: 21.7694061 },
    { lat: 48.873072, lng: 21.7762458 },
    { lat: 48.8722593, lng: 21.7764393 },
    { lat: 48.8722826, lng: 21.7771541 },
    { lat: 48.8721454, lng: 21.7788198 },
    { lat: 48.8720446, lng: 21.7795253 },
    { lat: 48.8720332, lng: 21.7802475 },
    { lat: 48.872431, lng: 21.7800537 },
    { lat: 48.87313, lng: 21.7799029 },
    { lat: 48.8734307, lng: 21.7794398 },
    { lat: 48.8736181, lng: 21.7789106 },
    { lat: 48.8750863, lng: 21.7768237 },
    { lat: 48.8759623, lng: 21.7757404 },
    { lat: 48.8761836, lng: 21.7752992 },
    { lat: 48.8763704, lng: 21.7750852 },
    { lat: 48.8762687, lng: 21.7732754 },
    { lat: 48.8761643, lng: 21.7731946 },
    { lat: 48.8760244, lng: 21.7725029 },
    { lat: 48.8759025, lng: 21.772358 },
    { lat: 48.8760187, lng: 21.7714739 },
    { lat: 48.8759119, lng: 21.770281 },
    { lat: 48.8761116, lng: 21.7703021 },
    { lat: 48.876143, lng: 21.7701233 },
    { lat: 48.8760543, lng: 21.7694057 },
    { lat: 48.8761476, lng: 21.7687245 },
    { lat: 48.8760889, lng: 21.767306 },
    { lat: 48.8762309, lng: 21.7670218 },
    { lat: 48.8762634, lng: 21.7668433 },
    { lat: 48.8762122, lng: 21.7666988 },
    { lat: 48.8762776, lng: 21.7665787 },
    { lat: 48.8766667, lng: 21.7665275 },
    { lat: 48.876923, lng: 21.7663862 },
    { lat: 48.8770741, lng: 21.7664864 },
    { lat: 48.877744, lng: 21.7650189 },
    { lat: 48.8777911, lng: 21.7647137 },
    { lat: 48.8779303, lng: 21.7646556 },
    { lat: 48.8780752, lng: 21.7643462 },
    { lat: 48.878194, lng: 21.764295 },
    { lat: 48.8784864, lng: 21.7639928 },
    { lat: 48.8788605, lng: 21.7640122 },
    { lat: 48.8789662, lng: 21.763925 },
    { lat: 48.8794605, lng: 21.763816 },
    { lat: 48.8796498, lng: 21.7635318 },
    { lat: 48.8800525, lng: 21.7625058 },
    { lat: 48.8797322, lng: 21.7623934 },
    { lat: 48.8793256, lng: 21.7620736 },
    { lat: 48.878917, lng: 21.7618493 },
    { lat: 48.8784572, lng: 21.7616931 },
    { lat: 48.8780261, lng: 21.7615978 },
    { lat: 48.8773813, lng: 21.7617196 },
    { lat: 48.8766936, lng: 21.7614526 },
    { lat: 48.8767847, lng: 21.7607719 },
    { lat: 48.87679, lng: 21.7603391 },
    { lat: 48.8765741, lng: 21.7599998 },
    { lat: 48.8758756, lng: 21.7599204 },
    { lat: 48.8755549, lng: 21.7584296 },
    { lat: 48.875797, lng: 21.758179 },
    { lat: 48.8755771, lng: 21.7562513 },
    { lat: 48.8760573, lng: 21.7561953 },
    { lat: 48.8761252, lng: 21.7561361 },
    { lat: 48.8763358, lng: 21.7555432 },
    { lat: 48.8765513, lng: 21.7545119 },
    { lat: 48.8767136, lng: 21.7544802 },
    { lat: 48.8767475, lng: 21.75414 },
    { lat: 48.8770888, lng: 21.7540753 },
    { lat: 48.877249, lng: 21.7531119 },
    { lat: 48.8774004, lng: 21.7531406 },
    { lat: 48.8774933, lng: 21.752571 },
    { lat: 48.8770361, lng: 21.7523379 },
    { lat: 48.8771506, lng: 21.7512669 },
    { lat: 48.8773224, lng: 21.7501999 },
    { lat: 48.8773076, lng: 21.7500665 },
    { lat: 48.876922, lng: 21.7499924 },
    { lat: 48.8770401, lng: 21.7491846 },
    { lat: 48.8773665, lng: 21.7477497 },
    { lat: 48.8774444, lng: 21.7476299 },
    { lat: 48.8773996, lng: 21.7476012 },
    { lat: 48.8775239, lng: 21.7471536 },
    { lat: 48.8776128, lng: 21.7465234 },
    { lat: 48.8775904, lng: 21.7450412 },
    { lat: 48.8773825, lng: 21.7421374 },
    { lat: 48.8773517, lng: 21.7411818 },
    { lat: 48.8773288, lng: 21.7402868 },
  ],
  Ďurďoš: [
    { lat: 49.031607, lng: 21.5330821 },
    { lat: 49.0321507, lng: 21.5327001 },
    { lat: 49.0326208, lng: 21.5326901 },
    { lat: 49.0331614, lng: 21.5332643 },
    { lat: 49.0337594, lng: 21.5342334 },
    { lat: 49.0339577, lng: 21.5343674 },
    { lat: 49.0341513, lng: 21.5346531 },
    { lat: 49.0341904, lng: 21.5349596 },
    { lat: 49.0345476, lng: 21.5344604 },
    { lat: 49.0344757, lng: 21.5342607 },
    { lat: 49.0350111, lng: 21.5329462 },
    { lat: 49.0351729, lng: 21.5320672 },
    { lat: 49.035461, lng: 21.5313573 },
    { lat: 49.0354883, lng: 21.5305333 },
    { lat: 49.0356745, lng: 21.5301561 },
    { lat: 49.0358323, lng: 21.529213 },
    { lat: 49.0359535, lng: 21.5288885 },
    { lat: 49.0363937, lng: 21.5281417 },
    { lat: 49.0371454, lng: 21.527586 },
    { lat: 49.0374723, lng: 21.5276076 },
    { lat: 49.0377866, lng: 21.5278258 },
    { lat: 49.0381291, lng: 21.5275262 },
    { lat: 49.0385413, lng: 21.527536 },
    { lat: 49.0387993, lng: 21.5272 },
    { lat: 49.0390589, lng: 21.5267099 },
    { lat: 49.0392256, lng: 21.5269156 },
    { lat: 49.0395137, lng: 21.526518 },
    { lat: 49.0401335, lng: 21.5252458 },
    { lat: 49.0407791, lng: 21.5259153 },
    { lat: 49.0411962, lng: 21.5259829 },
    { lat: 49.0416876, lng: 21.5262092 },
    { lat: 49.0414605, lng: 21.5271279 },
    { lat: 49.0418099, lng: 21.5273449 },
    { lat: 49.0421648, lng: 21.5274051 },
    { lat: 49.0422987, lng: 21.5275211 },
    { lat: 49.0422533, lng: 21.5277934 },
    { lat: 49.0431341, lng: 21.5283005 },
    { lat: 49.0437045, lng: 21.5285023 },
    { lat: 49.043903, lng: 21.5285183 },
    { lat: 49.0442213, lng: 21.5284347 },
    { lat: 49.0445741, lng: 21.5293716 },
    { lat: 49.0446342, lng: 21.5295032 },
    { lat: 49.0446933, lng: 21.5294431 },
    { lat: 49.0448998, lng: 21.5300245 },
    { lat: 49.0449974, lng: 21.530172 },
    { lat: 49.0452142, lng: 21.5302033 },
    { lat: 49.0454653, lng: 21.5303604 },
    { lat: 49.0457641, lng: 21.53039 },
    { lat: 49.0459649, lng: 21.5305608 },
    { lat: 49.0468392, lng: 21.5316795 },
    { lat: 49.0486525, lng: 21.5332991 },
    { lat: 49.048856, lng: 21.5334164 },
    { lat: 49.0499589, lng: 21.5343746 },
    { lat: 49.0507702, lng: 21.5345449 },
    { lat: 49.0513012, lng: 21.5351368 },
    { lat: 49.0516335, lng: 21.5353901 },
    { lat: 49.0519648, lng: 21.5354857 },
    { lat: 49.0527386, lng: 21.535519 },
    { lat: 49.0536167, lng: 21.5352165 },
    { lat: 49.0542321, lng: 21.5352594 },
    { lat: 49.0550896, lng: 21.5351871 },
    { lat: 49.0556227, lng: 21.5350234 },
    { lat: 49.0559083, lng: 21.5350187 },
    { lat: 49.0564726, lng: 21.5352397 },
    { lat: 49.0569635, lng: 21.5352473 },
    { lat: 49.0574475, lng: 21.5353412 },
    { lat: 49.0583434, lng: 21.535708 },
    { lat: 49.059591, lng: 21.5365393 },
    { lat: 49.0601673, lng: 21.536709 },
    { lat: 49.0609134, lng: 21.5366748 },
    { lat: 49.0607873, lng: 21.5368678 },
    { lat: 49.0606569, lng: 21.5376671 },
    { lat: 49.0606048, lng: 21.5387335 },
    { lat: 49.0610924, lng: 21.5393032 },
    { lat: 49.0611251, lng: 21.5396279 },
    { lat: 49.061442, lng: 21.5405321 },
    { lat: 49.0614285, lng: 21.5408135 },
    { lat: 49.0612705, lng: 21.5411108 },
    { lat: 49.0615068, lng: 21.5415073 },
    { lat: 49.0616676, lng: 21.5414243 },
    { lat: 49.0617819, lng: 21.5416887 },
    { lat: 49.0617942, lng: 21.5419646 },
    { lat: 49.0618897, lng: 21.5420817 },
    { lat: 49.0620509, lng: 21.5421311 },
    { lat: 49.0621882, lng: 21.5424102 },
    { lat: 49.0621466, lng: 21.5428499 },
    { lat: 49.0622881, lng: 21.5427998 },
    { lat: 49.0625152, lng: 21.543355 },
    { lat: 49.062635, lng: 21.543457 },
    { lat: 49.0627014, lng: 21.5436948 },
    { lat: 49.0626729, lng: 21.5439654 },
    { lat: 49.0628372, lng: 21.5440045 },
    { lat: 49.0629833, lng: 21.5444373 },
    { lat: 49.0632313, lng: 21.5445284 },
    { lat: 49.064033, lng: 21.5452334 },
    { lat: 49.0641914, lng: 21.5456257 },
    { lat: 49.0643357, lng: 21.5456367 },
    { lat: 49.0645053, lng: 21.5459413 },
    { lat: 49.0647996, lng: 21.5460416 },
    { lat: 49.0649757, lng: 21.5464689 },
    { lat: 49.0652257, lng: 21.5463564 },
    { lat: 49.0655852, lng: 21.5466577 },
    { lat: 49.0658127, lng: 21.546547 },
    { lat: 49.0660163, lng: 21.5467447 },
    { lat: 49.0665121, lng: 21.5468523 },
    { lat: 49.0665795, lng: 21.5468016 },
    { lat: 49.0669752, lng: 21.5472741 },
    { lat: 49.0670383, lng: 21.547237 },
    { lat: 49.0671982, lng: 21.5474831 },
    { lat: 49.0673054, lng: 21.5474546 },
    { lat: 49.0676446, lng: 21.5478702 },
    { lat: 49.0679807, lng: 21.5481016 },
    { lat: 49.0681368, lng: 21.5480964 },
    { lat: 49.068305, lng: 21.5482343 },
    { lat: 49.0685449, lng: 21.5482366 },
    { lat: 49.0688511, lng: 21.5483717 },
    { lat: 49.068918, lng: 21.5485858 },
    { lat: 49.0692438, lng: 21.5486507 },
    { lat: 49.0694093, lng: 21.548853 },
    { lat: 49.069569, lng: 21.5488918 },
    { lat: 49.0697803, lng: 21.5493175 },
    { lat: 49.069961, lng: 21.5493065 },
    { lat: 49.0701172, lng: 21.5495044 },
    { lat: 49.0702364, lng: 21.5495425 },
    { lat: 49.0703416, lng: 21.5497625 },
    { lat: 49.0710022, lng: 21.5501216 },
    { lat: 49.0711416, lng: 21.5504423 },
    { lat: 49.0719527, lng: 21.5514013 },
    { lat: 49.0733508, lng: 21.5515879 },
    { lat: 49.0735956, lng: 21.5514062 },
    { lat: 49.0737052, lng: 21.5512203 },
    { lat: 49.074262, lng: 21.550688 },
    { lat: 49.074281, lng: 21.550601 },
    { lat: 49.074484, lng: 21.550444 },
    { lat: 49.074563, lng: 21.550335 },
    { lat: 49.074448, lng: 21.550159 },
    { lat: 49.074506, lng: 21.549913 },
    { lat: 49.074511, lng: 21.549646 },
    { lat: 49.074572, lng: 21.549537 },
    { lat: 49.074807, lng: 21.549376 },
    { lat: 49.074944, lng: 21.54925 },
    { lat: 49.075459, lng: 21.548964 },
    { lat: 49.075314, lng: 21.548435 },
    { lat: 49.075021, lng: 21.547587 },
    { lat: 49.075016, lng: 21.547572 },
    { lat: 49.074995, lng: 21.547553 },
    { lat: 49.074798, lng: 21.547398 },
    { lat: 49.074622, lng: 21.547003 },
    { lat: 49.07451, lng: 21.54647 },
    { lat: 49.074334, lng: 21.545957 },
    { lat: 49.074009, lng: 21.54587 },
    { lat: 49.073675, lng: 21.545694 },
    { lat: 49.073755, lng: 21.545014 },
    { lat: 49.073407, lng: 21.544838 },
    { lat: 49.073361, lng: 21.544256 },
    { lat: 49.073262, lng: 21.54391 },
    { lat: 49.073152, lng: 21.543765 },
    { lat: 49.07312, lng: 21.543606 },
    { lat: 49.072967, lng: 21.543524 },
    { lat: 49.072867, lng: 21.543551 },
    { lat: 49.072748, lng: 21.543477 },
    { lat: 49.072784, lng: 21.542892 },
    { lat: 49.072239, lng: 21.542597 },
    { lat: 49.072062, lng: 21.542502 },
    { lat: 49.071804, lng: 21.542265 },
    { lat: 49.071515, lng: 21.542084 },
    { lat: 49.071112, lng: 21.5418 },
    { lat: 49.070961, lng: 21.541649 },
    { lat: 49.070645, lng: 21.541432 },
    { lat: 49.070443, lng: 21.54138 },
    { lat: 49.070204, lng: 21.541361 },
    { lat: 49.069953, lng: 21.54127 },
    { lat: 49.069763, lng: 21.539425 },
    { lat: 49.06967, lng: 21.539435 },
    { lat: 49.069662, lng: 21.538977 },
    { lat: 49.069585, lng: 21.538505 },
    { lat: 49.069366, lng: 21.537905 },
    { lat: 49.069269, lng: 21.537641 },
    { lat: 49.069069, lng: 21.537232 },
    { lat: 49.068837, lng: 21.536849 },
    { lat: 49.068879, lng: 21.536781 },
    { lat: 49.068651, lng: 21.536289 },
    { lat: 49.068355, lng: 21.535675 },
    { lat: 49.068233, lng: 21.535322 },
    { lat: 49.068104, lng: 21.534892 },
    { lat: 49.06799, lng: 21.534189 },
    { lat: 49.067907, lng: 21.533577 },
    { lat: 49.067679, lng: 21.532738 },
    { lat: 49.067663, lng: 21.532547 },
    { lat: 49.067677, lng: 21.532076 },
    { lat: 49.067672, lng: 21.531911 },
    { lat: 49.067664, lng: 21.531688 },
    { lat: 49.067973, lng: 21.530789 },
    { lat: 49.068334, lng: 21.530291 },
    { lat: 49.068494, lng: 21.529624 },
    { lat: 49.068482, lng: 21.529199 },
    { lat: 49.068445, lng: 21.528967 },
    { lat: 49.068425, lng: 21.528629 },
    { lat: 49.068412, lng: 21.528296 },
    { lat: 49.068215, lng: 21.527431 },
    { lat: 49.068177, lng: 21.527175 },
    { lat: 49.068092, lng: 21.526955 },
    { lat: 49.067688, lng: 21.526273 },
    { lat: 49.067635, lng: 21.525879 },
    { lat: 49.067426, lng: 21.525447 },
    { lat: 49.067219, lng: 21.525295 },
    { lat: 49.067125, lng: 21.525237 },
    { lat: 49.066782, lng: 21.524967 },
    { lat: 49.06663, lng: 21.5248 },
    { lat: 49.066564, lng: 21.524727 },
    { lat: 49.066428, lng: 21.524671 },
    { lat: 49.06622, lng: 21.524659 },
    { lat: 49.066028, lng: 21.524684 },
    { lat: 49.065897, lng: 21.524672 },
    { lat: 49.065753, lng: 21.524616 },
    { lat: 49.065605, lng: 21.524485 },
    { lat: 49.066159, lng: 21.523324 },
    { lat: 49.066284, lng: 21.522984 },
    { lat: 49.065867, lng: 21.522682 },
    { lat: 49.065472, lng: 21.523777 },
    { lat: 49.065448, lng: 21.523746 },
    { lat: 49.064412, lng: 21.522411 },
    { lat: 49.064343, lng: 21.522323 },
    { lat: 49.064214, lng: 21.522172 },
    { lat: 49.0640277, lng: 21.5230194 },
    { lat: 49.0638221, lng: 21.5247293 },
    { lat: 49.0635363, lng: 21.5256052 },
    { lat: 49.0632416, lng: 21.5260052 },
    { lat: 49.0628313, lng: 21.5262944 },
    { lat: 49.062602, lng: 21.5263794 },
    { lat: 49.0620459, lng: 21.5263643 },
    { lat: 49.0617397, lng: 21.5261602 },
    { lat: 49.0613924, lng: 21.5257421 },
    { lat: 49.0608227, lng: 21.5246501 },
    { lat: 49.0605393, lng: 21.5222005 },
    { lat: 49.0605481, lng: 21.5209711 },
    { lat: 49.0605511, lng: 21.5205594 },
    { lat: 49.060498, lng: 21.5199161 },
    { lat: 49.0602579, lng: 21.5186694 },
    { lat: 49.0599666, lng: 21.5177268 },
    { lat: 49.0598354, lng: 21.5169949 },
    { lat: 49.0595235, lng: 21.5159521 },
    { lat: 49.0591174, lng: 21.5150102 },
    { lat: 49.058646, lng: 21.514294 },
    { lat: 49.0582713, lng: 21.5153612 },
    { lat: 49.0572366, lng: 21.5146983 },
    { lat: 49.0566716, lng: 21.5142979 },
    { lat: 49.0563191, lng: 21.5141317 },
    { lat: 49.054464, lng: 21.5143756 },
    { lat: 49.0543415, lng: 21.5142208 },
    { lat: 49.0537843, lng: 21.5144484 },
    { lat: 49.0529125, lng: 21.5153617 },
    { lat: 49.0519821, lng: 21.5161952 },
    { lat: 49.0511167, lng: 21.5159918 },
    { lat: 49.0508073, lng: 21.5180833 },
    { lat: 49.0499633, lng: 21.5179925 },
    { lat: 49.0497641, lng: 21.5179195 },
    { lat: 49.049334, lng: 21.517469 },
    { lat: 49.0491812, lng: 21.5168489 },
    { lat: 49.049164, lng: 21.5155319 },
    { lat: 49.0484758, lng: 21.5155047 },
    { lat: 49.048221, lng: 21.5155997 },
    { lat: 49.0478158, lng: 21.5160338 },
    { lat: 49.0473372, lng: 21.5159423 },
    { lat: 49.0470774, lng: 21.5156255 },
    { lat: 49.0468335, lng: 21.5154307 },
    { lat: 49.0466335, lng: 21.5158033 },
    { lat: 49.046111, lng: 21.5155439 },
    { lat: 49.0457924, lng: 21.5150678 },
    { lat: 49.0460377, lng: 21.5135259 },
    { lat: 49.0458917, lng: 21.5134979 },
    { lat: 49.0455814, lng: 21.5132641 },
    { lat: 49.0447869, lng: 21.5122713 },
    { lat: 49.0442067, lng: 21.5116529 },
    { lat: 49.0437454, lng: 21.5095641 },
    { lat: 49.0434302, lng: 21.5095562 },
    { lat: 49.0432357, lng: 21.5090085 },
    { lat: 49.0423586, lng: 21.5081153 },
    { lat: 49.0418063, lng: 21.506549 },
    { lat: 49.0416872, lng: 21.5059602 },
    { lat: 49.0419568, lng: 21.5055529 },
    { lat: 49.0407681, lng: 21.5040362 },
    { lat: 49.0408051, lng: 21.5036397 },
    { lat: 49.0400188, lng: 21.5037214 },
    { lat: 49.0394253, lng: 21.5038976 },
    { lat: 49.0381887, lng: 21.5049142 },
    { lat: 49.0373131, lng: 21.5060525 },
    { lat: 49.0361026, lng: 21.5082639 },
    { lat: 49.0349089, lng: 21.5109424 },
    { lat: 49.034065, lng: 21.5131115 },
    { lat: 49.0339166, lng: 21.5135125 },
    { lat: 49.0337744, lng: 21.5138926 },
    { lat: 49.0336143, lng: 21.5153005 },
    { lat: 49.0333981, lng: 21.5164417 },
    { lat: 49.0333017, lng: 21.5172806 },
    { lat: 49.0333397, lng: 21.5189702 },
    { lat: 49.0333078, lng: 21.5197142 },
    { lat: 49.0332176, lng: 21.5205724 },
    { lat: 49.0329382, lng: 21.5218228 },
    { lat: 49.0328011, lng: 21.5228258 },
    { lat: 49.0328406, lng: 21.5242155 },
    { lat: 49.0331127, lng: 21.5251941 },
    { lat: 49.0331895, lng: 21.5258419 },
    { lat: 49.0330781, lng: 21.5267916 },
    { lat: 49.032997, lng: 21.5271902 },
    { lat: 49.0327694, lng: 21.5278846 },
    { lat: 49.0321292, lng: 21.5292791 },
    { lat: 49.0319749, lng: 21.5297087 },
    { lat: 49.0311795, lng: 21.5322141 },
    { lat: 49.0312592, lng: 21.5323207 },
    { lat: 49.031369, lng: 21.5324695 },
    { lat: 49.031607, lng: 21.5330821 },
  ],
  Babie: [
    { lat: 49.069671, lng: 21.521321 },
    { lat: 49.069681, lng: 21.521285 },
    { lat: 49.069755, lng: 21.520999 },
    { lat: 49.069771, lng: 21.520942 },
    { lat: 49.069784, lng: 21.520796 },
    { lat: 49.070024, lng: 21.520325 },
    { lat: 49.070045, lng: 21.520286 },
    { lat: 49.070093, lng: 21.520192 },
    { lat: 49.070246, lng: 21.519744 },
    { lat: 49.070616, lng: 21.519221 },
    { lat: 49.070627, lng: 21.519215 },
    { lat: 49.070918, lng: 21.519057 },
    { lat: 49.071171, lng: 21.518685 },
    { lat: 49.071318, lng: 21.518523 },
    { lat: 49.071487, lng: 21.518336 },
    { lat: 49.071497, lng: 21.518194 },
    { lat: 49.071662, lng: 21.518084 },
    { lat: 49.071747, lng: 21.517927 },
    { lat: 49.072142, lng: 21.517771 },
    { lat: 49.072161, lng: 21.517764 },
    { lat: 49.072278, lng: 21.517754 },
    { lat: 49.072313, lng: 21.517513 },
    { lat: 49.072448, lng: 21.517378 },
    { lat: 49.072492, lng: 21.517501 },
    { lat: 49.072632, lng: 21.51747 },
    { lat: 49.072659, lng: 21.517297 },
    { lat: 49.072794, lng: 21.517172 },
    { lat: 49.072957, lng: 21.516838 },
    { lat: 49.072955, lng: 21.516589 },
    { lat: 49.073029, lng: 21.516407 },
    { lat: 49.073051, lng: 21.516196 },
    { lat: 49.07315, lng: 21.515859 },
    { lat: 49.073299, lng: 21.514814 },
    { lat: 49.073263, lng: 21.514266 },
    { lat: 49.073271, lng: 21.514011 },
    { lat: 49.073208, lng: 21.51362 },
    { lat: 49.073193, lng: 21.513528 },
    { lat: 49.073216, lng: 21.513324 },
    { lat: 49.07271, lng: 21.512285 },
    { lat: 49.072541, lng: 21.511839 },
    { lat: 49.072504, lng: 21.511619 },
    { lat: 49.072261, lng: 21.511069 },
    { lat: 49.072236, lng: 21.51107 },
    { lat: 49.072264, lng: 21.510428 },
    { lat: 49.072237, lng: 21.509877 },
    { lat: 49.072219, lng: 21.508852 },
    { lat: 49.072232, lng: 21.508684 },
    { lat: 49.072207, lng: 21.508453 },
    { lat: 49.072204, lng: 21.508432 },
    { lat: 49.072567, lng: 21.508141 },
    { lat: 49.072913, lng: 21.507805 },
    { lat: 49.073396, lng: 21.507424 },
    { lat: 49.073667, lng: 21.50716 },
    { lat: 49.074282, lng: 21.505865 },
    { lat: 49.074426, lng: 21.505636 },
    { lat: 49.074524, lng: 21.505392 },
    { lat: 49.074755, lng: 21.504821 },
    { lat: 49.074986, lng: 21.504475 },
    { lat: 49.075161, lng: 21.504211 },
    { lat: 49.075164, lng: 21.504179 },
    { lat: 49.075305, lng: 21.503781 },
    { lat: 49.075522, lng: 21.502955 },
    { lat: 49.075756, lng: 21.502364 },
    { lat: 49.076048, lng: 21.501997 },
    { lat: 49.076203, lng: 21.501403 },
    { lat: 49.076311, lng: 21.501116 },
    { lat: 49.076651, lng: 21.500013 },
    { lat: 49.07699, lng: 21.498935 },
    { lat: 49.077118, lng: 21.498384 },
    { lat: 49.077197, lng: 21.497991 },
    { lat: 49.077235, lng: 21.497882 },
    { lat: 49.077409, lng: 21.497206 },
    { lat: 49.07745, lng: 21.49697 },
    { lat: 49.077807, lng: 21.496501 },
    { lat: 49.078372, lng: 21.495756 },
    { lat: 49.078452, lng: 21.495592 },
    { lat: 49.078472, lng: 21.495516 },
    { lat: 49.078483, lng: 21.495474 },
    { lat: 49.078466, lng: 21.494856 },
    { lat: 49.07841, lng: 21.494609 },
    { lat: 49.078452, lng: 21.494218 },
    { lat: 49.078412, lng: 21.493506 },
    { lat: 49.078373, lng: 21.493293 },
    { lat: 49.078337, lng: 21.493099 },
    { lat: 49.07834, lng: 21.493079 },
    { lat: 49.078363, lng: 21.492898 },
    { lat: 49.078393, lng: 21.492688 },
    { lat: 49.078424, lng: 21.49247 },
    { lat: 49.078452, lng: 21.492273 },
    { lat: 49.078469, lng: 21.492152 },
    { lat: 49.078494, lng: 21.492056 },
    { lat: 49.078549, lng: 21.491851 },
    { lat: 49.078585, lng: 21.491717 },
    { lat: 49.078625, lng: 21.491655 },
    { lat: 49.078741, lng: 21.491475 },
    { lat: 49.078766, lng: 21.491299 },
    { lat: 49.078773, lng: 21.491103 },
    { lat: 49.07878, lng: 21.490866 },
    { lat: 49.078812, lng: 21.490541 },
    { lat: 49.078832, lng: 21.490337 },
    { lat: 49.078844, lng: 21.490213 },
    { lat: 49.078878, lng: 21.489862 },
    { lat: 49.078904, lng: 21.489594 },
    { lat: 49.078909, lng: 21.48956 },
    { lat: 49.078911, lng: 21.489535 },
    { lat: 49.078975, lng: 21.488777 },
    { lat: 49.079002, lng: 21.488459 },
    { lat: 49.079006, lng: 21.488411 },
    { lat: 49.079061, lng: 21.487759 },
    { lat: 49.079291, lng: 21.487389 },
    { lat: 49.079725, lng: 21.486514 },
    { lat: 49.079737, lng: 21.486486 },
    { lat: 49.079775, lng: 21.486379 },
    { lat: 49.079718, lng: 21.486076 },
    { lat: 49.079673, lng: 21.486003 },
    { lat: 49.079569, lng: 21.485809 },
    { lat: 49.079041, lng: 21.485279 },
    { lat: 49.079077, lng: 21.4852 },
    { lat: 49.079294, lng: 21.484751 },
    { lat: 49.079466, lng: 21.484394 },
    { lat: 49.079555, lng: 21.484063 },
    { lat: 49.079637, lng: 21.483656 },
    { lat: 49.079756, lng: 21.482856 },
    { lat: 49.079723, lng: 21.482807 },
    { lat: 49.080129, lng: 21.481562 },
    { lat: 49.079654, lng: 21.480803 },
    { lat: 49.08002, lng: 21.480256 },
    { lat: 49.079668, lng: 21.478661 },
    { lat: 49.079603, lng: 21.478191 },
    { lat: 49.079231, lng: 21.477051 },
    { lat: 49.079205, lng: 21.476654 },
    { lat: 49.079007, lng: 21.475853 },
    { lat: 49.078947, lng: 21.475481 },
    { lat: 49.078829, lng: 21.475013 },
    { lat: 49.078713, lng: 21.474572 },
    { lat: 49.078575, lng: 21.474145 },
    { lat: 49.078463, lng: 21.473296 },
    { lat: 49.078458, lng: 21.473266 },
    { lat: 49.078547, lng: 21.472763 },
    { lat: 49.078253, lng: 21.472701 },
    { lat: 49.078279, lng: 21.471777 },
    { lat: 49.077777, lng: 21.47168 },
    { lat: 49.07778, lng: 21.471483 },
    { lat: 49.077799, lng: 21.471392 },
    { lat: 49.077916, lng: 21.470833 },
    { lat: 49.078127, lng: 21.470882 },
    { lat: 49.078449, lng: 21.47052 },
    { lat: 49.078545, lng: 21.470418 },
    { lat: 49.078648, lng: 21.470304 },
    { lat: 49.078461, lng: 21.470283 },
    { lat: 49.078323, lng: 21.470109 },
    { lat: 49.078279, lng: 21.469804 },
    { lat: 49.078267, lng: 21.469706 },
    { lat: 49.078008, lng: 21.469398 },
    { lat: 49.077878, lng: 21.469073 },
    { lat: 49.077761, lng: 21.468603 },
    { lat: 49.077704, lng: 21.468324 },
    { lat: 49.077509, lng: 21.468156 },
    { lat: 49.07757, lng: 21.467905 },
    { lat: 49.077597, lng: 21.467856 },
    { lat: 49.077471, lng: 21.467475 },
    { lat: 49.077522, lng: 21.467329 },
    { lat: 49.077543, lng: 21.467233 },
    { lat: 49.077467, lng: 21.467053 },
    { lat: 49.077564, lng: 21.466687 },
    { lat: 49.077555, lng: 21.466597 },
    { lat: 49.077659, lng: 21.465955 },
    { lat: 49.077625, lng: 21.465833 },
    { lat: 49.077492, lng: 21.465701 },
    { lat: 49.077491, lng: 21.465667 },
    { lat: 49.077569, lng: 21.465371 },
    { lat: 49.077414, lng: 21.465121 },
    { lat: 49.07737, lng: 21.465048 },
    { lat: 49.077183, lng: 21.46483 },
    { lat: 49.076822, lng: 21.464574 },
    { lat: 49.076482, lng: 21.464006 },
    { lat: 49.075693, lng: 21.463754 },
    { lat: 49.075291, lng: 21.463654 },
    { lat: 49.074845, lng: 21.462824 },
    { lat: 49.074828, lng: 21.462788 },
    { lat: 49.074134, lng: 21.46147 },
    { lat: 49.074117, lng: 21.461439 },
    { lat: 49.073707, lng: 21.460445 },
    { lat: 49.073697, lng: 21.460428 },
    { lat: 49.073384, lng: 21.459735 },
    { lat: 49.073043, lng: 21.458806 },
    { lat: 49.072735, lng: 21.458405 },
    { lat: 49.072539, lng: 21.458017 },
    { lat: 49.072499, lng: 21.457995 },
    { lat: 49.072219, lng: 21.457658 },
    { lat: 49.072125, lng: 21.457528 },
    { lat: 49.071849, lng: 21.457226 },
    { lat: 49.071591, lng: 21.45705 },
    { lat: 49.071497, lng: 21.456773 },
    { lat: 49.071246, lng: 21.456407 },
    { lat: 49.071142, lng: 21.456295 },
    { lat: 49.071129, lng: 21.456318 },
    { lat: 49.070898, lng: 21.456754 },
    { lat: 49.07086, lng: 21.456874 },
    { lat: 49.070652, lng: 21.457531 },
    { lat: 49.070361, lng: 21.458107 },
    { lat: 49.0702, lng: 21.458422 },
    { lat: 49.069807, lng: 21.459331 },
    { lat: 49.069387, lng: 21.460003 },
    { lat: 49.069356, lng: 21.460049 },
    { lat: 49.069138, lng: 21.460477 },
    { lat: 49.06909, lng: 21.46052 },
    { lat: 49.068723, lng: 21.460863 },
    { lat: 49.068479, lng: 21.461375 },
    { lat: 49.068347, lng: 21.461585 },
    { lat: 49.068048, lng: 21.462084 },
    { lat: 49.067943, lng: 21.462241 },
    { lat: 49.067525, lng: 21.462651 },
    { lat: 49.067178, lng: 21.462822 },
    { lat: 49.0669245, lng: 21.4632697 },
    { lat: 49.0668423, lng: 21.4632731 },
    { lat: 49.0666206, lng: 21.4635804 },
    { lat: 49.0664542, lng: 21.4640465 },
    { lat: 49.0662506, lng: 21.4643281 },
    { lat: 49.0659886, lng: 21.4648405 },
    { lat: 49.0658716, lng: 21.465581 },
    { lat: 49.0656362, lng: 21.4662716 },
    { lat: 49.0653191, lng: 21.4668199 },
    { lat: 49.0649414, lng: 21.4680435 },
    { lat: 49.0646204, lng: 21.4686104 },
    { lat: 49.0644932, lng: 21.4687287 },
    { lat: 49.0643917, lng: 21.4689949 },
    { lat: 49.0643542, lng: 21.4692966 },
    { lat: 49.0642655, lng: 21.4694905 },
    { lat: 49.0641647, lng: 21.4702898 },
    { lat: 49.0638006, lng: 21.4720582 },
    { lat: 49.0626456, lng: 21.4753156 },
    { lat: 49.0625293, lng: 21.4758152 },
    { lat: 49.062613, lng: 21.4759047 },
    { lat: 49.0625581, lng: 21.4761922 },
    { lat: 49.0624087, lng: 21.4764328 },
    { lat: 49.0619596, lng: 21.476932 },
    { lat: 49.061533, lng: 21.4771169 },
    { lat: 49.0610594, lng: 21.477593 },
    { lat: 49.0605895, lng: 21.477963 },
    { lat: 49.0601347, lng: 21.4784636 },
    { lat: 49.0594999, lng: 21.4798595 },
    { lat: 49.059104, lng: 21.480334 },
    { lat: 49.0591485, lng: 21.4804695 },
    { lat: 49.0587032, lng: 21.4808313 },
    { lat: 49.058473, lng: 21.4808883 },
    { lat: 49.0578541, lng: 21.480847 },
    { lat: 49.0578446, lng: 21.4804756 },
    { lat: 49.0575675, lng: 21.4804241 },
    { lat: 49.0571129, lng: 21.4801821 },
    { lat: 49.0567133, lng: 21.4802582 },
    { lat: 49.0559217, lng: 21.4808271 },
    { lat: 49.0550462, lng: 21.4817998 },
    { lat: 49.0549492, lng: 21.4817058 },
    { lat: 49.0548134, lng: 21.4820757 },
    { lat: 49.0543374, lng: 21.4826587 },
    { lat: 49.0542083, lng: 21.4832198 },
    { lat: 49.0540495, lng: 21.4839105 },
    { lat: 49.0551637, lng: 21.4849843 },
    { lat: 49.0549765, lng: 21.4854365 },
    { lat: 49.0552812, lng: 21.4862923 },
    { lat: 49.0555334, lng: 21.4867677 },
    { lat: 49.0555503, lng: 21.4874371 },
    { lat: 49.0553619, lng: 21.4876175 },
    { lat: 49.0553966, lng: 21.4881619 },
    { lat: 49.0555407, lng: 21.4887058 },
    { lat: 49.0557777, lng: 21.4893225 },
    { lat: 49.055955, lng: 21.4904084 },
    { lat: 49.0559592, lng: 21.4909007 },
    { lat: 49.0560317, lng: 21.4912733 },
    { lat: 49.0563084, lng: 21.4920929 },
    { lat: 49.0566249, lng: 21.4928145 },
    { lat: 49.0571905, lng: 21.4935454 },
    { lat: 49.0573961, lng: 21.4931436 },
    { lat: 49.0576484, lng: 21.4934332 },
    { lat: 49.0581903, lng: 21.4938895 },
    { lat: 49.0586548, lng: 21.4944729 },
    { lat: 49.0618979, lng: 21.4972816 },
    { lat: 49.0621396, lng: 21.4974295 },
    { lat: 49.0625722, lng: 21.4980072 },
    { lat: 49.0631226, lng: 21.4991142 },
    { lat: 49.0637437, lng: 21.5007665 },
    { lat: 49.0635722, lng: 21.501104 },
    { lat: 49.0629761, lng: 21.5018994 },
    { lat: 49.0625492, lng: 21.5029818 },
    { lat: 49.0616907, lng: 21.5045974 },
    { lat: 49.061794, lng: 21.5048202 },
    { lat: 49.0616546, lng: 21.5050264 },
    { lat: 49.0615823, lng: 21.5049379 },
    { lat: 49.0614124, lng: 21.5052541 },
    { lat: 49.0613803, lng: 21.5057402 },
    { lat: 49.0612333, lng: 21.5059913 },
    { lat: 49.0613439, lng: 21.5063223 },
    { lat: 49.0611843, lng: 21.5066048 },
    { lat: 49.0611913, lng: 21.5070087 },
    { lat: 49.0609999, lng: 21.5075548 },
    { lat: 49.0607124, lng: 21.5076918 },
    { lat: 49.0606913, lng: 21.5078927 },
    { lat: 49.0607508, lng: 21.5081693 },
    { lat: 49.0606561, lng: 21.5083636 },
    { lat: 49.0604126, lng: 21.5084227 },
    { lat: 49.0603992, lng: 21.5087371 },
    { lat: 49.0602444, lng: 21.5090706 },
    { lat: 49.0600197, lng: 21.5091185 },
    { lat: 49.0598218, lng: 21.509322 },
    { lat: 49.0592887, lng: 21.5100837 },
    { lat: 49.0590354, lng: 21.5102712 },
    { lat: 49.058909, lng: 21.5105733 },
    { lat: 49.0588662, lng: 21.510927 },
    { lat: 49.0586654, lng: 21.510829 },
    { lat: 49.0576155, lng: 21.5137062 },
    { lat: 49.0572366, lng: 21.5146983 },
    { lat: 49.0582713, lng: 21.5153612 },
    { lat: 49.058646, lng: 21.514294 },
    { lat: 49.0591174, lng: 21.5150102 },
    { lat: 49.0595235, lng: 21.5159521 },
    { lat: 49.0598354, lng: 21.5169949 },
    { lat: 49.0599666, lng: 21.5177268 },
    { lat: 49.0602579, lng: 21.5186694 },
    { lat: 49.060498, lng: 21.5199161 },
    { lat: 49.0605511, lng: 21.5205594 },
    { lat: 49.0605481, lng: 21.5209711 },
    { lat: 49.0605393, lng: 21.5222005 },
    { lat: 49.0608227, lng: 21.5246501 },
    { lat: 49.0613924, lng: 21.5257421 },
    { lat: 49.0617397, lng: 21.5261602 },
    { lat: 49.0620459, lng: 21.5263643 },
    { lat: 49.062602, lng: 21.5263794 },
    { lat: 49.0628313, lng: 21.5262944 },
    { lat: 49.0632416, lng: 21.5260052 },
    { lat: 49.0635363, lng: 21.5256052 },
    { lat: 49.0638221, lng: 21.5247293 },
    { lat: 49.0640277, lng: 21.5230194 },
    { lat: 49.064214, lng: 21.522172 },
    { lat: 49.06486, lng: 21.520972 },
    { lat: 49.065284, lng: 21.520463 },
    { lat: 49.065675, lng: 21.520025 },
    { lat: 49.065891, lng: 21.519843 },
    { lat: 49.066209, lng: 21.519642 },
    { lat: 49.06648, lng: 21.519532 },
    { lat: 49.066732, lng: 21.519494 },
    { lat: 49.067349, lng: 21.519598 },
    { lat: 49.068391, lng: 21.519931 },
    { lat: 49.069671, lng: 21.521321 },
  ],
  Piskorovce: [
    { lat: 49.126221, lng: 21.719698 },
    { lat: 49.125986, lng: 21.719316 },
    { lat: 49.125776, lng: 21.719528 },
    { lat: 49.125583, lng: 21.719351 },
    { lat: 49.125511, lng: 21.719468 },
    { lat: 49.125146, lng: 21.718813 },
    { lat: 49.124964, lng: 21.718957 },
    { lat: 49.124776, lng: 21.718842 },
    { lat: 49.124526, lng: 21.718965 },
    { lat: 49.124057, lng: 21.718422 },
    { lat: 49.12344, lng: 21.717293 },
    { lat: 49.123428, lng: 21.717259 },
    { lat: 49.123116, lng: 21.717551 },
    { lat: 49.122713, lng: 21.718177 },
    { lat: 49.122614, lng: 21.718408 },
    { lat: 49.122271, lng: 21.719003 },
    { lat: 49.122242, lng: 21.71896 },
    { lat: 49.121984, lng: 21.718455 },
    { lat: 49.121345, lng: 21.71773 },
    { lat: 49.121179, lng: 21.717258 },
    { lat: 49.120838, lng: 21.717148 },
    { lat: 49.120561, lng: 21.717081 },
    { lat: 49.120098, lng: 21.716897 },
    { lat: 49.119781, lng: 21.716553 },
    { lat: 49.119561, lng: 21.716488 },
    { lat: 49.118841, lng: 21.716785 },
    { lat: 49.118331, lng: 21.717038 },
    { lat: 49.117929, lng: 21.717207 },
    { lat: 49.117624, lng: 21.717274 },
    { lat: 49.11688, lng: 21.717724 },
    { lat: 49.115639, lng: 21.718418 },
    { lat: 49.115042, lng: 21.718677 },
    { lat: 49.114533, lng: 21.718847 },
    { lat: 49.114167, lng: 21.718844 },
    { lat: 49.113617, lng: 21.718974 },
    { lat: 49.113189, lng: 21.719033 },
    { lat: 49.112744, lng: 21.719378 },
    { lat: 49.112352, lng: 21.71963 },
    { lat: 49.11206, lng: 21.719657 },
    { lat: 49.111702, lng: 21.719734 },
    { lat: 49.111316, lng: 21.719723 },
    { lat: 49.111071, lng: 21.719685 },
    { lat: 49.110399, lng: 21.719857 },
    { lat: 49.110351, lng: 21.719866 },
    { lat: 49.110008, lng: 21.720074 },
    { lat: 49.109675, lng: 21.720304 },
    { lat: 49.109553, lng: 21.720498 },
    { lat: 49.109079, lng: 21.720952 },
    { lat: 49.108484, lng: 21.721649 },
    { lat: 49.107598, lng: 21.722325 },
    { lat: 49.107364, lng: 21.723165 },
    { lat: 49.107205, lng: 21.723295 },
    { lat: 49.1071, lng: 21.723383 },
    { lat: 49.106326, lng: 21.724022 },
    { lat: 49.105875, lng: 21.724396 },
    { lat: 49.105327, lng: 21.724955 },
    { lat: 49.105296, lng: 21.724982 },
    { lat: 49.105147, lng: 21.724899 },
    { lat: 49.104939, lng: 21.724868 },
    { lat: 49.104564, lng: 21.725064 },
    { lat: 49.104447, lng: 21.725054 },
    { lat: 49.10395, lng: 21.724875 },
    { lat: 49.103353, lng: 21.724449 },
    { lat: 49.103034, lng: 21.724222 },
    { lat: 49.102655, lng: 21.723968 },
    { lat: 49.102495, lng: 21.723924 },
    { lat: 49.102274, lng: 21.723938 },
    { lat: 49.102065, lng: 21.723879 },
    { lat: 49.101651, lng: 21.724017 },
    { lat: 49.100892, lng: 21.72417 },
    { lat: 49.10009, lng: 21.724441 },
    { lat: 49.099923, lng: 21.724475 },
    { lat: 49.099815, lng: 21.724487 },
    { lat: 49.099709, lng: 21.72445 },
    { lat: 49.099715, lng: 21.724419 },
    { lat: 49.099586, lng: 21.724294 },
    { lat: 49.099218, lng: 21.724201 },
    { lat: 49.098741, lng: 21.724234 },
    { lat: 49.097937, lng: 21.724109 },
    { lat: 49.097154, lng: 21.724107 },
    { lat: 49.096149, lng: 21.723993 },
    { lat: 49.095889, lng: 21.724508 },
    { lat: 49.095756, lng: 21.724964 },
    { lat: 49.095533, lng: 21.72489 },
    { lat: 49.095005, lng: 21.725477 },
    { lat: 49.094556, lng: 21.725422 },
    { lat: 49.094357, lng: 21.725459 },
    { lat: 49.094076, lng: 21.725315 },
    { lat: 49.093984, lng: 21.725314 },
    { lat: 49.0938359, lng: 21.7264273 },
    { lat: 49.0938885, lng: 21.7269678 },
    { lat: 49.0940193, lng: 21.727234 },
    { lat: 49.094104, lng: 21.7277044 },
    { lat: 49.0940586, lng: 21.7281153 },
    { lat: 49.0944259, lng: 21.7295784 },
    { lat: 49.0944611, lng: 21.7301394 },
    { lat: 49.0944255, lng: 21.7304485 },
    { lat: 49.0946109, lng: 21.7311991 },
    { lat: 49.094673, lng: 21.7316749 },
    { lat: 49.0943949, lng: 21.732262 },
    { lat: 49.0941999, lng: 21.7328344 },
    { lat: 49.0919181, lng: 21.7350223 },
    { lat: 49.0915089, lng: 21.7355008 },
    { lat: 49.0909524, lng: 21.7359367 },
    { lat: 49.0900696, lng: 21.7375535 },
    { lat: 49.0899941, lng: 21.7380595 },
    { lat: 49.0907147, lng: 21.7396943 },
    { lat: 49.0908654, lng: 21.7402019 },
    { lat: 49.0910505, lng: 21.7401865 },
    { lat: 49.0911263, lng: 21.740245 },
    { lat: 49.0912506, lng: 21.74048 },
    { lat: 49.091244, lng: 21.7406143 },
    { lat: 49.0914346, lng: 21.7408837 },
    { lat: 49.0915953, lng: 21.7414334 },
    { lat: 49.0917157, lng: 21.7414317 },
    { lat: 49.0918218, lng: 21.7418685 },
    { lat: 49.0920663, lng: 21.7418793 },
    { lat: 49.0921276, lng: 21.742121 },
    { lat: 49.0922699, lng: 21.7420527 },
    { lat: 49.092368, lng: 21.7422323 },
    { lat: 49.093662, lng: 21.7429676 },
    { lat: 49.0941383, lng: 21.7439172 },
    { lat: 49.0944613, lng: 21.7440072 },
    { lat: 49.0945381, lng: 21.7438525 },
    { lat: 49.09516, lng: 21.7441848 },
    { lat: 49.0955, lng: 21.7447497 },
    { lat: 49.0963098, lng: 21.7452076 },
    { lat: 49.0966585, lng: 21.7451629 },
    { lat: 49.0967286, lng: 21.7453887 },
    { lat: 49.0969408, lng: 21.7457572 },
    { lat: 49.0972081, lng: 21.7460398 },
    { lat: 49.0975522, lng: 21.7461623 },
    { lat: 49.0977535, lng: 21.7465833 },
    { lat: 49.0976136, lng: 21.7468284 },
    { lat: 49.0976328, lng: 21.746955 },
    { lat: 49.097746, lng: 21.746999 },
    { lat: 49.097796, lng: 21.747009 },
    { lat: 49.097946, lng: 21.74701 },
    { lat: 49.098136, lng: 21.747133 },
    { lat: 49.098318, lng: 21.746993 },
    { lat: 49.098591, lng: 21.747007 },
    { lat: 49.09897, lng: 21.746809 },
    { lat: 49.099112, lng: 21.746745 },
    { lat: 49.099536, lng: 21.74697 },
    { lat: 49.099787, lng: 21.74683 },
    { lat: 49.099908, lng: 21.746884 },
    { lat: 49.099981, lng: 21.74702 },
    { lat: 49.100122, lng: 21.74699 },
    { lat: 49.100333, lng: 21.747042 },
    { lat: 49.100306, lng: 21.747329 },
    { lat: 49.100605, lng: 21.748401 },
    { lat: 49.101659, lng: 21.749685 },
    { lat: 49.10288, lng: 21.752208 },
    { lat: 49.102989, lng: 21.752374 },
    { lat: 49.103403, lng: 21.751623 },
    { lat: 49.104528, lng: 21.750783 },
    { lat: 49.105176, lng: 21.750057 },
    { lat: 49.106158, lng: 21.749358 },
    { lat: 49.106725, lng: 21.748509 },
    { lat: 49.107551, lng: 21.748291 },
    { lat: 49.107572, lng: 21.748287 },
    { lat: 49.10761, lng: 21.74828 },
    { lat: 49.108157, lng: 21.748171 },
    { lat: 49.109341, lng: 21.747866 },
    { lat: 49.10948, lng: 21.747861 },
    { lat: 49.110672, lng: 21.747814 },
    { lat: 49.111053, lng: 21.747799 },
    { lat: 49.111462, lng: 21.747785 },
    { lat: 49.112625, lng: 21.748558 },
    { lat: 49.112665, lng: 21.748618 },
    { lat: 49.115357, lng: 21.748435 },
    { lat: 49.115834, lng: 21.748706 },
    { lat: 49.118243, lng: 21.748609 },
    { lat: 49.119125, lng: 21.74921 },
    { lat: 49.119463, lng: 21.749564 },
    { lat: 49.119897, lng: 21.749819 },
    { lat: 49.120107, lng: 21.749957 },
    { lat: 49.120817, lng: 21.750421 },
    { lat: 49.120936, lng: 21.748522 },
    { lat: 49.120762, lng: 21.747728 },
    { lat: 49.121071, lng: 21.747977 },
    { lat: 49.121226, lng: 21.748093 },
    { lat: 49.121638, lng: 21.748119 },
    { lat: 49.121954, lng: 21.747976 },
    { lat: 49.121966, lng: 21.747929 },
    { lat: 49.12218, lng: 21.747542 },
    { lat: 49.123494, lng: 21.747904 },
    { lat: 49.127834, lng: 21.745932 },
    { lat: 49.129822, lng: 21.742644 },
    { lat: 49.131382, lng: 21.742764 },
    { lat: 49.132003, lng: 21.742777 },
    { lat: 49.132314, lng: 21.742733 },
    { lat: 49.132382, lng: 21.741239 },
    { lat: 49.132458, lng: 21.740776 },
    { lat: 49.132399, lng: 21.740417 },
    { lat: 49.132162, lng: 21.739747 },
    { lat: 49.131992, lng: 21.739209 },
    { lat: 49.130694, lng: 21.738551 },
    { lat: 49.129749, lng: 21.737671 },
    { lat: 49.12956, lng: 21.737493 },
    { lat: 49.129389, lng: 21.737474 },
    { lat: 49.129188, lng: 21.737497 },
    { lat: 49.128678, lng: 21.737357 },
    { lat: 49.128437, lng: 21.737103 },
    { lat: 49.128357, lng: 21.737002 },
    { lat: 49.128277, lng: 21.736829 },
    { lat: 49.12812, lng: 21.736834 },
    { lat: 49.128037, lng: 21.736876 },
    { lat: 49.127953, lng: 21.73676 },
    { lat: 49.127856, lng: 21.73656 },
    { lat: 49.127698, lng: 21.736325 },
    { lat: 49.12761, lng: 21.736247 },
    { lat: 49.127402, lng: 21.736193 },
    { lat: 49.127314, lng: 21.736083 },
    { lat: 49.127096, lng: 21.736089 },
    { lat: 49.127062, lng: 21.73584 },
    { lat: 49.12694, lng: 21.735703 },
    { lat: 49.126942, lng: 21.735393 },
    { lat: 49.127083, lng: 21.735135 },
    { lat: 49.127136, lng: 21.735091 },
    { lat: 49.127203, lng: 21.735088 },
    { lat: 49.127359, lng: 21.734908 },
    { lat: 49.127513, lng: 21.734795 },
    { lat: 49.12748, lng: 21.734467 },
    { lat: 49.127703, lng: 21.734423 },
    { lat: 49.127575, lng: 21.734243 },
    { lat: 49.127667, lng: 21.734036 },
    { lat: 49.127651, lng: 21.733945 },
    { lat: 49.127693, lng: 21.733609 },
    { lat: 49.127606, lng: 21.733288 },
    { lat: 49.127755, lng: 21.732891 },
    { lat: 49.127696, lng: 21.732514 },
    { lat: 49.127659, lng: 21.73216 },
    { lat: 49.127735, lng: 21.731853 },
    { lat: 49.127662, lng: 21.731101 },
    { lat: 49.127491, lng: 21.730575 },
    { lat: 49.127448, lng: 21.730012 },
    { lat: 49.12745, lng: 21.72977 },
    { lat: 49.127233, lng: 21.729296 },
    { lat: 49.127217, lng: 21.729005 },
    { lat: 49.127076, lng: 21.728824 },
    { lat: 49.127004, lng: 21.728454 },
    { lat: 49.126994, lng: 21.728185 },
    { lat: 49.126947, lng: 21.727922 },
    { lat: 49.126807, lng: 21.727478 },
    { lat: 49.126783, lng: 21.727119 },
    { lat: 49.126689, lng: 21.726644 },
    { lat: 49.126578, lng: 21.726477 },
    { lat: 49.126497, lng: 21.72625 },
    { lat: 49.126535, lng: 21.725911 },
    { lat: 49.126513, lng: 21.725732 },
    { lat: 49.126531, lng: 21.725448 },
    { lat: 49.126609, lng: 21.725194 },
    { lat: 49.12662, lng: 21.724939 },
    { lat: 49.126601, lng: 21.72475 },
    { lat: 49.12643, lng: 21.724421 },
    { lat: 49.125888, lng: 21.723703 },
    { lat: 49.125307, lng: 21.723022 },
    { lat: 49.124943, lng: 21.722634 },
    { lat: 49.125256, lng: 21.72212 },
    { lat: 49.125258, lng: 21.722018 },
    { lat: 49.125588, lng: 21.72166 },
    { lat: 49.125876, lng: 21.721238 },
    { lat: 49.126084, lng: 21.720903 },
    { lat: 49.126047, lng: 21.720746 },
    { lat: 49.126171, lng: 21.720393 },
    { lat: 49.126032, lng: 21.720083 },
    { lat: 49.126039, lng: 21.719901 },
    { lat: 49.126221, lng: 21.719698 },
  ],
  NižnýHrušov: [
    { lat: 48.7882274, lng: 21.7317187 },
    { lat: 48.7881819, lng: 21.7317454 },
    { lat: 48.786513, lng: 21.7327321 },
    { lat: 48.7855881, lng: 21.7323078 },
    { lat: 48.7858195, lng: 21.7309896 },
    { lat: 48.7856191, lng: 21.7294728 },
    { lat: 48.780683, lng: 21.729791 },
    { lat: 48.780685, lng: 21.729845 },
    { lat: 48.780687, lng: 21.729919 },
    { lat: 48.78057, lng: 21.735934 },
    { lat: 48.780472, lng: 21.740936 },
    { lat: 48.780352, lng: 21.740958 },
    { lat: 48.780199, lng: 21.740972 },
    { lat: 48.779998, lng: 21.740948 },
    { lat: 48.779939, lng: 21.740931 },
    { lat: 48.7788, lng: 21.740731 },
    { lat: 48.77719, lng: 21.740449 },
    { lat: 48.775954, lng: 21.740233 },
    { lat: 48.774637, lng: 21.74 },
    { lat: 48.774635, lng: 21.740046 },
    { lat: 48.774548, lng: 21.74277 },
    { lat: 48.77454, lng: 21.743123 },
    { lat: 48.774486, lng: 21.743946 },
    { lat: 48.77451, lng: 21.744188 },
    { lat: 48.774497, lng: 21.744538 },
    { lat: 48.77442, lng: 21.74596 },
    { lat: 48.774393, lng: 21.746872 },
    { lat: 48.774364, lng: 21.747796 },
    { lat: 48.774348, lng: 21.748379 },
    { lat: 48.774341, lng: 21.74877 },
    { lat: 48.774342, lng: 21.748947 },
    { lat: 48.774349, lng: 21.7491 },
    { lat: 48.77436, lng: 21.749178 },
    { lat: 48.774107, lng: 21.74915 },
    { lat: 48.77418, lng: 21.750513 },
    { lat: 48.774346, lng: 21.752886 },
    { lat: 48.774539, lng: 21.755316 },
    { lat: 48.774532, lng: 21.755452 },
    { lat: 48.774527, lng: 21.755575 },
    { lat: 48.774542, lng: 21.755809 },
    { lat: 48.774601, lng: 21.756002 },
    { lat: 48.774693, lng: 21.756273 },
    { lat: 48.774717, lng: 21.756385 },
    { lat: 48.774724, lng: 21.75641 },
    { lat: 48.774734, lng: 21.756472 },
    { lat: 48.775617, lng: 21.756403 },
    { lat: 48.776996, lng: 21.756321 },
    { lat: 48.777584, lng: 21.756329 },
    { lat: 48.778898, lng: 21.756194 },
    { lat: 48.779304, lng: 21.756153 },
    { lat: 48.780797, lng: 21.756036 },
    { lat: 48.7808, lng: 21.756099 },
    { lat: 48.780867, lng: 21.757738 },
    { lat: 48.780703, lng: 21.758421 },
    { lat: 48.780727, lng: 21.758756 },
    { lat: 48.780885, lng: 21.7593 },
    { lat: 48.780997, lng: 21.759693 },
    { lat: 48.781214, lng: 21.760053 },
    { lat: 48.781673, lng: 21.760345 },
    { lat: 48.781738, lng: 21.76051 },
    { lat: 48.781679, lng: 21.760647 },
    { lat: 48.781548, lng: 21.760703 },
    { lat: 48.78157, lng: 21.760805 },
    { lat: 48.781594, lng: 21.760918 },
    { lat: 48.781616, lng: 21.761017 },
    { lat: 48.781618, lng: 21.761028 },
    { lat: 48.782073, lng: 21.763233 },
    { lat: 48.782629, lng: 21.765888 },
    { lat: 48.783299, lng: 21.768616 },
    { lat: 48.783801, lng: 21.771304 },
    { lat: 48.783819, lng: 21.771378 },
    { lat: 48.783828, lng: 21.771413 },
    { lat: 48.785696, lng: 21.77075 },
    { lat: 48.786643, lng: 21.770426 },
    { lat: 48.787375, lng: 21.77019 },
    { lat: 48.787376, lng: 21.770214 },
    { lat: 48.787474, lng: 21.772204 },
    { lat: 48.787499, lng: 21.772628 },
    { lat: 48.787595, lng: 21.774494 },
    { lat: 48.78763, lng: 21.775596 },
    { lat: 48.787743, lng: 21.777906 },
    { lat: 48.787856, lng: 21.778005 },
    { lat: 48.78808, lng: 21.7782 },
    { lat: 48.788108, lng: 21.778262 },
    { lat: 48.788151, lng: 21.778355 },
    { lat: 48.7882, lng: 21.778458 },
    { lat: 48.788072, lng: 21.778493 },
    { lat: 48.787942, lng: 21.778528 },
    { lat: 48.788195, lng: 21.779461 },
    { lat: 48.788286, lng: 21.779718 },
    { lat: 48.78827, lng: 21.780079 },
    { lat: 48.788484, lng: 21.780381 },
    { lat: 48.788349, lng: 21.780915 },
    { lat: 48.788492, lng: 21.781484 },
    { lat: 48.788634, lng: 21.781927 },
    { lat: 48.788991, lng: 21.782478 },
    { lat: 48.789274, lng: 21.782936 },
    { lat: 48.789431, lng: 21.783804 },
    { lat: 48.789678, lng: 21.784421 },
    { lat: 48.789825, lng: 21.784886 },
    { lat: 48.790031, lng: 21.785123 },
    { lat: 48.790406, lng: 21.785792 },
    { lat: 48.790725, lng: 21.786499 },
    { lat: 48.790903, lng: 21.787593 },
    { lat: 48.790905, lng: 21.788172 },
    { lat: 48.791022, lng: 21.788345 },
    { lat: 48.791559, lng: 21.789138 },
    { lat: 48.7918, lng: 21.790625 },
    { lat: 48.792051, lng: 21.791331 },
    { lat: 48.79209, lng: 21.792449 },
    { lat: 48.792172, lng: 21.793187 },
    { lat: 48.79181, lng: 21.794661 },
    { lat: 48.79187, lng: 21.795033 },
    { lat: 48.791927, lng: 21.795387 },
    { lat: 48.79209, lng: 21.795955 },
    { lat: 48.792224, lng: 21.796555 },
    { lat: 48.792061, lng: 21.797195 },
    { lat: 48.791979, lng: 21.797429 },
    { lat: 48.791818, lng: 21.797949 },
    { lat: 48.791608, lng: 21.798629 },
    { lat: 48.791376, lng: 21.799417 },
    { lat: 48.791348, lng: 21.799885 },
    { lat: 48.790728, lng: 21.801258 },
    { lat: 48.790714, lng: 21.801752 },
    { lat: 48.790558, lng: 21.802272 },
    { lat: 48.790537, lng: 21.802342 },
    { lat: 48.791611, lng: 21.802049 },
    { lat: 48.792166, lng: 21.801919 },
    { lat: 48.792912, lng: 21.80174 },
    { lat: 48.793767, lng: 21.801583 },
    { lat: 48.794616, lng: 21.801178 },
    { lat: 48.795948, lng: 21.800863 },
    { lat: 48.796689, lng: 21.800632 },
    { lat: 48.79682, lng: 21.800369 },
    { lat: 48.797826, lng: 21.800351 },
    { lat: 48.797872, lng: 21.800563 },
    { lat: 48.797929, lng: 21.800582 },
    { lat: 48.798802, lng: 21.800437 },
    { lat: 48.79883, lng: 21.800433 },
    { lat: 48.801387, lng: 21.800008 },
    { lat: 48.801333, lng: 21.800175 },
    { lat: 48.801075, lng: 21.80097 },
    { lat: 48.800561, lng: 21.802903 },
    { lat: 48.800539, lng: 21.802981 },
    { lat: 48.800556, lng: 21.802989 },
    { lat: 48.800607, lng: 21.803016 },
    { lat: 48.800689, lng: 21.803058 },
    { lat: 48.80073, lng: 21.80308 },
    { lat: 48.80076, lng: 21.803096 },
    { lat: 48.801147, lng: 21.803149 },
    { lat: 48.801859, lng: 21.803248 },
    { lat: 48.80256, lng: 21.803387 },
    { lat: 48.803712, lng: 21.803567 },
    { lat: 48.803745, lng: 21.803576 },
    { lat: 48.804253, lng: 21.803715 },
    { lat: 48.804268, lng: 21.803719 },
    { lat: 48.804707, lng: 21.804392 },
    { lat: 48.805205, lng: 21.804712 },
    { lat: 48.805719, lng: 21.805496 },
    { lat: 48.805749, lng: 21.805542 },
    { lat: 48.806095, lng: 21.805891 },
    { lat: 48.80613, lng: 21.805925 },
    { lat: 48.806047, lng: 21.80698 },
    { lat: 48.806365, lng: 21.8067 },
    { lat: 48.806579, lng: 21.806517 },
    { lat: 48.806942, lng: 21.806423 },
    { lat: 48.807105, lng: 21.806353 },
    { lat: 48.807519, lng: 21.806177 },
    { lat: 48.807634, lng: 21.806109 },
    { lat: 48.80807, lng: 21.805906 },
    { lat: 48.808287, lng: 21.805795 },
    { lat: 48.80834, lng: 21.805739 },
    { lat: 48.808595, lng: 21.805547 },
    { lat: 48.80876, lng: 21.805342 },
    { lat: 48.808881, lng: 21.805222 },
    { lat: 48.808945, lng: 21.805141 },
    { lat: 48.809005, lng: 21.805142 },
    { lat: 48.809258, lng: 21.805037 },
    { lat: 48.809503, lng: 21.804939 },
    { lat: 48.809575, lng: 21.80491 },
    { lat: 48.809637, lng: 21.804867 },
    { lat: 48.809811, lng: 21.804781 },
    { lat: 48.810057, lng: 21.804644 },
    { lat: 48.810216, lng: 21.804573 },
    { lat: 48.810669, lng: 21.804424 },
    { lat: 48.810911, lng: 21.804311 },
    { lat: 48.811182, lng: 21.804214 },
    { lat: 48.811207, lng: 21.804192 },
    { lat: 48.811414, lng: 21.804029 },
    { lat: 48.81178, lng: 21.80371 },
    { lat: 48.811837, lng: 21.803617 },
    { lat: 48.812024, lng: 21.803275 },
    { lat: 48.812125, lng: 21.803165 },
    { lat: 48.812537, lng: 21.802704 },
    { lat: 48.812933, lng: 21.802267 },
    { lat: 48.812988, lng: 21.802224 },
    { lat: 48.813079, lng: 21.802014 },
    { lat: 48.813386, lng: 21.801587 },
    { lat: 48.813664, lng: 21.801191 },
    { lat: 48.813732, lng: 21.801086 },
    { lat: 48.813876, lng: 21.80102 },
    { lat: 48.814013, lng: 21.800956 },
    { lat: 48.814133, lng: 21.800887 },
    { lat: 48.814277, lng: 21.800686 },
    { lat: 48.814445, lng: 21.800465 },
    { lat: 48.814544, lng: 21.800336 },
    { lat: 48.814606, lng: 21.800244 },
    { lat: 48.814673, lng: 21.800153 },
    { lat: 48.81474, lng: 21.800075 },
    { lat: 48.814828, lng: 21.799995 },
    { lat: 48.814913, lng: 21.799921 },
    { lat: 48.815, lng: 21.799854 },
    { lat: 48.815124, lng: 21.79975 },
    { lat: 48.815322, lng: 21.799587 },
    { lat: 48.815508, lng: 21.799431 },
    { lat: 48.81559, lng: 21.799364 },
    { lat: 48.815694, lng: 21.799282 },
    { lat: 48.815719, lng: 21.79926 },
    { lat: 48.815816, lng: 21.799218 },
    { lat: 48.815971, lng: 21.799044 },
    { lat: 48.816054, lng: 21.798969 },
    { lat: 48.816155, lng: 21.79888 },
    { lat: 48.8162, lng: 21.798843 },
    { lat: 48.816282, lng: 21.798776 },
    { lat: 48.816407, lng: 21.79868 },
    { lat: 48.816515, lng: 21.79859 },
    { lat: 48.816621, lng: 21.798517 },
    { lat: 48.81672, lng: 21.798427 },
    { lat: 48.816924, lng: 21.798272 },
    { lat: 48.816936, lng: 21.798277 },
    { lat: 48.817076, lng: 21.798043 },
    { lat: 48.817213, lng: 21.797837 },
    { lat: 48.817484, lng: 21.797432 },
    { lat: 48.817724, lng: 21.797073 },
    { lat: 48.817751, lng: 21.797023 },
    { lat: 48.818144, lng: 21.796613 },
    { lat: 48.818242, lng: 21.796516 },
    { lat: 48.818391, lng: 21.796159 },
    { lat: 48.818543, lng: 21.795774 },
    { lat: 48.818633, lng: 21.795666 },
    { lat: 48.818789, lng: 21.795452 },
    { lat: 48.819076, lng: 21.795035 },
    { lat: 48.819383, lng: 21.794551 },
    { lat: 48.819432, lng: 21.794474 },
    { lat: 48.819918, lng: 21.793736 },
    { lat: 48.820234, lng: 21.793489 },
    { lat: 48.820419, lng: 21.793296 },
    { lat: 48.820478, lng: 21.793319 },
    { lat: 48.820635, lng: 21.793257 },
    { lat: 48.820772, lng: 21.793201 },
    { lat: 48.820901, lng: 21.793152 },
    { lat: 48.821075, lng: 21.793082 },
    { lat: 48.821298, lng: 21.792963 },
    { lat: 48.821393, lng: 21.792925 },
    { lat: 48.8215, lng: 21.792836 },
    { lat: 48.821833, lng: 21.792495 },
    { lat: 48.822044, lng: 21.79265 },
    { lat: 48.822105, lng: 21.792694 },
    { lat: 48.822532, lng: 21.792917 },
    { lat: 48.822603, lng: 21.793022 },
    { lat: 48.822821, lng: 21.793176 },
    { lat: 48.82332, lng: 21.793494 },
    { lat: 48.82367, lng: 21.793902 },
    { lat: 48.823908, lng: 21.794151 },
    { lat: 48.824032, lng: 21.794221 },
    { lat: 48.824368, lng: 21.794903 },
    { lat: 48.824795, lng: 21.795489 },
    { lat: 48.825239, lng: 21.795991 },
    { lat: 48.825739, lng: 21.795883 },
    { lat: 48.82603, lng: 21.795721 },
    { lat: 48.826706, lng: 21.795667 },
    { lat: 48.8270048, lng: 21.7940328 },
    { lat: 48.8273941, lng: 21.7923974 },
    { lat: 48.8273857, lng: 21.791999 },
    { lat: 48.828146, lng: 21.7879522 },
    { lat: 48.8286907, lng: 21.785435 },
    { lat: 48.8287472, lng: 21.7848849 },
    { lat: 48.8290237, lng: 21.7834671 },
    { lat: 48.8292647, lng: 21.78257 },
    { lat: 48.8294136, lng: 21.7815176 },
    { lat: 48.8297549, lng: 21.7797778 },
    { lat: 48.8301692, lng: 21.7772725 },
    { lat: 48.8302302, lng: 21.7765568 },
    { lat: 48.8301448, lng: 21.7752755 },
    { lat: 48.8290966, lng: 21.774735 },
    { lat: 48.829385, lng: 21.7727441 },
    { lat: 48.829276, lng: 21.7704883 },
    { lat: 48.8289743, lng: 21.7703134 },
    { lat: 48.8288818, lng: 21.7703454 },
    { lat: 48.8284065, lng: 21.7693891 },
    { lat: 48.8293943, lng: 21.7687462 },
    { lat: 48.8290883, lng: 21.7677717 },
    { lat: 48.8288983, lng: 21.767559 },
    { lat: 48.8282873, lng: 21.7664902 },
    { lat: 48.8276157, lng: 21.7673828 },
    { lat: 48.8259946, lng: 21.7621122 },
    { lat: 48.8274848, lng: 21.7612044 },
    { lat: 48.8274285, lng: 21.7609932 },
    { lat: 48.8271564, lng: 21.7587821 },
    { lat: 48.8262298, lng: 21.7586352 },
    { lat: 48.8244461, lng: 21.759799 },
    { lat: 48.8227026, lng: 21.7534899 },
    { lat: 48.8224739, lng: 21.7525053 },
    { lat: 48.8215595, lng: 21.7517887 },
    { lat: 48.8215732, lng: 21.752941 },
    { lat: 48.8213524, lng: 21.7544129 },
    { lat: 48.8209227, lng: 21.7552691 },
    { lat: 48.8205049, lng: 21.7555051 },
    { lat: 48.819629, lng: 21.7555198 },
    { lat: 48.8179619, lng: 21.7550608 },
    { lat: 48.8175472, lng: 21.7547401 },
    { lat: 48.8164127, lng: 21.7546417 },
    { lat: 48.8157626, lng: 21.7550703 },
    { lat: 48.815376, lng: 21.7557182 },
    { lat: 48.815187, lng: 21.7574556 },
    { lat: 48.8148993, lng: 21.7589542 },
    { lat: 48.8142736, lng: 21.7597086 },
    { lat: 48.8137337, lng: 21.7598428 },
    { lat: 48.8126987, lng: 21.7593327 },
    { lat: 48.8116861, lng: 21.7588217 },
    { lat: 48.8112538, lng: 21.7584692 },
    { lat: 48.8096664, lng: 21.7577914 },
    { lat: 48.8076099, lng: 21.7574121 },
    { lat: 48.8068784, lng: 21.7574519 },
    { lat: 48.8059203, lng: 21.7572906 },
    { lat: 48.8032347, lng: 21.7566965 },
    { lat: 48.8009003, lng: 21.7560993 },
    { lat: 48.798937, lng: 21.7557983 },
    { lat: 48.7982396, lng: 21.7532217 },
    { lat: 48.7982493, lng: 21.7530702 },
    { lat: 48.7969566, lng: 21.7479971 },
    { lat: 48.7968125, lng: 21.747871 },
    { lat: 48.7955388, lng: 21.7442243 },
    { lat: 48.7948912, lng: 21.7421261 },
    { lat: 48.7943686, lng: 21.7398771 },
    { lat: 48.7932123, lng: 21.7327179 },
    { lat: 48.793076, lng: 21.7311559 },
    { lat: 48.7931583, lng: 21.7309107 },
    { lat: 48.7932919, lng: 21.7298067 },
    { lat: 48.7933409, lng: 21.7288638 },
    { lat: 48.7933984, lng: 21.7286608 },
    { lat: 48.7882274, lng: 21.7317187 },
  ],
  Remeniny: [
    { lat: 49.0330069, lng: 21.5344856 },
    { lat: 49.0316406, lng: 21.5368174 },
    { lat: 49.0314754, lng: 21.5373352 },
    { lat: 49.031343, lng: 21.5375891 },
    { lat: 49.0311003, lng: 21.5389098 },
    { lat: 49.0307487, lng: 21.5400855 },
    { lat: 49.030647, lng: 21.5406633 },
    { lat: 49.0306724, lng: 21.5409309 },
    { lat: 49.0305571, lng: 21.5412697 },
    { lat: 49.03021, lng: 21.5418872 },
    { lat: 49.0294093, lng: 21.5430965 },
    { lat: 49.0289114, lng: 21.5439468 },
    { lat: 49.0287871, lng: 21.5442898 },
    { lat: 49.0285166, lng: 21.5444276 },
    { lat: 49.0280782, lng: 21.5450395 },
    { lat: 49.0280195, lng: 21.5453488 },
    { lat: 49.0277765, lng: 21.5455102 },
    { lat: 49.0277696, lng: 21.5457467 },
    { lat: 49.0273378, lng: 21.5467184 },
    { lat: 49.0272008, lng: 21.5480796 },
    { lat: 49.027295, lng: 21.5494201 },
    { lat: 49.0274633, lng: 21.5499645 },
    { lat: 49.0272706, lng: 21.5517513 },
    { lat: 49.0268165, lng: 21.5524133 },
    { lat: 49.0268593, lng: 21.552526 },
    { lat: 49.026482, lng: 21.5531604 },
    { lat: 49.0256923, lng: 21.5537993 },
    { lat: 49.0248544, lng: 21.5552043 },
    { lat: 49.0247834, lng: 21.5554818 },
    { lat: 49.0248417, lng: 21.5556773 },
    { lat: 49.0247628, lng: 21.5557559 },
    { lat: 49.0239702, lng: 21.5572895 },
    { lat: 49.0229281, lng: 21.5594418 },
    { lat: 49.0220529, lng: 21.5608064 },
    { lat: 49.0225281, lng: 21.5615001 },
    { lat: 49.0225879, lng: 21.5633126 },
    { lat: 49.0235079, lng: 21.564541 },
    { lat: 49.0239846, lng: 21.5652994 },
    { lat: 49.0250326, lng: 21.5676814 },
    { lat: 49.025155, lng: 21.5681941 },
    { lat: 49.0256588, lng: 21.5696345 },
    { lat: 49.0266256, lng: 21.5712558 },
    { lat: 49.0272304, lng: 21.5725245 },
    { lat: 49.0276026, lng: 21.5725535 },
    { lat: 49.0278284, lng: 21.5727465 },
    { lat: 49.0310897, lng: 21.5788995 },
    { lat: 49.0320957, lng: 21.5809582 },
    { lat: 49.0328279, lng: 21.5811931 },
    { lat: 49.0329967, lng: 21.5813557 },
    { lat: 49.0334779, lng: 21.5823527 },
    { lat: 49.0337427, lng: 21.5826522 },
    { lat: 49.0346272, lng: 21.5843051 },
    { lat: 49.0352358, lng: 21.5855965 },
    { lat: 49.0354268, lng: 21.585833 },
    { lat: 49.0355565, lng: 21.5861657 },
    { lat: 49.0358889, lng: 21.5866728 },
    { lat: 49.0361375, lng: 21.5872359 },
    { lat: 49.0365145, lng: 21.5883296 },
    { lat: 49.0365766, lng: 21.5886193 },
    { lat: 49.0367686, lng: 21.5882007 },
    { lat: 49.0370364, lng: 21.5883602 },
    { lat: 49.0379784, lng: 21.5886197 },
    { lat: 49.0382235, lng: 21.5888137 },
    { lat: 49.0384974, lng: 21.5893178 },
    { lat: 49.0387241, lng: 21.5892982 },
    { lat: 49.0397273, lng: 21.5888775 },
    { lat: 49.0409219, lng: 21.5886761 },
    { lat: 49.0412642, lng: 21.5887255 },
    { lat: 49.0421275, lng: 21.5885741 },
    { lat: 49.0428887, lng: 21.5879556 },
    { lat: 49.0436181, lng: 21.5876077 },
    { lat: 49.0440745, lng: 21.5879538 },
    { lat: 49.0444259, lng: 21.5875679 },
    { lat: 49.0449522, lng: 21.5874371 },
    { lat: 49.0452836, lng: 21.5868509 },
    { lat: 49.0454611, lng: 21.5867208 },
    { lat: 49.0454769, lng: 21.5865193 },
    { lat: 49.0461473, lng: 21.5850192 },
    { lat: 49.0461966, lng: 21.5848159 },
    { lat: 49.0464741, lng: 21.58468 },
    { lat: 49.0464835, lng: 21.5843681 },
    { lat: 49.0468971, lng: 21.5840323 },
    { lat: 49.0469966, lng: 21.5835657 },
    { lat: 49.0472253, lng: 21.5831701 },
    { lat: 49.0477301, lng: 21.5829933 },
    { lat: 49.0480409, lng: 21.5825253 },
    { lat: 49.0482922, lng: 21.5822189 },
    { lat: 49.048552, lng: 21.5820512 },
    { lat: 49.0486857, lng: 21.5818058 },
    { lat: 49.0489595, lng: 21.5819029 },
    { lat: 49.0494996, lng: 21.5827041 },
    { lat: 49.0497452, lng: 21.5829651 },
    { lat: 49.0502486, lng: 21.5832623 },
    { lat: 49.0508839, lng: 21.5819587 },
    { lat: 49.051472, lng: 21.5814251 },
    { lat: 49.0514009, lng: 21.5809695 },
    { lat: 49.0514497, lng: 21.5805822 },
    { lat: 49.051742, lng: 21.5804948 },
    { lat: 49.0518239, lng: 21.5798055 },
    { lat: 49.0519823, lng: 21.5793914 },
    { lat: 49.0522376, lng: 21.5782611 },
    { lat: 49.0525276, lng: 21.5774845 },
    { lat: 49.0526394, lng: 21.5770221 },
    { lat: 49.0524627, lng: 21.5767706 },
    { lat: 49.0519405, lng: 21.5762398 },
    { lat: 49.0520299, lng: 21.5759377 },
    { lat: 49.0523502, lng: 21.5754457 },
    { lat: 49.0532168, lng: 21.5750141 },
    { lat: 49.0533487, lng: 21.574857 },
    { lat: 49.0541499, lng: 21.5743475 },
    { lat: 49.0543971, lng: 21.574089 },
    { lat: 49.054469, lng: 21.5738899 },
    { lat: 49.0554021, lng: 21.5730547 },
    { lat: 49.0561262, lng: 21.5712691 },
    { lat: 49.0562696, lng: 21.5710855 },
    { lat: 49.0564213, lng: 21.5706434 },
    { lat: 49.0565448, lng: 21.5699865 },
    { lat: 49.0567311, lng: 21.5698521 },
    { lat: 49.0570324, lng: 21.5689196 },
    { lat: 49.0570307, lng: 21.5686571 },
    { lat: 49.0570858, lng: 21.5684932 },
    { lat: 49.0577639, lng: 21.5678398 },
    { lat: 49.0578686, lng: 21.5673254 },
    { lat: 49.0579219, lng: 21.5667382 },
    { lat: 49.0576808, lng: 21.5659148 },
    { lat: 49.0576465, lng: 21.5653409 },
    { lat: 49.0578171, lng: 21.5646038 },
    { lat: 49.0583885, lng: 21.5641986 },
    { lat: 49.0588578, lng: 21.5641606 },
    { lat: 49.0589733, lng: 21.5642711 },
    { lat: 49.0591606, lng: 21.5649722 },
    { lat: 49.0592351, lng: 21.5650994 },
    { lat: 49.0596068, lng: 21.5651556 },
    { lat: 49.059726, lng: 21.5655966 },
    { lat: 49.0607287, lng: 21.565608 },
    { lat: 49.0613087, lng: 21.5653834 },
    { lat: 49.0620944, lng: 21.5646741 },
    { lat: 49.0634077, lng: 21.5637289 },
    { lat: 49.0635822, lng: 21.5632935 },
    { lat: 49.0644885, lng: 21.5626305 },
    { lat: 49.0655068, lng: 21.5614718 },
    { lat: 49.065858, lng: 21.5609176 },
    { lat: 49.0662692, lng: 21.5607 },
    { lat: 49.0671752, lng: 21.5598998 },
    { lat: 49.06751, lng: 21.5593298 },
    { lat: 49.0680409, lng: 21.5589623 },
    { lat: 49.0680621, lng: 21.5586337 },
    { lat: 49.0675916, lng: 21.5586704 },
    { lat: 49.0669766, lng: 21.5584449 },
    { lat: 49.0664946, lng: 21.5586682 },
    { lat: 49.0660161, lng: 21.5586129 },
    { lat: 49.0659679, lng: 21.5584983 },
    { lat: 49.065576, lng: 21.5584879 },
    { lat: 49.0652111, lng: 21.5583534 },
    { lat: 49.0650551, lng: 21.5584407 },
    { lat: 49.0648247, lng: 21.5582964 },
    { lat: 49.0650449, lng: 21.5578422 },
    { lat: 49.0652481, lng: 21.5577404 },
    { lat: 49.0652615, lng: 21.5575407 },
    { lat: 49.0653621, lng: 21.5573182 },
    { lat: 49.0653521, lng: 21.5572055 },
    { lat: 49.0654379, lng: 21.5568533 },
    { lat: 49.065449, lng: 21.5564088 },
    { lat: 49.0655076, lng: 21.5562557 },
    { lat: 49.0655019, lng: 21.5560515 },
    { lat: 49.0653634, lng: 21.5558471 },
    { lat: 49.0647492, lng: 21.5556079 },
    { lat: 49.0637242, lng: 21.5554016 },
    { lat: 49.0634231, lng: 21.5548967 },
    { lat: 49.0627283, lng: 21.5543948 },
    { lat: 49.0612958, lng: 21.5536303 },
    { lat: 49.0599688, lng: 21.5527248 },
    { lat: 49.0598192, lng: 21.5521698 },
    { lat: 49.0593142, lng: 21.5508108 },
    { lat: 49.0585524, lng: 21.5501319 },
    { lat: 49.058177, lng: 21.5487795 },
    { lat: 49.057726, lng: 21.5489054 },
    { lat: 49.0573875, lng: 21.5488335 },
    { lat: 49.057269, lng: 21.5490615 },
    { lat: 49.0572683, lng: 21.5492793 },
    { lat: 49.0571479, lng: 21.5496742 },
    { lat: 49.0569469, lng: 21.5498032 },
    { lat: 49.0567042, lng: 21.5501333 },
    { lat: 49.0564747, lng: 21.5495014 },
    { lat: 49.0561537, lng: 21.5489308 },
    { lat: 49.0557341, lng: 21.5484876 },
    { lat: 49.055494, lng: 21.5480731 },
    { lat: 49.0552115, lng: 21.5473106 },
    { lat: 49.0551963, lng: 21.5470892 },
    { lat: 49.0552725, lng: 21.5468077 },
    { lat: 49.055091, lng: 21.5466742 },
    { lat: 49.0549775, lng: 21.5462347 },
    { lat: 49.0549207, lng: 21.5461778 },
    { lat: 49.052024, lng: 21.5480272 },
    { lat: 49.05091, lng: 21.5484205 },
    { lat: 49.0500429, lng: 21.5489057 },
    { lat: 49.0485274, lng: 21.5495906 },
    { lat: 49.0470227, lng: 21.5494509 },
    { lat: 49.0463215, lng: 21.5482044 },
    { lat: 49.0461385, lng: 21.5477343 },
    { lat: 49.0456484, lng: 21.5471896 },
    { lat: 49.0452615, lng: 21.5466363 },
    { lat: 49.043947, lng: 21.5445842 },
    { lat: 49.0434196, lng: 21.5440004 },
    { lat: 49.0423694, lng: 21.5421894 },
    { lat: 49.0420823, lng: 21.542343 },
    { lat: 49.0416608, lng: 21.541436 },
    { lat: 49.0412974, lng: 21.5417133 },
    { lat: 49.0412124, lng: 21.541376 },
    { lat: 49.0409297, lng: 21.540651 },
    { lat: 49.0400325, lng: 21.5410694 },
    { lat: 49.0399309, lng: 21.5406941 },
    { lat: 49.0393124, lng: 21.5409515 },
    { lat: 49.0390532, lng: 21.5402957 },
    { lat: 49.0390965, lng: 21.540234 },
    { lat: 49.0389184, lng: 21.5401069 },
    { lat: 49.037662, lng: 21.5394804 },
    { lat: 49.0375069, lng: 21.5395228 },
    { lat: 49.0372941, lng: 21.5394414 },
    { lat: 49.0370365, lng: 21.5394349 },
    { lat: 49.0369964, lng: 21.539534 },
    { lat: 49.036898, lng: 21.5395892 },
    { lat: 49.0364434, lng: 21.5388397 },
    { lat: 49.0356653, lng: 21.5380935 },
    { lat: 49.0347852, lng: 21.5368089 },
    { lat: 49.0343347, lng: 21.5362761 },
    { lat: 49.0336976, lng: 21.5353149 },
    { lat: 49.0332327, lng: 21.5347519 },
    { lat: 49.0330069, lng: 21.5344856 },
  ],
  Soľ: [
    { lat: 48.9087339, lng: 21.5717542 },
    { lat: 48.9084421, lng: 21.571739 },
    { lat: 48.9082076, lng: 21.5717281 },
    { lat: 48.9080691, lng: 21.5715961 },
    { lat: 48.9079444, lng: 21.5697243 },
    { lat: 48.9064724, lng: 21.5701166 },
    { lat: 48.9046056, lng: 21.5703842 },
    { lat: 48.9044711, lng: 21.5710838 },
    { lat: 48.9043024, lng: 21.5714509 },
    { lat: 48.9041854, lng: 21.5720447 },
    { lat: 48.9039256, lng: 21.5725746 },
    { lat: 48.90352, lng: 21.5735958 },
    { lat: 48.9034865, lng: 21.5737653 },
    { lat: 48.9036421, lng: 21.5747809 },
    { lat: 48.9036064, lng: 21.5753176 },
    { lat: 48.9032979, lng: 21.5761436 },
    { lat: 48.9032196, lng: 21.5768081 },
    { lat: 48.903083, lng: 21.5773927 },
    { lat: 48.9028616, lng: 21.5776791 },
    { lat: 48.902777, lng: 21.577873 },
    { lat: 48.9026165, lng: 21.577692 },
    { lat: 48.9023101, lng: 21.577636 },
    { lat: 48.9017911, lng: 21.5778611 },
    { lat: 48.9011357, lng: 21.5779634 },
    { lat: 48.9010109, lng: 21.5857685 },
    { lat: 48.9009775, lng: 21.5860246 },
    { lat: 48.9008908, lng: 21.586263 },
    { lat: 48.9007187, lng: 21.5865018 },
    { lat: 48.9007803, lng: 21.5864939 },
    { lat: 48.9009824, lng: 21.5864817 },
    { lat: 48.9011678, lng: 21.5875211 },
    { lat: 48.9012331, lng: 21.587522 },
    { lat: 48.9015988, lng: 21.587158 },
    { lat: 48.9016875, lng: 21.5878786 },
    { lat: 48.9023534, lng: 21.5880357 },
    { lat: 48.9025154, lng: 21.5889995 },
    { lat: 48.9039593, lng: 21.5888474 },
    { lat: 48.9047408, lng: 21.5884739 },
    { lat: 48.9050669, lng: 21.5898359 },
    { lat: 48.9051309, lng: 21.5902796 },
    { lat: 48.9051363, lng: 21.5909119 },
    { lat: 48.9050832, lng: 21.5911049 },
    { lat: 48.9049495, lng: 21.5911989 },
    { lat: 48.904841, lng: 21.5916615 },
    { lat: 48.9048582, lng: 21.5919548 },
    { lat: 48.9049328, lng: 21.5921696 },
    { lat: 48.9049411, lng: 21.5926293 },
    { lat: 48.9050117, lng: 21.5927775 },
    { lat: 48.9051959, lng: 21.5929316 },
    { lat: 48.9051793, lng: 21.593265 },
    { lat: 48.9053377, lng: 21.593983 },
    { lat: 48.9052603, lng: 21.5941499 },
    { lat: 48.9053202, lng: 21.5943217 },
    { lat: 48.9053371, lng: 21.5946461 },
    { lat: 48.9056828, lng: 21.5961063 },
    { lat: 48.9056597, lng: 21.5966911 },
    { lat: 48.9058808, lng: 21.5974842 },
    { lat: 48.9061006, lng: 21.5978606 },
    { lat: 48.9061484, lng: 21.5982474 },
    { lat: 48.9066049, lng: 21.5988416 },
    { lat: 48.9067887, lng: 21.5996266 },
    { lat: 48.9069119, lng: 21.5998849 },
    { lat: 48.9068502, lng: 21.6000912 },
    { lat: 48.9070878, lng: 21.6003446 },
    { lat: 48.9072196, lng: 21.6007936 },
    { lat: 48.9072322, lng: 21.6014498 },
    { lat: 48.9073926, lng: 21.6018015 },
    { lat: 48.9074967, lng: 21.6022194 },
    { lat: 48.9076041, lng: 21.6024014 },
    { lat: 48.9076893, lng: 21.6027214 },
    { lat: 48.9081115, lng: 21.6034562 },
    { lat: 48.9083617, lng: 21.6046466 },
    { lat: 48.9084614, lng: 21.6048309 },
    { lat: 48.9084866, lng: 21.6051272 },
    { lat: 48.9086264, lng: 21.6053489 },
    { lat: 48.908691, lng: 21.6056027 },
    { lat: 48.9088741, lng: 21.6058364 },
    { lat: 48.9088636, lng: 21.6063528 },
    { lat: 48.9087443, lng: 21.6067163 },
    { lat: 48.9088275, lng: 21.6070815 },
    { lat: 48.9088185, lng: 21.6074455 },
    { lat: 48.9089956, lng: 21.6077446 },
    { lat: 48.9093183, lng: 21.6098396 },
    { lat: 48.9094126, lng: 21.6100006 },
    { lat: 48.9096598, lng: 21.6112456 },
    { lat: 48.9098509, lng: 21.6115224 },
    { lat: 48.910039, lng: 21.612748 },
    { lat: 48.9103335, lng: 21.6130229 },
    { lat: 48.910444, lng: 21.6138438 },
    { lat: 48.9104176, lng: 21.6141077 },
    { lat: 48.9105945, lng: 21.6146465 },
    { lat: 48.910463, lng: 21.6149711 },
    { lat: 48.9105243, lng: 21.6153206 },
    { lat: 48.9106912, lng: 21.6155002 },
    { lat: 48.9109522, lng: 21.6165552 },
    { lat: 48.911105, lng: 21.6164224 },
    { lat: 48.9113257, lng: 21.6168758 },
    { lat: 48.9112756, lng: 21.6170561 },
    { lat: 48.9117096, lng: 21.6174133 },
    { lat: 48.9118751, lng: 21.6177047 },
    { lat: 48.9122806, lng: 21.6181735 },
    { lat: 48.9123085, lng: 21.618122 },
    { lat: 48.9124204, lng: 21.6181803 },
    { lat: 48.913388, lng: 21.6189479 },
    { lat: 48.9143911, lng: 21.621379 },
    { lat: 48.9146015, lng: 21.6217841 },
    { lat: 48.914955, lng: 21.6222399 },
    { lat: 48.9150154, lng: 21.6220704 },
    { lat: 48.9150388, lng: 21.6221394 },
    { lat: 48.9159763, lng: 21.6226807 },
    { lat: 48.9161899, lng: 21.623203 },
    { lat: 48.9156644, lng: 21.6245857 },
    { lat: 48.9164007, lng: 21.6272107 },
    { lat: 48.9151865, lng: 21.6290799 },
    { lat: 48.9156054, lng: 21.6305696 },
    { lat: 48.9158117, lng: 21.6310319 },
    { lat: 48.9161678, lng: 21.6307611 },
    { lat: 48.9166849, lng: 21.6303795 },
    { lat: 48.9176393, lng: 21.6293721 },
    { lat: 48.9178008, lng: 21.6293121 },
    { lat: 48.9181596, lng: 21.6294721 },
    { lat: 48.9187135, lng: 21.6301016 },
    { lat: 48.9198155, lng: 21.6307293 },
    { lat: 48.920393, lng: 21.6307424 },
    { lat: 48.9210313, lng: 21.6303531 },
    { lat: 48.9212413, lng: 21.6302567 },
    { lat: 48.9214136, lng: 21.6302623 },
    { lat: 48.9220945, lng: 21.6295712 },
    { lat: 48.9223917, lng: 21.6289709 },
    { lat: 48.9224928, lng: 21.6285989 },
    { lat: 48.9224814, lng: 21.6274534 },
    { lat: 48.9226029, lng: 21.62708 },
    { lat: 48.9228144, lng: 21.6266681 },
    { lat: 48.9230679, lng: 21.6263196 },
    { lat: 48.9234642, lng: 21.6259624 },
    { lat: 48.9248238, lng: 21.6238156 },
    { lat: 48.9252154, lng: 21.6225947 },
    { lat: 48.9256241, lng: 21.6220037 },
    { lat: 48.9261863, lng: 21.6217855 },
    { lat: 48.9269852, lng: 21.6217511 },
    { lat: 48.9273176, lng: 21.621647 },
    { lat: 48.9278935, lng: 21.6212282 },
    { lat: 48.9282546, lng: 21.6211054 },
    { lat: 48.9288131, lng: 21.6211196 },
    { lat: 48.9292664, lng: 21.621041 },
    { lat: 48.9299782, lng: 21.62078 },
    { lat: 48.9301048, lng: 21.6205554 },
    { lat: 48.9300385, lng: 21.6203607 },
    { lat: 48.9293457, lng: 21.6195074 },
    { lat: 48.9294011, lng: 21.6189554 },
    { lat: 48.9296332, lng: 21.6185585 },
    { lat: 48.9306669, lng: 21.617446 },
    { lat: 48.930818, lng: 21.6170543 },
    { lat: 48.9310043, lng: 21.616262 },
    { lat: 48.9310083, lng: 21.6162446 },
    { lat: 48.9312169, lng: 21.6153671 },
    { lat: 48.9314486, lng: 21.6149205 },
    { lat: 48.9324024, lng: 21.6142614 },
    { lat: 48.932617, lng: 21.6139653 },
    { lat: 48.9327325, lng: 21.6133432 },
    { lat: 48.9328222, lng: 21.6125563 },
    { lat: 48.9328135, lng: 21.6111445 },
    { lat: 48.9327082, lng: 21.6091739 },
    { lat: 48.9325927, lng: 21.6083006 },
    { lat: 48.932111, lng: 21.6071177 },
    { lat: 48.932187, lng: 21.6060844 },
    { lat: 48.9326134, lng: 21.6053515 },
    { lat: 48.9327271, lng: 21.6053178 },
    { lat: 48.9331863, lng: 21.6053596 },
    { lat: 48.9343162, lng: 21.6056953 },
    { lat: 48.9344839, lng: 21.6056624 },
    { lat: 48.9348576, lng: 21.6057845 },
    { lat: 48.9354995, lng: 21.6056464 },
    { lat: 48.9358061, lng: 21.605464 },
    { lat: 48.9361169, lng: 21.605107 },
    { lat: 48.936346, lng: 21.6046884 },
    { lat: 48.9366289, lng: 21.6036972 },
    { lat: 48.9366781, lng: 21.6033631 },
    { lat: 48.9366713, lng: 21.6031023 },
    { lat: 48.9366293, lng: 21.6014778 },
    { lat: 48.9367228, lng: 21.5983713 },
    { lat: 48.9366655, lng: 21.5972022 },
    { lat: 48.9363882, lng: 21.5969802 },
    { lat: 48.9357768, lng: 21.5970048 },
    { lat: 48.9354423, lng: 21.596911 },
    { lat: 48.935252, lng: 21.5966985 },
    { lat: 48.9351102, lng: 21.5960931 },
    { lat: 48.9352666, lng: 21.5936762 },
    { lat: 48.9353595, lng: 21.5934884 },
    { lat: 48.9356115, lng: 21.5932078 },
    { lat: 48.9357647, lng: 21.593175 },
    { lat: 48.937537, lng: 21.5935079 },
    { lat: 48.9380601, lng: 21.5937513 },
    { lat: 48.9386826, lng: 21.5942031 },
    { lat: 48.9393021, lng: 21.5945552 },
    { lat: 48.9398771, lng: 21.5946801 },
    { lat: 48.9403275, lng: 21.5949314 },
    { lat: 48.9407313, lng: 21.5952927 },
    { lat: 48.9408939, lng: 21.5958395 },
    { lat: 48.9414395, lng: 21.5966474 },
    { lat: 48.9417671, lng: 21.5974646 },
    { lat: 48.942173, lng: 21.5978931 },
    { lat: 48.9425909, lng: 21.5978305 },
    { lat: 48.9430439, lng: 21.5973755 },
    { lat: 48.9435443, lng: 21.5969984 },
    { lat: 48.9434942, lng: 21.5967754 },
    { lat: 48.9433879, lng: 21.5956255 },
    { lat: 48.9443953, lng: 21.5954381 },
    { lat: 48.9438754, lng: 21.5930151 },
    { lat: 48.9429393, lng: 21.5933726 },
    { lat: 48.9427327, lng: 21.5925842 },
    { lat: 48.9416839, lng: 21.592936 },
    { lat: 48.9413977, lng: 21.5921996 },
    { lat: 48.9411439, lng: 21.5917739 },
    { lat: 48.9409406, lng: 21.5912956 },
    { lat: 48.9406568, lng: 21.5914079 },
    { lat: 48.9398751, lng: 21.5914957 },
    { lat: 48.9391501, lng: 21.5904271 },
    { lat: 48.9390668, lng: 21.5905105 },
    { lat: 48.9388587, lng: 21.5896668 },
    { lat: 48.9379199, lng: 21.5849294 },
    { lat: 48.937455, lng: 21.5831489 },
    { lat: 48.9367313, lng: 21.583509 },
    { lat: 48.9364391, lng: 21.583759 },
    { lat: 48.93565, lng: 21.5846268 },
    { lat: 48.9355186, lng: 21.5845135 },
    { lat: 48.9352189, lng: 21.5840551 },
    { lat: 48.9349684, lng: 21.5831498 },
    { lat: 48.9350826, lng: 21.5830082 },
    { lat: 48.9347403, lng: 21.5821084 },
    { lat: 48.9345357, lng: 21.5813508 },
    { lat: 48.9342574, lng: 21.5807321 },
    { lat: 48.934278, lng: 21.5798247 },
    { lat: 48.9341915, lng: 21.5798682 },
    { lat: 48.9338668, lng: 21.5800019 },
    { lat: 48.9333286, lng: 21.5803461 },
    { lat: 48.9325246, lng: 21.5810119 },
    { lat: 48.9307598, lng: 21.5821544 },
    { lat: 48.9296746, lng: 21.5830154 },
    { lat: 48.9277597, lng: 21.5841423 },
    { lat: 48.9270263, lng: 21.5843892 },
    { lat: 48.9251243, lng: 21.5845316 },
    { lat: 48.9250551, lng: 21.5846338 },
    { lat: 48.9248903, lng: 21.5840433 },
    { lat: 48.9248069, lng: 21.5839624 },
    { lat: 48.9242347, lng: 21.5839778 },
    { lat: 48.9239384, lng: 21.5838892 },
    { lat: 48.9234404, lng: 21.5834568 },
    { lat: 48.9233839, lng: 21.5833224 },
    { lat: 48.9233364, lng: 21.5829558 },
    { lat: 48.9232754, lng: 21.5829848 },
    { lat: 48.9232588, lng: 21.5829147 },
    { lat: 48.9231669, lng: 21.5828909 },
    { lat: 48.9230879, lng: 21.5829232 },
    { lat: 48.9229931, lng: 21.5827121 },
    { lat: 48.9226496, lng: 21.5826201 },
    { lat: 48.9224822, lng: 21.5827443 },
    { lat: 48.9222371, lng: 21.5825869 },
    { lat: 48.9221046, lng: 21.5827987 },
    { lat: 48.9219918, lng: 21.5828299 },
    { lat: 48.9219588, lng: 21.5826943 },
    { lat: 48.9218352, lng: 21.5825827 },
    { lat: 48.921459, lng: 21.5824828 },
    { lat: 48.9213591, lng: 21.5822914 },
    { lat: 48.9212311, lng: 21.5822277 },
    { lat: 48.9210834, lng: 21.5822562 },
    { lat: 48.9210508, lng: 21.5821363 },
    { lat: 48.9211405, lng: 21.5820393 },
    { lat: 48.9210576, lng: 21.581889 },
    { lat: 48.9207541, lng: 21.5819884 },
    { lat: 48.9203961, lng: 21.5819771 },
    { lat: 48.9202272, lng: 21.5816534 },
    { lat: 48.9200806, lng: 21.581796 },
    { lat: 48.919624, lng: 21.5816469 },
    { lat: 48.9194534, lng: 21.5818213 },
    { lat: 48.9193558, lng: 21.5816677 },
    { lat: 48.9190942, lng: 21.5816881 },
    { lat: 48.9187711, lng: 21.5815573 },
    { lat: 48.9184363, lng: 21.5816167 },
    { lat: 48.9184604, lng: 21.581315 },
    { lat: 48.9184092, lng: 21.5812536 },
    { lat: 48.9182376, lng: 21.5813916 },
    { lat: 48.9182146, lng: 21.5815788 },
    { lat: 48.9180203, lng: 21.5815451 },
    { lat: 48.9178291, lng: 21.5813925 },
    { lat: 48.9175764, lng: 21.5814035 },
    { lat: 48.9174712, lng: 21.5813426 },
    { lat: 48.917065, lng: 21.5815898 },
    { lat: 48.9169113, lng: 21.5815976 },
    { lat: 48.9168482, lng: 21.5815151 },
    { lat: 48.9167443, lng: 21.5815018 },
    { lat: 48.9166659, lng: 21.5816399 },
    { lat: 48.9162336, lng: 21.5818698 },
    { lat: 48.9156264, lng: 21.5820434 },
    { lat: 48.9153833, lng: 21.5822047 },
    { lat: 48.9151862, lng: 21.5821941 },
    { lat: 48.9150973, lng: 21.5821104 },
    { lat: 48.9147254, lng: 21.5820041 },
    { lat: 48.9145254, lng: 21.5818593 },
    { lat: 48.9136991, lng: 21.5810339 },
    { lat: 48.9135235, lng: 21.5808353 },
    { lat: 48.913385, lng: 21.5805434 },
    { lat: 48.9131351, lng: 21.5803409 },
    { lat: 48.9129391, lng: 21.5799279 },
    { lat: 48.9128702, lng: 21.5795213 },
    { lat: 48.9124852, lng: 21.5788297 },
    { lat: 48.9124085, lng: 21.5785413 },
    { lat: 48.9120841, lng: 21.5781612 },
    { lat: 48.9118734, lng: 21.5781895 },
    { lat: 48.9117023, lng: 21.5783543 },
    { lat: 48.9115288, lng: 21.5783451 },
    { lat: 48.9112173, lng: 21.577863 },
    { lat: 48.9111207, lng: 21.5778739 },
    { lat: 48.910915, lng: 21.5777601 },
    { lat: 48.9108781, lng: 21.5775293 },
    { lat: 48.9105731, lng: 21.5774814 },
    { lat: 48.9104835, lng: 21.5772954 },
    { lat: 48.9101454, lng: 21.5772779 },
    { lat: 48.9098762, lng: 21.577145 },
    { lat: 48.9098104, lng: 21.5766045 },
    { lat: 48.9094756, lng: 21.5755366 },
    { lat: 48.9093835, lng: 21.5748605 },
    { lat: 48.9089556, lng: 21.5749249 },
    { lat: 48.9087531, lng: 21.5736822 },
    { lat: 48.9088681, lng: 21.5736565 },
    { lat: 48.9088145, lng: 21.5733853 },
    { lat: 48.9088195, lng: 21.5724457 },
    { lat: 48.9087339, lng: 21.5717542 },
  ],
  Jasenovce: [
    { lat: 49.003511, lng: 21.774774 },
    { lat: 49.0036028, lng: 21.7746755 },
    { lat: 49.0036347, lng: 21.7741935 },
    { lat: 49.003661, lng: 21.773923 },
    { lat: 49.003998, lng: 21.773044 },
    { lat: 49.004081, lng: 21.77273 },
    { lat: 49.004139, lng: 21.772369 },
    { lat: 49.004423, lng: 21.772055 },
    { lat: 49.004532, lng: 21.771847 },
    { lat: 49.004699, lng: 21.771439 },
    { lat: 49.004782, lng: 21.771327 },
    { lat: 49.004751, lng: 21.771004 },
    { lat: 49.004805, lng: 21.770446 },
    { lat: 49.005084, lng: 21.769761 },
    { lat: 49.005945, lng: 21.767374 },
    { lat: 49.005932, lng: 21.766882 },
    { lat: 49.00585, lng: 21.766419 },
    { lat: 49.006024, lng: 21.766142 },
    { lat: 49.006283, lng: 21.765558 },
    { lat: 49.006394, lng: 21.765358 },
    { lat: 49.006549, lng: 21.76526 },
    { lat: 49.006672, lng: 21.765127 },
    { lat: 49.006641, lng: 21.76465 },
    { lat: 49.007187, lng: 21.7645 },
    { lat: 49.007527, lng: 21.764594 },
    { lat: 49.007815, lng: 21.764523 },
    { lat: 49.007953, lng: 21.764538 },
    { lat: 49.008188, lng: 21.764635 },
    { lat: 49.008405, lng: 21.764585 },
    { lat: 49.00886, lng: 21.764588 },
    { lat: 49.008905, lng: 21.764609 },
    { lat: 49.009016, lng: 21.764607 },
    { lat: 49.009793, lng: 21.764519 },
    { lat: 49.009985, lng: 21.764602 },
    { lat: 49.010553, lng: 21.764418 },
    { lat: 49.011168, lng: 21.764122 },
    { lat: 49.0114561, lng: 21.7639655 },
    { lat: 49.0114276, lng: 21.7638213 },
    { lat: 49.0111222, lng: 21.7635052 },
    { lat: 49.0110994, lng: 21.7632719 },
    { lat: 49.0109819, lng: 21.7631723 },
    { lat: 49.0109093, lng: 21.7623854 },
    { lat: 49.0109413, lng: 21.7622312 },
    { lat: 49.0112841, lng: 21.761704 },
    { lat: 49.0116001, lng: 21.7608042 },
    { lat: 49.0115919, lng: 21.7604069 },
    { lat: 49.0117272, lng: 21.7601195 },
    { lat: 49.011619, lng: 21.7599412 },
    { lat: 49.0117239, lng: 21.7597431 },
    { lat: 49.0120223, lng: 21.7594916 },
    { lat: 49.0122209, lng: 21.7590953 },
    { lat: 49.0133118, lng: 21.7586536 },
    { lat: 49.013391, lng: 21.7575184 },
    { lat: 49.013359, lng: 21.7568147 },
    { lat: 49.0134195, lng: 21.7560015 },
    { lat: 49.0130272, lng: 21.755838 },
    { lat: 49.0126777, lng: 21.7552609 },
    { lat: 49.0123679, lng: 21.7549487 },
    { lat: 49.0121484, lng: 21.7549022 },
    { lat: 49.0121868, lng: 21.7551597 },
    { lat: 49.0120081, lng: 21.7553817 },
    { lat: 49.0113912, lng: 21.7551051 },
    { lat: 49.0112611, lng: 21.7548672 },
    { lat: 49.0112484, lng: 21.7546794 },
    { lat: 49.0110699, lng: 21.7544474 },
    { lat: 49.0107529, lng: 21.7544022 },
    { lat: 49.0101904, lng: 21.7540291 },
    { lat: 49.0100076, lng: 21.75404 },
    { lat: 49.0100744, lng: 21.7536775 },
    { lat: 49.0099633, lng: 21.753345 },
    { lat: 49.0097002, lng: 21.7531862 },
    { lat: 49.0094775, lng: 21.7538652 },
    { lat: 49.0089976, lng: 21.7539673 },
    { lat: 49.0089076, lng: 21.7539247 },
    { lat: 49.00885, lng: 21.7537079 },
    { lat: 49.008868, lng: 21.7534964 },
    { lat: 49.0087579, lng: 21.7531574 },
    { lat: 49.0087901, lng: 21.7528848 },
    { lat: 49.008625, lng: 21.7526853 },
    { lat: 49.0084927, lng: 21.7527475 },
    { lat: 49.0081804, lng: 21.7532733 },
    { lat: 49.0078738, lng: 21.7525649 },
    { lat: 49.0077892, lng: 21.7522602 },
    { lat: 49.0077987, lng: 21.7518973 },
    { lat: 49.007741, lng: 21.7518815 },
    { lat: 49.0076591, lng: 21.7514267 },
    { lat: 49.0075459, lng: 21.7513516 },
    { lat: 49.0071535, lng: 21.750856 },
    { lat: 49.0070602, lng: 21.7505543 },
    { lat: 49.0070362, lng: 21.7500326 },
    { lat: 49.0070279, lng: 21.7479379 },
    { lat: 49.0071195, lng: 21.7473199 },
    { lat: 49.0072505, lng: 21.7469828 },
    { lat: 49.0073786, lng: 21.746653 },
    { lat: 49.0075394, lng: 21.746431 },
    { lat: 49.0079454, lng: 21.7462198 },
    { lat: 49.0082109, lng: 21.7461781 },
    { lat: 49.0089371, lng: 21.7463257 },
    { lat: 49.00892, lng: 21.7458501 },
    { lat: 49.0094024, lng: 21.7457181 },
    { lat: 49.0095262, lng: 21.7458463 },
    { lat: 49.0098433, lng: 21.74567 },
    { lat: 49.0103319, lng: 21.7451788 },
    { lat: 49.0106449, lng: 21.7446314 },
    { lat: 49.0108567, lng: 21.7444314 },
    { lat: 49.0109178, lng: 21.7446115 },
    { lat: 49.0112135, lng: 21.7442691 },
    { lat: 49.0113077, lng: 21.7440063 },
    { lat: 49.0116211, lng: 21.7436897 },
    { lat: 49.0116274, lng: 21.7434582 },
    { lat: 49.0112031, lng: 21.7427746 },
    { lat: 49.0111115, lng: 21.7427591 },
    { lat: 49.0107586, lng: 21.742981 },
    { lat: 49.0104989, lng: 21.7430292 },
    { lat: 49.0104958, lng: 21.7424507 },
    { lat: 49.0103454, lng: 21.7424588 },
    { lat: 49.0102945, lng: 21.7423634 },
    { lat: 49.0102157, lng: 21.7424058 },
    { lat: 49.0101797, lng: 21.7426567 },
    { lat: 49.0100867, lng: 21.7428466 },
    { lat: 49.0095267, lng: 21.7429734 },
    { lat: 49.0096088, lng: 21.7434471 },
    { lat: 49.0093384, lng: 21.7435992 },
    { lat: 49.0094162, lng: 21.7439004 },
    { lat: 49.0087655, lng: 21.7441392 },
    { lat: 49.0084981, lng: 21.7436482 },
    { lat: 49.0082879, lng: 21.7436052 },
    { lat: 49.0081194, lng: 21.7437058 },
    { lat: 49.0081666, lng: 21.7442956 },
    { lat: 49.008114, lng: 21.7445142 },
    { lat: 49.008004, lng: 21.7445288 },
    { lat: 49.0078483, lng: 21.744256 },
    { lat: 49.0077838, lng: 21.7438134 },
    { lat: 49.007597, lng: 21.7437668 },
    { lat: 49.007211, lng: 21.7438879 },
    { lat: 49.0071879, lng: 21.7436579 },
    { lat: 49.0070884, lng: 21.7434246 },
    { lat: 49.0069021, lng: 21.7436073 },
    { lat: 49.0067217, lng: 21.7440613 },
    { lat: 49.0068042, lng: 21.7442454 },
    { lat: 49.0068874, lng: 21.7447534 },
    { lat: 49.0068542, lng: 21.7451532 },
    { lat: 49.0065167, lng: 21.7452807 },
    { lat: 49.0062828, lng: 21.7452896 },
    { lat: 49.0062291, lng: 21.7453882 },
    { lat: 49.0063077, lng: 21.7458897 },
    { lat: 49.0061178, lng: 21.7461085 },
    { lat: 49.0055328, lng: 21.7464492 },
    { lat: 49.0053745, lng: 21.7461579 },
    { lat: 49.0051871, lng: 21.7461429 },
    { lat: 49.0049175, lng: 21.7463679 },
    { lat: 49.0046557, lng: 21.7462915 },
    { lat: 49.0046099, lng: 21.7461377 },
    { lat: 49.0043626, lng: 21.7461024 },
    { lat: 49.0042685, lng: 21.7459379 },
    { lat: 49.0040237, lng: 21.7461324 },
    { lat: 49.0038546, lng: 21.7461564 },
    { lat: 49.0035978, lng: 21.7463226 },
    { lat: 49.0036198, lng: 21.7464295 },
    { lat: 49.0035722, lng: 21.7465822 },
    { lat: 49.0033603, lng: 21.7470717 },
    { lat: 49.003225, lng: 21.7471885 },
    { lat: 49.0025026, lng: 21.7474871 },
    { lat: 49.0022413, lng: 21.7473856 },
    { lat: 49.0020865, lng: 21.7467648 },
    { lat: 49.0015241, lng: 21.7466441 },
    { lat: 49.001151, lng: 21.7467422 },
    { lat: 49.0010739, lng: 21.7466796 },
    { lat: 49.0009417, lng: 21.7469499 },
    { lat: 49.0008343, lng: 21.7469708 },
    { lat: 49.0007038, lng: 21.7474392 },
    { lat: 49.0007854, lng: 21.7476352 },
    { lat: 49.0007301, lng: 21.748352 },
    { lat: 49.0005846, lng: 21.7483734 },
    { lat: 49.0005414, lng: 21.7482747 },
    { lat: 49.0001378, lng: 21.7480711 },
    { lat: 48.9999223, lng: 21.7476658 },
    { lat: 48.9999546, lng: 21.7471089 },
    { lat: 49.0000861, lng: 21.7465881 },
    { lat: 49.0003204, lng: 21.7463044 },
    { lat: 48.9998072, lng: 21.7459127 },
    { lat: 48.9994476, lng: 21.7463517 },
    { lat: 48.9989254, lng: 21.746297 },
    { lat: 48.9990725, lng: 21.746504 },
    { lat: 48.9988868, lng: 21.746711 },
    { lat: 48.9982207, lng: 21.7461155 },
    { lat: 48.9979284, lng: 21.7455663 },
    { lat: 48.9984802, lng: 21.7449159 },
    { lat: 48.998276, lng: 21.7445042 },
    { lat: 48.9981445, lng: 21.7447279 },
    { lat: 48.9978245, lng: 21.7450449 },
    { lat: 48.9975607, lng: 21.744385 },
    { lat: 48.9975341, lng: 21.7439782 },
    { lat: 48.9974179, lng: 21.7436661 },
    { lat: 48.9971998, lng: 21.7435874 },
    { lat: 48.9967227, lng: 21.7431172 },
    { lat: 48.9970334, lng: 21.7422796 },
    { lat: 48.9963564, lng: 21.7416088 },
    { lat: 48.995448, lng: 21.7417975 },
    { lat: 48.9951703, lng: 21.7412119 },
    { lat: 48.9951543, lng: 21.7409489 },
    { lat: 48.9946939, lng: 21.740938 },
    { lat: 48.9943167, lng: 21.7410543 },
    { lat: 48.9941413, lng: 21.7402963 },
    { lat: 48.9941705, lng: 21.7397002 },
    { lat: 48.9940149, lng: 21.7392648 },
    { lat: 48.9938604, lng: 21.7391668 },
    { lat: 48.9935421, lng: 21.738745 },
    { lat: 48.9934459, lng: 21.7383368 },
    { lat: 48.9930863, lng: 21.7380142 },
    { lat: 48.9928365, lng: 21.7380965 },
    { lat: 48.992674, lng: 21.738042 },
    { lat: 48.9925821, lng: 21.7378395 },
    { lat: 48.9923655, lng: 21.7376017 },
    { lat: 48.9923374, lng: 21.7372248 },
    { lat: 48.992079, lng: 21.7367305 },
    { lat: 48.9924724, lng: 21.7361672 },
    { lat: 48.9921981, lng: 21.735243 },
    { lat: 48.9918394, lng: 21.735547 },
    { lat: 48.9916019, lng: 21.7356207 },
    { lat: 48.9914847, lng: 21.7357873 },
    { lat: 48.9918819, lng: 21.7373144 },
    { lat: 48.9913691, lng: 21.7376991 },
    { lat: 48.9912569, lng: 21.7390221 },
    { lat: 48.99082, lng: 21.7401572 },
    { lat: 48.9908797, lng: 21.7407518 },
    { lat: 48.9907755, lng: 21.7411866 },
    { lat: 48.9905879, lng: 21.7416233 },
    { lat: 48.9905428, lng: 21.7426334 },
    { lat: 48.9901004, lng: 21.7436395 },
    { lat: 48.990034, lng: 21.7436446 },
    { lat: 48.9898823, lng: 21.7439415 },
    { lat: 48.9896143, lng: 21.7442352 },
    { lat: 48.989534, lng: 21.7442577 },
    { lat: 48.9895384, lng: 21.7443427 },
    { lat: 48.9894125, lng: 21.7444675 },
    { lat: 48.9892943, lng: 21.7444633 },
    { lat: 48.9890989, lng: 21.7446963 },
    { lat: 48.9887845, lng: 21.7446271 },
    { lat: 48.9882332, lng: 21.7449223 },
    { lat: 48.9874602, lng: 21.7450929 },
    { lat: 48.9872037, lng: 21.7450455 },
    { lat: 48.9868265, lng: 21.7451152 },
    { lat: 48.9868207, lng: 21.7459988 },
    { lat: 48.9868873, lng: 21.746647 },
    { lat: 48.9866912, lng: 21.7474023 },
    { lat: 48.9865063, lng: 21.7478871 },
    { lat: 48.9864401, lng: 21.748912 },
    { lat: 48.9863526, lng: 21.7493941 },
    { lat: 48.9859289, lng: 21.7498806 },
    { lat: 48.9856837, lng: 21.7512839 },
    { lat: 48.9854598, lng: 21.7518753 },
    { lat: 48.9850021, lng: 21.753537 },
    { lat: 48.9850156, lng: 21.7543055 },
    { lat: 48.9847751, lng: 21.7552496 },
    { lat: 48.9847702, lng: 21.755919 },
    { lat: 48.9848769, lng: 21.7569347 },
    { lat: 48.9852614, lng: 21.7571401 },
    { lat: 48.9853783, lng: 21.7589672 },
    { lat: 48.9853528, lng: 21.7604685 },
    { lat: 48.9854293, lng: 21.7609373 },
    { lat: 48.9856204, lng: 21.7616137 },
    { lat: 48.9859381, lng: 21.7639347 },
    { lat: 48.9861112, lng: 21.7647867 },
    { lat: 48.9864899, lng: 21.7659617 },
    { lat: 48.9868354, lng: 21.7675511 },
    { lat: 48.9869053, lng: 21.7682389 },
    { lat: 48.9873182, lng: 21.7685379 },
    { lat: 48.988201, lng: 21.7694693 },
    { lat: 48.9887568, lng: 21.7703282 },
    { lat: 48.9889102, lng: 21.7706821 },
    { lat: 48.989804, lng: 21.7716941 },
    { lat: 48.9900512, lng: 21.7720656 },
    { lat: 48.9900204, lng: 21.7729032 },
    { lat: 48.9900466, lng: 21.7730925 },
    { lat: 48.9902608, lng: 21.7734474 },
    { lat: 48.9905446, lng: 21.7735924 },
    { lat: 48.9913029, lng: 21.7736793 },
    { lat: 48.9915727, lng: 21.7738016 },
    { lat: 48.991828, lng: 21.774448 },
    { lat: 48.993518, lng: 21.775424 },
    { lat: 48.994428, lng: 21.775551 },
    { lat: 48.995535, lng: 21.776031 },
    { lat: 48.996063, lng: 21.776273 },
    { lat: 48.997397, lng: 21.77668 },
    { lat: 48.997432, lng: 21.776697 },
    { lat: 48.99763, lng: 21.776778 },
    { lat: 48.998448, lng: 21.777459 },
    { lat: 48.999694, lng: 21.778063 },
    { lat: 48.999747, lng: 21.777968 },
    { lat: 48.999978, lng: 21.777575 },
    { lat: 49.000303, lng: 21.776999 },
    { lat: 49.000695, lng: 21.776318 },
    { lat: 49.000849, lng: 21.77605 },
    { lat: 49.001565, lng: 21.775946 },
    { lat: 49.002268, lng: 21.775668 },
    { lat: 49.003511, lng: 21.774774 },
  ],
  Komárany: [
    { lat: 48.9108825, lng: 21.6415751 },
    { lat: 48.9109641, lng: 21.641757 },
    { lat: 48.9121044, lng: 21.6438317 },
    { lat: 48.9123469, lng: 21.6435649 },
    { lat: 48.9131884, lng: 21.6451235 },
    { lat: 48.9139385, lng: 21.6468391 },
    { lat: 48.9145865, lng: 21.6478588 },
    { lat: 48.9148981, lng: 21.6481825 },
    { lat: 48.9162463, lng: 21.6513243 },
    { lat: 48.9165006, lng: 21.6517325 },
    { lat: 48.9170614, lng: 21.6529474 },
    { lat: 48.9173682, lng: 21.6533898 },
    { lat: 48.9175306, lng: 21.6537781 },
    { lat: 48.9184047, lng: 21.655292 },
    { lat: 48.9190048, lng: 21.654855 },
    { lat: 48.9195554, lng: 21.6554463 },
    { lat: 48.9198041, lng: 21.6558607 },
    { lat: 48.9200063, lng: 21.6552784 },
    { lat: 48.9210534, lng: 21.6533698 },
    { lat: 48.921568, lng: 21.6528143 },
    { lat: 48.9222119, lng: 21.6519202 },
    { lat: 48.9241623, lng: 21.6548689 },
    { lat: 48.9249166, lng: 21.6561151 },
    { lat: 48.9257359, lng: 21.6576971 },
    { lat: 48.9262725, lng: 21.6567836 },
    { lat: 48.9279663, lng: 21.6545446 },
    { lat: 48.9281096, lng: 21.65427 },
    { lat: 48.9287603, lng: 21.652561 },
    { lat: 48.9294831, lng: 21.6514242 },
    { lat: 48.929746, lng: 21.650916 },
    { lat: 48.9304537, lng: 21.6491922 },
    { lat: 48.9307234, lng: 21.6487698 },
    { lat: 48.9334228, lng: 21.6460371 },
    { lat: 48.9334422, lng: 21.6460888 },
    { lat: 48.9334786, lng: 21.6461863 },
    { lat: 48.9338049, lng: 21.6458945 },
    { lat: 48.9336478, lng: 21.6452149 },
    { lat: 48.9335424, lng: 21.6435884 },
    { lat: 48.9339761, lng: 21.641394 },
    { lat: 48.934015, lng: 21.6401463 },
    { lat: 48.9341391, lng: 21.6399105 },
    { lat: 48.9340995, lng: 21.6395752 },
    { lat: 48.9342412, lng: 21.6388806 },
    { lat: 48.9348015, lng: 21.6374038 },
    { lat: 48.9355636, lng: 21.6366427 },
    { lat: 48.9361119, lng: 21.6357585 },
    { lat: 48.9352725, lng: 21.633748 },
    { lat: 48.9347006, lng: 21.6314461 },
    { lat: 48.9357526, lng: 21.6297368 },
    { lat: 48.9363076, lng: 21.6290535 },
    { lat: 48.9370193, lng: 21.6284165 },
    { lat: 48.9369175, lng: 21.6277512 },
    { lat: 48.9368872, lng: 21.6273463 },
    { lat: 48.9361684, lng: 21.6272706 },
    { lat: 48.9356481, lng: 21.6257814 },
    { lat: 48.9356144, lng: 21.6254905 },
    { lat: 48.9355501, lng: 21.6253224 },
    { lat: 48.9348759, lng: 21.624495 },
    { lat: 48.9344196, lng: 21.6242277 },
    { lat: 48.9339714, lng: 21.6246617 },
    { lat: 48.9338971, lng: 21.6245844 },
    { lat: 48.9335653, lng: 21.6238476 },
    { lat: 48.9335363, lng: 21.6236906 },
    { lat: 48.9334216, lng: 21.6235773 },
    { lat: 48.9333521, lng: 21.6233828 },
    { lat: 48.9333514, lng: 21.6230318 },
    { lat: 48.9331908, lng: 21.6226284 },
    { lat: 48.9330655, lng: 21.6219239 },
    { lat: 48.9328055, lng: 21.6211915 },
    { lat: 48.9322803, lng: 21.6183441 },
    { lat: 48.9320829, lng: 21.6179154 },
    { lat: 48.932045, lng: 21.618056 },
    { lat: 48.9310043, lng: 21.616262 },
    { lat: 48.930818, lng: 21.6170543 },
    { lat: 48.9306669, lng: 21.617446 },
    { lat: 48.9296332, lng: 21.6185585 },
    { lat: 48.9294011, lng: 21.6189554 },
    { lat: 48.9293457, lng: 21.6195074 },
    { lat: 48.9300385, lng: 21.6203607 },
    { lat: 48.9301048, lng: 21.6205554 },
    { lat: 48.9299782, lng: 21.62078 },
    { lat: 48.9292664, lng: 21.621041 },
    { lat: 48.9288131, lng: 21.6211196 },
    { lat: 48.9282546, lng: 21.6211054 },
    { lat: 48.9278935, lng: 21.6212282 },
    { lat: 48.9273176, lng: 21.621647 },
    { lat: 48.9269852, lng: 21.6217511 },
    { lat: 48.9261863, lng: 21.6217855 },
    { lat: 48.9256241, lng: 21.6220037 },
    { lat: 48.9252154, lng: 21.6225947 },
    { lat: 48.9248238, lng: 21.6238156 },
    { lat: 48.9234642, lng: 21.6259624 },
    { lat: 48.9230679, lng: 21.6263196 },
    { lat: 48.9228144, lng: 21.6266681 },
    { lat: 48.9226029, lng: 21.62708 },
    { lat: 48.9224814, lng: 21.6274534 },
    { lat: 48.9224928, lng: 21.6285989 },
    { lat: 48.9223917, lng: 21.6289709 },
    { lat: 48.9220945, lng: 21.6295712 },
    { lat: 48.9214136, lng: 21.6302623 },
    { lat: 48.9212413, lng: 21.6302567 },
    { lat: 48.9210313, lng: 21.6303531 },
    { lat: 48.920393, lng: 21.6307424 },
    { lat: 48.9198155, lng: 21.6307293 },
    { lat: 48.9187135, lng: 21.6301016 },
    { lat: 48.9181596, lng: 21.6294721 },
    { lat: 48.9178008, lng: 21.6293121 },
    { lat: 48.9176393, lng: 21.6293721 },
    { lat: 48.9166849, lng: 21.6303795 },
    { lat: 48.9161678, lng: 21.6307611 },
    { lat: 48.9158117, lng: 21.6310319 },
    { lat: 48.9132872, lng: 21.6327097 },
    { lat: 48.9129975, lng: 21.6332249 },
    { lat: 48.9123469, lng: 21.6357009 },
    { lat: 48.9120051, lng: 21.6366129 },
    { lat: 48.9113456, lng: 21.6373392 },
    { lat: 48.9115343, lng: 21.6375297 },
    { lat: 48.9126485, lng: 21.6402491 },
    { lat: 48.9120712, lng: 21.6412238 },
    { lat: 48.9117669, lng: 21.64162 },
    { lat: 48.9113061, lng: 21.6407747 },
    { lat: 48.9111696, lng: 21.6412969 },
    { lat: 48.9108825, lng: 21.6415751 },
  ],
  HermanovcenadTopľou: [
    { lat: 48.9410584, lng: 21.4931122 },
    { lat: 48.9414272, lng: 21.49396 },
    { lat: 48.9416835, lng: 21.4943901 },
    { lat: 48.9420327, lng: 21.4952611 },
    { lat: 48.9438052, lng: 21.497552 },
    { lat: 48.9441676, lng: 21.4985381 },
    { lat: 48.9454573, lng: 21.5013694 },
    { lat: 48.9455706, lng: 21.5017134 },
    { lat: 48.9461042, lng: 21.5027853 },
    { lat: 48.9466519, lng: 21.5036423 },
    { lat: 48.9469646, lng: 21.5042688 },
    { lat: 48.9474914, lng: 21.5050638 },
    { lat: 48.9478429, lng: 21.5051197 },
    { lat: 48.9481138, lng: 21.5047979 },
    { lat: 48.9487128, lng: 21.5043747 },
    { lat: 48.9487425, lng: 21.5042356 },
    { lat: 48.9492417, lng: 21.5043036 },
    { lat: 48.9507519, lng: 21.5034082 },
    { lat: 48.9509312, lng: 21.5034605 },
    { lat: 48.9512893, lng: 21.5036645 },
    { lat: 48.9521423, lng: 21.503988 },
    { lat: 48.9528988, lng: 21.5045936 },
    { lat: 48.95358, lng: 21.5049657 },
    { lat: 48.9547103, lng: 21.5059315 },
    { lat: 48.9569887, lng: 21.5066384 },
    { lat: 48.9573726, lng: 21.5065658 },
    { lat: 48.958134, lng: 21.5067906 },
    { lat: 48.9587945, lng: 21.5065984 },
    { lat: 48.9594682, lng: 21.5068651 },
    { lat: 48.9600323, lng: 21.5081803 },
    { lat: 48.9607406, lng: 21.5089811 },
    { lat: 48.961553, lng: 21.5097403 },
    { lat: 48.9616793, lng: 21.5097868 },
    { lat: 48.9627028, lng: 21.5106793 },
    { lat: 48.9631102, lng: 21.5112727 },
    { lat: 48.9637158, lng: 21.5125827 },
    { lat: 48.9642325, lng: 21.5139348 },
    { lat: 48.9652084, lng: 21.5155253 },
    { lat: 48.9659048, lng: 21.5170615 },
    { lat: 48.9681196, lng: 21.523245 },
    { lat: 48.9689221, lng: 21.5248016 },
    { lat: 48.9691579, lng: 21.5251031 },
    { lat: 48.9692602, lng: 21.5254172 },
    { lat: 48.9699984, lng: 21.5259314 },
    { lat: 48.9726684, lng: 21.5302143 },
    { lat: 48.9724642, lng: 21.5329384 },
    { lat: 48.9730856, lng: 21.5346888 },
    { lat: 48.9740946, lng: 21.5364686 },
    { lat: 48.9740978, lng: 21.5380539 },
    { lat: 48.9746241, lng: 21.5402712 },
    { lat: 48.9756946, lng: 21.5438709 },
    { lat: 48.9762175, lng: 21.5451717 },
    { lat: 48.9770898, lng: 21.5466646 },
    { lat: 48.9774081, lng: 21.5472896 },
    { lat: 48.9787352, lng: 21.5458807 },
    { lat: 48.9803608, lng: 21.5435691 },
    { lat: 48.9812142, lng: 21.5423554 },
    { lat: 48.9819931, lng: 21.5410942 },
    { lat: 48.9821543, lng: 21.540585 },
    { lat: 48.9825709, lng: 21.5400005 },
    { lat: 48.9828944, lng: 21.5397841 },
    { lat: 48.9831909, lng: 21.5392088 },
    { lat: 48.9832279, lng: 21.5390062 },
    { lat: 48.98357, lng: 21.538722 },
    { lat: 48.9838529, lng: 21.5381497 },
    { lat: 48.9842312, lng: 21.5376446 },
    { lat: 48.9843804, lng: 21.5371857 },
    { lat: 48.9846582, lng: 21.5367054 },
    { lat: 48.9852388, lng: 21.5359514 },
    { lat: 48.9868273, lng: 21.5332113 },
    { lat: 48.9889898, lng: 21.5305129 },
    { lat: 48.9894496, lng: 21.5304971 },
    { lat: 48.9896264, lng: 21.529275 },
    { lat: 48.9910696, lng: 21.5262975 },
    { lat: 48.9927064, lng: 21.5234411 },
    { lat: 48.9928172, lng: 21.523341 },
    { lat: 48.9935099, lng: 21.5222118 },
    { lat: 48.9979359, lng: 21.5178642 },
    { lat: 48.9981409, lng: 21.5168812 },
    { lat: 48.9982551, lng: 21.5167031 },
    { lat: 49.0009656, lng: 21.513786 },
    { lat: 49.0024593, lng: 21.5124267 },
    { lat: 49.0041988, lng: 21.5112879 },
    { lat: 49.0047777, lng: 21.5108778 },
    { lat: 49.0052657, lng: 21.5103887 },
    { lat: 49.0049809, lng: 21.5095216 },
    { lat: 49.00406, lng: 21.5083968 },
    { lat: 49.0038399, lng: 21.5078577 },
    { lat: 49.002759, lng: 21.5068873 },
    { lat: 49.0013766, lng: 21.5055631 },
    { lat: 49.0004985, lng: 21.5045202 },
    { lat: 49.0004359, lng: 21.5045322 },
    { lat: 49.0001219, lng: 21.5036267 },
    { lat: 48.9999647, lng: 21.5029677 },
    { lat: 48.9997943, lng: 21.5024766 },
    { lat: 48.9989163, lng: 21.5020742 },
    { lat: 48.9984923, lng: 21.5013275 },
    { lat: 48.9981718, lng: 21.5012588 },
    { lat: 48.9983469, lng: 21.5009698 },
    { lat: 48.9983574, lng: 21.5003134 },
    { lat: 48.998237, lng: 21.5001089 },
    { lat: 48.9981453, lng: 21.5000963 },
    { lat: 48.9978725, lng: 21.4996016 },
    { lat: 48.9975375, lng: 21.4992626 },
    { lat: 48.9973464, lng: 21.4986016 },
    { lat: 48.9970616, lng: 21.4981238 },
    { lat: 48.9969595, lng: 21.4982148 },
    { lat: 48.9968345, lng: 21.4980475 },
    { lat: 48.9967839, lng: 21.4977362 },
    { lat: 48.9966012, lng: 21.4973692 },
    { lat: 48.9963377, lng: 21.497042 },
    { lat: 48.9961729, lng: 21.4964011 },
    { lat: 48.9960704, lng: 21.4962392 },
    { lat: 48.995629, lng: 21.4969692 },
    { lat: 48.9955828, lng: 21.4972073 },
    { lat: 48.9951865, lng: 21.497786 },
    { lat: 48.9950729, lng: 21.4982948 },
    { lat: 48.9949427, lng: 21.4985829 },
    { lat: 48.9942654, lng: 21.4985854 },
    { lat: 48.9941058, lng: 21.4986669 },
    { lat: 48.9930483, lng: 21.4969877 },
    { lat: 48.9926609, lng: 21.4967722 },
    { lat: 48.9922713, lng: 21.4953108 },
    { lat: 48.992408, lng: 21.4937963 },
    { lat: 48.9924082, lng: 21.4933355 },
    { lat: 48.9919027, lng: 21.4930899 },
    { lat: 48.9916076, lng: 21.4931181 },
    { lat: 48.9912157, lng: 21.4930315 },
    { lat: 48.9909927, lng: 21.4928288 },
    { lat: 48.9908158, lng: 21.4928592 },
    { lat: 48.9905811, lng: 21.4926329 },
    { lat: 48.9906205, lng: 21.4923627 },
    { lat: 48.9908774, lng: 21.4922539 },
    { lat: 48.9908857, lng: 21.4920739 },
    { lat: 48.9910127, lng: 21.4918322 },
    { lat: 48.9909873, lng: 21.4914993 },
    { lat: 48.9908393, lng: 21.4912064 },
    { lat: 48.9908929, lng: 21.4908388 },
    { lat: 48.9907703, lng: 21.4901333 },
    { lat: 48.990797, lng: 21.4898868 },
    { lat: 48.9909654, lng: 21.4895604 },
    { lat: 48.9907041, lng: 21.4891564 },
    { lat: 48.990707, lng: 21.4886328 },
    { lat: 48.9905255, lng: 21.488504 },
    { lat: 48.9902413, lng: 21.4881331 },
    { lat: 48.9897412, lng: 21.4893195 },
    { lat: 48.964376, lng: 21.440101 },
    { lat: 48.963822, lng: 21.441503 },
    { lat: 48.963615, lng: 21.442093 },
    { lat: 48.963371, lng: 21.442627 },
    { lat: 48.963147, lng: 21.443448 },
    { lat: 48.962079, lng: 21.446652 },
    { lat: 48.961766, lng: 21.447498 },
    { lat: 48.961472, lng: 21.448515 },
    { lat: 48.961128, lng: 21.448477 },
    { lat: 48.960964, lng: 21.448388 },
    { lat: 48.960575, lng: 21.448291 },
    { lat: 48.960059, lng: 21.4487 },
    { lat: 48.959716, lng: 21.449447 },
    { lat: 48.95897, lng: 21.449717 },
    { lat: 48.958182, lng: 21.449056 },
    { lat: 48.957605, lng: 21.448896 },
    { lat: 48.957087, lng: 21.449044 },
    { lat: 48.956941, lng: 21.449182 },
    { lat: 48.956596, lng: 21.449396 },
    { lat: 48.956105, lng: 21.450073 },
    { lat: 48.955892, lng: 21.450217 },
    { lat: 48.955799, lng: 21.450194 },
    { lat: 48.955179, lng: 21.450039 },
    { lat: 48.954239, lng: 21.450665 },
    { lat: 48.953559, lng: 21.451091 },
    { lat: 48.95303, lng: 21.451852 },
    { lat: 48.95276, lng: 21.45233 },
    { lat: 48.952572, lng: 21.452596 },
    { lat: 48.952212, lng: 21.452805 },
    { lat: 48.95182, lng: 21.45341 },
    { lat: 48.951727, lng: 21.453375 },
    { lat: 48.951348, lng: 21.453235 },
    { lat: 48.950952, lng: 21.453273 },
    { lat: 48.950396, lng: 21.453656 },
    { lat: 48.950023, lng: 21.453873 },
    { lat: 48.949483, lng: 21.454476 },
    { lat: 48.949211, lng: 21.454625 },
    { lat: 48.948618, lng: 21.455206 },
    { lat: 48.947821, lng: 21.456212 },
    { lat: 48.947492, lng: 21.456994 },
    { lat: 48.947386, lng: 21.457292 },
    { lat: 48.947329, lng: 21.457343 },
    { lat: 48.946849, lng: 21.457694 },
    { lat: 48.946595, lng: 21.457835 },
    { lat: 48.946551, lng: 21.457865 },
    { lat: 48.946264, lng: 21.458273 },
    { lat: 48.946096, lng: 21.458469 },
    { lat: 48.94594, lng: 21.458728 },
    { lat: 48.945556, lng: 21.459281 },
    { lat: 48.945521, lng: 21.459439 },
    { lat: 48.945299, lng: 21.460022 },
    { lat: 48.945277, lng: 21.460122 },
    { lat: 48.945174, lng: 21.460537 },
    { lat: 48.944968, lng: 21.460904 },
    { lat: 48.944984, lng: 21.46117 },
    { lat: 48.945319, lng: 21.461737 },
    { lat: 48.945367, lng: 21.461971 },
    { lat: 48.945385, lng: 21.462293 },
    { lat: 48.945346, lng: 21.463174 },
    { lat: 48.945351, lng: 21.464257 },
    { lat: 48.945321, lng: 21.465198 },
    { lat: 48.945328, lng: 21.466282 },
    { lat: 48.945305, lng: 21.467197 },
    { lat: 48.9444527, lng: 21.4685856 },
    { lat: 48.9441888, lng: 21.4688358 },
    { lat: 48.9434183, lng: 21.4701647 },
    { lat: 48.9428683, lng: 21.4720833 },
    { lat: 48.9425212, lng: 21.472954 },
    { lat: 48.9420966, lng: 21.4737221 },
    { lat: 48.9419679, lng: 21.4738624 },
    { lat: 48.9412476, lng: 21.4760775 },
    { lat: 48.9403952, lng: 21.47914 },
    { lat: 48.9410273, lng: 21.4788922 },
    { lat: 48.9414267, lng: 21.4788119 },
    { lat: 48.9418325, lng: 21.4791132 },
    { lat: 48.942254, lng: 21.4799968 },
    { lat: 48.9426558, lng: 21.4818696 },
    { lat: 48.9426138, lng: 21.4829905 },
    { lat: 48.9423616, lng: 21.4847316 },
    { lat: 48.9418152, lng: 21.4864434 },
    { lat: 48.941527, lng: 21.4867858 },
    { lat: 48.9410429, lng: 21.4875623 },
    { lat: 48.9411422, lng: 21.488705 },
    { lat: 48.9411146, lng: 21.4899338 },
    { lat: 48.9412042, lng: 21.4907879 },
    { lat: 48.9408956, lng: 21.4924218 },
    { lat: 48.9410004, lng: 21.4928905 },
    { lat: 48.9410584, lng: 21.4931122 },
  ],
  Sedliská: [
    { lat: 48.9048945, lng: 21.7466284 },
    { lat: 48.9049435, lng: 21.7463944 },
    { lat: 48.9056899, lng: 21.7472571 },
    { lat: 48.9058045, lng: 21.7472952 },
    { lat: 48.9061892, lng: 21.7471254 },
    { lat: 48.9064549, lng: 21.746709 },
    { lat: 48.9067192, lng: 21.7458124 },
    { lat: 48.9070394, lng: 21.7450003 },
    { lat: 48.9072491, lng: 21.7441689 },
    { lat: 48.9074175, lng: 21.7431344 },
    { lat: 48.9076382, lng: 21.7425065 },
    { lat: 48.9081167, lng: 21.7418935 },
    { lat: 48.9085394, lng: 21.7415844 },
    { lat: 48.9090896, lng: 21.7413333 },
    { lat: 48.9100698, lng: 21.7414321 },
    { lat: 48.9109248, lng: 21.7416419 },
    { lat: 48.9120785, lng: 21.7417039 },
    { lat: 48.9137724, lng: 21.742116 },
    { lat: 48.9142037, lng: 21.7419143 },
    { lat: 48.9144579, lng: 21.7418745 },
    { lat: 48.9146859, lng: 21.7416899 },
    { lat: 48.915206, lng: 21.7416627 },
    { lat: 48.9155028, lng: 21.7416978 },
    { lat: 48.9166208, lng: 21.7421899 },
    { lat: 48.9171111, lng: 21.7422672 },
    { lat: 48.9175907, lng: 21.7417746 },
    { lat: 48.9184329, lng: 21.7412491 },
    { lat: 48.9192379, lng: 21.7409823 },
    { lat: 48.9202837, lng: 21.7409662 },
    { lat: 48.9219169, lng: 21.7408229 },
    { lat: 48.9227576, lng: 21.7402462 },
    { lat: 48.923126, lng: 21.7398827 },
    { lat: 48.9242718, lng: 21.7384255 },
    { lat: 48.9261038, lng: 21.7364153 },
    { lat: 48.9265671, lng: 21.7358173 },
    { lat: 48.9273019, lng: 21.7346821 },
    { lat: 48.9274769, lng: 21.7343319 },
    { lat: 48.9273095, lng: 21.7341264 },
    { lat: 48.9275206, lng: 21.7331868 },
    { lat: 48.9270516, lng: 21.7327198 },
    { lat: 48.9269405, lng: 21.7327717 },
    { lat: 48.9274535, lng: 21.7315087 },
    { lat: 48.9278463, lng: 21.7308828 },
    { lat: 48.9271284, lng: 21.7294858 },
    { lat: 48.925472, lng: 21.7266934 },
    { lat: 48.9238816, lng: 21.722958 },
    { lat: 48.9235792, lng: 21.7217456 },
    { lat: 48.9232999, lng: 21.7189656 },
    { lat: 48.92339, lng: 21.7188774 },
    { lat: 48.9236861, lng: 21.7188867 },
    { lat: 48.9239826, lng: 21.7186996 },
    { lat: 48.9254485, lng: 21.716942 },
    { lat: 48.9256463, lng: 21.7165541 },
    { lat: 48.925776, lng: 21.7164438 },
    { lat: 48.9264256, lng: 21.7163328 },
    { lat: 48.9268779, lng: 21.7159146 },
    { lat: 48.927208, lng: 21.7158491 },
    { lat: 48.9276285, lng: 21.7159666 },
    { lat: 48.9278434, lng: 21.7159641 },
    { lat: 48.9283225, lng: 21.7157896 },
    { lat: 48.9290995, lng: 21.7152707 },
    { lat: 48.9292322, lng: 21.7138894 },
    { lat: 48.9293325, lng: 21.7135171 },
    { lat: 48.9293552, lng: 21.7129812 },
    { lat: 48.9290328, lng: 21.7114057 },
    { lat: 48.9288604, lng: 21.7109359 },
    { lat: 48.9288138, lng: 21.7101574 },
    { lat: 48.928582, lng: 21.7096558 },
    { lat: 48.9285286, lng: 21.7093897 },
    { lat: 48.9282454, lng: 21.7089389 },
    { lat: 48.9282831, lng: 21.7089197 },
    { lat: 48.9282539, lng: 21.7088775 },
    { lat: 48.9277047, lng: 21.708738 },
    { lat: 48.9276834, lng: 21.7085805 },
    { lat: 48.9275549, lng: 21.7085684 },
    { lat: 48.9274909, lng: 21.7084078 },
    { lat: 48.9273505, lng: 21.7082887 },
    { lat: 48.9272696, lng: 21.7083306 },
    { lat: 48.9269779, lng: 21.7090739 },
    { lat: 48.9267402, lng: 21.708939 },
    { lat: 48.9264232, lng: 21.7091171 },
    { lat: 48.9263723, lng: 21.7092333 },
    { lat: 48.9261644, lng: 21.709409 },
    { lat: 48.926079, lng: 21.7097073 },
    { lat: 48.9257713, lng: 21.7096855 },
    { lat: 48.9254718, lng: 21.7098854 },
    { lat: 48.9252225, lng: 21.7097732 },
    { lat: 48.9250781, lng: 21.7098848 },
    { lat: 48.9249928, lng: 21.7101398 },
    { lat: 48.9248558, lng: 21.7102785 },
    { lat: 48.9246545, lng: 21.7102813 },
    { lat: 48.9243954, lng: 21.7100619 },
    { lat: 48.9239637, lng: 21.7099448 },
    { lat: 48.9236052, lng: 21.7101319 },
    { lat: 48.9233076, lng: 21.7104447 },
    { lat: 48.9231721, lng: 21.7102985 },
    { lat: 48.923075, lng: 21.7100337 },
    { lat: 48.9223511, lng: 21.7095094 },
    { lat: 48.9222809, lng: 21.7093642 },
    { lat: 48.9222657, lng: 21.7091741 },
    { lat: 48.9223655, lng: 21.7087933 },
    { lat: 48.9226699, lng: 21.7079764 },
    { lat: 48.9228692, lng: 21.70727 },
    { lat: 48.9233731, lng: 21.7063881 },
    { lat: 48.9239272, lng: 21.7051993 },
    { lat: 48.9241924, lng: 21.7045182 },
    { lat: 48.9243764, lng: 21.7042055 },
    { lat: 48.9241529, lng: 21.7026732 },
    { lat: 48.9242938, lng: 21.7020144 },
    { lat: 48.9244035, lng: 21.7006145 },
    { lat: 48.92444, lng: 21.7006315 },
    { lat: 48.9246241, lng: 21.6991941 },
    { lat: 48.9251584, lng: 21.6986915 },
    { lat: 48.9253896, lng: 21.6982185 },
    { lat: 48.925257, lng: 21.6970139 },
    { lat: 48.9253207, lng: 21.6966565 },
    { lat: 48.9252953, lng: 21.6957168 },
    { lat: 48.9256378, lng: 21.6945274 },
    { lat: 48.9256716, lng: 21.6942427 },
    { lat: 48.925631, lng: 21.6922764 },
    { lat: 48.9254122, lng: 21.6916704 },
    { lat: 48.925155, lng: 21.6913143 },
    { lat: 48.9243641, lng: 21.6896488 },
    { lat: 48.9243868, lng: 21.6894857 },
    { lat: 48.9242266, lng: 21.6891397 },
    { lat: 48.9241643, lng: 21.6886234 },
    { lat: 48.924067, lng: 21.6885228 },
    { lat: 48.9233022, lng: 21.6881551 },
    { lat: 48.9232726, lng: 21.6881426 },
    { lat: 48.9229303, lng: 21.6883847 },
    { lat: 48.9217243, lng: 21.6888859 },
    { lat: 48.9206804, lng: 21.6900914 },
    { lat: 48.920582, lng: 21.6903232 },
    { lat: 48.9203614, lng: 21.690445 },
    { lat: 48.9202139, lng: 21.690695 },
    { lat: 48.9200802, lng: 21.6907784 },
    { lat: 48.9197647, lng: 21.6911336 },
    { lat: 48.9186705, lng: 21.6921103 },
    { lat: 48.9177042, lng: 21.693446 },
    { lat: 48.914768, lng: 21.6964089 },
    { lat: 48.9145305, lng: 21.6969495 },
    { lat: 48.9143167, lng: 21.697113 },
    { lat: 48.9139827, lng: 21.6975018 },
    { lat: 48.9128988, lng: 21.6995231 },
    { lat: 48.9125991, lng: 21.6999684 },
    { lat: 48.9119878, lng: 21.7007474 },
    { lat: 48.9110945, lng: 21.7017067 },
    { lat: 48.9105413, lng: 21.7024277 },
    { lat: 48.910181, lng: 21.7027934 },
    { lat: 48.9096995, lng: 21.7031666 },
    { lat: 48.9077592, lng: 21.7038129 },
    { lat: 48.9073131, lng: 21.704126 },
    { lat: 48.907157, lng: 21.704342 },
    { lat: 48.9067085, lng: 21.7047324 },
    { lat: 48.9053229, lng: 21.7057871 },
    { lat: 48.9048222, lng: 21.7060434 },
    { lat: 48.9048135, lng: 21.7060092 },
    { lat: 48.9046807, lng: 21.7062173 },
    { lat: 48.904165, lng: 21.7066176 },
    { lat: 48.9040882, lng: 21.7066098 },
    { lat: 48.9038747, lng: 21.706789 },
    { lat: 48.9035296, lng: 21.7069216 },
    { lat: 48.90366, lng: 21.7073766 },
    { lat: 48.9036119, lng: 21.7074758 },
    { lat: 48.9027343, lng: 21.7085564 },
    { lat: 48.9021933, lng: 21.709103 },
    { lat: 48.9017806, lng: 21.7097801 },
    { lat: 48.9015334, lng: 21.7100843 },
    { lat: 48.9012943, lng: 21.7106963 },
    { lat: 48.9011523, lng: 21.7109378 },
    { lat: 48.9006314, lng: 21.711608 },
    { lat: 48.9005724, lng: 21.712045 },
    { lat: 48.9004641, lng: 21.7120753 },
    { lat: 48.9003569, lng: 21.7124034 },
    { lat: 48.8999077, lng: 21.7131817 },
    { lat: 48.8997769, lng: 21.7131766 },
    { lat: 48.8996596, lng: 21.7135829 },
    { lat: 48.8991724, lng: 21.7145859 },
    { lat: 48.8973208, lng: 21.7179101 },
    { lat: 48.8963232, lng: 21.7227878 },
    { lat: 48.8962306, lng: 21.7227824 },
    { lat: 48.895667, lng: 21.7230728 },
    { lat: 48.8929668, lng: 21.7255082 },
    { lat: 48.8925253, lng: 21.7255279 },
    { lat: 48.8923134, lng: 21.7256796 },
    { lat: 48.8923464, lng: 21.7260323 },
    { lat: 48.8921006, lng: 21.729846 },
    { lat: 48.8908873, lng: 21.7329394 },
    { lat: 48.8896827, lng: 21.7362758 },
    { lat: 48.8888166, lng: 21.7379741 },
    { lat: 48.8888252, lng: 21.7382528 },
    { lat: 48.8901114, lng: 21.7376395 },
    { lat: 48.8910577, lng: 21.736923 },
    { lat: 48.8915355, lng: 21.73603 },
    { lat: 48.8918202, lng: 21.7359449 },
    { lat: 48.8948288, lng: 21.7355388 },
    { lat: 48.8971947, lng: 21.7353701 },
    { lat: 48.89769, lng: 21.7355021 },
    { lat: 48.8981789, lng: 21.7359988 },
    { lat: 48.8982949, lng: 21.7365466 },
    { lat: 48.8982985, lng: 21.7372304 },
    { lat: 48.8977826, lng: 21.7382185 },
    { lat: 48.8977646, lng: 21.7385149 },
    { lat: 48.8979341, lng: 21.7390537 },
    { lat: 48.8985507, lng: 21.739946 },
    { lat: 48.8998292, lng: 21.7413109 },
    { lat: 48.9003625, lng: 21.7420298 },
    { lat: 48.9005278, lng: 21.7425818 },
    { lat: 48.9011251, lng: 21.7434217 },
    { lat: 48.9015432, lng: 21.7434541 },
    { lat: 48.9020035, lng: 21.7439297 },
    { lat: 48.9032909, lng: 21.7449588 },
    { lat: 48.9035159, lng: 21.7452104 },
    { lat: 48.9041765, lng: 21.7455115 },
    { lat: 48.9045532, lng: 21.746016 },
    { lat: 48.9047317, lng: 21.7461717 },
    { lat: 48.9048945, lng: 21.7466284 },
  ],
  Banské: [
    { lat: 48.786726, lng: 21.582028 },
    { lat: 48.7872485, lng: 21.5816967 },
    { lat: 48.7877267, lng: 21.5816578 },
    { lat: 48.7877454, lng: 21.5817593 },
    { lat: 48.7879982, lng: 21.581828 },
    { lat: 48.7880976, lng: 21.5820025 },
    { lat: 48.7883332, lng: 21.5821394 },
    { lat: 48.7888177, lng: 21.5826867 },
    { lat: 48.7891897, lng: 21.5829507 },
    { lat: 48.7895291, lng: 21.5830563 },
    { lat: 48.7896368, lng: 21.5832078 },
    { lat: 48.7899155, lng: 21.5833605 },
    { lat: 48.7901098, lng: 21.5833844 },
    { lat: 48.7909448, lng: 21.5841972 },
    { lat: 48.7912623, lng: 21.5845975 },
    { lat: 48.79155, lng: 21.5847946 },
    { lat: 48.7922287, lng: 21.5860871 },
    { lat: 48.7924603, lng: 21.5863595 },
    { lat: 48.7926002, lng: 21.5868583 },
    { lat: 48.7926507, lng: 21.5880983 },
    { lat: 48.7926927, lng: 21.5881948 },
    { lat: 48.7927666, lng: 21.5889037 },
    { lat: 48.792934, lng: 21.5891749 },
    { lat: 48.7929874, lng: 21.5893567 },
    { lat: 48.7934898, lng: 21.5901385 },
    { lat: 48.7937205, lng: 21.5903046 },
    { lat: 48.7939532, lng: 21.5910205 },
    { lat: 48.7941463, lng: 21.5912908 },
    { lat: 48.7942227, lng: 21.5915257 },
    { lat: 48.79443, lng: 21.5918575 },
    { lat: 48.79465, lng: 21.592439 },
    { lat: 48.7947371, lng: 21.592857 },
    { lat: 48.7947153, lng: 21.5931668 },
    { lat: 48.7951318, lng: 21.5937011 },
    { lat: 48.7953508, lng: 21.5937684 },
    { lat: 48.7955145, lng: 21.5942272 },
    { lat: 48.7957075, lng: 21.5942067 },
    { lat: 48.7958674, lng: 21.5942946 },
    { lat: 48.7961266, lng: 21.5949139 },
    { lat: 48.796359, lng: 21.5952234 },
    { lat: 48.7964393, lng: 21.5955559 },
    { lat: 48.7966613, lng: 21.5960509 },
    { lat: 48.7968852, lng: 21.596775 },
    { lat: 48.7970732, lng: 21.5971009 },
    { lat: 48.7972526, lng: 21.5971594 },
    { lat: 48.7974439, lng: 21.597407 },
    { lat: 48.7975554, lng: 21.5976072 },
    { lat: 48.7985068, lng: 21.5967473 },
    { lat: 48.8065297, lng: 21.5888034 },
    { lat: 48.8079546, lng: 21.5876851 },
    { lat: 48.809344, lng: 21.5881257 },
    { lat: 48.8097753, lng: 21.5884225 },
    { lat: 48.8110888, lng: 21.5888434 },
    { lat: 48.8116464, lng: 21.5887844 },
    { lat: 48.8121148, lng: 21.5884706 },
    { lat: 48.8129137, lng: 21.588197 },
    { lat: 48.8135157, lng: 21.5877517 },
    { lat: 48.815007, lng: 21.588294 },
    { lat: 48.8151605, lng: 21.5885584 },
    { lat: 48.8151647, lng: 21.589306 },
    { lat: 48.8150512, lng: 21.5901201 },
    { lat: 48.8149336, lng: 21.5904154 },
    { lat: 48.8150864, lng: 21.5906548 },
    { lat: 48.8152537, lng: 21.591081 },
    { lat: 48.8151836, lng: 21.5914329 },
    { lat: 48.8152608, lng: 21.5916996 },
    { lat: 48.8155505, lng: 21.5919235 },
    { lat: 48.8165713, lng: 21.5906796 },
    { lat: 48.816905, lng: 21.5905068 },
    { lat: 48.8170476, lng: 21.5909364 },
    { lat: 48.8177213, lng: 21.5918911 },
    { lat: 48.8178964, lng: 21.5919249 },
    { lat: 48.8181858, lng: 21.5921288 },
    { lat: 48.818417, lng: 21.5927184 },
    { lat: 48.8188481, lng: 21.5933631 },
    { lat: 48.8190617, lng: 21.5933623 },
    { lat: 48.819695, lng: 21.5928329 },
    { lat: 48.819812, lng: 21.5930721 },
    { lat: 48.8200135, lng: 21.5938012 },
    { lat: 48.8201892, lng: 21.5936442 },
    { lat: 48.8203874, lng: 21.5940546 },
    { lat: 48.8206248, lng: 21.5953901 },
    { lat: 48.8208895, lng: 21.5955553 },
    { lat: 48.8209686, lng: 21.5959315 },
    { lat: 48.8212465, lng: 21.5961308 },
    { lat: 48.8214313, lng: 21.5957398 },
    { lat: 48.8222211, lng: 21.5958578 },
    { lat: 48.8225063, lng: 21.5956025 },
    { lat: 48.8234568, lng: 21.5951853 },
    { lat: 48.8233281, lng: 21.5957352 },
    { lat: 48.823364, lng: 21.596422 },
    { lat: 48.823727, lng: 21.5975342 },
    { lat: 48.8242815, lng: 21.5971603 },
    { lat: 48.8244933, lng: 21.5970957 },
    { lat: 48.8244982, lng: 21.5969083 },
    { lat: 48.8245585, lng: 21.5968455 },
    { lat: 48.8245674, lng: 21.5959158 },
    { lat: 48.8258424, lng: 21.5955593 },
    { lat: 48.8258096, lng: 21.5951083 },
    { lat: 48.8260656, lng: 21.5944488 },
    { lat: 48.8261067, lng: 21.5940287 },
    { lat: 48.8259683, lng: 21.5937858 },
    { lat: 48.8260843, lng: 21.5932271 },
    { lat: 48.826498, lng: 21.5928121 },
    { lat: 48.8265871, lng: 21.5925725 },
    { lat: 48.8269416, lng: 21.5920142 },
    { lat: 48.8273, lng: 21.5917952 },
    { lat: 48.8284314, lng: 21.5918941 },
    { lat: 48.8286432, lng: 21.5919898 },
    { lat: 48.8289149, lng: 21.5922884 },
    { lat: 48.8290261, lng: 21.5922863 },
    { lat: 48.8294227, lng: 21.5921855 },
    { lat: 48.8301981, lng: 21.5916705 },
    { lat: 48.8305778, lng: 21.5915623 },
    { lat: 48.8322477, lng: 21.5919327 },
    { lat: 48.8327339, lng: 21.5921387 },
    { lat: 48.8341784, lng: 21.592979 },
    { lat: 48.8344251, lng: 21.5933528 },
    { lat: 48.8347006, lng: 21.593148 },
    { lat: 48.8351624, lng: 21.5932477 },
    { lat: 48.8351954, lng: 21.5934238 },
    { lat: 48.8358497, lng: 21.5930787 },
    { lat: 48.8361591, lng: 21.593201 },
    { lat: 48.8368796, lng: 21.5928963 },
    { lat: 48.8370585, lng: 21.5926927 },
    { lat: 48.8375364, lng: 21.5925638 },
    { lat: 48.8380802, lng: 21.5921726 },
    { lat: 48.8406617, lng: 21.5918264 },
    { lat: 48.84203, lng: 21.5917066 },
    { lat: 48.845992, lng: 21.591119 },
    { lat: 48.8484237, lng: 21.5912957 },
    { lat: 48.8489309, lng: 21.5913675 },
    { lat: 48.8487913, lng: 21.5905962 },
    { lat: 48.8484988, lng: 21.58919 },
    { lat: 48.8483006, lng: 21.5888994 },
    { lat: 48.8480826, lng: 21.5881096 },
    { lat: 48.8485669, lng: 21.5879235 },
    { lat: 48.8487519, lng: 21.5877373 },
    { lat: 48.8488669, lng: 21.5874219 },
    { lat: 48.849067, lng: 21.5871326 },
    { lat: 48.849537, lng: 21.5862007 },
    { lat: 48.8505656, lng: 21.5850465 },
    { lat: 48.8508077, lng: 21.5846047 },
    { lat: 48.8515018, lng: 21.5837974 },
    { lat: 48.8518739, lng: 21.5830658 },
    { lat: 48.8521645, lng: 21.5827577 },
    { lat: 48.8525128, lng: 21.5820104 },
    { lat: 48.8529211, lng: 21.5813282 },
    { lat: 48.8530297, lng: 21.5809454 },
    { lat: 48.8532454, lng: 21.5805335 },
    { lat: 48.8533398, lng: 21.5799643 },
    { lat: 48.8532169, lng: 21.5792724 },
    { lat: 48.8530611, lng: 21.5778954 },
    { lat: 48.8531748, lng: 21.5767281 },
    { lat: 48.853185, lng: 21.5762922 },
    { lat: 48.853133, lng: 21.5759179 },
    { lat: 48.8534918, lng: 21.5753528 },
    { lat: 48.8536955, lng: 21.5747577 },
    { lat: 48.8541861, lng: 21.5727568 },
    { lat: 48.8547375, lng: 21.5716365 },
    { lat: 48.855069, lng: 21.5712182 },
    { lat: 48.855514, lng: 21.5710798 },
    { lat: 48.8562373, lng: 21.5699948 },
    { lat: 48.8564608, lng: 21.5695741 },
    { lat: 48.856868, lng: 21.5691744 },
    { lat: 48.8569389, lng: 21.5688186 },
    { lat: 48.8571151, lng: 21.5684042 },
    { lat: 48.8570836, lng: 21.5678785 },
    { lat: 48.8571669, lng: 21.5676055 },
    { lat: 48.8572406, lng: 21.5666628 },
    { lat: 48.8572694, lng: 21.5638497 },
    { lat: 48.8568693, lng: 21.5637401 },
    { lat: 48.8554824, lng: 21.5620794 },
    { lat: 48.8550521, lng: 21.5618593 },
    { lat: 48.8546472, lng: 21.5614639 },
    { lat: 48.854278, lng: 21.5607032 },
    { lat: 48.8541628, lng: 21.5602894 },
    { lat: 48.8539074, lng: 21.560128 },
    { lat: 48.8536017, lng: 21.5598223 },
    { lat: 48.8534785, lng: 21.5593648 },
    { lat: 48.8526764, lng: 21.5585303 },
    { lat: 48.852179, lng: 21.5583639 },
    { lat: 48.8509255, lng: 21.5568042 },
    { lat: 48.8511887, lng: 21.5499158 },
    { lat: 48.8510618, lng: 21.5498441 },
    { lat: 48.8508386, lng: 21.5493909 },
    { lat: 48.8504815, lng: 21.5483863 },
    { lat: 48.8502813, lng: 21.5479502 },
    { lat: 48.8495757, lng: 21.5476613 },
    { lat: 48.848579, lng: 21.5452432 },
    { lat: 48.8485401, lng: 21.5449445 },
    { lat: 48.8484843, lng: 21.5429751 },
    { lat: 48.8486718, lng: 21.5425588 },
    { lat: 48.8490059, lng: 21.5423592 },
    { lat: 48.8496104, lng: 21.5411798 },
    { lat: 48.8497823, lng: 21.5411729 },
    { lat: 48.849809, lng: 21.5410422 },
    { lat: 48.8499233, lng: 21.5410623 },
    { lat: 48.8499116, lng: 21.5404171 },
    { lat: 48.8497397, lng: 21.5401843 },
    { lat: 48.8493838, lng: 21.5400157 },
    { lat: 48.849107, lng: 21.5389098 },
    { lat: 48.8480463, lng: 21.5370908 },
    { lat: 48.8475369, lng: 21.5346451 },
    { lat: 48.8432069, lng: 21.5345972 },
    { lat: 48.8423547, lng: 21.5341051 },
    { lat: 48.8408042, lng: 21.5329165 },
    { lat: 48.8399805, lng: 21.5325555 },
    { lat: 48.839476, lng: 21.5322581 },
    { lat: 48.8391281, lng: 21.5318968 },
    { lat: 48.8387656, lng: 21.5314554 },
    { lat: 48.8386913, lng: 21.5310981 },
    { lat: 48.8383937, lng: 21.5303674 },
    { lat: 48.8382789, lng: 21.5296113 },
    { lat: 48.8381087, lng: 21.5296485 },
    { lat: 48.8377422, lng: 21.5292192 },
    { lat: 48.8371005, lng: 21.5279081 },
    { lat: 48.8365384, lng: 21.5274838 },
    { lat: 48.8365913, lng: 21.5271735 },
    { lat: 48.8365701, lng: 21.5267831 },
    { lat: 48.8363071, lng: 21.5262334 },
    { lat: 48.8362723, lng: 21.5259246 },
    { lat: 48.8361249, lng: 21.5255346 },
    { lat: 48.8359378, lng: 21.525279 },
    { lat: 48.8358664, lng: 21.5250665 },
    { lat: 48.8358238, lng: 21.5248364 },
    { lat: 48.8358218, lng: 21.5242879 },
    { lat: 48.8355633, lng: 21.5237477 },
    { lat: 48.8353983, lng: 21.5232113 },
    { lat: 48.8353141, lng: 21.5226657 },
    { lat: 48.8348635, lng: 21.5213084 },
    { lat: 48.834613, lng: 21.519541 },
    { lat: 48.833794, lng: 21.520415 },
    { lat: 48.832124, lng: 21.520954 },
    { lat: 48.831903, lng: 21.521028 },
    { lat: 48.83172, lng: 21.521158 },
    { lat: 48.831386, lng: 21.521323 },
    { lat: 48.830953, lng: 21.522578 },
    { lat: 48.83054, lng: 21.523575 },
    { lat: 48.830351, lng: 21.523802 },
    { lat: 48.830007, lng: 21.524402 },
    { lat: 48.829733, lng: 21.524661 },
    { lat: 48.829088, lng: 21.52466 },
    { lat: 48.82831, lng: 21.525405 },
    { lat: 48.828036, lng: 21.526003 },
    { lat: 48.827883, lng: 21.526249 },
    { lat: 48.827592, lng: 21.526663 },
    { lat: 48.827006, lng: 21.526971 },
    { lat: 48.826592, lng: 21.526775 },
    { lat: 48.82614, lng: 21.527258 },
    { lat: 48.825536, lng: 21.527701 },
    { lat: 48.825099, lng: 21.528234 },
    { lat: 48.824853, lng: 21.528582 },
    { lat: 48.824543, lng: 21.528822 },
    { lat: 48.823907, lng: 21.528945 },
    { lat: 48.823434, lng: 21.529376 },
    { lat: 48.822985, lng: 21.529533 },
    { lat: 48.822478, lng: 21.529791 },
    { lat: 48.822415, lng: 21.529822 },
    { lat: 48.822377, lng: 21.529842 },
    { lat: 48.822385, lng: 21.529898 },
    { lat: 48.822305, lng: 21.529943 },
    { lat: 48.822214, lng: 21.529932 },
    { lat: 48.822103, lng: 21.529904 },
    { lat: 48.82177, lng: 21.529913 },
    { lat: 48.821491, lng: 21.53009 },
    { lat: 48.821165, lng: 21.530138 },
    { lat: 48.820995, lng: 21.530031 },
    { lat: 48.820726, lng: 21.52971 },
    { lat: 48.820528, lng: 21.529537 },
    { lat: 48.820093, lng: 21.52941 },
    { lat: 48.819981, lng: 21.529347 },
    { lat: 48.819809, lng: 21.529234 },
    { lat: 48.819813, lng: 21.529208 },
    { lat: 48.819632, lng: 21.529113 },
    { lat: 48.819129, lng: 21.528892 },
    { lat: 48.818967, lng: 21.528859 },
    { lat: 48.818442, lng: 21.528658 },
    { lat: 48.818265, lng: 21.528571 },
    { lat: 48.818166, lng: 21.528538 },
    { lat: 48.817986, lng: 21.528433 },
    { lat: 48.817884, lng: 21.52837 },
    { lat: 48.817809, lng: 21.528356 },
    { lat: 48.817662, lng: 21.528342 },
    { lat: 48.817315, lng: 21.528233 },
    { lat: 48.817136, lng: 21.528114 },
    { lat: 48.816973, lng: 21.527998 },
    { lat: 48.816952, lng: 21.527981 },
    { lat: 48.816868, lng: 21.527881 },
    { lat: 48.81659, lng: 21.527587 },
    { lat: 48.816479, lng: 21.52728 },
    { lat: 48.816384, lng: 21.527073 },
    { lat: 48.816265, lng: 21.526822 },
    { lat: 48.81603, lng: 21.526508 },
    { lat: 48.81569, lng: 21.526222 },
    { lat: 48.815667, lng: 21.526209 },
    { lat: 48.814913, lng: 21.526006 },
    { lat: 48.814743, lng: 21.525991 },
    { lat: 48.814677, lng: 21.526001 },
    { lat: 48.814552, lng: 21.525946 },
    { lat: 48.81442, lng: 21.525938 },
    { lat: 48.814222, lng: 21.525878 },
    { lat: 48.813988, lng: 21.525863 },
    { lat: 48.813881, lng: 21.525835 },
    { lat: 48.81386, lng: 21.525814 },
    { lat: 48.813853, lng: 21.525798 },
    { lat: 48.813776, lng: 21.52583 },
    { lat: 48.813675, lng: 21.525849 },
    { lat: 48.813592, lng: 21.525856 },
    { lat: 48.813314, lng: 21.525727 },
    { lat: 48.813145, lng: 21.525762 },
    { lat: 48.813034, lng: 21.525771 },
    { lat: 48.812987, lng: 21.525784 },
    { lat: 48.81295, lng: 21.525781 },
    { lat: 48.812861, lng: 21.525804 },
    { lat: 48.812715, lng: 21.525809 },
    { lat: 48.812651, lng: 21.525905 },
    { lat: 48.812237, lng: 21.526306 },
    { lat: 48.812012, lng: 21.526412 },
    { lat: 48.812011, lng: 21.526413 },
    { lat: 48.811644, lng: 21.526833 },
    { lat: 48.810804, lng: 21.527795 },
    { lat: 48.810299, lng: 21.528321 },
    { lat: 48.809701, lng: 21.52932 },
    { lat: 48.809193, lng: 21.5297 },
    { lat: 48.808499, lng: 21.530035 },
    { lat: 48.808043, lng: 21.530123 },
    { lat: 48.806919, lng: 21.530393 },
    { lat: 48.806624, lng: 21.530505 },
    { lat: 48.806176, lng: 21.531217 },
    { lat: 48.806084, lng: 21.531313 },
    { lat: 48.805066, lng: 21.532952 },
    { lat: 48.804208, lng: 21.534417 },
    { lat: 48.803465, lng: 21.535245 },
    { lat: 48.803281, lng: 21.536237 },
    { lat: 48.802788, lng: 21.537065 },
    { lat: 48.802366, lng: 21.537978 },
    { lat: 48.801962, lng: 21.538344 },
    { lat: 48.800923, lng: 21.538782 },
    { lat: 48.800196, lng: 21.539131 },
    { lat: 48.799568, lng: 21.539285 },
    { lat: 48.799242, lng: 21.53937 },
    { lat: 48.799062, lng: 21.539446 },
    { lat: 48.798613, lng: 21.539681 },
    { lat: 48.798066, lng: 21.539909 },
    { lat: 48.796806, lng: 21.540379 },
    { lat: 48.795463, lng: 21.540967 },
    { lat: 48.795299, lng: 21.541073 },
    { lat: 48.795097, lng: 21.541175 },
    { lat: 48.794894, lng: 21.541298 },
    { lat: 48.794757, lng: 21.541362 },
    { lat: 48.79456, lng: 21.541426 },
    { lat: 48.794341, lng: 21.54148 },
    { lat: 48.794077, lng: 21.541568 },
    { lat: 48.794317, lng: 21.542335 },
    { lat: 48.794291, lng: 21.543355 },
    { lat: 48.794137, lng: 21.543758 },
    { lat: 48.793939, lng: 21.543944 },
    { lat: 48.793692, lng: 21.544275 },
    { lat: 48.793062, lng: 21.544969 },
    { lat: 48.792795, lng: 21.545559 },
    { lat: 48.792089, lng: 21.546576 },
    { lat: 48.791963, lng: 21.54809 },
    { lat: 48.791747, lng: 21.549744 },
    { lat: 48.791799, lng: 21.550356 },
    { lat: 48.791807, lng: 21.550465 },
    { lat: 48.791858, lng: 21.551059 },
    { lat: 48.791865, lng: 21.55114 },
    { lat: 48.792297, lng: 21.551985 },
    { lat: 48.792506, lng: 21.552686 },
    { lat: 48.792523, lng: 21.553603 },
    { lat: 48.792437, lng: 21.555262 },
    { lat: 48.792274, lng: 21.557378 },
    { lat: 48.792682, lng: 21.558002 },
    { lat: 48.793417, lng: 21.560482 },
    { lat: 48.793724, lng: 21.561361 },
    { lat: 48.793821, lng: 21.561665 },
    { lat: 48.793988, lng: 21.562408 },
    { lat: 48.79399, lng: 21.563579 },
    { lat: 48.793811, lng: 21.565114 },
    { lat: 48.793624, lng: 21.565484 },
    { lat: 48.792963, lng: 21.566337 },
    { lat: 48.792053, lng: 21.566357 },
    { lat: 48.791636, lng: 21.566809 },
    { lat: 48.791552, lng: 21.567634 },
    { lat: 48.791616, lng: 21.568378 },
    { lat: 48.791895, lng: 21.56952 },
    { lat: 48.791814, lng: 21.570025 },
    { lat: 48.791688, lng: 21.570543 },
    { lat: 48.791416, lng: 21.571527 },
    { lat: 48.790905, lng: 21.5731 },
    { lat: 48.790668, lng: 21.575052 },
    { lat: 48.790972, lng: 21.57593 },
    { lat: 48.790716, lng: 21.576615 },
    { lat: 48.790301, lng: 21.577729 },
    { lat: 48.789527, lng: 21.578586 },
    { lat: 48.789195, lng: 21.578733 },
    { lat: 48.788015, lng: 21.57972 },
    { lat: 48.786768, lng: 21.580573 },
    { lat: 48.786595, lng: 21.581979 },
    { lat: 48.786707, lng: 21.582018 },
    { lat: 48.786726, lng: 21.582028 },
  ],
  Giglovce: [
    { lat: 49.0211359, lng: 21.7495076 },
    { lat: 49.0203414, lng: 21.7482645 },
    { lat: 49.0200088, lng: 21.7474296 },
    { lat: 49.0197634, lng: 21.7466085 },
    { lat: 49.0186649, lng: 21.745064 },
    { lat: 49.0184954, lng: 21.7447421 },
    { lat: 49.0184537, lng: 21.744564 },
    { lat: 49.0172986, lng: 21.7437642 },
    { lat: 49.0169938, lng: 21.7429647 },
    { lat: 49.0168287, lng: 21.7423401 },
    { lat: 49.0162254, lng: 21.7409693 },
    { lat: 49.0153083, lng: 21.7401565 },
    { lat: 49.0149632, lng: 21.7401232 },
    { lat: 49.0147192, lng: 21.7399973 },
    { lat: 49.0143128, lng: 21.7389317 },
    { lat: 49.013256, lng: 21.7385349 },
    { lat: 49.013079, lng: 21.738348 },
    { lat: 49.0127144, lng: 21.7377475 },
    { lat: 49.0126388, lng: 21.7375278 },
    { lat: 49.011635, lng: 21.7370928 },
    { lat: 49.0108198, lng: 21.7364716 },
    { lat: 49.0106302, lng: 21.7356082 },
    { lat: 49.0102879, lng: 21.7350067 },
    { lat: 49.0100971, lng: 21.7343358 },
    { lat: 49.0098654, lng: 21.7338763 },
    { lat: 49.0093511, lng: 21.7333227 },
    { lat: 49.0093231, lng: 21.7327729 },
    { lat: 49.0091012, lng: 21.731542 },
    { lat: 49.0084162, lng: 21.729304 },
    { lat: 49.0079154, lng: 21.7280713 },
    { lat: 49.0077006, lng: 21.7273597 },
    { lat: 49.0073015, lng: 21.7264415 },
    { lat: 49.0072238, lng: 21.7259754 },
    { lat: 49.0067914, lng: 21.7243139 },
    { lat: 49.0064134, lng: 21.7242759 },
    { lat: 49.0061759, lng: 21.7232084 },
    { lat: 49.0053372, lng: 21.7207494 },
    { lat: 49.0060433, lng: 21.7192155 },
    { lat: 49.0060641, lng: 21.7189881 },
    { lat: 49.0048503, lng: 21.7187789 },
    { lat: 49.0037834, lng: 21.7184632 },
    { lat: 49.0021519, lng: 21.7178675 },
    { lat: 49.0002335, lng: 21.717435 },
    { lat: 48.9995381, lng: 21.7171365 },
    { lat: 48.9983977, lng: 21.7168494 },
    { lat: 48.9983688, lng: 21.716829 },
    { lat: 48.9977584, lng: 21.7161724 },
    { lat: 48.9966162, lng: 21.7162408 },
    { lat: 48.9963966, lng: 21.7161946 },
    { lat: 48.9963961, lng: 21.7163759 },
    { lat: 48.9959087, lng: 21.7162383 },
    { lat: 48.9956319, lng: 21.716493 },
    { lat: 48.9955944, lng: 21.7170302 },
    { lat: 48.9952174, lng: 21.7176116 },
    { lat: 48.9951556, lng: 21.717786 },
    { lat: 48.9949307, lng: 21.7178726 },
    { lat: 48.9946495, lng: 21.7182873 },
    { lat: 48.994418, lng: 21.718174 },
    { lat: 48.9940358, lng: 21.7184348 },
    { lat: 48.9938532, lng: 21.7187219 },
    { lat: 48.9938857, lng: 21.7192577 },
    { lat: 48.9935047, lng: 21.7196083 },
    { lat: 48.9933844, lng: 21.7196164 },
    { lat: 48.9930649, lng: 21.7199904 },
    { lat: 48.9929205, lng: 21.7202329 },
    { lat: 48.9930081, lng: 21.720354 },
    { lat: 48.9927305, lng: 21.7204023 },
    { lat: 48.9927792, lng: 21.7205404 },
    { lat: 48.9925611, lng: 21.7209684 },
    { lat: 48.9927342, lng: 21.7213424 },
    { lat: 48.9926277, lng: 21.7216524 },
    { lat: 48.9922955, lng: 21.722014 },
    { lat: 48.9924356, lng: 21.7225885 },
    { lat: 48.9923108, lng: 21.7226736 },
    { lat: 48.9922868, lng: 21.7231254 },
    { lat: 48.9921813, lng: 21.7232651 },
    { lat: 48.9921968, lng: 21.7237123 },
    { lat: 48.992054, lng: 21.7246396 },
    { lat: 48.9921001, lng: 21.7254034 },
    { lat: 48.9918081, lng: 21.7259305 },
    { lat: 48.9918204, lng: 21.7264269 },
    { lat: 48.9916028, lng: 21.7264522 },
    { lat: 48.9914859, lng: 21.7268312 },
    { lat: 48.9913623, lng: 21.7266278 },
    { lat: 48.9905911, lng: 21.7279119 },
    { lat: 48.9905231, lng: 21.7281034 },
    { lat: 48.990511, lng: 21.7281171 },
    { lat: 48.9906271, lng: 21.7283807 },
    { lat: 48.9905446, lng: 21.7286631 },
    { lat: 48.9904285, lng: 21.7287394 },
    { lat: 48.9902668, lng: 21.7286684 },
    { lat: 48.9902174, lng: 21.7287087 },
    { lat: 48.990228, lng: 21.7293166 },
    { lat: 48.990348, lng: 21.7303176 },
    { lat: 48.9903229, lng: 21.7306835 },
    { lat: 48.9907624, lng: 21.7319797 },
    { lat: 48.9906754, lng: 21.7324747 },
    { lat: 48.9908882, lng: 21.7335335 },
    { lat: 48.9911885, lng: 21.7337009 },
    { lat: 48.9915903, lng: 21.7342565 },
    { lat: 48.9912908, lng: 21.7346335 },
    { lat: 48.9910227, lng: 21.7346579 },
    { lat: 48.991229, lng: 21.7355153 },
    { lat: 48.9914847, lng: 21.7357873 },
    { lat: 48.9916019, lng: 21.7356207 },
    { lat: 48.9918394, lng: 21.735547 },
    { lat: 48.9921981, lng: 21.735243 },
    { lat: 48.9924724, lng: 21.7361672 },
    { lat: 48.992079, lng: 21.7367305 },
    { lat: 48.9923374, lng: 21.7372248 },
    { lat: 48.9923655, lng: 21.7376017 },
    { lat: 48.9925821, lng: 21.7378395 },
    { lat: 48.992674, lng: 21.738042 },
    { lat: 48.9928365, lng: 21.7380965 },
    { lat: 48.9930863, lng: 21.7380142 },
    { lat: 48.9934459, lng: 21.7383368 },
    { lat: 48.9935421, lng: 21.738745 },
    { lat: 48.9938604, lng: 21.7391668 },
    { lat: 48.9940149, lng: 21.7392648 },
    { lat: 48.9941705, lng: 21.7397002 },
    { lat: 48.9941413, lng: 21.7402963 },
    { lat: 48.9943167, lng: 21.7410543 },
    { lat: 48.9946939, lng: 21.740938 },
    { lat: 48.9951543, lng: 21.7409489 },
    { lat: 48.9951703, lng: 21.7412119 },
    { lat: 48.995448, lng: 21.7417975 },
    { lat: 48.9963564, lng: 21.7416088 },
    { lat: 48.9970334, lng: 21.7422796 },
    { lat: 48.9967227, lng: 21.7431172 },
    { lat: 48.9971998, lng: 21.7435874 },
    { lat: 48.9974179, lng: 21.7436661 },
    { lat: 48.9975341, lng: 21.7439782 },
    { lat: 48.9975607, lng: 21.744385 },
    { lat: 48.9978245, lng: 21.7450449 },
    { lat: 48.9981445, lng: 21.7447279 },
    { lat: 48.998276, lng: 21.7445042 },
    { lat: 48.9984802, lng: 21.7449159 },
    { lat: 48.9979284, lng: 21.7455663 },
    { lat: 48.9982207, lng: 21.7461155 },
    { lat: 48.9988868, lng: 21.746711 },
    { lat: 48.9990725, lng: 21.746504 },
    { lat: 48.9989254, lng: 21.746297 },
    { lat: 48.9994476, lng: 21.7463517 },
    { lat: 48.9998072, lng: 21.7459127 },
    { lat: 49.0003204, lng: 21.7463044 },
    { lat: 49.0000861, lng: 21.7465881 },
    { lat: 48.9999546, lng: 21.7471089 },
    { lat: 48.9999223, lng: 21.7476658 },
    { lat: 49.0001378, lng: 21.7480711 },
    { lat: 49.0005414, lng: 21.7482747 },
    { lat: 49.0005846, lng: 21.7483734 },
    { lat: 49.0007301, lng: 21.748352 },
    { lat: 49.0007854, lng: 21.7476352 },
    { lat: 49.0007038, lng: 21.7474392 },
    { lat: 49.0008343, lng: 21.7469708 },
    { lat: 49.0009417, lng: 21.7469499 },
    { lat: 49.0010739, lng: 21.7466796 },
    { lat: 49.001151, lng: 21.7467422 },
    { lat: 49.0015241, lng: 21.7466441 },
    { lat: 49.0020865, lng: 21.7467648 },
    { lat: 49.0022413, lng: 21.7473856 },
    { lat: 49.0025026, lng: 21.7474871 },
    { lat: 49.003225, lng: 21.7471885 },
    { lat: 49.0033603, lng: 21.7470717 },
    { lat: 49.0035722, lng: 21.7465822 },
    { lat: 49.0036198, lng: 21.7464295 },
    { lat: 49.0035978, lng: 21.7463226 },
    { lat: 49.0038546, lng: 21.7461564 },
    { lat: 49.0040237, lng: 21.7461324 },
    { lat: 49.0042685, lng: 21.7459379 },
    { lat: 49.0043626, lng: 21.7461024 },
    { lat: 49.0046099, lng: 21.7461377 },
    { lat: 49.0046557, lng: 21.7462915 },
    { lat: 49.0049175, lng: 21.7463679 },
    { lat: 49.0051871, lng: 21.7461429 },
    { lat: 49.0053745, lng: 21.7461579 },
    { lat: 49.0055328, lng: 21.7464492 },
    { lat: 49.0061178, lng: 21.7461085 },
    { lat: 49.0063077, lng: 21.7458897 },
    { lat: 49.0062291, lng: 21.7453882 },
    { lat: 49.0062828, lng: 21.7452896 },
    { lat: 49.0065167, lng: 21.7452807 },
    { lat: 49.0068542, lng: 21.7451532 },
    { lat: 49.0068874, lng: 21.7447534 },
    { lat: 49.0068042, lng: 21.7442454 },
    { lat: 49.0067217, lng: 21.7440613 },
    { lat: 49.0069021, lng: 21.7436073 },
    { lat: 49.0070884, lng: 21.7434246 },
    { lat: 49.0071879, lng: 21.7436579 },
    { lat: 49.007211, lng: 21.7438879 },
    { lat: 49.007597, lng: 21.7437668 },
    { lat: 49.0077838, lng: 21.7438134 },
    { lat: 49.0078483, lng: 21.744256 },
    { lat: 49.008004, lng: 21.7445288 },
    { lat: 49.008114, lng: 21.7445142 },
    { lat: 49.0081666, lng: 21.7442956 },
    { lat: 49.0081194, lng: 21.7437058 },
    { lat: 49.0082879, lng: 21.7436052 },
    { lat: 49.0084981, lng: 21.7436482 },
    { lat: 49.0087655, lng: 21.7441392 },
    { lat: 49.0094162, lng: 21.7439004 },
    { lat: 49.0093384, lng: 21.7435992 },
    { lat: 49.0096088, lng: 21.7434471 },
    { lat: 49.0095267, lng: 21.7429734 },
    { lat: 49.0100867, lng: 21.7428466 },
    { lat: 49.0101797, lng: 21.7426567 },
    { lat: 49.0102157, lng: 21.7424058 },
    { lat: 49.0102945, lng: 21.7423634 },
    { lat: 49.0103454, lng: 21.7424588 },
    { lat: 49.0104958, lng: 21.7424507 },
    { lat: 49.0104989, lng: 21.7430292 },
    { lat: 49.0107586, lng: 21.742981 },
    { lat: 49.0111115, lng: 21.7427591 },
    { lat: 49.0112031, lng: 21.7427746 },
    { lat: 49.0116274, lng: 21.7434582 },
    { lat: 49.0116211, lng: 21.7436897 },
    { lat: 49.0113077, lng: 21.7440063 },
    { lat: 49.0112135, lng: 21.7442691 },
    { lat: 49.0109178, lng: 21.7446115 },
    { lat: 49.0114638, lng: 21.7459925 },
    { lat: 49.0122644, lng: 21.7456715 },
    { lat: 49.0124216, lng: 21.7456626 },
    { lat: 49.0134849, lng: 21.7451269 },
    { lat: 49.0137662, lng: 21.7447459 },
    { lat: 49.0140654, lng: 21.7448738 },
    { lat: 49.0150628, lng: 21.746038 },
    { lat: 49.0154744, lng: 21.7463769 },
    { lat: 49.0164324, lng: 21.7465279 },
    { lat: 49.0169843, lng: 21.7467679 },
    { lat: 49.0172728, lng: 21.7471685 },
    { lat: 49.0175581, lng: 21.7474453 },
    { lat: 49.0177234, lng: 21.7474839 },
    { lat: 49.0181546, lng: 21.7480908 },
    { lat: 49.0184199, lng: 21.7482989 },
    { lat: 49.0192219, lng: 21.7487205 },
    { lat: 49.0192954, lng: 21.7488611 },
    { lat: 49.019479, lng: 21.7488778 },
    { lat: 49.0203637, lng: 21.7493556 },
    { lat: 49.0211359, lng: 21.7495076 },
  ],
  Petrovce: [
    { lat: 48.9979285, lng: 21.4518848 },
    { lat: 48.9975259, lng: 21.4509934 },
    { lat: 48.9970064, lng: 21.4492639 },
    { lat: 48.9967453, lng: 21.4487977 },
    { lat: 48.9962003, lng: 21.4481997 },
    { lat: 48.995405, lng: 21.4466192 },
    { lat: 48.9944867, lng: 21.4449496 },
    { lat: 48.9936379, lng: 21.4436605 },
    { lat: 48.9931603, lng: 21.4428302 },
    { lat: 48.9929567, lng: 21.4422914 },
    { lat: 48.9917716, lng: 21.4402723 },
    { lat: 48.9913418, lng: 21.4397334 },
    { lat: 48.990833, lng: 21.4387634 },
    { lat: 48.990465, lng: 21.4378326 },
    { lat: 48.9897935, lng: 21.436644 },
    { lat: 48.9869562, lng: 21.4318006 },
    { lat: 48.9865851, lng: 21.4313101 },
    { lat: 48.9861352, lng: 21.430499 },
    { lat: 48.9856636, lng: 21.4298046 },
    { lat: 48.9853756, lng: 21.4286881 },
    { lat: 48.9849876, lng: 21.4280078 },
    { lat: 48.9840639, lng: 21.4266624 },
    { lat: 48.982713, lng: 21.42424 },
    { lat: 48.982035, lng: 21.424465 },
    { lat: 48.981682, lng: 21.424396 },
    { lat: 48.981546, lng: 21.424366 },
    { lat: 48.981399, lng: 21.424417 },
    { lat: 48.980951, lng: 21.424749 },
    { lat: 48.980652, lng: 21.425464 },
    { lat: 48.980424, lng: 21.425899 },
    { lat: 48.980279, lng: 21.426236 },
    { lat: 48.980157, lng: 21.426814 },
    { lat: 48.980103, lng: 21.427124 },
    { lat: 48.979993, lng: 21.4275 },
    { lat: 48.979819, lng: 21.428522 },
    { lat: 48.979534, lng: 21.429459 },
    { lat: 48.979476, lng: 21.42963 },
    { lat: 48.97923, lng: 21.430145 },
    { lat: 48.979171, lng: 21.430249 },
    { lat: 48.978827, lng: 21.430265 },
    { lat: 48.978654, lng: 21.430242 },
    { lat: 48.978209, lng: 21.430034 },
    { lat: 48.977853, lng: 21.429764 },
    { lat: 48.977758, lng: 21.429742 },
    { lat: 48.97747, lng: 21.42973 },
    { lat: 48.977191, lng: 21.429817 },
    { lat: 48.976829, lng: 21.430019 },
    { lat: 48.976444, lng: 21.430214 },
    { lat: 48.975774, lng: 21.430569 },
    { lat: 48.975366, lng: 21.430783 },
    { lat: 48.974971, lng: 21.431102 },
    { lat: 48.974853, lng: 21.431143 },
    { lat: 48.974252, lng: 21.431539 },
    { lat: 48.973576, lng: 21.431918 },
    { lat: 48.973268, lng: 21.432089 },
    { lat: 48.972248, lng: 21.432729 },
    { lat: 48.971359, lng: 21.432996 },
    { lat: 48.970963, lng: 21.432923 },
    { lat: 48.970623, lng: 21.432597 },
    { lat: 48.96986, lng: 21.432085 },
    { lat: 48.969795, lng: 21.432136 },
    { lat: 48.969351, lng: 21.432393 },
    { lat: 48.969149, lng: 21.432795 },
    { lat: 48.96893, lng: 21.433313 },
    { lat: 48.968768, lng: 21.433866 },
    { lat: 48.968429, lng: 21.434934 },
    { lat: 48.968225, lng: 21.435434 },
    { lat: 48.96793, lng: 21.436528 },
    { lat: 48.967758, lng: 21.437179 },
    { lat: 48.967603, lng: 21.437478 },
    { lat: 48.967356, lng: 21.438475 },
    { lat: 48.96717, lng: 21.439078 },
    { lat: 48.966869, lng: 21.439291 },
    { lat: 48.966238, lng: 21.439408 },
    { lat: 48.965764, lng: 21.439764 },
    { lat: 48.96533, lng: 21.439814 },
    { lat: 48.96487, lng: 21.439824 },
    { lat: 48.964376, lng: 21.440101 },
    { lat: 48.9897412, lng: 21.4893195 },
    { lat: 48.9902413, lng: 21.4881331 },
    { lat: 48.9905255, lng: 21.488504 },
    { lat: 48.990707, lng: 21.4886328 },
    { lat: 48.9907041, lng: 21.4891564 },
    { lat: 48.9909654, lng: 21.4895604 },
    { lat: 48.990797, lng: 21.4898868 },
    { lat: 48.9907703, lng: 21.4901333 },
    { lat: 48.9908929, lng: 21.4908388 },
    { lat: 48.9908393, lng: 21.4912064 },
    { lat: 48.9909873, lng: 21.4914993 },
    { lat: 48.9910127, lng: 21.4918322 },
    { lat: 48.9908857, lng: 21.4920739 },
    { lat: 48.9908774, lng: 21.4922539 },
    { lat: 48.9906205, lng: 21.4923627 },
    { lat: 48.9905811, lng: 21.4926329 },
    { lat: 48.9908158, lng: 21.4928592 },
    { lat: 48.9909927, lng: 21.4928288 },
    { lat: 48.9912157, lng: 21.4930315 },
    { lat: 48.9916076, lng: 21.4931181 },
    { lat: 48.9919027, lng: 21.4930899 },
    { lat: 48.9924082, lng: 21.4933355 },
    { lat: 48.992408, lng: 21.4937963 },
    { lat: 48.9922713, lng: 21.4953108 },
    { lat: 48.9926609, lng: 21.4967722 },
    { lat: 48.9930483, lng: 21.4969877 },
    { lat: 48.9941058, lng: 21.4986669 },
    { lat: 48.9942654, lng: 21.4985854 },
    { lat: 48.9949427, lng: 21.4985829 },
    { lat: 48.9950729, lng: 21.4982948 },
    { lat: 48.9951865, lng: 21.497786 },
    { lat: 48.9955828, lng: 21.4972073 },
    { lat: 48.995629, lng: 21.4969692 },
    { lat: 48.9960704, lng: 21.4962392 },
    { lat: 48.9961729, lng: 21.4964011 },
    { lat: 48.9963377, lng: 21.497042 },
    { lat: 48.9966012, lng: 21.4973692 },
    { lat: 48.9967839, lng: 21.4977362 },
    { lat: 48.9968345, lng: 21.4980475 },
    { lat: 48.9969595, lng: 21.4982148 },
    { lat: 48.9970616, lng: 21.4981238 },
    { lat: 48.9973464, lng: 21.4986016 },
    { lat: 48.9975375, lng: 21.4992626 },
    { lat: 48.9978725, lng: 21.4996016 },
    { lat: 48.9981453, lng: 21.5000963 },
    { lat: 48.998237, lng: 21.5001089 },
    { lat: 48.9983574, lng: 21.5003134 },
    { lat: 48.9983469, lng: 21.5009698 },
    { lat: 48.9981718, lng: 21.5012588 },
    { lat: 48.9984923, lng: 21.5013275 },
    { lat: 48.9989163, lng: 21.5020742 },
    { lat: 48.9997943, lng: 21.5024766 },
    { lat: 48.9999647, lng: 21.5029677 },
    { lat: 49.0001219, lng: 21.5036267 },
    { lat: 49.0004359, lng: 21.5045322 },
    { lat: 49.0004985, lng: 21.5045202 },
    { lat: 49.0013766, lng: 21.5055631 },
    { lat: 49.002759, lng: 21.5068873 },
    { lat: 49.0027988, lng: 21.5068607 },
    { lat: 49.0052845, lng: 21.5052173 },
    { lat: 49.0069206, lng: 21.5038858 },
    { lat: 49.0084223, lng: 21.5033203 },
    { lat: 49.0091488, lng: 21.5035486 },
    { lat: 49.0085623, lng: 21.5021986 },
    { lat: 49.0090385, lng: 21.5012636 },
    { lat: 49.010053, lng: 21.5001511 },
    { lat: 49.010931, lng: 21.4995364 },
    { lat: 49.0120593, lng: 21.4974887 },
    { lat: 49.013221, lng: 21.4957824 },
    { lat: 49.0146263, lng: 21.4943316 },
    { lat: 49.0159604, lng: 21.4924352 },
    { lat: 49.0134516, lng: 21.4910732 },
    { lat: 49.0134761, lng: 21.4909056 },
    { lat: 49.0125528, lng: 21.4897077 },
    { lat: 49.0112958, lng: 21.4886015 },
    { lat: 49.0081016, lng: 21.4845669 },
    { lat: 49.0068262, lng: 21.4832984 },
    { lat: 49.005886, lng: 21.4821772 },
    { lat: 49.004609, lng: 21.480963 },
    { lat: 49.0029655, lng: 21.4792149 },
    { lat: 49.0035347, lng: 21.4781199 },
    { lat: 49.0041024, lng: 21.477203 },
    { lat: 49.004191, lng: 21.4773154 },
    { lat: 49.0044965, lng: 21.4769023 },
    { lat: 49.0052778, lng: 21.4761002 },
    { lat: 49.0059031, lng: 21.475349 },
    { lat: 49.006924, lng: 21.4760604 },
    { lat: 49.0075611, lng: 21.4771666 },
    { lat: 49.0082162, lng: 21.4767858 },
    { lat: 49.0081772, lng: 21.4765629 },
    { lat: 49.0083487, lng: 21.4761501 },
    { lat: 49.007935, lng: 21.4755777 },
    { lat: 49.0075746, lng: 21.474595 },
    { lat: 49.0066184, lng: 21.4732313 },
    { lat: 49.0062452, lng: 21.4728161 },
    { lat: 49.0057348, lng: 21.4725552 },
    { lat: 49.0054016, lng: 21.4720466 },
    { lat: 49.0050906, lng: 21.4713707 },
    { lat: 49.0046572, lng: 21.4699142 },
    { lat: 49.0044135, lng: 21.4695832 },
    { lat: 49.0046686, lng: 21.4685208 },
    { lat: 49.0044122, lng: 21.4680595 },
    { lat: 49.0042234, lng: 21.467403 },
    { lat: 49.0039607, lng: 21.4672299 },
    { lat: 49.0039178, lng: 21.4669927 },
    { lat: 49.0039496, lng: 21.4665745 },
    { lat: 49.0038213, lng: 21.4663802 },
    { lat: 49.0036366, lng: 21.4659009 },
    { lat: 49.0037353, lng: 21.4656212 },
    { lat: 49.0037314, lng: 21.4654473 },
    { lat: 49.0035875, lng: 21.4650602 },
    { lat: 49.0033013, lng: 21.4649321 },
    { lat: 49.0032763, lng: 21.4643758 },
    { lat: 49.0031908, lng: 21.4641038 },
    { lat: 49.003084, lng: 21.4641532 },
    { lat: 49.0028769, lng: 21.4644164 },
    { lat: 49.0027996, lng: 21.4644277 },
    { lat: 49.0027307, lng: 21.4643431 },
    { lat: 49.0026642, lng: 21.4640246 },
    { lat: 49.0024886, lng: 21.4637508 },
    { lat: 49.0022702, lng: 21.4637883 },
    { lat: 49.0020959, lng: 21.4636731 },
    { lat: 49.0019047, lng: 21.4638335 },
    { lat: 49.0017178, lng: 21.4637478 },
    { lat: 49.0013173, lng: 21.4633274 },
    { lat: 49.0011568, lng: 21.462945 },
    { lat: 49.0009094, lng: 21.4629504 },
    { lat: 49.0006775, lng: 21.4628326 },
    { lat: 49.0004142, lng: 21.4628334 },
    { lat: 49.000355, lng: 21.4625317 },
    { lat: 49.0001622, lng: 21.4624041 },
    { lat: 48.9999664, lng: 21.4624007 },
    { lat: 48.9998597, lng: 21.4623305 },
    { lat: 48.9997332, lng: 21.4618476 },
    { lat: 48.9995749, lng: 21.4615913 },
    { lat: 48.9995628, lng: 21.4611735 },
    { lat: 48.9993549, lng: 21.4607524 },
    { lat: 48.9989491, lng: 21.4603037 },
    { lat: 48.9987442, lng: 21.4598319 },
    { lat: 48.9985078, lng: 21.4596696 },
    { lat: 48.9979194, lng: 21.4590128 },
    { lat: 48.997953, lng: 21.4576521 },
    { lat: 48.9978617, lng: 21.4575271 },
    { lat: 48.9977322, lng: 21.4569395 },
    { lat: 48.9977087, lng: 21.4564792 },
    { lat: 48.9975714, lng: 21.4560559 },
    { lat: 48.997342, lng: 21.4557452 },
    { lat: 48.9968935, lng: 21.4553381 },
    { lat: 48.9964195, lng: 21.455132 },
    { lat: 48.9959483, lng: 21.4550607 },
    { lat: 48.9950271, lng: 21.4542546 },
    { lat: 48.994899, lng: 21.4538404 },
    { lat: 48.9953869, lng: 21.4533555 },
    { lat: 48.9956558, lng: 21.4532056 },
    { lat: 48.9958974, lng: 21.4531862 },
    { lat: 48.9962394, lng: 21.4529942 },
    { lat: 48.9970557, lng: 21.4528588 },
    { lat: 48.9972989, lng: 21.452749 },
    { lat: 48.9977367, lng: 21.4522538 },
    { lat: 48.9979285, lng: 21.4518848 },
  ],
  TovarnianskaPolianka: [
    { lat: 48.881253, lng: 21.78342 },
    { lat: 48.88146, lng: 21.783649 },
    { lat: 48.881487, lng: 21.783777 },
    { lat: 48.88152, lng: 21.783831 },
    { lat: 48.881543, lng: 21.783908 },
    { lat: 48.881602, lng: 21.783968 },
    { lat: 48.881909, lng: 21.784432 },
    { lat: 48.882138, lng: 21.785832 },
    { lat: 48.882198, lng: 21.787064 },
    { lat: 48.882248, lng: 21.787834 },
    { lat: 48.882268, lng: 21.788034 },
    { lat: 48.882275, lng: 21.788057 },
    { lat: 48.882272, lng: 21.78809 },
    { lat: 48.882604, lng: 21.788123 },
    { lat: 48.882755, lng: 21.788133 },
    { lat: 48.882988, lng: 21.788097 },
    { lat: 48.88317, lng: 21.788089 },
    { lat: 48.883489, lng: 21.788164 },
    { lat: 48.88379, lng: 21.788189 },
    { lat: 48.884437, lng: 21.787902 },
    { lat: 48.884562, lng: 21.787831 },
    { lat: 48.884684, lng: 21.787776 },
    { lat: 48.884862, lng: 21.787779 },
    { lat: 48.88512, lng: 21.787709 },
    { lat: 48.88531, lng: 21.787565 },
    { lat: 48.885469, lng: 21.787494 },
    { lat: 48.885746, lng: 21.787525 },
    { lat: 48.885882, lng: 21.78747 },
    { lat: 48.886047, lng: 21.787345 },
    { lat: 48.886284, lng: 21.787352 },
    { lat: 48.886502, lng: 21.787419 },
    { lat: 48.886872, lng: 21.787362 },
    { lat: 48.886934, lng: 21.787284 },
    { lat: 48.887068, lng: 21.787035 },
    { lat: 48.887325, lng: 21.786831 },
    { lat: 48.887424, lng: 21.786787 },
    { lat: 48.887608, lng: 21.786787 },
    { lat: 48.887718, lng: 21.786826 },
    { lat: 48.887805, lng: 21.786809 },
    { lat: 48.888153, lng: 21.786581 },
    { lat: 48.8883, lng: 21.786473 },
    { lat: 48.888618, lng: 21.78636 },
    { lat: 48.888942, lng: 21.786311 },
    { lat: 48.889173, lng: 21.786371 },
    { lat: 48.889616, lng: 21.786325 },
    { lat: 48.889828, lng: 21.78634 },
    { lat: 48.889976, lng: 21.786283 },
    { lat: 48.89, lng: 21.786309 },
    { lat: 48.890296, lng: 21.786576 },
    { lat: 48.890312, lng: 21.786588 },
    { lat: 48.890432, lng: 21.786307 },
    { lat: 48.89052, lng: 21.786166 },
    { lat: 48.890522, lng: 21.786026 },
    { lat: 48.890774, lng: 21.785361 },
    { lat: 48.890905, lng: 21.785169 },
    { lat: 48.891067, lng: 21.785069 },
    { lat: 48.891086, lng: 21.785091 },
    { lat: 48.89128, lng: 21.785077 },
    { lat: 48.891287, lng: 21.785039 },
    { lat: 48.891435, lng: 21.78503 },
    { lat: 48.891645, lng: 21.785078 },
    { lat: 48.891869, lng: 21.785069 },
    { lat: 48.892055, lng: 21.785104 },
    { lat: 48.892177, lng: 21.78518 },
    { lat: 48.892321, lng: 21.785221 },
    { lat: 48.892567, lng: 21.785235 },
    { lat: 48.892831, lng: 21.785319 },
    { lat: 48.893032, lng: 21.785362 },
    { lat: 48.893614, lng: 21.785616 },
    { lat: 48.894031, lng: 21.785778 },
    { lat: 48.894373, lng: 21.786002 },
    { lat: 48.89458, lng: 21.786164 },
    { lat: 48.894722, lng: 21.78627 },
    { lat: 48.89482, lng: 21.786323 },
    { lat: 48.895849, lng: 21.787029 },
    { lat: 48.895867, lng: 21.78715 },
    { lat: 48.895916, lng: 21.78706 },
    { lat: 48.89635, lng: 21.787032 },
    { lat: 48.896652, lng: 21.787034 },
    { lat: 48.897018, lng: 21.787165 },
    { lat: 48.897948, lng: 21.787818 },
    { lat: 48.898562, lng: 21.788326 },
    { lat: 48.898838, lng: 21.788579 },
    { lat: 48.90012, lng: 21.78902 },
    { lat: 48.901298, lng: 21.789199 },
    { lat: 48.901878, lng: 21.788746 },
    { lat: 48.903484, lng: 21.786793 },
    { lat: 48.904515, lng: 21.786091 },
    { lat: 48.90458, lng: 21.786045 },
    { lat: 48.904519, lng: 21.785633 },
    { lat: 48.904286, lng: 21.784867 },
    { lat: 48.903944, lng: 21.783631 },
    { lat: 48.903894, lng: 21.783375 },
    { lat: 48.903727, lng: 21.782903 },
    { lat: 48.903584, lng: 21.782501 },
    { lat: 48.903471, lng: 21.782135 },
    { lat: 48.903446, lng: 21.781929 },
    { lat: 48.903472, lng: 21.781768 },
    { lat: 48.903511, lng: 21.781435 },
    { lat: 48.903464, lng: 21.781067 },
    { lat: 48.903466, lng: 21.780817 },
    { lat: 48.903466, lng: 21.780421 },
    { lat: 48.903473, lng: 21.780156 },
    { lat: 48.903415, lng: 21.780059 },
    { lat: 48.903439, lng: 21.780033 },
    { lat: 48.903464, lng: 21.779812 },
    { lat: 48.903362, lng: 21.779199 },
    { lat: 48.9025035, lng: 21.7762372 },
    { lat: 48.9021482, lng: 21.7746116 },
    { lat: 48.9018817, lng: 21.773677 },
    { lat: 48.9018027, lng: 21.7728629 },
    { lat: 48.9017011, lng: 21.7723781 },
    { lat: 48.9016222, lng: 21.7707083 },
    { lat: 48.9015017, lng: 21.7707414 },
    { lat: 48.9008102, lng: 21.7713136 },
    { lat: 48.9007011, lng: 21.7710531 },
    { lat: 48.9005688, lng: 21.7711148 },
    { lat: 48.8999135, lng: 21.7697761 },
    { lat: 48.8986691, lng: 21.765588 },
    { lat: 48.899024, lng: 21.7654746 },
    { lat: 48.9007489, lng: 21.7643854 },
    { lat: 48.901812, lng: 21.763444 },
    { lat: 48.9023387, lng: 21.7628116 },
    { lat: 48.9031824, lng: 21.7613657 },
    { lat: 48.9035563, lng: 21.7601447 },
    { lat: 48.9036862, lng: 21.7599955 },
    { lat: 48.9040698, lng: 21.7599507 },
    { lat: 48.905114, lng: 21.7596169 },
    { lat: 48.9060738, lng: 21.7589426 },
    { lat: 48.9075771, lng: 21.7574278 },
    { lat: 48.9078376, lng: 21.7570686 },
    { lat: 48.9080581, lng: 21.7567255 },
    { lat: 48.9081821, lng: 21.7562723 },
    { lat: 48.9083599, lng: 21.756103 },
    { lat: 48.908483, lng: 21.7556506 },
    { lat: 48.9086706, lng: 21.7555956 },
    { lat: 48.9087211, lng: 21.7554634 },
    { lat: 48.9087472, lng: 21.7549626 },
    { lat: 48.9087167, lng: 21.7547041 },
    { lat: 48.9088341, lng: 21.7543361 },
    { lat: 48.9092481, lng: 21.7542511 },
    { lat: 48.9092874, lng: 21.7541221 },
    { lat: 48.9092857, lng: 21.7536326 },
    { lat: 48.9091773, lng: 21.753278 },
    { lat: 48.909015, lng: 21.7532271 },
    { lat: 48.9086634, lng: 21.7528601 },
    { lat: 48.9086474, lng: 21.7526823 },
    { lat: 48.9087285, lng: 21.7523905 },
    { lat: 48.9087008, lng: 21.7520657 },
    { lat: 48.9087826, lng: 21.7516301 },
    { lat: 48.9084323, lng: 21.751105 },
    { lat: 48.9081977, lng: 21.7512549 },
    { lat: 48.9077351, lng: 21.7517963 },
    { lat: 48.9066476, lng: 21.7527803 },
    { lat: 48.9063249, lng: 21.7531286 },
    { lat: 48.9062047, lng: 21.7533545 },
    { lat: 48.9055064, lng: 21.7535744 },
    { lat: 48.9036388, lng: 21.7549317 },
    { lat: 48.9030874, lng: 21.7550046 },
    { lat: 48.9001692, lng: 21.7550087 },
    { lat: 48.8987686, lng: 21.7550926 },
    { lat: 48.8954075, lng: 21.7573586 },
    { lat: 48.8930724, lng: 21.7623115 },
    { lat: 48.8928606, lng: 21.7626823 },
    { lat: 48.8924175, lng: 21.7629001 },
    { lat: 48.892012, lng: 21.7628826 },
    { lat: 48.8892217, lng: 21.7637103 },
    { lat: 48.8882586, lng: 21.7654096 },
    { lat: 48.8873973, lng: 21.7665869 },
    { lat: 48.8868484, lng: 21.7679919 },
    { lat: 48.8865645, lng: 21.7685725 },
    { lat: 48.8840821, lng: 21.7731143 },
    { lat: 48.8843294, lng: 21.7732756 },
    { lat: 48.8844068, lng: 21.7735286 },
    { lat: 48.8843279, lng: 21.7737808 },
    { lat: 48.8843672, lng: 21.7738159 },
    { lat: 48.8844385, lng: 21.7736665 },
    { lat: 48.8845371, lng: 21.7736065 },
    { lat: 48.8846283, lng: 21.7736947 },
    { lat: 48.8846481, lng: 21.7738911 },
    { lat: 48.8847741, lng: 21.7740201 },
    { lat: 48.8848997, lng: 21.774475 },
    { lat: 48.8846095, lng: 21.774691 },
    { lat: 48.8845053, lng: 21.7747069 },
    { lat: 48.8843436, lng: 21.774933 },
    { lat: 48.8840641, lng: 21.7758063 },
    { lat: 48.8841285, lng: 21.7762007 },
    { lat: 48.8837999, lng: 21.7764543 },
    { lat: 48.8837946, lng: 21.7765104 },
    { lat: 48.8830696, lng: 21.776625 },
    { lat: 48.8824414, lng: 21.7771188 },
    { lat: 48.8820251, lng: 21.7772794 },
    { lat: 48.8819195, lng: 21.7777665 },
    { lat: 48.8818119, lng: 21.7778134 },
    { lat: 48.8818096, lng: 21.7778972 },
    { lat: 48.8830805, lng: 21.7823825 },
    { lat: 48.8827138, lng: 21.7826841 },
    { lat: 48.8825709, lng: 21.7827292 },
    { lat: 48.882289, lng: 21.7826565 },
    { lat: 48.8822236, lng: 21.7828256 },
    { lat: 48.881691, lng: 21.7828935 },
    { lat: 48.8815082, lng: 21.7830435 },
    { lat: 48.881253, lng: 21.78342 },
  ],
  DlhéKlčovo: [
    { lat: 48.8413619, lng: 21.7184055 },
    { lat: 48.841099, lng: 21.7186242 },
    { lat: 48.8398771, lng: 21.7191256 },
    { lat: 48.8383005, lng: 21.7194465 },
    { lat: 48.8369831, lng: 21.7199444 },
    { lat: 48.8367144, lng: 21.7199995 },
    { lat: 48.8356252, lng: 21.7199476 },
    { lat: 48.834777, lng: 21.7201248 },
    { lat: 48.8347789, lng: 21.7200622 },
    { lat: 48.8342507, lng: 21.7204618 },
    { lat: 48.8341003, lng: 21.7198486 },
    { lat: 48.8331895, lng: 21.7202839 },
    { lat: 48.8331821, lng: 21.7204279 },
    { lat: 48.8328559, lng: 21.7205994 },
    { lat: 48.832903, lng: 21.7211779 },
    { lat: 48.8322838, lng: 21.7209539 },
    { lat: 48.8316898, lng: 21.7206233 },
    { lat: 48.8313907, lng: 21.720581 },
    { lat: 48.8311911, lng: 21.7206122 },
    { lat: 48.8300461, lng: 21.7211219 },
    { lat: 48.8302704, lng: 21.7222686 },
    { lat: 48.8295883, lng: 21.722889 },
    { lat: 48.8286026, lng: 21.7240162 },
    { lat: 48.828248, lng: 21.7246375 },
    { lat: 48.8284703, lng: 21.7251241 },
    { lat: 48.8287595, lng: 21.7259647 },
    { lat: 48.8282435, lng: 21.7263181 },
    { lat: 48.8279113, lng: 21.725041 },
    { lat: 48.8276973, lng: 21.7245788 },
    { lat: 48.8273609, lng: 21.7249127 },
    { lat: 48.8267207, lng: 21.72592 },
    { lat: 48.8264893, lng: 21.72551 },
    { lat: 48.8269441, lng: 21.7244277 },
    { lat: 48.8265761, lng: 21.7240415 },
    { lat: 48.8264654, lng: 21.7242825 },
    { lat: 48.8259779, lng: 21.7245595 },
    { lat: 48.8252636, lng: 21.7235987 },
    { lat: 48.8247904, lng: 21.7231918 },
    { lat: 48.8247076, lng: 21.7232969 },
    { lat: 48.8236411, lng: 21.7223738 },
    { lat: 48.8236165, lng: 21.7219583 },
    { lat: 48.8229244, lng: 21.7216174 },
    { lat: 48.8226842, lng: 21.721559 },
    { lat: 48.8224531, lng: 21.7217056 },
    { lat: 48.8218962, lng: 21.7214111 },
    { lat: 48.8214305, lng: 21.7213726 },
    { lat: 48.8213849, lng: 21.7215553 },
    { lat: 48.8209288, lng: 21.7217497 },
    { lat: 48.8207654, lng: 21.7217426 },
    { lat: 48.8201441, lng: 21.7223617 },
    { lat: 48.8196607, lng: 21.7217859 },
    { lat: 48.8193111, lng: 21.721172 },
    { lat: 48.8187267, lng: 21.7211129 },
    { lat: 48.81849, lng: 21.7200379 },
    { lat: 48.8158758, lng: 21.7213611 },
    { lat: 48.8158099, lng: 21.7214612 },
    { lat: 48.8153189, lng: 21.7216488 },
    { lat: 48.8149257, lng: 21.7218871 },
    { lat: 48.8071293, lng: 21.7257107 },
    { lat: 48.8069962, lng: 21.7255269 },
    { lat: 48.8062654, lng: 21.7257152 },
    { lat: 48.8058296, lng: 21.7255339 },
    { lat: 48.8055248, lng: 21.7253227 },
    { lat: 48.8052437, lng: 21.7253743 },
    { lat: 48.8047937, lng: 21.7255439 },
    { lat: 48.8045208, lng: 21.7257217 },
    { lat: 48.8043137, lng: 21.7257633 },
    { lat: 48.8042146, lng: 21.7255916 },
    { lat: 48.8040607, lng: 21.7256468 },
    { lat: 48.803949, lng: 21.7250058 },
    { lat: 48.8031079, lng: 21.7250175 },
    { lat: 48.8030115, lng: 21.7246134 },
    { lat: 48.8028969, lng: 21.7244455 },
    { lat: 48.8026508, lng: 21.724375 },
    { lat: 48.802554, lng: 21.7245394 },
    { lat: 48.8026483, lng: 21.7250386 },
    { lat: 48.8025398, lng: 21.7251438 },
    { lat: 48.8023223, lng: 21.7250508 },
    { lat: 48.8022081, lng: 21.7251092 },
    { lat: 48.8020539, lng: 21.7256094 },
    { lat: 48.8019591, lng: 21.7263536 },
    { lat: 48.8017409, lng: 21.7266931 },
    { lat: 48.800791, lng: 21.7260049 },
    { lat: 48.8000198, lng: 21.7259508 },
    { lat: 48.7998032, lng: 21.7253814 },
    { lat: 48.7997086, lng: 21.7248683 },
    { lat: 48.7994493, lng: 21.7241401 },
    { lat: 48.7994235, lng: 21.7239783 },
    { lat: 48.7994807, lng: 21.7237553 },
    { lat: 48.7991767, lng: 21.7236194 },
    { lat: 48.7989109, lng: 21.7237371 },
    { lat: 48.7983973, lng: 21.7238033 },
    { lat: 48.7974219, lng: 21.7234671 },
    { lat: 48.7973103, lng: 21.7236195 },
    { lat: 48.7972687, lng: 21.7238812 },
    { lat: 48.7972806, lng: 21.7241923 },
    { lat: 48.7974736, lng: 21.724806 },
    { lat: 48.7972275, lng: 21.7257821 },
    { lat: 48.7969056, lng: 21.7264464 },
    { lat: 48.7965882, lng: 21.7265229 },
    { lat: 48.7961734, lng: 21.7261591 },
    { lat: 48.7960279, lng: 21.7258925 },
    { lat: 48.7958984, lng: 21.724719 },
    { lat: 48.7960022, lng: 21.7234319 },
    { lat: 48.7958038, lng: 21.7229516 },
    { lat: 48.7956983, lng: 21.7223761 },
    { lat: 48.7957017, lng: 21.7220797 },
    { lat: 48.7958322, lng: 21.7217866 },
    { lat: 48.7958481, lng: 21.721579 },
    { lat: 48.7956569, lng: 21.720664 },
    { lat: 48.795259, lng: 21.7204662 },
    { lat: 48.7945233, lng: 21.7208015 },
    { lat: 48.7943117, lng: 21.7207168 },
    { lat: 48.793942, lng: 21.7198132 },
    { lat: 48.7935442, lng: 21.7196182 },
    { lat: 48.7931349, lng: 21.7190813 },
    { lat: 48.792734, lng: 21.7188662 },
    { lat: 48.7926509, lng: 21.7184489 },
    { lat: 48.7926977, lng: 21.7180574 },
    { lat: 48.792677, lng: 21.7175784 },
    { lat: 48.7924608, lng: 21.7175228 },
    { lat: 48.7922814, lng: 21.7175924 },
    { lat: 48.7918576, lng: 21.7180267 },
    { lat: 48.7918493, lng: 21.7185535 },
    { lat: 48.7919059, lng: 21.7193182 },
    { lat: 48.7919922, lng: 21.7196412 },
    { lat: 48.7925523, lng: 21.7202208 },
    { lat: 48.792679, lng: 21.7204161 },
    { lat: 48.7927431, lng: 21.7207507 },
    { lat: 48.7927257, lng: 21.7210676 },
    { lat: 48.7926448, lng: 21.7213202 },
    { lat: 48.7922754, lng: 21.7219356 },
    { lat: 48.7919299, lng: 21.7222219 },
    { lat: 48.7918331, lng: 21.7219274 },
    { lat: 48.791683, lng: 21.7217904 },
    { lat: 48.7911615, lng: 21.7215173 },
    { lat: 48.7911899, lng: 21.7226106 },
    { lat: 48.7881647, lng: 21.7242514 },
    { lat: 48.7884775, lng: 21.7249633 },
    { lat: 48.7887688, lng: 21.7258031 },
    { lat: 48.7874229, lng: 21.7262111 },
    { lat: 48.7877358, lng: 21.7279826 },
    { lat: 48.7877508, lng: 21.7282871 },
    { lat: 48.7877101, lng: 21.7285358 },
    { lat: 48.7875472, lng: 21.7290014 },
    { lat: 48.7876836, lng: 21.7298511 },
    { lat: 48.787852, lng: 21.7304705 },
    { lat: 48.7882274, lng: 21.7317187 },
    { lat: 48.7933984, lng: 21.7286608 },
    { lat: 48.7933409, lng: 21.7288638 },
    { lat: 48.7932919, lng: 21.7298067 },
    { lat: 48.7931583, lng: 21.7309107 },
    { lat: 48.793076, lng: 21.7311559 },
    { lat: 48.7932123, lng: 21.7327179 },
    { lat: 48.7943686, lng: 21.7398771 },
    { lat: 48.7948912, lng: 21.7421261 },
    { lat: 48.7955388, lng: 21.7442243 },
    { lat: 48.7968125, lng: 21.747871 },
    { lat: 48.7969566, lng: 21.7479971 },
    { lat: 48.7982493, lng: 21.7530702 },
    { lat: 48.7982396, lng: 21.7532217 },
    { lat: 48.798937, lng: 21.7557983 },
    { lat: 48.8009003, lng: 21.7560993 },
    { lat: 48.8032347, lng: 21.7566965 },
    { lat: 48.8059203, lng: 21.7572906 },
    { lat: 48.8068784, lng: 21.7574519 },
    { lat: 48.8076099, lng: 21.7574121 },
    { lat: 48.8096664, lng: 21.7577914 },
    { lat: 48.8112538, lng: 21.7584692 },
    { lat: 48.8116861, lng: 21.7588217 },
    { lat: 48.8126987, lng: 21.7593327 },
    { lat: 48.8137337, lng: 21.7598428 },
    { lat: 48.8142736, lng: 21.7597086 },
    { lat: 48.8148993, lng: 21.7589542 },
    { lat: 48.815187, lng: 21.7574556 },
    { lat: 48.815376, lng: 21.7557182 },
    { lat: 48.8157626, lng: 21.7550703 },
    { lat: 48.8164127, lng: 21.7546417 },
    { lat: 48.8175472, lng: 21.7547401 },
    { lat: 48.8179619, lng: 21.7550608 },
    { lat: 48.819629, lng: 21.7555198 },
    { lat: 48.8205049, lng: 21.7555051 },
    { lat: 48.8209227, lng: 21.7552691 },
    { lat: 48.8213524, lng: 21.7544129 },
    { lat: 48.8215732, lng: 21.752941 },
    { lat: 48.8215595, lng: 21.7517887 },
    { lat: 48.8225023, lng: 21.7501386 },
    { lat: 48.8231535, lng: 21.7503449 },
    { lat: 48.8238731, lng: 21.7506685 },
    { lat: 48.825008, lng: 21.7517144 },
    { lat: 48.8257977, lng: 21.7521851 },
    { lat: 48.8264351, lng: 21.752708 },
    { lat: 48.8266752, lng: 21.752905 },
    { lat: 48.8270294, lng: 21.7525539 },
    { lat: 48.8245725, lng: 21.7413097 },
    { lat: 48.8281483, lng: 21.7414953 },
    { lat: 48.8294401, lng: 21.7406237 },
    { lat: 48.8289338, lng: 21.7382569 },
    { lat: 48.8303231, lng: 21.7376234 },
    { lat: 48.830701, lng: 21.737365 },
    { lat: 48.8317822, lng: 21.7363951 },
    { lat: 48.8315301, lng: 21.7358902 },
    { lat: 48.8329352, lng: 21.7339019 },
    { lat: 48.8343586, lng: 21.7317663 },
    { lat: 48.8359553, lng: 21.7336708 },
    { lat: 48.8360621, lng: 21.7331596 },
    { lat: 48.8371243, lng: 21.7306871 },
    { lat: 48.8379252, lng: 21.7291616 },
    { lat: 48.8387052, lng: 21.7282966 },
    { lat: 48.8393151, lng: 21.7273345 },
    { lat: 48.8395176, lng: 21.728426 },
    { lat: 48.8408443, lng: 21.7275064 },
    { lat: 48.8410714, lng: 21.7285575 },
    { lat: 48.8410769, lng: 21.7285543 },
    { lat: 48.8415322, lng: 21.7280791 },
    { lat: 48.8420433, lng: 21.727876 },
    { lat: 48.842379, lng: 21.7275582 },
    { lat: 48.8424854, lng: 21.7273702 },
    { lat: 48.8431057, lng: 21.7268864 },
    { lat: 48.8434827, lng: 21.7268439 },
    { lat: 48.8441014, lng: 21.7266341 },
    { lat: 48.8441545, lng: 21.7267262 },
    { lat: 48.8451296, lng: 21.7253255 },
    { lat: 48.8446879, lng: 21.7241242 },
    { lat: 48.8434446, lng: 21.7250061 },
    { lat: 48.8434091, lng: 21.7247701 },
    { lat: 48.8434724, lng: 21.7243186 },
    { lat: 48.8434244, lng: 21.723998 },
    { lat: 48.8432868, lng: 21.7236471 },
    { lat: 48.84302, lng: 21.7233871 },
    { lat: 48.8429005, lng: 21.7217857 },
    { lat: 48.84284, lng: 21.7215437 },
    { lat: 48.8426415, lng: 21.7211912 },
    { lat: 48.8422573, lng: 21.7206998 },
    { lat: 48.8421958, lng: 21.7205083 },
    { lat: 48.8418304, lng: 21.7198482 },
    { lat: 48.8413619, lng: 21.7184055 },
  ],
  Hlinné: [
    { lat: 48.9474914, lng: 21.5050638 },
    { lat: 48.9469646, lng: 21.5042688 },
    { lat: 48.9466519, lng: 21.5036423 },
    { lat: 48.9461042, lng: 21.5027853 },
    { lat: 48.9455706, lng: 21.5017134 },
    { lat: 48.9450068, lng: 21.5008028 },
    { lat: 48.9444016, lng: 21.5008131 },
    { lat: 48.9439272, lng: 21.5034222 },
    { lat: 48.9438219, lng: 21.5054783 },
    { lat: 48.9438703, lng: 21.5063846 },
    { lat: 48.9438206, lng: 21.506889 },
    { lat: 48.9436883, lng: 21.5073742 },
    { lat: 48.9434279, lng: 21.5087407 },
    { lat: 48.9434875, lng: 21.5092508 },
    { lat: 48.943407, lng: 21.5098574 },
    { lat: 48.943404, lng: 21.5103801 },
    { lat: 48.943296, lng: 21.5108984 },
    { lat: 48.9433884, lng: 21.5113705 },
    { lat: 48.9433662, lng: 21.5127646 },
    { lat: 48.9434315, lng: 21.5129185 },
    { lat: 48.9433781, lng: 21.5132964 },
    { lat: 48.9433068, lng: 21.5134833 },
    { lat: 48.9432518, lng: 21.5141147 },
    { lat: 48.9432587, lng: 21.515027 },
    { lat: 48.9431658, lng: 21.5162271 },
    { lat: 48.943204, lng: 21.5180049 },
    { lat: 48.9429253, lng: 21.5192355 },
    { lat: 48.9430092, lng: 21.5195729 },
    { lat: 48.9429516, lng: 21.5199267 },
    { lat: 48.9428761, lng: 21.5200419 },
    { lat: 48.942675, lng: 21.5207216 },
    { lat: 48.9426024, lng: 21.5208212 },
    { lat: 48.9424661, lng: 21.5208541 },
    { lat: 48.9421765, lng: 21.5224569 },
    { lat: 48.9422893, lng: 21.5227004 },
    { lat: 48.942395, lng: 21.5231337 },
    { lat: 48.9422611, lng: 21.523373 },
    { lat: 48.9422167, lng: 21.5238698 },
    { lat: 48.942256, lng: 21.5240226 },
    { lat: 48.9423869, lng: 21.5240767 },
    { lat: 48.9424626, lng: 21.5242074 },
    { lat: 48.9424113, lng: 21.5245772 },
    { lat: 48.9422877, lng: 21.5248251 },
    { lat: 48.9423449, lng: 21.525335 },
    { lat: 48.942376, lng: 21.5264622 },
    { lat: 48.9421614, lng: 21.5277436 },
    { lat: 48.9419044, lng: 21.5283177 },
    { lat: 48.9417754, lng: 21.5283748 },
    { lat: 48.9416642, lng: 21.5285754 },
    { lat: 48.9415743, lng: 21.5284997 },
    { lat: 48.9415242, lng: 21.5287589 },
    { lat: 48.9411468, lng: 21.5298498 },
    { lat: 48.9410066, lng: 21.5299857 },
    { lat: 48.9407416, lng: 21.5300438 },
    { lat: 48.9406826, lng: 21.530382 },
    { lat: 48.9406084, lng: 21.5304258 },
    { lat: 48.9405632, lng: 21.5308515 },
    { lat: 48.9404971, lng: 21.5310226 },
    { lat: 48.940222, lng: 21.5313577 },
    { lat: 48.9402526, lng: 21.5316051 },
    { lat: 48.9400289, lng: 21.5322046 },
    { lat: 48.939684, lng: 21.5324956 },
    { lat: 48.9395585, lng: 21.533391 },
    { lat: 48.9395045, lng: 21.5334722 },
    { lat: 48.9394778, lng: 21.5339213 },
    { lat: 48.9393609, lng: 21.5340785 },
    { lat: 48.9393425, lng: 21.5342218 },
    { lat: 48.9392388, lng: 21.5343399 },
    { lat: 48.9391679, lng: 21.5343317 },
    { lat: 48.9391442, lng: 21.5344932 },
    { lat: 48.9386968, lng: 21.5352944 },
    { lat: 48.9386008, lng: 21.5356009 },
    { lat: 48.9384378, lng: 21.5357666 },
    { lat: 48.9383064, lng: 21.5358134 },
    { lat: 48.9381897, lng: 21.5359886 },
    { lat: 48.9380636, lng: 21.53598 },
    { lat: 48.9379777, lng: 21.5360858 },
    { lat: 48.9378696, lng: 21.5364067 },
    { lat: 48.9376314, lng: 21.5365683 },
    { lat: 48.9375528, lng: 21.5367724 },
    { lat: 48.9380472, lng: 21.5381062 },
    { lat: 48.9389225, lng: 21.5388593 },
    { lat: 48.9390167, lng: 21.5394787 },
    { lat: 48.9390227, lng: 21.5402056 },
    { lat: 48.9390678, lng: 21.5405355 },
    { lat: 48.9391688, lng: 21.540997 },
    { lat: 48.9394928, lng: 21.5420299 },
    { lat: 48.9396013, lng: 21.5426069 },
    { lat: 48.939511, lng: 21.5435895 },
    { lat: 48.9396126, lng: 21.5438398 },
    { lat: 48.9397767, lng: 21.5445925 },
    { lat: 48.939878, lng: 21.5455486 },
    { lat: 48.9399493, lng: 21.5457577 },
    { lat: 48.9399257, lng: 21.5459152 },
    { lat: 48.9399609, lng: 21.54621 },
    { lat: 48.9400765, lng: 21.5463982 },
    { lat: 48.9400367, lng: 21.5472992 },
    { lat: 48.9401469, lng: 21.5476216 },
    { lat: 48.9401131, lng: 21.5479767 },
    { lat: 48.9401742, lng: 21.5481459 },
    { lat: 48.9401829, lng: 21.5483365 },
    { lat: 48.94011, lng: 21.548745 },
    { lat: 48.9399552, lng: 21.5489988 },
    { lat: 48.9397568, lng: 21.5496623 },
    { lat: 48.939611, lng: 21.549917 },
    { lat: 48.9395536, lng: 21.5502704 },
    { lat: 48.9395721, lng: 21.5504936 },
    { lat: 48.9394309, lng: 21.5507873 },
    { lat: 48.9393248, lng: 21.5513371 },
    { lat: 48.9393387, lng: 21.5516705 },
    { lat: 48.9392606, lng: 21.5519282 },
    { lat: 48.9392408, lng: 21.5523468 },
    { lat: 48.9388666, lng: 21.552923 },
    { lat: 48.9384832, lng: 21.5532446 },
    { lat: 48.9383097, lng: 21.5535211 },
    { lat: 48.9380734, lng: 21.553763 },
    { lat: 48.9380668, lng: 21.5539216 },
    { lat: 48.9378614, lng: 21.5541804 },
    { lat: 48.9377401, lng: 21.5545866 },
    { lat: 48.9376119, lng: 21.5547467 },
    { lat: 48.9374597, lng: 21.5552937 },
    { lat: 48.9374108, lng: 21.5556401 },
    { lat: 48.9371447, lng: 21.5563796 },
    { lat: 48.9372585, lng: 21.5565801 },
    { lat: 48.9373688, lng: 21.5566621 },
    { lat: 48.9373825, lng: 21.5571483 },
    { lat: 48.9374476, lng: 21.5573072 },
    { lat: 48.9373824, lng: 21.5575022 },
    { lat: 48.9374887, lng: 21.5576879 },
    { lat: 48.9374032, lng: 21.5579235 },
    { lat: 48.9374356, lng: 21.557993 },
    { lat: 48.9375347, lng: 21.5579569 },
    { lat: 48.9376711, lng: 21.5582866 },
    { lat: 48.9375817, lng: 21.5584262 },
    { lat: 48.9376615, lng: 21.5586236 },
    { lat: 48.9378013, lng: 21.5586417 },
    { lat: 48.9378478, lng: 21.5585671 },
    { lat: 48.9381306, lng: 21.5589074 },
    { lat: 48.9380179, lng: 21.5592582 },
    { lat: 48.9380976, lng: 21.5594088 },
    { lat: 48.9381032, lng: 21.5599695 },
    { lat: 48.9380546, lng: 21.5600844 },
    { lat: 48.9382525, lng: 21.5603979 },
    { lat: 48.9382571, lng: 21.5606768 },
    { lat: 48.9383643, lng: 21.5608924 },
    { lat: 48.9383676, lng: 21.561011 },
    { lat: 48.9382844, lng: 21.5610855 },
    { lat: 48.9383694, lng: 21.5615102 },
    { lat: 48.9384052, lng: 21.5615844 },
    { lat: 48.9386466, lng: 21.5617273 },
    { lat: 48.9386227, lng: 21.5620402 },
    { lat: 48.9388218, lng: 21.5628014 },
    { lat: 48.9387533, lng: 21.5632386 },
    { lat: 48.9388547, lng: 21.5636103 },
    { lat: 48.9387095, lng: 21.5641239 },
    { lat: 48.9389647, lng: 21.5644363 },
    { lat: 48.9389758, lng: 21.564631 },
    { lat: 48.9389243, lng: 21.5646837 },
    { lat: 48.9391739, lng: 21.5650755 },
    { lat: 48.9391489, lng: 21.5653044 },
    { lat: 48.9392483, lng: 21.5653574 },
    { lat: 48.9393674, lng: 21.5656786 },
    { lat: 48.9393064, lng: 21.5658658 },
    { lat: 48.939305, lng: 21.5660584 },
    { lat: 48.9394229, lng: 21.5663278 },
    { lat: 48.9393518, lng: 21.5670471 },
    { lat: 48.9394319, lng: 21.5673708 },
    { lat: 48.9393097, lng: 21.5678286 },
    { lat: 48.9394592, lng: 21.568025 },
    { lat: 48.9374817, lng: 21.568119 },
    { lat: 48.9375353, lng: 21.5693477 },
    { lat: 48.9376322, lng: 21.5697952 },
    { lat: 48.9378156, lng: 21.5715598 },
    { lat: 48.9379728, lng: 21.5723113 },
    { lat: 48.9380496, lng: 21.5732006 },
    { lat: 48.9374747, lng: 21.5736855 },
    { lat: 48.9373083, lng: 21.5739303 },
    { lat: 48.9373106, lng: 21.5741752 },
    { lat: 48.9373601, lng: 21.5748975 },
    { lat: 48.9375143, lng: 21.5760599 },
    { lat: 48.9378693, lng: 21.5776911 },
    { lat: 48.9361378, lng: 21.5786151 },
    { lat: 48.9344571, lng: 21.5796285 },
    { lat: 48.934278, lng: 21.5798247 },
    { lat: 48.9342574, lng: 21.5807321 },
    { lat: 48.9345357, lng: 21.5813508 },
    { lat: 48.9347403, lng: 21.5821084 },
    { lat: 48.9350826, lng: 21.5830082 },
    { lat: 48.9349684, lng: 21.5831498 },
    { lat: 48.9352189, lng: 21.5840551 },
    { lat: 48.9355186, lng: 21.5845135 },
    { lat: 48.93565, lng: 21.5846268 },
    { lat: 48.9364391, lng: 21.583759 },
    { lat: 48.9367313, lng: 21.583509 },
    { lat: 48.937455, lng: 21.5831489 },
    { lat: 48.9379199, lng: 21.5849294 },
    { lat: 48.9388587, lng: 21.5896668 },
    { lat: 48.9390668, lng: 21.5905105 },
    { lat: 48.9391501, lng: 21.5904271 },
    { lat: 48.9398751, lng: 21.5914957 },
    { lat: 48.9406568, lng: 21.5914079 },
    { lat: 48.9409406, lng: 21.5912956 },
    { lat: 48.9411439, lng: 21.5917739 },
    { lat: 48.9413977, lng: 21.5921996 },
    { lat: 48.9416839, lng: 21.592936 },
    { lat: 48.9427327, lng: 21.5925842 },
    { lat: 48.9429393, lng: 21.5933726 },
    { lat: 48.9438754, lng: 21.5930151 },
    { lat: 48.9443953, lng: 21.5954381 },
    { lat: 48.9433879, lng: 21.5956255 },
    { lat: 48.9434942, lng: 21.5967754 },
    { lat: 48.9435443, lng: 21.5969984 },
    { lat: 48.943569, lng: 21.5969986 },
    { lat: 48.9443868, lng: 21.5970382 },
    { lat: 48.9455291, lng: 21.597694 },
    { lat: 48.9469323, lng: 21.5981907 },
    { lat: 48.9472616, lng: 21.5980522 },
    { lat: 48.9474645, lng: 21.5978215 },
    { lat: 48.9475345, lng: 21.5967024 },
    { lat: 48.9472746, lng: 21.5948023 },
    { lat: 48.9494622, lng: 21.5935222 },
    { lat: 48.9493037, lng: 21.5924363 },
    { lat: 48.9492141, lng: 21.5922062 },
    { lat: 48.9497036, lng: 21.5923799 },
    { lat: 48.9504768, lng: 21.5923821 },
    { lat: 48.9509558, lng: 21.5920933 },
    { lat: 48.9511877, lng: 21.5923365 },
    { lat: 48.9528923, lng: 21.5919825 },
    { lat: 48.9533036, lng: 21.594628 },
    { lat: 48.954021, lng: 21.5945354 },
    { lat: 48.9536734, lng: 21.5935676 },
    { lat: 48.9564989, lng: 21.5919212 },
    { lat: 48.9571677, lng: 21.591058 },
    { lat: 48.9576862, lng: 21.5907297 },
    { lat: 48.9576919, lng: 21.5908074 },
    { lat: 48.9581379, lng: 21.5907032 },
    { lat: 48.9583095, lng: 21.5905246 },
    { lat: 48.9583975, lng: 21.5902884 },
    { lat: 48.9584543, lng: 21.5898655 },
    { lat: 48.9584272, lng: 21.5892557 },
    { lat: 48.958626, lng: 21.5888781 },
    { lat: 48.9589163, lng: 21.5886457 },
    { lat: 48.9593963, lng: 21.5891093 },
    { lat: 48.9595242, lng: 21.5890971 },
    { lat: 48.9600619, lng: 21.5897038 },
    { lat: 48.9604238, lng: 21.5897639 },
    { lat: 48.960697, lng: 21.5896755 },
    { lat: 48.9613234, lng: 21.589144 },
    { lat: 48.9615671, lng: 21.5888018 },
    { lat: 48.9622211, lng: 21.5844599 },
    { lat: 48.963183, lng: 21.5842984 },
    { lat: 48.9634825, lng: 21.5831366 },
    { lat: 48.9634837, lng: 21.5828587 },
    { lat: 48.9642367, lng: 21.5829527 },
    { lat: 48.9646064, lng: 21.5831813 },
    { lat: 48.9653811, lng: 21.5840487 },
    { lat: 48.9661959, lng: 21.584625 },
    { lat: 48.9669331, lng: 21.584709 },
    { lat: 48.9678041, lng: 21.5838662 },
    { lat: 48.9687338, lng: 21.5832806 },
    { lat: 48.9686881, lng: 21.5829759 },
    { lat: 48.9684635, lng: 21.5825851 },
    { lat: 48.9683994, lng: 21.5817398 },
    { lat: 48.9685614, lng: 21.5815807 },
    { lat: 48.9684779, lng: 21.5803246 },
    { lat: 48.9683074, lng: 21.5792574 },
    { lat: 48.9681747, lng: 21.5776506 },
    { lat: 48.9684109, lng: 21.5777657 },
    { lat: 48.9685941, lng: 21.5777177 },
    { lat: 48.9687513, lng: 21.5771099 },
    { lat: 48.9686836, lng: 21.575448 },
    { lat: 48.9688663, lng: 21.5753471 },
    { lat: 48.9692289, lng: 21.5755145 },
    { lat: 48.9693306, lng: 21.5754095 },
    { lat: 48.9691422, lng: 21.5750696 },
    { lat: 48.9694309, lng: 21.5746538 },
    { lat: 48.9696857, lng: 21.5745926 },
    { lat: 48.9696589, lng: 21.5742749 },
    { lat: 48.9694579, lng: 21.5741357 },
    { lat: 48.969438, lng: 21.573862 },
    { lat: 48.9695046, lng: 21.5737921 },
    { lat: 48.9697889, lng: 21.5737412 },
    { lat: 48.9698591, lng: 21.5739146 },
    { lat: 48.9700479, lng: 21.5735837 },
    { lat: 48.9702799, lng: 21.5725098 },
    { lat: 48.970267, lng: 21.5720902 },
    { lat: 48.9699137, lng: 21.571563 },
    { lat: 48.9695399, lng: 21.5706751 },
    { lat: 48.969638, lng: 21.5702809 },
    { lat: 48.969785, lng: 21.5701872 },
    { lat: 48.9698793, lng: 21.570218 },
    { lat: 48.9698585, lng: 21.5699572 },
    { lat: 48.9696457, lng: 21.5697511 },
    { lat: 48.9694313, lng: 21.5693671 },
    { lat: 48.9692965, lng: 21.5692595 },
    { lat: 48.9692771, lng: 21.568966 },
    { lat: 48.9695125, lng: 21.5688161 },
    { lat: 48.9696519, lng: 21.5686521 },
    { lat: 48.9695597, lng: 21.5682555 },
    { lat: 48.9693367, lng: 21.5677196 },
    { lat: 48.9693048, lng: 21.5673034 },
    { lat: 48.9693686, lng: 21.5670901 },
    { lat: 48.9691211, lng: 21.566497 },
    { lat: 48.9691148, lng: 21.5661121 },
    { lat: 48.9689913, lng: 21.565434 },
    { lat: 48.9690438, lng: 21.5648619 },
    { lat: 48.9685828, lng: 21.564514 },
    { lat: 48.9684236, lng: 21.5642085 },
    { lat: 48.9686383, lng: 21.5639614 },
    { lat: 48.9685598, lng: 21.5638548 },
    { lat: 48.9679733, lng: 21.5636801 },
    { lat: 48.9681667, lng: 21.563154 },
    { lat: 48.9677033, lng: 21.5629234 },
    { lat: 48.9676, lng: 21.5625706 },
    { lat: 48.9675688, lng: 21.5620992 },
    { lat: 48.9676267, lng: 21.5615157 },
    { lat: 48.9675912, lng: 21.5614593 },
    { lat: 48.9671542, lng: 21.561363 },
    { lat: 48.967102, lng: 21.5612672 },
    { lat: 48.9673182, lng: 21.5609928 },
    { lat: 48.967325, lng: 21.5607539 },
    { lat: 48.9668262, lng: 21.5603387 },
    { lat: 48.9667169, lng: 21.560133 },
    { lat: 48.9667572, lng: 21.5594039 },
    { lat: 48.967102, lng: 21.5591061 },
    { lat: 48.9671244, lng: 21.5588623 },
    { lat: 48.966832, lng: 21.5577457 },
    { lat: 48.9666297, lng: 21.5573926 },
    { lat: 48.9667066, lng: 21.5570101 },
    { lat: 48.9658483, lng: 21.5555319 },
    { lat: 48.9655976, lng: 21.5556631 },
    { lat: 48.9651015, lng: 21.5552259 },
    { lat: 48.9643677, lng: 21.5548973 },
    { lat: 48.9640716, lng: 21.5549709 },
    { lat: 48.9635722, lng: 21.5530226 },
    { lat: 48.9633957, lng: 21.5531415 },
    { lat: 48.9632666, lng: 21.5525945 },
    { lat: 48.9629342, lng: 21.5517836 },
    { lat: 48.9626967, lng: 21.5509372 },
    { lat: 48.9625263, lng: 21.5497596 },
    { lat: 48.9622549, lng: 21.5491166 },
    { lat: 48.9623414, lng: 21.5479932 },
    { lat: 48.9622653, lng: 21.547528 },
    { lat: 48.9622953, lng: 21.5469646 },
    { lat: 48.9622674, lng: 21.5466929 },
    { lat: 48.9622215, lng: 21.5464201 },
    { lat: 48.9620941, lng: 21.5463665 },
    { lat: 48.9619426, lng: 21.5457867 },
    { lat: 48.9620383, lng: 21.5457117 },
    { lat: 48.9614841, lng: 21.5440549 },
    { lat: 48.9608433, lng: 21.5431948 },
    { lat: 48.9603414, lng: 21.5423206 },
    { lat: 48.9593823, lng: 21.5415031 },
    { lat: 48.9585677, lng: 21.5409469 },
    { lat: 48.9577817, lng: 21.5399714 },
    { lat: 48.957552, lng: 21.5393294 },
    { lat: 48.9572167, lng: 21.5392453 },
    { lat: 48.9571163, lng: 21.5392798 },
    { lat: 48.9569809, lng: 21.5391855 },
    { lat: 48.9562625, lng: 21.538293 },
    { lat: 48.9561355, lng: 21.5382551 },
    { lat: 48.9558882, lng: 21.5379892 },
    { lat: 48.9554515, lng: 21.5373879 },
    { lat: 48.9552982, lng: 21.5372533 },
    { lat: 48.9550825, lng: 21.5368303 },
    { lat: 48.9551085, lng: 21.5363553 },
    { lat: 48.9550345, lng: 21.5356928 },
    { lat: 48.9549288, lng: 21.5354975 },
    { lat: 48.9548806, lng: 21.5349872 },
    { lat: 48.9545835, lng: 21.5337898 },
    { lat: 48.9545054, lng: 21.5332626 },
    { lat: 48.9543739, lng: 21.5331054 },
    { lat: 48.9543006, lng: 21.5323111 },
    { lat: 48.9543341, lng: 21.5312632 },
    { lat: 48.9543021, lng: 21.530777 },
    { lat: 48.953518, lng: 21.5285207 },
    { lat: 48.9533955, lng: 21.5275371 },
    { lat: 48.952888, lng: 21.5257159 },
    { lat: 48.9528316, lng: 21.5249181 },
    { lat: 48.95243, lng: 21.5241278 },
    { lat: 48.9523725, lng: 21.5238864 },
    { lat: 48.9517146, lng: 21.5229479 },
    { lat: 48.9513643, lng: 21.5220642 },
    { lat: 48.9508748, lng: 21.5211899 },
    { lat: 48.9507018, lng: 21.5205297 },
    { lat: 48.9504897, lng: 21.5201935 },
    { lat: 48.9498435, lng: 21.5197405 },
    { lat: 48.9494674, lng: 21.5195751 },
    { lat: 48.9494617, lng: 21.5191416 },
    { lat: 48.9490965, lng: 21.5183212 },
    { lat: 48.9491046, lng: 21.5178209 },
    { lat: 48.9492049, lng: 21.5174281 },
    { lat: 48.9495627, lng: 21.5167624 },
    { lat: 48.9496563, lng: 21.5160596 },
    { lat: 48.9493287, lng: 21.5155746 },
    { lat: 48.9491409, lng: 21.5151839 },
    { lat: 48.94888, lng: 21.5137724 },
    { lat: 48.9485472, lng: 21.5129932 },
    { lat: 48.948312, lng: 21.5122029 },
    { lat: 48.9482029, lng: 21.5113392 },
    { lat: 48.9482416, lng: 21.5109916 },
    { lat: 48.9485456, lng: 21.5095837 },
    { lat: 48.9485018, lng: 21.5091166 },
    { lat: 48.9478919, lng: 21.5075016 },
    { lat: 48.9473769, lng: 21.5057003 },
    { lat: 48.9475012, lng: 21.5054043 },
    { lat: 48.9474914, lng: 21.5050638 },
  ],
  Girovce: [
    { lat: 49.020812, lng: 21.774112 },
    { lat: 49.020971, lng: 21.774008 },
    { lat: 49.021216, lng: 21.773474 },
    { lat: 49.021374, lng: 21.773133 },
    { lat: 49.022336, lng: 21.771649 },
    { lat: 49.022418, lng: 21.771523 },
    { lat: 49.022432, lng: 21.771496 },
    { lat: 49.022447, lng: 21.77147 },
    { lat: 49.022462, lng: 21.771441 },
    { lat: 49.023234, lng: 21.769871 },
    { lat: 49.023767, lng: 21.768012 },
    { lat: 49.023852, lng: 21.766595 },
    { lat: 49.023875, lng: 21.765182 },
    { lat: 49.023672, lng: 21.764571 },
    { lat: 49.02365, lng: 21.764503 },
    { lat: 49.023558, lng: 21.764227 },
    { lat: 49.023524, lng: 21.764127 },
    { lat: 49.02366, lng: 21.764091 },
    { lat: 49.023813, lng: 21.763798 },
    { lat: 49.023988, lng: 21.763799 },
    { lat: 49.024491, lng: 21.763619 },
    { lat: 49.024626, lng: 21.763472 },
    { lat: 49.024816, lng: 21.763458 },
    { lat: 49.025492, lng: 21.763119 },
    { lat: 49.026507, lng: 21.762443 },
    { lat: 49.026671, lng: 21.762264 },
    { lat: 49.026499, lng: 21.761666 },
    { lat: 49.026488, lng: 21.761637 },
    { lat: 49.026628, lng: 21.761598 },
    { lat: 49.026764, lng: 21.761638 },
    { lat: 49.026982, lng: 21.761601 },
    { lat: 49.027186, lng: 21.76147 },
    { lat: 49.027566, lng: 21.761082 },
    { lat: 49.027678, lng: 21.760968 },
    { lat: 49.028337, lng: 21.759655 },
    { lat: 49.028312, lng: 21.758977 },
    { lat: 49.028299, lng: 21.758658 },
    { lat: 49.028131, lng: 21.758618 },
    { lat: 49.027847, lng: 21.758354 },
    { lat: 49.027582, lng: 21.757704 },
    { lat: 49.027311, lng: 21.75722 },
    { lat: 49.027236, lng: 21.756771 },
    { lat: 49.027246, lng: 21.756736 },
    { lat: 49.027412, lng: 21.756121 },
    { lat: 49.02755, lng: 21.755766 },
    { lat: 49.027684, lng: 21.754913 },
    { lat: 49.02791, lng: 21.754718 },
    { lat: 49.027823, lng: 21.754475 },
    { lat: 49.027292, lng: 21.754344 },
    { lat: 49.027082, lng: 21.754141 },
    { lat: 49.026922, lng: 21.753286 },
    { lat: 49.02655, lng: 21.752512 },
    { lat: 49.026587, lng: 21.752383 },
    { lat: 49.0254644, lng: 21.7522137 },
    { lat: 49.0247805, lng: 21.7518723 },
    { lat: 49.0242142, lng: 21.7512653 },
    { lat: 49.0238884, lng: 21.7511494 },
    { lat: 49.0226317, lng: 21.7500004 },
    { lat: 49.0223014, lng: 21.7498457 },
    { lat: 49.0218538, lng: 21.7494297 },
    { lat: 49.0214458, lng: 21.7494833 },
    { lat: 49.0211359, lng: 21.7495076 },
    { lat: 49.0203637, lng: 21.7493556 },
    { lat: 49.019479, lng: 21.7488778 },
    { lat: 49.0192954, lng: 21.7488611 },
    { lat: 49.0192219, lng: 21.7487205 },
    { lat: 49.0184199, lng: 21.7482989 },
    { lat: 49.0181546, lng: 21.7480908 },
    { lat: 49.0177234, lng: 21.7474839 },
    { lat: 49.0175581, lng: 21.7474453 },
    { lat: 49.0172728, lng: 21.7471685 },
    { lat: 49.0169843, lng: 21.7467679 },
    { lat: 49.0164324, lng: 21.7465279 },
    { lat: 49.0154744, lng: 21.7463769 },
    { lat: 49.0150628, lng: 21.746038 },
    { lat: 49.0140654, lng: 21.7448738 },
    { lat: 49.0137662, lng: 21.7447459 },
    { lat: 49.0134849, lng: 21.7451269 },
    { lat: 49.0124216, lng: 21.7456626 },
    { lat: 49.0122644, lng: 21.7456715 },
    { lat: 49.0114638, lng: 21.7459925 },
    { lat: 49.0109178, lng: 21.7446115 },
    { lat: 49.0108567, lng: 21.7444314 },
    { lat: 49.0106449, lng: 21.7446314 },
    { lat: 49.0103319, lng: 21.7451788 },
    { lat: 49.0098433, lng: 21.74567 },
    { lat: 49.0095262, lng: 21.7458463 },
    { lat: 49.0094024, lng: 21.7457181 },
    { lat: 49.00892, lng: 21.7458501 },
    { lat: 49.0089371, lng: 21.7463257 },
    { lat: 49.0082109, lng: 21.7461781 },
    { lat: 49.0079454, lng: 21.7462198 },
    { lat: 49.0075394, lng: 21.746431 },
    { lat: 49.0073786, lng: 21.746653 },
    { lat: 49.0072505, lng: 21.7469828 },
    { lat: 49.0071195, lng: 21.7473199 },
    { lat: 49.0070279, lng: 21.7479379 },
    { lat: 49.0070362, lng: 21.7500326 },
    { lat: 49.0070602, lng: 21.7505543 },
    { lat: 49.0071535, lng: 21.750856 },
    { lat: 49.0075459, lng: 21.7513516 },
    { lat: 49.0076591, lng: 21.7514267 },
    { lat: 49.007741, lng: 21.7518815 },
    { lat: 49.0077987, lng: 21.7518973 },
    { lat: 49.0077892, lng: 21.7522602 },
    { lat: 49.0078738, lng: 21.7525649 },
    { lat: 49.0081804, lng: 21.7532733 },
    { lat: 49.0084927, lng: 21.7527475 },
    { lat: 49.008625, lng: 21.7526853 },
    { lat: 49.0087901, lng: 21.7528848 },
    { lat: 49.0087579, lng: 21.7531574 },
    { lat: 49.008868, lng: 21.7534964 },
    { lat: 49.00885, lng: 21.7537079 },
    { lat: 49.0089076, lng: 21.7539247 },
    { lat: 49.0089976, lng: 21.7539673 },
    { lat: 49.0094775, lng: 21.7538652 },
    { lat: 49.0097002, lng: 21.7531862 },
    { lat: 49.0099633, lng: 21.753345 },
    { lat: 49.0100744, lng: 21.7536775 },
    { lat: 49.0100076, lng: 21.75404 },
    { lat: 49.0101904, lng: 21.7540291 },
    { lat: 49.0107529, lng: 21.7544022 },
    { lat: 49.0110699, lng: 21.7544474 },
    { lat: 49.0112484, lng: 21.7546794 },
    { lat: 49.0112611, lng: 21.7548672 },
    { lat: 49.0113912, lng: 21.7551051 },
    { lat: 49.0120081, lng: 21.7553817 },
    { lat: 49.0121868, lng: 21.7551597 },
    { lat: 49.0121484, lng: 21.7549022 },
    { lat: 49.0123679, lng: 21.7549487 },
    { lat: 49.0126777, lng: 21.7552609 },
    { lat: 49.0130272, lng: 21.755838 },
    { lat: 49.0134195, lng: 21.7560015 },
    { lat: 49.013359, lng: 21.7568147 },
    { lat: 49.013391, lng: 21.7575184 },
    { lat: 49.0133118, lng: 21.7586536 },
    { lat: 49.0122209, lng: 21.7590953 },
    { lat: 49.0120223, lng: 21.7594916 },
    { lat: 49.0117239, lng: 21.7597431 },
    { lat: 49.011619, lng: 21.7599412 },
    { lat: 49.0117272, lng: 21.7601195 },
    { lat: 49.0115919, lng: 21.7604069 },
    { lat: 49.0116001, lng: 21.7608042 },
    { lat: 49.0112841, lng: 21.761704 },
    { lat: 49.0109413, lng: 21.7622312 },
    { lat: 49.0109093, lng: 21.7623854 },
    { lat: 49.0109819, lng: 21.7631723 },
    { lat: 49.0110994, lng: 21.7632719 },
    { lat: 49.0111222, lng: 21.7635052 },
    { lat: 49.0114276, lng: 21.7638213 },
    { lat: 49.0114561, lng: 21.7639655 },
    { lat: 49.011168, lng: 21.764122 },
    { lat: 49.011195, lng: 21.764216 },
    { lat: 49.011172, lng: 21.764403 },
    { lat: 49.011458, lng: 21.764508 },
    { lat: 49.011507, lng: 21.764526 },
    { lat: 49.011603, lng: 21.764693 },
    { lat: 49.011708, lng: 21.764872 },
    { lat: 49.011848, lng: 21.765045 },
    { lat: 49.011744, lng: 21.765486 },
    { lat: 49.011653, lng: 21.765707 },
    { lat: 49.011591, lng: 21.765916 },
    { lat: 49.011728, lng: 21.766082 },
    { lat: 49.011907, lng: 21.766625 },
    { lat: 49.012075, lng: 21.766759 },
    { lat: 49.012217, lng: 21.76681 },
    { lat: 49.012317, lng: 21.766847 },
    { lat: 49.012507, lng: 21.767035 },
    { lat: 49.012616, lng: 21.767206 },
    { lat: 49.012678, lng: 21.767622 },
    { lat: 49.012878, lng: 21.767583 },
    { lat: 49.012881, lng: 21.767415 },
    { lat: 49.01306, lng: 21.767091 },
    { lat: 49.013068, lng: 21.766795 },
    { lat: 49.012865, lng: 21.766708 },
    { lat: 49.012815, lng: 21.766435 },
    { lat: 49.013033, lng: 21.766204 },
    { lat: 49.013327, lng: 21.766144 },
    { lat: 49.013441, lng: 21.766295 },
    { lat: 49.013449, lng: 21.766512 },
    { lat: 49.013534, lng: 21.76663 },
    { lat: 49.013749, lng: 21.766234 },
    { lat: 49.013643, lng: 21.765867 },
    { lat: 49.01387, lng: 21.765962 },
    { lat: 49.014026, lng: 21.766087 },
    { lat: 49.014172, lng: 21.766302 },
    { lat: 49.014366, lng: 21.766436 },
    { lat: 49.014554, lng: 21.766443 },
    { lat: 49.014867, lng: 21.766719 },
    { lat: 49.015137, lng: 21.767034 },
    { lat: 49.015537, lng: 21.767521 },
    { lat: 49.015794, lng: 21.767762 },
    { lat: 49.016202, lng: 21.768061 },
    { lat: 49.016306, lng: 21.768287 },
    { lat: 49.016314, lng: 21.768589 },
    { lat: 49.016423, lng: 21.768907 },
    { lat: 49.016829, lng: 21.769252 },
    { lat: 49.017175, lng: 21.769416 },
    { lat: 49.017206, lng: 21.769457 },
    { lat: 49.017458, lng: 21.76968 },
    { lat: 49.017905, lng: 21.770215 },
    { lat: 49.018182, lng: 21.770647 },
    { lat: 49.01856, lng: 21.771024 },
    { lat: 49.018556, lng: 21.771346 },
    { lat: 49.018783, lng: 21.771703 },
    { lat: 49.019073, lng: 21.772021 },
    { lat: 49.01943, lng: 21.772372 },
    { lat: 49.019636, lng: 21.772509 },
    { lat: 49.019879, lng: 21.772895 },
    { lat: 49.020147, lng: 21.773181 },
    { lat: 49.02048, lng: 21.773489 },
    { lat: 49.020648, lng: 21.773847 },
    { lat: 49.020812, lng: 21.774112 },
  ],
};

export default UnitsVranovNadTopľou;
