import clsx from "clsx";
// import { useMediaQuery } from "usehooks-ts";

export default function MessageInput({ inputValues, setInputValues, size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  return (
    <>
      <div
        className={clsx("px-4 w-full", {
          // "flex flex-col w-1/2": !isSmallDevice && size.full,
          // "w-full": isSmallDevice,
        })}
      >
        <div className="w-full text-start">Napíš správu</div>
        <textarea
          onClick={(event) => event.stopPropagation()}
          value={inputValues.writeMessage}
          onChange={(e) => setInputValues({ ...inputValues, writeMessage: e.target.value })}
          placeholder="Napíš správu"
          type="text"
          cols={37}
          rows={6}
          className={clsx("resize-none py-2 w-full rounded-xl px-2 border border-opacity-50 border-sky-500", {
            // "py-2 w-full": isSmallDevice,
          })}
        ></textarea>
      </div>
    </>
  );
}
