import { React, useRef, useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faClockRotateLeft,
  faHouse,
  faMagnifyingGlass,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import REOffice from "../../Images/REOffice.png";
import FinInst from "../../Images/FinInstitution.png";
import CountrySlovakia from "../constants/regions";
import RegionBratislava from "../constants/Districts/RegionBratislava";
import RegionKošice from "../constants/Districts/RegionKosice";
import RegionTrnava from "../constants/Districts/RegionTrnava";
import RegionPresov from "../constants/Districts/RegionPresov";
import RegionBanskaBystrica from "../constants/Districts/RegionBanskaBystrica";
import RegionNitra from "../constants/Districts/RegionNitra";
import RegionTrencin from "../constants/Districts/RegionTrencin";
import RegionZilina from "../constants/Districts/RegionZilina";
import { averagePriceService } from "../../services/averagePriceService";

const SearchBox = ({
  gotResponse,
  isClickedRef,
  setCenter,
  center,
  map,
  setMap,
  hideMarkers,
  setHideMarkers,
  setNavSmall,
  navSmall,
  setViewSale,
  viewSale,
  filters,
  setFilters,
  setFilterSale,
  forSaleItems,
  filterSale,
  priceMap,
  setPriceMap,
  genAvgPrice,
  setGenAvgPrice,
  setPMResult,
  PMResult,
  setZoom,
  clickedReg,
  setClickedReg,
  setPick,
  pick,
  PMHistory,
  setPMHistory,
}) => {
  const [searchBox, setSearchBox] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [visibilityOfBtns, setVisibilityOfBtns] = useState({ left: false, right: false });
  const typeRef = useRef("");
  const locationRef = useRef("");
  const valueRef = useRef("");
  // const [pick, setPick] = useState({ region: false, district: false });

  library.add(faMagnifyingGlass);

  const onSBLoad = (ref) => {
    // console.log("onSBLoad ref = ", ref);
    setSearchBox(ref);
    const container = document.getElementById("scroll-container");
    // console.log("SearchBox loaded");
    if (container?.scrollLeft === 0 && container?.scrollLeft + container?.clientWidth >= container?.scrollWidth) {
      setVisibilityOfBtns({ ...visibilityOfBtns, left: true, right: true });
      return;
    }
    if (container?.scrollLeft === 0) {
      setVisibilityOfBtns({ ...visibilityOfBtns, left: true });
      return;
    }
  };

  const onPlacesChanged = () => {
    isClickedRef.current = false;
    // console.log("onPlacesChanged");
    var location = searchBox.getPlaces()[0]?.geometry.location.toString();
    if (location !== undefined) {
      var geometry = location.replace("(", "").replace(")", "").replace(" ", "").split(",");
      setCenter({
        ...center,
        lat: parseFloat(geometry[0]),
        lng: parseFloat(geometry[1]),
      });
      setZoom(15);
      setMap(map);
    } else {
      return;
    }
  };

  const searchProperty = async (e) => {
    // console.log("e = ", e);
    setFilterSale([]);
    // console.log("searchBox.getPlaces() = ", searchBox.getPlaces());
    if (typeof e === "object") {
      valueRef.current = e.target.value;
    }
    setTimeout(async () => {
      if (
        typeof e === "object"
          ? e.key === "Enter" && searchBox.getPlaces() !== undefined
          : searchBox.getPlaces() !== undefined
      ) {
        // console.log("searchProperty1");
        if (
          typeof e === "object"
            ? e.target.value.includes("dom") || e.target.value.includes("byt")
            : e.indexOf("dom") !== -1 || e.indexOf("byt") !== -1
        ) {
          // console.log("searchProperty2");
          var location = searchBox.getPlaces()[0]?.geometry.location.toString();
          var geometry = location?.replace("(", "").replace(")", "").replace(" ", "").split(",");
          const loc = await fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              geometry[0] +
              "," +
              geometry[1] +
              "&key=" +
              process.env.REACT_APP_API_KEY,
          );
          const result = await loc.json();
          for (let i = 0; i < result.results[0].address_components.length; i++) {
            // console.log(
            //   "searchProperty3",
            //   " || valueRef = ",
            //   valueRef.current,
            //   " || result = ",
            //   result.results[0].address_components[i].long_name,
            // );
            if (
              valueRef.current
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  result.results[0].address_components[i].long_name
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, ""),
                )
            ) {
              // console.log("searchProperty4");
              setFilters({ ...filters, location: result.results[0].address_components[i].long_name });
              locationRef.current = result.results[0].address_components[i].long_name;
              setFilters({ ...filters, location: locationRef.current });
              if (typeof e === "object" ? e.target.value.includes("dom") : e.indexOf("dom") !== -1) {
                setFilters({ ...filters, type: "" });
                setFilters({ ...filters, type: "Dom" });
                // console.log("searchProperty5a");
                typeRef.current = "dom";
              }
              if (typeof e === "object" ? e.target.value.includes("byt") : e.indexOf("byt") !== -1) {
                setFilters({ ...filters, type: "Byt" });
                // console.log("searchProperty5b");
                typeRef.current = "byt";
              }
              // console.log("searchProperty6");
              setNavSmall({
                ...navSmall,
                properties: true,
                subjects: false,
                helper: false,
                options: false,
                login: false,
              });
              setViewSale({ ...viewSale, view: true });
              filterForSale();
              document.getElementById("search").value = "";
              break;
            }
          }
        }
      }
    }, 500);
  };

  const checkScroll = (num, side) => {
    const container = document.getElementById("scroll-container");
    if (
      container.scrollLeft - num <= 0 &&
      container.scrollLeft - num + container.clientWidth <= container.scrollWidth &&
      side === "left"
    ) {
      setVisibilityOfBtns({ ...visibilityOfBtns, left: true, right: false });
      return;
    }
    if (container.scrollLeft - num <= 0 && side === "left") {
      // console.log("left = ", container.scrollLeft - num);
      setVisibilityOfBtns({ ...visibilityOfBtns, left: true });
    }
    if (container.scrollLeft - num + container.clientWidth >= container.scrollWidth && side === "left") {
      // console.log("left: right < scrollwidth");
      setVisibilityOfBtns({ ...visibilityOfBtns, right: false });
    }
    if (
      container.scrollLeft + num > 0 &&
      container.scrollLeft + num + container.clientWidth >= container.scrollWidth &&
      side === "right"
    ) {
      setVisibilityOfBtns({ ...visibilityOfBtns, left: false, right: true });
      return;
    }
    if (container.scrollLeft + num > 0 && side === "right") {
      // console.log("right: left ");
      setVisibilityOfBtns({ ...visibilityOfBtns, left: false });
    }
    if (container.scrollLeft + num + container.clientWidth >= container.scrollWidth && side === "right") {
      // console.log("right > scrollWidth");
      setVisibilityOfBtns({ ...visibilityOfBtns, right: true });
    }
  };

  const scrollLeft = () => {
    const container = document.getElementById("scroll-container");
    container.scrollBy({ left: -150, behavior: "smooth" });
    checkScroll(150, "left");
  };

  const scrollRight = () => {
    const container = document.getElementById("scroll-container");
    container.scrollBy({ left: 150, behavior: "smooth" });
    checkScroll(150, "right");
  };

  useEffect(() => {
    if (filters.location === "") {
      setFilterSale([]);
    }
    // if (priceMap.region || priceMap.district) {
    //   setHideMarkers({ ...hideMarkers, REO: false, history: false, house: false, flat: false, FI: false });
    // }
  }, [filters.location, setFilterSale]);

  const filLoc = (item) => {
    // return item.city === filters.location;
    return (
      item.city
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") ===
      locationRef.current
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
  };

  const filPrice = (item) => {
    return item.price >= filters.minPrice && item.price <= filters.maxPrice;
  };

  const filArea = (item) => {
    return (
      Number(JSON.parse(item?.parameters).area) >= filters.minArea &&
      Number(JSON.parse(item?.parameters).area) <= filters.maxArea
    );
  };

  const filType = (item) => {
    // return JSON.parse(item?.parameters).type.toLowerCase().includes(filters.type.toLowerCase());
    return JSON.parse(item?.parameters).type.toLowerCase().includes(typeRef.current.toLowerCase());
  };

  const filterForSale = (prop) => {
    if (prop === 1) {
      setFilterSale([]);
      setFilters({ ...filters, location: "" });
      return;
    }
    const filteredArray = {
      ...forSaleItems,
      items: forSaleItems?.items?.filter((item) => {
        // const locFilter = filters.location !== "" ? filLoc(item) : item.city;
        const locFilter = locationRef.current !== "" ? filLoc(item) : item.city;
        const priceFilter = filters.maxPrice !== "" && filters.minPrice !== "" ? filPrice(item) : item.price;
        const areaFilter =
          filters.maxArea !== "" && filters.minArea !== "" ? filArea(item) : JSON.parse(item.parameters).area;
        // const typeFilter = filters.type !== "" ? filType(item) : JSON.parse(item?.parameters).type;
        const typeFilter = typeRef.current !== "" ? filType(item) : JSON.parse(item?.parameters).type;
        return [locFilter, priceFilter, areaFilter, typeFilter].every((filter) => filter);
      }),
    };
    setFilterSale(filteredArray);
    return filteredArray;
  };

  const bounds = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(47.7314, 16.8332), // Southwest coordinates
    new window.google.maps.LatLng(49.6132, 22.5585), // Northeast coordinates
  );

  const pickDistrict = () => {
    // console.log("Key of region = ", priceMap.districtName);
    if (priceMap.districtName === "RegionBratislava") {
      return Object.keys(RegionBratislava);
    }
    if (priceMap.districtName === "RegionTrnava") {
      return Object.keys(RegionTrnava);
    }
    if (priceMap.districtName === "RegionTrenčín") {
      return Object.keys(RegionTrencin);
    }
    if (priceMap.districtName === "RegionNitra") {
      return Object.keys(RegionNitra);
    }
    if (priceMap.districtName === "RegionŽilina") {
      return Object.keys(RegionZilina);
    }
    if (priceMap.districtName === "RegionBanskáBystrica") {
      return Object.keys(RegionBanskaBystrica);
    }
    if (priceMap.districtName === "RegionPrešov") {
      return Object.keys(RegionPresov);
    }
    if (priceMap.districtName === "RegionKošice") {
      return Object.keys(RegionKošice);
    }
  };

  const setCenterOfRegion = (key) => {
    if (key === "RegionKošice") {
      map.panTo({ lat: 48.705, lng: 20.758 });
    }
    if (key === "RegionBanskáBystrica") {
      map.panTo({ lat: 48.55, lng: 19.1462 });
    }
    if (key === "RegionNitra") {
      map.panTo({ lat: 48.3069, lng: 18.0843 });
    }
    if (key === "RegionPrešov") {
      map.panTo({ lat: 49.0803, lng: 20.558 });
    }
    if (key === "RegionTrenčín") {
      map.panTo({ lat: 48.8945, lng: 18.044 });
    }
    if (key === "RegionTrnava") {
      map.panTo({ lat: 48.375, lng: 17.5872 });
    }
    if (key === "RegionŽilina") {
      map.panTo({ lat: 49.2211, lng: 18.7495 });
    }
    if (key === "RegionBratislava") {
      map.panTo({ lat: 48.1486, lng: 17.1077 });
    }
  };

  const setCenterOfDistrict = (key) => {
    // console.log("key = ", key);
    if (key === "DistrictMichalovce") {
      map.panTo({ lat: 48.7543, lng: 21.9195 });
    }
    if (key === "DistrictSobrance") {
      map.panTo({ lat: 48.7464, lng: 21.9666 });
    }
    if (key === "DistrictTrebišov") {
      map.panTo({ lat: 48.6284, lng: 21.721 });
    }
    if (key === "DistrictKošiceokolieOuter") {
      map.panTo({ lat: 48.748, lng: 21.0065 });
    }
    if (key === "DistrictKošiceI") {
      map.panTo({ lat: 48.7144, lng: 21.2618 });
    }
    if (key === "DistrictKošiceII") {
      map.panTo({ lat: 48.7282, lng: 21.2734 });
    }
    if (key === "DistrictKošiceIII") {
      map.panTo({ lat: 48.7174, lng: 21.2245 });
    }
    if (key === "DistrictKošiceIV") {
      map.panTo({ lat: 48.6936, lng: 21.2072 });
    }
    if (key === "DistrictGelnica") {
      map.panTo({ lat: 48.7728, lng: 20.4858 });
    }
    if (key === "DistrictRožňava") {
      map.panTo({ lat: 48.7355, lng: 20.5301 });
    }
    if (key === "DistrictSpišskáNováVes") {
      map.panTo({ lat: 49.004, lng: 20.5734 });
    }
    if (key === "DistrictBardejov") {
      map.panTo({ lat: 49.2916, lng: 21.2763 });
    }
    if (key === "DistrictHumenné") {
      map.panTo({ lat: 48.9374, lng: 21.9065 });
    }
    if (key === "DistrictKežmarok") {
      map.panTo({ lat: 49.2352, lng: 20.4295 });
    }
    if (key === "DistrictLevoča") {
      map.panTo({ lat: 49.0208, lng: 20.5881 });
    }
    if (key === "DistrictMedzilaborce") {
      map.panTo({ lat: 49.2718, lng: 21.905 });
    }
    if (key === "DistrictPoprad") {
      map.panTo({ lat: 49.1594, lng: 20.0975 });
    }
    if (key === "DistrictPrešov") {
      map.panTo({ lat: 49.0011, lng: 21.2396 });
    }
    if (key === "DistrictSabinov") {
      map.panTo({ lat: 49.1045, lng: 20.8986 });
    }
    if (key === "DistrictSnina") {
      map.panTo({ lat: 48.9884, lng: 22.1498 });
    }
    if (key === "DistrictStaráĽubovňa") {
      map.panTo({ lat: 49.2985, lng: 20.6881 });
    }
    if (key === "DistrictStropkov") {
      map.panTo({ lat: 49.2619, lng: 21.4052 });
    }
    if (key === "DistrictSvidník") {
      map.panTo({ lat: 49.232, lng: 21.453 });
    }
    if (key === "DistrictVranovnadTopľou") {
      map.panTo({ lat: 48.9302, lng: 21.3484 });
    }
    if (key === "DistrictBanskáBystrica") {
      map.panTo({ lat: 48.738, lng: 19.1457 });
    }
    if (key === "DistrictBanskáŠtiavnica") {
      map.panTo({ lat: 48.4558, lng: 18.8962 });
    }
    if (key === "DistrictBrezno") {
      map.panTo({ lat: 48.7665, lng: 19.6334 });
    }
    if (key === "DistrictDetva") {
      map.panTo({ lat: 48.5602, lng: 19.4186 });
    }
    if (key === "DistrictKrupina") {
      map.panTo({ lat: 48.3192, lng: 19.0874 });
    }
    if (key === "DistrictLučenec") {
      map.panTo({ lat: 48.3264, lng: 19.6312 });
    }
    if (key === "DistrictPoltár") {
      map.panTo({ lat: 48.4171, lng: 19.4815 });
    }
    if (key === "DistrictRevúca") {
      map.panTo({ lat: 48.6843, lng: 20.1189 });
    }
    if (key === "DistrictRimavskáSobota") {
      map.panTo({ lat: 48.455, lng: 19.8807 });
    }
    if (key === "DistrictVeľkýKrtíš") {
      map.panTo({ lat: 48.3071, lng: 19.2623 });
    }
    if (key === "DistrictŽarnovica") {
      map.panTo({ lat: 48.3831, lng: 18.6637 });
    }
    if (key === "DistrictŽiarnadHronom") {
      map.panTo({ lat: 48.5908, lng: 18.853 });
    }
    if (key === "DistrictZvolen") {
      map.panTo({ lat: 48.582, lng: 19.1262 });
    }
    if (key === "DistrictBytča") {
      map.panTo({ lat: 49.232, lng: 18.4104 });
    }
    if (key === "DistrictČadca") {
      map.panTo({ lat: 49.34, lng: 18.5741 });
    }
    if (key === "DistrictDolnýKubín") {
      map.panTo({ lat: 49.2184, lng: 19.3031 });
    }
    if (key === "DistrictKysuckéNovéMesto") {
      map.panTo({ lat: 49.2987, lng: 18.8055 });
    }
    if (key === "DistrictLiptovskýMikuláš") {
      map.panTo({ lat: 49.0853, lng: 19.6015 });
    }
    if (key === "DistrictMartin") {
      map.panTo({ lat: 49.0654, lng: 18.913 });
    }
    if (key === "DistrictNámestovo") {
      map.panTo({ lat: 49.4172, lng: 19.393 });
    }
    if (key === "DistrictRužomberok") {
      map.panTo({ lat: 49.0894, lng: 19.3052 });
    }
    if (key === "DistrictTurčianskeTeplice") {
      map.panTo({ lat: 48.9033, lng: 18.8614 });
    }
    if (key === "DistrictTvrdošín") {
      map.panTo({ lat: 49.2616, lng: 19.5665 });
    }
    if (key === "DistrictŽilina") {
      map.panTo({ lat: 49.2215, lng: 18.7407 });
    }
    if (key === "DistrictBánovcenadBebravou") {
      map.panTo({ lat: 48.7633, lng: 18.2708 });
    }
    if (key === "DistrictIlava") {
      map.panTo({ lat: 48.8939, lng: 18.1856 });
    }
    if (key === "DistrictMyjava") {
      map.panTo({ lat: 48.7031, lng: 17.5736 });
    }
    if (key === "DistrictNovéMestonadVáhom") {
      map.panTo({ lat: 48.763, lng: 17.8266 });
    }
    if (key === "DistrictPartizánske") {
      map.panTo({ lat: 48.5953, lng: 18.3206 });
    }
    if (key === "DistrictPovažskáBystrica") {
      map.panTo({ lat: 49.1295, lng: 18.4256 });
    }
    if (key === "DistrictPrievidza") {
      map.panTo({ lat: 48.7642, lng: 18.6301 });
    }
    if (key === "DistrictPúchov") {
      map.panTo({ lat: 49.1431, lng: 18.2345 });
    }
    if (key === "DistrictTrenčín") {
      map.panTo({ lat: 48.8971, lng: 18.0443 });
    }
    if (key === "DistrictKomárno") {
      map.panTo({ lat: 47.7666, lng: 18.0216 });
    }
    if (key === "DistrictLevice") {
      map.panTo({ lat: 48.1673, lng: 18.6211 });
    }
    if (key === "DistrictNitra") {
      map.panTo({ lat: 48.3084, lng: 18.084 });
    }
    if (key === "DistrictNovéZámky") {
      map.panTo({ lat: 47.9816, lng: 18.1691 });
    }
    if (key === "DistrictŠaľa") {
      map.panTo({ lat: 48.1347, lng: 17.9745 });
    }
    if (key === "DistrictTopoľčany") {
      map.panTo({ lat: 48.5706, lng: 18.1882 });
    }
    if (key === "DistrictZlatéMoravce") {
      map.panTo({ lat: 48.3534, lng: 18.3706 });
    }
    if (key === "DistrictDunajskáStreda") {
      map.panTo({ lat: 47.9893, lng: 17.463 });
    }
    if (key === "DistrictGalanta") {
      map.panTo({ lat: 48.2081, lng: 17.7671 });
    }
    if (key === "DistrictHlohovec") {
      map.panTo({ lat: 48.4391, lng: 17.8304 });
    }
    if (key === "DistrictPiešťany") {
      map.panTo({ lat: 48.5863, lng: 17.821 });
    }
    if (key === "DistrictSenica") {
      map.panTo({ lat: 48.6628, lng: 17.166 });
    }
    if (key === "DistrictSkalica") {
      map.panTo({ lat: 48.8314, lng: 17.1892 });
    }
    if (key === "DistrictTrnava") {
      map.panTo({ lat: 48.4765, lng: 17.5875 });
    }
    if (key === "DistrictBratislavaI") {
      map.panTo({ lat: 48.1482, lng: 17.1067 });
    }
    if (key === "DistrictBratislavaII") {
      map.panTo({ lat: 48.1652, lng: 17.1315 });
    }
    if (key === "DistrictBratislavaIII") {
      map.panTo({ lat: 48.1883, lng: 17.1764 });
    }
    if (key === "DistrictBratislavaIV") {
      map.panTo({ lat: 48.2074, lng: 17.1502 });
    }
    if (key === "DistrictBratislavaV") {
      map.panTo({ lat: 48.1603, lng: 17.2248 });
    }
    if (key === "DistrictMalacky") {
      map.panTo({ lat: 48.4417, lng: 17.0145 });
    }
    if (key === "DistrictPezinok") {
      map.panTo({ lat: 48.2541, lng: 17.27 });
    }
    if (key === "DistrictSenec") {
      map.panTo({ lat: 48.2137, lng: 17.4008 });
    }
  };

  const getAvgPrice = async (key) => {
    const parent = key.replace("District", "").replace("Outer", "");
    const request = await averagePriceService.getGeneralAvgPrice(decodeURIComponent(parent));
    const response = await request.json();
    setGenAvgPrice(response);
  };

  const defaultColor = "#7551b0";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E"; scroll
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("genAvgPrice = ", genAvgPrice);
    // console.log("color key = ", key);
    if (Object.keys(genAvgPrice).length > 0) {
      // console.log("key = ", key);
      // console.log("Object.keys length = ", Object.keys(genAvgPrice).length);
      if (genAvgPrice.hasOwnProperty(key)) {
        // console.log("has own property");
        if (genAvgPrice[key] >= 700 && genAvgPrice[key] <= 1000) {
          return green1;
        } else if (genAvgPrice[key] > 1000 && genAvgPrice[key] <= 1300) {
          return green2;
        } else if (genAvgPrice[key] > 1300 && genAvgPrice[key] <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key] > 1600 && genAvgPrice[key] <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key] > 1800 && genAvgPrice[key] <= 2000) {
          return orange1;
        } else if (genAvgPrice[key] > 2000 && genAvgPrice[key] <= 2100) {
          return red1;
        } else if (genAvgPrice[key] > 2100) {
          return red2;
        }
        // console.log("no property");
        return red2;
      } else return defaultColor;
    } else return defaultColor;
  };

  const setDistrict = (key) => {
    setPriceMap({ ...priceMap, district: false, cityName: key, city: true });
    setPick({ ...pick, district: false });
    // console.log("key = ", key, " || replaced key = ", key.replace("District", ""));
    setPMResult({
      ...PMResult,
      parent: key.replace("District", "").replace("Ľubovňa", " Ľubovňa").replace("Štiavnica", " Štiavnica"),
    });
    setPMHistory({
      ...PMHistory,
      district:
        PMResult?.region +
        " " +
        key.replace("District", "").replace("Ľubovňa", " Ľubovňa").replace("Štiavnica", " Štiavnica"),
    });
    setZoom(10);
    getAvgPrice(key);
    setColor(key.replace("Ext", "").replace("1", "").replace("Košicemestskáčasť", ""));
    setCenterOfDistrict(key);
  };

  const resetDistrict = () => {
    setZoom(9);
    setPriceMap({ ...priceMap, district: true, cityName: "", city: false });
    getAvgPrice(priceMap.districtName);
    setPMResult({ ...PMResult, active: false });
  };

  const setRegion = (key) => {
    setClickedReg(true);
    setPriceMap({
      ...priceMap,
      district: true,
      districtName: key,
      region: false,
      city: false,
      cityName: "",
    });
    setZoom(9);
    setPMResult({ ...PMResult, region: key.replace("Region", "") });
    setPMHistory({ ...PMHistory, region: key.replace("Region", "") });
    setPick(false);
    getAvgPrice(key);
    setCenterOfRegion(key);
  };

  const resetRegion = () => {
    setPriceMap({
      ...priceMap,
      region: !priceMap.region,
      district: false,
      city: false,
      districtName: "",
      cityName: "",
    });
    getAvgPrice("");
    setPMResult({ ...PMResult, region: "", active: false });
    setZoom(8);
    map.panTo({ lat: 48.6667, lng: 18.5 });
  };

  const addSpaceBeforeUppercase = (input) => {
    if (input.includes("Štiavnica")) {
      return input.replace("Štiavnica", " Štiavnica");
    }
    if (input.includes("Ľubovňa")) {
      return input.replace("Ľubovňa", " Ľubovňa");
    }
    if (!input.includes("Košice") && !input.includes("Bratislava")) {
      return input.replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ").replace("nad", " nad");
    } else {
      return input.replace("Bratislava", "Bratislava ").replace("Košice", "Košice ");
    }
  };

  return (
    <div
      className={clsx("w-full flex gap-2", {
        "flex-row": !isSmallDevice,
        "flex-col items-center": isSmallDevice,
      })}
    >
      <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={onSBLoad} bounds={bounds}>
        <div className="bg-sky-100 opacity-90 rounded-2xl relative flex flex-row sm:left-20 w-64 min-[320px]:w-80 h-12 formkit-field">
          <input
            className="bg-sky-100 w-full h-12 relative text-black text-ellipsis rounded-3xl outline-none text-sm px-2 border-0 placeholder:text-black"
            id="search"
            type="text"
            placeholder="Zadaj adresu..."
            onKeyDown={(e) => {
              searchProperty(e);
            }}
            onKeyUp={(e) => {
              searchProperty(e);
            }}
          />
          <button
            className="flex items-center rounded-r-2xl cursor-pointer pr-2"
            onClick={() => {
              if (searchBox) {
                searchProperty(valueRef.current);
                onPlacesChanged();
              } else {
                console.error("SearchBox is not loaded yet.");
              }
            }}
          >
            <FontAwesomeIcon
              icon="magnifying-glass"
              className={clsx("pl-3", {
                "z-10": gotResponse === "false",
              })}
            />
          </button>
        </div>
      </StandaloneSearchBox>
      {priceMap.region || priceMap.district || priceMap.city ? (
        <div
          onMouseLeave={() => setPick({ ...pick, region: false, district: false })}
          className={clsx("flex flex-row h-12 gap-2 rounded-lg px-2 relative no-scrollbar items-center select-none", {
            "ml-20": !isSmallDevice && !PMResult.active,
            "ml-28": !isSmallDevice && PMResult.active,
            "w-auto !absolute !left-0 top-[135px]": isSmallDevice,
          })}
        >
          {/* kraj */}
          <button
            onMouseOver={() => setPick({ ...pick, region: true, district: false })}
            className={clsx("w-36 h-7 bg-green-100 hover:bg-green-200 rounded-2xl truncate flex flex-row", {
              "bg-green-300": !hideMarkers.house,
              "bg-green-100": hideMarkers.house,
            })}
          >
            <div
              className={clsx("h-full rounded-l-2xl flex justify-center items-center", {
                "w-5/6": PMResult.region !== "" && clickedReg,
                "w-full": !(PMResult.region !== "" && clickedReg),
              })}
            >
              {PMResult.region !== "" && clickedReg ? PMResult.region.replace("Bystrica", " Bystrica") : "Vyber kraj"}
            </div>
            {PMResult.region !== "" && clickedReg ? (
              <div className="w-1/6 h-full flex justify-center items-center">
                <button
                  onClick={() => resetRegion()}
                  className="rounded-full w-4 h-4 flex justify-center items-center border-2 border-gray-400"
                >
                  <FontAwesomeIcon icon={faX} className="h-2" />
                </button>
              </div>
            ) : null}
          </button>
          {pick.region ? (
            <div
              className="absolute top-11 w-36 h-auto rounded-2xl flex flex-col gap-1"
              onMouseLeave={() => setPick({ ...pick, region: false })}
            >
              {Object.keys(CountrySlovakia).map((key) => {
                return (
                  <button
                    key={key}
                    className="w-full h-7 bg-green-100 hover:bg-green-200 rounded-2xl truncate px-2"
                    onClick={() => setRegion(key)}
                  >
                    {key
                      .replace("Region", "")
                      .replace("Bystrica", " Bystrica")
                      .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")}
                  </button>
                );
              })}
              {PMResult.region !== "" ? (
                <button
                  className="w-full h-7 bg-green-100 hover:bg-green-200 rounded-2xl truncate px-2"
                  onClick={() => resetRegion()}
                >
                  Vyber kraj
                </button>
              ) : null}
            </div>
          ) : null}
          {/* kraj */}

          {/* okres */}
          {priceMap.districtName !== "" ? (
            <div
              onMouseOver={() => setPick({ ...pick, district: true, region: false })}
              className={clsx("w-36 h-7 bg-green-100 hover:bg-green-200 rounded-2xl truncate flex flex-row", {
                "bg-green-300": !hideMarkers.flat,
                "bg-green-100": hideMarkers.flat,
              })}
            >
              <div
                className={clsx("rounded-l-2xl pl-1 text-center flex justify-center items-center", {
                  "w-5/6": priceMap.cityName !== "",
                  "w-full": priceMap.cityName === "",
                })}
              >
                <p className="w-full truncate">
                  {priceMap.cityName !== ""
                    ? addSpaceBeforeUppercase(priceMap.cityName.replace("District", "").replace("Outer", ""))
                    : "Vyber okres"}
                </p>
              </div>
              {priceMap.cityName !== "" ? (
                <div className="w-1/6 h-full flex justify-center items-center">
                  <button
                    onClick={() => resetDistrict()}
                    className="rounded-full w-4 h-4 flex justify-center items-center border-2 border-gray-400"
                  >
                    <FontAwesomeIcon icon={faX} className="h-2" />
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}

          {pick.district ? (
            <div
              className="absolute right-2 top-11 w-36 h-auto rounded-2xl flex flex-col gap-1"
              onMouseLeave={() => setPick({ ...pick, district: false })}
            >
              {pickDistrict().map((key) => {
                return key.replace("District", "") !== "KošiceokolieInner" ? (
                  <button
                    key={key}
                    className="w-full h-7 bg-green-100 hover:bg-green-200 rounded-2xl truncate px-2 "
                    onClick={() => setDistrict(key)}
                  >
                    {addSpaceBeforeUppercase(key.replace("District", "").replace("Outer", ""))}
                  </button>
                ) : null;
              })}
              {priceMap.cityName !== "" ? (
                <button
                  className="w-full h-7 bg-green-100 hover:bg-green-200 rounded-2xl"
                  onClick={() => resetDistrict()}
                >
                  Vyber okres
                </button>
              ) : null}
            </div>
          ) : null}
          {/* okres */}
        </div>
      ) : (
        <div
          className={clsx("h-12 gap-2 rounded-lg px-2 relative no-scrollbar items-center select-none", {
            "ml-20": !isSmallDevice,
            "w-full": isSmallDevice,
          })}
        >
          <div className="absolute left-0 w-full h-12 rounded-full flex items-center overflow-hidden">
            {!visibilityOfBtns.left && !isSmallDevice ? (
              <button
                onClick={() => scrollLeft()}
                className="absolute w-7 h-7 z-[51] rounded-full border border-sky-300 bg-sky-100"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            ) : null}
            {!visibilityOfBtns.right && !isSmallDevice ? (
              <button
                onClick={() => scrollRight()}
                className="absolute right-0 w-7 h-7 z-[51] rounded-full border border-sky-300 bg-sky-100"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            ) : null}
          </div>
          <div
            id="scroll-container"
            className={clsx("h-12 z-50 gap-2 rounded-lg relative no-scrollbar items-center", {
              // "ml-20 w-auto flex flex-row items-center": !isSmallDevice,
              "md:max-w-lg lg:max-w-3xl grid grid-rows-1 grid-cols-[80px_64px_96px_176px_176px] overflow-x-scroll ":
                !isSmallDevice,
              "w-full grid grid-rows-1 grid-cols-[80px_64px_96px_176px_176px] overflow-x-scroll ": isSmallDevice,
            })}
          >
            <button
              onClick={() => setHideMarkers({ ...hideMarkers, house: !hideMarkers.house })}
              className={clsx(
                "h-7 w-20 px-1 flex flex-row justify-center items-center gap-1 rounded-2xl z-50 hover:bg-green-200",
                {
                  "bg-green-300": !hideMarkers.house,
                  "bg-green-100": hideMarkers.house,
                },
              )}
            >
              <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon> Domy
            </button>
            <button
              onClick={() => setHideMarkers({ ...hideMarkers, flat: !hideMarkers.flat })}
              className={clsx(
                "h-7 w-16 px-1 flex flex-row justify-center items-center gap-1 rounded-2xl z-50 hover:bg-green-200",
                {
                  "bg-green-300": !hideMarkers.flat,
                  "bg-green-100": hideMarkers.flat,
                },
              )}
            >
              <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon> Byty
            </button>
            <button
              onClick={() => setHideMarkers({ ...hideMarkers, history: !hideMarkers.history })}
              className={clsx(
                "h-7 w-24 px-1 flex flex-row justify-center items-center gap-1 rounded-2xl z-50 hover:bg-red-200",
                {
                  "bg-red-300": !hideMarkers.history,
                  "bg-red-100": hideMarkers.history,
                },
              )}
            >
              <FontAwesomeIcon icon={faClockRotateLeft}></FontAwesomeIcon> História
            </button>
            <button
              onClick={() => setHideMarkers({ ...hideMarkers, REO: !hideMarkers.REO })}
              className={clsx(
                "h-7 w-44 px-1 flex flex-row justify-center items-center rounded-2xl z-50 hover:bg-blue-200",
                {
                  "bg-blue-300": !hideMarkers.REO,
                  "bg-blue-100": hideMarkers.REO,
                },
              )}
            >
              <img src={REOffice} alt="" className="w-6 h-6" />
              Realitné kancelárie
            </button>
            <button
              onClick={() => setHideMarkers({ ...hideMarkers, FI: !hideMarkers.FI })}
              className={clsx(
                "h-7 w-44 px-1 flex flex-row justify-center items-center gap-1 rounded-2xl z-50 hover:bg-purple-200",
                {
                  "bg-purple-300": !hideMarkers.FI,
                  "bg-purple-100": hideMarkers.FI,
                },
              )}
            >
              <img src={FinInst} alt="" className="w-4 h-4" /> Finančné inštitúcie
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
