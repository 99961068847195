import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";

export default function GallerySmall({ imgs, previous, setGallery, size }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={clsx(" w-full h-auto overflow-x-scroll gap-1 pt-1", {
        "no-scrollbar": isSmallDevice,
        "flex flex-row": imgs?.length <= 5 && size.full,
        "grid grid-rows-1": imgs?.length > 5 || !size.full,
      })}
      initial={{ x: previous !== "lv" && previous !== "in" ? -500 : 500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {imgs?.map((img, index) => (
        <div key={index} className="w-44 h-24 row-start-1">
          <img
            onClick={(e) => setGallery(true)}
            src={img?.img}
            // src="https://img.nehnutelnosti.sk/foto/ZmlsdGVyczpmb3JtYXQod2VicCkvbmVo/AfA9gI02lp_fss?st=i4a6aIqGGYnu4VW2ONPpFGRFfGWIoFK7flJDvUXKweE&ts=1731078170&e=0"
            alt=""
            className="w-full h-full rounded-xl object-cover"
          />
        </div>
      ))}
    </motion.div>
  );
}
