import { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { motion } from "framer-motion";

export default function PriceFilter({ filters, setFilters, isFilter }) {
  const [value, setValue] = useState([1000, 3000000]);

  const handleChange = (price) => {
    setValue(price);
    setFilters({ ...filters, minPrice: price[0], maxPrice: price[1] });
  };

  return (
    <motion.div
      className="w-full flex flex-col gap-2"
      initial={{ height: 30 }}
      animate={{ height: isFilter.price ? 100 : 30 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col">
        <p className="">Cena (EUR): </p>
        <div className="w-full flex flex-row gap-1">
          <input
            name="minPrice"
            value={filters.minPrice ? filters.minPrice : ""}
            onChange={(e) => setFilters({ ...filters, minPrice: e.target.value })}
            min={10}
            className="w-full rounded-lg text-center h-8 border border-opacity-50 border-sky-500"
            type="number"
            placeholder="Od"
          />
          <input
            name="maxPrice"
            value={filters.maxPrice ? filters.maxPrice : ""}
            onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
            min={10}
            className="w-full rounded-lg text-center h-8 border border-opacity-50 border-sky-500"
            type="number"
            placeholder="Do"
          />
        </div>
      </div>
      <div className="w-full p-2 h-8">
        <RangeSlider value={value} onInput={handleChange} min={1000} max={3000000} step={1000} />
      </div>
      <div className="mb-2 flex justify-between">
        <span className="-mt-4">{1000} €</span>
        <span className="-mt-4">{3000000} €</span>
      </div>
    </motion.div>
  );
}
