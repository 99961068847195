const UnitsRožňava = {
  Stratená: [
    { lat: 48.91126, lng: 20.391559 },
    { lat: 48.91126, lng: 20.391438 },
    { lat: 48.911337, lng: 20.390043 },
    { lat: 48.911337, lng: 20.390029 },
    { lat: 48.911342, lng: 20.389937 },
    { lat: 48.911343, lng: 20.389921 },
    { lat: 48.911379, lng: 20.388966 },
    { lat: 48.911323, lng: 20.388973 },
    { lat: 48.911209, lng: 20.388199 },
    { lat: 48.91125, lng: 20.388018 },
    { lat: 48.91201, lng: 20.387523 },
    { lat: 48.91245, lng: 20.387468 },
    { lat: 48.912517, lng: 20.387013 },
    { lat: 48.912358, lng: 20.38625 },
    { lat: 48.91253, lng: 20.385866 },
    { lat: 48.912529, lng: 20.385658 },
    { lat: 48.912511, lng: 20.385493 },
    { lat: 48.912588, lng: 20.385127 },
    { lat: 48.912617, lng: 20.384644 },
    { lat: 48.912485, lng: 20.384116 },
    { lat: 48.912339, lng: 20.383744 },
    { lat: 48.912159, lng: 20.382608 },
    { lat: 48.912181, lng: 20.381639 },
    { lat: 48.911919, lng: 20.380092 },
    { lat: 48.911894, lng: 20.379969 },
    { lat: 48.911611, lng: 20.379253 },
    { lat: 48.911422, lng: 20.378692 },
    { lat: 48.911373, lng: 20.378411 },
    { lat: 48.911227, lng: 20.378068 },
    { lat: 48.911048, lng: 20.377645 },
    { lat: 48.911128, lng: 20.37742 },
    { lat: 48.911018, lng: 20.376474 },
    { lat: 48.910695, lng: 20.375715 },
    { lat: 48.910903, lng: 20.37523 },
    { lat: 48.911285, lng: 20.3753 },
    { lat: 48.911535, lng: 20.375287 },
    { lat: 48.911935, lng: 20.375384 },
    { lat: 48.912134, lng: 20.375334 },
    { lat: 48.912481, lng: 20.37473 },
    { lat: 48.912784, lng: 20.374193 },
    { lat: 48.913138, lng: 20.373985 },
    { lat: 48.913204, lng: 20.373759 },
    { lat: 48.913332, lng: 20.373419 },
    { lat: 48.913547, lng: 20.372939 },
    { lat: 48.913836, lng: 20.372373 },
    { lat: 48.914204, lng: 20.371931 },
    { lat: 48.914266, lng: 20.371574 },
    { lat: 48.91427, lng: 20.371551 },
    { lat: 48.914301, lng: 20.371296 },
    { lat: 48.91434, lng: 20.371008 },
    { lat: 48.914378, lng: 20.370688 },
    { lat: 48.914395, lng: 20.370447 },
    { lat: 48.914341, lng: 20.369928 },
    { lat: 48.914522, lng: 20.369388 },
    { lat: 48.914625, lng: 20.369216 },
    { lat: 48.914778, lng: 20.368962 },
    { lat: 48.914998, lng: 20.368597 },
    { lat: 48.915248, lng: 20.368281 },
    { lat: 48.915423, lng: 20.368078 },
    { lat: 48.915621, lng: 20.367558 },
    { lat: 48.916141, lng: 20.366602 },
    { lat: 48.916089, lng: 20.36604 },
    { lat: 48.916164, lng: 20.365881 },
    { lat: 48.916874, lng: 20.365279 },
    { lat: 48.917099, lng: 20.364598 },
    { lat: 48.917217, lng: 20.364015 },
    { lat: 48.917856, lng: 20.36357 },
    { lat: 48.91804, lng: 20.362885 },
    { lat: 48.918676, lng: 20.362466 },
    { lat: 48.918835, lng: 20.361399 },
    { lat: 48.918974, lng: 20.360192 },
    { lat: 48.918969, lng: 20.359506 },
    { lat: 48.9192, lng: 20.358848 },
    { lat: 48.919241, lng: 20.358379 },
    { lat: 48.919215, lng: 20.358 },
    { lat: 48.919145, lng: 20.357637 },
    { lat: 48.919114, lng: 20.357406 },
    { lat: 48.919192, lng: 20.357204 },
    { lat: 48.919291, lng: 20.357075 },
    { lat: 48.919417, lng: 20.356964 },
    { lat: 48.91939, lng: 20.356798 },
    { lat: 48.919329, lng: 20.356578 },
    { lat: 48.919224, lng: 20.356224 },
    { lat: 48.919216, lng: 20.355984 },
    { lat: 48.919367, lng: 20.354806 },
    { lat: 48.919529, lng: 20.354546 },
    { lat: 48.919752, lng: 20.354378 },
    { lat: 48.919758, lng: 20.354199 },
    { lat: 48.919805, lng: 20.352955 },
    { lat: 48.919893, lng: 20.352338 },
    { lat: 48.920483, lng: 20.351205 },
    { lat: 48.920681, lng: 20.349505 },
    { lat: 48.921674, lng: 20.349356 },
    { lat: 48.922425, lng: 20.34859 },
    { lat: 48.922602, lng: 20.347147 },
    { lat: 48.922483, lng: 20.346766 },
    { lat: 48.922872, lng: 20.345273 },
    { lat: 48.92295, lng: 20.344344 },
    { lat: 48.923146, lng: 20.343526 },
    { lat: 48.92349, lng: 20.342967 },
    { lat: 48.923831, lng: 20.342706 },
    { lat: 48.923742, lng: 20.342396 },
    { lat: 48.923662, lng: 20.342268 },
    { lat: 48.923589, lng: 20.342175 },
    { lat: 48.923449, lng: 20.341971 },
    { lat: 48.92311, lng: 20.34185 },
    { lat: 48.922608, lng: 20.34134 },
    { lat: 48.920976, lng: 20.341259 },
    { lat: 48.92073, lng: 20.340904 },
    { lat: 48.920527, lng: 20.340668 },
    { lat: 48.920313, lng: 20.340537 },
    { lat: 48.919831, lng: 20.340305 },
    { lat: 48.919598, lng: 20.3399 },
    { lat: 48.919367, lng: 20.339586 },
    { lat: 48.919192, lng: 20.33942 },
    { lat: 48.91915, lng: 20.339431 },
    { lat: 48.918889, lng: 20.339601 },
    { lat: 48.918578, lng: 20.33944 },
    { lat: 48.918503, lng: 20.339413 },
    { lat: 48.918203, lng: 20.339528 },
    { lat: 48.918113, lng: 20.339651 },
    { lat: 48.917865, lng: 20.339783 },
    { lat: 48.917766, lng: 20.339782 },
    { lat: 48.917402, lng: 20.339913 },
    { lat: 48.917145, lng: 20.339797 },
    { lat: 48.917003, lng: 20.33954 },
    { lat: 48.916873, lng: 20.339498 },
    { lat: 48.916751, lng: 20.33951 },
    { lat: 48.916531, lng: 20.339462 },
    { lat: 48.916225, lng: 20.339445 },
    { lat: 48.915918, lng: 20.339334 },
    { lat: 48.915782, lng: 20.339161 },
    { lat: 48.915714, lng: 20.338665 },
    { lat: 48.915499, lng: 20.338452 },
    { lat: 48.915562, lng: 20.338077 },
    { lat: 48.915495, lng: 20.337772 },
    { lat: 48.915308, lng: 20.337606 },
    { lat: 48.915294, lng: 20.33732 },
    { lat: 48.914951, lng: 20.336963 },
    { lat: 48.914809, lng: 20.336934 },
    { lat: 48.914704, lng: 20.336883 },
    { lat: 48.914479, lng: 20.336779 },
    { lat: 48.914436, lng: 20.336642 },
    { lat: 48.914464, lng: 20.335839 },
    { lat: 48.914349, lng: 20.335577 },
    { lat: 48.91417, lng: 20.335476 },
    { lat: 48.913953, lng: 20.335296 },
    { lat: 48.913528, lng: 20.335381 },
    { lat: 48.913321, lng: 20.335297 },
    { lat: 48.913077, lng: 20.335035 },
    { lat: 48.912893, lng: 20.334595 },
    { lat: 48.91288, lng: 20.334493 },
    { lat: 48.912912, lng: 20.334414 },
    { lat: 48.912798, lng: 20.334417 },
    { lat: 48.912703, lng: 20.334502 },
    { lat: 48.912396, lng: 20.334328 },
    { lat: 48.912267, lng: 20.334076 },
    { lat: 48.912019, lng: 20.33414 },
    { lat: 48.911765, lng: 20.333823 },
    { lat: 48.911596, lng: 20.33379 },
    { lat: 48.911437, lng: 20.333354 },
    { lat: 48.911303, lng: 20.333162 },
    { lat: 48.911261, lng: 20.332922 },
    { lat: 48.911142, lng: 20.332692 },
    { lat: 48.910668, lng: 20.331642 },
    { lat: 48.910474, lng: 20.331729 },
    { lat: 48.91019, lng: 20.331444 },
    { lat: 48.910164, lng: 20.331118 },
    { lat: 48.910098, lng: 20.330982 },
    { lat: 48.910109, lng: 20.3307 },
    { lat: 48.910087, lng: 20.330358 },
    { lat: 48.910048, lng: 20.330255 },
    { lat: 48.909852, lng: 20.329864 },
    { lat: 48.909777, lng: 20.329626 },
    { lat: 48.909636, lng: 20.329497 },
    { lat: 48.909477, lng: 20.329024 },
    { lat: 48.90937, lng: 20.328852 },
    { lat: 48.90925, lng: 20.328767 },
    { lat: 48.908972, lng: 20.328145 },
    { lat: 48.908845, lng: 20.328033 },
    { lat: 48.908761, lng: 20.327856 },
    { lat: 48.908727, lng: 20.327497 },
    { lat: 48.908561, lng: 20.327427 },
    { lat: 48.908071, lng: 20.327665 },
    { lat: 48.90802, lng: 20.327668 },
    { lat: 48.907979, lng: 20.327606 },
    { lat: 48.907598, lng: 20.327402 },
    { lat: 48.907544, lng: 20.327316 },
    { lat: 48.907524, lng: 20.32715 },
    { lat: 48.907463, lng: 20.327085 },
    { lat: 48.907315, lng: 20.326948 },
    { lat: 48.907116, lng: 20.326885 },
    { lat: 48.907054, lng: 20.326542 },
    { lat: 48.907185, lng: 20.326461 },
    { lat: 48.907201, lng: 20.326442 },
    { lat: 48.90722, lng: 20.326399 },
    { lat: 48.907233, lng: 20.326329 },
    { lat: 48.907189, lng: 20.326197 },
    { lat: 48.907118, lng: 20.326065 },
    { lat: 48.906844, lng: 20.325751 },
    { lat: 48.906727, lng: 20.325653 },
    { lat: 48.906667, lng: 20.325541 },
    { lat: 48.906601, lng: 20.325292 },
    { lat: 48.906481, lng: 20.324952 },
    { lat: 48.90619, lng: 20.324703 },
    { lat: 48.906035, lng: 20.324617 },
    { lat: 48.905819, lng: 20.324568 },
    { lat: 48.905642, lng: 20.324594 },
    { lat: 48.905064, lng: 20.324772 },
    { lat: 48.905066, lng: 20.324795 },
    { lat: 48.904993, lng: 20.324769 },
    { lat: 48.904902, lng: 20.324743 },
    { lat: 48.904558, lng: 20.324375 },
    { lat: 48.904484, lng: 20.32435 },
    { lat: 48.904417, lng: 20.324268 },
    { lat: 48.904347, lng: 20.324233 },
    { lat: 48.904246, lng: 20.324289 },
    { lat: 48.904088, lng: 20.32431 },
    { lat: 48.90399, lng: 20.324312 },
    { lat: 48.90384, lng: 20.324295 },
    { lat: 48.903647, lng: 20.324327 },
    { lat: 48.903543, lng: 20.324405 },
    { lat: 48.903515, lng: 20.324439 },
    { lat: 48.903421, lng: 20.32437 },
    { lat: 48.905206, lng: 20.315842 },
    { lat: 48.90519, lng: 20.315189 },
    { lat: 48.905023, lng: 20.315024 },
    { lat: 48.90523, lng: 20.31415 },
    { lat: 48.905362, lng: 20.313762 },
    { lat: 48.905392, lng: 20.313685 },
    { lat: 48.905519, lng: 20.313548 },
    { lat: 48.905636, lng: 20.313059 },
    { lat: 48.905707, lng: 20.312946 },
    { lat: 48.905705, lng: 20.312887 },
    { lat: 48.905736, lng: 20.312642 },
    { lat: 48.905679, lng: 20.312085 },
    { lat: 48.905608, lng: 20.311629 },
    { lat: 48.905583, lng: 20.310641 },
    { lat: 48.905592, lng: 20.310509 },
    { lat: 48.905602, lng: 20.3103 },
    { lat: 48.905987, lng: 20.309403 },
    { lat: 48.906245, lng: 20.309151 },
    { lat: 48.906676, lng: 20.308186 },
    { lat: 48.906844, lng: 20.307598 },
    { lat: 48.907395, lng: 20.306477 },
    { lat: 48.90695, lng: 20.305435 },
    { lat: 48.906592, lng: 20.304877 },
    { lat: 48.906438, lng: 20.304981 },
    { lat: 48.905389, lng: 20.304901 },
    { lat: 48.905081, lng: 20.304921 },
    { lat: 48.904951, lng: 20.304583 },
    { lat: 48.904294, lng: 20.303743 },
    { lat: 48.903566, lng: 20.303258 },
    { lat: 48.903431, lng: 20.303093 },
    { lat: 48.903287, lng: 20.302712 },
    { lat: 48.903108, lng: 20.302522 },
    { lat: 48.903028, lng: 20.302293 },
    { lat: 48.902981, lng: 20.301744 },
    { lat: 48.902892, lng: 20.301157 },
    { lat: 48.902817, lng: 20.300921 },
    { lat: 48.90226, lng: 20.300009 },
    { lat: 48.902092, lng: 20.299955 },
    { lat: 48.901919, lng: 20.299463 },
    { lat: 48.901613, lng: 20.298877 },
    { lat: 48.901459, lng: 20.298753 },
    { lat: 48.900965, lng: 20.298998 },
    { lat: 48.900699, lng: 20.299057 },
    { lat: 48.900482, lng: 20.298911 },
    { lat: 48.900086, lng: 20.298343 },
    { lat: 48.899889, lng: 20.298189 },
    { lat: 48.90007, lng: 20.296425 },
    { lat: 48.899958, lng: 20.296428 },
    { lat: 48.899287, lng: 20.295259 },
    { lat: 48.899204, lng: 20.295201 },
    { lat: 48.898881, lng: 20.294958 },
    { lat: 48.897849, lng: 20.294301 },
    { lat: 48.897123, lng: 20.294313 },
    { lat: 48.896996, lng: 20.294111 },
    { lat: 48.896916, lng: 20.293939 },
    { lat: 48.896713, lng: 20.294165 },
    { lat: 48.895514, lng: 20.29555 },
    { lat: 48.895274, lng: 20.295026 },
    { lat: 48.894878, lng: 20.29424 },
    { lat: 48.894066, lng: 20.293053 },
    { lat: 48.89391, lng: 20.292822 },
    { lat: 48.893893, lng: 20.29284 },
    { lat: 48.893838, lng: 20.292968 },
    { lat: 48.893725, lng: 20.293205 },
    { lat: 48.893579, lng: 20.293451 },
    { lat: 48.893322, lng: 20.293746 },
    { lat: 48.893227, lng: 20.293909 },
    { lat: 48.893179, lng: 20.29409 },
    { lat: 48.893055, lng: 20.294295 },
    { lat: 48.89293, lng: 20.294577 },
    { lat: 48.892651, lng: 20.294873 },
    { lat: 48.892681, lng: 20.294953 },
    { lat: 48.892522, lng: 20.295021 },
    { lat: 48.892492, lng: 20.295119 },
    { lat: 48.892287, lng: 20.295286 },
    { lat: 48.892153, lng: 20.295594 },
    { lat: 48.891978, lng: 20.295538 },
    { lat: 48.891812, lng: 20.295713 },
    { lat: 48.89176, lng: 20.296071 },
    { lat: 48.891759, lng: 20.296182 },
    { lat: 48.891801, lng: 20.296263 },
    { lat: 48.891844, lng: 20.296615 },
    { lat: 48.891766, lng: 20.296801 },
    { lat: 48.891831, lng: 20.296977 },
    { lat: 48.891834, lng: 20.29755 },
    { lat: 48.89191, lng: 20.297815 },
    { lat: 48.891803, lng: 20.297849 },
    { lat: 48.891782, lng: 20.297857 },
    { lat: 48.891659, lng: 20.297725 },
    { lat: 48.89165, lng: 20.297729 },
    { lat: 48.891578, lng: 20.297596 },
    { lat: 48.891368, lng: 20.297337 },
    { lat: 48.89095, lng: 20.296879 },
    { lat: 48.890801, lng: 20.296782 },
    { lat: 48.89066, lng: 20.296726 },
    { lat: 48.890577, lng: 20.296623 },
    { lat: 48.890459, lng: 20.296563 },
    { lat: 48.890273, lng: 20.296371 },
    { lat: 48.890034, lng: 20.296147 },
    { lat: 48.889697, lng: 20.295777 },
    { lat: 48.889523, lng: 20.295629 },
    { lat: 48.889245, lng: 20.295544 },
    { lat: 48.888864, lng: 20.295538 },
    { lat: 48.888865, lng: 20.2955 },
    { lat: 48.888553, lng: 20.294329 },
    { lat: 48.88844, lng: 20.293978 },
    { lat: 48.888266, lng: 20.293711 },
    { lat: 48.887644, lng: 20.29305 },
    { lat: 48.887234, lng: 20.292226 },
    { lat: 48.886741, lng: 20.291307 },
    { lat: 48.885814, lng: 20.290015 },
    { lat: 48.885619, lng: 20.289592 },
    { lat: 48.885494, lng: 20.289071 },
    { lat: 48.884351, lng: 20.288794 },
    { lat: 48.883772, lng: 20.289043 },
    { lat: 48.883056, lng: 20.289281 },
    { lat: 48.882464, lng: 20.289032 },
    { lat: 48.880566, lng: 20.288874 },
    { lat: 48.877318, lng: 20.288781 },
    { lat: 48.876647, lng: 20.28875 },
    { lat: 48.876185, lng: 20.28873 },
    { lat: 48.876092, lng: 20.288726 },
    { lat: 48.875962, lng: 20.28876 },
    { lat: 48.875859, lng: 20.288786 },
    { lat: 48.875766, lng: 20.288811 },
    { lat: 48.875699, lng: 20.288828 },
    { lat: 48.87566, lng: 20.288838 },
    { lat: 48.8756057, lng: 20.2888425 },
    { lat: 48.875528, lng: 20.2890165 },
    { lat: 48.8754703, lng: 20.2889817 },
    { lat: 48.8751678, lng: 20.2881582 },
    { lat: 48.8745804, lng: 20.2871919 },
    { lat: 48.8733154, lng: 20.2862923 },
    { lat: 48.8718833, lng: 20.2854673 },
    { lat: 48.8712152, lng: 20.2848585 },
    { lat: 48.870912, lng: 20.2843882 },
    { lat: 48.87047, lng: 20.2835177 },
    { lat: 48.8701573, lng: 20.2825775 },
    { lat: 48.870025, lng: 20.2819899 },
    { lat: 48.8698541, lng: 20.2807315 },
    { lat: 48.8697881, lng: 20.27962 },
    { lat: 48.8698172, lng: 20.2789313 },
    { lat: 48.8690209, lng: 20.2787012 },
    { lat: 48.8683308, lng: 20.2783013 },
    { lat: 48.8672828, lng: 20.2764811 },
    { lat: 48.8669308, lng: 20.2771478 },
    { lat: 48.8677665, lng: 20.2788712 },
    { lat: 48.8680999, lng: 20.2793319 },
    { lat: 48.8682191, lng: 20.2797887 },
    { lat: 48.8685385, lng: 20.2801773 },
    { lat: 48.8689603, lng: 20.280943 },
    { lat: 48.8698283, lng: 20.2835447 },
    { lat: 48.870112, lng: 20.2836605 },
    { lat: 48.8704204, lng: 20.284146 },
    { lat: 48.8707296, lng: 20.2860262 },
    { lat: 48.8705367, lng: 20.2865973 },
    { lat: 48.8708533, lng: 20.2873171 },
    { lat: 48.8715847, lng: 20.2885065 },
    { lat: 48.8720643, lng: 20.2889058 },
    { lat: 48.8723698, lng: 20.2898678 },
    { lat: 48.8727879, lng: 20.29039 },
    { lat: 48.8731205, lng: 20.2905436 },
    { lat: 48.8734028, lng: 20.2917166 },
    { lat: 48.872907, lng: 20.2914808 },
    { lat: 48.8721884, lng: 20.292249 },
    { lat: 48.8720102, lng: 20.2934259 },
    { lat: 48.8723929, lng: 20.2939957 },
    { lat: 48.872325, lng: 20.294162 },
    { lat: 48.8718777, lng: 20.2945253 },
    { lat: 48.8717947, lng: 20.2944476 },
    { lat: 48.8713575, lng: 20.2947884 },
    { lat: 48.8711071, lng: 20.2945151 },
    { lat: 48.8710335, lng: 20.2945654 },
    { lat: 48.8703088, lng: 20.2955295 },
    { lat: 48.8698143, lng: 20.296015 },
    { lat: 48.869094, lng: 20.2970612 },
    { lat: 48.8687325, lng: 20.2973226 },
    { lat: 48.8682611, lng: 20.2973288 },
    { lat: 48.8682285, lng: 20.297419 },
    { lat: 48.8683596, lng: 20.2977789 },
    { lat: 48.8682659, lng: 20.2985442 },
    { lat: 48.8680043, lng: 20.299718 },
    { lat: 48.8683324, lng: 20.3009723 },
    { lat: 48.8684754, lng: 20.3007649 },
    { lat: 48.8685353, lng: 20.2993348 },
    { lat: 48.8686779, lng: 20.2988585 },
    { lat: 48.8686138, lng: 20.2987222 },
    { lat: 48.8685814, lng: 20.2984658 },
    { lat: 48.8686571, lng: 20.298303 },
    { lat: 48.8690008, lng: 20.298421 },
    { lat: 48.8694467, lng: 20.298769 },
    { lat: 48.8695169, lng: 20.2987162 },
    { lat: 48.8696373, lng: 20.2984582 },
    { lat: 48.8700632, lng: 20.2981746 },
    { lat: 48.8712708, lng: 20.2977245 },
    { lat: 48.8712784, lng: 20.2980644 },
    { lat: 48.8716304, lng: 20.2979959 },
    { lat: 48.8722352, lng: 20.2982242 },
    { lat: 48.8723572, lng: 20.2984053 },
    { lat: 48.8722842, lng: 20.2988136 },
    { lat: 48.872469, lng: 20.2989043 },
    { lat: 48.8726878, lng: 20.2988296 },
    { lat: 48.8732456, lng: 20.2988392 },
    { lat: 48.8733404, lng: 20.2989899 },
    { lat: 48.873412, lng: 20.2993222 },
    { lat: 48.8733032, lng: 20.2999391 },
    { lat: 48.8730549, lng: 20.2995754 },
    { lat: 48.8728481, lng: 20.2995861 },
    { lat: 48.8726444, lng: 20.2998194 },
    { lat: 48.8726593, lng: 20.3000616 },
    { lat: 48.8727351, lng: 20.3001228 },
    { lat: 48.8727436, lng: 20.3002268 },
    { lat: 48.8729578, lng: 20.3004065 },
    { lat: 48.8730775, lng: 20.3006433 },
    { lat: 48.873065, lng: 20.301103 },
    { lat: 48.8729683, lng: 20.3011396 },
    { lat: 48.8728492, lng: 20.3009968 },
    { lat: 48.87269, lng: 20.3011317 },
    { lat: 48.8726023, lng: 20.3013685 },
    { lat: 48.8728457, lng: 20.3016006 },
    { lat: 48.8730131, lng: 20.3015913 },
    { lat: 48.873487, lng: 20.3017627 },
    { lat: 48.8736078, lng: 20.3018817 },
    { lat: 48.8739452, lng: 20.3044697 },
    { lat: 48.8744461, lng: 20.3043146 },
    { lat: 48.8748743, lng: 20.3053655 },
    { lat: 48.8753471, lng: 20.3063419 },
    { lat: 48.8752497, lng: 20.306356 },
    { lat: 48.8751614, lng: 20.3065485 },
    { lat: 48.875289, lng: 20.3077377 },
    { lat: 48.8752824, lng: 20.3080284 },
    { lat: 48.8750358, lng: 20.3082032 },
    { lat: 48.8750891, lng: 20.3085297 },
    { lat: 48.8755193, lng: 20.3083012 },
    { lat: 48.875684, lng: 20.308368 },
    { lat: 48.8760866, lng: 20.3094223 },
    { lat: 48.8765676, lng: 20.3102883 },
    { lat: 48.877233, lng: 20.3106637 },
    { lat: 48.8773795, lng: 20.3111593 },
    { lat: 48.8774725, lng: 20.3110021 },
    { lat: 48.8777669, lng: 20.3113639 },
    { lat: 48.8781792, lng: 20.3117413 },
    { lat: 48.8785803, lng: 20.3120066 },
    { lat: 48.8791114, lng: 20.3121672 },
    { lat: 48.8800461, lng: 20.3121136 },
    { lat: 48.8799919, lng: 20.3119646 },
    { lat: 48.8801026, lng: 20.3119464 },
    { lat: 48.8800837, lng: 20.3118898 },
    { lat: 48.8802627, lng: 20.3117722 },
    { lat: 48.8808339, lng: 20.3111468 },
    { lat: 48.8812304, lng: 20.311264 },
    { lat: 48.8812991, lng: 20.3112343 },
    { lat: 48.882218, lng: 20.3108049 },
    { lat: 48.8822806, lng: 20.3105903 },
    { lat: 48.8823924, lng: 20.310454 },
    { lat: 48.8829475, lng: 20.3103982 },
    { lat: 48.8832938, lng: 20.3105274 },
    { lat: 48.8838368, lng: 20.3109346 },
    { lat: 48.884228, lng: 20.311412 },
    { lat: 48.8848037, lng: 20.3122563 },
    { lat: 48.8852433, lng: 20.3129308 },
    { lat: 48.8854841, lng: 20.313417 },
    { lat: 48.8855561, lng: 20.3138478 },
    { lat: 48.8854849, lng: 20.3144188 },
    { lat: 48.8852901, lng: 20.3149446 },
    { lat: 48.8846445, lng: 20.3153062 },
    { lat: 48.8848366, lng: 20.3162769 },
    { lat: 48.8851538, lng: 20.3161813 },
    { lat: 48.8852526, lng: 20.316434 },
    { lat: 48.8852451, lng: 20.316649 },
    { lat: 48.88519, lng: 20.3167373 },
    { lat: 48.8847401, lng: 20.3167514 },
    { lat: 48.8845905, lng: 20.3168113 },
    { lat: 48.8843971, lng: 20.317171 },
    { lat: 48.8842406, lng: 20.3176316 },
    { lat: 48.8841989, lng: 20.3176031 },
    { lat: 48.8839885, lng: 20.3178645 },
    { lat: 48.8838804, lng: 20.3178109 },
    { lat: 48.8838523, lng: 20.3175801 },
    { lat: 48.8833496, lng: 20.3180326 },
    { lat: 48.8825849, lng: 20.31835 },
    { lat: 48.882273, lng: 20.3183196 },
    { lat: 48.8815504, lng: 20.3180937 },
    { lat: 48.8811971, lng: 20.3180759 },
    { lat: 48.8805838, lng: 20.3181709 },
    { lat: 48.8801038, lng: 20.3183641 },
    { lat: 48.8796318, lng: 20.3183945 },
    { lat: 48.8790807, lng: 20.3189854 },
    { lat: 48.8788001, lng: 20.3193901 },
    { lat: 48.8783922, lng: 20.3197557 },
    { lat: 48.878109, lng: 20.3199431 },
    { lat: 48.877675, lng: 20.3200509 },
    { lat: 48.8774426, lng: 20.3201858 },
    { lat: 48.8768805, lng: 20.320587 },
    { lat: 48.8765493, lng: 20.3209576 },
    { lat: 48.8759019, lng: 20.3212226 },
    { lat: 48.8755008, lng: 20.3211572 },
    { lat: 48.8753745, lng: 20.3210644 },
    { lat: 48.8752257, lng: 20.3211202 },
    { lat: 48.8751471, lng: 20.3210364 },
    { lat: 48.8750176, lng: 20.3210963 },
    { lat: 48.8748689, lng: 20.3212122 },
    { lat: 48.8745436, lng: 20.3217675 },
    { lat: 48.8740384, lng: 20.3224401 },
    { lat: 48.8739661, lng: 20.3231306 },
    { lat: 48.8740727, lng: 20.323238 },
    { lat: 48.8741373, lng: 20.3234497 },
    { lat: 48.8742364, lng: 20.3235328 },
    { lat: 48.874533, lng: 20.3235453 },
    { lat: 48.8748209, lng: 20.3234477 },
    { lat: 48.8750403, lng: 20.3232703 },
    { lat: 48.8753055, lng: 20.3231987 },
    { lat: 48.8755108, lng: 20.3233567 },
    { lat: 48.8755376, lng: 20.3236387 },
    { lat: 48.8753828, lng: 20.3242038 },
    { lat: 48.8752029, lng: 20.3244975 },
    { lat: 48.8750202, lng: 20.3249886 },
    { lat: 48.8748127, lng: 20.3252657 },
    { lat: 48.8747508, lng: 20.325532 },
    { lat: 48.8748472, lng: 20.3257527 },
    { lat: 48.8750087, lng: 20.3259369 },
    { lat: 48.8751319, lng: 20.3259212 },
    { lat: 48.8751848, lng: 20.3260667 },
    { lat: 48.8751667, lng: 20.3262322 },
    { lat: 48.8750006, lng: 20.3265668 },
    { lat: 48.8751348, lng: 20.3270092 },
    { lat: 48.8755768, lng: 20.3273128 },
    { lat: 48.8758451, lng: 20.3276419 },
    { lat: 48.8758989, lng: 20.3278071 },
    { lat: 48.8758937, lng: 20.3282558 },
    { lat: 48.8756505, lng: 20.3284291 },
    { lat: 48.8750899, lng: 20.3285504 },
    { lat: 48.8748243, lng: 20.3285247 },
    { lat: 48.8744864, lng: 20.3288707 },
    { lat: 48.874422, lng: 20.3290954 },
    { lat: 48.8744548, lng: 20.3292788 },
    { lat: 48.8746107, lng: 20.3296101 },
    { lat: 48.874709, lng: 20.3296146 },
    { lat: 48.8747713, lng: 20.3297103 },
    { lat: 48.8747791, lng: 20.3303796 },
    { lat: 48.8746319, lng: 20.3307047 },
    { lat: 48.8745463, lng: 20.3307359 },
    { lat: 48.8743245, lng: 20.3306452 },
    { lat: 48.8739783, lng: 20.3301989 },
    { lat: 48.8736464, lng: 20.329077 },
    { lat: 48.8735925, lng: 20.3285598 },
    { lat: 48.8735094, lng: 20.3283569 },
    { lat: 48.8732278, lng: 20.3283545 },
    { lat: 48.8727827, lng: 20.3274781 },
    { lat: 48.8726093, lng: 20.3275701 },
    { lat: 48.8724565, lng: 20.3284784 },
    { lat: 48.872285, lng: 20.3288439 },
    { lat: 48.872296, lng: 20.3292421 },
    { lat: 48.8721842, lng: 20.3301085 },
    { lat: 48.8719807, lng: 20.3306353 },
    { lat: 48.871625, lng: 20.3313062 },
    { lat: 48.8713668, lng: 20.3315904 },
    { lat: 48.8712046, lng: 20.331906 },
    { lat: 48.8706965, lng: 20.3325652 },
    { lat: 48.8704326, lng: 20.3324417 },
    { lat: 48.8701312, lng: 20.3333538 },
    { lat: 48.8700193, lng: 20.3339469 },
    { lat: 48.8700082, lng: 20.3343606 },
    { lat: 48.869861, lng: 20.335038 },
    { lat: 48.8698617, lng: 20.3373207 },
    { lat: 48.8697806, lng: 20.3371155 },
    { lat: 48.8696593, lng: 20.3370111 },
    { lat: 48.8695517, lng: 20.3371391 },
    { lat: 48.8694411, lng: 20.3370742 },
    { lat: 48.8693552, lng: 20.3373293 },
    { lat: 48.8691875, lng: 20.3376172 },
    { lat: 48.8690821, lng: 20.3375461 },
    { lat: 48.8690262, lng: 20.3376137 },
    { lat: 48.8689044, lng: 20.3376077 },
    { lat: 48.8687072, lng: 20.3375172 },
    { lat: 48.868566, lng: 20.3373621 },
    { lat: 48.8683728, lng: 20.3373159 },
    { lat: 48.868289, lng: 20.3374493 },
    { lat: 48.8691791, lng: 20.338681 },
    { lat: 48.8692013, lng: 20.3387895 },
    { lat: 48.869142, lng: 20.3388549 },
    { lat: 48.8691338, lng: 20.3390516 },
    { lat: 48.869194, lng: 20.3401737 },
    { lat: 48.8691259, lng: 20.3404201 },
    { lat: 48.8691676, lng: 20.3407112 },
    { lat: 48.8690101, lng: 20.3412678 },
    { lat: 48.8690884, lng: 20.3413395 },
    { lat: 48.8691152, lng: 20.3417145 },
    { lat: 48.8689643, lng: 20.3421768 },
    { lat: 48.8680345, lng: 20.3442514 },
    { lat: 48.8677297, lng: 20.3458037 },
    { lat: 48.8677924, lng: 20.34635 },
    { lat: 48.8677148, lng: 20.3463215 },
    { lat: 48.8675834, lng: 20.3468521 },
    { lat: 48.8676244, lng: 20.3468689 },
    { lat: 48.8676259, lng: 20.3470794 },
    { lat: 48.8675779, lng: 20.3473273 },
    { lat: 48.8676242, lng: 20.3473278 },
    { lat: 48.867638, lng: 20.347539 },
    { lat: 48.8672629, lng: 20.3477412 },
    { lat: 48.8666731, lng: 20.3478597 },
    { lat: 48.8661494, lng: 20.3484149 },
    { lat: 48.8651118, lng: 20.3491029 },
    { lat: 48.864423, lng: 20.3498103 },
    { lat: 48.864128, lng: 20.3500103 },
    { lat: 48.8637926, lng: 20.3501345 },
    { lat: 48.8632656, lng: 20.3500733 },
    { lat: 48.8632648, lng: 20.3501989 },
    { lat: 48.8624776, lng: 20.3502588 },
    { lat: 48.8612235, lng: 20.3497972 },
    { lat: 48.8600489, lng: 20.3496204 },
    { lat: 48.8585354, lng: 20.3497959 },
    { lat: 48.857464, lng: 20.3503913 },
    { lat: 48.856511, lng: 20.351052 },
    { lat: 48.8562138, lng: 20.3513494 },
    { lat: 48.8559555, lng: 20.3517732 },
    { lat: 48.855491, lng: 20.3528625 },
    { lat: 48.8550556, lng: 20.3542721 },
    { lat: 48.8552524, lng: 20.3541498 },
    { lat: 48.8554935, lng: 20.3540687 },
    { lat: 48.8555676, lng: 20.3547287 },
    { lat: 48.8555495, lng: 20.3551948 },
    { lat: 48.8556556, lng: 20.3553471 },
    { lat: 48.8558863, lng: 20.3563965 },
    { lat: 48.8558224, lng: 20.3567204 },
    { lat: 48.8560118, lng: 20.3568785 },
    { lat: 48.8564805, lng: 20.357786 },
    { lat: 48.8567839, lng: 20.3582673 },
    { lat: 48.8569084, lng: 20.3588094 },
    { lat: 48.8569681, lng: 20.3588943 },
    { lat: 48.8569423, lng: 20.3590771 },
    { lat: 48.8570817, lng: 20.3592704 },
    { lat: 48.8570867, lng: 20.3594005 },
    { lat: 48.8569491, lng: 20.3596015 },
    { lat: 48.8569605, lng: 20.3598111 },
    { lat: 48.8571551, lng: 20.3603832 },
    { lat: 48.8572625, lng: 20.3604824 },
    { lat: 48.8571637, lng: 20.3608469 },
    { lat: 48.8572157, lng: 20.3609954 },
    { lat: 48.8571871, lng: 20.3611885 },
    { lat: 48.8570846, lng: 20.3613331 },
    { lat: 48.8569388, lng: 20.3617272 },
    { lat: 48.8571592, lng: 20.3621558 },
    { lat: 48.8571752, lng: 20.3624855 },
    { lat: 48.8571287, lng: 20.3627099 },
    { lat: 48.8573864, lng: 20.3630844 },
    { lat: 48.8575346, lng: 20.363568 },
    { lat: 48.8575274, lng: 20.3638525 },
    { lat: 48.8576912, lng: 20.3643015 },
    { lat: 48.8577143, lng: 20.3645533 },
    { lat: 48.8578357, lng: 20.3648365 },
    { lat: 48.8578601, lng: 20.365035 },
    { lat: 48.8578031, lng: 20.3658357 },
    { lat: 48.8580553, lng: 20.3665383 },
    { lat: 48.8584612, lng: 20.3673427 },
    { lat: 48.8587441, lng: 20.3686902 },
    { lat: 48.8591716, lng: 20.3694434 },
    { lat: 48.8596187, lng: 20.3700305 },
    { lat: 48.8602173, lng: 20.3709546 },
    { lat: 48.8604619, lng: 20.3715734 },
    { lat: 48.8607329, lng: 20.3731459 },
    { lat: 48.8609378, lng: 20.3739961 },
    { lat: 48.8614874, lng: 20.374464 },
    { lat: 48.862015, lng: 20.3746602 },
    { lat: 48.8618616, lng: 20.3747095 },
    { lat: 48.8621408, lng: 20.3749134 },
    { lat: 48.8623059, lng: 20.3749289 },
    { lat: 48.8627874, lng: 20.3745706 },
    { lat: 48.8634245, lng: 20.3743555 },
    { lat: 48.8638739, lng: 20.3738935 },
    { lat: 48.8638873, lng: 20.3737993 },
    { lat: 48.8648606, lng: 20.3748975 },
    { lat: 48.8654101, lng: 20.3758043 },
    { lat: 48.8661786, lng: 20.3767565 },
    { lat: 48.8675952, lng: 20.3779277 },
    { lat: 48.8680044, lng: 20.3777943 },
    { lat: 48.8681142, lng: 20.3773421 },
    { lat: 48.8679548, lng: 20.3772403 },
    { lat: 48.8681237, lng: 20.3768828 },
    { lat: 48.868181, lng: 20.3765619 },
    { lat: 48.8681334, lng: 20.3765189 },
    { lat: 48.8681455, lng: 20.3763368 },
    { lat: 48.8682776, lng: 20.3765834 },
    { lat: 48.8683674, lng: 20.3766048 },
    { lat: 48.8685775, lng: 20.3764704 },
    { lat: 48.8690666, lng: 20.3764823 },
    { lat: 48.8691771, lng: 20.3769257 },
    { lat: 48.8692454, lng: 20.3768317 },
    { lat: 48.8696509, lng: 20.3767745 },
    { lat: 48.8693589, lng: 20.3785836 },
    { lat: 48.8695912, lng: 20.38006 },
    { lat: 48.8706307, lng: 20.3802416 },
    { lat: 48.8705877, lng: 20.3820235 },
    { lat: 48.8707412, lng: 20.3834113 },
    { lat: 48.8709787, lng: 20.3842632 },
    { lat: 48.8723505, lng: 20.3847965 },
    { lat: 48.8742651, lng: 20.3840487 },
    { lat: 48.8745123, lng: 20.3837372 },
    { lat: 48.8746169, lng: 20.3833531 },
    { lat: 48.8747994, lng: 20.3831531 },
    { lat: 48.8747829, lng: 20.3844198 },
    { lat: 48.8750699, lng: 20.3858003 },
    { lat: 48.8750622, lng: 20.3861852 },
    { lat: 48.8748247, lng: 20.3867799 },
    { lat: 48.8747375, lng: 20.38762 },
    { lat: 48.8745784, lng: 20.3884907 },
    { lat: 48.8744055, lng: 20.3888826 },
    { lat: 48.8741531, lng: 20.3892604 },
    { lat: 48.8737957, lng: 20.3892489 },
    { lat: 48.8727105, lng: 20.3885511 },
    { lat: 48.8725279, lng: 20.3897225 },
    { lat: 48.8727455, lng: 20.3905804 },
    { lat: 48.8730616, lng: 20.3906968 },
    { lat: 48.8733637, lng: 20.3907038 },
    { lat: 48.8735272, lng: 20.391177 },
    { lat: 48.8734026, lng: 20.3914548 },
    { lat: 48.8734687, lng: 20.3918575 },
    { lat: 48.873254, lng: 20.3921904 },
    { lat: 48.8741874, lng: 20.3925086 },
    { lat: 48.874511, lng: 20.3924068 },
    { lat: 48.874531, lng: 20.392454 },
    { lat: 48.874793, lng: 20.391458 },
    { lat: 48.875085, lng: 20.391205 },
    { lat: 48.876079, lng: 20.39092 },
    { lat: 48.876187, lng: 20.391345 },
    { lat: 48.876316, lng: 20.391534 },
    { lat: 48.876352, lng: 20.391579 },
    { lat: 48.876503, lng: 20.391672 },
    { lat: 48.876911, lng: 20.391713 },
    { lat: 48.877775, lng: 20.391692 },
    { lat: 48.87801, lng: 20.391601 },
    { lat: 48.878627, lng: 20.392402 },
    { lat: 48.878937, lng: 20.393079 },
    { lat: 48.879331, lng: 20.393016 },
    { lat: 48.879635, lng: 20.392834 },
    { lat: 48.880128, lng: 20.392275 },
    { lat: 48.880141, lng: 20.39226 },
    { lat: 48.880157, lng: 20.392265 },
    { lat: 48.880825, lng: 20.392493 },
    { lat: 48.882501, lng: 20.391321 },
    { lat: 48.882834, lng: 20.391078 },
    { lat: 48.883625, lng: 20.390505 },
    { lat: 48.884025, lng: 20.389916 },
    { lat: 48.884253, lng: 20.389583 },
    { lat: 48.884559, lng: 20.389559 },
    { lat: 48.88543, lng: 20.390162 },
    { lat: 48.885771, lng: 20.390167 },
    { lat: 48.885903, lng: 20.390419 },
    { lat: 48.886593, lng: 20.390998 },
    { lat: 48.886672, lng: 20.391046 },
    { lat: 48.88714, lng: 20.39133 },
    { lat: 48.887232, lng: 20.391386 },
    { lat: 48.887484, lng: 20.391474 },
    { lat: 48.887796, lng: 20.391749 },
    { lat: 48.888016, lng: 20.391959 },
    { lat: 48.888488, lng: 20.39223 },
    { lat: 48.888966, lng: 20.392407 },
    { lat: 48.889412, lng: 20.39277 },
    { lat: 48.889906, lng: 20.392883 },
    { lat: 48.89012, lng: 20.392298 },
    { lat: 48.890198, lng: 20.391147 },
    { lat: 48.890275, lng: 20.390922 },
    { lat: 48.890529, lng: 20.390264 },
    { lat: 48.890835, lng: 20.389695 },
    { lat: 48.891037, lng: 20.38932 },
    { lat: 48.891164, lng: 20.389085 },
    { lat: 48.8915, lng: 20.388903 },
    { lat: 48.892172, lng: 20.38869 },
    { lat: 48.892616, lng: 20.388726 },
    { lat: 48.89295, lng: 20.388058 },
    { lat: 48.893216, lng: 20.38771 },
    { lat: 48.893497, lng: 20.387441 },
    { lat: 48.893775, lng: 20.38741 },
    { lat: 48.893839, lng: 20.387413 },
    { lat: 48.893984, lng: 20.387222 },
    { lat: 48.894031, lng: 20.387039 },
    { lat: 48.894351, lng: 20.386371 },
    { lat: 48.894522, lng: 20.38481 },
    { lat: 48.895427, lng: 20.384163 },
    { lat: 48.896004, lng: 20.383394 },
    { lat: 48.896453, lng: 20.382698 },
    { lat: 48.896957, lng: 20.382065 },
    { lat: 48.897539, lng: 20.38177 },
    { lat: 48.897625, lng: 20.382822 },
    { lat: 48.898228, lng: 20.383496 },
    { lat: 48.898428, lng: 20.383562 },
    { lat: 48.898592, lng: 20.383585 },
    { lat: 48.899113, lng: 20.383374 },
    { lat: 48.899624, lng: 20.383221 },
    { lat: 48.899961, lng: 20.383078 },
    { lat: 48.900446, lng: 20.383111 },
    { lat: 48.900873, lng: 20.383286 },
    { lat: 48.901437, lng: 20.383663 },
    { lat: 48.901674, lng: 20.384407 },
    { lat: 48.902133, lng: 20.385144 },
    { lat: 48.902509, lng: 20.38552 },
    { lat: 48.903003, lng: 20.386507 },
    { lat: 48.903101, lng: 20.386915 },
    { lat: 48.903055, lng: 20.387196 },
    { lat: 48.903134, lng: 20.388021 },
    { lat: 48.903235, lng: 20.388529 },
    { lat: 48.903572, lng: 20.389137 },
    { lat: 48.903555, lng: 20.389667 },
    { lat: 48.903748, lng: 20.389882 },
    { lat: 48.903646, lng: 20.390975 },
    { lat: 48.903815, lng: 20.391617 },
    { lat: 48.903666, lng: 20.391878 },
    { lat: 48.903544, lng: 20.392288 },
    { lat: 48.903188, lng: 20.392723 },
    { lat: 48.903181, lng: 20.393085 },
    { lat: 48.90314, lng: 20.393271 },
    { lat: 48.903112, lng: 20.393531 },
    { lat: 48.903099, lng: 20.393716 },
    { lat: 48.902938, lng: 20.394051 },
    { lat: 48.902832, lng: 20.394081 },
    { lat: 48.902639, lng: 20.394503 },
    { lat: 48.902525, lng: 20.394603 },
    { lat: 48.902403, lng: 20.3946 },
    { lat: 48.90218, lng: 20.394821 },
    { lat: 48.902172, lng: 20.394944 },
    { lat: 48.902142, lng: 20.395038 },
    { lat: 48.902092, lng: 20.395107 },
    { lat: 48.902054, lng: 20.39516 },
    { lat: 48.901991, lng: 20.39521 },
    { lat: 48.901981, lng: 20.395459 },
    { lat: 48.901989, lng: 20.395724 },
    { lat: 48.90197, lng: 20.395944 },
    { lat: 48.902012, lng: 20.395986 },
    { lat: 48.902048, lng: 20.396022 },
    { lat: 48.902078, lng: 20.396051 },
    { lat: 48.902073, lng: 20.396272 },
    { lat: 48.901994, lng: 20.396431 },
    { lat: 48.901956, lng: 20.396506 },
    { lat: 48.901978, lng: 20.396667 },
    { lat: 48.901829, lng: 20.396933 },
    { lat: 48.901968, lng: 20.397339 },
    { lat: 48.901909, lng: 20.397536 },
    { lat: 48.902042, lng: 20.397874 },
    { lat: 48.901939, lng: 20.398 },
    { lat: 48.901982, lng: 20.39809 },
    { lat: 48.901976, lng: 20.398227 },
    { lat: 48.90188, lng: 20.398284 },
    { lat: 48.901927, lng: 20.398684 },
    { lat: 48.902037, lng: 20.398768 },
    { lat: 48.901999, lng: 20.398923 },
    { lat: 48.90207, lng: 20.399106 },
    { lat: 48.902005, lng: 20.399391 },
    { lat: 48.902094, lng: 20.399571 },
    { lat: 48.901968, lng: 20.399924 },
    { lat: 48.902094, lng: 20.40007 },
    { lat: 48.90201, lng: 20.400224 },
    { lat: 48.902066, lng: 20.400321 },
    { lat: 48.902142, lng: 20.400783 },
    { lat: 48.902255, lng: 20.400861 },
    { lat: 48.902377, lng: 20.401096 },
    { lat: 48.902347, lng: 20.401203 },
    { lat: 48.902413, lng: 20.401301 },
    { lat: 48.902412, lng: 20.401676 },
    { lat: 48.902503, lng: 20.401778 },
    { lat: 48.902487, lng: 20.402255 },
    { lat: 48.902508, lng: 20.402305 },
    { lat: 48.902468, lng: 20.402575 },
    { lat: 48.902536, lng: 20.4026 },
    { lat: 48.902548, lng: 20.402894 },
    { lat: 48.90255, lng: 20.402926 },
    { lat: 48.902616, lng: 20.403251 },
    { lat: 48.902634, lng: 20.403262 },
    { lat: 48.902691, lng: 20.403296 },
    { lat: 48.90278, lng: 20.403349 },
    { lat: 48.902883, lng: 20.403563 },
    { lat: 48.902886, lng: 20.403931 },
    { lat: 48.90298, lng: 20.404037 },
    { lat: 48.903009, lng: 20.404275 },
    { lat: 48.902996, lng: 20.404539 },
    { lat: 48.90305, lng: 20.404767 },
    { lat: 48.90297, lng: 20.404938 },
    { lat: 48.902988, lng: 20.405027 },
    { lat: 48.903011, lng: 20.405083 },
    { lat: 48.903202, lng: 20.405227 },
    { lat: 48.903344, lng: 20.405559 },
    { lat: 48.903222, lng: 20.405725 },
    { lat: 48.903064, lng: 20.405823 },
    { lat: 48.903048, lng: 20.405976 },
    { lat: 48.903226, lng: 20.406275 },
    { lat: 48.903391, lng: 20.406506 },
    { lat: 48.903376, lng: 20.406877 },
    { lat: 48.903294, lng: 20.407003 },
    { lat: 48.903269, lng: 20.407079 },
    { lat: 48.90323, lng: 20.407254 },
    { lat: 48.903383, lng: 20.407608 },
    { lat: 48.903379, lng: 20.407865 },
    { lat: 48.903485, lng: 20.407988 },
    { lat: 48.903527, lng: 20.408042 },
    { lat: 48.903531, lng: 20.408144 },
    { lat: 48.903559, lng: 20.408558 },
    { lat: 48.903671, lng: 20.408801 },
    { lat: 48.903931, lng: 20.408814 },
    { lat: 48.904022, lng: 20.408905 },
    { lat: 48.904051, lng: 20.409089 },
    { lat: 48.903951, lng: 20.409342 },
    { lat: 48.904005, lng: 20.409574 },
    { lat: 48.904065, lng: 20.409569 },
    { lat: 48.904129, lng: 20.409748 },
    { lat: 48.904118, lng: 20.41003 },
    { lat: 48.904419, lng: 20.410263 },
    { lat: 48.90446, lng: 20.410361 },
    { lat: 48.904475, lng: 20.410821 },
    { lat: 48.904543, lng: 20.411002 },
    { lat: 48.904639, lng: 20.411096 },
    { lat: 48.904699, lng: 20.411305 },
    { lat: 48.90464, lng: 20.411411 },
    { lat: 48.904736, lng: 20.411692 },
    { lat: 48.904682, lng: 20.411876 },
    { lat: 48.904785, lng: 20.412146 },
    { lat: 48.904761, lng: 20.412338 },
    { lat: 48.904711, lng: 20.412483 },
    { lat: 48.904803, lng: 20.412615 },
    { lat: 48.904937, lng: 20.412612 },
    { lat: 48.905289, lng: 20.413396 },
    { lat: 48.905456, lng: 20.41344 },
    { lat: 48.905573, lng: 20.41362 },
    { lat: 48.905595, lng: 20.413909 },
    { lat: 48.905969, lng: 20.414324 },
    { lat: 48.906105, lng: 20.41462 },
    { lat: 48.906447, lng: 20.414759 },
    { lat: 48.906656, lng: 20.41465 },
    { lat: 48.906969, lng: 20.415014 },
    { lat: 48.906981, lng: 20.415021 },
    { lat: 48.907425, lng: 20.414835 },
    { lat: 48.90746, lng: 20.414837 },
    { lat: 48.907586, lng: 20.414909 },
    { lat: 48.907704, lng: 20.414786 },
    { lat: 48.907851, lng: 20.414781 },
    { lat: 48.908054, lng: 20.414975 },
    { lat: 48.908174, lng: 20.414958 },
    { lat: 48.90828, lng: 20.415042 },
    { lat: 48.9083, lng: 20.414997 },
    { lat: 48.908376, lng: 20.414902 },
    { lat: 48.908428, lng: 20.414503 },
    { lat: 48.907934, lng: 20.411669 },
    { lat: 48.907392, lng: 20.408539 },
    { lat: 48.907339, lng: 20.407801 },
    { lat: 48.90736, lng: 20.407364 },
    { lat: 48.907752, lng: 20.405413 },
    { lat: 48.907888, lng: 20.404306 },
    { lat: 48.907955, lng: 20.403935 },
    { lat: 48.908113, lng: 20.4027218 },
    { lat: 48.908217, lng: 20.400212 },
    { lat: 48.9082882, lng: 20.3986831 },
    { lat: 48.9082865, lng: 20.3980494 },
    { lat: 48.908263, lng: 20.396993 },
    { lat: 48.90837, lng: 20.395585 },
    { lat: 48.908461, lng: 20.394609 },
    { lat: 48.908676, lng: 20.393213 },
    { lat: 48.908969, lng: 20.391195 },
    { lat: 48.908881, lng: 20.390926 },
    { lat: 48.909418, lng: 20.391091 },
    { lat: 48.91126, lng: 20.391559 },
  ],
  Lipovník: [
    { lat: 48.6322073, lng: 20.6027283 },
    { lat: 48.6320718, lng: 20.6029645 },
    { lat: 48.6310314, lng: 20.6047765 },
    { lat: 48.6292404, lng: 20.6075543 },
    { lat: 48.6282142, lng: 20.605138 },
    { lat: 48.6279256, lng: 20.6054994 },
    { lat: 48.6276422, lng: 20.6057494 },
    { lat: 48.6257218, lng: 20.601551 },
    { lat: 48.6246141, lng: 20.5996539 },
    { lat: 48.6243909, lng: 20.5988736 },
    { lat: 48.624276, lng: 20.5987515 },
    { lat: 48.6235106, lng: 20.5991876 },
    { lat: 48.6234307, lng: 20.5986683 },
    { lat: 48.6228824, lng: 20.5980841 },
    { lat: 48.6228365, lng: 20.5978164 },
    { lat: 48.6225649, lng: 20.5975323 },
    { lat: 48.622427, lng: 20.5970851 },
    { lat: 48.6221266, lng: 20.5967561 },
    { lat: 48.6221901, lng: 20.596308 },
    { lat: 48.6221563, lng: 20.5962486 },
    { lat: 48.6219469, lng: 20.5962187 },
    { lat: 48.6215184, lng: 20.595588 },
    { lat: 48.6215019, lng: 20.5953185 },
    { lat: 48.6215601, lng: 20.5952457 },
    { lat: 48.621833, lng: 20.5954418 },
    { lat: 48.6219349, lng: 20.5953656 },
    { lat: 48.6218153, lng: 20.5951464 },
    { lat: 48.6218382, lng: 20.59491 },
    { lat: 48.6219083, lng: 20.5947945 },
    { lat: 48.6218854, lng: 20.5942655 },
    { lat: 48.6217826, lng: 20.5941645 },
    { lat: 48.6219827, lng: 20.5937887 },
    { lat: 48.6219796, lng: 20.5932729 },
    { lat: 48.6219279, lng: 20.5932459 },
    { lat: 48.6217125, lng: 20.5934774 },
    { lat: 48.6215453, lng: 20.5933715 },
    { lat: 48.6217047, lng: 20.5930201 },
    { lat: 48.6213268, lng: 20.5927285 },
    { lat: 48.6212825, lng: 20.5924717 },
    { lat: 48.6213029, lng: 20.5922618 },
    { lat: 48.620916, lng: 20.5917511 },
    { lat: 48.6207313, lng: 20.5915954 },
    { lat: 48.6206679, lng: 20.5917078 },
    { lat: 48.620274, lng: 20.59153 },
    { lat: 48.6196634, lng: 20.5911529 },
    { lat: 48.6195924, lng: 20.5910315 },
    { lat: 48.6195297, lng: 20.5910381 },
    { lat: 48.6192612, lng: 20.5907481 },
    { lat: 48.6191946, lng: 20.5904658 },
    { lat: 48.6192391, lng: 20.5902048 },
    { lat: 48.6191422, lng: 20.589988 },
    { lat: 48.6189685, lng: 20.589794 },
    { lat: 48.6188465, lng: 20.589358 },
    { lat: 48.6184667, lng: 20.5897769 },
    { lat: 48.6178833, lng: 20.5907224 },
    { lat: 48.6162561, lng: 20.5914235 },
    { lat: 48.6146856, lng: 20.5916697 },
    { lat: 48.6127908, lng: 20.5916451 },
    { lat: 48.6111689, lng: 20.59138 },
    { lat: 48.6108493, lng: 20.5911708 },
    { lat: 48.6106111, lng: 20.5908189 },
    { lat: 48.6091883, lng: 20.5905525 },
    { lat: 48.6089228, lng: 20.5906156 },
    { lat: 48.6081263, lng: 20.5905463 },
    { lat: 48.6076752, lng: 20.5902748 },
    { lat: 48.6071546, lng: 20.5900853 },
    { lat: 48.6081639, lng: 20.5915372 },
    { lat: 48.6082326, lng: 20.5925774 },
    { lat: 48.6082298, lng: 20.5943377 },
    { lat: 48.6084629, lng: 20.5960922 },
    { lat: 48.6084347, lng: 20.5964553 },
    { lat: 48.6086962, lng: 20.5990421 },
    { lat: 48.6085094, lng: 20.6014709 },
    { lat: 48.6086504, lng: 20.6036302 },
    { lat: 48.608501, lng: 20.6055731 },
    { lat: 48.6083728, lng: 20.6057325 },
    { lat: 48.6082228, lng: 20.6060958 },
    { lat: 48.6080319, lng: 20.606815 },
    { lat: 48.6079863, lng: 20.6072016 },
    { lat: 48.607654, lng: 20.608208 },
    { lat: 48.6076718, lng: 20.609096 },
    { lat: 48.6076306, lng: 20.6095096 },
    { lat: 48.6069497, lng: 20.610502 },
    { lat: 48.606651, lng: 20.6111337 },
    { lat: 48.60658, lng: 20.6114463 },
    { lat: 48.606261, lng: 20.6121413 },
    { lat: 48.606221, lng: 20.6124033 },
    { lat: 48.6062269, lng: 20.61293 },
    { lat: 48.6061748, lng: 20.613172 },
    { lat: 48.6062203, lng: 20.6136734 },
    { lat: 48.6061056, lng: 20.6139231 },
    { lat: 48.6060763, lng: 20.6148192 },
    { lat: 48.6061545, lng: 20.6154488 },
    { lat: 48.6060952, lng: 20.6158917 },
    { lat: 48.6061219, lng: 20.6170809 },
    { lat: 48.6063374, lng: 20.6180858 },
    { lat: 48.6064306, lng: 20.6190938 },
    { lat: 48.6064142, lng: 20.6205267 },
    { lat: 48.6063413, lng: 20.6207786 },
    { lat: 48.6063116, lng: 20.6229889 },
    { lat: 48.6061752, lng: 20.6234489 },
    { lat: 48.6065826, lng: 20.6263131 },
    { lat: 48.6067015, lng: 20.6265213 },
    { lat: 48.6069891, lng: 20.6299321 },
    { lat: 48.6072372, lng: 20.6321404 },
    { lat: 48.6072738, lng: 20.6333914 },
    { lat: 48.6075566, lng: 20.6351611 },
    { lat: 48.6077799, lng: 20.6379276 },
    { lat: 48.6077869, lng: 20.6379971 },
    { lat: 48.6082009, lng: 20.6385478 },
    { lat: 48.6084438, lng: 20.6398113 },
    { lat: 48.6084824, lng: 20.6404695 },
    { lat: 48.6083424, lng: 20.6411112 },
    { lat: 48.6083707, lng: 20.6415774 },
    { lat: 48.6082436, lng: 20.6426972 },
    { lat: 48.6080677, lng: 20.6435918 },
    { lat: 48.6079179, lng: 20.6440219 },
    { lat: 48.6083869, lng: 20.6449103 },
    { lat: 48.6087516, lng: 20.6452474 },
    { lat: 48.6089461, lng: 20.6455177 },
    { lat: 48.6099543, lng: 20.6472754 },
    { lat: 48.6102144, lng: 20.6478321 },
    { lat: 48.6104642, lng: 20.6481107 },
    { lat: 48.6110603, lng: 20.6484657 },
    { lat: 48.6116053, lng: 20.6486809 },
    { lat: 48.6119954, lng: 20.6491643 },
    { lat: 48.6121888, lng: 20.6494945 },
    { lat: 48.6129848, lng: 20.6503365 },
    { lat: 48.6140598, lng: 20.6512311 },
    { lat: 48.6149903, lng: 20.6556618 },
    { lat: 48.6162864, lng: 20.6590913 },
    { lat: 48.6165144, lng: 20.6588904 },
    { lat: 48.6185702, lng: 20.6570837 },
    { lat: 48.6194419, lng: 20.6560971 },
    { lat: 48.6198084, lng: 20.6531615 },
    { lat: 48.6198406, lng: 20.6523037 },
    { lat: 48.6199902, lng: 20.6514327 },
    { lat: 48.6203044, lng: 20.6503165 },
    { lat: 48.6205192, lng: 20.6501818 },
    { lat: 48.6206499, lng: 20.6499667 },
    { lat: 48.6213321, lng: 20.6495621 },
    { lat: 48.6223702, lng: 20.6495032 },
    { lat: 48.6225963, lng: 20.6494031 },
    { lat: 48.6229296, lng: 20.6490934 },
    { lat: 48.6231062, lng: 20.6487454 },
    { lat: 48.6232564, lng: 20.6482963 },
    { lat: 48.6235019, lng: 20.6470238 },
    { lat: 48.6239075, lng: 20.6457618 },
    { lat: 48.6247101, lng: 20.6447924 },
    { lat: 48.6247564, lng: 20.6447672 },
    { lat: 48.6248001, lng: 20.6451668 },
    { lat: 48.6254596, lng: 20.6438824 },
    { lat: 48.6281681, lng: 20.6418544 },
    { lat: 48.6301363, lng: 20.6381745 },
    { lat: 48.6311145, lng: 20.6384799 },
    { lat: 48.6321248, lng: 20.6389256 },
    { lat: 48.6330438, lng: 20.6394921 },
    { lat: 48.6338732, lng: 20.6388897 },
    { lat: 48.6347216, lng: 20.6377296 },
    { lat: 48.6352437, lng: 20.637197 },
    { lat: 48.6358723, lng: 20.6367061 },
    { lat: 48.6365002, lng: 20.6361102 },
    { lat: 48.6365607, lng: 20.635855 },
    { lat: 48.6367712, lng: 20.6359134 },
    { lat: 48.6370679, lng: 20.6357165 },
    { lat: 48.6374898, lng: 20.6352441 },
    { lat: 48.6374489, lng: 20.6347346 },
    { lat: 48.6374979, lng: 20.6343503 },
    { lat: 48.6375491, lng: 20.6345191 },
    { lat: 48.6382075, lng: 20.635692 },
    { lat: 48.6382535, lng: 20.6356547 },
    { lat: 48.6382201, lng: 20.6355383 },
    { lat: 48.63822, lng: 20.6346398 },
    { lat: 48.6382806, lng: 20.6344778 },
    { lat: 48.6387075, lng: 20.6345122 },
    { lat: 48.6388828, lng: 20.6340143 },
    { lat: 48.6391568, lng: 20.6334637 },
    { lat: 48.6393583, lng: 20.6315442 },
    { lat: 48.6393317, lng: 20.6311242 },
    { lat: 48.6392415, lng: 20.6308715 },
    { lat: 48.6396558, lng: 20.6307805 },
    { lat: 48.6395931, lng: 20.6300734 },
    { lat: 48.6395144, lng: 20.6258728 },
    { lat: 48.6398068, lng: 20.6258671 },
    { lat: 48.6404933, lng: 20.6260081 },
    { lat: 48.6408908, lng: 20.6259826 },
    { lat: 48.6407692, lng: 20.6248716 },
    { lat: 48.6407584, lng: 20.624142 },
    { lat: 48.640863, lng: 20.6238926 },
    { lat: 48.6409206, lng: 20.6230317 },
    { lat: 48.6411896, lng: 20.6226911 },
    { lat: 48.6418107, lng: 20.6226157 },
    { lat: 48.6427266, lng: 20.6233087 },
    { lat: 48.6442079, lng: 20.6248469 },
    { lat: 48.6452682, lng: 20.6260471 },
    { lat: 48.6453796, lng: 20.6254529 },
    { lat: 48.6455797, lng: 20.6246772 },
    { lat: 48.6458895, lng: 20.6238736 },
    { lat: 48.646325, lng: 20.6231873 },
    { lat: 48.6462404, lng: 20.6230288 },
    { lat: 48.6461052, lng: 20.6228456 },
    { lat: 48.6458162, lng: 20.62273 },
    { lat: 48.6456988, lng: 20.6225066 },
    { lat: 48.6451114, lng: 20.6223923 },
    { lat: 48.6449534, lng: 20.6224396 },
    { lat: 48.6445695, lng: 20.6221022 },
    { lat: 48.6439987, lng: 20.6217309 },
    { lat: 48.6425746, lng: 20.6215793 },
    { lat: 48.6429179, lng: 20.619174 },
    { lat: 48.6431762, lng: 20.6184426 },
    { lat: 48.6433476, lng: 20.6176188 },
    { lat: 48.6436072, lng: 20.6171426 },
    { lat: 48.6432235, lng: 20.6167202 },
    { lat: 48.6437321, lng: 20.6162792 },
    { lat: 48.6440404, lng: 20.6159021 },
    { lat: 48.6444053, lng: 20.615106 },
    { lat: 48.6444002, lng: 20.6146881 },
    { lat: 48.644583, lng: 20.6145123 },
    { lat: 48.6446038, lng: 20.6143123 },
    { lat: 48.6445007, lng: 20.6139833 },
    { lat: 48.6441438, lng: 20.6135847 },
    { lat: 48.6437333, lng: 20.6133529 },
    { lat: 48.643288, lng: 20.6132394 },
    { lat: 48.6430062, lng: 20.6133522 },
    { lat: 48.6428876, lng: 20.6131605 },
    { lat: 48.6427956, lng: 20.6125537 },
    { lat: 48.6426498, lng: 20.6124775 },
    { lat: 48.6425103, lng: 20.6121716 },
    { lat: 48.6424358, lng: 20.6122262 },
    { lat: 48.6423402, lng: 20.6121394 },
    { lat: 48.6421385, lng: 20.6121655 },
    { lat: 48.6418513, lng: 20.6122876 },
    { lat: 48.6411122, lng: 20.6122117 },
    { lat: 48.640968, lng: 20.6121468 },
    { lat: 48.6403464, lng: 20.6122063 },
    { lat: 48.6398416, lng: 20.6123434 },
    { lat: 48.6390495, lng: 20.6115296 },
    { lat: 48.6387961, lng: 20.6114048 },
    { lat: 48.6373738, lng: 20.6101312 },
    { lat: 48.636577, lng: 20.6091546 },
    { lat: 48.6354067, lng: 20.6068955 },
    { lat: 48.6350455, lng: 20.6062902 },
    { lat: 48.6349418, lng: 20.605914 },
    { lat: 48.6342634, lng: 20.6051938 },
    { lat: 48.634038, lng: 20.6051542 },
    { lat: 48.6336628, lng: 20.6048694 },
    { lat: 48.6334594, lng: 20.6045699 },
    { lat: 48.6332718, lng: 20.6044286 },
    { lat: 48.6330992, lng: 20.6044248 },
    { lat: 48.6328328, lng: 20.6040643 },
    { lat: 48.6326655, lng: 20.6037401 },
    { lat: 48.6324292, lng: 20.6035317 },
    { lat: 48.6322782, lng: 20.603189 },
    { lat: 48.6322073, lng: 20.6027283 },
  ],
  JablonovnadTurňou: [
    { lat: 48.6077799, lng: 20.6379276 },
    { lat: 48.6076255, lng: 20.6380161 },
    { lat: 48.6074351, lng: 20.6380155 },
    { lat: 48.6060449, lng: 20.6373378 },
    { lat: 48.6059149, lng: 20.636986 },
    { lat: 48.6038604, lng: 20.6373959 },
    { lat: 48.6025844, lng: 20.6398012 },
    { lat: 48.602138, lng: 20.6402792 },
    { lat: 48.6018211, lng: 20.6409485 },
    { lat: 48.6009263, lng: 20.641949 },
    { lat: 48.6002163, lng: 20.6422975 },
    { lat: 48.6001673, lng: 20.6423788 },
    { lat: 48.5998356, lng: 20.6422941 },
    { lat: 48.5996549, lng: 20.6423403 },
    { lat: 48.5996295, lng: 20.6427262 },
    { lat: 48.5995846, lng: 20.6428274 },
    { lat: 48.5991642, lng: 20.643121 },
    { lat: 48.598789, lng: 20.6431456 },
    { lat: 48.5986506, lng: 20.6432715 },
    { lat: 48.5984879, lng: 20.6435656 },
    { lat: 48.5984143, lng: 20.6435841 },
    { lat: 48.5983789, lng: 20.6438193 },
    { lat: 48.5982443, lng: 20.6439596 },
    { lat: 48.5980655, lng: 20.6439647 },
    { lat: 48.5978897, lng: 20.644146 },
    { lat: 48.5977937, lng: 20.6440426 },
    { lat: 48.597563, lng: 20.6435167 },
    { lat: 48.5973503, lng: 20.6435197 },
    { lat: 48.5973221, lng: 20.6433993 },
    { lat: 48.5970798, lng: 20.6434186 },
    { lat: 48.59702, lng: 20.6437284 },
    { lat: 48.5969176, lng: 20.6438528 },
    { lat: 48.5960211, lng: 20.6441835 },
    { lat: 48.5958861, lng: 20.6443771 },
    { lat: 48.5957122, lng: 20.6444221 },
    { lat: 48.5955905, lng: 20.6445722 },
    { lat: 48.5957944, lng: 20.6452319 },
    { lat: 48.5956841, lng: 20.6455387 },
    { lat: 48.5948188, lng: 20.6470118 },
    { lat: 48.59467, lng: 20.6470717 },
    { lat: 48.5942773, lng: 20.6468925 },
    { lat: 48.5940088, lng: 20.6468481 },
    { lat: 48.5938155, lng: 20.6469498 },
    { lat: 48.5932286, lng: 20.6469725 },
    { lat: 48.5930915, lng: 20.6468853 },
    { lat: 48.592814, lng: 20.6469957 },
    { lat: 48.5924593, lng: 20.6469595 },
    { lat: 48.5924053, lng: 20.6470262 },
    { lat: 48.5923131, lng: 20.6469932 },
    { lat: 48.5918548, lng: 20.6470897 },
    { lat: 48.5917475, lng: 20.6470182 },
    { lat: 48.5915108, lng: 20.6470999 },
    { lat: 48.591102, lng: 20.6470714 },
    { lat: 48.5906219, lng: 20.6471476 },
    { lat: 48.5904679, lng: 20.6470906 },
    { lat: 48.5900417, lng: 20.647199 },
    { lat: 48.589191, lng: 20.6471816 },
    { lat: 48.5890502, lng: 20.6472405 },
    { lat: 48.5879118, lng: 20.6472725 },
    { lat: 48.5869287, lng: 20.6469207 },
    { lat: 48.5867595, lng: 20.6466665 },
    { lat: 48.5857152, lng: 20.6458721 },
    { lat: 48.5847837, lng: 20.6447118 },
    { lat: 48.5837322, lng: 20.6437836 },
    { lat: 48.5825147, lng: 20.6431579 },
    { lat: 48.5822332, lng: 20.6429031 },
    { lat: 48.581799, lng: 20.6427888 },
    { lat: 48.5804931, lng: 20.6428592 },
    { lat: 48.5788586, lng: 20.6438602 },
    { lat: 48.5754595, lng: 20.6440399 },
    { lat: 48.575458, lng: 20.6439732 },
    { lat: 48.5746585, lng: 20.6442741 },
    { lat: 48.5745543, lng: 20.6447887 },
    { lat: 48.5746285, lng: 20.6448935 },
    { lat: 48.5745435, lng: 20.6451426 },
    { lat: 48.5746556, lng: 20.6457783 },
    { lat: 48.5722795, lng: 20.6474407 },
    { lat: 48.5703928, lng: 20.6480408 },
    { lat: 48.5687504, lng: 20.6473937 },
    { lat: 48.5685833, lng: 20.6473801 },
    { lat: 48.5663733, lng: 20.6533429 },
    { lat: 48.5656025, lng: 20.6556866 },
    { lat: 48.5662528, lng: 20.6568538 },
    { lat: 48.5672248, lng: 20.6587973 },
    { lat: 48.5645488, lng: 20.6603569 },
    { lat: 48.5629118, lng: 20.6611023 },
    { lat: 48.560509, lng: 20.663034 },
    { lat: 48.560478, lng: 20.663043 },
    { lat: 48.560361, lng: 20.663769 },
    { lat: 48.560249, lng: 20.664248 },
    { lat: 48.560116, lng: 20.66473 },
    { lat: 48.559942, lng: 20.665601 },
    { lat: 48.560425, lng: 20.666397 },
    { lat: 48.560684, lng: 20.66675 },
    { lat: 48.561218, lng: 20.66787 },
    { lat: 48.561704, lng: 20.66889 },
    { lat: 48.56186, lng: 20.669481 },
    { lat: 48.561996, lng: 20.670075 },
    { lat: 48.562051, lng: 20.670485 },
    { lat: 48.562218, lng: 20.671002 },
    { lat: 48.562456, lng: 20.671291 },
    { lat: 48.562539, lng: 20.671538 },
    { lat: 48.562527, lng: 20.67171 },
    { lat: 48.562442, lng: 20.671979 },
    { lat: 48.562207, lng: 20.67258 },
    { lat: 48.562192, lng: 20.672582 },
    { lat: 48.561936, lng: 20.674313 },
    { lat: 48.561709, lng: 20.674998 },
    { lat: 48.561713, lng: 20.675034 },
    { lat: 48.561387, lng: 20.675521 },
    { lat: 48.561284, lng: 20.675773 },
    { lat: 48.560923, lng: 20.677183 },
    { lat: 48.5608, lng: 20.677546 },
    { lat: 48.56058, lng: 20.678139 },
    { lat: 48.560407, lng: 20.678612 },
    { lat: 48.560271, lng: 20.678775 },
    { lat: 48.560199, lng: 20.678855 },
    { lat: 48.560099, lng: 20.679012 },
    { lat: 48.559954, lng: 20.679156 },
    { lat: 48.559886, lng: 20.679197 },
    { lat: 48.559799, lng: 20.679234 },
    { lat: 48.55966, lng: 20.679309 },
    { lat: 48.559516, lng: 20.679502 },
    { lat: 48.559534, lng: 20.679532 },
    { lat: 48.5596519, lng: 20.6796589 },
    { lat: 48.560526, lng: 20.6806 },
    { lat: 48.560843, lng: 20.680971 },
    { lat: 48.561139, lng: 20.681304 },
    { lat: 48.56127, lng: 20.68162 },
    { lat: 48.561377, lng: 20.681886 },
    { lat: 48.561744, lng: 20.683322 },
    { lat: 48.561885, lng: 20.683813 },
    { lat: 48.562072, lng: 20.684379 },
    { lat: 48.562394, lng: 20.685405 },
    { lat: 48.562916, lng: 20.686944 },
    { lat: 48.563313, lng: 20.688008 },
    { lat: 48.563581, lng: 20.689096 },
    { lat: 48.563935, lng: 20.690483 },
    { lat: 48.564075, lng: 20.691005 },
    { lat: 48.564246, lng: 20.691714 },
    { lat: 48.56443, lng: 20.692182 },
    { lat: 48.564769, lng: 20.693012 },
    { lat: 48.56505, lng: 20.693709 },
    { lat: 48.565273, lng: 20.694257 },
    { lat: 48.565637, lng: 20.69519 },
    { lat: 48.565751, lng: 20.695516 },
    { lat: 48.565888, lng: 20.696186 },
    { lat: 48.566306, lng: 20.697571 },
    { lat: 48.56656, lng: 20.698509 },
    { lat: 48.566868, lng: 20.699783 },
    { lat: 48.56712, lng: 20.700482 },
    { lat: 48.567291, lng: 20.700981 },
    { lat: 48.567367, lng: 20.701165 },
    { lat: 48.567494, lng: 20.701489 },
    { lat: 48.567703, lng: 20.702103 },
    { lat: 48.567748, lng: 20.702273 },
    { lat: 48.567864, lng: 20.703026 },
    { lat: 48.567932, lng: 20.70322 },
    { lat: 48.568079, lng: 20.703638 },
    { lat: 48.568184, lng: 20.703916 },
    { lat: 48.568195, lng: 20.704395 },
    { lat: 48.568317, lng: 20.704844 },
    { lat: 48.568396, lng: 20.705828 },
    { lat: 48.568449, lng: 20.705967 },
    { lat: 48.568868, lng: 20.706137 },
    { lat: 48.569128, lng: 20.706584 },
    { lat: 48.569455, lng: 20.708512 },
    { lat: 48.5710912, lng: 20.7049063 },
    { lat: 48.5754563, lng: 20.7027079 },
    { lat: 48.5830148, lng: 20.7053762 },
    { lat: 48.5835976, lng: 20.7054231 },
    { lat: 48.5848604, lng: 20.7053567 },
    { lat: 48.5862811, lng: 20.7054425 },
    { lat: 48.5874124, lng: 20.7052852 },
    { lat: 48.5874121, lng: 20.705213 },
    { lat: 48.5909725, lng: 20.7045623 },
    { lat: 48.5932484, lng: 20.7039825 },
    { lat: 48.5953543, lng: 20.7035573 },
    { lat: 48.5955979, lng: 20.7034176 },
    { lat: 48.5977426, lng: 20.702784 },
    { lat: 48.5991684, lng: 20.7022668 },
    { lat: 48.5997599, lng: 20.7019914 },
    { lat: 48.6004353, lng: 20.7018969 },
    { lat: 48.600467, lng: 20.7018065 },
    { lat: 48.6019323, lng: 20.7013195 },
    { lat: 48.6024233, lng: 20.7012632 },
    { lat: 48.6074151, lng: 20.70516 },
    { lat: 48.6081798, lng: 20.705037 },
    { lat: 48.6094711, lng: 20.704943 },
    { lat: 48.61165, lng: 20.7038262 },
    { lat: 48.6125732, lng: 20.7041392 },
    { lat: 48.6127707, lng: 20.7023268 },
    { lat: 48.6133374, lng: 20.7000713 },
    { lat: 48.6133484, lng: 20.6994029 },
    { lat: 48.6131808, lng: 20.6970488 },
    { lat: 48.6137092, lng: 20.6925405 },
    { lat: 48.6134071, lng: 20.6915892 },
    { lat: 48.612436, lng: 20.6902201 },
    { lat: 48.6122528, lng: 20.6897892 },
    { lat: 48.6120169, lng: 20.6894957 },
    { lat: 48.6118778, lng: 20.6891631 },
    { lat: 48.6122908, lng: 20.6886522 },
    { lat: 48.6126107, lng: 20.6880611 },
    { lat: 48.6125746, lng: 20.6868391 },
    { lat: 48.6126488, lng: 20.685897 },
    { lat: 48.6128911, lng: 20.6853979 },
    { lat: 48.6129117, lng: 20.6842851 },
    { lat: 48.6133432, lng: 20.6832871 },
    { lat: 48.6132441, lng: 20.6817257 },
    { lat: 48.6133106, lng: 20.6806931 },
    { lat: 48.6134356, lng: 20.6800628 },
    { lat: 48.6136779, lng: 20.6797302 },
    { lat: 48.6141297, lng: 20.6782035 },
    { lat: 48.6143127, lng: 20.6778782 },
    { lat: 48.6144156, lng: 20.677735 },
    { lat: 48.6145515, lng: 20.6771001 },
    { lat: 48.6149582, lng: 20.676858 },
    { lat: 48.6153911, lng: 20.6758736 },
    { lat: 48.6158381, lng: 20.6742144 },
    { lat: 48.6157422, lng: 20.6733029 },
    { lat: 48.615921, lng: 20.6724243 },
    { lat: 48.6157064, lng: 20.6712812 },
    { lat: 48.6156686, lng: 20.6698593 },
    { lat: 48.615475, lng: 20.668986 },
    { lat: 48.6158256, lng: 20.6672216 },
    { lat: 48.6161108, lng: 20.6650177 },
    { lat: 48.6169529, lng: 20.6623026 },
    { lat: 48.6165604, lng: 20.6604124 },
    { lat: 48.6162864, lng: 20.6590913 },
    { lat: 48.6149903, lng: 20.6556618 },
    { lat: 48.6140598, lng: 20.6512311 },
    { lat: 48.6129848, lng: 20.6503365 },
    { lat: 48.6121888, lng: 20.6494945 },
    { lat: 48.6119954, lng: 20.6491643 },
    { lat: 48.6116053, lng: 20.6486809 },
    { lat: 48.6110603, lng: 20.6484657 },
    { lat: 48.6104642, lng: 20.6481107 },
    { lat: 48.6102144, lng: 20.6478321 },
    { lat: 48.6099543, lng: 20.6472754 },
    { lat: 48.6089461, lng: 20.6455177 },
    { lat: 48.6087516, lng: 20.6452474 },
    { lat: 48.6083869, lng: 20.6449103 },
    { lat: 48.6079179, lng: 20.6440219 },
    { lat: 48.6080677, lng: 20.6435918 },
    { lat: 48.6082436, lng: 20.6426972 },
    { lat: 48.6083707, lng: 20.6415774 },
    { lat: 48.6083424, lng: 20.6411112 },
    { lat: 48.6084824, lng: 20.6404695 },
    { lat: 48.6084438, lng: 20.6398113 },
    { lat: 48.6082009, lng: 20.6385478 },
    { lat: 48.6077869, lng: 20.6379971 },
    { lat: 48.6077799, lng: 20.6379276 },
  ],
  Hrhov: [
    { lat: 48.575227, lng: 20.796101 },
    { lat: 48.575396, lng: 20.795942 },
    { lat: 48.575645, lng: 20.795709 },
    { lat: 48.575869, lng: 20.795498 },
    { lat: 48.57665, lng: 20.794764 },
    { lat: 48.576694, lng: 20.794723 },
    { lat: 48.576744, lng: 20.794676 },
    { lat: 48.57721, lng: 20.794597 },
    { lat: 48.577943, lng: 20.794516 },
    { lat: 48.57815, lng: 20.794493 },
    { lat: 48.579761, lng: 20.794313 },
    { lat: 48.579891, lng: 20.794299 },
    { lat: 48.580043, lng: 20.794282 },
    { lat: 48.580253, lng: 20.794258 },
    { lat: 48.580333, lng: 20.794208 },
    { lat: 48.581712, lng: 20.793939 },
    { lat: 48.582363, lng: 20.79387 },
    { lat: 48.582629, lng: 20.79385 },
    { lat: 48.582713, lng: 20.793845 },
    { lat: 48.582762, lng: 20.793842 },
    { lat: 48.582811, lng: 20.793804 },
    { lat: 48.582857, lng: 20.79377 },
    { lat: 48.582995, lng: 20.793863 },
    { lat: 48.583109, lng: 20.793873 },
    { lat: 48.583684, lng: 20.79379 },
    { lat: 48.585986, lng: 20.793562 },
    { lat: 48.58755, lng: 20.79336 },
    { lat: 48.58769, lng: 20.793342 },
    { lat: 48.587711, lng: 20.793339 },
    { lat: 48.587717, lng: 20.793338 },
    { lat: 48.58784, lng: 20.793321 },
    { lat: 48.588366, lng: 20.793258 },
    { lat: 48.589308, lng: 20.793162 },
    { lat: 48.58977, lng: 20.793117 },
    { lat: 48.590825, lng: 20.793008 },
    { lat: 48.591715, lng: 20.792916 },
    { lat: 48.592338, lng: 20.792878 },
    { lat: 48.592846, lng: 20.792897 },
    { lat: 48.593258, lng: 20.792944 },
    { lat: 48.593569, lng: 20.792979 },
    { lat: 48.593595, lng: 20.792981 },
    { lat: 48.594156, lng: 20.793029 },
    { lat: 48.594538, lng: 20.793072 },
    { lat: 48.594872, lng: 20.793071 },
    { lat: 48.595474, lng: 20.793054 },
    { lat: 48.596015, lng: 20.793068 },
    { lat: 48.597058, lng: 20.793179 },
    { lat: 48.597303, lng: 20.793218 },
    { lat: 48.597442, lng: 20.793231 },
    { lat: 48.597593, lng: 20.793239 },
    { lat: 48.598112, lng: 20.793255 },
    { lat: 48.598282, lng: 20.793251 },
    { lat: 48.598383, lng: 20.793249 },
    { lat: 48.598545, lng: 20.793246 },
    { lat: 48.598932, lng: 20.793238 },
    { lat: 48.600071, lng: 20.793117 },
    { lat: 48.600127, lng: 20.793109 },
    { lat: 48.600218, lng: 20.793096 },
    { lat: 48.600236, lng: 20.793093 },
    { lat: 48.600453, lng: 20.793069 },
    { lat: 48.600762, lng: 20.793059 },
    { lat: 48.601422, lng: 20.793061 },
    { lat: 48.60148, lng: 20.793061 },
    { lat: 48.601885, lng: 20.793063 },
    { lat: 48.603097, lng: 20.793045 },
    { lat: 48.603239, lng: 20.794422 },
    { lat: 48.603304, lng: 20.795049 },
    { lat: 48.603242, lng: 20.796168 },
    { lat: 48.603221, lng: 20.796867 },
    { lat: 48.603213, lng: 20.797269 },
    { lat: 48.603208, lng: 20.797486 },
    { lat: 48.603202, lng: 20.797941 },
    { lat: 48.603203, lng: 20.79798 },
    { lat: 48.603202, lng: 20.798028 },
    { lat: 48.603201, lng: 20.798098 },
    { lat: 48.603193, lng: 20.798649 },
    { lat: 48.603227, lng: 20.799689 },
    { lat: 48.603217, lng: 20.800236 },
    { lat: 48.603124, lng: 20.800993 },
    { lat: 48.603009, lng: 20.801585 },
    { lat: 48.602857, lng: 20.802482 },
    { lat: 48.602741, lng: 20.803143 },
    { lat: 48.602624, lng: 20.803823 },
    { lat: 48.602477, lng: 20.804573 },
    { lat: 48.602295, lng: 20.805014 },
    { lat: 48.602158, lng: 20.805294 },
    { lat: 48.602127, lng: 20.805304 },
    { lat: 48.601908, lng: 20.80568 },
    { lat: 48.601788, lng: 20.805792 },
    { lat: 48.601618, lng: 20.805948 },
    { lat: 48.601398, lng: 20.806127 },
    { lat: 48.601374, lng: 20.80615 },
    { lat: 48.601261, lng: 20.806263 },
    { lat: 48.601214, lng: 20.806308 },
    { lat: 48.601171, lng: 20.806353 },
    { lat: 48.601077, lng: 20.806455 },
    { lat: 48.601026, lng: 20.806512 },
    { lat: 48.600957, lng: 20.806589 },
    { lat: 48.60084, lng: 20.806773 },
    { lat: 48.600732, lng: 20.806943 },
    { lat: 48.600612, lng: 20.807124 },
    { lat: 48.600413, lng: 20.807432 },
    { lat: 48.600362, lng: 20.807534 },
    { lat: 48.600325, lng: 20.807605 },
    { lat: 48.600284, lng: 20.807678 },
    { lat: 48.600196, lng: 20.807853 },
    { lat: 48.600164, lng: 20.807918 },
    { lat: 48.600093, lng: 20.808165 },
    { lat: 48.600071, lng: 20.808257 },
    { lat: 48.60006, lng: 20.808359 },
    { lat: 48.600051, lng: 20.808517 },
    { lat: 48.600045, lng: 20.808689 },
    { lat: 48.600039, lng: 20.8088 },
    { lat: 48.600025, lng: 20.80922 },
    { lat: 48.600057, lng: 20.80938 },
    { lat: 48.600238, lng: 20.810049 },
    { lat: 48.600273, lng: 20.810333 },
    { lat: 48.600316, lng: 20.810532 },
    { lat: 48.600322, lng: 20.810557 },
    { lat: 48.600342, lng: 20.810545 },
    { lat: 48.600368, lng: 20.810531 },
    { lat: 48.600666, lng: 20.810362 },
    { lat: 48.600872, lng: 20.810276 },
    { lat: 48.601146, lng: 20.810279 },
    { lat: 48.601614, lng: 20.810284 },
    { lat: 48.601893, lng: 20.810286 },
    { lat: 48.603589, lng: 20.811451 },
    { lat: 48.604543, lng: 20.812157 },
    { lat: 48.60463, lng: 20.812085 },
    { lat: 48.60497, lng: 20.811824 },
    { lat: 48.605424, lng: 20.811641 },
    { lat: 48.605644, lng: 20.811315 },
    { lat: 48.605781, lng: 20.811244 },
    { lat: 48.60641, lng: 20.811259 },
    { lat: 48.606838, lng: 20.811158 },
    { lat: 48.607676, lng: 20.810824 },
    { lat: 48.608339, lng: 20.810458 },
    { lat: 48.608595, lng: 20.810392 },
    { lat: 48.608973, lng: 20.810198 },
    { lat: 48.609379, lng: 20.8102 },
    { lat: 48.609709, lng: 20.810023 },
    { lat: 48.610268, lng: 20.809806 },
    { lat: 48.611419, lng: 20.809498 },
    { lat: 48.61162, lng: 20.809357 },
    { lat: 48.611613, lng: 20.808524 },
    { lat: 48.611794, lng: 20.808516 },
    { lat: 48.612662, lng: 20.807753 },
    { lat: 48.61294, lng: 20.807566 },
    { lat: 48.612969, lng: 20.80755 },
    { lat: 48.613962, lng: 20.806505 },
    { lat: 48.614252, lng: 20.806063 },
    { lat: 48.614833, lng: 20.804986 },
    { lat: 48.614857, lng: 20.804941 },
    { lat: 48.6155, lng: 20.804178 },
    { lat: 48.615789, lng: 20.803833 },
    { lat: 48.615832, lng: 20.803783 },
    { lat: 48.616839, lng: 20.80283 },
    { lat: 48.617006, lng: 20.80188 },
    { lat: 48.617005, lng: 20.80187 },
    { lat: 48.617005, lng: 20.801673 },
    { lat: 48.617074, lng: 20.801094 },
    { lat: 48.617196, lng: 20.800989 },
    { lat: 48.617323, lng: 20.800872 },
    { lat: 48.617409, lng: 20.800792 },
    { lat: 48.61766, lng: 20.80056 },
    { lat: 48.617752, lng: 20.800502 },
    { lat: 48.620364, lng: 20.798861 },
    { lat: 48.621314, lng: 20.798417 },
    { lat: 48.621736, lng: 20.798062 },
    { lat: 48.621777, lng: 20.797989 },
    { lat: 48.621956, lng: 20.797663 },
    { lat: 48.624295, lng: 20.795999 },
    { lat: 48.6245471, lng: 20.7948306 },
    { lat: 48.6247801, lng: 20.793447 },
    { lat: 48.6247244, lng: 20.7926284 },
    { lat: 48.6245337, lng: 20.7920055 },
    { lat: 48.6243224, lng: 20.7903716 },
    { lat: 48.6240994, lng: 20.789371 },
    { lat: 48.6240975, lng: 20.7885143 },
    { lat: 48.6239461, lng: 20.7878574 },
    { lat: 48.6236545, lng: 20.7872163 },
    { lat: 48.623394, lng: 20.7863314 },
    { lat: 48.6230654, lng: 20.7854712 },
    { lat: 48.6227103, lng: 20.7848434 },
    { lat: 48.6222402, lng: 20.7838013 },
    { lat: 48.6217326, lng: 20.7833874 },
    { lat: 48.6212672, lng: 20.7818935 },
    { lat: 48.6210996, lng: 20.780875 },
    { lat: 48.6209868, lng: 20.779455 },
    { lat: 48.6208543, lng: 20.7787931 },
    { lat: 48.6208151, lng: 20.7780259 },
    { lat: 48.6208862, lng: 20.7767513 },
    { lat: 48.6206841, lng: 20.7759612 },
    { lat: 48.6206574, lng: 20.7734093 },
    { lat: 48.6202482, lng: 20.7707374 },
    { lat: 48.6202991, lng: 20.7696556 },
    { lat: 48.6202464, lng: 20.7687685 },
    { lat: 48.6199166, lng: 20.7667586 },
    { lat: 48.6197738, lng: 20.7661534 },
    { lat: 48.6192845, lng: 20.7652995 },
    { lat: 48.6190338, lng: 20.7646073 },
    { lat: 48.6189399, lng: 20.7642117 },
    { lat: 48.6186865, lng: 20.7621349 },
    { lat: 48.6185502, lng: 20.7616815 },
    { lat: 48.6183129, lng: 20.7612735 },
    { lat: 48.618064, lng: 20.760505 },
    { lat: 48.6188971, lng: 20.7589103 },
    { lat: 48.6190984, lng: 20.7584242 },
    { lat: 48.6191865, lng: 20.7579574 },
    { lat: 48.6192478, lng: 20.7567605 },
    { lat: 48.6192208, lng: 20.7553889 },
    { lat: 48.6191081, lng: 20.7545201 },
    { lat: 48.6191664, lng: 20.7532071 },
    { lat: 48.6197168, lng: 20.7521847 },
    { lat: 48.6200856, lng: 20.7510599 },
    { lat: 48.6204741, lng: 20.7495317 },
    { lat: 48.6205323, lng: 20.7484752 },
    { lat: 48.6204227, lng: 20.7463921 },
    { lat: 48.6204993, lng: 20.74509 },
    { lat: 48.6204908, lng: 20.744537 },
    { lat: 48.6201145, lng: 20.7410579 },
    { lat: 48.6196685, lng: 20.7396574 },
    { lat: 48.6183154, lng: 20.7380764 },
    { lat: 48.6180295, lng: 20.7375985 },
    { lat: 48.6175236, lng: 20.7364772 },
    { lat: 48.6169529, lng: 20.7343835 },
    { lat: 48.6167508, lng: 20.7338006 },
    { lat: 48.6151374, lng: 20.7313209 },
    { lat: 48.6126005, lng: 20.7311512 },
    { lat: 48.6116722, lng: 20.7275209 },
    { lat: 48.6113156, lng: 20.7231952 },
    { lat: 48.6110885, lng: 20.7226013 },
    { lat: 48.6112992, lng: 20.7191649 },
    { lat: 48.6108894, lng: 20.7185934 },
    { lat: 48.6109286, lng: 20.7174172 },
    { lat: 48.6107675, lng: 20.7149232 },
    { lat: 48.6100056, lng: 20.7135659 },
    { lat: 48.6097613, lng: 20.7131307 },
    { lat: 48.6096968, lng: 20.712429 },
    { lat: 48.6093552, lng: 20.7115969 },
    { lat: 48.6088131, lng: 20.7107252 },
    { lat: 48.6086161, lng: 20.7100041 },
    { lat: 48.6084883, lng: 20.7084082 },
    { lat: 48.6083656, lng: 20.7078465 },
    { lat: 48.6079278, lng: 20.706712 },
    { lat: 48.6074151, lng: 20.70516 },
    { lat: 48.6024233, lng: 20.7012632 },
    { lat: 48.6019323, lng: 20.7013195 },
    { lat: 48.600467, lng: 20.7018065 },
    { lat: 48.6004353, lng: 20.7018969 },
    { lat: 48.5997599, lng: 20.7019914 },
    { lat: 48.5991684, lng: 20.7022668 },
    { lat: 48.5977426, lng: 20.702784 },
    { lat: 48.5955979, lng: 20.7034176 },
    { lat: 48.5953543, lng: 20.7035573 },
    { lat: 48.5932484, lng: 20.7039825 },
    { lat: 48.5909725, lng: 20.7045623 },
    { lat: 48.5874121, lng: 20.705213 },
    { lat: 48.5874124, lng: 20.7052852 },
    { lat: 48.5862811, lng: 20.7054425 },
    { lat: 48.5848604, lng: 20.7053567 },
    { lat: 48.5835976, lng: 20.7054231 },
    { lat: 48.5830148, lng: 20.7053762 },
    { lat: 48.5754563, lng: 20.7027079 },
    { lat: 48.5710912, lng: 20.7049063 },
    { lat: 48.569455, lng: 20.708512 },
    { lat: 48.568273, lng: 20.709758 },
    { lat: 48.569297, lng: 20.711367 },
    { lat: 48.570058, lng: 20.712576 },
    { lat: 48.570391, lng: 20.713132 },
    { lat: 48.570081, lng: 20.71519 },
    { lat: 48.570223, lng: 20.716412 },
    { lat: 48.570334, lng: 20.717673 },
    { lat: 48.569964, lng: 20.718806 },
    { lat: 48.569294, lng: 20.720936 },
    { lat: 48.569491, lng: 20.722547 },
    { lat: 48.569389, lng: 20.72319 },
    { lat: 48.569244, lng: 20.724112 },
    { lat: 48.569536, lng: 20.724916 },
    { lat: 48.569713, lng: 20.725371 },
    { lat: 48.569733, lng: 20.725574 },
    { lat: 48.56961, lng: 20.726234 },
    { lat: 48.569059, lng: 20.726178 },
    { lat: 48.568838, lng: 20.726193 },
    { lat: 48.5682894, lng: 20.7262628 },
    { lat: 48.5680499, lng: 20.7263205 },
    { lat: 48.567764, lng: 20.726403 },
    { lat: 48.5671, lng: 20.726553 },
    { lat: 48.566205, lng: 20.726756 },
    { lat: 48.56548, lng: 20.726851 },
    { lat: 48.565202, lng: 20.726892 },
    { lat: 48.564773, lng: 20.726983 },
    { lat: 48.564408, lng: 20.727112 },
    { lat: 48.564441, lng: 20.727601 },
    { lat: 48.56448, lng: 20.728106 },
    { lat: 48.565173, lng: 20.728014 },
    { lat: 48.565745, lng: 20.727937 },
    { lat: 48.566038, lng: 20.727894 },
    { lat: 48.566334, lng: 20.72783 },
    { lat: 48.566405, lng: 20.728106 },
    { lat: 48.566407, lng: 20.729435 },
    { lat: 48.566347, lng: 20.731135 },
    { lat: 48.566356, lng: 20.73206 },
    { lat: 48.566559, lng: 20.73359 },
    { lat: 48.566693, lng: 20.734749 },
    { lat: 48.567237, lng: 20.734568 },
    { lat: 48.568679, lng: 20.734155 },
    { lat: 48.570085, lng: 20.733904 },
    { lat: 48.570138, lng: 20.734728 },
    { lat: 48.570188, lng: 20.735431 },
    { lat: 48.57022, lng: 20.736106 },
    { lat: 48.570319, lng: 20.73776 },
    { lat: 48.570393, lng: 20.738584 },
    { lat: 48.570422, lng: 20.739031 },
    { lat: 48.570485, lng: 20.740391 },
    { lat: 48.570627, lng: 20.74228 },
    { lat: 48.570671, lng: 20.742976 },
    { lat: 48.570712, lng: 20.74351 },
    { lat: 48.570735, lng: 20.743906 },
    { lat: 48.570764, lng: 20.744487 },
    { lat: 48.570818, lng: 20.745041 },
    { lat: 48.570883, lng: 20.74556 },
    { lat: 48.570897, lng: 20.746131 },
    { lat: 48.570927, lng: 20.747032 },
    { lat: 48.571019, lng: 20.748461 },
    { lat: 48.571067, lng: 20.749284 },
    { lat: 48.571112, lng: 20.749845 },
    { lat: 48.571145, lng: 20.750414 },
    { lat: 48.571231, lng: 20.751783 },
    { lat: 48.571342, lng: 20.753599 },
    { lat: 48.571389, lng: 20.75419 },
    { lat: 48.57139, lng: 20.754838 },
    { lat: 48.571416, lng: 20.755229 },
    { lat: 48.571447, lng: 20.755677 },
    { lat: 48.571475, lng: 20.755882 },
    { lat: 48.5715, lng: 20.756082 },
    { lat: 48.5715588, lng: 20.7569175 },
    { lat: 48.571636, lng: 20.757501 },
    { lat: 48.571687, lng: 20.758294 },
    { lat: 48.571763, lng: 20.759184 },
    { lat: 48.571806, lng: 20.759636 },
    { lat: 48.571809, lng: 20.760163 },
    { lat: 48.571821, lng: 20.760779 },
    { lat: 48.57192, lng: 20.761254 },
    { lat: 48.571976, lng: 20.761626 },
    { lat: 48.572088, lng: 20.762633 },
    { lat: 48.572166, lng: 20.763466 },
    { lat: 48.572278, lng: 20.764658 },
    { lat: 48.572307, lng: 20.764943 },
    { lat: 48.572365, lng: 20.765562 },
    { lat: 48.572412, lng: 20.766082 },
    { lat: 48.57244, lng: 20.766392 },
    { lat: 48.572531, lng: 20.767332 },
    { lat: 48.572622, lng: 20.768347 },
    { lat: 48.57266, lng: 20.768875 },
    { lat: 48.572715, lng: 20.769389 },
    { lat: 48.572757, lng: 20.769801 },
    { lat: 48.572798, lng: 20.770261 },
    { lat: 48.572827, lng: 20.770603 },
    { lat: 48.572885, lng: 20.771117 },
    { lat: 48.572937, lng: 20.771834 },
    { lat: 48.573003, lng: 20.772357 },
    { lat: 48.573032, lng: 20.77281 },
    { lat: 48.573072, lng: 20.773123 },
    { lat: 48.573099, lng: 20.773435 },
    { lat: 48.573153, lng: 20.774134 },
    { lat: 48.573211, lng: 20.774586 },
    { lat: 48.573289, lng: 20.775382 },
    { lat: 48.573366, lng: 20.776422 },
    { lat: 48.573421, lng: 20.777002 },
    { lat: 48.57347, lng: 20.777415 },
    { lat: 48.573519, lng: 20.777868 },
    { lat: 48.573551, lng: 20.778375 },
    { lat: 48.57361, lng: 20.778997 },
    { lat: 48.573681, lng: 20.779644 },
    { lat: 48.573741, lng: 20.780311 },
    { lat: 48.573816, lng: 20.781146 },
    { lat: 48.573853, lng: 20.781671 },
    { lat: 48.57391, lng: 20.782175 },
    { lat: 48.573971, lng: 20.782684 },
    { lat: 48.573994, lng: 20.783121 },
    { lat: 48.574027, lng: 20.78351 },
    { lat: 48.57413, lng: 20.784455 },
    { lat: 48.574178, lng: 20.785058 },
    { lat: 48.574246, lng: 20.785624 },
    { lat: 48.574292, lng: 20.786144 },
    { lat: 48.574376, lng: 20.787138 },
    { lat: 48.574422, lng: 20.787565 },
    { lat: 48.574451, lng: 20.787951 },
    { lat: 48.574555, lng: 20.78894 },
    { lat: 48.574631, lng: 20.789621 },
    { lat: 48.574804, lng: 20.791501 },
    { lat: 48.574853, lng: 20.791876 },
    { lat: 48.57494, lng: 20.792651 },
    { lat: 48.575075, lng: 20.794253 },
    { lat: 48.5751464, lng: 20.7951206 },
    { lat: 48.575227, lng: 20.796101 },
  ],
  GemerskáPoloma: [
    { lat: 48.7445833, lng: 20.4472495 },
    { lat: 48.7442159, lng: 20.4474801 },
    { lat: 48.7439294, lng: 20.4475267 },
    { lat: 48.7435186, lng: 20.4477699 },
    { lat: 48.7430417, lng: 20.4477249 },
    { lat: 48.7429413, lng: 20.4475976 },
    { lat: 48.742797, lng: 20.4474147 },
    { lat: 48.7423811, lng: 20.4474043 },
    { lat: 48.7411711, lng: 20.447832 },
    { lat: 48.7391974, lng: 20.4481635 },
    { lat: 48.7387648, lng: 20.4481303 },
    { lat: 48.7383494, lng: 20.4480696 },
    { lat: 48.738115, lng: 20.4479487 },
    { lat: 48.7372779, lng: 20.447759 },
    { lat: 48.7368326, lng: 20.4473285 },
    { lat: 48.7360911, lng: 20.4472875 },
    { lat: 48.7356664, lng: 20.4470389 },
    { lat: 48.7340625, lng: 20.4464626 },
    { lat: 48.7337864, lng: 20.4464541 },
    { lat: 48.7336218, lng: 20.4465996 },
    { lat: 48.7336105, lng: 20.4467197 },
    { lat: 48.733162, lng: 20.4475116 },
    { lat: 48.7328013, lng: 20.4483354 },
    { lat: 48.7327637, lng: 20.4485309 },
    { lat: 48.7316012, lng: 20.4502395 },
    { lat: 48.730401, lng: 20.4512258 },
    { lat: 48.7296737, lng: 20.4524447 },
    { lat: 48.7291755, lng: 20.4526729 },
    { lat: 48.7283181, lng: 20.4536705 },
    { lat: 48.728161, lng: 20.4545461 },
    { lat: 48.7275284, lng: 20.4551494 },
    { lat: 48.7270481, lng: 20.4558746 },
    { lat: 48.7258214, lng: 20.4556197 },
    { lat: 48.7248402, lng: 20.4545715 },
    { lat: 48.7239667, lng: 20.4539231 },
    { lat: 48.7238168, lng: 20.4536514 },
    { lat: 48.7234243, lng: 20.4535178 },
    { lat: 48.7228129, lng: 20.4531006 },
    { lat: 48.7223977, lng: 20.4529258 },
    { lat: 48.7220178, lng: 20.452888 },
    { lat: 48.7217074, lng: 20.4530482 },
    { lat: 48.7211032, lng: 20.4527004 },
    { lat: 48.7207792, lng: 20.4532169 },
    { lat: 48.720354, lng: 20.4536717 },
    { lat: 48.7201277, lng: 20.4533168 },
    { lat: 48.7194969, lng: 20.4525355 },
    { lat: 48.7191379, lng: 20.4523636 },
    { lat: 48.7187867, lng: 20.451949 },
    { lat: 48.7182306, lng: 20.4514208 },
    { lat: 48.7174219, lng: 20.4509085 },
    { lat: 48.7167609, lng: 20.4503706 },
    { lat: 48.7161072, lng: 20.4499999 },
    { lat: 48.7154913, lng: 20.4500286 },
    { lat: 48.7142377, lng: 20.4492197 },
    { lat: 48.7140802, lng: 20.449224 },
    { lat: 48.7140027, lng: 20.4491409 },
    { lat: 48.7136234, lng: 20.4491434 },
    { lat: 48.7133972, lng: 20.4493252 },
    { lat: 48.7131281, lng: 20.4497389 },
    { lat: 48.7129494, lng: 20.4498988 },
    { lat: 48.7127695, lng: 20.4498174 },
    { lat: 48.7126946, lng: 20.4500054 },
    { lat: 48.7125156, lng: 20.4501581 },
    { lat: 48.7123142, lng: 20.450105 },
    { lat: 48.7122038, lng: 20.4498325 },
    { lat: 48.7113293, lng: 20.4495177 },
    { lat: 48.7112321, lng: 20.4495069 },
    { lat: 48.7111498, lng: 20.4496253 },
    { lat: 48.7109119, lng: 20.4494376 },
    { lat: 48.7106788, lng: 20.4494072 },
    { lat: 48.7105711, lng: 20.4495677 },
    { lat: 48.7105682, lng: 20.4496963 },
    { lat: 48.7103482, lng: 20.4498362 },
    { lat: 48.71015, lng: 20.4495699 },
    { lat: 48.7100533, lng: 20.4495693 },
    { lat: 48.7099688, lng: 20.4494811 },
    { lat: 48.7098299, lng: 20.4496211 },
    { lat: 48.7097177, lng: 20.4495125 },
    { lat: 48.7094856, lng: 20.4495942 },
    { lat: 48.7094704, lng: 20.4494301 },
    { lat: 48.7093928, lng: 20.4493766 },
    { lat: 48.7093138, lng: 20.4494875 },
    { lat: 48.7092108, lng: 20.4494718 },
    { lat: 48.709142, lng: 20.4495327 },
    { lat: 48.7089822, lng: 20.4495358 },
    { lat: 48.7088879, lng: 20.4494206 },
    { lat: 48.7087075, lng: 20.4495664 },
    { lat: 48.7086844, lng: 20.4493106 },
    { lat: 48.7084055, lng: 20.4492927 },
    { lat: 48.7082493, lng: 20.4493864 },
    { lat: 48.7081455, lng: 20.449262 },
    { lat: 48.7078895, lng: 20.4493427 },
    { lat: 48.7076407, lng: 20.4495207 },
    { lat: 48.7076878, lng: 20.4501088 },
    { lat: 48.7068105, lng: 20.4504025 },
    { lat: 48.7067838, lng: 20.4500576 },
    { lat: 48.7066742, lng: 20.4497202 },
    { lat: 48.7061614, lng: 20.4496188 },
    { lat: 48.7060423, lng: 20.4503421 },
    { lat: 48.7055058, lng: 20.4502922 },
    { lat: 48.7052734, lng: 20.4501626 },
    { lat: 48.7049941, lng: 20.4501347 },
    { lat: 48.7044741, lng: 20.4497238 },
    { lat: 48.7042292, lng: 20.4493517 },
    { lat: 48.7045266, lng: 20.4490294 },
    { lat: 48.70422, lng: 20.4486681 },
    { lat: 48.7041413, lng: 20.4486692 },
    { lat: 48.703872, lng: 20.4484258 },
    { lat: 48.703839, lng: 20.4482711 },
    { lat: 48.7037679, lng: 20.4482049 },
    { lat: 48.7037124, lng: 20.4482546 },
    { lat: 48.7036498, lng: 20.448177 },
    { lat: 48.7036062, lng: 20.4482164 },
    { lat: 48.703553, lng: 20.4481201 },
    { lat: 48.7029078, lng: 20.4476452 },
    { lat: 48.7028293, lng: 20.4477397 },
    { lat: 48.702755, lng: 20.447653 },
    { lat: 48.7026812, lng: 20.4476976 },
    { lat: 48.7026499, lng: 20.4474953 },
    { lat: 48.7022572, lng: 20.4472641 },
    { lat: 48.7021379, lng: 20.4471482 },
    { lat: 48.7021167, lng: 20.4470367 },
    { lat: 48.7019198, lng: 20.4470388 },
    { lat: 48.7017066, lng: 20.4467617 },
    { lat: 48.7015919, lng: 20.4468248 },
    { lat: 48.7014847, lng: 20.4466672 },
    { lat: 48.7013785, lng: 20.446691 },
    { lat: 48.7011884, lng: 20.4464875 },
    { lat: 48.7011071, lng: 20.446573 },
    { lat: 48.7009, lng: 20.4464562 },
    { lat: 48.7008119, lng: 20.4465101 },
    { lat: 48.7006395, lng: 20.4464128 },
    { lat: 48.7005767, lng: 20.4463059 },
    { lat: 48.7003538, lng: 20.4462447 },
    { lat: 48.6998393, lng: 20.4463356 },
    { lat: 48.6995925, lng: 20.4461227 },
    { lat: 48.6991944, lng: 20.4459551 },
    { lat: 48.6990231, lng: 20.4460409 },
    { lat: 48.698742, lng: 20.4459351 },
    { lat: 48.6985131, lng: 20.4460444 },
    { lat: 48.6979048, lng: 20.4457403 },
    { lat: 48.697649, lng: 20.4457089 },
    { lat: 48.6974938, lng: 20.4455616 },
    { lat: 48.6971797, lng: 20.4455043 },
    { lat: 48.6968962, lng: 20.4450345 },
    { lat: 48.6965529, lng: 20.4446572 },
    { lat: 48.6960631, lng: 20.4433822 },
    { lat: 48.696021, lng: 20.4426851 },
    { lat: 48.6959073, lng: 20.4425428 },
    { lat: 48.6958625, lng: 20.4423371 },
    { lat: 48.6955666, lng: 20.4417796 },
    { lat: 48.6953678, lng: 20.4417954 },
    { lat: 48.6952618, lng: 20.4419411 },
    { lat: 48.6948289, lng: 20.4420135 },
    { lat: 48.6943094, lng: 20.4417346 },
    { lat: 48.6933821, lng: 20.4410087 },
    { lat: 48.6920255, lng: 20.440689 },
    { lat: 48.6917268, lng: 20.4407712 },
    { lat: 48.690869, lng: 20.4406887 },
    { lat: 48.6905986, lng: 20.4408066 },
    { lat: 48.6904724, lng: 20.4406814 },
    { lat: 48.690284, lng: 20.4407056 },
    { lat: 48.6899991, lng: 20.4405218 },
    { lat: 48.6898128, lng: 20.4400643 },
    { lat: 48.6897131, lng: 20.4399935 },
    { lat: 48.6892705, lng: 20.4399328 },
    { lat: 48.6890125, lng: 20.4396319 },
    { lat: 48.6885096, lng: 20.4387792 },
    { lat: 48.6882779, lng: 20.4381959 },
    { lat: 48.688013, lng: 20.4377773 },
    { lat: 48.6880903, lng: 20.437111 },
    { lat: 48.6879109, lng: 20.4369277 },
    { lat: 48.6875985, lng: 20.4362905 },
    { lat: 48.6870354, lng: 20.4356351 },
    { lat: 48.6868954, lng: 20.4352424 },
    { lat: 48.686481, lng: 20.4344741 },
    { lat: 48.6858182, lng: 20.4351923 },
    { lat: 48.6852753, lng: 20.4356033 },
    { lat: 48.6841182, lng: 20.4370364 },
    { lat: 48.6835692, lng: 20.4380002 },
    { lat: 48.6831328, lng: 20.4391698 },
    { lat: 48.6829413, lng: 20.4405566 },
    { lat: 48.6831246, lng: 20.442357 },
    { lat: 48.6832326, lng: 20.44271 },
    { lat: 48.6835115, lng: 20.4428826 },
    { lat: 48.6836814, lng: 20.4433233 },
    { lat: 48.6841598, lng: 20.4439678 },
    { lat: 48.6842344, lng: 20.4442411 },
    { lat: 48.6842804, lng: 20.4446822 },
    { lat: 48.6847471, lng: 20.4456203 },
    { lat: 48.6850332, lng: 20.4460663 },
    { lat: 48.6851582, lng: 20.4473458 },
    { lat: 48.6851512, lng: 20.4477185 },
    { lat: 48.6852003, lng: 20.4477089 },
    { lat: 48.685252, lng: 20.4480319 },
    { lat: 48.6853327, lng: 20.4488834 },
    { lat: 48.685654, lng: 20.4499015 },
    { lat: 48.6853502, lng: 20.4502667 },
    { lat: 48.6849489, lng: 20.45061 },
    { lat: 48.6852943, lng: 20.451461 },
    { lat: 48.685725, lng: 20.4527851 },
    { lat: 48.6858621, lng: 20.4530466 },
    { lat: 48.6863159, lng: 20.4544727 },
    { lat: 48.686483, lng: 20.4557017 },
    { lat: 48.6868813, lng: 20.457809 },
    { lat: 48.6868366, lng: 20.4585046 },
    { lat: 48.6864826, lng: 20.4593826 },
    { lat: 48.6857163, lng: 20.4602309 },
    { lat: 48.6855108, lng: 20.4606011 },
    { lat: 48.6852934, lng: 20.4612228 },
    { lat: 48.6853299, lng: 20.4623675 },
    { lat: 48.6850959, lng: 20.4632685 },
    { lat: 48.684816, lng: 20.4637541 },
    { lat: 48.6848659, lng: 20.4643628 },
    { lat: 48.6847632, lng: 20.4649225 },
    { lat: 48.6845673, lng: 20.4653378 },
    { lat: 48.6844588, lng: 20.4658639 },
    { lat: 48.6842653, lng: 20.4662565 },
    { lat: 48.6840431, lng: 20.4670521 },
    { lat: 48.6839317, lng: 20.4680082 },
    { lat: 48.6839269, lng: 20.468892 },
    { lat: 48.6839647, lng: 20.4699242 },
    { lat: 48.6840708, lng: 20.470466 },
    { lat: 48.6840877, lng: 20.4708601 },
    { lat: 48.68406, lng: 20.4715256 },
    { lat: 48.6847972, lng: 20.4733366 },
    { lat: 48.6854725, lng: 20.4771852 },
    { lat: 48.6856561, lng: 20.4791593 },
    { lat: 48.6864797, lng: 20.4794367 },
    { lat: 48.6879774, lng: 20.4800429 },
    { lat: 48.6882278, lng: 20.4802933 },
    { lat: 48.6884039, lng: 20.4801848 },
    { lat: 48.6886323, lng: 20.4801527 },
    { lat: 48.688993, lng: 20.4803067 },
    { lat: 48.6896407, lng: 20.4802892 },
    { lat: 48.6899027, lng: 20.4804302 },
    { lat: 48.690188, lng: 20.4804054 },
    { lat: 48.690461, lng: 20.480156 },
    { lat: 48.6907256, lng: 20.4800623 },
    { lat: 48.6914025, lng: 20.4802187 },
    { lat: 48.6917361, lng: 20.4799249 },
    { lat: 48.6919743, lng: 20.479583 },
    { lat: 48.6924406, lng: 20.4796163 },
    { lat: 48.6927309, lng: 20.4793726 },
    { lat: 48.6935071, lng: 20.4789334 },
    { lat: 48.696036, lng: 20.4780054 },
    { lat: 48.6963585, lng: 20.4784674 },
    { lat: 48.6971612, lng: 20.4793875 },
    { lat: 48.6977531, lng: 20.4803379 },
    { lat: 48.697966, lng: 20.4809979 },
    { lat: 48.6983123, lng: 20.4812861 },
    { lat: 48.6984422, lng: 20.4815316 },
    { lat: 48.6988252, lng: 20.4818969 },
    { lat: 48.6991056, lng: 20.4824092 },
    { lat: 48.6996993, lng: 20.4837641 },
    { lat: 48.7003142, lng: 20.4843932 },
    { lat: 48.7009036, lng: 20.4854847 },
    { lat: 48.701185, lng: 20.4857825 },
    { lat: 48.70154, lng: 20.4864767 },
    { lat: 48.7017467, lng: 20.4867103 },
    { lat: 48.7018707, lng: 20.486952 },
    { lat: 48.7018991, lng: 20.4871987 },
    { lat: 48.7021729, lng: 20.4880992 },
    { lat: 48.7022636, lng: 20.4886532 },
    { lat: 48.7023411, lng: 20.488802 },
    { lat: 48.7023788, lng: 20.4892326 },
    { lat: 48.7027691, lng: 20.4897057 },
    { lat: 48.7030899, lng: 20.4902378 },
    { lat: 48.7033734, lng: 20.4911963 },
    { lat: 48.7031638, lng: 20.4916973 },
    { lat: 48.7032095, lng: 20.4918078 },
    { lat: 48.7035285, lng: 20.4919641 },
    { lat: 48.703554, lng: 20.4920464 },
    { lat: 48.7043618, lng: 20.4925679 },
    { lat: 48.7044922, lng: 20.4927403 },
    { lat: 48.7046292, lng: 20.4927638 },
    { lat: 48.7057741, lng: 20.4933884 },
    { lat: 48.7059143, lng: 20.4935207 },
    { lat: 48.7063773, lng: 20.4925367 },
    { lat: 48.7072179, lng: 20.4930618 },
    { lat: 48.7077252, lng: 20.4931396 },
    { lat: 48.7080347, lng: 20.4934942 },
    { lat: 48.7082863, lng: 20.4935721 },
    { lat: 48.7084344, lng: 20.493199 },
    { lat: 48.7084988, lng: 20.4931753 },
    { lat: 48.7086081, lng: 20.4933267 },
    { lat: 48.708895, lng: 20.4939798 },
    { lat: 48.7089675, lng: 20.4945915 },
    { lat: 48.7094134, lng: 20.4952203 },
    { lat: 48.7095571, lng: 20.4956911 },
    { lat: 48.7096062, lng: 20.496221 },
    { lat: 48.7094698, lng: 20.4969375 },
    { lat: 48.7094055, lng: 20.4977458 },
    { lat: 48.7093201, lng: 20.4981057 },
    { lat: 48.7093324, lng: 20.4985607 },
    { lat: 48.7094634, lng: 20.4987751 },
    { lat: 48.7095936, lng: 20.4987993 },
    { lat: 48.709696, lng: 20.4992782 },
    { lat: 48.710011, lng: 20.499688 },
    { lat: 48.7100367, lng: 20.4999268 },
    { lat: 48.7104556, lng: 20.500049 },
    { lat: 48.7105465, lng: 20.5002465 },
    { lat: 48.7108749, lng: 20.5001503 },
    { lat: 48.7110269, lng: 20.5002397 },
    { lat: 48.7112628, lng: 20.5001935 },
    { lat: 48.7115629, lng: 20.5002387 },
    { lat: 48.7115529, lng: 20.5003048 },
    { lat: 48.7117836, lng: 20.500329 },
    { lat: 48.7118025, lng: 20.5002593 },
    { lat: 48.7118689, lng: 20.5002614 },
    { lat: 48.7120894, lng: 20.5003788 },
    { lat: 48.7121934, lng: 20.500295 },
    { lat: 48.7124673, lng: 20.500334 },
    { lat: 48.7138052, lng: 20.5007643 },
    { lat: 48.7140826, lng: 20.5007106 },
    { lat: 48.7144532, lng: 20.5002689 },
    { lat: 48.7145733, lng: 20.5001991 },
    { lat: 48.7147984, lng: 20.5001948 },
    { lat: 48.7151343, lng: 20.5000284 },
    { lat: 48.7153592, lng: 20.5001048 },
    { lat: 48.7155734, lng: 20.4998451 },
    { lat: 48.7157233, lng: 20.4997588 },
    { lat: 48.7159247, lng: 20.4999648 },
    { lat: 48.7160922, lng: 20.4999886 },
    { lat: 48.7165434, lng: 20.5003993 },
    { lat: 48.7178453, lng: 20.5010353 },
    { lat: 48.7184403, lng: 20.5015 },
    { lat: 48.7185912, lng: 20.5013213 },
    { lat: 48.718787, lng: 20.5007775 },
    { lat: 48.7192845, lng: 20.500265 },
    { lat: 48.7194745, lng: 20.4998633 },
    { lat: 48.7196536, lng: 20.499624 },
    { lat: 48.7199406, lng: 20.4996515 },
    { lat: 48.7209882, lng: 20.4994827 },
    { lat: 48.7220805, lng: 20.4998179 },
    { lat: 48.7221384, lng: 20.5000743 },
    { lat: 48.7222661, lng: 20.5002335 },
    { lat: 48.7227459, lng: 20.5015593 },
    { lat: 48.7230769, lng: 20.5022514 },
    { lat: 48.7234778, lng: 20.5027934 },
    { lat: 48.7237286, lng: 20.503059 },
    { lat: 48.7240817, lng: 20.5031936 },
    { lat: 48.7243201, lng: 20.5033665 },
    { lat: 48.724817, lng: 20.5040686 },
    { lat: 48.7261753, lng: 20.5053145 },
    { lat: 48.7269261, lng: 20.5057585 },
    { lat: 48.7273878, lng: 20.5062119 },
    { lat: 48.7276801, lng: 20.5066203 },
    { lat: 48.7288442, lng: 20.5077923 },
    { lat: 48.7293855, lng: 20.5079379 },
    { lat: 48.7302627, lng: 20.5080021 },
    { lat: 48.7307464, lng: 20.5077429 },
    { lat: 48.7310969, lng: 20.5079662 },
    { lat: 48.7318639, lng: 20.5090581 },
    { lat: 48.7323412, lng: 20.5100738 },
    { lat: 48.7324354, lng: 20.5112752 },
    { lat: 48.7324761, lng: 20.5113994 },
    { lat: 48.7326209, lng: 20.5114466 },
    { lat: 48.7329754, lng: 20.5112609 },
    { lat: 48.7333479, lng: 20.5108046 },
    { lat: 48.7337406, lng: 20.5105232 },
    { lat: 48.7343848, lng: 20.5101982 },
    { lat: 48.7347119, lng: 20.5099077 },
    { lat: 48.735177, lng: 20.5097011 },
    { lat: 48.7361998, lng: 20.5096583 },
    { lat: 48.7367474, lng: 20.5097261 },
    { lat: 48.7371983, lng: 20.5098264 },
    { lat: 48.737556, lng: 20.5100295 },
    { lat: 48.7384466, lng: 20.510028 },
    { lat: 48.7393231, lng: 20.5104957 },
    { lat: 48.7396232, lng: 20.5105118 },
    { lat: 48.7404478, lng: 20.5103733 },
    { lat: 48.740618, lng: 20.5104069 },
    { lat: 48.7407997, lng: 20.5105098 },
    { lat: 48.7410614, lng: 20.5109473 },
    { lat: 48.7421514, lng: 20.5119698 },
    { lat: 48.7431481, lng: 20.5125525 },
    { lat: 48.743275, lng: 20.5123626 },
    { lat: 48.743536, lng: 20.5122048 },
    { lat: 48.7442057, lng: 20.5124102 },
    { lat: 48.7445273, lng: 20.512397 },
    { lat: 48.7449231, lng: 20.5126222 },
    { lat: 48.745269, lng: 20.5125717 },
    { lat: 48.7459126, lng: 20.5127408 },
    { lat: 48.7463723, lng: 20.5129591 },
    { lat: 48.746702, lng: 20.5132567 },
    { lat: 48.74728, lng: 20.5139888 },
    { lat: 48.7477644, lng: 20.5144765 },
    { lat: 48.7479495, lng: 20.5149734 },
    { lat: 48.7480596, lng: 20.5151485 },
    { lat: 48.7481825, lng: 20.5152426 },
    { lat: 48.748738, lng: 20.5154028 },
    { lat: 48.7488286, lng: 20.5155735 },
    { lat: 48.748988, lng: 20.5163117 },
    { lat: 48.74911, lng: 20.5175011 },
    { lat: 48.7489139, lng: 20.5196393 },
    { lat: 48.7488856, lng: 20.5204516 },
    { lat: 48.748848, lng: 20.5207399 },
    { lat: 48.7484288, lng: 20.5221221 },
    { lat: 48.7483905, lng: 20.5223673 },
    { lat: 48.7484979, lng: 20.5229212 },
    { lat: 48.7486125, lng: 20.523215 },
    { lat: 48.7487589, lng: 20.5234605 },
    { lat: 48.7494395, lng: 20.5240801 },
    { lat: 48.7498248, lng: 20.5242935 },
    { lat: 48.7499697, lng: 20.5242904 },
    { lat: 48.7502992, lng: 20.5241279 },
    { lat: 48.7512481, lng: 20.523544 },
    { lat: 48.7515526, lng: 20.5235841 },
    { lat: 48.7529051, lng: 20.5250782 },
    { lat: 48.7537, lng: 20.5256485 },
    { lat: 48.7539467, lng: 20.5259251 },
    { lat: 48.754484, lng: 20.5269927 },
    { lat: 48.7550293, lng: 20.5286384 },
    { lat: 48.7552472, lng: 20.5297121 },
    { lat: 48.7551934, lng: 20.5305695 },
    { lat: 48.7552249, lng: 20.5312648 },
    { lat: 48.7551564, lng: 20.5318144 },
    { lat: 48.754919, lng: 20.5329379 },
    { lat: 48.7565354, lng: 20.5345077 },
    { lat: 48.7567841, lng: 20.535409 },
    { lat: 48.7568088, lng: 20.5361659 },
    { lat: 48.7566806, lng: 20.5370839 },
    { lat: 48.7564953, lng: 20.5390979 },
    { lat: 48.7564708, lng: 20.5411411 },
    { lat: 48.756719, lng: 20.543128 },
    { lat: 48.7568831, lng: 20.5431508 },
    { lat: 48.7579855, lng: 20.5443377 },
    { lat: 48.757995, lng: 20.545295 },
    { lat: 48.7582292, lng: 20.5469042 },
    { lat: 48.7595118, lng: 20.550139 },
    { lat: 48.7597747, lng: 20.550637 },
    { lat: 48.7606638, lng: 20.5518765 },
    { lat: 48.762628, lng: 20.5521985 },
    { lat: 48.7627838, lng: 20.5528784 },
    { lat: 48.761267, lng: 20.553794 },
    { lat: 48.762041, lng: 20.554168 },
    { lat: 48.762808, lng: 20.554656 },
    { lat: 48.763273, lng: 20.554853 },
    { lat: 48.763584, lng: 20.554916 },
    { lat: 48.764234, lng: 20.55491 },
    { lat: 48.764485, lng: 20.555012 },
    { lat: 48.764734, lng: 20.555069 },
    { lat: 48.765037, lng: 20.554986 },
    { lat: 48.765294, lng: 20.555239 },
    { lat: 48.765428, lng: 20.555338 },
    { lat: 48.765862, lng: 20.555352 },
    { lat: 48.766173, lng: 20.555045 },
    { lat: 48.766681, lng: 20.554883 },
    { lat: 48.766968, lng: 20.554955 },
    { lat: 48.767603, lng: 20.55465 },
    { lat: 48.767864, lng: 20.554679 },
    { lat: 48.768547, lng: 20.554645 },
    { lat: 48.76908, lng: 20.554203 },
    { lat: 48.769725, lng: 20.55399 },
    { lat: 48.770036, lng: 20.553871 },
    { lat: 48.77086, lng: 20.554242 },
    { lat: 48.771215, lng: 20.554269 },
    { lat: 48.771644, lng: 20.554438 },
    { lat: 48.771778, lng: 20.554324 },
    { lat: 48.772123, lng: 20.554178 },
    { lat: 48.77288, lng: 20.554211 },
    { lat: 48.773372, lng: 20.554128 },
    { lat: 48.774054, lng: 20.554095 },
    { lat: 48.774425, lng: 20.554179 },
    { lat: 48.774788, lng: 20.554224 },
    { lat: 48.775186, lng: 20.554188 },
    { lat: 48.776338, lng: 20.553205 },
    { lat: 48.777082, lng: 20.55258 },
    { lat: 48.778484, lng: 20.551214 },
    { lat: 48.779807, lng: 20.550035 },
    { lat: 48.780888, lng: 20.549318 },
    { lat: 48.780876, lng: 20.549042 },
    { lat: 48.781617, lng: 20.545961 },
    { lat: 48.782297, lng: 20.544084 },
    { lat: 48.782438, lng: 20.543676 },
    { lat: 48.782549, lng: 20.543388 },
    { lat: 48.78323, lng: 20.541608 },
    { lat: 48.783787, lng: 20.53916 },
    { lat: 48.784086, lng: 20.537938 },
    { lat: 48.78441, lng: 20.536909 },
    { lat: 48.78445, lng: 20.536391 },
    { lat: 48.784522, lng: 20.535441 },
    { lat: 48.78476, lng: 20.534765 },
    { lat: 48.785466, lng: 20.53427 },
    { lat: 48.787206, lng: 20.534112 },
    { lat: 48.789258, lng: 20.534679 },
    { lat: 48.78991, lng: 20.533409 },
    { lat: 48.794174, lng: 20.5336 },
    { lat: 48.793546, lng: 20.533009 },
    { lat: 48.793118, lng: 20.530916 },
    { lat: 48.793285, lng: 20.530099 },
    { lat: 48.793203, lng: 20.529817 },
    { lat: 48.793377, lng: 20.528803 },
    { lat: 48.793612, lng: 20.527944 },
    { lat: 48.794125, lng: 20.526998 },
    { lat: 48.794795, lng: 20.525214 },
    { lat: 48.795095, lng: 20.524014 },
    { lat: 48.796607, lng: 20.521663 },
    { lat: 48.796778, lng: 20.52107 },
    { lat: 48.797015, lng: 20.520604 },
    { lat: 48.796941, lng: 20.52002 },
    { lat: 48.796991, lng: 20.519398 },
    { lat: 48.797067, lng: 20.518604 },
    { lat: 48.797269, lng: 20.517208 },
    { lat: 48.797227, lng: 20.516764 },
    { lat: 48.797249, lng: 20.515508 },
    { lat: 48.797377, lng: 20.515023 },
    { lat: 48.797635, lng: 20.513073 },
    { lat: 48.798005, lng: 20.511715 },
    { lat: 48.798348, lng: 20.51101 },
    { lat: 48.798847, lng: 20.510611 },
    { lat: 48.799588, lng: 20.510251 },
    { lat: 48.799618, lng: 20.510189 },
    { lat: 48.800033, lng: 20.509968 },
    { lat: 48.800169, lng: 20.509716 },
    { lat: 48.80061, lng: 20.5094 },
    { lat: 48.801131, lng: 20.509022 },
    { lat: 48.80175, lng: 20.508442 },
    { lat: 48.801963, lng: 20.507967 },
    { lat: 48.802067, lng: 20.507801 },
    { lat: 48.802251, lng: 20.507328 },
    { lat: 48.802821, lng: 20.50703 },
    { lat: 48.80335, lng: 20.506826 },
    { lat: 48.803763, lng: 20.506546 },
    { lat: 48.80417, lng: 20.506338 },
    { lat: 48.805041, lng: 20.505856 },
    { lat: 48.805344, lng: 20.505335 },
    { lat: 48.805461, lng: 20.504784 },
    { lat: 48.805687, lng: 20.504364 },
    { lat: 48.805963, lng: 20.504094 },
    { lat: 48.806078, lng: 20.503395 },
    { lat: 48.806254, lng: 20.503007 },
    { lat: 48.806453, lng: 20.50278 },
    { lat: 48.806559, lng: 20.502486 },
    { lat: 48.806627, lng: 20.502075 },
    { lat: 48.806703, lng: 20.501631 },
    { lat: 48.806885, lng: 20.50112 },
    { lat: 48.807103, lng: 20.499897 },
    { lat: 48.807104, lng: 20.499114 },
    { lat: 48.807117, lng: 20.499112 },
    { lat: 48.807301, lng: 20.497163 },
    { lat: 48.807188, lng: 20.496055 },
    { lat: 48.807387, lng: 20.494665 },
    { lat: 48.807184, lng: 20.493759 },
    { lat: 48.807187, lng: 20.492637 },
    { lat: 48.807183, lng: 20.492307 },
    { lat: 48.807168, lng: 20.491341 },
    { lat: 48.806585, lng: 20.490634 },
    { lat: 48.806524, lng: 20.490217 },
    { lat: 48.80635, lng: 20.489828 },
    { lat: 48.8062, lng: 20.489574 },
    { lat: 48.805895, lng: 20.489063 },
    { lat: 48.805685, lng: 20.488662 },
    { lat: 48.805426, lng: 20.48867 },
    { lat: 48.805333, lng: 20.488596 },
    { lat: 48.804836, lng: 20.488362 },
    { lat: 48.804624, lng: 20.488184 },
    { lat: 48.804151, lng: 20.487908 },
    { lat: 48.803936, lng: 20.487659 },
    { lat: 48.803699, lng: 20.487439 },
    { lat: 48.803154, lng: 20.487443 },
    { lat: 48.802779, lng: 20.487024 },
    { lat: 48.802322, lng: 20.486429 },
    { lat: 48.801639, lng: 20.485826 },
    { lat: 48.801466, lng: 20.485615 },
    { lat: 48.801167, lng: 20.485394 },
    { lat: 48.800801, lng: 20.485072 },
    { lat: 48.800674, lng: 20.484905 },
    { lat: 48.800363, lng: 20.484758 },
    { lat: 48.800135, lng: 20.484561 },
    { lat: 48.79992, lng: 20.484545 },
    { lat: 48.799741, lng: 20.484384 },
    { lat: 48.799589, lng: 20.484142 },
    { lat: 48.799548, lng: 20.484073 },
    { lat: 48.799431, lng: 20.483706 },
    { lat: 48.799286, lng: 20.483447 },
    { lat: 48.799164, lng: 20.483394 },
    { lat: 48.798814, lng: 20.483021 },
    { lat: 48.798417, lng: 20.482837 },
    { lat: 48.798236, lng: 20.482913 },
    { lat: 48.79803, lng: 20.482941 },
    { lat: 48.797929, lng: 20.482933 },
    { lat: 48.797519, lng: 20.482795 },
    { lat: 48.797287, lng: 20.482829 },
    { lat: 48.796951, lng: 20.482761 },
    { lat: 48.796844, lng: 20.482821 },
    { lat: 48.796657, lng: 20.482823 },
    { lat: 48.796249, lng: 20.482688 },
    { lat: 48.795947, lng: 20.482709 },
    { lat: 48.795711, lng: 20.482515 },
    { lat: 48.795558, lng: 20.482401 },
    { lat: 48.795354, lng: 20.482265 },
    { lat: 48.795174, lng: 20.482206 },
    { lat: 48.795051, lng: 20.482173 },
    { lat: 48.794913, lng: 20.482024 },
    { lat: 48.794659, lng: 20.482076 },
    { lat: 48.794501, lng: 20.482173 },
    { lat: 48.794099, lng: 20.481785 },
    { lat: 48.793577, lng: 20.481491 },
    { lat: 48.79333, lng: 20.481227 },
    { lat: 48.793009, lng: 20.480924 },
    { lat: 48.792926, lng: 20.480782 },
    { lat: 48.792763, lng: 20.480559 },
    { lat: 48.792694, lng: 20.480425 },
    { lat: 48.791744, lng: 20.479264 },
    { lat: 48.791715, lng: 20.479281 },
    { lat: 48.791702, lng: 20.47929 },
    { lat: 48.791464, lng: 20.479307 },
    { lat: 48.791211, lng: 20.479023 },
    { lat: 48.790881, lng: 20.478719 },
    { lat: 48.790547, lng: 20.478281 },
    { lat: 48.790159, lng: 20.477782 },
    { lat: 48.789749, lng: 20.477247 },
    { lat: 48.789416, lng: 20.476878 },
    { lat: 48.789132, lng: 20.47636 },
    { lat: 48.7887849, lng: 20.4763565 },
    { lat: 48.7880803, lng: 20.4757956 },
    { lat: 48.7880112, lng: 20.4758472 },
    { lat: 48.787867, lng: 20.4757507 },
    { lat: 48.7877233, lng: 20.4757949 },
    { lat: 48.7875496, lng: 20.4757242 },
    { lat: 48.7873005, lng: 20.4757524 },
    { lat: 48.7868619, lng: 20.4753435 },
    { lat: 48.786385, lng: 20.4752128 },
    { lat: 48.7861051, lng: 20.4754048 },
    { lat: 48.7860219, lng: 20.4755573 },
    { lat: 48.7858937, lng: 20.4756486 },
    { lat: 48.785716, lng: 20.475654 },
    { lat: 48.7854387, lng: 20.4758535 },
    { lat: 48.785304, lng: 20.4761051 },
    { lat: 48.7850253, lng: 20.476299 },
    { lat: 48.7849594, lng: 20.4765853 },
    { lat: 48.7847882, lng: 20.4769136 },
    { lat: 48.7844528, lng: 20.4770371 },
    { lat: 48.784139, lng: 20.4767174 },
    { lat: 48.7839184, lng: 20.4767782 },
    { lat: 48.783779, lng: 20.4767245 },
    { lat: 48.7830762, lng: 20.4770785 },
    { lat: 48.7826312, lng: 20.4768573 },
    { lat: 48.7825012, lng: 20.4767257 },
    { lat: 48.7819272, lng: 20.476915 },
    { lat: 48.7812717, lng: 20.4764877 },
    { lat: 48.7807796, lng: 20.4766975 },
    { lat: 48.7803952, lng: 20.4764136 },
    { lat: 48.7797225, lng: 20.4764644 },
    { lat: 48.7792253, lng: 20.4761481 },
    { lat: 48.7790848, lng: 20.4761205 },
    { lat: 48.7788675, lng: 20.4762699 },
    { lat: 48.7785671, lng: 20.4766407 },
    { lat: 48.7783279, lng: 20.4768034 },
    { lat: 48.7780199, lng: 20.4772105 },
    { lat: 48.7776608, lng: 20.4770586 },
    { lat: 48.7780215, lng: 20.4742297 },
    { lat: 48.7774474, lng: 20.4731807 },
    { lat: 48.7767464, lng: 20.4721797 },
    { lat: 48.7762639, lng: 20.471658 },
    { lat: 48.7746821, lng: 20.4696863 },
    { lat: 48.7739935, lng: 20.4689575 },
    { lat: 48.7734315, lng: 20.4667417 },
    { lat: 48.7733987, lng: 20.4667674 },
    { lat: 48.7731088, lng: 20.4661234 },
    { lat: 48.7729423, lng: 20.465919 },
    { lat: 48.772677, lng: 20.4654218 },
    { lat: 48.7725562, lng: 20.4650281 },
    { lat: 48.7726312, lng: 20.4647464 },
    { lat: 48.771944, lng: 20.4650074 },
    { lat: 48.7716947, lng: 20.4646966 },
    { lat: 48.7716129, lng: 20.4643175 },
    { lat: 48.7711563, lng: 20.4645093 },
    { lat: 48.7708281, lng: 20.4648296 },
    { lat: 48.7707927, lng: 20.4651397 },
    { lat: 48.7684736, lng: 20.4638968 },
    { lat: 48.7665176, lng: 20.4637469 },
    { lat: 48.7662504, lng: 20.4636454 },
    { lat: 48.7654635, lng: 20.4636195 },
    { lat: 48.7649875, lng: 20.4634165 },
    { lat: 48.7640448, lng: 20.4632331 },
    { lat: 48.7631766, lng: 20.4629619 },
    { lat: 48.7630655, lng: 20.462738 },
    { lat: 48.7630368, lng: 20.46242 },
    { lat: 48.7626622, lng: 20.461866 },
    { lat: 48.762586, lng: 20.4616677 },
    { lat: 48.7625721, lng: 20.4609119 },
    { lat: 48.7625183, lng: 20.4608309 },
    { lat: 48.7624068, lng: 20.4602881 },
    { lat: 48.7622735, lng: 20.4599844 },
    { lat: 48.7622318, lng: 20.4592036 },
    { lat: 48.7610776, lng: 20.4582495 },
    { lat: 48.7609787, lng: 20.4579243 },
    { lat: 48.7597128, lng: 20.4560152 },
    { lat: 48.7592316, lng: 20.4544523 },
    { lat: 48.7584183, lng: 20.4539967 },
    { lat: 48.7578291, lng: 20.4539021 },
    { lat: 48.7574266, lng: 20.4536161 },
    { lat: 48.7571299, lng: 20.4532774 },
    { lat: 48.7520907, lng: 20.4523743 },
    { lat: 48.7516372, lng: 20.4519679 },
    { lat: 48.7508375, lng: 20.4515736 },
    { lat: 48.7498781, lng: 20.4512745 },
    { lat: 48.7498662, lng: 20.4513158 },
    { lat: 48.749578, lng: 20.4511681 },
    { lat: 48.7493385, lng: 20.4511488 },
    { lat: 48.7490797, lng: 20.4506781 },
    { lat: 48.7488568, lng: 20.4505865 },
    { lat: 48.7485406, lng: 20.4503241 },
    { lat: 48.7478501, lng: 20.4501392 },
    { lat: 48.7463091, lng: 20.4494416 },
    { lat: 48.7459446, lng: 20.4491517 },
    { lat: 48.7453442, lng: 20.4481066 },
    { lat: 48.7449426, lng: 20.4475524 },
    { lat: 48.7445833, lng: 20.4472495 },
  ],
  ČiernaLehota: [
    { lat: 48.7554296, lng: 20.2582659 },
    { lat: 48.7554833, lng: 20.2570939 },
    { lat: 48.7557816, lng: 20.2563788 },
    { lat: 48.7567762, lng: 20.2533332 },
    { lat: 48.7572577, lng: 20.2520129 },
    { lat: 48.7577124, lng: 20.2510409 },
    { lat: 48.7581242, lng: 20.2503497 },
    { lat: 48.7585467, lng: 20.2492731 },
    { lat: 48.7587997, lng: 20.2484678 },
    { lat: 48.7591986, lng: 20.2477913 },
    { lat: 48.7595377, lng: 20.2463446 },
    { lat: 48.7596202, lng: 20.2454433 },
    { lat: 48.7596815, lng: 20.2423591 },
    { lat: 48.7597367, lng: 20.2415913 },
    { lat: 48.7598554, lng: 20.2408338 },
    { lat: 48.7597969, lng: 20.2387812 },
    { lat: 48.7597899, lng: 20.2385368 },
    { lat: 48.7598406, lng: 20.237739 },
    { lat: 48.7598298, lng: 20.2376776 },
    { lat: 48.759503, lng: 20.2358155 },
    { lat: 48.7604416, lng: 20.2339343 },
    { lat: 48.7618434, lng: 20.2315416 },
    { lat: 48.7626383, lng: 20.230382 },
    { lat: 48.7635858, lng: 20.228804 },
    { lat: 48.765063, lng: 20.2265876 },
    { lat: 48.7664378, lng: 20.2213763 },
    { lat: 48.7665777, lng: 20.2204207 },
    { lat: 48.7676225, lng: 20.2170785 },
    { lat: 48.7684187, lng: 20.2162618 },
    { lat: 48.7693775, lng: 20.2151111 },
    { lat: 48.7700249, lng: 20.2141798 },
    { lat: 48.7716063, lng: 20.2113584 },
    { lat: 48.773564, lng: 20.20763 },
    { lat: 48.772769, lng: 20.206224 },
    { lat: 48.772185, lng: 20.205376 },
    { lat: 48.771786, lng: 20.204792 },
    { lat: 48.771283, lng: 20.204059 },
    { lat: 48.770867, lng: 20.203455 },
    { lat: 48.770647, lng: 20.203135 },
    { lat: 48.769829, lng: 20.201928 },
    { lat: 48.769749, lng: 20.201814 },
    { lat: 48.769082, lng: 20.200853 },
    { lat: 48.76868, lng: 20.200259 },
    { lat: 48.768329, lng: 20.199737 },
    { lat: 48.768111, lng: 20.199413 },
    { lat: 48.768031, lng: 20.199305 },
    { lat: 48.767458, lng: 20.198478 },
    { lat: 48.767176, lng: 20.198068 },
    { lat: 48.766841, lng: 20.197581 },
    { lat: 48.766431, lng: 20.197 },
    { lat: 48.76605, lng: 20.196455 },
    { lat: 48.765492, lng: 20.195643 },
    { lat: 48.76503, lng: 20.194987 },
    { lat: 48.764688, lng: 20.194507 },
    { lat: 48.764434, lng: 20.194156 },
    { lat: 48.764222, lng: 20.193862 },
    { lat: 48.763446, lng: 20.19279 },
    { lat: 48.763008, lng: 20.192871 },
    { lat: 48.762047, lng: 20.193063 },
    { lat: 48.761997, lng: 20.193072 },
    { lat: 48.761823, lng: 20.193106 },
    { lat: 48.760455, lng: 20.191579 },
    { lat: 48.759576, lng: 20.189026 },
    { lat: 48.758852, lng: 20.18692 },
    { lat: 48.758465, lng: 20.185793 },
    { lat: 48.758196, lng: 20.185312 },
    { lat: 48.757806, lng: 20.183309 },
    { lat: 48.757397, lng: 20.182547 },
    { lat: 48.757074, lng: 20.18195 },
    { lat: 48.756345, lng: 20.181984 },
    { lat: 48.756144, lng: 20.181993 },
    { lat: 48.755326, lng: 20.182049 },
    { lat: 48.754981, lng: 20.182058 },
    { lat: 48.754541, lng: 20.182059 },
    { lat: 48.754207, lng: 20.182078 },
    { lat: 48.753788, lng: 20.1821 },
    { lat: 48.753486, lng: 20.182107 },
    { lat: 48.752709, lng: 20.182124 },
    { lat: 48.752458, lng: 20.182122 },
    { lat: 48.751993, lng: 20.18213 },
    { lat: 48.751537, lng: 20.182146 },
    { lat: 48.75154, lng: 20.18218 },
    { lat: 48.751376, lng: 20.18227 },
    { lat: 48.751015, lng: 20.182471 },
    { lat: 48.750612, lng: 20.182688 },
    { lat: 48.750203, lng: 20.18279 },
    { lat: 48.74994, lng: 20.182689 },
    { lat: 48.749737, lng: 20.182703 },
    { lat: 48.749435, lng: 20.182736 },
    { lat: 48.749114, lng: 20.182805 },
    { lat: 48.7489, lng: 20.182852 },
    { lat: 48.748531, lng: 20.182844 },
    { lat: 48.748327, lng: 20.182713 },
    { lat: 48.748243, lng: 20.18264 },
    { lat: 48.747951, lng: 20.182363 },
    { lat: 48.74757, lng: 20.182447 },
    { lat: 48.747464, lng: 20.182543 },
    { lat: 48.747361, lng: 20.182657 },
    { lat: 48.747188, lng: 20.182707 },
    { lat: 48.746927, lng: 20.182786 },
    { lat: 48.746824, lng: 20.182807 },
    { lat: 48.746648, lng: 20.182867 },
    { lat: 48.746518, lng: 20.182868 },
    { lat: 48.746234, lng: 20.182857 },
    { lat: 48.745941, lng: 20.182703 },
    { lat: 48.745691, lng: 20.182573 },
    { lat: 48.745548, lng: 20.182528 },
    { lat: 48.745267, lng: 20.182412 },
    { lat: 48.745076, lng: 20.182334 },
    { lat: 48.744798, lng: 20.182221 },
    { lat: 48.744587, lng: 20.182242 },
    { lat: 48.744439, lng: 20.182247 },
    { lat: 48.744098, lng: 20.182235 },
    { lat: 48.743738, lng: 20.182076 },
    { lat: 48.74342, lng: 20.182136 },
    { lat: 48.742921, lng: 20.182199 },
    { lat: 48.742573, lng: 20.182073 },
    { lat: 48.742201, lng: 20.181941 },
    { lat: 48.741875, lng: 20.181825 },
    { lat: 48.741635, lng: 20.181732 },
    { lat: 48.741321, lng: 20.18148 },
    { lat: 48.740997, lng: 20.181222 },
    { lat: 48.740549, lng: 20.181416 },
    { lat: 48.739918, lng: 20.181696 },
    { lat: 48.739682, lng: 20.181485 },
    { lat: 48.739228, lng: 20.181665 },
    { lat: 48.738861, lng: 20.181813 },
    { lat: 48.738548, lng: 20.182119 },
    { lat: 48.738257, lng: 20.182392 },
    { lat: 48.737943, lng: 20.182343 },
    { lat: 48.737582, lng: 20.182699 },
    { lat: 48.737101, lng: 20.183193 },
    { lat: 48.73689, lng: 20.183311 },
    { lat: 48.736008, lng: 20.183776 },
    { lat: 48.735605, lng: 20.183783 },
    { lat: 48.735257, lng: 20.183785 },
    { lat: 48.73495, lng: 20.18379 },
    { lat: 48.734411, lng: 20.183796 },
    { lat: 48.734278, lng: 20.183791 },
    { lat: 48.7339, lng: 20.183737 },
    { lat: 48.733394, lng: 20.183665 },
    { lat: 48.733012, lng: 20.183602 },
    { lat: 48.732693, lng: 20.183575 },
    { lat: 48.732362, lng: 20.183528 },
    { lat: 48.731885, lng: 20.183458 },
    { lat: 48.731638, lng: 20.183399 },
    { lat: 48.731219, lng: 20.183141 },
    { lat: 48.730859, lng: 20.182926 },
    { lat: 48.730492, lng: 20.18275 },
    { lat: 48.730025, lng: 20.182517 },
    { lat: 48.729488, lng: 20.182385 },
    { lat: 48.729281, lng: 20.182366 },
    { lat: 48.729026, lng: 20.182331 },
    { lat: 48.728689, lng: 20.182264 },
    { lat: 48.728225, lng: 20.182303 },
    { lat: 48.727953, lng: 20.182544 },
    { lat: 48.727631, lng: 20.182844 },
    { lat: 48.72742, lng: 20.183056 },
    { lat: 48.727221, lng: 20.183248 },
    { lat: 48.726935, lng: 20.183494 },
    { lat: 48.726649, lng: 20.183875 },
    { lat: 48.726378, lng: 20.184238 },
    { lat: 48.726201, lng: 20.184592 },
    { lat: 48.725953, lng: 20.184994 },
    { lat: 48.725623, lng: 20.185205 },
    { lat: 48.725264, lng: 20.185399 },
    { lat: 48.725019, lng: 20.185774 },
    { lat: 48.724793, lng: 20.1861 },
    { lat: 48.724281, lng: 20.186698 },
    { lat: 48.724177, lng: 20.186822 },
    { lat: 48.723786, lng: 20.187324 },
    { lat: 48.72361, lng: 20.187582 },
    { lat: 48.723409, lng: 20.187874 },
    { lat: 48.723163, lng: 20.188265 },
    { lat: 48.722826, lng: 20.188751 },
    { lat: 48.722343, lng: 20.189167 },
    { lat: 48.721874, lng: 20.189593 },
    { lat: 48.721444, lng: 20.189991 },
    { lat: 48.721399, lng: 20.190033 },
    { lat: 48.721388, lng: 20.190006 },
    { lat: 48.720737, lng: 20.190464 },
    { lat: 48.720113, lng: 20.190903 },
    { lat: 48.719834, lng: 20.19269 },
    { lat: 48.719843, lng: 20.192776 },
    { lat: 48.719845, lng: 20.192792 },
    { lat: 48.719937, lng: 20.193644 },
    { lat: 48.719987, lng: 20.194124 },
    { lat: 48.719993, lng: 20.194167 },
    { lat: 48.720108, lng: 20.195253 },
    { lat: 48.720187, lng: 20.196615 },
    { lat: 48.720231, lng: 20.198036 },
    { lat: 48.720237, lng: 20.198267 },
    { lat: 48.720397, lng: 20.199141 },
    { lat: 48.720871, lng: 20.200359 },
    { lat: 48.721043, lng: 20.200623 },
    { lat: 48.72118, lng: 20.201068 },
    { lat: 48.721586, lng: 20.202044 },
    { lat: 48.721736, lng: 20.202638 },
    { lat: 48.721799, lng: 20.203882 },
    { lat: 48.721847, lng: 20.204125 },
    { lat: 48.721833, lng: 20.204851 },
    { lat: 48.72189, lng: 20.205387 },
    { lat: 48.721953, lng: 20.205853 },
    { lat: 48.722, lng: 20.206397 },
    { lat: 48.722097, lng: 20.207311 },
    { lat: 48.722063, lng: 20.207539 },
    { lat: 48.722083, lng: 20.207723 },
    { lat: 48.722059, lng: 20.207954 },
    { lat: 48.722013, lng: 20.208519 },
    { lat: 48.72204, lng: 20.209134 },
    { lat: 48.722031, lng: 20.209728 },
    { lat: 48.721395, lng: 20.210872 },
    { lat: 48.721198, lng: 20.211243 },
    { lat: 48.720739, lng: 20.211961 },
    { lat: 48.720509, lng: 20.212535 },
    { lat: 48.720199, lng: 20.212948 },
    { lat: 48.719815, lng: 20.21363 },
    { lat: 48.719815, lng: 20.213864 },
    { lat: 48.719766, lng: 20.214116 },
    { lat: 48.719547, lng: 20.214592 },
    { lat: 48.719329, lng: 20.215283 },
    { lat: 48.719084, lng: 20.2156 },
    { lat: 48.718992, lng: 20.215719 },
    { lat: 48.718953, lng: 20.21577 },
    { lat: 48.718645, lng: 20.216288 },
    { lat: 48.718502, lng: 20.216383 },
    { lat: 48.718081, lng: 20.216626 },
    { lat: 48.71798, lng: 20.216684 },
    { lat: 48.717607, lng: 20.216787 },
    { lat: 48.717484, lng: 20.216857 },
    { lat: 48.717408, lng: 20.216901 },
    { lat: 48.717313, lng: 20.216954 },
    { lat: 48.717063, lng: 20.217272 },
    { lat: 48.71695, lng: 20.217445 },
    { lat: 48.716763, lng: 20.217729 },
    { lat: 48.716498, lng: 20.218133 },
    { lat: 48.716169, lng: 20.219011 },
    { lat: 48.716031, lng: 20.219239 },
    { lat: 48.715702, lng: 20.219786 },
    { lat: 48.71493, lng: 20.22093 },
    { lat: 48.714776, lng: 20.221142 },
    { lat: 48.714214, lng: 20.221934 },
    { lat: 48.71414, lng: 20.222161 },
    { lat: 48.713999, lng: 20.222411 },
    { lat: 48.713797, lng: 20.222721 },
    { lat: 48.713639, lng: 20.22294 },
    { lat: 48.713412, lng: 20.223116 },
    { lat: 48.713049, lng: 20.223416 },
    { lat: 48.712197, lng: 20.224189 },
    { lat: 48.711896, lng: 20.224549 },
    { lat: 48.711645, lng: 20.225005 },
    { lat: 48.711395, lng: 20.225493 },
    { lat: 48.711226, lng: 20.225864 },
    { lat: 48.71112, lng: 20.226211 },
    { lat: 48.710731, lng: 20.227059 },
    { lat: 48.710675, lng: 20.227442 },
    { lat: 48.710655, lng: 20.227584 },
    { lat: 48.710637, lng: 20.227713 },
    { lat: 48.710481, lng: 20.228068 },
    { lat: 48.709893, lng: 20.228949 },
    { lat: 48.709706, lng: 20.229228 },
    { lat: 48.709033, lng: 20.230624 },
    { lat: 48.707697, lng: 20.233168 },
    { lat: 48.706601, lng: 20.234145 },
    { lat: 48.705858, lng: 20.235374 },
    { lat: 48.704768, lng: 20.236558 },
    { lat: 48.70444, lng: 20.237116 },
    { lat: 48.7045936, lng: 20.2377323 },
    { lat: 48.7052315, lng: 20.2391288 },
    { lat: 48.7056265, lng: 20.2401733 },
    { lat: 48.7059554, lng: 20.240658 },
    { lat: 48.70618, lng: 20.2411782 },
    { lat: 48.706928, lng: 20.2423459 },
    { lat: 48.7069824, lng: 20.2431761 },
    { lat: 48.7072615, lng: 20.2435413 },
    { lat: 48.7074137, lng: 20.2435168 },
    { lat: 48.7078294, lng: 20.2437166 },
    { lat: 48.7079867, lng: 20.2436795 },
    { lat: 48.7080643, lng: 20.2437916 },
    { lat: 48.7085043, lng: 20.2438715 },
    { lat: 48.7089458, lng: 20.2441253 },
    { lat: 48.7091395, lng: 20.2444932 },
    { lat: 48.7101225, lng: 20.245653 },
    { lat: 48.7105801, lng: 20.2459999 },
    { lat: 48.7109896, lng: 20.2465886 },
    { lat: 48.7114172, lng: 20.2470356 },
    { lat: 48.7115462, lng: 20.2472482 },
    { lat: 48.7116753, lng: 20.2477461 },
    { lat: 48.7126072, lng: 20.2484434 },
    { lat: 48.7128652, lng: 20.2490698 },
    { lat: 48.7134714, lng: 20.249757 },
    { lat: 48.7137241, lng: 20.2503101 },
    { lat: 48.7149493, lng: 20.251671 },
    { lat: 48.715532, lng: 20.2522499 },
    { lat: 48.71629, lng: 20.2528821 },
    { lat: 48.7169967, lng: 20.2538853 },
    { lat: 48.7171191, lng: 20.2544741 },
    { lat: 48.7170876, lng: 20.2562708 },
    { lat: 48.7173514, lng: 20.2578982 },
    { lat: 48.7176112, lng: 20.2587157 },
    { lat: 48.7176426, lng: 20.259765 },
    { lat: 48.7175672, lng: 20.2610218 },
    { lat: 48.7176945, lng: 20.2622182 },
    { lat: 48.7177215, lng: 20.2629016 },
    { lat: 48.7178174, lng: 20.263704 },
    { lat: 48.7180921, lng: 20.2646996 },
    { lat: 48.7182445, lng: 20.2647343 },
    { lat: 48.7193677, lng: 20.2654618 },
    { lat: 48.7193498, lng: 20.2656058 },
    { lat: 48.7199372, lng: 20.265989 },
    { lat: 48.7199856, lng: 20.2663356 },
    { lat: 48.7201072, lng: 20.2665874 },
    { lat: 48.7211163, lng: 20.2679866 },
    { lat: 48.7210709, lng: 20.2680627 },
    { lat: 48.7223436, lng: 20.2696664 },
    { lat: 48.7224007, lng: 20.2695457 },
    { lat: 48.7229074, lng: 20.2701378 },
    { lat: 48.7228955, lng: 20.2701849 },
    { lat: 48.7232299, lng: 20.2706387 },
    { lat: 48.7232143, lng: 20.2707272 },
    { lat: 48.7237625, lng: 20.2706082 },
    { lat: 48.7239199, lng: 20.271087 },
    { lat: 48.7243751, lng: 20.2715763 },
    { lat: 48.7254934, lng: 20.2723181 },
    { lat: 48.7255661, lng: 20.2721325 },
    { lat: 48.7258933, lng: 20.2724654 },
    { lat: 48.7261674, lng: 20.2725866 },
    { lat: 48.7263101, lng: 20.2724108 },
    { lat: 48.726694, lng: 20.2715793 },
    { lat: 48.7274262, lng: 20.2720134 },
    { lat: 48.7285325, lng: 20.2722541 },
    { lat: 48.7287178, lng: 20.2722363 },
    { lat: 48.7290715, lng: 20.2719544 },
    { lat: 48.729509, lng: 20.2714805 },
    { lat: 48.730473, lng: 20.270769 },
    { lat: 48.7306929, lng: 20.2704334 },
    { lat: 48.7308019, lng: 20.2705706 },
    { lat: 48.730954, lng: 20.2701192 },
    { lat: 48.7309664, lng: 20.2698591 },
    { lat: 48.7312143, lng: 20.2692934 },
    { lat: 48.7313752, lng: 20.2691494 },
    { lat: 48.7314182, lng: 20.2689829 },
    { lat: 48.7319051, lng: 20.2687931 },
    { lat: 48.7321562, lng: 20.2683327 },
    { lat: 48.7323046, lng: 20.2682715 },
    { lat: 48.7325589, lng: 20.2678077 },
    { lat: 48.7327071, lng: 20.2677978 },
    { lat: 48.7326885, lng: 20.2675516 },
    { lat: 48.7330904, lng: 20.2671468 },
    { lat: 48.7331186, lng: 20.2669809 },
    { lat: 48.7332373, lng: 20.2667904 },
    { lat: 48.7332575, lng: 20.2666216 },
    { lat: 48.7334797, lng: 20.2663129 },
    { lat: 48.7338304, lng: 20.2663569 },
    { lat: 48.734491, lng: 20.2660836 },
    { lat: 48.7346604, lng: 20.2659264 },
    { lat: 48.7349399, lng: 20.2657902 },
    { lat: 48.7352524, lng: 20.2653203 },
    { lat: 48.7355397, lng: 20.2652634 },
    { lat: 48.735782, lng: 20.2652982 },
    { lat: 48.7361109, lng: 20.2654698 },
    { lat: 48.7368961, lng: 20.2656433 },
    { lat: 48.7374378, lng: 20.2654192 },
    { lat: 48.7376943, lng: 20.2651874 },
    { lat: 48.7389968, lng: 20.2651469 },
    { lat: 48.739755, lng: 20.2649486 },
    { lat: 48.7403055, lng: 20.2650313 },
    { lat: 48.7412982, lng: 20.2654166 },
    { lat: 48.7417721, lng: 20.2653831 },
    { lat: 48.7430381, lng: 20.2644767 },
    { lat: 48.7436786, lng: 20.2638098 },
    { lat: 48.7441253, lng: 20.2639461 },
    { lat: 48.7446334, lng: 20.2638934 },
    { lat: 48.7448806, lng: 20.2639368 },
    { lat: 48.7464401, lng: 20.2643295 },
    { lat: 48.7473367, lng: 20.264362 },
    { lat: 48.7478295, lng: 20.2642072 },
    { lat: 48.748938, lng: 20.2632767 },
    { lat: 48.7495995, lng: 20.2627984 },
    { lat: 48.7502041, lng: 20.2621128 },
    { lat: 48.7508568, lng: 20.2608744 },
    { lat: 48.7529338, lng: 20.2586064 },
    { lat: 48.7538888, lng: 20.2581518 },
    { lat: 48.7554296, lng: 20.2582659 },
  ],
  Kružná: [
    { lat: 48.6507869, lng: 20.4698012 },
    { lat: 48.650602, lng: 20.4687555 },
    { lat: 48.6504279, lng: 20.4687673 },
    { lat: 48.6493073, lng: 20.4680158 },
    { lat: 48.649093, lng: 20.4677651 },
    { lat: 48.6486611, lng: 20.4670327 },
    { lat: 48.648143, lng: 20.4664656 },
    { lat: 48.6475868, lng: 20.4659601 },
    { lat: 48.6470276, lng: 20.4656155 },
    { lat: 48.6464756, lng: 20.465402 },
    { lat: 48.645719, lng: 20.4653422 },
    { lat: 48.6455119, lng: 20.4651072 },
    { lat: 48.6452238, lng: 20.4651698 },
    { lat: 48.6448873, lng: 20.4635651 },
    { lat: 48.6448644, lng: 20.4623678 },
    { lat: 48.6449278, lng: 20.4613073 },
    { lat: 48.6446615, lng: 20.4586715 },
    { lat: 48.6443125, lng: 20.4569537 },
    { lat: 48.6437177, lng: 20.4545959 },
    { lat: 48.6434084, lng: 20.4530595 },
    { lat: 48.642786, lng: 20.451376 },
    { lat: 48.6425695, lng: 20.4501852 },
    { lat: 48.6426511, lng: 20.449126 },
    { lat: 48.6427551, lng: 20.4487524 },
    { lat: 48.642886, lng: 20.4484965 },
    { lat: 48.6430101, lng: 20.4483825 },
    { lat: 48.6430564, lng: 20.4476148 },
    { lat: 48.6432342, lng: 20.4472893 },
    { lat: 48.6433575, lng: 20.4469115 },
    { lat: 48.6433794, lng: 20.4463645 },
    { lat: 48.643495, lng: 20.4462658 },
    { lat: 48.6435114, lng: 20.4460447 },
    { lat: 48.6435669, lng: 20.4461136 },
    { lat: 48.6435589, lng: 20.4452213 },
    { lat: 48.643818, lng: 20.4425255 },
    { lat: 48.6438125, lng: 20.4421457 },
    { lat: 48.6435787, lng: 20.4401129 },
    { lat: 48.6434418, lng: 20.4398887 },
    { lat: 48.643372, lng: 20.4394735 },
    { lat: 48.6432947, lng: 20.43872 },
    { lat: 48.6430518, lng: 20.4385808 },
    { lat: 48.6419934, lng: 20.4386088 },
    { lat: 48.6413983, lng: 20.4353099 },
    { lat: 48.6412635, lng: 20.4351421 },
    { lat: 48.6407466, lng: 20.4353482 },
    { lat: 48.6399351, lng: 20.4354158 },
    { lat: 48.6393583, lng: 20.4360005 },
    { lat: 48.6391162, lng: 20.4363302 },
    { lat: 48.6386748, lng: 20.4364436 },
    { lat: 48.6374371, lng: 20.4373284 },
    { lat: 48.6371091, lng: 20.4374078 },
    { lat: 48.6364107, lng: 20.4373405 },
    { lat: 48.6357674, lng: 20.4370103 },
    { lat: 48.6355236, lng: 20.4372329 },
    { lat: 48.6353446, lng: 20.4372602 },
    { lat: 48.635004, lng: 20.4377771 },
    { lat: 48.6347266, lng: 20.4384191 },
    { lat: 48.6346836, lng: 20.4388889 },
    { lat: 48.6348881, lng: 20.4394692 },
    { lat: 48.6350697, lng: 20.4401862 },
    { lat: 48.6346306, lng: 20.4402178 },
    { lat: 48.6341286, lng: 20.4406991 },
    { lat: 48.6338433, lng: 20.4408346 },
    { lat: 48.6335813, lng: 20.4408131 },
    { lat: 48.6325793, lng: 20.4403438 },
    { lat: 48.6321626, lng: 20.4405184 },
    { lat: 48.631736, lng: 20.4408778 },
    { lat: 48.6307881, lng: 20.4409456 },
    { lat: 48.6300706, lng: 20.4423298 },
    { lat: 48.629747, lng: 20.4427309 },
    { lat: 48.629233, lng: 20.4431338 },
    { lat: 48.6289633, lng: 20.4434174 },
    { lat: 48.6283496, lng: 20.4437424 },
    { lat: 48.6274315, lng: 20.4449157 },
    { lat: 48.6270031, lng: 20.4453144 },
    { lat: 48.6267343, lng: 20.445742 },
    { lat: 48.6262781, lng: 20.4468329 },
    { lat: 48.6259009, lng: 20.4474564 },
    { lat: 48.6255166, lng: 20.4496401 },
    { lat: 48.625466, lng: 20.4513327 },
    { lat: 48.6255178, lng: 20.4516607 },
    { lat: 48.6279578, lng: 20.4578628 },
    { lat: 48.6286382, lng: 20.4594163 },
    { lat: 48.6300884, lng: 20.4629805 },
    { lat: 48.6309288, lng: 20.4648673 },
    { lat: 48.6309201, lng: 20.4650236 },
    { lat: 48.6306911, lng: 20.4658446 },
    { lat: 48.6305872, lng: 20.4660746 },
    { lat: 48.6303746, lng: 20.4664112 },
    { lat: 48.6302031, lng: 20.4663686 },
    { lat: 48.629847, lng: 20.4669599 },
    { lat: 48.6296024, lng: 20.4676032 },
    { lat: 48.6282229, lng: 20.4700726 },
    { lat: 48.6282107, lng: 20.4705567 },
    { lat: 48.6280335, lng: 20.4708431 },
    { lat: 48.6278953, lng: 20.4713889 },
    { lat: 48.6279043, lng: 20.4716832 },
    { lat: 48.6276988, lng: 20.4720597 },
    { lat: 48.6277044, lng: 20.4722372 },
    { lat: 48.6286832, lng: 20.4724844 },
    { lat: 48.6310612, lng: 20.4733473 },
    { lat: 48.630873, lng: 20.4736372 },
    { lat: 48.6290943, lng: 20.4772285 },
    { lat: 48.6266921, lng: 20.4816665 },
    { lat: 48.6263774, lng: 20.4824257 },
    { lat: 48.6268794, lng: 20.4825417 },
    { lat: 48.6285588, lng: 20.4827398 },
    { lat: 48.6286936, lng: 20.4828176 },
    { lat: 48.6293298, lng: 20.4829072 },
    { lat: 48.62922, lng: 20.4838503 },
    { lat: 48.6293824, lng: 20.4842158 },
    { lat: 48.6305188, lng: 20.4847858 },
    { lat: 48.630799, lng: 20.4847842 },
    { lat: 48.6316833, lng: 20.4841994 },
    { lat: 48.6318856, lng: 20.4841279 },
    { lat: 48.6321342, lng: 20.4842426 },
    { lat: 48.6325053, lng: 20.4841976 },
    { lat: 48.6326493, lng: 20.4842825 },
    { lat: 48.6329101, lng: 20.4841213 },
    { lat: 48.6333391, lng: 20.4841539 },
    { lat: 48.633688, lng: 20.4844764 },
    { lat: 48.6339612, lng: 20.4846435 },
    { lat: 48.6345036, lng: 20.4852082 },
    { lat: 48.6345341, lng: 20.485327 },
    { lat: 48.6361345, lng: 20.4866249 },
    { lat: 48.6381937, lng: 20.4876026 },
    { lat: 48.6398105, lng: 20.4882559 },
    { lat: 48.6402918, lng: 20.4887192 },
    { lat: 48.6405373, lng: 20.4891439 },
    { lat: 48.6405671, lng: 20.4888573 },
    { lat: 48.6407291, lng: 20.4888224 },
    { lat: 48.640792, lng: 20.4889064 },
    { lat: 48.6409008, lng: 20.4889273 },
    { lat: 48.6410262, lng: 20.4888169 },
    { lat: 48.6413671, lng: 20.4883013 },
    { lat: 48.6415097, lng: 20.4879584 },
    { lat: 48.6419084, lng: 20.4877248 },
    { lat: 48.6419283, lng: 20.4879207 },
    { lat: 48.642409, lng: 20.4877717 },
    { lat: 48.6424576, lng: 20.4875977 },
    { lat: 48.6425206, lng: 20.4875983 },
    { lat: 48.642548, lng: 20.4876974 },
    { lat: 48.6427031, lng: 20.48787 },
    { lat: 48.6428864, lng: 20.4878315 },
    { lat: 48.6431216, lng: 20.4880994 },
    { lat: 48.6433616, lng: 20.488133 },
    { lat: 48.6435104, lng: 20.4880166 },
    { lat: 48.6436215, lng: 20.4880342 },
    { lat: 48.6437708, lng: 20.4882019 },
    { lat: 48.6438424, lng: 20.4884322 },
    { lat: 48.6439181, lng: 20.488404 },
    { lat: 48.6440262, lng: 20.4880173 },
    { lat: 48.6439757, lng: 20.4879607 },
    { lat: 48.6440244, lng: 20.4878198 },
    { lat: 48.6442851, lng: 20.4881076 },
    { lat: 48.6443559, lng: 20.487831 },
    { lat: 48.6443745, lng: 20.4876994 },
    { lat: 48.6442235, lng: 20.4876686 },
    { lat: 48.6441901, lng: 20.4875568 },
    { lat: 48.6441918, lng: 20.4872144 },
    { lat: 48.6442747, lng: 20.4869894 },
    { lat: 48.6443378, lng: 20.486937 },
    { lat: 48.6445797, lng: 20.4870758 },
    { lat: 48.6447379, lng: 20.4870024 },
    { lat: 48.6448064, lng: 20.4866989 },
    { lat: 48.6447412, lng: 20.4864335 },
    { lat: 48.6447711, lng: 20.4862978 },
    { lat: 48.6448437, lng: 20.4862207 },
    { lat: 48.645094, lng: 20.4863844 },
    { lat: 48.6452248, lng: 20.4863294 },
    { lat: 48.6453982, lng: 20.48582 },
    { lat: 48.6455444, lng: 20.4857572 },
    { lat: 48.6456057, lng: 20.4856484 },
    { lat: 48.6457596, lng: 20.4852168 },
    { lat: 48.645703, lng: 20.4849418 },
    { lat: 48.6454955, lng: 20.4846313 },
    { lat: 48.6454768, lng: 20.4845001 },
    { lat: 48.6455841, lng: 20.4837905 },
    { lat: 48.6456933, lng: 20.4836682 },
    { lat: 48.645725, lng: 20.4837588 },
    { lat: 48.6457038, lng: 20.4839732 },
    { lat: 48.6458273, lng: 20.4838743 },
    { lat: 48.6458442, lng: 20.483635 },
    { lat: 48.6459227, lng: 20.4835403 },
    { lat: 48.6461314, lng: 20.4836104 },
    { lat: 48.6462839, lng: 20.4835642 },
    { lat: 48.6463318, lng: 20.4835829 },
    { lat: 48.6463435, lng: 20.4836794 },
    { lat: 48.6464129, lng: 20.4837002 },
    { lat: 48.6464976, lng: 20.4835868 },
    { lat: 48.6464734, lng: 20.4833959 },
    { lat: 48.6467405, lng: 20.4832829 },
    { lat: 48.6464577, lng: 20.4830333 },
    { lat: 48.6464748, lng: 20.4828933 },
    { lat: 48.6468394, lng: 20.4825252 },
    { lat: 48.646826, lng: 20.4823202 },
    { lat: 48.6468896, lng: 20.4822165 },
    { lat: 48.6468558, lng: 20.4818886 },
    { lat: 48.6469712, lng: 20.4814823 },
    { lat: 48.6470921, lng: 20.4814346 },
    { lat: 48.6471325, lng: 20.4813104 },
    { lat: 48.6470897, lng: 20.4811581 },
    { lat: 48.6469562, lng: 20.4810838 },
    { lat: 48.6469434, lng: 20.4809524 },
    { lat: 48.6471018, lng: 20.4803783 },
    { lat: 48.647341, lng: 20.4805376 },
    { lat: 48.6477132, lng: 20.4804045 },
    { lat: 48.6478301, lng: 20.4802489 },
    { lat: 48.6480993, lng: 20.4801357 },
    { lat: 48.6482525, lng: 20.479926 },
    { lat: 48.6482778, lng: 20.479432 },
    { lat: 48.6483626, lng: 20.4793543 },
    { lat: 48.6487286, lng: 20.4786352 },
    { lat: 48.648801, lng: 20.4782889 },
    { lat: 48.6490545, lng: 20.4779353 },
    { lat: 48.6491461, lng: 20.4780058 },
    { lat: 48.649247, lng: 20.4779597 },
    { lat: 48.6493175, lng: 20.4777685 },
    { lat: 48.6493922, lng: 20.4777475 },
    { lat: 48.6494303, lng: 20.4776267 },
    { lat: 48.6493935, lng: 20.477513 },
    { lat: 48.6494578, lng: 20.4773982 },
    { lat: 48.6495151, lng: 20.4770959 },
    { lat: 48.6493258, lng: 20.4767629 },
    { lat: 48.6494806, lng: 20.4765701 },
    { lat: 48.6496091, lng: 20.4757981 },
    { lat: 48.6495581, lng: 20.4756721 },
    { lat: 48.6496758, lng: 20.4752379 },
    { lat: 48.6498513, lng: 20.4752054 },
    { lat: 48.6498499, lng: 20.4750499 },
    { lat: 48.6499716, lng: 20.4749611 },
    { lat: 48.6500736, lng: 20.4745325 },
    { lat: 48.650099, lng: 20.4740973 },
    { lat: 48.6502497, lng: 20.4738272 },
    { lat: 48.6501274, lng: 20.473483 },
    { lat: 48.6502497, lng: 20.4732275 },
    { lat: 48.650288, lng: 20.4729048 },
    { lat: 48.6501742, lng: 20.4727526 },
    { lat: 48.650154, lng: 20.4725738 },
    { lat: 48.6502192, lng: 20.4723958 },
    { lat: 48.6502245, lng: 20.4720841 },
    { lat: 48.6501416, lng: 20.4717387 },
    { lat: 48.6502232, lng: 20.4712435 },
    { lat: 48.6501943, lng: 20.4710796 },
    { lat: 48.6502996, lng: 20.4706126 },
    { lat: 48.6503748, lng: 20.4704612 },
    { lat: 48.6503659, lng: 20.4701632 },
    { lat: 48.6504043, lng: 20.470016 },
    { lat: 48.6505069, lng: 20.4699029 },
    { lat: 48.6507911, lng: 20.4698243 },
    { lat: 48.6507869, lng: 20.4698012 },
  ],
  Meliata: [
    { lat: 48.5208492, lng: 20.3641902 },
    { lat: 48.5208831, lng: 20.3641961 },
    { lat: 48.5213758, lng: 20.3642668 },
    { lat: 48.5218817, lng: 20.3642185 },
    { lat: 48.5223308, lng: 20.3643584 },
    { lat: 48.522714, lng: 20.3643645 },
    { lat: 48.5231018, lng: 20.3642029 },
    { lat: 48.5240527, lng: 20.3642727 },
    { lat: 48.5245194, lng: 20.3640214 },
    { lat: 48.5252779, lng: 20.3641561 },
    { lat: 48.5256937, lng: 20.364304 },
    { lat: 48.526044, lng: 20.3645677 },
    { lat: 48.5264522, lng: 20.3646402 },
    { lat: 48.5266937, lng: 20.3649152 },
    { lat: 48.5275068, lng: 20.3648285 },
    { lat: 48.5278036, lng: 20.3651054 },
    { lat: 48.5280409, lng: 20.3652139 },
    { lat: 48.5287121, lng: 20.3657077 },
    { lat: 48.5289293, lng: 20.3657661 },
    { lat: 48.5298406, lng: 20.3650713 },
    { lat: 48.5300827, lng: 20.3647242 },
    { lat: 48.5305102, lng: 20.3636684 },
    { lat: 48.531545, lng: 20.3586781 },
    { lat: 48.5316, lng: 20.355629 },
    { lat: 48.5317222, lng: 20.3538729 },
    { lat: 48.5321036, lng: 20.3516343 },
    { lat: 48.532477, lng: 20.3488171 },
    { lat: 48.5331303, lng: 20.3472554 },
    { lat: 48.5336167, lng: 20.3456931 },
    { lat: 48.5337508, lng: 20.3446231 },
    { lat: 48.53409, lng: 20.3436182 },
    { lat: 48.5343319, lng: 20.3430627 },
    { lat: 48.5352865, lng: 20.3415514 },
    { lat: 48.5360298, lng: 20.3408898 },
    { lat: 48.5364949, lng: 20.3400235 },
    { lat: 48.5367797, lng: 20.3393573 },
    { lat: 48.5372762, lng: 20.3377044 },
    { lat: 48.5380798, lng: 20.3336256 },
    { lat: 48.538299, lng: 20.332106 },
    { lat: 48.538281, lng: 20.332092 },
    { lat: 48.537553, lng: 20.331797 },
    { lat: 48.537346, lng: 20.331713 },
    { lat: 48.537025, lng: 20.331494 },
    { lat: 48.536999, lng: 20.331464 },
    { lat: 48.536881, lng: 20.331288 },
    { lat: 48.536554, lng: 20.330659 },
    { lat: 48.536032, lng: 20.329483 },
    { lat: 48.535909, lng: 20.32929 },
    { lat: 48.53582, lng: 20.328962 },
    { lat: 48.535517, lng: 20.328367 },
    { lat: 48.535691, lng: 20.32805 },
    { lat: 48.535768, lng: 20.327896 },
    { lat: 48.535832, lng: 20.327628 },
    { lat: 48.536076, lng: 20.327154 },
    { lat: 48.536182, lng: 20.326888 },
    { lat: 48.536269, lng: 20.326524 },
    { lat: 48.536246, lng: 20.326328 },
    { lat: 48.536296, lng: 20.326123 },
    { lat: 48.536319, lng: 20.325831 },
    { lat: 48.536336, lng: 20.325296 },
    { lat: 48.536158, lng: 20.324732 },
    { lat: 48.536065, lng: 20.324519 },
    { lat: 48.535783, lng: 20.324285 },
    { lat: 48.53545, lng: 20.323848 },
    { lat: 48.534905, lng: 20.323442 },
    { lat: 48.534498, lng: 20.323046 },
    { lat: 48.534425, lng: 20.322981 },
    { lat: 48.534045, lng: 20.322682 },
    { lat: 48.533919, lng: 20.322621 },
    { lat: 48.533643, lng: 20.32245 },
    { lat: 48.533507, lng: 20.3224 },
    { lat: 48.533317, lng: 20.322329 },
    { lat: 48.533086, lng: 20.322306 },
    { lat: 48.532819, lng: 20.32228 },
    { lat: 48.532593, lng: 20.322316 },
    { lat: 48.532421, lng: 20.322377 },
    { lat: 48.532192, lng: 20.322476 },
    { lat: 48.531691, lng: 20.322506 },
    { lat: 48.531385, lng: 20.322417 },
    { lat: 48.530864, lng: 20.32231 },
    { lat: 48.530566, lng: 20.322167 },
    { lat: 48.530303, lng: 20.321998 },
    { lat: 48.530132, lng: 20.321769 },
    { lat: 48.529774, lng: 20.321443 },
    { lat: 48.529747, lng: 20.321432 },
    { lat: 48.529615, lng: 20.321382 },
    { lat: 48.529374, lng: 20.321228 },
    { lat: 48.528894, lng: 20.321049 },
    { lat: 48.528729, lng: 20.32103 },
    { lat: 48.5286, lng: 20.321013 },
    { lat: 48.528308, lng: 20.320932 },
    { lat: 48.528138, lng: 20.32083 },
    { lat: 48.527956, lng: 20.320778 },
    { lat: 48.527704, lng: 20.320564 },
    { lat: 48.527577, lng: 20.320525 },
    { lat: 48.527405, lng: 20.320493 },
    { lat: 48.527075, lng: 20.320417 },
    { lat: 48.526946, lng: 20.320383 },
    { lat: 48.526683, lng: 20.320142 },
    { lat: 48.526305, lng: 20.319999 },
    { lat: 48.525861, lng: 20.319799 },
    { lat: 48.525768, lng: 20.319597 },
    { lat: 48.525639, lng: 20.319456 },
    { lat: 48.525641, lng: 20.319437 },
    { lat: 48.525327, lng: 20.319294 },
    { lat: 48.524982, lng: 20.319154 },
    { lat: 48.524776, lng: 20.319079 },
    { lat: 48.52443, lng: 20.318899 },
    { lat: 48.523895, lng: 20.318544 },
    { lat: 48.523875, lng: 20.318493 },
    { lat: 48.523838, lng: 20.318422 },
    { lat: 48.523795, lng: 20.318338 },
    { lat: 48.523771, lng: 20.318292 },
    { lat: 48.523652, lng: 20.318064 },
    { lat: 48.523447, lng: 20.317688 },
    { lat: 48.523156, lng: 20.317177 },
    { lat: 48.522951, lng: 20.316844 },
    { lat: 48.522947, lng: 20.316838 },
    { lat: 48.522904, lng: 20.316732 },
    { lat: 48.522713, lng: 20.316288 },
    { lat: 48.522653, lng: 20.31602 },
    { lat: 48.522644, lng: 20.315973 },
    { lat: 48.52249, lng: 20.315215 },
    { lat: 48.522389, lng: 20.31471 },
    { lat: 48.522198, lng: 20.313752 },
    { lat: 48.522074, lng: 20.312929 },
    { lat: 48.522061, lng: 20.312815 },
    { lat: 48.52203, lng: 20.312564 },
    { lat: 48.521962, lng: 20.31234 },
    { lat: 48.521336, lng: 20.310192 },
    { lat: 48.521333, lng: 20.310182 },
    { lat: 48.521304, lng: 20.310075 },
    { lat: 48.521145, lng: 20.31037 },
    { lat: 48.520976, lng: 20.310725 },
    { lat: 48.520961, lng: 20.310571 },
    { lat: 48.520962, lng: 20.310368 },
    { lat: 48.520983, lng: 20.310125 },
    { lat: 48.521081, lng: 20.309862 },
    { lat: 48.521053, lng: 20.309588 },
    { lat: 48.520926, lng: 20.309471 },
    { lat: 48.520888, lng: 20.309124 },
    { lat: 48.520737, lng: 20.309016 },
    { lat: 48.520728, lng: 20.308852 },
    { lat: 48.520648, lng: 20.308667 },
    { lat: 48.520626, lng: 20.308486 },
    { lat: 48.520395, lng: 20.308256 },
    { lat: 48.520416, lng: 20.308033 },
    { lat: 48.520185, lng: 20.308124 },
    { lat: 48.520154, lng: 20.307969 },
    { lat: 48.52017, lng: 20.307363 },
    { lat: 48.520544, lng: 20.307181 },
    { lat: 48.520617, lng: 20.306664 },
    { lat: 48.520671, lng: 20.306395 },
    { lat: 48.520611, lng: 20.306101 },
    { lat: 48.520667, lng: 20.305819 },
    { lat: 48.520531, lng: 20.305622 },
    { lat: 48.520572, lng: 20.305373 },
    { lat: 48.520495, lng: 20.305211 },
    { lat: 48.520464, lng: 20.305133 },
    { lat: 48.520389, lng: 20.304879 },
    { lat: 48.51999, lng: 20.304475 },
    { lat: 48.519942, lng: 20.304367 },
    { lat: 48.519975, lng: 20.304298 },
    { lat: 48.51998, lng: 20.304274 },
    { lat: 48.519905, lng: 20.304014 },
    { lat: 48.519837, lng: 20.303917 },
    { lat: 48.519808, lng: 20.30371 },
    { lat: 48.51979, lng: 20.30373 },
    { lat: 48.519644, lng: 20.303631 },
    { lat: 48.519662, lng: 20.303575 },
    { lat: 48.519542, lng: 20.303463 },
    { lat: 48.519414, lng: 20.303328 },
    { lat: 48.519309, lng: 20.303217 },
    { lat: 48.519247, lng: 20.303013 },
    { lat: 48.519076, lng: 20.302878 },
    { lat: 48.518879, lng: 20.302672 },
    { lat: 48.518733, lng: 20.30264 },
    { lat: 48.518719, lng: 20.30275 },
    { lat: 48.518557, lng: 20.302718 },
    { lat: 48.51825, lng: 20.30256 },
    { lat: 48.518222, lng: 20.302564 },
    { lat: 48.518167, lng: 20.302571 },
    { lat: 48.51792, lng: 20.302603 },
    { lat: 48.517877, lng: 20.302695 },
    { lat: 48.517558, lng: 20.302797 },
    { lat: 48.517494, lng: 20.302803 },
    { lat: 48.517469, lng: 20.302956 },
    { lat: 48.517373, lng: 20.302981 },
    { lat: 48.517132, lng: 20.303122 },
    { lat: 48.516927, lng: 20.303254 },
    { lat: 48.516778, lng: 20.303252 },
    { lat: 48.516663, lng: 20.303287 },
    { lat: 48.516258, lng: 20.303393 },
    { lat: 48.516135, lng: 20.303135 },
    { lat: 48.516121, lng: 20.303112 },
    { lat: 48.515905, lng: 20.303328 },
    { lat: 48.515502, lng: 20.303288 },
    { lat: 48.515353, lng: 20.30323 },
    { lat: 48.515179, lng: 20.30323 },
    { lat: 48.515035, lng: 20.303158 },
    { lat: 48.514964, lng: 20.303097 },
    { lat: 48.514816, lng: 20.303105 },
    { lat: 48.514635, lng: 20.303059 },
    { lat: 48.514305, lng: 20.302881 },
    { lat: 48.514071, lng: 20.302738 },
    { lat: 48.513696, lng: 20.302579 },
    { lat: 48.513364, lng: 20.302322 },
    { lat: 48.513323, lng: 20.302314 },
    { lat: 48.513084, lng: 20.302211 },
    { lat: 48.512849, lng: 20.302207 },
    { lat: 48.512691, lng: 20.302424 },
    { lat: 48.51243, lng: 20.30237 },
    { lat: 48.512265, lng: 20.302215 },
    { lat: 48.51205, lng: 20.302198 },
    { lat: 48.511941, lng: 20.302273 },
    { lat: 48.511859, lng: 20.302251 },
    { lat: 48.511681, lng: 20.302282 },
    { lat: 48.511493, lng: 20.302183 },
    { lat: 48.511311, lng: 20.3022 },
    { lat: 48.511224, lng: 20.302145 },
    { lat: 48.510867, lng: 20.302059 },
    { lat: 48.510341, lng: 20.301732 },
    { lat: 48.510337, lng: 20.30173 },
    { lat: 48.510251, lng: 20.301675 },
    { lat: 48.51025, lng: 20.301674 },
    { lat: 48.510132, lng: 20.301609 },
    { lat: 48.509699, lng: 20.30145 },
    { lat: 48.509533, lng: 20.30151 },
    { lat: 48.509488, lng: 20.301436 },
    { lat: 48.509438, lng: 20.301399 },
    { lat: 48.509414, lng: 20.301385 },
    { lat: 48.509034, lng: 20.301379 },
    { lat: 48.509011, lng: 20.301326 },
    { lat: 48.508696, lng: 20.30138 },
    { lat: 48.508472, lng: 20.301244 },
    { lat: 48.508429, lng: 20.301123 },
    { lat: 48.508222, lng: 20.301153 },
    { lat: 48.508195, lng: 20.30121 },
    { lat: 48.508164, lng: 20.301269 },
    { lat: 48.507981, lng: 20.301212 },
    { lat: 48.507781, lng: 20.301186 },
    { lat: 48.507754, lng: 20.301159 },
    { lat: 48.507674, lng: 20.301108 },
    { lat: 48.507638, lng: 20.301038 },
    { lat: 48.507575, lng: 20.300986 },
    { lat: 48.507432, lng: 20.300855 },
    { lat: 48.507334, lng: 20.300843 },
    { lat: 48.507078, lng: 20.300653 },
    { lat: 48.506382, lng: 20.299909 },
    { lat: 48.506251, lng: 20.299854 },
    { lat: 48.506165, lng: 20.299906 },
    { lat: 48.505998, lng: 20.299834 },
    { lat: 48.505838, lng: 20.299342 },
    { lat: 48.505758, lng: 20.299231 },
    { lat: 48.505666, lng: 20.299087 },
    { lat: 48.505466, lng: 20.298681 },
    { lat: 48.505447, lng: 20.298623 },
    { lat: 48.505279, lng: 20.298424 },
    { lat: 48.505098, lng: 20.298345 },
    { lat: 48.504982, lng: 20.298453 },
    { lat: 48.50485, lng: 20.298334 },
    { lat: 48.504631, lng: 20.298271 },
    { lat: 48.504602, lng: 20.298157 },
    { lat: 48.504502, lng: 20.298145 },
    { lat: 48.504271, lng: 20.298361 },
    { lat: 48.504137, lng: 20.298412 },
    { lat: 48.503681, lng: 20.298441 },
    { lat: 48.503621, lng: 20.298533 },
    { lat: 48.503544, lng: 20.298548 },
    { lat: 48.503479, lng: 20.298554 },
    { lat: 48.503297, lng: 20.298747 },
    { lat: 48.502592, lng: 20.298955 },
    { lat: 48.501852, lng: 20.299223 },
    { lat: 48.501805, lng: 20.29924 },
    { lat: 48.501562, lng: 20.299331 },
    { lat: 48.50122, lng: 20.299455 },
    { lat: 48.501174, lng: 20.299559 },
    { lat: 48.500998, lng: 20.300014 },
    { lat: 48.500811, lng: 20.300508 },
    { lat: 48.500713, lng: 20.300772 },
    { lat: 48.500703, lng: 20.300787 },
    { lat: 48.500682, lng: 20.300826 },
    { lat: 48.500534, lng: 20.301083 },
    { lat: 48.500398, lng: 20.301326 },
    { lat: 48.500153, lng: 20.301754 },
    { lat: 48.500118, lng: 20.301824 },
    { lat: 48.499927, lng: 20.302191 },
    { lat: 48.499788, lng: 20.302461 },
    { lat: 48.499571, lng: 20.302887 },
    { lat: 48.499407, lng: 20.303203 },
    { lat: 48.499351, lng: 20.303289 },
    { lat: 48.499084, lng: 20.303698 },
    { lat: 48.498989, lng: 20.303845 },
    { lat: 48.498715, lng: 20.304278 },
    { lat: 48.498573, lng: 20.304499 },
    { lat: 48.498486, lng: 20.30466 },
    { lat: 48.498424, lng: 20.304762 },
    { lat: 48.498295, lng: 20.304974 },
    { lat: 48.498135, lng: 20.305232 },
    { lat: 48.497972, lng: 20.305444 },
    { lat: 48.497728, lng: 20.305762 },
    { lat: 48.497481, lng: 20.306093 },
    { lat: 48.497144, lng: 20.306538 },
    { lat: 48.496974, lng: 20.306791 },
    { lat: 48.496734, lng: 20.307146 },
    { lat: 48.496446, lng: 20.307576 },
    { lat: 48.496172, lng: 20.307987 },
    { lat: 48.495938, lng: 20.308322 },
    { lat: 48.495711, lng: 20.308607 },
    { lat: 48.495545, lng: 20.308813 },
    { lat: 48.495299, lng: 20.309106 },
    { lat: 48.495146, lng: 20.309288 },
    { lat: 48.494978, lng: 20.309485 },
    { lat: 48.494982, lng: 20.309581 },
    { lat: 48.494983, lng: 20.30961 },
    { lat: 48.4950007, lng: 20.310604 },
    { lat: 48.4947134, lng: 20.3124879 },
    { lat: 48.4941911, lng: 20.314044 },
    { lat: 48.493609, lng: 20.3161746 },
    { lat: 48.4940661, lng: 20.3164872 },
    { lat: 48.4948487, lng: 20.3168016 },
    { lat: 48.4970675, lng: 20.318066 },
    { lat: 48.4988405, lng: 20.3201492 },
    { lat: 48.4983075, lng: 20.321403 },
    { lat: 48.4981815, lng: 20.3222646 },
    { lat: 48.4978656, lng: 20.3229715 },
    { lat: 48.4978559, lng: 20.3232962 },
    { lat: 48.4976758, lng: 20.3243085 },
    { lat: 48.4977598, lng: 20.324958 },
    { lat: 48.4976506, lng: 20.3251289 },
    { lat: 48.4975768, lng: 20.325401 },
    { lat: 48.4975404, lng: 20.3253638 },
    { lat: 48.4974472, lng: 20.3255748 },
    { lat: 48.4973872, lng: 20.3259955 },
    { lat: 48.4974321, lng: 20.32625 },
    { lat: 48.4974134, lng: 20.3265791 },
    { lat: 48.497359, lng: 20.3267451 },
    { lat: 48.4973414, lng: 20.3270435 },
    { lat: 48.4974282, lng: 20.3274157 },
    { lat: 48.4974297, lng: 20.3279439 },
    { lat: 48.4975402, lng: 20.3285537 },
    { lat: 48.4975234, lng: 20.3291027 },
    { lat: 48.4975648, lng: 20.3292361 },
    { lat: 48.4975831, lng: 20.3298804 },
    { lat: 48.4977555, lng: 20.3303427 },
    { lat: 48.4977578, lng: 20.3305765 },
    { lat: 48.4976765, lng: 20.3307719 },
    { lat: 48.4976366, lng: 20.331248 },
    { lat: 48.4977378, lng: 20.3315061 },
    { lat: 48.4977441, lng: 20.3322093 },
    { lat: 48.4979878, lng: 20.3332291 },
    { lat: 48.4983707, lng: 20.3336057 },
    { lat: 48.4985256, lng: 20.33388 },
    { lat: 48.4987779, lng: 20.3348355 },
    { lat: 48.4990709, lng: 20.3356171 },
    { lat: 48.4991111, lng: 20.3363276 },
    { lat: 48.4990851, lng: 20.3364396 },
    { lat: 48.4989419, lng: 20.3366408 },
    { lat: 48.4987821, lng: 20.3370455 },
    { lat: 48.4984942, lng: 20.3374857 },
    { lat: 48.4988337, lng: 20.3385117 },
    { lat: 48.4989487, lng: 20.3387189 },
    { lat: 48.4989247, lng: 20.339658 },
    { lat: 48.4990208, lng: 20.3411721 },
    { lat: 48.4992525, lng: 20.3423746 },
    { lat: 48.4995393, lng: 20.3428549 },
    { lat: 48.499843, lng: 20.3431633 },
    { lat: 48.5006305, lng: 20.3436356 },
    { lat: 48.5008938, lng: 20.3436884 },
    { lat: 48.5011372, lng: 20.3435149 },
    { lat: 48.5013307, lng: 20.3432542 },
    { lat: 48.5014225, lng: 20.3430355 },
    { lat: 48.5014873, lng: 20.3425625 },
    { lat: 48.5014436, lng: 20.3407231 },
    { lat: 48.5014803, lng: 20.3403317 },
    { lat: 48.5014716, lng: 20.3380886 },
    { lat: 48.5015421, lng: 20.3375007 },
    { lat: 48.5018944, lng: 20.3364316 },
    { lat: 48.502086, lng: 20.3360329 },
    { lat: 48.5025227, lng: 20.3362543 },
    { lat: 48.5027259, lng: 20.3364962 },
    { lat: 48.5033032, lng: 20.3374398 },
    { lat: 48.5037756, lng: 20.3384918 },
    { lat: 48.5055984, lng: 20.340324 },
    { lat: 48.5069276, lng: 20.3448671 },
    { lat: 48.5072569, lng: 20.3463818 },
    { lat: 48.5075662, lng: 20.3468059 },
    { lat: 48.5077866, lng: 20.3476945 },
    { lat: 48.5079348, lng: 20.3491002 },
    { lat: 48.5081862, lng: 20.349745 },
    { lat: 48.5087183, lng: 20.3508527 },
    { lat: 48.5087794, lng: 20.3512415 },
    { lat: 48.5087287, lng: 20.3519343 },
    { lat: 48.5087992, lng: 20.3524711 },
    { lat: 48.5088004, lng: 20.352879 },
    { lat: 48.5088823, lng: 20.3530188 },
    { lat: 48.5090453, lng: 20.353127 },
    { lat: 48.5092098, lng: 20.3538477 },
    { lat: 48.5093123, lng: 20.3546026 },
    { lat: 48.5092733, lng: 20.3553113 },
    { lat: 48.5094173, lng: 20.3559127 },
    { lat: 48.5095104, lng: 20.3561357 },
    { lat: 48.5097785, lng: 20.3563114 },
    { lat: 48.5102278, lng: 20.3563432 },
    { lat: 48.5110503, lng: 20.3571633 },
    { lat: 48.5112349, lng: 20.3575065 },
    { lat: 48.5115422, lng: 20.3584621 },
    { lat: 48.5118019, lng: 20.3589429 },
    { lat: 48.5117446, lng: 20.3591756 },
    { lat: 48.5117741, lng: 20.3592132 },
    { lat: 48.5119485, lng: 20.3592987 },
    { lat: 48.5125014, lng: 20.3591211 },
    { lat: 48.5126732, lng: 20.3587629 },
    { lat: 48.5128789, lng: 20.3585782 },
    { lat: 48.5130969, lng: 20.358536 },
    { lat: 48.5142155, lng: 20.358949 },
    { lat: 48.5150256, lng: 20.3589928 },
    { lat: 48.5153738, lng: 20.3589106 },
    { lat: 48.5155928, lng: 20.3589841 },
    { lat: 48.5158432, lng: 20.3592519 },
    { lat: 48.5162347, lng: 20.3601988 },
    { lat: 48.5165588, lng: 20.3606889 },
    { lat: 48.5168173, lng: 20.3609649 },
    { lat: 48.5171395, lng: 20.3611671 },
    { lat: 48.5177495, lng: 20.3614218 },
    { lat: 48.5181334, lng: 20.3618224 },
    { lat: 48.5185147, lng: 20.3620464 },
    { lat: 48.518957, lng: 20.3626178 },
    { lat: 48.5190464, lng: 20.3626554 },
    { lat: 48.5191391, lng: 20.3626106 },
    { lat: 48.5203072, lng: 20.3633701 },
    { lat: 48.5208492, lng: 20.3641902 },
  ],
  Dobšiná: [
    { lat: 48.8550556, lng: 20.3542721 },
    { lat: 48.855491, lng: 20.3528625 },
    { lat: 48.8559555, lng: 20.3517732 },
    { lat: 48.8562138, lng: 20.3513494 },
    { lat: 48.856511, lng: 20.351052 },
    { lat: 48.857464, lng: 20.3503913 },
    { lat: 48.8585354, lng: 20.3497959 },
    { lat: 48.8600489, lng: 20.3496204 },
    { lat: 48.8612235, lng: 20.3497972 },
    { lat: 48.8624776, lng: 20.3502588 },
    { lat: 48.8632648, lng: 20.3501989 },
    { lat: 48.8632656, lng: 20.3500733 },
    { lat: 48.8637926, lng: 20.3501345 },
    { lat: 48.864128, lng: 20.3500103 },
    { lat: 48.864423, lng: 20.3498103 },
    { lat: 48.8651118, lng: 20.3491029 },
    { lat: 48.8661494, lng: 20.3484149 },
    { lat: 48.8666731, lng: 20.3478597 },
    { lat: 48.8672629, lng: 20.3477412 },
    { lat: 48.867638, lng: 20.347539 },
    { lat: 48.8676242, lng: 20.3473278 },
    { lat: 48.8675779, lng: 20.3473273 },
    { lat: 48.8676259, lng: 20.3470794 },
    { lat: 48.8676244, lng: 20.3468689 },
    { lat: 48.8675834, lng: 20.3468521 },
    { lat: 48.8677148, lng: 20.3463215 },
    { lat: 48.8677924, lng: 20.34635 },
    { lat: 48.8677297, lng: 20.3458037 },
    { lat: 48.8680345, lng: 20.3442514 },
    { lat: 48.8689643, lng: 20.3421768 },
    { lat: 48.8691152, lng: 20.3417145 },
    { lat: 48.8690884, lng: 20.3413395 },
    { lat: 48.8690101, lng: 20.3412678 },
    { lat: 48.8691676, lng: 20.3407112 },
    { lat: 48.8691259, lng: 20.3404201 },
    { lat: 48.869194, lng: 20.3401737 },
    { lat: 48.8691338, lng: 20.3390516 },
    { lat: 48.869142, lng: 20.3388549 },
    { lat: 48.8692013, lng: 20.3387895 },
    { lat: 48.8691791, lng: 20.338681 },
    { lat: 48.868289, lng: 20.3374493 },
    { lat: 48.8683728, lng: 20.3373159 },
    { lat: 48.868566, lng: 20.3373621 },
    { lat: 48.8687072, lng: 20.3375172 },
    { lat: 48.8689044, lng: 20.3376077 },
    { lat: 48.8690262, lng: 20.3376137 },
    { lat: 48.8690821, lng: 20.3375461 },
    { lat: 48.8691875, lng: 20.3376172 },
    { lat: 48.8693552, lng: 20.3373293 },
    { lat: 48.8694411, lng: 20.3370742 },
    { lat: 48.8695517, lng: 20.3371391 },
    { lat: 48.8696593, lng: 20.3370111 },
    { lat: 48.8697806, lng: 20.3371155 },
    { lat: 48.8698617, lng: 20.3373207 },
    { lat: 48.869861, lng: 20.335038 },
    { lat: 48.8700082, lng: 20.3343606 },
    { lat: 48.8700193, lng: 20.3339469 },
    { lat: 48.8701312, lng: 20.3333538 },
    { lat: 48.8704326, lng: 20.3324417 },
    { lat: 48.8706965, lng: 20.3325652 },
    { lat: 48.8712046, lng: 20.331906 },
    { lat: 48.8713668, lng: 20.3315904 },
    { lat: 48.871625, lng: 20.3313062 },
    { lat: 48.8719807, lng: 20.3306353 },
    { lat: 48.8721842, lng: 20.3301085 },
    { lat: 48.872296, lng: 20.3292421 },
    { lat: 48.872285, lng: 20.3288439 },
    { lat: 48.8724565, lng: 20.3284784 },
    { lat: 48.8726093, lng: 20.3275701 },
    { lat: 48.8727827, lng: 20.3274781 },
    { lat: 48.8732278, lng: 20.3283545 },
    { lat: 48.8735094, lng: 20.3283569 },
    { lat: 48.8735925, lng: 20.3285598 },
    { lat: 48.8736464, lng: 20.329077 },
    { lat: 48.8739783, lng: 20.3301989 },
    { lat: 48.8743245, lng: 20.3306452 },
    { lat: 48.8745463, lng: 20.3307359 },
    { lat: 48.8746319, lng: 20.3307047 },
    { lat: 48.8747791, lng: 20.3303796 },
    { lat: 48.8747713, lng: 20.3297103 },
    { lat: 48.874709, lng: 20.3296146 },
    { lat: 48.8746107, lng: 20.3296101 },
    { lat: 48.8744548, lng: 20.3292788 },
    { lat: 48.874422, lng: 20.3290954 },
    { lat: 48.8744864, lng: 20.3288707 },
    { lat: 48.8748243, lng: 20.3285247 },
    { lat: 48.8750899, lng: 20.3285504 },
    { lat: 48.8756505, lng: 20.3284291 },
    { lat: 48.8758937, lng: 20.3282558 },
    { lat: 48.8758989, lng: 20.3278071 },
    { lat: 48.8758451, lng: 20.3276419 },
    { lat: 48.8755768, lng: 20.3273128 },
    { lat: 48.8751348, lng: 20.3270092 },
    { lat: 48.8750006, lng: 20.3265668 },
    { lat: 48.8751667, lng: 20.3262322 },
    { lat: 48.8751848, lng: 20.3260667 },
    { lat: 48.8751319, lng: 20.3259212 },
    { lat: 48.8750087, lng: 20.3259369 },
    { lat: 48.8748472, lng: 20.3257527 },
    { lat: 48.8747508, lng: 20.325532 },
    { lat: 48.8748127, lng: 20.3252657 },
    { lat: 48.8750202, lng: 20.3249886 },
    { lat: 48.8752029, lng: 20.3244975 },
    { lat: 48.8753828, lng: 20.3242038 },
    { lat: 48.8755376, lng: 20.3236387 },
    { lat: 48.8755108, lng: 20.3233567 },
    { lat: 48.8753055, lng: 20.3231987 },
    { lat: 48.8750403, lng: 20.3232703 },
    { lat: 48.8748209, lng: 20.3234477 },
    { lat: 48.874533, lng: 20.3235453 },
    { lat: 48.8742364, lng: 20.3235328 },
    { lat: 48.8741373, lng: 20.3234497 },
    { lat: 48.8740727, lng: 20.323238 },
    { lat: 48.8739661, lng: 20.3231306 },
    { lat: 48.8740384, lng: 20.3224401 },
    { lat: 48.8745436, lng: 20.3217675 },
    { lat: 48.8748689, lng: 20.3212122 },
    { lat: 48.8750176, lng: 20.3210963 },
    { lat: 48.8751471, lng: 20.3210364 },
    { lat: 48.8752257, lng: 20.3211202 },
    { lat: 48.8753745, lng: 20.3210644 },
    { lat: 48.8755008, lng: 20.3211572 },
    { lat: 48.8759019, lng: 20.3212226 },
    { lat: 48.8765493, lng: 20.3209576 },
    { lat: 48.8768805, lng: 20.320587 },
    { lat: 48.8774426, lng: 20.3201858 },
    { lat: 48.877675, lng: 20.3200509 },
    { lat: 48.878109, lng: 20.3199431 },
    { lat: 48.8783922, lng: 20.3197557 },
    { lat: 48.8788001, lng: 20.3193901 },
    { lat: 48.8790807, lng: 20.3189854 },
    { lat: 48.8796318, lng: 20.3183945 },
    { lat: 48.8801038, lng: 20.3183641 },
    { lat: 48.8805838, lng: 20.3181709 },
    { lat: 48.8811971, lng: 20.3180759 },
    { lat: 48.8815504, lng: 20.3180937 },
    { lat: 48.882273, lng: 20.3183196 },
    { lat: 48.8825849, lng: 20.31835 },
    { lat: 48.8833496, lng: 20.3180326 },
    { lat: 48.8838523, lng: 20.3175801 },
    { lat: 48.8838804, lng: 20.3178109 },
    { lat: 48.8839885, lng: 20.3178645 },
    { lat: 48.8841989, lng: 20.3176031 },
    { lat: 48.8842406, lng: 20.3176316 },
    { lat: 48.8843971, lng: 20.317171 },
    { lat: 48.8845905, lng: 20.3168113 },
    { lat: 48.8847401, lng: 20.3167514 },
    { lat: 48.88519, lng: 20.3167373 },
    { lat: 48.8852451, lng: 20.316649 },
    { lat: 48.8852526, lng: 20.316434 },
    { lat: 48.8851538, lng: 20.3161813 },
    { lat: 48.8848366, lng: 20.3162769 },
    { lat: 48.8846445, lng: 20.3153062 },
    { lat: 48.8852901, lng: 20.3149446 },
    { lat: 48.8854849, lng: 20.3144188 },
    { lat: 48.8855561, lng: 20.3138478 },
    { lat: 48.8854841, lng: 20.313417 },
    { lat: 48.8852433, lng: 20.3129308 },
    { lat: 48.8848037, lng: 20.3122563 },
    { lat: 48.884228, lng: 20.311412 },
    { lat: 48.8838368, lng: 20.3109346 },
    { lat: 48.8832938, lng: 20.3105274 },
    { lat: 48.8829475, lng: 20.3103982 },
    { lat: 48.8823924, lng: 20.310454 },
    { lat: 48.8822806, lng: 20.3105903 },
    { lat: 48.882218, lng: 20.3108049 },
    { lat: 48.8812991, lng: 20.3112343 },
    { lat: 48.8812304, lng: 20.311264 },
    { lat: 48.8808339, lng: 20.3111468 },
    { lat: 48.8802627, lng: 20.3117722 },
    { lat: 48.8800837, lng: 20.3118898 },
    { lat: 48.8801026, lng: 20.3119464 },
    { lat: 48.8799919, lng: 20.3119646 },
    { lat: 48.8800461, lng: 20.3121136 },
    { lat: 48.8791114, lng: 20.3121672 },
    { lat: 48.8785803, lng: 20.3120066 },
    { lat: 48.8781792, lng: 20.3117413 },
    { lat: 48.8777669, lng: 20.3113639 },
    { lat: 48.8774725, lng: 20.3110021 },
    { lat: 48.8773795, lng: 20.3111593 },
    { lat: 48.877233, lng: 20.3106637 },
    { lat: 48.8765676, lng: 20.3102883 },
    { lat: 48.8760866, lng: 20.3094223 },
    { lat: 48.875684, lng: 20.308368 },
    { lat: 48.8755193, lng: 20.3083012 },
    { lat: 48.8750891, lng: 20.3085297 },
    { lat: 48.8750358, lng: 20.3082032 },
    { lat: 48.8752824, lng: 20.3080284 },
    { lat: 48.875289, lng: 20.3077377 },
    { lat: 48.8751614, lng: 20.3065485 },
    { lat: 48.8752497, lng: 20.306356 },
    { lat: 48.8753471, lng: 20.3063419 },
    { lat: 48.8748743, lng: 20.3053655 },
    { lat: 48.8744461, lng: 20.3043146 },
    { lat: 48.8739452, lng: 20.3044697 },
    { lat: 48.8736078, lng: 20.3018817 },
    { lat: 48.873487, lng: 20.3017627 },
    { lat: 48.8730131, lng: 20.3015913 },
    { lat: 48.8728457, lng: 20.3016006 },
    { lat: 48.8726023, lng: 20.3013685 },
    { lat: 48.87269, lng: 20.3011317 },
    { lat: 48.8728492, lng: 20.3009968 },
    { lat: 48.8729683, lng: 20.3011396 },
    { lat: 48.873065, lng: 20.301103 },
    { lat: 48.8730775, lng: 20.3006433 },
    { lat: 48.8729578, lng: 20.3004065 },
    { lat: 48.8727436, lng: 20.3002268 },
    { lat: 48.8727351, lng: 20.3001228 },
    { lat: 48.8726593, lng: 20.3000616 },
    { lat: 48.8726444, lng: 20.2998194 },
    { lat: 48.8728481, lng: 20.2995861 },
    { lat: 48.8730549, lng: 20.2995754 },
    { lat: 48.8733032, lng: 20.2999391 },
    { lat: 48.873412, lng: 20.2993222 },
    { lat: 48.8733404, lng: 20.2989899 },
    { lat: 48.8732456, lng: 20.2988392 },
    { lat: 48.8726878, lng: 20.2988296 },
    { lat: 48.872469, lng: 20.2989043 },
    { lat: 48.8722842, lng: 20.2988136 },
    { lat: 48.8723572, lng: 20.2984053 },
    { lat: 48.8722352, lng: 20.2982242 },
    { lat: 48.8716304, lng: 20.2979959 },
    { lat: 48.8712784, lng: 20.2980644 },
    { lat: 48.8712708, lng: 20.2977245 },
    { lat: 48.8700632, lng: 20.2981746 },
    { lat: 48.8696373, lng: 20.2984582 },
    { lat: 48.8695169, lng: 20.2987162 },
    { lat: 48.8694467, lng: 20.298769 },
    { lat: 48.8690008, lng: 20.298421 },
    { lat: 48.8686571, lng: 20.298303 },
    { lat: 48.8685814, lng: 20.2984658 },
    { lat: 48.8686138, lng: 20.2987222 },
    { lat: 48.8686779, lng: 20.2988585 },
    { lat: 48.8685353, lng: 20.2993348 },
    { lat: 48.8684754, lng: 20.3007649 },
    { lat: 48.8683324, lng: 20.3009723 },
    { lat: 48.8680043, lng: 20.299718 },
    { lat: 48.8682659, lng: 20.2985442 },
    { lat: 48.8683596, lng: 20.2977789 },
    { lat: 48.8682285, lng: 20.297419 },
    { lat: 48.8682611, lng: 20.2973288 },
    { lat: 48.8687325, lng: 20.2973226 },
    { lat: 48.869094, lng: 20.2970612 },
    { lat: 48.8698143, lng: 20.296015 },
    { lat: 48.8703088, lng: 20.2955295 },
    { lat: 48.8710335, lng: 20.2945654 },
    { lat: 48.8711071, lng: 20.2945151 },
    { lat: 48.8713575, lng: 20.2947884 },
    { lat: 48.8717947, lng: 20.2944476 },
    { lat: 48.8718777, lng: 20.2945253 },
    { lat: 48.872325, lng: 20.294162 },
    { lat: 48.8723929, lng: 20.2939957 },
    { lat: 48.8720102, lng: 20.2934259 },
    { lat: 48.8721884, lng: 20.292249 },
    { lat: 48.872907, lng: 20.2914808 },
    { lat: 48.8734028, lng: 20.2917166 },
    { lat: 48.8731205, lng: 20.2905436 },
    { lat: 48.8727879, lng: 20.29039 },
    { lat: 48.8723698, lng: 20.2898678 },
    { lat: 48.8720643, lng: 20.2889058 },
    { lat: 48.8715847, lng: 20.2885065 },
    { lat: 48.8708533, lng: 20.2873171 },
    { lat: 48.8705367, lng: 20.2865973 },
    { lat: 48.8707296, lng: 20.2860262 },
    { lat: 48.8704204, lng: 20.284146 },
    { lat: 48.870112, lng: 20.2836605 },
    { lat: 48.8698283, lng: 20.2835447 },
    { lat: 48.8689603, lng: 20.280943 },
    { lat: 48.8685385, lng: 20.2801773 },
    { lat: 48.8682191, lng: 20.2797887 },
    { lat: 48.8680999, lng: 20.2793319 },
    { lat: 48.8677665, lng: 20.2788712 },
    { lat: 48.8669308, lng: 20.2771478 },
    { lat: 48.8672828, lng: 20.2764811 },
    { lat: 48.8683308, lng: 20.2783013 },
    { lat: 48.8690209, lng: 20.2787012 },
    { lat: 48.8698172, lng: 20.2789313 },
    { lat: 48.8697881, lng: 20.27962 },
    { lat: 48.8698541, lng: 20.2807315 },
    { lat: 48.870025, lng: 20.2819899 },
    { lat: 48.8701573, lng: 20.2825775 },
    { lat: 48.87047, lng: 20.2835177 },
    { lat: 48.870912, lng: 20.2843882 },
    { lat: 48.8712152, lng: 20.2848585 },
    { lat: 48.8718833, lng: 20.2854673 },
    { lat: 48.8733154, lng: 20.2862923 },
    { lat: 48.8745804, lng: 20.2871919 },
    { lat: 48.8751678, lng: 20.2881582 },
    { lat: 48.8754703, lng: 20.2889817 },
    { lat: 48.875528, lng: 20.2890165 },
    { lat: 48.8756057, lng: 20.2888425 },
    { lat: 48.87566, lng: 20.288838 },
    { lat: 48.875684, lng: 20.288631 },
    { lat: 48.875652, lng: 20.288574 },
    { lat: 48.875643, lng: 20.288382 },
    { lat: 48.875629, lng: 20.288265 },
    { lat: 48.875623, lng: 20.288194 },
    { lat: 48.875565, lng: 20.287949 },
    { lat: 48.875536, lng: 20.287862 },
    { lat: 48.875446, lng: 20.287642 },
    { lat: 48.875238, lng: 20.287151 },
    { lat: 48.874481, lng: 20.285444 },
    { lat: 48.874178, lng: 20.284951 },
    { lat: 48.874152, lng: 20.284909 },
    { lat: 48.874143, lng: 20.284863 },
    { lat: 48.874138, lng: 20.284836 },
    { lat: 48.873956, lng: 20.283974 },
    { lat: 48.873806, lng: 20.283491 },
    { lat: 48.873734, lng: 20.283361 },
    { lat: 48.873704, lng: 20.283356 },
    { lat: 48.873448, lng: 20.283479 },
    { lat: 48.873319, lng: 20.283491 },
    { lat: 48.873272, lng: 20.283355 },
    { lat: 48.873351, lng: 20.282897 },
    { lat: 48.87336, lng: 20.282454 },
    { lat: 48.873329, lng: 20.28235 },
    { lat: 48.873177, lng: 20.282354 },
    { lat: 48.873049, lng: 20.28255 },
    { lat: 48.872979, lng: 20.282542 },
    { lat: 48.8729, lng: 20.282309 },
    { lat: 48.872916, lng: 20.282046 },
    { lat: 48.872851, lng: 20.281692 },
    { lat: 48.872812, lng: 20.281079 },
    { lat: 48.872747, lng: 20.280828 },
    { lat: 48.872475, lng: 20.28078 },
    { lat: 48.872306, lng: 20.280605 },
    { lat: 48.872293, lng: 20.280574 },
    { lat: 48.872292, lng: 20.280576 },
    { lat: 48.872281, lng: 20.280459 },
    { lat: 48.872365, lng: 20.280334 },
    { lat: 48.872411, lng: 20.280203 },
    { lat: 48.872407, lng: 20.28016 },
    { lat: 48.872363, lng: 20.280035 },
    { lat: 48.87215, lng: 20.2799 },
    { lat: 48.872128, lng: 20.279676 },
    { lat: 48.87219, lng: 20.279511 },
    { lat: 48.872363, lng: 20.279415 },
    { lat: 48.872468, lng: 20.279256 },
    { lat: 48.872535, lng: 20.278409 },
    { lat: 48.872448, lng: 20.278223 },
    { lat: 48.872282, lng: 20.278199 },
    { lat: 48.87202, lng: 20.278305 },
    { lat: 48.87189, lng: 20.278298 },
    { lat: 48.871792, lng: 20.278176 },
    { lat: 48.871727, lng: 20.277735 },
    { lat: 48.871589, lng: 20.277425 },
    { lat: 48.871463, lng: 20.277316 },
    { lat: 48.871303, lng: 20.277318 },
    { lat: 48.871102, lng: 20.277183 },
    { lat: 48.871015, lng: 20.276908 },
    { lat: 48.871008, lng: 20.276604 },
    { lat: 48.87108, lng: 20.276385 },
    { lat: 48.871347, lng: 20.27602 },
    { lat: 48.871425, lng: 20.275593 },
    { lat: 48.871522, lng: 20.275444 },
    { lat: 48.871697, lng: 20.275307 },
    { lat: 48.871848, lng: 20.27507 },
    { lat: 48.871862, lng: 20.275021 },
    { lat: 48.871819, lng: 20.274643 },
    { lat: 48.871725, lng: 20.274549 },
    { lat: 48.871701, lng: 20.274379 },
    { lat: 48.871764, lng: 20.273992 },
    { lat: 48.871731, lng: 20.273486 },
    { lat: 48.87174, lng: 20.273406 },
    { lat: 48.871807, lng: 20.272939 },
    { lat: 48.871869, lng: 20.272855 },
    { lat: 48.871974, lng: 20.27272 },
    { lat: 48.872, lng: 20.272693 },
    { lat: 48.872001, lng: 20.272638 },
    { lat: 48.871987, lng: 20.272599 },
    { lat: 48.871907, lng: 20.272554 },
    { lat: 48.871885, lng: 20.272439 },
    { lat: 48.871886, lng: 20.272011 },
    { lat: 48.87196, lng: 20.27156 },
    { lat: 48.872067, lng: 20.2712 },
    { lat: 48.872271, lng: 20.270957 },
    { lat: 48.872399, lng: 20.270888 },
    { lat: 48.87262, lng: 20.270794 },
    { lat: 48.872723, lng: 20.270678 },
    { lat: 48.872734, lng: 20.270633 },
    { lat: 48.872702, lng: 20.270442 },
    { lat: 48.872782, lng: 20.27019 },
    { lat: 48.872867, lng: 20.270085 },
    { lat: 48.872891, lng: 20.270041 },
    { lat: 48.873097, lng: 20.269496 },
    { lat: 48.873098, lng: 20.269257 },
    { lat: 48.873221, lng: 20.268921 },
    { lat: 48.873319, lng: 20.268774 },
    { lat: 48.873645, lng: 20.268598 },
    { lat: 48.873681, lng: 20.268526 },
    { lat: 48.873685, lng: 20.268474 },
    { lat: 48.873747, lng: 20.268067 },
    { lat: 48.874075, lng: 20.267459 },
    { lat: 48.874057, lng: 20.26744 },
    { lat: 48.873878, lng: 20.267238 },
    { lat: 48.873786, lng: 20.266889 },
    { lat: 48.873629, lng: 20.266516 },
    { lat: 48.87343, lng: 20.266388 },
    { lat: 48.873277, lng: 20.266459 },
    { lat: 48.873224, lng: 20.266179 },
    { lat: 48.873158, lng: 20.266037 },
    { lat: 48.87312, lng: 20.265976 },
    { lat: 48.873014, lng: 20.265808 },
    { lat: 48.872914, lng: 20.265648 },
    { lat: 48.87283, lng: 20.2655 },
    { lat: 48.872749, lng: 20.265315 },
    { lat: 48.872707, lng: 20.265202 },
    { lat: 48.872675, lng: 20.265115 },
    { lat: 48.872579, lng: 20.264971 },
    { lat: 48.872353, lng: 20.264651 },
    { lat: 48.872277, lng: 20.264545 },
    { lat: 48.872215, lng: 20.264373 },
    { lat: 48.872055, lng: 20.264211 },
    { lat: 48.871971, lng: 20.264117 },
    { lat: 48.871845, lng: 20.264 },
    { lat: 48.871747, lng: 20.263912 },
    { lat: 48.871694, lng: 20.263602 },
    { lat: 48.871645, lng: 20.263373 },
    { lat: 48.871492, lng: 20.26326 },
    { lat: 48.871385, lng: 20.262982 },
    { lat: 48.871306, lng: 20.262782 },
    { lat: 48.871293, lng: 20.262507 },
    { lat: 48.871199, lng: 20.262151 },
    { lat: 48.871083, lng: 20.261915 },
    { lat: 48.871062, lng: 20.261759 },
    { lat: 48.870967, lng: 20.261617 },
    { lat: 48.87089, lng: 20.261453 },
    { lat: 48.870883, lng: 20.261332 },
    { lat: 48.870722, lng: 20.261136 },
    { lat: 48.870543, lng: 20.261123 },
    { lat: 48.870564, lng: 20.260939 },
    { lat: 48.870477, lng: 20.260332 },
    { lat: 48.870407, lng: 20.260275 },
    { lat: 48.87042, lng: 20.260006 },
    { lat: 48.870319, lng: 20.259817 },
    { lat: 48.870204, lng: 20.259557 },
    { lat: 48.870133, lng: 20.259398 },
    { lat: 48.870003, lng: 20.259206 },
    { lat: 48.870025, lng: 20.258833 },
    { lat: 48.870083, lng: 20.258641 },
    { lat: 48.870086, lng: 20.258581 },
    { lat: 48.870088, lng: 20.258537 },
    { lat: 48.870076, lng: 20.258459 },
    { lat: 48.870044, lng: 20.258354 },
    { lat: 48.869933, lng: 20.258022 },
    { lat: 48.870001, lng: 20.257893 },
    { lat: 48.870074, lng: 20.257803 },
    { lat: 48.870106, lng: 20.257763 },
    { lat: 48.870142, lng: 20.25769 },
    { lat: 48.870076, lng: 20.257442 },
    { lat: 48.870115, lng: 20.257286 },
    { lat: 48.870074, lng: 20.256889 },
    { lat: 48.870053, lng: 20.256718 },
    { lat: 48.869901, lng: 20.256128 },
    { lat: 48.870001, lng: 20.256058 },
    { lat: 48.870136, lng: 20.25557 },
    { lat: 48.870122, lng: 20.255178 },
    { lat: 48.8702, lng: 20.254883 },
    { lat: 48.870085, lng: 20.254732 },
    { lat: 48.870082, lng: 20.254706 },
    { lat: 48.870084, lng: 20.25468 },
    { lat: 48.87014, lng: 20.254551 },
    { lat: 48.870217, lng: 20.254382 },
    { lat: 48.870247, lng: 20.254181 },
    { lat: 48.870324, lng: 20.25405 },
    { lat: 48.870349, lng: 20.253811 },
    { lat: 48.870306, lng: 20.253536 },
    { lat: 48.870319, lng: 20.253234 },
    { lat: 48.870246, lng: 20.25303 },
    { lat: 48.870211, lng: 20.252704 },
    { lat: 48.870182, lng: 20.252606 },
    { lat: 48.870263, lng: 20.252506 },
    { lat: 48.870321, lng: 20.252465 },
    { lat: 48.870373, lng: 20.252353 },
    { lat: 48.870424, lng: 20.252181 },
    { lat: 48.870469, lng: 20.252012 },
    { lat: 48.870452, lng: 20.251807 },
    { lat: 48.870423, lng: 20.25157 },
    { lat: 48.870447, lng: 20.251514 },
    { lat: 48.870582, lng: 20.25131 },
    { lat: 48.870634, lng: 20.251197 },
    { lat: 48.870678, lng: 20.250969 },
    { lat: 48.870632, lng: 20.250729 },
    { lat: 48.870659, lng: 20.250407 },
    { lat: 48.870653, lng: 20.250257 },
    { lat: 48.87068, lng: 20.250206 },
    { lat: 48.870647, lng: 20.250083 },
    { lat: 48.870608, lng: 20.250036 },
    { lat: 48.870467, lng: 20.249532 },
    { lat: 48.870499, lng: 20.248897 },
    { lat: 48.870506, lng: 20.248579 },
    { lat: 48.870518, lng: 20.248263 },
    { lat: 48.870508, lng: 20.248261 },
    { lat: 48.870441, lng: 20.24824 },
    { lat: 48.870121, lng: 20.248137 },
    { lat: 48.870037, lng: 20.248111 },
    { lat: 48.869871, lng: 20.248058 },
    { lat: 48.869519, lng: 20.247925 },
    { lat: 48.869298, lng: 20.247849 },
    { lat: 48.868718, lng: 20.247905 },
    { lat: 48.868012, lng: 20.247141 },
    { lat: 48.867532, lng: 20.246948 },
    { lat: 48.867163, lng: 20.246481 },
    { lat: 48.866784, lng: 20.246123 },
    { lat: 48.866586, lng: 20.24571 },
    { lat: 48.866389, lng: 20.245503 },
    { lat: 48.865945, lng: 20.245028 },
    { lat: 48.865893, lng: 20.244985 },
    { lat: 48.865834, lng: 20.244958 },
    { lat: 48.865676, lng: 20.244759 },
    { lat: 48.865439, lng: 20.244724 },
    { lat: 48.865335, lng: 20.244758 },
    { lat: 48.865067, lng: 20.24461 },
    { lat: 48.864927, lng: 20.244524 },
    { lat: 48.86474, lng: 20.244606 },
    { lat: 48.864557, lng: 20.244477 },
    { lat: 48.864165, lng: 20.244621 },
    { lat: 48.863848, lng: 20.244756 },
    { lat: 48.863747, lng: 20.244808 },
    { lat: 48.863508, lng: 20.244948 },
    { lat: 48.863353, lng: 20.24498 },
    { lat: 48.863308, lng: 20.245075 },
    { lat: 48.863218, lng: 20.245134 },
    { lat: 48.863111, lng: 20.24521 },
    { lat: 48.862917, lng: 20.245252 },
    { lat: 48.86258, lng: 20.245278 },
    { lat: 48.862407, lng: 20.2451 },
    { lat: 48.862303, lng: 20.244896 },
    { lat: 48.862173, lng: 20.244749 },
    { lat: 48.86197, lng: 20.244712 },
    { lat: 48.861862, lng: 20.244601 },
    { lat: 48.86176, lng: 20.24439 },
    { lat: 48.861662, lng: 20.244246 },
    { lat: 48.861392, lng: 20.243955 },
    { lat: 48.861368, lng: 20.243777 },
    { lat: 48.861166, lng: 20.243452 },
    { lat: 48.860994, lng: 20.243254 },
    { lat: 48.860853, lng: 20.243179 },
    { lat: 48.860707, lng: 20.24307 },
    { lat: 48.860617, lng: 20.243004 },
    { lat: 48.860377, lng: 20.242975 },
    { lat: 48.86014, lng: 20.243047 },
    { lat: 48.859991, lng: 20.243097 },
    { lat: 48.859877, lng: 20.243193 },
    { lat: 48.859565, lng: 20.243275 },
    { lat: 48.859308, lng: 20.243318 },
    { lat: 48.859038, lng: 20.24315 },
    { lat: 48.858931, lng: 20.243152 },
    { lat: 48.858776, lng: 20.243158 },
    { lat: 48.858686, lng: 20.243132 },
    { lat: 48.858608, lng: 20.243115 },
    { lat: 48.858504, lng: 20.243043 },
    { lat: 48.858363, lng: 20.242986 },
    { lat: 48.85809, lng: 20.242814 },
    { lat: 48.858017, lng: 20.242793 },
    { lat: 48.857823, lng: 20.242747 },
    { lat: 48.857662, lng: 20.242714 },
    { lat: 48.85747, lng: 20.242523 },
    { lat: 48.857421, lng: 20.242521 },
    { lat: 48.857371, lng: 20.242557 },
    { lat: 48.857192, lng: 20.242505 },
    { lat: 48.857167, lng: 20.242501 },
    { lat: 48.857129, lng: 20.24245 },
    { lat: 48.85711, lng: 20.242286 },
    { lat: 48.857088, lng: 20.242267 },
    { lat: 48.857043, lng: 20.242287 },
    { lat: 48.856934, lng: 20.242292 },
    { lat: 48.856439, lng: 20.242105 },
    { lat: 48.856168, lng: 20.242242 },
    { lat: 48.856154, lng: 20.242287 },
    { lat: 48.85614, lng: 20.242311 },
    { lat: 48.855977, lng: 20.242402 },
    { lat: 48.855826, lng: 20.242574 },
    { lat: 48.855497, lng: 20.242682 },
    { lat: 48.855185, lng: 20.242726 },
    { lat: 48.855046, lng: 20.242855 },
    { lat: 48.854821, lng: 20.242864 },
    { lat: 48.854665, lng: 20.242778 },
    { lat: 48.854639, lng: 20.242745 },
    { lat: 48.854369, lng: 20.242573 },
    { lat: 48.854263, lng: 20.242542 },
    { lat: 48.85415, lng: 20.242477 },
    { lat: 48.854047, lng: 20.242464 },
    { lat: 48.853891, lng: 20.2422 },
    { lat: 48.853711, lng: 20.242118 },
    { lat: 48.853433, lng: 20.242118 },
    { lat: 48.853382, lng: 20.242043 },
    { lat: 48.853325, lng: 20.242008 },
    { lat: 48.853176, lng: 20.241917 },
    { lat: 48.853125, lng: 20.241836 },
    { lat: 48.852969, lng: 20.241872 },
    { lat: 48.852741, lng: 20.241985 },
    { lat: 48.852659, lng: 20.241955 },
    { lat: 48.852522, lng: 20.242041 },
    { lat: 48.852421, lng: 20.242148 },
    { lat: 48.85237, lng: 20.242141 },
    { lat: 48.852206, lng: 20.242066 },
    { lat: 48.852141, lng: 20.242061 },
    { lat: 48.852064, lng: 20.24205 },
    { lat: 48.852012, lng: 20.242048 },
    { lat: 48.85182, lng: 20.242083 },
    { lat: 48.851721, lng: 20.242177 },
    { lat: 48.851693, lng: 20.242176 },
    { lat: 48.851646, lng: 20.242093 },
    { lat: 48.851587, lng: 20.242025 },
    { lat: 48.85151, lng: 20.242051 },
    { lat: 48.851273, lng: 20.241972 },
    { lat: 48.85095, lng: 20.24186 },
    { lat: 48.850786, lng: 20.241807 },
    { lat: 48.850465, lng: 20.241735 },
    { lat: 48.850404, lng: 20.241648 },
    { lat: 48.850384, lng: 20.241568 },
    { lat: 48.850364, lng: 20.241499 },
    { lat: 48.850319, lng: 20.241433 },
    { lat: 48.850221, lng: 20.241448 },
    { lat: 48.849991, lng: 20.241318 },
    { lat: 48.849874, lng: 20.24125 },
    { lat: 48.849727, lng: 20.241109 },
    { lat: 48.849637, lng: 20.241034 },
    { lat: 48.849589, lng: 20.241008 },
    { lat: 48.849521, lng: 20.240972 },
    { lat: 48.849453, lng: 20.240937 },
    { lat: 48.849279, lng: 20.24087 },
    { lat: 48.849137, lng: 20.240817 },
    { lat: 48.84895, lng: 20.240764 },
    { lat: 48.848828, lng: 20.24073 },
    { lat: 48.848645, lng: 20.240743 },
    { lat: 48.848515, lng: 20.240772 },
    { lat: 48.848451, lng: 20.240787 },
    { lat: 48.848309, lng: 20.240836 },
    { lat: 48.848153, lng: 20.240796 },
    { lat: 48.847673, lng: 20.240832 },
    { lat: 48.847337, lng: 20.240944 },
    { lat: 48.847164, lng: 20.240956 },
    { lat: 48.847117, lng: 20.241008 },
    { lat: 48.846938, lng: 20.241011 },
    { lat: 48.846782, lng: 20.240952 },
    { lat: 48.846677, lng: 20.240976 },
    { lat: 48.846547, lng: 20.241152 },
    { lat: 48.846279, lng: 20.241265 },
    { lat: 48.846096, lng: 20.241345 },
    { lat: 48.84588, lng: 20.241434 },
    { lat: 48.845675, lng: 20.241523 },
    { lat: 48.845428, lng: 20.241404 },
    { lat: 48.845251, lng: 20.241274 },
    { lat: 48.845226, lng: 20.241256 },
    { lat: 48.845195, lng: 20.241255 },
    { lat: 48.84514, lng: 20.24124 },
    { lat: 48.844791, lng: 20.241258 },
    { lat: 48.844495, lng: 20.241007 },
    { lat: 48.844377, lng: 20.24062 },
    { lat: 48.844296, lng: 20.240524 },
    { lat: 48.844174, lng: 20.240195 },
    { lat: 48.844125, lng: 20.2400484 },
    { lat: 48.8440853, lng: 20.2399065 },
    { lat: 48.844036, lng: 20.239694 },
    { lat: 48.843946, lng: 20.239526 },
    { lat: 48.843923, lng: 20.239434 },
    { lat: 48.843898, lng: 20.238989 },
    { lat: 48.843837, lng: 20.238824 },
    { lat: 48.843801, lng: 20.238625 },
    { lat: 48.843811, lng: 20.238421 },
    { lat: 48.843806, lng: 20.238241 },
    { lat: 48.843704, lng: 20.237869 },
    { lat: 48.843519, lng: 20.237462 },
    { lat: 48.843517, lng: 20.237125 },
    { lat: 48.843516, lng: 20.236761 },
    { lat: 48.843514, lng: 20.236496 },
    { lat: 48.843321, lng: 20.235651 },
    { lat: 48.843267, lng: 20.235379 },
    { lat: 48.843219, lng: 20.235046 },
    { lat: 48.843143, lng: 20.234537 },
    { lat: 48.842768, lng: 20.233813 },
    { lat: 48.842605, lng: 20.233678 },
    { lat: 48.842446, lng: 20.233552 },
    { lat: 48.842222, lng: 20.233371 },
    { lat: 48.842061, lng: 20.233241 },
    { lat: 48.841908, lng: 20.233118 },
    { lat: 48.841706, lng: 20.232956 },
    { lat: 48.841522, lng: 20.23281 },
    { lat: 48.841348, lng: 20.232671 },
    { lat: 48.841237, lng: 20.232584 },
    { lat: 48.84096, lng: 20.232362 },
    { lat: 48.840769, lng: 20.232304 },
    { lat: 48.839169, lng: 20.231829 },
    { lat: 48.838282, lng: 20.231211 },
    { lat: 48.837902, lng: 20.230933 },
    { lat: 48.837611, lng: 20.230729 },
    { lat: 48.8373407, lng: 20.2314691 },
    { lat: 48.8368616, lng: 20.2332148 },
    { lat: 48.8364166, lng: 20.2338905 },
    { lat: 48.8356819, lng: 20.2355307 },
    { lat: 48.8351127, lng: 20.2362796 },
    { lat: 48.8329071, lng: 20.2395644 },
    { lat: 48.8312543, lng: 20.2419048 },
    { lat: 48.830132, lng: 20.2447008 },
    { lat: 48.8307234, lng: 20.2486919 },
    { lat: 48.8313825, lng: 20.2551394 },
    { lat: 48.8311729, lng: 20.2562743 },
    { lat: 48.8315427, lng: 20.257483 },
    { lat: 48.8312735, lng: 20.2603354 },
    { lat: 48.831008, lng: 20.2612266 },
    { lat: 48.8307128, lng: 20.262652 },
    { lat: 48.830475, lng: 20.2633348 },
    { lat: 48.8301516, lng: 20.2645593 },
    { lat: 48.8299815, lng: 20.2657817 },
    { lat: 48.8299667, lng: 20.2662384 },
    { lat: 48.8296732, lng: 20.2677315 },
    { lat: 48.8287287, lng: 20.2696282 },
    { lat: 48.82805, lng: 20.2705266 },
    { lat: 48.8270258, lng: 20.2721445 },
    { lat: 48.8258467, lng: 20.2729461 },
    { lat: 48.8250375, lng: 20.2732142 },
    { lat: 48.8246166, lng: 20.2731636 },
    { lat: 48.824096, lng: 20.2733535 },
    { lat: 48.8237721, lng: 20.2729671 },
    { lat: 48.823022, lng: 20.274024 },
    { lat: 48.8226041, lng: 20.2752797 },
    { lat: 48.8221773, lng: 20.2759965 },
    { lat: 48.8217354, lng: 20.2770119 },
    { lat: 48.8208902, lng: 20.277656 },
    { lat: 48.8206727, lng: 20.2780548 },
    { lat: 48.8206423, lng: 20.278229 },
    { lat: 48.8205796, lng: 20.2783208 },
    { lat: 48.8206241, lng: 20.2785641 },
    { lat: 48.8200586, lng: 20.2801113 },
    { lat: 48.820027, lng: 20.2803961 },
    { lat: 48.8196823, lng: 20.2809918 },
    { lat: 48.8189827, lng: 20.283855 },
    { lat: 48.8184043, lng: 20.2839489 },
    { lat: 48.816543, lng: 20.2856176 },
    { lat: 48.8150684, lng: 20.2884523 },
    { lat: 48.8133707, lng: 20.2903268 },
    { lat: 48.812963, lng: 20.2905568 },
    { lat: 48.8126905, lng: 20.2908717 },
    { lat: 48.8126182, lng: 20.2910687 },
    { lat: 48.8122456, lng: 20.291647 },
    { lat: 48.8109321, lng: 20.2931871 },
    { lat: 48.8099459, lng: 20.2951496 },
    { lat: 48.8098054, lng: 20.2965624 },
    { lat: 48.8097688, lng: 20.2972684 },
    { lat: 48.8096589, lng: 20.2974552 },
    { lat: 48.8095011, lng: 20.2980856 },
    { lat: 48.8095033, lng: 20.2990329 },
    { lat: 48.8094526, lng: 20.2992333 },
    { lat: 48.8097598, lng: 20.3002039 },
    { lat: 48.8098741, lng: 20.3004227 },
    { lat: 48.8099006, lng: 20.3007494 },
    { lat: 48.8098637, lng: 20.3011636 },
    { lat: 48.810031, lng: 20.3044037 },
    { lat: 48.8100513, lng: 20.3058178 },
    { lat: 48.8105512, lng: 20.3080386 },
    { lat: 48.8102898, lng: 20.3087833 },
    { lat: 48.8100055, lng: 20.3098056 },
    { lat: 48.8095126, lng: 20.3107968 },
    { lat: 48.8091726, lng: 20.3120384 },
    { lat: 48.8088856, lng: 20.3128475 },
    { lat: 48.8088965, lng: 20.312927 },
    { lat: 48.8076898, lng: 20.3149308 },
    { lat: 48.8067783, lng: 20.318996 },
    { lat: 48.8060031, lng: 20.3197337 },
    { lat: 48.8060421, lng: 20.3228736 },
    { lat: 48.8060073, lng: 20.3233679 },
    { lat: 48.8056723, lng: 20.3246867 },
    { lat: 48.8051041, lng: 20.3281444 },
    { lat: 48.8046689, lng: 20.3322156 },
    { lat: 48.8036229, lng: 20.3345089 },
    { lat: 48.8036982, lng: 20.3356082 },
    { lat: 48.8036192, lng: 20.3361825 },
    { lat: 48.8034681, lng: 20.3367889 },
    { lat: 48.8031258, lng: 20.3389909 },
    { lat: 48.8031393, lng: 20.3395459 },
    { lat: 48.8025249, lng: 20.3404243 },
    { lat: 48.8009652, lng: 20.3432994 },
    { lat: 48.800497, lng: 20.3442654 },
    { lat: 48.7991033, lng: 20.3448306 },
    { lat: 48.7996258, lng: 20.3479101 },
    { lat: 48.7968345, lng: 20.3514937 },
    { lat: 48.7965052, lng: 20.3518311 },
    { lat: 48.7962313, lng: 20.353248 },
    { lat: 48.795325, lng: 20.3548294 },
    { lat: 48.7937037, lng: 20.3565837 },
    { lat: 48.7913752, lng: 20.3596491 },
    { lat: 48.7915591, lng: 20.360941 },
    { lat: 48.7930718, lng: 20.3662358 },
    { lat: 48.7935133, lng: 20.3673436 },
    { lat: 48.7947206, lng: 20.3696302 },
    { lat: 48.7949809, lng: 20.3702483 },
    { lat: 48.7951871, lng: 20.3709827 },
    { lat: 48.7955496, lng: 20.3707153 },
    { lat: 48.7961298, lng: 20.3725085 },
    { lat: 48.7963368, lng: 20.3732335 },
    { lat: 48.7968397, lng: 20.3757686 },
    { lat: 48.7985126, lng: 20.3833708 },
    { lat: 48.7987207, lng: 20.3845076 },
    { lat: 48.7986431, lng: 20.3848941 },
    { lat: 48.7989947, lng: 20.384871 },
    { lat: 48.7993677, lng: 20.3849671 },
    { lat: 48.799871, lng: 20.3849911 },
    { lat: 48.8000734, lng: 20.3848983 },
    { lat: 48.8003354, lng: 20.3850963 },
    { lat: 48.8004882, lng: 20.3850579 },
    { lat: 48.8006504, lng: 20.3848818 },
    { lat: 48.8007611, lng: 20.3850671 },
    { lat: 48.8011498, lng: 20.3849529 },
    { lat: 48.8019638, lng: 20.3866215 },
    { lat: 48.8021005, lng: 20.3873678 },
    { lat: 48.8022938, lng: 20.3877395 },
    { lat: 48.8023235, lng: 20.3878972 },
    { lat: 48.802255, lng: 20.388019 },
    { lat: 48.8023698, lng: 20.3880775 },
    { lat: 48.8023779, lng: 20.3883493 },
    { lat: 48.8024235, lng: 20.3884507 },
    { lat: 48.8025878, lng: 20.3885373 },
    { lat: 48.8025167, lng: 20.3887408 },
    { lat: 48.8026747, lng: 20.3890684 },
    { lat: 48.8026484, lng: 20.3892082 },
    { lat: 48.8027074, lng: 20.3895106 },
    { lat: 48.8026025, lng: 20.3895916 },
    { lat: 48.802644, lng: 20.389943 },
    { lat: 48.8025974, lng: 20.390166 },
    { lat: 48.8026533, lng: 20.3903622 },
    { lat: 48.8025992, lng: 20.3907113 },
    { lat: 48.8026141, lng: 20.391218 },
    { lat: 48.8027174, lng: 20.3915068 },
    { lat: 48.8029557, lng: 20.3918195 },
    { lat: 48.8030488, lng: 20.3921607 },
    { lat: 48.8030331, lng: 20.3923114 },
    { lat: 48.8031254, lng: 20.3930398 },
    { lat: 48.8033141, lng: 20.393444 },
    { lat: 48.8032084, lng: 20.3936541 },
    { lat: 48.8032401, lng: 20.3942707 },
    { lat: 48.8031997, lng: 20.3945421 },
    { lat: 48.8031039, lng: 20.3947752 },
    { lat: 48.8031021, lng: 20.3950486 },
    { lat: 48.8031957, lng: 20.3952315 },
    { lat: 48.8031716, lng: 20.3958034 },
    { lat: 48.8030103, lng: 20.3963601 },
    { lat: 48.803043, lng: 20.3965359 },
    { lat: 48.8029728, lng: 20.3969092 },
    { lat: 48.8029696, lng: 20.3971778 },
    { lat: 48.8030413, lng: 20.3973129 },
    { lat: 48.8030615, lng: 20.3974883 },
    { lat: 48.803023, lng: 20.3981025 },
    { lat: 48.8031734, lng: 20.3983526 },
    { lat: 48.8033264, lng: 20.3988185 },
    { lat: 48.8033418, lng: 20.399103 },
    { lat: 48.803293, lng: 20.3992719 },
    { lat: 48.8033682, lng: 20.3997057 },
    { lat: 48.803354, lng: 20.3999831 },
    { lat: 48.80359, lng: 20.4005565 },
    { lat: 48.8036589, lng: 20.4005329 },
    { lat: 48.8038217, lng: 20.4008794 },
    { lat: 48.8038252, lng: 20.4010877 },
    { lat: 48.8040841, lng: 20.4012908 },
    { lat: 48.8041681, lng: 20.4015142 },
    { lat: 48.8042794, lng: 20.401628 },
    { lat: 48.8042641, lng: 20.4019637 },
    { lat: 48.8045515, lng: 20.4025879 },
    { lat: 48.8045596, lng: 20.402743 },
    { lat: 48.8047349, lng: 20.4029401 },
    { lat: 48.8047769, lng: 20.4036552 },
    { lat: 48.8049945, lng: 20.4038065 },
    { lat: 48.8050475, lng: 20.4038954 },
    { lat: 48.8050563, lng: 20.4041254 },
    { lat: 48.8052408, lng: 20.4045386 },
    { lat: 48.8053834, lng: 20.4046715 },
    { lat: 48.8054403, lng: 20.4048387 },
    { lat: 48.8054365, lng: 20.4050038 },
    { lat: 48.8055286, lng: 20.4052275 },
    { lat: 48.8055537, lng: 20.4055025 },
    { lat: 48.805638, lng: 20.4056763 },
    { lat: 48.8061062, lng: 20.4059932 },
    { lat: 48.806304, lng: 20.4065135 },
    { lat: 48.8064348, lng: 20.4065774 },
    { lat: 48.8064471, lng: 20.4067821 },
    { lat: 48.8065346, lng: 20.4068623 },
    { lat: 48.8065365, lng: 20.40703 },
    { lat: 48.8065907, lng: 20.407095 },
    { lat: 48.806691, lng: 20.4074773 },
    { lat: 48.8067156, lng: 20.4077099 },
    { lat: 48.8066676, lng: 20.4078112 },
    { lat: 48.806792, lng: 20.4080047 },
    { lat: 48.8069105, lng: 20.4082769 },
    { lat: 48.8069198, lng: 20.4084597 },
    { lat: 48.8070712, lng: 20.4087361 },
    { lat: 48.8070494, lng: 20.4091103 },
    { lat: 48.807122, lng: 20.4095105 },
    { lat: 48.807249, lng: 20.4097094 },
    { lat: 48.8073831, lng: 20.4101799 },
    { lat: 48.8074432, lng: 20.4109642 },
    { lat: 48.8073711, lng: 20.4111962 },
    { lat: 48.8074472, lng: 20.4112725 },
    { lat: 48.8074667, lng: 20.4114561 },
    { lat: 48.8073977, lng: 20.4119234 },
    { lat: 48.8075122, lng: 20.4123585 },
    { lat: 48.8076266, lng: 20.4124303 },
    { lat: 48.8076379, lng: 20.4129941 },
    { lat: 48.8077722, lng: 20.4130634 },
    { lat: 48.8078837, lng: 20.4135052 },
    { lat: 48.80794, lng: 20.4135642 },
    { lat: 48.8080717, lng: 20.4144812 },
    { lat: 48.8081678, lng: 20.4148729 },
    { lat: 48.8082054, lng: 20.4152386 },
    { lat: 48.8081668, lng: 20.4153467 },
    { lat: 48.8081788, lng: 20.4156053 },
    { lat: 48.8111421, lng: 20.4226604 },
    { lat: 48.8112568, lng: 20.4233074 },
    { lat: 48.8116883, lng: 20.424731 },
    { lat: 48.8124999, lng: 20.4277187 },
    { lat: 48.8133148, lng: 20.4293383 },
    { lat: 48.8142163, lng: 20.4306042 },
    { lat: 48.8162927, lng: 20.4322626 },
    { lat: 48.817486, lng: 20.4328483 },
    { lat: 48.8174924, lng: 20.4328581 },
    { lat: 48.8175334, lng: 20.4329206 },
    { lat: 48.8181529, lng: 20.4338657 },
    { lat: 48.8186167, lng: 20.4343891 },
    { lat: 48.8196303, lng: 20.435533 },
    { lat: 48.8203576, lng: 20.4373971 },
    { lat: 48.8210816, lng: 20.438202 },
    { lat: 48.8224555, lng: 20.4401044 },
    { lat: 48.8246884, lng: 20.4430474 },
    { lat: 48.8241945, lng: 20.4455591 },
    { lat: 48.8250911, lng: 20.4475907 },
    { lat: 48.8262208, lng: 20.4499552 },
    { lat: 48.8281269, lng: 20.4527803 },
    { lat: 48.8287356, lng: 20.453958 },
    { lat: 48.8301525, lng: 20.4555228 },
    { lat: 48.8305832, lng: 20.455925 },
    { lat: 48.8311633, lng: 20.4573018 },
    { lat: 48.831615, lng: 20.458595 },
    { lat: 48.832193, lng: 20.459783 },
    { lat: 48.832417, lng: 20.460242 },
    { lat: 48.833743, lng: 20.45792 },
    { lat: 48.833791, lng: 20.456429 },
    { lat: 48.833805, lng: 20.45602 },
    { lat: 48.834142, lng: 20.454977 },
    { lat: 48.834467, lng: 20.454736 },
    { lat: 48.834135, lng: 20.453243 },
    { lat: 48.834404, lng: 20.45295 },
    { lat: 48.83406, lng: 20.452228 },
    { lat: 48.834169, lng: 20.450521 },
    { lat: 48.834266, lng: 20.450533 },
    { lat: 48.834398, lng: 20.450219 },
    { lat: 48.83448, lng: 20.450021 },
    { lat: 48.834672, lng: 20.449559 },
    { lat: 48.835059, lng: 20.449582 },
    { lat: 48.835286, lng: 20.449011 },
    { lat: 48.835635, lng: 20.449295 },
    { lat: 48.835791, lng: 20.448598 },
    { lat: 48.836134, lng: 20.447985 },
    { lat: 48.83608, lng: 20.446844 },
    { lat: 48.836158, lng: 20.445926 },
    { lat: 48.836349, lng: 20.445693 },
    { lat: 48.836712, lng: 20.445557 },
    { lat: 48.836794, lng: 20.445044 },
    { lat: 48.836619, lng: 20.444851 },
    { lat: 48.836669, lng: 20.44438 },
    { lat: 48.836823, lng: 20.444186 },
    { lat: 48.837062, lng: 20.44439 },
    { lat: 48.837145, lng: 20.444227 },
    { lat: 48.837452, lng: 20.445 },
    { lat: 48.837871, lng: 20.444855 },
    { lat: 48.838167, lng: 20.444856 },
    { lat: 48.838305, lng: 20.444025 },
    { lat: 48.838332, lng: 20.443536 },
    { lat: 48.838368, lng: 20.443469 },
    { lat: 48.838551, lng: 20.443124 },
    { lat: 48.838332, lng: 20.442655 },
    { lat: 48.838302, lng: 20.442594 },
    { lat: 48.838335, lng: 20.4421 },
    { lat: 48.838248, lng: 20.442082 },
    { lat: 48.838284, lng: 20.441624 },
    { lat: 48.838471, lng: 20.441443 },
    { lat: 48.83833, lng: 20.440969 },
    { lat: 48.838214, lng: 20.440775 },
    { lat: 48.838343, lng: 20.440714 },
    { lat: 48.838485, lng: 20.440514 },
    { lat: 48.838377, lng: 20.440319 },
    { lat: 48.838449, lng: 20.440113 },
    { lat: 48.838435, lng: 20.439906 },
    { lat: 48.838512, lng: 20.439771 },
    { lat: 48.838463, lng: 20.439417 },
    { lat: 48.838494, lng: 20.439287 },
    { lat: 48.838203, lng: 20.438937 },
    { lat: 48.838328, lng: 20.437724 },
    { lat: 48.838032, lng: 20.437337 },
    { lat: 48.837909, lng: 20.437175 },
    { lat: 48.837907, lng: 20.436961 },
    { lat: 48.838199, lng: 20.43676 },
    { lat: 48.838295, lng: 20.436628 },
    { lat: 48.83866, lng: 20.43622 },
    { lat: 48.838668, lng: 20.436067 },
    { lat: 48.838539, lng: 20.435866 },
    { lat: 48.838405, lng: 20.435696 },
    { lat: 48.838221, lng: 20.435496 },
    { lat: 48.83801, lng: 20.435266 },
    { lat: 48.837914, lng: 20.435171 },
    { lat: 48.837692, lng: 20.434888 },
    { lat: 48.83746, lng: 20.434493 },
    { lat: 48.83727, lng: 20.434225 },
    { lat: 48.837032, lng: 20.433958 },
    { lat: 48.836806, lng: 20.433741 },
    { lat: 48.83671, lng: 20.433648 },
    { lat: 48.836543, lng: 20.433478 },
    { lat: 48.836394, lng: 20.433315 },
    { lat: 48.836354, lng: 20.433218 },
    { lat: 48.836343, lng: 20.433158 },
    { lat: 48.83634, lng: 20.43314 },
    { lat: 48.836337, lng: 20.433119 },
    { lat: 48.83633, lng: 20.433056 },
    { lat: 48.836339, lng: 20.432953 },
    { lat: 48.83637, lng: 20.432859 },
    { lat: 48.836389, lng: 20.432801 },
    { lat: 48.836403, lng: 20.432761 },
    { lat: 48.836473, lng: 20.432663 },
    { lat: 48.836525, lng: 20.432593 },
    { lat: 48.836681, lng: 20.432575 },
    { lat: 48.837096, lng: 20.432626 },
    { lat: 48.837336, lng: 20.432664 },
    { lat: 48.837701, lng: 20.432745 },
    { lat: 48.837688, lng: 20.43258 },
    { lat: 48.837731, lng: 20.432147 },
    { lat: 48.83773, lng: 20.432074 },
    { lat: 48.837722, lng: 20.431399 },
    { lat: 48.837709, lng: 20.431348 },
    { lat: 48.837752, lng: 20.430967 },
    { lat: 48.837594, lng: 20.430779 },
    { lat: 48.83756, lng: 20.430553 },
    { lat: 48.837606, lng: 20.430054 },
    { lat: 48.837511, lng: 20.42954 },
    { lat: 48.837546, lng: 20.429226 },
    { lat: 48.83722, lng: 20.428176 },
    { lat: 48.837067, lng: 20.427615 },
    { lat: 48.83695, lng: 20.427058 },
    { lat: 48.836574, lng: 20.426513 },
    { lat: 48.836631, lng: 20.426222 },
    { lat: 48.836535, lng: 20.425508 },
    { lat: 48.836399, lng: 20.425571 },
    { lat: 48.835925, lng: 20.425321 },
    { lat: 48.836128, lng: 20.424768 },
    { lat: 48.836642, lng: 20.424531 },
    { lat: 48.836701, lng: 20.424503 },
    { lat: 48.836841, lng: 20.424609 },
    { lat: 48.836975, lng: 20.424408 },
    { lat: 48.837139, lng: 20.424192 },
    { lat: 48.83729, lng: 20.424176 },
    { lat: 48.837885, lng: 20.424114 },
    { lat: 48.838116, lng: 20.423784 },
    { lat: 48.838359, lng: 20.42352 },
    { lat: 48.838721, lng: 20.423183 },
    { lat: 48.838967, lng: 20.422651 },
    { lat: 48.838956, lng: 20.422214 },
    { lat: 48.839059, lng: 20.421705 },
    { lat: 48.839198, lng: 20.421183 },
    { lat: 48.839296, lng: 20.420879 },
    { lat: 48.839789, lng: 20.419608 },
    { lat: 48.840563, lng: 20.418415 },
    { lat: 48.840876, lng: 20.418142 },
    { lat: 48.841209, lng: 20.417909 },
    { lat: 48.841676, lng: 20.417911 },
    { lat: 48.841903, lng: 20.417922 },
    { lat: 48.842225, lng: 20.417621 },
    { lat: 48.842228, lng: 20.417568 },
    { lat: 48.842312, lng: 20.417485 },
    { lat: 48.842312, lng: 20.417087 },
    { lat: 48.842133, lng: 20.416723 },
    { lat: 48.842099, lng: 20.416503 },
    { lat: 48.842183, lng: 20.416198 },
    { lat: 48.842315, lng: 20.415831 },
    { lat: 48.842461, lng: 20.415285 },
    { lat: 48.842548, lng: 20.414645 },
    { lat: 48.842677, lng: 20.413991 },
    { lat: 48.842755, lng: 20.413489 },
    { lat: 48.842915, lng: 20.413144 },
    { lat: 48.842998, lng: 20.412681 },
    { lat: 48.843243, lng: 20.410838 },
    { lat: 48.843305, lng: 20.409934 },
    { lat: 48.843367, lng: 20.409034 },
    { lat: 48.843434, lng: 20.40827 },
    { lat: 48.843599, lng: 20.407506 },
    { lat: 48.84363, lng: 20.406425 },
    { lat: 48.843594, lng: 20.405723 },
    { lat: 48.842963, lng: 20.404141 },
    { lat: 48.842725, lng: 20.403662 },
    { lat: 48.842717, lng: 20.403318 },
    { lat: 48.842789, lng: 20.402991 },
    { lat: 48.842931, lng: 20.402585 },
    { lat: 48.843331, lng: 20.402486 },
    { lat: 48.84352, lng: 20.402317 },
    { lat: 48.844003, lng: 20.401624 },
    { lat: 48.844271, lng: 20.401198 },
    { lat: 48.844434, lng: 20.400737 },
    { lat: 48.844559, lng: 20.400466 },
    { lat: 48.84489, lng: 20.399892 },
    { lat: 48.845068, lng: 20.399386 },
    { lat: 48.844948, lng: 20.39837 },
    { lat: 48.844791, lng: 20.398069 },
    { lat: 48.844642, lng: 20.397429 },
    { lat: 48.844639, lng: 20.396803 },
    { lat: 48.844655, lng: 20.396271 },
    { lat: 48.844574, lng: 20.395866 },
    { lat: 48.84389, lng: 20.395247 },
    { lat: 48.843569, lng: 20.394475 },
    { lat: 48.843467, lng: 20.393979 },
    { lat: 48.843941, lng: 20.393199 },
    { lat: 48.844361, lng: 20.392548 },
    { lat: 48.844579, lng: 20.392332 },
    { lat: 48.844728, lng: 20.392077 },
    { lat: 48.844747, lng: 20.391817 },
    { lat: 48.844626, lng: 20.391145 },
    { lat: 48.844449, lng: 20.390538 },
    { lat: 48.844399, lng: 20.389985 },
    { lat: 48.844342, lng: 20.389451 },
    { lat: 48.844338, lng: 20.389275 },
    { lat: 48.84437, lng: 20.389024 },
    { lat: 48.844405, lng: 20.388799 },
    { lat: 48.844449, lng: 20.388649 },
    { lat: 48.84454, lng: 20.388463 },
    { lat: 48.844628, lng: 20.388301 },
    { lat: 48.844829, lng: 20.38805 },
    { lat: 48.845035, lng: 20.387879 },
    { lat: 48.845335, lng: 20.387725 },
    { lat: 48.845632, lng: 20.387659 },
    { lat: 48.84573, lng: 20.387604 },
    { lat: 48.845839, lng: 20.387511 },
    { lat: 48.845935, lng: 20.387386 },
    { lat: 48.846046, lng: 20.387272 },
    { lat: 48.846186, lng: 20.387207 },
    { lat: 48.846255, lng: 20.387152 },
    { lat: 48.846295, lng: 20.387047 },
    { lat: 48.846256, lng: 20.386762 },
    { lat: 48.84629, lng: 20.386654 },
    { lat: 48.846345, lng: 20.386311 },
    { lat: 48.846439, lng: 20.386084 },
    { lat: 48.846486, lng: 20.385925 },
    { lat: 48.846669, lng: 20.385444 },
    { lat: 48.846718, lng: 20.385263 },
    { lat: 48.846882, lng: 20.384831 },
    { lat: 48.847092, lng: 20.384349 },
    { lat: 48.847235, lng: 20.384 },
    { lat: 48.847366, lng: 20.383497 },
    { lat: 48.847394, lng: 20.383261 },
    { lat: 48.847354, lng: 20.382725 },
    { lat: 48.847342, lng: 20.382381 },
    { lat: 48.8473844, lng: 20.3818022 },
    { lat: 48.8474469, lng: 20.3814125 },
    { lat: 48.8474982, lng: 20.3812935 },
    { lat: 48.847588, lng: 20.381137 },
    { lat: 48.847684, lng: 20.380978 },
    { lat: 48.847822, lng: 20.380866 },
    { lat: 48.847911, lng: 20.380733 },
    { lat: 48.848059, lng: 20.380124 },
    { lat: 48.848157, lng: 20.379786 },
    { lat: 48.848222, lng: 20.379408 },
    { lat: 48.848235, lng: 20.379099 },
    { lat: 48.848297, lng: 20.378803 },
    { lat: 48.848431, lng: 20.378302 },
    { lat: 48.848517, lng: 20.377927 },
    { lat: 48.848595, lng: 20.377711 },
    { lat: 48.848626, lng: 20.377738 },
    { lat: 48.84867, lng: 20.377678 },
    { lat: 48.8487094, lng: 20.3775827 },
    { lat: 48.8485175, lng: 20.377335 },
    { lat: 48.8482462, lng: 20.3771584 },
    { lat: 48.8480387, lng: 20.3771184 },
    { lat: 48.8477118, lng: 20.3771705 },
    { lat: 48.8485774, lng: 20.3766955 },
    { lat: 48.8487851, lng: 20.3764133 },
    { lat: 48.8498006, lng: 20.3745628 },
    { lat: 48.849923, lng: 20.3744383 },
    { lat: 48.8514537, lng: 20.3736846 },
    { lat: 48.8516675, lng: 20.3734212 },
    { lat: 48.8517713, lng: 20.3733955 },
    { lat: 48.8518893, lng: 20.3731524 },
    { lat: 48.8519432, lng: 20.3727128 },
    { lat: 48.8519221, lng: 20.3723364 },
    { lat: 48.8514239, lng: 20.3708075 },
    { lat: 48.8512803, lng: 20.3700854 },
    { lat: 48.8515912, lng: 20.3690661 },
    { lat: 48.8517939, lng: 20.3669021 },
    { lat: 48.8518923, lng: 20.3665047 },
    { lat: 48.8519216, lng: 20.366535 },
    { lat: 48.8519571, lng: 20.3664575 },
    { lat: 48.8519424, lng: 20.3663702 },
    { lat: 48.8522257, lng: 20.3658914 },
    { lat: 48.8533113, lng: 20.3644301 },
    { lat: 48.8534172, lng: 20.3640557 },
    { lat: 48.8537162, lng: 20.3608876 },
    { lat: 48.8539049, lng: 20.3600274 },
    { lat: 48.8542927, lng: 20.3586357 },
    { lat: 48.8545368, lng: 20.3572881 },
    { lat: 48.8546857, lng: 20.3560953 },
    { lat: 48.8548468, lng: 20.3553115 },
    { lat: 48.8549143, lng: 20.355195 },
    { lat: 48.8549081, lng: 20.3550057 },
    { lat: 48.8549804, lng: 20.3546045 },
    { lat: 48.8550556, lng: 20.3542721 },
  ],
  Dedinky: [
    { lat: 48.8550556, lng: 20.3542721 },
    { lat: 48.8549804, lng: 20.3546045 },
    { lat: 48.8549081, lng: 20.3550057 },
    { lat: 48.8549143, lng: 20.355195 },
    { lat: 48.8548468, lng: 20.3553115 },
    { lat: 48.8546857, lng: 20.3560953 },
    { lat: 48.8545368, lng: 20.3572881 },
    { lat: 48.8542927, lng: 20.3586357 },
    { lat: 48.8539049, lng: 20.3600274 },
    { lat: 48.8537162, lng: 20.3608876 },
    { lat: 48.8534172, lng: 20.3640557 },
    { lat: 48.8533113, lng: 20.3644301 },
    { lat: 48.8522257, lng: 20.3658914 },
    { lat: 48.8519424, lng: 20.3663702 },
    { lat: 48.8519571, lng: 20.3664575 },
    { lat: 48.8519216, lng: 20.366535 },
    { lat: 48.8518923, lng: 20.3665047 },
    { lat: 48.8517939, lng: 20.3669021 },
    { lat: 48.8515912, lng: 20.3690661 },
    { lat: 48.8512803, lng: 20.3700854 },
    { lat: 48.8514239, lng: 20.3708075 },
    { lat: 48.8519221, lng: 20.3723364 },
    { lat: 48.8519432, lng: 20.3727128 },
    { lat: 48.8518893, lng: 20.3731524 },
    { lat: 48.8517713, lng: 20.3733955 },
    { lat: 48.8516675, lng: 20.3734212 },
    { lat: 48.8514537, lng: 20.3736846 },
    { lat: 48.849923, lng: 20.3744383 },
    { lat: 48.8498006, lng: 20.3745628 },
    { lat: 48.8487851, lng: 20.3764133 },
    { lat: 48.8485774, lng: 20.3766955 },
    { lat: 48.8477118, lng: 20.3771705 },
    { lat: 48.8480387, lng: 20.3771184 },
    { lat: 48.8482462, lng: 20.3771584 },
    { lat: 48.8485175, lng: 20.377335 },
    { lat: 48.8487094, lng: 20.3775827 },
    { lat: 48.84867, lng: 20.377678 },
    { lat: 48.849381, lng: 20.378329 },
    { lat: 48.849837, lng: 20.378516 },
    { lat: 48.849959, lng: 20.378532 },
    { lat: 48.850268, lng: 20.378762 },
    { lat: 48.850493, lng: 20.379205 },
    { lat: 48.850641, lng: 20.379422 },
    { lat: 48.850761, lng: 20.379537 },
    { lat: 48.851132, lng: 20.37974 },
    { lat: 48.851364, lng: 20.379911 },
    { lat: 48.851532, lng: 20.380073 },
    { lat: 48.851712, lng: 20.380313 },
    { lat: 48.851829, lng: 20.380551 },
    { lat: 48.852175, lng: 20.381674 },
    { lat: 48.852459, lng: 20.382391 },
    { lat: 48.852732, lng: 20.382992 },
    { lat: 48.852932, lng: 20.383709 },
    { lat: 48.85294, lng: 20.383844 },
    { lat: 48.852979, lng: 20.38399 },
    { lat: 48.853017, lng: 20.384077 },
    { lat: 48.853108, lng: 20.384301 },
    { lat: 48.853134, lng: 20.384578 },
    { lat: 48.853264, lng: 20.384869 },
    { lat: 48.853308, lng: 20.384976 },
    { lat: 48.853373, lng: 20.385113 },
    { lat: 48.853505, lng: 20.385328 },
    { lat: 48.853526, lng: 20.38537 },
    { lat: 48.853608, lng: 20.385534 },
    { lat: 48.853646, lng: 20.38564 },
    { lat: 48.853711, lng: 20.385909 },
    { lat: 48.85372, lng: 20.385903 },
    { lat: 48.853886, lng: 20.385794 },
    { lat: 48.854007, lng: 20.385763 },
    { lat: 48.854242, lng: 20.385786 },
    { lat: 48.854307, lng: 20.385792 },
    { lat: 48.854313, lng: 20.385807 },
    { lat: 48.854373, lng: 20.385911 },
    { lat: 48.854427, lng: 20.385995 },
    { lat: 48.854867, lng: 20.385982 },
    { lat: 48.855136, lng: 20.385885 },
    { lat: 48.855429, lng: 20.38567 },
    { lat: 48.855702, lng: 20.385424 },
    { lat: 48.855766, lng: 20.385434 },
    { lat: 48.855824, lng: 20.38544 },
    { lat: 48.855915, lng: 20.385409 },
    { lat: 48.855951, lng: 20.38539 },
    { lat: 48.864004, lng: 20.389613 },
    { lat: 48.864129, lng: 20.389677 },
    { lat: 48.864958, lng: 20.390112 },
    { lat: 48.864987, lng: 20.39026 },
    { lat: 48.865041, lng: 20.390498 },
    { lat: 48.865137, lng: 20.39074 },
    { lat: 48.865188, lng: 20.390922 },
    { lat: 48.865239, lng: 20.391406 },
    { lat: 48.865421, lng: 20.391887 },
    { lat: 48.865533, lng: 20.392248 },
    { lat: 48.865701, lng: 20.392523 },
    { lat: 48.865882, lng: 20.392693 },
    { lat: 48.866236, lng: 20.393148 },
    { lat: 48.8664, lng: 20.393579 },
    { lat: 48.866424, lng: 20.393821 },
    { lat: 48.866434, lng: 20.393877 },
    { lat: 48.866494, lng: 20.393981 },
    { lat: 48.866534, lng: 20.394056 },
    { lat: 48.866792, lng: 20.394517 },
    { lat: 48.866859, lng: 20.39462 },
    { lat: 48.866893, lng: 20.394751 },
    { lat: 48.866996, lng: 20.394918 },
    { lat: 48.867077, lng: 20.395079 },
    { lat: 48.867151, lng: 20.395215 },
    { lat: 48.867219, lng: 20.39534 },
    { lat: 48.867273, lng: 20.395445 },
    { lat: 48.867416, lng: 20.395824 },
    { lat: 48.867591, lng: 20.396131 },
    { lat: 48.867776, lng: 20.396521 },
    { lat: 48.867869, lng: 20.396704 },
    { lat: 48.867969, lng: 20.396954 },
    { lat: 48.868073, lng: 20.397187 },
    { lat: 48.86813, lng: 20.397271 },
    { lat: 48.868254, lng: 20.397402 },
    { lat: 48.868401, lng: 20.397542 },
    { lat: 48.8685, lng: 20.397613 },
    { lat: 48.86859, lng: 20.397665 },
    { lat: 48.868733, lng: 20.397747 },
    { lat: 48.868789, lng: 20.397778 },
    { lat: 48.86899, lng: 20.398005 },
    { lat: 48.869283, lng: 20.398205 },
    { lat: 48.86943, lng: 20.398315 },
    { lat: 48.869434, lng: 20.398317 },
    { lat: 48.869756, lng: 20.398611 },
    { lat: 48.869872, lng: 20.398722 },
    { lat: 48.870085, lng: 20.398913 },
    { lat: 48.870182, lng: 20.399 },
    { lat: 48.870291, lng: 20.399098 },
    { lat: 48.870408, lng: 20.399209 },
    { lat: 48.870453, lng: 20.399763 },
    { lat: 48.870504, lng: 20.399929 },
    { lat: 48.870685, lng: 20.400213 },
    { lat: 48.870741, lng: 20.400299 },
    { lat: 48.871125, lng: 20.400748 },
    { lat: 48.871271, lng: 20.400971 },
    { lat: 48.871394, lng: 20.401187 },
    { lat: 48.871473, lng: 20.401364 },
    { lat: 48.871796, lng: 20.402198 },
    { lat: 48.872087, lng: 20.403023 },
    { lat: 48.872182, lng: 20.403366 },
    { lat: 48.872409, lng: 20.403809 },
    { lat: 48.872575, lng: 20.404069 },
    { lat: 48.872644, lng: 20.40425 },
    { lat: 48.872723, lng: 20.404458 },
    { lat: 48.872788, lng: 20.404621 },
    { lat: 48.872876, lng: 20.404909 },
    { lat: 48.872939, lng: 20.405084 },
    { lat: 48.873124, lng: 20.405529 },
    { lat: 48.873287, lng: 20.405934 },
    { lat: 48.873534, lng: 20.406779 },
    { lat: 48.873669, lng: 20.406949 },
    { lat: 48.873751, lng: 20.40723 },
    { lat: 48.873831, lng: 20.407458 },
    { lat: 48.874032, lng: 20.407909 },
    { lat: 48.874129, lng: 20.408032 },
    { lat: 48.8742, lng: 20.408172 },
    { lat: 48.874339, lng: 20.408689 },
    { lat: 48.874464, lng: 20.408863 },
    { lat: 48.8745, lng: 20.409041 },
    { lat: 48.874514, lng: 20.409284 },
    { lat: 48.874511, lng: 20.409584 },
    { lat: 48.874595, lng: 20.41028 },
    { lat: 48.874639, lng: 20.410547 },
    { lat: 48.874715, lng: 20.410761 },
    { lat: 48.874871, lng: 20.410941 },
    { lat: 48.874912, lng: 20.41106 },
    { lat: 48.874908, lng: 20.411217 },
    { lat: 48.874992, lng: 20.411462 },
    { lat: 48.875114, lng: 20.411994 },
    { lat: 48.875148, lng: 20.412263 },
    { lat: 48.875198, lng: 20.412551 },
    { lat: 48.87521, lng: 20.412789 },
    { lat: 48.875309, lng: 20.413049 },
    { lat: 48.87537, lng: 20.413337 },
    { lat: 48.875381, lng: 20.413488 },
    { lat: 48.875361, lng: 20.413964 },
    { lat: 48.875409, lng: 20.414315 },
    { lat: 48.875578, lng: 20.414788 },
    { lat: 48.875639, lng: 20.414871 },
    { lat: 48.875716, lng: 20.414907 },
    { lat: 48.875845, lng: 20.41492 },
    { lat: 48.876094, lng: 20.414953 },
    { lat: 48.876234, lng: 20.414912 },
    { lat: 48.876401, lng: 20.414925 },
    { lat: 48.876599, lng: 20.415099 },
    { lat: 48.876691, lng: 20.415234 },
    { lat: 48.876779, lng: 20.41549 },
    { lat: 48.876795, lng: 20.415477 },
    { lat: 48.876831, lng: 20.415301 },
    { lat: 48.876785, lng: 20.414999 },
    { lat: 48.876694, lng: 20.414622 },
    { lat: 48.876499, lng: 20.414089 },
    { lat: 48.876352, lng: 20.414028 },
    { lat: 48.875954, lng: 20.414074 },
    { lat: 48.875696, lng: 20.413297 },
    { lat: 48.875587, lng: 20.412897 },
    { lat: 48.87541, lng: 20.412286 },
    { lat: 48.875373, lng: 20.412202 },
    { lat: 48.875233, lng: 20.411557 },
    { lat: 48.875097, lng: 20.410471 },
    { lat: 48.875165, lng: 20.410005 },
    { lat: 48.875228, lng: 20.409579 },
    { lat: 48.875341, lng: 20.408981 },
    { lat: 48.875339, lng: 20.408824 },
    { lat: 48.875335, lng: 20.408686 },
    { lat: 48.875325, lng: 20.408258 },
    { lat: 48.875302, lng: 20.407507 },
    { lat: 48.875256, lng: 20.407387 },
    { lat: 48.875091, lng: 20.406918 },
    { lat: 48.874871, lng: 20.406351 },
    { lat: 48.87485, lng: 20.406296 },
    { lat: 48.874741, lng: 20.405997 },
    { lat: 48.874738, lng: 20.40599 },
    { lat: 48.874141, lng: 20.405638 },
    { lat: 48.873861, lng: 20.404983 },
    { lat: 48.873535, lng: 20.404408 },
    { lat: 48.873216, lng: 20.40366 },
    { lat: 48.873005, lng: 20.403182 },
    { lat: 48.872664, lng: 20.402409 },
    { lat: 48.872452, lng: 20.401875 },
    { lat: 48.872136, lng: 20.401058 },
    { lat: 48.871921, lng: 20.400471 },
    { lat: 48.871601, lng: 20.400206 },
    { lat: 48.87141, lng: 20.399864 },
    { lat: 48.871288, lng: 20.399193 },
    { lat: 48.871555, lng: 20.398908 },
    { lat: 48.87167, lng: 20.398761 },
    { lat: 48.872107, lng: 20.39835 },
    { lat: 48.872332, lng: 20.398098 },
    { lat: 48.872839, lng: 20.397394 },
    { lat: 48.873278, lng: 20.397194 },
    { lat: 48.873557, lng: 20.39712 },
    { lat: 48.873797, lng: 20.397064 },
    { lat: 48.873993, lng: 20.397048 },
    { lat: 48.873994, lng: 20.397028 },
    { lat: 48.874157, lng: 20.39521 },
    { lat: 48.874307, lng: 20.394432 },
    { lat: 48.874458, lng: 20.394044 },
    { lat: 48.874399, lng: 20.393924 },
    { lat: 48.874167, lng: 20.393443 },
    { lat: 48.874339, lng: 20.392994 },
    { lat: 48.874383, lng: 20.392878 },
    { lat: 48.874496, lng: 20.392581 },
    { lat: 48.874531, lng: 20.392454 },
    { lat: 48.874511, lng: 20.3924068 },
    { lat: 48.8741874, lng: 20.3925086 },
    { lat: 48.873254, lng: 20.3921904 },
    { lat: 48.8734687, lng: 20.3918575 },
    { lat: 48.8734026, lng: 20.3914548 },
    { lat: 48.8735272, lng: 20.391177 },
    { lat: 48.8733637, lng: 20.3907038 },
    { lat: 48.8730616, lng: 20.3906968 },
    { lat: 48.8727455, lng: 20.3905804 },
    { lat: 48.8725279, lng: 20.3897225 },
    { lat: 48.8727105, lng: 20.3885511 },
    { lat: 48.8737957, lng: 20.3892489 },
    { lat: 48.8741531, lng: 20.3892604 },
    { lat: 48.8744055, lng: 20.3888826 },
    { lat: 48.8745784, lng: 20.3884907 },
    { lat: 48.8747375, lng: 20.38762 },
    { lat: 48.8748247, lng: 20.3867799 },
    { lat: 48.8750622, lng: 20.3861852 },
    { lat: 48.8750699, lng: 20.3858003 },
    { lat: 48.8747829, lng: 20.3844198 },
    { lat: 48.8747994, lng: 20.3831531 },
    { lat: 48.8746169, lng: 20.3833531 },
    { lat: 48.8745123, lng: 20.3837372 },
    { lat: 48.8742651, lng: 20.3840487 },
    { lat: 48.8723505, lng: 20.3847965 },
    { lat: 48.8709787, lng: 20.3842632 },
    { lat: 48.8707412, lng: 20.3834113 },
    { lat: 48.8705877, lng: 20.3820235 },
    { lat: 48.8706307, lng: 20.3802416 },
    { lat: 48.8695912, lng: 20.38006 },
    { lat: 48.8693589, lng: 20.3785836 },
    { lat: 48.8696509, lng: 20.3767745 },
    { lat: 48.8692454, lng: 20.3768317 },
    { lat: 48.8691771, lng: 20.3769257 },
    { lat: 48.8690666, lng: 20.3764823 },
    { lat: 48.8685775, lng: 20.3764704 },
    { lat: 48.8683674, lng: 20.3766048 },
    { lat: 48.8682776, lng: 20.3765834 },
    { lat: 48.8681455, lng: 20.3763368 },
    { lat: 48.8681334, lng: 20.3765189 },
    { lat: 48.868181, lng: 20.3765619 },
    { lat: 48.8681237, lng: 20.3768828 },
    { lat: 48.8679548, lng: 20.3772403 },
    { lat: 48.8681142, lng: 20.3773421 },
    { lat: 48.8680044, lng: 20.3777943 },
    { lat: 48.8675952, lng: 20.3779277 },
    { lat: 48.8661786, lng: 20.3767565 },
    { lat: 48.8654101, lng: 20.3758043 },
    { lat: 48.8648606, lng: 20.3748975 },
    { lat: 48.8638873, lng: 20.3737993 },
    { lat: 48.8638739, lng: 20.3738935 },
    { lat: 48.8634245, lng: 20.3743555 },
    { lat: 48.8627874, lng: 20.3745706 },
    { lat: 48.8623059, lng: 20.3749289 },
    { lat: 48.8621408, lng: 20.3749134 },
    { lat: 48.8618616, lng: 20.3747095 },
    { lat: 48.862015, lng: 20.3746602 },
    { lat: 48.8614874, lng: 20.374464 },
    { lat: 48.8609378, lng: 20.3739961 },
    { lat: 48.8607329, lng: 20.3731459 },
    { lat: 48.8604619, lng: 20.3715734 },
    { lat: 48.8602173, lng: 20.3709546 },
    { lat: 48.8596187, lng: 20.3700305 },
    { lat: 48.8591716, lng: 20.3694434 },
    { lat: 48.8587441, lng: 20.3686902 },
    { lat: 48.8584612, lng: 20.3673427 },
    { lat: 48.8580553, lng: 20.3665383 },
    { lat: 48.8578031, lng: 20.3658357 },
    { lat: 48.8578601, lng: 20.365035 },
    { lat: 48.8578357, lng: 20.3648365 },
    { lat: 48.8577143, lng: 20.3645533 },
    { lat: 48.8576912, lng: 20.3643015 },
    { lat: 48.8575274, lng: 20.3638525 },
    { lat: 48.8575346, lng: 20.363568 },
    { lat: 48.8573864, lng: 20.3630844 },
    { lat: 48.8571287, lng: 20.3627099 },
    { lat: 48.8571752, lng: 20.3624855 },
    { lat: 48.8571592, lng: 20.3621558 },
    { lat: 48.8569388, lng: 20.3617272 },
    { lat: 48.8570846, lng: 20.3613331 },
    { lat: 48.8571871, lng: 20.3611885 },
    { lat: 48.8572157, lng: 20.3609954 },
    { lat: 48.8571637, lng: 20.3608469 },
    { lat: 48.8572625, lng: 20.3604824 },
    { lat: 48.8571551, lng: 20.3603832 },
    { lat: 48.8569605, lng: 20.3598111 },
    { lat: 48.8569491, lng: 20.3596015 },
    { lat: 48.8570867, lng: 20.3594005 },
    { lat: 48.8570817, lng: 20.3592704 },
    { lat: 48.8569423, lng: 20.3590771 },
    { lat: 48.8569681, lng: 20.3588943 },
    { lat: 48.8569084, lng: 20.3588094 },
    { lat: 48.8567839, lng: 20.3582673 },
    { lat: 48.8564805, lng: 20.357786 },
    { lat: 48.8560118, lng: 20.3568785 },
    { lat: 48.8558224, lng: 20.3567204 },
    { lat: 48.8558863, lng: 20.3563965 },
    { lat: 48.8556556, lng: 20.3553471 },
    { lat: 48.8555495, lng: 20.3551948 },
    { lat: 48.8555676, lng: 20.3547287 },
    { lat: 48.8554935, lng: 20.3540687 },
    { lat: 48.8552524, lng: 20.3541498 },
    { lat: 48.8550556, lng: 20.3542721 },
  ],
  Drnava: [
    { lat: 48.6144156, lng: 20.677735 },
    { lat: 48.6144237, lng: 20.6777126 },
    { lat: 48.6146561, lng: 20.6780985 },
    { lat: 48.61486, lng: 20.6783163 },
    { lat: 48.6150226, lng: 20.6788641 },
    { lat: 48.6152263, lng: 20.6791153 },
    { lat: 48.6153666, lng: 20.6795379 },
    { lat: 48.615533, lng: 20.6802822 },
    { lat: 48.615981, lng: 20.6811607 },
    { lat: 48.6162517, lng: 20.6822405 },
    { lat: 48.6165285, lng: 20.6826676 },
    { lat: 48.6167599, lng: 20.6835667 },
    { lat: 48.617168, lng: 20.6842329 },
    { lat: 48.6173803, lng: 20.6852901 },
    { lat: 48.6177147, lng: 20.6859762 },
    { lat: 48.6179006, lng: 20.6860475 },
    { lat: 48.6181741, lng: 20.6863265 },
    { lat: 48.6186409, lng: 20.6863766 },
    { lat: 48.6191741, lng: 20.6872689 },
    { lat: 48.6200291, lng: 20.6893227 },
    { lat: 48.6207845, lng: 20.690393 },
    { lat: 48.6209502, lng: 20.6907093 },
    { lat: 48.6212691, lng: 20.6910316 },
    { lat: 48.6218482, lng: 20.6920804 },
    { lat: 48.6219702, lng: 20.6919626 },
    { lat: 48.6221671, lng: 20.6919295 },
    { lat: 48.6224716, lng: 20.6917555 },
    { lat: 48.6226842, lng: 20.6917499 },
    { lat: 48.6238849, lng: 20.6909495 },
    { lat: 48.6247994, lng: 20.6914599 },
    { lat: 48.6249322, lng: 20.691432 },
    { lat: 48.625308, lng: 20.6915166 },
    { lat: 48.6263, lng: 20.6918328 },
    { lat: 48.6266483, lng: 20.6917819 },
    { lat: 48.6268369, lng: 20.6918317 },
    { lat: 48.62711, lng: 20.6917549 },
    { lat: 48.6273403, lng: 20.6918703 },
    { lat: 48.6274782, lng: 20.691853 },
    { lat: 48.6279468, lng: 20.6916703 },
    { lat: 48.6286948, lng: 20.6915245 },
    { lat: 48.6290294, lng: 20.6916894 },
    { lat: 48.6292917, lng: 20.6916555 },
    { lat: 48.6293857, lng: 20.6917635 },
    { lat: 48.629434, lng: 20.6919205 },
    { lat: 48.6298081, lng: 20.6919316 },
    { lat: 48.6299922, lng: 20.6919806 },
    { lat: 48.6300988, lng: 20.6920911 },
    { lat: 48.630451, lng: 20.6921169 },
    { lat: 48.6312831, lng: 20.6923739 },
    { lat: 48.6318071, lng: 20.6924532 },
    { lat: 48.6319312, lng: 20.6923612 },
    { lat: 48.6325701, lng: 20.6925066 },
    { lat: 48.6328498, lng: 20.6912277 },
    { lat: 48.6286665, lng: 20.6878476 },
    { lat: 48.6305794, lng: 20.6852479 },
    { lat: 48.6326676, lng: 20.6864654 },
    { lat: 48.6330652, lng: 20.6867521 },
    { lat: 48.6351389, lng: 20.6907999 },
    { lat: 48.6351294, lng: 20.692359 },
    { lat: 48.6346741, lng: 20.6935028 },
    { lat: 48.6350044, lng: 20.6934242 },
    { lat: 48.6351099, lng: 20.6934786 },
    { lat: 48.6352205, lng: 20.6934497 },
    { lat: 48.6353826, lng: 20.6932933 },
    { lat: 48.6362293, lng: 20.6931107 },
    { lat: 48.636779, lng: 20.6928345 },
    { lat: 48.6400969, lng: 20.6920821 },
    { lat: 48.6395824, lng: 20.6859934 },
    { lat: 48.6401134, lng: 20.6839188 },
    { lat: 48.6404051, lng: 20.6834161 },
    { lat: 48.6410966, lng: 20.6818595 },
    { lat: 48.6443898, lng: 20.6812055 },
    { lat: 48.6457329, lng: 20.680235 },
    { lat: 48.6475543, lng: 20.6796337 },
    { lat: 48.6477051, lng: 20.6802591 },
    { lat: 48.64872, lng: 20.6800556 },
    { lat: 48.6487117, lng: 20.6798826 },
    { lat: 48.6490181, lng: 20.6795719 },
    { lat: 48.6493818, lng: 20.6793274 },
    { lat: 48.6495625, lng: 20.6792824 },
    { lat: 48.6497679, lng: 20.6791096 },
    { lat: 48.6502304, lng: 20.6790066 },
    { lat: 48.6506326, lng: 20.6787625 },
    { lat: 48.6509357, lng: 20.6784849 },
    { lat: 48.6510951, lng: 20.6785672 },
    { lat: 48.6512205, lng: 20.6788026 },
    { lat: 48.6514247, lng: 20.6788767 },
    { lat: 48.6517201, lng: 20.6788262 },
    { lat: 48.6519126, lng: 20.6785426 },
    { lat: 48.652075, lng: 20.6784553 },
    { lat: 48.6522345, lng: 20.6785391 },
    { lat: 48.6526822, lng: 20.678465 },
    { lat: 48.6529734, lng: 20.6786124 },
    { lat: 48.6532475, lng: 20.678565 },
    { lat: 48.6536661, lng: 20.678712 },
    { lat: 48.6539902, lng: 20.6786463 },
    { lat: 48.6545239, lng: 20.6788048 },
    { lat: 48.6553447, lng: 20.6791866 },
    { lat: 48.6559253, lng: 20.68002 },
    { lat: 48.6563979, lng: 20.6802303 },
    { lat: 48.6568493, lng: 20.6806413 },
    { lat: 48.6573813, lng: 20.6812857 },
    { lat: 48.6580025, lng: 20.6815279 },
    { lat: 48.65851, lng: 20.6820494 },
    { lat: 48.658875, lng: 20.6822467 },
    { lat: 48.6594261, lng: 20.6826987 },
    { lat: 48.659778, lng: 20.6830659 },
    { lat: 48.6604377, lng: 20.6845639 },
    { lat: 48.6604653, lng: 20.6850524 },
    { lat: 48.6606268, lng: 20.6854744 },
    { lat: 48.6607249, lng: 20.6858873 },
    { lat: 48.6611177, lng: 20.6862316 },
    { lat: 48.6613212, lng: 20.6867855 },
    { lat: 48.6615956, lng: 20.6872157 },
    { lat: 48.6622412, lng: 20.6887716 },
    { lat: 48.6632741, lng: 20.6906659 },
    { lat: 48.6634788, lng: 20.6905989 },
    { lat: 48.6643135, lng: 20.6909567 },
    { lat: 48.6648858, lng: 20.6913762 },
    { lat: 48.6654564, lng: 20.6918406 },
    { lat: 48.6671372, lng: 20.6935722 },
    { lat: 48.6677579, lng: 20.6940518 },
    { lat: 48.6682818, lng: 20.6946313 },
    { lat: 48.6695101, lng: 20.6953197 },
    { lat: 48.6699876, lng: 20.6957003 },
    { lat: 48.6721807, lng: 20.696462 },
    { lat: 48.6732086, lng: 20.6965866 },
    { lat: 48.6751572, lng: 20.6966798 },
    { lat: 48.6756075, lng: 20.6965525 },
    { lat: 48.6766778, lng: 20.69698 },
    { lat: 48.6772133, lng: 20.6978807 },
    { lat: 48.678406, lng: 20.6973933 },
    { lat: 48.6790926, lng: 20.6969144 },
    { lat: 48.6795965, lng: 20.6971245 },
    { lat: 48.680905, lng: 20.698685 },
    { lat: 48.680994, lng: 20.698375 },
    { lat: 48.6809876, lng: 20.6982848 },
    { lat: 48.6808, lng: 20.697669 },
    { lat: 48.680758, lng: 20.697369 },
    { lat: 48.68077, lng: 20.696964 },
    { lat: 48.680793, lng: 20.696836 },
    { lat: 48.680895, lng: 20.696483 },
    { lat: 48.680892, lng: 20.696256 },
    { lat: 48.680805, lng: 20.69588 },
    { lat: 48.680812, lng: 20.695522 },
    { lat: 48.680825, lng: 20.695336 },
    { lat: 48.680876, lng: 20.69464 },
    { lat: 48.680794, lng: 20.694121 },
    { lat: 48.680506, lng: 20.693061 },
    { lat: 48.680512, lng: 20.692582 },
    { lat: 48.680639, lng: 20.690961 },
    { lat: 48.6807, lng: 20.690524 },
    { lat: 48.68086, lng: 20.687829 },
    { lat: 48.680881, lng: 20.687832 },
    { lat: 48.681181, lng: 20.685919 },
    { lat: 48.68263, lng: 20.682814 },
    { lat: 48.682655, lng: 20.682782 },
    { lat: 48.682724, lng: 20.682641 },
    { lat: 48.682747, lng: 20.682594 },
    { lat: 48.683338, lng: 20.681392 },
    { lat: 48.683897, lng: 20.680165 },
    { lat: 48.68438, lng: 20.678267 },
    { lat: 48.6854262, lng: 20.6777672 },
    { lat: 48.6865, lng: 20.677313 },
    { lat: 48.687893, lng: 20.677787 },
    { lat: 48.685672, lng: 20.673433 },
    { lat: 48.684207, lng: 20.6706 },
    { lat: 48.684162, lng: 20.668313 },
    { lat: 48.6841637, lng: 20.6682108 },
    { lat: 48.684227, lng: 20.667773 },
    { lat: 48.684433, lng: 20.66725 },
    { lat: 48.68455, lng: 20.666844 },
    { lat: 48.684655, lng: 20.666568 },
    { lat: 48.68472, lng: 20.666264 },
    { lat: 48.684725, lng: 20.666036 },
    { lat: 48.684653, lng: 20.665572 },
    { lat: 48.684653, lng: 20.665375 },
    { lat: 48.684848, lng: 20.664881 },
    { lat: 48.685007, lng: 20.664662 },
    { lat: 48.685147, lng: 20.664509 },
    { lat: 48.685263, lng: 20.664347 },
    { lat: 48.685374, lng: 20.663875 },
    { lat: 48.685471, lng: 20.663617 },
    { lat: 48.685582, lng: 20.663427 },
    { lat: 48.685692, lng: 20.663096 },
    { lat: 48.685749, lng: 20.662915 },
    { lat: 48.685786, lng: 20.662673 },
    { lat: 48.685815, lng: 20.662314 },
    { lat: 48.685869, lng: 20.662015 },
    { lat: 48.685935, lng: 20.661291 },
    { lat: 48.685812, lng: 20.660567 },
    { lat: 48.685757, lng: 20.659753 },
    { lat: 48.685771, lng: 20.659499 },
    { lat: 48.685836, lng: 20.659132 },
    { lat: 48.686045, lng: 20.658668 },
    { lat: 48.686243, lng: 20.658352 },
    { lat: 48.686435, lng: 20.658178 },
    { lat: 48.686596, lng: 20.658076 },
    { lat: 48.686784, lng: 20.658022 },
    { lat: 48.686792, lng: 20.658042 },
    { lat: 48.687068, lng: 20.657614 },
    { lat: 48.688531, lng: 20.655181 },
    { lat: 48.6872159, lng: 20.6533873 },
    { lat: 48.6840709, lng: 20.6505707 },
    { lat: 48.6838053, lng: 20.6501672 },
    { lat: 48.6835971, lng: 20.6493945 },
    { lat: 48.68348, lng: 20.6492103 },
    { lat: 48.6832254, lng: 20.64827 },
    { lat: 48.6829777, lng: 20.6477115 },
    { lat: 48.6827777, lng: 20.6475356 },
    { lat: 48.6826273, lng: 20.6472089 },
    { lat: 48.6823024, lng: 20.6462552 },
    { lat: 48.6821347, lng: 20.6459167 },
    { lat: 48.6819393, lng: 20.645155 },
    { lat: 48.6813111, lng: 20.6442521 },
    { lat: 48.6811966, lng: 20.6440143 },
    { lat: 48.6810088, lng: 20.6434295 },
    { lat: 48.6807673, lng: 20.6429485 },
    { lat: 48.6806105, lng: 20.6423786 },
    { lat: 48.680112, lng: 20.6412659 },
    { lat: 48.6799375, lng: 20.6406176 },
    { lat: 48.6795535, lng: 20.6402753 },
    { lat: 48.6787734, lng: 20.6401802 },
    { lat: 48.6776886, lng: 20.6398545 },
    { lat: 48.6765494, lng: 20.63933 },
    { lat: 48.6753214, lng: 20.6381915 },
    { lat: 48.6737835, lng: 20.6394359 },
    { lat: 48.6728829, lng: 20.6399022 },
    { lat: 48.6721443, lng: 20.63987 },
    { lat: 48.6707203, lng: 20.6410222 },
    { lat: 48.669731, lng: 20.6412814 },
    { lat: 48.6690879, lng: 20.6413388 },
    { lat: 48.6681309, lng: 20.6416167 },
    { lat: 48.6666859, lng: 20.6432182 },
    { lat: 48.6662821, lng: 20.6435664 },
    { lat: 48.66529, lng: 20.6439358 },
    { lat: 48.6648311, lng: 20.6442601 },
    { lat: 48.6644448, lng: 20.644381 },
    { lat: 48.6637923, lng: 20.6443295 },
    { lat: 48.6627443, lng: 20.6445831 },
    { lat: 48.6620833, lng: 20.6445195 },
    { lat: 48.6614459, lng: 20.6445468 },
    { lat: 48.6597724, lng: 20.6447221 },
    { lat: 48.6583133, lng: 20.6453091 },
    { lat: 48.6573184, lng: 20.6451655 },
    { lat: 48.6569567, lng: 20.6454649 },
    { lat: 48.656139, lng: 20.6455819 },
    { lat: 48.6543827, lng: 20.646014 },
    { lat: 48.65329, lng: 20.6443522 },
    { lat: 48.6526935, lng: 20.6438606 },
    { lat: 48.652473, lng: 20.6435584 },
    { lat: 48.6523742, lng: 20.6417957 },
    { lat: 48.6522371, lng: 20.6405017 },
    { lat: 48.65188, lng: 20.6397169 },
    { lat: 48.6513471, lng: 20.6391474 },
    { lat: 48.651147, lng: 20.6384126 },
    { lat: 48.650689, lng: 20.6377095 },
    { lat: 48.6505641, lng: 20.6373357 },
    { lat: 48.6503382, lng: 20.636326 },
    { lat: 48.6502212, lng: 20.6354879 },
    { lat: 48.6501208, lng: 20.6342774 },
    { lat: 48.6501459, lng: 20.6333923 },
    { lat: 48.6503064, lng: 20.6323806 },
    { lat: 48.6496562, lng: 20.631007 },
    { lat: 48.6482012, lng: 20.6303763 },
    { lat: 48.6476429, lng: 20.6303794 },
    { lat: 48.6470509, lng: 20.6300792 },
    { lat: 48.6470762, lng: 20.6290371 },
    { lat: 48.6473487, lng: 20.6272172 },
    { lat: 48.6465363, lng: 20.6266106 },
    { lat: 48.64619, lng: 20.6264367 },
    { lat: 48.6453994, lng: 20.626184 },
    { lat: 48.6452682, lng: 20.6260471 },
    { lat: 48.6442079, lng: 20.6248469 },
    { lat: 48.6427266, lng: 20.6233087 },
    { lat: 48.6418107, lng: 20.6226157 },
    { lat: 48.6411896, lng: 20.6226911 },
    { lat: 48.6409206, lng: 20.6230317 },
    { lat: 48.640863, lng: 20.6238926 },
    { lat: 48.6407584, lng: 20.624142 },
    { lat: 48.6407692, lng: 20.6248716 },
    { lat: 48.6408908, lng: 20.6259826 },
    { lat: 48.6404933, lng: 20.6260081 },
    { lat: 48.6398068, lng: 20.6258671 },
    { lat: 48.6395144, lng: 20.6258728 },
    { lat: 48.6395931, lng: 20.6300734 },
    { lat: 48.6396558, lng: 20.6307805 },
    { lat: 48.6392415, lng: 20.6308715 },
    { lat: 48.6393317, lng: 20.6311242 },
    { lat: 48.6393583, lng: 20.6315442 },
    { lat: 48.6391568, lng: 20.6334637 },
    { lat: 48.6388828, lng: 20.6340143 },
    { lat: 48.6387075, lng: 20.6345122 },
    { lat: 48.6382806, lng: 20.6344778 },
    { lat: 48.63822, lng: 20.6346398 },
    { lat: 48.6382201, lng: 20.6355383 },
    { lat: 48.6382535, lng: 20.6356547 },
    { lat: 48.6382075, lng: 20.635692 },
    { lat: 48.6375491, lng: 20.6345191 },
    { lat: 48.6374979, lng: 20.6343503 },
    { lat: 48.6374489, lng: 20.6347346 },
    { lat: 48.6374898, lng: 20.6352441 },
    { lat: 48.6370679, lng: 20.6357165 },
    { lat: 48.6367712, lng: 20.6359134 },
    { lat: 48.6365607, lng: 20.635855 },
    { lat: 48.6365002, lng: 20.6361102 },
    { lat: 48.6358723, lng: 20.6367061 },
    { lat: 48.6352437, lng: 20.637197 },
    { lat: 48.6347216, lng: 20.6377296 },
    { lat: 48.6338732, lng: 20.6388897 },
    { lat: 48.6330438, lng: 20.6394921 },
    { lat: 48.6321248, lng: 20.6389256 },
    { lat: 48.6311145, lng: 20.6384799 },
    { lat: 48.6301363, lng: 20.6381745 },
    { lat: 48.6281681, lng: 20.6418544 },
    { lat: 48.6254596, lng: 20.6438824 },
    { lat: 48.6248001, lng: 20.6451668 },
    { lat: 48.6247564, lng: 20.6447672 },
    { lat: 48.6247101, lng: 20.6447924 },
    { lat: 48.6239075, lng: 20.6457618 },
    { lat: 48.6235019, lng: 20.6470238 },
    { lat: 48.6232564, lng: 20.6482963 },
    { lat: 48.6231062, lng: 20.6487454 },
    { lat: 48.6229296, lng: 20.6490934 },
    { lat: 48.6225963, lng: 20.6494031 },
    { lat: 48.6223702, lng: 20.6495032 },
    { lat: 48.6213321, lng: 20.6495621 },
    { lat: 48.6206499, lng: 20.6499667 },
    { lat: 48.6205192, lng: 20.6501818 },
    { lat: 48.6203044, lng: 20.6503165 },
    { lat: 48.6199902, lng: 20.6514327 },
    { lat: 48.6198406, lng: 20.6523037 },
    { lat: 48.6198084, lng: 20.6531615 },
    { lat: 48.6194419, lng: 20.6560971 },
    { lat: 48.6185702, lng: 20.6570837 },
    { lat: 48.6165144, lng: 20.6588904 },
    { lat: 48.6162864, lng: 20.6590913 },
    { lat: 48.6165604, lng: 20.6604124 },
    { lat: 48.6169529, lng: 20.6623026 },
    { lat: 48.6161108, lng: 20.6650177 },
    { lat: 48.6158256, lng: 20.6672216 },
    { lat: 48.615475, lng: 20.668986 },
    { lat: 48.6156686, lng: 20.6698593 },
    { lat: 48.6157064, lng: 20.6712812 },
    { lat: 48.615921, lng: 20.6724243 },
    { lat: 48.6157422, lng: 20.6733029 },
    { lat: 48.6158381, lng: 20.6742144 },
    { lat: 48.6153911, lng: 20.6758736 },
    { lat: 48.6149582, lng: 20.676858 },
    { lat: 48.6145515, lng: 20.6771001 },
    { lat: 48.6144156, lng: 20.677735 },
  ],
  Brdárka: [
    { lat: 48.7678488, lng: 20.324135 },
    { lat: 48.7676046, lng: 20.3240493 },
    { lat: 48.7672043, lng: 20.3240407 },
    { lat: 48.7668516, lng: 20.3238855 },
    { lat: 48.766428, lng: 20.3244601 },
    { lat: 48.7653619, lng: 20.3251038 },
    { lat: 48.7650643, lng: 20.3253876 },
    { lat: 48.7641773, lng: 20.3251333 },
    { lat: 48.7640849, lng: 20.3250209 },
    { lat: 48.7639098, lng: 20.324979 },
    { lat: 48.7635386, lng: 20.3247554 },
    { lat: 48.7633372, lng: 20.3244985 },
    { lat: 48.7630095, lng: 20.324355 },
    { lat: 48.7627447, lng: 20.3241226 },
    { lat: 48.7623503, lng: 20.3240225 },
    { lat: 48.7619383, lng: 20.3240007 },
    { lat: 48.7609096, lng: 20.3241623 },
    { lat: 48.7602093, lng: 20.3243686 },
    { lat: 48.7596553, lng: 20.324364 },
    { lat: 48.7595626, lng: 20.3241292 },
    { lat: 48.759059, lng: 20.3241784 },
    { lat: 48.7587523, lng: 20.3242825 },
    { lat: 48.758394, lng: 20.3241031 },
    { lat: 48.7576991, lng: 20.324129 },
    { lat: 48.7572064, lng: 20.3240337 },
    { lat: 48.7566035, lng: 20.3243972 },
    { lat: 48.7564183, lng: 20.3244412 },
    { lat: 48.7554066, lng: 20.3241109 },
    { lat: 48.7547745, lng: 20.32381 },
    { lat: 48.7542039, lng: 20.3237081 },
    { lat: 48.753391, lng: 20.3234476 },
    { lat: 48.753307, lng: 20.323489 },
    { lat: 48.7532004, lng: 20.3238421 },
    { lat: 48.7528532, lng: 20.323635 },
    { lat: 48.7524295, lng: 20.323165 },
    { lat: 48.7520997, lng: 20.3230527 },
    { lat: 48.7514542, lng: 20.32296 },
    { lat: 48.7512172, lng: 20.3230907 },
    { lat: 48.7502421, lng: 20.3233264 },
    { lat: 48.7492686, lng: 20.323831 },
    { lat: 48.7489967, lng: 20.3238795 },
    { lat: 48.7484112, lng: 20.3241693 },
    { lat: 48.748119, lng: 20.3241839 },
    { lat: 48.747703, lng: 20.3240814 },
    { lat: 48.7474068, lng: 20.3241103 },
    { lat: 48.7471025, lng: 20.323933 },
    { lat: 48.7454929, lng: 20.3237499 },
    { lat: 48.7448273, lng: 20.3238071 },
    { lat: 48.7444601, lng: 20.32357 },
    { lat: 48.7443366, lng: 20.3235257 },
    { lat: 48.7441597, lng: 20.3235754 },
    { lat: 48.7439254, lng: 20.3234311 },
    { lat: 48.7433648, lng: 20.3235242 },
    { lat: 48.7430166, lng: 20.3238353 },
    { lat: 48.7429224, lng: 20.3239921 },
    { lat: 48.7427744, lng: 20.3241551 },
    { lat: 48.7430165, lng: 20.3245839 },
    { lat: 48.743274, lng: 20.3246894 },
    { lat: 48.7437276, lng: 20.324715 },
    { lat: 48.7438222, lng: 20.3249449 },
    { lat: 48.7444789, lng: 20.3257317 },
    { lat: 48.7445654, lng: 20.3257525 },
    { lat: 48.7448575, lng: 20.3261783 },
    { lat: 48.7448719, lng: 20.3262251 },
    { lat: 48.745427, lng: 20.3264815 },
    { lat: 48.745784, lng: 20.3269696 },
    { lat: 48.7460395, lng: 20.3271991 },
    { lat: 48.7460833, lng: 20.327718 },
    { lat: 48.7462316, lng: 20.3285219 },
    { lat: 48.7471202, lng: 20.3309798 },
    { lat: 48.7470986, lng: 20.3319612 },
    { lat: 48.7471768, lng: 20.333013 },
    { lat: 48.7473998, lng: 20.3339723 },
    { lat: 48.7473245, lng: 20.3346434 },
    { lat: 48.7475567, lng: 20.3352579 },
    { lat: 48.7479225, lng: 20.3357996 },
    { lat: 48.7484354, lng: 20.3364216 },
    { lat: 48.7493121, lng: 20.3371038 },
    { lat: 48.7498207, lng: 20.3385156 },
    { lat: 48.7495166, lng: 20.3393844 },
    { lat: 48.7495056, lng: 20.3394171 },
    { lat: 48.749813, lng: 20.339246 },
    { lat: 48.7502327, lng: 20.3389761 },
    { lat: 48.7506445, lng: 20.3390816 },
    { lat: 48.7512569, lng: 20.3389904 },
    { lat: 48.7515962, lng: 20.3390263 },
    { lat: 48.7522873, lng: 20.3389646 },
    { lat: 48.7528432, lng: 20.3395879 },
    { lat: 48.7533665, lng: 20.3398419 },
    { lat: 48.7540038, lng: 20.3404777 },
    { lat: 48.7541895, lng: 20.3408545 },
    { lat: 48.7542099, lng: 20.3411196 },
    { lat: 48.7544045, lng: 20.341865 },
    { lat: 48.7542627, lng: 20.3420428 },
    { lat: 48.7542652, lng: 20.3422221 },
    { lat: 48.7541645, lng: 20.3424126 },
    { lat: 48.7540277, lng: 20.3430404 },
    { lat: 48.7541248, lng: 20.3431881 },
    { lat: 48.7541775, lng: 20.3431538 },
    { lat: 48.7543769, lng: 20.3433613 },
    { lat: 48.7547806, lng: 20.3434015 },
    { lat: 48.755215, lng: 20.3439497 },
    { lat: 48.7553919, lng: 20.3443868 },
    { lat: 48.7554594, lng: 20.344909 },
    { lat: 48.7554572, lng: 20.345462 },
    { lat: 48.7560443, lng: 20.3465745 },
    { lat: 48.7570194, lng: 20.3473014 },
    { lat: 48.758068, lng: 20.3479754 },
    { lat: 48.7583573, lng: 20.3482321 },
    { lat: 48.7589802, lng: 20.3485591 },
    { lat: 48.7593528, lng: 20.3490458 },
    { lat: 48.7605943, lng: 20.3502538 },
    { lat: 48.7606735, lng: 20.3509035 },
    { lat: 48.7608972, lng: 20.3517396 },
    { lat: 48.7610281, lng: 20.3534616 },
    { lat: 48.7611579, lng: 20.3537317 },
    { lat: 48.7614054, lng: 20.3540424 },
    { lat: 48.7627347, lng: 20.3544105 },
    { lat: 48.7646514, lng: 20.3550469 },
    { lat: 48.765644, lng: 20.355503 },
    { lat: 48.7659437, lng: 20.3555946 },
    { lat: 48.7663334, lng: 20.3555666 },
    { lat: 48.7672429, lng: 20.3559651 },
    { lat: 48.7678824, lng: 20.3560749 },
    { lat: 48.7682782, lng: 20.3565539 },
    { lat: 48.7687554, lng: 20.3569282 },
    { lat: 48.7691553, lng: 20.3571349 },
    { lat: 48.7698069, lng: 20.3572711 },
    { lat: 48.7702249, lng: 20.3572494 },
    { lat: 48.7706677, lng: 20.3574277 },
    { lat: 48.7707784, lng: 20.3573208 },
    { lat: 48.7712699, lng: 20.357222 },
    { lat: 48.7714181, lng: 20.3565074 },
    { lat: 48.7718768, lng: 20.3560259 },
    { lat: 48.7718365, lng: 20.3558859 },
    { lat: 48.7724947, lng: 20.3552911 },
    { lat: 48.7727214, lng: 20.3552082 },
    { lat: 48.7729747, lng: 20.3553829 },
    { lat: 48.7731681, lng: 20.355344 },
    { lat: 48.7736431, lng: 20.3549902 },
    { lat: 48.7737164, lng: 20.3549377 },
    { lat: 48.7735662, lng: 20.3539657 },
    { lat: 48.7749863, lng: 20.3517531 },
    { lat: 48.7752362, lng: 20.3514904 },
    { lat: 48.7757527, lng: 20.3512772 },
    { lat: 48.776425, lng: 20.3507531 },
    { lat: 48.7765703, lng: 20.3507267 },
    { lat: 48.7773293, lng: 20.3506391 },
    { lat: 48.7776551, lng: 20.3507651 },
    { lat: 48.7779111, lng: 20.3510381 },
    { lat: 48.7781447, lng: 20.3514905 },
    { lat: 48.7782798, lng: 20.3511089 },
    { lat: 48.7783081, lng: 20.3500039 },
    { lat: 48.7781148, lng: 20.3493137 },
    { lat: 48.7780379, lng: 20.3484694 },
    { lat: 48.7779359, lng: 20.3479857 },
    { lat: 48.7777108, lng: 20.3473765 },
    { lat: 48.7772641, lng: 20.3455553 },
    { lat: 48.7767559, lng: 20.3431354 },
    { lat: 48.7765773, lng: 20.342825 },
    { lat: 48.776568, lng: 20.3418304 },
    { lat: 48.7767499, lng: 20.3414404 },
    { lat: 48.7769463, lng: 20.3402619 },
    { lat: 48.7770602, lng: 20.3400368 },
    { lat: 48.7774036, lng: 20.3396843 },
    { lat: 48.7772195, lng: 20.338596 },
    { lat: 48.7769965, lng: 20.3367099 },
    { lat: 48.7770086, lng: 20.3362379 },
    { lat: 48.7771242, lng: 20.3355639 },
    { lat: 48.7771314, lng: 20.335027 },
    { lat: 48.7772572, lng: 20.3340014 },
    { lat: 48.7776126, lng: 20.3323364 },
    { lat: 48.7778009, lng: 20.3318212 },
    { lat: 48.7778649, lng: 20.3314699 },
    { lat: 48.7773869, lng: 20.3303017 },
    { lat: 48.7771881, lng: 20.3300205 },
    { lat: 48.7768173, lng: 20.3292342 },
    { lat: 48.7766216, lng: 20.3283708 },
    { lat: 48.7764305, lng: 20.3280315 },
    { lat: 48.7761443, lng: 20.3270441 },
    { lat: 48.7757853, lng: 20.3267572 },
    { lat: 48.7753335, lng: 20.3264333 },
    { lat: 48.7743706, lng: 20.3261439 },
    { lat: 48.7739185, lng: 20.3257803 },
    { lat: 48.7731688, lng: 20.3257925 },
    { lat: 48.7727308, lng: 20.3258783 },
    { lat: 48.7720036, lng: 20.3257689 },
    { lat: 48.7714696, lng: 20.3259086 },
    { lat: 48.7702473, lng: 20.325638 },
    { lat: 48.7692138, lng: 20.3255255 },
    { lat: 48.7690277, lng: 20.325429 },
    { lat: 48.7681257, lng: 20.3256328 },
    { lat: 48.7676104, lng: 20.3255856 },
    { lat: 48.767358, lng: 20.3256734 },
    { lat: 48.767655, lng: 20.3248541 },
    { lat: 48.7678417, lng: 20.3241588 },
    { lat: 48.7678488, lng: 20.324135 },
  ],
  Slavec: [
    { lat: 48.5737942, lng: 20.4496072 },
    { lat: 48.5734023, lng: 20.4486572 },
    { lat: 48.573187, lng: 20.4479163 },
    { lat: 48.5725977, lng: 20.4469598 },
    { lat: 48.5724257, lng: 20.4464637 },
    { lat: 48.5722049, lng: 20.44477 },
    { lat: 48.5722679, lng: 20.4437046 },
    { lat: 48.5725868, lng: 20.4432063 },
    { lat: 48.5727415, lng: 20.4415058 },
    { lat: 48.5723174, lng: 20.4411616 },
    { lat: 48.5721114, lng: 20.4407168 },
    { lat: 48.5714698, lng: 20.439866 },
    { lat: 48.5708433, lng: 20.4386772 },
    { lat: 48.5699714, lng: 20.4376294 },
    { lat: 48.5687796, lng: 20.4360224 },
    { lat: 48.5684634, lng: 20.4353785 },
    { lat: 48.5684328, lng: 20.4348435 },
    { lat: 48.5680774, lng: 20.4343222 },
    { lat: 48.5678397, lng: 20.4338503 },
    { lat: 48.5676855, lng: 20.4322019 },
    { lat: 48.5677464, lng: 20.4309917 },
    { lat: 48.5661898, lng: 20.4296252 },
    { lat: 48.5655823, lng: 20.4289917 },
    { lat: 48.5651312, lng: 20.4288278 },
    { lat: 48.5643079, lng: 20.4279066 },
    { lat: 48.5640456, lng: 20.4277068 },
    { lat: 48.5637863, lng: 20.4273432 },
    { lat: 48.5624864, lng: 20.4258608 },
    { lat: 48.5621366, lng: 20.4272221 },
    { lat: 48.5614811, lng: 20.4278421 },
    { lat: 48.5612204, lng: 20.4278846 },
    { lat: 48.5610128, lng: 20.4281088 },
    { lat: 48.5608668, lng: 20.4280603 },
    { lat: 48.5602837, lng: 20.4286235 },
    { lat: 48.5602106, lng: 20.4288033 },
    { lat: 48.5600192, lng: 20.4289813 },
    { lat: 48.5598902, lng: 20.4293778 },
    { lat: 48.55948, lng: 20.4299871 },
    { lat: 48.5594105, lng: 20.4301773 },
    { lat: 48.5588008, lng: 20.4310495 },
    { lat: 48.5586515, lng: 20.4314385 },
    { lat: 48.5589163, lng: 20.431652 },
    { lat: 48.5589857, lng: 20.4318173 },
    { lat: 48.558607, lng: 20.4323702 },
    { lat: 48.558786, lng: 20.4328747 },
    { lat: 48.5590797, lng: 20.4342257 },
    { lat: 48.5593692, lng: 20.4350797 },
    { lat: 48.5593626, lng: 20.4358844 },
    { lat: 48.55944, lng: 20.4359321 },
    { lat: 48.5594795, lng: 20.4361445 },
    { lat: 48.5594643, lng: 20.436663 },
    { lat: 48.5595085, lng: 20.4367569 },
    { lat: 48.5595133, lng: 20.4370889 },
    { lat: 48.559665, lng: 20.4383189 },
    { lat: 48.5602404, lng: 20.4408303 },
    { lat: 48.5607775, lng: 20.442604 },
    { lat: 48.5602562, lng: 20.443022 },
    { lat: 48.5599838, lng: 20.4430828 },
    { lat: 48.5599367, lng: 20.4433574 },
    { lat: 48.5597873, lng: 20.4436607 },
    { lat: 48.5594121, lng: 20.444365 },
    { lat: 48.5588336, lng: 20.4452621 },
    { lat: 48.5586041, lng: 20.4454739 },
    { lat: 48.5584276, lng: 20.4457465 },
    { lat: 48.5581245, lng: 20.4460044 },
    { lat: 48.5574566, lng: 20.4468868 },
    { lat: 48.5572875, lng: 20.4471811 },
    { lat: 48.5564572, lng: 20.4489771 },
    { lat: 48.5566191, lng: 20.4491199 },
    { lat: 48.556698, lng: 20.4497739 },
    { lat: 48.5565794, lng: 20.4502355 },
    { lat: 48.5562329, lng: 20.4508348 },
    { lat: 48.5561521, lng: 20.4514333 },
    { lat: 48.5562642, lng: 20.4527893 },
    { lat: 48.5562898, lng: 20.4537852 },
    { lat: 48.556856, lng: 20.4552915 },
    { lat: 48.5577663, lng: 20.4555699 },
    { lat: 48.5579291, lng: 20.4557393 },
    { lat: 48.5581507, lng: 20.4563241 },
    { lat: 48.5578995, lng: 20.4578675 },
    { lat: 48.5579114, lng: 20.4587715 },
    { lat: 48.5579286, lng: 20.4591084 },
    { lat: 48.5582926, lng: 20.4605672 },
    { lat: 48.5583121, lng: 20.4610809 },
    { lat: 48.5582361, lng: 20.4614223 },
    { lat: 48.5582079, lng: 20.461574 },
    { lat: 48.5580308, lng: 20.4620702 },
    { lat: 48.5575778, lng: 20.4629246 },
    { lat: 48.5571147, lng: 20.4635374 },
    { lat: 48.556845, lng: 20.4646188 },
    { lat: 48.5574491, lng: 20.4662158 },
    { lat: 48.5574286, lng: 20.4664728 },
    { lat: 48.5568565, lng: 20.4677835 },
    { lat: 48.5568008, lng: 20.4686001 },
    { lat: 48.5567037, lng: 20.4692437 },
    { lat: 48.5566951, lng: 20.4703566 },
    { lat: 48.5564947, lng: 20.4723817 },
    { lat: 48.5564989, lng: 20.4732682 },
    { lat: 48.5564614, lng: 20.4736162 },
    { lat: 48.5565045, lng: 20.474071 },
    { lat: 48.5564221, lng: 20.4746941 },
    { lat: 48.556566, lng: 20.4759973 },
    { lat: 48.5570637, lng: 20.4767103 },
    { lat: 48.5571987, lng: 20.477486 },
    { lat: 48.5572144, lng: 20.480346 },
    { lat: 48.5573278, lng: 20.4806626 },
    { lat: 48.5573681, lng: 20.4811053 },
    { lat: 48.5572874, lng: 20.4811168 },
    { lat: 48.5573478, lng: 20.4824175 },
    { lat: 48.5574294, lng: 20.4836841 },
    { lat: 48.5571824, lng: 20.484268 },
    { lat: 48.5581599, lng: 20.484099 },
    { lat: 48.5599173, lng: 20.4849549 },
    { lat: 48.5617059, lng: 20.4853264 },
    { lat: 48.5631283, lng: 20.4858972 },
    { lat: 48.5652639, lng: 20.4867856 },
    { lat: 48.5676696, lng: 20.4879581 },
    { lat: 48.570045, lng: 20.4883984 },
    { lat: 48.5719116, lng: 20.4884158 },
    { lat: 48.5740581, lng: 20.4895008 },
    { lat: 48.5754001, lng: 20.4902174 },
    { lat: 48.5762324, lng: 20.4916553 },
    { lat: 48.5767728, lng: 20.4917446 },
    { lat: 48.5784645, lng: 20.4938505 },
    { lat: 48.579101, lng: 20.494518 },
    { lat: 48.579922, lng: 20.4955677 },
    { lat: 48.582008, lng: 20.4973244 },
    { lat: 48.5828129, lng: 20.4979797 },
    { lat: 48.5857423, lng: 20.4991913 },
    { lat: 48.5872515, lng: 20.4991742 },
    { lat: 48.588209, lng: 20.4993009 },
    { lat: 48.5894523, lng: 20.4993402 },
    { lat: 48.5935729, lng: 20.4998016 },
    { lat: 48.5957547, lng: 20.4999146 },
    { lat: 48.5978846, lng: 20.4999003 },
    { lat: 48.5992287, lng: 20.4995586 },
    { lat: 48.6001317, lng: 20.4996783 },
    { lat: 48.6018227, lng: 20.4986847 },
    { lat: 48.6035547, lng: 20.4977876 },
    { lat: 48.6066746, lng: 20.4960158 },
    { lat: 48.6054579, lng: 20.4935449 },
    { lat: 48.6051099, lng: 20.4930929 },
    { lat: 48.6038563, lng: 20.4930194 },
    { lat: 48.60438, lng: 20.4847981 },
    { lat: 48.6046483, lng: 20.4833129 },
    { lat: 48.6046358, lng: 20.4801713 },
    { lat: 48.6048236, lng: 20.4785261 },
    { lat: 48.6043823, lng: 20.4775133 },
    { lat: 48.603693, lng: 20.4766094 },
    { lat: 48.6041317, lng: 20.4753799 },
    { lat: 48.6037755, lng: 20.4731904 },
    { lat: 48.6028511, lng: 20.466048 },
    { lat: 48.602577, lng: 20.4639554 },
    { lat: 48.6023391, lng: 20.4640645 },
    { lat: 48.601719, lng: 20.4642765 },
    { lat: 48.601491, lng: 20.4641763 },
    { lat: 48.6012346, lng: 20.4643044 },
    { lat: 48.6008815, lng: 20.4643451 },
    { lat: 48.6005975, lng: 20.4644574 },
    { lat: 48.599798, lng: 20.4644316 },
    { lat: 48.5994962, lng: 20.4647855 },
    { lat: 48.5993315, lng: 20.4648993 },
    { lat: 48.5987462, lng: 20.4639806 },
    { lat: 48.5984899, lng: 20.4637544 },
    { lat: 48.5979759, lng: 20.4631465 },
    { lat: 48.5976158, lng: 20.4628837 },
    { lat: 48.5963279, lng: 20.4621188 },
    { lat: 48.5960528, lng: 20.4620496 },
    { lat: 48.5958213, lng: 20.4622684 },
    { lat: 48.5956474, lng: 20.4622296 },
    { lat: 48.5948269, lng: 20.461761 },
    { lat: 48.5945894, lng: 20.4611095 },
    { lat: 48.5941015, lng: 20.460301 },
    { lat: 48.5933146, lng: 20.4593565 },
    { lat: 48.5929012, lng: 20.4589951 },
    { lat: 48.5927532, lng: 20.4587702 },
    { lat: 48.5923842, lng: 20.4573586 },
    { lat: 48.5895308, lng: 20.4566129 },
    { lat: 48.5880284, lng: 20.4540826 },
    { lat: 48.5874965, lng: 20.4528008 },
    { lat: 48.5872626, lng: 20.4521207 },
    { lat: 48.586888, lng: 20.4513346 },
    { lat: 48.5851575, lng: 20.4502747 },
    { lat: 48.5845197, lng: 20.4503166 },
    { lat: 48.5840537, lng: 20.4502384 },
    { lat: 48.5827164, lng: 20.4489426 },
    { lat: 48.5823014, lng: 20.4489269 },
    { lat: 48.5811829, lng: 20.4485032 },
    { lat: 48.5798497, lng: 20.4482097 },
    { lat: 48.5789207, lng: 20.4484759 },
    { lat: 48.5781104, lng: 20.4485441 },
    { lat: 48.577611, lng: 20.4487351 },
    { lat: 48.5770399, lng: 20.4484356 },
    { lat: 48.5761975, lng: 20.4488749 },
    { lat: 48.5757691, lng: 20.448834 },
    { lat: 48.5752297, lng: 20.4486224 },
    { lat: 48.5748646, lng: 20.4485611 },
    { lat: 48.5746248, lng: 20.4489737 },
    { lat: 48.5744171, lng: 20.4494944 },
    { lat: 48.5737942, lng: 20.4496072 },
  ],
  Rakovnica: [
    { lat: 48.6813393, lng: 20.4730094 },
    { lat: 48.6813302, lng: 20.4729817 },
    { lat: 48.6802685, lng: 20.4711328 },
    { lat: 48.6801736, lng: 20.470825 },
    { lat: 48.6793998, lng: 20.469864 },
    { lat: 48.6791896, lng: 20.4695161 },
    { lat: 48.6791761, lng: 20.4693961 },
    { lat: 48.6788328, lng: 20.4688887 },
    { lat: 48.6786095, lng: 20.4684059 },
    { lat: 48.6784335, lng: 20.46765 },
    { lat: 48.6779654, lng: 20.466827 },
    { lat: 48.6772959, lng: 20.4653747 },
    { lat: 48.6764923, lng: 20.4642286 },
    { lat: 48.6762187, lng: 20.4639252 },
    { lat: 48.6760384, lng: 20.4638071 },
    { lat: 48.6750011, lng: 20.4638616 },
    { lat: 48.6748892, lng: 20.4595926 },
    { lat: 48.6744734, lng: 20.4590727 },
    { lat: 48.6739077, lng: 20.458647 },
    { lat: 48.6734569, lng: 20.4582206 },
    { lat: 48.6724149, lng: 20.4582368 },
    { lat: 48.6718785, lng: 20.4580796 },
    { lat: 48.6714694, lng: 20.4570903 },
    { lat: 48.6714618, lng: 20.4568576 },
    { lat: 48.6710254, lng: 20.4560702 },
    { lat: 48.671023, lng: 20.4558264 },
    { lat: 48.670507, lng: 20.4541267 },
    { lat: 48.6704304, lng: 20.4541223 },
    { lat: 48.6704261, lng: 20.453568 },
    { lat: 48.6703816, lng: 20.4532833 },
    { lat: 48.6703166, lng: 20.4530256 },
    { lat: 48.6701917, lng: 20.4527885 },
    { lat: 48.6699046, lng: 20.45157 },
    { lat: 48.6698019, lng: 20.4509152 },
    { lat: 48.6693455, lng: 20.4509311 },
    { lat: 48.6693756, lng: 20.4508345 },
    { lat: 48.6693195, lng: 20.4508689 },
    { lat: 48.6693235, lng: 20.4507688 },
    { lat: 48.6694299, lng: 20.4507542 },
    { lat: 48.6694206, lng: 20.4505089 },
    { lat: 48.668852, lng: 20.449946 },
    { lat: 48.6686697, lng: 20.4495318 },
    { lat: 48.6684796, lng: 20.4497132 },
    { lat: 48.6680943, lng: 20.4495035 },
    { lat: 48.6673758, lng: 20.4500362 },
    { lat: 48.6666451, lng: 20.4502437 },
    { lat: 48.6663332, lng: 20.4505439 },
    { lat: 48.6663022, lng: 20.450735 },
    { lat: 48.6661025, lng: 20.4507501 },
    { lat: 48.6659209, lng: 20.4506842 },
    { lat: 48.6655954, lng: 20.4507747 },
    { lat: 48.6656279, lng: 20.4506476 },
    { lat: 48.6655845, lng: 20.4505747 },
    { lat: 48.6650753, lng: 20.4501208 },
    { lat: 48.6648638, lng: 20.4500368 },
    { lat: 48.6638892, lng: 20.4493118 },
    { lat: 48.6634793, lng: 20.4488026 },
    { lat: 48.6621948, lng: 20.4476278 },
    { lat: 48.6620094, lng: 20.4473995 },
    { lat: 48.6617904, lng: 20.44697 },
    { lat: 48.6614303, lng: 20.4465042 },
    { lat: 48.6612079, lng: 20.4464245 },
    { lat: 48.6610821, lng: 20.4462569 },
    { lat: 48.6608781, lng: 20.4461363 },
    { lat: 48.6603448, lng: 20.4447488 },
    { lat: 48.6594655, lng: 20.442056 },
    { lat: 48.6591532, lng: 20.4414256 },
    { lat: 48.6585117, lng: 20.4403371 },
    { lat: 48.6585307, lng: 20.4402161 },
    { lat: 48.658354, lng: 20.4400106 },
    { lat: 48.6581284, lng: 20.4395885 },
    { lat: 48.6578314, lng: 20.4387342 },
    { lat: 48.6577223, lng: 20.4381738 },
    { lat: 48.6569781, lng: 20.4360496 },
    { lat: 48.6568767, lng: 20.4355726 },
    { lat: 48.6565163, lng: 20.4346294 },
    { lat: 48.6559909, lng: 20.433931 },
    { lat: 48.6557147, lng: 20.4330906 },
    { lat: 48.6555013, lng: 20.4326917 },
    { lat: 48.654911, lng: 20.4319761 },
    { lat: 48.6537809, lng: 20.4310126 },
    { lat: 48.6532513, lng: 20.4304107 },
    { lat: 48.6530325, lng: 20.4305191 },
    { lat: 48.6518376, lng: 20.4301916 },
    { lat: 48.651352, lng: 20.4309225 },
    { lat: 48.6508892, lng: 20.4313106 },
    { lat: 48.6506091, lng: 20.4313484 },
    { lat: 48.6497442, lng: 20.431932 },
    { lat: 48.6491728, lng: 20.4325988 },
    { lat: 48.6486906, lng: 20.4327681 },
    { lat: 48.6480479, lng: 20.4335745 },
    { lat: 48.6479336, lng: 20.4339437 },
    { lat: 48.646904, lng: 20.4348535 },
    { lat: 48.6464015, lng: 20.4359087 },
    { lat: 48.6450652, lng: 20.4378655 },
    { lat: 48.6449195, lng: 20.4383278 },
    { lat: 48.6443597, lng: 20.4386224 },
    { lat: 48.6435852, lng: 20.4391602 },
    { lat: 48.643483, lng: 20.4390783 },
    { lat: 48.6432947, lng: 20.43872 },
    { lat: 48.643372, lng: 20.4394735 },
    { lat: 48.6434418, lng: 20.4398887 },
    { lat: 48.6435787, lng: 20.4401129 },
    { lat: 48.6438125, lng: 20.4421457 },
    { lat: 48.643818, lng: 20.4425255 },
    { lat: 48.6435589, lng: 20.4452213 },
    { lat: 48.6435669, lng: 20.4461136 },
    { lat: 48.6435114, lng: 20.4460447 },
    { lat: 48.643495, lng: 20.4462658 },
    { lat: 48.6433794, lng: 20.4463645 },
    { lat: 48.6433575, lng: 20.4469115 },
    { lat: 48.6432342, lng: 20.4472893 },
    { lat: 48.6430564, lng: 20.4476148 },
    { lat: 48.6430101, lng: 20.4483825 },
    { lat: 48.642886, lng: 20.4484965 },
    { lat: 48.6427551, lng: 20.4487524 },
    { lat: 48.6426511, lng: 20.449126 },
    { lat: 48.6425695, lng: 20.4501852 },
    { lat: 48.642786, lng: 20.451376 },
    { lat: 48.6434084, lng: 20.4530595 },
    { lat: 48.6437177, lng: 20.4545959 },
    { lat: 48.6443125, lng: 20.4569537 },
    { lat: 48.6446615, lng: 20.4586715 },
    { lat: 48.6449278, lng: 20.4613073 },
    { lat: 48.6448644, lng: 20.4623678 },
    { lat: 48.6448873, lng: 20.4635651 },
    { lat: 48.6452238, lng: 20.4651698 },
    { lat: 48.6455119, lng: 20.4651072 },
    { lat: 48.645719, lng: 20.4653422 },
    { lat: 48.6464756, lng: 20.465402 },
    { lat: 48.6470276, lng: 20.4656155 },
    { lat: 48.6475868, lng: 20.4659601 },
    { lat: 48.648143, lng: 20.4664656 },
    { lat: 48.6486611, lng: 20.4670327 },
    { lat: 48.649093, lng: 20.4677651 },
    { lat: 48.6493073, lng: 20.4680158 },
    { lat: 48.6504279, lng: 20.4687673 },
    { lat: 48.650602, lng: 20.4687555 },
    { lat: 48.6507869, lng: 20.4698012 },
    { lat: 48.6510002, lng: 20.4704701 },
    { lat: 48.6536895, lng: 20.4709909 },
    { lat: 48.6540037, lng: 20.4712256 },
    { lat: 48.656064, lng: 20.4716075 },
    { lat: 48.6562948, lng: 20.4713402 },
    { lat: 48.6567256, lng: 20.4712073 },
    { lat: 48.6582829, lng: 20.4714498 },
    { lat: 48.6597483, lng: 20.4715788 },
    { lat: 48.6601939, lng: 20.4720769 },
    { lat: 48.6612104, lng: 20.4725187 },
    { lat: 48.6614127, lng: 20.4723603 },
    { lat: 48.6616961, lng: 20.4719598 },
    { lat: 48.6625545, lng: 20.4723297 },
    { lat: 48.6630911, lng: 20.472467 },
    { lat: 48.6636385, lng: 20.4722921 },
    { lat: 48.6643386, lng: 20.4716552 },
    { lat: 48.6639788, lng: 20.4715241 },
    { lat: 48.6641219, lng: 20.4710156 },
    { lat: 48.6642296, lng: 20.4701756 },
    { lat: 48.6644032, lng: 20.4697283 },
    { lat: 48.6645, lng: 20.4696383 },
    { lat: 48.6653133, lng: 20.4693806 },
    { lat: 48.6661426, lng: 20.4685915 },
    { lat: 48.6664109, lng: 20.4686845 },
    { lat: 48.6670593, lng: 20.4693419 },
    { lat: 48.6673876, lng: 20.4694429 },
    { lat: 48.6678415, lng: 20.4694487 },
    { lat: 48.6682331, lng: 20.4696213 },
    { lat: 48.6685702, lng: 20.4700803 },
    { lat: 48.6692769, lng: 20.4704252 },
    { lat: 48.6697182, lng: 20.4705397 },
    { lat: 48.6705695, lng: 20.4706339 },
    { lat: 48.6711372, lng: 20.4705779 },
    { lat: 48.6717361, lng: 20.4707223 },
    { lat: 48.6722236, lng: 20.4706669 },
    { lat: 48.6731813, lng: 20.4702346 },
    { lat: 48.6735763, lng: 20.470372 },
    { lat: 48.6740689, lng: 20.4701817 },
    { lat: 48.6747044, lng: 20.4701594 },
    { lat: 48.6749322, lng: 20.4700803 },
    { lat: 48.6756701, lng: 20.4702096 },
    { lat: 48.6760001, lng: 20.4700372 },
    { lat: 48.6771509, lng: 20.4716466 },
    { lat: 48.6773381, lng: 20.4720096 },
    { lat: 48.6779057, lng: 20.4721959 },
    { lat: 48.6795405, lng: 20.472248 },
    { lat: 48.68061, lng: 20.4725711 },
    { lat: 48.681015, lng: 20.4728933 },
    { lat: 48.6813393, lng: 20.4730094 },
  ],
  Vlachovo: [
    { lat: 48.7688699, lng: 20.3683194 },
    { lat: 48.7688558, lng: 20.3688897 },
    { lat: 48.7687102, lng: 20.3695826 },
    { lat: 48.7687147, lng: 20.3699634 },
    { lat: 48.7690582, lng: 20.3710433 },
    { lat: 48.7691361, lng: 20.3720117 },
    { lat: 48.7689723, lng: 20.3733155 },
    { lat: 48.7688146, lng: 20.3738092 },
    { lat: 48.7687919, lng: 20.3740735 },
    { lat: 48.7686301, lng: 20.3744901 },
    { lat: 48.7680552, lng: 20.3748816 },
    { lat: 48.7678293, lng: 20.3751536 },
    { lat: 48.7670082, lng: 20.3754758 },
    { lat: 48.7668161, lng: 20.3756027 },
    { lat: 48.7664502, lng: 20.3759858 },
    { lat: 48.7658813, lng: 20.3760962 },
    { lat: 48.7655279, lng: 20.3763622 },
    { lat: 48.7653847, lng: 20.37665 },
    { lat: 48.7652823, lng: 20.3771473 },
    { lat: 48.7652598, lng: 20.3784682 },
    { lat: 48.7651641, lng: 20.3789924 },
    { lat: 48.7648454, lng: 20.3798108 },
    { lat: 48.7647223, lng: 20.3803591 },
    { lat: 48.7646235, lng: 20.38098 },
    { lat: 48.7645909, lng: 20.3817781 },
    { lat: 48.7643991, lng: 20.3834055 },
    { lat: 48.764138, lng: 20.3843772 },
    { lat: 48.7634966, lng: 20.3857949 },
    { lat: 48.7633773, lng: 20.3868547 },
    { lat: 48.7632102, lng: 20.3871367 },
    { lat: 48.7629382, lng: 20.387439 },
    { lat: 48.7622954, lng: 20.3878257 },
    { lat: 48.7614089, lng: 20.3880535 },
    { lat: 48.7609904, lng: 20.3884104 },
    { lat: 48.7606498, lng: 20.3888386 },
    { lat: 48.7596398, lng: 20.389342 },
    { lat: 48.7589071, lng: 20.3898503 },
    { lat: 48.758421, lng: 20.3900022 },
    { lat: 48.7575112, lng: 20.3906824 },
    { lat: 48.757182, lng: 20.3910485 },
    { lat: 48.7561893, lng: 20.391651 },
    { lat: 48.7554037, lng: 20.3931885 },
    { lat: 48.7558103, lng: 20.3936877 },
    { lat: 48.7564514, lng: 20.3940498 },
    { lat: 48.7576656, lng: 20.3958508 },
    { lat: 48.760465, lng: 20.4004945 },
    { lat: 48.7636875, lng: 20.4061122 },
    { lat: 48.764289, lng: 20.4073841 },
    { lat: 48.7646884, lng: 20.4079631 },
    { lat: 48.7649394, lng: 20.4089605 },
    { lat: 48.7649258, lng: 20.4092519 },
    { lat: 48.766171, lng: 20.4149094 },
    { lat: 48.7662744, lng: 20.4156449 },
    { lat: 48.7674362, lng: 20.4150741 },
    { lat: 48.7677938, lng: 20.4166549 },
    { lat: 48.7679725, lng: 20.4170904 },
    { lat: 48.7679503, lng: 20.4172755 },
    { lat: 48.768145, lng: 20.4178082 },
    { lat: 48.7682096, lng: 20.4178194 },
    { lat: 48.7683064, lng: 20.4179944 },
    { lat: 48.7683345, lng: 20.4181982 },
    { lat: 48.7684176, lng: 20.4182263 },
    { lat: 48.7685366, lng: 20.4184801 },
    { lat: 48.7687163, lng: 20.418498 },
    { lat: 48.7688379, lng: 20.4187576 },
    { lat: 48.7689258, lng: 20.4196212 },
    { lat: 48.7688703, lng: 20.419701 },
    { lat: 48.7688463, lng: 20.4198705 },
    { lat: 48.7689898, lng: 20.4202928 },
    { lat: 48.7692811, lng: 20.4205255 },
    { lat: 48.7693262, lng: 20.4207307 },
    { lat: 48.7694663, lng: 20.4208577 },
    { lat: 48.7696368, lng: 20.4212266 },
    { lat: 48.7697096, lng: 20.4219242 },
    { lat: 48.7698507, lng: 20.421969 },
    { lat: 48.7699, lng: 20.422111 },
    { lat: 48.7699943, lng: 20.4221581 },
    { lat: 48.7700875, lng: 20.4225652 },
    { lat: 48.7702109, lng: 20.4226144 },
    { lat: 48.7702332, lng: 20.4228423 },
    { lat: 48.7701698, lng: 20.4228932 },
    { lat: 48.7702344, lng: 20.4230525 },
    { lat: 48.7702329, lng: 20.4232803 },
    { lat: 48.7701586, lng: 20.4232811 },
    { lat: 48.7701628, lng: 20.4234464 },
    { lat: 48.770562, lng: 20.4235269 },
    { lat: 48.7706157, lng: 20.4236353 },
    { lat: 48.770581, lng: 20.423728 },
    { lat: 48.7708519, lng: 20.4240995 },
    { lat: 48.7708305, lng: 20.4243381 },
    { lat: 48.7710953, lng: 20.4244881 },
    { lat: 48.7711537, lng: 20.4246879 },
    { lat: 48.7711377, lng: 20.4248628 },
    { lat: 48.7713303, lng: 20.4253062 },
    { lat: 48.7713553, lng: 20.4255217 },
    { lat: 48.7716334, lng: 20.4261404 },
    { lat: 48.7718646, lng: 20.4263302 },
    { lat: 48.7718917, lng: 20.4264471 },
    { lat: 48.7720752, lng: 20.4266263 },
    { lat: 48.7721197, lng: 20.4265478 },
    { lat: 48.7721833, lng: 20.4266812 },
    { lat: 48.7721454, lng: 20.4269333 },
    { lat: 48.7722341, lng: 20.4271936 },
    { lat: 48.7726736, lng: 20.427407 },
    { lat: 48.7726716, lng: 20.4275918 },
    { lat: 48.7727773, lng: 20.4276451 },
    { lat: 48.7729068, lng: 20.4285937 },
    { lat: 48.7728537, lng: 20.4287051 },
    { lat: 48.7729957, lng: 20.4289454 },
    { lat: 48.7730638, lng: 20.4293472 },
    { lat: 48.7730414, lng: 20.4295583 },
    { lat: 48.7731274, lng: 20.4297475 },
    { lat: 48.7731055, lng: 20.429909 },
    { lat: 48.773146, lng: 20.4300879 },
    { lat: 48.7730726, lng: 20.4301114 },
    { lat: 48.7730861, lng: 20.4302563 },
    { lat: 48.7731496, lng: 20.4302996 },
    { lat: 48.7731024, lng: 20.4303926 },
    { lat: 48.7732258, lng: 20.4307032 },
    { lat: 48.7732991, lng: 20.4310916 },
    { lat: 48.7732367, lng: 20.4312006 },
    { lat: 48.7732427, lng: 20.4312968 },
    { lat: 48.7731656, lng: 20.4313728 },
    { lat: 48.7731535, lng: 20.4315873 },
    { lat: 48.7731949, lng: 20.4316401 },
    { lat: 48.773067, lng: 20.4318012 },
    { lat: 48.7731464, lng: 20.4319046 },
    { lat: 48.7730838, lng: 20.4320949 },
    { lat: 48.7732391, lng: 20.4323317 },
    { lat: 48.7732055, lng: 20.4325084 },
    { lat: 48.773223, lng: 20.4327657 },
    { lat: 48.7733388, lng: 20.4328199 },
    { lat: 48.7733445, lng: 20.4329072 },
    { lat: 48.7734364, lng: 20.4329592 },
    { lat: 48.7733201, lng: 20.4332788 },
    { lat: 48.7733762, lng: 20.4333578 },
    { lat: 48.7733298, lng: 20.4339745 },
    { lat: 48.773357, lng: 20.4341556 },
    { lat: 48.7732665, lng: 20.4342962 },
    { lat: 48.7732127, lng: 20.4345987 },
    { lat: 48.7730717, lng: 20.4347087 },
    { lat: 48.7729679, lng: 20.4349444 },
    { lat: 48.7729248, lng: 20.435202 },
    { lat: 48.7727628, lng: 20.43529 },
    { lat: 48.772787, lng: 20.43537 },
    { lat: 48.7727501, lng: 20.4354049 },
    { lat: 48.7728069, lng: 20.4355321 },
    { lat: 48.7726841, lng: 20.4357413 },
    { lat: 48.7728299, lng: 20.4359642 },
    { lat: 48.7727893, lng: 20.4363479 },
    { lat: 48.7727027, lng: 20.4364975 },
    { lat: 48.7727637, lng: 20.4367139 },
    { lat: 48.7728255, lng: 20.4367674 },
    { lat: 48.7727949, lng: 20.4369057 },
    { lat: 48.7728422, lng: 20.437059 },
    { lat: 48.7727507, lng: 20.4374093 },
    { lat: 48.7728385, lng: 20.4375531 },
    { lat: 48.7727726, lng: 20.4376255 },
    { lat: 48.7727862, lng: 20.4377479 },
    { lat: 48.773087, lng: 20.4384928 },
    { lat: 48.773139, lng: 20.4387668 },
    { lat: 48.7732539, lng: 20.4388864 },
    { lat: 48.7732903, lng: 20.4390731 },
    { lat: 48.7734763, lng: 20.4392865 },
    { lat: 48.7734551, lng: 20.4393822 },
    { lat: 48.7735876, lng: 20.4394289 },
    { lat: 48.7735811, lng: 20.4394994 },
    { lat: 48.7736758, lng: 20.4395337 },
    { lat: 48.773676, lng: 20.4395944 },
    { lat: 48.7737694, lng: 20.4396801 },
    { lat: 48.7739584, lng: 20.4403588 },
    { lat: 48.7742546, lng: 20.44084 },
    { lat: 48.774556, lng: 20.4415688 },
    { lat: 48.7746855, lng: 20.4417168 },
    { lat: 48.7746348, lng: 20.4418669 },
    { lat: 48.77517, lng: 20.4433619 },
    { lat: 48.7754292, lng: 20.4445239 },
    { lat: 48.7755375, lng: 20.446136 },
    { lat: 48.7756427, lng: 20.446471 },
    { lat: 48.7757653, lng: 20.4466825 },
    { lat: 48.7758482, lng: 20.4470273 },
    { lat: 48.7761211, lng: 20.4475091 },
    { lat: 48.7762419, lng: 20.4481141 },
    { lat: 48.7760176, lng: 20.4487323 },
    { lat: 48.7761031, lng: 20.449713 },
    { lat: 48.7760207, lng: 20.4513395 },
    { lat: 48.7760589, lng: 20.4522937 },
    { lat: 48.7763601, lng: 20.4523957 },
    { lat: 48.7769013, lng: 20.4523564 },
    { lat: 48.7772796, lng: 20.4525346 },
    { lat: 48.7775173, lng: 20.4528053 },
    { lat: 48.7778836, lng: 20.4535319 },
    { lat: 48.7781773, lng: 20.4537617 },
    { lat: 48.7785378, lng: 20.4543642 },
    { lat: 48.7795433, lng: 20.4551976 },
    { lat: 48.7796662, lng: 20.4553533 },
    { lat: 48.7800748, lng: 20.4556461 },
    { lat: 48.7805271, lng: 20.4560832 },
    { lat: 48.7809141, lng: 20.456315 },
    { lat: 48.7818862, lng: 20.4566497 },
    { lat: 48.7819007, lng: 20.4565845 },
    { lat: 48.7823354, lng: 20.4568254 },
    { lat: 48.7827171, lng: 20.4566178 },
    { lat: 48.7827029, lng: 20.4565719 },
    { lat: 48.783006, lng: 20.4563393 },
    { lat: 48.7833005, lng: 20.4559096 },
    { lat: 48.7835765, lng: 20.4546014 },
    { lat: 48.7836623, lng: 20.4543941 },
    { lat: 48.7837948, lng: 20.4545354 },
    { lat: 48.7842969, lng: 20.4545898 },
    { lat: 48.7850819, lng: 20.4550464 },
    { lat: 48.7857907, lng: 20.4550293 },
    { lat: 48.7863063, lng: 20.4553853 },
    { lat: 48.7865029, lng: 20.455405 },
    { lat: 48.7867693, lng: 20.4555362 },
    { lat: 48.7869021, lng: 20.4555097 },
    { lat: 48.7871934, lng: 20.455888 },
    { lat: 48.7873969, lng: 20.4568338 },
    { lat: 48.7875313, lng: 20.45702 },
    { lat: 48.7875696, lng: 20.457202 },
    { lat: 48.7877043, lng: 20.4572528 },
    { lat: 48.7878023, lng: 20.4575175 },
    { lat: 48.7878423, lng: 20.4585757 },
    { lat: 48.7879155, lng: 20.4586957 },
    { lat: 48.7879208, lng: 20.4588992 },
    { lat: 48.7881225, lng: 20.4592292 },
    { lat: 48.7881456, lng: 20.4596917 },
    { lat: 48.7884084, lng: 20.4604771 },
    { lat: 48.7885891, lng: 20.4607625 },
    { lat: 48.788414, lng: 20.4611383 },
    { lat: 48.7882218, lng: 20.4620651 },
    { lat: 48.7883018, lng: 20.462517 },
    { lat: 48.7881921, lng: 20.4627179 },
    { lat: 48.7881738, lng: 20.4630066 },
    { lat: 48.7882737, lng: 20.4632347 },
    { lat: 48.7882081, lng: 20.4637053 },
    { lat: 48.7882853, lng: 20.4638422 },
    { lat: 48.7882606, lng: 20.4645885 },
    { lat: 48.7881249, lng: 20.464934 },
    { lat: 48.7882959, lng: 20.4657331 },
    { lat: 48.788436, lng: 20.4659873 },
    { lat: 48.7886485, lng: 20.4669363 },
    { lat: 48.7887604, lng: 20.4670949 },
    { lat: 48.7888498, lng: 20.467676 },
    { lat: 48.7887594, lng: 20.4678134 },
    { lat: 48.7888646, lng: 20.4681564 },
    { lat: 48.7887523, lng: 20.4685821 },
    { lat: 48.7888607, lng: 20.4689173 },
    { lat: 48.7886511, lng: 20.4695417 },
    { lat: 48.7886831, lng: 20.4696757 },
    { lat: 48.7886527, lng: 20.4703615 },
    { lat: 48.7884864, lng: 20.4705504 },
    { lat: 48.7885523, lng: 20.471135 },
    { lat: 48.7885164, lng: 20.471378 },
    { lat: 48.7885871, lng: 20.4716687 },
    { lat: 48.7884308, lng: 20.4725138 },
    { lat: 48.7881422, lng: 20.4728957 },
    { lat: 48.7880985, lng: 20.4733512 },
    { lat: 48.7879785, lng: 20.4735108 },
    { lat: 48.7876747, lng: 20.4736934 },
    { lat: 48.7876128, lng: 20.4740562 },
    { lat: 48.7875366, lng: 20.4741827 },
    { lat: 48.7875767, lng: 20.4745017 },
    { lat: 48.7876799, lng: 20.4746724 },
    { lat: 48.7878322, lng: 20.4748268 },
    { lat: 48.7880145, lng: 20.4748557 },
    { lat: 48.788851, lng: 20.4755738 },
    { lat: 48.788905, lng: 20.475651 },
    { lat: 48.789084, lng: 20.47577 },
    { lat: 48.789209, lng: 20.475845 },
    { lat: 48.789242, lng: 20.476006 },
    { lat: 48.789479, lng: 20.47637 },
    { lat: 48.789563, lng: 20.476453 },
    { lat: 48.789877, lng: 20.476473 },
    { lat: 48.790027, lng: 20.476516 },
    { lat: 48.790048, lng: 20.476794 },
    { lat: 48.790188, lng: 20.477081 },
    { lat: 48.790322, lng: 20.477296 },
    { lat: 48.790406, lng: 20.477313 },
    { lat: 48.790608, lng: 20.477397 },
    { lat: 48.790645, lng: 20.47753 },
    { lat: 48.790722, lng: 20.477638 },
    { lat: 48.790843, lng: 20.477694 },
    { lat: 48.791009, lng: 20.477894 },
    { lat: 48.791104, lng: 20.478115 },
    { lat: 48.791235, lng: 20.478299 },
    { lat: 48.791454, lng: 20.478376 },
    { lat: 48.791599, lng: 20.478527 },
    { lat: 48.791862, lng: 20.478333 },
    { lat: 48.791992, lng: 20.478335 },
    { lat: 48.792126, lng: 20.478384 },
    { lat: 48.792342, lng: 20.478485 },
    { lat: 48.792395, lng: 20.478579 },
    { lat: 48.792655, lng: 20.47869 },
    { lat: 48.792747, lng: 20.478713 },
    { lat: 48.793016, lng: 20.478835 },
    { lat: 48.793057, lng: 20.478991 },
    { lat: 48.793212, lng: 20.478973 },
    { lat: 48.793443, lng: 20.479085 },
    { lat: 48.793586, lng: 20.479008 },
    { lat: 48.793869, lng: 20.479059 },
    { lat: 48.794078, lng: 20.479026 },
    { lat: 48.794287, lng: 20.478932 },
    { lat: 48.794415, lng: 20.478955 },
    { lat: 48.794623, lng: 20.478873 },
    { lat: 48.794693, lng: 20.478943 },
    { lat: 48.794782, lng: 20.478933 },
    { lat: 48.794875, lng: 20.478967 },
    { lat: 48.795027, lng: 20.478951 },
    { lat: 48.795287, lng: 20.478758 },
    { lat: 48.795282, lng: 20.478692 },
    { lat: 48.795316, lng: 20.478557 },
    { lat: 48.795408, lng: 20.478465 },
    { lat: 48.795602, lng: 20.478396 },
    { lat: 48.795741, lng: 20.478226 },
    { lat: 48.795866, lng: 20.478002 },
    { lat: 48.796024, lng: 20.47791 },
    { lat: 48.796137, lng: 20.477822 },
    { lat: 48.796268, lng: 20.477857 },
    { lat: 48.796554, lng: 20.477702 },
    { lat: 48.796671, lng: 20.477544 },
    { lat: 48.796939, lng: 20.477264 },
    { lat: 48.797008, lng: 20.477156 },
    { lat: 48.797099, lng: 20.47714 },
    { lat: 48.797182, lng: 20.47707 },
    { lat: 48.797312, lng: 20.476966 },
    { lat: 48.797437, lng: 20.476786 },
    { lat: 48.797469, lng: 20.476816 },
    { lat: 48.797557, lng: 20.476897 },
    { lat: 48.797676, lng: 20.476999 },
    { lat: 48.79779, lng: 20.477102 },
    { lat: 48.797845, lng: 20.477148 },
    { lat: 48.797866, lng: 20.477162 },
    { lat: 48.797929, lng: 20.477205 },
    { lat: 48.797955, lng: 20.477222 },
    { lat: 48.798624, lng: 20.477659 },
    { lat: 48.798844, lng: 20.477991 },
    { lat: 48.798891, lng: 20.478223 },
    { lat: 48.799069, lng: 20.47845 },
    { lat: 48.799131, lng: 20.478598 },
    { lat: 48.799584, lng: 20.479334 },
    { lat: 48.799678, lng: 20.479449 },
    { lat: 48.800128, lng: 20.480369 },
    { lat: 48.800444, lng: 20.480633 },
    { lat: 48.80077, lng: 20.480846 },
    { lat: 48.800933, lng: 20.480888 },
    { lat: 48.800959, lng: 20.480948 },
    { lat: 48.800987, lng: 20.481006 },
    { lat: 48.801091, lng: 20.481043 },
    { lat: 48.801265, lng: 20.481101 },
    { lat: 48.801361, lng: 20.481099 },
    { lat: 48.801496, lng: 20.4811 },
    { lat: 48.801632, lng: 20.481148 },
    { lat: 48.801773, lng: 20.481188 },
    { lat: 48.80192, lng: 20.481226 },
    { lat: 48.802079, lng: 20.481271 },
    { lat: 48.802817, lng: 20.482186 },
    { lat: 48.803113, lng: 20.482614 },
    { lat: 48.803434, lng: 20.482777 },
    { lat: 48.804073, lng: 20.483525 },
    { lat: 48.804399, lng: 20.483782 },
    { lat: 48.804429, lng: 20.484078 },
    { lat: 48.804473, lng: 20.484178 },
    { lat: 48.804528, lng: 20.484268 },
    { lat: 48.804808, lng: 20.484191 },
    { lat: 48.804877, lng: 20.484217 },
    { lat: 48.805032, lng: 20.484642 },
    { lat: 48.805533, lng: 20.485434 },
    { lat: 48.805723, lng: 20.485535 },
    { lat: 48.805749, lng: 20.485543 },
    { lat: 48.805832, lng: 20.485564 },
    { lat: 48.80586, lng: 20.485569 },
    { lat: 48.805996, lng: 20.485585 },
    { lat: 48.806273, lng: 20.485234 },
    { lat: 48.806369, lng: 20.485118 },
    { lat: 48.806579, lng: 20.484868 },
    { lat: 48.806716, lng: 20.484693 },
    { lat: 48.806878, lng: 20.484485 },
    { lat: 48.807082, lng: 20.484161 },
    { lat: 48.807513, lng: 20.483851 },
    { lat: 48.807822, lng: 20.483521 },
    { lat: 48.808009, lng: 20.483335 },
    { lat: 48.808233, lng: 20.483131 },
    { lat: 48.80837, lng: 20.482849 },
    { lat: 48.808585, lng: 20.482554 },
    { lat: 48.80877, lng: 20.482296 },
    { lat: 48.809373, lng: 20.482087 },
    { lat: 48.8096, lng: 20.482004 },
    { lat: 48.809806, lng: 20.482008 },
    { lat: 48.809971, lng: 20.482037 },
    { lat: 48.81017, lng: 20.482135 },
    { lat: 48.810405, lng: 20.482183 },
    { lat: 48.810611, lng: 20.482168 },
    { lat: 48.810838, lng: 20.482207 },
    { lat: 48.810851, lng: 20.482214 },
    { lat: 48.810944, lng: 20.48226 },
    { lat: 48.811095, lng: 20.482385 },
    { lat: 48.81126, lng: 20.48251 },
    { lat: 48.811381, lng: 20.482593 },
    { lat: 48.811437, lng: 20.482622 },
    { lat: 48.811545, lng: 20.482636 },
    { lat: 48.811711, lng: 20.482656 },
    { lat: 48.811903, lng: 20.48261 },
    { lat: 48.811991, lng: 20.482647 },
    { lat: 48.812046, lng: 20.482656 },
    { lat: 48.812201, lng: 20.48278 },
    { lat: 48.81221, lng: 20.482756 },
    { lat: 48.812373, lng: 20.482367 },
    { lat: 48.812393, lng: 20.482295 },
    { lat: 48.812445, lng: 20.482102 },
    { lat: 48.812516, lng: 20.481828 },
    { lat: 48.812597, lng: 20.481383 },
    { lat: 48.812572, lng: 20.480868 },
    { lat: 48.812645, lng: 20.480671 },
    { lat: 48.812698, lng: 20.480522 },
    { lat: 48.812723, lng: 20.480476 },
    { lat: 48.812785, lng: 20.480371 },
    { lat: 48.8128, lng: 20.480339 },
    { lat: 48.812886, lng: 20.480056 },
    { lat: 48.8129222, lng: 20.4799052 },
    { lat: 48.812948, lng: 20.479684 },
    { lat: 48.81294, lng: 20.479433 },
    { lat: 48.813018, lng: 20.479116 },
    { lat: 48.813397, lng: 20.4788 },
    { lat: 48.813528, lng: 20.47871 },
    { lat: 48.813632, lng: 20.478654 },
    { lat: 48.813738, lng: 20.478599 },
    { lat: 48.814052, lng: 20.478416 },
    { lat: 48.81418, lng: 20.478317 },
    { lat: 48.814341, lng: 20.478191 },
    { lat: 48.814565, lng: 20.478019 },
    { lat: 48.814679, lng: 20.477937 },
    { lat: 48.814762, lng: 20.477852 },
    { lat: 48.814811, lng: 20.47777 },
    { lat: 48.814971, lng: 20.47745 },
    { lat: 48.815049, lng: 20.477122 },
    { lat: 48.815089, lng: 20.476898 },
    { lat: 48.815106, lng: 20.476629 },
    { lat: 48.815157, lng: 20.476343 },
    { lat: 48.815223, lng: 20.476176 },
    { lat: 48.815353, lng: 20.475763 },
    { lat: 48.815432, lng: 20.475562 },
    { lat: 48.81556, lng: 20.475264 },
    { lat: 48.815725, lng: 20.474874 },
    { lat: 48.816067, lng: 20.474068 },
    { lat: 48.816321, lng: 20.473534 },
    { lat: 48.816624, lng: 20.473051 },
    { lat: 48.816771, lng: 20.472286 },
    { lat: 48.81709, lng: 20.47135 },
    { lat: 48.81747, lng: 20.470712 },
    { lat: 48.817683, lng: 20.470004 },
    { lat: 48.817828, lng: 20.469077 },
    { lat: 48.817766, lng: 20.468723 },
    { lat: 48.817806, lng: 20.468302 },
    { lat: 48.817827, lng: 20.468145 },
    { lat: 48.817846, lng: 20.468082 },
    { lat: 48.817902, lng: 20.467894 },
    { lat: 48.81796, lng: 20.467708 },
    { lat: 48.818023, lng: 20.467502 },
    { lat: 48.818087, lng: 20.467274 },
    { lat: 48.818139, lng: 20.467101 },
    { lat: 48.818207, lng: 20.466872 },
    { lat: 48.818234, lng: 20.466694 },
    { lat: 48.818253, lng: 20.46656 },
    { lat: 48.818289, lng: 20.466478 },
    { lat: 48.818708, lng: 20.465554 },
    { lat: 48.819422, lng: 20.46383 },
    { lat: 48.819674, lng: 20.463369 },
    { lat: 48.819954, lng: 20.462989 },
    { lat: 48.820447, lng: 20.462968 },
    { lat: 48.820992, lng: 20.462815 },
    { lat: 48.821803, lng: 20.463155 },
    { lat: 48.822581, lng: 20.463275 },
    { lat: 48.822881, lng: 20.463373 },
    { lat: 48.823542, lng: 20.463131 },
    { lat: 48.824017, lng: 20.463156 },
    { lat: 48.824305, lng: 20.463038 },
    { lat: 48.824442, lng: 20.462981 },
    { lat: 48.825119, lng: 20.462938 },
    { lat: 48.825523, lng: 20.462732 },
    { lat: 48.825976, lng: 20.462913 },
    { lat: 48.826631, lng: 20.463042 },
    { lat: 48.826881, lng: 20.462971 },
    { lat: 48.827293, lng: 20.462738 },
    { lat: 48.827754, lng: 20.462716 },
    { lat: 48.828215, lng: 20.462264 },
    { lat: 48.828646, lng: 20.462274 },
    { lat: 48.829554, lng: 20.462713 },
    { lat: 48.830169, lng: 20.461636 },
    { lat: 48.830753, lng: 20.460582 },
    { lat: 48.830855, lng: 20.459985 },
    { lat: 48.830927, lng: 20.459548 },
    { lat: 48.831464, lng: 20.458645 },
    { lat: 48.831615, lng: 20.458595 },
    { lat: 48.8311633, lng: 20.4573018 },
    { lat: 48.8305832, lng: 20.455925 },
    { lat: 48.8301525, lng: 20.4555228 },
    { lat: 48.8287356, lng: 20.453958 },
    { lat: 48.8281269, lng: 20.4527803 },
    { lat: 48.8262208, lng: 20.4499552 },
    { lat: 48.8250911, lng: 20.4475907 },
    { lat: 48.8241945, lng: 20.4455591 },
    { lat: 48.8246884, lng: 20.4430474 },
    { lat: 48.8224555, lng: 20.4401044 },
    { lat: 48.8210816, lng: 20.438202 },
    { lat: 48.8203576, lng: 20.4373971 },
    { lat: 48.8196303, lng: 20.435533 },
    { lat: 48.8186167, lng: 20.4343891 },
    { lat: 48.8181529, lng: 20.4338657 },
    { lat: 48.8175334, lng: 20.4329206 },
    { lat: 48.8174924, lng: 20.4328581 },
    { lat: 48.817486, lng: 20.4328483 },
    { lat: 48.8162927, lng: 20.4322626 },
    { lat: 48.8142163, lng: 20.4306042 },
    { lat: 48.8133148, lng: 20.4293383 },
    { lat: 48.8124999, lng: 20.4277187 },
    { lat: 48.8116883, lng: 20.424731 },
    { lat: 48.8112568, lng: 20.4233074 },
    { lat: 48.8111421, lng: 20.4226604 },
    { lat: 48.8081788, lng: 20.4156053 },
    { lat: 48.8081668, lng: 20.4153467 },
    { lat: 48.8082054, lng: 20.4152386 },
    { lat: 48.8081678, lng: 20.4148729 },
    { lat: 48.8080717, lng: 20.4144812 },
    { lat: 48.80794, lng: 20.4135642 },
    { lat: 48.8078837, lng: 20.4135052 },
    { lat: 48.8077722, lng: 20.4130634 },
    { lat: 48.8076379, lng: 20.4129941 },
    { lat: 48.8076266, lng: 20.4124303 },
    { lat: 48.8075122, lng: 20.4123585 },
    { lat: 48.8073977, lng: 20.4119234 },
    { lat: 48.8074667, lng: 20.4114561 },
    { lat: 48.8074472, lng: 20.4112725 },
    { lat: 48.8073711, lng: 20.4111962 },
    { lat: 48.8074432, lng: 20.4109642 },
    { lat: 48.8073831, lng: 20.4101799 },
    { lat: 48.807249, lng: 20.4097094 },
    { lat: 48.807122, lng: 20.4095105 },
    { lat: 48.8070494, lng: 20.4091103 },
    { lat: 48.8070712, lng: 20.4087361 },
    { lat: 48.8069198, lng: 20.4084597 },
    { lat: 48.8069105, lng: 20.4082769 },
    { lat: 48.806792, lng: 20.4080047 },
    { lat: 48.8066676, lng: 20.4078112 },
    { lat: 48.8067156, lng: 20.4077099 },
    { lat: 48.806691, lng: 20.4074773 },
    { lat: 48.8065907, lng: 20.407095 },
    { lat: 48.8065365, lng: 20.40703 },
    { lat: 48.8065346, lng: 20.4068623 },
    { lat: 48.8064471, lng: 20.4067821 },
    { lat: 48.8064348, lng: 20.4065774 },
    { lat: 48.806304, lng: 20.4065135 },
    { lat: 48.8061062, lng: 20.4059932 },
    { lat: 48.805638, lng: 20.4056763 },
    { lat: 48.8055537, lng: 20.4055025 },
    { lat: 48.8055286, lng: 20.4052275 },
    { lat: 48.8054365, lng: 20.4050038 },
    { lat: 48.8054403, lng: 20.4048387 },
    { lat: 48.8053834, lng: 20.4046715 },
    { lat: 48.8052408, lng: 20.4045386 },
    { lat: 48.8050563, lng: 20.4041254 },
    { lat: 48.8050475, lng: 20.4038954 },
    { lat: 48.8049945, lng: 20.4038065 },
    { lat: 48.8047769, lng: 20.4036552 },
    { lat: 48.8047349, lng: 20.4029401 },
    { lat: 48.8045596, lng: 20.402743 },
    { lat: 48.8045515, lng: 20.4025879 },
    { lat: 48.8042641, lng: 20.4019637 },
    { lat: 48.8042794, lng: 20.401628 },
    { lat: 48.8041681, lng: 20.4015142 },
    { lat: 48.8040841, lng: 20.4012908 },
    { lat: 48.8038252, lng: 20.4010877 },
    { lat: 48.8038217, lng: 20.4008794 },
    { lat: 48.8036589, lng: 20.4005329 },
    { lat: 48.80359, lng: 20.4005565 },
    { lat: 48.803354, lng: 20.3999831 },
    { lat: 48.8033682, lng: 20.3997057 },
    { lat: 48.803293, lng: 20.3992719 },
    { lat: 48.8033418, lng: 20.399103 },
    { lat: 48.8033264, lng: 20.3988185 },
    { lat: 48.8031734, lng: 20.3983526 },
    { lat: 48.803023, lng: 20.3981025 },
    { lat: 48.8030615, lng: 20.3974883 },
    { lat: 48.8030413, lng: 20.3973129 },
    { lat: 48.8029696, lng: 20.3971778 },
    { lat: 48.8029728, lng: 20.3969092 },
    { lat: 48.803043, lng: 20.3965359 },
    { lat: 48.8030103, lng: 20.3963601 },
    { lat: 48.8031716, lng: 20.3958034 },
    { lat: 48.8031957, lng: 20.3952315 },
    { lat: 48.8031021, lng: 20.3950486 },
    { lat: 48.8031039, lng: 20.3947752 },
    { lat: 48.8031997, lng: 20.3945421 },
    { lat: 48.8032401, lng: 20.3942707 },
    { lat: 48.8032084, lng: 20.3936541 },
    { lat: 48.8033141, lng: 20.393444 },
    { lat: 48.8031254, lng: 20.3930398 },
    { lat: 48.8030331, lng: 20.3923114 },
    { lat: 48.8030488, lng: 20.3921607 },
    { lat: 48.8029557, lng: 20.3918195 },
    { lat: 48.8027174, lng: 20.3915068 },
    { lat: 48.8026141, lng: 20.391218 },
    { lat: 48.8025992, lng: 20.3907113 },
    { lat: 48.8026533, lng: 20.3903622 },
    { lat: 48.8025974, lng: 20.390166 },
    { lat: 48.802644, lng: 20.389943 },
    { lat: 48.8026025, lng: 20.3895916 },
    { lat: 48.8027074, lng: 20.3895106 },
    { lat: 48.8026484, lng: 20.3892082 },
    { lat: 48.8026747, lng: 20.3890684 },
    { lat: 48.8025167, lng: 20.3887408 },
    { lat: 48.8025878, lng: 20.3885373 },
    { lat: 48.8024235, lng: 20.3884507 },
    { lat: 48.8023779, lng: 20.3883493 },
    { lat: 48.8023698, lng: 20.3880775 },
    { lat: 48.802255, lng: 20.388019 },
    { lat: 48.8023235, lng: 20.3878972 },
    { lat: 48.8022938, lng: 20.3877395 },
    { lat: 48.8021005, lng: 20.3873678 },
    { lat: 48.8019638, lng: 20.3866215 },
    { lat: 48.8011498, lng: 20.3849529 },
    { lat: 48.8007611, lng: 20.3850671 },
    { lat: 48.8006504, lng: 20.3848818 },
    { lat: 48.8004882, lng: 20.3850579 },
    { lat: 48.8003354, lng: 20.3850963 },
    { lat: 48.8000734, lng: 20.3848983 },
    { lat: 48.799871, lng: 20.3849911 },
    { lat: 48.7993677, lng: 20.3849671 },
    { lat: 48.7989947, lng: 20.384871 },
    { lat: 48.7986431, lng: 20.3848941 },
    { lat: 48.7987207, lng: 20.3845076 },
    { lat: 48.7985126, lng: 20.3833708 },
    { lat: 48.7968397, lng: 20.3757686 },
    { lat: 48.7963368, lng: 20.3732335 },
    { lat: 48.7961298, lng: 20.3725085 },
    { lat: 48.7955496, lng: 20.3707153 },
    { lat: 48.7951871, lng: 20.3709827 },
    { lat: 48.7949809, lng: 20.3702483 },
    { lat: 48.7947206, lng: 20.3696302 },
    { lat: 48.7935133, lng: 20.3673436 },
    { lat: 48.7930718, lng: 20.3662358 },
    { lat: 48.7915591, lng: 20.360941 },
    { lat: 48.7913752, lng: 20.3596491 },
    { lat: 48.7912948, lng: 20.3597555 },
    { lat: 48.7911803, lng: 20.359907 },
    { lat: 48.7909085, lng: 20.3599553 },
    { lat: 48.7901528, lng: 20.3596054 },
    { lat: 48.7891304, lng: 20.3602473 },
    { lat: 48.7886801, lng: 20.3604831 },
    { lat: 48.7883516, lng: 20.3605497 },
    { lat: 48.7876636, lng: 20.3609309 },
    { lat: 48.7868377, lng: 20.3615599 },
    { lat: 48.7857995, lng: 20.3622284 },
    { lat: 48.7848909, lng: 20.3625537 },
    { lat: 48.7842696, lng: 20.3629393 },
    { lat: 48.7838528, lng: 20.3632864 },
    { lat: 48.7834048, lng: 20.3634349 },
    { lat: 48.7829787, lng: 20.363485 },
    { lat: 48.7824481, lng: 20.3639765 },
    { lat: 48.7822025, lng: 20.3640575 },
    { lat: 48.7819783, lng: 20.3640251 },
    { lat: 48.7815606, lng: 20.3637685 },
    { lat: 48.781326, lng: 20.3635236 },
    { lat: 48.7808393, lng: 20.3632246 },
    { lat: 48.7804976, lng: 20.3629195 },
    { lat: 48.78008, lng: 20.362865 },
    { lat: 48.7796103, lng: 20.3626905 },
    { lat: 48.7794344, lng: 20.3625364 },
    { lat: 48.7790397, lng: 20.3624328 },
    { lat: 48.7786288, lng: 20.3622299 },
    { lat: 48.7782156, lng: 20.3618876 },
    { lat: 48.7776593, lng: 20.3620603 },
    { lat: 48.7772348, lng: 20.3623799 },
    { lat: 48.7771193, lng: 20.3626272 },
    { lat: 48.7763922, lng: 20.3634255 },
    { lat: 48.7761237, lng: 20.3637634 },
    { lat: 48.7760766, lng: 20.3639414 },
    { lat: 48.7758982, lng: 20.3641007 },
    { lat: 48.775778, lng: 20.3640806 },
    { lat: 48.7755956, lng: 20.3643124 },
    { lat: 48.7746109, lng: 20.3646553 },
    { lat: 48.7743006, lng: 20.3648391 },
    { lat: 48.7741728, lng: 20.3650603 },
    { lat: 48.7739598, lng: 20.3649952 },
    { lat: 48.7737251, lng: 20.3650395 },
    { lat: 48.7730785, lng: 20.3655029 },
    { lat: 48.7725592, lng: 20.3661963 },
    { lat: 48.7723762, lng: 20.3666209 },
    { lat: 48.7720955, lng: 20.3668742 },
    { lat: 48.7715258, lng: 20.3677538 },
    { lat: 48.771223, lng: 20.3680768 },
    { lat: 48.7703128, lng: 20.3681842 },
    { lat: 48.7698696, lng: 20.3683169 },
    { lat: 48.7688699, lng: 20.3683194 },
  ],
  Plešivec: [
    { lat: 48.6576007, lng: 20.4234315 },
    { lat: 48.6576841, lng: 20.4232611 },
    { lat: 48.657633, lng: 20.4226266 },
    { lat: 48.6572294, lng: 20.4221096 },
    { lat: 48.6571598, lng: 20.4217944 },
    { lat: 48.6567837, lng: 20.4209994 },
    { lat: 48.6562568, lng: 20.4197029 },
    { lat: 48.6554366, lng: 20.4185099 },
    { lat: 48.6549981, lng: 20.4173243 },
    { lat: 48.6549833, lng: 20.4167326 },
    { lat: 48.6548889, lng: 20.4159659 },
    { lat: 48.6550419, lng: 20.4151993 },
    { lat: 48.6551586, lng: 20.414074 },
    { lat: 48.6553095, lng: 20.4134842 },
    { lat: 48.6552446, lng: 20.4122558 },
    { lat: 48.6551533, lng: 20.4119916 },
    { lat: 48.654935, lng: 20.4116082 },
    { lat: 48.6542154, lng: 20.4109326 },
    { lat: 48.6539116, lng: 20.4103811 },
    { lat: 48.6537157, lng: 20.4102946 },
    { lat: 48.653493, lng: 20.4100369 },
    { lat: 48.6535849, lng: 20.4083457 },
    { lat: 48.6534909, lng: 20.4078069 },
    { lat: 48.6526429, lng: 20.4071252 },
    { lat: 48.6521505, lng: 20.4068861 },
    { lat: 48.6515333, lng: 20.4067045 },
    { lat: 48.6516023, lng: 20.4060361 },
    { lat: 48.6506243, lng: 20.4054025 },
    { lat: 48.6501035, lng: 20.4047435 },
    { lat: 48.6492152, lng: 20.404131 },
    { lat: 48.6484336, lng: 20.4039524 },
    { lat: 48.6478635, lng: 20.4034157 },
    { lat: 48.6477056, lng: 20.4033775 },
    { lat: 48.6469334, lng: 20.4027712 },
    { lat: 48.6461656, lng: 20.4026011 },
    { lat: 48.6458272, lng: 20.402176 },
    { lat: 48.6454961, lng: 20.4014361 },
    { lat: 48.6450722, lng: 20.4011014 },
    { lat: 48.6441202, lng: 20.4007982 },
    { lat: 48.6433558, lng: 20.3986616 },
    { lat: 48.6430121, lng: 20.3985051 },
    { lat: 48.6422407, lng: 20.3976567 },
    { lat: 48.6416334, lng: 20.3974438 },
    { lat: 48.641395, lng: 20.3978044 },
    { lat: 48.6404557, lng: 20.3984469 },
    { lat: 48.6401251, lng: 20.3983964 },
    { lat: 48.6393883, lng: 20.4000033 },
    { lat: 48.6384901, lng: 20.4024779 },
    { lat: 48.6368579, lng: 20.4048083 },
    { lat: 48.6352218, lng: 20.4055204 },
    { lat: 48.6345905, lng: 20.4059113 },
    { lat: 48.6343118, lng: 20.4061025 },
    { lat: 48.6337483, lng: 20.4067039 },
    { lat: 48.6334095, lng: 20.4068296 },
    { lat: 48.6331268, lng: 20.4070921 },
    { lat: 48.6324712, lng: 20.407528 },
    { lat: 48.6322356, lng: 20.4077843 },
    { lat: 48.6321086, lng: 20.4078203 },
    { lat: 48.6316122, lng: 20.4075862 },
    { lat: 48.6315136, lng: 20.4072196 },
    { lat: 48.6313199, lng: 20.4073682 },
    { lat: 48.6306252, lng: 20.4076631 },
    { lat: 48.6299766, lng: 20.4074007 },
    { lat: 48.6285314, lng: 20.4065646 },
    { lat: 48.6282026, lng: 20.4061255 },
    { lat: 48.6280699, lng: 20.4058025 },
    { lat: 48.6276884, lng: 20.4052812 },
    { lat: 48.6272585, lng: 20.4049684 },
    { lat: 48.6269277, lng: 20.4049147 },
    { lat: 48.6266166, lng: 20.4047568 },
    { lat: 48.62645, lng: 20.4046154 },
    { lat: 48.626192, lng: 20.4041716 },
    { lat: 48.6256639, lng: 20.4041405 },
    { lat: 48.623965, lng: 20.4029796 },
    { lat: 48.623443, lng: 20.4021425 },
    { lat: 48.6232326, lng: 20.4005026 },
    { lat: 48.6230789, lng: 20.4001942 },
    { lat: 48.6227355, lng: 20.399843 },
    { lat: 48.6223881, lng: 20.399773 },
    { lat: 48.6221992, lng: 20.3996026 },
    { lat: 48.6219077, lng: 20.399632 },
    { lat: 48.6210907, lng: 20.3999127 },
    { lat: 48.6199258, lng: 20.4001312 },
    { lat: 48.6187216, lng: 20.4000364 },
    { lat: 48.6179576, lng: 20.3998181 },
    { lat: 48.617458, lng: 20.3997973 },
    { lat: 48.6168196, lng: 20.3999406 },
    { lat: 48.6166656, lng: 20.3999195 },
    { lat: 48.6163251, lng: 20.400029 },
    { lat: 48.6161446, lng: 20.400227 },
    { lat: 48.6158189, lng: 20.4002206 },
    { lat: 48.6154905, lng: 20.4007859 },
    { lat: 48.615367, lng: 20.4008504 },
    { lat: 48.6148886, lng: 20.4006833 },
    { lat: 48.6144776, lng: 20.4007413 },
    { lat: 48.6139994, lng: 20.4006621 },
    { lat: 48.6133781, lng: 20.4007864 },
    { lat: 48.6132338, lng: 20.4008759 },
    { lat: 48.6129655, lng: 20.4007755 },
    { lat: 48.6126938, lng: 20.4008288 },
    { lat: 48.6125389, lng: 20.4007839 },
    { lat: 48.6122764, lng: 20.4011631 },
    { lat: 48.6119433, lng: 20.4013989 },
    { lat: 48.6117847, lng: 20.4017597 },
    { lat: 48.6112735, lng: 20.4019058 },
    { lat: 48.6108403, lng: 20.4016237 },
    { lat: 48.6102634, lng: 20.4015805 },
    { lat: 48.6096531, lng: 20.4017565 },
    { lat: 48.6090787, lng: 20.4022227 },
    { lat: 48.6084946, lng: 20.4024099 },
    { lat: 48.6081644, lng: 20.4022858 },
    { lat: 48.6073264, lng: 20.4023706 },
    { lat: 48.6067771, lng: 20.4026353 },
    { lat: 48.6058324, lng: 20.4027548 },
    { lat: 48.604643, lng: 20.4033055 },
    { lat: 48.6041045, lng: 20.404269 },
    { lat: 48.6039544, lng: 20.4044113 },
    { lat: 48.6037784, lng: 20.4044291 },
    { lat: 48.603366, lng: 20.4043633 },
    { lat: 48.6029211, lng: 20.404035 },
    { lat: 48.6026897, lng: 20.4039342 },
    { lat: 48.6022577, lng: 20.4033581 },
    { lat: 48.6015169, lng: 20.403569 },
    { lat: 48.6012396, lng: 20.4033844 },
    { lat: 48.6010909, lng: 20.4030871 },
    { lat: 48.6009022, lng: 20.4029236 },
    { lat: 48.6004593, lng: 20.4026548 },
    { lat: 48.6001352, lng: 20.4020417 },
    { lat: 48.5999716, lng: 20.4012722 },
    { lat: 48.5996873, lng: 20.4007341 },
    { lat: 48.5991739, lng: 20.4002617 },
    { lat: 48.5990641, lng: 20.3999842 },
    { lat: 48.5987845, lng: 20.3996827 },
    { lat: 48.5976137, lng: 20.3986672 },
    { lat: 48.5973286, lng: 20.398691 },
    { lat: 48.5970319, lng: 20.398533 },
    { lat: 48.5968933, lng: 20.3983836 },
    { lat: 48.5967049, lng: 20.3979952 },
    { lat: 48.5965104, lng: 20.3977963 },
    { lat: 48.5959144, lng: 20.3975228 },
    { lat: 48.5956483, lng: 20.3972782 },
    { lat: 48.5954069, lng: 20.3972968 },
    { lat: 48.5950659, lng: 20.3971299 },
    { lat: 48.5949518, lng: 20.3969125 },
    { lat: 48.5948403, lng: 20.3962951 },
    { lat: 48.5948102, lng: 20.3957214 },
    { lat: 48.5946592, lng: 20.3953985 },
    { lat: 48.5944351, lng: 20.3955411 },
    { lat: 48.5935979, lng: 20.3957299 },
    { lat: 48.5929868, lng: 20.3960647 },
    { lat: 48.59244, lng: 20.3960759 },
    { lat: 48.592309, lng: 20.3963313 },
    { lat: 48.5918666, lng: 20.3967143 },
    { lat: 48.5914799, lng: 20.3968506 },
    { lat: 48.5911337, lng: 20.3968364 },
    { lat: 48.5908546, lng: 20.3972246 },
    { lat: 48.5905767, lng: 20.3982815 },
    { lat: 48.5903259, lng: 20.3989327 },
    { lat: 48.5901296, lng: 20.4004506 },
    { lat: 48.5897536, lng: 20.4009839 },
    { lat: 48.5895206, lng: 20.4010405 },
    { lat: 48.5893871, lng: 20.4011407 },
    { lat: 48.5890904, lng: 20.4014821 },
    { lat: 48.5888169, lng: 20.401692 },
    { lat: 48.5879387, lng: 20.4028055 },
    { lat: 48.5859814, lng: 20.4039743 },
    { lat: 48.5846285, lng: 20.4046354 },
    { lat: 48.5843195, lng: 20.4045653 },
    { lat: 48.5841589, lng: 20.4044317 },
    { lat: 48.5836647, lng: 20.4045242 },
    { lat: 48.5831139, lng: 20.4044591 },
    { lat: 48.5825301, lng: 20.4045947 },
    { lat: 48.5821636, lng: 20.4045207 },
    { lat: 48.5818946, lng: 20.4046135 },
    { lat: 48.5815828, lng: 20.4045859 },
    { lat: 48.5813802, lng: 20.4047985 },
    { lat: 48.5811424, lng: 20.4052602 },
    { lat: 48.5809464, lng: 20.4054949 },
    { lat: 48.5806528, lng: 20.4055863 },
    { lat: 48.580463, lng: 20.4058355 },
    { lat: 48.5798668, lng: 20.4060603 },
    { lat: 48.5794939, lng: 20.4060832 },
    { lat: 48.5792346, lng: 20.40646 },
    { lat: 48.5788042, lng: 20.4068339 },
    { lat: 48.5778261, lng: 20.4074316 },
    { lat: 48.577267, lng: 20.4078584 },
    { lat: 48.5767453, lng: 20.4080553 },
    { lat: 48.5763915, lng: 20.4059646 },
    { lat: 48.5762909, lng: 20.4058934 },
    { lat: 48.5756281, lng: 20.4015086 },
    { lat: 48.5752388, lng: 20.399293 },
    { lat: 48.5762012, lng: 20.3989025 },
    { lat: 48.5767072, lng: 20.3988498 },
    { lat: 48.5769894, lng: 20.3987512 },
    { lat: 48.5772889, lng: 20.3988132 },
    { lat: 48.5776503, lng: 20.3987507 },
    { lat: 48.5786556, lng: 20.3981551 },
    { lat: 48.5792797, lng: 20.397873 },
    { lat: 48.5794625, lng: 20.3976198 },
    { lat: 48.5791291, lng: 20.3966194 },
    { lat: 48.5789117, lng: 20.3945395 },
    { lat: 48.5785947, lng: 20.393501 },
    { lat: 48.578171, lng: 20.3932571 },
    { lat: 48.5778333, lng: 20.3921241 },
    { lat: 48.576828, lng: 20.3927159 },
    { lat: 48.5764747, lng: 20.3927813 },
    { lat: 48.5758038, lng: 20.3926092 },
    { lat: 48.5755751, lng: 20.3926093 },
    { lat: 48.5755255, lng: 20.3925453 },
    { lat: 48.575697, lng: 20.3922931 },
    { lat: 48.5758058, lng: 20.3923437 },
    { lat: 48.5759981, lng: 20.39219 },
    { lat: 48.5760522, lng: 20.3916654 },
    { lat: 48.5762923, lng: 20.3915277 },
    { lat: 48.5765424, lng: 20.3912306 },
    { lat: 48.5767082, lng: 20.3912101 },
    { lat: 48.5767048, lng: 20.3907434 },
    { lat: 48.576653, lng: 20.3903899 },
    { lat: 48.5764862, lng: 20.3898657 },
    { lat: 48.5757321, lng: 20.383855 },
    { lat: 48.5762912, lng: 20.3834608 },
    { lat: 48.5764267, lng: 20.3834443 },
    { lat: 48.5767573, lng: 20.3831077 },
    { lat: 48.5769081, lng: 20.3828146 },
    { lat: 48.576976, lng: 20.3825313 },
    { lat: 48.5769961, lng: 20.3821163 },
    { lat: 48.5769238, lng: 20.3817357 },
    { lat: 48.5769362, lng: 20.3814444 },
    { lat: 48.577099, lng: 20.3807911 },
    { lat: 48.5773664, lng: 20.3789956 },
    { lat: 48.5775829, lng: 20.37868 },
    { lat: 48.5782257, lng: 20.3780642 },
    { lat: 48.5784988, lng: 20.3775517 },
    { lat: 48.578646, lng: 20.3769342 },
    { lat: 48.578908, lng: 20.3765751 },
    { lat: 48.5800193, lng: 20.3753441 },
    { lat: 48.5803421, lng: 20.3748371 },
    { lat: 48.5807039, lng: 20.3744106 },
    { lat: 48.5809614, lng: 20.3736425 },
    { lat: 48.5814925, lng: 20.3731115 },
    { lat: 48.5815873, lng: 20.3720341 },
    { lat: 48.58115, lng: 20.370631 },
    { lat: 48.5811788, lng: 20.3704133 },
    { lat: 48.5811505, lng: 20.3695957 },
    { lat: 48.58106, lng: 20.3688543 },
    { lat: 48.580749, lng: 20.3672513 },
    { lat: 48.5804824, lng: 20.3660944 },
    { lat: 48.580274, lng: 20.3653933 },
    { lat: 48.5801801, lng: 20.3641937 },
    { lat: 48.5799722, lng: 20.363451 },
    { lat: 48.5799172, lng: 20.3630493 },
    { lat: 48.5799893, lng: 20.3623224 },
    { lat: 48.579925, lng: 20.3611274 },
    { lat: 48.5797781, lng: 20.360333 },
    { lat: 48.5796781, lng: 20.3590004 },
    { lat: 48.5796129, lng: 20.3587988 },
    { lat: 48.5794926, lng: 20.3573527 },
    { lat: 48.5795014, lng: 20.3568021 },
    { lat: 48.5794065, lng: 20.3562108 },
    { lat: 48.5795519, lng: 20.3556652 },
    { lat: 48.5795269, lng: 20.3555135 },
    { lat: 48.5791443, lng: 20.3551702 },
    { lat: 48.5788556, lng: 20.3547846 },
    { lat: 48.5787177, lng: 20.3545086 },
    { lat: 48.5779677, lng: 20.3541928 },
    { lat: 48.5776849, lng: 20.354014 },
    { lat: 48.5773486, lng: 20.3536777 },
    { lat: 48.5759253, lng: 20.3526365 },
    { lat: 48.5756991, lng: 20.3525245 },
    { lat: 48.5753725, lng: 20.3526397 },
    { lat: 48.5751916, lng: 20.3528239 },
    { lat: 48.5751721, lng: 20.3528445 },
    { lat: 48.5748972, lng: 20.3531009 },
    { lat: 48.5747111, lng: 20.3531192 },
    { lat: 48.5737098, lng: 20.3529418 },
    { lat: 48.5731041, lng: 20.3524818 },
    { lat: 48.5727994, lng: 20.3524088 },
    { lat: 48.5721226, lng: 20.3520012 },
    { lat: 48.5715304, lng: 20.3518878 },
    { lat: 48.5706766, lng: 20.351913 },
    { lat: 48.5700682, lng: 20.3518497 },
    { lat: 48.569131, lng: 20.3521013 },
    { lat: 48.5674944, lng: 20.351894 },
    { lat: 48.5666842, lng: 20.3523705 },
    { lat: 48.5642184, lng: 20.3541481 },
    { lat: 48.5637058, lng: 20.3545797 },
    { lat: 48.5623039, lng: 20.354757 },
    { lat: 48.5615936, lng: 20.3549693 },
    { lat: 48.5610521, lng: 20.3547394 },
    { lat: 48.5605201, lng: 20.3546089 },
    { lat: 48.5598889, lng: 20.3551765 },
    { lat: 48.5594499, lng: 20.3553254 },
    { lat: 48.5583535, lng: 20.3559738 },
    { lat: 48.5565474, lng: 20.3579217 },
    { lat: 48.5555847, lng: 20.3592002 },
    { lat: 48.5530261, lng: 20.3631205 },
    { lat: 48.5522547, lng: 20.3647779 },
    { lat: 48.551567, lng: 20.3660974 },
    { lat: 48.5504826, lng: 20.3677207 },
    { lat: 48.5500906, lng: 20.3683882 },
    { lat: 48.549158, lng: 20.3696068 },
    { lat: 48.5486117, lng: 20.3709414 },
    { lat: 48.5482089, lng: 20.3715039 },
    { lat: 48.5472504, lng: 20.3720231 },
    { lat: 48.5458629, lng: 20.3736148 },
    { lat: 48.5453898, lng: 20.3745431 },
    { lat: 48.5447919, lng: 20.3753916 },
    { lat: 48.544349, lng: 20.3761672 },
    { lat: 48.5440313, lng: 20.3768604 },
    { lat: 48.5438114, lng: 20.3770883 },
    { lat: 48.5436536, lng: 20.3773466 },
    { lat: 48.5428858, lng: 20.378721 },
    { lat: 48.5424137, lng: 20.3797375 },
    { lat: 48.5418152, lng: 20.3804794 },
    { lat: 48.5405206, lng: 20.381568 },
    { lat: 48.5401704, lng: 20.381975 },
    { lat: 48.5398393, lng: 20.3822076 },
    { lat: 48.5396755, lng: 20.3824323 },
    { lat: 48.5394014, lng: 20.3824783 },
    { lat: 48.5390353, lng: 20.3823281 },
    { lat: 48.5397453, lng: 20.3842396 },
    { lat: 48.5401831, lng: 20.3855449 },
    { lat: 48.5403828, lng: 20.3863405 },
    { lat: 48.5408405, lng: 20.3875492 },
    { lat: 48.5416341, lng: 20.3886215 },
    { lat: 48.5424185, lng: 20.3899851 },
    { lat: 48.5430887, lng: 20.3908917 },
    { lat: 48.5432618, lng: 20.3910009 },
    { lat: 48.5430999, lng: 20.3910368 },
    { lat: 48.5424747, lng: 20.3904205 },
    { lat: 48.541354, lng: 20.3894762 },
    { lat: 48.5414063, lng: 20.3893159 },
    { lat: 48.5409358, lng: 20.3891158 },
    { lat: 48.5371598, lng: 20.3859612 },
    { lat: 48.5370492, lng: 20.3862192 },
    { lat: 48.5366957, lng: 20.3865984 },
    { lat: 48.5359362, lng: 20.3880734 },
    { lat: 48.5352287, lng: 20.3895853 },
    { lat: 48.5349441, lng: 20.3903255 },
    { lat: 48.5343329, lng: 20.3902698 },
    { lat: 48.5340521, lng: 20.3903498 },
    { lat: 48.5338151, lng: 20.3905368 },
    { lat: 48.53381, lng: 20.390496 },
    { lat: 48.5336793, lng: 20.3905522 },
    { lat: 48.5336355, lng: 20.3904693 },
    { lat: 48.5335449, lng: 20.3904517 },
    { lat: 48.5332604, lng: 20.390727 },
    { lat: 48.5328233, lng: 20.3902845 },
    { lat: 48.5322907, lng: 20.3900223 },
    { lat: 48.5298705, lng: 20.3894323 },
    { lat: 48.5295256, lng: 20.3891601 },
    { lat: 48.5293181, lng: 20.3889196 },
    { lat: 48.5267869, lng: 20.3851065 },
    { lat: 48.5261524, lng: 20.3843062 },
    { lat: 48.5256146, lng: 20.383824 },
    { lat: 48.5251615, lng: 20.3836334 },
    { lat: 48.5242532, lng: 20.3834753 },
    { lat: 48.5238098, lng: 20.3835666 },
    { lat: 48.5237148, lng: 20.3835863 },
    { lat: 48.523721, lng: 20.384097 },
    { lat: 48.5236758, lng: 20.3842384 },
    { lat: 48.5232232, lng: 20.3843313 },
    { lat: 48.5234375, lng: 20.3852131 },
    { lat: 48.5233012, lng: 20.3854776 },
    { lat: 48.5231999, lng: 20.3859149 },
    { lat: 48.5231514, lng: 20.3859134 },
    { lat: 48.5231439, lng: 20.386097 },
    { lat: 48.5231925, lng: 20.3861038 },
    { lat: 48.522832, lng: 20.3891012 },
    { lat: 48.5228782, lng: 20.3891313 },
    { lat: 48.5226654, lng: 20.3902465 },
    { lat: 48.5227488, lng: 20.3908293 },
    { lat: 48.5226695, lng: 20.3916358 },
    { lat: 48.5227471, lng: 20.3921019 },
    { lat: 48.5232145, lng: 20.3929509 },
    { lat: 48.5237489, lng: 20.3936637 },
    { lat: 48.5238576, lng: 20.3939736 },
    { lat: 48.5247851, lng: 20.3951914 },
    { lat: 48.524936, lng: 20.3952486 },
    { lat: 48.5250344, lng: 20.395407 },
    { lat: 48.5252567, lng: 20.3961812 },
    { lat: 48.5255002, lng: 20.3965958 },
    { lat: 48.5256213, lng: 20.3965803 },
    { lat: 48.5257769, lng: 20.3966747 },
    { lat: 48.5259333, lng: 20.3968753 },
    { lat: 48.5259474, lng: 20.3970087 },
    { lat: 48.526042, lng: 20.3970388 },
    { lat: 48.5261584, lng: 20.3973079 },
    { lat: 48.5261366, lng: 20.3974181 },
    { lat: 48.5261831, lng: 20.397515 },
    { lat: 48.528144, lng: 20.3969076 },
    { lat: 48.5281747, lng: 20.3971529 },
    { lat: 48.528156, lng: 20.3978629 },
    { lat: 48.5287615, lng: 20.4019835 },
    { lat: 48.528934, lng: 20.4030713 },
    { lat: 48.5291875, lng: 20.4036933 },
    { lat: 48.5298914, lng: 20.4050112 },
    { lat: 48.5304521, lng: 20.4058306 },
    { lat: 48.5308261, lng: 20.4067993 },
    { lat: 48.5309823, lng: 20.4070582 },
    { lat: 48.5313058, lng: 20.4074137 },
    { lat: 48.5312801, lng: 20.4075065 },
    { lat: 48.5317918, lng: 20.4083996 },
    { lat: 48.531936, lng: 20.4094266 },
    { lat: 48.5320266, lng: 20.4096181 },
    { lat: 48.5321288, lng: 20.4097289 },
    { lat: 48.5335658, lng: 20.4105802 },
    { lat: 48.5341224, lng: 20.4113533 },
    { lat: 48.5343044, lng: 20.4119585 },
    { lat: 48.5357151, lng: 20.4154743 },
    { lat: 48.5368613, lng: 20.4171769 },
    { lat: 48.5369733, lng: 20.4175669 },
    { lat: 48.5369133, lng: 20.4185333 },
    { lat: 48.5367381, lng: 20.4194837 },
    { lat: 48.5367383, lng: 20.4199286 },
    { lat: 48.5370438, lng: 20.4218478 },
    { lat: 48.5370067, lng: 20.4221495 },
    { lat: 48.536589, lng: 20.4232407 },
    { lat: 48.5364365, lng: 20.4234917 },
    { lat: 48.5362734, lng: 20.4236988 },
    { lat: 48.5357781, lng: 20.4239084 },
    { lat: 48.5356243, lng: 20.4240699 },
    { lat: 48.5355312, lng: 20.4243761 },
    { lat: 48.5354955, lng: 20.4248851 },
    { lat: 48.5355802, lng: 20.425711 },
    { lat: 48.536, lng: 20.426855 },
    { lat: 48.5361058, lng: 20.4326314 },
    { lat: 48.5361786, lng: 20.4335046 },
    { lat: 48.5366227, lng: 20.4368512 },
    { lat: 48.5372988, lng: 20.4401122 },
    { lat: 48.5379714, lng: 20.4446442 },
    { lat: 48.5385333, lng: 20.4462373 },
    { lat: 48.5385909, lng: 20.4466587 },
    { lat: 48.5389883, lng: 20.4532574 },
    { lat: 48.5391604, lng: 20.4546735 },
    { lat: 48.5391941, lng: 20.4555049 },
    { lat: 48.539165, lng: 20.4579706 },
    { lat: 48.539005, lng: 20.4591822 },
    { lat: 48.5384094, lng: 20.461084 },
    { lat: 48.5382378, lng: 20.4614871 },
    { lat: 48.5378687, lng: 20.4619715 },
    { lat: 48.5373838, lng: 20.4622207 },
    { lat: 48.5371726, lng: 20.4624119 },
    { lat: 48.53711, lng: 20.462692 },
    { lat: 48.5371602, lng: 20.4629456 },
    { lat: 48.5372672, lng: 20.4631848 },
    { lat: 48.5377612, lng: 20.463946 },
    { lat: 48.5377796, lng: 20.4642255 },
    { lat: 48.5379165, lng: 20.4641214 },
    { lat: 48.5379582, lng: 20.4643313 },
    { lat: 48.5381703, lng: 20.4662119 },
    { lat: 48.5382329, lng: 20.4672718 },
    { lat: 48.5396307, lng: 20.4679939 },
    { lat: 48.541632, lng: 20.4685081 },
    { lat: 48.5422748, lng: 20.4690389 },
    { lat: 48.5427028, lng: 20.4699364 },
    { lat: 48.543239, lng: 20.4698832 },
    { lat: 48.5449718, lng: 20.4714825 },
    { lat: 48.5460921, lng: 20.4722239 },
    { lat: 48.5501845, lng: 20.4756297 },
    { lat: 48.5506767, lng: 20.4758714 },
    { lat: 48.5535961, lng: 20.4781869 },
    { lat: 48.5542691, lng: 20.4788056 },
    { lat: 48.5572414, lng: 20.4810912 },
    { lat: 48.5572874, lng: 20.4811168 },
    { lat: 48.5573681, lng: 20.4811053 },
    { lat: 48.5573278, lng: 20.4806626 },
    { lat: 48.5572144, lng: 20.480346 },
    { lat: 48.5571987, lng: 20.477486 },
    { lat: 48.5570637, lng: 20.4767103 },
    { lat: 48.556566, lng: 20.4759973 },
    { lat: 48.5564221, lng: 20.4746941 },
    { lat: 48.5565045, lng: 20.474071 },
    { lat: 48.5564614, lng: 20.4736162 },
    { lat: 48.5564989, lng: 20.4732682 },
    { lat: 48.5564947, lng: 20.4723817 },
    { lat: 48.5566951, lng: 20.4703566 },
    { lat: 48.5567037, lng: 20.4692437 },
    { lat: 48.5568008, lng: 20.4686001 },
    { lat: 48.5568565, lng: 20.4677835 },
    { lat: 48.5574286, lng: 20.4664728 },
    { lat: 48.5574491, lng: 20.4662158 },
    { lat: 48.556845, lng: 20.4646188 },
    { lat: 48.5571147, lng: 20.4635374 },
    { lat: 48.5575778, lng: 20.4629246 },
    { lat: 48.5580308, lng: 20.4620702 },
    { lat: 48.5582079, lng: 20.461574 },
    { lat: 48.5582361, lng: 20.4614223 },
    { lat: 48.5583121, lng: 20.4610809 },
    { lat: 48.5582926, lng: 20.4605672 },
    { lat: 48.5579286, lng: 20.4591084 },
    { lat: 48.5579114, lng: 20.4587715 },
    { lat: 48.5578995, lng: 20.4578675 },
    { lat: 48.5581507, lng: 20.4563241 },
    { lat: 48.5579291, lng: 20.4557393 },
    { lat: 48.5577663, lng: 20.4555699 },
    { lat: 48.556856, lng: 20.4552915 },
    { lat: 48.5562898, lng: 20.4537852 },
    { lat: 48.5562642, lng: 20.4527893 },
    { lat: 48.5561521, lng: 20.4514333 },
    { lat: 48.5562329, lng: 20.4508348 },
    { lat: 48.5565794, lng: 20.4502355 },
    { lat: 48.556698, lng: 20.4497739 },
    { lat: 48.5566191, lng: 20.4491199 },
    { lat: 48.5564572, lng: 20.4489771 },
    { lat: 48.5572875, lng: 20.4471811 },
    { lat: 48.5574566, lng: 20.4468868 },
    { lat: 48.5581245, lng: 20.4460044 },
    { lat: 48.5584276, lng: 20.4457465 },
    { lat: 48.5586041, lng: 20.4454739 },
    { lat: 48.5588336, lng: 20.4452621 },
    { lat: 48.5594121, lng: 20.444365 },
    { lat: 48.5597873, lng: 20.4436607 },
    { lat: 48.5599367, lng: 20.4433574 },
    { lat: 48.5599838, lng: 20.4430828 },
    { lat: 48.5602562, lng: 20.443022 },
    { lat: 48.5607775, lng: 20.442604 },
    { lat: 48.5602404, lng: 20.4408303 },
    { lat: 48.559665, lng: 20.4383189 },
    { lat: 48.5595133, lng: 20.4370889 },
    { lat: 48.5595085, lng: 20.4367569 },
    { lat: 48.5594643, lng: 20.436663 },
    { lat: 48.5594795, lng: 20.4361445 },
    { lat: 48.55944, lng: 20.4359321 },
    { lat: 48.5593626, lng: 20.4358844 },
    { lat: 48.5593692, lng: 20.4350797 },
    { lat: 48.5590797, lng: 20.4342257 },
    { lat: 48.558786, lng: 20.4328747 },
    { lat: 48.558607, lng: 20.4323702 },
    { lat: 48.5589857, lng: 20.4318173 },
    { lat: 48.5589163, lng: 20.431652 },
    { lat: 48.5586515, lng: 20.4314385 },
    { lat: 48.5588008, lng: 20.4310495 },
    { lat: 48.5594105, lng: 20.4301773 },
    { lat: 48.55948, lng: 20.4299871 },
    { lat: 48.5598902, lng: 20.4293778 },
    { lat: 48.5600192, lng: 20.4289813 },
    { lat: 48.5602106, lng: 20.4288033 },
    { lat: 48.5602837, lng: 20.4286235 },
    { lat: 48.5608668, lng: 20.4280603 },
    { lat: 48.5610128, lng: 20.4281088 },
    { lat: 48.5612204, lng: 20.4278846 },
    { lat: 48.5614811, lng: 20.4278421 },
    { lat: 48.5621366, lng: 20.4272221 },
    { lat: 48.5624864, lng: 20.4258608 },
    { lat: 48.5637863, lng: 20.4273432 },
    { lat: 48.5640456, lng: 20.4277068 },
    { lat: 48.5643079, lng: 20.4279066 },
    { lat: 48.5651312, lng: 20.4288278 },
    { lat: 48.5655823, lng: 20.4289917 },
    { lat: 48.5661898, lng: 20.4296252 },
    { lat: 48.5677464, lng: 20.4309917 },
    { lat: 48.5676855, lng: 20.4322019 },
    { lat: 48.5678397, lng: 20.4338503 },
    { lat: 48.5680774, lng: 20.4343222 },
    { lat: 48.5684328, lng: 20.4348435 },
    { lat: 48.5684634, lng: 20.4353785 },
    { lat: 48.5687796, lng: 20.4360224 },
    { lat: 48.5699714, lng: 20.4376294 },
    { lat: 48.5708433, lng: 20.4386772 },
    { lat: 48.5714698, lng: 20.439866 },
    { lat: 48.5721114, lng: 20.4407168 },
    { lat: 48.5723174, lng: 20.4411616 },
    { lat: 48.5727415, lng: 20.4415058 },
    { lat: 48.5725868, lng: 20.4432063 },
    { lat: 48.5722679, lng: 20.4437046 },
    { lat: 48.5722049, lng: 20.44477 },
    { lat: 48.5724257, lng: 20.4464637 },
    { lat: 48.5725977, lng: 20.4469598 },
    { lat: 48.573187, lng: 20.4479163 },
    { lat: 48.5734023, lng: 20.4486572 },
    { lat: 48.5737942, lng: 20.4496072 },
    { lat: 48.5744171, lng: 20.4494944 },
    { lat: 48.5746248, lng: 20.4489737 },
    { lat: 48.5748646, lng: 20.4485611 },
    { lat: 48.5752297, lng: 20.4486224 },
    { lat: 48.5757691, lng: 20.448834 },
    { lat: 48.5761975, lng: 20.4488749 },
    { lat: 48.5770399, lng: 20.4484356 },
    { lat: 48.577611, lng: 20.4487351 },
    { lat: 48.5781104, lng: 20.4485441 },
    { lat: 48.5789207, lng: 20.4484759 },
    { lat: 48.5798497, lng: 20.4482097 },
    { lat: 48.5811829, lng: 20.4485032 },
    { lat: 48.5823014, lng: 20.4489269 },
    { lat: 48.5827164, lng: 20.4489426 },
    { lat: 48.5840537, lng: 20.4502384 },
    { lat: 48.5845197, lng: 20.4503166 },
    { lat: 48.5851575, lng: 20.4502747 },
    { lat: 48.586888, lng: 20.4513346 },
    { lat: 48.5872626, lng: 20.4521207 },
    { lat: 48.5874965, lng: 20.4528008 },
    { lat: 48.5880284, lng: 20.4540826 },
    { lat: 48.5895308, lng: 20.4566129 },
    { lat: 48.5923842, lng: 20.4573586 },
    { lat: 48.5927532, lng: 20.4587702 },
    { lat: 48.5929012, lng: 20.4589951 },
    { lat: 48.5933146, lng: 20.4593565 },
    { lat: 48.5941015, lng: 20.460301 },
    { lat: 48.5945894, lng: 20.4611095 },
    { lat: 48.5948269, lng: 20.461761 },
    { lat: 48.5956474, lng: 20.4622296 },
    { lat: 48.5958213, lng: 20.4622684 },
    { lat: 48.5960528, lng: 20.4620496 },
    { lat: 48.5963279, lng: 20.4621188 },
    { lat: 48.5976158, lng: 20.4628837 },
    { lat: 48.5979759, lng: 20.4631465 },
    { lat: 48.5984899, lng: 20.4637544 },
    { lat: 48.5987462, lng: 20.4639806 },
    { lat: 48.5993315, lng: 20.4648993 },
    { lat: 48.5994962, lng: 20.4647855 },
    { lat: 48.599798, lng: 20.4644316 },
    { lat: 48.6005975, lng: 20.4644574 },
    { lat: 48.6008815, lng: 20.4643451 },
    { lat: 48.6012346, lng: 20.4643044 },
    { lat: 48.601491, lng: 20.4641763 },
    { lat: 48.601719, lng: 20.4642765 },
    { lat: 48.6023391, lng: 20.4640645 },
    { lat: 48.602577, lng: 20.4639554 },
    { lat: 48.6034821, lng: 20.4637971 },
    { lat: 48.6043014, lng: 20.4634872 },
    { lat: 48.604607, lng: 20.4629656 },
    { lat: 48.6047455, lng: 20.4624922 },
    { lat: 48.6050974, lng: 20.4605684 },
    { lat: 48.6055324, lng: 20.4602861 },
    { lat: 48.6064734, lng: 20.4603043 },
    { lat: 48.6066901, lng: 20.4600829 },
    { lat: 48.6072069, lng: 20.4592445 },
    { lat: 48.6084308, lng: 20.4587384 },
    { lat: 48.6087908, lng: 20.4589372 },
    { lat: 48.6098567, lng: 20.4591069 },
    { lat: 48.6103096, lng: 20.4592885 },
    { lat: 48.6105815, lng: 20.4595398 },
    { lat: 48.6110053, lng: 20.45961 },
    { lat: 48.6113231, lng: 20.4594705 },
    { lat: 48.6116428, lng: 20.458874 },
    { lat: 48.6117215, lng: 20.4586345 },
    { lat: 48.6117744, lng: 20.4583912 },
    { lat: 48.6117127, lng: 20.4577478 },
    { lat: 48.6117527, lng: 20.4575511 },
    { lat: 48.6122193, lng: 20.4572056 },
    { lat: 48.6124121, lng: 20.456947 },
    { lat: 48.6131552, lng: 20.4564694 },
    { lat: 48.6138098, lng: 20.4558276 },
    { lat: 48.6143163, lng: 20.4545455 },
    { lat: 48.6148065, lng: 20.4541669 },
    { lat: 48.6150818, lng: 20.4537681 },
    { lat: 48.6155238, lng: 20.4533086 },
    { lat: 48.6157708, lng: 20.4533204 },
    { lat: 48.6165903, lng: 20.4530788 },
    { lat: 48.6171175, lng: 20.4529928 },
    { lat: 48.617436, lng: 20.4528428 },
    { lat: 48.6178284, lng: 20.4530047 },
    { lat: 48.6179345, lng: 20.4529777 },
    { lat: 48.6183336, lng: 20.4522529 },
    { lat: 48.6187945, lng: 20.4518447 },
    { lat: 48.6190918, lng: 20.4513717 },
    { lat: 48.6194509, lng: 20.4505069 },
    { lat: 48.6198756, lng: 20.4497093 },
    { lat: 48.6200373, lng: 20.4492533 },
    { lat: 48.620195, lng: 20.4491375 },
    { lat: 48.6203574, lng: 20.4490882 },
    { lat: 48.6212807, lng: 20.4495612 },
    { lat: 48.6214342, lng: 20.4497757 },
    { lat: 48.6231586, lng: 20.4512416 },
    { lat: 48.62393, lng: 20.4515668 },
    { lat: 48.6249482, lng: 20.4514867 },
    { lat: 48.6254426, lng: 20.4513376 },
    { lat: 48.625466, lng: 20.4513327 },
    { lat: 48.6255166, lng: 20.4496401 },
    { lat: 48.6259009, lng: 20.4474564 },
    { lat: 48.6262781, lng: 20.4468329 },
    { lat: 48.6267343, lng: 20.445742 },
    { lat: 48.6270031, lng: 20.4453144 },
    { lat: 48.6274315, lng: 20.4449157 },
    { lat: 48.6283496, lng: 20.4437424 },
    { lat: 48.6289633, lng: 20.4434174 },
    { lat: 48.629233, lng: 20.4431338 },
    { lat: 48.629747, lng: 20.4427309 },
    { lat: 48.6300706, lng: 20.4423298 },
    { lat: 48.6307881, lng: 20.4409456 },
    { lat: 48.631736, lng: 20.4408778 },
    { lat: 48.6321626, lng: 20.4405184 },
    { lat: 48.6325793, lng: 20.4403438 },
    { lat: 48.6335813, lng: 20.4408131 },
    { lat: 48.6338433, lng: 20.4408346 },
    { lat: 48.6341286, lng: 20.4406991 },
    { lat: 48.6346306, lng: 20.4402178 },
    { lat: 48.6350697, lng: 20.4401862 },
    { lat: 48.6348881, lng: 20.4394692 },
    { lat: 48.6346836, lng: 20.4388889 },
    { lat: 48.6347266, lng: 20.4384191 },
    { lat: 48.635004, lng: 20.4377771 },
    { lat: 48.6353446, lng: 20.4372602 },
    { lat: 48.6355236, lng: 20.4372329 },
    { lat: 48.6357674, lng: 20.4370103 },
    { lat: 48.6364107, lng: 20.4373405 },
    { lat: 48.6371091, lng: 20.4374078 },
    { lat: 48.6374371, lng: 20.4373284 },
    { lat: 48.6386748, lng: 20.4364436 },
    { lat: 48.6391162, lng: 20.4363302 },
    { lat: 48.6393583, lng: 20.4360005 },
    { lat: 48.6399351, lng: 20.4354158 },
    { lat: 48.6407466, lng: 20.4353482 },
    { lat: 48.6412635, lng: 20.4351421 },
    { lat: 48.6413983, lng: 20.4353099 },
    { lat: 48.6419934, lng: 20.4386088 },
    { lat: 48.6430518, lng: 20.4385808 },
    { lat: 48.6432947, lng: 20.43872 },
    { lat: 48.643483, lng: 20.4390783 },
    { lat: 48.6435852, lng: 20.4391602 },
    { lat: 48.6443597, lng: 20.4386224 },
    { lat: 48.6449195, lng: 20.4383278 },
    { lat: 48.6450652, lng: 20.4378655 },
    { lat: 48.6464015, lng: 20.4359087 },
    { lat: 48.646904, lng: 20.4348535 },
    { lat: 48.6479336, lng: 20.4339437 },
    { lat: 48.6480479, lng: 20.4335745 },
    { lat: 48.6486906, lng: 20.4327681 },
    { lat: 48.6491728, lng: 20.4325988 },
    { lat: 48.6497442, lng: 20.431932 },
    { lat: 48.6506091, lng: 20.4313484 },
    { lat: 48.6508892, lng: 20.4313106 },
    { lat: 48.651352, lng: 20.4309225 },
    { lat: 48.6518376, lng: 20.4301916 },
    { lat: 48.6520935, lng: 20.4298179 },
    { lat: 48.6519756, lng: 20.4287608 },
    { lat: 48.6520756, lng: 20.4275174 },
    { lat: 48.6525193, lng: 20.4262445 },
    { lat: 48.6538476, lng: 20.4250848 },
    { lat: 48.6544038, lng: 20.4244926 },
    { lat: 48.6547291, lng: 20.4243394 },
    { lat: 48.6549938, lng: 20.4243135 },
    { lat: 48.6556687, lng: 20.4245006 },
    { lat: 48.6560506, lng: 20.4244495 },
    { lat: 48.6576007, lng: 20.4234315 },
  ],
  Bohúňovo: [
    { lat: 48.5208492, lng: 20.3641902 },
    { lat: 48.5203072, lng: 20.3633701 },
    { lat: 48.5191391, lng: 20.3626106 },
    { lat: 48.5190464, lng: 20.3626554 },
    { lat: 48.518957, lng: 20.3626178 },
    { lat: 48.5185147, lng: 20.3620464 },
    { lat: 48.5181334, lng: 20.3618224 },
    { lat: 48.5177495, lng: 20.3614218 },
    { lat: 48.5171395, lng: 20.3611671 },
    { lat: 48.5168173, lng: 20.3609649 },
    { lat: 48.5165588, lng: 20.3606889 },
    { lat: 48.5162347, lng: 20.3601988 },
    { lat: 48.5158432, lng: 20.3592519 },
    { lat: 48.5155928, lng: 20.3589841 },
    { lat: 48.5153738, lng: 20.3589106 },
    { lat: 48.5150256, lng: 20.3589928 },
    { lat: 48.5142155, lng: 20.358949 },
    { lat: 48.5130969, lng: 20.358536 },
    { lat: 48.5128789, lng: 20.3585782 },
    { lat: 48.5126732, lng: 20.3587629 },
    { lat: 48.5125014, lng: 20.3591211 },
    { lat: 48.5119485, lng: 20.3592987 },
    { lat: 48.5117741, lng: 20.3592132 },
    { lat: 48.5117446, lng: 20.3591756 },
    { lat: 48.5115501, lng: 20.3594176 },
    { lat: 48.5114114, lng: 20.3594749 },
    { lat: 48.5109728, lng: 20.3591672 },
    { lat: 48.5105333, lng: 20.3590187 },
    { lat: 48.5097852, lng: 20.3584811 },
    { lat: 48.5092252, lng: 20.3583308 },
    { lat: 48.5089334, lng: 20.3583866 },
    { lat: 48.508522, lng: 20.358664 },
    { lat: 48.5078378, lng: 20.3587341 },
    { lat: 48.5078446, lng: 20.3587637 },
    { lat: 48.5080192, lng: 20.3595229 },
    { lat: 48.5081403, lng: 20.3605216 },
    { lat: 48.5083183, lng: 20.3611612 },
    { lat: 48.5083693, lng: 20.36152 },
    { lat: 48.5082381, lng: 20.3623469 },
    { lat: 48.5082391, lng: 20.3629811 },
    { lat: 48.5083871, lng: 20.3634863 },
    { lat: 48.5084363, lng: 20.3639152 },
    { lat: 48.508229, lng: 20.365044 },
    { lat: 48.5080848, lng: 20.365567 },
    { lat: 48.5076558, lng: 20.3664357 },
    { lat: 48.5075199, lng: 20.3669658 },
    { lat: 48.5071444, lng: 20.3679459 },
    { lat: 48.5068007, lng: 20.3694453 },
    { lat: 48.5068498, lng: 20.3705426 },
    { lat: 48.5067876, lng: 20.3720435 },
    { lat: 48.5065366, lng: 20.3755063 },
    { lat: 48.5062069, lng: 20.3786901 },
    { lat: 48.5061897, lng: 20.3814787 },
    { lat: 48.5059974, lng: 20.382183 },
    { lat: 48.5055974, lng: 20.3824673 },
    { lat: 48.5055746, lng: 20.3825503 },
    { lat: 48.5056872, lng: 20.3827212 },
    { lat: 48.5061659, lng: 20.3829023 },
    { lat: 48.5060961, lng: 20.3833061 },
    { lat: 48.5056936, lng: 20.3833286 },
    { lat: 48.5055275, lng: 20.3835185 },
    { lat: 48.5055784, lng: 20.3836679 },
    { lat: 48.5055939, lng: 20.3837148 },
    { lat: 48.5058465, lng: 20.3841341 },
    { lat: 48.5058464, lng: 20.3844228 },
    { lat: 48.5057926, lng: 20.3845153 },
    { lat: 48.505521, lng: 20.3843703 },
    { lat: 48.5053702, lng: 20.3843693 },
    { lat: 48.5048478, lng: 20.3845468 },
    { lat: 48.5045824, lng: 20.3849091 },
    { lat: 48.5043789, lng: 20.3850615 },
    { lat: 48.5040786, lng: 20.3851613 },
    { lat: 48.504106, lng: 20.3855779 },
    { lat: 48.5039733, lng: 20.3858431 },
    { lat: 48.5036531, lng: 20.3860397 },
    { lat: 48.5035167, lng: 20.3860325 },
    { lat: 48.5035025, lng: 20.3861062 },
    { lat: 48.5032352, lng: 20.3862673 },
    { lat: 48.5030365, lng: 20.3863159 },
    { lat: 48.5029068, lng: 20.3861884 },
    { lat: 48.5028264, lng: 20.3862938 },
    { lat: 48.502836, lng: 20.3864017 },
    { lat: 48.5026252, lng: 20.3866282 },
    { lat: 48.5024615, lng: 20.3869969 },
    { lat: 48.502382, lng: 20.38704 },
    { lat: 48.5023605, lng: 20.387179 },
    { lat: 48.5022285, lng: 20.3871468 },
    { lat: 48.5021896, lng: 20.3872149 },
    { lat: 48.501923, lng: 20.3872211 },
    { lat: 48.5018341, lng: 20.3873141 },
    { lat: 48.5018913, lng: 20.3874526 },
    { lat: 48.5018132, lng: 20.3874694 },
    { lat: 48.5016717, lng: 20.3876863 },
    { lat: 48.5016864, lng: 20.3879829 },
    { lat: 48.5016175, lng: 20.3880933 },
    { lat: 48.5014987, lng: 20.3881083 },
    { lat: 48.5013664, lng: 20.3883855 },
    { lat: 48.5012996, lng: 20.3884329 },
    { lat: 48.5011363, lng: 20.388842 },
    { lat: 48.5009266, lng: 20.3890961 },
    { lat: 48.5009407, lng: 20.3896087 },
    { lat: 48.500801, lng: 20.3897792 },
    { lat: 48.5007389, lng: 20.3901259 },
    { lat: 48.5007679, lng: 20.3904994 },
    { lat: 48.5006135, lng: 20.3905828 },
    { lat: 48.5005368, lng: 20.3906996 },
    { lat: 48.5004468, lng: 20.3913147 },
    { lat: 48.5003514, lng: 20.3915272 },
    { lat: 48.5004273, lng: 20.3921483 },
    { lat: 48.50039, lng: 20.3925823 },
    { lat: 48.5005025, lng: 20.3927592 },
    { lat: 48.5004589, lng: 20.3930602 },
    { lat: 48.5005399, lng: 20.3933195 },
    { lat: 48.5005068, lng: 20.3936306 },
    { lat: 48.500553, lng: 20.3943005 },
    { lat: 48.5006311, lng: 20.3948952 },
    { lat: 48.5007412, lng: 20.3952652 },
    { lat: 48.5008556, lng: 20.3962196 },
    { lat: 48.5008226, lng: 20.3963315 },
    { lat: 48.5010049, lng: 20.3964448 },
    { lat: 48.5010469, lng: 20.3965992 },
    { lat: 48.501226, lng: 20.3968619 },
    { lat: 48.501143, lng: 20.3969849 },
    { lat: 48.501165, lng: 20.3974411 },
    { lat: 48.5012728, lng: 20.3975183 },
    { lat: 48.5013578, lng: 20.3977101 },
    { lat: 48.501377, lng: 20.3980372 },
    { lat: 48.5015195, lng: 20.398398 },
    { lat: 48.501814, lng: 20.3996183 },
    { lat: 48.5050604, lng: 20.4138823 },
    { lat: 48.5063237, lng: 20.4192523 },
    { lat: 48.508659, lng: 20.4187133 },
    { lat: 48.5110175, lng: 20.4182568 },
    { lat: 48.5134199, lng: 20.4150992 },
    { lat: 48.5134543, lng: 20.4150536 },
    { lat: 48.5134784, lng: 20.4149503 },
    { lat: 48.5140201, lng: 20.4131892 },
    { lat: 48.514072, lng: 20.4113133 },
    { lat: 48.5140654, lng: 20.4087382 },
    { lat: 48.5141046, lng: 20.4082304 },
    { lat: 48.5139858, lng: 20.4080415 },
    { lat: 48.5139167, lng: 20.4075041 },
    { lat: 48.5144851, lng: 20.4064694 },
    { lat: 48.5147513, lng: 20.4062178 },
    { lat: 48.5153039, lng: 20.4059776 },
    { lat: 48.5155957, lng: 20.4056019 },
    { lat: 48.5157637, lng: 20.4055443 },
    { lat: 48.5165357, lng: 20.4045373 },
    { lat: 48.5168554, lng: 20.4043013 },
    { lat: 48.517524, lng: 20.4032357 },
    { lat: 48.5197434, lng: 20.4025862 },
    { lat: 48.5199318, lng: 20.4023073 },
    { lat: 48.5209136, lng: 20.4014825 },
    { lat: 48.521297, lng: 20.4010282 },
    { lat: 48.5213925, lng: 20.4006096 },
    { lat: 48.5214203, lng: 20.4001898 },
    { lat: 48.5213838, lng: 20.3998562 },
    { lat: 48.5212309, lng: 20.3993413 },
    { lat: 48.5212952, lng: 20.3991662 },
    { lat: 48.5212148, lng: 20.3985413 },
    { lat: 48.5207563, lng: 20.3977142 },
    { lat: 48.520701, lng: 20.3974491 },
    { lat: 48.5207616, lng: 20.3970612 },
    { lat: 48.520695, lng: 20.3966496 },
    { lat: 48.5206955, lng: 20.3962521 },
    { lat: 48.5205933, lng: 20.3960245 },
    { lat: 48.5204482, lng: 20.3955057 },
    { lat: 48.5204526, lng: 20.3953856 },
    { lat: 48.5211582, lng: 20.394555 },
    { lat: 48.5217358, lng: 20.3936463 },
    { lat: 48.5217346, lng: 20.3934997 },
    { lat: 48.5216136, lng: 20.3933412 },
    { lat: 48.5215513, lng: 20.3930106 },
    { lat: 48.5215485, lng: 20.3927597 },
    { lat: 48.5216147, lng: 20.3926111 },
    { lat: 48.5217078, lng: 20.3925404 },
    { lat: 48.5222049, lng: 20.3925254 },
    { lat: 48.5225671, lng: 20.3921929 },
    { lat: 48.5227471, lng: 20.3921019 },
    { lat: 48.5226695, lng: 20.3916358 },
    { lat: 48.5227488, lng: 20.3908293 },
    { lat: 48.5226654, lng: 20.3902465 },
    { lat: 48.5228782, lng: 20.3891313 },
    { lat: 48.522832, lng: 20.3891012 },
    { lat: 48.5231925, lng: 20.3861038 },
    { lat: 48.5231439, lng: 20.386097 },
    { lat: 48.5231514, lng: 20.3859134 },
    { lat: 48.5231999, lng: 20.3859149 },
    { lat: 48.5233012, lng: 20.3854776 },
    { lat: 48.5234375, lng: 20.3852131 },
    { lat: 48.5232232, lng: 20.3843313 },
    { lat: 48.5236758, lng: 20.3842384 },
    { lat: 48.523721, lng: 20.384097 },
    { lat: 48.5237148, lng: 20.3835863 },
    { lat: 48.5236075, lng: 20.3836078 },
    { lat: 48.5225355, lng: 20.3837553 },
    { lat: 48.5221908, lng: 20.3837268 },
    { lat: 48.5217306, lng: 20.3835596 },
    { lat: 48.5208749, lng: 20.3828058 },
    { lat: 48.5193385, lng: 20.3806083 },
    { lat: 48.518755, lng: 20.3800204 },
    { lat: 48.5185817, lng: 20.3801148 },
    { lat: 48.5177902, lng: 20.379186 },
    { lat: 48.5179929, lng: 20.3788318 },
    { lat: 48.5179292, lng: 20.3787582 },
    { lat: 48.5177338, lng: 20.3791238 },
    { lat: 48.5175311, lng: 20.3790024 },
    { lat: 48.5172484, lng: 20.3788331 },
    { lat: 48.5170071, lng: 20.3787914 },
    { lat: 48.5166478, lng: 20.3789348 },
    { lat: 48.5166152, lng: 20.3787941 },
    { lat: 48.5165432, lng: 20.3784751 },
    { lat: 48.5166019, lng: 20.378249 },
    { lat: 48.5166719, lng: 20.3773492 },
    { lat: 48.517154, lng: 20.3772952 },
    { lat: 48.517513, lng: 20.3770535 },
    { lat: 48.5176573, lng: 20.3770543 },
    { lat: 48.5178515, lng: 20.377189 },
    { lat: 48.5181456, lng: 20.3760262 },
    { lat: 48.5182274, lng: 20.3758403 },
    { lat: 48.5188532, lng: 20.3748775 },
    { lat: 48.5189942, lng: 20.3748204 },
    { lat: 48.5189887, lng: 20.3745609 },
    { lat: 48.5188439, lng: 20.3741981 },
    { lat: 48.518718, lng: 20.3733602 },
    { lat: 48.5184556, lng: 20.373011 },
    { lat: 48.5183828, lng: 20.3726142 },
    { lat: 48.5184066, lng: 20.3709607 },
    { lat: 48.5184453, lng: 20.3705954 },
    { lat: 48.5183476, lng: 20.3702235 },
    { lat: 48.5184577, lng: 20.3696917 },
    { lat: 48.5184599, lng: 20.3693711 },
    { lat: 48.5185755, lng: 20.3689805 },
    { lat: 48.5185666, lng: 20.3684648 },
    { lat: 48.5186427, lng: 20.3682169 },
    { lat: 48.5190798, lng: 20.3676411 },
    { lat: 48.5192799, lng: 20.3664971 },
    { lat: 48.5192743, lng: 20.3662117 },
    { lat: 48.519413, lng: 20.3659238 },
    { lat: 48.519623, lng: 20.3649804 },
    { lat: 48.5198314, lng: 20.3644026 },
    { lat: 48.5202234, lng: 20.3640822 },
    { lat: 48.5208492, lng: 20.3641902 },
  ],
  Gočovo: [
    { lat: 48.7445833, lng: 20.4472495 },
    { lat: 48.7449426, lng: 20.4475524 },
    { lat: 48.7453442, lng: 20.4481066 },
    { lat: 48.7459446, lng: 20.4491517 },
    { lat: 48.7463091, lng: 20.4494416 },
    { lat: 48.7478501, lng: 20.4501392 },
    { lat: 48.7485406, lng: 20.4503241 },
    { lat: 48.7488568, lng: 20.4505865 },
    { lat: 48.7490797, lng: 20.4506781 },
    { lat: 48.7493385, lng: 20.4511488 },
    { lat: 48.749578, lng: 20.4511681 },
    { lat: 48.7498662, lng: 20.4513158 },
    { lat: 48.7498781, lng: 20.4512745 },
    { lat: 48.7508375, lng: 20.4515736 },
    { lat: 48.7516372, lng: 20.4519679 },
    { lat: 48.7520907, lng: 20.4523743 },
    { lat: 48.7571299, lng: 20.4532774 },
    { lat: 48.7574266, lng: 20.4536161 },
    { lat: 48.7578291, lng: 20.4539021 },
    { lat: 48.7584183, lng: 20.4539967 },
    { lat: 48.7592316, lng: 20.4544523 },
    { lat: 48.7597128, lng: 20.4560152 },
    { lat: 48.7609787, lng: 20.4579243 },
    { lat: 48.7610776, lng: 20.4582495 },
    { lat: 48.7622318, lng: 20.4592036 },
    { lat: 48.7622735, lng: 20.4599844 },
    { lat: 48.7624068, lng: 20.4602881 },
    { lat: 48.7625183, lng: 20.4608309 },
    { lat: 48.7625721, lng: 20.4609119 },
    { lat: 48.762586, lng: 20.4616677 },
    { lat: 48.7626622, lng: 20.461866 },
    { lat: 48.7630368, lng: 20.46242 },
    { lat: 48.7630655, lng: 20.462738 },
    { lat: 48.7631766, lng: 20.4629619 },
    { lat: 48.7640448, lng: 20.4632331 },
    { lat: 48.7649875, lng: 20.4634165 },
    { lat: 48.7654635, lng: 20.4636195 },
    { lat: 48.7662504, lng: 20.4636454 },
    { lat: 48.7665176, lng: 20.4637469 },
    { lat: 48.7684736, lng: 20.4638968 },
    { lat: 48.7707927, lng: 20.4651397 },
    { lat: 48.7708281, lng: 20.4648296 },
    { lat: 48.7711563, lng: 20.4645093 },
    { lat: 48.7716129, lng: 20.4643175 },
    { lat: 48.7716947, lng: 20.4646966 },
    { lat: 48.771944, lng: 20.4650074 },
    { lat: 48.7726312, lng: 20.4647464 },
    { lat: 48.7725562, lng: 20.4650281 },
    { lat: 48.772677, lng: 20.4654218 },
    { lat: 48.7729423, lng: 20.465919 },
    { lat: 48.7731088, lng: 20.4661234 },
    { lat: 48.7733987, lng: 20.4667674 },
    { lat: 48.7734315, lng: 20.4667417 },
    { lat: 48.7739935, lng: 20.4689575 },
    { lat: 48.7746821, lng: 20.4696863 },
    { lat: 48.7762639, lng: 20.471658 },
    { lat: 48.7767464, lng: 20.4721797 },
    { lat: 48.7774474, lng: 20.4731807 },
    { lat: 48.7780215, lng: 20.4742297 },
    { lat: 48.7776608, lng: 20.4770586 },
    { lat: 48.7780199, lng: 20.4772105 },
    { lat: 48.7783279, lng: 20.4768034 },
    { lat: 48.7785671, lng: 20.4766407 },
    { lat: 48.7788675, lng: 20.4762699 },
    { lat: 48.7790848, lng: 20.4761205 },
    { lat: 48.7792253, lng: 20.4761481 },
    { lat: 48.7797225, lng: 20.4764644 },
    { lat: 48.7803952, lng: 20.4764136 },
    { lat: 48.7807796, lng: 20.4766975 },
    { lat: 48.7812717, lng: 20.4764877 },
    { lat: 48.7819272, lng: 20.476915 },
    { lat: 48.7825012, lng: 20.4767257 },
    { lat: 48.7826312, lng: 20.4768573 },
    { lat: 48.7830762, lng: 20.4770785 },
    { lat: 48.783779, lng: 20.4767245 },
    { lat: 48.7839184, lng: 20.4767782 },
    { lat: 48.784139, lng: 20.4767174 },
    { lat: 48.7844528, lng: 20.4770371 },
    { lat: 48.7847882, lng: 20.4769136 },
    { lat: 48.7849594, lng: 20.4765853 },
    { lat: 48.7850253, lng: 20.476299 },
    { lat: 48.785304, lng: 20.4761051 },
    { lat: 48.7854387, lng: 20.4758535 },
    { lat: 48.785716, lng: 20.475654 },
    { lat: 48.7858937, lng: 20.4756486 },
    { lat: 48.7860219, lng: 20.4755573 },
    { lat: 48.7861051, lng: 20.4754048 },
    { lat: 48.786385, lng: 20.4752128 },
    { lat: 48.7868619, lng: 20.4753435 },
    { lat: 48.7873005, lng: 20.4757524 },
    { lat: 48.7875496, lng: 20.4757242 },
    { lat: 48.7877233, lng: 20.4757949 },
    { lat: 48.787867, lng: 20.4757507 },
    { lat: 48.7880112, lng: 20.4758472 },
    { lat: 48.7880803, lng: 20.4757956 },
    { lat: 48.7887849, lng: 20.4763565 },
    { lat: 48.789132, lng: 20.47636 },
    { lat: 48.78912, lng: 20.476281 },
    { lat: 48.788881, lng: 20.475736 },
    { lat: 48.788905, lng: 20.475651 },
    { lat: 48.788851, lng: 20.4755738 },
    { lat: 48.7880145, lng: 20.4748557 },
    { lat: 48.7878322, lng: 20.4748268 },
    { lat: 48.7876799, lng: 20.4746724 },
    { lat: 48.7875767, lng: 20.4745017 },
    { lat: 48.7875366, lng: 20.4741827 },
    { lat: 48.7876128, lng: 20.4740562 },
    { lat: 48.7876747, lng: 20.4736934 },
    { lat: 48.7879785, lng: 20.4735108 },
    { lat: 48.7880985, lng: 20.4733512 },
    { lat: 48.7881422, lng: 20.4728957 },
    { lat: 48.7884308, lng: 20.4725138 },
    { lat: 48.7885871, lng: 20.4716687 },
    { lat: 48.7885164, lng: 20.471378 },
    { lat: 48.7885523, lng: 20.471135 },
    { lat: 48.7884864, lng: 20.4705504 },
    { lat: 48.7886527, lng: 20.4703615 },
    { lat: 48.7886831, lng: 20.4696757 },
    { lat: 48.7886511, lng: 20.4695417 },
    { lat: 48.7888607, lng: 20.4689173 },
    { lat: 48.7887523, lng: 20.4685821 },
    { lat: 48.7888646, lng: 20.4681564 },
    { lat: 48.7887594, lng: 20.4678134 },
    { lat: 48.7888498, lng: 20.467676 },
    { lat: 48.7887604, lng: 20.4670949 },
    { lat: 48.7886485, lng: 20.4669363 },
    { lat: 48.788436, lng: 20.4659873 },
    { lat: 48.7882959, lng: 20.4657331 },
    { lat: 48.7881249, lng: 20.464934 },
    { lat: 48.7882606, lng: 20.4645885 },
    { lat: 48.7882853, lng: 20.4638422 },
    { lat: 48.7882081, lng: 20.4637053 },
    { lat: 48.7882737, lng: 20.4632347 },
    { lat: 48.7881738, lng: 20.4630066 },
    { lat: 48.7881921, lng: 20.4627179 },
    { lat: 48.7883018, lng: 20.462517 },
    { lat: 48.7882218, lng: 20.4620651 },
    { lat: 48.788414, lng: 20.4611383 },
    { lat: 48.7885891, lng: 20.4607625 },
    { lat: 48.7884084, lng: 20.4604771 },
    { lat: 48.7881456, lng: 20.4596917 },
    { lat: 48.7881225, lng: 20.4592292 },
    { lat: 48.7879208, lng: 20.4588992 },
    { lat: 48.7879155, lng: 20.4586957 },
    { lat: 48.7878423, lng: 20.4585757 },
    { lat: 48.7878023, lng: 20.4575175 },
    { lat: 48.7877043, lng: 20.4572528 },
    { lat: 48.7875696, lng: 20.457202 },
    { lat: 48.7875313, lng: 20.45702 },
    { lat: 48.7873969, lng: 20.4568338 },
    { lat: 48.7871934, lng: 20.455888 },
    { lat: 48.7869021, lng: 20.4555097 },
    { lat: 48.7867693, lng: 20.4555362 },
    { lat: 48.7865029, lng: 20.455405 },
    { lat: 48.7863063, lng: 20.4553853 },
    { lat: 48.7857907, lng: 20.4550293 },
    { lat: 48.7850819, lng: 20.4550464 },
    { lat: 48.7842969, lng: 20.4545898 },
    { lat: 48.7837948, lng: 20.4545354 },
    { lat: 48.7836623, lng: 20.4543941 },
    { lat: 48.7835765, lng: 20.4546014 },
    { lat: 48.7833005, lng: 20.4559096 },
    { lat: 48.783006, lng: 20.4563393 },
    { lat: 48.7827029, lng: 20.4565719 },
    { lat: 48.7827171, lng: 20.4566178 },
    { lat: 48.7823354, lng: 20.4568254 },
    { lat: 48.7819007, lng: 20.4565845 },
    { lat: 48.7818862, lng: 20.4566497 },
    { lat: 48.7809141, lng: 20.456315 },
    { lat: 48.7805271, lng: 20.4560832 },
    { lat: 48.7800748, lng: 20.4556461 },
    { lat: 48.7796662, lng: 20.4553533 },
    { lat: 48.7795433, lng: 20.4551976 },
    { lat: 48.7785378, lng: 20.4543642 },
    { lat: 48.7781773, lng: 20.4537617 },
    { lat: 48.7778836, lng: 20.4535319 },
    { lat: 48.7775173, lng: 20.4528053 },
    { lat: 48.7772796, lng: 20.4525346 },
    { lat: 48.7769013, lng: 20.4523564 },
    { lat: 48.7763601, lng: 20.4523957 },
    { lat: 48.7760589, lng: 20.4522937 },
    { lat: 48.7760207, lng: 20.4513395 },
    { lat: 48.7761031, lng: 20.449713 },
    { lat: 48.7760176, lng: 20.4487323 },
    { lat: 48.7762419, lng: 20.4481141 },
    { lat: 48.7761211, lng: 20.4475091 },
    { lat: 48.7758482, lng: 20.4470273 },
    { lat: 48.7757653, lng: 20.4466825 },
    { lat: 48.7756427, lng: 20.446471 },
    { lat: 48.7755375, lng: 20.446136 },
    { lat: 48.7754292, lng: 20.4445239 },
    { lat: 48.77517, lng: 20.4433619 },
    { lat: 48.7746348, lng: 20.4418669 },
    { lat: 48.7746855, lng: 20.4417168 },
    { lat: 48.774556, lng: 20.4415688 },
    { lat: 48.7742546, lng: 20.44084 },
    { lat: 48.7739584, lng: 20.4403588 },
    { lat: 48.7737694, lng: 20.4396801 },
    { lat: 48.773676, lng: 20.4395944 },
    { lat: 48.7736758, lng: 20.4395337 },
    { lat: 48.7735811, lng: 20.4394994 },
    { lat: 48.7735876, lng: 20.4394289 },
    { lat: 48.7734551, lng: 20.4393822 },
    { lat: 48.7734763, lng: 20.4392865 },
    { lat: 48.7732903, lng: 20.4390731 },
    { lat: 48.7732539, lng: 20.4388864 },
    { lat: 48.773139, lng: 20.4387668 },
    { lat: 48.773087, lng: 20.4384928 },
    { lat: 48.7727862, lng: 20.4377479 },
    { lat: 48.7727726, lng: 20.4376255 },
    { lat: 48.7728385, lng: 20.4375531 },
    { lat: 48.7727507, lng: 20.4374093 },
    { lat: 48.7728422, lng: 20.437059 },
    { lat: 48.7727949, lng: 20.4369057 },
    { lat: 48.7728255, lng: 20.4367674 },
    { lat: 48.7727637, lng: 20.4367139 },
    { lat: 48.7727027, lng: 20.4364975 },
    { lat: 48.7727893, lng: 20.4363479 },
    { lat: 48.7728299, lng: 20.4359642 },
    { lat: 48.7726841, lng: 20.4357413 },
    { lat: 48.7728069, lng: 20.4355321 },
    { lat: 48.7727501, lng: 20.4354049 },
    { lat: 48.772787, lng: 20.43537 },
    { lat: 48.7727628, lng: 20.43529 },
    { lat: 48.7729248, lng: 20.435202 },
    { lat: 48.7729679, lng: 20.4349444 },
    { lat: 48.7730717, lng: 20.4347087 },
    { lat: 48.7732127, lng: 20.4345987 },
    { lat: 48.7732665, lng: 20.4342962 },
    { lat: 48.773357, lng: 20.4341556 },
    { lat: 48.7733298, lng: 20.4339745 },
    { lat: 48.7733762, lng: 20.4333578 },
    { lat: 48.7733201, lng: 20.4332788 },
    { lat: 48.7734364, lng: 20.4329592 },
    { lat: 48.7733445, lng: 20.4329072 },
    { lat: 48.7733388, lng: 20.4328199 },
    { lat: 48.773223, lng: 20.4327657 },
    { lat: 48.7732055, lng: 20.4325084 },
    { lat: 48.7732391, lng: 20.4323317 },
    { lat: 48.7730838, lng: 20.4320949 },
    { lat: 48.7731464, lng: 20.4319046 },
    { lat: 48.773067, lng: 20.4318012 },
    { lat: 48.7731949, lng: 20.4316401 },
    { lat: 48.7731535, lng: 20.4315873 },
    { lat: 48.7731656, lng: 20.4313728 },
    { lat: 48.7732427, lng: 20.4312968 },
    { lat: 48.7732367, lng: 20.4312006 },
    { lat: 48.7732991, lng: 20.4310916 },
    { lat: 48.7732258, lng: 20.4307032 },
    { lat: 48.7731024, lng: 20.4303926 },
    { lat: 48.7731496, lng: 20.4302996 },
    { lat: 48.7730861, lng: 20.4302563 },
    { lat: 48.7730726, lng: 20.4301114 },
    { lat: 48.773146, lng: 20.4300879 },
    { lat: 48.7731055, lng: 20.429909 },
    { lat: 48.7731274, lng: 20.4297475 },
    { lat: 48.7730414, lng: 20.4295583 },
    { lat: 48.7730638, lng: 20.4293472 },
    { lat: 48.7729957, lng: 20.4289454 },
    { lat: 48.7728537, lng: 20.4287051 },
    { lat: 48.7729068, lng: 20.4285937 },
    { lat: 48.7727773, lng: 20.4276451 },
    { lat: 48.7726716, lng: 20.4275918 },
    { lat: 48.7726736, lng: 20.427407 },
    { lat: 48.7722341, lng: 20.4271936 },
    { lat: 48.7721454, lng: 20.4269333 },
    { lat: 48.7721833, lng: 20.4266812 },
    { lat: 48.7721197, lng: 20.4265478 },
    { lat: 48.7720752, lng: 20.4266263 },
    { lat: 48.7718917, lng: 20.4264471 },
    { lat: 48.7718646, lng: 20.4263302 },
    { lat: 48.7716334, lng: 20.4261404 },
    { lat: 48.7713553, lng: 20.4255217 },
    { lat: 48.7713303, lng: 20.4253062 },
    { lat: 48.7711377, lng: 20.4248628 },
    { lat: 48.7711537, lng: 20.4246879 },
    { lat: 48.7710953, lng: 20.4244881 },
    { lat: 48.7708305, lng: 20.4243381 },
    { lat: 48.7708519, lng: 20.4240995 },
    { lat: 48.770581, lng: 20.423728 },
    { lat: 48.7706157, lng: 20.4236353 },
    { lat: 48.770562, lng: 20.4235269 },
    { lat: 48.7701628, lng: 20.4234464 },
    { lat: 48.7701586, lng: 20.4232811 },
    { lat: 48.7702329, lng: 20.4232803 },
    { lat: 48.7702344, lng: 20.4230525 },
    { lat: 48.7701698, lng: 20.4228932 },
    { lat: 48.7702332, lng: 20.4228423 },
    { lat: 48.7702109, lng: 20.4226144 },
    { lat: 48.7700875, lng: 20.4225652 },
    { lat: 48.7699943, lng: 20.4221581 },
    { lat: 48.7699, lng: 20.422111 },
    { lat: 48.7698507, lng: 20.421969 },
    { lat: 48.7697096, lng: 20.4219242 },
    { lat: 48.7696368, lng: 20.4212266 },
    { lat: 48.7694663, lng: 20.4208577 },
    { lat: 48.7693262, lng: 20.4207307 },
    { lat: 48.7692811, lng: 20.4205255 },
    { lat: 48.7689898, lng: 20.4202928 },
    { lat: 48.7688463, lng: 20.4198705 },
    { lat: 48.7688703, lng: 20.419701 },
    { lat: 48.7689258, lng: 20.4196212 },
    { lat: 48.7688379, lng: 20.4187576 },
    { lat: 48.7687163, lng: 20.418498 },
    { lat: 48.7685366, lng: 20.4184801 },
    { lat: 48.7684176, lng: 20.4182263 },
    { lat: 48.7683345, lng: 20.4181982 },
    { lat: 48.7683064, lng: 20.4179944 },
    { lat: 48.7682096, lng: 20.4178194 },
    { lat: 48.768145, lng: 20.4178082 },
    { lat: 48.7679503, lng: 20.4172755 },
    { lat: 48.7679725, lng: 20.4170904 },
    { lat: 48.7677938, lng: 20.4166549 },
    { lat: 48.7674362, lng: 20.4150741 },
    { lat: 48.7662744, lng: 20.4156449 },
    { lat: 48.766171, lng: 20.4149094 },
    { lat: 48.7649258, lng: 20.4092519 },
    { lat: 48.7649394, lng: 20.4089605 },
    { lat: 48.7646884, lng: 20.4079631 },
    { lat: 48.764289, lng: 20.4073841 },
    { lat: 48.7636875, lng: 20.4061122 },
    { lat: 48.760465, lng: 20.4004945 },
    { lat: 48.7576656, lng: 20.3958508 },
    { lat: 48.7564514, lng: 20.3940498 },
    { lat: 48.7558103, lng: 20.3936877 },
    { lat: 48.7554037, lng: 20.3931885 },
    { lat: 48.7553511, lng: 20.3930767 },
    { lat: 48.7548364, lng: 20.3923132 },
    { lat: 48.7545372, lng: 20.3921154 },
    { lat: 48.7542795, lng: 20.3917091 },
    { lat: 48.7541784, lng: 20.3909507 },
    { lat: 48.7540665, lng: 20.3907096 },
    { lat: 48.7539628, lng: 20.3901767 },
    { lat: 48.7537548, lng: 20.3898909 },
    { lat: 48.753644, lng: 20.3898214 },
    { lat: 48.753254, lng: 20.389108 },
    { lat: 48.7530421, lng: 20.3892208 },
    { lat: 48.753044, lng: 20.389651 },
    { lat: 48.7531058, lng: 20.3897355 },
    { lat: 48.7529955, lng: 20.3899972 },
    { lat: 48.7529476, lng: 20.3904221 },
    { lat: 48.7528926, lng: 20.3905201 },
    { lat: 48.7528218, lng: 20.3914894 },
    { lat: 48.7526768, lng: 20.3920524 },
    { lat: 48.7526841, lng: 20.3921816 },
    { lat: 48.7526132, lng: 20.3924494 },
    { lat: 48.7524869, lng: 20.3926204 },
    { lat: 48.7524976, lng: 20.3927522 },
    { lat: 48.752415, lng: 20.3929793 },
    { lat: 48.7524198, lng: 20.3932197 },
    { lat: 48.7522963, lng: 20.3933158 },
    { lat: 48.7522447, lng: 20.3936133 },
    { lat: 48.7521676, lng: 20.39375 },
    { lat: 48.7520719, lng: 20.3942751 },
    { lat: 48.7521232, lng: 20.3944394 },
    { lat: 48.7519952, lng: 20.3949224 },
    { lat: 48.7517819, lng: 20.3953479 },
    { lat: 48.751503, lng: 20.3956239 },
    { lat: 48.7512616, lng: 20.39611 },
    { lat: 48.751232, lng: 20.3963356 },
    { lat: 48.7512694, lng: 20.3964908 },
    { lat: 48.7511674, lng: 20.3965593 },
    { lat: 48.7511599, lng: 20.3968369 },
    { lat: 48.7510964, lng: 20.3969106 },
    { lat: 48.7511326, lng: 20.3971522 },
    { lat: 48.7509734, lng: 20.3982246 },
    { lat: 48.7507657, lng: 20.3987416 },
    { lat: 48.7507262, lng: 20.3996514 },
    { lat: 48.7506788, lng: 20.3998233 },
    { lat: 48.750765, lng: 20.4008124 },
    { lat: 48.7507737, lng: 20.4015692 },
    { lat: 48.750748, lng: 20.4015133 },
    { lat: 48.7507522, lng: 20.4020674 },
    { lat: 48.7506929, lng: 20.4021936 },
    { lat: 48.7506442, lng: 20.4026 },
    { lat: 48.7506937, lng: 20.4032133 },
    { lat: 48.7508793, lng: 20.4037669 },
    { lat: 48.7508439, lng: 20.4039028 },
    { lat: 48.7504826, lng: 20.4042884 },
    { lat: 48.7504796, lng: 20.4045923 },
    { lat: 48.750295, lng: 20.4045656 },
    { lat: 48.7502161, lng: 20.4046507 },
    { lat: 48.7500539, lng: 20.4045057 },
    { lat: 48.7498396, lng: 20.4044913 },
    { lat: 48.7495003, lng: 20.4046041 },
    { lat: 48.7490874, lng: 20.4050535 },
    { lat: 48.7488844, lng: 20.4051947 },
    { lat: 48.7488787, lng: 20.4053358 },
    { lat: 48.7487767, lng: 20.4056169 },
    { lat: 48.7485683, lng: 20.4057037 },
    { lat: 48.7484198, lng: 20.4054416 },
    { lat: 48.7483152, lng: 20.4053847 },
    { lat: 48.7481346, lng: 20.4055175 },
    { lat: 48.7480258, lng: 20.4057079 },
    { lat: 48.7474041, lng: 20.4058815 },
    { lat: 48.7471272, lng: 20.4062072 },
    { lat: 48.7468991, lng: 20.4063057 },
    { lat: 48.7465419, lng: 20.4066281 },
    { lat: 48.7465023, lng: 20.4071225 },
    { lat: 48.7462931, lng: 20.4071882 },
    { lat: 48.7462395, lng: 20.4073167 },
    { lat: 48.7460846, lng: 20.4074484 },
    { lat: 48.7459687, lng: 20.4074799 },
    { lat: 48.7458675, lng: 20.4073935 },
    { lat: 48.7457977, lng: 20.4074549 },
    { lat: 48.7457329, lng: 20.4076754 },
    { lat: 48.7454473, lng: 20.4080061 },
    { lat: 48.7452596, lng: 20.4080457 },
    { lat: 48.744266, lng: 20.4086817 },
    { lat: 48.7441984, lng: 20.4088021 },
    { lat: 48.7440554, lng: 20.4088888 },
    { lat: 48.7440257, lng: 20.4090798 },
    { lat: 48.7436258, lng: 20.4094027 },
    { lat: 48.7434496, lng: 20.4097681 },
    { lat: 48.7433855, lng: 20.4102146 },
    { lat: 48.7434967, lng: 20.4104768 },
    { lat: 48.7434812, lng: 20.4108064 },
    { lat: 48.7435425, lng: 20.4109655 },
    { lat: 48.7434684, lng: 20.4113541 },
    { lat: 48.7434934, lng: 20.4115381 },
    { lat: 48.7436117, lng: 20.4115967 },
    { lat: 48.7436883, lng: 20.4114515 },
    { lat: 48.7436304, lng: 20.411437 },
    { lat: 48.7436413, lng: 20.4110746 },
    { lat: 48.7439977, lng: 20.4111751 },
    { lat: 48.7438532, lng: 20.4126984 },
    { lat: 48.7439027, lng: 20.4127279 },
    { lat: 48.7438328, lng: 20.4131725 },
    { lat: 48.7438456, lng: 20.4139463 },
    { lat: 48.7457249, lng: 20.4146542 },
    { lat: 48.7457541, lng: 20.4142057 },
    { lat: 48.7473434, lng: 20.41384 },
    { lat: 48.7475463, lng: 20.4155575 },
    { lat: 48.7475635, lng: 20.4156784 },
    { lat: 48.7475947, lng: 20.4156749 },
    { lat: 48.7475935, lng: 20.415816 },
    { lat: 48.7438463, lng: 20.4162703 },
    { lat: 48.7438725, lng: 20.4165966 },
    { lat: 48.7441945, lng: 20.4172197 },
    { lat: 48.7452951, lng: 20.4172036 },
    { lat: 48.7452909, lng: 20.4180321 },
    { lat: 48.744568, lng: 20.4183006 },
    { lat: 48.7444016, lng: 20.417983 },
    { lat: 48.7442725, lng: 20.4200008 },
    { lat: 48.7442815, lng: 20.4206511 },
    { lat: 48.7443145, lng: 20.4210146 },
    { lat: 48.7444412, lng: 20.4213807 },
    { lat: 48.7446702, lng: 20.421811 },
    { lat: 48.7446602, lng: 20.4221045 },
    { lat: 48.7445585, lng: 20.4222724 },
    { lat: 48.7445835, lng: 20.4228112 },
    { lat: 48.7443259, lng: 20.4232899 },
    { lat: 48.7443463, lng: 20.4236513 },
    { lat: 48.7441003, lng: 20.4239885 },
    { lat: 48.7440529, lng: 20.4241361 },
    { lat: 48.7440179, lng: 20.4244253 },
    { lat: 48.7440412, lng: 20.4246551 },
    { lat: 48.7441438, lng: 20.4249566 },
    { lat: 48.7438863, lng: 20.426292 },
    { lat: 48.7438629, lng: 20.4269491 },
    { lat: 48.7437424, lng: 20.4273031 },
    { lat: 48.743955, lng: 20.428158 },
    { lat: 48.7439587, lng: 20.428406 },
    { lat: 48.7438616, lng: 20.4286324 },
    { lat: 48.7439664, lng: 20.4288752 },
    { lat: 48.74395, lng: 20.4293861 },
    { lat: 48.7438061, lng: 20.4298382 },
    { lat: 48.7438657, lng: 20.430041 },
    { lat: 48.7439656, lng: 20.4300933 },
    { lat: 48.7439612, lng: 20.4303306 },
    { lat: 48.7440215, lng: 20.4305566 },
    { lat: 48.7439908, lng: 20.4309367 },
    { lat: 48.7440365, lng: 20.4315424 },
    { lat: 48.7440177, lng: 20.4320282 },
    { lat: 48.7440583, lng: 20.432146 },
    { lat: 48.7440688, lng: 20.4325707 },
    { lat: 48.7441778, lng: 20.4330351 },
    { lat: 48.7441323, lng: 20.4332599 },
    { lat: 48.7442054, lng: 20.4341776 },
    { lat: 48.7441353, lng: 20.4342889 },
    { lat: 48.7442307, lng: 20.4347248 },
    { lat: 48.7444226, lng: 20.4373476 },
    { lat: 48.7443807, lng: 20.4387373 },
    { lat: 48.7442399, lng: 20.4389129 },
    { lat: 48.7441103, lng: 20.4392956 },
    { lat: 48.7439886, lng: 20.4400311 },
    { lat: 48.7440633, lng: 20.4402508 },
    { lat: 48.7442217, lng: 20.4403898 },
    { lat: 48.7442493, lng: 20.4407331 },
    { lat: 48.7440585, lng: 20.4428232 },
    { lat: 48.7440935, lng: 20.4432108 },
    { lat: 48.7439217, lng: 20.4440249 },
    { lat: 48.7439178, lng: 20.4447816 },
    { lat: 48.7439496, lng: 20.4454446 },
    { lat: 48.7441268, lng: 20.4459002 },
    { lat: 48.7447172, lng: 20.446087 },
    { lat: 48.7445833, lng: 20.4472495 },
  ],
  Ochtiná: [
    { lat: 48.6986173, lng: 20.3250234 },
    { lat: 48.6991081, lng: 20.3245876 },
    { lat: 48.7006929, lng: 20.324048 },
    { lat: 48.7020567, lng: 20.3227494 },
    { lat: 48.7023248, lng: 20.3217098 },
    { lat: 48.7029109, lng: 20.3204569 },
    { lat: 48.7033423, lng: 20.3197917 },
    { lat: 48.7037855, lng: 20.3194909 },
    { lat: 48.7042343, lng: 20.318905 },
    { lat: 48.7048037, lng: 20.3184033 },
    { lat: 48.7049201, lng: 20.3181797 },
    { lat: 48.705009, lng: 20.3178042 },
    { lat: 48.7018234, lng: 20.3156514 },
    { lat: 48.7008297, lng: 20.3149204 },
    { lat: 48.6999428, lng: 20.3144112 },
    { lat: 48.6995743, lng: 20.3141183 },
    { lat: 48.6986437, lng: 20.3129856 },
    { lat: 48.6982533, lng: 20.3122722 },
    { lat: 48.6974843, lng: 20.3113266 },
    { lat: 48.6967512, lng: 20.3107258 },
    { lat: 48.6967833, lng: 20.3102182 },
    { lat: 48.6963471, lng: 20.3101509 },
    { lat: 48.6957149, lng: 20.3098241 },
    { lat: 48.6952208, lng: 20.3094269 },
    { lat: 48.6954602, lng: 20.3089539 },
    { lat: 48.694683, lng: 20.308233 },
    { lat: 48.6950208, lng: 20.3071392 },
    { lat: 48.6944761, lng: 20.3065708 },
    { lat: 48.6941731, lng: 20.3068538 },
    { lat: 48.6940515, lng: 20.306654 },
    { lat: 48.6938265, lng: 20.3057402 },
    { lat: 48.6936109, lng: 20.3046655 },
    { lat: 48.6934992, lng: 20.3043742 },
    { lat: 48.6935197, lng: 20.3030852 },
    { lat: 48.6934196, lng: 20.3020843 },
    { lat: 48.6934631, lng: 20.3015276 },
    { lat: 48.6931493, lng: 20.2998429 },
    { lat: 48.6928172, lng: 20.2992691 },
    { lat: 48.6926859, lng: 20.2987081 },
    { lat: 48.6925485, lng: 20.2983635 },
    { lat: 48.6923748, lng: 20.2980982 },
    { lat: 48.6914288, lng: 20.2974372 },
    { lat: 48.6903584, lng: 20.2961643 },
    { lat: 48.690072, lng: 20.2959511 },
    { lat: 48.6898674, lng: 20.2955599 },
    { lat: 48.6885867, lng: 20.2944884 },
    { lat: 48.688328, lng: 20.2939235 },
    { lat: 48.6867557, lng: 20.2924605 },
    { lat: 48.6863238, lng: 20.2918701 },
    { lat: 48.6859268, lng: 20.291483 },
    { lat: 48.6855709, lng: 20.2905953 },
    { lat: 48.6847134, lng: 20.2897506 },
    { lat: 48.6845259, lng: 20.2892478 },
    { lat: 48.6841669, lng: 20.2855624 },
    { lat: 48.683765, lng: 20.2839932 },
    { lat: 48.68379, lng: 20.2834286 },
    { lat: 48.6835333, lng: 20.2822862 },
    { lat: 48.6833548, lng: 20.2817844 },
    { lat: 48.682973, lng: 20.281097 },
    { lat: 48.6824153, lng: 20.2808078 },
    { lat: 48.6820828, lng: 20.2796813 },
    { lat: 48.6823194, lng: 20.2788588 },
    { lat: 48.6824756, lng: 20.2779602 },
    { lat: 48.6824444, lng: 20.2770318 },
    { lat: 48.682129, lng: 20.276026 },
    { lat: 48.6821, lng: 20.2759 },
    { lat: 48.682051, lng: 20.275681 },
    { lat: 48.682012, lng: 20.275513 },
    { lat: 48.68184, lng: 20.275447 },
    { lat: 48.681808, lng: 20.275426 },
    { lat: 48.68157, lng: 20.275339 },
    { lat: 48.681415, lng: 20.275281 },
    { lat: 48.681291, lng: 20.275239 },
    { lat: 48.681133, lng: 20.275079 },
    { lat: 48.680983, lng: 20.274923 },
    { lat: 48.680848, lng: 20.27497 },
    { lat: 48.680435, lng: 20.274945 },
    { lat: 48.680268, lng: 20.27512 },
    { lat: 48.679987, lng: 20.275113 },
    { lat: 48.679697, lng: 20.275108 },
    { lat: 48.679583, lng: 20.275212 },
    { lat: 48.679401, lng: 20.27539 },
    { lat: 48.679125, lng: 20.275593 },
    { lat: 48.678804, lng: 20.275876 },
    { lat: 48.678429, lng: 20.276004 },
    { lat: 48.678263, lng: 20.276064 },
    { lat: 48.67794, lng: 20.276279 },
    { lat: 48.677924, lng: 20.27629 },
    { lat: 48.677904, lng: 20.276303 },
    { lat: 48.677651, lng: 20.27647 },
    { lat: 48.677317, lng: 20.276684 },
    { lat: 48.676954, lng: 20.276919 },
    { lat: 48.676933, lng: 20.27693 },
    { lat: 48.676828, lng: 20.276983 },
    { lat: 48.676828, lng: 20.276984 },
    { lat: 48.67662, lng: 20.277061 },
    { lat: 48.676422, lng: 20.277063 },
    { lat: 48.676206, lng: 20.277106 },
    { lat: 48.675983, lng: 20.277156 },
    { lat: 48.675734, lng: 20.27732 },
    { lat: 48.675408, lng: 20.277465 },
    { lat: 48.674786, lng: 20.277504 },
    { lat: 48.67473, lng: 20.277567 },
    { lat: 48.674701, lng: 20.277742 },
    { lat: 48.674634, lng: 20.277914 },
    { lat: 48.674544, lng: 20.278448 },
    { lat: 48.67447, lng: 20.278673 },
    { lat: 48.67433, lng: 20.279089 },
    { lat: 48.67411, lng: 20.27956 },
    { lat: 48.674029, lng: 20.279674 },
    { lat: 48.673942, lng: 20.279983 },
    { lat: 48.67382, lng: 20.280411 },
    { lat: 48.673766, lng: 20.280617 },
    { lat: 48.673648, lng: 20.280931 },
    { lat: 48.673566, lng: 20.281355 },
    { lat: 48.673388, lng: 20.281803 },
    { lat: 48.673324, lng: 20.281968 },
    { lat: 48.673235, lng: 20.28216 },
    { lat: 48.672937, lng: 20.282304 },
    { lat: 48.672566, lng: 20.282506 },
    { lat: 48.672344, lng: 20.282628 },
    { lat: 48.672021, lng: 20.282851 },
    { lat: 48.671806, lng: 20.283073 },
    { lat: 48.671525, lng: 20.283145 },
    { lat: 48.67146, lng: 20.283165 },
    { lat: 48.671182, lng: 20.283474 },
    { lat: 48.671055, lng: 20.283514 },
    { lat: 48.67068, lng: 20.283604 },
    { lat: 48.670185, lng: 20.283952 },
    { lat: 48.669877, lng: 20.284268 },
    { lat: 48.669757, lng: 20.284447 },
    { lat: 48.669564, lng: 20.284747 },
    { lat: 48.669498, lng: 20.284815 },
    { lat: 48.669204, lng: 20.28496 },
    { lat: 48.668835, lng: 20.285136 },
    { lat: 48.668719, lng: 20.285506 },
    { lat: 48.668611, lng: 20.285637 },
    { lat: 48.668453, lng: 20.285844 },
    { lat: 48.667937, lng: 20.286328 },
    { lat: 48.667618, lng: 20.286749 },
    { lat: 48.667365, lng: 20.286918 },
    { lat: 48.667334, lng: 20.286958 },
    { lat: 48.666906, lng: 20.287374 },
    { lat: 48.666488, lng: 20.287787 },
    { lat: 48.666479, lng: 20.287767 },
    { lat: 48.666342, lng: 20.287826 },
    { lat: 48.665737, lng: 20.288069 },
    { lat: 48.665617, lng: 20.288217 },
    { lat: 48.665477, lng: 20.288365 },
    { lat: 48.665393, lng: 20.288496 },
    { lat: 48.665271, lng: 20.288612 },
    { lat: 48.665269, lng: 20.288614 },
    { lat: 48.665042, lng: 20.288714 },
    { lat: 48.664765, lng: 20.288832 },
    { lat: 48.66455, lng: 20.288945 },
    { lat: 48.66435, lng: 20.289046 },
    { lat: 48.664057, lng: 20.289235 },
    { lat: 48.663524, lng: 20.289373 },
    { lat: 48.663348, lng: 20.289482 },
    { lat: 48.662938, lng: 20.289732 },
    { lat: 48.662632, lng: 20.290048 },
    { lat: 48.662214, lng: 20.290195 },
    { lat: 48.661931, lng: 20.290666 },
    { lat: 48.661871, lng: 20.290894 },
    { lat: 48.661798, lng: 20.291062 },
    { lat: 48.661669, lng: 20.291476 },
    { lat: 48.661511, lng: 20.291995 },
    { lat: 48.661483, lng: 20.292091 },
    { lat: 48.661371, lng: 20.292456 },
    { lat: 48.66137, lng: 20.292459 },
    { lat: 48.661275, lng: 20.292859 },
    { lat: 48.661102, lng: 20.293277 },
    { lat: 48.661045, lng: 20.293375 },
    { lat: 48.660993, lng: 20.29345 },
    { lat: 48.66094, lng: 20.293528 },
    { lat: 48.66089, lng: 20.293597 },
    { lat: 48.660746, lng: 20.29377 },
    { lat: 48.660655, lng: 20.293878 },
    { lat: 48.66057, lng: 20.29398 },
    { lat: 48.660525, lng: 20.294035 },
    { lat: 48.660506, lng: 20.294058 },
    { lat: 48.660262, lng: 20.294352 },
    { lat: 48.660132, lng: 20.294534 },
    { lat: 48.66008, lng: 20.294605 },
    { lat: 48.660062, lng: 20.294631 },
    { lat: 48.659872, lng: 20.294901 },
    { lat: 48.659813, lng: 20.294983 },
    { lat: 48.659784, lng: 20.295024 },
    { lat: 48.659677, lng: 20.295173 },
    { lat: 48.659677, lng: 20.295174 },
    { lat: 48.659669, lng: 20.295185 },
    { lat: 48.659608, lng: 20.295269 },
    { lat: 48.659603, lng: 20.295276 },
    { lat: 48.659468, lng: 20.29545 },
    { lat: 48.659467, lng: 20.29545 },
    { lat: 48.659427, lng: 20.295501 },
    { lat: 48.659419, lng: 20.295511 },
    { lat: 48.659416, lng: 20.295512 },
    { lat: 48.659304, lng: 20.29563 },
    { lat: 48.659255, lng: 20.295681 },
    { lat: 48.659227, lng: 20.295716 },
    { lat: 48.658963, lng: 20.296042 },
    { lat: 48.658814, lng: 20.296178 },
    { lat: 48.658682, lng: 20.296445 },
    { lat: 48.658627, lng: 20.29648 },
    { lat: 48.658531, lng: 20.296653 },
    { lat: 48.658518, lng: 20.296631 },
    { lat: 48.658275, lng: 20.296941 },
    { lat: 48.658051, lng: 20.297165 },
    { lat: 48.657912, lng: 20.297486 },
    { lat: 48.657892, lng: 20.297785 },
    { lat: 48.657879, lng: 20.297812 },
    { lat: 48.657782, lng: 20.298078 },
    { lat: 48.657608, lng: 20.298485 },
    { lat: 48.657449, lng: 20.298843 },
    { lat: 48.657404, lng: 20.299472 },
    { lat: 48.65737, lng: 20.299918 },
    { lat: 48.657377, lng: 20.299924 },
    { lat: 48.657391, lng: 20.299938 },
    { lat: 48.657282, lng: 20.30031 },
    { lat: 48.657296, lng: 20.300339 },
    { lat: 48.657407, lng: 20.301173 },
    { lat: 48.657448, lng: 20.301278 },
    { lat: 48.657587, lng: 20.301746 },
    { lat: 48.658014, lng: 20.302226 },
    { lat: 48.658018, lng: 20.302389 },
    { lat: 48.6584659, lng: 20.3042021 },
    { lat: 48.6586434, lng: 20.3047085 },
    { lat: 48.6588165, lng: 20.3056227 },
    { lat: 48.6590367, lng: 20.3062368 },
    { lat: 48.6596592, lng: 20.306832 },
    { lat: 48.660333, lng: 20.3073336 },
    { lat: 48.6605253, lng: 20.3076399 },
    { lat: 48.6606266, lng: 20.3076722 },
    { lat: 48.6609956, lng: 20.3082366 },
    { lat: 48.6611976, lng: 20.3087059 },
    { lat: 48.6616924, lng: 20.3093789 },
    { lat: 48.6619359, lng: 20.3102164 },
    { lat: 48.6620501, lng: 20.3114398 },
    { lat: 48.6623042, lng: 20.3120883 },
    { lat: 48.6625549, lng: 20.3130556 },
    { lat: 48.6627651, lng: 20.3133325 },
    { lat: 48.662998, lng: 20.313445 },
    { lat: 48.6634088, lng: 20.3139566 },
    { lat: 48.6636033, lng: 20.31438 },
    { lat: 48.6638079, lng: 20.3148865 },
    { lat: 48.6637871, lng: 20.3156229 },
    { lat: 48.6638828, lng: 20.3161381 },
    { lat: 48.6641084, lng: 20.3168689 },
    { lat: 48.6646679, lng: 20.3177029 },
    { lat: 48.6648127, lng: 20.318031 },
    { lat: 48.6650673, lng: 20.3192755 },
    { lat: 48.6651511, lng: 20.3194016 },
    { lat: 48.6653331, lng: 20.3194535 },
    { lat: 48.6659307, lng: 20.3200173 },
    { lat: 48.6661995, lng: 20.3207611 },
    { lat: 48.6664018, lng: 20.3221343 },
    { lat: 48.6669697, lng: 20.3243008 },
    { lat: 48.6669875, lng: 20.3248464 },
    { lat: 48.6671207, lng: 20.3250569 },
    { lat: 48.6674723, lng: 20.3253506 },
    { lat: 48.6677982, lng: 20.3254491 },
    { lat: 48.668158, lng: 20.3259843 },
    { lat: 48.668363, lng: 20.3264446 },
    { lat: 48.6687518, lng: 20.3268283 },
    { lat: 48.6689228, lng: 20.3271106 },
    { lat: 48.6691452, lng: 20.3270936 },
    { lat: 48.6692377, lng: 20.3271609 },
    { lat: 48.6692945, lng: 20.3273162 },
    { lat: 48.6692202, lng: 20.3276324 },
    { lat: 48.6689338, lng: 20.3280893 },
    { lat: 48.6694133, lng: 20.3286873 },
    { lat: 48.669943, lng: 20.3296525 },
    { lat: 48.6701276, lng: 20.3297939 },
    { lat: 48.6705313, lng: 20.3302718 },
    { lat: 48.6706063, lng: 20.3304629 },
    { lat: 48.6706346, lng: 20.3307229 },
    { lat: 48.6712305, lng: 20.3313366 },
    { lat: 48.671551, lng: 20.3319303 },
    { lat: 48.6719002, lng: 20.3323653 },
    { lat: 48.6720776, lng: 20.3327892 },
    { lat: 48.6725916, lng: 20.3331469 },
    { lat: 48.6730977, lng: 20.3337697 },
    { lat: 48.6739311, lng: 20.3341728 },
    { lat: 48.6743909, lng: 20.3349636 },
    { lat: 48.6747005, lng: 20.3351642 },
    { lat: 48.675136, lng: 20.335762 },
    { lat: 48.6753615, lng: 20.3362319 },
    { lat: 48.6754897, lng: 20.3366641 },
    { lat: 48.6754926, lng: 20.3372294 },
    { lat: 48.675555, lng: 20.3375898 },
    { lat: 48.6757107, lng: 20.3380873 },
    { lat: 48.6759071, lng: 20.3384151 },
    { lat: 48.676016, lng: 20.3387586 },
    { lat: 48.6762096, lng: 20.3397694 },
    { lat: 48.6762421, lng: 20.3401667 },
    { lat: 48.67618, lng: 20.3404811 },
    { lat: 48.6761435, lng: 20.3411101 },
    { lat: 48.6762357, lng: 20.3416831 },
    { lat: 48.6762472, lng: 20.3424485 },
    { lat: 48.6762075, lng: 20.3430482 },
    { lat: 48.6764724, lng: 20.3446784 },
    { lat: 48.6772553, lng: 20.3460137 },
    { lat: 48.6777348, lng: 20.3459827 },
    { lat: 48.6777358, lng: 20.3459177 },
    { lat: 48.6777935, lng: 20.3460131 },
    { lat: 48.6781753, lng: 20.3455799 },
    { lat: 48.6783653, lng: 20.3456603 },
    { lat: 48.6786558, lng: 20.3441244 },
    { lat: 48.6785185, lng: 20.343833 },
    { lat: 48.678391, lng: 20.3437712 },
    { lat: 48.678283, lng: 20.3436279 },
    { lat: 48.6780813, lng: 20.343076 },
    { lat: 48.6781343, lng: 20.3428418 },
    { lat: 48.678274, lng: 20.3424973 },
    { lat: 48.6785975, lng: 20.3424159 },
    { lat: 48.6790464, lng: 20.341507 },
    { lat: 48.6792799, lng: 20.3415473 },
    { lat: 48.679836, lng: 20.3400405 },
    { lat: 48.6808257, lng: 20.3395722 },
    { lat: 48.6803175, lng: 20.34159 },
    { lat: 48.6805713, lng: 20.3427585 },
    { lat: 48.682062, lng: 20.3449282 },
    { lat: 48.6827704, lng: 20.3457685 },
    { lat: 48.682572, lng: 20.3463698 },
    { lat: 48.6825043, lng: 20.3469477 },
    { lat: 48.683185, lng: 20.3462121 },
    { lat: 48.6843819, lng: 20.3451047 },
    { lat: 48.6841419, lng: 20.3444923 },
    { lat: 48.6844283, lng: 20.3441256 },
    { lat: 48.6870276, lng: 20.3422437 },
    { lat: 48.6884359, lng: 20.3410778 },
    { lat: 48.6893362, lng: 20.3401002 },
    { lat: 48.6894453, lng: 20.3402424 },
    { lat: 48.6897178, lng: 20.3401854 },
    { lat: 48.6898096, lng: 20.3401434 },
    { lat: 48.6899647, lng: 20.3397565 },
    { lat: 48.6900696, lng: 20.3397369 },
    { lat: 48.6902269, lng: 20.3398427 },
    { lat: 48.6903058, lng: 20.3396692 },
    { lat: 48.6906913, lng: 20.3394194 },
    { lat: 48.6909267, lng: 20.339421 },
    { lat: 48.6910725, lng: 20.3393175 },
    { lat: 48.691617, lng: 20.3395326 },
    { lat: 48.6918704, lng: 20.339508 },
    { lat: 48.6923084, lng: 20.3385175 },
    { lat: 48.6925331, lng: 20.3378406 },
    { lat: 48.6929986, lng: 20.3367225 },
    { lat: 48.6942019, lng: 20.3353214 },
    { lat: 48.6952834, lng: 20.3346373 },
    { lat: 48.6958172, lng: 20.3344615 },
    { lat: 48.6958941, lng: 20.3341211 },
    { lat: 48.6963124, lng: 20.3335314 },
    { lat: 48.6965514, lng: 20.3329858 },
    { lat: 48.6965357, lng: 20.3327277 },
    { lat: 48.6967752, lng: 20.332284 },
    { lat: 48.6969807, lng: 20.3314034 },
    { lat: 48.6972589, lng: 20.330592 },
    { lat: 48.6974903, lng: 20.3306933 },
    { lat: 48.6978832, lng: 20.3304627 },
    { lat: 48.6976029, lng: 20.3293365 },
    { lat: 48.6974989, lng: 20.3286599 },
    { lat: 48.6972753, lng: 20.3287062 },
    { lat: 48.697189, lng: 20.3269504 },
    { lat: 48.6979392, lng: 20.3259461 },
    { lat: 48.6986173, lng: 20.3250234 },
  ],
  Rozložná: [
    { lat: 48.6026223, lng: 20.3596026 },
    { lat: 48.602632, lng: 20.3594983 },
    { lat: 48.6044793, lng: 20.3614906 },
    { lat: 48.6047378, lng: 20.3615369 },
    { lat: 48.6048711, lng: 20.3614262 },
    { lat: 48.6049492, lng: 20.3614385 },
    { lat: 48.6049948, lng: 20.3610186 },
    { lat: 48.6052202, lng: 20.3606137 },
    { lat: 48.6056721, lng: 20.3602483 },
    { lat: 48.6061732, lng: 20.3605077 },
    { lat: 48.6062762, lng: 20.3603758 },
    { lat: 48.6063567, lng: 20.3604484 },
    { lat: 48.6065328, lng: 20.3603707 },
    { lat: 48.6066236, lng: 20.3602178 },
    { lat: 48.6065324, lng: 20.3601006 },
    { lat: 48.6066953, lng: 20.3598884 },
    { lat: 48.607, lng: 20.3601092 },
    { lat: 48.606777, lng: 20.360658 },
    { lat: 48.6073418, lng: 20.3612562 },
    { lat: 48.6081936, lng: 20.361674 },
    { lat: 48.6087247, lng: 20.362098 },
    { lat: 48.608809, lng: 20.3620329 },
    { lat: 48.6095143, lng: 20.3597186 },
    { lat: 48.6098478, lng: 20.3599571 },
    { lat: 48.6099592, lng: 20.3602149 },
    { lat: 48.6101055, lng: 20.3602761 },
    { lat: 48.6105066, lng: 20.3601887 },
    { lat: 48.6111306, lng: 20.3604587 },
    { lat: 48.61166, lng: 20.3604294 },
    { lat: 48.612079, lng: 20.3605203 },
    { lat: 48.6121734, lng: 20.3606593 },
    { lat: 48.6135691, lng: 20.360901 },
    { lat: 48.6144276, lng: 20.3612919 },
    { lat: 48.6148188, lng: 20.3613931 },
    { lat: 48.615161, lng: 20.3612426 },
    { lat: 48.6157161, lng: 20.3611834 },
    { lat: 48.6164325, lng: 20.3609266 },
    { lat: 48.617437, lng: 20.361126 },
    { lat: 48.6179083, lng: 20.3610244 },
    { lat: 48.6183618, lng: 20.3622425 },
    { lat: 48.6184875, lng: 20.3623749 },
    { lat: 48.6188941, lng: 20.3625217 },
    { lat: 48.6195688, lng: 20.3630207 },
    { lat: 48.6198354, lng: 20.3631299 },
    { lat: 48.620006, lng: 20.3634032 },
    { lat: 48.6207595, lng: 20.3633194 },
    { lat: 48.6210368, lng: 20.3634719 },
    { lat: 48.6231088, lng: 20.3633541 },
    { lat: 48.6233929, lng: 20.3631344 },
    { lat: 48.6235851, lng: 20.3628622 },
    { lat: 48.6238468, lng: 20.3626388 },
    { lat: 48.6244704, lng: 20.3623443 },
    { lat: 48.62472, lng: 20.3624764 },
    { lat: 48.6256336, lng: 20.3617302 },
    { lat: 48.6260295, lng: 20.3613376 },
    { lat: 48.6272364, lng: 20.3608667 },
    { lat: 48.6276315, lng: 20.361069 },
    { lat: 48.6289305, lng: 20.3610249 },
    { lat: 48.6292855, lng: 20.3607427 },
    { lat: 48.6307769, lng: 20.3603168 },
    { lat: 48.6313843, lng: 20.3598606 },
    { lat: 48.631675, lng: 20.3594583 },
    { lat: 48.632052, lng: 20.3587237 },
    { lat: 48.6320645, lng: 20.3587005 },
    { lat: 48.6315635, lng: 20.358267 },
    { lat: 48.6315897, lng: 20.3580731 },
    { lat: 48.6309872, lng: 20.3576624 },
    { lat: 48.6309424, lng: 20.3569148 },
    { lat: 48.6307571, lng: 20.3562309 },
    { lat: 48.6295706, lng: 20.3546197 },
    { lat: 48.6292935, lng: 20.3539187 },
    { lat: 48.628196, lng: 20.3521862 },
    { lat: 48.6280065, lng: 20.3518035 },
    { lat: 48.6277467, lng: 20.3509407 },
    { lat: 48.6274636, lng: 20.3502068 },
    { lat: 48.6273644, lng: 20.3489842 },
    { lat: 48.6272108, lng: 20.348073 },
    { lat: 48.6271168, lng: 20.3471903 },
    { lat: 48.6271223, lng: 20.3468708 },
    { lat: 48.6273616, lng: 20.3468294 },
    { lat: 48.6272376, lng: 20.3455745 },
    { lat: 48.6275778, lng: 20.3436427 },
    { lat: 48.6273961, lng: 20.341283 },
    { lat: 48.6280829, lng: 20.3404116 },
    { lat: 48.6285129, lng: 20.3394554 },
    { lat: 48.6259855, lng: 20.3335793 },
    { lat: 48.6261122, lng: 20.3326389 },
    { lat: 48.6270099, lng: 20.330076 },
    { lat: 48.627251, lng: 20.3275324 },
    { lat: 48.6273065, lng: 20.3273417 },
    { lat: 48.6273953, lng: 20.3247977 },
    { lat: 48.6273645, lng: 20.3244754 },
    { lat: 48.6272439, lng: 20.3242078 },
    { lat: 48.6272577, lng: 20.3241343 },
    { lat: 48.6270016, lng: 20.3230264 },
    { lat: 48.6269971, lng: 20.3226221 },
    { lat: 48.6269974, lng: 20.3221707 },
    { lat: 48.6270898, lng: 20.3213654 },
    { lat: 48.6272855, lng: 20.3203459 },
    { lat: 48.627547, lng: 20.3195736 },
    { lat: 48.6278921, lng: 20.3191834 },
    { lat: 48.62859, lng: 20.3187162 },
    { lat: 48.6295187, lng: 20.3179582 },
    { lat: 48.6298589, lng: 20.3175876 },
    { lat: 48.6307364, lng: 20.3172478 },
    { lat: 48.6309945, lng: 20.3170521 },
    { lat: 48.631395, lng: 20.3154561 },
    { lat: 48.6314529, lng: 20.3148962 },
    { lat: 48.6316614, lng: 20.3139189 },
    { lat: 48.6319712, lng: 20.31329 },
    { lat: 48.63214, lng: 20.3131414 },
    { lat: 48.633263, lng: 20.3096405 },
    { lat: 48.633414, lng: 20.3092401 },
    { lat: 48.6336222, lng: 20.3088925 },
    { lat: 48.6338223, lng: 20.3084232 },
    { lat: 48.6338403, lng: 20.307968 },
    { lat: 48.6340976, lng: 20.3072038 },
    { lat: 48.6343096, lng: 20.3063236 },
    { lat: 48.6344204, lng: 20.3062482 },
    { lat: 48.6344369, lng: 20.3056881 },
    { lat: 48.6345983, lng: 20.3053551 },
    { lat: 48.6348238, lng: 20.3042718 },
    { lat: 48.6347436, lng: 20.3039168 },
    { lat: 48.6348077, lng: 20.3036548 },
    { lat: 48.6346809, lng: 20.3030092 },
    { lat: 48.6347029, lng: 20.3025059 },
    { lat: 48.6354737, lng: 20.3009993 },
    { lat: 48.6354948, lng: 20.3005937 },
    { lat: 48.6355902, lng: 20.3004139 },
    { lat: 48.6358948, lng: 20.3001122 },
    { lat: 48.6364688, lng: 20.2997831 },
    { lat: 48.6368219, lng: 20.2991424 },
    { lat: 48.6369825, lng: 20.2987552 },
    { lat: 48.6371536, lng: 20.2981549 },
    { lat: 48.6372127, lng: 20.2973047 },
    { lat: 48.637316, lng: 20.29669 },
    { lat: 48.63729, lng: 20.296702 },
    { lat: 48.637276, lng: 20.296709 },
    { lat: 48.637013, lng: 20.296777 },
    { lat: 48.636753, lng: 20.296905 },
    { lat: 48.636613, lng: 20.297107 },
    { lat: 48.6365, lng: 20.297246 },
    { lat: 48.636111, lng: 20.297475 },
    { lat: 48.636005, lng: 20.297445 },
    { lat: 48.635723, lng: 20.297287 },
    { lat: 48.635632, lng: 20.297264 },
    { lat: 48.635461, lng: 20.297313 },
    { lat: 48.635216, lng: 20.297365 },
    { lat: 48.635, lng: 20.29738 },
    { lat: 48.634819, lng: 20.297433 },
    { lat: 48.63479, lng: 20.297437 },
    { lat: 48.634687, lng: 20.297416 },
    { lat: 48.634517, lng: 20.297323 },
    { lat: 48.634272, lng: 20.297293 },
    { lat: 48.634082, lng: 20.297239 },
    { lat: 48.633804, lng: 20.297318 },
    { lat: 48.633775, lng: 20.297319 },
    { lat: 48.633664, lng: 20.297301 },
    { lat: 48.633369, lng: 20.297186 },
    { lat: 48.633021, lng: 20.297128 },
    { lat: 48.632852, lng: 20.297094 },
    { lat: 48.632656, lng: 20.297109 },
    { lat: 48.632557, lng: 20.297086 },
    { lat: 48.632452, lng: 20.297135 },
    { lat: 48.632294, lng: 20.297172 },
    { lat: 48.632098, lng: 20.297213 },
    { lat: 48.63183, lng: 20.297382 },
    { lat: 48.631719, lng: 20.297486 },
    { lat: 48.631607, lng: 20.297569 },
    { lat: 48.631513, lng: 20.297621 },
    { lat: 48.631293, lng: 20.297706 },
    { lat: 48.631113, lng: 20.297775 },
    { lat: 48.630951, lng: 20.297816 },
    { lat: 48.630777, lng: 20.29791 },
    { lat: 48.630657, lng: 20.298025 },
    { lat: 48.630539, lng: 20.298142 },
    { lat: 48.630446, lng: 20.298207 },
    { lat: 48.63024, lng: 20.298258 },
    { lat: 48.630085, lng: 20.298171 },
    { lat: 48.629959, lng: 20.298035 },
    { lat: 48.62995, lng: 20.298052 },
    { lat: 48.629673, lng: 20.298512 },
    { lat: 48.629389, lng: 20.298994 },
    { lat: 48.629204, lng: 20.299266 },
    { lat: 48.628888, lng: 20.299683 },
    { lat: 48.628614, lng: 20.299988 },
    { lat: 48.628361, lng: 20.300193 },
    { lat: 48.627977, lng: 20.30041 },
    { lat: 48.627762, lng: 20.300841 },
    { lat: 48.627666, lng: 20.300906 },
    { lat: 48.627626, lng: 20.300889 },
    { lat: 48.627593, lng: 20.300873 },
    { lat: 48.627364, lng: 20.300709 },
    { lat: 48.627251, lng: 20.300722 },
    { lat: 48.627121, lng: 20.300775 },
    { lat: 48.626668, lng: 20.301112 },
    { lat: 48.62629, lng: 20.301435 },
    { lat: 48.626216, lng: 20.301506 },
    { lat: 48.626105, lng: 20.301677 },
    { lat: 48.626004, lng: 20.301851 },
    { lat: 48.625948, lng: 20.30195 },
    { lat: 48.625849, lng: 20.302071 },
    { lat: 48.625755, lng: 20.302198 },
    { lat: 48.625676, lng: 20.302305 },
    { lat: 48.625457, lng: 20.302601 },
    { lat: 48.625349, lng: 20.302715 },
    { lat: 48.624886, lng: 20.303081 },
    { lat: 48.624748, lng: 20.303184 },
    { lat: 48.624521, lng: 20.303191 },
    { lat: 48.624373, lng: 20.303208 },
    { lat: 48.623996, lng: 20.30354 },
    { lat: 48.623823, lng: 20.303601 },
    { lat: 48.62349, lng: 20.303781 },
    { lat: 48.623114, lng: 20.303698 },
    { lat: 48.623047, lng: 20.303687 },
    { lat: 48.622898, lng: 20.303678 },
    { lat: 48.622644, lng: 20.303667 },
    { lat: 48.622614, lng: 20.303645 },
    { lat: 48.622399, lng: 20.303487 },
    { lat: 48.622183, lng: 20.303382 },
    { lat: 48.622007, lng: 20.303336 },
    { lat: 48.621838, lng: 20.303266 },
    { lat: 48.621026, lng: 20.302748 },
    { lat: 48.620901, lng: 20.302749 },
    { lat: 48.620849, lng: 20.302772 },
    { lat: 48.620661, lng: 20.302876 },
    { lat: 48.620569, lng: 20.302926 },
    { lat: 48.620487, lng: 20.302908 },
    { lat: 48.620338, lng: 20.302844 },
    { lat: 48.620137, lng: 20.302782 },
    { lat: 48.619946, lng: 20.302691 },
    { lat: 48.619791, lng: 20.302556 },
    { lat: 48.619476, lng: 20.302227 },
    { lat: 48.618908, lng: 20.301627 },
    { lat: 48.618657, lng: 20.301471 },
    { lat: 48.618391, lng: 20.301326 },
    { lat: 48.618302, lng: 20.30129 },
    { lat: 48.618154, lng: 20.301244 },
    { lat: 48.61805, lng: 20.301165 },
    { lat: 48.617987, lng: 20.301016 },
    { lat: 48.617897, lng: 20.300701 },
    { lat: 48.617876, lng: 20.300344 },
    { lat: 48.617736, lng: 20.299944 },
    { lat: 48.617444, lng: 20.299455 },
    { lat: 48.617425, lng: 20.29946 },
    { lat: 48.617092, lng: 20.299052 },
    { lat: 48.616748, lng: 20.298606 },
    { lat: 48.616754, lng: 20.298577 },
    { lat: 48.616627, lng: 20.298409 },
    { lat: 48.616347, lng: 20.297916 },
    { lat: 48.616171, lng: 20.297605 },
    { lat: 48.615981, lng: 20.297451 },
    { lat: 48.615581, lng: 20.297274 },
    { lat: 48.61507, lng: 20.297012 },
    { lat: 48.614629, lng: 20.296656 },
    { lat: 48.614462, lng: 20.296516 },
    { lat: 48.614298, lng: 20.296399 },
    { lat: 48.614041, lng: 20.296228 },
    { lat: 48.613823, lng: 20.296235 },
    { lat: 48.613583, lng: 20.296231 },
    { lat: 48.613473, lng: 20.29624 },
    { lat: 48.613362, lng: 20.296258 },
    { lat: 48.61319, lng: 20.296274 },
    { lat: 48.612963, lng: 20.296199 },
    { lat: 48.612703, lng: 20.296118 },
    { lat: 48.612169, lng: 20.295938 },
    { lat: 48.611244, lng: 20.296271 },
    { lat: 48.611035, lng: 20.296459 },
    { lat: 48.610334, lng: 20.299353 },
    { lat: 48.610234, lng: 20.301004 },
    { lat: 48.610011, lng: 20.302948 },
    { lat: 48.609468, lng: 20.304446 },
    { lat: 48.609463, lng: 20.30449 },
    { lat: 48.609455, lng: 20.304573 },
    { lat: 48.609239, lng: 20.30659 },
    { lat: 48.60804, lng: 20.309866 },
    { lat: 48.607666, lng: 20.311879 },
    { lat: 48.607123, lng: 20.313996 },
    { lat: 48.606908, lng: 20.314711 },
    { lat: 48.606387, lng: 20.316443 },
    { lat: 48.606363, lng: 20.316495 },
    { lat: 48.606, lng: 20.316882 },
    { lat: 48.60572, lng: 20.31718 },
    { lat: 48.60535, lng: 20.317578 },
    { lat: 48.605305, lng: 20.317625 },
    { lat: 48.605067, lng: 20.320212 },
    { lat: 48.605063, lng: 20.320257 },
    { lat: 48.605055, lng: 20.320355 },
    { lat: 48.604985, lng: 20.321114 },
    { lat: 48.604979, lng: 20.321168 },
    { lat: 48.604978, lng: 20.321185 },
    { lat: 48.604974, lng: 20.32121 },
    { lat: 48.604992, lng: 20.321201 },
    { lat: 48.605079, lng: 20.321528 },
    { lat: 48.605157, lng: 20.321824 },
    { lat: 48.605312, lng: 20.322396 },
    { lat: 48.605346, lng: 20.32253 },
    { lat: 48.605364, lng: 20.322721 },
    { lat: 48.605409, lng: 20.32312 },
    { lat: 48.605449, lng: 20.323454 },
    { lat: 48.605588, lng: 20.323565 },
    { lat: 48.605763, lng: 20.323703 },
    { lat: 48.605872, lng: 20.3238 },
    { lat: 48.605909, lng: 20.323865 },
    { lat: 48.606027, lng: 20.324114 },
    { lat: 48.606249, lng: 20.324606 },
    { lat: 48.606465, lng: 20.324822 },
    { lat: 48.606607, lng: 20.325048 },
    { lat: 48.606637, lng: 20.325127 },
    { lat: 48.606639, lng: 20.325192 },
    { lat: 48.606633, lng: 20.325253 },
    { lat: 48.606558, lng: 20.325439 },
    { lat: 48.606379, lng: 20.32586 },
    { lat: 48.606298, lng: 20.326164 },
    { lat: 48.606255, lng: 20.326319 },
    { lat: 48.606208, lng: 20.326512 },
    { lat: 48.606306, lng: 20.326997 },
    { lat: 48.606544, lng: 20.327474 },
    { lat: 48.606691, lng: 20.32776 },
    { lat: 48.60681, lng: 20.327999 },
    { lat: 48.606933, lng: 20.328163 },
    { lat: 48.607085, lng: 20.328377 },
    { lat: 48.607177, lng: 20.328531 },
    { lat: 48.607499, lng: 20.329105 },
    { lat: 48.607798, lng: 20.329682 },
    { lat: 48.607908, lng: 20.329901 },
    { lat: 48.608044, lng: 20.330346 },
    { lat: 48.608204, lng: 20.33069 },
    { lat: 48.608396, lng: 20.33109 },
    { lat: 48.608529, lng: 20.331554 },
    { lat: 48.608671, lng: 20.332016 },
    { lat: 48.608806, lng: 20.332444 },
    { lat: 48.608808, lng: 20.332699 },
    { lat: 48.608809, lng: 20.332873 },
    { lat: 48.608735, lng: 20.333079 },
    { lat: 48.608691, lng: 20.33317 },
    { lat: 48.608707, lng: 20.333372 },
    { lat: 48.608683, lng: 20.333732 },
    { lat: 48.608544, lng: 20.334237 },
    { lat: 48.608517, lng: 20.33431 },
    { lat: 48.60829, lng: 20.334843 },
    { lat: 48.608012, lng: 20.335332 },
    { lat: 48.60786, lng: 20.335582 },
    { lat: 48.607721, lng: 20.335833 },
    { lat: 48.607663, lng: 20.336131 },
    { lat: 48.607338, lng: 20.336527 },
    { lat: 48.607204, lng: 20.33699 },
    { lat: 48.60718, lng: 20.337002 },
    { lat: 48.607001, lng: 20.337042 },
    { lat: 48.606989, lng: 20.337022 },
    { lat: 48.606968, lng: 20.33705 },
    { lat: 48.606864, lng: 20.337159 },
    { lat: 48.606778, lng: 20.337248 },
    { lat: 48.606616, lng: 20.337416 },
    { lat: 48.606585, lng: 20.337486 },
    { lat: 48.606477, lng: 20.337622 },
    { lat: 48.606287, lng: 20.337847 },
    { lat: 48.606119, lng: 20.337937 },
    { lat: 48.606095, lng: 20.337947 },
    { lat: 48.605761, lng: 20.337974 },
    { lat: 48.605594, lng: 20.337991 },
    { lat: 48.605399, lng: 20.337954 },
    { lat: 48.604899, lng: 20.338215 },
    { lat: 48.604665, lng: 20.338338 },
    { lat: 48.604579, lng: 20.338375 },
    { lat: 48.604265, lng: 20.338258 },
    { lat: 48.604216, lng: 20.338253 },
    { lat: 48.603819, lng: 20.338325 },
    { lat: 48.603104, lng: 20.338475 },
    { lat: 48.602801, lng: 20.338801 },
    { lat: 48.60279, lng: 20.338781 },
    { lat: 48.602476, lng: 20.339056 },
    { lat: 48.602154, lng: 20.339265 },
    { lat: 48.601859, lng: 20.339758 },
    { lat: 48.601579, lng: 20.340083 },
    { lat: 48.601508, lng: 20.340328 },
    { lat: 48.601373, lng: 20.340834 },
    { lat: 48.601352, lng: 20.340883 },
    { lat: 48.601318, lng: 20.34103 },
    { lat: 48.601283, lng: 20.341123 },
    { lat: 48.601221, lng: 20.341249 },
    { lat: 48.600466, lng: 20.342068 },
    { lat: 48.600276, lng: 20.342291 },
    { lat: 48.600169, lng: 20.342431 },
    { lat: 48.600129, lng: 20.34249 },
    { lat: 48.600119, lng: 20.342552 },
    { lat: 48.600105, lng: 20.343046 },
    { lat: 48.600116, lng: 20.343299 },
    { lat: 48.600135, lng: 20.343572 },
    { lat: 48.600216, lng: 20.343975 },
    { lat: 48.600403, lng: 20.344414 },
    { lat: 48.600375, lng: 20.344405 },
    { lat: 48.600366, lng: 20.344557 },
    { lat: 48.600267, lng: 20.345252 },
    { lat: 48.600177, lng: 20.345559 },
    { lat: 48.60011, lng: 20.345852 },
    { lat: 48.60001, lng: 20.34606 },
    { lat: 48.599907, lng: 20.346362 },
    { lat: 48.599874, lng: 20.346523 },
    { lat: 48.599784, lng: 20.346758 },
    { lat: 48.599551, lng: 20.347538 },
    { lat: 48.59948, lng: 20.348017 },
    { lat: 48.599451, lng: 20.34838 },
    { lat: 48.599469, lng: 20.348795 },
    { lat: 48.599422, lng: 20.349131 },
    { lat: 48.599312, lng: 20.34989 },
    { lat: 48.599332, lng: 20.349894 },
    { lat: 48.59925, lng: 20.350441 },
    { lat: 48.599146, lng: 20.350758 },
    { lat: 48.599079, lng: 20.351049 },
    { lat: 48.599026, lng: 20.351467 },
    { lat: 48.598951, lng: 20.351821 },
    { lat: 48.598712, lng: 20.352404 },
    { lat: 48.598673, lng: 20.352756 },
    { lat: 48.598597, lng: 20.353485 },
    { lat: 48.598713, lng: 20.354114 },
    { lat: 48.5987, lng: 20.354127 },
    { lat: 48.598829, lng: 20.354875 },
    { lat: 48.59883, lng: 20.354981 },
    { lat: 48.598831, lng: 20.355075 },
    { lat: 48.598849, lng: 20.355494 },
    { lat: 48.598839, lng: 20.355987 },
    { lat: 48.599889, lng: 20.3568645 },
    { lat: 48.6025424, lng: 20.3594823 },
    { lat: 48.6026223, lng: 20.3596026 },
  ],
  SilickáBrezová: [
    { lat: 48.5571824, lng: 20.484268 },
    { lat: 48.5574294, lng: 20.4836841 },
    { lat: 48.5573478, lng: 20.4824175 },
    { lat: 48.5572874, lng: 20.4811168 },
    { lat: 48.5572414, lng: 20.4810912 },
    { lat: 48.5542691, lng: 20.4788056 },
    { lat: 48.5535961, lng: 20.4781869 },
    { lat: 48.5506767, lng: 20.4758714 },
    { lat: 48.5501845, lng: 20.4756297 },
    { lat: 48.5460921, lng: 20.4722239 },
    { lat: 48.5449718, lng: 20.4714825 },
    { lat: 48.543239, lng: 20.4698832 },
    { lat: 48.5427028, lng: 20.4699364 },
    { lat: 48.5422748, lng: 20.4690389 },
    { lat: 48.541632, lng: 20.4685081 },
    { lat: 48.5396307, lng: 20.4679939 },
    { lat: 48.5382329, lng: 20.4672718 },
    { lat: 48.5381703, lng: 20.4662119 },
    { lat: 48.5379582, lng: 20.4643313 },
    { lat: 48.5379165, lng: 20.4641214 },
    { lat: 48.5377796, lng: 20.4642255 },
    { lat: 48.5368889, lng: 20.4644332 },
    { lat: 48.5365649, lng: 20.4646504 },
    { lat: 48.5362157, lng: 20.4646126 },
    { lat: 48.5358931, lng: 20.4647188 },
    { lat: 48.5355001, lng: 20.4647462 },
    { lat: 48.5332582, lng: 20.4653418 },
    { lat: 48.5329758, lng: 20.4654989 },
    { lat: 48.5328364, lng: 20.4655077 },
    { lat: 48.5322742, lng: 20.466182 },
    { lat: 48.5319262, lng: 20.4664148 },
    { lat: 48.5315451, lng: 20.4661946 },
    { lat: 48.5312621, lng: 20.4662747 },
    { lat: 48.5308577, lng: 20.4661193 },
    { lat: 48.5298266, lng: 20.4648519 },
    { lat: 48.5281443, lng: 20.4641115 },
    { lat: 48.5263285, lng: 20.4638861 },
    { lat: 48.5254115, lng: 20.4639045 },
    { lat: 48.5235053, lng: 20.463733 },
    { lat: 48.5216452, lng: 20.4640314 },
    { lat: 48.5212087, lng: 20.4638078 },
    { lat: 48.5204012, lng: 20.4632407 },
    { lat: 48.5199481, lng: 20.4631099 },
    { lat: 48.519234, lng: 20.4631304 },
    { lat: 48.5174136, lng: 20.4633806 },
    { lat: 48.5168811, lng: 20.463382 },
    { lat: 48.5165599, lng: 20.4634696 },
    { lat: 48.5160884, lng: 20.4637712 },
    { lat: 48.5157953, lng: 20.4660176 },
    { lat: 48.5159406, lng: 20.4680075 },
    { lat: 48.5164944, lng: 20.4719283 },
    { lat: 48.5161254, lng: 20.4757755 },
    { lat: 48.5151316, lng: 20.4786163 },
    { lat: 48.5144979, lng: 20.4842909 },
    { lat: 48.5146512, lng: 20.4862648 },
    { lat: 48.5157308, lng: 20.4909732 },
    { lat: 48.5152874, lng: 20.4921184 },
    { lat: 48.5141218, lng: 20.494292 },
    { lat: 48.5131796, lng: 20.4957937 },
    { lat: 48.5114587, lng: 20.4981799 },
    { lat: 48.5110681, lng: 20.5004312 },
    { lat: 48.5103354, lng: 20.5038815 },
    { lat: 48.51005, lng: 20.5048561 },
    { lat: 48.509506, lng: 20.5073 },
    { lat: 48.510055, lng: 20.508095 },
    { lat: 48.5107, lng: 20.50903 },
    { lat: 48.511101, lng: 20.509602 },
    { lat: 48.511414, lng: 20.510057 },
    { lat: 48.511445, lng: 20.510107 },
    { lat: 48.511544, lng: 20.510103 },
    { lat: 48.511714, lng: 20.510122 },
    { lat: 48.51174, lng: 20.510128 },
    { lat: 48.511786, lng: 20.510153 },
    { lat: 48.51197, lng: 20.510285 },
    { lat: 48.512155, lng: 20.5104 },
    { lat: 48.512271, lng: 20.510437 },
    { lat: 48.512374, lng: 20.510445 },
    { lat: 48.512466, lng: 20.510418 },
    { lat: 48.51261, lng: 20.510337 },
    { lat: 48.512723, lng: 20.510231 },
    { lat: 48.512822, lng: 20.510093 },
    { lat: 48.512871, lng: 20.510006 },
    { lat: 48.512997, lng: 20.509697 },
    { lat: 48.513137, lng: 20.509458 },
    { lat: 48.513187, lng: 20.509391 },
    { lat: 48.513248, lng: 20.509338 },
    { lat: 48.513672, lng: 20.509115 },
    { lat: 48.513843, lng: 20.508999 },
    { lat: 48.514068, lng: 20.508855 },
    { lat: 48.51435, lng: 20.508668 },
    { lat: 48.514454, lng: 20.508625 },
    { lat: 48.514592, lng: 20.508626 },
    { lat: 48.514706, lng: 20.508657 },
    { lat: 48.514784, lng: 20.508693 },
    { lat: 48.514833, lng: 20.508735 },
    { lat: 48.514988, lng: 20.508894 },
    { lat: 48.515145, lng: 20.509026 },
    { lat: 48.515195, lng: 20.509049 },
    { lat: 48.515269, lng: 20.509045 },
    { lat: 48.515403, lng: 20.509023 },
    { lat: 48.51551, lng: 20.509037 },
    { lat: 48.515542, lng: 20.509037 },
    { lat: 48.515576, lng: 20.509025 },
    { lat: 48.515626, lng: 20.508984 },
    { lat: 48.51586, lng: 20.508741 },
    { lat: 48.515917, lng: 20.508671 },
    { lat: 48.516006, lng: 20.508547 },
    { lat: 48.516169, lng: 20.508352 },
    { lat: 48.516277, lng: 20.508188 },
    { lat: 48.516308, lng: 20.508132 },
    { lat: 48.516346, lng: 20.508053 },
    { lat: 48.516425, lng: 20.507842 },
    { lat: 48.516452, lng: 20.507729 },
    { lat: 48.516551, lng: 20.507506 },
    { lat: 48.516608, lng: 20.507352 },
    { lat: 48.516703, lng: 20.507174 },
    { lat: 48.516796, lng: 20.507079 },
    { lat: 48.516992, lng: 20.506924 },
    { lat: 48.517102, lng: 20.506806 },
    { lat: 48.51724, lng: 20.506687 },
    { lat: 48.517389, lng: 20.506518 },
    { lat: 48.517418, lng: 20.506474 },
    { lat: 48.517475, lng: 20.506391 },
    { lat: 48.517588, lng: 20.50615 },
    { lat: 48.517689, lng: 20.50591 },
    { lat: 48.517812, lng: 20.505662 },
    { lat: 48.51796, lng: 20.505432 },
    { lat: 48.518018, lng: 20.505368 },
    { lat: 48.518101, lng: 20.505318 },
    { lat: 48.518215, lng: 20.505278 },
    { lat: 48.518323, lng: 20.505215 },
    { lat: 48.518458, lng: 20.505162 },
    { lat: 48.518484, lng: 20.505142 },
    { lat: 48.518554, lng: 20.505066 },
    { lat: 48.518799, lng: 20.504699 },
    { lat: 48.518942, lng: 20.50449 },
    { lat: 48.519021, lng: 20.504371 },
    { lat: 48.519061, lng: 20.504276 },
    { lat: 48.519129, lng: 20.50413 },
    { lat: 48.519194, lng: 20.504009 },
    { lat: 48.519485, lng: 20.503567 },
    { lat: 48.519575, lng: 20.503411 },
    { lat: 48.519707, lng: 20.503111 },
    { lat: 48.51974, lng: 20.502991 },
    { lat: 48.519751, lng: 20.502887 },
    { lat: 48.5197881, lng: 20.5025911 },
    { lat: 48.519827, lng: 20.502606 },
    { lat: 48.51985, lng: 20.502492 },
    { lat: 48.519866, lng: 20.502505 },
    { lat: 48.520648, lng: 20.503221 },
    { lat: 48.520871, lng: 20.50298 },
    { lat: 48.521191, lng: 20.502792 },
    { lat: 48.521525, lng: 20.503656 },
    { lat: 48.52191, lng: 20.504592 },
    { lat: 48.52225, lng: 20.505133 },
    { lat: 48.522397, lng: 20.504659 },
    { lat: 48.522462, lng: 20.50446 },
    { lat: 48.522558, lng: 20.504142 },
    { lat: 48.522758, lng: 20.503529 },
    { lat: 48.52305, lng: 20.50259 },
    { lat: 48.523285, lng: 20.502268 },
    { lat: 48.523418, lng: 20.502566 },
    { lat: 48.524095, lng: 20.503316 },
    { lat: 48.524786, lng: 20.504085 },
    { lat: 48.525061, lng: 20.504206 },
    { lat: 48.525572, lng: 20.504369 },
    { lat: 48.525757, lng: 20.50448 },
    { lat: 48.525852, lng: 20.504516 },
    { lat: 48.526102, lng: 20.504094 },
    { lat: 48.526398, lng: 20.503612 },
    { lat: 48.527062, lng: 20.50237 },
    { lat: 48.527262, lng: 20.501967 },
    { lat: 48.527981, lng: 20.502446 },
    { lat: 48.528613, lng: 20.50287 },
    { lat: 48.528838, lng: 20.503025 },
    { lat: 48.529038, lng: 20.503134 },
    { lat: 48.529325, lng: 20.503273 },
    { lat: 48.529592, lng: 20.503365 },
    { lat: 48.530075, lng: 20.503441 },
    { lat: 48.53071, lng: 20.503626 },
    { lat: 48.531279, lng: 20.503817 },
    { lat: 48.531406, lng: 20.50389 },
    { lat: 48.531718, lng: 20.504088 },
    { lat: 48.531934, lng: 20.504226 },
    { lat: 48.53221, lng: 20.504559 },
    { lat: 48.532371, lng: 20.504723 },
    { lat: 48.532791, lng: 20.505158 },
    { lat: 48.533089, lng: 20.505467 },
    { lat: 48.532623, lng: 20.506104 },
    { lat: 48.532215, lng: 20.506608 },
    { lat: 48.531673, lng: 20.507081 },
    { lat: 48.532348, lng: 20.508336 },
    { lat: 48.532745, lng: 20.507595 },
    { lat: 48.533241, lng: 20.507286 },
    { lat: 48.53338, lng: 20.50758 },
    { lat: 48.534415, lng: 20.506507 },
    { lat: 48.534526, lng: 20.507436 },
    { lat: 48.534786, lng: 20.508445 },
    { lat: 48.535047, lng: 20.509476 },
    { lat: 48.535412, lng: 20.510335 },
    { lat: 48.535629, lng: 20.511909 },
    { lat: 48.53565, lng: 20.512709 },
    { lat: 48.535667, lng: 20.513431 },
    { lat: 48.535844, lng: 20.515115 },
    { lat: 48.536323, lng: 20.516539 },
    { lat: 48.536761, lng: 20.517826 },
    { lat: 48.537519, lng: 20.5191 },
    { lat: 48.5379603, lng: 20.518615 },
    { lat: 48.5379746, lng: 20.5185434 },
    { lat: 48.5401621, lng: 20.5175102 },
    { lat: 48.5408562, lng: 20.5168973 },
    { lat: 48.5417403, lng: 20.5162662 },
    { lat: 48.5428712, lng: 20.5151641 },
    { lat: 48.5446342, lng: 20.5130837 },
    { lat: 48.5459393, lng: 20.5116448 },
    { lat: 48.5476825, lng: 20.5106309 },
    { lat: 48.548628, lng: 20.5098375 },
    { lat: 48.5489556, lng: 20.5089656 },
    { lat: 48.5492508, lng: 20.5083701 },
    { lat: 48.549047, lng: 20.5067808 },
    { lat: 48.5491411, lng: 20.5045797 },
    { lat: 48.5493743, lng: 20.503531 },
    { lat: 48.5506876, lng: 20.5004522 },
    { lat: 48.5519527, lng: 20.4971745 },
    { lat: 48.5538621, lng: 20.4926171 },
    { lat: 48.5550871, lng: 20.4894461 },
    { lat: 48.5567783, lng: 20.4852598 },
    { lat: 48.5571824, lng: 20.484268 },
  ],
  KunovaTeplica: [
    { lat: 48.62472, lng: 20.3624764 },
    { lat: 48.6244704, lng: 20.3623443 },
    { lat: 48.6238468, lng: 20.3626388 },
    { lat: 48.6235851, lng: 20.3628622 },
    { lat: 48.6233929, lng: 20.3631344 },
    { lat: 48.6231088, lng: 20.3633541 },
    { lat: 48.6210368, lng: 20.3634719 },
    { lat: 48.6207595, lng: 20.3633194 },
    { lat: 48.620006, lng: 20.3634032 },
    { lat: 48.6198354, lng: 20.3631299 },
    { lat: 48.6195688, lng: 20.3630207 },
    { lat: 48.6188941, lng: 20.3625217 },
    { lat: 48.6184875, lng: 20.3623749 },
    { lat: 48.6183618, lng: 20.3622425 },
    { lat: 48.6179083, lng: 20.3610244 },
    { lat: 48.617437, lng: 20.361126 },
    { lat: 48.6164325, lng: 20.3609266 },
    { lat: 48.6157161, lng: 20.3611834 },
    { lat: 48.615161, lng: 20.3612426 },
    { lat: 48.6148188, lng: 20.3613931 },
    { lat: 48.6144276, lng: 20.3612919 },
    { lat: 48.6135691, lng: 20.360901 },
    { lat: 48.6121734, lng: 20.3606593 },
    { lat: 48.612079, lng: 20.3605203 },
    { lat: 48.61166, lng: 20.3604294 },
    { lat: 48.6111306, lng: 20.3604587 },
    { lat: 48.6105066, lng: 20.3601887 },
    { lat: 48.6101055, lng: 20.3602761 },
    { lat: 48.6099592, lng: 20.3602149 },
    { lat: 48.6098478, lng: 20.3599571 },
    { lat: 48.6095143, lng: 20.3597186 },
    { lat: 48.608809, lng: 20.3620329 },
    { lat: 48.6087247, lng: 20.362098 },
    { lat: 48.6081936, lng: 20.361674 },
    { lat: 48.6073418, lng: 20.3612562 },
    { lat: 48.606777, lng: 20.360658 },
    { lat: 48.607, lng: 20.3601092 },
    { lat: 48.6066953, lng: 20.3598884 },
    { lat: 48.6065324, lng: 20.3601006 },
    { lat: 48.6066236, lng: 20.3602178 },
    { lat: 48.6065328, lng: 20.3603707 },
    { lat: 48.6063567, lng: 20.3604484 },
    { lat: 48.6062762, lng: 20.3603758 },
    { lat: 48.6061732, lng: 20.3605077 },
    { lat: 48.6056721, lng: 20.3602483 },
    { lat: 48.6052202, lng: 20.3606137 },
    { lat: 48.6049948, lng: 20.3610186 },
    { lat: 48.6049492, lng: 20.3614385 },
    { lat: 48.6048711, lng: 20.3614262 },
    { lat: 48.6047378, lng: 20.3615369 },
    { lat: 48.6044793, lng: 20.3614906 },
    { lat: 48.602632, lng: 20.3594983 },
    { lat: 48.6026223, lng: 20.3596026 },
    { lat: 48.6025933, lng: 20.359956 },
    { lat: 48.6027163, lng: 20.3620576 },
    { lat: 48.6026764, lng: 20.3625624 },
    { lat: 48.6027003, lng: 20.3637939 },
    { lat: 48.6026129, lng: 20.3642111 },
    { lat: 48.6026034, lng: 20.3645774 },
    { lat: 48.6024693, lng: 20.3648052 },
    { lat: 48.6024208, lng: 20.3652104 },
    { lat: 48.6024977, lng: 20.365309 },
    { lat: 48.602508, lng: 20.3654276 },
    { lat: 48.6026124, lng: 20.3653871 },
    { lat: 48.6027264, lng: 20.3654543 },
    { lat: 48.6027313, lng: 20.3656125 },
    { lat: 48.6026452, lng: 20.3658016 },
    { lat: 48.6026868, lng: 20.365858 },
    { lat: 48.6028136, lng: 20.3658173 },
    { lat: 48.6028384, lng: 20.365934 },
    { lat: 48.602924, lng: 20.365993 },
    { lat: 48.6029211, lng: 20.36609 },
    { lat: 48.6030714, lng: 20.3661067 },
    { lat: 48.6031107, lng: 20.3662448 },
    { lat: 48.6029509, lng: 20.3664261 },
    { lat: 48.6030149, lng: 20.366946 },
    { lat: 48.6029179, lng: 20.367025 },
    { lat: 48.6027722, lng: 20.367046 },
    { lat: 48.602654, lng: 20.3669554 },
    { lat: 48.6025484, lng: 20.3670269 },
    { lat: 48.6024608, lng: 20.3673769 },
    { lat: 48.6024909, lng: 20.3674618 },
    { lat: 48.6024366, lng: 20.3675701 },
    { lat: 48.6022555, lng: 20.3676892 },
    { lat: 48.6022862, lng: 20.3679278 },
    { lat: 48.602232, lng: 20.3679852 },
    { lat: 48.6021684, lng: 20.3679442 },
    { lat: 48.6021283, lng: 20.3680676 },
    { lat: 48.6021277, lng: 20.3681128 },
    { lat: 48.6022501, lng: 20.368159 },
    { lat: 48.6024317, lng: 20.3680228 },
    { lat: 48.6025484, lng: 20.3681915 },
    { lat: 48.6025492, lng: 20.3683259 },
    { lat: 48.6023071, lng: 20.3685272 },
    { lat: 48.6022293, lng: 20.3687568 },
    { lat: 48.6023739, lng: 20.3690593 },
    { lat: 48.602343, lng: 20.3692542 },
    { lat: 48.6022244, lng: 20.3694147 },
    { lat: 48.6021126, lng: 20.3698451 },
    { lat: 48.6021375, lng: 20.3703724 },
    { lat: 48.6021072, lng: 20.3705209 },
    { lat: 48.6026254, lng: 20.3708144 },
    { lat: 48.6025172, lng: 20.371343 },
    { lat: 48.6029107, lng: 20.3715525 },
    { lat: 48.6018969, lng: 20.3751608 },
    { lat: 48.6021697, lng: 20.3754286 },
    { lat: 48.6019209, lng: 20.3766847 },
    { lat: 48.6021384, lng: 20.3770147 },
    { lat: 48.6012583, lng: 20.3779874 },
    { lat: 48.6004653, lng: 20.3785651 },
    { lat: 48.5991828, lng: 20.3796774 },
    { lat: 48.5995, lng: 20.380339 },
    { lat: 48.5996851, lng: 20.3808736 },
    { lat: 48.6000569, lng: 20.3823388 },
    { lat: 48.6002254, lng: 20.3822429 },
    { lat: 48.6010187, lng: 20.3842668 },
    { lat: 48.6011303, lng: 20.384706 },
    { lat: 48.6009418, lng: 20.3850509 },
    { lat: 48.6009843, lng: 20.3852699 },
    { lat: 48.6009644, lng: 20.3855755 },
    { lat: 48.6011266, lng: 20.3862508 },
    { lat: 48.6011069, lng: 20.3869082 },
    { lat: 48.6011811, lng: 20.3875493 },
    { lat: 48.6009735, lng: 20.3875372 },
    { lat: 48.600767, lng: 20.3873226 },
    { lat: 48.6003404, lng: 20.3872945 },
    { lat: 48.6004285, lng: 20.3867503 },
    { lat: 48.6004066, lng: 20.386416 },
    { lat: 48.600013, lng: 20.3859862 },
    { lat: 48.5994954, lng: 20.3865218 },
    { lat: 48.5992548, lng: 20.3868246 },
    { lat: 48.5992396, lng: 20.386932 },
    { lat: 48.5983711, lng: 20.3872717 },
    { lat: 48.5978045, lng: 20.3873565 },
    { lat: 48.5977984, lng: 20.3874321 },
    { lat: 48.5968948, lng: 20.3880057 },
    { lat: 48.5967568, lng: 20.3879883 },
    { lat: 48.596462, lng: 20.3882251 },
    { lat: 48.5966322, lng: 20.3885517 },
    { lat: 48.5968141, lng: 20.3887713 },
    { lat: 48.5967322, lng: 20.3896596 },
    { lat: 48.5964256, lng: 20.3905271 },
    { lat: 48.5946592, lng: 20.3953985 },
    { lat: 48.5948102, lng: 20.3957214 },
    { lat: 48.5948403, lng: 20.3962951 },
    { lat: 48.5949518, lng: 20.3969125 },
    { lat: 48.5950659, lng: 20.3971299 },
    { lat: 48.5954069, lng: 20.3972968 },
    { lat: 48.5956483, lng: 20.3972782 },
    { lat: 48.5959144, lng: 20.3975228 },
    { lat: 48.5965104, lng: 20.3977963 },
    { lat: 48.5967049, lng: 20.3979952 },
    { lat: 48.5968933, lng: 20.3983836 },
    { lat: 48.5970319, lng: 20.398533 },
    { lat: 48.5973286, lng: 20.398691 },
    { lat: 48.5976137, lng: 20.3986672 },
    { lat: 48.5987845, lng: 20.3996827 },
    { lat: 48.5990641, lng: 20.3999842 },
    { lat: 48.5991739, lng: 20.4002617 },
    { lat: 48.5996873, lng: 20.4007341 },
    { lat: 48.5999716, lng: 20.4012722 },
    { lat: 48.6001352, lng: 20.4020417 },
    { lat: 48.6004593, lng: 20.4026548 },
    { lat: 48.6009022, lng: 20.4029236 },
    { lat: 48.6010909, lng: 20.4030871 },
    { lat: 48.6012396, lng: 20.4033844 },
    { lat: 48.6015169, lng: 20.403569 },
    { lat: 48.6022577, lng: 20.4033581 },
    { lat: 48.6026897, lng: 20.4039342 },
    { lat: 48.6029211, lng: 20.404035 },
    { lat: 48.603366, lng: 20.4043633 },
    { lat: 48.6037784, lng: 20.4044291 },
    { lat: 48.6039544, lng: 20.4044113 },
    { lat: 48.6041045, lng: 20.404269 },
    { lat: 48.604643, lng: 20.4033055 },
    { lat: 48.6058324, lng: 20.4027548 },
    { lat: 48.6067771, lng: 20.4026353 },
    { lat: 48.6073264, lng: 20.4023706 },
    { lat: 48.6081644, lng: 20.4022858 },
    { lat: 48.6084946, lng: 20.4024099 },
    { lat: 48.6090787, lng: 20.4022227 },
    { lat: 48.6096531, lng: 20.4017565 },
    { lat: 48.6102634, lng: 20.4015805 },
    { lat: 48.6108403, lng: 20.4016237 },
    { lat: 48.6112735, lng: 20.4019058 },
    { lat: 48.6117847, lng: 20.4017597 },
    { lat: 48.6119433, lng: 20.4013989 },
    { lat: 48.6122764, lng: 20.4011631 },
    { lat: 48.6125389, lng: 20.4007839 },
    { lat: 48.6126938, lng: 20.4008288 },
    { lat: 48.6129655, lng: 20.4007755 },
    { lat: 48.6132338, lng: 20.4008759 },
    { lat: 48.6133781, lng: 20.4007864 },
    { lat: 48.6139994, lng: 20.4006621 },
    { lat: 48.6144776, lng: 20.4007413 },
    { lat: 48.6148886, lng: 20.4006833 },
    { lat: 48.615367, lng: 20.4008504 },
    { lat: 48.6154905, lng: 20.4007859 },
    { lat: 48.6158189, lng: 20.4002206 },
    { lat: 48.6161446, lng: 20.400227 },
    { lat: 48.6163251, lng: 20.400029 },
    { lat: 48.6166656, lng: 20.3999195 },
    { lat: 48.6168196, lng: 20.3999406 },
    { lat: 48.617458, lng: 20.3997973 },
    { lat: 48.6179576, lng: 20.3998181 },
    { lat: 48.6187216, lng: 20.4000364 },
    { lat: 48.6199258, lng: 20.4001312 },
    { lat: 48.6210907, lng: 20.3999127 },
    { lat: 48.6219077, lng: 20.399632 },
    { lat: 48.6249219, lng: 20.3930929 },
    { lat: 48.6250816, lng: 20.3923001 },
    { lat: 48.6254568, lng: 20.3914831 },
    { lat: 48.6255963, lng: 20.3905717 },
    { lat: 48.6255371, lng: 20.3905534 },
    { lat: 48.6255168, lng: 20.3903519 },
    { lat: 48.6258857, lng: 20.3893731 },
    { lat: 48.6259728, lng: 20.3887977 },
    { lat: 48.6263736, lng: 20.3881803 },
    { lat: 48.626281, lng: 20.3877381 },
    { lat: 48.626269, lng: 20.3874569 },
    { lat: 48.6261552, lng: 20.3874551 },
    { lat: 48.6259357, lng: 20.3870728 },
    { lat: 48.6256759, lng: 20.3838713 },
    { lat: 48.6255901, lng: 20.3821649 },
    { lat: 48.6257737, lng: 20.3822264 },
    { lat: 48.6257623, lng: 20.3816681 },
    { lat: 48.6267048, lng: 20.3813448 },
    { lat: 48.6266753, lng: 20.3808718 },
    { lat: 48.6275389, lng: 20.380607 },
    { lat: 48.6275993, lng: 20.3800703 },
    { lat: 48.6276102, lng: 20.3794225 },
    { lat: 48.6273924, lng: 20.3777454 },
    { lat: 48.6272711, lng: 20.377435 },
    { lat: 48.6268099, lng: 20.374705 },
    { lat: 48.6264273, lng: 20.3727548 },
    { lat: 48.6261273, lng: 20.3706471 },
    { lat: 48.625957, lng: 20.3699701 },
    { lat: 48.6252564, lng: 20.3658909 },
    { lat: 48.6251034, lng: 20.3647037 },
    { lat: 48.6249905, lng: 20.3643413 },
    { lat: 48.6247467, lng: 20.3629642 },
    { lat: 48.6247204, lng: 20.3624853 },
    { lat: 48.62472, lng: 20.3624764 },
  ],
  Hrušov: [
    { lat: 48.6071546, lng: 20.5900853 },
    { lat: 48.6070328, lng: 20.5897137 },
    { lat: 48.6068871, lng: 20.5892676 },
    { lat: 48.6056674, lng: 20.5844259 },
    { lat: 48.6048984, lng: 20.5836314 },
    { lat: 48.6045551, lng: 20.5820159 },
    { lat: 48.6036843, lng: 20.5809283 },
    { lat: 48.6028992, lng: 20.5784782 },
    { lat: 48.602118, lng: 20.575029 },
    { lat: 48.6020535, lng: 20.5750842 },
    { lat: 48.6008555, lng: 20.5767623 },
    { lat: 48.5993753, lng: 20.5786988 },
    { lat: 48.5985598, lng: 20.5803088 },
    { lat: 48.598057, lng: 20.5816066 },
    { lat: 48.5978287, lng: 20.5825335 },
    { lat: 48.5974006, lng: 20.583682 },
    { lat: 48.5978514, lng: 20.5841231 },
    { lat: 48.5982722, lng: 20.5861965 },
    { lat: 48.5979656, lng: 20.5870136 },
    { lat: 48.5985624, lng: 20.587837 },
    { lat: 48.5982648, lng: 20.5887195 },
    { lat: 48.598111, lng: 20.5898319 },
    { lat: 48.5971211, lng: 20.5916942 },
    { lat: 48.596256, lng: 20.5930313 },
    { lat: 48.5949749, lng: 20.5944206 },
    { lat: 48.5944188, lng: 20.5955266 },
    { lat: 48.5934255, lng: 20.5965019 },
    { lat: 48.592223, lng: 20.5972609 },
    { lat: 48.5914286, lng: 20.5975822 },
    { lat: 48.5907916, lng: 20.5976876 },
    { lat: 48.5897176, lng: 20.5976858 },
    { lat: 48.5895455, lng: 20.5977524 },
    { lat: 48.5883841, lng: 20.5978023 },
    { lat: 48.5872053, lng: 20.5977104 },
    { lat: 48.5862768, lng: 20.5979653 },
    { lat: 48.5847829, lng: 20.5984927 },
    { lat: 48.5845965, lng: 20.5984724 },
    { lat: 48.5834717, lng: 20.5991782 },
    { lat: 48.5824356, lng: 20.6006151 },
    { lat: 48.5802275, lng: 20.602171 },
    { lat: 48.5786462, lng: 20.6029295 },
    { lat: 48.5781775, lng: 20.6033192 },
    { lat: 48.5771495, lng: 20.6052231 },
    { lat: 48.5766998, lng: 20.6057957 },
    { lat: 48.5753255, lng: 20.6065088 },
    { lat: 48.5743232, lng: 20.6071191 },
    { lat: 48.5737201, lng: 20.6082405 },
    { lat: 48.5734475, lng: 20.6093063 },
    { lat: 48.5733031, lng: 20.6106537 },
    { lat: 48.5732946, lng: 20.6114714 },
    { lat: 48.5734022, lng: 20.6128071 },
    { lat: 48.5733214, lng: 20.6129699 },
    { lat: 48.5727885, lng: 20.6132378 },
    { lat: 48.5723481, lng: 20.6136907 },
    { lat: 48.5720298, lng: 20.6142583 },
    { lat: 48.5717612, lng: 20.6149781 },
    { lat: 48.5718237, lng: 20.6149344 },
    { lat: 48.5715284, lng: 20.6156283 },
    { lat: 48.5707017, lng: 20.6173492 },
    { lat: 48.5710129, lng: 20.6179167 },
    { lat: 48.5704104, lng: 20.6184356 },
    { lat: 48.5701546, lng: 20.6188603 },
    { lat: 48.571115, lng: 20.6232708 },
    { lat: 48.5709147, lng: 20.6237681 },
    { lat: 48.5709044, lng: 20.6239431 },
    { lat: 48.5709866, lng: 20.624658 },
    { lat: 48.5710312, lng: 20.6247098 },
    { lat: 48.5709789, lng: 20.6249704 },
    { lat: 48.5710195, lng: 20.6250943 },
    { lat: 48.5710841, lng: 20.6250787 },
    { lat: 48.5710335, lng: 20.6256001 },
    { lat: 48.5709122, lng: 20.6257364 },
    { lat: 48.5708624, lng: 20.6256968 },
    { lat: 48.5707798, lng: 20.6257488 },
    { lat: 48.5707518, lng: 20.6259683 },
    { lat: 48.5706186, lng: 20.6258947 },
    { lat: 48.570585, lng: 20.6259306 },
    { lat: 48.5706248, lng: 20.6262519 },
    { lat: 48.5705617, lng: 20.6264039 },
    { lat: 48.5705945, lng: 20.6265029 },
    { lat: 48.5704587, lng: 20.6266325 },
    { lat: 48.570344, lng: 20.6266075 },
    { lat: 48.5702816, lng: 20.6265331 },
    { lat: 48.570189, lng: 20.626555 },
    { lat: 48.5699902, lng: 20.6263247 },
    { lat: 48.5698908, lng: 20.6265046 },
    { lat: 48.5699523, lng: 20.6266446 },
    { lat: 48.5699101, lng: 20.6267547 },
    { lat: 48.5698029, lng: 20.6267775 },
    { lat: 48.5697721, lng: 20.6270406 },
    { lat: 48.5697195, lng: 20.6270528 },
    { lat: 48.5696224, lng: 20.6268891 },
    { lat: 48.5694979, lng: 20.626839 },
    { lat: 48.5693865, lng: 20.6269339 },
    { lat: 48.569312, lng: 20.6268711 },
    { lat: 48.5692364, lng: 20.6269275 },
    { lat: 48.5690708, lng: 20.6267083 },
    { lat: 48.5689687, lng: 20.6269041 },
    { lat: 48.5688458, lng: 20.6269303 },
    { lat: 48.5688819, lng: 20.6270535 },
    { lat: 48.5690338, lng: 20.6270829 },
    { lat: 48.5690348, lng: 20.6272342 },
    { lat: 48.5688367, lng: 20.6272368 },
    { lat: 48.5687794, lng: 20.6273023 },
    { lat: 48.5687294, lng: 20.6271333 },
    { lat: 48.5686992, lng: 20.6271405 },
    { lat: 48.5687151, lng: 20.6273887 },
    { lat: 48.5688095, lng: 20.6274484 },
    { lat: 48.5688074, lng: 20.6275702 },
    { lat: 48.5686669, lng: 20.6276131 },
    { lat: 48.56866, lng: 20.6277292 },
    { lat: 48.5686041, lng: 20.6277416 },
    { lat: 48.5685241, lng: 20.6274955 },
    { lat: 48.5684839, lng: 20.6275315 },
    { lat: 48.5684445, lng: 20.6277765 },
    { lat: 48.5683217, lng: 20.6275276 },
    { lat: 48.5682442, lng: 20.6275965 },
    { lat: 48.5681665, lng: 20.6275671 },
    { lat: 48.5680593, lng: 20.6279272 },
    { lat: 48.5680059, lng: 20.6279767 },
    { lat: 48.5680388, lng: 20.6280458 },
    { lat: 48.5680197, lng: 20.6281427 },
    { lat: 48.5679525, lng: 20.6282154 },
    { lat: 48.5678452, lng: 20.6281134 },
    { lat: 48.5676829, lng: 20.6282879 },
    { lat: 48.5676362, lng: 20.6284318 },
    { lat: 48.5673945, lng: 20.6285631 },
    { lat: 48.5673959, lng: 20.6287577 },
    { lat: 48.5672991, lng: 20.6287551 },
    { lat: 48.56727, lng: 20.6288897 },
    { lat: 48.5671598, lng: 20.629013 },
    { lat: 48.567037, lng: 20.6290406 },
    { lat: 48.5669725, lng: 20.6291881 },
    { lat: 48.5667616, lng: 20.6293627 },
    { lat: 48.5667114, lng: 20.6295012 },
    { lat: 48.5666039, lng: 20.6294824 },
    { lat: 48.5664131, lng: 20.6296524 },
    { lat: 48.5662868, lng: 20.6299888 },
    { lat: 48.5661976, lng: 20.6300136 },
    { lat: 48.565981, lng: 20.6302516 },
    { lat: 48.5659089, lng: 20.630436 },
    { lat: 48.5660003, lng: 20.6307185 },
    { lat: 48.565942, lng: 20.6309434 },
    { lat: 48.5657943, lng: 20.6308134 },
    { lat: 48.5656069, lng: 20.63089 },
    { lat: 48.5655994, lng: 20.6310265 },
    { lat: 48.5655121, lng: 20.6310977 },
    { lat: 48.5651039, lng: 20.632099 },
    { lat: 48.5651451, lng: 20.6325517 },
    { lat: 48.5653618, lng: 20.6326336 },
    { lat: 48.5654264, lng: 20.6328267 },
    { lat: 48.5652597, lng: 20.6334767 },
    { lat: 48.5650327, lng: 20.6336707 },
    { lat: 48.5642839, lng: 20.6349082 },
    { lat: 48.5628141, lng: 20.6344886 },
    { lat: 48.5623458, lng: 20.6342156 },
    { lat: 48.5613227, lng: 20.6334503 },
    { lat: 48.5610059, lng: 20.633314 },
    { lat: 48.5608154, lng: 20.6333134 },
    { lat: 48.5605243, lng: 20.6334747 },
    { lat: 48.5604583, lng: 20.6342926 },
    { lat: 48.5603896, lng: 20.6345372 },
    { lat: 48.5602273, lng: 20.6346629 },
    { lat: 48.5599938, lng: 20.6345852 },
    { lat: 48.5597108, lng: 20.6346352 },
    { lat: 48.5591923, lng: 20.6351163 },
    { lat: 48.5588397, lng: 20.6352346 },
    { lat: 48.5585101, lng: 20.635243 },
    { lat: 48.5576492, lng: 20.6357794 },
    { lat: 48.5574026, lng: 20.6361164 },
    { lat: 48.5573964, lng: 20.6363792 },
    { lat: 48.5574471, lng: 20.636567 },
    { lat: 48.5576673, lng: 20.636836 },
    { lat: 48.5576673, lng: 20.6370158 },
    { lat: 48.5572458, lng: 20.6387378 },
    { lat: 48.5572469, lng: 20.6389577 },
    { lat: 48.5573869, lng: 20.6394579 },
    { lat: 48.5573767, lng: 20.639646 },
    { lat: 48.557079, lng: 20.6407504 },
    { lat: 48.5571556, lng: 20.6416742 },
    { lat: 48.557042, lng: 20.6422362 },
    { lat: 48.5569206, lng: 20.6424008 },
    { lat: 48.5569078, lng: 20.6429794 },
    { lat: 48.5574254, lng: 20.6436781 },
    { lat: 48.5579867, lng: 20.644874 },
    { lat: 48.5581896, lng: 20.6450961 },
    { lat: 48.5589746, lng: 20.6452003 },
    { lat: 48.5590669, lng: 20.6453515 },
    { lat: 48.5592979, lng: 20.6467247 },
    { lat: 48.5596438, lng: 20.6481549 },
    { lat: 48.5601272, lng: 20.6490957 },
    { lat: 48.5607078, lng: 20.6505453 },
    { lat: 48.5613934, lng: 20.6524388 },
    { lat: 48.5614775, lng: 20.6530799 },
    { lat: 48.561413, lng: 20.653874 },
    { lat: 48.561368, lng: 20.65435 },
    { lat: 48.561353, lng: 20.654591 },
    { lat: 48.561356, lng: 20.655143 },
    { lat: 48.561322, lng: 20.655538 },
    { lat: 48.56124, lng: 20.655907 },
    { lat: 48.561194, lng: 20.656033 },
    { lat: 48.561157, lng: 20.656205 },
    { lat: 48.561169, lng: 20.656453 },
    { lat: 48.561185, lng: 20.656661 },
    { lat: 48.561158, lng: 20.656933 },
    { lat: 48.561179, lng: 20.656941 },
    { lat: 48.561164, lng: 20.657116 },
    { lat: 48.561033, lng: 20.658628 },
    { lat: 48.560975, lng: 20.659145 },
    { lat: 48.560898, lng: 20.659931 },
    { lat: 48.560635, lng: 20.661871 },
    { lat: 48.560509, lng: 20.663034 },
    { lat: 48.5629118, lng: 20.6611023 },
    { lat: 48.5645488, lng: 20.6603569 },
    { lat: 48.5672248, lng: 20.6587973 },
    { lat: 48.5662528, lng: 20.6568538 },
    { lat: 48.5656025, lng: 20.6556866 },
    { lat: 48.5663733, lng: 20.6533429 },
    { lat: 48.5685833, lng: 20.6473801 },
    { lat: 48.5687504, lng: 20.6473937 },
    { lat: 48.5703928, lng: 20.6480408 },
    { lat: 48.5722795, lng: 20.6474407 },
    { lat: 48.5746556, lng: 20.6457783 },
    { lat: 48.5745435, lng: 20.6451426 },
    { lat: 48.5746285, lng: 20.6448935 },
    { lat: 48.5745543, lng: 20.6447887 },
    { lat: 48.5746585, lng: 20.6442741 },
    { lat: 48.575458, lng: 20.6439732 },
    { lat: 48.5754595, lng: 20.6440399 },
    { lat: 48.5788586, lng: 20.6438602 },
    { lat: 48.5804931, lng: 20.6428592 },
    { lat: 48.581799, lng: 20.6427888 },
    { lat: 48.5822332, lng: 20.6429031 },
    { lat: 48.5825147, lng: 20.6431579 },
    { lat: 48.5837322, lng: 20.6437836 },
    { lat: 48.5847837, lng: 20.6447118 },
    { lat: 48.5857152, lng: 20.6458721 },
    { lat: 48.5867595, lng: 20.6466665 },
    { lat: 48.5869287, lng: 20.6469207 },
    { lat: 48.5879118, lng: 20.6472725 },
    { lat: 48.5890502, lng: 20.6472405 },
    { lat: 48.589191, lng: 20.6471816 },
    { lat: 48.5900417, lng: 20.647199 },
    { lat: 48.5904679, lng: 20.6470906 },
    { lat: 48.5906219, lng: 20.6471476 },
    { lat: 48.591102, lng: 20.6470714 },
    { lat: 48.5915108, lng: 20.6470999 },
    { lat: 48.5917475, lng: 20.6470182 },
    { lat: 48.5918548, lng: 20.6470897 },
    { lat: 48.5923131, lng: 20.6469932 },
    { lat: 48.5924053, lng: 20.6470262 },
    { lat: 48.5924593, lng: 20.6469595 },
    { lat: 48.592814, lng: 20.6469957 },
    { lat: 48.5930915, lng: 20.6468853 },
    { lat: 48.5932286, lng: 20.6469725 },
    { lat: 48.5938155, lng: 20.6469498 },
    { lat: 48.5940088, lng: 20.6468481 },
    { lat: 48.5942773, lng: 20.6468925 },
    { lat: 48.59467, lng: 20.6470717 },
    { lat: 48.5948188, lng: 20.6470118 },
    { lat: 48.5956841, lng: 20.6455387 },
    { lat: 48.5957944, lng: 20.6452319 },
    { lat: 48.5955905, lng: 20.6445722 },
    { lat: 48.5957122, lng: 20.6444221 },
    { lat: 48.5958861, lng: 20.6443771 },
    { lat: 48.5960211, lng: 20.6441835 },
    { lat: 48.5969176, lng: 20.6438528 },
    { lat: 48.59702, lng: 20.6437284 },
    { lat: 48.5970798, lng: 20.6434186 },
    { lat: 48.5973221, lng: 20.6433993 },
    { lat: 48.5973503, lng: 20.6435197 },
    { lat: 48.597563, lng: 20.6435167 },
    { lat: 48.5977937, lng: 20.6440426 },
    { lat: 48.5978897, lng: 20.644146 },
    { lat: 48.5980655, lng: 20.6439647 },
    { lat: 48.5982443, lng: 20.6439596 },
    { lat: 48.5983789, lng: 20.6438193 },
    { lat: 48.5984143, lng: 20.6435841 },
    { lat: 48.5984879, lng: 20.6435656 },
    { lat: 48.5986506, lng: 20.6432715 },
    { lat: 48.598789, lng: 20.6431456 },
    { lat: 48.5991642, lng: 20.643121 },
    { lat: 48.5995846, lng: 20.6428274 },
    { lat: 48.5996295, lng: 20.6427262 },
    { lat: 48.5996549, lng: 20.6423403 },
    { lat: 48.5998356, lng: 20.6422941 },
    { lat: 48.6001673, lng: 20.6423788 },
    { lat: 48.6002163, lng: 20.6422975 },
    { lat: 48.6009263, lng: 20.641949 },
    { lat: 48.6018211, lng: 20.6409485 },
    { lat: 48.602138, lng: 20.6402792 },
    { lat: 48.6025844, lng: 20.6398012 },
    { lat: 48.6038604, lng: 20.6373959 },
    { lat: 48.6059149, lng: 20.636986 },
    { lat: 48.6060449, lng: 20.6373378 },
    { lat: 48.6074351, lng: 20.6380155 },
    { lat: 48.6076255, lng: 20.6380161 },
    { lat: 48.6077799, lng: 20.6379276 },
    { lat: 48.6075566, lng: 20.6351611 },
    { lat: 48.6072738, lng: 20.6333914 },
    { lat: 48.6072372, lng: 20.6321404 },
    { lat: 48.6069891, lng: 20.6299321 },
    { lat: 48.6067015, lng: 20.6265213 },
    { lat: 48.6065826, lng: 20.6263131 },
    { lat: 48.6061752, lng: 20.6234489 },
    { lat: 48.6063116, lng: 20.6229889 },
    { lat: 48.6063413, lng: 20.6207786 },
    { lat: 48.6064142, lng: 20.6205267 },
    { lat: 48.6064306, lng: 20.6190938 },
    { lat: 48.6063374, lng: 20.6180858 },
    { lat: 48.6061219, lng: 20.6170809 },
    { lat: 48.6060952, lng: 20.6158917 },
    { lat: 48.6061545, lng: 20.6154488 },
    { lat: 48.6060763, lng: 20.6148192 },
    { lat: 48.6061056, lng: 20.6139231 },
    { lat: 48.6062203, lng: 20.6136734 },
    { lat: 48.6061748, lng: 20.613172 },
    { lat: 48.6062269, lng: 20.61293 },
    { lat: 48.606221, lng: 20.6124033 },
    { lat: 48.606261, lng: 20.6121413 },
    { lat: 48.60658, lng: 20.6114463 },
    { lat: 48.606651, lng: 20.6111337 },
    { lat: 48.6069497, lng: 20.610502 },
    { lat: 48.6076306, lng: 20.6095096 },
    { lat: 48.6076718, lng: 20.609096 },
    { lat: 48.607654, lng: 20.608208 },
    { lat: 48.6079863, lng: 20.6072016 },
    { lat: 48.6080319, lng: 20.606815 },
    { lat: 48.6082228, lng: 20.6060958 },
    { lat: 48.6083728, lng: 20.6057325 },
    { lat: 48.608501, lng: 20.6055731 },
    { lat: 48.6086504, lng: 20.6036302 },
    { lat: 48.6085094, lng: 20.6014709 },
    { lat: 48.6086962, lng: 20.5990421 },
    { lat: 48.6084347, lng: 20.5964553 },
    { lat: 48.6084629, lng: 20.5960922 },
    { lat: 48.6082298, lng: 20.5943377 },
    { lat: 48.6082326, lng: 20.5925774 },
    { lat: 48.6081639, lng: 20.5915372 },
    { lat: 48.6071546, lng: 20.5900853 },
  ],
  Gočaltovo: [
    { lat: 48.637316, lng: 20.29669 },
    { lat: 48.6372127, lng: 20.2973047 },
    { lat: 48.6371536, lng: 20.2981549 },
    { lat: 48.6369825, lng: 20.2987552 },
    { lat: 48.6368219, lng: 20.2991424 },
    { lat: 48.6364688, lng: 20.2997831 },
    { lat: 48.6358948, lng: 20.3001122 },
    { lat: 48.6355902, lng: 20.3004139 },
    { lat: 48.6354948, lng: 20.3005937 },
    { lat: 48.6354737, lng: 20.3009993 },
    { lat: 48.6347029, lng: 20.3025059 },
    { lat: 48.6346809, lng: 20.3030092 },
    { lat: 48.6348077, lng: 20.3036548 },
    { lat: 48.6347436, lng: 20.3039168 },
    { lat: 48.6348238, lng: 20.3042718 },
    { lat: 48.6345983, lng: 20.3053551 },
    { lat: 48.6344369, lng: 20.3056881 },
    { lat: 48.6344204, lng: 20.3062482 },
    { lat: 48.6343096, lng: 20.3063236 },
    { lat: 48.6340976, lng: 20.3072038 },
    { lat: 48.6338403, lng: 20.307968 },
    { lat: 48.6338223, lng: 20.3084232 },
    { lat: 48.6336222, lng: 20.3088925 },
    { lat: 48.633414, lng: 20.3092401 },
    { lat: 48.633263, lng: 20.3096405 },
    { lat: 48.63214, lng: 20.3131414 },
    { lat: 48.6319712, lng: 20.31329 },
    { lat: 48.6316614, lng: 20.3139189 },
    { lat: 48.6314529, lng: 20.3148962 },
    { lat: 48.631395, lng: 20.3154561 },
    { lat: 48.6309945, lng: 20.3170521 },
    { lat: 48.6307364, lng: 20.3172478 },
    { lat: 48.6298589, lng: 20.3175876 },
    { lat: 48.6295187, lng: 20.3179582 },
    { lat: 48.62859, lng: 20.3187162 },
    { lat: 48.6278921, lng: 20.3191834 },
    { lat: 48.627547, lng: 20.3195736 },
    { lat: 48.6272855, lng: 20.3203459 },
    { lat: 48.6270898, lng: 20.3213654 },
    { lat: 48.6269974, lng: 20.3221707 },
    { lat: 48.6269971, lng: 20.3226221 },
    { lat: 48.6270016, lng: 20.3230264 },
    { lat: 48.6272577, lng: 20.3241343 },
    { lat: 48.6272439, lng: 20.3242078 },
    { lat: 48.6273645, lng: 20.3244754 },
    { lat: 48.6273953, lng: 20.3247977 },
    { lat: 48.6273065, lng: 20.3273417 },
    { lat: 48.627251, lng: 20.3275324 },
    { lat: 48.6270099, lng: 20.330076 },
    { lat: 48.6261122, lng: 20.3326389 },
    { lat: 48.6259855, lng: 20.3335793 },
    { lat: 48.6285129, lng: 20.3394554 },
    { lat: 48.6280829, lng: 20.3404116 },
    { lat: 48.6273961, lng: 20.341283 },
    { lat: 48.6275778, lng: 20.3436427 },
    { lat: 48.6272376, lng: 20.3455745 },
    { lat: 48.6273616, lng: 20.3468294 },
    { lat: 48.6271223, lng: 20.3468708 },
    { lat: 48.6271168, lng: 20.3471903 },
    { lat: 48.6272108, lng: 20.348073 },
    { lat: 48.6273644, lng: 20.3489842 },
    { lat: 48.6274636, lng: 20.3502068 },
    { lat: 48.6277467, lng: 20.3509407 },
    { lat: 48.6280065, lng: 20.3518035 },
    { lat: 48.628196, lng: 20.3521862 },
    { lat: 48.6292935, lng: 20.3539187 },
    { lat: 48.6295706, lng: 20.3546197 },
    { lat: 48.6307571, lng: 20.3562309 },
    { lat: 48.6309424, lng: 20.3569148 },
    { lat: 48.6309872, lng: 20.3576624 },
    { lat: 48.6315897, lng: 20.3580731 },
    { lat: 48.6315635, lng: 20.358267 },
    { lat: 48.6320645, lng: 20.3587005 },
    { lat: 48.632136, lng: 20.3585721 },
    { lat: 48.6324346, lng: 20.3583727 },
    { lat: 48.633125, lng: 20.3584318 },
    { lat: 48.6334095, lng: 20.3583936 },
    { lat: 48.6337523, lng: 20.3585196 },
    { lat: 48.6341264, lng: 20.3585518 },
    { lat: 48.6344632, lng: 20.3583821 },
    { lat: 48.6348066, lng: 20.3584081 },
    { lat: 48.6354837, lng: 20.3582163 },
    { lat: 48.6357454, lng: 20.3582281 },
    { lat: 48.6359775, lng: 20.3581093 },
    { lat: 48.636512, lng: 20.3576374 },
    { lat: 48.6369671, lng: 20.3570928 },
    { lat: 48.6372102, lng: 20.3569093 },
    { lat: 48.6375268, lng: 20.3565385 },
    { lat: 48.6378319, lng: 20.3560441 },
    { lat: 48.6379418, lng: 20.3559756 },
    { lat: 48.6382822, lng: 20.3555165 },
    { lat: 48.6386174, lng: 20.3555364 },
    { lat: 48.6391475, lng: 20.3548341 },
    { lat: 48.6394791, lng: 20.3539165 },
    { lat: 48.6395454, lng: 20.3539405 },
    { lat: 48.6396354, lng: 20.3538532 },
    { lat: 48.6402788, lng: 20.3530219 },
    { lat: 48.6405505, lng: 20.3524467 },
    { lat: 48.6408175, lng: 20.352072 },
    { lat: 48.6409912, lng: 20.351555 },
    { lat: 48.6415599, lng: 20.3507171 },
    { lat: 48.6421003, lng: 20.3502801 },
    { lat: 48.6425336, lng: 20.3501542 },
    { lat: 48.643898, lng: 20.3494183 },
    { lat: 48.6443006, lng: 20.3492919 },
    { lat: 48.644861, lng: 20.3485839 },
    { lat: 48.6450967, lng: 20.3484445 },
    { lat: 48.6453693, lng: 20.3474923 },
    { lat: 48.6455259, lng: 20.3472046 },
    { lat: 48.6479035, lng: 20.3440549 },
    { lat: 48.6485378, lng: 20.3434535 },
    { lat: 48.6495051, lng: 20.3427015 },
    { lat: 48.6499116, lng: 20.3422927 },
    { lat: 48.6507181, lng: 20.3417515 },
    { lat: 48.6508439, lng: 20.3415637 },
    { lat: 48.6509186, lng: 20.3412875 },
    { lat: 48.6508343, lng: 20.3398126 },
    { lat: 48.6512583, lng: 20.3381507 },
    { lat: 48.651608, lng: 20.3375616 },
    { lat: 48.6522106, lng: 20.3361697 },
    { lat: 48.6522675, lng: 20.3357223 },
    { lat: 48.6529044, lng: 20.3343833 },
    { lat: 48.6530343, lng: 20.3337862 },
    { lat: 48.6530009, lng: 20.3324083 },
    { lat: 48.652939, lng: 20.3319173 },
    { lat: 48.6529779, lng: 20.3296529 },
    { lat: 48.6528778, lng: 20.3290204 },
    { lat: 48.6526708, lng: 20.3284443 },
    { lat: 48.6515478, lng: 20.3273748 },
    { lat: 48.6511899, lng: 20.3265729 },
    { lat: 48.6512509, lng: 20.3257423 },
    { lat: 48.6512436, lng: 20.323996 },
    { lat: 48.6511757, lng: 20.3236701 },
    { lat: 48.6511523, lng: 20.3232156 },
    { lat: 48.6510087, lng: 20.3225053 },
    { lat: 48.6508364, lng: 20.3221015 },
    { lat: 48.6507527, lng: 20.3217752 },
    { lat: 48.6507812, lng: 20.321611 },
    { lat: 48.6507524, lng: 20.3213329 },
    { lat: 48.6509155, lng: 20.3208995 },
    { lat: 48.6514273, lng: 20.3202535 },
    { lat: 48.653089, lng: 20.3175565 },
    { lat: 48.6533174, lng: 20.3171533 },
    { lat: 48.6538518, lng: 20.3159546 },
    { lat: 48.6540284, lng: 20.3152343 },
    { lat: 48.6542238, lng: 20.3146727 },
    { lat: 48.6545151, lng: 20.3131083 },
    { lat: 48.654507, lng: 20.3124662 },
    { lat: 48.6547305, lng: 20.3117321 },
    { lat: 48.6548948, lng: 20.3109848 },
    { lat: 48.6548266, lng: 20.3104181 },
    { lat: 48.6548736, lng: 20.3097201 },
    { lat: 48.6548082, lng: 20.3092625 },
    { lat: 48.6547759, lng: 20.3085224 },
    { lat: 48.6553303, lng: 20.3077819 },
    { lat: 48.6559474, lng: 20.3073254 },
    { lat: 48.6560912, lng: 20.3072852 },
    { lat: 48.6563472, lng: 20.3069221 },
    { lat: 48.6567684, lng: 20.3065793 },
    { lat: 48.6570767, lng: 20.3062269 },
    { lat: 48.6572067, lng: 20.3058049 },
    { lat: 48.6572103, lng: 20.3054684 },
    { lat: 48.6574246, lng: 20.3047888 },
    { lat: 48.6577164, lng: 20.30407 },
    { lat: 48.6578903, lng: 20.3030766 },
    { lat: 48.658018, lng: 20.302389 },
    { lat: 48.657861, lng: 20.302295 },
    { lat: 48.657712, lng: 20.302212 },
    { lat: 48.65755, lng: 20.301909 },
    { lat: 48.657457, lng: 20.301665 },
    { lat: 48.657305, lng: 20.301268 },
    { lat: 48.657195, lng: 20.300956 },
    { lat: 48.656936, lng: 20.300623 },
    { lat: 48.656728, lng: 20.300352 },
    { lat: 48.656542, lng: 20.300153 },
    { lat: 48.656337, lng: 20.299934 },
    { lat: 48.656091, lng: 20.299788 },
    { lat: 48.656035, lng: 20.299737 },
    { lat: 48.655878, lng: 20.299595 },
    { lat: 48.655648, lng: 20.299418 },
    { lat: 48.655425, lng: 20.299253 },
    { lat: 48.655205, lng: 20.298997 },
    { lat: 48.654933, lng: 20.298872 },
    { lat: 48.65478, lng: 20.298783 },
    { lat: 48.654559, lng: 20.298659 },
    { lat: 48.654437, lng: 20.298594 },
    { lat: 48.654277, lng: 20.298538 },
    { lat: 48.65403, lng: 20.298449 },
    { lat: 48.653959, lng: 20.29843 },
    { lat: 48.653833, lng: 20.298395 },
    { lat: 48.653729, lng: 20.298367 },
    { lat: 48.653163, lng: 20.298211 },
    { lat: 48.653104, lng: 20.29817 },
    { lat: 48.652809, lng: 20.297966 },
    { lat: 48.652473, lng: 20.29798 },
    { lat: 48.65185, lng: 20.298004 },
    { lat: 48.651188, lng: 20.298022 },
    { lat: 48.65107, lng: 20.297916 },
    { lat: 48.650756, lng: 20.297647 },
    { lat: 48.650453, lng: 20.297422 },
    { lat: 48.650264, lng: 20.297339 },
    { lat: 48.650004, lng: 20.297226 },
    { lat: 48.649745, lng: 20.29705 },
    { lat: 48.649657, lng: 20.296993 },
    { lat: 48.649587, lng: 20.296947 },
    { lat: 48.649572, lng: 20.296926 },
    { lat: 48.649558, lng: 20.296825 },
    { lat: 48.649193, lng: 20.296176 },
    { lat: 48.649147, lng: 20.29616 },
    { lat: 48.649151, lng: 20.29614 },
    { lat: 48.649073, lng: 20.296119 },
    { lat: 48.649044, lng: 20.296112 },
    { lat: 48.648976, lng: 20.296093 },
    { lat: 48.64895, lng: 20.296088 },
    { lat: 48.648908, lng: 20.29608 },
    { lat: 48.648826, lng: 20.296065 },
    { lat: 48.648652, lng: 20.295986 },
    { lat: 48.64853, lng: 20.295912 },
    { lat: 48.64838, lng: 20.295799 },
    { lat: 48.64829, lng: 20.295717 },
    { lat: 48.648175, lng: 20.295634 },
    { lat: 48.648011, lng: 20.295513 },
    { lat: 48.647968, lng: 20.295483 },
    { lat: 48.647779, lng: 20.29538 },
    { lat: 48.647616, lng: 20.295285 },
    { lat: 48.647545, lng: 20.295243 },
    { lat: 48.647331, lng: 20.29507 },
    { lat: 48.647175, lng: 20.29495 },
    { lat: 48.647024, lng: 20.294884 },
    { lat: 48.646858, lng: 20.294815 },
    { lat: 48.646832, lng: 20.294803 },
    { lat: 48.646711, lng: 20.294747 },
    { lat: 48.64662, lng: 20.294655 },
    { lat: 48.646459, lng: 20.294494 },
    { lat: 48.646191, lng: 20.29437 },
    { lat: 48.64601, lng: 20.294288 },
    { lat: 48.645863, lng: 20.294225 },
    { lat: 48.645705, lng: 20.294159 },
    { lat: 48.645416, lng: 20.294108 },
    { lat: 48.645229, lng: 20.294023 },
    { lat: 48.644927, lng: 20.29387 },
    { lat: 48.64485, lng: 20.293828 },
    { lat: 48.644672, lng: 20.293654 },
    { lat: 48.644542, lng: 20.29354 },
    { lat: 48.644394, lng: 20.293489 },
    { lat: 48.644254, lng: 20.293445 },
    { lat: 48.644101, lng: 20.293431 },
    { lat: 48.643859, lng: 20.293424 },
    { lat: 48.64376, lng: 20.293426 },
    { lat: 48.643569, lng: 20.293517 },
    { lat: 48.64339, lng: 20.293619 },
    { lat: 48.643137, lng: 20.293749 },
    { lat: 48.64299, lng: 20.293828 },
    { lat: 48.642756, lng: 20.293976 },
    { lat: 48.642578, lng: 20.294141 },
    { lat: 48.642446, lng: 20.294261 },
    { lat: 48.642204, lng: 20.294374 },
    { lat: 48.642083, lng: 20.294429 },
    { lat: 48.641844, lng: 20.294681 },
    { lat: 48.641702, lng: 20.294846 },
    { lat: 48.64153, lng: 20.294978 },
    { lat: 48.641298, lng: 20.295082 },
    { lat: 48.641066, lng: 20.29519 },
    { lat: 48.640783, lng: 20.295318 },
    { lat: 48.640565, lng: 20.295356 },
    { lat: 48.640371, lng: 20.295317 },
    { lat: 48.64026, lng: 20.295241 },
    { lat: 48.640147, lng: 20.295175 },
    { lat: 48.640064, lng: 20.295133 },
    { lat: 48.639894, lng: 20.295065 },
    { lat: 48.639862, lng: 20.295053 },
    { lat: 48.639703, lng: 20.295009 },
    { lat: 48.639626, lng: 20.294981 },
    { lat: 48.639553, lng: 20.294959 },
    { lat: 48.639489, lng: 20.294933 },
    { lat: 48.639433, lng: 20.294914 },
    { lat: 48.639133, lng: 20.294886 },
    { lat: 48.63904, lng: 20.295004 },
    { lat: 48.638982, lng: 20.295082 },
    { lat: 48.638934, lng: 20.295151 },
    { lat: 48.638847, lng: 20.295235 },
    { lat: 48.638724, lng: 20.295364 },
    { lat: 48.6386, lng: 20.295488 },
    { lat: 48.638326, lng: 20.295617 },
    { lat: 48.638215, lng: 20.295729 },
    { lat: 48.63815, lng: 20.295804 },
    { lat: 48.638083, lng: 20.295944 },
    { lat: 48.638037, lng: 20.296028 },
    { lat: 48.63798, lng: 20.296067 },
    { lat: 48.637876, lng: 20.296151 },
    { lat: 48.63773, lng: 20.296262 },
    { lat: 48.637647, lng: 20.296319 },
    { lat: 48.637551, lng: 20.29638 },
    { lat: 48.637491, lng: 20.296421 },
    { lat: 48.6374, lng: 20.296535 },
    { lat: 48.63732, lng: 20.296639 },
    { lat: 48.637316, lng: 20.29669 },
  ],
  Roštár: [
    { lat: 48.6825043, lng: 20.3469477 },
    { lat: 48.6823885, lng: 20.3471565 },
    { lat: 48.682041, lng: 20.3477489 },
    { lat: 48.6817222, lng: 20.3486184 },
    { lat: 48.6819727, lng: 20.3490663 },
    { lat: 48.6822859, lng: 20.3498511 },
    { lat: 48.6827091, lng: 20.3511814 },
    { lat: 48.682639, lng: 20.3514353 },
    { lat: 48.6824038, lng: 20.3517287 },
    { lat: 48.6822439, lng: 20.3516167 },
    { lat: 48.6821015, lng: 20.3516617 },
    { lat: 48.6817962, lng: 20.3520665 },
    { lat: 48.6818568, lng: 20.3525522 },
    { lat: 48.6820072, lng: 20.3528026 },
    { lat: 48.6819681, lng: 20.3528342 },
    { lat: 48.6822857, lng: 20.3537419 },
    { lat: 48.6824099, lng: 20.3539205 },
    { lat: 48.6827456, lng: 20.3541296 },
    { lat: 48.6829749, lng: 20.3541471 },
    { lat: 48.6832249, lng: 20.354496 },
    { lat: 48.6834792, lng: 20.3545805 },
    { lat: 48.6835755, lng: 20.3547933 },
    { lat: 48.6834876, lng: 20.3549122 },
    { lat: 48.683535, lng: 20.3553514 },
    { lat: 48.6837673, lng: 20.3557685 },
    { lat: 48.6839411, lng: 20.3558917 },
    { lat: 48.683903, lng: 20.3564197 },
    { lat: 48.6839996, lng: 20.3568181 },
    { lat: 48.6847553, lng: 20.3579845 },
    { lat: 48.6849534, lng: 20.3581897 },
    { lat: 48.6851078, lng: 20.3585371 },
    { lat: 48.6854255, lng: 20.3588326 },
    { lat: 48.6856107, lng: 20.3592222 },
    { lat: 48.6857738, lng: 20.3593375 },
    { lat: 48.685801, lng: 20.3595422 },
    { lat: 48.6859339, lng: 20.3596883 },
    { lat: 48.6859529, lng: 20.3601496 },
    { lat: 48.6861099, lng: 20.3606026 },
    { lat: 48.6863293, lng: 20.3606574 },
    { lat: 48.686393, lng: 20.3610463 },
    { lat: 48.6865901, lng: 20.361139 },
    { lat: 48.6865941, lng: 20.3614127 },
    { lat: 48.6867471, lng: 20.3616715 },
    { lat: 48.6867387, lng: 20.3620401 },
    { lat: 48.6870973, lng: 20.362605 },
    { lat: 48.6871672, lng: 20.3625778 },
    { lat: 48.6875565, lng: 20.3628625 },
    { lat: 48.6881203, lng: 20.363028 },
    { lat: 48.6882438, lng: 20.3629576 },
    { lat: 48.6883787, lng: 20.3630113 },
    { lat: 48.6888361, lng: 20.3636524 },
    { lat: 48.6889814, lng: 20.3636267 },
    { lat: 48.6891076, lng: 20.3638893 },
    { lat: 48.6897963, lng: 20.3645207 },
    { lat: 48.6898232, lng: 20.3648933 },
    { lat: 48.6899629, lng: 20.3651016 },
    { lat: 48.6900118, lng: 20.3658703 },
    { lat: 48.6899712, lng: 20.3661569 },
    { lat: 48.6899611, lng: 20.367062 },
    { lat: 48.6900065, lng: 20.36751 },
    { lat: 48.6899712, lng: 20.3678809 },
    { lat: 48.6903105, lng: 20.3683867 },
    { lat: 48.6903745, lng: 20.3685842 },
    { lat: 48.6903354, lng: 20.3688232 },
    { lat: 48.6903673, lng: 20.369215 },
    { lat: 48.6901107, lng: 20.369892 },
    { lat: 48.6901002, lng: 20.3702318 },
    { lat: 48.6899446, lng: 20.3704594 },
    { lat: 48.6897937, lng: 20.3711003 },
    { lat: 48.6898216, lng: 20.3719083 },
    { lat: 48.6896167, lng: 20.3730797 },
    { lat: 48.689085, lng: 20.374271 },
    { lat: 48.6887255, lng: 20.3753992 },
    { lat: 48.6889009, lng: 20.3758899 },
    { lat: 48.6891831, lng: 20.3782712 },
    { lat: 48.6894302, lng: 20.3795154 },
    { lat: 48.6897112, lng: 20.3813697 },
    { lat: 48.6897246, lng: 20.3822767 },
    { lat: 48.689653, lng: 20.3828742 },
    { lat: 48.6897551, lng: 20.3835047 },
    { lat: 48.6901234, lng: 20.3848498 },
    { lat: 48.690082, lng: 20.3856821 },
    { lat: 48.6901726, lng: 20.3868895 },
    { lat: 48.6902771, lng: 20.3874417 },
    { lat: 48.6908192, lng: 20.388396 },
    { lat: 48.6909582, lng: 20.3887267 },
    { lat: 48.6910329, lng: 20.3892092 },
    { lat: 48.6909844, lng: 20.3895869 },
    { lat: 48.6910184, lng: 20.3896182 },
    { lat: 48.6910535, lng: 20.390149 },
    { lat: 48.6911313, lng: 20.3929358 },
    { lat: 48.6914742, lng: 20.3942452 },
    { lat: 48.6919028, lng: 20.3954351 },
    { lat: 48.6921393, lng: 20.3959326 },
    { lat: 48.6921765, lng: 20.3963189 },
    { lat: 48.69205, lng: 20.3987712 },
    { lat: 48.6924343, lng: 20.3982831 },
    { lat: 48.6930235, lng: 20.3979385 },
    { lat: 48.693652, lng: 20.3977944 },
    { lat: 48.6942468, lng: 20.3973634 },
    { lat: 48.6959131, lng: 20.3964448 },
    { lat: 48.6963034, lng: 20.3961689 },
    { lat: 48.6966274, lng: 20.3960404 },
    { lat: 48.6972238, lng: 20.3949477 },
    { lat: 48.6984207, lng: 20.3935064 },
    { lat: 48.6987389, lng: 20.3932561 },
    { lat: 48.699195, lng: 20.3927335 },
    { lat: 48.6995168, lng: 20.3921367 },
    { lat: 48.7003132, lng: 20.3904185 },
    { lat: 48.7004484, lng: 20.3899507 },
    { lat: 48.7006967, lng: 20.3894377 },
    { lat: 48.7007706, lng: 20.3890744 },
    { lat: 48.7009014, lng: 20.3888137 },
    { lat: 48.7015257, lng: 20.3880378 },
    { lat: 48.7017876, lng: 20.3877931 },
    { lat: 48.7028312, lng: 20.3880476 },
    { lat: 48.7031589, lng: 20.3880153 },
    { lat: 48.7034892, lng: 20.3882052 },
    { lat: 48.7058788, lng: 20.3834057 },
    { lat: 48.7060581, lng: 20.3828833 },
    { lat: 48.7066426, lng: 20.3805495 },
    { lat: 48.7071895, lng: 20.3793092 },
    { lat: 48.7072598, lng: 20.3760767 },
    { lat: 48.7074479, lng: 20.375525 },
    { lat: 48.7075816, lng: 20.3739994 },
    { lat: 48.7074935, lng: 20.3725589 },
    { lat: 48.7075496, lng: 20.3717699 },
    { lat: 48.7080799, lng: 20.3701573 },
    { lat: 48.7079312, lng: 20.3689892 },
    { lat: 48.7072793, lng: 20.3658738 },
    { lat: 48.7073611, lng: 20.3654805 },
    { lat: 48.707022, lng: 20.3650715 },
    { lat: 48.7066276, lng: 20.3641051 },
    { lat: 48.7067545, lng: 20.3630479 },
    { lat: 48.7067027, lng: 20.3617076 },
    { lat: 48.7068048, lng: 20.3614373 },
    { lat: 48.7068146, lng: 20.3607878 },
    { lat: 48.7066302, lng: 20.360413 },
    { lat: 48.7077827, lng: 20.35615 },
    { lat: 48.7076719, lng: 20.356019 },
    { lat: 48.7078811, lng: 20.3555773 },
    { lat: 48.7082391, lng: 20.3548229 },
    { lat: 48.7074997, lng: 20.3537556 },
    { lat: 48.7068593, lng: 20.3528053 },
    { lat: 48.7066093, lng: 20.3520541 },
    { lat: 48.7064272, lng: 20.3516545 },
    { lat: 48.7059017, lng: 20.3497429 },
    { lat: 48.7052424, lng: 20.3484825 },
    { lat: 48.7049336, lng: 20.3481872 },
    { lat: 48.7040822, lng: 20.3464711 },
    { lat: 48.7032631, lng: 20.3453892 },
    { lat: 48.7030759, lng: 20.3450589 },
    { lat: 48.7029592, lng: 20.3445213 },
    { lat: 48.7019963, lng: 20.3424569 },
    { lat: 48.7016857, lng: 20.3426085 },
    { lat: 48.7014322, lng: 20.3424616 },
    { lat: 48.7012804, lng: 20.3427257 },
    { lat: 48.7011107, lng: 20.3427675 },
    { lat: 48.700939, lng: 20.3430119 },
    { lat: 48.7007133, lng: 20.343552 },
    { lat: 48.7005553, lng: 20.3435411 },
    { lat: 48.7004446, lng: 20.3438575 },
    { lat: 48.7002404, lng: 20.3440185 },
    { lat: 48.6995255, lng: 20.3448658 },
    { lat: 48.6991347, lng: 20.3450662 },
    { lat: 48.6991793, lng: 20.3453157 },
    { lat: 48.6982485, lng: 20.3459699 },
    { lat: 48.6979201, lng: 20.3460628 },
    { lat: 48.697672, lng: 20.345215 },
    { lat: 48.6975088, lng: 20.344955 },
    { lat: 48.6976617, lng: 20.3447179 },
    { lat: 48.6981614, lng: 20.3444476 },
    { lat: 48.6980804, lng: 20.3440467 },
    { lat: 48.698717, lng: 20.3436184 },
    { lat: 48.6991328, lng: 20.342957 },
    { lat: 48.6991977, lng: 20.3427991 },
    { lat: 48.6991161, lng: 20.3423275 },
    { lat: 48.6996452, lng: 20.3420614 },
    { lat: 48.6996647, lng: 20.342126 },
    { lat: 48.7001316, lng: 20.3419099 },
    { lat: 48.7011879, lng: 20.3408669 },
    { lat: 48.7016968, lng: 20.3407262 },
    { lat: 48.7016271, lng: 20.3403759 },
    { lat: 48.7017197, lng: 20.3400383 },
    { lat: 48.7021615, lng: 20.3398169 },
    { lat: 48.7018213, lng: 20.3387968 },
    { lat: 48.7017557, lng: 20.3378928 },
    { lat: 48.7016069, lng: 20.3369609 },
    { lat: 48.7012106, lng: 20.3352542 },
    { lat: 48.7008681, lng: 20.3343235 },
    { lat: 48.7006835, lng: 20.333254 },
    { lat: 48.7005824, lng: 20.3329436 },
    { lat: 48.7004125, lng: 20.3326278 },
    { lat: 48.7001957, lng: 20.3325869 },
    { lat: 48.7000831, lng: 20.3323858 },
    { lat: 48.6998826, lng: 20.3317145 },
    { lat: 48.6997102, lng: 20.3315113 },
    { lat: 48.699625, lng: 20.3312946 },
    { lat: 48.6994053, lng: 20.3311113 },
    { lat: 48.6993094, lng: 20.3309341 },
    { lat: 48.6993096, lng: 20.3298732 },
    { lat: 48.6992452, lng: 20.3293777 },
    { lat: 48.6993085, lng: 20.3290316 },
    { lat: 48.699261, lng: 20.3285642 },
    { lat: 48.6992967, lng: 20.328355 },
    { lat: 48.6987819, lng: 20.3262133 },
    { lat: 48.6986173, lng: 20.3250234 },
    { lat: 48.6979392, lng: 20.3259461 },
    { lat: 48.697189, lng: 20.3269504 },
    { lat: 48.6972753, lng: 20.3287062 },
    { lat: 48.6974989, lng: 20.3286599 },
    { lat: 48.6976029, lng: 20.3293365 },
    { lat: 48.6978832, lng: 20.3304627 },
    { lat: 48.6974903, lng: 20.3306933 },
    { lat: 48.6972589, lng: 20.330592 },
    { lat: 48.6969807, lng: 20.3314034 },
    { lat: 48.6967752, lng: 20.332284 },
    { lat: 48.6965357, lng: 20.3327277 },
    { lat: 48.6965514, lng: 20.3329858 },
    { lat: 48.6963124, lng: 20.3335314 },
    { lat: 48.6958941, lng: 20.3341211 },
    { lat: 48.6958172, lng: 20.3344615 },
    { lat: 48.6952834, lng: 20.3346373 },
    { lat: 48.6942019, lng: 20.3353214 },
    { lat: 48.6929986, lng: 20.3367225 },
    { lat: 48.6925331, lng: 20.3378406 },
    { lat: 48.6923084, lng: 20.3385175 },
    { lat: 48.6918704, lng: 20.339508 },
    { lat: 48.691617, lng: 20.3395326 },
    { lat: 48.6910725, lng: 20.3393175 },
    { lat: 48.6909267, lng: 20.339421 },
    { lat: 48.6906913, lng: 20.3394194 },
    { lat: 48.6903058, lng: 20.3396692 },
    { lat: 48.6902269, lng: 20.3398427 },
    { lat: 48.6900696, lng: 20.3397369 },
    { lat: 48.6899647, lng: 20.3397565 },
    { lat: 48.6898096, lng: 20.3401434 },
    { lat: 48.6897178, lng: 20.3401854 },
    { lat: 48.6894453, lng: 20.3402424 },
    { lat: 48.6893362, lng: 20.3401002 },
    { lat: 48.6884359, lng: 20.3410778 },
    { lat: 48.6870276, lng: 20.3422437 },
    { lat: 48.6844283, lng: 20.3441256 },
    { lat: 48.6841419, lng: 20.3444923 },
    { lat: 48.6843819, lng: 20.3451047 },
    { lat: 48.683185, lng: 20.3462121 },
    { lat: 48.6825043, lng: 20.3469477 },
  ],
  Henckovce: [
    { lat: 48.7336218, lng: 20.4465996 },
    { lat: 48.7327642, lng: 20.4447414 },
    { lat: 48.7325738, lng: 20.4442635 },
    { lat: 48.7323157, lng: 20.4438411 },
    { lat: 48.7316496, lng: 20.4431145 },
    { lat: 48.7314373, lng: 20.442443 },
    { lat: 48.731315, lng: 20.4423363 },
    { lat: 48.731073, lng: 20.4422756 },
    { lat: 48.7307636, lng: 20.4413679 },
    { lat: 48.730087, lng: 20.439828 },
    { lat: 48.7292904, lng: 20.4383324 },
    { lat: 48.7288052, lng: 20.4372353 },
    { lat: 48.7280385, lng: 20.4351351 },
    { lat: 48.7263161, lng: 20.4336662 },
    { lat: 48.72585, lng: 20.4330505 },
    { lat: 48.7255472, lng: 20.432963 },
    { lat: 48.7252982, lng: 20.4327817 },
    { lat: 48.7251568, lng: 20.432202 },
    { lat: 48.7249299, lng: 20.431981 },
    { lat: 48.7247526, lng: 20.4319037 },
    { lat: 48.724508, lng: 20.4316346 },
    { lat: 48.7243966, lng: 20.4312495 },
    { lat: 48.7222801, lng: 20.43138 },
    { lat: 48.7219094, lng: 20.4304565 },
    { lat: 48.7217512, lng: 20.4305879 },
    { lat: 48.7214262, lng: 20.4304264 },
    { lat: 48.7204236, lng: 20.4296438 },
    { lat: 48.7197358, lng: 20.4289059 },
    { lat: 48.7196111, lng: 20.428589 },
    { lat: 48.7200197, lng: 20.4282322 },
    { lat: 48.7192733, lng: 20.4274384 },
    { lat: 48.7184048, lng: 20.4273413 },
    { lat: 48.7176459, lng: 20.4251833 },
    { lat: 48.7171875, lng: 20.4255933 },
    { lat: 48.7168111, lng: 20.4257049 },
    { lat: 48.7167564, lng: 20.4256551 },
    { lat: 48.7166276, lng: 20.4248775 },
    { lat: 48.7163945, lng: 20.4243444 },
    { lat: 48.7160649, lng: 20.4232944 },
    { lat: 48.7154184, lng: 20.422138 },
    { lat: 48.7152183, lng: 20.4222061 },
    { lat: 48.7152306, lng: 20.4221126 },
    { lat: 48.7151557, lng: 20.4219181 },
    { lat: 48.7148825, lng: 20.4216035 },
    { lat: 48.7145256, lng: 20.4205441 },
    { lat: 48.7146011, lng: 20.420432 },
    { lat: 48.7145459, lng: 20.4201045 },
    { lat: 48.7144754, lng: 20.4201185 },
    { lat: 48.7143804, lng: 20.4199595 },
    { lat: 48.7142394, lng: 20.4200357 },
    { lat: 48.7141422, lng: 20.4199457 },
    { lat: 48.7141009, lng: 20.4196288 },
    { lat: 48.7138849, lng: 20.419337 },
    { lat: 48.7136392, lng: 20.4188592 },
    { lat: 48.7135937, lng: 20.4183779 },
    { lat: 48.7136377, lng: 20.4178742 },
    { lat: 48.7136036, lng: 20.4172774 },
    { lat: 48.7135597, lng: 20.4170106 },
    { lat: 48.7135038, lng: 20.4169609 },
    { lat: 48.7134105, lng: 20.4166118 },
    { lat: 48.7134135, lng: 20.4164867 },
    { lat: 48.7135001, lng: 20.4163076 },
    { lat: 48.713221, lng: 20.4161052 },
    { lat: 48.7130976, lng: 20.4158214 },
    { lat: 48.7122306, lng: 20.415467 },
    { lat: 48.7110014, lng: 20.4155076 },
    { lat: 48.7105199, lng: 20.4156729 },
    { lat: 48.7103984, lng: 20.4155869 },
    { lat: 48.7100363, lng: 20.4155083 },
    { lat: 48.7092778, lng: 20.4156354 },
    { lat: 48.7089807, lng: 20.4158189 },
    { lat: 48.7087303, lng: 20.4158065 },
    { lat: 48.7082353, lng: 20.415906 },
    { lat: 48.7073717, lng: 20.4163207 },
    { lat: 48.707075, lng: 20.41625 },
    { lat: 48.7060401, lng: 20.4180486 },
    { lat: 48.7056321, lng: 20.4175961 },
    { lat: 48.705287, lng: 20.417551 },
    { lat: 48.7047888, lng: 20.4168301 },
    { lat: 48.7041413, lng: 20.4156199 },
    { lat: 48.7039611, lng: 20.4151219 },
    { lat: 48.7035208, lng: 20.4143803 },
    { lat: 48.7028965, lng: 20.4136918 },
    { lat: 48.7011287, lng: 20.4127704 },
    { lat: 48.7000643, lng: 20.4122157 },
    { lat: 48.699952, lng: 20.4116626 },
    { lat: 48.6998599, lng: 20.4115794 },
    { lat: 48.6993642, lng: 20.4114893 },
    { lat: 48.6989736, lng: 20.4116104 },
    { lat: 48.6987522, lng: 20.4115905 },
    { lat: 48.698469, lng: 20.4114288 },
    { lat: 48.6975858, lng: 20.4103859 },
    { lat: 48.6970996, lng: 20.4101859 },
    { lat: 48.69655, lng: 20.4097705 },
    { lat: 48.6964021, lng: 20.4095264 },
    { lat: 48.6955331, lng: 20.4087996 },
    { lat: 48.695008, lng: 20.4076738 },
    { lat: 48.6919102, lng: 20.4054976 },
    { lat: 48.691884, lng: 20.4055152 },
    { lat: 48.6912753, lng: 20.4056162 },
    { lat: 48.6907659, lng: 20.4061354 },
    { lat: 48.6898995, lng: 20.407888 },
    { lat: 48.6898964, lng: 20.4099825 },
    { lat: 48.6898016, lng: 20.4113664 },
    { lat: 48.6899457, lng: 20.4123938 },
    { lat: 48.690372, lng: 20.4137366 },
    { lat: 48.6903836, lng: 20.4139798 },
    { lat: 48.6906552, lng: 20.4148977 },
    { lat: 48.6906058, lng: 20.4172857 },
    { lat: 48.6906812, lng: 20.4183209 },
    { lat: 48.6906502, lng: 20.4195428 },
    { lat: 48.6905615, lng: 20.4203457 },
    { lat: 48.6902801, lng: 20.4215299 },
    { lat: 48.6899631, lng: 20.4225469 },
    { lat: 48.6897725, lng: 20.4236316 },
    { lat: 48.6896507, lng: 20.4240706 },
    { lat: 48.6896266, lng: 20.4245028 },
    { lat: 48.6889434, lng: 20.4258353 },
    { lat: 48.6886272, lng: 20.4263122 },
    { lat: 48.6883962, lng: 20.42693 },
    { lat: 48.6891055, lng: 20.4284043 },
    { lat: 48.6892961, lng: 20.430391 },
    { lat: 48.688915, lng: 20.4316793 },
    { lat: 48.687135, lng: 20.4335791 },
    { lat: 48.6864873, lng: 20.4344653 },
    { lat: 48.686481, lng: 20.4344741 },
    { lat: 48.6868954, lng: 20.4352424 },
    { lat: 48.6870354, lng: 20.4356351 },
    { lat: 48.6875985, lng: 20.4362905 },
    { lat: 48.6879109, lng: 20.4369277 },
    { lat: 48.6880903, lng: 20.437111 },
    { lat: 48.688013, lng: 20.4377773 },
    { lat: 48.6882779, lng: 20.4381959 },
    { lat: 48.6885096, lng: 20.4387792 },
    { lat: 48.6890125, lng: 20.4396319 },
    { lat: 48.6892705, lng: 20.4399328 },
    { lat: 48.6897131, lng: 20.4399935 },
    { lat: 48.6898128, lng: 20.4400643 },
    { lat: 48.6899991, lng: 20.4405218 },
    { lat: 48.690284, lng: 20.4407056 },
    { lat: 48.6904724, lng: 20.4406814 },
    { lat: 48.6905986, lng: 20.4408066 },
    { lat: 48.690869, lng: 20.4406887 },
    { lat: 48.6917268, lng: 20.4407712 },
    { lat: 48.6920255, lng: 20.440689 },
    { lat: 48.6933821, lng: 20.4410087 },
    { lat: 48.6943094, lng: 20.4417346 },
    { lat: 48.6948289, lng: 20.4420135 },
    { lat: 48.6952618, lng: 20.4419411 },
    { lat: 48.6953678, lng: 20.4417954 },
    { lat: 48.6955666, lng: 20.4417796 },
    { lat: 48.6958625, lng: 20.4423371 },
    { lat: 48.6959073, lng: 20.4425428 },
    { lat: 48.696021, lng: 20.4426851 },
    { lat: 48.6960631, lng: 20.4433822 },
    { lat: 48.6965529, lng: 20.4446572 },
    { lat: 48.6968962, lng: 20.4450345 },
    { lat: 48.6971797, lng: 20.4455043 },
    { lat: 48.6974938, lng: 20.4455616 },
    { lat: 48.697649, lng: 20.4457089 },
    { lat: 48.6979048, lng: 20.4457403 },
    { lat: 48.6985131, lng: 20.4460444 },
    { lat: 48.698742, lng: 20.4459351 },
    { lat: 48.6990231, lng: 20.4460409 },
    { lat: 48.6991944, lng: 20.4459551 },
    { lat: 48.6995925, lng: 20.4461227 },
    { lat: 48.6998393, lng: 20.4463356 },
    { lat: 48.7003538, lng: 20.4462447 },
    { lat: 48.7005767, lng: 20.4463059 },
    { lat: 48.7006395, lng: 20.4464128 },
    { lat: 48.7008119, lng: 20.4465101 },
    { lat: 48.7009, lng: 20.4464562 },
    { lat: 48.7011071, lng: 20.446573 },
    { lat: 48.7011884, lng: 20.4464875 },
    { lat: 48.7013785, lng: 20.446691 },
    { lat: 48.7014847, lng: 20.4466672 },
    { lat: 48.7015919, lng: 20.4468248 },
    { lat: 48.7017066, lng: 20.4467617 },
    { lat: 48.7019198, lng: 20.4470388 },
    { lat: 48.7021167, lng: 20.4470367 },
    { lat: 48.7021379, lng: 20.4471482 },
    { lat: 48.7022572, lng: 20.4472641 },
    { lat: 48.7026499, lng: 20.4474953 },
    { lat: 48.7026812, lng: 20.4476976 },
    { lat: 48.702755, lng: 20.447653 },
    { lat: 48.7028293, lng: 20.4477397 },
    { lat: 48.7029078, lng: 20.4476452 },
    { lat: 48.703553, lng: 20.4481201 },
    { lat: 48.7036062, lng: 20.4482164 },
    { lat: 48.7036498, lng: 20.448177 },
    { lat: 48.7037124, lng: 20.4482546 },
    { lat: 48.7037679, lng: 20.4482049 },
    { lat: 48.703839, lng: 20.4482711 },
    { lat: 48.703872, lng: 20.4484258 },
    { lat: 48.7041413, lng: 20.4486692 },
    { lat: 48.70422, lng: 20.4486681 },
    { lat: 48.7045266, lng: 20.4490294 },
    { lat: 48.7042292, lng: 20.4493517 },
    { lat: 48.7044741, lng: 20.4497238 },
    { lat: 48.7049941, lng: 20.4501347 },
    { lat: 48.7052734, lng: 20.4501626 },
    { lat: 48.7055058, lng: 20.4502922 },
    { lat: 48.7060423, lng: 20.4503421 },
    { lat: 48.7061614, lng: 20.4496188 },
    { lat: 48.7066742, lng: 20.4497202 },
    { lat: 48.7067838, lng: 20.4500576 },
    { lat: 48.7068105, lng: 20.4504025 },
    { lat: 48.7076878, lng: 20.4501088 },
    { lat: 48.7076407, lng: 20.4495207 },
    { lat: 48.7078895, lng: 20.4493427 },
    { lat: 48.7081455, lng: 20.449262 },
    { lat: 48.7082493, lng: 20.4493864 },
    { lat: 48.7084055, lng: 20.4492927 },
    { lat: 48.7086844, lng: 20.4493106 },
    { lat: 48.7087075, lng: 20.4495664 },
    { lat: 48.7088879, lng: 20.4494206 },
    { lat: 48.7089822, lng: 20.4495358 },
    { lat: 48.709142, lng: 20.4495327 },
    { lat: 48.7092108, lng: 20.4494718 },
    { lat: 48.7093138, lng: 20.4494875 },
    { lat: 48.7093928, lng: 20.4493766 },
    { lat: 48.7094704, lng: 20.4494301 },
    { lat: 48.7094856, lng: 20.4495942 },
    { lat: 48.7097177, lng: 20.4495125 },
    { lat: 48.7098299, lng: 20.4496211 },
    { lat: 48.7099688, lng: 20.4494811 },
    { lat: 48.7100533, lng: 20.4495693 },
    { lat: 48.71015, lng: 20.4495699 },
    { lat: 48.7103482, lng: 20.4498362 },
    { lat: 48.7105682, lng: 20.4496963 },
    { lat: 48.7105711, lng: 20.4495677 },
    { lat: 48.7106788, lng: 20.4494072 },
    { lat: 48.7109119, lng: 20.4494376 },
    { lat: 48.7111498, lng: 20.4496253 },
    { lat: 48.7112321, lng: 20.4495069 },
    { lat: 48.7113293, lng: 20.4495177 },
    { lat: 48.7122038, lng: 20.4498325 },
    { lat: 48.7123142, lng: 20.450105 },
    { lat: 48.7125156, lng: 20.4501581 },
    { lat: 48.7126946, lng: 20.4500054 },
    { lat: 48.7127695, lng: 20.4498174 },
    { lat: 48.7129494, lng: 20.4498988 },
    { lat: 48.7131281, lng: 20.4497389 },
    { lat: 48.7133972, lng: 20.4493252 },
    { lat: 48.7136234, lng: 20.4491434 },
    { lat: 48.7140027, lng: 20.4491409 },
    { lat: 48.7140802, lng: 20.449224 },
    { lat: 48.7142377, lng: 20.4492197 },
    { lat: 48.7154913, lng: 20.4500286 },
    { lat: 48.7161072, lng: 20.4499999 },
    { lat: 48.7167609, lng: 20.4503706 },
    { lat: 48.7174219, lng: 20.4509085 },
    { lat: 48.7182306, lng: 20.4514208 },
    { lat: 48.7187867, lng: 20.451949 },
    { lat: 48.7191379, lng: 20.4523636 },
    { lat: 48.7194969, lng: 20.4525355 },
    { lat: 48.7201277, lng: 20.4533168 },
    { lat: 48.720354, lng: 20.4536717 },
    { lat: 48.7207792, lng: 20.4532169 },
    { lat: 48.7211032, lng: 20.4527004 },
    { lat: 48.7217074, lng: 20.4530482 },
    { lat: 48.7220178, lng: 20.452888 },
    { lat: 48.7223977, lng: 20.4529258 },
    { lat: 48.7228129, lng: 20.4531006 },
    { lat: 48.7234243, lng: 20.4535178 },
    { lat: 48.7238168, lng: 20.4536514 },
    { lat: 48.7239667, lng: 20.4539231 },
    { lat: 48.7248402, lng: 20.4545715 },
    { lat: 48.7258214, lng: 20.4556197 },
    { lat: 48.7270481, lng: 20.4558746 },
    { lat: 48.7275284, lng: 20.4551494 },
    { lat: 48.728161, lng: 20.4545461 },
    { lat: 48.7283181, lng: 20.4536705 },
    { lat: 48.7291755, lng: 20.4526729 },
    { lat: 48.7296737, lng: 20.4524447 },
    { lat: 48.730401, lng: 20.4512258 },
    { lat: 48.7316012, lng: 20.4502395 },
    { lat: 48.7327637, lng: 20.4485309 },
    { lat: 48.7328013, lng: 20.4483354 },
    { lat: 48.733162, lng: 20.4475116 },
    { lat: 48.7336105, lng: 20.4467197 },
    { lat: 48.7336218, lng: 20.4465996 },
  ],
  Silica: [
    { lat: 48.6011235, lng: 20.5364719 },
    { lat: 48.600669, lng: 20.5294731 },
    { lat: 48.6002393, lng: 20.5270983 },
    { lat: 48.6015568, lng: 20.5236646 },
    { lat: 48.6026742, lng: 20.5203298 },
    { lat: 48.6033429, lng: 20.5191804 },
    { lat: 48.6038719, lng: 20.5179433 },
    { lat: 48.6042367, lng: 20.5168269 },
    { lat: 48.6049827, lng: 20.511636 },
    { lat: 48.6056458, lng: 20.5090485 },
    { lat: 48.6056655, lng: 20.508971 },
    { lat: 48.6049173, lng: 20.5079052 },
    { lat: 48.6042087, lng: 20.5070548 },
    { lat: 48.6029002, lng: 20.5049499 },
    { lat: 48.5992287, lng: 20.4995586 },
    { lat: 48.5978846, lng: 20.4999003 },
    { lat: 48.5957547, lng: 20.4999146 },
    { lat: 48.5935729, lng: 20.4998016 },
    { lat: 48.5894523, lng: 20.4993402 },
    { lat: 48.588209, lng: 20.4993009 },
    { lat: 48.5872515, lng: 20.4991742 },
    { lat: 48.5857423, lng: 20.4991913 },
    { lat: 48.5828129, lng: 20.4979797 },
    { lat: 48.582008, lng: 20.4973244 },
    { lat: 48.579922, lng: 20.4955677 },
    { lat: 48.579101, lng: 20.494518 },
    { lat: 48.5784645, lng: 20.4938505 },
    { lat: 48.5767728, lng: 20.4917446 },
    { lat: 48.5762324, lng: 20.4916553 },
    { lat: 48.5754001, lng: 20.4902174 },
    { lat: 48.5740581, lng: 20.4895008 },
    { lat: 48.5719116, lng: 20.4884158 },
    { lat: 48.570045, lng: 20.4883984 },
    { lat: 48.5676696, lng: 20.4879581 },
    { lat: 48.5652639, lng: 20.4867856 },
    { lat: 48.5631283, lng: 20.4858972 },
    { lat: 48.5617059, lng: 20.4853264 },
    { lat: 48.5599173, lng: 20.4849549 },
    { lat: 48.5581599, lng: 20.484099 },
    { lat: 48.5571824, lng: 20.484268 },
    { lat: 48.5567783, lng: 20.4852598 },
    { lat: 48.5550871, lng: 20.4894461 },
    { lat: 48.5538621, lng: 20.4926171 },
    { lat: 48.5519527, lng: 20.4971745 },
    { lat: 48.5506876, lng: 20.5004522 },
    { lat: 48.5493743, lng: 20.503531 },
    { lat: 48.5491411, lng: 20.5045797 },
    { lat: 48.549047, lng: 20.5067808 },
    { lat: 48.5492508, lng: 20.5083701 },
    { lat: 48.5489556, lng: 20.5089656 },
    { lat: 48.548628, lng: 20.5098375 },
    { lat: 48.5476825, lng: 20.5106309 },
    { lat: 48.5459393, lng: 20.5116448 },
    { lat: 48.5446342, lng: 20.5130837 },
    { lat: 48.5428712, lng: 20.5151641 },
    { lat: 48.5417403, lng: 20.5162662 },
    { lat: 48.5408562, lng: 20.5168973 },
    { lat: 48.5401621, lng: 20.5175102 },
    { lat: 48.5379746, lng: 20.5185434 },
    { lat: 48.5379603, lng: 20.518615 },
    { lat: 48.537519, lng: 20.5191 },
    { lat: 48.537337, lng: 20.519291 },
    { lat: 48.5368, lng: 20.519807 },
    { lat: 48.53666, lng: 20.520027 },
    { lat: 48.536151, lng: 20.520823 },
    { lat: 48.535676, lng: 20.52104 },
    { lat: 48.535175, lng: 20.521267 },
    { lat: 48.534626, lng: 20.521568 },
    { lat: 48.53404, lng: 20.521895 },
    { lat: 48.533479, lng: 20.522185 },
    { lat: 48.532832, lng: 20.522543 },
    { lat: 48.532127, lng: 20.523174 },
    { lat: 48.531505, lng: 20.523722 },
    { lat: 48.530777, lng: 20.524698 },
    { lat: 48.530385, lng: 20.525215 },
    { lat: 48.530435, lng: 20.526559 },
    { lat: 48.530468, lng: 20.527467 },
    { lat: 48.53049, lng: 20.52808 },
    { lat: 48.530504, lng: 20.528562 },
    { lat: 48.530522, lng: 20.529028 },
    { lat: 48.53028, lng: 20.529558 },
    { lat: 48.530111, lng: 20.529929 },
    { lat: 48.529686, lng: 20.530776 },
    { lat: 48.529055, lng: 20.532091 },
    { lat: 48.528688, lng: 20.532846 },
    { lat: 48.528461, lng: 20.533322 },
    { lat: 48.528399, lng: 20.533636 },
    { lat: 48.52832, lng: 20.534056 },
    { lat: 48.528128, lng: 20.535582 },
    { lat: 48.527951, lng: 20.536941 },
    { lat: 48.527948, lng: 20.536962 },
    { lat: 48.527936, lng: 20.537049 },
    { lat: 48.527878, lng: 20.537471 },
    { lat: 48.52861, lng: 20.537761 },
    { lat: 48.529085, lng: 20.537943 },
    { lat: 48.529262, lng: 20.538041 },
    { lat: 48.529799, lng: 20.538289 },
    { lat: 48.530037, lng: 20.538368 },
    { lat: 48.530575, lng: 20.538546 },
    { lat: 48.53118, lng: 20.538758 },
    { lat: 48.531454, lng: 20.538853 },
    { lat: 48.531816, lng: 20.538999 },
    { lat: 48.532753, lng: 20.539368 },
    { lat: 48.533415, lng: 20.539581 },
    { lat: 48.533691, lng: 20.539676 },
    { lat: 48.534077, lng: 20.539842 },
    { lat: 48.534349, lng: 20.539936 },
    { lat: 48.535153, lng: 20.540215 },
    { lat: 48.535921, lng: 20.540502 },
    { lat: 48.536775, lng: 20.540797 },
    { lat: 48.537757, lng: 20.541153 },
    { lat: 48.538659, lng: 20.541496 },
    { lat: 48.538719, lng: 20.541581 },
    { lat: 48.538759, lng: 20.54164 },
    { lat: 48.539194, lng: 20.542276 },
    { lat: 48.539486, lng: 20.542681 },
    { lat: 48.539518, lng: 20.542726 },
    { lat: 48.539927, lng: 20.543295 },
    { lat: 48.540486, lng: 20.54408 },
    { lat: 48.540592, lng: 20.544212 },
    { lat: 48.540827, lng: 20.544465 },
    { lat: 48.540847, lng: 20.544469 },
    { lat: 48.540891, lng: 20.54448 },
    { lat: 48.541313, lng: 20.544582 },
    { lat: 48.541493, lng: 20.54462 },
    { lat: 48.542011, lng: 20.544786 },
    { lat: 48.54205, lng: 20.544798 },
    { lat: 48.542114, lng: 20.544819 },
    { lat: 48.542641, lng: 20.545216 },
    { lat: 48.543695, lng: 20.545986 },
    { lat: 48.544123, lng: 20.546307 },
    { lat: 48.544213, lng: 20.54637 },
    { lat: 48.544292, lng: 20.546494 },
    { lat: 48.544004, lng: 20.547291 },
    { lat: 48.543529, lng: 20.548244 },
    { lat: 48.543164, lng: 20.548879 },
    { lat: 48.542964, lng: 20.549412 },
    { lat: 48.542599, lng: 20.549985 },
    { lat: 48.542349, lng: 20.551123 },
    { lat: 48.542388, lng: 20.551881 },
    { lat: 48.542352, lng: 20.552803 },
    { lat: 48.542129, lng: 20.553629 },
    { lat: 48.542071, lng: 20.554857 },
    { lat: 48.54219, lng: 20.555555 },
    { lat: 48.542561, lng: 20.556382 },
    { lat: 48.542719, lng: 20.556596 },
    { lat: 48.542837, lng: 20.556754 },
    { lat: 48.543084, lng: 20.557097 },
    { lat: 48.543058, lng: 20.558052 },
    { lat: 48.542811, lng: 20.559362 },
    { lat: 48.542352, lng: 20.560391 },
    { lat: 48.542254, lng: 20.561043 },
    { lat: 48.541995, lng: 20.561759 },
    { lat: 48.541669, lng: 20.562952 },
    { lat: 48.541555, lng: 20.563902 },
    { lat: 48.541163, lng: 20.56507 },
    { lat: 48.540978, lng: 20.566537 },
    { lat: 48.540768, lng: 20.567572 },
    { lat: 48.540381, lng: 20.568663 },
    { lat: 48.540162, lng: 20.569406 },
    { lat: 48.539956, lng: 20.570531 },
    { lat: 48.539804, lng: 20.571862 },
    { lat: 48.539309, lng: 20.572825 },
    { lat: 48.5395777, lng: 20.5731759 },
    { lat: 48.5401566, lng: 20.5727571 },
    { lat: 48.5408509, lng: 20.5726204 },
    { lat: 48.541576, lng: 20.5720725 },
    { lat: 48.5419731, lng: 20.5719077 },
    { lat: 48.5425397, lng: 20.5700275 },
    { lat: 48.5429286, lng: 20.5694952 },
    { lat: 48.543053, lng: 20.5691696 },
    { lat: 48.5430813, lng: 20.568813 },
    { lat: 48.5432896, lng: 20.5682309 },
    { lat: 48.5435094, lng: 20.5679683 },
    { lat: 48.5437928, lng: 20.5671966 },
    { lat: 48.5439462, lng: 20.5662936 },
    { lat: 48.5443122, lng: 20.5650437 },
    { lat: 48.5443323, lng: 20.5640964 },
    { lat: 48.5445505, lng: 20.563604 },
    { lat: 48.5446111, lng: 20.5632649 },
    { lat: 48.5446288, lng: 20.5626473 },
    { lat: 48.5446995, lng: 20.562066 },
    { lat: 48.5455318, lng: 20.561344 },
    { lat: 48.5470856, lng: 20.5613396 },
    { lat: 48.5510489, lng: 20.5609129 },
    { lat: 48.5512496, lng: 20.5599788 },
    { lat: 48.5530359, lng: 20.5580181 },
    { lat: 48.554342, lng: 20.557443 },
    { lat: 48.5549506, lng: 20.5578215 },
    { lat: 48.5557242, lng: 20.5586733 },
    { lat: 48.5560307, lng: 20.5592589 },
    { lat: 48.5563418, lng: 20.560422 },
    { lat: 48.5566204, lng: 20.5617453 },
    { lat: 48.556884, lng: 20.5626255 },
    { lat: 48.5574763, lng: 20.5636655 },
    { lat: 48.5577307, lng: 20.5653623 },
    { lat: 48.5578794, lng: 20.566007 },
    { lat: 48.5583161, lng: 20.5670342 },
    { lat: 48.5585386, lng: 20.5680282 },
    { lat: 48.5589243, lng: 20.5686512 },
    { lat: 48.5596714, lng: 20.5686685 },
    { lat: 48.5603602, lng: 20.5686 },
    { lat: 48.5633774, lng: 20.566916 },
    { lat: 48.5654441, lng: 20.5658985 },
    { lat: 48.5663851, lng: 20.5651269 },
    { lat: 48.5679648, lng: 20.5650074 },
    { lat: 48.5685664, lng: 20.5659882 },
    { lat: 48.5712752, lng: 20.5652547 },
    { lat: 48.5729297, lng: 20.5653262 },
    { lat: 48.5754757, lng: 20.5645728 },
    { lat: 48.5764585, lng: 20.5640533 },
    { lat: 48.57775, lng: 20.5642053 },
    { lat: 48.5784187, lng: 20.5645684 },
    { lat: 48.5807046, lng: 20.5647244 },
    { lat: 48.5811885, lng: 20.5646282 },
    { lat: 48.5840312, lng: 20.5630042 },
    { lat: 48.5845888, lng: 20.5629235 },
    { lat: 48.586615, lng: 20.561385 },
    { lat: 48.5873601, lng: 20.56129 },
    { lat: 48.5886301, lng: 20.561468 },
    { lat: 48.5933056, lng: 20.5573805 },
    { lat: 48.5946133, lng: 20.5568151 },
    { lat: 48.5948559, lng: 20.5566464 },
    { lat: 48.5960081, lng: 20.5562904 },
    { lat: 48.5982162, lng: 20.5554751 },
    { lat: 48.5981393, lng: 20.55319 },
    { lat: 48.5982033, lng: 20.5502973 },
    { lat: 48.5983814, lng: 20.5484275 },
    { lat: 48.5984125, lng: 20.5471439 },
    { lat: 48.5985711, lng: 20.5449535 },
    { lat: 48.5989902, lng: 20.5444485 },
    { lat: 48.599061, lng: 20.5431964 },
    { lat: 48.5991568, lng: 20.5423851 },
    { lat: 48.5994066, lng: 20.5411972 },
    { lat: 48.5998231, lng: 20.5405889 },
    { lat: 48.6002801, lng: 20.540075 },
    { lat: 48.6003921, lng: 20.5397863 },
    { lat: 48.6011235, lng: 20.5364719 },
  ],
  Koceľovce: [
    { lat: 48.7291784, lng: 20.3678357 },
    { lat: 48.7298659, lng: 20.3669443 },
    { lat: 48.7305689, lng: 20.3657854 },
    { lat: 48.7314162, lng: 20.3645439 },
    { lat: 48.732411, lng: 20.3623971 },
    { lat: 48.7334796, lng: 20.3615526 },
    { lat: 48.7341593, lng: 20.3608408 },
    { lat: 48.7343821, lng: 20.3597923 },
    { lat: 48.734814, lng: 20.3558186 },
    { lat: 48.7350707, lng: 20.355029 },
    { lat: 48.7371458, lng: 20.3508377 },
    { lat: 48.733933, lng: 20.3500815 },
    { lat: 48.7326615, lng: 20.3494946 },
    { lat: 48.7314102, lng: 20.3485563 },
    { lat: 48.7305657, lng: 20.3482116 },
    { lat: 48.7291624, lng: 20.3471599 },
    { lat: 48.7283672, lng: 20.3463868 },
    { lat: 48.727428, lng: 20.3454738 },
    { lat: 48.7264647, lng: 20.3447624 },
    { lat: 48.7244784, lng: 20.3424165 },
    { lat: 48.7237804, lng: 20.3409711 },
    { lat: 48.7227785, lng: 20.3398798 },
    { lat: 48.7227269, lng: 20.3399119 },
    { lat: 48.722109, lng: 20.3387916 },
    { lat: 48.7213954, lng: 20.3379883 },
    { lat: 48.7213288, lng: 20.3377805 },
    { lat: 48.7213701, lng: 20.3377462 },
    { lat: 48.7211874, lng: 20.3366643 },
    { lat: 48.7207358, lng: 20.334637 },
    { lat: 48.720179, lng: 20.3339311 },
    { lat: 48.719848, lng: 20.3330596 },
    { lat: 48.7196751, lng: 20.3323204 },
    { lat: 48.7195411, lng: 20.3320299 },
    { lat: 48.7193998, lng: 20.33199 },
    { lat: 48.719245, lng: 20.3316878 },
    { lat: 48.7192376, lng: 20.3316713 },
    { lat: 48.7191767, lng: 20.3317252 },
    { lat: 48.7191, lng: 20.3316104 },
    { lat: 48.7189319, lng: 20.3315729 },
    { lat: 48.7184347, lng: 20.3316988 },
    { lat: 48.718338, lng: 20.3316158 },
    { lat: 48.7179202, lng: 20.3316736 },
    { lat: 48.7177466, lng: 20.3316391 },
    { lat: 48.7177438, lng: 20.3316882 },
    { lat: 48.7174602, lng: 20.331805 },
    { lat: 48.7171176, lng: 20.3318836 },
    { lat: 48.7171243, lng: 20.3320029 },
    { lat: 48.71685, lng: 20.3327644 },
    { lat: 48.7166677, lng: 20.3328829 },
    { lat: 48.7164438, lng: 20.3315848 },
    { lat: 48.7162514, lng: 20.331589 },
    { lat: 48.7156412, lng: 20.3320031 },
    { lat: 48.7152087, lng: 20.3320276 },
    { lat: 48.7151716, lng: 20.3321725 },
    { lat: 48.7149455, lng: 20.3322375 },
    { lat: 48.714424, lng: 20.3322318 },
    { lat: 48.7128659, lng: 20.3329123 },
    { lat: 48.7121565, lng: 20.3333782 },
    { lat: 48.7119287, lng: 20.3334245 },
    { lat: 48.7114211, lng: 20.3339528 },
    { lat: 48.7107452, lng: 20.3344407 },
    { lat: 48.7106098, lng: 20.3338252 },
    { lat: 48.7101221, lng: 20.3321156 },
    { lat: 48.7101081, lng: 20.3316116 },
    { lat: 48.7100244, lng: 20.3315142 },
    { lat: 48.7101526, lng: 20.3310739 },
    { lat: 48.7102069, lng: 20.3305599 },
    { lat: 48.7104158, lng: 20.3300574 },
    { lat: 48.7105823, lng: 20.3297895 },
    { lat: 48.7106958, lng: 20.329759 },
    { lat: 48.7110799, lng: 20.3290659 },
    { lat: 48.7116498, lng: 20.3275636 },
    { lat: 48.7121572, lng: 20.326918 },
    { lat: 48.7128405, lng: 20.3262193 },
    { lat: 48.7129474, lng: 20.3263581 },
    { lat: 48.7132851, lng: 20.3262692 },
    { lat: 48.7149754, lng: 20.3236725 },
    { lat: 48.7152369, lng: 20.3222317 },
    { lat: 48.7155926, lng: 20.3216227 },
    { lat: 48.71603, lng: 20.320332 },
    { lat: 48.7163558, lng: 20.3190174 },
    { lat: 48.7162254, lng: 20.3155039 },
    { lat: 48.7153844, lng: 20.3155657 },
    { lat: 48.7144136, lng: 20.3158549 },
    { lat: 48.7132804, lng: 20.3163928 },
    { lat: 48.7125814, lng: 20.3165182 },
    { lat: 48.711012, lng: 20.3165566 },
    { lat: 48.7102588, lng: 20.3167032 },
    { lat: 48.7097791, lng: 20.3170495 },
    { lat: 48.7092782, lng: 20.3171719 },
    { lat: 48.7088643, lng: 20.3173606 },
    { lat: 48.7084055, lng: 20.3174065 },
    { lat: 48.7079664, lng: 20.3173483 },
    { lat: 48.7074835, lng: 20.3174406 },
    { lat: 48.7068956, lng: 20.3174092 },
    { lat: 48.7062972, lng: 20.3172832 },
    { lat: 48.7055157, lng: 20.3172556 },
    { lat: 48.7052042, lng: 20.3174135 },
    { lat: 48.7050893, lng: 20.317643 },
    { lat: 48.705009, lng: 20.3178042 },
    { lat: 48.7049201, lng: 20.3181797 },
    { lat: 48.7048037, lng: 20.3184033 },
    { lat: 48.7042343, lng: 20.318905 },
    { lat: 48.7037855, lng: 20.3194909 },
    { lat: 48.7033423, lng: 20.3197917 },
    { lat: 48.7029109, lng: 20.3204569 },
    { lat: 48.7023248, lng: 20.3217098 },
    { lat: 48.7020567, lng: 20.3227494 },
    { lat: 48.7006929, lng: 20.324048 },
    { lat: 48.6991081, lng: 20.3245876 },
    { lat: 48.6986173, lng: 20.3250234 },
    { lat: 48.6987819, lng: 20.3262133 },
    { lat: 48.6992967, lng: 20.328355 },
    { lat: 48.699261, lng: 20.3285642 },
    { lat: 48.6993085, lng: 20.3290316 },
    { lat: 48.6992452, lng: 20.3293777 },
    { lat: 48.6993096, lng: 20.3298732 },
    { lat: 48.6993094, lng: 20.3309341 },
    { lat: 48.6994053, lng: 20.3311113 },
    { lat: 48.699625, lng: 20.3312946 },
    { lat: 48.6997102, lng: 20.3315113 },
    { lat: 48.6998826, lng: 20.3317145 },
    { lat: 48.7000831, lng: 20.3323858 },
    { lat: 48.7001957, lng: 20.3325869 },
    { lat: 48.7004125, lng: 20.3326278 },
    { lat: 48.7005824, lng: 20.3329436 },
    { lat: 48.7006835, lng: 20.333254 },
    { lat: 48.7008681, lng: 20.3343235 },
    { lat: 48.7012106, lng: 20.3352542 },
    { lat: 48.7016069, lng: 20.3369609 },
    { lat: 48.7017557, lng: 20.3378928 },
    { lat: 48.7018213, lng: 20.3387968 },
    { lat: 48.7021615, lng: 20.3398169 },
    { lat: 48.7017197, lng: 20.3400383 },
    { lat: 48.7016271, lng: 20.3403759 },
    { lat: 48.7016968, lng: 20.3407262 },
    { lat: 48.7011879, lng: 20.3408669 },
    { lat: 48.7001316, lng: 20.3419099 },
    { lat: 48.6996647, lng: 20.342126 },
    { lat: 48.6996452, lng: 20.3420614 },
    { lat: 48.6991161, lng: 20.3423275 },
    { lat: 48.6991977, lng: 20.3427991 },
    { lat: 48.6991328, lng: 20.342957 },
    { lat: 48.698717, lng: 20.3436184 },
    { lat: 48.6980804, lng: 20.3440467 },
    { lat: 48.6981614, lng: 20.3444476 },
    { lat: 48.6976617, lng: 20.3447179 },
    { lat: 48.6975088, lng: 20.344955 },
    { lat: 48.697672, lng: 20.345215 },
    { lat: 48.6979201, lng: 20.3460628 },
    { lat: 48.6982485, lng: 20.3459699 },
    { lat: 48.6991793, lng: 20.3453157 },
    { lat: 48.6991347, lng: 20.3450662 },
    { lat: 48.6995255, lng: 20.3448658 },
    { lat: 48.7002404, lng: 20.3440185 },
    { lat: 48.7004446, lng: 20.3438575 },
    { lat: 48.7005553, lng: 20.3435411 },
    { lat: 48.7007133, lng: 20.343552 },
    { lat: 48.700939, lng: 20.3430119 },
    { lat: 48.7011107, lng: 20.3427675 },
    { lat: 48.7012804, lng: 20.3427257 },
    { lat: 48.7014322, lng: 20.3424616 },
    { lat: 48.7016857, lng: 20.3426085 },
    { lat: 48.7019963, lng: 20.3424569 },
    { lat: 48.7029592, lng: 20.3445213 },
    { lat: 48.7030759, lng: 20.3450589 },
    { lat: 48.7032631, lng: 20.3453892 },
    { lat: 48.7040822, lng: 20.3464711 },
    { lat: 48.7049336, lng: 20.3481872 },
    { lat: 48.7052424, lng: 20.3484825 },
    { lat: 48.7059017, lng: 20.3497429 },
    { lat: 48.7064272, lng: 20.3516545 },
    { lat: 48.7066093, lng: 20.3520541 },
    { lat: 48.7068593, lng: 20.3528053 },
    { lat: 48.7074997, lng: 20.3537556 },
    { lat: 48.7082391, lng: 20.3548229 },
    { lat: 48.7083085, lng: 20.3551594 },
    { lat: 48.7085612, lng: 20.3545302 },
    { lat: 48.7086083, lng: 20.3546131 },
    { lat: 48.7095365, lng: 20.3538917 },
    { lat: 48.7097191, lng: 20.3536641 },
    { lat: 48.7099899, lng: 20.3539418 },
    { lat: 48.710813, lng: 20.3543757 },
    { lat: 48.7112554, lng: 20.354722 },
    { lat: 48.7115337, lng: 20.3542852 },
    { lat: 48.7120681, lng: 20.3548024 },
    { lat: 48.7130011, lng: 20.3551607 },
    { lat: 48.7151012, lng: 20.3561225 },
    { lat: 48.7155133, lng: 20.3561178 },
    { lat: 48.7166236, lng: 20.3563217 },
    { lat: 48.7171954, lng: 20.3562309 },
    { lat: 48.7176316, lng: 20.356356 },
    { lat: 48.7185, lng: 20.3571743 },
    { lat: 48.7184843, lng: 20.3574371 },
    { lat: 48.7188691, lng: 20.3575147 },
    { lat: 48.7202075, lng: 20.357185 },
    { lat: 48.7209187, lng: 20.3572041 },
    { lat: 48.7216296, lng: 20.3571224 },
    { lat: 48.7231255, lng: 20.3584227 },
    { lat: 48.7234825, lng: 20.358733 },
    { lat: 48.7241213, lng: 20.3595575 },
    { lat: 48.7247681, lng: 20.3606759 },
    { lat: 48.7256535, lng: 20.362055 },
    { lat: 48.7260668, lng: 20.3630158 },
    { lat: 48.7268421, lng: 20.3638447 },
    { lat: 48.7273674, lng: 20.364909 },
    { lat: 48.7284188, lng: 20.3661831 },
    { lat: 48.7285321, lng: 20.3671925 },
    { lat: 48.7287445, lng: 20.36756 },
    { lat: 48.7291784, lng: 20.3678357 },
  ],
  Ardovo: [
    { lat: 48.5134543, lng: 20.4150536 },
    { lat: 48.5137587, lng: 20.4157979 },
    { lat: 48.5139017, lng: 20.416353 },
    { lat: 48.5139359, lng: 20.4167152 },
    { lat: 48.5141118, lng: 20.4175185 },
    { lat: 48.5141546, lng: 20.4180756 },
    { lat: 48.5142905, lng: 20.4187387 },
    { lat: 48.5142043, lng: 20.4188417 },
    { lat: 48.5158908, lng: 20.4238333 },
    { lat: 48.515128, lng: 20.4257037 },
    { lat: 48.5149571, lng: 20.4264733 },
    { lat: 48.5149926, lng: 20.4267838 },
    { lat: 48.5149097, lng: 20.4301516 },
    { lat: 48.5147871, lng: 20.4321931 },
    { lat: 48.514433, lng: 20.4340035 },
    { lat: 48.5144028, lng: 20.4383761 },
    { lat: 48.5148019, lng: 20.4422889 },
    { lat: 48.5143063, lng: 20.4429905 },
    { lat: 48.5146295, lng: 20.4439674 },
    { lat: 48.5146973, lng: 20.4453026 },
    { lat: 48.5160316, lng: 20.4467801 },
    { lat: 48.5175911, lng: 20.4490246 },
    { lat: 48.5180151, lng: 20.4509085 },
    { lat: 48.5179459, lng: 20.4524883 },
    { lat: 48.517357, lng: 20.4537633 },
    { lat: 48.5153084, lng: 20.4552116 },
    { lat: 48.5141991, lng: 20.4571337 },
    { lat: 48.5153297, lng: 20.4600717 },
    { lat: 48.5156928, lng: 20.4620102 },
    { lat: 48.5160884, lng: 20.4637712 },
    { lat: 48.5165599, lng: 20.4634696 },
    { lat: 48.5168811, lng: 20.463382 },
    { lat: 48.5174136, lng: 20.4633806 },
    { lat: 48.519234, lng: 20.4631304 },
    { lat: 48.5199481, lng: 20.4631099 },
    { lat: 48.5204012, lng: 20.4632407 },
    { lat: 48.5212087, lng: 20.4638078 },
    { lat: 48.5216452, lng: 20.4640314 },
    { lat: 48.5235053, lng: 20.463733 },
    { lat: 48.5254115, lng: 20.4639045 },
    { lat: 48.5263285, lng: 20.4638861 },
    { lat: 48.5281443, lng: 20.4641115 },
    { lat: 48.5298266, lng: 20.4648519 },
    { lat: 48.5308577, lng: 20.4661193 },
    { lat: 48.5312621, lng: 20.4662747 },
    { lat: 48.5315451, lng: 20.4661946 },
    { lat: 48.5319262, lng: 20.4664148 },
    { lat: 48.5322742, lng: 20.466182 },
    { lat: 48.5328364, lng: 20.4655077 },
    { lat: 48.5329758, lng: 20.4654989 },
    { lat: 48.5332582, lng: 20.4653418 },
    { lat: 48.5355001, lng: 20.4647462 },
    { lat: 48.5358931, lng: 20.4647188 },
    { lat: 48.5362157, lng: 20.4646126 },
    { lat: 48.5365649, lng: 20.4646504 },
    { lat: 48.5368889, lng: 20.4644332 },
    { lat: 48.5377796, lng: 20.4642255 },
    { lat: 48.5377612, lng: 20.463946 },
    { lat: 48.5372672, lng: 20.4631848 },
    { lat: 48.5371602, lng: 20.4629456 },
    { lat: 48.53711, lng: 20.462692 },
    { lat: 48.5371726, lng: 20.4624119 },
    { lat: 48.5373838, lng: 20.4622207 },
    { lat: 48.5378687, lng: 20.4619715 },
    { lat: 48.5382378, lng: 20.4614871 },
    { lat: 48.5384094, lng: 20.461084 },
    { lat: 48.539005, lng: 20.4591822 },
    { lat: 48.539165, lng: 20.4579706 },
    { lat: 48.5391941, lng: 20.4555049 },
    { lat: 48.5391604, lng: 20.4546735 },
    { lat: 48.5389883, lng: 20.4532574 },
    { lat: 48.5385909, lng: 20.4466587 },
    { lat: 48.5385333, lng: 20.4462373 },
    { lat: 48.5379714, lng: 20.4446442 },
    { lat: 48.5372988, lng: 20.4401122 },
    { lat: 48.5366227, lng: 20.4368512 },
    { lat: 48.5361786, lng: 20.4335046 },
    { lat: 48.5361058, lng: 20.4326314 },
    { lat: 48.536, lng: 20.426855 },
    { lat: 48.5355802, lng: 20.425711 },
    { lat: 48.5354955, lng: 20.4248851 },
    { lat: 48.5355312, lng: 20.4243761 },
    { lat: 48.5356243, lng: 20.4240699 },
    { lat: 48.5357781, lng: 20.4239084 },
    { lat: 48.5362734, lng: 20.4236988 },
    { lat: 48.5364365, lng: 20.4234917 },
    { lat: 48.536589, lng: 20.4232407 },
    { lat: 48.5370067, lng: 20.4221495 },
    { lat: 48.5370438, lng: 20.4218478 },
    { lat: 48.5367383, lng: 20.4199286 },
    { lat: 48.5367381, lng: 20.4194837 },
    { lat: 48.5369133, lng: 20.4185333 },
    { lat: 48.5369733, lng: 20.4175669 },
    { lat: 48.5368613, lng: 20.4171769 },
    { lat: 48.5357151, lng: 20.4154743 },
    { lat: 48.5343044, lng: 20.4119585 },
    { lat: 48.5341224, lng: 20.4113533 },
    { lat: 48.5335658, lng: 20.4105802 },
    { lat: 48.5321288, lng: 20.4097289 },
    { lat: 48.5320266, lng: 20.4096181 },
    { lat: 48.531936, lng: 20.4094266 },
    { lat: 48.5317918, lng: 20.4083996 },
    { lat: 48.5312801, lng: 20.4075065 },
    { lat: 48.5313058, lng: 20.4074137 },
    { lat: 48.5309823, lng: 20.4070582 },
    { lat: 48.5308261, lng: 20.4067993 },
    { lat: 48.5304521, lng: 20.4058306 },
    { lat: 48.5298914, lng: 20.4050112 },
    { lat: 48.5291875, lng: 20.4036933 },
    { lat: 48.528934, lng: 20.4030713 },
    { lat: 48.5287615, lng: 20.4019835 },
    { lat: 48.528156, lng: 20.3978629 },
    { lat: 48.5281747, lng: 20.3971529 },
    { lat: 48.528144, lng: 20.3969076 },
    { lat: 48.5261831, lng: 20.397515 },
    { lat: 48.5261366, lng: 20.3974181 },
    { lat: 48.5261584, lng: 20.3973079 },
    { lat: 48.526042, lng: 20.3970388 },
    { lat: 48.5259474, lng: 20.3970087 },
    { lat: 48.5259333, lng: 20.3968753 },
    { lat: 48.5257769, lng: 20.3966747 },
    { lat: 48.5256213, lng: 20.3965803 },
    { lat: 48.5255002, lng: 20.3965958 },
    { lat: 48.5252567, lng: 20.3961812 },
    { lat: 48.5250344, lng: 20.395407 },
    { lat: 48.524936, lng: 20.3952486 },
    { lat: 48.5247851, lng: 20.3951914 },
    { lat: 48.5238576, lng: 20.3939736 },
    { lat: 48.5237489, lng: 20.3936637 },
    { lat: 48.5232145, lng: 20.3929509 },
    { lat: 48.5227471, lng: 20.3921019 },
    { lat: 48.5225671, lng: 20.3921929 },
    { lat: 48.5222049, lng: 20.3925254 },
    { lat: 48.5217078, lng: 20.3925404 },
    { lat: 48.5216147, lng: 20.3926111 },
    { lat: 48.5215485, lng: 20.3927597 },
    { lat: 48.5215513, lng: 20.3930106 },
    { lat: 48.5216136, lng: 20.3933412 },
    { lat: 48.5217346, lng: 20.3934997 },
    { lat: 48.5217358, lng: 20.3936463 },
    { lat: 48.5211582, lng: 20.394555 },
    { lat: 48.5204526, lng: 20.3953856 },
    { lat: 48.5204482, lng: 20.3955057 },
    { lat: 48.5205933, lng: 20.3960245 },
    { lat: 48.5206955, lng: 20.3962521 },
    { lat: 48.520695, lng: 20.3966496 },
    { lat: 48.5207616, lng: 20.3970612 },
    { lat: 48.520701, lng: 20.3974491 },
    { lat: 48.5207563, lng: 20.3977142 },
    { lat: 48.5212148, lng: 20.3985413 },
    { lat: 48.5212952, lng: 20.3991662 },
    { lat: 48.5212309, lng: 20.3993413 },
    { lat: 48.5213838, lng: 20.3998562 },
    { lat: 48.5214203, lng: 20.4001898 },
    { lat: 48.5213925, lng: 20.4006096 },
    { lat: 48.521297, lng: 20.4010282 },
    { lat: 48.5209136, lng: 20.4014825 },
    { lat: 48.5199318, lng: 20.4023073 },
    { lat: 48.5197434, lng: 20.4025862 },
    { lat: 48.517524, lng: 20.4032357 },
    { lat: 48.5168554, lng: 20.4043013 },
    { lat: 48.5165357, lng: 20.4045373 },
    { lat: 48.5157637, lng: 20.4055443 },
    { lat: 48.5155957, lng: 20.4056019 },
    { lat: 48.5153039, lng: 20.4059776 },
    { lat: 48.5147513, lng: 20.4062178 },
    { lat: 48.5144851, lng: 20.4064694 },
    { lat: 48.5139167, lng: 20.4075041 },
    { lat: 48.5139858, lng: 20.4080415 },
    { lat: 48.5141046, lng: 20.4082304 },
    { lat: 48.5140654, lng: 20.4087382 },
    { lat: 48.514072, lng: 20.4113133 },
    { lat: 48.5140201, lng: 20.4131892 },
    { lat: 48.5134784, lng: 20.4149503 },
    { lat: 48.5134543, lng: 20.4150536 },
  ],
  Rejdová: [
    { lat: 48.7554296, lng: 20.2582659 },
    { lat: 48.7557605, lng: 20.2592342 },
    { lat: 48.7563773, lng: 20.2609666 },
    { lat: 48.7571117, lng: 20.262107 },
    { lat: 48.7579933, lng: 20.2648974 },
    { lat: 48.7585523, lng: 20.26499 },
    { lat: 48.7604012, lng: 20.2675562 },
    { lat: 48.7608267, lng: 20.2683516 },
    { lat: 48.7612551, lng: 20.268934 },
    { lat: 48.7619197, lng: 20.2704797 },
    { lat: 48.7630829, lng: 20.2722477 },
    { lat: 48.7636759, lng: 20.2734611 },
    { lat: 48.7650827, lng: 20.2777322 },
    { lat: 48.7652305, lng: 20.277967 },
    { lat: 48.7652574, lng: 20.2781945 },
    { lat: 48.7656319, lng: 20.279352 },
    { lat: 48.7662181, lng: 20.279684 },
    { lat: 48.7666288, lng: 20.2807658 },
    { lat: 48.7687081, lng: 20.284129 },
    { lat: 48.7687216, lng: 20.2844969 },
    { lat: 48.7690857, lng: 20.284452 },
    { lat: 48.7691714, lng: 20.2847974 },
    { lat: 48.7698427, lng: 20.2850001 },
    { lat: 48.7702207, lng: 20.2853035 },
    { lat: 48.7704661, lng: 20.2861326 },
    { lat: 48.7709413, lng: 20.2865336 },
    { lat: 48.7711372, lng: 20.2869366 },
    { lat: 48.7711902, lng: 20.2873663 },
    { lat: 48.771396, lng: 20.2877637 },
    { lat: 48.7717612, lng: 20.2890908 },
    { lat: 48.7718069, lng: 20.2894255 },
    { lat: 48.7716496, lng: 20.2900647 },
    { lat: 48.7715044, lng: 20.2911288 },
    { lat: 48.7715775, lng: 20.2918413 },
    { lat: 48.7715142, lng: 20.2925817 },
    { lat: 48.7712729, lng: 20.2933466 },
    { lat: 48.7708408, lng: 20.294305 },
    { lat: 48.7708086, lng: 20.2946924 },
    { lat: 48.7712744, lng: 20.2961176 },
    { lat: 48.7715161, lng: 20.2971677 },
    { lat: 48.7717125, lng: 20.2970662 },
    { lat: 48.7722872, lng: 20.2979971 },
    { lat: 48.7728645, lng: 20.298644 },
    { lat: 48.7731245, lng: 20.2990763 },
    { lat: 48.7735007, lng: 20.3007481 },
    { lat: 48.7738847, lng: 20.3004309 },
    { lat: 48.7751395, lng: 20.299227 },
    { lat: 48.7762084, lng: 20.2986281 },
    { lat: 48.7767803, lng: 20.2984258 },
    { lat: 48.7778355, lng: 20.2981948 },
    { lat: 48.7790631, lng: 20.297793 },
    { lat: 48.7793113, lng: 20.2972309 },
    { lat: 48.7797416, lng: 20.2967767 },
    { lat: 48.7802583, lng: 20.2967985 },
    { lat: 48.7803827, lng: 20.296696 },
    { lat: 48.7810904, lng: 20.2966532 },
    { lat: 48.7812709, lng: 20.2965452 },
    { lat: 48.7816872, lng: 20.2965625 },
    { lat: 48.7817741, lng: 20.2964579 },
    { lat: 48.7823183, lng: 20.2962892 },
    { lat: 48.7824795, lng: 20.2963504 },
    { lat: 48.7826639, lng: 20.296576 },
    { lat: 48.7827937, lng: 20.2966118 },
    { lat: 48.7833956, lng: 20.2974291 },
    { lat: 48.7834435, lng: 20.2977612 },
    { lat: 48.7837961, lng: 20.2981103 },
    { lat: 48.7839196, lng: 20.2983838 },
    { lat: 48.7841502, lng: 20.2986374 },
    { lat: 48.784293, lng: 20.2984881 },
    { lat: 48.7844133, lng: 20.2986273 },
    { lat: 48.7844506, lng: 20.2988311 },
    { lat: 48.78495, lng: 20.2999426 },
    { lat: 48.7851242, lng: 20.3011976 },
    { lat: 48.7855846, lng: 20.302258 },
    { lat: 48.7858434, lng: 20.3027391 },
    { lat: 48.7859405, lng: 20.3031532 },
    { lat: 48.786109, lng: 20.3029974 },
    { lat: 48.7862151, lng: 20.3027408 },
    { lat: 48.7862, lng: 20.3023311 },
    { lat: 48.7863475, lng: 20.3018965 },
    { lat: 48.786559, lng: 20.3016868 },
    { lat: 48.7867799, lng: 20.3008557 },
    { lat: 48.7869353, lng: 20.3006589 },
    { lat: 48.7870638, lng: 20.300747 },
    { lat: 48.7872732, lng: 20.300715 },
    { lat: 48.7894079, lng: 20.3022879 },
    { lat: 48.7898565, lng: 20.3021874 },
    { lat: 48.7907865, lng: 20.3028144 },
    { lat: 48.7910827, lng: 20.3020372 },
    { lat: 48.7914956, lng: 20.3019398 },
    { lat: 48.791697, lng: 20.3014785 },
    { lat: 48.7919494, lng: 20.301597 },
    { lat: 48.7923421, lng: 20.3014696 },
    { lat: 48.7924828, lng: 20.3008934 },
    { lat: 48.7926563, lng: 20.30049 },
    { lat: 48.7927885, lng: 20.3003296 },
    { lat: 48.7931233, lng: 20.3001099 },
    { lat: 48.7931935, lng: 20.2998908 },
    { lat: 48.7934074, lng: 20.2997184 },
    { lat: 48.7939124, lng: 20.2999299 },
    { lat: 48.7945639, lng: 20.3003747 },
    { lat: 48.7957092, lng: 20.2999735 },
    { lat: 48.796373, lng: 20.2996114 },
    { lat: 48.7964337, lng: 20.299521 },
    { lat: 48.7967553, lng: 20.2993942 },
    { lat: 48.7974854, lng: 20.298598 },
    { lat: 48.7982228, lng: 20.2980482 },
    { lat: 48.7983593, lng: 20.2978527 },
    { lat: 48.7991731, lng: 20.2978436 },
    { lat: 48.7997426, lng: 20.2976904 },
    { lat: 48.7997964, lng: 20.2974852 },
    { lat: 48.8003989, lng: 20.2975415 },
    { lat: 48.8004333, lng: 20.2976188 },
    { lat: 48.8005456, lng: 20.2976362 },
    { lat: 48.8008218, lng: 20.2976125 },
    { lat: 48.801035, lng: 20.297423 },
    { lat: 48.8013394, lng: 20.2964533 },
    { lat: 48.8018381, lng: 20.2962199 },
    { lat: 48.8021464, lng: 20.2955539 },
    { lat: 48.8024808, lng: 20.2952667 },
    { lat: 48.8026994, lng: 20.2949781 },
    { lat: 48.80265, lng: 20.2945778 },
    { lat: 48.8031626, lng: 20.2946475 },
    { lat: 48.8035336, lng: 20.2948585 },
    { lat: 48.8040163, lng: 20.295246 },
    { lat: 48.804518, lng: 20.2953503 },
    { lat: 48.8048121, lng: 20.2955033 },
    { lat: 48.8060851, lng: 20.2951082 },
    { lat: 48.8071421, lng: 20.2956146 },
    { lat: 48.8077028, lng: 20.296013 },
    { lat: 48.8080425, lng: 20.2964091 },
    { lat: 48.8081406, lng: 20.2967275 },
    { lat: 48.8089686, lng: 20.2964478 },
    { lat: 48.8092614, lng: 20.2959892 },
    { lat: 48.8099459, lng: 20.2951496 },
    { lat: 48.8109321, lng: 20.2931871 },
    { lat: 48.8122456, lng: 20.291647 },
    { lat: 48.8126182, lng: 20.2910687 },
    { lat: 48.8126905, lng: 20.2908717 },
    { lat: 48.812963, lng: 20.2905568 },
    { lat: 48.8133707, lng: 20.2903268 },
    { lat: 48.8150684, lng: 20.2884523 },
    { lat: 48.816543, lng: 20.2856176 },
    { lat: 48.8184043, lng: 20.2839489 },
    { lat: 48.8189827, lng: 20.283855 },
    { lat: 48.8196823, lng: 20.2809918 },
    { lat: 48.820027, lng: 20.2803961 },
    { lat: 48.8200586, lng: 20.2801113 },
    { lat: 48.8206241, lng: 20.2785641 },
    { lat: 48.8205796, lng: 20.2783208 },
    { lat: 48.8206423, lng: 20.278229 },
    { lat: 48.8206727, lng: 20.2780548 },
    { lat: 48.8208902, lng: 20.277656 },
    { lat: 48.8217354, lng: 20.2770119 },
    { lat: 48.8221773, lng: 20.2759965 },
    { lat: 48.8226041, lng: 20.2752797 },
    { lat: 48.823022, lng: 20.274024 },
    { lat: 48.8237721, lng: 20.2729671 },
    { lat: 48.824096, lng: 20.2733535 },
    { lat: 48.8246166, lng: 20.2731636 },
    { lat: 48.8250375, lng: 20.2732142 },
    { lat: 48.8258467, lng: 20.2729461 },
    { lat: 48.8270258, lng: 20.2721445 },
    { lat: 48.82805, lng: 20.2705266 },
    { lat: 48.8287287, lng: 20.2696282 },
    { lat: 48.8296732, lng: 20.2677315 },
    { lat: 48.8299667, lng: 20.2662384 },
    { lat: 48.8299815, lng: 20.2657817 },
    { lat: 48.8301516, lng: 20.2645593 },
    { lat: 48.830475, lng: 20.2633348 },
    { lat: 48.8307128, lng: 20.262652 },
    { lat: 48.831008, lng: 20.2612266 },
    { lat: 48.8312735, lng: 20.2603354 },
    { lat: 48.8315427, lng: 20.257483 },
    { lat: 48.8311729, lng: 20.2562743 },
    { lat: 48.8313825, lng: 20.2551394 },
    { lat: 48.8307234, lng: 20.2486919 },
    { lat: 48.830132, lng: 20.2447008 },
    { lat: 48.8312543, lng: 20.2419048 },
    { lat: 48.8329071, lng: 20.2395644 },
    { lat: 48.8351127, lng: 20.2362796 },
    { lat: 48.8356819, lng: 20.2355307 },
    { lat: 48.8364166, lng: 20.2338905 },
    { lat: 48.8368616, lng: 20.2332148 },
    { lat: 48.8373407, lng: 20.2314691 },
    { lat: 48.837611, lng: 20.230729 },
    { lat: 48.837089, lng: 20.229076 },
    { lat: 48.83614, lng: 20.226463 },
    { lat: 48.835571, lng: 20.225155 },
    { lat: 48.835415, lng: 20.224831 },
    { lat: 48.835175, lng: 20.224328 },
    { lat: 48.834408, lng: 20.220379 },
    { lat: 48.833805, lng: 20.218653 },
    { lat: 48.831921, lng: 20.217815 },
    { lat: 48.830346, lng: 20.216906 },
    { lat: 48.829411, lng: 20.216328 },
    { lat: 48.828862, lng: 20.215988 },
    { lat: 48.828272, lng: 20.215116 },
    { lat: 48.827927, lng: 20.214603 },
    { lat: 48.82769, lng: 20.214247 },
    { lat: 48.827527, lng: 20.213998 },
    { lat: 48.826862, lng: 20.212989 },
    { lat: 48.826224, lng: 20.211903 },
    { lat: 48.825739, lng: 20.211077 },
    { lat: 48.825238, lng: 20.210204 },
    { lat: 48.824576, lng: 20.208855 },
    { lat: 48.823838, lng: 20.207401 },
    { lat: 48.823127, lng: 20.206002 },
    { lat: 48.822607, lng: 20.204979 },
    { lat: 48.822013, lng: 20.203886 },
    { lat: 48.821553, lng: 20.203033 },
    { lat: 48.821467, lng: 20.202872 },
    { lat: 48.820954, lng: 20.20192 },
    { lat: 48.820487, lng: 20.200937 },
    { lat: 48.82045, lng: 20.20086 },
    { lat: 48.820447, lng: 20.200854 },
    { lat: 48.819982, lng: 20.199877 },
    { lat: 48.819834, lng: 20.199592 },
    { lat: 48.819335, lng: 20.198628 },
    { lat: 48.819107, lng: 20.19819 },
    { lat: 48.818796, lng: 20.197591 },
    { lat: 48.818585, lng: 20.197184 },
    { lat: 48.817646, lng: 20.197039 },
    { lat: 48.81669, lng: 20.196894 },
    { lat: 48.815263, lng: 20.196719 },
    { lat: 48.81271, lng: 20.197473 },
    { lat: 48.81145, lng: 20.197565 },
    { lat: 48.809958, lng: 20.198403 },
    { lat: 48.809764, lng: 20.198553 },
    { lat: 48.808415, lng: 20.199595 },
    { lat: 48.80719, lng: 20.200518 },
    { lat: 48.806976, lng: 20.200679 },
    { lat: 48.806332, lng: 20.20032 },
    { lat: 48.805159, lng: 20.198811 },
    { lat: 48.803709, lng: 20.196995 },
    { lat: 48.801464, lng: 20.19532 },
    { lat: 48.800391, lng: 20.195522 },
    { lat: 48.799512, lng: 20.196099 },
    { lat: 48.798424, lng: 20.197786 },
    { lat: 48.797898, lng: 20.198507 },
    { lat: 48.797871, lng: 20.198545 },
    { lat: 48.796452, lng: 20.200378 },
    { lat: 48.795682, lng: 20.20003 },
    { lat: 48.795455, lng: 20.199928 },
    { lat: 48.795383, lng: 20.199895 },
    { lat: 48.795268, lng: 20.199844 },
    { lat: 48.793538, lng: 20.197953 },
    { lat: 48.793168, lng: 20.197099 },
    { lat: 48.793109, lng: 20.197035 },
    { lat: 48.792561, lng: 20.19643 },
    { lat: 48.792034, lng: 20.195513 },
    { lat: 48.791533, lng: 20.194765 },
    { lat: 48.790588, lng: 20.194319 },
    { lat: 48.790105, lng: 20.194794 },
    { lat: 48.78937, lng: 20.195454 },
    { lat: 48.787609, lng: 20.197034 },
    { lat: 48.787368, lng: 20.197263 },
    { lat: 48.78667, lng: 20.197847 },
    { lat: 48.786075, lng: 20.198413 },
    { lat: 48.785442, lng: 20.198979 },
    { lat: 48.785446, lng: 20.19901 },
    { lat: 48.785139, lng: 20.199087 },
    { lat: 48.784862, lng: 20.199323 },
    { lat: 48.783634, lng: 20.199751 },
    { lat: 48.782553, lng: 20.200456 },
    { lat: 48.782005, lng: 20.200492 },
    { lat: 48.781903, lng: 20.200372 },
    { lat: 48.781359, lng: 20.200387 },
    { lat: 48.780617, lng: 20.20056 },
    { lat: 48.780409, lng: 20.200565 },
    { lat: 48.780409, lng: 20.200545 },
    { lat: 48.779567, lng: 20.201088 },
    { lat: 48.778177, lng: 20.20245 },
    { lat: 48.778176, lng: 20.202451 },
    { lat: 48.777979, lng: 20.202647 },
    { lat: 48.776656, lng: 20.20385 },
    { lat: 48.7751921, lng: 20.2058317 },
    { lat: 48.774643, lng: 20.206575 },
    { lat: 48.773564, lng: 20.20763 },
    { lat: 48.7716063, lng: 20.2113584 },
    { lat: 48.7700249, lng: 20.2141798 },
    { lat: 48.7693775, lng: 20.2151111 },
    { lat: 48.7684187, lng: 20.2162618 },
    { lat: 48.7676225, lng: 20.2170785 },
    { lat: 48.7665777, lng: 20.2204207 },
    { lat: 48.7664378, lng: 20.2213763 },
    { lat: 48.765063, lng: 20.2265876 },
    { lat: 48.7635858, lng: 20.228804 },
    { lat: 48.7626383, lng: 20.230382 },
    { lat: 48.7618434, lng: 20.2315416 },
    { lat: 48.7604416, lng: 20.2339343 },
    { lat: 48.759503, lng: 20.2358155 },
    { lat: 48.7598298, lng: 20.2376776 },
    { lat: 48.7598406, lng: 20.237739 },
    { lat: 48.7597899, lng: 20.2385368 },
    { lat: 48.7597969, lng: 20.2387812 },
    { lat: 48.7598554, lng: 20.2408338 },
    { lat: 48.7597367, lng: 20.2415913 },
    { lat: 48.7596815, lng: 20.2423591 },
    { lat: 48.7596202, lng: 20.2454433 },
    { lat: 48.7595377, lng: 20.2463446 },
    { lat: 48.7591986, lng: 20.2477913 },
    { lat: 48.7587997, lng: 20.2484678 },
    { lat: 48.7585467, lng: 20.2492731 },
    { lat: 48.7581242, lng: 20.2503497 },
    { lat: 48.7577124, lng: 20.2510409 },
    { lat: 48.7572577, lng: 20.2520129 },
    { lat: 48.7567762, lng: 20.2533332 },
    { lat: 48.7557816, lng: 20.2563788 },
    { lat: 48.7554833, lng: 20.2570939 },
    { lat: 48.7554296, lng: 20.2582659 },
  ],
  DlháVes: [
    { lat: 48.5160884, lng: 20.4637712 },
    { lat: 48.5156928, lng: 20.4620102 },
    { lat: 48.5153297, lng: 20.4600717 },
    { lat: 48.5141991, lng: 20.4571337 },
    { lat: 48.5153084, lng: 20.4552116 },
    { lat: 48.517357, lng: 20.4537633 },
    { lat: 48.5179459, lng: 20.4524883 },
    { lat: 48.5180151, lng: 20.4509085 },
    { lat: 48.5175911, lng: 20.4490246 },
    { lat: 48.5160316, lng: 20.4467801 },
    { lat: 48.5146973, lng: 20.4453026 },
    { lat: 48.5146295, lng: 20.4439674 },
    { lat: 48.5143063, lng: 20.4429905 },
    { lat: 48.5148019, lng: 20.4422889 },
    { lat: 48.5144028, lng: 20.4383761 },
    { lat: 48.514433, lng: 20.4340035 },
    { lat: 48.5147871, lng: 20.4321931 },
    { lat: 48.5149097, lng: 20.4301516 },
    { lat: 48.5149926, lng: 20.4267838 },
    { lat: 48.5149571, lng: 20.4264733 },
    { lat: 48.515128, lng: 20.4257037 },
    { lat: 48.5158908, lng: 20.4238333 },
    { lat: 48.5142043, lng: 20.4188417 },
    { lat: 48.5142905, lng: 20.4187387 },
    { lat: 48.5141546, lng: 20.4180756 },
    { lat: 48.5141118, lng: 20.4175185 },
    { lat: 48.5139359, lng: 20.4167152 },
    { lat: 48.5139017, lng: 20.416353 },
    { lat: 48.5137587, lng: 20.4157979 },
    { lat: 48.5134543, lng: 20.4150536 },
    { lat: 48.5134199, lng: 20.4150992 },
    { lat: 48.5110175, lng: 20.4182568 },
    { lat: 48.508659, lng: 20.4187133 },
    { lat: 48.5063237, lng: 20.4192523 },
    { lat: 48.5061186, lng: 20.4193056 },
    { lat: 48.5051311, lng: 20.419571 },
    { lat: 48.5037731, lng: 20.420132 },
    { lat: 48.503689, lng: 20.4202288 },
    { lat: 48.5034187, lng: 20.4203418 },
    { lat: 48.5027686, lng: 20.4215121 },
    { lat: 48.5021896, lng: 20.422945 },
    { lat: 48.5010361, lng: 20.4251726 },
    { lat: 48.5009115, lng: 20.4255907 },
    { lat: 48.5003741, lng: 20.4262363 },
    { lat: 48.499965, lng: 20.4265473 },
    { lat: 48.4991367, lng: 20.427032 },
    { lat: 48.4988221, lng: 20.4271125 },
    { lat: 48.4970554, lng: 20.4279699 },
    { lat: 48.4966054, lng: 20.4280373 },
    { lat: 48.4961282, lng: 20.4282835 },
    { lat: 48.4951838, lng: 20.4292312 },
    { lat: 48.4945331, lng: 20.4301215 },
    { lat: 48.4941785, lng: 20.430444 },
    { lat: 48.4933865, lng: 20.4314374 },
    { lat: 48.4927738, lng: 20.4317301 },
    { lat: 48.4916686, lng: 20.4320398 },
    { lat: 48.4916858, lng: 20.432077 },
    { lat: 48.4914494, lng: 20.4322004 },
    { lat: 48.4913341, lng: 20.4321603 },
    { lat: 48.4911813, lng: 20.432226 },
    { lat: 48.4910809, lng: 20.4320739 },
    { lat: 48.4908651, lng: 20.4321448 },
    { lat: 48.4905002, lng: 20.4325807 },
    { lat: 48.4905577, lng: 20.4326723 },
    { lat: 48.490186, lng: 20.4328184 },
    { lat: 48.4899925, lng: 20.4329728 },
    { lat: 48.4899387, lng: 20.4328312 },
    { lat: 48.4886179, lng: 20.4334212 },
    { lat: 48.4882832, lng: 20.4331768 },
    { lat: 48.487198, lng: 20.4343388 },
    { lat: 48.4864743, lng: 20.436528 },
    { lat: 48.4856862, lng: 20.4378867 },
    { lat: 48.4849951, lng: 20.4406388 },
    { lat: 48.4848907, lng: 20.4418607 },
    { lat: 48.4846158, lng: 20.4437739 },
    { lat: 48.4842106, lng: 20.4446934 },
    { lat: 48.4837653, lng: 20.4459808 },
    { lat: 48.4828373, lng: 20.4477536 },
    { lat: 48.4827168, lng: 20.4479301 },
    { lat: 48.481209, lng: 20.4490815 },
    { lat: 48.4793787, lng: 20.4502564 },
    { lat: 48.4786526, lng: 20.4508496 },
    { lat: 48.4773419, lng: 20.4523916 },
    { lat: 48.4763791, lng: 20.4531335 },
    { lat: 48.4742218, lng: 20.4553573 },
    { lat: 48.4739679, lng: 20.4555509 },
    { lat: 48.473565, lng: 20.455592 },
    { lat: 48.473445, lng: 20.455811 },
    { lat: 48.4758824, lng: 20.4568562 },
    { lat: 48.4771654, lng: 20.4576072 },
    { lat: 48.4775908, lng: 20.4577814 },
    { lat: 48.4779617, lng: 20.4578539 },
    { lat: 48.4794644, lng: 20.4586631 },
    { lat: 48.48025, lng: 20.4594842 },
    { lat: 48.4816112, lng: 20.4606375 },
    { lat: 48.483105, lng: 20.4611239 },
    { lat: 48.4850263, lng: 20.4622263 },
    { lat: 48.4862991, lng: 20.4632468 },
    { lat: 48.4879909, lng: 20.4653091 },
    { lat: 48.4893295, lng: 20.465956 },
    { lat: 48.4905526, lng: 20.4657519 },
    { lat: 48.4914989, lng: 20.4656746 },
    { lat: 48.492861, lng: 20.465777 },
    { lat: 48.4928776, lng: 20.4661271 },
    { lat: 48.4930258, lng: 20.4661452 },
    { lat: 48.493047, lng: 20.4663205 },
    { lat: 48.4933063, lng: 20.4663554 },
    { lat: 48.4933268, lng: 20.4666913 },
    { lat: 48.4951812, lng: 20.4666216 },
    { lat: 48.4956837, lng: 20.4667489 },
    { lat: 48.498816, lng: 20.4678661 },
    { lat: 48.5012868, lng: 20.4673703 },
    { lat: 48.5038374, lng: 20.4670814 },
    { lat: 48.5051633, lng: 20.4661725 },
    { lat: 48.5059033, lng: 20.4648654 },
    { lat: 48.507189, lng: 20.4644471 },
    { lat: 48.5076123, lng: 20.4645041 },
    { lat: 48.5082345, lng: 20.4644343 },
    { lat: 48.5103468, lng: 20.4630024 },
    { lat: 48.5133358, lng: 20.4628691 },
    { lat: 48.5146584, lng: 20.4630932 },
    { lat: 48.5153137, lng: 20.4632983 },
    { lat: 48.5157974, lng: 20.4636094 },
    { lat: 48.5160884, lng: 20.4637712 },
  ],
  RožňavskéBystré: [
    { lat: 48.6576007, lng: 20.4234315 },
    { lat: 48.6560506, lng: 20.4244495 },
    { lat: 48.6556687, lng: 20.4245006 },
    { lat: 48.6549938, lng: 20.4243135 },
    { lat: 48.6547291, lng: 20.4243394 },
    { lat: 48.6544038, lng: 20.4244926 },
    { lat: 48.6538476, lng: 20.4250848 },
    { lat: 48.6525193, lng: 20.4262445 },
    { lat: 48.6520756, lng: 20.4275174 },
    { lat: 48.6519756, lng: 20.4287608 },
    { lat: 48.6520935, lng: 20.4298179 },
    { lat: 48.6518376, lng: 20.4301916 },
    { lat: 48.6530325, lng: 20.4305191 },
    { lat: 48.6532513, lng: 20.4304107 },
    { lat: 48.6537809, lng: 20.4310126 },
    { lat: 48.654911, lng: 20.4319761 },
    { lat: 48.6555013, lng: 20.4326917 },
    { lat: 48.6557147, lng: 20.4330906 },
    { lat: 48.6559909, lng: 20.433931 },
    { lat: 48.6565163, lng: 20.4346294 },
    { lat: 48.6568767, lng: 20.4355726 },
    { lat: 48.6569781, lng: 20.4360496 },
    { lat: 48.6577223, lng: 20.4381738 },
    { lat: 48.6578314, lng: 20.4387342 },
    { lat: 48.6581284, lng: 20.4395885 },
    { lat: 48.658354, lng: 20.4400106 },
    { lat: 48.6585307, lng: 20.4402161 },
    { lat: 48.6585117, lng: 20.4403371 },
    { lat: 48.6591532, lng: 20.4414256 },
    { lat: 48.6594655, lng: 20.442056 },
    { lat: 48.6603448, lng: 20.4447488 },
    { lat: 48.6608781, lng: 20.4461363 },
    { lat: 48.6610821, lng: 20.4462569 },
    { lat: 48.6612079, lng: 20.4464245 },
    { lat: 48.6614303, lng: 20.4465042 },
    { lat: 48.6617904, lng: 20.44697 },
    { lat: 48.6620094, lng: 20.4473995 },
    { lat: 48.6621948, lng: 20.4476278 },
    { lat: 48.6634793, lng: 20.4488026 },
    { lat: 48.6638892, lng: 20.4493118 },
    { lat: 48.6648638, lng: 20.4500368 },
    { lat: 48.6650753, lng: 20.4501208 },
    { lat: 48.6655845, lng: 20.4505747 },
    { lat: 48.6656279, lng: 20.4506476 },
    { lat: 48.6655954, lng: 20.4507747 },
    { lat: 48.6659209, lng: 20.4506842 },
    { lat: 48.6661025, lng: 20.4507501 },
    { lat: 48.6663022, lng: 20.450735 },
    { lat: 48.6663332, lng: 20.4505439 },
    { lat: 48.6666451, lng: 20.4502437 },
    { lat: 48.6673758, lng: 20.4500362 },
    { lat: 48.6680943, lng: 20.4495035 },
    { lat: 48.6684796, lng: 20.4497132 },
    { lat: 48.6686697, lng: 20.4495318 },
    { lat: 48.668852, lng: 20.449946 },
    { lat: 48.6694206, lng: 20.4505089 },
    { lat: 48.6694299, lng: 20.4507542 },
    { lat: 48.6693235, lng: 20.4507688 },
    { lat: 48.6693195, lng: 20.4508689 },
    { lat: 48.6693756, lng: 20.4508345 },
    { lat: 48.6693455, lng: 20.4509311 },
    { lat: 48.6698019, lng: 20.4509152 },
    { lat: 48.6699046, lng: 20.45157 },
    { lat: 48.6701917, lng: 20.4527885 },
    { lat: 48.6703166, lng: 20.4530256 },
    { lat: 48.6703816, lng: 20.4532833 },
    { lat: 48.6704261, lng: 20.453568 },
    { lat: 48.6704304, lng: 20.4541223 },
    { lat: 48.670507, lng: 20.4541267 },
    { lat: 48.671023, lng: 20.4558264 },
    { lat: 48.6710254, lng: 20.4560702 },
    { lat: 48.6714618, lng: 20.4568576 },
    { lat: 48.6714694, lng: 20.4570903 },
    { lat: 48.6718785, lng: 20.4580796 },
    { lat: 48.6724149, lng: 20.4582368 },
    { lat: 48.6734569, lng: 20.4582206 },
    { lat: 48.6739077, lng: 20.458647 },
    { lat: 48.6744734, lng: 20.4590727 },
    { lat: 48.6748892, lng: 20.4595926 },
    { lat: 48.6750011, lng: 20.4638616 },
    { lat: 48.6760384, lng: 20.4638071 },
    { lat: 48.6762187, lng: 20.4639252 },
    { lat: 48.6764923, lng: 20.4642286 },
    { lat: 48.6772959, lng: 20.4653747 },
    { lat: 48.6779654, lng: 20.466827 },
    { lat: 48.6784335, lng: 20.46765 },
    { lat: 48.6786095, lng: 20.4684059 },
    { lat: 48.6788328, lng: 20.4688887 },
    { lat: 48.6791761, lng: 20.4693961 },
    { lat: 48.6791896, lng: 20.4695161 },
    { lat: 48.6793998, lng: 20.469864 },
    { lat: 48.6801736, lng: 20.470825 },
    { lat: 48.6802685, lng: 20.4711328 },
    { lat: 48.6813302, lng: 20.4729817 },
    { lat: 48.6813393, lng: 20.4730094 },
    { lat: 48.6816395, lng: 20.4725795 },
    { lat: 48.6833923, lng: 20.4718593 },
    { lat: 48.68406, lng: 20.4715256 },
    { lat: 48.6840877, lng: 20.4708601 },
    { lat: 48.6840708, lng: 20.470466 },
    { lat: 48.6839647, lng: 20.4699242 },
    { lat: 48.6839269, lng: 20.468892 },
    { lat: 48.6839317, lng: 20.4680082 },
    { lat: 48.6840431, lng: 20.4670521 },
    { lat: 48.6842653, lng: 20.4662565 },
    { lat: 48.6844588, lng: 20.4658639 },
    { lat: 48.6845673, lng: 20.4653378 },
    { lat: 48.6847632, lng: 20.4649225 },
    { lat: 48.6848659, lng: 20.4643628 },
    { lat: 48.684816, lng: 20.4637541 },
    { lat: 48.6850959, lng: 20.4632685 },
    { lat: 48.6853299, lng: 20.4623675 },
    { lat: 48.6852934, lng: 20.4612228 },
    { lat: 48.6855108, lng: 20.4606011 },
    { lat: 48.6857163, lng: 20.4602309 },
    { lat: 48.6864826, lng: 20.4593826 },
    { lat: 48.6868366, lng: 20.4585046 },
    { lat: 48.6868813, lng: 20.457809 },
    { lat: 48.686483, lng: 20.4557017 },
    { lat: 48.6863159, lng: 20.4544727 },
    { lat: 48.6858621, lng: 20.4530466 },
    { lat: 48.685725, lng: 20.4527851 },
    { lat: 48.6852943, lng: 20.451461 },
    { lat: 48.6849489, lng: 20.45061 },
    { lat: 48.6853502, lng: 20.4502667 },
    { lat: 48.685654, lng: 20.4499015 },
    { lat: 48.6853327, lng: 20.4488834 },
    { lat: 48.685252, lng: 20.4480319 },
    { lat: 48.6852003, lng: 20.4477089 },
    { lat: 48.6851512, lng: 20.4477185 },
    { lat: 48.6851582, lng: 20.4473458 },
    { lat: 48.6850332, lng: 20.4460663 },
    { lat: 48.6847471, lng: 20.4456203 },
    { lat: 48.6842804, lng: 20.4446822 },
    { lat: 48.6842344, lng: 20.4442411 },
    { lat: 48.6841598, lng: 20.4439678 },
    { lat: 48.6836814, lng: 20.4433233 },
    { lat: 48.6835115, lng: 20.4428826 },
    { lat: 48.6832326, lng: 20.44271 },
    { lat: 48.6831246, lng: 20.442357 },
    { lat: 48.6829413, lng: 20.4405566 },
    { lat: 48.6831328, lng: 20.4391698 },
    { lat: 48.6835692, lng: 20.4380002 },
    { lat: 48.6841182, lng: 20.4370364 },
    { lat: 48.6852753, lng: 20.4356033 },
    { lat: 48.6858182, lng: 20.4351923 },
    { lat: 48.686481, lng: 20.4344741 },
    { lat: 48.6864873, lng: 20.4344653 },
    { lat: 48.687135, lng: 20.4335791 },
    { lat: 48.688915, lng: 20.4316793 },
    { lat: 48.6892961, lng: 20.430391 },
    { lat: 48.6891055, lng: 20.4284043 },
    { lat: 48.6883962, lng: 20.42693 },
    { lat: 48.6886272, lng: 20.4263122 },
    { lat: 48.6889434, lng: 20.4258353 },
    { lat: 48.6882972, lng: 20.4247735 },
    { lat: 48.6880619, lng: 20.4243645 },
    { lat: 48.687046, lng: 20.423237 },
    { lat: 48.6867387, lng: 20.4227698 },
    { lat: 48.6854913, lng: 20.4223035 },
    { lat: 48.685403, lng: 20.4221733 },
    { lat: 48.6846807, lng: 20.4219806 },
    { lat: 48.6836597, lng: 20.4220161 },
    { lat: 48.6828686, lng: 20.4217111 },
    { lat: 48.6811669, lng: 20.4206409 },
    { lat: 48.6801222, lng: 20.4209435 },
    { lat: 48.6796398, lng: 20.4209288 },
    { lat: 48.6790112, lng: 20.4211575 },
    { lat: 48.6785446, lng: 20.4210848 },
    { lat: 48.6781796, lng: 20.4212605 },
    { lat: 48.6781221, lng: 20.421434 },
    { lat: 48.6779228, lng: 20.4214916 },
    { lat: 48.6777475, lng: 20.4216854 },
    { lat: 48.6775714, lng: 20.4216715 },
    { lat: 48.6776199, lng: 20.4217909 },
    { lat: 48.6773907, lng: 20.4219256 },
    { lat: 48.6767519, lng: 20.4225599 },
    { lat: 48.676654, lng: 20.4228014 },
    { lat: 48.6765164, lng: 20.4228248 },
    { lat: 48.6764619, lng: 20.4229304 },
    { lat: 48.6761214, lng: 20.4231834 },
    { lat: 48.6756824, lng: 20.4233929 },
    { lat: 48.6755497, lng: 20.4237547 },
    { lat: 48.6752654, lng: 20.4237128 },
    { lat: 48.6749678, lng: 20.423525 },
    { lat: 48.6746718, lng: 20.4235589 },
    { lat: 48.674671, lng: 20.4237115 },
    { lat: 48.6742557, lng: 20.4238342 },
    { lat: 48.6741636, lng: 20.4239929 },
    { lat: 48.6740342, lng: 20.4240832 },
    { lat: 48.6739826, lng: 20.4242349 },
    { lat: 48.6736215, lng: 20.424392 },
    { lat: 48.6733616, lng: 20.4247232 },
    { lat: 48.6731407, lng: 20.4248653 },
    { lat: 48.6730526, lng: 20.4250625 },
    { lat: 48.6731462, lng: 20.4255421 },
    { lat: 48.6729547, lng: 20.4256005 },
    { lat: 48.6728248, lng: 20.4258209 },
    { lat: 48.6726504, lng: 20.4259739 },
    { lat: 48.6722205, lng: 20.425478 },
    { lat: 48.6714963, lng: 20.4255327 },
    { lat: 48.6713957, lng: 20.4256979 },
    { lat: 48.6713789, lng: 20.426057 },
    { lat: 48.67127, lng: 20.4263886 },
    { lat: 48.6711319, lng: 20.4263974 },
    { lat: 48.67079, lng: 20.4266772 },
    { lat: 48.6705929, lng: 20.4270328 },
    { lat: 48.6705519, lng: 20.4272284 },
    { lat: 48.6701944, lng: 20.4273873 },
    { lat: 48.6700668, lng: 20.4276421 },
    { lat: 48.6699434, lng: 20.4275687 },
    { lat: 48.6697841, lng: 20.4278769 },
    { lat: 48.6693429, lng: 20.4280645 },
    { lat: 48.6692656, lng: 20.4282532 },
    { lat: 48.6685707, lng: 20.4289584 },
    { lat: 48.6680653, lng: 20.4296094 },
    { lat: 48.6680076, lng: 20.4299279 },
    { lat: 48.667695, lng: 20.4299657 },
    { lat: 48.6676505, lng: 20.4303408 },
    { lat: 48.6673975, lng: 20.4303752 },
    { lat: 48.6671891, lng: 20.4306099 },
    { lat: 48.6672009, lng: 20.4305041 },
    { lat: 48.6667528, lng: 20.4298302 },
    { lat: 48.6667536, lng: 20.4297326 },
    { lat: 48.6663776, lng: 20.4293221 },
    { lat: 48.6661807, lng: 20.4288233 },
    { lat: 48.6659673, lng: 20.4280717 },
    { lat: 48.6650125, lng: 20.4270974 },
    { lat: 48.6644844, lng: 20.4267451 },
    { lat: 48.6642345, lng: 20.4266581 },
    { lat: 48.6639516, lng: 20.426205 },
    { lat: 48.6636417, lng: 20.4258775 },
    { lat: 48.6636071, lng: 20.4256757 },
    { lat: 48.6627426, lng: 20.4255639 },
    { lat: 48.6624892, lng: 20.4254776 },
    { lat: 48.6620024, lng: 20.425615 },
    { lat: 48.6617463, lng: 20.4253082 },
    { lat: 48.660002, lng: 20.4251588 },
    { lat: 48.6586505, lng: 20.4241772 },
    { lat: 48.6585504, lng: 20.4240359 },
    { lat: 48.6576007, lng: 20.4234315 },
  ],
  Honce: [
    { lat: 48.69205, lng: 20.3987712 },
    { lat: 48.6916246, lng: 20.3988654 },
    { lat: 48.6907367, lng: 20.3999065 },
    { lat: 48.6904887, lng: 20.3998765 },
    { lat: 48.6897247, lng: 20.4000688 },
    { lat: 48.6893329, lng: 20.4002741 },
    { lat: 48.6890867, lng: 20.4002522 },
    { lat: 48.6887856, lng: 20.4006841 },
    { lat: 48.6886454, lng: 20.4006636 },
    { lat: 48.6883391, lng: 20.4008126 },
    { lat: 48.687128, lng: 20.4010059 },
    { lat: 48.6851979, lng: 20.40121 },
    { lat: 48.6838986, lng: 20.4016828 },
    { lat: 48.6834616, lng: 20.4019469 },
    { lat: 48.6827824, lng: 20.4015028 },
    { lat: 48.6820163, lng: 20.4005196 },
    { lat: 48.6817984, lng: 20.4001838 },
    { lat: 48.6816815, lng: 20.3998387 },
    { lat: 48.6815388, lng: 20.3996663 },
    { lat: 48.6809927, lng: 20.3993769 },
    { lat: 48.680801, lng: 20.3992194 },
    { lat: 48.6807294, lng: 20.3990871 },
    { lat: 48.6800882, lng: 20.3989255 },
    { lat: 48.6795307, lng: 20.3983671 },
    { lat: 48.6790878, lng: 20.3980905 },
    { lat: 48.678632, lng: 20.3979287 },
    { lat: 48.6780475, lng: 20.3973926 },
    { lat: 48.6779844, lng: 20.3971882 },
    { lat: 48.6781447, lng: 20.3966739 },
    { lat: 48.6780877, lng: 20.3961836 },
    { lat: 48.6778983, lng: 20.395939 },
    { lat: 48.6778245, lng: 20.3956651 },
    { lat: 48.6777157, lng: 20.3949705 },
    { lat: 48.6777187, lng: 20.3944037 },
    { lat: 48.6776558, lng: 20.3937618 },
    { lat: 48.6775147, lng: 20.3930237 },
    { lat: 48.6773892, lng: 20.3928035 },
    { lat: 48.6769469, lng: 20.3924581 },
    { lat: 48.6765937, lng: 20.3919995 },
    { lat: 48.6764027, lng: 20.3916574 },
    { lat: 48.6759907, lng: 20.3912527 },
    { lat: 48.6748923, lng: 20.3905655 },
    { lat: 48.6745717, lng: 20.3908081 },
    { lat: 48.67404, lng: 20.3908604 },
    { lat: 48.6730625, lng: 20.3904537 },
    { lat: 48.6727254, lng: 20.3899691 },
    { lat: 48.67199, lng: 20.389322 },
    { lat: 48.6717594, lng: 20.3889767 },
    { lat: 48.6715233, lng: 20.3887847 },
    { lat: 48.6713975, lng: 20.3884395 },
    { lat: 48.6710432, lng: 20.3884787 },
    { lat: 48.6706315, lng: 20.3886727 },
    { lat: 48.6704726, lng: 20.3888416 },
    { lat: 48.6701809, lng: 20.3889872 },
    { lat: 48.6697732, lng: 20.3890742 },
    { lat: 48.6695071, lng: 20.3892119 },
    { lat: 48.6682801, lng: 20.3893954 },
    { lat: 48.6671603, lng: 20.3897657 },
    { lat: 48.6651875, lng: 20.3911138 },
    { lat: 48.6642006, lng: 20.391657 },
    { lat: 48.6641447, lng: 20.3918172 },
    { lat: 48.6633956, lng: 20.3925707 },
    { lat: 48.663236, lng: 20.3937221 },
    { lat: 48.663414, lng: 20.3940193 },
    { lat: 48.6638359, lng: 20.3944743 },
    { lat: 48.663798, lng: 20.3947198 },
    { lat: 48.6636291, lng: 20.3950117 },
    { lat: 48.6632259, lng: 20.3949277 },
    { lat: 48.6630026, lng: 20.3959696 },
    { lat: 48.6626653, lng: 20.3961024 },
    { lat: 48.6625056, lng: 20.3965139 },
    { lat: 48.6625686, lng: 20.396544 },
    { lat: 48.6622153, lng: 20.3973116 },
    { lat: 48.6619714, lng: 20.398206 },
    { lat: 48.6615645, lng: 20.3989896 },
    { lat: 48.6612917, lng: 20.3993017 },
    { lat: 48.6607264, lng: 20.4001533 },
    { lat: 48.6600078, lng: 20.4007365 },
    { lat: 48.6596314, lng: 20.4014351 },
    { lat: 48.6579804, lng: 20.4036025 },
    { lat: 48.6570958, lng: 20.4044958 },
    { lat: 48.6556977, lng: 20.407063 },
    { lat: 48.6554568, lng: 20.4072742 },
    { lat: 48.6552265, lng: 20.4073171 },
    { lat: 48.6547747, lng: 20.4076329 },
    { lat: 48.6538525, lng: 20.4084883 },
    { lat: 48.6535849, lng: 20.4083457 },
    { lat: 48.653493, lng: 20.4100369 },
    { lat: 48.6537157, lng: 20.4102946 },
    { lat: 48.6539116, lng: 20.4103811 },
    { lat: 48.6542154, lng: 20.4109326 },
    { lat: 48.654935, lng: 20.4116082 },
    { lat: 48.6551533, lng: 20.4119916 },
    { lat: 48.6552446, lng: 20.4122558 },
    { lat: 48.6553095, lng: 20.4134842 },
    { lat: 48.6551586, lng: 20.414074 },
    { lat: 48.6550419, lng: 20.4151993 },
    { lat: 48.6548889, lng: 20.4159659 },
    { lat: 48.6549833, lng: 20.4167326 },
    { lat: 48.6549981, lng: 20.4173243 },
    { lat: 48.6554366, lng: 20.4185099 },
    { lat: 48.6562568, lng: 20.4197029 },
    { lat: 48.6567837, lng: 20.4209994 },
    { lat: 48.6571598, lng: 20.4217944 },
    { lat: 48.6572294, lng: 20.4221096 },
    { lat: 48.657633, lng: 20.4226266 },
    { lat: 48.6576841, lng: 20.4232611 },
    { lat: 48.6576007, lng: 20.4234315 },
    { lat: 48.6585504, lng: 20.4240359 },
    { lat: 48.6586505, lng: 20.4241772 },
    { lat: 48.660002, lng: 20.4251588 },
    { lat: 48.6617463, lng: 20.4253082 },
    { lat: 48.6620024, lng: 20.425615 },
    { lat: 48.6624892, lng: 20.4254776 },
    { lat: 48.6627426, lng: 20.4255639 },
    { lat: 48.6636071, lng: 20.4256757 },
    { lat: 48.6636417, lng: 20.4258775 },
    { lat: 48.6639516, lng: 20.426205 },
    { lat: 48.6642345, lng: 20.4266581 },
    { lat: 48.6644844, lng: 20.4267451 },
    { lat: 48.6650125, lng: 20.4270974 },
    { lat: 48.6659673, lng: 20.4280717 },
    { lat: 48.6661807, lng: 20.4288233 },
    { lat: 48.6663776, lng: 20.4293221 },
    { lat: 48.6667536, lng: 20.4297326 },
    { lat: 48.6667528, lng: 20.4298302 },
    { lat: 48.6672009, lng: 20.4305041 },
    { lat: 48.6671891, lng: 20.4306099 },
    { lat: 48.6673975, lng: 20.4303752 },
    { lat: 48.6676505, lng: 20.4303408 },
    { lat: 48.667695, lng: 20.4299657 },
    { lat: 48.6680076, lng: 20.4299279 },
    { lat: 48.6680653, lng: 20.4296094 },
    { lat: 48.6685707, lng: 20.4289584 },
    { lat: 48.6692656, lng: 20.4282532 },
    { lat: 48.6693429, lng: 20.4280645 },
    { lat: 48.6697841, lng: 20.4278769 },
    { lat: 48.6699434, lng: 20.4275687 },
    { lat: 48.6700668, lng: 20.4276421 },
    { lat: 48.6701944, lng: 20.4273873 },
    { lat: 48.6705519, lng: 20.4272284 },
    { lat: 48.6705929, lng: 20.4270328 },
    { lat: 48.67079, lng: 20.4266772 },
    { lat: 48.6711319, lng: 20.4263974 },
    { lat: 48.67127, lng: 20.4263886 },
    { lat: 48.6713789, lng: 20.426057 },
    { lat: 48.6713957, lng: 20.4256979 },
    { lat: 48.6714963, lng: 20.4255327 },
    { lat: 48.6722205, lng: 20.425478 },
    { lat: 48.6726504, lng: 20.4259739 },
    { lat: 48.6728248, lng: 20.4258209 },
    { lat: 48.6729547, lng: 20.4256005 },
    { lat: 48.6731462, lng: 20.4255421 },
    { lat: 48.6730526, lng: 20.4250625 },
    { lat: 48.6731407, lng: 20.4248653 },
    { lat: 48.6733616, lng: 20.4247232 },
    { lat: 48.6736215, lng: 20.424392 },
    { lat: 48.6739826, lng: 20.4242349 },
    { lat: 48.6740342, lng: 20.4240832 },
    { lat: 48.6741636, lng: 20.4239929 },
    { lat: 48.6742557, lng: 20.4238342 },
    { lat: 48.674671, lng: 20.4237115 },
    { lat: 48.6746718, lng: 20.4235589 },
    { lat: 48.6749678, lng: 20.423525 },
    { lat: 48.6752654, lng: 20.4237128 },
    { lat: 48.6755497, lng: 20.4237547 },
    { lat: 48.6756824, lng: 20.4233929 },
    { lat: 48.6761214, lng: 20.4231834 },
    { lat: 48.6764619, lng: 20.4229304 },
    { lat: 48.6765164, lng: 20.4228248 },
    { lat: 48.676654, lng: 20.4228014 },
    { lat: 48.6767519, lng: 20.4225599 },
    { lat: 48.6773907, lng: 20.4219256 },
    { lat: 48.6776199, lng: 20.4217909 },
    { lat: 48.6775714, lng: 20.4216715 },
    { lat: 48.6777475, lng: 20.4216854 },
    { lat: 48.6779228, lng: 20.4214916 },
    { lat: 48.6781221, lng: 20.421434 },
    { lat: 48.6781796, lng: 20.4212605 },
    { lat: 48.6785446, lng: 20.4210848 },
    { lat: 48.6790112, lng: 20.4211575 },
    { lat: 48.6796398, lng: 20.4209288 },
    { lat: 48.6801222, lng: 20.4209435 },
    { lat: 48.6811669, lng: 20.4206409 },
    { lat: 48.6828686, lng: 20.4217111 },
    { lat: 48.6836597, lng: 20.4220161 },
    { lat: 48.6846807, lng: 20.4219806 },
    { lat: 48.685403, lng: 20.4221733 },
    { lat: 48.6854913, lng: 20.4223035 },
    { lat: 48.6867387, lng: 20.4227698 },
    { lat: 48.687046, lng: 20.423237 },
    { lat: 48.6880619, lng: 20.4243645 },
    { lat: 48.6882972, lng: 20.4247735 },
    { lat: 48.6889434, lng: 20.4258353 },
    { lat: 48.6896266, lng: 20.4245028 },
    { lat: 48.6896507, lng: 20.4240706 },
    { lat: 48.6897725, lng: 20.4236316 },
    { lat: 48.6899631, lng: 20.4225469 },
    { lat: 48.6902801, lng: 20.4215299 },
    { lat: 48.6905615, lng: 20.4203457 },
    { lat: 48.6906502, lng: 20.4195428 },
    { lat: 48.6906812, lng: 20.4183209 },
    { lat: 48.6906058, lng: 20.4172857 },
    { lat: 48.6906552, lng: 20.4148977 },
    { lat: 48.6903836, lng: 20.4139798 },
    { lat: 48.690372, lng: 20.4137366 },
    { lat: 48.6899457, lng: 20.4123938 },
    { lat: 48.6898016, lng: 20.4113664 },
    { lat: 48.6898964, lng: 20.4099825 },
    { lat: 48.6898995, lng: 20.407888 },
    { lat: 48.6907659, lng: 20.4061354 },
    { lat: 48.6912753, lng: 20.4056162 },
    { lat: 48.691884, lng: 20.4055152 },
    { lat: 48.6919102, lng: 20.4054976 },
    { lat: 48.6919487, lng: 20.4042364 },
    { lat: 48.6918732, lng: 20.4033555 },
    { lat: 48.6917726, lng: 20.4028176 },
    { lat: 48.6916823, lng: 20.4010473 },
    { lat: 48.6915921, lng: 20.4005705 },
    { lat: 48.6915792, lng: 20.3999703 },
    { lat: 48.6917493, lng: 20.3992997 },
    { lat: 48.69205, lng: 20.3987712 },
  ],
  NižnáSlaná: [
    { lat: 48.753254, lng: 20.389108 },
    { lat: 48.7532005, lng: 20.3890613 },
    { lat: 48.7529775, lng: 20.3891227 },
    { lat: 48.7527238, lng: 20.3893191 },
    { lat: 48.7512995, lng: 20.3893496 },
    { lat: 48.7505912, lng: 20.389527 },
    { lat: 48.7496217, lng: 20.3895244 },
    { lat: 48.7490088, lng: 20.389631 },
    { lat: 48.7486348, lng: 20.3898958 },
    { lat: 48.7481544, lng: 20.3900261 },
    { lat: 48.7477495, lng: 20.3900081 },
    { lat: 48.746497, lng: 20.3896756 },
    { lat: 48.7461673, lng: 20.3896813 },
    { lat: 48.7457194, lng: 20.389568 },
    { lat: 48.7452957, lng: 20.3901172 },
    { lat: 48.7450481, lng: 20.3905656 },
    { lat: 48.7447869, lng: 20.390859 },
    { lat: 48.7444318, lng: 20.3910514 },
    { lat: 48.7440088, lng: 20.3911831 },
    { lat: 48.74301, lng: 20.392 },
    { lat: 48.7425781, lng: 20.3914571 },
    { lat: 48.7420893, lng: 20.3906612 },
    { lat: 48.7395891, lng: 20.3871064 },
    { lat: 48.739119, lng: 20.3869761 },
    { lat: 48.7389697, lng: 20.3862392 },
    { lat: 48.7388037, lng: 20.3854202 },
    { lat: 48.7387665, lng: 20.3848027 },
    { lat: 48.7381116, lng: 20.3849056 },
    { lat: 48.7376884, lng: 20.3852039 },
    { lat: 48.737436, lng: 20.3850509 },
    { lat: 48.7371922, lng: 20.3851858 },
    { lat: 48.7368363, lng: 20.3851835 },
    { lat: 48.7364537, lng: 20.3852803 },
    { lat: 48.7363218, lng: 20.3853892 },
    { lat: 48.7359001, lng: 20.3854395 },
    { lat: 48.7358083, lng: 20.3853352 },
    { lat: 48.7353408, lng: 20.3854729 },
    { lat: 48.7350013, lng: 20.3854318 },
    { lat: 48.7346852, lng: 20.385207 },
    { lat: 48.7345724, lng: 20.3852012 },
    { lat: 48.7343417, lng: 20.3853188 },
    { lat: 48.7342137, lng: 20.3852741 },
    { lat: 48.7335931, lng: 20.3853835 },
    { lat: 48.7334449, lng: 20.3855128 },
    { lat: 48.7330906, lng: 20.3856135 },
    { lat: 48.7329811, lng: 20.3857498 },
    { lat: 48.7326755, lng: 20.3859432 },
    { lat: 48.7323834, lng: 20.3859332 },
    { lat: 48.7313193, lng: 20.384439 },
    { lat: 48.7303376, lng: 20.3838033 },
    { lat: 48.7299928, lng: 20.3832993 },
    { lat: 48.7297507, lng: 20.3821884 },
    { lat: 48.7297577, lng: 20.3818119 },
    { lat: 48.7296644, lng: 20.3810863 },
    { lat: 48.7295135, lng: 20.3776643 },
    { lat: 48.7294498, lng: 20.3768235 },
    { lat: 48.729281, lng: 20.3756338 },
    { lat: 48.7293768, lng: 20.3750533 },
    { lat: 48.7291815, lng: 20.374982 },
    { lat: 48.7286391, lng: 20.3744334 },
    { lat: 48.7281179, lng: 20.3740848 },
    { lat: 48.7274404, lng: 20.3738031 },
    { lat: 48.7269316, lng: 20.3736667 },
    { lat: 48.7256463, lng: 20.3732753 },
    { lat: 48.7251072, lng: 20.3731904 },
    { lat: 48.7244554, lng: 20.3731415 },
    { lat: 48.724148, lng: 20.3732289 },
    { lat: 48.7235818, lng: 20.3737895 },
    { lat: 48.7224049, lng: 20.3747455 },
    { lat: 48.7213149, lng: 20.375069 },
    { lat: 48.7211644, lng: 20.3753693 },
    { lat: 48.7196703, lng: 20.3766017 },
    { lat: 48.719337, lng: 20.376691 },
    { lat: 48.7184941, lng: 20.3772063 },
    { lat: 48.7181095, lng: 20.3778307 },
    { lat: 48.7175727, lng: 20.3785961 },
    { lat: 48.7173089, lng: 20.3788843 },
    { lat: 48.7170483, lng: 20.3793658 },
    { lat: 48.7168514, lng: 20.3796081 },
    { lat: 48.7157432, lng: 20.380801 },
    { lat: 48.7154375, lng: 20.3810814 },
    { lat: 48.7151817, lng: 20.3812219 },
    { lat: 48.7152155, lng: 20.3812532 },
    { lat: 48.7150401, lng: 20.38149 },
    { lat: 48.7149471, lng: 20.3815321 },
    { lat: 48.7141966, lng: 20.3824257 },
    { lat: 48.7134037, lng: 20.3851454 },
    { lat: 48.7131546, lng: 20.3855167 },
    { lat: 48.7122287, lng: 20.388176 },
    { lat: 48.7113605, lng: 20.3887628 },
    { lat: 48.7109364, lng: 20.3889818 },
    { lat: 48.7104309, lng: 20.3891585 },
    { lat: 48.7102716, lng: 20.3891439 },
    { lat: 48.7094088, lng: 20.3889027 },
    { lat: 48.7086892, lng: 20.388493 },
    { lat: 48.7081435, lng: 20.3883548 },
    { lat: 48.7076763, lng: 20.3883605 },
    { lat: 48.707383, lng: 20.388465 },
    { lat: 48.7063082, lng: 20.3883311 },
    { lat: 48.7058612, lng: 20.3881276 },
    { lat: 48.7054588, lng: 20.3880631 },
    { lat: 48.7049499, lng: 20.3878863 },
    { lat: 48.7040695, lng: 20.3880641 },
    { lat: 48.7034892, lng: 20.3882052 },
    { lat: 48.7031589, lng: 20.3880153 },
    { lat: 48.7028312, lng: 20.3880476 },
    { lat: 48.7017876, lng: 20.3877931 },
    { lat: 48.7015257, lng: 20.3880378 },
    { lat: 48.7009014, lng: 20.3888137 },
    { lat: 48.7007706, lng: 20.3890744 },
    { lat: 48.7006967, lng: 20.3894377 },
    { lat: 48.7004484, lng: 20.3899507 },
    { lat: 48.7003132, lng: 20.3904185 },
    { lat: 48.6995168, lng: 20.3921367 },
    { lat: 48.699195, lng: 20.3927335 },
    { lat: 48.6987389, lng: 20.3932561 },
    { lat: 48.6984207, lng: 20.3935064 },
    { lat: 48.6972238, lng: 20.3949477 },
    { lat: 48.6966274, lng: 20.3960404 },
    { lat: 48.6963034, lng: 20.3961689 },
    { lat: 48.6959131, lng: 20.3964448 },
    { lat: 48.6942468, lng: 20.3973634 },
    { lat: 48.693652, lng: 20.3977944 },
    { lat: 48.6930235, lng: 20.3979385 },
    { lat: 48.6924343, lng: 20.3982831 },
    { lat: 48.69205, lng: 20.3987712 },
    { lat: 48.6917493, lng: 20.3992997 },
    { lat: 48.6915792, lng: 20.3999703 },
    { lat: 48.6915921, lng: 20.4005705 },
    { lat: 48.6916823, lng: 20.4010473 },
    { lat: 48.6917726, lng: 20.4028176 },
    { lat: 48.6918732, lng: 20.4033555 },
    { lat: 48.6919487, lng: 20.4042364 },
    { lat: 48.6919102, lng: 20.4054976 },
    { lat: 48.695008, lng: 20.4076738 },
    { lat: 48.6955331, lng: 20.4087996 },
    { lat: 48.6964021, lng: 20.4095264 },
    { lat: 48.69655, lng: 20.4097705 },
    { lat: 48.6970996, lng: 20.4101859 },
    { lat: 48.6975858, lng: 20.4103859 },
    { lat: 48.698469, lng: 20.4114288 },
    { lat: 48.6987522, lng: 20.4115905 },
    { lat: 48.6989736, lng: 20.4116104 },
    { lat: 48.6993642, lng: 20.4114893 },
    { lat: 48.6998599, lng: 20.4115794 },
    { lat: 48.699952, lng: 20.4116626 },
    { lat: 48.7000643, lng: 20.4122157 },
    { lat: 48.7011287, lng: 20.4127704 },
    { lat: 48.7028965, lng: 20.4136918 },
    { lat: 48.7035208, lng: 20.4143803 },
    { lat: 48.7039611, lng: 20.4151219 },
    { lat: 48.7041413, lng: 20.4156199 },
    { lat: 48.7047888, lng: 20.4168301 },
    { lat: 48.705287, lng: 20.417551 },
    { lat: 48.7056321, lng: 20.4175961 },
    { lat: 48.7060401, lng: 20.4180486 },
    { lat: 48.707075, lng: 20.41625 },
    { lat: 48.7073717, lng: 20.4163207 },
    { lat: 48.7082353, lng: 20.415906 },
    { lat: 48.7087303, lng: 20.4158065 },
    { lat: 48.7089807, lng: 20.4158189 },
    { lat: 48.7092778, lng: 20.4156354 },
    { lat: 48.7100363, lng: 20.4155083 },
    { lat: 48.7103984, lng: 20.4155869 },
    { lat: 48.7105199, lng: 20.4156729 },
    { lat: 48.7110014, lng: 20.4155076 },
    { lat: 48.7122306, lng: 20.415467 },
    { lat: 48.7130976, lng: 20.4158214 },
    { lat: 48.713221, lng: 20.4161052 },
    { lat: 48.7135001, lng: 20.4163076 },
    { lat: 48.7134135, lng: 20.4164867 },
    { lat: 48.7134105, lng: 20.4166118 },
    { lat: 48.7135038, lng: 20.4169609 },
    { lat: 48.7135597, lng: 20.4170106 },
    { lat: 48.7136036, lng: 20.4172774 },
    { lat: 48.7136377, lng: 20.4178742 },
    { lat: 48.7135937, lng: 20.4183779 },
    { lat: 48.7136392, lng: 20.4188592 },
    { lat: 48.7138849, lng: 20.419337 },
    { lat: 48.7141009, lng: 20.4196288 },
    { lat: 48.7141422, lng: 20.4199457 },
    { lat: 48.7142394, lng: 20.4200357 },
    { lat: 48.7143804, lng: 20.4199595 },
    { lat: 48.7144754, lng: 20.4201185 },
    { lat: 48.7145459, lng: 20.4201045 },
    { lat: 48.7146011, lng: 20.420432 },
    { lat: 48.7145256, lng: 20.4205441 },
    { lat: 48.7148825, lng: 20.4216035 },
    { lat: 48.7151557, lng: 20.4219181 },
    { lat: 48.7152306, lng: 20.4221126 },
    { lat: 48.7152183, lng: 20.4222061 },
    { lat: 48.7154184, lng: 20.422138 },
    { lat: 48.7160649, lng: 20.4232944 },
    { lat: 48.7163945, lng: 20.4243444 },
    { lat: 48.7166276, lng: 20.4248775 },
    { lat: 48.7167564, lng: 20.4256551 },
    { lat: 48.7168111, lng: 20.4257049 },
    { lat: 48.7171875, lng: 20.4255933 },
    { lat: 48.7176459, lng: 20.4251833 },
    { lat: 48.7184048, lng: 20.4273413 },
    { lat: 48.7192733, lng: 20.4274384 },
    { lat: 48.7200197, lng: 20.4282322 },
    { lat: 48.7196111, lng: 20.428589 },
    { lat: 48.7197358, lng: 20.4289059 },
    { lat: 48.7204236, lng: 20.4296438 },
    { lat: 48.7214262, lng: 20.4304264 },
    { lat: 48.7217512, lng: 20.4305879 },
    { lat: 48.7219094, lng: 20.4304565 },
    { lat: 48.7222801, lng: 20.43138 },
    { lat: 48.7243966, lng: 20.4312495 },
    { lat: 48.724508, lng: 20.4316346 },
    { lat: 48.7247526, lng: 20.4319037 },
    { lat: 48.7249299, lng: 20.431981 },
    { lat: 48.7251568, lng: 20.432202 },
    { lat: 48.7252982, lng: 20.4327817 },
    { lat: 48.7255472, lng: 20.432963 },
    { lat: 48.72585, lng: 20.4330505 },
    { lat: 48.7263161, lng: 20.4336662 },
    { lat: 48.7280385, lng: 20.4351351 },
    { lat: 48.7288052, lng: 20.4372353 },
    { lat: 48.7292904, lng: 20.4383324 },
    { lat: 48.730087, lng: 20.439828 },
    { lat: 48.7307636, lng: 20.4413679 },
    { lat: 48.731073, lng: 20.4422756 },
    { lat: 48.731315, lng: 20.4423363 },
    { lat: 48.7314373, lng: 20.442443 },
    { lat: 48.7316496, lng: 20.4431145 },
    { lat: 48.7323157, lng: 20.4438411 },
    { lat: 48.7325738, lng: 20.4442635 },
    { lat: 48.7327642, lng: 20.4447414 },
    { lat: 48.7336218, lng: 20.4465996 },
    { lat: 48.7337864, lng: 20.4464541 },
    { lat: 48.7340625, lng: 20.4464626 },
    { lat: 48.7356664, lng: 20.4470389 },
    { lat: 48.7360911, lng: 20.4472875 },
    { lat: 48.7368326, lng: 20.4473285 },
    { lat: 48.7372779, lng: 20.447759 },
    { lat: 48.738115, lng: 20.4479487 },
    { lat: 48.7383494, lng: 20.4480696 },
    { lat: 48.7387648, lng: 20.4481303 },
    { lat: 48.7391974, lng: 20.4481635 },
    { lat: 48.7411711, lng: 20.447832 },
    { lat: 48.7423811, lng: 20.4474043 },
    { lat: 48.742797, lng: 20.4474147 },
    { lat: 48.7429413, lng: 20.4475976 },
    { lat: 48.7430417, lng: 20.4477249 },
    { lat: 48.7435186, lng: 20.4477699 },
    { lat: 48.7439294, lng: 20.4475267 },
    { lat: 48.7442159, lng: 20.4474801 },
    { lat: 48.7445833, lng: 20.4472495 },
    { lat: 48.7447172, lng: 20.446087 },
    { lat: 48.7441268, lng: 20.4459002 },
    { lat: 48.7439496, lng: 20.4454446 },
    { lat: 48.7439178, lng: 20.4447816 },
    { lat: 48.7439217, lng: 20.4440249 },
    { lat: 48.7440935, lng: 20.4432108 },
    { lat: 48.7440585, lng: 20.4428232 },
    { lat: 48.7442493, lng: 20.4407331 },
    { lat: 48.7442217, lng: 20.4403898 },
    { lat: 48.7440633, lng: 20.4402508 },
    { lat: 48.7439886, lng: 20.4400311 },
    { lat: 48.7441103, lng: 20.4392956 },
    { lat: 48.7442399, lng: 20.4389129 },
    { lat: 48.7443807, lng: 20.4387373 },
    { lat: 48.7444226, lng: 20.4373476 },
    { lat: 48.7442307, lng: 20.4347248 },
    { lat: 48.7441353, lng: 20.4342889 },
    { lat: 48.7442054, lng: 20.4341776 },
    { lat: 48.7441323, lng: 20.4332599 },
    { lat: 48.7441778, lng: 20.4330351 },
    { lat: 48.7440688, lng: 20.4325707 },
    { lat: 48.7440583, lng: 20.432146 },
    { lat: 48.7440177, lng: 20.4320282 },
    { lat: 48.7440365, lng: 20.4315424 },
    { lat: 48.7439908, lng: 20.4309367 },
    { lat: 48.7440215, lng: 20.4305566 },
    { lat: 48.7439612, lng: 20.4303306 },
    { lat: 48.7439656, lng: 20.4300933 },
    { lat: 48.7438657, lng: 20.430041 },
    { lat: 48.7438061, lng: 20.4298382 },
    { lat: 48.74395, lng: 20.4293861 },
    { lat: 48.7439664, lng: 20.4288752 },
    { lat: 48.7438616, lng: 20.4286324 },
    { lat: 48.7439587, lng: 20.428406 },
    { lat: 48.743955, lng: 20.428158 },
    { lat: 48.7437424, lng: 20.4273031 },
    { lat: 48.7438629, lng: 20.4269491 },
    { lat: 48.7438863, lng: 20.426292 },
    { lat: 48.7441438, lng: 20.4249566 },
    { lat: 48.7440412, lng: 20.4246551 },
    { lat: 48.7440179, lng: 20.4244253 },
    { lat: 48.7440529, lng: 20.4241361 },
    { lat: 48.7441003, lng: 20.4239885 },
    { lat: 48.7443463, lng: 20.4236513 },
    { lat: 48.7443259, lng: 20.4232899 },
    { lat: 48.7445835, lng: 20.4228112 },
    { lat: 48.7445585, lng: 20.4222724 },
    { lat: 48.7446602, lng: 20.4221045 },
    { lat: 48.7446702, lng: 20.421811 },
    { lat: 48.7444412, lng: 20.4213807 },
    { lat: 48.7443145, lng: 20.4210146 },
    { lat: 48.7442815, lng: 20.4206511 },
    { lat: 48.7442725, lng: 20.4200008 },
    { lat: 48.7444016, lng: 20.417983 },
    { lat: 48.744568, lng: 20.4183006 },
    { lat: 48.7452909, lng: 20.4180321 },
    { lat: 48.7452951, lng: 20.4172036 },
    { lat: 48.7441945, lng: 20.4172197 },
    { lat: 48.7438725, lng: 20.4165966 },
    { lat: 48.7438463, lng: 20.4162703 },
    { lat: 48.7475935, lng: 20.415816 },
    { lat: 48.7475947, lng: 20.4156749 },
    { lat: 48.7475635, lng: 20.4156784 },
    { lat: 48.7475463, lng: 20.4155575 },
    { lat: 48.7473434, lng: 20.41384 },
    { lat: 48.7457541, lng: 20.4142057 },
    { lat: 48.7457249, lng: 20.4146542 },
    { lat: 48.7438456, lng: 20.4139463 },
    { lat: 48.7438328, lng: 20.4131725 },
    { lat: 48.7439027, lng: 20.4127279 },
    { lat: 48.7438532, lng: 20.4126984 },
    { lat: 48.7439977, lng: 20.4111751 },
    { lat: 48.7436413, lng: 20.4110746 },
    { lat: 48.7436304, lng: 20.411437 },
    { lat: 48.7436883, lng: 20.4114515 },
    { lat: 48.7436117, lng: 20.4115967 },
    { lat: 48.7434934, lng: 20.4115381 },
    { lat: 48.7434684, lng: 20.4113541 },
    { lat: 48.7435425, lng: 20.4109655 },
    { lat: 48.7434812, lng: 20.4108064 },
    { lat: 48.7434967, lng: 20.4104768 },
    { lat: 48.7433855, lng: 20.4102146 },
    { lat: 48.7434496, lng: 20.4097681 },
    { lat: 48.7436258, lng: 20.4094027 },
    { lat: 48.7440257, lng: 20.4090798 },
    { lat: 48.7440554, lng: 20.4088888 },
    { lat: 48.7441984, lng: 20.4088021 },
    { lat: 48.744266, lng: 20.4086817 },
    { lat: 48.7452596, lng: 20.4080457 },
    { lat: 48.7454473, lng: 20.4080061 },
    { lat: 48.7457329, lng: 20.4076754 },
    { lat: 48.7457977, lng: 20.4074549 },
    { lat: 48.7458675, lng: 20.4073935 },
    { lat: 48.7459687, lng: 20.4074799 },
    { lat: 48.7460846, lng: 20.4074484 },
    { lat: 48.7462395, lng: 20.4073167 },
    { lat: 48.7462931, lng: 20.4071882 },
    { lat: 48.7465023, lng: 20.4071225 },
    { lat: 48.7465419, lng: 20.4066281 },
    { lat: 48.7468991, lng: 20.4063057 },
    { lat: 48.7471272, lng: 20.4062072 },
    { lat: 48.7474041, lng: 20.4058815 },
    { lat: 48.7480258, lng: 20.4057079 },
    { lat: 48.7481346, lng: 20.4055175 },
    { lat: 48.7483152, lng: 20.4053847 },
    { lat: 48.7484198, lng: 20.4054416 },
    { lat: 48.7485683, lng: 20.4057037 },
    { lat: 48.7487767, lng: 20.4056169 },
    { lat: 48.7488787, lng: 20.4053358 },
    { lat: 48.7488844, lng: 20.4051947 },
    { lat: 48.7490874, lng: 20.4050535 },
    { lat: 48.7495003, lng: 20.4046041 },
    { lat: 48.7498396, lng: 20.4044913 },
    { lat: 48.7500539, lng: 20.4045057 },
    { lat: 48.7502161, lng: 20.4046507 },
    { lat: 48.750295, lng: 20.4045656 },
    { lat: 48.7504796, lng: 20.4045923 },
    { lat: 48.7504826, lng: 20.4042884 },
    { lat: 48.7508439, lng: 20.4039028 },
    { lat: 48.7508793, lng: 20.4037669 },
    { lat: 48.7506937, lng: 20.4032133 },
    { lat: 48.7506442, lng: 20.4026 },
    { lat: 48.7506929, lng: 20.4021936 },
    { lat: 48.7507522, lng: 20.4020674 },
    { lat: 48.750748, lng: 20.4015133 },
    { lat: 48.7507737, lng: 20.4015692 },
    { lat: 48.750765, lng: 20.4008124 },
    { lat: 48.7506788, lng: 20.3998233 },
    { lat: 48.7507262, lng: 20.3996514 },
    { lat: 48.7507657, lng: 20.3987416 },
    { lat: 48.7509734, lng: 20.3982246 },
    { lat: 48.7511326, lng: 20.3971522 },
    { lat: 48.7510964, lng: 20.3969106 },
    { lat: 48.7511599, lng: 20.3968369 },
    { lat: 48.7511674, lng: 20.3965593 },
    { lat: 48.7512694, lng: 20.3964908 },
    { lat: 48.751232, lng: 20.3963356 },
    { lat: 48.7512616, lng: 20.39611 },
    { lat: 48.751503, lng: 20.3956239 },
    { lat: 48.7517819, lng: 20.3953479 },
    { lat: 48.7519952, lng: 20.3949224 },
    { lat: 48.7521232, lng: 20.3944394 },
    { lat: 48.7520719, lng: 20.3942751 },
    { lat: 48.7521676, lng: 20.39375 },
    { lat: 48.7522447, lng: 20.3936133 },
    { lat: 48.7522963, lng: 20.3933158 },
    { lat: 48.7524198, lng: 20.3932197 },
    { lat: 48.752415, lng: 20.3929793 },
    { lat: 48.7524976, lng: 20.3927522 },
    { lat: 48.7524869, lng: 20.3926204 },
    { lat: 48.7526132, lng: 20.3924494 },
    { lat: 48.7526841, lng: 20.3921816 },
    { lat: 48.7526768, lng: 20.3920524 },
    { lat: 48.7528218, lng: 20.3914894 },
    { lat: 48.7528926, lng: 20.3905201 },
    { lat: 48.7529476, lng: 20.3904221 },
    { lat: 48.7529955, lng: 20.3899972 },
    { lat: 48.7531058, lng: 20.3897355 },
    { lat: 48.753044, lng: 20.389651 },
    { lat: 48.7530421, lng: 20.3892208 },
    { lat: 48.753254, lng: 20.389108 },
  ],
  Čoltovo: [
    { lat: 48.5063237, lng: 20.4192523 },
    { lat: 48.5050604, lng: 20.4138823 },
    { lat: 48.501814, lng: 20.3996183 },
    { lat: 48.5015195, lng: 20.398398 },
    { lat: 48.501377, lng: 20.3980372 },
    { lat: 48.5013578, lng: 20.3977101 },
    { lat: 48.5012728, lng: 20.3975183 },
    { lat: 48.501165, lng: 20.3974411 },
    { lat: 48.501143, lng: 20.3969849 },
    { lat: 48.501226, lng: 20.3968619 },
    { lat: 48.5010469, lng: 20.3965992 },
    { lat: 48.5010049, lng: 20.3964448 },
    { lat: 48.5008226, lng: 20.3963315 },
    { lat: 48.5008556, lng: 20.3962196 },
    { lat: 48.5007412, lng: 20.3952652 },
    { lat: 48.5006311, lng: 20.3948952 },
    { lat: 48.500553, lng: 20.3943005 },
    { lat: 48.5005068, lng: 20.3936306 },
    { lat: 48.5005399, lng: 20.3933195 },
    { lat: 48.5004589, lng: 20.3930602 },
    { lat: 48.5005025, lng: 20.3927592 },
    { lat: 48.50039, lng: 20.3925823 },
    { lat: 48.5004273, lng: 20.3921483 },
    { lat: 48.5003514, lng: 20.3915272 },
    { lat: 48.5004468, lng: 20.3913147 },
    { lat: 48.5005368, lng: 20.3906996 },
    { lat: 48.5006135, lng: 20.3905828 },
    { lat: 48.5007679, lng: 20.3904994 },
    { lat: 48.5007389, lng: 20.3901259 },
    { lat: 48.500801, lng: 20.3897792 },
    { lat: 48.5009407, lng: 20.3896087 },
    { lat: 48.5009266, lng: 20.3890961 },
    { lat: 48.5011363, lng: 20.388842 },
    { lat: 48.5012996, lng: 20.3884329 },
    { lat: 48.5013664, lng: 20.3883855 },
    { lat: 48.5014987, lng: 20.3881083 },
    { lat: 48.5016175, lng: 20.3880933 },
    { lat: 48.5016864, lng: 20.3879829 },
    { lat: 48.5016717, lng: 20.3876863 },
    { lat: 48.5018132, lng: 20.3874694 },
    { lat: 48.5018913, lng: 20.3874526 },
    { lat: 48.5018341, lng: 20.3873141 },
    { lat: 48.501923, lng: 20.3872211 },
    { lat: 48.5021896, lng: 20.3872149 },
    { lat: 48.5022285, lng: 20.3871468 },
    { lat: 48.5023605, lng: 20.387179 },
    { lat: 48.502382, lng: 20.38704 },
    { lat: 48.5024615, lng: 20.3869969 },
    { lat: 48.5026252, lng: 20.3866282 },
    { lat: 48.502836, lng: 20.3864017 },
    { lat: 48.5028264, lng: 20.3862938 },
    { lat: 48.5029068, lng: 20.3861884 },
    { lat: 48.5030365, lng: 20.3863159 },
    { lat: 48.5032352, lng: 20.3862673 },
    { lat: 48.5035025, lng: 20.3861062 },
    { lat: 48.5035167, lng: 20.3860325 },
    { lat: 48.5036531, lng: 20.3860397 },
    { lat: 48.5039733, lng: 20.3858431 },
    { lat: 48.504106, lng: 20.3855779 },
    { lat: 48.5040786, lng: 20.3851613 },
    { lat: 48.5043789, lng: 20.3850615 },
    { lat: 48.5045824, lng: 20.3849091 },
    { lat: 48.5048478, lng: 20.3845468 },
    { lat: 48.5053702, lng: 20.3843693 },
    { lat: 48.505521, lng: 20.3843703 },
    { lat: 48.5057926, lng: 20.3845153 },
    { lat: 48.5058464, lng: 20.3844228 },
    { lat: 48.5058465, lng: 20.3841341 },
    { lat: 48.5055939, lng: 20.3837148 },
    { lat: 48.5055784, lng: 20.3836679 },
    { lat: 48.5055275, lng: 20.3835185 },
    { lat: 48.5056936, lng: 20.3833286 },
    { lat: 48.5060961, lng: 20.3833061 },
    { lat: 48.5061659, lng: 20.3829023 },
    { lat: 48.5056872, lng: 20.3827212 },
    { lat: 48.5055746, lng: 20.3825503 },
    { lat: 48.5055974, lng: 20.3824673 },
    { lat: 48.5059974, lng: 20.382183 },
    { lat: 48.5061897, lng: 20.3814787 },
    { lat: 48.5062069, lng: 20.3786901 },
    { lat: 48.5065366, lng: 20.3755063 },
    { lat: 48.5067876, lng: 20.3720435 },
    { lat: 48.5068498, lng: 20.3705426 },
    { lat: 48.5068007, lng: 20.3694453 },
    { lat: 48.5071444, lng: 20.3679459 },
    { lat: 48.5075199, lng: 20.3669658 },
    { lat: 48.5076558, lng: 20.3664357 },
    { lat: 48.5080848, lng: 20.365567 },
    { lat: 48.508229, lng: 20.365044 },
    { lat: 48.5084363, lng: 20.3639152 },
    { lat: 48.5083871, lng: 20.3634863 },
    { lat: 48.5082391, lng: 20.3629811 },
    { lat: 48.5082381, lng: 20.3623469 },
    { lat: 48.5083693, lng: 20.36152 },
    { lat: 48.5083183, lng: 20.3611612 },
    { lat: 48.5081403, lng: 20.3605216 },
    { lat: 48.5080192, lng: 20.3595229 },
    { lat: 48.5078446, lng: 20.3587637 },
    { lat: 48.5078378, lng: 20.3587341 },
    { lat: 48.5077975, lng: 20.3587427 },
    { lat: 48.5073721, lng: 20.3586906 },
    { lat: 48.5062468, lng: 20.3579867 },
    { lat: 48.5056084, lng: 20.3578461 },
    { lat: 48.5046194, lng: 20.3574651 },
    { lat: 48.5040189, lng: 20.3570215 },
    { lat: 48.5022784, lng: 20.3569226 },
    { lat: 48.5016282, lng: 20.3575753 },
    { lat: 48.5012318, lng: 20.3581535 },
    { lat: 48.5010567, lng: 20.3588393 },
    { lat: 48.501145, lng: 20.3602384 },
    { lat: 48.5010341, lng: 20.3603728 },
    { lat: 48.5008064, lng: 20.3611506 },
    { lat: 48.5005394, lng: 20.3618413 },
    { lat: 48.5005403, lng: 20.3619548 },
    { lat: 48.4994559, lng: 20.3641288 },
    { lat: 48.499344, lng: 20.3642068 },
    { lat: 48.4992019, lng: 20.3645236 },
    { lat: 48.4989629, lng: 20.3648031 },
    { lat: 48.4986385, lng: 20.3653834 },
    { lat: 48.4981789, lng: 20.3666874 },
    { lat: 48.4977982, lng: 20.3672641 },
    { lat: 48.4976956, lng: 20.367522 },
    { lat: 48.4975949, lng: 20.3675106 },
    { lat: 48.4969014, lng: 20.3665266 },
    { lat: 48.4966515, lng: 20.3663025 },
    { lat: 48.496669, lng: 20.3660531 },
    { lat: 48.4962696, lng: 20.3655996 },
    { lat: 48.4958909, lng: 20.3653034 },
    { lat: 48.4951144, lng: 20.364997 },
    { lat: 48.4950466, lng: 20.3650491 },
    { lat: 48.4949224, lng: 20.3653565 },
    { lat: 48.4949345, lng: 20.3657535 },
    { lat: 48.4947067, lng: 20.3658072 },
    { lat: 48.4944526, lng: 20.3656751 },
    { lat: 48.4942933, lng: 20.3654548 },
    { lat: 48.4941987, lng: 20.3651932 },
    { lat: 48.4940901, lng: 20.3645726 },
    { lat: 48.4940143, lng: 20.363605 },
    { lat: 48.4939467, lng: 20.3635176 },
    { lat: 48.4936104, lng: 20.3634441 },
    { lat: 48.4935897, lng: 20.3636341 },
    { lat: 48.4933192, lng: 20.3636863 },
    { lat: 48.4927446, lng: 20.3635895 },
    { lat: 48.4925504, lng: 20.3635227 },
    { lat: 48.4923332, lng: 20.3633519 },
    { lat: 48.4917125, lng: 20.3632044 },
    { lat: 48.4914433, lng: 20.3629061 },
    { lat: 48.4906669, lng: 20.3624322 },
    { lat: 48.4903889, lng: 20.3620608 },
    { lat: 48.490456, lng: 20.3612078 },
    { lat: 48.4907718, lng: 20.3605527 },
    { lat: 48.4910863, lng: 20.3602418 },
    { lat: 48.491284, lng: 20.3602279 },
    { lat: 48.4912674, lng: 20.3597146 },
    { lat: 48.4906584, lng: 20.3587091 },
    { lat: 48.4905532, lng: 20.358837 },
    { lat: 48.4900041, lng: 20.3582732 },
    { lat: 48.4899159, lng: 20.358118 },
    { lat: 48.4898043, lng: 20.3577118 },
    { lat: 48.4898085, lng: 20.3574125 },
    { lat: 48.4899081, lng: 20.3569333 },
    { lat: 48.4897779, lng: 20.3566569 },
    { lat: 48.4898321, lng: 20.3561373 },
    { lat: 48.4899008, lng: 20.3560184 },
    { lat: 48.4901196, lng: 20.3564067 },
    { lat: 48.4903192, lng: 20.35595 },
    { lat: 48.4905575, lng: 20.3557916 },
    { lat: 48.4904427, lng: 20.3552131 },
    { lat: 48.4901357, lng: 20.3541811 },
    { lat: 48.4900532, lng: 20.3540932 },
    { lat: 48.4895206, lng: 20.3545551 },
    { lat: 48.4894813, lng: 20.3544116 },
    { lat: 48.4894761, lng: 20.3539942 },
    { lat: 48.4891328, lng: 20.353389 },
    { lat: 48.4891814, lng: 20.3531873 },
    { lat: 48.4887543, lng: 20.3526914 },
    { lat: 48.4885129, lng: 20.3522199 },
    { lat: 48.488452, lng: 20.3519824 },
    { lat: 48.4885896, lng: 20.3508907 },
    { lat: 48.4887554, lng: 20.3503738 },
    { lat: 48.4889573, lng: 20.350007 },
    { lat: 48.4888221, lng: 20.3499472 },
    { lat: 48.488199, lng: 20.3490738 },
    { lat: 48.4878806, lng: 20.3490216 },
    { lat: 48.4876254, lng: 20.3493239 },
    { lat: 48.4874058, lng: 20.3492648 },
    { lat: 48.4872805, lng: 20.3491381 },
    { lat: 48.4872412, lng: 20.3486257 },
    { lat: 48.4871867, lng: 20.3485844 },
    { lat: 48.4867286, lng: 20.3487646 },
    { lat: 48.4866327, lng: 20.3489013 },
    { lat: 48.486541, lng: 20.3488919 },
    { lat: 48.4863633, lng: 20.348406 },
    { lat: 48.4862064, lng: 20.3481958 },
    { lat: 48.4857077, lng: 20.3472125 },
    { lat: 48.484203, lng: 20.343575 },
    { lat: 48.4837282, lng: 20.3428611 },
    { lat: 48.4833863, lng: 20.342935 },
    { lat: 48.4822048, lng: 20.3437357 },
    { lat: 48.4821698, lng: 20.3440238 },
    { lat: 48.4814948, lng: 20.3439258 },
    { lat: 48.480891, lng: 20.3440057 },
    { lat: 48.4807011, lng: 20.3438741 },
    { lat: 48.4804758, lng: 20.34361 },
    { lat: 48.4802582, lng: 20.343259 },
    { lat: 48.4800935, lng: 20.3431621 },
    { lat: 48.4793768, lng: 20.3452914 },
    { lat: 48.4800385, lng: 20.3456544 },
    { lat: 48.4801043, lng: 20.3457508 },
    { lat: 48.4802898, lng: 20.345831 },
    { lat: 48.4803899, lng: 20.3459709 },
    { lat: 48.4804887, lng: 20.345993 },
    { lat: 48.4809797, lng: 20.34579 },
    { lat: 48.4814177, lng: 20.345816 },
    { lat: 48.482086, lng: 20.346002 },
    { lat: 48.48221, lng: 20.3462097 },
    { lat: 48.4826143, lng: 20.346556 },
    { lat: 48.4827201, lng: 20.3468746 },
    { lat: 48.4827721, lng: 20.3469609 },
    { lat: 48.4828232, lng: 20.3469465 },
    { lat: 48.4830118, lng: 20.3474488 },
    { lat: 48.4835465, lng: 20.3478191 },
    { lat: 48.4836813, lng: 20.3483049 },
    { lat: 48.4842099, lng: 20.3486313 },
    { lat: 48.4842632, lng: 20.3487864 },
    { lat: 48.4845242, lng: 20.3488667 },
    { lat: 48.4845974, lng: 20.3489813 },
    { lat: 48.4846719, lng: 20.3495065 },
    { lat: 48.4845929, lng: 20.3497637 },
    { lat: 48.4847706, lng: 20.3501644 },
    { lat: 48.4848469, lng: 20.3505585 },
    { lat: 48.4848303, lng: 20.3506845 },
    { lat: 48.4850456, lng: 20.3514391 },
    { lat: 48.4850513, lng: 20.3515848 },
    { lat: 48.4849443, lng: 20.3518189 },
    { lat: 48.4849325, lng: 20.3520919 },
    { lat: 48.4851029, lng: 20.352418 },
    { lat: 48.4851366, lng: 20.3527083 },
    { lat: 48.485196, lng: 20.3527005 },
    { lat: 48.4852564, lng: 20.3528337 },
    { lat: 48.4852507, lng: 20.3532925 },
    { lat: 48.4854232, lng: 20.3536141 },
    { lat: 48.4856495, lng: 20.3536446 },
    { lat: 48.4858308, lng: 20.353815 },
    { lat: 48.4862299, lng: 20.3540057 },
    { lat: 48.486626, lng: 20.3545185 },
    { lat: 48.4866982, lng: 20.3549854 },
    { lat: 48.4868406, lng: 20.3550545 },
    { lat: 48.4871784, lng: 20.3554877 },
    { lat: 48.4873346, lng: 20.3557973 },
    { lat: 48.4873031, lng: 20.3559985 },
    { lat: 48.4873603, lng: 20.3564884 },
    { lat: 48.4876309, lng: 20.3566381 },
    { lat: 48.4878221, lng: 20.3569234 },
    { lat: 48.4878268, lng: 20.3570756 },
    { lat: 48.4879085, lng: 20.3571494 },
    { lat: 48.4878727, lng: 20.3575867 },
    { lat: 48.4881447, lng: 20.3581255 },
    { lat: 48.4881663, lng: 20.3586511 },
    { lat: 48.487949, lng: 20.3589691 },
    { lat: 48.4874934, lng: 20.3599421 },
    { lat: 48.4870476, lng: 20.3606956 },
    { lat: 48.4861508, lng: 20.3625699 },
    { lat: 48.4855232, lng: 20.3636368 },
    { lat: 48.485097, lng: 20.3641719 },
    { lat: 48.4851056, lng: 20.3642535 },
    { lat: 48.4850469, lng: 20.3643343 },
    { lat: 48.4848261, lng: 20.3645656 },
    { lat: 48.4847802, lng: 20.3647447 },
    { lat: 48.4844241, lng: 20.3650564 },
    { lat: 48.4843971, lng: 20.3649441 },
    { lat: 48.4842818, lng: 20.3649595 },
    { lat: 48.4842165, lng: 20.3651055 },
    { lat: 48.4842111, lng: 20.3654599 },
    { lat: 48.4839881, lng: 20.3656356 },
    { lat: 48.4840849, lng: 20.3657475 },
    { lat: 48.4840927, lng: 20.3660638 },
    { lat: 48.4841358, lng: 20.3661885 },
    { lat: 48.484042, lng: 20.3661797 },
    { lat: 48.4840056, lng: 20.3662567 },
    { lat: 48.4839136, lng: 20.3662708 },
    { lat: 48.483939, lng: 20.3665714 },
    { lat: 48.4838344, lng: 20.3666065 },
    { lat: 48.483944, lng: 20.3667609 },
    { lat: 48.4839418, lng: 20.3668757 },
    { lat: 48.4838136, lng: 20.3669416 },
    { lat: 48.4838465, lng: 20.3670037 },
    { lat: 48.4838135, lng: 20.3671383 },
    { lat: 48.4837193, lng: 20.3671792 },
    { lat: 48.4837864, lng: 20.3673171 },
    { lat: 48.4838488, lng: 20.3672953 },
    { lat: 48.4838948, lng: 20.3673803 },
    { lat: 48.4839393, lng: 20.3673331 },
    { lat: 48.4839747, lng: 20.3675238 },
    { lat: 48.4838255, lng: 20.3675099 },
    { lat: 48.483779, lng: 20.3676415 },
    { lat: 48.4838111, lng: 20.3676912 },
    { lat: 48.4837486, lng: 20.3677052 },
    { lat: 48.4836459, lng: 20.3679045 },
    { lat: 48.4836808, lng: 20.3681357 },
    { lat: 48.4837563, lng: 20.3681046 },
    { lat: 48.4838077, lng: 20.3681817 },
    { lat: 48.4836748, lng: 20.368411 },
    { lat: 48.4836904, lng: 20.3685281 },
    { lat: 48.4836339, lng: 20.3686662 },
    { lat: 48.4837236, lng: 20.3688302 },
    { lat: 48.4837115, lng: 20.3689267 },
    { lat: 48.4836237, lng: 20.3688954 },
    { lat: 48.4835808, lng: 20.3689517 },
    { lat: 48.4835787, lng: 20.3690434 },
    { lat: 48.4836201, lng: 20.3690323 },
    { lat: 48.4836385, lng: 20.3691053 },
    { lat: 48.4833935, lng: 20.3690867 },
    { lat: 48.4833032, lng: 20.3692827 },
    { lat: 48.4832887, lng: 20.3694341 },
    { lat: 48.4831413, lng: 20.3693186 },
    { lat: 48.4829764, lng: 20.3695396 },
    { lat: 48.4828901, lng: 20.3695546 },
    { lat: 48.4829411, lng: 20.3697631 },
    { lat: 48.4828577, lng: 20.3697903 },
    { lat: 48.4826466, lng: 20.3701262 },
    { lat: 48.4822349, lng: 20.3702585 },
    { lat: 48.4821957, lng: 20.3704639 },
    { lat: 48.4820943, lng: 20.37058 },
    { lat: 48.4819077, lng: 20.3704966 },
    { lat: 48.4818304, lng: 20.3706575 },
    { lat: 48.4817553, lng: 20.3705785 },
    { lat: 48.4816467, lng: 20.3706242 },
    { lat: 48.4816585, lng: 20.3707826 },
    { lat: 48.4814308, lng: 20.370979 },
    { lat: 48.4813729, lng: 20.3712336 },
    { lat: 48.4812939, lng: 20.371282 },
    { lat: 48.4812642, lng: 20.3717656 },
    { lat: 48.4811693, lng: 20.3718183 },
    { lat: 48.4811755, lng: 20.3720627 },
    { lat: 48.4811147, lng: 20.3722671 },
    { lat: 48.4813096, lng: 20.3727903 },
    { lat: 48.4812729, lng: 20.3729497 },
    { lat: 48.4813967, lng: 20.3730329 },
    { lat: 48.4814114, lng: 20.3731526 },
    { lat: 48.4815158, lng: 20.3731168 },
    { lat: 48.4815664, lng: 20.3732594 },
    { lat: 48.4813871, lng: 20.3734256 },
    { lat: 48.4814997, lng: 20.3734863 },
    { lat: 48.4815318, lng: 20.3735861 },
    { lat: 48.48144, lng: 20.3736893 },
    { lat: 48.4815087, lng: 20.3738057 },
    { lat: 48.4814229, lng: 20.3739172 },
    { lat: 48.4815575, lng: 20.3740471 },
    { lat: 48.4815345, lng: 20.3742631 },
    { lat: 48.4813766, lng: 20.3742638 },
    { lat: 48.4813222, lng: 20.3743935 },
    { lat: 48.4813299, lng: 20.374537 },
    { lat: 48.4814369, lng: 20.3745414 },
    { lat: 48.4813812, lng: 20.3746986 },
    { lat: 48.4814436, lng: 20.3748546 },
    { lat: 48.4813241, lng: 20.3754064 },
    { lat: 48.4812336, lng: 20.3755134 },
    { lat: 48.4811882, lng: 20.3757651 },
    { lat: 48.4812472, lng: 20.3759182 },
    { lat: 48.4811531, lng: 20.3759939 },
    { lat: 48.481192, lng: 20.3762123 },
    { lat: 48.4811527, lng: 20.3763901 },
    { lat: 48.4812305, lng: 20.3765054 },
    { lat: 48.4812547, lng: 20.3766665 },
    { lat: 48.4813106, lng: 20.3767699 },
    { lat: 48.4815244, lng: 20.3768239 },
    { lat: 48.4817436, lng: 20.3770188 },
    { lat: 48.4818728, lng: 20.3772474 },
    { lat: 48.4820331, lng: 20.3771102 },
    { lat: 48.4820755, lng: 20.3772708 },
    { lat: 48.4819164, lng: 20.3773547 },
    { lat: 48.4819602, lng: 20.3774928 },
    { lat: 48.4821189, lng: 20.377491 },
    { lat: 48.4822845, lng: 20.3773182 },
    { lat: 48.4823912, lng: 20.3775757 },
    { lat: 48.4823254, lng: 20.3776507 },
    { lat: 48.4823457, lng: 20.3777647 },
    { lat: 48.4824879, lng: 20.3778058 },
    { lat: 48.4825842, lng: 20.377703 },
    { lat: 48.4827505, lng: 20.3780132 },
    { lat: 48.482815, lng: 20.3780193 },
    { lat: 48.4829329, lng: 20.3785655 },
    { lat: 48.4828573, lng: 20.378734 },
    { lat: 48.4829158, lng: 20.3788197 },
    { lat: 48.4829289, lng: 20.379011 },
    { lat: 48.4830561, lng: 20.3792444 },
    { lat: 48.4831531, lng: 20.3792508 },
    { lat: 48.4830905, lng: 20.3794079 },
    { lat: 48.4829648, lng: 20.3793919 },
    { lat: 48.4832306, lng: 20.3795628 },
    { lat: 48.4831536, lng: 20.3799334 },
    { lat: 48.4832254, lng: 20.3799548 },
    { lat: 48.4832494, lng: 20.380166 },
    { lat: 48.4834709, lng: 20.3805957 },
    { lat: 48.4834629, lng: 20.3807085 },
    { lat: 48.483716, lng: 20.3808763 },
    { lat: 48.4839438, lng: 20.3813462 },
    { lat: 48.4838373, lng: 20.3813939 },
    { lat: 48.4839231, lng: 20.3815127 },
    { lat: 48.4840177, lng: 20.3814821 },
    { lat: 48.4840397, lng: 20.3815888 },
    { lat: 48.4839664, lng: 20.3816999 },
    { lat: 48.4839816, lng: 20.3818917 },
    { lat: 48.4841093, lng: 20.3819057 },
    { lat: 48.4840858, lng: 20.3820533 },
    { lat: 48.4841661, lng: 20.3821176 },
    { lat: 48.4843454, lng: 20.3819496 },
    { lat: 48.4844348, lng: 20.3819933 },
    { lat: 48.4843825, lng: 20.3821214 },
    { lat: 48.48441, lng: 20.3822226 },
    { lat: 48.4844806, lng: 20.3822119 },
    { lat: 48.4845395, lng: 20.3825457 },
    { lat: 48.484635, lng: 20.3826534 },
    { lat: 48.4847094, lng: 20.3825688 },
    { lat: 48.4847601, lng: 20.382771 },
    { lat: 48.484875, lng: 20.3828305 },
    { lat: 48.4848605, lng: 20.3829816 },
    { lat: 48.4850254, lng: 20.3829931 },
    { lat: 48.4849763, lng: 20.3832423 },
    { lat: 48.4848414, lng: 20.3833056 },
    { lat: 48.4848871, lng: 20.383522 },
    { lat: 48.4849767, lng: 20.3835387 },
    { lat: 48.4849661, lng: 20.3837597 },
    { lat: 48.4850272, lng: 20.3838264 },
    { lat: 48.4850746, lng: 20.383747 },
    { lat: 48.4854549, lng: 20.3841145 },
    { lat: 48.4855255, lng: 20.384398 },
    { lat: 48.4856131, lng: 20.3844478 },
    { lat: 48.4855867, lng: 20.3845524 },
    { lat: 48.4856788, lng: 20.3845418 },
    { lat: 48.4857319, lng: 20.3846636 },
    { lat: 48.485853, lng: 20.3845908 },
    { lat: 48.4859437, lng: 20.3846976 },
    { lat: 48.486042, lng: 20.384531 },
    { lat: 48.4861694, lng: 20.3847996 },
    { lat: 48.4860507, lng: 20.3849026 },
    { lat: 48.4860597, lng: 20.38502 },
    { lat: 48.4862544, lng: 20.3851301 },
    { lat: 48.4863109, lng: 20.3852242 },
    { lat: 48.486209, lng: 20.3855611 },
    { lat: 48.4862919, lng: 20.3855496 },
    { lat: 48.4863758, lng: 20.3857968 },
    { lat: 48.4863336, lng: 20.3858424 },
    { lat: 48.4863566, lng: 20.3859121 },
    { lat: 48.4864325, lng: 20.3858902 },
    { lat: 48.4864922, lng: 20.3861839 },
    { lat: 48.4866793, lng: 20.3861054 },
    { lat: 48.4867676, lng: 20.3862038 },
    { lat: 48.4868926, lng: 20.3862014 },
    { lat: 48.4868995, lng: 20.3866122 },
    { lat: 48.4868041, lng: 20.3867395 },
    { lat: 48.4869449, lng: 20.3868592 },
    { lat: 48.4868727, lng: 20.3869376 },
    { lat: 48.4869267, lng: 20.3870305 },
    { lat: 48.486888, lng: 20.3871319 },
    { lat: 48.4869292, lng: 20.3872092 },
    { lat: 48.4867536, lng: 20.3874716 },
    { lat: 48.4867854, lng: 20.3875998 },
    { lat: 48.4868464, lng: 20.3876012 },
    { lat: 48.48686, lng: 20.3877225 },
    { lat: 48.4869305, lng: 20.3876471 },
    { lat: 48.4870643, lng: 20.3876727 },
    { lat: 48.4871067, lng: 20.3878394 },
    { lat: 48.4869811, lng: 20.3881138 },
    { lat: 48.487017, lng: 20.3883155 },
    { lat: 48.4871147, lng: 20.3883364 },
    { lat: 48.4872881, lng: 20.3885417 },
    { lat: 48.4872589, lng: 20.3887452 },
    { lat: 48.4873676, lng: 20.3888784 },
    { lat: 48.4872609, lng: 20.3891206 },
    { lat: 48.4873636, lng: 20.3894464 },
    { lat: 48.4872547, lng: 20.3895421 },
    { lat: 48.4871953, lng: 20.3897578 },
    { lat: 48.4870656, lng: 20.3897784 },
    { lat: 48.4870183, lng: 20.389867 },
    { lat: 48.4870777, lng: 20.3900915 },
    { lat: 48.4871863, lng: 20.3902197 },
    { lat: 48.4871598, lng: 20.3903836 },
    { lat: 48.4870121, lng: 20.3905502 },
    { lat: 48.4870541, lng: 20.3914361 },
    { lat: 48.4871956, lng: 20.3918313 },
    { lat: 48.4872788, lng: 20.3916834 },
    { lat: 48.487271, lng: 20.3918581 },
    { lat: 48.4873577, lng: 20.3919485 },
    { lat: 48.4872563, lng: 20.3921501 },
    { lat: 48.4871432, lng: 20.3922288 },
    { lat: 48.4872848, lng: 20.3926031 },
    { lat: 48.4870109, lng: 20.3929724 },
    { lat: 48.48699, lng: 20.3931872 },
    { lat: 48.4870323, lng: 20.3932644 },
    { lat: 48.4871249, lng: 20.3932411 },
    { lat: 48.4871835, lng: 20.3933048 },
    { lat: 48.4870974, lng: 20.3934653 },
    { lat: 48.4871643, lng: 20.3936217 },
    { lat: 48.4872974, lng: 20.3936879 },
    { lat: 48.4873969, lng: 20.3941027 },
    { lat: 48.4873348, lng: 20.3942171 },
    { lat: 48.4873703, lng: 20.3944089 },
    { lat: 48.4874532, lng: 20.3944549 },
    { lat: 48.4874247, lng: 20.3945901 },
    { lat: 48.4874923, lng: 20.3947096 },
    { lat: 48.4874475, lng: 20.3947706 },
    { lat: 48.4875375, lng: 20.39486 },
    { lat: 48.4874102, lng: 20.3948871 },
    { lat: 48.4874134, lng: 20.3950576 },
    { lat: 48.487533, lng: 20.3952087 },
    { lat: 48.4873614, lng: 20.3952307 },
    { lat: 48.4871345, lng: 20.3953641 },
    { lat: 48.487181, lng: 20.3955468 },
    { lat: 48.4873317, lng: 20.3957172 },
    { lat: 48.4872518, lng: 20.3957731 },
    { lat: 48.4874211, lng: 20.3961375 },
    { lat: 48.4872688, lng: 20.3964781 },
    { lat: 48.4872937, lng: 20.3965973 },
    { lat: 48.4873993, lng: 20.3967116 },
    { lat: 48.4873189, lng: 20.3968418 },
    { lat: 48.4874474, lng: 20.3970766 },
    { lat: 48.4873984, lng: 20.3972133 },
    { lat: 48.4872132, lng: 20.3972816 },
    { lat: 48.4872432, lng: 20.3975651 },
    { lat: 48.4870156, lng: 20.3976237 },
    { lat: 48.4870028, lng: 20.397934 },
    { lat: 48.4871881, lng: 20.3982419 },
    { lat: 48.4871129, lng: 20.398421 },
    { lat: 48.4871079, lng: 20.3985851 },
    { lat: 48.48727, lng: 20.398702 },
    { lat: 48.4872674, lng: 20.3987821 },
    { lat: 48.4873309, lng: 20.3988198 },
    { lat: 48.4873292, lng: 20.3990678 },
    { lat: 48.4874169, lng: 20.3991844 },
    { lat: 48.4874344, lng: 20.3993131 },
    { lat: 48.4873861, lng: 20.39929 },
    { lat: 48.4873584, lng: 20.3993616 },
    { lat: 48.4873847, lng: 20.3994875 },
    { lat: 48.4873277, lng: 20.3996192 },
    { lat: 48.4874244, lng: 20.3998749 },
    { lat: 48.4875313, lng: 20.3999302 },
    { lat: 48.4875596, lng: 20.4000207 },
    { lat: 48.4877103, lng: 20.4000196 },
    { lat: 48.4876538, lng: 20.4002493 },
    { lat: 48.4876724, lng: 20.400641 },
    { lat: 48.4876149, lng: 20.4007841 },
    { lat: 48.487669, lng: 20.4009931 },
    { lat: 48.4858358, lng: 20.4035403 },
    { lat: 48.4853843, lng: 20.4049175 },
    { lat: 48.4853097, lng: 20.4053777 },
    { lat: 48.4852632, lng: 20.4069234 },
    { lat: 48.4851108, lng: 20.4088467 },
    { lat: 48.4848279, lng: 20.4107132 },
    { lat: 48.484612, lng: 20.4136576 },
    { lat: 48.4840585, lng: 20.4160761 },
    { lat: 48.4836828, lng: 20.4173177 },
    { lat: 48.4831301, lng: 20.4184121 },
    { lat: 48.4826907, lng: 20.418934 },
    { lat: 48.4821091, lng: 20.4194768 },
    { lat: 48.4807682, lng: 20.4205702 },
    { lat: 48.4802296, lng: 20.4217116 },
    { lat: 48.4798925, lng: 20.4217277 },
    { lat: 48.4787381, lng: 20.4221656 },
    { lat: 48.4783194, lng: 20.4219024 },
    { lat: 48.4774213, lng: 20.4218046 },
    { lat: 48.4766433, lng: 20.4219831 },
    { lat: 48.4760476, lng: 20.4225422 },
    { lat: 48.4750728, lng: 20.4230537 },
    { lat: 48.4739978, lng: 20.4240607 },
    { lat: 48.47321, lng: 20.4244558 },
    { lat: 48.4728307, lng: 20.42393 },
    { lat: 48.4727706, lng: 20.4237475 },
    { lat: 48.4725065, lng: 20.4236969 },
    { lat: 48.4723448, lng: 20.4232716 },
    { lat: 48.472189, lng: 20.4230243 },
    { lat: 48.47221, lng: 20.4229 },
    { lat: 48.4720218, lng: 20.4226573 },
    { lat: 48.4720174, lng: 20.422543 },
    { lat: 48.471878, lng: 20.4223163 },
    { lat: 48.4718813, lng: 20.4221978 },
    { lat: 48.4718303, lng: 20.4221299 },
    { lat: 48.471765, lng: 20.4221877 },
    { lat: 48.4715928, lng: 20.4221018 },
    { lat: 48.471494, lng: 20.4219319 },
    { lat: 48.4712989, lng: 20.4219265 },
    { lat: 48.4712655, lng: 20.4218193 },
    { lat: 48.4712148, lng: 20.4218748 },
    { lat: 48.4710573, lng: 20.4216436 },
    { lat: 48.4709719, lng: 20.4217071 },
    { lat: 48.4709564, lng: 20.4215111 },
    { lat: 48.4708948, lng: 20.4214625 },
    { lat: 48.4708788, lng: 20.4211005 },
    { lat: 48.4709235, lng: 20.4209182 },
    { lat: 48.4708348, lng: 20.4208638 },
    { lat: 48.4708799, lng: 20.4207466 },
    { lat: 48.4708639, lng: 20.4205947 },
    { lat: 48.4707353, lng: 20.420558 },
    { lat: 48.4707521, lng: 20.4203503 },
    { lat: 48.4707191, lng: 20.4203145 },
    { lat: 48.4706709, lng: 20.4202868 },
    { lat: 48.4705693, lng: 20.4203715 },
    { lat: 48.4705113, lng: 20.4202435 },
    { lat: 48.470488, lng: 20.420277 },
    { lat: 48.470456, lng: 20.420356 },
    { lat: 48.469962, lng: 20.421202 },
    { lat: 48.467994, lng: 20.425237 },
    { lat: 48.467621, lng: 20.427046 },
    { lat: 48.467124, lng: 20.427645 },
    { lat: 48.467298, lng: 20.427988 },
    { lat: 48.46794, lng: 20.429427 },
    { lat: 48.468068, lng: 20.429971 },
    { lat: 48.46917, lng: 20.43203 },
    { lat: 48.469265, lng: 20.433056 },
    { lat: 48.469245, lng: 20.433098 },
    { lat: 48.469229, lng: 20.433131 },
    { lat: 48.468896, lng: 20.43397 },
    { lat: 48.468284, lng: 20.435194 },
    { lat: 48.468003, lng: 20.436113 },
    { lat: 48.467698, lng: 20.436724 },
    { lat: 48.46765, lng: 20.436842 },
    { lat: 48.467558, lng: 20.437402 },
    { lat: 48.467723, lng: 20.438332 },
    { lat: 48.468738, lng: 20.439144 },
    { lat: 48.469166, lng: 20.439978 },
    { lat: 48.469415, lng: 20.441073 },
    { lat: 48.469428, lng: 20.44131 },
    { lat: 48.4695, lng: 20.44344 },
    { lat: 48.469718, lng: 20.443874 },
    { lat: 48.470318, lng: 20.445158 },
    { lat: 48.470495, lng: 20.445535 },
    { lat: 48.470972, lng: 20.446557 },
    { lat: 48.471474, lng: 20.44896 },
    { lat: 48.471534, lng: 20.449376 },
    { lat: 48.47162, lng: 20.450066 },
    { lat: 48.471729, lng: 20.450943 },
    { lat: 48.471775, lng: 20.451311 },
    { lat: 48.472075, lng: 20.452234 },
    { lat: 48.47222, lng: 20.452542 },
    { lat: 48.472749, lng: 20.453664 },
    { lat: 48.472803, lng: 20.453791 },
    { lat: 48.473199, lng: 20.454727 },
    { lat: 48.473565, lng: 20.455592 },
    { lat: 48.4739679, lng: 20.4555509 },
    { lat: 48.4742218, lng: 20.4553573 },
    { lat: 48.4763791, lng: 20.4531335 },
    { lat: 48.4773419, lng: 20.4523916 },
    { lat: 48.4786526, lng: 20.4508496 },
    { lat: 48.4793787, lng: 20.4502564 },
    { lat: 48.481209, lng: 20.4490815 },
    { lat: 48.4827168, lng: 20.4479301 },
    { lat: 48.4828373, lng: 20.4477536 },
    { lat: 48.4837653, lng: 20.4459808 },
    { lat: 48.4842106, lng: 20.4446934 },
    { lat: 48.4846158, lng: 20.4437739 },
    { lat: 48.4848907, lng: 20.4418607 },
    { lat: 48.4849951, lng: 20.4406388 },
    { lat: 48.4856862, lng: 20.4378867 },
    { lat: 48.4864743, lng: 20.436528 },
    { lat: 48.487198, lng: 20.4343388 },
    { lat: 48.4882832, lng: 20.4331768 },
    { lat: 48.4886179, lng: 20.4334212 },
    { lat: 48.4899387, lng: 20.4328312 },
    { lat: 48.4899925, lng: 20.4329728 },
    { lat: 48.490186, lng: 20.4328184 },
    { lat: 48.4905577, lng: 20.4326723 },
    { lat: 48.4905002, lng: 20.4325807 },
    { lat: 48.4908651, lng: 20.4321448 },
    { lat: 48.4910809, lng: 20.4320739 },
    { lat: 48.4911813, lng: 20.432226 },
    { lat: 48.4913341, lng: 20.4321603 },
    { lat: 48.4914494, lng: 20.4322004 },
    { lat: 48.4916858, lng: 20.432077 },
    { lat: 48.4916686, lng: 20.4320398 },
    { lat: 48.4927738, lng: 20.4317301 },
    { lat: 48.4933865, lng: 20.4314374 },
    { lat: 48.4941785, lng: 20.430444 },
    { lat: 48.4945331, lng: 20.4301215 },
    { lat: 48.4951838, lng: 20.4292312 },
    { lat: 48.4961282, lng: 20.4282835 },
    { lat: 48.4966054, lng: 20.4280373 },
    { lat: 48.4970554, lng: 20.4279699 },
    { lat: 48.4988221, lng: 20.4271125 },
    { lat: 48.4991367, lng: 20.427032 },
    { lat: 48.499965, lng: 20.4265473 },
    { lat: 48.5003741, lng: 20.4262363 },
    { lat: 48.5009115, lng: 20.4255907 },
    { lat: 48.5010361, lng: 20.4251726 },
    { lat: 48.5021896, lng: 20.422945 },
    { lat: 48.5027686, lng: 20.4215121 },
    { lat: 48.5034187, lng: 20.4203418 },
    { lat: 48.503689, lng: 20.4202288 },
    { lat: 48.5037731, lng: 20.420132 },
    { lat: 48.5051311, lng: 20.419571 },
    { lat: 48.5061186, lng: 20.4193056 },
    { lat: 48.5063237, lng: 20.4192523 },
  ],
  GemerskáHôrka: [
    { lat: 48.5751721, lng: 20.3528445 },
    { lat: 48.5749338, lng: 20.3516358 },
    { lat: 48.5749199, lng: 20.3511939 },
    { lat: 48.5747953, lng: 20.3507704 },
    { lat: 48.574794, lng: 20.35052 },
    { lat: 48.574772, lng: 20.350499 },
    { lat: 48.574498, lng: 20.350239 },
    { lat: 48.574278, lng: 20.349882 },
    { lat: 48.574049, lng: 20.349426 },
    { lat: 48.573764, lng: 20.349034 },
    { lat: 48.573738, lng: 20.349059 },
    { lat: 48.573629, lng: 20.34866 },
    { lat: 48.57355, lng: 20.348281 },
    { lat: 48.573257, lng: 20.347494 },
    { lat: 48.572786, lng: 20.346766 },
    { lat: 48.57238, lng: 20.346488 },
    { lat: 48.572206, lng: 20.346264 },
    { lat: 48.572052, lng: 20.34591 },
    { lat: 48.571658, lng: 20.344756 },
    { lat: 48.571312, lng: 20.344138 },
    { lat: 48.571254, lng: 20.344029 },
    { lat: 48.570971, lng: 20.343353 },
    { lat: 48.570813, lng: 20.342956 },
    { lat: 48.570718, lng: 20.342756 },
    { lat: 48.570429, lng: 20.342198 },
    { lat: 48.569953, lng: 20.341531 },
    { lat: 48.56965, lng: 20.341131 },
    { lat: 48.5694, lng: 20.340936 },
    { lat: 48.569329, lng: 20.340882 },
    { lat: 48.568583, lng: 20.340337 },
    { lat: 48.568077, lng: 20.340065 },
    { lat: 48.567891, lng: 20.340043 },
    { lat: 48.567517, lng: 20.339963 },
    { lat: 48.567034, lng: 20.33982 },
    { lat: 48.566447, lng: 20.339156 },
    { lat: 48.566051, lng: 20.339001 },
    { lat: 48.565887, lng: 20.3389 },
    { lat: 48.565732, lng: 20.338784 },
    { lat: 48.565334, lng: 20.338507 },
    { lat: 48.56504, lng: 20.338315 },
    { lat: 48.564734, lng: 20.33818 },
    { lat: 48.564439, lng: 20.338044 },
    { lat: 48.563892, lng: 20.3378 },
    { lat: 48.56328, lng: 20.337517 },
    { lat: 48.562476, lng: 20.337159 },
    { lat: 48.562361, lng: 20.337094 },
    { lat: 48.562011, lng: 20.336866 },
    { lat: 48.561268, lng: 20.336359 },
    { lat: 48.560992, lng: 20.336207 },
    { lat: 48.560451, lng: 20.336236 },
    { lat: 48.560079, lng: 20.336222 },
    { lat: 48.55944, lng: 20.336187 },
    { lat: 48.558817, lng: 20.336155 },
    { lat: 48.558358, lng: 20.336136 },
    { lat: 48.558127, lng: 20.336127 },
    { lat: 48.557698, lng: 20.336052 },
    { lat: 48.557236, lng: 20.335924 },
    { lat: 48.555409, lng: 20.335428 },
    { lat: 48.555127, lng: 20.335271 },
    { lat: 48.554574, lng: 20.334963 },
    { lat: 48.554559, lng: 20.334954 },
    { lat: 48.55319, lng: 20.334183 },
    { lat: 48.552587, lng: 20.333848 },
    { lat: 48.552172, lng: 20.333617 },
    { lat: 48.551571, lng: 20.333283 },
    { lat: 48.551168, lng: 20.331938 },
    { lat: 48.550853, lng: 20.33156 },
    { lat: 48.550799, lng: 20.331495 },
    { lat: 48.550749, lng: 20.331438 },
    { lat: 48.550655, lng: 20.331327 },
    { lat: 48.550623, lng: 20.331282 },
    { lat: 48.550588, lng: 20.331314 },
    { lat: 48.550335, lng: 20.331275 },
    { lat: 48.549698, lng: 20.331175 },
    { lat: 48.549111, lng: 20.331051 },
    { lat: 48.548733, lng: 20.330972 },
    { lat: 48.548639, lng: 20.330969 },
    { lat: 48.548591, lng: 20.330968 },
    { lat: 48.548552, lng: 20.330967 },
    { lat: 48.5485, lng: 20.330969 },
    { lat: 48.548059, lng: 20.330958 },
    { lat: 48.54781, lng: 20.330955 },
    { lat: 48.547298, lng: 20.331059 },
    { lat: 48.546957, lng: 20.331133 },
    { lat: 48.546514, lng: 20.331138 },
    { lat: 48.545918, lng: 20.331157 },
    { lat: 48.545419, lng: 20.331164 },
    { lat: 48.545008, lng: 20.33117 },
    { lat: 48.544441, lng: 20.331132 },
    { lat: 48.543797, lng: 20.331096 },
    { lat: 48.543155, lng: 20.3312 },
    { lat: 48.542429, lng: 20.331314 },
    { lat: 48.54233, lng: 20.331341 },
    { lat: 48.542152, lng: 20.331402 },
    { lat: 48.541776, lng: 20.331531 },
    { lat: 48.541034, lng: 20.331761 },
    { lat: 48.540622, lng: 20.33179 },
    { lat: 48.540586, lng: 20.33181 },
    { lat: 48.540532, lng: 20.331836 },
    { lat: 48.539868, lng: 20.332173 },
    { lat: 48.539685, lng: 20.332265 },
    { lat: 48.539545, lng: 20.332337 },
    { lat: 48.538744, lng: 20.33226 },
    { lat: 48.538557, lng: 20.332247 },
    { lat: 48.53832, lng: 20.332122 },
    { lat: 48.538299, lng: 20.332106 },
    { lat: 48.5380798, lng: 20.3336256 },
    { lat: 48.5372762, lng: 20.3377044 },
    { lat: 48.5367797, lng: 20.3393573 },
    { lat: 48.5364949, lng: 20.3400235 },
    { lat: 48.5360298, lng: 20.3408898 },
    { lat: 48.5352865, lng: 20.3415514 },
    { lat: 48.5343319, lng: 20.3430627 },
    { lat: 48.53409, lng: 20.3436182 },
    { lat: 48.5337508, lng: 20.3446231 },
    { lat: 48.5336167, lng: 20.3456931 },
    { lat: 48.5331303, lng: 20.3472554 },
    { lat: 48.532477, lng: 20.3488171 },
    { lat: 48.5321036, lng: 20.3516343 },
    { lat: 48.5317222, lng: 20.3538729 },
    { lat: 48.5316, lng: 20.355629 },
    { lat: 48.531545, lng: 20.3586781 },
    { lat: 48.5305102, lng: 20.3636684 },
    { lat: 48.5300827, lng: 20.3647242 },
    { lat: 48.5298406, lng: 20.3650713 },
    { lat: 48.5289293, lng: 20.3657661 },
    { lat: 48.5287121, lng: 20.3657077 },
    { lat: 48.5280409, lng: 20.3652139 },
    { lat: 48.5278036, lng: 20.3651054 },
    { lat: 48.5275068, lng: 20.3648285 },
    { lat: 48.5266937, lng: 20.3649152 },
    { lat: 48.5264522, lng: 20.3646402 },
    { lat: 48.526044, lng: 20.3645677 },
    { lat: 48.5256937, lng: 20.364304 },
    { lat: 48.5252779, lng: 20.3641561 },
    { lat: 48.5245194, lng: 20.3640214 },
    { lat: 48.5240527, lng: 20.3642727 },
    { lat: 48.5231018, lng: 20.3642029 },
    { lat: 48.522714, lng: 20.3643645 },
    { lat: 48.5223308, lng: 20.3643584 },
    { lat: 48.5218817, lng: 20.3642185 },
    { lat: 48.5213758, lng: 20.3642668 },
    { lat: 48.5208831, lng: 20.3641961 },
    { lat: 48.5208492, lng: 20.3641902 },
    { lat: 48.5202234, lng: 20.3640822 },
    { lat: 48.5198314, lng: 20.3644026 },
    { lat: 48.519623, lng: 20.3649804 },
    { lat: 48.519413, lng: 20.3659238 },
    { lat: 48.5192743, lng: 20.3662117 },
    { lat: 48.5192799, lng: 20.3664971 },
    { lat: 48.5190798, lng: 20.3676411 },
    { lat: 48.5186427, lng: 20.3682169 },
    { lat: 48.5185666, lng: 20.3684648 },
    { lat: 48.5185755, lng: 20.3689805 },
    { lat: 48.5184599, lng: 20.3693711 },
    { lat: 48.5184577, lng: 20.3696917 },
    { lat: 48.5183476, lng: 20.3702235 },
    { lat: 48.5184453, lng: 20.3705954 },
    { lat: 48.5184066, lng: 20.3709607 },
    { lat: 48.5183828, lng: 20.3726142 },
    { lat: 48.5184556, lng: 20.373011 },
    { lat: 48.518718, lng: 20.3733602 },
    { lat: 48.5188439, lng: 20.3741981 },
    { lat: 48.5189887, lng: 20.3745609 },
    { lat: 48.5189942, lng: 20.3748204 },
    { lat: 48.5188532, lng: 20.3748775 },
    { lat: 48.5182274, lng: 20.3758403 },
    { lat: 48.5181456, lng: 20.3760262 },
    { lat: 48.5178515, lng: 20.377189 },
    { lat: 48.5176573, lng: 20.3770543 },
    { lat: 48.517513, lng: 20.3770535 },
    { lat: 48.517154, lng: 20.3772952 },
    { lat: 48.5166719, lng: 20.3773492 },
    { lat: 48.5166019, lng: 20.378249 },
    { lat: 48.5165432, lng: 20.3784751 },
    { lat: 48.5166152, lng: 20.3787941 },
    { lat: 48.5166478, lng: 20.3789348 },
    { lat: 48.5170071, lng: 20.3787914 },
    { lat: 48.5172484, lng: 20.3788331 },
    { lat: 48.5175311, lng: 20.3790024 },
    { lat: 48.5177338, lng: 20.3791238 },
    { lat: 48.5179292, lng: 20.3787582 },
    { lat: 48.5179929, lng: 20.3788318 },
    { lat: 48.5177902, lng: 20.379186 },
    { lat: 48.5185817, lng: 20.3801148 },
    { lat: 48.518755, lng: 20.3800204 },
    { lat: 48.5193385, lng: 20.3806083 },
    { lat: 48.5208749, lng: 20.3828058 },
    { lat: 48.5217306, lng: 20.3835596 },
    { lat: 48.5221908, lng: 20.3837268 },
    { lat: 48.5225355, lng: 20.3837553 },
    { lat: 48.5236075, lng: 20.3836078 },
    { lat: 48.5237148, lng: 20.3835863 },
    { lat: 48.5238098, lng: 20.3835666 },
    { lat: 48.5242532, lng: 20.3834753 },
    { lat: 48.5251615, lng: 20.3836334 },
    { lat: 48.5256146, lng: 20.383824 },
    { lat: 48.5261524, lng: 20.3843062 },
    { lat: 48.5267869, lng: 20.3851065 },
    { lat: 48.5293181, lng: 20.3889196 },
    { lat: 48.5295256, lng: 20.3891601 },
    { lat: 48.5298705, lng: 20.3894323 },
    { lat: 48.5322907, lng: 20.3900223 },
    { lat: 48.5328233, lng: 20.3902845 },
    { lat: 48.5332604, lng: 20.390727 },
    { lat: 48.5335449, lng: 20.3904517 },
    { lat: 48.5336355, lng: 20.3904693 },
    { lat: 48.5336793, lng: 20.3905522 },
    { lat: 48.53381, lng: 20.390496 },
    { lat: 48.5338151, lng: 20.3905368 },
    { lat: 48.5340521, lng: 20.3903498 },
    { lat: 48.5343329, lng: 20.3902698 },
    { lat: 48.5349441, lng: 20.3903255 },
    { lat: 48.5352287, lng: 20.3895853 },
    { lat: 48.5359362, lng: 20.3880734 },
    { lat: 48.5366957, lng: 20.3865984 },
    { lat: 48.5370492, lng: 20.3862192 },
    { lat: 48.5371598, lng: 20.3859612 },
    { lat: 48.5409358, lng: 20.3891158 },
    { lat: 48.5414063, lng: 20.3893159 },
    { lat: 48.541354, lng: 20.3894762 },
    { lat: 48.5424747, lng: 20.3904205 },
    { lat: 48.5430999, lng: 20.3910368 },
    { lat: 48.5432618, lng: 20.3910009 },
    { lat: 48.5430887, lng: 20.3908917 },
    { lat: 48.5424185, lng: 20.3899851 },
    { lat: 48.5416341, lng: 20.3886215 },
    { lat: 48.5408405, lng: 20.3875492 },
    { lat: 48.5403828, lng: 20.3863405 },
    { lat: 48.5401831, lng: 20.3855449 },
    { lat: 48.5397453, lng: 20.3842396 },
    { lat: 48.5390353, lng: 20.3823281 },
    { lat: 48.5394014, lng: 20.3824783 },
    { lat: 48.5396755, lng: 20.3824323 },
    { lat: 48.5398393, lng: 20.3822076 },
    { lat: 48.5401704, lng: 20.381975 },
    { lat: 48.5405206, lng: 20.381568 },
    { lat: 48.5418152, lng: 20.3804794 },
    { lat: 48.5424137, lng: 20.3797375 },
    { lat: 48.5428858, lng: 20.378721 },
    { lat: 48.5436536, lng: 20.3773466 },
    { lat: 48.5438114, lng: 20.3770883 },
    { lat: 48.5440313, lng: 20.3768604 },
    { lat: 48.544349, lng: 20.3761672 },
    { lat: 48.5447919, lng: 20.3753916 },
    { lat: 48.5453898, lng: 20.3745431 },
    { lat: 48.5458629, lng: 20.3736148 },
    { lat: 48.5472504, lng: 20.3720231 },
    { lat: 48.5482089, lng: 20.3715039 },
    { lat: 48.5486117, lng: 20.3709414 },
    { lat: 48.549158, lng: 20.3696068 },
    { lat: 48.5500906, lng: 20.3683882 },
    { lat: 48.5504826, lng: 20.3677207 },
    { lat: 48.551567, lng: 20.3660974 },
    { lat: 48.5522547, lng: 20.3647779 },
    { lat: 48.5530261, lng: 20.3631205 },
    { lat: 48.5555847, lng: 20.3592002 },
    { lat: 48.5565474, lng: 20.3579217 },
    { lat: 48.5583535, lng: 20.3559738 },
    { lat: 48.5594499, lng: 20.3553254 },
    { lat: 48.5598889, lng: 20.3551765 },
    { lat: 48.5605201, lng: 20.3546089 },
    { lat: 48.5610521, lng: 20.3547394 },
    { lat: 48.5615936, lng: 20.3549693 },
    { lat: 48.5623039, lng: 20.354757 },
    { lat: 48.5637058, lng: 20.3545797 },
    { lat: 48.5642184, lng: 20.3541481 },
    { lat: 48.5666842, lng: 20.3523705 },
    { lat: 48.5674944, lng: 20.351894 },
    { lat: 48.569131, lng: 20.3521013 },
    { lat: 48.5700682, lng: 20.3518497 },
    { lat: 48.5706766, lng: 20.351913 },
    { lat: 48.5715304, lng: 20.3518878 },
    { lat: 48.5721226, lng: 20.3520012 },
    { lat: 48.5727994, lng: 20.3524088 },
    { lat: 48.5731041, lng: 20.3524818 },
    { lat: 48.5737098, lng: 20.3529418 },
    { lat: 48.5747111, lng: 20.3531192 },
    { lat: 48.5748972, lng: 20.3531009 },
    { lat: 48.5751721, lng: 20.3528445 },
  ],
  Pača: [
    { lat: 48.6753214, lng: 20.6381915 },
    { lat: 48.6765494, lng: 20.63933 },
    { lat: 48.6776886, lng: 20.6398545 },
    { lat: 48.6787734, lng: 20.6401802 },
    { lat: 48.6795535, lng: 20.6402753 },
    { lat: 48.6799375, lng: 20.6406176 },
    { lat: 48.680112, lng: 20.6412659 },
    { lat: 48.6806105, lng: 20.6423786 },
    { lat: 48.6807673, lng: 20.6429485 },
    { lat: 48.6810088, lng: 20.6434295 },
    { lat: 48.6811966, lng: 20.6440143 },
    { lat: 48.6813111, lng: 20.6442521 },
    { lat: 48.6819393, lng: 20.645155 },
    { lat: 48.6821347, lng: 20.6459167 },
    { lat: 48.6823024, lng: 20.6462552 },
    { lat: 48.6826273, lng: 20.6472089 },
    { lat: 48.6827777, lng: 20.6475356 },
    { lat: 48.6829777, lng: 20.6477115 },
    { lat: 48.6832254, lng: 20.64827 },
    { lat: 48.68348, lng: 20.6492103 },
    { lat: 48.6835971, lng: 20.6493945 },
    { lat: 48.6838053, lng: 20.6501672 },
    { lat: 48.6840709, lng: 20.6505707 },
    { lat: 48.6872159, lng: 20.6533873 },
    { lat: 48.688531, lng: 20.655181 },
    { lat: 48.690192, lng: 20.65392 },
    { lat: 48.691149, lng: 20.653153 },
    { lat: 48.69253, lng: 20.652162 },
    { lat: 48.69266, lng: 20.652069 },
    { lat: 48.69278, lng: 20.651983 },
    { lat: 48.69285, lng: 20.651932 },
    { lat: 48.692919, lng: 20.651883 },
    { lat: 48.693053, lng: 20.651787 },
    { lat: 48.693177, lng: 20.651698 },
    { lat: 48.6933, lng: 20.651643 },
    { lat: 48.693396, lng: 20.6516 },
    { lat: 48.693572, lng: 20.651522 },
    { lat: 48.693757, lng: 20.651439 },
    { lat: 48.693943, lng: 20.651356 },
    { lat: 48.694125, lng: 20.651275 },
    { lat: 48.694293, lng: 20.6512 },
    { lat: 48.694466, lng: 20.651123 },
    { lat: 48.694817, lng: 20.650967 },
    { lat: 48.694963, lng: 20.650902 },
    { lat: 48.695147, lng: 20.65082 },
    { lat: 48.695225, lng: 20.650784 },
    { lat: 48.695302, lng: 20.65075 },
    { lat: 48.695383, lng: 20.650714 },
    { lat: 48.695459, lng: 20.65068 },
    { lat: 48.695562, lng: 20.650635 },
    { lat: 48.695655, lng: 20.650593 },
    { lat: 48.69583, lng: 20.650515 },
    { lat: 48.695968, lng: 20.650452 },
    { lat: 48.696065, lng: 20.65041 },
    { lat: 48.696204, lng: 20.650361 },
    { lat: 48.696254, lng: 20.650343 },
    { lat: 48.696413, lng: 20.650287 },
    { lat: 48.696589, lng: 20.650226 },
    { lat: 48.696706, lng: 20.650185 },
    { lat: 48.696814, lng: 20.650146 },
    { lat: 48.696928, lng: 20.650106 },
    { lat: 48.697038, lng: 20.650068 },
    { lat: 48.697235, lng: 20.649999 },
    { lat: 48.69743, lng: 20.64993 },
    { lat: 48.697745, lng: 20.649819 },
    { lat: 48.698074, lng: 20.649703 },
    { lat: 48.698271, lng: 20.649634 },
    { lat: 48.698477, lng: 20.649562 },
    { lat: 48.698684, lng: 20.649488 },
    { lat: 48.69887, lng: 20.649423 },
    { lat: 48.699058, lng: 20.649357 },
    { lat: 48.699439, lng: 20.649358 },
    { lat: 48.699619, lng: 20.649358 },
    { lat: 48.699857, lng: 20.649359 },
    { lat: 48.700149, lng: 20.64936 },
    { lat: 48.700324, lng: 20.649361 },
    { lat: 48.700502, lng: 20.649361 },
    { lat: 48.700858, lng: 20.649363 },
    { lat: 48.701188, lng: 20.649363 },
    { lat: 48.701365, lng: 20.649364 },
    { lat: 48.701533, lng: 20.649364 },
    { lat: 48.701701, lng: 20.649365 },
    { lat: 48.701879, lng: 20.649365 },
    { lat: 48.702112, lng: 20.649366 },
    { lat: 48.702141, lng: 20.649366 },
    { lat: 48.702203, lng: 20.649366 },
    { lat: 48.702218, lng: 20.649365 },
    { lat: 48.702353, lng: 20.649348 },
    { lat: 48.702503, lng: 20.649331 },
    { lat: 48.702833, lng: 20.649291 },
    { lat: 48.702979, lng: 20.649274 },
    { lat: 48.70313, lng: 20.649256 },
    { lat: 48.703424, lng: 20.649221 },
    { lat: 48.703532, lng: 20.649208 },
    { lat: 48.703635, lng: 20.649196 },
    { lat: 48.703744, lng: 20.649183 },
    { lat: 48.703907, lng: 20.649164 },
    { lat: 48.704065, lng: 20.64914 },
    { lat: 48.704238, lng: 20.649114 },
    { lat: 48.704392, lng: 20.649091 },
    { lat: 48.704499, lng: 20.649075 },
    { lat: 48.704651, lng: 20.649047 },
    { lat: 48.704926, lng: 20.648996 },
    { lat: 48.705233, lng: 20.648945 },
    { lat: 48.705495, lng: 20.648903 },
    { lat: 48.705726, lng: 20.648865 },
    { lat: 48.705798, lng: 20.648853 },
    { lat: 48.706073, lng: 20.648804 },
    { lat: 48.706217, lng: 20.648779 },
    { lat: 48.706347, lng: 20.648756 },
    { lat: 48.706522, lng: 20.648725 },
    { lat: 48.706602, lng: 20.64871 },
    { lat: 48.706686, lng: 20.648695 },
    { lat: 48.706852, lng: 20.648666 },
    { lat: 48.707012, lng: 20.648637 },
    { lat: 48.707156, lng: 20.648612 },
    { lat: 48.707299, lng: 20.648586 },
    { lat: 48.707479, lng: 20.648554 },
    { lat: 48.707664, lng: 20.648522 },
    { lat: 48.707953, lng: 20.64847 },
    { lat: 48.708078, lng: 20.648447 },
    { lat: 48.708208, lng: 20.648424 },
    { lat: 48.708301, lng: 20.648408 },
    { lat: 48.708538, lng: 20.648378 },
    { lat: 48.708643, lng: 20.648365 },
    { lat: 48.708717, lng: 20.648355 },
    { lat: 48.708892, lng: 20.648333 },
    { lat: 48.709041, lng: 20.648314 },
    { lat: 48.709192, lng: 20.648296 },
    { lat: 48.709556, lng: 20.648249 },
    { lat: 48.709605, lng: 20.648242 },
    { lat: 48.709895, lng: 20.648206 },
    { lat: 48.710416, lng: 20.64814 },
    { lat: 48.710567, lng: 20.648121 },
    { lat: 48.710938, lng: 20.648075 },
    { lat: 48.711201, lng: 20.648042 },
    { lat: 48.711582, lng: 20.648001 },
    { lat: 48.712007, lng: 20.648172 },
    { lat: 48.712405, lng: 20.648628 },
    { lat: 48.713692, lng: 20.648903 },
    { lat: 48.714475, lng: 20.64907 },
    { lat: 48.714699, lng: 20.649126 },
    { lat: 48.715671, lng: 20.649772 },
    { lat: 48.715921, lng: 20.649707 },
    { lat: 48.716192, lng: 20.649637 },
    { lat: 48.716776, lng: 20.649486 },
    { lat: 48.717058, lng: 20.649413 },
    { lat: 48.71728, lng: 20.649311 },
    { lat: 48.717543, lng: 20.64919 },
    { lat: 48.717751, lng: 20.649102 },
    { lat: 48.717908, lng: 20.649037 },
    { lat: 48.718196, lng: 20.648916 },
    { lat: 48.71853, lng: 20.648775 },
    { lat: 48.718714, lng: 20.648698 },
    { lat: 48.718889, lng: 20.648764 },
    { lat: 48.719635, lng: 20.649045 },
    { lat: 48.719891, lng: 20.649141 },
    { lat: 48.72013, lng: 20.649231 },
    { lat: 48.720152, lng: 20.649239 },
    { lat: 48.720508, lng: 20.649253 },
    { lat: 48.72096, lng: 20.649271 },
    { lat: 48.721097, lng: 20.649186 },
    { lat: 48.721245, lng: 20.649096 },
    { lat: 48.72158, lng: 20.648889 },
    { lat: 48.721817, lng: 20.64864 },
    { lat: 48.722003, lng: 20.64819 },
    { lat: 48.722084, lng: 20.648015 },
    { lat: 48.722294, lng: 20.647868 },
    { lat: 48.722495, lng: 20.647728 },
    { lat: 48.722567, lng: 20.647678 },
    { lat: 48.72264, lng: 20.647627 },
    { lat: 48.72268, lng: 20.647599 },
    { lat: 48.722758, lng: 20.647544 },
    { lat: 48.723117, lng: 20.647292 },
    { lat: 48.723169, lng: 20.647255 },
    { lat: 48.723554, lng: 20.646985 },
    { lat: 48.72372, lng: 20.646869 },
    { lat: 48.723997, lng: 20.646675 },
    { lat: 48.724059, lng: 20.646631 },
    { lat: 48.724382, lng: 20.646402 },
    { lat: 48.724486, lng: 20.646328 },
    { lat: 48.724679, lng: 20.646194 },
    { lat: 48.724952, lng: 20.646005 },
    { lat: 48.724988, lng: 20.64598 },
    { lat: 48.725195, lng: 20.645835 },
    { lat: 48.725413, lng: 20.645682 },
    { lat: 48.725447, lng: 20.645657 },
    { lat: 48.725884, lng: 20.645332 },
    { lat: 48.725968, lng: 20.64527 },
    { lat: 48.726455, lng: 20.644912 },
    { lat: 48.726804, lng: 20.64536 },
    { lat: 48.727311, lng: 20.646006 },
    { lat: 48.727637, lng: 20.646422 },
    { lat: 48.728, lng: 20.646887 },
    { lat: 48.728345, lng: 20.647333 },
    { lat: 48.728756, lng: 20.647859 },
    { lat: 48.729156, lng: 20.648356 },
    { lat: 48.72944, lng: 20.648677 },
    { lat: 48.730067, lng: 20.649384 },
    { lat: 48.730799, lng: 20.650196 },
    { lat: 48.730878, lng: 20.650286 },
    { lat: 48.730896, lng: 20.650307 },
    { lat: 48.730924, lng: 20.650179 },
    { lat: 48.731057, lng: 20.649655 },
    { lat: 48.731111, lng: 20.649468 },
    { lat: 48.731185, lng: 20.649295 },
    { lat: 48.731299, lng: 20.649028 },
    { lat: 48.731511, lng: 20.648747 },
    { lat: 48.731821, lng: 20.648377 },
    { lat: 48.731975, lng: 20.648185 },
    { lat: 48.732177, lng: 20.648054 },
    { lat: 48.732369, lng: 20.647979 },
    { lat: 48.732631, lng: 20.64798 },
    { lat: 48.733097, lng: 20.647894 },
    { lat: 48.733237, lng: 20.647907 },
    { lat: 48.733846, lng: 20.648181 },
    { lat: 48.733862, lng: 20.648164 },
    { lat: 48.733871, lng: 20.648162 },
    { lat: 48.73454, lng: 20.647658 },
    { lat: 48.73665, lng: 20.650281 },
    { lat: 48.737102, lng: 20.650693 },
    { lat: 48.738838, lng: 20.652177 },
    { lat: 48.738944, lng: 20.65234 },
    { lat: 48.739367, lng: 20.65203 },
    { lat: 48.739578, lng: 20.65174 },
    { lat: 48.739677, lng: 20.651688 },
    { lat: 48.739767, lng: 20.651582 },
    { lat: 48.739825, lng: 20.651465 },
    { lat: 48.739918, lng: 20.651144 },
    { lat: 48.739906, lng: 20.650837 },
    { lat: 48.740023, lng: 20.650488 },
    { lat: 48.740213, lng: 20.649537 },
    { lat: 48.740281, lng: 20.649377 },
    { lat: 48.740423, lng: 20.649189 },
    { lat: 48.740631, lng: 20.648689 },
    { lat: 48.740592, lng: 20.648311 },
    { lat: 48.740647, lng: 20.648091 },
    { lat: 48.740736, lng: 20.647867 },
    { lat: 48.740833, lng: 20.647755 },
    { lat: 48.740844, lng: 20.64743 },
    { lat: 48.741047, lng: 20.647157 },
    { lat: 48.741089, lng: 20.646706 },
    { lat: 48.741312, lng: 20.646069 },
    { lat: 48.741354, lng: 20.645816 },
    { lat: 48.741406, lng: 20.645444 },
    { lat: 48.741448, lng: 20.64475 },
    { lat: 48.741581, lng: 20.644322 },
    { lat: 48.74163, lng: 20.644049 },
    { lat: 48.741668, lng: 20.64372 },
    { lat: 48.741672, lng: 20.643158 },
    { lat: 48.741636, lng: 20.642905 },
    { lat: 48.741611, lng: 20.642599 },
    { lat: 48.741647, lng: 20.642178 },
    { lat: 48.74171, lng: 20.64196 },
    { lat: 48.741767, lng: 20.64192 },
    { lat: 48.741824, lng: 20.641911 },
    { lat: 48.741887, lng: 20.641556 },
    { lat: 48.741973, lng: 20.641297 },
    { lat: 48.742029, lng: 20.641133 },
    { lat: 48.742132, lng: 20.640934 },
    { lat: 48.742305, lng: 20.64072 },
    { lat: 48.74259, lng: 20.640664 },
    { lat: 48.742752, lng: 20.640787 },
    { lat: 48.742794, lng: 20.640841 },
    { lat: 48.74304, lng: 20.641163 },
    { lat: 48.743179, lng: 20.641368 },
    { lat: 48.743304, lng: 20.641583 },
    { lat: 48.743438, lng: 20.641845 },
    { lat: 48.743657, lng: 20.642284 },
    { lat: 48.743717, lng: 20.642366 },
    { lat: 48.743788, lng: 20.642401 },
    { lat: 48.743887, lng: 20.642369 },
    { lat: 48.743971, lng: 20.642264 },
    { lat: 48.744109, lng: 20.642006 },
    { lat: 48.744172, lng: 20.641763 },
    { lat: 48.744073, lng: 20.641528 },
    { lat: 48.743977, lng: 20.641148 },
    { lat: 48.743991, lng: 20.640993 },
    { lat: 48.744062, lng: 20.640751 },
    { lat: 48.744149, lng: 20.640336 },
    { lat: 48.744149, lng: 20.640056 },
    { lat: 48.744222, lng: 20.639147 },
    { lat: 48.744196, lng: 20.638971 },
    { lat: 48.743849, lng: 20.637433 },
    { lat: 48.743875, lng: 20.636752 },
    { lat: 48.743962, lng: 20.636568 },
    { lat: 48.744048, lng: 20.63646 },
    { lat: 48.744153, lng: 20.636374 },
    { lat: 48.744278, lng: 20.636363 },
    { lat: 48.744641, lng: 20.636247 },
    { lat: 48.744889, lng: 20.636297 },
    { lat: 48.745007, lng: 20.636138 },
    { lat: 48.745162, lng: 20.635933 },
    { lat: 48.745379, lng: 20.635859 },
    { lat: 48.74614, lng: 20.635328 },
    { lat: 48.746449, lng: 20.635299 },
    { lat: 48.746569, lng: 20.635246 },
    { lat: 48.746672, lng: 20.635178 },
    { lat: 48.74676, lng: 20.634975 },
    { lat: 48.746938, lng: 20.634741 },
    { lat: 48.747136, lng: 20.634533 },
    { lat: 48.74734, lng: 20.634453 },
    { lat: 48.747586, lng: 20.634081 },
    { lat: 48.747876, lng: 20.633959 },
    { lat: 48.748063, lng: 20.633718 },
    { lat: 48.74824, lng: 20.633549 },
    { lat: 48.748391, lng: 20.633289 },
    { lat: 48.748479, lng: 20.633155 },
    { lat: 48.748864, lng: 20.632753 },
    { lat: 48.749044, lng: 20.632549 },
    { lat: 48.74927, lng: 20.632324 },
    { lat: 48.749549, lng: 20.632004 },
    { lat: 48.749756, lng: 20.631741 },
    { lat: 48.7501682, lng: 20.6313624 },
    { lat: 48.750259, lng: 20.631279 },
    { lat: 48.750236, lng: 20.631233 },
    { lat: 48.750204, lng: 20.631169 },
    { lat: 48.750185, lng: 20.62988 },
    { lat: 48.750333, lng: 20.628896 },
    { lat: 48.750561, lng: 20.628364 },
    { lat: 48.75082, lng: 20.627787 },
    { lat: 48.751093, lng: 20.627236 },
    { lat: 48.751441, lng: 20.626204 },
    { lat: 48.751989, lng: 20.624536 },
    { lat: 48.752487, lng: 20.623275 },
    { lat: 48.752793, lng: 20.622678 },
    { lat: 48.753068, lng: 20.622207 },
    { lat: 48.753606, lng: 20.621071 },
    { lat: 48.753791, lng: 20.620415 },
    { lat: 48.753926, lng: 20.619858 },
    { lat: 48.753986, lng: 20.619667 },
    { lat: 48.754358, lng: 20.618471 },
    { lat: 48.75492, lng: 20.617709 },
    { lat: 48.755084, lng: 20.61754 },
    { lat: 48.754637, lng: 20.616589 },
    { lat: 48.754405, lng: 20.616081 },
    { lat: 48.754186, lng: 20.614857 },
    { lat: 48.753782, lng: 20.61427 },
    { lat: 48.753217, lng: 20.613132 },
    { lat: 48.752882, lng: 20.61234 },
    { lat: 48.752749, lng: 20.611652 },
    { lat: 48.752735, lng: 20.611165 },
    { lat: 48.752722, lng: 20.610972 },
    { lat: 48.751594, lng: 20.608391 },
    { lat: 48.751294, lng: 20.607899 },
    { lat: 48.750536, lng: 20.607023 },
    { lat: 48.749599, lng: 20.606244 },
    { lat: 48.749553, lng: 20.60621 },
    { lat: 48.7495439, lng: 20.6062479 },
    { lat: 48.7491628, lng: 20.6061644 },
    { lat: 48.7481355, lng: 20.6062448 },
    { lat: 48.7477327, lng: 20.6064375 },
    { lat: 48.747186, lng: 20.6064807 },
    { lat: 48.7468664, lng: 20.6065805 },
    { lat: 48.7463123, lng: 20.6068797 },
    { lat: 48.7460198, lng: 20.6071003 },
    { lat: 48.7446793, lng: 20.6084711 },
    { lat: 48.7440809, lng: 20.6091982 },
    { lat: 48.7439246, lng: 20.6094802 },
    { lat: 48.7437506, lng: 20.6096466 },
    { lat: 48.7427589, lng: 20.6102695 },
    { lat: 48.7419335, lng: 20.6110692 },
    { lat: 48.7416555, lng: 20.6116863 },
    { lat: 48.7411218, lng: 20.6124296 },
    { lat: 48.7407994, lng: 20.6131881 },
    { lat: 48.7406602, lng: 20.613637 },
    { lat: 48.7404384, lng: 20.614004 },
    { lat: 48.739777, lng: 20.6147001 },
    { lat: 48.7394469, lng: 20.6152484 },
    { lat: 48.7389194, lng: 20.6163756 },
    { lat: 48.7362081, lng: 20.6212267 },
    { lat: 48.7350339, lng: 20.6228341 },
    { lat: 48.7347196, lng: 20.6228588 },
    { lat: 48.7344336, lng: 20.623169 },
    { lat: 48.7338854, lng: 20.6241629 },
    { lat: 48.7333247, lng: 20.6265781 },
    { lat: 48.7329934, lng: 20.6271295 },
    { lat: 48.7324732, lng: 20.6287073 },
    { lat: 48.7321596, lng: 20.629334 },
    { lat: 48.7317459, lng: 20.6299421 },
    { lat: 48.7314033, lng: 20.6308953 },
    { lat: 48.7307773, lng: 20.6329401 },
    { lat: 48.7306714, lng: 20.6331226 },
    { lat: 48.7301658, lng: 20.6359772 },
    { lat: 48.7300143, lng: 20.6364923 },
    { lat: 48.7297836, lng: 20.6373623 },
    { lat: 48.7294084, lng: 20.6372889 },
    { lat: 48.7288217, lng: 20.6369448 },
    { lat: 48.7282375, lng: 20.6367936 },
    { lat: 48.727628, lng: 20.6368091 },
    { lat: 48.7273019, lng: 20.6369189 },
    { lat: 48.7272997, lng: 20.6369187 },
    { lat: 48.7269989, lng: 20.6368844 },
    { lat: 48.7266164, lng: 20.6366722 },
    { lat: 48.725638, lng: 20.6349942 },
    { lat: 48.7253385, lng: 20.6345872 },
    { lat: 48.7248802, lng: 20.63428 },
    { lat: 48.7243941, lng: 20.6330145 },
    { lat: 48.7244258, lng: 20.6322452 },
    { lat: 48.7240552, lng: 20.6309398 },
    { lat: 48.7226495, lng: 20.6276971 },
    { lat: 48.7221244, lng: 20.6268497 },
    { lat: 48.7218634, lng: 20.6265754 },
    { lat: 48.7217879, lng: 20.6263859 },
    { lat: 48.7217482, lng: 20.6256643 },
    { lat: 48.7215795, lng: 20.624405 },
    { lat: 48.7214035, lng: 20.6223501 },
    { lat: 48.7212849, lng: 20.6216907 },
    { lat: 48.7213111, lng: 20.6214182 },
    { lat: 48.7212718, lng: 20.6208374 },
    { lat: 48.7211919, lng: 20.620464 },
    { lat: 48.7211923, lng: 20.6200732 },
    { lat: 48.7211293, lng: 20.6198926 },
    { lat: 48.72078, lng: 20.6192667 },
    { lat: 48.7205037, lng: 20.6189427 },
    { lat: 48.7199872, lng: 20.6180868 },
    { lat: 48.7194929, lng: 20.617683 },
    { lat: 48.7188248, lng: 20.6164938 },
    { lat: 48.7187927, lng: 20.6165115 },
    { lat: 48.7186123, lng: 20.6161963 },
    { lat: 48.7181578, lng: 20.6156502 },
    { lat: 48.7176726, lng: 20.6147853 },
    { lat: 48.716502, lng: 20.6133461 },
    { lat: 48.7163864, lng: 20.6131054 },
    { lat: 48.7160912, lng: 20.6118048 },
    { lat: 48.7159834, lng: 20.6098336 },
    { lat: 48.7154483, lng: 20.6091174 },
    { lat: 48.7145563, lng: 20.6071667 },
    { lat: 48.7140776, lng: 20.6065143 },
    { lat: 48.7132236, lng: 20.6056372 },
    { lat: 48.7126008, lng: 20.6051683 },
    { lat: 48.7120868, lng: 20.604905 },
    { lat: 48.7112539, lng: 20.6046066 },
    { lat: 48.7103625, lng: 20.6040635 },
    { lat: 48.7097469, lng: 20.6039769 },
    { lat: 48.7095421, lng: 20.6036671 },
    { lat: 48.7091864, lng: 20.6034238 },
    { lat: 48.7090792, lng: 20.603268 },
    { lat: 48.7086731, lng: 20.6023811 },
    { lat: 48.707363, lng: 20.5999624 },
    { lat: 48.7063525, lng: 20.5991774 },
    { lat: 48.7061409, lng: 20.599178 },
    { lat: 48.7055965, lng: 20.59898 },
    { lat: 48.7043946, lng: 20.5983151 },
    { lat: 48.7037402, lng: 20.5976331 },
    { lat: 48.7032811, lng: 20.5970298 },
    { lat: 48.7027484, lng: 20.5967454 },
    { lat: 48.7024882, lng: 20.5967397 },
    { lat: 48.7023224, lng: 20.5963611 },
    { lat: 48.7021205, lng: 20.5956487 },
    { lat: 48.7018683, lng: 20.5950959 },
    { lat: 48.7015911, lng: 20.5952114 },
    { lat: 48.7012618, lng: 20.5951046 },
    { lat: 48.7006367, lng: 20.5944825 },
    { lat: 48.7003089, lng: 20.5942943 },
    { lat: 48.6998912, lng: 20.5942968 },
    { lat: 48.6992856, lng: 20.5939312 },
    { lat: 48.6988235, lng: 20.5941345 },
    { lat: 48.6986624, lng: 20.5940371 },
    { lat: 48.6984236, lng: 20.593695 },
    { lat: 48.6979606, lng: 20.5926188 },
    { lat: 48.6973814, lng: 20.5914345 },
    { lat: 48.6970097, lng: 20.5915529 },
    { lat: 48.6964515, lng: 20.5914061 },
    { lat: 48.696217, lng: 20.5914906 },
    { lat: 48.6958008, lng: 20.5914064 },
    { lat: 48.6951707, lng: 20.5910215 },
    { lat: 48.6943759, lng: 20.590933 },
    { lat: 48.6942294, lng: 20.590863 },
    { lat: 48.6929229, lng: 20.5910956 },
    { lat: 48.6917406, lng: 20.591495 },
    { lat: 48.6895831, lng: 20.5921645 },
    { lat: 48.6877149, lng: 20.5928564 },
    { lat: 48.686088, lng: 20.5936574 },
    { lat: 48.6856627, lng: 20.5941857 },
    { lat: 48.6846955, lng: 20.5949814 },
    { lat: 48.6844274, lng: 20.5950448 },
    { lat: 48.6838499, lng: 20.5953232 },
    { lat: 48.6835534, lng: 20.5951236 },
    { lat: 48.6830416, lng: 20.595265 },
    { lat: 48.6826944, lng: 20.5952802 },
    { lat: 48.6823516, lng: 20.595575 },
    { lat: 48.6819678, lng: 20.5957642 },
    { lat: 48.6818357, lng: 20.5960289 },
    { lat: 48.6817078, lng: 20.5961055 },
    { lat: 48.6812731, lng: 20.5959726 },
    { lat: 48.6807749, lng: 20.5959626 },
    { lat: 48.6801161, lng: 20.5964167 },
    { lat: 48.6789582, lng: 20.5966499 },
    { lat: 48.6784725, lng: 20.5971601 },
    { lat: 48.6780478, lng: 20.5969989 },
    { lat: 48.6777104, lng: 20.5971671 },
    { lat: 48.6771999, lng: 20.5971802 },
    { lat: 48.6768871, lng: 20.5973738 },
    { lat: 48.6763041, lng: 20.5975625 },
    { lat: 48.6760004, lng: 20.5973528 },
    { lat: 48.6757424, lng: 20.5973814 },
    { lat: 48.6755487, lng: 20.5975636 },
    { lat: 48.6738166, lng: 20.597309 },
    { lat: 48.6735971, lng: 20.597369 },
    { lat: 48.6729702, lng: 20.5971898 },
    { lat: 48.671886, lng: 20.5974703 },
    { lat: 48.6716747, lng: 20.5976903 },
    { lat: 48.6714893, lng: 20.5977938 },
    { lat: 48.6711409, lng: 20.5985175 },
    { lat: 48.6710868, lng: 20.59876 },
    { lat: 48.6708469, lng: 20.5991601 },
    { lat: 48.6706957, lng: 20.5991798 },
    { lat: 48.6702241, lng: 20.60029 },
    { lat: 48.6705567, lng: 20.6007645 },
    { lat: 48.6703891, lng: 20.6011675 },
    { lat: 48.6725975, lng: 20.603092 },
    { lat: 48.6727524, lng: 20.6048617 },
    { lat: 48.6740318, lng: 20.6065704 },
    { lat: 48.6728035, lng: 20.6102932 },
    { lat: 48.6712253, lng: 20.6138757 },
    { lat: 48.671379, lng: 20.6144795 },
    { lat: 48.6718387, lng: 20.6153877 },
    { lat: 48.6720636, lng: 20.6169806 },
    { lat: 48.6719642, lng: 20.6179937 },
    { lat: 48.6728424, lng: 20.6189474 },
    { lat: 48.6755352, lng: 20.6176452 },
    { lat: 48.6762164, lng: 20.6192829 },
    { lat: 48.6726521, lng: 20.6225019 },
    { lat: 48.6719044, lng: 20.6249354 },
    { lat: 48.6711133, lng: 20.6291221 },
    { lat: 48.6722283, lng: 20.6299088 },
    { lat: 48.6729437, lng: 20.6306043 },
    { lat: 48.6735734, lng: 20.6319169 },
    { lat: 48.6742568, lng: 20.6340504 },
    { lat: 48.6745169, lng: 20.6347025 },
    { lat: 48.6749428, lng: 20.6351128 },
    { lat: 48.6750878, lng: 20.6354172 },
    { lat: 48.675413, lng: 20.6369962 },
    { lat: 48.6753214, lng: 20.6381915 },
  ],
  Lúčka: [
    { lat: 48.669184, lng: 20.728163 },
    { lat: 48.6682228, lng: 20.7269077 },
    { lat: 48.6673142, lng: 20.7255741 },
    { lat: 48.6662922, lng: 20.7245977 },
    { lat: 48.6661297, lng: 20.7245186 },
    { lat: 48.6654702, lng: 20.7237055 },
    { lat: 48.6650959, lng: 20.7227069 },
    { lat: 48.6646477, lng: 20.7224484 },
    { lat: 48.6640489, lng: 20.7219488 },
    { lat: 48.6639371, lng: 20.721777 },
    { lat: 48.663477, lng: 20.7214717 },
    { lat: 48.6633304, lng: 20.7213094 },
    { lat: 48.6630329, lng: 20.7211747 },
    { lat: 48.6625283, lng: 20.7211041 },
    { lat: 48.6619586, lng: 20.7206258 },
    { lat: 48.6615691, lng: 20.7201894 },
    { lat: 48.6612325, lng: 20.7196202 },
    { lat: 48.6609366, lng: 20.7193383 },
    { lat: 48.6608191, lng: 20.7188813 },
    { lat: 48.6605152, lng: 20.7186628 },
    { lat: 48.6600099, lng: 20.7180047 },
    { lat: 48.6594101, lng: 20.7167785 },
    { lat: 48.6587998, lng: 20.7161426 },
    { lat: 48.658628, lng: 20.7158688 },
    { lat: 48.6585481, lng: 20.7159048 },
    { lat: 48.6583445, lng: 20.7156589 },
    { lat: 48.6581854, lng: 20.7157074 },
    { lat: 48.6580038, lng: 20.7158892 },
    { lat: 48.6577835, lng: 20.715771 },
    { lat: 48.6577065, lng: 20.7157869 },
    { lat: 48.6571355, lng: 20.7163539 },
    { lat: 48.6569679, lng: 20.7164191 },
    { lat: 48.6568127, lng: 20.7165852 },
    { lat: 48.6565124, lng: 20.7165255 },
    { lat: 48.6561761, lng: 20.7165688 },
    { lat: 48.6561073, lng: 20.7168482 },
    { lat: 48.6559723, lng: 20.7171063 },
    { lat: 48.6557143, lng: 20.7171928 },
    { lat: 48.6555973, lng: 20.7171001 },
    { lat: 48.6555452, lng: 20.7171218 },
    { lat: 48.655505, lng: 20.7172924 },
    { lat: 48.6551137, lng: 20.7173405 },
    { lat: 48.654967, lng: 20.717294 },
    { lat: 48.6547676, lng: 20.7175452 },
    { lat: 48.6542017, lng: 20.7174006 },
    { lat: 48.6541174, lng: 20.717499 },
    { lat: 48.6541087, lng: 20.717698 },
    { lat: 48.6539989, lng: 20.7177411 },
    { lat: 48.6538868, lng: 20.7179133 },
    { lat: 48.6537219, lng: 20.7179904 },
    { lat: 48.6532704, lng: 20.7180854 },
    { lat: 48.6531876, lng: 20.7179725 },
    { lat: 48.6530207, lng: 20.7179618 },
    { lat: 48.6529151, lng: 20.7181306 },
    { lat: 48.65255, lng: 20.7181193 },
    { lat: 48.6524264, lng: 20.7182191 },
    { lat: 48.6522109, lng: 20.7180517 },
    { lat: 48.6518854, lng: 20.7179521 },
    { lat: 48.6511549, lng: 20.717989 },
    { lat: 48.6507922, lng: 20.7181384 },
    { lat: 48.6506178, lng: 20.7180179 },
    { lat: 48.650222, lng: 20.7180013 },
    { lat: 48.6501111, lng: 20.7177938 },
    { lat: 48.6500728, lng: 20.7175986 },
    { lat: 48.6499338, lng: 20.7174318 },
    { lat: 48.6497321, lng: 20.7169818 },
    { lat: 48.6497649, lng: 20.716763 },
    { lat: 48.6496885, lng: 20.7164541 },
    { lat: 48.6493666, lng: 20.7159814 },
    { lat: 48.6491679, lng: 20.715999 },
    { lat: 48.6490969, lng: 20.7156142 },
    { lat: 48.6487789, lng: 20.7152241 },
    { lat: 48.6486583, lng: 20.7149313 },
    { lat: 48.6484723, lng: 20.7148307 },
    { lat: 48.6479814, lng: 20.7143864 },
    { lat: 48.6478605, lng: 20.7144115 },
    { lat: 48.6471529, lng: 20.7142621 },
    { lat: 48.6469508, lng: 20.7138399 },
    { lat: 48.6469395, lng: 20.7136827 },
    { lat: 48.6468001, lng: 20.7135256 },
    { lat: 48.6465848, lng: 20.7128852 },
    { lat: 48.646606, lng: 20.7127599 },
    { lat: 48.646205, lng: 20.7124949 },
    { lat: 48.6459245, lng: 20.7118936 },
    { lat: 48.6456521, lng: 20.7118299 },
    { lat: 48.6456293, lng: 20.7116676 },
    { lat: 48.6455031, lng: 20.711633 },
    { lat: 48.64545, lng: 20.7114675 },
    { lat: 48.6451036, lng: 20.7114466 },
    { lat: 48.6448939, lng: 20.7113141 },
    { lat: 48.6447042, lng: 20.7109845 },
    { lat: 48.6445006, lng: 20.7108323 },
    { lat: 48.6444155, lng: 20.7106548 },
    { lat: 48.6442302, lng: 20.7105389 },
    { lat: 48.6440681, lng: 20.7105371 },
    { lat: 48.6439898, lng: 20.7104267 },
    { lat: 48.6434348, lng: 20.7103643 },
    { lat: 48.6432513, lng: 20.7102396 },
    { lat: 48.6430096, lng: 20.7098054 },
    { lat: 48.6428182, lng: 20.7096522 },
    { lat: 48.6425326, lng: 20.7097854 },
    { lat: 48.6420226, lng: 20.7096546 },
    { lat: 48.6416764, lng: 20.7096727 },
    { lat: 48.6415009, lng: 20.7095215 },
    { lat: 48.6410545, lng: 20.7095983 },
    { lat: 48.6409408, lng: 20.709472 },
    { lat: 48.6407068, lng: 20.7094728 },
    { lat: 48.6404403, lng: 20.7097408 },
    { lat: 48.6403704, lng: 20.7096717 },
    { lat: 48.6398479, lng: 20.7098911 },
    { lat: 48.6397368, lng: 20.7098048 },
    { lat: 48.6395418, lng: 20.7098939 },
    { lat: 48.6393215, lng: 20.7099012 },
    { lat: 48.6392417, lng: 20.7098083 },
    { lat: 48.6390951, lng: 20.7097711 },
    { lat: 48.6389654, lng: 20.7093581 },
    { lat: 48.6387709, lng: 20.7091767 },
    { lat: 48.6387236, lng: 20.7088897 },
    { lat: 48.6386344, lng: 20.7087239 },
    { lat: 48.6384491, lng: 20.7085489 },
    { lat: 48.6384367, lng: 20.7083937 },
    { lat: 48.6383011, lng: 20.7080637 },
    { lat: 48.6380771, lng: 20.7080929 },
    { lat: 48.6378969, lng: 20.7077783 },
    { lat: 48.6374551, lng: 20.7074832 },
    { lat: 48.637224, lng: 20.7071863 },
    { lat: 48.6366377, lng: 20.7069093 },
    { lat: 48.6363997, lng: 20.7065207 },
    { lat: 48.6359492, lng: 20.7064488 },
    { lat: 48.6358296, lng: 20.7063187 },
    { lat: 48.6356079, lng: 20.7062862 },
    { lat: 48.6355326, lng: 20.706199 },
    { lat: 48.6352222, lng: 20.7062075 },
    { lat: 48.6350062, lng: 20.7059265 },
    { lat: 48.6341559, lng: 20.7058583 },
    { lat: 48.6330313, lng: 20.705292 },
    { lat: 48.632719, lng: 20.7044628 },
    { lat: 48.6315992, lng: 20.7045376 },
    { lat: 48.6311515, lng: 20.7046366 },
    { lat: 48.6296782, lng: 20.7046166 },
    { lat: 48.6277869, lng: 20.7040245 },
    { lat: 48.6268788, lng: 20.7039447 },
    { lat: 48.6263213, lng: 20.704126 },
    { lat: 48.6255795, lng: 20.7055451 },
    { lat: 48.6245327, lng: 20.7064331 },
    { lat: 48.6237754, lng: 20.7065982 },
    { lat: 48.6231636, lng: 20.7071234 },
    { lat: 48.6227588, lng: 20.7076399 },
    { lat: 48.6220992, lng: 20.7088439 },
    { lat: 48.6219671, lng: 20.709031 },
    { lat: 48.6213961, lng: 20.7098398 },
    { lat: 48.6210358, lng: 20.710167 },
    { lat: 48.6207974, lng: 20.7103835 },
    { lat: 48.6201805, lng: 20.7109438 },
    { lat: 48.6197029, lng: 20.7110004 },
    { lat: 48.6191767, lng: 20.711558 },
    { lat: 48.6184253, lng: 20.7119545 },
    { lat: 48.6172594, lng: 20.7122855 },
    { lat: 48.6163206, lng: 20.7132665 },
    { lat: 48.6130637, lng: 20.7135486 },
    { lat: 48.6120571, lng: 20.7140526 },
    { lat: 48.6112702, lng: 20.7145921 },
    { lat: 48.6107675, lng: 20.7149232 },
    { lat: 48.6109286, lng: 20.7174172 },
    { lat: 48.6108894, lng: 20.7185934 },
    { lat: 48.6112992, lng: 20.7191649 },
    { lat: 48.6110885, lng: 20.7226013 },
    { lat: 48.6113156, lng: 20.7231952 },
    { lat: 48.6116722, lng: 20.7275209 },
    { lat: 48.6126005, lng: 20.7311512 },
    { lat: 48.6151374, lng: 20.7313209 },
    { lat: 48.6167508, lng: 20.7338006 },
    { lat: 48.6169529, lng: 20.7343835 },
    { lat: 48.6175236, lng: 20.7364772 },
    { lat: 48.6180295, lng: 20.7375985 },
    { lat: 48.6183154, lng: 20.7380764 },
    { lat: 48.6196685, lng: 20.7396574 },
    { lat: 48.6201145, lng: 20.7410579 },
    { lat: 48.6204908, lng: 20.744537 },
    { lat: 48.6204993, lng: 20.74509 },
    { lat: 48.6204227, lng: 20.7463921 },
    { lat: 48.6205323, lng: 20.7484752 },
    { lat: 48.6206958, lng: 20.7484165 },
    { lat: 48.6221843, lng: 20.7485208 },
    { lat: 48.62384, lng: 20.7490819 },
    { lat: 48.6251288, lng: 20.7492048 },
    { lat: 48.6294857, lng: 20.7483481 },
    { lat: 48.629874, lng: 20.7481542 },
    { lat: 48.6297926, lng: 20.7479547 },
    { lat: 48.6320327, lng: 20.7473114 },
    { lat: 48.6330865, lng: 20.7475639 },
    { lat: 48.6356102, lng: 20.7487061 },
    { lat: 48.6365385, lng: 20.7485663 },
    { lat: 48.6366169, lng: 20.7480389 },
    { lat: 48.6367459, lng: 20.7475857 },
    { lat: 48.63674, lng: 20.747226 },
    { lat: 48.637178, lng: 20.7466557 },
    { lat: 48.6376739, lng: 20.7457152 },
    { lat: 48.638051, lng: 20.7445985 },
    { lat: 48.6387048, lng: 20.744848 },
    { lat: 48.6388379, lng: 20.7453299 },
    { lat: 48.6389854, lng: 20.745585 },
    { lat: 48.6389926, lng: 20.7464893 },
    { lat: 48.6392369, lng: 20.7471587 },
    { lat: 48.6393318, lng: 20.7477103 },
    { lat: 48.6393909, lng: 20.7486456 },
    { lat: 48.6406704, lng: 20.7482507 },
    { lat: 48.6414087, lng: 20.7484358 },
    { lat: 48.6426783, lng: 20.7485532 },
    { lat: 48.6437466, lng: 20.7485587 },
    { lat: 48.6445502, lng: 20.7483256 },
    { lat: 48.6456674, lng: 20.7477319 },
    { lat: 48.6483909, lng: 20.7466888 },
    { lat: 48.6514797, lng: 20.7451187 },
    { lat: 48.6526478, lng: 20.7446952 },
    { lat: 48.6535397, lng: 20.7442861 },
    { lat: 48.6541347, lng: 20.7438938 },
    { lat: 48.6544694, lng: 20.7449521 },
    { lat: 48.6544911, lng: 20.7453114 },
    { lat: 48.6546231, lng: 20.7459826 },
    { lat: 48.6554527, lng: 20.745695 },
    { lat: 48.655866, lng: 20.7456667 },
    { lat: 48.6563552, lng: 20.745498 },
    { lat: 48.6570699, lng: 20.7455903 },
    { lat: 48.6580584, lng: 20.7456186 },
    { lat: 48.658735, lng: 20.7458104 },
    { lat: 48.6589141, lng: 20.7457536 },
    { lat: 48.6604986, lng: 20.7463716 },
    { lat: 48.6619407, lng: 20.7456696 },
    { lat: 48.6626063, lng: 20.7451081 },
    { lat: 48.6630725, lng: 20.7443401 },
    { lat: 48.6632686, lng: 20.7437435 },
    { lat: 48.6634369, lng: 20.7428072 },
    { lat: 48.6638996, lng: 20.7417282 },
    { lat: 48.6648743, lng: 20.7403806 },
    { lat: 48.665946, lng: 20.739268 },
    { lat: 48.6666994, lng: 20.7389262 },
    { lat: 48.6674624, lng: 20.7384098 },
    { lat: 48.6693596, lng: 20.73807 },
    { lat: 48.6699719, lng: 20.7382869 },
    { lat: 48.670413, lng: 20.738127 },
    { lat: 48.67077, lng: 20.737587 },
    { lat: 48.671337, lng: 20.736732 },
    { lat: 48.671324, lng: 20.736692 },
    { lat: 48.670902, lng: 20.736263 },
    { lat: 48.670485, lng: 20.735853 },
    { lat: 48.670115, lng: 20.73548 },
    { lat: 48.670021, lng: 20.735385 },
    { lat: 48.66959, lng: 20.734952 },
    { lat: 48.667953, lng: 20.733361 },
    { lat: 48.6675747, lng: 20.7322715 },
    { lat: 48.667426, lng: 20.731866 },
    { lat: 48.66826, lng: 20.730152 },
    { lat: 48.668366, lng: 20.729937 },
    { lat: 48.669184, lng: 20.728163 },
  ],
  Petrovo: [
    { lat: 48.7291784, lng: 20.3678357 },
    { lat: 48.7287445, lng: 20.36756 },
    { lat: 48.7285321, lng: 20.3671925 },
    { lat: 48.7284188, lng: 20.3661831 },
    { lat: 48.7273674, lng: 20.364909 },
    { lat: 48.7268421, lng: 20.3638447 },
    { lat: 48.7260668, lng: 20.3630158 },
    { lat: 48.7256535, lng: 20.362055 },
    { lat: 48.7247681, lng: 20.3606759 },
    { lat: 48.7241213, lng: 20.3595575 },
    { lat: 48.7234825, lng: 20.358733 },
    { lat: 48.7231255, lng: 20.3584227 },
    { lat: 48.7216296, lng: 20.3571224 },
    { lat: 48.7209187, lng: 20.3572041 },
    { lat: 48.7202075, lng: 20.357185 },
    { lat: 48.7188691, lng: 20.3575147 },
    { lat: 48.7184843, lng: 20.3574371 },
    { lat: 48.7185, lng: 20.3571743 },
    { lat: 48.7176316, lng: 20.356356 },
    { lat: 48.7171954, lng: 20.3562309 },
    { lat: 48.7166236, lng: 20.3563217 },
    { lat: 48.7155133, lng: 20.3561178 },
    { lat: 48.7151012, lng: 20.3561225 },
    { lat: 48.7130011, lng: 20.3551607 },
    { lat: 48.7120681, lng: 20.3548024 },
    { lat: 48.7115337, lng: 20.3542852 },
    { lat: 48.7112554, lng: 20.354722 },
    { lat: 48.710813, lng: 20.3543757 },
    { lat: 48.7099899, lng: 20.3539418 },
    { lat: 48.7097191, lng: 20.3536641 },
    { lat: 48.7095365, lng: 20.3538917 },
    { lat: 48.7086083, lng: 20.3546131 },
    { lat: 48.7085612, lng: 20.3545302 },
    { lat: 48.7083085, lng: 20.3551594 },
    { lat: 48.7082391, lng: 20.3548229 },
    { lat: 48.7078811, lng: 20.3555773 },
    { lat: 48.7076719, lng: 20.356019 },
    { lat: 48.7077827, lng: 20.35615 },
    { lat: 48.7066302, lng: 20.360413 },
    { lat: 48.7068146, lng: 20.3607878 },
    { lat: 48.7068048, lng: 20.3614373 },
    { lat: 48.7067027, lng: 20.3617076 },
    { lat: 48.7067545, lng: 20.3630479 },
    { lat: 48.7066276, lng: 20.3641051 },
    { lat: 48.707022, lng: 20.3650715 },
    { lat: 48.7073611, lng: 20.3654805 },
    { lat: 48.7072793, lng: 20.3658738 },
    { lat: 48.7079312, lng: 20.3689892 },
    { lat: 48.7080799, lng: 20.3701573 },
    { lat: 48.7075496, lng: 20.3717699 },
    { lat: 48.7074935, lng: 20.3725589 },
    { lat: 48.7075816, lng: 20.3739994 },
    { lat: 48.7074479, lng: 20.375525 },
    { lat: 48.7072598, lng: 20.3760767 },
    { lat: 48.7071895, lng: 20.3793092 },
    { lat: 48.7066426, lng: 20.3805495 },
    { lat: 48.7060581, lng: 20.3828833 },
    { lat: 48.7058788, lng: 20.3834057 },
    { lat: 48.7034892, lng: 20.3882052 },
    { lat: 48.7040695, lng: 20.3880641 },
    { lat: 48.7049499, lng: 20.3878863 },
    { lat: 48.7054588, lng: 20.3880631 },
    { lat: 48.7058612, lng: 20.3881276 },
    { lat: 48.7063082, lng: 20.3883311 },
    { lat: 48.707383, lng: 20.388465 },
    { lat: 48.7076763, lng: 20.3883605 },
    { lat: 48.7081435, lng: 20.3883548 },
    { lat: 48.7086892, lng: 20.388493 },
    { lat: 48.7094088, lng: 20.3889027 },
    { lat: 48.7102716, lng: 20.3891439 },
    { lat: 48.7104309, lng: 20.3891585 },
    { lat: 48.7109364, lng: 20.3889818 },
    { lat: 48.7113605, lng: 20.3887628 },
    { lat: 48.7122287, lng: 20.388176 },
    { lat: 48.7131546, lng: 20.3855167 },
    { lat: 48.7134037, lng: 20.3851454 },
    { lat: 48.7141966, lng: 20.3824257 },
    { lat: 48.7149471, lng: 20.3815321 },
    { lat: 48.7150401, lng: 20.38149 },
    { lat: 48.7152155, lng: 20.3812532 },
    { lat: 48.7151817, lng: 20.3812219 },
    { lat: 48.7154375, lng: 20.3810814 },
    { lat: 48.7157432, lng: 20.380801 },
    { lat: 48.7168514, lng: 20.3796081 },
    { lat: 48.7170483, lng: 20.3793658 },
    { lat: 48.7173089, lng: 20.3788843 },
    { lat: 48.7175727, lng: 20.3785961 },
    { lat: 48.7181095, lng: 20.3778307 },
    { lat: 48.7184941, lng: 20.3772063 },
    { lat: 48.719337, lng: 20.376691 },
    { lat: 48.7196703, lng: 20.3766017 },
    { lat: 48.7211644, lng: 20.3753693 },
    { lat: 48.7213149, lng: 20.375069 },
    { lat: 48.7224049, lng: 20.3747455 },
    { lat: 48.7235818, lng: 20.3737895 },
    { lat: 48.724148, lng: 20.3732289 },
    { lat: 48.7244554, lng: 20.3731415 },
    { lat: 48.7251072, lng: 20.3731904 },
    { lat: 48.7256463, lng: 20.3732753 },
    { lat: 48.7269316, lng: 20.3736667 },
    { lat: 48.7269875, lng: 20.373389 },
    { lat: 48.7272015, lng: 20.3723372 },
    { lat: 48.7280425, lng: 20.3692969 },
    { lat: 48.7285778, lng: 20.3690735 },
    { lat: 48.7289247, lng: 20.3681751 },
    { lat: 48.7291784, lng: 20.3678357 },
  ],
  KrásnohorskáDlháLúka: [
    { lat: 48.5982162, lng: 20.5554751 },
    { lat: 48.5989482, lng: 20.5578765 },
    { lat: 48.5985938, lng: 20.5620811 },
    { lat: 48.5986267, lng: 20.563519 },
    { lat: 48.5987779, lng: 20.5648075 },
    { lat: 48.6003083, lng: 20.5693222 },
    { lat: 48.601686, lng: 20.5715024 },
    { lat: 48.6020515, lng: 20.574725 },
    { lat: 48.602118, lng: 20.575029 },
    { lat: 48.6028992, lng: 20.5784782 },
    { lat: 48.6036843, lng: 20.5809283 },
    { lat: 48.6045551, lng: 20.5820159 },
    { lat: 48.6048984, lng: 20.5836314 },
    { lat: 48.6056674, lng: 20.5844259 },
    { lat: 48.6068871, lng: 20.5892676 },
    { lat: 48.6070328, lng: 20.5897137 },
    { lat: 48.6071546, lng: 20.5900853 },
    { lat: 48.6076752, lng: 20.5902748 },
    { lat: 48.6081263, lng: 20.5905463 },
    { lat: 48.6089228, lng: 20.5906156 },
    { lat: 48.6091883, lng: 20.5905525 },
    { lat: 48.6106111, lng: 20.5908189 },
    { lat: 48.6108493, lng: 20.5911708 },
    { lat: 48.6111689, lng: 20.59138 },
    { lat: 48.6127908, lng: 20.5916451 },
    { lat: 48.6146856, lng: 20.5916697 },
    { lat: 48.6162561, lng: 20.5914235 },
    { lat: 48.6178833, lng: 20.5907224 },
    { lat: 48.6184667, lng: 20.5897769 },
    { lat: 48.6188465, lng: 20.589358 },
    { lat: 48.6189685, lng: 20.589794 },
    { lat: 48.6191422, lng: 20.589988 },
    { lat: 48.6192391, lng: 20.5902048 },
    { lat: 48.6191946, lng: 20.5904658 },
    { lat: 48.6192612, lng: 20.5907481 },
    { lat: 48.6195297, lng: 20.5910381 },
    { lat: 48.6195924, lng: 20.5910315 },
    { lat: 48.6196634, lng: 20.5911529 },
    { lat: 48.620274, lng: 20.59153 },
    { lat: 48.6206679, lng: 20.5917078 },
    { lat: 48.6207313, lng: 20.5915954 },
    { lat: 48.620916, lng: 20.5917511 },
    { lat: 48.6213029, lng: 20.5922618 },
    { lat: 48.6212825, lng: 20.5924717 },
    { lat: 48.6213268, lng: 20.5927285 },
    { lat: 48.6217047, lng: 20.5930201 },
    { lat: 48.6215453, lng: 20.5933715 },
    { lat: 48.6217125, lng: 20.5934774 },
    { lat: 48.6219279, lng: 20.5932459 },
    { lat: 48.6219796, lng: 20.5932729 },
    { lat: 48.6219827, lng: 20.5937887 },
    { lat: 48.6217826, lng: 20.5941645 },
    { lat: 48.6218854, lng: 20.5942655 },
    { lat: 48.6219083, lng: 20.5947945 },
    { lat: 48.6218382, lng: 20.59491 },
    { lat: 48.6218153, lng: 20.5951464 },
    { lat: 48.6219349, lng: 20.5953656 },
    { lat: 48.621833, lng: 20.5954418 },
    { lat: 48.6215601, lng: 20.5952457 },
    { lat: 48.6215019, lng: 20.5953185 },
    { lat: 48.6215184, lng: 20.595588 },
    { lat: 48.6219469, lng: 20.5962187 },
    { lat: 48.6221563, lng: 20.5962486 },
    { lat: 48.6221901, lng: 20.596308 },
    { lat: 48.6221266, lng: 20.5967561 },
    { lat: 48.622427, lng: 20.5970851 },
    { lat: 48.6225649, lng: 20.5975323 },
    { lat: 48.6228365, lng: 20.5978164 },
    { lat: 48.6228824, lng: 20.5980841 },
    { lat: 48.6234307, lng: 20.5986683 },
    { lat: 48.6235106, lng: 20.5991876 },
    { lat: 48.624276, lng: 20.5987515 },
    { lat: 48.6243909, lng: 20.5988736 },
    { lat: 48.6246141, lng: 20.5996539 },
    { lat: 48.6257218, lng: 20.601551 },
    { lat: 48.6276422, lng: 20.6057494 },
    { lat: 48.6279256, lng: 20.6054994 },
    { lat: 48.6282142, lng: 20.605138 },
    { lat: 48.6292404, lng: 20.6075543 },
    { lat: 48.6310314, lng: 20.6047765 },
    { lat: 48.6320718, lng: 20.6029645 },
    { lat: 48.6322073, lng: 20.6027283 },
    { lat: 48.6327927, lng: 20.6011916 },
    { lat: 48.6332302, lng: 20.600042 },
    { lat: 48.6335336, lng: 20.5987919 },
    { lat: 48.6337524, lng: 20.5980991 },
    { lat: 48.634446, lng: 20.5950315 },
    { lat: 48.6350485, lng: 20.5918162 },
    { lat: 48.6356115, lng: 20.5881375 },
    { lat: 48.6358012, lng: 20.5871434 },
    { lat: 48.636642, lng: 20.5873785 },
    { lat: 48.637143, lng: 20.5875936 },
    { lat: 48.637422, lng: 20.5861787 },
    { lat: 48.6421016, lng: 20.5725468 },
    { lat: 48.6421354, lng: 20.5723717 },
    { lat: 48.6420617, lng: 20.5722913 },
    { lat: 48.6420371, lng: 20.5721175 },
    { lat: 48.6419473, lng: 20.5719966 },
    { lat: 48.6417605, lng: 20.5714227 },
    { lat: 48.6412444, lng: 20.5704361 },
    { lat: 48.642445, lng: 20.5672459 },
    { lat: 48.6424111, lng: 20.5669341 },
    { lat: 48.6425082, lng: 20.5665741 },
    { lat: 48.6424334, lng: 20.566349 },
    { lat: 48.6422617, lng: 20.5663631 },
    { lat: 48.6421775, lng: 20.5662189 },
    { lat: 48.6420738, lng: 20.566182 },
    { lat: 48.6420089, lng: 20.5660408 },
    { lat: 48.6420066, lng: 20.5657996 },
    { lat: 48.6412237, lng: 20.5643567 },
    { lat: 48.6401134, lng: 20.5627352 },
    { lat: 48.6400716, lng: 20.5627978 },
    { lat: 48.6390122, lng: 20.5611514 },
    { lat: 48.6379487, lng: 20.5636041 },
    { lat: 48.6375479, lng: 20.5625093 },
    { lat: 48.6372052, lng: 20.5617657 },
    { lat: 48.6370768, lng: 20.5620054 },
    { lat: 48.6352636, lng: 20.5611442 },
    { lat: 48.6341475, lng: 20.5630248 },
    { lat: 48.6339651, lng: 20.562781 },
    { lat: 48.6339188, lng: 20.5628696 },
    { lat: 48.6338487, lng: 20.5627089 },
    { lat: 48.6338564, lng: 20.562305 },
    { lat: 48.6339549, lng: 20.5620778 },
    { lat: 48.6339279, lng: 20.561985 },
    { lat: 48.633809, lng: 20.5618765 },
    { lat: 48.6337038, lng: 20.5619241 },
    { lat: 48.6335942, lng: 20.5616713 },
    { lat: 48.633537, lng: 20.561375 },
    { lat: 48.6334618, lng: 20.5612863 },
    { lat: 48.6335043, lng: 20.5611286 },
    { lat: 48.633443, lng: 20.5607796 },
    { lat: 48.6334795, lng: 20.5605487 },
    { lat: 48.6315402, lng: 20.5593832 },
    { lat: 48.6309222, lng: 20.5588981 },
    { lat: 48.6296484, lng: 20.5580376 },
    { lat: 48.6285545, lng: 20.5569837 },
    { lat: 48.6279295, lng: 20.5561939 },
    { lat: 48.6277697, lng: 20.5564992 },
    { lat: 48.6269278, lng: 20.5590317 },
    { lat: 48.6258644, lng: 20.5583493 },
    { lat: 48.6258967, lng: 20.5581263 },
    { lat: 48.6246813, lng: 20.5575076 },
    { lat: 48.6248338, lng: 20.5572727 },
    { lat: 48.6248604, lng: 20.5571452 },
    { lat: 48.6247909, lng: 20.5569357 },
    { lat: 48.6247268, lng: 20.556424 },
    { lat: 48.6248728, lng: 20.5558045 },
    { lat: 48.6251122, lng: 20.5553999 },
    { lat: 48.6249793, lng: 20.5546659 },
    { lat: 48.6247496, lng: 20.5547016 },
    { lat: 48.6247765, lng: 20.5541786 },
    { lat: 48.624846, lng: 20.5539283 },
    { lat: 48.6248546, lng: 20.5532499 },
    { lat: 48.6249284, lng: 20.5528653 },
    { lat: 48.6246054, lng: 20.5526 },
    { lat: 48.6242513, lng: 20.5524922 },
    { lat: 48.6235539, lng: 20.5518751 },
    { lat: 48.6229297, lng: 20.5511986 },
    { lat: 48.6190987, lng: 20.5490535 },
    { lat: 48.6177116, lng: 20.5480247 },
    { lat: 48.6159316, lng: 20.546911 },
    { lat: 48.6127848, lng: 20.5453083 },
    { lat: 48.6094252, lng: 20.5440398 },
    { lat: 48.6083522, lng: 20.5414944 },
    { lat: 48.6073567, lng: 20.5402813 },
    { lat: 48.606963, lng: 20.5399937 },
    { lat: 48.6053799, lng: 20.5393225 },
    { lat: 48.6038035, lng: 20.5379008 },
    { lat: 48.6028988, lng: 20.5376144 },
    { lat: 48.60184, lng: 20.5368495 },
    { lat: 48.6011235, lng: 20.5364719 },
    { lat: 48.6003921, lng: 20.5397863 },
    { lat: 48.6002801, lng: 20.540075 },
    { lat: 48.5998231, lng: 20.5405889 },
    { lat: 48.5994066, lng: 20.5411972 },
    { lat: 48.5991568, lng: 20.5423851 },
    { lat: 48.599061, lng: 20.5431964 },
    { lat: 48.5989902, lng: 20.5444485 },
    { lat: 48.5985711, lng: 20.5449535 },
    { lat: 48.5984125, lng: 20.5471439 },
    { lat: 48.5983814, lng: 20.5484275 },
    { lat: 48.5982033, lng: 20.5502973 },
    { lat: 48.5981393, lng: 20.55319 },
    { lat: 48.5982162, lng: 20.5554751 },
  ],
  Rochovce: [
    { lat: 48.7191152, lng: 20.3114761 },
    { lat: 48.7188107, lng: 20.3107145 },
    { lat: 48.7186735, lng: 20.3105487 },
    { lat: 48.7184435, lng: 20.3104234 },
    { lat: 48.7182653, lng: 20.3096976 },
    { lat: 48.718583, lng: 20.3093553 },
    { lat: 48.7186625, lng: 20.3090551 },
    { lat: 48.7184724, lng: 20.3084843 },
    { lat: 48.718477, lng: 20.3080289 },
    { lat: 48.7186586, lng: 20.3072305 },
    { lat: 48.7185635, lng: 20.3066695 },
    { lat: 48.7184082, lng: 20.3051192 },
    { lat: 48.7183616, lng: 20.3042728 },
    { lat: 48.7184568, lng: 20.3031359 },
    { lat: 48.7183302, lng: 20.3024942 },
    { lat: 48.7182986, lng: 20.3018023 },
    { lat: 48.7182451, lng: 20.3016178 },
    { lat: 48.7183162, lng: 20.301217 },
    { lat: 48.7182091, lng: 20.3010111 },
    { lat: 48.7181953, lng: 20.3007207 },
    { lat: 48.7180881, lng: 20.3005979 },
    { lat: 48.7179419, lng: 20.3002273 },
    { lat: 48.7175313, lng: 20.2997819 },
    { lat: 48.7173088, lng: 20.2996499 },
    { lat: 48.7172167, lng: 20.2994802 },
    { lat: 48.7166944, lng: 20.2994313 },
    { lat: 48.7166346, lng: 20.299283 },
    { lat: 48.7165276, lng: 20.2991977 },
    { lat: 48.7159658, lng: 20.2988857 },
    { lat: 48.7156886, lng: 20.2985942 },
    { lat: 48.7154672, lng: 20.2985206 },
    { lat: 48.715404, lng: 20.298461 },
    { lat: 48.7153269, lng: 20.2981851 },
    { lat: 48.7150067, lng: 20.2980904 },
    { lat: 48.7148761, lng: 20.2978343 },
    { lat: 48.7147926, lng: 20.2977993 },
    { lat: 48.714638, lng: 20.297535 },
    { lat: 48.7143943, lng: 20.2973803 },
    { lat: 48.7142329, lng: 20.2971983 },
    { lat: 48.7141861, lng: 20.2970648 },
    { lat: 48.713768, lng: 20.2966879 },
    { lat: 48.713579, lng: 20.2964053 },
    { lat: 48.7131282, lng: 20.2961393 },
    { lat: 48.7129934, lng: 20.2958298 },
    { lat: 48.712662, lng: 20.2953891 },
    { lat: 48.7125649, lng: 20.2951835 },
    { lat: 48.7123885, lng: 20.2935622 },
    { lat: 48.7122486, lng: 20.2930947 },
    { lat: 48.7117486, lng: 20.2926632 },
    { lat: 48.7114216, lng: 20.2926529 },
    { lat: 48.7111973, lng: 20.2925346 },
    { lat: 48.7109723, lng: 20.2925081 },
    { lat: 48.7109788, lng: 20.2924451 },
    { lat: 48.7105979, lng: 20.2921284 },
    { lat: 48.7098413, lng: 20.2907496 },
    { lat: 48.7080716, lng: 20.2913979 },
    { lat: 48.7078505, lng: 20.2917892 },
    { lat: 48.7065152, lng: 20.2897618 },
    { lat: 48.7062982, lng: 20.2894586 },
    { lat: 48.7059241, lng: 20.2890778 },
    { lat: 48.7057797, lng: 20.2890733 },
    { lat: 48.7055748, lng: 20.2888435 },
    { lat: 48.7051289, lng: 20.2891342 },
    { lat: 48.7046713, lng: 20.288726 },
    { lat: 48.704789, lng: 20.288105 },
    { lat: 48.7047734, lng: 20.2879385 },
    { lat: 48.7043973, lng: 20.2872852 },
    { lat: 48.7042581, lng: 20.2872667 },
    { lat: 48.7043153, lng: 20.2868857 },
    { lat: 48.7043261, lng: 20.2855895 },
    { lat: 48.7042267, lng: 20.2854379 },
    { lat: 48.7040476, lng: 20.2853471 },
    { lat: 48.7039456, lng: 20.2851904 },
    { lat: 48.7038086, lng: 20.284684 },
    { lat: 48.7037997, lng: 20.2837724 },
    { lat: 48.7040938, lng: 20.2826885 },
    { lat: 48.7043403, lng: 20.2821405 },
    { lat: 48.7043783, lng: 20.2819067 },
    { lat: 48.7043075, lng: 20.2815651 },
    { lat: 48.7041214, lng: 20.2810399 },
    { lat: 48.7039632, lng: 20.2807753 },
    { lat: 48.7036151, lng: 20.2804807 },
    { lat: 48.7034906, lng: 20.280094 },
    { lat: 48.7032355, lng: 20.2797112 },
    { lat: 48.7029677, lng: 20.2791723 },
    { lat: 48.7028109, lng: 20.278392 },
    { lat: 48.7025999, lng: 20.2778088 },
    { lat: 48.7024766, lng: 20.2770295 },
    { lat: 48.7023765, lng: 20.2766848 },
    { lat: 48.7022338, lng: 20.2763219 },
    { lat: 48.7018384, lng: 20.2756073 },
    { lat: 48.7014801, lng: 20.2745158 },
    { lat: 48.7011072, lng: 20.2739718 },
    { lat: 48.7009145, lng: 20.2731645 },
    { lat: 48.7007967, lng: 20.2728949 },
    { lat: 48.6997495, lng: 20.2715278 },
    { lat: 48.6995129, lng: 20.2710946 },
    { lat: 48.6990236, lng: 20.2699447 },
    { lat: 48.698395, lng: 20.2689653 },
    { lat: 48.695983, lng: 20.2671517 },
    { lat: 48.6958987, lng: 20.2667864 },
    { lat: 48.6958658, lng: 20.2663224 },
    { lat: 48.695796, lng: 20.2661807 },
    { lat: 48.6956325, lng: 20.2659737 },
    { lat: 48.6952403, lng: 20.2656804 },
    { lat: 48.6946039, lng: 20.2647928 },
    { lat: 48.6941768, lng: 20.2646744 },
    { lat: 48.6939801, lng: 20.2645175 },
    { lat: 48.6937003, lng: 20.2645021 },
    { lat: 48.6932778, lng: 20.2638429 },
    { lat: 48.692741, lng: 20.263524 },
    { lat: 48.692384, lng: 20.263823 },
    { lat: 48.691941, lng: 20.263899 },
    { lat: 48.690919, lng: 20.264399 },
    { lat: 48.689832, lng: 20.265551 },
    { lat: 48.689645, lng: 20.265884 },
    { lat: 48.689468, lng: 20.266408 },
    { lat: 48.689313, lng: 20.266975 },
    { lat: 48.689103, lng: 20.267571 },
    { lat: 48.688982, lng: 20.267993 },
    { lat: 48.688828, lng: 20.268451 },
    { lat: 48.68867, lng: 20.269003 },
    { lat: 48.688501, lng: 20.269345 },
    { lat: 48.688051, lng: 20.270407 },
    { lat: 48.687923, lng: 20.271317 },
    { lat: 48.686694, lng: 20.27217 },
    { lat: 48.686057, lng: 20.272668 },
    { lat: 48.685834, lng: 20.273 },
    { lat: 48.685635, lng: 20.273247 },
    { lat: 48.68544, lng: 20.273422 },
    { lat: 48.685125, lng: 20.273674 },
    { lat: 48.684593, lng: 20.274053 },
    { lat: 48.684449, lng: 20.274109 },
    { lat: 48.684082, lng: 20.274284 },
    { lat: 48.683502, lng: 20.274402 },
    { lat: 48.683073, lng: 20.274552 },
    { lat: 48.682724, lng: 20.274753 },
    { lat: 48.682386, lng: 20.275325 },
    { lat: 48.682129, lng: 20.276026 },
    { lat: 48.6824444, lng: 20.2770318 },
    { lat: 48.6824756, lng: 20.2779602 },
    { lat: 48.6823194, lng: 20.2788588 },
    { lat: 48.6820828, lng: 20.2796813 },
    { lat: 48.6824153, lng: 20.2808078 },
    { lat: 48.682973, lng: 20.281097 },
    { lat: 48.6833548, lng: 20.2817844 },
    { lat: 48.6835333, lng: 20.2822862 },
    { lat: 48.68379, lng: 20.2834286 },
    { lat: 48.683765, lng: 20.2839932 },
    { lat: 48.6841669, lng: 20.2855624 },
    { lat: 48.6845259, lng: 20.2892478 },
    { lat: 48.6847134, lng: 20.2897506 },
    { lat: 48.6855709, lng: 20.2905953 },
    { lat: 48.6859268, lng: 20.291483 },
    { lat: 48.6863238, lng: 20.2918701 },
    { lat: 48.6867557, lng: 20.2924605 },
    { lat: 48.688328, lng: 20.2939235 },
    { lat: 48.6885867, lng: 20.2944884 },
    { lat: 48.6898674, lng: 20.2955599 },
    { lat: 48.690072, lng: 20.2959511 },
    { lat: 48.6903584, lng: 20.2961643 },
    { lat: 48.6914288, lng: 20.2974372 },
    { lat: 48.6923748, lng: 20.2980982 },
    { lat: 48.6925485, lng: 20.2983635 },
    { lat: 48.6926859, lng: 20.2987081 },
    { lat: 48.6928172, lng: 20.2992691 },
    { lat: 48.6931493, lng: 20.2998429 },
    { lat: 48.6934631, lng: 20.3015276 },
    { lat: 48.6934196, lng: 20.3020843 },
    { lat: 48.6935197, lng: 20.3030852 },
    { lat: 48.6934992, lng: 20.3043742 },
    { lat: 48.6936109, lng: 20.3046655 },
    { lat: 48.6938265, lng: 20.3057402 },
    { lat: 48.6940515, lng: 20.306654 },
    { lat: 48.6941731, lng: 20.3068538 },
    { lat: 48.6944761, lng: 20.3065708 },
    { lat: 48.6950208, lng: 20.3071392 },
    { lat: 48.694683, lng: 20.308233 },
    { lat: 48.6954602, lng: 20.3089539 },
    { lat: 48.6952208, lng: 20.3094269 },
    { lat: 48.6957149, lng: 20.3098241 },
    { lat: 48.6963471, lng: 20.3101509 },
    { lat: 48.6967833, lng: 20.3102182 },
    { lat: 48.6967512, lng: 20.3107258 },
    { lat: 48.6974843, lng: 20.3113266 },
    { lat: 48.6982533, lng: 20.3122722 },
    { lat: 48.6986437, lng: 20.3129856 },
    { lat: 48.6995743, lng: 20.3141183 },
    { lat: 48.6999428, lng: 20.3144112 },
    { lat: 48.7008297, lng: 20.3149204 },
    { lat: 48.7018234, lng: 20.3156514 },
    { lat: 48.705009, lng: 20.3178042 },
    { lat: 48.7050893, lng: 20.317643 },
    { lat: 48.7052042, lng: 20.3174135 },
    { lat: 48.7055157, lng: 20.3172556 },
    { lat: 48.7062972, lng: 20.3172832 },
    { lat: 48.7068956, lng: 20.3174092 },
    { lat: 48.7074835, lng: 20.3174406 },
    { lat: 48.7079664, lng: 20.3173483 },
    { lat: 48.7084055, lng: 20.3174065 },
    { lat: 48.7088643, lng: 20.3173606 },
    { lat: 48.7092782, lng: 20.3171719 },
    { lat: 48.7097791, lng: 20.3170495 },
    { lat: 48.7102588, lng: 20.3167032 },
    { lat: 48.711012, lng: 20.3165566 },
    { lat: 48.7125814, lng: 20.3165182 },
    { lat: 48.7132804, lng: 20.3163928 },
    { lat: 48.7144136, lng: 20.3158549 },
    { lat: 48.7153844, lng: 20.3155657 },
    { lat: 48.7162254, lng: 20.3155039 },
    { lat: 48.7162643, lng: 20.3152298 },
    { lat: 48.7171017, lng: 20.3131459 },
    { lat: 48.7177059, lng: 20.3122667 },
    { lat: 48.7183617, lng: 20.3118145 },
    { lat: 48.7191152, lng: 20.3114761 },
  ],
  Čučma: [
    { lat: 48.744292, lng: 20.575697 },
    { lat: 48.7441915, lng: 20.5738083 },
    { lat: 48.7438452, lng: 20.5666752 },
    { lat: 48.7425154, lng: 20.563434 },
    { lat: 48.7338506, lng: 20.5516739 },
    { lat: 48.7305211, lng: 20.5490541 },
    { lat: 48.7274156, lng: 20.5463453 },
    { lat: 48.7241438, lng: 20.5446181 },
    { lat: 48.7206026, lng: 20.5429375 },
    { lat: 48.7165208, lng: 20.5378671 },
    { lat: 48.7157656, lng: 20.537571 },
    { lat: 48.7144827, lng: 20.5366462 },
    { lat: 48.7138956, lng: 20.5365117 },
    { lat: 48.713422, lng: 20.5363123 },
    { lat: 48.7126742, lng: 20.5361571 },
    { lat: 48.7120336, lng: 20.5358817 },
    { lat: 48.7118325, lng: 20.5356845 },
    { lat: 48.71111, lng: 20.5354206 },
    { lat: 48.7104684, lng: 20.5350308 },
    { lat: 48.7102451, lng: 20.5349917 },
    { lat: 48.7096428, lng: 20.5350891 },
    { lat: 48.708076, lng: 20.5357054 },
    { lat: 48.7075578, lng: 20.5360603 },
    { lat: 48.7071748, lng: 20.5364514 },
    { lat: 48.7069576, lng: 20.5367842 },
    { lat: 48.7061051, lng: 20.5369025 },
    { lat: 48.7056289, lng: 20.5368448 },
    { lat: 48.7047802, lng: 20.5369706 },
    { lat: 48.7045392, lng: 20.5372153 },
    { lat: 48.7043277, lng: 20.537307 },
    { lat: 48.7038283, lng: 20.5373536 },
    { lat: 48.703125, lng: 20.5369103 },
    { lat: 48.7025134, lng: 20.536872 },
    { lat: 48.7023346, lng: 20.5367905 },
    { lat: 48.7018289, lng: 20.5362674 },
    { lat: 48.7005706, lng: 20.5354042 },
    { lat: 48.6999149, lng: 20.5351804 },
    { lat: 48.6986231, lng: 20.5349563 },
    { lat: 48.6973784, lng: 20.5344021 },
    { lat: 48.6964336, lng: 20.5340956 },
    { lat: 48.6959419, lng: 20.5341644 },
    { lat: 48.6953171, lng: 20.534139 },
    { lat: 48.6949254, lng: 20.5342321 },
    { lat: 48.6945919, lng: 20.534167 },
    { lat: 48.6945911, lng: 20.5342006 },
    { lat: 48.6945723, lng: 20.5343045 },
    { lat: 48.6932704, lng: 20.5335952 },
    { lat: 48.6932987, lng: 20.5334798 },
    { lat: 48.6928604, lng: 20.5330184 },
    { lat: 48.6927122, lng: 20.5329996 },
    { lat: 48.6925553, lng: 20.533081 },
    { lat: 48.6921412, lng: 20.533537 },
    { lat: 48.6913055, lng: 20.5337159 },
    { lat: 48.6909765, lng: 20.5335599 },
    { lat: 48.6894817, lng: 20.5333614 },
    { lat: 48.6886286, lng: 20.5328803 },
    { lat: 48.6878915, lng: 20.5327718 },
    { lat: 48.6875097, lng: 20.5328035 },
    { lat: 48.6861715, lng: 20.5327211 },
    { lat: 48.6837732, lng: 20.5331479 },
    { lat: 48.6830069, lng: 20.5336423 },
    { lat: 48.6829832, lng: 20.5337345 },
    { lat: 48.6824071, lng: 20.5340727 },
    { lat: 48.6816561, lng: 20.5347139 },
    { lat: 48.6817259, lng: 20.5348652 },
    { lat: 48.6818409, lng: 20.5353257 },
    { lat: 48.6818055, lng: 20.5353384 },
    { lat: 48.682382, lng: 20.5375482 },
    { lat: 48.6825347, lng: 20.5375391 },
    { lat: 48.6826702, lng: 20.5377942 },
    { lat: 48.6827797, lng: 20.5378904 },
    { lat: 48.6828142, lng: 20.5380617 },
    { lat: 48.6826369, lng: 20.5381686 },
    { lat: 48.6827585, lng: 20.5388364 },
    { lat: 48.682977, lng: 20.5390474 },
    { lat: 48.6831856, lng: 20.53906 },
    { lat: 48.6842734, lng: 20.5388474 },
    { lat: 48.6844905, lng: 20.5389063 },
    { lat: 48.6845625, lng: 20.5390217 },
    { lat: 48.6853436, lng: 20.5391968 },
    { lat: 48.6853693, lng: 20.5392844 },
    { lat: 48.6852711, lng: 20.5393344 },
    { lat: 48.6851846, lng: 20.5395844 },
    { lat: 48.684733, lng: 20.5402505 },
    { lat: 48.6845429, lng: 20.540743 },
    { lat: 48.684469, lng: 20.5406584 },
    { lat: 48.6843424, lng: 20.5406495 },
    { lat: 48.6843358, lng: 20.5404377 },
    { lat: 48.6838471, lng: 20.5407093 },
    { lat: 48.6836745, lng: 20.540942 },
    { lat: 48.6835371, lng: 20.5409729 },
    { lat: 48.6835746, lng: 20.5408325 },
    { lat: 48.683191, lng: 20.540808 },
    { lat: 48.6831644, lng: 20.5411591 },
    { lat: 48.6830073, lng: 20.5409557 },
    { lat: 48.6825265, lng: 20.5409573 },
    { lat: 48.6824278, lng: 20.5410561 },
    { lat: 48.6823774, lng: 20.5413693 },
    { lat: 48.6820721, lng: 20.5414543 },
    { lat: 48.6819966, lng: 20.5415733 },
    { lat: 48.6819371, lng: 20.5418534 },
    { lat: 48.6819522, lng: 20.5420189 },
    { lat: 48.6811795, lng: 20.5422542 },
    { lat: 48.6809211, lng: 20.542264 },
    { lat: 48.6808913, lng: 20.5424343 },
    { lat: 48.6810482, lng: 20.5423199 },
    { lat: 48.6812244, lng: 20.5424899 },
    { lat: 48.6812403, lng: 20.5427668 },
    { lat: 48.6814823, lng: 20.5428842 },
    { lat: 48.6820282, lng: 20.542941 },
    { lat: 48.6822617, lng: 20.5427987 },
    { lat: 48.6822444, lng: 20.5429116 },
    { lat: 48.6823629, lng: 20.5431607 },
    { lat: 48.6825544, lng: 20.5433746 },
    { lat: 48.6826907, lng: 20.5437415 },
    { lat: 48.6830676, lng: 20.5439145 },
    { lat: 48.6831619, lng: 20.544002 },
    { lat: 48.6832396, lng: 20.5441832 },
    { lat: 48.6835291, lng: 20.544215 },
    { lat: 48.6837621, lng: 20.5445171 },
    { lat: 48.683914, lng: 20.5448769 },
    { lat: 48.6841319, lng: 20.5450776 },
    { lat: 48.6842255, lng: 20.5449874 },
    { lat: 48.6844774, lng: 20.5451649 },
    { lat: 48.6846989, lng: 20.5453967 },
    { lat: 48.6847577, lng: 20.5455588 },
    { lat: 48.6849357, lng: 20.5456168 },
    { lat: 48.6850333, lng: 20.5459774 },
    { lat: 48.6854083, lng: 20.5465222 },
    { lat: 48.6854361, lng: 20.5468199 },
    { lat: 48.6855105, lng: 20.5470309 },
    { lat: 48.6856619, lng: 20.5468325 },
    { lat: 48.6852944, lng: 20.5463383 },
    { lat: 48.6849301, lng: 20.5455318 },
    { lat: 48.6847169, lng: 20.5451828 },
    { lat: 48.6843877, lng: 20.5448368 },
    { lat: 48.68434, lng: 20.5446132 },
    { lat: 48.6840429, lng: 20.5440732 },
    { lat: 48.6839902, lng: 20.5440837 },
    { lat: 48.6837606, lng: 20.5437525 },
    { lat: 48.6836805, lng: 20.5434436 },
    { lat: 48.6839041, lng: 20.5433976 },
    { lat: 48.6840447, lng: 20.5435175 },
    { lat: 48.6845683, lng: 20.5436696 },
    { lat: 48.6849797, lng: 20.543866 },
    { lat: 48.6853404, lng: 20.5442973 },
    { lat: 48.6853919, lng: 20.5448645 },
    { lat: 48.6859504, lng: 20.5453229 },
    { lat: 48.6860735, lng: 20.5453345 },
    { lat: 48.6864789, lng: 20.5456092 },
    { lat: 48.6871654, lng: 20.546359 },
    { lat: 48.687508, lng: 20.5465816 },
    { lat: 48.6876866, lng: 20.5469307 },
    { lat: 48.6879893, lng: 20.547173 },
    { lat: 48.68811, lng: 20.5473624 },
    { lat: 48.6885587, lng: 20.5477977 },
    { lat: 48.6889254, lng: 20.5485834 },
    { lat: 48.6891584, lng: 20.5488538 },
    { lat: 48.6894812, lng: 20.5491473 },
    { lat: 48.6899935, lng: 20.5493933 },
    { lat: 48.6906705, lng: 20.5500064 },
    { lat: 48.6911574, lng: 20.550992 },
    { lat: 48.6914314, lng: 20.5517319 },
    { lat: 48.6915835, lng: 20.5523416 },
    { lat: 48.6916081, lng: 20.5526752 },
    { lat: 48.6917434, lng: 20.5530405 },
    { lat: 48.6944492, lng: 20.5505253 },
    { lat: 48.6979846, lng: 20.5478478 },
    { lat: 48.6983006, lng: 20.5481122 },
    { lat: 48.6987111, lng: 20.5486467 },
    { lat: 48.6989785, lng: 20.5489079 },
    { lat: 48.6990336, lng: 20.5491791 },
    { lat: 48.6991775, lng: 20.5493262 },
    { lat: 48.6992184, lng: 20.5492711 },
    { lat: 48.699293, lng: 20.5493104 },
    { lat: 48.6994383, lng: 20.5495583 },
    { lat: 48.6995733, lng: 20.5499786 },
    { lat: 48.6999064, lng: 20.5504287 },
    { lat: 48.7000467, lng: 20.5507516 },
    { lat: 48.7005354, lng: 20.5514798 },
    { lat: 48.700866, lng: 20.551777 },
    { lat: 48.7015599, lng: 20.5527014 },
    { lat: 48.7015854, lng: 20.5528101 },
    { lat: 48.7016759, lng: 20.5528837 },
    { lat: 48.7018793, lng: 20.5532672 },
    { lat: 48.7023787, lng: 20.5539212 },
    { lat: 48.7024959, lng: 20.5542581 },
    { lat: 48.702661, lng: 20.5544281 },
    { lat: 48.7028422, lng: 20.5544277 },
    { lat: 48.7029209, lng: 20.5545152 },
    { lat: 48.7030539, lng: 20.5548244 },
    { lat: 48.7032169, lng: 20.5550591 },
    { lat: 48.7033165, lng: 20.5548956 },
    { lat: 48.7033981, lng: 20.5549953 },
    { lat: 48.7037622, lng: 20.5560112 },
    { lat: 48.703878, lng: 20.5562523 },
    { lat: 48.7040405, lng: 20.5564489 },
    { lat: 48.704232, lng: 20.5569072 },
    { lat: 48.7042722, lng: 20.5571052 },
    { lat: 48.704257, lng: 20.5574616 },
    { lat: 48.7043446, lng: 20.557513 },
    { lat: 48.7043813, lng: 20.5577159 },
    { lat: 48.7044844, lng: 20.5578249 },
    { lat: 48.7046201, lng: 20.5581111 },
    { lat: 48.7047771, lng: 20.5582176 },
    { lat: 48.7048147, lng: 20.5583859 },
    { lat: 48.7047755, lng: 20.5588184 },
    { lat: 48.7049428, lng: 20.5593226 },
    { lat: 48.7051292, lng: 20.5594569 },
    { lat: 48.7053618, lng: 20.5594768 },
    { lat: 48.7055549, lng: 20.5598866 },
    { lat: 48.7057071, lng: 20.5600156 },
    { lat: 48.7057715, lng: 20.5601741 },
    { lat: 48.7058455, lng: 20.5606844 },
    { lat: 48.7059291, lng: 20.560877 },
    { lat: 48.7061378, lng: 20.5611303 },
    { lat: 48.7061604, lng: 20.5614026 },
    { lat: 48.706215, lng: 20.5615416 },
    { lat: 48.7063793, lng: 20.5616646 },
    { lat: 48.7066286, lng: 20.5617377 },
    { lat: 48.7067411, lng: 20.562192 },
    { lat: 48.7067485, lng: 20.562391 },
    { lat: 48.7068414, lng: 20.5624673 },
    { lat: 48.7069883, lng: 20.5627915 },
    { lat: 48.7070359, lng: 20.562858 },
    { lat: 48.7073518, lng: 20.5629355 },
    { lat: 48.7074211, lng: 20.5632284 },
    { lat: 48.7074608, lng: 20.5632354 },
    { lat: 48.7074633, lng: 20.563151 },
    { lat: 48.7076708, lng: 20.5633132 },
    { lat: 48.7077427, lng: 20.5633057 },
    { lat: 48.707967, lng: 20.5638644 },
    { lat: 48.708305, lng: 20.5639589 },
    { lat: 48.7085314, lng: 20.5645112 },
    { lat: 48.7090093, lng: 20.564553 },
    { lat: 48.7092359, lng: 20.564684 },
    { lat: 48.7094178, lng: 20.5648565 },
    { lat: 48.7094874, lng: 20.565035 },
    { lat: 48.7096104, lng: 20.5651027 },
    { lat: 48.7097612, lng: 20.5655263 },
    { lat: 48.7098267, lng: 20.5654078 },
    { lat: 48.7100446, lng: 20.5654226 },
    { lat: 48.7104647, lng: 20.5659191 },
    { lat: 48.7107443, lng: 20.5660232 },
    { lat: 48.7111123, lng: 20.5668407 },
    { lat: 48.7112306, lng: 20.566933 },
    { lat: 48.7114545, lng: 20.566958 },
    { lat: 48.7118347, lng: 20.5671321 },
    { lat: 48.7122036, lng: 20.5682511 },
    { lat: 48.7123212, lng: 20.5682969 },
    { lat: 48.7123172, lng: 20.5683995 },
    { lat: 48.7123867, lng: 20.5684864 },
    { lat: 48.7124075, lng: 20.5687434 },
    { lat: 48.7125961, lng: 20.5690919 },
    { lat: 48.7125509, lng: 20.5693396 },
    { lat: 48.7125742, lng: 20.5701826 },
    { lat: 48.7127235, lng: 20.5703861 },
    { lat: 48.7127507, lng: 20.5705096 },
    { lat: 48.7127229, lng: 20.5709459 },
    { lat: 48.7128311, lng: 20.5714986 },
    { lat: 48.7130001, lng: 20.5717186 },
    { lat: 48.7130405, lng: 20.571926 },
    { lat: 48.7132628, lng: 20.5724025 },
    { lat: 48.7138045, lng: 20.5729855 },
    { lat: 48.7138585, lng: 20.5733492 },
    { lat: 48.7138157, lng: 20.5735635 },
    { lat: 48.714241, lng: 20.5742925 },
    { lat: 48.7145111, lng: 20.5743814 },
    { lat: 48.7146858, lng: 20.5743196 },
    { lat: 48.7149028, lng: 20.5745877 },
    { lat: 48.7150358, lng: 20.5745922 },
    { lat: 48.7151721, lng: 20.5747512 },
    { lat: 48.7154162, lng: 20.574772 },
    { lat: 48.7156681, lng: 20.5751291 },
    { lat: 48.7157834, lng: 20.5751419 },
    { lat: 48.7161682, lng: 20.5755324 },
    { lat: 48.7164457, lng: 20.5754549 },
    { lat: 48.7167854, lng: 20.5756356 },
    { lat: 48.7167866, lng: 20.5757899 },
    { lat: 48.7169013, lng: 20.5757831 },
    { lat: 48.71714, lng: 20.5760244 },
    { lat: 48.7173388, lng: 20.5760166 },
    { lat: 48.7175815, lng: 20.5761239 },
    { lat: 48.7176866, lng: 20.576319 },
    { lat: 48.7178642, lng: 20.5763422 },
    { lat: 48.7181257, lng: 20.5762229 },
    { lat: 48.7184735, lng: 20.5766208 },
    { lat: 48.7188878, lng: 20.5765254 },
    { lat: 48.7191914, lng: 20.576671 },
    { lat: 48.71939, lng: 20.5764654 },
    { lat: 48.7200223, lng: 20.5763042 },
    { lat: 48.7202189, lng: 20.5762887 },
    { lat: 48.7202927, lng: 20.5764287 },
    { lat: 48.7210231, lng: 20.5762978 },
    { lat: 48.7211729, lng: 20.5763954 },
    { lat: 48.7212183, lng: 20.5764943 },
    { lat: 48.7216507, lng: 20.5765564 },
    { lat: 48.7218719, lng: 20.5764511 },
    { lat: 48.7220186, lng: 20.5764862 },
    { lat: 48.722246, lng: 20.5764297 },
    { lat: 48.7226263, lng: 20.5767557 },
    { lat: 48.7231288, lng: 20.5767991 },
    { lat: 48.7246886, lng: 20.575922 },
    { lat: 48.7259603, lng: 20.5758468 },
    { lat: 48.7273471, lng: 20.5762313 },
    { lat: 48.729427, lng: 20.5762723 },
    { lat: 48.7330356, lng: 20.5768581 },
    { lat: 48.7373594, lng: 20.5776638 },
    { lat: 48.7397506, lng: 20.5784224 },
    { lat: 48.744292, lng: 20.575697 },
  ],
  VyšnáSlaná: [
    { lat: 48.8099459, lng: 20.2951496 },
    { lat: 48.8092614, lng: 20.2959892 },
    { lat: 48.8089686, lng: 20.2964478 },
    { lat: 48.8081406, lng: 20.2967275 },
    { lat: 48.8080425, lng: 20.2964091 },
    { lat: 48.8077028, lng: 20.296013 },
    { lat: 48.8071421, lng: 20.2956146 },
    { lat: 48.8060851, lng: 20.2951082 },
    { lat: 48.8048121, lng: 20.2955033 },
    { lat: 48.804518, lng: 20.2953503 },
    { lat: 48.8040163, lng: 20.295246 },
    { lat: 48.8035336, lng: 20.2948585 },
    { lat: 48.8031626, lng: 20.2946475 },
    { lat: 48.80265, lng: 20.2945778 },
    { lat: 48.8026994, lng: 20.2949781 },
    { lat: 48.8024808, lng: 20.2952667 },
    { lat: 48.8021464, lng: 20.2955539 },
    { lat: 48.8018381, lng: 20.2962199 },
    { lat: 48.8013394, lng: 20.2964533 },
    { lat: 48.801035, lng: 20.297423 },
    { lat: 48.8008218, lng: 20.2976125 },
    { lat: 48.8005456, lng: 20.2976362 },
    { lat: 48.8004333, lng: 20.2976188 },
    { lat: 48.8003989, lng: 20.2975415 },
    { lat: 48.7997964, lng: 20.2974852 },
    { lat: 48.7997426, lng: 20.2976904 },
    { lat: 48.7991731, lng: 20.2978436 },
    { lat: 48.7983593, lng: 20.2978527 },
    { lat: 48.7982228, lng: 20.2980482 },
    { lat: 48.7974854, lng: 20.298598 },
    { lat: 48.7967553, lng: 20.2993942 },
    { lat: 48.7964337, lng: 20.299521 },
    { lat: 48.796373, lng: 20.2996114 },
    { lat: 48.7957092, lng: 20.2999735 },
    { lat: 48.7945639, lng: 20.3003747 },
    { lat: 48.7939124, lng: 20.2999299 },
    { lat: 48.7934074, lng: 20.2997184 },
    { lat: 48.7931935, lng: 20.2998908 },
    { lat: 48.7931233, lng: 20.3001099 },
    { lat: 48.7927885, lng: 20.3003296 },
    { lat: 48.7926563, lng: 20.30049 },
    { lat: 48.7924828, lng: 20.3008934 },
    { lat: 48.7923421, lng: 20.3014696 },
    { lat: 48.7919494, lng: 20.301597 },
    { lat: 48.791697, lng: 20.3014785 },
    { lat: 48.7914956, lng: 20.3019398 },
    { lat: 48.7910827, lng: 20.3020372 },
    { lat: 48.7907865, lng: 20.3028144 },
    { lat: 48.7898565, lng: 20.3021874 },
    { lat: 48.7894079, lng: 20.3022879 },
    { lat: 48.7872732, lng: 20.300715 },
    { lat: 48.7870638, lng: 20.300747 },
    { lat: 48.7869353, lng: 20.3006589 },
    { lat: 48.7867799, lng: 20.3008557 },
    { lat: 48.786559, lng: 20.3016868 },
    { lat: 48.7863475, lng: 20.3018965 },
    { lat: 48.7862, lng: 20.3023311 },
    { lat: 48.7862151, lng: 20.3027408 },
    { lat: 48.786109, lng: 20.3029974 },
    { lat: 48.7859405, lng: 20.3031532 },
    { lat: 48.7858434, lng: 20.3027391 },
    { lat: 48.7855846, lng: 20.302258 },
    { lat: 48.7851242, lng: 20.3011976 },
    { lat: 48.78495, lng: 20.2999426 },
    { lat: 48.7844506, lng: 20.2988311 },
    { lat: 48.7844133, lng: 20.2986273 },
    { lat: 48.784293, lng: 20.2984881 },
    { lat: 48.7841502, lng: 20.2986374 },
    { lat: 48.7839196, lng: 20.2983838 },
    { lat: 48.7837961, lng: 20.2981103 },
    { lat: 48.7834435, lng: 20.2977612 },
    { lat: 48.7833956, lng: 20.2974291 },
    { lat: 48.7827937, lng: 20.2966118 },
    { lat: 48.7826639, lng: 20.296576 },
    { lat: 48.7824795, lng: 20.2963504 },
    { lat: 48.7823183, lng: 20.2962892 },
    { lat: 48.7817741, lng: 20.2964579 },
    { lat: 48.7816872, lng: 20.2965625 },
    { lat: 48.7812709, lng: 20.2965452 },
    { lat: 48.7810904, lng: 20.2966532 },
    { lat: 48.7803827, lng: 20.296696 },
    { lat: 48.7802583, lng: 20.2967985 },
    { lat: 48.7797416, lng: 20.2967767 },
    { lat: 48.7793113, lng: 20.2972309 },
    { lat: 48.7790631, lng: 20.297793 },
    { lat: 48.7778355, lng: 20.2981948 },
    { lat: 48.7767803, lng: 20.2984258 },
    { lat: 48.7762084, lng: 20.2986281 },
    { lat: 48.7751395, lng: 20.299227 },
    { lat: 48.7738847, lng: 20.3004309 },
    { lat: 48.7735007, lng: 20.3007481 },
    { lat: 48.7737477, lng: 20.3019041 },
    { lat: 48.7736081, lng: 20.3035775 },
    { lat: 48.7736336, lng: 20.3047777 },
    { lat: 48.7735435, lng: 20.3052916 },
    { lat: 48.773432, lng: 20.3055533 },
    { lat: 48.7733718, lng: 20.3055641 },
    { lat: 48.7732482, lng: 20.3059154 },
    { lat: 48.7730836, lng: 20.306723 },
    { lat: 48.7730932, lng: 20.3074876 },
    { lat: 48.7734794, lng: 20.3083174 },
    { lat: 48.773577, lng: 20.30863 },
    { lat: 48.7738712, lng: 20.3090385 },
    { lat: 48.7737462, lng: 20.3092093 },
    { lat: 48.7737066, lng: 20.3094078 },
    { lat: 48.7733514, lng: 20.3097109 },
    { lat: 48.7733165, lng: 20.3108329 },
    { lat: 48.7728336, lng: 20.3128864 },
    { lat: 48.7722529, lng: 20.3144281 },
    { lat: 48.7722624, lng: 20.3145875 },
    { lat: 48.7724327, lng: 20.3147359 },
    { lat: 48.7719209, lng: 20.3159358 },
    { lat: 48.7719034, lng: 20.3166945 },
    { lat: 48.7720022, lng: 20.3175529 },
    { lat: 48.7720638, lng: 20.3177238 },
    { lat: 48.7719954, lng: 20.3181883 },
    { lat: 48.7718952, lng: 20.3184767 },
    { lat: 48.7715599, lng: 20.3183432 },
    { lat: 48.7712241, lng: 20.3183913 },
    { lat: 48.7711784, lng: 20.3186053 },
    { lat: 48.7708661, lng: 20.3188028 },
    { lat: 48.7704969, lng: 20.3195001 },
    { lat: 48.7704093, lng: 20.3195054 },
    { lat: 48.7693691, lng: 20.3204371 },
    { lat: 48.7687444, lng: 20.3214517 },
    { lat: 48.7683865, lng: 20.3222411 },
    { lat: 48.7682047, lng: 20.3231837 },
    { lat: 48.7681903, lng: 20.3235363 },
    { lat: 48.7679911, lng: 20.3241998 },
    { lat: 48.7678488, lng: 20.324135 },
    { lat: 48.7678417, lng: 20.3241588 },
    { lat: 48.767655, lng: 20.3248541 },
    { lat: 48.767358, lng: 20.3256734 },
    { lat: 48.7676104, lng: 20.3255856 },
    { lat: 48.7681257, lng: 20.3256328 },
    { lat: 48.7690277, lng: 20.325429 },
    { lat: 48.7692138, lng: 20.3255255 },
    { lat: 48.7702473, lng: 20.325638 },
    { lat: 48.7714696, lng: 20.3259086 },
    { lat: 48.7720036, lng: 20.3257689 },
    { lat: 48.7727308, lng: 20.3258783 },
    { lat: 48.7731688, lng: 20.3257925 },
    { lat: 48.7739185, lng: 20.3257803 },
    { lat: 48.7743706, lng: 20.3261439 },
    { lat: 48.7753335, lng: 20.3264333 },
    { lat: 48.7757853, lng: 20.3267572 },
    { lat: 48.7761443, lng: 20.3270441 },
    { lat: 48.7764305, lng: 20.3280315 },
    { lat: 48.7766216, lng: 20.3283708 },
    { lat: 48.7768173, lng: 20.3292342 },
    { lat: 48.7771881, lng: 20.3300205 },
    { lat: 48.7773869, lng: 20.3303017 },
    { lat: 48.7778649, lng: 20.3314699 },
    { lat: 48.7778009, lng: 20.3318212 },
    { lat: 48.7776126, lng: 20.3323364 },
    { lat: 48.7772572, lng: 20.3340014 },
    { lat: 48.7771314, lng: 20.335027 },
    { lat: 48.7771242, lng: 20.3355639 },
    { lat: 48.7770086, lng: 20.3362379 },
    { lat: 48.7769965, lng: 20.3367099 },
    { lat: 48.7772195, lng: 20.338596 },
    { lat: 48.7774036, lng: 20.3396843 },
    { lat: 48.7770602, lng: 20.3400368 },
    { lat: 48.7769463, lng: 20.3402619 },
    { lat: 48.7767499, lng: 20.3414404 },
    { lat: 48.776568, lng: 20.3418304 },
    { lat: 48.7765773, lng: 20.342825 },
    { lat: 48.7767559, lng: 20.3431354 },
    { lat: 48.7772641, lng: 20.3455553 },
    { lat: 48.7777108, lng: 20.3473765 },
    { lat: 48.7779359, lng: 20.3479857 },
    { lat: 48.7780379, lng: 20.3484694 },
    { lat: 48.7781148, lng: 20.3493137 },
    { lat: 48.7783081, lng: 20.3500039 },
    { lat: 48.7782798, lng: 20.3511089 },
    { lat: 48.7781447, lng: 20.3514905 },
    { lat: 48.7779111, lng: 20.3510381 },
    { lat: 48.7776551, lng: 20.3507651 },
    { lat: 48.7773293, lng: 20.3506391 },
    { lat: 48.7765703, lng: 20.3507267 },
    { lat: 48.776425, lng: 20.3507531 },
    { lat: 48.7757527, lng: 20.3512772 },
    { lat: 48.7752362, lng: 20.3514904 },
    { lat: 48.7749863, lng: 20.3517531 },
    { lat: 48.7735662, lng: 20.3539657 },
    { lat: 48.7737164, lng: 20.3549377 },
    { lat: 48.7736431, lng: 20.3549902 },
    { lat: 48.7741567, lng: 20.3557502 },
    { lat: 48.7743591, lng: 20.3562364 },
    { lat: 48.7747574, lng: 20.3585931 },
    { lat: 48.7746194, lng: 20.3591005 },
    { lat: 48.7744615, lng: 20.3594101 },
    { lat: 48.7738836, lng: 20.360106 },
    { lat: 48.7736574, lng: 20.3606043 },
    { lat: 48.7733339, lng: 20.3610635 },
    { lat: 48.7726387, lng: 20.3616044 },
    { lat: 48.7723276, lng: 20.3617726 },
    { lat: 48.7717485, lng: 20.3617352 },
    { lat: 48.7707901, lng: 20.3617939 },
    { lat: 48.7704522, lng: 20.3621111 },
    { lat: 48.7702023, lng: 20.3632223 },
    { lat: 48.7700307, lng: 20.3635588 },
    { lat: 48.7695243, lng: 20.3641497 },
    { lat: 48.7691502, lng: 20.3643498 },
    { lat: 48.7689898, lng: 20.3645403 },
    { lat: 48.7689381, lng: 20.3648033 },
    { lat: 48.7690071, lng: 20.3655165 },
    { lat: 48.7690183, lng: 20.3671212 },
    { lat: 48.7688078, lng: 20.3683199 },
    { lat: 48.7688699, lng: 20.3683194 },
    { lat: 48.7698696, lng: 20.3683169 },
    { lat: 48.7703128, lng: 20.3681842 },
    { lat: 48.771223, lng: 20.3680768 },
    { lat: 48.7715258, lng: 20.3677538 },
    { lat: 48.7720955, lng: 20.3668742 },
    { lat: 48.7723762, lng: 20.3666209 },
    { lat: 48.7725592, lng: 20.3661963 },
    { lat: 48.7730785, lng: 20.3655029 },
    { lat: 48.7737251, lng: 20.3650395 },
    { lat: 48.7739598, lng: 20.3649952 },
    { lat: 48.7741728, lng: 20.3650603 },
    { lat: 48.7743006, lng: 20.3648391 },
    { lat: 48.7746109, lng: 20.3646553 },
    { lat: 48.7755956, lng: 20.3643124 },
    { lat: 48.775778, lng: 20.3640806 },
    { lat: 48.7758982, lng: 20.3641007 },
    { lat: 48.7760766, lng: 20.3639414 },
    { lat: 48.7761237, lng: 20.3637634 },
    { lat: 48.7763922, lng: 20.3634255 },
    { lat: 48.7771193, lng: 20.3626272 },
    { lat: 48.7772348, lng: 20.3623799 },
    { lat: 48.7776593, lng: 20.3620603 },
    { lat: 48.7782156, lng: 20.3618876 },
    { lat: 48.7786288, lng: 20.3622299 },
    { lat: 48.7790397, lng: 20.3624328 },
    { lat: 48.7794344, lng: 20.3625364 },
    { lat: 48.7796103, lng: 20.3626905 },
    { lat: 48.78008, lng: 20.362865 },
    { lat: 48.7804976, lng: 20.3629195 },
    { lat: 48.7808393, lng: 20.3632246 },
    { lat: 48.781326, lng: 20.3635236 },
    { lat: 48.7815606, lng: 20.3637685 },
    { lat: 48.7819783, lng: 20.3640251 },
    { lat: 48.7822025, lng: 20.3640575 },
    { lat: 48.7824481, lng: 20.3639765 },
    { lat: 48.7829787, lng: 20.363485 },
    { lat: 48.7834048, lng: 20.3634349 },
    { lat: 48.7838528, lng: 20.3632864 },
    { lat: 48.7842696, lng: 20.3629393 },
    { lat: 48.7848909, lng: 20.3625537 },
    { lat: 48.7857995, lng: 20.3622284 },
    { lat: 48.7868377, lng: 20.3615599 },
    { lat: 48.7876636, lng: 20.3609309 },
    { lat: 48.7883516, lng: 20.3605497 },
    { lat: 48.7886801, lng: 20.3604831 },
    { lat: 48.7891304, lng: 20.3602473 },
    { lat: 48.7901528, lng: 20.3596054 },
    { lat: 48.7909085, lng: 20.3599553 },
    { lat: 48.7911803, lng: 20.359907 },
    { lat: 48.7912948, lng: 20.3597555 },
    { lat: 48.7913752, lng: 20.3596491 },
    { lat: 48.7937037, lng: 20.3565837 },
    { lat: 48.795325, lng: 20.3548294 },
    { lat: 48.7962313, lng: 20.353248 },
    { lat: 48.7965052, lng: 20.3518311 },
    { lat: 48.7968345, lng: 20.3514937 },
    { lat: 48.7996258, lng: 20.3479101 },
    { lat: 48.7991033, lng: 20.3448306 },
    { lat: 48.800497, lng: 20.3442654 },
    { lat: 48.8009652, lng: 20.3432994 },
    { lat: 48.8025249, lng: 20.3404243 },
    { lat: 48.8031393, lng: 20.3395459 },
    { lat: 48.8031258, lng: 20.3389909 },
    { lat: 48.8034681, lng: 20.3367889 },
    { lat: 48.8036192, lng: 20.3361825 },
    { lat: 48.8036982, lng: 20.3356082 },
    { lat: 48.8036229, lng: 20.3345089 },
    { lat: 48.8046689, lng: 20.3322156 },
    { lat: 48.8051041, lng: 20.3281444 },
    { lat: 48.8056723, lng: 20.3246867 },
    { lat: 48.8060073, lng: 20.3233679 },
    { lat: 48.8060421, lng: 20.3228736 },
    { lat: 48.8060031, lng: 20.3197337 },
    { lat: 48.8067783, lng: 20.318996 },
    { lat: 48.8076898, lng: 20.3149308 },
    { lat: 48.8088965, lng: 20.312927 },
    { lat: 48.8088856, lng: 20.3128475 },
    { lat: 48.8091726, lng: 20.3120384 },
    { lat: 48.8095126, lng: 20.3107968 },
    { lat: 48.8100055, lng: 20.3098056 },
    { lat: 48.8102898, lng: 20.3087833 },
    { lat: 48.8105512, lng: 20.3080386 },
    { lat: 48.8100513, lng: 20.3058178 },
    { lat: 48.810031, lng: 20.3044037 },
    { lat: 48.8098637, lng: 20.3011636 },
    { lat: 48.8099006, lng: 20.3007494 },
    { lat: 48.8098741, lng: 20.3004227 },
    { lat: 48.8097598, lng: 20.3002039 },
    { lat: 48.8094526, lng: 20.2992333 },
    { lat: 48.8095033, lng: 20.2990329 },
    { lat: 48.8095011, lng: 20.2980856 },
    { lat: 48.8096589, lng: 20.2974552 },
    { lat: 48.8097688, lng: 20.2972684 },
    { lat: 48.8098054, lng: 20.2965624 },
    { lat: 48.8099459, lng: 20.2951496 },
  ],
  Slavošovce: [
    { lat: 48.7579933, lng: 20.2648974 },
    { lat: 48.7571117, lng: 20.262107 },
    { lat: 48.7563773, lng: 20.2609666 },
    { lat: 48.7557605, lng: 20.2592342 },
    { lat: 48.7554296, lng: 20.2582659 },
    { lat: 48.7538888, lng: 20.2581518 },
    { lat: 48.7529338, lng: 20.2586064 },
    { lat: 48.7508568, lng: 20.2608744 },
    { lat: 48.7502041, lng: 20.2621128 },
    { lat: 48.7495995, lng: 20.2627984 },
    { lat: 48.748938, lng: 20.2632767 },
    { lat: 48.7478295, lng: 20.2642072 },
    { lat: 48.7473367, lng: 20.264362 },
    { lat: 48.7464401, lng: 20.2643295 },
    { lat: 48.7448806, lng: 20.2639368 },
    { lat: 48.7446334, lng: 20.2638934 },
    { lat: 48.7441253, lng: 20.2639461 },
    { lat: 48.7436786, lng: 20.2638098 },
    { lat: 48.7430381, lng: 20.2644767 },
    { lat: 48.7417721, lng: 20.2653831 },
    { lat: 48.7412982, lng: 20.2654166 },
    { lat: 48.7403055, lng: 20.2650313 },
    { lat: 48.739755, lng: 20.2649486 },
    { lat: 48.7389968, lng: 20.2651469 },
    { lat: 48.7376943, lng: 20.2651874 },
    { lat: 48.7374378, lng: 20.2654192 },
    { lat: 48.7368961, lng: 20.2656433 },
    { lat: 48.7361109, lng: 20.2654698 },
    { lat: 48.735782, lng: 20.2652982 },
    { lat: 48.7355397, lng: 20.2652634 },
    { lat: 48.7352524, lng: 20.2653203 },
    { lat: 48.7349399, lng: 20.2657902 },
    { lat: 48.7346604, lng: 20.2659264 },
    { lat: 48.734491, lng: 20.2660836 },
    { lat: 48.7338304, lng: 20.2663569 },
    { lat: 48.7334797, lng: 20.2663129 },
    { lat: 48.7332575, lng: 20.2666216 },
    { lat: 48.7332373, lng: 20.2667904 },
    { lat: 48.7331186, lng: 20.2669809 },
    { lat: 48.7330904, lng: 20.2671468 },
    { lat: 48.7326885, lng: 20.2675516 },
    { lat: 48.7327071, lng: 20.2677978 },
    { lat: 48.7325589, lng: 20.2678077 },
    { lat: 48.7323046, lng: 20.2682715 },
    { lat: 48.7321562, lng: 20.2683327 },
    { lat: 48.7319051, lng: 20.2687931 },
    { lat: 48.7314182, lng: 20.2689829 },
    { lat: 48.7313752, lng: 20.2691494 },
    { lat: 48.7312143, lng: 20.2692934 },
    { lat: 48.7309664, lng: 20.2698591 },
    { lat: 48.730954, lng: 20.2701192 },
    { lat: 48.7308019, lng: 20.2705706 },
    { lat: 48.7306929, lng: 20.2704334 },
    { lat: 48.730473, lng: 20.270769 },
    { lat: 48.729509, lng: 20.2714805 },
    { lat: 48.7290715, lng: 20.2719544 },
    { lat: 48.7287178, lng: 20.2722363 },
    { lat: 48.7285325, lng: 20.2722541 },
    { lat: 48.7274262, lng: 20.2720134 },
    { lat: 48.726694, lng: 20.2715793 },
    { lat: 48.7263101, lng: 20.2724108 },
    { lat: 48.7261674, lng: 20.2725866 },
    { lat: 48.7258933, lng: 20.2724654 },
    { lat: 48.7255661, lng: 20.2721325 },
    { lat: 48.7254934, lng: 20.2723181 },
    { lat: 48.7243751, lng: 20.2715763 },
    { lat: 48.7239199, lng: 20.271087 },
    { lat: 48.7237625, lng: 20.2706082 },
    { lat: 48.7232143, lng: 20.2707272 },
    { lat: 48.7232299, lng: 20.2706387 },
    { lat: 48.7228955, lng: 20.2701849 },
    { lat: 48.7229074, lng: 20.2701378 },
    { lat: 48.7224007, lng: 20.2695457 },
    { lat: 48.7223436, lng: 20.2696664 },
    { lat: 48.7210709, lng: 20.2680627 },
    { lat: 48.7211163, lng: 20.2679866 },
    { lat: 48.7201072, lng: 20.2665874 },
    { lat: 48.7199856, lng: 20.2663356 },
    { lat: 48.7199372, lng: 20.265989 },
    { lat: 48.7193498, lng: 20.2656058 },
    { lat: 48.7193677, lng: 20.2654618 },
    { lat: 48.7182445, lng: 20.2647343 },
    { lat: 48.7180921, lng: 20.2646996 },
    { lat: 48.7178174, lng: 20.263704 },
    { lat: 48.7177215, lng: 20.2629016 },
    { lat: 48.7176945, lng: 20.2622182 },
    { lat: 48.7175672, lng: 20.2610218 },
    { lat: 48.7176426, lng: 20.259765 },
    { lat: 48.7176112, lng: 20.2587157 },
    { lat: 48.7173514, lng: 20.2578982 },
    { lat: 48.7170876, lng: 20.2562708 },
    { lat: 48.7171191, lng: 20.2544741 },
    { lat: 48.7169967, lng: 20.2538853 },
    { lat: 48.71629, lng: 20.2528821 },
    { lat: 48.715532, lng: 20.2522499 },
    { lat: 48.7149493, lng: 20.251671 },
    { lat: 48.7137241, lng: 20.2503101 },
    { lat: 48.7134714, lng: 20.249757 },
    { lat: 48.7128652, lng: 20.2490698 },
    { lat: 48.7126072, lng: 20.2484434 },
    { lat: 48.7116753, lng: 20.2477461 },
    { lat: 48.7115462, lng: 20.2472482 },
    { lat: 48.7114172, lng: 20.2470356 },
    { lat: 48.7109896, lng: 20.2465886 },
    { lat: 48.7105801, lng: 20.2459999 },
    { lat: 48.7101225, lng: 20.245653 },
    { lat: 48.7091395, lng: 20.2444932 },
    { lat: 48.7089458, lng: 20.2441253 },
    { lat: 48.7085043, lng: 20.2438715 },
    { lat: 48.7080643, lng: 20.2437916 },
    { lat: 48.7079867, lng: 20.2436795 },
    { lat: 48.7078294, lng: 20.2437166 },
    { lat: 48.7074137, lng: 20.2435168 },
    { lat: 48.7072615, lng: 20.2435413 },
    { lat: 48.7069824, lng: 20.2431761 },
    { lat: 48.706928, lng: 20.2423459 },
    { lat: 48.70618, lng: 20.2411782 },
    { lat: 48.7059554, lng: 20.240658 },
    { lat: 48.7056265, lng: 20.2401733 },
    { lat: 48.7052315, lng: 20.2391288 },
    { lat: 48.7045936, lng: 20.2377323 },
    { lat: 48.70444, lng: 20.237116 },
    { lat: 48.704108, lng: 20.23768 },
    { lat: 48.7031, lng: 20.239537 },
    { lat: 48.702303, lng: 20.240664 },
    { lat: 48.70217, lng: 20.240852 },
    { lat: 48.7016, lng: 20.241923 },
    { lat: 48.701379, lng: 20.242497 },
    { lat: 48.701188, lng: 20.243395 },
    { lat: 48.700976, lng: 20.243675 },
    { lat: 48.700568, lng: 20.244387 },
    { lat: 48.700175, lng: 20.246423 },
    { lat: 48.700138, lng: 20.246582 },
    { lat: 48.700129, lng: 20.246623 },
    { lat: 48.699803, lng: 20.248063 },
    { lat: 48.699346, lng: 20.249935 },
    { lat: 48.698716, lng: 20.251053 },
    { lat: 48.698116, lng: 20.251914 },
    { lat: 48.697776, lng: 20.252964 },
    { lat: 48.696993, lng: 20.253941 },
    { lat: 48.695749, lng: 20.254631 },
    { lat: 48.694989, lng: 20.255168 },
    { lat: 48.693914, lng: 20.25626 },
    { lat: 48.693728, lng: 20.256621 },
    { lat: 48.693361, lng: 20.257703 },
    { lat: 48.693151, lng: 20.260505 },
    { lat: 48.693011, lng: 20.262497 },
    { lat: 48.692741, lng: 20.263524 },
    { lat: 48.6932778, lng: 20.2638429 },
    { lat: 48.6937003, lng: 20.2645021 },
    { lat: 48.6939801, lng: 20.2645175 },
    { lat: 48.6941768, lng: 20.2646744 },
    { lat: 48.6946039, lng: 20.2647928 },
    { lat: 48.6952403, lng: 20.2656804 },
    { lat: 48.6956325, lng: 20.2659737 },
    { lat: 48.695796, lng: 20.2661807 },
    { lat: 48.6958658, lng: 20.2663224 },
    { lat: 48.6958987, lng: 20.2667864 },
    { lat: 48.695983, lng: 20.2671517 },
    { lat: 48.698395, lng: 20.2689653 },
    { lat: 48.6990236, lng: 20.2699447 },
    { lat: 48.6995129, lng: 20.2710946 },
    { lat: 48.6997495, lng: 20.2715278 },
    { lat: 48.7007967, lng: 20.2728949 },
    { lat: 48.7009145, lng: 20.2731645 },
    { lat: 48.7011072, lng: 20.2739718 },
    { lat: 48.7014801, lng: 20.2745158 },
    { lat: 48.7018384, lng: 20.2756073 },
    { lat: 48.7022338, lng: 20.2763219 },
    { lat: 48.7023765, lng: 20.2766848 },
    { lat: 48.7024766, lng: 20.2770295 },
    { lat: 48.7025999, lng: 20.2778088 },
    { lat: 48.7028109, lng: 20.278392 },
    { lat: 48.7029677, lng: 20.2791723 },
    { lat: 48.7032355, lng: 20.2797112 },
    { lat: 48.7034906, lng: 20.280094 },
    { lat: 48.7036151, lng: 20.2804807 },
    { lat: 48.7039632, lng: 20.2807753 },
    { lat: 48.7041214, lng: 20.2810399 },
    { lat: 48.7043075, lng: 20.2815651 },
    { lat: 48.7043783, lng: 20.2819067 },
    { lat: 48.7043403, lng: 20.2821405 },
    { lat: 48.7040938, lng: 20.2826885 },
    { lat: 48.7037997, lng: 20.2837724 },
    { lat: 48.7038086, lng: 20.284684 },
    { lat: 48.7039456, lng: 20.2851904 },
    { lat: 48.7040476, lng: 20.2853471 },
    { lat: 48.7042267, lng: 20.2854379 },
    { lat: 48.7043261, lng: 20.2855895 },
    { lat: 48.7043153, lng: 20.2868857 },
    { lat: 48.7042581, lng: 20.2872667 },
    { lat: 48.7043973, lng: 20.2872852 },
    { lat: 48.7047734, lng: 20.2879385 },
    { lat: 48.704789, lng: 20.288105 },
    { lat: 48.7046713, lng: 20.288726 },
    { lat: 48.7051289, lng: 20.2891342 },
    { lat: 48.7055748, lng: 20.2888435 },
    { lat: 48.7057797, lng: 20.2890733 },
    { lat: 48.7059241, lng: 20.2890778 },
    { lat: 48.7062982, lng: 20.2894586 },
    { lat: 48.7065152, lng: 20.2897618 },
    { lat: 48.7078505, lng: 20.2917892 },
    { lat: 48.7080716, lng: 20.2913979 },
    { lat: 48.7098413, lng: 20.2907496 },
    { lat: 48.7105979, lng: 20.2921284 },
    { lat: 48.7109788, lng: 20.2924451 },
    { lat: 48.7109723, lng: 20.2925081 },
    { lat: 48.7111973, lng: 20.2925346 },
    { lat: 48.7114216, lng: 20.2926529 },
    { lat: 48.7117486, lng: 20.2926632 },
    { lat: 48.7122486, lng: 20.2930947 },
    { lat: 48.7123885, lng: 20.2935622 },
    { lat: 48.7125649, lng: 20.2951835 },
    { lat: 48.712662, lng: 20.2953891 },
    { lat: 48.7129934, lng: 20.2958298 },
    { lat: 48.7131282, lng: 20.2961393 },
    { lat: 48.713579, lng: 20.2964053 },
    { lat: 48.713768, lng: 20.2966879 },
    { lat: 48.7141861, lng: 20.2970648 },
    { lat: 48.7142329, lng: 20.2971983 },
    { lat: 48.7143943, lng: 20.2973803 },
    { lat: 48.714638, lng: 20.297535 },
    { lat: 48.7147926, lng: 20.2977993 },
    { lat: 48.7148761, lng: 20.2978343 },
    { lat: 48.7150067, lng: 20.2980904 },
    { lat: 48.7153269, lng: 20.2981851 },
    { lat: 48.715404, lng: 20.298461 },
    { lat: 48.7154672, lng: 20.2985206 },
    { lat: 48.7156886, lng: 20.2985942 },
    { lat: 48.7159658, lng: 20.2988857 },
    { lat: 48.7165276, lng: 20.2991977 },
    { lat: 48.7166346, lng: 20.299283 },
    { lat: 48.7166944, lng: 20.2994313 },
    { lat: 48.7172167, lng: 20.2994802 },
    { lat: 48.7173088, lng: 20.2996499 },
    { lat: 48.7175313, lng: 20.2997819 },
    { lat: 48.7179419, lng: 20.3002273 },
    { lat: 48.7180881, lng: 20.3005979 },
    { lat: 48.7181953, lng: 20.3007207 },
    { lat: 48.7182091, lng: 20.3010111 },
    { lat: 48.7183162, lng: 20.301217 },
    { lat: 48.7182451, lng: 20.3016178 },
    { lat: 48.7182986, lng: 20.3018023 },
    { lat: 48.7183302, lng: 20.3024942 },
    { lat: 48.7184568, lng: 20.3031359 },
    { lat: 48.7183616, lng: 20.3042728 },
    { lat: 48.7184082, lng: 20.3051192 },
    { lat: 48.7185635, lng: 20.3066695 },
    { lat: 48.7186586, lng: 20.3072305 },
    { lat: 48.718477, lng: 20.3080289 },
    { lat: 48.7184724, lng: 20.3084843 },
    { lat: 48.7186625, lng: 20.3090551 },
    { lat: 48.718583, lng: 20.3093553 },
    { lat: 48.7182653, lng: 20.3096976 },
    { lat: 48.7184435, lng: 20.3104234 },
    { lat: 48.7186735, lng: 20.3105487 },
    { lat: 48.7188107, lng: 20.3107145 },
    { lat: 48.7191152, lng: 20.3114761 },
    { lat: 48.7200325, lng: 20.3106257 },
    { lat: 48.7200065, lng: 20.3103045 },
    { lat: 48.7202504, lng: 20.3096871 },
    { lat: 48.7204481, lng: 20.3094155 },
    { lat: 48.7205795, lng: 20.3090646 },
    { lat: 48.7206311, lng: 20.3086813 },
    { lat: 48.7208357, lng: 20.3080128 },
    { lat: 48.7211381, lng: 20.3077365 },
    { lat: 48.7214412, lng: 20.307341 },
    { lat: 48.721713, lng: 20.3066883 },
    { lat: 48.7223223, lng: 20.3055378 },
    { lat: 48.7223761, lng: 20.3050729 },
    { lat: 48.7223737, lng: 20.304235 },
    { lat: 48.7228489, lng: 20.3033407 },
    { lat: 48.7233903, lng: 20.3030779 },
    { lat: 48.724111, lng: 20.3031636 },
    { lat: 48.7244745, lng: 20.3030979 },
    { lat: 48.7252265, lng: 20.3027785 },
    { lat: 48.7257531, lng: 20.3024802 },
    { lat: 48.7259538, lng: 20.3021095 },
    { lat: 48.7276295, lng: 20.2999521 },
    { lat: 48.7282465, lng: 20.2988617 },
    { lat: 48.7292157, lng: 20.2975539 },
    { lat: 48.7305356, lng: 20.296984 },
    { lat: 48.7310514, lng: 20.2968447 },
    { lat: 48.7315519, lng: 20.297229 },
    { lat: 48.7322486, lng: 20.2975315 },
    { lat: 48.732524, lng: 20.2976056 },
    { lat: 48.73265, lng: 20.2975758 },
    { lat: 48.7332071, lng: 20.2972494 },
    { lat: 48.7348549, lng: 20.2970011 },
    { lat: 48.7357459, lng: 20.2966916 },
    { lat: 48.7365972, lng: 20.2960858 },
    { lat: 48.7377878, lng: 20.2954861 },
    { lat: 48.7385632, lng: 20.2946423 },
    { lat: 48.7389361, lng: 20.2935849 },
    { lat: 48.7391668, lng: 20.2924882 },
    { lat: 48.7393396, lng: 20.2920709 },
    { lat: 48.740304, lng: 20.2905355 },
    { lat: 48.7403681, lng: 20.2903043 },
    { lat: 48.7406823, lng: 20.289641 },
    { lat: 48.7416252, lng: 20.2885619 },
    { lat: 48.7418839, lng: 20.2884384 },
    { lat: 48.7422027, lng: 20.2881261 },
    { lat: 48.7425641, lng: 20.287491 },
    { lat: 48.7430609, lng: 20.2872962 },
    { lat: 48.7435153, lng: 20.2867402 },
    { lat: 48.7436961, lng: 20.2866087 },
    { lat: 48.7438925, lng: 20.2868201 },
    { lat: 48.745285, lng: 20.2860331 },
    { lat: 48.7471044, lng: 20.2839316 },
    { lat: 48.7490118, lng: 20.2835221 },
    { lat: 48.749702, lng: 20.2830347 },
    { lat: 48.7503002, lng: 20.2824399 },
    { lat: 48.7508735, lng: 20.2809787 },
    { lat: 48.7514968, lng: 20.2797932 },
    { lat: 48.7516841, lng: 20.2789987 },
    { lat: 48.7517726, lng: 20.2770689 },
    { lat: 48.752342, lng: 20.2747126 },
    { lat: 48.7524248, lng: 20.2731548 },
    { lat: 48.7530858, lng: 20.271814 },
    { lat: 48.7538839, lng: 20.2708912 },
    { lat: 48.7547673, lng: 20.2696445 },
    { lat: 48.7553636, lng: 20.2686614 },
    { lat: 48.7559779, lng: 20.2679032 },
    { lat: 48.7564895, lng: 20.2671115 },
    { lat: 48.757542, lng: 20.2657319 },
    { lat: 48.7579933, lng: 20.2648974 },
  ],
  Pašková: [
    { lat: 48.5811788, lng: 20.3704133 },
    { lat: 48.58115, lng: 20.370631 },
    { lat: 48.5815873, lng: 20.3720341 },
    { lat: 48.5814925, lng: 20.3731115 },
    { lat: 48.5809614, lng: 20.3736425 },
    { lat: 48.5807039, lng: 20.3744106 },
    { lat: 48.5803421, lng: 20.3748371 },
    { lat: 48.5800193, lng: 20.3753441 },
    { lat: 48.578908, lng: 20.3765751 },
    { lat: 48.578646, lng: 20.3769342 },
    { lat: 48.5784988, lng: 20.3775517 },
    { lat: 48.5782257, lng: 20.3780642 },
    { lat: 48.5775829, lng: 20.37868 },
    { lat: 48.5773664, lng: 20.3789956 },
    { lat: 48.577099, lng: 20.3807911 },
    { lat: 48.5769362, lng: 20.3814444 },
    { lat: 48.5769238, lng: 20.3817357 },
    { lat: 48.5769961, lng: 20.3821163 },
    { lat: 48.576976, lng: 20.3825313 },
    { lat: 48.5769081, lng: 20.3828146 },
    { lat: 48.5767573, lng: 20.3831077 },
    { lat: 48.5764267, lng: 20.3834443 },
    { lat: 48.5762912, lng: 20.3834608 },
    { lat: 48.5757321, lng: 20.383855 },
    { lat: 48.5764862, lng: 20.3898657 },
    { lat: 48.576653, lng: 20.3903899 },
    { lat: 48.5767048, lng: 20.3907434 },
    { lat: 48.5767082, lng: 20.3912101 },
    { lat: 48.5765424, lng: 20.3912306 },
    { lat: 48.5762923, lng: 20.3915277 },
    { lat: 48.5760522, lng: 20.3916654 },
    { lat: 48.5759981, lng: 20.39219 },
    { lat: 48.5758058, lng: 20.3923437 },
    { lat: 48.575697, lng: 20.3922931 },
    { lat: 48.5755255, lng: 20.3925453 },
    { lat: 48.5755751, lng: 20.3926093 },
    { lat: 48.5758038, lng: 20.3926092 },
    { lat: 48.5764747, lng: 20.3927813 },
    { lat: 48.576828, lng: 20.3927159 },
    { lat: 48.5778333, lng: 20.3921241 },
    { lat: 48.578171, lng: 20.3932571 },
    { lat: 48.5785947, lng: 20.393501 },
    { lat: 48.5789117, lng: 20.3945395 },
    { lat: 48.5791291, lng: 20.3966194 },
    { lat: 48.5794625, lng: 20.3976198 },
    { lat: 48.5792797, lng: 20.397873 },
    { lat: 48.5786556, lng: 20.3981551 },
    { lat: 48.5776503, lng: 20.3987507 },
    { lat: 48.5772889, lng: 20.3988132 },
    { lat: 48.5769894, lng: 20.3987512 },
    { lat: 48.5767072, lng: 20.3988498 },
    { lat: 48.5762012, lng: 20.3989025 },
    { lat: 48.5752388, lng: 20.399293 },
    { lat: 48.5756281, lng: 20.4015086 },
    { lat: 48.5762909, lng: 20.4058934 },
    { lat: 48.5763915, lng: 20.4059646 },
    { lat: 48.5767453, lng: 20.4080553 },
    { lat: 48.577267, lng: 20.4078584 },
    { lat: 48.5778261, lng: 20.4074316 },
    { lat: 48.5788042, lng: 20.4068339 },
    { lat: 48.5792346, lng: 20.40646 },
    { lat: 48.5794939, lng: 20.4060832 },
    { lat: 48.5798668, lng: 20.4060603 },
    { lat: 48.580463, lng: 20.4058355 },
    { lat: 48.5806528, lng: 20.4055863 },
    { lat: 48.5809464, lng: 20.4054949 },
    { lat: 48.5811424, lng: 20.4052602 },
    { lat: 48.5813802, lng: 20.4047985 },
    { lat: 48.5815828, lng: 20.4045859 },
    { lat: 48.5818946, lng: 20.4046135 },
    { lat: 48.5821636, lng: 20.4045207 },
    { lat: 48.5825301, lng: 20.4045947 },
    { lat: 48.5831139, lng: 20.4044591 },
    { lat: 48.5836647, lng: 20.4045242 },
    { lat: 48.5841589, lng: 20.4044317 },
    { lat: 48.5843195, lng: 20.4045653 },
    { lat: 48.5846285, lng: 20.4046354 },
    { lat: 48.5859814, lng: 20.4039743 },
    { lat: 48.5879387, lng: 20.4028055 },
    { lat: 48.5888169, lng: 20.401692 },
    { lat: 48.5890904, lng: 20.4014821 },
    { lat: 48.5893871, lng: 20.4011407 },
    { lat: 48.5895206, lng: 20.4010405 },
    { lat: 48.5897536, lng: 20.4009839 },
    { lat: 48.5901296, lng: 20.4004506 },
    { lat: 48.5903259, lng: 20.3989327 },
    { lat: 48.5905767, lng: 20.3982815 },
    { lat: 48.5908546, lng: 20.3972246 },
    { lat: 48.5911337, lng: 20.3968364 },
    { lat: 48.5914799, lng: 20.3968506 },
    { lat: 48.5918666, lng: 20.3967143 },
    { lat: 48.592309, lng: 20.3963313 },
    { lat: 48.59244, lng: 20.3960759 },
    { lat: 48.5929868, lng: 20.3960647 },
    { lat: 48.5935979, lng: 20.3957299 },
    { lat: 48.5944351, lng: 20.3955411 },
    { lat: 48.5946592, lng: 20.3953985 },
    { lat: 48.5964256, lng: 20.3905271 },
    { lat: 48.5967322, lng: 20.3896596 },
    { lat: 48.5968141, lng: 20.3887713 },
    { lat: 48.5966322, lng: 20.3885517 },
    { lat: 48.596462, lng: 20.3882251 },
    { lat: 48.5967568, lng: 20.3879883 },
    { lat: 48.5968948, lng: 20.3880057 },
    { lat: 48.5977984, lng: 20.3874321 },
    { lat: 48.5978045, lng: 20.3873565 },
    { lat: 48.5983711, lng: 20.3872717 },
    { lat: 48.5992396, lng: 20.386932 },
    { lat: 48.5992548, lng: 20.3868246 },
    { lat: 48.5994954, lng: 20.3865218 },
    { lat: 48.600013, lng: 20.3859862 },
    { lat: 48.6004066, lng: 20.386416 },
    { lat: 48.6004285, lng: 20.3867503 },
    { lat: 48.6003404, lng: 20.3872945 },
    { lat: 48.600767, lng: 20.3873226 },
    { lat: 48.6009735, lng: 20.3875372 },
    { lat: 48.6011811, lng: 20.3875493 },
    { lat: 48.6011069, lng: 20.3869082 },
    { lat: 48.6011266, lng: 20.3862508 },
    { lat: 48.6009644, lng: 20.3855755 },
    { lat: 48.6009843, lng: 20.3852699 },
    { lat: 48.6009418, lng: 20.3850509 },
    { lat: 48.6011303, lng: 20.384706 },
    { lat: 48.6010187, lng: 20.3842668 },
    { lat: 48.6002254, lng: 20.3822429 },
    { lat: 48.6000569, lng: 20.3823388 },
    { lat: 48.5996851, lng: 20.3808736 },
    { lat: 48.5995, lng: 20.380339 },
    { lat: 48.5991828, lng: 20.3796774 },
    { lat: 48.6004653, lng: 20.3785651 },
    { lat: 48.6012583, lng: 20.3779874 },
    { lat: 48.6021384, lng: 20.3770147 },
    { lat: 48.6019209, lng: 20.3766847 },
    { lat: 48.6021697, lng: 20.3754286 },
    { lat: 48.6018969, lng: 20.3751608 },
    { lat: 48.6029107, lng: 20.3715525 },
    { lat: 48.6025172, lng: 20.371343 },
    { lat: 48.6026254, lng: 20.3708144 },
    { lat: 48.6021072, lng: 20.3705209 },
    { lat: 48.6021375, lng: 20.3703724 },
    { lat: 48.6021126, lng: 20.3698451 },
    { lat: 48.6022244, lng: 20.3694147 },
    { lat: 48.602343, lng: 20.3692542 },
    { lat: 48.6023739, lng: 20.3690593 },
    { lat: 48.6022293, lng: 20.3687568 },
    { lat: 48.6023071, lng: 20.3685272 },
    { lat: 48.6025492, lng: 20.3683259 },
    { lat: 48.6025484, lng: 20.3681915 },
    { lat: 48.6024317, lng: 20.3680228 },
    { lat: 48.6022501, lng: 20.368159 },
    { lat: 48.6021277, lng: 20.3681128 },
    { lat: 48.6021283, lng: 20.3680676 },
    { lat: 48.6021684, lng: 20.3679442 },
    { lat: 48.602232, lng: 20.3679852 },
    { lat: 48.6022862, lng: 20.3679278 },
    { lat: 48.6022555, lng: 20.3676892 },
    { lat: 48.6024366, lng: 20.3675701 },
    { lat: 48.6024909, lng: 20.3674618 },
    { lat: 48.6024608, lng: 20.3673769 },
    { lat: 48.6025484, lng: 20.3670269 },
    { lat: 48.602654, lng: 20.3669554 },
    { lat: 48.6027722, lng: 20.367046 },
    { lat: 48.6029179, lng: 20.367025 },
    { lat: 48.6030149, lng: 20.366946 },
    { lat: 48.6029509, lng: 20.3664261 },
    { lat: 48.6031107, lng: 20.3662448 },
    { lat: 48.6030714, lng: 20.3661067 },
    { lat: 48.6029211, lng: 20.36609 },
    { lat: 48.602924, lng: 20.365993 },
    { lat: 48.6028384, lng: 20.365934 },
    { lat: 48.6028136, lng: 20.3658173 },
    { lat: 48.6026868, lng: 20.365858 },
    { lat: 48.6026452, lng: 20.3658016 },
    { lat: 48.6027313, lng: 20.3656125 },
    { lat: 48.6027264, lng: 20.3654543 },
    { lat: 48.6026124, lng: 20.3653871 },
    { lat: 48.602508, lng: 20.3654276 },
    { lat: 48.6024977, lng: 20.365309 },
    { lat: 48.6024208, lng: 20.3652104 },
    { lat: 48.6006401, lng: 20.3645645 },
    { lat: 48.5984009, lng: 20.3639433 },
    { lat: 48.5966684, lng: 20.3645633 },
    { lat: 48.5958762, lng: 20.3652524 },
    { lat: 48.5958127, lng: 20.3653857 },
    { lat: 48.5957328, lng: 20.3658517 },
    { lat: 48.5955052, lng: 20.366257 },
    { lat: 48.595257, lng: 20.3670878 },
    { lat: 48.5949971, lng: 20.3675838 },
    { lat: 48.5949821, lng: 20.3679284 },
    { lat: 48.5949119, lng: 20.3681748 },
    { lat: 48.5947128, lng: 20.3684744 },
    { lat: 48.5944903, lng: 20.3691279 },
    { lat: 48.5943078, lng: 20.3692976 },
    { lat: 48.5940916, lng: 20.369704 },
    { lat: 48.5936378, lng: 20.3701128 },
    { lat: 48.5932016, lng: 20.3701939 },
    { lat: 48.5929346, lng: 20.3701629 },
    { lat: 48.5927117, lng: 20.3700178 },
    { lat: 48.5925109, lng: 20.3696032 },
    { lat: 48.5923049, lng: 20.3689935 },
    { lat: 48.5921316, lng: 20.3687053 },
    { lat: 48.5919111, lng: 20.3684753 },
    { lat: 48.5916656, lng: 20.3683596 },
    { lat: 48.5914314, lng: 20.3682994 },
    { lat: 48.5912322, lng: 20.3683365 },
    { lat: 48.5910527, lng: 20.3684967 },
    { lat: 48.5905687, lng: 20.3685329 },
    { lat: 48.5901919, lng: 20.3686866 },
    { lat: 48.5898785, lng: 20.3686249 },
    { lat: 48.5891096, lng: 20.3681052 },
    { lat: 48.5884234, lng: 20.3680628 },
    { lat: 48.5878781, lng: 20.3677952 },
    { lat: 48.5872091, lng: 20.3670929 },
    { lat: 48.5866281, lng: 20.3668676 },
    { lat: 48.5860313, lng: 20.3668682 },
    { lat: 48.585843, lng: 20.366948 },
    { lat: 48.5853887, lng: 20.3674586 },
    { lat: 48.5852038, lng: 20.367509 },
    { lat: 48.585036, lng: 20.3677162 },
    { lat: 48.5843763, lng: 20.3690888 },
    { lat: 48.5834548, lng: 20.369864 },
    { lat: 48.5831927, lng: 20.3699276 },
    { lat: 48.5828029, lng: 20.37016 },
    { lat: 48.5815358, lng: 20.3704115 },
    { lat: 48.5811947, lng: 20.3704134 },
    { lat: 48.5811788, lng: 20.3704133 },
  ],
  Bretka: [
    { lat: 48.5078378, lng: 20.3587341 },
    { lat: 48.508522, lng: 20.358664 },
    { lat: 48.5089334, lng: 20.3583866 },
    { lat: 48.5092252, lng: 20.3583308 },
    { lat: 48.5097852, lng: 20.3584811 },
    { lat: 48.5105333, lng: 20.3590187 },
    { lat: 48.5109728, lng: 20.3591672 },
    { lat: 48.5114114, lng: 20.3594749 },
    { lat: 48.5115501, lng: 20.3594176 },
    { lat: 48.5117446, lng: 20.3591756 },
    { lat: 48.5118019, lng: 20.3589429 },
    { lat: 48.5115422, lng: 20.3584621 },
    { lat: 48.5112349, lng: 20.3575065 },
    { lat: 48.5110503, lng: 20.3571633 },
    { lat: 48.5102278, lng: 20.3563432 },
    { lat: 48.5097785, lng: 20.3563114 },
    { lat: 48.5095104, lng: 20.3561357 },
    { lat: 48.5094173, lng: 20.3559127 },
    { lat: 48.5092733, lng: 20.3553113 },
    { lat: 48.5093123, lng: 20.3546026 },
    { lat: 48.5092098, lng: 20.3538477 },
    { lat: 48.5090453, lng: 20.353127 },
    { lat: 48.5088823, lng: 20.3530188 },
    { lat: 48.5088004, lng: 20.352879 },
    { lat: 48.5087992, lng: 20.3524711 },
    { lat: 48.5087287, lng: 20.3519343 },
    { lat: 48.5087794, lng: 20.3512415 },
    { lat: 48.5087183, lng: 20.3508527 },
    { lat: 48.5081862, lng: 20.349745 },
    { lat: 48.5079348, lng: 20.3491002 },
    { lat: 48.5077866, lng: 20.3476945 },
    { lat: 48.5075662, lng: 20.3468059 },
    { lat: 48.5072569, lng: 20.3463818 },
    { lat: 48.5069276, lng: 20.3448671 },
    { lat: 48.5055984, lng: 20.340324 },
    { lat: 48.5037756, lng: 20.3384918 },
    { lat: 48.5033032, lng: 20.3374398 },
    { lat: 48.5027259, lng: 20.3364962 },
    { lat: 48.5025227, lng: 20.3362543 },
    { lat: 48.502086, lng: 20.3360329 },
    { lat: 48.5018944, lng: 20.3364316 },
    { lat: 48.5015421, lng: 20.3375007 },
    { lat: 48.5014716, lng: 20.3380886 },
    { lat: 48.5014803, lng: 20.3403317 },
    { lat: 48.5014436, lng: 20.3407231 },
    { lat: 48.5014873, lng: 20.3425625 },
    { lat: 48.5014225, lng: 20.3430355 },
    { lat: 48.5013307, lng: 20.3432542 },
    { lat: 48.5011372, lng: 20.3435149 },
    { lat: 48.5008938, lng: 20.3436884 },
    { lat: 48.5006305, lng: 20.3436356 },
    { lat: 48.499843, lng: 20.3431633 },
    { lat: 48.4995393, lng: 20.3428549 },
    { lat: 48.4992525, lng: 20.3423746 },
    { lat: 48.4990208, lng: 20.3411721 },
    { lat: 48.4989247, lng: 20.339658 },
    { lat: 48.4989487, lng: 20.3387189 },
    { lat: 48.4988337, lng: 20.3385117 },
    { lat: 48.4984942, lng: 20.3374857 },
    { lat: 48.4987821, lng: 20.3370455 },
    { lat: 48.4989419, lng: 20.3366408 },
    { lat: 48.4990851, lng: 20.3364396 },
    { lat: 48.4991111, lng: 20.3363276 },
    { lat: 48.4990709, lng: 20.3356171 },
    { lat: 48.4987779, lng: 20.3348355 },
    { lat: 48.4985256, lng: 20.33388 },
    { lat: 48.4983707, lng: 20.3336057 },
    { lat: 48.4979878, lng: 20.3332291 },
    { lat: 48.4977441, lng: 20.3322093 },
    { lat: 48.4977378, lng: 20.3315061 },
    { lat: 48.4976366, lng: 20.331248 },
    { lat: 48.4976765, lng: 20.3307719 },
    { lat: 48.4977578, lng: 20.3305765 },
    { lat: 48.4977555, lng: 20.3303427 },
    { lat: 48.4975831, lng: 20.3298804 },
    { lat: 48.4975648, lng: 20.3292361 },
    { lat: 48.4975234, lng: 20.3291027 },
    { lat: 48.4975402, lng: 20.3285537 },
    { lat: 48.4974297, lng: 20.3279439 },
    { lat: 48.4974282, lng: 20.3274157 },
    { lat: 48.4973414, lng: 20.3270435 },
    { lat: 48.497359, lng: 20.3267451 },
    { lat: 48.4974134, lng: 20.3265791 },
    { lat: 48.4974321, lng: 20.32625 },
    { lat: 48.4973872, lng: 20.3259955 },
    { lat: 48.4974472, lng: 20.3255748 },
    { lat: 48.4975404, lng: 20.3253638 },
    { lat: 48.4975768, lng: 20.325401 },
    { lat: 48.4976506, lng: 20.3251289 },
    { lat: 48.4977598, lng: 20.324958 },
    { lat: 48.4976758, lng: 20.3243085 },
    { lat: 48.4978559, lng: 20.3232962 },
    { lat: 48.4978656, lng: 20.3229715 },
    { lat: 48.4981815, lng: 20.3222646 },
    { lat: 48.4983075, lng: 20.321403 },
    { lat: 48.4988405, lng: 20.3201492 },
    { lat: 48.4970675, lng: 20.318066 },
    { lat: 48.4948487, lng: 20.3168016 },
    { lat: 48.4940661, lng: 20.3164872 },
    { lat: 48.493609, lng: 20.3161746 },
    { lat: 48.4941911, lng: 20.314044 },
    { lat: 48.4947134, lng: 20.3124879 },
    { lat: 48.4950007, lng: 20.310604 },
    { lat: 48.494983, lng: 20.30961 },
    { lat: 48.494827, lng: 20.309614 },
    { lat: 48.494255, lng: 20.309631 },
    { lat: 48.493812, lng: 20.309638 },
    { lat: 48.493442, lng: 20.309646 },
    { lat: 48.492493, lng: 20.309616 },
    { lat: 48.491928, lng: 20.309264 },
    { lat: 48.491441, lng: 20.308958 },
    { lat: 48.490754, lng: 20.308517 },
    { lat: 48.49024, lng: 20.308192 },
    { lat: 48.489718, lng: 20.307892 },
    { lat: 48.489139, lng: 20.307565 },
    { lat: 48.488584, lng: 20.307598 },
    { lat: 48.488233, lng: 20.307627 },
    { lat: 48.48762, lng: 20.307794 },
    { lat: 48.487004, lng: 20.307993 },
    { lat: 48.486573, lng: 20.308133 },
    { lat: 48.485912, lng: 20.30865 },
    { lat: 48.48566, lng: 20.30885 },
    { lat: 48.485225, lng: 20.309105 },
    { lat: 48.48506, lng: 20.309216 },
    { lat: 48.484421, lng: 20.309269 },
    { lat: 48.484115, lng: 20.309292 },
    { lat: 48.483891, lng: 20.30921 },
    { lat: 48.48337, lng: 20.308868 },
    { lat: 48.483143, lng: 20.308722 },
    { lat: 48.482673, lng: 20.308414 },
    { lat: 48.482261, lng: 20.308143 },
    { lat: 48.481728, lng: 20.307795 },
    { lat: 48.481301, lng: 20.307481 },
    { lat: 48.480423, lng: 20.306834 },
    { lat: 48.479931, lng: 20.30656 },
    { lat: 48.479673, lng: 20.306424 },
    { lat: 48.479572, lng: 20.30662 },
    { lat: 48.47955, lng: 20.306662 },
    { lat: 48.47947, lng: 20.306811 },
    { lat: 48.479373, lng: 20.307002 },
    { lat: 48.479252, lng: 20.307226 },
    { lat: 48.47909, lng: 20.30753 },
    { lat: 48.478942, lng: 20.307805 },
    { lat: 48.478573, lng: 20.308499 },
    { lat: 48.478434, lng: 20.308642 },
    { lat: 48.478322, lng: 20.309886 },
    { lat: 48.477907, lng: 20.310956 },
    { lat: 48.477834, lng: 20.310976 },
    { lat: 48.477706, lng: 20.311091 },
    { lat: 48.477457, lng: 20.311451 },
    { lat: 48.477205, lng: 20.31175 },
    { lat: 48.476838, lng: 20.31202 },
    { lat: 48.476672, lng: 20.312647 },
    { lat: 48.476514, lng: 20.313084 },
    { lat: 48.476394, lng: 20.313308 },
    { lat: 48.476324, lng: 20.313479 },
    { lat: 48.476186, lng: 20.313676 },
    { lat: 48.476082, lng: 20.313861 },
    { lat: 48.476063, lng: 20.31395 },
    { lat: 48.476055, lng: 20.313985 },
    { lat: 48.476044, lng: 20.314017 },
    { lat: 48.475973, lng: 20.314088 },
    { lat: 48.475953, lng: 20.314117 },
    { lat: 48.475912, lng: 20.314159 },
    { lat: 48.475741, lng: 20.31454 },
    { lat: 48.475672, lng: 20.314693 },
    { lat: 48.47562, lng: 20.314762 },
    { lat: 48.475576, lng: 20.314821 },
    { lat: 48.475556, lng: 20.31496 },
    { lat: 48.475358, lng: 20.315338 },
    { lat: 48.475336, lng: 20.315381 },
    { lat: 48.475297, lng: 20.315549 },
    { lat: 48.475165, lng: 20.315704 },
    { lat: 48.475116, lng: 20.31583 },
    { lat: 48.475131, lng: 20.316042 },
    { lat: 48.475077, lng: 20.316328 },
    { lat: 48.475046, lng: 20.316386 },
    { lat: 48.475017, lng: 20.316436 },
    { lat: 48.474972, lng: 20.316684 },
    { lat: 48.474942, lng: 20.316669 },
    { lat: 48.47484, lng: 20.316872 },
    { lat: 48.474888, lng: 20.316992 },
    { lat: 48.474906, lng: 20.317084 },
    { lat: 48.474844, lng: 20.317197 },
    { lat: 48.474827, lng: 20.317249 },
    { lat: 48.474782, lng: 20.317435 },
    { lat: 48.474701, lng: 20.317583 },
    { lat: 48.474686, lng: 20.317782 },
    { lat: 48.474683, lng: 20.318026 },
    { lat: 48.47476, lng: 20.318268 },
    { lat: 48.474738, lng: 20.31834 },
    { lat: 48.47469, lng: 20.318442 },
    { lat: 48.4746, lng: 20.318487 },
    { lat: 48.474509, lng: 20.318648 },
    { lat: 48.474427, lng: 20.318796 },
    { lat: 48.474417, lng: 20.318826 },
    { lat: 48.474389, lng: 20.318945 },
    { lat: 48.474385, lng: 20.319057 },
    { lat: 48.474411, lng: 20.319169 },
    { lat: 48.474416, lng: 20.319201 },
    { lat: 48.474386, lng: 20.319569 },
    { lat: 48.474371, lng: 20.319596 },
    { lat: 48.474321, lng: 20.31967 },
    { lat: 48.474326, lng: 20.319715 },
    { lat: 48.474327, lng: 20.319777 },
    { lat: 48.474319, lng: 20.319942 },
    { lat: 48.474282, lng: 20.32 },
    { lat: 48.474174, lng: 20.320111 },
    { lat: 48.474151, lng: 20.320203 },
    { lat: 48.474071, lng: 20.320511 },
    { lat: 48.474113, lng: 20.32071 },
    { lat: 48.474216, lng: 20.320811 },
    { lat: 48.474222, lng: 20.32093 },
    { lat: 48.474173, lng: 20.321079 },
    { lat: 48.474188, lng: 20.321313 },
    { lat: 48.474195, lng: 20.321363 },
    { lat: 48.474234, lng: 20.321557 },
    { lat: 48.474362, lng: 20.321722 },
    { lat: 48.474363, lng: 20.321805 },
    { lat: 48.474269, lng: 20.32189 },
    { lat: 48.474161, lng: 20.322006 },
    { lat: 48.47416, lng: 20.322297 },
    { lat: 48.474187, lng: 20.322522 },
    { lat: 48.474278, lng: 20.322721 },
    { lat: 48.474168, lng: 20.322941 },
    { lat: 48.474211, lng: 20.323128 },
    { lat: 48.474237, lng: 20.32337 },
    { lat: 48.474255, lng: 20.323397 },
    { lat: 48.474212, lng: 20.323588 },
    { lat: 48.474252, lng: 20.323845 },
    { lat: 48.474245, lng: 20.324196 },
    { lat: 48.474354, lng: 20.324565 },
    { lat: 48.47434, lng: 20.324704 },
    { lat: 48.474362, lng: 20.324721 },
    { lat: 48.474394, lng: 20.324739 },
    { lat: 48.474424, lng: 20.324854 },
    { lat: 48.474408, lng: 20.324906 },
    { lat: 48.474424, lng: 20.325128 },
    { lat: 48.474632, lng: 20.325543 },
    { lat: 48.474766, lng: 20.32594 },
    { lat: 48.474786, lng: 20.326219 },
    { lat: 48.474823, lng: 20.326301 },
    { lat: 48.474835, lng: 20.326453 },
    { lat: 48.474822, lng: 20.326522 },
    { lat: 48.474795, lng: 20.32659 },
    { lat: 48.474752, lng: 20.326644 },
    { lat: 48.47472, lng: 20.326678 },
    { lat: 48.47468, lng: 20.32672 },
    { lat: 48.474635, lng: 20.326755 },
    { lat: 48.474603, lng: 20.326776 },
    { lat: 48.47456, lng: 20.326882 },
    { lat: 48.474315, lng: 20.326971 },
    { lat: 48.474238, lng: 20.326988 },
    { lat: 48.474052, lng: 20.327036 },
    { lat: 48.474018, lng: 20.32708 },
    { lat: 48.473876, lng: 20.327294 },
    { lat: 48.473817, lng: 20.327446 },
    { lat: 48.473813, lng: 20.327495 },
    { lat: 48.473817, lng: 20.327933 },
    { lat: 48.474208, lng: 20.328789 },
    { lat: 48.47448, lng: 20.329045 },
    { lat: 48.474507, lng: 20.32906 },
    { lat: 48.474973, lng: 20.3292 },
    { lat: 48.474985, lng: 20.329275 },
    { lat: 48.475019, lng: 20.329737 },
    { lat: 48.475054, lng: 20.330049 },
    { lat: 48.475525, lng: 20.330197 },
    { lat: 48.475675, lng: 20.330248 },
    { lat: 48.475703, lng: 20.330294 },
    { lat: 48.475709, lng: 20.330736 },
    { lat: 48.475622, lng: 20.330926 },
    { lat: 48.475483, lng: 20.331227 },
    { lat: 48.475461, lng: 20.331346 },
    { lat: 48.475462, lng: 20.331468 },
    { lat: 48.475505, lng: 20.331566 },
    { lat: 48.475632, lng: 20.331781 },
    { lat: 48.475665, lng: 20.33205 },
    { lat: 48.475665, lng: 20.332197 },
    { lat: 48.475621, lng: 20.332484 },
    { lat: 48.475641, lng: 20.332659 },
    { lat: 48.475649, lng: 20.332838 },
    { lat: 48.475625, lng: 20.332961 },
    { lat: 48.4756, lng: 20.33302 },
    { lat: 48.475538, lng: 20.333054 },
    { lat: 48.475391, lng: 20.332967 },
    { lat: 48.475134, lng: 20.333045 },
    { lat: 48.475091, lng: 20.333024 },
    { lat: 48.474966, lng: 20.332878 },
    { lat: 48.474882, lng: 20.332754 },
    { lat: 48.47488, lng: 20.33259 },
    { lat: 48.474874, lng: 20.332518 },
    { lat: 48.474848, lng: 20.332403 },
    { lat: 48.474824, lng: 20.332372 },
    { lat: 48.474741, lng: 20.332335 },
    { lat: 48.474707, lng: 20.332344 },
    { lat: 48.474676, lng: 20.332378 },
    { lat: 48.47465, lng: 20.332482 },
    { lat: 48.474609, lng: 20.332826 },
    { lat: 48.474541, lng: 20.332914 },
    { lat: 48.474433, lng: 20.333005 },
    { lat: 48.474395, lng: 20.333014 },
    { lat: 48.47433, lng: 20.332975 },
    { lat: 48.474266, lng: 20.332712 },
    { lat: 48.474238, lng: 20.332614 },
    { lat: 48.474178, lng: 20.332523 },
    { lat: 48.474014, lng: 20.332568 },
    { lat: 48.473873, lng: 20.332633 },
    { lat: 48.473813, lng: 20.332634 },
    { lat: 48.473707, lng: 20.332611 },
    { lat: 48.473657, lng: 20.332608 },
    { lat: 48.473582, lng: 20.332638 },
    { lat: 48.47355, lng: 20.332712 },
    { lat: 48.473534, lng: 20.332833 },
    { lat: 48.473537, lng: 20.332957 },
    { lat: 48.473552, lng: 20.333065 },
    { lat: 48.473602, lng: 20.333305 },
    { lat: 48.473613, lng: 20.3334 },
    { lat: 48.473605, lng: 20.333728 },
    { lat: 48.473635, lng: 20.334015 },
    { lat: 48.473765, lng: 20.334257 },
    { lat: 48.473805, lng: 20.334288 },
    { lat: 48.473918, lng: 20.334329 },
    { lat: 48.474008, lng: 20.334331 },
    { lat: 48.474035, lng: 20.334319 },
    { lat: 48.474127, lng: 20.334235 },
    { lat: 48.474149, lng: 20.334193 },
    { lat: 48.47416, lng: 20.334142 },
    { lat: 48.474075, lng: 20.333776 },
    { lat: 48.47429, lng: 20.333384 },
    { lat: 48.474419, lng: 20.333343 },
    { lat: 48.474517, lng: 20.33345 },
    { lat: 48.474527, lng: 20.333527 },
    { lat: 48.474523, lng: 20.333982 },
    { lat: 48.474622, lng: 20.334216 },
    { lat: 48.474623, lng: 20.334246 },
    { lat: 48.474597, lng: 20.334338 },
    { lat: 48.474523, lng: 20.33441 },
    { lat: 48.473972, lng: 20.334609 },
    { lat: 48.473881, lng: 20.334808 },
    { lat: 48.473869, lng: 20.334922 },
    { lat: 48.473903, lng: 20.335079 },
    { lat: 48.473965, lng: 20.335138 },
    { lat: 48.474065, lng: 20.33526 },
    { lat: 48.474099, lng: 20.335333 },
    { lat: 48.474167, lng: 20.335542 },
    { lat: 48.474237, lng: 20.335793 },
    { lat: 48.474262, lng: 20.335853 },
    { lat: 48.474363, lng: 20.336017 },
    { lat: 48.474471, lng: 20.33612 },
    { lat: 48.47455, lng: 20.336177 },
    { lat: 48.474739, lng: 20.336169 },
    { lat: 48.474827, lng: 20.336162 },
    { lat: 48.47493, lng: 20.336234 },
    { lat: 48.4753517, lng: 20.3362824 },
    { lat: 48.4754346, lng: 20.3367073 },
    { lat: 48.4759009, lng: 20.3371722 },
    { lat: 48.4754293, lng: 20.3376334 },
    { lat: 48.4753126, lng: 20.3378794 },
    { lat: 48.4753015, lng: 20.3380525 },
    { lat: 48.4754268, lng: 20.3383502 },
    { lat: 48.4757194, lng: 20.3383492 },
    { lat: 48.4760531, lng: 20.3386157 },
    { lat: 48.475547, lng: 20.3394388 },
    { lat: 48.4757574, lng: 20.3399267 },
    { lat: 48.4762799, lng: 20.3401272 },
    { lat: 48.4762836, lng: 20.3405343 },
    { lat: 48.4762279, lng: 20.3409595 },
    { lat: 48.4762899, lng: 20.3411634 },
    { lat: 48.4763643, lng: 20.3412182 },
    { lat: 48.4765204, lng: 20.3410941 },
    { lat: 48.4765958, lng: 20.3407169 },
    { lat: 48.4767916, lng: 20.3405391 },
    { lat: 48.4768867, lng: 20.3406667 },
    { lat: 48.4769617, lng: 20.3415243 },
    { lat: 48.4771111, lng: 20.3418323 },
    { lat: 48.4775216, lng: 20.3419317 },
    { lat: 48.4775875, lng: 20.3430396 },
    { lat: 48.4776278, lng: 20.3436994 },
    { lat: 48.4781292, lng: 20.3439072 },
    { lat: 48.4782925, lng: 20.3442832 },
    { lat: 48.4786695, lng: 20.3443948 },
    { lat: 48.478709, lng: 20.3449727 },
    { lat: 48.4788662, lng: 20.3451363 },
    { lat: 48.4792403, lng: 20.3451995 },
    { lat: 48.4793768, lng: 20.3452914 },
    { lat: 48.4800935, lng: 20.3431621 },
    { lat: 48.4802582, lng: 20.343259 },
    { lat: 48.4804758, lng: 20.34361 },
    { lat: 48.4807011, lng: 20.3438741 },
    { lat: 48.480891, lng: 20.3440057 },
    { lat: 48.4814948, lng: 20.3439258 },
    { lat: 48.4821698, lng: 20.3440238 },
    { lat: 48.4822048, lng: 20.3437357 },
    { lat: 48.4833863, lng: 20.342935 },
    { lat: 48.4837282, lng: 20.3428611 },
    { lat: 48.484203, lng: 20.343575 },
    { lat: 48.4857077, lng: 20.3472125 },
    { lat: 48.4862064, lng: 20.3481958 },
    { lat: 48.4863633, lng: 20.348406 },
    { lat: 48.486541, lng: 20.3488919 },
    { lat: 48.4866327, lng: 20.3489013 },
    { lat: 48.4867286, lng: 20.3487646 },
    { lat: 48.4871867, lng: 20.3485844 },
    { lat: 48.4872412, lng: 20.3486257 },
    { lat: 48.4872805, lng: 20.3491381 },
    { lat: 48.4874058, lng: 20.3492648 },
    { lat: 48.4876254, lng: 20.3493239 },
    { lat: 48.4878806, lng: 20.3490216 },
    { lat: 48.488199, lng: 20.3490738 },
    { lat: 48.4888221, lng: 20.3499472 },
    { lat: 48.4889573, lng: 20.350007 },
    { lat: 48.4887554, lng: 20.3503738 },
    { lat: 48.4885896, lng: 20.3508907 },
    { lat: 48.488452, lng: 20.3519824 },
    { lat: 48.4885129, lng: 20.3522199 },
    { lat: 48.4887543, lng: 20.3526914 },
    { lat: 48.4891814, lng: 20.3531873 },
    { lat: 48.4891328, lng: 20.353389 },
    { lat: 48.4894761, lng: 20.3539942 },
    { lat: 48.4894813, lng: 20.3544116 },
    { lat: 48.4895206, lng: 20.3545551 },
    { lat: 48.4900532, lng: 20.3540932 },
    { lat: 48.4901357, lng: 20.3541811 },
    { lat: 48.4904427, lng: 20.3552131 },
    { lat: 48.4905575, lng: 20.3557916 },
    { lat: 48.4903192, lng: 20.35595 },
    { lat: 48.4901196, lng: 20.3564067 },
    { lat: 48.4899008, lng: 20.3560184 },
    { lat: 48.4898321, lng: 20.3561373 },
    { lat: 48.4897779, lng: 20.3566569 },
    { lat: 48.4899081, lng: 20.3569333 },
    { lat: 48.4898085, lng: 20.3574125 },
    { lat: 48.4898043, lng: 20.3577118 },
    { lat: 48.4899159, lng: 20.358118 },
    { lat: 48.4900041, lng: 20.3582732 },
    { lat: 48.4905532, lng: 20.358837 },
    { lat: 48.4906584, lng: 20.3587091 },
    { lat: 48.4912674, lng: 20.3597146 },
    { lat: 48.491284, lng: 20.3602279 },
    { lat: 48.4910863, lng: 20.3602418 },
    { lat: 48.4907718, lng: 20.3605527 },
    { lat: 48.490456, lng: 20.3612078 },
    { lat: 48.4903889, lng: 20.3620608 },
    { lat: 48.4906669, lng: 20.3624322 },
    { lat: 48.4914433, lng: 20.3629061 },
    { lat: 48.4917125, lng: 20.3632044 },
    { lat: 48.4923332, lng: 20.3633519 },
    { lat: 48.4925504, lng: 20.3635227 },
    { lat: 48.4927446, lng: 20.3635895 },
    { lat: 48.4933192, lng: 20.3636863 },
    { lat: 48.4935897, lng: 20.3636341 },
    { lat: 48.4936104, lng: 20.3634441 },
    { lat: 48.4939467, lng: 20.3635176 },
    { lat: 48.4940143, lng: 20.363605 },
    { lat: 48.4940901, lng: 20.3645726 },
    { lat: 48.4941987, lng: 20.3651932 },
    { lat: 48.4942933, lng: 20.3654548 },
    { lat: 48.4944526, lng: 20.3656751 },
    { lat: 48.4947067, lng: 20.3658072 },
    { lat: 48.4949345, lng: 20.3657535 },
    { lat: 48.4949224, lng: 20.3653565 },
    { lat: 48.4950466, lng: 20.3650491 },
    { lat: 48.4951144, lng: 20.364997 },
    { lat: 48.4958909, lng: 20.3653034 },
    { lat: 48.4962696, lng: 20.3655996 },
    { lat: 48.496669, lng: 20.3660531 },
    { lat: 48.4966515, lng: 20.3663025 },
    { lat: 48.4969014, lng: 20.3665266 },
    { lat: 48.4975949, lng: 20.3675106 },
    { lat: 48.4976956, lng: 20.367522 },
    { lat: 48.4977982, lng: 20.3672641 },
    { lat: 48.4981789, lng: 20.3666874 },
    { lat: 48.4986385, lng: 20.3653834 },
    { lat: 48.4989629, lng: 20.3648031 },
    { lat: 48.4992019, lng: 20.3645236 },
    { lat: 48.499344, lng: 20.3642068 },
    { lat: 48.4994559, lng: 20.3641288 },
    { lat: 48.5005403, lng: 20.3619548 },
    { lat: 48.5005394, lng: 20.3618413 },
    { lat: 48.5008064, lng: 20.3611506 },
    { lat: 48.5010341, lng: 20.3603728 },
    { lat: 48.501145, lng: 20.3602384 },
    { lat: 48.5010567, lng: 20.3588393 },
    { lat: 48.5012318, lng: 20.3581535 },
    { lat: 48.5016282, lng: 20.3575753 },
    { lat: 48.5022784, lng: 20.3569226 },
    { lat: 48.5040189, lng: 20.3570215 },
    { lat: 48.5046194, lng: 20.3574651 },
    { lat: 48.5056084, lng: 20.3578461 },
    { lat: 48.5062468, lng: 20.3579867 },
    { lat: 48.5073721, lng: 20.3586906 },
    { lat: 48.5077975, lng: 20.3587427 },
    { lat: 48.5078378, lng: 20.3587341 },
  ],
  GemerskáPanica: [
    { lat: 48.470488, lng: 20.420277 },
    { lat: 48.4705113, lng: 20.4202435 },
    { lat: 48.4705693, lng: 20.4203715 },
    { lat: 48.4706709, lng: 20.4202868 },
    { lat: 48.4707191, lng: 20.4203145 },
    { lat: 48.4707521, lng: 20.4203503 },
    { lat: 48.4707353, lng: 20.420558 },
    { lat: 48.4708639, lng: 20.4205947 },
    { lat: 48.4708799, lng: 20.4207466 },
    { lat: 48.4708348, lng: 20.4208638 },
    { lat: 48.4709235, lng: 20.4209182 },
    { lat: 48.4708788, lng: 20.4211005 },
    { lat: 48.4708948, lng: 20.4214625 },
    { lat: 48.4709564, lng: 20.4215111 },
    { lat: 48.4709719, lng: 20.4217071 },
    { lat: 48.4710573, lng: 20.4216436 },
    { lat: 48.4712148, lng: 20.4218748 },
    { lat: 48.4712655, lng: 20.4218193 },
    { lat: 48.4712989, lng: 20.4219265 },
    { lat: 48.471494, lng: 20.4219319 },
    { lat: 48.4715928, lng: 20.4221018 },
    { lat: 48.471765, lng: 20.4221877 },
    { lat: 48.4718303, lng: 20.4221299 },
    { lat: 48.4718813, lng: 20.4221978 },
    { lat: 48.471878, lng: 20.4223163 },
    { lat: 48.4720174, lng: 20.422543 },
    { lat: 48.4720218, lng: 20.4226573 },
    { lat: 48.47221, lng: 20.4229 },
    { lat: 48.472189, lng: 20.4230243 },
    { lat: 48.4723448, lng: 20.4232716 },
    { lat: 48.4725065, lng: 20.4236969 },
    { lat: 48.4727706, lng: 20.4237475 },
    { lat: 48.4728307, lng: 20.42393 },
    { lat: 48.47321, lng: 20.4244558 },
    { lat: 48.4739978, lng: 20.4240607 },
    { lat: 48.4750728, lng: 20.4230537 },
    { lat: 48.4760476, lng: 20.4225422 },
    { lat: 48.4766433, lng: 20.4219831 },
    { lat: 48.4774213, lng: 20.4218046 },
    { lat: 48.4783194, lng: 20.4219024 },
    { lat: 48.4787381, lng: 20.4221656 },
    { lat: 48.4798925, lng: 20.4217277 },
    { lat: 48.4802296, lng: 20.4217116 },
    { lat: 48.4807682, lng: 20.4205702 },
    { lat: 48.4821091, lng: 20.4194768 },
    { lat: 48.4826907, lng: 20.418934 },
    { lat: 48.4831301, lng: 20.4184121 },
    { lat: 48.4836828, lng: 20.4173177 },
    { lat: 48.4840585, lng: 20.4160761 },
    { lat: 48.484612, lng: 20.4136576 },
    { lat: 48.4848279, lng: 20.4107132 },
    { lat: 48.4851108, lng: 20.4088467 },
    { lat: 48.4852632, lng: 20.4069234 },
    { lat: 48.4853097, lng: 20.4053777 },
    { lat: 48.4853843, lng: 20.4049175 },
    { lat: 48.4858358, lng: 20.4035403 },
    { lat: 48.487669, lng: 20.4009931 },
    { lat: 48.4876149, lng: 20.4007841 },
    { lat: 48.4876724, lng: 20.400641 },
    { lat: 48.4876538, lng: 20.4002493 },
    { lat: 48.4877103, lng: 20.4000196 },
    { lat: 48.4875596, lng: 20.4000207 },
    { lat: 48.4875313, lng: 20.3999302 },
    { lat: 48.4874244, lng: 20.3998749 },
    { lat: 48.4873277, lng: 20.3996192 },
    { lat: 48.4873847, lng: 20.3994875 },
    { lat: 48.4873584, lng: 20.3993616 },
    { lat: 48.4873861, lng: 20.39929 },
    { lat: 48.4874344, lng: 20.3993131 },
    { lat: 48.4874169, lng: 20.3991844 },
    { lat: 48.4873292, lng: 20.3990678 },
    { lat: 48.4873309, lng: 20.3988198 },
    { lat: 48.4872674, lng: 20.3987821 },
    { lat: 48.48727, lng: 20.398702 },
    { lat: 48.4871079, lng: 20.3985851 },
    { lat: 48.4871129, lng: 20.398421 },
    { lat: 48.4871881, lng: 20.3982419 },
    { lat: 48.4870028, lng: 20.397934 },
    { lat: 48.4870156, lng: 20.3976237 },
    { lat: 48.4872432, lng: 20.3975651 },
    { lat: 48.4872132, lng: 20.3972816 },
    { lat: 48.4873984, lng: 20.3972133 },
    { lat: 48.4874474, lng: 20.3970766 },
    { lat: 48.4873189, lng: 20.3968418 },
    { lat: 48.4873993, lng: 20.3967116 },
    { lat: 48.4872937, lng: 20.3965973 },
    { lat: 48.4872688, lng: 20.3964781 },
    { lat: 48.4874211, lng: 20.3961375 },
    { lat: 48.4872518, lng: 20.3957731 },
    { lat: 48.4873317, lng: 20.3957172 },
    { lat: 48.487181, lng: 20.3955468 },
    { lat: 48.4871345, lng: 20.3953641 },
    { lat: 48.4873614, lng: 20.3952307 },
    { lat: 48.487533, lng: 20.3952087 },
    { lat: 48.4874134, lng: 20.3950576 },
    { lat: 48.4874102, lng: 20.3948871 },
    { lat: 48.4875375, lng: 20.39486 },
    { lat: 48.4874475, lng: 20.3947706 },
    { lat: 48.4874923, lng: 20.3947096 },
    { lat: 48.4874247, lng: 20.3945901 },
    { lat: 48.4874532, lng: 20.3944549 },
    { lat: 48.4873703, lng: 20.3944089 },
    { lat: 48.4873348, lng: 20.3942171 },
    { lat: 48.4873969, lng: 20.3941027 },
    { lat: 48.4872974, lng: 20.3936879 },
    { lat: 48.4871643, lng: 20.3936217 },
    { lat: 48.4870974, lng: 20.3934653 },
    { lat: 48.4871835, lng: 20.3933048 },
    { lat: 48.4871249, lng: 20.3932411 },
    { lat: 48.4870323, lng: 20.3932644 },
    { lat: 48.48699, lng: 20.3931872 },
    { lat: 48.4870109, lng: 20.3929724 },
    { lat: 48.4872848, lng: 20.3926031 },
    { lat: 48.4871432, lng: 20.3922288 },
    { lat: 48.4872563, lng: 20.3921501 },
    { lat: 48.4873577, lng: 20.3919485 },
    { lat: 48.487271, lng: 20.3918581 },
    { lat: 48.4872788, lng: 20.3916834 },
    { lat: 48.4871956, lng: 20.3918313 },
    { lat: 48.4870541, lng: 20.3914361 },
    { lat: 48.4870121, lng: 20.3905502 },
    { lat: 48.4871598, lng: 20.3903836 },
    { lat: 48.4871863, lng: 20.3902197 },
    { lat: 48.4870777, lng: 20.3900915 },
    { lat: 48.4870183, lng: 20.389867 },
    { lat: 48.4870656, lng: 20.3897784 },
    { lat: 48.4871953, lng: 20.3897578 },
    { lat: 48.4872547, lng: 20.3895421 },
    { lat: 48.4873636, lng: 20.3894464 },
    { lat: 48.4872609, lng: 20.3891206 },
    { lat: 48.4873676, lng: 20.3888784 },
    { lat: 48.4872589, lng: 20.3887452 },
    { lat: 48.4872881, lng: 20.3885417 },
    { lat: 48.4871147, lng: 20.3883364 },
    { lat: 48.487017, lng: 20.3883155 },
    { lat: 48.4869811, lng: 20.3881138 },
    { lat: 48.4871067, lng: 20.3878394 },
    { lat: 48.4870643, lng: 20.3876727 },
    { lat: 48.4869305, lng: 20.3876471 },
    { lat: 48.48686, lng: 20.3877225 },
    { lat: 48.4868464, lng: 20.3876012 },
    { lat: 48.4867854, lng: 20.3875998 },
    { lat: 48.4867536, lng: 20.3874716 },
    { lat: 48.4869292, lng: 20.3872092 },
    { lat: 48.486888, lng: 20.3871319 },
    { lat: 48.4869267, lng: 20.3870305 },
    { lat: 48.4868727, lng: 20.3869376 },
    { lat: 48.4869449, lng: 20.3868592 },
    { lat: 48.4868041, lng: 20.3867395 },
    { lat: 48.4868995, lng: 20.3866122 },
    { lat: 48.4868926, lng: 20.3862014 },
    { lat: 48.4867676, lng: 20.3862038 },
    { lat: 48.4866793, lng: 20.3861054 },
    { lat: 48.4864922, lng: 20.3861839 },
    { lat: 48.4864325, lng: 20.3858902 },
    { lat: 48.4863566, lng: 20.3859121 },
    { lat: 48.4863336, lng: 20.3858424 },
    { lat: 48.4863758, lng: 20.3857968 },
    { lat: 48.4862919, lng: 20.3855496 },
    { lat: 48.486209, lng: 20.3855611 },
    { lat: 48.4863109, lng: 20.3852242 },
    { lat: 48.4862544, lng: 20.3851301 },
    { lat: 48.4860597, lng: 20.38502 },
    { lat: 48.4860507, lng: 20.3849026 },
    { lat: 48.4861694, lng: 20.3847996 },
    { lat: 48.486042, lng: 20.384531 },
    { lat: 48.4859437, lng: 20.3846976 },
    { lat: 48.485853, lng: 20.3845908 },
    { lat: 48.4857319, lng: 20.3846636 },
    { lat: 48.4856788, lng: 20.3845418 },
    { lat: 48.4855867, lng: 20.3845524 },
    { lat: 48.4856131, lng: 20.3844478 },
    { lat: 48.4855255, lng: 20.384398 },
    { lat: 48.4854549, lng: 20.3841145 },
    { lat: 48.4850746, lng: 20.383747 },
    { lat: 48.4850272, lng: 20.3838264 },
    { lat: 48.4849661, lng: 20.3837597 },
    { lat: 48.4849767, lng: 20.3835387 },
    { lat: 48.4848871, lng: 20.383522 },
    { lat: 48.4848414, lng: 20.3833056 },
    { lat: 48.4849763, lng: 20.3832423 },
    { lat: 48.4850254, lng: 20.3829931 },
    { lat: 48.4848605, lng: 20.3829816 },
    { lat: 48.484875, lng: 20.3828305 },
    { lat: 48.4847601, lng: 20.382771 },
    { lat: 48.4847094, lng: 20.3825688 },
    { lat: 48.484635, lng: 20.3826534 },
    { lat: 48.4845395, lng: 20.3825457 },
    { lat: 48.4844806, lng: 20.3822119 },
    { lat: 48.48441, lng: 20.3822226 },
    { lat: 48.4843825, lng: 20.3821214 },
    { lat: 48.4844348, lng: 20.3819933 },
    { lat: 48.4843454, lng: 20.3819496 },
    { lat: 48.4841661, lng: 20.3821176 },
    { lat: 48.4840858, lng: 20.3820533 },
    { lat: 48.4841093, lng: 20.3819057 },
    { lat: 48.4839816, lng: 20.3818917 },
    { lat: 48.4839664, lng: 20.3816999 },
    { lat: 48.4840397, lng: 20.3815888 },
    { lat: 48.4840177, lng: 20.3814821 },
    { lat: 48.4839231, lng: 20.3815127 },
    { lat: 48.4838373, lng: 20.3813939 },
    { lat: 48.4839438, lng: 20.3813462 },
    { lat: 48.483716, lng: 20.3808763 },
    { lat: 48.4834629, lng: 20.3807085 },
    { lat: 48.4834709, lng: 20.3805957 },
    { lat: 48.4832494, lng: 20.380166 },
    { lat: 48.4832254, lng: 20.3799548 },
    { lat: 48.4831536, lng: 20.3799334 },
    { lat: 48.4832306, lng: 20.3795628 },
    { lat: 48.4829648, lng: 20.3793919 },
    { lat: 48.4830905, lng: 20.3794079 },
    { lat: 48.4831531, lng: 20.3792508 },
    { lat: 48.4830561, lng: 20.3792444 },
    { lat: 48.4829289, lng: 20.379011 },
    { lat: 48.4829158, lng: 20.3788197 },
    { lat: 48.4828573, lng: 20.378734 },
    { lat: 48.4829329, lng: 20.3785655 },
    { lat: 48.482815, lng: 20.3780193 },
    { lat: 48.4827505, lng: 20.3780132 },
    { lat: 48.4825842, lng: 20.377703 },
    { lat: 48.4824879, lng: 20.3778058 },
    { lat: 48.4823457, lng: 20.3777647 },
    { lat: 48.4823254, lng: 20.3776507 },
    { lat: 48.4823912, lng: 20.3775757 },
    { lat: 48.4822845, lng: 20.3773182 },
    { lat: 48.4821189, lng: 20.377491 },
    { lat: 48.4819602, lng: 20.3774928 },
    { lat: 48.4819164, lng: 20.3773547 },
    { lat: 48.4820755, lng: 20.3772708 },
    { lat: 48.4820331, lng: 20.3771102 },
    { lat: 48.4818728, lng: 20.3772474 },
    { lat: 48.4817436, lng: 20.3770188 },
    { lat: 48.4815244, lng: 20.3768239 },
    { lat: 48.4813106, lng: 20.3767699 },
    { lat: 48.4812547, lng: 20.3766665 },
    { lat: 48.4812305, lng: 20.3765054 },
    { lat: 48.4811527, lng: 20.3763901 },
    { lat: 48.481192, lng: 20.3762123 },
    { lat: 48.4811531, lng: 20.3759939 },
    { lat: 48.4812472, lng: 20.3759182 },
    { lat: 48.4811882, lng: 20.3757651 },
    { lat: 48.4812336, lng: 20.3755134 },
    { lat: 48.4813241, lng: 20.3754064 },
    { lat: 48.4814436, lng: 20.3748546 },
    { lat: 48.4813812, lng: 20.3746986 },
    { lat: 48.4814369, lng: 20.3745414 },
    { lat: 48.4813299, lng: 20.374537 },
    { lat: 48.4813222, lng: 20.3743935 },
    { lat: 48.4813766, lng: 20.3742638 },
    { lat: 48.4815345, lng: 20.3742631 },
    { lat: 48.4815575, lng: 20.3740471 },
    { lat: 48.4814229, lng: 20.3739172 },
    { lat: 48.4815087, lng: 20.3738057 },
    { lat: 48.48144, lng: 20.3736893 },
    { lat: 48.4815318, lng: 20.3735861 },
    { lat: 48.4814997, lng: 20.3734863 },
    { lat: 48.4813871, lng: 20.3734256 },
    { lat: 48.4815664, lng: 20.3732594 },
    { lat: 48.4815158, lng: 20.3731168 },
    { lat: 48.4814114, lng: 20.3731526 },
    { lat: 48.4813967, lng: 20.3730329 },
    { lat: 48.4812729, lng: 20.3729497 },
    { lat: 48.4813096, lng: 20.3727903 },
    { lat: 48.4811147, lng: 20.3722671 },
    { lat: 48.4811755, lng: 20.3720627 },
    { lat: 48.4811693, lng: 20.3718183 },
    { lat: 48.4812642, lng: 20.3717656 },
    { lat: 48.4812939, lng: 20.371282 },
    { lat: 48.4813729, lng: 20.3712336 },
    { lat: 48.4814308, lng: 20.370979 },
    { lat: 48.4816585, lng: 20.3707826 },
    { lat: 48.4816467, lng: 20.3706242 },
    { lat: 48.4817553, lng: 20.3705785 },
    { lat: 48.4818304, lng: 20.3706575 },
    { lat: 48.4819077, lng: 20.3704966 },
    { lat: 48.4820943, lng: 20.37058 },
    { lat: 48.4821957, lng: 20.3704639 },
    { lat: 48.4822349, lng: 20.3702585 },
    { lat: 48.4826466, lng: 20.3701262 },
    { lat: 48.4828577, lng: 20.3697903 },
    { lat: 48.4829411, lng: 20.3697631 },
    { lat: 48.4828901, lng: 20.3695546 },
    { lat: 48.4829764, lng: 20.3695396 },
    { lat: 48.4831413, lng: 20.3693186 },
    { lat: 48.4832887, lng: 20.3694341 },
    { lat: 48.4833032, lng: 20.3692827 },
    { lat: 48.4833935, lng: 20.3690867 },
    { lat: 48.4836385, lng: 20.3691053 },
    { lat: 48.4836201, lng: 20.3690323 },
    { lat: 48.4835787, lng: 20.3690434 },
    { lat: 48.4835808, lng: 20.3689517 },
    { lat: 48.4836237, lng: 20.3688954 },
    { lat: 48.4837115, lng: 20.3689267 },
    { lat: 48.4837236, lng: 20.3688302 },
    { lat: 48.4836339, lng: 20.3686662 },
    { lat: 48.4836904, lng: 20.3685281 },
    { lat: 48.4836748, lng: 20.368411 },
    { lat: 48.4838077, lng: 20.3681817 },
    { lat: 48.4837563, lng: 20.3681046 },
    { lat: 48.4836808, lng: 20.3681357 },
    { lat: 48.4836459, lng: 20.3679045 },
    { lat: 48.4837486, lng: 20.3677052 },
    { lat: 48.4838111, lng: 20.3676912 },
    { lat: 48.483779, lng: 20.3676415 },
    { lat: 48.4838255, lng: 20.3675099 },
    { lat: 48.4839747, lng: 20.3675238 },
    { lat: 48.4839393, lng: 20.3673331 },
    { lat: 48.4838948, lng: 20.3673803 },
    { lat: 48.4838488, lng: 20.3672953 },
    { lat: 48.4837864, lng: 20.3673171 },
    { lat: 48.4837193, lng: 20.3671792 },
    { lat: 48.4838135, lng: 20.3671383 },
    { lat: 48.4838465, lng: 20.3670037 },
    { lat: 48.4838136, lng: 20.3669416 },
    { lat: 48.4839418, lng: 20.3668757 },
    { lat: 48.483944, lng: 20.3667609 },
    { lat: 48.4838344, lng: 20.3666065 },
    { lat: 48.483939, lng: 20.3665714 },
    { lat: 48.4839136, lng: 20.3662708 },
    { lat: 48.4840056, lng: 20.3662567 },
    { lat: 48.484042, lng: 20.3661797 },
    { lat: 48.4841358, lng: 20.3661885 },
    { lat: 48.4840927, lng: 20.3660638 },
    { lat: 48.4840849, lng: 20.3657475 },
    { lat: 48.4839881, lng: 20.3656356 },
    { lat: 48.4842111, lng: 20.3654599 },
    { lat: 48.4842165, lng: 20.3651055 },
    { lat: 48.4842818, lng: 20.3649595 },
    { lat: 48.4843971, lng: 20.3649441 },
    { lat: 48.4844241, lng: 20.3650564 },
    { lat: 48.4847802, lng: 20.3647447 },
    { lat: 48.4848261, lng: 20.3645656 },
    { lat: 48.4850469, lng: 20.3643343 },
    { lat: 48.4851056, lng: 20.3642535 },
    { lat: 48.485097, lng: 20.3641719 },
    { lat: 48.4855232, lng: 20.3636368 },
    { lat: 48.4861508, lng: 20.3625699 },
    { lat: 48.4870476, lng: 20.3606956 },
    { lat: 48.4874934, lng: 20.3599421 },
    { lat: 48.487949, lng: 20.3589691 },
    { lat: 48.4881663, lng: 20.3586511 },
    { lat: 48.4881447, lng: 20.3581255 },
    { lat: 48.4878727, lng: 20.3575867 },
    { lat: 48.4879085, lng: 20.3571494 },
    { lat: 48.4878268, lng: 20.3570756 },
    { lat: 48.4878221, lng: 20.3569234 },
    { lat: 48.4876309, lng: 20.3566381 },
    { lat: 48.4873603, lng: 20.3564884 },
    { lat: 48.4873031, lng: 20.3559985 },
    { lat: 48.4873346, lng: 20.3557973 },
    { lat: 48.4871784, lng: 20.3554877 },
    { lat: 48.4868406, lng: 20.3550545 },
    { lat: 48.4866982, lng: 20.3549854 },
    { lat: 48.486626, lng: 20.3545185 },
    { lat: 48.4862299, lng: 20.3540057 },
    { lat: 48.4858308, lng: 20.353815 },
    { lat: 48.4856495, lng: 20.3536446 },
    { lat: 48.4854232, lng: 20.3536141 },
    { lat: 48.4852507, lng: 20.3532925 },
    { lat: 48.4852564, lng: 20.3528337 },
    { lat: 48.485196, lng: 20.3527005 },
    { lat: 48.4851366, lng: 20.3527083 },
    { lat: 48.4851029, lng: 20.352418 },
    { lat: 48.4849325, lng: 20.3520919 },
    { lat: 48.4849443, lng: 20.3518189 },
    { lat: 48.4850513, lng: 20.3515848 },
    { lat: 48.4850456, lng: 20.3514391 },
    { lat: 48.4848303, lng: 20.3506845 },
    { lat: 48.4848469, lng: 20.3505585 },
    { lat: 48.4847706, lng: 20.3501644 },
    { lat: 48.4845929, lng: 20.3497637 },
    { lat: 48.4846719, lng: 20.3495065 },
    { lat: 48.4845974, lng: 20.3489813 },
    { lat: 48.4845242, lng: 20.3488667 },
    { lat: 48.4842632, lng: 20.3487864 },
    { lat: 48.4842099, lng: 20.3486313 },
    { lat: 48.4836813, lng: 20.3483049 },
    { lat: 48.4835465, lng: 20.3478191 },
    { lat: 48.4830118, lng: 20.3474488 },
    { lat: 48.4828232, lng: 20.3469465 },
    { lat: 48.4827721, lng: 20.3469609 },
    { lat: 48.4827201, lng: 20.3468746 },
    { lat: 48.4826143, lng: 20.346556 },
    { lat: 48.48221, lng: 20.3462097 },
    { lat: 48.482086, lng: 20.346002 },
    { lat: 48.4814177, lng: 20.345816 },
    { lat: 48.4809797, lng: 20.34579 },
    { lat: 48.4804887, lng: 20.345993 },
    { lat: 48.4803899, lng: 20.3459709 },
    { lat: 48.4802898, lng: 20.345831 },
    { lat: 48.4801043, lng: 20.3457508 },
    { lat: 48.4800385, lng: 20.3456544 },
    { lat: 48.4793768, lng: 20.3452914 },
    { lat: 48.4792403, lng: 20.3451995 },
    { lat: 48.4788662, lng: 20.3451363 },
    { lat: 48.478709, lng: 20.3449727 },
    { lat: 48.4786695, lng: 20.3443948 },
    { lat: 48.4782925, lng: 20.3442832 },
    { lat: 48.4781292, lng: 20.3439072 },
    { lat: 48.4776278, lng: 20.3436994 },
    { lat: 48.4774525, lng: 20.3437951 },
    { lat: 48.477345, lng: 20.343641 },
    { lat: 48.4769962, lng: 20.3435025 },
    { lat: 48.4768294, lng: 20.3435591 },
    { lat: 48.4766399, lng: 20.3437213 },
    { lat: 48.4764385, lng: 20.3435847 },
    { lat: 48.4763241, lng: 20.3433928 },
    { lat: 48.4744001, lng: 20.3429451 },
    { lat: 48.4745181, lng: 20.3426504 },
    { lat: 48.4739312, lng: 20.3424445 },
    { lat: 48.4737362, lng: 20.3422434 },
    { lat: 48.4736863, lng: 20.3420896 },
    { lat: 48.4735768, lng: 20.342107 },
    { lat: 48.473324, lng: 20.342414 },
    { lat: 48.4732687, lng: 20.3424107 },
    { lat: 48.4728859, lng: 20.3420676 },
    { lat: 48.4729465, lng: 20.341889 },
    { lat: 48.47278, lng: 20.3417171 },
    { lat: 48.4727426, lng: 20.3416536 },
    { lat: 48.47278, lng: 20.341602 },
    { lat: 48.472728, lng: 20.341563 },
    { lat: 48.471891, lng: 20.340925 },
    { lat: 48.471724, lng: 20.3408 },
    { lat: 48.470603, lng: 20.339971 },
    { lat: 48.470202, lng: 20.339668 },
    { lat: 48.469714, lng: 20.339299 },
    { lat: 48.469306, lng: 20.338994 },
    { lat: 48.468946, lng: 20.338724 },
    { lat: 48.46864, lng: 20.33849 },
    { lat: 48.468452, lng: 20.338349 },
    { lat: 48.468349, lng: 20.338269 },
    { lat: 48.468174, lng: 20.338134 },
    { lat: 48.4678, lng: 20.337846 },
    { lat: 48.467218, lng: 20.337403 },
    { lat: 48.466914, lng: 20.337171 },
    { lat: 48.466646, lng: 20.336969 },
    { lat: 48.466583, lng: 20.336915 },
    { lat: 48.466348, lng: 20.336743 },
    { lat: 48.466275, lng: 20.336684 },
    { lat: 48.465972, lng: 20.336453 },
    { lat: 48.46564, lng: 20.336197 },
    { lat: 48.46537, lng: 20.33599 },
    { lat: 48.465063, lng: 20.335761 },
    { lat: 48.464681, lng: 20.335476 },
    { lat: 48.464313, lng: 20.335206 },
    { lat: 48.463831, lng: 20.334849 },
    { lat: 48.463741, lng: 20.334782 },
    { lat: 48.463633, lng: 20.334701 },
    { lat: 48.463308, lng: 20.334462 },
    { lat: 48.463283, lng: 20.334478 },
    { lat: 48.462901, lng: 20.335553 },
    { lat: 48.462768, lng: 20.335929 },
    { lat: 48.4626, lng: 20.336405 },
    { lat: 48.462403, lng: 20.336952 },
    { lat: 48.462299, lng: 20.337245 },
    { lat: 48.462201, lng: 20.337519 },
    { lat: 48.462129, lng: 20.337719 },
    { lat: 48.4621, lng: 20.337803 },
    { lat: 48.461995, lng: 20.338107 },
    { lat: 48.461942, lng: 20.338246 },
    { lat: 48.461846, lng: 20.338521 },
    { lat: 48.461806, lng: 20.338634 },
    { lat: 48.461784, lng: 20.338695 },
    { lat: 48.461755, lng: 20.338779 },
    { lat: 48.461702, lng: 20.338926 },
    { lat: 48.461681, lng: 20.338988 },
    { lat: 48.461661, lng: 20.339044 },
    { lat: 48.461645, lng: 20.339087 },
    { lat: 48.461558, lng: 20.339325 },
    { lat: 48.461537, lng: 20.339381 },
    { lat: 48.461486, lng: 20.339518 },
    { lat: 48.461472, lng: 20.339558 },
    { lat: 48.46138, lng: 20.339808 },
    { lat: 48.461288, lng: 20.340067 },
    { lat: 48.46125, lng: 20.340178 },
    { lat: 48.461228, lng: 20.340225 },
    { lat: 48.461197, lng: 20.340543 },
    { lat: 48.461135, lng: 20.341157 },
    { lat: 48.461128, lng: 20.341228 },
    { lat: 48.46108, lng: 20.341661 },
    { lat: 48.461073, lng: 20.341705 },
    { lat: 48.461095, lng: 20.341975 },
    { lat: 48.461114, lng: 20.342218 },
    { lat: 48.461172, lng: 20.34294 },
    { lat: 48.461263, lng: 20.344111 },
    { lat: 48.46129, lng: 20.344472 },
    { lat: 48.461355, lng: 20.345512 },
    { lat: 48.461375, lng: 20.345867 },
    { lat: 48.461411, lng: 20.346452 },
    { lat: 48.461414, lng: 20.346487 },
    { lat: 48.461424, lng: 20.346633 },
    { lat: 48.461426, lng: 20.346661 },
    { lat: 48.461507, lng: 20.347825 },
    { lat: 48.461532, lng: 20.348219 },
    { lat: 48.461541, lng: 20.348351 },
    { lat: 48.461546, lng: 20.348419 },
    { lat: 48.461549, lng: 20.348479 },
    { lat: 48.461556, lng: 20.348596 },
    { lat: 48.461558, lng: 20.348622 },
    { lat: 48.461559, lng: 20.348642 },
    { lat: 48.461569, lng: 20.3487897 },
    { lat: 48.461582, lng: 20.348982 },
    { lat: 48.461643, lng: 20.349943 },
    { lat: 48.461644, lng: 20.350023 },
    { lat: 48.461705, lng: 20.351172 },
    { lat: 48.461763, lng: 20.352271 },
    { lat: 48.461783, lng: 20.352665 },
    { lat: 48.461792, lng: 20.352842 },
    { lat: 48.461816, lng: 20.353295 },
    { lat: 48.461846, lng: 20.353909 },
    { lat: 48.461849, lng: 20.353944 },
    { lat: 48.461872, lng: 20.354207 },
    { lat: 48.461888, lng: 20.354387 },
    { lat: 48.461912, lng: 20.354683 },
    { lat: 48.461696, lng: 20.354768 },
    { lat: 48.461726, lng: 20.354918 },
    { lat: 48.461753, lng: 20.355049 },
    { lat: 48.461826, lng: 20.355413 },
    { lat: 48.461879, lng: 20.355686 },
    { lat: 48.461971, lng: 20.356182 },
    { lat: 48.462202, lng: 20.357439 },
    { lat: 48.462204, lng: 20.35745 },
    { lat: 48.462213, lng: 20.357506 },
    { lat: 48.462237, lng: 20.357642 },
    { lat: 48.46226, lng: 20.357782 },
    { lat: 48.462293, lng: 20.357988 },
    { lat: 48.462324, lng: 20.358218 },
    { lat: 48.462338, lng: 20.358309 },
    { lat: 48.462349, lng: 20.358387 },
    { lat: 48.462375, lng: 20.358502 },
    { lat: 48.462393, lng: 20.358586 },
    { lat: 48.462407, lng: 20.358655 },
    { lat: 48.462423, lng: 20.35874 },
    { lat: 48.462439, lng: 20.358808 },
    { lat: 48.462452, lng: 20.358867 },
    { lat: 48.462472, lng: 20.358946 },
    { lat: 48.462488, lng: 20.359013 },
    { lat: 48.462502, lng: 20.359066 },
    { lat: 48.462514, lng: 20.359113 },
    { lat: 48.462526, lng: 20.359167 },
    { lat: 48.462549, lng: 20.359279 },
    { lat: 48.462565, lng: 20.35935 },
    { lat: 48.4626, lng: 20.359513 },
    { lat: 48.462616, lng: 20.359585 },
    { lat: 48.462634, lng: 20.35966 },
    { lat: 48.462662, lng: 20.359803 },
    { lat: 48.462694, lng: 20.359949 },
    { lat: 48.462715, lng: 20.360049 },
    { lat: 48.462727, lng: 20.360099 },
    { lat: 48.462742, lng: 20.360159 },
    { lat: 48.462761, lng: 20.360233 },
    { lat: 48.4628, lng: 20.360377 },
    { lat: 48.462838, lng: 20.360511 },
    { lat: 48.462858, lng: 20.360589 },
    { lat: 48.462877, lng: 20.360654 },
    { lat: 48.462916, lng: 20.360808 },
    { lat: 48.462936, lng: 20.360886 },
    { lat: 48.462958, lng: 20.36097 },
    { lat: 48.462977, lng: 20.361045 },
    { lat: 48.463009, lng: 20.361191 },
    { lat: 48.463027, lng: 20.361275 },
    { lat: 48.463044, lng: 20.361357 },
    { lat: 48.46306, lng: 20.361432 },
    { lat: 48.463077, lng: 20.361513 },
    { lat: 48.463093, lng: 20.361597 },
    { lat: 48.463111, lng: 20.361684 },
    { lat: 48.463141, lng: 20.361824 },
    { lat: 48.463161, lng: 20.361912 },
    { lat: 48.463183, lng: 20.362012 },
    { lat: 48.463216, lng: 20.362183 },
    { lat: 48.463196, lng: 20.362391 },
    { lat: 48.463095, lng: 20.362658 },
    { lat: 48.462975, lng: 20.362953 },
    { lat: 48.462849, lng: 20.36327 },
    { lat: 48.462787, lng: 20.363422 },
    { lat: 48.462659, lng: 20.363744 },
    { lat: 48.462524, lng: 20.364086 },
    { lat: 48.462445, lng: 20.364275 },
    { lat: 48.462308, lng: 20.364656 },
    { lat: 48.462182, lng: 20.36511 },
    { lat: 48.462114, lng: 20.365429 },
    { lat: 48.462092, lng: 20.365536 },
    { lat: 48.462066, lng: 20.36565 },
    { lat: 48.462035, lng: 20.365793 },
    { lat: 48.462005, lng: 20.365921 },
    { lat: 48.461994, lng: 20.365995 },
    { lat: 48.461981, lng: 20.366045 },
    { lat: 48.461952, lng: 20.366176 },
    { lat: 48.461941, lng: 20.366238 },
    { lat: 48.46193, lng: 20.3663 },
    { lat: 48.461903, lng: 20.36643 },
    { lat: 48.461852, lng: 20.366711 },
    { lat: 48.461825, lng: 20.366839 },
    { lat: 48.461813, lng: 20.366901 },
    { lat: 48.461798, lng: 20.366979 },
    { lat: 48.461771, lng: 20.367109 },
    { lat: 48.461756, lng: 20.367183 },
    { lat: 48.461738, lng: 20.367277 },
    { lat: 48.461723, lng: 20.367351 },
    { lat: 48.461699, lng: 20.367476 },
    { lat: 48.461268, lng: 20.369632 },
    { lat: 48.461514, lng: 20.36985 },
    { lat: 48.461814, lng: 20.370082 },
    { lat: 48.461921, lng: 20.370154 },
    { lat: 48.461945, lng: 20.37017 },
    { lat: 48.461963, lng: 20.370192 },
    { lat: 48.462019, lng: 20.37023 },
    { lat: 48.462117, lng: 20.370302 },
    { lat: 48.462142, lng: 20.370324 },
    { lat: 48.462274, lng: 20.370422 },
    { lat: 48.462316, lng: 20.370457 },
    { lat: 48.462364, lng: 20.370491 },
    { lat: 48.462415, lng: 20.370532 },
    { lat: 48.462458, lng: 20.370563 },
    { lat: 48.462505, lng: 20.370598 },
    { lat: 48.462528, lng: 20.370617 },
    { lat: 48.462572, lng: 20.370648 },
    { lat: 48.462609, lng: 20.37068 },
    { lat: 48.46266, lng: 20.370718 },
    { lat: 48.462703, lng: 20.370756 },
    { lat: 48.462745, lng: 20.370787 },
    { lat: 48.462788, lng: 20.370825 },
    { lat: 48.46284, lng: 20.370866 },
    { lat: 48.46288, lng: 20.3709 },
    { lat: 48.462964, lng: 20.370979 },
    { lat: 48.46304, lng: 20.371048 },
    { lat: 48.46311, lng: 20.371117 },
    { lat: 48.463184, lng: 20.371193 },
    { lat: 48.463217, lng: 20.371227 },
    { lat: 48.463278, lng: 20.371293 },
    { lat: 48.463342, lng: 20.371359 },
    { lat: 48.46338, lng: 20.371396 },
    { lat: 48.463466, lng: 20.371481 },
    { lat: 48.463485, lng: 20.371502 },
    { lat: 48.463536, lng: 20.371557 },
    { lat: 48.463665, lng: 20.371688 },
    { lat: 48.463698, lng: 20.371711 },
    { lat: 48.463778, lng: 20.371738 },
    { lat: 48.464217, lng: 20.371891 },
    { lat: 48.464551, lng: 20.372078 },
    { lat: 48.464494, lng: 20.372261 },
    { lat: 48.464393, lng: 20.37242 },
    { lat: 48.464387, lng: 20.372502 },
    { lat: 48.464379, lng: 20.372574 },
    { lat: 48.464413, lng: 20.372734 },
    { lat: 48.464514, lng: 20.372556 },
    { lat: 48.464549, lng: 20.372625 },
    { lat: 48.464523, lng: 20.372964 },
    { lat: 48.464589, lng: 20.373085 },
    { lat: 48.464618, lng: 20.373134 },
    { lat: 48.464704, lng: 20.373294 },
    { lat: 48.464746, lng: 20.373454 },
    { lat: 48.464836, lng: 20.373569 },
    { lat: 48.464879, lng: 20.373649 },
    { lat: 48.464959, lng: 20.373805 },
    { lat: 48.465028, lng: 20.37382 },
    { lat: 48.465086, lng: 20.37398 },
    { lat: 48.465186, lng: 20.374225 },
    { lat: 48.465175, lng: 20.374324 },
    { lat: 48.46533, lng: 20.374445 },
    { lat: 48.465368, lng: 20.374585 },
    { lat: 48.465489, lng: 20.374686 },
    { lat: 48.465626, lng: 20.374771 },
    { lat: 48.465674, lng: 20.374827 },
    { lat: 48.465678, lng: 20.374867 },
    { lat: 48.465743, lng: 20.374937 },
    { lat: 48.465918, lng: 20.375183 },
    { lat: 48.46598, lng: 20.375268 },
    { lat: 48.466009, lng: 20.375332 },
    { lat: 48.466031, lng: 20.375412 },
    { lat: 48.466272, lng: 20.375778 },
    { lat: 48.466403, lng: 20.375819 },
    { lat: 48.466477, lng: 20.375919 },
    { lat: 48.466457, lng: 20.375959 },
    { lat: 48.466487, lng: 20.376004 },
    { lat: 48.466554, lng: 20.376159 },
    { lat: 48.466779, lng: 20.376315 },
    { lat: 48.466838, lng: 20.376425 },
    { lat: 48.466935, lng: 20.37662 },
    { lat: 48.467067, lng: 20.37692 },
    { lat: 48.467174, lng: 20.377086 },
    { lat: 48.467266, lng: 20.377211 },
    { lat: 48.467436, lng: 20.377521 },
    { lat: 48.467547, lng: 20.377751 },
    { lat: 48.46767, lng: 20.377882 },
    { lat: 48.467695, lng: 20.378096 },
    { lat: 48.467803, lng: 20.378201 },
    { lat: 48.467828, lng: 20.378381 },
    { lat: 48.467889, lng: 20.378486 },
    { lat: 48.467941, lng: 20.378566 },
    { lat: 48.467963, lng: 20.378636 },
    { lat: 48.467949, lng: 20.378766 },
    { lat: 48.468011, lng: 20.378861 },
    { lat: 48.467994, lng: 20.378896 },
    { lat: 48.468077, lng: 20.37906 },
    { lat: 48.46803, lng: 20.379219 },
    { lat: 48.468068, lng: 20.379443 },
    { lat: 48.468007, lng: 20.379632 },
    { lat: 48.468067, lng: 20.379907 },
    { lat: 48.468161, lng: 20.380033 },
    { lat: 48.46811, lng: 20.380202 },
    { lat: 48.468073, lng: 20.380315 },
    { lat: 48.468024, lng: 20.380709 },
    { lat: 48.467937, lng: 20.380832 },
    { lat: 48.467858, lng: 20.380983 },
    { lat: 48.467822, lng: 20.381063 },
    { lat: 48.467901, lng: 20.38133 },
    { lat: 48.467844, lng: 20.38152 },
    { lat: 48.467823, lng: 20.38155 },
    { lat: 48.467651, lng: 20.381733 },
    { lat: 48.467554, lng: 20.381828 },
    { lat: 48.46752, lng: 20.381853 },
    { lat: 48.467501, lng: 20.381983 },
    { lat: 48.467485, lng: 20.382061 },
    { lat: 48.467389, lng: 20.382351 },
    { lat: 48.467341, lng: 20.382454 },
    { lat: 48.467111, lng: 20.382924 },
    { lat: 48.467037, lng: 20.382974 },
    { lat: 48.46694, lng: 20.383199 },
    { lat: 48.466826, lng: 20.383497 },
    { lat: 48.466816, lng: 20.383562 },
    { lat: 48.466803, lng: 20.383679 },
    { lat: 48.466779, lng: 20.383709 },
    { lat: 48.466674, lng: 20.383816 },
    { lat: 48.466702, lng: 20.383939 },
    { lat: 48.466652, lng: 20.383977 },
    { lat: 48.466608, lng: 20.384054 },
    { lat: 48.466561, lng: 20.384175 },
    { lat: 48.466541, lng: 20.384254 },
    { lat: 48.466398, lng: 20.384298 },
    { lat: 48.46633, lng: 20.384423 },
    { lat: 48.466202, lng: 20.384472 },
    { lat: 48.466149, lng: 20.384571 },
    { lat: 48.466001, lng: 20.38469 },
    { lat: 48.465909, lng: 20.384898 },
    { lat: 48.465846, lng: 20.385071 },
    { lat: 48.465877, lng: 20.385302 },
    { lat: 48.465893, lng: 20.385394 },
    { lat: 48.465863, lng: 20.385463 },
    { lat: 48.465805, lng: 20.385505 },
    { lat: 48.465789, lng: 20.385591 },
    { lat: 48.465774, lng: 20.385674 },
    { lat: 48.465747, lng: 20.385725 },
    { lat: 48.465597, lng: 20.385988 },
    { lat: 48.465584, lng: 20.386066 },
    { lat: 48.465573, lng: 20.38621 },
    { lat: 48.465579, lng: 20.386271 },
    { lat: 48.465594, lng: 20.386371 },
    { lat: 48.465605, lng: 20.38645 },
    { lat: 48.465595, lng: 20.386541 },
    { lat: 48.465576, lng: 20.386636 },
    { lat: 48.465529, lng: 20.386753 },
    { lat: 48.465084, lng: 20.387833 },
    { lat: 48.464948, lng: 20.388041 },
    { lat: 48.4647, lng: 20.388421 },
    { lat: 48.464242, lng: 20.389117 },
    { lat: 48.463995, lng: 20.3895 },
    { lat: 48.463316, lng: 20.390605 },
    { lat: 48.463089, lng: 20.390967 },
    { lat: 48.46291, lng: 20.391172 },
    { lat: 48.462823, lng: 20.39127 },
    { lat: 48.462386, lng: 20.391767 },
    { lat: 48.462094, lng: 20.392105 },
    { lat: 48.461682, lng: 20.392168 },
    { lat: 48.461598, lng: 20.392188 },
    { lat: 48.46156, lng: 20.392197 },
    { lat: 48.461444, lng: 20.392249 },
    { lat: 48.461259, lng: 20.392297 },
    { lat: 48.461182, lng: 20.392307 },
    { lat: 48.461072, lng: 20.392327 },
    { lat: 48.460973, lng: 20.392343 },
    { lat: 48.460907, lng: 20.392377 },
    { lat: 48.460813, lng: 20.392418 },
    { lat: 48.460784, lng: 20.392421 },
    { lat: 48.460734, lng: 20.392432 },
    { lat: 48.460691, lng: 20.392438 },
    { lat: 48.46063, lng: 20.392461 },
    { lat: 48.460446, lng: 20.392523 },
    { lat: 48.460243, lng: 20.392525 },
    { lat: 48.460016, lng: 20.392642 },
    { lat: 48.459927, lng: 20.392687 },
    { lat: 48.459645, lng: 20.392896 },
    { lat: 48.459409, lng: 20.39309 },
    { lat: 48.459259, lng: 20.393127 },
    { lat: 48.459072, lng: 20.393115 },
    { lat: 48.459044, lng: 20.393113 },
    { lat: 48.458998, lng: 20.39311 },
    { lat: 48.458908, lng: 20.393146 },
    { lat: 48.458242, lng: 20.393474 },
    { lat: 48.458064, lng: 20.393649 },
    { lat: 48.457808, lng: 20.393718 },
    { lat: 48.457388, lng: 20.393702 },
    { lat: 48.457002, lng: 20.393913 },
    { lat: 48.456762, lng: 20.393981 },
    { lat: 48.456714, lng: 20.394008 },
    { lat: 48.456671, lng: 20.394035 },
    { lat: 48.456716, lng: 20.394243 },
    { lat: 48.456716, lng: 20.39437 },
    { lat: 48.456783, lng: 20.394482 },
    { lat: 48.456755, lng: 20.394676 },
    { lat: 48.456964, lng: 20.394774 },
    { lat: 48.456978, lng: 20.395037 },
    { lat: 48.456837, lng: 20.395148 },
    { lat: 48.456798, lng: 20.395279 },
    { lat: 48.456836, lng: 20.395444 },
    { lat: 48.456764, lng: 20.395555 },
    { lat: 48.456791, lng: 20.395661 },
    { lat: 48.45661, lng: 20.39571 },
    { lat: 48.4565, lng: 20.395816 },
    { lat: 48.456529, lng: 20.395977 },
    { lat: 48.456473, lng: 20.395979 },
    { lat: 48.45648, lng: 20.396198 },
    { lat: 48.456551, lng: 20.396265 },
    { lat: 48.456657, lng: 20.396274 },
    { lat: 48.456663, lng: 20.39628 },
    { lat: 48.456597, lng: 20.396382 },
    { lat: 48.456541, lng: 20.396722 },
    { lat: 48.456642, lng: 20.396835 },
    { lat: 48.456618, lng: 20.397083 },
    { lat: 48.456683, lng: 20.397191 },
    { lat: 48.456748, lng: 20.397362 },
    { lat: 48.456831, lng: 20.397362 },
    { lat: 48.45682, lng: 20.39746 },
    { lat: 48.456742, lng: 20.397416 },
    { lat: 48.456661, lng: 20.397525 },
    { lat: 48.456757, lng: 20.397795 },
    { lat: 48.456854, lng: 20.397781 },
    { lat: 48.456947, lng: 20.397941 },
    { lat: 48.457061, lng: 20.397869 },
    { lat: 48.457142, lng: 20.397974 },
    { lat: 48.457243, lng: 20.398021 },
    { lat: 48.457265, lng: 20.39812 },
    { lat: 48.457265, lng: 20.398337 },
    { lat: 48.457348, lng: 20.398496 },
    { lat: 48.457476, lng: 20.398592 },
    { lat: 48.457447, lng: 20.398698 },
    { lat: 48.457421, lng: 20.398757 },
    { lat: 48.457517, lng: 20.398876 },
    { lat: 48.457516, lng: 20.399055 },
    { lat: 48.457569, lng: 20.399198 },
    { lat: 48.457584, lng: 20.399389 },
    { lat: 48.457629, lng: 20.399397 },
    { lat: 48.457601, lng: 20.399489 },
    { lat: 48.457614, lng: 20.399556 },
    { lat: 48.457506, lng: 20.399743 },
    { lat: 48.457526, lng: 20.399973 },
    { lat: 48.457568, lng: 20.400004 },
    { lat: 48.457566, lng: 20.400119 },
    { lat: 48.457442, lng: 20.400104 },
    { lat: 48.457405, lng: 20.40013 },
    { lat: 48.457425, lng: 20.400468 },
    { lat: 48.457465, lng: 20.400584 },
    { lat: 48.457479, lng: 20.400739 },
    { lat: 48.457426, lng: 20.400758 },
    { lat: 48.457395, lng: 20.400893 },
    { lat: 48.457417, lng: 20.400992 },
    { lat: 48.45758, lng: 20.401128 },
    { lat: 48.457612, lng: 20.401223 },
    { lat: 48.457467, lng: 20.40136 },
    { lat: 48.457409, lng: 20.4013 },
    { lat: 48.457361, lng: 20.401412 },
    { lat: 48.457348, lng: 20.401584 },
    { lat: 48.457366, lng: 20.40165 },
    { lat: 48.457329, lng: 20.401934 },
    { lat: 48.457445, lng: 20.401926 },
    { lat: 48.45756, lng: 20.402027 },
    { lat: 48.457525, lng: 20.402228 },
    { lat: 48.457444, lng: 20.402186 },
    { lat: 48.457375, lng: 20.40233 },
    { lat: 48.457355, lng: 20.40272 },
    { lat: 48.45737, lng: 20.402997 },
    { lat: 48.457505, lng: 20.402995 },
    { lat: 48.457575, lng: 20.403162 },
    { lat: 48.457644, lng: 20.403175 },
    { lat: 48.457634, lng: 20.403233 },
    { lat: 48.457618, lng: 20.403341 },
    { lat: 48.457657, lng: 20.403491 },
    { lat: 48.457828, lng: 20.403827 },
    { lat: 48.457813, lng: 20.403892 },
    { lat: 48.457855, lng: 20.403962 },
    { lat: 48.45795, lng: 20.403895 },
    { lat: 48.457948, lng: 20.404153 },
    { lat: 48.457895, lng: 20.404306 },
    { lat: 48.457929, lng: 20.404502 },
    { lat: 48.458073, lng: 20.404683 },
    { lat: 48.458335, lng: 20.404562 },
    { lat: 48.458349, lng: 20.404641 },
    { lat: 48.458297, lng: 20.404729 },
    { lat: 48.458441, lng: 20.404905 },
    { lat: 48.458422, lng: 20.404949 },
    { lat: 48.458495, lng: 20.404936 },
    { lat: 48.4584, lng: 20.405029 },
    { lat: 48.458444, lng: 20.405164 },
    { lat: 48.458569, lng: 20.405288 },
    { lat: 48.458794, lng: 20.405606 },
    { lat: 48.458966, lng: 20.40574 },
    { lat: 48.458975, lng: 20.405807 },
    { lat: 48.458739, lng: 20.405782 },
    { lat: 48.458686, lng: 20.406022 },
    { lat: 48.458726, lng: 20.406229 },
    { lat: 48.45858, lng: 20.406628 },
    { lat: 48.458718, lng: 20.40667 },
    { lat: 48.458736, lng: 20.406724 },
    { lat: 48.458636, lng: 20.406768 },
    { lat: 48.458635, lng: 20.406897 },
    { lat: 48.458722, lng: 20.407088 },
    { lat: 48.458778, lng: 20.407287 },
    { lat: 48.45872, lng: 20.407299 },
    { lat: 48.458674, lng: 20.407402 },
    { lat: 48.458719, lng: 20.407516 },
    { lat: 48.458856, lng: 20.407528 },
    { lat: 48.458989, lng: 20.407405 },
    { lat: 48.459142, lng: 20.407548 },
    { lat: 48.459203, lng: 20.407549 },
    { lat: 48.459208, lng: 20.407611 },
    { lat: 48.459189, lng: 20.407655 },
    { lat: 48.459135, lng: 20.407767 },
    { lat: 48.45912, lng: 20.407964 },
    { lat: 48.45921, lng: 20.408052 },
    { lat: 48.459355, lng: 20.407983 },
    { lat: 48.459579, lng: 20.408336 },
    { lat: 48.459666, lng: 20.408314 },
    { lat: 48.459689, lng: 20.40823 },
    { lat: 48.459753, lng: 20.408223 },
    { lat: 48.459806, lng: 20.408176 },
    { lat: 48.459927, lng: 20.407928 },
    { lat: 48.460041, lng: 20.407976 },
    { lat: 48.460191, lng: 20.408438 },
    { lat: 48.460358, lng: 20.408451 },
    { lat: 48.460393, lng: 20.408331 },
    { lat: 48.460452, lng: 20.408328 },
    { lat: 48.460486, lng: 20.408334 },
    { lat: 48.460414, lng: 20.408472 },
    { lat: 48.460564, lng: 20.408557 },
    { lat: 48.460426, lng: 20.408805 },
    { lat: 48.460575, lng: 20.409088 },
    { lat: 48.460583, lng: 20.409214 },
    { lat: 48.460521, lng: 20.409281 },
    { lat: 48.460511, lng: 20.409413 },
    { lat: 48.460591, lng: 20.409494 },
    { lat: 48.46079, lng: 20.409471 },
    { lat: 48.460835, lng: 20.409316 },
    { lat: 48.460883, lng: 20.409304 },
    { lat: 48.460921, lng: 20.409382 },
    { lat: 48.460964, lng: 20.409472 },
    { lat: 48.461069, lng: 20.409744 },
    { lat: 48.461005, lng: 20.40983 },
    { lat: 48.460985, lng: 20.409957 },
    { lat: 48.460914, lng: 20.410101 },
    { lat: 48.460924, lng: 20.410233 },
    { lat: 48.460978, lng: 20.410355 },
    { lat: 48.460998, lng: 20.410357 },
    { lat: 48.461037, lng: 20.410378 },
    { lat: 48.461036, lng: 20.410437 },
    { lat: 48.460926, lng: 20.410466 },
    { lat: 48.46094, lng: 20.410715 },
    { lat: 48.460901, lng: 20.410759 },
    { lat: 48.460922, lng: 20.41089 },
    { lat: 48.461079, lng: 20.410853 },
    { lat: 48.461129, lng: 20.410812 },
    { lat: 48.461143, lng: 20.410901 },
    { lat: 48.461109, lng: 20.410937 },
    { lat: 48.46112, lng: 20.411016 },
    { lat: 48.461155, lng: 20.411081 },
    { lat: 48.460963, lng: 20.411267 },
    { lat: 48.46091, lng: 20.411406 },
    { lat: 48.460896, lng: 20.411701 },
    { lat: 48.461035, lng: 20.411703 },
    { lat: 48.461041, lng: 20.411821 },
    { lat: 48.460913, lng: 20.412013 },
    { lat: 48.460976, lng: 20.412203 },
    { lat: 48.460938, lng: 20.412348 },
    { lat: 48.460985, lng: 20.412546 },
    { lat: 48.460958, lng: 20.412586 },
    { lat: 48.460969, lng: 20.412737 },
    { lat: 48.461012, lng: 20.412829 },
    { lat: 48.460907, lng: 20.412959 },
    { lat: 48.460812, lng: 20.413136 },
    { lat: 48.460831, lng: 20.41333 },
    { lat: 48.460882, lng: 20.413429 },
    { lat: 48.461076, lng: 20.413246 },
    { lat: 48.461108, lng: 20.413393 },
    { lat: 48.461193, lng: 20.413469 },
    { lat: 48.461254, lng: 20.413617 },
    { lat: 48.461487, lng: 20.413542 },
    { lat: 48.461525, lng: 20.413596 },
    { lat: 48.461454, lng: 20.413699 },
    { lat: 48.461334, lng: 20.413754 },
    { lat: 48.461317, lng: 20.413756 },
    { lat: 48.461258, lng: 20.41369 },
    { lat: 48.461139, lng: 20.413861 },
    { lat: 48.461149, lng: 20.413967 },
    { lat: 48.46124, lng: 20.41399 },
    { lat: 48.461469, lng: 20.414308 },
    { lat: 48.461522, lng: 20.41429 },
    { lat: 48.461561, lng: 20.414282 },
    { lat: 48.461675, lng: 20.414678 },
    { lat: 48.461796, lng: 20.414732 },
    { lat: 48.461888, lng: 20.414647 },
    { lat: 48.461937, lng: 20.414786 },
    { lat: 48.462115, lng: 20.414773 },
    { lat: 48.462086, lng: 20.414989 },
    { lat: 48.462132, lng: 20.415029 },
    { lat: 48.462139, lng: 20.415029 },
    { lat: 48.462232, lng: 20.41505 },
    { lat: 48.462256, lng: 20.415014 },
    { lat: 48.462234, lng: 20.414866 },
    { lat: 48.462273, lng: 20.414843 },
    { lat: 48.462315, lng: 20.414914 },
    { lat: 48.462349, lng: 20.414913 },
    { lat: 48.462373, lng: 20.414871 },
    { lat: 48.462481, lng: 20.414925 },
    { lat: 48.462575, lng: 20.414935 },
    { lat: 48.462615, lng: 20.415117 },
    { lat: 48.463023, lng: 20.415256 },
    { lat: 48.463037, lng: 20.415392 },
    { lat: 48.463066, lng: 20.415438 },
    { lat: 48.46315, lng: 20.415355 },
    { lat: 48.463142, lng: 20.415253 },
    { lat: 48.463218, lng: 20.415203 },
    { lat: 48.463324, lng: 20.415291 },
    { lat: 48.463426, lng: 20.415565 },
    { lat: 48.463506, lng: 20.415565 },
    { lat: 48.463551, lng: 20.415699 },
    { lat: 48.463518, lng: 20.415745 },
    { lat: 48.463517, lng: 20.415794 },
    { lat: 48.463573, lng: 20.415865 },
    { lat: 48.46363, lng: 20.415743 },
    { lat: 48.463826, lng: 20.41564 },
    { lat: 48.46389, lng: 20.415516 },
    { lat: 48.464007, lng: 20.415531 },
    { lat: 48.464044, lng: 20.415536 },
    { lat: 48.464118, lng: 20.415643 },
    { lat: 48.46413, lng: 20.415642 },
    { lat: 48.464182, lng: 20.415621 },
    { lat: 48.464275, lng: 20.415698 },
    { lat: 48.464264, lng: 20.415753 },
    { lat: 48.46428, lng: 20.415795 },
    { lat: 48.464331, lng: 20.415855 },
    { lat: 48.464325, lng: 20.416041 },
    { lat: 48.46435, lng: 20.41609 },
    { lat: 48.464426, lng: 20.416047 },
    { lat: 48.464436, lng: 20.416012 },
    { lat: 48.46442, lng: 20.415902 },
    { lat: 48.464487, lng: 20.415931 },
    { lat: 48.464579, lng: 20.415826 },
    { lat: 48.464661, lng: 20.415901 },
    { lat: 48.464679, lng: 20.41604 },
    { lat: 48.464714, lng: 20.416064 },
    { lat: 48.464785, lng: 20.41602 },
    { lat: 48.464963, lng: 20.416059 },
    { lat: 48.464997, lng: 20.416107 },
    { lat: 48.465023, lng: 20.416256 },
    { lat: 48.465055, lng: 20.416255 },
    { lat: 48.465106, lng: 20.416196 },
    { lat: 48.465162, lng: 20.41624 },
    { lat: 48.465155, lng: 20.416326 },
    { lat: 48.465079, lng: 20.416381 },
    { lat: 48.465094, lng: 20.416432 },
    { lat: 48.465226, lng: 20.41659 },
    { lat: 48.465369, lng: 20.416563 },
    { lat: 48.465432, lng: 20.41658 },
    { lat: 48.46546, lng: 20.416548 },
    { lat: 48.465495, lng: 20.416453 },
    { lat: 48.465529, lng: 20.416435 },
    { lat: 48.465528, lng: 20.416593 },
    { lat: 48.465546, lng: 20.416683 },
    { lat: 48.465578, lng: 20.416709 },
    { lat: 48.465735, lng: 20.416641 },
    { lat: 48.465779, lng: 20.416611 },
    { lat: 48.465844, lng: 20.416825 },
    { lat: 48.465865, lng: 20.416844 },
    { lat: 48.465977, lng: 20.416808 },
    { lat: 48.466147, lng: 20.416925 },
    { lat: 48.466162, lng: 20.417029 },
    { lat: 48.466268, lng: 20.417081 },
    { lat: 48.466414, lng: 20.417017 },
    { lat: 48.466487, lng: 20.417013 },
    { lat: 48.466554, lng: 20.417065 },
    { lat: 48.466667, lng: 20.41723 },
    { lat: 48.466608, lng: 20.417291 },
    { lat: 48.466597, lng: 20.417333 },
    { lat: 48.466625, lng: 20.417387 },
    { lat: 48.466726, lng: 20.417382 },
    { lat: 48.466901, lng: 20.417173 },
    { lat: 48.466957, lng: 20.417233 },
    { lat: 48.466991, lng: 20.417352 },
    { lat: 48.467119, lng: 20.417498 },
    { lat: 48.467137, lng: 20.417451 },
    { lat: 48.467114, lng: 20.417369 },
    { lat: 48.467126, lng: 20.417343 },
    { lat: 48.467184, lng: 20.417386 },
    { lat: 48.467227, lng: 20.417447 },
    { lat: 48.467286, lng: 20.417423 },
    { lat: 48.467332, lng: 20.417404 },
    { lat: 48.467356, lng: 20.41738 },
    { lat: 48.467381, lng: 20.417354 },
    { lat: 48.467463, lng: 20.417437 },
    { lat: 48.467612, lng: 20.417589 },
    { lat: 48.467778, lng: 20.417709 },
    { lat: 48.467879, lng: 20.417983 },
    { lat: 48.467979, lng: 20.418035 },
    { lat: 48.468095, lng: 20.418077 },
    { lat: 48.468203, lng: 20.418141 },
    { lat: 48.468298, lng: 20.418175 },
    { lat: 48.468346, lng: 20.418304 },
    { lat: 48.468314, lng: 20.418494 },
    { lat: 48.468363, lng: 20.418617 },
    { lat: 48.46837, lng: 20.418723 },
    { lat: 48.468406, lng: 20.418803 },
    { lat: 48.46848, lng: 20.418838 },
    { lat: 48.468572, lng: 20.418999 },
    { lat: 48.468709, lng: 20.419155 },
    { lat: 48.468743, lng: 20.41915 },
    { lat: 48.468802, lng: 20.418995 },
    { lat: 48.468829, lng: 20.419008 },
    { lat: 48.468853, lng: 20.419127 },
    { lat: 48.46891, lng: 20.419174 },
    { lat: 48.468996, lng: 20.419123 },
    { lat: 48.469067, lng: 20.419134 },
    { lat: 48.469135, lng: 20.419199 },
    { lat: 48.469226, lng: 20.41923 },
    { lat: 48.469238, lng: 20.419262 },
    { lat: 48.469204, lng: 20.419446 },
    { lat: 48.469206, lng: 20.419478 },
    { lat: 48.469317, lng: 20.419636 },
    { lat: 48.469354, lng: 20.419636 },
    { lat: 48.469481, lng: 20.419606 },
    { lat: 48.469565, lng: 20.419756 },
    { lat: 48.469796, lng: 20.419843 },
    { lat: 48.469817, lng: 20.419916 },
    { lat: 48.469855, lng: 20.419926 },
    { lat: 48.470063, lng: 20.419718 },
    { lat: 48.470223, lng: 20.419965 },
    { lat: 48.470281, lng: 20.4201 },
    { lat: 48.470407, lng: 20.420219 },
    { lat: 48.470436, lng: 20.420298 },
    { lat: 48.470488, lng: 20.420277 },
  ],
  Rudná: [
    { lat: 48.6439181, lng: 20.488404 },
    { lat: 48.6439309, lng: 20.4884186 },
    { lat: 48.6440351, lng: 20.488468 },
    { lat: 48.6466767, lng: 20.4993424 },
    { lat: 48.6467502, lng: 20.4992649 },
    { lat: 48.6468119, lng: 20.4989922 },
    { lat: 48.646894, lng: 20.4991038 },
    { lat: 48.6470073, lng: 20.4990606 },
    { lat: 48.6470031, lng: 20.4989819 },
    { lat: 48.6470816, lng: 20.4989165 },
    { lat: 48.6471576, lng: 20.4990168 },
    { lat: 48.6471573, lng: 20.49892 },
    { lat: 48.6472151, lng: 20.4988709 },
    { lat: 48.647246, lng: 20.4989152 },
    { lat: 48.6473067, lng: 20.4988787 },
    { lat: 48.6474424, lng: 20.4990174 },
    { lat: 48.6476641, lng: 20.4989215 },
    { lat: 48.6477711, lng: 20.499042 },
    { lat: 48.6481087, lng: 20.499042 },
    { lat: 48.6481453, lng: 20.4989673 },
    { lat: 48.6481199, lng: 20.4988886 },
    { lat: 48.6482045, lng: 20.4988903 },
    { lat: 48.6483191, lng: 20.4987623 },
    { lat: 48.6484743, lng: 20.4988181 },
    { lat: 48.648618, lng: 20.4987488 },
    { lat: 48.6486062, lng: 20.4986737 },
    { lat: 48.6486495, lng: 20.4986596 },
    { lat: 48.6488178, lng: 20.498673 },
    { lat: 48.6488758, lng: 20.4987795 },
    { lat: 48.6491202, lng: 20.4987469 },
    { lat: 48.6493224, lng: 20.4985576 },
    { lat: 48.6496254, lng: 20.498646 },
    { lat: 48.6497809, lng: 20.4985911 },
    { lat: 48.6499216, lng: 20.4984088 },
    { lat: 48.6503366, lng: 20.4984909 },
    { lat: 48.6504362, lng: 20.4984727 },
    { lat: 48.6505171, lng: 20.4983772 },
    { lat: 48.6505303, lng: 20.4984284 },
    { lat: 48.6507269, lng: 20.4983585 },
    { lat: 48.6508521, lng: 20.4984831 },
    { lat: 48.651202, lng: 20.4985316 },
    { lat: 48.6514284, lng: 20.4987188 },
    { lat: 48.6516741, lng: 20.4987213 },
    { lat: 48.6523656, lng: 20.4991155 },
    { lat: 48.6525296, lng: 20.499286 },
    { lat: 48.6526734, lng: 20.4991547 },
    { lat: 48.6534257, lng: 20.4994042 },
    { lat: 48.6536835, lng: 20.4995534 },
    { lat: 48.6539119, lng: 20.4998174 },
    { lat: 48.6564833, lng: 20.5018196 },
    { lat: 48.6569379, lng: 20.5007273 },
    { lat: 48.6574582, lng: 20.5004005 },
    { lat: 48.6584264, lng: 20.4995289 },
    { lat: 48.6591316, lng: 20.4991195 },
    { lat: 48.6599936, lng: 20.4988311 },
    { lat: 48.6602286, lng: 20.498613 },
    { lat: 48.6604819, lng: 20.4982825 },
    { lat: 48.6604818, lng: 20.4982223 },
    { lat: 48.6605769, lng: 20.4982035 },
    { lat: 48.6608737, lng: 20.4983043 },
    { lat: 48.6611426, lng: 20.4983956 },
    { lat: 48.6619496, lng: 20.4983604 },
    { lat: 48.6620618, lng: 20.4985906 },
    { lat: 48.6626553, lng: 20.4990705 },
    { lat: 48.6631937, lng: 20.4998238 },
    { lat: 48.6646909, lng: 20.4996951 },
    { lat: 48.6653957, lng: 20.4992744 },
    { lat: 48.6662785, lng: 20.4990957 },
    { lat: 48.6667408, lng: 20.4989037 },
    { lat: 48.6679746, lng: 20.4980213 },
    { lat: 48.6681372, lng: 20.4975254 },
    { lat: 48.6685831, lng: 20.4973487 },
    { lat: 48.6693676, lng: 20.4971591 },
    { lat: 48.6698306, lng: 20.4973122 },
    { lat: 48.6699768, lng: 20.4975181 },
    { lat: 48.6703039, lng: 20.4977138 },
    { lat: 48.6705961, lng: 20.4977309 },
    { lat: 48.6708071, lng: 20.4977442 },
    { lat: 48.670802, lng: 20.4966784 },
    { lat: 48.670757, lng: 20.4961696 },
    { lat: 48.6712074, lng: 20.4956843 },
    { lat: 48.6716582, lng: 20.4955182 },
    { lat: 48.6718685, lng: 20.4953022 },
    { lat: 48.671885, lng: 20.4951109 },
    { lat: 48.6722591, lng: 20.4945502 },
    { lat: 48.6733404, lng: 20.4935987 },
    { lat: 48.673567, lng: 20.4932173 },
    { lat: 48.6739052, lng: 20.4928952 },
    { lat: 48.6740561, lng: 20.4926592 },
    { lat: 48.6742698, lng: 20.4921418 },
    { lat: 48.6744706, lng: 20.4918535 },
    { lat: 48.6749538, lng: 20.491464 },
    { lat: 48.6751303, lng: 20.491456 },
    { lat: 48.6760192, lng: 20.490962 },
    { lat: 48.676323, lng: 20.4907165 },
    { lat: 48.676652, lng: 20.4905739 },
    { lat: 48.6779869, lng: 20.4893333 },
    { lat: 48.6784568, lng: 20.4892172 },
    { lat: 48.678995, lng: 20.4882325 },
    { lat: 48.6791914, lng: 20.4876438 },
    { lat: 48.6796312, lng: 20.4867656 },
    { lat: 48.6803208, lng: 20.4858224 },
    { lat: 48.6801749, lng: 20.4852061 },
    { lat: 48.6802023, lng: 20.4850052 },
    { lat: 48.680465, lng: 20.4842355 },
    { lat: 48.6805636, lng: 20.4840717 },
    { lat: 48.6809839, lng: 20.4837228 },
    { lat: 48.6812442, lng: 20.4836994 },
    { lat: 48.6813262, lng: 20.4836343 },
    { lat: 48.6817089, lng: 20.4827876 },
    { lat: 48.682147, lng: 20.482285 },
    { lat: 48.68252, lng: 20.4819936 },
    { lat: 48.6827235, lng: 20.481716 },
    { lat: 48.6830016, lng: 20.4815367 },
    { lat: 48.6833243, lng: 20.4814616 },
    { lat: 48.6843565, lng: 20.4801361 },
    { lat: 48.6848758, lng: 20.4797836 },
    { lat: 48.6850418, lng: 20.4796504 },
    { lat: 48.6856561, lng: 20.4791593 },
    { lat: 48.6854725, lng: 20.4771852 },
    { lat: 48.6847972, lng: 20.4733366 },
    { lat: 48.68406, lng: 20.4715256 },
    { lat: 48.6833923, lng: 20.4718593 },
    { lat: 48.6816395, lng: 20.4725795 },
    { lat: 48.6813393, lng: 20.4730094 },
    { lat: 48.681015, lng: 20.4728933 },
    { lat: 48.68061, lng: 20.4725711 },
    { lat: 48.6795405, lng: 20.472248 },
    { lat: 48.6779057, lng: 20.4721959 },
    { lat: 48.6773381, lng: 20.4720096 },
    { lat: 48.6771509, lng: 20.4716466 },
    { lat: 48.6760001, lng: 20.4700372 },
    { lat: 48.6756701, lng: 20.4702096 },
    { lat: 48.6749322, lng: 20.4700803 },
    { lat: 48.6747044, lng: 20.4701594 },
    { lat: 48.6740689, lng: 20.4701817 },
    { lat: 48.6735763, lng: 20.470372 },
    { lat: 48.6731813, lng: 20.4702346 },
    { lat: 48.6722236, lng: 20.4706669 },
    { lat: 48.6717361, lng: 20.4707223 },
    { lat: 48.6711372, lng: 20.4705779 },
    { lat: 48.6705695, lng: 20.4706339 },
    { lat: 48.6697182, lng: 20.4705397 },
    { lat: 48.6692769, lng: 20.4704252 },
    { lat: 48.6685702, lng: 20.4700803 },
    { lat: 48.6682331, lng: 20.4696213 },
    { lat: 48.6678415, lng: 20.4694487 },
    { lat: 48.6673876, lng: 20.4694429 },
    { lat: 48.6670593, lng: 20.4693419 },
    { lat: 48.6664109, lng: 20.4686845 },
    { lat: 48.6661426, lng: 20.4685915 },
    { lat: 48.6653133, lng: 20.4693806 },
    { lat: 48.6645, lng: 20.4696383 },
    { lat: 48.6644032, lng: 20.4697283 },
    { lat: 48.6642296, lng: 20.4701756 },
    { lat: 48.6641219, lng: 20.4710156 },
    { lat: 48.6639788, lng: 20.4715241 },
    { lat: 48.6643386, lng: 20.4716552 },
    { lat: 48.6636385, lng: 20.4722921 },
    { lat: 48.6630911, lng: 20.472467 },
    { lat: 48.6625545, lng: 20.4723297 },
    { lat: 48.6616961, lng: 20.4719598 },
    { lat: 48.6614127, lng: 20.4723603 },
    { lat: 48.6612104, lng: 20.4725187 },
    { lat: 48.6601939, lng: 20.4720769 },
    { lat: 48.6597483, lng: 20.4715788 },
    { lat: 48.6582829, lng: 20.4714498 },
    { lat: 48.6567256, lng: 20.4712073 },
    { lat: 48.6562948, lng: 20.4713402 },
    { lat: 48.656064, lng: 20.4716075 },
    { lat: 48.6540037, lng: 20.4712256 },
    { lat: 48.6536895, lng: 20.4709909 },
    { lat: 48.6510002, lng: 20.4704701 },
    { lat: 48.6507869, lng: 20.4698012 },
    { lat: 48.6507911, lng: 20.4698243 },
    { lat: 48.6505069, lng: 20.4699029 },
    { lat: 48.6504043, lng: 20.470016 },
    { lat: 48.6503659, lng: 20.4701632 },
    { lat: 48.6503748, lng: 20.4704612 },
    { lat: 48.6502996, lng: 20.4706126 },
    { lat: 48.6501943, lng: 20.4710796 },
    { lat: 48.6502232, lng: 20.4712435 },
    { lat: 48.6501416, lng: 20.4717387 },
    { lat: 48.6502245, lng: 20.4720841 },
    { lat: 48.6502192, lng: 20.4723958 },
    { lat: 48.650154, lng: 20.4725738 },
    { lat: 48.6501742, lng: 20.4727526 },
    { lat: 48.650288, lng: 20.4729048 },
    { lat: 48.6502497, lng: 20.4732275 },
    { lat: 48.6501274, lng: 20.473483 },
    { lat: 48.6502497, lng: 20.4738272 },
    { lat: 48.650099, lng: 20.4740973 },
    { lat: 48.6500736, lng: 20.4745325 },
    { lat: 48.6499716, lng: 20.4749611 },
    { lat: 48.6498499, lng: 20.4750499 },
    { lat: 48.6498513, lng: 20.4752054 },
    { lat: 48.6496758, lng: 20.4752379 },
    { lat: 48.6495581, lng: 20.4756721 },
    { lat: 48.6496091, lng: 20.4757981 },
    { lat: 48.6494806, lng: 20.4765701 },
    { lat: 48.6493258, lng: 20.4767629 },
    { lat: 48.6495151, lng: 20.4770959 },
    { lat: 48.6494578, lng: 20.4773982 },
    { lat: 48.6493935, lng: 20.477513 },
    { lat: 48.6494303, lng: 20.4776267 },
    { lat: 48.6493922, lng: 20.4777475 },
    { lat: 48.6493175, lng: 20.4777685 },
    { lat: 48.649247, lng: 20.4779597 },
    { lat: 48.6491461, lng: 20.4780058 },
    { lat: 48.6490545, lng: 20.4779353 },
    { lat: 48.648801, lng: 20.4782889 },
    { lat: 48.6487286, lng: 20.4786352 },
    { lat: 48.6483626, lng: 20.4793543 },
    { lat: 48.6482778, lng: 20.479432 },
    { lat: 48.6482525, lng: 20.479926 },
    { lat: 48.6480993, lng: 20.4801357 },
    { lat: 48.6478301, lng: 20.4802489 },
    { lat: 48.6477132, lng: 20.4804045 },
    { lat: 48.647341, lng: 20.4805376 },
    { lat: 48.6471018, lng: 20.4803783 },
    { lat: 48.6469434, lng: 20.4809524 },
    { lat: 48.6469562, lng: 20.4810838 },
    { lat: 48.6470897, lng: 20.4811581 },
    { lat: 48.6471325, lng: 20.4813104 },
    { lat: 48.6470921, lng: 20.4814346 },
    { lat: 48.6469712, lng: 20.4814823 },
    { lat: 48.6468558, lng: 20.4818886 },
    { lat: 48.6468896, lng: 20.4822165 },
    { lat: 48.646826, lng: 20.4823202 },
    { lat: 48.6468394, lng: 20.4825252 },
    { lat: 48.6464748, lng: 20.4828933 },
    { lat: 48.6464577, lng: 20.4830333 },
    { lat: 48.6467405, lng: 20.4832829 },
    { lat: 48.6464734, lng: 20.4833959 },
    { lat: 48.6464976, lng: 20.4835868 },
    { lat: 48.6464129, lng: 20.4837002 },
    { lat: 48.6463435, lng: 20.4836794 },
    { lat: 48.6463318, lng: 20.4835829 },
    { lat: 48.6462839, lng: 20.4835642 },
    { lat: 48.6461314, lng: 20.4836104 },
    { lat: 48.6459227, lng: 20.4835403 },
    { lat: 48.6458442, lng: 20.483635 },
    { lat: 48.6458273, lng: 20.4838743 },
    { lat: 48.6457038, lng: 20.4839732 },
    { lat: 48.645725, lng: 20.4837588 },
    { lat: 48.6456933, lng: 20.4836682 },
    { lat: 48.6455841, lng: 20.4837905 },
    { lat: 48.6454768, lng: 20.4845001 },
    { lat: 48.6454955, lng: 20.4846313 },
    { lat: 48.645703, lng: 20.4849418 },
    { lat: 48.6457596, lng: 20.4852168 },
    { lat: 48.6456057, lng: 20.4856484 },
    { lat: 48.6455444, lng: 20.4857572 },
    { lat: 48.6453982, lng: 20.48582 },
    { lat: 48.6452248, lng: 20.4863294 },
    { lat: 48.645094, lng: 20.4863844 },
    { lat: 48.6448437, lng: 20.4862207 },
    { lat: 48.6447711, lng: 20.4862978 },
    { lat: 48.6447412, lng: 20.4864335 },
    { lat: 48.6448064, lng: 20.4866989 },
    { lat: 48.6447379, lng: 20.4870024 },
    { lat: 48.6445797, lng: 20.4870758 },
    { lat: 48.6443378, lng: 20.486937 },
    { lat: 48.6442747, lng: 20.4869894 },
    { lat: 48.6441918, lng: 20.4872144 },
    { lat: 48.6441901, lng: 20.4875568 },
    { lat: 48.6442235, lng: 20.4876686 },
    { lat: 48.6443745, lng: 20.4876994 },
    { lat: 48.6443559, lng: 20.487831 },
    { lat: 48.6442851, lng: 20.4881076 },
    { lat: 48.6440244, lng: 20.4878198 },
    { lat: 48.6439757, lng: 20.4879607 },
    { lat: 48.6440262, lng: 20.4880173 },
    { lat: 48.6439181, lng: 20.488404 },
  ],
  SilickáJablonica: [
    { lat: 48.543615, lng: 20.611601 },
    { lat: 48.543662, lng: 20.611994 },
    { lat: 48.543644, lng: 20.612481 },
    { lat: 48.543622, lng: 20.612672 },
    { lat: 48.543525, lng: 20.613186 },
    { lat: 48.543476, lng: 20.613465 },
    { lat: 48.543339, lng: 20.614111 },
    { lat: 48.543376, lng: 20.614521 },
    { lat: 48.54338, lng: 20.614668 },
    { lat: 48.543439, lng: 20.615158 },
    { lat: 48.543457, lng: 20.615347 },
    { lat: 48.543459, lng: 20.615623 },
    { lat: 48.543476, lng: 20.615773 },
    { lat: 48.543521, lng: 20.615953 },
    { lat: 48.543583, lng: 20.61613 },
    { lat: 48.543726, lng: 20.616429 },
    { lat: 48.543918, lng: 20.616663 },
    { lat: 48.544002, lng: 20.616758 },
    { lat: 48.54424, lng: 20.617085 },
    { lat: 48.544362, lng: 20.617304 },
    { lat: 48.544756, lng: 20.617863 },
    { lat: 48.544851, lng: 20.618033 },
    { lat: 48.544948, lng: 20.618082 },
    { lat: 48.545057, lng: 20.618099 },
    { lat: 48.545408, lng: 20.618408 },
    { lat: 48.545485, lng: 20.618443 },
    { lat: 48.545552, lng: 20.61846 },
    { lat: 48.5457, lng: 20.618436 },
    { lat: 48.545785, lng: 20.61842 },
    { lat: 48.545981, lng: 20.618451 },
    { lat: 48.546103, lng: 20.618529 },
    { lat: 48.546357, lng: 20.618736 },
    { lat: 48.546561, lng: 20.618832 },
    { lat: 48.547094, lng: 20.618983 },
    { lat: 48.547387, lng: 20.619001 },
    { lat: 48.547508, lng: 20.619044 },
    { lat: 48.547672, lng: 20.619231 },
    { lat: 48.547837, lng: 20.619437 },
    { lat: 48.547993, lng: 20.619729 },
    { lat: 48.548038, lng: 20.61984 },
    { lat: 48.548132, lng: 20.620064 },
    { lat: 48.548237, lng: 20.620459 },
    { lat: 48.548298, lng: 20.620629 },
    { lat: 48.548385, lng: 20.620769 },
    { lat: 48.548498, lng: 20.620905 },
    { lat: 48.548737, lng: 20.621065 },
    { lat: 48.548854, lng: 20.621129 },
    { lat: 48.548949, lng: 20.621168 },
    { lat: 48.549029, lng: 20.62123 },
    { lat: 48.549097, lng: 20.62132 },
    { lat: 48.549222, lng: 20.621546 },
    { lat: 48.549403, lng: 20.621816 },
    { lat: 48.549455, lng: 20.62195 },
    { lat: 48.549524, lng: 20.622233 },
    { lat: 48.549569, lng: 20.622463 },
    { lat: 48.549626, lng: 20.622581 },
    { lat: 48.549676, lng: 20.622645 },
    { lat: 48.549813, lng: 20.622758 },
    { lat: 48.549962, lng: 20.622886 },
    { lat: 48.550029, lng: 20.622932 },
    { lat: 48.550139, lng: 20.622978 },
    { lat: 48.550233, lng: 20.623001 },
    { lat: 48.550406, lng: 20.62308 },
    { lat: 48.550528, lng: 20.623163 },
    { lat: 48.550626, lng: 20.623256 },
    { lat: 48.55069, lng: 20.623337 },
    { lat: 48.550759, lng: 20.623465 },
    { lat: 48.550788, lng: 20.62359 },
    { lat: 48.550789, lng: 20.623787 },
    { lat: 48.550803, lng: 20.623973 },
    { lat: 48.550862, lng: 20.624313 },
    { lat: 48.550892, lng: 20.624555 },
    { lat: 48.55095, lng: 20.624912 },
    { lat: 48.550976, lng: 20.625164 },
    { lat: 48.550996, lng: 20.62552 },
    { lat: 48.551019, lng: 20.625963 },
    { lat: 48.551038, lng: 20.626213 },
    { lat: 48.551036, lng: 20.626465 },
    { lat: 48.551021, lng: 20.626786 },
    { lat: 48.550971, lng: 20.627455 },
    { lat: 48.550972, lng: 20.627608 },
    { lat: 48.550991, lng: 20.627731 },
    { lat: 48.550989, lng: 20.627809 },
    { lat: 48.550894, lng: 20.628185 },
    { lat: 48.550835, lng: 20.628503 },
    { lat: 48.55077, lng: 20.628808 },
    { lat: 48.550732, lng: 20.629049 },
    { lat: 48.550661, lng: 20.629371 },
    { lat: 48.550528, lng: 20.629779 },
    { lat: 48.550528, lng: 20.629848 },
    { lat: 48.550553, lng: 20.629913 },
    { lat: 48.5506, lng: 20.630037 },
    { lat: 48.550673, lng: 20.630294 },
    { lat: 48.550977, lng: 20.631264 },
    { lat: 48.551147, lng: 20.631981 },
    { lat: 48.551181, lng: 20.632006 },
    { lat: 48.551029, lng: 20.632726 },
    { lat: 48.550953, lng: 20.633005 },
    { lat: 48.550912, lng: 20.633246 },
    { lat: 48.55088, lng: 20.633564 },
    { lat: 48.550884, lng: 20.633731 },
    { lat: 48.550914, lng: 20.633907 },
    { lat: 48.55096, lng: 20.634142 },
    { lat: 48.551071, lng: 20.634544 },
    { lat: 48.551249, lng: 20.635031 },
    { lat: 48.551383, lng: 20.635265 },
    { lat: 48.551422, lng: 20.63535 },
    { lat: 48.551425, lng: 20.635443 },
    { lat: 48.551449, lng: 20.63547 },
    { lat: 48.552014, lng: 20.636116 },
    { lat: 48.552271, lng: 20.636656 },
    { lat: 48.552664, lng: 20.637451 },
    { lat: 48.553314, lng: 20.63854 },
    { lat: 48.553728, lng: 20.638756 },
    { lat: 48.554071, lng: 20.638922 },
    { lat: 48.554908, lng: 20.639534 },
    { lat: 48.555288, lng: 20.640082 },
    { lat: 48.555438, lng: 20.640468 },
    { lat: 48.555412, lng: 20.64084 },
    { lat: 48.555343, lng: 20.641249 },
    { lat: 48.555244, lng: 20.641537 },
    { lat: 48.55516, lng: 20.641633 },
    { lat: 48.554918, lng: 20.642 },
    { lat: 48.554537, lng: 20.642548 },
    { lat: 48.554044, lng: 20.643734 },
    { lat: 48.5539037, lng: 20.6438132 },
    { lat: 48.553532, lng: 20.644023 },
    { lat: 48.553149, lng: 20.644107 },
    { lat: 48.553086, lng: 20.644073 },
    { lat: 48.553046, lng: 20.644017 },
    { lat: 48.552895, lng: 20.643789 },
    { lat: 48.55199, lng: 20.643361 },
    { lat: 48.5519695, lng: 20.6434417 },
    { lat: 48.55193, lng: 20.643597 },
    { lat: 48.551977, lng: 20.644056 },
    { lat: 48.552234, lng: 20.64387 },
    { lat: 48.552761, lng: 20.644728 },
    { lat: 48.553409, lng: 20.64604 },
    { lat: 48.553795, lng: 20.646901 },
    { lat: 48.553945, lng: 20.647174 },
    { lat: 48.554153, lng: 20.647294 },
    { lat: 48.555924, lng: 20.648268 },
    { lat: 48.557399, lng: 20.649115 },
    { lat: 48.557634, lng: 20.649241 },
    { lat: 48.55781, lng: 20.649483 },
    { lat: 48.558209, lng: 20.650018 },
    { lat: 48.558614, lng: 20.650693 },
    { lat: 48.559557, lng: 20.652501 },
    { lat: 48.560618, lng: 20.65329 },
    { lat: 48.561388, lng: 20.653857 },
    { lat: 48.561413, lng: 20.653874 },
    { lat: 48.5614775, lng: 20.6530799 },
    { lat: 48.5613934, lng: 20.6524388 },
    { lat: 48.5607078, lng: 20.6505453 },
    { lat: 48.5601272, lng: 20.6490957 },
    { lat: 48.5596438, lng: 20.6481549 },
    { lat: 48.5592979, lng: 20.6467247 },
    { lat: 48.5590669, lng: 20.6453515 },
    { lat: 48.5589746, lng: 20.6452003 },
    { lat: 48.5581896, lng: 20.6450961 },
    { lat: 48.5579867, lng: 20.644874 },
    { lat: 48.5574254, lng: 20.6436781 },
    { lat: 48.5569078, lng: 20.6429794 },
    { lat: 48.5569206, lng: 20.6424008 },
    { lat: 48.557042, lng: 20.6422362 },
    { lat: 48.5571556, lng: 20.6416742 },
    { lat: 48.557079, lng: 20.6407504 },
    { lat: 48.5573767, lng: 20.639646 },
    { lat: 48.5573869, lng: 20.6394579 },
    { lat: 48.5572469, lng: 20.6389577 },
    { lat: 48.5572458, lng: 20.6387378 },
    { lat: 48.5576673, lng: 20.6370158 },
    { lat: 48.5576673, lng: 20.636836 },
    { lat: 48.5574471, lng: 20.636567 },
    { lat: 48.5573964, lng: 20.6363792 },
    { lat: 48.5574026, lng: 20.6361164 },
    { lat: 48.5576492, lng: 20.6357794 },
    { lat: 48.5585101, lng: 20.635243 },
    { lat: 48.5588397, lng: 20.6352346 },
    { lat: 48.5591923, lng: 20.6351163 },
    { lat: 48.5597108, lng: 20.6346352 },
    { lat: 48.5599938, lng: 20.6345852 },
    { lat: 48.5602273, lng: 20.6346629 },
    { lat: 48.5603896, lng: 20.6345372 },
    { lat: 48.5604583, lng: 20.6342926 },
    { lat: 48.5605243, lng: 20.6334747 },
    { lat: 48.5608154, lng: 20.6333134 },
    { lat: 48.5610059, lng: 20.633314 },
    { lat: 48.5613227, lng: 20.6334503 },
    { lat: 48.5623458, lng: 20.6342156 },
    { lat: 48.5628141, lng: 20.6344886 },
    { lat: 48.5642839, lng: 20.6349082 },
    { lat: 48.5650327, lng: 20.6336707 },
    { lat: 48.5652597, lng: 20.6334767 },
    { lat: 48.5654264, lng: 20.6328267 },
    { lat: 48.5653618, lng: 20.6326336 },
    { lat: 48.5651451, lng: 20.6325517 },
    { lat: 48.5651039, lng: 20.632099 },
    { lat: 48.5655121, lng: 20.6310977 },
    { lat: 48.5655994, lng: 20.6310265 },
    { lat: 48.5656069, lng: 20.63089 },
    { lat: 48.5657943, lng: 20.6308134 },
    { lat: 48.565942, lng: 20.6309434 },
    { lat: 48.5660003, lng: 20.6307185 },
    { lat: 48.5659089, lng: 20.630436 },
    { lat: 48.565981, lng: 20.6302516 },
    { lat: 48.5661976, lng: 20.6300136 },
    { lat: 48.5662868, lng: 20.6299888 },
    { lat: 48.5664131, lng: 20.6296524 },
    { lat: 48.5666039, lng: 20.6294824 },
    { lat: 48.5667114, lng: 20.6295012 },
    { lat: 48.5667616, lng: 20.6293627 },
    { lat: 48.5669725, lng: 20.6291881 },
    { lat: 48.567037, lng: 20.6290406 },
    { lat: 48.5671598, lng: 20.629013 },
    { lat: 48.56727, lng: 20.6288897 },
    { lat: 48.5672991, lng: 20.6287551 },
    { lat: 48.5673959, lng: 20.6287577 },
    { lat: 48.5673945, lng: 20.6285631 },
    { lat: 48.5676362, lng: 20.6284318 },
    { lat: 48.5676829, lng: 20.6282879 },
    { lat: 48.5678452, lng: 20.6281134 },
    { lat: 48.5679525, lng: 20.6282154 },
    { lat: 48.5680197, lng: 20.6281427 },
    { lat: 48.5680388, lng: 20.6280458 },
    { lat: 48.5680059, lng: 20.6279767 },
    { lat: 48.5680593, lng: 20.6279272 },
    { lat: 48.5681665, lng: 20.6275671 },
    { lat: 48.5682442, lng: 20.6275965 },
    { lat: 48.5683217, lng: 20.6275276 },
    { lat: 48.5684445, lng: 20.6277765 },
    { lat: 48.5684839, lng: 20.6275315 },
    { lat: 48.5685241, lng: 20.6274955 },
    { lat: 48.5686041, lng: 20.6277416 },
    { lat: 48.56866, lng: 20.6277292 },
    { lat: 48.5686669, lng: 20.6276131 },
    { lat: 48.5688074, lng: 20.6275702 },
    { lat: 48.5688095, lng: 20.6274484 },
    { lat: 48.5687151, lng: 20.6273887 },
    { lat: 48.5686992, lng: 20.6271405 },
    { lat: 48.5687294, lng: 20.6271333 },
    { lat: 48.5687794, lng: 20.6273023 },
    { lat: 48.5688367, lng: 20.6272368 },
    { lat: 48.5690348, lng: 20.6272342 },
    { lat: 48.5690338, lng: 20.6270829 },
    { lat: 48.5688819, lng: 20.6270535 },
    { lat: 48.5688458, lng: 20.6269303 },
    { lat: 48.5689687, lng: 20.6269041 },
    { lat: 48.5690708, lng: 20.6267083 },
    { lat: 48.5692364, lng: 20.6269275 },
    { lat: 48.569312, lng: 20.6268711 },
    { lat: 48.5693865, lng: 20.6269339 },
    { lat: 48.5694979, lng: 20.626839 },
    { lat: 48.5696224, lng: 20.6268891 },
    { lat: 48.5697195, lng: 20.6270528 },
    { lat: 48.5697721, lng: 20.6270406 },
    { lat: 48.5698029, lng: 20.6267775 },
    { lat: 48.5699101, lng: 20.6267547 },
    { lat: 48.5699523, lng: 20.6266446 },
    { lat: 48.5698908, lng: 20.6265046 },
    { lat: 48.5699902, lng: 20.6263247 },
    { lat: 48.570189, lng: 20.626555 },
    { lat: 48.5702816, lng: 20.6265331 },
    { lat: 48.570344, lng: 20.6266075 },
    { lat: 48.5704587, lng: 20.6266325 },
    { lat: 48.5705945, lng: 20.6265029 },
    { lat: 48.5705617, lng: 20.6264039 },
    { lat: 48.5706248, lng: 20.6262519 },
    { lat: 48.570585, lng: 20.6259306 },
    { lat: 48.5706186, lng: 20.6258947 },
    { lat: 48.5707518, lng: 20.6259683 },
    { lat: 48.5707798, lng: 20.6257488 },
    { lat: 48.5708624, lng: 20.6256968 },
    { lat: 48.5709122, lng: 20.6257364 },
    { lat: 48.5710335, lng: 20.6256001 },
    { lat: 48.5710841, lng: 20.6250787 },
    { lat: 48.5710195, lng: 20.6250943 },
    { lat: 48.5709789, lng: 20.6249704 },
    { lat: 48.5710312, lng: 20.6247098 },
    { lat: 48.5709866, lng: 20.624658 },
    { lat: 48.5709044, lng: 20.6239431 },
    { lat: 48.5709147, lng: 20.6237681 },
    { lat: 48.571115, lng: 20.6232708 },
    { lat: 48.5701546, lng: 20.6188603 },
    { lat: 48.5704104, lng: 20.6184356 },
    { lat: 48.5710129, lng: 20.6179167 },
    { lat: 48.5707017, lng: 20.6173492 },
    { lat: 48.5715284, lng: 20.6156283 },
    { lat: 48.5718237, lng: 20.6149344 },
    { lat: 48.5717612, lng: 20.6149781 },
    { lat: 48.5720298, lng: 20.6142583 },
    { lat: 48.5723481, lng: 20.6136907 },
    { lat: 48.5727885, lng: 20.6132378 },
    { lat: 48.5733214, lng: 20.6129699 },
    { lat: 48.5734022, lng: 20.6128071 },
    { lat: 48.5732946, lng: 20.6114714 },
    { lat: 48.5733031, lng: 20.6106537 },
    { lat: 48.5734475, lng: 20.6093063 },
    { lat: 48.5737201, lng: 20.6082405 },
    { lat: 48.5743232, lng: 20.6071191 },
    { lat: 48.5753255, lng: 20.6065088 },
    { lat: 48.5766998, lng: 20.6057957 },
    { lat: 48.5771495, lng: 20.6052231 },
    { lat: 48.5781775, lng: 20.6033192 },
    { lat: 48.5786462, lng: 20.6029295 },
    { lat: 48.5802275, lng: 20.602171 },
    { lat: 48.5824356, lng: 20.6006151 },
    { lat: 48.5834717, lng: 20.5991782 },
    { lat: 48.5845965, lng: 20.5984724 },
    { lat: 48.5847829, lng: 20.5984927 },
    { lat: 48.5862768, lng: 20.5979653 },
    { lat: 48.5872053, lng: 20.5977104 },
    { lat: 48.5883841, lng: 20.5978023 },
    { lat: 48.5895455, lng: 20.5977524 },
    { lat: 48.5897176, lng: 20.5976858 },
    { lat: 48.5907916, lng: 20.5976876 },
    { lat: 48.5914286, lng: 20.5975822 },
    { lat: 48.592223, lng: 20.5972609 },
    { lat: 48.5934255, lng: 20.5965019 },
    { lat: 48.5944188, lng: 20.5955266 },
    { lat: 48.5949749, lng: 20.5944206 },
    { lat: 48.596256, lng: 20.5930313 },
    { lat: 48.5971211, lng: 20.5916942 },
    { lat: 48.598111, lng: 20.5898319 },
    { lat: 48.5982648, lng: 20.5887195 },
    { lat: 48.5985624, lng: 20.587837 },
    { lat: 48.5979656, lng: 20.5870136 },
    { lat: 48.5982722, lng: 20.5861965 },
    { lat: 48.5978514, lng: 20.5841231 },
    { lat: 48.5974006, lng: 20.583682 },
    { lat: 48.5978287, lng: 20.5825335 },
    { lat: 48.598057, lng: 20.5816066 },
    { lat: 48.5985598, lng: 20.5803088 },
    { lat: 48.5993753, lng: 20.5786988 },
    { lat: 48.6008555, lng: 20.5767623 },
    { lat: 48.6020535, lng: 20.5750842 },
    { lat: 48.602118, lng: 20.575029 },
    { lat: 48.6020515, lng: 20.574725 },
    { lat: 48.601686, lng: 20.5715024 },
    { lat: 48.6003083, lng: 20.5693222 },
    { lat: 48.5987779, lng: 20.5648075 },
    { lat: 48.5986267, lng: 20.563519 },
    { lat: 48.5985938, lng: 20.5620811 },
    { lat: 48.5989482, lng: 20.5578765 },
    { lat: 48.5982162, lng: 20.5554751 },
    { lat: 48.5960081, lng: 20.5562904 },
    { lat: 48.5948559, lng: 20.5566464 },
    { lat: 48.5946133, lng: 20.5568151 },
    { lat: 48.5933056, lng: 20.5573805 },
    { lat: 48.5886301, lng: 20.561468 },
    { lat: 48.5873601, lng: 20.56129 },
    { lat: 48.586615, lng: 20.561385 },
    { lat: 48.5845888, lng: 20.5629235 },
    { lat: 48.5840312, lng: 20.5630042 },
    { lat: 48.5811885, lng: 20.5646282 },
    { lat: 48.5807046, lng: 20.5647244 },
    { lat: 48.5784187, lng: 20.5645684 },
    { lat: 48.57775, lng: 20.5642053 },
    { lat: 48.5764585, lng: 20.5640533 },
    { lat: 48.5754757, lng: 20.5645728 },
    { lat: 48.5729297, lng: 20.5653262 },
    { lat: 48.5712752, lng: 20.5652547 },
    { lat: 48.5685664, lng: 20.5659882 },
    { lat: 48.5679648, lng: 20.5650074 },
    { lat: 48.5663851, lng: 20.5651269 },
    { lat: 48.5654441, lng: 20.5658985 },
    { lat: 48.5633774, lng: 20.566916 },
    { lat: 48.5603602, lng: 20.5686 },
    { lat: 48.5596714, lng: 20.5686685 },
    { lat: 48.5589243, lng: 20.5686512 },
    { lat: 48.5585386, lng: 20.5680282 },
    { lat: 48.5583161, lng: 20.5670342 },
    { lat: 48.5578794, lng: 20.566007 },
    { lat: 48.5577307, lng: 20.5653623 },
    { lat: 48.5574763, lng: 20.5636655 },
    { lat: 48.556884, lng: 20.5626255 },
    { lat: 48.5566204, lng: 20.5617453 },
    { lat: 48.5563418, lng: 20.560422 },
    { lat: 48.5560307, lng: 20.5592589 },
    { lat: 48.5557242, lng: 20.5586733 },
    { lat: 48.5549506, lng: 20.5578215 },
    { lat: 48.554342, lng: 20.557443 },
    { lat: 48.5530359, lng: 20.5580181 },
    { lat: 48.5512496, lng: 20.5599788 },
    { lat: 48.5510489, lng: 20.5609129 },
    { lat: 48.5470856, lng: 20.5613396 },
    { lat: 48.5455318, lng: 20.561344 },
    { lat: 48.5446995, lng: 20.562066 },
    { lat: 48.5446288, lng: 20.5626473 },
    { lat: 48.5446111, lng: 20.5632649 },
    { lat: 48.5445505, lng: 20.563604 },
    { lat: 48.5443323, lng: 20.5640964 },
    { lat: 48.5443122, lng: 20.5650437 },
    { lat: 48.5439462, lng: 20.5662936 },
    { lat: 48.5437928, lng: 20.5671966 },
    { lat: 48.5435094, lng: 20.5679683 },
    { lat: 48.5432896, lng: 20.5682309 },
    { lat: 48.5430813, lng: 20.568813 },
    { lat: 48.543053, lng: 20.5691696 },
    { lat: 48.5429286, lng: 20.5694952 },
    { lat: 48.5425397, lng: 20.5700275 },
    { lat: 48.5419731, lng: 20.5719077 },
    { lat: 48.541576, lng: 20.5720725 },
    { lat: 48.5408509, lng: 20.5726204 },
    { lat: 48.5401566, lng: 20.5727571 },
    { lat: 48.5395777, lng: 20.5731759 },
    { lat: 48.539309, lng: 20.572825 },
    { lat: 48.53909, lng: 20.5735 },
    { lat: 48.538885, lng: 20.574118 },
    { lat: 48.53868, lng: 20.574931 },
    { lat: 48.538577, lng: 20.57551 },
    { lat: 48.53847, lng: 20.576103 },
    { lat: 48.538282, lng: 20.576787 },
    { lat: 48.53804, lng: 20.57722 },
    { lat: 48.537787, lng: 20.577674 },
    { lat: 48.537372, lng: 20.578708 },
    { lat: 48.537323, lng: 20.579287 },
    { lat: 48.537265, lng: 20.579984 },
    { lat: 48.536929, lng: 20.581679 },
    { lat: 48.536823, lng: 20.582164 },
    { lat: 48.536716, lng: 20.582652 },
    { lat: 48.536444, lng: 20.583335 },
    { lat: 48.536262, lng: 20.583819 },
    { lat: 48.535988, lng: 20.584588 },
    { lat: 48.535822, lng: 20.585786 },
    { lat: 48.535759, lng: 20.586595 },
    { lat: 48.536009, lng: 20.58665 },
    { lat: 48.536465, lng: 20.586727 },
    { lat: 48.537072, lng: 20.586842 },
    { lat: 48.53792, lng: 20.587004 },
    { lat: 48.538823, lng: 20.587183 },
    { lat: 48.538848, lng: 20.587187 },
    { lat: 48.538863, lng: 20.587491 },
    { lat: 48.53889, lng: 20.587885 },
    { lat: 48.53908, lng: 20.589173 },
    { lat: 48.539195, lng: 20.58983 },
    { lat: 48.539212, lng: 20.589949 },
    { lat: 48.539187, lng: 20.590199 },
    { lat: 48.539141, lng: 20.590629 },
    { lat: 48.539151, lng: 20.590719 },
    { lat: 48.53919, lng: 20.591023 },
    { lat: 48.539162, lng: 20.591543 },
    { lat: 48.539165, lng: 20.591629 },
    { lat: 48.539231, lng: 20.592052 },
    { lat: 48.539333, lng: 20.592549 },
    { lat: 48.539566, lng: 20.593226 },
    { lat: 48.539721, lng: 20.593591 },
    { lat: 48.53987, lng: 20.593844 },
    { lat: 48.540032, lng: 20.594072 },
    { lat: 48.540193, lng: 20.594248 },
    { lat: 48.540407, lng: 20.594446 },
    { lat: 48.540532, lng: 20.594671 },
    { lat: 48.540662, lng: 20.594868 },
    { lat: 48.540709, lng: 20.594953 },
    { lat: 48.540827, lng: 20.595253 },
    { lat: 48.540912, lng: 20.595519 },
    { lat: 48.540925, lng: 20.595625 },
    { lat: 48.540925, lng: 20.59574 },
    { lat: 48.540913, lng: 20.59593 },
    { lat: 48.540855, lng: 20.596098 },
    { lat: 48.540741, lng: 20.596315 },
    { lat: 48.540717, lng: 20.596448 },
    { lat: 48.54069, lng: 20.596584 },
    { lat: 48.540699, lng: 20.596732 },
    { lat: 48.540804, lng: 20.596955 },
    { lat: 48.540962, lng: 20.597108 },
    { lat: 48.541081, lng: 20.597261 },
    { lat: 48.541154, lng: 20.597385 },
    { lat: 48.541198, lng: 20.597511 },
    { lat: 48.541733, lng: 20.598401 },
    { lat: 48.542049, lng: 20.598662 },
    { lat: 48.542133, lng: 20.599178 },
    { lat: 48.542293, lng: 20.599345 },
    { lat: 48.542377, lng: 20.599466 },
    { lat: 48.542409, lng: 20.599514 },
    { lat: 48.542551, lng: 20.599872 },
    { lat: 48.5426, lng: 20.600132 },
    { lat: 48.542579, lng: 20.600243 },
    { lat: 48.542421, lng: 20.601187 },
    { lat: 48.542384, lng: 20.601855 },
    { lat: 48.542392, lng: 20.60204 },
    { lat: 48.542452, lng: 20.602389 },
    { lat: 48.542553, lng: 20.602785 },
    { lat: 48.542648, lng: 20.602936 },
    { lat: 48.542805, lng: 20.603206 },
    { lat: 48.542953, lng: 20.603594 },
    { lat: 48.542951, lng: 20.603761 },
    { lat: 48.542931, lng: 20.604042 },
    { lat: 48.542894, lng: 20.604404 },
    { lat: 48.542808, lng: 20.604733 },
    { lat: 48.542437, lng: 20.605752 },
    { lat: 48.542215, lng: 20.606313 },
    { lat: 48.542146, lng: 20.606597 },
    { lat: 48.542133, lng: 20.606754 },
    { lat: 48.542148, lng: 20.606916 },
    { lat: 48.542211, lng: 20.607111 },
    { lat: 48.542349, lng: 20.607408 },
    { lat: 48.542605, lng: 20.607962 },
    { lat: 48.542884, lng: 20.608449 },
    { lat: 48.543134, lng: 20.609041 },
    { lat: 48.543155, lng: 20.609133 },
    { lat: 48.543227, lng: 20.609383 },
    { lat: 48.543318, lng: 20.60968 },
    { lat: 48.543381, lng: 20.610019 },
    { lat: 48.543426, lng: 20.610156 },
    { lat: 48.543477, lng: 20.610389 },
    { lat: 48.543532, lng: 20.610953 },
    { lat: 48.543605, lng: 20.611396 },
    { lat: 48.543615, lng: 20.611601 },
  ],
  Bôrka: [
    { lat: 48.624295, lng: 20.795999 },
    { lat: 48.624353, lng: 20.796107 },
    { lat: 48.624371, lng: 20.796263 },
    { lat: 48.624516, lng: 20.797561 },
    { lat: 48.625075, lng: 20.802626 },
    { lat: 48.625225, lng: 20.804412 },
    { lat: 48.625233, lng: 20.804511 },
    { lat: 48.625309, lng: 20.805409 },
    { lat: 48.625366, lng: 20.806086 },
    { lat: 48.625379, lng: 20.806243 },
    { lat: 48.624975, lng: 20.807727 },
    { lat: 48.624311, lng: 20.811069 },
    { lat: 48.624304, lng: 20.812752 },
    { lat: 48.624128, lng: 20.815804 },
    { lat: 48.623898, lng: 20.816957 },
    { lat: 48.623841, lng: 20.818207 },
    { lat: 48.62409, lng: 20.819563 },
    { lat: 48.624112, lng: 20.820885 },
    { lat: 48.623981, lng: 20.821531 },
    { lat: 48.623939, lng: 20.822389 },
    { lat: 48.624004, lng: 20.823019 },
    { lat: 48.623234, lng: 20.826037 },
    { lat: 48.622789, lng: 20.826532 },
    { lat: 48.620148, lng: 20.831789 },
    { lat: 48.620024, lng: 20.832035 },
    { lat: 48.620018, lng: 20.832047 },
    { lat: 48.620515, lng: 20.831833 },
    { lat: 48.620869, lng: 20.831759 },
    { lat: 48.621499, lng: 20.831798 },
    { lat: 48.621684, lng: 20.831763 },
    { lat: 48.622156, lng: 20.831713 },
    { lat: 48.622954, lng: 20.831784 },
    { lat: 48.623446, lng: 20.831664 },
    { lat: 48.623866, lng: 20.83151 },
    { lat: 48.623968, lng: 20.831427 },
    { lat: 48.624066, lng: 20.83139 },
    { lat: 48.624119, lng: 20.831232 },
    { lat: 48.624255, lng: 20.831173 },
    { lat: 48.624649, lng: 20.831246 },
    { lat: 48.624794, lng: 20.831375 },
    { lat: 48.62494, lng: 20.831412 },
    { lat: 48.625411, lng: 20.831228 },
    { lat: 48.62561, lng: 20.831173 },
    { lat: 48.625972, lng: 20.831148 },
    { lat: 48.626152, lng: 20.831049 },
    { lat: 48.626301, lng: 20.830881 },
    { lat: 48.626428, lng: 20.830881 },
    { lat: 48.626516, lng: 20.830855 },
    { lat: 48.62664, lng: 20.83089 },
    { lat: 48.626687, lng: 20.830958 },
    { lat: 48.626725, lng: 20.831112 },
    { lat: 48.6268592, lng: 20.8310787 },
    { lat: 48.627226, lng: 20.831148 },
    { lat: 48.627376, lng: 20.831034 },
    { lat: 48.627605, lng: 20.830925 },
    { lat: 48.627841, lng: 20.83089 },
    { lat: 48.628009, lng: 20.830923 },
    { lat: 48.6280935, lng: 20.8308993 },
    { lat: 48.628453, lng: 20.830462 },
    { lat: 48.628601, lng: 20.830605 },
    { lat: 48.628717, lng: 20.830625 },
    { lat: 48.628854, lng: 20.830576 },
    { lat: 48.628991, lng: 20.830613 },
    { lat: 48.629077, lng: 20.830548 },
    { lat: 48.6297883, lng: 20.8305298 },
    { lat: 48.630177, lng: 20.830445 },
    { lat: 48.630332, lng: 20.830237 },
    { lat: 48.63039, lng: 20.830216 },
    { lat: 48.630736, lng: 20.830231 },
    { lat: 48.631032, lng: 20.830165 },
    { lat: 48.631256, lng: 20.829626 },
    { lat: 48.631593, lng: 20.829012 },
    { lat: 48.631875, lng: 20.828815 },
    { lat: 48.632086, lng: 20.828511 },
    { lat: 48.632379, lng: 20.828426 },
    { lat: 48.6327, lng: 20.828489 },
    { lat: 48.632945, lng: 20.828424 },
    { lat: 48.633308, lng: 20.828072 },
    { lat: 48.633746, lng: 20.827265 },
    { lat: 48.6338, lng: 20.82699 },
    { lat: 48.633908, lng: 20.826691 },
    { lat: 48.634179, lng: 20.82635 },
    { lat: 48.634486, lng: 20.825781 },
    { lat: 48.634504, lng: 20.825496 },
    { lat: 48.634691, lng: 20.824917 },
    { lat: 48.634702, lng: 20.824789 },
    { lat: 48.634835, lng: 20.824563 },
    { lat: 48.634942, lng: 20.824529 },
    { lat: 48.6350356, lng: 20.8244458 },
    { lat: 48.635095, lng: 20.824393 },
    { lat: 48.635213, lng: 20.824054 },
    { lat: 48.6353201, lng: 20.823762 },
    { lat: 48.635807, lng: 20.82281 },
    { lat: 48.636097, lng: 20.822576 },
    { lat: 48.636021, lng: 20.822356 },
    { lat: 48.636095, lng: 20.821939 },
    { lat: 48.636307, lng: 20.821392 },
    { lat: 48.636435, lng: 20.821104 },
    { lat: 48.636639, lng: 20.820439 },
    { lat: 48.6366765, lng: 20.8200534 },
    { lat: 48.63683, lng: 20.819964 },
    { lat: 48.637197, lng: 20.819749 },
    { lat: 48.637469, lng: 20.819327 },
    { lat: 48.637747, lng: 20.818946 },
    { lat: 48.637906, lng: 20.818206 },
    { lat: 48.637845, lng: 20.817924 },
    { lat: 48.637958, lng: 20.817185 },
    { lat: 48.637898, lng: 20.816794 },
    { lat: 48.638009, lng: 20.816218 },
    { lat: 48.638314, lng: 20.815813 },
    { lat: 48.638682, lng: 20.815777 },
    { lat: 48.638802, lng: 20.815259 },
    { lat: 48.638854, lng: 20.81485 },
    { lat: 48.63925, lng: 20.814078 },
    { lat: 48.639301, lng: 20.813905 },
    { lat: 48.639404, lng: 20.813806 },
    { lat: 48.639544, lng: 20.813413 },
    { lat: 48.63987, lng: 20.813103 },
    { lat: 48.640127, lng: 20.8126 },
    { lat: 48.640305, lng: 20.812364 },
    { lat: 48.64041, lng: 20.812297 },
    { lat: 48.640549, lng: 20.812237 },
    { lat: 48.640653, lng: 20.812327 },
    { lat: 48.640796, lng: 20.812241 },
    { lat: 48.64092, lng: 20.81228 },
    { lat: 48.641039, lng: 20.812342 },
    { lat: 48.641237, lng: 20.812242 },
    { lat: 48.641438, lng: 20.812209 },
    { lat: 48.641637, lng: 20.811946 },
    { lat: 48.641819, lng: 20.811651 },
    { lat: 48.642134, lng: 20.811407 },
    { lat: 48.642382, lng: 20.811316 },
    { lat: 48.64259, lng: 20.81144 },
    { lat: 48.642635, lng: 20.811433 },
    { lat: 48.642793, lng: 20.811198 },
    { lat: 48.642969, lng: 20.811087 },
    { lat: 48.642972, lng: 20.810979 },
    { lat: 48.643146, lng: 20.810854 },
    { lat: 48.643297, lng: 20.81094 },
    { lat: 48.643632, lng: 20.810913 },
    { lat: 48.643697, lng: 20.810833 },
    { lat: 48.643806, lng: 20.810988 },
    { lat: 48.644198, lng: 20.810826 },
    { lat: 48.644311, lng: 20.810896 },
    { lat: 48.644673, lng: 20.810997 },
    { lat: 48.644845, lng: 20.810961 },
    { lat: 48.644984, lng: 20.811061 },
    { lat: 48.64507, lng: 20.810988 },
    { lat: 48.645242, lng: 20.810949 },
    { lat: 48.645257, lng: 20.81083 },
    { lat: 48.64542, lng: 20.810909 },
    { lat: 48.645709, lng: 20.810856 },
    { lat: 48.645954, lng: 20.810854 },
    { lat: 48.646591, lng: 20.810672 },
    { lat: 48.646719, lng: 20.810522 },
    { lat: 48.646984, lng: 20.810498 },
    { lat: 48.646995, lng: 20.810321 },
    { lat: 48.647335, lng: 20.809909 },
    { lat: 48.647383, lng: 20.809752 },
    { lat: 48.647517, lng: 20.809565 },
    { lat: 48.647609, lng: 20.80944 },
    { lat: 48.64773, lng: 20.809296 },
    { lat: 48.647864, lng: 20.809279 },
    { lat: 48.648037, lng: 20.809139 },
    { lat: 48.648116, lng: 20.808883 },
    { lat: 48.648272, lng: 20.808818 },
    { lat: 48.648395, lng: 20.808586 },
    { lat: 48.648424, lng: 20.80828 },
    { lat: 48.648479, lng: 20.808123 },
    { lat: 48.648626, lng: 20.808024 },
    { lat: 48.648768, lng: 20.807777 },
    { lat: 48.648964, lng: 20.807704 },
    { lat: 48.649052, lng: 20.807548 },
    { lat: 48.649063, lng: 20.807327 },
    { lat: 48.649147, lng: 20.807067 },
    { lat: 48.649286, lng: 20.806977 },
    { lat: 48.649398, lng: 20.806804 },
    { lat: 48.649444, lng: 20.806808 },
    { lat: 48.650253, lng: 20.805874 },
    { lat: 48.650628, lng: 20.805502 },
    { lat: 48.650938, lng: 20.804881 },
    { lat: 48.651106, lng: 20.804795 },
    { lat: 48.651255, lng: 20.804794 },
    { lat: 48.651318, lng: 20.804724 },
    { lat: 48.65132, lng: 20.804672 },
    { lat: 48.651518, lng: 20.80443 },
    { lat: 48.651645, lng: 20.804184 },
    { lat: 48.651844, lng: 20.804058 },
    { lat: 48.652028, lng: 20.803877 },
    { lat: 48.652137, lng: 20.803947 },
    { lat: 48.652288, lng: 20.803764 },
    { lat: 48.652292, lng: 20.803654 },
    { lat: 48.652383, lng: 20.803572 },
    { lat: 48.65249, lng: 20.803618 },
    { lat: 48.652577, lng: 20.803732 },
    { lat: 48.652711, lng: 20.803667 },
    { lat: 48.652816, lng: 20.803505 },
    { lat: 48.652983, lng: 20.803436 },
    { lat: 48.653059, lng: 20.803273 },
    { lat: 48.653368, lng: 20.803169 },
    { lat: 48.653448, lng: 20.803086 },
    { lat: 48.653639, lng: 20.803031 },
    { lat: 48.653709, lng: 20.802991 },
    { lat: 48.653773, lng: 20.80286 },
    { lat: 48.65391, lng: 20.802801 },
    { lat: 48.654098, lng: 20.802925 },
    { lat: 48.654212, lng: 20.802864 },
    { lat: 48.654282, lng: 20.802758 },
    { lat: 48.654413, lng: 20.802722 },
    { lat: 48.654497, lng: 20.802583 },
    { lat: 48.654594, lng: 20.802506 },
    { lat: 48.654717, lng: 20.802516 },
    { lat: 48.654827, lng: 20.802264 },
    { lat: 48.654911, lng: 20.802151 },
    { lat: 48.65505, lng: 20.802081 },
    { lat: 48.655179, lng: 20.802105 },
    { lat: 48.655306, lng: 20.801971 },
    { lat: 48.655572, lng: 20.801866 },
    { lat: 48.655771, lng: 20.801886 },
    { lat: 48.655941, lng: 20.802099 },
    { lat: 48.656467, lng: 20.802068 },
    { lat: 48.656585, lng: 20.802 },
    { lat: 48.656746, lng: 20.801849 },
    { lat: 48.657008, lng: 20.801742 },
    { lat: 48.657176, lng: 20.801779 },
    { lat: 48.657504, lng: 20.801658 },
    { lat: 48.657574, lng: 20.801584 },
    { lat: 48.657657, lng: 20.801609 },
    { lat: 48.65775, lng: 20.80156 },
    { lat: 48.657897, lng: 20.801581 },
    { lat: 48.658154, lng: 20.801484 },
    { lat: 48.658283, lng: 20.801399 },
    { lat: 48.658336, lng: 20.801249 },
    { lat: 48.658539, lng: 20.801166 },
    { lat: 48.658862, lng: 20.801191 },
    { lat: 48.658965, lng: 20.801089 },
    { lat: 48.659086, lng: 20.801127 },
    { lat: 48.659437, lng: 20.800891 },
    { lat: 48.659496, lng: 20.800805 },
    { lat: 48.659707, lng: 20.800621 },
    { lat: 48.659782, lng: 20.800517 },
    { lat: 48.660009, lng: 20.800359 },
    { lat: 48.660196, lng: 20.800188 },
    { lat: 48.660406, lng: 20.800137 },
    { lat: 48.660991, lng: 20.799654 },
    { lat: 48.66112, lng: 20.799312 },
    { lat: 48.661725, lng: 20.798823 },
    { lat: 48.661794, lng: 20.798614 },
    { lat: 48.661857, lng: 20.798498 },
    { lat: 48.661848, lng: 20.798361 },
    { lat: 48.662003, lng: 20.798055 },
    { lat: 48.662001, lng: 20.797864 },
    { lat: 48.662056, lng: 20.797483 },
    { lat: 48.66223, lng: 20.796803 },
    { lat: 48.662163, lng: 20.796474 },
    { lat: 48.662167, lng: 20.79634 },
    { lat: 48.662272, lng: 20.796159 },
    { lat: 48.662286, lng: 20.795828 },
    { lat: 48.662384, lng: 20.795742 },
    { lat: 48.66244, lng: 20.795611 },
    { lat: 48.6625, lng: 20.795555 },
    { lat: 48.662433, lng: 20.794875 },
    { lat: 48.662506, lng: 20.794823 },
    { lat: 48.662505, lng: 20.794639 },
    { lat: 48.662439, lng: 20.794616 },
    { lat: 48.662477, lng: 20.794329 },
    { lat: 48.662578, lng: 20.793938 },
    { lat: 48.662477, lng: 20.793775 },
    { lat: 48.662587, lng: 20.793528 },
    { lat: 48.662603, lng: 20.793051 },
    { lat: 48.662504, lng: 20.792894 },
    { lat: 48.662454, lng: 20.792332 },
    { lat: 48.662444, lng: 20.791983 },
    { lat: 48.662342, lng: 20.791721 },
    { lat: 48.66231, lng: 20.791336 },
    { lat: 48.662219, lng: 20.791073 },
    { lat: 48.66222, lng: 20.790789 },
    { lat: 48.66217, lng: 20.790703 },
    { lat: 48.662187, lng: 20.790413 },
    { lat: 48.662144, lng: 20.790209 },
    { lat: 48.662128, lng: 20.789786 },
    { lat: 48.662146, lng: 20.789782 },
    { lat: 48.663448, lng: 20.789431 },
    { lat: 48.664431, lng: 20.788976 },
    { lat: 48.665481, lng: 20.788411 },
    { lat: 48.665496, lng: 20.788402 },
    { lat: 48.665309, lng: 20.787851 },
    { lat: 48.665229, lng: 20.787561 },
    { lat: 48.664953, lng: 20.786514 },
    { lat: 48.664605, lng: 20.785549 },
    { lat: 48.664502, lng: 20.784829 },
    { lat: 48.664504, lng: 20.784583 },
    { lat: 48.664438, lng: 20.784372 },
    { lat: 48.664305, lng: 20.78406 },
    { lat: 48.664265, lng: 20.783771 },
    { lat: 48.664249, lng: 20.783351 },
    { lat: 48.664284, lng: 20.782942 },
    { lat: 48.664276, lng: 20.782441 },
    { lat: 48.664162, lng: 20.781861 },
    { lat: 48.664121, lng: 20.781333 },
    { lat: 48.663986, lng: 20.780891 },
    { lat: 48.663904, lng: 20.78054 },
    { lat: 48.663877, lng: 20.780204 },
    { lat: 48.663907, lng: 20.779821 },
    { lat: 48.663889, lng: 20.779549 },
    { lat: 48.663816, lng: 20.779198 },
    { lat: 48.663781, lng: 20.778896 },
    { lat: 48.663834, lng: 20.778624 },
    { lat: 48.663932, lng: 20.778234 },
    { lat: 48.664004, lng: 20.777707 },
    { lat: 48.664151, lng: 20.775984 },
    { lat: 48.664283, lng: 20.775298 },
    { lat: 48.6643, lng: 20.77487 },
    { lat: 48.664277, lng: 20.774517 },
    { lat: 48.66429, lng: 20.774341 },
    { lat: 48.664451, lng: 20.773291 },
    { lat: 48.664596, lng: 20.773007 },
    { lat: 48.664697, lng: 20.772744 },
    { lat: 48.664744, lng: 20.772456 },
    { lat: 48.664752, lng: 20.77203 },
    { lat: 48.665025, lng: 20.77128 },
    { lat: 48.665184, lng: 20.769797 },
    { lat: 48.665344, lng: 20.769492 },
    { lat: 48.665445, lng: 20.768722 },
    { lat: 48.66544, lng: 20.768333 },
    { lat: 48.665448, lng: 20.76803 },
    { lat: 48.665524, lng: 20.766923 },
    { lat: 48.665493, lng: 20.766436 },
    { lat: 48.665491, lng: 20.765722 },
    { lat: 48.665527, lng: 20.765476 },
    { lat: 48.665477, lng: 20.764879 },
    { lat: 48.665218, lng: 20.764102 },
    { lat: 48.665046, lng: 20.76383 },
    { lat: 48.664616, lng: 20.762292 },
    { lat: 48.664429, lng: 20.761652 },
    { lat: 48.664343, lng: 20.761224 },
    { lat: 48.664354, lng: 20.760936 },
    { lat: 48.664386, lng: 20.760706 },
    { lat: 48.664708, lng: 20.75913 },
    { lat: 48.664726, lng: 20.758866 },
    { lat: 48.664722, lng: 20.758481 },
    { lat: 48.664775, lng: 20.758132 },
    { lat: 48.665038, lng: 20.757345 },
    { lat: 48.665149, lng: 20.757153 },
    { lat: 48.665515, lng: 20.756959 },
    { lat: 48.665648, lng: 20.756767 },
    { lat: 48.66589, lng: 20.756615 },
    { lat: 48.666122, lng: 20.756404 },
    { lat: 48.666147, lng: 20.756291 },
    { lat: 48.666123, lng: 20.755822 },
    { lat: 48.666272, lng: 20.755174 },
    { lat: 48.666373, lng: 20.754511 },
    { lat: 48.666553, lng: 20.753895 },
    { lat: 48.66655, lng: 20.753868 },
    { lat: 48.666545, lng: 20.753813 },
    { lat: 48.666802, lng: 20.753317 },
    { lat: 48.667192, lng: 20.752729 },
    { lat: 48.667332, lng: 20.752436 },
    { lat: 48.667523, lng: 20.751929 },
    { lat: 48.667656, lng: 20.751333 },
    { lat: 48.667896, lng: 20.750634 },
    { lat: 48.667914, lng: 20.75041 },
    { lat: 48.668577, lng: 20.747961 },
    { lat: 48.668597, lng: 20.747891 },
    { lat: 48.669505, lng: 20.744536 },
    { lat: 48.669511, lng: 20.74431 },
    { lat: 48.669629, lng: 20.743906 },
    { lat: 48.669784, lng: 20.742565 },
    { lat: 48.669773, lng: 20.741961 },
    { lat: 48.669735, lng: 20.741546 },
    { lat: 48.669672, lng: 20.741129 },
    { lat: 48.66969, lng: 20.740665 },
    { lat: 48.669758, lng: 20.740254 },
    { lat: 48.669892, lng: 20.73994 },
    { lat: 48.670109, lng: 20.739267 },
    { lat: 48.670363, lng: 20.738553 },
    { lat: 48.670413, lng: 20.738127 },
    { lat: 48.6699719, lng: 20.7382869 },
    { lat: 48.6693596, lng: 20.73807 },
    { lat: 48.6674624, lng: 20.7384098 },
    { lat: 48.6666994, lng: 20.7389262 },
    { lat: 48.665946, lng: 20.739268 },
    { lat: 48.6648743, lng: 20.7403806 },
    { lat: 48.6638996, lng: 20.7417282 },
    { lat: 48.6634369, lng: 20.7428072 },
    { lat: 48.6632686, lng: 20.7437435 },
    { lat: 48.6630725, lng: 20.7443401 },
    { lat: 48.6626063, lng: 20.7451081 },
    { lat: 48.6619407, lng: 20.7456696 },
    { lat: 48.6604986, lng: 20.7463716 },
    { lat: 48.6589141, lng: 20.7457536 },
    { lat: 48.658735, lng: 20.7458104 },
    { lat: 48.6580584, lng: 20.7456186 },
    { lat: 48.6570699, lng: 20.7455903 },
    { lat: 48.6563552, lng: 20.745498 },
    { lat: 48.655866, lng: 20.7456667 },
    { lat: 48.6554527, lng: 20.745695 },
    { lat: 48.6546231, lng: 20.7459826 },
    { lat: 48.6544911, lng: 20.7453114 },
    { lat: 48.6544694, lng: 20.7449521 },
    { lat: 48.6541347, lng: 20.7438938 },
    { lat: 48.6535397, lng: 20.7442861 },
    { lat: 48.6526478, lng: 20.7446952 },
    { lat: 48.6514797, lng: 20.7451187 },
    { lat: 48.6483909, lng: 20.7466888 },
    { lat: 48.6456674, lng: 20.7477319 },
    { lat: 48.6445502, lng: 20.7483256 },
    { lat: 48.6437466, lng: 20.7485587 },
    { lat: 48.6426783, lng: 20.7485532 },
    { lat: 48.6414087, lng: 20.7484358 },
    { lat: 48.6406704, lng: 20.7482507 },
    { lat: 48.6393909, lng: 20.7486456 },
    { lat: 48.6393318, lng: 20.7477103 },
    { lat: 48.6392369, lng: 20.7471587 },
    { lat: 48.6389926, lng: 20.7464893 },
    { lat: 48.6389854, lng: 20.745585 },
    { lat: 48.6388379, lng: 20.7453299 },
    { lat: 48.6387048, lng: 20.744848 },
    { lat: 48.638051, lng: 20.7445985 },
    { lat: 48.6376739, lng: 20.7457152 },
    { lat: 48.637178, lng: 20.7466557 },
    { lat: 48.63674, lng: 20.747226 },
    { lat: 48.6367459, lng: 20.7475857 },
    { lat: 48.6366169, lng: 20.7480389 },
    { lat: 48.6365385, lng: 20.7485663 },
    { lat: 48.6356102, lng: 20.7487061 },
    { lat: 48.6330865, lng: 20.7475639 },
    { lat: 48.6320327, lng: 20.7473114 },
    { lat: 48.6297926, lng: 20.7479547 },
    { lat: 48.629874, lng: 20.7481542 },
    { lat: 48.6294857, lng: 20.7483481 },
    { lat: 48.6251288, lng: 20.7492048 },
    { lat: 48.62384, lng: 20.7490819 },
    { lat: 48.6221843, lng: 20.7485208 },
    { lat: 48.6206958, lng: 20.7484165 },
    { lat: 48.6205323, lng: 20.7484752 },
    { lat: 48.6204741, lng: 20.7495317 },
    { lat: 48.6200856, lng: 20.7510599 },
    { lat: 48.6197168, lng: 20.7521847 },
    { lat: 48.6191664, lng: 20.7532071 },
    { lat: 48.6191081, lng: 20.7545201 },
    { lat: 48.6192208, lng: 20.7553889 },
    { lat: 48.6192478, lng: 20.7567605 },
    { lat: 48.6191865, lng: 20.7579574 },
    { lat: 48.6190984, lng: 20.7584242 },
    { lat: 48.6188971, lng: 20.7589103 },
    { lat: 48.618064, lng: 20.760505 },
    { lat: 48.6183129, lng: 20.7612735 },
    { lat: 48.6185502, lng: 20.7616815 },
    { lat: 48.6186865, lng: 20.7621349 },
    { lat: 48.6189399, lng: 20.7642117 },
    { lat: 48.6190338, lng: 20.7646073 },
    { lat: 48.6192845, lng: 20.7652995 },
    { lat: 48.6197738, lng: 20.7661534 },
    { lat: 48.6199166, lng: 20.7667586 },
    { lat: 48.6202464, lng: 20.7687685 },
    { lat: 48.6202991, lng: 20.7696556 },
    { lat: 48.6202482, lng: 20.7707374 },
    { lat: 48.6206574, lng: 20.7734093 },
    { lat: 48.6206841, lng: 20.7759612 },
    { lat: 48.6208862, lng: 20.7767513 },
    { lat: 48.6208151, lng: 20.7780259 },
    { lat: 48.6208543, lng: 20.7787931 },
    { lat: 48.6209868, lng: 20.779455 },
    { lat: 48.6210996, lng: 20.780875 },
    { lat: 48.6212672, lng: 20.7818935 },
    { lat: 48.6217326, lng: 20.7833874 },
    { lat: 48.6222402, lng: 20.7838013 },
    { lat: 48.6227103, lng: 20.7848434 },
    { lat: 48.6230654, lng: 20.7854712 },
    { lat: 48.623394, lng: 20.7863314 },
    { lat: 48.6236545, lng: 20.7872163 },
    { lat: 48.6239461, lng: 20.7878574 },
    { lat: 48.6240975, lng: 20.7885143 },
    { lat: 48.6240994, lng: 20.789371 },
    { lat: 48.6243224, lng: 20.7903716 },
    { lat: 48.6245337, lng: 20.7920055 },
    { lat: 48.6247244, lng: 20.7926284 },
    { lat: 48.6247801, lng: 20.793447 },
    { lat: 48.6245471, lng: 20.7948306 },
    { lat: 48.624295, lng: 20.795999 },
  ],
  Slavoška: [
    { lat: 48.7398899, lng: 20.324974 },
    { lat: 48.7398882, lng: 20.3250227 },
    { lat: 48.7398895, lng: 20.3252855 },
    { lat: 48.7387687, lng: 20.326141 },
    { lat: 48.7383259, lng: 20.3263994 },
    { lat: 48.7383345, lng: 20.3266431 },
    { lat: 48.737853, lng: 20.3268037 },
    { lat: 48.7376399, lng: 20.3263617 },
    { lat: 48.7375226, lng: 20.3262173 },
    { lat: 48.7370807, lng: 20.3262306 },
    { lat: 48.7363401, lng: 20.3263857 },
    { lat: 48.7353383, lng: 20.3263105 },
    { lat: 48.7352839, lng: 20.3261558 },
    { lat: 48.7349918, lng: 20.3265211 },
    { lat: 48.7348163, lng: 20.3270733 },
    { lat: 48.7345386, lng: 20.3272871 },
    { lat: 48.7345635, lng: 20.3276662 },
    { lat: 48.7346239, lng: 20.3278599 },
    { lat: 48.7338717, lng: 20.3282375 },
    { lat: 48.7334192, lng: 20.3283302 },
    { lat: 48.732852, lng: 20.3286308 },
    { lat: 48.7326391, lng: 20.3286825 },
    { lat: 48.7325316, lng: 20.3286418 },
    { lat: 48.7323827, lng: 20.3283155 },
    { lat: 48.7322001, lng: 20.3283424 },
    { lat: 48.7315854, lng: 20.3277967 },
    { lat: 48.7313271, lng: 20.3279014 },
    { lat: 48.7309738, lng: 20.3281672 },
    { lat: 48.7307737, lng: 20.328495 },
    { lat: 48.7305441, lng: 20.3286713 },
    { lat: 48.7305044, lng: 20.3288389 },
    { lat: 48.7302741, lng: 20.3289957 },
    { lat: 48.730267, lng: 20.3290748 },
    { lat: 48.7297794, lng: 20.3291039 },
    { lat: 48.7296479, lng: 20.328939 },
    { lat: 48.7294638, lng: 20.3290096 },
    { lat: 48.7293596, lng: 20.329254 },
    { lat: 48.729227, lng: 20.329377 },
    { lat: 48.7291213, lng: 20.3293526 },
    { lat: 48.7290271, lng: 20.3292172 },
    { lat: 48.7283507, lng: 20.3299494 },
    { lat: 48.728282, lng: 20.330208 },
    { lat: 48.72799, lng: 20.3303456 },
    { lat: 48.7278863, lng: 20.3303199 },
    { lat: 48.727668, lng: 20.3304346 },
    { lat: 48.7272787, lng: 20.3303015 },
    { lat: 48.7271481, lng: 20.3302143 },
    { lat: 48.7271629, lng: 20.3300183 },
    { lat: 48.727101, lng: 20.3299307 },
    { lat: 48.726984, lng: 20.3299022 },
    { lat: 48.7267766, lng: 20.3299888 },
    { lat: 48.7262916, lng: 20.3299674 },
    { lat: 48.726011, lng: 20.3297858 },
    { lat: 48.7259234, lng: 20.32979 },
    { lat: 48.7256962, lng: 20.3295677 },
    { lat: 48.7254425, lng: 20.3294768 },
    { lat: 48.7252645, lng: 20.3293293 },
    { lat: 48.7248676, lng: 20.3292259 },
    { lat: 48.7243102, lng: 20.329401 },
    { lat: 48.7242378, lng: 20.3296264 },
    { lat: 48.7240479, lng: 20.3298085 },
    { lat: 48.7235396, lng: 20.3298273 },
    { lat: 48.7226071, lng: 20.3301135 },
    { lat: 48.7217373, lng: 20.329872 },
    { lat: 48.7215171, lng: 20.3299743 },
    { lat: 48.7210589, lng: 20.3299455 },
    { lat: 48.7205514, lng: 20.3300987 },
    { lat: 48.7200413, lng: 20.3305927 },
    { lat: 48.7198613, lng: 20.3310007 },
    { lat: 48.7196125, lng: 20.3311194 },
    { lat: 48.7192109, lng: 20.3315385 },
    { lat: 48.7192376, lng: 20.3316713 },
    { lat: 48.719245, lng: 20.3316878 },
    { lat: 48.7193998, lng: 20.33199 },
    { lat: 48.7195411, lng: 20.3320299 },
    { lat: 48.7196751, lng: 20.3323204 },
    { lat: 48.719848, lng: 20.3330596 },
    { lat: 48.720179, lng: 20.3339311 },
    { lat: 48.7207358, lng: 20.334637 },
    { lat: 48.7211874, lng: 20.3366643 },
    { lat: 48.7213701, lng: 20.3377462 },
    { lat: 48.7213288, lng: 20.3377805 },
    { lat: 48.7213954, lng: 20.3379883 },
    { lat: 48.722109, lng: 20.3387916 },
    { lat: 48.7227269, lng: 20.3399119 },
    { lat: 48.7227785, lng: 20.3398798 },
    { lat: 48.7237804, lng: 20.3409711 },
    { lat: 48.7244784, lng: 20.3424165 },
    { lat: 48.7264647, lng: 20.3447624 },
    { lat: 48.727428, lng: 20.3454738 },
    { lat: 48.7283672, lng: 20.3463868 },
    { lat: 48.7291624, lng: 20.3471599 },
    { lat: 48.7305657, lng: 20.3482116 },
    { lat: 48.7314102, lng: 20.3485563 },
    { lat: 48.7326615, lng: 20.3494946 },
    { lat: 48.733933, lng: 20.3500815 },
    { lat: 48.7371458, lng: 20.3508377 },
    { lat: 48.7377184, lng: 20.3498362 },
    { lat: 48.7381479, lng: 20.3490879 },
    { lat: 48.7392882, lng: 20.3509658 },
    { lat: 48.7395365, lng: 20.3508069 },
    { lat: 48.7399597, lng: 20.350799 },
    { lat: 48.7400525, lng: 20.3505803 },
    { lat: 48.7417742, lng: 20.350375 },
    { lat: 48.7419578, lng: 20.3506684 },
    { lat: 48.7431939, lng: 20.3501943 },
    { lat: 48.7438546, lng: 20.3501569 },
    { lat: 48.7440627, lng: 20.3502055 },
    { lat: 48.7441259, lng: 20.3496322 },
    { lat: 48.7439625, lng: 20.3496853 },
    { lat: 48.7441404, lng: 20.348497 },
    { lat: 48.7443011, lng: 20.3484543 },
    { lat: 48.7448375, lng: 20.3479732 },
    { lat: 48.7452746, lng: 20.3472822 },
    { lat: 48.7456511, lng: 20.3470305 },
    { lat: 48.745938, lng: 20.3471085 },
    { lat: 48.7473488, lng: 20.3444026 },
    { lat: 48.7476019, lng: 20.3442488 },
    { lat: 48.7480023, lng: 20.3441213 },
    { lat: 48.7486393, lng: 20.3434164 },
    { lat: 48.7495036, lng: 20.34262 },
    { lat: 48.7498925, lng: 20.3414939 },
    { lat: 48.7493318, lng: 20.3397009 },
    { lat: 48.7495056, lng: 20.3394171 },
    { lat: 48.7495166, lng: 20.3393844 },
    { lat: 48.7498207, lng: 20.3385156 },
    { lat: 48.7493121, lng: 20.3371038 },
    { lat: 48.7484354, lng: 20.3364216 },
    { lat: 48.7479225, lng: 20.3357996 },
    { lat: 48.7475567, lng: 20.3352579 },
    { lat: 48.7473245, lng: 20.3346434 },
    { lat: 48.7473998, lng: 20.3339723 },
    { lat: 48.7471768, lng: 20.333013 },
    { lat: 48.7470986, lng: 20.3319612 },
    { lat: 48.7471202, lng: 20.3309798 },
    { lat: 48.7462316, lng: 20.3285219 },
    { lat: 48.7460833, lng: 20.327718 },
    { lat: 48.7460395, lng: 20.3271991 },
    { lat: 48.745784, lng: 20.3269696 },
    { lat: 48.745427, lng: 20.3264815 },
    { lat: 48.7448719, lng: 20.3262251 },
    { lat: 48.7448575, lng: 20.3261783 },
    { lat: 48.7445654, lng: 20.3257525 },
    { lat: 48.7444789, lng: 20.3257317 },
    { lat: 48.7438222, lng: 20.3249449 },
    { lat: 48.7437276, lng: 20.324715 },
    { lat: 48.743274, lng: 20.3246894 },
    { lat: 48.7430165, lng: 20.3245839 },
    { lat: 48.7427744, lng: 20.3241551 },
    { lat: 48.7427544, lng: 20.3241562 },
    { lat: 48.7422232, lng: 20.3239609 },
    { lat: 48.7421685, lng: 20.3237456 },
    { lat: 48.7415778, lng: 20.3243063 },
    { lat: 48.7405112, lng: 20.3249986 },
    { lat: 48.7402978, lng: 20.3250394 },
    { lat: 48.7398899, lng: 20.324974 },
  ],
  Jovice: [
    { lat: 48.6378113, lng: 20.5439439 },
    { lat: 48.6377363, lng: 20.5438638 },
    { lat: 48.6365934, lng: 20.5426504 },
    { lat: 48.636234, lng: 20.5423372 },
    { lat: 48.6358391, lng: 20.5417747 },
    { lat: 48.6355576, lng: 20.5415885 },
    { lat: 48.6352896, lng: 20.5411975 },
    { lat: 48.6343238, lng: 20.5402344 },
    { lat: 48.6342998, lng: 20.5400392 },
    { lat: 48.6343758, lng: 20.5399644 },
    { lat: 48.6343145, lng: 20.5396806 },
    { lat: 48.6341406, lng: 20.5396369 },
    { lat: 48.6340742, lng: 20.539425 },
    { lat: 48.6341872, lng: 20.5384657 },
    { lat: 48.6340817, lng: 20.5381724 },
    { lat: 48.6340753, lng: 20.5376963 },
    { lat: 48.6338416, lng: 20.5376812 },
    { lat: 48.6338001, lng: 20.5378068 },
    { lat: 48.6337415, lng: 20.5378047 },
    { lat: 48.6336766, lng: 20.5376023 },
    { lat: 48.6334938, lng: 20.5373504 },
    { lat: 48.6334964, lng: 20.5372737 },
    { lat: 48.6333748, lng: 20.5372092 },
    { lat: 48.6334794, lng: 20.5369056 },
    { lat: 48.6334511, lng: 20.5367788 },
    { lat: 48.6333672, lng: 20.5367311 },
    { lat: 48.633152, lng: 20.5368194 },
    { lat: 48.6328041, lng: 20.5363965 },
    { lat: 48.6327011, lng: 20.5361713 },
    { lat: 48.632572, lng: 20.5360619 },
    { lat: 48.632115, lng: 20.5360067 },
    { lat: 48.6315081, lng: 20.5356117 },
    { lat: 48.6310472, lng: 20.5354197 },
    { lat: 48.6309821, lng: 20.5352743 },
    { lat: 48.6310306, lng: 20.535094 },
    { lat: 48.6310413, lng: 20.5347467 },
    { lat: 48.6310046, lng: 20.5346356 },
    { lat: 48.6310361, lng: 20.5344229 },
    { lat: 48.630854, lng: 20.5340689 },
    { lat: 48.6307755, lng: 20.5340453 },
    { lat: 48.6307711, lng: 20.5339602 },
    { lat: 48.630262, lng: 20.5338029 },
    { lat: 48.6297234, lng: 20.5337603 },
    { lat: 48.6296307, lng: 20.5338116 },
    { lat: 48.6279721, lng: 20.5337503 },
    { lat: 48.6277043, lng: 20.5337901 },
    { lat: 48.6267225, lng: 20.5335159 },
    { lat: 48.6265301, lng: 20.5335183 },
    { lat: 48.62576, lng: 20.5332462 },
    { lat: 48.624774, lng: 20.5330767 },
    { lat: 48.6230381, lng: 20.5335046 },
    { lat: 48.6229912, lng: 20.533126 },
    { lat: 48.6228231, lng: 20.5327259 },
    { lat: 48.6223979, lng: 20.5310704 },
    { lat: 48.6218837, lng: 20.529968 },
    { lat: 48.62126, lng: 20.5284283 },
    { lat: 48.6213136, lng: 20.5275415 },
    { lat: 48.6210761, lng: 20.5263061 },
    { lat: 48.6209527, lng: 20.52595 },
    { lat: 48.62059, lng: 20.5251971 },
    { lat: 48.6206081, lng: 20.5245059 },
    { lat: 48.6204921, lng: 20.5242926 },
    { lat: 48.6203668, lng: 20.5229281 },
    { lat: 48.6199701, lng: 20.5219831 },
    { lat: 48.6171521, lng: 20.5106677 },
    { lat: 48.6150906, lng: 20.5072471 },
    { lat: 48.6107561, lng: 20.5024561 },
    { lat: 48.6097606, lng: 20.502989 },
    { lat: 48.6090269, lng: 20.5029726 },
    { lat: 48.605224, lng: 20.5059034 },
    { lat: 48.6054779, lng: 20.5071494 },
    { lat: 48.6056655, lng: 20.508971 },
    { lat: 48.6056458, lng: 20.5090485 },
    { lat: 48.6049827, lng: 20.511636 },
    { lat: 48.6042367, lng: 20.5168269 },
    { lat: 48.6038719, lng: 20.5179433 },
    { lat: 48.6033429, lng: 20.5191804 },
    { lat: 48.6026742, lng: 20.5203298 },
    { lat: 48.6015568, lng: 20.5236646 },
    { lat: 48.6002393, lng: 20.5270983 },
    { lat: 48.600669, lng: 20.5294731 },
    { lat: 48.6011235, lng: 20.5364719 },
    { lat: 48.60184, lng: 20.5368495 },
    { lat: 48.6028988, lng: 20.5376144 },
    { lat: 48.6038035, lng: 20.5379008 },
    { lat: 48.6053799, lng: 20.5393225 },
    { lat: 48.606963, lng: 20.5399937 },
    { lat: 48.6073567, lng: 20.5402813 },
    { lat: 48.6083522, lng: 20.5414944 },
    { lat: 48.6094252, lng: 20.5440398 },
    { lat: 48.6127848, lng: 20.5453083 },
    { lat: 48.6159316, lng: 20.546911 },
    { lat: 48.6177116, lng: 20.5480247 },
    { lat: 48.6190987, lng: 20.5490535 },
    { lat: 48.6229297, lng: 20.5511986 },
    { lat: 48.6235539, lng: 20.5518751 },
    { lat: 48.6242513, lng: 20.5524922 },
    { lat: 48.6246054, lng: 20.5526 },
    { lat: 48.6249284, lng: 20.5528653 },
    { lat: 48.6248546, lng: 20.5532499 },
    { lat: 48.624846, lng: 20.5539283 },
    { lat: 48.6247765, lng: 20.5541786 },
    { lat: 48.6247496, lng: 20.5547016 },
    { lat: 48.6249793, lng: 20.5546659 },
    { lat: 48.6251122, lng: 20.5553999 },
    { lat: 48.6248728, lng: 20.5558045 },
    { lat: 48.6247268, lng: 20.556424 },
    { lat: 48.6247909, lng: 20.5569357 },
    { lat: 48.6248604, lng: 20.5571452 },
    { lat: 48.6248338, lng: 20.5572727 },
    { lat: 48.6246813, lng: 20.5575076 },
    { lat: 48.6258967, lng: 20.5581263 },
    { lat: 48.6258644, lng: 20.5583493 },
    { lat: 48.6269278, lng: 20.5590317 },
    { lat: 48.6277697, lng: 20.5564992 },
    { lat: 48.6279295, lng: 20.5561939 },
    { lat: 48.6285545, lng: 20.5569837 },
    { lat: 48.6296484, lng: 20.5580376 },
    { lat: 48.6309222, lng: 20.5588981 },
    { lat: 48.6315402, lng: 20.5593832 },
    { lat: 48.6334795, lng: 20.5605487 },
    { lat: 48.633443, lng: 20.5607796 },
    { lat: 48.6335043, lng: 20.5611286 },
    { lat: 48.6334618, lng: 20.5612863 },
    { lat: 48.633537, lng: 20.561375 },
    { lat: 48.6335942, lng: 20.5616713 },
    { lat: 48.6337038, lng: 20.5619241 },
    { lat: 48.633809, lng: 20.5618765 },
    { lat: 48.6339279, lng: 20.561985 },
    { lat: 48.6339549, lng: 20.5620778 },
    { lat: 48.6338564, lng: 20.562305 },
    { lat: 48.6338487, lng: 20.5627089 },
    { lat: 48.6339188, lng: 20.5628696 },
    { lat: 48.6339651, lng: 20.562781 },
    { lat: 48.6341475, lng: 20.5630248 },
    { lat: 48.6352636, lng: 20.5611442 },
    { lat: 48.6370768, lng: 20.5620054 },
    { lat: 48.6372052, lng: 20.5617657 },
    { lat: 48.6375479, lng: 20.5625093 },
    { lat: 48.6379487, lng: 20.5636041 },
    { lat: 48.6390122, lng: 20.5611514 },
    { lat: 48.6400716, lng: 20.5627978 },
    { lat: 48.6401134, lng: 20.5627352 },
    { lat: 48.6412237, lng: 20.5643567 },
    { lat: 48.6420066, lng: 20.5657996 },
    { lat: 48.6420089, lng: 20.5660408 },
    { lat: 48.6420738, lng: 20.566182 },
    { lat: 48.6420968, lng: 20.5660448 },
    { lat: 48.642424, lng: 20.5641492 },
    { lat: 48.6446849, lng: 20.5660681 },
    { lat: 48.6445293, lng: 20.5661802 },
    { lat: 48.6446549, lng: 20.5664445 },
    { lat: 48.6450594, lng: 20.5666946 },
    { lat: 48.645273, lng: 20.5666524 },
    { lat: 48.6453724, lng: 20.5665394 },
    { lat: 48.6456186, lng: 20.5665294 },
    { lat: 48.6460864, lng: 20.5668802 },
    { lat: 48.646184, lng: 20.5672359 },
    { lat: 48.6462667, lng: 20.5672517 },
    { lat: 48.6464443, lng: 20.5674537 },
    { lat: 48.6464383, lng: 20.5675396 },
    { lat: 48.6466781, lng: 20.5679969 },
    { lat: 48.6467345, lng: 20.5679968 },
    { lat: 48.6467487, lng: 20.5681391 },
    { lat: 48.6468475, lng: 20.5682515 },
    { lat: 48.6468692, lng: 20.568354 },
    { lat: 48.6473023, lng: 20.5687363 },
    { lat: 48.6474102, lng: 20.5691032 },
    { lat: 48.6476135, lng: 20.5690883 },
    { lat: 48.6477341, lng: 20.5691515 },
    { lat: 48.6478334, lng: 20.5690947 },
    { lat: 48.6480147, lng: 20.569281 },
    { lat: 48.6481149, lng: 20.5695535 },
    { lat: 48.6481701, lng: 20.5695553 },
    { lat: 48.6482362, lng: 20.5697285 },
    { lat: 48.6483601, lng: 20.5696718 },
    { lat: 48.6484256, lng: 20.5698301 },
    { lat: 48.6484137, lng: 20.5699593 },
    { lat: 48.6486943, lng: 20.5701792 },
    { lat: 48.6488926, lng: 20.5700614 },
    { lat: 48.6489961, lng: 20.5701542 },
    { lat: 48.6490395, lng: 20.570539 },
    { lat: 48.6492105, lng: 20.5706254 },
    { lat: 48.6494654, lng: 20.5704238 },
    { lat: 48.6497049, lng: 20.5705654 },
    { lat: 48.6497379, lng: 20.5712755 },
    { lat: 48.6499392, lng: 20.5714861 },
    { lat: 48.6504312, lng: 20.5716367 },
    { lat: 48.6505816, lng: 20.5712566 },
    { lat: 48.6509172, lng: 20.5714765 },
    { lat: 48.65139, lng: 20.5713467 },
    { lat: 48.6517514, lng: 20.5715631 },
    { lat: 48.6517467, lng: 20.5711925 },
    { lat: 48.6513117, lng: 20.5686182 },
    { lat: 48.6511042, lng: 20.5612012 },
    { lat: 48.6509937, lng: 20.5610763 },
    { lat: 48.6508237, lng: 20.5611049 },
    { lat: 48.6505407, lng: 20.5608549 },
    { lat: 48.6504563, lng: 20.5608552 },
    { lat: 48.6499032, lng: 20.5605094 },
    { lat: 48.6497075, lng: 20.5605462 },
    { lat: 48.649408, lng: 20.5602093 },
    { lat: 48.6489906, lng: 20.5598859 },
    { lat: 48.6489003, lng: 20.5596065 },
    { lat: 48.6480576, lng: 20.5585548 },
    { lat: 48.6479557, lng: 20.5583583 },
    { lat: 48.6475414, lng: 20.5579944 },
    { lat: 48.6470688, lng: 20.557122 },
    { lat: 48.6470566, lng: 20.556825 },
    { lat: 48.6472243, lng: 20.5567265 },
    { lat: 48.6471409, lng: 20.5563917 },
    { lat: 48.6472588, lng: 20.5564736 },
    { lat: 48.6474018, lng: 20.5561108 },
    { lat: 48.6473339, lng: 20.5559521 },
    { lat: 48.6472198, lng: 20.5559101 },
    { lat: 48.6471962, lng: 20.5556358 },
    { lat: 48.6471126, lng: 20.5554762 },
    { lat: 48.6468011, lng: 20.5553974 },
    { lat: 48.6467203, lng: 20.5552543 },
    { lat: 48.6465484, lng: 20.5553197 },
    { lat: 48.6465123, lng: 20.5552298 },
    { lat: 48.6463985, lng: 20.5552291 },
    { lat: 48.6463515, lng: 20.5550825 },
    { lat: 48.6463957, lng: 20.5549363 },
    { lat: 48.646325, lng: 20.5547283 },
    { lat: 48.6463491, lng: 20.5546864 },
    { lat: 48.64626, lng: 20.5545604 },
    { lat: 48.6462919, lng: 20.5544149 },
    { lat: 48.6461915, lng: 20.5543219 },
    { lat: 48.6461209, lng: 20.5544222 },
    { lat: 48.6460676, lng: 20.5543797 },
    { lat: 48.6459936, lng: 20.554178 },
    { lat: 48.6460644, lng: 20.5541072 },
    { lat: 48.6459207, lng: 20.553941 },
    { lat: 48.6459684, lng: 20.553859 },
    { lat: 48.6459332, lng: 20.5537316 },
    { lat: 48.6459796, lng: 20.5535248 },
    { lat: 48.6459015, lng: 20.5534227 },
    { lat: 48.6458938, lng: 20.5531514 },
    { lat: 48.6457308, lng: 20.5528539 },
    { lat: 48.6454968, lng: 20.5528299 },
    { lat: 48.6455017, lng: 20.552446 },
    { lat: 48.6452387, lng: 20.5523087 },
    { lat: 48.6452713, lng: 20.5520293 },
    { lat: 48.6453469, lng: 20.5518862 },
    { lat: 48.6453299, lng: 20.5513938 },
    { lat: 48.6452938, lng: 20.5513943 },
    { lat: 48.6452813, lng: 20.5511778 },
    { lat: 48.645342, lng: 20.55078 },
    { lat: 48.6452826, lng: 20.5505126 },
    { lat: 48.6451937, lng: 20.5503613 },
    { lat: 48.6452045, lng: 20.5502265 },
    { lat: 48.6450346, lng: 20.5498069 },
    { lat: 48.6450104, lng: 20.549523 },
    { lat: 48.6448215, lng: 20.5492516 },
    { lat: 48.6447734, lng: 20.5490481 },
    { lat: 48.6446671, lng: 20.5489432 },
    { lat: 48.6446472, lng: 20.5488017 },
    { lat: 48.6447849, lng: 20.5487534 },
    { lat: 48.6448278, lng: 20.5486017 },
    { lat: 48.644531, lng: 20.5483408 },
    { lat: 48.6445211, lng: 20.5482587 },
    { lat: 48.6445913, lng: 20.5480826 },
    { lat: 48.6444941, lng: 20.5480125 },
    { lat: 48.6444472, lng: 20.5480584 },
    { lat: 48.644293, lng: 20.547725 },
    { lat: 48.644174, lng: 20.5478252 },
    { lat: 48.6441755, lng: 20.5479286 },
    { lat: 48.6441112, lng: 20.5479515 },
    { lat: 48.6440708, lng: 20.5478018 },
    { lat: 48.6440439, lng: 20.5478397 },
    { lat: 48.6440125, lng: 20.5477516 },
    { lat: 48.6439666, lng: 20.5477601 },
    { lat: 48.6439429, lng: 20.5478774 },
    { lat: 48.6437326, lng: 20.5479137 },
    { lat: 48.643693, lng: 20.5479997 },
    { lat: 48.6436273, lng: 20.5479568 },
    { lat: 48.6436042, lng: 20.5480346 },
    { lat: 48.6434615, lng: 20.5478643 },
    { lat: 48.6434883, lng: 20.5477919 },
    { lat: 48.643414, lng: 20.5477035 },
    { lat: 48.6433306, lng: 20.5478523 },
    { lat: 48.6431885, lng: 20.5477236 },
    { lat: 48.6429603, lng: 20.547734 },
    { lat: 48.6429234, lng: 20.5474975 },
    { lat: 48.6428022, lng: 20.5475437 },
    { lat: 48.6427491, lng: 20.5476881 },
    { lat: 48.6426774, lng: 20.547697 },
    { lat: 48.6425426, lng: 20.5475863 },
    { lat: 48.642533, lng: 20.5476576 },
    { lat: 48.6424498, lng: 20.5476596 },
    { lat: 48.6423205, lng: 20.5474815 },
    { lat: 48.642271, lng: 20.5474829 },
    { lat: 48.6422885, lng: 20.5473827 },
    { lat: 48.6422321, lng: 20.5473501 },
    { lat: 48.6421553, lng: 20.5476151 },
    { lat: 48.6420145, lng: 20.547522 },
    { lat: 48.6420612, lng: 20.5476291 },
    { lat: 48.6420168, lng: 20.5477037 },
    { lat: 48.6420854, lng: 20.5477979 },
    { lat: 48.6420363, lng: 20.5479869 },
    { lat: 48.641955, lng: 20.5480155 },
    { lat: 48.6418763, lng: 20.5478651 },
    { lat: 48.6417683, lng: 20.5478365 },
    { lat: 48.6417039, lng: 20.5479516 },
    { lat: 48.6416341, lng: 20.5479194 },
    { lat: 48.6416618, lng: 20.5477885 },
    { lat: 48.6415359, lng: 20.54764 },
    { lat: 48.6414262, lng: 20.547843 },
    { lat: 48.6414976, lng: 20.547917 },
    { lat: 48.6414868, lng: 20.547987 },
    { lat: 48.6413975, lng: 20.5480672 },
    { lat: 48.6413775, lng: 20.5481632 },
    { lat: 48.6412969, lng: 20.5482679 },
    { lat: 48.6411708, lng: 20.5483031 },
    { lat: 48.6411333, lng: 20.5484751 },
    { lat: 48.6409622, lng: 20.5484749 },
    { lat: 48.6408846, lng: 20.5484192 },
    { lat: 48.6407779, lng: 20.5480935 },
    { lat: 48.6408554, lng: 20.5479353 },
    { lat: 48.6408283, lng: 20.547777 },
    { lat: 48.6407872, lng: 20.5477325 },
    { lat: 48.640695, lng: 20.5477979 },
    { lat: 48.640644, lng: 20.5479436 },
    { lat: 48.6405596, lng: 20.5479122 },
    { lat: 48.6406165, lng: 20.5476532 },
    { lat: 48.6405365, lng: 20.5476215 },
    { lat: 48.640539, lng: 20.5473575 },
    { lat: 48.6404308, lng: 20.5472328 },
    { lat: 48.6404562, lng: 20.5469768 },
    { lat: 48.6404015, lng: 20.5467479 },
    { lat: 48.6404141, lng: 20.5466614 },
    { lat: 48.6405255, lng: 20.5465993 },
    { lat: 48.6404774, lng: 20.5464848 },
    { lat: 48.640402, lng: 20.5464887 },
    { lat: 48.6403688, lng: 20.5464156 },
    { lat: 48.6401777, lng: 20.5464517 },
    { lat: 48.6400928, lng: 20.5463144 },
    { lat: 48.6399394, lng: 20.5462814 },
    { lat: 48.639862, lng: 20.5463445 },
    { lat: 48.6398005, lng: 20.5461187 },
    { lat: 48.6397555, lng: 20.5461182 },
    { lat: 48.639716, lng: 20.54627 },
    { lat: 48.6395191, lng: 20.546151 },
    { lat: 48.6394359, lng: 20.5462428 },
    { lat: 48.6393397, lng: 20.5462327 },
    { lat: 48.6390652, lng: 20.5459939 },
    { lat: 48.6390648, lng: 20.5458629 },
    { lat: 48.6387914, lng: 20.5458387 },
    { lat: 48.6385891, lng: 20.5454795 },
    { lat: 48.6387228, lng: 20.5453828 },
    { lat: 48.6383136, lng: 20.5445551 },
    { lat: 48.6379797, lng: 20.5442031 },
    { lat: 48.6378113, lng: 20.5439439 },
  ],
  Brzotín: [
    { lat: 48.602577, lng: 20.4639554 },
    { lat: 48.6028511, lng: 20.466048 },
    { lat: 48.6037755, lng: 20.4731904 },
    { lat: 48.6041317, lng: 20.4753799 },
    { lat: 48.603693, lng: 20.4766094 },
    { lat: 48.6043823, lng: 20.4775133 },
    { lat: 48.6048236, lng: 20.4785261 },
    { lat: 48.6046358, lng: 20.4801713 },
    { lat: 48.6046483, lng: 20.4833129 },
    { lat: 48.60438, lng: 20.4847981 },
    { lat: 48.6038563, lng: 20.4930194 },
    { lat: 48.6051099, lng: 20.4930929 },
    { lat: 48.6054579, lng: 20.4935449 },
    { lat: 48.6066746, lng: 20.4960158 },
    { lat: 48.6035547, lng: 20.4977876 },
    { lat: 48.6018227, lng: 20.4986847 },
    { lat: 48.6001317, lng: 20.4996783 },
    { lat: 48.5992287, lng: 20.4995586 },
    { lat: 48.6029002, lng: 20.5049499 },
    { lat: 48.6042087, lng: 20.5070548 },
    { lat: 48.6049173, lng: 20.5079052 },
    { lat: 48.6056655, lng: 20.508971 },
    { lat: 48.6054779, lng: 20.5071494 },
    { lat: 48.605224, lng: 20.5059034 },
    { lat: 48.6090269, lng: 20.5029726 },
    { lat: 48.6097606, lng: 20.502989 },
    { lat: 48.6107561, lng: 20.5024561 },
    { lat: 48.6150906, lng: 20.5072471 },
    { lat: 48.6171521, lng: 20.5106677 },
    { lat: 48.6199701, lng: 20.5219831 },
    { lat: 48.6203668, lng: 20.5229281 },
    { lat: 48.6204921, lng: 20.5242926 },
    { lat: 48.6206081, lng: 20.5245059 },
    { lat: 48.62059, lng: 20.5251971 },
    { lat: 48.6209527, lng: 20.52595 },
    { lat: 48.6210761, lng: 20.5263061 },
    { lat: 48.6213136, lng: 20.5275415 },
    { lat: 48.62126, lng: 20.5284283 },
    { lat: 48.6218837, lng: 20.529968 },
    { lat: 48.6223979, lng: 20.5310704 },
    { lat: 48.6228231, lng: 20.5327259 },
    { lat: 48.6229912, lng: 20.533126 },
    { lat: 48.6230381, lng: 20.5335046 },
    { lat: 48.624774, lng: 20.5330767 },
    { lat: 48.62576, lng: 20.5332462 },
    { lat: 48.6265301, lng: 20.5335183 },
    { lat: 48.6267225, lng: 20.5335159 },
    { lat: 48.6277043, lng: 20.5337901 },
    { lat: 48.6279721, lng: 20.5337503 },
    { lat: 48.6296307, lng: 20.5338116 },
    { lat: 48.6297234, lng: 20.5337603 },
    { lat: 48.630262, lng: 20.5338029 },
    { lat: 48.6307711, lng: 20.5339602 },
    { lat: 48.6307755, lng: 20.5340453 },
    { lat: 48.630854, lng: 20.5340689 },
    { lat: 48.6310361, lng: 20.5344229 },
    { lat: 48.6310046, lng: 20.5346356 },
    { lat: 48.6310413, lng: 20.5347467 },
    { lat: 48.6310306, lng: 20.535094 },
    { lat: 48.6309821, lng: 20.5352743 },
    { lat: 48.6310472, lng: 20.5354197 },
    { lat: 48.6315081, lng: 20.5356117 },
    { lat: 48.632115, lng: 20.5360067 },
    { lat: 48.632572, lng: 20.5360619 },
    { lat: 48.6327011, lng: 20.5361713 },
    { lat: 48.6328041, lng: 20.5363965 },
    { lat: 48.633152, lng: 20.5368194 },
    { lat: 48.6333672, lng: 20.5367311 },
    { lat: 48.6334511, lng: 20.5367788 },
    { lat: 48.6334794, lng: 20.5369056 },
    { lat: 48.6333748, lng: 20.5372092 },
    { lat: 48.6334964, lng: 20.5372737 },
    { lat: 48.6334938, lng: 20.5373504 },
    { lat: 48.6336766, lng: 20.5376023 },
    { lat: 48.6337415, lng: 20.5378047 },
    { lat: 48.6338001, lng: 20.5378068 },
    { lat: 48.6338416, lng: 20.5376812 },
    { lat: 48.6340753, lng: 20.5376963 },
    { lat: 48.6340817, lng: 20.5381724 },
    { lat: 48.6341872, lng: 20.5384657 },
    { lat: 48.6340742, lng: 20.539425 },
    { lat: 48.6341406, lng: 20.5396369 },
    { lat: 48.6343145, lng: 20.5396806 },
    { lat: 48.6343758, lng: 20.5399644 },
    { lat: 48.6342998, lng: 20.5400392 },
    { lat: 48.6343238, lng: 20.5402344 },
    { lat: 48.6352896, lng: 20.5411975 },
    { lat: 48.6355576, lng: 20.5415885 },
    { lat: 48.6358391, lng: 20.5417747 },
    { lat: 48.636234, lng: 20.5423372 },
    { lat: 48.6365934, lng: 20.5426504 },
    { lat: 48.6377363, lng: 20.5438638 },
    { lat: 48.6378113, lng: 20.5439439 },
    { lat: 48.6386758, lng: 20.5401474 },
    { lat: 48.6391272, lng: 20.5403911 },
    { lat: 48.639243, lng: 20.5395059 },
    { lat: 48.6399122, lng: 20.5399402 },
    { lat: 48.6404815, lng: 20.5370524 },
    { lat: 48.6401714, lng: 20.536461 },
    { lat: 48.6402058, lng: 20.5359963 },
    { lat: 48.6405224, lng: 20.5362116 },
    { lat: 48.6405538, lng: 20.536178 },
    { lat: 48.6404862, lng: 20.5356048 },
    { lat: 48.6406892, lng: 20.5356136 },
    { lat: 48.6410183, lng: 20.5357705 },
    { lat: 48.6414123, lng: 20.5362854 },
    { lat: 48.6417547, lng: 20.5347137 },
    { lat: 48.6419588, lng: 20.5345433 },
    { lat: 48.6423917, lng: 20.534554 },
    { lat: 48.6429648, lng: 20.5343441 },
    { lat: 48.6433721, lng: 20.5338518 },
    { lat: 48.6430109, lng: 20.5329207 },
    { lat: 48.6442086, lng: 20.5291903 },
    { lat: 48.6441683, lng: 20.529167 },
    { lat: 48.6455797, lng: 20.5244135 },
    { lat: 48.6455652, lng: 20.5243218 },
    { lat: 48.6458352, lng: 20.5233493 },
    { lat: 48.6461231, lng: 20.52292 },
    { lat: 48.6464224, lng: 20.522615 },
    { lat: 48.646855, lng: 20.5219285 },
    { lat: 48.6469227, lng: 20.5217176 },
    { lat: 48.6470993, lng: 20.521771 },
    { lat: 48.6473018, lng: 20.5216483 },
    { lat: 48.6473148, lng: 20.5213955 },
    { lat: 48.647391, lng: 20.5212612 },
    { lat: 48.6477909, lng: 20.5209085 },
    { lat: 48.6479016, lng: 20.5203717 },
    { lat: 48.6482256, lng: 20.5204866 },
    { lat: 48.6480161, lng: 20.5203353 },
    { lat: 48.6481077, lng: 20.5201021 },
    { lat: 48.6484011, lng: 20.5198792 },
    { lat: 48.6489815, lng: 20.5191946 },
    { lat: 48.6490452, lng: 20.5186465 },
    { lat: 48.6490607, lng: 20.5178543 },
    { lat: 48.6490239, lng: 20.5173169 },
    { lat: 48.6486606, lng: 20.5165737 },
    { lat: 48.6484893, lng: 20.5164782 },
    { lat: 48.6485576, lng: 20.5163482 },
    { lat: 48.6485904, lng: 20.5160534 },
    { lat: 48.6491033, lng: 20.5151056 },
    { lat: 48.6491618, lng: 20.5149222 },
    { lat: 48.6493501, lng: 20.5146562 },
    { lat: 48.6496821, lng: 20.51381 },
    { lat: 48.6505416, lng: 20.5121956 },
    { lat: 48.6505597, lng: 20.5120787 },
    { lat: 48.6507494, lng: 20.5120034 },
    { lat: 48.6508465, lng: 20.5121266 },
    { lat: 48.6510044, lng: 20.5121028 },
    { lat: 48.6513099, lng: 20.5107314 },
    { lat: 48.6518307, lng: 20.5099022 },
    { lat: 48.6519883, lng: 20.5098143 },
    { lat: 48.6525913, lng: 20.5091992 },
    { lat: 48.6529238, lng: 20.5090889 },
    { lat: 48.6531762, lng: 20.5085175 },
    { lat: 48.6532463, lng: 20.5079592 },
    { lat: 48.653623, lng: 20.5075518 },
    { lat: 48.6539655, lng: 20.5069267 },
    { lat: 48.6548669, lng: 20.5046864 },
    { lat: 48.6551426, lng: 20.5043217 },
    { lat: 48.6558867, lng: 20.5030548 },
    { lat: 48.6562627, lng: 20.5026067 },
    { lat: 48.6564833, lng: 20.5018196 },
    { lat: 48.6539119, lng: 20.4998174 },
    { lat: 48.6536835, lng: 20.4995534 },
    { lat: 48.6534257, lng: 20.4994042 },
    { lat: 48.6526734, lng: 20.4991547 },
    { lat: 48.6525296, lng: 20.499286 },
    { lat: 48.6523656, lng: 20.4991155 },
    { lat: 48.6516741, lng: 20.4987213 },
    { lat: 48.6514284, lng: 20.4987188 },
    { lat: 48.651202, lng: 20.4985316 },
    { lat: 48.6508521, lng: 20.4984831 },
    { lat: 48.6507269, lng: 20.4983585 },
    { lat: 48.6505303, lng: 20.4984284 },
    { lat: 48.6505171, lng: 20.4983772 },
    { lat: 48.6504362, lng: 20.4984727 },
    { lat: 48.6503366, lng: 20.4984909 },
    { lat: 48.6499216, lng: 20.4984088 },
    { lat: 48.6497809, lng: 20.4985911 },
    { lat: 48.6496254, lng: 20.498646 },
    { lat: 48.6493224, lng: 20.4985576 },
    { lat: 48.6491202, lng: 20.4987469 },
    { lat: 48.6488758, lng: 20.4987795 },
    { lat: 48.6488178, lng: 20.498673 },
    { lat: 48.6486495, lng: 20.4986596 },
    { lat: 48.6486062, lng: 20.4986737 },
    { lat: 48.648618, lng: 20.4987488 },
    { lat: 48.6484743, lng: 20.4988181 },
    { lat: 48.6483191, lng: 20.4987623 },
    { lat: 48.6482045, lng: 20.4988903 },
    { lat: 48.6481199, lng: 20.4988886 },
    { lat: 48.6481453, lng: 20.4989673 },
    { lat: 48.6481087, lng: 20.499042 },
    { lat: 48.6477711, lng: 20.499042 },
    { lat: 48.6476641, lng: 20.4989215 },
    { lat: 48.6474424, lng: 20.4990174 },
    { lat: 48.6473067, lng: 20.4988787 },
    { lat: 48.647246, lng: 20.4989152 },
    { lat: 48.6472151, lng: 20.4988709 },
    { lat: 48.6471573, lng: 20.49892 },
    { lat: 48.6471576, lng: 20.4990168 },
    { lat: 48.6470816, lng: 20.4989165 },
    { lat: 48.6470031, lng: 20.4989819 },
    { lat: 48.6470073, lng: 20.4990606 },
    { lat: 48.646894, lng: 20.4991038 },
    { lat: 48.6468119, lng: 20.4989922 },
    { lat: 48.6467502, lng: 20.4992649 },
    { lat: 48.6466767, lng: 20.4993424 },
    { lat: 48.6440351, lng: 20.488468 },
    { lat: 48.6439309, lng: 20.4884186 },
    { lat: 48.6439181, lng: 20.488404 },
    { lat: 48.6438424, lng: 20.4884322 },
    { lat: 48.6437708, lng: 20.4882019 },
    { lat: 48.6436215, lng: 20.4880342 },
    { lat: 48.6435104, lng: 20.4880166 },
    { lat: 48.6433616, lng: 20.488133 },
    { lat: 48.6431216, lng: 20.4880994 },
    { lat: 48.6428864, lng: 20.4878315 },
    { lat: 48.6427031, lng: 20.48787 },
    { lat: 48.642548, lng: 20.4876974 },
    { lat: 48.6425206, lng: 20.4875983 },
    { lat: 48.6424576, lng: 20.4875977 },
    { lat: 48.642409, lng: 20.4877717 },
    { lat: 48.6419283, lng: 20.4879207 },
    { lat: 48.6419084, lng: 20.4877248 },
    { lat: 48.6415097, lng: 20.4879584 },
    { lat: 48.6413671, lng: 20.4883013 },
    { lat: 48.6410262, lng: 20.4888169 },
    { lat: 48.6409008, lng: 20.4889273 },
    { lat: 48.640792, lng: 20.4889064 },
    { lat: 48.6407291, lng: 20.4888224 },
    { lat: 48.6405671, lng: 20.4888573 },
    { lat: 48.6405373, lng: 20.4891439 },
    { lat: 48.6402918, lng: 20.4887192 },
    { lat: 48.6398105, lng: 20.4882559 },
    { lat: 48.6381937, lng: 20.4876026 },
    { lat: 48.6361345, lng: 20.4866249 },
    { lat: 48.6345341, lng: 20.485327 },
    { lat: 48.6345036, lng: 20.4852082 },
    { lat: 48.6339612, lng: 20.4846435 },
    { lat: 48.633688, lng: 20.4844764 },
    { lat: 48.6333391, lng: 20.4841539 },
    { lat: 48.6329101, lng: 20.4841213 },
    { lat: 48.6326493, lng: 20.4842825 },
    { lat: 48.6325053, lng: 20.4841976 },
    { lat: 48.6321342, lng: 20.4842426 },
    { lat: 48.6318856, lng: 20.4841279 },
    { lat: 48.6316833, lng: 20.4841994 },
    { lat: 48.630799, lng: 20.4847842 },
    { lat: 48.6305188, lng: 20.4847858 },
    { lat: 48.6293824, lng: 20.4842158 },
    { lat: 48.62922, lng: 20.4838503 },
    { lat: 48.6293298, lng: 20.4829072 },
    { lat: 48.6286936, lng: 20.4828176 },
    { lat: 48.6285588, lng: 20.4827398 },
    { lat: 48.6268794, lng: 20.4825417 },
    { lat: 48.6263774, lng: 20.4824257 },
    { lat: 48.6266921, lng: 20.4816665 },
    { lat: 48.6290943, lng: 20.4772285 },
    { lat: 48.630873, lng: 20.4736372 },
    { lat: 48.6310612, lng: 20.4733473 },
    { lat: 48.6286832, lng: 20.4724844 },
    { lat: 48.6277044, lng: 20.4722372 },
    { lat: 48.6276988, lng: 20.4720597 },
    { lat: 48.6279043, lng: 20.4716832 },
    { lat: 48.6278953, lng: 20.4713889 },
    { lat: 48.6280335, lng: 20.4708431 },
    { lat: 48.6282107, lng: 20.4705567 },
    { lat: 48.6282229, lng: 20.4700726 },
    { lat: 48.6296024, lng: 20.4676032 },
    { lat: 48.629847, lng: 20.4669599 },
    { lat: 48.6302031, lng: 20.4663686 },
    { lat: 48.6303746, lng: 20.4664112 },
    { lat: 48.6305872, lng: 20.4660746 },
    { lat: 48.6306911, lng: 20.4658446 },
    { lat: 48.6309201, lng: 20.4650236 },
    { lat: 48.6309288, lng: 20.4648673 },
    { lat: 48.6300884, lng: 20.4629805 },
    { lat: 48.6286382, lng: 20.4594163 },
    { lat: 48.6279578, lng: 20.4578628 },
    { lat: 48.6255178, lng: 20.4516607 },
    { lat: 48.625466, lng: 20.4513327 },
    { lat: 48.6254426, lng: 20.4513376 },
    { lat: 48.6249482, lng: 20.4514867 },
    { lat: 48.62393, lng: 20.4515668 },
    { lat: 48.6231586, lng: 20.4512416 },
    { lat: 48.6214342, lng: 20.4497757 },
    { lat: 48.6212807, lng: 20.4495612 },
    { lat: 48.6203574, lng: 20.4490882 },
    { lat: 48.620195, lng: 20.4491375 },
    { lat: 48.6200373, lng: 20.4492533 },
    { lat: 48.6198756, lng: 20.4497093 },
    { lat: 48.6194509, lng: 20.4505069 },
    { lat: 48.6190918, lng: 20.4513717 },
    { lat: 48.6187945, lng: 20.4518447 },
    { lat: 48.6183336, lng: 20.4522529 },
    { lat: 48.6179345, lng: 20.4529777 },
    { lat: 48.6178284, lng: 20.4530047 },
    { lat: 48.617436, lng: 20.4528428 },
    { lat: 48.6171175, lng: 20.4529928 },
    { lat: 48.6165903, lng: 20.4530788 },
    { lat: 48.6157708, lng: 20.4533204 },
    { lat: 48.6155238, lng: 20.4533086 },
    { lat: 48.6150818, lng: 20.4537681 },
    { lat: 48.6148065, lng: 20.4541669 },
    { lat: 48.6143163, lng: 20.4545455 },
    { lat: 48.6138098, lng: 20.4558276 },
    { lat: 48.6131552, lng: 20.4564694 },
    { lat: 48.6124121, lng: 20.456947 },
    { lat: 48.6122193, lng: 20.4572056 },
    { lat: 48.6117527, lng: 20.4575511 },
    { lat: 48.6117127, lng: 20.4577478 },
    { lat: 48.6117744, lng: 20.4583912 },
    { lat: 48.6117215, lng: 20.4586345 },
    { lat: 48.6116428, lng: 20.458874 },
    { lat: 48.6113231, lng: 20.4594705 },
    { lat: 48.6110053, lng: 20.45961 },
    { lat: 48.6105815, lng: 20.4595398 },
    { lat: 48.6103096, lng: 20.4592885 },
    { lat: 48.6098567, lng: 20.4591069 },
    { lat: 48.6087908, lng: 20.4589372 },
    { lat: 48.6084308, lng: 20.4587384 },
    { lat: 48.6072069, lng: 20.4592445 },
    { lat: 48.6066901, lng: 20.4600829 },
    { lat: 48.6064734, lng: 20.4603043 },
    { lat: 48.6055324, lng: 20.4602861 },
    { lat: 48.6050974, lng: 20.4605684 },
    { lat: 48.6047455, lng: 20.4624922 },
    { lat: 48.604607, lng: 20.4629656 },
    { lat: 48.6043014, lng: 20.4634872 },
    { lat: 48.6034821, lng: 20.4637971 },
    { lat: 48.602577, lng: 20.4639554 },
  ],
  Rožňava: [
    { lat: 48.6511042, lng: 20.5612012 },
    { lat: 48.6512007, lng: 20.5612255 },
    { lat: 48.6514042, lng: 20.5613711 },
    { lat: 48.6516626, lng: 20.5612315 },
    { lat: 48.6517664, lng: 20.5614485 },
    { lat: 48.6518697, lng: 20.5614156 },
    { lat: 48.6520428, lng: 20.5615527 },
    { lat: 48.6523891, lng: 20.5614804 },
    { lat: 48.6526275, lng: 20.561293 },
    { lat: 48.6528371, lng: 20.561327 },
    { lat: 48.6529619, lng: 20.5611975 },
    { lat: 48.6531758, lng: 20.5613227 },
    { lat: 48.6534273, lng: 20.5612409 },
    { lat: 48.6535245, lng: 20.5613998 },
    { lat: 48.653711, lng: 20.5613007 },
    { lat: 48.6537697, lng: 20.5610618 },
    { lat: 48.6542791, lng: 20.5605859 },
    { lat: 48.6544235, lng: 20.5605926 },
    { lat: 48.6545606, lng: 20.5604963 },
    { lat: 48.6546712, lng: 20.5605005 },
    { lat: 48.6549846, lng: 20.5607262 },
    { lat: 48.6553887, lng: 20.5606366 },
    { lat: 48.6555881, lng: 20.5607267 },
    { lat: 48.6559938, lng: 20.5607342 },
    { lat: 48.6562034, lng: 20.5608322 },
    { lat: 48.6563578, lng: 20.5607793 },
    { lat: 48.6571245, lng: 20.5612938 },
    { lat: 48.6573916, lng: 20.5617845 },
    { lat: 48.6576205, lng: 20.5620095 },
    { lat: 48.6579457, lng: 20.5624893 },
    { lat: 48.6583473, lng: 20.5626631 },
    { lat: 48.6581841, lng: 20.5626972 },
    { lat: 48.6585403, lng: 20.5632245 },
    { lat: 48.658658, lng: 20.5632997 },
    { lat: 48.6587527, lng: 20.5635784 },
    { lat: 48.659471, lng: 20.5643977 },
    { lat: 48.6596533, lng: 20.564461 },
    { lat: 48.6597865, lng: 20.5647123 },
    { lat: 48.6599489, lng: 20.5647517 },
    { lat: 48.6602386, lng: 20.5651853 },
    { lat: 48.6603709, lng: 20.5652607 },
    { lat: 48.660625, lng: 20.5655884 },
    { lat: 48.6610783, lng: 20.5665222 },
    { lat: 48.6618851, lng: 20.5673625 },
    { lat: 48.6622771, lng: 20.5682207 },
    { lat: 48.6627562, lng: 20.5688788 },
    { lat: 48.6643812, lng: 20.5689776 },
    { lat: 48.6647271, lng: 20.5690721 },
    { lat: 48.6651687, lng: 20.5679525 },
    { lat: 48.6653791, lng: 20.5677332 },
    { lat: 48.6657281, lng: 20.5677673 },
    { lat: 48.6662513, lng: 20.5671444 },
    { lat: 48.6666337, lng: 20.5670097 },
    { lat: 48.6670282, lng: 20.5667799 },
    { lat: 48.6673467, lng: 20.5669005 },
    { lat: 48.6674247, lng: 20.5671216 },
    { lat: 48.6681404, lng: 20.5665944 },
    { lat: 48.6695471, lng: 20.5667221 },
    { lat: 48.6715883, lng: 20.5663242 },
    { lat: 48.6719876, lng: 20.5661335 },
    { lat: 48.6736192, lng: 20.5662583 },
    { lat: 48.673892, lng: 20.5666615 },
    { lat: 48.6740775, lng: 20.5670794 },
    { lat: 48.6741947, lng: 20.5686702 },
    { lat: 48.6743575, lng: 20.5688431 },
    { lat: 48.6751115, lng: 20.5687063 },
    { lat: 48.6762207, lng: 20.5687484 },
    { lat: 48.6767087, lng: 20.568635 },
    { lat: 48.676886, lng: 20.5683764 },
    { lat: 48.6775707, lng: 20.5691999 },
    { lat: 48.677721, lng: 20.5699483 },
    { lat: 48.6779266, lng: 20.5704896 },
    { lat: 48.6781912, lng: 20.5709141 },
    { lat: 48.6785819, lng: 20.5711895 },
    { lat: 48.6790829, lng: 20.5712831 },
    { lat: 48.6797492, lng: 20.5717048 },
    { lat: 48.6799738, lng: 20.5719293 },
    { lat: 48.6801734, lng: 20.5725823 },
    { lat: 48.6806281, lng: 20.5733383 },
    { lat: 48.6815685, lng: 20.5741718 },
    { lat: 48.6831182, lng: 20.5746134 },
    { lat: 48.6837619, lng: 20.5752442 },
    { lat: 48.68427, lng: 20.5754399 },
    { lat: 48.6848205, lng: 20.575776 },
    { lat: 48.6856475, lng: 20.5771357 },
    { lat: 48.6871808, lng: 20.579238 },
    { lat: 48.6884344, lng: 20.5796816 },
    { lat: 48.6883764, lng: 20.5801255 },
    { lat: 48.6893625, lng: 20.5833615 },
    { lat: 48.6899126, lng: 20.5845155 },
    { lat: 48.690154, lng: 20.5853249 },
    { lat: 48.690298, lng: 20.5862401 },
    { lat: 48.6905906, lng: 20.5874628 },
    { lat: 48.6929229, lng: 20.5910956 },
    { lat: 48.6942294, lng: 20.590863 },
    { lat: 48.6943759, lng: 20.590933 },
    { lat: 48.6951707, lng: 20.5910215 },
    { lat: 48.6958008, lng: 20.5914064 },
    { lat: 48.696217, lng: 20.5914906 },
    { lat: 48.6964515, lng: 20.5914061 },
    { lat: 48.6970097, lng: 20.5915529 },
    { lat: 48.6973814, lng: 20.5914345 },
    { lat: 48.6979606, lng: 20.5926188 },
    { lat: 48.6984236, lng: 20.593695 },
    { lat: 48.6986624, lng: 20.5940371 },
    { lat: 48.6988235, lng: 20.5941345 },
    { lat: 48.6992856, lng: 20.5939312 },
    { lat: 48.6998912, lng: 20.5942968 },
    { lat: 48.7003089, lng: 20.5942943 },
    { lat: 48.7006367, lng: 20.5944825 },
    { lat: 48.7012618, lng: 20.5951046 },
    { lat: 48.7015911, lng: 20.5952114 },
    { lat: 48.7018683, lng: 20.5950959 },
    { lat: 48.7021205, lng: 20.5956487 },
    { lat: 48.7023224, lng: 20.5963611 },
    { lat: 48.7024882, lng: 20.5967397 },
    { lat: 48.7027484, lng: 20.5967454 },
    { lat: 48.7032811, lng: 20.5970298 },
    { lat: 48.7037402, lng: 20.5976331 },
    { lat: 48.7043946, lng: 20.5983151 },
    { lat: 48.7055965, lng: 20.59898 },
    { lat: 48.7061409, lng: 20.599178 },
    { lat: 48.7063525, lng: 20.5991774 },
    { lat: 48.707363, lng: 20.5999624 },
    { lat: 48.7086731, lng: 20.6023811 },
    { lat: 48.7090792, lng: 20.603268 },
    { lat: 48.7091864, lng: 20.6034238 },
    { lat: 48.7095421, lng: 20.6036671 },
    { lat: 48.7097469, lng: 20.6039769 },
    { lat: 48.7103625, lng: 20.6040635 },
    { lat: 48.7112539, lng: 20.6046066 },
    { lat: 48.7120868, lng: 20.604905 },
    { lat: 48.7126008, lng: 20.6051683 },
    { lat: 48.7132236, lng: 20.6056372 },
    { lat: 48.7140776, lng: 20.6065143 },
    { lat: 48.7145563, lng: 20.6071667 },
    { lat: 48.7154483, lng: 20.6091174 },
    { lat: 48.7159834, lng: 20.6098336 },
    { lat: 48.7160912, lng: 20.6118048 },
    { lat: 48.7163864, lng: 20.6131054 },
    { lat: 48.716502, lng: 20.6133461 },
    { lat: 48.7176726, lng: 20.6147853 },
    { lat: 48.7181578, lng: 20.6156502 },
    { lat: 48.7186123, lng: 20.6161963 },
    { lat: 48.7187927, lng: 20.6165115 },
    { lat: 48.7188248, lng: 20.6164938 },
    { lat: 48.7194929, lng: 20.617683 },
    { lat: 48.7199872, lng: 20.6180868 },
    { lat: 48.7205037, lng: 20.6189427 },
    { lat: 48.72078, lng: 20.6192667 },
    { lat: 48.7211293, lng: 20.6198926 },
    { lat: 48.7211923, lng: 20.6200732 },
    { lat: 48.7211919, lng: 20.620464 },
    { lat: 48.7212718, lng: 20.6208374 },
    { lat: 48.7213111, lng: 20.6214182 },
    { lat: 48.7212849, lng: 20.6216907 },
    { lat: 48.7214035, lng: 20.6223501 },
    { lat: 48.7215795, lng: 20.624405 },
    { lat: 48.7217482, lng: 20.6256643 },
    { lat: 48.7217879, lng: 20.6263859 },
    { lat: 48.7218634, lng: 20.6265754 },
    { lat: 48.7221244, lng: 20.6268497 },
    { lat: 48.7226495, lng: 20.6276971 },
    { lat: 48.7240552, lng: 20.6309398 },
    { lat: 48.7244258, lng: 20.6322452 },
    { lat: 48.7243941, lng: 20.6330145 },
    { lat: 48.7248802, lng: 20.63428 },
    { lat: 48.7253385, lng: 20.6345872 },
    { lat: 48.725638, lng: 20.6349942 },
    { lat: 48.7266164, lng: 20.6366722 },
    { lat: 48.7269989, lng: 20.6368844 },
    { lat: 48.7272997, lng: 20.6369187 },
    { lat: 48.7273019, lng: 20.6369189 },
    { lat: 48.727628, lng: 20.6368091 },
    { lat: 48.7282375, lng: 20.6367936 },
    { lat: 48.7288217, lng: 20.6369448 },
    { lat: 48.7294084, lng: 20.6372889 },
    { lat: 48.7297836, lng: 20.6373623 },
    { lat: 48.7300143, lng: 20.6364923 },
    { lat: 48.7301658, lng: 20.6359772 },
    { lat: 48.7306714, lng: 20.6331226 },
    { lat: 48.7307773, lng: 20.6329401 },
    { lat: 48.7314033, lng: 20.6308953 },
    { lat: 48.7317459, lng: 20.6299421 },
    { lat: 48.7321596, lng: 20.629334 },
    { lat: 48.7324732, lng: 20.6287073 },
    { lat: 48.7329934, lng: 20.6271295 },
    { lat: 48.7333247, lng: 20.6265781 },
    { lat: 48.7338854, lng: 20.6241629 },
    { lat: 48.7344336, lng: 20.623169 },
    { lat: 48.7347196, lng: 20.6228588 },
    { lat: 48.7350339, lng: 20.6228341 },
    { lat: 48.7362081, lng: 20.6212267 },
    { lat: 48.7389194, lng: 20.6163756 },
    { lat: 48.7394469, lng: 20.6152484 },
    { lat: 48.739777, lng: 20.6147001 },
    { lat: 48.7404384, lng: 20.614004 },
    { lat: 48.7406602, lng: 20.613637 },
    { lat: 48.7407994, lng: 20.6131881 },
    { lat: 48.7411218, lng: 20.6124296 },
    { lat: 48.7416555, lng: 20.6116863 },
    { lat: 48.7419335, lng: 20.6110692 },
    { lat: 48.7427589, lng: 20.6102695 },
    { lat: 48.7437506, lng: 20.6096466 },
    { lat: 48.7439246, lng: 20.6094802 },
    { lat: 48.7440809, lng: 20.6091982 },
    { lat: 48.7446793, lng: 20.6084711 },
    { lat: 48.7460198, lng: 20.6071003 },
    { lat: 48.7463123, lng: 20.6068797 },
    { lat: 48.7468664, lng: 20.6065805 },
    { lat: 48.747186, lng: 20.6064807 },
    { lat: 48.7477327, lng: 20.6064375 },
    { lat: 48.7481355, lng: 20.6062448 },
    { lat: 48.7491628, lng: 20.6061644 },
    { lat: 48.7495439, lng: 20.6062479 },
    { lat: 48.749553, lng: 20.60621 },
    { lat: 48.748533, lng: 20.604924 },
    { lat: 48.748293, lng: 20.604225 },
    { lat: 48.748141, lng: 20.603709 },
    { lat: 48.7481, lng: 20.603526 },
    { lat: 48.748042, lng: 20.603213 },
    { lat: 48.748027, lng: 20.603085 },
    { lat: 48.7478, lng: 20.60238 },
    { lat: 48.747614, lng: 20.6017 },
    { lat: 48.747677, lng: 20.601134 },
    { lat: 48.747609, lng: 20.600602 },
    { lat: 48.747876, lng: 20.598016 },
    { lat: 48.748097, lng: 20.596853 },
    { lat: 48.748274, lng: 20.593819 },
    { lat: 48.748214, lng: 20.592942 },
    { lat: 48.748556, lng: 20.590901 },
    { lat: 48.747425, lng: 20.584021 },
    { lat: 48.745906, lng: 20.577394 },
    { lat: 48.744292, lng: 20.575697 },
    { lat: 48.7397506, lng: 20.5784224 },
    { lat: 48.7373594, lng: 20.5776638 },
    { lat: 48.7330356, lng: 20.5768581 },
    { lat: 48.729427, lng: 20.5762723 },
    { lat: 48.7273471, lng: 20.5762313 },
    { lat: 48.7259603, lng: 20.5758468 },
    { lat: 48.7246886, lng: 20.575922 },
    { lat: 48.7231288, lng: 20.5767991 },
    { lat: 48.7226263, lng: 20.5767557 },
    { lat: 48.722246, lng: 20.5764297 },
    { lat: 48.7220186, lng: 20.5764862 },
    { lat: 48.7218719, lng: 20.5764511 },
    { lat: 48.7216507, lng: 20.5765564 },
    { lat: 48.7212183, lng: 20.5764943 },
    { lat: 48.7211729, lng: 20.5763954 },
    { lat: 48.7210231, lng: 20.5762978 },
    { lat: 48.7202927, lng: 20.5764287 },
    { lat: 48.7202189, lng: 20.5762887 },
    { lat: 48.7200223, lng: 20.5763042 },
    { lat: 48.71939, lng: 20.5764654 },
    { lat: 48.7191914, lng: 20.576671 },
    { lat: 48.7188878, lng: 20.5765254 },
    { lat: 48.7184735, lng: 20.5766208 },
    { lat: 48.7181257, lng: 20.5762229 },
    { lat: 48.7178642, lng: 20.5763422 },
    { lat: 48.7176866, lng: 20.576319 },
    { lat: 48.7175815, lng: 20.5761239 },
    { lat: 48.7173388, lng: 20.5760166 },
    { lat: 48.71714, lng: 20.5760244 },
    { lat: 48.7169013, lng: 20.5757831 },
    { lat: 48.7167866, lng: 20.5757899 },
    { lat: 48.7167854, lng: 20.5756356 },
    { lat: 48.7164457, lng: 20.5754549 },
    { lat: 48.7161682, lng: 20.5755324 },
    { lat: 48.7157834, lng: 20.5751419 },
    { lat: 48.7156681, lng: 20.5751291 },
    { lat: 48.7154162, lng: 20.574772 },
    { lat: 48.7151721, lng: 20.5747512 },
    { lat: 48.7150358, lng: 20.5745922 },
    { lat: 48.7149028, lng: 20.5745877 },
    { lat: 48.7146858, lng: 20.5743196 },
    { lat: 48.7145111, lng: 20.5743814 },
    { lat: 48.714241, lng: 20.5742925 },
    { lat: 48.7138157, lng: 20.5735635 },
    { lat: 48.7138585, lng: 20.5733492 },
    { lat: 48.7138045, lng: 20.5729855 },
    { lat: 48.7132628, lng: 20.5724025 },
    { lat: 48.7130405, lng: 20.571926 },
    { lat: 48.7130001, lng: 20.5717186 },
    { lat: 48.7128311, lng: 20.5714986 },
    { lat: 48.7127229, lng: 20.5709459 },
    { lat: 48.7127507, lng: 20.5705096 },
    { lat: 48.7127235, lng: 20.5703861 },
    { lat: 48.7125742, lng: 20.5701826 },
    { lat: 48.7125509, lng: 20.5693396 },
    { lat: 48.7125961, lng: 20.5690919 },
    { lat: 48.7124075, lng: 20.5687434 },
    { lat: 48.7123867, lng: 20.5684864 },
    { lat: 48.7123172, lng: 20.5683995 },
    { lat: 48.7123212, lng: 20.5682969 },
    { lat: 48.7122036, lng: 20.5682511 },
    { lat: 48.7118347, lng: 20.5671321 },
    { lat: 48.7114545, lng: 20.566958 },
    { lat: 48.7112306, lng: 20.566933 },
    { lat: 48.7111123, lng: 20.5668407 },
    { lat: 48.7107443, lng: 20.5660232 },
    { lat: 48.7104647, lng: 20.5659191 },
    { lat: 48.7100446, lng: 20.5654226 },
    { lat: 48.7098267, lng: 20.5654078 },
    { lat: 48.7097612, lng: 20.5655263 },
    { lat: 48.7096104, lng: 20.5651027 },
    { lat: 48.7094874, lng: 20.565035 },
    { lat: 48.7094178, lng: 20.5648565 },
    { lat: 48.7092359, lng: 20.564684 },
    { lat: 48.7090093, lng: 20.564553 },
    { lat: 48.7085314, lng: 20.5645112 },
    { lat: 48.708305, lng: 20.5639589 },
    { lat: 48.707967, lng: 20.5638644 },
    { lat: 48.7077427, lng: 20.5633057 },
    { lat: 48.7076708, lng: 20.5633132 },
    { lat: 48.7074633, lng: 20.563151 },
    { lat: 48.7074608, lng: 20.5632354 },
    { lat: 48.7074211, lng: 20.5632284 },
    { lat: 48.7073518, lng: 20.5629355 },
    { lat: 48.7070359, lng: 20.562858 },
    { lat: 48.7069883, lng: 20.5627915 },
    { lat: 48.7068414, lng: 20.5624673 },
    { lat: 48.7067485, lng: 20.562391 },
    { lat: 48.7067411, lng: 20.562192 },
    { lat: 48.7066286, lng: 20.5617377 },
    { lat: 48.7063793, lng: 20.5616646 },
    { lat: 48.706215, lng: 20.5615416 },
    { lat: 48.7061604, lng: 20.5614026 },
    { lat: 48.7061378, lng: 20.5611303 },
    { lat: 48.7059291, lng: 20.560877 },
    { lat: 48.7058455, lng: 20.5606844 },
    { lat: 48.7057715, lng: 20.5601741 },
    { lat: 48.7057071, lng: 20.5600156 },
    { lat: 48.7055549, lng: 20.5598866 },
    { lat: 48.7053618, lng: 20.5594768 },
    { lat: 48.7051292, lng: 20.5594569 },
    { lat: 48.7049428, lng: 20.5593226 },
    { lat: 48.7047755, lng: 20.5588184 },
    { lat: 48.7048147, lng: 20.5583859 },
    { lat: 48.7047771, lng: 20.5582176 },
    { lat: 48.7046201, lng: 20.5581111 },
    { lat: 48.7044844, lng: 20.5578249 },
    { lat: 48.7043813, lng: 20.5577159 },
    { lat: 48.7043446, lng: 20.557513 },
    { lat: 48.704257, lng: 20.5574616 },
    { lat: 48.7042722, lng: 20.5571052 },
    { lat: 48.704232, lng: 20.5569072 },
    { lat: 48.7040405, lng: 20.5564489 },
    { lat: 48.703878, lng: 20.5562523 },
    { lat: 48.7037622, lng: 20.5560112 },
    { lat: 48.7033981, lng: 20.5549953 },
    { lat: 48.7033165, lng: 20.5548956 },
    { lat: 48.7032169, lng: 20.5550591 },
    { lat: 48.7030539, lng: 20.5548244 },
    { lat: 48.7029209, lng: 20.5545152 },
    { lat: 48.7028422, lng: 20.5544277 },
    { lat: 48.702661, lng: 20.5544281 },
    { lat: 48.7024959, lng: 20.5542581 },
    { lat: 48.7023787, lng: 20.5539212 },
    { lat: 48.7018793, lng: 20.5532672 },
    { lat: 48.7016759, lng: 20.5528837 },
    { lat: 48.7015854, lng: 20.5528101 },
    { lat: 48.7015599, lng: 20.5527014 },
    { lat: 48.700866, lng: 20.551777 },
    { lat: 48.7005354, lng: 20.5514798 },
    { lat: 48.7000467, lng: 20.5507516 },
    { lat: 48.6999064, lng: 20.5504287 },
    { lat: 48.6995733, lng: 20.5499786 },
    { lat: 48.6994383, lng: 20.5495583 },
    { lat: 48.699293, lng: 20.5493104 },
    { lat: 48.6992184, lng: 20.5492711 },
    { lat: 48.6991775, lng: 20.5493262 },
    { lat: 48.6990336, lng: 20.5491791 },
    { lat: 48.6989785, lng: 20.5489079 },
    { lat: 48.6987111, lng: 20.5486467 },
    { lat: 48.6983006, lng: 20.5481122 },
    { lat: 48.6979846, lng: 20.5478478 },
    { lat: 48.6944492, lng: 20.5505253 },
    { lat: 48.6917434, lng: 20.5530405 },
    { lat: 48.6916081, lng: 20.5526752 },
    { lat: 48.6915835, lng: 20.5523416 },
    { lat: 48.6914314, lng: 20.5517319 },
    { lat: 48.6911574, lng: 20.550992 },
    { lat: 48.6906705, lng: 20.5500064 },
    { lat: 48.6899935, lng: 20.5493933 },
    { lat: 48.6894812, lng: 20.5491473 },
    { lat: 48.6891584, lng: 20.5488538 },
    { lat: 48.6889254, lng: 20.5485834 },
    { lat: 48.6885587, lng: 20.5477977 },
    { lat: 48.68811, lng: 20.5473624 },
    { lat: 48.6879893, lng: 20.547173 },
    { lat: 48.6876866, lng: 20.5469307 },
    { lat: 48.687508, lng: 20.5465816 },
    { lat: 48.6871654, lng: 20.546359 },
    { lat: 48.6864789, lng: 20.5456092 },
    { lat: 48.6860735, lng: 20.5453345 },
    { lat: 48.6859504, lng: 20.5453229 },
    { lat: 48.6853919, lng: 20.5448645 },
    { lat: 48.6853404, lng: 20.5442973 },
    { lat: 48.6849797, lng: 20.543866 },
    { lat: 48.6845683, lng: 20.5436696 },
    { lat: 48.6840447, lng: 20.5435175 },
    { lat: 48.6839041, lng: 20.5433976 },
    { lat: 48.6836805, lng: 20.5434436 },
    { lat: 48.6837606, lng: 20.5437525 },
    { lat: 48.6839902, lng: 20.5440837 },
    { lat: 48.6840429, lng: 20.5440732 },
    { lat: 48.68434, lng: 20.5446132 },
    { lat: 48.6843877, lng: 20.5448368 },
    { lat: 48.6847169, lng: 20.5451828 },
    { lat: 48.6849301, lng: 20.5455318 },
    { lat: 48.6852944, lng: 20.5463383 },
    { lat: 48.6856619, lng: 20.5468325 },
    { lat: 48.6855105, lng: 20.5470309 },
    { lat: 48.6854361, lng: 20.5468199 },
    { lat: 48.6854083, lng: 20.5465222 },
    { lat: 48.6850333, lng: 20.5459774 },
    { lat: 48.6849357, lng: 20.5456168 },
    { lat: 48.6847577, lng: 20.5455588 },
    { lat: 48.6846989, lng: 20.5453967 },
    { lat: 48.6844774, lng: 20.5451649 },
    { lat: 48.6842255, lng: 20.5449874 },
    { lat: 48.6841319, lng: 20.5450776 },
    { lat: 48.683914, lng: 20.5448769 },
    { lat: 48.6837621, lng: 20.5445171 },
    { lat: 48.6835291, lng: 20.544215 },
    { lat: 48.6832396, lng: 20.5441832 },
    { lat: 48.6831619, lng: 20.544002 },
    { lat: 48.6830676, lng: 20.5439145 },
    { lat: 48.6826907, lng: 20.5437415 },
    { lat: 48.6825544, lng: 20.5433746 },
    { lat: 48.6823629, lng: 20.5431607 },
    { lat: 48.6822444, lng: 20.5429116 },
    { lat: 48.6822617, lng: 20.5427987 },
    { lat: 48.6820282, lng: 20.542941 },
    { lat: 48.6814823, lng: 20.5428842 },
    { lat: 48.6812403, lng: 20.5427668 },
    { lat: 48.6812244, lng: 20.5424899 },
    { lat: 48.6810482, lng: 20.5423199 },
    { lat: 48.6808913, lng: 20.5424343 },
    { lat: 48.6809211, lng: 20.542264 },
    { lat: 48.6811795, lng: 20.5422542 },
    { lat: 48.6819522, lng: 20.5420189 },
    { lat: 48.6819371, lng: 20.5418534 },
    { lat: 48.6819966, lng: 20.5415733 },
    { lat: 48.6820721, lng: 20.5414543 },
    { lat: 48.6823774, lng: 20.5413693 },
    { lat: 48.6824278, lng: 20.5410561 },
    { lat: 48.6825265, lng: 20.5409573 },
    { lat: 48.6830073, lng: 20.5409557 },
    { lat: 48.6831644, lng: 20.5411591 },
    { lat: 48.683191, lng: 20.540808 },
    { lat: 48.6835746, lng: 20.5408325 },
    { lat: 48.6835371, lng: 20.5409729 },
    { lat: 48.6836745, lng: 20.540942 },
    { lat: 48.6838471, lng: 20.5407093 },
    { lat: 48.6843358, lng: 20.5404377 },
    { lat: 48.6843424, lng: 20.5406495 },
    { lat: 48.684469, lng: 20.5406584 },
    { lat: 48.6845429, lng: 20.540743 },
    { lat: 48.684733, lng: 20.5402505 },
    { lat: 48.6851846, lng: 20.5395844 },
    { lat: 48.6852711, lng: 20.5393344 },
    { lat: 48.6853693, lng: 20.5392844 },
    { lat: 48.6853436, lng: 20.5391968 },
    { lat: 48.6845625, lng: 20.5390217 },
    { lat: 48.6844905, lng: 20.5389063 },
    { lat: 48.6842734, lng: 20.5388474 },
    { lat: 48.6831856, lng: 20.53906 },
    { lat: 48.682977, lng: 20.5390474 },
    { lat: 48.6827585, lng: 20.5388364 },
    { lat: 48.6826369, lng: 20.5381686 },
    { lat: 48.6828142, lng: 20.5380617 },
    { lat: 48.6827797, lng: 20.5378904 },
    { lat: 48.6826702, lng: 20.5377942 },
    { lat: 48.6825347, lng: 20.5375391 },
    { lat: 48.682382, lng: 20.5375482 },
    { lat: 48.6818055, lng: 20.5353384 },
    { lat: 48.6818409, lng: 20.5353257 },
    { lat: 48.6817259, lng: 20.5348652 },
    { lat: 48.6816561, lng: 20.5347139 },
    { lat: 48.6824071, lng: 20.5340727 },
    { lat: 48.6829832, lng: 20.5337345 },
    { lat: 48.6830069, lng: 20.5336423 },
    { lat: 48.6837732, lng: 20.5331479 },
    { lat: 48.6861715, lng: 20.5327211 },
    { lat: 48.6875097, lng: 20.5328035 },
    { lat: 48.6878915, lng: 20.5327718 },
    { lat: 48.6886286, lng: 20.5328803 },
    { lat: 48.6894817, lng: 20.5333614 },
    { lat: 48.6909765, lng: 20.5335599 },
    { lat: 48.6913055, lng: 20.5337159 },
    { lat: 48.6921412, lng: 20.533537 },
    { lat: 48.6925553, lng: 20.533081 },
    { lat: 48.6927122, lng: 20.5329996 },
    { lat: 48.6928604, lng: 20.5330184 },
    { lat: 48.6932987, lng: 20.5334798 },
    { lat: 48.6932704, lng: 20.5335952 },
    { lat: 48.6945723, lng: 20.5343045 },
    { lat: 48.6945911, lng: 20.5342006 },
    { lat: 48.6945919, lng: 20.534167 },
    { lat: 48.6936932, lng: 20.5318878 },
    { lat: 48.6935814, lng: 20.5294856 },
    { lat: 48.6934768, lng: 20.5284623 },
    { lat: 48.6928869, lng: 20.5245978 },
    { lat: 48.6926036, lng: 20.5230043 },
    { lat: 48.6911999, lng: 20.5166143 },
    { lat: 48.691234, lng: 20.5165049 },
    { lat: 48.691162, lng: 20.5163578 },
    { lat: 48.6910211, lng: 20.514904 },
    { lat: 48.6909599, lng: 20.5135371 },
    { lat: 48.6908559, lng: 20.5124145 },
    { lat: 48.6908724, lng: 20.5120717 },
    { lat: 48.6905931, lng: 20.511949 },
    { lat: 48.6906068, lng: 20.5118594 },
    { lat: 48.6905084, lng: 20.5114633 },
    { lat: 48.6905336, lng: 20.5113799 },
    { lat: 48.6904677, lng: 20.5112769 },
    { lat: 48.6905028, lng: 20.5111602 },
    { lat: 48.6904593, lng: 20.5110762 },
    { lat: 48.6905004, lng: 20.5109451 },
    { lat: 48.6904695, lng: 20.5108377 },
    { lat: 48.6904913, lng: 20.5106694 },
    { lat: 48.6904391, lng: 20.5105359 },
    { lat: 48.6904727, lng: 20.5102932 },
    { lat: 48.6902848, lng: 20.5098744 },
    { lat: 48.6902789, lng: 20.5087249 },
    { lat: 48.6900438, lng: 20.5063843 },
    { lat: 48.6897541, lng: 20.5051999 },
    { lat: 48.6884712, lng: 20.5022112 },
    { lat: 48.688415, lng: 20.5011319 },
    { lat: 48.6875959, lng: 20.4982099 },
    { lat: 48.6874088, lng: 20.4971768 },
    { lat: 48.6869833, lng: 20.494032 },
    { lat: 48.68633, lng: 20.4921964 },
    { lat: 48.6860318, lng: 20.4914818 },
    { lat: 48.6858822, lng: 20.4902552 },
    { lat: 48.6855826, lng: 20.4892101 },
    { lat: 48.6851999, lng: 20.4880969 },
    { lat: 48.6849122, lng: 20.4862329 },
    { lat: 48.6846908, lng: 20.4841477 },
    { lat: 48.6847641, lng: 20.4828899 },
    { lat: 48.6850424, lng: 20.4814011 },
    { lat: 48.6849347, lng: 20.4805159 },
    { lat: 48.6848758, lng: 20.4797836 },
    { lat: 48.6843565, lng: 20.4801361 },
    { lat: 48.6833243, lng: 20.4814616 },
    { lat: 48.6830016, lng: 20.4815367 },
    { lat: 48.6827235, lng: 20.481716 },
    { lat: 48.68252, lng: 20.4819936 },
    { lat: 48.682147, lng: 20.482285 },
    { lat: 48.6817089, lng: 20.4827876 },
    { lat: 48.6813262, lng: 20.4836343 },
    { lat: 48.6812442, lng: 20.4836994 },
    { lat: 48.6809839, lng: 20.4837228 },
    { lat: 48.6805636, lng: 20.4840717 },
    { lat: 48.680465, lng: 20.4842355 },
    { lat: 48.6802023, lng: 20.4850052 },
    { lat: 48.6801749, lng: 20.4852061 },
    { lat: 48.6803208, lng: 20.4858224 },
    { lat: 48.6796312, lng: 20.4867656 },
    { lat: 48.6791914, lng: 20.4876438 },
    { lat: 48.678995, lng: 20.4882325 },
    { lat: 48.6784568, lng: 20.4892172 },
    { lat: 48.6779869, lng: 20.4893333 },
    { lat: 48.676652, lng: 20.4905739 },
    { lat: 48.676323, lng: 20.4907165 },
    { lat: 48.6760192, lng: 20.490962 },
    { lat: 48.6751303, lng: 20.491456 },
    { lat: 48.6749538, lng: 20.491464 },
    { lat: 48.6744706, lng: 20.4918535 },
    { lat: 48.6742698, lng: 20.4921418 },
    { lat: 48.6740561, lng: 20.4926592 },
    { lat: 48.6739052, lng: 20.4928952 },
    { lat: 48.673567, lng: 20.4932173 },
    { lat: 48.6733404, lng: 20.4935987 },
    { lat: 48.6722591, lng: 20.4945502 },
    { lat: 48.671885, lng: 20.4951109 },
    { lat: 48.6718685, lng: 20.4953022 },
    { lat: 48.6716582, lng: 20.4955182 },
    { lat: 48.6712074, lng: 20.4956843 },
    { lat: 48.670757, lng: 20.4961696 },
    { lat: 48.670802, lng: 20.4966784 },
    { lat: 48.6708071, lng: 20.4977442 },
    { lat: 48.6705961, lng: 20.4977309 },
    { lat: 48.6703039, lng: 20.4977138 },
    { lat: 48.6699768, lng: 20.4975181 },
    { lat: 48.6698306, lng: 20.4973122 },
    { lat: 48.6693676, lng: 20.4971591 },
    { lat: 48.6685831, lng: 20.4973487 },
    { lat: 48.6681372, lng: 20.4975254 },
    { lat: 48.6679746, lng: 20.4980213 },
    { lat: 48.6667408, lng: 20.4989037 },
    { lat: 48.6662785, lng: 20.4990957 },
    { lat: 48.6653957, lng: 20.4992744 },
    { lat: 48.6646909, lng: 20.4996951 },
    { lat: 48.6631937, lng: 20.4998238 },
    { lat: 48.6626553, lng: 20.4990705 },
    { lat: 48.6620618, lng: 20.4985906 },
    { lat: 48.6619496, lng: 20.4983604 },
    { lat: 48.6611426, lng: 20.4983956 },
    { lat: 48.6608737, lng: 20.4983043 },
    { lat: 48.6605769, lng: 20.4982035 },
    { lat: 48.6604818, lng: 20.4982223 },
    { lat: 48.6604819, lng: 20.4982825 },
    { lat: 48.6602286, lng: 20.498613 },
    { lat: 48.6599936, lng: 20.4988311 },
    { lat: 48.6591316, lng: 20.4991195 },
    { lat: 48.6584264, lng: 20.4995289 },
    { lat: 48.6574582, lng: 20.5004005 },
    { lat: 48.6569379, lng: 20.5007273 },
    { lat: 48.6564833, lng: 20.5018196 },
    { lat: 48.6562627, lng: 20.5026067 },
    { lat: 48.6558867, lng: 20.5030548 },
    { lat: 48.6551426, lng: 20.5043217 },
    { lat: 48.6548669, lng: 20.5046864 },
    { lat: 48.6539655, lng: 20.5069267 },
    { lat: 48.653623, lng: 20.5075518 },
    { lat: 48.6532463, lng: 20.5079592 },
    { lat: 48.6531762, lng: 20.5085175 },
    { lat: 48.6529238, lng: 20.5090889 },
    { lat: 48.6525913, lng: 20.5091992 },
    { lat: 48.6519883, lng: 20.5098143 },
    { lat: 48.6518307, lng: 20.5099022 },
    { lat: 48.6513099, lng: 20.5107314 },
    { lat: 48.6510044, lng: 20.5121028 },
    { lat: 48.6508465, lng: 20.5121266 },
    { lat: 48.6507494, lng: 20.5120034 },
    { lat: 48.6505597, lng: 20.5120787 },
    { lat: 48.6505416, lng: 20.5121956 },
    { lat: 48.6496821, lng: 20.51381 },
    { lat: 48.6493501, lng: 20.5146562 },
    { lat: 48.6491618, lng: 20.5149222 },
    { lat: 48.6491033, lng: 20.5151056 },
    { lat: 48.6485904, lng: 20.5160534 },
    { lat: 48.6485576, lng: 20.5163482 },
    { lat: 48.6484893, lng: 20.5164782 },
    { lat: 48.6486606, lng: 20.5165737 },
    { lat: 48.6490239, lng: 20.5173169 },
    { lat: 48.6490607, lng: 20.5178543 },
    { lat: 48.6490452, lng: 20.5186465 },
    { lat: 48.6489815, lng: 20.5191946 },
    { lat: 48.6484011, lng: 20.5198792 },
    { lat: 48.6481077, lng: 20.5201021 },
    { lat: 48.6480161, lng: 20.5203353 },
    { lat: 48.6482256, lng: 20.5204866 },
    { lat: 48.6479016, lng: 20.5203717 },
    { lat: 48.6477909, lng: 20.5209085 },
    { lat: 48.647391, lng: 20.5212612 },
    { lat: 48.6473148, lng: 20.5213955 },
    { lat: 48.6473018, lng: 20.5216483 },
    { lat: 48.6470993, lng: 20.521771 },
    { lat: 48.6469227, lng: 20.5217176 },
    { lat: 48.646855, lng: 20.5219285 },
    { lat: 48.6464224, lng: 20.522615 },
    { lat: 48.6461231, lng: 20.52292 },
    { lat: 48.6458352, lng: 20.5233493 },
    { lat: 48.6455652, lng: 20.5243218 },
    { lat: 48.6455797, lng: 20.5244135 },
    { lat: 48.6441683, lng: 20.529167 },
    { lat: 48.6442086, lng: 20.5291903 },
    { lat: 48.6430109, lng: 20.5329207 },
    { lat: 48.6433721, lng: 20.5338518 },
    { lat: 48.6429648, lng: 20.5343441 },
    { lat: 48.6423917, lng: 20.534554 },
    { lat: 48.6419588, lng: 20.5345433 },
    { lat: 48.6417547, lng: 20.5347137 },
    { lat: 48.6414123, lng: 20.5362854 },
    { lat: 48.6410183, lng: 20.5357705 },
    { lat: 48.6406892, lng: 20.5356136 },
    { lat: 48.6404862, lng: 20.5356048 },
    { lat: 48.6405538, lng: 20.536178 },
    { lat: 48.6405224, lng: 20.5362116 },
    { lat: 48.6402058, lng: 20.5359963 },
    { lat: 48.6401714, lng: 20.536461 },
    { lat: 48.6404815, lng: 20.5370524 },
    { lat: 48.6399122, lng: 20.5399402 },
    { lat: 48.639243, lng: 20.5395059 },
    { lat: 48.6391272, lng: 20.5403911 },
    { lat: 48.6386758, lng: 20.5401474 },
    { lat: 48.6378113, lng: 20.5439439 },
    { lat: 48.6379797, lng: 20.5442031 },
    { lat: 48.6383136, lng: 20.5445551 },
    { lat: 48.6387228, lng: 20.5453828 },
    { lat: 48.6385891, lng: 20.5454795 },
    { lat: 48.6387914, lng: 20.5458387 },
    { lat: 48.6390648, lng: 20.5458629 },
    { lat: 48.6390652, lng: 20.5459939 },
    { lat: 48.6393397, lng: 20.5462327 },
    { lat: 48.6394359, lng: 20.5462428 },
    { lat: 48.6395191, lng: 20.546151 },
    { lat: 48.639716, lng: 20.54627 },
    { lat: 48.6397555, lng: 20.5461182 },
    { lat: 48.6398005, lng: 20.5461187 },
    { lat: 48.639862, lng: 20.5463445 },
    { lat: 48.6399394, lng: 20.5462814 },
    { lat: 48.6400928, lng: 20.5463144 },
    { lat: 48.6401777, lng: 20.5464517 },
    { lat: 48.6403688, lng: 20.5464156 },
    { lat: 48.640402, lng: 20.5464887 },
    { lat: 48.6404774, lng: 20.5464848 },
    { lat: 48.6405255, lng: 20.5465993 },
    { lat: 48.6404141, lng: 20.5466614 },
    { lat: 48.6404015, lng: 20.5467479 },
    { lat: 48.6404562, lng: 20.5469768 },
    { lat: 48.6404308, lng: 20.5472328 },
    { lat: 48.640539, lng: 20.5473575 },
    { lat: 48.6405365, lng: 20.5476215 },
    { lat: 48.6406165, lng: 20.5476532 },
    { lat: 48.6405596, lng: 20.5479122 },
    { lat: 48.640644, lng: 20.5479436 },
    { lat: 48.640695, lng: 20.5477979 },
    { lat: 48.6407872, lng: 20.5477325 },
    { lat: 48.6408283, lng: 20.547777 },
    { lat: 48.6408554, lng: 20.5479353 },
    { lat: 48.6407779, lng: 20.5480935 },
    { lat: 48.6408846, lng: 20.5484192 },
    { lat: 48.6409622, lng: 20.5484749 },
    { lat: 48.6411333, lng: 20.5484751 },
    { lat: 48.6411708, lng: 20.5483031 },
    { lat: 48.6412969, lng: 20.5482679 },
    { lat: 48.6413775, lng: 20.5481632 },
    { lat: 48.6413975, lng: 20.5480672 },
    { lat: 48.6414868, lng: 20.547987 },
    { lat: 48.6414976, lng: 20.547917 },
    { lat: 48.6414262, lng: 20.547843 },
    { lat: 48.6415359, lng: 20.54764 },
    { lat: 48.6416618, lng: 20.5477885 },
    { lat: 48.6416341, lng: 20.5479194 },
    { lat: 48.6417039, lng: 20.5479516 },
    { lat: 48.6417683, lng: 20.5478365 },
    { lat: 48.6418763, lng: 20.5478651 },
    { lat: 48.641955, lng: 20.5480155 },
    { lat: 48.6420363, lng: 20.5479869 },
    { lat: 48.6420854, lng: 20.5477979 },
    { lat: 48.6420168, lng: 20.5477037 },
    { lat: 48.6420612, lng: 20.5476291 },
    { lat: 48.6420145, lng: 20.547522 },
    { lat: 48.6421553, lng: 20.5476151 },
    { lat: 48.6422321, lng: 20.5473501 },
    { lat: 48.6422885, lng: 20.5473827 },
    { lat: 48.642271, lng: 20.5474829 },
    { lat: 48.6423205, lng: 20.5474815 },
    { lat: 48.6424498, lng: 20.5476596 },
    { lat: 48.642533, lng: 20.5476576 },
    { lat: 48.6425426, lng: 20.5475863 },
    { lat: 48.6426774, lng: 20.547697 },
    { lat: 48.6427491, lng: 20.5476881 },
    { lat: 48.6428022, lng: 20.5475437 },
    { lat: 48.6429234, lng: 20.5474975 },
    { lat: 48.6429603, lng: 20.547734 },
    { lat: 48.6431885, lng: 20.5477236 },
    { lat: 48.6433306, lng: 20.5478523 },
    { lat: 48.643414, lng: 20.5477035 },
    { lat: 48.6434883, lng: 20.5477919 },
    { lat: 48.6434615, lng: 20.5478643 },
    { lat: 48.6436042, lng: 20.5480346 },
    { lat: 48.6436273, lng: 20.5479568 },
    { lat: 48.643693, lng: 20.5479997 },
    { lat: 48.6437326, lng: 20.5479137 },
    { lat: 48.6439429, lng: 20.5478774 },
    { lat: 48.6439666, lng: 20.5477601 },
    { lat: 48.6440125, lng: 20.5477516 },
    { lat: 48.6440439, lng: 20.5478397 },
    { lat: 48.6440708, lng: 20.5478018 },
    { lat: 48.6441112, lng: 20.5479515 },
    { lat: 48.6441755, lng: 20.5479286 },
    { lat: 48.644174, lng: 20.5478252 },
    { lat: 48.644293, lng: 20.547725 },
    { lat: 48.6444472, lng: 20.5480584 },
    { lat: 48.6444941, lng: 20.5480125 },
    { lat: 48.6445913, lng: 20.5480826 },
    { lat: 48.6445211, lng: 20.5482587 },
    { lat: 48.644531, lng: 20.5483408 },
    { lat: 48.6448278, lng: 20.5486017 },
    { lat: 48.6447849, lng: 20.5487534 },
    { lat: 48.6446472, lng: 20.5488017 },
    { lat: 48.6446671, lng: 20.5489432 },
    { lat: 48.6447734, lng: 20.5490481 },
    { lat: 48.6448215, lng: 20.5492516 },
    { lat: 48.6450104, lng: 20.549523 },
    { lat: 48.6450346, lng: 20.5498069 },
    { lat: 48.6452045, lng: 20.5502265 },
    { lat: 48.6451937, lng: 20.5503613 },
    { lat: 48.6452826, lng: 20.5505126 },
    { lat: 48.645342, lng: 20.55078 },
    { lat: 48.6452813, lng: 20.5511778 },
    { lat: 48.6452938, lng: 20.5513943 },
    { lat: 48.6453299, lng: 20.5513938 },
    { lat: 48.6453469, lng: 20.5518862 },
    { lat: 48.6452713, lng: 20.5520293 },
    { lat: 48.6452387, lng: 20.5523087 },
    { lat: 48.6455017, lng: 20.552446 },
    { lat: 48.6454968, lng: 20.5528299 },
    { lat: 48.6457308, lng: 20.5528539 },
    { lat: 48.6458938, lng: 20.5531514 },
    { lat: 48.6459015, lng: 20.5534227 },
    { lat: 48.6459796, lng: 20.5535248 },
    { lat: 48.6459332, lng: 20.5537316 },
    { lat: 48.6459684, lng: 20.553859 },
    { lat: 48.6459207, lng: 20.553941 },
    { lat: 48.6460644, lng: 20.5541072 },
    { lat: 48.6459936, lng: 20.554178 },
    { lat: 48.6460676, lng: 20.5543797 },
    { lat: 48.6461209, lng: 20.5544222 },
    { lat: 48.6461915, lng: 20.5543219 },
    { lat: 48.6462919, lng: 20.5544149 },
    { lat: 48.64626, lng: 20.5545604 },
    { lat: 48.6463491, lng: 20.5546864 },
    { lat: 48.646325, lng: 20.5547283 },
    { lat: 48.6463957, lng: 20.5549363 },
    { lat: 48.6463515, lng: 20.5550825 },
    { lat: 48.6463985, lng: 20.5552291 },
    { lat: 48.6465123, lng: 20.5552298 },
    { lat: 48.6465484, lng: 20.5553197 },
    { lat: 48.6467203, lng: 20.5552543 },
    { lat: 48.6468011, lng: 20.5553974 },
    { lat: 48.6471126, lng: 20.5554762 },
    { lat: 48.6471962, lng: 20.5556358 },
    { lat: 48.6472198, lng: 20.5559101 },
    { lat: 48.6473339, lng: 20.5559521 },
    { lat: 48.6474018, lng: 20.5561108 },
    { lat: 48.6472588, lng: 20.5564736 },
    { lat: 48.6471409, lng: 20.5563917 },
    { lat: 48.6472243, lng: 20.5567265 },
    { lat: 48.6470566, lng: 20.556825 },
    { lat: 48.6470688, lng: 20.557122 },
    { lat: 48.6475414, lng: 20.5579944 },
    { lat: 48.6479557, lng: 20.5583583 },
    { lat: 48.6480576, lng: 20.5585548 },
    { lat: 48.6489003, lng: 20.5596065 },
    { lat: 48.6489906, lng: 20.5598859 },
    { lat: 48.649408, lng: 20.5602093 },
    { lat: 48.6497075, lng: 20.5605462 },
    { lat: 48.6499032, lng: 20.5605094 },
    { lat: 48.6504563, lng: 20.5608552 },
    { lat: 48.6505407, lng: 20.5608549 },
    { lat: 48.6508237, lng: 20.5611049 },
    { lat: 48.6509937, lng: 20.5610763 },
    { lat: 48.6511042, lng: 20.5612012 },
  ],
  Betliar: [
    { lat: 48.6848758, lng: 20.4797836 },
    { lat: 48.6849347, lng: 20.4805159 },
    { lat: 48.6850424, lng: 20.4814011 },
    { lat: 48.6847641, lng: 20.4828899 },
    { lat: 48.6846908, lng: 20.4841477 },
    { lat: 48.6849122, lng: 20.4862329 },
    { lat: 48.6851999, lng: 20.4880969 },
    { lat: 48.6855826, lng: 20.4892101 },
    { lat: 48.6858822, lng: 20.4902552 },
    { lat: 48.6860318, lng: 20.4914818 },
    { lat: 48.68633, lng: 20.4921964 },
    { lat: 48.6869833, lng: 20.494032 },
    { lat: 48.6874088, lng: 20.4971768 },
    { lat: 48.6875959, lng: 20.4982099 },
    { lat: 48.688415, lng: 20.5011319 },
    { lat: 48.6884712, lng: 20.5022112 },
    { lat: 48.6897541, lng: 20.5051999 },
    { lat: 48.6900438, lng: 20.5063843 },
    { lat: 48.6902789, lng: 20.5087249 },
    { lat: 48.6902848, lng: 20.5098744 },
    { lat: 48.6904727, lng: 20.5102932 },
    { lat: 48.6904391, lng: 20.5105359 },
    { lat: 48.6904913, lng: 20.5106694 },
    { lat: 48.6904695, lng: 20.5108377 },
    { lat: 48.6905004, lng: 20.5109451 },
    { lat: 48.6904593, lng: 20.5110762 },
    { lat: 48.6905028, lng: 20.5111602 },
    { lat: 48.6904677, lng: 20.5112769 },
    { lat: 48.6905336, lng: 20.5113799 },
    { lat: 48.6905084, lng: 20.5114633 },
    { lat: 48.6906068, lng: 20.5118594 },
    { lat: 48.6905931, lng: 20.511949 },
    { lat: 48.6908724, lng: 20.5120717 },
    { lat: 48.6908559, lng: 20.5124145 },
    { lat: 48.6909599, lng: 20.5135371 },
    { lat: 48.6910211, lng: 20.514904 },
    { lat: 48.691162, lng: 20.5163578 },
    { lat: 48.691234, lng: 20.5165049 },
    { lat: 48.6911999, lng: 20.5166143 },
    { lat: 48.6926036, lng: 20.5230043 },
    { lat: 48.6928869, lng: 20.5245978 },
    { lat: 48.6934768, lng: 20.5284623 },
    { lat: 48.6935814, lng: 20.5294856 },
    { lat: 48.6936932, lng: 20.5318878 },
    { lat: 48.6945919, lng: 20.534167 },
    { lat: 48.6949254, lng: 20.5342321 },
    { lat: 48.6953171, lng: 20.534139 },
    { lat: 48.6959419, lng: 20.5341644 },
    { lat: 48.6964336, lng: 20.5340956 },
    { lat: 48.6973784, lng: 20.5344021 },
    { lat: 48.6986231, lng: 20.5349563 },
    { lat: 48.6999149, lng: 20.5351804 },
    { lat: 48.7005706, lng: 20.5354042 },
    { lat: 48.7018289, lng: 20.5362674 },
    { lat: 48.7023346, lng: 20.5367905 },
    { lat: 48.7025134, lng: 20.536872 },
    { lat: 48.703125, lng: 20.5369103 },
    { lat: 48.7038283, lng: 20.5373536 },
    { lat: 48.7043277, lng: 20.537307 },
    { lat: 48.7045392, lng: 20.5372153 },
    { lat: 48.7047802, lng: 20.5369706 },
    { lat: 48.7056289, lng: 20.5368448 },
    { lat: 48.7061051, lng: 20.5369025 },
    { lat: 48.7069576, lng: 20.5367842 },
    { lat: 48.7071748, lng: 20.5364514 },
    { lat: 48.7075578, lng: 20.5360603 },
    { lat: 48.708076, lng: 20.5357054 },
    { lat: 48.7096428, lng: 20.5350891 },
    { lat: 48.7102451, lng: 20.5349917 },
    { lat: 48.7104684, lng: 20.5350308 },
    { lat: 48.71111, lng: 20.5354206 },
    { lat: 48.7118325, lng: 20.5356845 },
    { lat: 48.7120336, lng: 20.5358817 },
    { lat: 48.7126742, lng: 20.5361571 },
    { lat: 48.713422, lng: 20.5363123 },
    { lat: 48.7138956, lng: 20.5365117 },
    { lat: 48.7144827, lng: 20.5366462 },
    { lat: 48.7157656, lng: 20.537571 },
    { lat: 48.7165208, lng: 20.5378671 },
    { lat: 48.7206026, lng: 20.5429375 },
    { lat: 48.7241438, lng: 20.5446181 },
    { lat: 48.7274156, lng: 20.5463453 },
    { lat: 48.7305211, lng: 20.5490541 },
    { lat: 48.7338506, lng: 20.5516739 },
    { lat: 48.7425154, lng: 20.563434 },
    { lat: 48.7438452, lng: 20.5666752 },
    { lat: 48.7441915, lng: 20.5738083 },
    { lat: 48.744292, lng: 20.575697 },
    { lat: 48.745629, lng: 20.572407 },
    { lat: 48.745913, lng: 20.570813 },
    { lat: 48.746044, lng: 20.570146 },
    { lat: 48.746312, lng: 20.569255 },
    { lat: 48.746428, lng: 20.568636 },
    { lat: 48.746497, lng: 20.568102 },
    { lat: 48.746517, lng: 20.567463 },
    { lat: 48.74664, lng: 20.566841 },
    { lat: 48.746896, lng: 20.565018 },
    { lat: 48.746981, lng: 20.564665 },
    { lat: 48.747088, lng: 20.564466 },
    { lat: 48.747373, lng: 20.564101 },
    { lat: 48.747546, lng: 20.564023 },
    { lat: 48.748079, lng: 20.563886 },
    { lat: 48.748508, lng: 20.563574 },
    { lat: 48.748808, lng: 20.563423 },
    { lat: 48.748977, lng: 20.563374 },
    { lat: 48.749583, lng: 20.563085 },
    { lat: 48.750648, lng: 20.562321 },
    { lat: 48.750971, lng: 20.56194 },
    { lat: 48.751647, lng: 20.561527 },
    { lat: 48.751891, lng: 20.561448 },
    { lat: 48.752454, lng: 20.561011 },
    { lat: 48.752524, lng: 20.560705 },
    { lat: 48.752686, lng: 20.560444 },
    { lat: 48.752814, lng: 20.560328 },
    { lat: 48.75295, lng: 20.560103 },
    { lat: 48.753098, lng: 20.559922 },
    { lat: 48.753287, lng: 20.559754 },
    { lat: 48.753939, lng: 20.559329 },
    { lat: 48.754398, lng: 20.558658 },
    { lat: 48.754589, lng: 20.558246 },
    { lat: 48.754747, lng: 20.557511 },
    { lat: 48.756259, lng: 20.556694 },
    { lat: 48.757311, lng: 20.556217 },
    { lat: 48.757465, lng: 20.556156 },
    { lat: 48.757734, lng: 20.555885 },
    { lat: 48.761267, lng: 20.553794 },
    { lat: 48.7627838, lng: 20.5528784 },
    { lat: 48.762628, lng: 20.5521985 },
    { lat: 48.7606638, lng: 20.5518765 },
    { lat: 48.7597747, lng: 20.550637 },
    { lat: 48.7595118, lng: 20.550139 },
    { lat: 48.7582292, lng: 20.5469042 },
    { lat: 48.757995, lng: 20.545295 },
    { lat: 48.7579855, lng: 20.5443377 },
    { lat: 48.7568831, lng: 20.5431508 },
    { lat: 48.756719, lng: 20.543128 },
    { lat: 48.7564708, lng: 20.5411411 },
    { lat: 48.7564953, lng: 20.5390979 },
    { lat: 48.7566806, lng: 20.5370839 },
    { lat: 48.7568088, lng: 20.5361659 },
    { lat: 48.7567841, lng: 20.535409 },
    { lat: 48.7565354, lng: 20.5345077 },
    { lat: 48.754919, lng: 20.5329379 },
    { lat: 48.7551564, lng: 20.5318144 },
    { lat: 48.7552249, lng: 20.5312648 },
    { lat: 48.7551934, lng: 20.5305695 },
    { lat: 48.7552472, lng: 20.5297121 },
    { lat: 48.7550293, lng: 20.5286384 },
    { lat: 48.754484, lng: 20.5269927 },
    { lat: 48.7539467, lng: 20.5259251 },
    { lat: 48.7537, lng: 20.5256485 },
    { lat: 48.7529051, lng: 20.5250782 },
    { lat: 48.7515526, lng: 20.5235841 },
    { lat: 48.7512481, lng: 20.523544 },
    { lat: 48.7502992, lng: 20.5241279 },
    { lat: 48.7499697, lng: 20.5242904 },
    { lat: 48.7498248, lng: 20.5242935 },
    { lat: 48.7494395, lng: 20.5240801 },
    { lat: 48.7487589, lng: 20.5234605 },
    { lat: 48.7486125, lng: 20.523215 },
    { lat: 48.7484979, lng: 20.5229212 },
    { lat: 48.7483905, lng: 20.5223673 },
    { lat: 48.7484288, lng: 20.5221221 },
    { lat: 48.748848, lng: 20.5207399 },
    { lat: 48.7488856, lng: 20.5204516 },
    { lat: 48.7489139, lng: 20.5196393 },
    { lat: 48.74911, lng: 20.5175011 },
    { lat: 48.748988, lng: 20.5163117 },
    { lat: 48.7488286, lng: 20.5155735 },
    { lat: 48.748738, lng: 20.5154028 },
    { lat: 48.7481825, lng: 20.5152426 },
    { lat: 48.7480596, lng: 20.5151485 },
    { lat: 48.7479495, lng: 20.5149734 },
    { lat: 48.7477644, lng: 20.5144765 },
    { lat: 48.74728, lng: 20.5139888 },
    { lat: 48.746702, lng: 20.5132567 },
    { lat: 48.7463723, lng: 20.5129591 },
    { lat: 48.7459126, lng: 20.5127408 },
    { lat: 48.745269, lng: 20.5125717 },
    { lat: 48.7449231, lng: 20.5126222 },
    { lat: 48.7445273, lng: 20.512397 },
    { lat: 48.7442057, lng: 20.5124102 },
    { lat: 48.743536, lng: 20.5122048 },
    { lat: 48.743275, lng: 20.5123626 },
    { lat: 48.7431481, lng: 20.5125525 },
    { lat: 48.7421514, lng: 20.5119698 },
    { lat: 48.7410614, lng: 20.5109473 },
    { lat: 48.7407997, lng: 20.5105098 },
    { lat: 48.740618, lng: 20.5104069 },
    { lat: 48.7404478, lng: 20.5103733 },
    { lat: 48.7396232, lng: 20.5105118 },
    { lat: 48.7393231, lng: 20.5104957 },
    { lat: 48.7384466, lng: 20.510028 },
    { lat: 48.737556, lng: 20.5100295 },
    { lat: 48.7371983, lng: 20.5098264 },
    { lat: 48.7367474, lng: 20.5097261 },
    { lat: 48.7361998, lng: 20.5096583 },
    { lat: 48.735177, lng: 20.5097011 },
    { lat: 48.7347119, lng: 20.5099077 },
    { lat: 48.7343848, lng: 20.5101982 },
    { lat: 48.7337406, lng: 20.5105232 },
    { lat: 48.7333479, lng: 20.5108046 },
    { lat: 48.7329754, lng: 20.5112609 },
    { lat: 48.7326209, lng: 20.5114466 },
    { lat: 48.7324761, lng: 20.5113994 },
    { lat: 48.7324354, lng: 20.5112752 },
    { lat: 48.7323412, lng: 20.5100738 },
    { lat: 48.7318639, lng: 20.5090581 },
    { lat: 48.7310969, lng: 20.5079662 },
    { lat: 48.7307464, lng: 20.5077429 },
    { lat: 48.7302627, lng: 20.5080021 },
    { lat: 48.7293855, lng: 20.5079379 },
    { lat: 48.7288442, lng: 20.5077923 },
    { lat: 48.7276801, lng: 20.5066203 },
    { lat: 48.7273878, lng: 20.5062119 },
    { lat: 48.7269261, lng: 20.5057585 },
    { lat: 48.7261753, lng: 20.5053145 },
    { lat: 48.724817, lng: 20.5040686 },
    { lat: 48.7243201, lng: 20.5033665 },
    { lat: 48.7240817, lng: 20.5031936 },
    { lat: 48.7237286, lng: 20.503059 },
    { lat: 48.7234778, lng: 20.5027934 },
    { lat: 48.7230769, lng: 20.5022514 },
    { lat: 48.7227459, lng: 20.5015593 },
    { lat: 48.7222661, lng: 20.5002335 },
    { lat: 48.7221384, lng: 20.5000743 },
    { lat: 48.7220805, lng: 20.4998179 },
    { lat: 48.7209882, lng: 20.4994827 },
    { lat: 48.7199406, lng: 20.4996515 },
    { lat: 48.7196536, lng: 20.499624 },
    { lat: 48.7194745, lng: 20.4998633 },
    { lat: 48.7192845, lng: 20.500265 },
    { lat: 48.718787, lng: 20.5007775 },
    { lat: 48.7185912, lng: 20.5013213 },
    { lat: 48.7184403, lng: 20.5015 },
    { lat: 48.7178453, lng: 20.5010353 },
    { lat: 48.7165434, lng: 20.5003993 },
    { lat: 48.7160922, lng: 20.4999886 },
    { lat: 48.7159247, lng: 20.4999648 },
    { lat: 48.7157233, lng: 20.4997588 },
    { lat: 48.7155734, lng: 20.4998451 },
    { lat: 48.7153592, lng: 20.5001048 },
    { lat: 48.7151343, lng: 20.5000284 },
    { lat: 48.7147984, lng: 20.5001948 },
    { lat: 48.7145733, lng: 20.5001991 },
    { lat: 48.7144532, lng: 20.5002689 },
    { lat: 48.7140826, lng: 20.5007106 },
    { lat: 48.7138052, lng: 20.5007643 },
    { lat: 48.7124673, lng: 20.500334 },
    { lat: 48.7121934, lng: 20.500295 },
    { lat: 48.7120894, lng: 20.5003788 },
    { lat: 48.7118689, lng: 20.5002614 },
    { lat: 48.7118025, lng: 20.5002593 },
    { lat: 48.7117836, lng: 20.500329 },
    { lat: 48.7115529, lng: 20.5003048 },
    { lat: 48.7115629, lng: 20.5002387 },
    { lat: 48.7112628, lng: 20.5001935 },
    { lat: 48.7110269, lng: 20.5002397 },
    { lat: 48.7108749, lng: 20.5001503 },
    { lat: 48.7105465, lng: 20.5002465 },
    { lat: 48.7104556, lng: 20.500049 },
    { lat: 48.7100367, lng: 20.4999268 },
    { lat: 48.710011, lng: 20.499688 },
    { lat: 48.709696, lng: 20.4992782 },
    { lat: 48.7095936, lng: 20.4987993 },
    { lat: 48.7094634, lng: 20.4987751 },
    { lat: 48.7093324, lng: 20.4985607 },
    { lat: 48.7093201, lng: 20.4981057 },
    { lat: 48.7094055, lng: 20.4977458 },
    { lat: 48.7094698, lng: 20.4969375 },
    { lat: 48.7096062, lng: 20.496221 },
    { lat: 48.7095571, lng: 20.4956911 },
    { lat: 48.7094134, lng: 20.4952203 },
    { lat: 48.7089675, lng: 20.4945915 },
    { lat: 48.708895, lng: 20.4939798 },
    { lat: 48.7086081, lng: 20.4933267 },
    { lat: 48.7084988, lng: 20.4931753 },
    { lat: 48.7084344, lng: 20.493199 },
    { lat: 48.7082863, lng: 20.4935721 },
    { lat: 48.7080347, lng: 20.4934942 },
    { lat: 48.7077252, lng: 20.4931396 },
    { lat: 48.7072179, lng: 20.4930618 },
    { lat: 48.7063773, lng: 20.4925367 },
    { lat: 48.7059143, lng: 20.4935207 },
    { lat: 48.7057741, lng: 20.4933884 },
    { lat: 48.7046292, lng: 20.4927638 },
    { lat: 48.7044922, lng: 20.4927403 },
    { lat: 48.7043618, lng: 20.4925679 },
    { lat: 48.703554, lng: 20.4920464 },
    { lat: 48.7035285, lng: 20.4919641 },
    { lat: 48.7032095, lng: 20.4918078 },
    { lat: 48.7031638, lng: 20.4916973 },
    { lat: 48.7033734, lng: 20.4911963 },
    { lat: 48.7030899, lng: 20.4902378 },
    { lat: 48.7027691, lng: 20.4897057 },
    { lat: 48.7023788, lng: 20.4892326 },
    { lat: 48.7023411, lng: 20.488802 },
    { lat: 48.7022636, lng: 20.4886532 },
    { lat: 48.7021729, lng: 20.4880992 },
    { lat: 48.7018991, lng: 20.4871987 },
    { lat: 48.7018707, lng: 20.486952 },
    { lat: 48.7017467, lng: 20.4867103 },
    { lat: 48.70154, lng: 20.4864767 },
    { lat: 48.701185, lng: 20.4857825 },
    { lat: 48.7009036, lng: 20.4854847 },
    { lat: 48.7003142, lng: 20.4843932 },
    { lat: 48.6996993, lng: 20.4837641 },
    { lat: 48.6991056, lng: 20.4824092 },
    { lat: 48.6988252, lng: 20.4818969 },
    { lat: 48.6984422, lng: 20.4815316 },
    { lat: 48.6983123, lng: 20.4812861 },
    { lat: 48.697966, lng: 20.4809979 },
    { lat: 48.6977531, lng: 20.4803379 },
    { lat: 48.6971612, lng: 20.4793875 },
    { lat: 48.6963585, lng: 20.4784674 },
    { lat: 48.696036, lng: 20.4780054 },
    { lat: 48.6935071, lng: 20.4789334 },
    { lat: 48.6927309, lng: 20.4793726 },
    { lat: 48.6924406, lng: 20.4796163 },
    { lat: 48.6919743, lng: 20.479583 },
    { lat: 48.6917361, lng: 20.4799249 },
    { lat: 48.6914025, lng: 20.4802187 },
    { lat: 48.6907256, lng: 20.4800623 },
    { lat: 48.690461, lng: 20.480156 },
    { lat: 48.690188, lng: 20.4804054 },
    { lat: 48.6899027, lng: 20.4804302 },
    { lat: 48.6896407, lng: 20.4802892 },
    { lat: 48.688993, lng: 20.4803067 },
    { lat: 48.6886323, lng: 20.4801527 },
    { lat: 48.6884039, lng: 20.4801848 },
    { lat: 48.6882278, lng: 20.4802933 },
    { lat: 48.6879774, lng: 20.4800429 },
    { lat: 48.6864797, lng: 20.4794367 },
    { lat: 48.6856561, lng: 20.4791593 },
    { lat: 48.6850418, lng: 20.4796504 },
    { lat: 48.6848758, lng: 20.4797836 },
  ],
  Hanková: [
    { lat: 48.7579933, lng: 20.2648974 },
    { lat: 48.757542, lng: 20.2657319 },
    { lat: 48.7564895, lng: 20.2671115 },
    { lat: 48.7559779, lng: 20.2679032 },
    { lat: 48.7553636, lng: 20.2686614 },
    { lat: 48.7547673, lng: 20.2696445 },
    { lat: 48.7538839, lng: 20.2708912 },
    { lat: 48.7530858, lng: 20.271814 },
    { lat: 48.7524248, lng: 20.2731548 },
    { lat: 48.752342, lng: 20.2747126 },
    { lat: 48.7517726, lng: 20.2770689 },
    { lat: 48.7516841, lng: 20.2789987 },
    { lat: 48.7514968, lng: 20.2797932 },
    { lat: 48.7508735, lng: 20.2809787 },
    { lat: 48.7503002, lng: 20.2824399 },
    { lat: 48.749702, lng: 20.2830347 },
    { lat: 48.7490118, lng: 20.2835221 },
    { lat: 48.7471044, lng: 20.2839316 },
    { lat: 48.745285, lng: 20.2860331 },
    { lat: 48.7438925, lng: 20.2868201 },
    { lat: 48.7438702, lng: 20.2874601 },
    { lat: 48.7437347, lng: 20.2887097 },
    { lat: 48.7437386, lng: 20.290214 },
    { lat: 48.7439085, lng: 20.2939725 },
    { lat: 48.7442131, lng: 20.2949025 },
    { lat: 48.744953, lng: 20.2963676 },
    { lat: 48.7457812, lng: 20.2985617 },
    { lat: 48.7456173, lng: 20.2987513 },
    { lat: 48.7455686, lng: 20.2989426 },
    { lat: 48.745361, lng: 20.2990233 },
    { lat: 48.7449756, lng: 20.2999067 },
    { lat: 48.7446625, lng: 20.3002762 },
    { lat: 48.7445719, lng: 20.3007814 },
    { lat: 48.7446007, lng: 20.300914 },
    { lat: 48.7445547, lng: 20.3011733 },
    { lat: 48.7444963, lng: 20.3011528 },
    { lat: 48.7444218, lng: 20.3018656 },
    { lat: 48.7443556, lng: 20.3021035 },
    { lat: 48.7443626, lng: 20.3028011 },
    { lat: 48.7444241, lng: 20.3030165 },
    { lat: 48.7443167, lng: 20.3036966 },
    { lat: 48.7443655, lng: 20.3039049 },
    { lat: 48.7443697, lng: 20.304591 },
    { lat: 48.744451, lng: 20.3047689 },
    { lat: 48.7444981, lng: 20.3050805 },
    { lat: 48.7446214, lng: 20.3052636 },
    { lat: 48.7446642, lng: 20.3058692 },
    { lat: 48.7447857, lng: 20.3061816 },
    { lat: 48.7448811, lng: 20.3071554 },
    { lat: 48.7448013, lng: 20.3074515 },
    { lat: 48.7448536, lng: 20.3076891 },
    { lat: 48.744844, lng: 20.3081078 },
    { lat: 48.7449738, lng: 20.3086336 },
    { lat: 48.7447536, lng: 20.3089031 },
    { lat: 48.744765, lng: 20.3093095 },
    { lat: 48.7447244, lng: 20.3094548 },
    { lat: 48.744774, lng: 20.3096313 },
    { lat: 48.7447114, lng: 20.3101011 },
    { lat: 48.7446425, lng: 20.3102396 },
    { lat: 48.7446591, lng: 20.3103787 },
    { lat: 48.7445723, lng: 20.3106352 },
    { lat: 48.7445724, lng: 20.3111251 },
    { lat: 48.7444856, lng: 20.3113269 },
    { lat: 48.7445205, lng: 20.3114694 },
    { lat: 48.7444785, lng: 20.311633 },
    { lat: 48.744505, lng: 20.3117926 },
    { lat: 48.7444627, lng: 20.3119242 },
    { lat: 48.7444578, lng: 20.3123428 },
    { lat: 48.7442996, lng: 20.3131976 },
    { lat: 48.7443063, lng: 20.3136276 },
    { lat: 48.744179, lng: 20.3143144 },
    { lat: 48.7441271, lng: 20.31489 },
    { lat: 48.7440729, lng: 20.3150597 },
    { lat: 48.7438078, lng: 20.3152203 },
    { lat: 48.7437662, lng: 20.3153953 },
    { lat: 48.7435275, lng: 20.3157716 },
    { lat: 48.7434836, lng: 20.3159465 },
    { lat: 48.7431175, lng: 20.3163734 },
    { lat: 48.7431249, lng: 20.3164791 },
    { lat: 48.7430558, lng: 20.3166683 },
    { lat: 48.7428047, lng: 20.3168734 },
    { lat: 48.742707, lng: 20.3168468 },
    { lat: 48.7426237, lng: 20.3170237 },
    { lat: 48.7425221, lng: 20.3170506 },
    { lat: 48.7422087, lng: 20.3173579 },
    { lat: 48.7420255, lng: 20.3180579 },
    { lat: 48.7419344, lng: 20.3182012 },
    { lat: 48.7418379, lng: 20.3182445 },
    { lat: 48.7417733, lng: 20.3184029 },
    { lat: 48.7417962, lng: 20.3185297 },
    { lat: 48.7416149, lng: 20.318645 },
    { lat: 48.7415755, lng: 20.3188184 },
    { lat: 48.7415293, lng: 20.3188439 },
    { lat: 48.7414694, lng: 20.3191311 },
    { lat: 48.7412577, lng: 20.3195963 },
    { lat: 48.7412354, lng: 20.3197446 },
    { lat: 48.7410368, lng: 20.3200239 },
    { lat: 48.7409756, lng: 20.3202145 },
    { lat: 48.7408538, lng: 20.3209036 },
    { lat: 48.7408847, lng: 20.3209701 },
    { lat: 48.7407912, lng: 20.3210597 },
    { lat: 48.7407386, lng: 20.3214729 },
    { lat: 48.7406307, lng: 20.3217922 },
    { lat: 48.7405062, lng: 20.3231609 },
    { lat: 48.7404374, lng: 20.3235103 },
    { lat: 48.7402547, lng: 20.3241388 },
    { lat: 48.739972, lng: 20.3244533 },
    { lat: 48.739818, lng: 20.3247517 },
    { lat: 48.7397838, lng: 20.3249093 },
    { lat: 48.7398899, lng: 20.324974 },
    { lat: 48.7402978, lng: 20.3250394 },
    { lat: 48.7405112, lng: 20.3249986 },
    { lat: 48.7415778, lng: 20.3243063 },
    { lat: 48.7421685, lng: 20.3237456 },
    { lat: 48.7422232, lng: 20.3239609 },
    { lat: 48.7427544, lng: 20.3241562 },
    { lat: 48.7427744, lng: 20.3241551 },
    { lat: 48.7429224, lng: 20.3239921 },
    { lat: 48.7430166, lng: 20.3238353 },
    { lat: 48.7433648, lng: 20.3235242 },
    { lat: 48.7439254, lng: 20.3234311 },
    { lat: 48.7441597, lng: 20.3235754 },
    { lat: 48.7443366, lng: 20.3235257 },
    { lat: 48.7444601, lng: 20.32357 },
    { lat: 48.7448273, lng: 20.3238071 },
    { lat: 48.7454929, lng: 20.3237499 },
    { lat: 48.7471025, lng: 20.323933 },
    { lat: 48.7474068, lng: 20.3241103 },
    { lat: 48.747703, lng: 20.3240814 },
    { lat: 48.748119, lng: 20.3241839 },
    { lat: 48.7484112, lng: 20.3241693 },
    { lat: 48.7489967, lng: 20.3238795 },
    { lat: 48.7492686, lng: 20.323831 },
    { lat: 48.7502421, lng: 20.3233264 },
    { lat: 48.7512172, lng: 20.3230907 },
    { lat: 48.7514542, lng: 20.32296 },
    { lat: 48.7520997, lng: 20.3230527 },
    { lat: 48.7524295, lng: 20.323165 },
    { lat: 48.7528532, lng: 20.323635 },
    { lat: 48.7532004, lng: 20.3238421 },
    { lat: 48.753307, lng: 20.323489 },
    { lat: 48.753391, lng: 20.3234476 },
    { lat: 48.7542039, lng: 20.3237081 },
    { lat: 48.7547745, lng: 20.32381 },
    { lat: 48.7554066, lng: 20.3241109 },
    { lat: 48.7564183, lng: 20.3244412 },
    { lat: 48.7566035, lng: 20.3243972 },
    { lat: 48.7572064, lng: 20.3240337 },
    { lat: 48.7576991, lng: 20.324129 },
    { lat: 48.758394, lng: 20.3241031 },
    { lat: 48.7587523, lng: 20.3242825 },
    { lat: 48.759059, lng: 20.3241784 },
    { lat: 48.7595626, lng: 20.3241292 },
    { lat: 48.7596553, lng: 20.324364 },
    { lat: 48.7602093, lng: 20.3243686 },
    { lat: 48.7609096, lng: 20.3241623 },
    { lat: 48.7619383, lng: 20.3240007 },
    { lat: 48.7623503, lng: 20.3240225 },
    { lat: 48.7627447, lng: 20.3241226 },
    { lat: 48.7630095, lng: 20.324355 },
    { lat: 48.7633372, lng: 20.3244985 },
    { lat: 48.7635386, lng: 20.3247554 },
    { lat: 48.7639098, lng: 20.324979 },
    { lat: 48.7640849, lng: 20.3250209 },
    { lat: 48.7641773, lng: 20.3251333 },
    { lat: 48.7650643, lng: 20.3253876 },
    { lat: 48.7653619, lng: 20.3251038 },
    { lat: 48.766428, lng: 20.3244601 },
    { lat: 48.7668516, lng: 20.3238855 },
    { lat: 48.7672043, lng: 20.3240407 },
    { lat: 48.7676046, lng: 20.3240493 },
    { lat: 48.7678488, lng: 20.324135 },
    { lat: 48.7679911, lng: 20.3241998 },
    { lat: 48.7681903, lng: 20.3235363 },
    { lat: 48.7682047, lng: 20.3231837 },
    { lat: 48.7683865, lng: 20.3222411 },
    { lat: 48.7687444, lng: 20.3214517 },
    { lat: 48.7693691, lng: 20.3204371 },
    { lat: 48.7704093, lng: 20.3195054 },
    { lat: 48.7704969, lng: 20.3195001 },
    { lat: 48.7708661, lng: 20.3188028 },
    { lat: 48.7711784, lng: 20.3186053 },
    { lat: 48.7712241, lng: 20.3183913 },
    { lat: 48.7715599, lng: 20.3183432 },
    { lat: 48.7718952, lng: 20.3184767 },
    { lat: 48.7719954, lng: 20.3181883 },
    { lat: 48.7720638, lng: 20.3177238 },
    { lat: 48.7720022, lng: 20.3175529 },
    { lat: 48.7719034, lng: 20.3166945 },
    { lat: 48.7719209, lng: 20.3159358 },
    { lat: 48.7724327, lng: 20.3147359 },
    { lat: 48.7722624, lng: 20.3145875 },
    { lat: 48.7722529, lng: 20.3144281 },
    { lat: 48.7728336, lng: 20.3128864 },
    { lat: 48.7733165, lng: 20.3108329 },
    { lat: 48.7733514, lng: 20.3097109 },
    { lat: 48.7737066, lng: 20.3094078 },
    { lat: 48.7737462, lng: 20.3092093 },
    { lat: 48.7738712, lng: 20.3090385 },
    { lat: 48.773577, lng: 20.30863 },
    { lat: 48.7734794, lng: 20.3083174 },
    { lat: 48.7730932, lng: 20.3074876 },
    { lat: 48.7730836, lng: 20.306723 },
    { lat: 48.7732482, lng: 20.3059154 },
    { lat: 48.7733718, lng: 20.3055641 },
    { lat: 48.773432, lng: 20.3055533 },
    { lat: 48.7735435, lng: 20.3052916 },
    { lat: 48.7736336, lng: 20.3047777 },
    { lat: 48.7736081, lng: 20.3035775 },
    { lat: 48.7737477, lng: 20.3019041 },
    { lat: 48.7735007, lng: 20.3007481 },
    { lat: 48.7731245, lng: 20.2990763 },
    { lat: 48.7728645, lng: 20.298644 },
    { lat: 48.7722872, lng: 20.2979971 },
    { lat: 48.7717125, lng: 20.2970662 },
    { lat: 48.7715161, lng: 20.2971677 },
    { lat: 48.7712744, lng: 20.2961176 },
    { lat: 48.7708086, lng: 20.2946924 },
    { lat: 48.7708408, lng: 20.294305 },
    { lat: 48.7712729, lng: 20.2933466 },
    { lat: 48.7715142, lng: 20.2925817 },
    { lat: 48.7715775, lng: 20.2918413 },
    { lat: 48.7715044, lng: 20.2911288 },
    { lat: 48.7716496, lng: 20.2900647 },
    { lat: 48.7718069, lng: 20.2894255 },
    { lat: 48.7717612, lng: 20.2890908 },
    { lat: 48.771396, lng: 20.2877637 },
    { lat: 48.7711902, lng: 20.2873663 },
    { lat: 48.7711372, lng: 20.2869366 },
    { lat: 48.7709413, lng: 20.2865336 },
    { lat: 48.7704661, lng: 20.2861326 },
    { lat: 48.7702207, lng: 20.2853035 },
    { lat: 48.7698427, lng: 20.2850001 },
    { lat: 48.7691714, lng: 20.2847974 },
    { lat: 48.7690857, lng: 20.284452 },
    { lat: 48.7687216, lng: 20.2844969 },
    { lat: 48.7687081, lng: 20.284129 },
    { lat: 48.7666288, lng: 20.2807658 },
    { lat: 48.7662181, lng: 20.279684 },
    { lat: 48.7656319, lng: 20.279352 },
    { lat: 48.7652574, lng: 20.2781945 },
    { lat: 48.7652305, lng: 20.277967 },
    { lat: 48.7650827, lng: 20.2777322 },
    { lat: 48.7636759, lng: 20.2734611 },
    { lat: 48.7630829, lng: 20.2722477 },
    { lat: 48.7619197, lng: 20.2704797 },
    { lat: 48.7612551, lng: 20.268934 },
    { lat: 48.7608267, lng: 20.2683516 },
    { lat: 48.7604012, lng: 20.2675562 },
    { lat: 48.7585523, lng: 20.26499 },
    { lat: 48.7579933, lng: 20.2648974 },
  ],
  Kečovo: [
    { lat: 48.473445, lng: 20.455811 },
    { lat: 48.473253, lng: 20.456212 },
    { lat: 48.47319, lng: 20.456353 },
    { lat: 48.472981, lng: 20.456829 },
    { lat: 48.472448, lng: 20.457579 },
    { lat: 48.472396, lng: 20.457653 },
    { lat: 48.472217, lng: 20.458143 },
    { lat: 48.472202, lng: 20.458775 },
    { lat: 48.471761, lng: 20.459934 },
    { lat: 48.471401, lng: 20.460818 },
    { lat: 48.470911, lng: 20.461221 },
    { lat: 48.470867, lng: 20.461256 },
    { lat: 48.46996, lng: 20.461542 },
    { lat: 48.469576, lng: 20.4621 },
    { lat: 48.469157, lng: 20.462766 },
    { lat: 48.469038, lng: 20.463345 },
    { lat: 48.468812, lng: 20.46386 },
    { lat: 48.468398, lng: 20.464326 },
    { lat: 48.468026, lng: 20.464546 },
    { lat: 48.467538, lng: 20.464407 },
    { lat: 48.466871, lng: 20.463894 },
    { lat: 48.46605, lng: 20.463205 },
    { lat: 48.465436, lng: 20.463023 },
    { lat: 48.465044, lng: 20.463135 },
    { lat: 48.464539, lng: 20.463438 },
    { lat: 48.464102, lng: 20.463871 },
    { lat: 48.4639617, lng: 20.4638826 },
    { lat: 48.4639011, lng: 20.4638934 },
    { lat: 48.463976, lng: 20.463973 },
    { lat: 48.4641844, lng: 20.4641644 },
    { lat: 48.464412, lng: 20.4652202 },
    { lat: 48.4644225, lng: 20.4652691 },
    { lat: 48.4644447, lng: 20.4653719 },
    { lat: 48.4647019, lng: 20.4665222 },
    { lat: 48.4649643, lng: 20.4677163 },
    { lat: 48.4650501, lng: 20.4680824 },
    { lat: 48.4651419, lng: 20.4685178 },
    { lat: 48.4654601, lng: 20.4699372 },
    { lat: 48.4658068, lng: 20.471527 },
    { lat: 48.4660136, lng: 20.4724677 },
    { lat: 48.4661499, lng: 20.4730937 },
    { lat: 48.4662075, lng: 20.4731924 },
    { lat: 48.4668897, lng: 20.4743869 },
    { lat: 48.4674533, lng: 20.475382 },
    { lat: 48.4679821, lng: 20.47631 },
    { lat: 48.4686696, lng: 20.4775213 },
    { lat: 48.4688161, lng: 20.4777753 },
    { lat: 48.4688967, lng: 20.477919 },
    { lat: 48.4699621, lng: 20.4797714 },
    { lat: 48.4703468, lng: 20.4804461 },
    { lat: 48.4707339, lng: 20.4811151 },
    { lat: 48.4711112, lng: 20.4817167 },
    { lat: 48.4712684, lng: 20.4819226 },
    { lat: 48.4716058, lng: 20.4823377 },
    { lat: 48.4717567, lng: 20.4825228 },
    { lat: 48.472081, lng: 20.4829228 },
    { lat: 48.4724919, lng: 20.4834324 },
    { lat: 48.4725933, lng: 20.4835541 },
    { lat: 48.4727106, lng: 20.4837024 },
    { lat: 48.4730769, lng: 20.4842789 },
    { lat: 48.4737837, lng: 20.4854054 },
    { lat: 48.4738744, lng: 20.4855325 },
    { lat: 48.473933, lng: 20.4856147 },
    { lat: 48.4739871, lng: 20.4856289 },
    { lat: 48.4740434, lng: 20.4857178 },
    { lat: 48.4741093, lng: 20.4858218 },
    { lat: 48.4742234, lng: 20.4859438 },
    { lat: 48.4743225, lng: 20.4860794 },
    { lat: 48.474374, lng: 20.4861498 },
    { lat: 48.4745092, lng: 20.4863347 },
    { lat: 48.4749802, lng: 20.4869786 },
    { lat: 48.4754417, lng: 20.487875 },
    { lat: 48.4758574, lng: 20.4886688 },
    { lat: 48.4766259, lng: 20.4888796 },
    { lat: 48.4772578, lng: 20.4890527 },
    { lat: 48.4780903, lng: 20.4892819 },
    { lat: 48.4783503, lng: 20.4893497 },
    { lat: 48.4791059, lng: 20.4896788 },
    { lat: 48.4795539, lng: 20.4898769 },
    { lat: 48.4805282, lng: 20.4903023 },
    { lat: 48.480781, lng: 20.4904126 },
    { lat: 48.4809692, lng: 20.4905009 },
    { lat: 48.4818879, lng: 20.4910541 },
    { lat: 48.4821474, lng: 20.4912137 },
    { lat: 48.48259, lng: 20.4914859 },
    { lat: 48.4828175, lng: 20.4916236 },
    { lat: 48.4828224, lng: 20.4916216 },
    { lat: 48.4828383, lng: 20.4916152 },
    { lat: 48.4832199, lng: 20.4914612 },
    { lat: 48.4840377, lng: 20.4915569 },
    { lat: 48.4843392, lng: 20.4917834 },
    { lat: 48.4846541, lng: 20.4920157 },
    { lat: 48.4849404, lng: 20.4923014 },
    { lat: 48.4857912, lng: 20.4931738 },
    { lat: 48.4864173, lng: 20.493803 },
    { lat: 48.487315, lng: 20.494742 },
    { lat: 48.4876677, lng: 20.4950662 },
    { lat: 48.4876392, lng: 20.4960479 },
    { lat: 48.4877942, lng: 20.4967227 },
    { lat: 48.4877124, lng: 20.4972093 },
    { lat: 48.4881249, lng: 20.4985776 },
    { lat: 48.4882992, lng: 20.4995982 },
    { lat: 48.4883404, lng: 20.5002554 },
    { lat: 48.4883544, lng: 20.5005022 },
    { lat: 48.4883697, lng: 20.5007015 },
    { lat: 48.4883834, lng: 20.5008958 },
    { lat: 48.4884743, lng: 20.5022201 },
    { lat: 48.488549, lng: 20.503268 },
    { lat: 48.488644, lng: 20.504461 },
    { lat: 48.488693, lng: 20.505121 },
    { lat: 48.488741, lng: 20.505628 },
    { lat: 48.488774, lng: 20.506003 },
    { lat: 48.488783, lng: 20.506243 },
    { lat: 48.4888032, lng: 20.5065825 },
    { lat: 48.48889, lng: 20.5067798 },
    { lat: 48.4892515, lng: 20.5076111 },
    { lat: 48.4893156, lng: 20.5077598 },
    { lat: 48.4894245, lng: 20.5077755 },
    { lat: 48.489583, lng: 20.507804 },
    { lat: 48.4898291, lng: 20.5078429 },
    { lat: 48.4898742, lng: 20.5078459 },
    { lat: 48.4899005, lng: 20.5076842 },
    { lat: 48.4899232, lng: 20.5075409 },
    { lat: 48.4899675, lng: 20.5072656 },
    { lat: 48.4900025, lng: 20.5070078 },
    { lat: 48.490043, lng: 20.5067484 },
    { lat: 48.4900923, lng: 20.5064243 },
    { lat: 48.4901235, lng: 20.5062277 },
    { lat: 48.4901483, lng: 20.5060728 },
    { lat: 48.4901841, lng: 20.5058315 },
    { lat: 48.4902145, lng: 20.5056033 },
    { lat: 48.4902607, lng: 20.5053373 },
    { lat: 48.4903125, lng: 20.5050132 },
    { lat: 48.4903325, lng: 20.5048377 },
    { lat: 48.4903632, lng: 20.5047099 },
    { lat: 48.491555, lng: 20.504902 },
    { lat: 48.492342, lng: 20.505017 },
    { lat: 48.493751, lng: 20.505218 },
    { lat: 48.494095, lng: 20.50527 },
    { lat: 48.494495, lng: 20.505304 },
    { lat: 48.4949, lng: 20.505355 },
    { lat: 48.495392, lng: 20.505426 },
    { lat: 48.495813, lng: 20.505491 },
    { lat: 48.496129, lng: 20.505535 },
    { lat: 48.496578, lng: 20.505604 },
    { lat: 48.497775, lng: 20.505784 },
    { lat: 48.49817, lng: 20.50583 },
    { lat: 48.498619, lng: 20.505885 },
    { lat: 48.499222, lng: 20.505972 },
    { lat: 48.500352, lng: 20.506128 },
    { lat: 48.501231, lng: 20.506258 },
    { lat: 48.502074, lng: 20.506359 },
    { lat: 48.502593, lng: 20.506423 },
    { lat: 48.502975, lng: 20.506477 },
    { lat: 48.503367, lng: 20.506528 },
    { lat: 48.503549, lng: 20.506554 },
    { lat: 48.504869, lng: 20.506735 },
    { lat: 48.506062, lng: 20.506914 },
    { lat: 48.507322, lng: 20.507093 },
    { lat: 48.507751, lng: 20.507153 },
    { lat: 48.508248, lng: 20.507191 },
    { lat: 48.508725, lng: 20.507232 },
    { lat: 48.509506, lng: 20.5073 },
    { lat: 48.51005, lng: 20.5048561 },
    { lat: 48.5103354, lng: 20.5038815 },
    { lat: 48.5110681, lng: 20.5004312 },
    { lat: 48.5114587, lng: 20.4981799 },
    { lat: 48.5131796, lng: 20.4957937 },
    { lat: 48.5141218, lng: 20.494292 },
    { lat: 48.5152874, lng: 20.4921184 },
    { lat: 48.5157308, lng: 20.4909732 },
    { lat: 48.5146512, lng: 20.4862648 },
    { lat: 48.5144979, lng: 20.4842909 },
    { lat: 48.5151316, lng: 20.4786163 },
    { lat: 48.5161254, lng: 20.4757755 },
    { lat: 48.5164944, lng: 20.4719283 },
    { lat: 48.5159406, lng: 20.4680075 },
    { lat: 48.5157953, lng: 20.4660176 },
    { lat: 48.5160884, lng: 20.4637712 },
    { lat: 48.5157974, lng: 20.4636094 },
    { lat: 48.5153137, lng: 20.4632983 },
    { lat: 48.5146584, lng: 20.4630932 },
    { lat: 48.5133358, lng: 20.4628691 },
    { lat: 48.5103468, lng: 20.4630024 },
    { lat: 48.5082345, lng: 20.4644343 },
    { lat: 48.5076123, lng: 20.4645041 },
    { lat: 48.507189, lng: 20.4644471 },
    { lat: 48.5059033, lng: 20.4648654 },
    { lat: 48.5051633, lng: 20.4661725 },
    { lat: 48.5038374, lng: 20.4670814 },
    { lat: 48.5012868, lng: 20.4673703 },
    { lat: 48.498816, lng: 20.4678661 },
    { lat: 48.4956837, lng: 20.4667489 },
    { lat: 48.4951812, lng: 20.4666216 },
    { lat: 48.4933268, lng: 20.4666913 },
    { lat: 48.4933063, lng: 20.4663554 },
    { lat: 48.493047, lng: 20.4663205 },
    { lat: 48.4930258, lng: 20.4661452 },
    { lat: 48.4928776, lng: 20.4661271 },
    { lat: 48.492861, lng: 20.465777 },
    { lat: 48.4914989, lng: 20.4656746 },
    { lat: 48.4905526, lng: 20.4657519 },
    { lat: 48.4893295, lng: 20.465956 },
    { lat: 48.4879909, lng: 20.4653091 },
    { lat: 48.4862991, lng: 20.4632468 },
    { lat: 48.4850263, lng: 20.4622263 },
    { lat: 48.483105, lng: 20.4611239 },
    { lat: 48.4816112, lng: 20.4606375 },
    { lat: 48.48025, lng: 20.4594842 },
    { lat: 48.4794644, lng: 20.4586631 },
    { lat: 48.4779617, lng: 20.4578539 },
    { lat: 48.4775908, lng: 20.4577814 },
    { lat: 48.4771654, lng: 20.4576072 },
    { lat: 48.4758824, lng: 20.4568562 },
    { lat: 48.473445, lng: 20.455811 },
  ],
  KrásnohorskéPodhradie: [
    { lat: 48.6929229, lng: 20.5910956 },
    { lat: 48.6905906, lng: 20.5874628 },
    { lat: 48.690298, lng: 20.5862401 },
    { lat: 48.690154, lng: 20.5853249 },
    { lat: 48.6899126, lng: 20.5845155 },
    { lat: 48.6893625, lng: 20.5833615 },
    { lat: 48.6883764, lng: 20.5801255 },
    { lat: 48.6884344, lng: 20.5796816 },
    { lat: 48.6871808, lng: 20.579238 },
    { lat: 48.6856475, lng: 20.5771357 },
    { lat: 48.6848205, lng: 20.575776 },
    { lat: 48.68427, lng: 20.5754399 },
    { lat: 48.6837619, lng: 20.5752442 },
    { lat: 48.6831182, lng: 20.5746134 },
    { lat: 48.6815685, lng: 20.5741718 },
    { lat: 48.6806281, lng: 20.5733383 },
    { lat: 48.6801734, lng: 20.5725823 },
    { lat: 48.6799738, lng: 20.5719293 },
    { lat: 48.6797492, lng: 20.5717048 },
    { lat: 48.6790829, lng: 20.5712831 },
    { lat: 48.6785819, lng: 20.5711895 },
    { lat: 48.6781912, lng: 20.5709141 },
    { lat: 48.6779266, lng: 20.5704896 },
    { lat: 48.677721, lng: 20.5699483 },
    { lat: 48.6775707, lng: 20.5691999 },
    { lat: 48.676886, lng: 20.5683764 },
    { lat: 48.6767087, lng: 20.568635 },
    { lat: 48.6762207, lng: 20.5687484 },
    { lat: 48.6751115, lng: 20.5687063 },
    { lat: 48.6743575, lng: 20.5688431 },
    { lat: 48.6741947, lng: 20.5686702 },
    { lat: 48.6740775, lng: 20.5670794 },
    { lat: 48.673892, lng: 20.5666615 },
    { lat: 48.6736192, lng: 20.5662583 },
    { lat: 48.6719876, lng: 20.5661335 },
    { lat: 48.6715883, lng: 20.5663242 },
    { lat: 48.6695471, lng: 20.5667221 },
    { lat: 48.6681404, lng: 20.5665944 },
    { lat: 48.6674247, lng: 20.5671216 },
    { lat: 48.6673467, lng: 20.5669005 },
    { lat: 48.6670282, lng: 20.5667799 },
    { lat: 48.6666337, lng: 20.5670097 },
    { lat: 48.6662513, lng: 20.5671444 },
    { lat: 48.6657281, lng: 20.5677673 },
    { lat: 48.6653791, lng: 20.5677332 },
    { lat: 48.6651687, lng: 20.5679525 },
    { lat: 48.6647271, lng: 20.5690721 },
    { lat: 48.6643812, lng: 20.5689776 },
    { lat: 48.6627562, lng: 20.5688788 },
    { lat: 48.6622771, lng: 20.5682207 },
    { lat: 48.6618851, lng: 20.5673625 },
    { lat: 48.6610783, lng: 20.5665222 },
    { lat: 48.660625, lng: 20.5655884 },
    { lat: 48.6603709, lng: 20.5652607 },
    { lat: 48.6602386, lng: 20.5651853 },
    { lat: 48.6599489, lng: 20.5647517 },
    { lat: 48.6597865, lng: 20.5647123 },
    { lat: 48.6596533, lng: 20.564461 },
    { lat: 48.659471, lng: 20.5643977 },
    { lat: 48.6587527, lng: 20.5635784 },
    { lat: 48.658658, lng: 20.5632997 },
    { lat: 48.6585403, lng: 20.5632245 },
    { lat: 48.6581841, lng: 20.5626972 },
    { lat: 48.6583473, lng: 20.5626631 },
    { lat: 48.6579457, lng: 20.5624893 },
    { lat: 48.6576205, lng: 20.5620095 },
    { lat: 48.6573916, lng: 20.5617845 },
    { lat: 48.6571245, lng: 20.5612938 },
    { lat: 48.6563578, lng: 20.5607793 },
    { lat: 48.6562034, lng: 20.5608322 },
    { lat: 48.6559938, lng: 20.5607342 },
    { lat: 48.6555881, lng: 20.5607267 },
    { lat: 48.6553887, lng: 20.5606366 },
    { lat: 48.6549846, lng: 20.5607262 },
    { lat: 48.6546712, lng: 20.5605005 },
    { lat: 48.6545606, lng: 20.5604963 },
    { lat: 48.6544235, lng: 20.5605926 },
    { lat: 48.6542791, lng: 20.5605859 },
    { lat: 48.6537697, lng: 20.5610618 },
    { lat: 48.653711, lng: 20.5613007 },
    { lat: 48.6535245, lng: 20.5613998 },
    { lat: 48.6534273, lng: 20.5612409 },
    { lat: 48.6531758, lng: 20.5613227 },
    { lat: 48.6529619, lng: 20.5611975 },
    { lat: 48.6528371, lng: 20.561327 },
    { lat: 48.6526275, lng: 20.561293 },
    { lat: 48.6523891, lng: 20.5614804 },
    { lat: 48.6520428, lng: 20.5615527 },
    { lat: 48.6518697, lng: 20.5614156 },
    { lat: 48.6517664, lng: 20.5614485 },
    { lat: 48.6516626, lng: 20.5612315 },
    { lat: 48.6514042, lng: 20.5613711 },
    { lat: 48.6512007, lng: 20.5612255 },
    { lat: 48.6511042, lng: 20.5612012 },
    { lat: 48.6513117, lng: 20.5686182 },
    { lat: 48.6517467, lng: 20.5711925 },
    { lat: 48.6517514, lng: 20.5715631 },
    { lat: 48.65139, lng: 20.5713467 },
    { lat: 48.6509172, lng: 20.5714765 },
    { lat: 48.6505816, lng: 20.5712566 },
    { lat: 48.6504312, lng: 20.5716367 },
    { lat: 48.6499392, lng: 20.5714861 },
    { lat: 48.6497379, lng: 20.5712755 },
    { lat: 48.6497049, lng: 20.5705654 },
    { lat: 48.6494654, lng: 20.5704238 },
    { lat: 48.6492105, lng: 20.5706254 },
    { lat: 48.6490395, lng: 20.570539 },
    { lat: 48.6489961, lng: 20.5701542 },
    { lat: 48.6488926, lng: 20.5700614 },
    { lat: 48.6486943, lng: 20.5701792 },
    { lat: 48.6484137, lng: 20.5699593 },
    { lat: 48.6484256, lng: 20.5698301 },
    { lat: 48.6483601, lng: 20.5696718 },
    { lat: 48.6482362, lng: 20.5697285 },
    { lat: 48.6481701, lng: 20.5695553 },
    { lat: 48.6481149, lng: 20.5695535 },
    { lat: 48.6480147, lng: 20.569281 },
    { lat: 48.6478334, lng: 20.5690947 },
    { lat: 48.6477341, lng: 20.5691515 },
    { lat: 48.6476135, lng: 20.5690883 },
    { lat: 48.6474102, lng: 20.5691032 },
    { lat: 48.6473023, lng: 20.5687363 },
    { lat: 48.6468692, lng: 20.568354 },
    { lat: 48.6468475, lng: 20.5682515 },
    { lat: 48.6467487, lng: 20.5681391 },
    { lat: 48.6467345, lng: 20.5679968 },
    { lat: 48.6466781, lng: 20.5679969 },
    { lat: 48.6464383, lng: 20.5675396 },
    { lat: 48.6464443, lng: 20.5674537 },
    { lat: 48.6462667, lng: 20.5672517 },
    { lat: 48.646184, lng: 20.5672359 },
    { lat: 48.6460864, lng: 20.5668802 },
    { lat: 48.6456186, lng: 20.5665294 },
    { lat: 48.6453724, lng: 20.5665394 },
    { lat: 48.645273, lng: 20.5666524 },
    { lat: 48.6450594, lng: 20.5666946 },
    { lat: 48.6446549, lng: 20.5664445 },
    { lat: 48.6445293, lng: 20.5661802 },
    { lat: 48.6446849, lng: 20.5660681 },
    { lat: 48.642424, lng: 20.5641492 },
    { lat: 48.6420968, lng: 20.5660448 },
    { lat: 48.6420738, lng: 20.566182 },
    { lat: 48.6421775, lng: 20.5662189 },
    { lat: 48.6422617, lng: 20.5663631 },
    { lat: 48.6424334, lng: 20.566349 },
    { lat: 48.6425082, lng: 20.5665741 },
    { lat: 48.6424111, lng: 20.5669341 },
    { lat: 48.642445, lng: 20.5672459 },
    { lat: 48.6412444, lng: 20.5704361 },
    { lat: 48.6417605, lng: 20.5714227 },
    { lat: 48.6419473, lng: 20.5719966 },
    { lat: 48.6420371, lng: 20.5721175 },
    { lat: 48.6420617, lng: 20.5722913 },
    { lat: 48.6421354, lng: 20.5723717 },
    { lat: 48.6421016, lng: 20.5725468 },
    { lat: 48.637422, lng: 20.5861787 },
    { lat: 48.637143, lng: 20.5875936 },
    { lat: 48.636642, lng: 20.5873785 },
    { lat: 48.6358012, lng: 20.5871434 },
    { lat: 48.6356115, lng: 20.5881375 },
    { lat: 48.6350485, lng: 20.5918162 },
    { lat: 48.634446, lng: 20.5950315 },
    { lat: 48.6337524, lng: 20.5980991 },
    { lat: 48.6335336, lng: 20.5987919 },
    { lat: 48.6332302, lng: 20.600042 },
    { lat: 48.6327927, lng: 20.6011916 },
    { lat: 48.6322073, lng: 20.6027283 },
    { lat: 48.6322782, lng: 20.603189 },
    { lat: 48.6324292, lng: 20.6035317 },
    { lat: 48.6326655, lng: 20.6037401 },
    { lat: 48.6328328, lng: 20.6040643 },
    { lat: 48.6330992, lng: 20.6044248 },
    { lat: 48.6332718, lng: 20.6044286 },
    { lat: 48.6334594, lng: 20.6045699 },
    { lat: 48.6336628, lng: 20.6048694 },
    { lat: 48.634038, lng: 20.6051542 },
    { lat: 48.6342634, lng: 20.6051938 },
    { lat: 48.6349418, lng: 20.605914 },
    { lat: 48.6350455, lng: 20.6062902 },
    { lat: 48.6354067, lng: 20.6068955 },
    { lat: 48.636577, lng: 20.6091546 },
    { lat: 48.6373738, lng: 20.6101312 },
    { lat: 48.6387961, lng: 20.6114048 },
    { lat: 48.6390495, lng: 20.6115296 },
    { lat: 48.6398416, lng: 20.6123434 },
    { lat: 48.6403464, lng: 20.6122063 },
    { lat: 48.640968, lng: 20.6121468 },
    { lat: 48.6411122, lng: 20.6122117 },
    { lat: 48.6418513, lng: 20.6122876 },
    { lat: 48.6421385, lng: 20.6121655 },
    { lat: 48.6423402, lng: 20.6121394 },
    { lat: 48.6424358, lng: 20.6122262 },
    { lat: 48.6425103, lng: 20.6121716 },
    { lat: 48.6426498, lng: 20.6124775 },
    { lat: 48.6427956, lng: 20.6125537 },
    { lat: 48.6428876, lng: 20.6131605 },
    { lat: 48.6430062, lng: 20.6133522 },
    { lat: 48.643288, lng: 20.6132394 },
    { lat: 48.6437333, lng: 20.6133529 },
    { lat: 48.6441438, lng: 20.6135847 },
    { lat: 48.6445007, lng: 20.6139833 },
    { lat: 48.6446038, lng: 20.6143123 },
    { lat: 48.644583, lng: 20.6145123 },
    { lat: 48.6444002, lng: 20.6146881 },
    { lat: 48.6444053, lng: 20.615106 },
    { lat: 48.6440404, lng: 20.6159021 },
    { lat: 48.6437321, lng: 20.6162792 },
    { lat: 48.6432235, lng: 20.6167202 },
    { lat: 48.6436072, lng: 20.6171426 },
    { lat: 48.6433476, lng: 20.6176188 },
    { lat: 48.6431762, lng: 20.6184426 },
    { lat: 48.6429179, lng: 20.619174 },
    { lat: 48.6425746, lng: 20.6215793 },
    { lat: 48.6439987, lng: 20.6217309 },
    { lat: 48.6445695, lng: 20.6221022 },
    { lat: 48.6449534, lng: 20.6224396 },
    { lat: 48.6451114, lng: 20.6223923 },
    { lat: 48.6456988, lng: 20.6225066 },
    { lat: 48.6458162, lng: 20.62273 },
    { lat: 48.6461052, lng: 20.6228456 },
    { lat: 48.6462404, lng: 20.6230288 },
    { lat: 48.646325, lng: 20.6231873 },
    { lat: 48.6458895, lng: 20.6238736 },
    { lat: 48.6455797, lng: 20.6246772 },
    { lat: 48.6453796, lng: 20.6254529 },
    { lat: 48.6452682, lng: 20.6260471 },
    { lat: 48.6453994, lng: 20.626184 },
    { lat: 48.64619, lng: 20.6264367 },
    { lat: 48.6465363, lng: 20.6266106 },
    { lat: 48.6473487, lng: 20.6272172 },
    { lat: 48.6470762, lng: 20.6290371 },
    { lat: 48.6470509, lng: 20.6300792 },
    { lat: 48.6476429, lng: 20.6303794 },
    { lat: 48.6482012, lng: 20.6303763 },
    { lat: 48.6496562, lng: 20.631007 },
    { lat: 48.6503064, lng: 20.6323806 },
    { lat: 48.6501459, lng: 20.6333923 },
    { lat: 48.6501208, lng: 20.6342774 },
    { lat: 48.6502212, lng: 20.6354879 },
    { lat: 48.6503382, lng: 20.636326 },
    { lat: 48.6505641, lng: 20.6373357 },
    { lat: 48.650689, lng: 20.6377095 },
    { lat: 48.651147, lng: 20.6384126 },
    { lat: 48.6513471, lng: 20.6391474 },
    { lat: 48.65188, lng: 20.6397169 },
    { lat: 48.6522371, lng: 20.6405017 },
    { lat: 48.6523742, lng: 20.6417957 },
    { lat: 48.652473, lng: 20.6435584 },
    { lat: 48.6526935, lng: 20.6438606 },
    { lat: 48.65329, lng: 20.6443522 },
    { lat: 48.6543827, lng: 20.646014 },
    { lat: 48.656139, lng: 20.6455819 },
    { lat: 48.6569567, lng: 20.6454649 },
    { lat: 48.6573184, lng: 20.6451655 },
    { lat: 48.6583133, lng: 20.6453091 },
    { lat: 48.6597724, lng: 20.6447221 },
    { lat: 48.6614459, lng: 20.6445468 },
    { lat: 48.6620833, lng: 20.6445195 },
    { lat: 48.6627443, lng: 20.6445831 },
    { lat: 48.6637923, lng: 20.6443295 },
    { lat: 48.6644448, lng: 20.644381 },
    { lat: 48.6648311, lng: 20.6442601 },
    { lat: 48.66529, lng: 20.6439358 },
    { lat: 48.6662821, lng: 20.6435664 },
    { lat: 48.6666859, lng: 20.6432182 },
    { lat: 48.6681309, lng: 20.6416167 },
    { lat: 48.6690879, lng: 20.6413388 },
    { lat: 48.669731, lng: 20.6412814 },
    { lat: 48.6707203, lng: 20.6410222 },
    { lat: 48.6721443, lng: 20.63987 },
    { lat: 48.6728829, lng: 20.6399022 },
    { lat: 48.6737835, lng: 20.6394359 },
    { lat: 48.6753214, lng: 20.6381915 },
    { lat: 48.675413, lng: 20.6369962 },
    { lat: 48.6750878, lng: 20.6354172 },
    { lat: 48.6749428, lng: 20.6351128 },
    { lat: 48.6745169, lng: 20.6347025 },
    { lat: 48.6742568, lng: 20.6340504 },
    { lat: 48.6735734, lng: 20.6319169 },
    { lat: 48.6729437, lng: 20.6306043 },
    { lat: 48.6722283, lng: 20.6299088 },
    { lat: 48.6711133, lng: 20.6291221 },
    { lat: 48.6719044, lng: 20.6249354 },
    { lat: 48.6726521, lng: 20.6225019 },
    { lat: 48.6762164, lng: 20.6192829 },
    { lat: 48.6755352, lng: 20.6176452 },
    { lat: 48.6728424, lng: 20.6189474 },
    { lat: 48.6719642, lng: 20.6179937 },
    { lat: 48.6720636, lng: 20.6169806 },
    { lat: 48.6718387, lng: 20.6153877 },
    { lat: 48.671379, lng: 20.6144795 },
    { lat: 48.6712253, lng: 20.6138757 },
    { lat: 48.6728035, lng: 20.6102932 },
    { lat: 48.6740318, lng: 20.6065704 },
    { lat: 48.6727524, lng: 20.6048617 },
    { lat: 48.6725975, lng: 20.603092 },
    { lat: 48.6703891, lng: 20.6011675 },
    { lat: 48.6705567, lng: 20.6007645 },
    { lat: 48.6702241, lng: 20.60029 },
    { lat: 48.6706957, lng: 20.5991798 },
    { lat: 48.6708469, lng: 20.5991601 },
    { lat: 48.6710868, lng: 20.59876 },
    { lat: 48.6711409, lng: 20.5985175 },
    { lat: 48.6714893, lng: 20.5977938 },
    { lat: 48.6716747, lng: 20.5976903 },
    { lat: 48.671886, lng: 20.5974703 },
    { lat: 48.6729702, lng: 20.5971898 },
    { lat: 48.6735971, lng: 20.597369 },
    { lat: 48.6738166, lng: 20.597309 },
    { lat: 48.6755487, lng: 20.5975636 },
    { lat: 48.6757424, lng: 20.5973814 },
    { lat: 48.6760004, lng: 20.5973528 },
    { lat: 48.6763041, lng: 20.5975625 },
    { lat: 48.6768871, lng: 20.5973738 },
    { lat: 48.6771999, lng: 20.5971802 },
    { lat: 48.6777104, lng: 20.5971671 },
    { lat: 48.6780478, lng: 20.5969989 },
    { lat: 48.6784725, lng: 20.5971601 },
    { lat: 48.6789582, lng: 20.5966499 },
    { lat: 48.6801161, lng: 20.5964167 },
    { lat: 48.6807749, lng: 20.5959626 },
    { lat: 48.6812731, lng: 20.5959726 },
    { lat: 48.6817078, lng: 20.5961055 },
    { lat: 48.6818357, lng: 20.5960289 },
    { lat: 48.6819678, lng: 20.5957642 },
    { lat: 48.6823516, lng: 20.595575 },
    { lat: 48.6826944, lng: 20.5952802 },
    { lat: 48.6830416, lng: 20.595265 },
    { lat: 48.6835534, lng: 20.5951236 },
    { lat: 48.6838499, lng: 20.5953232 },
    { lat: 48.6844274, lng: 20.5950448 },
    { lat: 48.6846955, lng: 20.5949814 },
    { lat: 48.6856627, lng: 20.5941857 },
    { lat: 48.686088, lng: 20.5936574 },
    { lat: 48.6877149, lng: 20.5928564 },
    { lat: 48.6895831, lng: 20.5921645 },
    { lat: 48.6917406, lng: 20.591495 },
    { lat: 48.6929229, lng: 20.5910956 },
  ],
  Markuška: [
    { lat: 48.7191152, lng: 20.3114761 },
    { lat: 48.7183617, lng: 20.3118145 },
    { lat: 48.7177059, lng: 20.3122667 },
    { lat: 48.7171017, lng: 20.3131459 },
    { lat: 48.7162643, lng: 20.3152298 },
    { lat: 48.7162254, lng: 20.3155039 },
    { lat: 48.7163558, lng: 20.3190174 },
    { lat: 48.71603, lng: 20.320332 },
    { lat: 48.7155926, lng: 20.3216227 },
    { lat: 48.7152369, lng: 20.3222317 },
    { lat: 48.7149754, lng: 20.3236725 },
    { lat: 48.7132851, lng: 20.3262692 },
    { lat: 48.7129474, lng: 20.3263581 },
    { lat: 48.7128405, lng: 20.3262193 },
    { lat: 48.7121572, lng: 20.326918 },
    { lat: 48.7116498, lng: 20.3275636 },
    { lat: 48.7110799, lng: 20.3290659 },
    { lat: 48.7106958, lng: 20.329759 },
    { lat: 48.7105823, lng: 20.3297895 },
    { lat: 48.7104158, lng: 20.3300574 },
    { lat: 48.7102069, lng: 20.3305599 },
    { lat: 48.7101526, lng: 20.3310739 },
    { lat: 48.7100244, lng: 20.3315142 },
    { lat: 48.7101081, lng: 20.3316116 },
    { lat: 48.7101221, lng: 20.3321156 },
    { lat: 48.7106098, lng: 20.3338252 },
    { lat: 48.7107452, lng: 20.3344407 },
    { lat: 48.7114211, lng: 20.3339528 },
    { lat: 48.7119287, lng: 20.3334245 },
    { lat: 48.7121565, lng: 20.3333782 },
    { lat: 48.7128659, lng: 20.3329123 },
    { lat: 48.714424, lng: 20.3322318 },
    { lat: 48.7149455, lng: 20.3322375 },
    { lat: 48.7151716, lng: 20.3321725 },
    { lat: 48.7152087, lng: 20.3320276 },
    { lat: 48.7156412, lng: 20.3320031 },
    { lat: 48.7162514, lng: 20.331589 },
    { lat: 48.7164438, lng: 20.3315848 },
    { lat: 48.7166677, lng: 20.3328829 },
    { lat: 48.71685, lng: 20.3327644 },
    { lat: 48.7171243, lng: 20.3320029 },
    { lat: 48.7171176, lng: 20.3318836 },
    { lat: 48.7174602, lng: 20.331805 },
    { lat: 48.7177438, lng: 20.3316882 },
    { lat: 48.7177466, lng: 20.3316391 },
    { lat: 48.7179202, lng: 20.3316736 },
    { lat: 48.718338, lng: 20.3316158 },
    { lat: 48.7184347, lng: 20.3316988 },
    { lat: 48.7189319, lng: 20.3315729 },
    { lat: 48.7191, lng: 20.3316104 },
    { lat: 48.7191767, lng: 20.3317252 },
    { lat: 48.7192376, lng: 20.3316713 },
    { lat: 48.7192109, lng: 20.3315385 },
    { lat: 48.7196125, lng: 20.3311194 },
    { lat: 48.7198613, lng: 20.3310007 },
    { lat: 48.7200413, lng: 20.3305927 },
    { lat: 48.7205514, lng: 20.3300987 },
    { lat: 48.7210589, lng: 20.3299455 },
    { lat: 48.7215171, lng: 20.3299743 },
    { lat: 48.7217373, lng: 20.329872 },
    { lat: 48.7226071, lng: 20.3301135 },
    { lat: 48.7235396, lng: 20.3298273 },
    { lat: 48.7240479, lng: 20.3298085 },
    { lat: 48.7242378, lng: 20.3296264 },
    { lat: 48.7243102, lng: 20.329401 },
    { lat: 48.7248676, lng: 20.3292259 },
    { lat: 48.7252645, lng: 20.3293293 },
    { lat: 48.7254425, lng: 20.3294768 },
    { lat: 48.7256962, lng: 20.3295677 },
    { lat: 48.7259234, lng: 20.32979 },
    { lat: 48.726011, lng: 20.3297858 },
    { lat: 48.7262916, lng: 20.3299674 },
    { lat: 48.7267766, lng: 20.3299888 },
    { lat: 48.726984, lng: 20.3299022 },
    { lat: 48.727101, lng: 20.3299307 },
    { lat: 48.7271629, lng: 20.3300183 },
    { lat: 48.7271481, lng: 20.3302143 },
    { lat: 48.7272787, lng: 20.3303015 },
    { lat: 48.727668, lng: 20.3304346 },
    { lat: 48.7278863, lng: 20.3303199 },
    { lat: 48.72799, lng: 20.3303456 },
    { lat: 48.728282, lng: 20.330208 },
    { lat: 48.7283507, lng: 20.3299494 },
    { lat: 48.7290271, lng: 20.3292172 },
    { lat: 48.7291213, lng: 20.3293526 },
    { lat: 48.729227, lng: 20.329377 },
    { lat: 48.7293596, lng: 20.329254 },
    { lat: 48.7294638, lng: 20.3290096 },
    { lat: 48.7296479, lng: 20.328939 },
    { lat: 48.7297794, lng: 20.3291039 },
    { lat: 48.730267, lng: 20.3290748 },
    { lat: 48.7302741, lng: 20.3289957 },
    { lat: 48.7305044, lng: 20.3288389 },
    { lat: 48.7305441, lng: 20.3286713 },
    { lat: 48.7307737, lng: 20.328495 },
    { lat: 48.7309738, lng: 20.3281672 },
    { lat: 48.7313271, lng: 20.3279014 },
    { lat: 48.7315854, lng: 20.3277967 },
    { lat: 48.7322001, lng: 20.3283424 },
    { lat: 48.7323827, lng: 20.3283155 },
    { lat: 48.7325316, lng: 20.3286418 },
    { lat: 48.7326391, lng: 20.3286825 },
    { lat: 48.732852, lng: 20.3286308 },
    { lat: 48.7334192, lng: 20.3283302 },
    { lat: 48.7338717, lng: 20.3282375 },
    { lat: 48.7346239, lng: 20.3278599 },
    { lat: 48.7345635, lng: 20.3276662 },
    { lat: 48.7345386, lng: 20.3272871 },
    { lat: 48.7348163, lng: 20.3270733 },
    { lat: 48.7349918, lng: 20.3265211 },
    { lat: 48.7352839, lng: 20.3261558 },
    { lat: 48.7353383, lng: 20.3263105 },
    { lat: 48.7363401, lng: 20.3263857 },
    { lat: 48.7370807, lng: 20.3262306 },
    { lat: 48.7375226, lng: 20.3262173 },
    { lat: 48.7376399, lng: 20.3263617 },
    { lat: 48.737853, lng: 20.3268037 },
    { lat: 48.7383345, lng: 20.3266431 },
    { lat: 48.7383259, lng: 20.3263994 },
    { lat: 48.7387687, lng: 20.326141 },
    { lat: 48.7398895, lng: 20.3252855 },
    { lat: 48.7398882, lng: 20.3250227 },
    { lat: 48.7398899, lng: 20.324974 },
    { lat: 48.7397838, lng: 20.3249093 },
    { lat: 48.739818, lng: 20.3247517 },
    { lat: 48.739972, lng: 20.3244533 },
    { lat: 48.7402547, lng: 20.3241388 },
    { lat: 48.7404374, lng: 20.3235103 },
    { lat: 48.7405062, lng: 20.3231609 },
    { lat: 48.7406307, lng: 20.3217922 },
    { lat: 48.7407386, lng: 20.3214729 },
    { lat: 48.7407912, lng: 20.3210597 },
    { lat: 48.7408847, lng: 20.3209701 },
    { lat: 48.7408538, lng: 20.3209036 },
    { lat: 48.7409756, lng: 20.3202145 },
    { lat: 48.7410368, lng: 20.3200239 },
    { lat: 48.7412354, lng: 20.3197446 },
    { lat: 48.7412577, lng: 20.3195963 },
    { lat: 48.7414694, lng: 20.3191311 },
    { lat: 48.7415293, lng: 20.3188439 },
    { lat: 48.7415755, lng: 20.3188184 },
    { lat: 48.7416149, lng: 20.318645 },
    { lat: 48.7417962, lng: 20.3185297 },
    { lat: 48.7417733, lng: 20.3184029 },
    { lat: 48.7418379, lng: 20.3182445 },
    { lat: 48.7419344, lng: 20.3182012 },
    { lat: 48.7420255, lng: 20.3180579 },
    { lat: 48.7422087, lng: 20.3173579 },
    { lat: 48.7425221, lng: 20.3170506 },
    { lat: 48.7426237, lng: 20.3170237 },
    { lat: 48.742707, lng: 20.3168468 },
    { lat: 48.7428047, lng: 20.3168734 },
    { lat: 48.7430558, lng: 20.3166683 },
    { lat: 48.7431249, lng: 20.3164791 },
    { lat: 48.7431175, lng: 20.3163734 },
    { lat: 48.7434836, lng: 20.3159465 },
    { lat: 48.7435275, lng: 20.3157716 },
    { lat: 48.7437662, lng: 20.3153953 },
    { lat: 48.7438078, lng: 20.3152203 },
    { lat: 48.7440729, lng: 20.3150597 },
    { lat: 48.7441271, lng: 20.31489 },
    { lat: 48.744179, lng: 20.3143144 },
    { lat: 48.7443063, lng: 20.3136276 },
    { lat: 48.7442996, lng: 20.3131976 },
    { lat: 48.7444578, lng: 20.3123428 },
    { lat: 48.7444627, lng: 20.3119242 },
    { lat: 48.744505, lng: 20.3117926 },
    { lat: 48.7444785, lng: 20.311633 },
    { lat: 48.7445205, lng: 20.3114694 },
    { lat: 48.7444856, lng: 20.3113269 },
    { lat: 48.7445724, lng: 20.3111251 },
    { lat: 48.7445723, lng: 20.3106352 },
    { lat: 48.7446591, lng: 20.3103787 },
    { lat: 48.7446425, lng: 20.3102396 },
    { lat: 48.7447114, lng: 20.3101011 },
    { lat: 48.744774, lng: 20.3096313 },
    { lat: 48.7447244, lng: 20.3094548 },
    { lat: 48.744765, lng: 20.3093095 },
    { lat: 48.7447536, lng: 20.3089031 },
    { lat: 48.7449738, lng: 20.3086336 },
    { lat: 48.744844, lng: 20.3081078 },
    { lat: 48.7448536, lng: 20.3076891 },
    { lat: 48.7448013, lng: 20.3074515 },
    { lat: 48.7448811, lng: 20.3071554 },
    { lat: 48.7447857, lng: 20.3061816 },
    { lat: 48.7446642, lng: 20.3058692 },
    { lat: 48.7446214, lng: 20.3052636 },
    { lat: 48.7444981, lng: 20.3050805 },
    { lat: 48.744451, lng: 20.3047689 },
    { lat: 48.7443697, lng: 20.304591 },
    { lat: 48.7443655, lng: 20.3039049 },
    { lat: 48.7443167, lng: 20.3036966 },
    { lat: 48.7444241, lng: 20.3030165 },
    { lat: 48.7443626, lng: 20.3028011 },
    { lat: 48.7443556, lng: 20.3021035 },
    { lat: 48.7444218, lng: 20.3018656 },
    { lat: 48.7444963, lng: 20.3011528 },
    { lat: 48.7445547, lng: 20.3011733 },
    { lat: 48.7446007, lng: 20.300914 },
    { lat: 48.7445719, lng: 20.3007814 },
    { lat: 48.7446625, lng: 20.3002762 },
    { lat: 48.7449756, lng: 20.2999067 },
    { lat: 48.745361, lng: 20.2990233 },
    { lat: 48.7455686, lng: 20.2989426 },
    { lat: 48.7456173, lng: 20.2987513 },
    { lat: 48.7457812, lng: 20.2985617 },
    { lat: 48.744953, lng: 20.2963676 },
    { lat: 48.7442131, lng: 20.2949025 },
    { lat: 48.7439085, lng: 20.2939725 },
    { lat: 48.7437386, lng: 20.290214 },
    { lat: 48.7437347, lng: 20.2887097 },
    { lat: 48.7438702, lng: 20.2874601 },
    { lat: 48.7438925, lng: 20.2868201 },
    { lat: 48.7436961, lng: 20.2866087 },
    { lat: 48.7435153, lng: 20.2867402 },
    { lat: 48.7430609, lng: 20.2872962 },
    { lat: 48.7425641, lng: 20.287491 },
    { lat: 48.7422027, lng: 20.2881261 },
    { lat: 48.7418839, lng: 20.2884384 },
    { lat: 48.7416252, lng: 20.2885619 },
    { lat: 48.7406823, lng: 20.289641 },
    { lat: 48.7403681, lng: 20.2903043 },
    { lat: 48.740304, lng: 20.2905355 },
    { lat: 48.7393396, lng: 20.2920709 },
    { lat: 48.7391668, lng: 20.2924882 },
    { lat: 48.7389361, lng: 20.2935849 },
    { lat: 48.7385632, lng: 20.2946423 },
    { lat: 48.7377878, lng: 20.2954861 },
    { lat: 48.7365972, lng: 20.2960858 },
    { lat: 48.7357459, lng: 20.2966916 },
    { lat: 48.7348549, lng: 20.2970011 },
    { lat: 48.7332071, lng: 20.2972494 },
    { lat: 48.73265, lng: 20.2975758 },
    { lat: 48.732524, lng: 20.2976056 },
    { lat: 48.7322486, lng: 20.2975315 },
    { lat: 48.7315519, lng: 20.297229 },
    { lat: 48.7310514, lng: 20.2968447 },
    { lat: 48.7305356, lng: 20.296984 },
    { lat: 48.7292157, lng: 20.2975539 },
    { lat: 48.7282465, lng: 20.2988617 },
    { lat: 48.7276295, lng: 20.2999521 },
    { lat: 48.7259538, lng: 20.3021095 },
    { lat: 48.7257531, lng: 20.3024802 },
    { lat: 48.7252265, lng: 20.3027785 },
    { lat: 48.7244745, lng: 20.3030979 },
    { lat: 48.724111, lng: 20.3031636 },
    { lat: 48.7233903, lng: 20.3030779 },
    { lat: 48.7228489, lng: 20.3033407 },
    { lat: 48.7223737, lng: 20.304235 },
    { lat: 48.7223761, lng: 20.3050729 },
    { lat: 48.7223223, lng: 20.3055378 },
    { lat: 48.721713, lng: 20.3066883 },
    { lat: 48.7214412, lng: 20.307341 },
    { lat: 48.7211381, lng: 20.3077365 },
    { lat: 48.7208357, lng: 20.3080128 },
    { lat: 48.7206311, lng: 20.3086813 },
    { lat: 48.7205795, lng: 20.3090646 },
    { lat: 48.7204481, lng: 20.3094155 },
    { lat: 48.7202504, lng: 20.3096871 },
    { lat: 48.7200065, lng: 20.3103045 },
    { lat: 48.7200325, lng: 20.3106257 },
    { lat: 48.7191152, lng: 20.3114761 },
  ],
  Kobeliarovo: [
    { lat: 48.7269316, lng: 20.3736667 },
    { lat: 48.7274404, lng: 20.3738031 },
    { lat: 48.7281179, lng: 20.3740848 },
    { lat: 48.7286391, lng: 20.3744334 },
    { lat: 48.7291815, lng: 20.374982 },
    { lat: 48.7293768, lng: 20.3750533 },
    { lat: 48.729281, lng: 20.3756338 },
    { lat: 48.7294498, lng: 20.3768235 },
    { lat: 48.7295135, lng: 20.3776643 },
    { lat: 48.7296644, lng: 20.3810863 },
    { lat: 48.7297577, lng: 20.3818119 },
    { lat: 48.7297507, lng: 20.3821884 },
    { lat: 48.7299928, lng: 20.3832993 },
    { lat: 48.7303376, lng: 20.3838033 },
    { lat: 48.7313193, lng: 20.384439 },
    { lat: 48.7323834, lng: 20.3859332 },
    { lat: 48.7326755, lng: 20.3859432 },
    { lat: 48.7329811, lng: 20.3857498 },
    { lat: 48.7330906, lng: 20.3856135 },
    { lat: 48.7334449, lng: 20.3855128 },
    { lat: 48.7335931, lng: 20.3853835 },
    { lat: 48.7342137, lng: 20.3852741 },
    { lat: 48.7343417, lng: 20.3853188 },
    { lat: 48.7345724, lng: 20.3852012 },
    { lat: 48.7346852, lng: 20.385207 },
    { lat: 48.7350013, lng: 20.3854318 },
    { lat: 48.7353408, lng: 20.3854729 },
    { lat: 48.7358083, lng: 20.3853352 },
    { lat: 48.7359001, lng: 20.3854395 },
    { lat: 48.7363218, lng: 20.3853892 },
    { lat: 48.7364537, lng: 20.3852803 },
    { lat: 48.7368363, lng: 20.3851835 },
    { lat: 48.7371922, lng: 20.3851858 },
    { lat: 48.737436, lng: 20.3850509 },
    { lat: 48.7376884, lng: 20.3852039 },
    { lat: 48.7381116, lng: 20.3849056 },
    { lat: 48.7387665, lng: 20.3848027 },
    { lat: 48.7388037, lng: 20.3854202 },
    { lat: 48.7389697, lng: 20.3862392 },
    { lat: 48.739119, lng: 20.3869761 },
    { lat: 48.7395891, lng: 20.3871064 },
    { lat: 48.7420893, lng: 20.3906612 },
    { lat: 48.7425781, lng: 20.3914571 },
    { lat: 48.74301, lng: 20.392 },
    { lat: 48.7440088, lng: 20.3911831 },
    { lat: 48.7444318, lng: 20.3910514 },
    { lat: 48.7447869, lng: 20.390859 },
    { lat: 48.7450481, lng: 20.3905656 },
    { lat: 48.7452957, lng: 20.3901172 },
    { lat: 48.7457194, lng: 20.389568 },
    { lat: 48.7461673, lng: 20.3896813 },
    { lat: 48.746497, lng: 20.3896756 },
    { lat: 48.7477495, lng: 20.3900081 },
    { lat: 48.7481544, lng: 20.3900261 },
    { lat: 48.7486348, lng: 20.3898958 },
    { lat: 48.7490088, lng: 20.389631 },
    { lat: 48.7496217, lng: 20.3895244 },
    { lat: 48.7505912, lng: 20.389527 },
    { lat: 48.7512995, lng: 20.3893496 },
    { lat: 48.7527238, lng: 20.3893191 },
    { lat: 48.7529775, lng: 20.3891227 },
    { lat: 48.7532005, lng: 20.3890613 },
    { lat: 48.753254, lng: 20.389108 },
    { lat: 48.753644, lng: 20.3898214 },
    { lat: 48.7537548, lng: 20.3898909 },
    { lat: 48.7539628, lng: 20.3901767 },
    { lat: 48.7540665, lng: 20.3907096 },
    { lat: 48.7541784, lng: 20.3909507 },
    { lat: 48.7542795, lng: 20.3917091 },
    { lat: 48.7545372, lng: 20.3921154 },
    { lat: 48.7548364, lng: 20.3923132 },
    { lat: 48.7553511, lng: 20.3930767 },
    { lat: 48.7554037, lng: 20.3931885 },
    { lat: 48.7561893, lng: 20.391651 },
    { lat: 48.757182, lng: 20.3910485 },
    { lat: 48.7575112, lng: 20.3906824 },
    { lat: 48.758421, lng: 20.3900022 },
    { lat: 48.7589071, lng: 20.3898503 },
    { lat: 48.7596398, lng: 20.389342 },
    { lat: 48.7606498, lng: 20.3888386 },
    { lat: 48.7609904, lng: 20.3884104 },
    { lat: 48.7614089, lng: 20.3880535 },
    { lat: 48.7622954, lng: 20.3878257 },
    { lat: 48.7629382, lng: 20.387439 },
    { lat: 48.7632102, lng: 20.3871367 },
    { lat: 48.7633773, lng: 20.3868547 },
    { lat: 48.7634966, lng: 20.3857949 },
    { lat: 48.764138, lng: 20.3843772 },
    { lat: 48.7643991, lng: 20.3834055 },
    { lat: 48.7645909, lng: 20.3817781 },
    { lat: 48.7646235, lng: 20.38098 },
    { lat: 48.7647223, lng: 20.3803591 },
    { lat: 48.7648454, lng: 20.3798108 },
    { lat: 48.7651641, lng: 20.3789924 },
    { lat: 48.7652598, lng: 20.3784682 },
    { lat: 48.7652823, lng: 20.3771473 },
    { lat: 48.7653847, lng: 20.37665 },
    { lat: 48.7655279, lng: 20.3763622 },
    { lat: 48.7658813, lng: 20.3760962 },
    { lat: 48.7664502, lng: 20.3759858 },
    { lat: 48.7668161, lng: 20.3756027 },
    { lat: 48.7670082, lng: 20.3754758 },
    { lat: 48.7678293, lng: 20.3751536 },
    { lat: 48.7680552, lng: 20.3748816 },
    { lat: 48.7686301, lng: 20.3744901 },
    { lat: 48.7687919, lng: 20.3740735 },
    { lat: 48.7688146, lng: 20.3738092 },
    { lat: 48.7689723, lng: 20.3733155 },
    { lat: 48.7691361, lng: 20.3720117 },
    { lat: 48.7690582, lng: 20.3710433 },
    { lat: 48.7687147, lng: 20.3699634 },
    { lat: 48.7687102, lng: 20.3695826 },
    { lat: 48.7688558, lng: 20.3688897 },
    { lat: 48.7688699, lng: 20.3683194 },
    { lat: 48.7688078, lng: 20.3683199 },
    { lat: 48.7690183, lng: 20.3671212 },
    { lat: 48.7690071, lng: 20.3655165 },
    { lat: 48.7689381, lng: 20.3648033 },
    { lat: 48.7689898, lng: 20.3645403 },
    { lat: 48.7691502, lng: 20.3643498 },
    { lat: 48.7695243, lng: 20.3641497 },
    { lat: 48.7700307, lng: 20.3635588 },
    { lat: 48.7702023, lng: 20.3632223 },
    { lat: 48.7704522, lng: 20.3621111 },
    { lat: 48.7707901, lng: 20.3617939 },
    { lat: 48.7717485, lng: 20.3617352 },
    { lat: 48.7723276, lng: 20.3617726 },
    { lat: 48.7726387, lng: 20.3616044 },
    { lat: 48.7733339, lng: 20.3610635 },
    { lat: 48.7736574, lng: 20.3606043 },
    { lat: 48.7738836, lng: 20.360106 },
    { lat: 48.7744615, lng: 20.3594101 },
    { lat: 48.7746194, lng: 20.3591005 },
    { lat: 48.7747574, lng: 20.3585931 },
    { lat: 48.7743591, lng: 20.3562364 },
    { lat: 48.7741567, lng: 20.3557502 },
    { lat: 48.7736431, lng: 20.3549902 },
    { lat: 48.7731681, lng: 20.355344 },
    { lat: 48.7729747, lng: 20.3553829 },
    { lat: 48.7727214, lng: 20.3552082 },
    { lat: 48.7724947, lng: 20.3552911 },
    { lat: 48.7718365, lng: 20.3558859 },
    { lat: 48.7718768, lng: 20.3560259 },
    { lat: 48.7714181, lng: 20.3565074 },
    { lat: 48.7712699, lng: 20.357222 },
    { lat: 48.7707784, lng: 20.3573208 },
    { lat: 48.7706677, lng: 20.3574277 },
    { lat: 48.7702249, lng: 20.3572494 },
    { lat: 48.7698069, lng: 20.3572711 },
    { lat: 48.7691553, lng: 20.3571349 },
    { lat: 48.7687554, lng: 20.3569282 },
    { lat: 48.7682782, lng: 20.3565539 },
    { lat: 48.7678824, lng: 20.3560749 },
    { lat: 48.7672429, lng: 20.3559651 },
    { lat: 48.7663334, lng: 20.3555666 },
    { lat: 48.7659437, lng: 20.3555946 },
    { lat: 48.765644, lng: 20.355503 },
    { lat: 48.7646514, lng: 20.3550469 },
    { lat: 48.7627347, lng: 20.3544105 },
    { lat: 48.7614054, lng: 20.3540424 },
    { lat: 48.7611579, lng: 20.3537317 },
    { lat: 48.7610281, lng: 20.3534616 },
    { lat: 48.7608972, lng: 20.3517396 },
    { lat: 48.7606735, lng: 20.3509035 },
    { lat: 48.7605943, lng: 20.3502538 },
    { lat: 48.7593528, lng: 20.3490458 },
    { lat: 48.7589802, lng: 20.3485591 },
    { lat: 48.7583573, lng: 20.3482321 },
    { lat: 48.758068, lng: 20.3479754 },
    { lat: 48.7570194, lng: 20.3473014 },
    { lat: 48.7560443, lng: 20.3465745 },
    { lat: 48.7554572, lng: 20.345462 },
    { lat: 48.7554594, lng: 20.344909 },
    { lat: 48.7553919, lng: 20.3443868 },
    { lat: 48.755215, lng: 20.3439497 },
    { lat: 48.7547806, lng: 20.3434015 },
    { lat: 48.7543769, lng: 20.3433613 },
    { lat: 48.7541775, lng: 20.3431538 },
    { lat: 48.7541248, lng: 20.3431881 },
    { lat: 48.7540277, lng: 20.3430404 },
    { lat: 48.7541645, lng: 20.3424126 },
    { lat: 48.7542652, lng: 20.3422221 },
    { lat: 48.7542627, lng: 20.3420428 },
    { lat: 48.7544045, lng: 20.341865 },
    { lat: 48.7542099, lng: 20.3411196 },
    { lat: 48.7541895, lng: 20.3408545 },
    { lat: 48.7540038, lng: 20.3404777 },
    { lat: 48.7533665, lng: 20.3398419 },
    { lat: 48.7528432, lng: 20.3395879 },
    { lat: 48.7522873, lng: 20.3389646 },
    { lat: 48.7515962, lng: 20.3390263 },
    { lat: 48.7512569, lng: 20.3389904 },
    { lat: 48.7506445, lng: 20.3390816 },
    { lat: 48.7502327, lng: 20.3389761 },
    { lat: 48.749813, lng: 20.339246 },
    { lat: 48.7495056, lng: 20.3394171 },
    { lat: 48.7493318, lng: 20.3397009 },
    { lat: 48.7498925, lng: 20.3414939 },
    { lat: 48.7495036, lng: 20.34262 },
    { lat: 48.7486393, lng: 20.3434164 },
    { lat: 48.7480023, lng: 20.3441213 },
    { lat: 48.7476019, lng: 20.3442488 },
    { lat: 48.7473488, lng: 20.3444026 },
    { lat: 48.745938, lng: 20.3471085 },
    { lat: 48.7456511, lng: 20.3470305 },
    { lat: 48.7452746, lng: 20.3472822 },
    { lat: 48.7448375, lng: 20.3479732 },
    { lat: 48.7443011, lng: 20.3484543 },
    { lat: 48.7441404, lng: 20.348497 },
    { lat: 48.7439625, lng: 20.3496853 },
    { lat: 48.7441259, lng: 20.3496322 },
    { lat: 48.7440627, lng: 20.3502055 },
    { lat: 48.7438546, lng: 20.3501569 },
    { lat: 48.7431939, lng: 20.3501943 },
    { lat: 48.7419578, lng: 20.3506684 },
    { lat: 48.7417742, lng: 20.350375 },
    { lat: 48.7400525, lng: 20.3505803 },
    { lat: 48.7399597, lng: 20.350799 },
    { lat: 48.7395365, lng: 20.3508069 },
    { lat: 48.7392882, lng: 20.3509658 },
    { lat: 48.7381479, lng: 20.3490879 },
    { lat: 48.7377184, lng: 20.3498362 },
    { lat: 48.7371458, lng: 20.3508377 },
    { lat: 48.7350707, lng: 20.355029 },
    { lat: 48.734814, lng: 20.3558186 },
    { lat: 48.7343821, lng: 20.3597923 },
    { lat: 48.7341593, lng: 20.3608408 },
    { lat: 48.7334796, lng: 20.3615526 },
    { lat: 48.732411, lng: 20.3623971 },
    { lat: 48.7314162, lng: 20.3645439 },
    { lat: 48.7305689, lng: 20.3657854 },
    { lat: 48.7298659, lng: 20.3669443 },
    { lat: 48.7291784, lng: 20.3678357 },
    { lat: 48.7289247, lng: 20.3681751 },
    { lat: 48.7285778, lng: 20.3690735 },
    { lat: 48.7280425, lng: 20.3692969 },
    { lat: 48.7272015, lng: 20.3723372 },
    { lat: 48.7269875, lng: 20.373389 },
    { lat: 48.7269316, lng: 20.3736667 },
  ],
  Štítnik: [
    { lat: 48.6825043, lng: 20.3469477 },
    { lat: 48.682572, lng: 20.3463698 },
    { lat: 48.6827704, lng: 20.3457685 },
    { lat: 48.682062, lng: 20.3449282 },
    { lat: 48.6805713, lng: 20.3427585 },
    { lat: 48.6803175, lng: 20.34159 },
    { lat: 48.6808257, lng: 20.3395722 },
    { lat: 48.679836, lng: 20.3400405 },
    { lat: 48.6792799, lng: 20.3415473 },
    { lat: 48.6790464, lng: 20.341507 },
    { lat: 48.6785975, lng: 20.3424159 },
    { lat: 48.678274, lng: 20.3424973 },
    { lat: 48.6781343, lng: 20.3428418 },
    { lat: 48.6780813, lng: 20.343076 },
    { lat: 48.678283, lng: 20.3436279 },
    { lat: 48.678391, lng: 20.3437712 },
    { lat: 48.6785185, lng: 20.343833 },
    { lat: 48.6786558, lng: 20.3441244 },
    { lat: 48.6783653, lng: 20.3456603 },
    { lat: 48.6781753, lng: 20.3455799 },
    { lat: 48.6777935, lng: 20.3460131 },
    { lat: 48.6777358, lng: 20.3459177 },
    { lat: 48.6777348, lng: 20.3459827 },
    { lat: 48.6772553, lng: 20.3460137 },
    { lat: 48.6764724, lng: 20.3446784 },
    { lat: 48.6762075, lng: 20.3430482 },
    { lat: 48.6762472, lng: 20.3424485 },
    { lat: 48.6762357, lng: 20.3416831 },
    { lat: 48.6761435, lng: 20.3411101 },
    { lat: 48.67618, lng: 20.3404811 },
    { lat: 48.6762421, lng: 20.3401667 },
    { lat: 48.6762096, lng: 20.3397694 },
    { lat: 48.676016, lng: 20.3387586 },
    { lat: 48.6759071, lng: 20.3384151 },
    { lat: 48.6757107, lng: 20.3380873 },
    { lat: 48.675555, lng: 20.3375898 },
    { lat: 48.6754926, lng: 20.3372294 },
    { lat: 48.6754897, lng: 20.3366641 },
    { lat: 48.6753615, lng: 20.3362319 },
    { lat: 48.675136, lng: 20.335762 },
    { lat: 48.6747005, lng: 20.3351642 },
    { lat: 48.6743909, lng: 20.3349636 },
    { lat: 48.6739311, lng: 20.3341728 },
    { lat: 48.6730977, lng: 20.3337697 },
    { lat: 48.6725916, lng: 20.3331469 },
    { lat: 48.6720776, lng: 20.3327892 },
    { lat: 48.6719002, lng: 20.3323653 },
    { lat: 48.671551, lng: 20.3319303 },
    { lat: 48.6712305, lng: 20.3313366 },
    { lat: 48.6706346, lng: 20.3307229 },
    { lat: 48.6706063, lng: 20.3304629 },
    { lat: 48.6705313, lng: 20.3302718 },
    { lat: 48.6701276, lng: 20.3297939 },
    { lat: 48.669943, lng: 20.3296525 },
    { lat: 48.6694133, lng: 20.3286873 },
    { lat: 48.6689338, lng: 20.3280893 },
    { lat: 48.6692202, lng: 20.3276324 },
    { lat: 48.6692945, lng: 20.3273162 },
    { lat: 48.6692377, lng: 20.3271609 },
    { lat: 48.6691452, lng: 20.3270936 },
    { lat: 48.6689228, lng: 20.3271106 },
    { lat: 48.6687518, lng: 20.3268283 },
    { lat: 48.668363, lng: 20.3264446 },
    { lat: 48.668158, lng: 20.3259843 },
    { lat: 48.6677982, lng: 20.3254491 },
    { lat: 48.6674723, lng: 20.3253506 },
    { lat: 48.6671207, lng: 20.3250569 },
    { lat: 48.6669875, lng: 20.3248464 },
    { lat: 48.6669697, lng: 20.3243008 },
    { lat: 48.6664018, lng: 20.3221343 },
    { lat: 48.6661995, lng: 20.3207611 },
    { lat: 48.6659307, lng: 20.3200173 },
    { lat: 48.6653331, lng: 20.3194535 },
    { lat: 48.6651511, lng: 20.3194016 },
    { lat: 48.6650673, lng: 20.3192755 },
    { lat: 48.6648127, lng: 20.318031 },
    { lat: 48.6646679, lng: 20.3177029 },
    { lat: 48.6641084, lng: 20.3168689 },
    { lat: 48.6638828, lng: 20.3161381 },
    { lat: 48.6637871, lng: 20.3156229 },
    { lat: 48.6638079, lng: 20.3148865 },
    { lat: 48.6636033, lng: 20.31438 },
    { lat: 48.6634088, lng: 20.3139566 },
    { lat: 48.662998, lng: 20.313445 },
    { lat: 48.6627651, lng: 20.3133325 },
    { lat: 48.6625549, lng: 20.3130556 },
    { lat: 48.6623042, lng: 20.3120883 },
    { lat: 48.6620501, lng: 20.3114398 },
    { lat: 48.6619359, lng: 20.3102164 },
    { lat: 48.6616924, lng: 20.3093789 },
    { lat: 48.6611976, lng: 20.3087059 },
    { lat: 48.6609956, lng: 20.3082366 },
    { lat: 48.6606266, lng: 20.3076722 },
    { lat: 48.6605253, lng: 20.3076399 },
    { lat: 48.660333, lng: 20.3073336 },
    { lat: 48.6596592, lng: 20.306832 },
    { lat: 48.6590367, lng: 20.3062368 },
    { lat: 48.6588165, lng: 20.3056227 },
    { lat: 48.6586434, lng: 20.3047085 },
    { lat: 48.6584659, lng: 20.3042021 },
    { lat: 48.658018, lng: 20.302389 },
    { lat: 48.6578903, lng: 20.3030766 },
    { lat: 48.6577164, lng: 20.30407 },
    { lat: 48.6574246, lng: 20.3047888 },
    { lat: 48.6572103, lng: 20.3054684 },
    { lat: 48.6572067, lng: 20.3058049 },
    { lat: 48.6570767, lng: 20.3062269 },
    { lat: 48.6567684, lng: 20.3065793 },
    { lat: 48.6563472, lng: 20.3069221 },
    { lat: 48.6560912, lng: 20.3072852 },
    { lat: 48.6559474, lng: 20.3073254 },
    { lat: 48.6553303, lng: 20.3077819 },
    { lat: 48.6547759, lng: 20.3085224 },
    { lat: 48.6548082, lng: 20.3092625 },
    { lat: 48.6548736, lng: 20.3097201 },
    { lat: 48.6548266, lng: 20.3104181 },
    { lat: 48.6548948, lng: 20.3109848 },
    { lat: 48.6547305, lng: 20.3117321 },
    { lat: 48.654507, lng: 20.3124662 },
    { lat: 48.6545151, lng: 20.3131083 },
    { lat: 48.6542238, lng: 20.3146727 },
    { lat: 48.6540284, lng: 20.3152343 },
    { lat: 48.6538518, lng: 20.3159546 },
    { lat: 48.6533174, lng: 20.3171533 },
    { lat: 48.653089, lng: 20.3175565 },
    { lat: 48.6514273, lng: 20.3202535 },
    { lat: 48.6509155, lng: 20.3208995 },
    { lat: 48.6507524, lng: 20.3213329 },
    { lat: 48.6507812, lng: 20.321611 },
    { lat: 48.6507527, lng: 20.3217752 },
    { lat: 48.6508364, lng: 20.3221015 },
    { lat: 48.6510087, lng: 20.3225053 },
    { lat: 48.6511523, lng: 20.3232156 },
    { lat: 48.6511757, lng: 20.3236701 },
    { lat: 48.6512436, lng: 20.323996 },
    { lat: 48.6512509, lng: 20.3257423 },
    { lat: 48.6511899, lng: 20.3265729 },
    { lat: 48.6515478, lng: 20.3273748 },
    { lat: 48.6526708, lng: 20.3284443 },
    { lat: 48.6528778, lng: 20.3290204 },
    { lat: 48.6529779, lng: 20.3296529 },
    { lat: 48.652939, lng: 20.3319173 },
    { lat: 48.6530009, lng: 20.3324083 },
    { lat: 48.6530343, lng: 20.3337862 },
    { lat: 48.6529044, lng: 20.3343833 },
    { lat: 48.6522675, lng: 20.3357223 },
    { lat: 48.6522106, lng: 20.3361697 },
    { lat: 48.651608, lng: 20.3375616 },
    { lat: 48.6512583, lng: 20.3381507 },
    { lat: 48.6508343, lng: 20.3398126 },
    { lat: 48.6509186, lng: 20.3412875 },
    { lat: 48.6508439, lng: 20.3415637 },
    { lat: 48.6507181, lng: 20.3417515 },
    { lat: 48.6499116, lng: 20.3422927 },
    { lat: 48.6495051, lng: 20.3427015 },
    { lat: 48.6485378, lng: 20.3434535 },
    { lat: 48.6479035, lng: 20.3440549 },
    { lat: 48.6455259, lng: 20.3472046 },
    { lat: 48.6453693, lng: 20.3474923 },
    { lat: 48.6450967, lng: 20.3484445 },
    { lat: 48.644861, lng: 20.3485839 },
    { lat: 48.6443006, lng: 20.3492919 },
    { lat: 48.643898, lng: 20.3494183 },
    { lat: 48.6425336, lng: 20.3501542 },
    { lat: 48.6421003, lng: 20.3502801 },
    { lat: 48.6415599, lng: 20.3507171 },
    { lat: 48.6409912, lng: 20.351555 },
    { lat: 48.6408175, lng: 20.352072 },
    { lat: 48.6405505, lng: 20.3524467 },
    { lat: 48.6402788, lng: 20.3530219 },
    { lat: 48.6396354, lng: 20.3538532 },
    { lat: 48.6395454, lng: 20.3539405 },
    { lat: 48.6394791, lng: 20.3539165 },
    { lat: 48.6391475, lng: 20.3548341 },
    { lat: 48.6386174, lng: 20.3555364 },
    { lat: 48.6382822, lng: 20.3555165 },
    { lat: 48.6379418, lng: 20.3559756 },
    { lat: 48.6378319, lng: 20.3560441 },
    { lat: 48.6375268, lng: 20.3565385 },
    { lat: 48.6372102, lng: 20.3569093 },
    { lat: 48.6369671, lng: 20.3570928 },
    { lat: 48.636512, lng: 20.3576374 },
    { lat: 48.6359775, lng: 20.3581093 },
    { lat: 48.6357454, lng: 20.3582281 },
    { lat: 48.6354837, lng: 20.3582163 },
    { lat: 48.6348066, lng: 20.3584081 },
    { lat: 48.6344632, lng: 20.3583821 },
    { lat: 48.6341264, lng: 20.3585518 },
    { lat: 48.6337523, lng: 20.3585196 },
    { lat: 48.6334095, lng: 20.3583936 },
    { lat: 48.633125, lng: 20.3584318 },
    { lat: 48.6324346, lng: 20.3583727 },
    { lat: 48.632136, lng: 20.3585721 },
    { lat: 48.6320645, lng: 20.3587005 },
    { lat: 48.632052, lng: 20.3587237 },
    { lat: 48.631675, lng: 20.3594583 },
    { lat: 48.6313843, lng: 20.3598606 },
    { lat: 48.6307769, lng: 20.3603168 },
    { lat: 48.6292855, lng: 20.3607427 },
    { lat: 48.6289305, lng: 20.3610249 },
    { lat: 48.6276315, lng: 20.361069 },
    { lat: 48.6272364, lng: 20.3608667 },
    { lat: 48.6260295, lng: 20.3613376 },
    { lat: 48.6256336, lng: 20.3617302 },
    { lat: 48.62472, lng: 20.3624764 },
    { lat: 48.6247204, lng: 20.3624853 },
    { lat: 48.6247467, lng: 20.3629642 },
    { lat: 48.6249905, lng: 20.3643413 },
    { lat: 48.6251034, lng: 20.3647037 },
    { lat: 48.6252564, lng: 20.3658909 },
    { lat: 48.625957, lng: 20.3699701 },
    { lat: 48.6261273, lng: 20.3706471 },
    { lat: 48.6264273, lng: 20.3727548 },
    { lat: 48.6268099, lng: 20.374705 },
    { lat: 48.6272711, lng: 20.377435 },
    { lat: 48.6273924, lng: 20.3777454 },
    { lat: 48.6276102, lng: 20.3794225 },
    { lat: 48.6275993, lng: 20.3800703 },
    { lat: 48.6275389, lng: 20.380607 },
    { lat: 48.6266753, lng: 20.3808718 },
    { lat: 48.6267048, lng: 20.3813448 },
    { lat: 48.6257623, lng: 20.3816681 },
    { lat: 48.6257737, lng: 20.3822264 },
    { lat: 48.6255901, lng: 20.3821649 },
    { lat: 48.6256759, lng: 20.3838713 },
    { lat: 48.6259357, lng: 20.3870728 },
    { lat: 48.6261552, lng: 20.3874551 },
    { lat: 48.626269, lng: 20.3874569 },
    { lat: 48.626281, lng: 20.3877381 },
    { lat: 48.6263736, lng: 20.3881803 },
    { lat: 48.6259728, lng: 20.3887977 },
    { lat: 48.6258857, lng: 20.3893731 },
    { lat: 48.6255168, lng: 20.3903519 },
    { lat: 48.6255371, lng: 20.3905534 },
    { lat: 48.6255963, lng: 20.3905717 },
    { lat: 48.6254568, lng: 20.3914831 },
    { lat: 48.6250816, lng: 20.3923001 },
    { lat: 48.6249219, lng: 20.3930929 },
    { lat: 48.6219077, lng: 20.399632 },
    { lat: 48.6221992, lng: 20.3996026 },
    { lat: 48.6223881, lng: 20.399773 },
    { lat: 48.6227355, lng: 20.399843 },
    { lat: 48.6230789, lng: 20.4001942 },
    { lat: 48.6232326, lng: 20.4005026 },
    { lat: 48.623443, lng: 20.4021425 },
    { lat: 48.623965, lng: 20.4029796 },
    { lat: 48.6256639, lng: 20.4041405 },
    { lat: 48.626192, lng: 20.4041716 },
    { lat: 48.62645, lng: 20.4046154 },
    { lat: 48.6266166, lng: 20.4047568 },
    { lat: 48.6269277, lng: 20.4049147 },
    { lat: 48.6272585, lng: 20.4049684 },
    { lat: 48.6276884, lng: 20.4052812 },
    { lat: 48.6280699, lng: 20.4058025 },
    { lat: 48.6282026, lng: 20.4061255 },
    { lat: 48.6285314, lng: 20.4065646 },
    { lat: 48.6299766, lng: 20.4074007 },
    { lat: 48.6306252, lng: 20.4076631 },
    { lat: 48.6313199, lng: 20.4073682 },
    { lat: 48.6315136, lng: 20.4072196 },
    { lat: 48.6316122, lng: 20.4075862 },
    { lat: 48.6321086, lng: 20.4078203 },
    { lat: 48.6322356, lng: 20.4077843 },
    { lat: 48.6324712, lng: 20.407528 },
    { lat: 48.6331268, lng: 20.4070921 },
    { lat: 48.6334095, lng: 20.4068296 },
    { lat: 48.6337483, lng: 20.4067039 },
    { lat: 48.6343118, lng: 20.4061025 },
    { lat: 48.6345905, lng: 20.4059113 },
    { lat: 48.6352218, lng: 20.4055204 },
    { lat: 48.6368579, lng: 20.4048083 },
    { lat: 48.6384901, lng: 20.4024779 },
    { lat: 48.6393883, lng: 20.4000033 },
    { lat: 48.6401251, lng: 20.3983964 },
    { lat: 48.6404557, lng: 20.3984469 },
    { lat: 48.641395, lng: 20.3978044 },
    { lat: 48.6416334, lng: 20.3974438 },
    { lat: 48.6422407, lng: 20.3976567 },
    { lat: 48.6430121, lng: 20.3985051 },
    { lat: 48.6433558, lng: 20.3986616 },
    { lat: 48.6441202, lng: 20.4007982 },
    { lat: 48.6450722, lng: 20.4011014 },
    { lat: 48.6454961, lng: 20.4014361 },
    { lat: 48.6458272, lng: 20.402176 },
    { lat: 48.6461656, lng: 20.4026011 },
    { lat: 48.6469334, lng: 20.4027712 },
    { lat: 48.6477056, lng: 20.4033775 },
    { lat: 48.6478635, lng: 20.4034157 },
    { lat: 48.6484336, lng: 20.4039524 },
    { lat: 48.6492152, lng: 20.404131 },
    { lat: 48.6501035, lng: 20.4047435 },
    { lat: 48.6506243, lng: 20.4054025 },
    { lat: 48.6516023, lng: 20.4060361 },
    { lat: 48.6515333, lng: 20.4067045 },
    { lat: 48.6521505, lng: 20.4068861 },
    { lat: 48.6526429, lng: 20.4071252 },
    { lat: 48.6534909, lng: 20.4078069 },
    { lat: 48.6535849, lng: 20.4083457 },
    { lat: 48.6538525, lng: 20.4084883 },
    { lat: 48.6547747, lng: 20.4076329 },
    { lat: 48.6552265, lng: 20.4073171 },
    { lat: 48.6554568, lng: 20.4072742 },
    { lat: 48.6556977, lng: 20.407063 },
    { lat: 48.6570958, lng: 20.4044958 },
    { lat: 48.6579804, lng: 20.4036025 },
    { lat: 48.6596314, lng: 20.4014351 },
    { lat: 48.6600078, lng: 20.4007365 },
    { lat: 48.6607264, lng: 20.4001533 },
    { lat: 48.6612917, lng: 20.3993017 },
    { lat: 48.6615645, lng: 20.3989896 },
    { lat: 48.6619714, lng: 20.398206 },
    { lat: 48.6622153, lng: 20.3973116 },
    { lat: 48.6625686, lng: 20.396544 },
    { lat: 48.6625056, lng: 20.3965139 },
    { lat: 48.6626653, lng: 20.3961024 },
    { lat: 48.6630026, lng: 20.3959696 },
    { lat: 48.6632259, lng: 20.3949277 },
    { lat: 48.6636291, lng: 20.3950117 },
    { lat: 48.663798, lng: 20.3947198 },
    { lat: 48.6638359, lng: 20.3944743 },
    { lat: 48.663414, lng: 20.3940193 },
    { lat: 48.663236, lng: 20.3937221 },
    { lat: 48.6633956, lng: 20.3925707 },
    { lat: 48.6641447, lng: 20.3918172 },
    { lat: 48.6642006, lng: 20.391657 },
    { lat: 48.6651875, lng: 20.3911138 },
    { lat: 48.6671603, lng: 20.3897657 },
    { lat: 48.6682801, lng: 20.3893954 },
    { lat: 48.6695071, lng: 20.3892119 },
    { lat: 48.6697732, lng: 20.3890742 },
    { lat: 48.6701809, lng: 20.3889872 },
    { lat: 48.6704726, lng: 20.3888416 },
    { lat: 48.6706315, lng: 20.3886727 },
    { lat: 48.6710432, lng: 20.3884787 },
    { lat: 48.6713975, lng: 20.3884395 },
    { lat: 48.6715233, lng: 20.3887847 },
    { lat: 48.6717594, lng: 20.3889767 },
    { lat: 48.67199, lng: 20.389322 },
    { lat: 48.6727254, lng: 20.3899691 },
    { lat: 48.6730625, lng: 20.3904537 },
    { lat: 48.67404, lng: 20.3908604 },
    { lat: 48.6745717, lng: 20.3908081 },
    { lat: 48.6748923, lng: 20.3905655 },
    { lat: 48.6759907, lng: 20.3912527 },
    { lat: 48.6764027, lng: 20.3916574 },
    { lat: 48.6765937, lng: 20.3919995 },
    { lat: 48.6769469, lng: 20.3924581 },
    { lat: 48.6773892, lng: 20.3928035 },
    { lat: 48.6775147, lng: 20.3930237 },
    { lat: 48.6776558, lng: 20.3937618 },
    { lat: 48.6777187, lng: 20.3944037 },
    { lat: 48.6777157, lng: 20.3949705 },
    { lat: 48.6778245, lng: 20.3956651 },
    { lat: 48.6778983, lng: 20.395939 },
    { lat: 48.6780877, lng: 20.3961836 },
    { lat: 48.6781447, lng: 20.3966739 },
    { lat: 48.6779844, lng: 20.3971882 },
    { lat: 48.6780475, lng: 20.3973926 },
    { lat: 48.678632, lng: 20.3979287 },
    { lat: 48.6790878, lng: 20.3980905 },
    { lat: 48.6795307, lng: 20.3983671 },
    { lat: 48.6800882, lng: 20.3989255 },
    { lat: 48.6807294, lng: 20.3990871 },
    { lat: 48.680801, lng: 20.3992194 },
    { lat: 48.6809927, lng: 20.3993769 },
    { lat: 48.6815388, lng: 20.3996663 },
    { lat: 48.6816815, lng: 20.3998387 },
    { lat: 48.6817984, lng: 20.4001838 },
    { lat: 48.6820163, lng: 20.4005196 },
    { lat: 48.6827824, lng: 20.4015028 },
    { lat: 48.6834616, lng: 20.4019469 },
    { lat: 48.6838986, lng: 20.4016828 },
    { lat: 48.6851979, lng: 20.40121 },
    { lat: 48.687128, lng: 20.4010059 },
    { lat: 48.6883391, lng: 20.4008126 },
    { lat: 48.6886454, lng: 20.4006636 },
    { lat: 48.6887856, lng: 20.4006841 },
    { lat: 48.6890867, lng: 20.4002522 },
    { lat: 48.6893329, lng: 20.4002741 },
    { lat: 48.6897247, lng: 20.4000688 },
    { lat: 48.6904887, lng: 20.3998765 },
    { lat: 48.6907367, lng: 20.3999065 },
    { lat: 48.6916246, lng: 20.3988654 },
    { lat: 48.69205, lng: 20.3987712 },
    { lat: 48.6921765, lng: 20.3963189 },
    { lat: 48.6921393, lng: 20.3959326 },
    { lat: 48.6919028, lng: 20.3954351 },
    { lat: 48.6914742, lng: 20.3942452 },
    { lat: 48.6911313, lng: 20.3929358 },
    { lat: 48.6910535, lng: 20.390149 },
    { lat: 48.6910184, lng: 20.3896182 },
    { lat: 48.6909844, lng: 20.3895869 },
    { lat: 48.6910329, lng: 20.3892092 },
    { lat: 48.6909582, lng: 20.3887267 },
    { lat: 48.6908192, lng: 20.388396 },
    { lat: 48.6902771, lng: 20.3874417 },
    { lat: 48.6901726, lng: 20.3868895 },
    { lat: 48.690082, lng: 20.3856821 },
    { lat: 48.6901234, lng: 20.3848498 },
    { lat: 48.6897551, lng: 20.3835047 },
    { lat: 48.689653, lng: 20.3828742 },
    { lat: 48.6897246, lng: 20.3822767 },
    { lat: 48.6897112, lng: 20.3813697 },
    { lat: 48.6894302, lng: 20.3795154 },
    { lat: 48.6891831, lng: 20.3782712 },
    { lat: 48.6889009, lng: 20.3758899 },
    { lat: 48.6887255, lng: 20.3753992 },
    { lat: 48.689085, lng: 20.374271 },
    { lat: 48.6896167, lng: 20.3730797 },
    { lat: 48.6898216, lng: 20.3719083 },
    { lat: 48.6897937, lng: 20.3711003 },
    { lat: 48.6899446, lng: 20.3704594 },
    { lat: 48.6901002, lng: 20.3702318 },
    { lat: 48.6901107, lng: 20.369892 },
    { lat: 48.6903673, lng: 20.369215 },
    { lat: 48.6903354, lng: 20.3688232 },
    { lat: 48.6903745, lng: 20.3685842 },
    { lat: 48.6903105, lng: 20.3683867 },
    { lat: 48.6899712, lng: 20.3678809 },
    { lat: 48.6900065, lng: 20.36751 },
    { lat: 48.6899611, lng: 20.367062 },
    { lat: 48.6899712, lng: 20.3661569 },
    { lat: 48.6900118, lng: 20.3658703 },
    { lat: 48.6899629, lng: 20.3651016 },
    { lat: 48.6898232, lng: 20.3648933 },
    { lat: 48.6897963, lng: 20.3645207 },
    { lat: 48.6891076, lng: 20.3638893 },
    { lat: 48.6889814, lng: 20.3636267 },
    { lat: 48.6888361, lng: 20.3636524 },
    { lat: 48.6883787, lng: 20.3630113 },
    { lat: 48.6882438, lng: 20.3629576 },
    { lat: 48.6881203, lng: 20.363028 },
    { lat: 48.6875565, lng: 20.3628625 },
    { lat: 48.6871672, lng: 20.3625778 },
    { lat: 48.6870973, lng: 20.362605 },
    { lat: 48.6867387, lng: 20.3620401 },
    { lat: 48.6867471, lng: 20.3616715 },
    { lat: 48.6865941, lng: 20.3614127 },
    { lat: 48.6865901, lng: 20.361139 },
    { lat: 48.686393, lng: 20.3610463 },
    { lat: 48.6863293, lng: 20.3606574 },
    { lat: 48.6861099, lng: 20.3606026 },
    { lat: 48.6859529, lng: 20.3601496 },
    { lat: 48.6859339, lng: 20.3596883 },
    { lat: 48.685801, lng: 20.3595422 },
    { lat: 48.6857738, lng: 20.3593375 },
    { lat: 48.6856107, lng: 20.3592222 },
    { lat: 48.6854255, lng: 20.3588326 },
    { lat: 48.6851078, lng: 20.3585371 },
    { lat: 48.6849534, lng: 20.3581897 },
    { lat: 48.6847553, lng: 20.3579845 },
    { lat: 48.6839996, lng: 20.3568181 },
    { lat: 48.683903, lng: 20.3564197 },
    { lat: 48.6839411, lng: 20.3558917 },
    { lat: 48.6837673, lng: 20.3557685 },
    { lat: 48.683535, lng: 20.3553514 },
    { lat: 48.6834876, lng: 20.3549122 },
    { lat: 48.6835755, lng: 20.3547933 },
    { lat: 48.6834792, lng: 20.3545805 },
    { lat: 48.6832249, lng: 20.354496 },
    { lat: 48.6829749, lng: 20.3541471 },
    { lat: 48.6827456, lng: 20.3541296 },
    { lat: 48.6824099, lng: 20.3539205 },
    { lat: 48.6822857, lng: 20.3537419 },
    { lat: 48.6819681, lng: 20.3528342 },
    { lat: 48.6820072, lng: 20.3528026 },
    { lat: 48.6818568, lng: 20.3525522 },
    { lat: 48.6817962, lng: 20.3520665 },
    { lat: 48.6821015, lng: 20.3516617 },
    { lat: 48.6822439, lng: 20.3516167 },
    { lat: 48.6824038, lng: 20.3517287 },
    { lat: 48.682639, lng: 20.3514353 },
    { lat: 48.6827091, lng: 20.3511814 },
    { lat: 48.6822859, lng: 20.3498511 },
    { lat: 48.6819727, lng: 20.3490663 },
    { lat: 48.6817222, lng: 20.3486184 },
    { lat: 48.682041, lng: 20.3477489 },
    { lat: 48.6823885, lng: 20.3471565 },
    { lat: 48.6825043, lng: 20.3469477 },
  ],
  Kováčová: [
    { lat: 48.6144156, lng: 20.677735 },
    { lat: 48.6143127, lng: 20.6778782 },
    { lat: 48.6141297, lng: 20.6782035 },
    { lat: 48.6136779, lng: 20.6797302 },
    { lat: 48.6134356, lng: 20.6800628 },
    { lat: 48.6133106, lng: 20.6806931 },
    { lat: 48.6132441, lng: 20.6817257 },
    { lat: 48.6133432, lng: 20.6832871 },
    { lat: 48.6129117, lng: 20.6842851 },
    { lat: 48.6128911, lng: 20.6853979 },
    { lat: 48.6126488, lng: 20.685897 },
    { lat: 48.6125746, lng: 20.6868391 },
    { lat: 48.6126107, lng: 20.6880611 },
    { lat: 48.6122908, lng: 20.6886522 },
    { lat: 48.6118778, lng: 20.6891631 },
    { lat: 48.6120169, lng: 20.6894957 },
    { lat: 48.6122528, lng: 20.6897892 },
    { lat: 48.612436, lng: 20.6902201 },
    { lat: 48.6134071, lng: 20.6915892 },
    { lat: 48.6137092, lng: 20.6925405 },
    { lat: 48.6131808, lng: 20.6970488 },
    { lat: 48.6133484, lng: 20.6994029 },
    { lat: 48.6133374, lng: 20.7000713 },
    { lat: 48.6127707, lng: 20.7023268 },
    { lat: 48.6125732, lng: 20.7041392 },
    { lat: 48.61165, lng: 20.7038262 },
    { lat: 48.6094711, lng: 20.704943 },
    { lat: 48.6081798, lng: 20.705037 },
    { lat: 48.6074151, lng: 20.70516 },
    { lat: 48.6079278, lng: 20.706712 },
    { lat: 48.6083656, lng: 20.7078465 },
    { lat: 48.6084883, lng: 20.7084082 },
    { lat: 48.6086161, lng: 20.7100041 },
    { lat: 48.6088131, lng: 20.7107252 },
    { lat: 48.6093552, lng: 20.7115969 },
    { lat: 48.6096968, lng: 20.712429 },
    { lat: 48.6097613, lng: 20.7131307 },
    { lat: 48.6100056, lng: 20.7135659 },
    { lat: 48.6107675, lng: 20.7149232 },
    { lat: 48.6112702, lng: 20.7145921 },
    { lat: 48.6120571, lng: 20.7140526 },
    { lat: 48.6130637, lng: 20.7135486 },
    { lat: 48.6163206, lng: 20.7132665 },
    { lat: 48.6172594, lng: 20.7122855 },
    { lat: 48.6184253, lng: 20.7119545 },
    { lat: 48.6191767, lng: 20.711558 },
    { lat: 48.6197029, lng: 20.7110004 },
    { lat: 48.6201805, lng: 20.7109438 },
    { lat: 48.6207974, lng: 20.7103835 },
    { lat: 48.6210358, lng: 20.710167 },
    { lat: 48.6213961, lng: 20.7098398 },
    { lat: 48.6219671, lng: 20.709031 },
    { lat: 48.6220992, lng: 20.7088439 },
    { lat: 48.6227588, lng: 20.7076399 },
    { lat: 48.6231636, lng: 20.7071234 },
    { lat: 48.6237754, lng: 20.7065982 },
    { lat: 48.6245327, lng: 20.7064331 },
    { lat: 48.6255795, lng: 20.7055451 },
    { lat: 48.6263213, lng: 20.704126 },
    { lat: 48.6268788, lng: 20.7039447 },
    { lat: 48.6277869, lng: 20.7040245 },
    { lat: 48.6296782, lng: 20.7046166 },
    { lat: 48.6311515, lng: 20.7046366 },
    { lat: 48.6315992, lng: 20.7045376 },
    { lat: 48.632719, lng: 20.7044628 },
    { lat: 48.6330313, lng: 20.705292 },
    { lat: 48.6341559, lng: 20.7058583 },
    { lat: 48.6350062, lng: 20.7059265 },
    { lat: 48.6352222, lng: 20.7062075 },
    { lat: 48.6355326, lng: 20.706199 },
    { lat: 48.6356079, lng: 20.7062862 },
    { lat: 48.6358296, lng: 20.7063187 },
    { lat: 48.6359492, lng: 20.7064488 },
    { lat: 48.6363997, lng: 20.7065207 },
    { lat: 48.6366377, lng: 20.7069093 },
    { lat: 48.637224, lng: 20.7071863 },
    { lat: 48.6374551, lng: 20.7074832 },
    { lat: 48.6378969, lng: 20.7077783 },
    { lat: 48.6380771, lng: 20.7080929 },
    { lat: 48.6383011, lng: 20.7080637 },
    { lat: 48.6384367, lng: 20.7083937 },
    { lat: 48.6384491, lng: 20.7085489 },
    { lat: 48.6386344, lng: 20.7087239 },
    { lat: 48.6387236, lng: 20.7088897 },
    { lat: 48.6387709, lng: 20.7091767 },
    { lat: 48.6389654, lng: 20.7093581 },
    { lat: 48.6390951, lng: 20.7097711 },
    { lat: 48.6392417, lng: 20.7098083 },
    { lat: 48.6393215, lng: 20.7099012 },
    { lat: 48.6395418, lng: 20.7098939 },
    { lat: 48.6397368, lng: 20.7098048 },
    { lat: 48.6398479, lng: 20.7098911 },
    { lat: 48.6403704, lng: 20.7096717 },
    { lat: 48.6404403, lng: 20.7097408 },
    { lat: 48.6407068, lng: 20.7094728 },
    { lat: 48.6409408, lng: 20.709472 },
    { lat: 48.6410545, lng: 20.7095983 },
    { lat: 48.6415009, lng: 20.7095215 },
    { lat: 48.6416764, lng: 20.7096727 },
    { lat: 48.6420226, lng: 20.7096546 },
    { lat: 48.6425326, lng: 20.7097854 },
    { lat: 48.6428182, lng: 20.7096522 },
    { lat: 48.6430096, lng: 20.7098054 },
    { lat: 48.6432513, lng: 20.7102396 },
    { lat: 48.6434348, lng: 20.7103643 },
    { lat: 48.6439898, lng: 20.7104267 },
    { lat: 48.6440681, lng: 20.7105371 },
    { lat: 48.6442302, lng: 20.7105389 },
    { lat: 48.6444155, lng: 20.7106548 },
    { lat: 48.6445006, lng: 20.7108323 },
    { lat: 48.6447042, lng: 20.7109845 },
    { lat: 48.6448939, lng: 20.7113141 },
    { lat: 48.6451036, lng: 20.7114466 },
    { lat: 48.64545, lng: 20.7114675 },
    { lat: 48.6455031, lng: 20.711633 },
    { lat: 48.6456293, lng: 20.7116676 },
    { lat: 48.6456521, lng: 20.7118299 },
    { lat: 48.6459245, lng: 20.7118936 },
    { lat: 48.646205, lng: 20.7124949 },
    { lat: 48.646606, lng: 20.7127599 },
    { lat: 48.6465848, lng: 20.7128852 },
    { lat: 48.6468001, lng: 20.7135256 },
    { lat: 48.6469395, lng: 20.7136827 },
    { lat: 48.6469508, lng: 20.7138399 },
    { lat: 48.6471529, lng: 20.7142621 },
    { lat: 48.6478605, lng: 20.7144115 },
    { lat: 48.6479814, lng: 20.7143864 },
    { lat: 48.6484723, lng: 20.7148307 },
    { lat: 48.6486583, lng: 20.7149313 },
    { lat: 48.6487789, lng: 20.7152241 },
    { lat: 48.6490969, lng: 20.7156142 },
    { lat: 48.6491679, lng: 20.715999 },
    { lat: 48.6493666, lng: 20.7159814 },
    { lat: 48.6496885, lng: 20.7164541 },
    { lat: 48.6497649, lng: 20.716763 },
    { lat: 48.6497321, lng: 20.7169818 },
    { lat: 48.6499338, lng: 20.7174318 },
    { lat: 48.6500728, lng: 20.7175986 },
    { lat: 48.6501111, lng: 20.7177938 },
    { lat: 48.650222, lng: 20.7180013 },
    { lat: 48.6506178, lng: 20.7180179 },
    { lat: 48.6507922, lng: 20.7181384 },
    { lat: 48.6511549, lng: 20.717989 },
    { lat: 48.6518854, lng: 20.7179521 },
    { lat: 48.6522109, lng: 20.7180517 },
    { lat: 48.6524264, lng: 20.7182191 },
    { lat: 48.65255, lng: 20.7181193 },
    { lat: 48.6529151, lng: 20.7181306 },
    { lat: 48.6530207, lng: 20.7179618 },
    { lat: 48.6531876, lng: 20.7179725 },
    { lat: 48.6532704, lng: 20.7180854 },
    { lat: 48.6537219, lng: 20.7179904 },
    { lat: 48.6538868, lng: 20.7179133 },
    { lat: 48.6539989, lng: 20.7177411 },
    { lat: 48.6541087, lng: 20.717698 },
    { lat: 48.6541174, lng: 20.717499 },
    { lat: 48.6542017, lng: 20.7174006 },
    { lat: 48.6547676, lng: 20.7175452 },
    { lat: 48.654967, lng: 20.717294 },
    { lat: 48.6551137, lng: 20.7173405 },
    { lat: 48.655505, lng: 20.7172924 },
    { lat: 48.6555452, lng: 20.7171218 },
    { lat: 48.6555973, lng: 20.7171001 },
    { lat: 48.6557143, lng: 20.7171928 },
    { lat: 48.6559723, lng: 20.7171063 },
    { lat: 48.6561073, lng: 20.7168482 },
    { lat: 48.6561761, lng: 20.7165688 },
    { lat: 48.6565124, lng: 20.7165255 },
    { lat: 48.6568127, lng: 20.7165852 },
    { lat: 48.6569679, lng: 20.7164191 },
    { lat: 48.6571355, lng: 20.7163539 },
    { lat: 48.6577065, lng: 20.7157869 },
    { lat: 48.6577835, lng: 20.715771 },
    { lat: 48.6580038, lng: 20.7158892 },
    { lat: 48.6581854, lng: 20.7157074 },
    { lat: 48.6583445, lng: 20.7156589 },
    { lat: 48.6585481, lng: 20.7159048 },
    { lat: 48.658628, lng: 20.7158688 },
    { lat: 48.6587998, lng: 20.7161426 },
    { lat: 48.6594101, lng: 20.7167785 },
    { lat: 48.6600099, lng: 20.7180047 },
    { lat: 48.6605152, lng: 20.7186628 },
    { lat: 48.6608191, lng: 20.7188813 },
    { lat: 48.6609366, lng: 20.7193383 },
    { lat: 48.6612325, lng: 20.7196202 },
    { lat: 48.6615691, lng: 20.7201894 },
    { lat: 48.6619586, lng: 20.7206258 },
    { lat: 48.6625283, lng: 20.7211041 },
    { lat: 48.6630329, lng: 20.7211747 },
    { lat: 48.6633304, lng: 20.7213094 },
    { lat: 48.663477, lng: 20.7214717 },
    { lat: 48.6639371, lng: 20.721777 },
    { lat: 48.6640489, lng: 20.7219488 },
    { lat: 48.6646477, lng: 20.7224484 },
    { lat: 48.6650959, lng: 20.7227069 },
    { lat: 48.6654702, lng: 20.7237055 },
    { lat: 48.6661297, lng: 20.7245186 },
    { lat: 48.6662922, lng: 20.7245977 },
    { lat: 48.6673142, lng: 20.7255741 },
    { lat: 48.6682228, lng: 20.7269077 },
    { lat: 48.669184, lng: 20.728163 },
    { lat: 48.6699125, lng: 20.7269533 },
    { lat: 48.670159, lng: 20.725973 },
    { lat: 48.670391, lng: 20.724999 },
    { lat: 48.670257, lng: 20.722937 },
    { lat: 48.670221, lng: 20.722385 },
    { lat: 48.670227, lng: 20.721288 },
    { lat: 48.670446, lng: 20.721022 },
    { lat: 48.670694, lng: 20.720261 },
    { lat: 48.6708299, lng: 20.719677 },
    { lat: 48.671027, lng: 20.71951 },
    { lat: 48.671056, lng: 20.719485 },
    { lat: 48.671161, lng: 20.718669 },
    { lat: 48.67113, lng: 20.71793 },
    { lat: 48.67134, lng: 20.717023 },
    { lat: 48.671478, lng: 20.716126 },
    { lat: 48.671765, lng: 20.715288 },
    { lat: 48.671906, lng: 20.714781 },
    { lat: 48.67206, lng: 20.714323 },
    { lat: 48.672214, lng: 20.714098 },
    { lat: 48.672429, lng: 20.713152 },
    { lat: 48.672504, lng: 20.712659 },
    { lat: 48.672746, lng: 20.712252 },
    { lat: 48.672829, lng: 20.711954 },
    { lat: 48.672872, lng: 20.711743 },
    { lat: 48.673114, lng: 20.711436 },
    { lat: 48.673552, lng: 20.710961 },
    { lat: 48.673712, lng: 20.710483 },
    { lat: 48.674366, lng: 20.708776 },
    { lat: 48.674821, lng: 20.707903 },
    { lat: 48.67516, lng: 20.706937 },
    { lat: 48.675361, lng: 20.706489 },
    { lat: 48.675493, lng: 20.706356 },
    { lat: 48.675981, lng: 20.70562 },
    { lat: 48.6761287, lng: 20.7051457 },
    { lat: 48.676137, lng: 20.705119 },
    { lat: 48.676528, lng: 20.703859 },
    { lat: 48.676914, lng: 20.703432 },
    { lat: 48.677279, lng: 20.702999 },
    { lat: 48.677431, lng: 20.702865 },
    { lat: 48.677673, lng: 20.702739 },
    { lat: 48.67779, lng: 20.702621 },
    { lat: 48.677999, lng: 20.702196 },
    { lat: 48.678453, lng: 20.701671 },
    { lat: 48.678835, lng: 20.701138 },
    { lat: 48.679633, lng: 20.700004 },
    { lat: 48.679946, lng: 20.699638 },
    { lat: 48.680294, lng: 20.699297 },
    { lat: 48.680781, lng: 20.698781 },
    { lat: 48.680905, lng: 20.698685 },
    { lat: 48.6795965, lng: 20.6971245 },
    { lat: 48.6790926, lng: 20.6969144 },
    { lat: 48.678406, lng: 20.6973933 },
    { lat: 48.6772133, lng: 20.6978807 },
    { lat: 48.6766778, lng: 20.69698 },
    { lat: 48.6756075, lng: 20.6965525 },
    { lat: 48.6751572, lng: 20.6966798 },
    { lat: 48.6732086, lng: 20.6965866 },
    { lat: 48.6721807, lng: 20.696462 },
    { lat: 48.6699876, lng: 20.6957003 },
    { lat: 48.6695101, lng: 20.6953197 },
    { lat: 48.6682818, lng: 20.6946313 },
    { lat: 48.6677579, lng: 20.6940518 },
    { lat: 48.6671372, lng: 20.6935722 },
    { lat: 48.6654564, lng: 20.6918406 },
    { lat: 48.6648858, lng: 20.6913762 },
    { lat: 48.6643135, lng: 20.6909567 },
    { lat: 48.6634788, lng: 20.6905989 },
    { lat: 48.6632741, lng: 20.6906659 },
    { lat: 48.6622412, lng: 20.6887716 },
    { lat: 48.6615956, lng: 20.6872157 },
    { lat: 48.6613212, lng: 20.6867855 },
    { lat: 48.6611177, lng: 20.6862316 },
    { lat: 48.6607249, lng: 20.6858873 },
    { lat: 48.6606268, lng: 20.6854744 },
    { lat: 48.6604653, lng: 20.6850524 },
    { lat: 48.6604377, lng: 20.6845639 },
    { lat: 48.659778, lng: 20.6830659 },
    { lat: 48.6594261, lng: 20.6826987 },
    { lat: 48.658875, lng: 20.6822467 },
    { lat: 48.65851, lng: 20.6820494 },
    { lat: 48.6580025, lng: 20.6815279 },
    { lat: 48.6573813, lng: 20.6812857 },
    { lat: 48.6568493, lng: 20.6806413 },
    { lat: 48.6563979, lng: 20.6802303 },
    { lat: 48.6559253, lng: 20.68002 },
    { lat: 48.6553447, lng: 20.6791866 },
    { lat: 48.6545239, lng: 20.6788048 },
    { lat: 48.6539902, lng: 20.6786463 },
    { lat: 48.6536661, lng: 20.678712 },
    { lat: 48.6532475, lng: 20.678565 },
    { lat: 48.6529734, lng: 20.6786124 },
    { lat: 48.6526822, lng: 20.678465 },
    { lat: 48.6522345, lng: 20.6785391 },
    { lat: 48.652075, lng: 20.6784553 },
    { lat: 48.6519126, lng: 20.6785426 },
    { lat: 48.6517201, lng: 20.6788262 },
    { lat: 48.6514247, lng: 20.6788767 },
    { lat: 48.6512205, lng: 20.6788026 },
    { lat: 48.6510951, lng: 20.6785672 },
    { lat: 48.6509357, lng: 20.6784849 },
    { lat: 48.6506326, lng: 20.6787625 },
    { lat: 48.6502304, lng: 20.6790066 },
    { lat: 48.6497679, lng: 20.6791096 },
    { lat: 48.6495625, lng: 20.6792824 },
    { lat: 48.6493818, lng: 20.6793274 },
    { lat: 48.6490181, lng: 20.6795719 },
    { lat: 48.6487117, lng: 20.6798826 },
    { lat: 48.64872, lng: 20.6800556 },
    { lat: 48.6477051, lng: 20.6802591 },
    { lat: 48.6475543, lng: 20.6796337 },
    { lat: 48.6457329, lng: 20.680235 },
    { lat: 48.6443898, lng: 20.6812055 },
    { lat: 48.6410966, lng: 20.6818595 },
    { lat: 48.6404051, lng: 20.6834161 },
    { lat: 48.6401134, lng: 20.6839188 },
    { lat: 48.6395824, lng: 20.6859934 },
    { lat: 48.6400969, lng: 20.6920821 },
    { lat: 48.636779, lng: 20.6928345 },
    { lat: 48.6362293, lng: 20.6931107 },
    { lat: 48.6353826, lng: 20.6932933 },
    { lat: 48.6352205, lng: 20.6934497 },
    { lat: 48.6351099, lng: 20.6934786 },
    { lat: 48.6350044, lng: 20.6934242 },
    { lat: 48.6346741, lng: 20.6935028 },
    { lat: 48.6351294, lng: 20.692359 },
    { lat: 48.6351389, lng: 20.6907999 },
    { lat: 48.6330652, lng: 20.6867521 },
    { lat: 48.6326676, lng: 20.6864654 },
    { lat: 48.6305794, lng: 20.6852479 },
    { lat: 48.6286665, lng: 20.6878476 },
    { lat: 48.6328498, lng: 20.6912277 },
    { lat: 48.6325701, lng: 20.6925066 },
    { lat: 48.6319312, lng: 20.6923612 },
    { lat: 48.6318071, lng: 20.6924532 },
    { lat: 48.6312831, lng: 20.6923739 },
    { lat: 48.630451, lng: 20.6921169 },
    { lat: 48.6300988, lng: 20.6920911 },
    { lat: 48.6299922, lng: 20.6919806 },
    { lat: 48.6298081, lng: 20.6919316 },
    { lat: 48.629434, lng: 20.6919205 },
    { lat: 48.6293857, lng: 20.6917635 },
    { lat: 48.6292917, lng: 20.6916555 },
    { lat: 48.6290294, lng: 20.6916894 },
    { lat: 48.6286948, lng: 20.6915245 },
    { lat: 48.6279468, lng: 20.6916703 },
    { lat: 48.6274782, lng: 20.691853 },
    { lat: 48.6273403, lng: 20.6918703 },
    { lat: 48.62711, lng: 20.6917549 },
    { lat: 48.6268369, lng: 20.6918317 },
    { lat: 48.6266483, lng: 20.6917819 },
    { lat: 48.6263, lng: 20.6918328 },
    { lat: 48.625308, lng: 20.6915166 },
    { lat: 48.6249322, lng: 20.691432 },
    { lat: 48.6247994, lng: 20.6914599 },
    { lat: 48.6238849, lng: 20.6909495 },
    { lat: 48.6226842, lng: 20.6917499 },
    { lat: 48.6224716, lng: 20.6917555 },
    { lat: 48.6221671, lng: 20.6919295 },
    { lat: 48.6219702, lng: 20.6919626 },
    { lat: 48.6218482, lng: 20.6920804 },
    { lat: 48.6212691, lng: 20.6910316 },
    { lat: 48.6209502, lng: 20.6907093 },
    { lat: 48.6207845, lng: 20.690393 },
    { lat: 48.6200291, lng: 20.6893227 },
    { lat: 48.6191741, lng: 20.6872689 },
    { lat: 48.6186409, lng: 20.6863766 },
    { lat: 48.6181741, lng: 20.6863265 },
    { lat: 48.6179006, lng: 20.6860475 },
    { lat: 48.6177147, lng: 20.6859762 },
    { lat: 48.6173803, lng: 20.6852901 },
    { lat: 48.617168, lng: 20.6842329 },
    { lat: 48.6167599, lng: 20.6835667 },
    { lat: 48.6165285, lng: 20.6826676 },
    { lat: 48.6162517, lng: 20.6822405 },
    { lat: 48.615981, lng: 20.6811607 },
    { lat: 48.615533, lng: 20.6802822 },
    { lat: 48.6153666, lng: 20.6795379 },
    { lat: 48.6152263, lng: 20.6791153 },
    { lat: 48.6150226, lng: 20.6788641 },
    { lat: 48.61486, lng: 20.6783163 },
    { lat: 48.6146561, lng: 20.6780985 },
    { lat: 48.6144237, lng: 20.6777126 },
    { lat: 48.6144156, lng: 20.677735 },
  ],
};

export default UnitsRožňava;
