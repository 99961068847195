import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { motion } from "framer-motion";

export default function Properties({
  office,
  size,
  subMenu,
  setSubMenu,
  previous,
  setForSale,
  forSale,
  navSmall,
  setNavSmall,
  viewSale,
  setViewSale,
  indx,
  indxPrev,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return subMenu.lv ? (
    <motion.div
      className={clsx("flex flex-col gap-4 p-2", {
        "grid grid-cols-1 sm:grid-cols-2 gap-4": !isSmallDevice && size.full,
      })}
      initial={{ x: previous !== "ow" ? -500 : 500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: previous === "" ? null : 0.5 }}
    >
      {office?.items?.length === undefined ? (
        <div className="w-full flex justify-center items-center">Realitná kacelária nemá nehnuteľnosti</div>
      ) : (
        office?.items?.map((item, index) =>
          office.name === item.owner ? (
            <div
              key={index}
              className={clsx(
                "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200",
                {},
              )}
              onClick={() => {
                indxPrev.current = indx.current;
                indx.current = index;
                setNavSmall({ ...navSmall, properties: true, subjects: false });
                setSubMenu({
                  ...subMenu,
                  lv: false,
                  interest: false,
                  owner: true,
                  disp: false,
                  rating: false,
                  share: false,
                });
                setForSale({ ...forSale, menu: true });
                setViewSale({ ...viewSale, view: true });
              }}
            >
              <div className="flex flex-row">
                <div className="flex flex-col w-1/3">
                  <img src={item?.img[1]?.img} alt="photo1" className="w-full h-24 rounded-l-lg object-cover" />
                </div>
                <div className="flex flex-col justify-between w-2/3 p-1">
                  <div className="w-full h-2/5 flex flex-col items-start justify-start text-xs">
                    <div className="w-full flex flex-row items-center gap-1">
                      {JSON.parse(item?.parameters).type} • {JSON.parse(item?.parameters).area} m²
                    </div>
                    <div className="w-full flex flex-row items-center gap-1">
                      • {JSON.parse(item?.parameters).condition}
                    </div>
                    <div className="w-full h-6 gap-1 ">
                      • {item?.street ? item?.street + ", " : null}
                      {item.city}
                      {Number(JSON.parse(item?.lv).List.length) > 1
                        ? ", okres " + JSON.parse(item?.lv).List[0]?.nazov_okresu
                        : ", okres " + JSON.parse(item?.lv).List?.nazov_okresu}
                    </div>
                  </div>
                  <div className="w-full h-1/4 flex items-center justify-end text-xl font-semibold">{item.price} €</div>
                </div>
              </div>
            </div>
          ) : null,
        )
      )}
    </motion.div>
  ) : null;
}
