import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { motion } from "framer-motion";

export default function REBrokers({
  office,
  size,
  subMenu,
  setSubMenu,
  previous,
  subjects,
  setSubjects,
  indx,
  indxPrev,
  setSubjectsMenu,
  subjectsMenu,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const navigate = () => {
    if (subjects.ROffice) {
      setSubjects({ ...subjects, ROffice: false, RBroker: true, FInstitution: false, FBroker: false });
      setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: false, REB: true, menu: "ROffice" });
    }
    if (subjects.FInstitution) {
      setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: true });
      setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: true, REB: false, menu: "FInstitution" });
    }
  };

  return subMenu.interest ? (
    <motion.div
      className={clsx("flex flex-col", {
        "grid sm:grid-cols-2": !isSmallDevice && size.full,
      })}
      initial={{ x: previous === "lv" || previous === "ow" ? 500 : -500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {office?.brokers?.length === undefined ? (
        <div className="w-full flex justify-center items-center">Realitná kacelária nemá maklérov</div>
      ) : (
        office?.brokers?.map((broker, index) => (
          <div
            key={index}
            className={clsx(
              "transition-all duration-500 ease-linear min-h-max w-full flex-col justify-center sm:flex overflow-ellipsis p-2",
              {},
            )}
          >
            <div
              onClick={() => {
                indxPrev.current = indx.current;
                indx.current = index;
                // setSubjects({ ...subjects, ROffice: false, RBroker: true, FInstitution: false, FBroker: false });
                // setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: false, REB: true });
                navigate();
                setSubMenu({
                  ...subMenu,
                  lv: false,
                  interest: false,
                  owner: true,
                  disp: false,
                  rating: false,
                  share: false,
                });
              }}
              className={clsx(
                "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200 text-xs",
                {},
              )}
            >
              <div className={clsx("flex flex-row w-full gap-x-2 items-center", {})}>
                <img src={broker?.url} alt="" className="w-1/3 h-24 rounded-l-lg object-contain" />
                <div className={clsx("w-2/3 flex justify-between", {})}>
                  <div className="flex flex-col w-full h-24 p-[2px] items-start justify-between">
                    <div className="flex flex-col gap-x-2 gap-y-[2px]">
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                        {broker?.brokerName}
                      </p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{broker?.name}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{broker?.tel}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{broker?.email}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">
                        {broker?.street} {broker?.number}, {broker?.psc} {broker?.city}
                      </p>
                    </div>
                    <div className="w-full h-auto flex justify-end text-xl font-semibold"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </motion.div>
  ) : null;
}
