import { motion } from "framer-motion";
import Demolition from "../../Images/demolition.png";
import Recovery from "../../Images/recovery.png";
import Pawn from "../../Images/pawn.png";
import Insure from "../../Images/insure.png";
import Sell from "../../Images/sell.png";
import Build from "../../Images/build.png";
import ChangeOwner from "../../Images/changeOwner.png";
import Settle from "../../Images/settle.png";
import Price from "./Owner/Price";
import Parameters from "./Owner/Parameters";
import Description from "./Owner/Description";
import Photos from "./Owner/Photos";
import Url from "./Owner/Url";
import { forSaleService } from "../../services/forSaleService";
// import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { useState } from "react";
import { activityService } from "../../services/activityService";

export default function Owner({ subMenu, ownerMenu, setOwnerMenu, inputValues, setInputValues, previous, size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [loading, setLoading] = useState(false);

  const saveProperty = async () => {
    setLoading(true);
    const parameters = JSON.stringify({
      area: inputValues?.area,
      condition: inputValues?.condition,
      type: inputValues?.type,
    });
    const photos = inputValues?.photos;
    const email = JSON.parse(localStorage.getItem("userData"));
    const request = JSON.parse(
      JSON.stringify({
        email: email?.m,
        price: inputValues?.price,
        parameters: parameters,
        description: inputValues?.description,
        photos: photos,
        url: inputValues?.url,
      }),
    );
    console.log("request = ", request);
    // console.log("photos = ", photos);
    // eslint-disable-next-line
    const response = await forSaleService.create(request);
    setLoading(false);
    // navigate("/sprava-uctu/moje-nehnutelnosti");
    // console.log("response = ", response);
  };

  const load = () => {
    return (
      <svg
        aria-hidden="true"
        className="inline w-4 h-4 text-sky-200 animate-spin dark:text-white fill-white dark:fill-black"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    );
  };

  const saveActivity = async (activity) => {
    const email = JSON.parse(localStorage.getItem("userData"))?.m;
    const request = JSON.parse(JSON.stringify({ email: email, activityId: activity }));
    return await activityService.saveActivity(request);
  };

  return subMenu?.owner ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-start pt-1"
      initial={{ x: previous !== "lv" && previous !== "in" && previous !== "ds" ? -500 : 500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-base w-full flex flex-col items-center font-semibold gap-2">
        <button
          onClick={() => {
            setOwnerMenu({ ...ownerMenu, sell: !ownerMenu.sell });
            saveActivity("11");
          }}
          className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-red-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={Sell} alt="Sell" className="w-7 h-7" />
          Predať
        </button>
        {ownerMenu.sell ? (
          <motion.div
            className="text-base w-full flex flex-col items-center font-semibold gap-2 rounded-lg -mt-2 p-1"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <Url inputValues={inputValues} setInputValues={setInputValues} />
            <Price inputValues={inputValues} setInputValues={setInputValues} />
            <Parameters inputValues={inputValues} setInputValues={setInputValues} />
            <Description inputValues={inputValues} setInputValues={setInputValues} />
            <Photos inputValues={inputValues} setInputValues={setInputValues} />
            <div className="w-full flex flex-col justify-center items-center px-4">
              {loading ? "Prebieha ukladanie inzerátu" : null}
              <button onClick={() => saveProperty()} className="w-full bg-sky-300 rounded-xl h-10 mt-2">
                Uložiť
              </button>
            </div>
          </motion.div>
        ) : (
          <>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, sell: false });
                saveActivity("12");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-blue-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Insure} alt="Insure" className="w-7 h-7" />
              Poistiť
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, sell: false });
                saveActivity("13");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-green-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Pawn} alt="Pawn" className="w-7 h-7" />
              Založiť
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, sell: false });
                saveActivity("14");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-purple-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Recovery} alt="Recovery" className="w-6 h-6" />
              Obnoviť
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, sell: false });
                saveActivity("15");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-indigo-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Demolition} alt="Demolition" className="w-7 h-7" />
              Zbúrať
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, sell: false });
                saveActivity("16");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-cyan-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Build} alt="Build" className="w-7 h-7" />
              Postaviť
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, owner: false });
                saveActivity("17");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-emerald-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={ChangeOwner} alt="ChangeOwner" className="w-6 h-6" />
              Zmeniť vlastníka
            </button>
            <button
              onClick={() => {
                setOwnerMenu({ ...ownerMenu, owner: false });
                saveActivity("18");
              }}
              className={clsx("w-full flex flex-row items-center justify-center py-1 gap-1 bg-cyan-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <img src={Settle} alt="Settle" className="w-6 h-6" />
              Vysporiadať
            </button>
          </>
        )}
      </div>
    </motion.div>
  ) : null;
}
