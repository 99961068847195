const UnitsStropkov = {
  Krušinec: [
    { lat: 49.2365214, lng: 21.6471134 },
    { lat: 49.235698, lng: 21.6478219 },
    { lat: 49.2350228, lng: 21.6480606 },
    { lat: 49.2338098, lng: 21.649166 },
    { lat: 49.2312001, lng: 21.6517296 },
    { lat: 49.2292062, lng: 21.6553535 },
    { lat: 49.2290341, lng: 21.6548986 },
    { lat: 49.2279467, lng: 21.6566102 },
    { lat: 49.2276717, lng: 21.6573762 },
    { lat: 49.2269755, lng: 21.6583834 },
    { lat: 49.226335, lng: 21.6596484 },
    { lat: 49.2260159, lng: 21.6604506 },
    { lat: 49.2263182, lng: 21.6627242 },
    { lat: 49.2260436, lng: 21.6628466 },
    { lat: 49.2256299, lng: 21.6635184 },
    { lat: 49.2267706, lng: 21.665138 },
    { lat: 49.2268823, lng: 21.664821 },
    { lat: 49.227029, lng: 21.6648766 },
    { lat: 49.2275325, lng: 21.6653566 },
    { lat: 49.2277475, lng: 21.6652005 },
    { lat: 49.2281259, lng: 21.6656744 },
    { lat: 49.2282333, lng: 21.6659822 },
    { lat: 49.2287134, lng: 21.6664691 },
    { lat: 49.2289709, lng: 21.6664269 },
    { lat: 49.2291359, lng: 21.6661646 },
    { lat: 49.2293481, lng: 21.6659845 },
    { lat: 49.2294709, lng: 21.6659512 },
    { lat: 49.2296436, lng: 21.666008 },
    { lat: 49.230017, lng: 21.6665053 },
    { lat: 49.2301749, lng: 21.6671029 },
    { lat: 49.230098, lng: 21.6672582 },
    { lat: 49.2301649, lng: 21.6673969 },
    { lat: 49.2311143, lng: 21.6678128 },
    { lat: 49.2314604, lng: 21.6678378 },
    { lat: 49.2317328, lng: 21.667723 },
    { lat: 49.2356062, lng: 21.6667375 },
    { lat: 49.2362291, lng: 21.6673962 },
    { lat: 49.2368126, lng: 21.6677982 },
    { lat: 49.237478, lng: 21.6688175 },
    { lat: 49.2377788, lng: 21.6689991 },
    { lat: 49.2387502, lng: 21.6699775 },
    { lat: 49.2391185, lng: 21.6701623 },
    { lat: 49.2403354, lng: 21.6689458 },
    { lat: 49.2406522, lng: 21.6668111 },
    { lat: 49.2405551, lng: 21.6667503 },
    { lat: 49.2403113, lng: 21.6635256 },
    { lat: 49.2404369, lng: 21.6637146 },
    { lat: 49.2405247, lng: 21.6637191 },
    { lat: 49.2406246, lng: 21.6634212 },
    { lat: 49.2407231, lng: 21.6632825 },
    { lat: 49.2407277, lng: 21.6630755 },
    { lat: 49.240929, lng: 21.6630693 },
    { lat: 49.2409892, lng: 21.6631317 },
    { lat: 49.2410055, lng: 21.6632801 },
    { lat: 49.2411921, lng: 21.6633565 },
    { lat: 49.2412981, lng: 21.6632857 },
    { lat: 49.2413383, lng: 21.6631046 },
    { lat: 49.2411662, lng: 21.6630604 },
    { lat: 49.2414639, lng: 21.662557 },
    { lat: 49.2415355, lng: 21.6626601 },
    { lat: 49.2415893, lng: 21.6626506 },
    { lat: 49.2416187, lng: 21.6624493 },
    { lat: 49.2418646, lng: 21.6624766 },
    { lat: 49.24201, lng: 21.6623641 },
    { lat: 49.242059, lng: 21.6623866 },
    { lat: 49.2420821, lng: 21.6625788 },
    { lat: 49.2421936, lng: 21.6625361 },
    { lat: 49.2424419, lng: 21.6621139 },
    { lat: 49.2427062, lng: 21.6620706 },
    { lat: 49.242713, lng: 21.6618603 },
    { lat: 49.2428003, lng: 21.6618043 },
    { lat: 49.2428379, lng: 21.661659 },
    { lat: 49.2429429, lng: 21.6616718 },
    { lat: 49.2432269, lng: 21.6613151 },
    { lat: 49.2435299, lng: 21.6612459 },
    { lat: 49.2437298, lng: 21.6613589 },
    { lat: 49.2437738, lng: 21.6613212 },
    { lat: 49.2437549, lng: 21.661209 },
    { lat: 49.2438215, lng: 21.6609689 },
    { lat: 49.2439267, lng: 21.6611531 },
    { lat: 49.2442797, lng: 21.6610188 },
    { lat: 49.2443414, lng: 21.6609303 },
    { lat: 49.2448022, lng: 21.6611629 },
    { lat: 49.2448139, lng: 21.6610999 },
    { lat: 49.2446866, lng: 21.6608059 },
    { lat: 49.2446875, lng: 21.6606719 },
    { lat: 49.2447973, lng: 21.6605735 },
    { lat: 49.2448999, lng: 21.6606221 },
    { lat: 49.2450108, lng: 21.6605639 },
    { lat: 49.2450414, lng: 21.6604472 },
    { lat: 49.2451225, lng: 21.66037 },
    { lat: 49.2452785, lng: 21.6604379 },
    { lat: 49.2454512, lng: 21.6602513 },
    { lat: 49.2455265, lng: 21.6599178 },
    { lat: 49.2456629, lng: 21.6596795 },
    { lat: 49.2457485, lng: 21.659229 },
    { lat: 49.2456953, lng: 21.6590506 },
    { lat: 49.2457433, lng: 21.6587418 },
    { lat: 49.2456623, lng: 21.6585381 },
    { lat: 49.2457525, lng: 21.6584626 },
    { lat: 49.2457631, lng: 21.6579766 },
    { lat: 49.2461468, lng: 21.6567835 },
    { lat: 49.2460318, lng: 21.6566889 },
    { lat: 49.246299, lng: 21.6563836 },
    { lat: 49.246144, lng: 21.6523683 },
    { lat: 49.2469822, lng: 21.6508472 },
    { lat: 49.2483261, lng: 21.6500858 },
    { lat: 49.2480876, lng: 21.6495867 },
    { lat: 49.2474894, lng: 21.6479871 },
    { lat: 49.2474277, lng: 21.647995 },
    { lat: 49.2463718, lng: 21.6481318 },
    { lat: 49.2451225, lng: 21.6484812 },
    { lat: 49.2441787, lng: 21.6478559 },
    { lat: 49.2431697, lng: 21.6467877 },
    { lat: 49.2420063, lng: 21.6462834 },
    { lat: 49.2401777, lng: 21.6463826 },
    { lat: 49.2394667, lng: 21.6463388 },
    { lat: 49.2386097, lng: 21.6467594 },
    { lat: 49.2368363, lng: 21.6466981 },
    { lat: 49.2366982, lng: 21.6470445 },
    { lat: 49.2365214, lng: 21.6471134 },
  ],
  Miková: [
    { lat: 49.2951681, lng: 21.8020903 },
    { lat: 49.2947918, lng: 21.8025844 },
    { lat: 49.2940355, lng: 21.8036115 },
    { lat: 49.2936723, lng: 21.8038392 },
    { lat: 49.2934663, lng: 21.8042671 },
    { lat: 49.2922551, lng: 21.8050975 },
    { lat: 49.2918923, lng: 21.8055071 },
    { lat: 49.2914716, lng: 21.8056839 },
    { lat: 49.2912792, lng: 21.8058544 },
    { lat: 49.291124, lng: 21.8065964 },
    { lat: 49.291227, lng: 21.8069246 },
    { lat: 49.2911498, lng: 21.8073775 },
    { lat: 49.2912698, lng: 21.8075425 },
    { lat: 49.291215, lng: 21.8080312 },
    { lat: 49.2906989, lng: 21.8089474 },
    { lat: 49.2906952, lng: 21.8092409 },
    { lat: 49.2905388, lng: 21.8095491 },
    { lat: 49.2902253, lng: 21.8111733 },
    { lat: 49.2907405, lng: 21.8115649 },
    { lat: 49.2905006, lng: 21.8120792 },
    { lat: 49.2903049, lng: 21.8147827 },
    { lat: 49.2890223, lng: 21.8160375 },
    { lat: 49.2876554, lng: 21.8171154 },
    { lat: 49.2879776, lng: 21.8180591 },
    { lat: 49.288863, lng: 21.8195023 },
    { lat: 49.2880168, lng: 21.82137 },
    { lat: 49.2872608, lng: 21.8232923 },
    { lat: 49.2872104, lng: 21.8266358 },
    { lat: 49.2870779, lng: 21.827693 },
    { lat: 49.2872302, lng: 21.8280597 },
    { lat: 49.2867558, lng: 21.8281991 },
    { lat: 49.2862404, lng: 21.830785 },
    { lat: 49.2865953, lng: 21.83327 },
    { lat: 49.2865683, lng: 21.8335772 },
    { lat: 49.2867621, lng: 21.8339835 },
    { lat: 49.287284, lng: 21.8346866 },
    { lat: 49.2873149, lng: 21.8352771 },
    { lat: 49.2874335, lng: 21.8359132 },
    { lat: 49.2876286, lng: 21.8364572 },
    { lat: 49.2880193, lng: 21.8366932 },
    { lat: 49.2883774, lng: 21.8374123 },
    { lat: 49.2886526, lng: 21.8375292 },
    { lat: 49.2887397, lng: 21.8377744 },
    { lat: 49.2887087, lng: 21.8381402 },
    { lat: 49.2887527, lng: 21.8383213 },
    { lat: 49.2889953, lng: 21.8383515 },
    { lat: 49.2892011, lng: 21.8380499 },
    { lat: 49.2894839, lng: 21.838461 },
    { lat: 49.2898797, lng: 21.8414058 },
    { lat: 49.2902085, lng: 21.8431789 },
    { lat: 49.2901892, lng: 21.8457766 },
    { lat: 49.2911127, lng: 21.8466566 },
    { lat: 49.292149, lng: 21.8491062 },
    { lat: 49.2913969, lng: 21.8497837 },
    { lat: 49.2914049, lng: 21.850051 },
    { lat: 49.2910162, lng: 21.8502074 },
    { lat: 49.2909889, lng: 21.8505896 },
    { lat: 49.2906108, lng: 21.8519943 },
    { lat: 49.2906008, lng: 21.8523502 },
    { lat: 49.290954, lng: 21.8548771 },
    { lat: 49.290249, lng: 21.8573167 },
    { lat: 49.2898884, lng: 21.8589308 },
    { lat: 49.2893667, lng: 21.8606725 },
    { lat: 49.2893112, lng: 21.8610513 },
    { lat: 49.2891858, lng: 21.8635579 },
    { lat: 49.2889569, lng: 21.865447 },
    { lat: 49.2894427, lng: 21.8668177 },
    { lat: 49.289407, lng: 21.868848 },
    { lat: 49.289749, lng: 21.869276 },
    { lat: 49.290011, lng: 21.86961 },
    { lat: 49.290337, lng: 21.86979 },
    { lat: 49.290733, lng: 21.870118 },
    { lat: 49.290944, lng: 21.870352 },
    { lat: 49.291206, lng: 21.870098 },
    { lat: 49.291462, lng: 21.870096 },
    { lat: 49.292055, lng: 21.870023 },
    { lat: 49.292261, lng: 21.869842 },
    { lat: 49.292662, lng: 21.869347 },
    { lat: 49.293347, lng: 21.868905 },
    { lat: 49.293489, lng: 21.868875 },
    { lat: 49.29362, lng: 21.868724 },
    { lat: 49.293738, lng: 21.868662 },
    { lat: 49.293885, lng: 21.868459 },
    { lat: 49.294249, lng: 21.868094 },
    { lat: 49.294647, lng: 21.867694 },
    { lat: 49.29502, lng: 21.867472 },
    { lat: 49.295391, lng: 21.867064 },
    { lat: 49.295788, lng: 21.866681 },
    { lat: 49.29622, lng: 21.866339 },
    { lat: 49.296602, lng: 21.865729 },
    { lat: 49.296768, lng: 21.865574 },
    { lat: 49.296956, lng: 21.865412 },
    { lat: 49.29718, lng: 21.864906 },
    { lat: 49.297392, lng: 21.864558 },
    { lat: 49.297533, lng: 21.864383 },
    { lat: 49.297816, lng: 21.863964 },
    { lat: 49.29804, lng: 21.863728 },
    { lat: 49.298163, lng: 21.863495 },
    { lat: 49.298795, lng: 21.862727 },
    { lat: 49.299283, lng: 21.862396 },
    { lat: 49.299685, lng: 21.861628 },
    { lat: 49.299876, lng: 21.861078 },
    { lat: 49.300008, lng: 21.86079 },
    { lat: 49.300377, lng: 21.860264 },
    { lat: 49.301267, lng: 21.858421 },
    { lat: 49.301731, lng: 21.857819 },
    { lat: 49.302131, lng: 21.857233 },
    { lat: 49.302457, lng: 21.856745 },
    { lat: 49.302636, lng: 21.855495 },
    { lat: 49.303653, lng: 21.851954 },
    { lat: 49.303898, lng: 21.851219 },
    { lat: 49.30402, lng: 21.851276 },
    { lat: 49.304197, lng: 21.850501 },
    { lat: 49.304494, lng: 21.850507 },
    { lat: 49.304466, lng: 21.849889 },
    { lat: 49.304613, lng: 21.849027 },
    { lat: 49.304885, lng: 21.84901 },
    { lat: 49.304967, lng: 21.848414 },
    { lat: 49.305021, lng: 21.84826 },
    { lat: 49.305004, lng: 21.84815 },
    { lat: 49.305124, lng: 21.846761 },
    { lat: 49.306127, lng: 21.846195 },
    { lat: 49.307013, lng: 21.845223 },
    { lat: 49.307237, lng: 21.844893 },
    { lat: 49.307415, lng: 21.844659 },
    { lat: 49.307528, lng: 21.844579 },
    { lat: 49.307858, lng: 21.844543 },
    { lat: 49.308483, lng: 21.844474 },
    { lat: 49.308781, lng: 21.844399 },
    { lat: 49.309164, lng: 21.84425 },
    { lat: 49.309413, lng: 21.844118 },
    { lat: 49.309634, lng: 21.844062 },
    { lat: 49.310012, lng: 21.843783 },
    { lat: 49.310281, lng: 21.843663 },
    { lat: 49.310419, lng: 21.843546 },
    { lat: 49.310518, lng: 21.843536 },
    { lat: 49.310743, lng: 21.843334 },
    { lat: 49.310788, lng: 21.843291 },
    { lat: 49.310845, lng: 21.843246 },
    { lat: 49.31108, lng: 21.843076 },
    { lat: 49.311383, lng: 21.842904 },
    { lat: 49.311447, lng: 21.84285 },
    { lat: 49.311498, lng: 21.84281 },
    { lat: 49.311629, lng: 21.842728 },
    { lat: 49.311905, lng: 21.842636 },
    { lat: 49.311946, lng: 21.842623 },
    { lat: 49.312074, lng: 21.842569 },
    { lat: 49.312693, lng: 21.842187 },
    { lat: 49.313453, lng: 21.841684 },
    { lat: 49.313974, lng: 21.840854 },
    { lat: 49.313858, lng: 21.84059 },
    { lat: 49.313742, lng: 21.840368 },
    { lat: 49.31415, lng: 21.839885 },
    { lat: 49.314072, lng: 21.839756 },
    { lat: 49.314351, lng: 21.83941 },
    { lat: 49.314801, lng: 21.838743 },
    { lat: 49.314832, lng: 21.838659 },
    { lat: 49.314916, lng: 21.838508 },
    { lat: 49.315564, lng: 21.837572 },
    { lat: 49.3158, lng: 21.83727 },
    { lat: 49.315715, lng: 21.837115 },
    { lat: 49.316004, lng: 21.836848 },
    { lat: 49.316246, lng: 21.836571 },
    { lat: 49.316673, lng: 21.836133 },
    { lat: 49.316778, lng: 21.836005 },
    { lat: 49.317251, lng: 21.835234 },
    { lat: 49.317726, lng: 21.834597 },
    { lat: 49.318039, lng: 21.834203 },
    { lat: 49.318172, lng: 21.83398 },
    { lat: 49.318476, lng: 21.833627 },
    { lat: 49.318668, lng: 21.83347 },
    { lat: 49.318949, lng: 21.832967 },
    { lat: 49.319083, lng: 21.832753 },
    { lat: 49.319126, lng: 21.832638 },
    { lat: 49.319176, lng: 21.832334 },
    { lat: 49.319206, lng: 21.832238 },
    { lat: 49.319264, lng: 21.831908 },
    { lat: 49.319319, lng: 21.831561 },
    { lat: 49.319431, lng: 21.830903 },
    { lat: 49.31948, lng: 21.830623 },
    { lat: 49.319513, lng: 21.830431 },
    { lat: 49.31954, lng: 21.830282 },
    { lat: 49.319729, lng: 21.829306 },
    { lat: 49.319855, lng: 21.828781 },
    { lat: 49.319975, lng: 21.828616 },
    { lat: 49.32014, lng: 21.828469 },
    { lat: 49.320436, lng: 21.828199 },
    { lat: 49.320548, lng: 21.828055 },
    { lat: 49.321058, lng: 21.827676 },
    { lat: 49.321494, lng: 21.827344 },
    { lat: 49.321844, lng: 21.827005 },
    { lat: 49.322093, lng: 21.826522 },
    { lat: 49.322323, lng: 21.826352 },
    { lat: 49.322702, lng: 21.826012 },
    { lat: 49.323027, lng: 21.82578 },
    { lat: 49.323908, lng: 21.824728 },
    { lat: 49.3238751, lng: 21.823923 },
    { lat: 49.3238189, lng: 21.8236614 },
    { lat: 49.3234705, lng: 21.823017 },
    { lat: 49.322436, lng: 21.8220173 },
    { lat: 49.3219296, lng: 21.8217921 },
    { lat: 49.3216102, lng: 21.8214864 },
    { lat: 49.3211351, lng: 21.820765 },
    { lat: 49.3210546, lng: 21.8204392 },
    { lat: 49.3210081, lng: 21.8205105 },
    { lat: 49.3209268, lng: 21.820364 },
    { lat: 49.3204623, lng: 21.8204103 },
    { lat: 49.3194418, lng: 21.8206599 },
    { lat: 49.3191155, lng: 21.8206561 },
    { lat: 49.31906, lng: 21.8208188 },
    { lat: 49.3189028, lng: 21.8208264 },
    { lat: 49.3184717, lng: 21.8203317 },
    { lat: 49.3181652, lng: 21.8201829 },
    { lat: 49.3175665, lng: 21.8197325 },
    { lat: 49.3174874, lng: 21.8190194 },
    { lat: 49.317519, lng: 21.8185784 },
    { lat: 49.3174744, lng: 21.8182472 },
    { lat: 49.3175985, lng: 21.8175655 },
    { lat: 49.3175207, lng: 21.8172068 },
    { lat: 49.3179112, lng: 21.8164727 },
    { lat: 49.3178992, lng: 21.8161788 },
    { lat: 49.3181915, lng: 21.81565 },
    { lat: 49.3181104, lng: 21.8155579 },
    { lat: 49.3181081, lng: 21.8153767 },
    { lat: 49.3179464, lng: 21.8150024 },
    { lat: 49.3180193, lng: 21.8146389 },
    { lat: 49.3179226, lng: 21.8144259 },
    { lat: 49.3177749, lng: 21.8137148 },
    { lat: 49.3181567, lng: 21.8124587 },
    { lat: 49.3186048, lng: 21.8118303 },
    { lat: 49.3185313, lng: 21.8115456 },
    { lat: 49.3184252, lng: 21.8115736 },
    { lat: 49.3180756, lng: 21.8113225 },
    { lat: 49.317798, lng: 21.8112884 },
    { lat: 49.3174979, lng: 21.8105108 },
    { lat: 49.3174635, lng: 21.8102284 },
    { lat: 49.3169248, lng: 21.8093059 },
    { lat: 49.3168182, lng: 21.8089676 },
    { lat: 49.3167235, lng: 21.8086143 },
    { lat: 49.316623, lng: 21.8073386 },
    { lat: 49.3163182, lng: 21.8072904 },
    { lat: 49.3160254, lng: 21.8071422 },
    { lat: 49.3158095, lng: 21.8063203 },
    { lat: 49.315625, lng: 21.8059294 },
    { lat: 49.3152312, lng: 21.8063163 },
    { lat: 49.3151296, lng: 21.8060884 },
    { lat: 49.3149764, lng: 21.8059863 },
    { lat: 49.3148563, lng: 21.8056914 },
    { lat: 49.3146104, lng: 21.8054882 },
    { lat: 49.3145278, lng: 21.8053378 },
    { lat: 49.3120199, lng: 21.8046584 },
    { lat: 49.3111394, lng: 21.8040123 },
    { lat: 49.3109658, lng: 21.8031255 },
    { lat: 49.3103844, lng: 21.8013485 },
    { lat: 49.309633, lng: 21.8002202 },
    { lat: 49.3089224, lng: 21.7986631 },
    { lat: 49.3086749, lng: 21.7977913 },
    { lat: 49.3081676, lng: 21.7975337 },
    { lat: 49.3079654, lng: 21.797149 },
    { lat: 49.3076262, lng: 21.7965222 },
    { lat: 49.3071442, lng: 21.7954127 },
    { lat: 49.3070819, lng: 21.795091 },
    { lat: 49.3070465, lng: 21.7941992 },
    { lat: 49.3071368, lng: 21.7922648 },
    { lat: 49.3071013, lng: 21.7918936 },
    { lat: 49.3067525, lng: 21.7904152 },
    { lat: 49.30607, lng: 21.7907724 },
    { lat: 49.3056519, lng: 21.7905653 },
    { lat: 49.305109, lng: 21.791272 },
    { lat: 49.3050382, lng: 21.79145 },
    { lat: 49.304849, lng: 21.7916165 },
    { lat: 49.3044837, lng: 21.7917061 },
    { lat: 49.3043115, lng: 21.7919212 },
    { lat: 49.303637, lng: 21.7924592 },
    { lat: 49.3033602, lng: 21.7927543 },
    { lat: 49.3030442, lng: 21.792932 },
    { lat: 49.3028972, lng: 21.7931623 },
    { lat: 49.3026541, lng: 21.7932019 },
    { lat: 49.3024801, lng: 21.7933458 },
    { lat: 49.3019305, lng: 21.7939681 },
    { lat: 49.3011041, lng: 21.7942849 },
    { lat: 49.3008198, lng: 21.7944741 },
    { lat: 49.2988701, lng: 21.7967937 },
    { lat: 49.2985724, lng: 21.7972035 },
    { lat: 49.2980067, lng: 21.7982494 },
    { lat: 49.2973022, lng: 21.7991851 },
    { lat: 49.2972588, lng: 21.7992504 },
    { lat: 49.2964094, lng: 21.8005077 },
    { lat: 49.2957307, lng: 21.8012699 },
    { lat: 49.2951681, lng: 21.8020903 },
  ],
  Vyškovce: [
    { lat: 49.2561489, lng: 21.6444769 },
    { lat: 49.2561441, lng: 21.6444988 },
    { lat: 49.2544828, lng: 21.6455843 },
    { lat: 49.2504224, lng: 21.6468794 },
    { lat: 49.2497878, lng: 21.6472535 },
    { lat: 49.249812, lng: 21.647769 },
    { lat: 49.2494852, lng: 21.6475842 },
    { lat: 49.2482622, lng: 21.6481087 },
    { lat: 49.2474894, lng: 21.6479871 },
    { lat: 49.2480876, lng: 21.6495867 },
    { lat: 49.2483261, lng: 21.6500858 },
    { lat: 49.2469822, lng: 21.6508472 },
    { lat: 49.246144, lng: 21.6523683 },
    { lat: 49.246299, lng: 21.6563836 },
    { lat: 49.2460318, lng: 21.6566889 },
    { lat: 49.2461468, lng: 21.6567835 },
    { lat: 49.2457631, lng: 21.6579766 },
    { lat: 49.2457525, lng: 21.6584626 },
    { lat: 49.2456623, lng: 21.6585381 },
    { lat: 49.2457433, lng: 21.6587418 },
    { lat: 49.2456953, lng: 21.6590506 },
    { lat: 49.2457485, lng: 21.659229 },
    { lat: 49.2456629, lng: 21.6596795 },
    { lat: 49.2455265, lng: 21.6599178 },
    { lat: 49.2454512, lng: 21.6602513 },
    { lat: 49.2452785, lng: 21.6604379 },
    { lat: 49.2451225, lng: 21.66037 },
    { lat: 49.2450414, lng: 21.6604472 },
    { lat: 49.2450108, lng: 21.6605639 },
    { lat: 49.2448999, lng: 21.6606221 },
    { lat: 49.2447973, lng: 21.6605735 },
    { lat: 49.2446875, lng: 21.6606719 },
    { lat: 49.2446866, lng: 21.6608059 },
    { lat: 49.2448139, lng: 21.6610999 },
    { lat: 49.2448022, lng: 21.6611629 },
    { lat: 49.2443414, lng: 21.6609303 },
    { lat: 49.2442797, lng: 21.6610188 },
    { lat: 49.2439267, lng: 21.6611531 },
    { lat: 49.2438215, lng: 21.6609689 },
    { lat: 49.2437549, lng: 21.661209 },
    { lat: 49.2437738, lng: 21.6613212 },
    { lat: 49.2437298, lng: 21.6613589 },
    { lat: 49.2435299, lng: 21.6612459 },
    { lat: 49.2432269, lng: 21.6613151 },
    { lat: 49.2429429, lng: 21.6616718 },
    { lat: 49.2428379, lng: 21.661659 },
    { lat: 49.2428003, lng: 21.6618043 },
    { lat: 49.242713, lng: 21.6618603 },
    { lat: 49.2427062, lng: 21.6620706 },
    { lat: 49.2424419, lng: 21.6621139 },
    { lat: 49.2421936, lng: 21.6625361 },
    { lat: 49.2420821, lng: 21.6625788 },
    { lat: 49.242059, lng: 21.6623866 },
    { lat: 49.24201, lng: 21.6623641 },
    { lat: 49.2418646, lng: 21.6624766 },
    { lat: 49.2416187, lng: 21.6624493 },
    { lat: 49.2415893, lng: 21.6626506 },
    { lat: 49.2415355, lng: 21.6626601 },
    { lat: 49.2414639, lng: 21.662557 },
    { lat: 49.2411662, lng: 21.6630604 },
    { lat: 49.2413383, lng: 21.6631046 },
    { lat: 49.2412981, lng: 21.6632857 },
    { lat: 49.2411921, lng: 21.6633565 },
    { lat: 49.2410055, lng: 21.6632801 },
    { lat: 49.2409892, lng: 21.6631317 },
    { lat: 49.240929, lng: 21.6630693 },
    { lat: 49.2407277, lng: 21.6630755 },
    { lat: 49.2407231, lng: 21.6632825 },
    { lat: 49.2406246, lng: 21.6634212 },
    { lat: 49.2405247, lng: 21.6637191 },
    { lat: 49.2404369, lng: 21.6637146 },
    { lat: 49.2403113, lng: 21.6635256 },
    { lat: 49.2405551, lng: 21.6667503 },
    { lat: 49.2406522, lng: 21.6668111 },
    { lat: 49.2403354, lng: 21.6689458 },
    { lat: 49.2391185, lng: 21.6701623 },
    { lat: 49.239633, lng: 21.6703859 },
    { lat: 49.2397916, lng: 21.6707596 },
    { lat: 49.2398475, lng: 21.6710754 },
    { lat: 49.2405423, lng: 21.6709427 },
    { lat: 49.2407648, lng: 21.6710173 },
    { lat: 49.2411544, lng: 21.6708246 },
    { lat: 49.2413408, lng: 21.6708496 },
    { lat: 49.2416047, lng: 21.6705039 },
    { lat: 49.2418392, lng: 21.6706421 },
    { lat: 49.2421316, lng: 21.6706802 },
    { lat: 49.2423017, lng: 21.670776 },
    { lat: 49.2429907, lng: 21.6713746 },
    { lat: 49.2429657, lng: 21.6714528 },
    { lat: 49.2431425, lng: 21.6717959 },
    { lat: 49.2432186, lng: 21.6717757 },
    { lat: 49.2432894, lng: 21.6718587 },
    { lat: 49.2430995, lng: 21.6726537 },
    { lat: 49.2432588, lng: 21.6730995 },
    { lat: 49.2434546, lng: 21.6735232 },
    { lat: 49.2442247, lng: 21.6747546 },
    { lat: 49.2448633, lng: 21.676241 },
    { lat: 49.2446028, lng: 21.6783049 },
    { lat: 49.2456094, lng: 21.6797578 },
    { lat: 49.2457119, lng: 21.6799764 },
    { lat: 49.2458906, lng: 21.6800908 },
    { lat: 49.2459618, lng: 21.6799758 },
    { lat: 49.2461554, lng: 21.6800227 },
    { lat: 49.2465453, lng: 21.6803053 },
    { lat: 49.2466392, lng: 21.6801575 },
    { lat: 49.2468238, lng: 21.6803256 },
    { lat: 49.2479354, lng: 21.6802903 },
    { lat: 49.2486868, lng: 21.6799194 },
    { lat: 49.2490867, lng: 21.6798986 },
    { lat: 49.2498027, lng: 21.68025 },
    { lat: 49.2501867, lng: 21.6807692 },
    { lat: 49.2502593, lng: 21.6809159 },
    { lat: 49.2502778, lng: 21.6811394 },
    { lat: 49.2508703, lng: 21.6812945 },
    { lat: 49.2511018, lng: 21.6814963 },
    { lat: 49.251846, lng: 21.6827322 },
    { lat: 49.2532236, lng: 21.6845153 },
    { lat: 49.2542845, lng: 21.6862835 },
    { lat: 49.2543618, lng: 21.6866053 },
    { lat: 49.2544949, lng: 21.6867867 },
    { lat: 49.2547733, lng: 21.6874362 },
    { lat: 49.2561806, lng: 21.6914965 },
    { lat: 49.2565078, lng: 21.6919971 },
    { lat: 49.2575115, lng: 21.6927193 },
    { lat: 49.2574754, lng: 21.6920928 },
    { lat: 49.2582662, lng: 21.6914148 },
    { lat: 49.2581538, lng: 21.6908352 },
    { lat: 49.2573848, lng: 21.690551 },
    { lat: 49.2567065, lng: 21.6896763 },
    { lat: 49.2567393, lng: 21.6893447 },
    { lat: 49.2569504, lng: 21.6887466 },
    { lat: 49.2572432, lng: 21.6883876 },
    { lat: 49.2577499, lng: 21.6881549 },
    { lat: 49.2582065, lng: 21.6878122 },
    { lat: 49.2585234, lng: 21.687721 },
    { lat: 49.2588578, lng: 21.6875149 },
    { lat: 49.2599079, lng: 21.686329 },
    { lat: 49.2604173, lng: 21.6861566 },
    { lat: 49.2608549, lng: 21.6857718 },
    { lat: 49.2610164, lng: 21.6854682 },
    { lat: 49.2609803, lng: 21.6849122 },
    { lat: 49.2610233, lng: 21.6846804 },
    { lat: 49.2612197, lng: 21.6844128 },
    { lat: 49.261896, lng: 21.6838337 },
    { lat: 49.2620986, lng: 21.6838361 },
    { lat: 49.2624292, lng: 21.6834134 },
    { lat: 49.2630718, lng: 21.6828788 },
    { lat: 49.2632774, lng: 21.6828731 },
    { lat: 49.263498, lng: 21.6829612 },
    { lat: 49.2637713, lng: 21.6829199 },
    { lat: 49.264317, lng: 21.682676 },
    { lat: 49.2647673, lng: 21.6826852 },
    { lat: 49.2648738, lng: 21.6827127 },
    { lat: 49.2650583, lng: 21.682919 },
    { lat: 49.2652029, lng: 21.682951 },
    { lat: 49.2655424, lng: 21.6825241 },
    { lat: 49.2656666, lng: 21.6826172 },
    { lat: 49.2661358, lng: 21.6827116 },
    { lat: 49.2663707, lng: 21.6826169 },
    { lat: 49.2664943, lng: 21.6824906 },
    { lat: 49.267009, lng: 21.6822144 },
    { lat: 49.2671427, lng: 21.682206 },
    { lat: 49.2672575, lng: 21.6820864 },
    { lat: 49.2674821, lng: 21.6820301 },
    { lat: 49.2676033, lng: 21.6820672 },
    { lat: 49.2676408, lng: 21.6819989 },
    { lat: 49.2676555, lng: 21.6816587 },
    { lat: 49.267744, lng: 21.6814006 },
    { lat: 49.2676409, lng: 21.6809954 },
    { lat: 49.2676719, lng: 21.6810165 },
    { lat: 49.2672723, lng: 21.6805946 },
    { lat: 49.2661725, lng: 21.6785116 },
    { lat: 49.2657016, lng: 21.6770277 },
    { lat: 49.2650837, lng: 21.6744275 },
    { lat: 49.2646006, lng: 21.6715318 },
    { lat: 49.2640682, lng: 21.6678122 },
    { lat: 49.2641012, lng: 21.6647525 },
    { lat: 49.2642209, lng: 21.6635229 },
    { lat: 49.2642064, lng: 21.6626532 },
    { lat: 49.2645257, lng: 21.662235 },
    { lat: 49.2648333, lng: 21.6620834 },
    { lat: 49.2652015, lng: 21.6616409 },
    { lat: 49.2655582, lng: 21.6606425 },
    { lat: 49.265497, lng: 21.6593396 },
    { lat: 49.2670511, lng: 21.6532334 },
    { lat: 49.2665529, lng: 21.6511203 },
    { lat: 49.2665547, lng: 21.6507773 },
    { lat: 49.2664283, lng: 21.6502209 },
    { lat: 49.2664216, lng: 21.6497657 },
    { lat: 49.2661619, lng: 21.6481973 },
    { lat: 49.2667469, lng: 21.6467161 },
    { lat: 49.266353, lng: 21.6463751 },
    { lat: 49.2656432, lng: 21.6459246 },
    { lat: 49.2641573, lng: 21.6455893 },
    { lat: 49.263548, lng: 21.6451929 },
    { lat: 49.2632208, lng: 21.645114 },
    { lat: 49.262246, lng: 21.6451817 },
    { lat: 49.2618729, lng: 21.6450714 },
    { lat: 49.2606987, lng: 21.6452847 },
    { lat: 49.2604672, lng: 21.6451611 },
    { lat: 49.2603226, lng: 21.6449355 },
    { lat: 49.2601911, lng: 21.6448582 },
    { lat: 49.2597863, lng: 21.6448652 },
    { lat: 49.2586588, lng: 21.6445612 },
    { lat: 49.258218, lng: 21.6445676 },
    { lat: 49.2574135, lng: 21.6444401 },
    { lat: 49.2568968, lng: 21.6446293 },
    { lat: 49.2564493, lng: 21.6444704 },
    { lat: 49.2561489, lng: 21.6444769 },
  ],
  Gribov: [
    { lat: 49.3121398, lng: 21.6963045 },
    { lat: 49.3120121, lng: 21.6966168 },
    { lat: 49.3117827, lng: 21.6967014 },
    { lat: 49.3116253, lng: 21.6965829 },
    { lat: 49.3108818, lng: 21.6962895 },
    { lat: 49.3106966, lng: 21.6965116 },
    { lat: 49.3105299, lng: 21.6965441 },
    { lat: 49.3103644, lng: 21.6967455 },
    { lat: 49.3102074, lng: 21.6970618 },
    { lat: 49.3101247, lng: 21.6973813 },
    { lat: 49.3101391, lng: 21.6975423 },
    { lat: 49.3098597, lng: 21.6981877 },
    { lat: 49.309602, lng: 21.6983556 },
    { lat: 49.3095618, lng: 21.6985341 },
    { lat: 49.3093229, lng: 21.6987325 },
    { lat: 49.3090483, lng: 21.6988557 },
    { lat: 49.3084131, lng: 21.6989891 },
    { lat: 49.3080827, lng: 21.6992585 },
    { lat: 49.3073483, lng: 21.7001862 },
    { lat: 49.3069456, lng: 21.7005603 },
    { lat: 49.306777, lng: 21.7004894 },
    { lat: 49.3064621, lng: 21.7006122 },
    { lat: 49.3062793, lng: 21.70089 },
    { lat: 49.3061283, lng: 21.700875 },
    { lat: 49.3060702, lng: 21.7011464 },
    { lat: 49.3059707, lng: 21.7011267 },
    { lat: 49.3057821, lng: 21.7012657 },
    { lat: 49.3056192, lng: 21.7012312 },
    { lat: 49.3055249, lng: 21.7013607 },
    { lat: 49.3052776, lng: 21.7014144 },
    { lat: 49.3050242, lng: 21.7012284 },
    { lat: 49.3048994, lng: 21.701322 },
    { lat: 49.3046624, lng: 21.7012016 },
    { lat: 49.3045404, lng: 21.7013988 },
    { lat: 49.3045628, lng: 21.7016458 },
    { lat: 49.3044282, lng: 21.7015774 },
    { lat: 49.3043793, lng: 21.7017221 },
    { lat: 49.3041644, lng: 21.7018466 },
    { lat: 49.3040963, lng: 21.7018224 },
    { lat: 49.3040608, lng: 21.7021775 },
    { lat: 49.3038906, lng: 21.7021697 },
    { lat: 49.3038072, lng: 21.7019947 },
    { lat: 49.3036694, lng: 21.7020599 },
    { lat: 49.3035929, lng: 21.702268 },
    { lat: 49.3034673, lng: 21.7023209 },
    { lat: 49.303413, lng: 21.7023102 },
    { lat: 49.3033523, lng: 21.7020999 },
    { lat: 49.3029921, lng: 21.7017115 },
    { lat: 49.3027801, lng: 21.701569 },
    { lat: 49.3024127, lng: 21.7014575 },
    { lat: 49.3021636, lng: 21.7018974 },
    { lat: 49.3016011, lng: 21.7019826 },
    { lat: 49.3013496, lng: 21.7021688 },
    { lat: 49.3001949, lng: 21.7034066 },
    { lat: 49.2995786, lng: 21.703329 },
    { lat: 49.2993065, lng: 21.7033806 },
    { lat: 49.2983071, lng: 21.7038207 },
    { lat: 49.2978175, lng: 21.7044838 },
    { lat: 49.2973934, lng: 21.7049482 },
    { lat: 49.2970243, lng: 21.705154 },
    { lat: 49.2966334, lng: 21.7055136 },
    { lat: 49.2964338, lng: 21.7057884 },
    { lat: 49.2957167, lng: 21.7060151 },
    { lat: 49.295018, lng: 21.7064717 },
    { lat: 49.2944382, lng: 21.7066694 },
    { lat: 49.2938971, lng: 21.707044 },
    { lat: 49.2935733, lng: 21.7049437 },
    { lat: 49.2912521, lng: 21.7070677 },
    { lat: 49.2900617, lng: 21.7074378 },
    { lat: 49.2885629, lng: 21.7082163 },
    { lat: 49.2874164, lng: 21.7079534 },
    { lat: 49.2854993, lng: 21.7071826 },
    { lat: 49.2848286, lng: 21.7110614 },
    { lat: 49.285136, lng: 21.7113169 },
    { lat: 49.2844769, lng: 21.7138549 },
    { lat: 49.2844625, lng: 21.7143686 },
    { lat: 49.2840303, lng: 21.7160551 },
    { lat: 49.284011, lng: 21.7163831 },
    { lat: 49.284386, lng: 21.7179668 },
    { lat: 49.2843365, lng: 21.7183049 },
    { lat: 49.2845049, lng: 21.7194345 },
    { lat: 49.2846357, lng: 21.7222805 },
    { lat: 49.2842947, lng: 21.7251532 },
    { lat: 49.283787, lng: 21.727179 },
    { lat: 49.2833161, lng: 21.72872 },
    { lat: 49.2827805, lng: 21.7302037 },
    { lat: 49.2827569, lng: 21.7302823 },
    { lat: 49.2829361, lng: 21.7305086 },
    { lat: 49.2830067, lng: 21.7304596 },
    { lat: 49.2830329, lng: 21.7301333 },
    { lat: 49.2834504, lng: 21.7303914 },
    { lat: 49.2835899, lng: 21.7302221 },
    { lat: 49.2836953, lng: 21.7303388 },
    { lat: 49.2838023, lng: 21.7303015 },
    { lat: 49.2838867, lng: 21.73047 },
    { lat: 49.2840145, lng: 21.7304563 },
    { lat: 49.2841963, lng: 21.7306558 },
    { lat: 49.2843011, lng: 21.7305798 },
    { lat: 49.2844167, lng: 21.7306916 },
    { lat: 49.2845841, lng: 21.7316169 },
    { lat: 49.2847759, lng: 21.731898 },
    { lat: 49.2849367, lng: 21.731936 },
    { lat: 49.2872255, lng: 21.7346468 },
    { lat: 49.2899521, lng: 21.736649 },
    { lat: 49.2917075, lng: 21.7384206 },
    { lat: 49.293032, lng: 21.739642 },
    { lat: 49.29402, lng: 21.740592 },
    { lat: 49.295705, lng: 21.741398 },
    { lat: 49.295815, lng: 21.741451 },
    { lat: 49.296064, lng: 21.741498 },
    { lat: 49.296531, lng: 21.741297 },
    { lat: 49.296854, lng: 21.741242 },
    { lat: 49.297422, lng: 21.741207 },
    { lat: 49.297505, lng: 21.741202 },
    { lat: 49.298036, lng: 21.741103 },
    { lat: 49.29871, lng: 21.740977 },
    { lat: 49.299934, lng: 21.740653 },
    { lat: 49.301554, lng: 21.738627 },
    { lat: 49.304326, lng: 21.736426 },
    { lat: 49.305389, lng: 21.735087 },
    { lat: 49.306562, lng: 21.734203 },
    { lat: 49.30692, lng: 21.733961 },
    { lat: 49.308007, lng: 21.733377 },
    { lat: 49.308235, lng: 21.732985 },
    { lat: 49.308514, lng: 21.732458 },
    { lat: 49.309851, lng: 21.732176 },
    { lat: 49.310489, lng: 21.731779 },
    { lat: 49.312357, lng: 21.731291 },
    { lat: 49.312677, lng: 21.730824 },
    { lat: 49.312915, lng: 21.730438 },
    { lat: 49.313557, lng: 21.729896 },
    { lat: 49.314456, lng: 21.729311 },
    { lat: 49.315461, lng: 21.728324 },
    { lat: 49.315534, lng: 21.728438 },
    { lat: 49.316353, lng: 21.728078 },
    { lat: 49.316939, lng: 21.727277 },
    { lat: 49.317617, lng: 21.727235 },
    { lat: 49.31782, lng: 21.726748 },
    { lat: 49.31885, lng: 21.726237 },
    { lat: 49.318927, lng: 21.726253 },
    { lat: 49.31946, lng: 21.725861 },
    { lat: 49.3190578, lng: 21.724598 },
    { lat: 49.3187538, lng: 21.7232781 },
    { lat: 49.3186203, lng: 21.7230344 },
    { lat: 49.3185383, lng: 21.7224842 },
    { lat: 49.3169993, lng: 21.7193045 },
    { lat: 49.315705, lng: 21.7159692 },
    { lat: 49.31506, lng: 21.7152814 },
    { lat: 49.3150265, lng: 21.7146497 },
    { lat: 49.3141405, lng: 21.7142019 },
    { lat: 49.3129642, lng: 21.714172 },
    { lat: 49.3122305, lng: 21.7132248 },
    { lat: 49.3119793, lng: 21.7122 },
    { lat: 49.3120806, lng: 21.7116158 },
    { lat: 49.3119863, lng: 21.7112756 },
    { lat: 49.3120638, lng: 21.7109409 },
    { lat: 49.3119215, lng: 21.7104537 },
    { lat: 49.3118529, lng: 21.709649 },
    { lat: 49.3115804, lng: 21.7087546 },
    { lat: 49.3112702, lng: 21.7086391 },
    { lat: 49.3110101, lng: 21.7090511 },
    { lat: 49.3103965, lng: 21.7051527 },
    { lat: 49.3105478, lng: 21.7028555 },
    { lat: 49.3114765, lng: 21.7003492 },
    { lat: 49.3118603, lng: 21.6974991 },
    { lat: 49.3121398, lng: 21.6963045 },
  ],
  Vojtovce: [
    { lat: 49.2211067, lng: 21.6856495 },
    { lat: 49.220755, lng: 21.6854574 },
    { lat: 49.2201715, lng: 21.685295 },
    { lat: 49.2197983, lng: 21.6850571 },
    { lat: 49.2194826, lng: 21.6851165 },
    { lat: 49.2193211, lng: 21.685046 },
    { lat: 49.2184119, lng: 21.684958 },
    { lat: 49.2180328, lng: 21.6848314 },
    { lat: 49.2154144, lng: 21.6830921 },
    { lat: 49.2147317, lng: 21.6829669 },
    { lat: 49.2128136, lng: 21.6824252 },
    { lat: 49.2106901, lng: 21.6819321 },
    { lat: 49.2100984, lng: 21.6817206 },
    { lat: 49.2061857, lng: 21.6813377 },
    { lat: 49.2061821, lng: 21.6812918 },
    { lat: 49.2059758, lng: 21.6813188 },
    { lat: 49.2037023, lng: 21.6811004 },
    { lat: 49.2030325, lng: 21.6811323 },
    { lat: 49.201115, lng: 21.681775 },
    { lat: 49.2008837, lng: 21.6819129 },
    { lat: 49.200641, lng: 21.6822141 },
    { lat: 49.2002883, lng: 21.6824859 },
    { lat: 49.2001061, lng: 21.6831585 },
    { lat: 49.1998527, lng: 21.6832309 },
    { lat: 49.1997967, lng: 21.683442 },
    { lat: 49.1994125, lng: 21.6838823 },
    { lat: 49.1992776, lng: 21.6838029 },
    { lat: 49.1991836, lng: 21.6840319 },
    { lat: 49.1989664, lng: 21.6840279 },
    { lat: 49.1988503, lng: 21.6842261 },
    { lat: 49.1985536, lng: 21.6842347 },
    { lat: 49.1984398, lng: 21.6844415 },
    { lat: 49.1981505, lng: 21.6846808 },
    { lat: 49.1979248, lng: 21.6852385 },
    { lat: 49.197591, lng: 21.6855461 },
    { lat: 49.1969313, lng: 21.6865737 },
    { lat: 49.1967833, lng: 21.6866352 },
    { lat: 49.1966079, lng: 21.6870573 },
    { lat: 49.1964073, lng: 21.6872044 },
    { lat: 49.196283, lng: 21.6874384 },
    { lat: 49.1962029, lng: 21.6881434 },
    { lat: 49.1962176, lng: 21.6885651 },
    { lat: 49.1961582, lng: 21.6889084 },
    { lat: 49.1959764, lng: 21.6894676 },
    { lat: 49.1957434, lng: 21.6898771 },
    { lat: 49.1956682, lng: 21.6905933 },
    { lat: 49.1958002, lng: 21.6909897 },
    { lat: 49.1957954, lng: 21.6911446 },
    { lat: 49.1956682, lng: 21.6918133 },
    { lat: 49.1957131, lng: 21.6919774 },
    { lat: 49.1956459, lng: 21.6921971 },
    { lat: 49.1955478, lng: 21.6931068 },
    { lat: 49.1953375, lng: 21.6934929 },
    { lat: 49.1952878, lng: 21.6937748 },
    { lat: 49.1950602, lng: 21.6941821 },
    { lat: 49.1947362, lng: 21.69468 },
    { lat: 49.1941255, lng: 21.6954027 },
    { lat: 49.1939664, lng: 21.6958413 },
    { lat: 49.1936225, lng: 21.6963606 },
    { lat: 49.1934253, lng: 21.6968466 },
    { lat: 49.1930122, lng: 21.6985589 },
    { lat: 49.192815, lng: 21.7004814 },
    { lat: 49.1928324, lng: 21.7018863 },
    { lat: 49.1937495, lng: 21.7054229 },
    { lat: 49.1934986, lng: 21.7079501 },
    { lat: 49.1933795, lng: 21.7083719 },
    { lat: 49.1935488, lng: 21.7096644 },
    { lat: 49.1934953, lng: 21.7106421 },
    { lat: 49.1937422, lng: 21.7115482 },
    { lat: 49.1937709, lng: 21.7126675 },
    { lat: 49.1939217, lng: 21.7131757 },
    { lat: 49.1944635, lng: 21.7145146 },
    { lat: 49.1945144, lng: 21.7147366 },
    { lat: 49.1944854, lng: 21.7149919 },
    { lat: 49.1947438, lng: 21.7164624 },
    { lat: 49.1948513, lng: 21.7166583 },
    { lat: 49.1949286, lng: 21.7171524 },
    { lat: 49.1946321, lng: 21.7180563 },
    { lat: 49.1954511, lng: 21.7179245 },
    { lat: 49.1954867, lng: 21.7178723 },
    { lat: 49.197747, lng: 21.7145901 },
    { lat: 49.1998189, lng: 21.7126874 },
    { lat: 49.2014226, lng: 21.7108589 },
    { lat: 49.2014136, lng: 21.7108166 },
    { lat: 49.2032831, lng: 21.7088376 },
    { lat: 49.2076104, lng: 21.7058352 },
    { lat: 49.2085531, lng: 21.7053343 },
    { lat: 49.2106302, lng: 21.7052239 },
    { lat: 49.2115236, lng: 21.7053636 },
    { lat: 49.2127576, lng: 21.7042275 },
    { lat: 49.2131218, lng: 21.7042533 },
    { lat: 49.2140595, lng: 21.7036116 },
    { lat: 49.2148256, lng: 21.7041122 },
    { lat: 49.215017, lng: 21.7043763 },
    { lat: 49.215324, lng: 21.7036159 },
    { lat: 49.215576, lng: 21.7030687 },
    { lat: 49.2166101, lng: 21.7003919 },
    { lat: 49.2166638, lng: 21.6961834 },
    { lat: 49.2170765, lng: 21.6950015 },
    { lat: 49.2192673, lng: 21.6906186 },
    { lat: 49.2196094, lng: 21.688913 },
    { lat: 49.2198485, lng: 21.6872139 },
    { lat: 49.2202083, lng: 21.6865839 },
    { lat: 49.2211067, lng: 21.6856495 },
  ],
  Kručov: [
    { lat: 49.126864, lng: 21.6279384 },
    { lat: 49.1267175, lng: 21.6268245 },
    { lat: 49.1258254, lng: 21.6252258 },
    { lat: 49.1258072, lng: 21.6248028 },
    { lat: 49.1262973, lng: 21.6245879 },
    { lat: 49.1272198, lng: 21.623483 },
    { lat: 49.1280288, lng: 21.623079 },
    { lat: 49.128022, lng: 21.6228327 },
    { lat: 49.1277494, lng: 21.6218777 },
    { lat: 49.1270709, lng: 21.6206404 },
    { lat: 49.1266912, lng: 21.6197612 },
    { lat: 49.1263919, lng: 21.6194398 },
    { lat: 49.1262831, lng: 21.619246 },
    { lat: 49.126088, lng: 21.619146 },
    { lat: 49.125835, lng: 21.6185788 },
    { lat: 49.1255143, lng: 21.6184143 },
    { lat: 49.1250845, lng: 21.6180438 },
    { lat: 49.1246901, lng: 21.6174031 },
    { lat: 49.1248111, lng: 21.6171827 },
    { lat: 49.1249448, lng: 21.6170571 },
    { lat: 49.1249781, lng: 21.6167906 },
    { lat: 49.1251408, lng: 21.616862 },
    { lat: 49.1251946, lng: 21.6168133 },
    { lat: 49.1252493, lng: 21.6165484 },
    { lat: 49.1254074, lng: 21.6162864 },
    { lat: 49.1255814, lng: 21.6162279 },
    { lat: 49.1257059, lng: 21.615929 },
    { lat: 49.1258762, lng: 21.6158232 },
    { lat: 49.1259297, lng: 21.6156424 },
    { lat: 49.1261493, lng: 21.6156908 },
    { lat: 49.126213, lng: 21.6154781 },
    { lat: 49.1262736, lng: 21.6154257 },
    { lat: 49.1264473, lng: 21.615606 },
    { lat: 49.1265807, lng: 21.6155867 },
    { lat: 49.1267102, lng: 21.6154283 },
    { lat: 49.1269265, lng: 21.6153613 },
    { lat: 49.1271386, lng: 21.6150524 },
    { lat: 49.1276422, lng: 21.6152099 },
    { lat: 49.1279846, lng: 21.615092 },
    { lat: 49.128158, lng: 21.6151406 },
    { lat: 49.1282612, lng: 21.6150082 },
    { lat: 49.1290769, lng: 21.6147689 },
    { lat: 49.1296227, lng: 21.6143295 },
    { lat: 49.1300084, lng: 21.6136617 },
    { lat: 49.1302377, lng: 21.6134552 },
    { lat: 49.1303725, lng: 21.6134085 },
    { lat: 49.1306682, lng: 21.6130364 },
    { lat: 49.1307338, lng: 21.6128832 },
    { lat: 49.1291377, lng: 21.6119156 },
    { lat: 49.1290785, lng: 21.6114024 },
    { lat: 49.1290952, lng: 21.6108187 },
    { lat: 49.1292352, lng: 21.6103478 },
    { lat: 49.1292803, lng: 21.6103517 },
    { lat: 49.1300349, lng: 21.6088721 },
    { lat: 49.1302876, lng: 21.6081734 },
    { lat: 49.1302461, lng: 21.6080931 },
    { lat: 49.1304532, lng: 21.6077302 },
    { lat: 49.1305029, lng: 21.6074472 },
    { lat: 49.1315294, lng: 21.6069384 },
    { lat: 49.1331554, lng: 21.6071538 },
    { lat: 49.1334177, lng: 21.6070411 },
    { lat: 49.133964, lng: 21.6066597 },
    { lat: 49.1341172, lng: 21.6066268 },
    { lat: 49.1340642, lng: 21.605519 },
    { lat: 49.1342386, lng: 21.6049542 },
    { lat: 49.1344202, lng: 21.6049285 },
    { lat: 49.1344797, lng: 21.6023164 },
    { lat: 49.1345418, lng: 21.6021684 },
    { lat: 49.1353786, lng: 21.6013874 },
    { lat: 49.1357241, lng: 21.6013067 },
    { lat: 49.1357624, lng: 21.6013886 },
    { lat: 49.1359009, lng: 21.601393 },
    { lat: 49.1359217, lng: 21.601456 },
    { lat: 49.1367429, lng: 21.6013716 },
    { lat: 49.1368905, lng: 21.6014235 },
    { lat: 49.1366969, lng: 21.6004443 },
    { lat: 49.1370449, lng: 21.5994397 },
    { lat: 49.1371437, lng: 21.5985405 },
    { lat: 49.1371391, lng: 21.5973616 },
    { lat: 49.1373772, lng: 21.5957198 },
    { lat: 49.137367, lng: 21.594256 },
    { lat: 49.136852, lng: 21.594107 },
    { lat: 49.135971, lng: 21.594229 },
    { lat: 49.135456, lng: 21.59393 },
    { lat: 49.135464, lng: 21.593888 },
    { lat: 49.135306, lng: 21.592882 },
    { lat: 49.135161, lng: 21.592652 },
    { lat: 49.134705, lng: 21.593165 },
    { lat: 49.134428, lng: 21.593343 },
    { lat: 49.134337, lng: 21.59328 },
    { lat: 49.134074, lng: 21.593557 },
    { lat: 49.134045, lng: 21.593696 },
    { lat: 49.133927, lng: 21.593774 },
    { lat: 49.133902, lng: 21.59381 },
    { lat: 49.133741, lng: 21.593896 },
    { lat: 49.133374, lng: 21.593817 },
    { lat: 49.133307, lng: 21.593777 },
    { lat: 49.13291, lng: 21.593567 },
    { lat: 49.132451, lng: 21.593615 },
    { lat: 49.132326, lng: 21.593671 },
    { lat: 49.13175, lng: 21.593663 },
    { lat: 49.13163, lng: 21.593539 },
    { lat: 49.131167, lng: 21.593583 },
    { lat: 49.131002, lng: 21.593666 },
    { lat: 49.130616, lng: 21.593743 },
    { lat: 49.130087, lng: 21.593781 },
    { lat: 49.129921, lng: 21.593716 },
    { lat: 49.129891, lng: 21.593609 },
    { lat: 49.129748, lng: 21.59353 },
    { lat: 49.129701, lng: 21.593504 },
    { lat: 49.129603, lng: 21.593503 },
    { lat: 49.129226, lng: 21.593498 },
    { lat: 49.128858, lng: 21.593399 },
    { lat: 49.128556, lng: 21.593373 },
    { lat: 49.128098, lng: 21.593513 },
    { lat: 49.127324, lng: 21.593721 },
    { lat: 49.126813, lng: 21.593624 },
    { lat: 49.126605, lng: 21.593439 },
    { lat: 49.125983, lng: 21.593054 },
    { lat: 49.125912, lng: 21.592889 },
    { lat: 49.124555, lng: 21.592215 },
    { lat: 49.124237, lng: 21.592011 },
    { lat: 49.123974, lng: 21.592017 },
    { lat: 49.123781, lng: 21.591919 },
    { lat: 49.123701, lng: 21.591856 },
    { lat: 49.123626, lng: 21.591874 },
    { lat: 49.123479, lng: 21.592098 },
    { lat: 49.123315, lng: 21.592126 },
    { lat: 49.123182, lng: 21.591934 },
    { lat: 49.123124, lng: 21.591941 },
    { lat: 49.122757, lng: 21.592212 },
    { lat: 49.122479, lng: 21.592161 },
    { lat: 49.122282, lng: 21.592081 },
    { lat: 49.121973, lng: 21.591888 },
    { lat: 49.121945, lng: 21.591619 },
    { lat: 49.121926, lng: 21.591217 },
    { lat: 49.121542, lng: 21.590997 },
    { lat: 49.120747, lng: 21.590587 },
    { lat: 49.120578, lng: 21.590418 },
    { lat: 49.120317, lng: 21.590156 },
    { lat: 49.119967, lng: 21.590002 },
    { lat: 49.119655, lng: 21.589775 },
    { lat: 49.119823, lng: 21.58886 },
    { lat: 49.119785, lng: 21.588598 },
    { lat: 49.119727, lng: 21.588206 },
    { lat: 49.119712, lng: 21.588085 },
    { lat: 49.119694, lng: 21.587982 },
    { lat: 49.119679, lng: 21.587888 },
    { lat: 49.119587, lng: 21.587306 },
    { lat: 49.1195, lng: 21.58721 },
    { lat: 49.119191, lng: 21.586799 },
    { lat: 49.119018, lng: 21.586754 },
    { lat: 49.118874, lng: 21.587049 },
    { lat: 49.118847, lng: 21.587031 },
    { lat: 49.118621, lng: 21.586898 },
    { lat: 49.118564, lng: 21.586927 },
    { lat: 49.118376, lng: 21.587197 },
    { lat: 49.118256, lng: 21.5872 },
    { lat: 49.117951, lng: 21.587171 },
    { lat: 49.117844, lng: 21.586712 },
    { lat: 49.117889, lng: 21.586666 },
    { lat: 49.117808, lng: 21.585827 },
    { lat: 49.117899, lng: 21.585599 },
    { lat: 49.117925, lng: 21.58531 },
    { lat: 49.117873, lng: 21.585013 },
    { lat: 49.117852, lng: 21.584649 },
    { lat: 49.117587, lng: 21.58408 },
    { lat: 49.11749, lng: 21.583821 },
    { lat: 49.117341, lng: 21.583545 },
    { lat: 49.117181, lng: 21.583155 },
    { lat: 49.117086, lng: 21.582779 },
    { lat: 49.116931, lng: 21.58242 },
    { lat: 49.116716, lng: 21.582069 },
    { lat: 49.116559, lng: 21.581754 },
    { lat: 49.116331, lng: 21.581395 },
    { lat: 49.116044, lng: 21.581145 },
    { lat: 49.115688, lng: 21.580759 },
    { lat: 49.115454, lng: 21.580443 },
    { lat: 49.115096, lng: 21.580043 },
    { lat: 49.114861, lng: 21.579887 },
    { lat: 49.114693, lng: 21.579619 },
    { lat: 49.11424, lng: 21.578806 },
    { lat: 49.114002, lng: 21.578372 },
    { lat: 49.113224, lng: 21.577238 },
    { lat: 49.11319, lng: 21.577194 },
    { lat: 49.113153, lng: 21.577222 },
    { lat: 49.113019, lng: 21.57711 },
    { lat: 49.113017, lng: 21.577196 },
    { lat: 49.112841, lng: 21.5773 },
    { lat: 49.112701, lng: 21.577479 },
    { lat: 49.112415, lng: 21.577503 },
    { lat: 49.112014, lng: 21.577471 },
    { lat: 49.11175, lng: 21.577489 },
    { lat: 49.111447, lng: 21.577289 },
    { lat: 49.111419, lng: 21.577422 },
    { lat: 49.111001, lng: 21.577112 },
    { lat: 49.110827, lng: 21.576946 },
    { lat: 49.110647, lng: 21.576685 },
    { lat: 49.110135, lng: 21.576282 },
    { lat: 49.110004, lng: 21.576052 },
    { lat: 49.10926, lng: 21.577015 },
    { lat: 49.109248, lng: 21.577801 },
    { lat: 49.109579, lng: 21.578253 },
    { lat: 49.109888, lng: 21.578897 },
    { lat: 49.109731, lng: 21.57912 },
    { lat: 49.109535, lng: 21.579234 },
    { lat: 49.109437, lng: 21.57927 },
    { lat: 49.109285, lng: 21.579166 },
    { lat: 49.108984, lng: 21.580172 },
    { lat: 49.108603, lng: 21.580931 },
    { lat: 49.109004, lng: 21.582024 },
    { lat: 49.10885, lng: 21.582257 },
    { lat: 49.108867, lng: 21.582516 },
    { lat: 49.108703, lng: 21.582716 },
    { lat: 49.108048, lng: 21.583087 },
    { lat: 49.108148, lng: 21.583715 },
    { lat: 49.107818, lng: 21.584139 },
    { lat: 49.10718, lng: 21.585985 },
    { lat: 49.106593, lng: 21.58616 },
    { lat: 49.105413, lng: 21.586767 },
    { lat: 49.105207, lng: 21.588577 },
    { lat: 49.104865, lng: 21.58896 },
    { lat: 49.105829, lng: 21.591326 },
    { lat: 49.104347, lng: 21.592352 },
    { lat: 49.104355, lng: 21.593144 },
    { lat: 49.103761, lng: 21.593582 },
    { lat: 49.103714, lng: 21.593722 },
    { lat: 49.103687, lng: 21.593803 },
    { lat: 49.103614, lng: 21.594052 },
    { lat: 49.102994, lng: 21.594694 },
    { lat: 49.102766, lng: 21.594904 },
    { lat: 49.102811, lng: 21.59502 },
    { lat: 49.102863, lng: 21.59575 },
    { lat: 49.102981, lng: 21.596391 },
    { lat: 49.102788, lng: 21.596473 },
    { lat: 49.101835, lng: 21.596675 },
    { lat: 49.101578, lng: 21.596787 },
    { lat: 49.101232, lng: 21.597033 },
    { lat: 49.100847, lng: 21.597629 },
    { lat: 49.100676, lng: 21.598005 },
    { lat: 49.100718, lng: 21.598326 },
    { lat: 49.100144, lng: 21.59886 },
    { lat: 49.100122, lng: 21.598886 },
    { lat: 49.1003104, lng: 21.5992153 },
    { lat: 49.1016184, lng: 21.6018828 },
    { lat: 49.1022066, lng: 21.6049673 },
    { lat: 49.1023745, lng: 21.6055026 },
    { lat: 49.1026671, lng: 21.6061606 },
    { lat: 49.1028605, lng: 21.6075815 },
    { lat: 49.1031109, lng: 21.6086649 },
    { lat: 49.1037617, lng: 21.6085585 },
    { lat: 49.1040071, lng: 21.6081177 },
    { lat: 49.1056417, lng: 21.6081551 },
    { lat: 49.1057567, lng: 21.6084522 },
    { lat: 49.1059322, lng: 21.6084901 },
    { lat: 49.1060052, lng: 21.6087257 },
    { lat: 49.1059319, lng: 21.6091307 },
    { lat: 49.1059418, lng: 21.6094091 },
    { lat: 49.1060245, lng: 21.6095963 },
    { lat: 49.1061273, lng: 21.6102981 },
    { lat: 49.1059868, lng: 21.6105125 },
    { lat: 49.1059836, lng: 21.6107949 },
    { lat: 49.1061483, lng: 21.6112206 },
    { lat: 49.1061177, lng: 21.6116248 },
    { lat: 49.1058658, lng: 21.6118761 },
    { lat: 49.1058545, lng: 21.6121163 },
    { lat: 49.1062443, lng: 21.6124184 },
    { lat: 49.1088339, lng: 21.6138872 },
    { lat: 49.1088066, lng: 21.6142362 },
    { lat: 49.1106155, lng: 21.6148034 },
    { lat: 49.1106738, lng: 21.6152848 },
    { lat: 49.1125069, lng: 21.6156678 },
    { lat: 49.1130117, lng: 21.6159006 },
    { lat: 49.1128915, lng: 21.6168082 },
    { lat: 49.1128194, lng: 21.6180672 },
    { lat: 49.1126782, lng: 21.6188311 },
    { lat: 49.1129472, lng: 21.6197303 },
    { lat: 49.1130865, lng: 21.6207065 },
    { lat: 49.1131777, lng: 21.6208669 },
    { lat: 49.1140433, lng: 21.6244822 },
    { lat: 49.114638, lng: 21.6254606 },
    { lat: 49.1147797, lng: 21.6260689 },
    { lat: 49.1151362, lng: 21.625737 },
    { lat: 49.1152831, lng: 21.625961 },
    { lat: 49.1154098, lng: 21.6257852 },
    { lat: 49.115964, lng: 21.6261363 },
    { lat: 49.1163849, lng: 21.6262661 },
    { lat: 49.1165692, lng: 21.6266257 },
    { lat: 49.1169171, lng: 21.6265116 },
    { lat: 49.1171838, lng: 21.6268839 },
    { lat: 49.1173859, lng: 21.6273594 },
    { lat: 49.1176375, lng: 21.6276343 },
    { lat: 49.1180813, lng: 21.6277335 },
    { lat: 49.1192613, lng: 21.6290005 },
    { lat: 49.1196631, lng: 21.6298746 },
    { lat: 49.120242, lng: 21.630547 },
    { lat: 49.1205443, lng: 21.6311096 },
    { lat: 49.1212202, lng: 21.6334418 },
    { lat: 49.1217597, lng: 21.6324395 },
    { lat: 49.1219285, lng: 21.6307212 },
    { lat: 49.1225085, lng: 21.6299659 },
    { lat: 49.1230599, lng: 21.6295185 },
    { lat: 49.1233911, lng: 21.6294931 },
    { lat: 49.1236548, lng: 21.6296354 },
    { lat: 49.1242851, lng: 21.6294358 },
    { lat: 49.124713, lng: 21.629531 },
    { lat: 49.1254394, lng: 21.6294409 },
    { lat: 49.1263172, lng: 21.6283465 },
    { lat: 49.126864, lng: 21.6279384 },
  ],
  Breznička: [
    { lat: 49.215017, lng: 21.7043763 },
    { lat: 49.2146383, lng: 21.7059864 },
    { lat: 49.2147201, lng: 21.7060226 },
    { lat: 49.2143011, lng: 21.7070044 },
    { lat: 49.2142611, lng: 21.7076603 },
    { lat: 49.2148768, lng: 21.7079264 },
    { lat: 49.2148997, lng: 21.7084464 },
    { lat: 49.21554, lng: 21.7085832 },
    { lat: 49.2155682, lng: 21.709594 },
    { lat: 49.2147643, lng: 21.7102007 },
    { lat: 49.2138368, lng: 21.7123658 },
    { lat: 49.2139739, lng: 21.7127437 },
    { lat: 49.2140533, lng: 21.7128115 },
    { lat: 49.2141224, lng: 21.7127867 },
    { lat: 49.2140824, lng: 21.7131018 },
    { lat: 49.2142656, lng: 21.7132621 },
    { lat: 49.2142657, lng: 21.7135197 },
    { lat: 49.2144197, lng: 21.7136375 },
    { lat: 49.2145321, lng: 21.7140634 },
    { lat: 49.214803, lng: 21.7142225 },
    { lat: 49.2148804, lng: 21.7145545 },
    { lat: 49.2151008, lng: 21.7145872 },
    { lat: 49.2155949, lng: 21.7154028 },
    { lat: 49.2157786, lng: 21.7154608 },
    { lat: 49.215861, lng: 21.7155953 },
    { lat: 49.2163537, lng: 21.715935 },
    { lat: 49.2166376, lng: 21.7163736 },
    { lat: 49.2167215, lng: 21.7166545 },
    { lat: 49.2165127, lng: 21.7171687 },
    { lat: 49.2160915, lng: 21.7192686 },
    { lat: 49.2162196, lng: 21.7197262 },
    { lat: 49.2159253, lng: 21.7209254 },
    { lat: 49.2162662, lng: 21.7230888 },
    { lat: 49.2160385, lng: 21.7245113 },
    { lat: 49.2162057, lng: 21.7257618 },
    { lat: 49.2166122, lng: 21.7267521 },
    { lat: 49.21684, lng: 21.7275397 },
    { lat: 49.2172326, lng: 21.7292296 },
    { lat: 49.2176094, lng: 21.7301239 },
    { lat: 49.218043, lng: 21.7305624 },
    { lat: 49.2183628, lng: 21.7307913 },
    { lat: 49.2190132, lng: 21.7310976 },
    { lat: 49.2192349, lng: 21.731316 },
    { lat: 49.2195896, lng: 21.7313222 },
    { lat: 49.2206587, lng: 21.731006 },
    { lat: 49.2212845, lng: 21.7307636 },
    { lat: 49.2215204, lng: 21.7305842 },
    { lat: 49.2219068, lng: 21.7305596 },
    { lat: 49.2226062, lng: 21.7301695 },
    { lat: 49.2234833, lng: 21.7299022 },
    { lat: 49.2238602, lng: 21.7296889 },
    { lat: 49.2238864, lng: 21.729621 },
    { lat: 49.2239139, lng: 21.7295535 },
    { lat: 49.2244966, lng: 21.7292616 },
    { lat: 49.2245401, lng: 21.7291592 },
    { lat: 49.2247075, lng: 21.728221 },
    { lat: 49.2246529, lng: 21.7279034 },
    { lat: 49.2246991, lng: 21.7277803 },
    { lat: 49.2245717, lng: 21.727429 },
    { lat: 49.2244593, lng: 21.7266219 },
    { lat: 49.224575, lng: 21.7265737 },
    { lat: 49.224681, lng: 21.7266266 },
    { lat: 49.2248947, lng: 21.7264964 },
    { lat: 49.2249197, lng: 21.7263386 },
    { lat: 49.2250261, lng: 21.7261463 },
    { lat: 49.2251057, lng: 21.7261007 },
    { lat: 49.2252317, lng: 21.7258036 },
    { lat: 49.2253234, lng: 21.7258202 },
    { lat: 49.2259074, lng: 21.7252835 },
    { lat: 49.2264773, lng: 21.725395 },
    { lat: 49.2266436, lng: 21.7252577 },
    { lat: 49.2272463, lng: 21.7255882 },
    { lat: 49.22742, lng: 21.7259503 },
    { lat: 49.2274935, lng: 21.7257438 },
    { lat: 49.2276409, lng: 21.7256206 },
    { lat: 49.2277876, lng: 21.7256417 },
    { lat: 49.2283744, lng: 21.7251605 },
    { lat: 49.2284706, lng: 21.7253547 },
    { lat: 49.2298033, lng: 21.7238166 },
    { lat: 49.2307107, lng: 21.723502 },
    { lat: 49.2314181, lng: 21.7218078 },
    { lat: 49.232859, lng: 21.7188018 },
    { lat: 49.2333988, lng: 21.7178198 },
    { lat: 49.2335981, lng: 21.7163126 },
    { lat: 49.2342176, lng: 21.7166409 },
    { lat: 49.2347817, lng: 21.7172088 },
    { lat: 49.2347195, lng: 21.7176597 },
    { lat: 49.2348406, lng: 21.7178081 },
    { lat: 49.2350873, lng: 21.7178212 },
    { lat: 49.2353023, lng: 21.7182146 },
    { lat: 49.2352819, lng: 21.7184196 },
    { lat: 49.2353412, lng: 21.718746 },
    { lat: 49.2352681, lng: 21.7190375 },
    { lat: 49.2354426, lng: 21.7200586 },
    { lat: 49.2357213, lng: 21.720302 },
    { lat: 49.2357799, lng: 21.7205183 },
    { lat: 49.2362779, lng: 21.7213484 },
    { lat: 49.2365577, lng: 21.7220572 },
    { lat: 49.2365784, lng: 21.7219947 },
    { lat: 49.2366795, lng: 21.7223653 },
    { lat: 49.2365884, lng: 21.7224138 },
    { lat: 49.2366006, lng: 21.7242658 },
    { lat: 49.2366247, lng: 21.7242884 },
    { lat: 49.2368642, lng: 21.7223351 },
    { lat: 49.2365364, lng: 21.720079 },
    { lat: 49.23704, lng: 21.7179462 },
    { lat: 49.2374778, lng: 21.7141964 },
    { lat: 49.2378055, lng: 21.7120147 },
    { lat: 49.237822, lng: 21.7103606 },
    { lat: 49.2375584, lng: 21.7090782 },
    { lat: 49.23731, lng: 21.7081167 },
    { lat: 49.237443, lng: 21.7062736 },
    { lat: 49.2363196, lng: 21.7035941 },
    { lat: 49.2354732, lng: 21.6995 },
    { lat: 49.2345791, lng: 21.6987081 },
    { lat: 49.2328815, lng: 21.696563 },
    { lat: 49.231285, lng: 21.692477 },
    { lat: 49.2293338, lng: 21.6899007 },
    { lat: 49.2286542, lng: 21.6894333 },
    { lat: 49.2267661, lng: 21.6888731 },
    { lat: 49.2224344, lng: 21.6863299 },
    { lat: 49.2221652, lng: 21.6853999 },
    { lat: 49.2218987, lng: 21.6855253 },
    { lat: 49.2217917, lng: 21.6854377 },
    { lat: 49.2214266, lng: 21.6854934 },
    { lat: 49.2211922, lng: 21.6856912 },
    { lat: 49.2211067, lng: 21.6856495 },
    { lat: 49.2202083, lng: 21.6865839 },
    { lat: 49.2198485, lng: 21.6872139 },
    { lat: 49.2196094, lng: 21.688913 },
    { lat: 49.2192673, lng: 21.6906186 },
    { lat: 49.2170765, lng: 21.6950015 },
    { lat: 49.2166638, lng: 21.6961834 },
    { lat: 49.2166101, lng: 21.7003919 },
    { lat: 49.215576, lng: 21.7030687 },
    { lat: 49.215324, lng: 21.7036159 },
    { lat: 49.215017, lng: 21.7043763 },
  ],
  Staškovce: [
    { lat: 49.2657979, lng: 21.76432 },
    { lat: 49.2665243, lng: 21.766119 },
    { lat: 49.2666937, lng: 21.7664032 },
    { lat: 49.2667334, lng: 21.7668856 },
    { lat: 49.2669902, lng: 21.7672045 },
    { lat: 49.2671984, lng: 21.7673341 },
    { lat: 49.2673649, lng: 21.7675886 },
    { lat: 49.267478, lng: 21.7679224 },
    { lat: 49.2674687, lng: 21.7682929 },
    { lat: 49.2675809, lng: 21.7685814 },
    { lat: 49.2678405, lng: 21.7687485 },
    { lat: 49.2680029, lng: 21.7689724 },
    { lat: 49.2681453, lng: 21.7688776 },
    { lat: 49.2682191, lng: 21.7689397 },
    { lat: 49.2688018, lng: 21.7696796 },
    { lat: 49.2691534, lng: 21.7699571 },
    { lat: 49.2695346, lng: 21.7699133 },
    { lat: 49.2695877, lng: 21.7696629 },
    { lat: 49.2701965, lng: 21.7709152 },
    { lat: 49.2703253, lng: 21.7710731 },
    { lat: 49.2705636, lng: 21.7711551 },
    { lat: 49.2716873, lng: 21.7702475 },
    { lat: 49.272049, lng: 21.7707481 },
    { lat: 49.2722829, lng: 21.7713451 },
    { lat: 49.271953, lng: 21.7717168 },
    { lat: 49.2720392, lng: 21.7719604 },
    { lat: 49.2720125, lng: 21.7720089 },
    { lat: 49.2723361, lng: 21.7721736 },
    { lat: 49.2724819, lng: 21.77233 },
    { lat: 49.2725338, lng: 21.7725473 },
    { lat: 49.2727098, lng: 21.7728244 },
    { lat: 49.2729519, lng: 21.7729238 },
    { lat: 49.2734679, lng: 21.7733854 },
    { lat: 49.273634, lng: 21.7737175 },
    { lat: 49.2735432, lng: 21.773905 },
    { lat: 49.2740492, lng: 21.7747167 },
    { lat: 49.2741356, lng: 21.7747464 },
    { lat: 49.274513, lng: 21.7744254 },
    { lat: 49.2745085, lng: 21.7750291 },
    { lat: 49.2746774, lng: 21.7753394 },
    { lat: 49.274889, lng: 21.7764209 },
    { lat: 49.2748332, lng: 21.7766938 },
    { lat: 49.2746727, lng: 21.7769676 },
    { lat: 49.2745233, lng: 21.7774111 },
    { lat: 49.2744968, lng: 21.7780284 },
    { lat: 49.2747569, lng: 21.7782968 },
    { lat: 49.2750493, lng: 21.7783841 },
    { lat: 49.2756505, lng: 21.7781912 },
    { lat: 49.2760656, lng: 21.7781435 },
    { lat: 49.2763709, lng: 21.7783368 },
    { lat: 49.2764832, lng: 21.7787561 },
    { lat: 49.2766304, lng: 21.7789683 },
    { lat: 49.2778434, lng: 21.7803632 },
    { lat: 49.2780453, lng: 21.7807295 },
    { lat: 49.278177, lng: 21.7812187 },
    { lat: 49.2780598, lng: 21.781675 },
    { lat: 49.278035, lng: 21.781982 },
    { lat: 49.2783708, lng: 21.7826933 },
    { lat: 49.2785391, lng: 21.7833265 },
    { lat: 49.2792165, lng: 21.7842439 },
    { lat: 49.2796582, lng: 21.7846598 },
    { lat: 49.2809694, lng: 21.7850841 },
    { lat: 49.2814264, lng: 21.785479 },
    { lat: 49.2818416, lng: 21.7842349 },
    { lat: 49.2820327, lng: 21.7843133 },
    { lat: 49.2826281, lng: 21.7828669 },
    { lat: 49.2828611, lng: 21.7824377 },
    { lat: 49.283293, lng: 21.7811835 },
    { lat: 49.2834059, lng: 21.7803761 },
    { lat: 49.2833148, lng: 21.7795587 },
    { lat: 49.2833158, lng: 21.7792066 },
    { lat: 49.2830624, lng: 21.7789338 },
    { lat: 49.2824061, lng: 21.776807 },
    { lat: 49.282524, lng: 21.7765386 },
    { lat: 49.2826892, lng: 21.7757975 },
    { lat: 49.282833, lng: 21.7747597 },
    { lat: 49.2833556, lng: 21.7731465 },
    { lat: 49.2834367, lng: 21.772676 },
    { lat: 49.2843537, lng: 21.7715723 },
    { lat: 49.2845679, lng: 21.7714605 },
    { lat: 49.284722, lng: 21.7711981 },
    { lat: 49.2848635, lng: 21.7710627 },
    { lat: 49.28541, lng: 21.7707979 },
    { lat: 49.2859126, lng: 21.7702347 },
    { lat: 49.2862309, lng: 21.7697598 },
    { lat: 49.2863886, lng: 21.7692446 },
    { lat: 49.2890174, lng: 21.7668487 },
    { lat: 49.2895129, lng: 21.7683744 },
    { lat: 49.2899189, lng: 21.7688007 },
    { lat: 49.2900079, lng: 21.768031 },
    { lat: 49.2909519, lng: 21.7670958 },
    { lat: 49.2920852, lng: 21.7663014 },
    { lat: 49.2923953, lng: 21.7659487 },
    { lat: 49.293441, lng: 21.765589 },
    { lat: 49.293585, lng: 21.764957 },
    { lat: 49.293904, lng: 21.760445 },
    { lat: 49.293633, lng: 21.759902 },
    { lat: 49.293597, lng: 21.75968 },
    { lat: 49.293582, lng: 21.759608 },
    { lat: 49.293566, lng: 21.759537 },
    { lat: 49.293538, lng: 21.759403 },
    { lat: 49.293233, lng: 21.758941 },
    { lat: 49.293779, lng: 21.754276 },
    { lat: 49.29395, lng: 21.75282 },
    { lat: 49.29378, lng: 21.750569 },
    { lat: 49.293289, lng: 21.749839 },
    { lat: 49.293314, lng: 21.749539 },
    { lat: 49.29341, lng: 21.748461 },
    { lat: 49.29363, lng: 21.745947 },
    { lat: 49.293434, lng: 21.744803 },
    { lat: 49.293369, lng: 21.743707 },
    { lat: 49.293032, lng: 21.739642 },
    { lat: 49.2917075, lng: 21.7384206 },
    { lat: 49.2899521, lng: 21.736649 },
    { lat: 49.2872255, lng: 21.7346468 },
    { lat: 49.2849367, lng: 21.731936 },
    { lat: 49.2847759, lng: 21.731898 },
    { lat: 49.2845841, lng: 21.7316169 },
    { lat: 49.2844167, lng: 21.7306916 },
    { lat: 49.2843011, lng: 21.7305798 },
    { lat: 49.2841963, lng: 21.7306558 },
    { lat: 49.2840145, lng: 21.7304563 },
    { lat: 49.2838867, lng: 21.73047 },
    { lat: 49.2838023, lng: 21.7303015 },
    { lat: 49.2836953, lng: 21.7303388 },
    { lat: 49.2835899, lng: 21.7302221 },
    { lat: 49.2834504, lng: 21.7303914 },
    { lat: 49.2830329, lng: 21.7301333 },
    { lat: 49.2830067, lng: 21.7304596 },
    { lat: 49.2829361, lng: 21.7305086 },
    { lat: 49.2827569, lng: 21.7302823 },
    { lat: 49.2825993, lng: 21.7302416 },
    { lat: 49.2824546, lng: 21.7305093 },
    { lat: 49.2823132, lng: 21.7305656 },
    { lat: 49.2822844, lng: 21.7304131 },
    { lat: 49.2819795, lng: 21.7302836 },
    { lat: 49.2819227, lng: 21.730558 },
    { lat: 49.2818661, lng: 21.7306289 },
    { lat: 49.2817387, lng: 21.7304898 },
    { lat: 49.2816585, lng: 21.7307736 },
    { lat: 49.281442, lng: 21.730582 },
    { lat: 49.2812976, lng: 21.7307365 },
    { lat: 49.2811661, lng: 21.7307011 },
    { lat: 49.2811306, lng: 21.7308935 },
    { lat: 49.2809686, lng: 21.7312278 },
    { lat: 49.2810588, lng: 21.731367 },
    { lat: 49.2810796, lng: 21.7315589 },
    { lat: 49.2812056, lng: 21.7316419 },
    { lat: 49.2814357, lng: 21.732595 },
    { lat: 49.2815775, lng: 21.7328943 },
    { lat: 49.2816508, lng: 21.7333322 },
    { lat: 49.2818853, lng: 21.7336517 },
    { lat: 49.2819047, lng: 21.7338328 },
    { lat: 49.2821407, lng: 21.733904 },
    { lat: 49.2821742, lng: 21.7341909 },
    { lat: 49.2820805, lng: 21.7343923 },
    { lat: 49.281744, lng: 21.7355797 },
    { lat: 49.2815177, lng: 21.7361248 },
    { lat: 49.2812053, lng: 21.7366899 },
    { lat: 49.2809896, lng: 21.736958 },
    { lat: 49.2806502, lng: 21.7372189 },
    { lat: 49.2804968, lng: 21.7374614 },
    { lat: 49.2802206, lng: 21.7377376 },
    { lat: 49.2801155, lng: 21.7377116 },
    { lat: 49.2798465, lng: 21.7380489 },
    { lat: 49.2795064, lng: 21.7382907 },
    { lat: 49.2771008, lng: 21.7375908 },
    { lat: 49.2740525, lng: 21.7380139 },
    { lat: 49.2728085, lng: 21.738358 },
    { lat: 49.2717014, lng: 21.7378763 },
    { lat: 49.2703259, lng: 21.7384788 },
    { lat: 49.2699062, lng: 21.7378859 },
    { lat: 49.2682441, lng: 21.7379006 },
    { lat: 49.2676654, lng: 21.7402635 },
    { lat: 49.2668533, lng: 21.7400092 },
    { lat: 49.2660054, lng: 21.742017 },
    { lat: 49.2650869, lng: 21.7425476 },
    { lat: 49.2648229, lng: 21.7423481 },
    { lat: 49.2632579, lng: 21.7426772 },
    { lat: 49.2632148, lng: 21.7432572 },
    { lat: 49.2633541, lng: 21.7438112 },
    { lat: 49.2634327, lng: 21.7461921 },
    { lat: 49.263399, lng: 21.7463627 },
    { lat: 49.2638571, lng: 21.7466259 },
    { lat: 49.2636524, lng: 21.7471416 },
    { lat: 49.2643143, lng: 21.7483586 },
    { lat: 49.264551, lng: 21.7484582 },
    { lat: 49.2647995, lng: 21.7484624 },
    { lat: 49.2650484, lng: 21.7483822 },
    { lat: 49.2654303, lng: 21.7488759 },
    { lat: 49.2652186, lng: 21.7487834 },
    { lat: 49.2651663, lng: 21.7492325 },
    { lat: 49.2650121, lng: 21.7494819 },
    { lat: 49.2650097, lng: 21.7496041 },
    { lat: 49.2648637, lng: 21.7498263 },
    { lat: 49.2646827, lng: 21.7503442 },
    { lat: 49.2644805, lng: 21.7506967 },
    { lat: 49.2641551, lng: 21.7514975 },
    { lat: 49.2636047, lng: 21.753577 },
    { lat: 49.2636804, lng: 21.7541523 },
    { lat: 49.2635657, lng: 21.7549617 },
    { lat: 49.2636751, lng: 21.7557438 },
    { lat: 49.26376, lng: 21.7560573 },
    { lat: 49.2640497, lng: 21.7569403 },
    { lat: 49.2646174, lng: 21.7583418 },
    { lat: 49.2646376, lng: 21.7585549 },
    { lat: 49.2651542, lng: 21.7602758 },
    { lat: 49.2652548, lng: 21.7608596 },
    { lat: 49.2649091, lng: 21.761618 },
    { lat: 49.2655036, lng: 21.7620668 },
    { lat: 49.2657936, lng: 21.7620163 },
    { lat: 49.2657964, lng: 21.7623395 },
    { lat: 49.266093, lng: 21.7627103 },
    { lat: 49.2661809, lng: 21.7629283 },
    { lat: 49.2659937, lng: 21.7633377 },
    { lat: 49.2660329, lng: 21.7636397 },
    { lat: 49.2658727, lng: 21.763956 },
    { lat: 49.2657979, lng: 21.76432 },
  ],
  VyšnýHrabovec: [
    { lat: 49.1042453, lng: 21.6769317 },
    { lat: 49.1039125, lng: 21.6763918 },
    { lat: 49.1034951, lng: 21.6758482 },
    { lat: 49.103259, lng: 21.6749798 },
    { lat: 49.1032638, lng: 21.6748258 },
    { lat: 49.1031252, lng: 21.6745728 },
    { lat: 49.1029958, lng: 21.6741572 },
    { lat: 49.1028889, lng: 21.6740663 },
    { lat: 49.10276, lng: 21.6737979 },
    { lat: 49.1027052, lng: 21.6735572 },
    { lat: 49.1021776, lng: 21.6722681 },
    { lat: 49.1020882, lng: 21.6719186 },
    { lat: 49.1017888, lng: 21.6716218 },
    { lat: 49.101491, lng: 21.67147 },
    { lat: 49.1010783, lng: 21.6714748 },
    { lat: 49.1004553, lng: 21.6713204 },
    { lat: 49.0997006, lng: 21.6712382 },
    { lat: 49.0993016, lng: 21.6711276 },
    { lat: 49.0990295, lng: 21.670925 },
    { lat: 49.098782, lng: 21.6708393 },
    { lat: 49.0985229, lng: 21.671039 },
    { lat: 49.097507, lng: 21.6708261 },
    { lat: 49.097228, lng: 21.6707008 },
    { lat: 49.0970445, lng: 21.6707392 },
    { lat: 49.0953423, lng: 21.670358 },
    { lat: 49.0948371, lng: 21.6703151 },
    { lat: 49.0945793, lng: 21.670146 },
    { lat: 49.0942032, lng: 21.6702117 },
    { lat: 49.0929899, lng: 21.670733 },
    { lat: 49.0917068, lng: 21.6705875 },
    { lat: 49.0918547, lng: 21.6718522 },
    { lat: 49.0918085, lng: 21.6723831 },
    { lat: 49.0914556, lng: 21.6733141 },
    { lat: 49.0912519, lng: 21.675145 },
    { lat: 49.0914258, lng: 21.6759503 },
    { lat: 49.0889602, lng: 21.6774675 },
    { lat: 49.0873209, lng: 21.6778682 },
    { lat: 49.0852291, lng: 21.6780219 },
    { lat: 49.085484, lng: 21.6815813 },
    { lat: 49.0842764, lng: 21.6805203 },
    { lat: 49.0825397, lng: 21.6822383 },
    { lat: 49.082315, lng: 21.6827964 },
    { lat: 49.0819217, lng: 21.6830529 },
    { lat: 49.0816087, lng: 21.6835117 },
    { lat: 49.0804323, lng: 21.6821484 },
    { lat: 49.0803798, lng: 21.6823667 },
    { lat: 49.0805525, lng: 21.6830521 },
    { lat: 49.080815, lng: 21.6849883 },
    { lat: 49.0801337, lng: 21.6845432 },
    { lat: 49.0799924, lng: 21.6845211 },
    { lat: 49.0798327, lng: 21.6850597 },
    { lat: 49.0775848, lng: 21.689387 },
    { lat: 49.0769693, lng: 21.6892208 },
    { lat: 49.074265, lng: 21.690213 },
    { lat: 49.074413, lng: 21.690746 },
    { lat: 49.074829, lng: 21.691519 },
    { lat: 49.075299, lng: 21.692353 },
    { lat: 49.075687, lng: 21.69318 },
    { lat: 49.076262, lng: 21.694326 },
    { lat: 49.077719, lng: 21.696257 },
    { lat: 49.078263, lng: 21.699759 },
    { lat: 49.078126, lng: 21.701544 },
    { lat: 49.078004, lng: 21.703141 },
    { lat: 49.080563, lng: 21.707261 },
    { lat: 49.087344, lng: 21.718175 },
    { lat: 49.08912, lng: 21.718855 },
    { lat: 49.0895229, lng: 21.7192085 },
    { lat: 49.0901269, lng: 21.7192551 },
    { lat: 49.0907455, lng: 21.719205 },
    { lat: 49.0912539, lng: 21.7188204 },
    { lat: 49.0914982, lng: 21.7185321 },
    { lat: 49.0926509, lng: 21.7178075 },
    { lat: 49.0932632, lng: 21.7175215 },
    { lat: 49.0942255, lng: 21.7169483 },
    { lat: 49.0950134, lng: 21.7167556 },
    { lat: 49.0952999, lng: 21.7164087 },
    { lat: 49.0954544, lng: 21.7159174 },
    { lat: 49.0958781, lng: 21.7151804 },
    { lat: 49.0960944, lng: 21.7145226 },
    { lat: 49.0966533, lng: 21.7138853 },
    { lat: 49.097303, lng: 21.7134813 },
    { lat: 49.0974331, lng: 21.7133386 },
    { lat: 49.0972058, lng: 21.7122446 },
    { lat: 49.0970058, lng: 21.7116693 },
    { lat: 49.0969071, lng: 21.7111783 },
    { lat: 49.0968478, lng: 21.7103434 },
    { lat: 49.0971324, lng: 21.7095456 },
    { lat: 49.0968044, lng: 21.7086449 },
    { lat: 49.0967055, lng: 21.7079715 },
    { lat: 49.0968478, lng: 21.707315 },
    { lat: 49.0967077, lng: 21.7064152 },
    { lat: 49.096778, lng: 21.7063139 },
    { lat: 49.0967445, lng: 21.7061523 },
    { lat: 49.0968555, lng: 21.7059328 },
    { lat: 49.0968467, lng: 21.7056818 },
    { lat: 49.0969829, lng: 21.7053897 },
    { lat: 49.0970915, lng: 21.7038673 },
    { lat: 49.0969717, lng: 21.7034713 },
    { lat: 49.0969714, lng: 21.7031583 },
    { lat: 49.0968595, lng: 21.7030557 },
    { lat: 49.0968663, lng: 21.7026798 },
    { lat: 49.0967789, lng: 21.7024412 },
    { lat: 49.0967029, lng: 21.7024208 },
    { lat: 49.0966425, lng: 21.7021833 },
    { lat: 49.0966342, lng: 21.7018298 },
    { lat: 49.0965378, lng: 21.7017156 },
    { lat: 49.0968505, lng: 21.7015091 },
    { lat: 49.0971289, lng: 21.7010606 },
    { lat: 49.0977725, lng: 21.6996396 },
    { lat: 49.0981501, lng: 21.6991752 },
    { lat: 49.0987372, lng: 21.6979518 },
    { lat: 49.0992806, lng: 21.6965724 },
    { lat: 49.0994486, lng: 21.695789 },
    { lat: 49.0994579, lng: 21.6953831 },
    { lat: 49.0994074, lng: 21.6949673 },
    { lat: 49.0995261, lng: 21.69428 },
    { lat: 49.0994973, lng: 21.6937551 },
    { lat: 49.0995501, lng: 21.6922038 },
    { lat: 49.0994519, lng: 21.6917332 },
    { lat: 49.0994253, lng: 21.6913669 },
    { lat: 49.0994619, lng: 21.6912314 },
    { lat: 49.0994971, lng: 21.6901899 },
    { lat: 49.0994632, lng: 21.6896051 },
    { lat: 49.0995561, lng: 21.6883736 },
    { lat: 49.1003073, lng: 21.6875728 },
    { lat: 49.1003511, lng: 21.6868208 },
    { lat: 49.1008212, lng: 21.6861836 },
    { lat: 49.1011398, lng: 21.6849017 },
    { lat: 49.1016206, lng: 21.6836233 },
    { lat: 49.1017064, lng: 21.682804 },
    { lat: 49.1020681, lng: 21.6821147 },
    { lat: 49.1021105, lng: 21.6819411 },
    { lat: 49.1025172, lng: 21.6816715 },
    { lat: 49.1027086, lng: 21.6814621 },
    { lat: 49.1028612, lng: 21.6809922 },
    { lat: 49.1033496, lng: 21.6806234 },
    { lat: 49.1035871, lng: 21.6803396 },
    { lat: 49.1032755, lng: 21.6797133 },
    { lat: 49.1040221, lng: 21.678161 },
    { lat: 49.1041352, lng: 21.6777238 },
    { lat: 49.1041983, lng: 21.6770136 },
    { lat: 49.1042453, lng: 21.6769317 },
  ],
  Krišľovce: [
    { lat: 49.139603, lng: 21.744037 },
    { lat: 49.140375, lng: 21.7437973 },
    { lat: 49.1405764, lng: 21.743758 },
    { lat: 49.1408846, lng: 21.7438403 },
    { lat: 49.1411872, lng: 21.7437154 },
    { lat: 49.1414636, lng: 21.7432723 },
    { lat: 49.1421751, lng: 21.7425377 },
    { lat: 49.1423831, lng: 21.7419356 },
    { lat: 49.1423735, lng: 21.7418672 },
    { lat: 49.1421988, lng: 21.7413469 },
    { lat: 49.1420093, lng: 21.741073 },
    { lat: 49.1419016, lng: 21.7405769 },
    { lat: 49.1418712, lng: 21.7400589 },
    { lat: 49.1420391, lng: 21.7389678 },
    { lat: 49.1422826, lng: 21.7385648 },
    { lat: 49.1424175, lng: 21.7373694 },
    { lat: 49.1427539, lng: 21.7367382 },
    { lat: 49.1428733, lng: 21.7367402 },
    { lat: 49.1432529, lng: 21.7363363 },
    { lat: 49.1434169, lng: 21.7362903 },
    { lat: 49.1437472, lng: 21.7355082 },
    { lat: 49.1437257, lng: 21.7351164 },
    { lat: 49.1438705, lng: 21.734602 },
    { lat: 49.1440726, lng: 21.7341156 },
    { lat: 49.1442775, lng: 21.7338263 },
    { lat: 49.1444562, lng: 21.7336942 },
    { lat: 49.1444637, lng: 21.7334702 },
    { lat: 49.1442824, lng: 21.7328655 },
    { lat: 49.1445506, lng: 21.7319938 },
    { lat: 49.1446996, lng: 21.7316738 },
    { lat: 49.1444744, lng: 21.7311214 },
    { lat: 49.1446771, lng: 21.7308707 },
    { lat: 49.1447021, lng: 21.7304962 },
    { lat: 49.1444439, lng: 21.7305574 },
    { lat: 49.144186, lng: 21.7304224 },
    { lat: 49.1440114, lng: 21.7304188 },
    { lat: 49.1440129, lng: 21.7302606 },
    { lat: 49.1442646, lng: 21.729824 },
    { lat: 49.1439969, lng: 21.7297388 },
    { lat: 49.1439324, lng: 21.7292613 },
    { lat: 49.1436932, lng: 21.7285999 },
    { lat: 49.1436523, lng: 21.7281611 },
    { lat: 49.1437171, lng: 21.7277587 },
    { lat: 49.1441986, lng: 21.7266654 },
    { lat: 49.1445248, lng: 21.725564 },
    { lat: 49.1446877, lng: 21.7252554 },
    { lat: 49.1449334, lng: 21.7249391 },
    { lat: 49.1450846, lng: 21.7249168 },
    { lat: 49.1453818, lng: 21.7247126 },
    { lat: 49.1457148, lng: 21.724327 },
    { lat: 49.1455468, lng: 21.7238965 },
    { lat: 49.1457783, lng: 21.7237567 },
    { lat: 49.1462944, lng: 21.7231556 },
    { lat: 49.1463322, lng: 21.7228425 },
    { lat: 49.1466522, lng: 21.7222697 },
    { lat: 49.1467426, lng: 21.7222033 },
    { lat: 49.1467901, lng: 21.7216668 },
    { lat: 49.1467333, lng: 21.7215187 },
    { lat: 49.1463228, lng: 21.7210985 },
    { lat: 49.145871, lng: 21.7209066 },
    { lat: 49.1458524, lng: 21.7205815 },
    { lat: 49.1459006, lng: 21.719858 },
    { lat: 49.1458531, lng: 21.7190036 },
    { lat: 49.1457518, lng: 21.718795 },
    { lat: 49.1457141, lng: 21.718558 },
    { lat: 49.1458359, lng: 21.718194 },
    { lat: 49.1458126, lng: 21.7180334 },
    { lat: 49.145749, lng: 21.7180104 },
    { lat: 49.145745, lng: 21.7170214 },
    { lat: 49.1458971, lng: 21.7167745 },
    { lat: 49.1458973, lng: 21.7162337 },
    { lat: 49.1463542, lng: 21.7161123 },
    { lat: 49.1463236, lng: 21.7156029 },
    { lat: 49.1463572, lng: 21.7153431 },
    { lat: 49.1466435, lng: 21.7152769 },
    { lat: 49.1466484, lng: 21.7146636 },
    { lat: 49.1468075, lng: 21.7146357 },
    { lat: 49.1468561, lng: 21.7141447 },
    { lat: 49.1469427, lng: 21.7138468 },
    { lat: 49.1472718, lng: 21.7135731 },
    { lat: 49.1472903, lng: 21.7132518 },
    { lat: 49.1471732, lng: 21.7122403 },
    { lat: 49.1472675, lng: 21.7118162 },
    { lat: 49.1472952, lng: 21.7113369 },
    { lat: 49.1470768, lng: 21.7104061 },
    { lat: 49.1469582, lng: 21.7102971 },
    { lat: 49.1469011, lng: 21.7101393 },
    { lat: 49.1468325, lng: 21.7096782 },
    { lat: 49.1466063, lng: 21.709799 },
    { lat: 49.146401, lng: 21.7098261 },
    { lat: 49.1462633, lng: 21.7088346 },
    { lat: 49.1462787, lng: 21.7085311 },
    { lat: 49.1464464, lng: 21.7079005 },
    { lat: 49.1463738, lng: 21.7078813 },
    { lat: 49.1465299, lng: 21.7075313 },
    { lat: 49.1460946, lng: 21.7073219 },
    { lat: 49.1460338, lng: 21.7074461 },
    { lat: 49.1457518, lng: 21.7072951 },
    { lat: 49.1448846, lng: 21.7071284 },
    { lat: 49.1439897, lng: 21.7068179 },
    { lat: 49.1439061, lng: 21.7068827 },
    { lat: 49.1435827, lng: 21.7068655 },
    { lat: 49.1430901, lng: 21.7069931 },
    { lat: 49.1426219, lng: 21.7068595 },
    { lat: 49.1422998, lng: 21.706554 },
    { lat: 49.141361, lng: 21.7066109 },
    { lat: 49.1391285, lng: 21.7073052 },
    { lat: 49.1379485, lng: 21.7083177 },
    { lat: 49.1377235, lng: 21.7087395 },
    { lat: 49.1376347, lng: 21.7096644 },
    { lat: 49.1373662, lng: 21.7103547 },
    { lat: 49.1370641, lng: 21.7106666 },
    { lat: 49.1367453, lng: 21.7106885 },
    { lat: 49.1357769, lng: 21.7123014 },
    { lat: 49.1348483, lng: 21.7129974 },
    { lat: 49.1346985, lng: 21.7132377 },
    { lat: 49.1345245, lng: 21.7133782 },
    { lat: 49.1336669, lng: 21.7139968 },
    { lat: 49.1336537, lng: 21.7139596 },
    { lat: 49.133423, lng: 21.7142541 },
    { lat: 49.1327489, lng: 21.7142072 },
    { lat: 49.132379, lng: 21.7142923 },
    { lat: 49.1319983, lng: 21.7146198 },
    { lat: 49.1316689, lng: 21.715003 },
    { lat: 49.131141, lng: 21.7160445 },
    { lat: 49.1308722, lng: 21.7160935 },
    { lat: 49.1306836, lng: 21.7162686 },
    { lat: 49.130604, lng: 21.7165354 },
    { lat: 49.1295638, lng: 21.7167608 },
    { lat: 49.1289928, lng: 21.7171547 },
    { lat: 49.1284044, lng: 21.7179936 },
    { lat: 49.1281213, lng: 21.71856 },
    { lat: 49.1278489, lng: 21.7186807 },
    { lat: 49.1275767, lng: 21.7191056 },
    { lat: 49.1269608, lng: 21.7192497 },
    { lat: 49.1264923, lng: 21.7194841 },
    { lat: 49.126221, lng: 21.719698 },
    { lat: 49.126039, lng: 21.719901 },
    { lat: 49.126032, lng: 21.720083 },
    { lat: 49.126171, lng: 21.720393 },
    { lat: 49.126047, lng: 21.720746 },
    { lat: 49.126084, lng: 21.720903 },
    { lat: 49.125876, lng: 21.721238 },
    { lat: 49.125588, lng: 21.72166 },
    { lat: 49.125258, lng: 21.722018 },
    { lat: 49.125256, lng: 21.72212 },
    { lat: 49.124943, lng: 21.722634 },
    { lat: 49.125307, lng: 21.723022 },
    { lat: 49.125888, lng: 21.723703 },
    { lat: 49.12643, lng: 21.724421 },
    { lat: 49.126601, lng: 21.72475 },
    { lat: 49.12662, lng: 21.724939 },
    { lat: 49.126609, lng: 21.725194 },
    { lat: 49.126531, lng: 21.725448 },
    { lat: 49.126513, lng: 21.725732 },
    { lat: 49.126535, lng: 21.725911 },
    { lat: 49.126497, lng: 21.72625 },
    { lat: 49.126578, lng: 21.726477 },
    { lat: 49.126689, lng: 21.726644 },
    { lat: 49.126783, lng: 21.727119 },
    { lat: 49.126807, lng: 21.727478 },
    { lat: 49.126947, lng: 21.727922 },
    { lat: 49.126994, lng: 21.728185 },
    { lat: 49.127004, lng: 21.728454 },
    { lat: 49.127076, lng: 21.728824 },
    { lat: 49.127217, lng: 21.729005 },
    { lat: 49.127233, lng: 21.729296 },
    { lat: 49.12745, lng: 21.72977 },
    { lat: 49.127448, lng: 21.730012 },
    { lat: 49.127491, lng: 21.730575 },
    { lat: 49.127662, lng: 21.731101 },
    { lat: 49.127735, lng: 21.731853 },
    { lat: 49.127659, lng: 21.73216 },
    { lat: 49.127696, lng: 21.732514 },
    { lat: 49.127755, lng: 21.732891 },
    { lat: 49.127606, lng: 21.733288 },
    { lat: 49.127693, lng: 21.733609 },
    { lat: 49.127651, lng: 21.733945 },
    { lat: 49.127667, lng: 21.734036 },
    { lat: 49.127575, lng: 21.734243 },
    { lat: 49.127703, lng: 21.734423 },
    { lat: 49.12748, lng: 21.734467 },
    { lat: 49.127513, lng: 21.734795 },
    { lat: 49.127359, lng: 21.734908 },
    { lat: 49.127203, lng: 21.735088 },
    { lat: 49.127136, lng: 21.735091 },
    { lat: 49.127083, lng: 21.735135 },
    { lat: 49.126942, lng: 21.735393 },
    { lat: 49.12694, lng: 21.735703 },
    { lat: 49.127062, lng: 21.73584 },
    { lat: 49.127096, lng: 21.736089 },
    { lat: 49.127314, lng: 21.736083 },
    { lat: 49.127402, lng: 21.736193 },
    { lat: 49.12761, lng: 21.736247 },
    { lat: 49.127698, lng: 21.736325 },
    { lat: 49.127856, lng: 21.73656 },
    { lat: 49.127953, lng: 21.73676 },
    { lat: 49.128037, lng: 21.736876 },
    { lat: 49.12812, lng: 21.736834 },
    { lat: 49.128277, lng: 21.736829 },
    { lat: 49.128357, lng: 21.737002 },
    { lat: 49.128437, lng: 21.737103 },
    { lat: 49.128678, lng: 21.737357 },
    { lat: 49.129188, lng: 21.737497 },
    { lat: 49.129389, lng: 21.737474 },
    { lat: 49.12956, lng: 21.737493 },
    { lat: 49.129749, lng: 21.737671 },
    { lat: 49.130694, lng: 21.738551 },
    { lat: 49.131992, lng: 21.739209 },
    { lat: 49.132162, lng: 21.739747 },
    { lat: 49.132399, lng: 21.740417 },
    { lat: 49.132458, lng: 21.740776 },
    { lat: 49.132382, lng: 21.741239 },
    { lat: 49.132314, lng: 21.742733 },
    { lat: 49.132351, lng: 21.742857 },
    { lat: 49.132543, lng: 21.742753 },
    { lat: 49.132856, lng: 21.74298 },
    { lat: 49.133265, lng: 21.743087 },
    { lat: 49.13341, lng: 21.743187 },
    { lat: 49.134006, lng: 21.742875 },
    { lat: 49.134262, lng: 21.742774 },
    { lat: 49.134914, lng: 21.742592 },
    { lat: 49.135181, lng: 21.742561 },
    { lat: 49.135517, lng: 21.742341 },
    { lat: 49.135895, lng: 21.743387 },
    { lat: 49.136082, lng: 21.743755 },
    { lat: 49.136347, lng: 21.743244 },
    { lat: 49.136512, lng: 21.743196 },
    { lat: 49.136714, lng: 21.743298 },
    { lat: 49.137462, lng: 21.74437 },
    { lat: 49.138086, lng: 21.744126 },
    { lat: 49.138505, lng: 21.744125 },
    { lat: 49.138969, lng: 21.744049 },
    { lat: 49.139322, lng: 21.744077 },
    { lat: 49.139603, lng: 21.744037 },
  ],
  Tisinec: [
    { lat: 49.2365214, lng: 21.6471134 },
    { lat: 49.2362147, lng: 21.6469633 },
    { lat: 49.2359867, lng: 21.6466505 },
    { lat: 49.2352933, lng: 21.6467946 },
    { lat: 49.2352436, lng: 21.6462917 },
    { lat: 49.23501, lng: 21.6461013 },
    { lat: 49.2342265, lng: 21.6459581 },
    { lat: 49.2339091, lng: 21.6452547 },
    { lat: 49.2315296, lng: 21.6448696 },
    { lat: 49.2311949, lng: 21.6444715 },
    { lat: 49.2311968, lng: 21.6435553 },
    { lat: 49.2309806, lng: 21.6425072 },
    { lat: 49.23078, lng: 21.6424123 },
    { lat: 49.2299939, lng: 21.6399549 },
    { lat: 49.2291489, lng: 21.6378408 },
    { lat: 49.2289194, lng: 21.6374731 },
    { lat: 49.2278765, lng: 21.6370561 },
    { lat: 49.2265135, lng: 21.6369882 },
    { lat: 49.2261929, lng: 21.6352605 },
    { lat: 49.2256072, lng: 21.6333416 },
    { lat: 49.2250996, lng: 21.6338239 },
    { lat: 49.2249725, lng: 21.6334598 },
    { lat: 49.2247172, lng: 21.6326325 },
    { lat: 49.2246847, lng: 21.6323913 },
    { lat: 49.2248073, lng: 21.6309517 },
    { lat: 49.2238397, lng: 21.6286921 },
    { lat: 49.2247686, lng: 21.6264575 },
    { lat: 49.2236956, lng: 21.6208928 },
    { lat: 49.2235087, lng: 21.6220342 },
    { lat: 49.2224219, lng: 21.6256339 },
    { lat: 49.2224296, lng: 21.6262049 },
    { lat: 49.2213508, lng: 21.6291612 },
    { lat: 49.2205749, lng: 21.6296235 },
    { lat: 49.2186797, lng: 21.6313221 },
    { lat: 49.2181221, lng: 21.6315426 },
    { lat: 49.2169204, lng: 21.6337068 },
    { lat: 49.2146879, lng: 21.6335655 },
    { lat: 49.2129958, lng: 21.6349511 },
    { lat: 49.211077, lng: 21.6357476 },
    { lat: 49.2095902, lng: 21.6362546 },
    { lat: 49.2092853, lng: 21.6362165 },
    { lat: 49.2083813, lng: 21.6370087 },
    { lat: 49.2087651, lng: 21.639858 },
    { lat: 49.208784, lng: 21.6398499 },
    { lat: 49.2093165, lng: 21.6400334 },
    { lat: 49.209547, lng: 21.6406099 },
    { lat: 49.2092539, lng: 21.6412898 },
    { lat: 49.208941, lng: 21.6417681 },
    { lat: 49.2090279, lng: 21.6425681 },
    { lat: 49.2091578, lng: 21.6430791 },
    { lat: 49.2091504, lng: 21.6432231 },
    { lat: 49.2094556, lng: 21.6433595 },
    { lat: 49.2097957, lng: 21.6436174 },
    { lat: 49.210739, lng: 21.6445912 },
    { lat: 49.2118439, lng: 21.645299 },
    { lat: 49.2133617, lng: 21.6475404 },
    { lat: 49.213481, lng: 21.6500185 },
    { lat: 49.2139296, lng: 21.6507173 },
    { lat: 49.2145357, lng: 21.6519066 },
    { lat: 49.2153199, lng: 21.6525291 },
    { lat: 49.2167052, lng: 21.6532549 },
    { lat: 49.2171499, lng: 21.6532674 },
    { lat: 49.218017, lng: 21.6537145 },
    { lat: 49.218317, lng: 21.654241 },
    { lat: 49.218435, lng: 21.6546081 },
    { lat: 49.2194904, lng: 21.6546971 },
    { lat: 49.2200157, lng: 21.654856 },
    { lat: 49.2218673, lng: 21.6567671 },
    { lat: 49.2223249, lng: 21.6570041 },
    { lat: 49.2230101, lng: 21.6578 },
    { lat: 49.2260159, lng: 21.6604506 },
    { lat: 49.226335, lng: 21.6596484 },
    { lat: 49.2269755, lng: 21.6583834 },
    { lat: 49.2276717, lng: 21.6573762 },
    { lat: 49.2279467, lng: 21.6566102 },
    { lat: 49.2290341, lng: 21.6548986 },
    { lat: 49.2292062, lng: 21.6553535 },
    { lat: 49.2312001, lng: 21.6517296 },
    { lat: 49.2338098, lng: 21.649166 },
    { lat: 49.2350228, lng: 21.6480606 },
    { lat: 49.235698, lng: 21.6478219 },
    { lat: 49.2365214, lng: 21.6471134 },
  ],
  Korunková: [
    { lat: 49.1914973, lng: 21.7200939 },
    { lat: 49.1910658, lng: 21.721036 },
    { lat: 49.1904612, lng: 21.7227109 },
    { lat: 49.19021, lng: 21.7246387 },
    { lat: 49.1902715, lng: 21.7255164 },
    { lat: 49.1902633, lng: 21.7263088 },
    { lat: 49.1900548, lng: 21.7268874 },
    { lat: 49.189508, lng: 21.7273089 },
    { lat: 49.1885392, lng: 21.7286948 },
    { lat: 49.1878846, lng: 21.730008 },
    { lat: 49.1876181, lng: 21.7311242 },
    { lat: 49.187494, lng: 21.7314465 },
    { lat: 49.1874941, lng: 21.7318673 },
    { lat: 49.1871241, lng: 21.7321282 },
    { lat: 49.1858479, lng: 21.7334278 },
    { lat: 49.1849426, lng: 21.7341979 },
    { lat: 49.1847041, lng: 21.7344488 },
    { lat: 49.1846441, lng: 21.7346018 },
    { lat: 49.1844025, lng: 21.7347443 },
    { lat: 49.1841054, lng: 21.7350791 },
    { lat: 49.1839374, lng: 21.7355278 },
    { lat: 49.1840087, lng: 21.7357198 },
    { lat: 49.1840707, lng: 21.7357645 },
    { lat: 49.1838953, lng: 21.736324 },
    { lat: 49.184342, lng: 21.7370006 },
    { lat: 49.184431, lng: 21.7370052 },
    { lat: 49.1845325, lng: 21.7368943 },
    { lat: 49.1845031, lng: 21.7372266 },
    { lat: 49.1846041, lng: 21.7372117 },
    { lat: 49.1852324, lng: 21.7374466 },
    { lat: 49.1856131, lng: 21.7374671 },
    { lat: 49.1857586, lng: 21.7379065 },
    { lat: 49.1857439, lng: 21.7386313 },
    { lat: 49.1859724, lng: 21.7396493 },
    { lat: 49.1859143, lng: 21.7399208 },
    { lat: 49.1860088, lng: 21.7402606 },
    { lat: 49.185951, lng: 21.7406752 },
    { lat: 49.1859687, lng: 21.7414264 },
    { lat: 49.1862488, lng: 21.7421524 },
    { lat: 49.1862875, lng: 21.7431857 },
    { lat: 49.1858725, lng: 21.7433609 },
    { lat: 49.1851996, lng: 21.7446299 },
    { lat: 49.1850327, lng: 21.7452112 },
    { lat: 49.1850116, lng: 21.7471414 },
    { lat: 49.1852571, lng: 21.7483008 },
    { lat: 49.1855326, lng: 21.7491919 },
    { lat: 49.1850086, lng: 21.7495855 },
    { lat: 49.1849399, lng: 21.7497939 },
    { lat: 49.1848332, lng: 21.7499328 },
    { lat: 49.184708, lng: 21.7504328 },
    { lat: 49.1846563, lng: 21.751107 },
    { lat: 49.1845822, lng: 21.7514643 },
    { lat: 49.1845983, lng: 21.7518128 },
    { lat: 49.1848037, lng: 21.7529089 },
    { lat: 49.1847681, lng: 21.7532217 },
    { lat: 49.1848175, lng: 21.7537729 },
    { lat: 49.1847352, lng: 21.7541925 },
    { lat: 49.1842562, lng: 21.754324 },
    { lat: 49.1842144, lng: 21.7551745 },
    { lat: 49.1843178, lng: 21.7561991 },
    { lat: 49.1845798, lng: 21.7569659 },
    { lat: 49.1843851, lng: 21.7574773 },
    { lat: 49.183893, lng: 21.758269 },
    { lat: 49.1831904, lng: 21.7598662 },
    { lat: 49.1830595, lng: 21.7610047 },
    { lat: 49.1830548, lng: 21.7619004 },
    { lat: 49.182219, lng: 21.7632657 },
    { lat: 49.1819986, lng: 21.7639595 },
    { lat: 49.181898, lng: 21.764636 },
    { lat: 49.182181, lng: 21.764808 },
    { lat: 49.182563, lng: 21.76505 },
    { lat: 49.182809, lng: 21.765083 },
    { lat: 49.183577, lng: 21.765812 },
    { lat: 49.184177, lng: 21.765951 },
    { lat: 49.184614, lng: 21.766221 },
    { lat: 49.18486, lng: 21.766575 },
    { lat: 49.185614, lng: 21.767061 },
    { lat: 49.185976, lng: 21.767245 },
    { lat: 49.186873, lng: 21.767905 },
    { lat: 49.187177, lng: 21.768056 },
    { lat: 49.18749, lng: 21.768267 },
    { lat: 49.188221, lng: 21.768631 },
    { lat: 49.188688, lng: 21.769081 },
    { lat: 49.18886, lng: 21.769335 },
    { lat: 49.18906, lng: 21.76954 },
    { lat: 49.189339, lng: 21.7697 },
    { lat: 49.190227, lng: 21.770659 },
    { lat: 49.190511, lng: 21.771492 },
    { lat: 49.19094, lng: 21.771908 },
    { lat: 49.191344, lng: 21.772331 },
    { lat: 49.19164, lng: 21.772667 },
    { lat: 49.192005, lng: 21.773028 },
    { lat: 49.192227, lng: 21.773195 },
    { lat: 49.192707, lng: 21.773643 },
    { lat: 49.193004, lng: 21.774426 },
    { lat: 49.192984, lng: 21.774965 },
    { lat: 49.192406, lng: 21.776064 },
    { lat: 49.192262, lng: 21.776652 },
    { lat: 49.192075, lng: 21.777535 },
    { lat: 49.19178, lng: 21.778229 },
    { lat: 49.191347, lng: 21.779185 },
    { lat: 49.191445, lng: 21.780474 },
    { lat: 49.191138, lng: 21.781214 },
    { lat: 49.19163, lng: 21.781241 },
    { lat: 49.191823, lng: 21.781312 },
    { lat: 49.191968, lng: 21.781467 },
    { lat: 49.192122, lng: 21.78158 },
    { lat: 49.192217, lng: 21.781583 },
    { lat: 49.192415, lng: 21.781495 },
    { lat: 49.192596, lng: 21.781454 },
    { lat: 49.192739, lng: 21.781509 },
    { lat: 49.19311, lng: 21.781703 },
    { lat: 49.193174, lng: 21.781862 },
    { lat: 49.193688, lng: 21.782579 },
    { lat: 49.193742, lng: 21.782826 },
    { lat: 49.193757, lng: 21.783148 },
    { lat: 49.193815, lng: 21.783693 },
    { lat: 49.193933, lng: 21.784063 },
    { lat: 49.194092, lng: 21.784301 },
    { lat: 49.194111, lng: 21.784513 },
    { lat: 49.194088, lng: 21.785417 },
    { lat: 49.19406, lng: 21.78565 },
    { lat: 49.194134, lng: 21.786261 },
    { lat: 49.194007, lng: 21.786381 },
    { lat: 49.193801, lng: 21.786956 },
    { lat: 49.193786, lng: 21.787117 },
    { lat: 49.193605, lng: 21.787869 },
    { lat: 49.193529, lng: 21.788037 },
    { lat: 49.193695, lng: 21.788258 },
    { lat: 49.195112, lng: 21.788235 },
    { lat: 49.195692, lng: 21.788149 },
    { lat: 49.196515, lng: 21.787928 },
    { lat: 49.196721, lng: 21.787816 },
    { lat: 49.197246, lng: 21.787677 },
    { lat: 49.197852, lng: 21.787785 },
    { lat: 49.198078, lng: 21.788331 },
    { lat: 49.198335, lng: 21.78942 },
    { lat: 49.19875, lng: 21.789429 },
    { lat: 49.199171, lng: 21.788971 },
    { lat: 49.199397, lng: 21.788825 },
    { lat: 49.199687, lng: 21.788702 },
    { lat: 49.200671, lng: 21.788249 },
    { lat: 49.200844, lng: 21.787914 },
    { lat: 49.201244, lng: 21.78784 },
    { lat: 49.201927, lng: 21.787431 },
    { lat: 49.203013, lng: 21.787199 },
    { lat: 49.203441, lng: 21.78692 },
    { lat: 49.203755, lng: 21.786768 },
    { lat: 49.203978, lng: 21.786808 },
    { lat: 49.204422, lng: 21.786771 },
    { lat: 49.205077, lng: 21.786637 },
    { lat: 49.206878, lng: 21.785905 },
    { lat: 49.207394, lng: 21.7856 },
    { lat: 49.2087569, lng: 21.7840773 },
    { lat: 49.2092708, lng: 21.7836432 },
    { lat: 49.2098505, lng: 21.7830046 },
    { lat: 49.2106414, lng: 21.7824572 },
    { lat: 49.2111175, lng: 21.7817781 },
    { lat: 49.2113378, lng: 21.7815496 },
    { lat: 49.2127126, lng: 21.7782833 },
    { lat: 49.2135447, lng: 21.7779319 },
    { lat: 49.21406, lng: 21.7775468 },
    { lat: 49.2144103, lng: 21.7774402 },
    { lat: 49.2148964, lng: 21.7771451 },
    { lat: 49.2155007, lng: 21.7764984 },
    { lat: 49.2156079, lng: 21.7761735 },
    { lat: 49.2155464, lng: 21.7758858 },
    { lat: 49.2156204, lng: 21.7756582 },
    { lat: 49.2154532, lng: 21.7751171 },
    { lat: 49.2153278, lng: 21.7744476 },
    { lat: 49.215367, lng: 21.7740088 },
    { lat: 49.2153131, lng: 21.7735421 },
    { lat: 49.215475, lng: 21.7722553 },
    { lat: 49.2156417, lng: 21.7715719 },
    { lat: 49.2155637, lng: 21.7711298 },
    { lat: 49.2155656, lng: 21.7701218 },
    { lat: 49.2156279, lng: 21.7695412 },
    { lat: 49.2153592, lng: 21.7691992 },
    { lat: 49.2144786, lng: 21.765556 },
    { lat: 49.2138168, lng: 21.7642569 },
    { lat: 49.2134028, lng: 21.763741 },
    { lat: 49.213565, lng: 21.7635372 },
    { lat: 49.2136957, lng: 21.7631992 },
    { lat: 49.2136522, lng: 21.7628694 },
    { lat: 49.2134564, lng: 21.7627015 },
    { lat: 49.2133466, lng: 21.7622808 },
    { lat: 49.2131105, lng: 21.7604106 },
    { lat: 49.2128856, lng: 21.7597249 },
    { lat: 49.2129518, lng: 21.7592862 },
    { lat: 49.2130545, lng: 21.7589587 },
    { lat: 49.2132491, lng: 21.7586585 },
    { lat: 49.2130774, lng: 21.7574806 },
    { lat: 49.213251, lng: 21.7570202 },
    { lat: 49.2128195, lng: 21.7558856 },
    { lat: 49.2127026, lng: 21.7554179 },
    { lat: 49.21277, lng: 21.7548535 },
    { lat: 49.2128978, lng: 21.7547924 },
    { lat: 49.2128349, lng: 21.7542846 },
    { lat: 49.2127311, lng: 21.7540647 },
    { lat: 49.2127609, lng: 21.7539531 },
    { lat: 49.2129092, lng: 21.7539027 },
    { lat: 49.2130791, lng: 21.7536469 },
    { lat: 49.2133171, lng: 21.7529911 },
    { lat: 49.2127052, lng: 21.7519643 },
    { lat: 49.2127791, lng: 21.7515723 },
    { lat: 49.2130657, lng: 21.7513036 },
    { lat: 49.2125922, lng: 21.7504925 },
    { lat: 49.2118961, lng: 21.7496491 },
    { lat: 49.2119473, lng: 21.7494988 },
    { lat: 49.211374, lng: 21.7490051 },
    { lat: 49.2114545, lng: 21.748689 },
    { lat: 49.2112903, lng: 21.7480932 },
    { lat: 49.2113631, lng: 21.7475638 },
    { lat: 49.2112751, lng: 21.7473389 },
    { lat: 49.2112812, lng: 21.7470151 },
    { lat: 49.2103885, lng: 21.7465691 },
    { lat: 49.2100852, lng: 21.7462852 },
    { lat: 49.2102089, lng: 21.7459876 },
    { lat: 49.209738, lng: 21.7455802 },
    { lat: 49.208412, lng: 21.7450314 },
    { lat: 49.2084122, lng: 21.744913 },
    { lat: 49.2074641, lng: 21.7447055 },
    { lat: 49.2062641, lng: 21.7442447 },
    { lat: 49.2059576, lng: 21.7440549 },
    { lat: 49.2060214, lng: 21.7438265 },
    { lat: 49.205534, lng: 21.7435197 },
    { lat: 49.2054266, lng: 21.7433256 },
    { lat: 49.2053621, lng: 21.743481 },
    { lat: 49.2052004, lng: 21.7432403 },
    { lat: 49.2053448, lng: 21.7427457 },
    { lat: 49.2045455, lng: 21.7420772 },
    { lat: 49.2041794, lng: 21.7415929 },
    { lat: 49.2037222, lng: 21.7413653 },
    { lat: 49.2031095, lng: 21.7407828 },
    { lat: 49.2028081, lng: 21.7402258 },
    { lat: 49.2026243, lng: 21.7400853 },
    { lat: 49.2021826, lng: 21.7392032 },
    { lat: 49.2018465, lng: 21.7390447 },
    { lat: 49.20182, lng: 21.7384262 },
    { lat: 49.2011252, lng: 21.7381902 },
    { lat: 49.2010948, lng: 21.7380119 },
    { lat: 49.2007986, lng: 21.7378291 },
    { lat: 49.2004298, lng: 21.7378752 },
    { lat: 49.2003421, lng: 21.7372443 },
    { lat: 49.1998699, lng: 21.7372942 },
    { lat: 49.1995884, lng: 21.7348645 },
    { lat: 49.199757, lng: 21.7346861 },
    { lat: 49.1995079, lng: 21.733354 },
    { lat: 49.1995216, lng: 21.7331499 },
    { lat: 49.1994386, lng: 21.7329033 },
    { lat: 49.1993805, lng: 21.7322392 },
    { lat: 49.1994893, lng: 21.7320138 },
    { lat: 49.1993976, lng: 21.7312324 },
    { lat: 49.1994247, lng: 21.7304304 },
    { lat: 49.1992611, lng: 21.7301549 },
    { lat: 49.1992585, lng: 21.7300116 },
    { lat: 49.1986427, lng: 21.7300791 },
    { lat: 49.1983244, lng: 21.729784 },
    { lat: 49.1981278, lng: 21.7293735 },
    { lat: 49.1978586, lng: 21.7285122 },
    { lat: 49.197563, lng: 21.7259842 },
    { lat: 49.1973639, lng: 21.7249681 },
    { lat: 49.1969872, lng: 21.7239544 },
    { lat: 49.1969626, lng: 21.7232779 },
    { lat: 49.1970475, lng: 21.7227572 },
    { lat: 49.1970216, lng: 21.7222859 },
    { lat: 49.1971106, lng: 21.722294 },
    { lat: 49.1969782, lng: 21.7220472 },
    { lat: 49.1968527, lng: 21.7216003 },
    { lat: 49.1968232, lng: 21.7208295 },
    { lat: 49.1964126, lng: 21.7203708 },
    { lat: 49.1962505, lng: 21.7200705 },
    { lat: 49.1962408, lng: 21.7199527 },
    { lat: 49.1958711, lng: 21.7193764 },
    { lat: 49.1957299, lng: 21.7189762 },
    { lat: 49.1954511, lng: 21.7179245 },
    { lat: 49.1946321, lng: 21.7180563 },
    { lat: 49.1944728, lng: 21.7180651 },
    { lat: 49.1941379, lng: 21.7178636 },
    { lat: 49.193046, lng: 21.7182608 },
    { lat: 49.1921581, lng: 21.7190074 },
    { lat: 49.1917211, lng: 21.7196186 },
    { lat: 49.1914973, lng: 21.7200939 },
  ],
  Stropkov: [
    { lat: 49.174829, lng: 21.6985156 },
    { lat: 49.1749557, lng: 21.6995061 },
    { lat: 49.1750333, lng: 21.7008535 },
    { lat: 49.1751399, lng: 21.7010482 },
    { lat: 49.1750889, lng: 21.7017054 },
    { lat: 49.1743179, lng: 21.7048048 },
    { lat: 49.1738293, lng: 21.7054597 },
    { lat: 49.1737559, lng: 21.7059953 },
    { lat: 49.1737948, lng: 21.7062751 },
    { lat: 49.1737471, lng: 21.7065111 },
    { lat: 49.1737574, lng: 21.7068538 },
    { lat: 49.1739057, lng: 21.7077707 },
    { lat: 49.1738249, lng: 21.7084506 },
    { lat: 49.1738764, lng: 21.70857 },
    { lat: 49.1737258, lng: 21.7099991 },
    { lat: 49.173501, lng: 21.7102234 },
    { lat: 49.1735315, lng: 21.7102722 },
    { lat: 49.173452, lng: 21.7105791 },
    { lat: 49.1735043, lng: 21.7108491 },
    { lat: 49.1734612, lng: 21.7110463 },
    { lat: 49.173534, lng: 21.7114135 },
    { lat: 49.1733457, lng: 21.711523 },
    { lat: 49.1732156, lng: 21.7120894 },
    { lat: 49.1733768, lng: 21.7125206 },
    { lat: 49.1729351, lng: 21.7131667 },
    { lat: 49.1728894, lng: 21.7133102 },
    { lat: 49.1729357, lng: 21.7135687 },
    { lat: 49.173202, lng: 21.7136028 },
    { lat: 49.1738238, lng: 21.7132122 },
    { lat: 49.1742011, lng: 21.7132247 },
    { lat: 49.1742843, lng: 21.7135266 },
    { lat: 49.1744586, lng: 21.7138106 },
    { lat: 49.1750559, lng: 21.7141093 },
    { lat: 49.1752962, lng: 21.7141307 },
    { lat: 49.1754197, lng: 21.7142554 },
    { lat: 49.1754117, lng: 21.7144572 },
    { lat: 49.1757053, lng: 21.7147985 },
    { lat: 49.1760595, lng: 21.7150422 },
    { lat: 49.1768823, lng: 21.7149336 },
    { lat: 49.1772467, lng: 21.7149757 },
    { lat: 49.177296, lng: 21.7151791 },
    { lat: 49.1773647, lng: 21.7152201 },
    { lat: 49.1775043, lng: 21.7150522 },
    { lat: 49.1775885, lng: 21.71518 },
    { lat: 49.1776755, lng: 21.7151534 },
    { lat: 49.1778441, lng: 21.7152713 },
    { lat: 49.1781404, lng: 21.715163 },
    { lat: 49.1783113, lng: 21.7149057 },
    { lat: 49.1783568, lng: 21.7150054 },
    { lat: 49.1783486, lng: 21.7149074 },
    { lat: 49.1784622, lng: 21.7148748 },
    { lat: 49.1784487, lng: 21.7146128 },
    { lat: 49.1785327, lng: 21.7145572 },
    { lat: 49.1786734, lng: 21.7142731 },
    { lat: 49.1787542, lng: 21.7142605 },
    { lat: 49.1792185, lng: 21.7138681 },
    { lat: 49.1796596, lng: 21.7136692 },
    { lat: 49.1798944, lng: 21.7134821 },
    { lat: 49.1799196, lng: 21.7131662 },
    { lat: 49.179969, lng: 21.7132052 },
    { lat: 49.1800304, lng: 21.7131025 },
    { lat: 49.1803468, lng: 21.7130264 },
    { lat: 49.1807105, lng: 21.7126202 },
    { lat: 49.1810384, lng: 21.712603 },
    { lat: 49.1812909, lng: 21.7127441 },
    { lat: 49.1815233, lng: 21.7127707 },
    { lat: 49.1816137, lng: 21.7127021 },
    { lat: 49.1817945, lng: 21.7130207 },
    { lat: 49.1818974, lng: 21.7128755 },
    { lat: 49.1821776, lng: 21.7127922 },
    { lat: 49.1823297, lng: 21.7128486 },
    { lat: 49.1824244, lng: 21.7130657 },
    { lat: 49.1825954, lng: 21.7131928 },
    { lat: 49.1829579, lng: 21.7130322 },
    { lat: 49.1831699, lng: 21.7130974 },
    { lat: 49.1836348, lng: 21.7130207 },
    { lat: 49.1839613, lng: 21.712908 },
    { lat: 49.183999, lng: 21.7128014 },
    { lat: 49.1842065, lng: 21.7128194 },
    { lat: 49.1845405, lng: 21.7127307 },
    { lat: 49.1847602, lng: 21.7127843 },
    { lat: 49.1849928, lng: 21.7126957 },
    { lat: 49.1852277, lng: 21.7127257 },
    { lat: 49.1853314, lng: 21.7128222 },
    { lat: 49.185422, lng: 21.7132657 },
    { lat: 49.185465, lng: 21.7130652 },
    { lat: 49.1856207, lng: 21.7132019 },
    { lat: 49.1857502, lng: 21.7131727 },
    { lat: 49.1860338, lng: 21.7136837 },
    { lat: 49.1861917, lng: 21.7138256 },
    { lat: 49.1863603, lng: 21.713818 },
    { lat: 49.186352, lng: 21.7140987 },
    { lat: 49.1865749, lng: 21.7139813 },
    { lat: 49.1866414, lng: 21.7143195 },
    { lat: 49.1868075, lng: 21.7143844 },
    { lat: 49.1869605, lng: 21.7143069 },
    { lat: 49.1872427, lng: 21.714768 },
    { lat: 49.1873741, lng: 21.7147092 },
    { lat: 49.1874207, lng: 21.7149026 },
    { lat: 49.1876819, lng: 21.7149112 },
    { lat: 49.1877584, lng: 21.7152431 },
    { lat: 49.1878888, lng: 21.7151994 },
    { lat: 49.1879223, lng: 21.7154487 },
    { lat: 49.188415, lng: 21.7162014 },
    { lat: 49.1888047, lng: 21.7169824 },
    { lat: 49.1890052, lng: 21.7168635 },
    { lat: 49.1896586, lng: 21.7173646 },
    { lat: 49.1897264, lng: 21.7176733 },
    { lat: 49.1898576, lng: 21.7176173 },
    { lat: 49.1902521, lng: 21.7183646 },
    { lat: 49.1907378, lng: 21.71882 },
    { lat: 49.1908024, lng: 21.7190516 },
    { lat: 49.1911674, lng: 21.7194091 },
    { lat: 49.1914379, lng: 21.7199253 },
    { lat: 49.1914973, lng: 21.7200939 },
    { lat: 49.1917211, lng: 21.7196186 },
    { lat: 49.1921581, lng: 21.7190074 },
    { lat: 49.193046, lng: 21.7182608 },
    { lat: 49.1941379, lng: 21.7178636 },
    { lat: 49.1944728, lng: 21.7180651 },
    { lat: 49.1946321, lng: 21.7180563 },
    { lat: 49.1949286, lng: 21.7171524 },
    { lat: 49.1948513, lng: 21.7166583 },
    { lat: 49.1947438, lng: 21.7164624 },
    { lat: 49.1944854, lng: 21.7149919 },
    { lat: 49.1945144, lng: 21.7147366 },
    { lat: 49.1944635, lng: 21.7145146 },
    { lat: 49.1939217, lng: 21.7131757 },
    { lat: 49.1937709, lng: 21.7126675 },
    { lat: 49.1937422, lng: 21.7115482 },
    { lat: 49.1934953, lng: 21.7106421 },
    { lat: 49.1935488, lng: 21.7096644 },
    { lat: 49.1933795, lng: 21.7083719 },
    { lat: 49.1934986, lng: 21.7079501 },
    { lat: 49.1937495, lng: 21.7054229 },
    { lat: 49.1928324, lng: 21.7018863 },
    { lat: 49.192815, lng: 21.7004814 },
    { lat: 49.1930122, lng: 21.6985589 },
    { lat: 49.1934253, lng: 21.6968466 },
    { lat: 49.1936225, lng: 21.6963606 },
    { lat: 49.1939664, lng: 21.6958413 },
    { lat: 49.1941255, lng: 21.6954027 },
    { lat: 49.1947362, lng: 21.69468 },
    { lat: 49.1950602, lng: 21.6941821 },
    { lat: 49.1952878, lng: 21.6937748 },
    { lat: 49.1953375, lng: 21.6934929 },
    { lat: 49.1955478, lng: 21.6931068 },
    { lat: 49.1956459, lng: 21.6921971 },
    { lat: 49.1957131, lng: 21.6919774 },
    { lat: 49.1956682, lng: 21.6918133 },
    { lat: 49.1957954, lng: 21.6911446 },
    { lat: 49.1958002, lng: 21.6909897 },
    { lat: 49.1956682, lng: 21.6905933 },
    { lat: 49.1957434, lng: 21.6898771 },
    { lat: 49.1959764, lng: 21.6894676 },
    { lat: 49.1961582, lng: 21.6889084 },
    { lat: 49.1962176, lng: 21.6885651 },
    { lat: 49.1962029, lng: 21.6881434 },
    { lat: 49.196283, lng: 21.6874384 },
    { lat: 49.1964073, lng: 21.6872044 },
    { lat: 49.1966079, lng: 21.6870573 },
    { lat: 49.1967833, lng: 21.6866352 },
    { lat: 49.1969313, lng: 21.6865737 },
    { lat: 49.197591, lng: 21.6855461 },
    { lat: 49.1979248, lng: 21.6852385 },
    { lat: 49.1981505, lng: 21.6846808 },
    { lat: 49.1984398, lng: 21.6844415 },
    { lat: 49.1985536, lng: 21.6842347 },
    { lat: 49.1988503, lng: 21.6842261 },
    { lat: 49.1989664, lng: 21.6840279 },
    { lat: 49.1991836, lng: 21.6840319 },
    { lat: 49.1992776, lng: 21.6838029 },
    { lat: 49.1994125, lng: 21.6838823 },
    { lat: 49.1997967, lng: 21.683442 },
    { lat: 49.1998527, lng: 21.6832309 },
    { lat: 49.2001061, lng: 21.6831585 },
    { lat: 49.2002883, lng: 21.6824859 },
    { lat: 49.200641, lng: 21.6822141 },
    { lat: 49.2008837, lng: 21.6819129 },
    { lat: 49.201115, lng: 21.681775 },
    { lat: 49.2030325, lng: 21.6811323 },
    { lat: 49.2037023, lng: 21.6811004 },
    { lat: 49.2059758, lng: 21.6813188 },
    { lat: 49.2061821, lng: 21.6812918 },
    { lat: 49.2061857, lng: 21.6813377 },
    { lat: 49.2100984, lng: 21.6817206 },
    { lat: 49.2106901, lng: 21.6819321 },
    { lat: 49.2128136, lng: 21.6824252 },
    { lat: 49.2147317, lng: 21.6829669 },
    { lat: 49.2154144, lng: 21.6830921 },
    { lat: 49.2180328, lng: 21.6848314 },
    { lat: 49.2184119, lng: 21.684958 },
    { lat: 49.2193211, lng: 21.685046 },
    { lat: 49.2194826, lng: 21.6851165 },
    { lat: 49.2197983, lng: 21.6850571 },
    { lat: 49.2201715, lng: 21.685295 },
    { lat: 49.220755, lng: 21.6854574 },
    { lat: 49.2211067, lng: 21.6856495 },
    { lat: 49.2211922, lng: 21.6856912 },
    { lat: 49.2214266, lng: 21.6854934 },
    { lat: 49.2217917, lng: 21.6854377 },
    { lat: 49.2218987, lng: 21.6855253 },
    { lat: 49.2221652, lng: 21.6853999 },
    { lat: 49.2227399, lng: 21.6852671 },
    { lat: 49.2220732, lng: 21.6819818 },
    { lat: 49.2215982, lng: 21.6789631 },
    { lat: 49.2218851, lng: 21.678131 },
    { lat: 49.221563, lng: 21.675987 },
    { lat: 49.2220021, lng: 21.674047 },
    { lat: 49.2224881, lng: 21.6731721 },
    { lat: 49.2239188, lng: 21.6688313 },
    { lat: 49.2266574, lng: 21.6653604 },
    { lat: 49.2267706, lng: 21.665138 },
    { lat: 49.2256299, lng: 21.6635184 },
    { lat: 49.2260436, lng: 21.6628466 },
    { lat: 49.2263182, lng: 21.6627242 },
    { lat: 49.2260159, lng: 21.6604506 },
    { lat: 49.2230101, lng: 21.6578 },
    { lat: 49.2223249, lng: 21.6570041 },
    { lat: 49.2218673, lng: 21.6567671 },
    { lat: 49.2200157, lng: 21.654856 },
    { lat: 49.2194904, lng: 21.6546971 },
    { lat: 49.218435, lng: 21.6546081 },
    { lat: 49.218317, lng: 21.654241 },
    { lat: 49.218017, lng: 21.6537145 },
    { lat: 49.2171499, lng: 21.6532674 },
    { lat: 49.2167052, lng: 21.6532549 },
    { lat: 49.2153199, lng: 21.6525291 },
    { lat: 49.2145357, lng: 21.6519066 },
    { lat: 49.2139296, lng: 21.6507173 },
    { lat: 49.213481, lng: 21.6500185 },
    { lat: 49.2133617, lng: 21.6475404 },
    { lat: 49.2118439, lng: 21.645299 },
    { lat: 49.210739, lng: 21.6445912 },
    { lat: 49.2097957, lng: 21.6436174 },
    { lat: 49.2094556, lng: 21.6433595 },
    { lat: 49.2091504, lng: 21.6432231 },
    { lat: 49.2091578, lng: 21.6430791 },
    { lat: 49.2090279, lng: 21.6425681 },
    { lat: 49.208941, lng: 21.6417681 },
    { lat: 49.2092539, lng: 21.6412898 },
    { lat: 49.209547, lng: 21.6406099 },
    { lat: 49.2093165, lng: 21.6400334 },
    { lat: 49.208784, lng: 21.6398499 },
    { lat: 49.2087651, lng: 21.639858 },
    { lat: 49.2083813, lng: 21.6370087 },
    { lat: 49.2092853, lng: 21.6362165 },
    { lat: 49.2095902, lng: 21.6362546 },
    { lat: 49.211077, lng: 21.6357476 },
    { lat: 49.2129958, lng: 21.6349511 },
    { lat: 49.2146879, lng: 21.6335655 },
    { lat: 49.2169204, lng: 21.6337068 },
    { lat: 49.2181221, lng: 21.6315426 },
    { lat: 49.2186797, lng: 21.6313221 },
    { lat: 49.2205749, lng: 21.6296235 },
    { lat: 49.2213508, lng: 21.6291612 },
    { lat: 49.2224296, lng: 21.6262049 },
    { lat: 49.2224219, lng: 21.6256339 },
    { lat: 49.2235087, lng: 21.6220342 },
    { lat: 49.2236956, lng: 21.6208928 },
    { lat: 49.223611, lng: 21.6192467 },
    { lat: 49.2236388, lng: 21.6182508 },
    { lat: 49.2230316, lng: 21.6173587 },
    { lat: 49.2232277, lng: 21.6163031 },
    { lat: 49.2238383, lng: 21.6151558 },
    { lat: 49.2240951, lng: 21.6140614 },
    { lat: 49.2242667, lng: 21.6126151 },
    { lat: 49.2236631, lng: 21.6118466 },
    { lat: 49.22325, lng: 21.6104151 },
    { lat: 49.2239011, lng: 21.6092177 },
    { lat: 49.2243331, lng: 21.6090157 },
    { lat: 49.2244577, lng: 21.6083988 },
    { lat: 49.2245214, lng: 21.6076054 },
    { lat: 49.2244298, lng: 21.6057973 },
    { lat: 49.2245566, lng: 21.605412 },
    { lat: 49.2245899, lng: 21.6049081 },
    { lat: 49.2245511, lng: 21.6036228 },
    { lat: 49.2244293, lng: 21.6027194 },
    { lat: 49.2245374, lng: 21.6021967 },
    { lat: 49.2244282, lng: 21.6017423 },
    { lat: 49.2244466, lng: 21.6009389 },
    { lat: 49.2243501, lng: 21.6004585 },
    { lat: 49.2244266, lng: 21.5999328 },
    { lat: 49.2242564, lng: 21.5989881 },
    { lat: 49.2231669, lng: 21.5992444 },
    { lat: 49.2231125, lng: 21.599963 },
    { lat: 49.2229137, lng: 21.6006251 },
    { lat: 49.2226455, lng: 21.6017962 },
    { lat: 49.2226254, lng: 21.6022888 },
    { lat: 49.2226552, lng: 21.6027965 },
    { lat: 49.2227395, lng: 21.6032028 },
    { lat: 49.2227186, lng: 21.6035057 },
    { lat: 49.2228761, lng: 21.6039923 },
    { lat: 49.2228694, lng: 21.6044868 },
    { lat: 49.2230035, lng: 21.604981 },
    { lat: 49.2230096, lng: 21.6056126 },
    { lat: 49.2231883, lng: 21.605894 },
    { lat: 49.2233384, lng: 21.6064808 },
    { lat: 49.2232634, lng: 21.6068232 },
    { lat: 49.2233343, lng: 21.6071854 },
    { lat: 49.223172, lng: 21.607953 },
    { lat: 49.2228172, lng: 21.6081842 },
    { lat: 49.2229993, lng: 21.6087847 },
    { lat: 49.2228561, lng: 21.6090991 },
    { lat: 49.222442, lng: 21.6097523 },
    { lat: 49.2224262, lng: 21.6099577 },
    { lat: 49.2223068, lng: 21.6099107 },
    { lat: 49.2221938, lng: 21.6100928 },
    { lat: 49.2221527, lng: 21.6102704 },
    { lat: 49.221739, lng: 21.6107749 },
    { lat: 49.2215289, lng: 21.6108265 },
    { lat: 49.221449, lng: 21.6111087 },
    { lat: 49.2208226, lng: 21.6118068 },
    { lat: 49.2203487, lng: 21.6124569 },
    { lat: 49.2199925, lng: 21.613242 },
    { lat: 49.2197918, lng: 21.6134381 },
    { lat: 49.2194932, lng: 21.6140737 },
    { lat: 49.2189147, lng: 21.6150411 },
    { lat: 49.2189015, lng: 21.6151769 },
    { lat: 49.2186373, lng: 21.6155044 },
    { lat: 49.2186404, lng: 21.615706 },
    { lat: 49.218281, lng: 21.6160918 },
    { lat: 49.2182197, lng: 21.6163153 },
    { lat: 49.2182487, lng: 21.6165563 },
    { lat: 49.2168009, lng: 21.6190454 },
    { lat: 49.2149406, lng: 21.621032 },
    { lat: 49.2156749, lng: 21.6221643 },
    { lat: 49.2143371, lng: 21.622406 },
    { lat: 49.2139566, lng: 21.6222303 },
    { lat: 49.2132126, lng: 21.6226693 },
    { lat: 49.2131682, lng: 21.6228122 },
    { lat: 49.2128603, lng: 21.6225346 },
    { lat: 49.212668, lng: 21.6220824 },
    { lat: 49.2124488, lng: 21.6219705 },
    { lat: 49.2121471, lng: 21.6211834 },
    { lat: 49.2119583, lng: 21.6201272 },
    { lat: 49.2110041, lng: 21.6188077 },
    { lat: 49.2112351, lng: 21.6177541 },
    { lat: 49.2106585, lng: 21.6162186 },
    { lat: 49.2117535, lng: 21.6150877 },
    { lat: 49.2119024, lng: 21.6148216 },
    { lat: 49.2120045, lng: 21.6144016 },
    { lat: 49.2121841, lng: 21.6132009 },
    { lat: 49.2092111, lng: 21.6126813 },
    { lat: 49.2091268, lng: 21.6125993 },
    { lat: 49.2089618, lng: 21.612367 },
    { lat: 49.2087849, lng: 21.6088484 },
    { lat: 49.2068555, lng: 21.6085621 },
    { lat: 49.2053663, lng: 21.6083196 },
    { lat: 49.2049047, lng: 21.6083115 },
    { lat: 49.2042554, lng: 21.6087079 },
    { lat: 49.2045795, lng: 21.6093638 },
    { lat: 49.2043759, lng: 21.6104724 },
    { lat: 49.2041175, lng: 21.6113013 },
    { lat: 49.2040607, lng: 21.6123778 },
    { lat: 49.204422, lng: 21.6130011 },
    { lat: 49.2040215, lng: 21.6136078 },
    { lat: 49.2040003, lng: 21.6145607 },
    { lat: 49.203589, lng: 21.6151893 },
    { lat: 49.2032666, lng: 21.6154032 },
    { lat: 49.2030447, lng: 21.6157293 },
    { lat: 49.2028055, lng: 21.6159004 },
    { lat: 49.2021959, lng: 21.6158236 },
    { lat: 49.2021991, lng: 21.6153316 },
    { lat: 49.2023617, lng: 21.6152262 },
    { lat: 49.2023526, lng: 21.6146142 },
    { lat: 49.2022482, lng: 21.6139665 },
    { lat: 49.201881, lng: 21.6134212 },
    { lat: 49.2012495, lng: 21.6135233 },
    { lat: 49.2001411, lng: 21.6140476 },
    { lat: 49.1993051, lng: 21.6148983 },
    { lat: 49.1981064, lng: 21.6159788 },
    { lat: 49.1976857, lng: 21.6172507 },
    { lat: 49.1971845, lng: 21.6175943 },
    { lat: 49.1969934, lng: 21.6194807 },
    { lat: 49.1970427, lng: 21.6207459 },
    { lat: 49.1962971, lng: 21.6223061 },
    { lat: 49.1961829, lng: 21.6234386 },
    { lat: 49.1959553, lng: 21.6242067 },
    { lat: 49.1962495, lng: 21.6252116 },
    { lat: 49.1959017, lng: 21.6258619 },
    { lat: 49.1970383, lng: 21.6275501 },
    { lat: 49.197458, lng: 21.6285003 },
    { lat: 49.1965433, lng: 21.6289454 },
    { lat: 49.1951927, lng: 21.6294571 },
    { lat: 49.1953798, lng: 21.6347363 },
    { lat: 49.1955564, lng: 21.635925 },
    { lat: 49.19555, lng: 21.6383638 },
    { lat: 49.1958007, lng: 21.639714 },
    { lat: 49.1958819, lng: 21.6404714 },
    { lat: 49.1958931, lng: 21.6406311 },
    { lat: 49.1956367, lng: 21.6409183 },
    { lat: 49.1958184, lng: 21.6418934 },
    { lat: 49.195135, lng: 21.6427396 },
    { lat: 49.1900139, lng: 21.6444989 },
    { lat: 49.1891377, lng: 21.6448707 },
    { lat: 49.1882895, lng: 21.6449993 },
    { lat: 49.1880695, lng: 21.6451787 },
    { lat: 49.1873689, lng: 21.6451876 },
    { lat: 49.1868148, lng: 21.6450001 },
    { lat: 49.1853698, lng: 21.6440338 },
    { lat: 49.1852412, lng: 21.6431915 },
    { lat: 49.1838482, lng: 21.6426456 },
    { lat: 49.1794331, lng: 21.6421241 },
    { lat: 49.1787283, lng: 21.6423515 },
    { lat: 49.179472, lng: 21.6484625 },
    { lat: 49.1794982, lng: 21.6486809 },
    { lat: 49.1782196, lng: 21.6552631 },
    { lat: 49.1775107, lng: 21.6594886 },
    { lat: 49.1743717, lng: 21.6610727 },
    { lat: 49.1741904, lng: 21.6611642 },
    { lat: 49.1734572, lng: 21.6614226 },
    { lat: 49.1723723, lng: 21.6593343 },
    { lat: 49.1723912, lng: 21.6616898 },
    { lat: 49.172547, lng: 21.6639062 },
    { lat: 49.1734382, lng: 21.6694817 },
    { lat: 49.1746288, lng: 21.6716122 },
    { lat: 49.1753675, lng: 21.6776841 },
    { lat: 49.1756008, lng: 21.6781958 },
    { lat: 49.1755149, lng: 21.6805588 },
    { lat: 49.1758734, lng: 21.6829632 },
    { lat: 49.1758112, lng: 21.6855908 },
    { lat: 49.1753676, lng: 21.6856524 },
    { lat: 49.1747768, lng: 21.6873573 },
    { lat: 49.1746068, lng: 21.6891983 },
    { lat: 49.1746192, lng: 21.6893601 },
    { lat: 49.1747593, lng: 21.6897669 },
    { lat: 49.175276, lng: 21.6909934 },
    { lat: 49.175502, lng: 21.6920349 },
    { lat: 49.1748591, lng: 21.6923041 },
    { lat: 49.17505, lng: 21.6925374 },
    { lat: 49.1755721, lng: 21.6936358 },
    { lat: 49.1759549, lng: 21.6937744 },
    { lat: 49.1763196, lng: 21.6940745 },
    { lat: 49.1764748, lng: 21.6943248 },
    { lat: 49.1763796, lng: 21.6945968 },
    { lat: 49.1764769, lng: 21.6946127 },
    { lat: 49.1765353, lng: 21.6949434 },
    { lat: 49.1766881, lng: 21.6951463 },
    { lat: 49.1766541, lng: 21.6956824 },
    { lat: 49.1765482, lng: 21.6958447 },
    { lat: 49.1763691, lng: 21.6965505 },
    { lat: 49.1761643, lng: 21.6970541 },
    { lat: 49.1758581, lng: 21.6967036 },
    { lat: 49.175192, lng: 21.6962814 },
    { lat: 49.1750995, lng: 21.6973615 },
    { lat: 49.1751383, lng: 21.6982633 },
    { lat: 49.1750869, lng: 21.6986145 },
    { lat: 49.174829, lng: 21.6985156 },
  ],
  Bžany: [
    { lat: 49.0983858, lng: 21.638238 },
    { lat: 49.098265, lng: 21.6371166 },
    { lat: 49.0981386, lng: 21.6366493 },
    { lat: 49.0981564, lng: 21.6361883 },
    { lat: 49.0979286, lng: 21.6351782 },
    { lat: 49.0974917, lng: 21.6340568 },
    { lat: 49.0971488, lng: 21.6341483 },
    { lat: 49.0957743, lng: 21.6325172 },
    { lat: 49.0956331, lng: 21.6325807 },
    { lat: 49.0954622, lng: 21.6324168 },
    { lat: 49.0952853, lng: 21.6319132 },
    { lat: 49.0953819, lng: 21.6314131 },
    { lat: 49.0952756, lng: 21.6310695 },
    { lat: 49.0950737, lng: 21.630844 },
    { lat: 49.0949085, lng: 21.6302356 },
    { lat: 49.0948286, lng: 21.629746 },
    { lat: 49.0950405, lng: 21.6291025 },
    { lat: 49.0948044, lng: 21.6282908 },
    { lat: 49.0949371, lng: 21.6277902 },
    { lat: 49.0943573, lng: 21.6265958 },
    { lat: 49.0946792, lng: 21.6252105 },
    { lat: 49.0951106, lng: 21.6244029 },
    { lat: 49.0947838, lng: 21.6241003 },
    { lat: 49.0951888, lng: 21.6233612 },
    { lat: 49.0950282, lng: 21.6230882 },
    { lat: 49.0947497, lng: 21.622407 },
    { lat: 49.0938717, lng: 21.6218208 },
    { lat: 49.0937317, lng: 21.621231 },
    { lat: 49.0933727, lng: 21.6207369 },
    { lat: 49.0930283, lng: 21.6201182 },
    { lat: 49.0926742, lng: 21.6198349 },
    { lat: 49.092229, lng: 21.6189936 },
    { lat: 49.0922418, lng: 21.6171308 },
    { lat: 49.0919632, lng: 21.6167746 },
    { lat: 49.0917635, lng: 21.6153698 },
    { lat: 49.0916221, lng: 21.6149344 },
    { lat: 49.0917597, lng: 21.6145408 },
    { lat: 49.0917906, lng: 21.6141063 },
    { lat: 49.0919425, lng: 21.6138686 },
    { lat: 49.0926397, lng: 21.6134023 },
    { lat: 49.0921952, lng: 21.6122964 },
    { lat: 49.0920174, lng: 21.6120536 },
    { lat: 49.0916094, lng: 21.6117006 },
    { lat: 49.0914624, lng: 21.6113428 },
    { lat: 49.0912401, lng: 21.6095346 },
    { lat: 49.0913609, lng: 21.608446 },
    { lat: 49.091455, lng: 21.608386 },
    { lat: 49.091381, lng: 21.608346 },
    { lat: 49.091328, lng: 21.607278 },
    { lat: 49.091261, lng: 21.60729 },
    { lat: 49.090701, lng: 21.607289 },
    { lat: 49.090586, lng: 21.60642 },
    { lat: 49.0905, lng: 21.60597 },
    { lat: 49.090378, lng: 21.605507 },
    { lat: 49.090242, lng: 21.605115 },
    { lat: 49.089949, lng: 21.604803 },
    { lat: 49.089717, lng: 21.604539 },
    { lat: 49.089518, lng: 21.604214 },
    { lat: 49.089215, lng: 21.603984 },
    { lat: 49.08866, lng: 21.603669 },
    { lat: 49.088253, lng: 21.60358 },
    { lat: 49.088073, lng: 21.603877 },
    { lat: 49.087643, lng: 21.604228 },
    { lat: 49.08752, lng: 21.604376 },
    { lat: 49.087417, lng: 21.604389 },
    { lat: 49.087213, lng: 21.604601 },
    { lat: 49.087068, lng: 21.604788 },
    { lat: 49.086833, lng: 21.604903 },
    { lat: 49.086581, lng: 21.605087 },
    { lat: 49.08637, lng: 21.605261 },
    { lat: 49.086248, lng: 21.605127 },
    { lat: 49.086095, lng: 21.605105 },
    { lat: 49.086005, lng: 21.604962 },
    { lat: 49.085973, lng: 21.604999 },
    { lat: 49.085849, lng: 21.604911 },
    { lat: 49.085646, lng: 21.604715 },
    { lat: 49.085336, lng: 21.604612 },
    { lat: 49.085052, lng: 21.604688 },
    { lat: 49.084739, lng: 21.604575 },
    { lat: 49.084607, lng: 21.604636 },
    { lat: 49.084547, lng: 21.604741 },
    { lat: 49.084529, lng: 21.604728 },
    { lat: 49.084452, lng: 21.60458 },
    { lat: 49.084325, lng: 21.604729 },
    { lat: 49.084032, lng: 21.604704 },
    { lat: 49.083969, lng: 21.604771 },
    { lat: 49.083946, lng: 21.604957 },
    { lat: 49.083908, lng: 21.605057 },
    { lat: 49.083768, lng: 21.604938 },
    { lat: 49.08365, lng: 21.604891 },
    { lat: 49.083486, lng: 21.604957 },
    { lat: 49.083424, lng: 21.605031 },
    { lat: 49.083365, lng: 21.605099 },
    { lat: 49.08331, lng: 21.60511 },
    { lat: 49.083294, lng: 21.605096 },
    { lat: 49.083253, lng: 21.604953 },
    { lat: 49.083156, lng: 21.604914 },
    { lat: 49.083143, lng: 21.604927 },
    { lat: 49.083111, lng: 21.605248 },
    { lat: 49.082953, lng: 21.6055 },
    { lat: 49.082911, lng: 21.6055 },
    { lat: 49.082891, lng: 21.605449 },
    { lat: 49.082879, lng: 21.605326 },
    { lat: 49.082856, lng: 21.605319 },
    { lat: 49.082766, lng: 21.605351 },
    { lat: 49.082649, lng: 21.605539 },
    { lat: 49.082369, lng: 21.605825 },
    { lat: 49.082266, lng: 21.605878 },
    { lat: 49.08226, lng: 21.606015 },
    { lat: 49.082225, lng: 21.60616 },
    { lat: 49.082116, lng: 21.60619 },
    { lat: 49.081966, lng: 21.606301 },
    { lat: 49.081937, lng: 21.606603 },
    { lat: 49.081899, lng: 21.606676 },
    { lat: 49.081577, lng: 21.606858 },
    { lat: 49.081481, lng: 21.606922 },
    { lat: 49.081461, lng: 21.606942 },
    { lat: 49.081459, lng: 21.606979 },
    { lat: 49.081516, lng: 21.607293 },
    { lat: 49.081511, lng: 21.607307 },
    { lat: 49.081241, lng: 21.607603 },
    { lat: 49.081199, lng: 21.607728 },
    { lat: 49.081218, lng: 21.60803 },
    { lat: 49.081209, lng: 21.608218 },
    { lat: 49.080922, lng: 21.608307 },
    { lat: 49.080914, lng: 21.608446 },
    { lat: 49.08098, lng: 21.608533 },
    { lat: 49.080984, lng: 21.608556 },
    { lat: 49.080976, lng: 21.608577 },
    { lat: 49.080866, lng: 21.608684 },
    { lat: 49.080785, lng: 21.608733 },
    { lat: 49.080832, lng: 21.608875 },
    { lat: 49.080832, lng: 21.608981 },
    { lat: 49.08074, lng: 21.609162 },
    { lat: 49.080739, lng: 21.60919 },
    { lat: 49.08075, lng: 21.609238 },
    { lat: 49.080874, lng: 21.60926 },
    { lat: 49.080778, lng: 21.609403 },
    { lat: 49.080571, lng: 21.60956 },
    { lat: 49.080359, lng: 21.609521 },
    { lat: 49.080267, lng: 21.609475 },
    { lat: 49.080251, lng: 21.609478 },
    { lat: 49.080178, lng: 21.609601 },
    { lat: 49.080133, lng: 21.609754 },
    { lat: 49.079937, lng: 21.609893 },
    { lat: 49.079869, lng: 21.610228 },
    { lat: 49.079832, lng: 21.610219 },
    { lat: 49.079763, lng: 21.610008 },
    { lat: 49.079708, lng: 21.609941 },
    { lat: 49.079687, lng: 21.609947 },
    { lat: 49.079516, lng: 21.610207 },
    { lat: 49.079367, lng: 21.610132 },
    { lat: 49.079065, lng: 21.610228 },
    { lat: 49.078898, lng: 21.610004 },
    { lat: 49.078768, lng: 21.610215 },
    { lat: 49.078569, lng: 21.61024 },
    { lat: 49.078409, lng: 21.610188 },
    { lat: 49.078197, lng: 21.609951 },
    { lat: 49.078074, lng: 21.609878 },
    { lat: 49.078044, lng: 21.610368 },
    { lat: 49.077951, lng: 21.610521 },
    { lat: 49.077744, lng: 21.610294 },
    { lat: 49.077395, lng: 21.610228 },
    { lat: 49.077379, lng: 21.610148 },
    { lat: 49.077407, lng: 21.610031 },
    { lat: 49.077373, lng: 21.610031 },
    { lat: 49.077214, lng: 21.610044 },
    { lat: 49.076828, lng: 21.610015 },
    { lat: 49.076711, lng: 21.610102 },
    { lat: 49.076644, lng: 21.610209 },
    { lat: 49.076366, lng: 21.610402 },
    { lat: 49.076103, lng: 21.610427 },
    { lat: 49.075981, lng: 21.610581 },
    { lat: 49.075806, lng: 21.610731 },
    { lat: 49.075705, lng: 21.610777 },
    { lat: 49.075351, lng: 21.611066 },
    { lat: 49.074987, lng: 21.611148 },
    { lat: 49.074879, lng: 21.611308 },
    { lat: 49.074573, lng: 21.611398 },
    { lat: 49.074303, lng: 21.611323 },
    { lat: 49.073856, lng: 21.611517 },
    { lat: 49.073432, lng: 21.611516 },
    { lat: 49.072886, lng: 21.611652 },
    { lat: 49.07239, lng: 21.611528 },
    { lat: 49.072031, lng: 21.611677 },
    { lat: 49.071835, lng: 21.611459 },
    { lat: 49.071451, lng: 21.61113 },
    { lat: 49.071212, lng: 21.611003 },
    { lat: 49.07081, lng: 21.61092 },
    { lat: 49.070695, lng: 21.610805 },
    { lat: 49.07047, lng: 21.610522 },
    { lat: 49.070288, lng: 21.610398 },
    { lat: 49.07016, lng: 21.610259 },
    { lat: 49.069964, lng: 21.610101 },
    { lat: 49.069852, lng: 21.609955 },
    { lat: 49.069753, lng: 21.609698 },
    { lat: 49.069641, lng: 21.6095 },
    { lat: 49.069491, lng: 21.609336 },
    { lat: 49.069334, lng: 21.609189 },
    { lat: 49.069291, lng: 21.609372 },
    { lat: 49.069162, lng: 21.609479 },
    { lat: 49.069126, lng: 21.609795 },
    { lat: 49.069029, lng: 21.60979 },
    { lat: 49.068989, lng: 21.609815 },
    { lat: 49.068974, lng: 21.609963 },
    { lat: 49.068717, lng: 21.610603 },
    { lat: 49.0686, lng: 21.610747 },
    { lat: 49.068452, lng: 21.611048 },
    { lat: 49.068076, lng: 21.611788 },
    { lat: 49.068053, lng: 21.611883 },
    { lat: 49.068037, lng: 21.612112 },
    { lat: 49.067908, lng: 21.612502 },
    { lat: 49.067901, lng: 21.613198 },
    { lat: 49.068081, lng: 21.613755 },
    { lat: 49.068208, lng: 21.614511 },
    { lat: 49.068299, lng: 21.614761 },
    { lat: 49.06828, lng: 21.614865 },
    { lat: 49.068264, lng: 21.615123 },
    { lat: 49.068201, lng: 21.615716 },
    { lat: 49.068073, lng: 21.617048 },
    { lat: 49.067988, lng: 21.61729 },
    { lat: 49.068083, lng: 21.61778 },
    { lat: 49.068149, lng: 21.618299 },
    { lat: 49.068175, lng: 21.6185 },
    { lat: 49.068182, lng: 21.618825 },
    { lat: 49.068304, lng: 21.619184 },
    { lat: 49.068409, lng: 21.619612 },
    { lat: 49.068021, lng: 21.619782 },
    { lat: 49.067217, lng: 21.619943 },
    { lat: 49.066758, lng: 21.620312 },
    { lat: 49.066502, lng: 21.620459 },
    { lat: 49.066037, lng: 21.620606 },
    { lat: 49.065989, lng: 21.620499 },
    { lat: 49.065632, lng: 21.620606 },
    { lat: 49.065185, lng: 21.620858 },
    { lat: 49.065143, lng: 21.620894 },
    { lat: 49.064874, lng: 21.621121 },
    { lat: 49.064855, lng: 21.621138 },
    { lat: 49.064829, lng: 21.621302 },
    { lat: 49.064741, lng: 21.621352 },
    { lat: 49.064182, lng: 21.621836 },
    { lat: 49.063855, lng: 21.622159 },
    { lat: 49.063218, lng: 21.622847 },
    { lat: 49.062604, lng: 21.623471 },
    { lat: 49.061601, lng: 21.624581 },
    { lat: 49.060655, lng: 21.625615 },
    { lat: 49.060324, lng: 21.62602 },
    { lat: 49.060026, lng: 21.626744 },
    { lat: 49.059845, lng: 21.627456 },
    { lat: 49.059578, lng: 21.628116 },
    { lat: 49.059468, lng: 21.629527 },
    { lat: 49.059364, lng: 21.630157 },
    { lat: 49.059206, lng: 21.630997 },
    { lat: 49.059313, lng: 21.631793 },
    { lat: 49.059362, lng: 21.632323 },
    { lat: 49.059358, lng: 21.63238 },
    { lat: 49.059105, lng: 21.632327 },
    { lat: 49.058764, lng: 21.632311 },
    { lat: 49.058509, lng: 21.632228 },
    { lat: 49.058138, lng: 21.632348 },
    { lat: 49.057835, lng: 21.632174 },
    { lat: 49.057161, lng: 21.632083 },
    { lat: 49.05689, lng: 21.632544 },
    { lat: 49.056608, lng: 21.632947 },
    { lat: 49.056167, lng: 21.633267 },
    { lat: 49.055811, lng: 21.633264 },
    { lat: 49.055269, lng: 21.633108 },
    { lat: 49.05494, lng: 21.632832 },
    { lat: 49.054892, lng: 21.632793 },
    { lat: 49.054315, lng: 21.633785 },
    { lat: 49.054144, lng: 21.634098 },
    { lat: 49.05401, lng: 21.634748 },
    { lat: 49.054051, lng: 21.636077 },
    { lat: 49.053406, lng: 21.636676 },
    { lat: 49.053248, lng: 21.636899 },
    { lat: 49.052925, lng: 21.637659 },
    { lat: 49.052071, lng: 21.638033 },
    { lat: 49.051569, lng: 21.638073 },
    { lat: 49.05122, lng: 21.638453 },
    { lat: 49.051097, lng: 21.638582 },
    { lat: 49.050934, lng: 21.638698 },
    { lat: 49.050625, lng: 21.639057 },
    { lat: 49.0505, lng: 21.639223 },
    { lat: 49.050318, lng: 21.639351 },
    { lat: 49.050193, lng: 21.639482 },
    { lat: 49.049675, lng: 21.640044 },
    { lat: 49.049417, lng: 21.640448 },
    { lat: 49.049378, lng: 21.640898 },
    { lat: 49.049304, lng: 21.641129 },
    { lat: 49.048698, lng: 21.641933 },
    { lat: 49.048379, lng: 21.64239 },
    { lat: 49.048087, lng: 21.643044 },
    { lat: 49.047735, lng: 21.643929 },
    { lat: 49.047664, lng: 21.644052 },
    { lat: 49.047604, lng: 21.644425 },
    { lat: 49.047206, lng: 21.646434 },
    { lat: 49.047276, lng: 21.646653 },
    { lat: 49.047279, lng: 21.646819 },
    { lat: 49.047232, lng: 21.647032 },
    { lat: 49.047117, lng: 21.647157 },
    { lat: 49.046859, lng: 21.647331 },
    { lat: 49.046016, lng: 21.64866 },
    { lat: 49.045925, lng: 21.648879 },
    { lat: 49.045798, lng: 21.649072 },
    { lat: 49.045664, lng: 21.649212 },
    { lat: 49.045488, lng: 21.649343 },
    { lat: 49.045361, lng: 21.649485 },
    { lat: 49.045112, lng: 21.649843 },
    { lat: 49.044876, lng: 21.650218 },
    { lat: 49.044517, lng: 21.650533 },
    { lat: 49.043682, lng: 21.651791 },
    { lat: 49.043267, lng: 21.652376 },
    { lat: 49.043187, lng: 21.652416 },
    { lat: 49.043457, lng: 21.653044 },
    { lat: 49.043628, lng: 21.653253 },
    { lat: 49.043799, lng: 21.653709 },
    { lat: 49.043953, lng: 21.653892 },
    { lat: 49.044544, lng: 21.654385 },
    { lat: 49.044782, lng: 21.655118 },
    { lat: 49.045318, lng: 21.655505 },
    { lat: 49.045595, lng: 21.655933 },
    { lat: 49.045807, lng: 21.655947 },
    { lat: 49.046029, lng: 21.656147 },
    { lat: 49.047008, lng: 21.656627 },
    { lat: 49.04726, lng: 21.6569 },
    { lat: 49.047251, lng: 21.65723 },
    { lat: 49.047322, lng: 21.657501 },
    { lat: 49.047486, lng: 21.657884 },
    { lat: 49.047597, lng: 21.658322 },
    { lat: 49.047788, lng: 21.658667 },
    { lat: 49.047852, lng: 21.658801 },
    { lat: 49.047859, lng: 21.658952 },
    { lat: 49.048041, lng: 21.659325 },
    { lat: 49.048159, lng: 21.659703 },
    { lat: 49.048166, lng: 21.660387 },
    { lat: 49.0474, lng: 21.66195 },
    { lat: 49.047364, lng: 21.661952 },
    { lat: 49.046973, lng: 21.662947 },
    { lat: 49.046739, lng: 21.663388 },
    { lat: 49.046681, lng: 21.664855 },
    { lat: 49.046706, lng: 21.665417 },
    { lat: 49.046897, lng: 21.666671 },
    { lat: 49.046906, lng: 21.666725 },
    { lat: 49.046926, lng: 21.666854 },
    { lat: 49.046989, lng: 21.667529 },
    { lat: 49.047048, lng: 21.667845 },
    { lat: 49.047082, lng: 21.668437 },
    { lat: 49.047152, lng: 21.66937 },
    { lat: 49.04722, lng: 21.669474 },
    { lat: 49.047411, lng: 21.669777 },
    { lat: 49.047985, lng: 21.670266 },
    { lat: 49.048599, lng: 21.670391 },
    { lat: 49.048896, lng: 21.671138 },
    { lat: 49.049163, lng: 21.671769 },
    { lat: 49.049961, lng: 21.67295 },
    { lat: 49.050872, lng: 21.6743 },
    { lat: 49.052076, lng: 21.675858 },
    { lat: 49.052495, lng: 21.676945 },
    { lat: 49.053264, lng: 21.677828 },
    { lat: 49.053545, lng: 21.678376 },
    { lat: 49.053683, lng: 21.678389 },
    { lat: 49.053936, lng: 21.678461 },
    { lat: 49.053971, lng: 21.678471 },
    { lat: 49.054016, lng: 21.678484 },
    { lat: 49.054043, lng: 21.678491 },
    { lat: 49.05408, lng: 21.678502 },
    { lat: 49.054121, lng: 21.678489 },
    { lat: 49.054143, lng: 21.678482 },
    { lat: 49.054168, lng: 21.678473 },
    { lat: 49.054195, lng: 21.678464 },
    { lat: 49.058134, lng: 21.675536 },
    { lat: 49.06542, lng: 21.666388 },
    { lat: 49.0758496, lng: 21.6626813 },
    { lat: 49.0775031, lng: 21.6573024 },
    { lat: 49.0789104, lng: 21.6518019 },
    { lat: 49.0973526, lng: 21.6426185 },
    { lat: 49.0983858, lng: 21.638238 },
  ],
  Baňa: [
    { lat: 49.2242564, lng: 21.5989881 },
    { lat: 49.2244082, lng: 21.5978025 },
    { lat: 49.2244988, lng: 21.5970898 },
    { lat: 49.2243427, lng: 21.5963297 },
    { lat: 49.224216, lng: 21.596069 },
    { lat: 49.223974, lng: 21.595378 },
    { lat: 49.223216, lng: 21.595737 },
    { lat: 49.223073, lng: 21.595836 },
    { lat: 49.222955, lng: 21.59591 },
    { lat: 49.22264, lng: 21.595903 },
    { lat: 49.222124, lng: 21.595844 },
    { lat: 49.221927, lng: 21.595736 },
    { lat: 49.221357, lng: 21.595729 },
    { lat: 49.22102, lng: 21.595615 },
    { lat: 49.22058, lng: 21.595451 },
    { lat: 49.220202, lng: 21.595417 },
    { lat: 49.219963, lng: 21.59534 },
    { lat: 49.219699, lng: 21.595419 },
    { lat: 49.219269, lng: 21.595585 },
    { lat: 49.219029, lng: 21.595291 },
    { lat: 49.218657, lng: 21.59543 },
    { lat: 49.218178, lng: 21.595199 },
    { lat: 49.217867, lng: 21.595508 },
    { lat: 49.217236, lng: 21.597175 },
    { lat: 49.217177, lng: 21.597133 },
    { lat: 49.216994, lng: 21.596849 },
    { lat: 49.216929, lng: 21.596749 },
    { lat: 49.216855, lng: 21.596634 },
    { lat: 49.215754, lng: 21.594934 },
    { lat: 49.2150214, lng: 21.5967007 },
    { lat: 49.2148613, lng: 21.5974973 },
    { lat: 49.2142561, lng: 21.5988436 },
    { lat: 49.21468, lng: 21.5997299 },
    { lat: 49.215056, lng: 21.6005161 },
    { lat: 49.2141766, lng: 21.6017829 },
    { lat: 49.2136158, lng: 21.6021331 },
    { lat: 49.2136848, lng: 21.6024888 },
    { lat: 49.2140237, lng: 21.6042364 },
    { lat: 49.2104167, lng: 21.6078509 },
    { lat: 49.2091829, lng: 21.6086051 },
    { lat: 49.2087849, lng: 21.6088484 },
    { lat: 49.2089618, lng: 21.612367 },
    { lat: 49.2091268, lng: 21.6125993 },
    { lat: 49.2092111, lng: 21.6126813 },
    { lat: 49.2121841, lng: 21.6132009 },
    { lat: 49.2120045, lng: 21.6144016 },
    { lat: 49.2119024, lng: 21.6148216 },
    { lat: 49.2117535, lng: 21.6150877 },
    { lat: 49.2106585, lng: 21.6162186 },
    { lat: 49.2112351, lng: 21.6177541 },
    { lat: 49.2110041, lng: 21.6188077 },
    { lat: 49.2119583, lng: 21.6201272 },
    { lat: 49.2121471, lng: 21.6211834 },
    { lat: 49.2124488, lng: 21.6219705 },
    { lat: 49.212668, lng: 21.6220824 },
    { lat: 49.2128603, lng: 21.6225346 },
    { lat: 49.2131682, lng: 21.6228122 },
    { lat: 49.2132126, lng: 21.6226693 },
    { lat: 49.2139566, lng: 21.6222303 },
    { lat: 49.2143371, lng: 21.622406 },
    { lat: 49.2156749, lng: 21.6221643 },
    { lat: 49.2149406, lng: 21.621032 },
    { lat: 49.2168009, lng: 21.6190454 },
    { lat: 49.2182487, lng: 21.6165563 },
    { lat: 49.2182197, lng: 21.6163153 },
    { lat: 49.218281, lng: 21.6160918 },
    { lat: 49.2186404, lng: 21.615706 },
    { lat: 49.2186373, lng: 21.6155044 },
    { lat: 49.2189015, lng: 21.6151769 },
    { lat: 49.2189147, lng: 21.6150411 },
    { lat: 49.2194932, lng: 21.6140737 },
    { lat: 49.2197918, lng: 21.6134381 },
    { lat: 49.2199925, lng: 21.613242 },
    { lat: 49.2203487, lng: 21.6124569 },
    { lat: 49.2208226, lng: 21.6118068 },
    { lat: 49.221449, lng: 21.6111087 },
    { lat: 49.2215289, lng: 21.6108265 },
    { lat: 49.221739, lng: 21.6107749 },
    { lat: 49.2221527, lng: 21.6102704 },
    { lat: 49.2221938, lng: 21.6100928 },
    { lat: 49.2223068, lng: 21.6099107 },
    { lat: 49.2224262, lng: 21.6099577 },
    { lat: 49.222442, lng: 21.6097523 },
    { lat: 49.2228561, lng: 21.6090991 },
    { lat: 49.2229993, lng: 21.6087847 },
    { lat: 49.2228172, lng: 21.6081842 },
    { lat: 49.223172, lng: 21.607953 },
    { lat: 49.2233343, lng: 21.6071854 },
    { lat: 49.2232634, lng: 21.6068232 },
    { lat: 49.2233384, lng: 21.6064808 },
    { lat: 49.2231883, lng: 21.605894 },
    { lat: 49.2230096, lng: 21.6056126 },
    { lat: 49.2230035, lng: 21.604981 },
    { lat: 49.2228694, lng: 21.6044868 },
    { lat: 49.2228761, lng: 21.6039923 },
    { lat: 49.2227186, lng: 21.6035057 },
    { lat: 49.2227395, lng: 21.6032028 },
    { lat: 49.2226552, lng: 21.6027965 },
    { lat: 49.2226254, lng: 21.6022888 },
    { lat: 49.2226455, lng: 21.6017962 },
    { lat: 49.2229137, lng: 21.6006251 },
    { lat: 49.2231125, lng: 21.599963 },
    { lat: 49.2231669, lng: 21.5992444 },
    { lat: 49.2242564, lng: 21.5989881 },
  ],
  Miňovce: [
    { lat: 49.1122414, lng: 21.6558475 },
    { lat: 49.1127681, lng: 21.6545347 },
    { lat: 49.1133081, lng: 21.6541142 },
    { lat: 49.1133264, lng: 21.6539231 },
    { lat: 49.1138233, lng: 21.6537919 },
    { lat: 49.1139827, lng: 21.654147 },
    { lat: 49.114112, lng: 21.6537689 },
    { lat: 49.1174266, lng: 21.6549866 },
    { lat: 49.1181235, lng: 21.655422 },
    { lat: 49.1196678, lng: 21.6560719 },
    { lat: 49.1209294, lng: 21.657037 },
    { lat: 49.1206655, lng: 21.6577188 },
    { lat: 49.12066, lng: 21.6583394 },
    { lat: 49.1207849, lng: 21.6588405 },
    { lat: 49.1206221, lng: 21.6592279 },
    { lat: 49.1206368, lng: 21.6598472 },
    { lat: 49.1203896, lng: 21.660769 },
    { lat: 49.1204429, lng: 21.6606582 },
    { lat: 49.1206847, lng: 21.6605323 },
    { lat: 49.1205091, lng: 21.661238 },
    { lat: 49.1204096, lng: 21.6614275 },
    { lat: 49.1202907, lng: 21.6621001 },
    { lat: 49.1200538, lng: 21.6628646 },
    { lat: 49.1200976, lng: 21.6629423 },
    { lat: 49.1201504, lng: 21.6627767 },
    { lat: 49.1201864, lng: 21.6628538 },
    { lat: 49.1206035, lng: 21.6641326 },
    { lat: 49.120557, lng: 21.6641561 },
    { lat: 49.1206033, lng: 21.6642914 },
    { lat: 49.1207544, lng: 21.6644277 },
    { lat: 49.1208015, lng: 21.6647139 },
    { lat: 49.1205403, lng: 21.6648679 },
    { lat: 49.120826, lng: 21.6652866 },
    { lat: 49.1209521, lng: 21.6653713 },
    { lat: 49.1210186, lng: 21.6652968 },
    { lat: 49.1213128, lng: 21.6657308 },
    { lat: 49.1213738, lng: 21.6660301 },
    { lat: 49.1219951, lng: 21.666497 },
    { lat: 49.122124, lng: 21.6669745 },
    { lat: 49.1223687, lng: 21.6671214 },
    { lat: 49.1225075, lng: 21.6675965 },
    { lat: 49.1226644, lng: 21.6676559 },
    { lat: 49.1227031, lng: 21.667883 },
    { lat: 49.122764, lng: 21.6679277 },
    { lat: 49.1228221, lng: 21.6678705 },
    { lat: 49.1229249, lng: 21.6680547 },
    { lat: 49.1231768, lng: 21.667887 },
    { lat: 49.1235701, lng: 21.6682832 },
    { lat: 49.1237615, lng: 21.6686996 },
    { lat: 49.1237313, lng: 21.6687051 },
    { lat: 49.1237205, lng: 21.6689342 },
    { lat: 49.1237419, lng: 21.6689753 },
    { lat: 49.1238864, lng: 21.6688703 },
    { lat: 49.1239723, lng: 21.6690046 },
    { lat: 49.1239962, lng: 21.6693109 },
    { lat: 49.1240892, lng: 21.6695852 },
    { lat: 49.1243777, lng: 21.6700222 },
    { lat: 49.1247608, lng: 21.6707883 },
    { lat: 49.1247521, lng: 21.6713028 },
    { lat: 49.1252509, lng: 21.6721454 },
    { lat: 49.1261756, lng: 21.6726479 },
    { lat: 49.126383, lng: 21.6731608 },
    { lat: 49.126728, lng: 21.6732684 },
    { lat: 49.126732, lng: 21.6732071 },
    { lat: 49.1271482, lng: 21.6732901 },
    { lat: 49.1273559, lng: 21.6731945 },
    { lat: 49.1276202, lng: 21.6731868 },
    { lat: 49.1277728, lng: 21.673512 },
    { lat: 49.1279391, lng: 21.6734984 },
    { lat: 49.1279311, lng: 21.6737887 },
    { lat: 49.1281421, lng: 21.6738154 },
    { lat: 49.1282611, lng: 21.6741797 },
    { lat: 49.1284617, lng: 21.6745284 },
    { lat: 49.128979, lng: 21.6752614 },
    { lat: 49.1297407, lng: 21.6758505 },
    { lat: 49.1300924, lng: 21.6762498 },
    { lat: 49.1304666, lng: 21.6768197 },
    { lat: 49.1305624, lng: 21.6772412 },
    { lat: 49.1306754, lng: 21.6774712 },
    { lat: 49.1308864, lng: 21.6777852 },
    { lat: 49.1311527, lng: 21.6778518 },
    { lat: 49.1311461, lng: 21.6780276 },
    { lat: 49.131302, lng: 21.6783432 },
    { lat: 49.1313573, lng: 21.6786038 },
    { lat: 49.1314968, lng: 21.6785997 },
    { lat: 49.1315309, lng: 21.6786956 },
    { lat: 49.1314788, lng: 21.6788936 },
    { lat: 49.1316683, lng: 21.6792866 },
    { lat: 49.1316912, lng: 21.6796368 },
    { lat: 49.1315765, lng: 21.6802601 },
    { lat: 49.1314912, lng: 21.6803039 },
    { lat: 49.1313996, lng: 21.6806692 },
    { lat: 49.1314212, lng: 21.6807602 },
    { lat: 49.1314517, lng: 21.6807626 },
    { lat: 49.1317577, lng: 21.6807632 },
    { lat: 49.1318556, lng: 21.6808465 },
    { lat: 49.1320252, lng: 21.6812508 },
    { lat: 49.132097, lng: 21.6816228 },
    { lat: 49.1322008, lng: 21.6817567 },
    { lat: 49.1323999, lng: 21.6823029 },
    { lat: 49.1324297, lng: 21.6826584 },
    { lat: 49.1323877, lng: 21.6834349 },
    { lat: 49.1324555, lng: 21.6838233 },
    { lat: 49.1327792, lng: 21.6848128 },
    { lat: 49.1329632, lng: 21.6846242 },
    { lat: 49.1333932, lng: 21.6840067 },
    { lat: 49.1337785, lng: 21.683781 },
    { lat: 49.1344417, lng: 21.6835925 },
    { lat: 49.1349257, lng: 21.6804298 },
    { lat: 49.1350753, lng: 21.6803475 },
    { lat: 49.1353763, lng: 21.6792031 },
    { lat: 49.1355719, lng: 21.6781989 },
    { lat: 49.1356644, lng: 21.67725 },
    { lat: 49.1356432, lng: 21.6767624 },
    { lat: 49.1355441, lng: 21.6763839 },
    { lat: 49.1352826, lng: 21.6746208 },
    { lat: 49.1358429, lng: 21.6742864 },
    { lat: 49.1363001, lng: 21.6738905 },
    { lat: 49.1367027, lng: 21.6731747 },
    { lat: 49.1371203, lng: 21.6725599 },
    { lat: 49.1372741, lng: 21.6724665 },
    { lat: 49.1381988, lng: 21.6714286 },
    { lat: 49.1390601, lng: 21.6709088 },
    { lat: 49.1396628, lng: 21.6703597 },
    { lat: 49.1398461, lng: 21.6701121 },
    { lat: 49.1400175, lng: 21.6697032 },
    { lat: 49.1401729, lng: 21.6695037 },
    { lat: 49.1401787, lng: 21.669093 },
    { lat: 49.1412907, lng: 21.6678646 },
    { lat: 49.1412361, lng: 21.6669745 },
    { lat: 49.1409993, lng: 21.6655867 },
    { lat: 49.1406784, lng: 21.6644981 },
    { lat: 49.1407297, lng: 21.6639365 },
    { lat: 49.1408742, lng: 21.6634164 },
    { lat: 49.1410011, lng: 21.6625863 },
    { lat: 49.141521, lng: 21.6617631 },
    { lat: 49.1419884, lng: 21.6607893 },
    { lat: 49.142215, lng: 21.6600197 },
    { lat: 49.1424976, lng: 21.6592808 },
    { lat: 49.1437279, lng: 21.6575265 },
    { lat: 49.1442635, lng: 21.6572824 },
    { lat: 49.1442189, lng: 21.6567951 },
    { lat: 49.1445745, lng: 21.6565926 },
    { lat: 49.144955, lng: 21.6560292 },
    { lat: 49.1448409, lng: 21.6558463 },
    { lat: 49.1451756, lng: 21.6555345 },
    { lat: 49.1451912, lng: 21.6551174 },
    { lat: 49.1455864, lng: 21.6547155 },
    { lat: 49.1458612, lng: 21.6542281 },
    { lat: 49.1443816, lng: 21.6524398 },
    { lat: 49.1441198, lng: 21.6521233 },
    { lat: 49.1441819, lng: 21.6511387 },
    { lat: 49.1424081, lng: 21.649951 },
    { lat: 49.14166, lng: 21.649661 },
    { lat: 49.1420442, lng: 21.6486992 },
    { lat: 49.1355313, lng: 21.642869 },
    { lat: 49.1342356, lng: 21.6406919 },
    { lat: 49.1341509, lng: 21.6400671 },
    { lat: 49.1337141, lng: 21.6396393 },
    { lat: 49.1334263, lng: 21.639691 },
    { lat: 49.1330175, lng: 21.6403726 },
    { lat: 49.1327308, lng: 21.6404641 },
    { lat: 49.1324852, lng: 21.6406995 },
    { lat: 49.132258, lng: 21.6414439 },
    { lat: 49.1315748, lng: 21.6413903 },
    { lat: 49.1312594, lng: 21.6411646 },
    { lat: 49.1296133, lng: 21.64186 },
    { lat: 49.1288302, lng: 21.6421001 },
    { lat: 49.1274688, lng: 21.6427508 },
    { lat: 49.126042, lng: 21.6431959 },
    { lat: 49.1258376, lng: 21.6427538 },
    { lat: 49.1245239, lng: 21.6429714 },
    { lat: 49.1238767, lng: 21.6436598 },
    { lat: 49.1237909, lng: 21.6440581 },
    { lat: 49.1233142, lng: 21.6443592 },
    { lat: 49.1224801, lng: 21.6453316 },
    { lat: 49.11931, lng: 21.6460353 },
    { lat: 49.1181656, lng: 21.6461887 },
    { lat: 49.1174973, lng: 21.6468028 },
    { lat: 49.1163679, lng: 21.6475128 },
    { lat: 49.1158474, lng: 21.6484731 },
    { lat: 49.1145589, lng: 21.6469261 },
    { lat: 49.1142586, lng: 21.6464789 },
    { lat: 49.1132782, lng: 21.6474047 },
    { lat: 49.1130295, lng: 21.6475246 },
    { lat: 49.1128181, lng: 21.6478206 },
    { lat: 49.1120038, lng: 21.64853 },
    { lat: 49.1122665, lng: 21.6500382 },
    { lat: 49.1125455, lng: 21.6507402 },
    { lat: 49.1126495, lng: 21.6510015 },
    { lat: 49.1126716, lng: 21.651199 },
    { lat: 49.1091794, lng: 21.6517212 },
    { lat: 49.1091725, lng: 21.6522527 },
    { lat: 49.1086805, lng: 21.6525757 },
    { lat: 49.1084746, lng: 21.6531924 },
    { lat: 49.1086394, lng: 21.6532541 },
    { lat: 49.1089837, lng: 21.6537078 },
    { lat: 49.109157, lng: 21.6542484 },
    { lat: 49.1094185, lng: 21.6546394 },
    { lat: 49.1093211, lng: 21.6550693 },
    { lat: 49.1095155, lng: 21.6554326 },
    { lat: 49.1095449, lng: 21.6558523 },
    { lat: 49.1098438, lng: 21.6559982 },
    { lat: 49.1100102, lng: 21.6567856 },
    { lat: 49.1107064, lng: 21.6570666 },
    { lat: 49.1109317, lng: 21.656294 },
    { lat: 49.1115599, lng: 21.6556518 },
    { lat: 49.1122414, lng: 21.6558475 },
  ],
  Soľník: [
    { lat: 49.2172326, lng: 21.7292296 },
    { lat: 49.2172071, lng: 21.729337 },
    { lat: 49.2167469, lng: 21.7296717 },
    { lat: 49.2164747, lng: 21.7300091 },
    { lat: 49.2161168, lng: 21.7307624 },
    { lat: 49.2154876, lng: 21.7315515 },
    { lat: 49.2149901, lng: 21.732009 },
    { lat: 49.2141263, lng: 21.7326093 },
    { lat: 49.2136748, lng: 21.7331024 },
    { lat: 49.2136125, lng: 21.733252 },
    { lat: 49.2131023, lng: 21.7336577 },
    { lat: 49.2126763, lng: 21.7343057 },
    { lat: 49.2124121, lng: 21.7343522 },
    { lat: 49.211771, lng: 21.7343537 },
    { lat: 49.211408, lng: 21.7342451 },
    { lat: 49.2108859, lng: 21.7337672 },
    { lat: 49.2102138, lng: 21.7333651 },
    { lat: 49.2097067, lng: 21.7328272 },
    { lat: 49.2095246, lng: 21.7323621 },
    { lat: 49.2092675, lng: 21.7320855 },
    { lat: 49.2088474, lng: 21.7318096 },
    { lat: 49.2083801, lng: 21.7317521 },
    { lat: 49.2081024, lng: 21.7319196 },
    { lat: 49.2077921, lng: 21.7320136 },
    { lat: 49.2070256, lng: 21.7320448 },
    { lat: 49.2060275, lng: 21.731855 },
    { lat: 49.2054844, lng: 21.7316593 },
    { lat: 49.2051519, lng: 21.731672 },
    { lat: 49.2047665, lng: 21.7313961 },
    { lat: 49.2046994, lng: 21.7303048 },
    { lat: 49.2045121, lng: 21.7298207 },
    { lat: 49.2042981, lng: 21.7294688 },
    { lat: 49.2038652, lng: 21.7291333 },
    { lat: 49.2032148, lng: 21.7289548 },
    { lat: 49.2028129, lng: 21.7287753 },
    { lat: 49.2023688, lng: 21.7286973 },
    { lat: 49.201682, lng: 21.7290999 },
    { lat: 49.2010306, lng: 21.7296013 },
    { lat: 49.2003498, lng: 21.7295574 },
    { lat: 49.1999239, lng: 21.7296571 },
    { lat: 49.1992585, lng: 21.7300116 },
    { lat: 49.1992611, lng: 21.7301549 },
    { lat: 49.1994247, lng: 21.7304304 },
    { lat: 49.1993976, lng: 21.7312324 },
    { lat: 49.1994893, lng: 21.7320138 },
    { lat: 49.1993805, lng: 21.7322392 },
    { lat: 49.1994386, lng: 21.7329033 },
    { lat: 49.1995216, lng: 21.7331499 },
    { lat: 49.1995079, lng: 21.733354 },
    { lat: 49.199757, lng: 21.7346861 },
    { lat: 49.1995884, lng: 21.7348645 },
    { lat: 49.1998699, lng: 21.7372942 },
    { lat: 49.2003421, lng: 21.7372443 },
    { lat: 49.2004298, lng: 21.7378752 },
    { lat: 49.2007986, lng: 21.7378291 },
    { lat: 49.2010948, lng: 21.7380119 },
    { lat: 49.2011252, lng: 21.7381902 },
    { lat: 49.20182, lng: 21.7384262 },
    { lat: 49.2018465, lng: 21.7390447 },
    { lat: 49.2021826, lng: 21.7392032 },
    { lat: 49.2026243, lng: 21.7400853 },
    { lat: 49.2028081, lng: 21.7402258 },
    { lat: 49.2031095, lng: 21.7407828 },
    { lat: 49.2037222, lng: 21.7413653 },
    { lat: 49.2041794, lng: 21.7415929 },
    { lat: 49.2045455, lng: 21.7420772 },
    { lat: 49.2053448, lng: 21.7427457 },
    { lat: 49.2052004, lng: 21.7432403 },
    { lat: 49.2053621, lng: 21.743481 },
    { lat: 49.2054266, lng: 21.7433256 },
    { lat: 49.205534, lng: 21.7435197 },
    { lat: 49.2060214, lng: 21.7438265 },
    { lat: 49.2059576, lng: 21.7440549 },
    { lat: 49.2062641, lng: 21.7442447 },
    { lat: 49.2074641, lng: 21.7447055 },
    { lat: 49.2084122, lng: 21.744913 },
    { lat: 49.208412, lng: 21.7450314 },
    { lat: 49.209738, lng: 21.7455802 },
    { lat: 49.2102089, lng: 21.7459876 },
    { lat: 49.2100852, lng: 21.7462852 },
    { lat: 49.2103885, lng: 21.7465691 },
    { lat: 49.2112812, lng: 21.7470151 },
    { lat: 49.2112751, lng: 21.7473389 },
    { lat: 49.2113631, lng: 21.7475638 },
    { lat: 49.2112903, lng: 21.7480932 },
    { lat: 49.2114545, lng: 21.748689 },
    { lat: 49.211374, lng: 21.7490051 },
    { lat: 49.2119473, lng: 21.7494988 },
    { lat: 49.2118961, lng: 21.7496491 },
    { lat: 49.2125922, lng: 21.7504925 },
    { lat: 49.2130657, lng: 21.7513036 },
    { lat: 49.2127791, lng: 21.7515723 },
    { lat: 49.2127052, lng: 21.7519643 },
    { lat: 49.2133171, lng: 21.7529911 },
    { lat: 49.2130791, lng: 21.7536469 },
    { lat: 49.2129092, lng: 21.7539027 },
    { lat: 49.2127609, lng: 21.7539531 },
    { lat: 49.2127311, lng: 21.7540647 },
    { lat: 49.2128349, lng: 21.7542846 },
    { lat: 49.2128978, lng: 21.7547924 },
    { lat: 49.21277, lng: 21.7548535 },
    { lat: 49.2127026, lng: 21.7554179 },
    { lat: 49.2128195, lng: 21.7558856 },
    { lat: 49.213251, lng: 21.7570202 },
    { lat: 49.2130774, lng: 21.7574806 },
    { lat: 49.2132491, lng: 21.7586585 },
    { lat: 49.2130545, lng: 21.7589587 },
    { lat: 49.2129518, lng: 21.7592862 },
    { lat: 49.2128856, lng: 21.7597249 },
    { lat: 49.2131105, lng: 21.7604106 },
    { lat: 49.2133466, lng: 21.7622808 },
    { lat: 49.2134564, lng: 21.7627015 },
    { lat: 49.2136522, lng: 21.7628694 },
    { lat: 49.2136957, lng: 21.7631992 },
    { lat: 49.213565, lng: 21.7635372 },
    { lat: 49.2134028, lng: 21.763741 },
    { lat: 49.2138168, lng: 21.7642569 },
    { lat: 49.2144786, lng: 21.765556 },
    { lat: 49.2153592, lng: 21.7691992 },
    { lat: 49.2156279, lng: 21.7695412 },
    { lat: 49.2157299, lng: 21.7692684 },
    { lat: 49.2158553, lng: 21.7692514 },
    { lat: 49.2159998, lng: 21.7694397 },
    { lat: 49.2161356, lng: 21.7692947 },
    { lat: 49.2161964, lng: 21.769123 },
    { lat: 49.2165473, lng: 21.7689961 },
    { lat: 49.2166539, lng: 21.769285 },
    { lat: 49.216746, lng: 21.7693294 },
    { lat: 49.2170193, lng: 21.7690267 },
    { lat: 49.2172221, lng: 21.7691629 },
    { lat: 49.2173578, lng: 21.7688937 },
    { lat: 49.2176671, lng: 21.769406 },
    { lat: 49.2179428, lng: 21.769279 },
    { lat: 49.2180921, lng: 21.7693534 },
    { lat: 49.2186418, lng: 21.769254 },
    { lat: 49.2190037, lng: 21.7693751 },
    { lat: 49.2195253, lng: 21.7690133 },
    { lat: 49.2202055, lng: 21.7686602 },
    { lat: 49.2204425, lng: 21.7686453 },
    { lat: 49.2208019, lng: 21.7684978 },
    { lat: 49.221797, lng: 21.7688203 },
    { lat: 49.2220585, lng: 21.7691498 },
    { lat: 49.2222387, lng: 21.7689373 },
    { lat: 49.2226648, lng: 21.7687615 },
    { lat: 49.2230587, lng: 21.7683405 },
    { lat: 49.2230583, lng: 21.7680628 },
    { lat: 49.2231924, lng: 21.7674331 },
    { lat: 49.2232778, lng: 21.7673811 },
    { lat: 49.2232945, lng: 21.7672894 },
    { lat: 49.2232113, lng: 21.7671663 },
    { lat: 49.2229646, lng: 21.76672 },
    { lat: 49.2226429, lng: 21.7668099 },
    { lat: 49.2224923, lng: 21.7665942 },
    { lat: 49.2221849, lng: 21.7664614 },
    { lat: 49.2219521, lng: 21.7664541 },
    { lat: 49.2219131, lng: 21.766297 },
    { lat: 49.2212786, lng: 21.7658651 },
    { lat: 49.2210931, lng: 21.7658326 },
    { lat: 49.2209576, lng: 21.765897 },
    { lat: 49.2208898, lng: 21.7658004 },
    { lat: 49.220876, lng: 21.7656148 },
    { lat: 49.2208269, lng: 21.7655408 },
    { lat: 49.2204912, lng: 21.7654861 },
    { lat: 49.2204167, lng: 21.7649196 },
    { lat: 49.2202737, lng: 21.7645271 },
    { lat: 49.2200044, lng: 21.7642071 },
    { lat: 49.2201189, lng: 21.7636826 },
    { lat: 49.2203133, lng: 21.763376 },
    { lat: 49.220282, lng: 21.7632502 },
    { lat: 49.2200223, lng: 21.762831 },
    { lat: 49.2202415, lng: 21.762434 },
    { lat: 49.220158, lng: 21.7613092 },
    { lat: 49.2206203, lng: 21.7609732 },
    { lat: 49.2209036, lng: 21.7604517 },
    { lat: 49.2214971, lng: 21.7586377 },
    { lat: 49.2215917, lng: 21.7580377 },
    { lat: 49.2218215, lng: 21.7576703 },
    { lat: 49.2220756, lng: 21.7574104 },
    { lat: 49.2225271, lng: 21.7574219 },
    { lat: 49.2229024, lng: 21.7564647 },
    { lat: 49.2233669, lng: 21.7561195 },
    { lat: 49.2244465, lng: 21.7544018 },
    { lat: 49.2256009, lng: 21.7520594 },
    { lat: 49.2252907, lng: 21.7513867 },
    { lat: 49.2252054, lng: 21.7509223 },
    { lat: 49.2248647, lng: 21.7496952 },
    { lat: 49.2243878, lng: 21.7483681 },
    { lat: 49.223922, lng: 21.7464004 },
    { lat: 49.2240175, lng: 21.7443922 },
    { lat: 49.2243084, lng: 21.7424997 },
    { lat: 49.2243091, lng: 21.7410388 },
    { lat: 49.224434, lng: 21.7404492 },
    { lat: 49.2246015, lng: 21.7390814 },
    { lat: 49.2245865, lng: 21.7380052 },
    { lat: 49.2246345, lng: 21.7370557 },
    { lat: 49.2247211, lng: 21.7364206 },
    { lat: 49.2237265, lng: 21.7371265 },
    { lat: 49.2236606, lng: 21.7366415 },
    { lat: 49.2230477, lng: 21.7345996 },
    { lat: 49.2229562, lng: 21.7344638 },
    { lat: 49.2229157, lng: 21.7341201 },
    { lat: 49.2230222, lng: 21.7336004 },
    { lat: 49.2230713, lng: 21.7329909 },
    { lat: 49.2234937, lng: 21.7321645 },
    { lat: 49.2238039, lng: 21.7312992 },
    { lat: 49.2240459, lng: 21.730372 },
    { lat: 49.2240601, lng: 21.7302332 },
    { lat: 49.2238864, lng: 21.729621 },
    { lat: 49.2238602, lng: 21.7296889 },
    { lat: 49.2234833, lng: 21.7299022 },
    { lat: 49.2226062, lng: 21.7301695 },
    { lat: 49.2219068, lng: 21.7305596 },
    { lat: 49.2215204, lng: 21.7305842 },
    { lat: 49.2212845, lng: 21.7307636 },
    { lat: 49.2206587, lng: 21.731006 },
    { lat: 49.2195896, lng: 21.7313222 },
    { lat: 49.2192349, lng: 21.731316 },
    { lat: 49.2190132, lng: 21.7310976 },
    { lat: 49.2183628, lng: 21.7307913 },
    { lat: 49.218043, lng: 21.7305624 },
    { lat: 49.2176094, lng: 21.7301239 },
    { lat: 49.2172326, lng: 21.7292296 },
  ],
  Kolbovce: [
    { lat: 49.1728894, lng: 21.7133102 },
    { lat: 49.1728238, lng: 21.7132542 },
    { lat: 49.1727388, lng: 21.7133558 },
    { lat: 49.17254, lng: 21.71337 },
    { lat: 49.1724848, lng: 21.7134453 },
    { lat: 49.1724098, lng: 21.7133845 },
    { lat: 49.1723687, lng: 21.7131516 },
    { lat: 49.1721249, lng: 21.7131559 },
    { lat: 49.1718257, lng: 21.7133306 },
    { lat: 49.1716896, lng: 21.712826 },
    { lat: 49.1716302, lng: 21.7130443 },
    { lat: 49.1714593, lng: 21.7130438 },
    { lat: 49.1713953, lng: 21.7129251 },
    { lat: 49.1713835, lng: 21.7125631 },
    { lat: 49.1711046, lng: 21.7125722 },
    { lat: 49.1710594, lng: 21.7124761 },
    { lat: 49.1709436, lng: 21.7126501 },
    { lat: 49.1708738, lng: 21.7126426 },
    { lat: 49.1706819, lng: 21.7124108 },
    { lat: 49.1706157, lng: 21.7122463 },
    { lat: 49.1703903, lng: 21.712147 },
    { lat: 49.170211, lng: 21.7119622 },
    { lat: 49.1703017, lng: 21.711742 },
    { lat: 49.1699996, lng: 21.7110827 },
    { lat: 49.1697583, lng: 21.7109356 },
    { lat: 49.1696485, lng: 21.7107774 },
    { lat: 49.1697574, lng: 21.7107343 },
    { lat: 49.1698561, lng: 21.7105902 },
    { lat: 49.1698304, lng: 21.7105188 },
    { lat: 49.1694823, lng: 21.7102837 },
    { lat: 49.1694046, lng: 21.710286 },
    { lat: 49.1693408, lng: 21.7100416 },
    { lat: 49.1692366, lng: 21.7100154 },
    { lat: 49.1691168, lng: 21.7100749 },
    { lat: 49.1690573, lng: 21.7097921 },
    { lat: 49.1687645, lng: 21.709994 },
    { lat: 49.1685436, lng: 21.7102651 },
    { lat: 49.1681703, lng: 21.7111594 },
    { lat: 49.1680036, lng: 21.711829 },
    { lat: 49.1678866, lng: 21.712116 },
    { lat: 49.1675237, lng: 21.7123866 },
    { lat: 49.1665282, lng: 21.7126846 },
    { lat: 49.166514, lng: 21.7131675 },
    { lat: 49.166128, lng: 21.7135369 },
    { lat: 49.1660192, lng: 21.7138065 },
    { lat: 49.1659218, lng: 21.7137428 },
    { lat: 49.1657962, lng: 21.7140076 },
    { lat: 49.165385, lng: 21.7144451 },
    { lat: 49.1648896, lng: 21.7153173 },
    { lat: 49.1643829, lng: 21.7159753 },
    { lat: 49.1638971, lng: 21.7160209 },
    { lat: 49.1634026, lng: 21.7161633 },
    { lat: 49.1629347, lng: 21.7161266 },
    { lat: 49.1626068, lng: 21.7163141 },
    { lat: 49.1622217, lng: 21.7160526 },
    { lat: 49.1615932, lng: 21.7160132 },
    { lat: 49.1612519, lng: 21.715919 },
    { lat: 49.1604833, lng: 21.7159045 },
    { lat: 49.1600876, lng: 21.7162069 },
    { lat: 49.1595543, lng: 21.7164617 },
    { lat: 49.1596702, lng: 21.7168837 },
    { lat: 49.1594893, lng: 21.7170189 },
    { lat: 49.1594271, lng: 21.7172596 },
    { lat: 49.159571, lng: 21.717255 },
    { lat: 49.159687, lng: 21.7175113 },
    { lat: 49.1595358, lng: 21.7177899 },
    { lat: 49.1596284, lng: 21.7178855 },
    { lat: 49.1597895, lng: 21.7176422 },
    { lat: 49.1597282, lng: 21.7184303 },
    { lat: 49.1595788, lng: 21.7183946 },
    { lat: 49.159511, lng: 21.7188873 },
    { lat: 49.1597129, lng: 21.7190775 },
    { lat: 49.1601999, lng: 21.7206335 },
    { lat: 49.1601489, lng: 21.7208325 },
    { lat: 49.1597966, lng: 21.7209546 },
    { lat: 49.1596191, lng: 21.7213854 },
    { lat: 49.159799, lng: 21.7218501 },
    { lat: 49.159673, lng: 21.7223123 },
    { lat: 49.1593297, lng: 21.7229729 },
    { lat: 49.1591315, lng: 21.7234805 },
    { lat: 49.1585403, lng: 21.7245529 },
    { lat: 49.1582796, lng: 21.7248972 },
    { lat: 49.1579552, lng: 21.7258528 },
    { lat: 49.1577359, lng: 21.7260299 },
    { lat: 49.1573334, lng: 21.7272207 },
    { lat: 49.1573587, lng: 21.7274976 },
    { lat: 49.1571259, lng: 21.727884 },
    { lat: 49.1570125, lng: 21.7289498 },
    { lat: 49.1563651, lng: 21.7285017 },
    { lat: 49.1562751, lng: 21.7289271 },
    { lat: 49.1563077, lng: 21.7295608 },
    { lat: 49.1561614, lng: 21.7302761 },
    { lat: 49.155933, lng: 21.7308688 },
    { lat: 49.1556612, lng: 21.7311005 },
    { lat: 49.1553592, lng: 21.7315436 },
    { lat: 49.1546855, lng: 21.7323528 },
    { lat: 49.1539194, lng: 21.7341384 },
    { lat: 49.1537541, lng: 21.734775 },
    { lat: 49.1537608, lng: 21.7351962 },
    { lat: 49.1535712, lng: 21.735808 },
    { lat: 49.1535084, lng: 21.7363639 },
    { lat: 49.1530849, lng: 21.7381743 },
    { lat: 49.1530985, lng: 21.7385996 },
    { lat: 49.1530117, lng: 21.7391034 },
    { lat: 49.1527964, lng: 21.739554 },
    { lat: 49.1526612, lng: 21.7399667 },
    { lat: 49.1521574, lng: 21.740831 },
    { lat: 49.1518668, lng: 21.7412762 },
    { lat: 49.1519312, lng: 21.7413312 },
    { lat: 49.1529676, lng: 21.7416052 },
    { lat: 49.1543891, lng: 21.7415002 },
    { lat: 49.1545303, lng: 21.7415634 },
    { lat: 49.1550531, lng: 21.7415528 },
    { lat: 49.1557184, lng: 21.7411909 },
    { lat: 49.1562451, lng: 21.7410697 },
    { lat: 49.156627, lng: 21.7413488 },
    { lat: 49.1569882, lng: 21.7413151 },
    { lat: 49.1571308, lng: 21.7410037 },
    { lat: 49.1580948, lng: 21.7409969 },
    { lat: 49.1591148, lng: 21.7406966 },
    { lat: 49.1598499, lng: 21.7409252 },
    { lat: 49.1612854, lng: 21.7416082 },
    { lat: 49.1617399, lng: 21.7415267 },
    { lat: 49.1621404, lng: 21.7419999 },
    { lat: 49.1621565, lng: 21.7416754 },
    { lat: 49.1623612, lng: 21.7411596 },
    { lat: 49.1626029, lng: 21.7408511 },
    { lat: 49.1627677, lng: 21.7403636 },
    { lat: 49.1628756, lng: 21.740317 },
    { lat: 49.1630826, lng: 21.7400699 },
    { lat: 49.1631118, lng: 21.7401037 },
    { lat: 49.1635705, lng: 21.7392915 },
    { lat: 49.1635339, lng: 21.7391396 },
    { lat: 49.1637721, lng: 21.7386623 },
    { lat: 49.1639445, lng: 21.7387984 },
    { lat: 49.1640648, lng: 21.7390515 },
    { lat: 49.1647619, lng: 21.739803 },
    { lat: 49.1651065, lng: 21.7400784 },
    { lat: 49.1652276, lng: 21.7403157 },
    { lat: 49.1655167, lng: 21.7404899 },
    { lat: 49.1656599, lng: 21.7407489 },
    { lat: 49.1659494, lng: 21.7409374 },
    { lat: 49.1661387, lng: 21.7411626 },
    { lat: 49.1663696, lng: 21.7411772 },
    { lat: 49.1669068, lng: 21.7419162 },
    { lat: 49.1669961, lng: 21.74185 },
    { lat: 49.1671168, lng: 21.7419493 },
    { lat: 49.1677608, lng: 21.7419662 },
    { lat: 49.1682836, lng: 21.7424639 },
    { lat: 49.1687687, lng: 21.7431613 },
    { lat: 49.1689446, lng: 21.7432587 },
    { lat: 49.1691672, lng: 21.7435117 },
    { lat: 49.1693334, lng: 21.7434152 },
    { lat: 49.1694429, lng: 21.7435555 },
    { lat: 49.1696484, lng: 21.7438391 },
    { lat: 49.1697468, lng: 21.7441167 },
    { lat: 49.1696319, lng: 21.7445321 },
    { lat: 49.1698113, lng: 21.7446426 },
    { lat: 49.1700892, lng: 21.7452823 },
    { lat: 49.1702553, lng: 21.7455212 },
    { lat: 49.1704966, lng: 21.7456337 },
    { lat: 49.1708228, lng: 21.7456783 },
    { lat: 49.170858, lng: 21.7460723 },
    { lat: 49.1709947, lng: 21.7462595 },
    { lat: 49.171097, lng: 21.7465552 },
    { lat: 49.1713339, lng: 21.7468405 },
    { lat: 49.1714243, lng: 21.7472347 },
    { lat: 49.1713598, lng: 21.7475169 },
    { lat: 49.1711624, lng: 21.7478777 },
    { lat: 49.1712803, lng: 21.7481308 },
    { lat: 49.1709874, lng: 21.7488379 },
    { lat: 49.1710191, lng: 21.7488874 },
    { lat: 49.1710043, lng: 21.7490957 },
    { lat: 49.1707983, lng: 21.7496864 },
    { lat: 49.170769, lng: 21.7499438 },
    { lat: 49.1706325, lng: 21.7501819 },
    { lat: 49.1707583, lng: 21.7505553 },
    { lat: 49.1707605, lng: 21.7506839 },
    { lat: 49.1709223, lng: 21.7509385 },
    { lat: 49.1710006, lng: 21.7513407 },
    { lat: 49.1712474, lng: 21.7518684 },
    { lat: 49.1713475, lng: 21.7525086 },
    { lat: 49.1715646, lng: 21.75277 },
    { lat: 49.1715704, lng: 21.7528638 },
    { lat: 49.1713999, lng: 21.7531378 },
    { lat: 49.1713954, lng: 21.7532182 },
    { lat: 49.1715446, lng: 21.7533788 },
    { lat: 49.1716047, lng: 21.753566 },
    { lat: 49.1715686, lng: 21.7538597 },
    { lat: 49.1717707, lng: 21.7540152 },
    { lat: 49.1716474, lng: 21.7549748 },
    { lat: 49.1717921, lng: 21.7554721 },
    { lat: 49.1717021, lng: 21.7568354 },
    { lat: 49.1716994, lng: 21.7575499 },
    { lat: 49.171873, lng: 21.7582889 },
    { lat: 49.1719298, lng: 21.7588592 },
    { lat: 49.1718039, lng: 21.7596409 },
    { lat: 49.1716531, lng: 21.7601081 },
    { lat: 49.171358, lng: 21.7615437 },
    { lat: 49.1710749, lng: 21.7620736 },
    { lat: 49.1708112, lng: 21.76317 },
    { lat: 49.1704257, lng: 21.7642958 },
    { lat: 49.16992, lng: 21.765344 },
    { lat: 49.170225, lng: 21.765305 },
    { lat: 49.170226, lng: 21.765304 },
    { lat: 49.170647, lng: 21.765 },
    { lat: 49.171766, lng: 21.764838 },
    { lat: 49.171876, lng: 21.764779 },
    { lat: 49.172162, lng: 21.764823 },
    { lat: 49.172583, lng: 21.764831 },
    { lat: 49.172721, lng: 21.764745 },
    { lat: 49.172988, lng: 21.764807 },
    { lat: 49.173175, lng: 21.764571 },
    { lat: 49.173478, lng: 21.764651 },
    { lat: 49.173915, lng: 21.764465 },
    { lat: 49.175435, lng: 21.763376 },
    { lat: 49.175683, lng: 21.76328 },
    { lat: 49.175923, lng: 21.763154 },
    { lat: 49.176009, lng: 21.763109 },
    { lat: 49.176494, lng: 21.762844 },
    { lat: 49.176832, lng: 21.762729 },
    { lat: 49.179219, lng: 21.762648 },
    { lat: 49.179241, lng: 21.762792 },
    { lat: 49.179721, lng: 21.762801 },
    { lat: 49.180107, lng: 21.762679 },
    { lat: 49.180573, lng: 21.76361 },
    { lat: 49.181201, lng: 21.764383 },
    { lat: 49.181892, lng: 21.764706 },
    { lat: 49.181898, lng: 21.764636 },
    { lat: 49.1819986, lng: 21.7639595 },
    { lat: 49.182219, lng: 21.7632657 },
    { lat: 49.1830548, lng: 21.7619004 },
    { lat: 49.1830595, lng: 21.7610047 },
    { lat: 49.1831904, lng: 21.7598662 },
    { lat: 49.183893, lng: 21.758269 },
    { lat: 49.1843851, lng: 21.7574773 },
    { lat: 49.1845798, lng: 21.7569659 },
    { lat: 49.1843178, lng: 21.7561991 },
    { lat: 49.1842144, lng: 21.7551745 },
    { lat: 49.1842562, lng: 21.754324 },
    { lat: 49.1847352, lng: 21.7541925 },
    { lat: 49.1848175, lng: 21.7537729 },
    { lat: 49.1847681, lng: 21.7532217 },
    { lat: 49.1848037, lng: 21.7529089 },
    { lat: 49.1845983, lng: 21.7518128 },
    { lat: 49.1845822, lng: 21.7514643 },
    { lat: 49.1846563, lng: 21.751107 },
    { lat: 49.184708, lng: 21.7504328 },
    { lat: 49.1848332, lng: 21.7499328 },
    { lat: 49.1849399, lng: 21.7497939 },
    { lat: 49.1850086, lng: 21.7495855 },
    { lat: 49.1855326, lng: 21.7491919 },
    { lat: 49.1852571, lng: 21.7483008 },
    { lat: 49.1850116, lng: 21.7471414 },
    { lat: 49.1850327, lng: 21.7452112 },
    { lat: 49.1851996, lng: 21.7446299 },
    { lat: 49.1858725, lng: 21.7433609 },
    { lat: 49.1862875, lng: 21.7431857 },
    { lat: 49.1862488, lng: 21.7421524 },
    { lat: 49.1859687, lng: 21.7414264 },
    { lat: 49.185951, lng: 21.7406752 },
    { lat: 49.1860088, lng: 21.7402606 },
    { lat: 49.1859143, lng: 21.7399208 },
    { lat: 49.1859724, lng: 21.7396493 },
    { lat: 49.1857439, lng: 21.7386313 },
    { lat: 49.1857586, lng: 21.7379065 },
    { lat: 49.1856131, lng: 21.7374671 },
    { lat: 49.1852324, lng: 21.7374466 },
    { lat: 49.1846041, lng: 21.7372117 },
    { lat: 49.1845031, lng: 21.7372266 },
    { lat: 49.1845325, lng: 21.7368943 },
    { lat: 49.184431, lng: 21.7370052 },
    { lat: 49.184342, lng: 21.7370006 },
    { lat: 49.1838953, lng: 21.736324 },
    { lat: 49.1840707, lng: 21.7357645 },
    { lat: 49.1840087, lng: 21.7357198 },
    { lat: 49.1839374, lng: 21.7355278 },
    { lat: 49.1841054, lng: 21.7350791 },
    { lat: 49.1844025, lng: 21.7347443 },
    { lat: 49.1846441, lng: 21.7346018 },
    { lat: 49.1847041, lng: 21.7344488 },
    { lat: 49.1849426, lng: 21.7341979 },
    { lat: 49.1858479, lng: 21.7334278 },
    { lat: 49.1871241, lng: 21.7321282 },
    { lat: 49.1874941, lng: 21.7318673 },
    { lat: 49.187494, lng: 21.7314465 },
    { lat: 49.1876181, lng: 21.7311242 },
    { lat: 49.1878846, lng: 21.730008 },
    { lat: 49.1885392, lng: 21.7286948 },
    { lat: 49.189508, lng: 21.7273089 },
    { lat: 49.1900548, lng: 21.7268874 },
    { lat: 49.1902633, lng: 21.7263088 },
    { lat: 49.1902715, lng: 21.7255164 },
    { lat: 49.19021, lng: 21.7246387 },
    { lat: 49.1904612, lng: 21.7227109 },
    { lat: 49.1910658, lng: 21.721036 },
    { lat: 49.1914973, lng: 21.7200939 },
    { lat: 49.1914379, lng: 21.7199253 },
    { lat: 49.1911674, lng: 21.7194091 },
    { lat: 49.1908024, lng: 21.7190516 },
    { lat: 49.1907378, lng: 21.71882 },
    { lat: 49.1902521, lng: 21.7183646 },
    { lat: 49.1898576, lng: 21.7176173 },
    { lat: 49.1897264, lng: 21.7176733 },
    { lat: 49.1896586, lng: 21.7173646 },
    { lat: 49.1890052, lng: 21.7168635 },
    { lat: 49.1888047, lng: 21.7169824 },
    { lat: 49.188415, lng: 21.7162014 },
    { lat: 49.1879223, lng: 21.7154487 },
    { lat: 49.1878888, lng: 21.7151994 },
    { lat: 49.1877584, lng: 21.7152431 },
    { lat: 49.1876819, lng: 21.7149112 },
    { lat: 49.1874207, lng: 21.7149026 },
    { lat: 49.1873741, lng: 21.7147092 },
    { lat: 49.1872427, lng: 21.714768 },
    { lat: 49.1869605, lng: 21.7143069 },
    { lat: 49.1868075, lng: 21.7143844 },
    { lat: 49.1866414, lng: 21.7143195 },
    { lat: 49.1865749, lng: 21.7139813 },
    { lat: 49.186352, lng: 21.7140987 },
    { lat: 49.1863603, lng: 21.713818 },
    { lat: 49.1861917, lng: 21.7138256 },
    { lat: 49.1860338, lng: 21.7136837 },
    { lat: 49.1857502, lng: 21.7131727 },
    { lat: 49.1856207, lng: 21.7132019 },
    { lat: 49.185465, lng: 21.7130652 },
    { lat: 49.185422, lng: 21.7132657 },
    { lat: 49.1853314, lng: 21.7128222 },
    { lat: 49.1852277, lng: 21.7127257 },
    { lat: 49.1849928, lng: 21.7126957 },
    { lat: 49.1847602, lng: 21.7127843 },
    { lat: 49.1845405, lng: 21.7127307 },
    { lat: 49.1842065, lng: 21.7128194 },
    { lat: 49.183999, lng: 21.7128014 },
    { lat: 49.1839613, lng: 21.712908 },
    { lat: 49.1836348, lng: 21.7130207 },
    { lat: 49.1831699, lng: 21.7130974 },
    { lat: 49.1829579, lng: 21.7130322 },
    { lat: 49.1825954, lng: 21.7131928 },
    { lat: 49.1824244, lng: 21.7130657 },
    { lat: 49.1823297, lng: 21.7128486 },
    { lat: 49.1821776, lng: 21.7127922 },
    { lat: 49.1818974, lng: 21.7128755 },
    { lat: 49.1817945, lng: 21.7130207 },
    { lat: 49.1816137, lng: 21.7127021 },
    { lat: 49.1815233, lng: 21.7127707 },
    { lat: 49.1812909, lng: 21.7127441 },
    { lat: 49.1810384, lng: 21.712603 },
    { lat: 49.1807105, lng: 21.7126202 },
    { lat: 49.1803468, lng: 21.7130264 },
    { lat: 49.1800304, lng: 21.7131025 },
    { lat: 49.179969, lng: 21.7132052 },
    { lat: 49.1799196, lng: 21.7131662 },
    { lat: 49.1798944, lng: 21.7134821 },
    { lat: 49.1796596, lng: 21.7136692 },
    { lat: 49.1792185, lng: 21.7138681 },
    { lat: 49.1787542, lng: 21.7142605 },
    { lat: 49.1786734, lng: 21.7142731 },
    { lat: 49.1785327, lng: 21.7145572 },
    { lat: 49.1784487, lng: 21.7146128 },
    { lat: 49.1784622, lng: 21.7148748 },
    { lat: 49.1783486, lng: 21.7149074 },
    { lat: 49.1783568, lng: 21.7150054 },
    { lat: 49.1783113, lng: 21.7149057 },
    { lat: 49.1781404, lng: 21.715163 },
    { lat: 49.1778441, lng: 21.7152713 },
    { lat: 49.1776755, lng: 21.7151534 },
    { lat: 49.1775885, lng: 21.71518 },
    { lat: 49.1775043, lng: 21.7150522 },
    { lat: 49.1773647, lng: 21.7152201 },
    { lat: 49.177296, lng: 21.7151791 },
    { lat: 49.1772467, lng: 21.7149757 },
    { lat: 49.1768823, lng: 21.7149336 },
    { lat: 49.1760595, lng: 21.7150422 },
    { lat: 49.1757053, lng: 21.7147985 },
    { lat: 49.1754117, lng: 21.7144572 },
    { lat: 49.1754197, lng: 21.7142554 },
    { lat: 49.1752962, lng: 21.7141307 },
    { lat: 49.1750559, lng: 21.7141093 },
    { lat: 49.1744586, lng: 21.7138106 },
    { lat: 49.1742843, lng: 21.7135266 },
    { lat: 49.1742011, lng: 21.7132247 },
    { lat: 49.1738238, lng: 21.7132122 },
    { lat: 49.173202, lng: 21.7136028 },
    { lat: 49.1729357, lng: 21.7135687 },
    { lat: 49.1728894, lng: 21.7133102 },
  ],
  Šandal: [
    { lat: 49.1958819, lng: 21.6404714 },
    { lat: 49.1958007, lng: 21.639714 },
    { lat: 49.19555, lng: 21.6383638 },
    { lat: 49.1955564, lng: 21.635925 },
    { lat: 49.1953798, lng: 21.6347363 },
    { lat: 49.1951927, lng: 21.6294571 },
    { lat: 49.1965433, lng: 21.6289454 },
    { lat: 49.197458, lng: 21.6285003 },
    { lat: 49.1970383, lng: 21.6275501 },
    { lat: 49.1959017, lng: 21.6258619 },
    { lat: 49.1962495, lng: 21.6252116 },
    { lat: 49.1959553, lng: 21.6242067 },
    { lat: 49.1961829, lng: 21.6234386 },
    { lat: 49.1962971, lng: 21.6223061 },
    { lat: 49.1970427, lng: 21.6207459 },
    { lat: 49.1969934, lng: 21.6194807 },
    { lat: 49.1971845, lng: 21.6175943 },
    { lat: 49.1976857, lng: 21.6172507 },
    { lat: 49.1981064, lng: 21.6159788 },
    { lat: 49.1993051, lng: 21.6148983 },
    { lat: 49.2001411, lng: 21.6140476 },
    { lat: 49.2012495, lng: 21.6135233 },
    { lat: 49.201881, lng: 21.6134212 },
    { lat: 49.2022482, lng: 21.6139665 },
    { lat: 49.2023526, lng: 21.6146142 },
    { lat: 49.2023617, lng: 21.6152262 },
    { lat: 49.2021991, lng: 21.6153316 },
    { lat: 49.2021959, lng: 21.6158236 },
    { lat: 49.2028055, lng: 21.6159004 },
    { lat: 49.2030447, lng: 21.6157293 },
    { lat: 49.2032666, lng: 21.6154032 },
    { lat: 49.203589, lng: 21.6151893 },
    { lat: 49.2040003, lng: 21.6145607 },
    { lat: 49.2040215, lng: 21.6136078 },
    { lat: 49.204422, lng: 21.6130011 },
    { lat: 49.2040607, lng: 21.6123778 },
    { lat: 49.2041175, lng: 21.6113013 },
    { lat: 49.2043759, lng: 21.6104724 },
    { lat: 49.2045795, lng: 21.6093638 },
    { lat: 49.2042554, lng: 21.6087079 },
    { lat: 49.2049047, lng: 21.6083115 },
    { lat: 49.2053663, lng: 21.6083196 },
    { lat: 49.2068555, lng: 21.6085621 },
    { lat: 49.2087849, lng: 21.6088484 },
    { lat: 49.2091829, lng: 21.6086051 },
    { lat: 49.2104167, lng: 21.6078509 },
    { lat: 49.2140237, lng: 21.6042364 },
    { lat: 49.2136848, lng: 21.6024888 },
    { lat: 49.2136158, lng: 21.6021331 },
    { lat: 49.2141766, lng: 21.6017829 },
    { lat: 49.215056, lng: 21.6005161 },
    { lat: 49.21468, lng: 21.5997299 },
    { lat: 49.2142561, lng: 21.5988436 },
    { lat: 49.2148613, lng: 21.5974973 },
    { lat: 49.2150214, lng: 21.5967007 },
    { lat: 49.215754, lng: 21.594934 },
    { lat: 49.215234, lng: 21.594114 },
    { lat: 49.215189, lng: 21.594076 },
    { lat: 49.21507, lng: 21.593975 },
    { lat: 49.214738, lng: 21.593647 },
    { lat: 49.214603, lng: 21.593903 },
    { lat: 49.21392, lng: 21.593737 },
    { lat: 49.213623, lng: 21.594238 },
    { lat: 49.213426, lng: 21.594634 },
    { lat: 49.213137, lng: 21.59478 },
    { lat: 49.212547, lng: 21.595205 },
    { lat: 49.212224, lng: 21.595286 },
    { lat: 49.211962, lng: 21.595456 },
    { lat: 49.211977, lng: 21.595238 },
    { lat: 49.21162, lng: 21.595305 },
    { lat: 49.211444, lng: 21.59529 },
    { lat: 49.211103, lng: 21.595398 },
    { lat: 49.210888, lng: 21.595601 },
    { lat: 49.210485, lng: 21.596051 },
    { lat: 49.210272, lng: 21.596439 },
    { lat: 49.210042, lng: 21.597858 },
    { lat: 49.209461, lng: 21.598787 },
    { lat: 49.208701, lng: 21.599416 },
    { lat: 49.208666, lng: 21.598938 },
    { lat: 49.208643, lng: 21.598779 },
    { lat: 49.208581, lng: 21.598752 },
    { lat: 49.207726, lng: 21.598381 },
    { lat: 49.207676, lng: 21.598383 },
    { lat: 49.207673, lng: 21.598382 },
    { lat: 49.207367, lng: 21.598258 },
    { lat: 49.207019, lng: 21.598116 },
    { lat: 49.206976, lng: 21.598099 },
    { lat: 49.206933, lng: 21.598081 },
    { lat: 49.206771, lng: 21.598039 },
    { lat: 49.206567, lng: 21.598208 },
    { lat: 49.20618, lng: 21.598226 },
    { lat: 49.205948, lng: 21.598316 },
    { lat: 49.205796, lng: 21.598665 },
    { lat: 49.205487, lng: 21.598595 },
    { lat: 49.204261, lng: 21.598121 },
    { lat: 49.201779, lng: 21.597722 },
    { lat: 49.201224, lng: 21.597734 },
    { lat: 49.200578, lng: 21.597312 },
    { lat: 49.199821, lng: 21.597348 },
    { lat: 49.199595, lng: 21.597464 },
    { lat: 49.199368, lng: 21.59722 },
    { lat: 49.199105, lng: 21.597341 },
    { lat: 49.198992, lng: 21.59724 },
    { lat: 49.198722, lng: 21.597089 },
    { lat: 49.198196, lng: 21.597225 },
    { lat: 49.198167, lng: 21.597168 },
    { lat: 49.197986, lng: 21.596791 },
    { lat: 49.197818, lng: 21.597195 },
    { lat: 49.197775, lng: 21.597297 },
    { lat: 49.197693, lng: 21.597365 },
    { lat: 49.19753, lng: 21.597498 },
    { lat: 49.197169, lng: 21.597358 },
    { lat: 49.197017, lng: 21.597524 },
    { lat: 49.196917, lng: 21.597488 },
    { lat: 49.196873, lng: 21.597472 },
    { lat: 49.196609, lng: 21.597719 },
    { lat: 49.19627, lng: 21.597304 },
    { lat: 49.196073, lng: 21.597683 },
    { lat: 49.195842, lng: 21.597634 },
    { lat: 49.195629, lng: 21.597911 },
    { lat: 49.195228, lng: 21.597808 },
    { lat: 49.195035, lng: 21.59777 },
    { lat: 49.19481, lng: 21.597725 },
    { lat: 49.194653, lng: 21.59785 },
    { lat: 49.194477, lng: 21.597811 },
    { lat: 49.193921, lng: 21.597508 },
    { lat: 49.193776, lng: 21.59733 },
    { lat: 49.193432, lng: 21.597588 },
    { lat: 49.193401, lng: 21.597267 },
    { lat: 49.192993, lng: 21.597133 },
    { lat: 49.192831, lng: 21.597412 },
    { lat: 49.192708, lng: 21.597391 },
    { lat: 49.192491, lng: 21.597166 },
    { lat: 49.192551, lng: 21.596915 },
    { lat: 49.191994, lng: 21.595902 },
    { lat: 49.191749, lng: 21.596022 },
    { lat: 49.191591, lng: 21.595593 },
    { lat: 49.191617, lng: 21.595086 },
    { lat: 49.191256, lng: 21.595013 },
    { lat: 49.191209, lng: 21.594669 },
    { lat: 49.190897, lng: 21.594744 },
    { lat: 49.190782, lng: 21.594604 },
    { lat: 49.190797, lng: 21.594268 },
    { lat: 49.190595, lng: 21.594219 },
    { lat: 49.190504, lng: 21.593992 },
    { lat: 49.190333, lng: 21.594003 },
    { lat: 49.190445, lng: 21.593837 },
    { lat: 49.190246, lng: 21.593626 },
    { lat: 49.190165, lng: 21.593207 },
    { lat: 49.189862, lng: 21.593093 },
    { lat: 49.189817, lng: 21.59296 },
    { lat: 49.189706, lng: 21.592339 },
    { lat: 49.189835, lng: 21.591478 },
    { lat: 49.189814, lng: 21.590846 },
    { lat: 49.190027, lng: 21.590532 },
    { lat: 49.190023, lng: 21.590381 },
    { lat: 49.190013, lng: 21.58991 },
    { lat: 49.190008, lng: 21.589747 },
    { lat: 49.189673, lng: 21.589407 },
    { lat: 49.189613, lng: 21.589397 },
    { lat: 49.189497, lng: 21.589434 },
    { lat: 49.189376, lng: 21.589234 },
    { lat: 49.189317, lng: 21.589012 },
    { lat: 49.1892, lng: 21.588956 },
    { lat: 49.189086, lng: 21.588808 },
    { lat: 49.188987, lng: 21.588723 },
    { lat: 49.188938, lng: 21.588728 },
    { lat: 49.188893, lng: 21.588694 },
    { lat: 49.188871, lng: 21.588626 },
    { lat: 49.188814, lng: 21.588559 },
    { lat: 49.188741, lng: 21.588562 },
    { lat: 49.188733, lng: 21.588472 },
    { lat: 49.188648, lng: 21.58842 },
    { lat: 49.188616, lng: 21.588428 },
    { lat: 49.188347, lng: 21.588148 },
    { lat: 49.188288, lng: 21.588119 },
    { lat: 49.18824, lng: 21.588041 },
    { lat: 49.188171, lng: 21.588011 },
    { lat: 49.188108, lng: 21.588011 },
    { lat: 49.187917, lng: 21.587754 },
    { lat: 49.187798, lng: 21.587658 },
    { lat: 49.187758, lng: 21.587582 },
    { lat: 49.187623, lng: 21.587431 },
    { lat: 49.187497, lng: 21.587299 },
    { lat: 49.187353, lng: 21.587183 },
    { lat: 49.187226, lng: 21.587139 },
    { lat: 49.187189, lng: 21.587102 },
    { lat: 49.187124, lng: 21.587083 },
    { lat: 49.187054, lng: 21.587077 },
    { lat: 49.1869, lng: 21.586924 },
    { lat: 49.186801, lng: 21.586812 },
    { lat: 49.186692, lng: 21.586746 },
    { lat: 49.186637, lng: 21.586667 },
    { lat: 49.186601, lng: 21.586639 },
    { lat: 49.186568, lng: 21.586586 },
    { lat: 49.186525, lng: 21.586557 },
    { lat: 49.186459, lng: 21.586456 },
    { lat: 49.186299, lng: 21.5863 },
    { lat: 49.186269, lng: 21.586295 },
    { lat: 49.186129, lng: 21.58624 },
    { lat: 49.186091, lng: 21.586222 },
    { lat: 49.185943, lng: 21.586105 },
    { lat: 49.185893, lng: 21.586042 },
    { lat: 49.185846, lng: 21.58605 },
    { lat: 49.185769, lng: 21.586151 },
    { lat: 49.185663, lng: 21.586189 },
    { lat: 49.185609, lng: 21.586235 },
    { lat: 49.185557, lng: 21.586258 },
    { lat: 49.185458, lng: 21.586341 },
    { lat: 49.185395, lng: 21.586374 },
    { lat: 49.185224, lng: 21.586497 },
    { lat: 49.185154, lng: 21.586548 },
    { lat: 49.185074, lng: 21.58668 },
    { lat: 49.185006, lng: 21.586797 },
    { lat: 49.184918, lng: 21.586927 },
    { lat: 49.184802, lng: 21.587021 },
    { lat: 49.184794, lng: 21.587007 },
    { lat: 49.184661, lng: 21.586651 },
    { lat: 49.184445, lng: 21.585934 },
    { lat: 49.184331, lng: 21.58589 },
    { lat: 49.184278, lng: 21.585933 },
    { lat: 49.184254, lng: 21.585969 },
    { lat: 49.18412, lng: 21.586427 },
    { lat: 49.184016, lng: 21.586618 },
    { lat: 49.183959, lng: 21.586976 },
    { lat: 49.183863, lng: 21.587292 },
    { lat: 49.183847, lng: 21.58798 },
    { lat: 49.183855, lng: 21.588172 },
    { lat: 49.183857, lng: 21.588366 },
    { lat: 49.183848, lng: 21.588453 },
    { lat: 49.183822, lng: 21.588362 },
    { lat: 49.183725, lng: 21.588282 },
    { lat: 49.183573, lng: 21.588309 },
    { lat: 49.18329, lng: 21.588695 },
    { lat: 49.183207, lng: 21.58929 },
    { lat: 49.1830032, lng: 21.5895855 },
    { lat: 49.1826388, lng: 21.5896637 },
    { lat: 49.1825008, lng: 21.5907708 },
    { lat: 49.1823622, lng: 21.590893 },
    { lat: 49.1822155, lng: 21.5911942 },
    { lat: 49.1821212, lng: 21.5920957 },
    { lat: 49.1822806, lng: 21.5923256 },
    { lat: 49.1824413, lng: 21.5926892 },
    { lat: 49.1824959, lng: 21.5930356 },
    { lat: 49.1824332, lng: 21.5939355 },
    { lat: 49.182209, lng: 21.5945728 },
    { lat: 49.1818556, lng: 21.5949296 },
    { lat: 49.1814803, lng: 21.5956043 },
    { lat: 49.1813442, lng: 21.5959648 },
    { lat: 49.1813105, lng: 21.5963341 },
    { lat: 49.1811691, lng: 21.5965454 },
    { lat: 49.1809453, lng: 21.5973613 },
    { lat: 49.1807522, lng: 21.5977873 },
    { lat: 49.1807109, lng: 21.5979957 },
    { lat: 49.1801837, lng: 21.5987876 },
    { lat: 49.1796626, lng: 21.5992264 },
    { lat: 49.1796692, lng: 21.5997097 },
    { lat: 49.1795487, lng: 21.5999059 },
    { lat: 49.1793782, lng: 21.60049 },
    { lat: 49.1790423, lng: 21.6008752 },
    { lat: 49.1783302, lng: 21.6033604 },
    { lat: 49.178218, lng: 21.6039836 },
    { lat: 49.1779949, lng: 21.604739 },
    { lat: 49.1779559, lng: 21.6052046 },
    { lat: 49.1782563, lng: 21.6056503 },
    { lat: 49.1785241, lng: 21.6061804 },
    { lat: 49.1787597, lng: 21.6068154 },
    { lat: 49.1785512, lng: 21.6072473 },
    { lat: 49.1784168, lng: 21.6079219 },
    { lat: 49.1781333, lng: 21.6088218 },
    { lat: 49.1778747, lng: 21.6093957 },
    { lat: 49.17741, lng: 21.6097599 },
    { lat: 49.1771389, lng: 21.6103746 },
    { lat: 49.1766917, lng: 21.6110829 },
    { lat: 49.1761673, lng: 21.6114034 },
    { lat: 49.1756997, lng: 21.6114161 },
    { lat: 49.1748255, lng: 21.6119029 },
    { lat: 49.1742888, lng: 21.6125105 },
    { lat: 49.1742096, lng: 21.6129068 },
    { lat: 49.1741759, lng: 21.6133929 },
    { lat: 49.1738633, lng: 21.6143017 },
    { lat: 49.1737079, lng: 21.6151113 },
    { lat: 49.1732121, lng: 21.6167628 },
    { lat: 49.1728144, lng: 21.617516 },
    { lat: 49.172684, lng: 21.6180115 },
    { lat: 49.1725983, lng: 21.6184085 },
    { lat: 49.1723253, lng: 21.6214116 },
    { lat: 49.1722045, lng: 21.6217659 },
    { lat: 49.1717451, lng: 21.6220768 },
    { lat: 49.1716195, lng: 21.6226615 },
    { lat: 49.1716479, lng: 21.6227179 },
    { lat: 49.1715699, lng: 21.6228225 },
    { lat: 49.1715366, lng: 21.6230853 },
    { lat: 49.1716309, lng: 21.6233262 },
    { lat: 49.1715969, lng: 21.6234794 },
    { lat: 49.1716505, lng: 21.6237916 },
    { lat: 49.1717157, lng: 21.6238734 },
    { lat: 49.1717352, lng: 21.6240951 },
    { lat: 49.172054, lng: 21.6247233 },
    { lat: 49.1721905, lng: 21.6252672 },
    { lat: 49.1722111, lng: 21.625853 },
    { lat: 49.172088, lng: 21.6260424 },
    { lat: 49.1717614, lng: 21.6269319 },
    { lat: 49.1711139, lng: 21.6280162 },
    { lat: 49.1708328, lng: 21.6286723 },
    { lat: 49.170725, lng: 21.6292558 },
    { lat: 49.1707029, lng: 21.6298512 },
    { lat: 49.1701839, lng: 21.6310236 },
    { lat: 49.1699928, lng: 21.6319313 },
    { lat: 49.1698417, lng: 21.6320851 },
    { lat: 49.1696895, lng: 21.6325618 },
    { lat: 49.169728, lng: 21.6326518 },
    { lat: 49.1696003, lng: 21.6331697 },
    { lat: 49.1695681, lng: 21.6338818 },
    { lat: 49.1693654, lng: 21.6341658 },
    { lat: 49.169544, lng: 21.634602 },
    { lat: 49.1695639, lng: 21.6347941 },
    { lat: 49.1699301, lng: 21.6353939 },
    { lat: 49.1699578, lng: 21.6357063 },
    { lat: 49.1701142, lng: 21.6359516 },
    { lat: 49.1702619, lng: 21.6364247 },
    { lat: 49.1705769, lng: 21.6367541 },
    { lat: 49.1707209, lng: 21.637167 },
    { lat: 49.1710299, lng: 21.6373516 },
    { lat: 49.1711466, lng: 21.6375513 },
    { lat: 49.1714024, lng: 21.6377701 },
    { lat: 49.1717786, lng: 21.6378282 },
    { lat: 49.1723964, lng: 21.638042 },
    { lat: 49.1729985, lng: 21.6385002 },
    { lat: 49.1731758, lng: 21.6388204 },
    { lat: 49.1732271, lng: 21.6389642 },
    { lat: 49.1732082, lng: 21.6392621 },
    { lat: 49.1733172, lng: 21.6394665 },
    { lat: 49.1732984, lng: 21.6395544 },
    { lat: 49.1734223, lng: 21.64018 },
    { lat: 49.1736901, lng: 21.640639 },
    { lat: 49.17381, lng: 21.6407375 },
    { lat: 49.1738646, lng: 21.6412197 },
    { lat: 49.1739444, lng: 21.6413772 },
    { lat: 49.1739735, lng: 21.6415814 },
    { lat: 49.173921, lng: 21.6418418 },
    { lat: 49.1739095, lng: 21.6426112 },
    { lat: 49.17423, lng: 21.643681 },
    { lat: 49.1744493, lng: 21.6449622 },
    { lat: 49.174537, lng: 21.6449178 },
    { lat: 49.1747604, lng: 21.6449841 },
    { lat: 49.1748861, lng: 21.6438554 },
    { lat: 49.1752868, lng: 21.6436189 },
    { lat: 49.1752124, lng: 21.6425788 },
    { lat: 49.1775992, lng: 21.6415405 },
    { lat: 49.1778087, lng: 21.6425768 },
    { lat: 49.1787283, lng: 21.6423515 },
    { lat: 49.1794331, lng: 21.6421241 },
    { lat: 49.1838482, lng: 21.6426456 },
    { lat: 49.1852412, lng: 21.6431915 },
    { lat: 49.1853698, lng: 21.6440338 },
    { lat: 49.1868148, lng: 21.6450001 },
    { lat: 49.1873689, lng: 21.6451876 },
    { lat: 49.1880695, lng: 21.6451787 },
    { lat: 49.1882895, lng: 21.6449993 },
    { lat: 49.1891377, lng: 21.6448707 },
    { lat: 49.1900139, lng: 21.6444989 },
    { lat: 49.195135, lng: 21.6427396 },
    { lat: 49.1958184, lng: 21.6418934 },
    { lat: 49.1956367, lng: 21.6409183 },
    { lat: 49.1958931, lng: 21.6406311 },
    { lat: 49.1958819, lng: 21.6404714 },
  ],
  Vislava: [
    { lat: 49.2676719, lng: 21.6810165 },
    { lat: 49.267923, lng: 21.6811487 },
    { lat: 49.268375, lng: 21.6809809 },
    { lat: 49.2687015, lng: 21.6809917 },
    { lat: 49.269334, lng: 21.6813781 },
    { lat: 49.2696125, lng: 21.6814002 },
    { lat: 49.2701858, lng: 21.6817608 },
    { lat: 49.271008, lng: 21.6817603 },
    { lat: 49.2713457, lng: 21.6820229 },
    { lat: 49.2722332, lng: 21.6823105 },
    { lat: 49.272409, lng: 21.6824918 },
    { lat: 49.2728508, lng: 21.6833531 },
    { lat: 49.2735475, lng: 21.6844668 },
    { lat: 49.2741868, lng: 21.68481 },
    { lat: 49.2749616, lng: 21.6848424 },
    { lat: 49.2752228, lng: 21.6850212 },
    { lat: 49.2754078, lng: 21.6851227 },
    { lat: 49.2754317, lng: 21.6850928 },
    { lat: 49.275595, lng: 21.6853459 },
    { lat: 49.2761351, lng: 21.6848584 },
    { lat: 49.2767578, lng: 21.6846265 },
    { lat: 49.2773981, lng: 21.6845078 },
    { lat: 49.2779724, lng: 21.6842833 },
    { lat: 49.2786116, lng: 21.6841653 },
    { lat: 49.2791709, lng: 21.684172 },
    { lat: 49.2809894, lng: 21.6839954 },
    { lat: 49.2818917, lng: 21.683786 },
    { lat: 49.2824822, lng: 21.6837421 },
    { lat: 49.2831096, lng: 21.6835639 },
    { lat: 49.2838785, lng: 21.6835477 },
    { lat: 49.2860917, lng: 21.6827762 },
    { lat: 49.2862274, lng: 21.6825461 },
    { lat: 49.2864825, lng: 21.6823283 },
    { lat: 49.2869955, lng: 21.6817094 },
    { lat: 49.2879964, lng: 21.6803186 },
    { lat: 49.2884139, lng: 21.6799096 },
    { lat: 49.2888517, lng: 21.6786172 },
    { lat: 49.2897551, lng: 21.6766155 },
    { lat: 49.2910614, lng: 21.6743764 },
    { lat: 49.2913425, lng: 21.673541 },
    { lat: 49.2921383, lng: 21.6720118 },
    { lat: 49.2937878, lng: 21.6711964 },
    { lat: 49.2941484, lng: 21.6708482 },
    { lat: 49.2949578, lng: 21.6697022 },
    { lat: 49.2951601, lng: 21.6692743 },
    { lat: 49.2954557, lng: 21.6682292 },
    { lat: 49.2959463, lng: 21.6670269 },
    { lat: 49.2961256, lng: 21.6667888 },
    { lat: 49.29628, lng: 21.6667171 },
    { lat: 49.2966828, lng: 21.665845 },
    { lat: 49.2970618, lng: 21.6652588 },
    { lat: 49.2975614, lng: 21.6651407 },
    { lat: 49.2981406, lng: 21.6647242 },
    { lat: 49.298428, lng: 21.6644123 },
    { lat: 49.298789, lng: 21.663832 },
    { lat: 49.299291, lng: 21.662516 },
    { lat: 49.298927, lng: 21.662177 },
    { lat: 49.299071, lng: 21.660167 },
    { lat: 49.298981, lng: 21.65923 },
    { lat: 49.298815, lng: 21.658553 },
    { lat: 49.298781, lng: 21.657949 },
    { lat: 49.298726, lng: 21.65739 },
    { lat: 49.2982, lng: 21.655348 },
    { lat: 49.298022, lng: 21.654739 },
    { lat: 49.297424, lng: 21.652063 },
    { lat: 49.297421, lng: 21.652023 },
    { lat: 49.297411, lng: 21.651958 },
    { lat: 49.297361, lng: 21.651854 },
    { lat: 49.297082, lng: 21.651072 },
    { lat: 49.296557, lng: 21.649742 },
    { lat: 49.296433, lng: 21.648493 },
    { lat: 49.296678, lng: 21.647212 },
    { lat: 49.29702, lng: 21.645753 },
    { lat: 49.297193, lng: 21.645183 },
    { lat: 49.297449, lng: 21.644747 },
    { lat: 49.298146, lng: 21.643781 },
    { lat: 49.298684, lng: 21.642716 },
    { lat: 49.299309, lng: 21.641668 },
    { lat: 49.299569, lng: 21.641238 },
    { lat: 49.300345, lng: 21.639455 },
    { lat: 49.300686, lng: 21.638606 },
    { lat: 49.300441, lng: 21.638236 },
    { lat: 49.300364, lng: 21.637847 },
    { lat: 49.299919, lng: 21.63595 },
    { lat: 49.299288, lng: 21.633239 },
    { lat: 49.299023, lng: 21.633033 },
    { lat: 49.298341, lng: 21.6325 },
    { lat: 49.297697, lng: 21.631425 },
    { lat: 49.297211, lng: 21.631033 },
    { lat: 49.297345, lng: 21.630922 },
    { lat: 49.297367, lng: 21.630885 },
    { lat: 49.297372, lng: 21.630863 },
    { lat: 49.297058, lng: 21.630751 },
    { lat: 49.296842, lng: 21.630572 },
    { lat: 49.296641, lng: 21.630543 },
    { lat: 49.296242, lng: 21.630591 },
    { lat: 49.296092, lng: 21.630549 },
    { lat: 49.295856, lng: 21.630668 },
    { lat: 49.29544, lng: 21.630542 },
    { lat: 49.295292, lng: 21.630414 },
    { lat: 49.295186, lng: 21.630399 },
    { lat: 49.29442, lng: 21.630931 },
    { lat: 49.294162, lng: 21.631034 },
    { lat: 49.293883, lng: 21.631166 },
    { lat: 49.293583, lng: 21.631274 },
    { lat: 49.293036, lng: 21.631471 },
    { lat: 49.292493, lng: 21.631703 },
    { lat: 49.292117, lng: 21.631829 },
    { lat: 49.291818, lng: 21.631872 },
    { lat: 49.291696, lng: 21.631893 },
    { lat: 49.291472, lng: 21.631952 },
    { lat: 49.291318, lng: 21.632028 },
    { lat: 49.29133, lng: 21.632048 },
    { lat: 49.290979, lng: 21.632185 },
    { lat: 49.289745, lng: 21.633397 },
    { lat: 49.288911, lng: 21.633826 },
    { lat: 49.287886, lng: 21.634514 },
    { lat: 49.286637, lng: 21.635308 },
    { lat: 49.285382, lng: 21.636147 },
    { lat: 49.284916, lng: 21.636458 },
    { lat: 49.284102, lng: 21.635591 },
    { lat: 49.281768, lng: 21.636229 },
    { lat: 49.281414, lng: 21.636607 },
    { lat: 49.2811125, lng: 21.6371167 },
    { lat: 49.2808023, lng: 21.6373336 },
    { lat: 49.2804872, lng: 21.6378206 },
    { lat: 49.2782514, lng: 21.6403976 },
    { lat: 49.2778511, lng: 21.6409401 },
    { lat: 49.2772166, lng: 21.6414722 },
    { lat: 49.2770574, lng: 21.6415389 },
    { lat: 49.2760116, lng: 21.6424143 },
    { lat: 49.2756741, lng: 21.6427896 },
    { lat: 49.2748707, lng: 21.6434009 },
    { lat: 49.2745619, lng: 21.643912 },
    { lat: 49.274247, lng: 21.6440869 },
    { lat: 49.2737923, lng: 21.6446144 },
    { lat: 49.2730685, lng: 21.6450524 },
    { lat: 49.2722931, lng: 21.6451597 },
    { lat: 49.2717096, lng: 21.6458758 },
    { lat: 49.2714787, lng: 21.6463116 },
    { lat: 49.2712487, lng: 21.646582 },
    { lat: 49.2707273, lng: 21.6464448 },
    { lat: 49.2701478, lng: 21.6465191 },
    { lat: 49.2699611, lng: 21.6463976 },
    { lat: 49.269658, lng: 21.6460455 },
    { lat: 49.2695811, lng: 21.6460345 },
    { lat: 49.2692796, lng: 21.6461103 },
    { lat: 49.2680271, lng: 21.646098 },
    { lat: 49.2677252, lng: 21.6461622 },
    { lat: 49.2672291, lng: 21.6463648 },
    { lat: 49.2667469, lng: 21.6467161 },
    { lat: 49.2661619, lng: 21.6481973 },
    { lat: 49.2664216, lng: 21.6497657 },
    { lat: 49.2664283, lng: 21.6502209 },
    { lat: 49.2665547, lng: 21.6507773 },
    { lat: 49.2665529, lng: 21.6511203 },
    { lat: 49.2670511, lng: 21.6532334 },
    { lat: 49.265497, lng: 21.6593396 },
    { lat: 49.2655582, lng: 21.6606425 },
    { lat: 49.2652015, lng: 21.6616409 },
    { lat: 49.2648333, lng: 21.6620834 },
    { lat: 49.2645257, lng: 21.662235 },
    { lat: 49.2642064, lng: 21.6626532 },
    { lat: 49.2642209, lng: 21.6635229 },
    { lat: 49.2641012, lng: 21.6647525 },
    { lat: 49.2640682, lng: 21.6678122 },
    { lat: 49.2646006, lng: 21.6715318 },
    { lat: 49.2650837, lng: 21.6744275 },
    { lat: 49.2657016, lng: 21.6770277 },
    { lat: 49.2661725, lng: 21.6785116 },
    { lat: 49.2672723, lng: 21.6805946 },
    { lat: 49.2676719, lng: 21.6810165 },
  ],
  Veľkrop: [
    { lat: 49.2232945, lng: 21.7672894 },
    { lat: 49.2234564, lng: 21.7673363 },
    { lat: 49.2236637, lng: 21.7675529 },
    { lat: 49.2237262, lng: 21.7677096 },
    { lat: 49.2239773, lng: 21.7677673 },
    { lat: 49.2240398, lng: 21.7680921 },
    { lat: 49.2243495, lng: 21.7686587 },
    { lat: 49.2243519, lng: 21.7688824 },
    { lat: 49.2244644, lng: 21.7692278 },
    { lat: 49.2245933, lng: 21.7692901 },
    { lat: 49.2246286, lng: 21.7695208 },
    { lat: 49.2248029, lng: 21.7697405 },
    { lat: 49.2248781, lng: 21.7702002 },
    { lat: 49.2252099, lng: 21.7704142 },
    { lat: 49.2254384, lng: 21.7710196 },
    { lat: 49.2256013, lng: 21.7712288 },
    { lat: 49.2258053, lng: 21.7724512 },
    { lat: 49.2258716, lng: 21.7725321 },
    { lat: 49.2259085, lng: 21.7727004 },
    { lat: 49.2259339, lng: 21.7730677 },
    { lat: 49.2260912, lng: 21.7731082 },
    { lat: 49.2261362, lng: 21.7732328 },
    { lat: 49.2263116, lng: 21.773315 },
    { lat: 49.2264194, lng: 21.7734786 },
    { lat: 49.2265915, lng: 21.7735703 },
    { lat: 49.2267763, lng: 21.7738787 },
    { lat: 49.2275336, lng: 21.7747056 },
    { lat: 49.228031, lng: 21.7770802 },
    { lat: 49.2281192, lng: 21.7784356 },
    { lat: 49.2283955, lng: 21.7790252 },
    { lat: 49.2285166, lng: 21.7795732 },
    { lat: 49.2286087, lng: 21.7805109 },
    { lat: 49.2296719, lng: 21.7794071 },
    { lat: 49.2300975, lng: 21.7792038 },
    { lat: 49.2303233, lng: 21.7793661 },
    { lat: 49.2314758, lng: 21.779261 },
    { lat: 49.2325847, lng: 21.7792939 },
    { lat: 49.2332228, lng: 21.7789146 },
    { lat: 49.2335863, lng: 21.7790149 },
    { lat: 49.2337468, lng: 21.7771013 },
    { lat: 49.2338258, lng: 21.7770314 },
    { lat: 49.2341543, lng: 21.7769811 },
    { lat: 49.2346039, lng: 21.7769779 },
    { lat: 49.2349741, lng: 21.7773592 },
    { lat: 49.2355882, lng: 21.7778329 },
    { lat: 49.2364218, lng: 21.7778812 },
    { lat: 49.2375988, lng: 21.7785906 },
    { lat: 49.2380607, lng: 21.7784416 },
    { lat: 49.2382138, lng: 21.7782321 },
    { lat: 49.2383902, lng: 21.7780997 },
    { lat: 49.2391389, lng: 21.7782096 },
    { lat: 49.2397992, lng: 21.778078 },
    { lat: 49.2404465, lng: 21.7784399 },
    { lat: 49.2409132, lng: 21.7783971 },
    { lat: 49.241139, lng: 21.7787308 },
    { lat: 49.2418331, lng: 21.7786435 },
    { lat: 49.2420264, lng: 21.7785543 },
    { lat: 49.2425559, lng: 21.778152 },
    { lat: 49.2429193, lng: 21.778024 },
    { lat: 49.2441968, lng: 21.7767654 },
    { lat: 49.2447573, lng: 21.7763057 },
    { lat: 49.2450884, lng: 21.7758416 },
    { lat: 49.2452238, lng: 21.7757736 },
    { lat: 49.2455515, lng: 21.7753637 },
    { lat: 49.2457645, lng: 21.775288 },
    { lat: 49.2462374, lng: 21.7749186 },
    { lat: 49.2471439, lng: 21.7749984 },
    { lat: 49.2472462, lng: 21.7748733 },
    { lat: 49.2475297, lng: 21.7747437 },
    { lat: 49.2478784, lng: 21.7744405 },
    { lat: 49.2482486, lng: 21.7740122 },
    { lat: 49.2487505, lng: 21.7737198 },
    { lat: 49.2491803, lng: 21.7730822 },
    { lat: 49.249353, lng: 21.7725842 },
    { lat: 49.2494304, lng: 21.7721438 },
    { lat: 49.2493066, lng: 21.7719702 },
    { lat: 49.2492759, lng: 21.7710133 },
    { lat: 49.2496957, lng: 21.7703381 },
    { lat: 49.2500887, lng: 21.7697448 },
    { lat: 49.2497595, lng: 21.7689919 },
    { lat: 49.2497614, lng: 21.7685892 },
    { lat: 49.2498754, lng: 21.7676576 },
    { lat: 49.2497864, lng: 21.7659773 },
    { lat: 49.250096, lng: 21.7658215 },
    { lat: 49.2502033, lng: 21.7654084 },
    { lat: 49.2502114, lng: 21.7644657 },
    { lat: 49.2501688, lng: 21.7642187 },
    { lat: 49.2501584, lng: 21.7634805 },
    { lat: 49.2502185, lng: 21.763155 },
    { lat: 49.2502165, lng: 21.7627741 },
    { lat: 49.2500727, lng: 21.7611565 },
    { lat: 49.2499021, lng: 21.760781 },
    { lat: 49.2498159, lng: 21.7604143 },
    { lat: 49.2496245, lng: 21.7584745 },
    { lat: 49.2493264, lng: 21.7573208 },
    { lat: 49.2491422, lng: 21.7567783 },
    { lat: 49.2486716, lng: 21.7549623 },
    { lat: 49.2482469, lng: 21.7543374 },
    { lat: 49.248393, lng: 21.7541667 },
    { lat: 49.2479929, lng: 21.7534075 },
    { lat: 49.2484283, lng: 21.7524709 },
    { lat: 49.2482005, lng: 21.7520611 },
    { lat: 49.2488851, lng: 21.7496571 },
    { lat: 49.2489518, lng: 21.747956 },
    { lat: 49.2490503, lng: 21.7473411 },
    { lat: 49.2489457, lng: 21.7467851 },
    { lat: 49.2490665, lng: 21.745058 },
    { lat: 49.249184, lng: 21.7448996 },
    { lat: 49.2492686, lng: 21.7438441 },
    { lat: 49.2490631, lng: 21.7427488 },
    { lat: 49.249399, lng: 21.74001 },
    { lat: 49.2492153, lng: 21.7393995 },
    { lat: 49.2483512, lng: 21.7374729 },
    { lat: 49.2478693, lng: 21.7361416 },
    { lat: 49.2463787, lng: 21.7340082 },
    { lat: 49.2461108, lng: 21.7334509 },
    { lat: 49.2459849, lng: 21.733495 },
    { lat: 49.2443191, lng: 21.7319267 },
    { lat: 49.2441003, lng: 21.7318261 },
    { lat: 49.2432203, lng: 21.7311759 },
    { lat: 49.242636, lng: 21.7309881 },
    { lat: 49.2423377, lng: 21.7304259 },
    { lat: 49.2415644, lng: 21.7293861 },
    { lat: 49.2401736, lng: 21.728476 },
    { lat: 49.2397056, lng: 21.7278429 },
    { lat: 49.2394248, lng: 21.7275997 },
    { lat: 49.2386472, lng: 21.7265337 },
    { lat: 49.2382994, lng: 21.726264 },
    { lat: 49.2380213, lng: 21.7261764 },
    { lat: 49.2377681, lng: 21.7259639 },
    { lat: 49.2369568, lng: 21.7257333 },
    { lat: 49.2366247, lng: 21.7242884 },
    { lat: 49.2366006, lng: 21.7242658 },
    { lat: 49.2365884, lng: 21.7224138 },
    { lat: 49.2366795, lng: 21.7223653 },
    { lat: 49.2365784, lng: 21.7219947 },
    { lat: 49.2365577, lng: 21.7220572 },
    { lat: 49.2362779, lng: 21.7213484 },
    { lat: 49.2357799, lng: 21.7205183 },
    { lat: 49.2357213, lng: 21.720302 },
    { lat: 49.2354426, lng: 21.7200586 },
    { lat: 49.2352681, lng: 21.7190375 },
    { lat: 49.2353412, lng: 21.718746 },
    { lat: 49.2352819, lng: 21.7184196 },
    { lat: 49.2353023, lng: 21.7182146 },
    { lat: 49.2350873, lng: 21.7178212 },
    { lat: 49.2348406, lng: 21.7178081 },
    { lat: 49.2347195, lng: 21.7176597 },
    { lat: 49.2347817, lng: 21.7172088 },
    { lat: 49.2342176, lng: 21.7166409 },
    { lat: 49.2335981, lng: 21.7163126 },
    { lat: 49.2333988, lng: 21.7178198 },
    { lat: 49.232859, lng: 21.7188018 },
    { lat: 49.2314181, lng: 21.7218078 },
    { lat: 49.2307107, lng: 21.723502 },
    { lat: 49.2298033, lng: 21.7238166 },
    { lat: 49.2284706, lng: 21.7253547 },
    { lat: 49.2283744, lng: 21.7251605 },
    { lat: 49.2277876, lng: 21.7256417 },
    { lat: 49.2276409, lng: 21.7256206 },
    { lat: 49.2274935, lng: 21.7257438 },
    { lat: 49.22742, lng: 21.7259503 },
    { lat: 49.2272463, lng: 21.7255882 },
    { lat: 49.2266436, lng: 21.7252577 },
    { lat: 49.2264773, lng: 21.725395 },
    { lat: 49.2259074, lng: 21.7252835 },
    { lat: 49.2253234, lng: 21.7258202 },
    { lat: 49.2252317, lng: 21.7258036 },
    { lat: 49.2251057, lng: 21.7261007 },
    { lat: 49.2250261, lng: 21.7261463 },
    { lat: 49.2249197, lng: 21.7263386 },
    { lat: 49.2248947, lng: 21.7264964 },
    { lat: 49.224681, lng: 21.7266266 },
    { lat: 49.224575, lng: 21.7265737 },
    { lat: 49.2244593, lng: 21.7266219 },
    { lat: 49.2245717, lng: 21.727429 },
    { lat: 49.2246991, lng: 21.7277803 },
    { lat: 49.2246529, lng: 21.7279034 },
    { lat: 49.2247075, lng: 21.728221 },
    { lat: 49.2245401, lng: 21.7291592 },
    { lat: 49.2244966, lng: 21.7292616 },
    { lat: 49.2239139, lng: 21.7295535 },
    { lat: 49.2238864, lng: 21.729621 },
    { lat: 49.2240601, lng: 21.7302332 },
    { lat: 49.2240459, lng: 21.730372 },
    { lat: 49.2238039, lng: 21.7312992 },
    { lat: 49.2234937, lng: 21.7321645 },
    { lat: 49.2230713, lng: 21.7329909 },
    { lat: 49.2230222, lng: 21.7336004 },
    { lat: 49.2229157, lng: 21.7341201 },
    { lat: 49.2229562, lng: 21.7344638 },
    { lat: 49.2230477, lng: 21.7345996 },
    { lat: 49.2236606, lng: 21.7366415 },
    { lat: 49.2237265, lng: 21.7371265 },
    { lat: 49.2247211, lng: 21.7364206 },
    { lat: 49.2246345, lng: 21.7370557 },
    { lat: 49.2245865, lng: 21.7380052 },
    { lat: 49.2246015, lng: 21.7390814 },
    { lat: 49.224434, lng: 21.7404492 },
    { lat: 49.2243091, lng: 21.7410388 },
    { lat: 49.2243084, lng: 21.7424997 },
    { lat: 49.2240175, lng: 21.7443922 },
    { lat: 49.223922, lng: 21.7464004 },
    { lat: 49.2243878, lng: 21.7483681 },
    { lat: 49.2248647, lng: 21.7496952 },
    { lat: 49.2252054, lng: 21.7509223 },
    { lat: 49.2252907, lng: 21.7513867 },
    { lat: 49.2256009, lng: 21.7520594 },
    { lat: 49.2244465, lng: 21.7544018 },
    { lat: 49.2233669, lng: 21.7561195 },
    { lat: 49.2229024, lng: 21.7564647 },
    { lat: 49.2225271, lng: 21.7574219 },
    { lat: 49.2220756, lng: 21.7574104 },
    { lat: 49.2218215, lng: 21.7576703 },
    { lat: 49.2215917, lng: 21.7580377 },
    { lat: 49.2214971, lng: 21.7586377 },
    { lat: 49.2209036, lng: 21.7604517 },
    { lat: 49.2206203, lng: 21.7609732 },
    { lat: 49.220158, lng: 21.7613092 },
    { lat: 49.2202415, lng: 21.762434 },
    { lat: 49.2200223, lng: 21.762831 },
    { lat: 49.220282, lng: 21.7632502 },
    { lat: 49.2203133, lng: 21.763376 },
    { lat: 49.2201189, lng: 21.7636826 },
    { lat: 49.2200044, lng: 21.7642071 },
    { lat: 49.2202737, lng: 21.7645271 },
    { lat: 49.2204167, lng: 21.7649196 },
    { lat: 49.2204912, lng: 21.7654861 },
    { lat: 49.2208269, lng: 21.7655408 },
    { lat: 49.220876, lng: 21.7656148 },
    { lat: 49.2208898, lng: 21.7658004 },
    { lat: 49.2209576, lng: 21.765897 },
    { lat: 49.2210931, lng: 21.7658326 },
    { lat: 49.2212786, lng: 21.7658651 },
    { lat: 49.2219131, lng: 21.766297 },
    { lat: 49.2219521, lng: 21.7664541 },
    { lat: 49.2221849, lng: 21.7664614 },
    { lat: 49.2224923, lng: 21.7665942 },
    { lat: 49.2226429, lng: 21.7668099 },
    { lat: 49.2229646, lng: 21.76672 },
    { lat: 49.2232113, lng: 21.7671663 },
    { lat: 49.2232945, lng: 21.7672894 },
  ],
  Oľšavka: [
    { lat: 49.2854993, lng: 21.7071826 },
    { lat: 49.2874164, lng: 21.7079534 },
    { lat: 49.2885629, lng: 21.7082163 },
    { lat: 49.2900617, lng: 21.7074378 },
    { lat: 49.2912521, lng: 21.7070677 },
    { lat: 49.2935733, lng: 21.7049437 },
    { lat: 49.2938971, lng: 21.707044 },
    { lat: 49.2944382, lng: 21.7066694 },
    { lat: 49.295018, lng: 21.7064717 },
    { lat: 49.2957167, lng: 21.7060151 },
    { lat: 49.2964338, lng: 21.7057884 },
    { lat: 49.2966334, lng: 21.7055136 },
    { lat: 49.2970243, lng: 21.705154 },
    { lat: 49.2973934, lng: 21.7049482 },
    { lat: 49.2978175, lng: 21.7044838 },
    { lat: 49.2983071, lng: 21.7038207 },
    { lat: 49.2993065, lng: 21.7033806 },
    { lat: 49.2995786, lng: 21.703329 },
    { lat: 49.3001949, lng: 21.7034066 },
    { lat: 49.3013496, lng: 21.7021688 },
    { lat: 49.3016011, lng: 21.7019826 },
    { lat: 49.3021636, lng: 21.7018974 },
    { lat: 49.3024127, lng: 21.7014575 },
    { lat: 49.3027801, lng: 21.701569 },
    { lat: 49.3029921, lng: 21.7017115 },
    { lat: 49.3033523, lng: 21.7020999 },
    { lat: 49.303413, lng: 21.7023102 },
    { lat: 49.3034673, lng: 21.7023209 },
    { lat: 49.3035929, lng: 21.702268 },
    { lat: 49.3036694, lng: 21.7020599 },
    { lat: 49.3038072, lng: 21.7019947 },
    { lat: 49.3038906, lng: 21.7021697 },
    { lat: 49.3040608, lng: 21.7021775 },
    { lat: 49.3040963, lng: 21.7018224 },
    { lat: 49.3041644, lng: 21.7018466 },
    { lat: 49.3043793, lng: 21.7017221 },
    { lat: 49.3044282, lng: 21.7015774 },
    { lat: 49.3045628, lng: 21.7016458 },
    { lat: 49.3045404, lng: 21.7013988 },
    { lat: 49.3046624, lng: 21.7012016 },
    { lat: 49.3048994, lng: 21.701322 },
    { lat: 49.3050242, lng: 21.7012284 },
    { lat: 49.3052776, lng: 21.7014144 },
    { lat: 49.3055249, lng: 21.7013607 },
    { lat: 49.3056192, lng: 21.7012312 },
    { lat: 49.3057821, lng: 21.7012657 },
    { lat: 49.3059707, lng: 21.7011267 },
    { lat: 49.3060702, lng: 21.7011464 },
    { lat: 49.3061283, lng: 21.700875 },
    { lat: 49.3062793, lng: 21.70089 },
    { lat: 49.3064621, lng: 21.7006122 },
    { lat: 49.306777, lng: 21.7004894 },
    { lat: 49.3069456, lng: 21.7005603 },
    { lat: 49.3073483, lng: 21.7001862 },
    { lat: 49.3080827, lng: 21.6992585 },
    { lat: 49.3084131, lng: 21.6989891 },
    { lat: 49.3090483, lng: 21.6988557 },
    { lat: 49.3093229, lng: 21.6987325 },
    { lat: 49.3095618, lng: 21.6985341 },
    { lat: 49.309602, lng: 21.6983556 },
    { lat: 49.3098597, lng: 21.6981877 },
    { lat: 49.3101391, lng: 21.6975423 },
    { lat: 49.3101247, lng: 21.6973813 },
    { lat: 49.3102074, lng: 21.6970618 },
    { lat: 49.3103644, lng: 21.6967455 },
    { lat: 49.3105299, lng: 21.6965441 },
    { lat: 49.3106966, lng: 21.6965116 },
    { lat: 49.3108818, lng: 21.6962895 },
    { lat: 49.3116253, lng: 21.6965829 },
    { lat: 49.3117827, lng: 21.6967014 },
    { lat: 49.3120121, lng: 21.6966168 },
    { lat: 49.3121398, lng: 21.6963045 },
    { lat: 49.3123358, lng: 21.6956837 },
    { lat: 49.3129848, lng: 21.6945427 },
    { lat: 49.3135687, lng: 21.6937072 },
    { lat: 49.3142389, lng: 21.6933681 },
    { lat: 49.3144786, lng: 21.6930766 },
    { lat: 49.3147957, lng: 21.6930271 },
    { lat: 49.3154116, lng: 21.6927635 },
    { lat: 49.3164225, lng: 21.6917375 },
    { lat: 49.317601, lng: 21.691166 },
    { lat: 49.317581, lng: 21.691165 },
    { lat: 49.317283, lng: 21.691023 },
    { lat: 49.316978, lng: 21.690767 },
    { lat: 49.31684, lng: 21.690528 },
    { lat: 49.316652, lng: 21.690201 },
    { lat: 49.316268, lng: 21.689218 },
    { lat: 49.31609, lng: 21.688937 },
    { lat: 49.315647, lng: 21.688588 },
    { lat: 49.315403, lng: 21.688164 },
    { lat: 49.315376, lng: 21.688121 },
    { lat: 49.315265, lng: 21.687988 },
    { lat: 49.314964, lng: 21.687785 },
    { lat: 49.314971, lng: 21.687758 },
    { lat: 49.314102, lng: 21.686985 },
    { lat: 49.313744, lng: 21.686245 },
    { lat: 49.313733, lng: 21.686205 },
    { lat: 49.311876, lng: 21.681326 },
    { lat: 49.311799, lng: 21.681155 },
    { lat: 49.311831, lng: 21.681137 },
    { lat: 49.311542, lng: 21.679236 },
    { lat: 49.311169, lng: 21.678367 },
    { lat: 49.310539, lng: 21.677371 },
    { lat: 49.309957, lng: 21.675749 },
    { lat: 49.309949, lng: 21.675695 },
    { lat: 49.309864, lng: 21.675378 },
    { lat: 49.309426, lng: 21.673666 },
    { lat: 49.309247, lng: 21.672336 },
    { lat: 49.308545, lng: 21.67052 },
    { lat: 49.30853, lng: 21.670478 },
    { lat: 49.30793, lng: 21.669625 },
    { lat: 49.306548, lng: 21.668803 },
    { lat: 49.305686, lng: 21.668345 },
    { lat: 49.304932, lng: 21.668146 },
    { lat: 49.303237, lng: 21.6673 },
    { lat: 49.301691, lng: 21.666318 },
    { lat: 49.299735, lng: 21.664542 },
    { lat: 49.29904, lng: 21.66411 },
    { lat: 49.298789, lng: 21.663832 },
    { lat: 49.298428, lng: 21.6644123 },
    { lat: 49.2981406, lng: 21.6647242 },
    { lat: 49.2975614, lng: 21.6651407 },
    { lat: 49.2970618, lng: 21.6652588 },
    { lat: 49.2966828, lng: 21.665845 },
    { lat: 49.29628, lng: 21.6667171 },
    { lat: 49.2961256, lng: 21.6667888 },
    { lat: 49.2959463, lng: 21.6670269 },
    { lat: 49.2954557, lng: 21.6682292 },
    { lat: 49.2951601, lng: 21.6692743 },
    { lat: 49.2949578, lng: 21.6697022 },
    { lat: 49.2941484, lng: 21.6708482 },
    { lat: 49.2937878, lng: 21.6711964 },
    { lat: 49.2921383, lng: 21.6720118 },
    { lat: 49.2913425, lng: 21.673541 },
    { lat: 49.2910614, lng: 21.6743764 },
    { lat: 49.2897551, lng: 21.6766155 },
    { lat: 49.2888517, lng: 21.6786172 },
    { lat: 49.2884139, lng: 21.6799096 },
    { lat: 49.2879964, lng: 21.6803186 },
    { lat: 49.2869955, lng: 21.6817094 },
    { lat: 49.2864825, lng: 21.6823283 },
    { lat: 49.2862274, lng: 21.6825461 },
    { lat: 49.2860917, lng: 21.6827762 },
    { lat: 49.2838785, lng: 21.6835477 },
    { lat: 49.2831096, lng: 21.6835639 },
    { lat: 49.2824822, lng: 21.6837421 },
    { lat: 49.2818917, lng: 21.683786 },
    { lat: 49.2809894, lng: 21.6839954 },
    { lat: 49.2791709, lng: 21.684172 },
    { lat: 49.2786116, lng: 21.6841653 },
    { lat: 49.2779724, lng: 21.6842833 },
    { lat: 49.2773981, lng: 21.6845078 },
    { lat: 49.2767578, lng: 21.6846265 },
    { lat: 49.2761351, lng: 21.6848584 },
    { lat: 49.275595, lng: 21.6853459 },
    { lat: 49.2754317, lng: 21.6850928 },
    { lat: 49.2754078, lng: 21.6851227 },
    { lat: 49.2754707, lng: 21.685791 },
    { lat: 49.2759923, lng: 21.688501 },
    { lat: 49.2765126, lng: 21.6902862 },
    { lat: 49.2766886, lng: 21.6915563 },
    { lat: 49.2764519, lng: 21.6941849 },
    { lat: 49.2758167, lng: 21.6961304 },
    { lat: 49.2758508, lng: 21.6964777 },
    { lat: 49.2756279, lng: 21.697018 },
    { lat: 49.2754553, lng: 21.6972886 },
    { lat: 49.2754837, lng: 21.6976802 },
    { lat: 49.2752993, lng: 21.6985246 },
    { lat: 49.2753691, lng: 21.6986928 },
    { lat: 49.2754772, lng: 21.7000886 },
    { lat: 49.2784287, lng: 21.6994505 },
    { lat: 49.2809319, lng: 21.6991651 },
    { lat: 49.2811884, lng: 21.7004699 },
    { lat: 49.2815926, lng: 21.7014142 },
    { lat: 49.2815816, lng: 21.7018483 },
    { lat: 49.281737, lng: 21.7015147 },
    { lat: 49.2818028, lng: 21.70238 },
    { lat: 49.2820775, lng: 21.7024295 },
    { lat: 49.2824946, lng: 21.7032606 },
    { lat: 49.282801, lng: 21.7045829 },
    { lat: 49.2836765, lng: 21.7067853 },
    { lat: 49.2846514, lng: 21.706776 },
    { lat: 49.2854993, lng: 21.7071826 },
  ],
  Duplín: [
    { lat: 49.2474894, lng: 21.6479871 },
    { lat: 49.2482622, lng: 21.6481087 },
    { lat: 49.2494852, lng: 21.6475842 },
    { lat: 49.249812, lng: 21.647769 },
    { lat: 49.2497878, lng: 21.6472535 },
    { lat: 49.2504224, lng: 21.6468794 },
    { lat: 49.2544828, lng: 21.6455843 },
    { lat: 49.2561441, lng: 21.6444988 },
    { lat: 49.2561489, lng: 21.6444769 },
    { lat: 49.2525522, lng: 21.6395035 },
    { lat: 49.2517651, lng: 21.6382835 },
    { lat: 49.2506651, lng: 21.6359406 },
    { lat: 49.2505711, lng: 21.6358296 },
    { lat: 49.250259, lng: 21.6357364 },
    { lat: 49.2478771, lng: 21.63111 },
    { lat: 49.2480861, lng: 21.6305296 },
    { lat: 49.2481202, lng: 21.6293463 },
    { lat: 49.2482971, lng: 21.6271371 },
    { lat: 49.2484541, lng: 21.6262168 },
    { lat: 49.2486274, lng: 21.6260966 },
    { lat: 49.2484645, lng: 21.624092 },
    { lat: 49.249147, lng: 21.62077 },
    { lat: 49.248893, lng: 21.620196 },
    { lat: 49.248717, lng: 21.619801 },
    { lat: 49.247712, lng: 21.617547 },
    { lat: 49.247746, lng: 21.616521 },
    { lat: 49.247973, lng: 21.615912 },
    { lat: 49.24712, lng: 21.614401 },
    { lat: 49.246788, lng: 21.614114 },
    { lat: 49.246647, lng: 21.613993 },
    { lat: 49.246525, lng: 21.613887 },
    { lat: 49.246745, lng: 21.613326 },
    { lat: 49.24678, lng: 21.613236 },
    { lat: 49.2466677, lng: 21.6131707 },
    { lat: 49.246558, lng: 21.613114 },
    { lat: 49.246298, lng: 21.613022 },
    { lat: 49.245708, lng: 21.612814 },
    { lat: 49.24527, lng: 21.61266 },
    { lat: 49.24416, lng: 21.613136 },
    { lat: 49.244376, lng: 21.609993 },
    { lat: 49.244376, lng: 21.609987 },
    { lat: 49.244206, lng: 21.609994 },
    { lat: 49.243956, lng: 21.610005 },
    { lat: 49.242336, lng: 21.609503 },
    { lat: 49.242544, lng: 21.607849 },
    { lat: 49.242555, lng: 21.605613 },
    { lat: 49.242845, lng: 21.604438 },
    { lat: 49.242905, lng: 21.604038 },
    { lat: 49.24244, lng: 21.60366 },
    { lat: 49.241979, lng: 21.603286 },
    { lat: 49.240039, lng: 21.60098 },
    { lat: 49.238807, lng: 21.599702 },
    { lat: 49.237515, lng: 21.598228 },
    { lat: 49.237406, lng: 21.598104 },
    { lat: 49.237398, lng: 21.598095 },
    { lat: 49.235461, lng: 21.596054 },
    { lat: 49.234865, lng: 21.595733 },
    { lat: 49.234301, lng: 21.595437 },
    { lat: 49.233365, lng: 21.594928 },
    { lat: 49.233014, lng: 21.594859 },
    { lat: 49.232049, lng: 21.594425 },
    { lat: 49.231652, lng: 21.594112 },
    { lat: 49.23131, lng: 21.594157 },
    { lat: 49.230935, lng: 21.594133 },
    { lat: 49.230831, lng: 21.594088 },
    { lat: 49.230839, lng: 21.594069 },
    { lat: 49.230608, lng: 21.593777 },
    { lat: 49.230393, lng: 21.593678 },
    { lat: 49.230159, lng: 21.593646 },
    { lat: 49.23002, lng: 21.593571 },
    { lat: 49.229637, lng: 21.593491 },
    { lat: 49.229378, lng: 21.593653 },
    { lat: 49.229047, lng: 21.593886 },
    { lat: 49.228479, lng: 21.594015 },
    { lat: 49.228297, lng: 21.594007 },
    { lat: 49.228116, lng: 21.594006 },
    { lat: 49.228117, lng: 21.59403 },
    { lat: 49.227972, lng: 21.593983 },
    { lat: 49.227556, lng: 21.593818 },
    { lat: 49.227442, lng: 21.593721 },
    { lat: 49.227235, lng: 21.593544 },
    { lat: 49.227073, lng: 21.59349 },
    { lat: 49.226978, lng: 21.593364 },
    { lat: 49.226765, lng: 21.593102 },
    { lat: 49.2266, lng: 21.593025 },
    { lat: 49.226433, lng: 21.593049 },
    { lat: 49.226221, lng: 21.593033 },
    { lat: 49.226142, lng: 21.593029 },
    { lat: 49.22602, lng: 21.592988 },
    { lat: 49.225437, lng: 21.59323 },
    { lat: 49.225137, lng: 21.593536 },
    { lat: 49.224932, lng: 21.5937 },
    { lat: 49.224448, lng: 21.594078 },
    { lat: 49.224242, lng: 21.594268 },
    { lat: 49.224143, lng: 21.594455 },
    { lat: 49.223958, lng: 21.595024 },
    { lat: 49.224216, lng: 21.596069 },
    { lat: 49.2243427, lng: 21.5963297 },
    { lat: 49.2244988, lng: 21.5970898 },
    { lat: 49.2244082, lng: 21.5978025 },
    { lat: 49.2242564, lng: 21.5989881 },
    { lat: 49.2244266, lng: 21.5999328 },
    { lat: 49.2243501, lng: 21.6004585 },
    { lat: 49.2244466, lng: 21.6009389 },
    { lat: 49.2244282, lng: 21.6017423 },
    { lat: 49.2245374, lng: 21.6021967 },
    { lat: 49.2244293, lng: 21.6027194 },
    { lat: 49.2245511, lng: 21.6036228 },
    { lat: 49.2245899, lng: 21.6049081 },
    { lat: 49.2245566, lng: 21.605412 },
    { lat: 49.2244298, lng: 21.6057973 },
    { lat: 49.2245214, lng: 21.6076054 },
    { lat: 49.2244577, lng: 21.6083988 },
    { lat: 49.2243331, lng: 21.6090157 },
    { lat: 49.2239011, lng: 21.6092177 },
    { lat: 49.22325, lng: 21.6104151 },
    { lat: 49.2236631, lng: 21.6118466 },
    { lat: 49.2242667, lng: 21.6126151 },
    { lat: 49.2240951, lng: 21.6140614 },
    { lat: 49.2238383, lng: 21.6151558 },
    { lat: 49.2232277, lng: 21.6163031 },
    { lat: 49.2230316, lng: 21.6173587 },
    { lat: 49.2236388, lng: 21.6182508 },
    { lat: 49.223611, lng: 21.6192467 },
    { lat: 49.2236956, lng: 21.6208928 },
    { lat: 49.2247686, lng: 21.6264575 },
    { lat: 49.2238397, lng: 21.6286921 },
    { lat: 49.2248073, lng: 21.6309517 },
    { lat: 49.2246847, lng: 21.6323913 },
    { lat: 49.2247172, lng: 21.6326325 },
    { lat: 49.2249725, lng: 21.6334598 },
    { lat: 49.2250996, lng: 21.6338239 },
    { lat: 49.2256072, lng: 21.6333416 },
    { lat: 49.2261929, lng: 21.6352605 },
    { lat: 49.2265135, lng: 21.6369882 },
    { lat: 49.2278765, lng: 21.6370561 },
    { lat: 49.2289194, lng: 21.6374731 },
    { lat: 49.2291489, lng: 21.6378408 },
    { lat: 49.2299939, lng: 21.6399549 },
    { lat: 49.23078, lng: 21.6424123 },
    { lat: 49.2309806, lng: 21.6425072 },
    { lat: 49.2311968, lng: 21.6435553 },
    { lat: 49.2311949, lng: 21.6444715 },
    { lat: 49.2315296, lng: 21.6448696 },
    { lat: 49.2339091, lng: 21.6452547 },
    { lat: 49.2342265, lng: 21.6459581 },
    { lat: 49.23501, lng: 21.6461013 },
    { lat: 49.2352436, lng: 21.6462917 },
    { lat: 49.2352933, lng: 21.6467946 },
    { lat: 49.2359867, lng: 21.6466505 },
    { lat: 49.2362147, lng: 21.6469633 },
    { lat: 49.2365214, lng: 21.6471134 },
    { lat: 49.2366982, lng: 21.6470445 },
    { lat: 49.2368363, lng: 21.6466981 },
    { lat: 49.2386097, lng: 21.6467594 },
    { lat: 49.2394667, lng: 21.6463388 },
    { lat: 49.2401777, lng: 21.6463826 },
    { lat: 49.2420063, lng: 21.6462834 },
    { lat: 49.2431697, lng: 21.6467877 },
    { lat: 49.2441787, lng: 21.6478559 },
    { lat: 49.2451225, lng: 21.6484812 },
    { lat: 49.2463718, lng: 21.6481318 },
    { lat: 49.2474277, lng: 21.647995 },
    { lat: 49.2474894, lng: 21.6479871 },
  ],
  Jakušovce: [
    { lat: 49.1518668, lng: 21.7412762 },
    { lat: 49.1515732, lng: 21.7419221 },
    { lat: 49.151294, lng: 21.7422051 },
    { lat: 49.1509794, lng: 21.742351 },
    { lat: 49.1505073, lng: 21.7427897 },
    { lat: 49.1502989, lng: 21.7430731 },
    { lat: 49.149871, lng: 21.7426347 },
    { lat: 49.1482994, lng: 21.743265 },
    { lat: 49.1479516, lng: 21.7432152 },
    { lat: 49.1476139, lng: 21.742863 },
    { lat: 49.1471383, lng: 21.7418617 },
    { lat: 49.1469294, lng: 21.7416187 },
    { lat: 49.1463105, lng: 21.7411767 },
    { lat: 49.1459021, lng: 21.741094 },
    { lat: 49.1452866, lng: 21.7403274 },
    { lat: 49.1449437, lng: 21.74025 },
    { lat: 49.1442595, lng: 21.7402346 },
    { lat: 49.1439262, lng: 21.740138 },
    { lat: 49.1434863, lng: 21.7403957 },
    { lat: 49.1431447, lng: 21.7410883 },
    { lat: 49.1423735, lng: 21.7418672 },
    { lat: 49.1423831, lng: 21.7419356 },
    { lat: 49.1421751, lng: 21.7425377 },
    { lat: 49.1414636, lng: 21.7432723 },
    { lat: 49.1411872, lng: 21.7437154 },
    { lat: 49.1408846, lng: 21.7438403 },
    { lat: 49.1405764, lng: 21.743758 },
    { lat: 49.140375, lng: 21.7437973 },
    { lat: 49.139603, lng: 21.744037 },
    { lat: 49.139582, lng: 21.74436 },
    { lat: 49.139528, lng: 21.744954 },
    { lat: 49.139468, lng: 21.74518 },
    { lat: 49.139275, lng: 21.746061 },
    { lat: 49.139013, lng: 21.746716 },
    { lat: 49.139011, lng: 21.747436 },
    { lat: 49.139005, lng: 21.747621 },
    { lat: 49.138951, lng: 21.748143 },
    { lat: 49.13909, lng: 21.748315 },
    { lat: 49.139424, lng: 21.748889 },
    { lat: 49.139726, lng: 21.749505 },
    { lat: 49.139995, lng: 21.749783 },
    { lat: 49.140295, lng: 21.75026 },
    { lat: 49.140518, lng: 21.750549 },
    { lat: 49.140722, lng: 21.750917 },
    { lat: 49.141027, lng: 21.751377 },
    { lat: 49.141478, lng: 21.753165 },
    { lat: 49.141444, lng: 21.753281 },
    { lat: 49.141492, lng: 21.75339 },
    { lat: 49.141523, lng: 21.753385 },
    { lat: 49.141547, lng: 21.753688 },
    { lat: 49.141511, lng: 21.753948 },
    { lat: 49.141699, lng: 21.754142 },
    { lat: 49.141878, lng: 21.754058 },
    { lat: 49.142431, lng: 21.753742 },
    { lat: 49.142596, lng: 21.753666 },
    { lat: 49.143273, lng: 21.753701 },
    { lat: 49.143508, lng: 21.753892 },
    { lat: 49.143642, lng: 21.753855 },
    { lat: 49.143803, lng: 21.754157 },
    { lat: 49.143877, lng: 21.754322 },
    { lat: 49.14399, lng: 21.754307 },
    { lat: 49.144107, lng: 21.754432 },
    { lat: 49.144246, lng: 21.754731 },
    { lat: 49.144471, lng: 21.754896 },
    { lat: 49.144774, lng: 21.754978 },
    { lat: 49.14527, lng: 21.754911 },
    { lat: 49.146141, lng: 21.754429 },
    { lat: 49.146428, lng: 21.754189 },
    { lat: 49.146918, lng: 21.753976 },
    { lat: 49.147114, lng: 21.753918 },
    { lat: 49.147371, lng: 21.753841 },
    { lat: 49.148019, lng: 21.753573 },
    { lat: 49.148015, lng: 21.753843 },
    { lat: 49.148, lng: 21.754656 },
    { lat: 49.147986, lng: 21.754962 },
    { lat: 49.148249, lng: 21.756133 },
    { lat: 49.148233, lng: 21.756694 },
    { lat: 49.148206, lng: 21.757348 },
    { lat: 49.148057, lng: 21.757988 },
    { lat: 49.14803, lng: 21.758476 },
    { lat: 49.148145, lng: 21.758471 },
    { lat: 49.14817, lng: 21.758843 },
    { lat: 49.148194, lng: 21.758823 },
    { lat: 49.148244, lng: 21.758867 },
    { lat: 49.148287, lng: 21.758907 },
    { lat: 49.148461, lng: 21.759061 },
    { lat: 49.148657, lng: 21.759241 },
    { lat: 49.148911, lng: 21.759774 },
    { lat: 49.148991, lng: 21.760044 },
    { lat: 49.14896, lng: 21.760046 },
    { lat: 49.148847, lng: 21.760387 },
    { lat: 49.148856, lng: 21.760757 },
    { lat: 49.148861, lng: 21.760996 },
    { lat: 49.148691, lng: 21.761188 },
    { lat: 49.148582, lng: 21.761407 },
    { lat: 49.14864, lng: 21.761659 },
    { lat: 49.1488, lng: 21.762208 },
    { lat: 49.148774, lng: 21.762254 },
    { lat: 49.148619, lng: 21.762435 },
    { lat: 49.148393, lng: 21.762804 },
    { lat: 49.148069, lng: 21.763235 },
    { lat: 49.148182, lng: 21.764307 },
    { lat: 49.148347, lng: 21.764779 },
    { lat: 49.147892, lng: 21.765172 },
    { lat: 49.148323, lng: 21.7656 },
    { lat: 49.148587, lng: 21.766048 },
    { lat: 49.148706, lng: 21.766856 },
    { lat: 49.148817, lng: 21.767365 },
    { lat: 49.149016, lng: 21.767712 },
    { lat: 49.149205, lng: 21.7682 },
    { lat: 49.149572, lng: 21.768463 },
    { lat: 49.15036, lng: 21.768345 },
    { lat: 49.15075, lng: 21.768176 },
    { lat: 49.151301, lng: 21.768215 },
    { lat: 49.151521, lng: 21.768199 },
    { lat: 49.151831, lng: 21.768187 },
    { lat: 49.152306, lng: 21.768231 },
    { lat: 49.152333, lng: 21.768287 },
    { lat: 49.152499, lng: 21.768629 },
    { lat: 49.152591, lng: 21.768821 },
    { lat: 49.152876, lng: 21.769164 },
    { lat: 49.153509, lng: 21.769358 },
    { lat: 49.15392, lng: 21.769421 },
    { lat: 49.154271, lng: 21.769136 },
    { lat: 49.154915, lng: 21.769053 },
    { lat: 49.155702, lng: 21.768824 },
    { lat: 49.155905, lng: 21.768758 },
    { lat: 49.155949, lng: 21.768744 },
    { lat: 49.156046, lng: 21.768712 },
    { lat: 49.156393, lng: 21.768424 },
    { lat: 49.157359, lng: 21.767595 },
    { lat: 49.157604, lng: 21.76768 },
    { lat: 49.15766, lng: 21.767695 },
    { lat: 49.158072, lng: 21.767472 },
    { lat: 49.158306, lng: 21.767277 },
    { lat: 49.158483, lng: 21.767266 },
    { lat: 49.158508, lng: 21.76718 },
    { lat: 49.158572, lng: 21.767026 },
    { lat: 49.158662, lng: 21.767033 },
    { lat: 49.158806, lng: 21.766881 },
    { lat: 49.159005, lng: 21.766913 },
    { lat: 49.159064, lng: 21.766682 },
    { lat: 49.15934, lng: 21.766289 },
    { lat: 49.159407, lng: 21.766194 },
    { lat: 49.16045, lng: 21.76517 },
    { lat: 49.160706, lng: 21.764809 },
    { lat: 49.161029, lng: 21.764392 },
    { lat: 49.16139, lng: 21.764107 },
    { lat: 49.162153, lng: 21.764083 },
    { lat: 49.16249, lng: 21.764278 },
    { lat: 49.163007, lng: 21.764498 },
    { lat: 49.163415, lng: 21.764794 },
    { lat: 49.163778, lng: 21.765099 },
    { lat: 49.164237, lng: 21.765736 },
    { lat: 49.164563, lng: 21.765938 },
    { lat: 49.165196, lng: 21.766702 },
    { lat: 49.165479, lng: 21.766834 },
    { lat: 49.1658, lng: 21.766722 },
    { lat: 49.166265, lng: 21.766632 },
    { lat: 49.166727, lng: 21.766375 },
    { lat: 49.167193, lng: 21.766301 },
    { lat: 49.167602, lng: 21.76627 },
    { lat: 49.167625, lng: 21.766296 },
    { lat: 49.167916, lng: 21.76618 },
    { lat: 49.168305, lng: 21.765942 },
    { lat: 49.168492, lng: 21.766061 },
    { lat: 49.168773, lng: 21.765848 },
    { lat: 49.169188, lng: 21.765692 },
    { lat: 49.169625, lng: 21.765632 },
    { lat: 49.169901, lng: 21.765408 },
    { lat: 49.16992, lng: 21.765344 },
    { lat: 49.1704257, lng: 21.7642958 },
    { lat: 49.1708112, lng: 21.76317 },
    { lat: 49.1710749, lng: 21.7620736 },
    { lat: 49.171358, lng: 21.7615437 },
    { lat: 49.1716531, lng: 21.7601081 },
    { lat: 49.1718039, lng: 21.7596409 },
    { lat: 49.1719298, lng: 21.7588592 },
    { lat: 49.171873, lng: 21.7582889 },
    { lat: 49.1716994, lng: 21.7575499 },
    { lat: 49.1717021, lng: 21.7568354 },
    { lat: 49.1717921, lng: 21.7554721 },
    { lat: 49.1716474, lng: 21.7549748 },
    { lat: 49.1717707, lng: 21.7540152 },
    { lat: 49.1715686, lng: 21.7538597 },
    { lat: 49.1716047, lng: 21.753566 },
    { lat: 49.1715446, lng: 21.7533788 },
    { lat: 49.1713954, lng: 21.7532182 },
    { lat: 49.1713999, lng: 21.7531378 },
    { lat: 49.1715704, lng: 21.7528638 },
    { lat: 49.1715646, lng: 21.75277 },
    { lat: 49.1713475, lng: 21.7525086 },
    { lat: 49.1712474, lng: 21.7518684 },
    { lat: 49.1710006, lng: 21.7513407 },
    { lat: 49.1709223, lng: 21.7509385 },
    { lat: 49.1707605, lng: 21.7506839 },
    { lat: 49.1707583, lng: 21.7505553 },
    { lat: 49.1706325, lng: 21.7501819 },
    { lat: 49.170769, lng: 21.7499438 },
    { lat: 49.1707983, lng: 21.7496864 },
    { lat: 49.1710043, lng: 21.7490957 },
    { lat: 49.1710191, lng: 21.7488874 },
    { lat: 49.1709874, lng: 21.7488379 },
    { lat: 49.1712803, lng: 21.7481308 },
    { lat: 49.1711624, lng: 21.7478777 },
    { lat: 49.1713598, lng: 21.7475169 },
    { lat: 49.1714243, lng: 21.7472347 },
    { lat: 49.1713339, lng: 21.7468405 },
    { lat: 49.171097, lng: 21.7465552 },
    { lat: 49.1709947, lng: 21.7462595 },
    { lat: 49.170858, lng: 21.7460723 },
    { lat: 49.1708228, lng: 21.7456783 },
    { lat: 49.1704966, lng: 21.7456337 },
    { lat: 49.1702553, lng: 21.7455212 },
    { lat: 49.1700892, lng: 21.7452823 },
    { lat: 49.1698113, lng: 21.7446426 },
    { lat: 49.1696319, lng: 21.7445321 },
    { lat: 49.1697468, lng: 21.7441167 },
    { lat: 49.1696484, lng: 21.7438391 },
    { lat: 49.1694429, lng: 21.7435555 },
    { lat: 49.1693334, lng: 21.7434152 },
    { lat: 49.1691672, lng: 21.7435117 },
    { lat: 49.1689446, lng: 21.7432587 },
    { lat: 49.1687687, lng: 21.7431613 },
    { lat: 49.1682836, lng: 21.7424639 },
    { lat: 49.1677608, lng: 21.7419662 },
    { lat: 49.1671168, lng: 21.7419493 },
    { lat: 49.1669961, lng: 21.74185 },
    { lat: 49.1669068, lng: 21.7419162 },
    { lat: 49.1663696, lng: 21.7411772 },
    { lat: 49.1661387, lng: 21.7411626 },
    { lat: 49.1659494, lng: 21.7409374 },
    { lat: 49.1656599, lng: 21.7407489 },
    { lat: 49.1655167, lng: 21.7404899 },
    { lat: 49.1652276, lng: 21.7403157 },
    { lat: 49.1651065, lng: 21.7400784 },
    { lat: 49.1647619, lng: 21.739803 },
    { lat: 49.1640648, lng: 21.7390515 },
    { lat: 49.1639445, lng: 21.7387984 },
    { lat: 49.1637721, lng: 21.7386623 },
    { lat: 49.1635339, lng: 21.7391396 },
    { lat: 49.1635705, lng: 21.7392915 },
    { lat: 49.1631118, lng: 21.7401037 },
    { lat: 49.1630826, lng: 21.7400699 },
    { lat: 49.1628756, lng: 21.740317 },
    { lat: 49.1627677, lng: 21.7403636 },
    { lat: 49.1626029, lng: 21.7408511 },
    { lat: 49.1623612, lng: 21.7411596 },
    { lat: 49.1621565, lng: 21.7416754 },
    { lat: 49.1621404, lng: 21.7419999 },
    { lat: 49.1617399, lng: 21.7415267 },
    { lat: 49.1612854, lng: 21.7416082 },
    { lat: 49.1598499, lng: 21.7409252 },
    { lat: 49.1591148, lng: 21.7406966 },
    { lat: 49.1580948, lng: 21.7409969 },
    { lat: 49.1571308, lng: 21.7410037 },
    { lat: 49.1569882, lng: 21.7413151 },
    { lat: 49.156627, lng: 21.7413488 },
    { lat: 49.1562451, lng: 21.7410697 },
    { lat: 49.1557184, lng: 21.7411909 },
    { lat: 49.1550531, lng: 21.7415528 },
    { lat: 49.1545303, lng: 21.7415634 },
    { lat: 49.1543891, lng: 21.7415002 },
    { lat: 49.1529676, lng: 21.7416052 },
    { lat: 49.1519312, lng: 21.7413312 },
    { lat: 49.1518668, lng: 21.7412762 },
  ],
  Bystrá: [
    { lat: 49.2426997, lng: 21.8092522 },
    { lat: 49.2425834, lng: 21.8104069 },
    { lat: 49.240488, lng: 21.813377 },
    { lat: 49.2372151, lng: 21.8155725 },
    { lat: 49.2372998, lng: 21.8158061 },
    { lat: 49.236756, lng: 21.8162632 },
    { lat: 49.2368386, lng: 21.8168889 },
    { lat: 49.2363332, lng: 21.8169668 },
    { lat: 49.2349534, lng: 21.8176371 },
    { lat: 49.2345454, lng: 21.8181227 },
    { lat: 49.2350592, lng: 21.8188625 },
    { lat: 49.2348538, lng: 21.8193143 },
    { lat: 49.2344117, lng: 21.819438 },
    { lat: 49.2330786, lng: 21.8218368 },
    { lat: 49.2326155, lng: 21.8218977 },
    { lat: 49.2324798, lng: 21.8226508 },
    { lat: 49.2318521, lng: 21.8225186 },
    { lat: 49.2315799, lng: 21.8229967 },
    { lat: 49.230655, lng: 21.8228154 },
    { lat: 49.2306578, lng: 21.8234944 },
    { lat: 49.230074, lng: 21.824461 },
    { lat: 49.230289, lng: 21.824631 },
    { lat: 49.230485, lng: 21.824752 },
    { lat: 49.230912, lng: 21.82461 },
    { lat: 49.230975, lng: 21.82445 },
    { lat: 49.231284, lng: 21.824341 },
    { lat: 49.231403, lng: 21.824438 },
    { lat: 49.231783, lng: 21.824308 },
    { lat: 49.232077, lng: 21.824262 },
    { lat: 49.232116, lng: 21.824381 },
    { lat: 49.232091, lng: 21.824526 },
    { lat: 49.232195, lng: 21.824403 },
    { lat: 49.23223, lng: 21.824547 },
    { lat: 49.232503, lng: 21.824641 },
    { lat: 49.232386, lng: 21.824964 },
    { lat: 49.232656, lng: 21.824863 },
    { lat: 49.233026, lng: 21.824752 },
    { lat: 49.233292, lng: 21.824889 },
    { lat: 49.23351, lng: 21.8251 },
    { lat: 49.233654, lng: 21.825081 },
    { lat: 49.234227, lng: 21.825079 },
    { lat: 49.234445, lng: 21.825024 },
    { lat: 49.234937, lng: 21.825083 },
    { lat: 49.235044, lng: 21.825085 },
    { lat: 49.235435, lng: 21.825083 },
    { lat: 49.235669, lng: 21.825369 },
    { lat: 49.235831, lng: 21.82512 },
    { lat: 49.236145, lng: 21.825087 },
    { lat: 49.236413, lng: 21.824751 },
    { lat: 49.23659, lng: 21.825112 },
    { lat: 49.236805, lng: 21.825028 },
    { lat: 49.236897, lng: 21.825246 },
    { lat: 49.237032, lng: 21.825281 },
    { lat: 49.237103, lng: 21.825304 },
    { lat: 49.237317, lng: 21.825561 },
    { lat: 49.237734, lng: 21.825499 },
    { lat: 49.238108, lng: 21.825325 },
    { lat: 49.238288, lng: 21.825389 },
    { lat: 49.238405, lng: 21.825558 },
    { lat: 49.238761, lng: 21.825656 },
    { lat: 49.238994, lng: 21.825706 },
    { lat: 49.239259, lng: 21.825658 },
    { lat: 49.239405, lng: 21.825885 },
    { lat: 49.239698, lng: 21.826097 },
    { lat: 49.240311, lng: 21.826522 },
    { lat: 49.24077, lng: 21.826729 },
    { lat: 49.240955, lng: 21.826841 },
    { lat: 49.241399, lng: 21.826881 },
    { lat: 49.241561, lng: 21.827336 },
    { lat: 49.241589, lng: 21.827691 },
    { lat: 49.241756, lng: 21.828219 },
    { lat: 49.241734, lng: 21.829648 },
    { lat: 49.242047, lng: 21.830882 },
    { lat: 49.242909, lng: 21.831777 },
    { lat: 49.244069, lng: 21.831428 },
    { lat: 49.244398, lng: 21.831568 },
    { lat: 49.244584, lng: 21.831533 },
    { lat: 49.245384, lng: 21.831785 },
    { lat: 49.245794, lng: 21.832203 },
    { lat: 49.246063, lng: 21.831799 },
    { lat: 49.246471, lng: 21.83187 },
    { lat: 49.246761, lng: 21.831962 },
    { lat: 49.24689, lng: 21.832327 },
    { lat: 49.247439, lng: 21.832353 },
    { lat: 49.247597, lng: 21.83243 },
    { lat: 49.247698, lng: 21.832752 },
    { lat: 49.247675, lng: 21.832952 },
    { lat: 49.247702, lng: 21.833056 },
    { lat: 49.247882, lng: 21.833148 },
    { lat: 49.248097, lng: 21.832673 },
    { lat: 49.248529, lng: 21.831989 },
    { lat: 49.248844, lng: 21.831776 },
    { lat: 49.249051, lng: 21.831451 },
    { lat: 49.249271, lng: 21.831254 },
    { lat: 49.249423, lng: 21.831062 },
    { lat: 49.249689, lng: 21.830624 },
    { lat: 49.250374, lng: 21.829307 },
    { lat: 49.250621, lng: 21.829029 },
    { lat: 49.250605, lng: 21.828707 },
    { lat: 49.250793, lng: 21.828516 },
    { lat: 49.251291, lng: 21.828045 },
    { lat: 49.251797, lng: 21.8275 },
    { lat: 49.252083, lng: 21.827136 },
    { lat: 49.252346, lng: 21.827095 },
    { lat: 49.252934, lng: 21.826557 },
    { lat: 49.252921, lng: 21.826466 },
    { lat: 49.253395, lng: 21.82601 },
    { lat: 49.253429, lng: 21.825995 },
    { lat: 49.253815, lng: 21.826008 },
    { lat: 49.254092, lng: 21.826233 },
    { lat: 49.254268, lng: 21.826338 },
    { lat: 49.254617, lng: 21.826442 },
    { lat: 49.255156, lng: 21.826636 },
    { lat: 49.25567, lng: 21.827087 },
    { lat: 49.255838, lng: 21.827207 },
    { lat: 49.25593, lng: 21.827344 },
    { lat: 49.256266, lng: 21.827592 },
    { lat: 49.256465, lng: 21.827625 },
    { lat: 49.256978, lng: 21.827538 },
    { lat: 49.257398, lng: 21.827601 },
    { lat: 49.257571, lng: 21.827755 },
    { lat: 49.257869, lng: 21.827747 },
    { lat: 49.25807, lng: 21.82768 },
    { lat: 49.258983, lng: 21.826978 },
    { lat: 49.258979, lng: 21.826927 },
    { lat: 49.2588403, lng: 21.8261294 },
    { lat: 49.2583242, lng: 21.8254798 },
    { lat: 49.2581041, lng: 21.8245758 },
    { lat: 49.2579241, lng: 21.8242665 },
    { lat: 49.2578153, lng: 21.8237573 },
    { lat: 49.2574602, lng: 21.8230246 },
    { lat: 49.2572689, lng: 21.8224995 },
    { lat: 49.2570529, lng: 21.8222737 },
    { lat: 49.2569908, lng: 21.8220978 },
    { lat: 49.2570218, lng: 21.8219447 },
    { lat: 49.2568582, lng: 21.8216596 },
    { lat: 49.25677, lng: 21.8212 },
    { lat: 49.2563284, lng: 21.8196914 },
    { lat: 49.2558778, lng: 21.8187524 },
    { lat: 49.2556497, lng: 21.818581 },
    { lat: 49.2556772, lng: 21.8182491 },
    { lat: 49.2554625, lng: 21.8179923 },
    { lat: 49.2555762, lng: 21.8177417 },
    { lat: 49.2554869, lng: 21.8176765 },
    { lat: 49.2554811, lng: 21.8174514 },
    { lat: 49.2554212, lng: 21.8173492 },
    { lat: 49.2552731, lng: 21.817456 },
    { lat: 49.2552256, lng: 21.8172234 },
    { lat: 49.2550893, lng: 21.8170029 },
    { lat: 49.2550831, lng: 21.8167163 },
    { lat: 49.2549972, lng: 21.816783 },
    { lat: 49.2548447, lng: 21.8163713 },
    { lat: 49.2547337, lng: 21.8163013 },
    { lat: 49.2547497, lng: 21.8159953 },
    { lat: 49.2544975, lng: 21.8158128 },
    { lat: 49.2545637, lng: 21.8155002 },
    { lat: 49.2545234, lng: 21.8153853 },
    { lat: 49.2541396, lng: 21.815327 },
    { lat: 49.253878, lng: 21.8151245 },
    { lat: 49.2536149, lng: 21.8151781 },
    { lat: 49.2535013, lng: 21.8150888 },
    { lat: 49.2533641, lng: 21.8151788 },
    { lat: 49.2531811, lng: 21.8149724 },
    { lat: 49.2529719, lng: 21.8149781 },
    { lat: 49.252874, lng: 21.8147096 },
    { lat: 49.2528414, lng: 21.8144498 },
    { lat: 49.2526556, lng: 21.8140923 },
    { lat: 49.2526316, lng: 21.8136001 },
    { lat: 49.2525644, lng: 21.8136545 },
    { lat: 49.2524941, lng: 21.8135849 },
    { lat: 49.2525967, lng: 21.8134265 },
    { lat: 49.2525223, lng: 21.8130615 },
    { lat: 49.2525774, lng: 21.812934 },
    { lat: 49.2524872, lng: 21.8128454 },
    { lat: 49.2525024, lng: 21.8126843 },
    { lat: 49.2524285, lng: 21.8125713 },
    { lat: 49.2525293, lng: 21.8124216 },
    { lat: 49.2524586, lng: 21.8123848 },
    { lat: 49.2524265, lng: 21.8121466 },
    { lat: 49.2523587, lng: 21.8120855 },
    { lat: 49.2523435, lng: 21.8118516 },
    { lat: 49.2522403, lng: 21.8118183 },
    { lat: 49.2521977, lng: 21.8116898 },
    { lat: 49.2523407, lng: 21.8114338 },
    { lat: 49.2520716, lng: 21.8109884 },
    { lat: 49.2519171, lng: 21.8104544 },
    { lat: 49.2519823, lng: 21.8101037 },
    { lat: 49.2519898, lng: 21.8095635 },
    { lat: 49.2513665, lng: 21.8106478 },
    { lat: 49.2512993, lng: 21.8106525 },
    { lat: 49.2508175, lng: 21.8101602 },
    { lat: 49.250119, lng: 21.8085057 },
    { lat: 49.2493368, lng: 21.8087552 },
    { lat: 49.2486643, lng: 21.8083688 },
    { lat: 49.2474476, lng: 21.8085088 },
    { lat: 49.2469688, lng: 21.8083029 },
    { lat: 49.2467719, lng: 21.808044 },
    { lat: 49.2466251, lng: 21.8076727 },
    { lat: 49.245909, lng: 21.8083837 },
    { lat: 49.2443721, lng: 21.8092839 },
    { lat: 49.2442445, lng: 21.8091789 },
    { lat: 49.2440553, lng: 21.809254 },
    { lat: 49.244013, lng: 21.8090154 },
    { lat: 49.2436288, lng: 21.8092502 },
    { lat: 49.2434501, lng: 21.8086926 },
    { lat: 49.2426997, lng: 21.8092522 },
  ],
  VyšnáOlšava: [
    { lat: 49.1696895, lng: 21.6325618 },
    { lat: 49.1698417, lng: 21.6320851 },
    { lat: 49.1699928, lng: 21.6319313 },
    { lat: 49.1701839, lng: 21.6310236 },
    { lat: 49.1707029, lng: 21.6298512 },
    { lat: 49.170725, lng: 21.6292558 },
    { lat: 49.1708328, lng: 21.6286723 },
    { lat: 49.1711139, lng: 21.6280162 },
    { lat: 49.1717614, lng: 21.6269319 },
    { lat: 49.172088, lng: 21.6260424 },
    { lat: 49.1722111, lng: 21.625853 },
    { lat: 49.1721905, lng: 21.6252672 },
    { lat: 49.172054, lng: 21.6247233 },
    { lat: 49.1717352, lng: 21.6240951 },
    { lat: 49.1717157, lng: 21.6238734 },
    { lat: 49.1716505, lng: 21.6237916 },
    { lat: 49.1715969, lng: 21.6234794 },
    { lat: 49.1716309, lng: 21.6233262 },
    { lat: 49.1715366, lng: 21.6230853 },
    { lat: 49.1715699, lng: 21.6228225 },
    { lat: 49.1716479, lng: 21.6227179 },
    { lat: 49.1716195, lng: 21.6226615 },
    { lat: 49.1717451, lng: 21.6220768 },
    { lat: 49.1722045, lng: 21.6217659 },
    { lat: 49.1723253, lng: 21.6214116 },
    { lat: 49.1725983, lng: 21.6184085 },
    { lat: 49.172684, lng: 21.6180115 },
    { lat: 49.1728144, lng: 21.617516 },
    { lat: 49.1732121, lng: 21.6167628 },
    { lat: 49.1737079, lng: 21.6151113 },
    { lat: 49.1738633, lng: 21.6143017 },
    { lat: 49.1741759, lng: 21.6133929 },
    { lat: 49.1742096, lng: 21.6129068 },
    { lat: 49.1742888, lng: 21.6125105 },
    { lat: 49.1748255, lng: 21.6119029 },
    { lat: 49.1756997, lng: 21.6114161 },
    { lat: 49.1761673, lng: 21.6114034 },
    { lat: 49.1766917, lng: 21.6110829 },
    { lat: 49.1771389, lng: 21.6103746 },
    { lat: 49.17741, lng: 21.6097599 },
    { lat: 49.1778747, lng: 21.6093957 },
    { lat: 49.1781333, lng: 21.6088218 },
    { lat: 49.1784168, lng: 21.6079219 },
    { lat: 49.1785512, lng: 21.6072473 },
    { lat: 49.1787597, lng: 21.6068154 },
    { lat: 49.1785241, lng: 21.6061804 },
    { lat: 49.1782563, lng: 21.6056503 },
    { lat: 49.1779559, lng: 21.6052046 },
    { lat: 49.1779949, lng: 21.604739 },
    { lat: 49.178218, lng: 21.6039836 },
    { lat: 49.1783302, lng: 21.6033604 },
    { lat: 49.1790423, lng: 21.6008752 },
    { lat: 49.1793782, lng: 21.60049 },
    { lat: 49.1795487, lng: 21.5999059 },
    { lat: 49.1796692, lng: 21.5997097 },
    { lat: 49.1796626, lng: 21.5992264 },
    { lat: 49.1801837, lng: 21.5987876 },
    { lat: 49.1807109, lng: 21.5979957 },
    { lat: 49.1807522, lng: 21.5977873 },
    { lat: 49.1809453, lng: 21.5973613 },
    { lat: 49.1811691, lng: 21.5965454 },
    { lat: 49.1813105, lng: 21.5963341 },
    { lat: 49.1813442, lng: 21.5959648 },
    { lat: 49.1814803, lng: 21.5956043 },
    { lat: 49.1818556, lng: 21.5949296 },
    { lat: 49.182209, lng: 21.5945728 },
    { lat: 49.1824332, lng: 21.5939355 },
    { lat: 49.1824959, lng: 21.5930356 },
    { lat: 49.1824413, lng: 21.5926892 },
    { lat: 49.1822806, lng: 21.5923256 },
    { lat: 49.1821212, lng: 21.5920957 },
    { lat: 49.1822155, lng: 21.5911942 },
    { lat: 49.1823622, lng: 21.590893 },
    { lat: 49.1825008, lng: 21.5907708 },
    { lat: 49.1826388, lng: 21.5896637 },
    { lat: 49.1830032, lng: 21.5895855 },
    { lat: 49.183207, lng: 21.58929 },
    { lat: 49.183129, lng: 21.589299 },
    { lat: 49.182982, lng: 21.589074 },
    { lat: 49.182797, lng: 21.588977 },
    { lat: 49.182438, lng: 21.588687 },
    { lat: 49.182219, lng: 21.588592 },
    { lat: 49.182072, lng: 21.588369 },
    { lat: 49.181922, lng: 21.588308 },
    { lat: 49.181688, lng: 21.587886 },
    { lat: 49.181414, lng: 21.587652 },
    { lat: 49.181286, lng: 21.587439 },
    { lat: 49.181263, lng: 21.587292 },
    { lat: 49.181081, lng: 21.58704 },
    { lat: 49.180958, lng: 21.586498 },
    { lat: 49.180877, lng: 21.585937 },
    { lat: 49.180849, lng: 21.585804 },
    { lat: 49.180753, lng: 21.585465 },
    { lat: 49.180741, lng: 21.585321 },
    { lat: 49.180522, lng: 21.585302 },
    { lat: 49.179885, lng: 21.585143 },
    { lat: 49.179786, lng: 21.585149 },
    { lat: 49.179646, lng: 21.585119 },
    { lat: 49.179554, lng: 21.585053 },
    { lat: 49.179459, lng: 21.585013 },
    { lat: 49.179388, lng: 21.585063 },
    { lat: 49.179344, lng: 21.585121 },
    { lat: 49.179134, lng: 21.585162 },
    { lat: 49.179068, lng: 21.585098 },
    { lat: 49.178962, lng: 21.585063 },
    { lat: 49.178903, lng: 21.585107 },
    { lat: 49.178784, lng: 21.58509 },
    { lat: 49.178725, lng: 21.585138 },
    { lat: 49.178676, lng: 21.585123 },
    { lat: 49.178421, lng: 21.585064 },
    { lat: 49.17833, lng: 21.585091 },
    { lat: 49.178217, lng: 21.585071 },
    { lat: 49.178178, lng: 21.585054 },
    { lat: 49.17817, lng: 21.585053 },
    { lat: 49.178025, lng: 21.585048 },
    { lat: 49.17796, lng: 21.584928 },
    { lat: 49.177911, lng: 21.584921 },
    { lat: 49.177785, lng: 21.584991 },
    { lat: 49.177734, lng: 21.584988 },
    { lat: 49.177487, lng: 21.584877 },
    { lat: 49.177405, lng: 21.584856 },
    { lat: 49.177336, lng: 21.584847 },
    { lat: 49.177307, lng: 21.584865 },
    { lat: 49.177256, lng: 21.584848 },
    { lat: 49.177204, lng: 21.584774 },
    { lat: 49.177147, lng: 21.584732 },
    { lat: 49.177093, lng: 21.584742 },
    { lat: 49.177038, lng: 21.584779 },
    { lat: 49.176993, lng: 21.584776 },
    { lat: 49.176927, lng: 21.584748 },
    { lat: 49.176847, lng: 21.584733 },
    { lat: 49.176727, lng: 21.584747 },
    { lat: 49.176683, lng: 21.584781 },
    { lat: 49.17665, lng: 21.584844 },
    { lat: 49.176622, lng: 21.584859 },
    { lat: 49.17657, lng: 21.584819 },
    { lat: 49.176506, lng: 21.584791 },
    { lat: 49.176401, lng: 21.584702 },
    { lat: 49.176342, lng: 21.58461 },
    { lat: 49.17631, lng: 21.584595 },
    { lat: 49.176252, lng: 21.584588 },
    { lat: 49.176123, lng: 21.584514 },
    { lat: 49.176055, lng: 21.584433 },
    { lat: 49.175995, lng: 21.584348 },
    { lat: 49.175816, lng: 21.584135 },
    { lat: 49.175766, lng: 21.584144 },
    { lat: 49.175755, lng: 21.584078 },
    { lat: 49.175718, lng: 21.584059 },
    { lat: 49.175673, lng: 21.584101 },
    { lat: 49.175601, lng: 21.5841 },
    { lat: 49.175491, lng: 21.584135 },
    { lat: 49.175437, lng: 21.584196 },
    { lat: 49.175391, lng: 21.584217 },
    { lat: 49.175311, lng: 21.584332 },
    { lat: 49.175336, lng: 21.584386 },
    { lat: 49.175325, lng: 21.584447 },
    { lat: 49.175228, lng: 21.584494 },
    { lat: 49.175189, lng: 21.584537 },
    { lat: 49.175162, lng: 21.584686 },
    { lat: 49.175125, lng: 21.58471 },
    { lat: 49.175078, lng: 21.584628 },
    { lat: 49.175014, lng: 21.584677 },
    { lat: 49.174939, lng: 21.584708 },
    { lat: 49.174839, lng: 21.584685 },
    { lat: 49.174829, lng: 21.584613 },
    { lat: 49.174779, lng: 21.584583 },
    { lat: 49.174781, lng: 21.584643 },
    { lat: 49.17467, lng: 21.584713 },
    { lat: 49.174678, lng: 21.584828 },
    { lat: 49.174567, lng: 21.584766 },
    { lat: 49.174544, lng: 21.584888 },
    { lat: 49.174487, lng: 21.584928 },
    { lat: 49.174458, lng: 21.5849 },
    { lat: 49.174502, lng: 21.584864 },
    { lat: 49.174524, lng: 21.584805 },
    { lat: 49.174421, lng: 21.584706 },
    { lat: 49.174418, lng: 21.584799 },
    { lat: 49.174314, lng: 21.584707 },
    { lat: 49.174295, lng: 21.584545 },
    { lat: 49.17422, lng: 21.584172 },
    { lat: 49.174176, lng: 21.583902 },
    { lat: 49.174166, lng: 21.583814 },
    { lat: 49.174135, lng: 21.583548 },
    { lat: 49.174087, lng: 21.583302 },
    { lat: 49.173874, lng: 21.583105 },
    { lat: 49.173628, lng: 21.582932 },
    { lat: 49.173499, lng: 21.582497 },
    { lat: 49.173653, lng: 21.582344 },
    { lat: 49.173667, lng: 21.582133 },
    { lat: 49.173593, lng: 21.582046 },
    { lat: 49.1736, lng: 21.581684 },
    { lat: 49.17357, lng: 21.581363 },
    { lat: 49.173448, lng: 21.581135 },
    { lat: 49.173356, lng: 21.580791 },
    { lat: 49.173132, lng: 21.580672 },
    { lat: 49.17301, lng: 21.580503 },
    { lat: 49.172874, lng: 21.580472 },
    { lat: 49.172702, lng: 21.580328 },
    { lat: 49.172529, lng: 21.580229 },
    { lat: 49.172452, lng: 21.580009 },
    { lat: 49.17226, lng: 21.579842 },
    { lat: 49.172189, lng: 21.579529 },
    { lat: 49.172073, lng: 21.579346 },
    { lat: 49.171701, lng: 21.57924 },
    { lat: 49.171597, lng: 21.579065 },
    { lat: 49.171453, lng: 21.578931 },
    { lat: 49.171355, lng: 21.578991 },
    { lat: 49.171135, lng: 21.578886 },
    { lat: 49.171028, lng: 21.578741 },
    { lat: 49.170939, lng: 21.578657 },
    { lat: 49.170911, lng: 21.57855 },
    { lat: 49.170773, lng: 21.578477 },
    { lat: 49.17071, lng: 21.578335 },
    { lat: 49.170666, lng: 21.57816 },
    { lat: 49.170554, lng: 21.57797 },
    { lat: 49.170568, lng: 21.577744 },
    { lat: 49.1704, lng: 21.576771 },
    { lat: 49.170362, lng: 21.57664 },
    { lat: 49.17023, lng: 21.576389 },
    { lat: 49.170237, lng: 21.576184 },
    { lat: 49.17019, lng: 21.576017 },
    { lat: 49.170171, lng: 21.575956 },
    { lat: 49.17006, lng: 21.575644 },
    { lat: 49.170004, lng: 21.575665 },
    { lat: 49.169969, lng: 21.575683 },
    { lat: 49.169927, lng: 21.575706 },
    { lat: 49.16992, lng: 21.575709 },
    { lat: 49.169771, lng: 21.575785 },
    { lat: 49.16906, lng: 21.575775 },
    { lat: 49.168737, lng: 21.57547 },
    { lat: 49.168617, lng: 21.575277 },
    { lat: 49.168433, lng: 21.574897 },
    { lat: 49.168073, lng: 21.574549 },
    { lat: 49.167958, lng: 21.574403 },
    { lat: 49.167804, lng: 21.574241 },
    { lat: 49.167399, lng: 21.573853 },
    { lat: 49.166995, lng: 21.573464 },
    { lat: 49.166842, lng: 21.573337 },
    { lat: 49.166588, lng: 21.57313 },
    { lat: 49.166547, lng: 21.573094 },
    { lat: 49.166542, lng: 21.573109 },
    { lat: 49.166145, lng: 21.57289 },
    { lat: 49.165883, lng: 21.572784 },
    { lat: 49.1656, lng: 21.572797 },
    { lat: 49.165407, lng: 21.572935 },
    { lat: 49.165258, lng: 21.573015 },
    { lat: 49.164942, lng: 21.572956 },
    { lat: 49.164761, lng: 21.572924 },
    { lat: 49.164528, lng: 21.572763 },
    { lat: 49.163636, lng: 21.572797 },
    { lat: 49.163056, lng: 21.572715 },
    { lat: 49.163004, lng: 21.572641 },
    { lat: 49.163013, lng: 21.572627 },
    { lat: 49.162926, lng: 21.572478 },
    { lat: 49.162794, lng: 21.572174 },
    { lat: 49.162665, lng: 21.572031 },
    { lat: 49.162472, lng: 21.571986 },
    { lat: 49.162458, lng: 21.571866 },
    { lat: 49.162344, lng: 21.571736 },
    { lat: 49.162235, lng: 21.571548 },
    { lat: 49.162174, lng: 21.571339 },
    { lat: 49.162041, lng: 21.571466 },
    { lat: 49.16182, lng: 21.571123 },
    { lat: 49.16169, lng: 21.570785 },
    { lat: 49.161545, lng: 21.570631 },
    { lat: 49.161447, lng: 21.570579 },
    { lat: 49.161203, lng: 21.570276 },
    { lat: 49.16114, lng: 21.570157 },
    { lat: 49.160858, lng: 21.569895 },
    { lat: 49.160671, lng: 21.569702 },
    { lat: 49.16031, lng: 21.569528 },
    { lat: 49.160012, lng: 21.569299 },
    { lat: 49.159591, lng: 21.569232 },
    { lat: 49.159285, lng: 21.569336 },
    { lat: 49.15917, lng: 21.569418 },
    { lat: 49.159091, lng: 21.569522 },
    { lat: 49.158935, lng: 21.569567 },
    { lat: 49.158821, lng: 21.569664 },
    { lat: 49.158731, lng: 21.569817 },
    { lat: 49.158591, lng: 21.570054 },
    { lat: 49.158135, lng: 21.570282 },
    { lat: 49.157593, lng: 21.570511 },
    { lat: 49.157003, lng: 21.570771 },
    { lat: 49.156689, lng: 21.571224 },
    { lat: 49.156326, lng: 21.571651 },
    { lat: 49.156309, lng: 21.571831 },
    { lat: 49.156045, lng: 21.572361 },
    { lat: 49.15595, lng: 21.572445 },
    { lat: 49.155529, lng: 21.573101 },
    { lat: 49.155291, lng: 21.573353 },
    { lat: 49.155225, lng: 21.573507 },
    { lat: 49.15487, lng: 21.57402 },
    { lat: 49.154622, lng: 21.574283 },
    { lat: 49.154493, lng: 21.574471 },
    { lat: 49.154435, lng: 21.57467 },
    { lat: 49.1541, lng: 21.575042 },
    { lat: 49.15397, lng: 21.575159 },
    { lat: 49.153904, lng: 21.575352 },
    { lat: 49.153303, lng: 21.575773 },
    { lat: 49.152605, lng: 21.576511 },
    { lat: 49.152285, lng: 21.576753 },
    { lat: 49.151686, lng: 21.577389 },
    { lat: 49.151376, lng: 21.577794 },
    { lat: 49.151007, lng: 21.578164 },
    { lat: 49.150552, lng: 21.578426 },
    { lat: 49.150396, lng: 21.578543 },
    { lat: 49.149999, lng: 21.578855 },
    { lat: 49.149624, lng: 21.579098 },
    { lat: 49.149563, lng: 21.578989 },
    { lat: 49.148598, lng: 21.579392 },
    { lat: 49.148232, lng: 21.579719 },
    { lat: 49.147534, lng: 21.580409 },
    { lat: 49.147197, lng: 21.580541 },
    { lat: 49.146845, lng: 21.581045 },
    { lat: 49.146827, lng: 21.581035 },
    { lat: 49.146631, lng: 21.581414 },
    { lat: 49.146442, lng: 21.581616 },
    { lat: 49.14623, lng: 21.581802 },
    { lat: 49.145941, lng: 21.581855 },
    { lat: 49.145313, lng: 21.582354 },
    { lat: 49.145308, lng: 21.5824 },
    { lat: 49.14514, lng: 21.582426 },
    { lat: 49.144519, lng: 21.582701 },
    { lat: 49.143919, lng: 21.582968 },
    { lat: 49.143402, lng: 21.583291 },
    { lat: 49.143354, lng: 21.583398 },
    { lat: 49.143259, lng: 21.583589 },
    { lat: 49.143084, lng: 21.583846 },
    { lat: 49.1429, lng: 21.584152 },
    { lat: 49.142102, lng: 21.585742 },
    { lat: 49.141897, lng: 21.586522 },
    { lat: 49.141694, lng: 21.586423 },
    { lat: 49.141626, lng: 21.586395 },
    { lat: 49.141494, lng: 21.587311 },
    { lat: 49.14127, lng: 21.588482 },
    { lat: 49.141336, lng: 21.588545 },
    { lat: 49.141155, lng: 21.588573 },
    { lat: 49.140504, lng: 21.590053 },
    { lat: 49.140311, lng: 21.590425 },
    { lat: 49.139375, lng: 21.591613 },
    { lat: 49.138871, lng: 21.592326 },
    { lat: 49.138573, lng: 21.592736 },
    { lat: 49.138486, lng: 21.592766 },
    { lat: 49.138294, lng: 21.593089 },
    { lat: 49.13826, lng: 21.593141 },
    { lat: 49.137894, lng: 21.593478 },
    { lat: 49.137547, lng: 21.593989 },
    { lat: 49.137367, lng: 21.594256 },
    { lat: 49.1373772, lng: 21.5957198 },
    { lat: 49.1371391, lng: 21.5973616 },
    { lat: 49.1371437, lng: 21.5985405 },
    { lat: 49.1370449, lng: 21.5994397 },
    { lat: 49.1366969, lng: 21.6004443 },
    { lat: 49.1368905, lng: 21.6014235 },
    { lat: 49.137114, lng: 21.6017332 },
    { lat: 49.1374071, lng: 21.6022447 },
    { lat: 49.1372588, lng: 21.6031489 },
    { lat: 49.1379184, lng: 21.6035678 },
    { lat: 49.1384753, lng: 21.6037721 },
    { lat: 49.138942, lng: 21.6046159 },
    { lat: 49.1392787, lng: 21.6056898 },
    { lat: 49.1395264, lng: 21.6062617 },
    { lat: 49.1398602, lng: 21.6066179 },
    { lat: 49.1399601, lng: 21.6068551 },
    { lat: 49.1402122, lng: 21.6071404 },
    { lat: 49.1404152, lng: 21.6076117 },
    { lat: 49.1407733, lng: 21.6077483 },
    { lat: 49.1408582, lng: 21.6079272 },
    { lat: 49.1411042, lng: 21.6080788 },
    { lat: 49.1412245, lng: 21.6080294 },
    { lat: 49.1413626, lng: 21.6081456 },
    { lat: 49.1414604, lng: 21.6081045 },
    { lat: 49.1416908, lng: 21.6082604 },
    { lat: 49.141883, lng: 21.6084913 },
    { lat: 49.1420586, lng: 21.6085013 },
    { lat: 49.1421124, lng: 21.6088597 },
    { lat: 49.1424095, lng: 21.6088212 },
    { lat: 49.1426202, lng: 21.6089546 },
    { lat: 49.1426754, lng: 21.6091613 },
    { lat: 49.1428471, lng: 21.6089437 },
    { lat: 49.142863, lng: 21.6093563 },
    { lat: 49.1430649, lng: 21.6093343 },
    { lat: 49.1430174, lng: 21.6095702 },
    { lat: 49.1432319, lng: 21.6096801 },
    { lat: 49.1432801, lng: 21.609958 },
    { lat: 49.1433539, lng: 21.6101034 },
    { lat: 49.1436692, lng: 21.6100721 },
    { lat: 49.1437059, lng: 21.6101384 },
    { lat: 49.1438754, lng: 21.610001 },
    { lat: 49.1445965, lng: 21.6103677 },
    { lat: 49.1447332, lng: 21.6106403 },
    { lat: 49.1449289, lng: 21.610631 },
    { lat: 49.1449957, lng: 21.6107273 },
    { lat: 49.1450009, lng: 21.6112063 },
    { lat: 49.1454369, lng: 21.6115535 },
    { lat: 49.1455512, lng: 21.6114945 },
    { lat: 49.1457043, lng: 21.6117031 },
    { lat: 49.1459881, lng: 21.6117166 },
    { lat: 49.1463578, lng: 21.6122323 },
    { lat: 49.1469193, lng: 21.6124353 },
    { lat: 49.1469255, lng: 21.612663 },
    { lat: 49.1473189, lng: 21.6134633 },
    { lat: 49.1476467, lng: 21.6137258 },
    { lat: 49.1478323, lng: 21.6140507 },
    { lat: 49.1479206, lng: 21.6141183 },
    { lat: 49.1479202, lng: 21.6147533 },
    { lat: 49.1478627, lng: 21.615037 },
    { lat: 49.148194, lng: 21.6157931 },
    { lat: 49.1486288, lng: 21.6162993 },
    { lat: 49.1487408, lng: 21.6167718 },
    { lat: 49.148805, lng: 21.616818 },
    { lat: 49.1488901, lng: 21.6170807 },
    { lat: 49.148965, lng: 21.6171471 },
    { lat: 49.1489218, lng: 21.6177464 },
    { lat: 49.1495513, lng: 21.619075 },
    { lat: 49.1495073, lng: 21.6191583 },
    { lat: 49.1495824, lng: 21.6198392 },
    { lat: 49.1501595, lng: 21.6207388 },
    { lat: 49.1500642, lng: 21.6215334 },
    { lat: 49.1503216, lng: 21.6221771 },
    { lat: 49.1505678, lng: 21.6230699 },
    { lat: 49.1506211, lng: 21.6230054 },
    { lat: 49.1506793, lng: 21.6234419 },
    { lat: 49.1508619, lng: 21.6237886 },
    { lat: 49.1513046, lng: 21.6236855 },
    { lat: 49.151653, lng: 21.6237086 },
    { lat: 49.1517247, lng: 21.6235732 },
    { lat: 49.1519347, lng: 21.6234875 },
    { lat: 49.152471, lng: 21.6238378 },
    { lat: 49.1525531, lng: 21.6242509 },
    { lat: 49.1528123, lng: 21.6247124 },
    { lat: 49.1529468, lng: 21.6250671 },
    { lat: 49.1541402, lng: 21.6252264 },
    { lat: 49.1542368, lng: 21.6258323 },
    { lat: 49.154377, lng: 21.6258575 },
    { lat: 49.1544266, lng: 21.6255786 },
    { lat: 49.1544076, lng: 21.6253333 },
    { lat: 49.1550781, lng: 21.6254526 },
    { lat: 49.1551107, lng: 21.6256978 },
    { lat: 49.1553028, lng: 21.6257308 },
    { lat: 49.1553818, lng: 21.6254127 },
    { lat: 49.155361, lng: 21.6253515 },
    { lat: 49.1555494, lng: 21.6252949 },
    { lat: 49.155798, lng: 21.6254085 },
    { lat: 49.1559329, lng: 21.6253272 },
    { lat: 49.156142, lng: 21.6254476 },
    { lat: 49.1561373, lng: 21.6253936 },
    { lat: 49.1564513, lng: 21.6252406 },
    { lat: 49.1564534, lng: 21.6251463 },
    { lat: 49.1566576, lng: 21.6250865 },
    { lat: 49.1568844, lng: 21.6255253 },
    { lat: 49.1571307, lng: 21.6256451 },
    { lat: 49.157172, lng: 21.6255961 },
    { lat: 49.1571269, lng: 21.6253862 },
    { lat: 49.1572589, lng: 21.6248167 },
    { lat: 49.157391, lng: 21.6246311 },
    { lat: 49.1575436, lng: 21.6245412 },
    { lat: 49.1576764, lng: 21.6243397 },
    { lat: 49.1577219, lng: 21.6241488 },
    { lat: 49.1579052, lng: 21.6241022 },
    { lat: 49.1580344, lng: 21.6235721 },
    { lat: 49.1582011, lng: 21.6234964 },
    { lat: 49.1583264, lng: 21.6238338 },
    { lat: 49.1584243, lng: 21.6239584 },
    { lat: 49.1585417, lng: 21.6239746 },
    { lat: 49.158515, lng: 21.6241855 },
    { lat: 49.1587392, lng: 21.6242804 },
    { lat: 49.158965, lng: 21.6244774 },
    { lat: 49.1593854, lng: 21.6242212 },
    { lat: 49.1594002, lng: 21.624391 },
    { lat: 49.1595003, lng: 21.6244335 },
    { lat: 49.1598481, lng: 21.6241873 },
    { lat: 49.1597978, lng: 21.623917 },
    { lat: 49.1597203, lng: 21.6237983 },
    { lat: 49.1597606, lng: 21.6235837 },
    { lat: 49.1598811, lng: 21.623627 },
    { lat: 49.1600081, lng: 21.6235075 },
    { lat: 49.1601185, lng: 21.6235853 },
    { lat: 49.1602684, lng: 21.6232763 },
    { lat: 49.160356, lng: 21.6232279 },
    { lat: 49.1603962, lng: 21.623423 },
    { lat: 49.1604935, lng: 21.6234377 },
    { lat: 49.1604913, lng: 21.623358 },
    { lat: 49.1606341, lng: 21.6232364 },
    { lat: 49.1606381, lng: 21.6230122 },
    { lat: 49.1607479, lng: 21.622957 },
    { lat: 49.1607836, lng: 21.6231078 },
    { lat: 49.1608288, lng: 21.6230722 },
    { lat: 49.1608028, lng: 21.6229502 },
    { lat: 49.1608658, lng: 21.6228643 },
    { lat: 49.1610438, lng: 21.6228306 },
    { lat: 49.161107, lng: 21.6228855 },
    { lat: 49.1611183, lng: 21.6230031 },
    { lat: 49.1610671, lng: 21.6230703 },
    { lat: 49.1612267, lng: 21.6231823 },
    { lat: 49.1612037, lng: 21.622921 },
    { lat: 49.1615698, lng: 21.6228157 },
    { lat: 49.1615477, lng: 21.6227089 },
    { lat: 49.1616203, lng: 21.6226029 },
    { lat: 49.1617893, lng: 21.6226112 },
    { lat: 49.1617952, lng: 21.6225436 },
    { lat: 49.1619981, lng: 21.6224386 },
    { lat: 49.1620332, lng: 21.6222829 },
    { lat: 49.1623785, lng: 21.6222374 },
    { lat: 49.1626378, lng: 21.6223391 },
    { lat: 49.1628467, lng: 21.622531 },
    { lat: 49.1630283, lng: 21.6225525 },
    { lat: 49.1632832, lng: 21.6228184 },
    { lat: 49.1633991, lng: 21.6230644 },
    { lat: 49.1634142, lng: 21.6233237 },
    { lat: 49.1635114, lng: 21.6236705 },
    { lat: 49.1635931, lng: 21.6237789 },
    { lat: 49.1636953, lng: 21.6238496 },
    { lat: 49.163869, lng: 21.6238317 },
    { lat: 49.1638938, lng: 21.6239524 },
    { lat: 49.1640776, lng: 21.6239697 },
    { lat: 49.1646111, lng: 21.624477 },
    { lat: 49.1647051, lng: 21.6244549 },
    { lat: 49.1647986, lng: 21.6247005 },
    { lat: 49.165001, lng: 21.6247401 },
    { lat: 49.1650228, lng: 21.624839 },
    { lat: 49.1652344, lng: 21.6247593 },
    { lat: 49.1654175, lng: 21.6247915 },
    { lat: 49.1658735, lng: 21.6251366 },
    { lat: 49.1660113, lng: 21.6251523 },
    { lat: 49.1661832, lng: 21.6253502 },
    { lat: 49.16637, lng: 21.6257225 },
    { lat: 49.1665183, lng: 21.6262038 },
    { lat: 49.1666612, lng: 21.6272282 },
    { lat: 49.1669213, lng: 21.627858 },
    { lat: 49.1669144, lng: 21.6281414 },
    { lat: 49.1670419, lng: 21.6282328 },
    { lat: 49.1671628, lng: 21.6286615 },
    { lat: 49.1673351, lng: 21.6287922 },
    { lat: 49.1674908, lng: 21.6291809 },
    { lat: 49.1676621, lng: 21.6294368 },
    { lat: 49.1677675, lng: 21.6297482 },
    { lat: 49.1678334, lng: 21.6303882 },
    { lat: 49.1680463, lng: 21.6306069 },
    { lat: 49.1682595, lng: 21.6309532 },
    { lat: 49.1686649, lng: 21.6317495 },
    { lat: 49.169063, lng: 21.6320312 },
    { lat: 49.1694429, lng: 21.6325189 },
    { lat: 49.1696895, lng: 21.6325618 },
  ],
  Varechovce: [
    { lat: 49.2426997, lng: 21.8092522 },
    { lat: 49.2423337, lng: 21.8087534 },
    { lat: 49.2401352, lng: 21.8066911 },
    { lat: 49.2398734, lng: 21.8063448 },
    { lat: 49.2392088, lng: 21.8049183 },
    { lat: 49.2388768, lng: 21.8023466 },
    { lat: 49.2388185, lng: 21.8016589 },
    { lat: 49.2388292, lng: 21.8012551 },
    { lat: 49.2382456, lng: 21.801128 },
    { lat: 49.2375087, lng: 21.8006881 },
    { lat: 49.2366214, lng: 21.8000031 },
    { lat: 49.2367592, lng: 21.7985905 },
    { lat: 49.2373827, lng: 21.7987357 },
    { lat: 49.2373799, lng: 21.7977553 },
    { lat: 49.2371116, lng: 21.7963443 },
    { lat: 49.2369754, lng: 21.792363 },
    { lat: 49.2370665, lng: 21.7916228 },
    { lat: 49.2374977, lng: 21.7899945 },
    { lat: 49.2375636, lng: 21.7893274 },
    { lat: 49.2376529, lng: 21.7869617 },
    { lat: 49.2375345, lng: 21.7847043 },
    { lat: 49.2370903, lng: 21.7841478 },
    { lat: 49.2367418, lng: 21.7838529 },
    { lat: 49.2363251, lng: 21.7837091 },
    { lat: 49.2357677, lng: 21.7827811 },
    { lat: 49.2355125, lng: 21.7821315 },
    { lat: 49.2338691, lng: 21.779846 },
    { lat: 49.2337425, lng: 21.7796104 },
    { lat: 49.2335847, lng: 21.7790355 },
    { lat: 49.2335863, lng: 21.7790149 },
    { lat: 49.2332228, lng: 21.7789146 },
    { lat: 49.2325847, lng: 21.7792939 },
    { lat: 49.2314758, lng: 21.779261 },
    { lat: 49.2303233, lng: 21.7793661 },
    { lat: 49.2300975, lng: 21.7792038 },
    { lat: 49.2296719, lng: 21.7794071 },
    { lat: 49.2286087, lng: 21.7805109 },
    { lat: 49.2285166, lng: 21.7795732 },
    { lat: 49.2283955, lng: 21.7790252 },
    { lat: 49.2281192, lng: 21.7784356 },
    { lat: 49.228031, lng: 21.7770802 },
    { lat: 49.2275336, lng: 21.7747056 },
    { lat: 49.2267763, lng: 21.7738787 },
    { lat: 49.2265915, lng: 21.7735703 },
    { lat: 49.2264194, lng: 21.7734786 },
    { lat: 49.2263116, lng: 21.773315 },
    { lat: 49.2261362, lng: 21.7732328 },
    { lat: 49.2260912, lng: 21.7731082 },
    { lat: 49.2259339, lng: 21.7730677 },
    { lat: 49.2259085, lng: 21.7727004 },
    { lat: 49.2258716, lng: 21.7725321 },
    { lat: 49.2258053, lng: 21.7724512 },
    { lat: 49.2256013, lng: 21.7712288 },
    { lat: 49.2254384, lng: 21.7710196 },
    { lat: 49.2252099, lng: 21.7704142 },
    { lat: 49.2248781, lng: 21.7702002 },
    { lat: 49.2248029, lng: 21.7697405 },
    { lat: 49.2246286, lng: 21.7695208 },
    { lat: 49.2245933, lng: 21.7692901 },
    { lat: 49.2244644, lng: 21.7692278 },
    { lat: 49.2243519, lng: 21.7688824 },
    { lat: 49.2243495, lng: 21.7686587 },
    { lat: 49.2240398, lng: 21.7680921 },
    { lat: 49.2239773, lng: 21.7677673 },
    { lat: 49.2237262, lng: 21.7677096 },
    { lat: 49.2236637, lng: 21.7675529 },
    { lat: 49.2234564, lng: 21.7673363 },
    { lat: 49.2232945, lng: 21.7672894 },
    { lat: 49.2232778, lng: 21.7673811 },
    { lat: 49.2231924, lng: 21.7674331 },
    { lat: 49.2230583, lng: 21.7680628 },
    { lat: 49.2230587, lng: 21.7683405 },
    { lat: 49.2226648, lng: 21.7687615 },
    { lat: 49.2222387, lng: 21.7689373 },
    { lat: 49.2220585, lng: 21.7691498 },
    { lat: 49.221797, lng: 21.7688203 },
    { lat: 49.2208019, lng: 21.7684978 },
    { lat: 49.2204425, lng: 21.7686453 },
    { lat: 49.2202055, lng: 21.7686602 },
    { lat: 49.2195253, lng: 21.7690133 },
    { lat: 49.2190037, lng: 21.7693751 },
    { lat: 49.2186418, lng: 21.769254 },
    { lat: 49.2180921, lng: 21.7693534 },
    { lat: 49.2179428, lng: 21.769279 },
    { lat: 49.2176671, lng: 21.769406 },
    { lat: 49.2173578, lng: 21.7688937 },
    { lat: 49.2172221, lng: 21.7691629 },
    { lat: 49.2170193, lng: 21.7690267 },
    { lat: 49.216746, lng: 21.7693294 },
    { lat: 49.2166539, lng: 21.769285 },
    { lat: 49.2165473, lng: 21.7689961 },
    { lat: 49.2161964, lng: 21.769123 },
    { lat: 49.2161356, lng: 21.7692947 },
    { lat: 49.2159998, lng: 21.7694397 },
    { lat: 49.2158553, lng: 21.7692514 },
    { lat: 49.2157299, lng: 21.7692684 },
    { lat: 49.2156279, lng: 21.7695412 },
    { lat: 49.2155656, lng: 21.7701218 },
    { lat: 49.2155637, lng: 21.7711298 },
    { lat: 49.2156417, lng: 21.7715719 },
    { lat: 49.215475, lng: 21.7722553 },
    { lat: 49.2153131, lng: 21.7735421 },
    { lat: 49.215367, lng: 21.7740088 },
    { lat: 49.2153278, lng: 21.7744476 },
    { lat: 49.2154532, lng: 21.7751171 },
    { lat: 49.2156204, lng: 21.7756582 },
    { lat: 49.2155464, lng: 21.7758858 },
    { lat: 49.2156079, lng: 21.7761735 },
    { lat: 49.2155007, lng: 21.7764984 },
    { lat: 49.2148964, lng: 21.7771451 },
    { lat: 49.2144103, lng: 21.7774402 },
    { lat: 49.21406, lng: 21.7775468 },
    { lat: 49.2135447, lng: 21.7779319 },
    { lat: 49.2127126, lng: 21.7782833 },
    { lat: 49.2113378, lng: 21.7815496 },
    { lat: 49.2111175, lng: 21.7817781 },
    { lat: 49.2106414, lng: 21.7824572 },
    { lat: 49.2098505, lng: 21.7830046 },
    { lat: 49.2092708, lng: 21.7836432 },
    { lat: 49.2087569, lng: 21.7840773 },
    { lat: 49.207394, lng: 21.7856 },
    { lat: 49.207744, lng: 21.786912 },
    { lat: 49.208192, lng: 21.788347 },
    { lat: 49.208365, lng: 21.789322 },
    { lat: 49.208434, lng: 21.790084 },
    { lat: 49.208568, lng: 21.791173 },
    { lat: 49.208588, lng: 21.792016 },
    { lat: 49.208529, lng: 21.792284 },
    { lat: 49.208483, lng: 21.792728 },
    { lat: 49.208574, lng: 21.792776 },
    { lat: 49.208758, lng: 21.793128 },
    { lat: 49.208901, lng: 21.793756 },
    { lat: 49.208905, lng: 21.794012 },
    { lat: 49.209047, lng: 21.794897 },
    { lat: 49.209138, lng: 21.795698 },
    { lat: 49.209106, lng: 21.795886 },
    { lat: 49.209051, lng: 21.796122 },
    { lat: 49.20895, lng: 21.797165 },
    { lat: 49.208734, lng: 21.797484 },
    { lat: 49.20889, lng: 21.799296 },
    { lat: 49.209241, lng: 21.800413 },
    { lat: 49.209221, lng: 21.800942 },
    { lat: 49.209554, lng: 21.802061 },
    { lat: 49.209897, lng: 21.80291 },
    { lat: 49.21037, lng: 21.80369 },
    { lat: 49.210737, lng: 21.804651 },
    { lat: 49.21059, lng: 21.806149 },
    { lat: 49.210556, lng: 21.806349 },
    { lat: 49.210553, lng: 21.80637 },
    { lat: 49.210246, lng: 21.807075 },
    { lat: 49.210215, lng: 21.807238 },
    { lat: 49.210243, lng: 21.807463 },
    { lat: 49.210147, lng: 21.807764 },
    { lat: 49.210129, lng: 21.808013 },
    { lat: 49.210063, lng: 21.808067 },
    { lat: 49.210098, lng: 21.808386 },
    { lat: 49.210057, lng: 21.808618 },
    { lat: 49.20996, lng: 21.808695 },
    { lat: 49.209961, lng: 21.808907 },
    { lat: 49.210026, lng: 21.809333 },
    { lat: 49.209892, lng: 21.809882 },
    { lat: 49.209686, lng: 21.810261 },
    { lat: 49.209651, lng: 21.810802 },
    { lat: 49.209666, lng: 21.811629 },
    { lat: 49.209677, lng: 21.812192 },
    { lat: 49.209828, lng: 21.812382 },
    { lat: 49.209839, lng: 21.812473 },
    { lat: 49.209905, lng: 21.812488 },
    { lat: 49.20992, lng: 21.812559 },
    { lat: 49.209624, lng: 21.81316 },
    { lat: 49.209467, lng: 21.813571 },
    { lat: 49.209353, lng: 21.813689 },
    { lat: 49.209343, lng: 21.813821 },
    { lat: 49.208981, lng: 21.815007 },
    { lat: 49.208995, lng: 21.815237 },
    { lat: 49.209096, lng: 21.816873 },
    { lat: 49.209618, lng: 21.816664 },
    { lat: 49.211244, lng: 21.81662 },
    { lat: 49.211337, lng: 21.816943 },
    { lat: 49.211357, lng: 21.817424 },
    { lat: 49.211403, lng: 21.817633 },
    { lat: 49.212525, lng: 21.818324 },
    { lat: 49.212514, lng: 21.818098 },
    { lat: 49.212542, lng: 21.817715 },
    { lat: 49.21374, lng: 21.817702 },
    { lat: 49.213774, lng: 21.817724 },
    { lat: 49.216773, lng: 21.816283 },
    { lat: 49.217045, lng: 21.817674 },
    { lat: 49.217019, lng: 21.817874 },
    { lat: 49.217568, lng: 21.817751 },
    { lat: 49.217691, lng: 21.817647 },
    { lat: 49.217731, lng: 21.817293 },
    { lat: 49.217926, lng: 21.817171 },
    { lat: 49.218042, lng: 21.817291 },
    { lat: 49.218226, lng: 21.817097 },
    { lat: 49.218402, lng: 21.81716 },
    { lat: 49.21843, lng: 21.817327 },
    { lat: 49.218679, lng: 21.817373 },
    { lat: 49.218762, lng: 21.817237 },
    { lat: 49.21887, lng: 21.81731 },
    { lat: 49.218971, lng: 21.817782 },
    { lat: 49.219075, lng: 21.817858 },
    { lat: 49.219166, lng: 21.818273 },
    { lat: 49.219485, lng: 21.818404 },
    { lat: 49.219817, lng: 21.819251 },
    { lat: 49.219689, lng: 21.81961 },
    { lat: 49.219735, lng: 21.820324 },
    { lat: 49.219866, lng: 21.82048 },
    { lat: 49.219831, lng: 21.820634 },
    { lat: 49.220063, lng: 21.821086 },
    { lat: 49.219981, lng: 21.82122 },
    { lat: 49.219975, lng: 21.821694 },
    { lat: 49.219816, lng: 21.822099 },
    { lat: 49.219569, lng: 21.822226 },
    { lat: 49.219832, lng: 21.823172 },
    { lat: 49.220212, lng: 21.823736 },
    { lat: 49.220738, lng: 21.824099 },
    { lat: 49.220958, lng: 21.823988 },
    { lat: 49.221134, lng: 21.823995 },
    { lat: 49.221006, lng: 21.824416 },
    { lat: 49.221172, lng: 21.824763 },
    { lat: 49.221639, lng: 21.824752 },
    { lat: 49.221866, lng: 21.824908 },
    { lat: 49.222069, lng: 21.824959 },
    { lat: 49.22259, lng: 21.824071 },
    { lat: 49.222742, lng: 21.824139 },
    { lat: 49.222853, lng: 21.824656 },
    { lat: 49.223, lng: 21.824636 },
    { lat: 49.223279, lng: 21.824338 },
    { lat: 49.223541, lng: 21.824071 },
    { lat: 49.224003, lng: 21.823991 },
    { lat: 49.224243, lng: 21.823852 },
    { lat: 49.224539, lng: 21.824167 },
    { lat: 49.224693, lng: 21.824261 },
    { lat: 49.224915, lng: 21.82411 },
    { lat: 49.225086, lng: 21.824185 },
    { lat: 49.225117, lng: 21.823836 },
    { lat: 49.225741, lng: 21.824024 },
    { lat: 49.226343, lng: 21.824029 },
    { lat: 49.226554, lng: 21.82415 },
    { lat: 49.226587, lng: 21.824586 },
    { lat: 49.226973, lng: 21.824437 },
    { lat: 49.226994, lng: 21.823984 },
    { lat: 49.227577, lng: 21.824114 },
    { lat: 49.227631, lng: 21.824256 },
    { lat: 49.227809, lng: 21.824284 },
    { lat: 49.228023, lng: 21.823784 },
    { lat: 49.228366, lng: 21.824095 },
    { lat: 49.228432, lng: 21.824085 },
    { lat: 49.228456, lng: 21.823668 },
    { lat: 49.228642, lng: 21.82356 },
    { lat: 49.2287, lng: 21.82374 },
    { lat: 49.228889, lng: 21.823638 },
    { lat: 49.229043, lng: 21.823672 },
    { lat: 49.229086, lng: 21.823785 },
    { lat: 49.229348, lng: 21.823713 },
    { lat: 49.2295, lng: 21.823846 },
    { lat: 49.229467, lng: 21.82403 },
    { lat: 49.229563, lng: 21.824124 },
    { lat: 49.229661, lng: 21.823894 },
    { lat: 49.230074, lng: 21.824461 },
    { lat: 49.2306578, lng: 21.8234944 },
    { lat: 49.230655, lng: 21.8228154 },
    { lat: 49.2315799, lng: 21.8229967 },
    { lat: 49.2318521, lng: 21.8225186 },
    { lat: 49.2324798, lng: 21.8226508 },
    { lat: 49.2326155, lng: 21.8218977 },
    { lat: 49.2330786, lng: 21.8218368 },
    { lat: 49.2344117, lng: 21.819438 },
    { lat: 49.2348538, lng: 21.8193143 },
    { lat: 49.2350592, lng: 21.8188625 },
    { lat: 49.2345454, lng: 21.8181227 },
    { lat: 49.2349534, lng: 21.8176371 },
    { lat: 49.2363332, lng: 21.8169668 },
    { lat: 49.2368386, lng: 21.8168889 },
    { lat: 49.236756, lng: 21.8162632 },
    { lat: 49.2372998, lng: 21.8158061 },
    { lat: 49.2372151, lng: 21.8155725 },
    { lat: 49.240488, lng: 21.813377 },
    { lat: 49.2425834, lng: 21.8104069 },
    { lat: 49.2426997, lng: 21.8092522 },
  ],
  Havaj: [
    { lat: 49.2814264, lng: 21.785479 },
    { lat: 49.2809694, lng: 21.7850841 },
    { lat: 49.2796582, lng: 21.7846598 },
    { lat: 49.2792165, lng: 21.7842439 },
    { lat: 49.2785391, lng: 21.7833265 },
    { lat: 49.2783708, lng: 21.7826933 },
    { lat: 49.278035, lng: 21.781982 },
    { lat: 49.2780598, lng: 21.781675 },
    { lat: 49.278177, lng: 21.7812187 },
    { lat: 49.2780453, lng: 21.7807295 },
    { lat: 49.2778434, lng: 21.7803632 },
    { lat: 49.2766304, lng: 21.7789683 },
    { lat: 49.2764832, lng: 21.7787561 },
    { lat: 49.2763709, lng: 21.7783368 },
    { lat: 49.2760656, lng: 21.7781435 },
    { lat: 49.2756505, lng: 21.7781912 },
    { lat: 49.2750493, lng: 21.7783841 },
    { lat: 49.2747569, lng: 21.7782968 },
    { lat: 49.2744968, lng: 21.7780284 },
    { lat: 49.2745233, lng: 21.7774111 },
    { lat: 49.2746727, lng: 21.7769676 },
    { lat: 49.2748332, lng: 21.7766938 },
    { lat: 49.274889, lng: 21.7764209 },
    { lat: 49.2746774, lng: 21.7753394 },
    { lat: 49.2745085, lng: 21.7750291 },
    { lat: 49.274513, lng: 21.7744254 },
    { lat: 49.2741356, lng: 21.7747464 },
    { lat: 49.2740492, lng: 21.7747167 },
    { lat: 49.2735432, lng: 21.773905 },
    { lat: 49.273634, lng: 21.7737175 },
    { lat: 49.2734679, lng: 21.7733854 },
    { lat: 49.2729519, lng: 21.7729238 },
    { lat: 49.2727098, lng: 21.7728244 },
    { lat: 49.2725338, lng: 21.7725473 },
    { lat: 49.2724819, lng: 21.77233 },
    { lat: 49.2723361, lng: 21.7721736 },
    { lat: 49.2720125, lng: 21.7720089 },
    { lat: 49.2720392, lng: 21.7719604 },
    { lat: 49.271953, lng: 21.7717168 },
    { lat: 49.2722829, lng: 21.7713451 },
    { lat: 49.272049, lng: 21.7707481 },
    { lat: 49.2716873, lng: 21.7702475 },
    { lat: 49.2705636, lng: 21.7711551 },
    { lat: 49.2703253, lng: 21.7710731 },
    { lat: 49.2701965, lng: 21.7709152 },
    { lat: 49.2695877, lng: 21.7696629 },
    { lat: 49.2695346, lng: 21.7699133 },
    { lat: 49.2691534, lng: 21.7699571 },
    { lat: 49.2688018, lng: 21.7696796 },
    { lat: 49.2682191, lng: 21.7689397 },
    { lat: 49.2681453, lng: 21.7688776 },
    { lat: 49.2680029, lng: 21.7689724 },
    { lat: 49.2678405, lng: 21.7687485 },
    { lat: 49.2675809, lng: 21.7685814 },
    { lat: 49.2674687, lng: 21.7682929 },
    { lat: 49.267478, lng: 21.7679224 },
    { lat: 49.2673649, lng: 21.7675886 },
    { lat: 49.2671984, lng: 21.7673341 },
    { lat: 49.2669902, lng: 21.7672045 },
    { lat: 49.2667334, lng: 21.7668856 },
    { lat: 49.2666937, lng: 21.7664032 },
    { lat: 49.2665243, lng: 21.766119 },
    { lat: 49.2657979, lng: 21.76432 },
    { lat: 49.2652867, lng: 21.7646042 },
    { lat: 49.2648846, lng: 21.7645398 },
    { lat: 49.264597, lng: 21.7646021 },
    { lat: 49.2643321, lng: 21.7644969 },
    { lat: 49.2640211, lng: 21.7645087 },
    { lat: 49.2632777, lng: 21.76486 },
    { lat: 49.2631505, lng: 21.7648537 },
    { lat: 49.2630114, lng: 21.7649624 },
    { lat: 49.2625562, lng: 21.7647997 },
    { lat: 49.2614126, lng: 21.7646828 },
    { lat: 49.2611164, lng: 21.764841 },
    { lat: 49.2610317, lng: 21.7648285 },
    { lat: 49.2602215, lng: 21.7654662 },
    { lat: 49.2597419, lng: 21.7656659 },
    { lat: 49.2594995, lng: 21.7656785 },
    { lat: 49.2587061, lng: 21.7660126 },
    { lat: 49.2584105, lng: 21.766191 },
    { lat: 49.2576034, lng: 21.766855 },
    { lat: 49.2563114, lng: 21.7661861 },
    { lat: 49.2552324, lng: 21.7668675 },
    { lat: 49.2547472, lng: 21.7680066 },
    { lat: 49.2546597, lng: 21.768102 },
    { lat: 49.2546234, lng: 21.7682644 },
    { lat: 49.2543131, lng: 21.7686093 },
    { lat: 49.2538197, lng: 21.7688432 },
    { lat: 49.2534784, lng: 21.7689159 },
    { lat: 49.2531677, lng: 21.7691222 },
    { lat: 49.2528241, lng: 21.7691874 },
    { lat: 49.2526018, lng: 21.7694095 },
    { lat: 49.2524975, lng: 21.7692996 },
    { lat: 49.2523347, lng: 21.7693535 },
    { lat: 49.251686, lng: 21.7691489 },
    { lat: 49.2513327, lng: 21.7693635 },
    { lat: 49.2512669, lng: 21.7698583 },
    { lat: 49.2507121, lng: 21.7699779 },
    { lat: 49.2501157, lng: 21.7697504 },
    { lat: 49.2500887, lng: 21.7697448 },
    { lat: 49.2496957, lng: 21.7703381 },
    { lat: 49.2492759, lng: 21.7710133 },
    { lat: 49.2493066, lng: 21.7719702 },
    { lat: 49.2494304, lng: 21.7721438 },
    { lat: 49.249353, lng: 21.7725842 },
    { lat: 49.2491803, lng: 21.7730822 },
    { lat: 49.2487505, lng: 21.7737198 },
    { lat: 49.2482486, lng: 21.7740122 },
    { lat: 49.2478784, lng: 21.7744405 },
    { lat: 49.2475297, lng: 21.7747437 },
    { lat: 49.2472462, lng: 21.7748733 },
    { lat: 49.2471439, lng: 21.7749984 },
    { lat: 49.2462374, lng: 21.7749186 },
    { lat: 49.2457645, lng: 21.775288 },
    { lat: 49.2455515, lng: 21.7753637 },
    { lat: 49.2452238, lng: 21.7757736 },
    { lat: 49.2450884, lng: 21.7758416 },
    { lat: 49.2447573, lng: 21.7763057 },
    { lat: 49.2441968, lng: 21.7767654 },
    { lat: 49.2429193, lng: 21.778024 },
    { lat: 49.2425559, lng: 21.778152 },
    { lat: 49.2420264, lng: 21.7785543 },
    { lat: 49.2418331, lng: 21.7786435 },
    { lat: 49.241139, lng: 21.7787308 },
    { lat: 49.2409132, lng: 21.7783971 },
    { lat: 49.2404465, lng: 21.7784399 },
    { lat: 49.2397992, lng: 21.778078 },
    { lat: 49.2391389, lng: 21.7782096 },
    { lat: 49.2383902, lng: 21.7780997 },
    { lat: 49.2382138, lng: 21.7782321 },
    { lat: 49.2380607, lng: 21.7784416 },
    { lat: 49.2375988, lng: 21.7785906 },
    { lat: 49.2364218, lng: 21.7778812 },
    { lat: 49.2355882, lng: 21.7778329 },
    { lat: 49.2349741, lng: 21.7773592 },
    { lat: 49.2346039, lng: 21.7769779 },
    { lat: 49.2341543, lng: 21.7769811 },
    { lat: 49.2338258, lng: 21.7770314 },
    { lat: 49.2337468, lng: 21.7771013 },
    { lat: 49.2335863, lng: 21.7790149 },
    { lat: 49.2335847, lng: 21.7790355 },
    { lat: 49.2337425, lng: 21.7796104 },
    { lat: 49.2338691, lng: 21.779846 },
    { lat: 49.2355125, lng: 21.7821315 },
    { lat: 49.2357677, lng: 21.7827811 },
    { lat: 49.2363251, lng: 21.7837091 },
    { lat: 49.2367418, lng: 21.7838529 },
    { lat: 49.2370903, lng: 21.7841478 },
    { lat: 49.2375345, lng: 21.7847043 },
    { lat: 49.2376529, lng: 21.7869617 },
    { lat: 49.2375636, lng: 21.7893274 },
    { lat: 49.2374977, lng: 21.7899945 },
    { lat: 49.2370665, lng: 21.7916228 },
    { lat: 49.2369754, lng: 21.792363 },
    { lat: 49.2371116, lng: 21.7963443 },
    { lat: 49.2373799, lng: 21.7977553 },
    { lat: 49.2373827, lng: 21.7987357 },
    { lat: 49.2367592, lng: 21.7985905 },
    { lat: 49.2366214, lng: 21.8000031 },
    { lat: 49.2375087, lng: 21.8006881 },
    { lat: 49.2382456, lng: 21.801128 },
    { lat: 49.2388292, lng: 21.8012551 },
    { lat: 49.2388185, lng: 21.8016589 },
    { lat: 49.2388768, lng: 21.8023466 },
    { lat: 49.2392088, lng: 21.8049183 },
    { lat: 49.2398734, lng: 21.8063448 },
    { lat: 49.2401352, lng: 21.8066911 },
    { lat: 49.2423337, lng: 21.8087534 },
    { lat: 49.2426997, lng: 21.8092522 },
    { lat: 49.2434501, lng: 21.8086926 },
    { lat: 49.2436288, lng: 21.8092502 },
    { lat: 49.244013, lng: 21.8090154 },
    { lat: 49.2440553, lng: 21.809254 },
    { lat: 49.2442445, lng: 21.8091789 },
    { lat: 49.2443721, lng: 21.8092839 },
    { lat: 49.245909, lng: 21.8083837 },
    { lat: 49.2466251, lng: 21.8076727 },
    { lat: 49.2467719, lng: 21.808044 },
    { lat: 49.2469688, lng: 21.8083029 },
    { lat: 49.2474476, lng: 21.8085088 },
    { lat: 49.2486643, lng: 21.8083688 },
    { lat: 49.2493368, lng: 21.8087552 },
    { lat: 49.250119, lng: 21.8085057 },
    { lat: 49.2508175, lng: 21.8101602 },
    { lat: 49.2512993, lng: 21.8106525 },
    { lat: 49.2513665, lng: 21.8106478 },
    { lat: 49.2519898, lng: 21.8095635 },
    { lat: 49.2519823, lng: 21.8101037 },
    { lat: 49.2519171, lng: 21.8104544 },
    { lat: 49.2520716, lng: 21.8109884 },
    { lat: 49.2523407, lng: 21.8114338 },
    { lat: 49.2521977, lng: 21.8116898 },
    { lat: 49.2522403, lng: 21.8118183 },
    { lat: 49.2523435, lng: 21.8118516 },
    { lat: 49.2523587, lng: 21.8120855 },
    { lat: 49.2524265, lng: 21.8121466 },
    { lat: 49.2524586, lng: 21.8123848 },
    { lat: 49.2525293, lng: 21.8124216 },
    { lat: 49.2524285, lng: 21.8125713 },
    { lat: 49.2525024, lng: 21.8126843 },
    { lat: 49.2524872, lng: 21.8128454 },
    { lat: 49.2525774, lng: 21.812934 },
    { lat: 49.2525223, lng: 21.8130615 },
    { lat: 49.2525967, lng: 21.8134265 },
    { lat: 49.2524941, lng: 21.8135849 },
    { lat: 49.2525644, lng: 21.8136545 },
    { lat: 49.2526316, lng: 21.8136001 },
    { lat: 49.2526556, lng: 21.8140923 },
    { lat: 49.2528414, lng: 21.8144498 },
    { lat: 49.252874, lng: 21.8147096 },
    { lat: 49.2529719, lng: 21.8149781 },
    { lat: 49.2531811, lng: 21.8149724 },
    { lat: 49.2533641, lng: 21.8151788 },
    { lat: 49.2535013, lng: 21.8150888 },
    { lat: 49.2536149, lng: 21.8151781 },
    { lat: 49.253878, lng: 21.8151245 },
    { lat: 49.2541396, lng: 21.815327 },
    { lat: 49.2545234, lng: 21.8153853 },
    { lat: 49.2545637, lng: 21.8155002 },
    { lat: 49.2544975, lng: 21.8158128 },
    { lat: 49.2547497, lng: 21.8159953 },
    { lat: 49.2547337, lng: 21.8163013 },
    { lat: 49.2548447, lng: 21.8163713 },
    { lat: 49.2549972, lng: 21.816783 },
    { lat: 49.2550831, lng: 21.8167163 },
    { lat: 49.2550893, lng: 21.8170029 },
    { lat: 49.2552256, lng: 21.8172234 },
    { lat: 49.2552731, lng: 21.817456 },
    { lat: 49.2554212, lng: 21.8173492 },
    { lat: 49.2554811, lng: 21.8174514 },
    { lat: 49.2554869, lng: 21.8176765 },
    { lat: 49.2555762, lng: 21.8177417 },
    { lat: 49.2554625, lng: 21.8179923 },
    { lat: 49.2556772, lng: 21.8182491 },
    { lat: 49.2556497, lng: 21.818581 },
    { lat: 49.2558778, lng: 21.8187524 },
    { lat: 49.2563284, lng: 21.8196914 },
    { lat: 49.25677, lng: 21.8212 },
    { lat: 49.2568582, lng: 21.8216596 },
    { lat: 49.2570218, lng: 21.8219447 },
    { lat: 49.2569908, lng: 21.8220978 },
    { lat: 49.2570529, lng: 21.8222737 },
    { lat: 49.2572689, lng: 21.8224995 },
    { lat: 49.2574602, lng: 21.8230246 },
    { lat: 49.2578153, lng: 21.8237573 },
    { lat: 49.2579241, lng: 21.8242665 },
    { lat: 49.2581041, lng: 21.8245758 },
    { lat: 49.2583242, lng: 21.8254798 },
    { lat: 49.2588403, lng: 21.8261294 },
    { lat: 49.258979, lng: 21.826927 },
    { lat: 49.259247, lng: 21.826771 },
    { lat: 49.259528, lng: 21.826752 },
    { lat: 49.259924, lng: 21.826886 },
    { lat: 49.260296, lng: 21.827203 },
    { lat: 49.260624, lng: 21.827428 },
    { lat: 49.260889, lng: 21.827406 },
    { lat: 49.261047, lng: 21.827694 },
    { lat: 49.2650808, lng: 21.826178 },
    { lat: 49.2662759, lng: 21.8250612 },
    { lat: 49.2667325, lng: 21.8238787 },
    { lat: 49.2676462, lng: 21.8226641 },
    { lat: 49.2681271, lng: 21.8223824 },
    { lat: 49.2684445, lng: 21.8223072 },
    { lat: 49.2686205, lng: 21.8220189 },
    { lat: 49.2691634, lng: 21.8218318 },
    { lat: 49.2694738, lng: 21.8215199 },
    { lat: 49.2700618, lng: 21.8212969 },
    { lat: 49.2707318, lng: 21.820532 },
    { lat: 49.2711343, lng: 21.8203082 },
    { lat: 49.2712188, lng: 21.8201839 },
    { lat: 49.2711974, lng: 21.8201413 },
    { lat: 49.2717434, lng: 21.8193218 },
    { lat: 49.2718767, lng: 21.8187374 },
    { lat: 49.2721174, lng: 21.8183844 },
    { lat: 49.2725944, lng: 21.8179969 },
    { lat: 49.2732481, lng: 21.8177796 },
    { lat: 49.2737828, lng: 21.8166243 },
    { lat: 49.2739144, lng: 21.81457 },
    { lat: 49.2743001, lng: 21.8126419 },
    { lat: 49.2747342, lng: 21.8119007 },
    { lat: 49.2749245, lng: 21.810815 },
    { lat: 49.2749099, lng: 21.8087698 },
    { lat: 49.2736716, lng: 21.8073791 },
    { lat: 49.2730001, lng: 21.8048631 },
    { lat: 49.2725396, lng: 21.8039234 },
    { lat: 49.2715307, lng: 21.8024535 },
    { lat: 49.2707772, lng: 21.8016738 },
    { lat: 49.2708364, lng: 21.801484 },
    { lat: 49.2708779, lng: 21.8004805 },
    { lat: 49.2709838, lng: 21.8001863 },
    { lat: 49.2713293, lng: 21.7995372 },
    { lat: 49.27185, lng: 21.7988825 },
    { lat: 49.2719693, lng: 21.7975364 },
    { lat: 49.272272, lng: 21.7973668 },
    { lat: 49.2728629, lng: 21.7975122 },
    { lat: 49.2731737, lng: 21.7973104 },
    { lat: 49.2737479, lng: 21.7971203 },
    { lat: 49.2742098, lng: 21.7970978 },
    { lat: 49.274555, lng: 21.7972674 },
    { lat: 49.2751047, lng: 21.7971682 },
    { lat: 49.2752539, lng: 21.7973324 },
    { lat: 49.275529, lng: 21.7970506 },
    { lat: 49.2757152, lng: 21.7967216 },
    { lat: 49.2757819, lng: 21.7962587 },
    { lat: 49.275987, lng: 21.7957498 },
    { lat: 49.2768941, lng: 21.7950821 },
    { lat: 49.2772898, lng: 21.7945901 },
    { lat: 49.277373, lng: 21.7942439 },
    { lat: 49.2779838, lng: 21.7940765 },
    { lat: 49.2787114, lng: 21.7934581 },
    { lat: 49.2787233, lng: 21.7925712 },
    { lat: 49.2794527, lng: 21.7901275 },
    { lat: 49.2801602, lng: 21.7886532 },
    { lat: 49.2808901, lng: 21.7875248 },
    { lat: 49.2810835, lng: 21.7870494 },
    { lat: 49.2812218, lng: 21.7862217 },
    { lat: 49.2814264, lng: 21.785479 },
  ],
  Bukovce: [
    { lat: 49.2754078, lng: 21.6851227 },
    { lat: 49.2752228, lng: 21.6850212 },
    { lat: 49.2749616, lng: 21.6848424 },
    { lat: 49.2741868, lng: 21.68481 },
    { lat: 49.2735475, lng: 21.6844668 },
    { lat: 49.2728508, lng: 21.6833531 },
    { lat: 49.272409, lng: 21.6824918 },
    { lat: 49.2722332, lng: 21.6823105 },
    { lat: 49.2713457, lng: 21.6820229 },
    { lat: 49.271008, lng: 21.6817603 },
    { lat: 49.2701858, lng: 21.6817608 },
    { lat: 49.2696125, lng: 21.6814002 },
    { lat: 49.269334, lng: 21.6813781 },
    { lat: 49.2687015, lng: 21.6809917 },
    { lat: 49.268375, lng: 21.6809809 },
    { lat: 49.267923, lng: 21.6811487 },
    { lat: 49.2676719, lng: 21.6810165 },
    { lat: 49.2676409, lng: 21.6809954 },
    { lat: 49.267744, lng: 21.6814006 },
    { lat: 49.2676555, lng: 21.6816587 },
    { lat: 49.2676408, lng: 21.6819989 },
    { lat: 49.2676033, lng: 21.6820672 },
    { lat: 49.2674821, lng: 21.6820301 },
    { lat: 49.2672575, lng: 21.6820864 },
    { lat: 49.2671427, lng: 21.682206 },
    { lat: 49.267009, lng: 21.6822144 },
    { lat: 49.2664943, lng: 21.6824906 },
    { lat: 49.2663707, lng: 21.6826169 },
    { lat: 49.2661358, lng: 21.6827116 },
    { lat: 49.2656666, lng: 21.6826172 },
    { lat: 49.2655424, lng: 21.6825241 },
    { lat: 49.2652029, lng: 21.682951 },
    { lat: 49.2650583, lng: 21.682919 },
    { lat: 49.2648738, lng: 21.6827127 },
    { lat: 49.2647673, lng: 21.6826852 },
    { lat: 49.264317, lng: 21.682676 },
    { lat: 49.2637713, lng: 21.6829199 },
    { lat: 49.263498, lng: 21.6829612 },
    { lat: 49.2632774, lng: 21.6828731 },
    { lat: 49.2630718, lng: 21.6828788 },
    { lat: 49.2624292, lng: 21.6834134 },
    { lat: 49.2620986, lng: 21.6838361 },
    { lat: 49.261896, lng: 21.6838337 },
    { lat: 49.2612197, lng: 21.6844128 },
    { lat: 49.2610233, lng: 21.6846804 },
    { lat: 49.2609803, lng: 21.6849122 },
    { lat: 49.2610164, lng: 21.6854682 },
    { lat: 49.2608549, lng: 21.6857718 },
    { lat: 49.2604173, lng: 21.6861566 },
    { lat: 49.2599079, lng: 21.686329 },
    { lat: 49.2588578, lng: 21.6875149 },
    { lat: 49.2585234, lng: 21.687721 },
    { lat: 49.2582065, lng: 21.6878122 },
    { lat: 49.2577499, lng: 21.6881549 },
    { lat: 49.2572432, lng: 21.6883876 },
    { lat: 49.2569504, lng: 21.6887466 },
    { lat: 49.2567393, lng: 21.6893447 },
    { lat: 49.2567065, lng: 21.6896763 },
    { lat: 49.2573848, lng: 21.690551 },
    { lat: 49.2581538, lng: 21.6908352 },
    { lat: 49.2582662, lng: 21.6914148 },
    { lat: 49.2574754, lng: 21.6920928 },
    { lat: 49.2575115, lng: 21.6927193 },
    { lat: 49.2575269, lng: 21.692834 },
    { lat: 49.2575035, lng: 21.6930544 },
    { lat: 49.2599917, lng: 21.693763 },
    { lat: 49.2598747, lng: 21.6941812 },
    { lat: 49.2603983, lng: 21.696305 },
    { lat: 49.2603103, lng: 21.6968396 },
    { lat: 49.2601082, lng: 21.6974436 },
    { lat: 49.2596423, lng: 21.6981498 },
    { lat: 49.2594148, lng: 21.6992343 },
    { lat: 49.2588016, lng: 21.7015811 },
    { lat: 49.2583355, lng: 21.703958 },
    { lat: 49.2579709, lng: 21.7043311 },
    { lat: 49.2563694, lng: 21.7081421 },
    { lat: 49.2562715, lng: 21.7082645 },
    { lat: 49.2558756, lng: 21.70839 },
    { lat: 49.255555, lng: 21.7090272 },
    { lat: 49.2554328, lng: 21.7090395 },
    { lat: 49.2541918, lng: 21.7113937 },
    { lat: 49.2536987, lng: 21.7128968 },
    { lat: 49.2533609, lng: 21.7136063 },
    { lat: 49.2532423, lng: 21.7145485 },
    { lat: 49.2530792, lng: 21.715142 },
    { lat: 49.2529746, lng: 21.7165014 },
    { lat: 49.2528277, lng: 21.7172782 },
    { lat: 49.252843, lng: 21.7184447 },
    { lat: 49.252639, lng: 21.7186813 },
    { lat: 49.2523573, lng: 21.7191669 },
    { lat: 49.2514262, lng: 21.720027 },
    { lat: 49.2511163, lng: 21.7203858 },
    { lat: 49.2507665, lng: 21.7209786 },
    { lat: 49.2506731, lng: 21.721356 },
    { lat: 49.2506389, lng: 21.7217221 },
    { lat: 49.25064, lng: 21.7224876 },
    { lat: 49.2509099, lng: 21.7235824 },
    { lat: 49.2503123, lng: 21.7241185 },
    { lat: 49.249481, lng: 21.7252682 },
    { lat: 49.2482706, lng: 21.7271331 },
    { lat: 49.247798, lng: 21.7276768 },
    { lat: 49.247369, lng: 21.7284231 },
    { lat: 49.2467385, lng: 21.7299272 },
    { lat: 49.2467111, lng: 21.73131 },
    { lat: 49.2463086, lng: 21.7320446 },
    { lat: 49.2461108, lng: 21.7334509 },
    { lat: 49.2463787, lng: 21.7340082 },
    { lat: 49.2464907, lng: 21.7336056 },
    { lat: 49.2467138, lng: 21.7331572 },
    { lat: 49.247269, lng: 21.7324594 },
    { lat: 49.2476686, lng: 21.7317477 },
    { lat: 49.2480215, lng: 21.7309755 },
    { lat: 49.2480663, lng: 21.7307546 },
    { lat: 49.2482602, lng: 21.7306 },
    { lat: 49.2484472, lng: 21.7300969 },
    { lat: 49.2488562, lng: 21.7293591 },
    { lat: 49.2497562, lng: 21.7291925 },
    { lat: 49.2501318, lng: 21.7288139 },
    { lat: 49.2503854, lng: 21.7289544 },
    { lat: 49.2504372, lng: 21.7288281 },
    { lat: 49.2508018, lng: 21.7284966 },
    { lat: 49.2512757, lng: 21.7285097 },
    { lat: 49.2514748, lng: 21.7282594 },
    { lat: 49.2515632, lng: 21.7282395 },
    { lat: 49.2517729, lng: 21.7284246 },
    { lat: 49.2519995, lng: 21.7281517 },
    { lat: 49.2520542, lng: 21.7280006 },
    { lat: 49.2522072, lng: 21.7280027 },
    { lat: 49.2525093, lng: 21.7276426 },
    { lat: 49.252662, lng: 21.7275507 },
    { lat: 49.2528822, lng: 21.7275443 },
    { lat: 49.2529786, lng: 21.7279517 },
    { lat: 49.2532408, lng: 21.7277882 },
    { lat: 49.253359, lng: 21.7274906 },
    { lat: 49.2538371, lng: 21.727322 },
    { lat: 49.2539281, lng: 21.7274021 },
    { lat: 49.2541313, lng: 21.7273881 },
    { lat: 49.2543219, lng: 21.7274915 },
    { lat: 49.2547831, lng: 21.7274961 },
    { lat: 49.2550009, lng: 21.7276014 },
    { lat: 49.2552779, lng: 21.7276131 },
    { lat: 49.2553512, lng: 21.7275765 },
    { lat: 49.2555023, lng: 21.7273378 },
    { lat: 49.2557265, lng: 21.7272234 },
    { lat: 49.2559631, lng: 21.7273272 },
    { lat: 49.2562892, lng: 21.7277493 },
    { lat: 49.2576654, lng: 21.7279755 },
    { lat: 49.2579379, lng: 21.7281171 },
    { lat: 49.2582786, lng: 21.7285314 },
    { lat: 49.2584931, lng: 21.7289842 },
    { lat: 49.2595767, lng: 21.7287871 },
    { lat: 49.2612949, lng: 21.7290225 },
    { lat: 49.2619774, lng: 21.727988 },
    { lat: 49.2621407, lng: 21.7273188 },
    { lat: 49.2627387, lng: 21.7267938 },
    { lat: 49.263331, lng: 21.7288997 },
    { lat: 49.2639558, lng: 21.729679 },
    { lat: 49.2634251, lng: 21.7308316 },
    { lat: 49.263263, lng: 21.7314665 },
    { lat: 49.2622594, lng: 21.7320097 },
    { lat: 49.2625112, lng: 21.733831 },
    { lat: 49.2614644, lng: 21.7355026 },
    { lat: 49.2611313, lng: 21.7365637 },
    { lat: 49.2611814, lng: 21.73961 },
    { lat: 49.2609434, lng: 21.7407368 },
    { lat: 49.2609071, lng: 21.7419134 },
    { lat: 49.261079, lng: 21.7421668 },
    { lat: 49.2613232, lng: 21.7428119 },
    { lat: 49.2618294, lng: 21.743022 },
    { lat: 49.2620202, lng: 21.742962 },
    { lat: 49.2633541, lng: 21.7438112 },
    { lat: 49.2632148, lng: 21.7432572 },
    { lat: 49.2632579, lng: 21.7426772 },
    { lat: 49.2648229, lng: 21.7423481 },
    { lat: 49.2650869, lng: 21.7425476 },
    { lat: 49.2660054, lng: 21.742017 },
    { lat: 49.2668533, lng: 21.7400092 },
    { lat: 49.2676654, lng: 21.7402635 },
    { lat: 49.2682441, lng: 21.7379006 },
    { lat: 49.2699062, lng: 21.7378859 },
    { lat: 49.2703259, lng: 21.7384788 },
    { lat: 49.2717014, lng: 21.7378763 },
    { lat: 49.2728085, lng: 21.738358 },
    { lat: 49.2740525, lng: 21.7380139 },
    { lat: 49.2771008, lng: 21.7375908 },
    { lat: 49.2795064, lng: 21.7382907 },
    { lat: 49.2798465, lng: 21.7380489 },
    { lat: 49.2801155, lng: 21.7377116 },
    { lat: 49.2802206, lng: 21.7377376 },
    { lat: 49.2804968, lng: 21.7374614 },
    { lat: 49.2806502, lng: 21.7372189 },
    { lat: 49.2809896, lng: 21.736958 },
    { lat: 49.2812053, lng: 21.7366899 },
    { lat: 49.2815177, lng: 21.7361248 },
    { lat: 49.281744, lng: 21.7355797 },
    { lat: 49.2820805, lng: 21.7343923 },
    { lat: 49.2821742, lng: 21.7341909 },
    { lat: 49.2821407, lng: 21.733904 },
    { lat: 49.2819047, lng: 21.7338328 },
    { lat: 49.2818853, lng: 21.7336517 },
    { lat: 49.2816508, lng: 21.7333322 },
    { lat: 49.2815775, lng: 21.7328943 },
    { lat: 49.2814357, lng: 21.732595 },
    { lat: 49.2812056, lng: 21.7316419 },
    { lat: 49.2810796, lng: 21.7315589 },
    { lat: 49.2810588, lng: 21.731367 },
    { lat: 49.2809686, lng: 21.7312278 },
    { lat: 49.2811306, lng: 21.7308935 },
    { lat: 49.2811661, lng: 21.7307011 },
    { lat: 49.2812976, lng: 21.7307365 },
    { lat: 49.281442, lng: 21.730582 },
    { lat: 49.2816585, lng: 21.7307736 },
    { lat: 49.2817387, lng: 21.7304898 },
    { lat: 49.2818661, lng: 21.7306289 },
    { lat: 49.2819227, lng: 21.730558 },
    { lat: 49.2819795, lng: 21.7302836 },
    { lat: 49.2822844, lng: 21.7304131 },
    { lat: 49.2823132, lng: 21.7305656 },
    { lat: 49.2824546, lng: 21.7305093 },
    { lat: 49.2825993, lng: 21.7302416 },
    { lat: 49.2827569, lng: 21.7302823 },
    { lat: 49.2827805, lng: 21.7302037 },
    { lat: 49.2833161, lng: 21.72872 },
    { lat: 49.283787, lng: 21.727179 },
    { lat: 49.2842947, lng: 21.7251532 },
    { lat: 49.2846357, lng: 21.7222805 },
    { lat: 49.2845049, lng: 21.7194345 },
    { lat: 49.2843365, lng: 21.7183049 },
    { lat: 49.284386, lng: 21.7179668 },
    { lat: 49.284011, lng: 21.7163831 },
    { lat: 49.2840303, lng: 21.7160551 },
    { lat: 49.2844625, lng: 21.7143686 },
    { lat: 49.2844769, lng: 21.7138549 },
    { lat: 49.285136, lng: 21.7113169 },
    { lat: 49.2848286, lng: 21.7110614 },
    { lat: 49.2854993, lng: 21.7071826 },
    { lat: 49.2846514, lng: 21.706776 },
    { lat: 49.2836765, lng: 21.7067853 },
    { lat: 49.282801, lng: 21.7045829 },
    { lat: 49.2824946, lng: 21.7032606 },
    { lat: 49.2820775, lng: 21.7024295 },
    { lat: 49.2818028, lng: 21.70238 },
    { lat: 49.281737, lng: 21.7015147 },
    { lat: 49.2815816, lng: 21.7018483 },
    { lat: 49.2815926, lng: 21.7014142 },
    { lat: 49.2811884, lng: 21.7004699 },
    { lat: 49.2809319, lng: 21.6991651 },
    { lat: 49.2784287, lng: 21.6994505 },
    { lat: 49.2754772, lng: 21.7000886 },
    { lat: 49.2753691, lng: 21.6986928 },
    { lat: 49.2752993, lng: 21.6985246 },
    { lat: 49.2754837, lng: 21.6976802 },
    { lat: 49.2754553, lng: 21.6972886 },
    { lat: 49.2756279, lng: 21.697018 },
    { lat: 49.2758508, lng: 21.6964777 },
    { lat: 49.2758167, lng: 21.6961304 },
    { lat: 49.2764519, lng: 21.6941849 },
    { lat: 49.2766886, lng: 21.6915563 },
    { lat: 49.2765126, lng: 21.6902862 },
    { lat: 49.2759923, lng: 21.688501 },
    { lat: 49.2754707, lng: 21.685791 },
    { lat: 49.2754078, lng: 21.6851227 },
  ],
  NižnáOlšava: [
    { lat: 49.1696895, lng: 21.6325618 },
    { lat: 49.1694429, lng: 21.6325189 },
    { lat: 49.169063, lng: 21.6320312 },
    { lat: 49.1686649, lng: 21.6317495 },
    { lat: 49.1682595, lng: 21.6309532 },
    { lat: 49.1680463, lng: 21.6306069 },
    { lat: 49.1678334, lng: 21.6303882 },
    { lat: 49.1677675, lng: 21.6297482 },
    { lat: 49.1676621, lng: 21.6294368 },
    { lat: 49.1674908, lng: 21.6291809 },
    { lat: 49.1673351, lng: 21.6287922 },
    { lat: 49.1671628, lng: 21.6286615 },
    { lat: 49.1670419, lng: 21.6282328 },
    { lat: 49.1669144, lng: 21.6281414 },
    { lat: 49.1669213, lng: 21.627858 },
    { lat: 49.1666612, lng: 21.6272282 },
    { lat: 49.1665183, lng: 21.6262038 },
    { lat: 49.16637, lng: 21.6257225 },
    { lat: 49.1661832, lng: 21.6253502 },
    { lat: 49.1660113, lng: 21.6251523 },
    { lat: 49.1658735, lng: 21.6251366 },
    { lat: 49.1654175, lng: 21.6247915 },
    { lat: 49.1652344, lng: 21.6247593 },
    { lat: 49.1650228, lng: 21.624839 },
    { lat: 49.165001, lng: 21.6247401 },
    { lat: 49.1647986, lng: 21.6247005 },
    { lat: 49.1647051, lng: 21.6244549 },
    { lat: 49.1646111, lng: 21.624477 },
    { lat: 49.1640776, lng: 21.6239697 },
    { lat: 49.1638938, lng: 21.6239524 },
    { lat: 49.163869, lng: 21.6238317 },
    { lat: 49.1636953, lng: 21.6238496 },
    { lat: 49.1635931, lng: 21.6237789 },
    { lat: 49.1635114, lng: 21.6236705 },
    { lat: 49.1634142, lng: 21.6233237 },
    { lat: 49.1633991, lng: 21.6230644 },
    { lat: 49.1632832, lng: 21.6228184 },
    { lat: 49.1630283, lng: 21.6225525 },
    { lat: 49.1628467, lng: 21.622531 },
    { lat: 49.1626378, lng: 21.6223391 },
    { lat: 49.1623785, lng: 21.6222374 },
    { lat: 49.1620332, lng: 21.6222829 },
    { lat: 49.1619981, lng: 21.6224386 },
    { lat: 49.1617952, lng: 21.6225436 },
    { lat: 49.1617893, lng: 21.6226112 },
    { lat: 49.1616203, lng: 21.6226029 },
    { lat: 49.1615477, lng: 21.6227089 },
    { lat: 49.1615698, lng: 21.6228157 },
    { lat: 49.1612037, lng: 21.622921 },
    { lat: 49.1612267, lng: 21.6231823 },
    { lat: 49.1610671, lng: 21.6230703 },
    { lat: 49.1611183, lng: 21.6230031 },
    { lat: 49.161107, lng: 21.6228855 },
    { lat: 49.1610438, lng: 21.6228306 },
    { lat: 49.1608658, lng: 21.6228643 },
    { lat: 49.1608028, lng: 21.6229502 },
    { lat: 49.1608288, lng: 21.6230722 },
    { lat: 49.1607836, lng: 21.6231078 },
    { lat: 49.1607479, lng: 21.622957 },
    { lat: 49.1606381, lng: 21.6230122 },
    { lat: 49.1606341, lng: 21.6232364 },
    { lat: 49.1604913, lng: 21.623358 },
    { lat: 49.1604935, lng: 21.6234377 },
    { lat: 49.1603962, lng: 21.623423 },
    { lat: 49.160356, lng: 21.6232279 },
    { lat: 49.1602684, lng: 21.6232763 },
    { lat: 49.1601185, lng: 21.6235853 },
    { lat: 49.1600081, lng: 21.6235075 },
    { lat: 49.1598811, lng: 21.623627 },
    { lat: 49.1597606, lng: 21.6235837 },
    { lat: 49.1597203, lng: 21.6237983 },
    { lat: 49.1597978, lng: 21.623917 },
    { lat: 49.1598481, lng: 21.6241873 },
    { lat: 49.1595003, lng: 21.6244335 },
    { lat: 49.1594002, lng: 21.624391 },
    { lat: 49.1593854, lng: 21.6242212 },
    { lat: 49.158965, lng: 21.6244774 },
    { lat: 49.1587392, lng: 21.6242804 },
    { lat: 49.158515, lng: 21.6241855 },
    { lat: 49.1585417, lng: 21.6239746 },
    { lat: 49.1584243, lng: 21.6239584 },
    { lat: 49.1583264, lng: 21.6238338 },
    { lat: 49.1582011, lng: 21.6234964 },
    { lat: 49.1580344, lng: 21.6235721 },
    { lat: 49.1579052, lng: 21.6241022 },
    { lat: 49.1577219, lng: 21.6241488 },
    { lat: 49.1576764, lng: 21.6243397 },
    { lat: 49.1575436, lng: 21.6245412 },
    { lat: 49.157391, lng: 21.6246311 },
    { lat: 49.1572589, lng: 21.6248167 },
    { lat: 49.1571269, lng: 21.6253862 },
    { lat: 49.157172, lng: 21.6255961 },
    { lat: 49.1571307, lng: 21.6256451 },
    { lat: 49.1568844, lng: 21.6255253 },
    { lat: 49.1566576, lng: 21.6250865 },
    { lat: 49.1564534, lng: 21.6251463 },
    { lat: 49.1564513, lng: 21.6252406 },
    { lat: 49.1561373, lng: 21.6253936 },
    { lat: 49.156142, lng: 21.6254476 },
    { lat: 49.1559329, lng: 21.6253272 },
    { lat: 49.155798, lng: 21.6254085 },
    { lat: 49.1555494, lng: 21.6252949 },
    { lat: 49.155361, lng: 21.6253515 },
    { lat: 49.1553818, lng: 21.6254127 },
    { lat: 49.1553028, lng: 21.6257308 },
    { lat: 49.1551107, lng: 21.6256978 },
    { lat: 49.1550781, lng: 21.6254526 },
    { lat: 49.1544076, lng: 21.6253333 },
    { lat: 49.1544266, lng: 21.6255786 },
    { lat: 49.154377, lng: 21.6258575 },
    { lat: 49.1542368, lng: 21.6258323 },
    { lat: 49.1541402, lng: 21.6252264 },
    { lat: 49.1529468, lng: 21.6250671 },
    { lat: 49.1528123, lng: 21.6247124 },
    { lat: 49.1525531, lng: 21.6242509 },
    { lat: 49.152471, lng: 21.6238378 },
    { lat: 49.1519347, lng: 21.6234875 },
    { lat: 49.1517247, lng: 21.6235732 },
    { lat: 49.151653, lng: 21.6237086 },
    { lat: 49.1513046, lng: 21.6236855 },
    { lat: 49.1508619, lng: 21.6237886 },
    { lat: 49.1506793, lng: 21.6234419 },
    { lat: 49.1506211, lng: 21.6230054 },
    { lat: 49.1505678, lng: 21.6230699 },
    { lat: 49.1503216, lng: 21.6221771 },
    { lat: 49.1500642, lng: 21.6215334 },
    { lat: 49.1501595, lng: 21.6207388 },
    { lat: 49.1495824, lng: 21.6198392 },
    { lat: 49.1495073, lng: 21.6191583 },
    { lat: 49.1495513, lng: 21.619075 },
    { lat: 49.1489218, lng: 21.6177464 },
    { lat: 49.148965, lng: 21.6171471 },
    { lat: 49.1488901, lng: 21.6170807 },
    { lat: 49.148805, lng: 21.616818 },
    { lat: 49.1487408, lng: 21.6167718 },
    { lat: 49.1486288, lng: 21.6162993 },
    { lat: 49.148194, lng: 21.6157931 },
    { lat: 49.1478627, lng: 21.615037 },
    { lat: 49.1479202, lng: 21.6147533 },
    { lat: 49.1479206, lng: 21.6141183 },
    { lat: 49.1478323, lng: 21.6140507 },
    { lat: 49.1476467, lng: 21.6137258 },
    { lat: 49.1473189, lng: 21.6134633 },
    { lat: 49.1469255, lng: 21.612663 },
    { lat: 49.1469193, lng: 21.6124353 },
    { lat: 49.1463578, lng: 21.6122323 },
    { lat: 49.1459881, lng: 21.6117166 },
    { lat: 49.1457043, lng: 21.6117031 },
    { lat: 49.1455512, lng: 21.6114945 },
    { lat: 49.1454369, lng: 21.6115535 },
    { lat: 49.1450009, lng: 21.6112063 },
    { lat: 49.1449957, lng: 21.6107273 },
    { lat: 49.1449289, lng: 21.610631 },
    { lat: 49.1447332, lng: 21.6106403 },
    { lat: 49.1445965, lng: 21.6103677 },
    { lat: 49.1438754, lng: 21.610001 },
    { lat: 49.1437059, lng: 21.6101384 },
    { lat: 49.1436692, lng: 21.6100721 },
    { lat: 49.1433539, lng: 21.6101034 },
    { lat: 49.1432801, lng: 21.609958 },
    { lat: 49.1432319, lng: 21.6096801 },
    { lat: 49.1430174, lng: 21.6095702 },
    { lat: 49.1430649, lng: 21.6093343 },
    { lat: 49.142863, lng: 21.6093563 },
    { lat: 49.1428471, lng: 21.6089437 },
    { lat: 49.1426754, lng: 21.6091613 },
    { lat: 49.1426202, lng: 21.6089546 },
    { lat: 49.1424095, lng: 21.6088212 },
    { lat: 49.1421124, lng: 21.6088597 },
    { lat: 49.1420586, lng: 21.6085013 },
    { lat: 49.141883, lng: 21.6084913 },
    { lat: 49.1416908, lng: 21.6082604 },
    { lat: 49.1414604, lng: 21.6081045 },
    { lat: 49.1413626, lng: 21.6081456 },
    { lat: 49.1412245, lng: 21.6080294 },
    { lat: 49.1411042, lng: 21.6080788 },
    { lat: 49.1408582, lng: 21.6079272 },
    { lat: 49.1407733, lng: 21.6077483 },
    { lat: 49.1404152, lng: 21.6076117 },
    { lat: 49.1402122, lng: 21.6071404 },
    { lat: 49.1399601, lng: 21.6068551 },
    { lat: 49.1398602, lng: 21.6066179 },
    { lat: 49.1395264, lng: 21.6062617 },
    { lat: 49.1392787, lng: 21.6056898 },
    { lat: 49.138942, lng: 21.6046159 },
    { lat: 49.1384753, lng: 21.6037721 },
    { lat: 49.1379184, lng: 21.6035678 },
    { lat: 49.1372588, lng: 21.6031489 },
    { lat: 49.1374071, lng: 21.6022447 },
    { lat: 49.137114, lng: 21.6017332 },
    { lat: 49.1368905, lng: 21.6014235 },
    { lat: 49.1367429, lng: 21.6013716 },
    { lat: 49.1359217, lng: 21.601456 },
    { lat: 49.1359009, lng: 21.601393 },
    { lat: 49.1357624, lng: 21.6013886 },
    { lat: 49.1357241, lng: 21.6013067 },
    { lat: 49.1353786, lng: 21.6013874 },
    { lat: 49.1345418, lng: 21.6021684 },
    { lat: 49.1344797, lng: 21.6023164 },
    { lat: 49.1344202, lng: 21.6049285 },
    { lat: 49.1342386, lng: 21.6049542 },
    { lat: 49.1340642, lng: 21.605519 },
    { lat: 49.1341172, lng: 21.6066268 },
    { lat: 49.133964, lng: 21.6066597 },
    { lat: 49.1334177, lng: 21.6070411 },
    { lat: 49.1331554, lng: 21.6071538 },
    { lat: 49.1315294, lng: 21.6069384 },
    { lat: 49.1305029, lng: 21.6074472 },
    { lat: 49.1304532, lng: 21.6077302 },
    { lat: 49.1302461, lng: 21.6080931 },
    { lat: 49.1302876, lng: 21.6081734 },
    { lat: 49.1300349, lng: 21.6088721 },
    { lat: 49.1292803, lng: 21.6103517 },
    { lat: 49.1292352, lng: 21.6103478 },
    { lat: 49.1290952, lng: 21.6108187 },
    { lat: 49.1290785, lng: 21.6114024 },
    { lat: 49.1291377, lng: 21.6119156 },
    { lat: 49.1307338, lng: 21.6128832 },
    { lat: 49.1306682, lng: 21.6130364 },
    { lat: 49.1303725, lng: 21.6134085 },
    { lat: 49.1302377, lng: 21.6134552 },
    { lat: 49.1300084, lng: 21.6136617 },
    { lat: 49.1296227, lng: 21.6143295 },
    { lat: 49.1290769, lng: 21.6147689 },
    { lat: 49.1282612, lng: 21.6150082 },
    { lat: 49.128158, lng: 21.6151406 },
    { lat: 49.1279846, lng: 21.615092 },
    { lat: 49.1276422, lng: 21.6152099 },
    { lat: 49.1271386, lng: 21.6150524 },
    { lat: 49.1269265, lng: 21.6153613 },
    { lat: 49.1267102, lng: 21.6154283 },
    { lat: 49.1265807, lng: 21.6155867 },
    { lat: 49.1264473, lng: 21.615606 },
    { lat: 49.1262736, lng: 21.6154257 },
    { lat: 49.126213, lng: 21.6154781 },
    { lat: 49.1261493, lng: 21.6156908 },
    { lat: 49.1259297, lng: 21.6156424 },
    { lat: 49.1258762, lng: 21.6158232 },
    { lat: 49.1257059, lng: 21.615929 },
    { lat: 49.1255814, lng: 21.6162279 },
    { lat: 49.1254074, lng: 21.6162864 },
    { lat: 49.1252493, lng: 21.6165484 },
    { lat: 49.1251946, lng: 21.6168133 },
    { lat: 49.1251408, lng: 21.616862 },
    { lat: 49.1249781, lng: 21.6167906 },
    { lat: 49.1249448, lng: 21.6170571 },
    { lat: 49.1248111, lng: 21.6171827 },
    { lat: 49.1246901, lng: 21.6174031 },
    { lat: 49.1250845, lng: 21.6180438 },
    { lat: 49.1255143, lng: 21.6184143 },
    { lat: 49.125835, lng: 21.6185788 },
    { lat: 49.126088, lng: 21.619146 },
    { lat: 49.1262831, lng: 21.619246 },
    { lat: 49.1263919, lng: 21.6194398 },
    { lat: 49.1266912, lng: 21.6197612 },
    { lat: 49.1270709, lng: 21.6206404 },
    { lat: 49.1277494, lng: 21.6218777 },
    { lat: 49.128022, lng: 21.6228327 },
    { lat: 49.1280288, lng: 21.623079 },
    { lat: 49.1272198, lng: 21.623483 },
    { lat: 49.1262973, lng: 21.6245879 },
    { lat: 49.1258072, lng: 21.6248028 },
    { lat: 49.1258254, lng: 21.6252258 },
    { lat: 49.1267175, lng: 21.6268245 },
    { lat: 49.126864, lng: 21.6279384 },
    { lat: 49.1270698, lng: 21.6297738 },
    { lat: 49.1268698, lng: 21.6311038 },
    { lat: 49.1268644, lng: 21.6316368 },
    { lat: 49.1260486, lng: 21.6322947 },
    { lat: 49.1252503, lng: 21.6339109 },
    { lat: 49.1250106, lng: 21.635012 },
    { lat: 49.1248141, lng: 21.6356438 },
    { lat: 49.1245754, lng: 21.6359672 },
    { lat: 49.1244296, lng: 21.6364373 },
    { lat: 49.1247955, lng: 21.6364029 },
    { lat: 49.125008, lng: 21.6362794 },
    { lat: 49.1253913, lng: 21.6363032 },
    { lat: 49.1249132, lng: 21.6375437 },
    { lat: 49.1251897, lng: 21.6375678 },
    { lat: 49.1250486, lng: 21.6384597 },
    { lat: 49.1250857, lng: 21.6389534 },
    { lat: 49.1248509, lng: 21.6396185 },
    { lat: 49.1242227, lng: 21.6405064 },
    { lat: 49.1239751, lng: 21.6411245 },
    { lat: 49.1240087, lng: 21.6421485 },
    { lat: 49.1238722, lng: 21.643039 },
    { lat: 49.1238767, lng: 21.6436598 },
    { lat: 49.1245239, lng: 21.6429714 },
    { lat: 49.1258376, lng: 21.6427538 },
    { lat: 49.126042, lng: 21.6431959 },
    { lat: 49.1274688, lng: 21.6427508 },
    { lat: 49.1288302, lng: 21.6421001 },
    { lat: 49.1296133, lng: 21.64186 },
    { lat: 49.1312594, lng: 21.6411646 },
    { lat: 49.1315748, lng: 21.6413903 },
    { lat: 49.132258, lng: 21.6414439 },
    { lat: 49.1324852, lng: 21.6406995 },
    { lat: 49.1327308, lng: 21.6404641 },
    { lat: 49.1330175, lng: 21.6403726 },
    { lat: 49.1334263, lng: 21.639691 },
    { lat: 49.1337141, lng: 21.6396393 },
    { lat: 49.1341509, lng: 21.6400671 },
    { lat: 49.1342356, lng: 21.6406919 },
    { lat: 49.1355313, lng: 21.642869 },
    { lat: 49.1420442, lng: 21.6486992 },
    { lat: 49.14166, lng: 21.649661 },
    { lat: 49.1424081, lng: 21.649951 },
    { lat: 49.1441819, lng: 21.6511387 },
    { lat: 49.1441198, lng: 21.6521233 },
    { lat: 49.1443816, lng: 21.6524398 },
    { lat: 49.1458612, lng: 21.6542281 },
    { lat: 49.1464179, lng: 21.6546406 },
    { lat: 49.147061, lng: 21.655116 },
    { lat: 49.1483204, lng: 21.6524939 },
    { lat: 49.1494886, lng: 21.6513904 },
    { lat: 49.151338, lng: 21.6511428 },
    { lat: 49.1519711, lng: 21.651262 },
    { lat: 49.1523609, lng: 21.6509548 },
    { lat: 49.1550319, lng: 21.6501348 },
    { lat: 49.1556123, lng: 21.6502573 },
    { lat: 49.1615476, lng: 21.6491394 },
    { lat: 49.1630129, lng: 21.6492087 },
    { lat: 49.1650757, lng: 21.6491937 },
    { lat: 49.1666685, lng: 21.6483634 },
    { lat: 49.1689177, lng: 21.6469734 },
    { lat: 49.1707644, lng: 21.6460459 },
    { lat: 49.1713074, lng: 21.6461603 },
    { lat: 49.1719809, lng: 21.6460662 },
    { lat: 49.1733099, lng: 21.6450086 },
    { lat: 49.173839, lng: 21.6451443 },
    { lat: 49.1744493, lng: 21.6449622 },
    { lat: 49.17423, lng: 21.643681 },
    { lat: 49.1739095, lng: 21.6426112 },
    { lat: 49.173921, lng: 21.6418418 },
    { lat: 49.1739735, lng: 21.6415814 },
    { lat: 49.1739444, lng: 21.6413772 },
    { lat: 49.1738646, lng: 21.6412197 },
    { lat: 49.17381, lng: 21.6407375 },
    { lat: 49.1736901, lng: 21.640639 },
    { lat: 49.1734223, lng: 21.64018 },
    { lat: 49.1732984, lng: 21.6395544 },
    { lat: 49.1733172, lng: 21.6394665 },
    { lat: 49.1732082, lng: 21.6392621 },
    { lat: 49.1732271, lng: 21.6389642 },
    { lat: 49.1731758, lng: 21.6388204 },
    { lat: 49.1729985, lng: 21.6385002 },
    { lat: 49.1723964, lng: 21.638042 },
    { lat: 49.1717786, lng: 21.6378282 },
    { lat: 49.1714024, lng: 21.6377701 },
    { lat: 49.1711466, lng: 21.6375513 },
    { lat: 49.1710299, lng: 21.6373516 },
    { lat: 49.1707209, lng: 21.637167 },
    { lat: 49.1705769, lng: 21.6367541 },
    { lat: 49.1702619, lng: 21.6364247 },
    { lat: 49.1701142, lng: 21.6359516 },
    { lat: 49.1699578, lng: 21.6357063 },
    { lat: 49.1699301, lng: 21.6353939 },
    { lat: 49.1695639, lng: 21.6347941 },
    { lat: 49.169544, lng: 21.634602 },
    { lat: 49.1693654, lng: 21.6341658 },
    { lat: 49.1695681, lng: 21.6338818 },
    { lat: 49.1696003, lng: 21.6331697 },
    { lat: 49.169728, lng: 21.6326518 },
    { lat: 49.1696895, lng: 21.6325618 },
  ],
  Makovce: [
    { lat: 49.2463787, lng: 21.7340082 },
    { lat: 49.2478693, lng: 21.7361416 },
    { lat: 49.2483512, lng: 21.7374729 },
    { lat: 49.2492153, lng: 21.7393995 },
    { lat: 49.249399, lng: 21.74001 },
    { lat: 49.2490631, lng: 21.7427488 },
    { lat: 49.2492686, lng: 21.7438441 },
    { lat: 49.249184, lng: 21.7448996 },
    { lat: 49.2490665, lng: 21.745058 },
    { lat: 49.2489457, lng: 21.7467851 },
    { lat: 49.2490503, lng: 21.7473411 },
    { lat: 49.2489518, lng: 21.747956 },
    { lat: 49.2488851, lng: 21.7496571 },
    { lat: 49.2482005, lng: 21.7520611 },
    { lat: 49.2484283, lng: 21.7524709 },
    { lat: 49.2479929, lng: 21.7534075 },
    { lat: 49.248393, lng: 21.7541667 },
    { lat: 49.2482469, lng: 21.7543374 },
    { lat: 49.2486716, lng: 21.7549623 },
    { lat: 49.2491422, lng: 21.7567783 },
    { lat: 49.2493264, lng: 21.7573208 },
    { lat: 49.2496245, lng: 21.7584745 },
    { lat: 49.2498159, lng: 21.7604143 },
    { lat: 49.2499021, lng: 21.760781 },
    { lat: 49.2500727, lng: 21.7611565 },
    { lat: 49.2502165, lng: 21.7627741 },
    { lat: 49.2502185, lng: 21.763155 },
    { lat: 49.2501584, lng: 21.7634805 },
    { lat: 49.2501688, lng: 21.7642187 },
    { lat: 49.2502114, lng: 21.7644657 },
    { lat: 49.2502033, lng: 21.7654084 },
    { lat: 49.250096, lng: 21.7658215 },
    { lat: 49.2497864, lng: 21.7659773 },
    { lat: 49.2498754, lng: 21.7676576 },
    { lat: 49.2497614, lng: 21.7685892 },
    { lat: 49.2497595, lng: 21.7689919 },
    { lat: 49.2500887, lng: 21.7697448 },
    { lat: 49.2501157, lng: 21.7697504 },
    { lat: 49.2507121, lng: 21.7699779 },
    { lat: 49.2512669, lng: 21.7698583 },
    { lat: 49.2513327, lng: 21.7693635 },
    { lat: 49.251686, lng: 21.7691489 },
    { lat: 49.2523347, lng: 21.7693535 },
    { lat: 49.2524975, lng: 21.7692996 },
    { lat: 49.2526018, lng: 21.7694095 },
    { lat: 49.2528241, lng: 21.7691874 },
    { lat: 49.2531677, lng: 21.7691222 },
    { lat: 49.2534784, lng: 21.7689159 },
    { lat: 49.2538197, lng: 21.7688432 },
    { lat: 49.2543131, lng: 21.7686093 },
    { lat: 49.2546234, lng: 21.7682644 },
    { lat: 49.2546597, lng: 21.768102 },
    { lat: 49.2547472, lng: 21.7680066 },
    { lat: 49.2552324, lng: 21.7668675 },
    { lat: 49.2563114, lng: 21.7661861 },
    { lat: 49.2576034, lng: 21.766855 },
    { lat: 49.2584105, lng: 21.766191 },
    { lat: 49.2587061, lng: 21.7660126 },
    { lat: 49.2594995, lng: 21.7656785 },
    { lat: 49.2597419, lng: 21.7656659 },
    { lat: 49.2602215, lng: 21.7654662 },
    { lat: 49.2610317, lng: 21.7648285 },
    { lat: 49.2611164, lng: 21.764841 },
    { lat: 49.2614126, lng: 21.7646828 },
    { lat: 49.2625562, lng: 21.7647997 },
    { lat: 49.2630114, lng: 21.7649624 },
    { lat: 49.2631505, lng: 21.7648537 },
    { lat: 49.2632777, lng: 21.76486 },
    { lat: 49.2640211, lng: 21.7645087 },
    { lat: 49.2643321, lng: 21.7644969 },
    { lat: 49.264597, lng: 21.7646021 },
    { lat: 49.2648846, lng: 21.7645398 },
    { lat: 49.2652867, lng: 21.7646042 },
    { lat: 49.2657979, lng: 21.76432 },
    { lat: 49.2658727, lng: 21.763956 },
    { lat: 49.2660329, lng: 21.7636397 },
    { lat: 49.2659937, lng: 21.7633377 },
    { lat: 49.2661809, lng: 21.7629283 },
    { lat: 49.266093, lng: 21.7627103 },
    { lat: 49.2657964, lng: 21.7623395 },
    { lat: 49.2657936, lng: 21.7620163 },
    { lat: 49.2655036, lng: 21.7620668 },
    { lat: 49.2649091, lng: 21.761618 },
    { lat: 49.2652548, lng: 21.7608596 },
    { lat: 49.2651542, lng: 21.7602758 },
    { lat: 49.2646376, lng: 21.7585549 },
    { lat: 49.2646174, lng: 21.7583418 },
    { lat: 49.2640497, lng: 21.7569403 },
    { lat: 49.26376, lng: 21.7560573 },
    { lat: 49.2636751, lng: 21.7557438 },
    { lat: 49.2635657, lng: 21.7549617 },
    { lat: 49.2636804, lng: 21.7541523 },
    { lat: 49.2636047, lng: 21.753577 },
    { lat: 49.2641551, lng: 21.7514975 },
    { lat: 49.2644805, lng: 21.7506967 },
    { lat: 49.2646827, lng: 21.7503442 },
    { lat: 49.2648637, lng: 21.7498263 },
    { lat: 49.2650097, lng: 21.7496041 },
    { lat: 49.2650121, lng: 21.7494819 },
    { lat: 49.2651663, lng: 21.7492325 },
    { lat: 49.2652186, lng: 21.7487834 },
    { lat: 49.2654303, lng: 21.7488759 },
    { lat: 49.2650484, lng: 21.7483822 },
    { lat: 49.2647995, lng: 21.7484624 },
    { lat: 49.264551, lng: 21.7484582 },
    { lat: 49.2643143, lng: 21.7483586 },
    { lat: 49.2636524, lng: 21.7471416 },
    { lat: 49.2638571, lng: 21.7466259 },
    { lat: 49.263399, lng: 21.7463627 },
    { lat: 49.2634327, lng: 21.7461921 },
    { lat: 49.2633541, lng: 21.7438112 },
    { lat: 49.2620202, lng: 21.742962 },
    { lat: 49.2618294, lng: 21.743022 },
    { lat: 49.2613232, lng: 21.7428119 },
    { lat: 49.261079, lng: 21.7421668 },
    { lat: 49.2609071, lng: 21.7419134 },
    { lat: 49.2609434, lng: 21.7407368 },
    { lat: 49.2611814, lng: 21.73961 },
    { lat: 49.2611313, lng: 21.7365637 },
    { lat: 49.2614644, lng: 21.7355026 },
    { lat: 49.2625112, lng: 21.733831 },
    { lat: 49.2622594, lng: 21.7320097 },
    { lat: 49.263263, lng: 21.7314665 },
    { lat: 49.2634251, lng: 21.7308316 },
    { lat: 49.2639558, lng: 21.729679 },
    { lat: 49.263331, lng: 21.7288997 },
    { lat: 49.2627387, lng: 21.7267938 },
    { lat: 49.2621407, lng: 21.7273188 },
    { lat: 49.2619774, lng: 21.727988 },
    { lat: 49.2612949, lng: 21.7290225 },
    { lat: 49.2595767, lng: 21.7287871 },
    { lat: 49.2584931, lng: 21.7289842 },
    { lat: 49.2582786, lng: 21.7285314 },
    { lat: 49.2579379, lng: 21.7281171 },
    { lat: 49.2576654, lng: 21.7279755 },
    { lat: 49.2562892, lng: 21.7277493 },
    { lat: 49.2559631, lng: 21.7273272 },
    { lat: 49.2557265, lng: 21.7272234 },
    { lat: 49.2555023, lng: 21.7273378 },
    { lat: 49.2553512, lng: 21.7275765 },
    { lat: 49.2552779, lng: 21.7276131 },
    { lat: 49.2550009, lng: 21.7276014 },
    { lat: 49.2547831, lng: 21.7274961 },
    { lat: 49.2543219, lng: 21.7274915 },
    { lat: 49.2541313, lng: 21.7273881 },
    { lat: 49.2539281, lng: 21.7274021 },
    { lat: 49.2538371, lng: 21.727322 },
    { lat: 49.253359, lng: 21.7274906 },
    { lat: 49.2532408, lng: 21.7277882 },
    { lat: 49.2529786, lng: 21.7279517 },
    { lat: 49.2528822, lng: 21.7275443 },
    { lat: 49.252662, lng: 21.7275507 },
    { lat: 49.2525093, lng: 21.7276426 },
    { lat: 49.2522072, lng: 21.7280027 },
    { lat: 49.2520542, lng: 21.7280006 },
    { lat: 49.2519995, lng: 21.7281517 },
    { lat: 49.2517729, lng: 21.7284246 },
    { lat: 49.2515632, lng: 21.7282395 },
    { lat: 49.2514748, lng: 21.7282594 },
    { lat: 49.2512757, lng: 21.7285097 },
    { lat: 49.2508018, lng: 21.7284966 },
    { lat: 49.2504372, lng: 21.7288281 },
    { lat: 49.2503854, lng: 21.7289544 },
    { lat: 49.2501318, lng: 21.7288139 },
    { lat: 49.2497562, lng: 21.7291925 },
    { lat: 49.2488562, lng: 21.7293591 },
    { lat: 49.2484472, lng: 21.7300969 },
    { lat: 49.2482602, lng: 21.7306 },
    { lat: 49.2480663, lng: 21.7307546 },
    { lat: 49.2480215, lng: 21.7309755 },
    { lat: 49.2476686, lng: 21.7317477 },
    { lat: 49.247269, lng: 21.7324594 },
    { lat: 49.2467138, lng: 21.7331572 },
    { lat: 49.2464907, lng: 21.7336056 },
    { lat: 49.2463787, lng: 21.7340082 },
  ],
  Tokajík: [
    { lat: 49.1316436, lng: 21.6879679 },
    { lat: 49.1319942, lng: 21.6876547 },
    { lat: 49.1324427, lng: 21.6868898 },
    { lat: 49.132947, lng: 21.6862824 },
    { lat: 49.1327495, lng: 21.6858788 },
    { lat: 49.1326912, lng: 21.6852577 },
    { lat: 49.1327792, lng: 21.6848128 },
    { lat: 49.1324555, lng: 21.6838233 },
    { lat: 49.1323877, lng: 21.6834349 },
    { lat: 49.1324297, lng: 21.6826584 },
    { lat: 49.1323999, lng: 21.6823029 },
    { lat: 49.1322008, lng: 21.6817567 },
    { lat: 49.132097, lng: 21.6816228 },
    { lat: 49.1320252, lng: 21.6812508 },
    { lat: 49.1318556, lng: 21.6808465 },
    { lat: 49.1317577, lng: 21.6807632 },
    { lat: 49.1314517, lng: 21.6807626 },
    { lat: 49.1314212, lng: 21.6807602 },
    { lat: 49.1313642, lng: 21.6812329 },
    { lat: 49.1311464, lng: 21.6815397 },
    { lat: 49.131105, lng: 21.681676 },
    { lat: 49.1311286, lng: 21.6819191 },
    { lat: 49.130977, lng: 21.6820148 },
    { lat: 49.1309663, lng: 21.6822483 },
    { lat: 49.1308308, lng: 21.6823911 },
    { lat: 49.1308187, lng: 21.6826153 },
    { lat: 49.1307374, lng: 21.6827623 },
    { lat: 49.1305613, lng: 21.6827029 },
    { lat: 49.1304375, lng: 21.6829553 },
    { lat: 49.1304148, lng: 21.683108 },
    { lat: 49.1302973, lng: 21.6831297 },
    { lat: 49.1302682, lng: 21.6832217 },
    { lat: 49.1300156, lng: 21.6833654 },
    { lat: 49.129813, lng: 21.6833644 },
    { lat: 49.129772, lng: 21.6834241 },
    { lat: 49.1297935, lng: 21.6838025 },
    { lat: 49.1295814, lng: 21.6839972 },
    { lat: 49.1294663, lng: 21.6838542 },
    { lat: 49.1293021, lng: 21.6838943 },
    { lat: 49.1290114, lng: 21.6842179 },
    { lat: 49.1290133, lng: 21.6844979 },
    { lat: 49.1288118, lng: 21.6847778 },
    { lat: 49.1285012, lng: 21.6845713 },
    { lat: 49.1282187, lng: 21.6847248 },
    { lat: 49.1281549, lng: 21.6849924 },
    { lat: 49.127848, lng: 21.685211 },
    { lat: 49.127603, lng: 21.6854709 },
    { lat: 49.1274092, lng: 21.6853315 },
    { lat: 49.1272698, lng: 21.6854214 },
    { lat: 49.1271527, lng: 21.6853734 },
    { lat: 49.1270369, lng: 21.6855014 },
    { lat: 49.1262463, lng: 21.6852574 },
    { lat: 49.1260607, lng: 21.6851343 },
    { lat: 49.1251091, lng: 21.6850132 },
    { lat: 49.1247448, lng: 21.6850633 },
    { lat: 49.1243561, lng: 21.6852902 },
    { lat: 49.1240493, lng: 21.6855921 },
    { lat: 49.1233547, lng: 21.6865346 },
    { lat: 49.1232664, lng: 21.6870979 },
    { lat: 49.1229672, lng: 21.6873476 },
    { lat: 49.1223729, lng: 21.6884698 },
    { lat: 49.1217139, lng: 21.688182 },
    { lat: 49.1211027, lng: 21.6881351 },
    { lat: 49.120695, lng: 21.6877788 },
    { lat: 49.1207165, lng: 21.6875357 },
    { lat: 49.1202648, lng: 21.68793 },
    { lat: 49.1200536, lng: 21.6880247 },
    { lat: 49.1196277, lng: 21.6886676 },
    { lat: 49.1192568, lng: 21.6890916 },
    { lat: 49.1188731, lng: 21.6893063 },
    { lat: 49.1170325, lng: 21.6889222 },
    { lat: 49.1153621, lng: 21.6895071 },
    { lat: 49.1150999, lng: 21.6895048 },
    { lat: 49.114862, lng: 21.6896102 },
    { lat: 49.1145848, lng: 21.6898907 },
    { lat: 49.1137278, lng: 21.6910255 },
    { lat: 49.1132512, lng: 21.6919986 },
    { lat: 49.1130516, lng: 21.6922743 },
    { lat: 49.1128457, lng: 21.6922775 },
    { lat: 49.1126742, lng: 21.6924239 },
    { lat: 49.1124391, lng: 21.6925076 },
    { lat: 49.1111931, lng: 21.6926083 },
    { lat: 49.1109913, lng: 21.6922557 },
    { lat: 49.1106058, lng: 21.6918934 },
    { lat: 49.1101465, lng: 21.6909616 },
    { lat: 49.1101043, lng: 21.6905689 },
    { lat: 49.1100573, lng: 21.690495 },
    { lat: 49.1095333, lng: 21.6902501 },
    { lat: 49.1093215, lng: 21.6895287 },
    { lat: 49.1083364, lng: 21.6876222 },
    { lat: 49.1079965, lng: 21.6873613 },
    { lat: 49.1081668, lng: 21.6869187 },
    { lat: 49.1079613, lng: 21.6866856 },
    { lat: 49.107885, lng: 21.6854394 },
    { lat: 49.1075912, lng: 21.6849285 },
    { lat: 49.1071464, lng: 21.684747 },
    { lat: 49.1066743, lng: 21.6839281 },
    { lat: 49.1065527, lng: 21.6835124 },
    { lat: 49.106242, lng: 21.6837541 },
    { lat: 49.1060743, lng: 21.6836195 },
    { lat: 49.1054749, lng: 21.6838779 },
    { lat: 49.1047211, lng: 21.6824652 },
    { lat: 49.1035871, lng: 21.6803396 },
    { lat: 49.1033496, lng: 21.6806234 },
    { lat: 49.1028612, lng: 21.6809922 },
    { lat: 49.1027086, lng: 21.6814621 },
    { lat: 49.1025172, lng: 21.6816715 },
    { lat: 49.1021105, lng: 21.6819411 },
    { lat: 49.1020681, lng: 21.6821147 },
    { lat: 49.1017064, lng: 21.682804 },
    { lat: 49.1016206, lng: 21.6836233 },
    { lat: 49.1011398, lng: 21.6849017 },
    { lat: 49.1008212, lng: 21.6861836 },
    { lat: 49.1003511, lng: 21.6868208 },
    { lat: 49.1003073, lng: 21.6875728 },
    { lat: 49.0995561, lng: 21.6883736 },
    { lat: 49.0994632, lng: 21.6896051 },
    { lat: 49.0994971, lng: 21.6901899 },
    { lat: 49.0994619, lng: 21.6912314 },
    { lat: 49.0994253, lng: 21.6913669 },
    { lat: 49.0994519, lng: 21.6917332 },
    { lat: 49.0995501, lng: 21.6922038 },
    { lat: 49.0994973, lng: 21.6937551 },
    { lat: 49.0995261, lng: 21.69428 },
    { lat: 49.0994074, lng: 21.6949673 },
    { lat: 49.0994579, lng: 21.6953831 },
    { lat: 49.0994486, lng: 21.695789 },
    { lat: 49.0992806, lng: 21.6965724 },
    { lat: 49.0987372, lng: 21.6979518 },
    { lat: 49.0981501, lng: 21.6991752 },
    { lat: 49.0977725, lng: 21.6996396 },
    { lat: 49.0971289, lng: 21.7010606 },
    { lat: 49.0968505, lng: 21.7015091 },
    { lat: 49.0965378, lng: 21.7017156 },
    { lat: 49.0966342, lng: 21.7018298 },
    { lat: 49.0966425, lng: 21.7021833 },
    { lat: 49.0967029, lng: 21.7024208 },
    { lat: 49.0967789, lng: 21.7024412 },
    { lat: 49.0968663, lng: 21.7026798 },
    { lat: 49.0968595, lng: 21.7030557 },
    { lat: 49.0969714, lng: 21.7031583 },
    { lat: 49.0969717, lng: 21.7034713 },
    { lat: 49.0970915, lng: 21.7038673 },
    { lat: 49.0969829, lng: 21.7053897 },
    { lat: 49.0968467, lng: 21.7056818 },
    { lat: 49.0968555, lng: 21.7059328 },
    { lat: 49.0967445, lng: 21.7061523 },
    { lat: 49.096778, lng: 21.7063139 },
    { lat: 49.0967077, lng: 21.7064152 },
    { lat: 49.0968478, lng: 21.707315 },
    { lat: 49.0967055, lng: 21.7079715 },
    { lat: 49.0968044, lng: 21.7086449 },
    { lat: 49.0971324, lng: 21.7095456 },
    { lat: 49.0968478, lng: 21.7103434 },
    { lat: 49.0969071, lng: 21.7111783 },
    { lat: 49.0970058, lng: 21.7116693 },
    { lat: 49.0972058, lng: 21.7122446 },
    { lat: 49.0974331, lng: 21.7133386 },
    { lat: 49.097303, lng: 21.7134813 },
    { lat: 49.0966533, lng: 21.7138853 },
    { lat: 49.0960944, lng: 21.7145226 },
    { lat: 49.0958781, lng: 21.7151804 },
    { lat: 49.0954544, lng: 21.7159174 },
    { lat: 49.0952999, lng: 21.7164087 },
    { lat: 49.0950134, lng: 21.7167556 },
    { lat: 49.0942255, lng: 21.7169483 },
    { lat: 49.0932632, lng: 21.7175215 },
    { lat: 49.0926509, lng: 21.7178075 },
    { lat: 49.0914982, lng: 21.7185321 },
    { lat: 49.0912539, lng: 21.7188204 },
    { lat: 49.0907455, lng: 21.719205 },
    { lat: 49.0901269, lng: 21.7192551 },
    { lat: 49.0895229, lng: 21.7192085 },
    { lat: 49.08912, lng: 21.718855 },
    { lat: 49.089138, lng: 21.719578 },
    { lat: 49.089224, lng: 21.720969 },
    { lat: 49.089342, lng: 21.72144 },
    { lat: 49.09, lng: 21.722387 },
    { lat: 49.090217, lng: 21.722565 },
    { lat: 49.0907, lng: 21.72298 },
    { lat: 49.090732, lng: 21.72302 },
    { lat: 49.091302, lng: 21.723252 },
    { lat: 49.091497, lng: 21.723389 },
    { lat: 49.091831, lng: 21.723529 },
    { lat: 49.091886, lng: 21.723548 },
    { lat: 49.092226, lng: 21.72382 },
    { lat: 49.092518, lng: 21.724123 },
    { lat: 49.092882, lng: 21.724365 },
    { lat: 49.093264, lng: 21.724542 },
    { lat: 49.093616, lng: 21.724802 },
    { lat: 49.093795, lng: 21.724855 },
    { lat: 49.093893, lng: 21.725015 },
    { lat: 49.093984, lng: 21.725314 },
    { lat: 49.094076, lng: 21.725315 },
    { lat: 49.094357, lng: 21.725459 },
    { lat: 49.094556, lng: 21.725422 },
    { lat: 49.095005, lng: 21.725477 },
    { lat: 49.095533, lng: 21.72489 },
    { lat: 49.095756, lng: 21.724964 },
    { lat: 49.095889, lng: 21.724508 },
    { lat: 49.096149, lng: 21.723993 },
    { lat: 49.097154, lng: 21.724107 },
    { lat: 49.097937, lng: 21.724109 },
    { lat: 49.098741, lng: 21.724234 },
    { lat: 49.099218, lng: 21.724201 },
    { lat: 49.099586, lng: 21.724294 },
    { lat: 49.099715, lng: 21.724419 },
    { lat: 49.099709, lng: 21.72445 },
    { lat: 49.099815, lng: 21.724487 },
    { lat: 49.099923, lng: 21.724475 },
    { lat: 49.10009, lng: 21.724441 },
    { lat: 49.100892, lng: 21.72417 },
    { lat: 49.101651, lng: 21.724017 },
    { lat: 49.102065, lng: 21.723879 },
    { lat: 49.102274, lng: 21.723938 },
    { lat: 49.102495, lng: 21.723924 },
    { lat: 49.102655, lng: 21.723968 },
    { lat: 49.103034, lng: 21.724222 },
    { lat: 49.103353, lng: 21.724449 },
    { lat: 49.10395, lng: 21.724875 },
    { lat: 49.104447, lng: 21.725054 },
    { lat: 49.104564, lng: 21.725064 },
    { lat: 49.104939, lng: 21.724868 },
    { lat: 49.105147, lng: 21.724899 },
    { lat: 49.105296, lng: 21.724982 },
    { lat: 49.105327, lng: 21.724955 },
    { lat: 49.105875, lng: 21.724396 },
    { lat: 49.106326, lng: 21.724022 },
    { lat: 49.1071, lng: 21.723383 },
    { lat: 49.107205, lng: 21.723295 },
    { lat: 49.107364, lng: 21.723165 },
    { lat: 49.107598, lng: 21.722325 },
    { lat: 49.108484, lng: 21.721649 },
    { lat: 49.109079, lng: 21.720952 },
    { lat: 49.109553, lng: 21.720498 },
    { lat: 49.109675, lng: 21.720304 },
    { lat: 49.110008, lng: 21.720074 },
    { lat: 49.110351, lng: 21.719866 },
    { lat: 49.110399, lng: 21.719857 },
    { lat: 49.111071, lng: 21.719685 },
    { lat: 49.111316, lng: 21.719723 },
    { lat: 49.111702, lng: 21.719734 },
    { lat: 49.11206, lng: 21.719657 },
    { lat: 49.112352, lng: 21.71963 },
    { lat: 49.112744, lng: 21.719378 },
    { lat: 49.113189, lng: 21.719033 },
    { lat: 49.113617, lng: 21.718974 },
    { lat: 49.114167, lng: 21.718844 },
    { lat: 49.114533, lng: 21.718847 },
    { lat: 49.115042, lng: 21.718677 },
    { lat: 49.115639, lng: 21.718418 },
    { lat: 49.11688, lng: 21.717724 },
    { lat: 49.117624, lng: 21.717274 },
    { lat: 49.117929, lng: 21.717207 },
    { lat: 49.118331, lng: 21.717038 },
    { lat: 49.118841, lng: 21.716785 },
    { lat: 49.119561, lng: 21.716488 },
    { lat: 49.119781, lng: 21.716553 },
    { lat: 49.120098, lng: 21.716897 },
    { lat: 49.120561, lng: 21.717081 },
    { lat: 49.120838, lng: 21.717148 },
    { lat: 49.121179, lng: 21.717258 },
    { lat: 49.121345, lng: 21.71773 },
    { lat: 49.121984, lng: 21.718455 },
    { lat: 49.122242, lng: 21.71896 },
    { lat: 49.122271, lng: 21.719003 },
    { lat: 49.122614, lng: 21.718408 },
    { lat: 49.122713, lng: 21.718177 },
    { lat: 49.123116, lng: 21.717551 },
    { lat: 49.123428, lng: 21.717259 },
    { lat: 49.1234202, lng: 21.7169547 },
    { lat: 49.1231196, lng: 21.7163182 },
    { lat: 49.123033, lng: 21.7157669 },
    { lat: 49.1231252, lng: 21.7147171 },
    { lat: 49.1237457, lng: 21.7133953 },
    { lat: 49.1244807, lng: 21.7124787 },
    { lat: 49.1246546, lng: 21.7123346 },
    { lat: 49.1251137, lng: 21.7113321 },
    { lat: 49.1251944, lng: 21.7106507 },
    { lat: 49.125298, lng: 21.7103181 },
    { lat: 49.1253297, lng: 21.7098191 },
    { lat: 49.1251595, lng: 21.7094324 },
    { lat: 49.1249529, lng: 21.7092358 },
    { lat: 49.1244793, lng: 21.7079744 },
    { lat: 49.1240812, lng: 21.7075965 },
    { lat: 49.1243174, lng: 21.7064597 },
    { lat: 49.1242844, lng: 21.7057298 },
    { lat: 49.1240832, lng: 21.7040628 },
    { lat: 49.1242211, lng: 21.7033323 },
    { lat: 49.1241539, lng: 21.7027521 },
    { lat: 49.1243828, lng: 21.7019361 },
    { lat: 49.1248657, lng: 21.701192 },
    { lat: 49.1252588, lng: 21.7007825 },
    { lat: 49.1262238, lng: 21.7001904 },
    { lat: 49.126353, lng: 21.6999823 },
    { lat: 49.1266915, lng: 21.6991383 },
    { lat: 49.1266146, lng: 21.6977412 },
    { lat: 49.1268644, lng: 21.696481 },
    { lat: 49.1273085, lng: 21.6954277 },
    { lat: 49.1275274, lng: 21.695029 },
    { lat: 49.1278746, lng: 21.6947649 },
    { lat: 49.1280622, lng: 21.6943767 },
    { lat: 49.128364, lng: 21.6942667 },
    { lat: 49.128516, lng: 21.694106 },
    { lat: 49.1288779, lng: 21.6932924 },
    { lat: 49.1289513, lng: 21.6930496 },
    { lat: 49.1289116, lng: 21.6927065 },
    { lat: 49.1288082, lng: 21.6924626 },
    { lat: 49.1288211, lng: 21.6923975 },
    { lat: 49.1291587, lng: 21.6914001 },
    { lat: 49.1297697, lng: 21.6901047 },
    { lat: 49.129893, lng: 21.6895869 },
    { lat: 49.1300932, lng: 21.6892467 },
    { lat: 49.1305359, lng: 21.6888537 },
    { lat: 49.1307485, lng: 21.6885628 },
    { lat: 49.1316394, lng: 21.6880142 },
    { lat: 49.1316436, lng: 21.6879679 },
  ],
  Potoky: [
    { lat: 49.2667469, lng: 21.6467161 },
    { lat: 49.2672291, lng: 21.6463648 },
    { lat: 49.2677252, lng: 21.6461622 },
    { lat: 49.2680271, lng: 21.646098 },
    { lat: 49.2692796, lng: 21.6461103 },
    { lat: 49.2695811, lng: 21.6460345 },
    { lat: 49.269658, lng: 21.6460455 },
    { lat: 49.2699611, lng: 21.6463976 },
    { lat: 49.2701478, lng: 21.6465191 },
    { lat: 49.2707273, lng: 21.6464448 },
    { lat: 49.2712487, lng: 21.646582 },
    { lat: 49.2714787, lng: 21.6463116 },
    { lat: 49.2717096, lng: 21.6458758 },
    { lat: 49.2722931, lng: 21.6451597 },
    { lat: 49.2730685, lng: 21.6450524 },
    { lat: 49.2737923, lng: 21.6446144 },
    { lat: 49.274247, lng: 21.6440869 },
    { lat: 49.2745619, lng: 21.643912 },
    { lat: 49.2748707, lng: 21.6434009 },
    { lat: 49.2756741, lng: 21.6427896 },
    { lat: 49.2760116, lng: 21.6424143 },
    { lat: 49.2770574, lng: 21.6415389 },
    { lat: 49.2772166, lng: 21.6414722 },
    { lat: 49.2778511, lng: 21.6409401 },
    { lat: 49.2782514, lng: 21.6403976 },
    { lat: 49.2804872, lng: 21.6378206 },
    { lat: 49.2808023, lng: 21.6373336 },
    { lat: 49.2811125, lng: 21.6371167 },
    { lat: 49.281414, lng: 21.636607 },
    { lat: 49.280714, lng: 21.635408 },
    { lat: 49.27976, lng: 21.634675 },
    { lat: 49.279113, lng: 21.633985 },
    { lat: 49.27873, lng: 21.633202 },
    { lat: 49.278653, lng: 21.633109 },
    { lat: 49.277857, lng: 21.632076 },
    { lat: 49.277854, lng: 21.63198 },
    { lat: 49.277891, lng: 21.631841 },
    { lat: 49.277139, lng: 21.630502 },
    { lat: 49.2771, lng: 21.63054 },
    { lat: 49.275926, lng: 21.628889 },
    { lat: 49.276506, lng: 21.626672 },
    { lat: 49.275829, lng: 21.624346 },
    { lat: 49.275569, lng: 21.623448 },
    { lat: 49.275424, lng: 21.623183 },
    { lat: 49.275378, lng: 21.623098 },
    { lat: 49.274182, lng: 21.6209 },
    { lat: 49.272595, lng: 21.62123 },
    { lat: 49.271433, lng: 21.621558 },
    { lat: 49.271383, lng: 21.621969 },
    { lat: 49.271381, lng: 21.621999 },
    { lat: 49.271071, lng: 21.622056 },
    { lat: 49.270909, lng: 21.622039 },
    { lat: 49.27073, lng: 21.622027 },
    { lat: 49.270562, lng: 21.622053 },
    { lat: 49.270311, lng: 21.622265 },
    { lat: 49.270217, lng: 21.622314 },
    { lat: 49.269938, lng: 21.622364 },
    { lat: 49.269664, lng: 21.622292 },
    { lat: 49.269666, lng: 21.622275 },
    { lat: 49.269389, lng: 21.622153 },
    { lat: 49.269093, lng: 21.622115 },
    { lat: 49.268982, lng: 21.622044 },
    { lat: 49.268691, lng: 21.621856 },
    { lat: 49.268468, lng: 21.621819 },
    { lat: 49.268235, lng: 21.621608 },
    { lat: 49.268037, lng: 21.621369 },
    { lat: 49.268018, lng: 21.621164 },
    { lat: 49.267884, lng: 21.620748 },
    { lat: 49.267723, lng: 21.620449 },
    { lat: 49.267594, lng: 21.620338 },
    { lat: 49.267048, lng: 21.619872 },
    { lat: 49.267014, lng: 21.619809 },
    { lat: 49.266732, lng: 21.619327 },
    { lat: 49.266631, lng: 21.619156 },
    { lat: 49.266469, lng: 21.618871 },
    { lat: 49.266179, lng: 21.618491 },
    { lat: 49.265892, lng: 21.618539 },
    { lat: 49.26559, lng: 21.61859 },
    { lat: 49.265074, lng: 21.618567 },
    { lat: 49.264988, lng: 21.618564 },
    { lat: 49.264482, lng: 21.618198 },
    { lat: 49.264377, lng: 21.618122 },
    { lat: 49.264176, lng: 21.617562 },
    { lat: 49.263688, lng: 21.617565 },
    { lat: 49.262904, lng: 21.617521 },
    { lat: 49.26241, lng: 21.617604 },
    { lat: 49.261923, lng: 21.617684 },
    { lat: 49.261329, lng: 21.617782 },
    { lat: 49.26065, lng: 21.617894 },
    { lat: 49.260539, lng: 21.617927 },
    { lat: 49.257701, lng: 21.61875 },
    { lat: 49.257522, lng: 21.618803 },
    { lat: 49.257488, lng: 21.618796 },
    { lat: 49.257235, lng: 21.618706 },
    { lat: 49.257051, lng: 21.618641 },
    { lat: 49.256684, lng: 21.618511 },
    { lat: 49.255557, lng: 21.619047 },
    { lat: 49.255128, lng: 21.619252 },
    { lat: 49.255073, lng: 21.619278 },
    { lat: 49.253715, lng: 21.619393 },
    { lat: 49.253254, lng: 21.619432 },
    { lat: 49.252309, lng: 21.619564 },
    { lat: 49.251217, lng: 21.619717 },
    { lat: 49.251164, lng: 21.619724 },
    { lat: 49.249977, lng: 21.619891 },
    { lat: 49.249874, lng: 21.619905 },
    { lat: 49.249857, lng: 21.619924 },
    { lat: 49.249147, lng: 21.62077 },
    { lat: 49.2484645, lng: 21.624092 },
    { lat: 49.2486274, lng: 21.6260966 },
    { lat: 49.2484541, lng: 21.6262168 },
    { lat: 49.2482971, lng: 21.6271371 },
    { lat: 49.2481202, lng: 21.6293463 },
    { lat: 49.2480861, lng: 21.6305296 },
    { lat: 49.2478771, lng: 21.63111 },
    { lat: 49.250259, lng: 21.6357364 },
    { lat: 49.2505711, lng: 21.6358296 },
    { lat: 49.2506651, lng: 21.6359406 },
    { lat: 49.2517651, lng: 21.6382835 },
    { lat: 49.2525522, lng: 21.6395035 },
    { lat: 49.2561489, lng: 21.6444769 },
    { lat: 49.2564493, lng: 21.6444704 },
    { lat: 49.2568968, lng: 21.6446293 },
    { lat: 49.2574135, lng: 21.6444401 },
    { lat: 49.258218, lng: 21.6445676 },
    { lat: 49.2586588, lng: 21.6445612 },
    { lat: 49.2597863, lng: 21.6448652 },
    { lat: 49.2601911, lng: 21.6448582 },
    { lat: 49.2603226, lng: 21.6449355 },
    { lat: 49.2604672, lng: 21.6451611 },
    { lat: 49.2606987, lng: 21.6452847 },
    { lat: 49.2618729, lng: 21.6450714 },
    { lat: 49.262246, lng: 21.6451817 },
    { lat: 49.2632208, lng: 21.645114 },
    { lat: 49.263548, lng: 21.6451929 },
    { lat: 49.2641573, lng: 21.6455893 },
    { lat: 49.2656432, lng: 21.6459246 },
    { lat: 49.266353, lng: 21.6463751 },
    { lat: 49.2667469, lng: 21.6467161 },
  ],
  Breznica: [
    { lat: 49.1787283, lng: 21.6423515 },
    { lat: 49.1778087, lng: 21.6425768 },
    { lat: 49.1775992, lng: 21.6415405 },
    { lat: 49.1752124, lng: 21.6425788 },
    { lat: 49.1752868, lng: 21.6436189 },
    { lat: 49.1748861, lng: 21.6438554 },
    { lat: 49.1747604, lng: 21.6449841 },
    { lat: 49.174537, lng: 21.6449178 },
    { lat: 49.1744493, lng: 21.6449622 },
    { lat: 49.173839, lng: 21.6451443 },
    { lat: 49.1733099, lng: 21.6450086 },
    { lat: 49.1719809, lng: 21.6460662 },
    { lat: 49.1713074, lng: 21.6461603 },
    { lat: 49.1707644, lng: 21.6460459 },
    { lat: 49.1689177, lng: 21.6469734 },
    { lat: 49.1666685, lng: 21.6483634 },
    { lat: 49.1650757, lng: 21.6491937 },
    { lat: 49.1630129, lng: 21.6492087 },
    { lat: 49.1615476, lng: 21.6491394 },
    { lat: 49.1556123, lng: 21.6502573 },
    { lat: 49.1550319, lng: 21.6501348 },
    { lat: 49.1523609, lng: 21.6509548 },
    { lat: 49.1519711, lng: 21.651262 },
    { lat: 49.151338, lng: 21.6511428 },
    { lat: 49.1494886, lng: 21.6513904 },
    { lat: 49.1483204, lng: 21.6524939 },
    { lat: 49.147061, lng: 21.655116 },
    { lat: 49.1464179, lng: 21.6546406 },
    { lat: 49.1458612, lng: 21.6542281 },
    { lat: 49.1455864, lng: 21.6547155 },
    { lat: 49.1451912, lng: 21.6551174 },
    { lat: 49.1451756, lng: 21.6555345 },
    { lat: 49.1448409, lng: 21.6558463 },
    { lat: 49.144955, lng: 21.6560292 },
    { lat: 49.1445745, lng: 21.6565926 },
    { lat: 49.1442189, lng: 21.6567951 },
    { lat: 49.1442635, lng: 21.6572824 },
    { lat: 49.1437279, lng: 21.6575265 },
    { lat: 49.1424976, lng: 21.6592808 },
    { lat: 49.142215, lng: 21.6600197 },
    { lat: 49.1419884, lng: 21.6607893 },
    { lat: 49.141521, lng: 21.6617631 },
    { lat: 49.1410011, lng: 21.6625863 },
    { lat: 49.1408742, lng: 21.6634164 },
    { lat: 49.1407297, lng: 21.6639365 },
    { lat: 49.1406784, lng: 21.6644981 },
    { lat: 49.1409993, lng: 21.6655867 },
    { lat: 49.1412361, lng: 21.6669745 },
    { lat: 49.1412907, lng: 21.6678646 },
    { lat: 49.1401787, lng: 21.669093 },
    { lat: 49.1401729, lng: 21.6695037 },
    { lat: 49.1400175, lng: 21.6697032 },
    { lat: 49.1398461, lng: 21.6701121 },
    { lat: 49.1396628, lng: 21.6703597 },
    { lat: 49.1390601, lng: 21.6709088 },
    { lat: 49.1381988, lng: 21.6714286 },
    { lat: 49.1372741, lng: 21.6724665 },
    { lat: 49.1371203, lng: 21.6725599 },
    { lat: 49.1367027, lng: 21.6731747 },
    { lat: 49.1363001, lng: 21.6738905 },
    { lat: 49.1358429, lng: 21.6742864 },
    { lat: 49.1352826, lng: 21.6746208 },
    { lat: 49.1355441, lng: 21.6763839 },
    { lat: 49.1356432, lng: 21.6767624 },
    { lat: 49.1356644, lng: 21.67725 },
    { lat: 49.1355719, lng: 21.6781989 },
    { lat: 49.1353763, lng: 21.6792031 },
    { lat: 49.1350753, lng: 21.6803475 },
    { lat: 49.1349257, lng: 21.6804298 },
    { lat: 49.1344417, lng: 21.6835925 },
    { lat: 49.1337785, lng: 21.683781 },
    { lat: 49.1333932, lng: 21.6840067 },
    { lat: 49.1329632, lng: 21.6846242 },
    { lat: 49.1327792, lng: 21.6848128 },
    { lat: 49.1326912, lng: 21.6852577 },
    { lat: 49.1327495, lng: 21.6858788 },
    { lat: 49.132947, lng: 21.6862824 },
    { lat: 49.1324427, lng: 21.6868898 },
    { lat: 49.1319942, lng: 21.6876547 },
    { lat: 49.1316436, lng: 21.6879679 },
    { lat: 49.1320334, lng: 21.6879033 },
    { lat: 49.1340523, lng: 21.6868829 },
    { lat: 49.134673, lng: 21.686664 },
    { lat: 49.1346286, lng: 21.6865206 },
    { lat: 49.1347625, lng: 21.6859004 },
    { lat: 49.135232, lng: 21.6858714 },
    { lat: 49.1352935, lng: 21.685311 },
    { lat: 49.1353864, lng: 21.6849614 },
    { lat: 49.1357524, lng: 21.6848882 },
    { lat: 49.1369371, lng: 21.6833521 },
    { lat: 49.1378212, lng: 21.6825987 },
    { lat: 49.1381478, lng: 21.6824424 },
    { lat: 49.1382493, lng: 21.6832451 },
    { lat: 49.1388538, lng: 21.6828868 },
    { lat: 49.1389489, lng: 21.6827008 },
    { lat: 49.1391815, lng: 21.6818574 },
    { lat: 49.1398112, lng: 21.680846 },
    { lat: 49.1401387, lng: 21.6801866 },
    { lat: 49.1413575, lng: 21.6786141 },
    { lat: 49.1412733, lng: 21.6781271 },
    { lat: 49.1412797, lng: 21.6778206 },
    { lat: 49.1419129, lng: 21.6775584 },
    { lat: 49.1422618, lng: 21.6774823 },
    { lat: 49.1433758, lng: 21.676703 },
    { lat: 49.1447673, lng: 21.6762035 },
    { lat: 49.1452851, lng: 21.676254 },
    { lat: 49.146584, lng: 21.6770309 },
    { lat: 49.1470611, lng: 21.6770379 },
    { lat: 49.1474109, lng: 21.6767055 },
    { lat: 49.1476265, lng: 21.676771 },
    { lat: 49.1484611, lng: 21.6764775 },
    { lat: 49.1492473, lng: 21.6759721 },
    { lat: 49.1503631, lng: 21.674798 },
    { lat: 49.1504653, lng: 21.6747945 },
    { lat: 49.1510227, lng: 21.6751059 },
    { lat: 49.1511738, lng: 21.6749113 },
    { lat: 49.1549868, lng: 21.6746757 },
    { lat: 49.1555412, lng: 21.67621 },
    { lat: 49.1557361, lng: 21.6773087 },
    { lat: 49.1569914, lng: 21.6793809 },
    { lat: 49.1571192, lng: 21.682746 },
    { lat: 49.1592222, lng: 21.682597 },
    { lat: 49.1601285, lng: 21.6811171 },
    { lat: 49.1626764, lng: 21.6850407 },
    { lat: 49.1619778, lng: 21.6875496 },
    { lat: 49.1625907, lng: 21.6878721 },
    { lat: 49.1630456, lng: 21.6884745 },
    { lat: 49.1631568, lng: 21.6884722 },
    { lat: 49.1632326, lng: 21.6882302 },
    { lat: 49.1633953, lng: 21.6881809 },
    { lat: 49.1636279, lng: 21.6886685 },
    { lat: 49.1653732, lng: 21.6878522 },
    { lat: 49.1667074, lng: 21.6881501 },
    { lat: 49.166724, lng: 21.6887673 },
    { lat: 49.1675167, lng: 21.6888773 },
    { lat: 49.1674509, lng: 21.6891856 },
    { lat: 49.1676539, lng: 21.6893325 },
    { lat: 49.1675103, lng: 21.690018 },
    { lat: 49.1685281, lng: 21.6898835 },
    { lat: 49.1686925, lng: 21.6899311 },
    { lat: 49.1687729, lng: 21.6906156 },
    { lat: 49.1689174, lng: 21.6908874 },
    { lat: 49.1691939, lng: 21.6911303 },
    { lat: 49.1695078, lng: 21.6916794 },
    { lat: 49.1695132, lng: 21.6922593 },
    { lat: 49.1696304, lng: 21.6928923 },
    { lat: 49.1704254, lng: 21.6920369 },
    { lat: 49.1711254, lng: 21.6922585 },
    { lat: 49.1712344, lng: 21.6924663 },
    { lat: 49.1713298, lng: 21.6929615 },
    { lat: 49.1711991, lng: 21.6932465 },
    { lat: 49.1712298, lng: 21.6933018 },
    { lat: 49.1713812, lng: 21.6932438 },
    { lat: 49.1717721, lng: 21.6947293 },
    { lat: 49.1722419, lng: 21.6948794 },
    { lat: 49.17306, lng: 21.694893 },
    { lat: 49.1734427, lng: 21.6952739 },
    { lat: 49.1736161, lng: 21.6956564 },
    { lat: 49.1737598, lng: 21.6964452 },
    { lat: 49.1740568, lng: 21.6973669 },
    { lat: 49.1746104, lng: 21.6982498 },
    { lat: 49.174829, lng: 21.6985156 },
    { lat: 49.1750869, lng: 21.6986145 },
    { lat: 49.1751383, lng: 21.6982633 },
    { lat: 49.1750995, lng: 21.6973615 },
    { lat: 49.175192, lng: 21.6962814 },
    { lat: 49.1758581, lng: 21.6967036 },
    { lat: 49.1761643, lng: 21.6970541 },
    { lat: 49.1763691, lng: 21.6965505 },
    { lat: 49.1765482, lng: 21.6958447 },
    { lat: 49.1766541, lng: 21.6956824 },
    { lat: 49.1766881, lng: 21.6951463 },
    { lat: 49.1765353, lng: 21.6949434 },
    { lat: 49.1764769, lng: 21.6946127 },
    { lat: 49.1763796, lng: 21.6945968 },
    { lat: 49.1764748, lng: 21.6943248 },
    { lat: 49.1763196, lng: 21.6940745 },
    { lat: 49.1759549, lng: 21.6937744 },
    { lat: 49.1755721, lng: 21.6936358 },
    { lat: 49.17505, lng: 21.6925374 },
    { lat: 49.1748591, lng: 21.6923041 },
    { lat: 49.175502, lng: 21.6920349 },
    { lat: 49.175276, lng: 21.6909934 },
    { lat: 49.1747593, lng: 21.6897669 },
    { lat: 49.1746192, lng: 21.6893601 },
    { lat: 49.1746068, lng: 21.6891983 },
    { lat: 49.1747768, lng: 21.6873573 },
    { lat: 49.1753676, lng: 21.6856524 },
    { lat: 49.1758112, lng: 21.6855908 },
    { lat: 49.1758734, lng: 21.6829632 },
    { lat: 49.1755149, lng: 21.6805588 },
    { lat: 49.1756008, lng: 21.6781958 },
    { lat: 49.1753675, lng: 21.6776841 },
    { lat: 49.1746288, lng: 21.6716122 },
    { lat: 49.1734382, lng: 21.6694817 },
    { lat: 49.172547, lng: 21.6639062 },
    { lat: 49.1723912, lng: 21.6616898 },
    { lat: 49.1723723, lng: 21.6593343 },
    { lat: 49.1734572, lng: 21.6614226 },
    { lat: 49.1741904, lng: 21.6611642 },
    { lat: 49.1743717, lng: 21.6610727 },
    { lat: 49.1775107, lng: 21.6594886 },
    { lat: 49.1782196, lng: 21.6552631 },
    { lat: 49.1794982, lng: 21.6486809 },
    { lat: 49.179472, lng: 21.6484625 },
    { lat: 49.1787283, lng: 21.6423515 },
  ],
  Mrázovce: [
    { lat: 49.1122414, lng: 21.6558475 },
    { lat: 49.1120391, lng: 21.6575118 },
    { lat: 49.1121725, lng: 21.6589412 },
    { lat: 49.1124123, lng: 21.6606437 },
    { lat: 49.1126351, lng: 21.6610598 },
    { lat: 49.1125944, lng: 21.66122 },
    { lat: 49.1125774, lng: 21.6626586 },
    { lat: 49.1123886, lng: 21.6635815 },
    { lat: 49.1122605, lng: 21.6636718 },
    { lat: 49.1123288, lng: 21.6638266 },
    { lat: 49.1120482, lng: 21.6649722 },
    { lat: 49.1101269, lng: 21.6680025 },
    { lat: 49.1071645, lng: 21.6718104 },
    { lat: 49.1064158, lng: 21.6725335 },
    { lat: 49.1061986, lng: 21.6732337 },
    { lat: 49.1064567, lng: 21.6736702 },
    { lat: 49.1063796, lng: 21.6743577 },
    { lat: 49.1061204, lng: 21.6749197 },
    { lat: 49.1059381, lng: 21.6750819 },
    { lat: 49.1055554, lng: 21.6757049 },
    { lat: 49.1052835, lng: 21.6760063 },
    { lat: 49.1052518, lng: 21.676368 },
    { lat: 49.1050994, lng: 21.6765568 },
    { lat: 49.1047673, lng: 21.6766358 },
    { lat: 49.1042453, lng: 21.6769317 },
    { lat: 49.1041983, lng: 21.6770136 },
    { lat: 49.1041352, lng: 21.6777238 },
    { lat: 49.1040221, lng: 21.678161 },
    { lat: 49.1032755, lng: 21.6797133 },
    { lat: 49.1035871, lng: 21.6803396 },
    { lat: 49.1047211, lng: 21.6824652 },
    { lat: 49.1054749, lng: 21.6838779 },
    { lat: 49.1060743, lng: 21.6836195 },
    { lat: 49.106242, lng: 21.6837541 },
    { lat: 49.1065527, lng: 21.6835124 },
    { lat: 49.1066743, lng: 21.6839281 },
    { lat: 49.1071464, lng: 21.684747 },
    { lat: 49.1075912, lng: 21.6849285 },
    { lat: 49.107885, lng: 21.6854394 },
    { lat: 49.1079613, lng: 21.6866856 },
    { lat: 49.1081668, lng: 21.6869187 },
    { lat: 49.1079965, lng: 21.6873613 },
    { lat: 49.1083364, lng: 21.6876222 },
    { lat: 49.1093215, lng: 21.6895287 },
    { lat: 49.1095333, lng: 21.6902501 },
    { lat: 49.1100573, lng: 21.690495 },
    { lat: 49.1101043, lng: 21.6905689 },
    { lat: 49.1101465, lng: 21.6909616 },
    { lat: 49.1106058, lng: 21.6918934 },
    { lat: 49.1109913, lng: 21.6922557 },
    { lat: 49.1111931, lng: 21.6926083 },
    { lat: 49.1124391, lng: 21.6925076 },
    { lat: 49.1126742, lng: 21.6924239 },
    { lat: 49.1128457, lng: 21.6922775 },
    { lat: 49.1130516, lng: 21.6922743 },
    { lat: 49.1132512, lng: 21.6919986 },
    { lat: 49.1137278, lng: 21.6910255 },
    { lat: 49.1145848, lng: 21.6898907 },
    { lat: 49.114862, lng: 21.6896102 },
    { lat: 49.1150999, lng: 21.6895048 },
    { lat: 49.1153621, lng: 21.6895071 },
    { lat: 49.1170325, lng: 21.6889222 },
    { lat: 49.1188731, lng: 21.6893063 },
    { lat: 49.1192568, lng: 21.6890916 },
    { lat: 49.1196277, lng: 21.6886676 },
    { lat: 49.1200536, lng: 21.6880247 },
    { lat: 49.1202648, lng: 21.68793 },
    { lat: 49.1207165, lng: 21.6875357 },
    { lat: 49.120695, lng: 21.6877788 },
    { lat: 49.1211027, lng: 21.6881351 },
    { lat: 49.1217139, lng: 21.688182 },
    { lat: 49.1223729, lng: 21.6884698 },
    { lat: 49.1229672, lng: 21.6873476 },
    { lat: 49.1232664, lng: 21.6870979 },
    { lat: 49.1233547, lng: 21.6865346 },
    { lat: 49.1240493, lng: 21.6855921 },
    { lat: 49.1243561, lng: 21.6852902 },
    { lat: 49.1247448, lng: 21.6850633 },
    { lat: 49.1251091, lng: 21.6850132 },
    { lat: 49.1260607, lng: 21.6851343 },
    { lat: 49.1262463, lng: 21.6852574 },
    { lat: 49.1270369, lng: 21.6855014 },
    { lat: 49.1271527, lng: 21.6853734 },
    { lat: 49.1272698, lng: 21.6854214 },
    { lat: 49.1274092, lng: 21.6853315 },
    { lat: 49.127603, lng: 21.6854709 },
    { lat: 49.127848, lng: 21.685211 },
    { lat: 49.1281549, lng: 21.6849924 },
    { lat: 49.1282187, lng: 21.6847248 },
    { lat: 49.1285012, lng: 21.6845713 },
    { lat: 49.1288118, lng: 21.6847778 },
    { lat: 49.1290133, lng: 21.6844979 },
    { lat: 49.1290114, lng: 21.6842179 },
    { lat: 49.1293021, lng: 21.6838943 },
    { lat: 49.1294663, lng: 21.6838542 },
    { lat: 49.1295814, lng: 21.6839972 },
    { lat: 49.1297935, lng: 21.6838025 },
    { lat: 49.129772, lng: 21.6834241 },
    { lat: 49.129813, lng: 21.6833644 },
    { lat: 49.1300156, lng: 21.6833654 },
    { lat: 49.1302682, lng: 21.6832217 },
    { lat: 49.1302973, lng: 21.6831297 },
    { lat: 49.1304148, lng: 21.683108 },
    { lat: 49.1304375, lng: 21.6829553 },
    { lat: 49.1305613, lng: 21.6827029 },
    { lat: 49.1307374, lng: 21.6827623 },
    { lat: 49.1308187, lng: 21.6826153 },
    { lat: 49.1308308, lng: 21.6823911 },
    { lat: 49.1309663, lng: 21.6822483 },
    { lat: 49.130977, lng: 21.6820148 },
    { lat: 49.1311286, lng: 21.6819191 },
    { lat: 49.131105, lng: 21.681676 },
    { lat: 49.1311464, lng: 21.6815397 },
    { lat: 49.1313642, lng: 21.6812329 },
    { lat: 49.1314212, lng: 21.6807602 },
    { lat: 49.1313996, lng: 21.6806692 },
    { lat: 49.1314912, lng: 21.6803039 },
    { lat: 49.1315765, lng: 21.6802601 },
    { lat: 49.1316912, lng: 21.6796368 },
    { lat: 49.1316683, lng: 21.6792866 },
    { lat: 49.1314788, lng: 21.6788936 },
    { lat: 49.1315309, lng: 21.6786956 },
    { lat: 49.1314968, lng: 21.6785997 },
    { lat: 49.1313573, lng: 21.6786038 },
    { lat: 49.131302, lng: 21.6783432 },
    { lat: 49.1311461, lng: 21.6780276 },
    { lat: 49.1311527, lng: 21.6778518 },
    { lat: 49.1308864, lng: 21.6777852 },
    { lat: 49.1306754, lng: 21.6774712 },
    { lat: 49.1305624, lng: 21.6772412 },
    { lat: 49.1304666, lng: 21.6768197 },
    { lat: 49.1300924, lng: 21.6762498 },
    { lat: 49.1297407, lng: 21.6758505 },
    { lat: 49.128979, lng: 21.6752614 },
    { lat: 49.1284617, lng: 21.6745284 },
    { lat: 49.1282611, lng: 21.6741797 },
    { lat: 49.1281421, lng: 21.6738154 },
    { lat: 49.1279311, lng: 21.6737887 },
    { lat: 49.1279391, lng: 21.6734984 },
    { lat: 49.1277728, lng: 21.673512 },
    { lat: 49.1276202, lng: 21.6731868 },
    { lat: 49.1273559, lng: 21.6731945 },
    { lat: 49.1271482, lng: 21.6732901 },
    { lat: 49.126732, lng: 21.6732071 },
    { lat: 49.126728, lng: 21.6732684 },
    { lat: 49.126383, lng: 21.6731608 },
    { lat: 49.1261756, lng: 21.6726479 },
    { lat: 49.1252509, lng: 21.6721454 },
    { lat: 49.1247521, lng: 21.6713028 },
    { lat: 49.1247608, lng: 21.6707883 },
    { lat: 49.1243777, lng: 21.6700222 },
    { lat: 49.1240892, lng: 21.6695852 },
    { lat: 49.1239962, lng: 21.6693109 },
    { lat: 49.1239723, lng: 21.6690046 },
    { lat: 49.1238864, lng: 21.6688703 },
    { lat: 49.1237419, lng: 21.6689753 },
    { lat: 49.1237205, lng: 21.6689342 },
    { lat: 49.1237313, lng: 21.6687051 },
    { lat: 49.1237615, lng: 21.6686996 },
    { lat: 49.1235701, lng: 21.6682832 },
    { lat: 49.1231768, lng: 21.667887 },
    { lat: 49.1229249, lng: 21.6680547 },
    { lat: 49.1228221, lng: 21.6678705 },
    { lat: 49.122764, lng: 21.6679277 },
    { lat: 49.1227031, lng: 21.667883 },
    { lat: 49.1226644, lng: 21.6676559 },
    { lat: 49.1225075, lng: 21.6675965 },
    { lat: 49.1223687, lng: 21.6671214 },
    { lat: 49.122124, lng: 21.6669745 },
    { lat: 49.1219951, lng: 21.666497 },
    { lat: 49.1213738, lng: 21.6660301 },
    { lat: 49.1213128, lng: 21.6657308 },
    { lat: 49.1210186, lng: 21.6652968 },
    { lat: 49.1209521, lng: 21.6653713 },
    { lat: 49.120826, lng: 21.6652866 },
    { lat: 49.1205403, lng: 21.6648679 },
    { lat: 49.1208015, lng: 21.6647139 },
    { lat: 49.1207544, lng: 21.6644277 },
    { lat: 49.1206033, lng: 21.6642914 },
    { lat: 49.120557, lng: 21.6641561 },
    { lat: 49.1206035, lng: 21.6641326 },
    { lat: 49.1201864, lng: 21.6628538 },
    { lat: 49.1201504, lng: 21.6627767 },
    { lat: 49.1200976, lng: 21.6629423 },
    { lat: 49.1200538, lng: 21.6628646 },
    { lat: 49.1202907, lng: 21.6621001 },
    { lat: 49.1204096, lng: 21.6614275 },
    { lat: 49.1205091, lng: 21.661238 },
    { lat: 49.1206847, lng: 21.6605323 },
    { lat: 49.1204429, lng: 21.6606582 },
    { lat: 49.1203896, lng: 21.660769 },
    { lat: 49.1206368, lng: 21.6598472 },
    { lat: 49.1206221, lng: 21.6592279 },
    { lat: 49.1207849, lng: 21.6588405 },
    { lat: 49.12066, lng: 21.6583394 },
    { lat: 49.1206655, lng: 21.6577188 },
    { lat: 49.1209294, lng: 21.657037 },
    { lat: 49.1196678, lng: 21.6560719 },
    { lat: 49.1181235, lng: 21.655422 },
    { lat: 49.1174266, lng: 21.6549866 },
    { lat: 49.114112, lng: 21.6537689 },
    { lat: 49.1139827, lng: 21.654147 },
    { lat: 49.1138233, lng: 21.6537919 },
    { lat: 49.1133264, lng: 21.6539231 },
    { lat: 49.1133081, lng: 21.6541142 },
    { lat: 49.1127681, lng: 21.6545347 },
    { lat: 49.1122414, lng: 21.6558475 },
  ],
  Potôčky: [
    { lat: 49.2172326, lng: 21.7292296 },
    { lat: 49.21684, lng: 21.7275397 },
    { lat: 49.2166122, lng: 21.7267521 },
    { lat: 49.2162057, lng: 21.7257618 },
    { lat: 49.2160385, lng: 21.7245113 },
    { lat: 49.2162662, lng: 21.7230888 },
    { lat: 49.2159253, lng: 21.7209254 },
    { lat: 49.2162196, lng: 21.7197262 },
    { lat: 49.2160915, lng: 21.7192686 },
    { lat: 49.2165127, lng: 21.7171687 },
    { lat: 49.2167215, lng: 21.7166545 },
    { lat: 49.2166376, lng: 21.7163736 },
    { lat: 49.2163537, lng: 21.715935 },
    { lat: 49.215861, lng: 21.7155953 },
    { lat: 49.2157786, lng: 21.7154608 },
    { lat: 49.2155949, lng: 21.7154028 },
    { lat: 49.2151008, lng: 21.7145872 },
    { lat: 49.2148804, lng: 21.7145545 },
    { lat: 49.214803, lng: 21.7142225 },
    { lat: 49.2145321, lng: 21.7140634 },
    { lat: 49.2144197, lng: 21.7136375 },
    { lat: 49.2142657, lng: 21.7135197 },
    { lat: 49.2142656, lng: 21.7132621 },
    { lat: 49.2140824, lng: 21.7131018 },
    { lat: 49.2141224, lng: 21.7127867 },
    { lat: 49.2140533, lng: 21.7128115 },
    { lat: 49.2139739, lng: 21.7127437 },
    { lat: 49.2138368, lng: 21.7123658 },
    { lat: 49.2147643, lng: 21.7102007 },
    { lat: 49.2155682, lng: 21.709594 },
    { lat: 49.21554, lng: 21.7085832 },
    { lat: 49.2148997, lng: 21.7084464 },
    { lat: 49.2148768, lng: 21.7079264 },
    { lat: 49.2142611, lng: 21.7076603 },
    { lat: 49.2143011, lng: 21.7070044 },
    { lat: 49.2147201, lng: 21.7060226 },
    { lat: 49.2146383, lng: 21.7059864 },
    { lat: 49.215017, lng: 21.7043763 },
    { lat: 49.2148256, lng: 21.7041122 },
    { lat: 49.2140595, lng: 21.7036116 },
    { lat: 49.2131218, lng: 21.7042533 },
    { lat: 49.2127576, lng: 21.7042275 },
    { lat: 49.2115236, lng: 21.7053636 },
    { lat: 49.2106302, lng: 21.7052239 },
    { lat: 49.2085531, lng: 21.7053343 },
    { lat: 49.2076104, lng: 21.7058352 },
    { lat: 49.2032831, lng: 21.7088376 },
    { lat: 49.2014136, lng: 21.7108166 },
    { lat: 49.2014226, lng: 21.7108589 },
    { lat: 49.1998189, lng: 21.7126874 },
    { lat: 49.197747, lng: 21.7145901 },
    { lat: 49.1954867, lng: 21.7178723 },
    { lat: 49.1954511, lng: 21.7179245 },
    { lat: 49.1957299, lng: 21.7189762 },
    { lat: 49.1958711, lng: 21.7193764 },
    { lat: 49.1962408, lng: 21.7199527 },
    { lat: 49.1962505, lng: 21.7200705 },
    { lat: 49.1964126, lng: 21.7203708 },
    { lat: 49.1968232, lng: 21.7208295 },
    { lat: 49.1968527, lng: 21.7216003 },
    { lat: 49.1969782, lng: 21.7220472 },
    { lat: 49.1971106, lng: 21.722294 },
    { lat: 49.1970216, lng: 21.7222859 },
    { lat: 49.1970475, lng: 21.7227572 },
    { lat: 49.1969626, lng: 21.7232779 },
    { lat: 49.1969872, lng: 21.7239544 },
    { lat: 49.1973639, lng: 21.7249681 },
    { lat: 49.197563, lng: 21.7259842 },
    { lat: 49.1978586, lng: 21.7285122 },
    { lat: 49.1981278, lng: 21.7293735 },
    { lat: 49.1983244, lng: 21.729784 },
    { lat: 49.1986427, lng: 21.7300791 },
    { lat: 49.1992585, lng: 21.7300116 },
    { lat: 49.1999239, lng: 21.7296571 },
    { lat: 49.2003498, lng: 21.7295574 },
    { lat: 49.2010306, lng: 21.7296013 },
    { lat: 49.201682, lng: 21.7290999 },
    { lat: 49.2023688, lng: 21.7286973 },
    { lat: 49.2028129, lng: 21.7287753 },
    { lat: 49.2032148, lng: 21.7289548 },
    { lat: 49.2038652, lng: 21.7291333 },
    { lat: 49.2042981, lng: 21.7294688 },
    { lat: 49.2045121, lng: 21.7298207 },
    { lat: 49.2046994, lng: 21.7303048 },
    { lat: 49.2047665, lng: 21.7313961 },
    { lat: 49.2051519, lng: 21.731672 },
    { lat: 49.2054844, lng: 21.7316593 },
    { lat: 49.2060275, lng: 21.731855 },
    { lat: 49.2070256, lng: 21.7320448 },
    { lat: 49.2077921, lng: 21.7320136 },
    { lat: 49.2081024, lng: 21.7319196 },
    { lat: 49.2083801, lng: 21.7317521 },
    { lat: 49.2088474, lng: 21.7318096 },
    { lat: 49.2092675, lng: 21.7320855 },
    { lat: 49.2095246, lng: 21.7323621 },
    { lat: 49.2097067, lng: 21.7328272 },
    { lat: 49.2102138, lng: 21.7333651 },
    { lat: 49.2108859, lng: 21.7337672 },
    { lat: 49.211408, lng: 21.7342451 },
    { lat: 49.211771, lng: 21.7343537 },
    { lat: 49.2124121, lng: 21.7343522 },
    { lat: 49.2126763, lng: 21.7343057 },
    { lat: 49.2131023, lng: 21.7336577 },
    { lat: 49.2136125, lng: 21.733252 },
    { lat: 49.2136748, lng: 21.7331024 },
    { lat: 49.2141263, lng: 21.7326093 },
    { lat: 49.2149901, lng: 21.732009 },
    { lat: 49.2154876, lng: 21.7315515 },
    { lat: 49.2161168, lng: 21.7307624 },
    { lat: 49.2164747, lng: 21.7300091 },
    { lat: 49.2167469, lng: 21.7296717 },
    { lat: 49.2172071, lng: 21.729337 },
    { lat: 49.2172326, lng: 21.7292296 },
  ],
  Lomné: [
    { lat: 49.1122665, lng: 21.6500382 },
    { lat: 49.1120038, lng: 21.64853 },
    { lat: 49.1128181, lng: 21.6478206 },
    { lat: 49.1130295, lng: 21.6475246 },
    { lat: 49.1132782, lng: 21.6474047 },
    { lat: 49.1142586, lng: 21.6464789 },
    { lat: 49.1145589, lng: 21.6469261 },
    { lat: 49.1158474, lng: 21.6484731 },
    { lat: 49.1163679, lng: 21.6475128 },
    { lat: 49.1174973, lng: 21.6468028 },
    { lat: 49.1181656, lng: 21.6461887 },
    { lat: 49.11931, lng: 21.6460353 },
    { lat: 49.1224801, lng: 21.6453316 },
    { lat: 49.1233142, lng: 21.6443592 },
    { lat: 49.1237909, lng: 21.6440581 },
    { lat: 49.1238767, lng: 21.6436598 },
    { lat: 49.1238722, lng: 21.643039 },
    { lat: 49.1240087, lng: 21.6421485 },
    { lat: 49.1239751, lng: 21.6411245 },
    { lat: 49.1242227, lng: 21.6405064 },
    { lat: 49.1248509, lng: 21.6396185 },
    { lat: 49.1250857, lng: 21.6389534 },
    { lat: 49.1250486, lng: 21.6384597 },
    { lat: 49.1251897, lng: 21.6375678 },
    { lat: 49.1249132, lng: 21.6375437 },
    { lat: 49.1253913, lng: 21.6363032 },
    { lat: 49.125008, lng: 21.6362794 },
    { lat: 49.1247955, lng: 21.6364029 },
    { lat: 49.1244296, lng: 21.6364373 },
    { lat: 49.1245754, lng: 21.6359672 },
    { lat: 49.1248141, lng: 21.6356438 },
    { lat: 49.1250106, lng: 21.635012 },
    { lat: 49.1252503, lng: 21.6339109 },
    { lat: 49.1260486, lng: 21.6322947 },
    { lat: 49.1268644, lng: 21.6316368 },
    { lat: 49.1268698, lng: 21.6311038 },
    { lat: 49.1270698, lng: 21.6297738 },
    { lat: 49.126864, lng: 21.6279384 },
    { lat: 49.1263172, lng: 21.6283465 },
    { lat: 49.1254394, lng: 21.6294409 },
    { lat: 49.124713, lng: 21.629531 },
    { lat: 49.1242851, lng: 21.6294358 },
    { lat: 49.1236548, lng: 21.6296354 },
    { lat: 49.1233911, lng: 21.6294931 },
    { lat: 49.1230599, lng: 21.6295185 },
    { lat: 49.1225085, lng: 21.6299659 },
    { lat: 49.1219285, lng: 21.6307212 },
    { lat: 49.1217597, lng: 21.6324395 },
    { lat: 49.1212202, lng: 21.6334418 },
    { lat: 49.1205443, lng: 21.6311096 },
    { lat: 49.120242, lng: 21.630547 },
    { lat: 49.1196631, lng: 21.6298746 },
    { lat: 49.1192613, lng: 21.6290005 },
    { lat: 49.1180813, lng: 21.6277335 },
    { lat: 49.1176375, lng: 21.6276343 },
    { lat: 49.1173859, lng: 21.6273594 },
    { lat: 49.1171838, lng: 21.6268839 },
    { lat: 49.1169171, lng: 21.6265116 },
    { lat: 49.1165692, lng: 21.6266257 },
    { lat: 49.1163849, lng: 21.6262661 },
    { lat: 49.115964, lng: 21.6261363 },
    { lat: 49.1154098, lng: 21.6257852 },
    { lat: 49.1152831, lng: 21.625961 },
    { lat: 49.1151362, lng: 21.625737 },
    { lat: 49.1147797, lng: 21.6260689 },
    { lat: 49.114638, lng: 21.6254606 },
    { lat: 49.1140433, lng: 21.6244822 },
    { lat: 49.1131777, lng: 21.6208669 },
    { lat: 49.1130865, lng: 21.6207065 },
    { lat: 49.1129472, lng: 21.6197303 },
    { lat: 49.1126782, lng: 21.6188311 },
    { lat: 49.1128194, lng: 21.6180672 },
    { lat: 49.1128915, lng: 21.6168082 },
    { lat: 49.1130117, lng: 21.6159006 },
    { lat: 49.1125069, lng: 21.6156678 },
    { lat: 49.1106738, lng: 21.6152848 },
    { lat: 49.1106155, lng: 21.6148034 },
    { lat: 49.1088066, lng: 21.6142362 },
    { lat: 49.1088339, lng: 21.6138872 },
    { lat: 49.1062443, lng: 21.6124184 },
    { lat: 49.1058545, lng: 21.6121163 },
    { lat: 49.1058658, lng: 21.6118761 },
    { lat: 49.1061177, lng: 21.6116248 },
    { lat: 49.1061483, lng: 21.6112206 },
    { lat: 49.1059836, lng: 21.6107949 },
    { lat: 49.1059868, lng: 21.6105125 },
    { lat: 49.1061273, lng: 21.6102981 },
    { lat: 49.1060245, lng: 21.6095963 },
    { lat: 49.1059418, lng: 21.6094091 },
    { lat: 49.1059319, lng: 21.6091307 },
    { lat: 49.1060052, lng: 21.6087257 },
    { lat: 49.1059322, lng: 21.6084901 },
    { lat: 49.1057567, lng: 21.6084522 },
    { lat: 49.1056417, lng: 21.6081551 },
    { lat: 49.1040071, lng: 21.6081177 },
    { lat: 49.1037617, lng: 21.6085585 },
    { lat: 49.1031109, lng: 21.6086649 },
    { lat: 49.1028605, lng: 21.6075815 },
    { lat: 49.1026671, lng: 21.6061606 },
    { lat: 49.1023745, lng: 21.6055026 },
    { lat: 49.1022066, lng: 21.6049673 },
    { lat: 49.1016184, lng: 21.6018828 },
    { lat: 49.1003104, lng: 21.5992153 },
    { lat: 49.100122, lng: 21.598886 },
    { lat: 49.100046, lng: 21.598738 },
    { lat: 49.099594, lng: 21.599327 },
    { lat: 49.099335, lng: 21.599723 },
    { lat: 49.099057, lng: 21.600214 },
    { lat: 49.098783, lng: 21.600732 },
    { lat: 49.098659, lng: 21.601155 },
    { lat: 49.098465, lng: 21.601685 },
    { lat: 49.098481, lng: 21.60185 },
    { lat: 49.09833, lng: 21.602103 },
    { lat: 49.098356, lng: 21.60239 },
    { lat: 49.098296, lng: 21.602484 },
    { lat: 49.098014, lng: 21.60266 },
    { lat: 49.097854, lng: 21.602631 },
    { lat: 49.09726, lng: 21.602555 },
    { lat: 49.097144, lng: 21.603018 },
    { lat: 49.097062, lng: 21.60339 },
    { lat: 49.09695, lng: 21.603698 },
    { lat: 49.09669, lng: 21.604122 },
    { lat: 49.096444, lng: 21.604279 },
    { lat: 49.094846, lng: 21.605937 },
    { lat: 49.094522, lng: 21.606203 },
    { lat: 49.09376, lng: 21.606541 },
    { lat: 49.093391, lng: 21.607044 },
    { lat: 49.092932, lng: 21.60717 },
    { lat: 49.092969, lng: 21.607382 },
    { lat: 49.092996, lng: 21.607796 },
    { lat: 49.093217, lng: 21.608054 },
    { lat: 49.092157, lng: 21.608153 },
    { lat: 49.091924, lng: 21.608127 },
    { lat: 49.091455, lng: 21.608386 },
    { lat: 49.0913609, lng: 21.608446 },
    { lat: 49.0912401, lng: 21.6095346 },
    { lat: 49.0914624, lng: 21.6113428 },
    { lat: 49.0916094, lng: 21.6117006 },
    { lat: 49.0920174, lng: 21.6120536 },
    { lat: 49.0921952, lng: 21.6122964 },
    { lat: 49.0926397, lng: 21.6134023 },
    { lat: 49.0919425, lng: 21.6138686 },
    { lat: 49.0917906, lng: 21.6141063 },
    { lat: 49.0917597, lng: 21.6145408 },
    { lat: 49.0916221, lng: 21.6149344 },
    { lat: 49.0917635, lng: 21.6153698 },
    { lat: 49.0919632, lng: 21.6167746 },
    { lat: 49.0922418, lng: 21.6171308 },
    { lat: 49.092229, lng: 21.6189936 },
    { lat: 49.0926742, lng: 21.6198349 },
    { lat: 49.0930283, lng: 21.6201182 },
    { lat: 49.0933727, lng: 21.6207369 },
    { lat: 49.0937317, lng: 21.621231 },
    { lat: 49.0938717, lng: 21.6218208 },
    { lat: 49.0947497, lng: 21.622407 },
    { lat: 49.0950282, lng: 21.6230882 },
    { lat: 49.0951888, lng: 21.6233612 },
    { lat: 49.0947838, lng: 21.6241003 },
    { lat: 49.0951106, lng: 21.6244029 },
    { lat: 49.0946792, lng: 21.6252105 },
    { lat: 49.0943573, lng: 21.6265958 },
    { lat: 49.0949371, lng: 21.6277902 },
    { lat: 49.0948044, lng: 21.6282908 },
    { lat: 49.0950405, lng: 21.6291025 },
    { lat: 49.0948286, lng: 21.629746 },
    { lat: 49.0949085, lng: 21.6302356 },
    { lat: 49.0950737, lng: 21.630844 },
    { lat: 49.0952756, lng: 21.6310695 },
    { lat: 49.0953819, lng: 21.6314131 },
    { lat: 49.0952853, lng: 21.6319132 },
    { lat: 49.0954622, lng: 21.6324168 },
    { lat: 49.0956331, lng: 21.6325807 },
    { lat: 49.0957743, lng: 21.6325172 },
    { lat: 49.0971488, lng: 21.6341483 },
    { lat: 49.0974917, lng: 21.6340568 },
    { lat: 49.0979286, lng: 21.6351782 },
    { lat: 49.0981564, lng: 21.6361883 },
    { lat: 49.0981386, lng: 21.6366493 },
    { lat: 49.098265, lng: 21.6371166 },
    { lat: 49.0983858, lng: 21.638238 },
    { lat: 49.1005962, lng: 21.646045 },
    { lat: 49.111439, lng: 21.649646 },
    { lat: 49.1116832, lng: 21.6495569 },
    { lat: 49.1122665, lng: 21.6500382 },
  ],
  Brusnica: [
    { lat: 49.1316436, lng: 21.6879679 },
    { lat: 49.1316394, lng: 21.6880142 },
    { lat: 49.1307485, lng: 21.6885628 },
    { lat: 49.1305359, lng: 21.6888537 },
    { lat: 49.1300932, lng: 21.6892467 },
    { lat: 49.129893, lng: 21.6895869 },
    { lat: 49.1297697, lng: 21.6901047 },
    { lat: 49.1291587, lng: 21.6914001 },
    { lat: 49.1288211, lng: 21.6923975 },
    { lat: 49.1288082, lng: 21.6924626 },
    { lat: 49.1289116, lng: 21.6927065 },
    { lat: 49.1289513, lng: 21.6930496 },
    { lat: 49.1288779, lng: 21.6932924 },
    { lat: 49.128516, lng: 21.694106 },
    { lat: 49.128364, lng: 21.6942667 },
    { lat: 49.1280622, lng: 21.6943767 },
    { lat: 49.1278746, lng: 21.6947649 },
    { lat: 49.1275274, lng: 21.695029 },
    { lat: 49.1273085, lng: 21.6954277 },
    { lat: 49.1268644, lng: 21.696481 },
    { lat: 49.1266146, lng: 21.6977412 },
    { lat: 49.1266915, lng: 21.6991383 },
    { lat: 49.126353, lng: 21.6999823 },
    { lat: 49.1262238, lng: 21.7001904 },
    { lat: 49.1252588, lng: 21.7007825 },
    { lat: 49.1248657, lng: 21.701192 },
    { lat: 49.1243828, lng: 21.7019361 },
    { lat: 49.1241539, lng: 21.7027521 },
    { lat: 49.1242211, lng: 21.7033323 },
    { lat: 49.1240832, lng: 21.7040628 },
    { lat: 49.1242844, lng: 21.7057298 },
    { lat: 49.1243174, lng: 21.7064597 },
    { lat: 49.1240812, lng: 21.7075965 },
    { lat: 49.1244793, lng: 21.7079744 },
    { lat: 49.1249529, lng: 21.7092358 },
    { lat: 49.1251595, lng: 21.7094324 },
    { lat: 49.1253297, lng: 21.7098191 },
    { lat: 49.125298, lng: 21.7103181 },
    { lat: 49.1251944, lng: 21.7106507 },
    { lat: 49.1251137, lng: 21.7113321 },
    { lat: 49.1246546, lng: 21.7123346 },
    { lat: 49.1244807, lng: 21.7124787 },
    { lat: 49.1237457, lng: 21.7133953 },
    { lat: 49.1231252, lng: 21.7147171 },
    { lat: 49.123033, lng: 21.7157669 },
    { lat: 49.1231196, lng: 21.7163182 },
    { lat: 49.1234202, lng: 21.7169547 },
    { lat: 49.123428, lng: 21.717259 },
    { lat: 49.12344, lng: 21.717293 },
    { lat: 49.124057, lng: 21.718422 },
    { lat: 49.124526, lng: 21.718965 },
    { lat: 49.124776, lng: 21.718842 },
    { lat: 49.124964, lng: 21.718957 },
    { lat: 49.125146, lng: 21.718813 },
    { lat: 49.125511, lng: 21.719468 },
    { lat: 49.125583, lng: 21.719351 },
    { lat: 49.125776, lng: 21.719528 },
    { lat: 49.125986, lng: 21.719316 },
    { lat: 49.126221, lng: 21.719698 },
    { lat: 49.1264923, lng: 21.7194841 },
    { lat: 49.1269608, lng: 21.7192497 },
    { lat: 49.1275767, lng: 21.7191056 },
    { lat: 49.1278489, lng: 21.7186807 },
    { lat: 49.1281213, lng: 21.71856 },
    { lat: 49.1284044, lng: 21.7179936 },
    { lat: 49.1289928, lng: 21.7171547 },
    { lat: 49.1295638, lng: 21.7167608 },
    { lat: 49.130604, lng: 21.7165354 },
    { lat: 49.1306836, lng: 21.7162686 },
    { lat: 49.1308722, lng: 21.7160935 },
    { lat: 49.131141, lng: 21.7160445 },
    { lat: 49.1316689, lng: 21.715003 },
    { lat: 49.1319983, lng: 21.7146198 },
    { lat: 49.132379, lng: 21.7142923 },
    { lat: 49.1327489, lng: 21.7142072 },
    { lat: 49.133423, lng: 21.7142541 },
    { lat: 49.1336537, lng: 21.7139596 },
    { lat: 49.1336669, lng: 21.7139968 },
    { lat: 49.1345245, lng: 21.7133782 },
    { lat: 49.1346985, lng: 21.7132377 },
    { lat: 49.1348483, lng: 21.7129974 },
    { lat: 49.1357769, lng: 21.7123014 },
    { lat: 49.1367453, lng: 21.7106885 },
    { lat: 49.1370641, lng: 21.7106666 },
    { lat: 49.1373662, lng: 21.7103547 },
    { lat: 49.1376347, lng: 21.7096644 },
    { lat: 49.1377235, lng: 21.7087395 },
    { lat: 49.1379485, lng: 21.7083177 },
    { lat: 49.1391285, lng: 21.7073052 },
    { lat: 49.141361, lng: 21.7066109 },
    { lat: 49.1422998, lng: 21.706554 },
    { lat: 49.1426219, lng: 21.7068595 },
    { lat: 49.1430901, lng: 21.7069931 },
    { lat: 49.1435827, lng: 21.7068655 },
    { lat: 49.1439061, lng: 21.7068827 },
    { lat: 49.1439897, lng: 21.7068179 },
    { lat: 49.1448846, lng: 21.7071284 },
    { lat: 49.1457518, lng: 21.7072951 },
    { lat: 49.1460338, lng: 21.7074461 },
    { lat: 49.1460946, lng: 21.7073219 },
    { lat: 49.1465299, lng: 21.7075313 },
    { lat: 49.1463738, lng: 21.7078813 },
    { lat: 49.1464464, lng: 21.7079005 },
    { lat: 49.1462787, lng: 21.7085311 },
    { lat: 49.1462633, lng: 21.7088346 },
    { lat: 49.146401, lng: 21.7098261 },
    { lat: 49.1466063, lng: 21.709799 },
    { lat: 49.1468325, lng: 21.7096782 },
    { lat: 49.1469011, lng: 21.7101393 },
    { lat: 49.1469582, lng: 21.7102971 },
    { lat: 49.1470768, lng: 21.7104061 },
    { lat: 49.1472952, lng: 21.7113369 },
    { lat: 49.1472675, lng: 21.7118162 },
    { lat: 49.1471732, lng: 21.7122403 },
    { lat: 49.1472903, lng: 21.7132518 },
    { lat: 49.1472718, lng: 21.7135731 },
    { lat: 49.1469427, lng: 21.7138468 },
    { lat: 49.1468561, lng: 21.7141447 },
    { lat: 49.1468075, lng: 21.7146357 },
    { lat: 49.1466484, lng: 21.7146636 },
    { lat: 49.1466435, lng: 21.7152769 },
    { lat: 49.1463572, lng: 21.7153431 },
    { lat: 49.1463236, lng: 21.7156029 },
    { lat: 49.1463542, lng: 21.7161123 },
    { lat: 49.1458973, lng: 21.7162337 },
    { lat: 49.1458971, lng: 21.7167745 },
    { lat: 49.145745, lng: 21.7170214 },
    { lat: 49.145749, lng: 21.7180104 },
    { lat: 49.1458126, lng: 21.7180334 },
    { lat: 49.1458359, lng: 21.718194 },
    { lat: 49.1457141, lng: 21.718558 },
    { lat: 49.1457518, lng: 21.718795 },
    { lat: 49.1458531, lng: 21.7190036 },
    { lat: 49.1459006, lng: 21.719858 },
    { lat: 49.1458524, lng: 21.7205815 },
    { lat: 49.145871, lng: 21.7209066 },
    { lat: 49.1463228, lng: 21.7210985 },
    { lat: 49.1467333, lng: 21.7215187 },
    { lat: 49.1467901, lng: 21.7216668 },
    { lat: 49.1467426, lng: 21.7222033 },
    { lat: 49.1466522, lng: 21.7222697 },
    { lat: 49.1463322, lng: 21.7228425 },
    { lat: 49.1462944, lng: 21.7231556 },
    { lat: 49.1457783, lng: 21.7237567 },
    { lat: 49.1455468, lng: 21.7238965 },
    { lat: 49.1457148, lng: 21.724327 },
    { lat: 49.1453818, lng: 21.7247126 },
    { lat: 49.1450846, lng: 21.7249168 },
    { lat: 49.1449334, lng: 21.7249391 },
    { lat: 49.1446877, lng: 21.7252554 },
    { lat: 49.1445248, lng: 21.725564 },
    { lat: 49.1441986, lng: 21.7266654 },
    { lat: 49.1437171, lng: 21.7277587 },
    { lat: 49.1436523, lng: 21.7281611 },
    { lat: 49.1436932, lng: 21.7285999 },
    { lat: 49.1439324, lng: 21.7292613 },
    { lat: 49.1439969, lng: 21.7297388 },
    { lat: 49.1442646, lng: 21.729824 },
    { lat: 49.1440129, lng: 21.7302606 },
    { lat: 49.1440114, lng: 21.7304188 },
    { lat: 49.144186, lng: 21.7304224 },
    { lat: 49.1444439, lng: 21.7305574 },
    { lat: 49.1447021, lng: 21.7304962 },
    { lat: 49.1446771, lng: 21.7308707 },
    { lat: 49.1444744, lng: 21.7311214 },
    { lat: 49.1446996, lng: 21.7316738 },
    { lat: 49.1445506, lng: 21.7319938 },
    { lat: 49.1442824, lng: 21.7328655 },
    { lat: 49.1444637, lng: 21.7334702 },
    { lat: 49.1444562, lng: 21.7336942 },
    { lat: 49.1442775, lng: 21.7338263 },
    { lat: 49.1440726, lng: 21.7341156 },
    { lat: 49.1438705, lng: 21.734602 },
    { lat: 49.1437257, lng: 21.7351164 },
    { lat: 49.1437472, lng: 21.7355082 },
    { lat: 49.1434169, lng: 21.7362903 },
    { lat: 49.1432529, lng: 21.7363363 },
    { lat: 49.1428733, lng: 21.7367402 },
    { lat: 49.1427539, lng: 21.7367382 },
    { lat: 49.1424175, lng: 21.7373694 },
    { lat: 49.1422826, lng: 21.7385648 },
    { lat: 49.1420391, lng: 21.7389678 },
    { lat: 49.1418712, lng: 21.7400589 },
    { lat: 49.1419016, lng: 21.7405769 },
    { lat: 49.1420093, lng: 21.741073 },
    { lat: 49.1421988, lng: 21.7413469 },
    { lat: 49.1423735, lng: 21.7418672 },
    { lat: 49.1431447, lng: 21.7410883 },
    { lat: 49.1434863, lng: 21.7403957 },
    { lat: 49.1439262, lng: 21.740138 },
    { lat: 49.1442595, lng: 21.7402346 },
    { lat: 49.1449437, lng: 21.74025 },
    { lat: 49.1452866, lng: 21.7403274 },
    { lat: 49.1459021, lng: 21.741094 },
    { lat: 49.1463105, lng: 21.7411767 },
    { lat: 49.1469294, lng: 21.7416187 },
    { lat: 49.1471383, lng: 21.7418617 },
    { lat: 49.1476139, lng: 21.742863 },
    { lat: 49.1479516, lng: 21.7432152 },
    { lat: 49.1482994, lng: 21.743265 },
    { lat: 49.149871, lng: 21.7426347 },
    { lat: 49.1502989, lng: 21.7430731 },
    { lat: 49.1505073, lng: 21.7427897 },
    { lat: 49.1509794, lng: 21.742351 },
    { lat: 49.151294, lng: 21.7422051 },
    { lat: 49.1515732, lng: 21.7419221 },
    { lat: 49.1518668, lng: 21.7412762 },
    { lat: 49.1521574, lng: 21.740831 },
    { lat: 49.1526612, lng: 21.7399667 },
    { lat: 49.1527964, lng: 21.739554 },
    { lat: 49.1530117, lng: 21.7391034 },
    { lat: 49.1530985, lng: 21.7385996 },
    { lat: 49.1530849, lng: 21.7381743 },
    { lat: 49.1535084, lng: 21.7363639 },
    { lat: 49.1535712, lng: 21.735808 },
    { lat: 49.1537608, lng: 21.7351962 },
    { lat: 49.1537541, lng: 21.734775 },
    { lat: 49.1539194, lng: 21.7341384 },
    { lat: 49.1546855, lng: 21.7323528 },
    { lat: 49.1553592, lng: 21.7315436 },
    { lat: 49.1556612, lng: 21.7311005 },
    { lat: 49.155933, lng: 21.7308688 },
    { lat: 49.1561614, lng: 21.7302761 },
    { lat: 49.1563077, lng: 21.7295608 },
    { lat: 49.1562751, lng: 21.7289271 },
    { lat: 49.1563651, lng: 21.7285017 },
    { lat: 49.1570125, lng: 21.7289498 },
    { lat: 49.1571259, lng: 21.727884 },
    { lat: 49.1573587, lng: 21.7274976 },
    { lat: 49.1573334, lng: 21.7272207 },
    { lat: 49.1577359, lng: 21.7260299 },
    { lat: 49.1579552, lng: 21.7258528 },
    { lat: 49.1582796, lng: 21.7248972 },
    { lat: 49.1585403, lng: 21.7245529 },
    { lat: 49.1591315, lng: 21.7234805 },
    { lat: 49.1593297, lng: 21.7229729 },
    { lat: 49.159673, lng: 21.7223123 },
    { lat: 49.159799, lng: 21.7218501 },
    { lat: 49.1596191, lng: 21.7213854 },
    { lat: 49.1597966, lng: 21.7209546 },
    { lat: 49.1601489, lng: 21.7208325 },
    { lat: 49.1601999, lng: 21.7206335 },
    { lat: 49.1597129, lng: 21.7190775 },
    { lat: 49.159511, lng: 21.7188873 },
    { lat: 49.1595788, lng: 21.7183946 },
    { lat: 49.1597282, lng: 21.7184303 },
    { lat: 49.1597895, lng: 21.7176422 },
    { lat: 49.1596284, lng: 21.7178855 },
    { lat: 49.1595358, lng: 21.7177899 },
    { lat: 49.159687, lng: 21.7175113 },
    { lat: 49.159571, lng: 21.717255 },
    { lat: 49.1594271, lng: 21.7172596 },
    { lat: 49.1594893, lng: 21.7170189 },
    { lat: 49.1596702, lng: 21.7168837 },
    { lat: 49.1595543, lng: 21.7164617 },
    { lat: 49.1600876, lng: 21.7162069 },
    { lat: 49.1604833, lng: 21.7159045 },
    { lat: 49.1612519, lng: 21.715919 },
    { lat: 49.1615932, lng: 21.7160132 },
    { lat: 49.1622217, lng: 21.7160526 },
    { lat: 49.1626068, lng: 21.7163141 },
    { lat: 49.1629347, lng: 21.7161266 },
    { lat: 49.1634026, lng: 21.7161633 },
    { lat: 49.1638971, lng: 21.7160209 },
    { lat: 49.1643829, lng: 21.7159753 },
    { lat: 49.1648896, lng: 21.7153173 },
    { lat: 49.165385, lng: 21.7144451 },
    { lat: 49.1657962, lng: 21.7140076 },
    { lat: 49.1659218, lng: 21.7137428 },
    { lat: 49.1660192, lng: 21.7138065 },
    { lat: 49.166128, lng: 21.7135369 },
    { lat: 49.166514, lng: 21.7131675 },
    { lat: 49.1665282, lng: 21.7126846 },
    { lat: 49.1675237, lng: 21.7123866 },
    { lat: 49.1678866, lng: 21.712116 },
    { lat: 49.1680036, lng: 21.711829 },
    { lat: 49.1681703, lng: 21.7111594 },
    { lat: 49.1685436, lng: 21.7102651 },
    { lat: 49.1687645, lng: 21.709994 },
    { lat: 49.1690573, lng: 21.7097921 },
    { lat: 49.1691168, lng: 21.7100749 },
    { lat: 49.1692366, lng: 21.7100154 },
    { lat: 49.1693408, lng: 21.7100416 },
    { lat: 49.1694046, lng: 21.710286 },
    { lat: 49.1694823, lng: 21.7102837 },
    { lat: 49.1698304, lng: 21.7105188 },
    { lat: 49.1698561, lng: 21.7105902 },
    { lat: 49.1697574, lng: 21.7107343 },
    { lat: 49.1696485, lng: 21.7107774 },
    { lat: 49.1697583, lng: 21.7109356 },
    { lat: 49.1699996, lng: 21.7110827 },
    { lat: 49.1703017, lng: 21.711742 },
    { lat: 49.170211, lng: 21.7119622 },
    { lat: 49.1703903, lng: 21.712147 },
    { lat: 49.1706157, lng: 21.7122463 },
    { lat: 49.1706819, lng: 21.7124108 },
    { lat: 49.1708738, lng: 21.7126426 },
    { lat: 49.1709436, lng: 21.7126501 },
    { lat: 49.1710594, lng: 21.7124761 },
    { lat: 49.1711046, lng: 21.7125722 },
    { lat: 49.1713835, lng: 21.7125631 },
    { lat: 49.1713953, lng: 21.7129251 },
    { lat: 49.1714593, lng: 21.7130438 },
    { lat: 49.1716302, lng: 21.7130443 },
    { lat: 49.1716896, lng: 21.712826 },
    { lat: 49.1718257, lng: 21.7133306 },
    { lat: 49.1721249, lng: 21.7131559 },
    { lat: 49.1723687, lng: 21.7131516 },
    { lat: 49.1724098, lng: 21.7133845 },
    { lat: 49.1724848, lng: 21.7134453 },
    { lat: 49.17254, lng: 21.71337 },
    { lat: 49.1727388, lng: 21.7133558 },
    { lat: 49.1728238, lng: 21.7132542 },
    { lat: 49.1728894, lng: 21.7133102 },
    { lat: 49.1729351, lng: 21.7131667 },
    { lat: 49.1733768, lng: 21.7125206 },
    { lat: 49.1732156, lng: 21.7120894 },
    { lat: 49.1733457, lng: 21.711523 },
    { lat: 49.173534, lng: 21.7114135 },
    { lat: 49.1734612, lng: 21.7110463 },
    { lat: 49.1735043, lng: 21.7108491 },
    { lat: 49.173452, lng: 21.7105791 },
    { lat: 49.1735315, lng: 21.7102722 },
    { lat: 49.173501, lng: 21.7102234 },
    { lat: 49.1737258, lng: 21.7099991 },
    { lat: 49.1738764, lng: 21.70857 },
    { lat: 49.1738249, lng: 21.7084506 },
    { lat: 49.1739057, lng: 21.7077707 },
    { lat: 49.1737574, lng: 21.7068538 },
    { lat: 49.1737471, lng: 21.7065111 },
    { lat: 49.1737948, lng: 21.7062751 },
    { lat: 49.1737559, lng: 21.7059953 },
    { lat: 49.1738293, lng: 21.7054597 },
    { lat: 49.1743179, lng: 21.7048048 },
    { lat: 49.1750889, lng: 21.7017054 },
    { lat: 49.1751399, lng: 21.7010482 },
    { lat: 49.1750333, lng: 21.7008535 },
    { lat: 49.1749557, lng: 21.6995061 },
    { lat: 49.174829, lng: 21.6985156 },
    { lat: 49.1746104, lng: 21.6982498 },
    { lat: 49.1740568, lng: 21.6973669 },
    { lat: 49.1737598, lng: 21.6964452 },
    { lat: 49.1736161, lng: 21.6956564 },
    { lat: 49.1734427, lng: 21.6952739 },
    { lat: 49.17306, lng: 21.694893 },
    { lat: 49.1722419, lng: 21.6948794 },
    { lat: 49.1717721, lng: 21.6947293 },
    { lat: 49.1713812, lng: 21.6932438 },
    { lat: 49.1712298, lng: 21.6933018 },
    { lat: 49.1711991, lng: 21.6932465 },
    { lat: 49.1713298, lng: 21.6929615 },
    { lat: 49.1712344, lng: 21.6924663 },
    { lat: 49.1711254, lng: 21.6922585 },
    { lat: 49.1704254, lng: 21.6920369 },
    { lat: 49.1696304, lng: 21.6928923 },
    { lat: 49.1695132, lng: 21.6922593 },
    { lat: 49.1695078, lng: 21.6916794 },
    { lat: 49.1691939, lng: 21.6911303 },
    { lat: 49.1689174, lng: 21.6908874 },
    { lat: 49.1687729, lng: 21.6906156 },
    { lat: 49.1686925, lng: 21.6899311 },
    { lat: 49.1685281, lng: 21.6898835 },
    { lat: 49.1675103, lng: 21.690018 },
    { lat: 49.1676539, lng: 21.6893325 },
    { lat: 49.1674509, lng: 21.6891856 },
    { lat: 49.1675167, lng: 21.6888773 },
    { lat: 49.166724, lng: 21.6887673 },
    { lat: 49.1667074, lng: 21.6881501 },
    { lat: 49.1653732, lng: 21.6878522 },
    { lat: 49.1636279, lng: 21.6886685 },
    { lat: 49.1633953, lng: 21.6881809 },
    { lat: 49.1632326, lng: 21.6882302 },
    { lat: 49.1631568, lng: 21.6884722 },
    { lat: 49.1630456, lng: 21.6884745 },
    { lat: 49.1625907, lng: 21.6878721 },
    { lat: 49.1619778, lng: 21.6875496 },
    { lat: 49.1626764, lng: 21.6850407 },
    { lat: 49.1601285, lng: 21.6811171 },
    { lat: 49.1592222, lng: 21.682597 },
    { lat: 49.1571192, lng: 21.682746 },
    { lat: 49.1569914, lng: 21.6793809 },
    { lat: 49.1557361, lng: 21.6773087 },
    { lat: 49.1555412, lng: 21.67621 },
    { lat: 49.1549868, lng: 21.6746757 },
    { lat: 49.1511738, lng: 21.6749113 },
    { lat: 49.1510227, lng: 21.6751059 },
    { lat: 49.1504653, lng: 21.6747945 },
    { lat: 49.1503631, lng: 21.674798 },
    { lat: 49.1492473, lng: 21.6759721 },
    { lat: 49.1484611, lng: 21.6764775 },
    { lat: 49.1476265, lng: 21.676771 },
    { lat: 49.1474109, lng: 21.6767055 },
    { lat: 49.1470611, lng: 21.6770379 },
    { lat: 49.146584, lng: 21.6770309 },
    { lat: 49.1452851, lng: 21.676254 },
    { lat: 49.1447673, lng: 21.6762035 },
    { lat: 49.1433758, lng: 21.676703 },
    { lat: 49.1422618, lng: 21.6774823 },
    { lat: 49.1419129, lng: 21.6775584 },
    { lat: 49.1412797, lng: 21.6778206 },
    { lat: 49.1412733, lng: 21.6781271 },
    { lat: 49.1413575, lng: 21.6786141 },
    { lat: 49.1401387, lng: 21.6801866 },
    { lat: 49.1398112, lng: 21.680846 },
    { lat: 49.1391815, lng: 21.6818574 },
    { lat: 49.1389489, lng: 21.6827008 },
    { lat: 49.1388538, lng: 21.6828868 },
    { lat: 49.1382493, lng: 21.6832451 },
    { lat: 49.1381478, lng: 21.6824424 },
    { lat: 49.1378212, lng: 21.6825987 },
    { lat: 49.1369371, lng: 21.6833521 },
    { lat: 49.1357524, lng: 21.6848882 },
    { lat: 49.1353864, lng: 21.6849614 },
    { lat: 49.1352935, lng: 21.685311 },
    { lat: 49.135232, lng: 21.6858714 },
    { lat: 49.1347625, lng: 21.6859004 },
    { lat: 49.1346286, lng: 21.6865206 },
    { lat: 49.134673, lng: 21.686664 },
    { lat: 49.1340523, lng: 21.6868829 },
    { lat: 49.1320334, lng: 21.6879033 },
    { lat: 49.1316436, lng: 21.6879679 },
  ],
  Kožuchovce: [
    { lat: 49.3121398, lng: 21.6963045 },
    { lat: 49.3118603, lng: 21.6974991 },
    { lat: 49.3114765, lng: 21.7003492 },
    { lat: 49.3105478, lng: 21.7028555 },
    { lat: 49.3103965, lng: 21.7051527 },
    { lat: 49.3110101, lng: 21.7090511 },
    { lat: 49.3112702, lng: 21.7086391 },
    { lat: 49.3115804, lng: 21.7087546 },
    { lat: 49.3118529, lng: 21.709649 },
    { lat: 49.3119215, lng: 21.7104537 },
    { lat: 49.3120638, lng: 21.7109409 },
    { lat: 49.3119863, lng: 21.7112756 },
    { lat: 49.3120806, lng: 21.7116158 },
    { lat: 49.3119793, lng: 21.7122 },
    { lat: 49.3122305, lng: 21.7132248 },
    { lat: 49.3129642, lng: 21.714172 },
    { lat: 49.3141405, lng: 21.7142019 },
    { lat: 49.3150265, lng: 21.7146497 },
    { lat: 49.31506, lng: 21.7152814 },
    { lat: 49.315705, lng: 21.7159692 },
    { lat: 49.3169993, lng: 21.7193045 },
    { lat: 49.3185383, lng: 21.7224842 },
    { lat: 49.3186203, lng: 21.7230344 },
    { lat: 49.3187538, lng: 21.7232781 },
    { lat: 49.3190578, lng: 21.724598 },
    { lat: 49.31946, lng: 21.725861 },
    { lat: 49.319959, lng: 21.725212 },
    { lat: 49.320007, lng: 21.725149 },
    { lat: 49.320022, lng: 21.72511 },
    { lat: 49.320079, lng: 21.724993 },
    { lat: 49.320096, lng: 21.724969 },
    { lat: 49.320179, lng: 21.724787 },
    { lat: 49.321366, lng: 21.722784 },
    { lat: 49.321406, lng: 21.722717 },
    { lat: 49.323379, lng: 21.719508 },
    { lat: 49.324205, lng: 21.71958 },
    { lat: 49.324803, lng: 21.719823 },
    { lat: 49.325273, lng: 21.720013 },
    { lat: 49.325321, lng: 21.720001 },
    { lat: 49.325676, lng: 21.71991 },
    { lat: 49.327833, lng: 21.719262 },
    { lat: 49.327981, lng: 21.719217 },
    { lat: 49.329183, lng: 21.71791 },
    { lat: 49.329984, lng: 21.717333 },
    { lat: 49.330902, lng: 21.716812 },
    { lat: 49.331458, lng: 21.716496 },
    { lat: 49.332819, lng: 21.715455 },
    { lat: 49.332856, lng: 21.715357 },
    { lat: 49.33337, lng: 21.714011 },
    { lat: 49.334068, lng: 21.71223 },
    { lat: 49.335608, lng: 21.709537 },
    { lat: 49.336314, lng: 21.708731 },
    { lat: 49.33695, lng: 21.707835 },
    { lat: 49.33773, lng: 21.706608 },
    { lat: 49.337765, lng: 21.706552 },
    { lat: 49.339704, lng: 21.703499 },
    { lat: 49.339681, lng: 21.702408 },
    { lat: 49.339565, lng: 21.701427 },
    { lat: 49.339368, lng: 21.700538 },
    { lat: 49.337575, lng: 21.695957 },
    { lat: 49.335826, lng: 21.692401 },
    { lat: 49.335654, lng: 21.692197 },
    { lat: 49.335653, lng: 21.69216 },
    { lat: 49.335643, lng: 21.692084 },
    { lat: 49.334966, lng: 21.691641 },
    { lat: 49.334296, lng: 21.689917 },
    { lat: 49.333214, lng: 21.688696 },
    { lat: 49.332286, lng: 21.687987 },
    { lat: 49.331344, lng: 21.686555 },
    { lat: 49.330202, lng: 21.684769 },
    { lat: 49.328392, lng: 21.682009 },
    { lat: 49.32826, lng: 21.681776 },
    { lat: 49.327949, lng: 21.682613 },
    { lat: 49.326485, lng: 21.683883 },
    { lat: 49.325535, lng: 21.684266 },
    { lat: 49.32536, lng: 21.683871 },
    { lat: 49.325191, lng: 21.684962 },
    { lat: 49.32446, lng: 21.684368 },
    { lat: 49.323493, lng: 21.684903 },
    { lat: 49.323063, lng: 21.685612 },
    { lat: 49.321705, lng: 21.687177 },
    { lat: 49.319735, lng: 21.688884 },
    { lat: 49.319168, lng: 21.689202 },
    { lat: 49.318658, lng: 21.689895 },
    { lat: 49.317601, lng: 21.691166 },
    { lat: 49.3164225, lng: 21.6917375 },
    { lat: 49.3154116, lng: 21.6927635 },
    { lat: 49.3147957, lng: 21.6930271 },
    { lat: 49.3144786, lng: 21.6930766 },
    { lat: 49.3142389, lng: 21.6933681 },
    { lat: 49.3135687, lng: 21.6937072 },
    { lat: 49.3129848, lng: 21.6945427 },
    { lat: 49.3123358, lng: 21.6956837 },
    { lat: 49.3121398, lng: 21.6963045 },
  ],
  Vladiča: [
    { lat: 49.323908, lng: 21.824728 },
    { lat: 49.324507, lng: 21.824416 },
    { lat: 49.32477, lng: 21.824246 },
    { lat: 49.325248, lng: 21.82388 },
    { lat: 49.325352, lng: 21.823884 },
    { lat: 49.325395, lng: 21.823886 },
    { lat: 49.325474, lng: 21.823693 },
    { lat: 49.325707, lng: 21.823472 },
    { lat: 49.326202, lng: 21.823026 },
    { lat: 49.326466, lng: 21.822929 },
    { lat: 49.327444, lng: 21.822767 },
    { lat: 49.328282, lng: 21.822569 },
    { lat: 49.328684, lng: 21.822348 },
    { lat: 49.329107, lng: 21.822192 },
    { lat: 49.329358, lng: 21.822065 },
    { lat: 49.329843, lng: 21.821944 },
    { lat: 49.330279, lng: 21.821888 },
    { lat: 49.330658, lng: 21.82187 },
    { lat: 49.331019, lng: 21.821753 },
    { lat: 49.331682, lng: 21.821223 },
    { lat: 49.332449, lng: 21.820936 },
    { lat: 49.332559, lng: 21.821219 },
    { lat: 49.334002, lng: 21.820292 },
    { lat: 49.334218, lng: 21.820177 },
    { lat: 49.334726, lng: 21.81968 },
    { lat: 49.335098, lng: 21.819135 },
    { lat: 49.33532, lng: 21.81863 },
    { lat: 49.335603, lng: 21.817965 },
    { lat: 49.335805, lng: 21.817492 },
    { lat: 49.336477, lng: 21.816485 },
    { lat: 49.337, lng: 21.816034 },
    { lat: 49.337457, lng: 21.815796 },
    { lat: 49.33789, lng: 21.815619 },
    { lat: 49.33827, lng: 21.815295 },
    { lat: 49.338803, lng: 21.814716 },
    { lat: 49.339211, lng: 21.814289 },
    { lat: 49.338991, lng: 21.813615 },
    { lat: 49.338633, lng: 21.812447 },
    { lat: 49.33786, lng: 21.809509 },
    { lat: 49.337784, lng: 21.809245 },
    { lat: 49.337486, lng: 21.8082 },
    { lat: 49.337456, lng: 21.808109 },
    { lat: 49.337079, lng: 21.806859 },
    { lat: 49.33684, lng: 21.805457 },
    { lat: 49.3365, lng: 21.804017 },
    { lat: 49.336342, lng: 21.803349 },
    { lat: 49.335928, lng: 21.801776 },
    { lat: 49.335883, lng: 21.801605 },
    { lat: 49.335875, lng: 21.801034 },
    { lat: 49.335878, lng: 21.800511 },
    { lat: 49.336131, lng: 21.80003 },
    { lat: 49.336173, lng: 21.799965 },
    { lat: 49.336479, lng: 21.799435 },
    { lat: 49.337151, lng: 21.798071 },
    { lat: 49.338226, lng: 21.79745 },
    { lat: 49.338663, lng: 21.797157 },
    { lat: 49.339045, lng: 21.796893 },
    { lat: 49.338911, lng: 21.796501 },
    { lat: 49.339289, lng: 21.796103 },
    { lat: 49.339416, lng: 21.795977 },
    { lat: 49.340276, lng: 21.795121 },
    { lat: 49.340997, lng: 21.794996 },
    { lat: 49.342802, lng: 21.795025 },
    { lat: 49.344297, lng: 21.794372 },
    { lat: 49.344788, lng: 21.793794 },
    { lat: 49.347259, lng: 21.791722 },
    { lat: 49.347562, lng: 21.791527 },
    { lat: 49.347737, lng: 21.791344 },
    { lat: 49.348809, lng: 21.790229 },
    { lat: 49.349249, lng: 21.790347 },
    { lat: 49.350587, lng: 21.790908 },
    { lat: 49.350737, lng: 21.790947 },
    { lat: 49.35129, lng: 21.791381 },
    { lat: 49.352554, lng: 21.791356 },
    { lat: 49.353794, lng: 21.790299 },
    { lat: 49.354602, lng: 21.78982 },
    { lat: 49.3554882, lng: 21.7892157 },
    { lat: 49.355137, lng: 21.78752 },
    { lat: 49.35548, lng: 21.786915 },
    { lat: 49.355772, lng: 21.785771 },
    { lat: 49.356061, lng: 21.785325 },
    { lat: 49.356219, lng: 21.784309 },
    { lat: 49.356225, lng: 21.783612 },
    { lat: 49.355894, lng: 21.781638 },
    { lat: 49.355781, lng: 21.780625 },
    { lat: 49.355874, lng: 21.779774 },
    { lat: 49.3558852, lng: 21.7789276 },
    { lat: 49.356048, lng: 21.777994 },
    { lat: 49.356765, lng: 21.776818 },
    { lat: 49.357585, lng: 21.775487 },
    { lat: 49.358278, lng: 21.774808 },
    { lat: 49.358672, lng: 21.774157 },
    { lat: 49.359076, lng: 21.773585 },
    { lat: 49.359866, lng: 21.77314 },
    { lat: 49.360691, lng: 21.772608 },
    { lat: 49.36129, lng: 21.772373 },
    { lat: 49.361916, lng: 21.772107 },
    { lat: 49.3626945, lng: 21.7719543 },
    { lat: 49.3631445, lng: 21.7714846 },
    { lat: 49.363121, lng: 21.771446 },
    { lat: 49.358078, lng: 21.762588 },
    { lat: 49.356363, lng: 21.759579 },
    { lat: 49.356145, lng: 21.759541 },
    { lat: 49.355657, lng: 21.759663 },
    { lat: 49.355457, lng: 21.759808 },
    { lat: 49.355297, lng: 21.75982 },
    { lat: 49.354904, lng: 21.760422 },
    { lat: 49.354644, lng: 21.760537 },
    { lat: 49.354428, lng: 21.760671 },
    { lat: 49.354311, lng: 21.761054 },
    { lat: 49.354248, lng: 21.761169 },
    { lat: 49.354033, lng: 21.761425 },
    { lat: 49.353793, lng: 21.761598 },
    { lat: 49.353653, lng: 21.761728 },
    { lat: 49.353446, lng: 21.761845 },
    { lat: 49.353244, lng: 21.761807 },
    { lat: 49.352918, lng: 21.762086 },
    { lat: 49.352882, lng: 21.761741 },
    { lat: 49.352877, lng: 21.761708 },
    { lat: 49.352873, lng: 21.761675 },
    { lat: 49.352491, lng: 21.760567 },
    { lat: 49.352139, lng: 21.760128 },
    { lat: 49.35142, lng: 21.759002 },
    { lat: 49.351229, lng: 21.758614 },
    { lat: 49.350862, lng: 21.758348 },
    { lat: 49.35016, lng: 21.757796 },
    { lat: 49.349656, lng: 21.756222 },
    { lat: 49.34894, lng: 21.756144 },
    { lat: 49.348722, lng: 21.755328 },
    { lat: 49.348527, lng: 21.75491 },
    { lat: 49.348478, lng: 21.754807 },
    { lat: 49.348023, lng: 21.753816 },
    { lat: 49.347985, lng: 21.753731 },
    { lat: 49.347564, lng: 21.752724 },
    { lat: 49.347329, lng: 21.752399 },
    { lat: 49.347156, lng: 21.752009 },
    { lat: 49.346984, lng: 21.751619 },
    { lat: 49.346823, lng: 21.75122 },
    { lat: 49.346367, lng: 21.751524 },
    { lat: 49.346074, lng: 21.751509 },
    { lat: 49.345616, lng: 21.751256 },
    { lat: 49.345255, lng: 21.750964 },
    { lat: 49.345242, lng: 21.750875 },
    { lat: 49.34516, lng: 21.750735 },
    { lat: 49.345056, lng: 21.750362 },
    { lat: 49.344977, lng: 21.750205 },
    { lat: 49.344423, lng: 21.749615 },
    { lat: 49.344356, lng: 21.749469 },
    { lat: 49.344026, lng: 21.749145 },
    { lat: 49.343867, lng: 21.748889 },
    { lat: 49.343744, lng: 21.748712 },
    { lat: 49.343544, lng: 21.748277 },
    { lat: 49.343406, lng: 21.748155 },
    { lat: 49.343236, lng: 21.747863 },
    { lat: 49.342935, lng: 21.747584 },
    { lat: 49.342565, lng: 21.747468 },
    { lat: 49.342305, lng: 21.74749 },
    { lat: 49.342209, lng: 21.747449 },
    { lat: 49.342033, lng: 21.747409 },
    { lat: 49.341914, lng: 21.747341 },
    { lat: 49.341907, lng: 21.747359 },
    { lat: 49.341748, lng: 21.74746 },
    { lat: 49.341606, lng: 21.747459 },
    { lat: 49.341457, lng: 21.747798 },
    { lat: 49.341138, lng: 21.748182 },
    { lat: 49.340927, lng: 21.748524 },
    { lat: 49.340662, lng: 21.748813 },
    { lat: 49.340489, lng: 21.748922 },
    { lat: 49.340467, lng: 21.74886 },
    { lat: 49.340169, lng: 21.749129 },
    { lat: 49.33991, lng: 21.749265 },
    { lat: 49.339766, lng: 21.749212 },
    { lat: 49.339498, lng: 21.749157 },
    { lat: 49.339382, lng: 21.749088 },
    { lat: 49.339189, lng: 21.749012 },
    { lat: 49.338777, lng: 21.749077 },
    { lat: 49.338688, lng: 21.74901 },
    { lat: 49.338505, lng: 21.748833 },
    { lat: 49.338299, lng: 21.748741 },
    { lat: 49.33814, lng: 21.748769 },
    { lat: 49.33794, lng: 21.748679 },
    { lat: 49.337755, lng: 21.748675 },
    { lat: 49.3376, lng: 21.748591 },
    { lat: 49.337494, lng: 21.748563 },
    { lat: 49.337346, lng: 21.748418 },
    { lat: 49.337211, lng: 21.748437 },
    { lat: 49.336939, lng: 21.74824 },
    { lat: 49.336854, lng: 21.748258 },
    { lat: 49.336569, lng: 21.748195 },
    { lat: 49.33611, lng: 21.748316 },
    { lat: 49.335985, lng: 21.748316 },
    { lat: 49.33584, lng: 21.748397 },
    { lat: 49.33553, lng: 21.748365 },
    { lat: 49.335133, lng: 21.748482 },
    { lat: 49.334849, lng: 21.748663 },
    { lat: 49.334808, lng: 21.748719 },
    { lat: 49.334646, lng: 21.748641 },
    { lat: 49.33451, lng: 21.74866 },
    { lat: 49.334389, lng: 21.748614 },
    { lat: 49.333924, lng: 21.748778 },
    { lat: 49.333348, lng: 21.748771 },
    { lat: 49.333327, lng: 21.748771 },
    { lat: 49.333134, lng: 21.748677 },
    { lat: 49.332998, lng: 21.748601 },
    { lat: 49.332582, lng: 21.74854 },
    { lat: 49.332079, lng: 21.74852 },
    { lat: 49.331914, lng: 21.748489 },
    { lat: 49.331334, lng: 21.748391 },
    { lat: 49.331159, lng: 21.748409 },
    { lat: 49.331065, lng: 21.748456 },
    { lat: 49.330968, lng: 21.748505 },
    { lat: 49.330752, lng: 21.748655 },
    { lat: 49.330249, lng: 21.748726 },
    { lat: 49.329364, lng: 21.749541 },
    { lat: 49.328815, lng: 21.749667 },
    { lat: 49.328699, lng: 21.749753 },
    { lat: 49.328525, lng: 21.749727 },
    { lat: 49.328325, lng: 21.749806 },
    { lat: 49.32804, lng: 21.749833 },
    { lat: 49.327927, lng: 21.74983 },
    { lat: 49.327745, lng: 21.74973 },
    { lat: 49.327518, lng: 21.749698 },
    { lat: 49.327423, lng: 21.749715 },
    { lat: 49.32721, lng: 21.749693 },
    { lat: 49.326955, lng: 21.74986 },
    { lat: 49.326832, lng: 21.749879 },
    { lat: 49.326701, lng: 21.749956 },
    { lat: 49.326295, lng: 21.75036 },
    { lat: 49.325921, lng: 21.750532 },
    { lat: 49.325883, lng: 21.750549 },
    { lat: 49.325774, lng: 21.750558 },
    { lat: 49.325629, lng: 21.750493 },
    { lat: 49.325522, lng: 21.750498 },
    { lat: 49.325423, lng: 21.750428 },
    { lat: 49.324629, lng: 21.750299 },
    { lat: 49.324495, lng: 21.750495 },
    { lat: 49.323981, lng: 21.750804 },
    { lat: 49.323549, lng: 21.751032 },
    { lat: 49.323435, lng: 21.751166 },
    { lat: 49.32294, lng: 21.751568 },
    { lat: 49.322726, lng: 21.751694 },
    { lat: 49.322494, lng: 21.751815 },
    { lat: 49.32229, lng: 21.751909 },
    { lat: 49.321693, lng: 21.752021 },
    { lat: 49.320939, lng: 21.752274 },
    { lat: 49.320409, lng: 21.752853 },
    { lat: 49.319681, lng: 21.753538 },
    { lat: 49.319297, lng: 21.753859 },
    { lat: 49.318759, lng: 21.754334 },
    { lat: 49.318481, lng: 21.754541 },
    { lat: 49.31827, lng: 21.754625 },
    { lat: 49.318022, lng: 21.754804 },
    { lat: 49.317657, lng: 21.755144 },
    { lat: 49.317333, lng: 21.755289 },
    { lat: 49.317123, lng: 21.755495 },
    { lat: 49.316673, lng: 21.755799 },
    { lat: 49.315942, lng: 21.756189 },
    { lat: 49.315372, lng: 21.756548 },
    { lat: 49.315001, lng: 21.7567 },
    { lat: 49.314745, lng: 21.757077 },
    { lat: 49.314587, lng: 21.757341 },
    { lat: 49.314338, lng: 21.757386 },
    { lat: 49.314154, lng: 21.757709 },
    { lat: 49.313928, lng: 21.757806 },
    { lat: 49.31363, lng: 21.757644 },
    { lat: 49.313337, lng: 21.757775 },
    { lat: 49.312786, lng: 21.757915 },
    { lat: 49.312504, lng: 21.758111 },
    { lat: 49.312137, lng: 21.758763 },
    { lat: 49.31192, lng: 21.759048 },
    { lat: 49.310875, lng: 21.760486 },
    { lat: 49.310676, lng: 21.761094 },
    { lat: 49.310634, lng: 21.761142 },
    { lat: 49.310088, lng: 21.761891 },
    { lat: 49.309804, lng: 21.762431 },
    { lat: 49.30932, lng: 21.76275 },
    { lat: 49.30868, lng: 21.762956 },
    { lat: 49.308271, lng: 21.762985 },
    { lat: 49.307917, lng: 21.763336 },
    { lat: 49.307716, lng: 21.76346 },
    { lat: 49.307413, lng: 21.763608 },
    { lat: 49.307193, lng: 21.763668 },
    { lat: 49.30704, lng: 21.763696 },
    { lat: 49.306823, lng: 21.763755 },
    { lat: 49.306509, lng: 21.763896 },
    { lat: 49.305992, lng: 21.763994 },
    { lat: 49.305644, lng: 21.764161 },
    { lat: 49.305305, lng: 21.764207 },
    { lat: 49.304947, lng: 21.764372 },
    { lat: 49.304954, lng: 21.764348 },
    { lat: 49.304724, lng: 21.764428 },
    { lat: 49.30421, lng: 21.764505 },
    { lat: 49.303751, lng: 21.764684 },
    { lat: 49.303557, lng: 21.76483 },
    { lat: 49.303249, lng: 21.765139 },
    { lat: 49.302852, lng: 21.765431 },
    { lat: 49.302544, lng: 21.765502 },
    { lat: 49.302192, lng: 21.765751 },
    { lat: 49.301788, lng: 21.765888 },
    { lat: 49.300931, lng: 21.765702 },
    { lat: 49.300095, lng: 21.765691 },
    { lat: 49.299881, lng: 21.765642 },
    { lat: 49.299705, lng: 21.765798 },
    { lat: 49.299425, lng: 21.765942 },
    { lat: 49.298651, lng: 21.766195 },
    { lat: 49.298482, lng: 21.766305 },
    { lat: 49.298352, lng: 21.766336 },
    { lat: 49.298224, lng: 21.766275 },
    { lat: 49.298129, lng: 21.766393 },
    { lat: 49.297541, lng: 21.76655 },
    { lat: 49.297017, lng: 21.766534 },
    { lat: 49.296531, lng: 21.76644 },
    { lat: 49.296372, lng: 21.766461 },
    { lat: 49.296178, lng: 21.766455 },
    { lat: 49.295968, lng: 21.766446 },
    { lat: 49.295461, lng: 21.766337 },
    { lat: 49.294492, lng: 21.766206 },
    { lat: 49.294035, lng: 21.76598 },
    { lat: 49.293834, lng: 21.76579 },
    { lat: 49.293441, lng: 21.765589 },
    { lat: 49.2923953, lng: 21.7659487 },
    { lat: 49.2920852, lng: 21.7663014 },
    { lat: 49.2909519, lng: 21.7670958 },
    { lat: 49.2900079, lng: 21.768031 },
    { lat: 49.2899189, lng: 21.7688007 },
    { lat: 49.2895129, lng: 21.7683744 },
    { lat: 49.2890174, lng: 21.7668487 },
    { lat: 49.2863886, lng: 21.7692446 },
    { lat: 49.2862309, lng: 21.7697598 },
    { lat: 49.2859126, lng: 21.7702347 },
    { lat: 49.28541, lng: 21.7707979 },
    { lat: 49.2848635, lng: 21.7710627 },
    { lat: 49.284722, lng: 21.7711981 },
    { lat: 49.2845679, lng: 21.7714605 },
    { lat: 49.2843537, lng: 21.7715723 },
    { lat: 49.2834367, lng: 21.772676 },
    { lat: 49.2833556, lng: 21.7731465 },
    { lat: 49.282833, lng: 21.7747597 },
    { lat: 49.2826892, lng: 21.7757975 },
    { lat: 49.282524, lng: 21.7765386 },
    { lat: 49.2824061, lng: 21.776807 },
    { lat: 49.2830624, lng: 21.7789338 },
    { lat: 49.2833158, lng: 21.7792066 },
    { lat: 49.2833148, lng: 21.7795587 },
    { lat: 49.2834059, lng: 21.7803761 },
    { lat: 49.283293, lng: 21.7811835 },
    { lat: 49.2828611, lng: 21.7824377 },
    { lat: 49.2826281, lng: 21.7828669 },
    { lat: 49.2820327, lng: 21.7843133 },
    { lat: 49.2818416, lng: 21.7842349 },
    { lat: 49.2814264, lng: 21.785479 },
    { lat: 49.2812218, lng: 21.7862217 },
    { lat: 49.2810835, lng: 21.7870494 },
    { lat: 49.2808901, lng: 21.7875248 },
    { lat: 49.2807417, lng: 21.7882205 },
    { lat: 49.2806622, lng: 21.7883255 },
    { lat: 49.2807926, lng: 21.7885345 },
    { lat: 49.280884, lng: 21.7891057 },
    { lat: 49.2812428, lng: 21.7894511 },
    { lat: 49.2815036, lng: 21.7899284 },
    { lat: 49.2815953, lng: 21.7902925 },
    { lat: 49.2817896, lng: 21.7907131 },
    { lat: 49.2818137, lng: 21.7909029 },
    { lat: 49.2819154, lng: 21.7910077 },
    { lat: 49.2822624, lng: 21.791165 },
    { lat: 49.2829118, lng: 21.7918757 },
    { lat: 49.2831213, lng: 21.791974 },
    { lat: 49.28306, lng: 21.7921618 },
    { lat: 49.2832603, lng: 21.7924249 },
    { lat: 49.2834036, lng: 21.7923971 },
    { lat: 49.2837433, lng: 21.7925365 },
    { lat: 49.2840484, lng: 21.7924121 },
    { lat: 49.2843845, lng: 21.7921016 },
    { lat: 49.2852494, lng: 21.7927521 },
    { lat: 49.2855848, lng: 21.7929317 },
    { lat: 49.2860772, lng: 21.7930049 },
    { lat: 49.2866284, lng: 21.7934374 },
    { lat: 49.2872717, lng: 21.7936146 },
    { lat: 49.2880066, lng: 21.7939764 },
    { lat: 49.2884266, lng: 21.7939474 },
    { lat: 49.2887137, lng: 21.794219 },
    { lat: 49.2887699, lng: 21.79395 },
    { lat: 49.2888157, lng: 21.7939014 },
    { lat: 49.2889762, lng: 21.7939735 },
    { lat: 49.2891437, lng: 21.7942696 },
    { lat: 49.2892166, lng: 21.7945247 },
    { lat: 49.2892708, lng: 21.7945333 },
    { lat: 49.2893368, lng: 21.7943455 },
    { lat: 49.2894196, lng: 21.7943268 },
    { lat: 49.2895426, lng: 21.7941741 },
    { lat: 49.2898103, lng: 21.7942505 },
    { lat: 49.2900291, lng: 21.7944098 },
    { lat: 49.290132, lng: 21.7946508 },
    { lat: 49.2903635, lng: 21.7949427 },
    { lat: 49.2904764, lng: 21.79496 },
    { lat: 49.2905629, lng: 21.7950873 },
    { lat: 49.2906983, lng: 21.7951004 },
    { lat: 49.2911655, lng: 21.7955477 },
    { lat: 49.2913549, lng: 21.7955279 },
    { lat: 49.291679, lng: 21.7956189 },
    { lat: 49.2919788, lng: 21.7960249 },
    { lat: 49.2920936, lng: 21.7960713 },
    { lat: 49.292139, lng: 21.7961774 },
    { lat: 49.2923146, lng: 21.7962698 },
    { lat: 49.2926891, lng: 21.7966575 },
    { lat: 49.292734, lng: 21.7969615 },
    { lat: 49.2926939, lng: 21.7976727 },
    { lat: 49.2933573, lng: 21.7987039 },
    { lat: 49.2935342, lng: 21.7991473 },
    { lat: 49.293967, lng: 21.7997492 },
    { lat: 49.2940195, lng: 21.799994 },
    { lat: 49.2951681, lng: 21.8020903 },
    { lat: 49.2957307, lng: 21.8012699 },
    { lat: 49.2964094, lng: 21.8005077 },
    { lat: 49.2972588, lng: 21.7992504 },
    { lat: 49.2973022, lng: 21.7991851 },
    { lat: 49.2980067, lng: 21.7982494 },
    { lat: 49.2985724, lng: 21.7972035 },
    { lat: 49.2988701, lng: 21.7967937 },
    { lat: 49.3008198, lng: 21.7944741 },
    { lat: 49.3011041, lng: 21.7942849 },
    { lat: 49.3019305, lng: 21.7939681 },
    { lat: 49.3024801, lng: 21.7933458 },
    { lat: 49.3026541, lng: 21.7932019 },
    { lat: 49.3028972, lng: 21.7931623 },
    { lat: 49.3030442, lng: 21.792932 },
    { lat: 49.3033602, lng: 21.7927543 },
    { lat: 49.303637, lng: 21.7924592 },
    { lat: 49.3043115, lng: 21.7919212 },
    { lat: 49.3044837, lng: 21.7917061 },
    { lat: 49.304849, lng: 21.7916165 },
    { lat: 49.3050382, lng: 21.79145 },
    { lat: 49.305109, lng: 21.791272 },
    { lat: 49.3056519, lng: 21.7905653 },
    { lat: 49.30607, lng: 21.7907724 },
    { lat: 49.3067525, lng: 21.7904152 },
    { lat: 49.3071013, lng: 21.7918936 },
    { lat: 49.3071368, lng: 21.7922648 },
    { lat: 49.3070465, lng: 21.7941992 },
    { lat: 49.3070819, lng: 21.795091 },
    { lat: 49.3071442, lng: 21.7954127 },
    { lat: 49.3076262, lng: 21.7965222 },
    { lat: 49.3079654, lng: 21.797149 },
    { lat: 49.3081676, lng: 21.7975337 },
    { lat: 49.3086749, lng: 21.7977913 },
    { lat: 49.3089224, lng: 21.7986631 },
    { lat: 49.309633, lng: 21.8002202 },
    { lat: 49.3103844, lng: 21.8013485 },
    { lat: 49.3109658, lng: 21.8031255 },
    { lat: 49.3111394, lng: 21.8040123 },
    { lat: 49.3120199, lng: 21.8046584 },
    { lat: 49.3145278, lng: 21.8053378 },
    { lat: 49.3146104, lng: 21.8054882 },
    { lat: 49.3148563, lng: 21.8056914 },
    { lat: 49.3149764, lng: 21.8059863 },
    { lat: 49.3151296, lng: 21.8060884 },
    { lat: 49.3152312, lng: 21.8063163 },
    { lat: 49.315625, lng: 21.8059294 },
    { lat: 49.3158095, lng: 21.8063203 },
    { lat: 49.3160254, lng: 21.8071422 },
    { lat: 49.3163182, lng: 21.8072904 },
    { lat: 49.316623, lng: 21.8073386 },
    { lat: 49.3167235, lng: 21.8086143 },
    { lat: 49.3168182, lng: 21.8089676 },
    { lat: 49.3169248, lng: 21.8093059 },
    { lat: 49.3174635, lng: 21.8102284 },
    { lat: 49.3174979, lng: 21.8105108 },
    { lat: 49.317798, lng: 21.8112884 },
    { lat: 49.3180756, lng: 21.8113225 },
    { lat: 49.3184252, lng: 21.8115736 },
    { lat: 49.3185313, lng: 21.8115456 },
    { lat: 49.3186048, lng: 21.8118303 },
    { lat: 49.3181567, lng: 21.8124587 },
    { lat: 49.3177749, lng: 21.8137148 },
    { lat: 49.3179226, lng: 21.8144259 },
    { lat: 49.3180193, lng: 21.8146389 },
    { lat: 49.3179464, lng: 21.8150024 },
    { lat: 49.3181081, lng: 21.8153767 },
    { lat: 49.3181104, lng: 21.8155579 },
    { lat: 49.3181915, lng: 21.81565 },
    { lat: 49.3178992, lng: 21.8161788 },
    { lat: 49.3179112, lng: 21.8164727 },
    { lat: 49.3175207, lng: 21.8172068 },
    { lat: 49.3175985, lng: 21.8175655 },
    { lat: 49.3174744, lng: 21.8182472 },
    { lat: 49.317519, lng: 21.8185784 },
    { lat: 49.3174874, lng: 21.8190194 },
    { lat: 49.3175665, lng: 21.8197325 },
    { lat: 49.3181652, lng: 21.8201829 },
    { lat: 49.3184717, lng: 21.8203317 },
    { lat: 49.3189028, lng: 21.8208264 },
    { lat: 49.31906, lng: 21.8208188 },
    { lat: 49.3191155, lng: 21.8206561 },
    { lat: 49.3194418, lng: 21.8206599 },
    { lat: 49.3204623, lng: 21.8204103 },
    { lat: 49.3209268, lng: 21.820364 },
    { lat: 49.3210081, lng: 21.8205105 },
    { lat: 49.3210546, lng: 21.8204392 },
    { lat: 49.3211351, lng: 21.820765 },
    { lat: 49.3216102, lng: 21.8214864 },
    { lat: 49.3219296, lng: 21.8217921 },
    { lat: 49.322436, lng: 21.8220173 },
    { lat: 49.3234705, lng: 21.823017 },
    { lat: 49.3238189, lng: 21.8236614 },
    { lat: 49.3238751, lng: 21.823923 },
    { lat: 49.323908, lng: 21.824728 },
  ],
  Chotča: [
    { lat: 49.2575115, lng: 21.6927193 },
    { lat: 49.2565078, lng: 21.6919971 },
    { lat: 49.2561806, lng: 21.6914965 },
    { lat: 49.2547733, lng: 21.6874362 },
    { lat: 49.2544949, lng: 21.6867867 },
    { lat: 49.2543618, lng: 21.6866053 },
    { lat: 49.2542845, lng: 21.6862835 },
    { lat: 49.2532236, lng: 21.6845153 },
    { lat: 49.251846, lng: 21.6827322 },
    { lat: 49.2511018, lng: 21.6814963 },
    { lat: 49.2508703, lng: 21.6812945 },
    { lat: 49.2502778, lng: 21.6811394 },
    { lat: 49.2502593, lng: 21.6809159 },
    { lat: 49.2501867, lng: 21.6807692 },
    { lat: 49.2498027, lng: 21.68025 },
    { lat: 49.2490867, lng: 21.6798986 },
    { lat: 49.2486868, lng: 21.6799194 },
    { lat: 49.2479354, lng: 21.6802903 },
    { lat: 49.2468238, lng: 21.6803256 },
    { lat: 49.2466392, lng: 21.6801575 },
    { lat: 49.2465453, lng: 21.6803053 },
    { lat: 49.2461554, lng: 21.6800227 },
    { lat: 49.2459618, lng: 21.6799758 },
    { lat: 49.2458906, lng: 21.6800908 },
    { lat: 49.2457119, lng: 21.6799764 },
    { lat: 49.2456094, lng: 21.6797578 },
    { lat: 49.2446028, lng: 21.6783049 },
    { lat: 49.2448633, lng: 21.676241 },
    { lat: 49.2442247, lng: 21.6747546 },
    { lat: 49.2434546, lng: 21.6735232 },
    { lat: 49.2432588, lng: 21.6730995 },
    { lat: 49.2430995, lng: 21.6726537 },
    { lat: 49.2432894, lng: 21.6718587 },
    { lat: 49.2432186, lng: 21.6717757 },
    { lat: 49.2431425, lng: 21.6717959 },
    { lat: 49.2429657, lng: 21.6714528 },
    { lat: 49.2429907, lng: 21.6713746 },
    { lat: 49.2423017, lng: 21.670776 },
    { lat: 49.2421316, lng: 21.6706802 },
    { lat: 49.2418392, lng: 21.6706421 },
    { lat: 49.2416047, lng: 21.6705039 },
    { lat: 49.2413408, lng: 21.6708496 },
    { lat: 49.2411544, lng: 21.6708246 },
    { lat: 49.2407648, lng: 21.6710173 },
    { lat: 49.2405423, lng: 21.6709427 },
    { lat: 49.2398475, lng: 21.6710754 },
    { lat: 49.2397916, lng: 21.6707596 },
    { lat: 49.239633, lng: 21.6703859 },
    { lat: 49.2391185, lng: 21.6701623 },
    { lat: 49.2387502, lng: 21.6699775 },
    { lat: 49.2377788, lng: 21.6689991 },
    { lat: 49.237478, lng: 21.6688175 },
    { lat: 49.2368126, lng: 21.6677982 },
    { lat: 49.2362291, lng: 21.6673962 },
    { lat: 49.2356062, lng: 21.6667375 },
    { lat: 49.2317328, lng: 21.667723 },
    { lat: 49.2314604, lng: 21.6678378 },
    { lat: 49.2311143, lng: 21.6678128 },
    { lat: 49.2301649, lng: 21.6673969 },
    { lat: 49.230098, lng: 21.6672582 },
    { lat: 49.2301749, lng: 21.6671029 },
    { lat: 49.230017, lng: 21.6665053 },
    { lat: 49.2296436, lng: 21.666008 },
    { lat: 49.2294709, lng: 21.6659512 },
    { lat: 49.2293481, lng: 21.6659845 },
    { lat: 49.2291359, lng: 21.6661646 },
    { lat: 49.2289709, lng: 21.6664269 },
    { lat: 49.2287134, lng: 21.6664691 },
    { lat: 49.2282333, lng: 21.6659822 },
    { lat: 49.2281259, lng: 21.6656744 },
    { lat: 49.2277475, lng: 21.6652005 },
    { lat: 49.2275325, lng: 21.6653566 },
    { lat: 49.227029, lng: 21.6648766 },
    { lat: 49.2268823, lng: 21.664821 },
    { lat: 49.2267706, lng: 21.665138 },
    { lat: 49.2266574, lng: 21.6653604 },
    { lat: 49.2239188, lng: 21.6688313 },
    { lat: 49.2224881, lng: 21.6731721 },
    { lat: 49.2220021, lng: 21.674047 },
    { lat: 49.221563, lng: 21.675987 },
    { lat: 49.2218851, lng: 21.678131 },
    { lat: 49.2215982, lng: 21.6789631 },
    { lat: 49.2220732, lng: 21.6819818 },
    { lat: 49.2227399, lng: 21.6852671 },
    { lat: 49.2221652, lng: 21.6853999 },
    { lat: 49.2224344, lng: 21.6863299 },
    { lat: 49.2267661, lng: 21.6888731 },
    { lat: 49.2286542, lng: 21.6894333 },
    { lat: 49.2293338, lng: 21.6899007 },
    { lat: 49.231285, lng: 21.692477 },
    { lat: 49.2328815, lng: 21.696563 },
    { lat: 49.2345791, lng: 21.6987081 },
    { lat: 49.2354732, lng: 21.6995 },
    { lat: 49.2363196, lng: 21.7035941 },
    { lat: 49.237443, lng: 21.7062736 },
    { lat: 49.23731, lng: 21.7081167 },
    { lat: 49.2375584, lng: 21.7090782 },
    { lat: 49.237822, lng: 21.7103606 },
    { lat: 49.2378055, lng: 21.7120147 },
    { lat: 49.2374778, lng: 21.7141964 },
    { lat: 49.23704, lng: 21.7179462 },
    { lat: 49.2365364, lng: 21.720079 },
    { lat: 49.2368642, lng: 21.7223351 },
    { lat: 49.2366247, lng: 21.7242884 },
    { lat: 49.2369568, lng: 21.7257333 },
    { lat: 49.2377681, lng: 21.7259639 },
    { lat: 49.2380213, lng: 21.7261764 },
    { lat: 49.2382994, lng: 21.726264 },
    { lat: 49.2386472, lng: 21.7265337 },
    { lat: 49.2394248, lng: 21.7275997 },
    { lat: 49.2397056, lng: 21.7278429 },
    { lat: 49.2401736, lng: 21.728476 },
    { lat: 49.2415644, lng: 21.7293861 },
    { lat: 49.2423377, lng: 21.7304259 },
    { lat: 49.242636, lng: 21.7309881 },
    { lat: 49.2432203, lng: 21.7311759 },
    { lat: 49.2441003, lng: 21.7318261 },
    { lat: 49.2443191, lng: 21.7319267 },
    { lat: 49.2459849, lng: 21.733495 },
    { lat: 49.2461108, lng: 21.7334509 },
    { lat: 49.2463086, lng: 21.7320446 },
    { lat: 49.2467111, lng: 21.73131 },
    { lat: 49.2467385, lng: 21.7299272 },
    { lat: 49.247369, lng: 21.7284231 },
    { lat: 49.247798, lng: 21.7276768 },
    { lat: 49.2482706, lng: 21.7271331 },
    { lat: 49.249481, lng: 21.7252682 },
    { lat: 49.2503123, lng: 21.7241185 },
    { lat: 49.2509099, lng: 21.7235824 },
    { lat: 49.25064, lng: 21.7224876 },
    { lat: 49.2506389, lng: 21.7217221 },
    { lat: 49.2506731, lng: 21.721356 },
    { lat: 49.2507665, lng: 21.7209786 },
    { lat: 49.2511163, lng: 21.7203858 },
    { lat: 49.2514262, lng: 21.720027 },
    { lat: 49.2523573, lng: 21.7191669 },
    { lat: 49.252639, lng: 21.7186813 },
    { lat: 49.252843, lng: 21.7184447 },
    { lat: 49.2528277, lng: 21.7172782 },
    { lat: 49.2529746, lng: 21.7165014 },
    { lat: 49.2530792, lng: 21.715142 },
    { lat: 49.2532423, lng: 21.7145485 },
    { lat: 49.2533609, lng: 21.7136063 },
    { lat: 49.2536987, lng: 21.7128968 },
    { lat: 49.2541918, lng: 21.7113937 },
    { lat: 49.2554328, lng: 21.7090395 },
    { lat: 49.255555, lng: 21.7090272 },
    { lat: 49.2558756, lng: 21.70839 },
    { lat: 49.2562715, lng: 21.7082645 },
    { lat: 49.2563694, lng: 21.7081421 },
    { lat: 49.2579709, lng: 21.7043311 },
    { lat: 49.2583355, lng: 21.703958 },
    { lat: 49.2588016, lng: 21.7015811 },
    { lat: 49.2594148, lng: 21.6992343 },
    { lat: 49.2596423, lng: 21.6981498 },
    { lat: 49.2601082, lng: 21.6974436 },
    { lat: 49.2603103, lng: 21.6968396 },
    { lat: 49.2603983, lng: 21.696305 },
    { lat: 49.2598747, lng: 21.6941812 },
    { lat: 49.2599917, lng: 21.693763 },
    { lat: 49.2575035, lng: 21.6930544 },
    { lat: 49.2575269, lng: 21.692834 },
    { lat: 49.2575115, lng: 21.6927193 },
  ],
  MaláPoľana: [
    { lat: 49.2951681, lng: 21.8020903 },
    { lat: 49.2940195, lng: 21.799994 },
    { lat: 49.293967, lng: 21.7997492 },
    { lat: 49.2935342, lng: 21.7991473 },
    { lat: 49.2933573, lng: 21.7987039 },
    { lat: 49.2926939, lng: 21.7976727 },
    { lat: 49.292734, lng: 21.7969615 },
    { lat: 49.2926891, lng: 21.7966575 },
    { lat: 49.2923146, lng: 21.7962698 },
    { lat: 49.292139, lng: 21.7961774 },
    { lat: 49.2920936, lng: 21.7960713 },
    { lat: 49.2919788, lng: 21.7960249 },
    { lat: 49.291679, lng: 21.7956189 },
    { lat: 49.2913549, lng: 21.7955279 },
    { lat: 49.2911655, lng: 21.7955477 },
    { lat: 49.2906983, lng: 21.7951004 },
    { lat: 49.2905629, lng: 21.7950873 },
    { lat: 49.2904764, lng: 21.79496 },
    { lat: 49.2903635, lng: 21.7949427 },
    { lat: 49.290132, lng: 21.7946508 },
    { lat: 49.2900291, lng: 21.7944098 },
    { lat: 49.2898103, lng: 21.7942505 },
    { lat: 49.2895426, lng: 21.7941741 },
    { lat: 49.2894196, lng: 21.7943268 },
    { lat: 49.2893368, lng: 21.7943455 },
    { lat: 49.2892708, lng: 21.7945333 },
    { lat: 49.2892166, lng: 21.7945247 },
    { lat: 49.2891437, lng: 21.7942696 },
    { lat: 49.2889762, lng: 21.7939735 },
    { lat: 49.2888157, lng: 21.7939014 },
    { lat: 49.2887699, lng: 21.79395 },
    { lat: 49.2887137, lng: 21.794219 },
    { lat: 49.2884266, lng: 21.7939474 },
    { lat: 49.2880066, lng: 21.7939764 },
    { lat: 49.2872717, lng: 21.7936146 },
    { lat: 49.2866284, lng: 21.7934374 },
    { lat: 49.2860772, lng: 21.7930049 },
    { lat: 49.2855848, lng: 21.7929317 },
    { lat: 49.2852494, lng: 21.7927521 },
    { lat: 49.2843845, lng: 21.7921016 },
    { lat: 49.2840484, lng: 21.7924121 },
    { lat: 49.2837433, lng: 21.7925365 },
    { lat: 49.2834036, lng: 21.7923971 },
    { lat: 49.2832603, lng: 21.7924249 },
    { lat: 49.28306, lng: 21.7921618 },
    { lat: 49.2831213, lng: 21.791974 },
    { lat: 49.2829118, lng: 21.7918757 },
    { lat: 49.2822624, lng: 21.791165 },
    { lat: 49.2819154, lng: 21.7910077 },
    { lat: 49.2818137, lng: 21.7909029 },
    { lat: 49.2817896, lng: 21.7907131 },
    { lat: 49.2815953, lng: 21.7902925 },
    { lat: 49.2815036, lng: 21.7899284 },
    { lat: 49.2812428, lng: 21.7894511 },
    { lat: 49.280884, lng: 21.7891057 },
    { lat: 49.2807926, lng: 21.7885345 },
    { lat: 49.2806622, lng: 21.7883255 },
    { lat: 49.2807417, lng: 21.7882205 },
    { lat: 49.2808901, lng: 21.7875248 },
    { lat: 49.2801602, lng: 21.7886532 },
    { lat: 49.2794527, lng: 21.7901275 },
    { lat: 49.2787233, lng: 21.7925712 },
    { lat: 49.2787114, lng: 21.7934581 },
    { lat: 49.2779838, lng: 21.7940765 },
    { lat: 49.277373, lng: 21.7942439 },
    { lat: 49.2772898, lng: 21.7945901 },
    { lat: 49.2768941, lng: 21.7950821 },
    { lat: 49.275987, lng: 21.7957498 },
    { lat: 49.2757819, lng: 21.7962587 },
    { lat: 49.2757152, lng: 21.7967216 },
    { lat: 49.275529, lng: 21.7970506 },
    { lat: 49.2752539, lng: 21.7973324 },
    { lat: 49.2751047, lng: 21.7971682 },
    { lat: 49.274555, lng: 21.7972674 },
    { lat: 49.2742098, lng: 21.7970978 },
    { lat: 49.2737479, lng: 21.7971203 },
    { lat: 49.2731737, lng: 21.7973104 },
    { lat: 49.2728629, lng: 21.7975122 },
    { lat: 49.272272, lng: 21.7973668 },
    { lat: 49.2719693, lng: 21.7975364 },
    { lat: 49.27185, lng: 21.7988825 },
    { lat: 49.2713293, lng: 21.7995372 },
    { lat: 49.2709838, lng: 21.8001863 },
    { lat: 49.2708779, lng: 21.8004805 },
    { lat: 49.2708364, lng: 21.801484 },
    { lat: 49.2707772, lng: 21.8016738 },
    { lat: 49.2715307, lng: 21.8024535 },
    { lat: 49.2725396, lng: 21.8039234 },
    { lat: 49.2730001, lng: 21.8048631 },
    { lat: 49.2736716, lng: 21.8073791 },
    { lat: 49.2749099, lng: 21.8087698 },
    { lat: 49.2749245, lng: 21.810815 },
    { lat: 49.2747342, lng: 21.8119007 },
    { lat: 49.2743001, lng: 21.8126419 },
    { lat: 49.2739144, lng: 21.81457 },
    { lat: 49.2737828, lng: 21.8166243 },
    { lat: 49.2732481, lng: 21.8177796 },
    { lat: 49.2725944, lng: 21.8179969 },
    { lat: 49.2721174, lng: 21.8183844 },
    { lat: 49.2718767, lng: 21.8187374 },
    { lat: 49.2717434, lng: 21.8193218 },
    { lat: 49.2711974, lng: 21.8201413 },
    { lat: 49.2712188, lng: 21.8201839 },
    { lat: 49.2711343, lng: 21.8203082 },
    { lat: 49.2707318, lng: 21.820532 },
    { lat: 49.2700618, lng: 21.8212969 },
    { lat: 49.2694738, lng: 21.8215199 },
    { lat: 49.2691634, lng: 21.8218318 },
    { lat: 49.2686205, lng: 21.8220189 },
    { lat: 49.2684445, lng: 21.8223072 },
    { lat: 49.2681271, lng: 21.8223824 },
    { lat: 49.2676462, lng: 21.8226641 },
    { lat: 49.2667325, lng: 21.8238787 },
    { lat: 49.2662759, lng: 21.8250612 },
    { lat: 49.2650808, lng: 21.826178 },
    { lat: 49.261047, lng: 21.827694 },
    { lat: 49.261077, lng: 21.82773 },
    { lat: 49.261565, lng: 21.828613 },
    { lat: 49.261944, lng: 21.829456 },
    { lat: 49.262377, lng: 21.830267 },
    { lat: 49.262559, lng: 21.830515 },
    { lat: 49.262963, lng: 21.830932 },
    { lat: 49.263391, lng: 21.831058 },
    { lat: 49.263703, lng: 21.831278 },
    { lat: 49.264, lng: 21.832027 },
    { lat: 49.26424, lng: 21.832434 },
    { lat: 49.264663, lng: 21.833084 },
    { lat: 49.264849, lng: 21.8335 },
    { lat: 49.264896, lng: 21.833831 },
    { lat: 49.264677, lng: 21.834353 },
    { lat: 49.264425, lng: 21.834926 },
    { lat: 49.264067, lng: 21.835449 },
    { lat: 49.263986, lng: 21.836375 },
    { lat: 49.263595, lng: 21.837961 },
    { lat: 49.263241, lng: 21.839014 },
    { lat: 49.262866, lng: 21.84042 },
    { lat: 49.2627, lng: 21.840995 },
    { lat: 49.263135, lng: 21.841822 },
    { lat: 49.263564, lng: 21.842293 },
    { lat: 49.263786, lng: 21.842737 },
    { lat: 49.264425, lng: 21.843637 },
    { lat: 49.264807, lng: 21.844045 },
    { lat: 49.26527, lng: 21.844686 },
    { lat: 49.265818, lng: 21.845358 },
    { lat: 49.266183, lng: 21.845824 },
    { lat: 49.266344, lng: 21.845992 },
    { lat: 49.266733, lng: 21.846434 },
    { lat: 49.267563, lng: 21.847175 },
    { lat: 49.268211, lng: 21.847428 },
    { lat: 49.268596, lng: 21.847466 },
    { lat: 49.26895, lng: 21.847346 },
    { lat: 49.26938, lng: 21.847524 },
    { lat: 49.269782, lng: 21.84749 },
    { lat: 49.270046, lng: 21.847514 },
    { lat: 49.270663, lng: 21.847615 },
    { lat: 49.271171, lng: 21.847496 },
    { lat: 49.271338, lng: 21.847317 },
    { lat: 49.27147, lng: 21.847133 },
    { lat: 49.271668, lng: 21.847077 },
    { lat: 49.271993, lng: 21.847473 },
    { lat: 49.272069, lng: 21.848258 },
    { lat: 49.272204, lng: 21.848425 },
    { lat: 49.272435, lng: 21.848606 },
    { lat: 49.27257, lng: 21.848933 },
    { lat: 49.27265, lng: 21.849588 },
    { lat: 49.272639, lng: 21.850174 },
    { lat: 49.272946, lng: 21.850513 },
    { lat: 49.273118, lng: 21.850647 },
    { lat: 49.273172, lng: 21.850696 },
    { lat: 49.273393, lng: 21.850843 },
    { lat: 49.273937, lng: 21.851281 },
    { lat: 49.274304, lng: 21.851822 },
    { lat: 49.274644, lng: 21.852369 },
    { lat: 49.274765, lng: 21.852144 },
    { lat: 49.275121, lng: 21.852125 },
    { lat: 49.275424, lng: 21.85207 },
    { lat: 49.275582, lng: 21.85237 },
    { lat: 49.276077, lng: 21.851709 },
    { lat: 49.276211, lng: 21.852587 },
    { lat: 49.276385, lng: 21.852983 },
    { lat: 49.276522, lng: 21.853609 },
    { lat: 49.276612, lng: 21.854309 },
    { lat: 49.276768, lng: 21.854687 },
    { lat: 49.277105, lng: 21.855272 },
    { lat: 49.277798, lng: 21.856656 },
    { lat: 49.27782, lng: 21.856701 },
    { lat: 49.27783, lng: 21.85672 },
    { lat: 49.277848, lng: 21.856755 },
    { lat: 49.27824, lng: 21.857525 },
    { lat: 49.278497, lng: 21.857218 },
    { lat: 49.27853, lng: 21.85725 },
    { lat: 49.278729, lng: 21.857485 },
    { lat: 49.278748, lng: 21.857509 },
    { lat: 49.279733, lng: 21.858634 },
    { lat: 49.280082, lng: 21.858234 },
    { lat: 49.282497, lng: 21.861305 },
    { lat: 49.282597, lng: 21.861433 },
    { lat: 49.283472, lng: 21.862544 },
    { lat: 49.283523, lng: 21.86261 },
    { lat: 49.283565, lng: 21.862666 },
    { lat: 49.284823, lng: 21.864111 },
    { lat: 49.284848, lng: 21.864069 },
    { lat: 49.284855, lng: 21.864059 },
    { lat: 49.284997, lng: 21.8638 },
    { lat: 49.285157, lng: 21.864051 },
    { lat: 49.28543, lng: 21.864475 },
    { lat: 49.285465, lng: 21.864975 },
    { lat: 49.285601, lng: 21.865286 },
    { lat: 49.285462, lng: 21.865462 },
    { lat: 49.285801, lng: 21.865716 },
    { lat: 49.286014, lng: 21.866037 },
    { lat: 49.286248, lng: 21.866336 },
    { lat: 49.286822, lng: 21.867037 },
    { lat: 49.287023, lng: 21.867456 },
    { lat: 49.287187, lng: 21.867767 },
    { lat: 49.287835, lng: 21.868618 },
    { lat: 49.288154, lng: 21.868786 },
    { lat: 49.288655, lng: 21.868854 },
    { lat: 49.289144, lng: 21.868744 },
    { lat: 49.289407, lng: 21.868848 },
    { lat: 49.2894427, lng: 21.8668177 },
    { lat: 49.2889569, lng: 21.865447 },
    { lat: 49.2891858, lng: 21.8635579 },
    { lat: 49.2893112, lng: 21.8610513 },
    { lat: 49.2893667, lng: 21.8606725 },
    { lat: 49.2898884, lng: 21.8589308 },
    { lat: 49.290249, lng: 21.8573167 },
    { lat: 49.290954, lng: 21.8548771 },
    { lat: 49.2906008, lng: 21.8523502 },
    { lat: 49.2906108, lng: 21.8519943 },
    { lat: 49.2909889, lng: 21.8505896 },
    { lat: 49.2910162, lng: 21.8502074 },
    { lat: 49.2914049, lng: 21.850051 },
    { lat: 49.2913969, lng: 21.8497837 },
    { lat: 49.292149, lng: 21.8491062 },
    { lat: 49.2911127, lng: 21.8466566 },
    { lat: 49.2901892, lng: 21.8457766 },
    { lat: 49.2902085, lng: 21.8431789 },
    { lat: 49.2898797, lng: 21.8414058 },
    { lat: 49.2894839, lng: 21.838461 },
    { lat: 49.2892011, lng: 21.8380499 },
    { lat: 49.2889953, lng: 21.8383515 },
    { lat: 49.2887527, lng: 21.8383213 },
    { lat: 49.2887087, lng: 21.8381402 },
    { lat: 49.2887397, lng: 21.8377744 },
    { lat: 49.2886526, lng: 21.8375292 },
    { lat: 49.2883774, lng: 21.8374123 },
    { lat: 49.2880193, lng: 21.8366932 },
    { lat: 49.2876286, lng: 21.8364572 },
    { lat: 49.2874335, lng: 21.8359132 },
    { lat: 49.2873149, lng: 21.8352771 },
    { lat: 49.287284, lng: 21.8346866 },
    { lat: 49.2867621, lng: 21.8339835 },
    { lat: 49.2865683, lng: 21.8335772 },
    { lat: 49.2865953, lng: 21.83327 },
    { lat: 49.2862404, lng: 21.830785 },
    { lat: 49.2867558, lng: 21.8281991 },
    { lat: 49.2872302, lng: 21.8280597 },
    { lat: 49.2870779, lng: 21.827693 },
    { lat: 49.2872104, lng: 21.8266358 },
    { lat: 49.2872608, lng: 21.8232923 },
    { lat: 49.2880168, lng: 21.82137 },
    { lat: 49.288863, lng: 21.8195023 },
    { lat: 49.2879776, lng: 21.8180591 },
    { lat: 49.2876554, lng: 21.8171154 },
    { lat: 49.2890223, lng: 21.8160375 },
    { lat: 49.2903049, lng: 21.8147827 },
    { lat: 49.2905006, lng: 21.8120792 },
    { lat: 49.2907405, lng: 21.8115649 },
    { lat: 49.2902253, lng: 21.8111733 },
    { lat: 49.2905388, lng: 21.8095491 },
    { lat: 49.2906952, lng: 21.8092409 },
    { lat: 49.2906989, lng: 21.8089474 },
    { lat: 49.291215, lng: 21.8080312 },
    { lat: 49.2912698, lng: 21.8075425 },
    { lat: 49.2911498, lng: 21.8073775 },
    { lat: 49.291227, lng: 21.8069246 },
    { lat: 49.291124, lng: 21.8065964 },
    { lat: 49.2912792, lng: 21.8058544 },
    { lat: 49.2914716, lng: 21.8056839 },
    { lat: 49.2918923, lng: 21.8055071 },
    { lat: 49.2922551, lng: 21.8050975 },
    { lat: 49.2934663, lng: 21.8042671 },
    { lat: 49.2936723, lng: 21.8038392 },
    { lat: 49.2940355, lng: 21.8036115 },
    { lat: 49.2947918, lng: 21.8025844 },
    { lat: 49.2951681, lng: 21.8020903 },
  ],
  TuranynadOndavou: [
    { lat: 49.1042453, lng: 21.6769317 },
    { lat: 49.1047673, lng: 21.6766358 },
    { lat: 49.1050994, lng: 21.6765568 },
    { lat: 49.1052518, lng: 21.676368 },
    { lat: 49.1052835, lng: 21.6760063 },
    { lat: 49.1055554, lng: 21.6757049 },
    { lat: 49.1059381, lng: 21.6750819 },
    { lat: 49.1061204, lng: 21.6749197 },
    { lat: 49.1063796, lng: 21.6743577 },
    { lat: 49.1064567, lng: 21.6736702 },
    { lat: 49.1061986, lng: 21.6732337 },
    { lat: 49.1064158, lng: 21.6725335 },
    { lat: 49.1071645, lng: 21.6718104 },
    { lat: 49.1101269, lng: 21.6680025 },
    { lat: 49.1120482, lng: 21.6649722 },
    { lat: 49.1123288, lng: 21.6638266 },
    { lat: 49.1122605, lng: 21.6636718 },
    { lat: 49.1123886, lng: 21.6635815 },
    { lat: 49.1125774, lng: 21.6626586 },
    { lat: 49.1125944, lng: 21.66122 },
    { lat: 49.1126351, lng: 21.6610598 },
    { lat: 49.1124123, lng: 21.6606437 },
    { lat: 49.1121725, lng: 21.6589412 },
    { lat: 49.1120391, lng: 21.6575118 },
    { lat: 49.1122414, lng: 21.6558475 },
    { lat: 49.1115599, lng: 21.6556518 },
    { lat: 49.1109317, lng: 21.656294 },
    { lat: 49.1107064, lng: 21.6570666 },
    { lat: 49.1100102, lng: 21.6567856 },
    { lat: 49.1098438, lng: 21.6559982 },
    { lat: 49.1095449, lng: 21.6558523 },
    { lat: 49.1095155, lng: 21.6554326 },
    { lat: 49.1093211, lng: 21.6550693 },
    { lat: 49.1094185, lng: 21.6546394 },
    { lat: 49.109157, lng: 21.6542484 },
    { lat: 49.1089837, lng: 21.6537078 },
    { lat: 49.1086394, lng: 21.6532541 },
    { lat: 49.1084746, lng: 21.6531924 },
    { lat: 49.1086805, lng: 21.6525757 },
    { lat: 49.1091725, lng: 21.6522527 },
    { lat: 49.1091794, lng: 21.6517212 },
    { lat: 49.1126716, lng: 21.651199 },
    { lat: 49.1126495, lng: 21.6510015 },
    { lat: 49.1125455, lng: 21.6507402 },
    { lat: 49.1122665, lng: 21.6500382 },
    { lat: 49.1116832, lng: 21.6495569 },
    { lat: 49.111439, lng: 21.649646 },
    { lat: 49.1005962, lng: 21.646045 },
    { lat: 49.0983858, lng: 21.638238 },
    { lat: 49.0973526, lng: 21.6426185 },
    { lat: 49.0789104, lng: 21.6518019 },
    { lat: 49.0775031, lng: 21.6573024 },
    { lat: 49.0758496, lng: 21.6626813 },
    { lat: 49.06542, lng: 21.666388 },
    { lat: 49.067291, lng: 21.669454 },
    { lat: 49.067308, lng: 21.669443 },
    { lat: 49.067749, lng: 21.671753 },
    { lat: 49.068572, lng: 21.675027 },
    { lat: 49.069058, lng: 21.676804 },
    { lat: 49.067817, lng: 21.678752 },
    { lat: 49.06833, lng: 21.680081 },
    { lat: 49.068861, lng: 21.681123 },
    { lat: 49.069429, lng: 21.681788 },
    { lat: 49.06955, lng: 21.682426 },
    { lat: 49.069944, lng: 21.683281 },
    { lat: 49.070669, lng: 21.684172 },
    { lat: 49.070849, lng: 21.684748 },
    { lat: 49.071005, lng: 21.684822 },
    { lat: 49.071015, lng: 21.684842 },
    { lat: 49.071285, lng: 21.685444 },
    { lat: 49.072357, lng: 21.687056 },
    { lat: 49.072793, lng: 21.687402 },
    { lat: 49.073169, lng: 21.688223 },
    { lat: 49.073464, lng: 21.688907 },
    { lat: 49.074265, lng: 21.690213 },
    { lat: 49.0769693, lng: 21.6892208 },
    { lat: 49.0775848, lng: 21.689387 },
    { lat: 49.0798327, lng: 21.6850597 },
    { lat: 49.0799924, lng: 21.6845211 },
    { lat: 49.0801337, lng: 21.6845432 },
    { lat: 49.080815, lng: 21.6849883 },
    { lat: 49.0805525, lng: 21.6830521 },
    { lat: 49.0803798, lng: 21.6823667 },
    { lat: 49.0804323, lng: 21.6821484 },
    { lat: 49.0816087, lng: 21.6835117 },
    { lat: 49.0819217, lng: 21.6830529 },
    { lat: 49.082315, lng: 21.6827964 },
    { lat: 49.0825397, lng: 21.6822383 },
    { lat: 49.0842764, lng: 21.6805203 },
    { lat: 49.085484, lng: 21.6815813 },
    { lat: 49.0852291, lng: 21.6780219 },
    { lat: 49.0873209, lng: 21.6778682 },
    { lat: 49.0889602, lng: 21.6774675 },
    { lat: 49.0914258, lng: 21.6759503 },
    { lat: 49.0912519, lng: 21.675145 },
    { lat: 49.0914556, lng: 21.6733141 },
    { lat: 49.0918085, lng: 21.6723831 },
    { lat: 49.0918547, lng: 21.6718522 },
    { lat: 49.0917068, lng: 21.6705875 },
    { lat: 49.0929899, lng: 21.670733 },
    { lat: 49.0942032, lng: 21.6702117 },
    { lat: 49.0945793, lng: 21.670146 },
    { lat: 49.0948371, lng: 21.6703151 },
    { lat: 49.0953423, lng: 21.670358 },
    { lat: 49.0970445, lng: 21.6707392 },
    { lat: 49.097228, lng: 21.6707008 },
    { lat: 49.097507, lng: 21.6708261 },
    { lat: 49.0985229, lng: 21.671039 },
    { lat: 49.098782, lng: 21.6708393 },
    { lat: 49.0990295, lng: 21.670925 },
    { lat: 49.0993016, lng: 21.6711276 },
    { lat: 49.0997006, lng: 21.6712382 },
    { lat: 49.1004553, lng: 21.6713204 },
    { lat: 49.1010783, lng: 21.6714748 },
    { lat: 49.101491, lng: 21.67147 },
    { lat: 49.1017888, lng: 21.6716218 },
    { lat: 49.1020882, lng: 21.6719186 },
    { lat: 49.1021776, lng: 21.6722681 },
    { lat: 49.1027052, lng: 21.6735572 },
    { lat: 49.10276, lng: 21.6737979 },
    { lat: 49.1028889, lng: 21.6740663 },
    { lat: 49.1029958, lng: 21.6741572 },
    { lat: 49.1031252, lng: 21.6745728 },
    { lat: 49.1032638, lng: 21.6748258 },
    { lat: 49.103259, lng: 21.6749798 },
    { lat: 49.1034951, lng: 21.6758482 },
    { lat: 49.1039125, lng: 21.6763918 },
    { lat: 49.1042453, lng: 21.6769317 },
  ],
};

export default UnitsStropkov;
