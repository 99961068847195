const UnitsŠaľa = {
  Vlčany: [
    { lat: 48.022491, lng: 17.868624 },
    { lat: 48.019921, lng: 17.872068 },
    { lat: 48.018354, lng: 17.874114 },
    { lat: 48.017942, lng: 17.873986 },
    { lat: 48.017855, lng: 17.87396 },
    { lat: 48.016577, lng: 17.873568 },
    { lat: 48.013598, lng: 17.872686 },
    { lat: 48.012522, lng: 17.8723 },
    { lat: 48.011328, lng: 17.87193 },
    { lat: 48.010438, lng: 17.871619 },
    { lat: 48.009679, lng: 17.871354 },
    { lat: 48.009591, lng: 17.871323 },
    { lat: 48.009287, lng: 17.871222 },
    { lat: 48.006785, lng: 17.870382 },
    { lat: 48.006487, lng: 17.87018 },
    { lat: 48.005895, lng: 17.869777 },
    { lat: 48.005471, lng: 17.869626 },
    { lat: 48.004962, lng: 17.870079 },
    { lat: 48.004596, lng: 17.870407 },
    { lat: 48.004409, lng: 17.870575 },
    { lat: 48.003694, lng: 17.871216 },
    { lat: 48.002139, lng: 17.872191 },
    { lat: 48.00149, lng: 17.8726 },
    { lat: 48.001423, lng: 17.872642 },
    { lat: 48.001406, lng: 17.872653 },
    { lat: 48.00131, lng: 17.872713 },
    { lat: 48.001093, lng: 17.872848 },
    { lat: 47.99995, lng: 17.873561 },
    { lat: 47.999353, lng: 17.873922 },
    { lat: 47.998729, lng: 17.874297 },
    { lat: 47.997556, lng: 17.875004 },
    { lat: 47.996836, lng: 17.875407 },
    { lat: 47.995228, lng: 17.876282 },
    { lat: 47.994125, lng: 17.876925 },
    { lat: 47.992646, lng: 17.877822 },
    { lat: 47.990706, lng: 17.878919 },
    { lat: 47.989349, lng: 17.879718 },
    { lat: 47.988268, lng: 17.880376 },
    { lat: 47.987985, lng: 17.880529 },
    { lat: 47.9880563, lng: 17.8820515 },
    { lat: 47.9877781, lng: 17.8839634 },
    { lat: 47.9873308, lng: 17.8865442 },
    { lat: 47.9868259, lng: 17.8889776 },
    { lat: 47.9864363, lng: 17.8913235 },
    { lat: 47.9862764, lng: 17.8938997 },
    { lat: 47.9862176, lng: 17.8959139 },
    { lat: 47.9855652, lng: 17.8979488 },
    { lat: 47.987075, lng: 17.9019731 },
    { lat: 47.9883286, lng: 17.9062788 },
    { lat: 47.9894961, lng: 17.9108056 },
    { lat: 47.9900552, lng: 17.9140215 },
    { lat: 47.99072, lng: 17.918954 },
    { lat: 47.9907632, lng: 17.918961 },
    { lat: 47.9937639, lng: 17.9194491 },
    { lat: 47.9986774, lng: 17.9205617 },
    { lat: 48.0009411, lng: 17.9211263 },
    { lat: 48.0042441, lng: 17.9222437 },
    { lat: 48.0054718, lng: 17.9246701 },
    { lat: 48.0077085, lng: 17.9279887 },
    { lat: 48.0098482, lng: 17.9310471 },
    { lat: 48.0106924, lng: 17.9324018 },
    { lat: 48.0133626, lng: 17.9364314 },
    { lat: 48.0134606, lng: 17.9365815 },
    { lat: 48.015036, lng: 17.9391621 },
    { lat: 48.0163329, lng: 17.941421 },
    { lat: 48.0174822, lng: 17.9435064 },
    { lat: 48.0182668, lng: 17.9455771 },
    { lat: 48.0186032, lng: 17.9466748 },
    { lat: 48.0197459, lng: 17.9481372 },
    { lat: 48.0207619, lng: 17.9492215 },
    { lat: 48.0226788, lng: 17.952193 },
    { lat: 48.0230522, lng: 17.952896 },
    { lat: 48.0239656, lng: 17.9543371 },
    { lat: 48.024693, lng: 17.9555365 },
    { lat: 48.0252205, lng: 17.9564252 },
    { lat: 48.0260047, lng: 17.9584186 },
    { lat: 48.0271506, lng: 17.9602738 },
    { lat: 48.0275403, lng: 17.9610988 },
    { lat: 48.0279593, lng: 17.9615506 },
    { lat: 48.0287069, lng: 17.9618385 },
    { lat: 48.0292595, lng: 17.9627093 },
    { lat: 48.0299498, lng: 17.9634676 },
    { lat: 48.0303051, lng: 17.9642479 },
    { lat: 48.0314892, lng: 17.9660238 },
    { lat: 48.0336376, lng: 17.9697921 },
    { lat: 48.0345371, lng: 17.9713998 },
    { lat: 48.0354173, lng: 17.9731594 },
    { lat: 48.0363922, lng: 17.9755873 },
    { lat: 48.0373536, lng: 17.9777914 },
    { lat: 48.0390874, lng: 17.9823647 },
    { lat: 48.0394438, lng: 17.9837666 },
    { lat: 48.0402431, lng: 17.9864331 },
    { lat: 48.0411767, lng: 17.9887263 },
    { lat: 48.0463224, lng: 17.9966522 },
    { lat: 48.047221, lng: 17.9981 },
    { lat: 48.047428, lng: 17.997827 },
    { lat: 48.049292, lng: 17.995585 },
    { lat: 48.051011, lng: 17.993514 },
    { lat: 48.051033, lng: 17.993489 },
    { lat: 48.052105, lng: 17.992556 },
    { lat: 48.052119, lng: 17.992539 },
    { lat: 48.052985, lng: 17.991622 },
    { lat: 48.053012, lng: 17.991582 },
    { lat: 48.054531, lng: 17.989331 },
    { lat: 48.054646, lng: 17.989163 },
    { lat: 48.056578, lng: 17.98657 },
    { lat: 48.058456, lng: 17.984325 },
    { lat: 48.05869, lng: 17.98416 },
    { lat: 48.059015, lng: 17.983952 },
    { lat: 48.0589472, lng: 17.983233 },
    { lat: 48.0606292, lng: 17.9816618 },
    { lat: 48.0609703, lng: 17.9809715 },
    { lat: 48.0611312, lng: 17.9800446 },
    { lat: 48.061055, lng: 17.9795851 },
    { lat: 48.061223, lng: 17.9789455 },
    { lat: 48.061154, lng: 17.9785645 },
    { lat: 48.0609559, lng: 17.9782178 },
    { lat: 48.0607307, lng: 17.9780589 },
    { lat: 48.0603092, lng: 17.9780321 },
    { lat: 48.0597577, lng: 17.9775333 },
    { lat: 48.0595591, lng: 17.9772727 },
    { lat: 48.0594302, lng: 17.9767619 },
    { lat: 48.0595177, lng: 17.9766238 },
    { lat: 48.059595, lng: 17.9762381 },
    { lat: 48.059524, lng: 17.9760539 },
    { lat: 48.0594088, lng: 17.9759204 },
    { lat: 48.0588934, lng: 17.9758134 },
    { lat: 48.0585993, lng: 17.9758977 },
    { lat: 48.0585027, lng: 17.9760186 },
    { lat: 48.0580551, lng: 17.9759339 },
    { lat: 48.0577305, lng: 17.975528 },
    { lat: 48.0572519, lng: 17.9745721 },
    { lat: 48.05701, lng: 17.9739498 },
    { lat: 48.0568323, lng: 17.9731159 },
    { lat: 48.0564322, lng: 17.9724693 },
    { lat: 48.0563074, lng: 17.9716005 },
    { lat: 48.0562702, lng: 17.9716182 },
    { lat: 48.0558529, lng: 17.965468 },
    { lat: 48.0518437, lng: 17.9586804 },
    { lat: 48.052044, lng: 17.9584443 },
    { lat: 48.0521967, lng: 17.9580594 },
    { lat: 48.0542857, lng: 17.9561484 },
    { lat: 48.0548861, lng: 17.9538677 },
    { lat: 48.0552333, lng: 17.9531676 },
    { lat: 48.0557879, lng: 17.9527631 },
    { lat: 48.0558751, lng: 17.9526202 },
    { lat: 48.0560329, lng: 17.9521283 },
    { lat: 48.0562203, lng: 17.9519263 },
    { lat: 48.05655, lng: 17.9509745 },
    { lat: 48.0568563, lng: 17.9496368 },
    { lat: 48.0606377, lng: 17.9506268 },
    { lat: 48.0586978, lng: 17.9480983 },
    { lat: 48.0560032, lng: 17.9468419 },
    { lat: 48.0546914, lng: 17.9462991 },
    { lat: 48.0541484, lng: 17.9423256 },
    { lat: 48.0545056, lng: 17.9418291 },
    { lat: 48.0549779, lng: 17.9404023 },
    { lat: 48.0552842, lng: 17.9390133 },
    { lat: 48.0559168, lng: 17.93801 },
    { lat: 48.0561016, lng: 17.9376175 },
    { lat: 48.0564546, lng: 17.9368304 },
    { lat: 48.0567198, lng: 17.9358939 },
    { lat: 48.0564192, lng: 17.9348096 },
    { lat: 48.0551683, lng: 17.9313631 },
    { lat: 48.0552611, lng: 17.9284233 },
    { lat: 48.0552474, lng: 17.9266928 },
    { lat: 48.0554962, lng: 17.9222533 },
    { lat: 48.0555396, lng: 17.9205923 },
    { lat: 48.055478, lng: 17.9196513 },
    { lat: 48.0553236, lng: 17.919283 },
    { lat: 48.0551816, lng: 17.9190827 },
    { lat: 48.0522598, lng: 17.9168475 },
    { lat: 48.0499016, lng: 17.911809 },
    { lat: 48.04712, lng: 17.9061661 },
    { lat: 48.0449527, lng: 17.9023651 },
    { lat: 48.0433868, lng: 17.8992671 },
    { lat: 48.0426313, lng: 17.8980958 },
    { lat: 48.0424104, lng: 17.8978791 },
    { lat: 48.0421665, lng: 17.8970092 },
    { lat: 48.0419393, lng: 17.8964294 },
    { lat: 48.0416889, lng: 17.8959487 },
    { lat: 48.0398049, lng: 17.8931182 },
    { lat: 48.0378048, lng: 17.8898829 },
    { lat: 48.034274, lng: 17.88447 },
    { lat: 48.03426, lng: 17.884449 },
    { lat: 48.03391, lng: 17.883905 },
    { lat: 48.033518, lng: 17.883298 },
    { lat: 48.033351, lng: 17.883039 },
    { lat: 48.032769, lng: 17.882134 },
    { lat: 48.032572, lng: 17.881828 },
    { lat: 48.032532, lng: 17.881767 },
    { lat: 48.032478, lng: 17.881685 },
    { lat: 48.032244, lng: 17.88141 },
    { lat: 48.031611, lng: 17.880672 },
    { lat: 48.031091, lng: 17.880064 },
    { lat: 48.031039, lng: 17.880005 },
    { lat: 48.030993, lng: 17.879928 },
    { lat: 48.030753, lng: 17.879539 },
    { lat: 48.030177, lng: 17.878602 },
    { lat: 48.029613, lng: 17.877685 },
    { lat: 48.029577, lng: 17.877628 },
    { lat: 48.029549, lng: 17.877581 },
    { lat: 48.029539, lng: 17.877566 },
    { lat: 48.02947, lng: 17.877454 },
    { lat: 48.02915, lng: 17.87704 },
    { lat: 48.02886, lng: 17.876666 },
    { lat: 48.028492, lng: 17.876191 },
    { lat: 48.028373, lng: 17.87604 },
    { lat: 48.028301, lng: 17.875947 },
    { lat: 48.027345, lng: 17.874705 },
    { lat: 48.027161, lng: 17.874465 },
    { lat: 48.027095, lng: 17.874379 },
    { lat: 48.027087, lng: 17.874369 },
    { lat: 48.026826, lng: 17.874019 },
    { lat: 48.026436, lng: 17.873592 },
    { lat: 48.026081, lng: 17.873203 },
    { lat: 48.026064, lng: 17.873184 },
    { lat: 48.025781, lng: 17.872867 },
    { lat: 48.025736, lng: 17.872817 },
    { lat: 48.025693, lng: 17.872762 },
    { lat: 48.024725, lng: 17.87166 },
    { lat: 48.024718, lng: 17.871651 },
    { lat: 48.024457, lng: 17.871301 },
    { lat: 48.024393, lng: 17.871215 },
    { lat: 48.024367, lng: 17.871181 },
    { lat: 48.024252, lng: 17.871023 },
    { lat: 48.023327, lng: 17.869763 },
    { lat: 48.022731, lng: 17.86895 },
    { lat: 48.022699, lng: 17.868905 },
    { lat: 48.022542, lng: 17.868695 },
    { lat: 48.022491, lng: 17.868624 },
  ],
  HornáKráľová: [
    { lat: 48.226445, lng: 17.868959 },
    { lat: 48.224757, lng: 17.870325 },
    { lat: 48.224005, lng: 17.870917 },
    { lat: 48.223231, lng: 17.871806 },
    { lat: 48.222832, lng: 17.872268 },
    { lat: 48.222172, lng: 17.873215 },
    { lat: 48.222116, lng: 17.873348 },
    { lat: 48.221632, lng: 17.874347 },
    { lat: 48.221343, lng: 17.874801 },
    { lat: 48.220851, lng: 17.875409 },
    { lat: 48.220281, lng: 17.876064 },
    { lat: 48.219663, lng: 17.876771 },
    { lat: 48.219061, lng: 17.877458 },
    { lat: 48.218526, lng: 17.87798 },
    { lat: 48.218157, lng: 17.878288 },
    { lat: 48.217655, lng: 17.878719 },
    { lat: 48.217256, lng: 17.879164 },
    { lat: 48.216786, lng: 17.879821 },
    { lat: 48.216482, lng: 17.880208 },
    { lat: 48.216257, lng: 17.880477 },
    { lat: 48.215627, lng: 17.881205 },
    { lat: 48.215114, lng: 17.881865 },
    { lat: 48.214931, lng: 17.882025 },
    { lat: 48.214684, lng: 17.882153 },
    { lat: 48.214587, lng: 17.882188 },
    { lat: 48.214407, lng: 17.882209 },
    { lat: 48.214144, lng: 17.882196 },
    { lat: 48.213969, lng: 17.882168 },
    { lat: 48.213855, lng: 17.882128 },
    { lat: 48.213622, lng: 17.881909 },
    { lat: 48.213518, lng: 17.881778 },
    { lat: 48.213187, lng: 17.881453 },
    { lat: 48.212956, lng: 17.881162 },
    { lat: 48.212818, lng: 17.880972 },
    { lat: 48.212628, lng: 17.880707 },
    { lat: 48.212488, lng: 17.880519 },
    { lat: 48.212222, lng: 17.880205 },
    { lat: 48.212109, lng: 17.880235 },
    { lat: 48.211725, lng: 17.880373 },
    { lat: 48.211126, lng: 17.880588 },
    { lat: 48.210841, lng: 17.880716 },
    { lat: 48.210245, lng: 17.88097 },
    { lat: 48.209489, lng: 17.881326 },
    { lat: 48.208735, lng: 17.881977 },
    { lat: 48.208636, lng: 17.882062 },
    { lat: 48.2086002, lng: 17.8820921 },
    { lat: 48.208562, lng: 17.8821332 },
    { lat: 48.2087708, lng: 17.8825007 },
    { lat: 48.2090983, lng: 17.8833189 },
    { lat: 48.2100531, lng: 17.8848004 },
    { lat: 48.2131162, lng: 17.8869773 },
    { lat: 48.2157172, lng: 17.8892946 },
    { lat: 48.2157755, lng: 17.8893506 },
    { lat: 48.217672, lng: 17.8913749 },
    { lat: 48.2191802, lng: 17.8931751 },
    { lat: 48.2218561, lng: 17.8961584 },
    { lat: 48.2235772, lng: 17.8970811 },
    { lat: 48.22513, lng: 17.898209 },
    { lat: 48.2256691, lng: 17.8986843 },
    { lat: 48.2271056, lng: 17.8974481 },
    { lat: 48.2312678, lng: 17.9036235 },
    { lat: 48.2323948, lng: 17.9054349 },
    { lat: 48.2359719, lng: 17.9108698 },
    { lat: 48.2367408, lng: 17.9128289 },
    { lat: 48.2377084, lng: 17.9148451 },
    { lat: 48.235828, lng: 17.9163931 },
    { lat: 48.2367907, lng: 17.9189824 },
    { lat: 48.2373339, lng: 17.918504 },
    { lat: 48.2377361, lng: 17.9195882 },
    { lat: 48.2389677, lng: 17.9185677 },
    { lat: 48.2394259, lng: 17.9197403 },
    { lat: 48.2395575, lng: 17.9196753 },
    { lat: 48.2396713, lng: 17.9197098 },
    { lat: 48.2397317, lng: 17.9198875 },
    { lat: 48.2400458, lng: 17.9196289 },
    { lat: 48.2406915, lng: 17.9218327 },
    { lat: 48.240665, lng: 17.9218604 },
    { lat: 48.2410831, lng: 17.9228749 },
    { lat: 48.2423064, lng: 17.9324081 },
    { lat: 48.2428854, lng: 17.9355287 },
    { lat: 48.2427631, lng: 17.9360662 },
    { lat: 48.2429202, lng: 17.9376969 },
    { lat: 48.2436269, lng: 17.9431244 },
    { lat: 48.2437954, lng: 17.945 },
    { lat: 48.2438041, lng: 17.9461373 },
    { lat: 48.243878, lng: 17.9470813 },
    { lat: 48.2457517, lng: 17.9581378 },
    { lat: 48.2462412, lng: 17.9604193 },
    { lat: 48.2463213, lng: 17.961019 },
    { lat: 48.2463072, lng: 17.9613863 },
    { lat: 48.2464242, lng: 17.9616808 },
    { lat: 48.2464914, lng: 17.9626087 },
    { lat: 48.2466777, lng: 17.9640748 },
    { lat: 48.2465658, lng: 17.9653485 },
    { lat: 48.2466938, lng: 17.9673087 },
    { lat: 48.2468429, lng: 17.9680617 },
    { lat: 48.2471317, lng: 17.9689984 },
    { lat: 48.2471696, lng: 17.9696658 },
    { lat: 48.247473, lng: 17.9707712 },
    { lat: 48.2475639, lng: 17.9717013 },
    { lat: 48.2478552, lng: 17.9728856 },
    { lat: 48.249943, lng: 17.975901 },
    { lat: 48.250957, lng: 17.974841 },
    { lat: 48.251383, lng: 17.974378 },
    { lat: 48.251634, lng: 17.972615 },
    { lat: 48.251495, lng: 17.970878 },
    { lat: 48.251489, lng: 17.970304 },
    { lat: 48.251489, lng: 17.970262 },
    { lat: 48.251486, lng: 17.969954 },
    { lat: 48.251377, lng: 17.968914 },
    { lat: 48.251352, lng: 17.968425 },
    { lat: 48.251324, lng: 17.967888 },
    { lat: 48.251509, lng: 17.967031 },
    { lat: 48.251937, lng: 17.965428 },
    { lat: 48.25198, lng: 17.964987 },
    { lat: 48.251983, lng: 17.9646 },
    { lat: 48.251961, lng: 17.964142 },
    { lat: 48.251895, lng: 17.962818 },
    { lat: 48.251913, lng: 17.961414 },
    { lat: 48.251908, lng: 17.959956 },
    { lat: 48.25229, lng: 17.958529 },
    { lat: 48.252644, lng: 17.957197 },
    { lat: 48.252658, lng: 17.95714 },
    { lat: 48.252782, lng: 17.956678 },
    { lat: 48.253253, lng: 17.954928 },
    { lat: 48.253377, lng: 17.954188 },
    { lat: 48.253477, lng: 17.953459 },
    { lat: 48.253494, lng: 17.952688 },
    { lat: 48.253514, lng: 17.952267 },
    { lat: 48.253525, lng: 17.952015 },
    { lat: 48.253549, lng: 17.951293 },
    { lat: 48.253585, lng: 17.950335 },
    { lat: 48.253624, lng: 17.949274 },
    { lat: 48.253694, lng: 17.949292 },
    { lat: 48.253721, lng: 17.948956 },
    { lat: 48.253729, lng: 17.94886 },
    { lat: 48.253733, lng: 17.948842 },
    { lat: 48.254033, lng: 17.947782 },
    { lat: 48.254251, lng: 17.94697 },
    { lat: 48.254563, lng: 17.945862 },
    { lat: 48.25508, lng: 17.944281 },
    { lat: 48.255238, lng: 17.94385 },
    { lat: 48.25588, lng: 17.942097 },
    { lat: 48.255896, lng: 17.942053 },
    { lat: 48.256315, lng: 17.941037 },
    { lat: 48.256495, lng: 17.940598 },
    { lat: 48.257317, lng: 17.938655 },
    { lat: 48.258331, lng: 17.936192 },
    { lat: 48.259759, lng: 17.932657 },
    { lat: 48.259865, lng: 17.932396 },
    { lat: 48.259913, lng: 17.93228 },
    { lat: 48.259933, lng: 17.93223 },
    { lat: 48.25999, lng: 17.932091 },
    { lat: 48.260035, lng: 17.932094 },
    { lat: 48.260118, lng: 17.931997 },
    { lat: 48.260213, lng: 17.931887 },
    { lat: 48.261061, lng: 17.930909 },
    { lat: 48.261303, lng: 17.930528 },
    { lat: 48.261325, lng: 17.930495 },
    { lat: 48.261444, lng: 17.930308 },
    { lat: 48.261699, lng: 17.929887 },
    { lat: 48.262102, lng: 17.929317 },
    { lat: 48.262679, lng: 17.928425 },
    { lat: 48.262684, lng: 17.928415 },
    { lat: 48.262707, lng: 17.928372 },
    { lat: 48.262797, lng: 17.92818 },
    { lat: 48.263396, lng: 17.926571 },
    { lat: 48.263447, lng: 17.926272 },
    { lat: 48.263418, lng: 17.92593 },
    { lat: 48.263508, lng: 17.925712 },
    { lat: 48.263776, lng: 17.92526 },
    { lat: 48.264125, lng: 17.924771 },
    { lat: 48.264316, lng: 17.924504 },
    { lat: 48.264613, lng: 17.924111 },
    { lat: 48.264968, lng: 17.923543 },
    { lat: 48.265488, lng: 17.922493 },
    { lat: 48.265942, lng: 17.921657 },
    { lat: 48.266223, lng: 17.921002 },
    { lat: 48.266328, lng: 17.920802 },
    { lat: 48.266524, lng: 17.920553 },
    { lat: 48.267239, lng: 17.91963 },
    { lat: 48.267461, lng: 17.919264 },
    { lat: 48.267677, lng: 17.918874 },
    { lat: 48.267924, lng: 17.918333 },
    { lat: 48.268198, lng: 17.917846 },
    { lat: 48.268713, lng: 17.916974 },
    { lat: 48.268922, lng: 17.916487 },
    { lat: 48.268963, lng: 17.916259 },
    { lat: 48.268572, lng: 17.915735 },
    { lat: 48.268267, lng: 17.915362 },
    { lat: 48.268717, lng: 17.914687 },
    { lat: 48.269191, lng: 17.913993 },
    { lat: 48.26965, lng: 17.913278 },
    { lat: 48.270006, lng: 17.912757 },
    { lat: 48.269547, lng: 17.911652 },
    { lat: 48.270804, lng: 17.909966 },
    { lat: 48.272412, lng: 17.908493 },
    { lat: 48.272708, lng: 17.908211 },
    { lat: 48.272844, lng: 17.908112 },
    { lat: 48.272885, lng: 17.908082 },
    { lat: 48.272758, lng: 17.907241 },
    { lat: 48.272681, lng: 17.907095 },
    { lat: 48.2722677, lng: 17.9060632 },
    { lat: 48.2717346, lng: 17.9041415 },
    { lat: 48.2715065, lng: 17.903562 },
    { lat: 48.2700433, lng: 17.9008951 },
    { lat: 48.2691196, lng: 17.8994222 },
    { lat: 48.2687853, lng: 17.8989727 },
    { lat: 48.2672492, lng: 17.897359 },
    { lat: 48.2665393, lng: 17.8968013 },
    { lat: 48.2655815, lng: 17.8962171 },
    { lat: 48.2643279, lng: 17.8950536 },
    { lat: 48.2626274, lng: 17.8933007 },
    { lat: 48.2603143, lng: 17.8904722 },
    { lat: 48.2591514, lng: 17.8891705 },
    { lat: 48.2585257, lng: 17.8892958 },
    { lat: 48.2563852, lng: 17.8902029 },
    { lat: 48.2551218, lng: 17.8906067 },
    { lat: 48.2536931, lng: 17.8899259 },
    { lat: 48.252325, lng: 17.8894418 },
    { lat: 48.2513488, lng: 17.8892249 },
    { lat: 48.2503786, lng: 17.8898495 },
    { lat: 48.2495102, lng: 17.8897121 },
    { lat: 48.2485984, lng: 17.8892495 },
    { lat: 48.2479068, lng: 17.8885508 },
    { lat: 48.247771, lng: 17.888621 },
    { lat: 48.2472022, lng: 17.888548 },
    { lat: 48.2467366, lng: 17.8882633 },
    { lat: 48.2466361, lng: 17.8882993 },
    { lat: 48.2458456, lng: 17.8875869 },
    { lat: 48.2445824, lng: 17.886085 },
    { lat: 48.2425372, lng: 17.8824539 },
    { lat: 48.2409734, lng: 17.8788289 },
    { lat: 48.24032, lng: 17.8771996 },
    { lat: 48.239666, lng: 17.8759354 },
    { lat: 48.2389526, lng: 17.8743047 },
    { lat: 48.2363286, lng: 17.8686484 },
    { lat: 48.2357259, lng: 17.8685608 },
    { lat: 48.2345447, lng: 17.8688101 },
    { lat: 48.2331707, lng: 17.8687702 },
    { lat: 48.2311166, lng: 17.8689436 },
    { lat: 48.2296223, lng: 17.8689509 },
    { lat: 48.2281578, lng: 17.8688349 },
    { lat: 48.226445, lng: 17.868959 },
  ],
  KráľovánadVáhom: [
    { lat: 48.182735, lng: 17.837646 },
    { lat: 48.18297, lng: 17.837478 },
    { lat: 48.183268, lng: 17.837273 },
    { lat: 48.183611, lng: 17.836919 },
    { lat: 48.183981, lng: 17.83664 },
    { lat: 48.184106, lng: 17.836562 },
    { lat: 48.18437, lng: 17.836233 },
    { lat: 48.185028, lng: 17.835661 },
    { lat: 48.185463, lng: 17.835498 },
    { lat: 48.18611, lng: 17.83512 },
    { lat: 48.18666, lng: 17.83473 },
    { lat: 48.187342, lng: 17.834247 },
    { lat: 48.187946, lng: 17.833867 },
    { lat: 48.188156, lng: 17.833786 },
    { lat: 48.188598, lng: 17.833506 },
    { lat: 48.188936, lng: 17.833291 },
    { lat: 48.189047, lng: 17.833217 },
    { lat: 48.189307, lng: 17.833019 },
    { lat: 48.189538, lng: 17.832834 },
    { lat: 48.189608, lng: 17.832785 },
    { lat: 48.189673, lng: 17.832745 },
    { lat: 48.189714, lng: 17.832724 },
    { lat: 48.189819, lng: 17.83267 },
    { lat: 48.189882, lng: 17.832632 },
    { lat: 48.19022, lng: 17.832433 },
    { lat: 48.190474, lng: 17.832289 },
    { lat: 48.19049, lng: 17.83228 },
    { lat: 48.19052, lng: 17.832265 },
    { lat: 48.190538, lng: 17.832255 },
    { lat: 48.19059, lng: 17.832237 },
    { lat: 48.190752, lng: 17.832189 },
    { lat: 48.190952, lng: 17.832154 },
    { lat: 48.191142, lng: 17.83212 },
    { lat: 48.191496, lng: 17.832082 },
    { lat: 48.191627, lng: 17.832049 },
    { lat: 48.191793, lng: 17.832002 },
    { lat: 48.191904, lng: 17.831969 },
    { lat: 48.192163, lng: 17.831885 },
    { lat: 48.19241, lng: 17.83175 },
    { lat: 48.1926, lng: 17.83162 },
    { lat: 48.192679, lng: 17.831574 },
    { lat: 48.1928, lng: 17.831449 },
    { lat: 48.192869, lng: 17.831351 },
    { lat: 48.192937, lng: 17.83126 },
    { lat: 48.192992, lng: 17.831192 },
    { lat: 48.193051, lng: 17.83112 },
    { lat: 48.193231, lng: 17.830818 },
    { lat: 48.193241, lng: 17.830801 },
    { lat: 48.193307, lng: 17.830693 },
    { lat: 48.193327, lng: 17.830659 },
    { lat: 48.19339, lng: 17.830557 },
    { lat: 48.193462, lng: 17.830422 },
    { lat: 48.197058, lng: 17.823217 },
    { lat: 48.200243, lng: 17.820896 },
    { lat: 48.20105, lng: 17.820477 },
    { lat: 48.19861, lng: 17.817153 },
    { lat: 48.198331, lng: 17.816452 },
    { lat: 48.198199, lng: 17.816355 },
    { lat: 48.197738, lng: 17.816008 },
    { lat: 48.19713, lng: 17.815451 },
    { lat: 48.196833, lng: 17.815175 },
    { lat: 48.196785, lng: 17.815112 },
    { lat: 48.196584, lng: 17.814853 },
    { lat: 48.19656, lng: 17.814823 },
    { lat: 48.196449, lng: 17.814681 },
    { lat: 48.196373, lng: 17.814586 },
    { lat: 48.196182, lng: 17.814347 },
    { lat: 48.196147, lng: 17.814302 },
    { lat: 48.195917, lng: 17.813947 },
    { lat: 48.195676, lng: 17.813515 },
    { lat: 48.195402, lng: 17.813024 },
    { lat: 48.195005, lng: 17.812244 },
    { lat: 48.194866, lng: 17.811786 },
    { lat: 48.194804, lng: 17.811539 },
    { lat: 48.194799, lng: 17.811017 },
    { lat: 48.194805, lng: 17.810512 },
    { lat: 48.194853, lng: 17.810004 },
    { lat: 48.194903, lng: 17.809498 },
    { lat: 48.194955, lng: 17.808993 },
    { lat: 48.195002, lng: 17.808442 },
    { lat: 48.195148, lng: 17.807978 },
    { lat: 48.19531, lng: 17.807472 },
    { lat: 48.195416, lng: 17.807159 },
    { lat: 48.195163, lng: 17.806841 },
    { lat: 48.194897, lng: 17.806509 },
    { lat: 48.1946, lng: 17.806185 },
    { lat: 48.194497, lng: 17.806073 },
    { lat: 48.194211, lng: 17.805684 },
    { lat: 48.193924, lng: 17.805116 },
    { lat: 48.19357, lng: 17.804591 },
    { lat: 48.193224, lng: 17.804162 },
    { lat: 48.192728, lng: 17.803456 },
    { lat: 48.192298, lng: 17.80262 },
    { lat: 48.192112, lng: 17.802288 },
    { lat: 48.191934, lng: 17.801975 },
    { lat: 48.191703, lng: 17.801591 },
    { lat: 48.191409, lng: 17.801015 },
    { lat: 48.191361, lng: 17.800988 },
    { lat: 48.191348, lng: 17.800996 },
    { lat: 48.1913, lng: 17.801026 },
    { lat: 48.19099, lng: 17.80111 },
    { lat: 48.190431, lng: 17.801122 },
    { lat: 48.189327, lng: 17.800952 },
    { lat: 48.188036, lng: 17.800904 },
    { lat: 48.187473, lng: 17.800873 },
    { lat: 48.18704, lng: 17.800821 },
    { lat: 48.186995, lng: 17.800816 },
    { lat: 48.186946, lng: 17.80081 },
    { lat: 48.1869, lng: 17.800795 },
    { lat: 48.186757, lng: 17.800768 },
    { lat: 48.186624, lng: 17.800718 },
    { lat: 48.186512, lng: 17.800677 },
    { lat: 48.186316, lng: 17.800638 },
    { lat: 48.186076, lng: 17.800618 },
    { lat: 48.18578, lng: 17.800572 },
    { lat: 48.185737, lng: 17.800565 },
    { lat: 48.185394, lng: 17.801106 },
    { lat: 48.185137, lng: 17.801601 },
    { lat: 48.184595, lng: 17.802667 },
    { lat: 48.18456, lng: 17.802759 },
    { lat: 48.184445, lng: 17.803059 },
    { lat: 48.184164, lng: 17.803776 },
    { lat: 48.184025, lng: 17.804033 },
    { lat: 48.183569, lng: 17.804878 },
    { lat: 48.18315, lng: 17.805535 },
    { lat: 48.182171, lng: 17.806727 },
    { lat: 48.181781, lng: 17.807132 },
    { lat: 48.181013, lng: 17.808184 },
    { lat: 48.180001, lng: 17.809713 },
    { lat: 48.179591, lng: 17.810807 },
    { lat: 48.179387, lng: 17.811124 },
    { lat: 48.179373, lng: 17.811147 },
    { lat: 48.179218, lng: 17.811389 },
    { lat: 48.179038, lng: 17.811668 },
    { lat: 48.178967, lng: 17.811779 },
    { lat: 48.178618, lng: 17.812322 },
    { lat: 48.177856, lng: 17.8132 },
    { lat: 48.177755, lng: 17.813337 },
    { lat: 48.177741, lng: 17.813356 },
    { lat: 48.177722, lng: 17.813384 },
    { lat: 48.177693, lng: 17.813423 },
    { lat: 48.177678, lng: 17.813444 },
    { lat: 48.177571, lng: 17.81359 },
    { lat: 48.177548, lng: 17.813623 },
    { lat: 48.176468, lng: 17.815104 },
    { lat: 48.174944, lng: 17.817167 },
    { lat: 48.173681, lng: 17.818838 },
    { lat: 48.172207, lng: 17.820843 },
    { lat: 48.170905, lng: 17.82275 },
    { lat: 48.170228, lng: 17.823798 },
    { lat: 48.169069, lng: 17.825795 },
    { lat: 48.168379, lng: 17.826883 },
    { lat: 48.167891, lng: 17.827698 },
    { lat: 48.167623, lng: 17.828183 },
    { lat: 48.167391, lng: 17.828601 },
    { lat: 48.167187, lng: 17.828965 },
    { lat: 48.166924, lng: 17.82809 },
    { lat: 48.166442, lng: 17.826611 },
    { lat: 48.1662, lng: 17.825982 },
    { lat: 48.166036, lng: 17.825638 },
    { lat: 48.165955, lng: 17.825305 },
    { lat: 48.165884, lng: 17.824774 },
    { lat: 48.16589, lng: 17.824702 },
    { lat: 48.165891, lng: 17.824673 },
    { lat: 48.165904, lng: 17.824477 },
    { lat: 48.165907, lng: 17.824437 },
    { lat: 48.16591, lng: 17.824341 },
    { lat: 48.166018, lng: 17.824015 },
    { lat: 48.166241, lng: 17.823593 },
    { lat: 48.166372, lng: 17.823394 },
    { lat: 48.166602, lng: 17.823046 },
    { lat: 48.166642, lng: 17.822861 },
    { lat: 48.166643, lng: 17.822644 },
    { lat: 48.166552, lng: 17.822336 },
    { lat: 48.166284, lng: 17.821838 },
    { lat: 48.166142, lng: 17.8214 },
    { lat: 48.166121, lng: 17.821247 },
    { lat: 48.166113, lng: 17.821194 },
    { lat: 48.166069, lng: 17.821103 },
    { lat: 48.166025, lng: 17.821042 },
    { lat: 48.165993, lng: 17.821012 },
    { lat: 48.165835, lng: 17.82085 },
    { lat: 48.165542, lng: 17.820717 },
    { lat: 48.165111, lng: 17.820711 },
    { lat: 48.164861, lng: 17.820709 },
    { lat: 48.164556, lng: 17.820706 },
    { lat: 48.164453, lng: 17.820641 },
    { lat: 48.164, lng: 17.820387 },
    { lat: 48.163912, lng: 17.820325 },
    { lat: 48.163628, lng: 17.820006 },
    { lat: 48.163115, lng: 17.818978 },
    { lat: 48.162951, lng: 17.819184 },
    { lat: 48.162944, lng: 17.819194 },
    { lat: 48.162816, lng: 17.8193629 },
    { lat: 48.1608391, lng: 17.8231949 },
    { lat: 48.1599105, lng: 17.825241 },
    { lat: 48.1576035, lng: 17.8307081 },
    { lat: 48.1568796, lng: 17.831918 },
    { lat: 48.1535806, lng: 17.8359773 },
    { lat: 48.1515026, lng: 17.8384469 },
    { lat: 48.1510088, lng: 17.8393206 },
    { lat: 48.1512955, lng: 17.8399834 },
    { lat: 48.1513342, lng: 17.8399703 },
    { lat: 48.1516092, lng: 17.8398729 },
    { lat: 48.152063, lng: 17.8395954 },
    { lat: 48.1527985, lng: 17.8393649 },
    { lat: 48.1531726, lng: 17.8395742 },
    { lat: 48.1539252, lng: 17.8398628 },
    { lat: 48.1552027, lng: 17.8406469 },
    { lat: 48.1556015, lng: 17.8408196 },
    { lat: 48.157252, lng: 17.8411854 },
    { lat: 48.1577373, lng: 17.8414035 },
    { lat: 48.1589717, lng: 17.8415441 },
    { lat: 48.1599846, lng: 17.8417862 },
    { lat: 48.160853, lng: 17.8417931 },
    { lat: 48.1614831, lng: 17.8419624 },
    { lat: 48.1624237, lng: 17.8434361 },
    { lat: 48.1626964, lng: 17.8441462 },
    { lat: 48.1630062, lng: 17.8456462 },
    { lat: 48.1625507, lng: 17.8459727 },
    { lat: 48.1620185, lng: 17.8461078 },
    { lat: 48.1617128, lng: 17.8465555 },
    { lat: 48.1615002, lng: 17.8469732 },
    { lat: 48.1610544, lng: 17.8473606 },
    { lat: 48.1596233, lng: 17.848049 },
    { lat: 48.1586399, lng: 17.8488752 },
    { lat: 48.1578885, lng: 17.8493252 },
    { lat: 48.1573783, lng: 17.8502647 },
    { lat: 48.1570426, lng: 17.8510288 },
    { lat: 48.1570185, lng: 17.8510836 },
    { lat: 48.1570648, lng: 17.851153 },
    { lat: 48.160469, lng: 17.8562561 },
    { lat: 48.1608288, lng: 17.856086 },
    { lat: 48.1610781, lng: 17.8558624 },
    { lat: 48.161379, lng: 17.8554673 },
    { lat: 48.161787, lng: 17.8546428 },
    { lat: 48.1620044, lng: 17.8538978 },
    { lat: 48.1651478, lng: 17.8562137 },
    { lat: 48.1649773, lng: 17.857726 },
    { lat: 48.1663432, lng: 17.8585736 },
    { lat: 48.1663484, lng: 17.8585308 },
    { lat: 48.1665184, lng: 17.85723 },
    { lat: 48.1668134, lng: 17.8559619 },
    { lat: 48.1669553, lng: 17.8555323 },
    { lat: 48.1677162, lng: 17.8540385 },
    { lat: 48.1693374, lng: 17.8517363 },
    { lat: 48.1711068, lng: 17.8493826 },
    { lat: 48.1716783, lng: 17.8482268 },
    { lat: 48.1724814, lng: 17.8470375 },
    { lat: 48.1728023, lng: 17.8467269 },
    { lat: 48.1733129, lng: 17.8464204 },
    { lat: 48.1740351, lng: 17.8461563 },
    { lat: 48.1752198, lng: 17.846995 },
    { lat: 48.1761838, lng: 17.8481745 },
    { lat: 48.1769322, lng: 17.8492431 },
    { lat: 48.1772709, lng: 17.8495936 },
    { lat: 48.1779446, lng: 17.8500874 },
    { lat: 48.178421, lng: 17.8502143 },
    { lat: 48.1787886, lng: 17.8503005 },
    { lat: 48.179116, lng: 17.8502649 },
    { lat: 48.1797673, lng: 17.8500233 },
    { lat: 48.18044, lng: 17.8495612 },
    { lat: 48.1807655, lng: 17.8492531 },
    { lat: 48.1810262, lng: 17.8488478 },
    { lat: 48.1811392, lng: 17.8484058 },
    { lat: 48.1812029, lng: 17.8475676 },
    { lat: 48.1810014, lng: 17.8467462 },
    { lat: 48.1806259, lng: 17.8460145 },
    { lat: 48.1800903, lng: 17.8454346 },
    { lat: 48.1794268, lng: 17.8449797 },
    { lat: 48.1788386, lng: 17.8446662 },
    { lat: 48.1769147, lng: 17.8404972 },
    { lat: 48.1763066, lng: 17.8392719 },
    { lat: 48.1759629, lng: 17.8388016 },
    { lat: 48.1756583, lng: 17.8382552 },
    { lat: 48.175581, lng: 17.8379326 },
    { lat: 48.1755377, lng: 17.8373383 },
    { lat: 48.1755331, lng: 17.8361414 },
    { lat: 48.1756475, lng: 17.8351523 },
    { lat: 48.175418, lng: 17.8347778 },
    { lat: 48.1748077, lng: 17.8340307 },
    { lat: 48.1742981, lng: 17.8332903 },
    { lat: 48.17495, lng: 17.8322091 },
    { lat: 48.1754687, lng: 17.8317224 },
    { lat: 48.1761257, lng: 17.8313924 },
    { lat: 48.1766853, lng: 17.8312403 },
    { lat: 48.1778849, lng: 17.8313986 },
    { lat: 48.1784585, lng: 17.8320443 },
    { lat: 48.1790571, lng: 17.8326158 },
    { lat: 48.1793527, lng: 17.8335706 },
    { lat: 48.1799082, lng: 17.8347706 },
    { lat: 48.180281, lng: 17.8352528 },
    { lat: 48.1803643, lng: 17.8353553 },
    { lat: 48.1808007, lng: 17.8356994 },
    { lat: 48.1822093, lng: 17.836595 },
    { lat: 48.182735, lng: 17.837646 },
  ],
  Selice: [
    { lat: 48.059015, lng: 17.983952 },
    { lat: 48.060502, lng: 17.983048 },
    { lat: 48.061318, lng: 17.982738 },
    { lat: 48.062473, lng: 17.982377 },
    { lat: 48.063267, lng: 17.982282 },
    { lat: 48.064158, lng: 17.982184 },
    { lat: 48.065683, lng: 17.981768 },
    { lat: 48.067584, lng: 17.981523 },
    { lat: 48.067694, lng: 17.981428 },
    { lat: 48.06772, lng: 17.981409 },
    { lat: 48.06781, lng: 17.981335 },
    { lat: 48.067833, lng: 17.981315 },
    { lat: 48.070506, lng: 17.9791 },
    { lat: 48.072042, lng: 17.977749 },
    { lat: 48.073506, lng: 17.978563 },
    { lat: 48.074013, lng: 17.978976 },
    { lat: 48.075453, lng: 17.979741 },
    { lat: 48.07654, lng: 17.980319 },
    { lat: 48.076834, lng: 17.980503 },
    { lat: 48.076949, lng: 17.980589 },
    { lat: 48.077766, lng: 17.980971 },
    { lat: 48.078554, lng: 17.981304 },
    { lat: 48.078775, lng: 17.981386 },
    { lat: 48.07958, lng: 17.981709 },
    { lat: 48.080738, lng: 17.982105 },
    { lat: 48.081445, lng: 17.982374 },
    { lat: 48.082103, lng: 17.982642 },
    { lat: 48.082646, lng: 17.982845 },
    { lat: 48.082958, lng: 17.982953 },
    { lat: 48.083636, lng: 17.983199 },
    { lat: 48.083773, lng: 17.98327 },
    { lat: 48.085736, lng: 17.984135 },
    { lat: 48.086648, lng: 17.984351 },
    { lat: 48.087162, lng: 17.984423 },
    { lat: 48.087184, lng: 17.98443 },
    { lat: 48.087209, lng: 17.984446 },
    { lat: 48.087263, lng: 17.984477 },
    { lat: 48.08789, lng: 17.984828 },
    { lat: 48.088981, lng: 17.98535 },
    { lat: 48.08948, lng: 17.985569 },
    { lat: 48.089683, lng: 17.985675 },
    { lat: 48.090047, lng: 17.985903 },
    { lat: 48.090416, lng: 17.986186 },
    { lat: 48.093139, lng: 17.98873 },
    { lat: 48.094615, lng: 17.990071 },
    { lat: 48.094693, lng: 17.990141 },
    { lat: 48.096107, lng: 17.991417 },
    { lat: 48.096871, lng: 17.992061 },
    { lat: 48.097488, lng: 17.992609 },
    { lat: 48.0984854, lng: 17.9933902 },
    { lat: 48.098734, lng: 17.993587 },
    { lat: 48.100882, lng: 17.995316 },
    { lat: 48.101361, lng: 17.995695 },
    { lat: 48.101394, lng: 17.995721 },
    { lat: 48.103095, lng: 17.997044 },
    { lat: 48.103482, lng: 17.997314 },
    { lat: 48.103502, lng: 17.997329 },
    { lat: 48.103537, lng: 17.997355 },
    { lat: 48.10356, lng: 17.99737 },
    { lat: 48.103678, lng: 17.997452 },
    { lat: 48.104445, lng: 17.998126 },
    { lat: 48.105759, lng: 17.999184 },
    { lat: 48.106493, lng: 17.999717 },
    { lat: 48.106892, lng: 17.999936 },
    { lat: 48.107199, lng: 18.000107 },
    { lat: 48.107507, lng: 18.000298 },
    { lat: 48.108648, lng: 18.00099 },
    { lat: 48.10936, lng: 18.001432 },
    { lat: 48.111334, lng: 18.002815 },
    { lat: 48.112328, lng: 18.003509 },
    { lat: 48.113873, lng: 18.004233 },
    { lat: 48.114475, lng: 18.003443 },
    { lat: 48.114542, lng: 18.003355 },
    { lat: 48.114757, lng: 18.003073 },
    { lat: 48.115333, lng: 18.002174 },
    { lat: 48.115497, lng: 18.001935 },
    { lat: 48.115592, lng: 18.001795 },
    { lat: 48.116042, lng: 18.001154 },
    { lat: 48.116531, lng: 18.000336 },
    { lat: 48.116566, lng: 18.000278 },
    { lat: 48.116658, lng: 18.000192 },
    { lat: 48.116679, lng: 18.00017 },
    { lat: 48.117209, lng: 17.999396 },
    { lat: 48.118008, lng: 17.998156 },
    { lat: 48.118364, lng: 17.997645 },
    { lat: 48.118818, lng: 17.996717 },
    { lat: 48.11884, lng: 17.99667 },
    { lat: 48.11927, lng: 17.995806 },
    { lat: 48.119693, lng: 17.994768 },
    { lat: 48.120116, lng: 17.99379 },
    { lat: 48.120653, lng: 17.992489 },
    { lat: 48.121681, lng: 17.990359 },
    { lat: 48.122247, lng: 17.989225 },
    { lat: 48.12355, lng: 17.986501 },
    { lat: 48.124063, lng: 17.985439 },
    { lat: 48.124261, lng: 17.984889 },
    { lat: 48.124696, lng: 17.983881 },
    { lat: 48.125289, lng: 17.982142 },
    { lat: 48.12593, lng: 17.980599 },
    { lat: 48.126564, lng: 17.979109 },
    { lat: 48.126998, lng: 17.977377 },
    { lat: 48.126462, lng: 17.97611 },
    { lat: 48.126629, lng: 17.975438 },
    { lat: 48.126828, lng: 17.974773 },
    { lat: 48.12723, lng: 17.973444 },
    { lat: 48.126888, lng: 17.97308 },
    { lat: 48.126854, lng: 17.973047 },
    { lat: 48.125531, lng: 17.971754 },
    { lat: 48.125735, lng: 17.971092 },
    { lat: 48.125764, lng: 17.971008 },
    { lat: 48.126061, lng: 17.970065 },
    { lat: 48.126179, lng: 17.969625 },
    { lat: 48.126263, lng: 17.969169 },
    { lat: 48.12656, lng: 17.968027 },
    { lat: 48.126718, lng: 17.967102 },
    { lat: 48.127335, lng: 17.965161 },
    { lat: 48.127676, lng: 17.964212 },
    { lat: 48.128087, lng: 17.963475 },
    { lat: 48.128156, lng: 17.963399 },
    { lat: 48.12945, lng: 17.962417 },
    { lat: 48.130271, lng: 17.96187 },
    { lat: 48.131032, lng: 17.961326 },
    { lat: 48.131467, lng: 17.961021 },
    { lat: 48.1315165, lng: 17.9609366 },
    { lat: 48.1314577, lng: 17.9608601 },
    { lat: 48.1305467, lng: 17.9596771 },
    { lat: 48.1299381, lng: 17.9589835 },
    { lat: 48.1295851, lng: 17.9586865 },
    { lat: 48.129252, lng: 17.9585363 },
    { lat: 48.1290624, lng: 17.9585183 },
    { lat: 48.1288726, lng: 17.9583641 },
    { lat: 48.1273232, lng: 17.956676 },
    { lat: 48.1270121, lng: 17.9563371 },
    { lat: 48.1296488, lng: 17.9525416 },
    { lat: 48.1290376, lng: 17.9515972 },
    { lat: 48.1276257, lng: 17.9489641 },
    { lat: 48.1266052, lng: 17.9484968 },
    { lat: 48.1245991, lng: 17.9477433 },
    { lat: 48.1225928, lng: 17.9448219 },
    { lat: 48.1210465, lng: 17.9428437 },
    { lat: 48.1206157, lng: 17.9423808 },
    { lat: 48.1170992, lng: 17.9390927 },
    { lat: 48.1151502, lng: 17.9371936 },
    { lat: 48.1132677, lng: 17.9356894 },
    { lat: 48.1107034, lng: 17.9347792 },
    { lat: 48.1097733, lng: 17.9345205 },
    { lat: 48.1094817, lng: 17.9338405 },
    { lat: 48.1091698, lng: 17.9333708 },
    { lat: 48.1076166, lng: 17.9313757 },
    { lat: 48.106311, lng: 17.9300231 },
    { lat: 48.1027559, lng: 17.9267173 },
    { lat: 48.1018304, lng: 17.9256919 },
    { lat: 48.1013026, lng: 17.9249383 },
    { lat: 48.0996081, lng: 17.9231298 },
    { lat: 48.0993237, lng: 17.9227576 },
    { lat: 48.0972552, lng: 17.9187124 },
    { lat: 48.0968078, lng: 17.9177016 },
    { lat: 48.0961046, lng: 17.9138562 },
    { lat: 48.0960317, lng: 17.9102599 },
    { lat: 48.0957645, lng: 17.9113045 },
    { lat: 48.0947614, lng: 17.9132047 },
    { lat: 48.0939829, lng: 17.9144569 },
    { lat: 48.093453, lng: 17.9150604 },
    { lat: 48.0921299, lng: 17.911141 },
    { lat: 48.0920165, lng: 17.9109448 },
    { lat: 48.0923462, lng: 17.909979 },
    { lat: 48.0923699, lng: 17.9097039 },
    { lat: 48.0918167, lng: 17.9093665 },
    { lat: 48.0909489, lng: 17.909018 },
    { lat: 48.0902913, lng: 17.9088578 },
    { lat: 48.0899975, lng: 17.9088725 },
    { lat: 48.0899779, lng: 17.9088653 },
    { lat: 48.089888, lng: 17.9089816 },
    { lat: 48.0883715, lng: 17.9087902 },
    { lat: 48.087674, lng: 17.9091455 },
    { lat: 48.086696, lng: 17.9101948 },
    { lat: 48.0853876, lng: 17.911084 },
    { lat: 48.0850616, lng: 17.9115532 },
    { lat: 48.0818432, lng: 17.9138735 },
    { lat: 48.0782319, lng: 17.9178245 },
    { lat: 48.0768682, lng: 17.9195535 },
    { lat: 48.0758739, lng: 17.9205745 },
    { lat: 48.0740212, lng: 17.922206 },
    { lat: 48.0717812, lng: 17.9243369 },
    { lat: 48.0713408, lng: 17.9247436 },
    { lat: 48.070745, lng: 17.9233711 },
    { lat: 48.0704749, lng: 17.9228804 },
    { lat: 48.0699699, lng: 17.9224295 },
    { lat: 48.0695223, lng: 17.9212286 },
    { lat: 48.0699345, lng: 17.9207318 },
    { lat: 48.0701635, lng: 17.9202653 },
    { lat: 48.0702064, lng: 17.919745 },
    { lat: 48.0701379, lng: 17.9196014 },
    { lat: 48.0696865, lng: 17.9193589 },
    { lat: 48.0691594, lng: 17.9194552 },
    { lat: 48.0681818, lng: 17.9198595 },
    { lat: 48.0666929, lng: 17.9177786 },
    { lat: 48.0613607, lng: 17.9146611 },
    { lat: 48.0591804, lng: 17.9130566 },
    { lat: 48.0575589, lng: 17.9127135 },
    { lat: 48.0562265, lng: 17.9156941 },
    { lat: 48.0557248, lng: 17.9170797 },
    { lat: 48.0551816, lng: 17.9190827 },
    { lat: 48.0553236, lng: 17.919283 },
    { lat: 48.055478, lng: 17.9196513 },
    { lat: 48.0555396, lng: 17.9205923 },
    { lat: 48.0554962, lng: 17.9222533 },
    { lat: 48.0552474, lng: 17.9266928 },
    { lat: 48.0552611, lng: 17.9284233 },
    { lat: 48.0551683, lng: 17.9313631 },
    { lat: 48.0564192, lng: 17.9348096 },
    { lat: 48.0567198, lng: 17.9358939 },
    { lat: 48.0564546, lng: 17.9368304 },
    { lat: 48.0561016, lng: 17.9376175 },
    { lat: 48.0559168, lng: 17.93801 },
    { lat: 48.0552842, lng: 17.9390133 },
    { lat: 48.0549779, lng: 17.9404023 },
    { lat: 48.0545056, lng: 17.9418291 },
    { lat: 48.0541484, lng: 17.9423256 },
    { lat: 48.0546914, lng: 17.9462991 },
    { lat: 48.0560032, lng: 17.9468419 },
    { lat: 48.0586978, lng: 17.9480983 },
    { lat: 48.0606377, lng: 17.9506268 },
    { lat: 48.0568563, lng: 17.9496368 },
    { lat: 48.05655, lng: 17.9509745 },
    { lat: 48.0562203, lng: 17.9519263 },
    { lat: 48.0560329, lng: 17.9521283 },
    { lat: 48.0558751, lng: 17.9526202 },
    { lat: 48.0557879, lng: 17.9527631 },
    { lat: 48.0552333, lng: 17.9531676 },
    { lat: 48.0548861, lng: 17.9538677 },
    { lat: 48.0542857, lng: 17.9561484 },
    { lat: 48.0521967, lng: 17.9580594 },
    { lat: 48.052044, lng: 17.9584443 },
    { lat: 48.0518437, lng: 17.9586804 },
    { lat: 48.0558529, lng: 17.965468 },
    { lat: 48.0562702, lng: 17.9716182 },
    { lat: 48.0563074, lng: 17.9716005 },
    { lat: 48.0564322, lng: 17.9724693 },
    { lat: 48.0568323, lng: 17.9731159 },
    { lat: 48.05701, lng: 17.9739498 },
    { lat: 48.0572519, lng: 17.9745721 },
    { lat: 48.0577305, lng: 17.975528 },
    { lat: 48.0580551, lng: 17.9759339 },
    { lat: 48.0585027, lng: 17.9760186 },
    { lat: 48.0585993, lng: 17.9758977 },
    { lat: 48.0588934, lng: 17.9758134 },
    { lat: 48.0594088, lng: 17.9759204 },
    { lat: 48.059524, lng: 17.9760539 },
    { lat: 48.059595, lng: 17.9762381 },
    { lat: 48.0595177, lng: 17.9766238 },
    { lat: 48.0594302, lng: 17.9767619 },
    { lat: 48.0595591, lng: 17.9772727 },
    { lat: 48.0597577, lng: 17.9775333 },
    { lat: 48.0603092, lng: 17.9780321 },
    { lat: 48.0607307, lng: 17.9780589 },
    { lat: 48.0609559, lng: 17.9782178 },
    { lat: 48.061154, lng: 17.9785645 },
    { lat: 48.061223, lng: 17.9789455 },
    { lat: 48.061055, lng: 17.9795851 },
    { lat: 48.0611312, lng: 17.9800446 },
    { lat: 48.0609703, lng: 17.9809715 },
    { lat: 48.0606292, lng: 17.9816618 },
    { lat: 48.0589472, lng: 17.983233 },
    { lat: 48.059015, lng: 17.983952 },
  ],
  Hájske: [
    { lat: 48.275582, lng: 17.906496 },
    { lat: 48.275578, lng: 17.90645 },
    { lat: 48.275567, lng: 17.906331 },
    { lat: 48.275557, lng: 17.906207 },
    { lat: 48.27553, lng: 17.905898 },
    { lat: 48.275502, lng: 17.905615 },
    { lat: 48.275476, lng: 17.905362 },
    { lat: 48.275415, lng: 17.904756 },
    { lat: 48.275334, lng: 17.903965 },
    { lat: 48.275318, lng: 17.903805 },
    { lat: 48.27531, lng: 17.903721 },
    { lat: 48.275269, lng: 17.903449 },
    { lat: 48.275241, lng: 17.903268 },
    { lat: 48.275212, lng: 17.90308 },
    { lat: 48.27519, lng: 17.902945 },
    { lat: 48.275137, lng: 17.902725 },
    { lat: 48.274967, lng: 17.902083 },
    { lat: 48.274805, lng: 17.901398 },
    { lat: 48.274471, lng: 17.900159 },
    { lat: 48.274456, lng: 17.900103 },
    { lat: 48.274336, lng: 17.89961 },
    { lat: 48.274225, lng: 17.89914 },
    { lat: 48.274226, lng: 17.898453 },
    { lat: 48.274677, lng: 17.89837 },
    { lat: 48.274966, lng: 17.898316 },
    { lat: 48.27663, lng: 17.898012 },
    { lat: 48.277012, lng: 17.897935 },
    { lat: 48.277073, lng: 17.898058 },
    { lat: 48.277117, lng: 17.898046 },
    { lat: 48.277162, lng: 17.898037 },
    { lat: 48.27907, lng: 17.897564 },
    { lat: 48.279508, lng: 17.89746 },
    { lat: 48.279903, lng: 17.897369 },
    { lat: 48.280642, lng: 17.897206 },
    { lat: 48.281059, lng: 17.897106 },
    { lat: 48.281156, lng: 17.897086 },
    { lat: 48.281258, lng: 17.897059 },
    { lat: 48.281484, lng: 17.897006 },
    { lat: 48.281712, lng: 17.896956 },
    { lat: 48.282081, lng: 17.896869 },
    { lat: 48.282197, lng: 17.896839 },
    { lat: 48.282726, lng: 17.896673 },
    { lat: 48.283163, lng: 17.89654 },
    { lat: 48.283105, lng: 17.896424 },
    { lat: 48.282936, lng: 17.896091 },
    { lat: 48.282026, lng: 17.894297 },
    { lat: 48.281573, lng: 17.893387 },
    { lat: 48.281478, lng: 17.893196 },
    { lat: 48.279851, lng: 17.889989 },
    { lat: 48.279827, lng: 17.889942 },
    { lat: 48.278577, lng: 17.887485 },
    { lat: 48.277852, lng: 17.886094 },
    { lat: 48.277644, lng: 17.885695 },
    { lat: 48.279082, lng: 17.884744 },
    { lat: 48.280074, lng: 17.884087 },
    { lat: 48.280085, lng: 17.884079 },
    { lat: 48.280269, lng: 17.883958 },
    { lat: 48.280298, lng: 17.883937 },
    { lat: 48.280689, lng: 17.883722 },
    { lat: 48.280923, lng: 17.883593 },
    { lat: 48.282498, lng: 17.88273 },
    { lat: 48.282796, lng: 17.882433 },
    { lat: 48.282919, lng: 17.882311 },
    { lat: 48.282941, lng: 17.882289 },
    { lat: 48.282983, lng: 17.882248 },
    { lat: 48.283536, lng: 17.881689 },
    { lat: 48.283655, lng: 17.881573 },
    { lat: 48.283983, lng: 17.881256 },
    { lat: 48.284572, lng: 17.881018 },
    { lat: 48.284563, lng: 17.880946 },
    { lat: 48.284325, lng: 17.878962 },
    { lat: 48.284014, lng: 17.876353 },
    { lat: 48.282136, lng: 17.876261 },
    { lat: 48.28212, lng: 17.876115 },
    { lat: 48.28197, lng: 17.874953 },
    { lat: 48.28183, lng: 17.873862 },
    { lat: 48.281611, lng: 17.87217 },
    { lat: 48.281549, lng: 17.871588 },
    { lat: 48.281496, lng: 17.871544 },
    { lat: 48.28147, lng: 17.871435 },
    { lat: 48.281368, lng: 17.870663 },
    { lat: 48.281357, lng: 17.870585 },
    { lat: 48.281329, lng: 17.870386 },
    { lat: 48.281193, lng: 17.869364 },
    { lat: 48.281034, lng: 17.868165 },
    { lat: 48.28098, lng: 17.867765 },
    { lat: 48.280865, lng: 17.866921 },
    { lat: 48.280689, lng: 17.865608 },
    { lat: 48.280196, lng: 17.865916 },
    { lat: 48.279643, lng: 17.866293 },
    { lat: 48.27964, lng: 17.866295 },
    { lat: 48.279226, lng: 17.866551 },
    { lat: 48.278718, lng: 17.866863 },
    { lat: 48.277518, lng: 17.866637 },
    { lat: 48.277373, lng: 17.866616 },
    { lat: 48.276748, lng: 17.865635 },
    { lat: 48.276682, lng: 17.86553 },
    { lat: 48.275786, lng: 17.864468 },
    { lat: 48.275142, lng: 17.864309 },
    { lat: 48.274547, lng: 17.864205 },
    { lat: 48.273944, lng: 17.864338 },
    { lat: 48.27348, lng: 17.864071 },
    { lat: 48.273462, lng: 17.86406 },
    { lat: 48.273438, lng: 17.864047 },
    { lat: 48.272935, lng: 17.86376 },
    { lat: 48.272215, lng: 17.863404 },
    { lat: 48.271618, lng: 17.862915 },
    { lat: 48.271365, lng: 17.862707 },
    { lat: 48.271159, lng: 17.862539 },
    { lat: 48.270805, lng: 17.862278 },
    { lat: 48.270754, lng: 17.86223 },
    { lat: 48.270714, lng: 17.862209 },
    { lat: 48.27039, lng: 17.862034 },
    { lat: 48.269991, lng: 17.861748 },
    { lat: 48.269226, lng: 17.861184 },
    { lat: 48.268453, lng: 17.860579 },
    { lat: 48.26806, lng: 17.860388 },
    { lat: 48.267472, lng: 17.860088 },
    { lat: 48.266679, lng: 17.859223 },
    { lat: 48.266667, lng: 17.85921 },
    { lat: 48.266458, lng: 17.858964 },
    { lat: 48.266058, lng: 17.858495 },
    { lat: 48.265407, lng: 17.857587 },
    { lat: 48.265122, lng: 17.857117 },
    { lat: 48.264841, lng: 17.856466 },
    { lat: 48.264721, lng: 17.856188 },
    { lat: 48.264501, lng: 17.855673 },
    { lat: 48.26448, lng: 17.855623 },
    { lat: 48.264244, lng: 17.855078 },
    { lat: 48.264236, lng: 17.85506 },
    { lat: 48.263673, lng: 17.853771 },
    { lat: 48.263494, lng: 17.85339 },
    { lat: 48.262476, lng: 17.851855 },
    { lat: 48.26208, lng: 17.851395 },
    { lat: 48.261667, lng: 17.850991 },
    { lat: 48.261586, lng: 17.850912 },
    { lat: 48.261325, lng: 17.850654 },
    { lat: 48.261123, lng: 17.851671 },
    { lat: 48.260352, lng: 17.854107 },
    { lat: 48.259573, lng: 17.855669 },
    { lat: 48.258675, lng: 17.857142 },
    { lat: 48.257529, lng: 17.857841 },
    { lat: 48.257036, lng: 17.858083 },
    { lat: 48.256077, lng: 17.858356 },
    { lat: 48.255027, lng: 17.858561 },
    { lat: 48.254124, lng: 17.859105 },
    { lat: 48.252594, lng: 17.859083 },
    { lat: 48.252217, lng: 17.858435 },
    { lat: 48.251744, lng: 17.857454 },
    { lat: 48.251248, lng: 17.85633 },
    { lat: 48.251218, lng: 17.856378 },
    { lat: 48.250614, lng: 17.855379 },
    { lat: 48.250492, lng: 17.855174 },
    { lat: 48.250454, lng: 17.855107 },
    { lat: 48.250377, lng: 17.854962 },
    { lat: 48.250181, lng: 17.854581 },
    { lat: 48.250137, lng: 17.854492 },
    { lat: 48.250104, lng: 17.854424 },
    { lat: 48.249778, lng: 17.853779 },
    { lat: 48.249495, lng: 17.853181 },
    { lat: 48.249266, lng: 17.852695 },
    { lat: 48.249227, lng: 17.852614 },
    { lat: 48.248912, lng: 17.851929 },
    { lat: 48.248864, lng: 17.851823 },
    { lat: 48.248764, lng: 17.851576 },
    { lat: 48.248633, lng: 17.851293 },
    { lat: 48.248426, lng: 17.850878 },
    { lat: 48.248321, lng: 17.850672 },
    { lat: 48.248162, lng: 17.850362 },
    { lat: 48.247562, lng: 17.849034 },
    { lat: 48.247156, lng: 17.848242 },
    { lat: 48.246724, lng: 17.847473 },
    { lat: 48.246614, lng: 17.847251 },
    { lat: 48.246405, lng: 17.84684 },
    { lat: 48.246428, lng: 17.846815 },
    { lat: 48.246267, lng: 17.846503 },
    { lat: 48.246234, lng: 17.846373 },
    { lat: 48.246207, lng: 17.84627 },
    { lat: 48.24562, lng: 17.846644 },
    { lat: 48.245156, lng: 17.84693 },
    { lat: 48.244336, lng: 17.847456 },
    { lat: 48.242865, lng: 17.848374 },
    { lat: 48.242266, lng: 17.848758 },
    { lat: 48.242136, lng: 17.848818 },
    { lat: 48.241992, lng: 17.848874 },
    { lat: 48.240468, lng: 17.849447 },
    { lat: 48.240388, lng: 17.849492 },
    { lat: 48.240001, lng: 17.849636 },
    { lat: 48.239913, lng: 17.849675 },
    { lat: 48.239038, lng: 17.850022 },
    { lat: 48.238229, lng: 17.850339 },
    { lat: 48.237965, lng: 17.850454 },
    { lat: 48.23795, lng: 17.850461 },
    { lat: 48.237868, lng: 17.850496 },
    { lat: 48.237771, lng: 17.850537 },
    { lat: 48.23777, lng: 17.850538 },
    { lat: 48.237679, lng: 17.85057 },
    { lat: 48.236738, lng: 17.850902 },
    { lat: 48.236495, lng: 17.851003 },
    { lat: 48.23582, lng: 17.85127 },
    { lat: 48.23484, lng: 17.851708 },
    { lat: 48.234569, lng: 17.851839 },
    { lat: 48.234558, lng: 17.851843 },
    { lat: 48.234142, lng: 17.852021 },
    { lat: 48.233281, lng: 17.852382 },
    { lat: 48.233192, lng: 17.852393 },
    { lat: 48.233062, lng: 17.852309 },
    { lat: 48.232955, lng: 17.852189 },
    { lat: 48.232854, lng: 17.852254 },
    { lat: 48.232759, lng: 17.852314 },
    { lat: 48.232562, lng: 17.852487 },
    { lat: 48.232491, lng: 17.852546 },
    { lat: 48.23242, lng: 17.852645 },
    { lat: 48.232404, lng: 17.852623 },
    { lat: 48.232374, lng: 17.852684 },
    { lat: 48.232348, lng: 17.852659 },
    { lat: 48.232291, lng: 17.852717 },
    { lat: 48.23208, lng: 17.852923 },
    { lat: 48.231757, lng: 17.853166 },
    { lat: 48.231166, lng: 17.853542 },
    { lat: 48.231166, lng: 17.853627 },
    { lat: 48.231087, lng: 17.85387 },
    { lat: 48.230888, lng: 17.855238 },
    { lat: 48.23068, lng: 17.856392 },
    { lat: 48.230174, lng: 17.856983 },
    { lat: 48.229533, lng: 17.858339 },
    { lat: 48.228611, lng: 17.85991 },
    { lat: 48.228159, lng: 17.860951 },
    { lat: 48.227383, lng: 17.862672 },
    { lat: 48.226647, lng: 17.864042 },
    { lat: 48.226601, lng: 17.864119 },
    { lat: 48.225234, lng: 17.866034 },
    { lat: 48.225052, lng: 17.866415 },
    { lat: 48.225259, lng: 17.867094 },
    { lat: 48.225654, lng: 17.868409 },
    { lat: 48.226445, lng: 17.868959 },
    { lat: 48.2281578, lng: 17.8688349 },
    { lat: 48.2296223, lng: 17.8689509 },
    { lat: 48.2311166, lng: 17.8689436 },
    { lat: 48.2331707, lng: 17.8687702 },
    { lat: 48.2345447, lng: 17.8688101 },
    { lat: 48.2357259, lng: 17.8685608 },
    { lat: 48.2363286, lng: 17.8686484 },
    { lat: 48.2389526, lng: 17.8743047 },
    { lat: 48.239666, lng: 17.8759354 },
    { lat: 48.24032, lng: 17.8771996 },
    { lat: 48.2409734, lng: 17.8788289 },
    { lat: 48.2425372, lng: 17.8824539 },
    { lat: 48.2445824, lng: 17.886085 },
    { lat: 48.2458456, lng: 17.8875869 },
    { lat: 48.2466361, lng: 17.8882993 },
    { lat: 48.2467366, lng: 17.8882633 },
    { lat: 48.2472022, lng: 17.888548 },
    { lat: 48.247771, lng: 17.888621 },
    { lat: 48.2479068, lng: 17.8885508 },
    { lat: 48.2485984, lng: 17.8892495 },
    { lat: 48.2495102, lng: 17.8897121 },
    { lat: 48.2503786, lng: 17.8898495 },
    { lat: 48.2513488, lng: 17.8892249 },
    { lat: 48.252325, lng: 17.8894418 },
    { lat: 48.2536931, lng: 17.8899259 },
    { lat: 48.2551218, lng: 17.8906067 },
    { lat: 48.2563852, lng: 17.8902029 },
    { lat: 48.2585257, lng: 17.8892958 },
    { lat: 48.2591514, lng: 17.8891705 },
    { lat: 48.2603143, lng: 17.8904722 },
    { lat: 48.2626274, lng: 17.8933007 },
    { lat: 48.2643279, lng: 17.8950536 },
    { lat: 48.2655815, lng: 17.8962171 },
    { lat: 48.2665393, lng: 17.8968013 },
    { lat: 48.2672492, lng: 17.897359 },
    { lat: 48.2687853, lng: 17.8989727 },
    { lat: 48.2691196, lng: 17.8994222 },
    { lat: 48.2700433, lng: 17.9008951 },
    { lat: 48.2715065, lng: 17.903562 },
    { lat: 48.2717346, lng: 17.9041415 },
    { lat: 48.2722677, lng: 17.9060632 },
    { lat: 48.272681, lng: 17.907095 },
    { lat: 48.272724, lng: 17.907057 },
    { lat: 48.272734, lng: 17.907048 },
    { lat: 48.273329, lng: 17.906478 },
    { lat: 48.273475, lng: 17.906443 },
    { lat: 48.273537, lng: 17.906431 },
    { lat: 48.273747, lng: 17.906413 },
    { lat: 48.274335, lng: 17.906424 },
    { lat: 48.274662, lng: 17.906468 },
    { lat: 48.274843, lng: 17.906479 },
    { lat: 48.275041, lng: 17.906452 },
    { lat: 48.275264, lng: 17.906407 },
    { lat: 48.275452, lng: 17.906419 },
    { lat: 48.275582, lng: 17.906496 },
  ],
  DlhánadVáhom: [
    { lat: 48.182735, lng: 17.837646 },
    { lat: 48.1822093, lng: 17.836595 },
    { lat: 48.1808007, lng: 17.8356994 },
    { lat: 48.1803643, lng: 17.8353553 },
    { lat: 48.180281, lng: 17.8352528 },
    { lat: 48.1799082, lng: 17.8347706 },
    { lat: 48.1793527, lng: 17.8335706 },
    { lat: 48.1790571, lng: 17.8326158 },
    { lat: 48.1784585, lng: 17.8320443 },
    { lat: 48.1778849, lng: 17.8313986 },
    { lat: 48.1766853, lng: 17.8312403 },
    { lat: 48.1761257, lng: 17.8313924 },
    { lat: 48.1754687, lng: 17.8317224 },
    { lat: 48.17495, lng: 17.8322091 },
    { lat: 48.1742981, lng: 17.8332903 },
    { lat: 48.1748077, lng: 17.8340307 },
    { lat: 48.175418, lng: 17.8347778 },
    { lat: 48.1756475, lng: 17.8351523 },
    { lat: 48.1755331, lng: 17.8361414 },
    { lat: 48.1755377, lng: 17.8373383 },
    { lat: 48.175581, lng: 17.8379326 },
    { lat: 48.1756583, lng: 17.8382552 },
    { lat: 48.1759629, lng: 17.8388016 },
    { lat: 48.1763066, lng: 17.8392719 },
    { lat: 48.1769147, lng: 17.8404972 },
    { lat: 48.1788386, lng: 17.8446662 },
    { lat: 48.1794268, lng: 17.8449797 },
    { lat: 48.1800903, lng: 17.8454346 },
    { lat: 48.1806259, lng: 17.8460145 },
    { lat: 48.1810014, lng: 17.8467462 },
    { lat: 48.1812029, lng: 17.8475676 },
    { lat: 48.1811392, lng: 17.8484058 },
    { lat: 48.1810262, lng: 17.8488478 },
    { lat: 48.1807655, lng: 17.8492531 },
    { lat: 48.18044, lng: 17.8495612 },
    { lat: 48.1797673, lng: 17.8500233 },
    { lat: 48.179116, lng: 17.8502649 },
    { lat: 48.1787886, lng: 17.8503005 },
    { lat: 48.178421, lng: 17.8502143 },
    { lat: 48.1779446, lng: 17.8500874 },
    { lat: 48.1772709, lng: 17.8495936 },
    { lat: 48.1769322, lng: 17.8492431 },
    { lat: 48.1761838, lng: 17.8481745 },
    { lat: 48.1752198, lng: 17.846995 },
    { lat: 48.1740351, lng: 17.8461563 },
    { lat: 48.1733129, lng: 17.8464204 },
    { lat: 48.1728023, lng: 17.8467269 },
    { lat: 48.1724814, lng: 17.8470375 },
    { lat: 48.1716783, lng: 17.8482268 },
    { lat: 48.1711068, lng: 17.8493826 },
    { lat: 48.1693374, lng: 17.8517363 },
    { lat: 48.1677162, lng: 17.8540385 },
    { lat: 48.1669553, lng: 17.8555323 },
    { lat: 48.1668134, lng: 17.8559619 },
    { lat: 48.1665184, lng: 17.85723 },
    { lat: 48.1663484, lng: 17.8585308 },
    { lat: 48.1663432, lng: 17.8585736 },
    { lat: 48.1663228, lng: 17.8589594 },
    { lat: 48.1659466, lng: 17.8617643 },
    { lat: 48.1658397, lng: 17.8630805 },
    { lat: 48.1658247, lng: 17.8640147 },
    { lat: 48.1657054, lng: 17.865741 },
    { lat: 48.1655706, lng: 17.8661788 },
    { lat: 48.1653631, lng: 17.8673821 },
    { lat: 48.1649279, lng: 17.8687583 },
    { lat: 48.1647283, lng: 17.8695917 },
    { lat: 48.1641124, lng: 17.8711898 },
    { lat: 48.1639491, lng: 17.8718811 },
    { lat: 48.1636444, lng: 17.8727681 },
    { lat: 48.1636449, lng: 17.8730944 },
    { lat: 48.1626784, lng: 17.875162 },
    { lat: 48.1629108, lng: 17.8776019 },
    { lat: 48.1631662, lng: 17.877497 },
    { lat: 48.163242, lng: 17.8773509 },
    { lat: 48.1632369, lng: 17.8770069 },
    { lat: 48.1633035, lng: 17.8768974 },
    { lat: 48.1636922, lng: 17.8768278 },
    { lat: 48.1641426, lng: 17.8764404 },
    { lat: 48.1648106, lng: 17.8752695 },
    { lat: 48.1653218, lng: 17.874598 },
    { lat: 48.1657619, lng: 17.8742639 },
    { lat: 48.1664291, lng: 17.8739509 },
    { lat: 48.1666006, lng: 17.8739515 },
    { lat: 48.1678625, lng: 17.8747063 },
    { lat: 48.170146, lng: 17.8747963 },
    { lat: 48.1714271, lng: 17.8754781 },
    { lat: 48.1718126, lng: 17.8767453 },
    { lat: 48.176173, lng: 17.8814446 },
    { lat: 48.1784887, lng: 17.8837849 },
    { lat: 48.1832064, lng: 17.8868828 },
    { lat: 48.1831854, lng: 17.8868383 },
    { lat: 48.184301, lng: 17.8841342 },
    { lat: 48.1848154, lng: 17.8832011 },
    { lat: 48.1850481, lng: 17.8828701 },
    { lat: 48.1860635, lng: 17.8827328 },
    { lat: 48.1874959, lng: 17.8828628 },
    { lat: 48.1885511, lng: 17.8830989 },
    { lat: 48.1888661, lng: 17.8830971 },
    { lat: 48.1900287, lng: 17.8828906 },
    { lat: 48.1902774, lng: 17.8827884 },
    { lat: 48.1907941, lng: 17.8824318 },
    { lat: 48.1913775, lng: 17.881943 },
    { lat: 48.1936475, lng: 17.8794065 },
    { lat: 48.1951943, lng: 17.877986 },
    { lat: 48.1958167, lng: 17.8777302 },
    { lat: 48.1970307, lng: 17.8782772 },
    { lat: 48.1989841, lng: 17.8782454 },
    { lat: 48.2004285, lng: 17.8772923 },
    { lat: 48.200891, lng: 17.876866 },
    { lat: 48.200876, lng: 17.876822 },
    { lat: 48.20085, lng: 17.876749 },
    { lat: 48.200817, lng: 17.876655 },
    { lat: 48.200708, lng: 17.876345 },
    { lat: 48.200678, lng: 17.876259 },
    { lat: 48.200401, lng: 17.87547 },
    { lat: 48.200346, lng: 17.875313 },
    { lat: 48.200315, lng: 17.875227 },
    { lat: 48.200302, lng: 17.875191 },
    { lat: 48.20019, lng: 17.874867 },
    { lat: 48.199683, lng: 17.873819 },
    { lat: 48.199099, lng: 17.872733 },
    { lat: 48.199054, lng: 17.872627 },
    { lat: 48.198781, lng: 17.871974 },
    { lat: 48.198723, lng: 17.871837 },
    { lat: 48.198343, lng: 17.870789 },
    { lat: 48.198029, lng: 17.86993 },
    { lat: 48.197746, lng: 17.869162 },
    { lat: 48.197615, lng: 17.868791 },
    { lat: 48.197522, lng: 17.868527 },
    { lat: 48.197426, lng: 17.867255 },
    { lat: 48.197423, lng: 17.867204 },
    { lat: 48.197414, lng: 17.866857 },
    { lat: 48.197379, lng: 17.865746 },
    { lat: 48.196878, lng: 17.864117 },
    { lat: 48.196663, lng: 17.863257 },
    { lat: 48.196606, lng: 17.863045 },
    { lat: 48.196586, lng: 17.862949 },
    { lat: 48.19646, lng: 17.862358 },
    { lat: 48.196323, lng: 17.861719 },
    { lat: 48.195928, lng: 17.860596 },
    { lat: 48.195687, lng: 17.85987 },
    { lat: 48.195395, lng: 17.859252 },
    { lat: 48.195065, lng: 17.858486 },
    { lat: 48.195046, lng: 17.858443 },
    { lat: 48.194733, lng: 17.85772 },
    { lat: 48.194683, lng: 17.857607 },
    { lat: 48.19456, lng: 17.857323 },
    { lat: 48.194541, lng: 17.857287 },
    { lat: 48.194505, lng: 17.857223 },
    { lat: 48.194492, lng: 17.857198 },
    { lat: 48.194105, lng: 17.856439 },
    { lat: 48.192966, lng: 17.853811 },
    { lat: 48.19244, lng: 17.85248 },
    { lat: 48.192421, lng: 17.85243 },
    { lat: 48.192256, lng: 17.852015 },
    { lat: 48.192234, lng: 17.851959 },
    { lat: 48.191652, lng: 17.850482 },
    { lat: 48.191641, lng: 17.850436 },
    { lat: 48.191268, lng: 17.84908 },
    { lat: 48.190606, lng: 17.847887 },
    { lat: 48.190218, lng: 17.846597 },
    { lat: 48.190066, lng: 17.846003 },
    { lat: 48.190001, lng: 17.845679 },
    { lat: 48.189915, lng: 17.845477 },
    { lat: 48.18972, lng: 17.844906 },
    { lat: 48.189564, lng: 17.844451 },
    { lat: 48.189277, lng: 17.843821 },
    { lat: 48.189166, lng: 17.843653 },
    { lat: 48.189112, lng: 17.843584 },
    { lat: 48.189091, lng: 17.843559 },
    { lat: 48.188875, lng: 17.843316 },
    { lat: 48.188858, lng: 17.843297 },
    { lat: 48.188841, lng: 17.843279 },
    { lat: 48.188828, lng: 17.843263 },
    { lat: 48.18882, lng: 17.843255 },
    { lat: 48.188803, lng: 17.843235 },
    { lat: 48.188774, lng: 17.843202 },
    { lat: 48.18874, lng: 17.843161 },
    { lat: 48.188682, lng: 17.84309 },
    { lat: 48.188669, lng: 17.843075 },
    { lat: 48.188656, lng: 17.84306 },
    { lat: 48.188643, lng: 17.843044 },
    { lat: 48.188635, lng: 17.843034 },
    { lat: 48.188626, lng: 17.843025 },
    { lat: 48.188609, lng: 17.843005 },
    { lat: 48.188576, lng: 17.842965 },
    { lat: 48.188559, lng: 17.842947 },
    { lat: 48.188539, lng: 17.842923 },
    { lat: 48.188505, lng: 17.842887 },
    { lat: 48.188485, lng: 17.842866 },
    { lat: 48.188465, lng: 17.842844 },
    { lat: 48.188448, lng: 17.842827 },
    { lat: 48.188438, lng: 17.842816 },
    { lat: 48.188267, lng: 17.84263 },
    { lat: 48.188246, lng: 17.842607 },
    { lat: 48.188088, lng: 17.842434 },
    { lat: 48.187984, lng: 17.842312 },
    { lat: 48.187822, lng: 17.842121 },
    { lat: 48.1878, lng: 17.842093 },
    { lat: 48.187791, lng: 17.842088 },
    { lat: 48.187766, lng: 17.842074 },
    { lat: 48.187701, lng: 17.842024 },
    { lat: 48.187608, lng: 17.841963 },
    { lat: 48.187512, lng: 17.841899 },
    { lat: 48.187436, lng: 17.841844 },
    { lat: 48.187077, lng: 17.841564 },
    { lat: 48.187068, lng: 17.841558 },
    { lat: 48.187024, lng: 17.841526 },
    { lat: 48.186945, lng: 17.84147 },
    { lat: 48.186909, lng: 17.841445 },
    { lat: 48.186857, lng: 17.841408 },
    { lat: 48.186809, lng: 17.841374 },
    { lat: 48.186698, lng: 17.841299 },
    { lat: 48.186599, lng: 17.841227 },
    { lat: 48.186472, lng: 17.841139 },
    { lat: 48.186199, lng: 17.840948 },
    { lat: 48.185934, lng: 17.840761 },
    { lat: 48.185776, lng: 17.840652 },
    { lat: 48.185125, lng: 17.841177 },
    { lat: 48.184561, lng: 17.841634 },
    { lat: 48.184421, lng: 17.841297 },
    { lat: 48.183985, lng: 17.841388 },
    { lat: 48.183707, lng: 17.840574 },
    { lat: 48.183638, lng: 17.840307 },
    { lat: 48.183491, lng: 17.839745 },
    { lat: 48.183339, lng: 17.839238 },
    { lat: 48.183159, lng: 17.838534 },
    { lat: 48.18315, lng: 17.838501 },
    { lat: 48.183145, lng: 17.838486 },
    { lat: 48.183008, lng: 17.838207 },
    { lat: 48.182735, lng: 17.837646 },
  ],
  Šaľa: [
    { lat: 48.1512955, lng: 17.8399834 },
    { lat: 48.1509752, lng: 17.840466 },
    { lat: 48.150327, lng: 17.8413646 },
    { lat: 48.149423, lng: 17.8430439 },
    { lat: 48.1485414, lng: 17.8452569 },
    { lat: 48.1479779, lng: 17.847537 },
    { lat: 48.1467507, lng: 17.8508318 },
    { lat: 48.1438393, lng: 17.8555168 },
    { lat: 48.1428241, lng: 17.8567823 },
    { lat: 48.1409585, lng: 17.8585044 },
    { lat: 48.140765, lng: 17.858534 },
    { lat: 48.1406063, lng: 17.8586702 },
    { lat: 48.1400486, lng: 17.8593323 },
    { lat: 48.1396325, lng: 17.8596581 },
    { lat: 48.1389966, lng: 17.8604617 },
    { lat: 48.1386389, lng: 17.8609989 },
    { lat: 48.1384349, lng: 17.8610773 },
    { lat: 48.1376357, lng: 17.8611539 },
    { lat: 48.1338503, lng: 17.8631976 },
    { lat: 48.1331532, lng: 17.8636475 },
    { lat: 48.1328902, lng: 17.8637604 },
    { lat: 48.1307659, lng: 17.8625107 },
    { lat: 48.1291311, lng: 17.8619688 },
    { lat: 48.1283108, lng: 17.8615532 },
    { lat: 48.1281083, lng: 17.8613431 },
    { lat: 48.1276956, lng: 17.861128 },
    { lat: 48.1264629, lng: 17.8595777 },
    { lat: 48.1264013, lng: 17.859402 },
    { lat: 48.1258628, lng: 17.8585162 },
    { lat: 48.1257963, lng: 17.858279 },
    { lat: 48.1257671, lng: 17.8576901 },
    { lat: 48.1227137, lng: 17.8559257 },
    { lat: 48.1212947, lng: 17.8546519 },
    { lat: 48.1197722, lng: 17.8550457 },
    { lat: 48.1197165, lng: 17.8551127 },
    { lat: 48.1190865, lng: 17.8558851 },
    { lat: 48.117936, lng: 17.8588893 },
    { lat: 48.1200845, lng: 17.860348 },
    { lat: 48.1195014, lng: 17.8624236 },
    { lat: 48.1184669, lng: 17.8645035 },
    { lat: 48.1169181, lng: 17.8648897 },
    { lat: 48.1153669, lng: 17.8646275 },
    { lat: 48.1139995, lng: 17.8641806 },
    { lat: 48.1119295, lng: 17.8628561 },
    { lat: 48.1113011, lng: 17.862235 },
    { lat: 48.1103271, lng: 17.8648977 },
    { lat: 48.1097587, lng: 17.8658656 },
    { lat: 48.1095906, lng: 17.8659599 },
    { lat: 48.1090557, lng: 17.866481 },
    { lat: 48.1084331, lng: 17.8660824 },
    { lat: 48.1081971, lng: 17.8667832 },
    { lat: 48.1076468, lng: 17.8680824 },
    { lat: 48.1073845, lng: 17.8678942 },
    { lat: 48.1064823, lng: 17.8684036 },
    { lat: 48.1060721, lng: 17.868896 },
    { lat: 48.1054311, lng: 17.8694542 },
    { lat: 48.1051377, lng: 17.8696029 },
    { lat: 48.1051717, lng: 17.8693112 },
    { lat: 48.1048017, lng: 17.8692026 },
    { lat: 48.1046187, lng: 17.8690001 },
    { lat: 48.104588, lng: 17.8688108 },
    { lat: 48.104635, lng: 17.8684734 },
    { lat: 48.1044984, lng: 17.8681583 },
    { lat: 48.1045048, lng: 17.8675772 },
    { lat: 48.1043052, lng: 17.8673614 },
    { lat: 48.1042595, lng: 17.8672214 },
    { lat: 48.1043184, lng: 17.8668383 },
    { lat: 48.1046204, lng: 17.8660558 },
    { lat: 48.1050538, lng: 17.8665862 },
    { lat: 48.1054027, lng: 17.8667565 },
    { lat: 48.1052439, lng: 17.865785 },
    { lat: 48.1052382, lng: 17.8655054 },
    { lat: 48.1053175, lng: 17.8648451 },
    { lat: 48.1052481, lng: 17.8646546 },
    { lat: 48.1048742, lng: 17.864927 },
    { lat: 48.1045224, lng: 17.8653017 },
    { lat: 48.104372, lng: 17.8652971 },
    { lat: 48.1039698, lng: 17.8650684 },
    { lat: 48.103596, lng: 17.8651063 },
    { lat: 48.1030388, lng: 17.8654987 },
    { lat: 48.1028036, lng: 17.8658073 },
    { lat: 48.1026343, lng: 17.8661408 },
    { lat: 48.1024205, lng: 17.8673329 },
    { lat: 48.1024347, lng: 17.8686179 },
    { lat: 48.1027289, lng: 17.8699749 },
    { lat: 48.1030614, lng: 17.8706504 },
    { lat: 48.1032429, lng: 17.8712406 },
    { lat: 48.1032953, lng: 17.8716526 },
    { lat: 48.1031014, lng: 17.871916 },
    { lat: 48.1027139, lng: 17.8718343 },
    { lat: 48.1023653, lng: 17.8710811 },
    { lat: 48.1018536, lng: 17.870245 },
    { lat: 48.1014284, lng: 17.8700684 },
    { lat: 48.1008898, lng: 17.870139 },
    { lat: 48.1005514, lng: 17.8704037 },
    { lat: 48.1004414, lng: 17.8706517 },
    { lat: 48.1003742, lng: 17.8712694 },
    { lat: 48.1001428, lng: 17.8712539 },
    { lat: 48.0999698, lng: 17.8705436 },
    { lat: 48.0998314, lng: 17.8703301 },
    { lat: 48.0989508, lng: 17.8695549 },
    { lat: 48.0988001, lng: 17.8693219 },
    { lat: 48.0984703, lng: 17.8681372 },
    { lat: 48.098135, lng: 17.8675871 },
    { lat: 48.0979422, lng: 17.8674051 },
    { lat: 48.0975219, lng: 17.8672307 },
    { lat: 48.0971377, lng: 17.8674667 },
    { lat: 48.0969895, lng: 17.8677005 },
    { lat: 48.0967939, lng: 17.8684906 },
    { lat: 48.0967481, lng: 17.8693818 },
    { lat: 48.096813, lng: 17.8706481 },
    { lat: 48.0970058, lng: 17.8712709 },
    { lat: 48.0972475, lng: 17.8717005 },
    { lat: 48.0972765, lng: 17.871877 },
    { lat: 48.0972445, lng: 17.8722236 },
    { lat: 48.0970128, lng: 17.8729384 },
    { lat: 48.096893, lng: 17.873103 },
    { lat: 48.0967546, lng: 17.8731293 },
    { lat: 48.0957328, lng: 17.87257 },
    { lat: 48.0956917, lng: 17.8726169 },
    { lat: 48.0955026, lng: 17.8732189 },
    { lat: 48.0953691, lng: 17.8745418 },
    { lat: 48.0955006, lng: 17.8751073 },
    { lat: 48.0956734, lng: 17.8754764 },
    { lat: 48.0958929, lng: 17.8757432 },
    { lat: 48.097218, lng: 17.8766847 },
    { lat: 48.0974391, lng: 17.876996 },
    { lat: 48.0975926, lng: 17.8773495 },
    { lat: 48.0975895, lng: 17.8777592 },
    { lat: 48.0975065, lng: 17.8780459 },
    { lat: 48.0971378, lng: 17.8783648 },
    { lat: 48.0967887, lng: 17.878234 },
    { lat: 48.0964032, lng: 17.8783715 },
    { lat: 48.0962741, lng: 17.8785851 },
    { lat: 48.0960175, lng: 17.8792443 },
    { lat: 48.0958859, lng: 17.8804914 },
    { lat: 48.0958367, lng: 17.881444 },
    { lat: 48.0960212, lng: 17.8824518 },
    { lat: 48.096056, lng: 17.8830957 },
    { lat: 48.0958401, lng: 17.8836632 },
    { lat: 48.0958045, lng: 17.8841137 },
    { lat: 48.0956911, lng: 17.8845165 },
    { lat: 48.0953843, lng: 17.8853145 },
    { lat: 48.0951349, lng: 17.8855928 },
    { lat: 48.094754, lng: 17.8856774 },
    { lat: 48.0942284, lng: 17.8855189 },
    { lat: 48.0934031, lng: 17.885147 },
    { lat: 48.0930362, lng: 17.8847584 },
    { lat: 48.0927868, lng: 17.8840352 },
    { lat: 48.0927756, lng: 17.8828906 },
    { lat: 48.092461, lng: 17.8815112 },
    { lat: 48.0921682, lng: 17.8809853 },
    { lat: 48.0917984, lng: 17.8805307 },
    { lat: 48.0915194, lng: 17.8803032 },
    { lat: 48.0910704, lng: 17.8802724 },
    { lat: 48.0907193, lng: 17.8804773 },
    { lat: 48.0898868, lng: 17.8814478 },
    { lat: 48.0896305, lng: 17.8818724 },
    { lat: 48.0894484, lng: 17.8819187 },
    { lat: 48.0893368, lng: 17.881792 },
    { lat: 48.0892642, lng: 17.8815091 },
    { lat: 48.0893158, lng: 17.8809686 },
    { lat: 48.089454, lng: 17.8804616 },
    { lat: 48.0893504, lng: 17.8801134 },
    { lat: 48.0890924, lng: 17.8800486 },
    { lat: 48.0886399, lng: 17.8803615 },
    { lat: 48.0882893, lng: 17.8807954 },
    { lat: 48.0879731, lng: 17.8810372 },
    { lat: 48.0877087, lng: 17.8813284 },
    { lat: 48.0871503, lng: 17.8823134 },
    { lat: 48.0870254, lng: 17.8823947 },
    { lat: 48.086903, lng: 17.8823474 },
    { lat: 48.0867304, lng: 17.8820199 },
    { lat: 48.0866952, lng: 17.8816989 },
    { lat: 48.0867132, lng: 17.8815707 },
    { lat: 48.0869746, lng: 17.8811564 },
    { lat: 48.0869929, lng: 17.8810099 },
    { lat: 48.0869694, lng: 17.8797001 },
    { lat: 48.0870158, lng: 17.8787592 },
    { lat: 48.0870002, lng: 17.8783013 },
    { lat: 48.0869348, lng: 17.8781178 },
    { lat: 48.0862443, lng: 17.8772193 },
    { lat: 48.0861856, lng: 17.8768319 },
    { lat: 48.0860184, lng: 17.8768472 },
    { lat: 48.0849055, lng: 17.877441 },
    { lat: 48.0840491, lng: 17.8819757 },
    { lat: 48.0839449, lng: 17.8834708 },
    { lat: 48.0838158, lng: 17.8841651 },
    { lat: 48.0834571, lng: 17.8859821 },
    { lat: 48.0827072, lng: 17.8890296 },
    { lat: 48.0826592, lng: 17.889518 },
    { lat: 48.0820042, lng: 17.8933478 },
    { lat: 48.0816648, lng: 17.8958014 },
    { lat: 48.0817983, lng: 17.8973598 },
    { lat: 48.0820025, lng: 17.8988797 },
    { lat: 48.0822308, lng: 17.9002943 },
    { lat: 48.0824201, lng: 17.9010449 },
    { lat: 48.0831973, lng: 17.9016861 },
    { lat: 48.085117, lng: 17.9019021 },
    { lat: 48.0856163, lng: 17.9026506 },
    { lat: 48.0857827, lng: 17.9037345 },
    { lat: 48.0863937, lng: 17.905115 },
    { lat: 48.0880032, lng: 17.9045714 },
    { lat: 48.0884658, lng: 17.9054414 },
    { lat: 48.0899632, lng: 17.9088461 },
    { lat: 48.0899779, lng: 17.9088653 },
    { lat: 48.0899975, lng: 17.9088725 },
    { lat: 48.0902913, lng: 17.9088578 },
    { lat: 48.0909489, lng: 17.909018 },
    { lat: 48.0918167, lng: 17.9093665 },
    { lat: 48.0923699, lng: 17.9097039 },
    { lat: 48.0923462, lng: 17.909979 },
    { lat: 48.0920165, lng: 17.9109448 },
    { lat: 48.0921299, lng: 17.911141 },
    { lat: 48.093453, lng: 17.9150604 },
    { lat: 48.0939829, lng: 17.9144569 },
    { lat: 48.0947614, lng: 17.9132047 },
    { lat: 48.0957645, lng: 17.9113045 },
    { lat: 48.0960317, lng: 17.9102599 },
    { lat: 48.0962113, lng: 17.9092024 },
    { lat: 48.0963746, lng: 17.9088992 },
    { lat: 48.0965062, lng: 17.9083725 },
    { lat: 48.0966536, lng: 17.9080499 },
    { lat: 48.0967678, lng: 17.9078262 },
    { lat: 48.0970171, lng: 17.9075463 },
    { lat: 48.0977328, lng: 17.9071414 },
    { lat: 48.0981308, lng: 17.9070768 },
    { lat: 48.0985492, lng: 17.9073298 },
    { lat: 48.0991008, lng: 17.9078815 },
    { lat: 48.0996494, lng: 17.9086024 },
    { lat: 48.1007063, lng: 17.9102062 },
    { lat: 48.1015432, lng: 17.9112529 },
    { lat: 48.1030955, lng: 17.9126138 },
    { lat: 48.1037908, lng: 17.9131341 },
    { lat: 48.1043323, lng: 17.9133151 },
    { lat: 48.104907, lng: 17.913671 },
    { lat: 48.1049808, lng: 17.9136933 },
    { lat: 48.1061226, lng: 17.9140379 },
    { lat: 48.1068652, lng: 17.9141676 },
    { lat: 48.1081423, lng: 17.9140632 },
    { lat: 48.109182, lng: 17.9138967 },
    { lat: 48.1102247, lng: 17.9134076 },
    { lat: 48.1106842, lng: 17.9130399 },
    { lat: 48.1112531, lng: 17.9124048 },
    { lat: 48.1114984, lng: 17.9119452 },
    { lat: 48.1124723, lng: 17.9105877 },
    { lat: 48.1135534, lng: 17.9095585 },
    { lat: 48.114082, lng: 17.9091559 },
    { lat: 48.1145607, lng: 17.9088784 },
    { lat: 48.1151778, lng: 17.9087075 },
    { lat: 48.1154158, lng: 17.9087392 },
    { lat: 48.1167635, lng: 17.9092189 },
    { lat: 48.1173665, lng: 17.9096727 },
    { lat: 48.1176017, lng: 17.9099181 },
    { lat: 48.1182929, lng: 17.9113011 },
    { lat: 48.1183789, lng: 17.9116391 },
    { lat: 48.1185424, lng: 17.913943 },
    { lat: 48.118866, lng: 17.9155508 },
    { lat: 48.1189771, lng: 17.9172717 },
    { lat: 48.1192596, lng: 17.9186878 },
    { lat: 48.1196658, lng: 17.9200264 },
    { lat: 48.1201208, lng: 17.9209024 },
    { lat: 48.1210483, lng: 17.9217887 },
    { lat: 48.1212903, lng: 17.9219647 },
    { lat: 48.1218741, lng: 17.9220613 },
    { lat: 48.1235108, lng: 17.9217431 },
    { lat: 48.1243744, lng: 17.9214277 },
    { lat: 48.1255324, lng: 17.9207754 },
    { lat: 48.1261955, lng: 17.920226 },
    { lat: 48.1270293, lng: 17.9193069 },
    { lat: 48.1273338, lng: 17.9190965 },
    { lat: 48.1297108, lng: 17.9178455 },
    { lat: 48.1299807, lng: 17.9177768 },
    { lat: 48.1307899, lng: 17.9178121 },
    { lat: 48.1319549, lng: 17.9180342 },
    { lat: 48.1331808, lng: 17.9187424 },
    { lat: 48.1339555, lng: 17.9193238 },
    { lat: 48.134544, lng: 17.9199636 },
    { lat: 48.1350981, lng: 17.9206677 },
    { lat: 48.1357854, lng: 17.9218408 },
    { lat: 48.136165, lng: 17.9231277 },
    { lat: 48.1363824, lng: 17.9241276 },
    { lat: 48.1367438, lng: 17.9251915 },
    { lat: 48.1372755, lng: 17.926254 },
    { lat: 48.1380117, lng: 17.9272273 },
    { lat: 48.1382549, lng: 17.9274227 },
    { lat: 48.1385062, lng: 17.9275809 },
    { lat: 48.139137, lng: 17.9277292 },
    { lat: 48.1401902, lng: 17.9273564 },
    { lat: 48.141044, lng: 17.9267497 },
    { lat: 48.1416446, lng: 17.9261716 },
    { lat: 48.1420163, lng: 17.9256584 },
    { lat: 48.142411, lng: 17.9249826 },
    { lat: 48.1430853, lng: 17.9231812 },
    { lat: 48.1435878, lng: 17.9214503 },
    { lat: 48.1438285, lng: 17.920431 },
    { lat: 48.1440403, lng: 17.9189183 },
    { lat: 48.144065, lng: 17.9175747 },
    { lat: 48.1441601, lng: 17.9166857 },
    { lat: 48.1442688, lng: 17.9161157 },
    { lat: 48.1445871, lng: 17.9149593 },
    { lat: 48.1447753, lng: 17.9139339 },
    { lat: 48.1448701, lng: 17.9130594 },
    { lat: 48.1449281, lng: 17.9116152 },
    { lat: 48.1448715, lng: 17.9099773 },
    { lat: 48.1450008, lng: 17.9092984 },
    { lat: 48.1455132, lng: 17.9084221 },
    { lat: 48.1460826, lng: 17.9072976 },
    { lat: 48.1471051, lng: 17.9057702 },
    { lat: 48.1489517, lng: 17.9039672 },
    { lat: 48.1497432, lng: 17.9052913 },
    { lat: 48.151972, lng: 17.9072671 },
    { lat: 48.1553273, lng: 17.9100087 },
    { lat: 48.1564799, lng: 17.9110516 },
    { lat: 48.1571452, lng: 17.9117822 },
    { lat: 48.1586579, lng: 17.9150414 },
    { lat: 48.1616064, lng: 17.9203488 },
    { lat: 48.1615805, lng: 17.920702 },
    { lat: 48.1617296, lng: 17.9212651 },
    { lat: 48.1618994, lng: 17.9217014 },
    { lat: 48.1628216, lng: 17.9233744 },
    { lat: 48.1633114, lng: 17.9241584 },
    { lat: 48.163493, lng: 17.9246578 },
    { lat: 48.1635372, lng: 17.9250909 },
    { lat: 48.1638871, lng: 17.9255371 },
    { lat: 48.1641335, lng: 17.9259525 },
    { lat: 48.1657873, lng: 17.9292149 },
    { lat: 48.16673, lng: 17.9305802 },
    { lat: 48.1683739, lng: 17.9331248 },
    { lat: 48.1735152, lng: 17.9405312 },
    { lat: 48.1760129, lng: 17.9374998 },
    { lat: 48.1766349, lng: 17.9363651 },
    { lat: 48.1767647, lng: 17.9359331 },
    { lat: 48.176354, lng: 17.9351338 },
    { lat: 48.1777888, lng: 17.9326538 },
    { lat: 48.1817503, lng: 17.9251448 },
    { lat: 48.1820632, lng: 17.9246646 },
    { lat: 48.1822971, lng: 17.9241483 },
    { lat: 48.1835713, lng: 17.9222066 },
    { lat: 48.1851853, lng: 17.9199481 },
    { lat: 48.1861487, lng: 17.9189329 },
    { lat: 48.1866953, lng: 17.9184351 },
    { lat: 48.1884272, lng: 17.9163163 },
    { lat: 48.1891413, lng: 17.9149495 },
    { lat: 48.1894316, lng: 17.9142837 },
    { lat: 48.1894867, lng: 17.913944 },
    { lat: 48.189521, lng: 17.913597 },
    { lat: 48.1894307, lng: 17.9120534 },
    { lat: 48.1893277, lng: 17.9112495 },
    { lat: 48.1890976, lng: 17.908108 },
    { lat: 48.1889822, lng: 17.9073957 },
    { lat: 48.1887512, lng: 17.9065466 },
    { lat: 48.1886184, lng: 17.9058097 },
    { lat: 48.1885165, lng: 17.9044846 },
    { lat: 48.1880514, lng: 17.9003209 },
    { lat: 48.1888228, lng: 17.8990105 },
    { lat: 48.1884776, lng: 17.8980475 },
    { lat: 48.1882204, lng: 17.897774 },
    { lat: 48.1881513, lng: 17.8961905 },
    { lat: 48.1877393, lng: 17.8950936 },
    { lat: 48.1877418, lng: 17.8942825 },
    { lat: 48.1878225, lng: 17.8936606 },
    { lat: 48.1863923, lng: 17.8920847 },
    { lat: 48.185422, lng: 17.8904414 },
    { lat: 48.1849793, lng: 17.8899176 },
    { lat: 48.1840624, lng: 17.8885419 },
    { lat: 48.1832064, lng: 17.8868828 },
    { lat: 48.1784887, lng: 17.8837849 },
    { lat: 48.176173, lng: 17.8814446 },
    { lat: 48.1718126, lng: 17.8767453 },
    { lat: 48.1714271, lng: 17.8754781 },
    { lat: 48.170146, lng: 17.8747963 },
    { lat: 48.1678625, lng: 17.8747063 },
    { lat: 48.1666006, lng: 17.8739515 },
    { lat: 48.1664291, lng: 17.8739509 },
    { lat: 48.1657619, lng: 17.8742639 },
    { lat: 48.1653218, lng: 17.874598 },
    { lat: 48.1648106, lng: 17.8752695 },
    { lat: 48.1641426, lng: 17.8764404 },
    { lat: 48.1636922, lng: 17.8768278 },
    { lat: 48.1633035, lng: 17.8768974 },
    { lat: 48.1632369, lng: 17.8770069 },
    { lat: 48.163242, lng: 17.8773509 },
    { lat: 48.1631662, lng: 17.877497 },
    { lat: 48.1629108, lng: 17.8776019 },
    { lat: 48.1626784, lng: 17.875162 },
    { lat: 48.1636449, lng: 17.8730944 },
    { lat: 48.1636444, lng: 17.8727681 },
    { lat: 48.1639491, lng: 17.8718811 },
    { lat: 48.1641124, lng: 17.8711898 },
    { lat: 48.1647283, lng: 17.8695917 },
    { lat: 48.1649279, lng: 17.8687583 },
    { lat: 48.1653631, lng: 17.8673821 },
    { lat: 48.1655706, lng: 17.8661788 },
    { lat: 48.1657054, lng: 17.865741 },
    { lat: 48.1658247, lng: 17.8640147 },
    { lat: 48.1658397, lng: 17.8630805 },
    { lat: 48.1659466, lng: 17.8617643 },
    { lat: 48.1663228, lng: 17.8589594 },
    { lat: 48.1663432, lng: 17.8585736 },
    { lat: 48.1649773, lng: 17.857726 },
    { lat: 48.1651478, lng: 17.8562137 },
    { lat: 48.1620044, lng: 17.8538978 },
    { lat: 48.161787, lng: 17.8546428 },
    { lat: 48.161379, lng: 17.8554673 },
    { lat: 48.1610781, lng: 17.8558624 },
    { lat: 48.1608288, lng: 17.856086 },
    { lat: 48.160469, lng: 17.8562561 },
    { lat: 48.1570648, lng: 17.851153 },
    { lat: 48.1570185, lng: 17.8510836 },
    { lat: 48.1570426, lng: 17.8510288 },
    { lat: 48.1573783, lng: 17.8502647 },
    { lat: 48.1578885, lng: 17.8493252 },
    { lat: 48.1586399, lng: 17.8488752 },
    { lat: 48.1596233, lng: 17.848049 },
    { lat: 48.1610544, lng: 17.8473606 },
    { lat: 48.1615002, lng: 17.8469732 },
    { lat: 48.1617128, lng: 17.8465555 },
    { lat: 48.1620185, lng: 17.8461078 },
    { lat: 48.1625507, lng: 17.8459727 },
    { lat: 48.1630062, lng: 17.8456462 },
    { lat: 48.1626964, lng: 17.8441462 },
    { lat: 48.1624237, lng: 17.8434361 },
    { lat: 48.1614831, lng: 17.8419624 },
    { lat: 48.160853, lng: 17.8417931 },
    { lat: 48.1599846, lng: 17.8417862 },
    { lat: 48.1589717, lng: 17.8415441 },
    { lat: 48.1577373, lng: 17.8414035 },
    { lat: 48.157252, lng: 17.8411854 },
    { lat: 48.1556015, lng: 17.8408196 },
    { lat: 48.1552027, lng: 17.8406469 },
    { lat: 48.1539252, lng: 17.8398628 },
    { lat: 48.1531726, lng: 17.8395742 },
    { lat: 48.1527985, lng: 17.8393649 },
    { lat: 48.152063, lng: 17.8395954 },
    { lat: 48.1516092, lng: 17.8398729 },
    { lat: 48.1513342, lng: 17.8399703 },
    { lat: 48.1512955, lng: 17.8399834 },
  ],
  Tešedíkovo: [
    { lat: 48.1197722, lng: 17.8550457 },
    { lat: 48.1187814, lng: 17.8551793 },
    { lat: 48.1182524, lng: 17.8552049 },
    { lat: 48.1180989, lng: 17.8551517 },
    { lat: 48.1176464, lng: 17.8547309 },
    { lat: 48.1167998, lng: 17.8541937 },
    { lat: 48.1155497, lng: 17.8524395 },
    { lat: 48.1148836, lng: 17.8517173 },
    { lat: 48.1154395, lng: 17.8490004 },
    { lat: 48.1155101, lng: 17.8488816 },
    { lat: 48.1154959, lng: 17.8484968 },
    { lat: 48.1154879, lng: 17.8473585 },
    { lat: 48.1155295, lng: 17.8468805 },
    { lat: 48.1156906, lng: 17.8463395 },
    { lat: 48.1159179, lng: 17.845976 },
    { lat: 48.1162321, lng: 17.8451912 },
    { lat: 48.1166084, lng: 17.8444992 },
    { lat: 48.1168046, lng: 17.8434288 },
    { lat: 48.1166739, lng: 17.8429719 },
    { lat: 48.1153928, lng: 17.8421644 },
    { lat: 48.1150859, lng: 17.8421513 },
    { lat: 48.1148017, lng: 17.8423583 },
    { lat: 48.1142668, lng: 17.8424145 },
    { lat: 48.113743, lng: 17.8421556 },
    { lat: 48.1123462, lng: 17.8410859 },
    { lat: 48.1119042, lng: 17.841003 },
    { lat: 48.1118964, lng: 17.8404131 },
    { lat: 48.1120121, lng: 17.8388801 },
    { lat: 48.1110928, lng: 17.8367943 },
    { lat: 48.1098777, lng: 17.8365651 },
    { lat: 48.1092446, lng: 17.836183 },
    { lat: 48.1091137, lng: 17.83567 },
    { lat: 48.1087838, lng: 17.8353553 },
    { lat: 48.1081586, lng: 17.8354144 },
    { lat: 48.1076363, lng: 17.8351988 },
    { lat: 48.1072955, lng: 17.834284 },
    { lat: 48.1066373, lng: 17.8339856 },
    { lat: 48.1062435, lng: 17.8334741 },
    { lat: 48.1059401, lng: 17.8325443 },
    { lat: 48.105585, lng: 17.8319823 },
    { lat: 48.1053421, lng: 17.8312986 },
    { lat: 48.1051221, lng: 17.8305128 },
    { lat: 48.1050816, lng: 17.8290815 },
    { lat: 48.1056522, lng: 17.8274005 },
    { lat: 48.10568, lng: 17.8265605 },
    { lat: 48.1058726, lng: 17.8207444 },
    { lat: 48.105754, lng: 17.820104 },
    { lat: 48.105633, lng: 17.820079 },
    { lat: 48.105565, lng: 17.820072 },
    { lat: 48.105232, lng: 17.82004 },
    { lat: 48.105145, lng: 17.820035 },
    { lat: 48.105071, lng: 17.820027 },
    { lat: 48.105029, lng: 17.820025 },
    { lat: 48.104988, lng: 17.820022 },
    { lat: 48.104905, lng: 17.820011 },
    { lat: 48.104812, lng: 17.820002 },
    { lat: 48.104568, lng: 17.819983 },
    { lat: 48.10452, lng: 17.819979 },
    { lat: 48.10448, lng: 17.819952 },
    { lat: 48.104318, lng: 17.819842 },
    { lat: 48.104246, lng: 17.819789 },
    { lat: 48.104188, lng: 17.81975 },
    { lat: 48.10413, lng: 17.819708 },
    { lat: 48.10403, lng: 17.819642 },
    { lat: 48.103941, lng: 17.819584 },
    { lat: 48.103823, lng: 17.819505 },
    { lat: 48.103713, lng: 17.819431 },
    { lat: 48.103603, lng: 17.819353 },
    { lat: 48.103495, lng: 17.819278 },
    { lat: 48.103383, lng: 17.819205 },
    { lat: 48.103052, lng: 17.818978 },
    { lat: 48.102986, lng: 17.818934 },
    { lat: 48.102843, lng: 17.818834 },
    { lat: 48.102773, lng: 17.818786 },
    { lat: 48.102693, lng: 17.81873 },
    { lat: 48.10233, lng: 17.818481 },
    { lat: 48.102236, lng: 17.818416 },
    { lat: 48.10129, lng: 17.817765 },
    { lat: 48.099144, lng: 17.816283 },
    { lat: 48.099036, lng: 17.816204 },
    { lat: 48.098934, lng: 17.816138 },
    { lat: 48.098755, lng: 17.816011 },
    { lat: 48.098706, lng: 17.815983 },
    { lat: 48.098643, lng: 17.815938 },
    { lat: 48.098573, lng: 17.815894 },
    { lat: 48.0985, lng: 17.815846 },
    { lat: 48.098272, lng: 17.815693 },
    { lat: 48.098155, lng: 17.815617 },
    { lat: 48.09802, lng: 17.815522 },
    { lat: 48.09798, lng: 17.815497 },
    { lat: 48.097358, lng: 17.815079 },
    { lat: 48.095541, lng: 17.813509 },
    { lat: 48.093679, lng: 17.811807 },
    { lat: 48.09286, lng: 17.811059 },
    { lat: 48.092815, lng: 17.811018 },
    { lat: 48.092354, lng: 17.8106 },
    { lat: 48.092211, lng: 17.810476 },
    { lat: 48.092153, lng: 17.810427 },
    { lat: 48.092057, lng: 17.810344 },
    { lat: 48.091493, lng: 17.809857 },
    { lat: 48.090679, lng: 17.809138 },
    { lat: 48.090036, lng: 17.808576 },
    { lat: 48.089668, lng: 17.808249 },
    { lat: 48.08951, lng: 17.808101 },
    { lat: 48.089482, lng: 17.808075 },
    { lat: 48.088802, lng: 17.807451 },
    { lat: 48.088346, lng: 17.807028 },
    { lat: 48.088215, lng: 17.806986 },
    { lat: 48.088056, lng: 17.806936 },
    { lat: 48.087918, lng: 17.806891 },
    { lat: 48.087896, lng: 17.806889 },
    { lat: 48.087871, lng: 17.806876 },
    { lat: 48.087851, lng: 17.806869 },
    { lat: 48.087832, lng: 17.806864 },
    { lat: 48.087816, lng: 17.806859 },
    { lat: 48.08776, lng: 17.806839 },
    { lat: 48.087709, lng: 17.806822 },
    { lat: 48.087602, lng: 17.806786 },
    { lat: 48.087473, lng: 17.806743 },
    { lat: 48.087415, lng: 17.806721 },
    { lat: 48.087218, lng: 17.80666 },
    { lat: 48.087181, lng: 17.806648 },
    { lat: 48.087144, lng: 17.806636 },
    { lat: 48.087099, lng: 17.80662 },
    { lat: 48.086938, lng: 17.806566 },
    { lat: 48.086867, lng: 17.806544 },
    { lat: 48.086784, lng: 17.806518 },
    { lat: 48.086752, lng: 17.806508 },
    { lat: 48.086704, lng: 17.806488 },
    { lat: 48.086464, lng: 17.806415 },
    { lat: 48.086405, lng: 17.806401 },
    { lat: 48.08634, lng: 17.80638 },
    { lat: 48.0863, lng: 17.806367 },
    { lat: 48.086262, lng: 17.806358 },
    { lat: 48.086227, lng: 17.80635 },
    { lat: 48.086187, lng: 17.806335 },
    { lat: 48.086153, lng: 17.806326 },
    { lat: 48.086073, lng: 17.806303 },
    { lat: 48.086001, lng: 17.806279 },
    { lat: 48.085962, lng: 17.80627 },
    { lat: 48.085916, lng: 17.806256 },
    { lat: 48.085862, lng: 17.806241 },
    { lat: 48.085767, lng: 17.806213 },
    { lat: 48.085716, lng: 17.806197 },
    { lat: 48.085676, lng: 17.806189 },
    { lat: 48.085647, lng: 17.806181 },
    { lat: 48.085583, lng: 17.80616 },
    { lat: 48.085509, lng: 17.80614 },
    { lat: 48.085414, lng: 17.80611 },
    { lat: 48.085331, lng: 17.806088 },
    { lat: 48.085306, lng: 17.806078 },
    { lat: 48.085284, lng: 17.806071 },
    { lat: 48.085238, lng: 17.806061 },
    { lat: 48.08519, lng: 17.806046 },
    { lat: 48.085151, lng: 17.806034 },
    { lat: 48.085074, lng: 17.806004 },
    { lat: 48.085034, lng: 17.805987 },
    { lat: 48.084927, lng: 17.805945 },
    { lat: 48.084865, lng: 17.805918 },
    { lat: 48.084804, lng: 17.805898 },
    { lat: 48.084735, lng: 17.805869 },
    { lat: 48.084556, lng: 17.805806 },
    { lat: 48.084443, lng: 17.805758 },
    { lat: 48.084395, lng: 17.80574 },
    { lat: 48.084339, lng: 17.805719 },
    { lat: 48.084293, lng: 17.805708 },
    { lat: 48.084249, lng: 17.805693 },
    { lat: 48.084142, lng: 17.805659 },
    { lat: 48.084098, lng: 17.805645 },
    { lat: 48.084063, lng: 17.805636 },
    { lat: 48.08402, lng: 17.805624 },
    { lat: 48.083901, lng: 17.805589 },
    { lat: 48.083825, lng: 17.805567 },
    { lat: 48.083757, lng: 17.805546 },
    { lat: 48.083129, lng: 17.805359 },
    { lat: 48.082546, lng: 17.805188 },
    { lat: 48.082005, lng: 17.805013 },
    { lat: 48.081627, lng: 17.804897 },
    { lat: 48.081567, lng: 17.804879 },
    { lat: 48.081518, lng: 17.804864 },
    { lat: 48.081476, lng: 17.804851 },
    { lat: 48.081223, lng: 17.80477 },
    { lat: 48.081083, lng: 17.804724 },
    { lat: 48.081044, lng: 17.804711 },
    { lat: 48.080757, lng: 17.804614 },
    { lat: 48.080526, lng: 17.804517 },
    { lat: 48.080286, lng: 17.804412 },
    { lat: 48.080152, lng: 17.804359 },
    { lat: 48.080022, lng: 17.804306 },
    { lat: 48.079729, lng: 17.804184 },
    { lat: 48.079636, lng: 17.804147 },
    { lat: 48.07958, lng: 17.804123 },
    { lat: 48.079524, lng: 17.804102 },
    { lat: 48.079448, lng: 17.804069 },
    { lat: 48.079291, lng: 17.804003 },
    { lat: 48.078829, lng: 17.803811 },
    { lat: 48.078698, lng: 17.803764 },
    { lat: 48.07805, lng: 17.803493 },
    { lat: 48.07797, lng: 17.803459 },
    { lat: 48.077721, lng: 17.803365 },
    { lat: 48.077381, lng: 17.803205 },
    { lat: 48.076788, lng: 17.802935 },
    { lat: 48.07678, lng: 17.802931 },
    { lat: 48.07676, lng: 17.802922 },
    { lat: 48.07668, lng: 17.802885 },
    { lat: 48.076645, lng: 17.802868 },
    { lat: 48.076209, lng: 17.802792 },
    { lat: 48.076145, lng: 17.8026 },
    { lat: 48.07603, lng: 17.802673 },
    { lat: 48.075342, lng: 17.802976 },
    { lat: 48.074905, lng: 17.80306 },
    { lat: 48.074348, lng: 17.80318 },
    { lat: 48.074112, lng: 17.803287 },
    { lat: 48.073751, lng: 17.803528 },
    { lat: 48.073535, lng: 17.803779 },
    { lat: 48.073415, lng: 17.803982 },
    { lat: 48.073328, lng: 17.804199 },
    { lat: 48.073251, lng: 17.804427 },
    { lat: 48.073152, lng: 17.804781 },
    { lat: 48.073142, lng: 17.805045 },
    { lat: 48.073156, lng: 17.805733 },
    { lat: 48.07321, lng: 17.806328 },
    { lat: 48.073214, lng: 17.806621 },
    { lat: 48.073187, lng: 17.806875 },
    { lat: 48.073144, lng: 17.807053 },
    { lat: 48.073039, lng: 17.807211 },
    { lat: 48.072917, lng: 17.807312 },
    { lat: 48.0727, lng: 17.807253 },
    { lat: 48.072375, lng: 17.807025 },
    { lat: 48.071534, lng: 17.806249 },
    { lat: 48.071374, lng: 17.80605 },
    { lat: 48.071211, lng: 17.805812 },
    { lat: 48.071124, lng: 17.805712 },
    { lat: 48.070878, lng: 17.805494 },
    { lat: 48.070831, lng: 17.805501 },
    { lat: 48.07075, lng: 17.805507 },
    { lat: 48.070611, lng: 17.805619 },
    { lat: 48.070536, lng: 17.805774 },
    { lat: 48.070481, lng: 17.805998 },
    { lat: 48.070479, lng: 17.806238 },
    { lat: 48.070557, lng: 17.806888 },
    { lat: 48.070659, lng: 17.8075 },
    { lat: 48.070966, lng: 17.808277 },
    { lat: 48.071032, lng: 17.808387 },
    { lat: 48.071304, lng: 17.808776 },
    { lat: 48.071467, lng: 17.809039 },
    { lat: 48.071536, lng: 17.809241 },
    { lat: 48.071566, lng: 17.809485 },
    { lat: 48.07154, lng: 17.80978 },
    { lat: 48.071479, lng: 17.810038 },
    { lat: 48.071186, lng: 17.810524 },
    { lat: 48.071021, lng: 17.810651 },
    { lat: 48.070606, lng: 17.810866 },
    { lat: 48.070255, lng: 17.811008 },
    { lat: 48.069965, lng: 17.811267 },
    { lat: 48.069177, lng: 17.812325 },
    { lat: 48.069064, lng: 17.812441 },
    { lat: 48.068858, lng: 17.812588 },
    { lat: 48.06878, lng: 17.812709 },
    { lat: 48.068554, lng: 17.813164 },
    { lat: 48.06843, lng: 17.813603 },
    { lat: 48.068306, lng: 17.814108 },
    { lat: 48.068284, lng: 17.814293 },
    { lat: 48.068246, lng: 17.81469 },
    { lat: 48.068255, lng: 17.814826 },
    { lat: 48.068291, lng: 17.815015 },
    { lat: 48.06834, lng: 17.815156 },
    { lat: 48.068474, lng: 17.815457 },
    { lat: 48.068514, lng: 17.815731 },
    { lat: 48.068493, lng: 17.815852 },
    { lat: 48.068444, lng: 17.815973 },
    { lat: 48.068347, lng: 17.816138 },
    { lat: 48.068101, lng: 17.816407 },
    { lat: 48.068002, lng: 17.816448 },
    { lat: 48.067864, lng: 17.816427 },
    { lat: 48.067756, lng: 17.81634 },
    { lat: 48.067669, lng: 17.816252 },
    { lat: 48.06705, lng: 17.815062 },
    { lat: 48.066891, lng: 17.814781 },
    { lat: 48.06667, lng: 17.814495 },
    { lat: 48.066513, lng: 17.814361 },
    { lat: 48.066215, lng: 17.814191 },
    { lat: 48.065963, lng: 17.814156 },
    { lat: 48.065693, lng: 17.814319 },
    { lat: 48.06539, lng: 17.814621 },
    { lat: 48.065486, lng: 17.81484 },
    { lat: 48.065498, lng: 17.814866 },
    { lat: 48.06556, lng: 17.815021 },
    { lat: 48.065615, lng: 17.815161 },
    { lat: 48.06563, lng: 17.815196 },
    { lat: 48.066955, lng: 17.818518 },
    { lat: 48.066999, lng: 17.818628 },
    { lat: 48.067066, lng: 17.818798 },
    { lat: 48.06745, lng: 17.819771 },
    { lat: 48.068363, lng: 17.822071 },
    { lat: 48.069236, lng: 17.824265 },
    { lat: 48.069498, lng: 17.824926 },
    { lat: 48.069507, lng: 17.82495 },
    { lat: 48.069569, lng: 17.825106 },
    { lat: 48.06957, lng: 17.825109 },
    { lat: 48.069949, lng: 17.826062 },
    { lat: 48.072139, lng: 17.83156 },
    { lat: 48.073749, lng: 17.835646 },
    { lat: 48.073773, lng: 17.835705 },
    { lat: 48.074272, lng: 17.836973 },
    { lat: 48.076783, lng: 17.843422 },
    { lat: 48.076794, lng: 17.84345 },
    { lat: 48.076836, lng: 17.843555 },
    { lat: 48.075782, lng: 17.844693 },
    { lat: 48.0732, lng: 17.847449 },
    { lat: 48.069865, lng: 17.850961 },
    { lat: 48.069795, lng: 17.851035 },
    { lat: 48.069283, lng: 17.851574 },
    { lat: 48.069169, lng: 17.851315 },
    { lat: 48.069123, lng: 17.851212 },
    { lat: 48.06902, lng: 17.850979 },
    { lat: 48.069019, lng: 17.850975 },
    { lat: 48.068444, lng: 17.849666 },
    { lat: 48.067592, lng: 17.847754 },
    { lat: 48.067556, lng: 17.847849 },
    { lat: 48.067539, lng: 17.847807 },
    { lat: 48.067112, lng: 17.846717 },
    { lat: 48.066782, lng: 17.845993 },
    { lat: 48.066284, lng: 17.84489 },
    { lat: 48.066261, lng: 17.844839 },
    { lat: 48.066215, lng: 17.844784 },
    { lat: 48.066196, lng: 17.844761 },
    { lat: 48.06592, lng: 17.844136 },
    { lat: 48.065081, lng: 17.84225 },
    { lat: 48.064251, lng: 17.840402 },
    { lat: 48.063392, lng: 17.838467 },
    { lat: 48.063337, lng: 17.838562 },
    { lat: 48.06333, lng: 17.838573 },
    { lat: 48.060845, lng: 17.842839 },
    { lat: 48.060623, lng: 17.843221 },
    { lat: 48.060245, lng: 17.843869 },
    { lat: 48.060221, lng: 17.84391 },
    { lat: 48.058974, lng: 17.846051 },
    { lat: 48.057835, lng: 17.848001 },
    { lat: 48.057161, lng: 17.849154 },
    { lat: 48.057049, lng: 17.849345 },
    { lat: 48.056204, lng: 17.850804 },
    { lat: 48.056181, lng: 17.850843 },
    { lat: 48.05575, lng: 17.851586 },
    { lat: 48.054496, lng: 17.853759 },
    { lat: 48.054371, lng: 17.853975 },
    { lat: 48.05439, lng: 17.853989 },
    { lat: 48.054622, lng: 17.854155 },
    { lat: 48.056447, lng: 17.855457 },
    { lat: 48.0584645, lng: 17.8568957 },
    { lat: 48.0633983, lng: 17.8609022 },
    { lat: 48.0679896, lng: 17.8637811 },
    { lat: 48.0740618, lng: 17.8674306 },
    { lat: 48.0763616, lng: 17.8686735 },
    { lat: 48.0777014, lng: 17.8690202 },
    { lat: 48.0794525, lng: 17.8712785 },
    { lat: 48.0798094, lng: 17.8719084 },
    { lat: 48.0797902, lng: 17.8727197 },
    { lat: 48.0799806, lng: 17.8731961 },
    { lat: 48.0799755, lng: 17.8734457 },
    { lat: 48.0798431, lng: 17.8739575 },
    { lat: 48.0795092, lng: 17.8745318 },
    { lat: 48.0793251, lng: 17.8751958 },
    { lat: 48.0792689, lng: 17.8756076 },
    { lat: 48.0792767, lng: 17.8759179 },
    { lat: 48.0793225, lng: 17.8762258 },
    { lat: 48.0794675, lng: 17.876474 },
    { lat: 48.0796623, lng: 17.8765372 },
    { lat: 48.0797983, lng: 17.8764562 },
    { lat: 48.0799465, lng: 17.8762412 },
    { lat: 48.0802126, lng: 17.8751989 },
    { lat: 48.0803869, lng: 17.8750601 },
    { lat: 48.0807386, lng: 17.8749579 },
    { lat: 48.0815923, lng: 17.8751283 },
    { lat: 48.08238, lng: 17.8751433 },
    { lat: 48.0825, lng: 17.8752064 },
    { lat: 48.0826141, lng: 17.875409 },
    { lat: 48.0825428, lng: 17.8762402 },
    { lat: 48.0826844, lng: 17.8765411 },
    { lat: 48.0830097, lng: 17.8769901 },
    { lat: 48.0832187, lng: 17.8771745 },
    { lat: 48.0834266, lng: 17.8772553 },
    { lat: 48.0837045, lng: 17.87722 },
    { lat: 48.0848667, lng: 17.8765665 },
    { lat: 48.0848813, lng: 17.8767661 },
    { lat: 48.0849055, lng: 17.877441 },
    { lat: 48.0860184, lng: 17.8768472 },
    { lat: 48.0861856, lng: 17.8768319 },
    { lat: 48.0862443, lng: 17.8772193 },
    { lat: 48.0869348, lng: 17.8781178 },
    { lat: 48.0870002, lng: 17.8783013 },
    { lat: 48.0870158, lng: 17.8787592 },
    { lat: 48.0869694, lng: 17.8797001 },
    { lat: 48.0869929, lng: 17.8810099 },
    { lat: 48.0869746, lng: 17.8811564 },
    { lat: 48.0867132, lng: 17.8815707 },
    { lat: 48.0866952, lng: 17.8816989 },
    { lat: 48.0867304, lng: 17.8820199 },
    { lat: 48.086903, lng: 17.8823474 },
    { lat: 48.0870254, lng: 17.8823947 },
    { lat: 48.0871503, lng: 17.8823134 },
    { lat: 48.0877087, lng: 17.8813284 },
    { lat: 48.0879731, lng: 17.8810372 },
    { lat: 48.0882893, lng: 17.8807954 },
    { lat: 48.0886399, lng: 17.8803615 },
    { lat: 48.0890924, lng: 17.8800486 },
    { lat: 48.0893504, lng: 17.8801134 },
    { lat: 48.089454, lng: 17.8804616 },
    { lat: 48.0893158, lng: 17.8809686 },
    { lat: 48.0892642, lng: 17.8815091 },
    { lat: 48.0893368, lng: 17.881792 },
    { lat: 48.0894484, lng: 17.8819187 },
    { lat: 48.0896305, lng: 17.8818724 },
    { lat: 48.0898868, lng: 17.8814478 },
    { lat: 48.0907193, lng: 17.8804773 },
    { lat: 48.0910704, lng: 17.8802724 },
    { lat: 48.0915194, lng: 17.8803032 },
    { lat: 48.0917984, lng: 17.8805307 },
    { lat: 48.0921682, lng: 17.8809853 },
    { lat: 48.092461, lng: 17.8815112 },
    { lat: 48.0927756, lng: 17.8828906 },
    { lat: 48.0927868, lng: 17.8840352 },
    { lat: 48.0930362, lng: 17.8847584 },
    { lat: 48.0934031, lng: 17.885147 },
    { lat: 48.0942284, lng: 17.8855189 },
    { lat: 48.094754, lng: 17.8856774 },
    { lat: 48.0951349, lng: 17.8855928 },
    { lat: 48.0953843, lng: 17.8853145 },
    { lat: 48.0956911, lng: 17.8845165 },
    { lat: 48.0958045, lng: 17.8841137 },
    { lat: 48.0958401, lng: 17.8836632 },
    { lat: 48.096056, lng: 17.8830957 },
    { lat: 48.0960212, lng: 17.8824518 },
    { lat: 48.0958367, lng: 17.881444 },
    { lat: 48.0958859, lng: 17.8804914 },
    { lat: 48.0960175, lng: 17.8792443 },
    { lat: 48.0962741, lng: 17.8785851 },
    { lat: 48.0964032, lng: 17.8783715 },
    { lat: 48.0967887, lng: 17.878234 },
    { lat: 48.0971378, lng: 17.8783648 },
    { lat: 48.0975065, lng: 17.8780459 },
    { lat: 48.0975895, lng: 17.8777592 },
    { lat: 48.0975926, lng: 17.8773495 },
    { lat: 48.0974391, lng: 17.876996 },
    { lat: 48.097218, lng: 17.8766847 },
    { lat: 48.0958929, lng: 17.8757432 },
    { lat: 48.0956734, lng: 17.8754764 },
    { lat: 48.0955006, lng: 17.8751073 },
    { lat: 48.0953691, lng: 17.8745418 },
    { lat: 48.0955026, lng: 17.8732189 },
    { lat: 48.0956917, lng: 17.8726169 },
    { lat: 48.0957328, lng: 17.87257 },
    { lat: 48.0967546, lng: 17.8731293 },
    { lat: 48.096893, lng: 17.873103 },
    { lat: 48.0970128, lng: 17.8729384 },
    { lat: 48.0972445, lng: 17.8722236 },
    { lat: 48.0972765, lng: 17.871877 },
    { lat: 48.0972475, lng: 17.8717005 },
    { lat: 48.0970058, lng: 17.8712709 },
    { lat: 48.096813, lng: 17.8706481 },
    { lat: 48.0967481, lng: 17.8693818 },
    { lat: 48.0967939, lng: 17.8684906 },
    { lat: 48.0969895, lng: 17.8677005 },
    { lat: 48.0971377, lng: 17.8674667 },
    { lat: 48.0975219, lng: 17.8672307 },
    { lat: 48.0979422, lng: 17.8674051 },
    { lat: 48.098135, lng: 17.8675871 },
    { lat: 48.0984703, lng: 17.8681372 },
    { lat: 48.0988001, lng: 17.8693219 },
    { lat: 48.0989508, lng: 17.8695549 },
    { lat: 48.0998314, lng: 17.8703301 },
    { lat: 48.0999698, lng: 17.8705436 },
    { lat: 48.1001428, lng: 17.8712539 },
    { lat: 48.1003742, lng: 17.8712694 },
    { lat: 48.1004414, lng: 17.8706517 },
    { lat: 48.1005514, lng: 17.8704037 },
    { lat: 48.1008898, lng: 17.870139 },
    { lat: 48.1014284, lng: 17.8700684 },
    { lat: 48.1018536, lng: 17.870245 },
    { lat: 48.1023653, lng: 17.8710811 },
    { lat: 48.1027139, lng: 17.8718343 },
    { lat: 48.1031014, lng: 17.871916 },
    { lat: 48.1032953, lng: 17.8716526 },
    { lat: 48.1032429, lng: 17.8712406 },
    { lat: 48.1030614, lng: 17.8706504 },
    { lat: 48.1027289, lng: 17.8699749 },
    { lat: 48.1024347, lng: 17.8686179 },
    { lat: 48.1024205, lng: 17.8673329 },
    { lat: 48.1026343, lng: 17.8661408 },
    { lat: 48.1028036, lng: 17.8658073 },
    { lat: 48.1030388, lng: 17.8654987 },
    { lat: 48.103596, lng: 17.8651063 },
    { lat: 48.1039698, lng: 17.8650684 },
    { lat: 48.104372, lng: 17.8652971 },
    { lat: 48.1045224, lng: 17.8653017 },
    { lat: 48.1048742, lng: 17.864927 },
    { lat: 48.1052481, lng: 17.8646546 },
    { lat: 48.1053175, lng: 17.8648451 },
    { lat: 48.1052382, lng: 17.8655054 },
    { lat: 48.1052439, lng: 17.865785 },
    { lat: 48.1054027, lng: 17.8667565 },
    { lat: 48.1050538, lng: 17.8665862 },
    { lat: 48.1046204, lng: 17.8660558 },
    { lat: 48.1043184, lng: 17.8668383 },
    { lat: 48.1042595, lng: 17.8672214 },
    { lat: 48.1043052, lng: 17.8673614 },
    { lat: 48.1045048, lng: 17.8675772 },
    { lat: 48.1044984, lng: 17.8681583 },
    { lat: 48.104635, lng: 17.8684734 },
    { lat: 48.104588, lng: 17.8688108 },
    { lat: 48.1046187, lng: 17.8690001 },
    { lat: 48.1048017, lng: 17.8692026 },
    { lat: 48.1051717, lng: 17.8693112 },
    { lat: 48.1051377, lng: 17.8696029 },
    { lat: 48.1054311, lng: 17.8694542 },
    { lat: 48.1060721, lng: 17.868896 },
    { lat: 48.1064823, lng: 17.8684036 },
    { lat: 48.1073845, lng: 17.8678942 },
    { lat: 48.1076468, lng: 17.8680824 },
    { lat: 48.1081971, lng: 17.8667832 },
    { lat: 48.1084331, lng: 17.8660824 },
    { lat: 48.1090557, lng: 17.866481 },
    { lat: 48.1095906, lng: 17.8659599 },
    { lat: 48.1097587, lng: 17.8658656 },
    { lat: 48.1103271, lng: 17.8648977 },
    { lat: 48.1113011, lng: 17.862235 },
    { lat: 48.1119295, lng: 17.8628561 },
    { lat: 48.1139995, lng: 17.8641806 },
    { lat: 48.1153669, lng: 17.8646275 },
    { lat: 48.1169181, lng: 17.8648897 },
    { lat: 48.1184669, lng: 17.8645035 },
    { lat: 48.1195014, lng: 17.8624236 },
    { lat: 48.1200845, lng: 17.860348 },
    { lat: 48.117936, lng: 17.8588893 },
    { lat: 48.1190865, lng: 17.8558851 },
    { lat: 48.1197165, lng: 17.8551127 },
    { lat: 48.1197722, lng: 17.8550457 },
  ],
  Žihárec: [
    { lat: 48.0849055, lng: 17.877441 },
    { lat: 48.0848813, lng: 17.8767661 },
    { lat: 48.0848667, lng: 17.8765665 },
    { lat: 48.0837045, lng: 17.87722 },
    { lat: 48.0834266, lng: 17.8772553 },
    { lat: 48.0832187, lng: 17.8771745 },
    { lat: 48.0830097, lng: 17.8769901 },
    { lat: 48.0826844, lng: 17.8765411 },
    { lat: 48.0825428, lng: 17.8762402 },
    { lat: 48.0826141, lng: 17.875409 },
    { lat: 48.0825, lng: 17.8752064 },
    { lat: 48.08238, lng: 17.8751433 },
    { lat: 48.0815923, lng: 17.8751283 },
    { lat: 48.0807386, lng: 17.8749579 },
    { lat: 48.0803869, lng: 17.8750601 },
    { lat: 48.0802126, lng: 17.8751989 },
    { lat: 48.0799465, lng: 17.8762412 },
    { lat: 48.0797983, lng: 17.8764562 },
    { lat: 48.0796623, lng: 17.8765372 },
    { lat: 48.0794675, lng: 17.876474 },
    { lat: 48.0793225, lng: 17.8762258 },
    { lat: 48.0792767, lng: 17.8759179 },
    { lat: 48.0792689, lng: 17.8756076 },
    { lat: 48.0793251, lng: 17.8751958 },
    { lat: 48.0795092, lng: 17.8745318 },
    { lat: 48.0798431, lng: 17.8739575 },
    { lat: 48.0799755, lng: 17.8734457 },
    { lat: 48.0799806, lng: 17.8731961 },
    { lat: 48.0797902, lng: 17.8727197 },
    { lat: 48.0798094, lng: 17.8719084 },
    { lat: 48.0794525, lng: 17.8712785 },
    { lat: 48.0777014, lng: 17.8690202 },
    { lat: 48.0763616, lng: 17.8686735 },
    { lat: 48.0740618, lng: 17.8674306 },
    { lat: 48.0679896, lng: 17.8637811 },
    { lat: 48.0633983, lng: 17.8609022 },
    { lat: 48.0584645, lng: 17.8568957 },
    { lat: 48.056447, lng: 17.855457 },
    { lat: 48.056422, lng: 17.855561 },
    { lat: 48.056418, lng: 17.855576 },
    { lat: 48.056371, lng: 17.855597 },
    { lat: 48.056297, lng: 17.855629 },
    { lat: 48.056291, lng: 17.855632 },
    { lat: 48.056273, lng: 17.85564 },
    { lat: 48.056165, lng: 17.855672 },
    { lat: 48.055958, lng: 17.855735 },
    { lat: 48.055786, lng: 17.855784 },
    { lat: 48.055456, lng: 17.85588 },
    { lat: 48.055391, lng: 17.855899 },
    { lat: 48.055199, lng: 17.855941 },
    { lat: 48.055159, lng: 17.85595 },
    { lat: 48.054607, lng: 17.856074 },
    { lat: 48.053973, lng: 17.856275 },
    { lat: 48.053777, lng: 17.85644 },
    { lat: 48.053684, lng: 17.856554 },
    { lat: 48.053608, lng: 17.856649 },
    { lat: 48.05352, lng: 17.856833 },
    { lat: 48.053376, lng: 17.857281 },
    { lat: 48.053081, lng: 17.8582 },
    { lat: 48.052878, lng: 17.858839 },
    { lat: 48.052738, lng: 17.859286 },
    { lat: 48.052412, lng: 17.860324 },
    { lat: 48.052265, lng: 17.860791 },
    { lat: 48.052046, lng: 17.861471 },
    { lat: 48.051759, lng: 17.862363 },
    { lat: 48.051725, lng: 17.862677 },
    { lat: 48.051772, lng: 17.862921 },
    { lat: 48.051839, lng: 17.863122 },
    { lat: 48.052012, lng: 17.863414 },
    { lat: 48.052238, lng: 17.863809 },
    { lat: 48.052319, lng: 17.864125 },
    { lat: 48.052299, lng: 17.864561 },
    { lat: 48.052251, lng: 17.865019 },
    { lat: 48.05216, lng: 17.865781 },
    { lat: 48.052149, lng: 17.86612 },
    { lat: 48.052178, lng: 17.866441 },
    { lat: 48.052316, lng: 17.867429 },
    { lat: 48.052377, lng: 17.867849 },
    { lat: 48.052467, lng: 17.868154 },
    { lat: 48.052578, lng: 17.868405 },
    { lat: 48.052701, lng: 17.868683 },
    { lat: 48.052763, lng: 17.868898 },
    { lat: 48.052796, lng: 17.869072 },
    { lat: 48.05281, lng: 17.869146 },
    { lat: 48.052817, lng: 17.869229 },
    { lat: 48.052958, lng: 17.870014 },
    { lat: 48.05305, lng: 17.870515 },
    { lat: 48.053132, lng: 17.871182 },
    { lat: 48.053103, lng: 17.871436 },
    { lat: 48.053031, lng: 17.871586 },
    { lat: 48.052914, lng: 17.871767 },
    { lat: 48.05272, lng: 17.872034 },
    { lat: 48.052311, lng: 17.87258 },
    { lat: 48.051691, lng: 17.873377 },
    { lat: 48.051046, lng: 17.874228 },
    { lat: 48.050366, lng: 17.875126 },
    { lat: 48.050165, lng: 17.875395 },
    { lat: 48.049718, lng: 17.875996 },
    { lat: 48.048845, lng: 17.877142 },
    { lat: 48.048143, lng: 17.878073 },
    { lat: 48.047768, lng: 17.878559 },
    { lat: 48.047108, lng: 17.879432 },
    { lat: 48.046343, lng: 17.880447 },
    { lat: 48.04612, lng: 17.880713 },
    { lat: 48.045965, lng: 17.880851 },
    { lat: 48.045919, lng: 17.880892 },
    { lat: 48.04569, lng: 17.881048 },
    { lat: 48.045642, lng: 17.881068 },
    { lat: 48.045623, lng: 17.881079 },
    { lat: 48.045301, lng: 17.881253 },
    { lat: 48.045085, lng: 17.88133 },
    { lat: 48.044853, lng: 17.881299 },
    { lat: 48.044644, lng: 17.881197 },
    { lat: 48.044397, lng: 17.881027 },
    { lat: 48.043781, lng: 17.880548 },
    { lat: 48.043133, lng: 17.880048 },
    { lat: 48.042313, lng: 17.879413 },
    { lat: 48.041451, lng: 17.878755 },
    { lat: 48.040677, lng: 17.87817 },
    { lat: 48.040476, lng: 17.878015 },
    { lat: 48.040396, lng: 17.87798 },
    { lat: 48.040299, lng: 17.877937 },
    { lat: 48.040237, lng: 17.877941 },
    { lat: 48.040142, lng: 17.877948 },
    { lat: 48.040108, lng: 17.877976 },
    { lat: 48.039951, lng: 17.878104 },
    { lat: 48.039827, lng: 17.878281 },
    { lat: 48.03979, lng: 17.878332 },
    { lat: 48.039641, lng: 17.878577 },
    { lat: 48.039543, lng: 17.878737 },
    { lat: 48.039477, lng: 17.878844 },
    { lat: 48.039418, lng: 17.87894 },
    { lat: 48.039313, lng: 17.879109 },
    { lat: 48.039271, lng: 17.879179 },
    { lat: 48.039222, lng: 17.879257 },
    { lat: 48.039179, lng: 17.879325 },
    { lat: 48.03914, lng: 17.879388 },
    { lat: 48.039116, lng: 17.879429 },
    { lat: 48.039066, lng: 17.87951 },
    { lat: 48.038987, lng: 17.879639 },
    { lat: 48.038912, lng: 17.879764 },
    { lat: 48.038854, lng: 17.879858 },
    { lat: 48.038813, lng: 17.879924 },
    { lat: 48.038769, lng: 17.879997 },
    { lat: 48.038726, lng: 17.880068 },
    { lat: 48.038688, lng: 17.88013 },
    { lat: 48.038533, lng: 17.880383 },
    { lat: 48.038519, lng: 17.880407 },
    { lat: 48.03846, lng: 17.880501 },
    { lat: 48.038366, lng: 17.880651 },
    { lat: 48.038003, lng: 17.881234 },
    { lat: 48.037901, lng: 17.881399 },
    { lat: 48.037639, lng: 17.881838 },
    { lat: 48.037524, lng: 17.882029 },
    { lat: 48.037445, lng: 17.882162 },
    { lat: 48.037367, lng: 17.882291 },
    { lat: 48.037348, lng: 17.882325 },
    { lat: 48.037322, lng: 17.882366 },
    { lat: 48.03731, lng: 17.882388 },
    { lat: 48.037297, lng: 17.882407 },
    { lat: 48.037274, lng: 17.882443 },
    { lat: 48.037188, lng: 17.882578 },
    { lat: 48.037097, lng: 17.88272 },
    { lat: 48.037007, lng: 17.882862 },
    { lat: 48.036883, lng: 17.883055 },
    { lat: 48.036699, lng: 17.883261 },
    { lat: 48.03638, lng: 17.883414 },
    { lat: 48.035863, lng: 17.883678 },
    { lat: 48.035019, lng: 17.884098 },
    { lat: 48.034406, lng: 17.884401 },
    { lat: 48.034342, lng: 17.884433 },
    { lat: 48.034298, lng: 17.884454 },
    { lat: 48.034274, lng: 17.88447 },
    { lat: 48.0378048, lng: 17.8898829 },
    { lat: 48.0398049, lng: 17.8931182 },
    { lat: 48.0416889, lng: 17.8959487 },
    { lat: 48.0419393, lng: 17.8964294 },
    { lat: 48.0421665, lng: 17.8970092 },
    { lat: 48.0424104, lng: 17.8978791 },
    { lat: 48.0426313, lng: 17.8980958 },
    { lat: 48.0433868, lng: 17.8992671 },
    { lat: 48.0449527, lng: 17.9023651 },
    { lat: 48.04712, lng: 17.9061661 },
    { lat: 48.0499016, lng: 17.911809 },
    { lat: 48.0522598, lng: 17.9168475 },
    { lat: 48.0551816, lng: 17.9190827 },
    { lat: 48.0557248, lng: 17.9170797 },
    { lat: 48.0562265, lng: 17.9156941 },
    { lat: 48.0575589, lng: 17.9127135 },
    { lat: 48.0591804, lng: 17.9130566 },
    { lat: 48.0613607, lng: 17.9146611 },
    { lat: 48.0666929, lng: 17.9177786 },
    { lat: 48.0681818, lng: 17.9198595 },
    { lat: 48.0691594, lng: 17.9194552 },
    { lat: 48.0696865, lng: 17.9193589 },
    { lat: 48.0701379, lng: 17.9196014 },
    { lat: 48.0702064, lng: 17.919745 },
    { lat: 48.0701635, lng: 17.9202653 },
    { lat: 48.0699345, lng: 17.9207318 },
    { lat: 48.0695223, lng: 17.9212286 },
    { lat: 48.0699699, lng: 17.9224295 },
    { lat: 48.0704749, lng: 17.9228804 },
    { lat: 48.070745, lng: 17.9233711 },
    { lat: 48.0713408, lng: 17.9247436 },
    { lat: 48.0717812, lng: 17.9243369 },
    { lat: 48.0740212, lng: 17.922206 },
    { lat: 48.0758739, lng: 17.9205745 },
    { lat: 48.0768682, lng: 17.9195535 },
    { lat: 48.0782319, lng: 17.9178245 },
    { lat: 48.0818432, lng: 17.9138735 },
    { lat: 48.0850616, lng: 17.9115532 },
    { lat: 48.0853876, lng: 17.911084 },
    { lat: 48.086696, lng: 17.9101948 },
    { lat: 48.087674, lng: 17.9091455 },
    { lat: 48.0883715, lng: 17.9087902 },
    { lat: 48.089888, lng: 17.9089816 },
    { lat: 48.0899779, lng: 17.9088653 },
    { lat: 48.0899632, lng: 17.9088461 },
    { lat: 48.0884658, lng: 17.9054414 },
    { lat: 48.0880032, lng: 17.9045714 },
    { lat: 48.0863937, lng: 17.905115 },
    { lat: 48.0857827, lng: 17.9037345 },
    { lat: 48.0856163, lng: 17.9026506 },
    { lat: 48.085117, lng: 17.9019021 },
    { lat: 48.0831973, lng: 17.9016861 },
    { lat: 48.0824201, lng: 17.9010449 },
    { lat: 48.0822308, lng: 17.9002943 },
    { lat: 48.0820025, lng: 17.8988797 },
    { lat: 48.0817983, lng: 17.8973598 },
    { lat: 48.0816648, lng: 17.8958014 },
    { lat: 48.0820042, lng: 17.8933478 },
    { lat: 48.0826592, lng: 17.889518 },
    { lat: 48.0827072, lng: 17.8890296 },
    { lat: 48.0834571, lng: 17.8859821 },
    { lat: 48.0838158, lng: 17.8841651 },
    { lat: 48.0839449, lng: 17.8834708 },
    { lat: 48.0840491, lng: 17.8819757 },
    { lat: 48.0849055, lng: 17.877441 },
  ],
  Diakovce: [
    { lat: 48.1197722, lng: 17.8550457 },
    { lat: 48.1212947, lng: 17.8546519 },
    { lat: 48.1227137, lng: 17.8559257 },
    { lat: 48.1257671, lng: 17.8576901 },
    { lat: 48.1257963, lng: 17.858279 },
    { lat: 48.1258628, lng: 17.8585162 },
    { lat: 48.1264013, lng: 17.859402 },
    { lat: 48.1264629, lng: 17.8595777 },
    { lat: 48.1276956, lng: 17.861128 },
    { lat: 48.1281083, lng: 17.8613431 },
    { lat: 48.1283108, lng: 17.8615532 },
    { lat: 48.1291311, lng: 17.8619688 },
    { lat: 48.1307659, lng: 17.8625107 },
    { lat: 48.1328902, lng: 17.8637604 },
    { lat: 48.1331532, lng: 17.8636475 },
    { lat: 48.1338503, lng: 17.8631976 },
    { lat: 48.1376357, lng: 17.8611539 },
    { lat: 48.1384349, lng: 17.8610773 },
    { lat: 48.1386389, lng: 17.8609989 },
    { lat: 48.1389966, lng: 17.8604617 },
    { lat: 48.1396325, lng: 17.8596581 },
    { lat: 48.1400486, lng: 17.8593323 },
    { lat: 48.1406063, lng: 17.8586702 },
    { lat: 48.140765, lng: 17.858534 },
    { lat: 48.1409585, lng: 17.8585044 },
    { lat: 48.1428241, lng: 17.8567823 },
    { lat: 48.1438393, lng: 17.8555168 },
    { lat: 48.1467507, lng: 17.8508318 },
    { lat: 48.1479779, lng: 17.847537 },
    { lat: 48.1485414, lng: 17.8452569 },
    { lat: 48.149423, lng: 17.8430439 },
    { lat: 48.150327, lng: 17.8413646 },
    { lat: 48.1509752, lng: 17.840466 },
    { lat: 48.1512955, lng: 17.8399834 },
    { lat: 48.1510088, lng: 17.8393206 },
    { lat: 48.1515026, lng: 17.8384469 },
    { lat: 48.1535806, lng: 17.8359773 },
    { lat: 48.1568796, lng: 17.831918 },
    { lat: 48.1576035, lng: 17.8307081 },
    { lat: 48.1599105, lng: 17.825241 },
    { lat: 48.1608391, lng: 17.8231949 },
    { lat: 48.162816, lng: 17.8193629 },
    { lat: 48.162944, lng: 17.819194 },
    { lat: 48.162926, lng: 17.81918 },
    { lat: 48.162317, lng: 17.818706 },
    { lat: 48.161468, lng: 17.817909 },
    { lat: 48.161263, lng: 17.817409 },
    { lat: 48.161006, lng: 17.817271 },
    { lat: 48.160646, lng: 17.81748 },
    { lat: 48.16028, lng: 17.817781 },
    { lat: 48.160176, lng: 17.817744 },
    { lat: 48.160152, lng: 17.817736 },
    { lat: 48.159651, lng: 17.817559 },
    { lat: 48.15961, lng: 17.817544 },
    { lat: 48.159365, lng: 17.817602 },
    { lat: 48.158954, lng: 17.817886 },
    { lat: 48.158898, lng: 17.817764 },
    { lat: 48.158955, lng: 17.81645 },
    { lat: 48.158714, lng: 17.815317 },
    { lat: 48.157703, lng: 17.814402 },
    { lat: 48.157668, lng: 17.814372 },
    { lat: 48.157635, lng: 17.814343 },
    { lat: 48.156061, lng: 17.815867 },
    { lat: 48.155884, lng: 17.815999 },
    { lat: 48.155417, lng: 17.816346 },
    { lat: 48.153864, lng: 17.813017 },
    { lat: 48.152804, lng: 17.810758 },
    { lat: 48.152441, lng: 17.80998 },
    { lat: 48.150675, lng: 17.806809 },
    { lat: 48.149708, lng: 17.804304 },
    { lat: 48.14969, lng: 17.80426 },
    { lat: 48.149609, lng: 17.804049 },
    { lat: 48.148484, lng: 17.80025 },
    { lat: 48.148471, lng: 17.800206 },
    { lat: 48.148467, lng: 17.800189 },
    { lat: 48.148482, lng: 17.800144 },
    { lat: 48.148496, lng: 17.800104 },
    { lat: 48.148535, lng: 17.79999 },
    { lat: 48.148569, lng: 17.799888 },
    { lat: 48.148767, lng: 17.799306 },
    { lat: 48.14965, lng: 17.79843 },
    { lat: 48.150814, lng: 17.797233 },
    { lat: 48.15109, lng: 17.796869 },
    { lat: 48.15125, lng: 17.796657 },
    { lat: 48.15175, lng: 17.795997 },
    { lat: 48.152007, lng: 17.795769 },
    { lat: 48.152059, lng: 17.79572 },
    { lat: 48.152314, lng: 17.795497 },
    { lat: 48.152403, lng: 17.79541 },
    { lat: 48.152495, lng: 17.795337 },
    { lat: 48.154209, lng: 17.794802 },
    { lat: 48.155166, lng: 17.795016 },
    { lat: 48.156126, lng: 17.795323 },
    { lat: 48.157148, lng: 17.794991 },
    { lat: 48.157171, lng: 17.794916 },
    { lat: 48.156936, lng: 17.793468 },
    { lat: 48.156874, lng: 17.793081 },
    { lat: 48.156619, lng: 17.791679 },
    { lat: 48.156185, lng: 17.790094 },
    { lat: 48.15645, lng: 17.788134 },
    { lat: 48.156691, lng: 17.786368 },
    { lat: 48.156986, lng: 17.784474 },
    { lat: 48.157113, lng: 17.783333 },
    { lat: 48.157256, lng: 17.782711 },
    { lat: 48.157471, lng: 17.781775 },
    { lat: 48.157515, lng: 17.781536 },
    { lat: 48.15811, lng: 17.778261 },
    { lat: 48.158167, lng: 17.778029 },
    { lat: 48.157552, lng: 17.776039 },
    { lat: 48.157382, lng: 17.775702 },
    { lat: 48.156983, lng: 17.774908 },
    { lat: 48.156619, lng: 17.773631 },
    { lat: 48.156316, lng: 17.771463 },
    { lat: 48.156063, lng: 17.770613 },
    { lat: 48.156052, lng: 17.770579 },
    { lat: 48.156009, lng: 17.770449 },
    { lat: 48.155999, lng: 17.770418 },
    { lat: 48.155478, lng: 17.768752 },
    { lat: 48.154543, lng: 17.76692 },
    { lat: 48.153985, lng: 17.765942 },
    { lat: 48.153364, lng: 17.765028 },
    { lat: 48.152837, lng: 17.763723 },
    { lat: 48.152811, lng: 17.763657 },
    { lat: 48.152484, lng: 17.762867 },
    { lat: 48.151813, lng: 17.761314 },
    { lat: 48.151791, lng: 17.761273 },
    { lat: 48.151697, lng: 17.761099 },
    { lat: 48.150834, lng: 17.75949 },
    { lat: 48.15039, lng: 17.758638 },
    { lat: 48.150067, lng: 17.758018 },
    { lat: 48.149917, lng: 17.757711 },
    { lat: 48.149617, lng: 17.75715 },
    { lat: 48.149062, lng: 17.756114 },
    { lat: 48.148364, lng: 17.754824 },
    { lat: 48.148346, lng: 17.754791 },
    { lat: 48.148332, lng: 17.754766 },
    { lat: 48.148302, lng: 17.754818 },
    { lat: 48.148184, lng: 17.754884 },
    { lat: 48.147784, lng: 17.755199 },
    { lat: 48.1471353, lng: 17.7557483 },
    { lat: 48.147034, lng: 17.755819 },
    { lat: 48.14699, lng: 17.755868 },
    { lat: 48.146971, lng: 17.755935 },
    { lat: 48.146951, lng: 17.756007 },
    { lat: 48.146577, lng: 17.757341 },
    { lat: 48.14645, lng: 17.757805 },
    { lat: 48.145996, lng: 17.759465 },
    { lat: 48.14599, lng: 17.759487 },
    { lat: 48.145941, lng: 17.759662 },
    { lat: 48.145632, lng: 17.760695 },
    { lat: 48.145206, lng: 17.761693 },
    { lat: 48.144668, lng: 17.762526 },
    { lat: 48.143959, lng: 17.763647 },
    { lat: 48.143206, lng: 17.764846 },
    { lat: 48.143071, lng: 17.765535 },
    { lat: 48.142786, lng: 17.76698 },
    { lat: 48.142757, lng: 17.767123 },
    { lat: 48.142752, lng: 17.767148 },
    { lat: 48.142572, lng: 17.768068 },
    { lat: 48.141905, lng: 17.770695 },
    { lat: 48.141881, lng: 17.770794 },
    { lat: 48.141868, lng: 17.770841 },
    { lat: 48.141852, lng: 17.770908 },
    { lat: 48.141837, lng: 17.770967 },
    { lat: 48.141816, lng: 17.771049 },
    { lat: 48.141719, lng: 17.771435 },
    { lat: 48.141671, lng: 17.771608 },
    { lat: 48.141402, lng: 17.772524 },
    { lat: 48.141001, lng: 17.773958 },
    { lat: 48.140576, lng: 17.775084 },
    { lat: 48.140344, lng: 17.775711 },
    { lat: 48.140283, lng: 17.775888 },
    { lat: 48.140177, lng: 17.77616 },
    { lat: 48.139557, lng: 17.777672 },
    { lat: 48.137965, lng: 17.779271 },
    { lat: 48.136674, lng: 17.780683 },
    { lat: 48.134979, lng: 17.78264 },
    { lat: 48.134964, lng: 17.782657 },
    { lat: 48.13486, lng: 17.782782 },
    { lat: 48.134849, lng: 17.782795 },
    { lat: 48.134812, lng: 17.78284 },
    { lat: 48.134803, lng: 17.782848 },
    { lat: 48.134601, lng: 17.783062 },
    { lat: 48.134479, lng: 17.78319 },
    { lat: 48.134113, lng: 17.783575 },
    { lat: 48.133891, lng: 17.783809 },
    { lat: 48.13385, lng: 17.783851 },
    { lat: 48.13384, lng: 17.783863 },
    { lat: 48.133716, lng: 17.783994 },
    { lat: 48.133365, lng: 17.784363 },
    { lat: 48.133335, lng: 17.784394 },
    { lat: 48.13234, lng: 17.785479 },
    { lat: 48.131393, lng: 17.786511 },
    { lat: 48.13132, lng: 17.78659 },
    { lat: 48.131157, lng: 17.787234 },
    { lat: 48.13104, lng: 17.787537 },
    { lat: 48.131018, lng: 17.787586 },
    { lat: 48.131002, lng: 17.787629 },
    { lat: 48.130961, lng: 17.787739 },
    { lat: 48.13047, lng: 17.788811 },
    { lat: 48.130454, lng: 17.788847 },
    { lat: 48.130426, lng: 17.788917 },
    { lat: 48.130408, lng: 17.78896 },
    { lat: 48.130374, lng: 17.789044 },
    { lat: 48.130288, lng: 17.789246 },
    { lat: 48.130259, lng: 17.789312 },
    { lat: 48.130234, lng: 17.789374 },
    { lat: 48.130209, lng: 17.789431 },
    { lat: 48.130184, lng: 17.789496 },
    { lat: 48.130166, lng: 17.789536 },
    { lat: 48.130059, lng: 17.78978 },
    { lat: 48.129916, lng: 17.79013 },
    { lat: 48.129701, lng: 17.790639 },
    { lat: 48.129597, lng: 17.790892 },
    { lat: 48.129495, lng: 17.791125 },
    { lat: 48.129473, lng: 17.791176 },
    { lat: 48.129398, lng: 17.791301 },
    { lat: 48.129304, lng: 17.791444 },
    { lat: 48.129225, lng: 17.791573 },
    { lat: 48.129147, lng: 17.791703 },
    { lat: 48.129043, lng: 17.791874 },
    { lat: 48.128939, lng: 17.792041 },
    { lat: 48.128888, lng: 17.792129 },
    { lat: 48.128825, lng: 17.79223 },
    { lat: 48.128765, lng: 17.792328 },
    { lat: 48.128645, lng: 17.792528 },
    { lat: 48.128522, lng: 17.792726 },
    { lat: 48.128453, lng: 17.792835 },
    { lat: 48.128395, lng: 17.792935 },
    { lat: 48.128274, lng: 17.793132 },
    { lat: 48.12815, lng: 17.793333 },
    { lat: 48.128093, lng: 17.793428 },
    { lat: 48.128041, lng: 17.793507 },
    { lat: 48.127989, lng: 17.793596 },
    { lat: 48.127934, lng: 17.793674 },
    { lat: 48.127873, lng: 17.793766 },
    { lat: 48.127843, lng: 17.793807 },
    { lat: 48.127812, lng: 17.793847 },
    { lat: 48.127753, lng: 17.793933 },
    { lat: 48.127574, lng: 17.794183 },
    { lat: 48.127512, lng: 17.794273 },
    { lat: 48.127471, lng: 17.794333 },
    { lat: 48.127449, lng: 17.794369 },
    { lat: 48.127331, lng: 17.794537 },
    { lat: 48.127275, lng: 17.794617 },
    { lat: 48.127248, lng: 17.794657 },
    { lat: 48.127219, lng: 17.794699 },
    { lat: 48.127103, lng: 17.794857 },
    { lat: 48.127071, lng: 17.794908 },
    { lat: 48.127018, lng: 17.794985 },
    { lat: 48.126992, lng: 17.795024 },
    { lat: 48.126933, lng: 17.795105 },
    { lat: 48.126904, lng: 17.795145 },
    { lat: 48.126878, lng: 17.795183 },
    { lat: 48.126793, lng: 17.795305 },
    { lat: 48.126767, lng: 17.795342 },
    { lat: 48.126561, lng: 17.795633 },
    { lat: 48.126259, lng: 17.796048 },
    { lat: 48.126202, lng: 17.796125 },
    { lat: 48.126145, lng: 17.796206 },
    { lat: 48.126093, lng: 17.796276 },
    { lat: 48.126034, lng: 17.796356 },
    { lat: 48.125709, lng: 17.796798 },
    { lat: 48.12566, lng: 17.796867 },
    { lat: 48.125604, lng: 17.796939 },
    { lat: 48.125554, lng: 17.79701 },
    { lat: 48.125481, lng: 17.797112 },
    { lat: 48.125426, lng: 17.797185 },
    { lat: 48.125364, lng: 17.797272 },
    { lat: 48.125259, lng: 17.797414 },
    { lat: 48.125203, lng: 17.797491 },
    { lat: 48.125146, lng: 17.797569 },
    { lat: 48.125085, lng: 17.797655 },
    { lat: 48.125001, lng: 17.797764 },
    { lat: 48.124971, lng: 17.797803 },
    { lat: 48.124917, lng: 17.797871 },
    { lat: 48.124863, lng: 17.797952 },
    { lat: 48.124805, lng: 17.798021 },
    { lat: 48.124684, lng: 17.798181 },
    { lat: 48.124627, lng: 17.798261 },
    { lat: 48.124571, lng: 17.798335 },
    { lat: 48.124524, lng: 17.798394 },
    { lat: 48.124352, lng: 17.798615 },
    { lat: 48.124296, lng: 17.798687 },
    { lat: 48.124238, lng: 17.798767 },
    { lat: 48.124129, lng: 17.798911 },
    { lat: 48.124097, lng: 17.798954 },
    { lat: 48.124017, lng: 17.799055 },
    { lat: 48.123934, lng: 17.799168 },
    { lat: 48.123869, lng: 17.79925 },
    { lat: 48.123745, lng: 17.799411 },
    { lat: 48.12369, lng: 17.799479 },
    { lat: 48.123635, lng: 17.79956 },
    { lat: 48.123572, lng: 17.799632 },
    { lat: 48.123518, lng: 17.799707 },
    { lat: 48.123435, lng: 17.799812 },
    { lat: 48.123375, lng: 17.799892 },
    { lat: 48.123294, lng: 17.799995 },
    { lat: 48.123255, lng: 17.80005 },
    { lat: 48.123117, lng: 17.800232 },
    { lat: 48.123014, lng: 17.800358 },
    { lat: 48.122929, lng: 17.800474 },
    { lat: 48.122813, lng: 17.800621 },
    { lat: 48.122767, lng: 17.800692 },
    { lat: 48.122649, lng: 17.800845 },
    { lat: 48.122568, lng: 17.800953 },
    { lat: 48.12251, lng: 17.801025 },
    { lat: 48.122457, lng: 17.801096 },
    { lat: 48.122353, lng: 17.801237 },
    { lat: 48.122287, lng: 17.801317 },
    { lat: 48.122224, lng: 17.8014 },
    { lat: 48.122152, lng: 17.801499 },
    { lat: 48.122086, lng: 17.801587 },
    { lat: 48.122053, lng: 17.801627 },
    { lat: 48.121969, lng: 17.801725 },
    { lat: 48.121922, lng: 17.80179 },
    { lat: 48.121422, lng: 17.8024 },
    { lat: 48.121376, lng: 17.80246 },
    { lat: 48.12132, lng: 17.80254 },
    { lat: 48.12122, lng: 17.802677 },
    { lat: 48.121167, lng: 17.802735 },
    { lat: 48.121017, lng: 17.802937 },
    { lat: 48.120912, lng: 17.803073 },
    { lat: 48.120808, lng: 17.803212 },
    { lat: 48.120662, lng: 17.803408 },
    { lat: 48.120635, lng: 17.803438 },
    { lat: 48.120584, lng: 17.8035 },
    { lat: 48.120503, lng: 17.8036 },
    { lat: 48.120314, lng: 17.803824 },
    { lat: 48.120207, lng: 17.803949 },
    { lat: 48.120168, lng: 17.803993 },
    { lat: 48.120113, lng: 17.804063 },
    { lat: 48.119932, lng: 17.804309 },
    { lat: 48.119854, lng: 17.804409 },
    { lat: 48.119543, lng: 17.804827 },
    { lat: 48.119342, lng: 17.805099 },
    { lat: 48.11923, lng: 17.805243 },
    { lat: 48.119137, lng: 17.80537 },
    { lat: 48.119095, lng: 17.805427 },
    { lat: 48.119068, lng: 17.805466 },
    { lat: 48.11904, lng: 17.805504 },
    { lat: 48.118998, lng: 17.805555 },
    { lat: 48.118947, lng: 17.805617 },
    { lat: 48.118847, lng: 17.805743 },
    { lat: 48.118749, lng: 17.805869 },
    { lat: 48.118745, lng: 17.805874 },
    { lat: 48.118672, lng: 17.805973 },
    { lat: 48.118558, lng: 17.806121 },
    { lat: 48.118412, lng: 17.806312 },
    { lat: 48.118365, lng: 17.806372 },
    { lat: 48.118301, lng: 17.806451 },
    { lat: 48.118172, lng: 17.806621 },
    { lat: 48.118154, lng: 17.806644 },
    { lat: 48.118106, lng: 17.806702 },
    { lat: 48.118055, lng: 17.806768 },
    { lat: 48.118002, lng: 17.806832 },
    { lat: 48.11798, lng: 17.806859 },
    { lat: 48.117943, lng: 17.80691 },
    { lat: 48.117897, lng: 17.806979 },
    { lat: 48.117804, lng: 17.807091 },
    { lat: 48.117792, lng: 17.807104 },
    { lat: 48.117779, lng: 17.807123 },
    { lat: 48.117762, lng: 17.807147 },
    { lat: 48.117745, lng: 17.807173 },
    { lat: 48.117739, lng: 17.807181 },
    { lat: 48.117719, lng: 17.807181 },
    { lat: 48.117689, lng: 17.80717 },
    { lat: 48.117463, lng: 17.807112 },
    { lat: 48.117299, lng: 17.807071 },
    { lat: 48.116961, lng: 17.806986 },
    { lat: 48.11694, lng: 17.807006 },
    { lat: 48.116925, lng: 17.807021 },
    { lat: 48.116807, lng: 17.807134 },
    { lat: 48.11668, lng: 17.807258 },
    { lat: 48.116666, lng: 17.807268 },
    { lat: 48.116632, lng: 17.80729 },
    { lat: 48.116439, lng: 17.807567 },
    { lat: 48.116341, lng: 17.807777 },
    { lat: 48.116213, lng: 17.808262 },
    { lat: 48.116235, lng: 17.808409 },
    { lat: 48.116202, lng: 17.808796 },
    { lat: 48.11596, lng: 17.809395 },
    { lat: 48.115442, lng: 17.809965 },
    { lat: 48.114934, lng: 17.810697 },
    { lat: 48.114872, lng: 17.810832 },
    { lat: 48.114567, lng: 17.811489 },
    { lat: 48.114124, lng: 17.812374 },
    { lat: 48.113728, lng: 17.813166 },
    { lat: 48.112777, lng: 17.813172 },
    { lat: 48.112746, lng: 17.813173 },
    { lat: 48.111884, lng: 17.813183 },
    { lat: 48.111524, lng: 17.813258 },
    { lat: 48.110682, lng: 17.813432 },
    { lat: 48.110084, lng: 17.813565 },
    { lat: 48.10899, lng: 17.813811 },
    { lat: 48.108499, lng: 17.813924 },
    { lat: 48.108429, lng: 17.813935 },
    { lat: 48.108366, lng: 17.813954 },
    { lat: 48.108295, lng: 17.813966 },
    { lat: 48.108232, lng: 17.813985 },
    { lat: 48.108168, lng: 17.813996 },
    { lat: 48.10805, lng: 17.814026 },
    { lat: 48.107582, lng: 17.81412 },
    { lat: 48.107482, lng: 17.814138 },
    { lat: 48.107417, lng: 17.814152 },
    { lat: 48.107366, lng: 17.814161 },
    { lat: 48.107301, lng: 17.814172 },
    { lat: 48.107149, lng: 17.8142 },
    { lat: 48.107066, lng: 17.814216 },
    { lat: 48.106978, lng: 17.814231 },
    { lat: 48.106914, lng: 17.814243 },
    { lat: 48.106904, lng: 17.814244 },
    { lat: 48.10685, lng: 17.814248 },
    { lat: 48.106819, lng: 17.814251 },
    { lat: 48.106817, lng: 17.814306 },
    { lat: 48.10682, lng: 17.814386 },
    { lat: 48.106819, lng: 17.814484 },
    { lat: 48.106812, lng: 17.814742 },
    { lat: 48.106808, lng: 17.814944 },
    { lat: 48.106806, lng: 17.81499 },
    { lat: 48.106806, lng: 17.815231 },
    { lat: 48.10681, lng: 17.81549 },
    { lat: 48.106812, lng: 17.815952 },
    { lat: 48.106809, lng: 17.816116 },
    { lat: 48.106791, lng: 17.816253 },
    { lat: 48.106762, lng: 17.816497 },
    { lat: 48.106753, lng: 17.816578 },
    { lat: 48.106739, lng: 17.8167 },
    { lat: 48.106725, lng: 17.816825 },
    { lat: 48.106709, lng: 17.816948 },
    { lat: 48.106703, lng: 17.816994 },
    { lat: 48.106668, lng: 17.817062 },
    { lat: 48.106654, lng: 17.817099 },
    { lat: 48.106629, lng: 17.817157 },
    { lat: 48.106602, lng: 17.817219 },
    { lat: 48.106549, lng: 17.817343 },
    { lat: 48.106451, lng: 17.817569 },
    { lat: 48.106433, lng: 17.817613 },
    { lat: 48.10641, lng: 17.817665 },
    { lat: 48.106364, lng: 17.817774 },
    { lat: 48.106343, lng: 17.817821 },
    { lat: 48.106316, lng: 17.817887 },
    { lat: 48.106274, lng: 17.817984 },
    { lat: 48.106249, lng: 17.818036 },
    { lat: 48.106228, lng: 17.818081 },
    { lat: 48.106209, lng: 17.818126 },
    { lat: 48.106185, lng: 17.818186 },
    { lat: 48.106149, lng: 17.818266 },
    { lat: 48.106118, lng: 17.818376 },
    { lat: 48.106106, lng: 17.818418 },
    { lat: 48.106094, lng: 17.818459 },
    { lat: 48.106086, lng: 17.818493 },
    { lat: 48.106061, lng: 17.818576 },
    { lat: 48.105992, lng: 17.818812 },
    { lat: 48.105986, lng: 17.818831 },
    { lat: 48.105975, lng: 17.818869 },
    { lat: 48.105944, lng: 17.818958 },
    { lat: 48.105906, lng: 17.819104 },
    { lat: 48.105866, lng: 17.819243 },
    { lat: 48.105834, lng: 17.819355 },
    { lat: 48.105798, lng: 17.819467 },
    { lat: 48.105769, lng: 17.819575 },
    { lat: 48.105736, lng: 17.819688 },
    { lat: 48.105746, lng: 17.819862 },
    { lat: 48.105754, lng: 17.820104 },
    { lat: 48.1058726, lng: 17.8207444 },
    { lat: 48.10568, lng: 17.8265605 },
    { lat: 48.1056522, lng: 17.8274005 },
    { lat: 48.1050816, lng: 17.8290815 },
    { lat: 48.1051221, lng: 17.8305128 },
    { lat: 48.1053421, lng: 17.8312986 },
    { lat: 48.105585, lng: 17.8319823 },
    { lat: 48.1059401, lng: 17.8325443 },
    { lat: 48.1062435, lng: 17.8334741 },
    { lat: 48.1066373, lng: 17.8339856 },
    { lat: 48.1072955, lng: 17.834284 },
    { lat: 48.1076363, lng: 17.8351988 },
    { lat: 48.1081586, lng: 17.8354144 },
    { lat: 48.1087838, lng: 17.8353553 },
    { lat: 48.1091137, lng: 17.83567 },
    { lat: 48.1092446, lng: 17.836183 },
    { lat: 48.1098777, lng: 17.8365651 },
    { lat: 48.1110928, lng: 17.8367943 },
    { lat: 48.1120121, lng: 17.8388801 },
    { lat: 48.1118964, lng: 17.8404131 },
    { lat: 48.1119042, lng: 17.841003 },
    { lat: 48.1123462, lng: 17.8410859 },
    { lat: 48.113743, lng: 17.8421556 },
    { lat: 48.1142668, lng: 17.8424145 },
    { lat: 48.1148017, lng: 17.8423583 },
    { lat: 48.1150859, lng: 17.8421513 },
    { lat: 48.1153928, lng: 17.8421644 },
    { lat: 48.1166739, lng: 17.8429719 },
    { lat: 48.1168046, lng: 17.8434288 },
    { lat: 48.1166084, lng: 17.8444992 },
    { lat: 48.1162321, lng: 17.8451912 },
    { lat: 48.1159179, lng: 17.845976 },
    { lat: 48.1156906, lng: 17.8463395 },
    { lat: 48.1155295, lng: 17.8468805 },
    { lat: 48.1154879, lng: 17.8473585 },
    { lat: 48.1154959, lng: 17.8484968 },
    { lat: 48.1155101, lng: 17.8488816 },
    { lat: 48.1154395, lng: 17.8490004 },
    { lat: 48.1148836, lng: 17.8517173 },
    { lat: 48.1155497, lng: 17.8524395 },
    { lat: 48.1167998, lng: 17.8541937 },
    { lat: 48.1176464, lng: 17.8547309 },
    { lat: 48.1180989, lng: 17.8551517 },
    { lat: 48.1182524, lng: 17.8552049 },
    { lat: 48.1187814, lng: 17.8551793 },
    { lat: 48.1197722, lng: 17.8550457 },
  ],
  TrnovecnadVáhom: [
    { lat: 48.1489517, lng: 17.9039672 },
    { lat: 48.1471051, lng: 17.9057702 },
    { lat: 48.1460826, lng: 17.9072976 },
    { lat: 48.1455132, lng: 17.9084221 },
    { lat: 48.1450008, lng: 17.9092984 },
    { lat: 48.1448715, lng: 17.9099773 },
    { lat: 48.1449281, lng: 17.9116152 },
    { lat: 48.1448701, lng: 17.9130594 },
    { lat: 48.1447753, lng: 17.9139339 },
    { lat: 48.1445871, lng: 17.9149593 },
    { lat: 48.1442688, lng: 17.9161157 },
    { lat: 48.1441601, lng: 17.9166857 },
    { lat: 48.144065, lng: 17.9175747 },
    { lat: 48.1440403, lng: 17.9189183 },
    { lat: 48.1438285, lng: 17.920431 },
    { lat: 48.1435878, lng: 17.9214503 },
    { lat: 48.1430853, lng: 17.9231812 },
    { lat: 48.142411, lng: 17.9249826 },
    { lat: 48.1420163, lng: 17.9256584 },
    { lat: 48.1416446, lng: 17.9261716 },
    { lat: 48.141044, lng: 17.9267497 },
    { lat: 48.1401902, lng: 17.9273564 },
    { lat: 48.139137, lng: 17.9277292 },
    { lat: 48.1385062, lng: 17.9275809 },
    { lat: 48.1382549, lng: 17.9274227 },
    { lat: 48.1380117, lng: 17.9272273 },
    { lat: 48.1372755, lng: 17.926254 },
    { lat: 48.1367438, lng: 17.9251915 },
    { lat: 48.1363824, lng: 17.9241276 },
    { lat: 48.136165, lng: 17.9231277 },
    { lat: 48.1357854, lng: 17.9218408 },
    { lat: 48.1350981, lng: 17.9206677 },
    { lat: 48.134544, lng: 17.9199636 },
    { lat: 48.1339555, lng: 17.9193238 },
    { lat: 48.1331808, lng: 17.9187424 },
    { lat: 48.1319549, lng: 17.9180342 },
    { lat: 48.1307899, lng: 17.9178121 },
    { lat: 48.1299807, lng: 17.9177768 },
    { lat: 48.1297108, lng: 17.9178455 },
    { lat: 48.1273338, lng: 17.9190965 },
    { lat: 48.1270293, lng: 17.9193069 },
    { lat: 48.1261955, lng: 17.920226 },
    { lat: 48.1255324, lng: 17.9207754 },
    { lat: 48.1243744, lng: 17.9214277 },
    { lat: 48.1235108, lng: 17.9217431 },
    { lat: 48.1218741, lng: 17.9220613 },
    { lat: 48.1212903, lng: 17.9219647 },
    { lat: 48.1210483, lng: 17.9217887 },
    { lat: 48.1201208, lng: 17.9209024 },
    { lat: 48.1196658, lng: 17.9200264 },
    { lat: 48.1192596, lng: 17.9186878 },
    { lat: 48.1189771, lng: 17.9172717 },
    { lat: 48.118866, lng: 17.9155508 },
    { lat: 48.1185424, lng: 17.913943 },
    { lat: 48.1183789, lng: 17.9116391 },
    { lat: 48.1182929, lng: 17.9113011 },
    { lat: 48.1176017, lng: 17.9099181 },
    { lat: 48.1173665, lng: 17.9096727 },
    { lat: 48.1167635, lng: 17.9092189 },
    { lat: 48.1154158, lng: 17.9087392 },
    { lat: 48.1151778, lng: 17.9087075 },
    { lat: 48.1145607, lng: 17.9088784 },
    { lat: 48.114082, lng: 17.9091559 },
    { lat: 48.1135534, lng: 17.9095585 },
    { lat: 48.1124723, lng: 17.9105877 },
    { lat: 48.1114984, lng: 17.9119452 },
    { lat: 48.1112531, lng: 17.9124048 },
    { lat: 48.1106842, lng: 17.9130399 },
    { lat: 48.1102247, lng: 17.9134076 },
    { lat: 48.109182, lng: 17.9138967 },
    { lat: 48.1081423, lng: 17.9140632 },
    { lat: 48.1068652, lng: 17.9141676 },
    { lat: 48.1061226, lng: 17.9140379 },
    { lat: 48.1049808, lng: 17.9136933 },
    { lat: 48.104907, lng: 17.913671 },
    { lat: 48.1043323, lng: 17.9133151 },
    { lat: 48.1037908, lng: 17.9131341 },
    { lat: 48.1030955, lng: 17.9126138 },
    { lat: 48.1015432, lng: 17.9112529 },
    { lat: 48.1007063, lng: 17.9102062 },
    { lat: 48.0996494, lng: 17.9086024 },
    { lat: 48.0991008, lng: 17.9078815 },
    { lat: 48.0985492, lng: 17.9073298 },
    { lat: 48.0981308, lng: 17.9070768 },
    { lat: 48.0977328, lng: 17.9071414 },
    { lat: 48.0970171, lng: 17.9075463 },
    { lat: 48.0967678, lng: 17.9078262 },
    { lat: 48.0966536, lng: 17.9080499 },
    { lat: 48.0965062, lng: 17.9083725 },
    { lat: 48.0963746, lng: 17.9088992 },
    { lat: 48.0962113, lng: 17.9092024 },
    { lat: 48.0960317, lng: 17.9102599 },
    { lat: 48.0961046, lng: 17.9138562 },
    { lat: 48.0968078, lng: 17.9177016 },
    { lat: 48.0972552, lng: 17.9187124 },
    { lat: 48.0993237, lng: 17.9227576 },
    { lat: 48.0996081, lng: 17.9231298 },
    { lat: 48.1013026, lng: 17.9249383 },
    { lat: 48.1018304, lng: 17.9256919 },
    { lat: 48.1027559, lng: 17.9267173 },
    { lat: 48.106311, lng: 17.9300231 },
    { lat: 48.1076166, lng: 17.9313757 },
    { lat: 48.1091698, lng: 17.9333708 },
    { lat: 48.1094817, lng: 17.9338405 },
    { lat: 48.1097733, lng: 17.9345205 },
    { lat: 48.1107034, lng: 17.9347792 },
    { lat: 48.1132677, lng: 17.9356894 },
    { lat: 48.1151502, lng: 17.9371936 },
    { lat: 48.1170992, lng: 17.9390927 },
    { lat: 48.1206157, lng: 17.9423808 },
    { lat: 48.1210465, lng: 17.9428437 },
    { lat: 48.1225928, lng: 17.9448219 },
    { lat: 48.1245991, lng: 17.9477433 },
    { lat: 48.1266052, lng: 17.9484968 },
    { lat: 48.1276257, lng: 17.9489641 },
    { lat: 48.1290376, lng: 17.9515972 },
    { lat: 48.1296488, lng: 17.9525416 },
    { lat: 48.1270121, lng: 17.9563371 },
    { lat: 48.1273232, lng: 17.956676 },
    { lat: 48.1288726, lng: 17.9583641 },
    { lat: 48.1290624, lng: 17.9585183 },
    { lat: 48.129252, lng: 17.9585363 },
    { lat: 48.1295851, lng: 17.9586865 },
    { lat: 48.1299381, lng: 17.9589835 },
    { lat: 48.1305467, lng: 17.9596771 },
    { lat: 48.1314577, lng: 17.9608601 },
    { lat: 48.1315165, lng: 17.9609366 },
    { lat: 48.131467, lng: 17.961021 },
    { lat: 48.135528, lng: 17.965955 },
    { lat: 48.135903, lng: 17.966412 },
    { lat: 48.136126, lng: 17.966681 },
    { lat: 48.136321, lng: 17.966922 },
    { lat: 48.136719, lng: 17.967416 },
    { lat: 48.136775, lng: 17.967487 },
    { lat: 48.136826, lng: 17.967542 },
    { lat: 48.137069, lng: 17.96784 },
    { lat: 48.137964, lng: 17.968925 },
    { lat: 48.139408, lng: 17.970686 },
    { lat: 48.13968, lng: 17.9710143 },
    { lat: 48.139872, lng: 17.971246 },
    { lat: 48.139917, lng: 17.971301 },
    { lat: 48.140003, lng: 17.971402 },
    { lat: 48.140049, lng: 17.971456 },
    { lat: 48.140288, lng: 17.971745 },
    { lat: 48.140325, lng: 17.971791 },
    { lat: 48.14103, lng: 17.972658 },
    { lat: 48.142207, lng: 17.9741 },
    { lat: 48.143113, lng: 17.975143 },
    { lat: 48.14326, lng: 17.975315 },
    { lat: 48.143278, lng: 17.975336 },
    { lat: 48.143448, lng: 17.97553 },
    { lat: 48.143571, lng: 17.975681 },
    { lat: 48.144423, lng: 17.976671 },
    { lat: 48.14446, lng: 17.976716 },
    { lat: 48.144537, lng: 17.976805 },
    { lat: 48.144578, lng: 17.976852 },
    { lat: 48.144842, lng: 17.977159 },
    { lat: 48.146965, lng: 17.979609 },
    { lat: 48.147786, lng: 17.980535 },
    { lat: 48.147946, lng: 17.980717 },
    { lat: 48.149849, lng: 17.982876 },
    { lat: 48.149863, lng: 17.982892 },
    { lat: 48.149939, lng: 17.982978 },
    { lat: 48.149951, lng: 17.982993 },
    { lat: 48.150027, lng: 17.98308 },
    { lat: 48.150048, lng: 17.983103 },
    { lat: 48.150085, lng: 17.983145 },
    { lat: 48.150102, lng: 17.983164 },
    { lat: 48.150165, lng: 17.983236 },
    { lat: 48.150251, lng: 17.983334 },
    { lat: 48.150301, lng: 17.983391 },
    { lat: 48.150317, lng: 17.98341 },
    { lat: 48.15034, lng: 17.983436 },
    { lat: 48.150353, lng: 17.983451 },
    { lat: 48.150381, lng: 17.983483 },
    { lat: 48.150869, lng: 17.984044 },
    { lat: 48.151522, lng: 17.984783 },
    { lat: 48.152219, lng: 17.985578 },
    { lat: 48.155228, lng: 17.988997 },
    { lat: 48.156059, lng: 17.989941 },
    { lat: 48.156249, lng: 17.990154 },
    { lat: 48.158741, lng: 17.992977 },
    { lat: 48.15883, lng: 17.993078 },
    { lat: 48.159266, lng: 17.993578 },
    { lat: 48.160675, lng: 17.995189 },
    { lat: 48.161157, lng: 17.995742 },
    { lat: 48.163434, lng: 17.998334 },
    { lat: 48.163909, lng: 17.998879 },
    { lat: 48.164243, lng: 17.999253 },
    { lat: 48.164406, lng: 17.999449 },
    { lat: 48.165083, lng: 18.000206 },
    { lat: 48.165356, lng: 18.000525 },
    { lat: 48.165946, lng: 18.001168 },
    { lat: 48.166254, lng: 18.000616 },
    { lat: 48.166707, lng: 17.999878 },
    { lat: 48.167232, lng: 17.999189 },
    { lat: 48.167652, lng: 17.998325 },
    { lat: 48.168098, lng: 17.997479 },
    { lat: 48.168619, lng: 17.996675 },
    { lat: 48.169208, lng: 17.996016 },
    { lat: 48.169736, lng: 17.995474 },
    { lat: 48.170416, lng: 17.99474 },
    { lat: 48.170711, lng: 17.994423 },
    { lat: 48.171141, lng: 17.993953 },
    { lat: 48.171869, lng: 17.99323 },
    { lat: 48.17235, lng: 17.992708 },
    { lat: 48.172407, lng: 17.992646 },
    { lat: 48.172423, lng: 17.992629 },
    { lat: 48.1725, lng: 17.992549 },
    { lat: 48.17301, lng: 17.992023 },
    { lat: 48.173609, lng: 17.991388 },
    { lat: 48.173933, lng: 17.991051 },
    { lat: 48.1741607, lng: 17.9908094 },
    { lat: 48.174294, lng: 17.990668 },
    { lat: 48.174838, lng: 17.990099 },
    { lat: 48.175819, lng: 17.989407 },
    { lat: 48.175854, lng: 17.989382 },
    { lat: 48.17605, lng: 17.989251 },
    { lat: 48.176454, lng: 17.988985 },
    { lat: 48.1768, lng: 17.988748 },
    { lat: 48.177084, lng: 17.988485 },
    { lat: 48.177459, lng: 17.988116 },
    { lat: 48.177967, lng: 17.987675 },
    { lat: 48.178414, lng: 17.987269 },
    { lat: 48.178889, lng: 17.986844 },
    { lat: 48.179673, lng: 17.986121 },
    { lat: 48.180142, lng: 17.985708 },
    { lat: 48.180927, lng: 17.984449 },
    { lat: 48.181443, lng: 17.983715 },
    { lat: 48.181971, lng: 17.982944 },
    { lat: 48.182031, lng: 17.982857 },
    { lat: 48.182647, lng: 17.981968 },
    { lat: 48.183418, lng: 17.981145 },
    { lat: 48.183713, lng: 17.980827 },
    { lat: 48.184036, lng: 17.980295 },
    { lat: 48.184681, lng: 17.979224 },
    { lat: 48.184703, lng: 17.979185 },
    { lat: 48.185617, lng: 17.977828 },
    { lat: 48.186379, lng: 17.976704 },
    { lat: 48.186463, lng: 17.976581 },
    { lat: 48.186518, lng: 17.976503 },
    { lat: 48.187064, lng: 17.975782 },
    { lat: 48.187166, lng: 17.975648 },
    { lat: 48.187519, lng: 17.975182 },
    { lat: 48.188068, lng: 17.974454 },
    { lat: 48.188591, lng: 17.973767 },
    { lat: 48.188754, lng: 17.973552 },
    { lat: 48.188885, lng: 17.973379 },
    { lat: 48.189439, lng: 17.972639 },
    { lat: 48.189327, lng: 17.972455 },
    { lat: 48.188951, lng: 17.9718 },
    { lat: 48.188788, lng: 17.971514 },
    { lat: 48.18828, lng: 17.970616 },
    { lat: 48.188261, lng: 17.970501 },
    { lat: 48.1882476, lng: 17.9703993 },
    { lat: 48.1864121, lng: 17.9675785 },
    { lat: 48.1844426, lng: 17.9648435 },
    { lat: 48.1839727, lng: 17.9637137 },
    { lat: 48.1826189, lng: 17.9615306 },
    { lat: 48.1807587, lng: 17.9594713 },
    { lat: 48.180737, lng: 17.9594487 },
    { lat: 48.1807336, lng: 17.959412 },
    { lat: 48.1804341, lng: 17.9570036 },
    { lat: 48.1793508, lng: 17.9498217 },
    { lat: 48.1787942, lng: 17.9466587 },
    { lat: 48.1785942, lng: 17.9450454 },
    { lat: 48.1774192, lng: 17.9404891 },
    { lat: 48.1768695, lng: 17.9370881 },
    { lat: 48.1766349, lng: 17.9363651 },
    { lat: 48.1760129, lng: 17.9374998 },
    { lat: 48.1735152, lng: 17.9405312 },
    { lat: 48.1683739, lng: 17.9331248 },
    { lat: 48.16673, lng: 17.9305802 },
    { lat: 48.1657873, lng: 17.9292149 },
    { lat: 48.1641335, lng: 17.9259525 },
    { lat: 48.1638871, lng: 17.9255371 },
    { lat: 48.1635372, lng: 17.9250909 },
    { lat: 48.163493, lng: 17.9246578 },
    { lat: 48.1633114, lng: 17.9241584 },
    { lat: 48.1628216, lng: 17.9233744 },
    { lat: 48.1618994, lng: 17.9217014 },
    { lat: 48.1617296, lng: 17.9212651 },
    { lat: 48.1615805, lng: 17.920702 },
    { lat: 48.1616064, lng: 17.9203488 },
    { lat: 48.1586579, lng: 17.9150414 },
    { lat: 48.1571452, lng: 17.9117822 },
    { lat: 48.1564799, lng: 17.9110516 },
    { lat: 48.1553273, lng: 17.9100087 },
    { lat: 48.151972, lng: 17.9072671 },
    { lat: 48.1497432, lng: 17.9052913 },
    { lat: 48.1489517, lng: 17.9039672 },
  ],
  Močenok: [
    { lat: 48.200891, lng: 17.876866 },
    { lat: 48.2004285, lng: 17.8772923 },
    { lat: 48.1989841, lng: 17.8782454 },
    { lat: 48.1970307, lng: 17.8782772 },
    { lat: 48.1958167, lng: 17.8777302 },
    { lat: 48.1951943, lng: 17.877986 },
    { lat: 48.1936475, lng: 17.8794065 },
    { lat: 48.1913775, lng: 17.881943 },
    { lat: 48.1907941, lng: 17.8824318 },
    { lat: 48.1902774, lng: 17.8827884 },
    { lat: 48.1900287, lng: 17.8828906 },
    { lat: 48.1888661, lng: 17.8830971 },
    { lat: 48.1885511, lng: 17.8830989 },
    { lat: 48.1874959, lng: 17.8828628 },
    { lat: 48.1860635, lng: 17.8827328 },
    { lat: 48.1850481, lng: 17.8828701 },
    { lat: 48.1848154, lng: 17.8832011 },
    { lat: 48.184301, lng: 17.8841342 },
    { lat: 48.1831854, lng: 17.8868383 },
    { lat: 48.1832064, lng: 17.8868828 },
    { lat: 48.1840624, lng: 17.8885419 },
    { lat: 48.1849793, lng: 17.8899176 },
    { lat: 48.185422, lng: 17.8904414 },
    { lat: 48.1863923, lng: 17.8920847 },
    { lat: 48.1878225, lng: 17.8936606 },
    { lat: 48.1877418, lng: 17.8942825 },
    { lat: 48.1877393, lng: 17.8950936 },
    { lat: 48.1881513, lng: 17.8961905 },
    { lat: 48.1882204, lng: 17.897774 },
    { lat: 48.1884776, lng: 17.8980475 },
    { lat: 48.1888228, lng: 17.8990105 },
    { lat: 48.1880514, lng: 17.9003209 },
    { lat: 48.1885165, lng: 17.9044846 },
    { lat: 48.1886184, lng: 17.9058097 },
    { lat: 48.1887512, lng: 17.9065466 },
    { lat: 48.1889822, lng: 17.9073957 },
    { lat: 48.1890976, lng: 17.908108 },
    { lat: 48.1893277, lng: 17.9112495 },
    { lat: 48.1894307, lng: 17.9120534 },
    { lat: 48.189521, lng: 17.913597 },
    { lat: 48.1894867, lng: 17.913944 },
    { lat: 48.1894316, lng: 17.9142837 },
    { lat: 48.1891413, lng: 17.9149495 },
    { lat: 48.1884272, lng: 17.9163163 },
    { lat: 48.1866953, lng: 17.9184351 },
    { lat: 48.1861487, lng: 17.9189329 },
    { lat: 48.1851853, lng: 17.9199481 },
    { lat: 48.1835713, lng: 17.9222066 },
    { lat: 48.1822971, lng: 17.9241483 },
    { lat: 48.1820632, lng: 17.9246646 },
    { lat: 48.1817503, lng: 17.9251448 },
    { lat: 48.1777888, lng: 17.9326538 },
    { lat: 48.176354, lng: 17.9351338 },
    { lat: 48.1767647, lng: 17.9359331 },
    { lat: 48.1766349, lng: 17.9363651 },
    { lat: 48.1768695, lng: 17.9370881 },
    { lat: 48.1774192, lng: 17.9404891 },
    { lat: 48.1785942, lng: 17.9450454 },
    { lat: 48.1787942, lng: 17.9466587 },
    { lat: 48.1793508, lng: 17.9498217 },
    { lat: 48.1804341, lng: 17.9570036 },
    { lat: 48.1807336, lng: 17.959412 },
    { lat: 48.180737, lng: 17.9594487 },
    { lat: 48.1807587, lng: 17.9594713 },
    { lat: 48.1826189, lng: 17.9615306 },
    { lat: 48.1839727, lng: 17.9637137 },
    { lat: 48.1844426, lng: 17.9648435 },
    { lat: 48.1864121, lng: 17.9675785 },
    { lat: 48.1882476, lng: 17.9703993 },
    { lat: 48.188261, lng: 17.970501 },
    { lat: 48.188421, lng: 17.970686 },
    { lat: 48.188533, lng: 17.970846 },
    { lat: 48.189263, lng: 17.971739 },
    { lat: 48.189716, lng: 17.972293 },
    { lat: 48.189743, lng: 17.972257 },
    { lat: 48.189977, lng: 17.972634 },
    { lat: 48.190485, lng: 17.973458 },
    { lat: 48.190968, lng: 17.974087 },
    { lat: 48.191542, lng: 17.974715 },
    { lat: 48.191558, lng: 17.974683 },
    { lat: 48.191601, lng: 17.974599 },
    { lat: 48.191614, lng: 17.974572 },
    { lat: 48.192588, lng: 17.975514 },
    { lat: 48.193088, lng: 17.976003 },
    { lat: 48.193413, lng: 17.976326 },
    { lat: 48.193847, lng: 17.976768 },
    { lat: 48.194145, lng: 17.977043 },
    { lat: 48.194461, lng: 17.977322 },
    { lat: 48.194752, lng: 17.977643 },
    { lat: 48.195044, lng: 17.977899 },
    { lat: 48.195196, lng: 17.977985 },
    { lat: 48.195583, lng: 17.978301 },
    { lat: 48.196103, lng: 17.978786 },
    { lat: 48.196162, lng: 17.978841 },
    { lat: 48.196466, lng: 17.979141 },
    { lat: 48.196893, lng: 17.979562 },
    { lat: 48.196974, lng: 17.979631 },
    { lat: 48.197328, lng: 17.979933 },
    { lat: 48.197945, lng: 17.980384 },
    { lat: 48.198293, lng: 17.980636 },
    { lat: 48.198491, lng: 17.980777 },
    { lat: 48.19897, lng: 17.981112 },
    { lat: 48.198999, lng: 17.981133 },
    { lat: 48.199066, lng: 17.981154 },
    { lat: 48.199104, lng: 17.981165 },
    { lat: 48.200424, lng: 17.982763 },
    { lat: 48.200677, lng: 17.983153 },
    { lat: 48.200874, lng: 17.983503 },
    { lat: 48.201096, lng: 17.98385 },
    { lat: 48.201445, lng: 17.984309 },
    { lat: 48.201792, lng: 17.984781 },
    { lat: 48.202863, lng: 17.986009 },
    { lat: 48.203015, lng: 17.986194 },
    { lat: 48.203754, lng: 17.987104 },
    { lat: 48.204827, lng: 17.98807 },
    { lat: 48.205145, lng: 17.988351 },
    { lat: 48.205825, lng: 17.988941 },
    { lat: 48.20708, lng: 17.990043 },
    { lat: 48.20764, lng: 17.990546 },
    { lat: 48.208871, lng: 17.991548 },
    { lat: 48.209478, lng: 17.991963 },
    { lat: 48.211561, lng: 17.990526 },
    { lat: 48.212522, lng: 17.989874 },
    { lat: 48.213795, lng: 17.989213 },
    { lat: 48.214717, lng: 17.989023 },
    { lat: 48.214921, lng: 17.988983 },
    { lat: 48.215554, lng: 17.988858 },
    { lat: 48.216297, lng: 17.988345 },
    { lat: 48.21827, lng: 17.98769 },
    { lat: 48.218314, lng: 17.987682 },
    { lat: 48.218399, lng: 17.987666 },
    { lat: 48.218482, lng: 17.98766 },
    { lat: 48.219594, lng: 17.987592 },
    { lat: 48.221197, lng: 17.987138 },
    { lat: 48.222291, lng: 17.986365 },
    { lat: 48.222351, lng: 17.986347 },
    { lat: 48.223217, lng: 17.986634 },
    { lat: 48.224422, lng: 17.986902 },
    { lat: 48.225863, lng: 17.986965 },
    { lat: 48.225873, lng: 17.987008 },
    { lat: 48.22641, lng: 17.986844 },
    { lat: 48.226939, lng: 17.986741 },
    { lat: 48.227601, lng: 17.986577 },
    { lat: 48.228313, lng: 17.986282 },
    { lat: 48.229348, lng: 17.985495 },
    { lat: 48.229986, lng: 17.9851 },
    { lat: 48.230592, lng: 17.984864 },
    { lat: 48.231974, lng: 17.983819 },
    { lat: 48.232364, lng: 17.983413 },
    { lat: 48.23258, lng: 17.983238 },
    { lat: 48.233062, lng: 17.982875 },
    { lat: 48.233903, lng: 17.982317 },
    { lat: 48.234258, lng: 17.982111 },
    { lat: 48.235265, lng: 17.981503 },
    { lat: 48.23604, lng: 17.981013 },
    { lat: 48.236358, lng: 17.980781 },
    { lat: 48.236847, lng: 17.980502 },
    { lat: 48.237615, lng: 17.97997 },
    { lat: 48.237627, lng: 17.980002 },
    { lat: 48.23793, lng: 17.980793 },
    { lat: 48.23795, lng: 17.980842 },
    { lat: 48.237857, lng: 17.98152 },
    { lat: 48.237803, lng: 17.98214 },
    { lat: 48.237798, lng: 17.982394 },
    { lat: 48.237913, lng: 17.983516 },
    { lat: 48.237901, lng: 17.98373 },
    { lat: 48.237824, lng: 17.98403 },
    { lat: 48.237463, lng: 17.98505 },
    { lat: 48.23739, lng: 17.985277 },
    { lat: 48.237353, lng: 17.985572 },
    { lat: 48.237343, lng: 17.985835 },
    { lat: 48.237352, lng: 17.986272 },
    { lat: 48.237378, lng: 17.986626 },
    { lat: 48.237384, lng: 17.986719 },
    { lat: 48.237386, lng: 17.986827 },
    { lat: 48.237306, lng: 17.987191 },
    { lat: 48.237095, lng: 17.987559 },
    { lat: 48.23702, lng: 17.987717 },
    { lat: 48.23696, lng: 17.98826 },
    { lat: 48.236974, lng: 17.988718 },
    { lat: 48.236943, lng: 17.989029 },
    { lat: 48.236773, lng: 17.989851 },
    { lat: 48.236755, lng: 17.99006 },
    { lat: 48.236775, lng: 17.990227 },
    { lat: 48.236792, lng: 17.990451 },
    { lat: 48.236813, lng: 17.990426 },
    { lat: 48.238896, lng: 17.988893 },
    { lat: 48.24624, lng: 17.984667 },
    { lat: 48.246358, lng: 17.9846 },
    { lat: 48.252241, lng: 17.981174 },
    { lat: 48.252299, lng: 17.98114 },
    { lat: 48.252325, lng: 17.981124 },
    { lat: 48.25237, lng: 17.981098 },
    { lat: 48.252418, lng: 17.981071 },
    { lat: 48.25149, lng: 17.979235 },
    { lat: 48.250903, lng: 17.978054 },
    { lat: 48.250782, lng: 17.977784 },
    { lat: 48.250769, lng: 17.977756 },
    { lat: 48.250732, lng: 17.97767 },
    { lat: 48.250717, lng: 17.977639 },
    { lat: 48.249943, lng: 17.975901 },
    { lat: 48.2478552, lng: 17.9728856 },
    { lat: 48.2475639, lng: 17.9717013 },
    { lat: 48.247473, lng: 17.9707712 },
    { lat: 48.2471696, lng: 17.9696658 },
    { lat: 48.2471317, lng: 17.9689984 },
    { lat: 48.2468429, lng: 17.9680617 },
    { lat: 48.2466938, lng: 17.9673087 },
    { lat: 48.2465658, lng: 17.9653485 },
    { lat: 48.2466777, lng: 17.9640748 },
    { lat: 48.2464914, lng: 17.9626087 },
    { lat: 48.2464242, lng: 17.9616808 },
    { lat: 48.2463072, lng: 17.9613863 },
    { lat: 48.2463213, lng: 17.961019 },
    { lat: 48.2462412, lng: 17.9604193 },
    { lat: 48.2457517, lng: 17.9581378 },
    { lat: 48.243878, lng: 17.9470813 },
    { lat: 48.2438041, lng: 17.9461373 },
    { lat: 48.2437954, lng: 17.945 },
    { lat: 48.2436269, lng: 17.9431244 },
    { lat: 48.2429202, lng: 17.9376969 },
    { lat: 48.2427631, lng: 17.9360662 },
    { lat: 48.2428854, lng: 17.9355287 },
    { lat: 48.2423064, lng: 17.9324081 },
    { lat: 48.2410831, lng: 17.9228749 },
    { lat: 48.240665, lng: 17.9218604 },
    { lat: 48.2406915, lng: 17.9218327 },
    { lat: 48.2400458, lng: 17.9196289 },
    { lat: 48.2397317, lng: 17.9198875 },
    { lat: 48.2396713, lng: 17.9197098 },
    { lat: 48.2395575, lng: 17.9196753 },
    { lat: 48.2394259, lng: 17.9197403 },
    { lat: 48.2389677, lng: 17.9185677 },
    { lat: 48.2377361, lng: 17.9195882 },
    { lat: 48.2373339, lng: 17.918504 },
    { lat: 48.2367907, lng: 17.9189824 },
    { lat: 48.235828, lng: 17.9163931 },
    { lat: 48.2377084, lng: 17.9148451 },
    { lat: 48.2367408, lng: 17.9128289 },
    { lat: 48.2359719, lng: 17.9108698 },
    { lat: 48.2323948, lng: 17.9054349 },
    { lat: 48.2312678, lng: 17.9036235 },
    { lat: 48.2271056, lng: 17.8974481 },
    { lat: 48.2256691, lng: 17.8986843 },
    { lat: 48.22513, lng: 17.898209 },
    { lat: 48.2235772, lng: 17.8970811 },
    { lat: 48.2218561, lng: 17.8961584 },
    { lat: 48.2191802, lng: 17.8931751 },
    { lat: 48.217672, lng: 17.8913749 },
    { lat: 48.2157755, lng: 17.8893506 },
    { lat: 48.2157172, lng: 17.8892946 },
    { lat: 48.2131162, lng: 17.8869773 },
    { lat: 48.2100531, lng: 17.8848004 },
    { lat: 48.2090983, lng: 17.8833189 },
    { lat: 48.2087708, lng: 17.8825007 },
    { lat: 48.208562, lng: 17.8821332 },
    { lat: 48.2086002, lng: 17.8820921 },
    { lat: 48.208291, lng: 17.881636 },
    { lat: 48.207741, lng: 17.880813 },
    { lat: 48.207506, lng: 17.880568 },
    { lat: 48.207228, lng: 17.880156 },
    { lat: 48.207095, lng: 17.879939 },
    { lat: 48.206934, lng: 17.879744 },
    { lat: 48.206795, lng: 17.879645 },
    { lat: 48.206625, lng: 17.879592 },
    { lat: 48.206303, lng: 17.879506 },
    { lat: 48.205724, lng: 17.879257 },
    { lat: 48.204859, lng: 17.878889 },
    { lat: 48.204141, lng: 17.878604 },
    { lat: 48.203889, lng: 17.878495 },
    { lat: 48.20354, lng: 17.878354 },
    { lat: 48.203158, lng: 17.878133 },
    { lat: 48.202947, lng: 17.87802 },
    { lat: 48.202216, lng: 17.877661 },
    { lat: 48.201511, lng: 17.877406 },
    { lat: 48.201292, lng: 17.877277 },
    { lat: 48.201149, lng: 17.877135 },
    { lat: 48.200899, lng: 17.8769 },
    { lat: 48.200891, lng: 17.876866 },
  ],
  Neded: [
    { lat: 48.013439, lng: 18.012008 },
    { lat: 48.013669, lng: 18.012269 },
    { lat: 48.014307, lng: 18.012849 },
    { lat: 48.014999, lng: 18.013654 },
    { lat: 48.015647, lng: 18.014444 },
    { lat: 48.016375, lng: 18.015347 },
    { lat: 48.017, lng: 18.016081 },
    { lat: 48.017033, lng: 18.01612 },
    { lat: 48.01733, lng: 18.016468 },
    { lat: 48.017895, lng: 18.017186 },
    { lat: 48.019142, lng: 18.018822 },
    { lat: 48.019262, lng: 18.019035 },
    { lat: 48.019329, lng: 18.018971 },
    { lat: 48.021435, lng: 18.016745 },
    { lat: 48.022048, lng: 18.016097 },
    { lat: 48.022107, lng: 18.016034 },
    { lat: 48.022111, lng: 18.016029 },
    { lat: 48.022125, lng: 18.016015 },
    { lat: 48.02219, lng: 18.015945 },
    { lat: 48.022211, lng: 18.015923 },
    { lat: 48.02222, lng: 18.015913 },
    { lat: 48.024824, lng: 18.013177 },
    { lat: 48.025652, lng: 18.012301 },
    { lat: 48.027971, lng: 18.009891 },
    { lat: 48.02978, lng: 18.007985 },
    { lat: 48.031267, lng: 18.00641 },
    { lat: 48.031481, lng: 18.006181 },
    { lat: 48.032774, lng: 18.004804 },
    { lat: 48.033031, lng: 18.004537 },
    { lat: 48.03392, lng: 18.003609 },
    { lat: 48.035423, lng: 18.004028 },
    { lat: 48.037907, lng: 18.004726 },
    { lat: 48.038385, lng: 18.00486 },
    { lat: 48.040678, lng: 18.00547 },
    { lat: 48.041158, lng: 18.005605 },
    { lat: 48.041391, lng: 18.005671 },
    { lat: 48.041521, lng: 18.005707 },
    { lat: 48.041733, lng: 18.005767 },
    { lat: 48.041849, lng: 18.0058 },
    { lat: 48.041914, lng: 18.005819 },
    { lat: 48.043001, lng: 18.006138 },
    { lat: 48.043016, lng: 18.006142 },
    { lat: 48.043043, lng: 18.006149 },
    { lat: 48.043162, lng: 18.006183 },
    { lat: 48.043176, lng: 18.006187 },
    { lat: 48.043696, lng: 18.006335 },
    { lat: 48.043717, lng: 18.006341 },
    { lat: 48.043638, lng: 18.006129 },
    { lat: 48.043606, lng: 18.006039 },
    { lat: 48.043503, lng: 18.005774 },
    { lat: 48.043459, lng: 18.005664 },
    { lat: 48.043399, lng: 18.005508 },
    { lat: 48.04339, lng: 18.005483 },
    { lat: 48.043307, lng: 18.005262 },
    { lat: 48.043241, lng: 18.005085 },
    { lat: 48.042719, lng: 18.003707 },
    { lat: 48.04338, lng: 18.002444 },
    { lat: 48.044204, lng: 18.001654 },
    { lat: 48.045088, lng: 18.000684 },
    { lat: 48.046191, lng: 17.999335 },
    { lat: 48.046217, lng: 17.999306 },
    { lat: 48.046388, lng: 17.999105 },
    { lat: 48.046711, lng: 17.998711 },
    { lat: 48.046739, lng: 17.998679 },
    { lat: 48.047221, lng: 17.9981 },
    { lat: 48.0463224, lng: 17.9966522 },
    { lat: 48.0411767, lng: 17.9887263 },
    { lat: 48.0402431, lng: 17.9864331 },
    { lat: 48.0394438, lng: 17.9837666 },
    { lat: 48.0390874, lng: 17.9823647 },
    { lat: 48.0373536, lng: 17.9777914 },
    { lat: 48.0363922, lng: 17.9755873 },
    { lat: 48.0354173, lng: 17.9731594 },
    { lat: 48.0345371, lng: 17.9713998 },
    { lat: 48.0336376, lng: 17.9697921 },
    { lat: 48.0314892, lng: 17.9660238 },
    { lat: 48.0303051, lng: 17.9642479 },
    { lat: 48.0299498, lng: 17.9634676 },
    { lat: 48.0292595, lng: 17.9627093 },
    { lat: 48.0287069, lng: 17.9618385 },
    { lat: 48.0279593, lng: 17.9615506 },
    { lat: 48.0275403, lng: 17.9610988 },
    { lat: 48.0271506, lng: 17.9602738 },
    { lat: 48.0260047, lng: 17.9584186 },
    { lat: 48.0252205, lng: 17.9564252 },
    { lat: 48.024693, lng: 17.9555365 },
    { lat: 48.0239656, lng: 17.9543371 },
    { lat: 48.0230522, lng: 17.952896 },
    { lat: 48.0226788, lng: 17.952193 },
    { lat: 48.0207619, lng: 17.9492215 },
    { lat: 48.0197459, lng: 17.9481372 },
    { lat: 48.0186032, lng: 17.9466748 },
    { lat: 48.0182668, lng: 17.9455771 },
    { lat: 48.0174822, lng: 17.9435064 },
    { lat: 48.0163329, lng: 17.941421 },
    { lat: 48.015036, lng: 17.9391621 },
    { lat: 48.0134606, lng: 17.9365815 },
    { lat: 48.0133626, lng: 17.9364314 },
    { lat: 48.0106924, lng: 17.9324018 },
    { lat: 48.0098482, lng: 17.9310471 },
    { lat: 48.0077085, lng: 17.9279887 },
    { lat: 48.0054718, lng: 17.9246701 },
    { lat: 48.0042441, lng: 17.9222437 },
    { lat: 48.0009411, lng: 17.9211263 },
    { lat: 47.9986774, lng: 17.9205617 },
    { lat: 47.9937639, lng: 17.9194491 },
    { lat: 47.9907632, lng: 17.918961 },
    { lat: 47.99072, lng: 17.918954 },
    { lat: 47.9900552, lng: 17.9140215 },
    { lat: 47.9894961, lng: 17.9108056 },
    { lat: 47.9883286, lng: 17.9062788 },
    { lat: 47.987075, lng: 17.9019731 },
    { lat: 47.9855652, lng: 17.8979488 },
    { lat: 47.9862176, lng: 17.8959139 },
    { lat: 47.9862764, lng: 17.8938997 },
    { lat: 47.9864363, lng: 17.8913235 },
    { lat: 47.9868259, lng: 17.8889776 },
    { lat: 47.9873308, lng: 17.8865442 },
    { lat: 47.9877781, lng: 17.8839634 },
    { lat: 47.9880563, lng: 17.8820515 },
    { lat: 47.987985, lng: 17.880529 },
    { lat: 47.987889, lng: 17.880516 },
    { lat: 47.986478, lng: 17.880356 },
    { lat: 47.986274, lng: 17.880294 },
    { lat: 47.98627, lng: 17.880315 },
    { lat: 47.986243, lng: 17.880502 },
    { lat: 47.986229, lng: 17.880605 },
    { lat: 47.986219, lng: 17.880676 },
    { lat: 47.986219, lng: 17.880678 },
    { lat: 47.986152, lng: 17.880939 },
    { lat: 47.986126, lng: 17.881035 },
    { lat: 47.986122, lng: 17.881046 },
    { lat: 47.986069, lng: 17.881179 },
    { lat: 47.986021, lng: 17.8813 },
    { lat: 47.986011, lng: 17.881315 },
    { lat: 47.985986, lng: 17.881351 },
    { lat: 47.985932, lng: 17.881433 },
    { lat: 47.985865, lng: 17.881533 },
    { lat: 47.985798, lng: 17.881635 },
    { lat: 47.985509, lng: 17.882069 },
    { lat: 47.985503, lng: 17.882077 },
    { lat: 47.985463, lng: 17.882169 },
    { lat: 47.985417, lng: 17.882256 },
    { lat: 47.985342, lng: 17.882399 },
    { lat: 47.98527, lng: 17.882536 },
    { lat: 47.985221, lng: 17.882632 },
    { lat: 47.985149, lng: 17.882768 },
    { lat: 47.985144, lng: 17.882779 },
    { lat: 47.985069, lng: 17.882943 },
    { lat: 47.984769, lng: 17.883598 },
    { lat: 47.984571, lng: 17.884032 },
    { lat: 47.984566, lng: 17.884046 },
    { lat: 47.984311, lng: 17.884874 },
    { lat: 47.984293, lng: 17.884932 },
    { lat: 47.984267, lng: 17.884996 },
    { lat: 47.984051, lng: 17.88554 },
    { lat: 47.983964, lng: 17.885703 },
    { lat: 47.983885, lng: 17.885849 },
    { lat: 47.983854, lng: 17.885884 },
    { lat: 47.983737, lng: 17.886015 },
    { lat: 47.98363, lng: 17.886136 },
    { lat: 47.983521, lng: 17.886258 },
    { lat: 47.983421, lng: 17.886371 },
    { lat: 47.983325, lng: 17.886546 },
    { lat: 47.983218, lng: 17.886702 },
    { lat: 47.98313, lng: 17.886941 },
    { lat: 47.983088, lng: 17.887168 },
    { lat: 47.983088, lng: 17.887182 },
    { lat: 47.983084, lng: 17.887241 },
    { lat: 47.983076, lng: 17.887355 },
    { lat: 47.983077, lng: 17.887542 },
    { lat: 47.983079, lng: 17.887809 },
    { lat: 47.983005, lng: 17.888119 },
    { lat: 47.982954, lng: 17.888221 },
    { lat: 47.982898, lng: 17.888335 },
    { lat: 47.982861, lng: 17.888386 },
    { lat: 47.982774, lng: 17.88851 },
    { lat: 47.98277, lng: 17.888516 },
    { lat: 47.982566, lng: 17.888717 },
    { lat: 47.982328, lng: 17.888951 },
    { lat: 47.982279, lng: 17.889043 },
    { lat: 47.982215, lng: 17.889161 },
    { lat: 47.982222, lng: 17.889174 },
    { lat: 47.98251, lng: 17.889641 },
    { lat: 47.982507, lng: 17.88965 },
    { lat: 47.982504, lng: 17.889666 },
    { lat: 47.982486, lng: 17.889736 },
    { lat: 47.982447, lng: 17.889887 },
    { lat: 47.982403, lng: 17.890039 },
    { lat: 47.982314, lng: 17.890345 },
    { lat: 47.98221, lng: 17.890561 },
    { lat: 47.981525, lng: 17.891624 },
    { lat: 47.981541, lng: 17.891654 },
    { lat: 47.981548, lng: 17.891669 },
    { lat: 47.981543, lng: 17.891689 },
    { lat: 47.98147, lng: 17.891956 },
    { lat: 47.981461, lng: 17.891989 },
    { lat: 47.981404, lng: 17.892269 },
    { lat: 47.981375, lng: 17.892417 },
    { lat: 47.981326, lng: 17.892654 },
    { lat: 47.981307, lng: 17.892723 },
    { lat: 47.981274, lng: 17.89284 },
    { lat: 47.981232, lng: 17.892989 },
    { lat: 47.980977, lng: 17.893268 },
    { lat: 47.980914, lng: 17.893337 },
    { lat: 47.980863, lng: 17.893392 },
    { lat: 47.980809, lng: 17.893458 },
    { lat: 47.980722, lng: 17.893565 },
    { lat: 47.980671, lng: 17.893651 },
    { lat: 47.980574, lng: 17.893811 },
    { lat: 47.980603, lng: 17.893847 },
    { lat: 47.980647, lng: 17.893902 },
    { lat: 47.980589, lng: 17.894057 },
    { lat: 47.980517, lng: 17.894248 },
    { lat: 47.980441, lng: 17.894448 },
    { lat: 47.980411, lng: 17.894526 },
    { lat: 47.980343, lng: 17.894765 },
    { lat: 47.980186, lng: 17.895325 },
    { lat: 47.980043, lng: 17.89567 },
    { lat: 47.980024, lng: 17.895708 },
    { lat: 47.979969, lng: 17.895817 },
    { lat: 47.97992, lng: 17.895895 },
    { lat: 47.979612, lng: 17.896375 },
    { lat: 47.97956, lng: 17.896512 },
    { lat: 47.979556, lng: 17.896549 },
    { lat: 47.979544, lng: 17.89668 },
    { lat: 47.979567, lng: 17.896748 },
    { lat: 47.979604, lng: 17.896796 },
    { lat: 47.979608, lng: 17.896798 },
    { lat: 47.97965, lng: 17.89682 },
    { lat: 47.979692, lng: 17.896843 },
    { lat: 47.97971, lng: 17.896853 },
    { lat: 47.97975, lng: 17.896927 },
    { lat: 47.979786, lng: 17.896993 },
    { lat: 47.979835, lng: 17.897059 },
    { lat: 47.979977, lng: 17.897249 },
    { lat: 47.980023, lng: 17.897277 },
    { lat: 47.980094, lng: 17.897319 },
    { lat: 47.980109, lng: 17.897391 },
    { lat: 47.980123, lng: 17.897451 },
    { lat: 47.980125, lng: 17.897513 },
    { lat: 47.980129, lng: 17.897625 },
    { lat: 47.980148, lng: 17.897683 },
    { lat: 47.980181, lng: 17.89779 },
    { lat: 47.98021, lng: 17.897848 },
    { lat: 47.98026, lng: 17.897946 },
    { lat: 47.980305, lng: 17.897983 },
    { lat: 47.980358, lng: 17.898028 },
    { lat: 47.980392, lng: 17.898021 },
    { lat: 47.980466, lng: 17.898004 },
    { lat: 47.980514, lng: 17.898043 },
    { lat: 47.980567, lng: 17.898087 },
    { lat: 47.980598, lng: 17.898147 },
    { lat: 47.980648, lng: 17.898243 },
    { lat: 47.980654, lng: 17.898305 },
    { lat: 47.980677, lng: 17.898511 },
    { lat: 47.98068, lng: 17.898541 },
    { lat: 47.980693, lng: 17.898752 },
    { lat: 47.980686, lng: 17.898786 },
    { lat: 47.980591, lng: 17.899285 },
    { lat: 47.98059, lng: 17.899307 },
    { lat: 47.980567, lng: 17.899655 },
    { lat: 47.980566, lng: 17.89968 },
    { lat: 47.980567, lng: 17.899862 },
    { lat: 47.980556, lng: 17.89993 },
    { lat: 47.980385, lng: 17.90005 },
    { lat: 47.980374, lng: 17.900058 },
    { lat: 47.980267, lng: 17.900098 },
    { lat: 47.980254, lng: 17.900105 },
    { lat: 47.980205, lng: 17.900133 },
    { lat: 47.980148, lng: 17.900193 },
    { lat: 47.98009, lng: 17.900334 },
    { lat: 47.980031, lng: 17.90039 },
    { lat: 47.980017, lng: 17.900402 },
    { lat: 47.97982, lng: 17.900435 },
    { lat: 47.979795, lng: 17.900439 },
    { lat: 47.979568, lng: 17.900609 },
    { lat: 47.979546, lng: 17.900626 },
    { lat: 47.979374, lng: 17.900662 },
    { lat: 47.979289, lng: 17.90068 },
    { lat: 47.979273, lng: 17.900684 },
    { lat: 47.979184, lng: 17.90073 },
    { lat: 47.979152, lng: 17.900748 },
    { lat: 47.97901, lng: 17.900851 },
    { lat: 47.978988, lng: 17.900866 },
    { lat: 47.978765, lng: 17.900971 },
    { lat: 47.978752, lng: 17.900977 },
    { lat: 47.978734, lng: 17.900985 },
    { lat: 47.978444, lng: 17.901077 },
    { lat: 47.978366, lng: 17.90136 },
    { lat: 47.978354, lng: 17.901553 },
    { lat: 47.978347, lng: 17.901671 },
    { lat: 47.97834, lng: 17.901768 },
    { lat: 47.978336, lng: 17.901832 },
    { lat: 47.978337, lng: 17.901859 },
    { lat: 47.978337, lng: 17.90188 },
    { lat: 47.978337, lng: 17.901918 },
    { lat: 47.978338, lng: 17.901984 },
    { lat: 47.978337, lng: 17.902051 },
    { lat: 47.978338, lng: 17.902115 },
    { lat: 47.978358, lng: 17.902147 },
    { lat: 47.978497, lng: 17.902373 },
    { lat: 47.978519, lng: 17.902411 },
    { lat: 47.978581, lng: 17.90251 },
    { lat: 47.978588, lng: 17.902524 },
    { lat: 47.97861, lng: 17.902566 },
    { lat: 47.978795, lng: 17.902913 },
    { lat: 47.978904, lng: 17.90315 },
    { lat: 47.979004, lng: 17.903367 },
    { lat: 47.97901, lng: 17.903394 },
    { lat: 47.979028, lng: 17.903469 },
    { lat: 47.979074, lng: 17.903675 },
    { lat: 47.979182, lng: 17.904099 },
    { lat: 47.979228, lng: 17.904279 },
    { lat: 47.979284, lng: 17.904502 },
    { lat: 47.979359, lng: 17.904757 },
    { lat: 47.979386, lng: 17.904856 },
    { lat: 47.979336, lng: 17.905506 },
    { lat: 47.979303, lng: 17.905775 },
    { lat: 47.979293, lng: 17.905872 },
    { lat: 47.979025, lng: 17.90685 },
    { lat: 47.979031, lng: 17.906988 },
    { lat: 47.979208, lng: 17.907356 },
    { lat: 47.979225, lng: 17.907446 },
    { lat: 47.979187, lng: 17.907889 },
    { lat: 47.979185, lng: 17.90792 },
    { lat: 47.979181, lng: 17.907987 },
    { lat: 47.979192, lng: 17.908165 },
    { lat: 47.979222, lng: 17.908238 },
    { lat: 47.979253, lng: 17.908316 },
    { lat: 47.979252, lng: 17.908418 },
    { lat: 47.979122, lng: 17.908699 },
    { lat: 47.97908, lng: 17.908836 },
    { lat: 47.979078, lng: 17.908843 },
    { lat: 47.97907, lng: 17.909077 },
    { lat: 47.979072, lng: 17.909188 },
    { lat: 47.979082, lng: 17.909628 },
    { lat: 47.979082, lng: 17.909646 },
    { lat: 47.97909, lng: 17.909696 },
    { lat: 47.979113, lng: 17.909855 },
    { lat: 47.979192, lng: 17.909906 },
    { lat: 47.979273, lng: 17.909883 },
    { lat: 47.979343, lng: 17.909913 },
    { lat: 47.979353, lng: 17.909918 },
    { lat: 47.979375, lng: 17.909934 },
    { lat: 47.979434, lng: 17.909974 },
    { lat: 47.979439, lng: 17.909976 },
    { lat: 47.979442, lng: 17.909981 },
    { lat: 47.979572, lng: 17.910113 },
    { lat: 47.979641, lng: 17.910184 },
    { lat: 47.979717, lng: 17.910262 },
    { lat: 47.97973, lng: 17.910276 },
    { lat: 47.979733, lng: 17.910279 },
    { lat: 47.979794, lng: 17.910412 },
    { lat: 47.979795, lng: 17.910415 },
    { lat: 47.979849, lng: 17.910565 },
    { lat: 47.979853, lng: 17.910576 },
    { lat: 47.979904, lng: 17.910723 },
    { lat: 47.979955, lng: 17.910872 },
    { lat: 47.980199, lng: 17.911582 },
    { lat: 47.9802, lng: 17.911663 },
    { lat: 47.9802, lng: 17.912293 },
    { lat: 47.9802, lng: 17.912738 },
    { lat: 47.9802, lng: 17.912945 },
    { lat: 47.980215, lng: 17.913212 },
    { lat: 47.980232, lng: 17.913518 },
    { lat: 47.980242, lng: 17.913688 },
    { lat: 47.980273, lng: 17.914235 },
    { lat: 47.980277, lng: 17.914307 },
    { lat: 47.980298, lng: 17.914672 },
    { lat: 47.980303, lng: 17.914767 },
    { lat: 47.980307, lng: 17.91483 },
    { lat: 47.980329, lng: 17.915421 },
    { lat: 47.980334, lng: 17.915582 },
    { lat: 47.980339, lng: 17.915705 },
    { lat: 47.980351, lng: 17.91602 },
    { lat: 47.980365, lng: 17.916381 },
    { lat: 47.980382, lng: 17.91685 },
    { lat: 47.980394, lng: 17.917176 },
    { lat: 47.980408, lng: 17.917538 },
    { lat: 47.980427, lng: 17.918052 },
    { lat: 47.980441, lng: 17.918564 },
    { lat: 47.980455, lng: 17.919057 },
    { lat: 47.980468, lng: 17.919507 },
    { lat: 47.980496, lng: 17.920496 },
    { lat: 47.980497, lng: 17.92059 },
    { lat: 47.980498, lng: 17.920687 },
    { lat: 47.980498, lng: 17.920703 },
    { lat: 47.9805, lng: 17.920769 },
    { lat: 47.980501, lng: 17.920866 },
    { lat: 47.980474, lng: 17.920937 },
    { lat: 47.980455, lng: 17.920984 },
    { lat: 47.980159, lng: 17.921752 },
    { lat: 47.980132, lng: 17.921821 },
    { lat: 47.97969, lng: 17.922962 },
    { lat: 47.979674, lng: 17.923004 },
    { lat: 47.979658, lng: 17.923044 },
    { lat: 47.979441, lng: 17.923605 },
    { lat: 47.979387, lng: 17.923742 },
    { lat: 47.979287, lng: 17.924001 },
    { lat: 47.97927, lng: 17.924029 },
    { lat: 47.978942, lng: 17.924563 },
    { lat: 47.978435, lng: 17.925391 },
    { lat: 47.97803, lng: 17.926052 },
    { lat: 47.977892, lng: 17.926277 },
    { lat: 47.977023, lng: 17.927586 },
    { lat: 47.976756, lng: 17.927987 },
    { lat: 47.976388, lng: 17.928745 },
    { lat: 47.976346, lng: 17.928832 },
    { lat: 47.976297, lng: 17.928932 },
    { lat: 47.976289, lng: 17.928948 },
    { lat: 47.975896, lng: 17.929756 },
    { lat: 47.97586, lng: 17.92983 },
    { lat: 47.975644, lng: 17.930275 },
    { lat: 47.975617, lng: 17.930332 },
    { lat: 47.975588, lng: 17.930388 },
    { lat: 47.975546, lng: 17.930477 },
    { lat: 47.975476, lng: 17.930619 },
    { lat: 47.975425, lng: 17.930726 },
    { lat: 47.975424, lng: 17.930728 },
    { lat: 47.975399, lng: 17.930772 },
    { lat: 47.975319, lng: 17.93092 },
    { lat: 47.975308, lng: 17.930941 },
    { lat: 47.975236, lng: 17.931074 },
    { lat: 47.975001, lng: 17.931509 },
    { lat: 47.974718, lng: 17.93203 },
    { lat: 47.974705, lng: 17.932053 },
    { lat: 47.974701, lng: 17.932062 },
    { lat: 47.974645, lng: 17.932162 },
    { lat: 47.974579, lng: 17.932282 },
    { lat: 47.974543, lng: 17.93235 },
    { lat: 47.974151, lng: 17.933069 },
    { lat: 47.973939, lng: 17.933457 },
    { lat: 47.973891, lng: 17.933547 },
    { lat: 47.973845, lng: 17.933631 },
    { lat: 47.973601, lng: 17.934079 },
    { lat: 47.973559, lng: 17.934155 },
    { lat: 47.973556, lng: 17.934173 },
    { lat: 47.973422, lng: 17.934829 },
    { lat: 47.973398, lng: 17.934948 },
    { lat: 47.973379, lng: 17.935043 },
    { lat: 47.973357, lng: 17.935148 },
    { lat: 47.97331, lng: 17.935376 },
    { lat: 47.973301, lng: 17.935418 },
    { lat: 47.973139, lng: 17.936211 },
    { lat: 47.973104, lng: 17.936381 },
    { lat: 47.973091, lng: 17.936442 },
    { lat: 47.973084, lng: 17.936478 },
    { lat: 47.973059, lng: 17.936618 },
    { lat: 47.972867, lng: 17.937638 },
    { lat: 47.972798, lng: 17.938006 },
    { lat: 47.972718, lng: 17.938432 },
    { lat: 47.972684, lng: 17.938607 },
    { lat: 47.972681, lng: 17.938626 },
    { lat: 47.972349, lng: 17.939535 },
    { lat: 47.972341, lng: 17.939555 },
    { lat: 47.972294, lng: 17.939683 },
    { lat: 47.9722, lng: 17.939943 },
    { lat: 47.972013, lng: 17.940454 },
    { lat: 47.971923, lng: 17.9407 },
    { lat: 47.971906, lng: 17.940745 },
    { lat: 47.971745, lng: 17.941186 },
    { lat: 47.971709, lng: 17.941285 },
    { lat: 47.971612, lng: 17.94155 },
    { lat: 47.971498, lng: 17.941863 },
    { lat: 47.971355, lng: 17.942378 },
    { lat: 47.97132, lng: 17.942506 },
    { lat: 47.971302, lng: 17.942569 },
    { lat: 47.971212, lng: 17.942899 },
    { lat: 47.971123, lng: 17.943219 },
    { lat: 47.971062, lng: 17.943439 },
    { lat: 47.970943, lng: 17.94387 },
    { lat: 47.970865, lng: 17.944149 },
    { lat: 47.970788, lng: 17.94443 },
    { lat: 47.970776, lng: 17.944473 },
    { lat: 47.970753, lng: 17.944553 },
    { lat: 47.970717, lng: 17.944687 },
    { lat: 47.970681, lng: 17.944816 },
    { lat: 47.970644, lng: 17.94495 },
    { lat: 47.97064, lng: 17.944968 },
    { lat: 47.970636, lng: 17.94498 },
    { lat: 47.970619, lng: 17.945032 },
    { lat: 47.970617, lng: 17.945038 },
    { lat: 47.970607, lng: 17.945066 },
    { lat: 47.970604, lng: 17.945077 },
    { lat: 47.970582, lng: 17.945161 },
    { lat: 47.97058, lng: 17.945168 },
    { lat: 47.970571, lng: 17.945202 },
    { lat: 47.970564, lng: 17.945229 },
    { lat: 47.970536, lng: 17.945331 },
    { lat: 47.97046, lng: 17.945606 },
    { lat: 47.970419, lng: 17.945758 },
    { lat: 47.970297, lng: 17.946205 },
    { lat: 47.970264, lng: 17.946323 },
    { lat: 47.970122, lng: 17.946842 },
    { lat: 47.970065, lng: 17.947052 },
    { lat: 47.970044, lng: 17.947117 },
    { lat: 47.970042, lng: 17.947124 },
    { lat: 47.969971, lng: 17.947351 },
    { lat: 47.96938, lng: 17.949235 },
    { lat: 47.969343, lng: 17.949353 },
    { lat: 47.969237, lng: 17.949691 },
    { lat: 47.9692, lng: 17.94981 },
    { lat: 47.969191, lng: 17.949841 },
    { lat: 47.969158, lng: 17.949934 },
    { lat: 47.969082, lng: 17.950151 },
    { lat: 47.969021, lng: 17.950322 },
    { lat: 47.968978, lng: 17.950444 },
    { lat: 47.968953, lng: 17.950517 },
    { lat: 47.968853, lng: 17.950802 },
    { lat: 47.968842, lng: 17.950832 },
    { lat: 47.968744, lng: 17.951111 },
    { lat: 47.968555, lng: 17.951649 },
    { lat: 47.968524, lng: 17.951769 },
    { lat: 47.968489, lng: 17.951909 },
    { lat: 47.96841, lng: 17.952221 },
    { lat: 47.968369, lng: 17.952385 },
    { lat: 47.96835, lng: 17.952454 },
    { lat: 47.96831, lng: 17.952592 },
    { lat: 47.968279, lng: 17.9527 },
    { lat: 47.968263, lng: 17.952755 },
    { lat: 47.968245, lng: 17.952818 },
    { lat: 47.968209, lng: 17.952943 },
    { lat: 47.968181, lng: 17.953038 },
    { lat: 47.968164, lng: 17.953102 },
    { lat: 47.968077, lng: 17.953401 },
    { lat: 47.968051, lng: 17.953489 },
    { lat: 47.96799, lng: 17.9537 },
    { lat: 47.967881, lng: 17.954076 },
    { lat: 47.96783, lng: 17.95425 },
    { lat: 47.967716, lng: 17.954649 },
    { lat: 47.967682, lng: 17.954763 },
    { lat: 47.967517, lng: 17.955334 },
    { lat: 47.967506, lng: 17.955371 },
    { lat: 47.967478, lng: 17.955466 },
    { lat: 47.967411, lng: 17.955705 },
    { lat: 47.967369, lng: 17.95586 },
    { lat: 47.967319, lng: 17.956037 },
    { lat: 47.967189, lng: 17.956502 },
    { lat: 47.967181, lng: 17.956527 },
    { lat: 47.967164, lng: 17.956592 },
    { lat: 47.967144, lng: 17.95666 },
    { lat: 47.967133, lng: 17.956703 },
    { lat: 47.967124, lng: 17.956736 },
    { lat: 47.967118, lng: 17.956755 },
    { lat: 47.967077, lng: 17.956904 },
    { lat: 47.966991, lng: 17.957208 },
    { lat: 47.966936, lng: 17.957409 },
    { lat: 47.966915, lng: 17.957482 },
    { lat: 47.966911, lng: 17.957499 },
    { lat: 47.966895, lng: 17.957555 },
    { lat: 47.966874, lng: 17.957627 },
    { lat: 47.966822, lng: 17.957813 },
    { lat: 47.966764, lng: 17.958021 },
    { lat: 47.966661, lng: 17.958382 },
    { lat: 47.966619, lng: 17.958534 },
    { lat: 47.966606, lng: 17.958579 },
    { lat: 47.966573, lng: 17.958693 },
    { lat: 47.966476, lng: 17.959034 },
    { lat: 47.966305, lng: 17.959636 },
    { lat: 47.966243, lng: 17.959828 },
    { lat: 47.966205, lng: 17.959945 },
    { lat: 47.966169, lng: 17.960057 },
    { lat: 47.966151, lng: 17.960114 },
    { lat: 47.966132, lng: 17.960171 },
    { lat: 47.966113, lng: 17.960232 },
    { lat: 47.966051, lng: 17.960422 },
    { lat: 47.965967, lng: 17.960686 },
    { lat: 47.965948, lng: 17.960746 },
    { lat: 47.965927, lng: 17.960812 },
    { lat: 47.965807, lng: 17.961183 },
    { lat: 47.965687, lng: 17.961555 },
    { lat: 47.96567, lng: 17.961609 },
    { lat: 47.965496, lng: 17.962148 },
    { lat: 47.965495, lng: 17.962154 },
    { lat: 47.964776, lng: 17.964673 },
    { lat: 47.964277, lng: 17.966266 },
    { lat: 47.964264, lng: 17.966308 },
    { lat: 47.964262, lng: 17.966315 },
    { lat: 47.96426, lng: 17.966322 },
    { lat: 47.964248, lng: 17.966359 },
    { lat: 47.964214, lng: 17.966469 },
    { lat: 47.964204, lng: 17.966501 },
    { lat: 47.964196, lng: 17.966524 },
    { lat: 47.964147, lng: 17.96668 },
    { lat: 47.964071, lng: 17.966928 },
    { lat: 47.96398, lng: 17.96722 },
    { lat: 47.963945, lng: 17.96733 },
    { lat: 47.963903, lng: 17.967464 },
    { lat: 47.963852, lng: 17.967627 },
    { lat: 47.963811, lng: 17.967761 },
    { lat: 47.963791, lng: 17.967823 },
    { lat: 47.963717, lng: 17.968062 },
    { lat: 47.963436, lng: 17.968883 },
    { lat: 47.963149, lng: 17.969723 },
    { lat: 47.963035, lng: 17.970127 },
    { lat: 47.963033, lng: 17.970136 },
    { lat: 47.963021, lng: 17.970177 },
    { lat: 47.962988, lng: 17.97029 },
    { lat: 47.962974, lng: 17.970338 },
    { lat: 47.962969, lng: 17.970356 },
    { lat: 47.962957, lng: 17.970395 },
    { lat: 47.962941, lng: 17.970447 },
    { lat: 47.963343, lng: 17.970858 },
    { lat: 47.96336, lng: 17.970876 },
    { lat: 47.963797, lng: 17.971321 },
    { lat: 47.964484, lng: 17.971165 },
    { lat: 47.965833, lng: 17.9707 },
    { lat: 47.967828, lng: 17.970036 },
    { lat: 47.968604, lng: 17.969826 },
    { lat: 47.969516, lng: 17.969447 },
    { lat: 47.970981, lng: 17.969433 },
    { lat: 47.972508, lng: 17.968972 },
    { lat: 47.973329, lng: 17.968753 },
    { lat: 47.974132, lng: 17.968414 },
    { lat: 47.97476, lng: 17.968508 },
    { lat: 47.975137, lng: 17.968955 },
    { lat: 47.975154, lng: 17.968969 },
    { lat: 47.976448, lng: 17.970103 },
    { lat: 47.977343, lng: 17.97096 },
    { lat: 47.978443, lng: 17.972008 },
    { lat: 47.978753, lng: 17.97291 },
    { lat: 47.979022, lng: 17.973585 },
    { lat: 47.979242, lng: 17.97415 },
    { lat: 47.980059, lng: 17.974945 },
    { lat: 47.980315, lng: 17.975138 },
    { lat: 47.980704, lng: 17.975557 },
    { lat: 47.981797, lng: 17.974545 },
    { lat: 47.983078, lng: 17.975912 },
    { lat: 47.983817, lng: 17.976651 },
    { lat: 47.984194, lng: 17.977784 },
    { lat: 47.984458, lng: 17.978534 },
    { lat: 47.984472, lng: 17.978571 },
    { lat: 47.98448, lng: 17.978597 },
    { lat: 47.984513, lng: 17.978692 },
    { lat: 47.984538, lng: 17.978764 },
    { lat: 47.984551, lng: 17.978801 },
    { lat: 47.984612, lng: 17.978985 },
    { lat: 47.984631, lng: 17.979043 },
    { lat: 47.984691, lng: 17.97922 },
    { lat: 47.984875, lng: 17.979779 },
    { lat: 47.984891, lng: 17.979826 },
    { lat: 47.984935, lng: 17.979957 },
    { lat: 47.985412, lng: 17.981399 },
    { lat: 47.985445, lng: 17.981502 },
    { lat: 47.985462, lng: 17.981546 },
    { lat: 47.985755, lng: 17.982436 },
    { lat: 47.98581, lng: 17.982633 },
    { lat: 47.985825, lng: 17.982682 },
    { lat: 47.986071, lng: 17.983536 },
    { lat: 47.986702, lng: 17.983139 },
    { lat: 47.986786, lng: 17.982996 },
    { lat: 47.987454, lng: 17.982251 },
    { lat: 47.988589, lng: 17.981059 },
    { lat: 47.989549, lng: 17.980273 },
    { lat: 47.990466, lng: 17.979425 },
    { lat: 47.991074, lng: 17.978836 },
    { lat: 47.991367, lng: 17.979478 },
    { lat: 47.991417, lng: 17.979528 },
    { lat: 47.99149, lng: 17.979597 },
    { lat: 47.992014, lng: 17.98008 },
    { lat: 47.992537, lng: 17.980563 },
    { lat: 47.992709, lng: 17.98079 },
    { lat: 47.992978, lng: 17.981146 },
    { lat: 47.993135, lng: 17.981351 },
    { lat: 47.993227, lng: 17.981474 },
    { lat: 47.99335, lng: 17.981635 },
    { lat: 47.993377, lng: 17.981671 },
    { lat: 47.993382, lng: 17.981678 },
    { lat: 47.994281, lng: 17.982864 },
    { lat: 47.994303, lng: 17.982894 },
    { lat: 47.995515, lng: 17.984886 },
    { lat: 47.995672, lng: 17.985143 },
    { lat: 47.997174, lng: 17.986497 },
    { lat: 47.997689, lng: 17.986976 },
    { lat: 47.997708, lng: 17.986994 },
    { lat: 47.997728, lng: 17.987014 },
    { lat: 47.997881, lng: 17.987159 },
    { lat: 47.997901, lng: 17.987178 },
    { lat: 47.997963, lng: 17.987237 },
    { lat: 47.998072, lng: 17.987339 },
    { lat: 47.998376, lng: 17.987625 },
    { lat: 47.998706, lng: 17.987938 },
    { lat: 48.000051, lng: 17.987916 },
    { lat: 48.000359, lng: 17.988272 },
    { lat: 48.000481, lng: 17.988413 },
    { lat: 48.0006, lng: 17.98854 },
    { lat: 48.000623, lng: 17.988568 },
    { lat: 48.000694, lng: 17.988651 },
    { lat: 48.000706, lng: 17.988665 },
    { lat: 48.000899, lng: 17.988895 },
    { lat: 48.001007, lng: 17.98898 },
    { lat: 48.001443, lng: 17.989304 },
    { lat: 48.00177, lng: 17.989526 },
    { lat: 48.002043, lng: 17.98967 },
    { lat: 48.002207, lng: 17.989679 },
    { lat: 48.002419, lng: 17.989656 },
    { lat: 48.002602, lng: 17.98957 },
    { lat: 48.002669, lng: 17.989492 },
    { lat: 48.002846, lng: 17.989262 },
    { lat: 48.002936, lng: 17.989075 },
    { lat: 48.003077, lng: 17.98872 },
    { lat: 48.003104, lng: 17.988636 },
    { lat: 48.003239, lng: 17.988202 },
    { lat: 48.00327, lng: 17.988042 },
    { lat: 48.00328, lng: 17.987988 },
    { lat: 48.003323, lng: 17.987739 },
    { lat: 48.003366, lng: 17.987495 },
    { lat: 48.003371, lng: 17.987467 },
    { lat: 48.003391, lng: 17.987361 },
    { lat: 48.003399, lng: 17.987327 },
    { lat: 48.003422, lng: 17.987194 },
    { lat: 48.00347, lng: 17.986928 },
    { lat: 48.003617, lng: 17.986199 },
    { lat: 48.003626, lng: 17.986155 },
    { lat: 48.003683, lng: 17.985946 },
    { lat: 48.003762, lng: 17.985661 },
    { lat: 48.003825, lng: 17.985389 },
    { lat: 48.004022, lng: 17.984802 },
    { lat: 48.004255, lng: 17.984206 },
    { lat: 48.004447, lng: 17.983893 },
    { lat: 48.004674, lng: 17.983565 },
    { lat: 48.005131, lng: 17.983205 },
    { lat: 48.005271, lng: 17.983129 },
    { lat: 48.005563, lng: 17.983137 },
    { lat: 48.005801, lng: 17.983234 },
    { lat: 48.005924, lng: 17.983353 },
    { lat: 48.005994, lng: 17.983501 },
    { lat: 48.006027, lng: 17.983743 },
    { lat: 48.006026, lng: 17.983935 },
    { lat: 48.005954, lng: 17.984066 },
    { lat: 48.00591, lng: 17.984142 },
    { lat: 48.005595, lng: 17.984539 },
    { lat: 48.005302, lng: 17.98484 },
    { lat: 48.005032, lng: 17.985113 },
    { lat: 48.004826, lng: 17.985411 },
    { lat: 48.00464, lng: 17.985703 },
    { lat: 48.004531, lng: 17.986004 },
    { lat: 48.004527, lng: 17.986158 },
    { lat: 48.004571, lng: 17.986444 },
    { lat: 48.004667, lng: 17.986765 },
    { lat: 48.004907, lng: 17.98744 },
    { lat: 48.005058, lng: 17.987685 },
    { lat: 48.005137, lng: 17.987882 },
    { lat: 48.00514, lng: 17.98789 },
    { lat: 48.005451, lng: 17.988584 },
    { lat: 48.005732, lng: 17.989623 },
    { lat: 48.005963, lng: 17.990425 },
    { lat: 48.005984, lng: 17.990501 },
    { lat: 48.006166, lng: 17.991222 },
    { lat: 48.006478, lng: 17.992458 },
    { lat: 48.006932, lng: 17.994029 },
    { lat: 48.006991, lng: 17.994329 },
    { lat: 48.007167, lng: 17.995205 },
    { lat: 48.007191, lng: 17.995325 },
    { lat: 48.007202, lng: 17.995374 },
    { lat: 48.007627, lng: 17.997246 },
    { lat: 48.007971, lng: 18.000471 },
    { lat: 48.007986, lng: 18.000584 },
    { lat: 48.008206, lng: 18.002021 },
    { lat: 48.00821, lng: 18.002081 },
    { lat: 48.008223, lng: 18.00222 },
    { lat: 48.00825, lng: 18.002512 },
    { lat: 48.008313, lng: 18.003214 },
    { lat: 48.008438, lng: 18.005385 },
    { lat: 48.008439, lng: 18.005401 },
    { lat: 48.008693, lng: 18.006349 },
    { lat: 48.009083, lng: 18.007726 },
    { lat: 48.009738, lng: 18.008372 },
    { lat: 48.009771, lng: 18.008405 },
    { lat: 48.00981, lng: 18.008442 },
    { lat: 48.00995, lng: 18.008579 },
    { lat: 48.009984, lng: 18.008612 },
    { lat: 48.010067, lng: 18.008695 },
    { lat: 48.010394, lng: 18.009004 },
    { lat: 48.010668, lng: 18.008827 },
    { lat: 48.010865, lng: 18.008907 },
    { lat: 48.010908, lng: 18.008944 },
    { lat: 48.011107, lng: 18.009158 },
    { lat: 48.011044, lng: 18.009857 },
    { lat: 48.011058, lng: 18.00985 },
    { lat: 48.012097, lng: 18.009298 },
    { lat: 48.012924, lng: 18.009036 },
    { lat: 48.01309, lng: 18.009156 },
    { lat: 48.013281, lng: 18.009188 },
    { lat: 48.013305, lng: 18.00919 },
    { lat: 48.01366, lng: 18.009685 },
    { lat: 48.013662, lng: 18.010475 },
    { lat: 48.013439, lng: 18.012008 },
  ],
};

export default UnitsŠaľa;
