import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InsertPhoto from "../../../Images/insertPhoto.png";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";

export default function Photos({ inputValues, setInputValues }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function () {
      const newArr = [...inputValues?.photos];
      newArr.push({ img: reader.result });
      console.log("newArr = ", newArr);
      setInputValues({ ...inputValues, photos: newArr });
    };
    reader.readAsDataURL(file);
  };

  const removeImg = (index) => {
    for (var i = 0; i <= inputValues?.photos.length; i++) {
      if (i === Number(index)) {
        const newArr = [...inputValues?.photos];
        newArr.splice(index, 1);
        setInputValues({ ...inputValues, photos: newArr });
        console.log("spilced photos = ", inputValues?.photos);
        return;
      }
    }
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full h-44 flex flex-col items-start pt-1 border-b border-opacity-50 border-b-sky-500"
    >
      <p className="font-semibold flex flex-row items-center gap-1">
        <img src={InsertPhoto} alt="" className="w-5 h-5" /> Galéria
      </p>

      <div
        className={clsx("grid grid-rows-1 w-full h-full overflow-x-scroll overflow-y-hidden gap-1 p-1", {
          "no-scrollbar": isSmallDevice,
        })}
      >
        {inputValues?.photos
          ? Object.keys(inputValues?.photos).map((key, index) => {
              return (
                <div key={index} className="relative w-44 h-[132px] row-start-1">
                  <button
                    onClick={() => removeImg(key)}
                    className="absolute top-1 right-1 h-8 w-8 rounded-full bg-sky-100"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <img src={inputValues?.photos[key].img} alt="" className="h-full w-full rounded-lg object-cover" />
                </div>
              );
            })
          : null}
        <label
          htmlFor="newImg"
          className="w-44 h-[132px] row-start-1 rounded-lg border border-opacity-50 border-sky-500"
        >
          {/* {!inputValues.photo1 === null ? ( */}
          <div className="w-full h-full rounded-lg flex justify-center items-center">
            <span className="font-semibold rounded-lg flex flex-col">
              Vložiť foto
              <FontAwesomeIcon icon={faPlus} />
              {/* <br className={clsx("", { hidden: priceExpanded, flex: !priceExpanded })} />  */}
            </span>
          </div>
          {/* ) : null} */}

          <input
            id="newImg"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => addImage(e)}
            className="absolute hidden w-full h-auto"
          />
          {/* {inputValues.photo1 !== null ? (
            <img src={inputValues.photo1} alt="newImg" className="w-full h-auto rounded-lg" />
          ) : null} */}
        </label>
      </div>
    </motion.div>
  );
}
