export default function SubjectsHeader({ office }) {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col justify-between w-full p-1 gap-1 text-xs">
        <div className="w-full flex flex-col items-start justify-start">
          <div className="w-full flex flex-row items-center gap-1">
            <p className="text-lg">{office?.name}</p>
          </div>
          <div className="w-full flex flex-row items-center gap-1">
            <p>
              {office?.street} {office?.number}, {office?.psc} {office?.city}
            </p>
          </div>
          <div className="w-full flex flex-row items-center gap-1"></div>
        </div>
        <div className="w-full flex items-center justify-start text-xl font-semibold">
          <img src={office?.img} alt="" className="w-40 h-16 object-cover" />
        </div>
      </div>
      {/* <div
        // onClick={() => {
        //   window.open(
        //     `https://kataster.skgeodesy.sk/Portal45/api/Bo/GeneratePrfPublic?prfNumber=${lv?.List?.cislo_listu_vlastnictva}&cadastralUnitCode=${lv?.List?.nazov_katastralneho_uzemia}&outputType=pdf`,
        //     "_blank",
        //   );
        // }}
        className="w-1/3 flex justify-end items-start cursor-pointer"
      >
      </div> */}
    </div>
  );
}
