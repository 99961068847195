const UnitsTrenčín = {
  HornéSrnie: [
    { lat: 49.011681, lng: 18.105531 },
    { lat: 49.011649, lng: 18.104337 },
    { lat: 49.011656, lng: 18.10428 },
    { lat: 49.011719, lng: 18.103795 },
    { lat: 49.011897, lng: 18.103365 },
    { lat: 49.012025, lng: 18.103182 },
    { lat: 49.012056, lng: 18.103128 },
    { lat: 49.012495, lng: 18.102645 },
    { lat: 49.012605, lng: 18.102454 },
    { lat: 49.012892, lng: 18.101779 },
    { lat: 49.013022, lng: 18.101674 },
    { lat: 49.013783, lng: 18.101505 },
    { lat: 49.013974, lng: 18.101504 },
    { lat: 49.014702, lng: 18.101295 },
    { lat: 49.01543, lng: 18.101059 },
    { lat: 49.015877, lng: 18.100804 },
    { lat: 49.016293, lng: 18.100615 },
    { lat: 49.016562, lng: 18.100559 },
    { lat: 49.016866, lng: 18.100554 },
    { lat: 49.017141, lng: 18.100604 },
    { lat: 49.017369, lng: 18.100721 },
    { lat: 49.017743, lng: 18.101094 },
    { lat: 49.017845, lng: 18.101148 },
    { lat: 49.018074, lng: 18.101184 },
    { lat: 49.018449, lng: 18.101066 },
    { lat: 49.018647, lng: 18.100928 },
    { lat: 49.019466, lng: 18.100217 },
    { lat: 49.01992, lng: 18.099783 },
    { lat: 49.019993, lng: 18.099629 },
    { lat: 49.020008, lng: 18.099257 },
    { lat: 49.019966, lng: 18.098825 },
    { lat: 49.019945, lng: 18.098419 },
    { lat: 49.019881, lng: 18.097908 },
    { lat: 49.019648, lng: 18.097175 },
    { lat: 49.019517, lng: 18.09667 },
    { lat: 49.019516, lng: 18.09634 },
    { lat: 49.019545, lng: 18.095824 },
    { lat: 49.019484, lng: 18.0953 },
    { lat: 49.019497, lng: 18.095078 },
    { lat: 49.019476, lng: 18.094998 },
    { lat: 49.019521, lng: 18.094635 },
    { lat: 49.019583, lng: 18.09425 },
    { lat: 49.01966, lng: 18.094028 },
    { lat: 49.019929, lng: 18.093634 },
    { lat: 49.020384, lng: 18.093108 },
    { lat: 49.020675, lng: 18.092769 },
    { lat: 49.020751, lng: 18.092642 },
    { lat: 49.021002, lng: 18.092044 },
    { lat: 49.021107, lng: 18.091733 },
    { lat: 49.021182, lng: 18.091666 },
    { lat: 49.021295, lng: 18.091676 },
    { lat: 49.021619, lng: 18.091942 },
    { lat: 49.021821, lng: 18.092097 },
    { lat: 49.022324, lng: 18.092615 },
    { lat: 49.022605, lng: 18.092876 },
    { lat: 49.023154, lng: 18.093347 },
    { lat: 49.023766, lng: 18.093694 },
    { lat: 49.024106, lng: 18.093851 },
    { lat: 49.024441, lng: 18.093976 },
    { lat: 49.024576, lng: 18.094065 },
    { lat: 49.025418, lng: 18.094885 },
    { lat: 49.025616, lng: 18.095191 },
    { lat: 49.02568, lng: 18.095289 },
    { lat: 49.026138, lng: 18.095835 },
    { lat: 49.025183, lng: 18.097107 },
    { lat: 49.024405, lng: 18.09829 },
    { lat: 49.0241, lng: 18.099021 },
    { lat: 49.023897, lng: 18.099533 },
    { lat: 49.025479, lng: 18.099893 },
    { lat: 49.025774, lng: 18.09998 },
    { lat: 49.026897, lng: 18.100302 },
    { lat: 49.027576, lng: 18.099684 },
    { lat: 49.02808, lng: 18.099417 },
    { lat: 49.02821, lng: 18.099418 },
    { lat: 49.028648, lng: 18.099606 },
    { lat: 49.029145, lng: 18.099585 },
    { lat: 49.029433, lng: 18.09932 },
    { lat: 49.029686, lng: 18.099104 },
    { lat: 49.029799, lng: 18.098965 },
    { lat: 49.030366, lng: 18.098433 },
    { lat: 49.030568, lng: 18.098236 },
    { lat: 49.030838, lng: 18.097953 },
    { lat: 49.03145, lng: 18.097009 },
    { lat: 49.0317, lng: 18.096285 },
    { lat: 49.032013, lng: 18.096434 },
    { lat: 49.032353, lng: 18.096589 },
    { lat: 49.0331, lng: 18.096373 },
    { lat: 49.03312, lng: 18.096357 },
    { lat: 49.034099, lng: 18.095532 },
    { lat: 49.034167, lng: 18.095075 },
    { lat: 49.034419, lng: 18.094852 },
    { lat: 49.034454, lng: 18.09484 },
    { lat: 49.034984, lng: 18.094483 },
    { lat: 49.035253, lng: 18.094359 },
    { lat: 49.035795, lng: 18.094301 },
    { lat: 49.03654, lng: 18.093973 },
    { lat: 49.037085, lng: 18.094075 },
    { lat: 49.037277, lng: 18.094189 },
    { lat: 49.037741, lng: 18.094297 },
    { lat: 49.038332, lng: 18.094776 },
    { lat: 49.038731, lng: 18.095475 },
    { lat: 49.039009, lng: 18.095855 },
    { lat: 49.03932, lng: 18.096198 },
    { lat: 49.039646, lng: 18.096649 },
    { lat: 49.039839, lng: 18.09703 },
    { lat: 49.040332, lng: 18.097706 },
    { lat: 49.04067, lng: 18.098298 },
    { lat: 49.041274, lng: 18.099481 },
    { lat: 49.042447, lng: 18.100565 },
    { lat: 49.042849, lng: 18.10099 },
    { lat: 49.042889, lng: 18.100933 },
    { lat: 49.043068, lng: 18.099752 },
    { lat: 49.043213, lng: 18.099244 },
    { lat: 49.043402, lng: 18.09869 },
    { lat: 49.043638, lng: 18.098186 },
    { lat: 49.04398, lng: 18.097419 },
    { lat: 49.044003, lng: 18.097314 },
    { lat: 49.044066, lng: 18.097028 },
    { lat: 49.044133, lng: 18.097031 },
    { lat: 49.044552, lng: 18.097011 },
    { lat: 49.044984, lng: 18.097523 },
    { lat: 49.045824, lng: 18.097984 },
    { lat: 49.046657, lng: 18.098382 },
    { lat: 49.047411, lng: 18.098774 },
    { lat: 49.048258, lng: 18.099389 },
    { lat: 49.04883, lng: 18.099842 },
    { lat: 49.049474, lng: 18.100242 },
    { lat: 49.049824, lng: 18.100526 },
    { lat: 49.050473, lng: 18.100938 },
    { lat: 49.051102, lng: 18.101394 },
    { lat: 49.051909, lng: 18.102274 },
    { lat: 49.053654, lng: 18.101104 },
    { lat: 49.054155, lng: 18.100591 },
    { lat: 49.055065, lng: 18.099759 },
    { lat: 49.055466, lng: 18.099393 },
    { lat: 49.055792, lng: 18.099063 },
    { lat: 49.056471, lng: 18.098535 },
    { lat: 49.056909, lng: 18.098153 },
    { lat: 49.057533, lng: 18.097598 },
    { lat: 49.058217, lng: 18.097147 },
    { lat: 49.05863, lng: 18.096653 },
    { lat: 49.059031, lng: 18.096128 },
    { lat: 49.059276, lng: 18.095806 },
    { lat: 49.059293, lng: 18.095798 },
    { lat: 49.059355, lng: 18.095768 },
    { lat: 49.059379, lng: 18.095758 },
    { lat: 49.059414, lng: 18.095743 },
    { lat: 49.059442, lng: 18.095732 },
    { lat: 49.059475, lng: 18.095714 },
    { lat: 49.060209, lng: 18.095322 },
    { lat: 49.060788, lng: 18.094991 },
    { lat: 49.061374, lng: 18.094641 },
    { lat: 49.062102, lng: 18.094411 },
    { lat: 49.062843, lng: 18.094073 },
    { lat: 49.063356, lng: 18.093845 },
    { lat: 49.063512, lng: 18.094191 },
    { lat: 49.063981, lng: 18.094568 },
    { lat: 49.064315, lng: 18.094781 },
    { lat: 49.064819, lng: 18.095069 },
    { lat: 49.065223, lng: 18.095473 },
    { lat: 49.065538, lng: 18.095625 },
    { lat: 49.065853, lng: 18.095842 },
    { lat: 49.066152, lng: 18.09605 },
    { lat: 49.066617, lng: 18.096383 },
    { lat: 49.066937, lng: 18.096594 },
    { lat: 49.06713, lng: 18.096721 },
    { lat: 49.067318, lng: 18.096842 },
    { lat: 49.067326, lng: 18.096848 },
    { lat: 49.067343, lng: 18.096782 },
    { lat: 49.067345, lng: 18.096694 },
    { lat: 49.06738, lng: 18.096623 },
    { lat: 49.067447, lng: 18.096623 },
    { lat: 49.067476, lng: 18.096581 },
    { lat: 49.067492, lng: 18.096459 },
    { lat: 49.067525, lng: 18.096388 },
    { lat: 49.067522, lng: 18.096194 },
    { lat: 49.067543, lng: 18.09613 },
    { lat: 49.067618, lng: 18.09607 },
    { lat: 49.067659, lng: 18.095977 },
    { lat: 49.067759, lng: 18.09594 },
    { lat: 49.067808, lng: 18.095828 },
    { lat: 49.067844, lng: 18.095618 },
    { lat: 49.067871, lng: 18.09556 },
    { lat: 49.067977, lng: 18.095584 },
    { lat: 49.068048, lng: 18.095559 },
    { lat: 49.068102, lng: 18.095444 },
    { lat: 49.068194, lng: 18.095318 },
    { lat: 49.068362, lng: 18.09513 },
    { lat: 49.068416, lng: 18.094989 },
    { lat: 49.068456, lng: 18.094955 },
    { lat: 49.068509, lng: 18.094932 },
    { lat: 49.068558, lng: 18.094864 },
    { lat: 49.068558, lng: 18.094805 },
    { lat: 49.068614, lng: 18.094716 },
    { lat: 49.068657, lng: 18.094717 },
    { lat: 49.0687, lng: 18.094733 },
    { lat: 49.068718, lng: 18.094641 },
    { lat: 49.068737, lng: 18.094603 },
    { lat: 49.068763, lng: 18.094598 },
    { lat: 49.068818, lng: 18.094608 },
    { lat: 49.06895, lng: 18.094513 },
    { lat: 49.068935, lng: 18.094374 },
    { lat: 49.069005, lng: 18.094176 },
    { lat: 49.069076, lng: 18.094139 },
    { lat: 49.069147, lng: 18.093992 },
    { lat: 49.069235, lng: 18.093963 },
    { lat: 49.0692751, lng: 18.0938673 },
    { lat: 49.069232, lng: 18.093791 },
    { lat: 49.069076, lng: 18.093486 },
    { lat: 49.068955, lng: 18.093341 },
    { lat: 49.06822, lng: 18.092224 },
    { lat: 49.067435, lng: 18.09165 },
    { lat: 49.066674, lng: 18.091199 },
    { lat: 49.065561, lng: 18.090968 },
    { lat: 49.064697, lng: 18.090721 },
    { lat: 49.063965, lng: 18.090605 },
    { lat: 49.063325, lng: 18.090573 },
    { lat: 49.062728, lng: 18.090576 },
    { lat: 49.06182, lng: 18.091129 },
    { lat: 49.06138, lng: 18.091455 },
    { lat: 49.061252, lng: 18.09169 },
    { lat: 49.061004, lng: 18.092148 },
    { lat: 49.060784, lng: 18.092497 },
    { lat: 49.060544, lng: 18.09288 },
    { lat: 49.060193, lng: 18.093543 },
    { lat: 49.060129, lng: 18.093698 },
    { lat: 49.059911, lng: 18.09423 },
    { lat: 49.059725, lng: 18.094726 },
    { lat: 49.059599, lng: 18.095062 },
    { lat: 49.059374, lng: 18.095347 },
    { lat: 49.0592895, lng: 18.0954269 },
    { lat: 49.059244, lng: 18.0954718 },
    { lat: 49.05918, lng: 18.095321 },
    { lat: 49.059023, lng: 18.09519 },
    { lat: 49.058894, lng: 18.095121 },
    { lat: 49.058698, lng: 18.095008 },
    { lat: 49.058676, lng: 18.094961 },
    { lat: 49.058631, lng: 18.094777 },
    { lat: 49.058602, lng: 18.094612 },
    { lat: 49.058478, lng: 18.094141 },
    { lat: 49.058467, lng: 18.093879 },
    { lat: 49.058448, lng: 18.093631 },
    { lat: 49.058398, lng: 18.093483 },
    { lat: 49.058289, lng: 18.09333 },
    { lat: 49.05823, lng: 18.093269 },
    { lat: 49.05817, lng: 18.093137 },
    { lat: 49.058161, lng: 18.093018 },
    { lat: 49.058176, lng: 18.092782 },
    { lat: 49.058075, lng: 18.092471 },
    { lat: 49.058034, lng: 18.092388 },
    { lat: 49.057979, lng: 18.092272 },
    { lat: 49.057944, lng: 18.092212 },
    { lat: 49.057898, lng: 18.092131 },
    { lat: 49.057844, lng: 18.09203 },
    { lat: 49.057791, lng: 18.091932 },
    { lat: 49.057719, lng: 18.091807 },
    { lat: 49.057669, lng: 18.091716 },
    { lat: 49.057608, lng: 18.091586 },
    { lat: 49.057572, lng: 18.091529 },
    { lat: 49.057506, lng: 18.091458 },
    { lat: 49.0574, lng: 18.091347 },
    { lat: 49.057321, lng: 18.091279 },
    { lat: 49.05731, lng: 18.091271 },
    { lat: 49.057237, lng: 18.091196 },
    { lat: 49.057172, lng: 18.091118 },
    { lat: 49.05714, lng: 18.091081 },
    { lat: 49.057043, lng: 18.090904 },
    { lat: 49.05699, lng: 18.090813 },
    { lat: 49.056931, lng: 18.090734 },
    { lat: 49.056805, lng: 18.090577 },
    { lat: 49.056752, lng: 18.090506 },
    { lat: 49.056645, lng: 18.09041 },
    { lat: 49.05658, lng: 18.090338 },
    { lat: 49.056483, lng: 18.090301 },
    { lat: 49.056375, lng: 18.090243 },
    { lat: 49.056311, lng: 18.090169 },
    { lat: 49.056224, lng: 18.090117 },
    { lat: 49.056112, lng: 18.089964 },
    { lat: 49.056073, lng: 18.089874 },
    { lat: 49.056013, lng: 18.089503 },
    { lat: 49.055977, lng: 18.089393 },
    { lat: 49.055904, lng: 18.089077 },
    { lat: 49.05585, lng: 18.088892 },
    { lat: 49.055823, lng: 18.088818 },
    { lat: 49.055699, lng: 18.088634 },
    { lat: 49.055536, lng: 18.088591 },
    { lat: 49.055416, lng: 18.088562 },
    { lat: 49.055395, lng: 18.088547 },
    { lat: 49.055291, lng: 18.088455 },
    { lat: 49.055145, lng: 18.088334 },
    { lat: 49.055024, lng: 18.088245 },
    { lat: 49.054969, lng: 18.088206 },
    { lat: 49.05481, lng: 18.08811 },
    { lat: 49.054598, lng: 18.087922 },
    { lat: 49.054534, lng: 18.087866 },
    { lat: 49.054487, lng: 18.087827 },
    { lat: 49.054439, lng: 18.087785 },
    { lat: 49.05437, lng: 18.087717 },
    { lat: 49.054237, lng: 18.087578 },
    { lat: 49.054081, lng: 18.087386 },
    { lat: 49.054013, lng: 18.087298 },
    { lat: 49.053928, lng: 18.08721 },
    { lat: 49.05384, lng: 18.087122 },
    { lat: 49.05375, lng: 18.087091 },
    { lat: 49.053648, lng: 18.087055 },
    { lat: 49.053567, lng: 18.086997 },
    { lat: 49.053486, lng: 18.086893 },
    { lat: 49.053468, lng: 18.086839 },
    { lat: 49.053408, lng: 18.086613 },
    { lat: 49.053384, lng: 18.086506 },
    { lat: 49.053344, lng: 18.086363 },
    { lat: 49.053344, lng: 18.086262 },
    { lat: 49.053343, lng: 18.086173 },
    { lat: 49.053363, lng: 18.086075 },
    { lat: 49.053403, lng: 18.085906 },
    { lat: 49.053411, lng: 18.08574 },
    { lat: 49.053416, lng: 18.085559 },
    { lat: 49.053395, lng: 18.085473 },
    { lat: 49.053364, lng: 18.085334 },
    { lat: 49.053336, lng: 18.085229 },
    { lat: 49.053306, lng: 18.085134 },
    { lat: 49.053265, lng: 18.085047 },
    { lat: 49.053237, lng: 18.084983 },
    { lat: 49.053155, lng: 18.084889 },
    { lat: 49.053071, lng: 18.084764 },
    { lat: 49.053045, lng: 18.084713 },
    { lat: 49.052996, lng: 18.084626 },
    { lat: 49.052945, lng: 18.084524 },
    { lat: 49.052843, lng: 18.084302 },
    { lat: 49.052818, lng: 18.084235 },
    { lat: 49.052684, lng: 18.084025 },
    { lat: 49.052567, lng: 18.083876 },
    { lat: 49.052542, lng: 18.083849 },
    { lat: 49.052349, lng: 18.083651 },
    { lat: 49.052289, lng: 18.083597 },
    { lat: 49.052041, lng: 18.083436 },
    { lat: 49.051963, lng: 18.083384 },
    { lat: 49.051933, lng: 18.083352 },
    { lat: 49.0518, lng: 18.083212 },
    { lat: 49.051706, lng: 18.083098 },
    { lat: 49.051662, lng: 18.083015 },
    { lat: 49.051453, lng: 18.08273 },
    { lat: 49.051335, lng: 18.082542 },
    { lat: 49.051289, lng: 18.08245 },
    { lat: 49.051122, lng: 18.082415 },
    { lat: 49.051017, lng: 18.08243 },
    { lat: 49.050567, lng: 18.082175 },
    { lat: 49.050286, lng: 18.082078 },
    { lat: 49.050043, lng: 18.082041 },
    { lat: 49.049864, lng: 18.081997 },
    { lat: 49.049726, lng: 18.081944 },
    { lat: 49.049515, lng: 18.081861 },
    { lat: 49.049326, lng: 18.081847 },
    { lat: 49.048809, lng: 18.081807 },
    { lat: 49.048493, lng: 18.081776 },
    { lat: 49.048034, lng: 18.081731 },
    { lat: 49.047956, lng: 18.081731 },
    { lat: 49.047923, lng: 18.081779 },
    { lat: 49.047779, lng: 18.081915 },
    { lat: 49.047727, lng: 18.081921 },
    { lat: 49.047609, lng: 18.081949 },
    { lat: 49.047502, lng: 18.081969 },
    { lat: 49.047404, lng: 18.081965 },
    { lat: 49.047289, lng: 18.081992 },
    { lat: 49.047187, lng: 18.081986 },
    { lat: 49.047029, lng: 18.082014 },
    { lat: 49.046958, lng: 18.082018 },
    { lat: 49.046919, lng: 18.082024 },
    { lat: 49.04681, lng: 18.082043 },
    { lat: 49.046733, lng: 18.082045 },
    { lat: 49.046631, lng: 18.082059 },
    { lat: 49.046592, lng: 18.082056 },
    { lat: 49.046251, lng: 18.082065 },
    { lat: 49.046207, lng: 18.082047 },
    { lat: 49.046165, lng: 18.082042 },
    { lat: 49.046115, lng: 18.08193 },
    { lat: 49.04611, lng: 18.081853 },
    { lat: 49.04611, lng: 18.081767 },
    { lat: 49.046107, lng: 18.081652 },
    { lat: 49.046099, lng: 18.081564 },
    { lat: 49.046008, lng: 18.081209 },
    { lat: 49.04593, lng: 18.080941 },
    { lat: 49.045828, lng: 18.080617 },
    { lat: 49.045811, lng: 18.080545 },
    { lat: 49.045815, lng: 18.080464 },
    { lat: 49.045807, lng: 18.080374 },
    { lat: 49.045736, lng: 18.080289 },
    { lat: 49.04563, lng: 18.080125 },
    { lat: 49.045399, lng: 18.079765 },
    { lat: 49.045143, lng: 18.079409 },
    { lat: 49.045183, lng: 18.079332 },
    { lat: 49.045123, lng: 18.079004 },
    { lat: 49.045033, lng: 18.078692 },
    { lat: 49.044807, lng: 18.078663 },
    { lat: 49.044674, lng: 18.078691 },
    { lat: 49.044545, lng: 18.078829 },
    { lat: 49.044343, lng: 18.078916 },
    { lat: 49.04421, lng: 18.078974 },
    { lat: 49.044127, lng: 18.078985 },
    { lat: 49.043974, lng: 18.078847 },
    { lat: 49.043755, lng: 18.078614 },
    { lat: 49.043515, lng: 18.078338 },
    { lat: 49.043395, lng: 18.0782 },
    { lat: 49.043268, lng: 18.078123 },
    { lat: 49.042993, lng: 18.077874 },
    { lat: 49.043116, lng: 18.077594 },
    { lat: 49.043339, lng: 18.077101 },
    { lat: 49.043357, lng: 18.07705 },
    { lat: 49.043078, lng: 18.07688 },
    { lat: 49.042997, lng: 18.076806 },
    { lat: 49.0429, lng: 18.076754 },
    { lat: 49.042761, lng: 18.076714 },
    { lat: 49.04265, lng: 18.076724 },
    { lat: 49.042599, lng: 18.076718 },
    { lat: 49.042521, lng: 18.076697 },
    { lat: 49.042295, lng: 18.076766 },
    { lat: 49.042187, lng: 18.076843 },
    { lat: 49.042045, lng: 18.076975 },
    { lat: 49.042032, lng: 18.076944 },
    { lat: 49.04224, lng: 18.076693 },
    { lat: 49.042328, lng: 18.07641 },
    { lat: 49.042362, lng: 18.076075 },
    { lat: 49.042357, lng: 18.075783 },
    { lat: 49.042223, lng: 18.075428 },
    { lat: 49.042135, lng: 18.074682 },
    { lat: 49.042239, lng: 18.074554 },
    { lat: 49.04231, lng: 18.07449 },
    { lat: 49.042209, lng: 18.074214 },
    { lat: 49.042141, lng: 18.074031 },
    { lat: 49.042063, lng: 18.073815 },
    { lat: 49.042044, lng: 18.073763 },
    { lat: 49.041991, lng: 18.073611 },
    { lat: 49.041893, lng: 18.073375 },
    { lat: 49.041804, lng: 18.073196 },
    { lat: 49.041753, lng: 18.073116 },
    { lat: 49.041647, lng: 18.072979 },
    { lat: 49.041464, lng: 18.072772 },
    { lat: 49.041319, lng: 18.072618 },
    { lat: 49.041169, lng: 18.072458 },
    { lat: 49.040999, lng: 18.072279 },
    { lat: 49.040787, lng: 18.072058 },
    { lat: 49.040636, lng: 18.071894 },
    { lat: 49.040487, lng: 18.071739 },
    { lat: 49.040311, lng: 18.071555 },
    { lat: 49.040143, lng: 18.071386 },
    { lat: 49.040016, lng: 18.071286 },
    { lat: 49.0399016, lng: 18.071226 },
    { lat: 49.039892, lng: 18.071221 },
    { lat: 49.039761, lng: 18.071203 },
    { lat: 49.039693, lng: 18.071191 },
    { lat: 49.039446, lng: 18.071161 },
    { lat: 49.039167, lng: 18.07113 },
    { lat: 49.038935, lng: 18.071104 },
    { lat: 49.038623, lng: 18.071063 },
    { lat: 49.038441, lng: 18.071045 },
    { lat: 49.038269, lng: 18.071022 },
    { lat: 49.03811, lng: 18.070998 },
    { lat: 49.038028, lng: 18.070979 },
    { lat: 49.037942, lng: 18.070944 },
    { lat: 49.037868, lng: 18.070892 },
    { lat: 49.037773, lng: 18.070777 },
    { lat: 49.037691, lng: 18.070618 },
    { lat: 49.037642, lng: 18.07049 },
    { lat: 49.037564, lng: 18.070244 },
    { lat: 49.037457, lng: 18.069926 },
    { lat: 49.037392, lng: 18.069751 },
    { lat: 49.037334, lng: 18.069618 },
    { lat: 49.03726, lng: 18.069472 },
    { lat: 49.037135, lng: 18.069264 },
    { lat: 49.036955, lng: 18.068982 },
    { lat: 49.036748, lng: 18.068663 },
    { lat: 49.03667, lng: 18.068538 },
    { lat: 49.03657, lng: 18.068372 },
    { lat: 49.036467, lng: 18.068157 },
    { lat: 49.03636, lng: 18.067915 },
    { lat: 49.036215, lng: 18.067556 },
    { lat: 49.036053, lng: 18.06715 },
    { lat: 49.035937, lng: 18.066866 },
    { lat: 49.035818, lng: 18.066575 },
    { lat: 49.035706, lng: 18.066306 },
    { lat: 49.035585, lng: 18.06604 },
    { lat: 49.035474, lng: 18.065815 },
    { lat: 49.035334, lng: 18.065537 },
    { lat: 49.03524, lng: 18.065352 },
    { lat: 49.035104, lng: 18.065084 },
    { lat: 49.034968, lng: 18.064816 },
    { lat: 49.034862, lng: 18.064598 },
    { lat: 49.034798, lng: 18.064413 },
    { lat: 49.034773, lng: 18.064286 },
    { lat: 49.034755, lng: 18.06408 },
    { lat: 49.034753, lng: 18.063885 },
    { lat: 49.034755, lng: 18.063706 },
    { lat: 49.034754, lng: 18.06354 },
    { lat: 49.03475, lng: 18.063365 },
    { lat: 49.034748, lng: 18.063239 },
    { lat: 49.034736, lng: 18.063084 },
    { lat: 49.034698, lng: 18.06289 },
    { lat: 49.034652, lng: 18.062744 },
    { lat: 49.034581, lng: 18.062551 },
    { lat: 49.03448, lng: 18.062283 },
    { lat: 49.034355, lng: 18.061937 },
    { lat: 49.034288, lng: 18.061758 },
    { lat: 49.034172, lng: 18.061436 },
    { lat: 49.034092, lng: 18.061199 },
    { lat: 49.034038, lng: 18.061029 },
    { lat: 49.033928, lng: 18.060704 },
    { lat: 49.03384, lng: 18.060492 },
    { lat: 49.033715, lng: 18.060259 },
    { lat: 49.033614, lng: 18.060028 },
    { lat: 49.033506, lng: 18.059747 },
    { lat: 49.033312, lng: 18.059228 },
    { lat: 49.03313, lng: 18.058742 },
    { lat: 49.032995, lng: 18.058382 },
    { lat: 49.032898, lng: 18.058142 },
    { lat: 49.03279, lng: 18.057925 },
    { lat: 49.032723, lng: 18.057802 },
    { lat: 49.032611, lng: 18.057617 },
    { lat: 49.032512, lng: 18.057449 },
    { lat: 49.032436, lng: 18.057331 },
    { lat: 49.032341, lng: 18.057197 },
    { lat: 49.032247, lng: 18.057087 },
    { lat: 49.032074, lng: 18.056922 },
    { lat: 49.031911, lng: 18.056778 },
    { lat: 49.031749, lng: 18.056624 },
    { lat: 49.031677, lng: 18.05654 },
    { lat: 49.031621, lng: 18.056458 },
    { lat: 49.031446, lng: 18.056111 },
    { lat: 49.031328, lng: 18.055835 },
    { lat: 49.031242, lng: 18.05564 },
    { lat: 49.0312008, lng: 18.0555217 },
    { lat: 49.031185, lng: 18.055479 },
    { lat: 49.03124, lng: 18.055434 },
    { lat: 49.031528, lng: 18.055193 },
    { lat: 49.031863, lng: 18.054933 },
    { lat: 49.03192, lng: 18.054886 },
    { lat: 49.031903, lng: 18.054811 },
    { lat: 49.031906, lng: 18.054766 },
    { lat: 49.031914, lng: 18.054543 },
    { lat: 49.031873, lng: 18.054199 },
    { lat: 49.031845, lng: 18.054081 },
    { lat: 49.031734, lng: 18.053762 },
    { lat: 49.031625, lng: 18.053587 },
    { lat: 49.031579, lng: 18.053612 },
    { lat: 49.031196, lng: 18.05387 },
    { lat: 49.030802, lng: 18.054223 },
    { lat: 49.03043, lng: 18.054613 },
    { lat: 49.030147, lng: 18.054917 },
    { lat: 49.029994, lng: 18.055082 },
    { lat: 49.029651, lng: 18.055536 },
    { lat: 49.029319, lng: 18.055969 },
    { lat: 49.029224, lng: 18.056148 },
    { lat: 49.028832, lng: 18.056896 },
    { lat: 49.028637, lng: 18.057264 },
    { lat: 49.0285613, lng: 18.057185 },
    { lat: 49.028505, lng: 18.057123 },
    { lat: 49.028709, lng: 18.056741 },
    { lat: 49.029216, lng: 18.055808 },
    { lat: 49.029321, lng: 18.05564 },
    { lat: 49.029512, lng: 18.055336 },
    { lat: 49.029341, lng: 18.055096 },
    { lat: 49.029313, lng: 18.055054 },
    { lat: 49.029657, lng: 18.054633 },
    { lat: 49.029818, lng: 18.054472 },
    { lat: 49.0301851, lng: 18.0540799 },
    { lat: 49.030484, lng: 18.053664 },
    { lat: 49.030713, lng: 18.053467 },
    { lat: 49.030955, lng: 18.053306 },
    { lat: 49.031316, lng: 18.053296 },
    { lat: 49.031415, lng: 18.053396 },
    { lat: 49.0315919, lng: 18.0532891 },
    { lat: 49.031641, lng: 18.0532339 },
    { lat: 49.0316844, lng: 18.0531847 },
    { lat: 49.031517, lng: 18.052588 },
    { lat: 49.0313672, lng: 18.0520534 },
    { lat: 49.0313677, lng: 18.0519374 },
    { lat: 49.0313689, lng: 18.0517432 },
    { lat: 49.03137, lng: 18.051551 },
    { lat: 49.031373, lng: 18.051215 },
    { lat: 49.03127, lng: 18.050885 },
    { lat: 49.03116, lng: 18.050535 },
    { lat: 49.031066, lng: 18.050232 },
    { lat: 49.031035, lng: 18.05013 },
    { lat: 49.030788, lng: 18.049341 },
    { lat: 49.030673, lng: 18.048973 },
    { lat: 49.030375, lng: 18.048268 },
    { lat: 49.030242, lng: 18.047954 },
    { lat: 49.030004, lng: 18.047393 },
    { lat: 49.029693, lng: 18.04666 },
    { lat: 49.029622, lng: 18.046492 },
    { lat: 49.029481, lng: 18.04616 },
    { lat: 49.029202, lng: 18.045502 },
    { lat: 49.028926, lng: 18.044854 },
    { lat: 49.028705, lng: 18.044319 },
    { lat: 49.028484, lng: 18.043802 },
    { lat: 49.028294, lng: 18.043363 },
    { lat: 49.028109, lng: 18.042928 },
    { lat: 49.027932, lng: 18.04251 },
    { lat: 49.027812, lng: 18.042227 },
    { lat: 49.027765, lng: 18.041912 },
    { lat: 49.0275403, lng: 18.0409932 },
    { lat: 49.0272163, lng: 18.039673 },
    { lat: 49.0267444, lng: 18.038313 },
    { lat: 49.0264471, lng: 18.0374571 },
    { lat: 49.0263752, lng: 18.03725 },
    { lat: 49.0264523, lng: 18.0355718 },
    { lat: 49.0264697, lng: 18.0351962 },
    { lat: 49.0264723, lng: 18.0349684 },
    { lat: 49.026474, lng: 18.034735 },
    { lat: 49.0265381, lng: 18.0342263 },
    { lat: 49.026626, lng: 18.0332982 },
    { lat: 49.0266421, lng: 18.0330957 },
    { lat: 49.0267202, lng: 18.0321852 },
    { lat: 49.0267573, lng: 18.031719 },
    { lat: 49.0267989, lng: 18.0304757 },
    { lat: 49.0268293, lng: 18.0297257 },
    { lat: 49.0261502, lng: 18.0288865 },
    { lat: 49.025668, lng: 18.0282901 },
    { lat: 49.0252205, lng: 18.0280656 },
    { lat: 49.0241543, lng: 18.0275294 },
    { lat: 49.0234566, lng: 18.0269038 },
    { lat: 49.0227182, lng: 18.0262418 },
    { lat: 49.0219702, lng: 18.0253519 },
    { lat: 49.0212311, lng: 18.0244724 },
    { lat: 49.0211785, lng: 18.0231612 },
    { lat: 49.0211548, lng: 18.0225736 },
    { lat: 49.0212726, lng: 18.0218991 },
    { lat: 49.0214335, lng: 18.0209751 },
    { lat: 49.021525, lng: 18.0199127 },
    { lat: 49.0216294, lng: 18.0186933 },
    { lat: 49.0217646, lng: 18.0170269 },
    { lat: 49.0218869, lng: 18.0155439 },
    { lat: 49.0221042, lng: 18.0143341 },
    { lat: 49.0220695, lng: 18.0143216 },
    { lat: 49.0216972, lng: 18.0139177 },
    { lat: 49.0211957, lng: 18.0126848 },
    { lat: 49.0210159, lng: 18.0126392 },
    { lat: 49.0210464, lng: 18.0134081 },
    { lat: 49.0213722, lng: 18.0145523 },
    { lat: 49.0213341, lng: 18.0161534 },
    { lat: 49.0211219, lng: 18.0173246 },
    { lat: 49.02017, lng: 18.0198312 },
    { lat: 49.0200664, lng: 18.0201056 },
    { lat: 49.0195755, lng: 18.0212585 },
    { lat: 49.0194823, lng: 18.0213985 },
    { lat: 49.0194551, lng: 18.0214392 },
    { lat: 49.0175819, lng: 18.0242471 },
    { lat: 49.0169258, lng: 18.0250484 },
    { lat: 49.0163109, lng: 18.025801 },
    { lat: 49.0146606, lng: 18.0263158 },
    { lat: 49.014222, lng: 18.0293397 },
    { lat: 49.013843, lng: 18.0298466 },
    { lat: 49.0125363, lng: 18.0315984 },
    { lat: 49.0113433, lng: 18.030962 },
    { lat: 49.0111361, lng: 18.0314697 },
    { lat: 49.0106279, lng: 18.0324675 },
    { lat: 49.0103556, lng: 18.0328233 },
    { lat: 49.0100349, lng: 18.0332831 },
    { lat: 49.0095233, lng: 18.0341663 },
    { lat: 49.0093159, lng: 18.0346096 },
    { lat: 49.009189, lng: 18.0347962 },
    { lat: 49.0076258, lng: 18.03714 },
    { lat: 49.0073187, lng: 18.0376019 },
    { lat: 49.0068944, lng: 18.0386037 },
    { lat: 49.0067606, lng: 18.0392487 },
    { lat: 49.0067493, lng: 18.0397672 },
    { lat: 49.0067939, lng: 18.0404323 },
    { lat: 49.0067772, lng: 18.040862 },
    { lat: 49.0067787, lng: 18.0419445 },
    { lat: 49.006727, lng: 18.0426615 },
    { lat: 49.0064694, lng: 18.0429872 },
    { lat: 49.0057649, lng: 18.043443 },
    { lat: 49.0055496, lng: 18.0436404 },
    { lat: 49.005421, lng: 18.0438346 },
    { lat: 49.0052895, lng: 18.0443844 },
    { lat: 49.0049906, lng: 18.0450629 },
    { lat: 49.0048017, lng: 18.0455336 },
    { lat: 49.0046514, lng: 18.0461128 },
    { lat: 49.0043767, lng: 18.0467765 },
    { lat: 49.0042036, lng: 18.0478103 },
    { lat: 49.0043578, lng: 18.049154 },
    { lat: 49.0042195, lng: 18.0500713 },
    { lat: 49.003162, lng: 18.0518073 },
    { lat: 49.002589, lng: 18.0565043 },
    { lat: 49.0036687, lng: 18.0570012 },
    { lat: 49.0036878, lng: 18.0570001 },
    { lat: 49.0037194, lng: 18.056998 },
    { lat: 49.0037388, lng: 18.0573501 },
    { lat: 49.0035985, lng: 18.05796 },
    { lat: 49.003186, lng: 18.058607 },
    { lat: 49.0023807, lng: 18.0597185 },
    { lat: 49.0017172, lng: 18.0604892 },
    { lat: 49.0011747, lng: 18.0609423 },
    { lat: 49.0003075, lng: 18.0619388 },
    { lat: 48.999626, lng: 18.0622518 },
    { lat: 48.9989009, lng: 18.0620487 },
    { lat: 48.9985052, lng: 18.0623221 },
    { lat: 48.9983067, lng: 18.0625199 },
    { lat: 48.9977747, lng: 18.063051 },
    { lat: 48.9975311, lng: 18.0636337 },
    { lat: 48.997544, lng: 18.0638951 },
    { lat: 48.9981297, lng: 18.0647847 },
    { lat: 48.9983234, lng: 18.0653164 },
    { lat: 48.9982635, lng: 18.0660329 },
    { lat: 48.9979464, lng: 18.0667667 },
    { lat: 48.9978181, lng: 18.0670637 },
    { lat: 48.997431, lng: 18.0675665 },
    { lat: 48.9970098, lng: 18.067891 },
    { lat: 48.9963467, lng: 18.0685547 },
    { lat: 48.9955188, lng: 18.0691849 },
    { lat: 48.9953335, lng: 18.0695832 },
    { lat: 48.9951887, lng: 18.0702759 },
    { lat: 48.9950707, lng: 18.0710781 },
    { lat: 48.9948796, lng: 18.0717101 },
    { lat: 48.9942982, lng: 18.0729445 },
    { lat: 48.9937534, lng: 18.0735656 },
    { lat: 48.9931655, lng: 18.0738783 },
    { lat: 48.9929464, lng: 18.0740515 },
    { lat: 48.9920431, lng: 18.0745268 },
    { lat: 48.991791, lng: 18.0748665 },
    { lat: 48.9916831, lng: 18.0751459 },
    { lat: 48.9914191, lng: 18.0758257 },
    { lat: 48.991312, lng: 18.0766577 },
    { lat: 48.9908147, lng: 18.0778842 },
    { lat: 48.990378, lng: 18.0789689 },
    { lat: 48.9902971, lng: 18.0789769 },
    { lat: 48.9879028, lng: 18.0793504 },
    { lat: 48.9878859, lng: 18.0793527 },
    { lat: 48.9880317, lng: 18.0797452 },
    { lat: 48.9878672, lng: 18.0805011 },
    { lat: 48.9874837, lng: 18.0811279 },
    { lat: 48.9873533, lng: 18.0822019 },
    { lat: 48.9872591, lng: 18.0836069 },
    { lat: 48.9872801, lng: 18.0838905 },
    { lat: 48.9872861, lng: 18.0839716 },
    { lat: 48.9872988, lng: 18.0841542 },
    { lat: 48.9872139, lng: 18.084844 },
    { lat: 48.9861849, lng: 18.0866136 },
    { lat: 48.9853489, lng: 18.0879386 },
    { lat: 48.9852863, lng: 18.087809 },
    { lat: 48.9841588, lng: 18.0890904 },
    { lat: 48.9834515, lng: 18.0896755 },
    { lat: 48.9831344, lng: 18.0900044 },
    { lat: 48.9823639, lng: 18.0911186 },
    { lat: 48.9818804, lng: 18.0920174 },
    { lat: 48.9819528, lng: 18.0920996 },
    { lat: 48.9819861, lng: 18.0921282 },
    { lat: 48.9825436, lng: 18.0926146 },
    { lat: 48.9826349, lng: 18.0926914 },
    { lat: 48.9828726, lng: 18.0931115 },
    { lat: 48.9830549, lng: 18.0934528 },
    { lat: 48.9830728, lng: 18.0934877 },
    { lat: 48.9833495, lng: 18.0938006 },
    { lat: 48.9834506, lng: 18.0942225 },
    { lat: 48.9833986, lng: 18.0945122 },
    { lat: 48.9837032, lng: 18.0951306 },
    { lat: 48.983879, lng: 18.0957667 },
    { lat: 48.9837858, lng: 18.0960666 },
    { lat: 48.9836513, lng: 18.0962959 },
    { lat: 48.9832086, lng: 18.0967919 },
    { lat: 48.983116, lng: 18.0969491 },
    { lat: 48.9834117, lng: 18.0976456 },
    { lat: 48.9836459, lng: 18.0981032 },
    { lat: 48.9837706, lng: 18.0985244 },
    { lat: 48.9837992, lng: 18.0987243 },
    { lat: 48.9837922, lng: 18.0990094 },
    { lat: 48.9837901, lng: 18.099051 },
    { lat: 48.9838079, lng: 18.0991467 },
    { lat: 48.9839399, lng: 18.0998067 },
    { lat: 48.9835909, lng: 18.0999375 },
    { lat: 48.9833125, lng: 18.1001351 },
    { lat: 48.9829759, lng: 18.1003056 },
    { lat: 48.9826637, lng: 18.1003726 },
    { lat: 48.9823515, lng: 18.100495 },
    { lat: 48.9818648, lng: 18.1006859 },
    { lat: 48.981343, lng: 18.100891 },
    { lat: 48.9810184, lng: 18.1009165 },
    { lat: 48.9805405, lng: 18.101079 },
    { lat: 48.9801701, lng: 18.1011553 },
    { lat: 48.9796769, lng: 18.1014449 },
    { lat: 48.979799, lng: 18.1018384 },
    { lat: 48.9802607, lng: 18.1021333 },
    { lat: 48.9807067, lng: 18.1028944 },
    { lat: 48.9810467, lng: 18.102976 },
    { lat: 48.9820324, lng: 18.1035715 },
    { lat: 48.9822032, lng: 18.1036747 },
    { lat: 48.982716, lng: 18.1040109 },
    { lat: 48.9828342, lng: 18.1040841 },
    { lat: 48.9832532, lng: 18.1043632 },
    { lat: 48.9841579, lng: 18.1049577 },
    { lat: 48.984863, lng: 18.1045097 },
    { lat: 48.9850523, lng: 18.1043895 },
    { lat: 48.9851112, lng: 18.1043521 },
    { lat: 48.9858734, lng: 18.1038727 },
    { lat: 48.9867125, lng: 18.105149 },
    { lat: 48.9867967, lng: 18.1052768 },
    { lat: 48.9868733, lng: 18.1053937 },
    { lat: 48.986913, lng: 18.1054541 },
    { lat: 48.9869841, lng: 18.1057259 },
    { lat: 48.9870144, lng: 18.106074 },
    { lat: 48.9870008, lng: 18.1062102 },
    { lat: 48.9870026, lng: 18.1062772 },
    { lat: 48.9870321, lng: 18.1065132 },
    { lat: 48.9870332, lng: 18.1065149 },
    { lat: 48.9871019, lng: 18.1071337 },
    { lat: 48.9871757, lng: 18.1074925 },
    { lat: 48.9872538, lng: 18.1078072 },
    { lat: 48.9874047, lng: 18.1085987 },
    { lat: 48.9875681, lng: 18.1099763 },
    { lat: 48.9876164, lng: 18.1099329 },
    { lat: 48.9876653, lng: 18.1099813 },
    { lat: 48.9882588, lng: 18.1105637 },
    { lat: 48.9881668, lng: 18.1114481 },
    { lat: 48.9881154, lng: 18.1123892 },
    { lat: 48.9881214, lng: 18.112554 },
    { lat: 48.9881629, lng: 18.1127436 },
    { lat: 48.9882994, lng: 18.1132298 },
    { lat: 48.9883154, lng: 18.1132902 },
    { lat: 48.9880939, lng: 18.1133238 },
    { lat: 48.987554, lng: 18.1131032 },
    { lat: 48.9879894, lng: 18.1136424 },
    { lat: 48.9882038, lng: 18.1139752 },
    { lat: 48.9886766, lng: 18.1147517 },
    { lat: 48.9890178, lng: 18.1153237 },
    { lat: 48.988714, lng: 18.1156141 },
    { lat: 48.9888464, lng: 18.1159485 },
    { lat: 48.988365, lng: 18.1166025 },
    { lat: 48.9880949, lng: 18.1170429 },
    { lat: 48.9881199, lng: 18.1171031 },
    { lat: 48.9878671, lng: 18.1176698 },
    { lat: 48.9877885, lng: 18.1180224 },
    { lat: 48.9877182, lng: 18.1184676 },
    { lat: 48.987654, lng: 18.118672 },
    { lat: 48.989824, lng: 18.118111 },
    { lat: 48.990272, lng: 18.117909 },
    { lat: 48.991989, lng: 18.117502 },
    { lat: 48.993607, lng: 18.117272 },
    { lat: 48.994272, lng: 18.117744 },
    { lat: 48.995502, lng: 18.117874 },
    { lat: 48.996522, lng: 18.117565 },
    { lat: 48.99708, lng: 18.117308 },
    { lat: 48.997427, lng: 18.117148 },
    { lat: 48.99765, lng: 18.117082 },
    { lat: 48.997783, lng: 18.117042 },
    { lat: 48.999154, lng: 18.116631 },
    { lat: 48.999645, lng: 18.115759 },
    { lat: 48.999996, lng: 18.115198 },
    { lat: 49.00106, lng: 18.114354 },
    { lat: 49.001717, lng: 18.114072 },
    { lat: 49.0026, lng: 18.114302 },
    { lat: 49.002877, lng: 18.114279 },
    { lat: 49.0032, lng: 18.114086 },
    { lat: 49.003594, lng: 18.113659 },
    { lat: 49.003728, lng: 18.113268 },
    { lat: 49.003839, lng: 18.112945 },
    { lat: 49.003956, lng: 18.112606 },
    { lat: 49.004277, lng: 18.111658 },
    { lat: 49.004716, lng: 18.110411 },
    { lat: 49.00503, lng: 18.109562 },
    { lat: 49.005643, lng: 18.109859 },
    { lat: 49.005719, lng: 18.109624 },
    { lat: 49.005826, lng: 18.109412 },
    { lat: 49.006001, lng: 18.109332 },
    { lat: 49.006656, lng: 18.109497 },
    { lat: 49.006792, lng: 18.109223 },
    { lat: 49.00689, lng: 18.109024 },
    { lat: 49.007142, lng: 18.108513 },
    { lat: 49.007151, lng: 18.108493 },
    { lat: 49.007851, lng: 18.107442 },
    { lat: 49.008643, lng: 18.106873 },
    { lat: 49.008786, lng: 18.106776 },
    { lat: 49.009188, lng: 18.106503 },
    { lat: 49.009227, lng: 18.106517 },
    { lat: 49.009779, lng: 18.106713 },
    { lat: 49.010242, lng: 18.107077 },
    { lat: 49.010923, lng: 18.106846 },
    { lat: 49.011029, lng: 18.106372 },
    { lat: 49.011244, lng: 18.106044 },
    { lat: 49.011484, lng: 18.105751 },
    { lat: 49.011681, lng: 18.105531 },
  ],
  Nemšová: [
    { lat: 48.987654, lng: 18.118672 },
    { lat: 48.9877182, lng: 18.1184676 },
    { lat: 48.9877885, lng: 18.1180224 },
    { lat: 48.9878671, lng: 18.1176698 },
    { lat: 48.9881199, lng: 18.1171031 },
    { lat: 48.9880949, lng: 18.1170429 },
    { lat: 48.988365, lng: 18.1166025 },
    { lat: 48.9888464, lng: 18.1159485 },
    { lat: 48.988714, lng: 18.1156141 },
    { lat: 48.9890178, lng: 18.1153237 },
    { lat: 48.9886766, lng: 18.1147517 },
    { lat: 48.9882038, lng: 18.1139752 },
    { lat: 48.9879894, lng: 18.1136424 },
    { lat: 48.987554, lng: 18.1131032 },
    { lat: 48.9880939, lng: 18.1133238 },
    { lat: 48.9883154, lng: 18.1132902 },
    { lat: 48.9882994, lng: 18.1132298 },
    { lat: 48.9881629, lng: 18.1127436 },
    { lat: 48.9881214, lng: 18.112554 },
    { lat: 48.9881154, lng: 18.1123892 },
    { lat: 48.9881668, lng: 18.1114481 },
    { lat: 48.9882588, lng: 18.1105637 },
    { lat: 48.9876653, lng: 18.1099813 },
    { lat: 48.9876164, lng: 18.1099329 },
    { lat: 48.9875681, lng: 18.1099763 },
    { lat: 48.9874047, lng: 18.1085987 },
    { lat: 48.9872538, lng: 18.1078072 },
    { lat: 48.9871757, lng: 18.1074925 },
    { lat: 48.9871019, lng: 18.1071337 },
    { lat: 48.9870332, lng: 18.1065149 },
    { lat: 48.9870321, lng: 18.1065132 },
    { lat: 48.9870026, lng: 18.1062772 },
    { lat: 48.9870008, lng: 18.1062102 },
    { lat: 48.9870144, lng: 18.106074 },
    { lat: 48.9869841, lng: 18.1057259 },
    { lat: 48.986913, lng: 18.1054541 },
    { lat: 48.9868733, lng: 18.1053937 },
    { lat: 48.9867967, lng: 18.1052768 },
    { lat: 48.9867125, lng: 18.105149 },
    { lat: 48.9858734, lng: 18.1038727 },
    { lat: 48.9851112, lng: 18.1043521 },
    { lat: 48.9850523, lng: 18.1043895 },
    { lat: 48.984863, lng: 18.1045097 },
    { lat: 48.9841579, lng: 18.1049577 },
    { lat: 48.9832532, lng: 18.1043632 },
    { lat: 48.9828342, lng: 18.1040841 },
    { lat: 48.982716, lng: 18.1040109 },
    { lat: 48.9822032, lng: 18.1036747 },
    { lat: 48.9820324, lng: 18.1035715 },
    { lat: 48.9810467, lng: 18.102976 },
    { lat: 48.9807067, lng: 18.1028944 },
    { lat: 48.9802607, lng: 18.1021333 },
    { lat: 48.979799, lng: 18.1018384 },
    { lat: 48.9796769, lng: 18.1014449 },
    { lat: 48.9801701, lng: 18.1011553 },
    { lat: 48.9805405, lng: 18.101079 },
    { lat: 48.9810184, lng: 18.1009165 },
    { lat: 48.981343, lng: 18.100891 },
    { lat: 48.9818648, lng: 18.1006859 },
    { lat: 48.9823515, lng: 18.100495 },
    { lat: 48.9826637, lng: 18.1003726 },
    { lat: 48.9829759, lng: 18.1003056 },
    { lat: 48.9833125, lng: 18.1001351 },
    { lat: 48.9835909, lng: 18.0999375 },
    { lat: 48.9839399, lng: 18.0998067 },
    { lat: 48.9838079, lng: 18.0991467 },
    { lat: 48.9837901, lng: 18.099051 },
    { lat: 48.9837922, lng: 18.0990094 },
    { lat: 48.9837992, lng: 18.0987243 },
    { lat: 48.9837706, lng: 18.0985244 },
    { lat: 48.9836459, lng: 18.0981032 },
    { lat: 48.9834117, lng: 18.0976456 },
    { lat: 48.983116, lng: 18.0969491 },
    { lat: 48.9832086, lng: 18.0967919 },
    { lat: 48.9836513, lng: 18.0962959 },
    { lat: 48.9837858, lng: 18.0960666 },
    { lat: 48.983879, lng: 18.0957667 },
    { lat: 48.9837032, lng: 18.0951306 },
    { lat: 48.9833986, lng: 18.0945122 },
    { lat: 48.9834506, lng: 18.0942225 },
    { lat: 48.9833495, lng: 18.0938006 },
    { lat: 48.9830728, lng: 18.0934877 },
    { lat: 48.9830549, lng: 18.0934528 },
    { lat: 48.9828726, lng: 18.0931115 },
    { lat: 48.9826349, lng: 18.0926914 },
    { lat: 48.9825436, lng: 18.0926146 },
    { lat: 48.9819861, lng: 18.0921282 },
    { lat: 48.9819528, lng: 18.0920996 },
    { lat: 48.9818804, lng: 18.0920174 },
    { lat: 48.9823639, lng: 18.0911186 },
    { lat: 48.9831344, lng: 18.0900044 },
    { lat: 48.9834515, lng: 18.0896755 },
    { lat: 48.9841588, lng: 18.0890904 },
    { lat: 48.9852863, lng: 18.087809 },
    { lat: 48.9853489, lng: 18.0879386 },
    { lat: 48.9861849, lng: 18.0866136 },
    { lat: 48.9872139, lng: 18.084844 },
    { lat: 48.9872988, lng: 18.0841542 },
    { lat: 48.9872861, lng: 18.0839716 },
    { lat: 48.9872801, lng: 18.0838905 },
    { lat: 48.9872591, lng: 18.0836069 },
    { lat: 48.9873533, lng: 18.0822019 },
    { lat: 48.9874837, lng: 18.0811279 },
    { lat: 48.9878672, lng: 18.0805011 },
    { lat: 48.9880317, lng: 18.0797452 },
    { lat: 48.9878859, lng: 18.0793527 },
    { lat: 48.9879028, lng: 18.0793504 },
    { lat: 48.9902971, lng: 18.0789769 },
    { lat: 48.990378, lng: 18.0789689 },
    { lat: 48.9908147, lng: 18.0778842 },
    { lat: 48.991312, lng: 18.0766577 },
    { lat: 48.9914191, lng: 18.0758257 },
    { lat: 48.9916831, lng: 18.0751459 },
    { lat: 48.991791, lng: 18.0748665 },
    { lat: 48.9920431, lng: 18.0745268 },
    { lat: 48.9929464, lng: 18.0740515 },
    { lat: 48.9931655, lng: 18.0738783 },
    { lat: 48.9937534, lng: 18.0735656 },
    { lat: 48.9942982, lng: 18.0729445 },
    { lat: 48.9948796, lng: 18.0717101 },
    { lat: 48.9950707, lng: 18.0710781 },
    { lat: 48.9951887, lng: 18.0702759 },
    { lat: 48.9953335, lng: 18.0695832 },
    { lat: 48.9955188, lng: 18.0691849 },
    { lat: 48.9963467, lng: 18.0685547 },
    { lat: 48.9970098, lng: 18.067891 },
    { lat: 48.997431, lng: 18.0675665 },
    { lat: 48.9978181, lng: 18.0670637 },
    { lat: 48.9979464, lng: 18.0667667 },
    { lat: 48.9982635, lng: 18.0660329 },
    { lat: 48.9983234, lng: 18.0653164 },
    { lat: 48.9981297, lng: 18.0647847 },
    { lat: 48.997544, lng: 18.0638951 },
    { lat: 48.9975311, lng: 18.0636337 },
    { lat: 48.9977747, lng: 18.063051 },
    { lat: 48.9983067, lng: 18.0625199 },
    { lat: 48.9985052, lng: 18.0623221 },
    { lat: 48.9989009, lng: 18.0620487 },
    { lat: 48.999626, lng: 18.0622518 },
    { lat: 49.0003075, lng: 18.0619388 },
    { lat: 49.0011747, lng: 18.0609423 },
    { lat: 49.0017172, lng: 18.0604892 },
    { lat: 49.0023807, lng: 18.0597185 },
    { lat: 49.003186, lng: 18.058607 },
    { lat: 49.0035985, lng: 18.05796 },
    { lat: 49.0037388, lng: 18.0573501 },
    { lat: 49.0037194, lng: 18.056998 },
    { lat: 49.0036878, lng: 18.0570001 },
    { lat: 49.0036687, lng: 18.0570012 },
    { lat: 49.002589, lng: 18.0565043 },
    { lat: 49.003162, lng: 18.0518073 },
    { lat: 49.0042195, lng: 18.0500713 },
    { lat: 49.0043578, lng: 18.049154 },
    { lat: 49.0042036, lng: 18.0478103 },
    { lat: 49.0043767, lng: 18.0467765 },
    { lat: 49.0046514, lng: 18.0461128 },
    { lat: 49.0048017, lng: 18.0455336 },
    { lat: 49.0049906, lng: 18.0450629 },
    { lat: 49.0052895, lng: 18.0443844 },
    { lat: 49.005421, lng: 18.0438346 },
    { lat: 49.0055496, lng: 18.0436404 },
    { lat: 49.0057649, lng: 18.043443 },
    { lat: 49.0064694, lng: 18.0429872 },
    { lat: 49.006727, lng: 18.0426615 },
    { lat: 49.0067787, lng: 18.0419445 },
    { lat: 49.0067772, lng: 18.040862 },
    { lat: 49.0067939, lng: 18.0404323 },
    { lat: 49.0067493, lng: 18.0397672 },
    { lat: 49.0067606, lng: 18.0392487 },
    { lat: 49.0068944, lng: 18.0386037 },
    { lat: 49.0073187, lng: 18.0376019 },
    { lat: 49.0076258, lng: 18.03714 },
    { lat: 49.009189, lng: 18.0347962 },
    { lat: 49.0093159, lng: 18.0346096 },
    { lat: 49.0095233, lng: 18.0341663 },
    { lat: 49.0100349, lng: 18.0332831 },
    { lat: 49.0103556, lng: 18.0328233 },
    { lat: 49.0106279, lng: 18.0324675 },
    { lat: 49.0111361, lng: 18.0314697 },
    { lat: 49.0113433, lng: 18.030962 },
    { lat: 49.0125363, lng: 18.0315984 },
    { lat: 49.013843, lng: 18.0298466 },
    { lat: 49.014222, lng: 18.0293397 },
    { lat: 49.0146606, lng: 18.0263158 },
    { lat: 49.0163109, lng: 18.025801 },
    { lat: 49.0169258, lng: 18.0250484 },
    { lat: 49.0175819, lng: 18.0242471 },
    { lat: 49.0194551, lng: 18.0214392 },
    { lat: 49.0194823, lng: 18.0213985 },
    { lat: 49.0195755, lng: 18.0212585 },
    { lat: 49.0200664, lng: 18.0201056 },
    { lat: 49.02017, lng: 18.0198312 },
    { lat: 49.0211219, lng: 18.0173246 },
    { lat: 49.0213341, lng: 18.0161534 },
    { lat: 49.0213722, lng: 18.0145523 },
    { lat: 49.0210464, lng: 18.0134081 },
    { lat: 49.0210159, lng: 18.0126392 },
    { lat: 49.0207263, lng: 18.011535 },
    { lat: 49.0202482, lng: 18.0106413 },
    { lat: 49.0196918, lng: 18.0101032 },
    { lat: 49.0185457, lng: 18.0100336 },
    { lat: 49.0181278, lng: 18.0100092 },
    { lat: 49.0178646, lng: 18.0094876 },
    { lat: 49.0172397, lng: 18.0093007 },
    { lat: 49.0171104, lng: 18.0085068 },
    { lat: 49.0171315, lng: 18.0075226 },
    { lat: 49.0169328, lng: 18.0067137 },
    { lat: 49.0167304, lng: 18.0065179 },
    { lat: 49.0166802, lng: 18.006468 },
    { lat: 49.0164037, lng: 18.0061985 },
    { lat: 49.0158135, lng: 18.0062205 },
    { lat: 49.0152468, lng: 18.0058342 },
    { lat: 49.0146428, lng: 18.0058901 },
    { lat: 49.0139922, lng: 18.0060718 },
    { lat: 49.0132641, lng: 18.0059529 },
    { lat: 49.0123738, lng: 18.0063807 },
    { lat: 49.0122088, lng: 18.0063733 },
    { lat: 49.0121668, lng: 18.0063711 },
    { lat: 49.0119406, lng: 18.0063613 },
    { lat: 49.0110784, lng: 18.0062093 },
    { lat: 49.0104156, lng: 18.0063599 },
    { lat: 49.0099048, lng: 18.0067702 },
    { lat: 49.0096031, lng: 18.0070284 },
    { lat: 49.0092555, lng: 18.0079334 },
    { lat: 49.0089128, lng: 18.0089935 },
    { lat: 49.0087814, lng: 18.0099044 },
    { lat: 49.0087509, lng: 18.0101178 },
    { lat: 49.0084514, lng: 18.0112014 },
    { lat: 49.0085272, lng: 18.0124339 },
    { lat: 49.0084698, lng: 18.0132632 },
    { lat: 49.0084033, lng: 18.0137256 },
    { lat: 49.0084223, lng: 18.013952 },
    { lat: 49.007903, lng: 18.0150421 },
    { lat: 49.0078689, lng: 18.015632 },
    { lat: 49.0076418, lng: 18.0172816 },
    { lat: 49.007492, lng: 18.0182849 },
    { lat: 49.0074088, lng: 18.0193085 },
    { lat: 49.0074153, lng: 18.0199378 },
    { lat: 49.0074249, lng: 18.020291 },
    { lat: 49.0076044, lng: 18.0205804 },
    { lat: 49.0073493, lng: 18.0212905 },
    { lat: 49.0073156, lng: 18.0212764 },
    { lat: 49.0072775, lng: 18.0212604 },
    { lat: 49.0071677, lng: 18.0212145 },
    { lat: 49.0070518, lng: 18.0211656 },
    { lat: 49.0066377, lng: 18.0210891 },
    { lat: 49.0061524, lng: 18.0208598 },
    { lat: 49.0055711, lng: 18.0201076 },
    { lat: 49.0053551, lng: 18.0195053 },
    { lat: 49.0052306, lng: 18.0185948 },
    { lat: 49.0051408, lng: 18.017855 },
    { lat: 49.0048695, lng: 18.0174248 },
    { lat: 49.0047961, lng: 18.016984 },
    { lat: 49.0045041, lng: 18.0173152 },
    { lat: 49.0044755, lng: 18.0173478 },
    { lat: 49.0041709, lng: 18.0176933 },
    { lat: 49.0039245, lng: 18.0179561 },
    { lat: 49.0038399, lng: 18.0182674 },
    { lat: 49.0037101, lng: 18.0184975 },
    { lat: 49.0033763, lng: 18.0190942 },
    { lat: 49.0031447, lng: 18.0199539 },
    { lat: 49.0030889, lng: 18.0203896 },
    { lat: 49.0027726, lng: 18.0210159 },
    { lat: 49.0025212, lng: 18.021788 },
    { lat: 49.0023175, lng: 18.0224156 },
    { lat: 49.0020585, lng: 18.0233856 },
    { lat: 49.0020456, lng: 18.0234356 },
    { lat: 49.0018021, lng: 18.0232475 },
    { lat: 49.0015996, lng: 18.0229325 },
    { lat: 49.0014989, lng: 18.022853 },
    { lat: 49.0010119, lng: 18.0223034 },
    { lat: 49.000545, lng: 18.0222711 },
    { lat: 49.0002865, lng: 18.0221746 },
    { lat: 49.0001716, lng: 18.0220418 },
    { lat: 48.9999045, lng: 18.021243 },
    { lat: 48.9995575, lng: 18.0202147 },
    { lat: 48.9992597, lng: 18.0197763 },
    { lat: 48.9986628, lng: 18.01893 },
    { lat: 48.9981886, lng: 18.018218 },
    { lat: 48.9976884, lng: 18.017851 },
    { lat: 48.9975845, lng: 18.0181365 },
    { lat: 48.9971684, lng: 18.0192576 },
    { lat: 48.996849, lng: 18.020583 },
    { lat: 48.9967706, lng: 18.0208618 },
    { lat: 48.9967447, lng: 18.0209569 },
    { lat: 48.9965504, lng: 18.0216468 },
    { lat: 48.9960809, lng: 18.0231834 },
    { lat: 48.9955299, lng: 18.0238691 },
    { lat: 48.9946629, lng: 18.0242113 },
    { lat: 48.9939015, lng: 18.0244307 },
    { lat: 48.9930318, lng: 18.0250719 },
    { lat: 48.9926646, lng: 18.0262574 },
    { lat: 48.9922721, lng: 18.0276252 },
    { lat: 48.9921044, lng: 18.0291516 },
    { lat: 48.9919586, lng: 18.0302455 },
    { lat: 48.9918183, lng: 18.0309506 },
    { lat: 48.9916986, lng: 18.0315458 },
    { lat: 48.9916573, lng: 18.0317088 },
    { lat: 48.9916225, lng: 18.031845 },
    { lat: 48.9915246, lng: 18.0322356 },
    { lat: 48.9914225, lng: 18.0326279 },
    { lat: 48.9907694, lng: 18.0337305 },
    { lat: 48.9902093, lng: 18.0346476 },
    { lat: 48.9896956, lng: 18.0355308 },
    { lat: 48.9896211, lng: 18.0355516 },
    { lat: 48.9889317, lng: 18.0357438 },
    { lat: 48.988308, lng: 18.0361736 },
    { lat: 48.9873062, lng: 18.0371454 },
    { lat: 48.9864108, lng: 18.0368461 },
    { lat: 48.9858251, lng: 18.036638 },
    { lat: 48.9850931, lng: 18.0367417 },
    { lat: 48.9845466, lng: 18.0368315 },
    { lat: 48.9838848, lng: 18.0367292 },
    { lat: 48.9830169, lng: 18.0365359 },
    { lat: 48.9828362, lng: 18.0365158 },
    { lat: 48.9828001, lng: 18.0365117 },
    { lat: 48.982341, lng: 18.0364595 },
    { lat: 48.9817118, lng: 18.0367193 },
    { lat: 48.9811412, lng: 18.0369004 },
    { lat: 48.9810968, lng: 18.0369139 },
    { lat: 48.9810263, lng: 18.0369217 },
    { lat: 48.9806509, lng: 18.0379128 },
    { lat: 48.9804984, lng: 18.0382191 },
    { lat: 48.9803787, lng: 18.0387149 },
    { lat: 48.9803327, lng: 18.0390876 },
    { lat: 48.9802125, lng: 18.039345 },
    { lat: 48.9800247, lng: 18.0394541 },
    { lat: 48.9797144, lng: 18.039707 },
    { lat: 48.9796323, lng: 18.0397313 },
    { lat: 48.9795776, lng: 18.0398749 },
    { lat: 48.9793973, lng: 18.0402651 },
    { lat: 48.979397, lng: 18.0404897 },
    { lat: 48.9793308, lng: 18.0406926 },
    { lat: 48.979222, lng: 18.0407411 },
    { lat: 48.979149, lng: 18.0407858 },
    { lat: 48.9789503, lng: 18.0412859 },
    { lat: 48.978928, lng: 18.0415379 },
    { lat: 48.9787903, lng: 18.0417315 },
    { lat: 48.9784377, lng: 18.0420712 },
    { lat: 48.9781439, lng: 18.0422389 },
    { lat: 48.9779505, lng: 18.0423642 },
    { lat: 48.9777554, lng: 18.042466 },
    { lat: 48.9774242, lng: 18.0426649 },
    { lat: 48.9772837, lng: 18.0426362 },
    { lat: 48.9771666, lng: 18.0427982 },
    { lat: 48.977099, lng: 18.0427775 },
    { lat: 48.9769544, lng: 18.0429131 },
    { lat: 48.9766501, lng: 18.0431691 },
    { lat: 48.9763771, lng: 18.0434579 },
    { lat: 48.9762886, lng: 18.0434533 },
    { lat: 48.9762279, lng: 18.0435513 },
    { lat: 48.976034, lng: 18.0438624 },
    { lat: 48.9758724, lng: 18.0440546 },
    { lat: 48.9756657, lng: 18.0440502 },
    { lat: 48.9755942, lng: 18.044275 },
    { lat: 48.9753128, lng: 18.0445577 },
    { lat: 48.9752169, lng: 18.0444791 },
    { lat: 48.9751643, lng: 18.0447003 },
    { lat: 48.9749518, lng: 18.0449076 },
    { lat: 48.9748719, lng: 18.0451587 },
    { lat: 48.9747001, lng: 18.0453303 },
    { lat: 48.9746379, lng: 18.0455966 },
    { lat: 48.974502, lng: 18.0459164 },
    { lat: 48.9743688, lng: 18.0462631 },
    { lat: 48.9743604, lng: 18.0465229 },
    { lat: 48.9742879, lng: 18.0467518 },
    { lat: 48.9742327, lng: 18.0469066 },
    { lat: 48.9742435, lng: 18.0470912 },
    { lat: 48.9741922, lng: 18.0476615 },
    { lat: 48.9740376, lng: 18.0482769 },
    { lat: 48.9739411, lng: 18.0485389 },
    { lat: 48.9736938, lng: 18.0489596 },
    { lat: 48.973661, lng: 18.0490143 },
    { lat: 48.973595, lng: 18.0490261 },
    { lat: 48.9735526, lng: 18.0491291 },
    { lat: 48.9734603, lng: 18.0491926 },
    { lat: 48.9735326, lng: 18.0494056 },
    { lat: 48.9734007, lng: 18.0494277 },
    { lat: 48.9732943, lng: 18.049557 },
    { lat: 48.9733128, lng: 18.0497201 },
    { lat: 48.9731026, lng: 18.049732 },
    { lat: 48.9730223, lng: 18.0498833 },
    { lat: 48.9730084, lng: 18.0499739 },
    { lat: 48.9728588, lng: 18.0499629 },
    { lat: 48.9727829, lng: 18.050036 },
    { lat: 48.9727559, lng: 18.0502297 },
    { lat: 48.9725703, lng: 18.0503183 },
    { lat: 48.9724545, lng: 18.0503839 },
    { lat: 48.9722549, lng: 18.0506188 },
    { lat: 48.9720934, lng: 18.0507333 },
    { lat: 48.9721549, lng: 18.0508946 },
    { lat: 48.9720854, lng: 18.0509805 },
    { lat: 48.9719119, lng: 18.0509443 },
    { lat: 48.9718187, lng: 18.0510905 },
    { lat: 48.9716572, lng: 18.0512438 },
    { lat: 48.9715322, lng: 18.0512093 },
    { lat: 48.9714434, lng: 18.0513283 },
    { lat: 48.9713237, lng: 18.0514242 },
    { lat: 48.9712218, lng: 18.0514775 },
    { lat: 48.971099, lng: 18.051424 },
    { lat: 48.9710135, lng: 18.0515631 },
    { lat: 48.9708394, lng: 18.0517113 },
    { lat: 48.9706514, lng: 18.0516795 },
    { lat: 48.9704948, lng: 18.0515498 },
    { lat: 48.9703352, lng: 18.0515627 },
    { lat: 48.9702484, lng: 18.0515263 },
    { lat: 48.9701317, lng: 18.0516337 },
    { lat: 48.9698866, lng: 18.0516872 },
    { lat: 48.9698351, lng: 18.0517341 },
    { lat: 48.969798, lng: 18.0518914 },
    { lat: 48.9696335, lng: 18.0519136 },
    { lat: 48.9694095, lng: 18.0520207 },
    { lat: 48.9693285, lng: 18.0522949 },
    { lat: 48.9692297, lng: 18.0523798 },
    { lat: 48.9691076, lng: 18.0525687 },
    { lat: 48.9690108, lng: 18.0527854 },
    { lat: 48.9688698, lng: 18.052923 },
    { lat: 48.9688799, lng: 18.0530172 },
    { lat: 48.9686803, lng: 18.0531921 },
    { lat: 48.9685897, lng: 18.0531303 },
    { lat: 48.9685083, lng: 18.0531463 },
    { lat: 48.9684914, lng: 18.0532445 },
    { lat: 48.9683965, lng: 18.0533967 },
    { lat: 48.9682975, lng: 18.0535559 },
    { lat: 48.9681786, lng: 18.0534329 },
    { lat: 48.9681581, lng: 18.0534497 },
    { lat: 48.968147, lng: 18.0536071 },
    { lat: 48.968032, lng: 18.053688 },
    { lat: 48.9679883, lng: 18.0538656 },
    { lat: 48.9678395, lng: 18.0541001 },
    { lat: 48.9677698, lng: 18.0542783 },
    { lat: 48.9677928, lng: 18.0544002 },
    { lat: 48.967714, lng: 18.0546756 },
    { lat: 48.9675845, lng: 18.0547775 },
    { lat: 48.967567, lng: 18.0548229 },
    { lat: 48.9675848, lng: 18.0548766 },
    { lat: 48.9675463, lng: 18.0550107 },
    { lat: 48.9675764, lng: 18.0551769 },
    { lat: 48.96748, lng: 18.0554209 },
    { lat: 48.9673373, lng: 18.0553906 },
    { lat: 48.9671791, lng: 18.0556016 },
    { lat: 48.9671324, lng: 18.0555755 },
    { lat: 48.9670574, lng: 18.0555867 },
    { lat: 48.9669518, lng: 18.0557457 },
    { lat: 48.9668488, lng: 18.0558203 },
    { lat: 48.9668102, lng: 18.0559135 },
    { lat: 48.9666667, lng: 18.0560069 },
    { lat: 48.9666663, lng: 18.0562508 },
    { lat: 48.9666592, lng: 18.0564163 },
    { lat: 48.966677, lng: 18.056531 },
    { lat: 48.9666405, lng: 18.0567363 },
    { lat: 48.9666383, lng: 18.0570053 },
    { lat: 48.9664997, lng: 18.0571472 },
    { lat: 48.9664143, lng: 18.0572105 },
    { lat: 48.9663858, lng: 18.0573587 },
    { lat: 48.9663108, lng: 18.0573085 },
    { lat: 48.9662578, lng: 18.0573893 },
    { lat: 48.9661819, lng: 18.0574415 },
    { lat: 48.9661041, lng: 18.0573859 },
    { lat: 48.9659945, lng: 18.0574409 },
    { lat: 48.96598, lng: 18.0573861 },
    { lat: 48.9654655, lng: 18.0574455 },
    { lat: 48.9649034, lng: 18.0575012 },
    { lat: 48.96421, lng: 18.0574363 },
    { lat: 48.9635481, lng: 18.0573679 },
    { lat: 48.9633791, lng: 18.0574302 },
    { lat: 48.9627169, lng: 18.0573777 },
    { lat: 48.9620826, lng: 18.0573573 },
    { lat: 48.9618001, lng: 18.0573304 },
    { lat: 48.9610153, lng: 18.0571503 },
    { lat: 48.9604237, lng: 18.0567589 },
    { lat: 48.9601316, lng: 18.0564547 },
    { lat: 48.9596474, lng: 18.0561059 },
    { lat: 48.9588771, lng: 18.0554969 },
    { lat: 48.9578202, lng: 18.0565111 },
    { lat: 48.9576505, lng: 18.0567918 },
    { lat: 48.9573519, lng: 18.0577439 },
    { lat: 48.9571882, lng: 18.0584189 },
    { lat: 48.9570171, lng: 18.0590061 },
    { lat: 48.9566683, lng: 18.0606461 },
    { lat: 48.9564733, lng: 18.061056 },
    { lat: 48.9561861, lng: 18.0613536 },
    { lat: 48.955695, lng: 18.0615649 },
    { lat: 48.9549682, lng: 18.0618707 },
    { lat: 48.9543707, lng: 18.0620396 },
    { lat: 48.9541113, lng: 18.0622943 },
    { lat: 48.9537832, lng: 18.0631874 },
    { lat: 48.9537217, lng: 18.0635228 },
    { lat: 48.9536425, lng: 18.0637127 },
    { lat: 48.9532717, lng: 18.0642431 },
    { lat: 48.9532861, lng: 18.064392 },
    { lat: 48.9527285, lng: 18.0650851 },
    { lat: 48.9519129, lng: 18.065786 },
    { lat: 48.9515537, lng: 18.0660538 },
    { lat: 48.9511457, lng: 18.0663324 },
    { lat: 48.9507623, lng: 18.0667445 },
    { lat: 48.9505958, lng: 18.0668079 },
    { lat: 48.9504417, lng: 18.0671464 },
    { lat: 48.950499, lng: 18.0677021 },
    { lat: 48.9506048, lng: 18.0677194 },
    { lat: 48.9506672, lng: 18.0682256 },
    { lat: 48.9506443, lng: 18.0691295 },
    { lat: 48.9506068, lng: 18.0695479 },
    { lat: 48.9505369, lng: 18.0703235 },
    { lat: 48.95055, lng: 18.0705677 },
    { lat: 48.9505455, lng: 18.0709726 },
    { lat: 48.9505357, lng: 18.0710203 },
    { lat: 48.9504587, lng: 18.0714023 },
    { lat: 48.9504059, lng: 18.0715394 },
    { lat: 48.9503266, lng: 18.0722513 },
    { lat: 48.9501199, lng: 18.073138 },
    { lat: 48.9500986, lng: 18.0733724 },
    { lat: 48.9498555, lng: 18.073981 },
    { lat: 48.9497855, lng: 18.0741549 },
    { lat: 48.9461758, lng: 18.083181 },
    { lat: 48.9460172, lng: 18.0835965 },
    { lat: 48.9451176, lng: 18.0859382 },
    { lat: 48.9445204, lng: 18.0873299 },
    { lat: 48.9444625, lng: 18.0874597 },
    { lat: 48.9440254, lng: 18.0880917 },
    { lat: 48.9436325, lng: 18.0886506 },
    { lat: 48.9434416, lng: 18.0889222 },
    { lat: 48.9432438, lng: 18.0892033 },
    { lat: 48.9432038, lng: 18.0892571 },
    { lat: 48.9431987, lng: 18.0892667 },
    { lat: 48.9431588, lng: 18.0893518 },
    { lat: 48.9429323, lng: 18.0898248 },
    { lat: 48.9426416, lng: 18.0904332 },
    { lat: 48.941526, lng: 18.0927699 },
    { lat: 48.9412342, lng: 18.0933805 },
    { lat: 48.9420769, lng: 18.0945933 },
    { lat: 48.9430351, lng: 18.0959765 },
    { lat: 48.9434296, lng: 18.096432 },
    { lat: 48.9439874, lng: 18.0970777 },
    { lat: 48.9446989, lng: 18.0979018 },
    { lat: 48.9448079, lng: 18.0980283 },
    { lat: 48.944935, lng: 18.0981401 },
    { lat: 48.9449778, lng: 18.0981775 },
    { lat: 48.944998, lng: 18.0981952 },
    { lat: 48.945086, lng: 18.0982732 },
    { lat: 48.9454322, lng: 18.0985772 },
    { lat: 48.9457164, lng: 18.0988281 },
    { lat: 48.9458153, lng: 18.0989158 },
    { lat: 48.9459345, lng: 18.0990043 },
    { lat: 48.9459426, lng: 18.0990097 },
    { lat: 48.9459638, lng: 18.0990255 },
    { lat: 48.945998, lng: 18.0990514 },
    { lat: 48.946038, lng: 18.0990812 },
    { lat: 48.9460439, lng: 18.0990848 },
    { lat: 48.9470288, lng: 18.0998166 },
    { lat: 48.9470419, lng: 18.0998263 },
    { lat: 48.9470735, lng: 18.0998497 },
    { lat: 48.9470901, lng: 18.0998622 },
    { lat: 48.947102, lng: 18.0998714 },
    { lat: 48.9471101, lng: 18.0998775 },
    { lat: 48.9471491, lng: 18.0999057 },
    { lat: 48.9471645, lng: 18.099918 },
    { lat: 48.9471821, lng: 18.0999311 },
    { lat: 48.9471932, lng: 18.0999482 },
    { lat: 48.947202, lng: 18.0999618 },
    { lat: 48.9472441, lng: 18.1000258 },
    { lat: 48.947254, lng: 18.1000417 },
    { lat: 48.9472677, lng: 18.1000625 },
    { lat: 48.9472726, lng: 18.1000697 },
    { lat: 48.9472813, lng: 18.1000843 },
    { lat: 48.9472901, lng: 18.1000982 },
    { lat: 48.9473013, lng: 18.1001151 },
    { lat: 48.9473322, lng: 18.1001619 },
    { lat: 48.9473496, lng: 18.1001901 },
    { lat: 48.947367, lng: 18.1002158 },
    { lat: 48.9474091, lng: 18.1002812 },
    { lat: 48.9474177, lng: 18.1002955 },
    { lat: 48.9474377, lng: 18.1003258 },
    { lat: 48.9474575, lng: 18.1003569 },
    { lat: 48.9474661, lng: 18.1003709 },
    { lat: 48.9474811, lng: 18.1003929 },
    { lat: 48.947486, lng: 18.1004009 },
    { lat: 48.9477911, lng: 18.1008727 },
    { lat: 48.9478232, lng: 18.1009215 },
    { lat: 48.9485982, lng: 18.1021221 },
    { lat: 48.9488887, lng: 18.1028688 },
    { lat: 48.9489259, lng: 18.1029643 },
    { lat: 48.9490744, lng: 18.1035016 },
    { lat: 48.9491928, lng: 18.103927 },
    { lat: 48.949353, lng: 18.1045066 },
    { lat: 48.9493691, lng: 18.1045727 },
    { lat: 48.9495696, lng: 18.105419 },
    { lat: 48.9495815, lng: 18.1054702 },
    { lat: 48.94968, lng: 18.1058829 },
    { lat: 48.9500169, lng: 18.1073116 },
    { lat: 48.9501094, lng: 18.1076996 },
    { lat: 48.9501681, lng: 18.1079524 },
    { lat: 48.950171, lng: 18.1079653 },
    { lat: 48.9502038, lng: 18.1081024 },
    { lat: 48.9504628, lng: 18.1091998 },
    { lat: 48.9505076, lng: 18.1093895 },
    { lat: 48.9505373, lng: 18.109513 },
    { lat: 48.9505912, lng: 18.1097416 },
    { lat: 48.9505958, lng: 18.1097633 },
    { lat: 48.9507467, lng: 18.1104009 },
    { lat: 48.9508409, lng: 18.1107965 },
    { lat: 48.9510638, lng: 18.1117404 },
    { lat: 48.9511029, lng: 18.111909 },
    { lat: 48.9511269, lng: 18.1120099 },
    { lat: 48.9511875, lng: 18.1121982 },
    { lat: 48.9512025, lng: 18.1122439 },
    { lat: 48.9515083, lng: 18.1131983 },
    { lat: 48.9517228, lng: 18.1138597 },
    { lat: 48.9519447, lng: 18.1145349 },
    { lat: 48.9519583, lng: 18.1145726 },
    { lat: 48.9521092, lng: 18.1150339 },
    { lat: 48.952297, lng: 18.1156264 },
    { lat: 48.9523909, lng: 18.1159237 },
    { lat: 48.9524172, lng: 18.1160067 },
    { lat: 48.9526907, lng: 18.1167931 },
    { lat: 48.9526364, lng: 18.1171199 },
    { lat: 48.9525084, lng: 18.1178735 },
    { lat: 48.952442, lng: 18.1179358 },
    { lat: 48.9524303, lng: 18.117946 },
    { lat: 48.9518622, lng: 18.1184822 },
    { lat: 48.9518222, lng: 18.1185156 },
    { lat: 48.9512775, lng: 18.1192386 },
    { lat: 48.9508597, lng: 18.1197788 },
    { lat: 48.9507935, lng: 18.1198842 },
    { lat: 48.9505402, lng: 18.1207327 },
    { lat: 48.950384, lng: 18.1212483 },
    { lat: 48.9503788, lng: 18.1216853 },
    { lat: 48.9505949, lng: 18.1217752 },
    { lat: 48.9510243, lng: 18.1218231 },
    { lat: 48.9511245, lng: 18.1218955 },
    { lat: 48.9513799, lng: 18.1228398 },
    { lat: 48.9514719, lng: 18.1231783 },
    { lat: 48.9515174, lng: 18.1238286 },
    { lat: 48.9513841, lng: 18.1241569 },
    { lat: 48.9520959, lng: 18.1254198 },
    { lat: 48.9526352, lng: 18.1263532 },
    { lat: 48.9527206, lng: 18.1265055 },
    { lat: 48.9527283, lng: 18.1265196 },
    { lat: 48.9527642, lng: 18.1265758 },
    { lat: 48.9528216, lng: 18.1266694 },
    { lat: 48.9528587, lng: 18.1267252 },
    { lat: 48.9529834, lng: 18.1269308 },
    { lat: 48.953118, lng: 18.1271487 },
    { lat: 48.9532312, lng: 18.1273315 },
    { lat: 48.9533122, lng: 18.127465 },
    { lat: 48.9534087, lng: 18.127628 },
    { lat: 48.9538115, lng: 18.1277851 },
    { lat: 48.954032, lng: 18.1278691 },
    { lat: 48.9544948, lng: 18.1280501 },
    { lat: 48.9546028, lng: 18.1280601 },
    { lat: 48.9545891, lng: 18.1283352 },
    { lat: 48.9547329, lng: 18.1287318 },
    { lat: 48.9548302, lng: 18.1291615 },
    { lat: 48.9549819, lng: 18.1294666 },
    { lat: 48.9550853, lng: 18.1297505 },
    { lat: 48.9550907, lng: 18.13006 },
    { lat: 48.9550984, lng: 18.1305232 },
    { lat: 48.9551982, lng: 18.130999 },
    { lat: 48.9553211, lng: 18.1313122 },
    { lat: 48.9554248, lng: 18.1316988 },
    { lat: 48.9554343, lng: 18.1317287 },
    { lat: 48.9555322, lng: 18.1322101 },
    { lat: 48.9555755, lng: 18.1323131 },
    { lat: 48.9556897, lng: 18.1327101 },
    { lat: 48.9558289, lng: 18.133084 },
    { lat: 48.9558608, lng: 18.1331706 },
    { lat: 48.956274, lng: 18.133266 },
    { lat: 48.956686, lng: 18.133375 },
    { lat: 48.957542, lng: 18.1336 },
    { lat: 48.958347, lng: 18.133257 },
    { lat: 48.958368, lng: 18.133264 },
    { lat: 48.958503, lng: 18.133313 },
    { lat: 48.958565, lng: 18.133336 },
    { lat: 48.959343, lng: 18.133619 },
    { lat: 48.959456, lng: 18.13366 },
    { lat: 48.959664, lng: 18.133735 },
    { lat: 48.959756, lng: 18.133769 },
    { lat: 48.960004, lng: 18.134087 },
    { lat: 48.963133, lng: 18.138077 },
    { lat: 48.96402, lng: 18.139209 },
    { lat: 48.964902, lng: 18.140332 },
    { lat: 48.965447, lng: 18.141056 },
    { lat: 48.965684, lng: 18.141371 },
    { lat: 48.966177, lng: 18.142004 },
    { lat: 48.966254, lng: 18.142104 },
    { lat: 48.966332, lng: 18.142202 },
    { lat: 48.966856, lng: 18.142875 },
    { lat: 48.96691, lng: 18.142941 },
    { lat: 48.967585, lng: 18.14379 },
    { lat: 48.968311, lng: 18.144696 },
    { lat: 48.968523, lng: 18.144961 },
    { lat: 48.968768, lng: 18.145269 },
    { lat: 48.9689059, lng: 18.1454419 },
    { lat: 48.969857, lng: 18.146635 },
    { lat: 48.970454, lng: 18.147384 },
    { lat: 48.970753, lng: 18.147768 },
    { lat: 48.970653, lng: 18.145729 },
    { lat: 48.970636, lng: 18.145395 },
    { lat: 48.970581, lng: 18.144325 },
    { lat: 48.970465, lng: 18.141938 },
    { lat: 48.970812, lng: 18.140273 },
    { lat: 48.97092, lng: 18.139757 },
    { lat: 48.970948, lng: 18.139628 },
    { lat: 48.971105, lng: 18.138874 },
    { lat: 48.971165, lng: 18.138584 },
    { lat: 48.971189, lng: 18.138472 },
    { lat: 48.971212, lng: 18.138381 },
    { lat: 48.971296, lng: 18.137957 },
    { lat: 48.971301, lng: 18.137934 },
    { lat: 48.971329, lng: 18.137803 },
    { lat: 48.971352, lng: 18.137692 },
    { lat: 48.971357, lng: 18.13767 },
    { lat: 48.97139, lng: 18.137511 },
    { lat: 48.971409, lng: 18.13742 },
    { lat: 48.971646, lng: 18.136287 },
    { lat: 48.971824, lng: 18.135886 },
    { lat: 48.971955, lng: 18.135658 },
    { lat: 48.972248, lng: 18.135276 },
    { lat: 48.972611, lng: 18.134864 },
    { lat: 48.972644, lng: 18.13483 },
    { lat: 48.972935, lng: 18.13452 },
    { lat: 48.973008, lng: 18.134588 },
    { lat: 48.973042, lng: 18.134619 },
    { lat: 48.973148, lng: 18.134718 },
    { lat: 48.973416, lng: 18.134129 },
    { lat: 48.973689, lng: 18.133573 },
    { lat: 48.974114, lng: 18.132734 },
    { lat: 48.97419, lng: 18.132602 },
    { lat: 48.974192, lng: 18.132599 },
    { lat: 48.974258, lng: 18.132454 },
    { lat: 48.975137, lng: 18.130876 },
    { lat: 48.975545, lng: 18.130177 },
    { lat: 48.975839, lng: 18.129673 },
    { lat: 48.975914, lng: 18.129545 },
    { lat: 48.976233, lng: 18.12902 },
    { lat: 48.976848, lng: 18.128009 },
    { lat: 48.977269, lng: 18.127317 },
    { lat: 48.977296, lng: 18.127272 },
    { lat: 48.977502, lng: 18.126934 },
    { lat: 48.977653, lng: 18.126686 },
    { lat: 48.977743, lng: 18.126538 },
    { lat: 48.977832, lng: 18.126391 },
    { lat: 48.977871, lng: 18.126354 },
    { lat: 48.977927, lng: 18.12628 },
    { lat: 48.978035, lng: 18.126163 },
    { lat: 48.978099, lng: 18.126048 },
    { lat: 48.978813, lng: 18.124915 },
    { lat: 48.978974, lng: 18.124468 },
    { lat: 48.979065, lng: 18.124337 },
    { lat: 48.979079, lng: 18.123945 },
    { lat: 48.979081, lng: 18.12391 },
    { lat: 48.979591, lng: 18.123502 },
    { lat: 48.979837, lng: 18.123314 },
    { lat: 48.980157, lng: 18.123068 },
    { lat: 48.980429, lng: 18.122881 },
    { lat: 48.980576, lng: 18.122782 },
    { lat: 48.980742, lng: 18.122689 },
    { lat: 48.981281, lng: 18.122418 },
    { lat: 48.981854, lng: 18.12216 },
    { lat: 48.982044, lng: 18.122065 },
    { lat: 48.982217, lng: 18.121967 },
    { lat: 48.982537, lng: 18.121732 },
    { lat: 48.982846, lng: 18.121476 },
    { lat: 48.984139, lng: 18.120453 },
    { lat: 48.98425, lng: 18.120702 },
    { lat: 48.98441, lng: 18.121029 },
    { lat: 48.984642, lng: 18.120754 },
    { lat: 48.984814, lng: 18.120649 },
    { lat: 48.985037, lng: 18.120572 },
    { lat: 48.98509, lng: 18.120553 },
    { lat: 48.985146, lng: 18.120467 },
    { lat: 48.985477, lng: 18.120331 },
    { lat: 48.985663, lng: 18.120191 },
    { lat: 48.985716, lng: 18.12015 },
    { lat: 48.987654, lng: 18.118672 },
  ],
  TrenčianskaTeplá: [
    { lat: 48.9090212, lng: 18.1357834 },
    { lat: 48.9090491, lng: 18.1358572 },
    { lat: 48.9091736, lng: 18.1361785 },
    { lat: 48.9092679, lng: 18.1364422 },
    { lat: 48.9094596, lng: 18.1367883 },
    { lat: 48.9095773, lng: 18.1370898 },
    { lat: 48.9096477, lng: 18.1373107 },
    { lat: 48.910058, lng: 18.1382584 },
    { lat: 48.9102191, lng: 18.1386248 },
    { lat: 48.9102643, lng: 18.1387225 },
    { lat: 48.910317, lng: 18.1388374 },
    { lat: 48.910421, lng: 18.13866 },
    { lat: 48.9105162, lng: 18.1389243 },
    { lat: 48.9107437, lng: 18.138876 },
    { lat: 48.9110354, lng: 18.1388659 },
    { lat: 48.9111897, lng: 18.139212 },
    { lat: 48.9114243, lng: 18.1398337 },
    { lat: 48.9117275, lng: 18.140395 },
    { lat: 48.9117453, lng: 18.1404282 },
    { lat: 48.9117591, lng: 18.1404543 },
    { lat: 48.9113276, lng: 18.1411876 },
    { lat: 48.9112385, lng: 18.1414416 },
    { lat: 48.9109709, lng: 18.1418894 },
    { lat: 48.9108492, lng: 18.1420872 },
    { lat: 48.9106482, lng: 18.1423779 },
    { lat: 48.9107498, lng: 18.1426701 },
    { lat: 48.9107785, lng: 18.1428151 },
    { lat: 48.9107764, lng: 18.1428972 },
    { lat: 48.9107767, lng: 18.1429237 },
    { lat: 48.9107739, lng: 18.1430511 },
    { lat: 48.9108285, lng: 18.1433118 },
    { lat: 48.9108531, lng: 18.1434836 },
    { lat: 48.9108888, lng: 18.1437296 },
    { lat: 48.9109455, lng: 18.1440844 },
    { lat: 48.9109477, lng: 18.1441028 },
    { lat: 48.9109484, lng: 18.1441138 },
    { lat: 48.9112679, lng: 18.1440211 },
    { lat: 48.9116206, lng: 18.143896 },
    { lat: 48.9117488, lng: 18.1438886 },
    { lat: 48.912018, lng: 18.1438977 },
    { lat: 48.9123161, lng: 18.1438239 },
    { lat: 48.912325, lng: 18.143822 },
    { lat: 48.9124524, lng: 18.1438011 },
    { lat: 48.9126521, lng: 18.1439335 },
    { lat: 48.9126717, lng: 18.1438842 },
    { lat: 48.9126923, lng: 18.1438309 },
    { lat: 48.9126943, lng: 18.1438242 },
    { lat: 48.9127243, lng: 18.1437384 },
    { lat: 48.9128679, lng: 18.1433146 },
    { lat: 48.9129823, lng: 18.1433044 },
    { lat: 48.9129771, lng: 18.1432728 },
    { lat: 48.9132451, lng: 18.1433796 },
    { lat: 48.9136486, lng: 18.1438192 },
    { lat: 48.914011, lng: 18.1439777 },
    { lat: 48.9141521, lng: 18.1440013 },
    { lat: 48.9143922, lng: 18.1438753 },
    { lat: 48.9146944, lng: 18.1437942 },
    { lat: 48.9148371, lng: 18.1436694 },
    { lat: 48.9150797, lng: 18.1433741 },
    { lat: 48.9152179, lng: 18.1431667 },
    { lat: 48.9156691, lng: 18.1427956 },
    { lat: 48.9157919, lng: 18.1427301 },
    { lat: 48.9161411, lng: 18.1426851 },
    { lat: 48.9162465, lng: 18.1426312 },
    { lat: 48.9165102, lng: 18.1422761 },
    { lat: 48.9168244, lng: 18.1418167 },
    { lat: 48.9169082, lng: 18.1418988 },
    { lat: 48.9171788, lng: 18.1423228 },
    { lat: 48.9172832, lng: 18.1425292 },
    { lat: 48.9172502, lng: 18.1426405 },
    { lat: 48.9169494, lng: 18.1433931 },
    { lat: 48.9169554, lng: 18.1433991 },
    { lat: 48.9181263, lng: 18.1445038 },
    { lat: 48.9191124, lng: 18.1454372 },
    { lat: 48.9191887, lng: 18.1455099 },
    { lat: 48.9192175, lng: 18.1455377 },
    { lat: 48.9205432, lng: 18.1467877 },
    { lat: 48.9205453, lng: 18.1467824 },
    { lat: 48.920672, lng: 18.146397 },
    { lat: 48.9208049, lng: 18.1460621 },
    { lat: 48.9209239, lng: 18.145817 },
    { lat: 48.920954, lng: 18.1457535 },
    { lat: 48.9210096, lng: 18.1456395 },
    { lat: 48.9212304, lng: 18.1451254 },
    { lat: 48.921484, lng: 18.1443582 },
    { lat: 48.9216474, lng: 18.1439041 },
    { lat: 48.9218527, lng: 18.1432374 },
    { lat: 48.921983, lng: 18.1428009 },
    { lat: 48.9221494, lng: 18.1425172 },
    { lat: 48.9223176, lng: 18.1423789 },
    { lat: 48.9223887, lng: 18.1423206 },
    { lat: 48.9226604, lng: 18.1422378 },
    { lat: 48.9227322, lng: 18.1421913 },
    { lat: 48.9228509, lng: 18.1420603 },
    { lat: 48.9229506, lng: 18.141891 },
    { lat: 48.9230683, lng: 18.1413708 },
    { lat: 48.9230961, lng: 18.1413039 },
    { lat: 48.92311, lng: 18.1413882 },
    { lat: 48.9231147, lng: 18.1414134 },
    { lat: 48.9231371, lng: 18.1415432 },
    { lat: 48.9231405, lng: 18.1415646 },
    { lat: 48.9231491, lng: 18.1416152 },
    { lat: 48.9233265, lng: 18.1424015 },
    { lat: 48.92351, lng: 18.1432329 },
    { lat: 48.9237619, lng: 18.1442931 },
    { lat: 48.9237705, lng: 18.1442823 },
    { lat: 48.9240616, lng: 18.1437182 },
    { lat: 48.9245327, lng: 18.1432001 },
    { lat: 48.9246962, lng: 18.1431762 },
    { lat: 48.9248253, lng: 18.1429886 },
    { lat: 48.9249471, lng: 18.1427147 },
    { lat: 48.9249786, lng: 18.142576 },
    { lat: 48.9249982, lng: 18.1425264 },
    { lat: 48.9251385, lng: 18.1421596 },
    { lat: 48.9251491, lng: 18.1421313 },
    { lat: 48.9251799, lng: 18.1420378 },
    { lat: 48.9252286, lng: 18.1418857 },
    { lat: 48.9253211, lng: 18.1420008 },
    { lat: 48.925461, lng: 18.1421763 },
    { lat: 48.9256144, lng: 18.1423715 },
    { lat: 48.9256773, lng: 18.1425228 },
    { lat: 48.9259689, lng: 18.1428425 },
    { lat: 48.9262926, lng: 18.1431899 },
    { lat: 48.9262973, lng: 18.1431929 },
    { lat: 48.926302, lng: 18.1431976 },
    { lat: 48.9261063, lng: 18.1440293 },
    { lat: 48.9260045, lng: 18.1443755 },
    { lat: 48.9258439, lng: 18.144666 },
    { lat: 48.9257342, lng: 18.1448945 },
    { lat: 48.9256461, lng: 18.1450688 },
    { lat: 48.9256511, lng: 18.145114 },
    { lat: 48.9257457, lng: 18.1451711 },
    { lat: 48.9263839, lng: 18.1456298 },
    { lat: 48.9271209, lng: 18.1461605 },
    { lat: 48.927148, lng: 18.146185 },
    { lat: 48.927158, lng: 18.146169 },
    { lat: 48.92717, lng: 18.14615 },
    { lat: 48.927877, lng: 18.144989 },
    { lat: 48.927595, lng: 18.144776 },
    { lat: 48.927543, lng: 18.144733 },
    { lat: 48.927447, lng: 18.144662 },
    { lat: 48.927371, lng: 18.144602 },
    { lat: 48.927406, lng: 18.144574 },
    { lat: 48.928223, lng: 18.143407 },
    { lat: 48.928227, lng: 18.1434 },
    { lat: 48.928245, lng: 18.143135 },
    { lat: 48.928372, lng: 18.143127 },
    { lat: 48.928369, lng: 18.143124 },
    { lat: 48.928101, lng: 18.14283 },
    { lat: 48.9281, lng: 18.142808 },
    { lat: 48.928136, lng: 18.142675 },
    { lat: 48.928155, lng: 18.142558 },
    { lat: 48.928184, lng: 18.142449 },
    { lat: 48.928218, lng: 18.142302 },
    { lat: 48.928256, lng: 18.142156 },
    { lat: 48.928331, lng: 18.141839 },
    { lat: 48.928375, lng: 18.141672 },
    { lat: 48.928395, lng: 18.141556 },
    { lat: 48.928457, lng: 18.141284 },
    { lat: 48.927693, lng: 18.140478 },
    { lat: 48.927622, lng: 18.140454 },
    { lat: 48.927683, lng: 18.140397 },
    { lat: 48.927688, lng: 18.140389 },
    { lat: 48.927728, lng: 18.140332 },
    { lat: 48.927779, lng: 18.140267 },
    { lat: 48.927841, lng: 18.140186 },
    { lat: 48.927903, lng: 18.140109 },
    { lat: 48.927934, lng: 18.140055 },
    { lat: 48.928, lng: 18.139984 },
    { lat: 48.928022, lng: 18.139963 },
    { lat: 48.928051, lng: 18.139923 },
    { lat: 48.928088, lng: 18.139885 },
    { lat: 48.928122, lng: 18.13985 },
    { lat: 48.928153, lng: 18.139818 },
    { lat: 48.928182, lng: 18.139776 },
    { lat: 48.928209, lng: 18.139729 },
    { lat: 48.928251, lng: 18.139668 },
    { lat: 48.928298, lng: 18.139607 },
    { lat: 48.928351, lng: 18.139553 },
    { lat: 48.928391, lng: 18.139511 },
    { lat: 48.928441, lng: 18.139464 },
    { lat: 48.928714, lng: 18.139147 },
    { lat: 48.928842, lng: 18.139075 },
    { lat: 48.929106, lng: 18.138914 },
    { lat: 48.929411, lng: 18.13875 },
    { lat: 48.929415, lng: 18.138737 },
    { lat: 48.929617, lng: 18.138135 },
    { lat: 48.929824, lng: 18.137553 },
    { lat: 48.930015, lng: 18.136953 },
    { lat: 48.930015, lng: 18.136952 },
    { lat: 48.930045, lng: 18.136815 },
    { lat: 48.930065, lng: 18.136751 },
    { lat: 48.930091, lng: 18.136687 },
    { lat: 48.93018, lng: 18.136492 },
    { lat: 48.930183, lng: 18.136487 },
    { lat: 48.93027, lng: 18.136371 },
    { lat: 48.930273, lng: 18.136367 },
    { lat: 48.930302, lng: 18.136336 },
    { lat: 48.930305, lng: 18.13633 },
    { lat: 48.930419, lng: 18.136161 },
    { lat: 48.930664, lng: 18.135959 },
    { lat: 48.930726, lng: 18.135915 },
    { lat: 48.931032, lng: 18.13542 },
    { lat: 48.931445, lng: 18.134489 },
    { lat: 48.93154, lng: 18.134296 },
    { lat: 48.931607, lng: 18.134193 },
    { lat: 48.931706, lng: 18.134055 },
    { lat: 48.931886, lng: 18.133883 },
    { lat: 48.931912, lng: 18.133864 },
    { lat: 48.932195, lng: 18.133142 },
    { lat: 48.932561, lng: 18.132222 },
    { lat: 48.932653, lng: 18.132004 },
    { lat: 48.93273, lng: 18.131754 },
    { lat: 48.932741, lng: 18.13172 },
    { lat: 48.932741, lng: 18.13121 },
    { lat: 48.932598, lng: 18.130562 },
    { lat: 48.933154, lng: 18.130614 },
    { lat: 48.933705, lng: 18.130716 },
    { lat: 48.933823, lng: 18.130822 },
    { lat: 48.934272, lng: 18.130069 },
    { lat: 48.934547, lng: 18.130451 },
    { lat: 48.934725, lng: 18.130841 },
    { lat: 48.93477, lng: 18.130946 },
    { lat: 48.934819, lng: 18.130907 },
    { lat: 48.935494, lng: 18.130411 },
    { lat: 48.93621, lng: 18.129868 },
    { lat: 48.936565, lng: 18.129634 },
    { lat: 48.936905, lng: 18.129283 },
    { lat: 48.936859, lng: 18.129179 },
    { lat: 48.936896, lng: 18.129018 },
    { lat: 48.936881, lng: 18.12897 },
    { lat: 48.936863, lng: 18.128918 },
    { lat: 48.936734, lng: 18.128504 },
    { lat: 48.93665, lng: 18.1282 },
    { lat: 48.936557, lng: 18.127914 },
    { lat: 48.936525, lng: 18.127735 },
    { lat: 48.936511, lng: 18.12766 },
    { lat: 48.936489, lng: 18.12741 },
    { lat: 48.936474, lng: 18.127179 },
    { lat: 48.936466, lng: 18.126987 },
    { lat: 48.936509, lng: 18.12657 },
    { lat: 48.93673, lng: 18.127157 },
    { lat: 48.936962, lng: 18.127587 },
    { lat: 48.937256, lng: 18.127971 },
    { lat: 48.937633, lng: 18.128368 },
    { lat: 48.937849, lng: 18.128572 },
    { lat: 48.938301, lng: 18.12896 },
    { lat: 48.938379, lng: 18.129008 },
    { lat: 48.938831, lng: 18.129288 },
    { lat: 48.939305, lng: 18.12957 },
    { lat: 48.939839, lng: 18.129888 },
    { lat: 48.939863, lng: 18.129904 },
    { lat: 48.9399, lng: 18.129928 },
    { lat: 48.939966, lng: 18.12997 },
    { lat: 48.940019, lng: 18.129999 },
    { lat: 48.940072, lng: 18.130023 },
    { lat: 48.940307, lng: 18.1302 },
    { lat: 48.940349, lng: 18.130228 },
    { lat: 48.940385, lng: 18.130241 },
    { lat: 48.940615, lng: 18.130486 },
    { lat: 48.940702, lng: 18.13056 },
    { lat: 48.940733, lng: 18.130589 },
    { lat: 48.940762, lng: 18.130616 },
    { lat: 48.940794, lng: 18.130643 },
    { lat: 48.940825, lng: 18.130668 },
    { lat: 48.940857, lng: 18.130694 },
    { lat: 48.94089, lng: 18.130712 },
    { lat: 48.941045, lng: 18.13084 },
    { lat: 48.941147, lng: 18.130939 },
    { lat: 48.941413, lng: 18.131151 },
    { lat: 48.941462, lng: 18.131193 },
    { lat: 48.941524, lng: 18.131231 },
    { lat: 48.941636, lng: 18.131312 },
    { lat: 48.941752, lng: 18.131391 },
    { lat: 48.941829, lng: 18.131442 },
    { lat: 48.941961, lng: 18.131529 },
    { lat: 48.942301, lng: 18.131759 },
    { lat: 48.942452, lng: 18.131866 },
    { lat: 48.942786, lng: 18.132241 },
    { lat: 48.943628, lng: 18.133281 },
    { lat: 48.944206, lng: 18.133936 },
    { lat: 48.944753, lng: 18.134529 },
    { lat: 48.94503, lng: 18.134855 },
    { lat: 48.945419, lng: 18.13555 },
    { lat: 48.945739, lng: 18.136128 },
    { lat: 48.946152, lng: 18.136761 },
    { lat: 48.946697, lng: 18.137378 },
    { lat: 48.946828, lng: 18.137523 },
    { lat: 48.946942, lng: 18.137657 },
    { lat: 48.947345, lng: 18.138288 },
    { lat: 48.947729, lng: 18.138957 },
    { lat: 48.948079, lng: 18.139578 },
    { lat: 48.948363, lng: 18.140188 },
    { lat: 48.948524, lng: 18.14057 },
    { lat: 48.948594, lng: 18.140715 },
    { lat: 48.948728, lng: 18.141013 },
    { lat: 48.948897, lng: 18.14134 },
    { lat: 48.949312, lng: 18.142099 },
    { lat: 48.949597, lng: 18.14258 },
    { lat: 48.949847, lng: 18.143051 },
    { lat: 48.949957, lng: 18.143307 },
    { lat: 48.950087, lng: 18.143652 },
    { lat: 48.95019, lng: 18.144017 },
    { lat: 48.950249, lng: 18.14431 },
    { lat: 48.950287, lng: 18.144553 },
    { lat: 48.950612, lng: 18.144254 },
    { lat: 48.950697, lng: 18.144094 },
    { lat: 48.950743, lng: 18.144025 },
    { lat: 48.950767, lng: 18.143513 },
    { lat: 48.950778, lng: 18.143344 },
    { lat: 48.950832, lng: 18.14296 },
    { lat: 48.950844, lng: 18.142868 },
    { lat: 48.950926, lng: 18.14248 },
    { lat: 48.95096, lng: 18.142328 },
    { lat: 48.95101, lng: 18.14217 },
    { lat: 48.951055, lng: 18.142027 },
    { lat: 48.951096, lng: 18.141926 },
    { lat: 48.951148, lng: 18.141805 },
    { lat: 48.951335, lng: 18.141412 },
    { lat: 48.951343, lng: 18.141398 },
    { lat: 48.951468, lng: 18.141137 },
    { lat: 48.951551, lng: 18.140979 },
    { lat: 48.951578, lng: 18.140929 },
    { lat: 48.951652, lng: 18.140802 },
    { lat: 48.951845, lng: 18.140475 },
    { lat: 48.951921, lng: 18.140437 },
    { lat: 48.952057, lng: 18.139766 },
    { lat: 48.952158, lng: 18.139407 },
    { lat: 48.952295, lng: 18.139085 },
    { lat: 48.952444, lng: 18.138782 },
    { lat: 48.952604, lng: 18.138413 },
    { lat: 48.952657, lng: 18.138421 },
    { lat: 48.952781, lng: 18.138224 },
    { lat: 48.952905, lng: 18.138025 },
    { lat: 48.952971, lng: 18.13792 },
    { lat: 48.953026, lng: 18.137832 },
    { lat: 48.953096, lng: 18.13772 },
    { lat: 48.953171, lng: 18.137601 },
    { lat: 48.953315, lng: 18.13737 },
    { lat: 48.953394, lng: 18.137245 },
    { lat: 48.953464, lng: 18.137135 },
    { lat: 48.953512, lng: 18.137057 },
    { lat: 48.953557, lng: 18.136981 },
    { lat: 48.953584, lng: 18.13694 },
    { lat: 48.953607, lng: 18.136905 },
    { lat: 48.953707, lng: 18.136716 },
    { lat: 48.953761, lng: 18.136619 },
    { lat: 48.953811, lng: 18.136527 },
    { lat: 48.953901, lng: 18.136373 },
    { lat: 48.954174, lng: 18.13604 },
    { lat: 48.95424, lng: 18.135959 },
    { lat: 48.954386, lng: 18.135842 },
    { lat: 48.954468, lng: 18.135896 },
    { lat: 48.95459, lng: 18.136033 },
    { lat: 48.954641, lng: 18.136032 },
    { lat: 48.954824, lng: 18.136029 },
    { lat: 48.954865, lng: 18.136028 },
    { lat: 48.954895, lng: 18.136028 },
    { lat: 48.954947, lng: 18.13579 },
    { lat: 48.954969, lng: 18.135641 },
    { lat: 48.955267, lng: 18.135559 },
    { lat: 48.955559, lng: 18.135477 },
    { lat: 48.955757, lng: 18.135436 },
    { lat: 48.956093, lng: 18.135567 },
    { lat: 48.956074, lng: 18.135465 },
    { lat: 48.956093, lng: 18.134779 },
    { lat: 48.956135, lng: 18.133991 },
    { lat: 48.956196, lng: 18.133674 },
    { lat: 48.956274, lng: 18.133266 },
    { lat: 48.9558608, lng: 18.1331706 },
    { lat: 48.9558289, lng: 18.133084 },
    { lat: 48.9556897, lng: 18.1327101 },
    { lat: 48.9555755, lng: 18.1323131 },
    { lat: 48.9555322, lng: 18.1322101 },
    { lat: 48.9554343, lng: 18.1317287 },
    { lat: 48.9554248, lng: 18.1316988 },
    { lat: 48.9553211, lng: 18.1313122 },
    { lat: 48.9551982, lng: 18.130999 },
    { lat: 48.9550984, lng: 18.1305232 },
    { lat: 48.9550907, lng: 18.13006 },
    { lat: 48.9550853, lng: 18.1297505 },
    { lat: 48.9549819, lng: 18.1294666 },
    { lat: 48.9548302, lng: 18.1291615 },
    { lat: 48.9547329, lng: 18.1287318 },
    { lat: 48.9545891, lng: 18.1283352 },
    { lat: 48.9546028, lng: 18.1280601 },
    { lat: 48.9544948, lng: 18.1280501 },
    { lat: 48.954032, lng: 18.1278691 },
    { lat: 48.9538115, lng: 18.1277851 },
    { lat: 48.9534087, lng: 18.127628 },
    { lat: 48.9533122, lng: 18.127465 },
    { lat: 48.9532312, lng: 18.1273315 },
    { lat: 48.953118, lng: 18.1271487 },
    { lat: 48.9529834, lng: 18.1269308 },
    { lat: 48.9528587, lng: 18.1267252 },
    { lat: 48.9528216, lng: 18.1266694 },
    { lat: 48.9527642, lng: 18.1265758 },
    { lat: 48.9527283, lng: 18.1265196 },
    { lat: 48.9527206, lng: 18.1265055 },
    { lat: 48.9526352, lng: 18.1263532 },
    { lat: 48.9520959, lng: 18.1254198 },
    { lat: 48.9513841, lng: 18.1241569 },
    { lat: 48.9515174, lng: 18.1238286 },
    { lat: 48.9514719, lng: 18.1231783 },
    { lat: 48.9513799, lng: 18.1228398 },
    { lat: 48.9511245, lng: 18.1218955 },
    { lat: 48.9510243, lng: 18.1218231 },
    { lat: 48.9505949, lng: 18.1217752 },
    { lat: 48.9503788, lng: 18.1216853 },
    { lat: 48.950384, lng: 18.1212483 },
    { lat: 48.9505402, lng: 18.1207327 },
    { lat: 48.9507935, lng: 18.1198842 },
    { lat: 48.9508597, lng: 18.1197788 },
    { lat: 48.9512775, lng: 18.1192386 },
    { lat: 48.9518222, lng: 18.1185156 },
    { lat: 48.9518622, lng: 18.1184822 },
    { lat: 48.9524303, lng: 18.117946 },
    { lat: 48.952442, lng: 18.1179358 },
    { lat: 48.9525084, lng: 18.1178735 },
    { lat: 48.9526364, lng: 18.1171199 },
    { lat: 48.9526907, lng: 18.1167931 },
    { lat: 48.9524172, lng: 18.1160067 },
    { lat: 48.9523909, lng: 18.1159237 },
    { lat: 48.952297, lng: 18.1156264 },
    { lat: 48.9521092, lng: 18.1150339 },
    { lat: 48.9519583, lng: 18.1145726 },
    { lat: 48.9519447, lng: 18.1145349 },
    { lat: 48.9517228, lng: 18.1138597 },
    { lat: 48.9515083, lng: 18.1131983 },
    { lat: 48.9512025, lng: 18.1122439 },
    { lat: 48.9511875, lng: 18.1121982 },
    { lat: 48.9511269, lng: 18.1120099 },
    { lat: 48.9511029, lng: 18.111909 },
    { lat: 48.9510638, lng: 18.1117404 },
    { lat: 48.9508409, lng: 18.1107965 },
    { lat: 48.9507467, lng: 18.1104009 },
    { lat: 48.9505958, lng: 18.1097633 },
    { lat: 48.9505912, lng: 18.1097416 },
    { lat: 48.9505373, lng: 18.109513 },
    { lat: 48.9505076, lng: 18.1093895 },
    { lat: 48.9504628, lng: 18.1091998 },
    { lat: 48.9502038, lng: 18.1081024 },
    { lat: 48.950171, lng: 18.1079653 },
    { lat: 48.9501681, lng: 18.1079524 },
    { lat: 48.9501094, lng: 18.1076996 },
    { lat: 48.9500169, lng: 18.1073116 },
    { lat: 48.94968, lng: 18.1058829 },
    { lat: 48.9495815, lng: 18.1054702 },
    { lat: 48.9495696, lng: 18.105419 },
    { lat: 48.9493691, lng: 18.1045727 },
    { lat: 48.949353, lng: 18.1045066 },
    { lat: 48.9491928, lng: 18.103927 },
    { lat: 48.9490744, lng: 18.1035016 },
    { lat: 48.9489259, lng: 18.1029643 },
    { lat: 48.9488887, lng: 18.1028688 },
    { lat: 48.9485982, lng: 18.1021221 },
    { lat: 48.9478232, lng: 18.1009215 },
    { lat: 48.9477911, lng: 18.1008727 },
    { lat: 48.947486, lng: 18.1004009 },
    { lat: 48.9474811, lng: 18.1003929 },
    { lat: 48.9474661, lng: 18.1003709 },
    { lat: 48.9474575, lng: 18.1003569 },
    { lat: 48.9474377, lng: 18.1003258 },
    { lat: 48.9474177, lng: 18.1002955 },
    { lat: 48.9474091, lng: 18.1002812 },
    { lat: 48.947367, lng: 18.1002158 },
    { lat: 48.9473496, lng: 18.1001901 },
    { lat: 48.9473322, lng: 18.1001619 },
    { lat: 48.9473013, lng: 18.1001151 },
    { lat: 48.9472901, lng: 18.1000982 },
    { lat: 48.9472813, lng: 18.1000843 },
    { lat: 48.9472726, lng: 18.1000697 },
    { lat: 48.9472677, lng: 18.1000625 },
    { lat: 48.947254, lng: 18.1000417 },
    { lat: 48.9472441, lng: 18.1000258 },
    { lat: 48.947202, lng: 18.0999618 },
    { lat: 48.9471932, lng: 18.0999482 },
    { lat: 48.9471821, lng: 18.0999311 },
    { lat: 48.9471645, lng: 18.099918 },
    { lat: 48.9471491, lng: 18.0999057 },
    { lat: 48.9471101, lng: 18.0998775 },
    { lat: 48.947102, lng: 18.0998714 },
    { lat: 48.9470901, lng: 18.0998622 },
    { lat: 48.9470735, lng: 18.0998497 },
    { lat: 48.9470419, lng: 18.0998263 },
    { lat: 48.9470288, lng: 18.0998166 },
    { lat: 48.9460439, lng: 18.0990848 },
    { lat: 48.946038, lng: 18.0990812 },
    { lat: 48.945998, lng: 18.0990514 },
    { lat: 48.9459638, lng: 18.0990255 },
    { lat: 48.9459426, lng: 18.0990097 },
    { lat: 48.9459345, lng: 18.0990043 },
    { lat: 48.9458153, lng: 18.0989158 },
    { lat: 48.9457164, lng: 18.0988281 },
    { lat: 48.9454322, lng: 18.0985772 },
    { lat: 48.945086, lng: 18.0982732 },
    { lat: 48.944998, lng: 18.0981952 },
    { lat: 48.9449778, lng: 18.0981775 },
    { lat: 48.944935, lng: 18.0981401 },
    { lat: 48.9448079, lng: 18.0980283 },
    { lat: 48.9446989, lng: 18.0979018 },
    { lat: 48.9439874, lng: 18.0970777 },
    { lat: 48.9434296, lng: 18.096432 },
    { lat: 48.9430351, lng: 18.0959765 },
    { lat: 48.9420769, lng: 18.0945933 },
    { lat: 48.9412342, lng: 18.0933805 },
    { lat: 48.9404995, lng: 18.0944619 },
    { lat: 48.9404756, lng: 18.0944985 },
    { lat: 48.9403467, lng: 18.0946885 },
    { lat: 48.9401825, lng: 18.0949316 },
    { lat: 48.9400734, lng: 18.095093 },
    { lat: 48.9400349, lng: 18.0951503 },
    { lat: 48.9397473, lng: 18.0955762 },
    { lat: 48.9388896, lng: 18.0949944 },
    { lat: 48.937837, lng: 18.0943598 },
    { lat: 48.9364545, lng: 18.093526 },
    { lat: 48.935154, lng: 18.0927402 },
    { lat: 48.9335345, lng: 18.0919057 },
    { lat: 48.9327752, lng: 18.0913091 },
    { lat: 48.9318408, lng: 18.0902804 },
    { lat: 48.9315303, lng: 18.08933 },
    { lat: 48.9298876, lng: 18.0883294 },
    { lat: 48.92969, lng: 18.0882095 },
    { lat: 48.9296339, lng: 18.088175 },
    { lat: 48.9294593, lng: 18.0880685 },
    { lat: 48.9289059, lng: 18.0877311 },
    { lat: 48.9287142, lng: 18.0876144 },
    { lat: 48.9278775, lng: 18.0871022 },
    { lat: 48.9276644, lng: 18.0869718 },
    { lat: 48.9275579, lng: 18.0869064 },
    { lat: 48.9271122, lng: 18.0866342 },
    { lat: 48.9261643, lng: 18.0860562 },
    { lat: 48.9248478, lng: 18.0852524 },
    { lat: 48.9246824, lng: 18.0855108 },
    { lat: 48.9246503, lng: 18.0855607 },
    { lat: 48.9243958, lng: 18.085958 },
    { lat: 48.9241081, lng: 18.0864057 },
    { lat: 48.9240296, lng: 18.0865286 },
    { lat: 48.9239715, lng: 18.0865583 },
    { lat: 48.9221516, lng: 18.0874961 },
    { lat: 48.9221176, lng: 18.0875133 },
    { lat: 48.9220242, lng: 18.0877885 },
    { lat: 48.922005, lng: 18.0878449 },
    { lat: 48.9219877, lng: 18.0878978 },
    { lat: 48.9218007, lng: 18.0884464 },
    { lat: 48.9217825, lng: 18.0884998 },
    { lat: 48.921769, lng: 18.0885397 },
    { lat: 48.9217093, lng: 18.088716 },
    { lat: 48.9216838, lng: 18.0888016 },
    { lat: 48.9216668, lng: 18.0888602 },
    { lat: 48.9216289, lng: 18.0889848 },
    { lat: 48.9216113, lng: 18.0890474 },
    { lat: 48.921595, lng: 18.0891013 },
    { lat: 48.920136, lng: 18.0940243 },
    { lat: 48.9200894, lng: 18.0944464 },
    { lat: 48.9200901, lng: 18.0944955 },
    { lat: 48.9200551, lng: 18.0952139 },
    { lat: 48.9200464, lng: 18.0952556 },
    { lat: 48.9200135, lng: 18.095353 },
    { lat: 48.9200351, lng: 18.0953939 },
    { lat: 48.9198627, lng: 18.0959595 },
    { lat: 48.9197848, lng: 18.0962502 },
    { lat: 48.9196665, lng: 18.0966389 },
    { lat: 48.9196117, lng: 18.0968637 },
    { lat: 48.9196053, lng: 18.0968882 },
    { lat: 48.9195909, lng: 18.0969336 },
    { lat: 48.919318, lng: 18.0979243 },
    { lat: 48.919108, lng: 18.0986812 },
    { lat: 48.9190045, lng: 18.0990517 },
    { lat: 48.9189789, lng: 18.0990221 },
    { lat: 48.9189547, lng: 18.0989916 },
    { lat: 48.9188694, lng: 18.0992743 },
    { lat: 48.9185313, lng: 18.1004061 },
    { lat: 48.9182294, lng: 18.1013863 },
    { lat: 48.9181062, lng: 18.1017889 },
    { lat: 48.9179545, lng: 18.1023043 },
    { lat: 48.9177369, lng: 18.1030647 },
    { lat: 48.9176247, lng: 18.1037177 },
    { lat: 48.9172923, lng: 18.1047927 },
    { lat: 48.9171089, lng: 18.1053282 },
    { lat: 48.9167696, lng: 18.1055067 },
    { lat: 48.916599, lng: 18.1057211 },
    { lat: 48.9162574, lng: 18.1065009 },
    { lat: 48.9157263, lng: 18.1080061 },
    { lat: 48.9155693, lng: 18.1083726 },
    { lat: 48.9151482, lng: 18.1093805 },
    { lat: 48.9148454, lng: 18.1101105 },
    { lat: 48.9131947, lng: 18.1109318 },
    { lat: 48.912769, lng: 18.1113177 },
    { lat: 48.9118003, lng: 18.1120072 },
    { lat: 48.9115666, lng: 18.1123944 },
    { lat: 48.9113894, lng: 18.1128466 },
    { lat: 48.9113334, lng: 18.1129845 },
    { lat: 48.9110306, lng: 18.1139671 },
    { lat: 48.9098815, lng: 18.1164152 },
    { lat: 48.9097105, lng: 18.1169894 },
    { lat: 48.9093004, lng: 18.1175052 },
    { lat: 48.9087422, lng: 18.1190491 },
    { lat: 48.9083795, lng: 18.1199962 },
    { lat: 48.9079043, lng: 18.1205642 },
    { lat: 48.9074177, lng: 18.1207359 },
    { lat: 48.9072202, lng: 18.120532 },
    { lat: 48.9068959, lng: 18.1205642 },
    { lat: 48.9066455, lng: 18.1207734 },
    { lat: 48.9064793, lng: 18.1211484 },
    { lat: 48.9062984, lng: 18.1227746 },
    { lat: 48.9060561, lng: 18.12391 },
    { lat: 48.9058266, lng: 18.1249931 },
    { lat: 48.9056397, lng: 18.1259902 },
    { lat: 48.9052285, lng: 18.1264726 },
    { lat: 48.9047887, lng: 18.1273502 },
    { lat: 48.9048974, lng: 18.1281363 },
    { lat: 48.9048424, lng: 18.1283187 },
    { lat: 48.9045162, lng: 18.128802 },
    { lat: 48.9041226, lng: 18.1295474 },
    { lat: 48.9038186, lng: 18.1303003 },
    { lat: 48.9034778, lng: 18.1308673 },
    { lat: 48.9032625, lng: 18.1312403 },
    { lat: 48.9029326, lng: 18.1317388 },
    { lat: 48.9027568, lng: 18.132076 },
    { lat: 48.9026374, lng: 18.1324478 },
    { lat: 48.9023608, lng: 18.1333437 },
    { lat: 48.9022438, lng: 18.1336839 },
    { lat: 48.90221, lng: 18.1339567 },
    { lat: 48.9021509, lng: 18.1344158 },
    { lat: 48.9019948, lng: 18.1350144 },
    { lat: 48.9020931, lng: 18.1363213 },
    { lat: 48.9020024, lng: 18.1366871 },
    { lat: 48.9019486, lng: 18.1371419 },
    { lat: 48.901759, lng: 18.137609 },
    { lat: 48.9017773, lng: 18.1377866 },
    { lat: 48.9017906, lng: 18.1377841 },
    { lat: 48.9021131, lng: 18.1378779 },
    { lat: 48.9027358, lng: 18.138087 },
    { lat: 48.9027587, lng: 18.1380947 },
    { lat: 48.9034964, lng: 18.1383222 },
    { lat: 48.9042099, lng: 18.1385651 },
    { lat: 48.9047171, lng: 18.1387286 },
    { lat: 48.905022, lng: 18.1388269 },
    { lat: 48.9055269, lng: 18.1378801 },
    { lat: 48.9059061, lng: 18.1371592 },
    { lat: 48.9064831, lng: 18.1365425 },
    { lat: 48.9070526, lng: 18.1358325 },
    { lat: 48.9075038, lng: 18.1350108 },
    { lat: 48.9075233, lng: 18.1349964 },
    { lat: 48.9081086, lng: 18.135031 },
    { lat: 48.9083603, lng: 18.1351877 },
    { lat: 48.9085377, lng: 18.135229 },
    { lat: 48.9087634, lng: 18.1353087 },
    { lat: 48.9088298, lng: 18.135344 },
    { lat: 48.9090212, lng: 18.1357834 },
  ],
  TrenčianskeTeplice: [
    { lat: 48.930084, lng: 18.154797 },
    { lat: 48.930193, lng: 18.154407 },
    { lat: 48.930293, lng: 18.154059 },
    { lat: 48.93024, lng: 18.153946 },
    { lat: 48.929711, lng: 18.153619 },
    { lat: 48.929662, lng: 18.153567 },
    { lat: 48.929598, lng: 18.153504 },
    { lat: 48.929532, lng: 18.153338 },
    { lat: 48.929035, lng: 18.153048 },
    { lat: 48.929018, lng: 18.152952 },
    { lat: 48.929003, lng: 18.152865 },
    { lat: 48.928998, lng: 18.152836 },
    { lat: 48.928909, lng: 18.152815 },
    { lat: 48.928466, lng: 18.152756 },
    { lat: 48.927969, lng: 18.152689 },
    { lat: 48.927935, lng: 18.152563 },
    { lat: 48.927878, lng: 18.152488 },
    { lat: 48.927777, lng: 18.15246 },
    { lat: 48.927956, lng: 18.151733 },
    { lat: 48.927952, lng: 18.151663 },
    { lat: 48.92788, lng: 18.150551 },
    { lat: 48.928311, lng: 18.150195 },
    { lat: 48.928342, lng: 18.150169 },
    { lat: 48.928232, lng: 18.149767 },
    { lat: 48.927904, lng: 18.14856 },
    { lat: 48.927895, lng: 18.148529 },
    { lat: 48.927884, lng: 18.148493 },
    { lat: 48.927806, lng: 18.148229 },
    { lat: 48.927855, lng: 18.147618 },
    { lat: 48.927877, lng: 18.147351 },
    { lat: 48.9279, lng: 18.147066 },
    { lat: 48.927912, lng: 18.146882 },
    { lat: 48.927919, lng: 18.146793 },
    { lat: 48.927921, lng: 18.146749 },
    { lat: 48.927881, lng: 18.146719 },
    { lat: 48.927148, lng: 18.146185 },
    { lat: 48.9271209, lng: 18.1461605 },
    { lat: 48.9263839, lng: 18.1456298 },
    { lat: 48.9257457, lng: 18.1451711 },
    { lat: 48.9256511, lng: 18.145114 },
    { lat: 48.9256461, lng: 18.1450688 },
    { lat: 48.9257342, lng: 18.1448945 },
    { lat: 48.9258439, lng: 18.144666 },
    { lat: 48.9260045, lng: 18.1443755 },
    { lat: 48.9261063, lng: 18.1440293 },
    { lat: 48.926302, lng: 18.1431976 },
    { lat: 48.9262973, lng: 18.1431929 },
    { lat: 48.9262926, lng: 18.1431899 },
    { lat: 48.9259689, lng: 18.1428425 },
    { lat: 48.9256773, lng: 18.1425228 },
    { lat: 48.9256144, lng: 18.1423715 },
    { lat: 48.925461, lng: 18.1421763 },
    { lat: 48.9253211, lng: 18.1420008 },
    { lat: 48.9252286, lng: 18.1418857 },
    { lat: 48.9251799, lng: 18.1420378 },
    { lat: 48.9251491, lng: 18.1421313 },
    { lat: 48.9251385, lng: 18.1421596 },
    { lat: 48.9249982, lng: 18.1425264 },
    { lat: 48.9249786, lng: 18.142576 },
    { lat: 48.9249471, lng: 18.1427147 },
    { lat: 48.9248253, lng: 18.1429886 },
    { lat: 48.9246962, lng: 18.1431762 },
    { lat: 48.9245327, lng: 18.1432001 },
    { lat: 48.9240616, lng: 18.1437182 },
    { lat: 48.9237705, lng: 18.1442823 },
    { lat: 48.9237619, lng: 18.1442931 },
    { lat: 48.92351, lng: 18.1432329 },
    { lat: 48.9233265, lng: 18.1424015 },
    { lat: 48.9231491, lng: 18.1416152 },
    { lat: 48.9231405, lng: 18.1415646 },
    { lat: 48.9231371, lng: 18.1415432 },
    { lat: 48.9231147, lng: 18.1414134 },
    { lat: 48.92311, lng: 18.1413882 },
    { lat: 48.9230961, lng: 18.1413039 },
    { lat: 48.9230683, lng: 18.1413708 },
    { lat: 48.9229506, lng: 18.141891 },
    { lat: 48.9228509, lng: 18.1420603 },
    { lat: 48.9227322, lng: 18.1421913 },
    { lat: 48.9226604, lng: 18.1422378 },
    { lat: 48.9223887, lng: 18.1423206 },
    { lat: 48.9223176, lng: 18.1423789 },
    { lat: 48.9221494, lng: 18.1425172 },
    { lat: 48.921983, lng: 18.1428009 },
    { lat: 48.9218527, lng: 18.1432374 },
    { lat: 48.9216474, lng: 18.1439041 },
    { lat: 48.921484, lng: 18.1443582 },
    { lat: 48.9212304, lng: 18.1451254 },
    { lat: 48.9210096, lng: 18.1456395 },
    { lat: 48.920954, lng: 18.1457535 },
    { lat: 48.9209239, lng: 18.145817 },
    { lat: 48.9208049, lng: 18.1460621 },
    { lat: 48.920672, lng: 18.146397 },
    { lat: 48.9205453, lng: 18.1467824 },
    { lat: 48.9205432, lng: 18.1467877 },
    { lat: 48.9192175, lng: 18.1455377 },
    { lat: 48.9191887, lng: 18.1455099 },
    { lat: 48.9191124, lng: 18.1454372 },
    { lat: 48.9181263, lng: 18.1445038 },
    { lat: 48.9169554, lng: 18.1433991 },
    { lat: 48.9169494, lng: 18.1433931 },
    { lat: 48.9172502, lng: 18.1426405 },
    { lat: 48.9172832, lng: 18.1425292 },
    { lat: 48.9171788, lng: 18.1423228 },
    { lat: 48.9169082, lng: 18.1418988 },
    { lat: 48.9168244, lng: 18.1418167 },
    { lat: 48.9165102, lng: 18.1422761 },
    { lat: 48.9162465, lng: 18.1426312 },
    { lat: 48.9161411, lng: 18.1426851 },
    { lat: 48.9157919, lng: 18.1427301 },
    { lat: 48.9156691, lng: 18.1427956 },
    { lat: 48.9152179, lng: 18.1431667 },
    { lat: 48.9150797, lng: 18.1433741 },
    { lat: 48.9148371, lng: 18.1436694 },
    { lat: 48.9146944, lng: 18.1437942 },
    { lat: 48.9143922, lng: 18.1438753 },
    { lat: 48.9141521, lng: 18.1440013 },
    { lat: 48.914011, lng: 18.1439777 },
    { lat: 48.9136486, lng: 18.1438192 },
    { lat: 48.9132451, lng: 18.1433796 },
    { lat: 48.9129771, lng: 18.1432728 },
    { lat: 48.9129823, lng: 18.1433044 },
    { lat: 48.9128679, lng: 18.1433146 },
    { lat: 48.9127243, lng: 18.1437384 },
    { lat: 48.9126943, lng: 18.1438242 },
    { lat: 48.9126923, lng: 18.1438309 },
    { lat: 48.9126717, lng: 18.1438842 },
    { lat: 48.9126521, lng: 18.1439335 },
    { lat: 48.9124524, lng: 18.1438011 },
    { lat: 48.912325, lng: 18.143822 },
    { lat: 48.9123161, lng: 18.1438239 },
    { lat: 48.912018, lng: 18.1438977 },
    { lat: 48.9117488, lng: 18.1438886 },
    { lat: 48.9116206, lng: 18.143896 },
    { lat: 48.9112679, lng: 18.1440211 },
    { lat: 48.9109484, lng: 18.1441138 },
    { lat: 48.9109477, lng: 18.1441028 },
    { lat: 48.9109455, lng: 18.1440844 },
    { lat: 48.9108888, lng: 18.1437296 },
    { lat: 48.9108531, lng: 18.1434836 },
    { lat: 48.9108285, lng: 18.1433118 },
    { lat: 48.9107739, lng: 18.1430511 },
    { lat: 48.9107767, lng: 18.1429237 },
    { lat: 48.9107764, lng: 18.1428972 },
    { lat: 48.9107785, lng: 18.1428151 },
    { lat: 48.9107498, lng: 18.1426701 },
    { lat: 48.9106482, lng: 18.1423779 },
    { lat: 48.9108492, lng: 18.1420872 },
    { lat: 48.9109709, lng: 18.1418894 },
    { lat: 48.9112385, lng: 18.1414416 },
    { lat: 48.9113276, lng: 18.1411876 },
    { lat: 48.9117591, lng: 18.1404543 },
    { lat: 48.9117453, lng: 18.1404282 },
    { lat: 48.9117275, lng: 18.140395 },
    { lat: 48.9114243, lng: 18.1398337 },
    { lat: 48.9111897, lng: 18.139212 },
    { lat: 48.9110354, lng: 18.1388659 },
    { lat: 48.9107437, lng: 18.138876 },
    { lat: 48.9105162, lng: 18.1389243 },
    { lat: 48.910421, lng: 18.13866 },
    { lat: 48.910317, lng: 18.1388374 },
    { lat: 48.9102643, lng: 18.1387225 },
    { lat: 48.9102191, lng: 18.1386248 },
    { lat: 48.910058, lng: 18.1382584 },
    { lat: 48.9096477, lng: 18.1373107 },
    { lat: 48.9095773, lng: 18.1370898 },
    { lat: 48.9094596, lng: 18.1367883 },
    { lat: 48.9092679, lng: 18.1364422 },
    { lat: 48.9091736, lng: 18.1361785 },
    { lat: 48.9090491, lng: 18.1358572 },
    { lat: 48.9090212, lng: 18.1357834 },
    { lat: 48.9088298, lng: 18.135344 },
    { lat: 48.9087634, lng: 18.1353087 },
    { lat: 48.9085377, lng: 18.135229 },
    { lat: 48.9083603, lng: 18.1351877 },
    { lat: 48.9081086, lng: 18.135031 },
    { lat: 48.9075233, lng: 18.1349964 },
    { lat: 48.9075038, lng: 18.1350108 },
    { lat: 48.9070526, lng: 18.1358325 },
    { lat: 48.9064831, lng: 18.1365425 },
    { lat: 48.9059061, lng: 18.1371592 },
    { lat: 48.9055269, lng: 18.1378801 },
    { lat: 48.905022, lng: 18.1388269 },
    { lat: 48.9047171, lng: 18.1387286 },
    { lat: 48.9042099, lng: 18.1385651 },
    { lat: 48.9034964, lng: 18.1383222 },
    { lat: 48.9027587, lng: 18.1380947 },
    { lat: 48.9027358, lng: 18.138087 },
    { lat: 48.9021131, lng: 18.1378779 },
    { lat: 48.9017906, lng: 18.1377841 },
    { lat: 48.9017773, lng: 18.1377866 },
    { lat: 48.9018191, lng: 18.1382976 },
    { lat: 48.9019423, lng: 18.1387302 },
    { lat: 48.9019451, lng: 18.1389343 },
    { lat: 48.9018832, lng: 18.1391101 },
    { lat: 48.9016531, lng: 18.1394251 },
    { lat: 48.9014144, lng: 18.1399228 },
    { lat: 48.9010671, lng: 18.1407294 },
    { lat: 48.9009448, lng: 18.141227 },
    { lat: 48.9009256, lng: 18.1413035 },
    { lat: 48.9009144, lng: 18.141348 },
    { lat: 48.9006491, lng: 18.1417359 },
    { lat: 48.9001516, lng: 18.1419699 },
    { lat: 48.8999626, lng: 18.1424681 },
    { lat: 48.8999518, lng: 18.1428494 },
    { lat: 48.9000268, lng: 18.1433247 },
    { lat: 48.9000153, lng: 18.1439851 },
    { lat: 48.8998147, lng: 18.1449856 },
    { lat: 48.8997535, lng: 18.1451591 },
    { lat: 48.8995557, lng: 18.1456183 },
    { lat: 48.8990745, lng: 18.1465115 },
    { lat: 48.8987342, lng: 18.1470065 },
    { lat: 48.8985501, lng: 18.1472372 },
    { lat: 48.898508, lng: 18.1472905 },
    { lat: 48.898489, lng: 18.1473138 },
    { lat: 48.8982534, lng: 18.1475327 },
    { lat: 48.8980367, lng: 18.1480801 },
    { lat: 48.8977444, lng: 18.1485292 },
    { lat: 48.8976806, lng: 18.1487712 },
    { lat: 48.8976532, lng: 18.14941 },
    { lat: 48.8976698, lng: 18.1496976 },
    { lat: 48.8978126, lng: 18.1501567 },
    { lat: 48.8977926, lng: 18.1505144 },
    { lat: 48.8977905, lng: 18.1505556 },
    { lat: 48.8974914, lng: 18.150478 },
    { lat: 48.8973179, lng: 18.1505212 },
    { lat: 48.8971087, lng: 18.1506315 },
    { lat: 48.8971036, lng: 18.150659 },
    { lat: 48.8970676, lng: 18.1506613 },
    { lat: 48.8963552, lng: 18.1512817 },
    { lat: 48.8960665, lng: 18.1516181 },
    { lat: 48.8956299, lng: 18.1521276 },
    { lat: 48.8947016, lng: 18.1526407 },
    { lat: 48.8943734, lng: 18.1528012 },
    { lat: 48.894086, lng: 18.1530402 },
    { lat: 48.8934869, lng: 18.1533177 },
    { lat: 48.8926332, lng: 18.1538721 },
    { lat: 48.8919646, lng: 18.154256 },
    { lat: 48.8915965, lng: 18.1543888 },
    { lat: 48.8911873, lng: 18.1546489 },
    { lat: 48.8907584, lng: 18.1548418 },
    { lat: 48.8903036, lng: 18.1551349 },
    { lat: 48.8899862, lng: 18.1552856 },
    { lat: 48.8897288, lng: 18.1555583 },
    { lat: 48.8894745, lng: 18.1558269 },
    { lat: 48.8890175, lng: 18.1562949 },
    { lat: 48.8886984, lng: 18.1567683 },
    { lat: 48.8885423, lng: 18.157154 },
    { lat: 48.8884962, lng: 18.1576862 },
    { lat: 48.8883328, lng: 18.1582762 },
    { lat: 48.8880869, lng: 18.158842 },
    { lat: 48.8879236, lng: 18.1594188 },
    { lat: 48.8877769, lng: 18.1600238 },
    { lat: 48.8876946, lng: 18.1602609 },
    { lat: 48.8874876, lng: 18.1606233 },
    { lat: 48.8872722, lng: 18.1609559 },
    { lat: 48.8870123, lng: 18.1613045 },
    { lat: 48.8868951, lng: 18.1616582 },
    { lat: 48.8867696, lng: 18.1621438 },
    { lat: 48.8867619, lng: 18.1621656 },
    { lat: 48.8867503, lng: 18.162198 },
    { lat: 48.8867307, lng: 18.1622519 },
    { lat: 48.8866486, lng: 18.1624736 },
    { lat: 48.886319, lng: 18.1628418 },
    { lat: 48.8861562, lng: 18.1630914 },
    { lat: 48.8859168, lng: 18.1634986 },
    { lat: 48.8857353, lng: 18.1641483 },
    { lat: 48.8854401, lng: 18.164664 },
    { lat: 48.884906, lng: 18.1650721 },
    { lat: 48.8853645, lng: 18.1656259 },
    { lat: 48.8856986, lng: 18.1662117 },
    { lat: 48.8860863, lng: 18.1666569 },
    { lat: 48.8862047, lng: 18.1669689 },
    { lat: 48.8863182, lng: 18.1673508 },
    { lat: 48.8866607, lng: 18.1679308 },
    { lat: 48.8868843, lng: 18.1682085 },
    { lat: 48.8871885, lng: 18.1685311 },
    { lat: 48.8874072, lng: 18.1689571 },
    { lat: 48.887416, lng: 18.1689742 },
    { lat: 48.8876805, lng: 18.1695509 },
    { lat: 48.8878522, lng: 18.1702375 },
    { lat: 48.8878645, lng: 18.1704352 },
    { lat: 48.8878829, lng: 18.1713016 },
    { lat: 48.887851, lng: 18.1722169 },
    { lat: 48.8878317, lng: 18.1726863 },
    { lat: 48.8878337, lng: 18.1727011 },
    { lat: 48.8878844, lng: 18.1731918 },
    { lat: 48.8879687, lng: 18.1733997 },
    { lat: 48.8881164, lng: 18.1737929 },
    { lat: 48.8881283, lng: 18.1742908 },
    { lat: 48.8881993, lng: 18.1748182 },
    { lat: 48.8882305, lng: 18.1751249 },
    { lat: 48.888101, lng: 18.1756208 },
    { lat: 48.8885074, lng: 18.1760754 },
    { lat: 48.8886768, lng: 18.1763105 },
    { lat: 48.8888477, lng: 18.176479 },
    { lat: 48.8890526, lng: 18.176666 },
    { lat: 48.8894418, lng: 18.1770975 },
    { lat: 48.8896964, lng: 18.1774988 },
    { lat: 48.8903932, lng: 18.1785297 },
    { lat: 48.8904373, lng: 18.1786103 },
    { lat: 48.8904661, lng: 18.1786595 },
    { lat: 48.8905188, lng: 18.1787526 },
    { lat: 48.8905589, lng: 18.1788215 },
    { lat: 48.8906409, lng: 18.178952 },
    { lat: 48.8909457, lng: 18.1791914 },
    { lat: 48.8909777, lng: 18.1792169 },
    { lat: 48.8911082, lng: 18.1793474 },
    { lat: 48.8913027, lng: 18.1795519 },
    { lat: 48.8917078, lng: 18.1799229 },
    { lat: 48.8921824, lng: 18.1802686 },
    { lat: 48.8924159, lng: 18.180563 },
    { lat: 48.8928619, lng: 18.1810003 },
    { lat: 48.8928964, lng: 18.181032 },
    { lat: 48.8933469, lng: 18.1812098 },
    { lat: 48.8937815, lng: 18.181313 },
    { lat: 48.8941111, lng: 18.1813748 },
    { lat: 48.8941982, lng: 18.1813963 },
    { lat: 48.8945501, lng: 18.1815327 },
    { lat: 48.8950527, lng: 18.1817158 },
    { lat: 48.8953349, lng: 18.1818747 },
    { lat: 48.8955381, lng: 18.1820318 },
    { lat: 48.895792, lng: 18.182306 },
    { lat: 48.8957168, lng: 18.1820024 },
    { lat: 48.8959642, lng: 18.1817122 },
    { lat: 48.8963292, lng: 18.1812118 },
    { lat: 48.8964538, lng: 18.1805541 },
    { lat: 48.8964821, lng: 18.1802778 },
    { lat: 48.8965389, lng: 18.1797147 },
    { lat: 48.8966259, lng: 18.178954 },
    { lat: 48.896672, lng: 18.178761 },
    { lat: 48.8967079, lng: 18.1786107 },
    { lat: 48.8968396, lng: 18.1778215 },
    { lat: 48.897372, lng: 18.1775445 },
    { lat: 48.8978678, lng: 18.1769012 },
    { lat: 48.8984559, lng: 18.1761825 },
    { lat: 48.8990884, lng: 18.1757218 },
    { lat: 48.899251, lng: 18.1755271 },
    { lat: 48.8995301, lng: 18.1755135 },
    { lat: 48.8995545, lng: 18.1755249 },
    { lat: 48.9001883, lng: 18.1758155 },
    { lat: 48.9007903, lng: 18.1760195 },
    { lat: 48.901084, lng: 18.176259 },
    { lat: 48.9012226, lng: 18.176396 },
    { lat: 48.9012675, lng: 18.1764492 },
    { lat: 48.9012856, lng: 18.1764715 },
    { lat: 48.9016164, lng: 18.1768668 },
    { lat: 48.9023693, lng: 18.1777133 },
    { lat: 48.9026382, lng: 18.1781914 },
    { lat: 48.9028785, lng: 18.1785839 },
    { lat: 48.9034407, lng: 18.1790745 },
    { lat: 48.9039241, lng: 18.1792404 },
    { lat: 48.9046085, lng: 18.1797041 },
    { lat: 48.9046225, lng: 18.1797133 },
    { lat: 48.9052563, lng: 18.1801177 },
    { lat: 48.9057699, lng: 18.1805709 },
    { lat: 48.906445, lng: 18.1808142 },
    { lat: 48.9064624, lng: 18.1808205 },
    { lat: 48.9064658, lng: 18.1808218 },
    { lat: 48.9065443, lng: 18.1809129 },
    { lat: 48.90656, lng: 18.1809302 },
    { lat: 48.9067352, lng: 18.1811323 },
    { lat: 48.906751, lng: 18.1811507 },
    { lat: 48.9068067, lng: 18.1812161 },
    { lat: 48.9068333, lng: 18.181246 },
    { lat: 48.9067989, lng: 18.1815522 },
    { lat: 48.9067485, lng: 18.1816963 },
    { lat: 48.9066768, lng: 18.1818428 },
    { lat: 48.906525, lng: 18.1820289 },
    { lat: 48.9062542, lng: 18.1824428 },
    { lat: 48.9060034, lng: 18.1824576 },
    { lat: 48.9058866, lng: 18.1824867 },
    { lat: 48.9056931, lng: 18.1826741 },
    { lat: 48.9056402, lng: 18.1827736 },
    { lat: 48.9055382, lng: 18.1829205 },
    { lat: 48.9051786, lng: 18.18332 },
    { lat: 48.9051599, lng: 18.1833292 },
    { lat: 48.9050225, lng: 18.1835092 },
    { lat: 48.9049962, lng: 18.1835602 },
    { lat: 48.9048901, lng: 18.1836199 },
    { lat: 48.9047662, lng: 18.1837242 },
    { lat: 48.9045189, lng: 18.1839951 },
    { lat: 48.9044058, lng: 18.1843439 },
    { lat: 48.9041116, lng: 18.1841335 },
    { lat: 48.9040748, lng: 18.1841223 },
    { lat: 48.9039094, lng: 18.1840709 },
    { lat: 48.9037809, lng: 18.1841145 },
    { lat: 48.9036442, lng: 18.1842878 },
    { lat: 48.9035569, lng: 18.1845167 },
    { lat: 48.9035575, lng: 18.1846623 },
    { lat: 48.9036606, lng: 18.184828 },
    { lat: 48.9036138, lng: 18.1849974 },
    { lat: 48.903526, lng: 18.1850194 },
    { lat: 48.9033253, lng: 18.1850836 },
    { lat: 48.9032591, lng: 18.1851074 },
    { lat: 48.9031381, lng: 18.185241 },
    { lat: 48.9030726, lng: 18.1853182 },
    { lat: 48.9030173, lng: 18.1854134 },
    { lat: 48.9029771, lng: 18.1856396 },
    { lat: 48.902795, lng: 18.1861996 },
    { lat: 48.9026988, lng: 18.1862948 },
    { lat: 48.9026498, lng: 18.1863432 },
    { lat: 48.9025111, lng: 18.1864641 },
    { lat: 48.9024909, lng: 18.1866805 },
    { lat: 48.9024766, lng: 18.1868277 },
    { lat: 48.9023567, lng: 18.1875311 },
    { lat: 48.9022491, lng: 18.1879764 },
    { lat: 48.9022438, lng: 18.1880395 },
    { lat: 48.9022179, lng: 18.1883342 },
    { lat: 48.9022137, lng: 18.1883602 },
    { lat: 48.902226, lng: 18.1884174 },
    { lat: 48.9022432, lng: 18.1884202 },
    { lat: 48.902141, lng: 18.1887041 },
    { lat: 48.9022621, lng: 18.1887501 },
    { lat: 48.9023878, lng: 18.188797 },
    { lat: 48.9024168, lng: 18.1886723 },
    { lat: 48.9025341, lng: 18.1887713 },
    { lat: 48.902688, lng: 18.1888983 },
    { lat: 48.9029663, lng: 18.1891263 },
    { lat: 48.9030398, lng: 18.1891876 },
    { lat: 48.9032079, lng: 18.1893257 },
    { lat: 48.9032, lng: 18.1893461 },
    { lat: 48.9031631, lng: 18.1894486 },
    { lat: 48.9031232, lng: 18.18956 },
    { lat: 48.9030912, lng: 18.1896503 },
    { lat: 48.9030541, lng: 18.1897528 },
    { lat: 48.9029986, lng: 18.1899061 },
    { lat: 48.9026766, lng: 18.1908022 },
    { lat: 48.9026317, lng: 18.1909258 },
    { lat: 48.9025889, lng: 18.1910459 },
    { lat: 48.9025461, lng: 18.1911649 },
    { lat: 48.9024625, lng: 18.1913972 },
    { lat: 48.9024568, lng: 18.1914177 },
    { lat: 48.9023803, lng: 18.1913614 },
    { lat: 48.9023592, lng: 18.191427 },
    { lat: 48.9023583, lng: 18.1914307 },
    { lat: 48.9023348, lng: 18.1915116 },
    { lat: 48.9022193, lng: 18.1918977 },
    { lat: 48.9022172, lng: 18.191903 },
    { lat: 48.9021007, lng: 18.1922907 },
    { lat: 48.9023693, lng: 18.1924478 },
    { lat: 48.9022699, lng: 18.1927009 },
    { lat: 48.9021287, lng: 18.1930513 },
    { lat: 48.902102, lng: 18.1931176 },
    { lat: 48.9020486, lng: 18.1932498 },
    { lat: 48.9019676, lng: 18.1934492 },
    { lat: 48.9018285, lng: 18.1937979 },
    { lat: 48.9016863, lng: 18.1941523 },
    { lat: 48.9018984, lng: 18.19437 },
    { lat: 48.9015626, lng: 18.1949677 },
    { lat: 48.9012547, lng: 18.1955026 },
    { lat: 48.9012168, lng: 18.1955675 },
    { lat: 48.9009464, lng: 18.1960267 },
    { lat: 48.9008409, lng: 18.1962157 },
    { lat: 48.9007556, lng: 18.1963808 },
    { lat: 48.9007146, lng: 18.1964705 },
    { lat: 48.9005943, lng: 18.1967776 },
    { lat: 48.9006063, lng: 18.1967889 },
    { lat: 48.9006633, lng: 18.1968381 },
    { lat: 48.9006932, lng: 18.196865 },
    { lat: 48.900929, lng: 18.1970641 },
    { lat: 48.9009501, lng: 18.1968992 },
    { lat: 48.900957, lng: 18.1968625 },
    { lat: 48.9010119, lng: 18.1967361 },
    { lat: 48.9010428, lng: 18.1967633 },
    { lat: 48.9012134, lng: 18.1964527 },
    { lat: 48.9014412, lng: 18.1958801 },
    { lat: 48.9015662, lng: 18.195619 },
    { lat: 48.9016061, lng: 18.1956478 },
    { lat: 48.9016442, lng: 18.195566 },
    { lat: 48.9016104, lng: 18.1955249 },
    { lat: 48.9016507, lng: 18.1954392 },
    { lat: 48.9017464, lng: 18.1953976 },
    { lat: 48.9018562, lng: 18.1955029 },
    { lat: 48.901869, lng: 18.1954481 },
    { lat: 48.9019301, lng: 18.1954554 },
    { lat: 48.9019817, lng: 18.1953486 },
    { lat: 48.9020876, lng: 18.1952865 },
    { lat: 48.9021935, lng: 18.195009 },
    { lat: 48.9022878, lng: 18.1947863 },
    { lat: 48.9021878, lng: 18.1946909 },
    { lat: 48.902092, lng: 18.1946171 },
    { lat: 48.9021054, lng: 18.1945952 },
    { lat: 48.9021602, lng: 18.1944657 },
    { lat: 48.9023639, lng: 18.1940059 },
    { lat: 48.9025303, lng: 18.1936213 },
    { lat: 48.9025428, lng: 18.1936247 },
    { lat: 48.9026089, lng: 18.1934578 },
    { lat: 48.9027666, lng: 18.1932565 },
    { lat: 48.9031897, lng: 18.1925483 },
    { lat: 48.9032145, lng: 18.1925073 },
    { lat: 48.9034827, lng: 18.1920885 },
    { lat: 48.9034898, lng: 18.1920765 },
    { lat: 48.9036151, lng: 18.1918781 },
    { lat: 48.9037454, lng: 18.1916718 },
    { lat: 48.9037406, lng: 18.1916664 },
    { lat: 48.9040157, lng: 18.1912323 },
    { lat: 48.904019, lng: 18.1912275 },
    { lat: 48.9040521, lng: 18.1911776 },
    { lat: 48.9040606, lng: 18.1911643 },
    { lat: 48.9040647, lng: 18.1911587 },
    { lat: 48.9040925, lng: 18.1911156 },
    { lat: 48.9041198, lng: 18.1910593 },
    { lat: 48.9043608, lng: 18.1905379 },
    { lat: 48.9044045, lng: 18.1904546 },
    { lat: 48.9045549, lng: 18.1902242 },
    { lat: 48.9046645, lng: 18.1900899 },
    { lat: 48.9046512, lng: 18.1900357 },
    { lat: 48.904818, lng: 18.1899344 },
    { lat: 48.9051053, lng: 18.1899055 },
    { lat: 48.9052761, lng: 18.189931 },
    { lat: 48.9054286, lng: 18.1899772 },
    { lat: 48.9057735, lng: 18.1902638 },
    { lat: 48.9059907, lng: 18.1904514 },
    { lat: 48.9060756, lng: 18.1905345 },
    { lat: 48.9062051, lng: 18.1906279 },
    { lat: 48.9063602, lng: 18.1906562 },
    { lat: 48.9065499, lng: 18.190756 },
    { lat: 48.9065349, lng: 18.1908071 },
    { lat: 48.9066391, lng: 18.1908927 },
    { lat: 48.9067146, lng: 18.1909516 },
    { lat: 48.9067663, lng: 18.191009 },
    { lat: 48.9067914, lng: 18.190972 },
    { lat: 48.9069023, lng: 18.1911944 },
    { lat: 48.9073201, lng: 18.1917512 },
    { lat: 48.907368, lng: 18.1917768 },
    { lat: 48.9074145, lng: 18.1918001 },
    { lat: 48.9074164, lng: 18.1917969 },
    { lat: 48.9074542, lng: 18.191845 },
    { lat: 48.9075176, lng: 18.1920444 },
    { lat: 48.9076689, lng: 18.1923824 },
    { lat: 48.9077379, lng: 18.1925218 },
    { lat: 48.9078041, lng: 18.1926727 },
    { lat: 48.907896, lng: 18.1928991 },
    { lat: 48.9080462, lng: 18.1934897 },
    { lat: 48.9080627, lng: 18.1935823 },
    { lat: 48.9080862, lng: 18.1937373 },
    { lat: 48.9081016, lng: 18.1939071 },
    { lat: 48.9081093, lng: 18.1939994 },
    { lat: 48.9081256, lng: 18.1941845 },
    { lat: 48.9081078, lng: 18.1943701 },
    { lat: 48.9086129, lng: 18.194028 },
    { lat: 48.9086217, lng: 18.1940207 },
    { lat: 48.9086905, lng: 18.1939621 },
    { lat: 48.9087013, lng: 18.1939532 },
    { lat: 48.9087265, lng: 18.1939233 },
    { lat: 48.9087427, lng: 18.1938878 },
    { lat: 48.9087638, lng: 18.1938068 },
    { lat: 48.9087692, lng: 18.1937605 },
    { lat: 48.9087684, lng: 18.1936312 },
    { lat: 48.9086867, lng: 18.1932653 },
    { lat: 48.9086414, lng: 18.1930654 },
    { lat: 48.9086145, lng: 18.1929133 },
    { lat: 48.9085878, lng: 18.1928809 },
    { lat: 48.9085806, lng: 18.1928714 },
    { lat: 48.9085622, lng: 18.1928473 },
    { lat: 48.9085489, lng: 18.1928304 },
    { lat: 48.9085307, lng: 18.1927518 },
    { lat: 48.9081533, lng: 18.1918162 },
    { lat: 48.9081284, lng: 18.191657 },
    { lat: 48.9081052, lng: 18.1916074 },
    { lat: 48.9080938, lng: 18.1915662 },
    { lat: 48.9079004, lng: 18.1911656 },
    { lat: 48.9076352, lng: 18.1906152 },
    { lat: 48.90763, lng: 18.190603 },
    { lat: 48.9075791, lng: 18.1905024 },
    { lat: 48.9073955, lng: 18.190135 },
    { lat: 48.9073728, lng: 18.190073 },
    { lat: 48.9073176, lng: 18.1899178 },
    { lat: 48.9072671, lng: 18.1897638 },
    { lat: 48.9072581, lng: 18.1897467 },
    { lat: 48.9071653, lng: 18.1895635 },
    { lat: 48.9070616, lng: 18.1894878 },
    { lat: 48.9071072, lng: 18.1895944 },
    { lat: 48.9070883, lng: 18.1896181 },
    { lat: 48.9070662, lng: 18.1896454 },
    { lat: 48.9070547, lng: 18.1896595 },
    { lat: 48.9066592, lng: 18.1896328 },
    { lat: 48.9065868, lng: 18.1896277 },
    { lat: 48.9065054, lng: 18.1895678 },
    { lat: 48.9064241, lng: 18.1895071 },
    { lat: 48.9063637, lng: 18.1894183 },
    { lat: 48.9063669, lng: 18.1893762 },
    { lat: 48.906309, lng: 18.1892895 },
    { lat: 48.9062842, lng: 18.1892526 },
    { lat: 48.9062055, lng: 18.1890808 },
    { lat: 48.9062066, lng: 18.1890584 },
    { lat: 48.9062073, lng: 18.1890343 },
    { lat: 48.9060621, lng: 18.1888817 },
    { lat: 48.9059776, lng: 18.1888634 },
    { lat: 48.9059316, lng: 18.1888892 },
    { lat: 48.9059284, lng: 18.1889323 },
    { lat: 48.9057893, lng: 18.1889241 },
    { lat: 48.9057953, lng: 18.1888724 },
    { lat: 48.9057639, lng: 18.1888353 },
    { lat: 48.9057363, lng: 18.1888278 },
    { lat: 48.9056556, lng: 18.1888772 },
    { lat: 48.9056523, lng: 18.1888971 },
    { lat: 48.9055091, lng: 18.1889396 },
    { lat: 48.9054268, lng: 18.1889401 },
    { lat: 48.9051558, lng: 18.1887582 },
    { lat: 48.9051686, lng: 18.1887091 },
    { lat: 48.9051733, lng: 18.1886927 },
    { lat: 48.9051779, lng: 18.1886742 },
    { lat: 48.9052701, lng: 18.1886487 },
    { lat: 48.9052243, lng: 18.188205 },
    { lat: 48.9053148, lng: 18.1880507 },
    { lat: 48.9053388, lng: 18.1880173 },
    { lat: 48.9053874, lng: 18.1878212 },
    { lat: 48.9054387, lng: 18.1878704 },
    { lat: 48.9054971, lng: 18.1878461 },
    { lat: 48.9055233, lng: 18.1878904 },
    { lat: 48.9056261, lng: 18.1874604 },
    { lat: 48.9056938, lng: 18.1871824 },
    { lat: 48.9058185, lng: 18.1866236 },
    { lat: 48.9058992, lng: 18.1863202 },
    { lat: 48.9061071, lng: 18.1857364 },
    { lat: 48.9061303, lng: 18.185667 },
    { lat: 48.9061231, lng: 18.1856608 },
    { lat: 48.9062134, lng: 18.1855251 },
    { lat: 48.9062918, lng: 18.1853949 },
    { lat: 48.9063689, lng: 18.1852638 },
    { lat: 48.9064501, lng: 18.185105 },
    { lat: 48.906504, lng: 18.1849841 },
    { lat: 48.9068248, lng: 18.1842218 },
    { lat: 48.9069654, lng: 18.1838797 },
    { lat: 48.9073562, lng: 18.1844371 },
    { lat: 48.9075183, lng: 18.1846872 },
    { lat: 48.9075259, lng: 18.1846995 },
    { lat: 48.9077775, lng: 18.185091 },
    { lat: 48.9077873, lng: 18.1851058 },
    { lat: 48.9077972, lng: 18.1851206 },
    { lat: 48.9082059, lng: 18.1856961 },
    { lat: 48.908561, lng: 18.1861805 },
    { lat: 48.9088712, lng: 18.186514 },
    { lat: 48.9091138, lng: 18.1863739 },
    { lat: 48.9093119, lng: 18.1862502 },
    { lat: 48.9098197, lng: 18.1859341 },
    { lat: 48.9104189, lng: 18.1856799 },
    { lat: 48.9107527, lng: 18.1854805 },
    { lat: 48.9112006, lng: 18.184909 },
    { lat: 48.9112564, lng: 18.1848388 },
    { lat: 48.911528, lng: 18.1845956 },
    { lat: 48.9117, lng: 18.183838 },
    { lat: 48.911939, lng: 18.183402 },
    { lat: 48.912082, lng: 18.183255 },
    { lat: 48.912209, lng: 18.183024 },
    { lat: 48.912313, lng: 18.182856 },
    { lat: 48.912567, lng: 18.182257 },
    { lat: 48.912812, lng: 18.18165 },
    { lat: 48.912977, lng: 18.181065 },
    { lat: 48.913075, lng: 18.180736 },
    { lat: 48.91339, lng: 18.180454 },
    { lat: 48.913595, lng: 18.180216 },
    { lat: 48.91417, lng: 18.179639 },
    { lat: 48.914342, lng: 18.179509 },
    { lat: 48.914552, lng: 18.179261 },
    { lat: 48.914568, lng: 18.179257 },
    { lat: 48.914572, lng: 18.179051 },
    { lat: 48.91461, lng: 18.178929 },
    { lat: 48.914658, lng: 18.17879 },
    { lat: 48.914893, lng: 18.178321 },
    { lat: 48.914948, lng: 18.178049 },
    { lat: 48.914893, lng: 18.177615 },
    { lat: 48.914724, lng: 18.176745 },
    { lat: 48.914751, lng: 18.176518 },
    { lat: 48.914808, lng: 18.176368 },
    { lat: 48.91536, lng: 18.175767 },
    { lat: 48.915618, lng: 18.175176 },
    { lat: 48.915687, lng: 18.174718 },
    { lat: 48.915742, lng: 18.174194 },
    { lat: 48.915771, lng: 18.173181 },
    { lat: 48.916012, lng: 18.172748 },
    { lat: 48.916412, lng: 18.171946 },
    { lat: 48.916635, lng: 18.171365 },
    { lat: 48.917131, lng: 18.170709 },
    { lat: 48.917291, lng: 18.170432 },
    { lat: 48.917455, lng: 18.170202 },
    { lat: 48.917599, lng: 18.170005 },
    { lat: 48.917694, lng: 18.169879 },
    { lat: 48.917733, lng: 18.169764 },
    { lat: 48.917789, lng: 18.169572 },
    { lat: 48.917884, lng: 18.169377 },
    { lat: 48.917953, lng: 18.169304 },
    { lat: 48.918217, lng: 18.169137 },
    { lat: 48.918565, lng: 18.169028 },
    { lat: 48.918575, lng: 18.169052 },
    { lat: 48.91862, lng: 18.169096 },
    { lat: 48.919013, lng: 18.168193 },
    { lat: 48.919435, lng: 18.167755 },
    { lat: 48.919916, lng: 18.167314 },
    { lat: 48.920603, lng: 18.166728 },
    { lat: 48.920846, lng: 18.166524 },
    { lat: 48.921231, lng: 18.16631 },
    { lat: 48.921704, lng: 18.16616 },
    { lat: 48.922735, lng: 18.165809 },
    { lat: 48.923298, lng: 18.16545 },
    { lat: 48.923723, lng: 18.164661 },
    { lat: 48.924068, lng: 18.163815 },
    { lat: 48.924372, lng: 18.163327 },
    { lat: 48.924819, lng: 18.162 },
    { lat: 48.925386, lng: 18.160709 },
    { lat: 48.925926, lng: 18.159776 },
    { lat: 48.925958, lng: 18.159719 },
    { lat: 48.925975, lng: 18.159689 },
    { lat: 48.926013, lng: 18.159624 },
    { lat: 48.926019, lng: 18.159613 },
    { lat: 48.926102, lng: 18.159488 },
    { lat: 48.926153, lng: 18.159412 },
    { lat: 48.926338, lng: 18.159133 },
    { lat: 48.926833, lng: 18.158592 },
    { lat: 48.926952, lng: 18.158496 },
    { lat: 48.927038, lng: 18.158162 },
    { lat: 48.927477, lng: 18.157776 },
    { lat: 48.927652, lng: 18.157486 },
    { lat: 48.92791, lng: 18.157142 },
    { lat: 48.928006, lng: 18.157097 },
    { lat: 48.928125, lng: 18.15692 },
    { lat: 48.928228, lng: 18.156897 },
    { lat: 48.928386, lng: 18.156788 },
    { lat: 48.928524, lng: 18.156795 },
    { lat: 48.928811, lng: 18.156587 },
    { lat: 48.928852, lng: 18.156475 },
    { lat: 48.929013, lng: 18.156338 },
    { lat: 48.929156, lng: 18.156151 },
    { lat: 48.929308, lng: 18.156084 },
    { lat: 48.929355, lng: 18.155962 },
    { lat: 48.929452, lng: 18.155899 },
    { lat: 48.929541, lng: 18.155711 },
    { lat: 48.929401, lng: 18.155598 },
    { lat: 48.929379, lng: 18.155639 },
    { lat: 48.929153, lng: 18.155342 },
    { lat: 48.929782, lng: 18.15495 },
    { lat: 48.930084, lng: 18.154797 },
  ],
  AdamovskéKochanovce: [
    { lat: 48.8472991, lng: 17.9494491 },
    { lat: 48.8476677, lng: 17.9484942 },
    { lat: 48.8476951, lng: 17.9484213 },
    { lat: 48.8477733, lng: 17.948217 },
    { lat: 48.8481642, lng: 17.948813 },
    { lat: 48.8484889, lng: 17.9493165 },
    { lat: 48.8487252, lng: 17.9495143 },
    { lat: 48.8488634, lng: 17.9495241 },
    { lat: 48.8490499, lng: 17.9494706 },
    { lat: 48.8493802, lng: 17.9492545 },
    { lat: 48.8504285, lng: 17.9485667 },
    { lat: 48.8508848, lng: 17.9482707 },
    { lat: 48.8513505, lng: 17.9479557 },
    { lat: 48.8513948, lng: 17.947926 },
    { lat: 48.8518108, lng: 17.9476505 },
    { lat: 48.8522427, lng: 17.9473617 },
    { lat: 48.8522618, lng: 17.9473013 },
    { lat: 48.8523786, lng: 17.9472359 },
    { lat: 48.8524273, lng: 17.947221 },
    { lat: 48.8524822, lng: 17.9472128 },
    { lat: 48.8526361, lng: 17.9472555 },
    { lat: 48.8527857, lng: 17.9473996 },
    { lat: 48.8529218, lng: 17.9473544 },
    { lat: 48.8529485, lng: 17.9473458 },
    { lat: 48.8529993, lng: 17.9473288 },
    { lat: 48.853108, lng: 17.9472211 },
    { lat: 48.8532573, lng: 17.9469989 },
    { lat: 48.8535194, lng: 17.9466064 },
    { lat: 48.8542139, lng: 17.9455666 },
    { lat: 48.8542926, lng: 17.9455063 },
    { lat: 48.8543864, lng: 17.945434 },
    { lat: 48.8543783, lng: 17.9454279 },
    { lat: 48.8543049, lng: 17.9453713 },
    { lat: 48.8533743, lng: 17.9446632 },
    { lat: 48.8532284, lng: 17.9445254 },
    { lat: 48.853109, lng: 17.9444142 },
    { lat: 48.8530218, lng: 17.9443315 },
    { lat: 48.8529118, lng: 17.944229 },
    { lat: 48.8528817, lng: 17.9442005 },
    { lat: 48.8527893, lng: 17.944126 },
    { lat: 48.8526897, lng: 17.9440465 },
    { lat: 48.8526542, lng: 17.9440173 },
    { lat: 48.8525925, lng: 17.943967 },
    { lat: 48.8525369, lng: 17.9439226 },
    { lat: 48.852424, lng: 17.9438312 },
    { lat: 48.8522877, lng: 17.9437223 },
    { lat: 48.8522213, lng: 17.943668 },
    { lat: 48.8521572, lng: 17.9436162 },
    { lat: 48.8521562, lng: 17.9436145 },
    { lat: 48.8520065, lng: 17.9434927 },
    { lat: 48.8518865, lng: 17.9433912 },
    { lat: 48.8518248, lng: 17.9433402 },
    { lat: 48.8517297, lng: 17.9432618 },
    { lat: 48.8516195, lng: 17.9431697 },
    { lat: 48.8515801, lng: 17.9431367 },
    { lat: 48.8514235, lng: 17.9429496 },
    { lat: 48.8512302, lng: 17.9427176 },
    { lat: 48.8511328, lng: 17.9426005 },
    { lat: 48.8510332, lng: 17.9424808 },
    { lat: 48.8509955, lng: 17.9424358 },
    { lat: 48.8509422, lng: 17.9423743 },
    { lat: 48.8508911, lng: 17.942315 },
    { lat: 48.8508486, lng: 17.9422645 },
    { lat: 48.8507966, lng: 17.9422046 },
    { lat: 48.8507576, lng: 17.9421591 },
    { lat: 48.8507529, lng: 17.942154 },
    { lat: 48.8507163, lng: 17.9421081 },
    { lat: 48.8506579, lng: 17.9420376 },
    { lat: 48.8505813, lng: 17.9419467 },
    { lat: 48.8504851, lng: 17.941829 },
    { lat: 48.850388, lng: 17.9417111 },
    { lat: 48.8504884, lng: 17.9415028 },
    { lat: 48.8505025, lng: 17.9414733 },
    { lat: 48.850604, lng: 17.9412633 },
    { lat: 48.850623, lng: 17.9412259 },
    { lat: 48.8506422, lng: 17.9411871 },
    { lat: 48.8506844, lng: 17.9411007 },
    { lat: 48.8507087, lng: 17.9410522 },
    { lat: 48.8508648, lng: 17.9407355 },
    { lat: 48.8510328, lng: 17.9403917 },
    { lat: 48.8512353, lng: 17.9399827 },
    { lat: 48.8513511, lng: 17.9397478 },
    { lat: 48.8513885, lng: 17.9396571 },
    { lat: 48.8515773, lng: 17.9393602 },
    { lat: 48.8518424, lng: 17.9389978 },
    { lat: 48.8521575, lng: 17.9386772 },
    { lat: 48.8522223, lng: 17.9386103 },
    { lat: 48.8523501, lng: 17.9384817 },
    { lat: 48.8524551, lng: 17.9383691 },
    { lat: 48.8525795, lng: 17.9382381 },
    { lat: 48.8526629, lng: 17.9381413 },
    { lat: 48.8526913, lng: 17.9381109 },
    { lat: 48.8528059, lng: 17.9379848 },
    { lat: 48.8529321, lng: 17.9378683 },
    { lat: 48.8530607, lng: 17.9377528 },
    { lat: 48.8531737, lng: 17.9376452 },
    { lat: 48.8532596, lng: 17.9375693 },
    { lat: 48.8533752, lng: 17.937461 },
    { lat: 48.8535209, lng: 17.9372959 },
    { lat: 48.8536878, lng: 17.9371393 },
    { lat: 48.8537461, lng: 17.9370779 },
    { lat: 48.8537503, lng: 17.937073 },
    { lat: 48.8538159, lng: 17.9369995 },
    { lat: 48.8539572, lng: 17.9368146 },
    { lat: 48.8540588, lng: 17.9367017 },
    { lat: 48.8541679, lng: 17.93658 },
    { lat: 48.854322, lng: 17.936399 },
    { lat: 48.8544703, lng: 17.9362213 },
    { lat: 48.854587, lng: 17.936076 },
    { lat: 48.8546163, lng: 17.9360361 },
    { lat: 48.8546195, lng: 17.9360328 },
    { lat: 48.8547218, lng: 17.9359127 },
    { lat: 48.8547449, lng: 17.9358844 },
    { lat: 48.8547481, lng: 17.9358811 },
    { lat: 48.8548163, lng: 17.9357915 },
    { lat: 48.8548553, lng: 17.9357474 },
    { lat: 48.8548796, lng: 17.9357183 },
    { lat: 48.8550088, lng: 17.9355579 },
    { lat: 48.8550382, lng: 17.9355238 },
    { lat: 48.8550708, lng: 17.9354835 },
    { lat: 48.8551257, lng: 17.9354165 },
    { lat: 48.8552582, lng: 17.9352546 },
    { lat: 48.8553843, lng: 17.9350806 },
    { lat: 48.8555125, lng: 17.9349025 },
    { lat: 48.8556402, lng: 17.9347155 },
    { lat: 48.8557598, lng: 17.9345428 },
    { lat: 48.8559327, lng: 17.9342456 },
    { lat: 48.8560139, lng: 17.9341126 },
    { lat: 48.8561847, lng: 17.9338322 },
    { lat: 48.8560013, lng: 17.933576 },
    { lat: 48.8560338, lng: 17.9335349 },
    { lat: 48.8561049, lng: 17.9334417 },
    { lat: 48.8565389, lng: 17.9332428 },
    { lat: 48.8569344, lng: 17.9330606 },
    { lat: 48.8570451, lng: 17.933006 },
    { lat: 48.8571822, lng: 17.9329373 },
    { lat: 48.8573137, lng: 17.9328734 },
    { lat: 48.8575034, lng: 17.9327779 },
    { lat: 48.8576101, lng: 17.9327317 },
    { lat: 48.8578016, lng: 17.9326513 },
    { lat: 48.8586474, lng: 17.9322421 },
    { lat: 48.8590937, lng: 17.9320953 },
    { lat: 48.8595219, lng: 17.9318588 },
    { lat: 48.8601687, lng: 17.9316123 },
    { lat: 48.86084, lng: 17.9312482 },
    { lat: 48.8611246, lng: 17.9311382 },
    { lat: 48.8622207, lng: 17.9311022 },
    { lat: 48.8624313, lng: 17.9309974 },
    { lat: 48.8626122, lng: 17.9308352 },
    { lat: 48.8628442, lng: 17.9305604 },
    { lat: 48.8630181, lng: 17.9302408 },
    { lat: 48.8632614, lng: 17.9298754 },
    { lat: 48.8634895, lng: 17.9295717 },
    { lat: 48.8635569, lng: 17.9294911 },
    { lat: 48.8637396, lng: 17.9292441 },
    { lat: 48.8646883, lng: 17.9277577 },
    { lat: 48.8652663, lng: 17.927155 },
    { lat: 48.8657192, lng: 17.9264425 },
    { lat: 48.8659605, lng: 17.9262294 },
    { lat: 48.8662898, lng: 17.9261512 },
    { lat: 48.8667647, lng: 17.9261255 },
    { lat: 48.8677997, lng: 17.9262007 },
    { lat: 48.867894, lng: 17.926381 },
    { lat: 48.8680041, lng: 17.9262601 },
    { lat: 48.8683453, lng: 17.9260168 },
    { lat: 48.8684938, lng: 17.9258774 },
    { lat: 48.8685912, lng: 17.9257504 },
    { lat: 48.8687446, lng: 17.9253733 },
    { lat: 48.8688254, lng: 17.9252682 },
    { lat: 48.868964, lng: 17.9251524 },
    { lat: 48.8690549, lng: 17.9250471 },
    { lat: 48.869163, lng: 17.9248931 },
    { lat: 48.8693476, lng: 17.9245265 },
    { lat: 48.8694305, lng: 17.9243071 },
    { lat: 48.8696199, lng: 17.9240018 },
    { lat: 48.8696628, lng: 17.9239439 },
    { lat: 48.8697967, lng: 17.923766 },
    { lat: 48.8701995, lng: 17.9235759 },
    { lat: 48.8708423, lng: 17.9230208 },
    { lat: 48.8713449, lng: 17.9225875 },
    { lat: 48.871408, lng: 17.9225322 },
    { lat: 48.871552, lng: 17.9222772 },
    { lat: 48.8716987, lng: 17.9220129 },
    { lat: 48.8719987, lng: 17.9216672 },
    { lat: 48.8720198, lng: 17.9216432 },
    { lat: 48.8720891, lng: 17.9215569 },
    { lat: 48.8721081, lng: 17.9215342 },
    { lat: 48.8721533, lng: 17.92148 },
    { lat: 48.8722609, lng: 17.9213523 },
    { lat: 48.872295, lng: 17.9213178 },
    { lat: 48.8723448, lng: 17.921264 },
    { lat: 48.873035, lng: 17.9202161 },
    { lat: 48.8732057, lng: 17.9199571 },
    { lat: 48.8732395, lng: 17.9198976 },
    { lat: 48.8733015, lng: 17.9198227 },
    { lat: 48.8733101, lng: 17.9198129 },
    { lat: 48.8737695, lng: 17.9192679 },
    { lat: 48.8738246, lng: 17.9192073 },
    { lat: 48.8738321, lng: 17.9191987 },
    { lat: 48.8745744, lng: 17.9183806 },
    { lat: 48.8745946, lng: 17.9183585 },
    { lat: 48.8747576, lng: 17.9181769 },
    { lat: 48.8749159, lng: 17.9180124 },
    { lat: 48.8750197, lng: 17.9179171 },
    { lat: 48.8753208, lng: 17.9175167 },
    { lat: 48.8757392, lng: 17.9170587 },
    { lat: 48.8758903, lng: 17.916812 },
    { lat: 48.8759181, lng: 17.916768 },
    { lat: 48.876023, lng: 17.9165958 },
    { lat: 48.8764039, lng: 17.9157549 },
    { lat: 48.8764611, lng: 17.9156355 },
    { lat: 48.8764812, lng: 17.915575 },
    { lat: 48.8764986, lng: 17.915526 },
    { lat: 48.8767162, lng: 17.9148825 },
    { lat: 48.8767558, lng: 17.9147681 },
    { lat: 48.8770526, lng: 17.913882 },
    { lat: 48.877215, lng: 17.9134064 },
    { lat: 48.8777859, lng: 17.9123256 },
    { lat: 48.8782645, lng: 17.911818 },
    { lat: 48.8787617, lng: 17.9112611 },
    { lat: 48.8789598, lng: 17.910271 },
    { lat: 48.8789763, lng: 17.9099263 },
    { lat: 48.8790711, lng: 17.9093021 },
    { lat: 48.8792033, lng: 17.9089458 },
    { lat: 48.8795405, lng: 17.9083191 },
    { lat: 48.879725, lng: 17.9081022 },
    { lat: 48.8798997, lng: 17.9080018 },
    { lat: 48.88007, lng: 17.9079625 },
    { lat: 48.8802128, lng: 17.9080282 },
    { lat: 48.8804045, lng: 17.9081019 },
    { lat: 48.8810206, lng: 17.9079109 },
    { lat: 48.8813665, lng: 17.9076722 },
    { lat: 48.8821759, lng: 17.9064665 },
    { lat: 48.8826063, lng: 17.9059633 },
    { lat: 48.8828709, lng: 17.9057654 },
    { lat: 48.8833685, lng: 17.9054591 },
    { lat: 48.884005, lng: 17.9050064 },
    { lat: 48.8845188, lng: 17.9043711 },
    { lat: 48.8847869, lng: 17.9040415 },
    { lat: 48.8852092, lng: 17.9036272 },
    { lat: 48.8853606, lng: 17.9031985 },
    { lat: 48.8854661, lng: 17.9027719 },
    { lat: 48.8857536, lng: 17.9019952 },
    { lat: 48.8858307, lng: 17.9017952 },
    { lat: 48.8861281, lng: 17.9010652 },
    { lat: 48.8862505, lng: 17.9007908 },
    { lat: 48.8861506, lng: 17.8997483 },
    { lat: 48.8861246, lng: 17.8992397 },
    { lat: 48.8860499, lng: 17.897409 },
    { lat: 48.8859295, lng: 17.8945946 },
    { lat: 48.8859198, lng: 17.8944133 },
    { lat: 48.8859115, lng: 17.8942746 },
    { lat: 48.8859727, lng: 17.8941076 },
    { lat: 48.885993, lng: 17.8940517 },
    { lat: 48.8860927, lng: 17.8937772 },
    { lat: 48.8861607, lng: 17.8936111 },
    { lat: 48.8863, lng: 17.8932784 },
    { lat: 48.8863515, lng: 17.8931598 },
    { lat: 48.886374, lng: 17.8928762 },
    { lat: 48.8863878, lng: 17.8928614 },
    { lat: 48.8865895, lng: 17.8925921 },
    { lat: 48.8866344, lng: 17.8925321 },
    { lat: 48.8867892, lng: 17.8924591 },
    { lat: 48.8869774, lng: 17.8925494 },
    { lat: 48.8871319, lng: 17.8918319 },
    { lat: 48.8871578, lng: 17.8916995 },
    { lat: 48.8875234, lng: 17.8916397 },
    { lat: 48.8875625, lng: 17.8916329 },
    { lat: 48.8875927, lng: 17.891628 },
    { lat: 48.8875983, lng: 17.8916272 },
    { lat: 48.8876427, lng: 17.8916172 },
    { lat: 48.8877442, lng: 17.8915946 },
    { lat: 48.8879992, lng: 17.8915389 },
    { lat: 48.8880911, lng: 17.8915262 },
    { lat: 48.888147, lng: 17.8915185 },
    { lat: 48.888237, lng: 17.8912493 },
    { lat: 48.8884275, lng: 17.8906243 },
    { lat: 48.8886603, lng: 17.8898967 },
    { lat: 48.8887409, lng: 17.8896395 },
    { lat: 48.8888306, lng: 17.8892731 },
    { lat: 48.8889853, lng: 17.8886355 },
    { lat: 48.8890262, lng: 17.8884691 },
    { lat: 48.8891514, lng: 17.887959 },
    { lat: 48.890042, lng: 17.8862305 },
    { lat: 48.8899134, lng: 17.8858572 },
    { lat: 48.8897399, lng: 17.885355 },
    { lat: 48.8896974, lng: 17.8852296 },
    { lat: 48.8896947, lng: 17.8852203 },
    { lat: 48.8896848, lng: 17.8851901 },
    { lat: 48.8896332, lng: 17.8850085 },
    { lat: 48.8896208, lng: 17.8848768 },
    { lat: 48.8894124, lng: 17.8827032 },
    { lat: 48.889412, lng: 17.8819647 },
    { lat: 48.889413, lng: 17.8816783 },
    { lat: 48.889413, lng: 17.8806671 },
    { lat: 48.8894125, lng: 17.8804501 },
    { lat: 48.8894117, lng: 17.8803627 },
    { lat: 48.8894116, lng: 17.8801561 },
    { lat: 48.8894015, lng: 17.8800223 },
    { lat: 48.8896668, lng: 17.8796484 },
    { lat: 48.8898031, lng: 17.8794563 },
    { lat: 48.8898508, lng: 17.879388 },
    { lat: 48.8912457, lng: 17.8774102 },
    { lat: 48.8912215, lng: 17.8744358 },
    { lat: 48.8907724, lng: 17.8750583 },
    { lat: 48.8904363, lng: 17.8755338 },
    { lat: 48.8896784, lng: 17.876595 },
    { lat: 48.8894098, lng: 17.8769521 },
    { lat: 48.8891439, lng: 17.8772773 },
    { lat: 48.8888164, lng: 17.8775779 },
    { lat: 48.8886396, lng: 17.8776777 },
    { lat: 48.888437, lng: 17.8775551 },
    { lat: 48.8876846, lng: 17.8769999 },
    { lat: 48.8874354, lng: 17.8775135 },
    { lat: 48.8874137, lng: 17.8775459 },
    { lat: 48.8870684, lng: 17.8782433 },
    { lat: 48.886196, lng: 17.8800081 },
    { lat: 48.8840375, lng: 17.8820732 },
    { lat: 48.8840112, lng: 17.8821031 },
    { lat: 48.883624, lng: 17.8824578 },
    { lat: 48.8852923, lng: 17.8846745 },
    { lat: 48.8850232, lng: 17.8854559 },
    { lat: 48.884513, lng: 17.8853639 },
    { lat: 48.884381, lng: 17.8853642 },
    { lat: 48.8839345, lng: 17.8853735 },
    { lat: 48.8834037, lng: 17.8854641 },
    { lat: 48.8827743, lng: 17.8852447 },
    { lat: 48.8823096, lng: 17.8852694 },
    { lat: 48.8818122, lng: 17.8858403 },
    { lat: 48.8813538, lng: 17.886141 },
    { lat: 48.8810913, lng: 17.8866524 },
    { lat: 48.8807578, lng: 17.8872486 },
    { lat: 48.8806953, lng: 17.8872978 },
    { lat: 48.8800008, lng: 17.8876483 },
    { lat: 48.879899, lng: 17.8876473 },
    { lat: 48.8796166, lng: 17.8875841 },
    { lat: 48.879015, lng: 17.8874376 },
    { lat: 48.8786891, lng: 17.8875525 },
    { lat: 48.8782125, lng: 17.8876822 },
    { lat: 48.8777227, lng: 17.8879628 },
    { lat: 48.8767163, lng: 17.8881616 },
    { lat: 48.8766006, lng: 17.8881891 },
    { lat: 48.8763269, lng: 17.8888024 },
    { lat: 48.8762732, lng: 17.8889199 },
    { lat: 48.8762502, lng: 17.8889705 },
    { lat: 48.8761433, lng: 17.8890333 },
    { lat: 48.8755552, lng: 17.8891277 },
    { lat: 48.8754401, lng: 17.8891092 },
    { lat: 48.8753201, lng: 17.8893836 },
    { lat: 48.8749864, lng: 17.8899553 },
    { lat: 48.8748944, lng: 17.8901129 },
    { lat: 48.8746297, lng: 17.8903294 },
    { lat: 48.87452, lng: 17.8904955 },
    { lat: 48.8743986, lng: 17.8907317 },
    { lat: 48.8742846, lng: 17.8909569 },
    { lat: 48.8739552, lng: 17.8915248 },
    { lat: 48.8738237, lng: 17.8918524 },
    { lat: 48.87367, lng: 17.8921877 },
    { lat: 48.8734906, lng: 17.8924542 },
    { lat: 48.8734389, lng: 17.8925312 },
    { lat: 48.8732144, lng: 17.8928343 },
    { lat: 48.8730804, lng: 17.8929149 },
    { lat: 48.8729687, lng: 17.8929539 },
    { lat: 48.8728835, lng: 17.8929833 },
    { lat: 48.8726072, lng: 17.8930235 },
    { lat: 48.8724305, lng: 17.8930353 },
    { lat: 48.8723101, lng: 17.8930028 },
    { lat: 48.8720658, lng: 17.8929372 },
    { lat: 48.8720006, lng: 17.8929225 },
    { lat: 48.8715607, lng: 17.8929477 },
    { lat: 48.8712313, lng: 17.8933266 },
    { lat: 48.8710344, lng: 17.8936015 },
    { lat: 48.8708791, lng: 17.8938345 },
    { lat: 48.8705145, lng: 17.894437 },
    { lat: 48.8703798, lng: 17.8946359 },
    { lat: 48.8702841, lng: 17.8947563 },
    { lat: 48.8699299, lng: 17.8951325 },
    { lat: 48.8696465, lng: 17.8953204 },
    { lat: 48.869185, lng: 17.8957182 },
    { lat: 48.8686875, lng: 17.8967187 },
    { lat: 48.8684615, lng: 17.8976749 },
    { lat: 48.8684089, lng: 17.8980987 },
    { lat: 48.8677251, lng: 17.8992862 },
    { lat: 48.8671226, lng: 17.8999339 },
    { lat: 48.8667262, lng: 17.9004549 },
    { lat: 48.8659714, lng: 17.9016646 },
    { lat: 48.8658019, lng: 17.9019271 },
    { lat: 48.8657327, lng: 17.9020349 },
    { lat: 48.8655795, lng: 17.9021904 },
    { lat: 48.8647415, lng: 17.9034456 },
    { lat: 48.8642848, lng: 17.9040742 },
    { lat: 48.8640566, lng: 17.9043508 },
    { lat: 48.8639372, lng: 17.9045067 },
    { lat: 48.8639131, lng: 17.9045377 },
    { lat: 48.8638643, lng: 17.9046082 },
    { lat: 48.8638227, lng: 17.904668 },
    { lat: 48.8636041, lng: 17.9049398 },
    { lat: 48.8622894, lng: 17.9064892 },
    { lat: 48.8621986, lng: 17.9065924 },
    { lat: 48.8620756, lng: 17.9067426 },
    { lat: 48.8620451, lng: 17.906782 },
    { lat: 48.8618804, lng: 17.906971 },
    { lat: 48.8608083, lng: 17.9082001 },
    { lat: 48.8607671, lng: 17.9082466 },
    { lat: 48.8607659, lng: 17.9082471 },
    { lat: 48.8603065, lng: 17.9087545 },
    { lat: 48.8602876, lng: 17.9087783 },
    { lat: 48.860032, lng: 17.9090923 },
    { lat: 48.8598765, lng: 17.9092844 },
    { lat: 48.8598196, lng: 17.9093535 },
    { lat: 48.8596526, lng: 17.9095586 },
    { lat: 48.8594968, lng: 17.9097492 },
    { lat: 48.8593802, lng: 17.9098921 },
    { lat: 48.8592529, lng: 17.9100484 },
    { lat: 48.859133, lng: 17.9101961 },
    { lat: 48.8590279, lng: 17.9103252 },
    { lat: 48.8589669, lng: 17.9104003 },
    { lat: 48.8589415, lng: 17.9104458 },
    { lat: 48.858859, lng: 17.9105973 },
    { lat: 48.8587677, lng: 17.9107668 },
    { lat: 48.8587626, lng: 17.9107758 },
    { lat: 48.8586812, lng: 17.9108866 },
    { lat: 48.8585601, lng: 17.9110528 },
    { lat: 48.8585247, lng: 17.9111014 },
    { lat: 48.8584722, lng: 17.9112036 },
    { lat: 48.8584032, lng: 17.911336 },
    { lat: 48.8583244, lng: 17.9114885 },
    { lat: 48.8583102, lng: 17.9115162 },
    { lat: 48.858232, lng: 17.911604 },
    { lat: 48.8581315, lng: 17.9117157 },
    { lat: 48.8581282, lng: 17.9117201 },
    { lat: 48.8580289, lng: 17.9118517 },
    { lat: 48.8580164, lng: 17.9118678 },
    { lat: 48.8580058, lng: 17.91188 },
    { lat: 48.857945, lng: 17.9119937 },
    { lat: 48.857885, lng: 17.9121063 },
    { lat: 48.857822, lng: 17.9122232 },
    { lat: 48.8577885, lng: 17.9122845 },
    { lat: 48.8577691, lng: 17.9123395 },
    { lat: 48.8577267, lng: 17.91246 },
    { lat: 48.8576822, lng: 17.9125826 },
    { lat: 48.8575992, lng: 17.9128159 },
    { lat: 48.8575097, lng: 17.9128892 },
    { lat: 48.8571886, lng: 17.9130702 },
    { lat: 48.8567354, lng: 17.9133613 },
    { lat: 48.8563271, lng: 17.91346 },
    { lat: 48.8562292, lng: 17.9135033 },
    { lat: 48.8561907, lng: 17.91352 },
    { lat: 48.8556969, lng: 17.9137352 },
    { lat: 48.8556702, lng: 17.9137432 },
    { lat: 48.8554053, lng: 17.9138233 },
    { lat: 48.8551814, lng: 17.913891 },
    { lat: 48.8551603, lng: 17.913899 },
    { lat: 48.8550524, lng: 17.9139249 },
    { lat: 48.8550034, lng: 17.913937 },
    { lat: 48.8548778, lng: 17.9139675 },
    { lat: 48.854756, lng: 17.913933 },
    { lat: 48.8545331, lng: 17.9138683 },
    { lat: 48.8544896, lng: 17.9139517 },
    { lat: 48.8544198, lng: 17.9140846 },
    { lat: 48.8543254, lng: 17.9142636 },
    { lat: 48.8542484, lng: 17.9144107 },
    { lat: 48.8541643, lng: 17.9145714 },
    { lat: 48.8540832, lng: 17.9147255 },
    { lat: 48.8540023, lng: 17.9148793 },
    { lat: 48.8539172, lng: 17.9150426 },
    { lat: 48.8538825, lng: 17.9151069 },
    { lat: 48.8538494, lng: 17.9151735 },
    { lat: 48.8537778, lng: 17.9153181 },
    { lat: 48.8537096, lng: 17.9154583 },
    { lat: 48.853646, lng: 17.9155857 },
    { lat: 48.8535866, lng: 17.9157067 },
    { lat: 48.8535826, lng: 17.9157152 },
    { lat: 48.8535194, lng: 17.9158289 },
    { lat: 48.8534513, lng: 17.9159522 },
    { lat: 48.8533594, lng: 17.9161161 },
    { lat: 48.8532759, lng: 17.916267 },
    { lat: 48.8531943, lng: 17.9164126 },
    { lat: 48.8531127, lng: 17.9165597 },
    { lat: 48.8530231, lng: 17.9167218 },
    { lat: 48.8529425, lng: 17.9168651 },
    { lat: 48.8528429, lng: 17.9170459 },
    { lat: 48.8527716, lng: 17.9171589 },
    { lat: 48.8526241, lng: 17.9174652 },
    { lat: 48.8526068, lng: 17.9174962 },
    { lat: 48.8522183, lng: 17.9181542 },
    { lat: 48.8514882, lng: 17.9191402 },
    { lat: 48.8513462, lng: 17.9193321 },
    { lat: 48.8507152, lng: 17.9204055 },
    { lat: 48.8506753, lng: 17.9204737 },
    { lat: 48.8504108, lng: 17.9209401 },
    { lat: 48.850451, lng: 17.9211213 },
    { lat: 48.8505425, lng: 17.9215395 },
    { lat: 48.8505496, lng: 17.921583 },
    { lat: 48.8504238, lng: 17.9216676 },
    { lat: 48.8501673, lng: 17.9220777 },
    { lat: 48.8499727, lng: 17.9223912 },
    { lat: 48.8496178, lng: 17.9229854 },
    { lat: 48.8494801, lng: 17.9232154 },
    { lat: 48.8493567, lng: 17.9234173 },
    { lat: 48.848731, lng: 17.9233182 },
    { lat: 48.8486488, lng: 17.9233001 },
    { lat: 48.8486227, lng: 17.9233331 },
    { lat: 48.8485713, lng: 17.9234 },
    { lat: 48.8480292, lng: 17.9241528 },
    { lat: 48.8477565, lng: 17.9247619 },
    { lat: 48.8476687, lng: 17.9248445 },
    { lat: 48.8461589, lng: 17.9276456 },
    { lat: 48.8459771, lng: 17.9290603 },
    { lat: 48.8459406, lng: 17.9290356 },
    { lat: 48.8453894, lng: 17.9285786 },
    { lat: 48.845317, lng: 17.9284997 },
    { lat: 48.844953, lng: 17.928131 },
    { lat: 48.8440253, lng: 17.9272076 },
    { lat: 48.8430386, lng: 17.9262206 },
    { lat: 48.8430228, lng: 17.92626 },
    { lat: 48.842763, lng: 17.9269005 },
    { lat: 48.8423504, lng: 17.9279286 },
    { lat: 48.8423071, lng: 17.9280897 },
    { lat: 48.8422878, lng: 17.9281629 },
    { lat: 48.8422628, lng: 17.9282556 },
    { lat: 48.8422035, lng: 17.9284748 },
    { lat: 48.8421228, lng: 17.9286905 },
    { lat: 48.8416869, lng: 17.9298002 },
    { lat: 48.8412933, lng: 17.9308095 },
    { lat: 48.8412925, lng: 17.9308164 },
    { lat: 48.8408859, lng: 17.931869 },
    { lat: 48.8405651, lng: 17.9326963 },
    { lat: 48.8400855, lng: 17.9339456 },
    { lat: 48.8397402, lng: 17.9348536 },
    { lat: 48.8396206, lng: 17.9351753 },
    { lat: 48.8394961, lng: 17.9355105 },
    { lat: 48.8394843, lng: 17.9355433 },
    { lat: 48.8394787, lng: 17.9355599 },
    { lat: 48.8394338, lng: 17.9356793 },
    { lat: 48.8392933, lng: 17.9360479 },
    { lat: 48.8392555, lng: 17.9361526 },
    { lat: 48.8392038, lng: 17.9362891 },
    { lat: 48.8389688, lng: 17.9369286 },
    { lat: 48.8388844, lng: 17.937158 },
    { lat: 48.8388399, lng: 17.9372806 },
    { lat: 48.8388242, lng: 17.9373229 },
    { lat: 48.8388204, lng: 17.9373341 },
    { lat: 48.8387776, lng: 17.93745 },
    { lat: 48.8387081, lng: 17.9376448 },
    { lat: 48.838828, lng: 17.9377283 },
    { lat: 48.8389485, lng: 17.9378372 },
    { lat: 48.8389545, lng: 17.9378421 },
    { lat: 48.8390535, lng: 17.9379311 },
    { lat: 48.8393857, lng: 17.9382195 },
    { lat: 48.8395668, lng: 17.9383807 },
    { lat: 48.8395848, lng: 17.9383965 },
    { lat: 48.8393837, lng: 17.9389071 },
    { lat: 48.8393103, lng: 17.9390956 },
    { lat: 48.8390286, lng: 17.9398224 },
    { lat: 48.8387258, lng: 17.9406103 },
    { lat: 48.838526, lng: 17.9411027 },
    { lat: 48.8382246, lng: 17.9418595 },
    { lat: 48.8377644, lng: 17.9430186 },
    { lat: 48.8375233, lng: 17.9436334 },
    { lat: 48.8372337, lng: 17.9443603 },
    { lat: 48.8371246, lng: 17.9446346 },
    { lat: 48.836371, lng: 17.9465339 },
    { lat: 48.8361504, lng: 17.9470904 },
    { lat: 48.8361289, lng: 17.9471467 },
    { lat: 48.8361901, lng: 17.9471541 },
    { lat: 48.836768, lng: 17.947227 },
    { lat: 48.8371967, lng: 17.9472809 },
    { lat: 48.8372226, lng: 17.9472823 },
    { lat: 48.8374083, lng: 17.9472913 },
    { lat: 48.8379711, lng: 17.9473398 },
    { lat: 48.8383263, lng: 17.9473715 },
    { lat: 48.8384987, lng: 17.9473869 },
    { lat: 48.8385815, lng: 17.9473943 },
    { lat: 48.8388335, lng: 17.9474168 },
    { lat: 48.839039, lng: 17.9474349 },
    { lat: 48.8393573, lng: 17.9474551 },
    { lat: 48.8394796, lng: 17.9474628 },
    { lat: 48.8395544, lng: 17.9474672 },
    { lat: 48.8396247, lng: 17.947472 },
    { lat: 48.8396824, lng: 17.9474755 },
    { lat: 48.8396835, lng: 17.9474754 },
    { lat: 48.8397674, lng: 17.9474811 },
    { lat: 48.8397803, lng: 17.9487027 },
    { lat: 48.8397938, lng: 17.9499334 },
    { lat: 48.8398253, lng: 17.9508206 },
    { lat: 48.8398665, lng: 17.9518735 },
    { lat: 48.8398802, lng: 17.9522389 },
    { lat: 48.8398818, lng: 17.9523218 },
    { lat: 48.8404155, lng: 17.9530934 },
    { lat: 48.8408158, lng: 17.9536962 },
    { lat: 48.8409177, lng: 17.9538543 },
    { lat: 48.8411937, lng: 17.9543537 },
    { lat: 48.8413377, lng: 17.9546325 },
    { lat: 48.8414245, lng: 17.9547988 },
    { lat: 48.841478, lng: 17.9549036 },
    { lat: 48.841552, lng: 17.9550462 },
    { lat: 48.8417128, lng: 17.9553582 },
    { lat: 48.8418145, lng: 17.9552472 },
    { lat: 48.8419067, lng: 17.9551468 },
    { lat: 48.8419448, lng: 17.9551043 },
    { lat: 48.8421505, lng: 17.9548798 },
    { lat: 48.8422469, lng: 17.9547746 },
    { lat: 48.8423276, lng: 17.9546887 },
    { lat: 48.8423626, lng: 17.954652 },
    { lat: 48.8424636, lng: 17.9545454 },
    { lat: 48.8426613, lng: 17.9544187 },
    { lat: 48.8427028, lng: 17.9543923 },
    { lat: 48.842806, lng: 17.9543255 },
    { lat: 48.8432898, lng: 17.9540136 },
    { lat: 48.843303, lng: 17.9540093 },
    { lat: 48.8435344, lng: 17.9538945 },
    { lat: 48.8442343, lng: 17.9535377 },
    { lat: 48.8445566, lng: 17.9534735 },
    { lat: 48.8439341, lng: 17.9520786 },
    { lat: 48.8439978, lng: 17.9520083 },
    { lat: 48.8443252, lng: 17.9516514 },
    { lat: 48.8448591, lng: 17.9510649 },
    { lat: 48.8443888, lng: 17.9499717 },
    { lat: 48.8439013, lng: 17.9488544 },
    { lat: 48.84346, lng: 17.9478512 },
    { lat: 48.8436946, lng: 17.9479062 },
    { lat: 48.8437635, lng: 17.9479226 },
    { lat: 48.8438275, lng: 17.9479371 },
    { lat: 48.8438308, lng: 17.9479381 },
    { lat: 48.843863, lng: 17.9479455 },
    { lat: 48.8439064, lng: 17.947956 },
    { lat: 48.8440679, lng: 17.9479942 },
    { lat: 48.8441847, lng: 17.9480212 },
    { lat: 48.8442065, lng: 17.9480264 },
    { lat: 48.8443221, lng: 17.9480526 },
    { lat: 48.8444158, lng: 17.9480738 },
    { lat: 48.8444468, lng: 17.9480814 },
    { lat: 48.8445187, lng: 17.9480974 },
    { lat: 48.8445942, lng: 17.9481146 },
    { lat: 48.8446379, lng: 17.9481248 },
    { lat: 48.8447808, lng: 17.9481572 },
    { lat: 48.8448265, lng: 17.9481671 },
    { lat: 48.8449032, lng: 17.9481842 },
    { lat: 48.845021, lng: 17.9482111 },
    { lat: 48.8450713, lng: 17.9482228 },
    { lat: 48.8452351, lng: 17.9482606 },
    { lat: 48.8452656, lng: 17.9482805 },
    { lat: 48.8453208, lng: 17.9483163 },
    { lat: 48.8454424, lng: 17.9483892 },
    { lat: 48.8454796, lng: 17.9484064 },
    { lat: 48.8455783, lng: 17.9484516 },
    { lat: 48.8456931, lng: 17.9485044 },
    { lat: 48.8458882, lng: 17.9485949 },
    { lat: 48.8465333, lng: 17.9488912 },
    { lat: 48.8465555, lng: 17.9489025 },
    { lat: 48.8472991, lng: 17.9494491 },
  ],
  ChocholnáVelčice: [
    { lat: 48.9171842, lng: 17.8982905 },
    { lat: 48.9173201, lng: 17.8982349 },
    { lat: 48.9173868, lng: 17.8981992 },
    { lat: 48.9174365, lng: 17.8981446 },
    { lat: 48.9175785, lng: 17.8979875 },
    { lat: 48.9176605, lng: 17.8978686 },
    { lat: 48.9177843, lng: 17.8976918 },
    { lat: 48.9178643, lng: 17.897539 },
    { lat: 48.917925, lng: 17.8974229 },
    { lat: 48.9179726, lng: 17.8973334 },
    { lat: 48.9179868, lng: 17.89725 },
    { lat: 48.9180191, lng: 17.8970708 },
    { lat: 48.9180531, lng: 17.8968841 },
    { lat: 48.9183609, lng: 17.8960876 },
    { lat: 48.9183968, lng: 17.8959933 },
    { lat: 48.9184673, lng: 17.8959251 },
    { lat: 48.918873, lng: 17.8955356 },
    { lat: 48.9189179, lng: 17.8955319 },
    { lat: 48.9201654, lng: 17.8939539 },
    { lat: 48.9203526, lng: 17.8937062 },
    { lat: 48.9207327, lng: 17.8934933 },
    { lat: 48.920832, lng: 17.8934373 },
    { lat: 48.9209208, lng: 17.8933564 },
    { lat: 48.9211111, lng: 17.8931809 },
    { lat: 48.9215309, lng: 17.8927618 },
    { lat: 48.9220053, lng: 17.8924486 },
    { lat: 48.9220694, lng: 17.8924064 },
    { lat: 48.9221323, lng: 17.8923464 },
    { lat: 48.9224225, lng: 17.8920665 },
    { lat: 48.922486, lng: 17.8919763 },
    { lat: 48.922588, lng: 17.8918312 },
    { lat: 48.9227272, lng: 17.8917431 },
    { lat: 48.9227805, lng: 17.8917088 },
    { lat: 48.9228373, lng: 17.8916981 },
    { lat: 48.9230181, lng: 17.891663 },
    { lat: 48.9232252, lng: 17.8915591 },
    { lat: 48.9234, lng: 17.8914452 },
    { lat: 48.923461, lng: 17.8914055 },
    { lat: 48.923509, lng: 17.8913422 },
    { lat: 48.92364, lng: 17.8911715 },
    { lat: 48.9236146, lng: 17.8911273 },
    { lat: 48.9236543, lng: 17.8910716 },
    { lat: 48.9240027, lng: 17.8905776 },
    { lat: 48.9240473, lng: 17.8905137 },
    { lat: 48.9241066, lng: 17.890485 },
    { lat: 48.9243064, lng: 17.8903892 },
    { lat: 48.9243167, lng: 17.8903347 },
    { lat: 48.9243403, lng: 17.8903149 },
    { lat: 48.9243675, lng: 17.8903187 },
    { lat: 48.9244989, lng: 17.8903249 },
    { lat: 48.9246354, lng: 17.8903061 },
    { lat: 48.9247085, lng: 17.890282 },
    { lat: 48.9247727, lng: 17.8902606 },
    { lat: 48.9248274, lng: 17.89023 },
    { lat: 48.9249287, lng: 17.8901719 },
    { lat: 48.9250609, lng: 17.8900955 },
    { lat: 48.9251641, lng: 17.8900306 },
    { lat: 48.925782, lng: 17.8896613 },
    { lat: 48.9258369, lng: 17.8895593 },
    { lat: 48.9259548, lng: 17.8893427 },
    { lat: 48.9261582, lng: 17.8888196 },
    { lat: 48.9262042, lng: 17.8887379 },
    { lat: 48.9262827, lng: 17.8885992 },
    { lat: 48.9266892, lng: 17.8881672 },
    { lat: 48.9268078, lng: 17.8880001 },
    { lat: 48.926863, lng: 17.8879221 },
    { lat: 48.9269036, lng: 17.8878461 },
    { lat: 48.9271429, lng: 17.8873956 },
    { lat: 48.927391, lng: 17.8868077 },
    { lat: 48.9274837, lng: 17.8864532 },
    { lat: 48.927525, lng: 17.8862985 },
    { lat: 48.9275492, lng: 17.8862119 },
    { lat: 48.927559, lng: 17.8861111 },
    { lat: 48.9275806, lng: 17.8859078 },
    { lat: 48.9276366, lng: 17.885316 },
    { lat: 48.927677, lng: 17.885311 },
    { lat: 48.927633, lng: 17.884698 },
    { lat: 48.927488, lng: 17.883903 },
    { lat: 48.92724, lng: 17.882742 },
    { lat: 48.927162, lng: 17.8826518 },
    { lat: 48.927111, lng: 17.882017 },
    { lat: 48.927047, lng: 17.880877 },
    { lat: 48.926975, lng: 17.880029 },
    { lat: 48.926905, lng: 17.87964 },
    { lat: 48.926666, lng: 17.878685 },
    { lat: 48.926327, lng: 17.877258 },
    { lat: 48.925909, lng: 17.875863 },
    { lat: 48.925502, lng: 17.874808 },
    { lat: 48.925238, lng: 17.87429 },
    { lat: 48.924771, lng: 17.873643 },
    { lat: 48.924486, lng: 17.873276 },
    { lat: 48.923692, lng: 17.871937 },
    { lat: 48.923197, lng: 17.87085 },
    { lat: 48.922623, lng: 17.869695 },
    { lat: 48.922596, lng: 17.868896 },
    { lat: 48.92269, lng: 17.868296 },
    { lat: 48.922967, lng: 17.867211 },
    { lat: 48.923284, lng: 17.865989 },
    { lat: 48.923447, lng: 17.865503 },
    { lat: 48.923562, lng: 17.865128 },
    { lat: 48.923862, lng: 17.864004 },
    { lat: 48.924174, lng: 17.862936 },
    { lat: 48.924328, lng: 17.862332 },
    { lat: 48.924445, lng: 17.861489 },
    { lat: 48.924502, lng: 17.860549 },
    { lat: 48.924638, lng: 17.859721 },
    { lat: 48.924807, lng: 17.859078 },
    { lat: 48.925003, lng: 17.858556 },
    { lat: 48.92546, lng: 17.85777 },
    { lat: 48.925798, lng: 17.857127 },
    { lat: 48.926251, lng: 17.856243 },
    { lat: 48.926632, lng: 17.85523 },
    { lat: 48.926782, lng: 17.854715 },
    { lat: 48.926991, lng: 17.853842 },
    { lat: 48.92726, lng: 17.852886 },
    { lat: 48.927383, lng: 17.852252 },
    { lat: 48.927452, lng: 17.851654 },
    { lat: 48.92751, lng: 17.850106 },
    { lat: 48.927605, lng: 17.8487 },
    { lat: 48.927619, lng: 17.847859 },
    { lat: 48.92764, lng: 17.847158 },
    { lat: 48.927609, lng: 17.847029 },
    { lat: 48.926943, lng: 17.845624 },
    { lat: 48.926455, lng: 17.844589 },
    { lat: 48.926401, lng: 17.844223 },
    { lat: 48.926355, lng: 17.844248 },
    { lat: 48.92608, lng: 17.844346 },
    { lat: 48.92582, lng: 17.844662 },
    { lat: 48.925802, lng: 17.844673 },
    { lat: 48.92574, lng: 17.844712 },
    { lat: 48.925678, lng: 17.844747 },
    { lat: 48.925366, lng: 17.845206 },
    { lat: 48.925167, lng: 17.845293 },
    { lat: 48.924527, lng: 17.84554 },
    { lat: 48.924065, lng: 17.845734 },
    { lat: 48.923899, lng: 17.845813 },
    { lat: 48.923859, lng: 17.845829 },
    { lat: 48.923847, lng: 17.845834 },
    { lat: 48.923452, lng: 17.846009 },
    { lat: 48.923126, lng: 17.84615 },
    { lat: 48.9226838, lng: 17.8461405 },
    { lat: 48.922476, lng: 17.846136 },
    { lat: 48.921794, lng: 17.845809 },
    { lat: 48.921629, lng: 17.84573 },
    { lat: 48.921413, lng: 17.845661 },
    { lat: 48.920512, lng: 17.844859 },
    { lat: 48.91975, lng: 17.844596 },
    { lat: 48.919171, lng: 17.844282 },
    { lat: 48.918549, lng: 17.843994 },
    { lat: 48.918291, lng: 17.843826 },
    { lat: 48.917979, lng: 17.843726 },
    { lat: 48.917131, lng: 17.843186 },
    { lat: 48.916562, lng: 17.842566 },
    { lat: 48.916502, lng: 17.84254 },
    { lat: 48.916115, lng: 17.842372 },
    { lat: 48.915696, lng: 17.842266 },
    { lat: 48.91533, lng: 17.842376 },
    { lat: 48.914814, lng: 17.842592 },
    { lat: 48.914487, lng: 17.842897 },
    { lat: 48.913671, lng: 17.843071 },
    { lat: 48.913105, lng: 17.843062 },
    { lat: 48.912661, lng: 17.843315 },
    { lat: 48.912341, lng: 17.843544 },
    { lat: 48.911712, lng: 17.843979 },
    { lat: 48.91113, lng: 17.844527 },
    { lat: 48.91082, lng: 17.844775 },
    { lat: 48.910221, lng: 17.845011 },
    { lat: 48.909987, lng: 17.845352 },
    { lat: 48.909724, lng: 17.845469 },
    { lat: 48.908487, lng: 17.845727 },
    { lat: 48.907966, lng: 17.845512 },
    { lat: 48.907437, lng: 17.845351 },
    { lat: 48.907065, lng: 17.845427 },
    { lat: 48.906301, lng: 17.845647 },
    { lat: 48.905863, lng: 17.845783 },
    { lat: 48.905294, lng: 17.845913 },
    { lat: 48.904226, lng: 17.84594 },
    { lat: 48.903568, lng: 17.845738 },
    { lat: 48.903066, lng: 17.845571 },
    { lat: 48.903045, lng: 17.845562 },
    { lat: 48.903024, lng: 17.845523 },
    { lat: 48.9029836, lng: 17.8456159 },
    { lat: 48.9017215, lng: 17.8472983 },
    { lat: 48.9012478, lng: 17.8477538 },
    { lat: 48.900879, lng: 17.8484114 },
    { lat: 48.9006458, lng: 17.848776 },
    { lat: 48.9000972, lng: 17.8495429 },
    { lat: 48.8991337, lng: 17.8504678 },
    { lat: 48.8989346, lng: 17.8506839 },
    { lat: 48.8989119, lng: 17.850833 },
    { lat: 48.8987453, lng: 17.8519612 },
    { lat: 48.8986512, lng: 17.85276 },
    { lat: 48.8985555, lng: 17.853624 },
    { lat: 48.898518, lng: 17.8537716 },
    { lat: 48.8984911, lng: 17.8539027 },
    { lat: 48.8983592, lng: 17.8545073 },
    { lat: 48.8982422, lng: 17.8550341 },
    { lat: 48.8979743, lng: 17.8562283 },
    { lat: 48.8978178, lng: 17.8569284 },
    { lat: 48.8977397, lng: 17.8572948 },
    { lat: 48.8974431, lng: 17.8584263 },
    { lat: 48.896788, lng: 17.8596358 },
    { lat: 48.8967758, lng: 17.8596568 },
    { lat: 48.8967473, lng: 17.859711 },
    { lat: 48.8965904, lng: 17.8599918 },
    { lat: 48.8964876, lng: 17.8601772 },
    { lat: 48.8961359, lng: 17.8613826 },
    { lat: 48.8953902, lng: 17.8639761 },
    { lat: 48.8949815, lng: 17.8654376 },
    { lat: 48.8946603, lng: 17.865763 },
    { lat: 48.8945976, lng: 17.8658266 },
    { lat: 48.8943705, lng: 17.8660485 },
    { lat: 48.8937623, lng: 17.8666724 },
    { lat: 48.8934206, lng: 17.8683839 },
    { lat: 48.8929728, lng: 17.8706385 },
    { lat: 48.8929633, lng: 17.8706889 },
    { lat: 48.89291, lng: 17.8709625 },
    { lat: 48.8923624, lng: 17.8735325 },
    { lat: 48.8912215, lng: 17.8744358 },
    { lat: 48.8912457, lng: 17.8774102 },
    { lat: 48.8898508, lng: 17.879388 },
    { lat: 48.8898031, lng: 17.8794563 },
    { lat: 48.8896668, lng: 17.8796484 },
    { lat: 48.8894015, lng: 17.8800223 },
    { lat: 48.8894116, lng: 17.8801561 },
    { lat: 48.8894117, lng: 17.8803627 },
    { lat: 48.8894125, lng: 17.8804501 },
    { lat: 48.889413, lng: 17.8806671 },
    { lat: 48.889413, lng: 17.8816783 },
    { lat: 48.889412, lng: 17.8819647 },
    { lat: 48.8894124, lng: 17.8827032 },
    { lat: 48.8896208, lng: 17.8848768 },
    { lat: 48.8896332, lng: 17.8850085 },
    { lat: 48.8896848, lng: 17.8851901 },
    { lat: 48.8896947, lng: 17.8852203 },
    { lat: 48.8896974, lng: 17.8852296 },
    { lat: 48.8897399, lng: 17.885355 },
    { lat: 48.8899134, lng: 17.8858572 },
    { lat: 48.890042, lng: 17.8862305 },
    { lat: 48.8891514, lng: 17.887959 },
    { lat: 48.8890262, lng: 17.8884691 },
    { lat: 48.8889853, lng: 17.8886355 },
    { lat: 48.8888306, lng: 17.8892731 },
    { lat: 48.8887409, lng: 17.8896395 },
    { lat: 48.8886603, lng: 17.8898967 },
    { lat: 48.8884275, lng: 17.8906243 },
    { lat: 48.888237, lng: 17.8912493 },
    { lat: 48.888147, lng: 17.8915185 },
    { lat: 48.8880911, lng: 17.8915262 },
    { lat: 48.8879992, lng: 17.8915389 },
    { lat: 48.8877442, lng: 17.8915946 },
    { lat: 48.8876427, lng: 17.8916172 },
    { lat: 48.8875983, lng: 17.8916272 },
    { lat: 48.8875927, lng: 17.891628 },
    { lat: 48.8875625, lng: 17.8916329 },
    { lat: 48.8875234, lng: 17.8916397 },
    { lat: 48.8871578, lng: 17.8916995 },
    { lat: 48.8871319, lng: 17.8918319 },
    { lat: 48.8869774, lng: 17.8925494 },
    { lat: 48.8867892, lng: 17.8924591 },
    { lat: 48.8866344, lng: 17.8925321 },
    { lat: 48.8865895, lng: 17.8925921 },
    { lat: 48.8863878, lng: 17.8928614 },
    { lat: 48.886374, lng: 17.8928762 },
    { lat: 48.8863515, lng: 17.8931598 },
    { lat: 48.8863, lng: 17.8932784 },
    { lat: 48.8861607, lng: 17.8936111 },
    { lat: 48.8860927, lng: 17.8937772 },
    { lat: 48.885993, lng: 17.8940517 },
    { lat: 48.8859727, lng: 17.8941076 },
    { lat: 48.8859115, lng: 17.8942746 },
    { lat: 48.8859198, lng: 17.8944133 },
    { lat: 48.8859295, lng: 17.8945946 },
    { lat: 48.8860499, lng: 17.897409 },
    { lat: 48.8861246, lng: 17.8992397 },
    { lat: 48.8861506, lng: 17.8997483 },
    { lat: 48.8862505, lng: 17.9007908 },
    { lat: 48.8861281, lng: 17.9010652 },
    { lat: 48.8858307, lng: 17.9017952 },
    { lat: 48.8857536, lng: 17.9019952 },
    { lat: 48.8854661, lng: 17.9027719 },
    { lat: 48.8853606, lng: 17.9031985 },
    { lat: 48.8852092, lng: 17.9036272 },
    { lat: 48.8847869, lng: 17.9040415 },
    { lat: 48.8845188, lng: 17.9043711 },
    { lat: 48.884005, lng: 17.9050064 },
    { lat: 48.8833685, lng: 17.9054591 },
    { lat: 48.8828709, lng: 17.9057654 },
    { lat: 48.8826063, lng: 17.9059633 },
    { lat: 48.8821759, lng: 17.9064665 },
    { lat: 48.8813665, lng: 17.9076722 },
    { lat: 48.8810206, lng: 17.9079109 },
    { lat: 48.8804045, lng: 17.9081019 },
    { lat: 48.8802128, lng: 17.9080282 },
    { lat: 48.88007, lng: 17.9079625 },
    { lat: 48.8798997, lng: 17.9080018 },
    { lat: 48.879725, lng: 17.9081022 },
    { lat: 48.8795405, lng: 17.9083191 },
    { lat: 48.8792033, lng: 17.9089458 },
    { lat: 48.8790711, lng: 17.9093021 },
    { lat: 48.8789763, lng: 17.9099263 },
    { lat: 48.8789598, lng: 17.910271 },
    { lat: 48.8787617, lng: 17.9112611 },
    { lat: 48.8782645, lng: 17.911818 },
    { lat: 48.8777859, lng: 17.9123256 },
    { lat: 48.877215, lng: 17.9134064 },
    { lat: 48.8770526, lng: 17.913882 },
    { lat: 48.8767558, lng: 17.9147681 },
    { lat: 48.8767162, lng: 17.9148825 },
    { lat: 48.8764986, lng: 17.915526 },
    { lat: 48.8764812, lng: 17.915575 },
    { lat: 48.8764611, lng: 17.9156355 },
    { lat: 48.8764039, lng: 17.9157549 },
    { lat: 48.876023, lng: 17.9165958 },
    { lat: 48.8759181, lng: 17.916768 },
    { lat: 48.8758903, lng: 17.916812 },
    { lat: 48.8757392, lng: 17.9170587 },
    { lat: 48.8753208, lng: 17.9175167 },
    { lat: 48.8750197, lng: 17.9179171 },
    { lat: 48.8749159, lng: 17.9180124 },
    { lat: 48.8747576, lng: 17.9181769 },
    { lat: 48.8745946, lng: 17.9183585 },
    { lat: 48.8745744, lng: 17.9183806 },
    { lat: 48.8738321, lng: 17.9191987 },
    { lat: 48.8738246, lng: 17.9192073 },
    { lat: 48.8737695, lng: 17.9192679 },
    { lat: 48.8733101, lng: 17.9198129 },
    { lat: 48.8733015, lng: 17.9198227 },
    { lat: 48.8732395, lng: 17.9198976 },
    { lat: 48.8732057, lng: 17.9199571 },
    { lat: 48.873035, lng: 17.9202161 },
    { lat: 48.8723448, lng: 17.921264 },
    { lat: 48.872295, lng: 17.9213178 },
    { lat: 48.8722609, lng: 17.9213523 },
    { lat: 48.8721533, lng: 17.92148 },
    { lat: 48.8721081, lng: 17.9215342 },
    { lat: 48.8720891, lng: 17.9215569 },
    { lat: 48.8720198, lng: 17.9216432 },
    { lat: 48.8719987, lng: 17.9216672 },
    { lat: 48.8716987, lng: 17.9220129 },
    { lat: 48.871552, lng: 17.9222772 },
    { lat: 48.871408, lng: 17.9225322 },
    { lat: 48.8713449, lng: 17.9225875 },
    { lat: 48.8708423, lng: 17.9230208 },
    { lat: 48.8701995, lng: 17.9235759 },
    { lat: 48.8697967, lng: 17.923766 },
    { lat: 48.8696628, lng: 17.9239439 },
    { lat: 48.8696199, lng: 17.9240018 },
    { lat: 48.8694305, lng: 17.9243071 },
    { lat: 48.8693476, lng: 17.9245265 },
    { lat: 48.869163, lng: 17.9248931 },
    { lat: 48.8690549, lng: 17.9250471 },
    { lat: 48.868964, lng: 17.9251524 },
    { lat: 48.8688254, lng: 17.9252682 },
    { lat: 48.8687446, lng: 17.9253733 },
    { lat: 48.8685912, lng: 17.9257504 },
    { lat: 48.8684938, lng: 17.9258774 },
    { lat: 48.8683453, lng: 17.9260168 },
    { lat: 48.8680041, lng: 17.9262601 },
    { lat: 48.867894, lng: 17.926381 },
    { lat: 48.8677997, lng: 17.9262007 },
    { lat: 48.8667647, lng: 17.9261255 },
    { lat: 48.8662898, lng: 17.9261512 },
    { lat: 48.8659605, lng: 17.9262294 },
    { lat: 48.8657192, lng: 17.9264425 },
    { lat: 48.8652663, lng: 17.927155 },
    { lat: 48.8646883, lng: 17.9277577 },
    { lat: 48.8637396, lng: 17.9292441 },
    { lat: 48.8635569, lng: 17.9294911 },
    { lat: 48.8634895, lng: 17.9295717 },
    { lat: 48.8632614, lng: 17.9298754 },
    { lat: 48.8630181, lng: 17.9302408 },
    { lat: 48.8628442, lng: 17.9305604 },
    { lat: 48.8626122, lng: 17.9308352 },
    { lat: 48.8624313, lng: 17.9309974 },
    { lat: 48.8622207, lng: 17.9311022 },
    { lat: 48.8611246, lng: 17.9311382 },
    { lat: 48.86084, lng: 17.9312482 },
    { lat: 48.8601687, lng: 17.9316123 },
    { lat: 48.8595219, lng: 17.9318588 },
    { lat: 48.8590937, lng: 17.9320953 },
    { lat: 48.8586474, lng: 17.9322421 },
    { lat: 48.8578016, lng: 17.9326513 },
    { lat: 48.8576101, lng: 17.9327317 },
    { lat: 48.8575034, lng: 17.9327779 },
    { lat: 48.8573137, lng: 17.9328734 },
    { lat: 48.8571822, lng: 17.9329373 },
    { lat: 48.8570451, lng: 17.933006 },
    { lat: 48.8569344, lng: 17.9330606 },
    { lat: 48.8565389, lng: 17.9332428 },
    { lat: 48.8561049, lng: 17.9334417 },
    { lat: 48.8560338, lng: 17.9335349 },
    { lat: 48.8560013, lng: 17.933576 },
    { lat: 48.8561847, lng: 17.9338322 },
    { lat: 48.8560139, lng: 17.9341126 },
    { lat: 48.8559327, lng: 17.9342456 },
    { lat: 48.8557598, lng: 17.9345428 },
    { lat: 48.8556402, lng: 17.9347155 },
    { lat: 48.8555125, lng: 17.9349025 },
    { lat: 48.8553843, lng: 17.9350806 },
    { lat: 48.8552582, lng: 17.9352546 },
    { lat: 48.8551257, lng: 17.9354165 },
    { lat: 48.8550708, lng: 17.9354835 },
    { lat: 48.8550382, lng: 17.9355238 },
    { lat: 48.8550088, lng: 17.9355579 },
    { lat: 48.8548796, lng: 17.9357183 },
    { lat: 48.8548553, lng: 17.9357474 },
    { lat: 48.8548163, lng: 17.9357915 },
    { lat: 48.8547481, lng: 17.9358811 },
    { lat: 48.8547449, lng: 17.9358844 },
    { lat: 48.8547218, lng: 17.9359127 },
    { lat: 48.8546195, lng: 17.9360328 },
    { lat: 48.8546163, lng: 17.9360361 },
    { lat: 48.854587, lng: 17.936076 },
    { lat: 48.8544703, lng: 17.9362213 },
    { lat: 48.854322, lng: 17.936399 },
    { lat: 48.8541679, lng: 17.93658 },
    { lat: 48.8540588, lng: 17.9367017 },
    { lat: 48.8539572, lng: 17.9368146 },
    { lat: 48.8538159, lng: 17.9369995 },
    { lat: 48.8537503, lng: 17.937073 },
    { lat: 48.8537461, lng: 17.9370779 },
    { lat: 48.8536878, lng: 17.9371393 },
    { lat: 48.8535209, lng: 17.9372959 },
    { lat: 48.8533752, lng: 17.937461 },
    { lat: 48.8532596, lng: 17.9375693 },
    { lat: 48.8531737, lng: 17.9376452 },
    { lat: 48.8530607, lng: 17.9377528 },
    { lat: 48.8529321, lng: 17.9378683 },
    { lat: 48.8528059, lng: 17.9379848 },
    { lat: 48.8526913, lng: 17.9381109 },
    { lat: 48.8526629, lng: 17.9381413 },
    { lat: 48.8525795, lng: 17.9382381 },
    { lat: 48.8524551, lng: 17.9383691 },
    { lat: 48.8523501, lng: 17.9384817 },
    { lat: 48.8522223, lng: 17.9386103 },
    { lat: 48.8521575, lng: 17.9386772 },
    { lat: 48.8518424, lng: 17.9389978 },
    { lat: 48.8515773, lng: 17.9393602 },
    { lat: 48.8513885, lng: 17.9396571 },
    { lat: 48.8513511, lng: 17.9397478 },
    { lat: 48.8512353, lng: 17.9399827 },
    { lat: 48.8510328, lng: 17.9403917 },
    { lat: 48.8508648, lng: 17.9407355 },
    { lat: 48.8507087, lng: 17.9410522 },
    { lat: 48.8506844, lng: 17.9411007 },
    { lat: 48.8506422, lng: 17.9411871 },
    { lat: 48.850623, lng: 17.9412259 },
    { lat: 48.850604, lng: 17.9412633 },
    { lat: 48.8505025, lng: 17.9414733 },
    { lat: 48.8504884, lng: 17.9415028 },
    { lat: 48.850388, lng: 17.9417111 },
    { lat: 48.8504851, lng: 17.941829 },
    { lat: 48.8505813, lng: 17.9419467 },
    { lat: 48.8506579, lng: 17.9420376 },
    { lat: 48.8507163, lng: 17.9421081 },
    { lat: 48.8507529, lng: 17.942154 },
    { lat: 48.8507576, lng: 17.9421591 },
    { lat: 48.8507966, lng: 17.9422046 },
    { lat: 48.8508486, lng: 17.9422645 },
    { lat: 48.8508911, lng: 17.942315 },
    { lat: 48.8509422, lng: 17.9423743 },
    { lat: 48.8509955, lng: 17.9424358 },
    { lat: 48.8510332, lng: 17.9424808 },
    { lat: 48.8511328, lng: 17.9426005 },
    { lat: 48.8512302, lng: 17.9427176 },
    { lat: 48.8514235, lng: 17.9429496 },
    { lat: 48.8515801, lng: 17.9431367 },
    { lat: 48.8516195, lng: 17.9431697 },
    { lat: 48.8517297, lng: 17.9432618 },
    { lat: 48.8518248, lng: 17.9433402 },
    { lat: 48.8518865, lng: 17.9433912 },
    { lat: 48.8520065, lng: 17.9434927 },
    { lat: 48.8521562, lng: 17.9436145 },
    { lat: 48.8521572, lng: 17.9436162 },
    { lat: 48.8522213, lng: 17.943668 },
    { lat: 48.8522877, lng: 17.9437223 },
    { lat: 48.852424, lng: 17.9438312 },
    { lat: 48.8525369, lng: 17.9439226 },
    { lat: 48.8525925, lng: 17.943967 },
    { lat: 48.8526542, lng: 17.9440173 },
    { lat: 48.8526897, lng: 17.9440465 },
    { lat: 48.8527893, lng: 17.944126 },
    { lat: 48.8528817, lng: 17.9442005 },
    { lat: 48.8529118, lng: 17.944229 },
    { lat: 48.8530218, lng: 17.9443315 },
    { lat: 48.853109, lng: 17.9444142 },
    { lat: 48.8532284, lng: 17.9445254 },
    { lat: 48.8533743, lng: 17.9446632 },
    { lat: 48.8543049, lng: 17.9453713 },
    { lat: 48.8543783, lng: 17.9454279 },
    { lat: 48.8543864, lng: 17.945434 },
    { lat: 48.8542926, lng: 17.9455063 },
    { lat: 48.8542139, lng: 17.9455666 },
    { lat: 48.8535194, lng: 17.9466064 },
    { lat: 48.8532573, lng: 17.9469989 },
    { lat: 48.853108, lng: 17.9472211 },
    { lat: 48.8529993, lng: 17.9473288 },
    { lat: 48.8529485, lng: 17.9473458 },
    { lat: 48.8529218, lng: 17.9473544 },
    { lat: 48.8527857, lng: 17.9473996 },
    { lat: 48.8526361, lng: 17.9472555 },
    { lat: 48.8524822, lng: 17.9472128 },
    { lat: 48.8524273, lng: 17.947221 },
    { lat: 48.8523786, lng: 17.9472359 },
    { lat: 48.8522618, lng: 17.9473013 },
    { lat: 48.8522427, lng: 17.9473617 },
    { lat: 48.8518108, lng: 17.9476505 },
    { lat: 48.8513948, lng: 17.947926 },
    { lat: 48.8513505, lng: 17.9479557 },
    { lat: 48.8508848, lng: 17.9482707 },
    { lat: 48.8504285, lng: 17.9485667 },
    { lat: 48.8493802, lng: 17.9492545 },
    { lat: 48.8490499, lng: 17.9494706 },
    { lat: 48.8488634, lng: 17.9495241 },
    { lat: 48.8487252, lng: 17.9495143 },
    { lat: 48.8484889, lng: 17.9493165 },
    { lat: 48.8481642, lng: 17.948813 },
    { lat: 48.8477733, lng: 17.948217 },
    { lat: 48.8476951, lng: 17.9484213 },
    { lat: 48.8476677, lng: 17.9484942 },
    { lat: 48.8472991, lng: 17.9494491 },
    { lat: 48.8478347, lng: 17.950274 },
    { lat: 48.8478397, lng: 17.9502815 },
    { lat: 48.8482338, lng: 17.9511021 },
    { lat: 48.8483784, lng: 17.95133 },
    { lat: 48.8483898, lng: 17.9513492 },
    { lat: 48.8485408, lng: 17.951593 },
    { lat: 48.8488847, lng: 17.9521732 },
    { lat: 48.8491404, lng: 17.9526192 },
    { lat: 48.8494276, lng: 17.9531204 },
    { lat: 48.8496778, lng: 17.9535542 },
    { lat: 48.849892, lng: 17.9539264 },
    { lat: 48.8500008, lng: 17.9541043 },
    { lat: 48.8503742, lng: 17.9547267 },
    { lat: 48.8508232, lng: 17.9554646 },
    { lat: 48.8511595, lng: 17.9560128 },
    { lat: 48.8514494, lng: 17.9564836 },
    { lat: 48.851845, lng: 17.9570983 },
    { lat: 48.8519839, lng: 17.9573105 },
    { lat: 48.852215, lng: 17.9575144 },
    { lat: 48.8526382, lng: 17.9578928 },
    { lat: 48.8527299, lng: 17.957976 },
    { lat: 48.852966, lng: 17.9581875 },
    { lat: 48.853287, lng: 17.9585171 },
    { lat: 48.8535313, lng: 17.9587726 },
    { lat: 48.8539601, lng: 17.9592259 },
    { lat: 48.8541113, lng: 17.9593934 },
    { lat: 48.854127, lng: 17.9594106 },
    { lat: 48.8541523, lng: 17.959439 },
    { lat: 48.8544182, lng: 17.9597335 },
    { lat: 48.8546201, lng: 17.9599594 },
    { lat: 48.8546503, lng: 17.9599933 },
    { lat: 48.8550746, lng: 17.9604672 },
    { lat: 48.8552705, lng: 17.960686 },
    { lat: 48.8554824, lng: 17.9609291 },
    { lat: 48.855872, lng: 17.9613677 },
    { lat: 48.8558938, lng: 17.9613919 },
    { lat: 48.8559771, lng: 17.9614849 },
    { lat: 48.856011, lng: 17.9615218 },
    { lat: 48.8561621, lng: 17.9616718 },
    { lat: 48.8563336, lng: 17.9618437 },
    { lat: 48.856552, lng: 17.9620609 },
    { lat: 48.856643, lng: 17.9621309 },
    { lat: 48.8569413, lng: 17.9623629 },
    { lat: 48.8569849, lng: 17.9623954 },
    { lat: 48.8581988, lng: 17.9633319 },
    { lat: 48.8585633, lng: 17.9628599 },
    { lat: 48.8589398, lng: 17.962373 },
    { lat: 48.8592929, lng: 17.9619147 },
    { lat: 48.8594532, lng: 17.9617075 },
    { lat: 48.8596858, lng: 17.9614062 },
    { lat: 48.8597779, lng: 17.9612846 },
    { lat: 48.8613945, lng: 17.9591998 },
    { lat: 48.8614081, lng: 17.9591822 },
    { lat: 48.8614887, lng: 17.9590784 },
    { lat: 48.8615014, lng: 17.9590612 },
    { lat: 48.8615117, lng: 17.9590462 },
    { lat: 48.8612697, lng: 17.9587322 },
    { lat: 48.8632867, lng: 17.9583542 },
    { lat: 48.8646694, lng: 17.9581463 },
    { lat: 48.8646851, lng: 17.9581438 },
    { lat: 48.8647019, lng: 17.9581412 },
    { lat: 48.8647701, lng: 17.9581304 },
    { lat: 48.8647858, lng: 17.9581279 },
    { lat: 48.8648226, lng: 17.9581225 },
    { lat: 48.8649122, lng: 17.9581099 },
    { lat: 48.8649681, lng: 17.9581023 },
    { lat: 48.8650856, lng: 17.9580855 },
    { lat: 48.8656221, lng: 17.9593247 },
    { lat: 48.8656957, lng: 17.9594806 },
    { lat: 48.865849, lng: 17.9598555 },
    { lat: 48.8662827, lng: 17.9609646 },
    { lat: 48.8666981, lng: 17.961893 },
    { lat: 48.8667526, lng: 17.9620132 },
    { lat: 48.8667804, lng: 17.9620431 },
    { lat: 48.8667983, lng: 17.9620629 },
    { lat: 48.8668685, lng: 17.9621405 },
    { lat: 48.8669445, lng: 17.9622419 },
    { lat: 48.8674275, lng: 17.9630938 },
    { lat: 48.8692469, lng: 17.96072 },
    { lat: 48.8693301, lng: 17.9605997 },
    { lat: 48.8693778, lng: 17.9605311 },
    { lat: 48.8693976, lng: 17.9605016 },
    { lat: 48.8701652, lng: 17.9594212 },
    { lat: 48.8708634, lng: 17.9584269 },
    { lat: 48.8708728, lng: 17.9584131 },
    { lat: 48.8709012, lng: 17.958378 },
    { lat: 48.8709497, lng: 17.9583226 },
    { lat: 48.8709763, lng: 17.9582918 },
    { lat: 48.8710216, lng: 17.9582382 },
    { lat: 48.871033, lng: 17.9582234 },
    { lat: 48.8715984, lng: 17.9575581 },
    { lat: 48.8716374, lng: 17.9575094 },
    { lat: 48.8716129, lng: 17.9574794 },
    { lat: 48.8719621, lng: 17.9570674 },
    { lat: 48.8720992, lng: 17.9569057 },
    { lat: 48.8722881, lng: 17.9566823 },
    { lat: 48.8723396, lng: 17.9566211 },
    { lat: 48.8724335, lng: 17.9565107 },
    { lat: 48.8724756, lng: 17.9564594 },
    { lat: 48.8728105, lng: 17.956072 },
    { lat: 48.8729073, lng: 17.9559949 },
    { lat: 48.8731469, lng: 17.9558073 },
    { lat: 48.8732308, lng: 17.9557381 },
    { lat: 48.8732394, lng: 17.9557326 },
    { lat: 48.8733, lng: 17.9556895 },
    { lat: 48.8733305, lng: 17.9556689 },
    { lat: 48.8733733, lng: 17.9556498 },
    { lat: 48.8740068, lng: 17.9553481 },
    { lat: 48.875601, lng: 17.9545987 },
    { lat: 48.8759092, lng: 17.953919 },
    { lat: 48.8759546, lng: 17.9538472 },
    { lat: 48.8759802, lng: 17.9538 },
    { lat: 48.8760489, lng: 17.9536866 },
    { lat: 48.8762515, lng: 17.9535051 },
    { lat: 48.877252, lng: 17.9524481 },
    { lat: 48.8779049, lng: 17.9515083 },
    { lat: 48.8781991, lng: 17.9511973 },
    { lat: 48.8785771, lng: 17.9505349 },
    { lat: 48.8785768, lng: 17.9503029 },
    { lat: 48.8790859, lng: 17.9495057 },
    { lat: 48.8796097, lng: 17.9489761 },
    { lat: 48.8799137, lng: 17.9487742 },
    { lat: 48.8800072, lng: 17.9487122 },
    { lat: 48.8801296, lng: 17.9486069 },
    { lat: 48.8801553, lng: 17.9485848 },
    { lat: 48.8802477, lng: 17.9485054 },
    { lat: 48.8804635, lng: 17.9483195 },
    { lat: 48.8809235, lng: 17.9478746 },
    { lat: 48.8821187, lng: 17.9466438 },
    { lat: 48.8821387, lng: 17.9466934 },
    { lat: 48.8822262, lng: 17.9465344 },
    { lat: 48.8824929, lng: 17.9462146 },
    { lat: 48.8827765, lng: 17.94548 },
    { lat: 48.8828244, lng: 17.9453544 },
    { lat: 48.8829282, lng: 17.9452427 },
    { lat: 48.8830047, lng: 17.9451608 },
    { lat: 48.88336, lng: 17.9447772 },
    { lat: 48.8838324, lng: 17.9442388 },
    { lat: 48.8842026, lng: 17.9438768 },
    { lat: 48.8846878, lng: 17.9434018 },
    { lat: 48.885065, lng: 17.9431389 },
    { lat: 48.8851678, lng: 17.9430675 },
    { lat: 48.8852662, lng: 17.9429561 },
    { lat: 48.8854817, lng: 17.9427106 },
    { lat: 48.8857104, lng: 17.9424161 },
    { lat: 48.8858115, lng: 17.9422946 },
    { lat: 48.8861738, lng: 17.9418605 },
    { lat: 48.8865792, lng: 17.941409 },
    { lat: 48.886591, lng: 17.941435 },
    { lat: 48.8869833, lng: 17.9410298 },
    { lat: 48.8870981, lng: 17.9409115 },
    { lat: 48.8872011, lng: 17.940783 },
    { lat: 48.887755, lng: 17.9400948 },
    { lat: 48.8880099, lng: 17.9397687 },
    { lat: 48.8882632, lng: 17.9394746 },
    { lat: 48.8885709, lng: 17.9391649 },
    { lat: 48.8887999, lng: 17.9389703 },
    { lat: 48.8888739, lng: 17.9389078 },
    { lat: 48.889107, lng: 17.9387101 },
    { lat: 48.8892254, lng: 17.9386139 },
    { lat: 48.8892998, lng: 17.9385528 },
    { lat: 48.8892705, lng: 17.9384417 },
    { lat: 48.8889449, lng: 17.9372015 },
    { lat: 48.8879593, lng: 17.935817 },
    { lat: 48.8878939, lng: 17.9357241 },
    { lat: 48.8878693, lng: 17.9356127 },
    { lat: 48.8877974, lng: 17.9352797 },
    { lat: 48.8878526, lng: 17.9345752 },
    { lat: 48.8878639, lng: 17.9344237 },
    { lat: 48.8878902, lng: 17.9340905 },
    { lat: 48.8878992, lng: 17.9339767 },
    { lat: 48.8879158, lng: 17.9339324 },
    { lat: 48.8879373, lng: 17.9338771 },
    { lat: 48.8880384, lng: 17.9336215 },
    { lat: 48.8883452, lng: 17.9328413 },
    { lat: 48.888358, lng: 17.9328101 },
    { lat: 48.8884333, lng: 17.9326172 },
    { lat: 48.888781, lng: 17.9318995 },
    { lat: 48.8889593, lng: 17.9315368 },
    { lat: 48.8890144, lng: 17.9314224 },
    { lat: 48.8890765, lng: 17.9313252 },
    { lat: 48.8890918, lng: 17.9313002 },
    { lat: 48.8891237, lng: 17.9312485 },
    { lat: 48.8894513, lng: 17.9307419 },
    { lat: 48.8902237, lng: 17.9297047 },
    { lat: 48.8902977, lng: 17.9296049 },
    { lat: 48.8903146, lng: 17.9295843 },
    { lat: 48.8903368, lng: 17.9295199 },
    { lat: 48.890349, lng: 17.9294781 },
    { lat: 48.8903545, lng: 17.9294601 },
    { lat: 48.8903573, lng: 17.9294515 },
    { lat: 48.8903583, lng: 17.9294474 },
    { lat: 48.8903667, lng: 17.929419 },
    { lat: 48.8904387, lng: 17.9291856 },
    { lat: 48.8904587, lng: 17.9291241 },
    { lat: 48.890478, lng: 17.9290672 },
    { lat: 48.8904975, lng: 17.9290176 },
    { lat: 48.8905982, lng: 17.9288341 },
    { lat: 48.8907424, lng: 17.9285833 },
    { lat: 48.8908437, lng: 17.9284069 },
    { lat: 48.8911084, lng: 17.9279437 },
    { lat: 48.8913229, lng: 17.927547 },
    { lat: 48.8915093, lng: 17.9272097 },
    { lat: 48.8916718, lng: 17.9269255 },
    { lat: 48.8917608, lng: 17.9267709 },
    { lat: 48.8918551, lng: 17.9266514 },
    { lat: 48.8924789, lng: 17.9258681 },
    { lat: 48.892727, lng: 17.9254287 },
    { lat: 48.8927933, lng: 17.9253112 },
    { lat: 48.8928502, lng: 17.925151 },
    { lat: 48.8929859, lng: 17.9247729 },
    { lat: 48.8930399, lng: 17.9246206 },
    { lat: 48.8932709, lng: 17.9239726 },
    { lat: 48.8933681, lng: 17.9233709 },
    { lat: 48.8933923, lng: 17.9232252 },
    { lat: 48.8933919, lng: 17.9231083 },
    { lat: 48.8933897, lng: 17.922445 },
    { lat: 48.8934582, lng: 17.9222699 },
    { lat: 48.893468, lng: 17.9222434 },
    { lat: 48.8935337, lng: 17.9222118 },
    { lat: 48.8935809, lng: 17.9221888 },
    { lat: 48.8936742, lng: 17.9221444 },
    { lat: 48.8937537, lng: 17.9220578 },
    { lat: 48.8939116, lng: 17.9218851 },
    { lat: 48.8941462, lng: 17.9215045 },
    { lat: 48.8943682, lng: 17.9211031 },
    { lat: 48.894443, lng: 17.9210128 },
    { lat: 48.8945378, lng: 17.9208979 },
    { lat: 48.8947567, lng: 17.9209152 },
    { lat: 48.8948729, lng: 17.9209717 },
    { lat: 48.8951558, lng: 17.9213044 },
    { lat: 48.895298, lng: 17.921456 },
    { lat: 48.8954202, lng: 17.9214822 },
    { lat: 48.8954386, lng: 17.9214855 },
    { lat: 48.8957023, lng: 17.9214413 },
    { lat: 48.8958184, lng: 17.9214214 },
    { lat: 48.895947, lng: 17.9213998 },
    { lat: 48.8960986, lng: 17.9213478 },
    { lat: 48.8964988, lng: 17.9212099 },
    { lat: 48.8965183, lng: 17.9211961 },
    { lat: 48.8965911, lng: 17.9211463 },
    { lat: 48.8968924, lng: 17.920937 },
    { lat: 48.8970019, lng: 17.9208613 },
    { lat: 48.8970861, lng: 17.9207622 },
    { lat: 48.8971252, lng: 17.9207166 },
    { lat: 48.8972044, lng: 17.920625 },
    { lat: 48.8972528, lng: 17.9205678 },
    { lat: 48.8977191, lng: 17.9201532 },
    { lat: 48.8979462, lng: 17.9198181 },
    { lat: 48.8980097, lng: 17.919724 },
    { lat: 48.8980727, lng: 17.9196659 },
    { lat: 48.8981348, lng: 17.9196092 },
    { lat: 48.8982344, lng: 17.9196325 },
    { lat: 48.8984, lng: 17.9198903 },
    { lat: 48.898457, lng: 17.9199786 },
    { lat: 48.8984728, lng: 17.9200916 },
    { lat: 48.8984791, lng: 17.9201377 },
    { lat: 48.8986615, lng: 17.9209428 },
    { lat: 48.898738, lng: 17.9212813 },
    { lat: 48.8989724, lng: 17.9217643 },
    { lat: 48.8990419, lng: 17.9218308 },
    { lat: 48.8991388, lng: 17.9219247 },
    { lat: 48.8993353, lng: 17.9221137 },
    { lat: 48.8994334, lng: 17.9222082 },
    { lat: 48.8995227, lng: 17.9223398 },
    { lat: 48.8997826, lng: 17.9227231 },
    { lat: 48.8999134, lng: 17.922854 },
    { lat: 48.9000917, lng: 17.9229282 },
    { lat: 48.9001089, lng: 17.9229358 },
    { lat: 48.9001878, lng: 17.9229142 },
    { lat: 48.9004256, lng: 17.9228489 },
    { lat: 48.9005176, lng: 17.9227649 },
    { lat: 48.9005999, lng: 17.9226904 },
    { lat: 48.9006952, lng: 17.9226379 },
    { lat: 48.9007564, lng: 17.9226047 },
    { lat: 48.9008786, lng: 17.9225362 },
    { lat: 48.9009119, lng: 17.922508 },
    { lat: 48.9013052, lng: 17.9221748 },
    { lat: 48.9018212, lng: 17.9214208 },
    { lat: 48.9019501, lng: 17.9211993 },
    { lat: 48.902049, lng: 17.9210217 },
    { lat: 48.9021315, lng: 17.920873 },
    { lat: 48.9022287, lng: 17.9207628 },
    { lat: 48.9023579, lng: 17.9206176 },
    { lat: 48.9026108, lng: 17.9203575 },
    { lat: 48.9027067, lng: 17.9202593 },
    { lat: 48.9028808, lng: 17.9200937 },
    { lat: 48.9029628, lng: 17.9200125 },
    { lat: 48.9031335, lng: 17.9198458 },
    { lat: 48.9034989, lng: 17.919331 },
    { lat: 48.9036061, lng: 17.9191802 },
    { lat: 48.9037242, lng: 17.91906 },
    { lat: 48.9037913, lng: 17.9189915 },
    { lat: 48.9040171, lng: 17.9187474 },
    { lat: 48.9043475, lng: 17.9183629 },
    { lat: 48.904454, lng: 17.9182571 },
    { lat: 48.904666, lng: 17.918045 },
    { lat: 48.9049633, lng: 17.9175258 },
    { lat: 48.9049939, lng: 17.9174721 },
    { lat: 48.9050757, lng: 17.9173302 },
    { lat: 48.9050152, lng: 17.9171681 },
    { lat: 48.9046753, lng: 17.9162587 },
    { lat: 48.9043927, lng: 17.9154551 },
    { lat: 48.9041761, lng: 17.9149309 },
    { lat: 48.9039453, lng: 17.9143933 },
    { lat: 48.9036263, lng: 17.9134369 },
    { lat: 48.903485, lng: 17.912756 },
    { lat: 48.9034455, lng: 17.9125652 },
    { lat: 48.9034558, lng: 17.9123976 },
    { lat: 48.9034774, lng: 17.9120785 },
    { lat: 48.9034959, lng: 17.9119178 },
    { lat: 48.9035138, lng: 17.9117661 },
    { lat: 48.9035284, lng: 17.9116503 },
    { lat: 48.9035516, lng: 17.911509 },
    { lat: 48.9035847, lng: 17.9113451 },
    { lat: 48.9036187, lng: 17.9112138 },
    { lat: 48.9037421, lng: 17.9108646 },
    { lat: 48.9039369, lng: 17.9105708 },
    { lat: 48.90433, lng: 17.9100894 },
    { lat: 48.9046825, lng: 17.9095091 },
    { lat: 48.9047196, lng: 17.9094484 },
    { lat: 48.9047833, lng: 17.9093431 },
    { lat: 48.9049081, lng: 17.9092437 },
    { lat: 48.9051679, lng: 17.9090357 },
    { lat: 48.9053553, lng: 17.9088858 },
    { lat: 48.9055862, lng: 17.9082734 },
    { lat: 48.905681, lng: 17.9076131 },
    { lat: 48.9057819, lng: 17.9070549 },
    { lat: 48.9058193, lng: 17.9068502 },
    { lat: 48.9059003, lng: 17.9066579 },
    { lat: 48.9061206, lng: 17.9061331 },
    { lat: 48.9064553, lng: 17.9051613 },
    { lat: 48.9064803, lng: 17.9051101 },
    { lat: 48.9065105, lng: 17.9050496 },
    { lat: 48.9071298, lng: 17.9046079 },
    { lat: 48.9075799, lng: 17.9039783 },
    { lat: 48.9078368, lng: 17.9036522 },
    { lat: 48.9080178, lng: 17.9032659 },
    { lat: 48.908082, lng: 17.9031286 },
    { lat: 48.9081806, lng: 17.9032313 },
    { lat: 48.9081916, lng: 17.9032441 },
    { lat: 48.9084465, lng: 17.9035592 },
    { lat: 48.9087084, lng: 17.9039397 },
    { lat: 48.9088798, lng: 17.9038232 },
    { lat: 48.9093044, lng: 17.9035246 },
    { lat: 48.909415, lng: 17.9034473 },
    { lat: 48.9095178, lng: 17.9033355 },
    { lat: 48.9103181, lng: 17.9024677 },
    { lat: 48.910663, lng: 17.9021047 },
    { lat: 48.9112281, lng: 17.9018334 },
    { lat: 48.9113017, lng: 17.9017978 },
    { lat: 48.9113922, lng: 17.9018204 },
    { lat: 48.911689, lng: 17.9018951 },
    { lat: 48.9120876, lng: 17.9018997 },
    { lat: 48.9124175, lng: 17.9019217 },
    { lat: 48.9124812, lng: 17.9019466 },
    { lat: 48.9126498, lng: 17.9020131 },
    { lat: 48.9127065, lng: 17.9020354 },
    { lat: 48.9127566, lng: 17.9020235 },
    { lat: 48.9128756, lng: 17.9019956 },
    { lat: 48.9131606, lng: 17.9019287 },
    { lat: 48.9137307, lng: 17.9017987 },
    { lat: 48.9140378, lng: 17.9016663 },
    { lat: 48.9140993, lng: 17.9016395 },
    { lat: 48.9141529, lng: 17.9015895 },
    { lat: 48.9143664, lng: 17.9013863 },
    { lat: 48.9147129, lng: 17.9009915 },
    { lat: 48.9148795, lng: 17.9008311 },
    { lat: 48.9149349, lng: 17.9007775 },
    { lat: 48.9149792, lng: 17.9007072 },
    { lat: 48.9149885, lng: 17.9006922 },
    { lat: 48.9152439, lng: 17.9002813 },
    { lat: 48.9153357, lng: 17.9002144 },
    { lat: 48.9157079, lng: 17.8999431 },
    { lat: 48.9157668, lng: 17.8998729 },
    { lat: 48.9158302, lng: 17.8997974 },
    { lat: 48.9160438, lng: 17.8995422 },
    { lat: 48.9162227, lng: 17.8990409 },
    { lat: 48.9162759, lng: 17.8989299 },
    { lat: 48.9163486, lng: 17.8988255 },
    { lat: 48.9163996, lng: 17.8987535 },
    { lat: 48.916461, lng: 17.8987238 },
    { lat: 48.9166004, lng: 17.8986572 },
    { lat: 48.9167168, lng: 17.8985837 },
    { lat: 48.9170018, lng: 17.8984048 },
    { lat: 48.9171842, lng: 17.8982905 },
  ],
  DolnáSúča: [
    { lat: 49.0020456, lng: 18.0234356 },
    { lat: 49.0020585, lng: 18.0233856 },
    { lat: 49.0023175, lng: 18.0224156 },
    { lat: 49.0025212, lng: 18.021788 },
    { lat: 49.0027726, lng: 18.0210159 },
    { lat: 49.0030889, lng: 18.0203896 },
    { lat: 49.0031447, lng: 18.0199539 },
    { lat: 49.0033763, lng: 18.0190942 },
    { lat: 49.0037101, lng: 18.0184975 },
    { lat: 49.0038399, lng: 18.0182674 },
    { lat: 49.0039245, lng: 18.0179561 },
    { lat: 49.0041709, lng: 18.0176933 },
    { lat: 49.0044755, lng: 18.0173478 },
    { lat: 49.0045041, lng: 18.0173152 },
    { lat: 49.0047961, lng: 18.016984 },
    { lat: 49.0042952, lng: 18.0163155 },
    { lat: 49.0040071, lng: 18.015869 },
    { lat: 49.0038831, lng: 18.015678 },
    { lat: 49.0032264, lng: 18.0145846 },
    { lat: 49.0031314, lng: 18.0143897 },
    { lat: 49.0031044, lng: 18.014337 },
    { lat: 49.0028066, lng: 18.0137448 },
    { lat: 49.0025385, lng: 18.0132718 },
    { lat: 49.0022354, lng: 18.0127425 },
    { lat: 49.0022092, lng: 18.0126631 },
    { lat: 49.0021426, lng: 18.0124715 },
    { lat: 49.0022052, lng: 18.0123444 },
    { lat: 49.0026855, lng: 18.0114524 },
    { lat: 49.0029855, lng: 18.0104738 },
    { lat: 49.0032841, lng: 18.0094867 },
    { lat: 49.0035415, lng: 18.0082388 },
    { lat: 49.0037814, lng: 18.0069477 },
    { lat: 49.0039618, lng: 18.0057933 },
    { lat: 49.0039843, lng: 18.004778 },
    { lat: 49.0040153, lng: 18.0037895 },
    { lat: 49.0042419, lng: 18.0030955 },
    { lat: 49.0042772, lng: 18.0029846 },
    { lat: 49.0045165, lng: 18.0022014 },
    { lat: 49.0047512, lng: 18.0009944 },
    { lat: 49.0047674, lng: 18.0008275 },
    { lat: 49.0047864, lng: 18.0007696 },
    { lat: 49.0050798, lng: 17.9999046 },
    { lat: 49.0053862, lng: 17.9991284 },
    { lat: 49.0054438, lng: 17.9980181 },
    { lat: 49.0054617, lng: 17.9969422 },
    { lat: 49.0051879, lng: 17.9961649 },
    { lat: 49.0051056, lng: 17.995423 },
    { lat: 49.004953, lng: 17.9946726 },
    { lat: 49.0046703, lng: 17.9939171 },
    { lat: 49.0042432, lng: 17.9933321 },
    { lat: 49.0040671, lng: 17.9929489 },
    { lat: 49.003916, lng: 17.9926092 },
    { lat: 49.0031381, lng: 17.9919701 },
    { lat: 49.0028879, lng: 17.9917666 },
    { lat: 49.0024574, lng: 17.9914165 },
    { lat: 49.0019979, lng: 17.9921179 },
    { lat: 49.0016818, lng: 17.9927286 },
    { lat: 49.0016643, lng: 17.9927619 },
    { lat: 49.0015184, lng: 17.9930431 },
    { lat: 49.0014545, lng: 17.993146 },
    { lat: 49.0010641, lng: 17.9937765 },
    { lat: 49.0006645, lng: 17.9943504 },
    { lat: 48.9999906, lng: 17.9949235 },
    { lat: 48.9997551, lng: 17.9954854 },
    { lat: 48.9997366, lng: 17.9955318 },
    { lat: 48.9996673, lng: 17.9956965 },
    { lat: 48.9996593, lng: 17.9957167 },
    { lat: 48.9994301, lng: 17.9962105 },
    { lat: 48.9992849, lng: 17.9965236 },
    { lat: 48.9989415, lng: 17.997245 },
    { lat: 48.9984727, lng: 17.9982747 },
    { lat: 48.9982004, lng: 17.9987833 },
    { lat: 48.9978505, lng: 17.9994348 },
    { lat: 48.9974534, lng: 17.9999935 },
    { lat: 48.9973212, lng: 18.0001808 },
    { lat: 48.996663, lng: 18.0010191 },
    { lat: 48.9961752, lng: 18.0019363 },
    { lat: 48.9960295, lng: 18.0020884 },
    { lat: 48.9960138, lng: 18.0021081 },
    { lat: 48.9959373, lng: 18.0020567 },
    { lat: 48.9958451, lng: 18.0020426 },
    { lat: 48.9956497, lng: 18.0019046 },
    { lat: 48.9955761, lng: 18.0016145 },
    { lat: 48.9955646, lng: 18.0015913 },
    { lat: 48.9954765, lng: 18.0015695 },
    { lat: 48.9953525, lng: 18.0014767 },
    { lat: 48.995237, lng: 18.0013584 },
    { lat: 48.9951174, lng: 18.0012234 },
    { lat: 48.9950623, lng: 18.0011302 },
    { lat: 48.9949343, lng: 18.0012181 },
    { lat: 48.9948431, lng: 18.001224 },
    { lat: 48.9946461, lng: 18.0010388 },
    { lat: 48.9945481, lng: 18.0010827 },
    { lat: 48.9944659, lng: 18.0011395 },
    { lat: 48.994306, lng: 18.000918 },
    { lat: 48.9938265, lng: 18.0005196 },
    { lat: 48.9937777, lng: 18.0004583 },
    { lat: 48.9937504, lng: 18.0003405 },
    { lat: 48.9936153, lng: 18.000287 },
    { lat: 48.9935507, lng: 18.0002056 },
    { lat: 48.9935476, lng: 17.9999243 },
    { lat: 48.9934946, lng: 17.9997881 },
    { lat: 48.9934426, lng: 17.9996561 },
    { lat: 48.9934614, lng: 17.9995903 },
    { lat: 48.9934618, lng: 17.9994993 },
    { lat: 48.9934399, lng: 17.9995141 },
    { lat: 48.9928724, lng: 17.9997276 },
    { lat: 48.9924799, lng: 17.9998662 },
    { lat: 48.9921652, lng: 17.9999644 },
    { lat: 48.9920657, lng: 17.9999973 },
    { lat: 48.9918476, lng: 18.000072 },
    { lat: 48.9914338, lng: 18.0001743 },
    { lat: 48.9912495, lng: 18.0002242 },
    { lat: 48.9906939, lng: 18.0002559 },
    { lat: 48.9906668, lng: 18.000141 },
    { lat: 48.9903567, lng: 17.9988241 },
    { lat: 48.9900963, lng: 17.997762 },
    { lat: 48.9900667, lng: 17.9976406 },
    { lat: 48.9895526, lng: 17.9980912 },
    { lat: 48.9888847, lng: 17.99882 },
    { lat: 48.9884896, lng: 17.9991268 },
    { lat: 48.9877421, lng: 17.9997129 },
    { lat: 48.9875744, lng: 17.999818 },
    { lat: 48.9874852, lng: 17.999874 },
    { lat: 48.9874669, lng: 17.9998869 },
    { lat: 48.9873478, lng: 17.9999739 },
    { lat: 48.9867195, lng: 18.0004411 },
    { lat: 48.9863986, lng: 18.0005297 },
    { lat: 48.9863861, lng: 18.0015412 },
    { lat: 48.9854085, lng: 18.0021729 },
    { lat: 48.9845151, lng: 18.0017763 },
    { lat: 48.9839904, lng: 18.0015096 },
    { lat: 48.9833286, lng: 18.0014635 },
    { lat: 48.9830465, lng: 18.0015205 },
    { lat: 48.9825542, lng: 18.0013658 },
    { lat: 48.9822007, lng: 18.0013036 },
    { lat: 48.9818875, lng: 18.0014465 },
    { lat: 48.9814236, lng: 18.0015038 },
    { lat: 48.9810937, lng: 18.0017287 },
    { lat: 48.9808202, lng: 18.0017591 },
    { lat: 48.9801667, lng: 18.0017036 },
    { lat: 48.979705, lng: 18.0017578 },
    { lat: 48.9793186, lng: 18.0018075 },
    { lat: 48.9787766, lng: 18.0021086 },
    { lat: 48.9783541, lng: 18.0024676 },
    { lat: 48.9780031, lng: 18.0025645 },
    { lat: 48.977726, lng: 18.0026852 },
    { lat: 48.9777301, lng: 18.0027393 },
    { lat: 48.9767462, lng: 18.0027818 },
    { lat: 48.9762281, lng: 18.0027995 },
    { lat: 48.9756312, lng: 18.0028207 },
    { lat: 48.9750939, lng: 18.0029886 },
    { lat: 48.9747093, lng: 18.0031088 },
    { lat: 48.9743279, lng: 18.0032268 },
    { lat: 48.9737752, lng: 18.0034033 },
    { lat: 48.9730672, lng: 18.0036289 },
    { lat: 48.9723052, lng: 18.0038741 },
    { lat: 48.9717115, lng: 18.0040655 },
    { lat: 48.97111, lng: 18.0042557 },
    { lat: 48.9702859, lng: 18.0045233 },
    { lat: 48.9694703, lng: 18.0047772 },
    { lat: 48.9692701, lng: 18.0048479 },
    { lat: 48.9692238, lng: 18.0048639 },
    { lat: 48.969018, lng: 18.0049361 },
    { lat: 48.9685594, lng: 18.0049075 },
    { lat: 48.9682362, lng: 18.0049411 },
    { lat: 48.9681729, lng: 18.0043246 },
    { lat: 48.9681422, lng: 18.0036502 },
    { lat: 48.9680823, lng: 18.0028077 },
    { lat: 48.9680503, lng: 18.00232 },
    { lat: 48.9680102, lng: 18.0023297 },
    { lat: 48.9679633, lng: 18.0023415 },
    { lat: 48.96794, lng: 18.0023472 },
    { lat: 48.9675876, lng: 18.0023998 },
    { lat: 48.967534, lng: 18.0024078 },
    { lat: 48.9675278, lng: 18.0022668 },
    { lat: 48.9674734, lng: 18.0022425 },
    { lat: 48.9673267, lng: 18.0023027 },
    { lat: 48.966918, lng: 18.0022017 },
    { lat: 48.9668846, lng: 18.0019803 },
    { lat: 48.966778, lng: 18.0017595 },
    { lat: 48.9666962, lng: 18.0013669 },
    { lat: 48.966579, lng: 18.0012007 },
    { lat: 48.9666401, lng: 18.0008206 },
    { lat: 48.9666714, lng: 18.0004492 },
    { lat: 48.9667286, lng: 18.0003495 },
    { lat: 48.966789, lng: 18.0001294 },
    { lat: 48.966792, lng: 17.9999885 },
    { lat: 48.9667554, lng: 17.9998642 },
    { lat: 48.9666646, lng: 17.9996851 },
    { lat: 48.9667337, lng: 17.9992126 },
    { lat: 48.9666814, lng: 17.999167 },
    { lat: 48.9665496, lng: 17.9989787 },
    { lat: 48.9663315, lng: 17.9985522 },
    { lat: 48.9662455, lng: 17.9984173 },
    { lat: 48.9662051, lng: 17.9982457 },
    { lat: 48.966175, lng: 17.9978924 },
    { lat: 48.9658687, lng: 17.9978432 },
    { lat: 48.9655887, lng: 17.9978041 },
    { lat: 48.9654398, lng: 17.9978614 },
    { lat: 48.965346, lng: 17.9978788 },
    { lat: 48.9653191, lng: 17.9978437 },
    { lat: 48.9650622, lng: 17.9979261 },
    { lat: 48.9648302, lng: 17.9979957 },
    { lat: 48.964429, lng: 17.9980826 },
    { lat: 48.9641701, lng: 17.9980913 },
    { lat: 48.9638961, lng: 17.9980201 },
    { lat: 48.9635284, lng: 17.9981218 },
    { lat: 48.9632145, lng: 17.9982687 },
    { lat: 48.963172, lng: 17.9979537 },
    { lat: 48.9632839, lng: 17.9978588 },
    { lat: 48.9632266, lng: 17.9976162 },
    { lat: 48.9632212, lng: 17.997581 },
    { lat: 48.9634409, lng: 17.9971162 },
    { lat: 48.9633591, lng: 17.9969165 },
    { lat: 48.9634878, lng: 17.9964758 },
    { lat: 48.9634607, lng: 17.9960923 },
    { lat: 48.9630734, lng: 17.9959741 },
    { lat: 48.9628675, lng: 17.9959468 },
    { lat: 48.9625447, lng: 17.9957749 },
    { lat: 48.9623386, lng: 17.9952483 },
    { lat: 48.9622018, lng: 17.9950705 },
    { lat: 48.9621214, lng: 17.9948947 },
    { lat: 48.9621304, lng: 17.9947595 },
    { lat: 48.9621661, lng: 17.9944863 },
    { lat: 48.9619237, lng: 17.9943386 },
    { lat: 48.9618895, lng: 17.9943512 },
    { lat: 48.9614532, lng: 17.9945122 },
    { lat: 48.9610381, lng: 17.994725 },
    { lat: 48.9605801, lng: 17.9948268 },
    { lat: 48.9603271, lng: 17.9948789 },
    { lat: 48.960175, lng: 17.9949387 },
    { lat: 48.9598657, lng: 17.9950369 },
    { lat: 48.9592848, lng: 17.9953275 },
    { lat: 48.9591019, lng: 17.9950586 },
    { lat: 48.9589976, lng: 17.9946889 },
    { lat: 48.9589577, lng: 17.9945858 },
    { lat: 48.9588409, lng: 17.9942706 },
    { lat: 48.9589257, lng: 17.9942163 },
    { lat: 48.9587245, lng: 17.9936364 },
    { lat: 48.9584679, lng: 17.9936893 },
    { lat: 48.9584365, lng: 17.9935779 },
    { lat: 48.9583036, lng: 17.9930242 },
    { lat: 48.9582137, lng: 17.9926719 },
    { lat: 48.9581412, lng: 17.9923776 },
    { lat: 48.9579129, lng: 17.9915712 },
    { lat: 48.9577051, lng: 17.9917205 },
    { lat: 48.9575157, lng: 17.9916856 },
    { lat: 48.9573238, lng: 17.9913788 },
    { lat: 48.9572069, lng: 17.9910823 },
    { lat: 48.9571536, lng: 17.991096 },
    { lat: 48.9570092, lng: 17.9909268 },
    { lat: 48.9570142, lng: 17.9908025 },
    { lat: 48.9571408, lng: 17.990424 },
    { lat: 48.9572491, lng: 17.9902106 },
    { lat: 48.9572534, lng: 17.990112 },
    { lat: 48.9574126, lng: 17.9898285 },
    { lat: 48.9572619, lng: 17.989607 },
    { lat: 48.9571818, lng: 17.9897396 },
    { lat: 48.9570042, lng: 17.9896959 },
    { lat: 48.9567821, lng: 17.9893559 },
    { lat: 48.9566575, lng: 17.9889674 },
    { lat: 48.9565448, lng: 17.9885497 },
    { lat: 48.9565215, lng: 17.9884799 },
    { lat: 48.9558809, lng: 17.9885058 },
    { lat: 48.9551849, lng: 17.9886254 },
    { lat: 48.9549573, lng: 17.9887283 },
    { lat: 48.9547533, lng: 17.988927 },
    { lat: 48.9545752, lng: 17.9891782 },
    { lat: 48.9542508, lng: 17.9896641 },
    { lat: 48.95414, lng: 17.9900262 },
    { lat: 48.9540743, lng: 17.9901547 },
    { lat: 48.9537284, lng: 17.9905659 },
    { lat: 48.9533524, lng: 17.9902573 },
    { lat: 48.9533671, lng: 17.990162 },
    { lat: 48.9534739, lng: 17.9897531 },
    { lat: 48.9536782, lng: 17.9893916 },
    { lat: 48.9537966, lng: 17.9888544 },
    { lat: 48.9538176, lng: 17.9887887 },
    { lat: 48.9540007, lng: 17.9881816 },
    { lat: 48.9540657, lng: 17.9879853 },
    { lat: 48.9541509, lng: 17.9874569 },
    { lat: 48.9541882, lng: 17.9871552 },
    { lat: 48.95427, lng: 17.9871628 },
    { lat: 48.9545794, lng: 17.9872251 },
    { lat: 48.954632, lng: 17.9872136 },
    { lat: 48.9551924, lng: 17.9870554 },
    { lat: 48.9557483, lng: 17.9868539 },
    { lat: 48.9558528, lng: 17.9866931 },
    { lat: 48.9556911, lng: 17.9859265 },
    { lat: 48.9552948, lng: 17.9853118 },
    { lat: 48.9555701, lng: 17.9850035 },
    { lat: 48.9554936, lng: 17.9848732 },
    { lat: 48.9554728, lng: 17.9848462 },
    { lat: 48.9554836, lng: 17.9848002 },
    { lat: 48.9555124, lng: 17.9847151 },
    { lat: 48.955554, lng: 17.9845794 },
    { lat: 48.9556081, lng: 17.9843099 },
    { lat: 48.9555852, lng: 17.9840943 },
    { lat: 48.9556389, lng: 17.9839544 },
    { lat: 48.9556713, lng: 17.9837385 },
    { lat: 48.9556769, lng: 17.9836806 },
    { lat: 48.9556877, lng: 17.983556 },
    { lat: 48.9556796, lng: 17.9835546 },
    { lat: 48.9557851, lng: 17.98318 },
    { lat: 48.9557109, lng: 17.9830525 },
    { lat: 48.9556264, lng: 17.982883 },
    { lat: 48.9556227, lng: 17.9827449 },
    { lat: 48.9556472, lng: 17.9826985 },
    { lat: 48.9556732, lng: 17.9826425 },
    { lat: 48.9557018, lng: 17.9824978 },
    { lat: 48.9556573, lng: 17.9823182 },
    { lat: 48.9556355, lng: 17.982217 },
    { lat: 48.9556326, lng: 17.9821891 },
    { lat: 48.9556148, lng: 17.9821161 },
    { lat: 48.9555433, lng: 17.9819545 },
    { lat: 48.9555112, lng: 17.9819068 },
    { lat: 48.9554875, lng: 17.9817743 },
    { lat: 48.9554774, lng: 17.9814309 },
    { lat: 48.9555218, lng: 17.9806746 },
    { lat: 48.9555261, lng: 17.9805189 },
    { lat: 48.9555698, lng: 17.9797311 },
    { lat: 48.955538, lng: 17.9792341 },
    { lat: 48.9556028, lng: 17.9790503 },
    { lat: 48.9555284, lng: 17.978748 },
    { lat: 48.9548622, lng: 17.97878 },
    { lat: 48.9544504, lng: 17.978819 },
    { lat: 48.9540905, lng: 17.9782497 },
    { lat: 48.9541257, lng: 17.978196 },
    { lat: 48.9542497, lng: 17.9780024 },
    { lat: 48.9543139, lng: 17.9779042 },
    { lat: 48.9543603, lng: 17.9778333 },
    { lat: 48.9543986, lng: 17.977772 },
    { lat: 48.9544887, lng: 17.9776373 },
    { lat: 48.9545235, lng: 17.9775776 },
    { lat: 48.9545373, lng: 17.9775628 },
    { lat: 48.9545455, lng: 17.9775451 },
    { lat: 48.954563, lng: 17.9775169 },
    { lat: 48.9545825, lng: 17.9774873 },
    { lat: 48.9545992, lng: 17.9774613 },
    { lat: 48.9541865, lng: 17.9767469 },
    { lat: 48.9542369, lng: 17.9766628 },
    { lat: 48.9542794, lng: 17.9765981 },
    { lat: 48.9543237, lng: 17.9765274 },
    { lat: 48.9536831, lng: 17.9758499 },
    { lat: 48.9537209, lng: 17.9757611 },
    { lat: 48.9537408, lng: 17.9757171 },
    { lat: 48.9537697, lng: 17.9756496 },
    { lat: 48.953792, lng: 17.9755916 },
    { lat: 48.953797, lng: 17.9755805 },
    { lat: 48.9538268, lng: 17.9755122 },
    { lat: 48.9538667, lng: 17.9754237 },
    { lat: 48.9539398, lng: 17.9752482 },
    { lat: 48.9539959, lng: 17.9751242 },
    { lat: 48.954036, lng: 17.9750225 },
    { lat: 48.9538004, lng: 17.9748553 },
    { lat: 48.9535349, lng: 17.9744894 },
    { lat: 48.9534977, lng: 17.9743964 },
    { lat: 48.9533685, lng: 17.974311 },
    { lat: 48.9531484, lng: 17.9741561 },
    { lat: 48.9532528, lng: 17.9739953 },
    { lat: 48.9534455, lng: 17.9735499 },
    { lat: 48.9533352, lng: 17.9730816 },
    { lat: 48.9533235, lng: 17.9730351 },
    { lat: 48.9532247, lng: 17.9729843 },
    { lat: 48.9529843, lng: 17.9730835 },
    { lat: 48.9527154, lng: 17.9731357 },
    { lat: 48.9525567, lng: 17.973083 },
    { lat: 48.9523136, lng: 17.9730002 },
    { lat: 48.9522443, lng: 17.9728966 },
    { lat: 48.9522046, lng: 17.9725335 },
    { lat: 48.9521216, lng: 17.9720662 },
    { lat: 48.9520911, lng: 17.9715679 },
    { lat: 48.9521985, lng: 17.9712674 },
    { lat: 48.9522123, lng: 17.9712336 },
    { lat: 48.9520151, lng: 17.9709158 },
    { lat: 48.9518494, lng: 17.9708242 },
    { lat: 48.9515567, lng: 17.9707381 },
    { lat: 48.951279, lng: 17.9707226 },
    { lat: 48.9510114, lng: 17.9706244 },
    { lat: 48.9508838, lng: 17.9706986 },
    { lat: 48.9506615, lng: 17.970678 },
    { lat: 48.950312, lng: 17.9705914 },
    { lat: 48.9501479, lng: 17.9705083 },
    { lat: 48.9499361, lng: 17.9707627 },
    { lat: 48.94972, lng: 17.9707355 },
    { lat: 48.9496288, lng: 17.9708126 },
    { lat: 48.9495891, lng: 17.9709046 },
    { lat: 48.9494921, lng: 17.9710205 },
    { lat: 48.9494254, lng: 17.9710756 },
    { lat: 48.9493656, lng: 17.9711922 },
    { lat: 48.9491979, lng: 17.9712758 },
    { lat: 48.9490626, lng: 17.9715072 },
    { lat: 48.9488814, lng: 17.9716864 },
    { lat: 48.9488567, lng: 17.97186 },
    { lat: 48.9487278, lng: 17.9720833 },
    { lat: 48.9485459, lng: 17.9720844 },
    { lat: 48.9482709, lng: 17.9721453 },
    { lat: 48.9481786, lng: 17.9719953 },
    { lat: 48.9479994, lng: 17.9718097 },
    { lat: 48.9476444, lng: 17.9718022 },
    { lat: 48.9474414, lng: 17.9716884 },
    { lat: 48.9471196, lng: 17.9714788 },
    { lat: 48.9470069, lng: 17.9713643 },
    { lat: 48.9469337, lng: 17.9712741 },
    { lat: 48.9467638, lng: 17.971093 },
    { lat: 48.9466093, lng: 17.9710013 },
    { lat: 48.9464566, lng: 17.9708225 },
    { lat: 48.9463664, lng: 17.9708058 },
    { lat: 48.9462976, lng: 17.9708816 },
    { lat: 48.946279, lng: 17.970855 },
    { lat: 48.9461096, lng: 17.970587 },
    { lat: 48.9462834, lng: 17.969727 },
    { lat: 48.9463724, lng: 17.9692505 },
    { lat: 48.9469219, lng: 17.9692631 },
    { lat: 48.9468879, lng: 17.9682555 },
    { lat: 48.9468512, lng: 17.9680705 },
    { lat: 48.9468, lng: 17.9678366 },
    { lat: 48.9467543, lng: 17.9676163 },
    { lat: 48.94671, lng: 17.9674237 },
    { lat: 48.9466975, lng: 17.9674014 },
    { lat: 48.9466247, lng: 17.9672778 },
    { lat: 48.9464024, lng: 17.9668999 },
    { lat: 48.9467313, lng: 17.9666991 },
    { lat: 48.9472804, lng: 17.9660987 },
    { lat: 48.9472922, lng: 17.9660497 },
    { lat: 48.9474222, lng: 17.9662247 },
    { lat: 48.9476685, lng: 17.9665333 },
    { lat: 48.9480316, lng: 17.9669444 },
    { lat: 48.9479167, lng: 17.9660164 },
    { lat: 48.9477683, lng: 17.9657034 },
    { lat: 48.9477174, lng: 17.9655872 },
    { lat: 48.947561, lng: 17.9654226 },
    { lat: 48.9473272, lng: 17.9647339 },
    { lat: 48.9470603, lng: 17.9644418 },
    { lat: 48.9470471, lng: 17.9641032 },
    { lat: 48.9469529, lng: 17.9639416 },
    { lat: 48.9467995, lng: 17.9639439 },
    { lat: 48.9466722, lng: 17.9635322 },
    { lat: 48.9465686, lng: 17.9632131 },
    { lat: 48.9464882, lng: 17.9629581 },
    { lat: 48.9464247, lng: 17.9627642 },
    { lat: 48.9463876, lng: 17.9626131 },
    { lat: 48.9463671, lng: 17.9625351 },
    { lat: 48.9463376, lng: 17.9624163 },
    { lat: 48.9463302, lng: 17.9623883 },
    { lat: 48.9466459, lng: 17.9622869 },
    { lat: 48.9468502, lng: 17.9623067 },
    { lat: 48.9469524, lng: 17.9622992 },
    { lat: 48.9475844, lng: 17.9621636 },
    { lat: 48.9477954, lng: 17.9621053 },
    { lat: 48.9477926, lng: 17.962077 },
    { lat: 48.9478006, lng: 17.9620048 },
    { lat: 48.947808, lng: 17.9619786 },
    { lat: 48.9476296, lng: 17.9616694 },
    { lat: 48.9473959, lng: 17.9612906 },
    { lat: 48.9472103, lng: 17.9610528 },
    { lat: 48.946901, lng: 17.9607669 },
    { lat: 48.9468907, lng: 17.9608013 },
    { lat: 48.9468211, lng: 17.960771 },
    { lat: 48.9467439, lng: 17.9607428 },
    { lat: 48.9466789, lng: 17.9607143 },
    { lat: 48.9466142, lng: 17.960688 },
    { lat: 48.946554, lng: 17.9606621 },
    { lat: 48.9465021, lng: 17.9606444 },
    { lat: 48.9463912, lng: 17.9606021 },
    { lat: 48.9462817, lng: 17.9605615 },
    { lat: 48.9462333, lng: 17.9605465 },
    { lat: 48.9460891, lng: 17.9604926 },
    { lat: 48.9459943, lng: 17.9604568 },
    { lat: 48.9459074, lng: 17.9604198 },
    { lat: 48.9449291, lng: 17.9600596 },
    { lat: 48.9446717, lng: 17.9599634 },
    { lat: 48.9445641, lng: 17.9599192 },
    { lat: 48.9444634, lng: 17.9598773 },
    { lat: 48.9444682, lng: 17.9598465 },
    { lat: 48.9432528, lng: 17.9592415 },
    { lat: 48.9431366, lng: 17.9591081 },
    { lat: 48.9430208, lng: 17.9589261 },
    { lat: 48.9428823, lng: 17.9587971 },
    { lat: 48.9426617, lng: 17.9586546 },
    { lat: 48.9423521, lng: 17.9585544 },
    { lat: 48.9418641, lng: 17.9584143 },
    { lat: 48.9416795, lng: 17.9582877 },
    { lat: 48.9411315, lng: 17.9579778 },
    { lat: 48.9408932, lng: 17.957804 },
    { lat: 48.9408897, lng: 17.9577804 },
    { lat: 48.9409057, lng: 17.9572705 },
    { lat: 48.9409106, lng: 17.9571259 },
    { lat: 48.9409151, lng: 17.9569741 },
    { lat: 48.9397673, lng: 17.9560254 },
    { lat: 48.9396568, lng: 17.9557587 },
    { lat: 48.9395165, lng: 17.955412 },
    { lat: 48.9386987, lng: 17.955441 },
    { lat: 48.9385776, lng: 17.9558152 },
    { lat: 48.9384803, lng: 17.9561154 },
    { lat: 48.9384554, lng: 17.9561902 },
    { lat: 48.9384464, lng: 17.9562661 },
    { lat: 48.9384395, lng: 17.9563206 },
    { lat: 48.9383467, lng: 17.9567734 },
    { lat: 48.9382715, lng: 17.957235 },
    { lat: 48.9380842, lng: 17.9582641 },
    { lat: 48.938, lng: 17.9591832 },
    { lat: 48.937973, lng: 17.9599843 },
    { lat: 48.9382755, lng: 17.9602513 },
    { lat: 48.9388262, lng: 17.9607238 },
    { lat: 48.9389419, lng: 17.9608868 },
    { lat: 48.9391783, lng: 17.9612756 },
    { lat: 48.939615, lng: 17.9616916 },
    { lat: 48.9401804, lng: 17.9621844 },
    { lat: 48.9403053, lng: 17.9625393 },
    { lat: 48.9405816, lng: 17.9627844 },
    { lat: 48.9410563, lng: 17.9630039 },
    { lat: 48.9415799, lng: 17.9633452 },
    { lat: 48.9419118, lng: 17.9635382 },
    { lat: 48.9418257, lng: 17.9638527 },
    { lat: 48.941646, lng: 17.9642689 },
    { lat: 48.9416279, lng: 17.9643069 },
    { lat: 48.9411546, lng: 17.9653066 },
    { lat: 48.9410163, lng: 17.9657341 },
    { lat: 48.9409738, lng: 17.9659147 },
    { lat: 48.9408468, lng: 17.966341 },
    { lat: 48.9408788, lng: 17.9671685 },
    { lat: 48.9409187, lng: 17.9680499 },
    { lat: 48.9410958, lng: 17.9686382 },
    { lat: 48.9411687, lng: 17.9688598 },
    { lat: 48.9412758, lng: 17.9691622 },
    { lat: 48.9414879, lng: 17.969712 },
    { lat: 48.941621, lng: 17.9702263 },
    { lat: 48.9417482, lng: 17.9705798 },
    { lat: 48.9421251, lng: 17.9714759 },
    { lat: 48.9424723, lng: 17.9722467 },
    { lat: 48.9427274, lng: 17.9728603 },
    { lat: 48.9428447, lng: 17.9732945 },
    { lat: 48.9429237, lng: 17.9737139 },
    { lat: 48.942957, lng: 17.9739901 },
    { lat: 48.9431761, lng: 17.9745853 },
    { lat: 48.9432976, lng: 17.9751683 },
    { lat: 48.9433413, lng: 17.9754105 },
    { lat: 48.943455, lng: 17.976052 },
    { lat: 48.9435749, lng: 17.9762881 },
    { lat: 48.9440005, lng: 17.9768612 },
    { lat: 48.944598, lng: 17.9775732 },
    { lat: 48.9449779, lng: 17.9780233 },
    { lat: 48.9453347, lng: 17.978752 },
    { lat: 48.9457072, lng: 17.9793443 },
    { lat: 48.9460358, lng: 17.9798603 },
    { lat: 48.9464823, lng: 17.9803477 },
    { lat: 48.9468381, lng: 17.9809649 },
    { lat: 48.9472421, lng: 17.9815335 },
    { lat: 48.947657, lng: 17.9820978 },
    { lat: 48.9471916, lng: 17.9827993 },
    { lat: 48.9474251, lng: 17.9831955 },
    { lat: 48.9476814, lng: 17.9836395 },
    { lat: 48.9478799, lng: 17.9845511 },
    { lat: 48.9482004, lng: 17.9852558 },
    { lat: 48.9483866, lng: 17.985546 },
    { lat: 48.9487859, lng: 17.9859591 },
    { lat: 48.9498356, lng: 17.9868571 },
    { lat: 48.9499025, lng: 17.9868634 },
    { lat: 48.9505913, lng: 17.9869254 },
    { lat: 48.951296, lng: 17.9877573 },
    { lat: 48.9514325, lng: 17.98825 },
    { lat: 48.951314, lng: 17.9888817 },
    { lat: 48.9513003, lng: 17.9889342 },
    { lat: 48.9512847, lng: 17.9889941 },
    { lat: 48.9511483, lng: 17.9895715 },
    { lat: 48.9509076, lng: 17.9901404 },
    { lat: 48.9505058, lng: 17.9904813 },
    { lat: 48.9499665, lng: 17.9910966 },
    { lat: 48.949584, lng: 17.9910241 },
    { lat: 48.9492336, lng: 17.9909785 },
    { lat: 48.9490369, lng: 17.9910466 },
    { lat: 48.9488703, lng: 17.9917052 },
    { lat: 48.948672, lng: 17.9924934 },
    { lat: 48.9482743, lng: 17.9929637 },
    { lat: 48.9480771, lng: 17.9936764 },
    { lat: 48.9475667, lng: 17.9945894 },
    { lat: 48.9471266, lng: 17.995548 },
    { lat: 48.946931, lng: 17.9964773 },
    { lat: 48.9464948, lng: 17.9974254 },
    { lat: 48.9460655, lng: 17.998334 },
    { lat: 48.9456645, lng: 17.9991735 },
    { lat: 48.9452666, lng: 18.0000017 },
    { lat: 48.944763, lng: 18.0010296 },
    { lat: 48.9445729, lng: 18.0008138 },
    { lat: 48.9445546, lng: 18.000845 },
    { lat: 48.9440185, lng: 18.0017649 },
    { lat: 48.9435468, lng: 18.002588 },
    { lat: 48.9435191, lng: 18.0026356 },
    { lat: 48.9432403, lng: 18.0031338 },
    { lat: 48.9424465, lng: 18.004503 },
    { lat: 48.9424027, lng: 18.0044906 },
    { lat: 48.9420753, lng: 18.0052908 },
    { lat: 48.9425918, lng: 18.0058203 },
    { lat: 48.9428025, lng: 18.0060645 },
    { lat: 48.9431635, lng: 18.0064847 },
    { lat: 48.9429729, lng: 18.0068291 },
    { lat: 48.9427372, lng: 18.0074646 },
    { lat: 48.9425459, lng: 18.0082963 },
    { lat: 48.9423831, lng: 18.0091538 },
    { lat: 48.9422364, lng: 18.0099029 },
    { lat: 48.9420272, lng: 18.0107395 },
    { lat: 48.9417796, lng: 18.0114395 },
    { lat: 48.9416785, lng: 18.0117139 },
    { lat: 48.9415087, lng: 18.0122805 },
    { lat: 48.9411943, lng: 18.013094 },
    { lat: 48.9411089, lng: 18.013294 },
    { lat: 48.9411216, lng: 18.0135296 },
    { lat: 48.9410044, lng: 18.0138923 },
    { lat: 48.9408123, lng: 18.0140615 },
    { lat: 48.9406756, lng: 18.0142536 },
    { lat: 48.9405011, lng: 18.0145268 },
    { lat: 48.9404103, lng: 18.0148856 },
    { lat: 48.940243, lng: 18.0155505 },
    { lat: 48.9401623, lng: 18.015867 },
    { lat: 48.940481, lng: 18.0161627 },
    { lat: 48.940587, lng: 18.0173492 },
    { lat: 48.9405985, lng: 18.0181953 },
    { lat: 48.9403427, lng: 18.0182203 },
    { lat: 48.9400711, lng: 18.0180948 },
    { lat: 48.9399423, lng: 18.0183611 },
    { lat: 48.9399861, lng: 18.0187037 },
    { lat: 48.9398731, lng: 18.019352 },
    { lat: 48.9395629, lng: 18.0194134 },
    { lat: 48.9393691, lng: 18.0194559 },
    { lat: 48.9391774, lng: 18.0195745 },
    { lat: 48.9385488, lng: 18.01971 },
    { lat: 48.9382533, lng: 18.0199216 },
    { lat: 48.937735, lng: 18.0204177 },
    { lat: 48.9377049, lng: 18.0204458 },
    { lat: 48.9374568, lng: 18.0210233 },
    { lat: 48.9369939, lng: 18.0220557 },
    { lat: 48.9372204, lng: 18.0223369 },
    { lat: 48.9372927, lng: 18.0224915 },
    { lat: 48.9366487, lng: 18.0239753 },
    { lat: 48.9366207, lng: 18.0240379 },
    { lat: 48.9373165, lng: 18.0248146 },
    { lat: 48.9382175, lng: 18.0257074 },
    { lat: 48.9381447, lng: 18.0257919 },
    { lat: 48.937688, lng: 18.0254546 },
    { lat: 48.9369591, lng: 18.0264558 },
    { lat: 48.9372013, lng: 18.0267945 },
    { lat: 48.9373102, lng: 18.0271093 },
    { lat: 48.9375199, lng: 18.0273735 },
    { lat: 48.9375629, lng: 18.0274545 },
    { lat: 48.9378026, lng: 18.0277506 },
    { lat: 48.9380041, lng: 18.0278741 },
    { lat: 48.9379948, lng: 18.0282365 },
    { lat: 48.9381653, lng: 18.0288104 },
    { lat: 48.9382717, lng: 18.0293179 },
    { lat: 48.9384198, lng: 18.0299757 },
    { lat: 48.9384753, lng: 18.0304 },
    { lat: 48.9384694, lng: 18.0305491 },
    { lat: 48.9384883, lng: 18.0307752 },
    { lat: 48.9384615, lng: 18.030781 },
    { lat: 48.938882, lng: 18.0319615 },
    { lat: 48.9390543, lng: 18.0325711 },
    { lat: 48.9391385, lng: 18.0327575 },
    { lat: 48.9392647, lng: 18.0330413 },
    { lat: 48.9394724, lng: 18.0335991 },
    { lat: 48.9398324, lng: 18.0340232 },
    { lat: 48.9401908, lng: 18.0345925 },
    { lat: 48.9405536, lng: 18.0349079 },
    { lat: 48.9409168, lng: 18.0353086 },
    { lat: 48.9409564, lng: 18.0356572 },
    { lat: 48.9411378, lng: 18.0358434 },
    { lat: 48.9413471, lng: 18.0362936 },
    { lat: 48.9413567, lng: 18.0367282 },
    { lat: 48.9413639, lng: 18.0369071 },
    { lat: 48.9413818, lng: 18.0373861 },
    { lat: 48.9415179, lng: 18.0379159 },
    { lat: 48.9416103, lng: 18.0384132 },
    { lat: 48.9417035, lng: 18.0389416 },
    { lat: 48.9416712, lng: 18.0392795 },
    { lat: 48.9416678, lng: 18.039317 },
    { lat: 48.9412842, lng: 18.039511 },
    { lat: 48.9408952, lng: 18.0397862 },
    { lat: 48.9404315, lng: 18.0399433 },
    { lat: 48.9400837, lng: 18.040131 },
    { lat: 48.9400931, lng: 18.0401577 },
    { lat: 48.9396941, lng: 18.0406489 },
    { lat: 48.939704, lng: 18.0406612 },
    { lat: 48.9397344, lng: 18.0407185 },
    { lat: 48.9397696, lng: 18.0407784 },
    { lat: 48.9397731, lng: 18.0407826 },
    { lat: 48.9398114, lng: 18.0408585 },
    { lat: 48.9398255, lng: 18.040885 },
    { lat: 48.939886, lng: 18.0409935 },
    { lat: 48.9399111, lng: 18.0410382 },
    { lat: 48.9399427, lng: 18.0410928 },
    { lat: 48.9399488, lng: 18.0411045 },
    { lat: 48.9399729, lng: 18.0411479 },
    { lat: 48.9400041, lng: 18.0412015 },
    { lat: 48.9400424, lng: 18.0412728 },
    { lat: 48.9400869, lng: 18.0413594 },
    { lat: 48.9401134, lng: 18.0414079 },
    { lat: 48.9401186, lng: 18.0414194 },
    { lat: 48.940196, lng: 18.0415658 },
    { lat: 48.9402202, lng: 18.041611 },
    { lat: 48.9402469, lng: 18.0416631 },
    { lat: 48.9402713, lng: 18.0417129 },
    { lat: 48.9402739, lng: 18.0417201 },
    { lat: 48.9402964, lng: 18.0417576 },
    { lat: 48.9403262, lng: 18.0418211 },
    { lat: 48.9403806, lng: 18.0419229 },
    { lat: 48.9401918, lng: 18.0421275 },
    { lat: 48.9402273, lng: 18.0422168 },
    { lat: 48.9402355, lng: 18.0422397 },
    { lat: 48.9402726, lng: 18.042332 },
    { lat: 48.9403259, lng: 18.0424547 },
    { lat: 48.9399516, lng: 18.042766 },
    { lat: 48.9394574, lng: 18.0431946 },
    { lat: 48.9397459, lng: 18.0439759 },
    { lat: 48.939914, lng: 18.04436 },
    { lat: 48.9404048, lng: 18.0452037 },
    { lat: 48.9408786, lng: 18.0459719 },
    { lat: 48.941313, lng: 18.0466819 },
    { lat: 48.9412616, lng: 18.04677 },
    { lat: 48.9412413, lng: 18.0468065 },
    { lat: 48.9409709, lng: 18.0472978 },
    { lat: 48.9410403, lng: 18.0474202 },
    { lat: 48.9408033, lng: 18.047827 },
    { lat: 48.9408257, lng: 18.0478786 },
    { lat: 48.9408494, lng: 18.0479195 },
    { lat: 48.9408583, lng: 18.0479338 },
    { lat: 48.9408783, lng: 18.0479684 },
    { lat: 48.9408961, lng: 18.0480033 },
    { lat: 48.9409114, lng: 18.0480332 },
    { lat: 48.9409264, lng: 18.0480595 },
    { lat: 48.9409605, lng: 18.0481214 },
    { lat: 48.9405976, lng: 18.0483969 },
    { lat: 48.940327, lng: 18.0486348 },
    { lat: 48.9401863, lng: 18.0487967 },
    { lat: 48.9402195, lng: 18.0488586 },
    { lat: 48.9402754, lng: 18.0489656 },
    { lat: 48.9397881, lng: 18.049395 },
    { lat: 48.9396845, lng: 18.0491886 },
    { lat: 48.9395942, lng: 18.0490116 },
    { lat: 48.9395667, lng: 18.0489489 },
    { lat: 48.9392533, lng: 18.049417 },
    { lat: 48.9391506, lng: 18.0493802 },
    { lat: 48.9391004, lng: 18.0494265 },
    { lat: 48.9386221, lng: 18.0498726 },
    { lat: 48.9387742, lng: 18.0499659 },
    { lat: 48.938856, lng: 18.0500511 },
    { lat: 48.9390601, lng: 18.0502615 },
    { lat: 48.9390748, lng: 18.0502822 },
    { lat: 48.9391124, lng: 18.0503263 },
    { lat: 48.9391234, lng: 18.0503413 },
    { lat: 48.9391334, lng: 18.0503532 },
    { lat: 48.9392302, lng: 18.050468 },
    { lat: 48.939321, lng: 18.0505929 },
    { lat: 48.9394107, lng: 18.0507563 },
    { lat: 48.9395456, lng: 18.0509757 },
    { lat: 48.9395762, lng: 18.0510377 },
    { lat: 48.9396336, lng: 18.0511498 },
    { lat: 48.9397167, lng: 18.0513364 },
    { lat: 48.9397925, lng: 18.0515488 },
    { lat: 48.939906, lng: 18.0519079 },
    { lat: 48.9399486, lng: 18.052059 },
    { lat: 48.9399899, lng: 18.0521665 },
    { lat: 48.9400167, lng: 18.0522204 },
    { lat: 48.9400584, lng: 18.0522944 },
    { lat: 48.9400969, lng: 18.0523732 },
    { lat: 48.9401527, lng: 18.0524795 },
    { lat: 48.9402175, lng: 18.0525842 },
    { lat: 48.9402799, lng: 18.0527248 },
    { lat: 48.940383, lng: 18.0528458 },
    { lat: 48.9404668, lng: 18.053065 },
    { lat: 48.9405786, lng: 18.0532381 },
    { lat: 48.9406008, lng: 18.0532541 },
    { lat: 48.9405819, lng: 18.0533715 },
    { lat: 48.9406428, lng: 18.0534495 },
    { lat: 48.9407408, lng: 18.0536376 },
    { lat: 48.9407738, lng: 18.0537007 },
    { lat: 48.9407672, lng: 18.0537619 },
    { lat: 48.9408059, lng: 18.053864 },
    { lat: 48.9409174, lng: 18.053919 },
    { lat: 48.940981, lng: 18.0539635 },
    { lat: 48.9410206, lng: 18.0540619 },
    { lat: 48.9410778, lng: 18.0541501 },
    { lat: 48.941146, lng: 18.0542163 },
    { lat: 48.9411839, lng: 18.0543066 },
    { lat: 48.9413242, lng: 18.0545828 },
    { lat: 48.9413468, lng: 18.0546408 },
    { lat: 48.9413859, lng: 18.0546714 },
    { lat: 48.9414683, lng: 18.0547296 },
    { lat: 48.9416507, lng: 18.0547797 },
    { lat: 48.9416993, lng: 18.0548004 },
    { lat: 48.9417188, lng: 18.0548642 },
    { lat: 48.941728, lng: 18.0549243 },
    { lat: 48.9418387, lng: 18.055216 },
    { lat: 48.9419583, lng: 18.0553711 },
    { lat: 48.9421434, lng: 18.0555809 },
    { lat: 48.9422402, lng: 18.0557111 },
    { lat: 48.9423096, lng: 18.0557826 },
    { lat: 48.9423608, lng: 18.0558241 },
    { lat: 48.9423891, lng: 18.0558476 },
    { lat: 48.9424116, lng: 18.0558647 },
    { lat: 48.9424176, lng: 18.05587 },
    { lat: 48.9424499, lng: 18.0559047 },
    { lat: 48.9424706, lng: 18.0559271 },
    { lat: 48.9424776, lng: 18.0559337 },
    { lat: 48.9426263, lng: 18.0561592 },
    { lat: 48.9427361, lng: 18.0562406 },
    { lat: 48.9429249, lng: 18.0564036 },
    { lat: 48.9430629, lng: 18.0565796 },
    { lat: 48.9432649, lng: 18.056734 },
    { lat: 48.9433685, lng: 18.056883 },
    { lat: 48.9436661, lng: 18.0570508 },
    { lat: 48.9439786, lng: 18.0573795 },
    { lat: 48.9439634, lng: 18.0573913 },
    { lat: 48.9446573, lng: 18.0578696 },
    { lat: 48.9449251, lng: 18.0581261 },
    { lat: 48.9450096, lng: 18.0581995 },
    { lat: 48.9452626, lng: 18.0584537 },
    { lat: 48.9454808, lng: 18.0585578 },
    { lat: 48.9457142, lng: 18.0587683 },
    { lat: 48.9460889, lng: 18.0591896 },
    { lat: 48.946324, lng: 18.0593888 },
    { lat: 48.9464419, lng: 18.0596131 },
    { lat: 48.9467643, lng: 18.0600669 },
    { lat: 48.947006, lng: 18.0602451 },
    { lat: 48.9471607, lng: 18.0606141 },
    { lat: 48.9472149, lng: 18.0608664 },
    { lat: 48.9472317, lng: 18.0610193 },
    { lat: 48.9472849, lng: 18.0610961 },
    { lat: 48.94752, lng: 18.0612795 },
    { lat: 48.9481072, lng: 18.0620757 },
    { lat: 48.9487009, lng: 18.0628679 },
    { lat: 48.949066, lng: 18.0634377 },
    { lat: 48.949443, lng: 18.0633815 },
    { lat: 48.9496972, lng: 18.0632302 },
    { lat: 48.94996, lng: 18.0630749 },
    { lat: 48.9504801, lng: 18.062724 },
    { lat: 48.9510641, lng: 18.062524 },
    { lat: 48.9514201, lng: 18.0626469 },
    { lat: 48.9524294, lng: 18.0615328 },
    { lat: 48.9529416, lng: 18.0609298 },
    { lat: 48.9534016, lng: 18.0603432 },
    { lat: 48.9536199, lng: 18.0601213 },
    { lat: 48.954049, lng: 18.0596835 },
    { lat: 48.9540817, lng: 18.0596439 },
    { lat: 48.9544907, lng: 18.0591489 },
    { lat: 48.9552107, lng: 18.0584919 },
    { lat: 48.955437, lng: 18.0582887 },
    { lat: 48.9556795, lng: 18.0580197 },
    { lat: 48.9561326, lng: 18.0573733 },
    { lat: 48.9561692, lng: 18.05734 },
    { lat: 48.9562923, lng: 18.0572282 },
    { lat: 48.9563621, lng: 18.0571645 },
    { lat: 48.9564155, lng: 18.0571347 },
    { lat: 48.9565282, lng: 18.0570725 },
    { lat: 48.9566213, lng: 18.0570219 },
    { lat: 48.9567207, lng: 18.0569668 },
    { lat: 48.9568179, lng: 18.0569102 },
    { lat: 48.9576247, lng: 18.056331 },
    { lat: 48.9581822, lng: 18.0558686 },
    { lat: 48.958523, lng: 18.0556575 },
    { lat: 48.9588771, lng: 18.0554969 },
    { lat: 48.9596474, lng: 18.0561059 },
    { lat: 48.9601316, lng: 18.0564547 },
    { lat: 48.9604237, lng: 18.0567589 },
    { lat: 48.9610153, lng: 18.0571503 },
    { lat: 48.9618001, lng: 18.0573304 },
    { lat: 48.9620826, lng: 18.0573573 },
    { lat: 48.9627169, lng: 18.0573777 },
    { lat: 48.9633791, lng: 18.0574302 },
    { lat: 48.9635481, lng: 18.0573679 },
    { lat: 48.96421, lng: 18.0574363 },
    { lat: 48.9649034, lng: 18.0575012 },
    { lat: 48.9654655, lng: 18.0574455 },
    { lat: 48.96598, lng: 18.0573861 },
    { lat: 48.9659945, lng: 18.0574409 },
    { lat: 48.9661041, lng: 18.0573859 },
    { lat: 48.9661819, lng: 18.0574415 },
    { lat: 48.9662578, lng: 18.0573893 },
    { lat: 48.9663108, lng: 18.0573085 },
    { lat: 48.9663858, lng: 18.0573587 },
    { lat: 48.9664143, lng: 18.0572105 },
    { lat: 48.9664997, lng: 18.0571472 },
    { lat: 48.9666383, lng: 18.0570053 },
    { lat: 48.9666405, lng: 18.0567363 },
    { lat: 48.966677, lng: 18.056531 },
    { lat: 48.9666592, lng: 18.0564163 },
    { lat: 48.9666663, lng: 18.0562508 },
    { lat: 48.9666667, lng: 18.0560069 },
    { lat: 48.9668102, lng: 18.0559135 },
    { lat: 48.9668488, lng: 18.0558203 },
    { lat: 48.9669518, lng: 18.0557457 },
    { lat: 48.9670574, lng: 18.0555867 },
    { lat: 48.9671324, lng: 18.0555755 },
    { lat: 48.9671791, lng: 18.0556016 },
    { lat: 48.9673373, lng: 18.0553906 },
    { lat: 48.96748, lng: 18.0554209 },
    { lat: 48.9675764, lng: 18.0551769 },
    { lat: 48.9675463, lng: 18.0550107 },
    { lat: 48.9675848, lng: 18.0548766 },
    { lat: 48.967567, lng: 18.0548229 },
    { lat: 48.9675845, lng: 18.0547775 },
    { lat: 48.967714, lng: 18.0546756 },
    { lat: 48.9677928, lng: 18.0544002 },
    { lat: 48.9677698, lng: 18.0542783 },
    { lat: 48.9678395, lng: 18.0541001 },
    { lat: 48.9679883, lng: 18.0538656 },
    { lat: 48.968032, lng: 18.053688 },
    { lat: 48.968147, lng: 18.0536071 },
    { lat: 48.9681581, lng: 18.0534497 },
    { lat: 48.9681786, lng: 18.0534329 },
    { lat: 48.9682975, lng: 18.0535559 },
    { lat: 48.9683965, lng: 18.0533967 },
    { lat: 48.9684914, lng: 18.0532445 },
    { lat: 48.9685083, lng: 18.0531463 },
    { lat: 48.9685897, lng: 18.0531303 },
    { lat: 48.9686803, lng: 18.0531921 },
    { lat: 48.9688799, lng: 18.0530172 },
    { lat: 48.9688698, lng: 18.052923 },
    { lat: 48.9690108, lng: 18.0527854 },
    { lat: 48.9691076, lng: 18.0525687 },
    { lat: 48.9692297, lng: 18.0523798 },
    { lat: 48.9693285, lng: 18.0522949 },
    { lat: 48.9694095, lng: 18.0520207 },
    { lat: 48.9696335, lng: 18.0519136 },
    { lat: 48.969798, lng: 18.0518914 },
    { lat: 48.9698351, lng: 18.0517341 },
    { lat: 48.9698866, lng: 18.0516872 },
    { lat: 48.9701317, lng: 18.0516337 },
    { lat: 48.9702484, lng: 18.0515263 },
    { lat: 48.9703352, lng: 18.0515627 },
    { lat: 48.9704948, lng: 18.0515498 },
    { lat: 48.9706514, lng: 18.0516795 },
    { lat: 48.9708394, lng: 18.0517113 },
    { lat: 48.9710135, lng: 18.0515631 },
    { lat: 48.971099, lng: 18.051424 },
    { lat: 48.9712218, lng: 18.0514775 },
    { lat: 48.9713237, lng: 18.0514242 },
    { lat: 48.9714434, lng: 18.0513283 },
    { lat: 48.9715322, lng: 18.0512093 },
    { lat: 48.9716572, lng: 18.0512438 },
    { lat: 48.9718187, lng: 18.0510905 },
    { lat: 48.9719119, lng: 18.0509443 },
    { lat: 48.9720854, lng: 18.0509805 },
    { lat: 48.9721549, lng: 18.0508946 },
    { lat: 48.9720934, lng: 18.0507333 },
    { lat: 48.9722549, lng: 18.0506188 },
    { lat: 48.9724545, lng: 18.0503839 },
    { lat: 48.9725703, lng: 18.0503183 },
    { lat: 48.9727559, lng: 18.0502297 },
    { lat: 48.9727829, lng: 18.050036 },
    { lat: 48.9728588, lng: 18.0499629 },
    { lat: 48.9730084, lng: 18.0499739 },
    { lat: 48.9730223, lng: 18.0498833 },
    { lat: 48.9731026, lng: 18.049732 },
    { lat: 48.9733128, lng: 18.0497201 },
    { lat: 48.9732943, lng: 18.049557 },
    { lat: 48.9734007, lng: 18.0494277 },
    { lat: 48.9735326, lng: 18.0494056 },
    { lat: 48.9734603, lng: 18.0491926 },
    { lat: 48.9735526, lng: 18.0491291 },
    { lat: 48.973595, lng: 18.0490261 },
    { lat: 48.973661, lng: 18.0490143 },
    { lat: 48.9736938, lng: 18.0489596 },
    { lat: 48.9739411, lng: 18.0485389 },
    { lat: 48.9740376, lng: 18.0482769 },
    { lat: 48.9741922, lng: 18.0476615 },
    { lat: 48.9742435, lng: 18.0470912 },
    { lat: 48.9742327, lng: 18.0469066 },
    { lat: 48.9742879, lng: 18.0467518 },
    { lat: 48.9743604, lng: 18.0465229 },
    { lat: 48.9743688, lng: 18.0462631 },
    { lat: 48.974502, lng: 18.0459164 },
    { lat: 48.9746379, lng: 18.0455966 },
    { lat: 48.9747001, lng: 18.0453303 },
    { lat: 48.9748719, lng: 18.0451587 },
    { lat: 48.9749518, lng: 18.0449076 },
    { lat: 48.9751643, lng: 18.0447003 },
    { lat: 48.9752169, lng: 18.0444791 },
    { lat: 48.9753128, lng: 18.0445577 },
    { lat: 48.9755942, lng: 18.044275 },
    { lat: 48.9756657, lng: 18.0440502 },
    { lat: 48.9758724, lng: 18.0440546 },
    { lat: 48.976034, lng: 18.0438624 },
    { lat: 48.9762279, lng: 18.0435513 },
    { lat: 48.9762886, lng: 18.0434533 },
    { lat: 48.9763771, lng: 18.0434579 },
    { lat: 48.9766501, lng: 18.0431691 },
    { lat: 48.9769544, lng: 18.0429131 },
    { lat: 48.977099, lng: 18.0427775 },
    { lat: 48.9771666, lng: 18.0427982 },
    { lat: 48.9772837, lng: 18.0426362 },
    { lat: 48.9774242, lng: 18.0426649 },
    { lat: 48.9777554, lng: 18.042466 },
    { lat: 48.9779505, lng: 18.0423642 },
    { lat: 48.9781439, lng: 18.0422389 },
    { lat: 48.9784377, lng: 18.0420712 },
    { lat: 48.9787903, lng: 18.0417315 },
    { lat: 48.978928, lng: 18.0415379 },
    { lat: 48.9789503, lng: 18.0412859 },
    { lat: 48.979149, lng: 18.0407858 },
    { lat: 48.979222, lng: 18.0407411 },
    { lat: 48.9793308, lng: 18.0406926 },
    { lat: 48.979397, lng: 18.0404897 },
    { lat: 48.9793973, lng: 18.0402651 },
    { lat: 48.9795776, lng: 18.0398749 },
    { lat: 48.9796323, lng: 18.0397313 },
    { lat: 48.9797144, lng: 18.039707 },
    { lat: 48.9800247, lng: 18.0394541 },
    { lat: 48.9802125, lng: 18.039345 },
    { lat: 48.9803327, lng: 18.0390876 },
    { lat: 48.9803787, lng: 18.0387149 },
    { lat: 48.9804984, lng: 18.0382191 },
    { lat: 48.9806509, lng: 18.0379128 },
    { lat: 48.9810263, lng: 18.0369217 },
    { lat: 48.9810968, lng: 18.0369139 },
    { lat: 48.9811412, lng: 18.0369004 },
    { lat: 48.9817118, lng: 18.0367193 },
    { lat: 48.982341, lng: 18.0364595 },
    { lat: 48.9828001, lng: 18.0365117 },
    { lat: 48.9828362, lng: 18.0365158 },
    { lat: 48.9830169, lng: 18.0365359 },
    { lat: 48.9838848, lng: 18.0367292 },
    { lat: 48.9845466, lng: 18.0368315 },
    { lat: 48.9850931, lng: 18.0367417 },
    { lat: 48.9858251, lng: 18.036638 },
    { lat: 48.9864108, lng: 18.0368461 },
    { lat: 48.9873062, lng: 18.0371454 },
    { lat: 48.988308, lng: 18.0361736 },
    { lat: 48.9889317, lng: 18.0357438 },
    { lat: 48.9896211, lng: 18.0355516 },
    { lat: 48.9896956, lng: 18.0355308 },
    { lat: 48.9902093, lng: 18.0346476 },
    { lat: 48.9907694, lng: 18.0337305 },
    { lat: 48.9914225, lng: 18.0326279 },
    { lat: 48.9915246, lng: 18.0322356 },
    { lat: 48.9916225, lng: 18.031845 },
    { lat: 48.9916573, lng: 18.0317088 },
    { lat: 48.9916986, lng: 18.0315458 },
    { lat: 48.9918183, lng: 18.0309506 },
    { lat: 48.9919586, lng: 18.0302455 },
    { lat: 48.9921044, lng: 18.0291516 },
    { lat: 48.9922721, lng: 18.0276252 },
    { lat: 48.9926646, lng: 18.0262574 },
    { lat: 48.9930318, lng: 18.0250719 },
    { lat: 48.9939015, lng: 18.0244307 },
    { lat: 48.9946629, lng: 18.0242113 },
    { lat: 48.9955299, lng: 18.0238691 },
    { lat: 48.9960809, lng: 18.0231834 },
    { lat: 48.9965504, lng: 18.0216468 },
    { lat: 48.9967447, lng: 18.0209569 },
    { lat: 48.9967706, lng: 18.0208618 },
    { lat: 48.996849, lng: 18.020583 },
    { lat: 48.9971684, lng: 18.0192576 },
    { lat: 48.9975845, lng: 18.0181365 },
    { lat: 48.9976884, lng: 18.017851 },
    { lat: 48.9981886, lng: 18.018218 },
    { lat: 48.9986628, lng: 18.01893 },
    { lat: 48.9992597, lng: 18.0197763 },
    { lat: 48.9995575, lng: 18.0202147 },
    { lat: 48.9999045, lng: 18.021243 },
    { lat: 49.0001716, lng: 18.0220418 },
    { lat: 49.0002865, lng: 18.0221746 },
    { lat: 49.000545, lng: 18.0222711 },
    { lat: 49.0010119, lng: 18.0223034 },
    { lat: 49.0014989, lng: 18.022853 },
    { lat: 49.0015996, lng: 18.0229325 },
    { lat: 49.0018021, lng: 18.0232475 },
    { lat: 49.0020456, lng: 18.0234356 },
  ],
  Drietoma: [
    { lat: 48.9171842, lng: 17.8982905 },
    { lat: 48.9170018, lng: 17.8984048 },
    { lat: 48.9167168, lng: 17.8985837 },
    { lat: 48.9166004, lng: 17.8986572 },
    { lat: 48.916461, lng: 17.8987238 },
    { lat: 48.9163996, lng: 17.8987535 },
    { lat: 48.9163486, lng: 17.8988255 },
    { lat: 48.9162759, lng: 17.8989299 },
    { lat: 48.9162227, lng: 17.8990409 },
    { lat: 48.9160438, lng: 17.8995422 },
    { lat: 48.9158302, lng: 17.8997974 },
    { lat: 48.9157668, lng: 17.8998729 },
    { lat: 48.9157079, lng: 17.8999431 },
    { lat: 48.9153357, lng: 17.9002144 },
    { lat: 48.9152439, lng: 17.9002813 },
    { lat: 48.9149885, lng: 17.9006922 },
    { lat: 48.9149792, lng: 17.9007072 },
    { lat: 48.9149349, lng: 17.9007775 },
    { lat: 48.9148795, lng: 17.9008311 },
    { lat: 48.9147129, lng: 17.9009915 },
    { lat: 48.9143664, lng: 17.9013863 },
    { lat: 48.9141529, lng: 17.9015895 },
    { lat: 48.9140993, lng: 17.9016395 },
    { lat: 48.9140378, lng: 17.9016663 },
    { lat: 48.9137307, lng: 17.9017987 },
    { lat: 48.9131606, lng: 17.9019287 },
    { lat: 48.9128756, lng: 17.9019956 },
    { lat: 48.9127566, lng: 17.9020235 },
    { lat: 48.9127065, lng: 17.9020354 },
    { lat: 48.9126498, lng: 17.9020131 },
    { lat: 48.9124812, lng: 17.9019466 },
    { lat: 48.9124175, lng: 17.9019217 },
    { lat: 48.9120876, lng: 17.9018997 },
    { lat: 48.911689, lng: 17.9018951 },
    { lat: 48.9113922, lng: 17.9018204 },
    { lat: 48.9113017, lng: 17.9017978 },
    { lat: 48.9112281, lng: 17.9018334 },
    { lat: 48.910663, lng: 17.9021047 },
    { lat: 48.9103181, lng: 17.9024677 },
    { lat: 48.9095178, lng: 17.9033355 },
    { lat: 48.909415, lng: 17.9034473 },
    { lat: 48.9093044, lng: 17.9035246 },
    { lat: 48.9088798, lng: 17.9038232 },
    { lat: 48.9087084, lng: 17.9039397 },
    { lat: 48.9084465, lng: 17.9035592 },
    { lat: 48.9081916, lng: 17.9032441 },
    { lat: 48.9081806, lng: 17.9032313 },
    { lat: 48.908082, lng: 17.9031286 },
    { lat: 48.9080178, lng: 17.9032659 },
    { lat: 48.9078368, lng: 17.9036522 },
    { lat: 48.9075799, lng: 17.9039783 },
    { lat: 48.9071298, lng: 17.9046079 },
    { lat: 48.9065105, lng: 17.9050496 },
    { lat: 48.9064803, lng: 17.9051101 },
    { lat: 48.9064553, lng: 17.9051613 },
    { lat: 48.9061206, lng: 17.9061331 },
    { lat: 48.9059003, lng: 17.9066579 },
    { lat: 48.9058193, lng: 17.9068502 },
    { lat: 48.9057819, lng: 17.9070549 },
    { lat: 48.905681, lng: 17.9076131 },
    { lat: 48.9055862, lng: 17.9082734 },
    { lat: 48.9053553, lng: 17.9088858 },
    { lat: 48.9051679, lng: 17.9090357 },
    { lat: 48.9049081, lng: 17.9092437 },
    { lat: 48.9047833, lng: 17.9093431 },
    { lat: 48.9047196, lng: 17.9094484 },
    { lat: 48.9046825, lng: 17.9095091 },
    { lat: 48.90433, lng: 17.9100894 },
    { lat: 48.9039369, lng: 17.9105708 },
    { lat: 48.9037421, lng: 17.9108646 },
    { lat: 48.9036187, lng: 17.9112138 },
    { lat: 48.9035847, lng: 17.9113451 },
    { lat: 48.9035516, lng: 17.911509 },
    { lat: 48.9035284, lng: 17.9116503 },
    { lat: 48.9035138, lng: 17.9117661 },
    { lat: 48.9034959, lng: 17.9119178 },
    { lat: 48.9034774, lng: 17.9120785 },
    { lat: 48.9034558, lng: 17.9123976 },
    { lat: 48.9034455, lng: 17.9125652 },
    { lat: 48.903485, lng: 17.912756 },
    { lat: 48.9036263, lng: 17.9134369 },
    { lat: 48.9039453, lng: 17.9143933 },
    { lat: 48.9041761, lng: 17.9149309 },
    { lat: 48.9043927, lng: 17.9154551 },
    { lat: 48.9046753, lng: 17.9162587 },
    { lat: 48.9050152, lng: 17.9171681 },
    { lat: 48.9050757, lng: 17.9173302 },
    { lat: 48.9049939, lng: 17.9174721 },
    { lat: 48.9049633, lng: 17.9175258 },
    { lat: 48.904666, lng: 17.918045 },
    { lat: 48.904454, lng: 17.9182571 },
    { lat: 48.9043475, lng: 17.9183629 },
    { lat: 48.9040171, lng: 17.9187474 },
    { lat: 48.9037913, lng: 17.9189915 },
    { lat: 48.9037242, lng: 17.91906 },
    { lat: 48.9036061, lng: 17.9191802 },
    { lat: 48.9034989, lng: 17.919331 },
    { lat: 48.9031335, lng: 17.9198458 },
    { lat: 48.9029628, lng: 17.9200125 },
    { lat: 48.9028808, lng: 17.9200937 },
    { lat: 48.9027067, lng: 17.9202593 },
    { lat: 48.9026108, lng: 17.9203575 },
    { lat: 48.9023579, lng: 17.9206176 },
    { lat: 48.9022287, lng: 17.9207628 },
    { lat: 48.9021315, lng: 17.920873 },
    { lat: 48.902049, lng: 17.9210217 },
    { lat: 48.9019501, lng: 17.9211993 },
    { lat: 48.9018212, lng: 17.9214208 },
    { lat: 48.9013052, lng: 17.9221748 },
    { lat: 48.9009119, lng: 17.922508 },
    { lat: 48.9008786, lng: 17.9225362 },
    { lat: 48.9007564, lng: 17.9226047 },
    { lat: 48.9006952, lng: 17.9226379 },
    { lat: 48.9005999, lng: 17.9226904 },
    { lat: 48.9005176, lng: 17.9227649 },
    { lat: 48.9004256, lng: 17.9228489 },
    { lat: 48.9001878, lng: 17.9229142 },
    { lat: 48.9001089, lng: 17.9229358 },
    { lat: 48.9000917, lng: 17.9229282 },
    { lat: 48.8999134, lng: 17.922854 },
    { lat: 48.8997826, lng: 17.9227231 },
    { lat: 48.8995227, lng: 17.9223398 },
    { lat: 48.8994334, lng: 17.9222082 },
    { lat: 48.8993353, lng: 17.9221137 },
    { lat: 48.8991388, lng: 17.9219247 },
    { lat: 48.8990419, lng: 17.9218308 },
    { lat: 48.8989724, lng: 17.9217643 },
    { lat: 48.898738, lng: 17.9212813 },
    { lat: 48.8986615, lng: 17.9209428 },
    { lat: 48.8984791, lng: 17.9201377 },
    { lat: 48.8984728, lng: 17.9200916 },
    { lat: 48.898457, lng: 17.9199786 },
    { lat: 48.8984, lng: 17.9198903 },
    { lat: 48.8982344, lng: 17.9196325 },
    { lat: 48.8981348, lng: 17.9196092 },
    { lat: 48.8980727, lng: 17.9196659 },
    { lat: 48.8980097, lng: 17.919724 },
    { lat: 48.8979462, lng: 17.9198181 },
    { lat: 48.8977191, lng: 17.9201532 },
    { lat: 48.8972528, lng: 17.9205678 },
    { lat: 48.8972044, lng: 17.920625 },
    { lat: 48.8971252, lng: 17.9207166 },
    { lat: 48.8970861, lng: 17.9207622 },
    { lat: 48.8970019, lng: 17.9208613 },
    { lat: 48.8968924, lng: 17.920937 },
    { lat: 48.8965911, lng: 17.9211463 },
    { lat: 48.8965183, lng: 17.9211961 },
    { lat: 48.8964988, lng: 17.9212099 },
    { lat: 48.8960986, lng: 17.9213478 },
    { lat: 48.895947, lng: 17.9213998 },
    { lat: 48.8958184, lng: 17.9214214 },
    { lat: 48.8957023, lng: 17.9214413 },
    { lat: 48.8954386, lng: 17.9214855 },
    { lat: 48.8954202, lng: 17.9214822 },
    { lat: 48.895298, lng: 17.921456 },
    { lat: 48.8951558, lng: 17.9213044 },
    { lat: 48.8948729, lng: 17.9209717 },
    { lat: 48.8947567, lng: 17.9209152 },
    { lat: 48.8945378, lng: 17.9208979 },
    { lat: 48.894443, lng: 17.9210128 },
    { lat: 48.8943682, lng: 17.9211031 },
    { lat: 48.8941462, lng: 17.9215045 },
    { lat: 48.8939116, lng: 17.9218851 },
    { lat: 48.8937537, lng: 17.9220578 },
    { lat: 48.8936742, lng: 17.9221444 },
    { lat: 48.8935809, lng: 17.9221888 },
    { lat: 48.8935337, lng: 17.9222118 },
    { lat: 48.893468, lng: 17.9222434 },
    { lat: 48.8934582, lng: 17.9222699 },
    { lat: 48.8933897, lng: 17.922445 },
    { lat: 48.8933919, lng: 17.9231083 },
    { lat: 48.8933923, lng: 17.9232252 },
    { lat: 48.8933681, lng: 17.9233709 },
    { lat: 48.8932709, lng: 17.9239726 },
    { lat: 48.8930399, lng: 17.9246206 },
    { lat: 48.8929859, lng: 17.9247729 },
    { lat: 48.8928502, lng: 17.925151 },
    { lat: 48.8927933, lng: 17.9253112 },
    { lat: 48.892727, lng: 17.9254287 },
    { lat: 48.8924789, lng: 17.9258681 },
    { lat: 48.8918551, lng: 17.9266514 },
    { lat: 48.8917608, lng: 17.9267709 },
    { lat: 48.8916718, lng: 17.9269255 },
    { lat: 48.8915093, lng: 17.9272097 },
    { lat: 48.8913229, lng: 17.927547 },
    { lat: 48.8911084, lng: 17.9279437 },
    { lat: 48.8908437, lng: 17.9284069 },
    { lat: 48.8907424, lng: 17.9285833 },
    { lat: 48.8905982, lng: 17.9288341 },
    { lat: 48.8904975, lng: 17.9290176 },
    { lat: 48.890478, lng: 17.9290672 },
    { lat: 48.8904587, lng: 17.9291241 },
    { lat: 48.8904387, lng: 17.9291856 },
    { lat: 48.8903667, lng: 17.929419 },
    { lat: 48.8903583, lng: 17.9294474 },
    { lat: 48.8903573, lng: 17.9294515 },
    { lat: 48.8903545, lng: 17.9294601 },
    { lat: 48.890349, lng: 17.9294781 },
    { lat: 48.8903368, lng: 17.9295199 },
    { lat: 48.8903146, lng: 17.9295843 },
    { lat: 48.8902977, lng: 17.9296049 },
    { lat: 48.8902237, lng: 17.9297047 },
    { lat: 48.8894513, lng: 17.9307419 },
    { lat: 48.8891237, lng: 17.9312485 },
    { lat: 48.8890918, lng: 17.9313002 },
    { lat: 48.8890765, lng: 17.9313252 },
    { lat: 48.8890144, lng: 17.9314224 },
    { lat: 48.8889593, lng: 17.9315368 },
    { lat: 48.888781, lng: 17.9318995 },
    { lat: 48.8884333, lng: 17.9326172 },
    { lat: 48.888358, lng: 17.9328101 },
    { lat: 48.8883452, lng: 17.9328413 },
    { lat: 48.8880384, lng: 17.9336215 },
    { lat: 48.8879373, lng: 17.9338771 },
    { lat: 48.8879158, lng: 17.9339324 },
    { lat: 48.8878992, lng: 17.9339767 },
    { lat: 48.8878902, lng: 17.9340905 },
    { lat: 48.8878639, lng: 17.9344237 },
    { lat: 48.8878526, lng: 17.9345752 },
    { lat: 48.8877974, lng: 17.9352797 },
    { lat: 48.8878693, lng: 17.9356127 },
    { lat: 48.8878939, lng: 17.9357241 },
    { lat: 48.8879593, lng: 17.935817 },
    { lat: 48.8889449, lng: 17.9372015 },
    { lat: 48.8892705, lng: 17.9384417 },
    { lat: 48.8892998, lng: 17.9385528 },
    { lat: 48.8892254, lng: 17.9386139 },
    { lat: 48.889107, lng: 17.9387101 },
    { lat: 48.8888739, lng: 17.9389078 },
    { lat: 48.8887999, lng: 17.9389703 },
    { lat: 48.8885709, lng: 17.9391649 },
    { lat: 48.8882632, lng: 17.9394746 },
    { lat: 48.8880099, lng: 17.9397687 },
    { lat: 48.887755, lng: 17.9400948 },
    { lat: 48.8872011, lng: 17.940783 },
    { lat: 48.8870981, lng: 17.9409115 },
    { lat: 48.8869833, lng: 17.9410298 },
    { lat: 48.886591, lng: 17.941435 },
    { lat: 48.8865792, lng: 17.941409 },
    { lat: 48.8861738, lng: 17.9418605 },
    { lat: 48.8858115, lng: 17.9422946 },
    { lat: 48.8857104, lng: 17.9424161 },
    { lat: 48.8854817, lng: 17.9427106 },
    { lat: 48.8852662, lng: 17.9429561 },
    { lat: 48.8851678, lng: 17.9430675 },
    { lat: 48.885065, lng: 17.9431389 },
    { lat: 48.8846878, lng: 17.9434018 },
    { lat: 48.8842026, lng: 17.9438768 },
    { lat: 48.8838324, lng: 17.9442388 },
    { lat: 48.88336, lng: 17.9447772 },
    { lat: 48.8830047, lng: 17.9451608 },
    { lat: 48.8829282, lng: 17.9452427 },
    { lat: 48.8828244, lng: 17.9453544 },
    { lat: 48.8827765, lng: 17.94548 },
    { lat: 48.8824929, lng: 17.9462146 },
    { lat: 48.8822262, lng: 17.9465344 },
    { lat: 48.8821387, lng: 17.9466934 },
    { lat: 48.8821187, lng: 17.9466438 },
    { lat: 48.8809235, lng: 17.9478746 },
    { lat: 48.8804635, lng: 17.9483195 },
    { lat: 48.8802477, lng: 17.9485054 },
    { lat: 48.8801553, lng: 17.9485848 },
    { lat: 48.8801296, lng: 17.9486069 },
    { lat: 48.8800072, lng: 17.9487122 },
    { lat: 48.8799137, lng: 17.9487742 },
    { lat: 48.8796097, lng: 17.9489761 },
    { lat: 48.8790859, lng: 17.9495057 },
    { lat: 48.8791478, lng: 17.9496561 },
    { lat: 48.879194, lng: 17.9497688 },
    { lat: 48.8796015, lng: 17.95011 },
    { lat: 48.8798521, lng: 17.9503192 },
    { lat: 48.8799209, lng: 17.9503733 },
    { lat: 48.8799628, lng: 17.9504142 },
    { lat: 48.8800035, lng: 17.9504549 },
    { lat: 48.8800278, lng: 17.9504817 },
    { lat: 48.879926, lng: 17.9508018 },
    { lat: 48.879958, lng: 17.9508469 },
    { lat: 48.8800072, lng: 17.9509331 },
    { lat: 48.8800711, lng: 17.9510022 },
    { lat: 48.8801785, lng: 17.9511725 },
    { lat: 48.8804041, lng: 17.9514942 },
    { lat: 48.8805379, lng: 17.9512593 },
    { lat: 48.8805816, lng: 17.9513099 },
    { lat: 48.8806483, lng: 17.9513894 },
    { lat: 48.8807317, lng: 17.9514992 },
    { lat: 48.8807672, lng: 17.9515463 },
    { lat: 48.8809746, lng: 17.9518045 },
    { lat: 48.8804461, lng: 17.9526385 },
    { lat: 48.8802507, lng: 17.9529955 },
    { lat: 48.8801191, lng: 17.9529453 },
    { lat: 48.8800348, lng: 17.9529117 },
    { lat: 48.879956, lng: 17.9528794 },
    { lat: 48.8798927, lng: 17.9528572 },
    { lat: 48.8797989, lng: 17.9528385 },
    { lat: 48.879499, lng: 17.9528824 },
    { lat: 48.8794169, lng: 17.9530568 },
    { lat: 48.8794028, lng: 17.953087 },
    { lat: 48.87938, lng: 17.9531572 },
    { lat: 48.8793211, lng: 17.9533431 },
    { lat: 48.8792954, lng: 17.9534252 },
    { lat: 48.8792891, lng: 17.9534494 },
    { lat: 48.8792845, lng: 17.9534679 },
    { lat: 48.8793531, lng: 17.953484 },
    { lat: 48.8794297, lng: 17.9535001 },
    { lat: 48.8794926, lng: 17.9535144 },
    { lat: 48.880246, lng: 17.9535803 },
    { lat: 48.8804142, lng: 17.9536954 },
    { lat: 48.8807659, lng: 17.9539341 },
    { lat: 48.8808767, lng: 17.954051 },
    { lat: 48.881128, lng: 17.9544986 },
    { lat: 48.8815114, lng: 17.9553101 },
    { lat: 48.8820256, lng: 17.9566868 },
    { lat: 48.8822464, lng: 17.9573466 },
    { lat: 48.8825545, lng: 17.9580489 },
    { lat: 48.8826694, lng: 17.9583112 },
    { lat: 48.8826837, lng: 17.9583401 },
    { lat: 48.8825976, lng: 17.9585078 },
    { lat: 48.882477, lng: 17.9587377 },
    { lat: 48.8822401, lng: 17.959213 },
    { lat: 48.8821005, lng: 17.9595623 },
    { lat: 48.8820744, lng: 17.9596921 },
    { lat: 48.8820804, lng: 17.9597738 },
    { lat: 48.8820842, lng: 17.9598382 },
    { lat: 48.8821471, lng: 17.9601165 },
    { lat: 48.8823525, lng: 17.9608379 },
    { lat: 48.8823709, lng: 17.9609017 },
    { lat: 48.88269, lng: 17.9620369 },
    { lat: 48.8828619, lng: 17.9626489 },
    { lat: 48.8829237, lng: 17.9628732 },
    { lat: 48.8831931, lng: 17.9638169 },
    { lat: 48.8831945, lng: 17.9638214 },
    { lat: 48.8832379, lng: 17.9639668 },
    { lat: 48.883287, lng: 17.9641441 },
    { lat: 48.883457, lng: 17.9647094 },
    { lat: 48.8834905, lng: 17.9648543 },
    { lat: 48.8835382, lng: 17.964841 },
    { lat: 48.8836038, lng: 17.9648984 },
    { lat: 48.8837766, lng: 17.9650315 },
    { lat: 48.8838542, lng: 17.9649722 },
    { lat: 48.8838856, lng: 17.9649478 },
    { lat: 48.8840161, lng: 17.9648468 },
    { lat: 48.884077, lng: 17.9648456 },
    { lat: 48.8841524, lng: 17.9648454 },
    { lat: 48.8843852, lng: 17.9650377 },
    { lat: 48.8845442, lng: 17.9651699 },
    { lat: 48.8846842, lng: 17.9652845 },
    { lat: 48.8847116, lng: 17.9653073 },
    { lat: 48.8848055, lng: 17.9653878 },
    { lat: 48.8848149, lng: 17.9653951 },
    { lat: 48.8848815, lng: 17.9654491 },
    { lat: 48.8849206, lng: 17.9654818 },
    { lat: 48.8849561, lng: 17.9655103 },
    { lat: 48.8849158, lng: 17.9656485 },
    { lat: 48.8849022, lng: 17.9657059 },
    { lat: 48.8848835, lng: 17.9657695 },
    { lat: 48.8846923, lng: 17.9664454 },
    { lat: 48.8846747, lng: 17.966512 },
    { lat: 48.884659, lng: 17.9665701 },
    { lat: 48.8846083, lng: 17.9666882 },
    { lat: 48.883849, lng: 17.9671637 },
    { lat: 48.8837411, lng: 17.9672312 },
    { lat: 48.8836604, lng: 17.9672784 },
    { lat: 48.88339, lng: 17.9672698 },
    { lat: 48.8832926, lng: 17.9672623 },
    { lat: 48.8831022, lng: 17.9674009 },
    { lat: 48.8830362, lng: 17.9675451 },
    { lat: 48.8829243, lng: 17.9679781 },
    { lat: 48.8829146, lng: 17.9680063 },
    { lat: 48.8827535, lng: 17.9684282 },
    { lat: 48.8827231, lng: 17.9685058 },
    { lat: 48.8826874, lng: 17.9686251 },
    { lat: 48.8826799, lng: 17.9686541 },
    { lat: 48.882628, lng: 17.9686727 },
    { lat: 48.8824716, lng: 17.9686804 },
    { lat: 48.8824691, lng: 17.9688851 },
    { lat: 48.8826595, lng: 17.9688412 },
    { lat: 48.8827592, lng: 17.9688305 },
    { lat: 48.8831619, lng: 17.968635 },
    { lat: 48.8833963, lng: 17.9696394 },
    { lat: 48.8834017, lng: 17.9698983 },
    { lat: 48.8834167, lng: 17.9706092 },
    { lat: 48.8834259, lng: 17.9709558 },
    { lat: 48.8836575, lng: 17.9708238 },
    { lat: 48.8837128, lng: 17.9709959 },
    { lat: 48.8837919, lng: 17.9712645 },
    { lat: 48.8838076, lng: 17.9713169 },
    { lat: 48.8838244, lng: 17.9713734 },
    { lat: 48.8838595, lng: 17.9714912 },
    { lat: 48.8838936, lng: 17.9716064 },
    { lat: 48.8839176, lng: 17.9716888 },
    { lat: 48.8839401, lng: 17.971765 },
    { lat: 48.8839597, lng: 17.971829 },
    { lat: 48.8839989, lng: 17.9719553 },
    { lat: 48.884028, lng: 17.972046 },
    { lat: 48.8840434, lng: 17.9720988 },
    { lat: 48.8840664, lng: 17.9721792 },
    { lat: 48.8840891, lng: 17.972259 },
    { lat: 48.8840962, lng: 17.9722827 },
    { lat: 48.884117, lng: 17.9723509 },
    { lat: 48.8842321, lng: 17.9727283 },
    { lat: 48.8843721, lng: 17.9728239 },
    { lat: 48.8845169, lng: 17.9729236 },
    { lat: 48.8845506, lng: 17.9729785 },
    { lat: 48.8846703, lng: 17.9731702 },
    { lat: 48.8847528, lng: 17.9732293 },
    { lat: 48.8851349, lng: 17.9735067 },
    { lat: 48.8851655, lng: 17.9735481 },
    { lat: 48.8851826, lng: 17.9735687 },
    { lat: 48.8852009, lng: 17.9735913 },
    { lat: 48.8856155, lng: 17.9741133 },
    { lat: 48.8855679, lng: 17.974511 },
    { lat: 48.8855123, lng: 17.9749809 },
    { lat: 48.8852117, lng: 17.9751252 },
    { lat: 48.8850902, lng: 17.9753612 },
    { lat: 48.8849474, lng: 17.9756378 },
    { lat: 48.8854822, lng: 17.9756539 },
    { lat: 48.8859249, lng: 17.975657 },
    { lat: 48.8862522, lng: 17.9756174 },
    { lat: 48.8864368, lng: 17.9763737 },
    { lat: 48.8870197, lng: 17.9773885 },
    { lat: 48.8873261, lng: 17.9777979 },
    { lat: 48.88742, lng: 17.9776707 },
    { lat: 48.8875342, lng: 17.9775188 },
    { lat: 48.8875634, lng: 17.9774801 },
    { lat: 48.8876021, lng: 17.9774267 },
    { lat: 48.8879321, lng: 17.9772437 },
    { lat: 48.8879468, lng: 17.9772646 },
    { lat: 48.8879703, lng: 17.9772984 },
    { lat: 48.8890096, lng: 17.9787807 },
    { lat: 48.8890872, lng: 17.9787199 },
    { lat: 48.8891637, lng: 17.9786593 },
    { lat: 48.8892575, lng: 17.9785859 },
    { lat: 48.889333, lng: 17.9785265 },
    { lat: 48.8893742, lng: 17.9784947 },
    { lat: 48.8894236, lng: 17.9784553 },
    { lat: 48.8894711, lng: 17.9784184 },
    { lat: 48.8895283, lng: 17.9783733 },
    { lat: 48.8895566, lng: 17.9784329 },
    { lat: 48.8895735, lng: 17.9784704 },
    { lat: 48.8895945, lng: 17.9785163 },
    { lat: 48.8896942, lng: 17.978431 },
    { lat: 48.8897364, lng: 17.9785325 },
    { lat: 48.8898316, lng: 17.9787663 },
    { lat: 48.8898581, lng: 17.978834 },
    { lat: 48.88998, lng: 17.9787561 },
    { lat: 48.8900148, lng: 17.9788887 },
    { lat: 48.8901681, lng: 17.9788459 },
    { lat: 48.8902945, lng: 17.9791497 },
    { lat: 48.8904653, lng: 17.9795587 },
    { lat: 48.890602, lng: 17.9798847 },
    { lat: 48.8901192, lng: 17.9800803 },
    { lat: 48.890128, lng: 17.9803044 },
    { lat: 48.8901397, lng: 17.9805976 },
    { lat: 48.8901434, lng: 17.980676 },
    { lat: 48.8901467, lng: 17.9807523 },
    { lat: 48.8901497, lng: 17.9808426 },
    { lat: 48.8901521, lng: 17.9808828 },
    { lat: 48.8901538, lng: 17.9809307 },
    { lat: 48.8898868, lng: 17.9811109 },
    { lat: 48.8896607, lng: 17.9812195 },
    { lat: 48.8891798, lng: 17.9817287 },
    { lat: 48.8891595, lng: 17.9817504 },
    { lat: 48.8891609, lng: 17.9817739 },
    { lat: 48.889175, lng: 17.9819553 },
    { lat: 48.8890007, lng: 17.981964 },
    { lat: 48.8889521, lng: 17.9819663 },
    { lat: 48.8888826, lng: 17.98197 },
    { lat: 48.8887689, lng: 17.9819754 },
    { lat: 48.8887007, lng: 17.9819707 },
    { lat: 48.8886361, lng: 17.9819663 },
    { lat: 48.8885547, lng: 17.9819605 },
    { lat: 48.8884856, lng: 17.9819556 },
    { lat: 48.8884107, lng: 17.9819503 },
    { lat: 48.8883008, lng: 17.9819427 },
    { lat: 48.888244, lng: 17.9819386 },
    { lat: 48.8882044, lng: 17.9819361 },
    { lat: 48.8881623, lng: 17.9819332 },
    { lat: 48.8880322, lng: 17.9819241 },
    { lat: 48.8879742, lng: 17.9819194 },
    { lat: 48.8878985, lng: 17.9819143 },
    { lat: 48.8878621, lng: 17.9819117 },
    { lat: 48.8878238, lng: 17.9819123 },
    { lat: 48.8877548, lng: 17.9819131 },
    { lat: 48.8875261, lng: 17.9819159 },
    { lat: 48.8874594, lng: 17.9819167 },
    { lat: 48.8874199, lng: 17.9819171 },
    { lat: 48.8873805, lng: 17.9819178 },
    { lat: 48.8872552, lng: 17.9819194 },
    { lat: 48.8871774, lng: 17.98192 },
    { lat: 48.8871054, lng: 17.9819208 },
    { lat: 48.8870444, lng: 17.981922 },
    { lat: 48.8869959, lng: 17.9819221 },
    { lat: 48.8868177, lng: 17.9819245 },
    { lat: 48.8868521, lng: 17.9821049 },
    { lat: 48.886887, lng: 17.9822952 },
    { lat: 48.8869081, lng: 17.9826872 },
    { lat: 48.8869274, lng: 17.9830533 },
    { lat: 48.8869288, lng: 17.9830775 },
    { lat: 48.8869438, lng: 17.9831195 },
    { lat: 48.8870004, lng: 17.9832556 },
    { lat: 48.8870103, lng: 17.9832719 },
    { lat: 48.887124, lng: 17.9833823 },
    { lat: 48.8871551, lng: 17.983392 },
    { lat: 48.8874386, lng: 17.9834513 },
    { lat: 48.887785, lng: 17.9835257 },
    { lat: 48.8880316, lng: 17.9835754 },
    { lat: 48.8883145, lng: 17.9834156 },
    { lat: 48.8886589, lng: 17.9832249 },
    { lat: 48.8890838, lng: 17.9829881 },
    { lat: 48.88936, lng: 17.9828321 },
    { lat: 48.8895296, lng: 17.982795 },
    { lat: 48.8897768, lng: 17.9828159 },
    { lat: 48.8900776, lng: 17.9828445 },
    { lat: 48.8901751, lng: 17.9828524 },
    { lat: 48.8903715, lng: 17.9827736 },
    { lat: 48.8903843, lng: 17.9828788 },
    { lat: 48.8905102, lng: 17.9827716 },
    { lat: 48.8905669, lng: 17.982724 },
    { lat: 48.8906509, lng: 17.982652 },
    { lat: 48.8906871, lng: 17.9826169 },
    { lat: 48.8907729, lng: 17.9825027 },
    { lat: 48.8908232, lng: 17.9824183 },
    { lat: 48.8910162, lng: 17.9820553 },
    { lat: 48.8912119, lng: 17.9816821 },
    { lat: 48.8913377, lng: 17.9814412 },
    { lat: 48.8915371, lng: 17.9816099 },
    { lat: 48.8915919, lng: 17.9816566 },
    { lat: 48.8917395, lng: 17.9817846 },
    { lat: 48.8919497, lng: 17.9819636 },
    { lat: 48.8920592, lng: 17.9820581 },
    { lat: 48.892114, lng: 17.9821052 },
    { lat: 48.8924256, lng: 17.9823744 },
    { lat: 48.8927689, lng: 17.9826648 },
    { lat: 48.8930198, lng: 17.9828799 },
    { lat: 48.8933598, lng: 17.9831697 },
    { lat: 48.8935818, lng: 17.9833561 },
    { lat: 48.89369, lng: 17.9834465 },
    { lat: 48.8937322, lng: 17.9834759 },
    { lat: 48.893872, lng: 17.9835838 },
    { lat: 48.894113, lng: 17.9837878 },
    { lat: 48.8941446, lng: 17.9836547 },
    { lat: 48.8941759, lng: 17.983531 },
    { lat: 48.894183, lng: 17.9834995 },
    { lat: 48.8942081, lng: 17.9833928 },
    { lat: 48.8942244, lng: 17.9833239 },
    { lat: 48.8942498, lng: 17.9832185 },
    { lat: 48.8942679, lng: 17.9831443 },
    { lat: 48.8942756, lng: 17.9831178 },
    { lat: 48.8942881, lng: 17.9830655 },
    { lat: 48.8943055, lng: 17.9829957 },
    { lat: 48.8943127, lng: 17.9829668 },
    { lat: 48.8943217, lng: 17.9829056 },
    { lat: 48.8943344, lng: 17.9828178 },
    { lat: 48.8943497, lng: 17.9827128 },
    { lat: 48.8943594, lng: 17.982648 },
    { lat: 48.8943643, lng: 17.9826154 },
    { lat: 48.894369, lng: 17.9825821 },
    { lat: 48.8943716, lng: 17.9825663 },
    { lat: 48.8943712, lng: 17.9825241 },
    { lat: 48.8943728, lng: 17.9824352 },
    { lat: 48.8943732, lng: 17.9823308 },
    { lat: 48.8943741, lng: 17.9823049 },
    { lat: 48.8943667, lng: 17.9822366 },
    { lat: 48.8943559, lng: 17.9821344 },
    { lat: 48.8943472, lng: 17.9820427 },
    { lat: 48.8943394, lng: 17.981967 },
    { lat: 48.8943629, lng: 17.9819642 },
    { lat: 48.8943661, lng: 17.9819623 },
    { lat: 48.8944494, lng: 17.9816321 },
    { lat: 48.8944668, lng: 17.9815662 },
    { lat: 48.8944713, lng: 17.981547 },
    { lat: 48.8944664, lng: 17.9814992 },
    { lat: 48.894417, lng: 17.9810285 },
    { lat: 48.8944161, lng: 17.9810136 },
    { lat: 48.8943995, lng: 17.980829 },
    { lat: 48.894374, lng: 17.9805311 },
    { lat: 48.8943548, lng: 17.9803228 },
    { lat: 48.8943184, lng: 17.9798999 },
    { lat: 48.8942745, lng: 17.979385 },
    { lat: 48.8942704, lng: 17.9793368 },
    { lat: 48.8942765, lng: 17.9790763 },
    { lat: 48.8942803, lng: 17.9787407 },
    { lat: 48.8942785, lng: 17.9786154 },
    { lat: 48.8943118, lng: 17.9787013 },
    { lat: 48.8943239, lng: 17.9787337 },
    { lat: 48.894383, lng: 17.9788939 },
    { lat: 48.8944599, lng: 17.9791054 },
    { lat: 48.8945153, lng: 17.9792586 },
    { lat: 48.8945842, lng: 17.9794508 },
    { lat: 48.8947294, lng: 17.9797111 },
    { lat: 48.8948546, lng: 17.9799355 },
    { lat: 48.894987, lng: 17.980171 },
    { lat: 48.8951024, lng: 17.980386 },
    { lat: 48.8952035, lng: 17.9805666 },
    { lat: 48.8953247, lng: 17.980785 },
    { lat: 48.8953838, lng: 17.9806758 },
    { lat: 48.8954202, lng: 17.9806088 },
    { lat: 48.8954467, lng: 17.9805607 },
    { lat: 48.8954602, lng: 17.9805367 },
    { lat: 48.8954916, lng: 17.9804804 },
    { lat: 48.8955947, lng: 17.9806557 },
    { lat: 48.8957041, lng: 17.980846 },
    { lat: 48.8958101, lng: 17.9805783 },
    { lat: 48.89581, lng: 17.9805772 },
    { lat: 48.8958386, lng: 17.9805045 },
    { lat: 48.8960626, lng: 17.980725 },
    { lat: 48.8962354, lng: 17.9808995 },
    { lat: 48.8962875, lng: 17.9807877 },
    { lat: 48.8966153, lng: 17.9810791 },
    { lat: 48.8967903, lng: 17.9812354 },
    { lat: 48.896838, lng: 17.9812773 },
    { lat: 48.8968696, lng: 17.9813157 },
    { lat: 48.8969863, lng: 17.981458 },
    { lat: 48.8972272, lng: 17.9817519 },
    { lat: 48.8973975, lng: 17.9819587 },
    { lat: 48.8975757, lng: 17.9819932 },
    { lat: 48.8976025, lng: 17.9816674 },
    { lat: 48.8976133, lng: 17.9815422 },
    { lat: 48.8976467, lng: 17.9811345 },
    { lat: 48.8976756, lng: 17.9809924 },
    { lat: 48.8977193, lng: 17.9807601 },
    { lat: 48.8977912, lng: 17.9803916 },
    { lat: 48.8978986, lng: 17.9801843 },
    { lat: 48.8979674, lng: 17.979917 },
    { lat: 48.8980413, lng: 17.9796232 },
    { lat: 48.8980781, lng: 17.9794822 },
    { lat: 48.8982263, lng: 17.9791808 },
    { lat: 48.8982945, lng: 17.9790384 },
    { lat: 48.8983378, lng: 17.9789471 },
    { lat: 48.8984226, lng: 17.9787978 },
    { lat: 48.8985176, lng: 17.9786324 },
    { lat: 48.898667, lng: 17.9789231 },
    { lat: 48.8988869, lng: 17.9786739 },
    { lat: 48.8989965, lng: 17.9787709 },
    { lat: 48.8990002, lng: 17.9787744 },
    { lat: 48.8990353, lng: 17.9787954 },
    { lat: 48.8990353, lng: 17.9787961 },
    { lat: 48.8990586, lng: 17.9788095 },
    { lat: 48.8991171, lng: 17.9787283 },
    { lat: 48.8991997, lng: 17.9785603 },
    { lat: 48.899297, lng: 17.9783547 },
    { lat: 48.8994183, lng: 17.9782313 },
    { lat: 48.8995398, lng: 17.978054 },
    { lat: 48.8996312, lng: 17.9779199 },
    { lat: 48.8996285, lng: 17.9779099 },
    { lat: 48.8996032, lng: 17.9777713 },
    { lat: 48.8997455, lng: 17.9777135 },
    { lat: 48.9000359, lng: 17.9775893 },
    { lat: 48.9002237, lng: 17.9776523 },
    { lat: 48.9004285, lng: 17.9777166 },
    { lat: 48.9005665, lng: 17.9776658 },
    { lat: 48.9006867, lng: 17.9776197 },
    { lat: 48.9008953, lng: 17.9775388 },
    { lat: 48.9009792, lng: 17.9775066 },
    { lat: 48.9010663, lng: 17.9774725 },
    { lat: 48.9013682, lng: 17.9773467 },
    { lat: 48.9016655, lng: 17.9772222 },
    { lat: 48.9018669, lng: 17.977137 },
    { lat: 48.9019503, lng: 17.9770553 },
    { lat: 48.9019746, lng: 17.9770304 },
    { lat: 48.9019981, lng: 17.9770082 },
    { lat: 48.902152, lng: 17.9768585 },
    { lat: 48.9021686, lng: 17.9768365 },
    { lat: 48.9022923, lng: 17.9766757 },
    { lat: 48.9023589, lng: 17.9765831 },
    { lat: 48.9023911, lng: 17.9765356 },
    { lat: 48.9026423, lng: 17.9762205 },
    { lat: 48.9029808, lng: 17.975799 },
    { lat: 48.9032698, lng: 17.9754389 },
    { lat: 48.9033508, lng: 17.9753403 },
    { lat: 48.9035433, lng: 17.9751017 },
    { lat: 48.9037275, lng: 17.9748767 },
    { lat: 48.9038403, lng: 17.9747243 },
    { lat: 48.9039328, lng: 17.9746105 },
    { lat: 48.9042527, lng: 17.9742372 },
    { lat: 48.9044471, lng: 17.974014 },
    { lat: 48.9046033, lng: 17.9738306 },
    { lat: 48.904709, lng: 17.9737071 },
    { lat: 48.9049856, lng: 17.9733859 },
    { lat: 48.9051845, lng: 17.9731981 },
    { lat: 48.9054207, lng: 17.9729771 },
    { lat: 48.9056227, lng: 17.9727867 },
    { lat: 48.9056408, lng: 17.9727853 },
    { lat: 48.905843, lng: 17.9728277 },
    { lat: 48.9058635, lng: 17.9728317 },
    { lat: 48.9058911, lng: 17.9728376 },
    { lat: 48.9060145, lng: 17.9728652 },
    { lat: 48.9062594, lng: 17.9728623 },
    { lat: 48.9062718, lng: 17.9728642 },
    { lat: 48.9064163, lng: 17.9728833 },
    { lat: 48.9065732, lng: 17.9729017 },
    { lat: 48.9066353, lng: 17.9728839 },
    { lat: 48.9066729, lng: 17.9728726 },
    { lat: 48.9069226, lng: 17.9727998 },
    { lat: 48.9069362, lng: 17.9727456 },
    { lat: 48.9069894, lng: 17.972518 },
    { lat: 48.9070781, lng: 17.9721464 },
    { lat: 48.9073228, lng: 17.9719136 },
    { lat: 48.9075315, lng: 17.9720673 },
    { lat: 48.9076211, lng: 17.9721308 },
    { lat: 48.9076477, lng: 17.9721591 },
    { lat: 48.9077453, lng: 17.9722829 },
    { lat: 48.9078596, lng: 17.9724223 },
    { lat: 48.9079456, lng: 17.9728088 },
    { lat: 48.9079211, lng: 17.972946 },
    { lat: 48.9079143, lng: 17.9729831 },
    { lat: 48.907971, lng: 17.973026 },
    { lat: 48.9080312, lng: 17.973072 },
    { lat: 48.9082988, lng: 17.9732802 },
    { lat: 48.9085002, lng: 17.9733463 },
    { lat: 48.9086189, lng: 17.9733865 },
    { lat: 48.9087765, lng: 17.9734392 },
    { lat: 48.9089816, lng: 17.9735085 },
    { lat: 48.9094522, lng: 17.9734511 },
    { lat: 48.9098119, lng: 17.973407 },
    { lat: 48.9098255, lng: 17.9734095 },
    { lat: 48.9101159, lng: 17.9732809 },
    { lat: 48.9103122, lng: 17.9730324 },
    { lat: 48.9105143, lng: 17.9727827 },
    { lat: 48.9108266, lng: 17.9727975 },
    { lat: 48.9110306, lng: 17.9726595 },
    { lat: 48.9110922, lng: 17.9726131 },
    { lat: 48.9111484, lng: 17.9725724 },
    { lat: 48.9113697, lng: 17.9724198 },
    { lat: 48.9115798, lng: 17.9722731 },
    { lat: 48.9117424, lng: 17.9721601 },
    { lat: 48.9119008, lng: 17.9720499 },
    { lat: 48.912058, lng: 17.9719416 },
    { lat: 48.9120849, lng: 17.9719196 },
    { lat: 48.9122291, lng: 17.9718199 },
    { lat: 48.9123584, lng: 17.9717337 },
    { lat: 48.912465, lng: 17.9715889 },
    { lat: 48.91247, lng: 17.9715822 },
    { lat: 48.9126089, lng: 17.9713917 },
    { lat: 48.9127269, lng: 17.9712327 },
    { lat: 48.9127886, lng: 17.9712032 },
    { lat: 48.912965, lng: 17.9711149 },
    { lat: 48.9131272, lng: 17.9710339 },
    { lat: 48.9131446, lng: 17.9709447 },
    { lat: 48.9131688, lng: 17.9708021 },
    { lat: 48.9132273, lng: 17.9704773 },
    { lat: 48.913341, lng: 17.970413 },
    { lat: 48.9134241, lng: 17.97035 },
    { lat: 48.9135181, lng: 17.970279 },
    { lat: 48.9135549, lng: 17.9702506 },
    { lat: 48.91361, lng: 17.9702094 },
    { lat: 48.9137105, lng: 17.9701347 },
    { lat: 48.9137169, lng: 17.9701112 },
    { lat: 48.9137615, lng: 17.9699845 },
    { lat: 48.9137924, lng: 17.9698999 },
    { lat: 48.9138147, lng: 17.9698366 },
    { lat: 48.91389, lng: 17.9696239 },
    { lat: 48.9137169, lng: 17.9693677 },
    { lat: 48.9135894, lng: 17.969177 },
    { lat: 48.9134616, lng: 17.9689834 },
    { lat: 48.9134224, lng: 17.9688377 },
    { lat: 48.9133565, lng: 17.9685998 },
    { lat: 48.9133329, lng: 17.9685079 },
    { lat: 48.9134133, lng: 17.9682813 },
    { lat: 48.9134152, lng: 17.9682771 },
    { lat: 48.9135874, lng: 17.9681187 },
    { lat: 48.9137736, lng: 17.9682314 },
    { lat: 48.9139309, lng: 17.9684274 },
    { lat: 48.914157, lng: 17.9687169 },
    { lat: 48.9141997, lng: 17.9687714 },
    { lat: 48.9143271, lng: 17.9689424 },
    { lat: 48.9143563, lng: 17.96898 },
    { lat: 48.9144342, lng: 17.9686177 },
    { lat: 48.9145314, lng: 17.9681631 },
    { lat: 48.9145356, lng: 17.9681381 },
    { lat: 48.9145144, lng: 17.9681044 },
    { lat: 48.9143667, lng: 17.9678594 },
    { lat: 48.9142077, lng: 17.9675947 },
    { lat: 48.9143145, lng: 17.9674165 },
    { lat: 48.9144617, lng: 17.9671764 },
    { lat: 48.9144918, lng: 17.9670374 },
    { lat: 48.9145769, lng: 17.9666648 },
    { lat: 48.9146966, lng: 17.9666255 },
    { lat: 48.9148548, lng: 17.9665744 },
    { lat: 48.9149636, lng: 17.9666776 },
    { lat: 48.9152079, lng: 17.966912 },
    { lat: 48.9153566, lng: 17.9670592 },
    { lat: 48.91539, lng: 17.9670924 },
    { lat: 48.9157, lng: 17.9670271 },
    { lat: 48.9158749, lng: 17.9669885 },
    { lat: 48.9160422, lng: 17.9669548 },
    { lat: 48.9162183, lng: 17.9669164 },
    { lat: 48.9162852, lng: 17.9669044 },
    { lat: 48.9166059, lng: 17.9665656 },
    { lat: 48.9168217, lng: 17.9663402 },
    { lat: 48.9170014, lng: 17.9661506 },
    { lat: 48.9171392, lng: 17.9660586 },
    { lat: 48.9173116, lng: 17.9659428 },
    { lat: 48.917368, lng: 17.965901 },
    { lat: 48.9175301, lng: 17.9657813 },
    { lat: 48.9176607, lng: 17.9655836 },
    { lat: 48.9177848, lng: 17.9653955 },
    { lat: 48.9178511, lng: 17.9652928 },
    { lat: 48.9179167, lng: 17.9651822 },
    { lat: 48.9180136, lng: 17.9650258 },
    { lat: 48.9181855, lng: 17.9647651 },
    { lat: 48.9183615, lng: 17.9646115 },
    { lat: 48.9185123, lng: 17.964358 },
    { lat: 48.9187299, lng: 17.963992 },
    { lat: 48.9188078, lng: 17.9638575 },
    { lat: 48.9190044, lng: 17.9635199 },
    { lat: 48.9191288, lng: 17.963317 },
    { lat: 48.9192934, lng: 17.9630447 },
    { lat: 48.9194959, lng: 17.9627109 },
    { lat: 48.9195876, lng: 17.9625634 },
    { lat: 48.9196806, lng: 17.9624 },
    { lat: 48.919708, lng: 17.9623672 },
    { lat: 48.9197505, lng: 17.962286 },
    { lat: 48.9199046, lng: 17.9619735 },
    { lat: 48.9199851, lng: 17.9618078 },
    { lat: 48.9200452, lng: 17.961678 },
    { lat: 48.9200655, lng: 17.9617157 },
    { lat: 48.9201462, lng: 17.9618605 },
    { lat: 48.9203242, lng: 17.9621771 },
    { lat: 48.9203818, lng: 17.9621401 },
    { lat: 48.9207387, lng: 17.9618968 },
    { lat: 48.9207812, lng: 17.9618128 },
    { lat: 48.9209348, lng: 17.9615108 },
    { lat: 48.9212113, lng: 17.9608819 },
    { lat: 48.9213674, lng: 17.9605278 },
    { lat: 48.9213946, lng: 17.9604918 },
    { lat: 48.921573, lng: 17.9602847 },
    { lat: 48.9216893, lng: 17.9601529 },
    { lat: 48.9216537, lng: 17.9600627 },
    { lat: 48.9215767, lng: 17.9598704 },
    { lat: 48.9215004, lng: 17.9596874 },
    { lat: 48.9214963, lng: 17.9596593 },
    { lat: 48.9215476, lng: 17.9596103 },
    { lat: 48.9216685, lng: 17.959495 },
    { lat: 48.9216843, lng: 17.9594796 },
    { lat: 48.921911, lng: 17.9592656 },
    { lat: 48.9219602, lng: 17.9592588 },
    { lat: 48.9221012, lng: 17.9592392 },
    { lat: 48.9221918, lng: 17.9592239 },
    { lat: 48.9222279, lng: 17.9592215 },
    { lat: 48.9222637, lng: 17.9592188 },
    { lat: 48.9223548, lng: 17.9592127 },
    { lat: 48.9223885, lng: 17.9592107 },
    { lat: 48.9228148, lng: 17.9591832 },
    { lat: 48.9228286, lng: 17.9592653 },
    { lat: 48.9228429, lng: 17.959258 },
    { lat: 48.9230815, lng: 17.9591345 },
    { lat: 48.9232066, lng: 17.9589785 },
    { lat: 48.9233785, lng: 17.9587953 },
    { lat: 48.9233964, lng: 17.9587929 },
    { lat: 48.9234676, lng: 17.9588291 },
    { lat: 48.9236071, lng: 17.958613 },
    { lat: 48.923536, lng: 17.9580617 },
    { lat: 48.9235528, lng: 17.9579463 },
    { lat: 48.9235847, lng: 17.957783 },
    { lat: 48.9236734, lng: 17.9573362 },
    { lat: 48.9237291, lng: 17.9570407 },
    { lat: 48.9238371, lng: 17.9564804 },
    { lat: 48.9240207, lng: 17.9561509 },
    { lat: 48.9240295, lng: 17.9560535 },
    { lat: 48.9240625, lng: 17.955717 },
    { lat: 48.9240518, lng: 17.9554206 },
    { lat: 48.9240513, lng: 17.9552807 },
    { lat: 48.9240476, lng: 17.9548203 },
    { lat: 48.9240475, lng: 17.9547787 },
    { lat: 48.9240974, lng: 17.9544047 },
    { lat: 48.9241236, lng: 17.9541807 },
    { lat: 48.9242488, lng: 17.9540276 },
    { lat: 48.924286, lng: 17.9539887 },
    { lat: 48.924304, lng: 17.9539691 },
    { lat: 48.9243273, lng: 17.953944 },
    { lat: 48.924477, lng: 17.9537842 },
    { lat: 48.9245797, lng: 17.953614 },
    { lat: 48.9246474, lng: 17.9534802 },
    { lat: 48.9249648, lng: 17.953332 },
    { lat: 48.9252821, lng: 17.9530125 },
    { lat: 48.9254452, lng: 17.9528481 },
    { lat: 48.9257826, lng: 17.9527692 },
    { lat: 48.9260311, lng: 17.9527169 },
    { lat: 48.9262204, lng: 17.9530524 },
    { lat: 48.9263196, lng: 17.952995 },
    { lat: 48.9266833, lng: 17.9527712 },
    { lat: 48.9268767, lng: 17.9527777 },
    { lat: 48.9269163, lng: 17.9527791 },
    { lat: 48.9269414, lng: 17.9527843 },
    { lat: 48.9270516, lng: 17.9528557 },
    { lat: 48.9272115, lng: 17.9529634 },
    { lat: 48.9273748, lng: 17.9527846 },
    { lat: 48.9275272, lng: 17.9526154 },
    { lat: 48.9276072, lng: 17.9525726 },
    { lat: 48.9278628, lng: 17.9525831 },
    { lat: 48.9279511, lng: 17.9525682 },
    { lat: 48.9280155, lng: 17.9525497 },
    { lat: 48.9282314, lng: 17.9524971 },
    { lat: 48.9285027, lng: 17.9524299 },
    { lat: 48.9285504, lng: 17.9524323 },
    { lat: 48.9286051, lng: 17.952442 },
    { lat: 48.9287649, lng: 17.9524916 },
    { lat: 48.9291168, lng: 17.9524312 },
    { lat: 48.9295325, lng: 17.9523237 },
    { lat: 48.9297971, lng: 17.9522347 },
    { lat: 48.9300606, lng: 17.9519409 },
    { lat: 48.9302473, lng: 17.9516637 },
    { lat: 48.9304766, lng: 17.9513422 },
    { lat: 48.930672, lng: 17.9510606 },
    { lat: 48.9309011, lng: 17.9508322 },
    { lat: 48.930998, lng: 17.950755 },
    { lat: 48.9310614, lng: 17.9507004 },
    { lat: 48.9314178, lng: 17.9506763 },
    { lat: 48.9314522, lng: 17.950686 },
    { lat: 48.9316243, lng: 17.9506764 },
    { lat: 48.9318834, lng: 17.9506661 },
    { lat: 48.9322266, lng: 17.9505745 },
    { lat: 48.9325226, lng: 17.9504062 },
    { lat: 48.9326701, lng: 17.950325 },
    { lat: 48.9327789, lng: 17.9502577 },
    { lat: 48.9329846, lng: 17.950396 },
    { lat: 48.9330616, lng: 17.9504376 },
    { lat: 48.9332688, lng: 17.9502182 },
    { lat: 48.9335213, lng: 17.9499657 },
    { lat: 48.9335938, lng: 17.9500279 },
    { lat: 48.9338694, lng: 17.9503158 },
    { lat: 48.9341715, lng: 17.9506345 },
    { lat: 48.934406, lng: 17.9508759 },
    { lat: 48.9345025, lng: 17.9509797 },
    { lat: 48.9348255, lng: 17.9513232 },
    { lat: 48.934979, lng: 17.951495 },
    { lat: 48.9350767, lng: 17.9516026 },
    { lat: 48.9350853, lng: 17.9516122 },
    { lat: 48.9351156, lng: 17.9515728 },
    { lat: 48.9351744, lng: 17.9515005 },
    { lat: 48.9353488, lng: 17.9512828 },
    { lat: 48.9354321, lng: 17.9511633 },
    { lat: 48.9355264, lng: 17.9511554 },
    { lat: 48.9357726, lng: 17.9511587 },
    { lat: 48.9358348, lng: 17.9511383 },
    { lat: 48.9361588, lng: 17.9510466 },
    { lat: 48.9364496, lng: 17.9509654 },
    { lat: 48.9367505, lng: 17.9508628 },
    { lat: 48.9370776, lng: 17.9507825 },
    { lat: 48.9374093, lng: 17.9506847 },
    { lat: 48.9376223, lng: 17.950392 },
    { lat: 48.9380237, lng: 17.9498361 },
    { lat: 48.9385583, lng: 17.9491091 },
    { lat: 48.9392325, lng: 17.9481849 },
    { lat: 48.9392494, lng: 17.9481618 },
    { lat: 48.9392554, lng: 17.9481527 },
    { lat: 48.9392734, lng: 17.9481319 },
    { lat: 48.9392916, lng: 17.9481122 },
    { lat: 48.9393358, lng: 17.9480609 },
    { lat: 48.9393423, lng: 17.9480535 },
    { lat: 48.9393518, lng: 17.9480429 },
    { lat: 48.9393602, lng: 17.9480331 },
    { lat: 48.9393938, lng: 17.9479933 },
    { lat: 48.9394086, lng: 17.9479762 },
    { lat: 48.9397595, lng: 17.947572 },
    { lat: 48.9401309, lng: 17.9471398 },
    { lat: 48.9405339, lng: 17.946667 },
    { lat: 48.9409479, lng: 17.9461467 },
    { lat: 48.9413487, lng: 17.9453733 },
    { lat: 48.9416025, lng: 17.9447063 },
    { lat: 48.9418494, lng: 17.9440728 },
    { lat: 48.9419032, lng: 17.9439362 },
    { lat: 48.9422912, lng: 17.9429641 },
    { lat: 48.9423334, lng: 17.9428585 },
    { lat: 48.9423521, lng: 17.9428104 },
    { lat: 48.9425693, lng: 17.9422686 },
    { lat: 48.942958, lng: 17.9412314 },
    { lat: 48.9434252, lng: 17.9401526 },
    { lat: 48.9438155, lng: 17.9394269 },
    { lat: 48.9443527, lng: 17.938513 },
    { lat: 48.9445101, lng: 17.9381625 },
    { lat: 48.944553, lng: 17.9380679 },
    { lat: 48.9445717, lng: 17.9380251 },
    { lat: 48.9445857, lng: 17.9379941 },
    { lat: 48.9449074, lng: 17.9373859 },
    { lat: 48.9453365, lng: 17.9365923 },
    { lat: 48.9458548, lng: 17.9356292 },
    { lat: 48.9462906, lng: 17.9348368 },
    { lat: 48.9466276, lng: 17.934221 },
    { lat: 48.9466427, lng: 17.9341931 },
    { lat: 48.94671, lng: 17.9340718 },
    { lat: 48.946847, lng: 17.9340207 },
    { lat: 48.9472843, lng: 17.9338579 },
    { lat: 48.9479109, lng: 17.9335963 },
    { lat: 48.9486239, lng: 17.9333253 },
    { lat: 48.948878, lng: 17.9332347 },
    { lat: 48.9489155, lng: 17.933217 },
    { lat: 48.9489441, lng: 17.9332051 },
    { lat: 48.9493083, lng: 17.933065 },
    { lat: 48.9499002, lng: 17.9328645 },
    { lat: 48.9501498, lng: 17.9327939 },
    { lat: 48.9501721, lng: 17.9327873 },
    { lat: 48.9502086, lng: 17.9327769 },
    { lat: 48.9502787, lng: 17.9327582 },
    { lat: 48.9503464, lng: 17.9327396 },
    { lat: 48.9505483, lng: 17.9326785 },
    { lat: 48.951009, lng: 17.9327017 },
    { lat: 48.9516248, lng: 17.9327313 },
    { lat: 48.9521236, lng: 17.9322006 },
    { lat: 48.9527421, lng: 17.9315364 },
    { lat: 48.9530105, lng: 17.9312495 },
    { lat: 48.9530425, lng: 17.931216 },
    { lat: 48.9533045, lng: 17.9309362 },
    { lat: 48.9538037, lng: 17.9302405 },
    { lat: 48.9538815, lng: 17.9301447 },
    { lat: 48.9543501, lng: 17.929463 },
    { lat: 48.9548876, lng: 17.9285799 },
    { lat: 48.9553398, lng: 17.9278544 },
    { lat: 48.955466, lng: 17.927642 },
    { lat: 48.9556006, lng: 17.9274368 },
    { lat: 48.9556234, lng: 17.9274024 },
    { lat: 48.9560838, lng: 17.9267012 },
    { lat: 48.9565797, lng: 17.9258953 },
    { lat: 48.9570898, lng: 17.9251521 },
    { lat: 48.9576151, lng: 17.9243471 },
    { lat: 48.9580971, lng: 17.9235927 },
    { lat: 48.9586231, lng: 17.9227596 },
    { lat: 48.959119, lng: 17.9219694 },
    { lat: 48.9595557, lng: 17.9213841 },
    { lat: 48.9600254, lng: 17.9209122 },
    { lat: 48.9603976, lng: 17.9205301 },
    { lat: 48.9604828, lng: 17.920443 },
    { lat: 48.9605061, lng: 17.9204171 },
    { lat: 48.9605368, lng: 17.9203845 },
    { lat: 48.9608772, lng: 17.920018 },
    { lat: 48.9612156, lng: 17.9196557 },
    { lat: 48.961693, lng: 17.9191428 },
    { lat: 48.962077, lng: 17.9186926 },
    { lat: 48.9626142, lng: 17.9180471 },
    { lat: 48.9631571, lng: 17.9173862 },
    { lat: 48.9636857, lng: 17.9167278 },
    { lat: 48.9637265, lng: 17.9166759 },
    { lat: 48.9637558, lng: 17.9166388 },
    { lat: 48.9638232, lng: 17.9165555 },
    { lat: 48.9643399, lng: 17.9159074 },
    { lat: 48.9643471, lng: 17.9158982 },
    { lat: 48.964368, lng: 17.9158723 },
    { lat: 48.9651252, lng: 17.9149425 },
    { lat: 48.9658948, lng: 17.9139747 },
    { lat: 48.9662273, lng: 17.9135513 },
    { lat: 48.9665482, lng: 17.9131414 },
    { lat: 48.9665987, lng: 17.9130762 },
    { lat: 48.9672403, lng: 17.9122546 },
    { lat: 48.9677859, lng: 17.9115809 },
    { lat: 48.9681169, lng: 17.9111904 },
    { lat: 48.9681381, lng: 17.9111652 },
    { lat: 48.9681389, lng: 17.9111636 },
    { lat: 48.9681727, lng: 17.9111238 },
    { lat: 48.968315, lng: 17.9109554 },
    { lat: 48.9686305, lng: 17.9105875 },
    { lat: 48.9692705, lng: 17.9098505 },
    { lat: 48.9699232, lng: 17.9091152 },
    { lat: 48.9699835, lng: 17.9090476 },
    { lat: 48.9700658, lng: 17.9089533 },
    { lat: 48.9700964, lng: 17.9089189 },
    { lat: 48.9707075, lng: 17.9082246 },
    { lat: 48.9710507, lng: 17.9078327 },
    { lat: 48.9711112, lng: 17.9077644 },
    { lat: 48.9713932, lng: 17.9074413 },
    { lat: 48.9719989, lng: 17.9067372 },
    { lat: 48.9729209, lng: 17.9057158 },
    { lat: 48.9738033, lng: 17.9047461 },
    { lat: 48.9746321, lng: 17.9038082 },
    { lat: 48.9750927, lng: 17.9032987 },
    { lat: 48.9751412, lng: 17.903245 },
    { lat: 48.9752568, lng: 17.9031173 },
    { lat: 48.9759558, lng: 17.9023115 },
    { lat: 48.9760125, lng: 17.901843 },
    { lat: 48.9760184, lng: 17.9017912 },
    { lat: 48.9762997, lng: 17.9014062 },
    { lat: 48.976273, lng: 17.901318 },
    { lat: 48.976191, lng: 17.901315 },
    { lat: 48.97612, lng: 17.901312 },
    { lat: 48.975819, lng: 17.900964 },
    { lat: 48.975663, lng: 17.900922 },
    { lat: 48.975529, lng: 17.900693 },
    { lat: 48.975451, lng: 17.900559 },
    { lat: 48.975275, lng: 17.900556 },
    { lat: 48.9751, lng: 17.900552 },
    { lat: 48.974922, lng: 17.900549 },
    { lat: 48.974502, lng: 17.900322 },
    { lat: 48.974345, lng: 17.900462 },
    { lat: 48.97425, lng: 17.900548 },
    { lat: 48.97405, lng: 17.900725 },
    { lat: 48.973841, lng: 17.900969 },
    { lat: 48.97352, lng: 17.900975 },
    { lat: 48.973462, lng: 17.901085 },
    { lat: 48.973422, lng: 17.90116 },
    { lat: 48.97334, lng: 17.901317 },
    { lat: 48.973268, lng: 17.901429 },
    { lat: 48.973138, lng: 17.901634 },
    { lat: 48.972814, lng: 17.901715 },
    { lat: 48.972625, lng: 17.901595 },
    { lat: 48.972426, lng: 17.901468 },
    { lat: 48.972192, lng: 17.901261 },
    { lat: 48.972077, lng: 17.90116 },
    { lat: 48.971861, lng: 17.900859 },
    { lat: 48.971799, lng: 17.900792 },
    { lat: 48.971509, lng: 17.900602 },
    { lat: 48.971281, lng: 17.900391 },
    { lat: 48.971019, lng: 17.900149 },
    { lat: 48.970898, lng: 17.900105 },
    { lat: 48.970755, lng: 17.900053 },
    { lat: 48.970448, lng: 17.900021 },
    { lat: 48.970138, lng: 17.89999 },
    { lat: 48.97006, lng: 17.899778 },
    { lat: 48.969941, lng: 17.899606 },
    { lat: 48.969626, lng: 17.899623 },
    { lat: 48.969506, lng: 17.899629 },
    { lat: 48.969206, lng: 17.899653 },
    { lat: 48.968846, lng: 17.900017 },
    { lat: 48.968721, lng: 17.900109 },
    { lat: 48.968548, lng: 17.900235 },
    { lat: 48.968335, lng: 17.90039 },
    { lat: 48.967966, lng: 17.900519 },
    { lat: 48.967543, lng: 17.900908 },
    { lat: 48.967119, lng: 17.901209 },
    { lat: 48.966542, lng: 17.901565 },
    { lat: 48.966243, lng: 17.901618 },
    { lat: 48.966099, lng: 17.901738 },
    { lat: 48.965772, lng: 17.902011 },
    { lat: 48.965503, lng: 17.902235 },
    { lat: 48.965379, lng: 17.902362 },
    { lat: 48.965209, lng: 17.902534 },
    { lat: 48.965114, lng: 17.902577 },
    { lat: 48.965102, lng: 17.902583 },
    { lat: 48.965051, lng: 17.902606 },
    { lat: 48.965012, lng: 17.902625 },
    { lat: 48.96494, lng: 17.902588 },
    { lat: 48.964857, lng: 17.902548 },
    { lat: 48.964647, lng: 17.902676 },
    { lat: 48.964523, lng: 17.902752 },
    { lat: 48.964405, lng: 17.902824 },
    { lat: 48.964181, lng: 17.903038 },
    { lat: 48.964001, lng: 17.902919 },
    { lat: 48.963906, lng: 17.902856 },
    { lat: 48.963777, lng: 17.902718 },
    { lat: 48.963587, lng: 17.902517 },
    { lat: 48.963475, lng: 17.902418 },
    { lat: 48.963403, lng: 17.902355 },
    { lat: 48.963285, lng: 17.902253 },
    { lat: 48.962877, lng: 17.902015 },
    { lat: 48.962714, lng: 17.90199 },
    { lat: 48.962482, lng: 17.901952 },
    { lat: 48.96238, lng: 17.901944 },
    { lat: 48.962162, lng: 17.901925 },
    { lat: 48.961965, lng: 17.901873 },
    { lat: 48.961699, lng: 17.901802 },
    { lat: 48.961553, lng: 17.901735 },
    { lat: 48.961259, lng: 17.901601 },
    { lat: 48.961207, lng: 17.901581 },
    { lat: 48.9611, lng: 17.90154 },
    { lat: 48.961017, lng: 17.901508 },
    { lat: 48.960897, lng: 17.901462 },
    { lat: 48.960812, lng: 17.90143 },
    { lat: 48.960669, lng: 17.901407 },
    { lat: 48.960472, lng: 17.901374 },
    { lat: 48.960376, lng: 17.901359 },
    { lat: 48.960296, lng: 17.901328 },
    { lat: 48.960203, lng: 17.901292 },
    { lat: 48.960108, lng: 17.901256 },
    { lat: 48.960012, lng: 17.90122 },
    { lat: 48.959729, lng: 17.901162 },
    { lat: 48.959533, lng: 17.901123 },
    { lat: 48.959455, lng: 17.901091 },
    { lat: 48.959186, lng: 17.900986 },
    { lat: 48.9589534, lng: 17.9007966 },
    { lat: 48.958691, lng: 17.9004456 },
    { lat: 48.9584639, lng: 17.9003101 },
    { lat: 48.958447, lng: 17.9002999 },
    { lat: 48.958208, lng: 17.900196 },
    { lat: 48.957855, lng: 17.900063 },
    { lat: 48.957523, lng: 17.899995 },
    { lat: 48.957221, lng: 17.899892 },
    { lat: 48.957196, lng: 17.899884 },
    { lat: 48.957066, lng: 17.899839 },
    { lat: 48.956969, lng: 17.899786 },
    { lat: 48.956932, lng: 17.899765 },
    { lat: 48.956785, lng: 17.899684 },
    { lat: 48.956632, lng: 17.899599 },
    { lat: 48.956452, lng: 17.899528 },
    { lat: 48.956333, lng: 17.899481 },
    { lat: 48.956198, lng: 17.899426 },
    { lat: 48.95611, lng: 17.899399 },
    { lat: 48.955984, lng: 17.899361 },
    { lat: 48.955876, lng: 17.899327 },
    { lat: 48.955816, lng: 17.899309 },
    { lat: 48.955674, lng: 17.899225 },
    { lat: 48.95552, lng: 17.899135 },
    { lat: 48.955486, lng: 17.899134 },
    { lat: 48.955324, lng: 17.899066 },
    { lat: 48.955207, lng: 17.899017 },
    { lat: 48.954954, lng: 17.898982 },
    { lat: 48.954779, lng: 17.898828 },
    { lat: 48.954658, lng: 17.898752 },
    { lat: 48.954532, lng: 17.898674 },
    { lat: 48.954364, lng: 17.898426 },
    { lat: 48.954225, lng: 17.898247 },
    { lat: 48.954102, lng: 17.898086 },
    { lat: 48.953897, lng: 17.897837 },
    { lat: 48.953684, lng: 17.897612 },
    { lat: 48.953473, lng: 17.897484 },
    { lat: 48.953182, lng: 17.897337 },
    { lat: 48.952907, lng: 17.897167 },
    { lat: 48.95282, lng: 17.897021 },
    { lat: 48.952863, lng: 17.896956 },
    { lat: 48.952657, lng: 17.896839 },
    { lat: 48.9522, lng: 17.896897 },
    { lat: 48.952128, lng: 17.896843 },
    { lat: 48.952121, lng: 17.896838 },
    { lat: 48.952112, lng: 17.896831 },
    { lat: 48.952041, lng: 17.896757 },
    { lat: 48.95201, lng: 17.896725 },
    { lat: 48.951918, lng: 17.89663 },
    { lat: 48.951875, lng: 17.896586 },
    { lat: 48.9518115, lng: 17.896524 },
    { lat: 48.951745, lng: 17.896451 },
    { lat: 48.95171, lng: 17.896415 },
    { lat: 48.951722, lng: 17.896206 },
    { lat: 48.951725, lng: 17.89614 },
    { lat: 48.951729, lng: 17.896077 },
    { lat: 48.951729, lng: 17.896068 },
    { lat: 48.951705, lng: 17.896048 },
    { lat: 48.951616, lng: 17.895974 },
    { lat: 48.951488, lng: 17.895867 },
    { lat: 48.951467, lng: 17.895841 },
    { lat: 48.951194, lng: 17.895515 },
    { lat: 48.951184, lng: 17.895503 },
    { lat: 48.950951, lng: 17.895101 },
    { lat: 48.9509312, lng: 17.8950681 },
    { lat: 48.9509477, lng: 17.8950313 },
    { lat: 48.950867, lng: 17.8949706 },
    { lat: 48.9507832, lng: 17.8949493 },
    { lat: 48.9506603, lng: 17.894937 },
    { lat: 48.9506, lng: 17.894931 },
    { lat: 48.950498, lng: 17.894893 },
    { lat: 48.950443, lng: 17.894861 },
    { lat: 48.950362, lng: 17.894813 },
    { lat: 48.950237, lng: 17.894735 },
    { lat: 48.950099, lng: 17.894723 },
    { lat: 48.949968, lng: 17.894713 },
    { lat: 48.949796, lng: 17.894696 },
    { lat: 48.949749, lng: 17.89467 },
    { lat: 48.94965, lng: 17.894594 },
    { lat: 48.949629, lng: 17.894582 },
    { lat: 48.949441, lng: 17.894477 },
    { lat: 48.949237, lng: 17.894408 },
    { lat: 48.949194, lng: 17.8944 },
    { lat: 48.948979, lng: 17.89436 },
    { lat: 48.948883, lng: 17.894315 },
    { lat: 48.948709, lng: 17.894189 },
    { lat: 48.948597, lng: 17.894136 },
    { lat: 48.948512, lng: 17.894096 },
    { lat: 48.948402, lng: 17.894061 },
    { lat: 48.948296, lng: 17.893813 },
    { lat: 48.948279, lng: 17.893773 },
    { lat: 48.948177, lng: 17.893625 },
    { lat: 48.948078, lng: 17.89348 },
    { lat: 48.947933, lng: 17.893242 },
    { lat: 48.947838, lng: 17.893254 },
    { lat: 48.947708, lng: 17.89293 },
    { lat: 48.947595, lng: 17.892871 },
    { lat: 48.947371, lng: 17.892713 },
    { lat: 48.947294, lng: 17.892579 },
    { lat: 48.947018, lng: 17.892344 },
    { lat: 48.946943, lng: 17.892321 },
    { lat: 48.946853, lng: 17.892294 },
    { lat: 48.946769, lng: 17.892269 },
    { lat: 48.946749, lng: 17.892221 },
    { lat: 48.946686, lng: 17.892072 },
    { lat: 48.946518, lng: 17.891885 },
    { lat: 48.946501, lng: 17.891876 },
    { lat: 48.946489, lng: 17.891884 },
    { lat: 48.946472, lng: 17.891896 },
    { lat: 48.94647, lng: 17.891895 },
    { lat: 48.946416, lng: 17.89188 },
    { lat: 48.946391, lng: 17.891874 },
    { lat: 48.946365, lng: 17.891866 },
    { lat: 48.946299, lng: 17.891759 },
    { lat: 48.946272, lng: 17.891646 },
    { lat: 48.946239, lng: 17.891509 },
    { lat: 48.946213, lng: 17.891398 },
    { lat: 48.946177, lng: 17.891248 },
    { lat: 48.946148, lng: 17.891184 },
    { lat: 48.946134, lng: 17.891153 },
    { lat: 48.946, lng: 17.890858 },
    { lat: 48.945993, lng: 17.890846 },
    { lat: 48.945883, lng: 17.890683 },
    { lat: 48.945805, lng: 17.890566 },
    { lat: 48.945725, lng: 17.890452 },
    { lat: 48.945687, lng: 17.890399 },
    { lat: 48.945596, lng: 17.890271 },
    { lat: 48.945584, lng: 17.890203 },
    { lat: 48.945582, lng: 17.890197 },
    { lat: 48.945559, lng: 17.890163 },
    { lat: 48.945502, lng: 17.890093 },
    { lat: 48.945404, lng: 17.889974 },
    { lat: 48.945308, lng: 17.889856 },
    { lat: 48.945282, lng: 17.889752 },
    { lat: 48.945123, lng: 17.889632 },
    { lat: 48.94502, lng: 17.889552 },
    { lat: 48.944981, lng: 17.889453 },
    { lat: 48.944883, lng: 17.889418 },
    { lat: 48.944796, lng: 17.889325 },
    { lat: 48.944673, lng: 17.889317 },
    { lat: 48.944458, lng: 17.889203 },
    { lat: 48.944363, lng: 17.889117 },
    { lat: 48.944259, lng: 17.889063 },
    { lat: 48.944075, lng: 17.888927 },
    { lat: 48.943885, lng: 17.888908 },
    { lat: 48.943716, lng: 17.888892 },
    { lat: 48.94364, lng: 17.888885 },
    { lat: 48.943333, lng: 17.888851 },
    { lat: 48.943192, lng: 17.888835 },
    { lat: 48.942971, lng: 17.888817 },
    { lat: 48.942704, lng: 17.888737 },
    { lat: 48.942284, lng: 17.888612 },
    { lat: 48.941967, lng: 17.888529 },
    { lat: 48.941677, lng: 17.888439 },
    { lat: 48.94141, lng: 17.888425 },
    { lat: 48.941275, lng: 17.888463 },
    { lat: 48.941155, lng: 17.888481 },
    { lat: 48.940952, lng: 17.888485 },
    { lat: 48.940901, lng: 17.888478 },
    { lat: 48.940787, lng: 17.888461 },
    { lat: 48.940678, lng: 17.888411 },
    { lat: 48.940494, lng: 17.888394 },
    { lat: 48.940216, lng: 17.88828 },
    { lat: 48.940184, lng: 17.888162 },
    { lat: 48.940091, lng: 17.888083 },
    { lat: 48.940004, lng: 17.888095 },
    { lat: 48.939853, lng: 17.888042 },
    { lat: 48.939769, lng: 17.887943 },
    { lat: 48.939308, lng: 17.887974 },
    { lat: 48.939133, lng: 17.887927 },
    { lat: 48.938956, lng: 17.887802 },
    { lat: 48.93889, lng: 17.887793 },
    { lat: 48.938825, lng: 17.887785 },
    { lat: 48.938593, lng: 17.887753 },
    { lat: 48.938421, lng: 17.887688 },
    { lat: 48.938246, lng: 17.887621 },
    { lat: 48.937921, lng: 17.887543 },
    { lat: 48.9377, lng: 17.887533 },
    { lat: 48.937331, lng: 17.887446 },
    { lat: 48.937036, lng: 17.887294 },
    { lat: 48.936873, lng: 17.887222 },
    { lat: 48.936674, lng: 17.887142 },
    { lat: 48.936667, lng: 17.887139 },
    { lat: 48.936465, lng: 17.887057 },
    { lat: 48.936403, lng: 17.887035 },
    { lat: 48.936206, lng: 17.886968 },
    { lat: 48.935945, lng: 17.886922 },
    { lat: 48.935855, lng: 17.886891 },
    { lat: 48.935776, lng: 17.886863 },
    { lat: 48.935629, lng: 17.88681 },
    { lat: 48.935137, lng: 17.886494 },
    { lat: 48.93477, lng: 17.886354 },
    { lat: 48.934734, lng: 17.886338 },
    { lat: 48.934468, lng: 17.886223 },
    { lat: 48.934271, lng: 17.886274 },
    { lat: 48.934119, lng: 17.886313 },
    { lat: 48.933252, lng: 17.886311 },
    { lat: 48.932981, lng: 17.886286 },
    { lat: 48.932934, lng: 17.886282 },
    { lat: 48.932686, lng: 17.886372 },
    { lat: 48.932613, lng: 17.886398 },
    { lat: 48.932194, lng: 17.886523 },
    { lat: 48.931665, lng: 17.886503 },
    { lat: 48.931121, lng: 17.886443 },
    { lat: 48.93061, lng: 17.886388 },
    { lat: 48.930349, lng: 17.886366 },
    { lat: 48.930187, lng: 17.886352 },
    { lat: 48.930024, lng: 17.886339 },
    { lat: 48.929331, lng: 17.886221 },
    { lat: 48.929083, lng: 17.886277 },
    { lat: 48.929009, lng: 17.886294 },
    { lat: 48.928948, lng: 17.886307 },
    { lat: 48.928563, lng: 17.886105 },
    { lat: 48.928176, lng: 17.885757 },
    { lat: 48.927899, lng: 17.885512 },
    { lat: 48.927677, lng: 17.885311 },
    { lat: 48.9276366, lng: 17.885316 },
    { lat: 48.9275806, lng: 17.8859078 },
    { lat: 48.927559, lng: 17.8861111 },
    { lat: 48.9275492, lng: 17.8862119 },
    { lat: 48.927525, lng: 17.8862985 },
    { lat: 48.9274837, lng: 17.8864532 },
    { lat: 48.927391, lng: 17.8868077 },
    { lat: 48.9271429, lng: 17.8873956 },
    { lat: 48.9269036, lng: 17.8878461 },
    { lat: 48.926863, lng: 17.8879221 },
    { lat: 48.9268078, lng: 17.8880001 },
    { lat: 48.9266892, lng: 17.8881672 },
    { lat: 48.9262827, lng: 17.8885992 },
    { lat: 48.9262042, lng: 17.8887379 },
    { lat: 48.9261582, lng: 17.8888196 },
    { lat: 48.9259548, lng: 17.8893427 },
    { lat: 48.9258369, lng: 17.8895593 },
    { lat: 48.925782, lng: 17.8896613 },
    { lat: 48.9251641, lng: 17.8900306 },
    { lat: 48.9250609, lng: 17.8900955 },
    { lat: 48.9249287, lng: 17.8901719 },
    { lat: 48.9248274, lng: 17.89023 },
    { lat: 48.9247727, lng: 17.8902606 },
    { lat: 48.9247085, lng: 17.890282 },
    { lat: 48.9246354, lng: 17.8903061 },
    { lat: 48.9244989, lng: 17.8903249 },
    { lat: 48.9243675, lng: 17.8903187 },
    { lat: 48.9243403, lng: 17.8903149 },
    { lat: 48.9243167, lng: 17.8903347 },
    { lat: 48.9243064, lng: 17.8903892 },
    { lat: 48.9241066, lng: 17.890485 },
    { lat: 48.9240473, lng: 17.8905137 },
    { lat: 48.9240027, lng: 17.8905776 },
    { lat: 48.9236543, lng: 17.8910716 },
    { lat: 48.9236146, lng: 17.8911273 },
    { lat: 48.92364, lng: 17.8911715 },
    { lat: 48.923509, lng: 17.8913422 },
    { lat: 48.923461, lng: 17.8914055 },
    { lat: 48.9234, lng: 17.8914452 },
    { lat: 48.9232252, lng: 17.8915591 },
    { lat: 48.9230181, lng: 17.891663 },
    { lat: 48.9228373, lng: 17.8916981 },
    { lat: 48.9227805, lng: 17.8917088 },
    { lat: 48.9227272, lng: 17.8917431 },
    { lat: 48.922588, lng: 17.8918312 },
    { lat: 48.922486, lng: 17.8919763 },
    { lat: 48.9224225, lng: 17.8920665 },
    { lat: 48.9221323, lng: 17.8923464 },
    { lat: 48.9220694, lng: 17.8924064 },
    { lat: 48.9220053, lng: 17.8924486 },
    { lat: 48.9215309, lng: 17.8927618 },
    { lat: 48.9211111, lng: 17.8931809 },
    { lat: 48.9209208, lng: 17.8933564 },
    { lat: 48.920832, lng: 17.8934373 },
    { lat: 48.9207327, lng: 17.8934933 },
    { lat: 48.9203526, lng: 17.8937062 },
    { lat: 48.9201654, lng: 17.8939539 },
    { lat: 48.9189179, lng: 17.8955319 },
    { lat: 48.918873, lng: 17.8955356 },
    { lat: 48.9184673, lng: 17.8959251 },
    { lat: 48.9183968, lng: 17.8959933 },
    { lat: 48.9183609, lng: 17.8960876 },
    { lat: 48.9180531, lng: 17.8968841 },
    { lat: 48.9180191, lng: 17.8970708 },
    { lat: 48.9179868, lng: 17.89725 },
    { lat: 48.9179726, lng: 17.8973334 },
    { lat: 48.917925, lng: 17.8974229 },
    { lat: 48.9178643, lng: 17.897539 },
    { lat: 48.9177843, lng: 17.8976918 },
    { lat: 48.9176605, lng: 17.8978686 },
    { lat: 48.9175785, lng: 17.8979875 },
    { lat: 48.9174365, lng: 17.8981446 },
    { lat: 48.9173868, lng: 17.8981992 },
    { lat: 48.9173201, lng: 17.8982349 },
    { lat: 48.9171842, lng: 17.8982905 },
  ],
  HornáSúča: [
    { lat: 49.0279633, lng: 17.9932528 },
    { lat: 49.0279921, lng: 17.993138 },
    { lat: 49.0283512, lng: 17.9915502 },
    { lat: 49.0284954, lng: 17.9909141 },
    { lat: 49.0283745, lng: 17.9901351 },
    { lat: 49.0282275, lng: 17.9891882 },
    { lat: 49.0280725, lng: 17.9882067 },
    { lat: 49.0279257, lng: 17.9872796 },
    { lat: 49.0277225, lng: 17.986597 },
    { lat: 49.0274312, lng: 17.9856208 },
    { lat: 49.0270304, lng: 17.9848276 },
    { lat: 49.0265776, lng: 17.983926 },
    { lat: 49.0258115, lng: 17.9832857 },
    { lat: 49.0252687, lng: 17.9828287 },
    { lat: 49.0246777, lng: 17.9821652 },
    { lat: 49.0240705, lng: 17.9814823 },
    { lat: 49.0237888, lng: 17.9811666 },
    { lat: 49.0235673, lng: 17.980502 },
    { lat: 49.0232359, lng: 17.9795081 },
    { lat: 49.0230135, lng: 17.9781095 },
    { lat: 49.022893, lng: 17.9773531 },
    { lat: 49.022748, lng: 17.9764202 },
    { lat: 49.0226405, lng: 17.9757274 },
    { lat: 49.022551, lng: 17.9745923 },
    { lat: 49.0226955, lng: 17.973878 },
    { lat: 49.0228544, lng: 17.9730854 },
    { lat: 49.0230661, lng: 17.972162 },
    { lat: 49.0233855, lng: 17.9707664 },
    { lat: 49.0236273, lng: 17.9696612 },
    { lat: 49.0238372, lng: 17.9687011 },
    { lat: 49.0239809, lng: 17.9676933 },
    { lat: 49.0241411, lng: 17.966569 },
    { lat: 49.0242332, lng: 17.9655099 },
    { lat: 49.0243345, lng: 17.9643264 },
    { lat: 49.0242962, lng: 17.963554 },
    { lat: 49.0242552, lng: 17.9627239 },
    { lat: 49.0242189, lng: 17.9619905 },
    { lat: 49.0241462, lng: 17.9608541 },
    { lat: 49.0240749, lng: 17.9597663 },
    { lat: 49.0241933, lng: 17.9576849 },
    { lat: 49.0245315, lng: 17.9556835 },
    { lat: 49.0250397, lng: 17.9535006 },
    { lat: 49.0250187, lng: 17.9512845 },
    { lat: 49.0247057, lng: 17.9504867 },
    { lat: 49.0242198, lng: 17.9492383 },
    { lat: 49.0236732, lng: 17.9474846 },
    { lat: 49.0235857, lng: 17.9471301 },
    { lat: 49.023516, lng: 17.94636 },
    { lat: 49.0232742, lng: 17.9450766 },
    { lat: 49.0234038, lng: 17.943457 },
    { lat: 49.0233469, lng: 17.9422272 },
    { lat: 49.0234693, lng: 17.9397078 },
    { lat: 49.0234783, lng: 17.9392746 },
    { lat: 49.0234624, lng: 17.9376748 },
    { lat: 49.0234802, lng: 17.93723 },
    { lat: 49.023579, lng: 17.9350807 },
    { lat: 49.0234631, lng: 17.9346787 },
    { lat: 49.0233599, lng: 17.9340866 },
    { lat: 49.0231447, lng: 17.9334743 },
    { lat: 49.0226786, lng: 17.9324299 },
    { lat: 49.0222163, lng: 17.9314447 },
    { lat: 49.022179, lng: 17.9313493 },
    { lat: 49.0219642, lng: 17.9309088 },
    { lat: 49.0217316, lng: 17.9303414 },
    { lat: 49.0215235, lng: 17.9298209 },
    { lat: 49.0212029, lng: 17.9290067 },
    { lat: 49.020794, lng: 17.9282025 },
    { lat: 49.020689, lng: 17.9279177 },
    { lat: 49.0204005, lng: 17.9270788 },
    { lat: 49.0203895, lng: 17.9270433 },
    { lat: 49.0203311, lng: 17.9268573 },
    { lat: 49.020046, lng: 17.9259252 },
    { lat: 49.0200284, lng: 17.9256332 },
    { lat: 49.0199986, lng: 17.9249301 },
    { lat: 49.0200003, lng: 17.9245934 },
    { lat: 49.0199608, lng: 17.924314 },
    { lat: 49.0189837, lng: 17.9237857 },
    { lat: 49.0185834, lng: 17.9234642 },
    { lat: 49.0184567, lng: 17.9232616 },
    { lat: 49.0183758, lng: 17.9230365 },
    { lat: 49.0179641, lng: 17.9222557 },
    { lat: 49.0177709, lng: 17.9217825 },
    { lat: 49.0175203, lng: 17.9215089 },
    { lat: 49.0167658, lng: 17.9210399 },
    { lat: 49.0164279, lng: 17.92095 },
    { lat: 49.0159712, lng: 17.9209401 },
    { lat: 49.0156365, lng: 17.9209783 },
    { lat: 49.0154785, lng: 17.9209119 },
    { lat: 49.0154062, lng: 17.9208785 },
    { lat: 49.0152158, lng: 17.9206421 },
    { lat: 49.0148774, lng: 17.9200661 },
    { lat: 49.0146415, lng: 17.9195822 },
    { lat: 49.0145655, lng: 17.9194589 },
    { lat: 49.0140351, lng: 17.9185352 },
    { lat: 49.0136261, lng: 17.9175894 },
    { lat: 49.0135643, lng: 17.9174606 },
    { lat: 49.0131315, lng: 17.9168407 },
    { lat: 49.0129065, lng: 17.9165641 },
    { lat: 49.012514, lng: 17.9162188 },
    { lat: 49.0119985, lng: 17.9156599 },
    { lat: 49.0112753, lng: 17.914818 },
    { lat: 49.0109181, lng: 17.9138869 },
    { lat: 49.0099219, lng: 17.9138004 },
    { lat: 49.0095056, lng: 17.9138641 },
    { lat: 49.0091513, lng: 17.9136968 },
    { lat: 49.0084795, lng: 17.9133803 },
    { lat: 49.007784, lng: 17.9130538 },
    { lat: 49.0061513, lng: 17.9122085 },
    { lat: 49.0057998, lng: 17.9120909 },
    { lat: 49.0055276, lng: 17.9121321 },
    { lat: 49.0052212, lng: 17.9122119 },
    { lat: 49.0045783, lng: 17.9127778 },
    { lat: 49.0038613, lng: 17.9133492 },
    { lat: 49.0029118, lng: 17.9133659 },
    { lat: 49.0023648, lng: 17.9131208 },
    { lat: 49.002063, lng: 17.9130665 },
    { lat: 49.0019187, lng: 17.9130826 },
    { lat: 49.001336, lng: 17.9131503 },
    { lat: 49.0011451, lng: 17.9133109 },
    { lat: 49.0004679, lng: 17.9136174 },
    { lat: 48.9995665, lng: 17.9139211 },
    { lat: 48.9993619, lng: 17.9139463 },
    { lat: 48.9988553, lng: 17.9139999 },
    { lat: 48.9982568, lng: 17.9140814 },
    { lat: 48.9979744, lng: 17.9142293 },
    { lat: 48.9975011, lng: 17.9144862 },
    { lat: 48.9973945, lng: 17.9144853 },
    { lat: 48.997382, lng: 17.914482 },
    { lat: 48.996422, lng: 17.914474 },
    { lat: 48.995781, lng: 17.91417 },
    { lat: 48.995328, lng: 17.913939 },
    { lat: 48.994853, lng: 17.913747 },
    { lat: 48.99437, lng: 17.913404 },
    { lat: 48.994088, lng: 17.913309 },
    { lat: 48.993181, lng: 17.913336 },
    { lat: 48.992636, lng: 17.913112 },
    { lat: 48.992287, lng: 17.912798 },
    { lat: 48.991738, lng: 17.912413 },
    { lat: 48.991423, lng: 17.912286 },
    { lat: 48.990875, lng: 17.912135 },
    { lat: 48.990461, lng: 17.911774 },
    { lat: 48.990178, lng: 17.911513 },
    { lat: 48.989768, lng: 17.910758 },
    { lat: 48.989553, lng: 17.910331 },
    { lat: 48.98941, lng: 17.910149 },
    { lat: 48.989314, lng: 17.910025 },
    { lat: 48.989137, lng: 17.909865 },
    { lat: 48.98893, lng: 17.909676 },
    { lat: 48.988919, lng: 17.909667 },
    { lat: 48.988679, lng: 17.909307 },
    { lat: 48.988539, lng: 17.909095 },
    { lat: 48.988104, lng: 17.908289 },
    { lat: 48.987662, lng: 17.907813 },
    { lat: 48.987378, lng: 17.907439 },
    { lat: 48.987178, lng: 17.907106 },
    { lat: 48.987169, lng: 17.907093 },
    { lat: 48.9867, lng: 17.906558 },
    { lat: 48.986538, lng: 17.906365 },
    { lat: 48.986354, lng: 17.906146 },
    { lat: 48.986126, lng: 17.905872 },
    { lat: 48.985999, lng: 17.905638 },
    { lat: 48.985932, lng: 17.905513 },
    { lat: 48.985915, lng: 17.905504 },
    { lat: 48.98589, lng: 17.905492 },
    { lat: 48.985589, lng: 17.905338 },
    { lat: 48.985091, lng: 17.904624 },
    { lat: 48.98488, lng: 17.904362 },
    { lat: 48.984875, lng: 17.904355 },
    { lat: 48.984572, lng: 17.904104 },
    { lat: 48.984223, lng: 17.903868 },
    { lat: 48.98372, lng: 17.903584 },
    { lat: 48.983718, lng: 17.903583 },
    { lat: 48.983436, lng: 17.903475 },
    { lat: 48.983431, lng: 17.903473 },
    { lat: 48.983374, lng: 17.903444 },
    { lat: 48.983092, lng: 17.9033 },
    { lat: 48.98262, lng: 17.903066 },
    { lat: 48.982146, lng: 17.902883 },
    { lat: 48.981814, lng: 17.902729 },
    { lat: 48.981199, lng: 17.902566 },
    { lat: 48.980591, lng: 17.902454 },
    { lat: 48.980153, lng: 17.902489 },
    { lat: 48.979834, lng: 17.902476 },
    { lat: 48.979517, lng: 17.902394 },
    { lat: 48.979207, lng: 17.902302 },
    { lat: 48.978824, lng: 17.902183 },
    { lat: 48.978611, lng: 17.9021 },
    { lat: 48.978405, lng: 17.902033 },
    { lat: 48.977758, lng: 17.90184 },
    { lat: 48.976999, lng: 17.901742 },
    { lat: 48.97684, lng: 17.901765 },
    { lat: 48.9767239, lng: 17.9017338 },
    { lat: 48.9764887, lng: 17.9016417 },
    { lat: 48.9763872, lng: 17.9015632 },
    { lat: 48.9762997, lng: 17.9014062 },
    { lat: 48.9760184, lng: 17.9017912 },
    { lat: 48.9760125, lng: 17.901843 },
    { lat: 48.9759558, lng: 17.9023115 },
    { lat: 48.9752568, lng: 17.9031173 },
    { lat: 48.9751412, lng: 17.903245 },
    { lat: 48.9750927, lng: 17.9032987 },
    { lat: 48.9746321, lng: 17.9038082 },
    { lat: 48.9738033, lng: 17.9047461 },
    { lat: 48.9729209, lng: 17.9057158 },
    { lat: 48.9719989, lng: 17.9067372 },
    { lat: 48.9713932, lng: 17.9074413 },
    { lat: 48.9711112, lng: 17.9077644 },
    { lat: 48.9710507, lng: 17.9078327 },
    { lat: 48.9707075, lng: 17.9082246 },
    { lat: 48.9700964, lng: 17.9089189 },
    { lat: 48.9700658, lng: 17.9089533 },
    { lat: 48.9699835, lng: 17.9090476 },
    { lat: 48.9699232, lng: 17.9091152 },
    { lat: 48.9692705, lng: 17.9098505 },
    { lat: 48.9686305, lng: 17.9105875 },
    { lat: 48.968315, lng: 17.9109554 },
    { lat: 48.9681727, lng: 17.9111238 },
    { lat: 48.9681389, lng: 17.9111636 },
    { lat: 48.9681381, lng: 17.9111652 },
    { lat: 48.9681169, lng: 17.9111904 },
    { lat: 48.9677859, lng: 17.9115809 },
    { lat: 48.9672403, lng: 17.9122546 },
    { lat: 48.9665987, lng: 17.9130762 },
    { lat: 48.9665482, lng: 17.9131414 },
    { lat: 48.9662273, lng: 17.9135513 },
    { lat: 48.9658948, lng: 17.9139747 },
    { lat: 48.9651252, lng: 17.9149425 },
    { lat: 48.964368, lng: 17.9158723 },
    { lat: 48.9643471, lng: 17.9158982 },
    { lat: 48.9643399, lng: 17.9159074 },
    { lat: 48.9638232, lng: 17.9165555 },
    { lat: 48.9637558, lng: 17.9166388 },
    { lat: 48.9637265, lng: 17.9166759 },
    { lat: 48.9636857, lng: 17.9167278 },
    { lat: 48.9631571, lng: 17.9173862 },
    { lat: 48.9626142, lng: 17.9180471 },
    { lat: 48.962077, lng: 17.9186926 },
    { lat: 48.961693, lng: 17.9191428 },
    { lat: 48.9612156, lng: 17.9196557 },
    { lat: 48.9608772, lng: 17.920018 },
    { lat: 48.9605368, lng: 17.9203845 },
    { lat: 48.9605061, lng: 17.9204171 },
    { lat: 48.9604828, lng: 17.920443 },
    { lat: 48.9603976, lng: 17.9205301 },
    { lat: 48.9600254, lng: 17.9209122 },
    { lat: 48.9595557, lng: 17.9213841 },
    { lat: 48.959119, lng: 17.9219694 },
    { lat: 48.9586231, lng: 17.9227596 },
    { lat: 48.9580971, lng: 17.9235927 },
    { lat: 48.9576151, lng: 17.9243471 },
    { lat: 48.9570898, lng: 17.9251521 },
    { lat: 48.9565797, lng: 17.9258953 },
    { lat: 48.9560838, lng: 17.9267012 },
    { lat: 48.9556234, lng: 17.9274024 },
    { lat: 48.9556006, lng: 17.9274368 },
    { lat: 48.955466, lng: 17.927642 },
    { lat: 48.9553398, lng: 17.9278544 },
    { lat: 48.9548876, lng: 17.9285799 },
    { lat: 48.9543501, lng: 17.929463 },
    { lat: 48.9538815, lng: 17.9301447 },
    { lat: 48.9538037, lng: 17.9302405 },
    { lat: 48.9533045, lng: 17.9309362 },
    { lat: 48.9530425, lng: 17.931216 },
    { lat: 48.9530105, lng: 17.9312495 },
    { lat: 48.9527421, lng: 17.9315364 },
    { lat: 48.9521236, lng: 17.9322006 },
    { lat: 48.9516248, lng: 17.9327313 },
    { lat: 48.951009, lng: 17.9327017 },
    { lat: 48.9505483, lng: 17.9326785 },
    { lat: 48.9503464, lng: 17.9327396 },
    { lat: 48.9502787, lng: 17.9327582 },
    { lat: 48.9502086, lng: 17.9327769 },
    { lat: 48.9501721, lng: 17.9327873 },
    { lat: 48.9501498, lng: 17.9327939 },
    { lat: 48.9499002, lng: 17.9328645 },
    { lat: 48.9493083, lng: 17.933065 },
    { lat: 48.9489441, lng: 17.9332051 },
    { lat: 48.9489155, lng: 17.933217 },
    { lat: 48.948878, lng: 17.9332347 },
    { lat: 48.9486239, lng: 17.9333253 },
    { lat: 48.9479109, lng: 17.9335963 },
    { lat: 48.9472843, lng: 17.9338579 },
    { lat: 48.946847, lng: 17.9340207 },
    { lat: 48.94671, lng: 17.9340718 },
    { lat: 48.9466427, lng: 17.9341931 },
    { lat: 48.9466276, lng: 17.934221 },
    { lat: 48.9462906, lng: 17.9348368 },
    { lat: 48.9458548, lng: 17.9356292 },
    { lat: 48.9453365, lng: 17.9365923 },
    { lat: 48.9449074, lng: 17.9373859 },
    { lat: 48.9445857, lng: 17.9379941 },
    { lat: 48.9445717, lng: 17.9380251 },
    { lat: 48.944553, lng: 17.9380679 },
    { lat: 48.9445101, lng: 17.9381625 },
    { lat: 48.9443527, lng: 17.938513 },
    { lat: 48.9438155, lng: 17.9394269 },
    { lat: 48.9434252, lng: 17.9401526 },
    { lat: 48.942958, lng: 17.9412314 },
    { lat: 48.9425693, lng: 17.9422686 },
    { lat: 48.9423521, lng: 17.9428104 },
    { lat: 48.9423334, lng: 17.9428585 },
    { lat: 48.9422912, lng: 17.9429641 },
    { lat: 48.9419032, lng: 17.9439362 },
    { lat: 48.9418494, lng: 17.9440728 },
    { lat: 48.9416025, lng: 17.9447063 },
    { lat: 48.9413487, lng: 17.9453733 },
    { lat: 48.9409479, lng: 17.9461467 },
    { lat: 48.9405339, lng: 17.946667 },
    { lat: 48.9401309, lng: 17.9471398 },
    { lat: 48.9397595, lng: 17.947572 },
    { lat: 48.9394086, lng: 17.9479762 },
    { lat: 48.9393938, lng: 17.9479933 },
    { lat: 48.9393602, lng: 17.9480331 },
    { lat: 48.9393518, lng: 17.9480429 },
    { lat: 48.9393423, lng: 17.9480535 },
    { lat: 48.9393358, lng: 17.9480609 },
    { lat: 48.9392916, lng: 17.9481122 },
    { lat: 48.9392734, lng: 17.9481319 },
    { lat: 48.9392554, lng: 17.9481527 },
    { lat: 48.9392494, lng: 17.9481618 },
    { lat: 48.9392325, lng: 17.9481849 },
    { lat: 48.9385583, lng: 17.9491091 },
    { lat: 48.9380237, lng: 17.9498361 },
    { lat: 48.9376223, lng: 17.950392 },
    { lat: 48.9374093, lng: 17.9506847 },
    { lat: 48.9376351, lng: 17.9514242 },
    { lat: 48.9377299, lng: 17.9519206 },
    { lat: 48.9379731, lng: 17.9528204 },
    { lat: 48.938171, lng: 17.9538147 },
    { lat: 48.9383431, lng: 17.9543749 },
    { lat: 48.9385054, lng: 17.9549017 },
    { lat: 48.9386954, lng: 17.955309 },
    { lat: 48.9386987, lng: 17.955441 },
    { lat: 48.9395165, lng: 17.955412 },
    { lat: 48.9396568, lng: 17.9557587 },
    { lat: 48.9397673, lng: 17.9560254 },
    { lat: 48.9409151, lng: 17.9569741 },
    { lat: 48.9409106, lng: 17.9571259 },
    { lat: 48.9409057, lng: 17.9572705 },
    { lat: 48.9408897, lng: 17.9577804 },
    { lat: 48.9408932, lng: 17.957804 },
    { lat: 48.9411315, lng: 17.9579778 },
    { lat: 48.9416795, lng: 17.9582877 },
    { lat: 48.9418641, lng: 17.9584143 },
    { lat: 48.9423521, lng: 17.9585544 },
    { lat: 48.9426617, lng: 17.9586546 },
    { lat: 48.9428823, lng: 17.9587971 },
    { lat: 48.9430208, lng: 17.9589261 },
    { lat: 48.9431366, lng: 17.9591081 },
    { lat: 48.9432528, lng: 17.9592415 },
    { lat: 48.9444682, lng: 17.9598465 },
    { lat: 48.9444634, lng: 17.9598773 },
    { lat: 48.9445641, lng: 17.9599192 },
    { lat: 48.9446717, lng: 17.9599634 },
    { lat: 48.9449291, lng: 17.9600596 },
    { lat: 48.9459074, lng: 17.9604198 },
    { lat: 48.9459943, lng: 17.9604568 },
    { lat: 48.9460891, lng: 17.9604926 },
    { lat: 48.9462333, lng: 17.9605465 },
    { lat: 48.9462817, lng: 17.9605615 },
    { lat: 48.9463912, lng: 17.9606021 },
    { lat: 48.9465021, lng: 17.9606444 },
    { lat: 48.946554, lng: 17.9606621 },
    { lat: 48.9466142, lng: 17.960688 },
    { lat: 48.9466789, lng: 17.9607143 },
    { lat: 48.9467439, lng: 17.9607428 },
    { lat: 48.9468211, lng: 17.960771 },
    { lat: 48.9468907, lng: 17.9608013 },
    { lat: 48.946901, lng: 17.9607669 },
    { lat: 48.9472103, lng: 17.9610528 },
    { lat: 48.9473959, lng: 17.9612906 },
    { lat: 48.9476296, lng: 17.9616694 },
    { lat: 48.947808, lng: 17.9619786 },
    { lat: 48.9478006, lng: 17.9620048 },
    { lat: 48.9477926, lng: 17.962077 },
    { lat: 48.9477954, lng: 17.9621053 },
    { lat: 48.9475844, lng: 17.9621636 },
    { lat: 48.9469524, lng: 17.9622992 },
    { lat: 48.9468502, lng: 17.9623067 },
    { lat: 48.9466459, lng: 17.9622869 },
    { lat: 48.9463302, lng: 17.9623883 },
    { lat: 48.9463376, lng: 17.9624163 },
    { lat: 48.9463671, lng: 17.9625351 },
    { lat: 48.9463876, lng: 17.9626131 },
    { lat: 48.9464247, lng: 17.9627642 },
    { lat: 48.9464882, lng: 17.9629581 },
    { lat: 48.9465686, lng: 17.9632131 },
    { lat: 48.9466722, lng: 17.9635322 },
    { lat: 48.9467995, lng: 17.9639439 },
    { lat: 48.9469529, lng: 17.9639416 },
    { lat: 48.9470471, lng: 17.9641032 },
    { lat: 48.9470603, lng: 17.9644418 },
    { lat: 48.9473272, lng: 17.9647339 },
    { lat: 48.947561, lng: 17.9654226 },
    { lat: 48.9477174, lng: 17.9655872 },
    { lat: 48.9477683, lng: 17.9657034 },
    { lat: 48.9479167, lng: 17.9660164 },
    { lat: 48.9480316, lng: 17.9669444 },
    { lat: 48.9476685, lng: 17.9665333 },
    { lat: 48.9474222, lng: 17.9662247 },
    { lat: 48.9472922, lng: 17.9660497 },
    { lat: 48.9472804, lng: 17.9660987 },
    { lat: 48.9467313, lng: 17.9666991 },
    { lat: 48.9464024, lng: 17.9668999 },
    { lat: 48.9466247, lng: 17.9672778 },
    { lat: 48.9466975, lng: 17.9674014 },
    { lat: 48.94671, lng: 17.9674237 },
    { lat: 48.9467543, lng: 17.9676163 },
    { lat: 48.9468, lng: 17.9678366 },
    { lat: 48.9468512, lng: 17.9680705 },
    { lat: 48.9468879, lng: 17.9682555 },
    { lat: 48.9469219, lng: 17.9692631 },
    { lat: 48.9463724, lng: 17.9692505 },
    { lat: 48.9462834, lng: 17.969727 },
    { lat: 48.9461096, lng: 17.970587 },
    { lat: 48.946279, lng: 17.970855 },
    { lat: 48.9462976, lng: 17.9708816 },
    { lat: 48.9463664, lng: 17.9708058 },
    { lat: 48.9464566, lng: 17.9708225 },
    { lat: 48.9466093, lng: 17.9710013 },
    { lat: 48.9467638, lng: 17.971093 },
    { lat: 48.9469337, lng: 17.9712741 },
    { lat: 48.9470069, lng: 17.9713643 },
    { lat: 48.9471196, lng: 17.9714788 },
    { lat: 48.9474414, lng: 17.9716884 },
    { lat: 48.9476444, lng: 17.9718022 },
    { lat: 48.9479994, lng: 17.9718097 },
    { lat: 48.9481786, lng: 17.9719953 },
    { lat: 48.9482709, lng: 17.9721453 },
    { lat: 48.9485459, lng: 17.9720844 },
    { lat: 48.9487278, lng: 17.9720833 },
    { lat: 48.9488567, lng: 17.97186 },
    { lat: 48.9488814, lng: 17.9716864 },
    { lat: 48.9490626, lng: 17.9715072 },
    { lat: 48.9491979, lng: 17.9712758 },
    { lat: 48.9493656, lng: 17.9711922 },
    { lat: 48.9494254, lng: 17.9710756 },
    { lat: 48.9494921, lng: 17.9710205 },
    { lat: 48.9495891, lng: 17.9709046 },
    { lat: 48.9496288, lng: 17.9708126 },
    { lat: 48.94972, lng: 17.9707355 },
    { lat: 48.9499361, lng: 17.9707627 },
    { lat: 48.9501479, lng: 17.9705083 },
    { lat: 48.950312, lng: 17.9705914 },
    { lat: 48.9506615, lng: 17.970678 },
    { lat: 48.9508838, lng: 17.9706986 },
    { lat: 48.9510114, lng: 17.9706244 },
    { lat: 48.951279, lng: 17.9707226 },
    { lat: 48.9515567, lng: 17.9707381 },
    { lat: 48.9518494, lng: 17.9708242 },
    { lat: 48.9520151, lng: 17.9709158 },
    { lat: 48.9522123, lng: 17.9712336 },
    { lat: 48.9521985, lng: 17.9712674 },
    { lat: 48.9520911, lng: 17.9715679 },
    { lat: 48.9521216, lng: 17.9720662 },
    { lat: 48.9522046, lng: 17.9725335 },
    { lat: 48.9522443, lng: 17.9728966 },
    { lat: 48.9523136, lng: 17.9730002 },
    { lat: 48.9525567, lng: 17.973083 },
    { lat: 48.9527154, lng: 17.9731357 },
    { lat: 48.9529843, lng: 17.9730835 },
    { lat: 48.9532247, lng: 17.9729843 },
    { lat: 48.9533235, lng: 17.9730351 },
    { lat: 48.9533352, lng: 17.9730816 },
    { lat: 48.9534455, lng: 17.9735499 },
    { lat: 48.9532528, lng: 17.9739953 },
    { lat: 48.9531484, lng: 17.9741561 },
    { lat: 48.9533685, lng: 17.974311 },
    { lat: 48.9534977, lng: 17.9743964 },
    { lat: 48.9535349, lng: 17.9744894 },
    { lat: 48.9538004, lng: 17.9748553 },
    { lat: 48.954036, lng: 17.9750225 },
    { lat: 48.9539959, lng: 17.9751242 },
    { lat: 48.9539398, lng: 17.9752482 },
    { lat: 48.9538667, lng: 17.9754237 },
    { lat: 48.9538268, lng: 17.9755122 },
    { lat: 48.953797, lng: 17.9755805 },
    { lat: 48.953792, lng: 17.9755916 },
    { lat: 48.9537697, lng: 17.9756496 },
    { lat: 48.9537408, lng: 17.9757171 },
    { lat: 48.9537209, lng: 17.9757611 },
    { lat: 48.9536831, lng: 17.9758499 },
    { lat: 48.9543237, lng: 17.9765274 },
    { lat: 48.9542794, lng: 17.9765981 },
    { lat: 48.9542369, lng: 17.9766628 },
    { lat: 48.9541865, lng: 17.9767469 },
    { lat: 48.9545992, lng: 17.9774613 },
    { lat: 48.9545825, lng: 17.9774873 },
    { lat: 48.954563, lng: 17.9775169 },
    { lat: 48.9545455, lng: 17.9775451 },
    { lat: 48.9545373, lng: 17.9775628 },
    { lat: 48.9545235, lng: 17.9775776 },
    { lat: 48.9544887, lng: 17.9776373 },
    { lat: 48.9543986, lng: 17.977772 },
    { lat: 48.9543603, lng: 17.9778333 },
    { lat: 48.9543139, lng: 17.9779042 },
    { lat: 48.9542497, lng: 17.9780024 },
    { lat: 48.9541257, lng: 17.978196 },
    { lat: 48.9540905, lng: 17.9782497 },
    { lat: 48.9544504, lng: 17.978819 },
    { lat: 48.9548622, lng: 17.97878 },
    { lat: 48.9555284, lng: 17.978748 },
    { lat: 48.9556028, lng: 17.9790503 },
    { lat: 48.955538, lng: 17.9792341 },
    { lat: 48.9555698, lng: 17.9797311 },
    { lat: 48.9555261, lng: 17.9805189 },
    { lat: 48.9555218, lng: 17.9806746 },
    { lat: 48.9554774, lng: 17.9814309 },
    { lat: 48.9554875, lng: 17.9817743 },
    { lat: 48.9555112, lng: 17.9819068 },
    { lat: 48.9555433, lng: 17.9819545 },
    { lat: 48.9556148, lng: 17.9821161 },
    { lat: 48.9556326, lng: 17.9821891 },
    { lat: 48.9556355, lng: 17.982217 },
    { lat: 48.9556573, lng: 17.9823182 },
    { lat: 48.9557018, lng: 17.9824978 },
    { lat: 48.9556732, lng: 17.9826425 },
    { lat: 48.9556472, lng: 17.9826985 },
    { lat: 48.9556227, lng: 17.9827449 },
    { lat: 48.9556264, lng: 17.982883 },
    { lat: 48.9557109, lng: 17.9830525 },
    { lat: 48.9557851, lng: 17.98318 },
    { lat: 48.9556796, lng: 17.9835546 },
    { lat: 48.9556877, lng: 17.983556 },
    { lat: 48.9556769, lng: 17.9836806 },
    { lat: 48.9556713, lng: 17.9837385 },
    { lat: 48.9556389, lng: 17.9839544 },
    { lat: 48.9555852, lng: 17.9840943 },
    { lat: 48.9556081, lng: 17.9843099 },
    { lat: 48.955554, lng: 17.9845794 },
    { lat: 48.9555124, lng: 17.9847151 },
    { lat: 48.9554836, lng: 17.9848002 },
    { lat: 48.9554728, lng: 17.9848462 },
    { lat: 48.9554936, lng: 17.9848732 },
    { lat: 48.9555701, lng: 17.9850035 },
    { lat: 48.9552948, lng: 17.9853118 },
    { lat: 48.9556911, lng: 17.9859265 },
    { lat: 48.9558528, lng: 17.9866931 },
    { lat: 48.9557483, lng: 17.9868539 },
    { lat: 48.9551924, lng: 17.9870554 },
    { lat: 48.954632, lng: 17.9872136 },
    { lat: 48.9545794, lng: 17.9872251 },
    { lat: 48.95427, lng: 17.9871628 },
    { lat: 48.9541882, lng: 17.9871552 },
    { lat: 48.9541509, lng: 17.9874569 },
    { lat: 48.9540657, lng: 17.9879853 },
    { lat: 48.9540007, lng: 17.9881816 },
    { lat: 48.9538176, lng: 17.9887887 },
    { lat: 48.9537966, lng: 17.9888544 },
    { lat: 48.9536782, lng: 17.9893916 },
    { lat: 48.9534739, lng: 17.9897531 },
    { lat: 48.9533671, lng: 17.990162 },
    { lat: 48.9533524, lng: 17.9902573 },
    { lat: 48.9537284, lng: 17.9905659 },
    { lat: 48.9540743, lng: 17.9901547 },
    { lat: 48.95414, lng: 17.9900262 },
    { lat: 48.9542508, lng: 17.9896641 },
    { lat: 48.9545752, lng: 17.9891782 },
    { lat: 48.9547533, lng: 17.988927 },
    { lat: 48.9549573, lng: 17.9887283 },
    { lat: 48.9551849, lng: 17.9886254 },
    { lat: 48.9558809, lng: 17.9885058 },
    { lat: 48.9565215, lng: 17.9884799 },
    { lat: 48.9565448, lng: 17.9885497 },
    { lat: 48.9566575, lng: 17.9889674 },
    { lat: 48.9567821, lng: 17.9893559 },
    { lat: 48.9570042, lng: 17.9896959 },
    { lat: 48.9571818, lng: 17.9897396 },
    { lat: 48.9572619, lng: 17.989607 },
    { lat: 48.9574126, lng: 17.9898285 },
    { lat: 48.9572534, lng: 17.990112 },
    { lat: 48.9572491, lng: 17.9902106 },
    { lat: 48.9571408, lng: 17.990424 },
    { lat: 48.9570142, lng: 17.9908025 },
    { lat: 48.9570092, lng: 17.9909268 },
    { lat: 48.9571536, lng: 17.991096 },
    { lat: 48.9572069, lng: 17.9910823 },
    { lat: 48.9573238, lng: 17.9913788 },
    { lat: 48.9575157, lng: 17.9916856 },
    { lat: 48.9577051, lng: 17.9917205 },
    { lat: 48.9579129, lng: 17.9915712 },
    { lat: 48.9581412, lng: 17.9923776 },
    { lat: 48.9582137, lng: 17.9926719 },
    { lat: 48.9583036, lng: 17.9930242 },
    { lat: 48.9584365, lng: 17.9935779 },
    { lat: 48.9584679, lng: 17.9936893 },
    { lat: 48.9587245, lng: 17.9936364 },
    { lat: 48.9589257, lng: 17.9942163 },
    { lat: 48.9588409, lng: 17.9942706 },
    { lat: 48.9589577, lng: 17.9945858 },
    { lat: 48.9589976, lng: 17.9946889 },
    { lat: 48.9591019, lng: 17.9950586 },
    { lat: 48.9592848, lng: 17.9953275 },
    { lat: 48.9598657, lng: 17.9950369 },
    { lat: 48.960175, lng: 17.9949387 },
    { lat: 48.9603271, lng: 17.9948789 },
    { lat: 48.9605801, lng: 17.9948268 },
    { lat: 48.9610381, lng: 17.994725 },
    { lat: 48.9614532, lng: 17.9945122 },
    { lat: 48.9618895, lng: 17.9943512 },
    { lat: 48.9619237, lng: 17.9943386 },
    { lat: 48.9621661, lng: 17.9944863 },
    { lat: 48.9621304, lng: 17.9947595 },
    { lat: 48.9621214, lng: 17.9948947 },
    { lat: 48.9622018, lng: 17.9950705 },
    { lat: 48.9623386, lng: 17.9952483 },
    { lat: 48.9625447, lng: 17.9957749 },
    { lat: 48.9628675, lng: 17.9959468 },
    { lat: 48.9630734, lng: 17.9959741 },
    { lat: 48.9634607, lng: 17.9960923 },
    { lat: 48.9634878, lng: 17.9964758 },
    { lat: 48.9633591, lng: 17.9969165 },
    { lat: 48.9634409, lng: 17.9971162 },
    { lat: 48.9632212, lng: 17.997581 },
    { lat: 48.9632266, lng: 17.9976162 },
    { lat: 48.9632839, lng: 17.9978588 },
    { lat: 48.963172, lng: 17.9979537 },
    { lat: 48.9632145, lng: 17.9982687 },
    { lat: 48.9635284, lng: 17.9981218 },
    { lat: 48.9638961, lng: 17.9980201 },
    { lat: 48.9641701, lng: 17.9980913 },
    { lat: 48.964429, lng: 17.9980826 },
    { lat: 48.9648302, lng: 17.9979957 },
    { lat: 48.9650622, lng: 17.9979261 },
    { lat: 48.9653191, lng: 17.9978437 },
    { lat: 48.965346, lng: 17.9978788 },
    { lat: 48.9654398, lng: 17.9978614 },
    { lat: 48.9655887, lng: 17.9978041 },
    { lat: 48.9658687, lng: 17.9978432 },
    { lat: 48.966175, lng: 17.9978924 },
    { lat: 48.9662051, lng: 17.9982457 },
    { lat: 48.9662455, lng: 17.9984173 },
    { lat: 48.9663315, lng: 17.9985522 },
    { lat: 48.9665496, lng: 17.9989787 },
    { lat: 48.9666814, lng: 17.999167 },
    { lat: 48.9667337, lng: 17.9992126 },
    { lat: 48.9666646, lng: 17.9996851 },
    { lat: 48.9667554, lng: 17.9998642 },
    { lat: 48.966792, lng: 17.9999885 },
    { lat: 48.966789, lng: 18.0001294 },
    { lat: 48.9667286, lng: 18.0003495 },
    { lat: 48.9666714, lng: 18.0004492 },
    { lat: 48.9666401, lng: 18.0008206 },
    { lat: 48.966579, lng: 18.0012007 },
    { lat: 48.9666962, lng: 18.0013669 },
    { lat: 48.966778, lng: 18.0017595 },
    { lat: 48.9668846, lng: 18.0019803 },
    { lat: 48.966918, lng: 18.0022017 },
    { lat: 48.9673267, lng: 18.0023027 },
    { lat: 48.9674734, lng: 18.0022425 },
    { lat: 48.9675278, lng: 18.0022668 },
    { lat: 48.967534, lng: 18.0024078 },
    { lat: 48.9675876, lng: 18.0023998 },
    { lat: 48.96794, lng: 18.0023472 },
    { lat: 48.9679633, lng: 18.0023415 },
    { lat: 48.9680102, lng: 18.0023297 },
    { lat: 48.9680503, lng: 18.00232 },
    { lat: 48.9680823, lng: 18.0028077 },
    { lat: 48.9681422, lng: 18.0036502 },
    { lat: 48.9681729, lng: 18.0043246 },
    { lat: 48.9682362, lng: 18.0049411 },
    { lat: 48.9685594, lng: 18.0049075 },
    { lat: 48.969018, lng: 18.0049361 },
    { lat: 48.9692238, lng: 18.0048639 },
    { lat: 48.9692701, lng: 18.0048479 },
    { lat: 48.9694703, lng: 18.0047772 },
    { lat: 48.9702859, lng: 18.0045233 },
    { lat: 48.97111, lng: 18.0042557 },
    { lat: 48.9717115, lng: 18.0040655 },
    { lat: 48.9723052, lng: 18.0038741 },
    { lat: 48.9730672, lng: 18.0036289 },
    { lat: 48.9737752, lng: 18.0034033 },
    { lat: 48.9743279, lng: 18.0032268 },
    { lat: 48.9747093, lng: 18.0031088 },
    { lat: 48.9750939, lng: 18.0029886 },
    { lat: 48.9756312, lng: 18.0028207 },
    { lat: 48.9762281, lng: 18.0027995 },
    { lat: 48.9767462, lng: 18.0027818 },
    { lat: 48.9777301, lng: 18.0027393 },
    { lat: 48.977726, lng: 18.0026852 },
    { lat: 48.9780031, lng: 18.0025645 },
    { lat: 48.9783541, lng: 18.0024676 },
    { lat: 48.9787766, lng: 18.0021086 },
    { lat: 48.9793186, lng: 18.0018075 },
    { lat: 48.979705, lng: 18.0017578 },
    { lat: 48.9801667, lng: 18.0017036 },
    { lat: 48.9808202, lng: 18.0017591 },
    { lat: 48.9810937, lng: 18.0017287 },
    { lat: 48.9814236, lng: 18.0015038 },
    { lat: 48.9818875, lng: 18.0014465 },
    { lat: 48.9822007, lng: 18.0013036 },
    { lat: 48.9825542, lng: 18.0013658 },
    { lat: 48.9830465, lng: 18.0015205 },
    { lat: 48.9833286, lng: 18.0014635 },
    { lat: 48.9839904, lng: 18.0015096 },
    { lat: 48.9845151, lng: 18.0017763 },
    { lat: 48.9854085, lng: 18.0021729 },
    { lat: 48.9863861, lng: 18.0015412 },
    { lat: 48.9863986, lng: 18.0005297 },
    { lat: 48.9867195, lng: 18.0004411 },
    { lat: 48.9873478, lng: 17.9999739 },
    { lat: 48.9874669, lng: 17.9998869 },
    { lat: 48.9874852, lng: 17.999874 },
    { lat: 48.9875744, lng: 17.999818 },
    { lat: 48.9877421, lng: 17.9997129 },
    { lat: 48.9884896, lng: 17.9991268 },
    { lat: 48.9888847, lng: 17.99882 },
    { lat: 48.9895526, lng: 17.9980912 },
    { lat: 48.9900667, lng: 17.9976406 },
    { lat: 48.9900963, lng: 17.997762 },
    { lat: 48.9903567, lng: 17.9988241 },
    { lat: 48.9906668, lng: 18.000141 },
    { lat: 48.9906939, lng: 18.0002559 },
    { lat: 48.9912495, lng: 18.0002242 },
    { lat: 48.9914338, lng: 18.0001743 },
    { lat: 48.9918476, lng: 18.000072 },
    { lat: 48.9920657, lng: 17.9999973 },
    { lat: 48.9921652, lng: 17.9999644 },
    { lat: 48.9924799, lng: 17.9998662 },
    { lat: 48.9928724, lng: 17.9997276 },
    { lat: 48.9934399, lng: 17.9995141 },
    { lat: 48.9934618, lng: 17.9994993 },
    { lat: 48.9934614, lng: 17.9995903 },
    { lat: 48.9934426, lng: 17.9996561 },
    { lat: 48.9934946, lng: 17.9997881 },
    { lat: 48.9935476, lng: 17.9999243 },
    { lat: 48.9935507, lng: 18.0002056 },
    { lat: 48.9936153, lng: 18.000287 },
    { lat: 48.9937504, lng: 18.0003405 },
    { lat: 48.9937777, lng: 18.0004583 },
    { lat: 48.9938265, lng: 18.0005196 },
    { lat: 48.994306, lng: 18.000918 },
    { lat: 48.9944659, lng: 18.0011395 },
    { lat: 48.9945481, lng: 18.0010827 },
    { lat: 48.9946461, lng: 18.0010388 },
    { lat: 48.9948431, lng: 18.001224 },
    { lat: 48.9949343, lng: 18.0012181 },
    { lat: 48.9950623, lng: 18.0011302 },
    { lat: 48.9951174, lng: 18.0012234 },
    { lat: 48.995237, lng: 18.0013584 },
    { lat: 48.9953525, lng: 18.0014767 },
    { lat: 48.9954765, lng: 18.0015695 },
    { lat: 48.9955646, lng: 18.0015913 },
    { lat: 48.9955761, lng: 18.0016145 },
    { lat: 48.9956497, lng: 18.0019046 },
    { lat: 48.9958451, lng: 18.0020426 },
    { lat: 48.9959373, lng: 18.0020567 },
    { lat: 48.9960138, lng: 18.0021081 },
    { lat: 48.9960295, lng: 18.0020884 },
    { lat: 48.9961752, lng: 18.0019363 },
    { lat: 48.996663, lng: 18.0010191 },
    { lat: 48.9973212, lng: 18.0001808 },
    { lat: 48.9974534, lng: 17.9999935 },
    { lat: 48.9978505, lng: 17.9994348 },
    { lat: 48.9982004, lng: 17.9987833 },
    { lat: 48.9984727, lng: 17.9982747 },
    { lat: 48.9989415, lng: 17.997245 },
    { lat: 48.9992849, lng: 17.9965236 },
    { lat: 48.9994301, lng: 17.9962105 },
    { lat: 48.9996593, lng: 17.9957167 },
    { lat: 48.9996673, lng: 17.9956965 },
    { lat: 48.9997366, lng: 17.9955318 },
    { lat: 48.9997551, lng: 17.9954854 },
    { lat: 48.9999906, lng: 17.9949235 },
    { lat: 49.0006645, lng: 17.9943504 },
    { lat: 49.0010641, lng: 17.9937765 },
    { lat: 49.0014545, lng: 17.993146 },
    { lat: 49.0015184, lng: 17.9930431 },
    { lat: 49.0016643, lng: 17.9927619 },
    { lat: 49.0016818, lng: 17.9927286 },
    { lat: 49.0019979, lng: 17.9921179 },
    { lat: 49.0024574, lng: 17.9914165 },
    { lat: 49.0028879, lng: 17.9917666 },
    { lat: 49.0031381, lng: 17.9919701 },
    { lat: 49.003916, lng: 17.9926092 },
    { lat: 49.0040671, lng: 17.9929489 },
    { lat: 49.0042432, lng: 17.9933321 },
    { lat: 49.0046703, lng: 17.9939171 },
    { lat: 49.004953, lng: 17.9946726 },
    { lat: 49.0051056, lng: 17.995423 },
    { lat: 49.0051879, lng: 17.9961649 },
    { lat: 49.0054617, lng: 17.9969422 },
    { lat: 49.0054438, lng: 17.9980181 },
    { lat: 49.0053862, lng: 17.9991284 },
    { lat: 49.0050798, lng: 17.9999046 },
    { lat: 49.0047864, lng: 18.0007696 },
    { lat: 49.0047674, lng: 18.0008275 },
    { lat: 49.0047512, lng: 18.0009944 },
    { lat: 49.0045165, lng: 18.0022014 },
    { lat: 49.0042772, lng: 18.0029846 },
    { lat: 49.0042419, lng: 18.0030955 },
    { lat: 49.0040153, lng: 18.0037895 },
    { lat: 49.0039843, lng: 18.004778 },
    { lat: 49.0039618, lng: 18.0057933 },
    { lat: 49.0037814, lng: 18.0069477 },
    { lat: 49.0035415, lng: 18.0082388 },
    { lat: 49.0032841, lng: 18.0094867 },
    { lat: 49.0029855, lng: 18.0104738 },
    { lat: 49.0026855, lng: 18.0114524 },
    { lat: 49.0022052, lng: 18.0123444 },
    { lat: 49.0021426, lng: 18.0124715 },
    { lat: 49.0022092, lng: 18.0126631 },
    { lat: 49.0022354, lng: 18.0127425 },
    { lat: 49.0025385, lng: 18.0132718 },
    { lat: 49.0028066, lng: 18.0137448 },
    { lat: 49.0031044, lng: 18.014337 },
    { lat: 49.0031314, lng: 18.0143897 },
    { lat: 49.0032264, lng: 18.0145846 },
    { lat: 49.0038831, lng: 18.015678 },
    { lat: 49.0040071, lng: 18.015869 },
    { lat: 49.0042952, lng: 18.0163155 },
    { lat: 49.0047961, lng: 18.016984 },
    { lat: 49.0048695, lng: 18.0174248 },
    { lat: 49.0051408, lng: 18.017855 },
    { lat: 49.0052306, lng: 18.0185948 },
    { lat: 49.0053551, lng: 18.0195053 },
    { lat: 49.0055711, lng: 18.0201076 },
    { lat: 49.0061524, lng: 18.0208598 },
    { lat: 49.0066377, lng: 18.0210891 },
    { lat: 49.0070518, lng: 18.0211656 },
    { lat: 49.0071677, lng: 18.0212145 },
    { lat: 49.0072775, lng: 18.0212604 },
    { lat: 49.0073156, lng: 18.0212764 },
    { lat: 49.0073493, lng: 18.0212905 },
    { lat: 49.0076044, lng: 18.0205804 },
    { lat: 49.0074249, lng: 18.020291 },
    { lat: 49.0074153, lng: 18.0199378 },
    { lat: 49.0074088, lng: 18.0193085 },
    { lat: 49.007492, lng: 18.0182849 },
    { lat: 49.0076418, lng: 18.0172816 },
    { lat: 49.0078689, lng: 18.015632 },
    { lat: 49.007903, lng: 18.0150421 },
    { lat: 49.0084223, lng: 18.013952 },
    { lat: 49.0084033, lng: 18.0137256 },
    { lat: 49.0084698, lng: 18.0132632 },
    { lat: 49.0085272, lng: 18.0124339 },
    { lat: 49.0084514, lng: 18.0112014 },
    { lat: 49.0087509, lng: 18.0101178 },
    { lat: 49.0087814, lng: 18.0099044 },
    { lat: 49.0089128, lng: 18.0089935 },
    { lat: 49.0092555, lng: 18.0079334 },
    { lat: 49.0096031, lng: 18.0070284 },
    { lat: 49.0099048, lng: 18.0067702 },
    { lat: 49.0104156, lng: 18.0063599 },
    { lat: 49.0110784, lng: 18.0062093 },
    { lat: 49.0119406, lng: 18.0063613 },
    { lat: 49.0121668, lng: 18.0063711 },
    { lat: 49.0122088, lng: 18.0063733 },
    { lat: 49.0123738, lng: 18.0063807 },
    { lat: 49.0132641, lng: 18.0059529 },
    { lat: 49.0139922, lng: 18.0060718 },
    { lat: 49.0146428, lng: 18.0058901 },
    { lat: 49.0152468, lng: 18.0058342 },
    { lat: 49.0158135, lng: 18.0062205 },
    { lat: 49.0164037, lng: 18.0061985 },
    { lat: 49.0166802, lng: 18.006468 },
    { lat: 49.0167304, lng: 18.0065179 },
    { lat: 49.0169328, lng: 18.0067137 },
    { lat: 49.0171315, lng: 18.0075226 },
    { lat: 49.0171104, lng: 18.0085068 },
    { lat: 49.0172397, lng: 18.0093007 },
    { lat: 49.0178646, lng: 18.0094876 },
    { lat: 49.0181278, lng: 18.0100092 },
    { lat: 49.0185457, lng: 18.0100336 },
    { lat: 49.0196918, lng: 18.0101032 },
    { lat: 49.0202482, lng: 18.0106413 },
    { lat: 49.0207263, lng: 18.011535 },
    { lat: 49.0210159, lng: 18.0126392 },
    { lat: 49.0211957, lng: 18.0126848 },
    { lat: 49.0216972, lng: 18.0139177 },
    { lat: 49.0220695, lng: 18.0143216 },
    { lat: 49.0221042, lng: 18.0143341 },
    { lat: 49.0225707, lng: 18.0128933 },
    { lat: 49.0226669, lng: 18.0120876 },
    { lat: 49.0225164, lng: 18.0116273 },
    { lat: 49.0223472, lng: 18.0111098 },
    { lat: 49.0216996, lng: 18.0097841 },
    { lat: 49.0215387, lng: 18.0094553 },
    { lat: 49.0212128, lng: 18.0088642 },
    { lat: 49.0213333, lng: 18.007312 },
    { lat: 49.0221124, lng: 18.0057256 },
    { lat: 49.0225421, lng: 18.0047553 },
    { lat: 49.0229022, lng: 18.003942 },
    { lat: 49.0235546, lng: 18.0019625 },
    { lat: 49.0244139, lng: 18.0001954 },
    { lat: 49.0247518, lng: 17.9997045 },
    { lat: 49.0255535, lng: 17.9985415 },
    { lat: 49.0262178, lng: 17.9976547 },
    { lat: 49.0266208, lng: 17.9971187 },
    { lat: 49.0270995, lng: 17.9961525 },
    { lat: 49.0272787, lng: 17.9957047 },
    { lat: 49.0274657, lng: 17.9952389 },
    { lat: 49.0276994, lng: 17.9943052 },
    { lat: 49.0279633, lng: 17.9932528 },
  ],
  Ivanovce: [
    { lat: 48.8258696, lng: 17.9347675 },
    { lat: 48.8262055, lng: 17.9337965 },
    { lat: 48.8268773, lng: 17.9318932 },
    { lat: 48.8270202, lng: 17.9314857 },
    { lat: 48.8270442, lng: 17.9314169 },
    { lat: 48.8271166, lng: 17.9312113 },
    { lat: 48.8271305, lng: 17.9311783 },
    { lat: 48.8271934, lng: 17.9309841 },
    { lat: 48.8273788, lng: 17.9305746 },
    { lat: 48.8276016, lng: 17.9301282 },
    { lat: 48.8276954, lng: 17.9299394 },
    { lat: 48.8279485, lng: 17.9294344 },
    { lat: 48.8280376, lng: 17.9292466 },
    { lat: 48.8282053, lng: 17.9288235 },
    { lat: 48.8285927, lng: 17.9278611 },
    { lat: 48.8290513, lng: 17.9266959 },
    { lat: 48.8295126, lng: 17.9255385 },
    { lat: 48.8296404, lng: 17.9252233 },
    { lat: 48.8299955, lng: 17.9243463 },
    { lat: 48.8303685, lng: 17.9234281 },
    { lat: 48.8307371, lng: 17.9225163 },
    { lat: 48.8311323, lng: 17.9215535 },
    { lat: 48.8313018, lng: 17.9211418 },
    { lat: 48.8315148, lng: 17.9206096 },
    { lat: 48.8324267, lng: 17.9183419 },
    { lat: 48.8327865, lng: 17.9173785 },
    { lat: 48.8332227, lng: 17.9162169 },
    { lat: 48.8336447, lng: 17.9150995 },
    { lat: 48.833654, lng: 17.9150699 },
    { lat: 48.8336869, lng: 17.9149743 },
    { lat: 48.8337009, lng: 17.9149287 },
    { lat: 48.8337485, lng: 17.9147785 },
    { lat: 48.83383, lng: 17.9146505 },
    { lat: 48.8339011, lng: 17.9145383 },
    { lat: 48.8339647, lng: 17.9143561 },
    { lat: 48.8344811, lng: 17.9129161 },
    { lat: 48.8346608, lng: 17.9123701 },
    { lat: 48.8348496, lng: 17.9117945 },
    { lat: 48.8351818, lng: 17.9107055 },
    { lat: 48.8353279, lng: 17.9103371 },
    { lat: 48.8353546, lng: 17.9102736 },
    { lat: 48.8356844, lng: 17.9094654 },
    { lat: 48.8358696, lng: 17.909091 },
    { lat: 48.8360053, lng: 17.9088337 },
    { lat: 48.8360915, lng: 17.9086695 },
    { lat: 48.8361248, lng: 17.9086044 },
    { lat: 48.8362536, lng: 17.9083617 },
    { lat: 48.8362984, lng: 17.9082806 },
    { lat: 48.836122, lng: 17.9080297 },
    { lat: 48.8359962, lng: 17.9078127 },
    { lat: 48.8359301, lng: 17.9076333 },
    { lat: 48.8358866, lng: 17.9073215 },
    { lat: 48.8358636, lng: 17.9070129 },
    { lat: 48.835822, lng: 17.9066782 },
    { lat: 48.8358089, lng: 17.9066091 },
    { lat: 48.8358034, lng: 17.9065736 },
    { lat: 48.8358002, lng: 17.9065587 },
    { lat: 48.8357397, lng: 17.9062639 },
    { lat: 48.835703, lng: 17.9061066 },
    { lat: 48.8356857, lng: 17.9060226 },
    { lat: 48.8355917, lng: 17.9058123 },
    { lat: 48.8355952, lng: 17.9057947 },
    { lat: 48.8355988, lng: 17.9057806 },
    { lat: 48.8356128, lng: 17.9057095 },
    { lat: 48.8356163, lng: 17.9056939 },
    { lat: 48.8356393, lng: 17.9055689 },
    { lat: 48.8356366, lng: 17.9052787 },
    { lat: 48.8361482, lng: 17.9049271 },
    { lat: 48.8363833, lng: 17.9044809 },
    { lat: 48.8369673, lng: 17.9033771 },
    { lat: 48.8370993, lng: 17.9031318 },
    { lat: 48.8370552, lng: 17.9030913 },
    { lat: 48.8375369, lng: 17.902088 },
    { lat: 48.8373987, lng: 17.9018927 },
    { lat: 48.8376279, lng: 17.9014785 },
    { lat: 48.8379231, lng: 17.9009079 },
    { lat: 48.8383227, lng: 17.9002459 },
    { lat: 48.8388025, lng: 17.8994201 },
    { lat: 48.8391209, lng: 17.8988409 },
    { lat: 48.8392972, lng: 17.898544 },
    { lat: 48.8395077, lng: 17.8981767 },
    { lat: 48.8398166, lng: 17.8976791 },
    { lat: 48.8399438, lng: 17.8974508 },
    { lat: 48.8402134, lng: 17.8969367 },
    { lat: 48.8405331, lng: 17.8963405 },
    { lat: 48.8410935, lng: 17.8954817 },
    { lat: 48.8414579, lng: 17.8949176 },
    { lat: 48.8418262, lng: 17.8943576 },
    { lat: 48.8418973, lng: 17.8941873 },
    { lat: 48.8423615, lng: 17.893083 },
    { lat: 48.8426478, lng: 17.8924028 },
    { lat: 48.8429616, lng: 17.8915937 },
    { lat: 48.8430767, lng: 17.8911238 },
    { lat: 48.8432652, lng: 17.8902223 },
    { lat: 48.8432155, lng: 17.8901818 },
    { lat: 48.8430374, lng: 17.8904099 },
    { lat: 48.842955, lng: 17.8902987 },
    { lat: 48.8428346, lng: 17.8900426 },
    { lat: 48.8428279, lng: 17.8900263 },
    { lat: 48.842771, lng: 17.8898862 },
    { lat: 48.8417265, lng: 17.8883202 },
    { lat: 48.841694, lng: 17.8882476 },
    { lat: 48.8416847, lng: 17.8882263 },
    { lat: 48.8416404, lng: 17.8881281 },
    { lat: 48.8423947, lng: 17.8870053 },
    { lat: 48.8424155, lng: 17.8869744 },
    { lat: 48.8427315, lng: 17.8866194 },
    { lat: 48.843002, lng: 17.8866093 },
    { lat: 48.8432143, lng: 17.8861041 },
    { lat: 48.8433846, lng: 17.886029 },
    { lat: 48.8436739, lng: 17.8855264 },
    { lat: 48.8438338, lng: 17.8848815 },
    { lat: 48.8439333, lng: 17.8848079 },
    { lat: 48.8443027, lng: 17.8848146 },
    { lat: 48.844748, lng: 17.8841296 },
    { lat: 48.844647, lng: 17.8839518 },
    { lat: 48.844677, lng: 17.8837541 },
    { lat: 48.8449421, lng: 17.883471 },
    { lat: 48.844835, lng: 17.8826457 },
    { lat: 48.8449705, lng: 17.8821425 },
    { lat: 48.8453939, lng: 17.8815235 },
    { lat: 48.8456464, lng: 17.8815725 },
    { lat: 48.8456539, lng: 17.8812854 },
    { lat: 48.8457126, lng: 17.8811164 },
    { lat: 48.8458022, lng: 17.8810481 },
    { lat: 48.8460925, lng: 17.8808253 },
    { lat: 48.8462364, lng: 17.8806499 },
    { lat: 48.8459458, lng: 17.8804323 },
    { lat: 48.8454143, lng: 17.8800036 },
    { lat: 48.8453491, lng: 17.8802327 },
    { lat: 48.8452856, lng: 17.8801569 },
    { lat: 48.8451286, lng: 17.8799657 },
    { lat: 48.8445599, lng: 17.8792371 },
    { lat: 48.8447724, lng: 17.8787741 },
    { lat: 48.8449751, lng: 17.878334 },
    { lat: 48.845162, lng: 17.8778197 },
    { lat: 48.8451986, lng: 17.8777168 },
    { lat: 48.8453638, lng: 17.8772546 },
    { lat: 48.8454738, lng: 17.8769437 },
    { lat: 48.8457341, lng: 17.8766357 },
    { lat: 48.846054, lng: 17.8762134 },
    { lat: 48.8460998, lng: 17.8760533 },
    { lat: 48.8461324, lng: 17.8759746 },
    { lat: 48.8464456, lng: 17.8747658 },
    { lat: 48.8465095, lng: 17.8743258 },
    { lat: 48.8465204, lng: 17.8743028 },
    { lat: 48.8465347, lng: 17.8742761 },
    { lat: 48.8466366, lng: 17.8740957 },
    { lat: 48.846688, lng: 17.873994 },
    { lat: 48.8467356, lng: 17.8738656 },
    { lat: 48.846814, lng: 17.8737211 },
    { lat: 48.8470272, lng: 17.8736636 },
    { lat: 48.8471736, lng: 17.8735656 },
    { lat: 48.8474304, lng: 17.8730097 },
    { lat: 48.8477399, lng: 17.8722662 },
    { lat: 48.8478217, lng: 17.8720495 },
    { lat: 48.8482643, lng: 17.8708715 },
    { lat: 48.8484826, lng: 17.8701836 },
    { lat: 48.8485643, lng: 17.8693688 },
    { lat: 48.8488862, lng: 17.8692787 },
    { lat: 48.8487638, lng: 17.8677344 },
    { lat: 48.8488949, lng: 17.8677155 },
    { lat: 48.8489966, lng: 17.8677004 },
    { lat: 48.8489192, lng: 17.8668828 },
    { lat: 48.8491258, lng: 17.8667148 },
    { lat: 48.8493487, lng: 17.8665355 },
    { lat: 48.8492406, lng: 17.8658695 },
    { lat: 48.8493664, lng: 17.8657818 },
    { lat: 48.8493373, lng: 17.8653543 },
    { lat: 48.8495865, lng: 17.8650506 },
    { lat: 48.8499328, lng: 17.8646547 },
    { lat: 48.8499037, lng: 17.8645641 },
    { lat: 48.8502406, lng: 17.8643683 },
    { lat: 48.8504013, lng: 17.8641117 },
    { lat: 48.8504793, lng: 17.8640005 },
    { lat: 48.8507082, lng: 17.8633996 },
    { lat: 48.8507656, lng: 17.8632644 },
    { lat: 48.8507644, lng: 17.8627194 },
    { lat: 48.851047, lng: 17.862331 },
    { lat: 48.850543, lng: 17.861762 },
    { lat: 48.85043, lng: 17.86157 },
    { lat: 48.850325, lng: 17.861408 },
    { lat: 48.850029, lng: 17.860958 },
    { lat: 48.849856, lng: 17.860775 },
    { lat: 48.849635, lng: 17.86102 },
    { lat: 48.849525, lng: 17.861145 },
    { lat: 48.84942, lng: 17.861247 },
    { lat: 48.849304, lng: 17.861361 },
    { lat: 48.848928, lng: 17.862195 },
    { lat: 48.848813, lng: 17.862453 },
    { lat: 48.848707, lng: 17.862775 },
    { lat: 48.848608, lng: 17.863077 },
    { lat: 48.848544, lng: 17.863301 },
    { lat: 48.848238, lng: 17.864103 },
    { lat: 48.848011, lng: 17.86391 },
    { lat: 48.847759, lng: 17.863519 },
    { lat: 48.847662, lng: 17.863337 },
    { lat: 48.847518, lng: 17.863017 },
    { lat: 48.847378, lng: 17.862667 },
    { lat: 48.84716, lng: 17.862379 },
    { lat: 48.846832, lng: 17.862852 },
    { lat: 48.846788, lng: 17.862947 },
    { lat: 48.846283, lng: 17.863647 },
    { lat: 48.84604, lng: 17.864912 },
    { lat: 48.845771, lng: 17.865485 },
    { lat: 48.845267, lng: 17.865533 },
    { lat: 48.844591, lng: 17.86575 },
    { lat: 48.843737, lng: 17.866265 },
    { lat: 48.842864, lng: 17.867261 },
    { lat: 48.842794, lng: 17.866775 },
    { lat: 48.842509, lng: 17.867208 },
    { lat: 48.841967, lng: 17.867934 },
    { lat: 48.841717, lng: 17.868229 },
    { lat: 48.841333, lng: 17.868745 },
    { lat: 48.841083, lng: 17.868914 },
    { lat: 48.841061, lng: 17.868854 },
    { lat: 48.84068, lng: 17.867259 },
    { lat: 48.840607, lng: 17.866903 },
    { lat: 48.839275, lng: 17.867431 },
    { lat: 48.839319, lng: 17.867923 },
    { lat: 48.838862, lng: 17.868052 },
    { lat: 48.838952, lng: 17.868722 },
    { lat: 48.838969, lng: 17.868791 },
    { lat: 48.83904, lng: 17.869244 },
    { lat: 48.838086, lng: 17.86954 },
    { lat: 48.835954, lng: 17.870561 },
    { lat: 48.835764, lng: 17.870339 },
    { lat: 48.835284, lng: 17.870341 },
    { lat: 48.834741, lng: 17.870513 },
    { lat: 48.834591, lng: 17.870685 },
    { lat: 48.834542, lng: 17.870866 },
    { lat: 48.834513, lng: 17.871359 },
    { lat: 48.834063, lng: 17.872223 },
    { lat: 48.834518, lng: 17.873219 },
    { lat: 48.834897, lng: 17.873909 },
    { lat: 48.834966, lng: 17.874118 },
    { lat: 48.835529, lng: 17.875184 },
    { lat: 48.835716, lng: 17.875907 },
    { lat: 48.835577, lng: 17.876397 },
    { lat: 48.835394, lng: 17.876445 },
    { lat: 48.834938, lng: 17.876535 },
    { lat: 48.834505, lng: 17.876567 },
    { lat: 48.833537, lng: 17.87671 },
    { lat: 48.833184, lng: 17.876605 },
    { lat: 48.832448, lng: 17.876362 },
    { lat: 48.832307, lng: 17.876353 },
    { lat: 48.832046, lng: 17.876332 },
    { lat: 48.83142, lng: 17.876675 },
    { lat: 48.831172, lng: 17.876815 },
    { lat: 48.831003, lng: 17.876903 },
    { lat: 48.830694, lng: 17.87707 },
    { lat: 48.830586, lng: 17.877128 },
    { lat: 48.830464, lng: 17.87719 },
    { lat: 48.830328, lng: 17.877263 },
    { lat: 48.830328, lng: 17.877319 },
    { lat: 48.830316, lng: 17.877329 },
    { lat: 48.8303695, lng: 17.8783567 },
    { lat: 48.8303789, lng: 17.8785127 },
    { lat: 48.8303896, lng: 17.8786911 },
    { lat: 48.8303768, lng: 17.8790748 },
    { lat: 48.8303934, lng: 17.8801007 },
    { lat: 48.8304, lng: 17.8804004 },
    { lat: 48.8304266, lng: 17.8810857 },
    { lat: 48.8302681, lng: 17.881659 },
    { lat: 48.8301116, lng: 17.8821915 },
    { lat: 48.8298955, lng: 17.8825037 },
    { lat: 48.8298509, lng: 17.8826037 },
    { lat: 48.8297829, lng: 17.8827309 },
    { lat: 48.8295543, lng: 17.8829105 },
    { lat: 48.8294029, lng: 17.8830399 },
    { lat: 48.8291061, lng: 17.8834535 },
    { lat: 48.8285525, lng: 17.8840271 },
    { lat: 48.8282818, lng: 17.884407 },
    { lat: 48.8280794, lng: 17.8847574 },
    { lat: 48.8278962, lng: 17.8849758 },
    { lat: 48.8277262, lng: 17.8851887 },
    { lat: 48.8271853, lng: 17.8856356 },
    { lat: 48.827253, lng: 17.8856162 },
    { lat: 48.8275461, lng: 17.8856879 },
    { lat: 48.8280333, lng: 17.8858088 },
    { lat: 48.8286852, lng: 17.8859693 },
    { lat: 48.8287058, lng: 17.8859732 },
    { lat: 48.8289271, lng: 17.8859961 },
    { lat: 48.8291363, lng: 17.8860203 },
    { lat: 48.8291888, lng: 17.8860284 },
    { lat: 48.8291767, lng: 17.8866929 },
    { lat: 48.8291736, lng: 17.8869402 },
    { lat: 48.8291636, lng: 17.8876187 },
    { lat: 48.8291483, lng: 17.8887332 },
    { lat: 48.828616, lng: 17.8889689 },
    { lat: 48.8278638, lng: 17.8892994 },
    { lat: 48.8269741, lng: 17.8896922 },
    { lat: 48.8260591, lng: 17.8900924 },
    { lat: 48.8251307, lng: 17.8904977 },
    { lat: 48.8249836, lng: 17.8905656 },
    { lat: 48.8245323, lng: 17.8907582 },
    { lat: 48.8247349, lng: 17.8913859 },
    { lat: 48.8248714, lng: 17.8917203 },
    { lat: 48.8250131, lng: 17.8921256 },
    { lat: 48.8250721, lng: 17.8923765 },
    { lat: 48.8251077, lng: 17.892574 },
    { lat: 48.8251236, lng: 17.8926509 },
    { lat: 48.8251333, lng: 17.8926969 },
    { lat: 48.8251378, lng: 17.8927178 },
    { lat: 48.8251537, lng: 17.8927772 },
    { lat: 48.8251667, lng: 17.8928238 },
    { lat: 48.8251887, lng: 17.8929074 },
    { lat: 48.8251385, lng: 17.8929373 },
    { lat: 48.8250791, lng: 17.8929809 },
    { lat: 48.8248379, lng: 17.8931557 },
    { lat: 48.8245082, lng: 17.8933448 },
    { lat: 48.8242674, lng: 17.8934858 },
    { lat: 48.8241283, lng: 17.8935207 },
    { lat: 48.8239748, lng: 17.8935576 },
    { lat: 48.8238151, lng: 17.893379 },
    { lat: 48.823695, lng: 17.8932419 },
    { lat: 48.8236248, lng: 17.8931602 },
    { lat: 48.8234927, lng: 17.893014 },
    { lat: 48.8234238, lng: 17.892993 },
    { lat: 48.823365, lng: 17.8929707 },
    { lat: 48.8233027, lng: 17.8929485 },
    { lat: 48.8232412, lng: 17.8929419 },
    { lat: 48.8231547, lng: 17.8929463 },
    { lat: 48.8231204, lng: 17.8929585 },
    { lat: 48.8230636, lng: 17.8929896 },
    { lat: 48.8230271, lng: 17.8930197 },
    { lat: 48.8229908, lng: 17.8930563 },
    { lat: 48.822819, lng: 17.8932411 },
    { lat: 48.822663, lng: 17.8933884 },
    { lat: 48.8224117, lng: 17.893658 },
    { lat: 48.8220523, lng: 17.8940435 },
    { lat: 48.8214961, lng: 17.8946317 },
    { lat: 48.8210188, lng: 17.8951409 },
    { lat: 48.8207025, lng: 17.8954746 },
    { lat: 48.8203798, lng: 17.895816 },
    { lat: 48.8199904, lng: 17.8962321 },
    { lat: 48.8196351, lng: 17.8966103 },
    { lat: 48.8193434, lng: 17.8969241 },
    { lat: 48.8189954, lng: 17.8972944 },
    { lat: 48.8188533, lng: 17.8974447 },
    { lat: 48.818831, lng: 17.8974703 },
    { lat: 48.8186907, lng: 17.8976315 },
    { lat: 48.8186504, lng: 17.8976775 },
    { lat: 48.8185821, lng: 17.8975727 },
    { lat: 48.8185287, lng: 17.8976241 },
    { lat: 48.8185052, lng: 17.8976474 },
    { lat: 48.8184894, lng: 17.8976632 },
    { lat: 48.818415, lng: 17.8977404 },
    { lat: 48.8179596, lng: 17.8982032 },
    { lat: 48.8177172, lng: 17.8984525 },
    { lat: 48.8176971, lng: 17.8984374 },
    { lat: 48.8174762, lng: 17.8982726 },
    { lat: 48.8172316, lng: 17.8980914 },
    { lat: 48.816967, lng: 17.8978953 },
    { lat: 48.8166413, lng: 17.8976583 },
    { lat: 48.8163657, lng: 17.8974498 },
    { lat: 48.8161821, lng: 17.8973068 },
    { lat: 48.8161579, lng: 17.897338 },
    { lat: 48.8158391, lng: 17.8978153 },
    { lat: 48.8154078, lng: 17.8984563 },
    { lat: 48.8151718, lng: 17.8988105 },
    { lat: 48.8151512, lng: 17.8988427 },
    { lat: 48.8149961, lng: 17.8990804 },
    { lat: 48.8148137, lng: 17.8993505 },
    { lat: 48.8142955, lng: 17.9001213 },
    { lat: 48.8137673, lng: 17.9009131 },
    { lat: 48.8137084, lng: 17.9010036 },
    { lat: 48.8136292, lng: 17.900771 },
    { lat: 48.8133871, lng: 17.900367 },
    { lat: 48.8132083, lng: 17.9000793 },
    { lat: 48.8130011, lng: 17.8998238 },
    { lat: 48.8129703, lng: 17.8998739 },
    { lat: 48.8129351, lng: 17.8999303 },
    { lat: 48.812834, lng: 17.900095 },
    { lat: 48.81283, lng: 17.9001 },
    { lat: 48.81267, lng: 17.900297 },
    { lat: 48.812234, lng: 17.900793 },
    { lat: 48.812089, lng: 17.900957 },
    { lat: 48.811874, lng: 17.901202 },
    { lat: 48.811617, lng: 17.901605 },
    { lat: 48.811619, lng: 17.901649 },
    { lat: 48.811671, lng: 17.902283 },
    { lat: 48.81178, lng: 17.903702 },
    { lat: 48.811767, lng: 17.904395 },
    { lat: 48.811756, lng: 17.904726 },
    { lat: 48.811755, lng: 17.90475 },
    { lat: 48.811751, lng: 17.904839 },
    { lat: 48.81174, lng: 17.905146 },
    { lat: 48.811729, lng: 17.905429 },
    { lat: 48.811659, lng: 17.905973 },
    { lat: 48.811635, lng: 17.90615 },
    { lat: 48.81162, lng: 17.906247 },
    { lat: 48.811619, lng: 17.906252 },
    { lat: 48.811616, lng: 17.906272 },
    { lat: 48.811616, lng: 17.906273 },
    { lat: 48.811598, lng: 17.906376 },
    { lat: 48.811563, lng: 17.906594 },
    { lat: 48.811481, lng: 17.907095 },
    { lat: 48.811447, lng: 17.907794 },
    { lat: 48.811442, lng: 17.907884 },
    { lat: 48.811434, lng: 17.908059 },
    { lat: 48.811427, lng: 17.908117 },
    { lat: 48.811399, lng: 17.908329 },
    { lat: 48.81138, lng: 17.908474 },
    { lat: 48.811354, lng: 17.908653 },
    { lat: 48.811365, lng: 17.909179 },
    { lat: 48.811372, lng: 17.909894 },
    { lat: 48.811381, lng: 17.910642 },
    { lat: 48.81139, lng: 17.91128 },
    { lat: 48.811395, lng: 17.911991 },
    { lat: 48.811407, lng: 17.912904 },
    { lat: 48.811429, lng: 17.914613 },
    { lat: 48.811444, lng: 17.915729 },
    { lat: 48.811462, lng: 17.917015 },
    { lat: 48.811463, lng: 17.917393 },
    { lat: 48.811485, lng: 17.918917 },
    { lat: 48.811492, lng: 17.919506 },
    { lat: 48.811503, lng: 17.920277 },
    { lat: 48.811503, lng: 17.920294 },
    { lat: 48.811505, lng: 17.920513 },
    { lat: 48.811508, lng: 17.920639 },
    { lat: 48.811505, lng: 17.920686 },
    { lat: 48.811522, lng: 17.920725 },
    { lat: 48.8116736, lng: 17.9210293 },
    { lat: 48.8117512, lng: 17.9212511 },
    { lat: 48.8118067, lng: 17.9214784 },
    { lat: 48.8118259, lng: 17.9216491 },
    { lat: 48.8118623, lng: 17.922123 },
    { lat: 48.8118815, lng: 17.9221834 },
    { lat: 48.8118971, lng: 17.9222342 },
    { lat: 48.811958, lng: 17.9224221 },
    { lat: 48.8120177, lng: 17.9226091 },
    { lat: 48.8123435, lng: 17.9231493 },
    { lat: 48.8124905, lng: 17.9233805 },
    { lat: 48.8126612, lng: 17.9236472 },
    { lat: 48.8130005, lng: 17.9241708 },
    { lat: 48.8133655, lng: 17.9246852 },
    { lat: 48.8137496, lng: 17.9251633 },
    { lat: 48.8141412, lng: 17.9255583 },
    { lat: 48.8145403, lng: 17.9259069 },
    { lat: 48.8149685, lng: 17.9262568 },
    { lat: 48.8153081, lng: 17.9264764 },
    { lat: 48.8153912, lng: 17.9265298 },
    { lat: 48.8158414, lng: 17.9267882 },
    { lat: 48.8159282, lng: 17.9268236 },
    { lat: 48.816304, lng: 17.9269777 },
    { lat: 48.8167977, lng: 17.9271518 },
    { lat: 48.8168804, lng: 17.9271755 },
    { lat: 48.8171341, lng: 17.9272489 },
    { lat: 48.8171387, lng: 17.9272504 },
    { lat: 48.8172721, lng: 17.9272891 },
    { lat: 48.8177574, lng: 17.9274041 },
    { lat: 48.8179357, lng: 17.9274381 },
    { lat: 48.81822, lng: 17.927494 },
    { lat: 48.8186391, lng: 17.9275763 },
    { lat: 48.8193848, lng: 17.9277563 },
    { lat: 48.8195818, lng: 17.9278031 },
    { lat: 48.8196241, lng: 17.9278152 },
    { lat: 48.8197068, lng: 17.9278375 },
    { lat: 48.8200267, lng: 17.9279373 },
    { lat: 48.820199, lng: 17.9279907 },
    { lat: 48.8202391, lng: 17.9279415 },
    { lat: 48.8203004, lng: 17.9278557 },
    { lat: 48.820385, lng: 17.9277391 },
    { lat: 48.820511, lng: 17.9275643 },
    { lat: 48.821094, lng: 17.9272157 },
    { lat: 48.8215165, lng: 17.9272394 },
    { lat: 48.8215544, lng: 17.9272486 },
    { lat: 48.8217581, lng: 17.9272973 },
    { lat: 48.8217675, lng: 17.9272999 },
    { lat: 48.8222787, lng: 17.9274308 },
    { lat: 48.8225, lng: 17.927605 },
    { lat: 48.8229889, lng: 17.9280033 },
    { lat: 48.8230247, lng: 17.9280327 },
    { lat: 48.8234388, lng: 17.9284481 },
    { lat: 48.8235171, lng: 17.9285853 },
    { lat: 48.8236028, lng: 17.928738 },
    { lat: 48.8236823, lng: 17.9288768 },
    { lat: 48.8237555, lng: 17.9290058 },
    { lat: 48.8237884, lng: 17.9290647 },
    { lat: 48.8238289, lng: 17.9291379 },
    { lat: 48.8239051, lng: 17.9292797 },
    { lat: 48.8239824, lng: 17.9294217 },
    { lat: 48.8240598, lng: 17.9295648 },
    { lat: 48.8241422, lng: 17.9297165 },
    { lat: 48.8243797, lng: 17.9301564 },
    { lat: 48.8247281, lng: 17.9310801 },
    { lat: 48.8248121, lng: 17.9313294 },
    { lat: 48.8248478, lng: 17.9314363 },
    { lat: 48.8248846, lng: 17.9315455 },
    { lat: 48.8249916, lng: 17.931859 },
    { lat: 48.8250997, lng: 17.9321766 },
    { lat: 48.8255421, lng: 17.9334815 },
    { lat: 48.8258666, lng: 17.9347758 },
    { lat: 48.8258696, lng: 17.9347675 },
  ],
  IvanovceExt: [
    { lat: 48.855988, lng: 17.854393 },
    { lat: 48.8563045, lng: 17.8553841 },
    { lat: 48.8566104, lng: 17.8561321 },
    { lat: 48.8566515, lng: 17.8562343 },
    { lat: 48.8568779, lng: 17.8567862 },
    { lat: 48.8569189, lng: 17.8568852 },
    { lat: 48.8570618, lng: 17.8567109 },
    { lat: 48.8570156, lng: 17.8565448 },
    { lat: 48.8569355, lng: 17.8562631 },
    { lat: 48.8570307, lng: 17.8561947 },
    { lat: 48.8570674, lng: 17.8561684 },
    { lat: 48.8570969, lng: 17.8561518 },
    { lat: 48.8571195, lng: 17.856134 },
    { lat: 48.857512, lng: 17.8558641 },
    { lat: 48.8580135, lng: 17.8568987 },
    { lat: 48.858133, lng: 17.8571582 },
    { lat: 48.8581448, lng: 17.8571859 },
    { lat: 48.8581671, lng: 17.8572377 },
    { lat: 48.8581335, lng: 17.8572775 },
    { lat: 48.8580152, lng: 17.8574129 },
    { lat: 48.8581641, lng: 17.8576898 },
    { lat: 48.8582811, lng: 17.8579084 },
    { lat: 48.8582104, lng: 17.8580103 },
    { lat: 48.8582633, lng: 17.8581192 },
    { lat: 48.8583134, lng: 17.8582194 },
    { lat: 48.8580396, lng: 17.8584527 },
    { lat: 48.8582847, lng: 17.8586615 },
    { lat: 48.8586062, lng: 17.8581125 },
    { lat: 48.8591432, lng: 17.8576032 },
    { lat: 48.8598575, lng: 17.8570926 },
    { lat: 48.859906, lng: 17.8570583 },
    { lat: 48.8605043, lng: 17.8566434 },
    { lat: 48.8602913, lng: 17.8561484 },
    { lat: 48.8602941, lng: 17.855858 },
    { lat: 48.8603743, lng: 17.8558193 },
    { lat: 48.8604415, lng: 17.8557913 },
    { lat: 48.8605364, lng: 17.8556982 },
    { lat: 48.8606442, lng: 17.8555947 },
    { lat: 48.8607519, lng: 17.855569 },
    { lat: 48.8608913, lng: 17.8556336 },
    { lat: 48.8609511, lng: 17.8557458 },
    { lat: 48.8611578, lng: 17.8557304 },
    { lat: 48.8612658, lng: 17.8556652 },
    { lat: 48.8614167, lng: 17.8556027 },
    { lat: 48.8614336, lng: 17.8556043 },
    { lat: 48.8614826, lng: 17.8556871 },
    { lat: 48.8616207, lng: 17.8555615 },
    { lat: 48.8618418, lng: 17.8554659 },
    { lat: 48.8618574, lng: 17.8552731 },
    { lat: 48.8619542, lng: 17.8550628 },
    { lat: 48.8620824, lng: 17.8549647 },
    { lat: 48.8622157, lng: 17.8546842 },
    { lat: 48.8623573, lng: 17.8545229 },
    { lat: 48.8624715, lng: 17.8545092 },
    { lat: 48.8624871, lng: 17.8544852 },
    { lat: 48.8630171, lng: 17.8553933 },
    { lat: 48.8630582, lng: 17.855365 },
    { lat: 48.863168, lng: 17.8552003 },
    { lat: 48.8635155, lng: 17.8546559 },
    { lat: 48.8641427, lng: 17.8549292 },
    { lat: 48.8642855, lng: 17.8549944 },
    { lat: 48.864404, lng: 17.8550483 },
    { lat: 48.864499, lng: 17.8550899 },
    { lat: 48.86517, lng: 17.8552852 },
    { lat: 48.8652239, lng: 17.8553006 },
    { lat: 48.8653481, lng: 17.8553365 },
    { lat: 48.8658746, lng: 17.8553269 },
    { lat: 48.866047, lng: 17.8553034 },
    { lat: 48.8662711, lng: 17.855273 },
    { lat: 48.8663012, lng: 17.8552857 },
    { lat: 48.8666017, lng: 17.8554201 },
    { lat: 48.8670517, lng: 17.8556022 },
    { lat: 48.8670633, lng: 17.8556067 },
    { lat: 48.8672265, lng: 17.8556722 },
    { lat: 48.8672311, lng: 17.8556738 },
    { lat: 48.8676031, lng: 17.8558337 },
    { lat: 48.8678129, lng: 17.8561119 },
    { lat: 48.8680724, lng: 17.8563549 },
    { lat: 48.8681724, lng: 17.8564601 },
    { lat: 48.8683706, lng: 17.8566256 },
    { lat: 48.8687466, lng: 17.8569394 },
    { lat: 48.8688877, lng: 17.856992 },
    { lat: 48.8689745, lng: 17.857028 },
    { lat: 48.8690586, lng: 17.8570568 },
    { lat: 48.8690828, lng: 17.8570657 },
    { lat: 48.8692041, lng: 17.8571123 },
    { lat: 48.8694309, lng: 17.8571472 },
    { lat: 48.8696696, lng: 17.8571543 },
    { lat: 48.8700041, lng: 17.8571635 },
    { lat: 48.8702519, lng: 17.8571736 },
    { lat: 48.8704726, lng: 17.8571612 },
    { lat: 48.8706299, lng: 17.8571502 },
    { lat: 48.8706504, lng: 17.8571517 },
    { lat: 48.8714057, lng: 17.8570623 },
    { lat: 48.8718451, lng: 17.8571427 },
    { lat: 48.8721888, lng: 17.8571115 },
    { lat: 48.872457, lng: 17.8569926 },
    { lat: 48.8725653, lng: 17.8569331 },
    { lat: 48.8736972, lng: 17.8562017 },
    { lat: 48.874253, lng: 17.85587 },
    { lat: 48.8753536, lng: 17.8559447 },
    { lat: 48.8756964, lng: 17.855862 },
    { lat: 48.8772937, lng: 17.855488 },
    { lat: 48.877505, lng: 17.8554387 },
    { lat: 48.8783522, lng: 17.8553351 },
    { lat: 48.8792891, lng: 17.8552052 },
    { lat: 48.8804337, lng: 17.8539693 },
    { lat: 48.8815178, lng: 17.8528724 },
    { lat: 48.8817722, lng: 17.8525997 },
    { lat: 48.8825574, lng: 17.8525144 },
    { lat: 48.8836418, lng: 17.8546798 },
    { lat: 48.8837501, lng: 17.8546587 },
    { lat: 48.8854498, lng: 17.8542819 },
    { lat: 48.8859946, lng: 17.8540109 },
    { lat: 48.8864282, lng: 17.853828 },
    { lat: 48.88753, lng: 17.853301 },
    { lat: 48.8877909, lng: 17.8531762 },
    { lat: 48.888325, lng: 17.8527286 },
    { lat: 48.8888741, lng: 17.8522682 },
    { lat: 48.8890642, lng: 17.8521095 },
    { lat: 48.8903683, lng: 17.851644 },
    { lat: 48.8907088, lng: 17.8514342 },
    { lat: 48.8910539, lng: 17.8512213 },
    { lat: 48.892226, lng: 17.8504982 },
    { lat: 48.8931888, lng: 17.849562 },
    { lat: 48.8936846, lng: 17.849081 },
    { lat: 48.8938535, lng: 17.8490919 },
    { lat: 48.8950058, lng: 17.849164 },
    { lat: 48.8952438, lng: 17.8492698 },
    { lat: 48.8960829, lng: 17.8496319 },
    { lat: 48.8962879, lng: 17.8497211 },
    { lat: 48.8973224, lng: 17.8501959 },
    { lat: 48.8975282, lng: 17.850294 },
    { lat: 48.8976636, lng: 17.8503312 },
    { lat: 48.8989346, lng: 17.8506839 },
    { lat: 48.8991337, lng: 17.8504678 },
    { lat: 48.9000972, lng: 17.8495429 },
    { lat: 48.9006458, lng: 17.848776 },
    { lat: 48.900879, lng: 17.8484114 },
    { lat: 48.9012478, lng: 17.8477538 },
    { lat: 48.9017215, lng: 17.8472983 },
    { lat: 48.9029836, lng: 17.8456159 },
    { lat: 48.903024, lng: 17.845523 },
    { lat: 48.903047, lng: 17.845478 },
    { lat: 48.903246, lng: 17.844918 },
    { lat: 48.903295, lng: 17.844494 },
    { lat: 48.903324, lng: 17.844097 },
    { lat: 48.903257, lng: 17.843601 },
    { lat: 48.903314, lng: 17.843232 },
    { lat: 48.903293, lng: 17.842961 },
    { lat: 48.903264, lng: 17.842956 },
    { lat: 48.903091, lng: 17.842374 },
    { lat: 48.903062, lng: 17.841806 },
    { lat: 48.903074, lng: 17.841806 },
    { lat: 48.903085, lng: 17.841365 },
    { lat: 48.902926, lng: 17.840672 },
    { lat: 48.902825, lng: 17.840284 },
    { lat: 48.902806, lng: 17.839959 },
    { lat: 48.90277, lng: 17.839701 },
    { lat: 48.902689, lng: 17.839296 },
    { lat: 48.902661, lng: 17.838988 },
    { lat: 48.90249, lng: 17.838322 },
    { lat: 48.902337, lng: 17.83803 },
    { lat: 48.902059, lng: 17.837822 },
    { lat: 48.901954, lng: 17.83757 },
    { lat: 48.901886, lng: 17.83744 },
    { lat: 48.901686, lng: 17.837326 },
    { lat: 48.901645, lng: 17.837274 },
    { lat: 48.901599, lng: 17.837202 },
    { lat: 48.901457, lng: 17.836906 },
    { lat: 48.901257, lng: 17.836729 },
    { lat: 48.901077, lng: 17.83651 },
    { lat: 48.901012, lng: 17.836401 },
    { lat: 48.900921, lng: 17.836334 },
    { lat: 48.900781, lng: 17.836273 },
    { lat: 48.900585, lng: 17.836111 },
    { lat: 48.900417, lng: 17.835953 },
    { lat: 48.90031, lng: 17.8358 },
    { lat: 48.900226, lng: 17.835544 },
    { lat: 48.90007, lng: 17.835331 },
    { lat: 48.899717, lng: 17.835145 },
    { lat: 48.899283, lng: 17.835096 },
    { lat: 48.899064, lng: 17.834995 },
    { lat: 48.898792, lng: 17.834777 },
    { lat: 48.898303, lng: 17.834472 },
    { lat: 48.897746, lng: 17.834332 },
    { lat: 48.897529, lng: 17.834246 },
    { lat: 48.897425, lng: 17.834187 },
    { lat: 48.89726, lng: 17.834106 },
    { lat: 48.897091, lng: 17.834004 },
    { lat: 48.896828, lng: 17.833672 },
    { lat: 48.896721, lng: 17.833547 },
    { lat: 48.896686, lng: 17.833578 },
    { lat: 48.896652, lng: 17.833553 },
    { lat: 48.895851, lng: 17.833009 },
    { lat: 48.895895, lng: 17.832712 },
    { lat: 48.895506, lng: 17.832517 },
    { lat: 48.894976, lng: 17.832282 },
    { lat: 48.89476, lng: 17.832151 },
    { lat: 48.894771, lng: 17.832115 },
    { lat: 48.894384, lng: 17.831897 },
    { lat: 48.893914, lng: 17.831778 },
    { lat: 48.893689, lng: 17.831722 },
    { lat: 48.89343, lng: 17.831656 },
    { lat: 48.893107, lng: 17.831389 },
    { lat: 48.892819, lng: 17.83138 },
    { lat: 48.892527, lng: 17.831245 },
    { lat: 48.892287, lng: 17.831237 },
    { lat: 48.891636, lng: 17.831091 },
    { lat: 48.891135, lng: 17.830936 },
    { lat: 48.890816, lng: 17.830925 },
    { lat: 48.890647, lng: 17.830877 },
    { lat: 48.890434, lng: 17.830779 },
    { lat: 48.89037, lng: 17.830719 },
    { lat: 48.890341, lng: 17.830734 },
    { lat: 48.890096, lng: 17.830789 },
    { lat: 48.889803, lng: 17.830846 },
    { lat: 48.889641, lng: 17.83086 },
    { lat: 48.889248, lng: 17.83091 },
    { lat: 48.888765, lng: 17.831005 },
    { lat: 48.888649, lng: 17.831154 },
    { lat: 48.888451, lng: 17.83139 },
    { lat: 48.888126, lng: 17.831827 },
    { lat: 48.887667, lng: 17.832357 },
    { lat: 48.88706, lng: 17.832778 },
    { lat: 48.88655, lng: 17.833135 },
    { lat: 48.886038, lng: 17.833416 },
    { lat: 48.88545, lng: 17.83346 },
    { lat: 48.884771, lng: 17.833584 },
    { lat: 48.884754, lng: 17.8336 },
    { lat: 48.8841, lng: 17.833838 },
    { lat: 48.883286, lng: 17.834396 },
    { lat: 48.882788, lng: 17.834629 },
    { lat: 48.882294, lng: 17.834875 },
    { lat: 48.881586, lng: 17.835168 },
    { lat: 48.881527, lng: 17.835228 },
    { lat: 48.880748, lng: 17.835889 },
    { lat: 48.880639, lng: 17.836184 },
    { lat: 48.880167, lng: 17.836914 },
    { lat: 48.880025, lng: 17.837104 },
    { lat: 48.879527, lng: 17.837139 },
    { lat: 48.879203, lng: 17.837237 },
    { lat: 48.878951, lng: 17.837251 },
    { lat: 48.878802, lng: 17.837265 },
    { lat: 48.87812, lng: 17.837158 },
    { lat: 48.87796, lng: 17.837165 },
    { lat: 48.877428, lng: 17.837292 },
    { lat: 48.877172, lng: 17.837267 },
    { lat: 48.876621, lng: 17.837523 },
    { lat: 48.876032, lng: 17.837746 },
    { lat: 48.875992, lng: 17.837765 },
    { lat: 48.875337, lng: 17.838075 },
    { lat: 48.874886, lng: 17.838297 },
    { lat: 48.87477, lng: 17.838342 },
    { lat: 48.874501, lng: 17.838474 },
    { lat: 48.874127, lng: 17.838656 },
    { lat: 48.873208, lng: 17.839122 },
    { lat: 48.872789, lng: 17.839383 },
    { lat: 48.872225, lng: 17.839492 },
    { lat: 48.871758, lng: 17.839642 },
    { lat: 48.871193, lng: 17.83981 },
    { lat: 48.870672, lng: 17.839959 },
    { lat: 48.87037, lng: 17.840437 },
    { lat: 48.870143, lng: 17.840716 },
    { lat: 48.869824, lng: 17.840276 },
    { lat: 48.86952, lng: 17.840423 },
    { lat: 48.869074, lng: 17.840688 },
    { lat: 48.869045, lng: 17.840707 },
    { lat: 48.868994, lng: 17.840734 },
    { lat: 48.868653, lng: 17.840912 },
    { lat: 48.868556, lng: 17.841024 },
    { lat: 48.86834, lng: 17.841241 },
    { lat: 48.868197, lng: 17.84136 },
    { lat: 48.868009, lng: 17.841516 },
    { lat: 48.867934, lng: 17.841538 },
    { lat: 48.867669, lng: 17.841755 },
    { lat: 48.867443, lng: 17.841907 },
    { lat: 48.867399, lng: 17.84194 },
    { lat: 48.867345, lng: 17.84198 },
    { lat: 48.867223, lng: 17.842094 },
    { lat: 48.867109, lng: 17.842207 },
    { lat: 48.866896, lng: 17.842396 },
    { lat: 48.866788, lng: 17.842492 },
    { lat: 48.866636, lng: 17.842628 },
    { lat: 48.8666, lng: 17.84266 },
    { lat: 48.866652, lng: 17.84289 },
    { lat: 48.866566, lng: 17.842953 },
    { lat: 48.866344, lng: 17.843115 },
    { lat: 48.866448, lng: 17.843437 },
    { lat: 48.865987, lng: 17.844088 },
    { lat: 48.865142, lng: 17.845276 },
    { lat: 48.864765, lng: 17.845819 },
    { lat: 48.864835, lng: 17.845983 },
    { lat: 48.864303, lng: 17.846594 },
    { lat: 48.864157, lng: 17.846754 },
    { lat: 48.864027, lng: 17.84686 },
    { lat: 48.863844, lng: 17.847255 },
    { lat: 48.863637, lng: 17.84773 },
    { lat: 48.863205, lng: 17.847358 },
    { lat: 48.863032, lng: 17.847528 },
    { lat: 48.862505, lng: 17.848077 },
    { lat: 48.862429, lng: 17.848153 },
    { lat: 48.862061, lng: 17.848786 },
    { lat: 48.861878, lng: 17.849035 },
    { lat: 48.861716, lng: 17.848588 },
    { lat: 48.861697, lng: 17.848538 },
    { lat: 48.86161, lng: 17.848286 },
    { lat: 48.861078, lng: 17.848818 },
    { lat: 48.860853, lng: 17.849046 },
    { lat: 48.860945, lng: 17.849305 },
    { lat: 48.860945, lng: 17.849409 },
    { lat: 48.860946, lng: 17.849498 },
    { lat: 48.860947, lng: 17.849614 },
    { lat: 48.861033, lng: 17.849917 },
    { lat: 48.860734, lng: 17.850146 },
    { lat: 48.860626, lng: 17.850228 },
    { lat: 48.860602, lng: 17.850245 },
    { lat: 48.860562, lng: 17.850277 },
    { lat: 48.860478, lng: 17.850346 },
    { lat: 48.860185, lng: 17.850513 },
    { lat: 48.860171, lng: 17.850542 },
    { lat: 48.860023, lng: 17.851017 },
    { lat: 48.859978, lng: 17.85117 },
    { lat: 48.86003, lng: 17.852243 },
    { lat: 48.859533, lng: 17.85267 },
    { lat: 48.859462, lng: 17.852742 },
    { lat: 48.859272, lng: 17.852935 },
    { lat: 48.859198, lng: 17.853032 },
    { lat: 48.858922, lng: 17.853093 },
    { lat: 48.858765, lng: 17.853242 },
    { lat: 48.858737, lng: 17.853529 },
    { lat: 48.858587, lng: 17.853739 },
    { lat: 48.858371, lng: 17.853976 },
    { lat: 48.858251, lng: 17.854124 },
    { lat: 48.858164, lng: 17.854232 },
    { lat: 48.858058, lng: 17.854363 },
    { lat: 48.857892, lng: 17.854569 },
    { lat: 48.857459, lng: 17.855049 },
    { lat: 48.857403, lng: 17.854545 },
    { lat: 48.857392, lng: 17.854456 },
    { lat: 48.85736, lng: 17.853849 },
    { lat: 48.857198, lng: 17.853491 },
    { lat: 48.856973, lng: 17.853669 },
    { lat: 48.856708, lng: 17.853878 },
    { lat: 48.856636, lng: 17.853936 },
    { lat: 48.856494, lng: 17.854049 },
    { lat: 48.855988, lng: 17.854393 },
  ],
  MelčiceLieskové: [
    { lat: 48.855988, lng: 17.854393 },
    { lat: 48.855932, lng: 17.854285 },
    { lat: 48.855758, lng: 17.853935 },
    { lat: 48.855671, lng: 17.854122 },
    { lat: 48.855365, lng: 17.854747 },
    { lat: 48.855155, lng: 17.855183 },
    { lat: 48.854937, lng: 17.855577 },
    { lat: 48.85466, lng: 17.856027 },
    { lat: 48.854295, lng: 17.856765 },
    { lat: 48.853385, lng: 17.858368 },
    { lat: 48.85311, lng: 17.859022 },
    { lat: 48.853032, lng: 17.859224 },
    { lat: 48.852938, lng: 17.859599 },
    { lat: 48.852892, lng: 17.859727 },
    { lat: 48.852775, lng: 17.860036 },
    { lat: 48.85243, lng: 17.860523 },
    { lat: 48.852184, lng: 17.86087 },
    { lat: 48.852175, lng: 17.861083 },
    { lat: 48.852154, lng: 17.861112 },
    { lat: 48.851842, lng: 17.861621 },
    { lat: 48.851682, lng: 17.861724 },
    { lat: 48.851486, lng: 17.861846 },
    { lat: 48.851268, lng: 17.861897 },
    { lat: 48.851207, lng: 17.861903 },
    { lat: 48.851047, lng: 17.862331 },
    { lat: 48.8507644, lng: 17.8627194 },
    { lat: 48.8507656, lng: 17.8632644 },
    { lat: 48.8507082, lng: 17.8633996 },
    { lat: 48.8504793, lng: 17.8640005 },
    { lat: 48.8504013, lng: 17.8641117 },
    { lat: 48.8502406, lng: 17.8643683 },
    { lat: 48.8499037, lng: 17.8645641 },
    { lat: 48.8499328, lng: 17.8646547 },
    { lat: 48.8495865, lng: 17.8650506 },
    { lat: 48.8493373, lng: 17.8653543 },
    { lat: 48.8493664, lng: 17.8657818 },
    { lat: 48.8492406, lng: 17.8658695 },
    { lat: 48.8493487, lng: 17.8665355 },
    { lat: 48.8491258, lng: 17.8667148 },
    { lat: 48.8489192, lng: 17.8668828 },
    { lat: 48.8489966, lng: 17.8677004 },
    { lat: 48.8488949, lng: 17.8677155 },
    { lat: 48.8487638, lng: 17.8677344 },
    { lat: 48.8488862, lng: 17.8692787 },
    { lat: 48.8485643, lng: 17.8693688 },
    { lat: 48.8484826, lng: 17.8701836 },
    { lat: 48.8482643, lng: 17.8708715 },
    { lat: 48.8478217, lng: 17.8720495 },
    { lat: 48.8477399, lng: 17.8722662 },
    { lat: 48.8474304, lng: 17.8730097 },
    { lat: 48.8471736, lng: 17.8735656 },
    { lat: 48.8470272, lng: 17.8736636 },
    { lat: 48.846814, lng: 17.8737211 },
    { lat: 48.8467356, lng: 17.8738656 },
    { lat: 48.846688, lng: 17.873994 },
    { lat: 48.8466366, lng: 17.8740957 },
    { lat: 48.8465347, lng: 17.8742761 },
    { lat: 48.8465204, lng: 17.8743028 },
    { lat: 48.8465095, lng: 17.8743258 },
    { lat: 48.8464456, lng: 17.8747658 },
    { lat: 48.8461324, lng: 17.8759746 },
    { lat: 48.8460998, lng: 17.8760533 },
    { lat: 48.846054, lng: 17.8762134 },
    { lat: 48.8457341, lng: 17.8766357 },
    { lat: 48.8454738, lng: 17.8769437 },
    { lat: 48.8453638, lng: 17.8772546 },
    { lat: 48.8451986, lng: 17.8777168 },
    { lat: 48.845162, lng: 17.8778197 },
    { lat: 48.8449751, lng: 17.878334 },
    { lat: 48.8447724, lng: 17.8787741 },
    { lat: 48.8445599, lng: 17.8792371 },
    { lat: 48.8451286, lng: 17.8799657 },
    { lat: 48.8452856, lng: 17.8801569 },
    { lat: 48.8453491, lng: 17.8802327 },
    { lat: 48.8454143, lng: 17.8800036 },
    { lat: 48.8459458, lng: 17.8804323 },
    { lat: 48.8462364, lng: 17.8806499 },
    { lat: 48.8460925, lng: 17.8808253 },
    { lat: 48.8458022, lng: 17.8810481 },
    { lat: 48.8457126, lng: 17.8811164 },
    { lat: 48.8456539, lng: 17.8812854 },
    { lat: 48.8456464, lng: 17.8815725 },
    { lat: 48.8453939, lng: 17.8815235 },
    { lat: 48.8449705, lng: 17.8821425 },
    { lat: 48.844835, lng: 17.8826457 },
    { lat: 48.8449421, lng: 17.883471 },
    { lat: 48.844677, lng: 17.8837541 },
    { lat: 48.844647, lng: 17.8839518 },
    { lat: 48.844748, lng: 17.8841296 },
    { lat: 48.8443027, lng: 17.8848146 },
    { lat: 48.8439333, lng: 17.8848079 },
    { lat: 48.8438338, lng: 17.8848815 },
    { lat: 48.8436739, lng: 17.8855264 },
    { lat: 48.8433846, lng: 17.886029 },
    { lat: 48.8432143, lng: 17.8861041 },
    { lat: 48.843002, lng: 17.8866093 },
    { lat: 48.8427315, lng: 17.8866194 },
    { lat: 48.8424155, lng: 17.8869744 },
    { lat: 48.8423947, lng: 17.8870053 },
    { lat: 48.8416404, lng: 17.8881281 },
    { lat: 48.8416847, lng: 17.8882263 },
    { lat: 48.841694, lng: 17.8882476 },
    { lat: 48.8417265, lng: 17.8883202 },
    { lat: 48.842771, lng: 17.8898862 },
    { lat: 48.8428279, lng: 17.8900263 },
    { lat: 48.8428346, lng: 17.8900426 },
    { lat: 48.842955, lng: 17.8902987 },
    { lat: 48.8430374, lng: 17.8904099 },
    { lat: 48.8432155, lng: 17.8901818 },
    { lat: 48.8432652, lng: 17.8902223 },
    { lat: 48.8430767, lng: 17.8911238 },
    { lat: 48.8429616, lng: 17.8915937 },
    { lat: 48.8426478, lng: 17.8924028 },
    { lat: 48.8423615, lng: 17.893083 },
    { lat: 48.8418973, lng: 17.8941873 },
    { lat: 48.8418262, lng: 17.8943576 },
    { lat: 48.8414579, lng: 17.8949176 },
    { lat: 48.8410935, lng: 17.8954817 },
    { lat: 48.8405331, lng: 17.8963405 },
    { lat: 48.8402134, lng: 17.8969367 },
    { lat: 48.8399438, lng: 17.8974508 },
    { lat: 48.8398166, lng: 17.8976791 },
    { lat: 48.8395077, lng: 17.8981767 },
    { lat: 48.8392972, lng: 17.898544 },
    { lat: 48.8391209, lng: 17.8988409 },
    { lat: 48.8388025, lng: 17.8994201 },
    { lat: 48.8383227, lng: 17.9002459 },
    { lat: 48.8379231, lng: 17.9009079 },
    { lat: 48.8376279, lng: 17.9014785 },
    { lat: 48.8373987, lng: 17.9018927 },
    { lat: 48.8375369, lng: 17.902088 },
    { lat: 48.8370552, lng: 17.9030913 },
    { lat: 48.8370993, lng: 17.9031318 },
    { lat: 48.8369673, lng: 17.9033771 },
    { lat: 48.8363833, lng: 17.9044809 },
    { lat: 48.8361482, lng: 17.9049271 },
    { lat: 48.8356366, lng: 17.9052787 },
    { lat: 48.8356393, lng: 17.9055689 },
    { lat: 48.8356163, lng: 17.9056939 },
    { lat: 48.8356128, lng: 17.9057095 },
    { lat: 48.8355988, lng: 17.9057806 },
    { lat: 48.8355952, lng: 17.9057947 },
    { lat: 48.8355917, lng: 17.9058123 },
    { lat: 48.8356857, lng: 17.9060226 },
    { lat: 48.835703, lng: 17.9061066 },
    { lat: 48.8357397, lng: 17.9062639 },
    { lat: 48.8358002, lng: 17.9065587 },
    { lat: 48.8358034, lng: 17.9065736 },
    { lat: 48.8358089, lng: 17.9066091 },
    { lat: 48.835822, lng: 17.9066782 },
    { lat: 48.8358636, lng: 17.9070129 },
    { lat: 48.8358866, lng: 17.9073215 },
    { lat: 48.8359301, lng: 17.9076333 },
    { lat: 48.8359962, lng: 17.9078127 },
    { lat: 48.836122, lng: 17.9080297 },
    { lat: 48.8362984, lng: 17.9082806 },
    { lat: 48.8362536, lng: 17.9083617 },
    { lat: 48.8361248, lng: 17.9086044 },
    { lat: 48.8360915, lng: 17.9086695 },
    { lat: 48.8360053, lng: 17.9088337 },
    { lat: 48.8358696, lng: 17.909091 },
    { lat: 48.8356844, lng: 17.9094654 },
    { lat: 48.8353546, lng: 17.9102736 },
    { lat: 48.8353279, lng: 17.9103371 },
    { lat: 48.8351818, lng: 17.9107055 },
    { lat: 48.8348496, lng: 17.9117945 },
    { lat: 48.8346608, lng: 17.9123701 },
    { lat: 48.8344811, lng: 17.9129161 },
    { lat: 48.8339647, lng: 17.9143561 },
    { lat: 48.8339011, lng: 17.9145383 },
    { lat: 48.83383, lng: 17.9146505 },
    { lat: 48.8337485, lng: 17.9147785 },
    { lat: 48.8337009, lng: 17.9149287 },
    { lat: 48.8336869, lng: 17.9149743 },
    { lat: 48.833654, lng: 17.9150699 },
    { lat: 48.8336447, lng: 17.9150995 },
    { lat: 48.8332227, lng: 17.9162169 },
    { lat: 48.8327865, lng: 17.9173785 },
    { lat: 48.8324267, lng: 17.9183419 },
    { lat: 48.8315148, lng: 17.9206096 },
    { lat: 48.8313018, lng: 17.9211418 },
    { lat: 48.8311323, lng: 17.9215535 },
    { lat: 48.8307371, lng: 17.9225163 },
    { lat: 48.8303685, lng: 17.9234281 },
    { lat: 48.8299955, lng: 17.9243463 },
    { lat: 48.8296404, lng: 17.9252233 },
    { lat: 48.8295126, lng: 17.9255385 },
    { lat: 48.8290513, lng: 17.9266959 },
    { lat: 48.8285927, lng: 17.9278611 },
    { lat: 48.8282053, lng: 17.9288235 },
    { lat: 48.8280376, lng: 17.9292466 },
    { lat: 48.8279485, lng: 17.9294344 },
    { lat: 48.8276954, lng: 17.9299394 },
    { lat: 48.8276016, lng: 17.9301282 },
    { lat: 48.8273788, lng: 17.9305746 },
    { lat: 48.8271934, lng: 17.9309841 },
    { lat: 48.8271305, lng: 17.9311783 },
    { lat: 48.8271166, lng: 17.9312113 },
    { lat: 48.8270442, lng: 17.9314169 },
    { lat: 48.8270202, lng: 17.9314857 },
    { lat: 48.8268773, lng: 17.9318932 },
    { lat: 48.8262055, lng: 17.9337965 },
    { lat: 48.8258696, lng: 17.9347675 },
    { lat: 48.8266005, lng: 17.9376912 },
    { lat: 48.8267842, lng: 17.9384427 },
    { lat: 48.8268627, lng: 17.9385455 },
    { lat: 48.8268955, lng: 17.9385879 },
    { lat: 48.8272883, lng: 17.9390977 },
    { lat: 48.8275709, lng: 17.9394623 },
    { lat: 48.8289687, lng: 17.9412489 },
    { lat: 48.8294029, lng: 17.941772 },
    { lat: 48.8295312, lng: 17.9419357 },
    { lat: 48.8302639, lng: 17.9428869 },
    { lat: 48.83112, lng: 17.9440319 },
    { lat: 48.8310448, lng: 17.9441149 },
    { lat: 48.8308447, lng: 17.9445077 },
    { lat: 48.832414, lng: 17.9453847 },
    { lat: 48.8342256, lng: 17.9470129 },
    { lat: 48.8343733, lng: 17.9470256 },
    { lat: 48.8345772, lng: 17.9470382 },
    { lat: 48.8351337, lng: 17.9470771 },
    { lat: 48.8353592, lng: 17.947094 },
    { lat: 48.8359418, lng: 17.9471343 },
    { lat: 48.8361289, lng: 17.9471467 },
    { lat: 48.8361504, lng: 17.9470904 },
    { lat: 48.836371, lng: 17.9465339 },
    { lat: 48.8371246, lng: 17.9446346 },
    { lat: 48.8372337, lng: 17.9443603 },
    { lat: 48.8375233, lng: 17.9436334 },
    { lat: 48.8377644, lng: 17.9430186 },
    { lat: 48.8382246, lng: 17.9418595 },
    { lat: 48.838526, lng: 17.9411027 },
    { lat: 48.8387258, lng: 17.9406103 },
    { lat: 48.8390286, lng: 17.9398224 },
    { lat: 48.8393103, lng: 17.9390956 },
    { lat: 48.8393837, lng: 17.9389071 },
    { lat: 48.8395848, lng: 17.9383965 },
    { lat: 48.8395668, lng: 17.9383807 },
    { lat: 48.8393857, lng: 17.9382195 },
    { lat: 48.8390535, lng: 17.9379311 },
    { lat: 48.8389545, lng: 17.9378421 },
    { lat: 48.8389485, lng: 17.9378372 },
    { lat: 48.838828, lng: 17.9377283 },
    { lat: 48.8387081, lng: 17.9376448 },
    { lat: 48.8387776, lng: 17.93745 },
    { lat: 48.8388204, lng: 17.9373341 },
    { lat: 48.8388242, lng: 17.9373229 },
    { lat: 48.8388399, lng: 17.9372806 },
    { lat: 48.8388844, lng: 17.937158 },
    { lat: 48.8389688, lng: 17.9369286 },
    { lat: 48.8392038, lng: 17.9362891 },
    { lat: 48.8392555, lng: 17.9361526 },
    { lat: 48.8392933, lng: 17.9360479 },
    { lat: 48.8394338, lng: 17.9356793 },
    { lat: 48.8394787, lng: 17.9355599 },
    { lat: 48.8394843, lng: 17.9355433 },
    { lat: 48.8394961, lng: 17.9355105 },
    { lat: 48.8396206, lng: 17.9351753 },
    { lat: 48.8397402, lng: 17.9348536 },
    { lat: 48.8400855, lng: 17.9339456 },
    { lat: 48.8405651, lng: 17.9326963 },
    { lat: 48.8408859, lng: 17.931869 },
    { lat: 48.8412925, lng: 17.9308164 },
    { lat: 48.8412933, lng: 17.9308095 },
    { lat: 48.8416869, lng: 17.9298002 },
    { lat: 48.8421228, lng: 17.9286905 },
    { lat: 48.8422035, lng: 17.9284748 },
    { lat: 48.8422628, lng: 17.9282556 },
    { lat: 48.8422878, lng: 17.9281629 },
    { lat: 48.8423071, lng: 17.9280897 },
    { lat: 48.8423504, lng: 17.9279286 },
    { lat: 48.842763, lng: 17.9269005 },
    { lat: 48.8430228, lng: 17.92626 },
    { lat: 48.8430386, lng: 17.9262206 },
    { lat: 48.8440253, lng: 17.9272076 },
    { lat: 48.844953, lng: 17.928131 },
    { lat: 48.845317, lng: 17.9284997 },
    { lat: 48.8453894, lng: 17.9285786 },
    { lat: 48.8459406, lng: 17.9290356 },
    { lat: 48.8459771, lng: 17.9290603 },
    { lat: 48.8461589, lng: 17.9276456 },
    { lat: 48.8476687, lng: 17.9248445 },
    { lat: 48.8477565, lng: 17.9247619 },
    { lat: 48.8480292, lng: 17.9241528 },
    { lat: 48.8485713, lng: 17.9234 },
    { lat: 48.8486227, lng: 17.9233331 },
    { lat: 48.8486488, lng: 17.9233001 },
    { lat: 48.848731, lng: 17.9233182 },
    { lat: 48.8493567, lng: 17.9234173 },
    { lat: 48.8494801, lng: 17.9232154 },
    { lat: 48.8496178, lng: 17.9229854 },
    { lat: 48.8499727, lng: 17.9223912 },
    { lat: 48.8501673, lng: 17.9220777 },
    { lat: 48.8504238, lng: 17.9216676 },
    { lat: 48.8505496, lng: 17.921583 },
    { lat: 48.8505425, lng: 17.9215395 },
    { lat: 48.850451, lng: 17.9211213 },
    { lat: 48.8504108, lng: 17.9209401 },
    { lat: 48.8506753, lng: 17.9204737 },
    { lat: 48.8507152, lng: 17.9204055 },
    { lat: 48.8513462, lng: 17.9193321 },
    { lat: 48.8514882, lng: 17.9191402 },
    { lat: 48.8522183, lng: 17.9181542 },
    { lat: 48.8526068, lng: 17.9174962 },
    { lat: 48.8526241, lng: 17.9174652 },
    { lat: 48.8527716, lng: 17.9171589 },
    { lat: 48.8528429, lng: 17.9170459 },
    { lat: 48.8529425, lng: 17.9168651 },
    { lat: 48.8530231, lng: 17.9167218 },
    { lat: 48.8531127, lng: 17.9165597 },
    { lat: 48.8531943, lng: 17.9164126 },
    { lat: 48.8532759, lng: 17.916267 },
    { lat: 48.8533594, lng: 17.9161161 },
    { lat: 48.8534513, lng: 17.9159522 },
    { lat: 48.8535194, lng: 17.9158289 },
    { lat: 48.8535826, lng: 17.9157152 },
    { lat: 48.8535866, lng: 17.9157067 },
    { lat: 48.853646, lng: 17.9155857 },
    { lat: 48.8537096, lng: 17.9154583 },
    { lat: 48.8537778, lng: 17.9153181 },
    { lat: 48.8538494, lng: 17.9151735 },
    { lat: 48.8538825, lng: 17.9151069 },
    { lat: 48.8539172, lng: 17.9150426 },
    { lat: 48.8540023, lng: 17.9148793 },
    { lat: 48.8540832, lng: 17.9147255 },
    { lat: 48.8541643, lng: 17.9145714 },
    { lat: 48.8542484, lng: 17.9144107 },
    { lat: 48.8543254, lng: 17.9142636 },
    { lat: 48.8544198, lng: 17.9140846 },
    { lat: 48.8544896, lng: 17.9139517 },
    { lat: 48.8545331, lng: 17.9138683 },
    { lat: 48.854756, lng: 17.913933 },
    { lat: 48.8548778, lng: 17.9139675 },
    { lat: 48.8550034, lng: 17.913937 },
    { lat: 48.8550524, lng: 17.9139249 },
    { lat: 48.8551603, lng: 17.913899 },
    { lat: 48.8551814, lng: 17.913891 },
    { lat: 48.8554053, lng: 17.9138233 },
    { lat: 48.8556702, lng: 17.9137432 },
    { lat: 48.8556969, lng: 17.9137352 },
    { lat: 48.8561907, lng: 17.91352 },
    { lat: 48.8562292, lng: 17.9135033 },
    { lat: 48.8563271, lng: 17.91346 },
    { lat: 48.8567354, lng: 17.9133613 },
    { lat: 48.8571886, lng: 17.9130702 },
    { lat: 48.8575097, lng: 17.9128892 },
    { lat: 48.8575992, lng: 17.9128159 },
    { lat: 48.8576822, lng: 17.9125826 },
    { lat: 48.8577267, lng: 17.91246 },
    { lat: 48.8577691, lng: 17.9123395 },
    { lat: 48.8577885, lng: 17.9122845 },
    { lat: 48.857822, lng: 17.9122232 },
    { lat: 48.857885, lng: 17.9121063 },
    { lat: 48.857945, lng: 17.9119937 },
    { lat: 48.8580058, lng: 17.91188 },
    { lat: 48.8580164, lng: 17.9118678 },
    { lat: 48.8580289, lng: 17.9118517 },
    { lat: 48.8581282, lng: 17.9117201 },
    { lat: 48.8581315, lng: 17.9117157 },
    { lat: 48.858232, lng: 17.911604 },
    { lat: 48.8583102, lng: 17.9115162 },
    { lat: 48.8583244, lng: 17.9114885 },
    { lat: 48.8584032, lng: 17.911336 },
    { lat: 48.8584722, lng: 17.9112036 },
    { lat: 48.8585247, lng: 17.9111014 },
    { lat: 48.8585601, lng: 17.9110528 },
    { lat: 48.8586812, lng: 17.9108866 },
    { lat: 48.8587626, lng: 17.9107758 },
    { lat: 48.8587677, lng: 17.9107668 },
    { lat: 48.858859, lng: 17.9105973 },
    { lat: 48.8589415, lng: 17.9104458 },
    { lat: 48.8589669, lng: 17.9104003 },
    { lat: 48.8590279, lng: 17.9103252 },
    { lat: 48.859133, lng: 17.9101961 },
    { lat: 48.8592529, lng: 17.9100484 },
    { lat: 48.8593802, lng: 17.9098921 },
    { lat: 48.8594968, lng: 17.9097492 },
    { lat: 48.8596526, lng: 17.9095586 },
    { lat: 48.8598196, lng: 17.9093535 },
    { lat: 48.8598765, lng: 17.9092844 },
    { lat: 48.860032, lng: 17.9090923 },
    { lat: 48.8602876, lng: 17.9087783 },
    { lat: 48.8603065, lng: 17.9087545 },
    { lat: 48.8607659, lng: 17.9082471 },
    { lat: 48.8607671, lng: 17.9082466 },
    { lat: 48.8608083, lng: 17.9082001 },
    { lat: 48.8618804, lng: 17.906971 },
    { lat: 48.8620451, lng: 17.906782 },
    { lat: 48.8620756, lng: 17.9067426 },
    { lat: 48.8621986, lng: 17.9065924 },
    { lat: 48.8622894, lng: 17.9064892 },
    { lat: 48.8636041, lng: 17.9049398 },
    { lat: 48.8638227, lng: 17.904668 },
    { lat: 48.8638643, lng: 17.9046082 },
    { lat: 48.8639131, lng: 17.9045377 },
    { lat: 48.8639372, lng: 17.9045067 },
    { lat: 48.8640566, lng: 17.9043508 },
    { lat: 48.8642848, lng: 17.9040742 },
    { lat: 48.8647415, lng: 17.9034456 },
    { lat: 48.8655795, lng: 17.9021904 },
    { lat: 48.8657327, lng: 17.9020349 },
    { lat: 48.8658019, lng: 17.9019271 },
    { lat: 48.8659714, lng: 17.9016646 },
    { lat: 48.8667262, lng: 17.9004549 },
    { lat: 48.8671226, lng: 17.8999339 },
    { lat: 48.8677251, lng: 17.8992862 },
    { lat: 48.8684089, lng: 17.8980987 },
    { lat: 48.8684615, lng: 17.8976749 },
    { lat: 48.8686875, lng: 17.8967187 },
    { lat: 48.869185, lng: 17.8957182 },
    { lat: 48.8696465, lng: 17.8953204 },
    { lat: 48.8699299, lng: 17.8951325 },
    { lat: 48.8702841, lng: 17.8947563 },
    { lat: 48.8703798, lng: 17.8946359 },
    { lat: 48.8705145, lng: 17.894437 },
    { lat: 48.8708791, lng: 17.8938345 },
    { lat: 48.8710344, lng: 17.8936015 },
    { lat: 48.8712313, lng: 17.8933266 },
    { lat: 48.8715607, lng: 17.8929477 },
    { lat: 48.8720006, lng: 17.8929225 },
    { lat: 48.8720658, lng: 17.8929372 },
    { lat: 48.8723101, lng: 17.8930028 },
    { lat: 48.8724305, lng: 17.8930353 },
    { lat: 48.8726072, lng: 17.8930235 },
    { lat: 48.8728835, lng: 17.8929833 },
    { lat: 48.8729687, lng: 17.8929539 },
    { lat: 48.8730804, lng: 17.8929149 },
    { lat: 48.8732144, lng: 17.8928343 },
    { lat: 48.8734389, lng: 17.8925312 },
    { lat: 48.8734906, lng: 17.8924542 },
    { lat: 48.87367, lng: 17.8921877 },
    { lat: 48.8738237, lng: 17.8918524 },
    { lat: 48.8739552, lng: 17.8915248 },
    { lat: 48.8742846, lng: 17.8909569 },
    { lat: 48.8743986, lng: 17.8907317 },
    { lat: 48.87452, lng: 17.8904955 },
    { lat: 48.8746297, lng: 17.8903294 },
    { lat: 48.8748944, lng: 17.8901129 },
    { lat: 48.8749864, lng: 17.8899553 },
    { lat: 48.8753201, lng: 17.8893836 },
    { lat: 48.8754401, lng: 17.8891092 },
    { lat: 48.8755552, lng: 17.8891277 },
    { lat: 48.8761433, lng: 17.8890333 },
    { lat: 48.8762502, lng: 17.8889705 },
    { lat: 48.8762732, lng: 17.8889199 },
    { lat: 48.8763269, lng: 17.8888024 },
    { lat: 48.8766006, lng: 17.8881891 },
    { lat: 48.8767163, lng: 17.8881616 },
    { lat: 48.8777227, lng: 17.8879628 },
    { lat: 48.8782125, lng: 17.8876822 },
    { lat: 48.8786891, lng: 17.8875525 },
    { lat: 48.879015, lng: 17.8874376 },
    { lat: 48.8796166, lng: 17.8875841 },
    { lat: 48.879899, lng: 17.8876473 },
    { lat: 48.8800008, lng: 17.8876483 },
    { lat: 48.8806953, lng: 17.8872978 },
    { lat: 48.8807578, lng: 17.8872486 },
    { lat: 48.8810913, lng: 17.8866524 },
    { lat: 48.8813538, lng: 17.886141 },
    { lat: 48.8818122, lng: 17.8858403 },
    { lat: 48.8823096, lng: 17.8852694 },
    { lat: 48.8827743, lng: 17.8852447 },
    { lat: 48.8834037, lng: 17.8854641 },
    { lat: 48.8839345, lng: 17.8853735 },
    { lat: 48.884381, lng: 17.8853642 },
    { lat: 48.884513, lng: 17.8853639 },
    { lat: 48.8850232, lng: 17.8854559 },
    { lat: 48.8852923, lng: 17.8846745 },
    { lat: 48.883624, lng: 17.8824578 },
    { lat: 48.8840112, lng: 17.8821031 },
    { lat: 48.8840375, lng: 17.8820732 },
    { lat: 48.886196, lng: 17.8800081 },
    { lat: 48.8870684, lng: 17.8782433 },
    { lat: 48.8874137, lng: 17.8775459 },
    { lat: 48.8874354, lng: 17.8775135 },
    { lat: 48.8876846, lng: 17.8769999 },
    { lat: 48.888437, lng: 17.8775551 },
    { lat: 48.8886396, lng: 17.8776777 },
    { lat: 48.8888164, lng: 17.8775779 },
    { lat: 48.8891439, lng: 17.8772773 },
    { lat: 48.8894098, lng: 17.8769521 },
    { lat: 48.8896784, lng: 17.876595 },
    { lat: 48.8904363, lng: 17.8755338 },
    { lat: 48.8907724, lng: 17.8750583 },
    { lat: 48.8912215, lng: 17.8744358 },
    { lat: 48.8923624, lng: 17.8735325 },
    { lat: 48.89291, lng: 17.8709625 },
    { lat: 48.8929633, lng: 17.8706889 },
    { lat: 48.8929728, lng: 17.8706385 },
    { lat: 48.8934206, lng: 17.8683839 },
    { lat: 48.8937623, lng: 17.8666724 },
    { lat: 48.8943705, lng: 17.8660485 },
    { lat: 48.8945976, lng: 17.8658266 },
    { lat: 48.8946603, lng: 17.865763 },
    { lat: 48.8949815, lng: 17.8654376 },
    { lat: 48.8953902, lng: 17.8639761 },
    { lat: 48.8961359, lng: 17.8613826 },
    { lat: 48.8964876, lng: 17.8601772 },
    { lat: 48.8965904, lng: 17.8599918 },
    { lat: 48.8967473, lng: 17.859711 },
    { lat: 48.8967758, lng: 17.8596568 },
    { lat: 48.896788, lng: 17.8596358 },
    { lat: 48.8974431, lng: 17.8584263 },
    { lat: 48.8977397, lng: 17.8572948 },
    { lat: 48.8978178, lng: 17.8569284 },
    { lat: 48.8979743, lng: 17.8562283 },
    { lat: 48.8982422, lng: 17.8550341 },
    { lat: 48.8983592, lng: 17.8545073 },
    { lat: 48.8984911, lng: 17.8539027 },
    { lat: 48.898518, lng: 17.8537716 },
    { lat: 48.8985555, lng: 17.853624 },
    { lat: 48.8986512, lng: 17.85276 },
    { lat: 48.8987453, lng: 17.8519612 },
    { lat: 48.8989119, lng: 17.850833 },
    { lat: 48.8989346, lng: 17.8506839 },
    { lat: 48.8976636, lng: 17.8503312 },
    { lat: 48.8975282, lng: 17.850294 },
    { lat: 48.8973224, lng: 17.8501959 },
    { lat: 48.8962879, lng: 17.8497211 },
    { lat: 48.8960829, lng: 17.8496319 },
    { lat: 48.8952438, lng: 17.8492698 },
    { lat: 48.8950058, lng: 17.849164 },
    { lat: 48.8938535, lng: 17.8490919 },
    { lat: 48.8936846, lng: 17.849081 },
    { lat: 48.8931888, lng: 17.849562 },
    { lat: 48.892226, lng: 17.8504982 },
    { lat: 48.8910539, lng: 17.8512213 },
    { lat: 48.8907088, lng: 17.8514342 },
    { lat: 48.8903683, lng: 17.851644 },
    { lat: 48.8890642, lng: 17.8521095 },
    { lat: 48.8888741, lng: 17.8522682 },
    { lat: 48.888325, lng: 17.8527286 },
    { lat: 48.8877909, lng: 17.8531762 },
    { lat: 48.88753, lng: 17.853301 },
    { lat: 48.8864282, lng: 17.853828 },
    { lat: 48.8859946, lng: 17.8540109 },
    { lat: 48.8854498, lng: 17.8542819 },
    { lat: 48.8837501, lng: 17.8546587 },
    { lat: 48.8836418, lng: 17.8546798 },
    { lat: 48.8825574, lng: 17.8525144 },
    { lat: 48.8817722, lng: 17.8525997 },
    { lat: 48.8815178, lng: 17.8528724 },
    { lat: 48.8804337, lng: 17.8539693 },
    { lat: 48.8792891, lng: 17.8552052 },
    { lat: 48.8783522, lng: 17.8553351 },
    { lat: 48.877505, lng: 17.8554387 },
    { lat: 48.8772937, lng: 17.855488 },
    { lat: 48.8756964, lng: 17.855862 },
    { lat: 48.8753536, lng: 17.8559447 },
    { lat: 48.874253, lng: 17.85587 },
    { lat: 48.8736972, lng: 17.8562017 },
    { lat: 48.8725653, lng: 17.8569331 },
    { lat: 48.872457, lng: 17.8569926 },
    { lat: 48.8721888, lng: 17.8571115 },
    { lat: 48.8718451, lng: 17.8571427 },
    { lat: 48.8714057, lng: 17.8570623 },
    { lat: 48.8706504, lng: 17.8571517 },
    { lat: 48.8706299, lng: 17.8571502 },
    { lat: 48.8704726, lng: 17.8571612 },
    { lat: 48.8702519, lng: 17.8571736 },
    { lat: 48.8700041, lng: 17.8571635 },
    { lat: 48.8696696, lng: 17.8571543 },
    { lat: 48.8694309, lng: 17.8571472 },
    { lat: 48.8692041, lng: 17.8571123 },
    { lat: 48.8690828, lng: 17.8570657 },
    { lat: 48.8690586, lng: 17.8570568 },
    { lat: 48.8689745, lng: 17.857028 },
    { lat: 48.8688877, lng: 17.856992 },
    { lat: 48.8687466, lng: 17.8569394 },
    { lat: 48.8683706, lng: 17.8566256 },
    { lat: 48.8681724, lng: 17.8564601 },
    { lat: 48.8680724, lng: 17.8563549 },
    { lat: 48.8678129, lng: 17.8561119 },
    { lat: 48.8676031, lng: 17.8558337 },
    { lat: 48.8672311, lng: 17.8556738 },
    { lat: 48.8672265, lng: 17.8556722 },
    { lat: 48.8670633, lng: 17.8556067 },
    { lat: 48.8670517, lng: 17.8556022 },
    { lat: 48.8666017, lng: 17.8554201 },
    { lat: 48.8663012, lng: 17.8552857 },
    { lat: 48.8662711, lng: 17.855273 },
    { lat: 48.866047, lng: 17.8553034 },
    { lat: 48.8658746, lng: 17.8553269 },
    { lat: 48.8653481, lng: 17.8553365 },
    { lat: 48.8652239, lng: 17.8553006 },
    { lat: 48.86517, lng: 17.8552852 },
    { lat: 48.864499, lng: 17.8550899 },
    { lat: 48.864404, lng: 17.8550483 },
    { lat: 48.8642855, lng: 17.8549944 },
    { lat: 48.8641427, lng: 17.8549292 },
    { lat: 48.8635155, lng: 17.8546559 },
    { lat: 48.863168, lng: 17.8552003 },
    { lat: 48.8630582, lng: 17.855365 },
    { lat: 48.8630171, lng: 17.8553933 },
    { lat: 48.8624871, lng: 17.8544852 },
    { lat: 48.8624715, lng: 17.8545092 },
    { lat: 48.8623573, lng: 17.8545229 },
    { lat: 48.8622157, lng: 17.8546842 },
    { lat: 48.8620824, lng: 17.8549647 },
    { lat: 48.8619542, lng: 17.8550628 },
    { lat: 48.8618574, lng: 17.8552731 },
    { lat: 48.8618418, lng: 17.8554659 },
    { lat: 48.8616207, lng: 17.8555615 },
    { lat: 48.8614826, lng: 17.8556871 },
    { lat: 48.8614336, lng: 17.8556043 },
    { lat: 48.8614167, lng: 17.8556027 },
    { lat: 48.8612658, lng: 17.8556652 },
    { lat: 48.8611578, lng: 17.8557304 },
    { lat: 48.8609511, lng: 17.8557458 },
    { lat: 48.8608913, lng: 17.8556336 },
    { lat: 48.8607519, lng: 17.855569 },
    { lat: 48.8606442, lng: 17.8555947 },
    { lat: 48.8605364, lng: 17.8556982 },
    { lat: 48.8604415, lng: 17.8557913 },
    { lat: 48.8603743, lng: 17.8558193 },
    { lat: 48.8602941, lng: 17.855858 },
    { lat: 48.8602913, lng: 17.8561484 },
    { lat: 48.8605043, lng: 17.8566434 },
    { lat: 48.859906, lng: 17.8570583 },
    { lat: 48.8598575, lng: 17.8570926 },
    { lat: 48.8591432, lng: 17.8576032 },
    { lat: 48.8586062, lng: 17.8581125 },
    { lat: 48.8582847, lng: 17.8586615 },
    { lat: 48.8580396, lng: 17.8584527 },
    { lat: 48.8583134, lng: 17.8582194 },
    { lat: 48.8582633, lng: 17.8581192 },
    { lat: 48.8582104, lng: 17.8580103 },
    { lat: 48.8582811, lng: 17.8579084 },
    { lat: 48.8581641, lng: 17.8576898 },
    { lat: 48.8580152, lng: 17.8574129 },
    { lat: 48.8581335, lng: 17.8572775 },
    { lat: 48.8581671, lng: 17.8572377 },
    { lat: 48.8581448, lng: 17.8571859 },
    { lat: 48.858133, lng: 17.8571582 },
    { lat: 48.8580135, lng: 17.8568987 },
    { lat: 48.857512, lng: 17.8558641 },
    { lat: 48.8571195, lng: 17.856134 },
    { lat: 48.8570969, lng: 17.8561518 },
    { lat: 48.8570674, lng: 17.8561684 },
    { lat: 48.8570307, lng: 17.8561947 },
    { lat: 48.8569355, lng: 17.8562631 },
    { lat: 48.8570156, lng: 17.8565448 },
    { lat: 48.8570618, lng: 17.8567109 },
    { lat: 48.8569189, lng: 17.8568852 },
    { lat: 48.8568779, lng: 17.8567862 },
    { lat: 48.8566515, lng: 17.8562343 },
    { lat: 48.8566104, lng: 17.8561321 },
    { lat: 48.8563045, lng: 17.8553841 },
    { lat: 48.855988, lng: 17.854393 },
  ],
  Štvrtok: [
    { lat: 48.8106177, lng: 17.8668409 },
    { lat: 48.81005, lng: 17.866868 },
    { lat: 48.809808, lng: 17.866965 },
    { lat: 48.809673, lng: 17.867032 },
    { lat: 48.809446, lng: 17.867372 },
    { lat: 48.809343, lng: 17.867579 },
    { lat: 48.809172, lng: 17.867766 },
    { lat: 48.809008, lng: 17.867838 },
    { lat: 48.808891, lng: 17.867988 },
    { lat: 48.808778, lng: 17.868197 },
    { lat: 48.80861, lng: 17.868405 },
    { lat: 48.80845, lng: 17.86888 },
    { lat: 48.808443, lng: 17.868899 },
    { lat: 48.808355, lng: 17.869116 },
    { lat: 48.808209, lng: 17.869436 },
    { lat: 48.808046, lng: 17.869786 },
    { lat: 48.807908, lng: 17.870282 },
    { lat: 48.807892, lng: 17.870346 },
    { lat: 48.80787, lng: 17.870426 },
    { lat: 48.807851, lng: 17.870497 },
    { lat: 48.807674, lng: 17.871156 },
    { lat: 48.807651, lng: 17.871214 },
    { lat: 48.807347, lng: 17.87105 },
    { lat: 48.807337, lng: 17.871043 },
    { lat: 48.807312, lng: 17.871031 },
    { lat: 48.807068, lng: 17.870897 },
    { lat: 48.807036, lng: 17.870876 },
    { lat: 48.806847, lng: 17.870775 },
    { lat: 48.806814, lng: 17.870757 },
    { lat: 48.806809, lng: 17.870755 },
    { lat: 48.806039, lng: 17.870605 },
    { lat: 48.806038, lng: 17.870605 },
    { lat: 48.806015, lng: 17.870598 },
    { lat: 48.806012, lng: 17.870597 },
    { lat: 48.805795, lng: 17.870557 },
    { lat: 48.805727, lng: 17.870545 },
    { lat: 48.805579, lng: 17.870518 },
    { lat: 48.805386, lng: 17.87054 },
    { lat: 48.805239, lng: 17.870552 },
    { lat: 48.805212, lng: 17.870645 },
    { lat: 48.805084, lng: 17.871093 },
    { lat: 48.805074, lng: 17.871105 },
    { lat: 48.80507, lng: 17.871111 },
    { lat: 48.805044, lng: 17.871156 },
    { lat: 48.805035, lng: 17.87117 },
    { lat: 48.804833, lng: 17.871455 },
    { lat: 48.80483, lng: 17.87146 },
    { lat: 48.804676, lng: 17.871703 },
    { lat: 48.804558, lng: 17.871911 },
    { lat: 48.804451, lng: 17.8721 },
    { lat: 48.804448, lng: 17.872103 },
    { lat: 48.804315, lng: 17.872238 },
    { lat: 48.804075, lng: 17.872482 },
    { lat: 48.803859, lng: 17.872702 },
    { lat: 48.803663, lng: 17.872901 },
    { lat: 48.803656, lng: 17.872908 },
    { lat: 48.803475, lng: 17.873092 },
    { lat: 48.803307, lng: 17.873264 },
    { lat: 48.803144, lng: 17.873431 },
    { lat: 48.802991, lng: 17.873588 },
    { lat: 48.802927, lng: 17.873653 },
    { lat: 48.802852, lng: 17.873735 },
    { lat: 48.803926, lng: 17.875041 },
    { lat: 48.805066, lng: 17.876428 },
    { lat: 48.805031, lng: 17.876697 },
    { lat: 48.804996, lng: 17.876956 },
    { lat: 48.804967, lng: 17.877222 },
    { lat: 48.807092, lng: 17.879311 },
    { lat: 48.807659, lng: 17.880498 },
    { lat: 48.808415, lng: 17.882082 },
    { lat: 48.808426, lng: 17.882128 },
    { lat: 48.808429, lng: 17.882148 },
    { lat: 48.808656, lng: 17.883202 },
    { lat: 48.809323, lng: 17.884459 },
    { lat: 48.810619, lng: 17.886901 },
    { lat: 48.810647, lng: 17.886952 },
    { lat: 48.810654, lng: 17.886964 },
    { lat: 48.810716, lng: 17.887071 },
    { lat: 48.810727, lng: 17.887089 },
    { lat: 48.810743, lng: 17.887115 },
    { lat: 48.811172, lng: 17.887783 },
    { lat: 48.81142, lng: 17.888165 },
    { lat: 48.811462, lng: 17.888232 },
    { lat: 48.81177, lng: 17.888694 },
    { lat: 48.811906, lng: 17.888482 },
    { lat: 48.811924, lng: 17.888458 },
    { lat: 48.81193, lng: 17.888448 },
    { lat: 48.811999, lng: 17.888345 },
    { lat: 48.812028, lng: 17.888304 },
    { lat: 48.812032, lng: 17.888297 },
    { lat: 48.812128, lng: 17.888151 },
    { lat: 48.812428, lng: 17.888582 },
    { lat: 48.812531, lng: 17.888734 },
    { lat: 48.812584, lng: 17.888803 },
    { lat: 48.812626, lng: 17.888866 },
    { lat: 48.812689, lng: 17.888956 },
    { lat: 48.812747, lng: 17.889042 },
    { lat: 48.812812, lng: 17.889144 },
    { lat: 48.812927, lng: 17.88931 },
    { lat: 48.81304, lng: 17.889475 },
    { lat: 48.813233, lng: 17.889761 },
    { lat: 48.81331, lng: 17.889874 },
    { lat: 48.813374, lng: 17.889968 },
    { lat: 48.813418, lng: 17.89003 },
    { lat: 48.813479, lng: 17.890126 },
    { lat: 48.813545, lng: 17.890211 },
    { lat: 48.813698, lng: 17.890445 },
    { lat: 48.813973, lng: 17.890849 },
    { lat: 48.814013, lng: 17.890916 },
    { lat: 48.814264, lng: 17.891293 },
    { lat: 48.814254, lng: 17.891375 },
    { lat: 48.814252, lng: 17.891391 },
    { lat: 48.814245, lng: 17.891449 },
    { lat: 48.814242, lng: 17.891499 },
    { lat: 48.814184, lng: 17.891719 },
    { lat: 48.814058, lng: 17.892021 },
    { lat: 48.813797, lng: 17.89246 },
    { lat: 48.813546, lng: 17.892829 },
    { lat: 48.812987, lng: 17.893502 },
    { lat: 48.812972, lng: 17.89352 },
    { lat: 48.812948, lng: 17.893543 },
    { lat: 48.812724, lng: 17.893778 },
    { lat: 48.812586, lng: 17.893783 },
    { lat: 48.812576, lng: 17.893932 },
    { lat: 48.812563, lng: 17.894087 },
    { lat: 48.812375, lng: 17.896211 },
    { lat: 48.812318, lng: 17.897117 },
    { lat: 48.812419, lng: 17.898397 },
    { lat: 48.812571, lng: 17.899024 },
    { lat: 48.812834, lng: 17.900095 },
    { lat: 48.8129351, lng: 17.8999303 },
    { lat: 48.8129703, lng: 17.8998739 },
    { lat: 48.8130011, lng: 17.8998238 },
    { lat: 48.8132083, lng: 17.9000793 },
    { lat: 48.8133871, lng: 17.900367 },
    { lat: 48.8136292, lng: 17.900771 },
    { lat: 48.8137084, lng: 17.9010036 },
    { lat: 48.8137673, lng: 17.9009131 },
    { lat: 48.8142955, lng: 17.9001213 },
    { lat: 48.8148137, lng: 17.8993505 },
    { lat: 48.8149961, lng: 17.8990804 },
    { lat: 48.8151512, lng: 17.8988427 },
    { lat: 48.8151718, lng: 17.8988105 },
    { lat: 48.8154078, lng: 17.8984563 },
    { lat: 48.8158391, lng: 17.8978153 },
    { lat: 48.8161579, lng: 17.897338 },
    { lat: 48.8161821, lng: 17.8973068 },
    { lat: 48.8163657, lng: 17.8974498 },
    { lat: 48.8166413, lng: 17.8976583 },
    { lat: 48.816967, lng: 17.8978953 },
    { lat: 48.8172316, lng: 17.8980914 },
    { lat: 48.8174762, lng: 17.8982726 },
    { lat: 48.8176971, lng: 17.8984374 },
    { lat: 48.8177172, lng: 17.8984525 },
    { lat: 48.8179596, lng: 17.8982032 },
    { lat: 48.818415, lng: 17.8977404 },
    { lat: 48.8184894, lng: 17.8976632 },
    { lat: 48.8185052, lng: 17.8976474 },
    { lat: 48.8185287, lng: 17.8976241 },
    { lat: 48.8185821, lng: 17.8975727 },
    { lat: 48.8186504, lng: 17.8976775 },
    { lat: 48.8186907, lng: 17.8976315 },
    { lat: 48.818831, lng: 17.8974703 },
    { lat: 48.8188533, lng: 17.8974447 },
    { lat: 48.8189954, lng: 17.8972944 },
    { lat: 48.8193434, lng: 17.8969241 },
    { lat: 48.8196351, lng: 17.8966103 },
    { lat: 48.8199904, lng: 17.8962321 },
    { lat: 48.8203798, lng: 17.895816 },
    { lat: 48.8207025, lng: 17.8954746 },
    { lat: 48.8210188, lng: 17.8951409 },
    { lat: 48.8214961, lng: 17.8946317 },
    { lat: 48.8220523, lng: 17.8940435 },
    { lat: 48.8224117, lng: 17.893658 },
    { lat: 48.822663, lng: 17.8933884 },
    { lat: 48.822819, lng: 17.8932411 },
    { lat: 48.8229908, lng: 17.8930563 },
    { lat: 48.8230271, lng: 17.8930197 },
    { lat: 48.8230636, lng: 17.8929896 },
    { lat: 48.8231204, lng: 17.8929585 },
    { lat: 48.8231547, lng: 17.8929463 },
    { lat: 48.8232412, lng: 17.8929419 },
    { lat: 48.8233027, lng: 17.8929485 },
    { lat: 48.823365, lng: 17.8929707 },
    { lat: 48.8234238, lng: 17.892993 },
    { lat: 48.8234927, lng: 17.893014 },
    { lat: 48.8236248, lng: 17.8931602 },
    { lat: 48.823695, lng: 17.8932419 },
    { lat: 48.8238151, lng: 17.893379 },
    { lat: 48.8239748, lng: 17.8935576 },
    { lat: 48.8241283, lng: 17.8935207 },
    { lat: 48.8242674, lng: 17.8934858 },
    { lat: 48.8245082, lng: 17.8933448 },
    { lat: 48.8248379, lng: 17.8931557 },
    { lat: 48.8250791, lng: 17.8929809 },
    { lat: 48.8251385, lng: 17.8929373 },
    { lat: 48.8251887, lng: 17.8929074 },
    { lat: 48.8251667, lng: 17.8928238 },
    { lat: 48.8251537, lng: 17.8927772 },
    { lat: 48.8251378, lng: 17.8927178 },
    { lat: 48.8251333, lng: 17.8926969 },
    { lat: 48.8251236, lng: 17.8926509 },
    { lat: 48.8251077, lng: 17.892574 },
    { lat: 48.8250721, lng: 17.8923765 },
    { lat: 48.8250131, lng: 17.8921256 },
    { lat: 48.8248714, lng: 17.8917203 },
    { lat: 48.8247349, lng: 17.8913859 },
    { lat: 48.8245323, lng: 17.8907582 },
    { lat: 48.8249836, lng: 17.8905656 },
    { lat: 48.8251307, lng: 17.8904977 },
    { lat: 48.8260591, lng: 17.8900924 },
    { lat: 48.8269741, lng: 17.8896922 },
    { lat: 48.8278638, lng: 17.8892994 },
    { lat: 48.828616, lng: 17.8889689 },
    { lat: 48.8291483, lng: 17.8887332 },
    { lat: 48.8291636, lng: 17.8876187 },
    { lat: 48.8291736, lng: 17.8869402 },
    { lat: 48.8291767, lng: 17.8866929 },
    { lat: 48.8291888, lng: 17.8860284 },
    { lat: 48.8291363, lng: 17.8860203 },
    { lat: 48.8289271, lng: 17.8859961 },
    { lat: 48.8287058, lng: 17.8859732 },
    { lat: 48.8286852, lng: 17.8859693 },
    { lat: 48.8280333, lng: 17.8858088 },
    { lat: 48.8275461, lng: 17.8856879 },
    { lat: 48.827253, lng: 17.8856162 },
    { lat: 48.8271853, lng: 17.8856356 },
    { lat: 48.8277262, lng: 17.8851887 },
    { lat: 48.8278962, lng: 17.8849758 },
    { lat: 48.8280794, lng: 17.8847574 },
    { lat: 48.8282818, lng: 17.884407 },
    { lat: 48.8285525, lng: 17.8840271 },
    { lat: 48.8291061, lng: 17.8834535 },
    { lat: 48.8294029, lng: 17.8830399 },
    { lat: 48.8295543, lng: 17.8829105 },
    { lat: 48.8297829, lng: 17.8827309 },
    { lat: 48.8298509, lng: 17.8826037 },
    { lat: 48.8298955, lng: 17.8825037 },
    { lat: 48.8301116, lng: 17.8821915 },
    { lat: 48.8302681, lng: 17.881659 },
    { lat: 48.8304266, lng: 17.8810857 },
    { lat: 48.8304, lng: 17.8804004 },
    { lat: 48.8303934, lng: 17.8801007 },
    { lat: 48.8303768, lng: 17.8790748 },
    { lat: 48.8303896, lng: 17.8786911 },
    { lat: 48.8303789, lng: 17.8785127 },
    { lat: 48.8303695, lng: 17.8783567 },
    { lat: 48.830316, lng: 17.877329 },
    { lat: 48.830154, lng: 17.87654 },
    { lat: 48.829953, lng: 17.876233 },
    { lat: 48.829697, lng: 17.875944 },
    { lat: 48.829255, lng: 17.875801 },
    { lat: 48.828916, lng: 17.875532 },
    { lat: 48.828734, lng: 17.875264 },
    { lat: 48.828696, lng: 17.87525 },
    { lat: 48.828576, lng: 17.875209 },
    { lat: 48.828498, lng: 17.875185 },
    { lat: 48.828349, lng: 17.875136 },
    { lat: 48.828308, lng: 17.875123 },
    { lat: 48.828251, lng: 17.875118 },
    { lat: 48.828217, lng: 17.875114 },
    { lat: 48.828186, lng: 17.87511 },
    { lat: 48.828109, lng: 17.8751 },
    { lat: 48.828062, lng: 17.875097 },
    { lat: 48.828015, lng: 17.875094 },
    { lat: 48.827964, lng: 17.875088 },
    { lat: 48.827935, lng: 17.875085 },
    { lat: 48.827898, lng: 17.875082 },
    { lat: 48.827571, lng: 17.875962 },
    { lat: 48.827537, lng: 17.876004 },
    { lat: 48.827415, lng: 17.876134 },
    { lat: 48.827381, lng: 17.876173 },
    { lat: 48.827295, lng: 17.875996 },
    { lat: 48.82726, lng: 17.87593 },
    { lat: 48.827256, lng: 17.87592 },
    { lat: 48.827251, lng: 17.875916 },
    { lat: 48.827249, lng: 17.875909 },
    { lat: 48.827246, lng: 17.875906 },
    { lat: 48.827244, lng: 17.875899 },
    { lat: 48.827211, lng: 17.875838 },
    { lat: 48.827199, lng: 17.875813 },
    { lat: 48.827181, lng: 17.875779 },
    { lat: 48.827173, lng: 17.875763 },
    { lat: 48.827125, lng: 17.875748 },
    { lat: 48.827074, lng: 17.875726 },
    { lat: 48.827023, lng: 17.875651 },
    { lat: 48.826988, lng: 17.875599 },
    { lat: 48.826969, lng: 17.875574 },
    { lat: 48.826953, lng: 17.875546 },
    { lat: 48.826917, lng: 17.87549 },
    { lat: 48.826854, lng: 17.8754 },
    { lat: 48.826847, lng: 17.875371 },
    { lat: 48.82682, lng: 17.875272 },
    { lat: 48.826764, lng: 17.875058 },
    { lat: 48.826718, lng: 17.874878 },
    { lat: 48.826685, lng: 17.874672 },
    { lat: 48.826676, lng: 17.874617 },
    { lat: 48.826671, lng: 17.874573 },
    { lat: 48.826663, lng: 17.874526 },
    { lat: 48.826656, lng: 17.874485 },
    { lat: 48.826653, lng: 17.874458 },
    { lat: 48.826644, lng: 17.874391 },
    { lat: 48.826637, lng: 17.87435 },
    { lat: 48.826619, lng: 17.874218 },
    { lat: 48.826607, lng: 17.874129 },
    { lat: 48.826024, lng: 17.874562 },
    { lat: 48.825423, lng: 17.875005 },
    { lat: 48.825475, lng: 17.875073 },
    { lat: 48.825523, lng: 17.875137 },
    { lat: 48.825482, lng: 17.875128 },
    { lat: 48.825408, lng: 17.875111 },
    { lat: 48.825373, lng: 17.875113 },
    { lat: 48.825339, lng: 17.875114 },
    { lat: 48.825291, lng: 17.875118 },
    { lat: 48.825233, lng: 17.875118 },
    { lat: 48.825219, lng: 17.87512 },
    { lat: 48.8252, lng: 17.875123 },
    { lat: 48.825111, lng: 17.875127 },
    { lat: 48.825017, lng: 17.875142 },
    { lat: 48.824938, lng: 17.875145 },
    { lat: 48.824835, lng: 17.875213 },
    { lat: 48.824585, lng: 17.875369 },
    { lat: 48.82438, lng: 17.875165 },
    { lat: 48.824288, lng: 17.875079 },
    { lat: 48.824195, lng: 17.874986 },
    { lat: 48.824091, lng: 17.874858 },
    { lat: 48.824079, lng: 17.874844 },
    { lat: 48.824126, lng: 17.874738 },
    { lat: 48.824033, lng: 17.874622 },
    { lat: 48.824016, lng: 17.874605 },
    { lat: 48.82385, lng: 17.874386 },
    { lat: 48.82401, lng: 17.87428 },
    { lat: 48.824098, lng: 17.874223 },
    { lat: 48.824109, lng: 17.873896 },
    { lat: 48.823969, lng: 17.873576 },
    { lat: 48.823919, lng: 17.873461 },
    { lat: 48.823707, lng: 17.873036 },
    { lat: 48.823565, lng: 17.872859 },
    { lat: 48.823311, lng: 17.872783 },
    { lat: 48.822864, lng: 17.872878 },
    { lat: 48.822716, lng: 17.873191 },
    { lat: 48.822346, lng: 17.873802 },
    { lat: 48.822166, lng: 17.874111 },
    { lat: 48.822062, lng: 17.873978 },
    { lat: 48.821926, lng: 17.873811 },
    { lat: 48.821903, lng: 17.873774 },
    { lat: 48.821883, lng: 17.873743 },
    { lat: 48.821822, lng: 17.873653 },
    { lat: 48.821733, lng: 17.873934 },
    { lat: 48.82169, lng: 17.874063 },
    { lat: 48.821639, lng: 17.874155 },
    { lat: 48.821577, lng: 17.874261 },
    { lat: 48.821505, lng: 17.874344 },
    { lat: 48.821364, lng: 17.874508 },
    { lat: 48.821299, lng: 17.874577 },
    { lat: 48.82123, lng: 17.874389 },
    { lat: 48.821147, lng: 17.874151 },
    { lat: 48.821126, lng: 17.874097 },
    { lat: 48.820967, lng: 17.873701 },
    { lat: 48.820646, lng: 17.873052 },
    { lat: 48.820342, lng: 17.872653 },
    { lat: 48.820265, lng: 17.872616 },
    { lat: 48.820039, lng: 17.872497 },
    { lat: 48.8193374, lng: 17.8717325 },
    { lat: 48.819291, lng: 17.871682 },
    { lat: 48.819561, lng: 17.871194 },
    { lat: 48.819512, lng: 17.871127 },
    { lat: 48.819467, lng: 17.871059 },
    { lat: 48.819312, lng: 17.870831 },
    { lat: 48.819236, lng: 17.870718 },
    { lat: 48.818875, lng: 17.871574 },
    { lat: 48.818802, lng: 17.870684 },
    { lat: 48.818983, lng: 17.870301 },
    { lat: 48.819204, lng: 17.869835 },
    { lat: 48.819177, lng: 17.869776 },
    { lat: 48.819147, lng: 17.869704 },
    { lat: 48.818956, lng: 17.869263 },
    { lat: 48.817918, lng: 17.869901 },
    { lat: 48.817654, lng: 17.870168 },
    { lat: 48.817043, lng: 17.869511 },
    { lat: 48.816625, lng: 17.868948 },
    { lat: 48.816396, lng: 17.868578 },
    { lat: 48.815763, lng: 17.867417 },
    { lat: 48.815023, lng: 17.867158 },
    { lat: 48.814546, lng: 17.866995 },
    { lat: 48.813824, lng: 17.866751 },
    { lat: 48.8138587, lng: 17.8664863 },
    { lat: 48.813456, lng: 17.866436 },
    { lat: 48.812916, lng: 17.866368 },
    { lat: 48.812016, lng: 17.866519 },
    { lat: 48.811725, lng: 17.866563 },
    { lat: 48.811384, lng: 17.86662 },
    { lat: 48.810952, lng: 17.86684 },
    { lat: 48.810804, lng: 17.866843 },
    { lat: 48.810649, lng: 17.866852 },
    { lat: 48.8106177, lng: 17.8668409 },
  ],
  Dubodiel: [
    { lat: 48.751937, lng: 18.131985 },
    { lat: 48.752158, lng: 18.132315 },
    { lat: 48.75249, lng: 18.132782 },
    { lat: 48.752545, lng: 18.132829 },
    { lat: 48.752943, lng: 18.133094 },
    { lat: 48.753235, lng: 18.133348 },
    { lat: 48.753427, lng: 18.133564 },
    { lat: 48.75355, lng: 18.133665 },
    { lat: 48.753624, lng: 18.133695 },
    { lat: 48.753823, lng: 18.133778 },
    { lat: 48.754018, lng: 18.133855 },
    { lat: 48.754059, lng: 18.133874 },
    { lat: 48.754242, lng: 18.133963 },
    { lat: 48.754475, lng: 18.133939 },
    { lat: 48.754663, lng: 18.133825 },
    { lat: 48.75491, lng: 18.133706 },
    { lat: 48.754973, lng: 18.133676 },
    { lat: 48.755073, lng: 18.133628 },
    { lat: 48.755458, lng: 18.133292 },
    { lat: 48.755672, lng: 18.133106 },
    { lat: 48.756049, lng: 18.13288 },
    { lat: 48.756431, lng: 18.132774 },
    { lat: 48.756603, lng: 18.132809 },
    { lat: 48.75713, lng: 18.13254 },
    { lat: 48.757226, lng: 18.132577 },
    { lat: 48.757271, lng: 18.132594 },
    { lat: 48.757332, lng: 18.132617 },
    { lat: 48.757419, lng: 18.132651 },
    { lat: 48.757482, lng: 18.132675 },
    { lat: 48.757509, lng: 18.132686 },
    { lat: 48.757547, lng: 18.1327 },
    { lat: 48.757586, lng: 18.132716 },
    { lat: 48.757626, lng: 18.132731 },
    { lat: 48.757645, lng: 18.132739 },
    { lat: 48.757779, lng: 18.132792 },
    { lat: 48.757854, lng: 18.132825 },
    { lat: 48.757883, lng: 18.132816 },
    { lat: 48.757931, lng: 18.132794 },
    { lat: 48.757974, lng: 18.132774 },
    { lat: 48.758039, lng: 18.132744 },
    { lat: 48.758322, lng: 18.132692 },
    { lat: 48.758648, lng: 18.132685 },
    { lat: 48.758896, lng: 18.132697 },
    { lat: 48.75911, lng: 18.132895 },
    { lat: 48.759255, lng: 18.13307 },
    { lat: 48.759357, lng: 18.13319 },
    { lat: 48.75957, lng: 18.133514 },
    { lat: 48.759593, lng: 18.133547 },
    { lat: 48.759615, lng: 18.133577 },
    { lat: 48.7596532, lng: 18.1329667 },
    { lat: 48.7597162, lng: 18.1322229 },
    { lat: 48.7597842, lng: 18.1313737 },
    { lat: 48.759854, lng: 18.1305142 },
    { lat: 48.7599188, lng: 18.1297287 },
    { lat: 48.7611218, lng: 18.1296887 },
    { lat: 48.7623078, lng: 18.1302114 },
    { lat: 48.7624731, lng: 18.1302406 },
    { lat: 48.7624329, lng: 18.1299197 },
    { lat: 48.7623602, lng: 18.1297541 },
    { lat: 48.7623196, lng: 18.1296576 },
    { lat: 48.7622437, lng: 18.1293246 },
    { lat: 48.7620027, lng: 18.1290025 },
    { lat: 48.7618943, lng: 18.128788 },
    { lat: 48.7618, lng: 18.1282315 },
    { lat: 48.761553, lng: 18.1276884 },
    { lat: 48.7615527, lng: 18.1276677 },
    { lat: 48.7615628, lng: 18.127588 },
    { lat: 48.7616103, lng: 18.1272015 },
    { lat: 48.7617435, lng: 18.126698 },
    { lat: 48.7618385, lng: 18.1265624 },
    { lat: 48.761951, lng: 18.1265206 },
    { lat: 48.7627896, lng: 18.1268115 },
    { lat: 48.7633124, lng: 18.1269924 },
    { lat: 48.7633774, lng: 18.1269837 },
    { lat: 48.7638812, lng: 18.1265676 },
    { lat: 48.7648122, lng: 18.126079 },
    { lat: 48.7653018, lng: 18.1258232 },
    { lat: 48.7659159, lng: 18.1246845 },
    { lat: 48.7660845, lng: 18.1243794 },
    { lat: 48.7662668, lng: 18.1241957 },
    { lat: 48.7664098, lng: 18.123937 },
    { lat: 48.766633, lng: 18.1235674 },
    { lat: 48.7667477, lng: 18.1234044 },
    { lat: 48.7668638, lng: 18.1233228 },
    { lat: 48.7670456, lng: 18.1230339 },
    { lat: 48.7671013, lng: 18.1228072 },
    { lat: 48.7671539, lng: 18.1227397 },
    { lat: 48.7672218, lng: 18.1226474 },
    { lat: 48.7673099, lng: 18.1225161 },
    { lat: 48.7673413, lng: 18.1224705 },
    { lat: 48.7674094, lng: 18.1224417 },
    { lat: 48.7677355, lng: 18.1223032 },
    { lat: 48.7680451, lng: 18.1223285 },
    { lat: 48.7681918, lng: 18.1224636 },
    { lat: 48.7683623, lng: 18.1226595 },
    { lat: 48.7685981, lng: 18.1228341 },
    { lat: 48.7690468, lng: 18.1227312 },
    { lat: 48.7693337, lng: 18.1227728 },
    { lat: 48.7695396, lng: 18.1228806 },
    { lat: 48.7700425, lng: 18.1231228 },
    { lat: 48.7700694, lng: 18.1231214 },
    { lat: 48.7702, lng: 18.1231151 },
    { lat: 48.7702764, lng: 18.1231117 },
    { lat: 48.7703259, lng: 18.1231094 },
    { lat: 48.7704151, lng: 18.1231094 },
    { lat: 48.7704409, lng: 18.1231059 },
    { lat: 48.770477, lng: 18.1231047 },
    { lat: 48.7705761, lng: 18.1230997 },
    { lat: 48.7705995, lng: 18.1230966 },
    { lat: 48.7706267, lng: 18.1230962 },
    { lat: 48.7706491, lng: 18.1230954 },
    { lat: 48.7706578, lng: 18.123091 },
    { lat: 48.7707053, lng: 18.1230897 },
    { lat: 48.7707244, lng: 18.1230893 },
    { lat: 48.7707524, lng: 18.1230867 },
    { lat: 48.7708264, lng: 18.1229444 },
    { lat: 48.77086, lng: 18.122745 },
    { lat: 48.7709755, lng: 18.1225343 },
    { lat: 48.7710079, lng: 18.1224745 },
    { lat: 48.7710024, lng: 18.122339 },
    { lat: 48.7709979, lng: 18.1222415 },
    { lat: 48.771026, lng: 18.1221627 },
    { lat: 48.7710551, lng: 18.1220819 },
    { lat: 48.7710806, lng: 18.1218978 },
    { lat: 48.7710688, lng: 18.12185 },
    { lat: 48.7710417, lng: 18.121733 },
    { lat: 48.7711085, lng: 18.1215639 },
    { lat: 48.7711332, lng: 18.1215016 },
    { lat: 48.7711644, lng: 18.1214198 },
    { lat: 48.7712205, lng: 18.1213354 },
    { lat: 48.7712256, lng: 18.1213258 },
    { lat: 48.7712515, lng: 18.1212869 },
    { lat: 48.7712814, lng: 18.1210239 },
    { lat: 48.7712622, lng: 18.1209664 },
    { lat: 48.7711347, lng: 18.1209461 },
    { lat: 48.7710792, lng: 18.1208254 },
    { lat: 48.7711874, lng: 18.1206114 },
    { lat: 48.7713099, lng: 18.119925 },
    { lat: 48.771454, lng: 18.1199956 },
    { lat: 48.7715252, lng: 18.1199185 },
    { lat: 48.771591, lng: 18.1194396 },
    { lat: 48.7715797, lng: 18.1192855 },
    { lat: 48.7715253, lng: 18.1191275 },
    { lat: 48.7713843, lng: 18.1190285 },
    { lat: 48.7712824, lng: 18.1187316 },
    { lat: 48.7713225, lng: 18.1184128 },
    { lat: 48.7712834, lng: 18.1182649 },
    { lat: 48.7715517, lng: 18.1176217 },
    { lat: 48.7716425, lng: 18.1175119 },
    { lat: 48.7717769, lng: 18.117533 },
    { lat: 48.7719235, lng: 18.1174552 },
    { lat: 48.772085, lng: 18.1174548 },
    { lat: 48.7723831, lng: 18.1172065 },
    { lat: 48.7725537, lng: 18.1171133 },
    { lat: 48.7726067, lng: 18.1169771 },
    { lat: 48.7726177, lng: 18.1168733 },
    { lat: 48.7726093, lng: 18.11679 },
    { lat: 48.7724987, lng: 18.1166523 },
    { lat: 48.7725359, lng: 18.1165561 },
    { lat: 48.7725426, lng: 18.1163989 },
    { lat: 48.7726255, lng: 18.1161947 },
    { lat: 48.7726992, lng: 18.1158113 },
    { lat: 48.7726994, lng: 18.1155458 },
    { lat: 48.7726389, lng: 18.1152623 },
    { lat: 48.7726514, lng: 18.1149526 },
    { lat: 48.7725918, lng: 18.1148182 },
    { lat: 48.7726155, lng: 18.1144332 },
    { lat: 48.7725535, lng: 18.1142784 },
    { lat: 48.7724349, lng: 18.1141582 },
    { lat: 48.7724053, lng: 18.1139421 },
    { lat: 48.7722702, lng: 18.1138106 },
    { lat: 48.7721391, lng: 18.1135379 },
    { lat: 48.7721183, lng: 18.113375 },
    { lat: 48.7719922, lng: 18.1131879 },
    { lat: 48.771956, lng: 18.1130092 },
    { lat: 48.7718107, lng: 18.1126865 },
    { lat: 48.7716042, lng: 18.1126854 },
    { lat: 48.7715248, lng: 18.1125636 },
    { lat: 48.7715486, lng: 18.1124084 },
    { lat: 48.7715455, lng: 18.1122374 },
    { lat: 48.7715093, lng: 18.112083 },
    { lat: 48.7714874, lng: 18.1118806 },
    { lat: 48.7713994, lng: 18.111649 },
    { lat: 48.771397, lng: 18.1113921 },
    { lat: 48.771345, lng: 18.111008 },
    { lat: 48.7711988, lng: 18.1107427 },
    { lat: 48.7709479, lng: 18.1104055 },
    { lat: 48.7710784, lng: 18.1100711 },
    { lat: 48.7711006, lng: 18.1096568 },
    { lat: 48.7712306, lng: 18.1091568 },
    { lat: 48.7712147, lng: 18.1091149 },
    { lat: 48.7714386, lng: 18.108835 },
    { lat: 48.7713736, lng: 18.1081245 },
    { lat: 48.7713721, lng: 18.1078106 },
    { lat: 48.7714683, lng: 18.1076808 },
    { lat: 48.7715596, lng: 18.107367 },
    { lat: 48.7718817, lng: 18.1064264 },
    { lat: 48.7719707, lng: 18.1058038 },
    { lat: 48.7720412, lng: 18.1056162 },
    { lat: 48.772094, lng: 18.1051105 },
    { lat: 48.7721428, lng: 18.1050596 },
    { lat: 48.7724993, lng: 18.104977 },
    { lat: 48.7725541, lng: 18.1047972 },
    { lat: 48.7725488, lng: 18.104628 },
    { lat: 48.7725217, lng: 18.1045496 },
    { lat: 48.7725029, lng: 18.1045185 },
    { lat: 48.7724149, lng: 18.104365 },
    { lat: 48.7722177, lng: 18.1038446 },
    { lat: 48.7724094, lng: 18.1035324 },
    { lat: 48.7725463, lng: 18.1035603 },
    { lat: 48.7728703, lng: 18.1030165 },
    { lat: 48.7728901, lng: 18.103011 },
    { lat: 48.7730129, lng: 18.102736 },
    { lat: 48.7730276, lng: 18.1026968 },
    { lat: 48.773286, lng: 18.1025811 },
    { lat: 48.7733577, lng: 18.1024159 },
    { lat: 48.7735018, lng: 18.1022557 },
    { lat: 48.7736514, lng: 18.1023059 },
    { lat: 48.7737109, lng: 18.1022239 },
    { lat: 48.7738279, lng: 18.1019839 },
    { lat: 48.7738592, lng: 18.101745 },
    { lat: 48.7739262, lng: 18.1017153 },
    { lat: 48.7741426, lng: 18.1015934 },
    { lat: 48.7741614, lng: 18.1015469 },
    { lat: 48.7742362, lng: 18.1014582 },
    { lat: 48.7742611, lng: 18.1013991 },
    { lat: 48.7743154, lng: 18.1012888 },
    { lat: 48.7743836, lng: 18.101294 },
    { lat: 48.7746881, lng: 18.100902 },
    { lat: 48.7748934, lng: 18.1006728 },
    { lat: 48.7749251, lng: 18.1006149 },
    { lat: 48.7749585, lng: 18.1005474 },
    { lat: 48.7749777, lng: 18.1005484 },
    { lat: 48.774982, lng: 18.1005253 },
    { lat: 48.7749707, lng: 18.1005251 },
    { lat: 48.775013, lng: 18.100436 },
    { lat: 48.775038, lng: 18.1004395 },
    { lat: 48.7750388, lng: 18.1004158 },
    { lat: 48.7750284, lng: 18.1004114 },
    { lat: 48.7750859, lng: 18.1002961 },
    { lat: 48.7751401, lng: 18.1001779 },
    { lat: 48.7751422, lng: 18.099886 },
    { lat: 48.7751785, lng: 18.0998314 },
    { lat: 48.7752013, lng: 18.0996427 },
    { lat: 48.7752522, lng: 18.099525 },
    { lat: 48.7754312, lng: 18.0992496 },
    { lat: 48.7755085, lng: 18.0991441 },
    { lat: 48.775614, lng: 18.0989983 },
    { lat: 48.7757218, lng: 18.0988519 },
    { lat: 48.7758752, lng: 18.0988307 },
    { lat: 48.7759275, lng: 18.0985671 },
    { lat: 48.7760406, lng: 18.098434 },
    { lat: 48.7762508, lng: 18.0982112 },
    { lat: 48.776256, lng: 18.0979526 },
    { lat: 48.7764658, lng: 18.0978308 },
    { lat: 48.7764585, lng: 18.0975716 },
    { lat: 48.7768645, lng: 18.0971979 },
    { lat: 48.776887, lng: 18.0968707 },
    { lat: 48.7770686, lng: 18.0967527 },
    { lat: 48.7771468, lng: 18.0966832 },
    { lat: 48.7772262, lng: 18.0965148 },
    { lat: 48.7773228, lng: 18.0963874 },
    { lat: 48.7774439, lng: 18.0963762 },
    { lat: 48.7775586, lng: 18.0963509 },
    { lat: 48.7776108, lng: 18.0962974 },
    { lat: 48.7776652, lng: 18.096128 },
    { lat: 48.777702, lng: 18.0960419 },
    { lat: 48.7777683, lng: 18.0960187 },
    { lat: 48.7778224, lng: 18.0960745 },
    { lat: 48.7778486, lng: 18.0959633 },
    { lat: 48.7778529, lng: 18.0958428 },
    { lat: 48.7778089, lng: 18.0956888 },
    { lat: 48.7777381, lng: 18.095598 },
    { lat: 48.7777458, lng: 18.0955362 },
    { lat: 48.7779006, lng: 18.0954808 },
    { lat: 48.7779855, lng: 18.0954086 },
    { lat: 48.7780974, lng: 18.0952963 },
    { lat: 48.7781923, lng: 18.0951223 },
    { lat: 48.7782573, lng: 18.0949798 },
    { lat: 48.7782905, lng: 18.0947946 },
    { lat: 48.7783422, lng: 18.0946929 },
    { lat: 48.7784122, lng: 18.0945189 },
    { lat: 48.7783947, lng: 18.0943162 },
    { lat: 48.7783983, lng: 18.0941272 },
    { lat: 48.7784409, lng: 18.0940059 },
    { lat: 48.7785295, lng: 18.0938216 },
    { lat: 48.7785463, lng: 18.0936845 },
    { lat: 48.7785636, lng: 18.0934006 },
    { lat: 48.7786694, lng: 18.0933216 },
    { lat: 48.7787775, lng: 18.0931812 },
    { lat: 48.7789085, lng: 18.0931444 },
    { lat: 48.7790719, lng: 18.0929648 },
    { lat: 48.7791723, lng: 18.0928297 },
    { lat: 48.7791813, lng: 18.0927888 },
    { lat: 48.779185, lng: 18.0927396 },
    { lat: 48.7791727, lng: 18.091598 },
    { lat: 48.7792346, lng: 18.0914022 },
    { lat: 48.7792428, lng: 18.0913889 },
    { lat: 48.7792508, lng: 18.091371 },
    { lat: 48.7792672, lng: 18.0913445 },
    { lat: 48.7792729, lng: 18.0913244 },
    { lat: 48.7792778, lng: 18.0913123 },
    { lat: 48.779341, lng: 18.0912563 },
    { lat: 48.7794842, lng: 18.0909992 },
    { lat: 48.7795797, lng: 18.0905091 },
    { lat: 48.7797385, lng: 18.0901371 },
    { lat: 48.7798247, lng: 18.0897595 },
    { lat: 48.7798231, lng: 18.0895003 },
    { lat: 48.779989, lng: 18.0893651 },
    { lat: 48.7802192, lng: 18.0887639 },
    { lat: 48.7802007, lng: 18.0884479 },
    { lat: 48.7803455, lng: 18.0879719 },
    { lat: 48.7803394, lng: 18.0874417 },
    { lat: 48.7803127, lng: 18.086984 },
    { lat: 48.7802408, lng: 18.0864096 },
    { lat: 48.7801945, lng: 18.0860272 },
    { lat: 48.7801995, lng: 18.0857442 },
    { lat: 48.780136, lng: 18.085431 },
    { lat: 48.7802074, lng: 18.0853162 },
    { lat: 48.7801496, lng: 18.0851214 },
    { lat: 48.7801953, lng: 18.084668 },
    { lat: 48.7801417, lng: 18.084444 },
    { lat: 48.7801498, lng: 18.0843156 },
    { lat: 48.7801629, lng: 18.084151 },
    { lat: 48.7801165, lng: 18.0837614 },
    { lat: 48.7802345, lng: 18.0833134 },
    { lat: 48.7803277, lng: 18.0826464 },
    { lat: 48.7803726, lng: 18.0822772 },
    { lat: 48.7804262, lng: 18.081803 },
    { lat: 48.7803221, lng: 18.0811993 },
    { lat: 48.7802997, lng: 18.0811637 },
    { lat: 48.7801463, lng: 18.0809144 },
    { lat: 48.7799832, lng: 18.0806321 },
    { lat: 48.7799341, lng: 18.0804694 },
    { lat: 48.7798559, lng: 18.0801743 },
    { lat: 48.779726, lng: 18.0799594 },
    { lat: 48.7796342, lng: 18.0799137 },
    { lat: 48.7795703, lng: 18.0797312 },
    { lat: 48.7793151, lng: 18.0790719 },
    { lat: 48.779122, lng: 18.0788756 },
    { lat: 48.7787711, lng: 18.0780681 },
    { lat: 48.7786682, lng: 18.0776592 },
    { lat: 48.7782613, lng: 18.0769949 },
    { lat: 48.7781655, lng: 18.0764166 },
    { lat: 48.7780756, lng: 18.0762712 },
    { lat: 48.7780166, lng: 18.0758808 },
    { lat: 48.778056, lng: 18.0754905 },
    { lat: 48.7780682, lng: 18.0748505 },
    { lat: 48.7781396, lng: 18.0743951 },
    { lat: 48.7781264, lng: 18.0741886 },
    { lat: 48.7780536, lng: 18.073697 },
    { lat: 48.7779869, lng: 18.0732326 },
    { lat: 48.7778604, lng: 18.0726312 },
    { lat: 48.7778003, lng: 18.0721097 },
    { lat: 48.7777618, lng: 18.0716214 },
    { lat: 48.7776993, lng: 18.0712129 },
    { lat: 48.7778155, lng: 18.0702659 },
    { lat: 48.7780477, lng: 18.0698376 },
    { lat: 48.7783274, lng: 18.0695256 },
    { lat: 48.7784258, lng: 18.0692025 },
    { lat: 48.7785439, lng: 18.0687154 },
    { lat: 48.7785898, lng: 18.0682845 },
    { lat: 48.7786399, lng: 18.0679085 },
    { lat: 48.7786446, lng: 18.0678721 },
    { lat: 48.7785446, lng: 18.0676661 },
    { lat: 48.7784627, lng: 18.0669439 },
    { lat: 48.7783455, lng: 18.0659087 },
    { lat: 48.7783645, lng: 18.0657515 },
    { lat: 48.7783872, lng: 18.0655581 },
    { lat: 48.7784154, lng: 18.0653296 },
    { lat: 48.7784124, lng: 18.0651615 },
    { lat: 48.7784105, lng: 18.0649757 },
    { lat: 48.7784213, lng: 18.0647566 },
    { lat: 48.778437, lng: 18.0644629 },
    { lat: 48.7783795, lng: 18.0639868 },
    { lat: 48.7782968, lng: 18.0634401 },
    { lat: 48.778091, lng: 18.0629546 },
    { lat: 48.7776311, lng: 18.0610776 },
    { lat: 48.7773144, lng: 18.0597947 },
    { lat: 48.7773204, lng: 18.0597442 },
    { lat: 48.7774269, lng: 18.0582144 },
    { lat: 48.7775247, lng: 18.0566483 },
    { lat: 48.7775353, lng: 18.0565592 },
    { lat: 48.7775777, lng: 18.0562827 },
    { lat: 48.7775569, lng: 18.0562339 },
    { lat: 48.7771828, lng: 18.0536491 },
    { lat: 48.7771131, lng: 18.0529622 },
    { lat: 48.7770932, lng: 18.05249 },
    { lat: 48.7770724, lng: 18.0521156 },
    { lat: 48.7770332, lng: 18.0516819 },
    { lat: 48.7770195, lng: 18.0515255 },
    { lat: 48.7768959, lng: 18.0499774 },
    { lat: 48.7768609, lng: 18.0495552 },
    { lat: 48.7768529, lng: 18.0494596 },
    { lat: 48.7768435, lng: 18.0493149 },
    { lat: 48.7768074, lng: 18.0485479 },
    { lat: 48.7767348, lng: 18.0471965 },
    { lat: 48.7767179, lng: 18.0468732 },
    { lat: 48.7767077, lng: 18.0467554 },
    { lat: 48.7766324, lng: 18.0461096 },
    { lat: 48.7765672, lng: 18.0454607 },
    { lat: 48.776483, lng: 18.0446437 },
    { lat: 48.7764536, lng: 18.0440672 },
    { lat: 48.7764204, lng: 18.0435392 },
    { lat: 48.7763811, lng: 18.0427945 },
    { lat: 48.7763557, lng: 18.0422669 },
    { lat: 48.7763261, lng: 18.0417631 },
    { lat: 48.7762974, lng: 18.0413713 },
    { lat: 48.7761451, lng: 18.040704 },
    { lat: 48.7757905, lng: 18.0409124 },
    { lat: 48.7756382, lng: 18.0410443 },
    { lat: 48.775558, lng: 18.0411212 },
    { lat: 48.7753371, lng: 18.0412208 },
    { lat: 48.7749767, lng: 18.0413903 },
    { lat: 48.7748111, lng: 18.0414133 },
    { lat: 48.7745252, lng: 18.0416185 },
    { lat: 48.7741226, lng: 18.0416985 },
    { lat: 48.7737969, lng: 18.0416125 },
    { lat: 48.7735967, lng: 18.0416423 },
    { lat: 48.7728339, lng: 18.0417622 },
    { lat: 48.7724799, lng: 18.0416346 },
    { lat: 48.7721659, lng: 18.0415914 },
    { lat: 48.7719116, lng: 18.0415659 },
    { lat: 48.7715298, lng: 18.0414645 },
    { lat: 48.7710963, lng: 18.0413479 },
    { lat: 48.7707383, lng: 18.0412638 },
    { lat: 48.7705848, lng: 18.0412272 },
    { lat: 48.7703226, lng: 18.0410679 },
    { lat: 48.7700667, lng: 18.0407463 },
    { lat: 48.7696688, lng: 18.0402389 },
    { lat: 48.7695499, lng: 18.0401147 },
    { lat: 48.7694102, lng: 18.0399836 },
    { lat: 48.7691618, lng: 18.0397541 },
    { lat: 48.7687794, lng: 18.0391995 },
    { lat: 48.7685436, lng: 18.0387408 },
    { lat: 48.7682186, lng: 18.0385717 },
    { lat: 48.7681265, lng: 18.0385218 },
    { lat: 48.7679911, lng: 18.0384238 },
    { lat: 48.7673285, lng: 18.0380179 },
    { lat: 48.7672425, lng: 18.0379605 },
    { lat: 48.7665811, lng: 18.0375206 },
    { lat: 48.766344, lng: 18.037363 },
    { lat: 48.766342, lng: 18.037375 },
    { lat: 48.766239, lng: 18.037385 },
    { lat: 48.765772, lng: 18.037621 },
    { lat: 48.765231, lng: 18.037872 },
    { lat: 48.764276, lng: 18.038442 },
    { lat: 48.763688, lng: 18.038742 },
    { lat: 48.763321, lng: 18.039181 },
    { lat: 48.76303, lng: 18.039682 },
    { lat: 48.762842, lng: 18.040115 },
    { lat: 48.762564, lng: 18.04051 },
    { lat: 48.762322, lng: 18.040938 },
    { lat: 48.762126, lng: 18.041236 },
    { lat: 48.761871, lng: 18.04178 },
    { lat: 48.761766, lng: 18.042047 },
    { lat: 48.76164, lng: 18.042367 },
    { lat: 48.761284, lng: 18.042919 },
    { lat: 48.761029, lng: 18.043239 },
    { lat: 48.760859, lng: 18.043563 },
    { lat: 48.760456, lng: 18.044302 },
    { lat: 48.759899, lng: 18.04507 },
    { lat: 48.759744, lng: 18.045766 },
    { lat: 48.759525, lng: 18.046147 },
    { lat: 48.759413, lng: 18.046458 },
    { lat: 48.759223, lng: 18.04697 },
    { lat: 48.759102, lng: 18.047386 },
    { lat: 48.759061, lng: 18.048266 },
    { lat: 48.758936, lng: 18.049118 },
    { lat: 48.758764, lng: 18.049577 },
    { lat: 48.7587, lng: 18.049911 },
    { lat: 48.758649, lng: 18.050275 },
    { lat: 48.758483, lng: 18.050791 },
    { lat: 48.75845, lng: 18.051186 },
    { lat: 48.758468, lng: 18.051555 },
    { lat: 48.758532, lng: 18.051985 },
    { lat: 48.758275, lng: 18.05268 },
    { lat: 48.758018, lng: 18.053371 },
    { lat: 48.757922, lng: 18.05386 },
    { lat: 48.757584, lng: 18.054496 },
    { lat: 48.757228, lng: 18.054963 },
    { lat: 48.756983, lng: 18.055371 },
    { lat: 48.756887, lng: 18.055531 },
    { lat: 48.756625, lng: 18.05605 },
    { lat: 48.756262, lng: 18.056742 },
    { lat: 48.75609, lng: 18.057358 },
    { lat: 48.756004, lng: 18.057745 },
    { lat: 48.755978, lng: 18.058253 },
    { lat: 48.756017, lng: 18.058944 },
    { lat: 48.755951, lng: 18.059564 },
    { lat: 48.755936, lng: 18.060301 },
    { lat: 48.755994, lng: 18.061155 },
    { lat: 48.755973, lng: 18.061787 },
    { lat: 48.755876, lng: 18.062478 },
    { lat: 48.75577, lng: 18.063083 },
    { lat: 48.755677, lng: 18.063551 },
    { lat: 48.755496, lng: 18.064001 },
    { lat: 48.755387, lng: 18.064607 },
    { lat: 48.755176, lng: 18.065166 },
    { lat: 48.755022, lng: 18.065682 },
    { lat: 48.754916, lng: 18.065894 },
    { lat: 48.754748, lng: 18.066161 },
    { lat: 48.754646, lng: 18.066436 },
    { lat: 48.754425, lng: 18.066791 },
    { lat: 48.754351, lng: 18.06691 },
    { lat: 48.754352, lng: 18.066936 },
    { lat: 48.75423, lng: 18.066945 },
    { lat: 48.754201, lng: 18.066974 },
    { lat: 48.75408, lng: 18.06709 },
    { lat: 48.753991, lng: 18.067114 },
    { lat: 48.753905, lng: 18.067317 },
    { lat: 48.753887, lng: 18.067443 },
    { lat: 48.753511, lng: 18.068067 },
    { lat: 48.753374, lng: 18.068387 },
    { lat: 48.753212, lng: 18.06875 },
    { lat: 48.753237, lng: 18.069043 },
    { lat: 48.753187, lng: 18.069425 },
    { lat: 48.753131, lng: 18.069471 },
    { lat: 48.753117, lng: 18.069501 },
    { lat: 48.752998, lng: 18.070046 },
    { lat: 48.752993, lng: 18.070222 },
    { lat: 48.752931, lng: 18.070503 },
    { lat: 48.752829, lng: 18.070424 },
    { lat: 48.752742, lng: 18.070446 },
    { lat: 48.752708, lng: 18.070558 },
    { lat: 48.752752, lng: 18.070918 },
    { lat: 48.752814, lng: 18.07112 },
    { lat: 48.752843, lng: 18.071322 },
    { lat: 48.752847, lng: 18.071457 },
    { lat: 48.752759, lng: 18.071689 },
    { lat: 48.752813, lng: 18.072176 },
    { lat: 48.752811, lng: 18.072464 },
    { lat: 48.752919, lng: 18.072848 },
    { lat: 48.752987, lng: 18.072872 },
    { lat: 48.75299, lng: 18.072975 },
    { lat: 48.752992, lng: 18.073174 },
    { lat: 48.752903, lng: 18.073486 },
    { lat: 48.752804, lng: 18.074043 },
    { lat: 48.752803, lng: 18.074077 },
    { lat: 48.752792, lng: 18.074366 },
    { lat: 48.752802, lng: 18.074382 },
    { lat: 48.752772, lng: 18.074625 },
    { lat: 48.752799, lng: 18.074711 },
    { lat: 48.752796, lng: 18.074865 },
    { lat: 48.75284, lng: 18.074969 },
    { lat: 48.752837, lng: 18.075048 },
    { lat: 48.752748, lng: 18.075382 },
    { lat: 48.752671, lng: 18.075421 },
    { lat: 48.752598, lng: 18.07584 },
    { lat: 48.752607, lng: 18.076015 },
    { lat: 48.752539, lng: 18.076478 },
    { lat: 48.752389, lng: 18.07682 },
    { lat: 48.752363, lng: 18.076819 },
    { lat: 48.752274, lng: 18.076971 },
    { lat: 48.752131, lng: 18.077275 },
    { lat: 48.751917, lng: 18.077528 },
    { lat: 48.751808, lng: 18.077706 },
    { lat: 48.751637, lng: 18.077872 },
    { lat: 48.751531, lng: 18.077879 },
    { lat: 48.751454, lng: 18.077992 },
    { lat: 48.7514, lng: 18.078205 },
    { lat: 48.751346, lng: 18.078348 },
    { lat: 48.751191, lng: 18.078411 },
    { lat: 48.751115, lng: 18.078596 },
    { lat: 48.750811, lng: 18.078919 },
    { lat: 48.750796, lng: 18.079038 },
    { lat: 48.750669, lng: 18.079232 },
    { lat: 48.750619, lng: 18.079239 },
    { lat: 48.750459, lng: 18.079406 },
    { lat: 48.750397, lng: 18.079392 },
    { lat: 48.750324, lng: 18.079563 },
    { lat: 48.750348, lng: 18.079719 },
    { lat: 48.750237, lng: 18.079866 },
    { lat: 48.750201, lng: 18.079973 },
    { lat: 48.750131, lng: 18.080069 },
    { lat: 48.749942, lng: 18.080487 },
    { lat: 48.749649, lng: 18.080648 },
    { lat: 48.749501, lng: 18.080832 },
    { lat: 48.749339, lng: 18.080892 },
    { lat: 48.749123, lng: 18.081094 },
    { lat: 48.749119, lng: 18.081126 },
    { lat: 48.749023, lng: 18.081247 },
    { lat: 48.748936, lng: 18.081347 },
    { lat: 48.748861, lng: 18.08128 },
    { lat: 48.748559, lng: 18.081492 },
    { lat: 48.748436, lng: 18.081633 },
    { lat: 48.748256, lng: 18.081785 },
    { lat: 48.748218, lng: 18.081911 },
    { lat: 48.748166, lng: 18.081996 },
    { lat: 48.748043, lng: 18.082052 },
    { lat: 48.747916, lng: 18.082266 },
    { lat: 48.747827, lng: 18.082297 },
    { lat: 48.747718, lng: 18.082449 },
    { lat: 48.747649, lng: 18.082383 },
    { lat: 48.747536, lng: 18.082476 },
    { lat: 48.747365, lng: 18.082568 },
    { lat: 48.747275, lng: 18.082567 },
    { lat: 48.747216, lng: 18.082696 },
    { lat: 48.747042, lng: 18.082779 },
    { lat: 48.746906, lng: 18.082892 },
    { lat: 48.746715, lng: 18.083056 },
    { lat: 48.746604, lng: 18.083082 },
    { lat: 48.746518, lng: 18.083167 },
    { lat: 48.746492, lng: 18.083329 },
    { lat: 48.746437, lng: 18.083432 },
    { lat: 48.74621, lng: 18.083544 },
    { lat: 48.746041, lng: 18.083672 },
    { lat: 48.745933, lng: 18.083793 },
    { lat: 48.745741, lng: 18.083948 },
    { lat: 48.745702, lng: 18.084152 },
    { lat: 48.745578, lng: 18.084256 },
    { lat: 48.745437, lng: 18.084242 },
    { lat: 48.74519, lng: 18.084365 },
    { lat: 48.745046, lng: 18.084369 },
    { lat: 48.744891, lng: 18.084539 },
    { lat: 48.744793, lng: 18.084485 },
    { lat: 48.744334, lng: 18.085163 },
    { lat: 48.744118, lng: 18.085402 },
    { lat: 48.744076, lng: 18.0856 },
    { lat: 48.743873, lng: 18.085654 },
    { lat: 48.74373, lng: 18.085823 },
    { lat: 48.743531, lng: 18.085873 },
    { lat: 48.74334, lng: 18.086212 },
    { lat: 48.74325, lng: 18.08622 },
    { lat: 48.743087, lng: 18.08646 },
    { lat: 48.743079, lng: 18.086551 },
    { lat: 48.742976, lng: 18.086576 },
    { lat: 48.742822, lng: 18.08702 },
    { lat: 48.742706, lng: 18.087104 },
    { lat: 48.742648, lng: 18.087126 },
    { lat: 48.742335, lng: 18.087479 },
    { lat: 48.742338, lng: 18.0877 },
    { lat: 48.742316, lng: 18.087736 },
    { lat: 48.742286, lng: 18.08776 },
    { lat: 48.742265, lng: 18.087771 },
    { lat: 48.742169, lng: 18.087644 },
    { lat: 48.742149, lng: 18.087677 },
    { lat: 48.742062, lng: 18.087802 },
    { lat: 48.742017, lng: 18.088012 },
    { lat: 48.741905, lng: 18.088144 },
    { lat: 48.741805, lng: 18.088332 },
    { lat: 48.741715, lng: 18.088449 },
    { lat: 48.741571, lng: 18.088456 },
    { lat: 48.741499, lng: 18.088556 },
    { lat: 48.741435, lng: 18.088654 },
    { lat: 48.74141, lng: 18.088816 },
    { lat: 48.74123, lng: 18.089002 },
    { lat: 48.741134, lng: 18.089145 },
    { lat: 48.741037, lng: 18.089135 },
    { lat: 48.740957, lng: 18.089108 },
    { lat: 48.740891, lng: 18.089168 },
    { lat: 48.740873, lng: 18.089336 },
    { lat: 48.740818, lng: 18.089476 },
    { lat: 48.740703, lng: 18.089523 },
    { lat: 48.740688, lng: 18.089591 },
    { lat: 48.740717, lng: 18.08975 },
    { lat: 48.740816, lng: 18.089987 },
    { lat: 48.740721, lng: 18.090267 },
    { lat: 48.740621, lng: 18.090414 },
    { lat: 48.740453, lng: 18.090411 },
    { lat: 48.740306, lng: 18.09066 },
    { lat: 48.740254, lng: 18.090942 },
    { lat: 48.740206, lng: 18.090948 },
    { lat: 48.74017, lng: 18.090925 },
    { lat: 48.740146, lng: 18.090924 },
    { lat: 48.740026, lng: 18.09119 },
    { lat: 48.740052, lng: 18.091285 },
    { lat: 48.739952, lng: 18.091465 },
    { lat: 48.739833, lng: 18.091473 },
    { lat: 48.739761, lng: 18.09159 },
    { lat: 48.739689, lng: 18.091634 },
    { lat: 48.739648, lng: 18.091813 },
    { lat: 48.739576, lng: 18.091901 },
    { lat: 48.73946, lng: 18.0919 },
    { lat: 48.73936, lng: 18.092055 },
    { lat: 48.739276, lng: 18.092135 },
    { lat: 48.739282, lng: 18.092297 },
    { lat: 48.739239, lng: 18.092544 },
    { lat: 48.739155, lng: 18.092629 },
    { lat: 48.739055, lng: 18.092694 },
    { lat: 48.738974, lng: 18.092791 },
    { lat: 48.738959, lng: 18.092982 },
    { lat: 48.738909, lng: 18.093044 },
    { lat: 48.738711, lng: 18.093162 },
    { lat: 48.738611, lng: 18.093417 },
    { lat: 48.738537, lng: 18.09332 },
    { lat: 48.738511, lng: 18.093335 },
    { lat: 48.73845, lng: 18.093486 },
    { lat: 48.738426, lng: 18.093615 },
    { lat: 48.738237, lng: 18.093968 },
    { lat: 48.738184, lng: 18.09401 },
    { lat: 48.738141, lng: 18.094065 },
    { lat: 48.738127, lng: 18.094081 },
    { lat: 48.738154, lng: 18.094134 },
    { lat: 48.738175, lng: 18.09423 },
    { lat: 48.738112, lng: 18.094624 },
    { lat: 48.737941, lng: 18.094727 },
    { lat: 48.737815, lng: 18.094694 },
    { lat: 48.737798, lng: 18.094706 },
    { lat: 48.737698, lng: 18.094821 },
    { lat: 48.737558, lng: 18.094875 },
    { lat: 48.737564, lng: 18.09496 },
    { lat: 48.737399, lng: 18.095067 },
    { lat: 48.737401, lng: 18.095128 },
    { lat: 48.737454, lng: 18.095299 },
    { lat: 48.737372, lng: 18.095401 },
    { lat: 48.73724, lng: 18.095415 },
    { lat: 48.737217, lng: 18.095451 },
    { lat: 48.737229, lng: 18.095474 },
    { lat: 48.737255, lng: 18.095524 },
    { lat: 48.737531, lng: 18.096027 },
    { lat: 48.737686, lng: 18.096231 },
    { lat: 48.73778, lng: 18.096414 },
    { lat: 48.737876, lng: 18.096563 },
    { lat: 48.737943, lng: 18.096666 },
    { lat: 48.737961, lng: 18.096704 },
    { lat: 48.738119, lng: 18.097016 },
    { lat: 48.738148, lng: 18.097167 },
    { lat: 48.738271, lng: 18.097498 },
    { lat: 48.738444, lng: 18.097864 },
    { lat: 48.738597, lng: 18.098091 },
    { lat: 48.738618, lng: 18.098121 },
    { lat: 48.738648, lng: 18.098166 },
    { lat: 48.738675, lng: 18.098204 },
    { lat: 48.738702, lng: 18.098302 },
    { lat: 48.73821, lng: 18.098862 },
    { lat: 48.738125, lng: 18.098945 },
    { lat: 48.738021, lng: 18.099048 },
    { lat: 48.737983, lng: 18.099084 },
    { lat: 48.737885, lng: 18.099258 },
    { lat: 48.737693, lng: 18.099405 },
    { lat: 48.736908, lng: 18.100494 },
    { lat: 48.73677, lng: 18.10073 },
    { lat: 48.73667, lng: 18.100932 },
    { lat: 48.736397, lng: 18.101365 },
    { lat: 48.736353, lng: 18.101462 },
    { lat: 48.736322, lng: 18.101529 },
    { lat: 48.736184, lng: 18.101824 },
    { lat: 48.736063, lng: 18.102084 },
    { lat: 48.73593, lng: 18.102585 },
    { lat: 48.735978, lng: 18.103039 },
    { lat: 48.735996, lng: 18.103325 },
    { lat: 48.736061, lng: 18.10375 },
    { lat: 48.736171, lng: 18.104042 },
    { lat: 48.736215, lng: 18.104215 },
    { lat: 48.736368, lng: 18.104614 },
    { lat: 48.736589, lng: 18.105228 },
    { lat: 48.736629, lng: 18.105343 },
    { lat: 48.736672, lng: 18.105471 },
    { lat: 48.736693, lng: 18.105532 },
    { lat: 48.737063, lng: 18.104738 },
    { lat: 48.73742, lng: 18.104091 },
    { lat: 48.737426, lng: 18.104083 },
    { lat: 48.737432, lng: 18.10408 },
    { lat: 48.737455, lng: 18.104067 },
    { lat: 48.737461, lng: 18.104063 },
    { lat: 48.737935, lng: 18.103791 },
    { lat: 48.738357, lng: 18.10365 },
    { lat: 48.739128, lng: 18.103309 },
    { lat: 48.740438, lng: 18.104313 },
    { lat: 48.740755, lng: 18.104863 },
    { lat: 48.741158, lng: 18.106065 },
    { lat: 48.741186, lng: 18.106182 },
    { lat: 48.741189, lng: 18.106194 },
    { lat: 48.741202, lng: 18.106251 },
    { lat: 48.741206, lng: 18.106276 },
    { lat: 48.741286, lng: 18.106628 },
    { lat: 48.741311, lng: 18.106751 },
    { lat: 48.741337, lng: 18.106868 },
    { lat: 48.741296, lng: 18.107054 },
    { lat: 48.741204, lng: 18.107456 },
    { lat: 48.741147, lng: 18.108689 },
    { lat: 48.741146, lng: 18.108873 },
    { lat: 48.741156, lng: 18.109182 },
    { lat: 48.741243, lng: 18.109214 },
    { lat: 48.741278, lng: 18.109738 },
    { lat: 48.741315, lng: 18.110274 },
    { lat: 48.741199, lng: 18.110688 },
    { lat: 48.741223, lng: 18.11109 },
    { lat: 48.741347, lng: 18.111261 },
    { lat: 48.741512, lng: 18.111637 },
    { lat: 48.741416, lng: 18.112998 },
    { lat: 48.741335, lng: 18.114343 },
    { lat: 48.741354, lng: 18.114351 },
    { lat: 48.741294, lng: 18.114532 },
    { lat: 48.741308, lng: 18.114929 },
    { lat: 48.741414, lng: 18.115128 },
    { lat: 48.741404, lng: 18.115309 },
    { lat: 48.741431, lng: 18.115613 },
    { lat: 48.741454, lng: 18.116061 },
    { lat: 48.74156, lng: 18.116127 },
    { lat: 48.74184, lng: 18.116607 },
    { lat: 48.741816, lng: 18.116835 },
    { lat: 48.741738, lng: 18.117012 },
    { lat: 48.741523, lng: 18.117171 },
    { lat: 48.741419, lng: 18.117611 },
    { lat: 48.741546, lng: 18.118005 },
    { lat: 48.741541, lng: 18.118314 },
    { lat: 48.741669, lng: 18.118712 },
    { lat: 48.741862, lng: 18.118825 },
    { lat: 48.742072, lng: 18.119098 },
    { lat: 48.742044, lng: 18.119262 },
    { lat: 48.742457, lng: 18.119519 },
    { lat: 48.742553, lng: 18.119508 },
    { lat: 48.742592, lng: 18.119725 },
    { lat: 48.742833, lng: 18.120271 },
    { lat: 48.743286, lng: 18.12062 },
    { lat: 48.743274, lng: 18.120822 },
    { lat: 48.743302, lng: 18.120901 },
    { lat: 48.743515, lng: 18.121155 },
    { lat: 48.743856, lng: 18.121775 },
    { lat: 48.743874, lng: 18.121865 },
    { lat: 48.743608, lng: 18.122607 },
    { lat: 48.743653, lng: 18.123509 },
    { lat: 48.743663, lng: 18.12367 },
    { lat: 48.743689, lng: 18.124324 },
    { lat: 48.743887, lng: 18.124383 },
    { lat: 48.743823, lng: 18.124701 },
    { lat: 48.744112, lng: 18.125254 },
    { lat: 48.744114, lng: 18.125357 },
    { lat: 48.744106, lng: 18.12562 },
    { lat: 48.744319, lng: 18.125945 },
    { lat: 48.744232, lng: 18.126306 },
    { lat: 48.744309, lng: 18.126349 },
    { lat: 48.744429, lng: 18.126509 },
    { lat: 48.744614, lng: 18.126572 },
    { lat: 48.745052, lng: 18.126706 },
    { lat: 48.745068, lng: 18.126713 },
    { lat: 48.745104, lng: 18.126729 },
    { lat: 48.745132, lng: 18.126739 },
    { lat: 48.745789, lng: 18.126727 },
    { lat: 48.746598, lng: 18.126772 },
    { lat: 48.746932, lng: 18.12688 },
    { lat: 48.747155, lng: 18.127093 },
    { lat: 48.747448, lng: 18.127248 },
    { lat: 48.747758, lng: 18.127204 },
    { lat: 48.747937, lng: 18.127216 },
    { lat: 48.748022, lng: 18.12725 },
    { lat: 48.74815, lng: 18.127252 },
    { lat: 48.748667, lng: 18.127358 },
    { lat: 48.748899, lng: 18.127606 },
    { lat: 48.74928, lng: 18.12832 },
    { lat: 48.749281, lng: 18.128387 },
    { lat: 48.749647, lng: 18.129006 },
    { lat: 48.750003, lng: 18.129596 },
    { lat: 48.750495, lng: 18.130156 },
    { lat: 48.750695, lng: 18.130357 },
    { lat: 48.751165, lng: 18.13082 },
    { lat: 48.751344, lng: 18.130981 },
    { lat: 48.751745, lng: 18.131334 },
    { lat: 48.751783, lng: 18.131386 },
    { lat: 48.751937, lng: 18.131985 },
  ],
  KostolnáZáriečie: [
    { lat: 48.8700668, lng: 17.9779005 },
    { lat: 48.8711636, lng: 17.9789447 },
    { lat: 48.8723702, lng: 17.9800872 },
    { lat: 48.8724002, lng: 17.9801146 },
    { lat: 48.8728218, lng: 17.980524 },
    { lat: 48.872888, lng: 17.9805756 },
    { lat: 48.8732484, lng: 17.9809263 },
    { lat: 48.8734434, lng: 17.9811117 },
    { lat: 48.8735551, lng: 17.9812058 },
    { lat: 48.8738535, lng: 17.9815546 },
    { lat: 48.8739402, lng: 17.98165 },
    { lat: 48.8739754, lng: 17.9816893 },
    { lat: 48.8753071, lng: 17.9831717 },
    { lat: 48.8756809, lng: 17.9835984 },
    { lat: 48.8763144, lng: 17.9843071 },
    { lat: 48.8772399, lng: 17.9854336 },
    { lat: 48.877489, lng: 17.9857303 },
    { lat: 48.8778519, lng: 17.9861639 },
    { lat: 48.8778788, lng: 17.9861986 },
    { lat: 48.8782562, lng: 17.9863771 },
    { lat: 48.8787923, lng: 17.9866229 },
    { lat: 48.8788412, lng: 17.986646 },
    { lat: 48.878963, lng: 17.9867015 },
    { lat: 48.8790129, lng: 17.9867252 },
    { lat: 48.8790633, lng: 17.9867377 },
    { lat: 48.8791092, lng: 17.9867706 },
    { lat: 48.87961, lng: 17.9871277 },
    { lat: 48.8797429, lng: 17.9872193 },
    { lat: 48.8797818, lng: 17.9872463 },
    { lat: 48.8798125, lng: 17.987268 },
    { lat: 48.879883, lng: 17.9873165 },
    { lat: 48.8799385, lng: 17.9873552 },
    { lat: 48.8799772, lng: 17.9873829 },
    { lat: 48.8800622, lng: 17.987443 },
    { lat: 48.880307, lng: 17.9876152 },
    { lat: 48.8811767, lng: 17.9882922 },
    { lat: 48.8812481, lng: 17.9883159 },
    { lat: 48.8813267, lng: 17.9883694 },
    { lat: 48.8813774, lng: 17.9884019 },
    { lat: 48.8816086, lng: 17.9885526 },
    { lat: 48.8821036, lng: 17.9888676 },
    { lat: 48.8821549, lng: 17.9888028 },
    { lat: 48.8829637, lng: 17.9878027 },
    { lat: 48.8836197, lng: 17.9869873 },
    { lat: 48.8848244, lng: 17.9854871 },
    { lat: 48.885471, lng: 17.9846832 },
    { lat: 48.8855228, lng: 17.9846432 },
    { lat: 48.8855886, lng: 17.984593 },
    { lat: 48.8856015, lng: 17.984583 },
    { lat: 48.8857164, lng: 17.9845024 },
    { lat: 48.8857884, lng: 17.9843449 },
    { lat: 48.8858095, lng: 17.9843018 },
    { lat: 48.8859226, lng: 17.9840591 },
    { lat: 48.8863958, lng: 17.9841406 },
    { lat: 48.8867022, lng: 17.9820503 },
    { lat: 48.8867371, lng: 17.9820126 },
    { lat: 48.8868177, lng: 17.9819245 },
    { lat: 48.8869959, lng: 17.9819221 },
    { lat: 48.8870444, lng: 17.981922 },
    { lat: 48.8871054, lng: 17.9819208 },
    { lat: 48.8871774, lng: 17.98192 },
    { lat: 48.8872552, lng: 17.9819194 },
    { lat: 48.8873805, lng: 17.9819178 },
    { lat: 48.8874199, lng: 17.9819171 },
    { lat: 48.8874594, lng: 17.9819167 },
    { lat: 48.8875261, lng: 17.9819159 },
    { lat: 48.8877548, lng: 17.9819131 },
    { lat: 48.8878238, lng: 17.9819123 },
    { lat: 48.8878621, lng: 17.9819117 },
    { lat: 48.8878985, lng: 17.9819143 },
    { lat: 48.8879742, lng: 17.9819194 },
    { lat: 48.8880322, lng: 17.9819241 },
    { lat: 48.8881623, lng: 17.9819332 },
    { lat: 48.8882044, lng: 17.9819361 },
    { lat: 48.888244, lng: 17.9819386 },
    { lat: 48.8883008, lng: 17.9819427 },
    { lat: 48.8884107, lng: 17.9819503 },
    { lat: 48.8884856, lng: 17.9819556 },
    { lat: 48.8885547, lng: 17.9819605 },
    { lat: 48.8886361, lng: 17.9819663 },
    { lat: 48.8887007, lng: 17.9819707 },
    { lat: 48.8887689, lng: 17.9819754 },
    { lat: 48.8888826, lng: 17.98197 },
    { lat: 48.8889521, lng: 17.9819663 },
    { lat: 48.8890007, lng: 17.981964 },
    { lat: 48.889175, lng: 17.9819553 },
    { lat: 48.8891609, lng: 17.9817739 },
    { lat: 48.8891595, lng: 17.9817504 },
    { lat: 48.8891798, lng: 17.9817287 },
    { lat: 48.8896607, lng: 17.9812195 },
    { lat: 48.8898868, lng: 17.9811109 },
    { lat: 48.8901538, lng: 17.9809307 },
    { lat: 48.8901521, lng: 17.9808828 },
    { lat: 48.8901497, lng: 17.9808426 },
    { lat: 48.8901467, lng: 17.9807523 },
    { lat: 48.8901434, lng: 17.980676 },
    { lat: 48.8901397, lng: 17.9805976 },
    { lat: 48.890128, lng: 17.9803044 },
    { lat: 48.8901192, lng: 17.9800803 },
    { lat: 48.890602, lng: 17.9798847 },
    { lat: 48.8904653, lng: 17.9795587 },
    { lat: 48.8902945, lng: 17.9791497 },
    { lat: 48.8901681, lng: 17.9788459 },
    { lat: 48.8900148, lng: 17.9788887 },
    { lat: 48.88998, lng: 17.9787561 },
    { lat: 48.8898581, lng: 17.978834 },
    { lat: 48.8898316, lng: 17.9787663 },
    { lat: 48.8897364, lng: 17.9785325 },
    { lat: 48.8896942, lng: 17.978431 },
    { lat: 48.8895945, lng: 17.9785163 },
    { lat: 48.8895735, lng: 17.9784704 },
    { lat: 48.8895566, lng: 17.9784329 },
    { lat: 48.8895283, lng: 17.9783733 },
    { lat: 48.8894711, lng: 17.9784184 },
    { lat: 48.8894236, lng: 17.9784553 },
    { lat: 48.8893742, lng: 17.9784947 },
    { lat: 48.889333, lng: 17.9785265 },
    { lat: 48.8892575, lng: 17.9785859 },
    { lat: 48.8891637, lng: 17.9786593 },
    { lat: 48.8890872, lng: 17.9787199 },
    { lat: 48.8890096, lng: 17.9787807 },
    { lat: 48.8879703, lng: 17.9772984 },
    { lat: 48.8879468, lng: 17.9772646 },
    { lat: 48.8879321, lng: 17.9772437 },
    { lat: 48.8876021, lng: 17.9774267 },
    { lat: 48.8875634, lng: 17.9774801 },
    { lat: 48.8875342, lng: 17.9775188 },
    { lat: 48.88742, lng: 17.9776707 },
    { lat: 48.8873261, lng: 17.9777979 },
    { lat: 48.8870197, lng: 17.9773885 },
    { lat: 48.8864368, lng: 17.9763737 },
    { lat: 48.8862522, lng: 17.9756174 },
    { lat: 48.8859249, lng: 17.975657 },
    { lat: 48.8854822, lng: 17.9756539 },
    { lat: 48.8849474, lng: 17.9756378 },
    { lat: 48.8850902, lng: 17.9753612 },
    { lat: 48.8852117, lng: 17.9751252 },
    { lat: 48.8855123, lng: 17.9749809 },
    { lat: 48.8855679, lng: 17.974511 },
    { lat: 48.8856155, lng: 17.9741133 },
    { lat: 48.8852009, lng: 17.9735913 },
    { lat: 48.8851826, lng: 17.9735687 },
    { lat: 48.8851655, lng: 17.9735481 },
    { lat: 48.8851349, lng: 17.9735067 },
    { lat: 48.8847528, lng: 17.9732293 },
    { lat: 48.8846703, lng: 17.9731702 },
    { lat: 48.8845506, lng: 17.9729785 },
    { lat: 48.8845169, lng: 17.9729236 },
    { lat: 48.8843721, lng: 17.9728239 },
    { lat: 48.8842321, lng: 17.9727283 },
    { lat: 48.884117, lng: 17.9723509 },
    { lat: 48.8840962, lng: 17.9722827 },
    { lat: 48.8840891, lng: 17.972259 },
    { lat: 48.8840664, lng: 17.9721792 },
    { lat: 48.8840434, lng: 17.9720988 },
    { lat: 48.884028, lng: 17.972046 },
    { lat: 48.8839989, lng: 17.9719553 },
    { lat: 48.8839597, lng: 17.971829 },
    { lat: 48.8839401, lng: 17.971765 },
    { lat: 48.8839176, lng: 17.9716888 },
    { lat: 48.8838936, lng: 17.9716064 },
    { lat: 48.8838595, lng: 17.9714912 },
    { lat: 48.8838244, lng: 17.9713734 },
    { lat: 48.8838076, lng: 17.9713169 },
    { lat: 48.8837919, lng: 17.9712645 },
    { lat: 48.8837128, lng: 17.9709959 },
    { lat: 48.8836575, lng: 17.9708238 },
    { lat: 48.8834259, lng: 17.9709558 },
    { lat: 48.8834167, lng: 17.9706092 },
    { lat: 48.8834017, lng: 17.9698983 },
    { lat: 48.8833963, lng: 17.9696394 },
    { lat: 48.8831619, lng: 17.968635 },
    { lat: 48.8827592, lng: 17.9688305 },
    { lat: 48.8826595, lng: 17.9688412 },
    { lat: 48.8824691, lng: 17.9688851 },
    { lat: 48.8824716, lng: 17.9686804 },
    { lat: 48.882628, lng: 17.9686727 },
    { lat: 48.8826799, lng: 17.9686541 },
    { lat: 48.8826874, lng: 17.9686251 },
    { lat: 48.8827231, lng: 17.9685058 },
    { lat: 48.8827535, lng: 17.9684282 },
    { lat: 48.8829146, lng: 17.9680063 },
    { lat: 48.8829243, lng: 17.9679781 },
    { lat: 48.8830362, lng: 17.9675451 },
    { lat: 48.8831022, lng: 17.9674009 },
    { lat: 48.8832926, lng: 17.9672623 },
    { lat: 48.88339, lng: 17.9672698 },
    { lat: 48.8836604, lng: 17.9672784 },
    { lat: 48.8837411, lng: 17.9672312 },
    { lat: 48.883849, lng: 17.9671637 },
    { lat: 48.8846083, lng: 17.9666882 },
    { lat: 48.884659, lng: 17.9665701 },
    { lat: 48.8846747, lng: 17.966512 },
    { lat: 48.8846923, lng: 17.9664454 },
    { lat: 48.8848835, lng: 17.9657695 },
    { lat: 48.8849022, lng: 17.9657059 },
    { lat: 48.8849158, lng: 17.9656485 },
    { lat: 48.8849561, lng: 17.9655103 },
    { lat: 48.8849206, lng: 17.9654818 },
    { lat: 48.8848815, lng: 17.9654491 },
    { lat: 48.8848149, lng: 17.9653951 },
    { lat: 48.8848055, lng: 17.9653878 },
    { lat: 48.8847116, lng: 17.9653073 },
    { lat: 48.8846842, lng: 17.9652845 },
    { lat: 48.8845442, lng: 17.9651699 },
    { lat: 48.8843852, lng: 17.9650377 },
    { lat: 48.8841524, lng: 17.9648454 },
    { lat: 48.884077, lng: 17.9648456 },
    { lat: 48.8840161, lng: 17.9648468 },
    { lat: 48.8838856, lng: 17.9649478 },
    { lat: 48.8838542, lng: 17.9649722 },
    { lat: 48.8837766, lng: 17.9650315 },
    { lat: 48.8836038, lng: 17.9648984 },
    { lat: 48.8835382, lng: 17.964841 },
    { lat: 48.8834905, lng: 17.9648543 },
    { lat: 48.883457, lng: 17.9647094 },
    { lat: 48.883287, lng: 17.9641441 },
    { lat: 48.8832379, lng: 17.9639668 },
    { lat: 48.8831945, lng: 17.9638214 },
    { lat: 48.8831931, lng: 17.9638169 },
    { lat: 48.8829237, lng: 17.9628732 },
    { lat: 48.8828619, lng: 17.9626489 },
    { lat: 48.88269, lng: 17.9620369 },
    { lat: 48.8823709, lng: 17.9609017 },
    { lat: 48.8823525, lng: 17.9608379 },
    { lat: 48.8821471, lng: 17.9601165 },
    { lat: 48.8820842, lng: 17.9598382 },
    { lat: 48.8820804, lng: 17.9597738 },
    { lat: 48.8820744, lng: 17.9596921 },
    { lat: 48.8821005, lng: 17.9595623 },
    { lat: 48.8822401, lng: 17.959213 },
    { lat: 48.882477, lng: 17.9587377 },
    { lat: 48.8825976, lng: 17.9585078 },
    { lat: 48.8826837, lng: 17.9583401 },
    { lat: 48.8826694, lng: 17.9583112 },
    { lat: 48.8825545, lng: 17.9580489 },
    { lat: 48.8822464, lng: 17.9573466 },
    { lat: 48.8820256, lng: 17.9566868 },
    { lat: 48.8815114, lng: 17.9553101 },
    { lat: 48.881128, lng: 17.9544986 },
    { lat: 48.8808767, lng: 17.954051 },
    { lat: 48.8807659, lng: 17.9539341 },
    { lat: 48.8804142, lng: 17.9536954 },
    { lat: 48.880246, lng: 17.9535803 },
    { lat: 48.8794926, lng: 17.9535144 },
    { lat: 48.8794297, lng: 17.9535001 },
    { lat: 48.8793531, lng: 17.953484 },
    { lat: 48.8792845, lng: 17.9534679 },
    { lat: 48.8792891, lng: 17.9534494 },
    { lat: 48.8792954, lng: 17.9534252 },
    { lat: 48.8793211, lng: 17.9533431 },
    { lat: 48.87938, lng: 17.9531572 },
    { lat: 48.8794028, lng: 17.953087 },
    { lat: 48.8794169, lng: 17.9530568 },
    { lat: 48.879499, lng: 17.9528824 },
    { lat: 48.8797989, lng: 17.9528385 },
    { lat: 48.8798927, lng: 17.9528572 },
    { lat: 48.879956, lng: 17.9528794 },
    { lat: 48.8800348, lng: 17.9529117 },
    { lat: 48.8801191, lng: 17.9529453 },
    { lat: 48.8802507, lng: 17.9529955 },
    { lat: 48.8804461, lng: 17.9526385 },
    { lat: 48.8809746, lng: 17.9518045 },
    { lat: 48.8807672, lng: 17.9515463 },
    { lat: 48.8807317, lng: 17.9514992 },
    { lat: 48.8806483, lng: 17.9513894 },
    { lat: 48.8805816, lng: 17.9513099 },
    { lat: 48.8805379, lng: 17.9512593 },
    { lat: 48.8804041, lng: 17.9514942 },
    { lat: 48.8801785, lng: 17.9511725 },
    { lat: 48.8800711, lng: 17.9510022 },
    { lat: 48.8800072, lng: 17.9509331 },
    { lat: 48.879958, lng: 17.9508469 },
    { lat: 48.879926, lng: 17.9508018 },
    { lat: 48.8800278, lng: 17.9504817 },
    { lat: 48.8800035, lng: 17.9504549 },
    { lat: 48.8799628, lng: 17.9504142 },
    { lat: 48.8799209, lng: 17.9503733 },
    { lat: 48.8798521, lng: 17.9503192 },
    { lat: 48.8796015, lng: 17.95011 },
    { lat: 48.879194, lng: 17.9497688 },
    { lat: 48.8791478, lng: 17.9496561 },
    { lat: 48.8790859, lng: 17.9495057 },
    { lat: 48.8785768, lng: 17.9503029 },
    { lat: 48.8785771, lng: 17.9505349 },
    { lat: 48.8781991, lng: 17.9511973 },
    { lat: 48.8779049, lng: 17.9515083 },
    { lat: 48.877252, lng: 17.9524481 },
    { lat: 48.8762515, lng: 17.9535051 },
    { lat: 48.8760489, lng: 17.9536866 },
    { lat: 48.8759802, lng: 17.9538 },
    { lat: 48.8759546, lng: 17.9538472 },
    { lat: 48.8759092, lng: 17.953919 },
    { lat: 48.875601, lng: 17.9545987 },
    { lat: 48.8740068, lng: 17.9553481 },
    { lat: 48.8733733, lng: 17.9556498 },
    { lat: 48.8733305, lng: 17.9556689 },
    { lat: 48.8733, lng: 17.9556895 },
    { lat: 48.8732394, lng: 17.9557326 },
    { lat: 48.8732308, lng: 17.9557381 },
    { lat: 48.8731469, lng: 17.9558073 },
    { lat: 48.8729073, lng: 17.9559949 },
    { lat: 48.8728105, lng: 17.956072 },
    { lat: 48.8724756, lng: 17.9564594 },
    { lat: 48.8724335, lng: 17.9565107 },
    { lat: 48.8723396, lng: 17.9566211 },
    { lat: 48.8722881, lng: 17.9566823 },
    { lat: 48.8720992, lng: 17.9569057 },
    { lat: 48.8719621, lng: 17.9570674 },
    { lat: 48.8716129, lng: 17.9574794 },
    { lat: 48.8716374, lng: 17.9575094 },
    { lat: 48.8715984, lng: 17.9575581 },
    { lat: 48.871033, lng: 17.9582234 },
    { lat: 48.8710216, lng: 17.9582382 },
    { lat: 48.8709763, lng: 17.9582918 },
    { lat: 48.8709497, lng: 17.9583226 },
    { lat: 48.8709012, lng: 17.958378 },
    { lat: 48.8708728, lng: 17.9584131 },
    { lat: 48.8708634, lng: 17.9584269 },
    { lat: 48.8701652, lng: 17.9594212 },
    { lat: 48.8693976, lng: 17.9605016 },
    { lat: 48.8693778, lng: 17.9605311 },
    { lat: 48.8693301, lng: 17.9605997 },
    { lat: 48.8692469, lng: 17.96072 },
    { lat: 48.8674275, lng: 17.9630938 },
    { lat: 48.8675382, lng: 17.9634375 },
    { lat: 48.8676659, lng: 17.9637474 },
    { lat: 48.867926, lng: 17.964364 },
    { lat: 48.8680418, lng: 17.9646401 },
    { lat: 48.8681966, lng: 17.9650051 },
    { lat: 48.8682599, lng: 17.9651581 },
    { lat: 48.8684272, lng: 17.9655576 },
    { lat: 48.8684615, lng: 17.9656422 },
    { lat: 48.8686257, lng: 17.9658055 },
    { lat: 48.8689498, lng: 17.96617 },
    { lat: 48.8687442, lng: 17.9666587 },
    { lat: 48.8689502, lng: 17.9671641 },
    { lat: 48.8690475, lng: 17.9673767 },
    { lat: 48.8690684, lng: 17.9674269 },
    { lat: 48.8691342, lng: 17.9675624 },
    { lat: 48.8693138, lng: 17.967966 },
    { lat: 48.8693831, lng: 17.9681265 },
    { lat: 48.8695777, lng: 17.9686671 },
    { lat: 48.8697339, lng: 17.9691277 },
    { lat: 48.8701309, lng: 17.9695794 },
    { lat: 48.8702773, lng: 17.9697469 },
    { lat: 48.8702882, lng: 17.9697591 },
    { lat: 48.8704962, lng: 17.9699713 },
    { lat: 48.8699306, lng: 17.9711297 },
    { lat: 48.8699204, lng: 17.971149 },
    { lat: 48.8699936, lng: 17.9712226 },
    { lat: 48.8701686, lng: 17.9713948 },
    { lat: 48.8704497, lng: 17.9716638 },
    { lat: 48.8705941, lng: 17.9718122 },
    { lat: 48.8710881, lng: 17.9722823 },
    { lat: 48.8715217, lng: 17.9727216 },
    { lat: 48.8714921, lng: 17.9728138 },
    { lat: 48.8713454, lng: 17.9733103 },
    { lat: 48.8712629, lng: 17.9735937 },
    { lat: 48.8712504, lng: 17.9736312 },
    { lat: 48.8712385, lng: 17.9736769 },
    { lat: 48.8708841, lng: 17.9748951 },
    { lat: 48.8708522, lng: 17.9750038 },
    { lat: 48.870812, lng: 17.9751437 },
    { lat: 48.8703327, lng: 17.9769207 },
    { lat: 48.8700668, lng: 17.9779005 },
  ],
  KrivosúdBodovka: [
    { lat: 48.811522, lng: 17.920725 },
    { lat: 48.811523, lng: 17.920774 },
    { lat: 48.811523, lng: 17.920858 },
    { lat: 48.811524, lng: 17.920932 },
    { lat: 48.811525, lng: 17.920969 },
    { lat: 48.811536, lng: 17.921843 },
    { lat: 48.811541, lng: 17.922274 },
    { lat: 48.811382, lng: 17.922856 },
    { lat: 48.811333, lng: 17.923192 },
    { lat: 48.81134, lng: 17.923408 },
    { lat: 48.81143, lng: 17.923614 },
    { lat: 48.811501, lng: 17.923798 },
    { lat: 48.811483, lng: 17.924016 },
    { lat: 48.811505, lng: 17.924312 },
    { lat: 48.811453, lng: 17.924757 },
    { lat: 48.811447, lng: 17.924802 },
    { lat: 48.811398, lng: 17.926461 },
    { lat: 48.811355, lng: 17.927471 },
    { lat: 48.811385, lng: 17.928394 },
    { lat: 48.811355, lng: 17.928912 },
    { lat: 48.810809, lng: 17.929415 },
    { lat: 48.81084, lng: 17.929518 },
    { lat: 48.81081, lng: 17.929559 },
    { lat: 48.810391, lng: 17.930107 },
    { lat: 48.81035, lng: 17.93016 },
    { lat: 48.810338, lng: 17.930177 },
    { lat: 48.810317, lng: 17.930205 },
    { lat: 48.810118, lng: 17.930465 },
    { lat: 48.81008, lng: 17.930515 },
    { lat: 48.80991, lng: 17.93036 },
    { lat: 48.809609, lng: 17.929822 },
    { lat: 48.809519, lng: 17.929541 },
    { lat: 48.809473, lng: 17.929214 },
    { lat: 48.809322, lng: 17.929046 },
    { lat: 48.809267, lng: 17.929008 },
    { lat: 48.809236, lng: 17.92899 },
    { lat: 48.809195, lng: 17.92893 },
    { lat: 48.809188, lng: 17.92897 },
    { lat: 48.80915, lng: 17.929052 },
    { lat: 48.809132, lng: 17.929092 },
    { lat: 48.808877, lng: 17.929583 },
    { lat: 48.808777, lng: 17.929661 },
    { lat: 48.808337, lng: 17.930083 },
    { lat: 48.808245, lng: 17.930177 },
    { lat: 48.808213, lng: 17.930348 },
    { lat: 48.808134, lng: 17.930434 },
    { lat: 48.808106, lng: 17.93046 },
    { lat: 48.808056, lng: 17.930508 },
    { lat: 48.80781, lng: 17.930745 },
    { lat: 48.807638, lng: 17.930956 },
    { lat: 48.807559, lng: 17.931135 },
    { lat: 48.807401, lng: 17.931771 },
    { lat: 48.807322, lng: 17.932025 },
    { lat: 48.807239, lng: 17.932228 },
    { lat: 48.806918, lng: 17.932783 },
    { lat: 48.806818, lng: 17.932895 },
    { lat: 48.806674, lng: 17.932803 },
    { lat: 48.806649, lng: 17.932787 },
    { lat: 48.806603, lng: 17.932758 },
    { lat: 48.806548, lng: 17.932724 },
    { lat: 48.806519, lng: 17.932705 },
    { lat: 48.806486, lng: 17.932684 },
    { lat: 48.806446, lng: 17.932659 },
    { lat: 48.806408, lng: 17.932634 },
    { lat: 48.806324, lng: 17.932581 },
    { lat: 48.806198, lng: 17.932501 },
    { lat: 48.806074, lng: 17.932423 },
    { lat: 48.805922, lng: 17.932325 },
    { lat: 48.80588, lng: 17.932311 },
    { lat: 48.805849, lng: 17.9323 },
    { lat: 48.805841, lng: 17.932298 },
    { lat: 48.805741, lng: 17.932264 },
    { lat: 48.805485, lng: 17.932177 },
    { lat: 48.804504, lng: 17.931636 },
    { lat: 48.80432, lng: 17.931525 },
    { lat: 48.804086, lng: 17.931392 },
    { lat: 48.803721, lng: 17.930776 },
    { lat: 48.803543, lng: 17.930469 },
    { lat: 48.803361, lng: 17.930153 },
    { lat: 48.803086, lng: 17.930392 },
    { lat: 48.80294, lng: 17.930139 },
    { lat: 48.802673, lng: 17.930466 },
    { lat: 48.802576, lng: 17.930294 },
    { lat: 48.802413, lng: 17.930104 },
    { lat: 48.801775, lng: 17.92976 },
    { lat: 48.8013, lng: 17.929632 },
    { lat: 48.801289, lng: 17.929713 },
    { lat: 48.801284, lng: 17.929754 },
    { lat: 48.801274, lng: 17.929823 },
    { lat: 48.80065, lng: 17.929945 },
    { lat: 48.800441, lng: 17.930064 },
    { lat: 48.800351, lng: 17.930106 },
    { lat: 48.800347, lng: 17.930261 },
    { lat: 48.800338, lng: 17.93055 },
    { lat: 48.800021, lng: 17.930485 },
    { lat: 48.799734, lng: 17.930517 },
    { lat: 48.799514, lng: 17.93048 },
    { lat: 48.799288, lng: 17.930394 },
    { lat: 48.799152, lng: 17.930292 },
    { lat: 48.798993, lng: 17.930144 },
    { lat: 48.798356, lng: 17.929811 },
    { lat: 48.798304, lng: 17.929985 },
    { lat: 48.798175, lng: 17.930016 },
    { lat: 48.798088, lng: 17.930092 },
    { lat: 48.797971, lng: 17.930097 },
    { lat: 48.797917, lng: 17.930119 },
    { lat: 48.79774, lng: 17.930164 },
    { lat: 48.797544, lng: 17.930409 },
    { lat: 48.79746, lng: 17.930448 },
    { lat: 48.797432, lng: 17.930484 },
    { lat: 48.797416, lng: 17.93052 },
    { lat: 48.797375, lng: 17.930537 },
    { lat: 48.797325, lng: 17.930612 },
    { lat: 48.797279, lng: 17.930645 },
    { lat: 48.797153, lng: 17.930827 },
    { lat: 48.797114, lng: 17.930963 },
    { lat: 48.796964, lng: 17.931266 },
    { lat: 48.79673, lng: 17.931663 },
    { lat: 48.796678, lng: 17.931761 },
    { lat: 48.796543, lng: 17.931849 },
    { lat: 48.796358, lng: 17.93195 },
    { lat: 48.796239, lng: 17.932026 },
    { lat: 48.796061, lng: 17.932257 },
    { lat: 48.7958, lng: 17.932643 },
    { lat: 48.796463, lng: 17.933776 },
    { lat: 48.796221, lng: 17.934592 },
    { lat: 48.796213, lng: 17.934622 },
    { lat: 48.796202, lng: 17.934829 },
    { lat: 48.796102, lng: 17.935425 },
    { lat: 48.796184, lng: 17.935905 },
    { lat: 48.796295, lng: 17.936147 },
    { lat: 48.796513, lng: 17.936376 },
    { lat: 48.796608, lng: 17.936536 },
    { lat: 48.796647, lng: 17.936689 },
    { lat: 48.796582, lng: 17.93692 },
    { lat: 48.79642, lng: 17.937128 },
    { lat: 48.796279, lng: 17.937259 },
    { lat: 48.796188, lng: 17.937306 },
    { lat: 48.796185, lng: 17.937307 },
    { lat: 48.796107, lng: 17.937328 },
    { lat: 48.795684, lng: 17.937253 },
    { lat: 48.795298, lng: 17.937376 },
    { lat: 48.795197, lng: 17.937409 },
    { lat: 48.795047, lng: 17.937568 },
    { lat: 48.794808, lng: 17.937891 },
    { lat: 48.794582, lng: 17.938117 },
    { lat: 48.794351, lng: 17.938314 },
    { lat: 48.79419, lng: 17.938548 },
    { lat: 48.794019, lng: 17.938892 },
    { lat: 48.793798, lng: 17.939308 },
    { lat: 48.793725, lng: 17.939626 },
    { lat: 48.793709, lng: 17.940148 },
    { lat: 48.793743, lng: 17.940327 },
    { lat: 48.79376, lng: 17.940622 },
    { lat: 48.793809, lng: 17.940977 },
    { lat: 48.793883, lng: 17.941387 },
    { lat: 48.794021, lng: 17.941918 },
    { lat: 48.79429, lng: 17.942543 },
    { lat: 48.794439, lng: 17.942895 },
    { lat: 48.794508, lng: 17.943121 },
    { lat: 48.794535, lng: 17.943412 },
    { lat: 48.794516, lng: 17.944121 },
    { lat: 48.794424, lng: 17.944648 },
    { lat: 48.794332, lng: 17.945129 },
    { lat: 48.794296, lng: 17.945376 },
    { lat: 48.794366, lng: 17.946278 },
    { lat: 48.79444, lng: 17.946573 },
    { lat: 48.794445, lng: 17.946711 },
    { lat: 48.794411, lng: 17.946922 },
    { lat: 48.794358, lng: 17.947323 },
    { lat: 48.794366, lng: 17.947581 },
    { lat: 48.794396, lng: 17.94792 },
    { lat: 48.794345, lng: 17.948445 },
    { lat: 48.794249, lng: 17.9492 },
    { lat: 48.794168, lng: 17.949968 },
    { lat: 48.794167, lng: 17.949983 },
    { lat: 48.794151, lng: 17.950714 },
    { lat: 48.794165, lng: 17.951416 },
    { lat: 48.794182, lng: 17.951827 },
    { lat: 48.794132, lng: 17.953865 },
    { lat: 48.794418, lng: 17.955623 },
    { lat: 48.794671, lng: 17.956723 },
    { lat: 48.794856, lng: 17.957465 },
    { lat: 48.79485, lng: 17.957484 },
    { lat: 48.794988, lng: 17.957572 },
    { lat: 48.795111, lng: 17.957599 },
    { lat: 48.795454, lng: 17.957685 },
    { lat: 48.795481, lng: 17.957712 },
    { lat: 48.795862, lng: 17.958132 },
    { lat: 48.794994, lng: 17.95962 },
    { lat: 48.795388, lng: 17.961066 },
    { lat: 48.795519, lng: 17.962429 },
    { lat: 48.795541, lng: 17.963788 },
    { lat: 48.795549, lng: 17.964247 },
    { lat: 48.795543, lng: 17.964891 },
    { lat: 48.795534, lng: 17.965617 },
    { lat: 48.795551, lng: 17.966502 },
    { lat: 48.7958191, lng: 17.9665862 },
    { lat: 48.7959188, lng: 17.9666084 },
    { lat: 48.7962665, lng: 17.9666133 },
    { lat: 48.79649, lng: 17.9666122 },
    { lat: 48.7967294, lng: 17.9666136 },
    { lat: 48.7969552, lng: 17.9666158 },
    { lat: 48.7970885, lng: 17.9666148 },
    { lat: 48.7973753, lng: 17.9666162 },
    { lat: 48.7975874, lng: 17.9666156 },
    { lat: 48.7978344, lng: 17.9666134 },
    { lat: 48.7980499, lng: 17.9666134 },
    { lat: 48.7982825, lng: 17.9666118 },
    { lat: 48.7984324, lng: 17.9666097 },
    { lat: 48.7985501, lng: 17.9664812 },
    { lat: 48.7986512, lng: 17.9663597 },
    { lat: 48.79871, lng: 17.9661719 },
    { lat: 48.7987906, lng: 17.9659121 },
    { lat: 48.7987949, lng: 17.9655864 },
    { lat: 48.798797, lng: 17.9654311 },
    { lat: 48.7987997, lng: 17.9653452 },
    { lat: 48.7988113, lng: 17.9652938 },
    { lat: 48.7988609, lng: 17.9650268 },
    { lat: 48.7989568, lng: 17.9647051 },
    { lat: 48.7990794, lng: 17.9642982 },
    { lat: 48.7991531, lng: 17.9640372 },
    { lat: 48.7991919, lng: 17.9639105 },
    { lat: 48.7992231, lng: 17.9637902 },
    { lat: 48.7992258, lng: 17.9637813 },
    { lat: 48.7993807, lng: 17.9635568 },
    { lat: 48.7995204, lng: 17.9633605 },
    { lat: 48.7996123, lng: 17.9631601 },
    { lat: 48.7997266, lng: 17.9629035 },
    { lat: 48.7998599, lng: 17.9626186 },
    { lat: 48.7999519, lng: 17.9624961 },
    { lat: 48.8001097, lng: 17.962282 },
    { lat: 48.8001309, lng: 17.9623171 },
    { lat: 48.8001472, lng: 17.9623238 },
    { lat: 48.800536, lng: 17.9624459 },
    { lat: 48.8007749, lng: 17.9624584 },
    { lat: 48.8010204, lng: 17.9624711 },
    { lat: 48.8010909, lng: 17.9624386 },
    { lat: 48.8015927, lng: 17.9622062 },
    { lat: 48.8019649, lng: 17.9623327 },
    { lat: 48.8021158, lng: 17.9621578 },
    { lat: 48.8022753, lng: 17.9619724 },
    { lat: 48.8026485, lng: 17.9616426 },
    { lat: 48.8031634, lng: 17.9612283 },
    { lat: 48.8037567, lng: 17.9609003 },
    { lat: 48.8040406, lng: 17.9607384 },
    { lat: 48.8042538, lng: 17.9604336 },
    { lat: 48.8045247, lng: 17.9597912 },
    { lat: 48.8047584, lng: 17.9595252 },
    { lat: 48.8052325, lng: 17.9591654 },
    { lat: 48.8054114, lng: 17.9588342 },
    { lat: 48.8057212, lng: 17.9586336 },
    { lat: 48.8058378, lng: 17.958622 },
    { lat: 48.8061665, lng: 17.9583383 },
    { lat: 48.8063649, lng: 17.9580875 },
    { lat: 48.806458, lng: 17.9577962 },
    { lat: 48.8067059, lng: 17.957487 },
    { lat: 48.807076, lng: 17.9574827 },
    { lat: 48.8076744, lng: 17.9572191 },
    { lat: 48.8079307, lng: 17.9570312 },
    { lat: 48.808057, lng: 17.9569366 },
    { lat: 48.8082749, lng: 17.9566351 },
    { lat: 48.8084358, lng: 17.9566239 },
    { lat: 48.8086918, lng: 17.9563164 },
    { lat: 48.8089762, lng: 17.9562418 },
    { lat: 48.8091912, lng: 17.9560986 },
    { lat: 48.809951, lng: 17.9558344 },
    { lat: 48.8105722, lng: 17.9557493 },
    { lat: 48.8107123, lng: 17.9557309 },
    { lat: 48.8108196, lng: 17.9556937 },
    { lat: 48.8109715, lng: 17.9556497 },
    { lat: 48.8116524, lng: 17.9556179 },
    { lat: 48.812074, lng: 17.9554569 },
    { lat: 48.8123416, lng: 17.9555145 },
    { lat: 48.8126612, lng: 17.9553646 },
    { lat: 48.8129235, lng: 17.9551816 },
    { lat: 48.8129831, lng: 17.9553124 },
    { lat: 48.8131253, lng: 17.9553886 },
    { lat: 48.8138218, lng: 17.9556341 },
    { lat: 48.8144363, lng: 17.9561938 },
    { lat: 48.8150907, lng: 17.9569281 },
    { lat: 48.8156444, lng: 17.9575248 },
    { lat: 48.8158203, lng: 17.9578095 },
    { lat: 48.816004, lng: 17.9579918 },
    { lat: 48.8160493, lng: 17.9580333 },
    { lat: 48.8166885, lng: 17.9583601 },
    { lat: 48.8169819, lng: 17.9583791 },
    { lat: 48.8172495, lng: 17.9583999 },
    { lat: 48.8177149, lng: 17.9585785 },
    { lat: 48.8181102, lng: 17.9588648 },
    { lat: 48.8183272, lng: 17.9590214 },
    { lat: 48.8183544, lng: 17.9590414 },
    { lat: 48.8185477, lng: 17.9591819 },
    { lat: 48.8187949, lng: 17.9591424 },
    { lat: 48.8191491, lng: 17.9591772 },
    { lat: 48.8193332, lng: 17.9589715 },
    { lat: 48.819461, lng: 17.9589577 },
    { lat: 48.8196294, lng: 17.9586388 },
    { lat: 48.8196692, lng: 17.958565 },
    { lat: 48.819915, lng: 17.9584308 },
    { lat: 48.8200092, lng: 17.95838 },
    { lat: 48.8201475, lng: 17.9582959 },
    { lat: 48.8204022, lng: 17.9577221 },
    { lat: 48.8205375, lng: 17.9572265 },
    { lat: 48.8206329, lng: 17.957009 },
    { lat: 48.8209948, lng: 17.956639 },
    { lat: 48.8213197, lng: 17.9565621 },
    { lat: 48.8217095, lng: 17.9567223 },
    { lat: 48.8218556, lng: 17.9567486 },
    { lat: 48.8220291, lng: 17.9569287 },
    { lat: 48.8222916, lng: 17.9571866 },
    { lat: 48.8226046, lng: 17.9576135 },
    { lat: 48.8230749, lng: 17.9577391 },
    { lat: 48.8232994, lng: 17.9579818 },
    { lat: 48.8236297, lng: 17.958051 },
    { lat: 48.8238221, lng: 17.9579459 },
    { lat: 48.8239627, lng: 17.9580356 },
    { lat: 48.8243767, lng: 17.9574804 },
    { lat: 48.8245365, lng: 17.95745 },
    { lat: 48.824645, lng: 17.9571111 },
    { lat: 48.8251038, lng: 17.9569524 },
    { lat: 48.8252848, lng: 17.9569972 },
    { lat: 48.8254673, lng: 17.956801 },
    { lat: 48.8256044, lng: 17.9567719 },
    { lat: 48.8257602, lng: 17.9567894 },
    { lat: 48.8266626, lng: 17.9567074 },
    { lat: 48.8267995, lng: 17.9567316 },
    { lat: 48.8268376, lng: 17.9567268 },
    { lat: 48.8268686, lng: 17.9566954 },
    { lat: 48.8271208, lng: 17.9567254 },
    { lat: 48.8271834, lng: 17.9567509 },
    { lat: 48.827203, lng: 17.9567582 },
    { lat: 48.8272754, lng: 17.9567989 },
    { lat: 48.8272952, lng: 17.9568288 },
    { lat: 48.8273014, lng: 17.9567992 },
    { lat: 48.827423, lng: 17.9564534 },
    { lat: 48.8274385, lng: 17.9564108 },
    { lat: 48.8275127, lng: 17.9559895 },
    { lat: 48.8275607, lng: 17.9550508 },
    { lat: 48.8277068, lng: 17.9540328 },
    { lat: 48.8277208, lng: 17.9539309 },
    { lat: 48.8277322, lng: 17.9538549 },
    { lat: 48.8277425, lng: 17.9531553 },
    { lat: 48.8278212, lng: 17.9523743 },
    { lat: 48.8279096, lng: 17.9519256 },
    { lat: 48.8279216, lng: 17.9518605 },
    { lat: 48.8280211, lng: 17.9513511 },
    { lat: 48.8282103, lng: 17.9501879 },
    { lat: 48.8282593, lng: 17.9497585 },
    { lat: 48.8282927, lng: 17.9494505 },
    { lat: 48.8283648, lng: 17.949144 },
    { lat: 48.8284165, lng: 17.949047 },
    { lat: 48.8284459, lng: 17.9489893 },
    { lat: 48.8284761, lng: 17.9489275 },
    { lat: 48.8294512, lng: 17.947109 },
    { lat: 48.8295792, lng: 17.9468704 },
    { lat: 48.8296554, lng: 17.9467296 },
    { lat: 48.8296902, lng: 17.9466654 },
    { lat: 48.8297053, lng: 17.9466368 },
    { lat: 48.8297481, lng: 17.9465575 },
    { lat: 48.8298434, lng: 17.9463793 },
    { lat: 48.8302612, lng: 17.9456028 },
    { lat: 48.8306304, lng: 17.944905 },
    { lat: 48.8308447, lng: 17.9445077 },
    { lat: 48.8310448, lng: 17.9441149 },
    { lat: 48.83112, lng: 17.9440319 },
    { lat: 48.8302639, lng: 17.9428869 },
    { lat: 48.8295312, lng: 17.9419357 },
    { lat: 48.8294029, lng: 17.941772 },
    { lat: 48.8289687, lng: 17.9412489 },
    { lat: 48.8275709, lng: 17.9394623 },
    { lat: 48.8272883, lng: 17.9390977 },
    { lat: 48.8268955, lng: 17.9385879 },
    { lat: 48.8268627, lng: 17.9385455 },
    { lat: 48.8267842, lng: 17.9384427 },
    { lat: 48.8266005, lng: 17.9376912 },
    { lat: 48.8258696, lng: 17.9347675 },
    { lat: 48.8258666, lng: 17.9347758 },
    { lat: 48.8255421, lng: 17.9334815 },
    { lat: 48.8250997, lng: 17.9321766 },
    { lat: 48.8249916, lng: 17.931859 },
    { lat: 48.8248846, lng: 17.9315455 },
    { lat: 48.8248478, lng: 17.9314363 },
    { lat: 48.8248121, lng: 17.9313294 },
    { lat: 48.8247281, lng: 17.9310801 },
    { lat: 48.8243797, lng: 17.9301564 },
    { lat: 48.8241422, lng: 17.9297165 },
    { lat: 48.8240598, lng: 17.9295648 },
    { lat: 48.8239824, lng: 17.9294217 },
    { lat: 48.8239051, lng: 17.9292797 },
    { lat: 48.8238289, lng: 17.9291379 },
    { lat: 48.8237884, lng: 17.9290647 },
    { lat: 48.8237555, lng: 17.9290058 },
    { lat: 48.8236823, lng: 17.9288768 },
    { lat: 48.8236028, lng: 17.928738 },
    { lat: 48.8235171, lng: 17.9285853 },
    { lat: 48.8234388, lng: 17.9284481 },
    { lat: 48.8230247, lng: 17.9280327 },
    { lat: 48.8229889, lng: 17.9280033 },
    { lat: 48.8225, lng: 17.927605 },
    { lat: 48.8222787, lng: 17.9274308 },
    { lat: 48.8217675, lng: 17.9272999 },
    { lat: 48.8217581, lng: 17.9272973 },
    { lat: 48.8215544, lng: 17.9272486 },
    { lat: 48.8215165, lng: 17.9272394 },
    { lat: 48.821094, lng: 17.9272157 },
    { lat: 48.820511, lng: 17.9275643 },
    { lat: 48.820385, lng: 17.9277391 },
    { lat: 48.8203004, lng: 17.9278557 },
    { lat: 48.8202391, lng: 17.9279415 },
    { lat: 48.820199, lng: 17.9279907 },
    { lat: 48.8200267, lng: 17.9279373 },
    { lat: 48.8197068, lng: 17.9278375 },
    { lat: 48.8196241, lng: 17.9278152 },
    { lat: 48.8195818, lng: 17.9278031 },
    { lat: 48.8193848, lng: 17.9277563 },
    { lat: 48.8186391, lng: 17.9275763 },
    { lat: 48.81822, lng: 17.927494 },
    { lat: 48.8179357, lng: 17.9274381 },
    { lat: 48.8177574, lng: 17.9274041 },
    { lat: 48.8172721, lng: 17.9272891 },
    { lat: 48.8171387, lng: 17.9272504 },
    { lat: 48.8171341, lng: 17.9272489 },
    { lat: 48.8168804, lng: 17.9271755 },
    { lat: 48.8167977, lng: 17.9271518 },
    { lat: 48.816304, lng: 17.9269777 },
    { lat: 48.8159282, lng: 17.9268236 },
    { lat: 48.8158414, lng: 17.9267882 },
    { lat: 48.8153912, lng: 17.9265298 },
    { lat: 48.8153081, lng: 17.9264764 },
    { lat: 48.8149685, lng: 17.9262568 },
    { lat: 48.8145403, lng: 17.9259069 },
    { lat: 48.8141412, lng: 17.9255583 },
    { lat: 48.8137496, lng: 17.9251633 },
    { lat: 48.8133655, lng: 17.9246852 },
    { lat: 48.8130005, lng: 17.9241708 },
    { lat: 48.8126612, lng: 17.9236472 },
    { lat: 48.8124905, lng: 17.9233805 },
    { lat: 48.8123435, lng: 17.9231493 },
    { lat: 48.8120177, lng: 17.9226091 },
    { lat: 48.811958, lng: 17.9224221 },
    { lat: 48.8118971, lng: 17.9222342 },
    { lat: 48.8118815, lng: 17.9221834 },
    { lat: 48.8118623, lng: 17.922123 },
    { lat: 48.8118259, lng: 17.9216491 },
    { lat: 48.8118067, lng: 17.9214784 },
    { lat: 48.8117512, lng: 17.9212511 },
    { lat: 48.8116736, lng: 17.9210293 },
    { lat: 48.811522, lng: 17.920725 },
  ],
  Selec: [
    { lat: 48.7761549, lng: 17.9663951 },
    { lat: 48.776013, lng: 17.966485 },
    { lat: 48.775813, lng: 17.966625 },
    { lat: 48.775653, lng: 17.966729 },
    { lat: 48.775463, lng: 17.966856 },
    { lat: 48.775379, lng: 17.966914 },
    { lat: 48.775149, lng: 17.967085 },
    { lat: 48.774939, lng: 17.967252 },
    { lat: 48.774701, lng: 17.967416 },
    { lat: 48.774451, lng: 17.967587 },
    { lat: 48.774353, lng: 17.967656 },
    { lat: 48.774008, lng: 17.967899 },
    { lat: 48.773853, lng: 17.968006 },
    { lat: 48.773725, lng: 17.968095 },
    { lat: 48.773672, lng: 17.96813 },
    { lat: 48.773536, lng: 17.968222 },
    { lat: 48.773422, lng: 17.968302 },
    { lat: 48.773287, lng: 17.968395 },
    { lat: 48.773194, lng: 17.968461 },
    { lat: 48.773073, lng: 17.968544 },
    { lat: 48.773021, lng: 17.968582 },
    { lat: 48.772857, lng: 17.968695 },
    { lat: 48.772777, lng: 17.968751 },
    { lat: 48.772628, lng: 17.968854 },
    { lat: 48.772485, lng: 17.968953 },
    { lat: 48.772361, lng: 17.96904 },
    { lat: 48.772269, lng: 17.969103 },
    { lat: 48.772081, lng: 17.969234 },
    { lat: 48.771893, lng: 17.969365 },
    { lat: 48.771746, lng: 17.969464 },
    { lat: 48.77161, lng: 17.969557 },
    { lat: 48.771466, lng: 17.969656 },
    { lat: 48.771335, lng: 17.969747 },
    { lat: 48.771243, lng: 17.96981 },
    { lat: 48.771191, lng: 17.969846 },
    { lat: 48.771145, lng: 17.969877 },
    { lat: 48.771031, lng: 17.969956 },
    { lat: 48.770992, lng: 17.969985 },
    { lat: 48.770893, lng: 17.970051 },
    { lat: 48.770815, lng: 17.970106 },
    { lat: 48.770782, lng: 17.970132 },
    { lat: 48.770682, lng: 17.970198 },
    { lat: 48.770591, lng: 17.97026 },
    { lat: 48.770484, lng: 17.970335 },
    { lat: 48.770412, lng: 17.970384 },
    { lat: 48.770367, lng: 17.970415 },
    { lat: 48.770331, lng: 17.970443 },
    { lat: 48.770264, lng: 17.970492 },
    { lat: 48.770203, lng: 17.970532 },
    { lat: 48.770112, lng: 17.970595 },
    { lat: 48.769977, lng: 17.970689 },
    { lat: 48.769859, lng: 17.970775 },
    { lat: 48.769724, lng: 17.97087 },
    { lat: 48.769597, lng: 17.97096 },
    { lat: 48.769491, lng: 17.971032 },
    { lat: 48.769438, lng: 17.971069 },
    { lat: 48.769292, lng: 17.971171 },
    { lat: 48.769151, lng: 17.971271 },
    { lat: 48.769058, lng: 17.971338 },
    { lat: 48.768882, lng: 17.971455 },
    { lat: 48.768731, lng: 17.971552 },
    { lat: 48.768579, lng: 17.971649 },
    { lat: 48.768519, lng: 17.97169 },
    { lat: 48.768395, lng: 17.971775 },
    { lat: 48.768226, lng: 17.9719 },
    { lat: 48.768099, lng: 17.971993 },
    { lat: 48.767917, lng: 17.972122 },
    { lat: 48.767755, lng: 17.972239 },
    { lat: 48.767575, lng: 17.972368 },
    { lat: 48.767459, lng: 17.972453 },
    { lat: 48.767277, lng: 17.972584 },
    { lat: 48.767118, lng: 17.972696 },
    { lat: 48.767039, lng: 17.972758 },
    { lat: 48.766869, lng: 17.972891 },
    { lat: 48.766712, lng: 17.973014 },
    { lat: 48.766431, lng: 17.973204 },
    { lat: 48.766153, lng: 17.9734 },
    { lat: 48.765778, lng: 17.973626 },
    { lat: 48.765321, lng: 17.973908 },
    { lat: 48.764708, lng: 17.974367 },
    { lat: 48.764514, lng: 17.974511 },
    { lat: 48.764176, lng: 17.974761 },
    { lat: 48.763823, lng: 17.975014 },
    { lat: 48.763435, lng: 17.97528 },
    { lat: 48.762943, lng: 17.975611 },
    { lat: 48.762676, lng: 17.975783 },
    { lat: 48.762337, lng: 17.976012 },
    { lat: 48.761822, lng: 17.976355 },
    { lat: 48.761422, lng: 17.976626 },
    { lat: 48.761182, lng: 17.976806 },
    { lat: 48.760741, lng: 17.977115 },
    { lat: 48.76058, lng: 17.977224 },
    { lat: 48.760324, lng: 17.977408 },
    { lat: 48.760133, lng: 17.977543 },
    { lat: 48.759869, lng: 17.97773 },
    { lat: 48.759493, lng: 17.977992 },
    { lat: 48.759112, lng: 17.978257 },
    { lat: 48.758795, lng: 17.978479 },
    { lat: 48.758525, lng: 17.978669 },
    { lat: 48.758255, lng: 17.978824 },
    { lat: 48.758035, lng: 17.978952 },
    { lat: 48.75768, lng: 17.979152 },
    { lat: 48.757244, lng: 17.979411 },
    { lat: 48.756757, lng: 17.979698 },
    { lat: 48.756276, lng: 17.979982 },
    { lat: 48.75575, lng: 17.980307 },
    { lat: 48.755242, lng: 17.980634 },
    { lat: 48.754789, lng: 17.98092 },
    { lat: 48.754388, lng: 17.981175 },
    { lat: 48.7541, lng: 17.981362 },
    { lat: 48.753772, lng: 17.981576 },
    { lat: 48.753536, lng: 17.981722 },
    { lat: 48.753133, lng: 17.98196 },
    { lat: 48.752768, lng: 17.982176 },
    { lat: 48.75223, lng: 17.982499 },
    { lat: 48.751746, lng: 17.982792 },
    { lat: 48.751239, lng: 17.9831 },
    { lat: 48.750919, lng: 17.9833 },
    { lat: 48.750403, lng: 17.983628 },
    { lat: 48.75037, lng: 17.983649 },
    { lat: 48.750446, lng: 17.983873 },
    { lat: 48.750602, lng: 17.984359 },
    { lat: 48.750747, lng: 17.984746 },
    { lat: 48.750915, lng: 17.9852 },
    { lat: 48.751004, lng: 17.985448 },
    { lat: 48.751113, lng: 17.985742 },
    { lat: 48.751229, lng: 17.986075 },
    { lat: 48.751361, lng: 17.98646 },
    { lat: 48.75144, lng: 17.986693 },
    { lat: 48.751567, lng: 17.987068 },
    { lat: 48.751664, lng: 17.987351 },
    { lat: 48.751683, lng: 17.987331 },
    { lat: 48.751753, lng: 17.987492 },
    { lat: 48.751824, lng: 17.987658 },
    { lat: 48.751876, lng: 17.987784 },
    { lat: 48.752012, lng: 17.988087 },
    { lat: 48.752086, lng: 17.988292 },
    { lat: 48.752183, lng: 17.988553 },
    { lat: 48.7523, lng: 17.988878 },
    { lat: 48.752428, lng: 17.989217 },
    { lat: 48.752489, lng: 17.989363 },
    { lat: 48.752564, lng: 17.989539 },
    { lat: 48.752606, lng: 17.98964 },
    { lat: 48.752703, lng: 17.98986 },
    { lat: 48.752785, lng: 17.990051 },
    { lat: 48.752884, lng: 17.990279 },
    { lat: 48.752947, lng: 17.990544 },
    { lat: 48.753027, lng: 17.990859 },
    { lat: 48.753075, lng: 17.991057 },
    { lat: 48.753116, lng: 17.991176 },
    { lat: 48.753235, lng: 17.991511 },
    { lat: 48.753311, lng: 17.991722 },
    { lat: 48.753413, lng: 17.992 },
    { lat: 48.753519, lng: 17.99229 },
    { lat: 48.753584, lng: 17.99244 },
    { lat: 48.7537, lng: 17.992711 },
    { lat: 48.753802, lng: 17.992947 },
    { lat: 48.753936, lng: 17.993242 },
    { lat: 48.754077, lng: 17.993551 },
    { lat: 48.754092, lng: 17.993583 },
    { lat: 48.754, lng: 17.993609 },
    { lat: 48.753837, lng: 17.993661 },
    { lat: 48.753668, lng: 17.993715 },
    { lat: 48.753528, lng: 17.993781 },
    { lat: 48.753354, lng: 17.99386 },
    { lat: 48.753201, lng: 17.993928 },
    { lat: 48.753078, lng: 17.993988 },
    { lat: 48.752967, lng: 17.994264 },
    { lat: 48.752888, lng: 17.99446 },
    { lat: 48.752836, lng: 17.994784 },
    { lat: 48.752784, lng: 17.995098 },
    { lat: 48.752768, lng: 17.995189 },
    { lat: 48.752647, lng: 17.995542 },
    { lat: 48.75252, lng: 17.995912 },
    { lat: 48.752428, lng: 17.996177 },
    { lat: 48.752295, lng: 17.996688 },
    { lat: 48.752205, lng: 17.997037 },
    { lat: 48.752038, lng: 17.997223 },
    { lat: 48.751808, lng: 17.997485 },
    { lat: 48.75165, lng: 17.997664 },
    { lat: 48.751581, lng: 17.997824 },
    { lat: 48.751497, lng: 17.998005 },
    { lat: 48.751395, lng: 17.998222 },
    { lat: 48.751357, lng: 17.998286 },
    { lat: 48.751228, lng: 17.998492 },
    { lat: 48.751069, lng: 17.998745 },
    { lat: 48.750914, lng: 17.998992 },
    { lat: 48.750828, lng: 17.999135 },
    { lat: 48.750771, lng: 17.999266 },
    { lat: 48.75068, lng: 17.999478 },
    { lat: 48.750565, lng: 17.999744 },
    { lat: 48.750456, lng: 17.999997 },
    { lat: 48.750418, lng: 18.000107 },
    { lat: 48.750327, lng: 18.000378 },
    { lat: 48.750284, lng: 18.000501 },
    { lat: 48.750222, lng: 18.000702 },
    { lat: 48.750117, lng: 18.000987 },
    { lat: 48.750073, lng: 18.001113 },
    { lat: 48.750035, lng: 18.001219 },
    { lat: 48.749999, lng: 18.0013 },
    { lat: 48.749942, lng: 18.001427 },
    { lat: 48.749881, lng: 18.001559 },
    { lat: 48.749828, lng: 18.001681 },
    { lat: 48.7498, lng: 18.00174 },
    { lat: 48.749781, lng: 18.001775 },
    { lat: 48.74966, lng: 18.001942 },
    { lat: 48.749527, lng: 18.002124 },
    { lat: 48.749413, lng: 18.002283 },
    { lat: 48.749398, lng: 18.002302 },
    { lat: 48.749331, lng: 18.002336 },
    { lat: 48.749169, lng: 18.002422 },
    { lat: 48.749038, lng: 18.002493 },
    { lat: 48.748932, lng: 18.002547 },
    { lat: 48.748901, lng: 18.002564 },
    { lat: 48.748749, lng: 18.002699 },
    { lat: 48.748613, lng: 18.002819 },
    { lat: 48.748456, lng: 18.002957 },
    { lat: 48.748388, lng: 18.003014 },
    { lat: 48.74824, lng: 18.003147 },
    { lat: 48.748139, lng: 18.003229 },
    { lat: 48.748081, lng: 18.003323 },
    { lat: 48.747977, lng: 18.003482 },
    { lat: 48.747978, lng: 18.003499 },
    { lat: 48.748026, lng: 18.004208 },
    { lat: 48.748431, lng: 18.004918 },
    { lat: 48.748931, lng: 18.005673 },
    { lat: 48.749154, lng: 18.006209 },
    { lat: 48.749544, lng: 18.006855 },
    { lat: 48.749715, lng: 18.007003 },
    { lat: 48.749979, lng: 18.00769 },
    { lat: 48.750222, lng: 18.009146 },
    { lat: 48.750421, lng: 18.009808 },
    { lat: 48.750515, lng: 18.01005 },
    { lat: 48.751022, lng: 18.011211 },
    { lat: 48.751236, lng: 18.01192 },
    { lat: 48.751622, lng: 18.012964 },
    { lat: 48.751672, lng: 18.013523 },
    { lat: 48.751761, lng: 18.01423 },
    { lat: 48.752205, lng: 18.015492 },
    { lat: 48.752479, lng: 18.016703 },
    { lat: 48.752633, lng: 18.01763 },
    { lat: 48.752904, lng: 18.018407 },
    { lat: 48.753149, lng: 18.019042 },
    { lat: 48.753218, lng: 18.019543 },
    { lat: 48.753177, lng: 18.020716 },
    { lat: 48.752841, lng: 18.021213 },
    { lat: 48.752768, lng: 18.021494 },
    { lat: 48.753008, lng: 18.021815 },
    { lat: 48.753112, lng: 18.022304 },
    { lat: 48.753045, lng: 18.023021 },
    { lat: 48.753451, lng: 18.024245 },
    { lat: 48.753786, lng: 18.024832 },
    { lat: 48.753947, lng: 18.025215 },
    { lat: 48.754298, lng: 18.026363 },
    { lat: 48.754362, lng: 18.026573 },
    { lat: 48.754437, lng: 18.027375 },
    { lat: 48.754585, lng: 18.027859 },
    { lat: 48.754794, lng: 18.02837 },
    { lat: 48.75536, lng: 18.029463 },
    { lat: 48.755665, lng: 18.029952 },
    { lat: 48.756156, lng: 18.030306 },
    { lat: 48.756822, lng: 18.030991 },
    { lat: 48.757547, lng: 18.031528 },
    { lat: 48.758032, lng: 18.031754 },
    { lat: 48.758819, lng: 18.032202 },
    { lat: 48.759561, lng: 18.032846 },
    { lat: 48.759981, lng: 18.033179 },
    { lat: 48.760023, lng: 18.033212 },
    { lat: 48.7604, lng: 18.033502 },
    { lat: 48.760897, lng: 18.033779 },
    { lat: 48.761991, lng: 18.033979 },
    { lat: 48.762285, lng: 18.034094 },
    { lat: 48.762623, lng: 18.034407 },
    { lat: 48.763329, lng: 18.036124 },
    { lat: 48.763571, lng: 18.036406 },
    { lat: 48.764108, lng: 18.036782 },
    { lat: 48.764551, lng: 18.036991 },
    { lat: 48.764967, lng: 18.037153 },
    { lat: 48.765646, lng: 18.0373 },
    { lat: 48.765866, lng: 18.037347 },
    { lat: 48.766344, lng: 18.037363 },
    { lat: 48.7665811, lng: 18.0375206 },
    { lat: 48.7672425, lng: 18.0379605 },
    { lat: 48.7673285, lng: 18.0380179 },
    { lat: 48.7679911, lng: 18.0384238 },
    { lat: 48.7681265, lng: 18.0385218 },
    { lat: 48.7682186, lng: 18.0385717 },
    { lat: 48.7685436, lng: 18.0387408 },
    { lat: 48.7687794, lng: 18.0391995 },
    { lat: 48.7691618, lng: 18.0397541 },
    { lat: 48.7694102, lng: 18.0399836 },
    { lat: 48.7695499, lng: 18.0401147 },
    { lat: 48.7696688, lng: 18.0402389 },
    { lat: 48.7700667, lng: 18.0407463 },
    { lat: 48.7703226, lng: 18.0410679 },
    { lat: 48.7705848, lng: 18.0412272 },
    { lat: 48.7707383, lng: 18.0412638 },
    { lat: 48.7710963, lng: 18.0413479 },
    { lat: 48.7715298, lng: 18.0414645 },
    { lat: 48.7719116, lng: 18.0415659 },
    { lat: 48.7721659, lng: 18.0415914 },
    { lat: 48.7724799, lng: 18.0416346 },
    { lat: 48.7728339, lng: 18.0417622 },
    { lat: 48.7735967, lng: 18.0416423 },
    { lat: 48.7737969, lng: 18.0416125 },
    { lat: 48.7741226, lng: 18.0416985 },
    { lat: 48.7745252, lng: 18.0416185 },
    { lat: 48.7748111, lng: 18.0414133 },
    { lat: 48.7749767, lng: 18.0413903 },
    { lat: 48.7753371, lng: 18.0412208 },
    { lat: 48.775558, lng: 18.0411212 },
    { lat: 48.7756382, lng: 18.0410443 },
    { lat: 48.7757905, lng: 18.0409124 },
    { lat: 48.7761451, lng: 18.040704 },
    { lat: 48.7767694, lng: 18.040017 },
    { lat: 48.7771245, lng: 18.0391672 },
    { lat: 48.7771033, lng: 18.0377688 },
    { lat: 48.7770989, lng: 18.0373088 },
    { lat: 48.7775454, lng: 18.0355572 },
    { lat: 48.7780168, lng: 18.0349604 },
    { lat: 48.7781168, lng: 18.0349112 },
    { lat: 48.7784839, lng: 18.0347071 },
    { lat: 48.7788843, lng: 18.0346477 },
    { lat: 48.7793663, lng: 18.0344546 },
    { lat: 48.7795354, lng: 18.0343953 },
    { lat: 48.7800623, lng: 18.034257 },
    { lat: 48.780638, lng: 18.0340097 },
    { lat: 48.7808661, lng: 18.0338795 },
    { lat: 48.7812471, lng: 18.0336577 },
    { lat: 48.7816157, lng: 18.0333629 },
    { lat: 48.781918, lng: 18.0330956 },
    { lat: 48.7825908, lng: 18.0327336 },
    { lat: 48.7826602, lng: 18.032682 },
    { lat: 48.7831304, lng: 18.0321773 },
    { lat: 48.7837627, lng: 18.0313211 },
    { lat: 48.784414, lng: 18.0304802 },
    { lat: 48.7851727, lng: 18.0301399 },
    { lat: 48.7858369, lng: 18.0290384 },
    { lat: 48.7863944, lng: 18.0285376 },
    { lat: 48.7869353, lng: 18.0277946 },
    { lat: 48.7874233, lng: 18.0274034 },
    { lat: 48.7877024, lng: 18.0274291 },
    { lat: 48.7879816, lng: 18.027435 },
    { lat: 48.7880219, lng: 18.0274482 },
    { lat: 48.7882464, lng: 18.0272923 },
    { lat: 48.7889958, lng: 18.0267773 },
    { lat: 48.7894385, lng: 18.0265128 },
    { lat: 48.7900804, lng: 18.0260883 },
    { lat: 48.7906586, lng: 18.0257492 },
    { lat: 48.7912366, lng: 18.0253848 },
    { lat: 48.7914458, lng: 18.0252381 },
    { lat: 48.791621, lng: 18.0251303 },
    { lat: 48.7918489, lng: 18.0249757 },
    { lat: 48.7915621, lng: 18.0240741 },
    { lat: 48.7913378, lng: 18.0233903 },
    { lat: 48.7911269, lng: 18.0227025 },
    { lat: 48.7907563, lng: 18.0215875 },
    { lat: 48.7913369, lng: 18.0208862 },
    { lat: 48.7917171, lng: 18.0200223 },
    { lat: 48.7920884, lng: 18.0198705 },
    { lat: 48.79255, lng: 18.0192393 },
    { lat: 48.7925436, lng: 18.0192273 },
    { lat: 48.7926616, lng: 18.0183372 },
    { lat: 48.7927285, lng: 18.0177529 },
    { lat: 48.7928772, lng: 18.017174 },
    { lat: 48.7929093, lng: 18.0170283 },
    { lat: 48.7929984, lng: 18.0163751 },
    { lat: 48.7930396, lng: 18.0159271 },
    { lat: 48.7932686, lng: 18.0149912 },
    { lat: 48.7936673, lng: 18.0143649 },
    { lat: 48.7938249, lng: 18.0134211 },
    { lat: 48.7940917, lng: 18.0126043 },
    { lat: 48.7947254, lng: 18.011677 },
    { lat: 48.7950034, lng: 18.0107111 },
    { lat: 48.7954031, lng: 18.0103699 },
    { lat: 48.7956013, lng: 18.0098666 },
    { lat: 48.7958994, lng: 18.0096762 },
    { lat: 48.7959457, lng: 18.00939 },
    { lat: 48.7960223, lng: 18.0089651 },
    { lat: 48.7967484, lng: 18.0088027 },
    { lat: 48.7969753, lng: 18.0087434 },
    { lat: 48.7970686, lng: 18.0085858 },
    { lat: 48.7974548, lng: 18.0083215 },
    { lat: 48.7976879, lng: 18.00814 },
    { lat: 48.7977283, lng: 18.0082512 },
    { lat: 48.7981639, lng: 18.0079448 },
    { lat: 48.7983338, lng: 18.0076114 },
    { lat: 48.7985485, lng: 18.0072147 },
    { lat: 48.7987691, lng: 18.0069367 },
    { lat: 48.7990799, lng: 18.0067135 },
    { lat: 48.7993339, lng: 18.0064693 },
    { lat: 48.7994494, lng: 18.0063978 },
    { lat: 48.7998195, lng: 18.006358 },
    { lat: 48.7998845, lng: 18.0063689 },
    { lat: 48.8000349, lng: 18.0066045 },
    { lat: 48.80012, lng: 18.0066478 },
    { lat: 48.8002741, lng: 18.0065829 },
    { lat: 48.8004135, lng: 18.0064609 },
    { lat: 48.8005993, lng: 18.0064905 },
    { lat: 48.8007668, lng: 18.0064789 },
    { lat: 48.8008174, lng: 18.0064756 },
    { lat: 48.8010617, lng: 18.0060465 },
    { lat: 48.8010925, lng: 18.0059922 },
    { lat: 48.8011017, lng: 18.005976 },
    { lat: 48.8012153, lng: 18.0059334 },
    { lat: 48.8012496, lng: 18.0059201 },
    { lat: 48.8013941, lng: 18.0058651 },
    { lat: 48.8014041, lng: 18.0058613 },
    { lat: 48.8014307, lng: 18.0058512 },
    { lat: 48.8014924, lng: 18.0058278 },
    { lat: 48.8016644, lng: 18.00572 },
    { lat: 48.8017578, lng: 18.0055638 },
    { lat: 48.8019626, lng: 18.0055908 },
    { lat: 48.8022607, lng: 18.0055529 },
    { lat: 48.8025173, lng: 18.0055618 },
    { lat: 48.8026477, lng: 18.0054941 },
    { lat: 48.802759, lng: 18.0052402 },
    { lat: 48.8033755, lng: 18.0052429 },
    { lat: 48.8036927, lng: 18.0050918 },
    { lat: 48.8042184, lng: 18.0048194 },
    { lat: 48.8042986, lng: 18.0048042 },
    { lat: 48.8043276, lng: 18.0047988 },
    { lat: 48.8043779, lng: 18.0047895 },
    { lat: 48.8045711, lng: 18.0047526 },
    { lat: 48.804609, lng: 18.004746 },
    { lat: 48.8046972, lng: 18.0047294 },
    { lat: 48.8047762, lng: 18.0047284 },
    { lat: 48.804892, lng: 18.0047034 },
    { lat: 48.8049411, lng: 18.0046939 },
    { lat: 48.8050617, lng: 18.0046535 },
    { lat: 48.8050916, lng: 18.004643 },
    { lat: 48.8051083, lng: 18.0046376 },
    { lat: 48.8053197, lng: 18.0045685 },
    { lat: 48.805578, lng: 18.0044335 },
    { lat: 48.8061232, lng: 18.0042018 },
    { lat: 48.806236, lng: 18.0041471 },
    { lat: 48.8062712, lng: 18.0041298 },
    { lat: 48.8063547, lng: 18.0040902 },
    { lat: 48.8067694, lng: 18.0039838 },
    { lat: 48.8068026, lng: 18.0039736 },
    { lat: 48.8068658, lng: 18.003954 },
    { lat: 48.8068946, lng: 18.0039454 },
    { lat: 48.8070863, lng: 18.0038872 },
    { lat: 48.8070898, lng: 18.0038856 },
    { lat: 48.8071125, lng: 18.0038711 },
    { lat: 48.8071267, lng: 18.0038624 },
    { lat: 48.8071549, lng: 18.0038439 },
    { lat: 48.8071647, lng: 18.0038375 },
    { lat: 48.8073348, lng: 18.0038134 },
    { lat: 48.8073405, lng: 18.0038141 },
    { lat: 48.8074804, lng: 18.0037725 },
    { lat: 48.8075114, lng: 18.0037641 },
    { lat: 48.807659, lng: 18.0037211 },
    { lat: 48.8076439, lng: 18.0035821 },
    { lat: 48.8076192, lng: 18.0033928 },
    { lat: 48.8076153, lng: 18.0033654 },
    { lat: 48.8075872, lng: 18.0031755 },
    { lat: 48.8075695, lng: 18.0030479 },
    { lat: 48.8075668, lng: 18.0030225 },
    { lat: 48.8075603, lng: 18.0029485 },
    { lat: 48.8075585, lng: 18.0029173 },
    { lat: 48.8075558, lng: 18.0028557 },
    { lat: 48.8075512, lng: 18.0027933 },
    { lat: 48.8075494, lng: 18.0027642 },
    { lat: 48.8075154, lng: 18.0023244 },
    { lat: 48.8074973, lng: 18.0020379 },
    { lat: 48.8074543, lng: 18.0014243 },
    { lat: 48.8074378, lng: 18.0012052 },
    { lat: 48.807395, lng: 18.0006897 },
    { lat: 48.8073546, lng: 18.0001377 },
    { lat: 48.8073571, lng: 18.0001244 },
    { lat: 48.8073944, lng: 17.9999156 },
    { lat: 48.8074069, lng: 17.999842 },
    { lat: 48.8074104, lng: 17.9998258 },
    { lat: 48.8074444, lng: 17.9996364 },
    { lat: 48.8074732, lng: 17.9994166 },
    { lat: 48.8074784, lng: 17.9993886 },
    { lat: 48.8075855, lng: 17.9987951 },
    { lat: 48.8076009, lng: 17.9982563 },
    { lat: 48.8075919, lng: 17.9978933 },
    { lat: 48.8075719, lng: 17.9974592 },
    { lat: 48.8075947, lng: 17.9973132 },
    { lat: 48.8075973, lng: 17.9972971 },
    { lat: 48.8077004, lng: 17.9967532 },
    { lat: 48.8076069, lng: 17.9964877 },
    { lat: 48.8076803, lng: 17.9956509 },
    { lat: 48.8077195, lng: 17.9952062 },
    { lat: 48.8077395, lng: 17.9951434 },
    { lat: 48.8077731, lng: 17.9950446 },
    { lat: 48.8077892, lng: 17.994993 },
    { lat: 48.8078496, lng: 17.9948098 },
    { lat: 48.8079684, lng: 17.994452 },
    { lat: 48.808015, lng: 17.9943053 },
    { lat: 48.8080227, lng: 17.9942825 },
    { lat: 48.8080037, lng: 17.9942299 },
    { lat: 48.8079699, lng: 17.9941342 },
    { lat: 48.8079562, lng: 17.9940952 },
    { lat: 48.8079478, lng: 17.9940692 },
    { lat: 48.8079271, lng: 17.9940029 },
    { lat: 48.8079686, lng: 17.9936367 },
    { lat: 48.808018, lng: 17.9931362 },
    { lat: 48.8080716, lng: 17.9925921 },
    { lat: 48.8080851, lng: 17.9925176 },
    { lat: 48.8080572, lng: 17.9924852 },
    { lat: 48.8078697, lng: 17.9923868 },
    { lat: 48.8077409, lng: 17.9922718 },
    { lat: 48.8075784, lng: 17.9922115 },
    { lat: 48.8075001, lng: 17.9923034 },
    { lat: 48.8074621, lng: 17.9923079 },
    { lat: 48.8073517, lng: 17.9922931 },
    { lat: 48.8072586, lng: 17.9922632 },
    { lat: 48.8071891, lng: 17.9923478 },
    { lat: 48.8071873, lng: 17.9922607 },
    { lat: 48.8072051, lng: 17.9918748 },
    { lat: 48.8072128, lng: 17.9917015 },
    { lat: 48.8072191, lng: 17.9914238 },
    { lat: 48.8072194, lng: 17.9913944 },
    { lat: 48.8072196, lng: 17.9913769 },
    { lat: 48.8072199, lng: 17.9910947 },
    { lat: 48.8072106, lng: 17.9905381 },
    { lat: 48.806862, lng: 17.9896849 },
    { lat: 48.8067288, lng: 17.9893202 },
    { lat: 48.8057674, lng: 17.989001 },
    { lat: 48.8058012, lng: 17.9880873 },
    { lat: 48.8055805, lng: 17.9873532 },
    { lat: 48.8055043, lng: 17.987076 },
    { lat: 48.8051889, lng: 17.9858492 },
    { lat: 48.8047312, lng: 17.985573 },
    { lat: 48.8046886, lng: 17.9855208 },
    { lat: 48.8037355, lng: 17.9843134 },
    { lat: 48.8037269, lng: 17.9842641 },
    { lat: 48.8035756, lng: 17.9835592 },
    { lat: 48.803198, lng: 17.9828837 },
    { lat: 48.8029776, lng: 17.982039 },
    { lat: 48.8030043, lng: 17.9814994 },
    { lat: 48.8031015, lng: 17.981141 },
    { lat: 48.8031845, lng: 17.9808293 },
    { lat: 48.8033204, lng: 17.9802856 },
    { lat: 48.8033651, lng: 17.9797646 },
    { lat: 48.8033271, lng: 17.9793312 },
    { lat: 48.8031729, lng: 17.9789598 },
    { lat: 48.8031328, lng: 17.9787182 },
    { lat: 48.8030592, lng: 17.9783003 },
    { lat: 48.8030115, lng: 17.9780057 },
    { lat: 48.80301, lng: 17.977925 },
    { lat: 48.8030118, lng: 17.9778237 },
    { lat: 48.8030077, lng: 17.9777348 },
    { lat: 48.8030056, lng: 17.9776434 },
    { lat: 48.802997, lng: 17.9775547 },
    { lat: 48.802954, lng: 17.9773239 },
    { lat: 48.8029364, lng: 17.9771039 },
    { lat: 48.8029348, lng: 17.9769255 },
    { lat: 48.8028795, lng: 17.9767755 },
    { lat: 48.8028447, lng: 17.9766832 },
    { lat: 48.8028069, lng: 17.976596 },
    { lat: 48.8027268, lng: 17.9764056 },
    { lat: 48.8025806, lng: 17.9761294 },
    { lat: 48.8025268, lng: 17.97587 },
    { lat: 48.8024526, lng: 17.9756477 },
    { lat: 48.8024274, lng: 17.9754696 },
    { lat: 48.8024116, lng: 17.9750733 },
    { lat: 48.8023773, lng: 17.9749691 },
    { lat: 48.8024052, lng: 17.974869 },
    { lat: 48.8024085, lng: 17.9747139 },
    { lat: 48.8024205, lng: 17.9746328 },
    { lat: 48.8024066, lng: 17.9745695 },
    { lat: 48.8024486, lng: 17.9743447 },
    { lat: 48.8025305, lng: 17.9741634 },
    { lat: 48.80256, lng: 17.9739596 },
    { lat: 48.8025599, lng: 17.9738222 },
    { lat: 48.8025928, lng: 17.9736949 },
    { lat: 48.8025951, lng: 17.9736584 },
    { lat: 48.8026076, lng: 17.9735625 },
    { lat: 48.8026025, lng: 17.9734569 },
    { lat: 48.8026241, lng: 17.9732903 },
    { lat: 48.8026237, lng: 17.9731479 },
    { lat: 48.8026086, lng: 17.9730289 },
    { lat: 48.8025104, lng: 17.9725707 },
    { lat: 48.8024493, lng: 17.972438 },
    { lat: 48.8024286, lng: 17.9722768 },
    { lat: 48.8023655, lng: 17.9721468 },
    { lat: 48.802276, lng: 17.9719141 },
    { lat: 48.8021572, lng: 17.9717574 },
    { lat: 48.8020261, lng: 17.9715253 },
    { lat: 48.8019033, lng: 17.9711299 },
    { lat: 48.8018804, lng: 17.971012 },
    { lat: 48.8018991, lng: 17.9707925 },
    { lat: 48.8018561, lng: 17.9704117 },
    { lat: 48.8018119, lng: 17.9701807 },
    { lat: 48.8017651, lng: 17.9697162 },
    { lat: 48.8016561, lng: 17.9691006 },
    { lat: 48.8014644, lng: 17.9680744 },
    { lat: 48.8011968, lng: 17.9666329 },
    { lat: 48.8009285, lng: 17.9651958 },
    { lat: 48.8007915, lng: 17.9646749 },
    { lat: 48.8006312, lng: 17.9640866 },
    { lat: 48.8004075, lng: 17.9632847 },
    { lat: 48.8001309, lng: 17.9623171 },
    { lat: 48.8001097, lng: 17.962282 },
    { lat: 48.7999519, lng: 17.9624961 },
    { lat: 48.7998599, lng: 17.9626186 },
    { lat: 48.7997266, lng: 17.9629035 },
    { lat: 48.7996123, lng: 17.9631601 },
    { lat: 48.7995204, lng: 17.9633605 },
    { lat: 48.7993807, lng: 17.9635568 },
    { lat: 48.7992258, lng: 17.9637813 },
    { lat: 48.7992231, lng: 17.9637902 },
    { lat: 48.7991919, lng: 17.9639105 },
    { lat: 48.7991531, lng: 17.9640372 },
    { lat: 48.7990794, lng: 17.9642982 },
    { lat: 48.7989568, lng: 17.9647051 },
    { lat: 48.7988609, lng: 17.9650268 },
    { lat: 48.7988113, lng: 17.9652938 },
    { lat: 48.7987997, lng: 17.9653452 },
    { lat: 48.798797, lng: 17.9654311 },
    { lat: 48.7987949, lng: 17.9655864 },
    { lat: 48.7987906, lng: 17.9659121 },
    { lat: 48.79871, lng: 17.9661719 },
    { lat: 48.7986512, lng: 17.9663597 },
    { lat: 48.7985501, lng: 17.9664812 },
    { lat: 48.7984324, lng: 17.9666097 },
    { lat: 48.7982825, lng: 17.9666118 },
    { lat: 48.7980499, lng: 17.9666134 },
    { lat: 48.7978344, lng: 17.9666134 },
    { lat: 48.7975874, lng: 17.9666156 },
    { lat: 48.7973753, lng: 17.9666162 },
    { lat: 48.7970885, lng: 17.9666148 },
    { lat: 48.7969552, lng: 17.9666158 },
    { lat: 48.7967294, lng: 17.9666136 },
    { lat: 48.79649, lng: 17.9666122 },
    { lat: 48.7962665, lng: 17.9666133 },
    { lat: 48.7959188, lng: 17.9666084 },
    { lat: 48.7958191, lng: 17.9665862 },
    { lat: 48.795551, lng: 17.966502 },
    { lat: 48.795394, lng: 17.966523 },
    { lat: 48.795101, lng: 17.96656 },
    { lat: 48.794965, lng: 17.966666 },
    { lat: 48.794623, lng: 17.966947 },
    { lat: 48.794448, lng: 17.967087 },
    { lat: 48.794258, lng: 17.967229 },
    { lat: 48.793999, lng: 17.967422 },
    { lat: 48.793935, lng: 17.967471 },
    { lat: 48.793883, lng: 17.967491 },
    { lat: 48.793804, lng: 17.967517 },
    { lat: 48.793656, lng: 17.967568 },
    { lat: 48.79348, lng: 17.96763 },
    { lat: 48.793208, lng: 17.96772 },
    { lat: 48.792946, lng: 17.967795 },
    { lat: 48.79278, lng: 17.967842 },
    { lat: 48.792601, lng: 17.967906 },
    { lat: 48.792383, lng: 17.967981 },
    { lat: 48.792256, lng: 17.968028 },
    { lat: 48.79208, lng: 17.968088 },
    { lat: 48.791939, lng: 17.968139 },
    { lat: 48.791708, lng: 17.96822 },
    { lat: 48.791486, lng: 17.968296 },
    { lat: 48.791433, lng: 17.968312 },
    { lat: 48.791338, lng: 17.96836 },
    { lat: 48.79102, lng: 17.968511 },
    { lat: 48.790826, lng: 17.968602 },
    { lat: 48.790718, lng: 17.968654 },
    { lat: 48.790514, lng: 17.968749 },
    { lat: 48.790289, lng: 17.968855 },
    { lat: 48.790082, lng: 17.968958 },
    { lat: 48.789762, lng: 17.968997 },
    { lat: 48.789545, lng: 17.969028 },
    { lat: 48.789296, lng: 17.969022 },
    { lat: 48.78905, lng: 17.969019 },
    { lat: 48.788809, lng: 17.969015 },
    { lat: 48.78858, lng: 17.969012 },
    { lat: 48.788361, lng: 17.969009 },
    { lat: 48.788261, lng: 17.968994 },
    { lat: 48.787999, lng: 17.968951 },
    { lat: 48.787644, lng: 17.968894 },
    { lat: 48.787382, lng: 17.968681 },
    { lat: 48.787215, lng: 17.968547 },
    { lat: 48.786924, lng: 17.9683 },
    { lat: 48.786812, lng: 17.968208 },
    { lat: 48.786614, lng: 17.968055 },
    { lat: 48.786365, lng: 17.967867 },
    { lat: 48.786231, lng: 17.967767 },
    { lat: 48.785972, lng: 17.967574 },
    { lat: 48.785755, lng: 17.967408 },
    { lat: 48.785408, lng: 17.96728 },
    { lat: 48.785174, lng: 17.967194 },
    { lat: 48.784899, lng: 17.967296 },
    { lat: 48.784704, lng: 17.967369 },
    { lat: 48.784485, lng: 17.967411 },
    { lat: 48.784436, lng: 17.967385 },
    { lat: 48.784305, lng: 17.967358 },
    { lat: 48.784107, lng: 17.967368 },
    { lat: 48.783843, lng: 17.967531 },
    { lat: 48.783684, lng: 17.967583 },
    { lat: 48.783537, lng: 17.96763 },
    { lat: 48.78328, lng: 17.967624 },
    { lat: 48.783141, lng: 17.967622 },
    { lat: 48.783068, lng: 17.967592 },
    { lat: 48.782928, lng: 17.967611 },
    { lat: 48.782803, lng: 17.96763 },
    { lat: 48.782529, lng: 17.967533 },
    { lat: 48.782279, lng: 17.967452 },
    { lat: 48.781969, lng: 17.967319 },
    { lat: 48.781729, lng: 17.967214 },
    { lat: 48.781594, lng: 17.96716 },
    { lat: 48.781233, lng: 17.967069 },
    { lat: 48.781038, lng: 17.966998 },
    { lat: 48.780851, lng: 17.966927 },
    { lat: 48.780723, lng: 17.966881 },
    { lat: 48.780579, lng: 17.966808 },
    { lat: 48.780524, lng: 17.966781 },
    { lat: 48.78035, lng: 17.96673 },
    { lat: 48.780139, lng: 17.966672 },
    { lat: 48.779937, lng: 17.966617 },
    { lat: 48.77963, lng: 17.966513 },
    { lat: 48.779333, lng: 17.966413 },
    { lat: 48.779234, lng: 17.966376 },
    { lat: 48.778989, lng: 17.966286 },
    { lat: 48.778557, lng: 17.966129 },
    { lat: 48.778404, lng: 17.966073 },
    { lat: 48.777993, lng: 17.965927 },
    { lat: 48.777885, lng: 17.965882 },
    { lat: 48.77764, lng: 17.965757 },
    { lat: 48.777545, lng: 17.96571 },
    { lat: 48.77743, lng: 17.965734 },
    { lat: 48.777288, lng: 17.965751 },
    { lat: 48.777137, lng: 17.965777 },
    { lat: 48.776992, lng: 17.965869 },
    { lat: 48.776784, lng: 17.966003 },
    { lat: 48.776548, lng: 17.96615 },
    { lat: 48.77629, lng: 17.966316 },
    { lat: 48.7761549, lng: 17.9663951 },
  ],
  TrenčianskeStankovce: [
    { lat: 48.8398818, lng: 17.9523218 },
    { lat: 48.8398802, lng: 17.9522389 },
    { lat: 48.8398665, lng: 17.9518735 },
    { lat: 48.8398253, lng: 17.9508206 },
    { lat: 48.8397938, lng: 17.9499334 },
    { lat: 48.8397803, lng: 17.9487027 },
    { lat: 48.8397674, lng: 17.9474811 },
    { lat: 48.8396835, lng: 17.9474754 },
    { lat: 48.8396824, lng: 17.9474755 },
    { lat: 48.8396247, lng: 17.947472 },
    { lat: 48.8395544, lng: 17.9474672 },
    { lat: 48.8394796, lng: 17.9474628 },
    { lat: 48.8393573, lng: 17.9474551 },
    { lat: 48.839039, lng: 17.9474349 },
    { lat: 48.8388335, lng: 17.9474168 },
    { lat: 48.8385815, lng: 17.9473943 },
    { lat: 48.8384987, lng: 17.9473869 },
    { lat: 48.8383263, lng: 17.9473715 },
    { lat: 48.8379711, lng: 17.9473398 },
    { lat: 48.8374083, lng: 17.9472913 },
    { lat: 48.8372226, lng: 17.9472823 },
    { lat: 48.8371967, lng: 17.9472809 },
    { lat: 48.836768, lng: 17.947227 },
    { lat: 48.8361901, lng: 17.9471541 },
    { lat: 48.8361289, lng: 17.9471467 },
    { lat: 48.8359418, lng: 17.9471343 },
    { lat: 48.8353592, lng: 17.947094 },
    { lat: 48.8351337, lng: 17.9470771 },
    { lat: 48.8345772, lng: 17.9470382 },
    { lat: 48.8343733, lng: 17.9470256 },
    { lat: 48.8342256, lng: 17.9470129 },
    { lat: 48.832414, lng: 17.9453847 },
    { lat: 48.8308447, lng: 17.9445077 },
    { lat: 48.8306304, lng: 17.944905 },
    { lat: 48.8302612, lng: 17.9456028 },
    { lat: 48.8298434, lng: 17.9463793 },
    { lat: 48.8297481, lng: 17.9465575 },
    { lat: 48.8297053, lng: 17.9466368 },
    { lat: 48.8296902, lng: 17.9466654 },
    { lat: 48.8296554, lng: 17.9467296 },
    { lat: 48.8295792, lng: 17.9468704 },
    { lat: 48.8294512, lng: 17.947109 },
    { lat: 48.8284761, lng: 17.9489275 },
    { lat: 48.8284459, lng: 17.9489893 },
    { lat: 48.8284165, lng: 17.949047 },
    { lat: 48.8283648, lng: 17.949144 },
    { lat: 48.8282927, lng: 17.9494505 },
    { lat: 48.8282593, lng: 17.9497585 },
    { lat: 48.8282103, lng: 17.9501879 },
    { lat: 48.8280211, lng: 17.9513511 },
    { lat: 48.8279216, lng: 17.9518605 },
    { lat: 48.8279096, lng: 17.9519256 },
    { lat: 48.8278212, lng: 17.9523743 },
    { lat: 48.8277425, lng: 17.9531553 },
    { lat: 48.8277322, lng: 17.9538549 },
    { lat: 48.8277208, lng: 17.9539309 },
    { lat: 48.8277068, lng: 17.9540328 },
    { lat: 48.8275607, lng: 17.9550508 },
    { lat: 48.8275127, lng: 17.9559895 },
    { lat: 48.8274385, lng: 17.9564108 },
    { lat: 48.827423, lng: 17.9564534 },
    { lat: 48.8273014, lng: 17.9567992 },
    { lat: 48.8272952, lng: 17.9568288 },
    { lat: 48.8272754, lng: 17.9567989 },
    { lat: 48.827203, lng: 17.9567582 },
    { lat: 48.8271834, lng: 17.9567509 },
    { lat: 48.8271208, lng: 17.9567254 },
    { lat: 48.8268686, lng: 17.9566954 },
    { lat: 48.8268376, lng: 17.9567268 },
    { lat: 48.8267995, lng: 17.9567316 },
    { lat: 48.8266626, lng: 17.9567074 },
    { lat: 48.8257602, lng: 17.9567894 },
    { lat: 48.8256044, lng: 17.9567719 },
    { lat: 48.8254673, lng: 17.956801 },
    { lat: 48.8252848, lng: 17.9569972 },
    { lat: 48.8251038, lng: 17.9569524 },
    { lat: 48.824645, lng: 17.9571111 },
    { lat: 48.8245365, lng: 17.95745 },
    { lat: 48.8243767, lng: 17.9574804 },
    { lat: 48.8239627, lng: 17.9580356 },
    { lat: 48.8238221, lng: 17.9579459 },
    { lat: 48.8236297, lng: 17.958051 },
    { lat: 48.8232994, lng: 17.9579818 },
    { lat: 48.8230749, lng: 17.9577391 },
    { lat: 48.8226046, lng: 17.9576135 },
    { lat: 48.8222916, lng: 17.9571866 },
    { lat: 48.8220291, lng: 17.9569287 },
    { lat: 48.8218556, lng: 17.9567486 },
    { lat: 48.8217095, lng: 17.9567223 },
    { lat: 48.8213197, lng: 17.9565621 },
    { lat: 48.8209948, lng: 17.956639 },
    { lat: 48.8206329, lng: 17.957009 },
    { lat: 48.8205375, lng: 17.9572265 },
    { lat: 48.8204022, lng: 17.9577221 },
    { lat: 48.8201475, lng: 17.9582959 },
    { lat: 48.8200092, lng: 17.95838 },
    { lat: 48.819915, lng: 17.9584308 },
    { lat: 48.8196692, lng: 17.958565 },
    { lat: 48.8196294, lng: 17.9586388 },
    { lat: 48.819461, lng: 17.9589577 },
    { lat: 48.8193332, lng: 17.9589715 },
    { lat: 48.8191491, lng: 17.9591772 },
    { lat: 48.8187949, lng: 17.9591424 },
    { lat: 48.8185477, lng: 17.9591819 },
    { lat: 48.8183544, lng: 17.9590414 },
    { lat: 48.8183272, lng: 17.9590214 },
    { lat: 48.8181102, lng: 17.9588648 },
    { lat: 48.8177149, lng: 17.9585785 },
    { lat: 48.8172495, lng: 17.9583999 },
    { lat: 48.8169819, lng: 17.9583791 },
    { lat: 48.8166885, lng: 17.9583601 },
    { lat: 48.8160493, lng: 17.9580333 },
    { lat: 48.816004, lng: 17.9579918 },
    { lat: 48.8158203, lng: 17.9578095 },
    { lat: 48.8156444, lng: 17.9575248 },
    { lat: 48.8150907, lng: 17.9569281 },
    { lat: 48.8144363, lng: 17.9561938 },
    { lat: 48.8138218, lng: 17.9556341 },
    { lat: 48.8131253, lng: 17.9553886 },
    { lat: 48.8129831, lng: 17.9553124 },
    { lat: 48.8129235, lng: 17.9551816 },
    { lat: 48.8126612, lng: 17.9553646 },
    { lat: 48.8123416, lng: 17.9555145 },
    { lat: 48.812074, lng: 17.9554569 },
    { lat: 48.8116524, lng: 17.9556179 },
    { lat: 48.8109715, lng: 17.9556497 },
    { lat: 48.8108196, lng: 17.9556937 },
    { lat: 48.8107123, lng: 17.9557309 },
    { lat: 48.8105722, lng: 17.9557493 },
    { lat: 48.809951, lng: 17.9558344 },
    { lat: 48.8091912, lng: 17.9560986 },
    { lat: 48.8089762, lng: 17.9562418 },
    { lat: 48.8086918, lng: 17.9563164 },
    { lat: 48.8084358, lng: 17.9566239 },
    { lat: 48.8082749, lng: 17.9566351 },
    { lat: 48.808057, lng: 17.9569366 },
    { lat: 48.8079307, lng: 17.9570312 },
    { lat: 48.8076744, lng: 17.9572191 },
    { lat: 48.807076, lng: 17.9574827 },
    { lat: 48.8067059, lng: 17.957487 },
    { lat: 48.806458, lng: 17.9577962 },
    { lat: 48.8063649, lng: 17.9580875 },
    { lat: 48.8061665, lng: 17.9583383 },
    { lat: 48.8058378, lng: 17.958622 },
    { lat: 48.8057212, lng: 17.9586336 },
    { lat: 48.8054114, lng: 17.9588342 },
    { lat: 48.8052325, lng: 17.9591654 },
    { lat: 48.8047584, lng: 17.9595252 },
    { lat: 48.8045247, lng: 17.9597912 },
    { lat: 48.8042538, lng: 17.9604336 },
    { lat: 48.8040406, lng: 17.9607384 },
    { lat: 48.8037567, lng: 17.9609003 },
    { lat: 48.8031634, lng: 17.9612283 },
    { lat: 48.8026485, lng: 17.9616426 },
    { lat: 48.8022753, lng: 17.9619724 },
    { lat: 48.8021158, lng: 17.9621578 },
    { lat: 48.8019649, lng: 17.9623327 },
    { lat: 48.8015927, lng: 17.9622062 },
    { lat: 48.8010909, lng: 17.9624386 },
    { lat: 48.8010204, lng: 17.9624711 },
    { lat: 48.8007749, lng: 17.9624584 },
    { lat: 48.800536, lng: 17.9624459 },
    { lat: 48.8001472, lng: 17.9623238 },
    { lat: 48.8001309, lng: 17.9623171 },
    { lat: 48.8004075, lng: 17.9632847 },
    { lat: 48.8006312, lng: 17.9640866 },
    { lat: 48.8007915, lng: 17.9646749 },
    { lat: 48.8009285, lng: 17.9651958 },
    { lat: 48.8011968, lng: 17.9666329 },
    { lat: 48.8014644, lng: 17.9680744 },
    { lat: 48.8016561, lng: 17.9691006 },
    { lat: 48.8017651, lng: 17.9697162 },
    { lat: 48.8018119, lng: 17.9701807 },
    { lat: 48.8018561, lng: 17.9704117 },
    { lat: 48.8018991, lng: 17.9707925 },
    { lat: 48.8018804, lng: 17.971012 },
    { lat: 48.8019033, lng: 17.9711299 },
    { lat: 48.8020261, lng: 17.9715253 },
    { lat: 48.8021572, lng: 17.9717574 },
    { lat: 48.802276, lng: 17.9719141 },
    { lat: 48.8023655, lng: 17.9721468 },
    { lat: 48.8024286, lng: 17.9722768 },
    { lat: 48.8024493, lng: 17.972438 },
    { lat: 48.8025104, lng: 17.9725707 },
    { lat: 48.8026086, lng: 17.9730289 },
    { lat: 48.8026237, lng: 17.9731479 },
    { lat: 48.8026241, lng: 17.9732903 },
    { lat: 48.8026025, lng: 17.9734569 },
    { lat: 48.8026076, lng: 17.9735625 },
    { lat: 48.8025951, lng: 17.9736584 },
    { lat: 48.8025928, lng: 17.9736949 },
    { lat: 48.8025599, lng: 17.9738222 },
    { lat: 48.80256, lng: 17.9739596 },
    { lat: 48.8025305, lng: 17.9741634 },
    { lat: 48.8024486, lng: 17.9743447 },
    { lat: 48.8024066, lng: 17.9745695 },
    { lat: 48.8024205, lng: 17.9746328 },
    { lat: 48.8024085, lng: 17.9747139 },
    { lat: 48.8024052, lng: 17.974869 },
    { lat: 48.8023773, lng: 17.9749691 },
    { lat: 48.8024116, lng: 17.9750733 },
    { lat: 48.8024274, lng: 17.9754696 },
    { lat: 48.8024526, lng: 17.9756477 },
    { lat: 48.8025268, lng: 17.97587 },
    { lat: 48.8025806, lng: 17.9761294 },
    { lat: 48.8027268, lng: 17.9764056 },
    { lat: 48.8028069, lng: 17.976596 },
    { lat: 48.8028447, lng: 17.9766832 },
    { lat: 48.8028795, lng: 17.9767755 },
    { lat: 48.8029348, lng: 17.9769255 },
    { lat: 48.8029364, lng: 17.9771039 },
    { lat: 48.802954, lng: 17.9773239 },
    { lat: 48.802997, lng: 17.9775547 },
    { lat: 48.8030056, lng: 17.9776434 },
    { lat: 48.8030077, lng: 17.9777348 },
    { lat: 48.8030118, lng: 17.9778237 },
    { lat: 48.80301, lng: 17.977925 },
    { lat: 48.8030115, lng: 17.9780057 },
    { lat: 48.8030592, lng: 17.9783003 },
    { lat: 48.8031328, lng: 17.9787182 },
    { lat: 48.8031729, lng: 17.9789598 },
    { lat: 48.8033271, lng: 17.9793312 },
    { lat: 48.8033651, lng: 17.9797646 },
    { lat: 48.8033204, lng: 17.9802856 },
    { lat: 48.8031845, lng: 17.9808293 },
    { lat: 48.8031015, lng: 17.981141 },
    { lat: 48.8030043, lng: 17.9814994 },
    { lat: 48.8029776, lng: 17.982039 },
    { lat: 48.803198, lng: 17.9828837 },
    { lat: 48.8035756, lng: 17.9835592 },
    { lat: 48.8037269, lng: 17.9842641 },
    { lat: 48.8037355, lng: 17.9843134 },
    { lat: 48.8046886, lng: 17.9855208 },
    { lat: 48.8047312, lng: 17.985573 },
    { lat: 48.8051889, lng: 17.9858492 },
    { lat: 48.8055043, lng: 17.987076 },
    { lat: 48.8055805, lng: 17.9873532 },
    { lat: 48.8058012, lng: 17.9880873 },
    { lat: 48.8057674, lng: 17.989001 },
    { lat: 48.8067288, lng: 17.9893202 },
    { lat: 48.806862, lng: 17.9896849 },
    { lat: 48.8072106, lng: 17.9905381 },
    { lat: 48.8072199, lng: 17.9910947 },
    { lat: 48.8072196, lng: 17.9913769 },
    { lat: 48.8072194, lng: 17.9913944 },
    { lat: 48.8072191, lng: 17.9914238 },
    { lat: 48.8072128, lng: 17.9917015 },
    { lat: 48.8072051, lng: 17.9918748 },
    { lat: 48.8071873, lng: 17.9922607 },
    { lat: 48.8071891, lng: 17.9923478 },
    { lat: 48.8072586, lng: 17.9922632 },
    { lat: 48.8073517, lng: 17.9922931 },
    { lat: 48.8074621, lng: 17.9923079 },
    { lat: 48.8075001, lng: 17.9923034 },
    { lat: 48.8075784, lng: 17.9922115 },
    { lat: 48.8077409, lng: 17.9922718 },
    { lat: 48.8078697, lng: 17.9923868 },
    { lat: 48.8080572, lng: 17.9924852 },
    { lat: 48.8080851, lng: 17.9925176 },
    { lat: 48.8080716, lng: 17.9925921 },
    { lat: 48.808018, lng: 17.9931362 },
    { lat: 48.8079686, lng: 17.9936367 },
    { lat: 48.8079271, lng: 17.9940029 },
    { lat: 48.8079478, lng: 17.9940692 },
    { lat: 48.8079562, lng: 17.9940952 },
    { lat: 48.8079699, lng: 17.9941342 },
    { lat: 48.8080037, lng: 17.9942299 },
    { lat: 48.8080227, lng: 17.9942825 },
    { lat: 48.808015, lng: 17.9943053 },
    { lat: 48.8079684, lng: 17.994452 },
    { lat: 48.8078496, lng: 17.9948098 },
    { lat: 48.8077892, lng: 17.994993 },
    { lat: 48.8077731, lng: 17.9950446 },
    { lat: 48.8077395, lng: 17.9951434 },
    { lat: 48.8077195, lng: 17.9952062 },
    { lat: 48.8076803, lng: 17.9956509 },
    { lat: 48.8076069, lng: 17.9964877 },
    { lat: 48.8077004, lng: 17.9967532 },
    { lat: 48.8075973, lng: 17.9972971 },
    { lat: 48.8075947, lng: 17.9973132 },
    { lat: 48.8075719, lng: 17.9974592 },
    { lat: 48.8075919, lng: 17.9978933 },
    { lat: 48.8076009, lng: 17.9982563 },
    { lat: 48.8075855, lng: 17.9987951 },
    { lat: 48.8074784, lng: 17.9993886 },
    { lat: 48.8074732, lng: 17.9994166 },
    { lat: 48.8074444, lng: 17.9996364 },
    { lat: 48.8074104, lng: 17.9998258 },
    { lat: 48.8074069, lng: 17.999842 },
    { lat: 48.8073944, lng: 17.9999156 },
    { lat: 48.8073571, lng: 18.0001244 },
    { lat: 48.8073546, lng: 18.0001377 },
    { lat: 48.807395, lng: 18.0006897 },
    { lat: 48.8074378, lng: 18.0012052 },
    { lat: 48.8074543, lng: 18.0014243 },
    { lat: 48.8074973, lng: 18.0020379 },
    { lat: 48.8075154, lng: 18.0023244 },
    { lat: 48.8075494, lng: 18.0027642 },
    { lat: 48.8075512, lng: 18.0027933 },
    { lat: 48.8075558, lng: 18.0028557 },
    { lat: 48.8075585, lng: 18.0029173 },
    { lat: 48.8075603, lng: 18.0029485 },
    { lat: 48.8075668, lng: 18.0030225 },
    { lat: 48.8075695, lng: 18.0030479 },
    { lat: 48.8075872, lng: 18.0031755 },
    { lat: 48.8076153, lng: 18.0033654 },
    { lat: 48.8076192, lng: 18.0033928 },
    { lat: 48.8076439, lng: 18.0035821 },
    { lat: 48.807659, lng: 18.0037211 },
    { lat: 48.8075114, lng: 18.0037641 },
    { lat: 48.8074804, lng: 18.0037725 },
    { lat: 48.8073405, lng: 18.0038141 },
    { lat: 48.8073348, lng: 18.0038134 },
    { lat: 48.8071647, lng: 18.0038375 },
    { lat: 48.8071549, lng: 18.0038439 },
    { lat: 48.8071267, lng: 18.0038624 },
    { lat: 48.8071125, lng: 18.0038711 },
    { lat: 48.8070898, lng: 18.0038856 },
    { lat: 48.8070863, lng: 18.0038872 },
    { lat: 48.8068946, lng: 18.0039454 },
    { lat: 48.8068658, lng: 18.003954 },
    { lat: 48.8068026, lng: 18.0039736 },
    { lat: 48.8067694, lng: 18.0039838 },
    { lat: 48.8063547, lng: 18.0040902 },
    { lat: 48.8062712, lng: 18.0041298 },
    { lat: 48.806236, lng: 18.0041471 },
    { lat: 48.8061232, lng: 18.0042018 },
    { lat: 48.805578, lng: 18.0044335 },
    { lat: 48.8053197, lng: 18.0045685 },
    { lat: 48.8051083, lng: 18.0046376 },
    { lat: 48.8050916, lng: 18.004643 },
    { lat: 48.8050617, lng: 18.0046535 },
    { lat: 48.8049411, lng: 18.0046939 },
    { lat: 48.804892, lng: 18.0047034 },
    { lat: 48.8047762, lng: 18.0047284 },
    { lat: 48.8046972, lng: 18.0047294 },
    { lat: 48.804609, lng: 18.004746 },
    { lat: 48.8045711, lng: 18.0047526 },
    { lat: 48.8043779, lng: 18.0047895 },
    { lat: 48.8043276, lng: 18.0047988 },
    { lat: 48.8042986, lng: 18.0048042 },
    { lat: 48.8042184, lng: 18.0048194 },
    { lat: 48.8036927, lng: 18.0050918 },
    { lat: 48.8033755, lng: 18.0052429 },
    { lat: 48.802759, lng: 18.0052402 },
    { lat: 48.8026477, lng: 18.0054941 },
    { lat: 48.8025173, lng: 18.0055618 },
    { lat: 48.8022607, lng: 18.0055529 },
    { lat: 48.8019626, lng: 18.0055908 },
    { lat: 48.8017578, lng: 18.0055638 },
    { lat: 48.8016644, lng: 18.00572 },
    { lat: 48.8014924, lng: 18.0058278 },
    { lat: 48.8014307, lng: 18.0058512 },
    { lat: 48.8014041, lng: 18.0058613 },
    { lat: 48.8013941, lng: 18.0058651 },
    { lat: 48.8012496, lng: 18.0059201 },
    { lat: 48.8012153, lng: 18.0059334 },
    { lat: 48.8011017, lng: 18.005976 },
    { lat: 48.8010925, lng: 18.0059922 },
    { lat: 48.8010617, lng: 18.0060465 },
    { lat: 48.8008174, lng: 18.0064756 },
    { lat: 48.8007668, lng: 18.0064789 },
    { lat: 48.8005993, lng: 18.0064905 },
    { lat: 48.8004135, lng: 18.0064609 },
    { lat: 48.8002741, lng: 18.0065829 },
    { lat: 48.80012, lng: 18.0066478 },
    { lat: 48.8000349, lng: 18.0066045 },
    { lat: 48.7998845, lng: 18.0063689 },
    { lat: 48.7998195, lng: 18.006358 },
    { lat: 48.7994494, lng: 18.0063978 },
    { lat: 48.7993339, lng: 18.0064693 },
    { lat: 48.7990799, lng: 18.0067135 },
    { lat: 48.7987691, lng: 18.0069367 },
    { lat: 48.7985485, lng: 18.0072147 },
    { lat: 48.7983338, lng: 18.0076114 },
    { lat: 48.7981639, lng: 18.0079448 },
    { lat: 48.7977283, lng: 18.0082512 },
    { lat: 48.7976879, lng: 18.00814 },
    { lat: 48.7974548, lng: 18.0083215 },
    { lat: 48.7970686, lng: 18.0085858 },
    { lat: 48.7969753, lng: 18.0087434 },
    { lat: 48.7967484, lng: 18.0088027 },
    { lat: 48.7960223, lng: 18.0089651 },
    { lat: 48.7959457, lng: 18.00939 },
    { lat: 48.7958994, lng: 18.0096762 },
    { lat: 48.7956013, lng: 18.0098666 },
    { lat: 48.7954031, lng: 18.0103699 },
    { lat: 48.7950034, lng: 18.0107111 },
    { lat: 48.7947254, lng: 18.011677 },
    { lat: 48.7940917, lng: 18.0126043 },
    { lat: 48.7938249, lng: 18.0134211 },
    { lat: 48.7936673, lng: 18.0143649 },
    { lat: 48.7932686, lng: 18.0149912 },
    { lat: 48.7930396, lng: 18.0159271 },
    { lat: 48.7929984, lng: 18.0163751 },
    { lat: 48.7929093, lng: 18.0170283 },
    { lat: 48.7928772, lng: 18.017174 },
    { lat: 48.7927285, lng: 18.0177529 },
    { lat: 48.7926616, lng: 18.0183372 },
    { lat: 48.7925436, lng: 18.0192273 },
    { lat: 48.79255, lng: 18.0192393 },
    { lat: 48.7920884, lng: 18.0198705 },
    { lat: 48.7917171, lng: 18.0200223 },
    { lat: 48.7913369, lng: 18.0208862 },
    { lat: 48.7907563, lng: 18.0215875 },
    { lat: 48.7911269, lng: 18.0227025 },
    { lat: 48.7913378, lng: 18.0233903 },
    { lat: 48.7915621, lng: 18.0240741 },
    { lat: 48.7918489, lng: 18.0249757 },
    { lat: 48.791621, lng: 18.0251303 },
    { lat: 48.7914458, lng: 18.0252381 },
    { lat: 48.7912366, lng: 18.0253848 },
    { lat: 48.7906586, lng: 18.0257492 },
    { lat: 48.7900804, lng: 18.0260883 },
    { lat: 48.7894385, lng: 18.0265128 },
    { lat: 48.7889958, lng: 18.0267773 },
    { lat: 48.7882464, lng: 18.0272923 },
    { lat: 48.7880219, lng: 18.0274482 },
    { lat: 48.7879816, lng: 18.027435 },
    { lat: 48.7877024, lng: 18.0274291 },
    { lat: 48.7874233, lng: 18.0274034 },
    { lat: 48.7869353, lng: 18.0277946 },
    { lat: 48.7863944, lng: 18.0285376 },
    { lat: 48.7858369, lng: 18.0290384 },
    { lat: 48.7851727, lng: 18.0301399 },
    { lat: 48.784414, lng: 18.0304802 },
    { lat: 48.7837627, lng: 18.0313211 },
    { lat: 48.7831304, lng: 18.0321773 },
    { lat: 48.7826602, lng: 18.032682 },
    { lat: 48.7825908, lng: 18.0327336 },
    { lat: 48.781918, lng: 18.0330956 },
    { lat: 48.7816157, lng: 18.0333629 },
    { lat: 48.7812471, lng: 18.0336577 },
    { lat: 48.7808661, lng: 18.0338795 },
    { lat: 48.780638, lng: 18.0340097 },
    { lat: 48.7800623, lng: 18.034257 },
    { lat: 48.7795354, lng: 18.0343953 },
    { lat: 48.7793663, lng: 18.0344546 },
    { lat: 48.7788843, lng: 18.0346477 },
    { lat: 48.7784839, lng: 18.0347071 },
    { lat: 48.7781168, lng: 18.0349112 },
    { lat: 48.7780168, lng: 18.0349604 },
    { lat: 48.7775454, lng: 18.0355572 },
    { lat: 48.7770989, lng: 18.0373088 },
    { lat: 48.7771033, lng: 18.0377688 },
    { lat: 48.7771245, lng: 18.0391672 },
    { lat: 48.7767694, lng: 18.040017 },
    { lat: 48.7761451, lng: 18.040704 },
    { lat: 48.7764927, lng: 18.0405312 },
    { lat: 48.7770257, lng: 18.040478 },
    { lat: 48.7775856, lng: 18.0412464 },
    { lat: 48.7779234, lng: 18.0417709 },
    { lat: 48.7782617, lng: 18.0420145 },
    { lat: 48.7785601, lng: 18.0423873 },
    { lat: 48.7787855, lng: 18.0428411 },
    { lat: 48.7793316, lng: 18.0440118 },
    { lat: 48.7793954, lng: 18.0440488 },
    { lat: 48.7798154, lng: 18.0442927 },
    { lat: 48.7799478, lng: 18.0443361 },
    { lat: 48.7803519, lng: 18.0444348 },
    { lat: 48.7804829, lng: 18.0444741 },
    { lat: 48.780814, lng: 18.0446524 },
    { lat: 48.7809339, lng: 18.0447164 },
    { lat: 48.7810781, lng: 18.0449594 },
    { lat: 48.781272, lng: 18.0452811 },
    { lat: 48.7818036, lng: 18.0454572 },
    { lat: 48.7818543, lng: 18.0454747 },
    { lat: 48.7818924, lng: 18.0454878 },
    { lat: 48.7821123, lng: 18.0455638 },
    { lat: 48.7824548, lng: 18.0456844 },
    { lat: 48.7827481, lng: 18.0452226 },
    { lat: 48.7828845, lng: 18.0449892 },
    { lat: 48.7835549, lng: 18.0443958 },
    { lat: 48.7840345, lng: 18.043957 },
    { lat: 48.7842665, lng: 18.0438093 },
    { lat: 48.7847066, lng: 18.0435227 },
    { lat: 48.7848143, lng: 18.0434528 },
    { lat: 48.7849584, lng: 18.0433696 },
    { lat: 48.785144, lng: 18.0432076 },
    { lat: 48.7852209, lng: 18.0431307 },
    { lat: 48.7852948, lng: 18.0430645 },
    { lat: 48.7855489, lng: 18.0428177 },
    { lat: 48.7857843, lng: 18.0425616 },
    { lat: 48.7861895, lng: 18.0420797 },
    { lat: 48.7862685, lng: 18.0419835 },
    { lat: 48.7864488, lng: 18.0417323 },
    { lat: 48.7864696, lng: 18.0416644 },
    { lat: 48.7866607, lng: 18.0413616 },
    { lat: 48.7867547, lng: 18.0412111 },
    { lat: 48.7868095, lng: 18.0409933 },
    { lat: 48.7869198, lng: 18.0408539 },
    { lat: 48.7869995, lng: 18.0408467 },
    { lat: 48.7871875, lng: 18.0406643 },
    { lat: 48.7873905, lng: 18.0405095 },
    { lat: 48.7876289, lng: 18.0401517 },
    { lat: 48.7877397, lng: 18.0399811 },
    { lat: 48.7878213, lng: 18.0398323 },
    { lat: 48.7878474, lng: 18.0397776 },
    { lat: 48.7879334, lng: 18.0396475 },
    { lat: 48.7880451, lng: 18.0395523 },
    { lat: 48.7881205, lng: 18.0394706 },
    { lat: 48.7882208, lng: 18.0393908 },
    { lat: 48.7883558, lng: 18.0392706 },
    { lat: 48.7883792, lng: 18.0393025 },
    { lat: 48.7886794, lng: 18.0392829 },
    { lat: 48.7888501, lng: 18.039286 },
    { lat: 48.7889766, lng: 18.0394626 },
    { lat: 48.7890946, lng: 18.0394341 },
    { lat: 48.7893621, lng: 18.0390892 },
    { lat: 48.7894875, lng: 18.0390544 },
    { lat: 48.7897211, lng: 18.0390329 },
    { lat: 48.7897606, lng: 18.0390126 },
    { lat: 48.7898121, lng: 18.0389509 },
    { lat: 48.7899226, lng: 18.0389285 },
    { lat: 48.7900092, lng: 18.0389809 },
    { lat: 48.7900653, lng: 18.0389583 },
    { lat: 48.7902113, lng: 18.0388473 },
    { lat: 48.7903216, lng: 18.0388439 },
    { lat: 48.7903732, lng: 18.0388001 },
    { lat: 48.7904192, lng: 18.0388146 },
    { lat: 48.7904698, lng: 18.0389066 },
    { lat: 48.7906109, lng: 18.0389463 },
    { lat: 48.7907016, lng: 18.0389928 },
    { lat: 48.790795, lng: 18.0389118 },
    { lat: 48.7908696, lng: 18.0387564 },
    { lat: 48.7909009, lng: 18.0387336 },
    { lat: 48.7909505, lng: 18.038636 },
    { lat: 48.7909634, lng: 18.0385706 },
    { lat: 48.7909463, lng: 18.0384111 },
    { lat: 48.7909384, lng: 18.0382779 },
    { lat: 48.7909369, lng: 18.0381965 },
    { lat: 48.790917, lng: 18.0380668 },
    { lat: 48.7910468, lng: 18.0378374 },
    { lat: 48.7911525, lng: 18.0377515 },
    { lat: 48.791205, lng: 18.0375712 },
    { lat: 48.7912599, lng: 18.0374511 },
    { lat: 48.7912969, lng: 18.0372556 },
    { lat: 48.7913115, lng: 18.0370987 },
    { lat: 48.7913013, lng: 18.0370224 },
    { lat: 48.791278, lng: 18.0369521 },
    { lat: 48.7912781, lng: 18.0368408 },
    { lat: 48.7913169, lng: 18.0367683 },
    { lat: 48.7913389, lng: 18.0366447 },
    { lat: 48.7913366, lng: 18.0365695 },
    { lat: 48.7914, lng: 18.0364163 },
    { lat: 48.7914614, lng: 18.0359857 },
    { lat: 48.791569, lng: 18.0352825 },
    { lat: 48.7916905, lng: 18.0349876 },
    { lat: 48.7917468, lng: 18.0347957 },
    { lat: 48.7917629, lng: 18.0345304 },
    { lat: 48.7917593, lng: 18.0344289 },
    { lat: 48.791858, lng: 18.0339997 },
    { lat: 48.7919217, lng: 18.0338168 },
    { lat: 48.7919253, lng: 18.0337057 },
    { lat: 48.7920016, lng: 18.0334871 },
    { lat: 48.7919599, lng: 18.033429 },
    { lat: 48.7919252, lng: 18.0333578 },
    { lat: 48.7919791, lng: 18.0331973 },
    { lat: 48.7920121, lng: 18.0330876 },
    { lat: 48.7920802, lng: 18.0329023 },
    { lat: 48.792118, lng: 18.0327605 },
    { lat: 48.7921629, lng: 18.0325636 },
    { lat: 48.7922615, lng: 18.0322053 },
    { lat: 48.7923348, lng: 18.0320121 },
    { lat: 48.7923707, lng: 18.0319357 },
    { lat: 48.7923927, lng: 18.0318482 },
    { lat: 48.7924489, lng: 18.0317662 },
    { lat: 48.7926444, lng: 18.0315798 },
    { lat: 48.7926655, lng: 18.0315355 },
    { lat: 48.792732, lng: 18.0314764 },
    { lat: 48.7928145, lng: 18.0311907 },
    { lat: 48.7928772, lng: 18.0309707 },
    { lat: 48.7929636, lng: 18.0307349 },
    { lat: 48.7929931, lng: 18.0307345 },
    { lat: 48.7930274, lng: 18.0306103 },
    { lat: 48.7930185, lng: 18.0304587 },
    { lat: 48.7929772, lng: 18.0302559 },
    { lat: 48.7930682, lng: 18.0300736 },
    { lat: 48.7931292, lng: 18.0300178 },
    { lat: 48.7933496, lng: 18.0295839 },
    { lat: 48.7934648, lng: 18.0295075 },
    { lat: 48.7936266, lng: 18.0294757 },
    { lat: 48.7937157, lng: 18.0294182 },
    { lat: 48.7938891, lng: 18.0294878 },
    { lat: 48.7940357, lng: 18.0295239 },
    { lat: 48.7941696, lng: 18.0295971 },
    { lat: 48.7942814, lng: 18.0296507 },
    { lat: 48.7944155, lng: 18.0296516 },
    { lat: 48.7945791, lng: 18.0298393 },
    { lat: 48.7946817, lng: 18.0298584 },
    { lat: 48.794778, lng: 18.02998 },
    { lat: 48.7948855, lng: 18.0300618 },
    { lat: 48.7950063, lng: 18.0301582 },
    { lat: 48.7951424, lng: 18.0303607 },
    { lat: 48.795245, lng: 18.0304363 },
    { lat: 48.7954835, lng: 18.0305176 },
    { lat: 48.7956424, lng: 18.0305703 },
    { lat: 48.7958792, lng: 18.0307037 },
    { lat: 48.7960944, lng: 18.0306951 },
    { lat: 48.7964001, lng: 18.030729 },
    { lat: 48.7965302, lng: 18.0307741 },
    { lat: 48.7967154, lng: 18.0308547 },
    { lat: 48.7969263, lng: 18.0309057 },
    { lat: 48.796958, lng: 18.0308903 },
    { lat: 48.7971547, lng: 18.0309322 },
    { lat: 48.7972238, lng: 18.0308806 },
    { lat: 48.7972768, lng: 18.0309178 },
    { lat: 48.7972755, lng: 18.0309524 },
    { lat: 48.7974537, lng: 18.0309902 },
    { lat: 48.7975442, lng: 18.0309365 },
    { lat: 48.7976166, lng: 18.0309371 },
    { lat: 48.7977377, lng: 18.0308889 },
    { lat: 48.7978761, lng: 18.0308609 },
    { lat: 48.7979378, lng: 18.0309292 },
    { lat: 48.7980889, lng: 18.0309851 },
    { lat: 48.7981417, lng: 18.0309267 },
    { lat: 48.7982234, lng: 18.0308964 },
    { lat: 48.7983199, lng: 18.0308834 },
    { lat: 48.7984985, lng: 18.0308883 },
    { lat: 48.7985381, lng: 18.0308697 },
    { lat: 48.7985837, lng: 18.030861 },
    { lat: 48.7987798, lng: 18.0308542 },
    { lat: 48.798814, lng: 18.0308414 },
    { lat: 48.7988376, lng: 18.0308597 },
    { lat: 48.7989143, lng: 18.0308013 },
    { lat: 48.7990779, lng: 18.0307642 },
    { lat: 48.7991088, lng: 18.0306745 },
    { lat: 48.7991935, lng: 18.0306183 },
    { lat: 48.7993184, lng: 18.0307064 },
    { lat: 48.7993518, lng: 18.0307369 },
    { lat: 48.7994343, lng: 18.0308357 },
    { lat: 48.7995592, lng: 18.030869 },
    { lat: 48.7996649, lng: 18.0308966 },
    { lat: 48.799768, lng: 18.03098 },
    { lat: 48.7998957, lng: 18.0308883 },
    { lat: 48.7999576, lng: 18.0308263 },
    { lat: 48.8000116, lng: 18.0308634 },
    { lat: 48.8001681, lng: 18.0307785 },
    { lat: 48.800202, lng: 18.0307231 },
    { lat: 48.8002606, lng: 18.0306271 },
    { lat: 48.8004227, lng: 18.0304681 },
    { lat: 48.8004604, lng: 18.0301878 },
    { lat: 48.800687, lng: 18.0297594 },
    { lat: 48.8009829, lng: 18.0294588 },
    { lat: 48.8011163, lng: 18.0293066 },
    { lat: 48.801279, lng: 18.0293093 },
    { lat: 48.8013845, lng: 18.0290695 },
    { lat: 48.8015648, lng: 18.0289908 },
    { lat: 48.8016958, lng: 18.0288933 },
    { lat: 48.8018347, lng: 18.0287069 },
    { lat: 48.8019272, lng: 18.0286662 },
    { lat: 48.8022211, lng: 18.028368 },
    { lat: 48.8022939, lng: 18.0282991 },
    { lat: 48.8024422, lng: 18.0281001 },
    { lat: 48.8024546, lng: 18.0279247 },
    { lat: 48.8025731, lng: 18.0276954 },
    { lat: 48.8026039, lng: 18.027625 },
    { lat: 48.8027886, lng: 18.0273877 },
    { lat: 48.8028885, lng: 18.0273574 },
    { lat: 48.8029617, lng: 18.0272233 },
    { lat: 48.8032065, lng: 18.027163 },
    { lat: 48.8032994, lng: 18.0269378 },
    { lat: 48.8034966, lng: 18.0265306 },
    { lat: 48.8037197, lng: 18.0265642 },
    { lat: 48.8038262, lng: 18.0264381 },
    { lat: 48.80392, lng: 18.0262672 },
    { lat: 48.8040924, lng: 18.0261468 },
    { lat: 48.804288, lng: 18.0259017 },
    { lat: 48.8043041, lng: 18.0256213 },
    { lat: 48.8043831, lng: 18.0254857 },
    { lat: 48.8045958, lng: 18.0254183 },
    { lat: 48.8048336, lng: 18.0252408 },
    { lat: 48.8050039, lng: 18.0250885 },
    { lat: 48.8051702, lng: 18.0250926 },
    { lat: 48.8052081, lng: 18.0250849 },
    { lat: 48.8052232, lng: 18.0250736 },
    { lat: 48.8053825, lng: 18.0249224 },
    { lat: 48.8056016, lng: 18.0247926 },
    { lat: 48.8057528, lng: 18.0245971 },
    { lat: 48.8058677, lng: 18.02452 },
    { lat: 48.8059719, lng: 18.02445 },
    { lat: 48.8060667, lng: 18.0243889 },
    { lat: 48.8062838, lng: 18.0240513 },
    { lat: 48.8063182, lng: 18.0239288 },
    { lat: 48.8064634, lng: 18.0238057 },
    { lat: 48.80662, lng: 18.0235329 },
    { lat: 48.8068393, lng: 18.023462 },
    { lat: 48.806953, lng: 18.0233239 },
    { lat: 48.8072845, lng: 18.0230703 },
    { lat: 48.8074299, lng: 18.023065 },
    { lat: 48.8076584, lng: 18.0228447 },
    { lat: 48.8076302, lng: 18.0226158 },
    { lat: 48.8077369, lng: 18.0224159 },
    { lat: 48.808086, lng: 18.0221549 },
    { lat: 48.8083013, lng: 18.0219411 },
    { lat: 48.8085828, lng: 18.0218686 },
    { lat: 48.8087646, lng: 18.0216629 },
    { lat: 48.8089238, lng: 18.0217201 },
    { lat: 48.8092788, lng: 18.0213875 },
    { lat: 48.8093456, lng: 18.0212177 },
    { lat: 48.8094973, lng: 18.0211098 },
    { lat: 48.8095308, lng: 18.0208364 },
    { lat: 48.8096598, lng: 18.0206889 },
    { lat: 48.8097213, lng: 18.0207372 },
    { lat: 48.8098678, lng: 18.0206959 },
    { lat: 48.810035, lng: 18.0205071 },
    { lat: 48.8101278, lng: 18.0205881 },
    { lat: 48.810221, lng: 18.0204444 },
    { lat: 48.8103626, lng: 18.0205116 },
    { lat: 48.8105648, lng: 18.0203023 },
    { lat: 48.8105963, lng: 18.020264 },
    { lat: 48.8108567, lng: 18.020144 },
    { lat: 48.8110804, lng: 18.0202039 },
    { lat: 48.8113928, lng: 18.0199895 },
    { lat: 48.8114598, lng: 18.0200549 },
    { lat: 48.8118049, lng: 18.0199359 },
    { lat: 48.812, lng: 18.019724 },
    { lat: 48.8121622, lng: 18.0196792 },
    { lat: 48.8121856, lng: 18.019445 },
    { lat: 48.8122687, lng: 18.0193783 },
    { lat: 48.8123482, lng: 18.0193668 },
    { lat: 48.8124248, lng: 18.0193873 },
    { lat: 48.8125551, lng: 18.0192787 },
    { lat: 48.8130744, lng: 18.0191848 },
    { lat: 48.8132457, lng: 18.0191831 },
    { lat: 48.81346, lng: 18.018931 },
    { lat: 48.8136307, lng: 18.0189731 },
    { lat: 48.8136795, lng: 18.0191667 },
    { lat: 48.8141298, lng: 18.0195327 },
    { lat: 48.8142628, lng: 18.0194126 },
    { lat: 48.814618, lng: 18.0197904 },
    { lat: 48.8146734, lng: 18.0198506 },
    { lat: 48.8150067, lng: 18.0198961 },
    { lat: 48.8155228, lng: 18.0199219 },
    { lat: 48.8156372, lng: 18.019851 },
    { lat: 48.8156903, lng: 18.0197743 },
    { lat: 48.8158015, lng: 18.0195738 },
    { lat: 48.816026, lng: 18.0197436 },
    { lat: 48.8165391, lng: 18.0196219 },
    { lat: 48.8169537, lng: 18.0200511 },
    { lat: 48.8170202, lng: 18.0201836 },
    { lat: 48.8172557, lng: 18.0203473 },
    { lat: 48.8173294, lng: 18.0203939 },
    { lat: 48.817485, lng: 18.0202367 },
    { lat: 48.8180168, lng: 18.0205487 },
    { lat: 48.8184015, lng: 18.020659 },
    { lat: 48.8185436, lng: 18.0206945 },
    { lat: 48.8188819, lng: 18.0209002 },
    { lat: 48.8191518, lng: 18.021094 },
    { lat: 48.8192465, lng: 18.021381 },
    { lat: 48.8195007, lng: 18.0217287 },
    { lat: 48.8195579, lng: 18.0217797 },
    { lat: 48.8197156, lng: 18.0218913 },
    { lat: 48.8198634, lng: 18.0218874 },
    { lat: 48.8205078, lng: 18.0226369 },
    { lat: 48.8206439, lng: 18.0225702 },
    { lat: 48.8208676, lng: 18.0226495 },
    { lat: 48.82121, lng: 18.0225252 },
    { lat: 48.8215667, lng: 18.0227538 },
    { lat: 48.8217492, lng: 18.0226718 },
    { lat: 48.8219134, lng: 18.0226256 },
    { lat: 48.8220561, lng: 18.0225949 },
    { lat: 48.8221667, lng: 18.022634 },
    { lat: 48.8222724, lng: 18.0226047 },
    { lat: 48.8223618, lng: 18.0224561 },
    { lat: 48.8224014, lng: 18.022343 },
    { lat: 48.8224427, lng: 18.0222607 },
    { lat: 48.8225201, lng: 18.0222137 },
    { lat: 48.823104, lng: 18.0217605 },
    { lat: 48.8233628, lng: 18.021632 },
    { lat: 48.8237686, lng: 18.0218526 },
    { lat: 48.8239807, lng: 18.0219675 },
    { lat: 48.8244125, lng: 18.0219389 },
    { lat: 48.8246819, lng: 18.0218956 },
    { lat: 48.825218, lng: 18.0216093 },
    { lat: 48.8254631, lng: 18.0215747 },
    { lat: 48.8256686, lng: 18.0212701 },
    { lat: 48.8258132, lng: 18.021195 },
    { lat: 48.8261473, lng: 18.021311 },
    { lat: 48.8262684, lng: 18.0211266 },
    { lat: 48.8265196, lng: 18.0211149 },
    { lat: 48.8266954, lng: 18.0209184 },
    { lat: 48.8267846, lng: 18.0207674 },
    { lat: 48.826848, lng: 18.0206961 },
    { lat: 48.826999, lng: 18.0209376 },
    { lat: 48.8272065, lng: 18.020879 },
    { lat: 48.8272696, lng: 18.0206463 },
    { lat: 48.8272577, lng: 18.0205401 },
    { lat: 48.8271213, lng: 18.0203927 },
    { lat: 48.827135, lng: 18.020317 },
    { lat: 48.8273569, lng: 18.0202533 },
    { lat: 48.8275523, lng: 18.020046 },
    { lat: 48.8277463, lng: 18.0199263 },
    { lat: 48.8279023, lng: 18.0197211 },
    { lat: 48.8281569, lng: 18.0191404 },
    { lat: 48.8283215, lng: 18.0188885 },
    { lat: 48.8285868, lng: 18.0188866 },
    { lat: 48.8286704, lng: 18.0184884 },
    { lat: 48.8286564, lng: 18.018327 },
    { lat: 48.8287966, lng: 18.0181769 },
    { lat: 48.8293617, lng: 18.0174453 },
    { lat: 48.8295183, lng: 18.0175387 },
    { lat: 48.8296341, lng: 18.0175284 },
    { lat: 48.8298451, lng: 18.0173175 },
    { lat: 48.8299665, lng: 18.0169848 },
    { lat: 48.8300997, lng: 18.0167339 },
    { lat: 48.8301043, lng: 18.016431 },
    { lat: 48.8302292, lng: 18.0164062 },
    { lat: 48.8306448, lng: 18.0155496 },
    { lat: 48.8306233, lng: 18.0153942 },
    { lat: 48.8306347, lng: 18.0151459 },
    { lat: 48.830922, lng: 18.0148322 },
    { lat: 48.8310859, lng: 18.0144915 },
    { lat: 48.831324, lng: 18.0143945 },
    { lat: 48.831662, lng: 18.0142713 },
    { lat: 48.8318411, lng: 18.0143048 },
    { lat: 48.8322725, lng: 18.0138473 },
    { lat: 48.8325274, lng: 18.0140395 },
    { lat: 48.832843, lng: 18.0139739 },
    { lat: 48.8329061, lng: 18.01376 },
    { lat: 48.8329883, lng: 18.0137016 },
    { lat: 48.8331541, lng: 18.013509 },
    { lat: 48.8332702, lng: 18.0134492 },
    { lat: 48.8334687, lng: 18.0132346 },
    { lat: 48.8335522, lng: 18.0127927 },
    { lat: 48.8337105, lng: 18.0126459 },
    { lat: 48.8339022, lng: 18.0125286 },
    { lat: 48.8339841, lng: 18.0123494 },
    { lat: 48.8340667, lng: 18.0121416 },
    { lat: 48.8341421, lng: 18.0118681 },
    { lat: 48.834173, lng: 18.0116314 },
    { lat: 48.8341531, lng: 18.0114453 },
    { lat: 48.8342166, lng: 18.0113557 },
    { lat: 48.8343456, lng: 18.0112458 },
    { lat: 48.8343716, lng: 18.0110578 },
    { lat: 48.8344048, lng: 18.0109863 },
    { lat: 48.8344695, lng: 18.010937 },
    { lat: 48.8345482, lng: 18.0109683 },
    { lat: 48.8346764, lng: 18.0110003 },
    { lat: 48.8347221, lng: 18.0109884 },
    { lat: 48.834833, lng: 18.0106513 },
    { lat: 48.8348117, lng: 18.0105754 },
    { lat: 48.8348863, lng: 18.0103489 },
    { lat: 48.8350871, lng: 18.0102522 },
    { lat: 48.8351368, lng: 18.0100613 },
    { lat: 48.8351605, lng: 18.0098704 },
    { lat: 48.835206, lng: 18.0098345 },
    { lat: 48.8353333, lng: 18.0098925 },
    { lat: 48.8354329, lng: 18.0097041 },
    { lat: 48.8355064, lng: 18.009687 },
    { lat: 48.8356953, lng: 18.0097703 },
    { lat: 48.8359332, lng: 18.009746 },
    { lat: 48.8361266, lng: 18.009814 },
    { lat: 48.836394, lng: 18.0097161 },
    { lat: 48.8365126, lng: 18.0095661 },
    { lat: 48.8366456, lng: 18.0095223 },
    { lat: 48.8368166, lng: 18.0095905 },
    { lat: 48.8370433, lng: 18.009721 },
    { lat: 48.8371784, lng: 18.0096181 },
    { lat: 48.8373597, lng: 18.009744 },
    { lat: 48.8374145, lng: 18.0098917 },
    { lat: 48.8375008, lng: 18.0099395 },
    { lat: 48.837586, lng: 18.009852 },
    { lat: 48.837575, lng: 18.0096661 },
    { lat: 48.837585, lng: 18.0095874 },
    { lat: 48.8377988, lng: 18.009366 },
    { lat: 48.8378921, lng: 18.0093559 },
    { lat: 48.8381099, lng: 18.009091 },
    { lat: 48.8382569, lng: 18.0090574 },
    { lat: 48.8382808, lng: 18.0088292 },
    { lat: 48.8383965, lng: 18.0087816 },
    { lat: 48.8384222, lng: 18.0087015 },
    { lat: 48.8382436, lng: 18.0084881 },
    { lat: 48.8382382, lng: 18.0083774 },
    { lat: 48.8382726, lng: 18.0083466 },
    { lat: 48.8384262, lng: 18.0082918 },
    { lat: 48.8385892, lng: 18.0081654 },
    { lat: 48.8386253, lng: 18.0080716 },
    { lat: 48.8386449, lng: 18.0076774 },
    { lat: 48.8386035, lng: 18.0075855 },
    { lat: 48.8384847, lng: 18.0075243 },
    { lat: 48.8384713, lng: 18.0074705 },
    { lat: 48.8385169, lng: 18.0073608 },
    { lat: 48.8384455, lng: 18.0072412 },
    { lat: 48.8384363, lng: 18.0072019 },
    { lat: 48.8384376, lng: 18.007167 },
    { lat: 48.8384655, lng: 18.0071435 },
    { lat: 48.8385012, lng: 18.0071354 },
    { lat: 48.8385843, lng: 18.0072245 },
    { lat: 48.8386164, lng: 18.0072122 },
    { lat: 48.8386935, lng: 18.0069514 },
    { lat: 48.8386939, lng: 18.0068266 },
    { lat: 48.8386981, lng: 18.0067042 },
    { lat: 48.8387028, lng: 18.0066678 },
    { lat: 48.83873, lng: 18.0066315 },
    { lat: 48.8388108, lng: 18.0066434 },
    { lat: 48.8389438, lng: 18.0065287 },
    { lat: 48.8390418, lng: 18.0065057 },
    { lat: 48.839074, lng: 18.0063219 },
    { lat: 48.8391041, lng: 18.0063353 },
    { lat: 48.8391151, lng: 18.0063697 },
    { lat: 48.8391872, lng: 18.0064806 },
    { lat: 48.839263, lng: 18.0065427 },
    { lat: 48.8392903, lng: 18.0065648 },
    { lat: 48.8393212, lng: 18.0065513 },
    { lat: 48.8393832, lng: 18.0063387 },
    { lat: 48.8394291, lng: 18.0061838 },
    { lat: 48.8393784, lng: 18.0058972 },
    { lat: 48.8393845, lng: 18.0058491 },
    { lat: 48.8394381, lng: 18.0057598 },
    { lat: 48.8394714, lng: 18.0054457 },
    { lat: 48.8394584, lng: 18.0053421 },
    { lat: 48.8393403, lng: 18.0050607 },
    { lat: 48.8393476, lng: 18.0049913 },
    { lat: 48.8395316, lng: 18.0048596 },
    { lat: 48.8396049, lng: 18.0046483 },
    { lat: 48.8395421, lng: 18.0044658 },
    { lat: 48.8395629, lng: 18.0043978 },
    { lat: 48.8396302, lng: 18.0043507 },
    { lat: 48.8398319, lng: 18.0043269 },
    { lat: 48.8400275, lng: 18.0044101 },
    { lat: 48.8401355, lng: 18.0041725 },
    { lat: 48.8402164, lng: 18.0041297 },
    { lat: 48.8403313, lng: 18.003936 },
    { lat: 48.8402935, lng: 18.0036771 },
    { lat: 48.8403185, lng: 18.0036239 },
    { lat: 48.840443, lng: 18.0036661 },
    { lat: 48.8405802, lng: 18.0035454 },
    { lat: 48.8406767, lng: 18.0035545 },
    { lat: 48.8407447, lng: 18.0035392 },
    { lat: 48.8407871, lng: 18.0035292 },
    { lat: 48.8408759, lng: 18.0034867 },
    { lat: 48.8410781, lng: 18.0033947 },
    { lat: 48.841118, lng: 18.0033048 },
    { lat: 48.8410238, lng: 18.0031448 },
    { lat: 48.8410198, lng: 18.0030562 },
    { lat: 48.8412046, lng: 18.0027462 },
    { lat: 48.841357, lng: 18.0026306 },
    { lat: 48.8414093, lng: 18.0025228 },
    { lat: 48.8414226, lng: 18.0023483 },
    { lat: 48.8417472, lng: 18.002186 },
    { lat: 48.8418892, lng: 18.0020525 },
    { lat: 48.8420136, lng: 18.0018245 },
    { lat: 48.8421234, lng: 18.0015803 },
    { lat: 48.8422202, lng: 18.0013855 },
    { lat: 48.842314, lng: 18.0011593 },
    { lat: 48.8425761, lng: 18.0008216 },
    { lat: 48.8426044, lng: 18.0006877 },
    { lat: 48.8427659, lng: 18.0004992 },
    { lat: 48.8428212, lng: 18.0004028 },
    { lat: 48.8428524, lng: 18.0004183 },
    { lat: 48.8430011, lng: 18.0004486 },
    { lat: 48.8430283, lng: 18.0004532 },
    { lat: 48.8430962, lng: 18.0004723 },
    { lat: 48.843101, lng: 18.0004584 },
    { lat: 48.8431488, lng: 18.0003146 },
    { lat: 48.8431823, lng: 18.0002485 },
    { lat: 48.843237, lng: 18.0001421 },
    { lat: 48.8433058, lng: 18.00003 },
    { lat: 48.8433832, lng: 17.9999277 },
    { lat: 48.843603, lng: 17.9995969 },
    { lat: 48.8437275, lng: 17.9994094 },
    { lat: 48.8438961, lng: 17.999073 },
    { lat: 48.8446033, lng: 17.9992945 },
    { lat: 48.8448793, lng: 17.9993818 },
    { lat: 48.8451024, lng: 17.9994357 },
    { lat: 48.845335, lng: 17.9994726 },
    { lat: 48.8454789, lng: 17.9994423 },
    { lat: 48.8455674, lng: 17.9994163 },
    { lat: 48.8456498, lng: 17.9993926 },
    { lat: 48.8457906, lng: 17.9993351 },
    { lat: 48.8458913, lng: 17.9992813 },
    { lat: 48.8460235, lng: 17.9992053 },
    { lat: 48.8460509, lng: 17.9991923 },
    { lat: 48.8461154, lng: 17.9991964 },
    { lat: 48.8461538, lng: 17.9991998 },
    { lat: 48.8462109, lng: 17.9992071 },
    { lat: 48.8462254, lng: 17.9992044 },
    { lat: 48.8466734, lng: 17.9991454 },
    { lat: 48.8467357, lng: 17.9991868 },
    { lat: 48.8476401, lng: 17.9997667 },
    { lat: 48.8476494, lng: 17.9997716 },
    { lat: 48.8478327, lng: 17.9997796 },
    { lat: 48.8478815, lng: 17.999782 },
    { lat: 48.8483817, lng: 17.9996886 },
    { lat: 48.8487887, lng: 17.999361 },
    { lat: 48.8490995, lng: 17.9991167 },
    { lat: 48.8493242, lng: 17.9989852 },
    { lat: 48.8495031, lng: 17.998919 },
    { lat: 48.8498022, lng: 17.998806 },
    { lat: 48.8500036, lng: 17.9989847 },
    { lat: 48.8502025, lng: 17.9988756 },
    { lat: 48.8504062, lng: 17.9986931 },
    { lat: 48.8506932, lng: 17.9982589 },
    { lat: 48.8509265, lng: 17.9978531 },
    { lat: 48.8515111, lng: 17.9969003 },
    { lat: 48.8516431, lng: 17.9966115 },
    { lat: 48.8517223, lng: 17.9962875 },
    { lat: 48.8517752, lng: 17.9958312 },
    { lat: 48.851783, lng: 17.9956538 },
    { lat: 48.8518092, lng: 17.9953177 },
    { lat: 48.8518522, lng: 17.994765 },
    { lat: 48.8518786, lng: 17.9946395 },
    { lat: 48.8515782, lng: 17.9944065 },
    { lat: 48.8517243, lng: 17.9928707 },
    { lat: 48.8518177, lng: 17.991952 },
    { lat: 48.8516378, lng: 17.9913328 },
    { lat: 48.8515653, lng: 17.9910799 },
    { lat: 48.8515395, lng: 17.9909315 },
    { lat: 48.8515165, lng: 17.9907139 },
    { lat: 48.8515017, lng: 17.9905761 },
    { lat: 48.8514785, lng: 17.9903356 },
    { lat: 48.8514121, lng: 17.9900543 },
    { lat: 48.8513793, lng: 17.9899183 },
    { lat: 48.8514896, lng: 17.9896492 },
    { lat: 48.8515614, lng: 17.9894674 },
    { lat: 48.8515194, lng: 17.9893326 },
    { lat: 48.8515032, lng: 17.9892864 },
    { lat: 48.8514664, lng: 17.9891818 },
    { lat: 48.8514254, lng: 17.989059 },
    { lat: 48.8513691, lng: 17.9888925 },
    { lat: 48.8513012, lng: 17.9886957 },
    { lat: 48.851216, lng: 17.9885349 },
    { lat: 48.8511334, lng: 17.9883773 },
    { lat: 48.8510742, lng: 17.9882377 },
    { lat: 48.8510388, lng: 17.988154 },
    { lat: 48.8509678, lng: 17.9879812 },
    { lat: 48.8509145, lng: 17.987825 },
    { lat: 48.8508593, lng: 17.9876756 },
    { lat: 48.8508184, lng: 17.987555 },
    { lat: 48.8508039, lng: 17.9874835 },
    { lat: 48.850781, lng: 17.9873644 },
    { lat: 48.8507532, lng: 17.987218 },
    { lat: 48.8507274, lng: 17.9870699 },
    { lat: 48.8506939, lng: 17.9869039 },
    { lat: 48.8506559, lng: 17.9867008 },
    { lat: 48.8506328, lng: 17.9865943 },
    { lat: 48.85062, lng: 17.9865337 },
    { lat: 48.8506078, lng: 17.9864805 },
    { lat: 48.8505751, lng: 17.9863628 },
    { lat: 48.8505413, lng: 17.9862516 },
    { lat: 48.8504488, lng: 17.9859839 },
    { lat: 48.8504244, lng: 17.9859751 },
    { lat: 48.8503868, lng: 17.9859669 },
    { lat: 48.8503386, lng: 17.9859559 },
    { lat: 48.8503216, lng: 17.9859518 },
    { lat: 48.85032, lng: 17.9858896 },
    { lat: 48.8503196, lng: 17.985842 },
    { lat: 48.8503027, lng: 17.9857698 },
    { lat: 48.8503014, lng: 17.9857635 },
    { lat: 48.8502989, lng: 17.9857606 },
    { lat: 48.8502685, lng: 17.9857021 },
    { lat: 48.850215, lng: 17.9856025 },
    { lat: 48.850143, lng: 17.9854933 },
    { lat: 48.850014, lng: 17.9852944 },
    { lat: 48.849941, lng: 17.9851488 },
    { lat: 48.8498595, lng: 17.9849915 },
    { lat: 48.8497848, lng: 17.9847597 },
    { lat: 48.8497667, lng: 17.9846823 },
    { lat: 48.8497286, lng: 17.9844771 },
    { lat: 48.8497228, lng: 17.9844363 },
    { lat: 48.8497211, lng: 17.9843907 },
    { lat: 48.8497193, lng: 17.9843017 },
    { lat: 48.8497034, lng: 17.9842039 },
    { lat: 48.8496408, lng: 17.9841575 },
    { lat: 48.84956, lng: 17.9840904 },
    { lat: 48.8494275, lng: 17.9839866 },
    { lat: 48.8492733, lng: 17.9838621 },
    { lat: 48.8491643, lng: 17.9837755 },
    { lat: 48.8491381, lng: 17.9837522 },
    { lat: 48.8490447, lng: 17.9836617 },
    { lat: 48.8490231, lng: 17.9836392 },
    { lat: 48.849013, lng: 17.9836205 },
    { lat: 48.8488461, lng: 17.9834715 },
    { lat: 48.8487074, lng: 17.9833413 },
    { lat: 48.8485721, lng: 17.9832092 },
    { lat: 48.8485389, lng: 17.9831793 },
    { lat: 48.8483456, lng: 17.9830064 },
    { lat: 48.8481019, lng: 17.9835602 },
    { lat: 48.8479671, lng: 17.9838355 },
    { lat: 48.8477742, lng: 17.9842205 },
    { lat: 48.847753, lng: 17.9842628 },
    { lat: 48.8477349, lng: 17.9842993 },
    { lat: 48.8476113, lng: 17.984577 },
    { lat: 48.8475228, lng: 17.9847757 },
    { lat: 48.8472549, lng: 17.9853035 },
    { lat: 48.8472326, lng: 17.9853466 },
    { lat: 48.8470932, lng: 17.9856215 },
    { lat: 48.8468755, lng: 17.9860424 },
    { lat: 48.8466423, lng: 17.9865292 },
    { lat: 48.8465892, lng: 17.9866389 },
    { lat: 48.8464909, lng: 17.9868286 },
    { lat: 48.8464391, lng: 17.9869274 },
    { lat: 48.8463784, lng: 17.9870457 },
    { lat: 48.8462048, lng: 17.9873929 },
    { lat: 48.8460396, lng: 17.9876508 },
    { lat: 48.8460048, lng: 17.9876533 },
    { lat: 48.846017, lng: 17.9875975 },
    { lat: 48.8461263, lng: 17.9870968 },
    { lat: 48.846263, lng: 17.9867535 },
    { lat: 48.8464063, lng: 17.9862223 },
    { lat: 48.8464103, lng: 17.98621 },
    { lat: 48.8464815, lng: 17.9859473 },
    { lat: 48.8465579, lng: 17.9857108 },
    { lat: 48.8466197, lng: 17.985497 },
    { lat: 48.8466764, lng: 17.985332 },
    { lat: 48.8466975, lng: 17.9850575 },
    { lat: 48.8467183, lng: 17.9847812 },
    { lat: 48.8466905, lng: 17.9842729 },
    { lat: 48.8466796, lng: 17.9840881 },
    { lat: 48.8466737, lng: 17.9839326 },
    { lat: 48.8466611, lng: 17.9836441 },
    { lat: 48.8466525, lng: 17.9832883 },
    { lat: 48.8466582, lng: 17.9831567 },
    { lat: 48.8466826, lng: 17.9827098 },
    { lat: 48.8468777, lng: 17.9817006 },
    { lat: 48.8468655, lng: 17.9808476 },
    { lat: 48.8468378, lng: 17.9807034 },
    { lat: 48.8467935, lng: 17.9805073 },
    { lat: 48.8466913, lng: 17.9799837 },
    { lat: 48.8463307, lng: 17.9789086 },
    { lat: 48.8461065, lng: 17.978307 },
    { lat: 48.84604, lng: 17.9781182 },
    { lat: 48.8459475, lng: 17.9778517 },
    { lat: 48.8459222, lng: 17.9777867 },
    { lat: 48.8459021, lng: 17.9777314 },
    { lat: 48.8458821, lng: 17.9776814 },
    { lat: 48.8457781, lng: 17.9774114 },
    { lat: 48.845747, lng: 17.977325 },
    { lat: 48.8456091, lng: 17.9769424 },
    { lat: 48.8452454, lng: 17.9759477 },
    { lat: 48.8451534, lng: 17.9757273 },
    { lat: 48.8450802, lng: 17.9755427 },
    { lat: 48.8447484, lng: 17.9746307 },
    { lat: 48.8444383, lng: 17.9737004 },
    { lat: 48.8443247, lng: 17.9733443 },
    { lat: 48.8441996, lng: 17.97295 },
    { lat: 48.8439959, lng: 17.9723127 },
    { lat: 48.8437462, lng: 17.9716085 },
    { lat: 48.843654, lng: 17.9713462 },
    { lat: 48.8435307, lng: 17.9709979 },
    { lat: 48.8432538, lng: 17.9700778 },
    { lat: 48.8428386, lng: 17.9691877 },
    { lat: 48.8424819, lng: 17.9684107 },
    { lat: 48.842212, lng: 17.9678172 },
    { lat: 48.8419034, lng: 17.9673157 },
    { lat: 48.841225, lng: 17.9662129 },
    { lat: 48.8407578, lng: 17.9654553 },
    { lat: 48.8405469, lng: 17.9651139 },
    { lat: 48.8405325, lng: 17.9648558 },
    { lat: 48.8404973, lng: 17.9642034 },
    { lat: 48.8404814, lng: 17.9638448 },
    { lat: 48.8404235, lng: 17.9624129 },
    { lat: 48.8403147, lng: 17.9597085 },
    { lat: 48.8402857, lng: 17.9589174 },
    { lat: 48.8402792, lng: 17.9586778 },
    { lat: 48.8402564, lng: 17.9583097 },
    { lat: 48.8402232, lng: 17.9577915 },
    { lat: 48.8402024, lng: 17.9574621 },
    { lat: 48.8401652, lng: 17.956857 },
    { lat: 48.8401567, lng: 17.9567499 },
    { lat: 48.8401427, lng: 17.9565705 },
    { lat: 48.8401214, lng: 17.9563072 },
    { lat: 48.840044, lng: 17.9550658 },
    { lat: 48.8400412, lng: 17.9550193 },
    { lat: 48.8400376, lng: 17.9549592 },
    { lat: 48.8400174, lng: 17.9546198 },
    { lat: 48.839963, lng: 17.9537207 },
    { lat: 48.8399433, lng: 17.9534102 },
    { lat: 48.8399401, lng: 17.9533569 },
    { lat: 48.8399341, lng: 17.9532549 },
    { lat: 48.8398962, lng: 17.9525989 },
    { lat: 48.8398818, lng: 17.9523218 },
  ],
  VeľkéBierovce: [
    { lat: 48.8629419, lng: 18.0038099 },
    { lat: 48.8630032, lng: 18.0036984 },
    { lat: 48.8630113, lng: 18.0036833 },
    { lat: 48.8630337, lng: 18.003643 },
    { lat: 48.8630581, lng: 18.0035992 },
    { lat: 48.8632111, lng: 18.0033225 },
    { lat: 48.8633866, lng: 18.0030065 },
    { lat: 48.8634584, lng: 18.0028838 },
    { lat: 48.8634959, lng: 18.0028705 },
    { lat: 48.8635234, lng: 18.0028592 },
    { lat: 48.8635544, lng: 18.0028472 },
    { lat: 48.863687, lng: 18.0026108 },
    { lat: 48.8641694, lng: 18.0017557 },
    { lat: 48.8641804, lng: 18.0017348 },
    { lat: 48.8640429, lng: 18.0014893 },
    { lat: 48.8640594, lng: 18.0014594 },
    { lat: 48.8643668, lng: 18.0009137 },
    { lat: 48.8648301, lng: 18.0000808 },
    { lat: 48.8649491, lng: 17.9998617 },
    { lat: 48.8653462, lng: 17.9991342 },
    { lat: 48.865652, lng: 17.9985779 },
    { lat: 48.8659291, lng: 17.9980718 },
    { lat: 48.8666034, lng: 17.9968398 },
    { lat: 48.866546, lng: 17.9967641 },
    { lat: 48.8665239, lng: 17.9967348 },
    { lat: 48.8665127, lng: 17.9967184 },
    { lat: 48.8664758, lng: 17.9966657 },
    { lat: 48.8664647, lng: 17.9966504 },
    { lat: 48.8664193, lng: 17.9965902 },
    { lat: 48.8664008, lng: 17.9965655 },
    { lat: 48.8663728, lng: 17.996527 },
    { lat: 48.8663249, lng: 17.9964614 },
    { lat: 48.866299, lng: 17.996427 },
    { lat: 48.8662709, lng: 17.9963885 },
    { lat: 48.8662216, lng: 17.9963185 },
    { lat: 48.8662057, lng: 17.996297 },
    { lat: 48.8661787, lng: 17.9962615 },
    { lat: 48.8661749, lng: 17.9962567 },
    { lat: 48.8660818, lng: 17.9961302 },
    { lat: 48.8660229, lng: 17.9960501 },
    { lat: 48.8659911, lng: 17.9960074 },
    { lat: 48.8659615, lng: 17.995967 },
    { lat: 48.8658511, lng: 17.9958156 },
    { lat: 48.865776, lng: 17.995714 },
    { lat: 48.8651481, lng: 17.9948661 },
    { lat: 48.8636238, lng: 17.9942603 },
    { lat: 48.86363, lng: 17.9942358 },
    { lat: 48.8636431, lng: 17.9941888 },
    { lat: 48.8636505, lng: 17.9941617 },
    { lat: 48.8639424, lng: 17.9931437 },
    { lat: 48.8637694, lng: 17.9928894 },
    { lat: 48.8637211, lng: 17.9928164 },
    { lat: 48.8636469, lng: 17.9927103 },
    { lat: 48.8635752, lng: 17.9926021 },
    { lat: 48.8634824, lng: 17.9924646 },
    { lat: 48.8634183, lng: 17.9923715 },
    { lat: 48.8633933, lng: 17.9923322 },
    { lat: 48.8633561, lng: 17.9922767 },
    { lat: 48.8633303, lng: 17.99224 },
    { lat: 48.8632883, lng: 17.9921769 },
    { lat: 48.8631974, lng: 17.9919799 },
    { lat: 48.8637233, lng: 17.9919181 },
    { lat: 48.864199, lng: 17.9918165 },
    { lat: 48.8643096, lng: 17.9917943 },
    { lat: 48.8645616, lng: 17.9917436 },
    { lat: 48.8647857, lng: 17.9916981 },
    { lat: 48.8650491, lng: 17.9916447 },
    { lat: 48.8652554, lng: 17.991603 },
    { lat: 48.8654015, lng: 17.9915735 },
    { lat: 48.8654092, lng: 17.9915717 },
    { lat: 48.8654349, lng: 17.9915664 },
    { lat: 48.8652109, lng: 17.9912549 },
    { lat: 48.8643418, lng: 17.9900675 },
    { lat: 48.8640357, lng: 17.9896474 },
    { lat: 48.8638342, lng: 17.9893482 },
    { lat: 48.8636915, lng: 17.9891504 },
    { lat: 48.8636716, lng: 17.9891585 },
    { lat: 48.8633081, lng: 17.9893111 },
    { lat: 48.8620968, lng: 17.9898196 },
    { lat: 48.8618279, lng: 17.9899322 },
    { lat: 48.8617742, lng: 17.9899549 },
    { lat: 48.8616974, lng: 17.9899764 },
    { lat: 48.861689, lng: 17.9899665 },
    { lat: 48.861592, lng: 17.9898334 },
    { lat: 48.8613778, lng: 17.989551 },
    { lat: 48.8611483, lng: 17.9892424 },
    { lat: 48.8610142, lng: 17.9890542 },
    { lat: 48.8606798, lng: 17.9886114 },
    { lat: 48.8606638, lng: 17.9885899 },
    { lat: 48.860617, lng: 17.9885211 },
    { lat: 48.8604217, lng: 17.9882354 },
    { lat: 48.8598951, lng: 17.9874625 },
    { lat: 48.8593974, lng: 17.9867778 },
    { lat: 48.8592127, lng: 17.9865137 },
    { lat: 48.8591201, lng: 17.9863804 },
    { lat: 48.8580854, lng: 17.9848996 },
    { lat: 48.8580236, lng: 17.9847904 },
    { lat: 48.8576774, lng: 17.9844909 },
    { lat: 48.8575595, lng: 17.9843865 },
    { lat: 48.857452, lng: 17.9842882 },
    { lat: 48.8572744, lng: 17.9841447 },
    { lat: 48.857095, lng: 17.9839952 },
    { lat: 48.8569173, lng: 17.9838538 },
    { lat: 48.8567406, lng: 17.9837091 },
    { lat: 48.8565547, lng: 17.9835623 },
    { lat: 48.8563667, lng: 17.9834184 },
    { lat: 48.8562, lng: 17.9832912 },
    { lat: 48.8559023, lng: 17.9830644 },
    { lat: 48.855862, lng: 17.9830354 },
    { lat: 48.8558445, lng: 17.9830238 },
    { lat: 48.8558036, lng: 17.9829996 },
    { lat: 48.8557885, lng: 17.9829931 },
    { lat: 48.8558541, lng: 17.9827663 },
    { lat: 48.8559572, lng: 17.9823916 },
    { lat: 48.8560242, lng: 17.9821531 },
    { lat: 48.8560289, lng: 17.9821367 },
    { lat: 48.8560364, lng: 17.9821113 },
    { lat: 48.8563235, lng: 17.9811088 },
    { lat: 48.8565612, lng: 17.9802825 },
    { lat: 48.8566939, lng: 17.9798172 },
    { lat: 48.8568074, lng: 17.9794289 },
    { lat: 48.8566178, lng: 17.9791981 },
    { lat: 48.8565836, lng: 17.9791562 },
    { lat: 48.8564343, lng: 17.9789797 },
    { lat: 48.8562312, lng: 17.9787332 },
    { lat: 48.8562143, lng: 17.9787122 },
    { lat: 48.8559263, lng: 17.9783668 },
    { lat: 48.8559461, lng: 17.9783011 },
    { lat: 48.8556985, lng: 17.978143 },
    { lat: 48.855567, lng: 17.9780591 },
    { lat: 48.8552364, lng: 17.9778482 },
    { lat: 48.8550472, lng: 17.9777246 },
    { lat: 48.8551042, lng: 17.9776175 },
    { lat: 48.8551082, lng: 17.9776091 },
    { lat: 48.85527, lng: 17.9772347 },
    { lat: 48.8552748, lng: 17.9772237 },
    { lat: 48.8553441, lng: 17.9770755 },
    { lat: 48.8553529, lng: 17.9770521 },
    { lat: 48.85541, lng: 17.9768952 },
    { lat: 48.8554262, lng: 17.9768604 },
    { lat: 48.8554906, lng: 17.9767101 },
    { lat: 48.8555633, lng: 17.9765084 },
    { lat: 48.8556028, lng: 17.9763772 },
    { lat: 48.8556584, lng: 17.9762105 },
    { lat: 48.8557656, lng: 17.9762862 },
    { lat: 48.855869, lng: 17.9763556 },
    { lat: 48.8560479, lng: 17.9764008 },
    { lat: 48.8561972, lng: 17.9763701 },
    { lat: 48.8562875, lng: 17.9763488 },
    { lat: 48.8563822, lng: 17.9763101 },
    { lat: 48.8565318, lng: 17.9761887 },
    { lat: 48.8566323, lng: 17.9761133 },
    { lat: 48.8567225, lng: 17.9759784 },
    { lat: 48.8568667, lng: 17.9756713 },
    { lat: 48.8568924, lng: 17.9756062 },
    { lat: 48.8569172, lng: 17.9754731 },
    { lat: 48.8569415, lng: 17.9753124 },
    { lat: 48.8570533, lng: 17.9753506 },
    { lat: 48.8571376, lng: 17.9753846 },
    { lat: 48.8573239, lng: 17.9754037 },
    { lat: 48.857335, lng: 17.9753237 },
    { lat: 48.8573405, lng: 17.9752853 },
    { lat: 48.857343, lng: 17.9752677 },
    { lat: 48.8573477, lng: 17.9752352 },
    { lat: 48.8573626, lng: 17.9751432 },
    { lat: 48.8573792, lng: 17.9750037 },
    { lat: 48.8574383, lng: 17.9745934 },
    { lat: 48.8575989, lng: 17.9734177 },
    { lat: 48.8576512, lng: 17.9730432 },
    { lat: 48.8577178, lng: 17.9725861 },
    { lat: 48.857759, lng: 17.9721776 },
    { lat: 48.8575415, lng: 17.970171 },
    { lat: 48.8575388, lng: 17.9701409 },
    { lat: 48.8575123, lng: 17.9699058 },
    { lat: 48.8574985, lng: 17.9698238 },
    { lat: 48.8573488, lng: 17.9689556 },
    { lat: 48.8573317, lng: 17.9688587 },
    { lat: 48.8573212, lng: 17.9687974 },
    { lat: 48.8572779, lng: 17.968542 },
    { lat: 48.8571795, lng: 17.9679489 },
    { lat: 48.8571284, lng: 17.967643 },
    { lat: 48.8571186, lng: 17.967588 },
    { lat: 48.8570993, lng: 17.9674903 },
    { lat: 48.8570747, lng: 17.9673628 },
    { lat: 48.8570531, lng: 17.9672457 },
    { lat: 48.8570323, lng: 17.9671439 },
    { lat: 48.8569421, lng: 17.9653024 },
    { lat: 48.8568093, lng: 17.9642047 },
    { lat: 48.8566996, lng: 17.9632909 },
    { lat: 48.8566589, lng: 17.9629485 },
    { lat: 48.856552, lng: 17.9620609 },
    { lat: 48.8563336, lng: 17.9618437 },
    { lat: 48.8561621, lng: 17.9616718 },
    { lat: 48.856011, lng: 17.9615218 },
    { lat: 48.8559771, lng: 17.9614849 },
    { lat: 48.8558938, lng: 17.9613919 },
    { lat: 48.855872, lng: 17.9613677 },
    { lat: 48.8554824, lng: 17.9609291 },
    { lat: 48.8552705, lng: 17.960686 },
    { lat: 48.8550746, lng: 17.9604672 },
    { lat: 48.8546503, lng: 17.9599933 },
    { lat: 48.8546201, lng: 17.9599594 },
    { lat: 48.8544182, lng: 17.9597335 },
    { lat: 48.8541523, lng: 17.959439 },
    { lat: 48.854127, lng: 17.9594106 },
    { lat: 48.8541113, lng: 17.9593934 },
    { lat: 48.8539601, lng: 17.9592259 },
    { lat: 48.8535313, lng: 17.9587726 },
    { lat: 48.853287, lng: 17.9585171 },
    { lat: 48.852966, lng: 17.9581875 },
    { lat: 48.8527299, lng: 17.957976 },
    { lat: 48.8526382, lng: 17.9578928 },
    { lat: 48.852215, lng: 17.9575144 },
    { lat: 48.8519839, lng: 17.9573105 },
    { lat: 48.851845, lng: 17.9570983 },
    { lat: 48.8514494, lng: 17.9564836 },
    { lat: 48.8511595, lng: 17.9560128 },
    { lat: 48.8508232, lng: 17.9554646 },
    { lat: 48.8503742, lng: 17.9547267 },
    { lat: 48.8500008, lng: 17.9541043 },
    { lat: 48.849892, lng: 17.9539264 },
    { lat: 48.8496778, lng: 17.9535542 },
    { lat: 48.8494276, lng: 17.9531204 },
    { lat: 48.8491404, lng: 17.9526192 },
    { lat: 48.8488847, lng: 17.9521732 },
    { lat: 48.8485408, lng: 17.951593 },
    { lat: 48.8483898, lng: 17.9513492 },
    { lat: 48.8483784, lng: 17.95133 },
    { lat: 48.8482338, lng: 17.9511021 },
    { lat: 48.8478397, lng: 17.9502815 },
    { lat: 48.8478347, lng: 17.950274 },
    { lat: 48.8472991, lng: 17.9494491 },
    { lat: 48.8465555, lng: 17.9489025 },
    { lat: 48.8465333, lng: 17.9488912 },
    { lat: 48.8458882, lng: 17.9485949 },
    { lat: 48.8456931, lng: 17.9485044 },
    { lat: 48.8455783, lng: 17.9484516 },
    { lat: 48.8454796, lng: 17.9484064 },
    { lat: 48.8454424, lng: 17.9483892 },
    { lat: 48.8453208, lng: 17.9483163 },
    { lat: 48.8452656, lng: 17.9482805 },
    { lat: 48.8452351, lng: 17.9482606 },
    { lat: 48.8450713, lng: 17.9482228 },
    { lat: 48.845021, lng: 17.9482111 },
    { lat: 48.8449032, lng: 17.9481842 },
    { lat: 48.8448265, lng: 17.9481671 },
    { lat: 48.8447808, lng: 17.9481572 },
    { lat: 48.8446379, lng: 17.9481248 },
    { lat: 48.8445942, lng: 17.9481146 },
    { lat: 48.8445187, lng: 17.9480974 },
    { lat: 48.8444468, lng: 17.9480814 },
    { lat: 48.8444158, lng: 17.9480738 },
    { lat: 48.8443221, lng: 17.9480526 },
    { lat: 48.8442065, lng: 17.9480264 },
    { lat: 48.8441847, lng: 17.9480212 },
    { lat: 48.8440679, lng: 17.9479942 },
    { lat: 48.8439064, lng: 17.947956 },
    { lat: 48.843863, lng: 17.9479455 },
    { lat: 48.8438308, lng: 17.9479381 },
    { lat: 48.8438275, lng: 17.9479371 },
    { lat: 48.8437635, lng: 17.9479226 },
    { lat: 48.8436946, lng: 17.9479062 },
    { lat: 48.84346, lng: 17.9478512 },
    { lat: 48.8439013, lng: 17.9488544 },
    { lat: 48.8443888, lng: 17.9499717 },
    { lat: 48.8448591, lng: 17.9510649 },
    { lat: 48.8443252, lng: 17.9516514 },
    { lat: 48.8439978, lng: 17.9520083 },
    { lat: 48.8439341, lng: 17.9520786 },
    { lat: 48.8445566, lng: 17.9534735 },
    { lat: 48.8442343, lng: 17.9535377 },
    { lat: 48.8435344, lng: 17.9538945 },
    { lat: 48.843303, lng: 17.9540093 },
    { lat: 48.8432898, lng: 17.9540136 },
    { lat: 48.842806, lng: 17.9543255 },
    { lat: 48.8427028, lng: 17.9543923 },
    { lat: 48.8426613, lng: 17.9544187 },
    { lat: 48.8424636, lng: 17.9545454 },
    { lat: 48.8423626, lng: 17.954652 },
    { lat: 48.8423276, lng: 17.9546887 },
    { lat: 48.8422469, lng: 17.9547746 },
    { lat: 48.8421505, lng: 17.9548798 },
    { lat: 48.8419448, lng: 17.9551043 },
    { lat: 48.8419067, lng: 17.9551468 },
    { lat: 48.8418145, lng: 17.9552472 },
    { lat: 48.8417128, lng: 17.9553582 },
    { lat: 48.841552, lng: 17.9550462 },
    { lat: 48.841478, lng: 17.9549036 },
    { lat: 48.8414245, lng: 17.9547988 },
    { lat: 48.8413377, lng: 17.9546325 },
    { lat: 48.8411937, lng: 17.9543537 },
    { lat: 48.8409177, lng: 17.9538543 },
    { lat: 48.8408158, lng: 17.9536962 },
    { lat: 48.8404155, lng: 17.9530934 },
    { lat: 48.8398818, lng: 17.9523218 },
    { lat: 48.8398962, lng: 17.9525989 },
    { lat: 48.8399341, lng: 17.9532549 },
    { lat: 48.8399401, lng: 17.9533569 },
    { lat: 48.8399433, lng: 17.9534102 },
    { lat: 48.839963, lng: 17.9537207 },
    { lat: 48.8400174, lng: 17.9546198 },
    { lat: 48.8400376, lng: 17.9549592 },
    { lat: 48.8400412, lng: 17.9550193 },
    { lat: 48.840044, lng: 17.9550658 },
    { lat: 48.8401214, lng: 17.9563072 },
    { lat: 48.8401427, lng: 17.9565705 },
    { lat: 48.8401567, lng: 17.9567499 },
    { lat: 48.8401652, lng: 17.956857 },
    { lat: 48.8402024, lng: 17.9574621 },
    { lat: 48.8402232, lng: 17.9577915 },
    { lat: 48.8402564, lng: 17.9583097 },
    { lat: 48.8402792, lng: 17.9586778 },
    { lat: 48.8402857, lng: 17.9589174 },
    { lat: 48.8403147, lng: 17.9597085 },
    { lat: 48.8404235, lng: 17.9624129 },
    { lat: 48.8404814, lng: 17.9638448 },
    { lat: 48.8404973, lng: 17.9642034 },
    { lat: 48.8405325, lng: 17.9648558 },
    { lat: 48.8405469, lng: 17.9651139 },
    { lat: 48.8407578, lng: 17.9654553 },
    { lat: 48.841225, lng: 17.9662129 },
    { lat: 48.8419034, lng: 17.9673157 },
    { lat: 48.842212, lng: 17.9678172 },
    { lat: 48.8424819, lng: 17.9684107 },
    { lat: 48.8428386, lng: 17.9691877 },
    { lat: 48.8432538, lng: 17.9700778 },
    { lat: 48.8435307, lng: 17.9709979 },
    { lat: 48.843654, lng: 17.9713462 },
    { lat: 48.8437462, lng: 17.9716085 },
    { lat: 48.8439959, lng: 17.9723127 },
    { lat: 48.8441996, lng: 17.97295 },
    { lat: 48.8443247, lng: 17.9733443 },
    { lat: 48.8444383, lng: 17.9737004 },
    { lat: 48.8447484, lng: 17.9746307 },
    { lat: 48.8450802, lng: 17.9755427 },
    { lat: 48.8451534, lng: 17.9757273 },
    { lat: 48.8452454, lng: 17.9759477 },
    { lat: 48.8456091, lng: 17.9769424 },
    { lat: 48.845747, lng: 17.977325 },
    { lat: 48.8457781, lng: 17.9774114 },
    { lat: 48.8458821, lng: 17.9776814 },
    { lat: 48.8459021, lng: 17.9777314 },
    { lat: 48.8459222, lng: 17.9777867 },
    { lat: 48.8459475, lng: 17.9778517 },
    { lat: 48.84604, lng: 17.9781182 },
    { lat: 48.8461065, lng: 17.978307 },
    { lat: 48.8463307, lng: 17.9789086 },
    { lat: 48.8466913, lng: 17.9799837 },
    { lat: 48.8467935, lng: 17.9805073 },
    { lat: 48.8468378, lng: 17.9807034 },
    { lat: 48.8468655, lng: 17.9808476 },
    { lat: 48.8468777, lng: 17.9817006 },
    { lat: 48.8466826, lng: 17.9827098 },
    { lat: 48.8466582, lng: 17.9831567 },
    { lat: 48.8466525, lng: 17.9832883 },
    { lat: 48.8466611, lng: 17.9836441 },
    { lat: 48.8466737, lng: 17.9839326 },
    { lat: 48.8466796, lng: 17.9840881 },
    { lat: 48.8466905, lng: 17.9842729 },
    { lat: 48.8467183, lng: 17.9847812 },
    { lat: 48.8466975, lng: 17.9850575 },
    { lat: 48.8466764, lng: 17.985332 },
    { lat: 48.8466197, lng: 17.985497 },
    { lat: 48.8465579, lng: 17.9857108 },
    { lat: 48.8464815, lng: 17.9859473 },
    { lat: 48.8464103, lng: 17.98621 },
    { lat: 48.8464063, lng: 17.9862223 },
    { lat: 48.846263, lng: 17.9867535 },
    { lat: 48.8461263, lng: 17.9870968 },
    { lat: 48.846017, lng: 17.9875975 },
    { lat: 48.8460048, lng: 17.9876533 },
    { lat: 48.8460396, lng: 17.9876508 },
    { lat: 48.8462048, lng: 17.9873929 },
    { lat: 48.8463784, lng: 17.9870457 },
    { lat: 48.8464391, lng: 17.9869274 },
    { lat: 48.8464909, lng: 17.9868286 },
    { lat: 48.8465892, lng: 17.9866389 },
    { lat: 48.8466423, lng: 17.9865292 },
    { lat: 48.8468755, lng: 17.9860424 },
    { lat: 48.8470932, lng: 17.9856215 },
    { lat: 48.8472326, lng: 17.9853466 },
    { lat: 48.8472549, lng: 17.9853035 },
    { lat: 48.8475228, lng: 17.9847757 },
    { lat: 48.8476113, lng: 17.984577 },
    { lat: 48.8477349, lng: 17.9842993 },
    { lat: 48.847753, lng: 17.9842628 },
    { lat: 48.8477742, lng: 17.9842205 },
    { lat: 48.8479671, lng: 17.9838355 },
    { lat: 48.8481019, lng: 17.9835602 },
    { lat: 48.8483456, lng: 17.9830064 },
    { lat: 48.8485389, lng: 17.9831793 },
    { lat: 48.8485721, lng: 17.9832092 },
    { lat: 48.8487074, lng: 17.9833413 },
    { lat: 48.8488461, lng: 17.9834715 },
    { lat: 48.849013, lng: 17.9836205 },
    { lat: 48.8490231, lng: 17.9836392 },
    { lat: 48.8490447, lng: 17.9836617 },
    { lat: 48.8491381, lng: 17.9837522 },
    { lat: 48.8491643, lng: 17.9837755 },
    { lat: 48.8492733, lng: 17.9838621 },
    { lat: 48.8494275, lng: 17.9839866 },
    { lat: 48.84956, lng: 17.9840904 },
    { lat: 48.8496408, lng: 17.9841575 },
    { lat: 48.8497034, lng: 17.9842039 },
    { lat: 48.8497193, lng: 17.9843017 },
    { lat: 48.8497211, lng: 17.9843907 },
    { lat: 48.8497228, lng: 17.9844363 },
    { lat: 48.8497286, lng: 17.9844771 },
    { lat: 48.8497667, lng: 17.9846823 },
    { lat: 48.8497848, lng: 17.9847597 },
    { lat: 48.8498595, lng: 17.9849915 },
    { lat: 48.849941, lng: 17.9851488 },
    { lat: 48.850014, lng: 17.9852944 },
    { lat: 48.850143, lng: 17.9854933 },
    { lat: 48.850215, lng: 17.9856025 },
    { lat: 48.8502685, lng: 17.9857021 },
    { lat: 48.8502989, lng: 17.9857606 },
    { lat: 48.8503014, lng: 17.9857635 },
    { lat: 48.8503027, lng: 17.9857698 },
    { lat: 48.8503196, lng: 17.985842 },
    { lat: 48.85032, lng: 17.9858896 },
    { lat: 48.8503216, lng: 17.9859518 },
    { lat: 48.8503386, lng: 17.9859559 },
    { lat: 48.8503868, lng: 17.9859669 },
    { lat: 48.8504244, lng: 17.9859751 },
    { lat: 48.8504488, lng: 17.9859839 },
    { lat: 48.8505413, lng: 17.9862516 },
    { lat: 48.8505751, lng: 17.9863628 },
    { lat: 48.8506078, lng: 17.9864805 },
    { lat: 48.85062, lng: 17.9865337 },
    { lat: 48.8506328, lng: 17.9865943 },
    { lat: 48.8506559, lng: 17.9867008 },
    { lat: 48.8506939, lng: 17.9869039 },
    { lat: 48.8507274, lng: 17.9870699 },
    { lat: 48.8507532, lng: 17.987218 },
    { lat: 48.850781, lng: 17.9873644 },
    { lat: 48.8508039, lng: 17.9874835 },
    { lat: 48.8508184, lng: 17.987555 },
    { lat: 48.8508593, lng: 17.9876756 },
    { lat: 48.8509145, lng: 17.987825 },
    { lat: 48.8509678, lng: 17.9879812 },
    { lat: 48.8510388, lng: 17.988154 },
    { lat: 48.8510742, lng: 17.9882377 },
    { lat: 48.8511334, lng: 17.9883773 },
    { lat: 48.851216, lng: 17.9885349 },
    { lat: 48.8513012, lng: 17.9886957 },
    { lat: 48.8513691, lng: 17.9888925 },
    { lat: 48.8514254, lng: 17.989059 },
    { lat: 48.8514664, lng: 17.9891818 },
    { lat: 48.8515032, lng: 17.9892864 },
    { lat: 48.8515194, lng: 17.9893326 },
    { lat: 48.8515614, lng: 17.9894674 },
    { lat: 48.8514896, lng: 17.9896492 },
    { lat: 48.8513793, lng: 17.9899183 },
    { lat: 48.8514121, lng: 17.9900543 },
    { lat: 48.8514785, lng: 17.9903356 },
    { lat: 48.8515017, lng: 17.9905761 },
    { lat: 48.8515165, lng: 17.9907139 },
    { lat: 48.8515395, lng: 17.9909315 },
    { lat: 48.8515653, lng: 17.9910799 },
    { lat: 48.8516378, lng: 17.9913328 },
    { lat: 48.8518177, lng: 17.991952 },
    { lat: 48.8517243, lng: 17.9928707 },
    { lat: 48.8515782, lng: 17.9944065 },
    { lat: 48.8518786, lng: 17.9946395 },
    { lat: 48.8518522, lng: 17.994765 },
    { lat: 48.8518092, lng: 17.9953177 },
    { lat: 48.8522661, lng: 17.9956176 },
    { lat: 48.853065, lng: 17.9961478 },
    { lat: 48.8537497, lng: 17.9965999 },
    { lat: 48.8537674, lng: 17.9966115 },
    { lat: 48.8537751, lng: 17.9966101 },
    { lat: 48.8545146, lng: 17.9964813 },
    { lat: 48.8549074, lng: 17.9964251 },
    { lat: 48.8550148, lng: 17.9964094 },
    { lat: 48.8551807, lng: 17.9963862 },
    { lat: 48.8552236, lng: 17.9963875 },
    { lat: 48.8552326, lng: 17.9963881 },
    { lat: 48.8552653, lng: 17.9963894 },
    { lat: 48.8552634, lng: 17.9964896 },
    { lat: 48.8552614, lng: 17.9965881 },
    { lat: 48.8552552, lng: 17.9969434 },
    { lat: 48.8551947, lng: 17.9971592 },
    { lat: 48.8551378, lng: 17.9973615 },
    { lat: 48.8551385, lng: 17.9974123 },
    { lat: 48.8551185, lng: 17.9977021 },
    { lat: 48.8551336, lng: 17.9981094 },
    { lat: 48.8551087, lng: 17.9983418 },
    { lat: 48.8550453, lng: 17.9984328 },
    { lat: 48.8549458, lng: 17.9986384 },
    { lat: 48.8546832, lng: 17.999044 },
    { lat: 48.8545272, lng: 17.9992705 },
    { lat: 48.8544262, lng: 17.9994706 },
    { lat: 48.8543239, lng: 17.9997844 },
    { lat: 48.8542581, lng: 18.000103 },
    { lat: 48.8541947, lng: 18.0003675 },
    { lat: 48.8542603, lng: 18.0004081 },
    { lat: 48.8550537, lng: 18.0009033 },
    { lat: 48.8552612, lng: 18.0010328 },
    { lat: 48.8560792, lng: 18.0015436 },
    { lat: 48.8566271, lng: 17.9998546 },
    { lat: 48.858729, lng: 18.0022682 },
    { lat: 48.8587446, lng: 18.0022847 },
    { lat: 48.8587751, lng: 18.0023193 },
    { lat: 48.8588509, lng: 18.0024026 },
    { lat: 48.8588716, lng: 18.0024274 },
    { lat: 48.8590951, lng: 18.0026759 },
    { lat: 48.8595505, lng: 18.0031833 },
    { lat: 48.8599892, lng: 18.0036715 },
    { lat: 48.8601113, lng: 18.0033875 },
    { lat: 48.8603677, lng: 18.002819 },
    { lat: 48.8604682, lng: 18.002591 },
    { lat: 48.8606566, lng: 18.0021697 },
    { lat: 48.8611084, lng: 18.0011617 },
    { lat: 48.8612191, lng: 18.0009144 },
    { lat: 48.8615238, lng: 18.0014308 },
    { lat: 48.8622095, lng: 18.0025855 },
    { lat: 48.8623921, lng: 18.0028909 },
    { lat: 48.8629419, lng: 18.0038099 },
  ],
  Trenčín: [
    { lat: 48.9472194, lng: 17.9879098 },
    { lat: 48.9472137, lng: 17.9878488 },
    { lat: 48.947202, lng: 17.9877675 },
    { lat: 48.9471682, lng: 17.9869279 },
    { lat: 48.9471606, lng: 17.9863217 },
    { lat: 48.9471591, lng: 17.9862368 },
    { lat: 48.9471513, lng: 17.985649 },
    { lat: 48.9470593, lng: 17.98497 },
    { lat: 48.9470569, lng: 17.9849481 },
    { lat: 48.9467431, lng: 17.9851409 },
    { lat: 48.9461154, lng: 17.9853292 },
    { lat: 48.9456799, lng: 17.9856162 },
    { lat: 48.9453628, lng: 17.9846813 },
    { lat: 48.9453164, lng: 17.9840524 },
    { lat: 48.9454054, lng: 17.9831685 },
    { lat: 48.9454717, lng: 17.9825746 },
    { lat: 48.9451863, lng: 17.9816408 },
    { lat: 48.9448958, lng: 17.9812319 },
    { lat: 48.9445322, lng: 17.9805979 },
    { lat: 48.9445412, lng: 17.9805834 },
    { lat: 48.9443677, lng: 17.9802451 },
    { lat: 48.9436857, lng: 17.9793787 },
    { lat: 48.9431394, lng: 17.9786167 },
    { lat: 48.9427719, lng: 17.9778051 },
    { lat: 48.9425319, lng: 17.9771431 },
    { lat: 48.9424211, lng: 17.9762822 },
    { lat: 48.9424847, lng: 17.975946 },
    { lat: 48.9424776, lng: 17.9754433 },
    { lat: 48.9423011, lng: 17.9748667 },
    { lat: 48.9419271, lng: 17.9742463 },
    { lat: 48.9419235, lng: 17.9742062 },
    { lat: 48.9421786, lng: 17.9737133 },
    { lat: 48.9423805, lng: 17.9735762 },
    { lat: 48.942379, lng: 17.9735513 },
    { lat: 48.9429237, lng: 17.9737139 },
    { lat: 48.9428447, lng: 17.9732945 },
    { lat: 48.9427274, lng: 17.9728603 },
    { lat: 48.9424723, lng: 17.9722467 },
    { lat: 48.9421251, lng: 17.9714759 },
    { lat: 48.9417482, lng: 17.9705798 },
    { lat: 48.941621, lng: 17.9702263 },
    { lat: 48.9414879, lng: 17.969712 },
    { lat: 48.9412758, lng: 17.9691622 },
    { lat: 48.9411687, lng: 17.9688598 },
    { lat: 48.9410958, lng: 17.9686382 },
    { lat: 48.9409187, lng: 17.9680499 },
    { lat: 48.9408788, lng: 17.9671685 },
    { lat: 48.9408468, lng: 17.966341 },
    { lat: 48.9409738, lng: 17.9659147 },
    { lat: 48.9410163, lng: 17.9657341 },
    { lat: 48.9411546, lng: 17.9653066 },
    { lat: 48.9416279, lng: 17.9643069 },
    { lat: 48.941646, lng: 17.9642689 },
    { lat: 48.9418257, lng: 17.9638527 },
    { lat: 48.9419118, lng: 17.9635382 },
    { lat: 48.9415799, lng: 17.9633452 },
    { lat: 48.9410563, lng: 17.9630039 },
    { lat: 48.9405816, lng: 17.9627844 },
    { lat: 48.9403053, lng: 17.9625393 },
    { lat: 48.9401804, lng: 17.9621844 },
    { lat: 48.939615, lng: 17.9616916 },
    { lat: 48.9391783, lng: 17.9612756 },
    { lat: 48.9389419, lng: 17.9608868 },
    { lat: 48.9388262, lng: 17.9607238 },
    { lat: 48.9382755, lng: 17.9602513 },
    { lat: 48.937973, lng: 17.9599843 },
    { lat: 48.938, lng: 17.9591832 },
    { lat: 48.9380842, lng: 17.9582641 },
    { lat: 48.9382715, lng: 17.957235 },
    { lat: 48.9383467, lng: 17.9567734 },
    { lat: 48.9384395, lng: 17.9563206 },
    { lat: 48.9384464, lng: 17.9562661 },
    { lat: 48.9384554, lng: 17.9561902 },
    { lat: 48.9384803, lng: 17.9561154 },
    { lat: 48.9385776, lng: 17.9558152 },
    { lat: 48.9386987, lng: 17.955441 },
    { lat: 48.9386954, lng: 17.955309 },
    { lat: 48.9385054, lng: 17.9549017 },
    { lat: 48.9383431, lng: 17.9543749 },
    { lat: 48.938171, lng: 17.9538147 },
    { lat: 48.9379731, lng: 17.9528204 },
    { lat: 48.9377299, lng: 17.9519206 },
    { lat: 48.9376351, lng: 17.9514242 },
    { lat: 48.9374093, lng: 17.9506847 },
    { lat: 48.9370776, lng: 17.9507825 },
    { lat: 48.9367505, lng: 17.9508628 },
    { lat: 48.9364496, lng: 17.9509654 },
    { lat: 48.9361588, lng: 17.9510466 },
    { lat: 48.9358348, lng: 17.9511383 },
    { lat: 48.9357726, lng: 17.9511587 },
    { lat: 48.9355264, lng: 17.9511554 },
    { lat: 48.9354321, lng: 17.9511633 },
    { lat: 48.9353488, lng: 17.9512828 },
    { lat: 48.9351744, lng: 17.9515005 },
    { lat: 48.9351156, lng: 17.9515728 },
    { lat: 48.9350853, lng: 17.9516122 },
    { lat: 48.9350767, lng: 17.9516026 },
    { lat: 48.934979, lng: 17.951495 },
    { lat: 48.9348255, lng: 17.9513232 },
    { lat: 48.9345025, lng: 17.9509797 },
    { lat: 48.934406, lng: 17.9508759 },
    { lat: 48.9341715, lng: 17.9506345 },
    { lat: 48.9338694, lng: 17.9503158 },
    { lat: 48.9335938, lng: 17.9500279 },
    { lat: 48.9335213, lng: 17.9499657 },
    { lat: 48.9332688, lng: 17.9502182 },
    { lat: 48.9330616, lng: 17.9504376 },
    { lat: 48.9329846, lng: 17.950396 },
    { lat: 48.9327789, lng: 17.9502577 },
    { lat: 48.9326701, lng: 17.950325 },
    { lat: 48.9325226, lng: 17.9504062 },
    { lat: 48.9322266, lng: 17.9505745 },
    { lat: 48.9318834, lng: 17.9506661 },
    { lat: 48.9316243, lng: 17.9506764 },
    { lat: 48.9314522, lng: 17.950686 },
    { lat: 48.9314178, lng: 17.9506763 },
    { lat: 48.9310614, lng: 17.9507004 },
    { lat: 48.930998, lng: 17.950755 },
    { lat: 48.9309011, lng: 17.9508322 },
    { lat: 48.930672, lng: 17.9510606 },
    { lat: 48.9304766, lng: 17.9513422 },
    { lat: 48.9302473, lng: 17.9516637 },
    { lat: 48.9300606, lng: 17.9519409 },
    { lat: 48.9297971, lng: 17.9522347 },
    { lat: 48.9295325, lng: 17.9523237 },
    { lat: 48.9291168, lng: 17.9524312 },
    { lat: 48.9287649, lng: 17.9524916 },
    { lat: 48.9286051, lng: 17.952442 },
    { lat: 48.9285504, lng: 17.9524323 },
    { lat: 48.9285027, lng: 17.9524299 },
    { lat: 48.9282314, lng: 17.9524971 },
    { lat: 48.9280155, lng: 17.9525497 },
    { lat: 48.9279511, lng: 17.9525682 },
    { lat: 48.9278628, lng: 17.9525831 },
    { lat: 48.9276072, lng: 17.9525726 },
    { lat: 48.9275272, lng: 17.9526154 },
    { lat: 48.9273748, lng: 17.9527846 },
    { lat: 48.9272115, lng: 17.9529634 },
    { lat: 48.9270516, lng: 17.9528557 },
    { lat: 48.9269414, lng: 17.9527843 },
    { lat: 48.9269163, lng: 17.9527791 },
    { lat: 48.9268767, lng: 17.9527777 },
    { lat: 48.9266833, lng: 17.9527712 },
    { lat: 48.9263196, lng: 17.952995 },
    { lat: 48.9262204, lng: 17.9530524 },
    { lat: 48.9260311, lng: 17.9527169 },
    { lat: 48.9257826, lng: 17.9527692 },
    { lat: 48.9254452, lng: 17.9528481 },
    { lat: 48.9252821, lng: 17.9530125 },
    { lat: 48.9249648, lng: 17.953332 },
    { lat: 48.9246474, lng: 17.9534802 },
    { lat: 48.9245797, lng: 17.953614 },
    { lat: 48.924477, lng: 17.9537842 },
    { lat: 48.9243273, lng: 17.953944 },
    { lat: 48.924304, lng: 17.9539691 },
    { lat: 48.924286, lng: 17.9539887 },
    { lat: 48.9242488, lng: 17.9540276 },
    { lat: 48.9241236, lng: 17.9541807 },
    { lat: 48.9240974, lng: 17.9544047 },
    { lat: 48.9240475, lng: 17.9547787 },
    { lat: 48.9240476, lng: 17.9548203 },
    { lat: 48.9240513, lng: 17.9552807 },
    { lat: 48.9240518, lng: 17.9554206 },
    { lat: 48.9240625, lng: 17.955717 },
    { lat: 48.9240295, lng: 17.9560535 },
    { lat: 48.9240207, lng: 17.9561509 },
    { lat: 48.9238371, lng: 17.9564804 },
    { lat: 48.9237291, lng: 17.9570407 },
    { lat: 48.9236734, lng: 17.9573362 },
    { lat: 48.9235847, lng: 17.957783 },
    { lat: 48.9235528, lng: 17.9579463 },
    { lat: 48.923536, lng: 17.9580617 },
    { lat: 48.9236071, lng: 17.958613 },
    { lat: 48.9234676, lng: 17.9588291 },
    { lat: 48.9233964, lng: 17.9587929 },
    { lat: 48.9233785, lng: 17.9587953 },
    { lat: 48.9232066, lng: 17.9589785 },
    { lat: 48.9230815, lng: 17.9591345 },
    { lat: 48.9228429, lng: 17.959258 },
    { lat: 48.9228286, lng: 17.9592653 },
    { lat: 48.9228148, lng: 17.9591832 },
    { lat: 48.9223885, lng: 17.9592107 },
    { lat: 48.9223548, lng: 17.9592127 },
    { lat: 48.9222637, lng: 17.9592188 },
    { lat: 48.9222279, lng: 17.9592215 },
    { lat: 48.9221918, lng: 17.9592239 },
    { lat: 48.9221012, lng: 17.9592392 },
    { lat: 48.9219602, lng: 17.9592588 },
    { lat: 48.921911, lng: 17.9592656 },
    { lat: 48.9216843, lng: 17.9594796 },
    { lat: 48.9216685, lng: 17.959495 },
    { lat: 48.9215476, lng: 17.9596103 },
    { lat: 48.9214963, lng: 17.9596593 },
    { lat: 48.9215004, lng: 17.9596874 },
    { lat: 48.9215767, lng: 17.9598704 },
    { lat: 48.9216537, lng: 17.9600627 },
    { lat: 48.9216893, lng: 17.9601529 },
    { lat: 48.921573, lng: 17.9602847 },
    { lat: 48.9213946, lng: 17.9604918 },
    { lat: 48.9213674, lng: 17.9605278 },
    { lat: 48.9212113, lng: 17.9608819 },
    { lat: 48.9209348, lng: 17.9615108 },
    { lat: 48.9207812, lng: 17.9618128 },
    { lat: 48.9207387, lng: 17.9618968 },
    { lat: 48.9203818, lng: 17.9621401 },
    { lat: 48.9203242, lng: 17.9621771 },
    { lat: 48.9201462, lng: 17.9618605 },
    { lat: 48.9200655, lng: 17.9617157 },
    { lat: 48.9200452, lng: 17.961678 },
    { lat: 48.9199851, lng: 17.9618078 },
    { lat: 48.9199046, lng: 17.9619735 },
    { lat: 48.9197505, lng: 17.962286 },
    { lat: 48.919708, lng: 17.9623672 },
    { lat: 48.9196806, lng: 17.9624 },
    { lat: 48.9195876, lng: 17.9625634 },
    { lat: 48.9194959, lng: 17.9627109 },
    { lat: 48.9192934, lng: 17.9630447 },
    { lat: 48.9191288, lng: 17.963317 },
    { lat: 48.9190044, lng: 17.9635199 },
    { lat: 48.9188078, lng: 17.9638575 },
    { lat: 48.9187299, lng: 17.963992 },
    { lat: 48.9185123, lng: 17.964358 },
    { lat: 48.9183615, lng: 17.9646115 },
    { lat: 48.9181855, lng: 17.9647651 },
    { lat: 48.9180136, lng: 17.9650258 },
    { lat: 48.9179167, lng: 17.9651822 },
    { lat: 48.9178511, lng: 17.9652928 },
    { lat: 48.9177848, lng: 17.9653955 },
    { lat: 48.9176607, lng: 17.9655836 },
    { lat: 48.9175301, lng: 17.9657813 },
    { lat: 48.917368, lng: 17.965901 },
    { lat: 48.9173116, lng: 17.9659428 },
    { lat: 48.9171392, lng: 17.9660586 },
    { lat: 48.9170014, lng: 17.9661506 },
    { lat: 48.9168217, lng: 17.9663402 },
    { lat: 48.9166059, lng: 17.9665656 },
    { lat: 48.9162852, lng: 17.9669044 },
    { lat: 48.9162183, lng: 17.9669164 },
    { lat: 48.9160422, lng: 17.9669548 },
    { lat: 48.9158749, lng: 17.9669885 },
    { lat: 48.9157, lng: 17.9670271 },
    { lat: 48.91539, lng: 17.9670924 },
    { lat: 48.9153566, lng: 17.9670592 },
    { lat: 48.9152079, lng: 17.966912 },
    { lat: 48.9149636, lng: 17.9666776 },
    { lat: 48.9148548, lng: 17.9665744 },
    { lat: 48.9146966, lng: 17.9666255 },
    { lat: 48.9145769, lng: 17.9666648 },
    { lat: 48.9144918, lng: 17.9670374 },
    { lat: 48.9144617, lng: 17.9671764 },
    { lat: 48.9143145, lng: 17.9674165 },
    { lat: 48.9142077, lng: 17.9675947 },
    { lat: 48.9143667, lng: 17.9678594 },
    { lat: 48.9145144, lng: 17.9681044 },
    { lat: 48.9145356, lng: 17.9681381 },
    { lat: 48.9145314, lng: 17.9681631 },
    { lat: 48.9144342, lng: 17.9686177 },
    { lat: 48.9143563, lng: 17.96898 },
    { lat: 48.9143271, lng: 17.9689424 },
    { lat: 48.9141997, lng: 17.9687714 },
    { lat: 48.914157, lng: 17.9687169 },
    { lat: 48.9139309, lng: 17.9684274 },
    { lat: 48.9137736, lng: 17.9682314 },
    { lat: 48.9135874, lng: 17.9681187 },
    { lat: 48.9134152, lng: 17.9682771 },
    { lat: 48.9134133, lng: 17.9682813 },
    { lat: 48.9133329, lng: 17.9685079 },
    { lat: 48.9133565, lng: 17.9685998 },
    { lat: 48.9134224, lng: 17.9688377 },
    { lat: 48.9134616, lng: 17.9689834 },
    { lat: 48.9135894, lng: 17.969177 },
    { lat: 48.9137169, lng: 17.9693677 },
    { lat: 48.91389, lng: 17.9696239 },
    { lat: 48.9138147, lng: 17.9698366 },
    { lat: 48.9137924, lng: 17.9698999 },
    { lat: 48.9137615, lng: 17.9699845 },
    { lat: 48.9137169, lng: 17.9701112 },
    { lat: 48.9137105, lng: 17.9701347 },
    { lat: 48.91361, lng: 17.9702094 },
    { lat: 48.9135549, lng: 17.9702506 },
    { lat: 48.9135181, lng: 17.970279 },
    { lat: 48.9134241, lng: 17.97035 },
    { lat: 48.913341, lng: 17.970413 },
    { lat: 48.9132273, lng: 17.9704773 },
    { lat: 48.9131688, lng: 17.9708021 },
    { lat: 48.9131446, lng: 17.9709447 },
    { lat: 48.9131272, lng: 17.9710339 },
    { lat: 48.912965, lng: 17.9711149 },
    { lat: 48.9127886, lng: 17.9712032 },
    { lat: 48.9127269, lng: 17.9712327 },
    { lat: 48.9126089, lng: 17.9713917 },
    { lat: 48.91247, lng: 17.9715822 },
    { lat: 48.912465, lng: 17.9715889 },
    { lat: 48.9123584, lng: 17.9717337 },
    { lat: 48.9122291, lng: 17.9718199 },
    { lat: 48.9120849, lng: 17.9719196 },
    { lat: 48.912058, lng: 17.9719416 },
    { lat: 48.9119008, lng: 17.9720499 },
    { lat: 48.9117424, lng: 17.9721601 },
    { lat: 48.9115798, lng: 17.9722731 },
    { lat: 48.9113697, lng: 17.9724198 },
    { lat: 48.9111484, lng: 17.9725724 },
    { lat: 48.9110922, lng: 17.9726131 },
    { lat: 48.9110306, lng: 17.9726595 },
    { lat: 48.9108266, lng: 17.9727975 },
    { lat: 48.9105143, lng: 17.9727827 },
    { lat: 48.9103122, lng: 17.9730324 },
    { lat: 48.9101159, lng: 17.9732809 },
    { lat: 48.9098255, lng: 17.9734095 },
    { lat: 48.9098119, lng: 17.973407 },
    { lat: 48.9094522, lng: 17.9734511 },
    { lat: 48.9089816, lng: 17.9735085 },
    { lat: 48.9087765, lng: 17.9734392 },
    { lat: 48.9086189, lng: 17.9733865 },
    { lat: 48.9085002, lng: 17.9733463 },
    { lat: 48.9082988, lng: 17.9732802 },
    { lat: 48.9080312, lng: 17.973072 },
    { lat: 48.907971, lng: 17.973026 },
    { lat: 48.9079143, lng: 17.9729831 },
    { lat: 48.9079211, lng: 17.972946 },
    { lat: 48.9079456, lng: 17.9728088 },
    { lat: 48.9078596, lng: 17.9724223 },
    { lat: 48.9077453, lng: 17.9722829 },
    { lat: 48.9076477, lng: 17.9721591 },
    { lat: 48.9076211, lng: 17.9721308 },
    { lat: 48.9075315, lng: 17.9720673 },
    { lat: 48.9073228, lng: 17.9719136 },
    { lat: 48.9070781, lng: 17.9721464 },
    { lat: 48.9069894, lng: 17.972518 },
    { lat: 48.9069362, lng: 17.9727456 },
    { lat: 48.9069226, lng: 17.9727998 },
    { lat: 48.9066729, lng: 17.9728726 },
    { lat: 48.9066353, lng: 17.9728839 },
    { lat: 48.9065732, lng: 17.9729017 },
    { lat: 48.9064163, lng: 17.9728833 },
    { lat: 48.9062718, lng: 17.9728642 },
    { lat: 48.9062594, lng: 17.9728623 },
    { lat: 48.9060145, lng: 17.9728652 },
    { lat: 48.9058911, lng: 17.9728376 },
    { lat: 48.9058635, lng: 17.9728317 },
    { lat: 48.905843, lng: 17.9728277 },
    { lat: 48.9056408, lng: 17.9727853 },
    { lat: 48.9056227, lng: 17.9727867 },
    { lat: 48.9054207, lng: 17.9729771 },
    { lat: 48.9051845, lng: 17.9731981 },
    { lat: 48.9049856, lng: 17.9733859 },
    { lat: 48.904709, lng: 17.9737071 },
    { lat: 48.9046033, lng: 17.9738306 },
    { lat: 48.9044471, lng: 17.974014 },
    { lat: 48.9042527, lng: 17.9742372 },
    { lat: 48.9039328, lng: 17.9746105 },
    { lat: 48.9038403, lng: 17.9747243 },
    { lat: 48.9037275, lng: 17.9748767 },
    { lat: 48.9035433, lng: 17.9751017 },
    { lat: 48.9033508, lng: 17.9753403 },
    { lat: 48.9032698, lng: 17.9754389 },
    { lat: 48.9029808, lng: 17.975799 },
    { lat: 48.9026423, lng: 17.9762205 },
    { lat: 48.9023911, lng: 17.9765356 },
    { lat: 48.9023589, lng: 17.9765831 },
    { lat: 48.9022923, lng: 17.9766757 },
    { lat: 48.9021686, lng: 17.9768365 },
    { lat: 48.902152, lng: 17.9768585 },
    { lat: 48.9019981, lng: 17.9770082 },
    { lat: 48.9019746, lng: 17.9770304 },
    { lat: 48.9019503, lng: 17.9770553 },
    { lat: 48.9018669, lng: 17.977137 },
    { lat: 48.9016655, lng: 17.9772222 },
    { lat: 48.9013682, lng: 17.9773467 },
    { lat: 48.9010663, lng: 17.9774725 },
    { lat: 48.9009792, lng: 17.9775066 },
    { lat: 48.9008953, lng: 17.9775388 },
    { lat: 48.9006867, lng: 17.9776197 },
    { lat: 48.9005665, lng: 17.9776658 },
    { lat: 48.9004285, lng: 17.9777166 },
    { lat: 48.9002237, lng: 17.9776523 },
    { lat: 48.9000359, lng: 17.9775893 },
    { lat: 48.8997455, lng: 17.9777135 },
    { lat: 48.8996032, lng: 17.9777713 },
    { lat: 48.8996285, lng: 17.9779099 },
    { lat: 48.8996312, lng: 17.9779199 },
    { lat: 48.8995398, lng: 17.978054 },
    { lat: 48.8994183, lng: 17.9782313 },
    { lat: 48.899297, lng: 17.9783547 },
    { lat: 48.8991997, lng: 17.9785603 },
    { lat: 48.8991171, lng: 17.9787283 },
    { lat: 48.8990586, lng: 17.9788095 },
    { lat: 48.8990353, lng: 17.9787961 },
    { lat: 48.8990353, lng: 17.9787954 },
    { lat: 48.8990002, lng: 17.9787744 },
    { lat: 48.8989965, lng: 17.9787709 },
    { lat: 48.8988869, lng: 17.9786739 },
    { lat: 48.898667, lng: 17.9789231 },
    { lat: 48.8985176, lng: 17.9786324 },
    { lat: 48.8984226, lng: 17.9787978 },
    { lat: 48.8983378, lng: 17.9789471 },
    { lat: 48.8982945, lng: 17.9790384 },
    { lat: 48.8982263, lng: 17.9791808 },
    { lat: 48.8980781, lng: 17.9794822 },
    { lat: 48.8980413, lng: 17.9796232 },
    { lat: 48.8979674, lng: 17.979917 },
    { lat: 48.8978986, lng: 17.9801843 },
    { lat: 48.8977912, lng: 17.9803916 },
    { lat: 48.8977193, lng: 17.9807601 },
    { lat: 48.8976756, lng: 17.9809924 },
    { lat: 48.8976467, lng: 17.9811345 },
    { lat: 48.8976133, lng: 17.9815422 },
    { lat: 48.8976025, lng: 17.9816674 },
    { lat: 48.8975757, lng: 17.9819932 },
    { lat: 48.8973975, lng: 17.9819587 },
    { lat: 48.8972272, lng: 17.9817519 },
    { lat: 48.8969863, lng: 17.981458 },
    { lat: 48.8968696, lng: 17.9813157 },
    { lat: 48.896838, lng: 17.9812773 },
    { lat: 48.8967903, lng: 17.9812354 },
    { lat: 48.8966153, lng: 17.9810791 },
    { lat: 48.8962875, lng: 17.9807877 },
    { lat: 48.8962354, lng: 17.9808995 },
    { lat: 48.8960626, lng: 17.980725 },
    { lat: 48.8958386, lng: 17.9805045 },
    { lat: 48.89581, lng: 17.9805772 },
    { lat: 48.8958101, lng: 17.9805783 },
    { lat: 48.8957041, lng: 17.980846 },
    { lat: 48.8955947, lng: 17.9806557 },
    { lat: 48.8954916, lng: 17.9804804 },
    { lat: 48.8954602, lng: 17.9805367 },
    { lat: 48.8954467, lng: 17.9805607 },
    { lat: 48.8954202, lng: 17.9806088 },
    { lat: 48.8953838, lng: 17.9806758 },
    { lat: 48.8953247, lng: 17.980785 },
    { lat: 48.8952035, lng: 17.9805666 },
    { lat: 48.8951024, lng: 17.980386 },
    { lat: 48.894987, lng: 17.980171 },
    { lat: 48.8948546, lng: 17.9799355 },
    { lat: 48.8947294, lng: 17.9797111 },
    { lat: 48.8945842, lng: 17.9794508 },
    { lat: 48.8945153, lng: 17.9792586 },
    { lat: 48.8944599, lng: 17.9791054 },
    { lat: 48.894383, lng: 17.9788939 },
    { lat: 48.8943239, lng: 17.9787337 },
    { lat: 48.8943118, lng: 17.9787013 },
    { lat: 48.8942785, lng: 17.9786154 },
    { lat: 48.8942803, lng: 17.9787407 },
    { lat: 48.8942765, lng: 17.9790763 },
    { lat: 48.8942704, lng: 17.9793368 },
    { lat: 48.8942745, lng: 17.979385 },
    { lat: 48.8943184, lng: 17.9798999 },
    { lat: 48.8943548, lng: 17.9803228 },
    { lat: 48.894374, lng: 17.9805311 },
    { lat: 48.8943995, lng: 17.980829 },
    { lat: 48.8944161, lng: 17.9810136 },
    { lat: 48.894417, lng: 17.9810285 },
    { lat: 48.8944664, lng: 17.9814992 },
    { lat: 48.8944713, lng: 17.981547 },
    { lat: 48.8944668, lng: 17.9815662 },
    { lat: 48.8944494, lng: 17.9816321 },
    { lat: 48.8943661, lng: 17.9819623 },
    { lat: 48.8943629, lng: 17.9819642 },
    { lat: 48.8943394, lng: 17.981967 },
    { lat: 48.8943472, lng: 17.9820427 },
    { lat: 48.8943559, lng: 17.9821344 },
    { lat: 48.8943667, lng: 17.9822366 },
    { lat: 48.8943741, lng: 17.9823049 },
    { lat: 48.8943732, lng: 17.9823308 },
    { lat: 48.8943728, lng: 17.9824352 },
    { lat: 48.8943712, lng: 17.9825241 },
    { lat: 48.8943716, lng: 17.9825663 },
    { lat: 48.894369, lng: 17.9825821 },
    { lat: 48.8943643, lng: 17.9826154 },
    { lat: 48.8943594, lng: 17.982648 },
    { lat: 48.8943497, lng: 17.9827128 },
    { lat: 48.8943344, lng: 17.9828178 },
    { lat: 48.8943217, lng: 17.9829056 },
    { lat: 48.8943127, lng: 17.9829668 },
    { lat: 48.8943055, lng: 17.9829957 },
    { lat: 48.8942881, lng: 17.9830655 },
    { lat: 48.8942756, lng: 17.9831178 },
    { lat: 48.8942679, lng: 17.9831443 },
    { lat: 48.8942498, lng: 17.9832185 },
    { lat: 48.8942244, lng: 17.9833239 },
    { lat: 48.8942081, lng: 17.9833928 },
    { lat: 48.894183, lng: 17.9834995 },
    { lat: 48.8941759, lng: 17.983531 },
    { lat: 48.8941446, lng: 17.9836547 },
    { lat: 48.894113, lng: 17.9837878 },
    { lat: 48.893872, lng: 17.9835838 },
    { lat: 48.8937322, lng: 17.9834759 },
    { lat: 48.89369, lng: 17.9834465 },
    { lat: 48.8935818, lng: 17.9833561 },
    { lat: 48.8933598, lng: 17.9831697 },
    { lat: 48.8930198, lng: 17.9828799 },
    { lat: 48.8927689, lng: 17.9826648 },
    { lat: 48.8924256, lng: 17.9823744 },
    { lat: 48.892114, lng: 17.9821052 },
    { lat: 48.8920592, lng: 17.9820581 },
    { lat: 48.8919497, lng: 17.9819636 },
    { lat: 48.8917395, lng: 17.9817846 },
    { lat: 48.8915919, lng: 17.9816566 },
    { lat: 48.8915371, lng: 17.9816099 },
    { lat: 48.8913377, lng: 17.9814412 },
    { lat: 48.8912119, lng: 17.9816821 },
    { lat: 48.8910162, lng: 17.9820553 },
    { lat: 48.8908232, lng: 17.9824183 },
    { lat: 48.8907729, lng: 17.9825027 },
    { lat: 48.8906871, lng: 17.9826169 },
    { lat: 48.8906509, lng: 17.982652 },
    { lat: 48.8905669, lng: 17.982724 },
    { lat: 48.8905102, lng: 17.9827716 },
    { lat: 48.8903843, lng: 17.9828788 },
    { lat: 48.8903715, lng: 17.9827736 },
    { lat: 48.8901751, lng: 17.9828524 },
    { lat: 48.8900776, lng: 17.9828445 },
    { lat: 48.8897768, lng: 17.9828159 },
    { lat: 48.8895296, lng: 17.982795 },
    { lat: 48.88936, lng: 17.9828321 },
    { lat: 48.8890838, lng: 17.9829881 },
    { lat: 48.8886589, lng: 17.9832249 },
    { lat: 48.8883145, lng: 17.9834156 },
    { lat: 48.8880316, lng: 17.9835754 },
    { lat: 48.887785, lng: 17.9835257 },
    { lat: 48.8874386, lng: 17.9834513 },
    { lat: 48.8871551, lng: 17.983392 },
    { lat: 48.887124, lng: 17.9833823 },
    { lat: 48.8870103, lng: 17.9832719 },
    { lat: 48.8870004, lng: 17.9832556 },
    { lat: 48.8869438, lng: 17.9831195 },
    { lat: 48.8869288, lng: 17.9830775 },
    { lat: 48.8869274, lng: 17.9830533 },
    { lat: 48.8869081, lng: 17.9826872 },
    { lat: 48.886887, lng: 17.9822952 },
    { lat: 48.8868521, lng: 17.9821049 },
    { lat: 48.8868177, lng: 17.9819245 },
    { lat: 48.8867371, lng: 17.9820126 },
    { lat: 48.8867022, lng: 17.9820503 },
    { lat: 48.8863958, lng: 17.9841406 },
    { lat: 48.8859226, lng: 17.9840591 },
    { lat: 48.8858095, lng: 17.9843018 },
    { lat: 48.8857884, lng: 17.9843449 },
    { lat: 48.8857164, lng: 17.9845024 },
    { lat: 48.8856015, lng: 17.984583 },
    { lat: 48.8855886, lng: 17.984593 },
    { lat: 48.8855228, lng: 17.9846432 },
    { lat: 48.885471, lng: 17.9846832 },
    { lat: 48.8848244, lng: 17.9854871 },
    { lat: 48.8836197, lng: 17.9869873 },
    { lat: 48.8829637, lng: 17.9878027 },
    { lat: 48.8821549, lng: 17.9888028 },
    { lat: 48.8821036, lng: 17.9888676 },
    { lat: 48.8816086, lng: 17.9885526 },
    { lat: 48.8813774, lng: 17.9884019 },
    { lat: 48.8813267, lng: 17.9883694 },
    { lat: 48.8812481, lng: 17.9883159 },
    { lat: 48.8811767, lng: 17.9882922 },
    { lat: 48.880307, lng: 17.9876152 },
    { lat: 48.8800622, lng: 17.987443 },
    { lat: 48.8799772, lng: 17.9873829 },
    { lat: 48.8799385, lng: 17.9873552 },
    { lat: 48.879883, lng: 17.9873165 },
    { lat: 48.8798125, lng: 17.987268 },
    { lat: 48.8797818, lng: 17.9872463 },
    { lat: 48.8797429, lng: 17.9872193 },
    { lat: 48.87961, lng: 17.9871277 },
    { lat: 48.8791092, lng: 17.9867706 },
    { lat: 48.8790633, lng: 17.9867377 },
    { lat: 48.8790129, lng: 17.9867252 },
    { lat: 48.878963, lng: 17.9867015 },
    { lat: 48.8788412, lng: 17.986646 },
    { lat: 48.8787923, lng: 17.9866229 },
    { lat: 48.8782562, lng: 17.9863771 },
    { lat: 48.8778788, lng: 17.9861986 },
    { lat: 48.8778519, lng: 17.9861639 },
    { lat: 48.877489, lng: 17.9857303 },
    { lat: 48.8772399, lng: 17.9854336 },
    { lat: 48.8763144, lng: 17.9843071 },
    { lat: 48.8756809, lng: 17.9835984 },
    { lat: 48.8753071, lng: 17.9831717 },
    { lat: 48.8739754, lng: 17.9816893 },
    { lat: 48.8739402, lng: 17.98165 },
    { lat: 48.8738535, lng: 17.9815546 },
    { lat: 48.8735551, lng: 17.9812058 },
    { lat: 48.8734434, lng: 17.9811117 },
    { lat: 48.8732484, lng: 17.9809263 },
    { lat: 48.872888, lng: 17.9805756 },
    { lat: 48.8728218, lng: 17.980524 },
    { lat: 48.8724002, lng: 17.9801146 },
    { lat: 48.8723702, lng: 17.9800872 },
    { lat: 48.8711636, lng: 17.9789447 },
    { lat: 48.8700668, lng: 17.9779005 },
    { lat: 48.869878, lng: 17.9781589 },
    { lat: 48.8697381, lng: 17.9783537 },
    { lat: 48.8695673, lng: 17.9785963 },
    { lat: 48.8694488, lng: 17.9787666 },
    { lat: 48.8693001, lng: 17.9789794 },
    { lat: 48.8692731, lng: 17.9790186 },
    { lat: 48.8692595, lng: 17.9790373 },
    { lat: 48.8686924, lng: 17.9798273 },
    { lat: 48.868607, lng: 17.9799482 },
    { lat: 48.8685772, lng: 17.9811056 },
    { lat: 48.8685545, lng: 17.9822631 },
    { lat: 48.8685537, lng: 17.9822887 },
    { lat: 48.869124, lng: 17.982542 },
    { lat: 48.8695647, lng: 17.9827232 },
    { lat: 48.8695627, lng: 17.982745 },
    { lat: 48.8695536, lng: 17.9828592 },
    { lat: 48.8695469, lng: 17.9830684 },
    { lat: 48.8695178, lng: 17.9831685 },
    { lat: 48.8691972, lng: 17.9837384 },
    { lat: 48.869188, lng: 17.9837547 },
    { lat: 48.8691747, lng: 17.9837773 },
    { lat: 48.8691568, lng: 17.9838188 },
    { lat: 48.8690241, lng: 17.9841129 },
    { lat: 48.868942, lng: 17.9843084 },
    { lat: 48.868511, lng: 17.9853218 },
    { lat: 48.868416, lng: 17.9856032 },
    { lat: 48.8684065, lng: 17.9856335 },
    { lat: 48.8684005, lng: 17.9856838 },
    { lat: 48.8681834, lng: 17.987279 },
    { lat: 48.8679562, lng: 17.9887991 },
    { lat: 48.8679563, lng: 17.9888206 },
    { lat: 48.8679086, lng: 17.9891207 },
    { lat: 48.8678973, lng: 17.9892144 },
    { lat: 48.8679087, lng: 17.989391 },
    { lat: 48.8679385, lng: 17.9898336 },
    { lat: 48.8679784, lng: 17.9904316 },
    { lat: 48.867935, lng: 17.9909387 },
    { lat: 48.8679294, lng: 17.9909989 },
    { lat: 48.8679263, lng: 17.9910395 },
    { lat: 48.8678589, lng: 17.9914674 },
    { lat: 48.8677844, lng: 17.9919425 },
    { lat: 48.8677278, lng: 17.9923015 },
    { lat: 48.8676027, lng: 17.9929169 },
    { lat: 48.867549, lng: 17.9930773 },
    { lat: 48.8674971, lng: 17.9932317 },
    { lat: 48.8673488, lng: 17.9936028 },
    { lat: 48.8672595, lng: 17.9938286 },
    { lat: 48.8671961, lng: 17.9940007 },
    { lat: 48.8671889, lng: 17.9939902 },
    { lat: 48.8671863, lng: 17.9939869 },
    { lat: 48.8665226, lng: 17.9930687 },
    { lat: 48.866401, lng: 17.992901 },
    { lat: 48.8662805, lng: 17.9927352 },
    { lat: 48.866246, lng: 17.9926872 },
    { lat: 48.8658638, lng: 17.9921591 },
    { lat: 48.8657125, lng: 17.9919492 },
    { lat: 48.8655443, lng: 17.9917165 },
    { lat: 48.8654349, lng: 17.9915664 },
    { lat: 48.8654092, lng: 17.9915717 },
    { lat: 48.8654015, lng: 17.9915735 },
    { lat: 48.8652554, lng: 17.991603 },
    { lat: 48.8650491, lng: 17.9916447 },
    { lat: 48.8647857, lng: 17.9916981 },
    { lat: 48.8645616, lng: 17.9917436 },
    { lat: 48.8643096, lng: 17.9917943 },
    { lat: 48.864199, lng: 17.9918165 },
    { lat: 48.8637233, lng: 17.9919181 },
    { lat: 48.8631974, lng: 17.9919799 },
    { lat: 48.8632883, lng: 17.9921769 },
    { lat: 48.8633303, lng: 17.99224 },
    { lat: 48.8633561, lng: 17.9922767 },
    { lat: 48.8633933, lng: 17.9923322 },
    { lat: 48.8634183, lng: 17.9923715 },
    { lat: 48.8634824, lng: 17.9924646 },
    { lat: 48.8635752, lng: 17.9926021 },
    { lat: 48.8636469, lng: 17.9927103 },
    { lat: 48.8637211, lng: 17.9928164 },
    { lat: 48.8637694, lng: 17.9928894 },
    { lat: 48.8639424, lng: 17.9931437 },
    { lat: 48.8636505, lng: 17.9941617 },
    { lat: 48.8636431, lng: 17.9941888 },
    { lat: 48.86363, lng: 17.9942358 },
    { lat: 48.8636238, lng: 17.9942603 },
    { lat: 48.8651481, lng: 17.9948661 },
    { lat: 48.865776, lng: 17.995714 },
    { lat: 48.8658511, lng: 17.9958156 },
    { lat: 48.8659615, lng: 17.995967 },
    { lat: 48.8659911, lng: 17.9960074 },
    { lat: 48.8660229, lng: 17.9960501 },
    { lat: 48.8660818, lng: 17.9961302 },
    { lat: 48.8661749, lng: 17.9962567 },
    { lat: 48.8661787, lng: 17.9962615 },
    { lat: 48.8662057, lng: 17.996297 },
    { lat: 48.8662216, lng: 17.9963185 },
    { lat: 48.8662709, lng: 17.9963885 },
    { lat: 48.866299, lng: 17.996427 },
    { lat: 48.8663249, lng: 17.9964614 },
    { lat: 48.8663728, lng: 17.996527 },
    { lat: 48.8664008, lng: 17.9965655 },
    { lat: 48.8664193, lng: 17.9965902 },
    { lat: 48.8664647, lng: 17.9966504 },
    { lat: 48.8664758, lng: 17.9966657 },
    { lat: 48.8665127, lng: 17.9967184 },
    { lat: 48.8665239, lng: 17.9967348 },
    { lat: 48.866546, lng: 17.9967641 },
    { lat: 48.8666034, lng: 17.9968398 },
    { lat: 48.8659291, lng: 17.9980718 },
    { lat: 48.865652, lng: 17.9985779 },
    { lat: 48.8653462, lng: 17.9991342 },
    { lat: 48.8649491, lng: 17.9998617 },
    { lat: 48.8648301, lng: 18.0000808 },
    { lat: 48.8643668, lng: 18.0009137 },
    { lat: 48.8640594, lng: 18.0014594 },
    { lat: 48.8640429, lng: 18.0014893 },
    { lat: 48.8641804, lng: 18.0017348 },
    { lat: 48.8641694, lng: 18.0017557 },
    { lat: 48.863687, lng: 18.0026108 },
    { lat: 48.8635544, lng: 18.0028472 },
    { lat: 48.8635234, lng: 18.0028592 },
    { lat: 48.8634959, lng: 18.0028705 },
    { lat: 48.8634584, lng: 18.0028838 },
    { lat: 48.8633866, lng: 18.0030065 },
    { lat: 48.8632111, lng: 18.0033225 },
    { lat: 48.8630581, lng: 18.0035992 },
    { lat: 48.8630337, lng: 18.003643 },
    { lat: 48.8630113, lng: 18.0036833 },
    { lat: 48.8630032, lng: 18.0036984 },
    { lat: 48.8629419, lng: 18.0038099 },
    { lat: 48.8629739, lng: 18.0037956 },
    { lat: 48.8630042, lng: 18.003775 },
    { lat: 48.8630547, lng: 18.003866 },
    { lat: 48.8631224, lng: 18.0039787 },
    { lat: 48.8631694, lng: 18.004049 },
    { lat: 48.8631874, lng: 18.0041057 },
    { lat: 48.8631804, lng: 18.0045802 },
    { lat: 48.8631179, lng: 18.0052403 },
    { lat: 48.8631131, lng: 18.0052955 },
    { lat: 48.8631018, lng: 18.0054637 },
    { lat: 48.8630917, lng: 18.0055819 },
    { lat: 48.8630868, lng: 18.0057267 },
    { lat: 48.8630789, lng: 18.0059181 },
    { lat: 48.8630773, lng: 18.0059868 },
    { lat: 48.8630656, lng: 18.0060973 },
    { lat: 48.8630088, lng: 18.0062402 },
    { lat: 48.8629959, lng: 18.0062703 },
    { lat: 48.8629342, lng: 18.0064708 },
    { lat: 48.8629051, lng: 18.0064546 },
    { lat: 48.8627714, lng: 18.0066538 },
    { lat: 48.8627223, lng: 18.0067243 },
    { lat: 48.8626604, lng: 18.0068001 },
    { lat: 48.8625194, lng: 18.0069561 },
    { lat: 48.8623406, lng: 18.0071567 },
    { lat: 48.862137, lng: 18.007493 },
    { lat: 48.8619061, lng: 18.0080379 },
    { lat: 48.8617109, lng: 18.0087286 },
    { lat: 48.8612542, lng: 18.0100351 },
    { lat: 48.861114, lng: 18.0103175 },
    { lat: 48.860968, lng: 18.0105205 },
    { lat: 48.8606082, lng: 18.0111758 },
    { lat: 48.8604401, lng: 18.0114057 },
    { lat: 48.8602452, lng: 18.0117931 },
    { lat: 48.8600132, lng: 18.0122998 },
    { lat: 48.8599845, lng: 18.0123693 },
    { lat: 48.8598808, lng: 18.0126213 },
    { lat: 48.8595585, lng: 18.0136446 },
    { lat: 48.8594396, lng: 18.0140382 },
    { lat: 48.8593163, lng: 18.0144545 },
    { lat: 48.8592185, lng: 18.0147853 },
    { lat: 48.8590841, lng: 18.0152448 },
    { lat: 48.8587612, lng: 18.0163337 },
    { lat: 48.8585211, lng: 18.0171393 },
    { lat: 48.8582434, lng: 18.0180776 },
    { lat: 48.8582279, lng: 18.0181238 },
    { lat: 48.8590628, lng: 18.0185025 },
    { lat: 48.859113, lng: 18.0184907 },
    { lat: 48.8592131, lng: 18.0184632 },
    { lat: 48.8592659, lng: 18.0184249 },
    { lat: 48.8592402, lng: 18.018638 },
    { lat: 48.8592175, lng: 18.0186956 },
    { lat: 48.8592074, lng: 18.0187317 },
    { lat: 48.859153, lng: 18.0189405 },
    { lat: 48.8591421, lng: 18.0189847 },
    { lat: 48.8589323, lng: 18.0198102 },
    { lat: 48.8585718, lng: 18.0198583 },
    { lat: 48.8585395, lng: 18.0200006 },
    { lat: 48.8583187, lng: 18.0199692 },
    { lat: 48.8582889, lng: 18.0199819 },
    { lat: 48.8582242, lng: 18.0200107 },
    { lat: 48.8580755, lng: 18.020076 },
    { lat: 48.8579237, lng: 18.0201417 },
    { lat: 48.8577741, lng: 18.0202071 },
    { lat: 48.8576244, lng: 18.0202725 },
    { lat: 48.8575253, lng: 18.020316 },
    { lat: 48.8574746, lng: 18.0203351 },
    { lat: 48.8573245, lng: 18.0203916 },
    { lat: 48.8571743, lng: 18.0204478 },
    { lat: 48.8570549, lng: 18.0204926 },
    { lat: 48.8570242, lng: 18.0205047 },
    { lat: 48.8568752, lng: 18.0205621 },
    { lat: 48.8567564, lng: 18.0207287 },
    { lat: 48.8567312, lng: 18.0207422 },
    { lat: 48.8566796, lng: 18.0207703 },
    { lat: 48.8565691, lng: 18.0207497 },
    { lat: 48.8565313, lng: 18.0207423 },
    { lat: 48.8565153, lng: 18.0207395 },
    { lat: 48.8564163, lng: 18.0207615 },
    { lat: 48.8562648, lng: 18.0207952 },
    { lat: 48.8561712, lng: 18.0208158 },
    { lat: 48.8561056, lng: 18.0208548 },
    { lat: 48.8559401, lng: 18.0209549 },
    { lat: 48.8557656, lng: 18.0210599 },
    { lat: 48.8555324, lng: 18.0212008 },
    { lat: 48.8555161, lng: 18.0212295 },
    { lat: 48.855511, lng: 18.0212388 },
    { lat: 48.8552933, lng: 18.0216227 },
    { lat: 48.8551642, lng: 18.0216552 },
    { lat: 48.8551206, lng: 18.0217385 },
    { lat: 48.8549485, lng: 18.0213855 },
    { lat: 48.8547961, lng: 18.0215366 },
    { lat: 48.8546166, lng: 18.0216698 },
    { lat: 48.8546001, lng: 18.0216986 },
    { lat: 48.8544898, lng: 18.0218375 },
    { lat: 48.8543892, lng: 18.021969 },
    { lat: 48.8542976, lng: 18.0221191 },
    { lat: 48.8542061, lng: 18.0222687 },
    { lat: 48.8541186, lng: 18.0224128 },
    { lat: 48.8540682, lng: 18.0225931 },
    { lat: 48.8539899, lng: 18.0227571 },
    { lat: 48.8539265, lng: 18.0229065 },
    { lat: 48.8538757, lng: 18.02308 },
    { lat: 48.8538416, lng: 18.023267 },
    { lat: 48.8537092, lng: 18.0237784 },
    { lat: 48.8536547, lng: 18.0242338 },
    { lat: 48.853631, lng: 18.024366 },
    { lat: 48.8536281, lng: 18.0244693 },
    { lat: 48.8536291, lng: 18.0246021 },
    { lat: 48.8536338, lng: 18.0247219 },
    { lat: 48.8536144, lng: 18.0249679 },
    { lat: 48.8535216, lng: 18.0250779 },
    { lat: 48.8534856, lng: 18.0252139 },
    { lat: 48.8534784, lng: 18.0252436 },
    { lat: 48.8534286, lng: 18.0254148 },
    { lat: 48.8533916, lng: 18.0255334 },
    { lat: 48.8534493, lng: 18.0254604 },
    { lat: 48.8534651, lng: 18.0254808 },
    { lat: 48.8534775, lng: 18.0254971 },
    { lat: 48.8534811, lng: 18.025502 },
    { lat: 48.853454, lng: 18.02554 },
    { lat: 48.8534457, lng: 18.0255526 },
    { lat: 48.853484, lng: 18.0255675 },
    { lat: 48.8534871, lng: 18.0255639 },
    { lat: 48.853593, lng: 18.0254277 },
    { lat: 48.8536172, lng: 18.0253965 },
    { lat: 48.8536187, lng: 18.0254009 },
    { lat: 48.8536937, lng: 18.0259879 },
    { lat: 48.853748, lng: 18.0264131 },
    { lat: 48.8538336, lng: 18.0270387 },
    { lat: 48.8538397, lng: 18.027087 },
    { lat: 48.8539092, lng: 18.0275955 },
    { lat: 48.8539037, lng: 18.0278844 },
    { lat: 48.8539016, lng: 18.0280047 },
    { lat: 48.8538885, lng: 18.0287591 },
    { lat: 48.8538777, lng: 18.029344 },
    { lat: 48.8538766, lng: 18.0293449 },
    { lat: 48.8535936, lng: 18.029462 },
    { lat: 48.8534172, lng: 18.0295353 },
    { lat: 48.8532314, lng: 18.0295969 },
    { lat: 48.8531539, lng: 18.0296228 },
    { lat: 48.852968, lng: 18.029687 },
    { lat: 48.8528906, lng: 18.0297136 },
    { lat: 48.8527016, lng: 18.0297606 },
    { lat: 48.8526225, lng: 18.0297803 },
    { lat: 48.8524316, lng: 18.029815 },
    { lat: 48.8523523, lng: 18.0298297 },
    { lat: 48.8521641, lng: 18.0298906 },
    { lat: 48.8520842, lng: 18.0299161 },
    { lat: 48.8518921, lng: 18.0299112 },
    { lat: 48.8518119, lng: 18.0299095 },
    { lat: 48.851556, lng: 18.0299341 },
    { lat: 48.8514123, lng: 18.0299481 },
    { lat: 48.8512117, lng: 18.0299516 },
    { lat: 48.8511226, lng: 18.0299529 },
    { lat: 48.8510632, lng: 18.0299437 },
    { lat: 48.85102, lng: 18.0299373 },
    { lat: 48.8509608, lng: 18.0299281 },
    { lat: 48.8509372, lng: 18.0299482 },
    { lat: 48.8509527, lng: 18.0300637 },
    { lat: 48.8509456, lng: 18.0300958 },
    { lat: 48.8509429, lng: 18.0301983 },
    { lat: 48.850883, lng: 18.0305429 },
    { lat: 48.850876, lng: 18.031038 },
    { lat: 48.8508301, lng: 18.0317935 },
    { lat: 48.8508125, lng: 18.0327613 },
    { lat: 48.8506878, lng: 18.0334189 },
    { lat: 48.8505643, lng: 18.0337968 },
    { lat: 48.8505831, lng: 18.0338875 },
    { lat: 48.8504142, lng: 18.034297 },
    { lat: 48.8502986, lng: 18.0347276 },
    { lat: 48.8501546, lng: 18.0352551 },
    { lat: 48.8500736, lng: 18.0355667 },
    { lat: 48.8499388, lng: 18.0360766 },
    { lat: 48.8498831, lng: 18.0362447 },
    { lat: 48.8498823, lng: 18.0362462 },
    { lat: 48.8498122, lng: 18.0364574 },
    { lat: 48.8498122, lng: 18.0364589 },
    { lat: 48.849786, lng: 18.0365309 },
    { lat: 48.8497118, lng: 18.0366899 },
    { lat: 48.8497466, lng: 18.0369361 },
    { lat: 48.8496487, lng: 18.0372084 },
    { lat: 48.8496137, lng: 18.0375342 },
    { lat: 48.8496362, lng: 18.0378985 },
    { lat: 48.8497596, lng: 18.0380972 },
    { lat: 48.8497718, lng: 18.0381328 },
    { lat: 48.8498536, lng: 18.038354 },
    { lat: 48.8498041, lng: 18.0384893 },
    { lat: 48.8497981, lng: 18.0385034 },
    { lat: 48.8497943, lng: 18.0385157 },
    { lat: 48.8498027, lng: 18.0386182 },
    { lat: 48.8498077, lng: 18.0386662 },
    { lat: 48.8498081, lng: 18.0386744 },
    { lat: 48.8498014, lng: 18.0387126 },
    { lat: 48.8497939, lng: 18.038758 },
    { lat: 48.849803, lng: 18.0388521 },
    { lat: 48.8498246, lng: 18.0390674 },
    { lat: 48.8497279, lng: 18.0392486 },
    { lat: 48.8498743, lng: 18.0394535 },
    { lat: 48.8499954, lng: 18.0397643 },
    { lat: 48.8499747, lng: 18.039951 },
    { lat: 48.8499835, lng: 18.0400035 },
    { lat: 48.849994, lng: 18.0400508 },
    { lat: 48.8499947, lng: 18.0400622 },
    { lat: 48.8500053, lng: 18.0401049 },
    { lat: 48.8500296, lng: 18.0402342 },
    { lat: 48.8501426, lng: 18.0403297 },
    { lat: 48.8502104, lng: 18.040485 },
    { lat: 48.8501524, lng: 18.0414382 },
    { lat: 48.8501492, lng: 18.0414798 },
    { lat: 48.8501236, lng: 18.0418696 },
    { lat: 48.850111, lng: 18.0420415 },
    { lat: 48.8501242, lng: 18.0422863 },
    { lat: 48.8501559, lng: 18.0424397 },
    { lat: 48.8501998, lng: 18.0431659 },
    { lat: 48.8502607, lng: 18.0432816 },
    { lat: 48.8502636, lng: 18.0433508 },
    { lat: 48.8501994, lng: 18.0437002 },
    { lat: 48.8502079, lng: 18.0438446 },
    { lat: 48.8503028, lng: 18.0441145 },
    { lat: 48.8502992, lng: 18.0442595 },
    { lat: 48.8503927, lng: 18.0443009 },
    { lat: 48.8503797, lng: 18.0444625 },
    { lat: 48.8503481, lng: 18.0447341 },
    { lat: 48.8503178, lng: 18.0450027 },
    { lat: 48.8502872, lng: 18.0452716 },
    { lat: 48.8502784, lng: 18.0453506 },
    { lat: 48.8502758, lng: 18.0453824 },
    { lat: 48.8504047, lng: 18.0454643 },
    { lat: 48.8504088, lng: 18.0455741 },
    { lat: 48.8504193, lng: 18.0457928 },
    { lat: 48.850407, lng: 18.0458496 },
    { lat: 48.8504036, lng: 18.0458683 },
    { lat: 48.8503604, lng: 18.0461146 },
    { lat: 48.850314, lng: 18.0463796 },
    { lat: 48.8502962, lng: 18.0464795 },
    { lat: 48.8502829, lng: 18.0467171 },
    { lat: 48.8502784, lng: 18.0467922 },
    { lat: 48.8502461, lng: 18.0467829 },
    { lat: 48.8499732, lng: 18.0466904 },
    { lat: 48.8499192, lng: 18.0466733 },
    { lat: 48.8499041, lng: 18.0467617 },
    { lat: 48.8498741, lng: 18.0468826 },
    { lat: 48.849851, lng: 18.0472999 },
    { lat: 48.84984, lng: 18.0475146 },
    { lat: 48.8498186, lng: 18.0478999 },
    { lat: 48.8498122, lng: 18.0479649 },
    { lat: 48.8496483, lng: 18.0482865 },
    { lat: 48.8496349, lng: 18.0484442 },
    { lat: 48.849631, lng: 18.0484913 },
    { lat: 48.8495976, lng: 18.0488857 },
    { lat: 48.8496233, lng: 18.0489973 },
    { lat: 48.8496848, lng: 18.0492341 },
    { lat: 48.8497171, lng: 18.049362 },
    { lat: 48.8498591, lng: 18.0494765 },
    { lat: 48.849854, lng: 18.0496191 },
    { lat: 48.8498112, lng: 18.0497768 },
    { lat: 48.8496845, lng: 18.0500394 },
    { lat: 48.8497524, lng: 18.0504068 },
    { lat: 48.8497794, lng: 18.0505612 },
    { lat: 48.8498396, lng: 18.0506993 },
    { lat: 48.8499135, lng: 18.0507306 },
    { lat: 48.850005, lng: 18.0507896 },
    { lat: 48.8499793, lng: 18.0515456 },
    { lat: 48.849928, lng: 18.0518238 },
    { lat: 48.8499177, lng: 18.0518782 },
    { lat: 48.8499506, lng: 18.0520551 },
    { lat: 48.8499803, lng: 18.0521392 },
    { lat: 48.8499821, lng: 18.0526091 },
    { lat: 48.8499572, lng: 18.0528773 },
    { lat: 48.849924, lng: 18.0532197 },
    { lat: 48.8500062, lng: 18.0534641 },
    { lat: 48.850046, lng: 18.053448 },
    { lat: 48.8500772, lng: 18.0535413 },
    { lat: 48.8500845, lng: 18.053646 },
    { lat: 48.8500983, lng: 18.0537277 },
    { lat: 48.8501211, lng: 18.0537898 },
    { lat: 48.8501981, lng: 18.0539256 },
    { lat: 48.8502165, lng: 18.0540694 },
    { lat: 48.8502221, lng: 18.0542016 },
    { lat: 48.8502105, lng: 18.0542741 },
    { lat: 48.8502426, lng: 18.0543407 },
    { lat: 48.8502322, lng: 18.0543726 },
    { lat: 48.8502219, lng: 18.0544474 },
    { lat: 48.8501699, lng: 18.0546583 },
    { lat: 48.8502314, lng: 18.0547275 },
    { lat: 48.8502806, lng: 18.0548925 },
    { lat: 48.8502827, lng: 18.0550416 },
    { lat: 48.8502781, lng: 18.0551204 },
    { lat: 48.8503184, lng: 18.0552099 },
    { lat: 48.8503044, lng: 18.0553956 },
    { lat: 48.8503579, lng: 18.0554963 },
    { lat: 48.850386, lng: 18.0555936 },
    { lat: 48.8504335, lng: 18.0556889 },
    { lat: 48.8504535, lng: 18.0557608 },
    { lat: 48.8504563, lng: 18.0558084 },
    { lat: 48.850431, lng: 18.0560136 },
    { lat: 48.8504539, lng: 18.0560578 },
    { lat: 48.8505193, lng: 18.0561321 },
    { lat: 48.8505276, lng: 18.0561963 },
    { lat: 48.8505159, lng: 18.0563444 },
    { lat: 48.850523, lng: 18.0564449 },
    { lat: 48.8505608, lng: 18.056706 },
    { lat: 48.8505626, lng: 18.0567735 },
    { lat: 48.8505327, lng: 18.0569976 },
    { lat: 48.8504958, lng: 18.0571394 },
    { lat: 48.8504933, lng: 18.0572093 },
    { lat: 48.8505013, lng: 18.0573666 },
    { lat: 48.8504436, lng: 18.0575765 },
    { lat: 48.8504688, lng: 18.0577558 },
    { lat: 48.8504974, lng: 18.0578563 },
    { lat: 48.8504128, lng: 18.0580314 },
    { lat: 48.8504015, lng: 18.0582057 },
    { lat: 48.8503663, lng: 18.0584526 },
    { lat: 48.8503734, lng: 18.058533 },
    { lat: 48.8504325, lng: 18.0586781 },
    { lat: 48.8504488, lng: 18.058783 },
    { lat: 48.8504639, lng: 18.0588462 },
    { lat: 48.8505619, lng: 18.0589563 },
    { lat: 48.8506192, lng: 18.0589948 },
    { lat: 48.8506215, lng: 18.0591264 },
    { lat: 48.8505532, lng: 18.0592521 },
    { lat: 48.8504642, lng: 18.0593515 },
    { lat: 48.8504564, lng: 18.05947 },
    { lat: 48.8504365, lng: 18.0596508 },
    { lat: 48.8504013, lng: 18.0597595 },
    { lat: 48.8504273, lng: 18.0599373 },
    { lat: 48.8504327, lng: 18.060009 },
    { lat: 48.8504001, lng: 18.0602603 },
    { lat: 48.8503879, lng: 18.0604185 },
    { lat: 48.85045, lng: 18.060422 },
    { lat: 48.8505252, lng: 18.0605265 },
    { lat: 48.8506134, lng: 18.0606279 },
    { lat: 48.8508479, lng: 18.0606222 },
    { lat: 48.8509619, lng: 18.060585 },
    { lat: 48.8510765, lng: 18.0605757 },
    { lat: 48.8511829, lng: 18.0606753 },
    { lat: 48.8515237, lng: 18.0607534 },
    { lat: 48.8517327, lng: 18.06072 },
    { lat: 48.8519229, lng: 18.0606528 },
    { lat: 48.8521549, lng: 18.060608 },
    { lat: 48.8522337, lng: 18.060564 },
    { lat: 48.8522762, lng: 18.0604788 },
    { lat: 48.8525249, lng: 18.0605048 },
    { lat: 48.8527283, lng: 18.0604929 },
    { lat: 48.8532961, lng: 18.0604932 },
    { lat: 48.8541739, lng: 18.060412 },
    { lat: 48.8546907, lng: 18.0603357 },
    { lat: 48.8551051, lng: 18.0602247 },
    { lat: 48.8554439, lng: 18.06013 },
    { lat: 48.8558177, lng: 18.0599428 },
    { lat: 48.8557706, lng: 18.0597714 },
    { lat: 48.855743, lng: 18.059669 },
    { lat: 48.855684, lng: 18.0595096 },
    { lat: 48.8556138, lng: 18.059332 },
    { lat: 48.8554719, lng: 18.0593762 },
    { lat: 48.8554633, lng: 18.0593433 },
    { lat: 48.8554495, lng: 18.0592799 },
    { lat: 48.855431, lng: 18.059215 },
    { lat: 48.8554228, lng: 18.0591513 },
    { lat: 48.8554032, lng: 18.059031 },
    { lat: 48.8553883, lng: 18.0589509 },
    { lat: 48.8553078, lng: 18.0585206 },
    { lat: 48.8552406, lng: 18.0582375 },
    { lat: 48.855215, lng: 18.0581327 },
    { lat: 48.8551679, lng: 18.0579613 },
    { lat: 48.855151, lng: 18.057903 },
    { lat: 48.8551221, lng: 18.0578187 },
    { lat: 48.855085, lng: 18.0577015 },
    { lat: 48.8550649, lng: 18.0576494 },
    { lat: 48.8550252, lng: 18.0575479 },
    { lat: 48.8549641, lng: 18.0573931 },
    { lat: 48.8549472, lng: 18.0573151 },
    { lat: 48.8549102, lng: 18.0571839 },
    { lat: 48.8548738, lng: 18.0570634 },
    { lat: 48.8548612, lng: 18.0570217 },
    { lat: 48.8548508, lng: 18.0569614 },
    { lat: 48.8548491, lng: 18.0569119 },
    { lat: 48.8548496, lng: 18.0568243 },
    { lat: 48.8549104, lng: 18.0566671 },
    { lat: 48.8549761, lng: 18.0566908 },
    { lat: 48.8553251, lng: 18.05656 },
    { lat: 48.8553426, lng: 18.0565516 },
    { lat: 48.8554719, lng: 18.0563342 },
    { lat: 48.8558204, lng: 18.0557515 },
    { lat: 48.8558198, lng: 18.0557214 },
    { lat: 48.8557982, lng: 18.0546979 },
    { lat: 48.8557883, lng: 18.0542025 },
    { lat: 48.8558255, lng: 18.0541076 },
    { lat: 48.8559579, lng: 18.0540342 },
    { lat: 48.8560137, lng: 18.0540059 },
    { lat: 48.8560244, lng: 18.0538783 },
    { lat: 48.8560953, lng: 18.053663 },
    { lat: 48.8560605, lng: 18.053457 },
    { lat: 48.8559845, lng: 18.0533149 },
    { lat: 48.8559447, lng: 18.0529418 },
    { lat: 48.8559116, lng: 18.0520517 },
    { lat: 48.8559091, lng: 18.0520076 },
    { lat: 48.8559466, lng: 18.052014 },
    { lat: 48.855943, lng: 18.0507397 },
    { lat: 48.8563525, lng: 18.0507376 },
    { lat: 48.8564169, lng: 18.0507192 },
    { lat: 48.8566698, lng: 18.0507604 },
    { lat: 48.8578276, lng: 18.0509492 },
    { lat: 48.8578379, lng: 18.0509507 },
    { lat: 48.8581022, lng: 18.0509935 },
    { lat: 48.8590069, lng: 18.0511408 },
    { lat: 48.8590354, lng: 18.0511477 },
    { lat: 48.8590305, lng: 18.0509459 },
    { lat: 48.8590324, lng: 18.0506521 },
    { lat: 48.8590303, lng: 18.0504036 },
    { lat: 48.8590782, lng: 18.0503929 },
    { lat: 48.8591996, lng: 18.0503661 },
    { lat: 48.8593444, lng: 18.0503344 },
    { lat: 48.8594025, lng: 18.0503216 },
    { lat: 48.8594825, lng: 18.0503026 },
    { lat: 48.8594925, lng: 18.0503001 },
    { lat: 48.8595773, lng: 18.0502819 },
    { lat: 48.8601155, lng: 18.0501646 },
    { lat: 48.8601358, lng: 18.0501841 },
    { lat: 48.8602488, lng: 18.0502434 },
    { lat: 48.8602144, lng: 18.05039 },
    { lat: 48.8601846, lng: 18.0504961 },
    { lat: 48.8600922, lng: 18.0508276 },
    { lat: 48.8600621, lng: 18.0509668 },
    { lat: 48.8600269, lng: 18.051041 },
    { lat: 48.8599651, lng: 18.0513332 },
    { lat: 48.8599623, lng: 18.0514397 },
    { lat: 48.8599881, lng: 18.0518785 },
    { lat: 48.8599917, lng: 18.052078 },
    { lat: 48.8599866, lng: 18.0522558 },
    { lat: 48.8599856, lng: 18.0522993 },
    { lat: 48.8599583, lng: 18.052447 },
    { lat: 48.8599164, lng: 18.0526401 },
    { lat: 48.8598668, lng: 18.0528146 },
    { lat: 48.8598618, lng: 18.0528418 },
    { lat: 48.859782, lng: 18.0531762 },
    { lat: 48.859764, lng: 18.0532159 },
    { lat: 48.8597287, lng: 18.053364 },
    { lat: 48.8596739, lng: 18.0536018 },
    { lat: 48.8596181, lng: 18.0538079 },
    { lat: 48.8594847, lng: 18.0541707 },
    { lat: 48.8593934, lng: 18.054345 },
    { lat: 48.8593626, lng: 18.0544154 },
    { lat: 48.8593168, lng: 18.0545589 },
    { lat: 48.8592793, lng: 18.0547463 },
    { lat: 48.8592647, lng: 18.0548999 },
    { lat: 48.8592585, lng: 18.0550438 },
    { lat: 48.8592617, lng: 18.0553132 },
    { lat: 48.8592729, lng: 18.0554271 },
    { lat: 48.8593122, lng: 18.0556534 },
    { lat: 48.8593482, lng: 18.055941 },
    { lat: 48.8593881, lng: 18.056198 },
    { lat: 48.8593863, lng: 18.0562441 },
    { lat: 48.8594381, lng: 18.0565117 },
    { lat: 48.859478, lng: 18.0567891 },
    { lat: 48.859487, lng: 18.0569216 },
    { lat: 48.8594939, lng: 18.0572533 },
    { lat: 48.8594944, lng: 18.0573578 },
    { lat: 48.8594786, lng: 18.0577026 },
    { lat: 48.8594856, lng: 18.0577458 },
    { lat: 48.8594538, lng: 18.0582077 },
    { lat: 48.8594408, lng: 18.0583894 },
    { lat: 48.8594049, lng: 18.0586401 },
    { lat: 48.8593973, lng: 18.0587035 },
    { lat: 48.8595254, lng: 18.0609515 },
    { lat: 48.8595257, lng: 18.0609561 },
    { lat: 48.8595277, lng: 18.0609902 },
    { lat: 48.8596394, lng: 18.0608913 },
    { lat: 48.859781, lng: 18.0607658 },
    { lat: 48.859826, lng: 18.0607257 },
    { lat: 48.860059, lng: 18.060504 },
    { lat: 48.8601981, lng: 18.0603724 },
    { lat: 48.8606612, lng: 18.0599732 },
    { lat: 48.8610263, lng: 18.0596581 },
    { lat: 48.8614228, lng: 18.0593177 },
    { lat: 48.8617228, lng: 18.0590611 },
    { lat: 48.8618438, lng: 18.0589506 },
    { lat: 48.8618694, lng: 18.0589274 },
    { lat: 48.8620821, lng: 18.0587637 },
    { lat: 48.8621955, lng: 18.0586767 },
    { lat: 48.8623225, lng: 18.0585514 },
    { lat: 48.8624725, lng: 18.0584151 },
    { lat: 48.8625272, lng: 18.058365 },
    { lat: 48.8628157, lng: 18.0581664 },
    { lat: 48.8630024, lng: 18.058038 },
    { lat: 48.8631807, lng: 18.057962 },
    { lat: 48.8632502, lng: 18.0579326 },
    { lat: 48.863426, lng: 18.0578896 },
    { lat: 48.8634905, lng: 18.0578737 },
    { lat: 48.8636891, lng: 18.0578556 },
    { lat: 48.8637778, lng: 18.0578476 },
    { lat: 48.8640292, lng: 18.0578034 },
    { lat: 48.8641687, lng: 18.0577785 },
    { lat: 48.8645973, lng: 18.0577362 },
    { lat: 48.8649138, lng: 18.0577058 },
    { lat: 48.8650766, lng: 18.0576914 },
    { lat: 48.8651273, lng: 18.0576878 },
    { lat: 48.8653449, lng: 18.0576646 },
    { lat: 48.8654514, lng: 18.0576535 },
    { lat: 48.8656102, lng: 18.0576271 },
    { lat: 48.8657106, lng: 18.0576096 },
    { lat: 48.8658537, lng: 18.0575656 },
    { lat: 48.8658869, lng: 18.0575554 },
    { lat: 48.8661342, lng: 18.0574246 },
    { lat: 48.8662767, lng: 18.0573499 },
    { lat: 48.8665013, lng: 18.057215 },
    { lat: 48.8666223, lng: 18.0571424 },
    { lat: 48.866815, lng: 18.0570598 },
    { lat: 48.8668996, lng: 18.057023 },
    { lat: 48.8669396, lng: 18.0570112 },
    { lat: 48.8671189, lng: 18.0570476 },
    { lat: 48.8671885, lng: 18.0570612 },
    { lat: 48.8676043, lng: 18.0571471 },
    { lat: 48.8679096, lng: 18.0572107 },
    { lat: 48.8679395, lng: 18.0572411 },
    { lat: 48.8679413, lng: 18.0572513 },
    { lat: 48.8679642, lng: 18.0574339 },
    { lat: 48.8679997, lng: 18.0580184 },
    { lat: 48.8672811, lng: 18.0580646 },
    { lat: 48.8673091, lng: 18.0584627 },
    { lat: 48.8670214, lng: 18.058581 },
    { lat: 48.8663862, lng: 18.0590662 },
    { lat: 48.8667005, lng: 18.0590569 },
    { lat: 48.8669184, lng: 18.0590956 },
    { lat: 48.8669569, lng: 18.0591191 },
    { lat: 48.8669659, lng: 18.0591355 },
    { lat: 48.8670425, lng: 18.0592679 },
    { lat: 48.8671267, lng: 18.0594142 },
    { lat: 48.8671454, lng: 18.0594469 },
    { lat: 48.8672409, lng: 18.0596115 },
    { lat: 48.8673452, lng: 18.0597931 },
    { lat: 48.8673765, lng: 18.059847 },
    { lat: 48.8674633, lng: 18.059997 },
    { lat: 48.867463, lng: 18.0600093 },
    { lat: 48.86746, lng: 18.0601541 },
    { lat: 48.86746, lng: 18.0601903 },
    { lat: 48.8674579, lng: 18.0603476 },
    { lat: 48.8674572, lng: 18.0603929 },
    { lat: 48.8674539, lng: 18.06055 },
    { lat: 48.867454, lng: 18.0605732 },
    { lat: 48.8674511, lng: 18.0607328 },
    { lat: 48.8674488, lng: 18.0609073 },
    { lat: 48.8674466, lng: 18.0610603 },
    { lat: 48.8674439, lng: 18.0612281 },
    { lat: 48.8674411, lng: 18.0614099 },
    { lat: 48.8674385, lng: 18.0615801 },
    { lat: 48.8673218, lng: 18.0618041 },
    { lat: 48.8672237, lng: 18.061994 },
    { lat: 48.8671018, lng: 18.0622273 },
    { lat: 48.8670511, lng: 18.0623259 },
    { lat: 48.8670927, lng: 18.0625529 },
    { lat: 48.8671339, lng: 18.0627754 },
    { lat: 48.8671702, lng: 18.0629719 },
    { lat: 48.8671999, lng: 18.0631318 },
    { lat: 48.8672144, lng: 18.0632055 },
    { lat: 48.8672277, lng: 18.0632797 },
    { lat: 48.8672736, lng: 18.0635219 },
    { lat: 48.8673832, lng: 18.0633907 },
    { lat: 48.8674844, lng: 18.0632692 },
    { lat: 48.867576, lng: 18.0631568 },
    { lat: 48.8677121, lng: 18.0629937 },
    { lat: 48.8677539, lng: 18.0630124 },
    { lat: 48.8677971, lng: 18.0632339 },
    { lat: 48.8678334, lng: 18.0634294 },
    { lat: 48.8678738, lng: 18.0636355 },
    { lat: 48.8679011, lng: 18.0637734 },
    { lat: 48.8679637, lng: 18.0638976 },
    { lat: 48.8680747, lng: 18.0641178 },
    { lat: 48.8681554, lng: 18.0642801 },
    { lat: 48.8682358, lng: 18.064441 },
    { lat: 48.8683278, lng: 18.0646244 },
    { lat: 48.8684197, lng: 18.0648085 },
    { lat: 48.8685017, lng: 18.0649724 },
    { lat: 48.868582, lng: 18.0651326 },
    { lat: 48.8686626, lng: 18.0652924 },
    { lat: 48.868675, lng: 18.0653173 },
    { lat: 48.8687496, lng: 18.0654667 },
    { lat: 48.868821, lng: 18.0656077 },
    { lat: 48.8688957, lng: 18.0657647 },
    { lat: 48.8689646, lng: 18.0659028 },
    { lat: 48.8690351, lng: 18.0660449 },
    { lat: 48.8691029, lng: 18.0661814 },
    { lat: 48.8691767, lng: 18.0663274 },
    { lat: 48.8692408, lng: 18.0664571 },
    { lat: 48.8692459, lng: 18.0664665 },
    { lat: 48.8693482, lng: 18.066671 },
    { lat: 48.8694018, lng: 18.06678 },
    { lat: 48.8695699, lng: 18.0668147 },
    { lat: 48.8698052, lng: 18.0668634 },
    { lat: 48.8698288, lng: 18.0668624 },
    { lat: 48.8699752, lng: 18.0668549 },
    { lat: 48.8701482, lng: 18.0668456 },
    { lat: 48.8702845, lng: 18.0668384 },
    { lat: 48.8703196, lng: 18.0668197 },
    { lat: 48.8703402, lng: 18.0668083 },
    { lat: 48.8704617, lng: 18.0667422 },
    { lat: 48.8705896, lng: 18.066673 },
    { lat: 48.8707186, lng: 18.0666029 },
    { lat: 48.8708477, lng: 18.0665329 },
    { lat: 48.8709706, lng: 18.0664916 },
    { lat: 48.8710615, lng: 18.0664611 },
    { lat: 48.8711478, lng: 18.066432 },
    { lat: 48.8712153, lng: 18.0664093 },
    { lat: 48.8713016, lng: 18.0663804 },
    { lat: 48.8713752, lng: 18.0665193 },
    { lat: 48.8714676, lng: 18.0666919 },
    { lat: 48.8715512, lng: 18.066847 },
    { lat: 48.8716397, lng: 18.06701 },
    { lat: 48.8717153, lng: 18.0671623 },
    { lat: 48.8717969, lng: 18.0673245 },
    { lat: 48.8718226, lng: 18.0673759 },
    { lat: 48.8718968, lng: 18.0674871 },
    { lat: 48.8719945, lng: 18.0676342 },
    { lat: 48.8720834, lng: 18.0677825 },
    { lat: 48.8721732, lng: 18.0679321 },
    { lat: 48.8722169, lng: 18.068014 },
    { lat: 48.8722609, lng: 18.0680963 },
    { lat: 48.8723316, lng: 18.0682456 },
    { lat: 48.8724098, lng: 18.0684068 },
    { lat: 48.8724872, lng: 18.0685692 },
    { lat: 48.8725706, lng: 18.0687412 },
    { lat: 48.8726568, lng: 18.0689214 },
    { lat: 48.8728524, lng: 18.0689862 },
    { lat: 48.8730757, lng: 18.0690602 },
    { lat: 48.8731191, lng: 18.0690712 },
    { lat: 48.8731568, lng: 18.0690805 },
    { lat: 48.8733071, lng: 18.069119 },
    { lat: 48.8737658, lng: 18.0692635 },
    { lat: 48.873806, lng: 18.0692761 },
    { lat: 48.8738227, lng: 18.0692742 },
    { lat: 48.8740665, lng: 18.0692532 },
    { lat: 48.8741349, lng: 18.0692458 },
    { lat: 48.8742008, lng: 18.0692373 },
    { lat: 48.8742348, lng: 18.0692363 },
    { lat: 48.8742683, lng: 18.0692301 },
    { lat: 48.8743663, lng: 18.0692083 },
    { lat: 48.8744648, lng: 18.0691775 },
    { lat: 48.8746417, lng: 18.0691121 },
    { lat: 48.8746191, lng: 18.0689968 },
    { lat: 48.8747027, lng: 18.0691738 },
    { lat: 48.8748368, lng: 18.0694193 },
    { lat: 48.8749007, lng: 18.0695462 },
    { lat: 48.8749662, lng: 18.0697034 },
    { lat: 48.8750053, lng: 18.06981 },
    { lat: 48.8750198, lng: 18.0699049 },
    { lat: 48.8750313, lng: 18.0700607 },
    { lat: 48.8749813, lng: 18.07054 },
    { lat: 48.8749719, lng: 18.0710547 },
    { lat: 48.874943, lng: 18.0716605 },
    { lat: 48.8749499, lng: 18.0721074 },
    { lat: 48.8749988, lng: 18.072365 },
    { lat: 48.8751243, lng: 18.0731979 },
    { lat: 48.8751854, lng: 18.0732596 },
    { lat: 48.8754877, lng: 18.0730616 },
    { lat: 48.8758718, lng: 18.0728203 },
    { lat: 48.8764482, lng: 18.0724862 },
    { lat: 48.8767894, lng: 18.0722983 },
    { lat: 48.8770208, lng: 18.0721464 },
    { lat: 48.877304, lng: 18.0719513 },
    { lat: 48.8776084, lng: 18.0716967 },
    { lat: 48.8778012, lng: 18.0715357 },
    { lat: 48.8780309, lng: 18.0713345 },
    { lat: 48.8782311, lng: 18.0711509 },
    { lat: 48.8784183, lng: 18.0709357 },
    { lat: 48.878645, lng: 18.0707023 },
    { lat: 48.8788984, lng: 18.0704628 },
    { lat: 48.8789148, lng: 18.070509 },
    { lat: 48.8794165, lng: 18.0705187 },
    { lat: 48.8796253, lng: 18.0705222 },
    { lat: 48.8801506, lng: 18.0706438 },
    { lat: 48.8806838, lng: 18.0707324 },
    { lat: 48.8810495, lng: 18.0707506 },
    { lat: 48.8816454, lng: 18.070753 },
    { lat: 48.8821205, lng: 18.0707691 },
    { lat: 48.8826388, lng: 18.0722051 },
    { lat: 48.8826401, lng: 18.0722068 },
    { lat: 48.882663, lng: 18.0722704 },
    { lat: 48.8826845, lng: 18.0723306 },
    { lat: 48.8827059, lng: 18.0723901 },
    { lat: 48.8827424, lng: 18.0724924 },
    { lat: 48.882746, lng: 18.0726501 },
    { lat: 48.8827535, lng: 18.072989 },
    { lat: 48.8827587, lng: 18.0731966 },
    { lat: 48.8829184, lng: 18.0741691 },
    { lat: 48.8818881, lng: 18.0769549 },
    { lat: 48.881731, lng: 18.0775088 },
    { lat: 48.8832157, lng: 18.0779481 },
    { lat: 48.8837394, lng: 18.078103 },
    { lat: 48.8839232, lng: 18.0784835 },
    { lat: 48.8816131, lng: 18.0786197 },
    { lat: 48.8813866, lng: 18.0790908 },
    { lat: 48.8813974, lng: 18.0803974 },
    { lat: 48.8815966, lng: 18.0814897 },
    { lat: 48.8813436, lng: 18.0820848 },
    { lat: 48.8815121, lng: 18.0829633 },
    { lat: 48.881852, lng: 18.0833727 },
    { lat: 48.8826333, lng: 18.0845968 },
    { lat: 48.8827165, lng: 18.0846487 },
    { lat: 48.8828288, lng: 18.0848911 },
    { lat: 48.882817, lng: 18.0851512 },
    { lat: 48.8827733, lng: 18.0854271 },
    { lat: 48.8827318, lng: 18.0855886 },
    { lat: 48.8827246, lng: 18.0856179 },
    { lat: 48.8826812, lng: 18.0857657 },
    { lat: 48.8826328, lng: 18.0859985 },
    { lat: 48.8826131, lng: 18.0860714 },
    { lat: 48.8825696, lng: 18.0861726 },
    { lat: 48.8823834, lng: 18.0865798 },
    { lat: 48.8822016, lng: 18.0869093 },
    { lat: 48.8821061, lng: 18.0871455 },
    { lat: 48.882038, lng: 18.0873727 },
    { lat: 48.8819935, lng: 18.0876931 },
    { lat: 48.8819891, lng: 18.0876937 },
    { lat: 48.8819451, lng: 18.0877143 },
    { lat: 48.8820999, lng: 18.0879517 },
    { lat: 48.8820582, lng: 18.0881448 },
    { lat: 48.8823139, lng: 18.0890105 },
    { lat: 48.8824778, lng: 18.0892654 },
    { lat: 48.8826862, lng: 18.0894329 },
    { lat: 48.8827011, lng: 18.0898024 },
    { lat: 48.8827433, lng: 18.0899875 },
    { lat: 48.882927, lng: 18.0903314 },
    { lat: 48.8827782, lng: 18.0908946 },
    { lat: 48.8826762, lng: 18.0916803 },
    { lat: 48.8824045, lng: 18.0922477 },
    { lat: 48.8822232, lng: 18.0925832 },
    { lat: 48.8813827, lng: 18.0928576 },
    { lat: 48.8806332, lng: 18.092954 },
    { lat: 48.8802883, lng: 18.0929801 },
    { lat: 48.8797142, lng: 18.0931194 },
    { lat: 48.8794017, lng: 18.0932749 },
    { lat: 48.8789813, lng: 18.0935779 },
    { lat: 48.878472, lng: 18.0938795 },
    { lat: 48.8782074, lng: 18.0941233 },
    { lat: 48.8779733, lng: 18.0940585 },
    { lat: 48.8774328, lng: 18.0945637 },
    { lat: 48.8769185, lng: 18.0950502 },
    { lat: 48.8762901, lng: 18.0956538 },
    { lat: 48.8756223, lng: 18.0962997 },
    { lat: 48.8752517, lng: 18.0966777 },
    { lat: 48.8752176, lng: 18.0967127 },
    { lat: 48.8750374, lng: 18.0968964 },
    { lat: 48.874922, lng: 18.0970255 },
    { lat: 48.8743807, lng: 18.0976317 },
    { lat: 48.8739283, lng: 18.098139 },
    { lat: 48.8738512, lng: 18.098212 },
    { lat: 48.8737679, lng: 18.098292 },
    { lat: 48.8739258, lng: 18.0988506 },
    { lat: 48.8731627, lng: 18.0987906 },
    { lat: 48.8728996, lng: 18.0988036 },
    { lat: 48.8726137, lng: 18.0988365 },
    { lat: 48.8721467, lng: 18.0990363 },
    { lat: 48.8714962, lng: 18.0995338 },
    { lat: 48.8708813, lng: 18.1000556 },
    { lat: 48.870229, lng: 18.1002902 },
    { lat: 48.8699633, lng: 18.100377 },
    { lat: 48.8697299, lng: 18.1005559 },
    { lat: 48.8696914, lng: 18.1005933 },
    { lat: 48.8693444, lng: 18.1009336 },
    { lat: 48.8693169, lng: 18.1009814 },
    { lat: 48.8691525, lng: 18.1012604 },
    { lat: 48.8688841, lng: 18.1019796 },
    { lat: 48.8684363, lng: 18.1030134 },
    { lat: 48.8681559, lng: 18.1037219 },
    { lat: 48.8680131, lng: 18.104563 },
    { lat: 48.8678822, lng: 18.1046984 },
    { lat: 48.8675553, lng: 18.1048812 },
    { lat: 48.8670938, lng: 18.1050375 },
    { lat: 48.8667525, lng: 18.1057477 },
    { lat: 48.866081, lng: 18.1069113 },
    { lat: 48.8659549, lng: 18.1081748 },
    { lat: 48.8662203, lng: 18.1086229 },
    { lat: 48.8663979, lng: 18.1091204 },
    { lat: 48.8667794, lng: 18.1096411 },
    { lat: 48.8672227, lng: 18.1104372 },
    { lat: 48.8673921, lng: 18.1113494 },
    { lat: 48.8675797, lng: 18.112071 },
    { lat: 48.8677194, lng: 18.1129624 },
    { lat: 48.8678197, lng: 18.1137194 },
    { lat: 48.8679617, lng: 18.1143148 },
    { lat: 48.8679776, lng: 18.1143371 },
    { lat: 48.8680653, lng: 18.1149347 },
    { lat: 48.8680666, lng: 18.1149367 },
    { lat: 48.868114, lng: 18.1150526 },
    { lat: 48.8682016, lng: 18.1152628 },
    { lat: 48.8682076, lng: 18.1157527 },
    { lat: 48.8681669, lng: 18.1161793 },
    { lat: 48.8682095, lng: 18.1164447 },
    { lat: 48.8682401, lng: 18.116641 },
    { lat: 48.8683277, lng: 18.1168515 },
    { lat: 48.8683392, lng: 18.1168539 },
    { lat: 48.8683884, lng: 18.116866 },
    { lat: 48.8684909, lng: 18.1175807 },
    { lat: 48.8685314, lng: 18.1178298 },
    { lat: 48.8685566, lng: 18.1179913 },
    { lat: 48.8685571, lng: 18.1179995 },
    { lat: 48.8685581, lng: 18.118156 },
    { lat: 48.8684877, lng: 18.1184948 },
    { lat: 48.8684721, lng: 18.1187923 },
    { lat: 48.8684715, lng: 18.1188017 },
    { lat: 48.8684433, lng: 18.119265 },
    { lat: 48.8685997, lng: 18.1197431 },
    { lat: 48.8687103, lng: 18.1200556 },
    { lat: 48.8688269, lng: 18.1206466 },
    { lat: 48.8689555, lng: 18.1212653 },
    { lat: 48.8689304, lng: 18.1216529 },
    { lat: 48.8687984, lng: 18.1221003 },
    { lat: 48.8690695, lng: 18.1224563 },
    { lat: 48.8691353, lng: 18.122616 },
    { lat: 48.8691537, lng: 18.1226609 },
    { lat: 48.8691629, lng: 18.122684 },
    { lat: 48.869167, lng: 18.1226946 },
    { lat: 48.8691784, lng: 18.1227755 },
    { lat: 48.869246, lng: 18.1232605 },
    { lat: 48.869334, lng: 18.1236484 },
    { lat: 48.8696854, lng: 18.1241414 },
    { lat: 48.8698645, lng: 18.1244689 },
    { lat: 48.869708, lng: 18.1249234 },
    { lat: 48.8695404, lng: 18.1254369 },
    { lat: 48.8693749, lng: 18.1260859 },
    { lat: 48.8693546, lng: 18.1265514 },
    { lat: 48.869329, lng: 18.1269308 },
    { lat: 48.8691624, lng: 18.1274778 },
    { lat: 48.8689356, lng: 18.128474 },
    { lat: 48.8686934, lng: 18.1293372 },
    { lat: 48.8684193, lng: 18.1297081 },
    { lat: 48.8679553, lng: 18.130152 },
    { lat: 48.8679039, lng: 18.1301839 },
    { lat: 48.8676001, lng: 18.1303714 },
    { lat: 48.867417, lng: 18.1302524 },
    { lat: 48.8668074, lng: 18.1305956 },
    { lat: 48.8663816, lng: 18.1311559 },
    { lat: 48.8660989, lng: 18.1316717 },
    { lat: 48.8659428, lng: 18.1319003 },
    { lat: 48.865638, lng: 18.1324796 },
    { lat: 48.8657659, lng: 18.1332669 },
    { lat: 48.8659599, lng: 18.1340243 },
    { lat: 48.8660677, lng: 18.1344433 },
    { lat: 48.8661657, lng: 18.1349073 },
    { lat: 48.8662082, lng: 18.1353504 },
    { lat: 48.8662515, lng: 18.1358042 },
    { lat: 48.8664003, lng: 18.1359775 },
    { lat: 48.865889, lng: 18.135956 },
    { lat: 48.865637, lng: 18.1362436 },
    { lat: 48.8650075, lng: 18.1363811 },
    { lat: 48.8649903, lng: 18.1362608 },
    { lat: 48.8647265, lng: 18.136307 },
    { lat: 48.8642472, lng: 18.1361149 },
    { lat: 48.8641347, lng: 18.1361403 },
    { lat: 48.864138, lng: 18.1366466 },
    { lat: 48.8641379, lng: 18.137152 },
    { lat: 48.8637927, lng: 18.1374054 },
    { lat: 48.8637178, lng: 18.1374555 },
    { lat: 48.8635277, lng: 18.1375859 },
    { lat: 48.8632737, lng: 18.1375254 },
    { lat: 48.8629579, lng: 18.1378007 },
    { lat: 48.8627977, lng: 18.1379367 },
    { lat: 48.8623594, lng: 18.1381441 },
    { lat: 48.8618488, lng: 18.1382726 },
    { lat: 48.8618681, lng: 18.137532 },
    { lat: 48.8618687, lng: 18.1371148 },
    { lat: 48.8617608, lng: 18.1366725 },
    { lat: 48.8615855, lng: 18.1359472 },
    { lat: 48.8613956, lng: 18.1361147 },
    { lat: 48.8613027, lng: 18.1360697 },
    { lat: 48.8612734, lng: 18.1363234 },
    { lat: 48.8607325, lng: 18.1364154 },
    { lat: 48.8607134, lng: 18.136614 },
    { lat: 48.8599521, lng: 18.1367382 },
    { lat: 48.8594655, lng: 18.1369678 },
    { lat: 48.8596593, lng: 18.1374886 },
    { lat: 48.8598188, lng: 18.1379598 },
    { lat: 48.859847, lng: 18.1380177 },
    { lat: 48.8599725, lng: 18.138316 },
    { lat: 48.8600301, lng: 18.1384521 },
    { lat: 48.8603707, lng: 18.1391723 },
    { lat: 48.8607053, lng: 18.140018 },
    { lat: 48.8608518, lng: 18.1406587 },
    { lat: 48.8611869, lng: 18.1411449 },
    { lat: 48.8618304, lng: 18.1411139 },
    { lat: 48.862588, lng: 18.1414114 },
    { lat: 48.8629616, lng: 18.1415094 },
    { lat: 48.8632106, lng: 18.1413859 },
    { lat: 48.8637246, lng: 18.1419029 },
    { lat: 48.8641554, lng: 18.1423657 },
    { lat: 48.864794, lng: 18.1430098 },
    { lat: 48.8652032, lng: 18.1434691 },
    { lat: 48.865437, lng: 18.143709 },
    { lat: 48.866277, lng: 18.1438497 },
    { lat: 48.8665496, lng: 18.1445432 },
    { lat: 48.8667174, lng: 18.1448328 },
    { lat: 48.8673425, lng: 18.1456895 },
    { lat: 48.8680208, lng: 18.1469009 },
    { lat: 48.8683624, lng: 18.1470513 },
    { lat: 48.8694021, lng: 18.1478978 },
    { lat: 48.8697775, lng: 18.1495897 },
    { lat: 48.8699372, lng: 18.1507334 },
    { lat: 48.870018, lng: 18.1513909 },
    { lat: 48.8702375, lng: 18.15138 },
    { lat: 48.8706831, lng: 18.1513198 },
    { lat: 48.8709113, lng: 18.1513816 },
    { lat: 48.8710661, lng: 18.1514822 },
    { lat: 48.8713123, lng: 18.1515646 },
    { lat: 48.87153, lng: 18.1515418 },
    { lat: 48.8717684, lng: 18.1514894 },
    { lat: 48.8720321, lng: 18.1515228 },
    { lat: 48.872373, lng: 18.1516041 },
    { lat: 48.8729958, lng: 18.1515419 },
    { lat: 48.873651, lng: 18.1513811 },
    { lat: 48.8739937, lng: 18.1513743 },
    { lat: 48.8745039, lng: 18.1512782 },
    { lat: 48.8749407, lng: 18.1511449 },
    { lat: 48.875138, lng: 18.1516092 },
    { lat: 48.8755846, lng: 18.1519751 },
    { lat: 48.8758933, lng: 18.1521259 },
    { lat: 48.8760757, lng: 18.1522737 },
    { lat: 48.8764418, lng: 18.1525947 },
    { lat: 48.8770175, lng: 18.1526958 },
    { lat: 48.8771563, lng: 18.1529685 },
    { lat: 48.8774954, lng: 18.1541897 },
    { lat: 48.8777399, lng: 18.1550048 },
    { lat: 48.8780183, lng: 18.1553916 },
    { lat: 48.8781002, lng: 18.1557905 },
    { lat: 48.8781378, lng: 18.1564247 },
    { lat: 48.8783016, lng: 18.157542 },
    { lat: 48.8783386, lng: 18.158319 },
    { lat: 48.8783913, lng: 18.1586056 },
    { lat: 48.8784696, lng: 18.1588691 },
    { lat: 48.8786466, lng: 18.1595508 },
    { lat: 48.8789185, lng: 18.1600579 },
    { lat: 48.8791052, lng: 18.1608477 },
    { lat: 48.879268, lng: 18.1610918 },
    { lat: 48.8793696, lng: 18.1612099 },
    { lat: 48.8794623, lng: 18.1615457 },
    { lat: 48.8795481, lng: 18.1617003 },
    { lat: 48.8800372, lng: 18.1622977 },
    { lat: 48.8805609, lng: 18.1629245 },
    { lat: 48.8807658, lng: 18.1631304 },
    { lat: 48.881104, lng: 18.163637 },
    { lat: 48.8811993, lng: 18.1638423 },
    { lat: 48.8813214, lng: 18.1639997 },
    { lat: 48.8815964, lng: 18.1642486 },
    { lat: 48.88178, lng: 18.1645179 },
    { lat: 48.8820195, lng: 18.1647178 },
    { lat: 48.8823024, lng: 18.1649087 },
    { lat: 48.8825601, lng: 18.1652277 },
    { lat: 48.8826371, lng: 18.165271 },
    { lat: 48.883429, lng: 18.1653242 },
    { lat: 48.8837481, lng: 18.1651248 },
    { lat: 48.8839541, lng: 18.1650979 },
    { lat: 48.8841277, lng: 18.1651147 },
    { lat: 48.8843188, lng: 18.1651811 },
    { lat: 48.8845506, lng: 18.1651698 },
    { lat: 48.8847098, lng: 18.1651924 },
    { lat: 48.8848012, lng: 18.1651696 },
    { lat: 48.884906, lng: 18.1650721 },
    { lat: 48.8854401, lng: 18.164664 },
    { lat: 48.8857353, lng: 18.1641483 },
    { lat: 48.8859168, lng: 18.1634986 },
    { lat: 48.8861562, lng: 18.1630914 },
    { lat: 48.886319, lng: 18.1628418 },
    { lat: 48.8866486, lng: 18.1624736 },
    { lat: 48.8867307, lng: 18.1622519 },
    { lat: 48.8867503, lng: 18.162198 },
    { lat: 48.8867619, lng: 18.1621656 },
    { lat: 48.8867696, lng: 18.1621438 },
    { lat: 48.8868951, lng: 18.1616582 },
    { lat: 48.8870123, lng: 18.1613045 },
    { lat: 48.8872722, lng: 18.1609559 },
    { lat: 48.8874876, lng: 18.1606233 },
    { lat: 48.8876946, lng: 18.1602609 },
    { lat: 48.8877769, lng: 18.1600238 },
    { lat: 48.8879236, lng: 18.1594188 },
    { lat: 48.8880869, lng: 18.158842 },
    { lat: 48.8883328, lng: 18.1582762 },
    { lat: 48.8884962, lng: 18.1576862 },
    { lat: 48.8885423, lng: 18.157154 },
    { lat: 48.8886984, lng: 18.1567683 },
    { lat: 48.8890175, lng: 18.1562949 },
    { lat: 48.8894745, lng: 18.1558269 },
    { lat: 48.8897288, lng: 18.1555583 },
    { lat: 48.8899862, lng: 18.1552856 },
    { lat: 48.8903036, lng: 18.1551349 },
    { lat: 48.8907584, lng: 18.1548418 },
    { lat: 48.8911873, lng: 18.1546489 },
    { lat: 48.8915965, lng: 18.1543888 },
    { lat: 48.8919646, lng: 18.154256 },
    { lat: 48.8926332, lng: 18.1538721 },
    { lat: 48.8934869, lng: 18.1533177 },
    { lat: 48.894086, lng: 18.1530402 },
    { lat: 48.8943734, lng: 18.1528012 },
    { lat: 48.8947016, lng: 18.1526407 },
    { lat: 48.8956299, lng: 18.1521276 },
    { lat: 48.8960665, lng: 18.1516181 },
    { lat: 48.8963552, lng: 18.1512817 },
    { lat: 48.8970676, lng: 18.1506613 },
    { lat: 48.8971036, lng: 18.150659 },
    { lat: 48.8971087, lng: 18.1506315 },
    { lat: 48.8973179, lng: 18.1505212 },
    { lat: 48.8974914, lng: 18.150478 },
    { lat: 48.8977905, lng: 18.1505556 },
    { lat: 48.8977926, lng: 18.1505144 },
    { lat: 48.8978126, lng: 18.1501567 },
    { lat: 48.8976698, lng: 18.1496976 },
    { lat: 48.8976532, lng: 18.14941 },
    { lat: 48.8976806, lng: 18.1487712 },
    { lat: 48.8977444, lng: 18.1485292 },
    { lat: 48.8980367, lng: 18.1480801 },
    { lat: 48.8982534, lng: 18.1475327 },
    { lat: 48.898489, lng: 18.1473138 },
    { lat: 48.898508, lng: 18.1472905 },
    { lat: 48.8985501, lng: 18.1472372 },
    { lat: 48.8987342, lng: 18.1470065 },
    { lat: 48.8990745, lng: 18.1465115 },
    { lat: 48.8995557, lng: 18.1456183 },
    { lat: 48.8997535, lng: 18.1451591 },
    { lat: 48.8998147, lng: 18.1449856 },
    { lat: 48.9000153, lng: 18.1439851 },
    { lat: 48.9000268, lng: 18.1433247 },
    { lat: 48.8999518, lng: 18.1428494 },
    { lat: 48.8999626, lng: 18.1424681 },
    { lat: 48.9001516, lng: 18.1419699 },
    { lat: 48.9006491, lng: 18.1417359 },
    { lat: 48.9009144, lng: 18.141348 },
    { lat: 48.9009256, lng: 18.1413035 },
    { lat: 48.9009448, lng: 18.141227 },
    { lat: 48.9010671, lng: 18.1407294 },
    { lat: 48.9014144, lng: 18.1399228 },
    { lat: 48.9016531, lng: 18.1394251 },
    { lat: 48.9018832, lng: 18.1391101 },
    { lat: 48.9019451, lng: 18.1389343 },
    { lat: 48.9019423, lng: 18.1387302 },
    { lat: 48.9018191, lng: 18.1382976 },
    { lat: 48.9017773, lng: 18.1377866 },
    { lat: 48.901759, lng: 18.137609 },
    { lat: 48.9019486, lng: 18.1371419 },
    { lat: 48.9020024, lng: 18.1366871 },
    { lat: 48.9020931, lng: 18.1363213 },
    { lat: 48.9019948, lng: 18.1350144 },
    { lat: 48.9021509, lng: 18.1344158 },
    { lat: 48.90221, lng: 18.1339567 },
    { lat: 48.9022438, lng: 18.1336839 },
    { lat: 48.9023608, lng: 18.1333437 },
    { lat: 48.9026374, lng: 18.1324478 },
    { lat: 48.9027568, lng: 18.132076 },
    { lat: 48.9029326, lng: 18.1317388 },
    { lat: 48.9032625, lng: 18.1312403 },
    { lat: 48.9034778, lng: 18.1308673 },
    { lat: 48.9038186, lng: 18.1303003 },
    { lat: 48.9041226, lng: 18.1295474 },
    { lat: 48.9045162, lng: 18.128802 },
    { lat: 48.9048424, lng: 18.1283187 },
    { lat: 48.9048974, lng: 18.1281363 },
    { lat: 48.9047887, lng: 18.1273502 },
    { lat: 48.9052285, lng: 18.1264726 },
    { lat: 48.9056397, lng: 18.1259902 },
    { lat: 48.9058266, lng: 18.1249931 },
    { lat: 48.9060561, lng: 18.12391 },
    { lat: 48.9062984, lng: 18.1227746 },
    { lat: 48.9064793, lng: 18.1211484 },
    { lat: 48.9066455, lng: 18.1207734 },
    { lat: 48.9068959, lng: 18.1205642 },
    { lat: 48.9072202, lng: 18.120532 },
    { lat: 48.9074177, lng: 18.1207359 },
    { lat: 48.9079043, lng: 18.1205642 },
    { lat: 48.9083795, lng: 18.1199962 },
    { lat: 48.9087422, lng: 18.1190491 },
    { lat: 48.9093004, lng: 18.1175052 },
    { lat: 48.9097105, lng: 18.1169894 },
    { lat: 48.9098815, lng: 18.1164152 },
    { lat: 48.9110306, lng: 18.1139671 },
    { lat: 48.9113334, lng: 18.1129845 },
    { lat: 48.9113894, lng: 18.1128466 },
    { lat: 48.9115666, lng: 18.1123944 },
    { lat: 48.9118003, lng: 18.1120072 },
    { lat: 48.912769, lng: 18.1113177 },
    { lat: 48.9131947, lng: 18.1109318 },
    { lat: 48.9148454, lng: 18.1101105 },
    { lat: 48.9151482, lng: 18.1093805 },
    { lat: 48.9155693, lng: 18.1083726 },
    { lat: 48.9157263, lng: 18.1080061 },
    { lat: 48.9162574, lng: 18.1065009 },
    { lat: 48.916599, lng: 18.1057211 },
    { lat: 48.9167696, lng: 18.1055067 },
    { lat: 48.9171089, lng: 18.1053282 },
    { lat: 48.9172923, lng: 18.1047927 },
    { lat: 48.9176247, lng: 18.1037177 },
    { lat: 48.9177369, lng: 18.1030647 },
    { lat: 48.9179545, lng: 18.1023043 },
    { lat: 48.9181062, lng: 18.1017889 },
    { lat: 48.9182294, lng: 18.1013863 },
    { lat: 48.9185313, lng: 18.1004061 },
    { lat: 48.9188694, lng: 18.0992743 },
    { lat: 48.9189547, lng: 18.0989916 },
    { lat: 48.9189789, lng: 18.0990221 },
    { lat: 48.9190045, lng: 18.0990517 },
    { lat: 48.919108, lng: 18.0986812 },
    { lat: 48.919318, lng: 18.0979243 },
    { lat: 48.9195909, lng: 18.0969336 },
    { lat: 48.9196053, lng: 18.0968882 },
    { lat: 48.9196117, lng: 18.0968637 },
    { lat: 48.9196665, lng: 18.0966389 },
    { lat: 48.9197848, lng: 18.0962502 },
    { lat: 48.9198627, lng: 18.0959595 },
    { lat: 48.9200351, lng: 18.0953939 },
    { lat: 48.9200135, lng: 18.095353 },
    { lat: 48.9200464, lng: 18.0952556 },
    { lat: 48.9200551, lng: 18.0952139 },
    { lat: 48.9200901, lng: 18.0944955 },
    { lat: 48.9200894, lng: 18.0944464 },
    { lat: 48.920136, lng: 18.0940243 },
    { lat: 48.921595, lng: 18.0891013 },
    { lat: 48.9216113, lng: 18.0890474 },
    { lat: 48.9216289, lng: 18.0889848 },
    { lat: 48.9216668, lng: 18.0888602 },
    { lat: 48.9216838, lng: 18.0888016 },
    { lat: 48.9217093, lng: 18.088716 },
    { lat: 48.921769, lng: 18.0885397 },
    { lat: 48.9217825, lng: 18.0884998 },
    { lat: 48.9218007, lng: 18.0884464 },
    { lat: 48.9219877, lng: 18.0878978 },
    { lat: 48.922005, lng: 18.0878449 },
    { lat: 48.9220242, lng: 18.0877885 },
    { lat: 48.9221176, lng: 18.0875133 },
    { lat: 48.9221516, lng: 18.0874961 },
    { lat: 48.9239715, lng: 18.0865583 },
    { lat: 48.9240296, lng: 18.0865286 },
    { lat: 48.9241081, lng: 18.0864057 },
    { lat: 48.9243958, lng: 18.085958 },
    { lat: 48.9246503, lng: 18.0855607 },
    { lat: 48.9246824, lng: 18.0855108 },
    { lat: 48.9248478, lng: 18.0852524 },
    { lat: 48.9248383, lng: 18.0837145 },
    { lat: 48.9247564, lng: 18.0825003 },
    { lat: 48.9244919, lng: 18.0804056 },
    { lat: 48.924456, lng: 18.0801193 },
    { lat: 48.9244453, lng: 18.0800332 },
    { lat: 48.9224805, lng: 18.0805914 },
    { lat: 48.9216866, lng: 18.0808329 },
    { lat: 48.9216322, lng: 18.0808496 },
    { lat: 48.9212186, lng: 18.0809754 },
    { lat: 48.9207618, lng: 18.0811139 },
    { lat: 48.920407, lng: 18.0812219 },
    { lat: 48.9201054, lng: 18.0813137 },
    { lat: 48.920061, lng: 18.0813275 },
    { lat: 48.9189621, lng: 18.0816613 },
    { lat: 48.9164884, lng: 18.0797491 },
    { lat: 48.9179307, lng: 18.0783347 },
    { lat: 48.9182636, lng: 18.0777736 },
    { lat: 48.9184865, lng: 18.0773988 },
    { lat: 48.918539, lng: 18.0773106 },
    { lat: 48.9186161, lng: 18.0771801 },
    { lat: 48.9193286, lng: 18.0759332 },
    { lat: 48.9196154, lng: 18.0754332 },
    { lat: 48.9197077, lng: 18.0752722 },
    { lat: 48.9197261, lng: 18.0752392 },
    { lat: 48.919769, lng: 18.0751642 },
    { lat: 48.9197792, lng: 18.0751079 },
    { lat: 48.920095, lng: 18.0733635 },
    { lat: 48.9196983, lng: 18.0731793 },
    { lat: 48.9194239, lng: 18.0730812 },
    { lat: 48.9189891, lng: 18.0726492 },
    { lat: 48.9184515, lng: 18.0715416 },
    { lat: 48.9181977, lng: 18.0712532 },
    { lat: 48.9177113, lng: 18.0707889 },
    { lat: 48.9174295, lng: 18.0703123 },
    { lat: 48.9166072, lng: 18.0697777 },
    { lat: 48.9157304, lng: 18.0693743 },
    { lat: 48.9151574, lng: 18.0688202 },
    { lat: 48.9146524, lng: 18.0682118 },
    { lat: 48.9144317, lng: 18.0678141 },
    { lat: 48.9143654, lng: 18.0677395 },
    { lat: 48.9138246, lng: 18.0671219 },
    { lat: 48.9136406, lng: 18.0667527 },
    { lat: 48.9134862, lng: 18.0662353 },
    { lat: 48.913266, lng: 18.0655182 },
    { lat: 48.9131139, lng: 18.0650572 },
    { lat: 48.913102, lng: 18.0650279 },
    { lat: 48.9130772, lng: 18.0649915 },
    { lat: 48.9128476, lng: 18.0647694 },
    { lat: 48.9122954, lng: 18.0647817 },
    { lat: 48.911268, lng: 18.0650064 },
    { lat: 48.9110373, lng: 18.0650572 },
    { lat: 48.9104022, lng: 18.0649446 },
    { lat: 48.9101456, lng: 18.0661176 },
    { lat: 48.9098947, lng: 18.0672876 },
    { lat: 48.9098397, lng: 18.0687419 },
    { lat: 48.9098129, lng: 18.0695004 },
    { lat: 48.9096896, lng: 18.0698804 },
    { lat: 48.9094818, lng: 18.0701476 },
    { lat: 48.9087655, lng: 18.0709665 },
    { lat: 48.9086348, lng: 18.0711409 },
    { lat: 48.9084511, lng: 18.0712642 },
    { lat: 48.9080077, lng: 18.0715757 },
    { lat: 48.9075098, lng: 18.0718008 },
    { lat: 48.9071273, lng: 18.0721307 },
    { lat: 48.9067381, lng: 18.0725406 },
    { lat: 48.9064171, lng: 18.0729002 },
    { lat: 48.9062413, lng: 18.0732291 },
    { lat: 48.9061289, lng: 18.0734157 },
    { lat: 48.9061186, lng: 18.0734289 },
    { lat: 48.9060493, lng: 18.0735175 },
    { lat: 48.906046, lng: 18.0735226 },
    { lat: 48.9060209, lng: 18.0735544 },
    { lat: 48.9057733, lng: 18.0740321 },
    { lat: 48.9055605, lng: 18.0746767 },
    { lat: 48.9055204, lng: 18.074799 },
    { lat: 48.9055173, lng: 18.0748066 },
    { lat: 48.9055047, lng: 18.0748424 },
    { lat: 48.9054982, lng: 18.0748648 },
    { lat: 48.905358, lng: 18.0752865 },
    { lat: 48.9053408, lng: 18.0753401 },
    { lat: 48.9051053, lng: 18.0760415 },
    { lat: 48.9050823, lng: 18.0761099 },
    { lat: 48.9050602, lng: 18.0761771 },
    { lat: 48.9049766, lng: 18.0764261 },
    { lat: 48.904696, lng: 18.077266 },
    { lat: 48.9046576, lng: 18.077382 },
    { lat: 48.9046577, lng: 18.077383 },
    { lat: 48.9046434, lng: 18.0774266 },
    { lat: 48.9046317, lng: 18.0774601 },
    { lat: 48.904623, lng: 18.0774863 },
    { lat: 48.9043974, lng: 18.0781631 },
    { lat: 48.9042456, lng: 18.0786164 },
    { lat: 48.9042391, lng: 18.0786364 },
    { lat: 48.9041245, lng: 18.0789764 },
    { lat: 48.9041223, lng: 18.0789806 },
    { lat: 48.9041099, lng: 18.0790182 },
    { lat: 48.9041051, lng: 18.0790346 },
    { lat: 48.9040915, lng: 18.0790726 },
    { lat: 48.9039552, lng: 18.0794798 },
    { lat: 48.9038331, lng: 18.0787813 },
    { lat: 48.9038216, lng: 18.0787179 },
    { lat: 48.9037502, lng: 18.0783095 },
    { lat: 48.9034323, lng: 18.0764976 },
    { lat: 48.9034271, lng: 18.0764291 },
    { lat: 48.9034238, lng: 18.0763721 },
    { lat: 48.9024007, lng: 18.0715482 },
    { lat: 48.9020066, lng: 18.0696691 },
    { lat: 48.902493, lng: 18.0681068 },
    { lat: 48.9025595, lng: 18.0678919 },
    { lat: 48.9026469, lng: 18.0676111 },
    { lat: 48.9028616, lng: 18.06692 },
    { lat: 48.9030581, lng: 18.0662888 },
    { lat: 48.9030743, lng: 18.0662367 },
    { lat: 48.9031266, lng: 18.0660686 },
    { lat: 48.9036402, lng: 18.0644089 },
    { lat: 48.9041291, lng: 18.0628339 },
    { lat: 48.904223, lng: 18.0615051 },
    { lat: 48.9042342, lng: 18.0613483 },
    { lat: 48.9042054, lng: 18.0611868 },
    { lat: 48.9037889, lng: 18.0588713 },
    { lat: 48.9037388, lng: 18.0582864 },
    { lat: 48.9037012, lng: 18.0578531 },
    { lat: 48.9036623, lng: 18.0574039 },
    { lat: 48.9036359, lng: 18.0571061 },
    { lat: 48.9036228, lng: 18.0569586 },
    { lat: 48.9035776, lng: 18.0564374 },
    { lat: 48.9035591, lng: 18.0562182 },
    { lat: 48.9035458, lng: 18.0560675 },
    { lat: 48.9035202, lng: 18.0557649 },
    { lat: 48.9035069, lng: 18.0556139 },
    { lat: 48.903487, lng: 18.0553906 },
    { lat: 48.9034479, lng: 18.0551483 },
    { lat: 48.9034323, lng: 18.055054 },
    { lat: 48.9033833, lng: 18.0547599 },
    { lat: 48.9033623, lng: 18.0546304 },
    { lat: 48.9033592, lng: 18.054614 },
    { lat: 48.9033502, lng: 18.0545574 },
    { lat: 48.9033482, lng: 18.054544 },
    { lat: 48.9032803, lng: 18.0541356 },
    { lat: 48.9032379, lng: 18.0538752 },
    { lat: 48.9031807, lng: 18.0535338 },
    { lat: 48.9031491, lng: 18.0533415 },
    { lat: 48.9030056, lng: 18.0524691 },
    { lat: 48.9028863, lng: 18.0517454 },
    { lat: 48.9024031, lng: 18.048836 },
    { lat: 48.9020071, lng: 18.0464505 },
    { lat: 48.9019456, lng: 18.0460753 },
    { lat: 48.9019318, lng: 18.0459932 },
    { lat: 48.9019265, lng: 18.0459624 },
    { lat: 48.9019199, lng: 18.0459267 },
    { lat: 48.9019124, lng: 18.045876 },
    { lat: 48.901818, lng: 18.0453015 },
    { lat: 48.9017478, lng: 18.0448759 },
    { lat: 48.9016966, lng: 18.0445639 },
    { lat: 48.9016703, lng: 18.0444003 },
    { lat: 48.9016686, lng: 18.0443926 },
    { lat: 48.9016669, lng: 18.0443831 },
    { lat: 48.9015948, lng: 18.0439412 },
    { lat: 48.901546, lng: 18.0436483 },
    { lat: 48.9015092, lng: 18.0434251 },
    { lat: 48.9014968, lng: 18.0433507 },
    { lat: 48.9014935, lng: 18.0433311 },
    { lat: 48.90149, lng: 18.0433104 },
    { lat: 48.9014876, lng: 18.0432899 },
    { lat: 48.9014551, lng: 18.0430992 },
    { lat: 48.9014481, lng: 18.0430567 },
    { lat: 48.9014373, lng: 18.0429886 },
    { lat: 48.9014197, lng: 18.0428822 },
    { lat: 48.9014109, lng: 18.0428289 },
    { lat: 48.9013973, lng: 18.04275 },
    { lat: 48.901371, lng: 18.0425893 },
    { lat: 48.9013641, lng: 18.0425482 },
    { lat: 48.9013377, lng: 18.0423879 },
    { lat: 48.9013341, lng: 18.0423629 },
    { lat: 48.9013011, lng: 18.0421672 },
    { lat: 48.9012888, lng: 18.0420949 },
    { lat: 48.9012679, lng: 18.0419676 },
    { lat: 48.9012519, lng: 18.0418682 },
    { lat: 48.9012383, lng: 18.0417897 },
    { lat: 48.9013723, lng: 18.0417278 },
    { lat: 48.9013822, lng: 18.0417236 },
    { lat: 48.9021964, lng: 18.0413475 },
    { lat: 48.9029248, lng: 18.0410106 },
    { lat: 48.9030897, lng: 18.0409345 },
    { lat: 48.9039027, lng: 18.0405588 },
    { lat: 48.9039601, lng: 18.0405324 },
    { lat: 48.9040501, lng: 18.0404909 },
    { lat: 48.9040667, lng: 18.0404836 },
    { lat: 48.9040953, lng: 18.0404711 },
    { lat: 48.9041271, lng: 18.0404571 },
    { lat: 48.9041568, lng: 18.0404442 },
    { lat: 48.9041965, lng: 18.0404266 },
    { lat: 48.9042118, lng: 18.040422 },
    { lat: 48.9042286, lng: 18.0404173 },
    { lat: 48.904455, lng: 18.0403504 },
    { lat: 48.9045015, lng: 18.0403366 },
    { lat: 48.9045171, lng: 18.040332 },
    { lat: 48.904551, lng: 18.0403145 },
    { lat: 48.9046093, lng: 18.0402873 },
    { lat: 48.9046321, lng: 18.0402738 },
    { lat: 48.9058812, lng: 18.0403286 },
    { lat: 48.9060942, lng: 18.0403794 },
    { lat: 48.9061995, lng: 18.0404043 },
    { lat: 48.9062413, lng: 18.0404241 },
    { lat: 48.9062879, lng: 18.0404476 },
    { lat: 48.9063798, lng: 18.0404944 },
    { lat: 48.906432, lng: 18.0405214 },
    { lat: 48.9066031, lng: 18.0406085 },
    { lat: 48.9067507, lng: 18.0406825 },
    { lat: 48.9073574, lng: 18.0409055 },
    { lat: 48.9080444, lng: 18.0410538 },
    { lat: 48.9084422, lng: 18.0411389 },
    { lat: 48.9085486, lng: 18.0411622 },
    { lat: 48.9086087, lng: 18.0411678 },
    { lat: 48.9090353, lng: 18.0412077 },
    { lat: 48.9095276, lng: 18.0413063 },
    { lat: 48.9097044, lng: 18.0413412 },
    { lat: 48.9103474, lng: 18.0414697 },
    { lat: 48.9104207, lng: 18.0414849 },
    { lat: 48.9109618, lng: 18.0413612 },
    { lat: 48.9111303, lng: 18.0413315 },
    { lat: 48.9117081, lng: 18.0412295 },
    { lat: 48.9123248, lng: 18.0411039 },
    { lat: 48.9124854, lng: 18.0411482 },
    { lat: 48.9127776, lng: 18.0412229 },
    { lat: 48.9133006, lng: 18.041117 },
    { lat: 48.9134376, lng: 18.0411064 },
    { lat: 48.913603, lng: 18.0409625 },
    { lat: 48.9139827, lng: 18.0410687 },
    { lat: 48.9142037, lng: 18.0411031 },
    { lat: 48.9146539, lng: 18.0411782 },
    { lat: 48.9151378, lng: 18.0408075 },
    { lat: 48.9156572, lng: 18.0403904 },
    { lat: 48.9158934, lng: 18.0404342 },
    { lat: 48.9164984, lng: 18.040337 },
    { lat: 48.9167953, lng: 18.0404566 },
    { lat: 48.9172487, lng: 18.0403928 },
    { lat: 48.9175158, lng: 18.0405961 },
    { lat: 48.9178263, lng: 18.0406004 },
    { lat: 48.9180369, lng: 18.0404969 },
    { lat: 48.9184044, lng: 18.0402582 },
    { lat: 48.9188119, lng: 18.0401421 },
    { lat: 48.9195133, lng: 18.0400771 },
    { lat: 48.9203929, lng: 18.0404489 },
    { lat: 48.9212751, lng: 18.0405966 },
    { lat: 48.9222954, lng: 18.0406375 },
    { lat: 48.9229562, lng: 18.0402629 },
    { lat: 48.9240425, lng: 18.039753 },
    { lat: 48.9246428, lng: 18.0397705 },
    { lat: 48.9250628, lng: 18.0392181 },
    { lat: 48.9250711, lng: 18.039203 },
    { lat: 48.9251741, lng: 18.039015 },
    { lat: 48.9255769, lng: 18.0382819 },
    { lat: 48.9258778, lng: 18.037817 },
    { lat: 48.9261304, lng: 18.0374269 },
    { lat: 48.9262649, lng: 18.0372561 },
    { lat: 48.9265094, lng: 18.0369453 },
    { lat: 48.9267112, lng: 18.0364593 },
    { lat: 48.9274582, lng: 18.0365335 },
    { lat: 48.9274711, lng: 18.0358875 },
    { lat: 48.9282615, lng: 18.0344541 },
    { lat: 48.9284843, lng: 18.0341156 },
    { lat: 48.9288572, lng: 18.0337364 },
    { lat: 48.9291639, lng: 18.0333701 },
    { lat: 48.929619, lng: 18.0328925 },
    { lat: 48.9299176, lng: 18.0323863 },
    { lat: 48.9304547, lng: 18.0316539 },
    { lat: 48.9308761, lng: 18.0308721 },
    { lat: 48.9313404, lng: 18.0302461 },
    { lat: 48.9316316, lng: 18.029692 },
    { lat: 48.9317676, lng: 18.0289112 },
    { lat: 48.931814, lng: 18.0277247 },
    { lat: 48.9318054, lng: 18.0267689 },
    { lat: 48.9326393, lng: 18.0257313 },
    { lat: 48.9326501, lng: 18.0247808 },
    { lat: 48.9329477, lng: 18.0234353 },
    { lat: 48.9331168, lng: 18.0229694 },
    { lat: 48.933689, lng: 18.0224328 },
    { lat: 48.9337756, lng: 18.0223521 },
    { lat: 48.9336744, lng: 18.0215697 },
    { lat: 48.9334639, lng: 18.0204463 },
    { lat: 48.9336839, lng: 18.0197768 },
    { lat: 48.9340008, lng: 18.0188113 },
    { lat: 48.9344202, lng: 18.0181141 },
    { lat: 48.9349798, lng: 18.0172876 },
    { lat: 48.9357427, lng: 18.0160415 },
    { lat: 48.936107, lng: 18.0154403 },
    { lat: 48.9365586, lng: 18.0146022 },
    { lat: 48.9367812, lng: 18.0139736 },
    { lat: 48.9368949, lng: 18.0138917 },
    { lat: 48.9370683, lng: 18.013768 },
    { lat: 48.9372815, lng: 18.0134598 },
    { lat: 48.9379687, lng: 18.0129017 },
    { lat: 48.9384143, lng: 18.0125156 },
    { lat: 48.9391117, lng: 18.0119018 },
    { lat: 48.9396257, lng: 18.0111995 },
    { lat: 48.9398478, lng: 18.0105461 },
    { lat: 48.9400611, lng: 18.0095704 },
    { lat: 48.9402208, lng: 18.0092346 },
    { lat: 48.9403772, lng: 18.0078476 },
    { lat: 48.9403739, lng: 18.0069839 },
    { lat: 48.940523, lng: 18.0062237 },
    { lat: 48.9408348, lng: 18.0051948 },
    { lat: 48.9414932, lng: 18.0035332 },
    { lat: 48.9420616, lng: 18.0026693 },
    { lat: 48.9427004, lng: 18.0016554 },
    { lat: 48.9431123, lng: 18.0008874 },
    { lat: 48.9433106, lng: 18.0005169 },
    { lat: 48.9433573, lng: 18.0004301 },
    { lat: 48.9436903, lng: 17.9997971 },
    { lat: 48.9442064, lng: 17.998639 },
    { lat: 48.9446135, lng: 17.9979025 },
    { lat: 48.9448971, lng: 17.9972233 },
    { lat: 48.9452642, lng: 17.9962496 },
    { lat: 48.9457357, lng: 17.9953668 },
    { lat: 48.9454697, lng: 17.9948586 },
    { lat: 48.9452535, lng: 17.993873 },
    { lat: 48.9453648, lng: 17.9931397 },
    { lat: 48.9455047, lng: 17.9927369 },
    { lat: 48.9457716, lng: 17.9922668 },
    { lat: 48.9460014, lng: 17.9918003 },
    { lat: 48.9464023, lng: 17.9913285 },
    { lat: 48.9465696, lng: 17.9907779 },
    { lat: 48.9468877, lng: 17.989745 },
    { lat: 48.9470585, lng: 17.9888333 },
    { lat: 48.9472334, lng: 17.9879941 },
    { lat: 48.9472194, lng: 17.9879098 },
  ],
  Hrabovka: [
    { lat: 48.9386221, lng: 18.0498726 },
    { lat: 48.9391004, lng: 18.0494265 },
    { lat: 48.9391506, lng: 18.0493802 },
    { lat: 48.9392533, lng: 18.049417 },
    { lat: 48.9395667, lng: 18.0489489 },
    { lat: 48.9395942, lng: 18.0490116 },
    { lat: 48.9396845, lng: 18.0491886 },
    { lat: 48.9397881, lng: 18.049395 },
    { lat: 48.9402754, lng: 18.0489656 },
    { lat: 48.9402195, lng: 18.0488586 },
    { lat: 48.9401863, lng: 18.0487967 },
    { lat: 48.940327, lng: 18.0486348 },
    { lat: 48.9405976, lng: 18.0483969 },
    { lat: 48.9409605, lng: 18.0481214 },
    { lat: 48.9409264, lng: 18.0480595 },
    { lat: 48.9409114, lng: 18.0480332 },
    { lat: 48.9408961, lng: 18.0480033 },
    { lat: 48.9408783, lng: 18.0479684 },
    { lat: 48.9408583, lng: 18.0479338 },
    { lat: 48.9408494, lng: 18.0479195 },
    { lat: 48.9408257, lng: 18.0478786 },
    { lat: 48.9408033, lng: 18.047827 },
    { lat: 48.9410403, lng: 18.0474202 },
    { lat: 48.9409709, lng: 18.0472978 },
    { lat: 48.9412413, lng: 18.0468065 },
    { lat: 48.9412616, lng: 18.04677 },
    { lat: 48.941313, lng: 18.0466819 },
    { lat: 48.9408786, lng: 18.0459719 },
    { lat: 48.9404048, lng: 18.0452037 },
    { lat: 48.939914, lng: 18.04436 },
    { lat: 48.9397459, lng: 18.0439759 },
    { lat: 48.9394574, lng: 18.0431946 },
    { lat: 48.9399516, lng: 18.042766 },
    { lat: 48.9403259, lng: 18.0424547 },
    { lat: 48.9402726, lng: 18.042332 },
    { lat: 48.9402355, lng: 18.0422397 },
    { lat: 48.9402273, lng: 18.0422168 },
    { lat: 48.9401918, lng: 18.0421275 },
    { lat: 48.9403806, lng: 18.0419229 },
    { lat: 48.9403262, lng: 18.0418211 },
    { lat: 48.9402964, lng: 18.0417576 },
    { lat: 48.9402739, lng: 18.0417201 },
    { lat: 48.9402713, lng: 18.0417129 },
    { lat: 48.9402469, lng: 18.0416631 },
    { lat: 48.9402202, lng: 18.041611 },
    { lat: 48.940196, lng: 18.0415658 },
    { lat: 48.9401186, lng: 18.0414194 },
    { lat: 48.9401134, lng: 18.0414079 },
    { lat: 48.9400869, lng: 18.0413594 },
    { lat: 48.9400424, lng: 18.0412728 },
    { lat: 48.9400041, lng: 18.0412015 },
    { lat: 48.9399729, lng: 18.0411479 },
    { lat: 48.9399488, lng: 18.0411045 },
    { lat: 48.9399427, lng: 18.0410928 },
    { lat: 48.9399111, lng: 18.0410382 },
    { lat: 48.939886, lng: 18.0409935 },
    { lat: 48.9398255, lng: 18.040885 },
    { lat: 48.9398114, lng: 18.0408585 },
    { lat: 48.9397731, lng: 18.0407826 },
    { lat: 48.9397696, lng: 18.0407784 },
    { lat: 48.9397344, lng: 18.0407185 },
    { lat: 48.939704, lng: 18.0406612 },
    { lat: 48.9396941, lng: 18.0406489 },
    { lat: 48.9400931, lng: 18.0401577 },
    { lat: 48.9400837, lng: 18.040131 },
    { lat: 48.9404315, lng: 18.0399433 },
    { lat: 48.9408952, lng: 18.0397862 },
    { lat: 48.9412842, lng: 18.039511 },
    { lat: 48.9416678, lng: 18.039317 },
    { lat: 48.9416712, lng: 18.0392795 },
    { lat: 48.9417035, lng: 18.0389416 },
    { lat: 48.9416103, lng: 18.0384132 },
    { lat: 48.9415179, lng: 18.0379159 },
    { lat: 48.9413818, lng: 18.0373861 },
    { lat: 48.9413639, lng: 18.0369071 },
    { lat: 48.9413567, lng: 18.0367282 },
    { lat: 48.9413471, lng: 18.0362936 },
    { lat: 48.9411378, lng: 18.0358434 },
    { lat: 48.9409564, lng: 18.0356572 },
    { lat: 48.9409168, lng: 18.0353086 },
    { lat: 48.9405536, lng: 18.0349079 },
    { lat: 48.9401908, lng: 18.0345925 },
    { lat: 48.9398324, lng: 18.0340232 },
    { lat: 48.9394724, lng: 18.0335991 },
    { lat: 48.9392647, lng: 18.0330413 },
    { lat: 48.9391385, lng: 18.0327575 },
    { lat: 48.9390543, lng: 18.0325711 },
    { lat: 48.938882, lng: 18.0319615 },
    { lat: 48.9384615, lng: 18.030781 },
    { lat: 48.9384883, lng: 18.0307752 },
    { lat: 48.9384694, lng: 18.0305491 },
    { lat: 48.9384753, lng: 18.0304 },
    { lat: 48.9384198, lng: 18.0299757 },
    { lat: 48.9382717, lng: 18.0293179 },
    { lat: 48.9381653, lng: 18.0288104 },
    { lat: 48.9379948, lng: 18.0282365 },
    { lat: 48.9380041, lng: 18.0278741 },
    { lat: 48.9378026, lng: 18.0277506 },
    { lat: 48.9375629, lng: 18.0274545 },
    { lat: 48.9375199, lng: 18.0273735 },
    { lat: 48.9373102, lng: 18.0271093 },
    { lat: 48.9372013, lng: 18.0267945 },
    { lat: 48.9369591, lng: 18.0264558 },
    { lat: 48.937688, lng: 18.0254546 },
    { lat: 48.9381447, lng: 18.0257919 },
    { lat: 48.9382175, lng: 18.0257074 },
    { lat: 48.9373165, lng: 18.0248146 },
    { lat: 48.9366207, lng: 18.0240379 },
    { lat: 48.9366487, lng: 18.0239753 },
    { lat: 48.9372927, lng: 18.0224915 },
    { lat: 48.9372204, lng: 18.0223369 },
    { lat: 48.9369939, lng: 18.0220557 },
    { lat: 48.9374568, lng: 18.0210233 },
    { lat: 48.9377049, lng: 18.0204458 },
    { lat: 48.937735, lng: 18.0204177 },
    { lat: 48.9382533, lng: 18.0199216 },
    { lat: 48.9385488, lng: 18.01971 },
    { lat: 48.9391774, lng: 18.0195745 },
    { lat: 48.9393691, lng: 18.0194559 },
    { lat: 48.9395629, lng: 18.0194134 },
    { lat: 48.9398731, lng: 18.019352 },
    { lat: 48.9399861, lng: 18.0187037 },
    { lat: 48.9399423, lng: 18.0183611 },
    { lat: 48.9400711, lng: 18.0180948 },
    { lat: 48.9403427, lng: 18.0182203 },
    { lat: 48.9405985, lng: 18.0181953 },
    { lat: 48.940587, lng: 18.0173492 },
    { lat: 48.940481, lng: 18.0161627 },
    { lat: 48.9401623, lng: 18.015867 },
    { lat: 48.940243, lng: 18.0155505 },
    { lat: 48.9404103, lng: 18.0148856 },
    { lat: 48.9405011, lng: 18.0145268 },
    { lat: 48.9406756, lng: 18.0142536 },
    { lat: 48.9408123, lng: 18.0140615 },
    { lat: 48.9410044, lng: 18.0138923 },
    { lat: 48.9411216, lng: 18.0135296 },
    { lat: 48.9411089, lng: 18.013294 },
    { lat: 48.9411943, lng: 18.013094 },
    { lat: 48.9415087, lng: 18.0122805 },
    { lat: 48.9416785, lng: 18.0117139 },
    { lat: 48.9417796, lng: 18.0114395 },
    { lat: 48.9420272, lng: 18.0107395 },
    { lat: 48.9422364, lng: 18.0099029 },
    { lat: 48.9423831, lng: 18.0091538 },
    { lat: 48.9425459, lng: 18.0082963 },
    { lat: 48.9427372, lng: 18.0074646 },
    { lat: 48.9429729, lng: 18.0068291 },
    { lat: 48.9431635, lng: 18.0064847 },
    { lat: 48.9428025, lng: 18.0060645 },
    { lat: 48.9425918, lng: 18.0058203 },
    { lat: 48.9420753, lng: 18.0052908 },
    { lat: 48.9424027, lng: 18.0044906 },
    { lat: 48.9424465, lng: 18.004503 },
    { lat: 48.9432403, lng: 18.0031338 },
    { lat: 48.9435191, lng: 18.0026356 },
    { lat: 48.9435468, lng: 18.002588 },
    { lat: 48.9440185, lng: 18.0017649 },
    { lat: 48.9445546, lng: 18.000845 },
    { lat: 48.9445729, lng: 18.0008138 },
    { lat: 48.944763, lng: 18.0010296 },
    { lat: 48.9452666, lng: 18.0000017 },
    { lat: 48.9456645, lng: 17.9991735 },
    { lat: 48.9460655, lng: 17.998334 },
    { lat: 48.9464948, lng: 17.9974254 },
    { lat: 48.946931, lng: 17.9964773 },
    { lat: 48.9471266, lng: 17.995548 },
    { lat: 48.9475667, lng: 17.9945894 },
    { lat: 48.9480771, lng: 17.9936764 },
    { lat: 48.9482743, lng: 17.9929637 },
    { lat: 48.948672, lng: 17.9924934 },
    { lat: 48.9488703, lng: 17.9917052 },
    { lat: 48.9490369, lng: 17.9910466 },
    { lat: 48.9492336, lng: 17.9909785 },
    { lat: 48.949584, lng: 17.9910241 },
    { lat: 48.9499665, lng: 17.9910966 },
    { lat: 48.9505058, lng: 17.9904813 },
    { lat: 48.9509076, lng: 17.9901404 },
    { lat: 48.9511483, lng: 17.9895715 },
    { lat: 48.9512847, lng: 17.9889941 },
    { lat: 48.9513003, lng: 17.9889342 },
    { lat: 48.951314, lng: 17.9888817 },
    { lat: 48.9514325, lng: 17.98825 },
    { lat: 48.951296, lng: 17.9877573 },
    { lat: 48.9505913, lng: 17.9869254 },
    { lat: 48.9499025, lng: 17.9868634 },
    { lat: 48.9498356, lng: 17.9868571 },
    { lat: 48.9487859, lng: 17.9859591 },
    { lat: 48.9483866, lng: 17.985546 },
    { lat: 48.9482004, lng: 17.9852558 },
    { lat: 48.9478799, lng: 17.9845511 },
    { lat: 48.9476814, lng: 17.9836395 },
    { lat: 48.9474251, lng: 17.9831955 },
    { lat: 48.9471916, lng: 17.9827993 },
    { lat: 48.947657, lng: 17.9820978 },
    { lat: 48.9472421, lng: 17.9815335 },
    { lat: 48.9468381, lng: 17.9809649 },
    { lat: 48.9464823, lng: 17.9803477 },
    { lat: 48.9460358, lng: 17.9798603 },
    { lat: 48.9457072, lng: 17.9793443 },
    { lat: 48.9453347, lng: 17.978752 },
    { lat: 48.9449779, lng: 17.9780233 },
    { lat: 48.944598, lng: 17.9775732 },
    { lat: 48.9440005, lng: 17.9768612 },
    { lat: 48.9435749, lng: 17.9762881 },
    { lat: 48.943455, lng: 17.976052 },
    { lat: 48.9433413, lng: 17.9754105 },
    { lat: 48.9432976, lng: 17.9751683 },
    { lat: 48.9431761, lng: 17.9745853 },
    { lat: 48.942957, lng: 17.9739901 },
    { lat: 48.9429237, lng: 17.9737139 },
    { lat: 48.942379, lng: 17.9735513 },
    { lat: 48.9423805, lng: 17.9735762 },
    { lat: 48.9421786, lng: 17.9737133 },
    { lat: 48.9419235, lng: 17.9742062 },
    { lat: 48.9419271, lng: 17.9742463 },
    { lat: 48.9423011, lng: 17.9748667 },
    { lat: 48.9424776, lng: 17.9754433 },
    { lat: 48.9424847, lng: 17.975946 },
    { lat: 48.9424211, lng: 17.9762822 },
    { lat: 48.9425319, lng: 17.9771431 },
    { lat: 48.9427719, lng: 17.9778051 },
    { lat: 48.9431394, lng: 17.9786167 },
    { lat: 48.9436857, lng: 17.9793787 },
    { lat: 48.9443677, lng: 17.9802451 },
    { lat: 48.9445412, lng: 17.9805834 },
    { lat: 48.9445322, lng: 17.9805979 },
    { lat: 48.9448958, lng: 17.9812319 },
    { lat: 48.9451863, lng: 17.9816408 },
    { lat: 48.9454717, lng: 17.9825746 },
    { lat: 48.9454054, lng: 17.9831685 },
    { lat: 48.9453164, lng: 17.9840524 },
    { lat: 48.9453628, lng: 17.9846813 },
    { lat: 48.9456799, lng: 17.9856162 },
    { lat: 48.9461154, lng: 17.9853292 },
    { lat: 48.9467431, lng: 17.9851409 },
    { lat: 48.9470569, lng: 17.9849481 },
    { lat: 48.9470593, lng: 17.98497 },
    { lat: 48.9471513, lng: 17.985649 },
    { lat: 48.9471591, lng: 17.9862368 },
    { lat: 48.9471606, lng: 17.9863217 },
    { lat: 48.9471682, lng: 17.9869279 },
    { lat: 48.947202, lng: 17.9877675 },
    { lat: 48.9472137, lng: 17.9878488 },
    { lat: 48.9472194, lng: 17.9879098 },
    { lat: 48.9472334, lng: 17.9879941 },
    { lat: 48.9470585, lng: 17.9888333 },
    { lat: 48.9468877, lng: 17.989745 },
    { lat: 48.9465696, lng: 17.9907779 },
    { lat: 48.9464023, lng: 17.9913285 },
    { lat: 48.9460014, lng: 17.9918003 },
    { lat: 48.9457716, lng: 17.9922668 },
    { lat: 48.9455047, lng: 17.9927369 },
    { lat: 48.9453648, lng: 17.9931397 },
    { lat: 48.9452535, lng: 17.993873 },
    { lat: 48.9454697, lng: 17.9948586 },
    { lat: 48.9457357, lng: 17.9953668 },
    { lat: 48.9452642, lng: 17.9962496 },
    { lat: 48.9448971, lng: 17.9972233 },
    { lat: 48.9446135, lng: 17.9979025 },
    { lat: 48.9442064, lng: 17.998639 },
    { lat: 48.9436903, lng: 17.9997971 },
    { lat: 48.9433573, lng: 18.0004301 },
    { lat: 48.9433106, lng: 18.0005169 },
    { lat: 48.9431123, lng: 18.0008874 },
    { lat: 48.9427004, lng: 18.0016554 },
    { lat: 48.9420616, lng: 18.0026693 },
    { lat: 48.9414932, lng: 18.0035332 },
    { lat: 48.9408348, lng: 18.0051948 },
    { lat: 48.940523, lng: 18.0062237 },
    { lat: 48.9403739, lng: 18.0069839 },
    { lat: 48.9403772, lng: 18.0078476 },
    { lat: 48.9402208, lng: 18.0092346 },
    { lat: 48.9400611, lng: 18.0095704 },
    { lat: 48.9398478, lng: 18.0105461 },
    { lat: 48.9396257, lng: 18.0111995 },
    { lat: 48.9391117, lng: 18.0119018 },
    { lat: 48.9384143, lng: 18.0125156 },
    { lat: 48.9379687, lng: 18.0129017 },
    { lat: 48.9372815, lng: 18.0134598 },
    { lat: 48.9370683, lng: 18.013768 },
    { lat: 48.9368949, lng: 18.0138917 },
    { lat: 48.9367812, lng: 18.0139736 },
    { lat: 48.9365586, lng: 18.0146022 },
    { lat: 48.936107, lng: 18.0154403 },
    { lat: 48.9357427, lng: 18.0160415 },
    { lat: 48.9349798, lng: 18.0172876 },
    { lat: 48.9344202, lng: 18.0181141 },
    { lat: 48.9340008, lng: 18.0188113 },
    { lat: 48.9336839, lng: 18.0197768 },
    { lat: 48.9334639, lng: 18.0204463 },
    { lat: 48.9336744, lng: 18.0215697 },
    { lat: 48.9337756, lng: 18.0223521 },
    { lat: 48.933689, lng: 18.0224328 },
    { lat: 48.9331168, lng: 18.0229694 },
    { lat: 48.9329477, lng: 18.0234353 },
    { lat: 48.9326501, lng: 18.0247808 },
    { lat: 48.9326393, lng: 18.0257313 },
    { lat: 48.9318054, lng: 18.0267689 },
    { lat: 48.931814, lng: 18.0277247 },
    { lat: 48.9317676, lng: 18.0289112 },
    { lat: 48.9316316, lng: 18.029692 },
    { lat: 48.9313404, lng: 18.0302461 },
    { lat: 48.9308761, lng: 18.0308721 },
    { lat: 48.9304547, lng: 18.0316539 },
    { lat: 48.9299176, lng: 18.0323863 },
    { lat: 48.929619, lng: 18.0328925 },
    { lat: 48.9291639, lng: 18.0333701 },
    { lat: 48.9288572, lng: 18.0337364 },
    { lat: 48.9284843, lng: 18.0341156 },
    { lat: 48.9282615, lng: 18.0344541 },
    { lat: 48.9274711, lng: 18.0358875 },
    { lat: 48.9274582, lng: 18.0365335 },
    { lat: 48.9267112, lng: 18.0364593 },
    { lat: 48.9265094, lng: 18.0369453 },
    { lat: 48.9262649, lng: 18.0372561 },
    { lat: 48.9261304, lng: 18.0374269 },
    { lat: 48.9258778, lng: 18.037817 },
    { lat: 48.9255769, lng: 18.0382819 },
    { lat: 48.9251741, lng: 18.039015 },
    { lat: 48.9250711, lng: 18.039203 },
    { lat: 48.9250628, lng: 18.0392181 },
    { lat: 48.9251006, lng: 18.0392249 },
    { lat: 48.9254519, lng: 18.0392883 },
    { lat: 48.9255318, lng: 18.0393033 },
    { lat: 48.9256059, lng: 18.0393167 },
    { lat: 48.9258638, lng: 18.0393629 },
    { lat: 48.9263017, lng: 18.0394426 },
    { lat: 48.927988, lng: 18.0399839 },
    { lat: 48.9280203, lng: 18.0399946 },
    { lat: 48.9280801, lng: 18.0400138 },
    { lat: 48.9281468, lng: 18.0400353 },
    { lat: 48.9281594, lng: 18.040039 },
    { lat: 48.9285933, lng: 18.0401637 },
    { lat: 48.9286919, lng: 18.0401917 },
    { lat: 48.9287242, lng: 18.0402013 },
    { lat: 48.9287655, lng: 18.040213 },
    { lat: 48.9287261, lng: 18.0402901 },
    { lat: 48.92859, lng: 18.0405515 },
    { lat: 48.9284561, lng: 18.0408082 },
    { lat: 48.9284116, lng: 18.0408954 },
    { lat: 48.9283888, lng: 18.0409674 },
    { lat: 48.9283449, lng: 18.0411061 },
    { lat: 48.9283013, lng: 18.0412448 },
    { lat: 48.9282729, lng: 18.0413369 },
    { lat: 48.9282461, lng: 18.0414213 },
    { lat: 48.9280546, lng: 18.0416392 },
    { lat: 48.9276867, lng: 18.0418515 },
    { lat: 48.9275108, lng: 18.0420446 },
    { lat: 48.9273551, lng: 18.0425674 },
    { lat: 48.9270888, lng: 18.0430266 },
    { lat: 48.9270262, lng: 18.0431542 },
    { lat: 48.9268164, lng: 18.0435798 },
    { lat: 48.9267053, lng: 18.0437262 },
    { lat: 48.9266236, lng: 18.0438334 },
    { lat: 48.9265547, lng: 18.0438728 },
    { lat: 48.9263549, lng: 18.0439867 },
    { lat: 48.9256245, lng: 18.044404 },
    { lat: 48.9253185, lng: 18.0444798 },
    { lat: 48.9253013, lng: 18.0444739 },
    { lat: 48.9249549, lng: 18.0443416 },
    { lat: 48.9245201, lng: 18.044569 },
    { lat: 48.9242029, lng: 18.0448939 },
    { lat: 48.9240995, lng: 18.044999 },
    { lat: 48.9237891, lng: 18.0456302 },
    { lat: 48.9236595, lng: 18.0466945 },
    { lat: 48.9235486, lng: 18.0472643 },
    { lat: 48.923247, lng: 18.0481459 },
    { lat: 48.9231127, lng: 18.0485294 },
    { lat: 48.9230902, lng: 18.0486666 },
    { lat: 48.9230427, lng: 18.0489537 },
    { lat: 48.9230243, lng: 18.0490645 },
    { lat: 48.923026, lng: 18.0493263 },
    { lat: 48.9230398, lng: 18.0497924 },
    { lat: 48.9231124, lng: 18.050317 },
    { lat: 48.9231708, lng: 18.0506242 },
    { lat: 48.9234603, lng: 18.0515006 },
    { lat: 48.9234883, lng: 18.0516103 },
    { lat: 48.9235344, lng: 18.051744 },
    { lat: 48.9236248, lng: 18.0519945 },
    { lat: 48.9238181, lng: 18.0525021 },
    { lat: 48.9238329, lng: 18.0525396 },
    { lat: 48.9239567, lng: 18.052707 },
    { lat: 48.9241243, lng: 18.0529331 },
    { lat: 48.924144, lng: 18.0529781 },
    { lat: 48.9241636, lng: 18.0538551 },
    { lat: 48.9241912, lng: 18.0543624 },
    { lat: 48.9241748, lng: 18.0546238 },
    { lat: 48.924166, lng: 18.0551073 },
    { lat: 48.9242236, lng: 18.0555747 },
    { lat: 48.9244888, lng: 18.05621 },
    { lat: 48.9247161, lng: 18.0568896 },
    { lat: 48.9248221, lng: 18.0570232 },
    { lat: 48.9250805, lng: 18.0572719 },
    { lat: 48.9252622, lng: 18.0574473 },
    { lat: 48.9255203, lng: 18.0577319 },
    { lat: 48.9256341, lng: 18.0578824 },
    { lat: 48.9257661, lng: 18.0581331 },
    { lat: 48.9261902, lng: 18.0588788 },
    { lat: 48.926459, lng: 18.0593263 },
    { lat: 48.9269013, lng: 18.0602121 },
    { lat: 48.9270014, lng: 18.0603838 },
    { lat: 48.9270424, lng: 18.0604658 },
    { lat: 48.9270578, lng: 18.0604982 },
    { lat: 48.9270828, lng: 18.0605372 },
    { lat: 48.9275727, lng: 18.0611947 },
    { lat: 48.928123, lng: 18.0618467 },
    { lat: 48.9281837, lng: 18.0619178 },
    { lat: 48.9282067, lng: 18.0619431 },
    { lat: 48.9282284, lng: 18.0619699 },
    { lat: 48.9282357, lng: 18.0619776 },
    { lat: 48.9282841, lng: 18.0620335 },
    { lat: 48.9282951, lng: 18.0620467 },
    { lat: 48.9283012, lng: 18.0620538 },
    { lat: 48.92841, lng: 18.0621806 },
    { lat: 48.9286906, lng: 18.062539 },
    { lat: 48.9287426, lng: 18.0625582 },
    { lat: 48.9287494, lng: 18.0625817 },
    { lat: 48.9287666, lng: 18.0626203 },
    { lat: 48.9288881, lng: 18.0627902 },
    { lat: 48.9289138, lng: 18.0628255 },
    { lat: 48.9289851, lng: 18.0628679 },
    { lat: 48.9290471, lng: 18.0629239 },
    { lat: 48.9292057, lng: 18.063068 },
    { lat: 48.9292836, lng: 18.0626059 },
    { lat: 48.9293464, lng: 18.0622113 },
    { lat: 48.9293724, lng: 18.0621181 },
    { lat: 48.9295204, lng: 18.0616961 },
    { lat: 48.9295509, lng: 18.0615589 },
    { lat: 48.9295737, lng: 18.0614671 },
    { lat: 48.9295914, lng: 18.0613865 },
    { lat: 48.9296024, lng: 18.0613606 },
    { lat: 48.9296181, lng: 18.0613205 },
    { lat: 48.929638, lng: 18.061274 },
    { lat: 48.9296909, lng: 18.0611384 },
    { lat: 48.9297233, lng: 18.061054 },
    { lat: 48.9297333, lng: 18.06103 },
    { lat: 48.9297584, lng: 18.0609785 },
    { lat: 48.9298192, lng: 18.0608207 },
    { lat: 48.9299166, lng: 18.0605771 },
    { lat: 48.9299521, lng: 18.0604887 },
    { lat: 48.929978, lng: 18.0604314 },
    { lat: 48.9300492, lng: 18.0603339 },
    { lat: 48.93016, lng: 18.0601104 },
    { lat: 48.930248, lng: 18.0599927 },
    { lat: 48.9303663, lng: 18.0598163 },
    { lat: 48.9303522, lng: 18.0597486 },
    { lat: 48.9303354, lng: 18.0596726 },
    { lat: 48.9304158, lng: 18.059446 },
    { lat: 48.9304874, lng: 18.0592784 },
    { lat: 48.9306402, lng: 18.0589603 },
    { lat: 48.930768, lng: 18.0587923 },
    { lat: 48.9311454, lng: 18.058319 },
    { lat: 48.9309852, lng: 18.058066 },
    { lat: 48.9313382, lng: 18.0577733 },
    { lat: 48.9313961, lng: 18.057684 },
    { lat: 48.9314207, lng: 18.0575637 },
    { lat: 48.9314154, lng: 18.0573548 },
    { lat: 48.9313707, lng: 18.0571128 },
    { lat: 48.9313571, lng: 18.0570532 },
    { lat: 48.9311983, lng: 18.0569422 },
    { lat: 48.9311365, lng: 18.0567965 },
    { lat: 48.9311398, lng: 18.0566012 },
    { lat: 48.9311869, lng: 18.0563436 },
    { lat: 48.9313407, lng: 18.0558681 },
    { lat: 48.931355, lng: 18.0556314 },
    { lat: 48.931362, lng: 18.0555181 },
    { lat: 48.9315087, lng: 18.0551509 },
    { lat: 48.9316887, lng: 18.0548521 },
    { lat: 48.9316561, lng: 18.0547972 },
    { lat: 48.9319259, lng: 18.0543 },
    { lat: 48.9321216, lng: 18.0540557 },
    { lat: 48.9322535, lng: 18.0540132 },
    { lat: 48.9324594, lng: 18.053774 },
    { lat: 48.9324948, lng: 18.0535861 },
    { lat: 48.9325733, lng: 18.0534406 },
    { lat: 48.9327008, lng: 18.0532511 },
    { lat: 48.93293, lng: 18.0528856 },
    { lat: 48.9330908, lng: 18.0525492 },
    { lat: 48.9331796, lng: 18.0523861 },
    { lat: 48.933273, lng: 18.0523447 },
    { lat: 48.9334007, lng: 18.0523849 },
    { lat: 48.9335227, lng: 18.0524051 },
    { lat: 48.9335464, lng: 18.0524837 },
    { lat: 48.9337656, lng: 18.0522571 },
    { lat: 48.9339407, lng: 18.0523555 },
    { lat: 48.9341333, lng: 18.0523884 },
    { lat: 48.9342755, lng: 18.0523136 },
    { lat: 48.9345174, lng: 18.0523169 },
    { lat: 48.9345355, lng: 18.0523187 },
    { lat: 48.9350345, lng: 18.0523993 },
    { lat: 48.9350361, lng: 18.0523865 },
    { lat: 48.9354386, lng: 18.052245 },
    { lat: 48.9355887, lng: 18.0522283 },
    { lat: 48.935771, lng: 18.0522551 },
    { lat: 48.935816, lng: 18.052273 },
    { lat: 48.9359548, lng: 18.0523286 },
    { lat: 48.9360928, lng: 18.0525297 },
    { lat: 48.936944, lng: 18.0517626 },
    { lat: 48.937337, lng: 18.0513991 },
    { lat: 48.9374377, lng: 18.051253 },
    { lat: 48.9384283, lng: 18.0498187 },
    { lat: 48.9386221, lng: 18.0498726 },
  ],
  SkalkanadVáhom: [
    { lat: 48.9248478, lng: 18.0852524 },
    { lat: 48.9261643, lng: 18.0860562 },
    { lat: 48.9271122, lng: 18.0866342 },
    { lat: 48.9275579, lng: 18.0869064 },
    { lat: 48.9276644, lng: 18.0869718 },
    { lat: 48.9278775, lng: 18.0871022 },
    { lat: 48.9287142, lng: 18.0876144 },
    { lat: 48.9289059, lng: 18.0877311 },
    { lat: 48.9294593, lng: 18.0880685 },
    { lat: 48.9296339, lng: 18.088175 },
    { lat: 48.92969, lng: 18.0882095 },
    { lat: 48.9298876, lng: 18.0883294 },
    { lat: 48.9315303, lng: 18.08933 },
    { lat: 48.9318408, lng: 18.0902804 },
    { lat: 48.9327752, lng: 18.0913091 },
    { lat: 48.9335345, lng: 18.0919057 },
    { lat: 48.935154, lng: 18.0927402 },
    { lat: 48.9364545, lng: 18.093526 },
    { lat: 48.937837, lng: 18.0943598 },
    { lat: 48.9388896, lng: 18.0949944 },
    { lat: 48.9397473, lng: 18.0955762 },
    { lat: 48.9400349, lng: 18.0951503 },
    { lat: 48.9400734, lng: 18.095093 },
    { lat: 48.9401825, lng: 18.0949316 },
    { lat: 48.9403467, lng: 18.0946885 },
    { lat: 48.9404756, lng: 18.0944985 },
    { lat: 48.9404995, lng: 18.0944619 },
    { lat: 48.9412342, lng: 18.0933805 },
    { lat: 48.941526, lng: 18.0927699 },
    { lat: 48.9426416, lng: 18.0904332 },
    { lat: 48.9429323, lng: 18.0898248 },
    { lat: 48.9431588, lng: 18.0893518 },
    { lat: 48.9431987, lng: 18.0892667 },
    { lat: 48.9432038, lng: 18.0892571 },
    { lat: 48.9432438, lng: 18.0892033 },
    { lat: 48.9434416, lng: 18.0889222 },
    { lat: 48.9436325, lng: 18.0886506 },
    { lat: 48.9440254, lng: 18.0880917 },
    { lat: 48.9444625, lng: 18.0874597 },
    { lat: 48.9445204, lng: 18.0873299 },
    { lat: 48.9451176, lng: 18.0859382 },
    { lat: 48.9460172, lng: 18.0835965 },
    { lat: 48.9461758, lng: 18.083181 },
    { lat: 48.9497855, lng: 18.0741549 },
    { lat: 48.9498555, lng: 18.073981 },
    { lat: 48.9500986, lng: 18.0733724 },
    { lat: 48.9501199, lng: 18.073138 },
    { lat: 48.9503266, lng: 18.0722513 },
    { lat: 48.9504059, lng: 18.0715394 },
    { lat: 48.9504587, lng: 18.0714023 },
    { lat: 48.9505357, lng: 18.0710203 },
    { lat: 48.9505455, lng: 18.0709726 },
    { lat: 48.95055, lng: 18.0705677 },
    { lat: 48.9505369, lng: 18.0703235 },
    { lat: 48.9506068, lng: 18.0695479 },
    { lat: 48.9506443, lng: 18.0691295 },
    { lat: 48.9506672, lng: 18.0682256 },
    { lat: 48.9506048, lng: 18.0677194 },
    { lat: 48.950499, lng: 18.0677021 },
    { lat: 48.9504417, lng: 18.0671464 },
    { lat: 48.9505958, lng: 18.0668079 },
    { lat: 48.9507623, lng: 18.0667445 },
    { lat: 48.9511457, lng: 18.0663324 },
    { lat: 48.9515537, lng: 18.0660538 },
    { lat: 48.9519129, lng: 18.065786 },
    { lat: 48.9527285, lng: 18.0650851 },
    { lat: 48.9532861, lng: 18.064392 },
    { lat: 48.9532717, lng: 18.0642431 },
    { lat: 48.9536425, lng: 18.0637127 },
    { lat: 48.9537217, lng: 18.0635228 },
    { lat: 48.9537832, lng: 18.0631874 },
    { lat: 48.9541113, lng: 18.0622943 },
    { lat: 48.9543707, lng: 18.0620396 },
    { lat: 48.9549682, lng: 18.0618707 },
    { lat: 48.955695, lng: 18.0615649 },
    { lat: 48.9561861, lng: 18.0613536 },
    { lat: 48.9564733, lng: 18.061056 },
    { lat: 48.9566683, lng: 18.0606461 },
    { lat: 48.9570171, lng: 18.0590061 },
    { lat: 48.9571882, lng: 18.0584189 },
    { lat: 48.9573519, lng: 18.0577439 },
    { lat: 48.9576505, lng: 18.0567918 },
    { lat: 48.9578202, lng: 18.0565111 },
    { lat: 48.9588771, lng: 18.0554969 },
    { lat: 48.958523, lng: 18.0556575 },
    { lat: 48.9581822, lng: 18.0558686 },
    { lat: 48.9576247, lng: 18.056331 },
    { lat: 48.9568179, lng: 18.0569102 },
    { lat: 48.9567207, lng: 18.0569668 },
    { lat: 48.9566213, lng: 18.0570219 },
    { lat: 48.9565282, lng: 18.0570725 },
    { lat: 48.9564155, lng: 18.0571347 },
    { lat: 48.9563621, lng: 18.0571645 },
    { lat: 48.9562923, lng: 18.0572282 },
    { lat: 48.9561692, lng: 18.05734 },
    { lat: 48.9561326, lng: 18.0573733 },
    { lat: 48.9556795, lng: 18.0580197 },
    { lat: 48.955437, lng: 18.0582887 },
    { lat: 48.9552107, lng: 18.0584919 },
    { lat: 48.9544907, lng: 18.0591489 },
    { lat: 48.9540817, lng: 18.0596439 },
    { lat: 48.954049, lng: 18.0596835 },
    { lat: 48.9536199, lng: 18.0601213 },
    { lat: 48.9534016, lng: 18.0603432 },
    { lat: 48.9529416, lng: 18.0609298 },
    { lat: 48.9524294, lng: 18.0615328 },
    { lat: 48.9514201, lng: 18.0626469 },
    { lat: 48.9510641, lng: 18.062524 },
    { lat: 48.9504801, lng: 18.062724 },
    { lat: 48.94996, lng: 18.0630749 },
    { lat: 48.9496972, lng: 18.0632302 },
    { lat: 48.949443, lng: 18.0633815 },
    { lat: 48.949066, lng: 18.0634377 },
    { lat: 48.9487009, lng: 18.0628679 },
    { lat: 48.9481072, lng: 18.0620757 },
    { lat: 48.94752, lng: 18.0612795 },
    { lat: 48.9472849, lng: 18.0610961 },
    { lat: 48.9472317, lng: 18.0610193 },
    { lat: 48.9472149, lng: 18.0608664 },
    { lat: 48.9471607, lng: 18.0606141 },
    { lat: 48.947006, lng: 18.0602451 },
    { lat: 48.9467643, lng: 18.0600669 },
    { lat: 48.9464419, lng: 18.0596131 },
    { lat: 48.946324, lng: 18.0593888 },
    { lat: 48.9460889, lng: 18.0591896 },
    { lat: 48.9457142, lng: 18.0587683 },
    { lat: 48.9454808, lng: 18.0585578 },
    { lat: 48.9452626, lng: 18.0584537 },
    { lat: 48.9450096, lng: 18.0581995 },
    { lat: 48.9449251, lng: 18.0581261 },
    { lat: 48.9446573, lng: 18.0578696 },
    { lat: 48.9439634, lng: 18.0573913 },
    { lat: 48.9439786, lng: 18.0573795 },
    { lat: 48.9436661, lng: 18.0570508 },
    { lat: 48.9433685, lng: 18.056883 },
    { lat: 48.9432649, lng: 18.056734 },
    { lat: 48.9430629, lng: 18.0565796 },
    { lat: 48.9429249, lng: 18.0564036 },
    { lat: 48.9427361, lng: 18.0562406 },
    { lat: 48.9426263, lng: 18.0561592 },
    { lat: 48.9424776, lng: 18.0559337 },
    { lat: 48.9424706, lng: 18.0559271 },
    { lat: 48.9424499, lng: 18.0559047 },
    { lat: 48.9424176, lng: 18.05587 },
    { lat: 48.9424116, lng: 18.0558647 },
    { lat: 48.9423891, lng: 18.0558476 },
    { lat: 48.9423608, lng: 18.0558241 },
    { lat: 48.9423096, lng: 18.0557826 },
    { lat: 48.9422402, lng: 18.0557111 },
    { lat: 48.9421434, lng: 18.0555809 },
    { lat: 48.9419583, lng: 18.0553711 },
    { lat: 48.9418387, lng: 18.055216 },
    { lat: 48.941728, lng: 18.0549243 },
    { lat: 48.9417188, lng: 18.0548642 },
    { lat: 48.9416993, lng: 18.0548004 },
    { lat: 48.9416507, lng: 18.0547797 },
    { lat: 48.9414683, lng: 18.0547296 },
    { lat: 48.9413859, lng: 18.0546714 },
    { lat: 48.9413468, lng: 18.0546408 },
    { lat: 48.9413242, lng: 18.0545828 },
    { lat: 48.9411839, lng: 18.0543066 },
    { lat: 48.941146, lng: 18.0542163 },
    { lat: 48.9410778, lng: 18.0541501 },
    { lat: 48.9410206, lng: 18.0540619 },
    { lat: 48.940981, lng: 18.0539635 },
    { lat: 48.9409174, lng: 18.053919 },
    { lat: 48.9408059, lng: 18.053864 },
    { lat: 48.9407672, lng: 18.0537619 },
    { lat: 48.9407738, lng: 18.0537007 },
    { lat: 48.9407408, lng: 18.0536376 },
    { lat: 48.9406428, lng: 18.0534495 },
    { lat: 48.9405819, lng: 18.0533715 },
    { lat: 48.9406008, lng: 18.0532541 },
    { lat: 48.9405786, lng: 18.0532381 },
    { lat: 48.9404668, lng: 18.053065 },
    { lat: 48.940383, lng: 18.0528458 },
    { lat: 48.9402799, lng: 18.0527248 },
    { lat: 48.9402175, lng: 18.0525842 },
    { lat: 48.9401527, lng: 18.0524795 },
    { lat: 48.9400969, lng: 18.0523732 },
    { lat: 48.9400584, lng: 18.0522944 },
    { lat: 48.9400167, lng: 18.0522204 },
    { lat: 48.9399899, lng: 18.0521665 },
    { lat: 48.9399486, lng: 18.052059 },
    { lat: 48.939906, lng: 18.0519079 },
    { lat: 48.9397925, lng: 18.0515488 },
    { lat: 48.9397167, lng: 18.0513364 },
    { lat: 48.9396336, lng: 18.0511498 },
    { lat: 48.9395762, lng: 18.0510377 },
    { lat: 48.9395456, lng: 18.0509757 },
    { lat: 48.9394107, lng: 18.0507563 },
    { lat: 48.939321, lng: 18.0505929 },
    { lat: 48.9392302, lng: 18.050468 },
    { lat: 48.9391334, lng: 18.0503532 },
    { lat: 48.9391234, lng: 18.0503413 },
    { lat: 48.9391124, lng: 18.0503263 },
    { lat: 48.9390748, lng: 18.0502822 },
    { lat: 48.9390601, lng: 18.0502615 },
    { lat: 48.938856, lng: 18.0500511 },
    { lat: 48.9387742, lng: 18.0499659 },
    { lat: 48.9386221, lng: 18.0498726 },
    { lat: 48.9384283, lng: 18.0498187 },
    { lat: 48.9374377, lng: 18.051253 },
    { lat: 48.937337, lng: 18.0513991 },
    { lat: 48.936944, lng: 18.0517626 },
    { lat: 48.9360928, lng: 18.0525297 },
    { lat: 48.9359548, lng: 18.0523286 },
    { lat: 48.935816, lng: 18.052273 },
    { lat: 48.935771, lng: 18.0522551 },
    { lat: 48.9355887, lng: 18.0522283 },
    { lat: 48.9354386, lng: 18.052245 },
    { lat: 48.9350361, lng: 18.0523865 },
    { lat: 48.9350345, lng: 18.0523993 },
    { lat: 48.9345355, lng: 18.0523187 },
    { lat: 48.9345174, lng: 18.0523169 },
    { lat: 48.9342755, lng: 18.0523136 },
    { lat: 48.9341333, lng: 18.0523884 },
    { lat: 48.9339407, lng: 18.0523555 },
    { lat: 48.9337656, lng: 18.0522571 },
    { lat: 48.9335464, lng: 18.0524837 },
    { lat: 48.9335227, lng: 18.0524051 },
    { lat: 48.9334007, lng: 18.0523849 },
    { lat: 48.933273, lng: 18.0523447 },
    { lat: 48.9331796, lng: 18.0523861 },
    { lat: 48.9330908, lng: 18.0525492 },
    { lat: 48.93293, lng: 18.0528856 },
    { lat: 48.9327008, lng: 18.0532511 },
    { lat: 48.9325733, lng: 18.0534406 },
    { lat: 48.9324948, lng: 18.0535861 },
    { lat: 48.9324594, lng: 18.053774 },
    { lat: 48.9322535, lng: 18.0540132 },
    { lat: 48.9321216, lng: 18.0540557 },
    { lat: 48.9319259, lng: 18.0543 },
    { lat: 48.9316561, lng: 18.0547972 },
    { lat: 48.9316887, lng: 18.0548521 },
    { lat: 48.9315087, lng: 18.0551509 },
    { lat: 48.931362, lng: 18.0555181 },
    { lat: 48.931355, lng: 18.0556314 },
    { lat: 48.9313407, lng: 18.0558681 },
    { lat: 48.9311869, lng: 18.0563436 },
    { lat: 48.9311398, lng: 18.0566012 },
    { lat: 48.9311365, lng: 18.0567965 },
    { lat: 48.9311983, lng: 18.0569422 },
    { lat: 48.9313571, lng: 18.0570532 },
    { lat: 48.9313707, lng: 18.0571128 },
    { lat: 48.9314154, lng: 18.0573548 },
    { lat: 48.9314207, lng: 18.0575637 },
    { lat: 48.9313961, lng: 18.057684 },
    { lat: 48.9313382, lng: 18.0577733 },
    { lat: 48.9309852, lng: 18.058066 },
    { lat: 48.9311454, lng: 18.058319 },
    { lat: 48.930768, lng: 18.0587923 },
    { lat: 48.9306402, lng: 18.0589603 },
    { lat: 48.9304874, lng: 18.0592784 },
    { lat: 48.9304158, lng: 18.059446 },
    { lat: 48.9303354, lng: 18.0596726 },
    { lat: 48.9303522, lng: 18.0597486 },
    { lat: 48.9303663, lng: 18.0598163 },
    { lat: 48.930248, lng: 18.0599927 },
    { lat: 48.93016, lng: 18.0601104 },
    { lat: 48.9300492, lng: 18.0603339 },
    { lat: 48.929978, lng: 18.0604314 },
    { lat: 48.9299521, lng: 18.0604887 },
    { lat: 48.9299166, lng: 18.0605771 },
    { lat: 48.9298192, lng: 18.0608207 },
    { lat: 48.9297584, lng: 18.0609785 },
    { lat: 48.9297333, lng: 18.06103 },
    { lat: 48.9297233, lng: 18.061054 },
    { lat: 48.9296909, lng: 18.0611384 },
    { lat: 48.929638, lng: 18.061274 },
    { lat: 48.9296181, lng: 18.0613205 },
    { lat: 48.9296024, lng: 18.0613606 },
    { lat: 48.9295914, lng: 18.0613865 },
    { lat: 48.9295737, lng: 18.0614671 },
    { lat: 48.9295509, lng: 18.0615589 },
    { lat: 48.9295204, lng: 18.0616961 },
    { lat: 48.9293724, lng: 18.0621181 },
    { lat: 48.9293464, lng: 18.0622113 },
    { lat: 48.9292836, lng: 18.0626059 },
    { lat: 48.9292057, lng: 18.063068 },
    { lat: 48.9290471, lng: 18.0629239 },
    { lat: 48.9289851, lng: 18.0628679 },
    { lat: 48.9289138, lng: 18.0628255 },
    { lat: 48.9288881, lng: 18.0627902 },
    { lat: 48.9287666, lng: 18.0626203 },
    { lat: 48.9287494, lng: 18.0625817 },
    { lat: 48.9287426, lng: 18.0625582 },
    { lat: 48.9286906, lng: 18.062539 },
    { lat: 48.92841, lng: 18.0621806 },
    { lat: 48.9283012, lng: 18.0620538 },
    { lat: 48.9282951, lng: 18.0620467 },
    { lat: 48.9282841, lng: 18.0620335 },
    { lat: 48.9282357, lng: 18.0619776 },
    { lat: 48.9282284, lng: 18.0619699 },
    { lat: 48.9282067, lng: 18.0619431 },
    { lat: 48.9281837, lng: 18.0619178 },
    { lat: 48.928123, lng: 18.0618467 },
    { lat: 48.9275727, lng: 18.0611947 },
    { lat: 48.9270828, lng: 18.0605372 },
    { lat: 48.9270578, lng: 18.0604982 },
    { lat: 48.9270424, lng: 18.0604658 },
    { lat: 48.9270014, lng: 18.0603838 },
    { lat: 48.9269013, lng: 18.0602121 },
    { lat: 48.926459, lng: 18.0593263 },
    { lat: 48.9261902, lng: 18.0588788 },
    { lat: 48.9257661, lng: 18.0581331 },
    { lat: 48.9256341, lng: 18.0578824 },
    { lat: 48.9255203, lng: 18.0577319 },
    { lat: 48.9252622, lng: 18.0574473 },
    { lat: 48.9250805, lng: 18.0572719 },
    { lat: 48.9248221, lng: 18.0570232 },
    { lat: 48.9247161, lng: 18.0568896 },
    { lat: 48.9244888, lng: 18.05621 },
    { lat: 48.9242236, lng: 18.0555747 },
    { lat: 48.924166, lng: 18.0551073 },
    { lat: 48.9241748, lng: 18.0546238 },
    { lat: 48.9241912, lng: 18.0543624 },
    { lat: 48.9241636, lng: 18.0538551 },
    { lat: 48.924144, lng: 18.0529781 },
    { lat: 48.9241243, lng: 18.0529331 },
    { lat: 48.9239567, lng: 18.052707 },
    { lat: 48.9238329, lng: 18.0525396 },
    { lat: 48.9238181, lng: 18.0525021 },
    { lat: 48.9236248, lng: 18.0519945 },
    { lat: 48.9235344, lng: 18.051744 },
    { lat: 48.9234883, lng: 18.0516103 },
    { lat: 48.9234603, lng: 18.0515006 },
    { lat: 48.9231708, lng: 18.0506242 },
    { lat: 48.9231124, lng: 18.050317 },
    { lat: 48.9230398, lng: 18.0497924 },
    { lat: 48.923026, lng: 18.0493263 },
    { lat: 48.9230243, lng: 18.0490645 },
    { lat: 48.9227665, lng: 18.048922 },
    { lat: 48.9226007, lng: 18.0489057 },
    { lat: 48.9223992, lng: 18.0489329 },
    { lat: 48.9219611, lng: 18.0491782 },
    { lat: 48.9216437, lng: 18.049408 },
    { lat: 48.9215595, lng: 18.0494711 },
    { lat: 48.9213931, lng: 18.0496758 },
    { lat: 48.9209035, lng: 18.0504485 },
    { lat: 48.9205561, lng: 18.0509328 },
    { lat: 48.920047, lng: 18.0516241 },
    { lat: 48.9199725, lng: 18.0517418 },
    { lat: 48.9198495, lng: 18.0519148 },
    { lat: 48.9198309, lng: 18.051945 },
    { lat: 48.9197547, lng: 18.0520485 },
    { lat: 48.9196281, lng: 18.0522178 },
    { lat: 48.919176, lng: 18.0531299 },
    { lat: 48.9184424, lng: 18.0546735 },
    { lat: 48.9184373, lng: 18.0546832 },
    { lat: 48.9180055, lng: 18.0556163 },
    { lat: 48.9180018, lng: 18.05563 },
    { lat: 48.9179059, lng: 18.0560919 },
    { lat: 48.9177659, lng: 18.0563788 },
    { lat: 48.9174017, lng: 18.0571179 },
    { lat: 48.917302, lng: 18.0573222 },
    { lat: 48.9172745, lng: 18.0573529 },
    { lat: 48.9167802, lng: 18.057915 },
    { lat: 48.9155866, lng: 18.0587906 },
    { lat: 48.9151503, lng: 18.0598781 },
    { lat: 48.9151528, lng: 18.0598796 },
    { lat: 48.9151845, lng: 18.0599008 },
    { lat: 48.915221, lng: 18.0599257 },
    { lat: 48.9149892, lng: 18.0604994 },
    { lat: 48.9149302, lng: 18.0606473 },
    { lat: 48.9145339, lng: 18.061631 },
    { lat: 48.9145425, lng: 18.0616403 },
    { lat: 48.9145022, lng: 18.0617426 },
    { lat: 48.9144224, lng: 18.0619808 },
    { lat: 48.914321, lng: 18.0620784 },
    { lat: 48.9142608, lng: 18.0622261 },
    { lat: 48.9141054, lng: 18.0625047 },
    { lat: 48.9140206, lng: 18.0626916 },
    { lat: 48.9139712, lng: 18.0629311 },
    { lat: 48.9137821, lng: 18.0637089 },
    { lat: 48.9137379, lng: 18.0643238 },
    { lat: 48.9136555, lng: 18.0644795 },
    { lat: 48.9133241, lng: 18.0648904 },
    { lat: 48.9131139, lng: 18.0650572 },
    { lat: 48.913266, lng: 18.0655182 },
    { lat: 48.9134862, lng: 18.0662353 },
    { lat: 48.9136406, lng: 18.0667527 },
    { lat: 48.9138246, lng: 18.0671219 },
    { lat: 48.9143654, lng: 18.0677395 },
    { lat: 48.9144317, lng: 18.0678141 },
    { lat: 48.9146524, lng: 18.0682118 },
    { lat: 48.9151574, lng: 18.0688202 },
    { lat: 48.9157304, lng: 18.0693743 },
    { lat: 48.9166072, lng: 18.0697777 },
    { lat: 48.9174295, lng: 18.0703123 },
    { lat: 48.9177113, lng: 18.0707889 },
    { lat: 48.9181977, lng: 18.0712532 },
    { lat: 48.9184515, lng: 18.0715416 },
    { lat: 48.9189891, lng: 18.0726492 },
    { lat: 48.9194239, lng: 18.0730812 },
    { lat: 48.9196983, lng: 18.0731793 },
    { lat: 48.920095, lng: 18.0733635 },
    { lat: 48.9197792, lng: 18.0751079 },
    { lat: 48.919769, lng: 18.0751642 },
    { lat: 48.9197261, lng: 18.0752392 },
    { lat: 48.9197077, lng: 18.0752722 },
    { lat: 48.9196154, lng: 18.0754332 },
    { lat: 48.9193286, lng: 18.0759332 },
    { lat: 48.9186161, lng: 18.0771801 },
    { lat: 48.918539, lng: 18.0773106 },
    { lat: 48.9184865, lng: 18.0773988 },
    { lat: 48.9182636, lng: 18.0777736 },
    { lat: 48.9179307, lng: 18.0783347 },
    { lat: 48.9164884, lng: 18.0797491 },
    { lat: 48.9189621, lng: 18.0816613 },
    { lat: 48.920061, lng: 18.0813275 },
    { lat: 48.9201054, lng: 18.0813137 },
    { lat: 48.920407, lng: 18.0812219 },
    { lat: 48.9207618, lng: 18.0811139 },
    { lat: 48.9212186, lng: 18.0809754 },
    { lat: 48.9216322, lng: 18.0808496 },
    { lat: 48.9216866, lng: 18.0808329 },
    { lat: 48.9224805, lng: 18.0805914 },
    { lat: 48.9244453, lng: 18.0800332 },
    { lat: 48.924456, lng: 18.0801193 },
    { lat: 48.9244919, lng: 18.0804056 },
    { lat: 48.9247564, lng: 18.0825003 },
    { lat: 48.9248383, lng: 18.0837145 },
    { lat: 48.9248478, lng: 18.0852524 },
  ],
  Zamarovce: [
    { lat: 48.9037889, lng: 18.0588713 },
    { lat: 48.9042054, lng: 18.0611868 },
    { lat: 48.9042342, lng: 18.0613483 },
    { lat: 48.904223, lng: 18.0615051 },
    { lat: 48.9041291, lng: 18.0628339 },
    { lat: 48.9036402, lng: 18.0644089 },
    { lat: 48.9031266, lng: 18.0660686 },
    { lat: 48.9030743, lng: 18.0662367 },
    { lat: 48.9030581, lng: 18.0662888 },
    { lat: 48.9028616, lng: 18.06692 },
    { lat: 48.9026469, lng: 18.0676111 },
    { lat: 48.9025595, lng: 18.0678919 },
    { lat: 48.902493, lng: 18.0681068 },
    { lat: 48.9020066, lng: 18.0696691 },
    { lat: 48.9024007, lng: 18.0715482 },
    { lat: 48.9034238, lng: 18.0763721 },
    { lat: 48.9034271, lng: 18.0764291 },
    { lat: 48.9034323, lng: 18.0764976 },
    { lat: 48.9037502, lng: 18.0783095 },
    { lat: 48.9038216, lng: 18.0787179 },
    { lat: 48.9038331, lng: 18.0787813 },
    { lat: 48.9039552, lng: 18.0794798 },
    { lat: 48.9040915, lng: 18.0790726 },
    { lat: 48.9041051, lng: 18.0790346 },
    { lat: 48.9041099, lng: 18.0790182 },
    { lat: 48.9041223, lng: 18.0789806 },
    { lat: 48.9041245, lng: 18.0789764 },
    { lat: 48.9042391, lng: 18.0786364 },
    { lat: 48.9042456, lng: 18.0786164 },
    { lat: 48.9043974, lng: 18.0781631 },
    { lat: 48.904623, lng: 18.0774863 },
    { lat: 48.9046317, lng: 18.0774601 },
    { lat: 48.9046434, lng: 18.0774266 },
    { lat: 48.9046577, lng: 18.077383 },
    { lat: 48.9046576, lng: 18.077382 },
    { lat: 48.904696, lng: 18.077266 },
    { lat: 48.9049766, lng: 18.0764261 },
    { lat: 48.9050602, lng: 18.0761771 },
    { lat: 48.9050823, lng: 18.0761099 },
    { lat: 48.9051053, lng: 18.0760415 },
    { lat: 48.9053408, lng: 18.0753401 },
    { lat: 48.905358, lng: 18.0752865 },
    { lat: 48.9054982, lng: 18.0748648 },
    { lat: 48.9055047, lng: 18.0748424 },
    { lat: 48.9055173, lng: 18.0748066 },
    { lat: 48.9055204, lng: 18.074799 },
    { lat: 48.9055605, lng: 18.0746767 },
    { lat: 48.9057733, lng: 18.0740321 },
    { lat: 48.9060209, lng: 18.0735544 },
    { lat: 48.906046, lng: 18.0735226 },
    { lat: 48.9060493, lng: 18.0735175 },
    { lat: 48.9061186, lng: 18.0734289 },
    { lat: 48.9061289, lng: 18.0734157 },
    { lat: 48.9062413, lng: 18.0732291 },
    { lat: 48.9064171, lng: 18.0729002 },
    { lat: 48.9067381, lng: 18.0725406 },
    { lat: 48.9071273, lng: 18.0721307 },
    { lat: 48.9075098, lng: 18.0718008 },
    { lat: 48.9080077, lng: 18.0715757 },
    { lat: 48.9084511, lng: 18.0712642 },
    { lat: 48.9086348, lng: 18.0711409 },
    { lat: 48.9087655, lng: 18.0709665 },
    { lat: 48.9094818, lng: 18.0701476 },
    { lat: 48.9096896, lng: 18.0698804 },
    { lat: 48.9098129, lng: 18.0695004 },
    { lat: 48.9098397, lng: 18.0687419 },
    { lat: 48.9098947, lng: 18.0672876 },
    { lat: 48.9101456, lng: 18.0661176 },
    { lat: 48.9104022, lng: 18.0649446 },
    { lat: 48.9110373, lng: 18.0650572 },
    { lat: 48.911268, lng: 18.0650064 },
    { lat: 48.9122954, lng: 18.0647817 },
    { lat: 48.9128476, lng: 18.0647694 },
    { lat: 48.9130772, lng: 18.0649915 },
    { lat: 48.913102, lng: 18.0650279 },
    { lat: 48.9131139, lng: 18.0650572 },
    { lat: 48.9133241, lng: 18.0648904 },
    { lat: 48.9136555, lng: 18.0644795 },
    { lat: 48.9137379, lng: 18.0643238 },
    { lat: 48.9137821, lng: 18.0637089 },
    { lat: 48.9139712, lng: 18.0629311 },
    { lat: 48.9140206, lng: 18.0626916 },
    { lat: 48.9141054, lng: 18.0625047 },
    { lat: 48.9142608, lng: 18.0622261 },
    { lat: 48.914321, lng: 18.0620784 },
    { lat: 48.9144224, lng: 18.0619808 },
    { lat: 48.9145022, lng: 18.0617426 },
    { lat: 48.9145425, lng: 18.0616403 },
    { lat: 48.9145339, lng: 18.061631 },
    { lat: 48.9149302, lng: 18.0606473 },
    { lat: 48.9149892, lng: 18.0604994 },
    { lat: 48.915221, lng: 18.0599257 },
    { lat: 48.9151845, lng: 18.0599008 },
    { lat: 48.9151528, lng: 18.0598796 },
    { lat: 48.9151503, lng: 18.0598781 },
    { lat: 48.9155866, lng: 18.0587906 },
    { lat: 48.9167802, lng: 18.057915 },
    { lat: 48.9172745, lng: 18.0573529 },
    { lat: 48.917302, lng: 18.0573222 },
    { lat: 48.9174017, lng: 18.0571179 },
    { lat: 48.9177659, lng: 18.0563788 },
    { lat: 48.9179059, lng: 18.0560919 },
    { lat: 48.9180018, lng: 18.05563 },
    { lat: 48.9180055, lng: 18.0556163 },
    { lat: 48.9184373, lng: 18.0546832 },
    { lat: 48.9184424, lng: 18.0546735 },
    { lat: 48.919176, lng: 18.0531299 },
    { lat: 48.9196281, lng: 18.0522178 },
    { lat: 48.9197547, lng: 18.0520485 },
    { lat: 48.9198309, lng: 18.051945 },
    { lat: 48.9198495, lng: 18.0519148 },
    { lat: 48.9199725, lng: 18.0517418 },
    { lat: 48.920047, lng: 18.0516241 },
    { lat: 48.9205561, lng: 18.0509328 },
    { lat: 48.9209035, lng: 18.0504485 },
    { lat: 48.9213931, lng: 18.0496758 },
    { lat: 48.9215595, lng: 18.0494711 },
    { lat: 48.9216437, lng: 18.049408 },
    { lat: 48.9219611, lng: 18.0491782 },
    { lat: 48.9223992, lng: 18.0489329 },
    { lat: 48.9226007, lng: 18.0489057 },
    { lat: 48.9227665, lng: 18.048922 },
    { lat: 48.9230243, lng: 18.0490645 },
    { lat: 48.9230427, lng: 18.0489537 },
    { lat: 48.9230902, lng: 18.0486666 },
    { lat: 48.9231127, lng: 18.0485294 },
    { lat: 48.923247, lng: 18.0481459 },
    { lat: 48.9235486, lng: 18.0472643 },
    { lat: 48.9236595, lng: 18.0466945 },
    { lat: 48.9237891, lng: 18.0456302 },
    { lat: 48.9240995, lng: 18.044999 },
    { lat: 48.9242029, lng: 18.0448939 },
    { lat: 48.9245201, lng: 18.044569 },
    { lat: 48.9249549, lng: 18.0443416 },
    { lat: 48.9253013, lng: 18.0444739 },
    { lat: 48.9253185, lng: 18.0444798 },
    { lat: 48.9256245, lng: 18.044404 },
    { lat: 48.9263549, lng: 18.0439867 },
    { lat: 48.9265547, lng: 18.0438728 },
    { lat: 48.9266236, lng: 18.0438334 },
    { lat: 48.9267053, lng: 18.0437262 },
    { lat: 48.9268164, lng: 18.0435798 },
    { lat: 48.9270262, lng: 18.0431542 },
    { lat: 48.9270888, lng: 18.0430266 },
    { lat: 48.9273551, lng: 18.0425674 },
    { lat: 48.9275108, lng: 18.0420446 },
    { lat: 48.9276867, lng: 18.0418515 },
    { lat: 48.9280546, lng: 18.0416392 },
    { lat: 48.9282461, lng: 18.0414213 },
    { lat: 48.9282729, lng: 18.0413369 },
    { lat: 48.9283013, lng: 18.0412448 },
    { lat: 48.9283449, lng: 18.0411061 },
    { lat: 48.9283888, lng: 18.0409674 },
    { lat: 48.9284116, lng: 18.0408954 },
    { lat: 48.9284561, lng: 18.0408082 },
    { lat: 48.92859, lng: 18.0405515 },
    { lat: 48.9287261, lng: 18.0402901 },
    { lat: 48.9287655, lng: 18.040213 },
    { lat: 48.9287242, lng: 18.0402013 },
    { lat: 48.9286919, lng: 18.0401917 },
    { lat: 48.9285933, lng: 18.0401637 },
    { lat: 48.9281594, lng: 18.040039 },
    { lat: 48.9281468, lng: 18.0400353 },
    { lat: 48.9280801, lng: 18.0400138 },
    { lat: 48.9280203, lng: 18.0399946 },
    { lat: 48.927988, lng: 18.0399839 },
    { lat: 48.9263017, lng: 18.0394426 },
    { lat: 48.9258638, lng: 18.0393629 },
    { lat: 48.9256059, lng: 18.0393167 },
    { lat: 48.9255318, lng: 18.0393033 },
    { lat: 48.9254519, lng: 18.0392883 },
    { lat: 48.9251006, lng: 18.0392249 },
    { lat: 48.9250628, lng: 18.0392181 },
    { lat: 48.9246428, lng: 18.0397705 },
    { lat: 48.9240425, lng: 18.039753 },
    { lat: 48.9229562, lng: 18.0402629 },
    { lat: 48.9222954, lng: 18.0406375 },
    { lat: 48.9212751, lng: 18.0405966 },
    { lat: 48.9203929, lng: 18.0404489 },
    { lat: 48.9195133, lng: 18.0400771 },
    { lat: 48.9188119, lng: 18.0401421 },
    { lat: 48.9184044, lng: 18.0402582 },
    { lat: 48.9180369, lng: 18.0404969 },
    { lat: 48.9178263, lng: 18.0406004 },
    { lat: 48.9175158, lng: 18.0405961 },
    { lat: 48.9172487, lng: 18.0403928 },
    { lat: 48.9167953, lng: 18.0404566 },
    { lat: 48.9164984, lng: 18.040337 },
    { lat: 48.9158934, lng: 18.0404342 },
    { lat: 48.9156572, lng: 18.0403904 },
    { lat: 48.9151378, lng: 18.0408075 },
    { lat: 48.9146539, lng: 18.0411782 },
    { lat: 48.9142037, lng: 18.0411031 },
    { lat: 48.9139827, lng: 18.0410687 },
    { lat: 48.913603, lng: 18.0409625 },
    { lat: 48.9134376, lng: 18.0411064 },
    { lat: 48.9133006, lng: 18.041117 },
    { lat: 48.9127776, lng: 18.0412229 },
    { lat: 48.9124854, lng: 18.0411482 },
    { lat: 48.9123248, lng: 18.0411039 },
    { lat: 48.9117081, lng: 18.0412295 },
    { lat: 48.9111303, lng: 18.0413315 },
    { lat: 48.9109618, lng: 18.0413612 },
    { lat: 48.9104207, lng: 18.0414849 },
    { lat: 48.9103474, lng: 18.0414697 },
    { lat: 48.9097044, lng: 18.0413412 },
    { lat: 48.9095276, lng: 18.0413063 },
    { lat: 48.9090353, lng: 18.0412077 },
    { lat: 48.9086087, lng: 18.0411678 },
    { lat: 48.9085486, lng: 18.0411622 },
    { lat: 48.9084422, lng: 18.0411389 },
    { lat: 48.9080444, lng: 18.0410538 },
    { lat: 48.9073574, lng: 18.0409055 },
    { lat: 48.9067507, lng: 18.0406825 },
    { lat: 48.9066031, lng: 18.0406085 },
    { lat: 48.906432, lng: 18.0405214 },
    { lat: 48.9063798, lng: 18.0404944 },
    { lat: 48.9062879, lng: 18.0404476 },
    { lat: 48.9062413, lng: 18.0404241 },
    { lat: 48.9061995, lng: 18.0404043 },
    { lat: 48.9060942, lng: 18.0403794 },
    { lat: 48.9058812, lng: 18.0403286 },
    { lat: 48.9046321, lng: 18.0402738 },
    { lat: 48.9046093, lng: 18.0402873 },
    { lat: 48.904551, lng: 18.0403145 },
    { lat: 48.9045171, lng: 18.040332 },
    { lat: 48.9045015, lng: 18.0403366 },
    { lat: 48.904455, lng: 18.0403504 },
    { lat: 48.9042286, lng: 18.0404173 },
    { lat: 48.9042118, lng: 18.040422 },
    { lat: 48.9041965, lng: 18.0404266 },
    { lat: 48.9041568, lng: 18.0404442 },
    { lat: 48.9041271, lng: 18.0404571 },
    { lat: 48.9040953, lng: 18.0404711 },
    { lat: 48.9040667, lng: 18.0404836 },
    { lat: 48.9040501, lng: 18.0404909 },
    { lat: 48.9039601, lng: 18.0405324 },
    { lat: 48.9039027, lng: 18.0405588 },
    { lat: 48.9030897, lng: 18.0409345 },
    { lat: 48.9029248, lng: 18.0410106 },
    { lat: 48.9021964, lng: 18.0413475 },
    { lat: 48.9013822, lng: 18.0417236 },
    { lat: 48.9013723, lng: 18.0417278 },
    { lat: 48.9012383, lng: 18.0417897 },
    { lat: 48.9012519, lng: 18.0418682 },
    { lat: 48.9012679, lng: 18.0419676 },
    { lat: 48.9012888, lng: 18.0420949 },
    { lat: 48.9013011, lng: 18.0421672 },
    { lat: 48.9013341, lng: 18.0423629 },
    { lat: 48.9013377, lng: 18.0423879 },
    { lat: 48.9013641, lng: 18.0425482 },
    { lat: 48.901371, lng: 18.0425893 },
    { lat: 48.9013973, lng: 18.04275 },
    { lat: 48.9014109, lng: 18.0428289 },
    { lat: 48.9014197, lng: 18.0428822 },
    { lat: 48.9014373, lng: 18.0429886 },
    { lat: 48.9014481, lng: 18.0430567 },
    { lat: 48.9014551, lng: 18.0430992 },
    { lat: 48.9014876, lng: 18.0432899 },
    { lat: 48.90149, lng: 18.0433104 },
    { lat: 48.9014935, lng: 18.0433311 },
    { lat: 48.9014968, lng: 18.0433507 },
    { lat: 48.9015092, lng: 18.0434251 },
    { lat: 48.901546, lng: 18.0436483 },
    { lat: 48.9015948, lng: 18.0439412 },
    { lat: 48.9016669, lng: 18.0443831 },
    { lat: 48.9016686, lng: 18.0443926 },
    { lat: 48.9016703, lng: 18.0444003 },
    { lat: 48.9016966, lng: 18.0445639 },
    { lat: 48.9017478, lng: 18.0448759 },
    { lat: 48.901818, lng: 18.0453015 },
    { lat: 48.9019124, lng: 18.045876 },
    { lat: 48.9019199, lng: 18.0459267 },
    { lat: 48.9019265, lng: 18.0459624 },
    { lat: 48.9019318, lng: 18.0459932 },
    { lat: 48.9019456, lng: 18.0460753 },
    { lat: 48.9020071, lng: 18.0464505 },
    { lat: 48.9024031, lng: 18.048836 },
    { lat: 48.9028863, lng: 18.0517454 },
    { lat: 48.9030056, lng: 18.0524691 },
    { lat: 48.9031491, lng: 18.0533415 },
    { lat: 48.9031807, lng: 18.0535338 },
    { lat: 48.9032379, lng: 18.0538752 },
    { lat: 48.9032803, lng: 18.0541356 },
    { lat: 48.9033482, lng: 18.054544 },
    { lat: 48.9033502, lng: 18.0545574 },
    { lat: 48.9033592, lng: 18.054614 },
    { lat: 48.9033623, lng: 18.0546304 },
    { lat: 48.9033833, lng: 18.0547599 },
    { lat: 48.9034323, lng: 18.055054 },
    { lat: 48.9034479, lng: 18.0551483 },
    { lat: 48.903487, lng: 18.0553906 },
    { lat: 48.9035069, lng: 18.0556139 },
    { lat: 48.9035202, lng: 18.0557649 },
    { lat: 48.9035458, lng: 18.0560675 },
    { lat: 48.9035591, lng: 18.0562182 },
    { lat: 48.9035776, lng: 18.0564374 },
    { lat: 48.9036228, lng: 18.0569586 },
    { lat: 48.9036359, lng: 18.0571061 },
    { lat: 48.9036623, lng: 18.0574039 },
    { lat: 48.9037012, lng: 18.0578531 },
    { lat: 48.9037388, lng: 18.0582864 },
    { lat: 48.9037889, lng: 18.0588713 },
  ],
  Bobot: [
    { lat: 48.801149, lng: 18.219549 },
    { lat: 48.801676, lng: 18.219448 },
    { lat: 48.80237, lng: 18.218967 },
    { lat: 48.802773, lng: 18.218687 },
    { lat: 48.803274, lng: 18.218288 },
    { lat: 48.803808, lng: 18.217778 },
    { lat: 48.804143, lng: 18.217372 },
    { lat: 48.804486, lng: 18.216574 },
    { lat: 48.80461, lng: 18.215905 },
    { lat: 48.804637, lng: 18.215616 },
    { lat: 48.804819, lng: 18.214753 },
    { lat: 48.805353, lng: 18.214159 },
    { lat: 48.805959, lng: 18.213144 },
    { lat: 48.806106, lng: 18.212643 },
    { lat: 48.806739, lng: 18.21178 },
    { lat: 48.806956, lng: 18.211643 },
    { lat: 48.807622, lng: 18.211575 },
    { lat: 48.808078, lng: 18.211611 },
    { lat: 48.808791, lng: 18.21182 },
    { lat: 48.809657, lng: 18.21205 },
    { lat: 48.810334, lng: 18.212084 },
    { lat: 48.811165, lng: 18.212096 },
    { lat: 48.811477, lng: 18.21213 },
    { lat: 48.812213, lng: 18.212048 },
    { lat: 48.81276, lng: 18.212079 },
    { lat: 48.81343, lng: 18.212277 },
    { lat: 48.813697, lng: 18.212422 },
    { lat: 48.81471, lng: 18.212691 },
    { lat: 48.815977, lng: 18.212871 },
    { lat: 48.816463, lng: 18.213048 },
    { lat: 48.816943, lng: 18.213215 },
    { lat: 48.817588, lng: 18.213313 },
    { lat: 48.818625, lng: 18.213434 },
    { lat: 48.819836, lng: 18.213361 },
    { lat: 48.820638, lng: 18.213289 },
    { lat: 48.820973, lng: 18.213168 },
    { lat: 48.821252, lng: 18.212958 },
    { lat: 48.821847, lng: 18.212641 },
    { lat: 48.822307, lng: 18.212206 },
    { lat: 48.822299, lng: 18.212185 },
    { lat: 48.822503, lng: 18.212074 },
    { lat: 48.82275, lng: 18.212027 },
    { lat: 48.823546, lng: 18.211963 },
    { lat: 48.824226, lng: 18.211786 },
    { lat: 48.824993, lng: 18.211739 },
    { lat: 48.825419, lng: 18.21177 },
    { lat: 48.825608, lng: 18.211867 },
    { lat: 48.825692, lng: 18.211886 },
    { lat: 48.825806, lng: 18.212067 },
    { lat: 48.826098, lng: 18.212624 },
    { lat: 48.826263, lng: 18.212808 },
    { lat: 48.826466, lng: 18.212925 },
    { lat: 48.826705, lng: 18.212961 },
    { lat: 48.826822, lng: 18.212934 },
    { lat: 48.827462, lng: 18.213034 },
    { lat: 48.827895, lng: 18.213196 },
    { lat: 48.828553, lng: 18.21301 },
    { lat: 48.829045, lng: 18.212757 },
    { lat: 48.829436, lng: 18.212617 },
    { lat: 48.829856, lng: 18.212463 },
    { lat: 48.830213, lng: 18.212241 },
    { lat: 48.830395, lng: 18.212179 },
    { lat: 48.830674, lng: 18.212127 },
    { lat: 48.830817, lng: 18.212044 },
    { lat: 48.831282, lng: 18.211975 },
    { lat: 48.8322, lng: 18.212372 },
    { lat: 48.83271, lng: 18.212742 },
    { lat: 48.833135, lng: 18.213087 },
    { lat: 48.833522, lng: 18.21304 },
    { lat: 48.834406, lng: 18.212655 },
    { lat: 48.835818, lng: 18.212133 },
    { lat: 48.836275, lng: 18.211592 },
    { lat: 48.836561, lng: 18.211053 },
    { lat: 48.836674, lng: 18.210963 },
    { lat: 48.836971, lng: 18.21121 },
    { lat: 48.837527, lng: 18.211384 },
    { lat: 48.837823, lng: 18.21134 },
    { lat: 48.838267, lng: 18.211097 },
    { lat: 48.838522, lng: 18.211147 },
    { lat: 48.838866, lng: 18.211275 },
    { lat: 48.839044, lng: 18.211273 },
    { lat: 48.839236, lng: 18.211394 },
    { lat: 48.83945, lng: 18.211575 },
    { lat: 48.839762, lng: 18.211953 },
    { lat: 48.840252, lng: 18.212682 },
    { lat: 48.841268, lng: 18.212657 },
    { lat: 48.8417, lng: 18.212702 },
    { lat: 48.841857, lng: 18.212709 },
    { lat: 48.84232, lng: 18.213128 },
    { lat: 48.842991, lng: 18.213362 },
    { lat: 48.843785, lng: 18.213742 },
    { lat: 48.8438, lng: 18.21376 },
    { lat: 48.843826, lng: 18.213787 },
    { lat: 48.843862, lng: 18.213829 },
    { lat: 48.843887, lng: 18.213857 },
    { lat: 48.844177, lng: 18.214198 },
    { lat: 48.844405, lng: 18.214367 },
    { lat: 48.845444, lng: 18.214288 },
    { lat: 48.845886, lng: 18.215796 },
    { lat: 48.846116, lng: 18.216229 },
    { lat: 48.846221, lng: 18.216342 },
    { lat: 48.846915, lng: 18.216777 },
    { lat: 48.847013, lng: 18.216883 },
    { lat: 48.847783, lng: 18.217638 },
    { lat: 48.848016, lng: 18.217751 },
    { lat: 48.848247, lng: 18.217803 },
    { lat: 48.848791, lng: 18.218306 },
    { lat: 48.849498, lng: 18.218194 },
    { lat: 48.850141, lng: 18.218261 },
    { lat: 48.850593, lng: 18.218906 },
    { lat: 48.851019, lng: 18.218821 },
    { lat: 48.851628, lng: 18.218887 },
    { lat: 48.852548, lng: 18.219227 },
    { lat: 48.853018, lng: 18.219584 },
    { lat: 48.853641, lng: 18.219936 },
    { lat: 48.854261, lng: 18.219921 },
    { lat: 48.854764, lng: 18.22006 },
    { lat: 48.855126, lng: 18.220112 },
    { lat: 48.855401, lng: 18.220548 },
    { lat: 48.855603, lng: 18.220768 },
    { lat: 48.856012, lng: 18.221184 },
    { lat: 48.856507, lng: 18.222144 },
    { lat: 48.856905, lng: 18.22291 },
    { lat: 48.857554, lng: 18.223014 },
    { lat: 48.858376, lng: 18.224102 },
    { lat: 48.85864, lng: 18.224379 },
    { lat: 48.858991, lng: 18.225045 },
    { lat: 48.85961, lng: 18.226184 },
    { lat: 48.859731, lng: 18.226352 },
    { lat: 48.859963, lng: 18.226745 },
    { lat: 48.860122, lng: 18.227038 },
    { lat: 48.860424, lng: 18.227926 },
    { lat: 48.860616, lng: 18.228079 },
    { lat: 48.860943, lng: 18.228843 },
    { lat: 48.861208, lng: 18.229167 },
    { lat: 48.861296, lng: 18.229436 },
    { lat: 48.861392, lng: 18.229591 },
    { lat: 48.861519, lng: 18.229689 },
    { lat: 48.86181, lng: 18.229797 },
    { lat: 48.861919, lng: 18.229872 },
    { lat: 48.86209, lng: 18.230046 },
    { lat: 48.862823, lng: 18.229329 },
    { lat: 48.863184, lng: 18.229019 },
    { lat: 48.864149, lng: 18.228818 },
    { lat: 48.864429, lng: 18.228477 },
    { lat: 48.864984, lng: 18.227937 },
    { lat: 48.865201, lng: 18.227951 },
    { lat: 48.865476, lng: 18.227836 },
    { lat: 48.865947, lng: 18.227802 },
    { lat: 48.866791, lng: 18.22768 },
    { lat: 48.867062, lng: 18.227705 },
    { lat: 48.867095, lng: 18.227709 },
    { lat: 48.867406, lng: 18.227725 },
    { lat: 48.867561, lng: 18.227719 },
    { lat: 48.867901, lng: 18.227783 },
    { lat: 48.868202, lng: 18.227753 },
    { lat: 48.86846, lng: 18.227696 },
    { lat: 48.868578, lng: 18.227654 },
    { lat: 48.869525, lng: 18.227113 },
    { lat: 48.869647, lng: 18.227055 },
    { lat: 48.869972, lng: 18.227472 },
    { lat: 48.87023, lng: 18.227983 },
    { lat: 48.870306, lng: 18.228063 },
    { lat: 48.870991, lng: 18.228181 },
    { lat: 48.871979, lng: 18.228361 },
    { lat: 48.8718435, lng: 18.2276543 },
    { lat: 48.8716498, lng: 18.2260796 },
    { lat: 48.8717487, lng: 18.2247881 },
    { lat: 48.8716582, lng: 18.224053 },
    { lat: 48.8715426, lng: 18.2233922 },
    { lat: 48.8715927, lng: 18.2228275 },
    { lat: 48.8720083, lng: 18.2219668 },
    { lat: 48.8724323, lng: 18.2213882 },
    { lat: 48.872819, lng: 18.2206911 },
    { lat: 48.8727534, lng: 18.2203542 },
    { lat: 48.8725046, lng: 18.2200256 },
    { lat: 48.8724387, lng: 18.2197041 },
    { lat: 48.8724199, lng: 18.2196116 },
    { lat: 48.8726026, lng: 18.2190403 },
    { lat: 48.8728723, lng: 18.2175216 },
    { lat: 48.8728935, lng: 18.2168037 },
    { lat: 48.8729112, lng: 18.216444 },
    { lat: 48.8729215, lng: 18.21621 },
    { lat: 48.8729304, lng: 18.2159898 },
    { lat: 48.8732205, lng: 18.214829 },
    { lat: 48.8734615, lng: 18.2144081 },
    { lat: 48.8733334, lng: 18.213941 },
    { lat: 48.8732892, lng: 18.2139178 },
    { lat: 48.8733064, lng: 18.2139044 },
    { lat: 48.8732815, lng: 18.2138819 },
    { lat: 48.87319, lng: 18.213329 },
    { lat: 48.8730889, lng: 18.2127637 },
    { lat: 48.8728676, lng: 18.2119344 },
    { lat: 48.8727273, lng: 18.2114539 },
    { lat: 48.8722896, lng: 18.2104523 },
    { lat: 48.8721427, lng: 18.2100927 },
    { lat: 48.8718892, lng: 18.209311 },
    { lat: 48.871327, lng: 18.2087924 },
    { lat: 48.8707245, lng: 18.2084775 },
    { lat: 48.8704744, lng: 18.208429 },
    { lat: 48.8700094, lng: 18.2080923 },
    { lat: 48.8696964, lng: 18.2079866 },
    { lat: 48.8695234, lng: 18.2078814 },
    { lat: 48.8687096, lng: 18.2074437 },
    { lat: 48.868425, lng: 18.20726 },
    { lat: 48.8680946, lng: 18.2071462 },
    { lat: 48.8677925, lng: 18.2069124 },
    { lat: 48.8673044, lng: 18.2067262 },
    { lat: 48.8662351, lng: 18.2064766 },
    { lat: 48.8648825, lng: 18.2054598 },
    { lat: 48.8638938, lng: 18.2046087 },
    { lat: 48.8634174, lng: 18.2041929 },
    { lat: 48.8634052, lng: 18.2042157 },
    { lat: 48.8633794, lng: 18.2041983 },
    { lat: 48.8630397, lng: 18.2039606 },
    { lat: 48.8623599, lng: 18.2038115 },
    { lat: 48.8616802, lng: 18.2038253 },
    { lat: 48.8610458, lng: 18.2041135 },
    { lat: 48.8604737, lng: 18.2034269 },
    { lat: 48.8600465, lng: 18.2028639 },
    { lat: 48.8600306, lng: 18.2028417 },
    { lat: 48.8599185, lng: 18.20264 },
    { lat: 48.8594323, lng: 18.2016358 },
    { lat: 48.8588309, lng: 18.2007145 },
    { lat: 48.858443, lng: 18.2001277 },
    { lat: 48.8581503, lng: 18.1997276 },
    { lat: 48.8578084, lng: 18.1991345 },
    { lat: 48.8574432, lng: 18.1987694 },
    { lat: 48.8567931, lng: 18.1984136 },
    { lat: 48.8562794, lng: 18.1980735 },
    { lat: 48.8560544, lng: 18.1979061 },
    { lat: 48.8551799, lng: 18.1975333 },
    { lat: 48.8546988, lng: 18.1974483 },
    { lat: 48.8543819, lng: 18.1974306 },
    { lat: 48.8539675, lng: 18.1975436 },
    { lat: 48.853588, lng: 18.1975771 },
    { lat: 48.8529218, lng: 18.1975506 },
    { lat: 48.8521729, lng: 18.197339 },
    { lat: 48.8519828, lng: 18.1971745 },
    { lat: 48.8516857, lng: 18.1971477 },
    { lat: 48.8510793, lng: 18.1970019 },
    { lat: 48.8505312, lng: 18.1968692 },
    { lat: 48.8498566, lng: 18.1958116 },
    { lat: 48.8488409, lng: 18.1958659 },
    { lat: 48.8480826, lng: 18.1959078 },
    { lat: 48.8477288, lng: 18.1962697 },
    { lat: 48.8469984, lng: 18.1969535 },
    { lat: 48.8460835, lng: 18.197701 },
    { lat: 48.8449777, lng: 18.1982143 },
    { lat: 48.8444071, lng: 18.1984765 },
    { lat: 48.8435379, lng: 18.1987654 },
    { lat: 48.8426009, lng: 18.1990923 },
    { lat: 48.8418024, lng: 18.1995701 },
    { lat: 48.8410854, lng: 18.1998576 },
    { lat: 48.8400195, lng: 18.2002004 },
    { lat: 48.8398108, lng: 18.2001816 },
    { lat: 48.8397523, lng: 18.2002101 },
    { lat: 48.8390563, lng: 18.200128 },
    { lat: 48.8386701, lng: 18.2000442 },
    { lat: 48.8372637, lng: 18.1997641 },
    { lat: 48.8364205, lng: 18.1995793 },
    { lat: 48.8360037, lng: 18.1994927 },
    { lat: 48.8355394, lng: 18.199388 },
    { lat: 48.8354315, lng: 18.1993947 },
    { lat: 48.8351873, lng: 18.1994032 },
    { lat: 48.8348696, lng: 18.1994916 },
    { lat: 48.8345964, lng: 18.1993918 },
    { lat: 48.8338526, lng: 18.1993888 },
    { lat: 48.833107, lng: 18.1993913 },
    { lat: 48.8326354, lng: 18.1993739 },
    { lat: 48.8325923, lng: 18.1993724 },
    { lat: 48.8320678, lng: 18.1993548 },
    { lat: 48.8316717, lng: 18.1993167 },
    { lat: 48.8311633, lng: 18.1989496 },
    { lat: 48.830232, lng: 18.1983344 },
    { lat: 48.8296634, lng: 18.1981214 },
    { lat: 48.8287032, lng: 18.1981633 },
    { lat: 48.8283313, lng: 18.1980884 },
    { lat: 48.8280945, lng: 18.1980544 },
    { lat: 48.8280141, lng: 18.1979504 },
    { lat: 48.8279557, lng: 18.1978764 },
    { lat: 48.8278985, lng: 18.197803 },
    { lat: 48.8276914, lng: 18.1975359 },
    { lat: 48.827451, lng: 18.1973025 },
    { lat: 48.8263833, lng: 18.196906 },
    { lat: 48.825594, lng: 18.1966003 },
    { lat: 48.8250457, lng: 18.1964243 },
    { lat: 48.8250477, lng: 18.1963804 },
    { lat: 48.8251355, lng: 18.1951798 },
    { lat: 48.8251464, lng: 18.1950359 },
    { lat: 48.8251536, lng: 18.194964 },
    { lat: 48.825158, lng: 18.1949001 },
    { lat: 48.8251648, lng: 18.1948254 },
    { lat: 48.8251702, lng: 18.1947627 },
    { lat: 48.8251852, lng: 18.1946286 },
    { lat: 48.8251903, lng: 18.1945613 },
    { lat: 48.8251968, lng: 18.1944978 },
    { lat: 48.825206, lng: 18.1944235 },
    { lat: 48.8252128, lng: 18.1943646 },
    { lat: 48.8252231, lng: 18.1942884 },
    { lat: 48.8252326, lng: 18.1942159 },
    { lat: 48.8252354, lng: 18.1941489 },
    { lat: 48.8252394, lng: 18.1940786 },
    { lat: 48.825242, lng: 18.1940084 },
    { lat: 48.8252483, lng: 18.1939413 },
    { lat: 48.82525, lng: 18.1938716 },
    { lat: 48.8252538, lng: 18.193802 },
    { lat: 48.8252576, lng: 18.1937492 },
    { lat: 48.8252657, lng: 18.193635 },
    { lat: 48.8252776, lng: 18.1934669 },
    { lat: 48.8252833, lng: 18.1933898 },
    { lat: 48.8252894, lng: 18.193302 },
    { lat: 48.8252949, lng: 18.1932218 },
    { lat: 48.8252993, lng: 18.193179 },
    { lat: 48.8253025, lng: 18.1931352 },
    { lat: 48.825309, lng: 18.1930516 },
    { lat: 48.8253153, lng: 18.1929666 },
    { lat: 48.8253218, lng: 18.1928827 },
    { lat: 48.8253284, lng: 18.1928009 },
    { lat: 48.8253343, lng: 18.1927099 },
    { lat: 48.8253354, lng: 18.1926291 },
    { lat: 48.8253408, lng: 18.1925468 },
    { lat: 48.8253509, lng: 18.1923069 },
    { lat: 48.8253618, lng: 18.1920649 },
    { lat: 48.8253658, lng: 18.1919777 },
    { lat: 48.8253689, lng: 18.1918967 },
    { lat: 48.825372, lng: 18.1918135 },
    { lat: 48.8253766, lng: 18.1917334 },
    { lat: 48.8253796, lng: 18.1916507 },
    { lat: 48.8253828, lng: 18.1915697 },
    { lat: 48.8253873, lng: 18.1914874 },
    { lat: 48.8253905, lng: 18.1914072 },
    { lat: 48.8253991, lng: 18.1912441 },
    { lat: 48.8254025, lng: 18.1911645 },
    { lat: 48.8254046, lng: 18.1911217 },
    { lat: 48.8254066, lng: 18.1910798 },
    { lat: 48.8254078, lng: 18.191002 },
    { lat: 48.8254131, lng: 18.1908387 },
    { lat: 48.825414, lng: 18.1907559 },
    { lat: 48.8254159, lng: 18.1906732 },
    { lat: 48.8254171, lng: 18.1906323 },
    { lat: 48.8254246, lng: 18.1904096 },
    { lat: 48.8254255, lng: 18.1903665 },
    { lat: 48.825426, lng: 18.1903188 },
    { lat: 48.8254327, lng: 18.1901392 },
    { lat: 48.8254309, lng: 18.1899518 },
    { lat: 48.8254291, lng: 18.1898614 },
    { lat: 48.8254293, lng: 18.1897669 },
    { lat: 48.8254299, lng: 18.1896787 },
    { lat: 48.8254293, lng: 18.18959 },
    { lat: 48.8254304, lng: 18.1895099 },
    { lat: 48.8254298, lng: 18.1894789 },
    { lat: 48.8254291, lng: 18.1894303 },
    { lat: 48.8254272, lng: 18.1893968 },
    { lat: 48.8242768, lng: 18.1888162 },
    { lat: 48.8234747, lng: 18.1883297 },
    { lat: 48.8226479, lng: 18.1879624 },
    { lat: 48.821862, lng: 18.1873828 },
    { lat: 48.8215594, lng: 18.1871832 },
    { lat: 48.8210967, lng: 18.1868303 },
    { lat: 48.8198172, lng: 18.1858722 },
    { lat: 48.819064, lng: 18.1854674 },
    { lat: 48.8186697, lng: 18.1854037 },
    { lat: 48.8186771, lng: 18.185373 },
    { lat: 48.8181787, lng: 18.1854595 },
    { lat: 48.8176234, lng: 18.1855561 },
    { lat: 48.8174127, lng: 18.185441 },
    { lat: 48.8172925, lng: 18.1853953 },
    { lat: 48.8165735, lng: 18.1846229 },
    { lat: 48.8164926, lng: 18.1845315 },
    { lat: 48.8162001, lng: 18.1839206 },
    { lat: 48.816089, lng: 18.1836162 },
    { lat: 48.8160748, lng: 18.1836239 },
    { lat: 48.815838, lng: 18.1827277 },
    { lat: 48.815835, lng: 18.1827113 },
    { lat: 48.8158297, lng: 18.1826619 },
    { lat: 48.8158249, lng: 18.1826138 },
    { lat: 48.8158241, lng: 18.1825846 },
    { lat: 48.8158231, lng: 18.1825675 },
    { lat: 48.8158226, lng: 18.1825167 },
    { lat: 48.8157471, lng: 18.1822445 },
    { lat: 48.8155667, lng: 18.1818179 },
    { lat: 48.8154807, lng: 18.181618 },
    { lat: 48.8153304, lng: 18.1813779 },
    { lat: 48.8153188, lng: 18.1813938 },
    { lat: 48.8145066, lng: 18.1799277 },
    { lat: 48.8143336, lng: 18.18 },
    { lat: 48.8142632, lng: 18.1800297 },
    { lat: 48.8141727, lng: 18.1800678 },
    { lat: 48.813644, lng: 18.1802897 },
    { lat: 48.8134457, lng: 18.1803736 },
    { lat: 48.8132717, lng: 18.1804467 },
    { lat: 48.8132387, lng: 18.180461 },
    { lat: 48.8130418, lng: 18.1805526 },
    { lat: 48.8128248, lng: 18.1806439 },
    { lat: 48.8119335, lng: 18.1810375 },
    { lat: 48.8119267, lng: 18.1809966 },
    { lat: 48.8118324, lng: 18.1810641 },
    { lat: 48.8117736, lng: 18.1810612 },
    { lat: 48.8111658, lng: 18.1813634 },
    { lat: 48.8111101, lng: 18.1813199 },
    { lat: 48.8111032, lng: 18.1813147 },
    { lat: 48.8107057, lng: 18.1816063 },
    { lat: 48.8104932, lng: 18.1817142 },
    { lat: 48.8104539, lng: 18.1817591 },
    { lat: 48.8104315, lng: 18.1818584 },
    { lat: 48.8102429, lng: 18.1823082 },
    { lat: 48.8100276, lng: 18.182704 },
    { lat: 48.8099625, lng: 18.1830036 },
    { lat: 48.809727, lng: 18.1834794 },
    { lat: 48.8096299, lng: 18.1837933 },
    { lat: 48.8096116, lng: 18.1840062 },
    { lat: 48.8095306, lng: 18.1842446 },
    { lat: 48.8090345, lng: 18.1852684 },
    { lat: 48.8089133, lng: 18.1856563 },
    { lat: 48.8089262, lng: 18.1857402 },
    { lat: 48.8089522, lng: 18.1859014 },
    { lat: 48.8089112, lng: 18.1858546 },
    { lat: 48.8088405, lng: 18.1859566 },
    { lat: 48.8084748, lng: 18.1864839 },
    { lat: 48.8079582, lng: 18.187118 },
    { lat: 48.8079467, lng: 18.1871528 },
    { lat: 48.8078811, lng: 18.1873082 },
    { lat: 48.8078667, lng: 18.1873538 },
    { lat: 48.807862, lng: 18.1873659 },
    { lat: 48.807842, lng: 18.1874136 },
    { lat: 48.8078371, lng: 18.1874257 },
    { lat: 48.807828, lng: 18.1874448 },
    { lat: 48.8077057, lng: 18.187592 },
    { lat: 48.8076718, lng: 18.1876322 },
    { lat: 48.8074799, lng: 18.1878269 },
    { lat: 48.8074103, lng: 18.1878912 },
    { lat: 48.8073256, lng: 18.1879675 },
    { lat: 48.8071097, lng: 18.1880794 },
    { lat: 48.8070442, lng: 18.1880376 },
    { lat: 48.8068766, lng: 18.1877919 },
    { lat: 48.8068481, lng: 18.1877494 },
    { lat: 48.8068013, lng: 18.1876826 },
    { lat: 48.8067495, lng: 18.1876042 },
    { lat: 48.8066738, lng: 18.1874843 },
    { lat: 48.8065429, lng: 18.1872899 },
    { lat: 48.8065386, lng: 18.1872719 },
    { lat: 48.806451, lng: 18.1871435 },
    { lat: 48.8064301, lng: 18.1871122 },
    { lat: 48.8064202, lng: 18.1870967 },
    { lat: 48.8063671, lng: 18.1870006 },
    { lat: 48.8062648, lng: 18.1868287 },
    { lat: 48.8061235, lng: 18.1865881 },
    { lat: 48.8060692, lng: 18.1864915 },
    { lat: 48.8059734, lng: 18.1863136 },
    { lat: 48.8059216, lng: 18.1862185 },
    { lat: 48.8057303, lng: 18.1858162 },
    { lat: 48.8054527, lng: 18.1851881 },
    { lat: 48.8052641, lng: 18.1846545 },
    { lat: 48.8051821, lng: 18.1841703 },
    { lat: 48.8048969, lng: 18.183493 },
    { lat: 48.8047192, lng: 18.1830303 },
    { lat: 48.8045664, lng: 18.1826689 },
    { lat: 48.8040473, lng: 18.1813155 },
    { lat: 48.8039809, lng: 18.1811854 },
    { lat: 48.803791, lng: 18.1807454 },
    { lat: 48.8037695, lng: 18.1806878 },
    { lat: 48.8035937, lng: 18.1801246 },
    { lat: 48.8034323, lng: 18.179653 },
    { lat: 48.8033574, lng: 18.1794077 },
    { lat: 48.8033339, lng: 18.1792547 },
    { lat: 48.8032986, lng: 18.1788806 },
    { lat: 48.8031021, lng: 18.1777385 },
    { lat: 48.8030895, lng: 18.1776607 },
    { lat: 48.8030927, lng: 18.1776335 },
    { lat: 48.8028277, lng: 18.1771866 },
    { lat: 48.8026708, lng: 18.1763067 },
    { lat: 48.8025087, lng: 18.1753742 },
    { lat: 48.8023522, lng: 18.1747917 },
    { lat: 48.8023389, lng: 18.1747379 },
    { lat: 48.8022192, lng: 18.1742644 },
    { lat: 48.8020185, lng: 18.1734859 },
    { lat: 48.8019656, lng: 18.1732545 },
    { lat: 48.8017277, lng: 18.1723387 },
    { lat: 48.8012738, lng: 18.1705331 },
    { lat: 48.8012547, lng: 18.1704346 },
    { lat: 48.8010229, lng: 18.1698238 },
    { lat: 48.8010113, lng: 18.1697942 },
    { lat: 48.8002178, lng: 18.1703405 },
    { lat: 48.8001438, lng: 18.1703871 },
    { lat: 48.8001191, lng: 18.1705074 },
    { lat: 48.8000434, lng: 18.1704866 },
    { lat: 48.799949, lng: 18.170609 },
    { lat: 48.7999321, lng: 18.1707294 },
    { lat: 48.7997929, lng: 18.170625 },
    { lat: 48.7997739, lng: 18.1706483 },
    { lat: 48.7997617, lng: 18.1707107 },
    { lat: 48.7996998, lng: 18.1707887 },
    { lat: 48.7997186, lng: 18.1708603 },
    { lat: 48.7996061, lng: 18.1709844 },
    { lat: 48.7995829, lng: 18.1709924 },
    { lat: 48.7995789, lng: 18.1708666 },
    { lat: 48.7995335, lng: 18.1708418 },
    { lat: 48.799528, lng: 18.1707248 },
    { lat: 48.7994756, lng: 18.170736 },
    { lat: 48.7994796, lng: 18.1707097 },
    { lat: 48.7994847, lng: 18.17068 },
    { lat: 48.7994761, lng: 18.1706658 },
    { lat: 48.79946, lng: 18.1706826 },
    { lat: 48.7994463, lng: 18.1706984 },
    { lat: 48.7994271, lng: 18.1707181 },
    { lat: 48.7987135, lng: 18.1714166 },
    { lat: 48.7984193, lng: 18.1716953 },
    { lat: 48.7980461, lng: 18.1721312 },
    { lat: 48.7973281, lng: 18.1729466 },
    { lat: 48.7967421, lng: 18.173626 },
    { lat: 48.7965013, lng: 18.1738536 },
    { lat: 48.7964968, lng: 18.1737758 },
    { lat: 48.7964944, lng: 18.1737504 },
    { lat: 48.7964898, lng: 18.1736708 },
    { lat: 48.7963591, lng: 18.173755 },
    { lat: 48.7962613, lng: 18.1738173 },
    { lat: 48.7959776, lng: 18.1739868 },
    { lat: 48.7956862, lng: 18.1741571 },
    { lat: 48.7953975, lng: 18.1742979 },
    { lat: 48.7949189, lng: 18.1745308 },
    { lat: 48.7948762, lng: 18.1745525 },
    { lat: 48.7948585, lng: 18.1745617 },
    { lat: 48.7948148, lng: 18.1745828 },
    { lat: 48.7946723, lng: 18.1746593 },
    { lat: 48.7941181, lng: 18.1749508 },
    { lat: 48.7940819, lng: 18.1749677 },
    { lat: 48.793629, lng: 18.1751958 },
    { lat: 48.7932523, lng: 18.1753965 },
    { lat: 48.792857, lng: 18.1756016 },
    { lat: 48.7925896, lng: 18.1757425 },
    { lat: 48.7924119, lng: 18.1758669 },
    { lat: 48.7923574, lng: 18.1759231 },
    { lat: 48.7922583, lng: 18.1760256 },
    { lat: 48.7922457, lng: 18.1760405 },
    { lat: 48.7918171, lng: 18.1765338 },
    { lat: 48.7916265, lng: 18.1767515 },
    { lat: 48.7913441, lng: 18.1770783 },
    { lat: 48.7913566, lng: 18.1771357 },
    { lat: 48.7913608, lng: 18.1771505 },
    { lat: 48.791377, lng: 18.1772182 },
    { lat: 48.7913972, lng: 18.1772953 },
    { lat: 48.7914081, lng: 18.1773286 },
    { lat: 48.7914156, lng: 18.1773601 },
    { lat: 48.7917763, lng: 18.1787444 },
    { lat: 48.7919305, lng: 18.1793225 },
    { lat: 48.7920306, lng: 18.1797104 },
    { lat: 48.7921695, lng: 18.1802572 },
    { lat: 48.7922793, lng: 18.180694 },
    { lat: 48.7923262, lng: 18.180884 },
    { lat: 48.7924051, lng: 18.1811946 },
    { lat: 48.7925553, lng: 18.1817463 },
    { lat: 48.793071, lng: 18.1817656 },
    { lat: 48.7935737, lng: 18.1818353 },
    { lat: 48.793572, lng: 18.1819983 },
    { lat: 48.793556, lng: 18.182936 },
    { lat: 48.7935379, lng: 18.183895 },
    { lat: 48.7935377, lng: 18.1839326 },
    { lat: 48.7935131, lng: 18.1849766 },
    { lat: 48.7934771, lng: 18.1868399 },
    { lat: 48.7934771, lng: 18.1868768 },
    { lat: 48.793476, lng: 18.1868991 },
    { lat: 48.7934429, lng: 18.1888554 },
    { lat: 48.7934434, lng: 18.1889015 },
    { lat: 48.7933952, lng: 18.1913974 },
    { lat: 48.7933798, lng: 18.1924237 },
    { lat: 48.7933253, lng: 18.1924395 },
    { lat: 48.7931218, lng: 18.1925507 },
    { lat: 48.7931533, lng: 18.1926668 },
    { lat: 48.7932106, lng: 18.1928783 },
    { lat: 48.793225, lng: 18.1929308 },
    { lat: 48.7914171, lng: 18.1939323 },
    { lat: 48.7916555, lng: 18.1947011 },
    { lat: 48.7916494, lng: 18.1948089 },
    { lat: 48.7916386, lng: 18.1949753 },
    { lat: 48.7918903, lng: 18.1965082 },
    { lat: 48.7921488, lng: 18.1980031 },
    { lat: 48.792399, lng: 18.1995101 },
    { lat: 48.7923999, lng: 18.1995662 },
    { lat: 48.7924023, lng: 18.199646 },
    { lat: 48.792403, lng: 18.1996992 },
    { lat: 48.7925825, lng: 18.2011315 },
    { lat: 48.7926448, lng: 18.2014919 },
    { lat: 48.7927091, lng: 18.2017472 },
    { lat: 48.7927121, lng: 18.2017576 },
    { lat: 48.7929296, lng: 18.2025034 },
    { lat: 48.7932981, lng: 18.203398 },
    { lat: 48.7937599, lng: 18.2045601 },
    { lat: 48.7939752, lng: 18.2050486 },
    { lat: 48.7944993, lng: 18.2063372 },
    { lat: 48.794374, lng: 18.2063164 },
    { lat: 48.7944044, lng: 18.2071016 },
    { lat: 48.7942867, lng: 18.2083718 },
    { lat: 48.7940104, lng: 18.2091032 },
    { lat: 48.793684, lng: 18.2098278 },
    { lat: 48.7939599, lng: 18.2101738 },
    { lat: 48.7934336, lng: 18.2109705 },
    { lat: 48.7933127, lng: 18.2111625 },
    { lat: 48.7930149, lng: 18.2117749 },
    { lat: 48.7924189, lng: 18.2129157 },
    { lat: 48.7922545, lng: 18.2132361 },
    { lat: 48.7922413, lng: 18.2132615 },
    { lat: 48.7922101, lng: 18.2133278 },
    { lat: 48.7922228, lng: 18.2133713 },
    { lat: 48.7922301, lng: 18.2133996 },
    { lat: 48.7923248, lng: 18.2138106 },
    { lat: 48.7924563, lng: 18.2141165 },
    { lat: 48.7934583, lng: 18.2154766 },
    { lat: 48.7943146, lng: 18.216577 },
    { lat: 48.7948035, lng: 18.2170952 },
    { lat: 48.7953179, lng: 18.2176622 },
    { lat: 48.7952919, lng: 18.2177046 },
    { lat: 48.7952755, lng: 18.2177311 },
    { lat: 48.795215, lng: 18.2178357 },
    { lat: 48.7951886, lng: 18.2180052 },
    { lat: 48.7955173, lng: 18.2187992 },
    { lat: 48.796229, lng: 18.2198969 },
    { lat: 48.7968171, lng: 18.2207489 },
    { lat: 48.7972704, lng: 18.2211797 },
    { lat: 48.797804, lng: 18.221598 },
    { lat: 48.798092, lng: 18.221182 },
    { lat: 48.798301, lng: 18.221059 },
    { lat: 48.798501, lng: 18.221057 },
    { lat: 48.798841, lng: 18.220737 },
    { lat: 48.799165, lng: 18.220411 },
    { lat: 48.799613, lng: 18.22004 },
    { lat: 48.800082, lng: 18.219742 },
    { lat: 48.801149, lng: 18.219549 },
  ],
  DolnáPoruba: [
    { lat: 48.924891, lng: 18.278471 },
    { lat: 48.924347, lng: 18.277283 },
    { lat: 48.923964, lng: 18.276854 },
    { lat: 48.923676, lng: 18.276476 },
    { lat: 48.92344, lng: 18.27627 },
    { lat: 48.923096, lng: 18.276018 },
    { lat: 48.922851, lng: 18.275762 },
    { lat: 48.92274, lng: 18.275578 },
    { lat: 48.922428, lng: 18.274925 },
    { lat: 48.922361, lng: 18.274728 },
    { lat: 48.922251, lng: 18.274331 },
    { lat: 48.922116, lng: 18.274167 },
    { lat: 48.92204, lng: 18.274225 },
    { lat: 48.921797, lng: 18.274114 },
    { lat: 48.921772, lng: 18.274103 },
    { lat: 48.921292, lng: 18.273951 },
    { lat: 48.920975, lng: 18.273764 },
    { lat: 48.92065, lng: 18.2735 },
    { lat: 48.920632, lng: 18.273287 },
    { lat: 48.920708, lng: 18.272782 },
    { lat: 48.920561, lng: 18.271923 },
    { lat: 48.92034, lng: 18.271333 },
    { lat: 48.920307, lng: 18.270842 },
    { lat: 48.920248, lng: 18.270106 },
    { lat: 48.920247, lng: 18.269818 },
    { lat: 48.920175, lng: 18.269007 },
    { lat: 48.919947, lng: 18.268363 },
    { lat: 48.919852, lng: 18.267689 },
    { lat: 48.919677, lng: 18.267357 },
    { lat: 48.919516, lng: 18.266583 },
    { lat: 48.91946, lng: 18.266332 },
    { lat: 48.919368, lng: 18.265921 },
    { lat: 48.919234, lng: 18.265356 },
    { lat: 48.919101, lng: 18.265148 },
    { lat: 48.919002, lng: 18.264865 },
    { lat: 48.919019, lng: 18.263788 },
    { lat: 48.918886, lng: 18.263523 },
    { lat: 48.918629, lng: 18.262536 },
    { lat: 48.918533, lng: 18.261875 },
    { lat: 48.918417, lng: 18.261438 },
    { lat: 48.918246, lng: 18.260669 },
    { lat: 48.91806, lng: 18.259737 },
    { lat: 48.917922, lng: 18.258789 },
    { lat: 48.917766, lng: 18.257763 },
    { lat: 48.917499, lng: 18.25683 },
    { lat: 48.917335, lng: 18.256131 },
    { lat: 48.917051, lng: 18.25552 },
    { lat: 48.917026, lng: 18.255467 },
    { lat: 48.91679, lng: 18.255003 },
    { lat: 48.916596, lng: 18.254539 },
    { lat: 48.916518, lng: 18.254367 },
    { lat: 48.915998, lng: 18.254278 },
    { lat: 48.915726, lng: 18.254234 },
    { lat: 48.915264, lng: 18.25427 },
    { lat: 48.915022, lng: 18.254221 },
    { lat: 48.914846, lng: 18.254036 },
    { lat: 48.914625, lng: 18.253583 },
    { lat: 48.914475, lng: 18.253441 },
    { lat: 48.914346, lng: 18.253319 },
    { lat: 48.914079, lng: 18.253176 },
    { lat: 48.913805, lng: 18.253063 },
    { lat: 48.9133582, lng: 18.2531164 },
    { lat: 48.9133337, lng: 18.2531193 },
    { lat: 48.9129226, lng: 18.2531691 },
    { lat: 48.9126936, lng: 18.2531926 },
    { lat: 48.9124909, lng: 18.2532159 },
    { lat: 48.9123171, lng: 18.2532361 },
    { lat: 48.912184, lng: 18.2532571 },
    { lat: 48.9118099, lng: 18.2538603 },
    { lat: 48.9116477, lng: 18.253884 },
    { lat: 48.911598, lng: 18.2539464 },
    { lat: 48.9115267, lng: 18.2540393 },
    { lat: 48.9113963, lng: 18.2542074 },
    { lat: 48.9113733, lng: 18.2542395 },
    { lat: 48.9113531, lng: 18.2542421 },
    { lat: 48.9103353, lng: 18.2545589 },
    { lat: 48.9100064, lng: 18.2555403 },
    { lat: 48.909906, lng: 18.2557001 },
    { lat: 48.9094756, lng: 18.256407 },
    { lat: 48.9094563, lng: 18.2564415 },
    { lat: 48.909244, lng: 18.2567171 },
    { lat: 48.909103, lng: 18.2569349 },
    { lat: 48.909026, lng: 18.2570537 },
    { lat: 48.9090125, lng: 18.2570738 },
    { lat: 48.9089762, lng: 18.2571298 },
    { lat: 48.9089004, lng: 18.2572448 },
    { lat: 48.9088573, lng: 18.2573171 },
    { lat: 48.9088173, lng: 18.2573708 },
    { lat: 48.9087501, lng: 18.2574782 },
    { lat: 48.9086756, lng: 18.2575981 },
    { lat: 48.9086219, lng: 18.2576851 },
    { lat: 48.9085276, lng: 18.2578531 },
    { lat: 48.9083017, lng: 18.2582459 },
    { lat: 48.9082258, lng: 18.2583792 },
    { lat: 48.9081514, lng: 18.2585758 },
    { lat: 48.9080945, lng: 18.2587285 },
    { lat: 48.907898, lng: 18.2592603 },
    { lat: 48.9075538, lng: 18.2601937 },
    { lat: 48.9075001, lng: 18.2603431 },
    { lat: 48.9062021, lng: 18.2604148 },
    { lat: 48.9061816, lng: 18.2603906 },
    { lat: 48.9061785, lng: 18.2601163 },
    { lat: 48.9061134, lng: 18.2600057 },
    { lat: 48.9058924, lng: 18.259927 },
    { lat: 48.9056748, lng: 18.2599672 },
    { lat: 48.9055134, lng: 18.2595906 },
    { lat: 48.9050518, lng: 18.2592105 },
    { lat: 48.9049861, lng: 18.258949 },
    { lat: 48.9049832, lng: 18.2589393 },
    { lat: 48.9049794, lng: 18.2589308 },
    { lat: 48.9049067, lng: 18.2587832 },
    { lat: 48.9048608, lng: 18.258691 },
    { lat: 48.9045304, lng: 18.2581969 },
    { lat: 48.90432, lng: 18.2581057 },
    { lat: 48.904176, lng: 18.2581934 },
    { lat: 48.9040921, lng: 18.2581649 },
    { lat: 48.904059, lng: 18.2580978 },
    { lat: 48.9039982, lng: 18.2579429 },
    { lat: 48.9035685, lng: 18.2579873 },
    { lat: 48.9033989, lng: 18.2577243 },
    { lat: 48.903368, lng: 18.2574547 },
    { lat: 48.9032419, lng: 18.2572822 },
    { lat: 48.903042, lng: 18.2571595 },
    { lat: 48.9028845, lng: 18.25683 },
    { lat: 48.9027943, lng: 18.2567708 },
    { lat: 48.9026764, lng: 18.2566427 },
    { lat: 48.9026498, lng: 18.256633 },
    { lat: 48.9024551, lng: 18.2565598 },
    { lat: 48.9023868, lng: 18.2564317 },
    { lat: 48.9023626, lng: 18.2558471 },
    { lat: 48.9023232, lng: 18.2558278 },
    { lat: 48.9023115, lng: 18.2558218 },
    { lat: 48.9022628, lng: 18.255797 },
    { lat: 48.9022423, lng: 18.2557946 },
    { lat: 48.9022412, lng: 18.2557948 },
    { lat: 48.9019451, lng: 18.2557643 },
    { lat: 48.9019091, lng: 18.2556894 },
    { lat: 48.9018888, lng: 18.2556468 },
    { lat: 48.9019842, lng: 18.2553048 },
    { lat: 48.9020047, lng: 18.2548083 },
    { lat: 48.9020994, lng: 18.2543322 },
    { lat: 48.9020458, lng: 18.2543406 },
    { lat: 48.9019242, lng: 18.2544282 },
    { lat: 48.9016665, lng: 18.254336 },
    { lat: 48.9014825, lng: 18.2541568 },
    { lat: 48.9013247, lng: 18.2540422 },
    { lat: 48.9012177, lng: 18.2539654 },
    { lat: 48.9010184, lng: 18.2540908 },
    { lat: 48.9004661, lng: 18.2551131 },
    { lat: 48.9003332, lng: 18.2556167 },
    { lat: 48.9002889, lng: 18.2557907 },
    { lat: 48.9002003, lng: 18.2559569 },
    { lat: 48.9001226, lng: 18.2559803 },
    { lat: 48.8999585, lng: 18.2560294 },
    { lat: 48.8998609, lng: 18.256059 },
    { lat: 48.8996344, lng: 18.256127 },
    { lat: 48.8994816, lng: 18.2564324 },
    { lat: 48.8994157, lng: 18.2565633 },
    { lat: 48.8993411, lng: 18.2567606 },
    { lat: 48.8992774, lng: 18.2569289 },
    { lat: 48.899196, lng: 18.2571443 },
    { lat: 48.8990635, lng: 18.257276 },
    { lat: 48.8990153, lng: 18.2573232 },
    { lat: 48.8988753, lng: 18.2575588 },
    { lat: 48.8982408, lng: 18.2577891 },
    { lat: 48.8978376, lng: 18.2579792 },
    { lat: 48.8976362, lng: 18.2582511 },
    { lat: 48.8974614, lng: 18.25835 },
    { lat: 48.8972063, lng: 18.2584877 },
    { lat: 48.8967942, lng: 18.2586211 },
    { lat: 48.8966932, lng: 18.2586318 },
    { lat: 48.8965101, lng: 18.2587841 },
    { lat: 48.8963497, lng: 18.2589987 },
    { lat: 48.8962199, lng: 18.259058 },
    { lat: 48.8960937, lng: 18.2590207 },
    { lat: 48.8960147, lng: 18.2591407 },
    { lat: 48.89593, lng: 18.2592196 },
    { lat: 48.8956586, lng: 18.2594494 },
    { lat: 48.8954974, lng: 18.2595877 },
    { lat: 48.8954179, lng: 18.2596784 },
    { lat: 48.8952278, lng: 18.2598901 },
    { lat: 48.895108, lng: 18.2600248 },
    { lat: 48.8949946, lng: 18.260235 },
    { lat: 48.8940257, lng: 18.2606341 },
    { lat: 48.8937765, lng: 18.2607376 },
    { lat: 48.8936363, lng: 18.2607513 },
    { lat: 48.8929797, lng: 18.2608123 },
    { lat: 48.8928803, lng: 18.260831 },
    { lat: 48.8922829, lng: 18.2609413 },
    { lat: 48.8917626, lng: 18.2607981 },
    { lat: 48.8916221, lng: 18.2607508 },
    { lat: 48.8915764, lng: 18.260736 },
    { lat: 48.891535, lng: 18.2607238 },
    { lat: 48.8914994, lng: 18.2607134 },
    { lat: 48.8907998, lng: 18.2608919 },
    { lat: 48.890723, lng: 18.2609102 },
    { lat: 48.8898144, lng: 18.260149 },
    { lat: 48.8897708, lng: 18.2601167 },
    { lat: 48.8897305, lng: 18.2600818 },
    { lat: 48.8896035, lng: 18.2599736 },
    { lat: 48.8885688, lng: 18.2591185 },
    { lat: 48.8863088, lng: 18.257867 },
    { lat: 48.8854397, lng: 18.2570653 },
    { lat: 48.8850048, lng: 18.2571554 },
    { lat: 48.883318, lng: 18.2570582 },
    { lat: 48.8825318, lng: 18.2571985 },
    { lat: 48.8815268, lng: 18.2573668 },
    { lat: 48.8813923, lng: 18.2574421 },
    { lat: 48.8802868, lng: 18.2580593 },
    { lat: 48.880101, lng: 18.258156 },
    { lat: 48.880107, lng: 18.258228 },
    { lat: 48.880173, lng: 18.259237 },
    { lat: 48.880244, lng: 18.259815 },
    { lat: 48.880346, lng: 18.260141 },
    { lat: 48.880577, lng: 18.260617 },
    { lat: 48.881443, lng: 18.2625 },
    { lat: 48.881843, lng: 18.263612 },
    { lat: 48.882608, lng: 18.266619 },
    { lat: 48.883142, lng: 18.267309 },
    { lat: 48.883301, lng: 18.268505 },
    { lat: 48.883724, lng: 18.269402 },
    { lat: 48.883875, lng: 18.270488 },
    { lat: 48.884082, lng: 18.27157 },
    { lat: 48.884203, lng: 18.272289 },
    { lat: 48.884295, lng: 18.27279 },
    { lat: 48.884395, lng: 18.273634 },
    { lat: 48.884363, lng: 18.274736 },
    { lat: 48.884129, lng: 18.275331 },
    { lat: 48.884118, lng: 18.276142 },
    { lat: 48.884452, lng: 18.276724 },
    { lat: 48.884975, lng: 18.277993 },
    { lat: 48.885452, lng: 18.278651 },
    { lat: 48.885655, lng: 18.279371 },
    { lat: 48.885863, lng: 18.280346 },
    { lat: 48.88584, lng: 18.280969 },
    { lat: 48.885874, lng: 18.281556 },
    { lat: 48.886025, lng: 18.282501 },
    { lat: 48.886127, lng: 18.283925 },
    { lat: 48.886106, lng: 18.284456 },
    { lat: 48.886254, lng: 18.28603 },
    { lat: 48.886523, lng: 18.286922 },
    { lat: 48.886521, lng: 18.287418 },
    { lat: 48.886388, lng: 18.289257 },
    { lat: 48.885299, lng: 18.289226 },
    { lat: 48.88471, lng: 18.289503 },
    { lat: 48.884579, lng: 18.289657 },
    { lat: 48.884518, lng: 18.28973 },
    { lat: 48.884399, lng: 18.28989 },
    { lat: 48.883748, lng: 18.290882 },
    { lat: 48.88346, lng: 18.291666 },
    { lat: 48.88329, lng: 18.291555 },
    { lat: 48.88325, lng: 18.292378 },
    { lat: 48.883124, lng: 18.29252 },
    { lat: 48.882914, lng: 18.292749 },
    { lat: 48.882902, lng: 18.292761 },
    { lat: 48.882694, lng: 18.292955 },
    { lat: 48.882599, lng: 18.293045 },
    { lat: 48.882164, lng: 18.293791 },
    { lat: 48.88211, lng: 18.293889 },
    { lat: 48.881872, lng: 18.294321 },
    { lat: 48.881763, lng: 18.29462 },
    { lat: 48.88154, lng: 18.295052 },
    { lat: 48.881451, lng: 18.2951 },
    { lat: 48.881139, lng: 18.295266 },
    { lat: 48.880992, lng: 18.295416 },
    { lat: 48.88097, lng: 18.295437 },
    { lat: 48.88072, lng: 18.296085 },
    { lat: 48.880819, lng: 18.297281 },
    { lat: 48.880857, lng: 18.298158 },
    { lat: 48.880765, lng: 18.298683 },
    { lat: 48.880444, lng: 18.299233 },
    { lat: 48.880167, lng: 18.299801 },
    { lat: 48.879956, lng: 18.301348 },
    { lat: 48.879105, lng: 18.301792 },
    { lat: 48.878412, lng: 18.302063 },
    { lat: 48.87864, lng: 18.303461 },
    { lat: 48.878835, lng: 18.304638 },
    { lat: 48.878996, lng: 18.305583 },
    { lat: 48.879109, lng: 18.305927 },
    { lat: 48.879118, lng: 18.305955 },
    { lat: 48.879185, lng: 18.306157 },
    { lat: 48.879232, lng: 18.306205 },
    { lat: 48.87926, lng: 18.306235 },
    { lat: 48.879475, lng: 18.306445 },
    { lat: 48.879608, lng: 18.306578 },
    { lat: 48.880063, lng: 18.307489 },
    { lat: 48.880202, lng: 18.308054 },
    { lat: 48.880238, lng: 18.308385 },
    { lat: 48.880254, lng: 18.308536 },
    { lat: 48.87961, lng: 18.309017 },
    { lat: 48.8796643, lng: 18.3095254 },
    { lat: 48.879805, lng: 18.310841 },
    { lat: 48.880237, lng: 18.31096 },
    { lat: 48.880496, lng: 18.311459 },
    { lat: 48.881066, lng: 18.312656 },
    { lat: 48.880532, lng: 18.313862 },
    { lat: 48.880424, lng: 18.314168 },
    { lat: 48.880155, lng: 18.314647 },
    { lat: 48.882026, lng: 18.314316 },
    { lat: 48.883517, lng: 18.314886 },
    { lat: 48.884564, lng: 18.315768 },
    { lat: 48.884572, lng: 18.316352 },
    { lat: 48.88468, lng: 18.316517 },
    { lat: 48.884695, lng: 18.316536 },
    { lat: 48.884945, lng: 18.316917 },
    { lat: 48.885191, lng: 18.317122 },
    { lat: 48.885986, lng: 18.317778 },
    { lat: 48.886861, lng: 18.318488 },
    { lat: 48.8877315, lng: 18.3189214 },
    { lat: 48.887797, lng: 18.318954 },
    { lat: 48.888672, lng: 18.319621 },
    { lat: 48.8889927, lng: 18.3200131 },
    { lat: 48.889229, lng: 18.320302 },
    { lat: 48.889253, lng: 18.320465 },
    { lat: 48.889477, lng: 18.322092 },
    { lat: 48.889863, lng: 18.323158 },
    { lat: 48.89033, lng: 18.324034 },
    { lat: 48.890469, lng: 18.324298 },
    { lat: 48.890645, lng: 18.325009 },
    { lat: 48.890782, lng: 18.326109 },
    { lat: 48.890885, lng: 18.326335 },
    { lat: 48.891748, lng: 18.327117 },
    { lat: 48.892875, lng: 18.328991 },
    { lat: 48.893331, lng: 18.329697 },
    { lat: 48.893634, lng: 18.330166 },
    { lat: 48.893792, lng: 18.330298 },
    { lat: 48.89413, lng: 18.330584 },
    { lat: 48.894393, lng: 18.330812 },
    { lat: 48.894475, lng: 18.330922 },
    { lat: 48.894815, lng: 18.33139 },
    { lat: 48.895024, lng: 18.332125 },
    { lat: 48.895238, lng: 18.332317 },
    { lat: 48.895262, lng: 18.332517 },
    { lat: 48.895429, lng: 18.332111 },
    { lat: 48.895616, lng: 18.332008 },
    { lat: 48.895796, lng: 18.331999 },
    { lat: 48.896106, lng: 18.331987 },
    { lat: 48.896454, lng: 18.332001 },
    { lat: 48.897001, lng: 18.332263 },
    { lat: 48.897365, lng: 18.332221 },
    { lat: 48.898244, lng: 18.332183 },
    { lat: 48.898699, lng: 18.331975 },
    { lat: 48.899084, lng: 18.331493 },
    { lat: 48.899458, lng: 18.330901 },
    { lat: 48.899863, lng: 18.330262 },
    { lat: 48.900422, lng: 18.329122 },
    { lat: 48.900949, lng: 18.327985 },
    { lat: 48.901284, lng: 18.327892 },
    { lat: 48.90156, lng: 18.327823 },
    { lat: 48.90183, lng: 18.327384 },
    { lat: 48.902552, lng: 18.327303 },
    { lat: 48.902554, lng: 18.327303 },
    { lat: 48.902619, lng: 18.327308 },
    { lat: 48.902935, lng: 18.327336 },
    { lat: 48.903313, lng: 18.327137 },
    { lat: 48.90359, lng: 18.327209 },
    { lat: 48.903948, lng: 18.327248 },
    { lat: 48.90419, lng: 18.327092 },
    { lat: 48.904537, lng: 18.326978 },
    { lat: 48.904655, lng: 18.326947 },
    { lat: 48.904711, lng: 18.326911 },
    { lat: 48.90497, lng: 18.32674 },
    { lat: 48.904984, lng: 18.326708 },
    { lat: 48.905139, lng: 18.326393 },
    { lat: 48.905301, lng: 18.326217 },
    { lat: 48.905467, lng: 18.326043 },
    { lat: 48.905757, lng: 18.325743 },
    { lat: 48.907828, lng: 18.324418 },
    { lat: 48.90895, lng: 18.325912 },
    { lat: 48.908978, lng: 18.32595 },
    { lat: 48.909105, lng: 18.326121 },
    { lat: 48.909297, lng: 18.326296 },
    { lat: 48.910469, lng: 18.327367 },
    { lat: 48.910827, lng: 18.327896 },
    { lat: 48.910965, lng: 18.328103 },
    { lat: 48.911252, lng: 18.328403 },
    { lat: 48.911377, lng: 18.328533 },
    { lat: 48.911377, lng: 18.328534 },
    { lat: 48.911383, lng: 18.328543 },
    { lat: 48.912085, lng: 18.329678 },
    { lat: 48.913325, lng: 18.328779 },
    { lat: 48.913658, lng: 18.328655 },
    { lat: 48.914721, lng: 18.328239 },
    { lat: 48.916161, lng: 18.327308 },
    { lat: 48.917074, lng: 18.327071 },
    { lat: 48.91836, lng: 18.327161 },
    { lat: 48.918607, lng: 18.327178 },
    { lat: 48.918608, lng: 18.327178 },
    { lat: 48.919545, lng: 18.326868 },
    { lat: 48.919932, lng: 18.326966 },
    { lat: 48.919933, lng: 18.326967 },
    { lat: 48.919963, lng: 18.32697 },
    { lat: 48.920435, lng: 18.327014 },
    { lat: 48.921015, lng: 18.326938 },
    { lat: 48.92153, lng: 18.326915 },
    { lat: 48.922306, lng: 18.326881 },
    { lat: 48.922585, lng: 18.326911 },
    { lat: 48.923227, lng: 18.327083 },
    { lat: 48.924283, lng: 18.325118 },
    { lat: 48.924525, lng: 18.32434 },
    { lat: 48.924572, lng: 18.324039 },
    { lat: 48.924587, lng: 18.323916 },
    { lat: 48.924587, lng: 18.323886 },
    { lat: 48.924647, lng: 18.323534 },
    { lat: 48.924561, lng: 18.323052 },
    { lat: 48.924535, lng: 18.322739 },
    { lat: 48.924465, lng: 18.321878 },
    { lat: 48.924482, lng: 18.32181 },
    { lat: 48.924631, lng: 18.32121 },
    { lat: 48.924043, lng: 18.319692 },
    { lat: 48.923961, lng: 18.31955 },
    { lat: 48.923917, lng: 18.319473 },
    { lat: 48.923886, lng: 18.319416 },
    { lat: 48.923511, lng: 18.31875 },
    { lat: 48.923555, lng: 18.318303 },
    { lat: 48.923561, lng: 18.318257 },
    { lat: 48.923589, lng: 18.318031 },
    { lat: 48.923706, lng: 18.31739 },
    { lat: 48.923791, lng: 18.317411 },
    { lat: 48.923721, lng: 18.316711 },
    { lat: 48.923637, lng: 18.316323 },
    { lat: 48.923627, lng: 18.316 },
    { lat: 48.923689, lng: 18.315544 },
    { lat: 48.923704, lng: 18.31551 },
    { lat: 48.923754, lng: 18.315397 },
    { lat: 48.923785, lng: 18.315328 },
    { lat: 48.923661, lng: 18.314158 },
    { lat: 48.923656, lng: 18.314119 },
    { lat: 48.923794, lng: 18.314057 },
    { lat: 48.923721, lng: 18.313563 },
    { lat: 48.923697, lng: 18.313438 },
    { lat: 48.923641, lng: 18.313205 },
    { lat: 48.923665, lng: 18.313116 },
    { lat: 48.923694, lng: 18.313015 },
    { lat: 48.923703, lng: 18.312947 },
    { lat: 48.923832, lng: 18.311956 },
    { lat: 48.923754, lng: 18.311823 },
    { lat: 48.923637, lng: 18.311616 },
    { lat: 48.923576, lng: 18.311058 },
    { lat: 48.92352, lng: 18.310218 },
    { lat: 48.923343, lng: 18.309583 },
    { lat: 48.923335, lng: 18.309235 },
    { lat: 48.923311, lng: 18.308299 },
    { lat: 48.923577, lng: 18.307923 },
    { lat: 48.923928, lng: 18.30742 },
    { lat: 48.923677, lng: 18.306112 },
    { lat: 48.924502, lng: 18.304756 },
    { lat: 48.924497, lng: 18.304261 },
    { lat: 48.924482, lng: 18.303669 },
    { lat: 48.924762, lng: 18.300866 },
    { lat: 48.924767, lng: 18.300411 },
    { lat: 48.924936, lng: 18.30008 },
    { lat: 48.924919, lng: 18.299808 },
    { lat: 48.924913, lng: 18.299711 },
    { lat: 48.924909, lng: 18.299646 },
    { lat: 48.924902, lng: 18.299535 },
    { lat: 48.924883, lng: 18.299233 },
    { lat: 48.924647, lng: 18.298042 },
    { lat: 48.924607, lng: 18.297798 },
    { lat: 48.92456, lng: 18.297447 },
    { lat: 48.924392, lng: 18.296747 },
    { lat: 48.924664, lng: 18.296682 },
    { lat: 48.924677, lng: 18.296586 },
    { lat: 48.924722, lng: 18.296257 },
    { lat: 48.924748, lng: 18.296066 },
    { lat: 48.924803, lng: 18.295941 },
    { lat: 48.925049, lng: 18.295246 },
    { lat: 48.924499, lng: 18.2943 },
    { lat: 48.924128, lng: 18.294165 },
    { lat: 48.923932, lng: 18.293795 },
    { lat: 48.923761, lng: 18.2933 },
    { lat: 48.923867, lng: 18.293082 },
    { lat: 48.923751, lng: 18.292885 },
    { lat: 48.923516, lng: 18.292554 },
    { lat: 48.923381, lng: 18.292556 },
    { lat: 48.923589, lng: 18.292194 },
    { lat: 48.923692, lng: 18.292013 },
    { lat: 48.92398, lng: 18.291827 },
    { lat: 48.924047, lng: 18.291844 },
    { lat: 48.924251, lng: 18.291813 },
    { lat: 48.924435, lng: 18.291739 },
    { lat: 48.924537, lng: 18.291556 },
    { lat: 48.924652, lng: 18.291218 },
    { lat: 48.924418, lng: 18.290333 },
    { lat: 48.924284, lng: 18.28926 },
    { lat: 48.924138, lng: 18.28868 },
    { lat: 48.924306, lng: 18.288299 },
    { lat: 48.924226, lng: 18.287833 },
    { lat: 48.924284, lng: 18.287746 },
    { lat: 48.924258, lng: 18.287578 },
    { lat: 48.924026, lng: 18.287051 },
    { lat: 48.923664, lng: 18.286194 },
    { lat: 48.923873, lng: 18.286017 },
    { lat: 48.923858, lng: 18.285355 },
    { lat: 48.924591, lng: 18.285105 },
    { lat: 48.925036, lng: 18.28336 },
    { lat: 48.92502, lng: 18.283106 },
    { lat: 48.924974, lng: 18.282104 },
    { lat: 48.924814, lng: 18.280452 },
    { lat: 48.924873, lng: 18.279006 },
    { lat: 48.924891, lng: 18.278471 },
  ],
  Horňany: [
    { lat: 48.779045, lng: 18.179027 },
    { lat: 48.778617, lng: 18.179529 },
    { lat: 48.778346, lng: 18.179922 },
    { lat: 48.777713, lng: 18.180661 },
    { lat: 48.776724, lng: 18.181788 },
    { lat: 48.776687, lng: 18.18191 },
    { lat: 48.776447, lng: 18.182132 },
    { lat: 48.77611, lng: 18.182583 },
    { lat: 48.775818, lng: 18.18281 },
    { lat: 48.775479, lng: 18.18304 },
    { lat: 48.775074, lng: 18.183518 },
    { lat: 48.774684, lng: 18.183911 },
    { lat: 48.774335, lng: 18.184117 },
    { lat: 48.774334, lng: 18.184118 },
    { lat: 48.77384, lng: 18.184406 },
    { lat: 48.773634, lng: 18.184759 },
    { lat: 48.773518, lng: 18.184957 },
    { lat: 48.773464, lng: 18.185224 },
    { lat: 48.773328, lng: 18.185449 },
    { lat: 48.773308, lng: 18.185485 },
    { lat: 48.77317, lng: 18.185568 },
    { lat: 48.772721, lng: 18.186246 },
    { lat: 48.772745, lng: 18.186421 },
    { lat: 48.772366, lng: 18.186598 },
    { lat: 48.771974, lng: 18.186668 },
    { lat: 48.771828, lng: 18.186935 },
    { lat: 48.771482, lng: 18.187935 },
    { lat: 48.771663, lng: 18.188735 },
    { lat: 48.77199, lng: 18.189538 },
    { lat: 48.772267, lng: 18.190189 },
    { lat: 48.772373, lng: 18.190539 },
    { lat: 48.772386, lng: 18.19058 },
    { lat: 48.772466, lng: 18.190844 },
    { lat: 48.772722, lng: 18.19202 },
    { lat: 48.772378, lng: 18.192492 },
    { lat: 48.772245, lng: 18.192622 },
    { lat: 48.772054, lng: 18.192807 },
    { lat: 48.771959, lng: 18.192894 },
    { lat: 48.771538, lng: 18.193282 },
    { lat: 48.77152, lng: 18.193298 },
    { lat: 48.771505, lng: 18.193312 },
    { lat: 48.771503, lng: 18.193313 },
    { lat: 48.771288, lng: 18.193462 },
    { lat: 48.77109, lng: 18.193671 },
    { lat: 48.7703825, lng: 18.1945538 },
    { lat: 48.770395, lng: 18.194589 },
    { lat: 48.770861, lng: 18.195994 },
    { lat: 48.77085, lng: 18.196042 },
    { lat: 48.770585, lng: 18.197149 },
    { lat: 48.768698, lng: 18.199333 },
    { lat: 48.768446, lng: 18.199626 },
    { lat: 48.768273, lng: 18.20003 },
    { lat: 48.768265, lng: 18.200056 },
    { lat: 48.76818, lng: 18.200316 },
    { lat: 48.76807, lng: 18.20061 },
    { lat: 48.768055, lng: 18.200607 },
    { lat: 48.76807, lng: 18.200861 },
    { lat: 48.768145, lng: 18.201289 },
    { lat: 48.768324, lng: 18.20209 },
    { lat: 48.768399, lng: 18.202325 },
    { lat: 48.769331, lng: 18.204052 },
    { lat: 48.769929, lng: 18.205123 },
    { lat: 48.76993, lng: 18.205127 },
    { lat: 48.769934, lng: 18.205132 },
    { lat: 48.770131, lng: 18.205427 },
    { lat: 48.770154, lng: 18.205454 },
    { lat: 48.770193, lng: 18.205503 },
    { lat: 48.770215, lng: 18.20553 },
    { lat: 48.771112, lng: 18.206633 },
    { lat: 48.771775, lng: 18.20748 },
    { lat: 48.772469, lng: 18.20841 },
    { lat: 48.772695, lng: 18.208746 },
    { lat: 48.773129, lng: 18.209393 },
    { lat: 48.773369, lng: 18.209842 },
    { lat: 48.773688, lng: 18.210605 },
    { lat: 48.773847, lng: 18.21103 },
    { lat: 48.774098, lng: 18.211051 },
    { lat: 48.774507, lng: 18.211162 },
    { lat: 48.774866, lng: 18.211134 },
    { lat: 48.774905, lng: 18.211295 },
    { lat: 48.774933, lng: 18.211414 },
    { lat: 48.775033, lng: 18.211838 },
    { lat: 48.775485, lng: 18.211932 },
    { lat: 48.775571, lng: 18.212319 },
    { lat: 48.77629, lng: 18.212748 },
    { lat: 48.776605, lng: 18.212901 },
    { lat: 48.77661, lng: 18.212968 },
    { lat: 48.776663, lng: 18.213682 },
    { lat: 48.776664, lng: 18.21369 },
    { lat: 48.776716, lng: 18.214115 },
    { lat: 48.776742, lng: 18.214624 },
    { lat: 48.77674, lng: 18.21534 },
    { lat: 48.776732, lng: 18.215438 },
    { lat: 48.776726, lng: 18.21553 },
    { lat: 48.776715, lng: 18.215673 },
    { lat: 48.776704, lng: 18.215811 },
    { lat: 48.776669, lng: 18.216267 },
    { lat: 48.776647, lng: 18.216603 },
    { lat: 48.77668, lng: 18.216703 },
    { lat: 48.776737, lng: 18.216875 },
    { lat: 48.776763, lng: 18.216954 },
    { lat: 48.776767, lng: 18.216975 },
    { lat: 48.776859, lng: 18.217393 },
    { lat: 48.777095, lng: 18.21811 },
    { lat: 48.777098, lng: 18.218115 },
    { lat: 48.777107, lng: 18.218149 },
    { lat: 48.777234, lng: 18.218617 },
    { lat: 48.777253, lng: 18.218686 },
    { lat: 48.777305, lng: 18.218646 },
    { lat: 48.777358, lng: 18.218607 },
    { lat: 48.777677, lng: 18.218368 },
    { lat: 48.777826, lng: 18.218242 },
    { lat: 48.777917, lng: 18.218165 },
    { lat: 48.77799, lng: 18.218102 },
    { lat: 48.778, lng: 18.218143 },
    { lat: 48.778072, lng: 18.218519 },
    { lat: 48.778113, lng: 18.218755 },
    { lat: 48.778152, lng: 18.218998 },
    { lat: 48.778222, lng: 18.219484 },
    { lat: 48.778286, lng: 18.219984 },
    { lat: 48.778288, lng: 18.219991 },
    { lat: 48.778351, lng: 18.220523 },
    { lat: 48.778406, lng: 18.221152 },
    { lat: 48.778472, lng: 18.222189 },
    { lat: 48.778525, lng: 18.22318 },
    { lat: 48.77961, lng: 18.223125 },
    { lat: 48.779682, lng: 18.223122 },
    { lat: 48.78081, lng: 18.223073 },
    { lat: 48.781329, lng: 18.22291 },
    { lat: 48.781639, lng: 18.222746 },
    { lat: 48.782829, lng: 18.222043 },
    { lat: 48.78313, lng: 18.221898 },
    { lat: 48.783387, lng: 18.221655 },
    { lat: 48.783504, lng: 18.221433 },
    { lat: 48.783632, lng: 18.221192 },
    { lat: 48.783986, lng: 18.221068 },
    { lat: 48.78482, lng: 18.220829 },
    { lat: 48.785846, lng: 18.220533 },
    { lat: 48.786358, lng: 18.220483 },
    { lat: 48.786962, lng: 18.220566 },
    { lat: 48.787519, lng: 18.220878 },
    { lat: 48.788604, lng: 18.221551 },
    { lat: 48.789263, lng: 18.221994 },
    { lat: 48.790064, lng: 18.222455 },
    { lat: 48.790982, lng: 18.222837 },
    { lat: 48.791963, lng: 18.223116 },
    { lat: 48.792324, lng: 18.223463 },
    { lat: 48.792665, lng: 18.223521 },
    { lat: 48.792962, lng: 18.223635 },
    { lat: 48.793697, lng: 18.223348 },
    { lat: 48.794684, lng: 18.222957 },
    { lat: 48.79583, lng: 18.222553 },
    { lat: 48.796289, lng: 18.222206 },
    { lat: 48.796617, lng: 18.222087 },
    { lat: 48.797119, lng: 18.221871 },
    { lat: 48.797804, lng: 18.221598 },
    { lat: 48.7972704, lng: 18.2211797 },
    { lat: 48.7968171, lng: 18.2207489 },
    { lat: 48.796229, lng: 18.2198969 },
    { lat: 48.7955173, lng: 18.2187992 },
    { lat: 48.7951886, lng: 18.2180052 },
    { lat: 48.795215, lng: 18.2178357 },
    { lat: 48.7952755, lng: 18.2177311 },
    { lat: 48.7952919, lng: 18.2177046 },
    { lat: 48.7953179, lng: 18.2176622 },
    { lat: 48.7948035, lng: 18.2170952 },
    { lat: 48.7943146, lng: 18.216577 },
    { lat: 48.7934583, lng: 18.2154766 },
    { lat: 48.7924563, lng: 18.2141165 },
    { lat: 48.7923248, lng: 18.2138106 },
    { lat: 48.7922301, lng: 18.2133996 },
    { lat: 48.7922228, lng: 18.2133713 },
    { lat: 48.7922101, lng: 18.2133278 },
    { lat: 48.7922413, lng: 18.2132615 },
    { lat: 48.7922545, lng: 18.2132361 },
    { lat: 48.7924189, lng: 18.2129157 },
    { lat: 48.7930149, lng: 18.2117749 },
    { lat: 48.7933127, lng: 18.2111625 },
    { lat: 48.7934336, lng: 18.2109705 },
    { lat: 48.7939599, lng: 18.2101738 },
    { lat: 48.793684, lng: 18.2098278 },
    { lat: 48.7940104, lng: 18.2091032 },
    { lat: 48.7942867, lng: 18.2083718 },
    { lat: 48.7944044, lng: 18.2071016 },
    { lat: 48.794374, lng: 18.2063164 },
    { lat: 48.7944993, lng: 18.2063372 },
    { lat: 48.7939752, lng: 18.2050486 },
    { lat: 48.7937599, lng: 18.2045601 },
    { lat: 48.7932981, lng: 18.203398 },
    { lat: 48.7929296, lng: 18.2025034 },
    { lat: 48.7927121, lng: 18.2017576 },
    { lat: 48.7927091, lng: 18.2017472 },
    { lat: 48.7926448, lng: 18.2014919 },
    { lat: 48.7925825, lng: 18.2011315 },
    { lat: 48.792403, lng: 18.1996992 },
    { lat: 48.7924023, lng: 18.199646 },
    { lat: 48.7923999, lng: 18.1995662 },
    { lat: 48.792399, lng: 18.1995101 },
    { lat: 48.7921488, lng: 18.1980031 },
    { lat: 48.7918903, lng: 18.1965082 },
    { lat: 48.7916386, lng: 18.1949753 },
    { lat: 48.7916494, lng: 18.1948089 },
    { lat: 48.7916555, lng: 18.1947011 },
    { lat: 48.7914171, lng: 18.1939323 },
    { lat: 48.793225, lng: 18.1929308 },
    { lat: 48.7932106, lng: 18.1928783 },
    { lat: 48.7931533, lng: 18.1926668 },
    { lat: 48.7931218, lng: 18.1925507 },
    { lat: 48.7933253, lng: 18.1924395 },
    { lat: 48.7933798, lng: 18.1924237 },
    { lat: 48.7933952, lng: 18.1913974 },
    { lat: 48.7934434, lng: 18.1889015 },
    { lat: 48.7934429, lng: 18.1888554 },
    { lat: 48.793476, lng: 18.1868991 },
    { lat: 48.7934771, lng: 18.1868768 },
    { lat: 48.7934771, lng: 18.1868399 },
    { lat: 48.7935131, lng: 18.1849766 },
    { lat: 48.7935377, lng: 18.1839326 },
    { lat: 48.7935379, lng: 18.183895 },
    { lat: 48.793556, lng: 18.182936 },
    { lat: 48.793572, lng: 18.1819983 },
    { lat: 48.7935737, lng: 18.1818353 },
    { lat: 48.793071, lng: 18.1817656 },
    { lat: 48.7925553, lng: 18.1817463 },
    { lat: 48.7917473, lng: 18.1818601 },
    { lat: 48.7912043, lng: 18.1819378 },
    { lat: 48.7902041, lng: 18.1820851 },
    { lat: 48.7897452, lng: 18.1821525 },
    { lat: 48.7897129, lng: 18.1821585 },
    { lat: 48.7891991, lng: 18.1824453 },
    { lat: 48.788695, lng: 18.1827258 },
    { lat: 48.7886419, lng: 18.1827442 },
    { lat: 48.7884949, lng: 18.1827994 },
    { lat: 48.7883789, lng: 18.1828412 },
    { lat: 48.7882472, lng: 18.1828872 },
    { lat: 48.7879538, lng: 18.1829238 },
    { lat: 48.7878363, lng: 18.182941 },
    { lat: 48.7876535, lng: 18.1829619 },
    { lat: 48.7875448, lng: 18.1829756 },
    { lat: 48.787435, lng: 18.18299 },
    { lat: 48.7872189, lng: 18.1830185 },
    { lat: 48.7871115, lng: 18.1830349 },
    { lat: 48.7869122, lng: 18.1830608 },
    { lat: 48.7866925, lng: 18.183088 },
    { lat: 48.7865686, lng: 18.1830714 },
    { lat: 48.7864103, lng: 18.1830454 },
    { lat: 48.7861905, lng: 18.18301 },
    { lat: 48.7860208, lng: 18.1829826 },
    { lat: 48.7859115, lng: 18.1829652 },
    { lat: 48.7857464, lng: 18.1829397 },
    { lat: 48.785244, lng: 18.182958 },
    { lat: 48.7852195, lng: 18.182963 },
    { lat: 48.7851235, lng: 18.1829793 },
    { lat: 48.785024, lng: 18.1829974 },
    { lat: 48.7849246, lng: 18.1830123 },
    { lat: 48.784853, lng: 18.183025 },
    { lat: 48.7847771, lng: 18.1830386 },
    { lat: 48.7846284, lng: 18.1830632 },
    { lat: 48.7845314, lng: 18.1830817 },
    { lat: 48.7844332, lng: 18.1831015 },
    { lat: 48.7842466, lng: 18.1831343 },
    { lat: 48.7840615, lng: 18.1831727 },
    { lat: 48.7838728, lng: 18.1832079 },
    { lat: 48.7836853, lng: 18.183242 },
    { lat: 48.7833322, lng: 18.1833225 },
    { lat: 48.7831782, lng: 18.1833743 },
    { lat: 48.7828701, lng: 18.18347 },
    { lat: 48.7826774, lng: 18.1835524 },
    { lat: 48.7825369, lng: 18.1836006 },
    { lat: 48.7824977, lng: 18.1836269 },
    { lat: 48.7824072, lng: 18.1836649 },
    { lat: 48.7822755, lng: 18.1837252 },
    { lat: 48.7821434, lng: 18.1837845 },
    { lat: 48.7820184, lng: 18.1838482 },
    { lat: 48.781881, lng: 18.1839093 },
    { lat: 48.781792, lng: 18.1839521 },
    { lat: 48.7817032, lng: 18.183995 },
    { lat: 48.7815473, lng: 18.1840728 },
    { lat: 48.7814266, lng: 18.184132 },
    { lat: 48.7814047, lng: 18.184142 },
    { lat: 48.7811839, lng: 18.1842492 },
    { lat: 48.7808663, lng: 18.184417 },
    { lat: 48.7807344, lng: 18.184419 },
    { lat: 48.7806939, lng: 18.1844207 },
    { lat: 48.7806242, lng: 18.1844267 },
    { lat: 48.7805703, lng: 18.1844302 },
    { lat: 48.7803035, lng: 18.1844007 },
    { lat: 48.7800482, lng: 18.1830636 },
    { lat: 48.7798086, lng: 18.1823935 },
    { lat: 48.7797351, lng: 18.1819177 },
    { lat: 48.7796906, lng: 18.1816384 },
    { lat: 48.779671, lng: 18.1815108 },
    { lat: 48.7796631, lng: 18.1814145 },
    { lat: 48.7796283, lng: 18.1811228 },
    { lat: 48.7796006, lng: 18.1809948 },
    { lat: 48.7795824, lng: 18.1808759 },
    { lat: 48.7795457, lng: 18.1806285 },
    { lat: 48.7794652, lng: 18.180369 },
    { lat: 48.7793867, lng: 18.1801228 },
    { lat: 48.7793106, lng: 18.1798774 },
    { lat: 48.7792289, lng: 18.179637 },
    { lat: 48.7791499, lng: 18.1793816 },
    { lat: 48.7790459, lng: 18.1790629 },
    { lat: 48.779045, lng: 18.179027 },
  ],
  Motešice: [
    { lat: 48.8010113, lng: 18.1697942 },
    { lat: 48.8010229, lng: 18.1698238 },
    { lat: 48.8012547, lng: 18.1704346 },
    { lat: 48.8012738, lng: 18.1705331 },
    { lat: 48.8017277, lng: 18.1723387 },
    { lat: 48.8019656, lng: 18.1732545 },
    { lat: 48.8020185, lng: 18.1734859 },
    { lat: 48.8022192, lng: 18.1742644 },
    { lat: 48.8023389, lng: 18.1747379 },
    { lat: 48.8023522, lng: 18.1747917 },
    { lat: 48.8025087, lng: 18.1753742 },
    { lat: 48.8026708, lng: 18.1763067 },
    { lat: 48.8028277, lng: 18.1771866 },
    { lat: 48.8030927, lng: 18.1776335 },
    { lat: 48.8030895, lng: 18.1776607 },
    { lat: 48.8031021, lng: 18.1777385 },
    { lat: 48.8032986, lng: 18.1788806 },
    { lat: 48.8033339, lng: 18.1792547 },
    { lat: 48.8033574, lng: 18.1794077 },
    { lat: 48.8034323, lng: 18.179653 },
    { lat: 48.8035937, lng: 18.1801246 },
    { lat: 48.8037695, lng: 18.1806878 },
    { lat: 48.803791, lng: 18.1807454 },
    { lat: 48.8039809, lng: 18.1811854 },
    { lat: 48.8040473, lng: 18.1813155 },
    { lat: 48.8045664, lng: 18.1826689 },
    { lat: 48.8047192, lng: 18.1830303 },
    { lat: 48.8048969, lng: 18.183493 },
    { lat: 48.8051821, lng: 18.1841703 },
    { lat: 48.8052641, lng: 18.1846545 },
    { lat: 48.8054527, lng: 18.1851881 },
    { lat: 48.8057303, lng: 18.1858162 },
    { lat: 48.8059216, lng: 18.1862185 },
    { lat: 48.8059734, lng: 18.1863136 },
    { lat: 48.8060692, lng: 18.1864915 },
    { lat: 48.8061235, lng: 18.1865881 },
    { lat: 48.8062648, lng: 18.1868287 },
    { lat: 48.8063671, lng: 18.1870006 },
    { lat: 48.8064202, lng: 18.1870967 },
    { lat: 48.8064301, lng: 18.1871122 },
    { lat: 48.806451, lng: 18.1871435 },
    { lat: 48.8065386, lng: 18.1872719 },
    { lat: 48.8065429, lng: 18.1872899 },
    { lat: 48.8066738, lng: 18.1874843 },
    { lat: 48.8067495, lng: 18.1876042 },
    { lat: 48.8068013, lng: 18.1876826 },
    { lat: 48.8068481, lng: 18.1877494 },
    { lat: 48.8068766, lng: 18.1877919 },
    { lat: 48.8070442, lng: 18.1880376 },
    { lat: 48.8071097, lng: 18.1880794 },
    { lat: 48.8073256, lng: 18.1879675 },
    { lat: 48.8074103, lng: 18.1878912 },
    { lat: 48.8074799, lng: 18.1878269 },
    { lat: 48.8076718, lng: 18.1876322 },
    { lat: 48.8077057, lng: 18.187592 },
    { lat: 48.807828, lng: 18.1874448 },
    { lat: 48.8078371, lng: 18.1874257 },
    { lat: 48.807842, lng: 18.1874136 },
    { lat: 48.807862, lng: 18.1873659 },
    { lat: 48.8078667, lng: 18.1873538 },
    { lat: 48.8078811, lng: 18.1873082 },
    { lat: 48.8079467, lng: 18.1871528 },
    { lat: 48.8079582, lng: 18.187118 },
    { lat: 48.8084748, lng: 18.1864839 },
    { lat: 48.8088405, lng: 18.1859566 },
    { lat: 48.8089112, lng: 18.1858546 },
    { lat: 48.8089522, lng: 18.1859014 },
    { lat: 48.8089262, lng: 18.1857402 },
    { lat: 48.8089133, lng: 18.1856563 },
    { lat: 48.8090345, lng: 18.1852684 },
    { lat: 48.8095306, lng: 18.1842446 },
    { lat: 48.8096116, lng: 18.1840062 },
    { lat: 48.8096299, lng: 18.1837933 },
    { lat: 48.809727, lng: 18.1834794 },
    { lat: 48.8099625, lng: 18.1830036 },
    { lat: 48.8100276, lng: 18.182704 },
    { lat: 48.8102429, lng: 18.1823082 },
    { lat: 48.8104315, lng: 18.1818584 },
    { lat: 48.8104539, lng: 18.1817591 },
    { lat: 48.8104932, lng: 18.1817142 },
    { lat: 48.8107057, lng: 18.1816063 },
    { lat: 48.8111032, lng: 18.1813147 },
    { lat: 48.8111101, lng: 18.1813199 },
    { lat: 48.8111658, lng: 18.1813634 },
    { lat: 48.8117736, lng: 18.1810612 },
    { lat: 48.8118324, lng: 18.1810641 },
    { lat: 48.8119267, lng: 18.1809966 },
    { lat: 48.8119335, lng: 18.1810375 },
    { lat: 48.8128248, lng: 18.1806439 },
    { lat: 48.8130418, lng: 18.1805526 },
    { lat: 48.8132387, lng: 18.180461 },
    { lat: 48.8132717, lng: 18.1804467 },
    { lat: 48.8134457, lng: 18.1803736 },
    { lat: 48.813644, lng: 18.1802897 },
    { lat: 48.8141727, lng: 18.1800678 },
    { lat: 48.8142632, lng: 18.1800297 },
    { lat: 48.8143336, lng: 18.18 },
    { lat: 48.8145066, lng: 18.1799277 },
    { lat: 48.8153188, lng: 18.1813938 },
    { lat: 48.8153304, lng: 18.1813779 },
    { lat: 48.8154807, lng: 18.181618 },
    { lat: 48.8155667, lng: 18.1818179 },
    { lat: 48.8157471, lng: 18.1822445 },
    { lat: 48.8158226, lng: 18.1825167 },
    { lat: 48.8158231, lng: 18.1825675 },
    { lat: 48.8158241, lng: 18.1825846 },
    { lat: 48.8158249, lng: 18.1826138 },
    { lat: 48.8158297, lng: 18.1826619 },
    { lat: 48.815835, lng: 18.1827113 },
    { lat: 48.815838, lng: 18.1827277 },
    { lat: 48.8160748, lng: 18.1836239 },
    { lat: 48.816089, lng: 18.1836162 },
    { lat: 48.8162001, lng: 18.1839206 },
    { lat: 48.8164926, lng: 18.1845315 },
    { lat: 48.8165735, lng: 18.1846229 },
    { lat: 48.8172925, lng: 18.1853953 },
    { lat: 48.8174127, lng: 18.185441 },
    { lat: 48.8176234, lng: 18.1855561 },
    { lat: 48.8181787, lng: 18.1854595 },
    { lat: 48.8186771, lng: 18.185373 },
    { lat: 48.8186697, lng: 18.1854037 },
    { lat: 48.819064, lng: 18.1854674 },
    { lat: 48.8198172, lng: 18.1858722 },
    { lat: 48.8210967, lng: 18.1868303 },
    { lat: 48.8215594, lng: 18.1871832 },
    { lat: 48.821862, lng: 18.1873828 },
    { lat: 48.8226479, lng: 18.1879624 },
    { lat: 48.8234747, lng: 18.1883297 },
    { lat: 48.8242768, lng: 18.1888162 },
    { lat: 48.8254272, lng: 18.1893968 },
    { lat: 48.8254291, lng: 18.1894303 },
    { lat: 48.8254298, lng: 18.1894789 },
    { lat: 48.8254304, lng: 18.1895099 },
    { lat: 48.8254293, lng: 18.18959 },
    { lat: 48.8254299, lng: 18.1896787 },
    { lat: 48.8254293, lng: 18.1897669 },
    { lat: 48.8254291, lng: 18.1898614 },
    { lat: 48.8254309, lng: 18.1899518 },
    { lat: 48.8254327, lng: 18.1901392 },
    { lat: 48.825426, lng: 18.1903188 },
    { lat: 48.8254255, lng: 18.1903665 },
    { lat: 48.8254246, lng: 18.1904096 },
    { lat: 48.8254171, lng: 18.1906323 },
    { lat: 48.8254159, lng: 18.1906732 },
    { lat: 48.825414, lng: 18.1907559 },
    { lat: 48.8254131, lng: 18.1908387 },
    { lat: 48.8254078, lng: 18.191002 },
    { lat: 48.8254066, lng: 18.1910798 },
    { lat: 48.8254046, lng: 18.1911217 },
    { lat: 48.8254025, lng: 18.1911645 },
    { lat: 48.8253991, lng: 18.1912441 },
    { lat: 48.8253905, lng: 18.1914072 },
    { lat: 48.8253873, lng: 18.1914874 },
    { lat: 48.8253828, lng: 18.1915697 },
    { lat: 48.8253796, lng: 18.1916507 },
    { lat: 48.8253766, lng: 18.1917334 },
    { lat: 48.825372, lng: 18.1918135 },
    { lat: 48.8253689, lng: 18.1918967 },
    { lat: 48.8253658, lng: 18.1919777 },
    { lat: 48.8253618, lng: 18.1920649 },
    { lat: 48.8253509, lng: 18.1923069 },
    { lat: 48.8253408, lng: 18.1925468 },
    { lat: 48.8253354, lng: 18.1926291 },
    { lat: 48.8253343, lng: 18.1927099 },
    { lat: 48.8253284, lng: 18.1928009 },
    { lat: 48.8253218, lng: 18.1928827 },
    { lat: 48.8253153, lng: 18.1929666 },
    { lat: 48.825309, lng: 18.1930516 },
    { lat: 48.8253025, lng: 18.1931352 },
    { lat: 48.8252993, lng: 18.193179 },
    { lat: 48.8252949, lng: 18.1932218 },
    { lat: 48.8252894, lng: 18.193302 },
    { lat: 48.8252833, lng: 18.1933898 },
    { lat: 48.8252776, lng: 18.1934669 },
    { lat: 48.8252657, lng: 18.193635 },
    { lat: 48.8252576, lng: 18.1937492 },
    { lat: 48.8252538, lng: 18.193802 },
    { lat: 48.82525, lng: 18.1938716 },
    { lat: 48.8252483, lng: 18.1939413 },
    { lat: 48.825242, lng: 18.1940084 },
    { lat: 48.8252394, lng: 18.1940786 },
    { lat: 48.8252354, lng: 18.1941489 },
    { lat: 48.8252326, lng: 18.1942159 },
    { lat: 48.8252231, lng: 18.1942884 },
    { lat: 48.8252128, lng: 18.1943646 },
    { lat: 48.825206, lng: 18.1944235 },
    { lat: 48.8251968, lng: 18.1944978 },
    { lat: 48.8251903, lng: 18.1945613 },
    { lat: 48.8251852, lng: 18.1946286 },
    { lat: 48.8251702, lng: 18.1947627 },
    { lat: 48.8251648, lng: 18.1948254 },
    { lat: 48.825158, lng: 18.1949001 },
    { lat: 48.8251536, lng: 18.194964 },
    { lat: 48.8251464, lng: 18.1950359 },
    { lat: 48.8251355, lng: 18.1951798 },
    { lat: 48.8250477, lng: 18.1963804 },
    { lat: 48.8250457, lng: 18.1964243 },
    { lat: 48.825594, lng: 18.1966003 },
    { lat: 48.8263833, lng: 18.196906 },
    { lat: 48.827451, lng: 18.1973025 },
    { lat: 48.8276914, lng: 18.1975359 },
    { lat: 48.8278985, lng: 18.197803 },
    { lat: 48.8279557, lng: 18.1978764 },
    { lat: 48.8280141, lng: 18.1979504 },
    { lat: 48.8280945, lng: 18.1980544 },
    { lat: 48.8283313, lng: 18.1980884 },
    { lat: 48.8287032, lng: 18.1981633 },
    { lat: 48.8296634, lng: 18.1981214 },
    { lat: 48.830232, lng: 18.1983344 },
    { lat: 48.8311633, lng: 18.1989496 },
    { lat: 48.8316717, lng: 18.1993167 },
    { lat: 48.8320678, lng: 18.1993548 },
    { lat: 48.8325923, lng: 18.1993724 },
    { lat: 48.8326354, lng: 18.1993739 },
    { lat: 48.833107, lng: 18.1993913 },
    { lat: 48.8338526, lng: 18.1993888 },
    { lat: 48.8345964, lng: 18.1993918 },
    { lat: 48.8348696, lng: 18.1994916 },
    { lat: 48.8351873, lng: 18.1994032 },
    { lat: 48.8354315, lng: 18.1993947 },
    { lat: 48.8355394, lng: 18.199388 },
    { lat: 48.8360037, lng: 18.1994927 },
    { lat: 48.8364205, lng: 18.1995793 },
    { lat: 48.8372637, lng: 18.1997641 },
    { lat: 48.8386701, lng: 18.2000442 },
    { lat: 48.8390563, lng: 18.200128 },
    { lat: 48.8397523, lng: 18.2002101 },
    { lat: 48.8398108, lng: 18.2001816 },
    { lat: 48.8400195, lng: 18.2002004 },
    { lat: 48.8410854, lng: 18.1998576 },
    { lat: 48.8418024, lng: 18.1995701 },
    { lat: 48.8426009, lng: 18.1990923 },
    { lat: 48.8435379, lng: 18.1987654 },
    { lat: 48.8444071, lng: 18.1984765 },
    { lat: 48.8449777, lng: 18.1982143 },
    { lat: 48.8460835, lng: 18.197701 },
    { lat: 48.8469984, lng: 18.1969535 },
    { lat: 48.8477288, lng: 18.1962697 },
    { lat: 48.8480826, lng: 18.1959078 },
    { lat: 48.8488409, lng: 18.1958659 },
    { lat: 48.8498566, lng: 18.1958116 },
    { lat: 48.8505312, lng: 18.1968692 },
    { lat: 48.8510793, lng: 18.1970019 },
    { lat: 48.8516857, lng: 18.1971477 },
    { lat: 48.8519828, lng: 18.1971745 },
    { lat: 48.8521729, lng: 18.197339 },
    { lat: 48.8529218, lng: 18.1975506 },
    { lat: 48.853588, lng: 18.1975771 },
    { lat: 48.8539675, lng: 18.1975436 },
    { lat: 48.8543819, lng: 18.1974306 },
    { lat: 48.8546988, lng: 18.1974483 },
    { lat: 48.8551799, lng: 18.1975333 },
    { lat: 48.8560544, lng: 18.1979061 },
    { lat: 48.8562794, lng: 18.1980735 },
    { lat: 48.8567931, lng: 18.1984136 },
    { lat: 48.8574432, lng: 18.1987694 },
    { lat: 48.8578084, lng: 18.1991345 },
    { lat: 48.8581503, lng: 18.1997276 },
    { lat: 48.858443, lng: 18.2001277 },
    { lat: 48.8588309, lng: 18.2007145 },
    { lat: 48.8594323, lng: 18.2016358 },
    { lat: 48.8599185, lng: 18.20264 },
    { lat: 48.8600306, lng: 18.2028417 },
    { lat: 48.8600465, lng: 18.2028639 },
    { lat: 48.8604737, lng: 18.2034269 },
    { lat: 48.8610458, lng: 18.2041135 },
    { lat: 48.8616802, lng: 18.2038253 },
    { lat: 48.8623599, lng: 18.2038115 },
    { lat: 48.8630397, lng: 18.2039606 },
    { lat: 48.8633794, lng: 18.2041983 },
    { lat: 48.8634052, lng: 18.2042157 },
    { lat: 48.8634174, lng: 18.2041929 },
    { lat: 48.8638938, lng: 18.2046087 },
    { lat: 48.8648825, lng: 18.2054598 },
    { lat: 48.8662351, lng: 18.2064766 },
    { lat: 48.8673044, lng: 18.2067262 },
    { lat: 48.8677925, lng: 18.2069124 },
    { lat: 48.8680946, lng: 18.2071462 },
    { lat: 48.868425, lng: 18.20726 },
    { lat: 48.8687096, lng: 18.2074437 },
    { lat: 48.8695234, lng: 18.2078814 },
    { lat: 48.8696964, lng: 18.2079866 },
    { lat: 48.8700094, lng: 18.2080923 },
    { lat: 48.8704744, lng: 18.208429 },
    { lat: 48.8707245, lng: 18.2084775 },
    { lat: 48.871327, lng: 18.2087924 },
    { lat: 48.8718892, lng: 18.209311 },
    { lat: 48.8721427, lng: 18.2100927 },
    { lat: 48.8722896, lng: 18.2104523 },
    { lat: 48.8727273, lng: 18.2114539 },
    { lat: 48.8728676, lng: 18.2119344 },
    { lat: 48.8730889, lng: 18.2127637 },
    { lat: 48.87319, lng: 18.213329 },
    { lat: 48.8732815, lng: 18.2138819 },
    { lat: 48.8733064, lng: 18.2139044 },
    { lat: 48.8733195, lng: 18.2139181 },
    { lat: 48.8733438, lng: 18.2139092 },
    { lat: 48.873661, lng: 18.213081 },
    { lat: 48.8737627, lng: 18.2123747 },
    { lat: 48.8737002, lng: 18.2113194 },
    { lat: 48.8738145, lng: 18.2105402 },
    { lat: 48.8736622, lng: 18.2093744 },
    { lat: 48.873165, lng: 18.2083778 },
    { lat: 48.8729099, lng: 18.2077256 },
    { lat: 48.8728088, lng: 18.2072023 },
    { lat: 48.8728651, lng: 18.2069422 },
    { lat: 48.8731862, lng: 18.2065225 },
    { lat: 48.8732267, lng: 18.2064032 },
    { lat: 48.8732381, lng: 18.2062271 },
    { lat: 48.8732595, lng: 18.2062247 },
    { lat: 48.873235, lng: 18.2060153 },
    { lat: 48.873264, lng: 18.2060101 },
    { lat: 48.8732903, lng: 18.2057797 },
    { lat: 48.8733342, lng: 18.2056589 },
    { lat: 48.8733543, lng: 18.2054974 },
    { lat: 48.8732422, lng: 18.2051763 },
    { lat: 48.873286, lng: 18.2048376 },
    { lat: 48.8734928, lng: 18.2045848 },
    { lat: 48.8737562, lng: 18.2042419 },
    { lat: 48.8737322, lng: 18.2040963 },
    { lat: 48.8737218, lng: 18.2039141 },
    { lat: 48.8737964, lng: 18.2033265 },
    { lat: 48.8738488, lng: 18.2031963 },
    { lat: 48.8739945, lng: 18.2027107 },
    { lat: 48.8739779, lng: 18.2026573 },
    { lat: 48.873891, lng: 18.2026344 },
    { lat: 48.8738847, lng: 18.2024667 },
    { lat: 48.8739507, lng: 18.2022982 },
    { lat: 48.8740314, lng: 18.2022123 },
    { lat: 48.8740111, lng: 18.2019934 },
    { lat: 48.8741741, lng: 18.2017486 },
    { lat: 48.8742242, lng: 18.2010674 },
    { lat: 48.8743059, lng: 18.2008957 },
    { lat: 48.8743128, lng: 18.2005668 },
    { lat: 48.8742437, lng: 18.2004207 },
    { lat: 48.8742557, lng: 18.200219 },
    { lat: 48.874281, lng: 18.2000698 },
    { lat: 48.8742671, lng: 18.1998645 },
    { lat: 48.8742977, lng: 18.1995532 },
    { lat: 48.8742422, lng: 18.1994311 },
    { lat: 48.8741466, lng: 18.1993599 },
    { lat: 48.8741433, lng: 18.1993015 },
    { lat: 48.8741807, lng: 18.1991285 },
    { lat: 48.8742657, lng: 18.1988954 },
    { lat: 48.8742515, lng: 18.1985904 },
    { lat: 48.8741779, lng: 18.1984861 },
    { lat: 48.8741832, lng: 18.1984008 },
    { lat: 48.8742831, lng: 18.1982959 },
    { lat: 48.8742581, lng: 18.1982357 },
    { lat: 48.8743396, lng: 18.19804 },
    { lat: 48.8743993, lng: 18.1980583 },
    { lat: 48.8746508, lng: 18.1981356 },
    { lat: 48.8750855, lng: 18.1982779 },
    { lat: 48.8753852, lng: 18.19837 },
    { lat: 48.8758487, lng: 18.198462 },
    { lat: 48.8761249, lng: 18.1985162 },
    { lat: 48.8764818, lng: 18.1985638 },
    { lat: 48.8769737, lng: 18.1985406 },
    { lat: 48.8772015, lng: 18.1985579 },
    { lat: 48.8774726, lng: 18.1985859 },
    { lat: 48.8778805, lng: 18.1978854 },
    { lat: 48.8781638, lng: 18.1974161 },
    { lat: 48.8784675, lng: 18.1969434 },
    { lat: 48.8787803, lng: 18.1967523 },
    { lat: 48.8791203, lng: 18.196621 },
    { lat: 48.8794204, lng: 18.1963445 },
    { lat: 48.8794944, lng: 18.196139 },
    { lat: 48.8795327, lng: 18.1953718 },
    { lat: 48.8797354, lng: 18.1947765 },
    { lat: 48.879851, lng: 18.1946264 },
    { lat: 48.8802077, lng: 18.1940979 },
    { lat: 48.8806377, lng: 18.1935099 },
    { lat: 48.8809974, lng: 18.1930709 },
    { lat: 48.8811307, lng: 18.1929181 },
    { lat: 48.8811865, lng: 18.192848 },
    { lat: 48.8812084, lng: 18.1928176 },
    { lat: 48.881157, lng: 18.1925895 },
    { lat: 48.8811171, lng: 18.1923463 },
    { lat: 48.8811106, lng: 18.192155 },
    { lat: 48.8810644, lng: 18.1920593 },
    { lat: 48.8810118, lng: 18.1920447 },
    { lat: 48.8809099, lng: 18.1921973 },
    { lat: 48.8808542, lng: 18.1922078 },
    { lat: 48.8807664, lng: 18.1921155 },
    { lat: 48.8806349, lng: 18.1919869 },
    { lat: 48.8804741, lng: 18.1919945 },
    { lat: 48.8803275, lng: 18.1919576 },
    { lat: 48.8801409, lng: 18.191813 },
    { lat: 48.8799286, lng: 18.1917897 },
    { lat: 48.879723, lng: 18.1918652 },
    { lat: 48.8795704, lng: 18.1918797 },
    { lat: 48.8792945, lng: 18.1917906 },
    { lat: 48.8791437, lng: 18.1918363 },
    { lat: 48.8787682, lng: 18.1917819 },
    { lat: 48.8786362, lng: 18.1918018 },
    { lat: 48.8785504, lng: 18.1917622 },
    { lat: 48.878517, lng: 18.1916903 },
    { lat: 48.8784963, lng: 18.1916267 },
    { lat: 48.8785121, lng: 18.1915475 },
    { lat: 48.8784911, lng: 18.1913789 },
    { lat: 48.8783828, lng: 18.1913007 },
    { lat: 48.878349, lng: 18.1913227 },
    { lat: 48.8783154, lng: 18.1914616 },
    { lat: 48.878186, lng: 18.1914891 },
    { lat: 48.8781054, lng: 18.1913832 },
    { lat: 48.8781132, lng: 18.1912632 },
    { lat: 48.877946, lng: 18.1910447 },
    { lat: 48.8778339, lng: 18.1910358 },
    { lat: 48.8777116, lng: 18.1909484 },
    { lat: 48.8776585, lng: 18.1909507 },
    { lat: 48.8776197, lng: 18.1908999 },
    { lat: 48.8776276, lng: 18.1908035 },
    { lat: 48.8775312, lng: 18.1906391 },
    { lat: 48.8774284, lng: 18.1905746 },
    { lat: 48.8772369, lng: 18.1905052 },
    { lat: 48.8771004, lng: 18.1903687 },
    { lat: 48.8769552, lng: 18.190352 },
    { lat: 48.8766845, lng: 18.1904347 },
    { lat: 48.8765307, lng: 18.1903872 },
    { lat: 48.876359, lng: 18.1901679 },
    { lat: 48.8763194, lng: 18.1899322 },
    { lat: 48.8762818, lng: 18.1898659 },
    { lat: 48.8762043, lng: 18.1898704 },
    { lat: 48.8761222, lng: 18.189816 },
    { lat: 48.8760469, lng: 18.1897449 },
    { lat: 48.8760186, lng: 18.1897429 },
    { lat: 48.8759817, lng: 18.1898277 },
    { lat: 48.8757059, lng: 18.1897401 },
    { lat: 48.8756505, lng: 18.1897603 },
    { lat: 48.875584, lng: 18.18992 },
    { lat: 48.8755704, lng: 18.1900727 },
    { lat: 48.8754352, lng: 18.1900991 },
    { lat: 48.8753227, lng: 18.1900835 },
    { lat: 48.8752, lng: 18.1901284 },
    { lat: 48.8751524, lng: 18.1901823 },
    { lat: 48.8751402, lng: 18.1902449 },
    { lat: 48.8751934, lng: 18.1903476 },
    { lat: 48.8750546, lng: 18.1903856 },
    { lat: 48.8748993, lng: 18.190353 },
    { lat: 48.8748507, lng: 18.1904326 },
    { lat: 48.874867, lng: 18.1905183 },
    { lat: 48.874924, lng: 18.1905688 },
    { lat: 48.8749252, lng: 18.1906264 },
    { lat: 48.874871, lng: 18.1907077 },
    { lat: 48.8748303, lng: 18.1907242 },
    { lat: 48.874713, lng: 18.1906461 },
    { lat: 48.8746679, lng: 18.190432 },
    { lat: 48.8746746, lng: 18.1902938 },
    { lat: 48.8746561, lng: 18.1902633 },
    { lat: 48.8745134, lng: 18.1902376 },
    { lat: 48.8744307, lng: 18.1901521 },
    { lat: 48.8744297, lng: 18.1901351 },
    { lat: 48.8746825, lng: 18.1898597 },
    { lat: 48.8746197, lng: 18.1898479 },
    { lat: 48.8744238, lng: 18.1900104 },
    { lat: 48.8743906, lng: 18.1899642 },
    { lat: 48.8743601, lng: 18.1899227 },
    { lat: 48.874055, lng: 18.1895063 },
    { lat: 48.874049, lng: 18.1894996 },
    { lat: 48.874003, lng: 18.189444 },
    { lat: 48.8739036, lng: 18.1893234 },
    { lat: 48.8737622, lng: 18.1891786 },
    { lat: 48.8736733, lng: 18.1890849 },
    { lat: 48.8735138, lng: 18.1889798 },
    { lat: 48.8730686, lng: 18.1887744 },
    { lat: 48.8726245, lng: 18.188605 },
    { lat: 48.8724394, lng: 18.1885646 },
    { lat: 48.8722665, lng: 18.188482 },
    { lat: 48.872119, lng: 18.1884502 },
    { lat: 48.8719672, lng: 18.1884785 },
    { lat: 48.8718251, lng: 18.1885779 },
    { lat: 48.8717067, lng: 18.1886187 },
    { lat: 48.871585, lng: 18.1886616 },
    { lat: 48.8714681, lng: 18.1887469 },
    { lat: 48.8713625, lng: 18.1888555 },
    { lat: 48.8712938, lng: 18.1889567 },
    { lat: 48.8711689, lng: 18.1890588 },
    { lat: 48.8710335, lng: 18.1891179 },
    { lat: 48.8708219, lng: 18.1892244 },
    { lat: 48.8706404, lng: 18.1892888 },
    { lat: 48.8704503, lng: 18.1893171 },
    { lat: 48.8702776, lng: 18.1892955 },
    { lat: 48.8701252, lng: 18.1892561 },
    { lat: 48.870052, lng: 18.1892371 },
    { lat: 48.8700395, lng: 18.1892373 },
    { lat: 48.8698477, lng: 18.1892375 },
    { lat: 48.8697259, lng: 18.1892572 },
    { lat: 48.8696811, lng: 18.1892642 },
    { lat: 48.869624, lng: 18.1892732 },
    { lat: 48.8694498, lng: 18.1893241 },
    { lat: 48.8693898, lng: 18.1893417 },
    { lat: 48.8693621, lng: 18.1893497 },
    { lat: 48.8691372, lng: 18.1893442 },
    { lat: 48.8690001, lng: 18.1893125 },
    { lat: 48.8688435, lng: 18.1892787 },
    { lat: 48.8686486, lng: 18.1892241 },
    { lat: 48.868451, lng: 18.1891839 },
    { lat: 48.8682834, lng: 18.189173 },
    { lat: 48.8678921, lng: 18.1891777 },
    { lat: 48.8677961, lng: 18.1891778 },
    { lat: 48.8677408, lng: 18.1891783 },
    { lat: 48.8676882, lng: 18.1891835 },
    { lat: 48.8675983, lng: 18.1891921 },
    { lat: 48.8675111, lng: 18.1892051 },
    { lat: 48.8675078, lng: 18.1892055 },
    { lat: 48.8673386, lng: 18.1892278 },
    { lat: 48.8673119, lng: 18.1892321 },
    { lat: 48.8672784, lng: 18.1892376 },
    { lat: 48.8670893, lng: 18.1892468 },
    { lat: 48.8669677, lng: 18.1892538 },
    { lat: 48.8669047, lng: 18.1892554 },
    { lat: 48.8668649, lng: 18.1892502 },
    { lat: 48.8668147, lng: 18.1892411 },
    { lat: 48.8665405, lng: 18.1892009 },
    { lat: 48.8665088, lng: 18.1891965 },
    { lat: 48.8664609, lng: 18.1891903 },
    { lat: 48.8664336, lng: 18.1891849 },
    { lat: 48.866388, lng: 18.1891777 },
    { lat: 48.8663549, lng: 18.1891727 },
    { lat: 48.8661852, lng: 18.1891446 },
    { lat: 48.8660768, lng: 18.1891238 },
    { lat: 48.8657005, lng: 18.1890551 },
    { lat: 48.8654934, lng: 18.1890058 },
    { lat: 48.865442, lng: 18.1889764 },
    { lat: 48.8650533, lng: 18.1888657 },
    { lat: 48.8646633, lng: 18.1887566 },
    { lat: 48.8645582, lng: 18.1887339 },
    { lat: 48.8644383, lng: 18.1887673 },
    { lat: 48.8638868, lng: 18.1890293 },
    { lat: 48.8638365, lng: 18.1890377 },
    { lat: 48.863571, lng: 18.1891703 },
    { lat: 48.8633251, lng: 18.1892912 },
    { lat: 48.8631327, lng: 18.1893775 },
    { lat: 48.8629907, lng: 18.1894207 },
    { lat: 48.8628431, lng: 18.1894244 },
    { lat: 48.8626687, lng: 18.1893936 },
    { lat: 48.8626297, lng: 18.1893812 },
    { lat: 48.8625519, lng: 18.1893653 },
    { lat: 48.862699, lng: 18.1890359 },
    { lat: 48.862821, lng: 18.1889417 },
    { lat: 48.8629513, lng: 18.1885747 },
    { lat: 48.8630024, lng: 18.188521 },
    { lat: 48.8630487, lng: 18.1885041 },
    { lat: 48.8630678, lng: 18.1883837 },
    { lat: 48.8630503, lng: 18.1883753 },
    { lat: 48.8632099, lng: 18.1880513 },
    { lat: 48.8633291, lng: 18.1874543 },
    { lat: 48.8629749, lng: 18.1874172 },
    { lat: 48.8625757, lng: 18.1873011 },
    { lat: 48.8623538, lng: 18.1873286 },
    { lat: 48.8621984, lng: 18.1874516 },
    { lat: 48.8618728, lng: 18.1878343 },
    { lat: 48.8616067, lng: 18.187935 },
    { lat: 48.8610318, lng: 18.1880822 },
    { lat: 48.8607394, lng: 18.1881208 },
    { lat: 48.8605593, lng: 18.1881306 },
    { lat: 48.8599952, lng: 18.1880538 },
    { lat: 48.8596297, lng: 18.1879917 },
    { lat: 48.8590009, lng: 18.1877941 },
    { lat: 48.858469, lng: 18.1876066 },
    { lat: 48.858401, lng: 18.1875844 },
    { lat: 48.8582776, lng: 18.1875384 },
    { lat: 48.8575587, lng: 18.1872004 },
    { lat: 48.8570197, lng: 18.1868365 },
    { lat: 48.8569894, lng: 18.1868183 },
    { lat: 48.856974, lng: 18.186806 },
    { lat: 48.856947, lng: 18.1867891 },
    { lat: 48.856905, lng: 18.186762 },
    { lat: 48.8566168, lng: 18.1878361 },
    { lat: 48.8562599, lng: 18.1873406 },
    { lat: 48.8559196, lng: 18.1868756 },
    { lat: 48.8558846, lng: 18.1869534 },
    { lat: 48.855868, lng: 18.1869817 },
    { lat: 48.8558372, lng: 18.1869148 },
    { lat: 48.8556761, lng: 18.1867609 },
    { lat: 48.8555115, lng: 18.1866281 },
    { lat: 48.8553821, lng: 18.1864965 },
    { lat: 48.8552844, lng: 18.1864051 },
    { lat: 48.8552025, lng: 18.186354 },
    { lat: 48.8550427, lng: 18.1862274 },
    { lat: 48.8549205, lng: 18.1861622 },
    { lat: 48.8548077, lng: 18.1860642 },
    { lat: 48.8547557, lng: 18.1860643 },
    { lat: 48.8545929, lng: 18.1859804 },
    { lat: 48.8543741, lng: 18.1859212 },
    { lat: 48.8543381, lng: 18.1859241 },
    { lat: 48.8542683, lng: 18.1859645 },
    { lat: 48.8542093, lng: 18.1859587 },
    { lat: 48.854144, lng: 18.1859411 },
    { lat: 48.854052, lng: 18.1859329 },
    { lat: 48.8539833, lng: 18.1858566 },
    { lat: 48.8538966, lng: 18.1858581 },
    { lat: 48.853746, lng: 18.1858901 },
    { lat: 48.8536868, lng: 18.1858026 },
    { lat: 48.8536247, lng: 18.1858406 },
    { lat: 48.8535805, lng: 18.1858378 },
    { lat: 48.853378, lng: 18.1858076 },
    { lat: 48.8532329, lng: 18.1857748 },
    { lat: 48.8531829, lng: 18.1857534 },
    { lat: 48.8530933, lng: 18.1857448 },
    { lat: 48.8530224, lng: 18.1857087 },
    { lat: 48.8529323, lng: 18.1856708 },
    { lat: 48.8528754, lng: 18.1856647 },
    { lat: 48.8528799, lng: 18.1856246 },
    { lat: 48.8530576, lng: 18.1839852 },
    { lat: 48.8530722, lng: 18.1838435 },
    { lat: 48.8530343, lng: 18.1838352 },
    { lat: 48.8530071, lng: 18.1838299 },
    { lat: 48.8529751, lng: 18.1838255 },
    { lat: 48.8529502, lng: 18.1838238 },
    { lat: 48.8529182, lng: 18.1838176 },
    { lat: 48.8528226, lng: 18.1838009 },
    { lat: 48.8527747, lng: 18.183795 },
    { lat: 48.8527453, lng: 18.1837921 },
    { lat: 48.8526683, lng: 18.1837854 },
    { lat: 48.852557, lng: 18.1837757 },
    { lat: 48.8524177, lng: 18.1837665 },
    { lat: 48.8523122, lng: 18.1837006 },
    { lat: 48.8522423, lng: 18.18366 },
    { lat: 48.8522141, lng: 18.1836218 },
    { lat: 48.8521237, lng: 18.1835219 },
    { lat: 48.8520675, lng: 18.1834666 },
    { lat: 48.8520315, lng: 18.183428 },
    { lat: 48.851986, lng: 18.1833817 },
    { lat: 48.8519454, lng: 18.183324 },
    { lat: 48.8519763, lng: 18.1832748 },
    { lat: 48.8520119, lng: 18.1832221 },
    { lat: 48.8520303, lng: 18.1831932 },
    { lat: 48.8521274, lng: 18.183113 },
    { lat: 48.8523108, lng: 18.1828487 },
    { lat: 48.8524108, lng: 18.1827455 },
    { lat: 48.8524766, lng: 18.1826931 },
    { lat: 48.8525542, lng: 18.1826291 },
    { lat: 48.8527626, lng: 18.1824662 },
    { lat: 48.8529343, lng: 18.1822937 },
    { lat: 48.8531531, lng: 18.182072 },
    { lat: 48.8533985, lng: 18.1818293 },
    { lat: 48.8542365, lng: 18.18086 },
    { lat: 48.8542392, lng: 18.1808077 },
    { lat: 48.8542327, lng: 18.1807566 },
    { lat: 48.8542066, lng: 18.1807511 },
    { lat: 48.854154, lng: 18.1806839 },
    { lat: 48.8540356, lng: 18.180663 },
    { lat: 48.8539291, lng: 18.1806738 },
    { lat: 48.8539231, lng: 18.1806313 },
    { lat: 48.854019, lng: 18.1804742 },
    { lat: 48.8542637, lng: 18.1801573 },
    { lat: 48.8545954, lng: 18.1795678 },
    { lat: 48.8548675, lng: 18.1792348 },
    { lat: 48.8554158, lng: 18.1789986 },
    { lat: 48.8554335, lng: 18.1791507 },
    { lat: 48.8554354, lng: 18.1791834 },
    { lat: 48.8555514, lng: 18.1791401 },
    { lat: 48.8556464, lng: 18.1792995 },
    { lat: 48.8557103, lng: 18.1793341 },
    { lat: 48.8557991, lng: 18.1793277 },
    { lat: 48.8558513, lng: 18.179352 },
    { lat: 48.8559368, lng: 18.1793457 },
    { lat: 48.8559634, lng: 18.1793558 },
    { lat: 48.8560591, lng: 18.1794335 },
    { lat: 48.8561445, lng: 18.179467 },
    { lat: 48.8562068, lng: 18.1794312 },
    { lat: 48.8563199, lng: 18.179494 },
    { lat: 48.8563877, lng: 18.1794936 },
    { lat: 48.8564514, lng: 18.179582 },
    { lat: 48.8565467, lng: 18.1796683 },
    { lat: 48.8566976, lng: 18.1797419 },
    { lat: 48.8568188, lng: 18.1796914 },
    { lat: 48.8568847, lng: 18.1796043 },
    { lat: 48.8570563, lng: 18.1796231 },
    { lat: 48.8572097, lng: 18.1797204 },
    { lat: 48.8572833, lng: 18.1796483 },
    { lat: 48.8573685, lng: 18.1796371 },
    { lat: 48.857449, lng: 18.1795078 },
    { lat: 48.8575783, lng: 18.1794771 },
    { lat: 48.8576204, lng: 18.1795633 },
    { lat: 48.8576435, lng: 18.1795706 },
    { lat: 48.85776, lng: 18.1794961 },
    { lat: 48.8581256, lng: 18.179517 },
    { lat: 48.8581768, lng: 18.1794858 },
    { lat: 48.8584109, lng: 18.1793173 },
    { lat: 48.8584969, lng: 18.1791844 },
    { lat: 48.8586038, lng: 18.179099 },
    { lat: 48.8588209, lng: 18.1787385 },
    { lat: 48.8589499, lng: 18.1785684 },
    { lat: 48.8591211, lng: 18.1784608 },
    { lat: 48.8591738, lng: 18.1784191 },
    { lat: 48.8592555, lng: 18.1783266 },
    { lat: 48.859291, lng: 18.1782578 },
    { lat: 48.8593227, lng: 18.1782206 },
    { lat: 48.8593478, lng: 18.1782054 },
    { lat: 48.8594037, lng: 18.1781981 },
    { lat: 48.8594068, lng: 18.1781697 },
    { lat: 48.8594076, lng: 18.1781259 },
    { lat: 48.8595826, lng: 18.1779139 },
    { lat: 48.8598253, lng: 18.1777184 },
    { lat: 48.8598748, lng: 18.1776782 },
    { lat: 48.8600531, lng: 18.17746 },
    { lat: 48.860097, lng: 18.177361 },
    { lat: 48.8603254, lng: 18.1770764 },
    { lat: 48.8604468, lng: 18.1768732 },
    { lat: 48.8607249, lng: 18.1758431 },
    { lat: 48.8606812, lng: 18.174964 },
    { lat: 48.8607124, lng: 18.1741919 },
    { lat: 48.8607843, lng: 18.1738767 },
    { lat: 48.8609974, lng: 18.1722641 },
    { lat: 48.8611478, lng: 18.1714253 },
    { lat: 48.8614324, lng: 18.1701967 },
    { lat: 48.8611604, lng: 18.1697855 },
    { lat: 48.8609628, lng: 18.1695074 },
    { lat: 48.8607745, lng: 18.1690012 },
    { lat: 48.8606767, lng: 18.1685966 },
    { lat: 48.8606495, lng: 18.1683217 },
    { lat: 48.8606451, lng: 18.1680292 },
    { lat: 48.8606598, lng: 18.1678362 },
    { lat: 48.8606948, lng: 18.167599 },
    { lat: 48.8607607, lng: 18.1672767 },
    { lat: 48.8609031, lng: 18.1668672 },
    { lat: 48.8613618, lng: 18.16537 },
    { lat: 48.8613388, lng: 18.1653451 },
    { lat: 48.8609064, lng: 18.1647928 },
    { lat: 48.8610547, lng: 18.1634486 },
    { lat: 48.8603444, lng: 18.1625793 },
    { lat: 48.8598694, lng: 18.1624663 },
    { lat: 48.8594289, lng: 18.1628669 },
    { lat: 48.8587673, lng: 18.1628438 },
    { lat: 48.8587144, lng: 18.1628444 },
    { lat: 48.859041, lng: 18.1623621 },
    { lat: 48.8593612, lng: 18.1622018 },
    { lat: 48.8596643, lng: 18.1620383 },
    { lat: 48.8599801, lng: 18.1615281 },
    { lat: 48.8602282, lng: 18.1615454 },
    { lat: 48.860059, lng: 18.1603497 },
    { lat: 48.8598745, lng: 18.1592266 },
    { lat: 48.8598468, lng: 18.1590411 },
    { lat: 48.8598416, lng: 18.1589887 },
    { lat: 48.8598299, lng: 18.1589046 },
    { lat: 48.8598277, lng: 18.1588863 },
    { lat: 48.859826, lng: 18.1588561 },
    { lat: 48.8600123, lng: 18.1588188 },
    { lat: 48.8600296, lng: 18.1588247 },
    { lat: 48.8601305, lng: 18.1588727 },
    { lat: 48.8601776, lng: 18.1589051 },
    { lat: 48.8601948, lng: 18.1589136 },
    { lat: 48.8602647, lng: 18.1589108 },
    { lat: 48.8604585, lng: 18.1589263 },
    { lat: 48.8606011, lng: 18.1589146 },
    { lat: 48.8607274, lng: 18.1589107 },
    { lat: 48.8608062, lng: 18.1588874 },
    { lat: 48.8609862, lng: 18.1588585 },
    { lat: 48.8611136, lng: 18.1588377 },
    { lat: 48.8611536, lng: 18.1588675 },
    { lat: 48.861292, lng: 18.1588187 },
    { lat: 48.8613236, lng: 18.1588374 },
    { lat: 48.8614549, lng: 18.1587691 },
    { lat: 48.8615267, lng: 18.1587435 },
    { lat: 48.8616467, lng: 18.1586716 },
    { lat: 48.86169, lng: 18.1586602 },
    { lat: 48.8617712, lng: 18.1585996 },
    { lat: 48.8618036, lng: 18.1585347 },
    { lat: 48.8618842, lng: 18.158366 },
    { lat: 48.8619288, lng: 18.1582576 },
    { lat: 48.8620189, lng: 18.1581392 },
    { lat: 48.8621229, lng: 18.1580068 },
    { lat: 48.8621392, lng: 18.1579749 },
    { lat: 48.8621706, lng: 18.1579123 },
    { lat: 48.8622922, lng: 18.1577532 },
    { lat: 48.8623243, lng: 18.1577235 },
    { lat: 48.8623435, lng: 18.157703 },
    { lat: 48.8624038, lng: 18.1576341 },
    { lat: 48.8625014, lng: 18.1575272 },
    { lat: 48.862577, lng: 18.1574473 },
    { lat: 48.8626032, lng: 18.1574094 },
    { lat: 48.8626629, lng: 18.1573306 },
    { lat: 48.8627343, lng: 18.157277 },
    { lat: 48.8628961, lng: 18.1570885 },
    { lat: 48.862994, lng: 18.1570078 },
    { lat: 48.8630778, lng: 18.1568935 },
    { lat: 48.8632028, lng: 18.1566952 },
    { lat: 48.8632403, lng: 18.15662 },
    { lat: 48.8632551, lng: 18.156565 },
    { lat: 48.8632565, lng: 18.1562791 },
    { lat: 48.8633721, lng: 18.1557993 },
    { lat: 48.8638239, lng: 18.1548473 },
    { lat: 48.8641187, lng: 18.1542279 },
    { lat: 48.8642983, lng: 18.154074 },
    { lat: 48.8643412, lng: 18.1540723 },
    { lat: 48.8645351, lng: 18.1542856 },
    { lat: 48.8651616, lng: 18.1540333 },
    { lat: 48.8655953, lng: 18.1538073 },
    { lat: 48.8663227, lng: 18.1532312 },
    { lat: 48.8665611, lng: 18.1529824 },
    { lat: 48.8670846, lng: 18.1525319 },
    { lat: 48.8674777, lng: 18.152403 },
    { lat: 48.8677563, lng: 18.152399 },
    { lat: 48.8678673, lng: 18.1520151 },
    { lat: 48.8682891, lng: 18.1519164 },
    { lat: 48.8688967, lng: 18.1518448 },
    { lat: 48.8692758, lng: 18.1518457 },
    { lat: 48.8695302, lng: 18.1516359 },
    { lat: 48.8697407, lng: 18.1515306 },
    { lat: 48.870018, lng: 18.1513909 },
    { lat: 48.8699372, lng: 18.1507334 },
    { lat: 48.8697775, lng: 18.1495897 },
    { lat: 48.8694021, lng: 18.1478978 },
    { lat: 48.8683624, lng: 18.1470513 },
    { lat: 48.8680208, lng: 18.1469009 },
    { lat: 48.8673425, lng: 18.1456895 },
    { lat: 48.8667174, lng: 18.1448328 },
    { lat: 48.8665496, lng: 18.1445432 },
    { lat: 48.866277, lng: 18.1438497 },
    { lat: 48.865437, lng: 18.143709 },
    { lat: 48.8652032, lng: 18.1434691 },
    { lat: 48.8650797, lng: 18.1436346 },
    { lat: 48.8648084, lng: 18.1439606 },
    { lat: 48.8645325, lng: 18.1442442 },
    { lat: 48.8641055, lng: 18.144683 },
    { lat: 48.8635423, lng: 18.1452475 },
    { lat: 48.8632123, lng: 18.1452985 },
    { lat: 48.8630366, lng: 18.1453236 },
    { lat: 48.8625044, lng: 18.1455832 },
    { lat: 48.8621029, lng: 18.145882 },
    { lat: 48.8616315, lng: 18.1462962 },
    { lat: 48.861094, lng: 18.1460343 },
    { lat: 48.8608305, lng: 18.1461428 },
    { lat: 48.8605, lng: 18.1461075 },
    { lat: 48.8603956, lng: 18.1460977 },
    { lat: 48.8603383, lng: 18.1461408 },
    { lat: 48.8598186, lng: 18.1465599 },
    { lat: 48.8597569, lng: 18.1466248 },
    { lat: 48.8596253, lng: 18.1467501 },
    { lat: 48.8595986, lng: 18.1467748 },
    { lat: 48.8592901, lng: 18.1470788 },
    { lat: 48.8589308, lng: 18.1473641 },
    { lat: 48.8585438, lng: 18.147307 },
    { lat: 48.8580623, lng: 18.1472326 },
    { lat: 48.8576847, lng: 18.1473762 },
    { lat: 48.8574332, lng: 18.147243 },
    { lat: 48.8572834, lng: 18.1472869 },
    { lat: 48.8572303, lng: 18.1473051 },
    { lat: 48.8570994, lng: 18.1473475 },
    { lat: 48.8564815, lng: 18.1479191 },
    { lat: 48.8563384, lng: 18.1481075 },
    { lat: 48.8559221, lng: 18.1486861 },
    { lat: 48.855463, lng: 18.1490029 },
    { lat: 48.8547674, lng: 18.149365 },
    { lat: 48.8538187, lng: 18.1501507 },
    { lat: 48.8536573, lng: 18.1506953 },
    { lat: 48.8536164, lng: 18.1512196 },
    { lat: 48.8534729, lng: 18.1515891 },
    { lat: 48.8532906, lng: 18.1518505 },
    { lat: 48.8532666, lng: 18.1518852 },
    { lat: 48.8531679, lng: 18.1520323 },
    { lat: 48.8527172, lng: 18.1515356 },
    { lat: 48.8522086, lng: 18.1511316 },
    { lat: 48.8517994, lng: 18.1511389 },
    { lat: 48.8512954, lng: 18.1509518 },
    { lat: 48.8505638, lng: 18.1513742 },
    { lat: 48.85017, lng: 18.1519216 },
    { lat: 48.8499959, lng: 18.152149 },
    { lat: 48.849643, lng: 18.1523688 },
    { lat: 48.8493612, lng: 18.1525499 },
    { lat: 48.8490076, lng: 18.1529533 },
    { lat: 48.848616, lng: 18.1534396 },
    { lat: 48.8484042, lng: 18.1541326 },
    { lat: 48.8480239, lng: 18.1545217 },
    { lat: 48.8477089, lng: 18.1549296 },
    { lat: 48.8471786, lng: 18.1558287 },
    { lat: 48.847222, lng: 18.1566008 },
    { lat: 48.84709, lng: 18.1571675 },
    { lat: 48.8466675, lng: 18.1579587 },
    { lat: 48.8465915, lng: 18.1580315 },
    { lat: 48.8465668, lng: 18.1580541 },
    { lat: 48.8457679, lng: 18.158797 },
    { lat: 48.8454268, lng: 18.1590457 },
    { lat: 48.845015, lng: 18.1591053 },
    { lat: 48.8444239, lng: 18.1593058 },
    { lat: 48.8440823, lng: 18.1596022 },
    { lat: 48.8437987, lng: 18.1596545 },
    { lat: 48.8434741, lng: 18.1596234 },
    { lat: 48.8430098, lng: 18.159576 },
    { lat: 48.8423351, lng: 18.1595016 },
    { lat: 48.8419286, lng: 18.159476 },
    { lat: 48.8414515, lng: 18.1593633 },
    { lat: 48.840931, lng: 18.1592388 },
    { lat: 48.8404571, lng: 18.1591823 },
    { lat: 48.8399978, lng: 18.1590662 },
    { lat: 48.8396625, lng: 18.1589838 },
    { lat: 48.8393081, lng: 18.1589441 },
    { lat: 48.8389322, lng: 18.1589008 },
    { lat: 48.8382893, lng: 18.1588108 },
    { lat: 48.8380112, lng: 18.1589784 },
    { lat: 48.8376005, lng: 18.1591553 },
    { lat: 48.8375961, lng: 18.1591573 },
    { lat: 48.8375377, lng: 18.1591819 },
    { lat: 48.837486, lng: 18.1592038 },
    { lat: 48.8373738, lng: 18.1592549 },
    { lat: 48.8369257, lng: 18.1596234 },
    { lat: 48.8364711, lng: 18.1600149 },
    { lat: 48.8360882, lng: 18.1603046 },
    { lat: 48.8350375, lng: 18.1604588 },
    { lat: 48.83344, lng: 18.1608925 },
    { lat: 48.8324939, lng: 18.1614538 },
    { lat: 48.8322239, lng: 18.1616425 },
    { lat: 48.8320743, lng: 18.1617472 },
    { lat: 48.8319269, lng: 18.1618519 },
    { lat: 48.8318302, lng: 18.1619174 },
    { lat: 48.8311266, lng: 18.1624157 },
    { lat: 48.8309431, lng: 18.1625431 },
    { lat: 48.8308266, lng: 18.1626552 },
    { lat: 48.8307197, lng: 18.1627568 },
    { lat: 48.8306075, lng: 18.1628658 },
    { lat: 48.8304767, lng: 18.1629466 },
    { lat: 48.8304408, lng: 18.1629681 },
    { lat: 48.8301154, lng: 18.1630008 },
    { lat: 48.8299337, lng: 18.1630185 },
    { lat: 48.8299178, lng: 18.1630184 },
    { lat: 48.8297103, lng: 18.1630982 },
    { lat: 48.8296117, lng: 18.1631714 },
    { lat: 48.8294581, lng: 18.1632841 },
    { lat: 48.8292644, lng: 18.1634308 },
    { lat: 48.8289742, lng: 18.1636222 },
    { lat: 48.8288482, lng: 18.1637041 },
    { lat: 48.8287188, lng: 18.1637903 },
    { lat: 48.8285243, lng: 18.163882 },
    { lat: 48.8284629, lng: 18.1639693 },
    { lat: 48.8284721, lng: 18.1639935 },
    { lat: 48.8283318, lng: 18.1640668 },
    { lat: 48.8277926, lng: 18.1642825 },
    { lat: 48.8273613, lng: 18.1644381 },
    { lat: 48.8265966, lng: 18.1647381 },
    { lat: 48.8263518, lng: 18.164835 },
    { lat: 48.8260616, lng: 18.1648909 },
    { lat: 48.8258058, lng: 18.1649158 },
    { lat: 48.8255392, lng: 18.1649107 },
    { lat: 48.8248427, lng: 18.1647697 },
    { lat: 48.8242026, lng: 18.1646671 },
    { lat: 48.8237829, lng: 18.164646 },
    { lat: 48.823504, lng: 18.1645884 },
    { lat: 48.8233149, lng: 18.1645762 },
    { lat: 48.8230198, lng: 18.1646264 },
    { lat: 48.8228731, lng: 18.1646823 },
    { lat: 48.8226446, lng: 18.1648107 },
    { lat: 48.822303, lng: 18.16505 },
    { lat: 48.8219364, lng: 18.1653653 },
    { lat: 48.8213642, lng: 18.165952 },
    { lat: 48.8207785, lng: 18.1668123 },
    { lat: 48.8204702, lng: 18.1671779 },
    { lat: 48.8200739, lng: 18.1677238 },
    { lat: 48.819825, lng: 18.1680031 },
    { lat: 48.8192784, lng: 18.1684646 },
    { lat: 48.8188386, lng: 18.1689976 },
    { lat: 48.8185571, lng: 18.1692826 },
    { lat: 48.8182863, lng: 18.1695408 },
    { lat: 48.8172574, lng: 18.1702655 },
    { lat: 48.8168798, lng: 18.170492 },
    { lat: 48.8161326, lng: 18.1706019 },
    { lat: 48.815685, lng: 18.1708852 },
    { lat: 48.8150476, lng: 18.1714743 },
    { lat: 48.8149532, lng: 18.171523 },
    { lat: 48.8148553, lng: 18.1717403 },
    { lat: 48.8146494, lng: 18.1718879 },
    { lat: 48.8138078, lng: 18.1723432 },
    { lat: 48.8136532, lng: 18.1723404 },
    { lat: 48.8132642, lng: 18.1721512 },
    { lat: 48.8129494, lng: 18.1720754 },
    { lat: 48.8127186, lng: 18.1720833 },
    { lat: 48.8123461, lng: 18.1722385 },
    { lat: 48.8117592, lng: 18.1725864 },
    { lat: 48.810992, lng: 18.172744 },
    { lat: 48.8108305, lng: 18.1728233 },
    { lat: 48.8099514, lng: 18.1733692 },
    { lat: 48.8099469, lng: 18.1733483 },
    { lat: 48.8099263, lng: 18.1733241 },
    { lat: 48.8098371, lng: 18.172854 },
    { lat: 48.8097641, lng: 18.1724709 },
    { lat: 48.8097036, lng: 18.1721635 },
    { lat: 48.8094465, lng: 18.1709724 },
    { lat: 48.8091473, lng: 18.1695827 },
    { lat: 48.8088922, lng: 18.169697 },
    { lat: 48.8086468, lng: 18.1698054 },
    { lat: 48.808408, lng: 18.1699114 },
    { lat: 48.8081603, lng: 18.1700172 },
    { lat: 48.8080251, lng: 18.1700791 },
    { lat: 48.8078895, lng: 18.1700566 },
    { lat: 48.8075601, lng: 18.1699834 },
    { lat: 48.807562, lng: 18.1690764 },
    { lat: 48.8075609, lng: 18.1690378 },
    { lat: 48.8074405, lng: 18.1691236 },
    { lat: 48.8073707, lng: 18.1691629 },
    { lat: 48.8071875, lng: 18.1691563 },
    { lat: 48.8069557, lng: 18.1691096 },
    { lat: 48.8068428, lng: 18.1691654 },
    { lat: 48.806609, lng: 18.1692027 },
    { lat: 48.8060686, lng: 18.1692434 },
    { lat: 48.8059687, lng: 18.1691918 },
    { lat: 48.8058214, lng: 18.1692417 },
    { lat: 48.8057708, lng: 18.1692044 },
    { lat: 48.8056732, lng: 18.1692538 },
    { lat: 48.8055727, lng: 18.1692538 },
    { lat: 48.8053658, lng: 18.1692457 },
    { lat: 48.8051858, lng: 18.1691965 },
    { lat: 48.8051494, lng: 18.1691913 },
    { lat: 48.8050419, lng: 18.1691851 },
    { lat: 48.8048149, lng: 18.1691657 },
    { lat: 48.8047667, lng: 18.1692115 },
    { lat: 48.8046384, lng: 18.1691758 },
    { lat: 48.804499, lng: 18.1691649 },
    { lat: 48.8040288, lng: 18.16913 },
    { lat: 48.8035777, lng: 18.1691188 },
    { lat: 48.8031445, lng: 18.1690823 },
    { lat: 48.802783, lng: 18.1690549 },
    { lat: 48.802622, lng: 18.169039 },
    { lat: 48.8024831, lng: 18.1690373 },
    { lat: 48.8021712, lng: 18.1690331 },
    { lat: 48.8020433, lng: 18.1690253 },
    { lat: 48.8019415, lng: 18.1690599 },
    { lat: 48.8018008, lng: 18.1691833 },
    { lat: 48.8017256, lng: 18.1692491 },
    { lat: 48.8015432, lng: 18.1693541 },
    { lat: 48.8015083, lng: 18.1693752 },
    { lat: 48.8010113, lng: 18.1697942 },
  ],
  Neporadza: [
    { lat: 48.7996246, lng: 18.1451802 },
    { lat: 48.7998893, lng: 18.1457175 },
    { lat: 48.7999888, lng: 18.1460146 },
    { lat: 48.8001536, lng: 18.1465207 },
    { lat: 48.8001875, lng: 18.1466369 },
    { lat: 48.8002923, lng: 18.1469476 },
    { lat: 48.8004443, lng: 18.1473509 },
    { lat: 48.8007846, lng: 18.147871 },
    { lat: 48.8011317, lng: 18.148368 },
    { lat: 48.8012445, lng: 18.1485435 },
    { lat: 48.8013903, lng: 18.1487998 },
    { lat: 48.8015788, lng: 18.1491514 },
    { lat: 48.8019847, lng: 18.1498475 },
    { lat: 48.8021685, lng: 18.1501764 },
    { lat: 48.8023623, lng: 18.1505213 },
    { lat: 48.8023737, lng: 18.1505427 },
    { lat: 48.8023801, lng: 18.1505554 },
    { lat: 48.8024004, lng: 18.150596 },
    { lat: 48.8024126, lng: 18.1506112 },
    { lat: 48.8024207, lng: 18.1506366 },
    { lat: 48.8026903, lng: 18.1512589 },
    { lat: 48.8027186, lng: 18.1512984 },
    { lat: 48.802748, lng: 18.1513364 },
    { lat: 48.802966, lng: 18.1516909 },
    { lat: 48.8031356, lng: 18.1520479 },
    { lat: 48.8033399, lng: 18.1524604 },
    { lat: 48.8035652, lng: 18.1528633 },
    { lat: 48.8036425, lng: 18.1530278 },
    { lat: 48.8038337, lng: 18.1534066 },
    { lat: 48.8040106, lng: 18.1536964 },
    { lat: 48.8042822, lng: 18.1542181 },
    { lat: 48.8044199, lng: 18.1544519 },
    { lat: 48.8046742, lng: 18.1550221 },
    { lat: 48.8046999, lng: 18.1550585 },
    { lat: 48.8050174, lng: 18.155494 },
    { lat: 48.805409, lng: 18.1560027 },
    { lat: 48.8057984, lng: 18.1564552 },
    { lat: 48.805949, lng: 18.1565981 },
    { lat: 48.806069, lng: 18.156608 },
    { lat: 48.806063, lng: 18.1570122 },
    { lat: 48.8060708, lng: 18.1571626 },
    { lat: 48.8061741, lng: 18.1577794 },
    { lat: 48.806164, lng: 18.157978 },
    { lat: 48.8061121, lng: 18.1581148 },
    { lat: 48.8060127, lng: 18.1582898 },
    { lat: 48.8057775, lng: 18.1585532 },
    { lat: 48.8054929, lng: 18.1588619 },
    { lat: 48.8052695, lng: 18.1590541 },
    { lat: 48.8050425, lng: 18.1593243 },
    { lat: 48.8050758, lng: 18.159394 },
    { lat: 48.8055363, lng: 18.1603326 },
    { lat: 48.8055401, lng: 18.1603406 },
    { lat: 48.8055451, lng: 18.1603511 },
    { lat: 48.8049064, lng: 18.1608785 },
    { lat: 48.8046583, lng: 18.1611361 },
    { lat: 48.8045468, lng: 18.1614319 },
    { lat: 48.804391, lng: 18.1616816 },
    { lat: 48.8043849, lng: 18.1616746 },
    { lat: 48.8043775, lng: 18.1616659 },
    { lat: 48.8042321, lng: 18.1620392 },
    { lat: 48.8041096, lng: 18.1624391 },
    { lat: 48.8039703, lng: 18.1628982 },
    { lat: 48.8038851, lng: 18.1631264 },
    { lat: 48.8037532, lng: 18.1634374 },
    { lat: 48.8038742, lng: 18.1636366 },
    { lat: 48.8035008, lng: 18.1639323 },
    { lat: 48.8033058, lng: 18.164093 },
    { lat: 48.8031491, lng: 18.164291 },
    { lat: 48.80306, lng: 18.1644467 },
    { lat: 48.8029709, lng: 18.1646861 },
    { lat: 48.8029056, lng: 18.1649027 },
    { lat: 48.8029802, lng: 18.1649813 },
    { lat: 48.8028277, lng: 18.1652724 },
    { lat: 48.8025474, lng: 18.1658125 },
    { lat: 48.8023603, lng: 18.1662449 },
    { lat: 48.8023651, lng: 18.16625 },
    { lat: 48.802371, lng: 18.1662543 },
    { lat: 48.8022672, lng: 18.1667262 },
    { lat: 48.8021633, lng: 18.1671129 },
    { lat: 48.8021578, lng: 18.167138 },
    { lat: 48.8020954, lng: 18.1673667 },
    { lat: 48.801939, lng: 18.1679029 },
    { lat: 48.8017347, lng: 18.1681364 },
    { lat: 48.8015531, lng: 18.168353 },
    { lat: 48.8014206, lng: 18.1685195 },
    { lat: 48.8014367, lng: 18.1685245 },
    { lat: 48.8014844, lng: 18.1685526 },
    { lat: 48.8015183, lng: 18.1685692 },
    { lat: 48.8015937, lng: 18.1686061 },
    { lat: 48.8015586, lng: 18.1686412 },
    { lat: 48.801028, lng: 18.1691652 },
    { lat: 48.8002747, lng: 18.1698808 },
    { lat: 48.8000323, lng: 18.1701184 },
    { lat: 48.799965, lng: 18.1701842 },
    { lat: 48.7998305, lng: 18.1703168 },
    { lat: 48.7995165, lng: 18.1706247 },
    { lat: 48.7994761, lng: 18.1706658 },
    { lat: 48.7994847, lng: 18.17068 },
    { lat: 48.7994796, lng: 18.1707097 },
    { lat: 48.7994756, lng: 18.170736 },
    { lat: 48.799528, lng: 18.1707248 },
    { lat: 48.7995335, lng: 18.1708418 },
    { lat: 48.7995789, lng: 18.1708666 },
    { lat: 48.7995829, lng: 18.1709924 },
    { lat: 48.7996061, lng: 18.1709844 },
    { lat: 48.7997186, lng: 18.1708603 },
    { lat: 48.7996998, lng: 18.1707887 },
    { lat: 48.7997617, lng: 18.1707107 },
    { lat: 48.7997739, lng: 18.1706483 },
    { lat: 48.7997929, lng: 18.170625 },
    { lat: 48.7999321, lng: 18.1707294 },
    { lat: 48.799949, lng: 18.170609 },
    { lat: 48.8000434, lng: 18.1704866 },
    { lat: 48.8001191, lng: 18.1705074 },
    { lat: 48.8001438, lng: 18.1703871 },
    { lat: 48.8002178, lng: 18.1703405 },
    { lat: 48.8010113, lng: 18.1697942 },
    { lat: 48.8015083, lng: 18.1693752 },
    { lat: 48.8015432, lng: 18.1693541 },
    { lat: 48.8017256, lng: 18.1692491 },
    { lat: 48.8018008, lng: 18.1691833 },
    { lat: 48.8019415, lng: 18.1690599 },
    { lat: 48.8020433, lng: 18.1690253 },
    { lat: 48.8021712, lng: 18.1690331 },
    { lat: 48.8024831, lng: 18.1690373 },
    { lat: 48.802622, lng: 18.169039 },
    { lat: 48.802783, lng: 18.1690549 },
    { lat: 48.8031445, lng: 18.1690823 },
    { lat: 48.8035777, lng: 18.1691188 },
    { lat: 48.8040288, lng: 18.16913 },
    { lat: 48.804499, lng: 18.1691649 },
    { lat: 48.8046384, lng: 18.1691758 },
    { lat: 48.8047667, lng: 18.1692115 },
    { lat: 48.8048149, lng: 18.1691657 },
    { lat: 48.8050419, lng: 18.1691851 },
    { lat: 48.8051494, lng: 18.1691913 },
    { lat: 48.8051858, lng: 18.1691965 },
    { lat: 48.8053658, lng: 18.1692457 },
    { lat: 48.8055727, lng: 18.1692538 },
    { lat: 48.8056732, lng: 18.1692538 },
    { lat: 48.8057708, lng: 18.1692044 },
    { lat: 48.8058214, lng: 18.1692417 },
    { lat: 48.8059687, lng: 18.1691918 },
    { lat: 48.8060686, lng: 18.1692434 },
    { lat: 48.806609, lng: 18.1692027 },
    { lat: 48.8068428, lng: 18.1691654 },
    { lat: 48.8069557, lng: 18.1691096 },
    { lat: 48.8071875, lng: 18.1691563 },
    { lat: 48.8073707, lng: 18.1691629 },
    { lat: 48.8074405, lng: 18.1691236 },
    { lat: 48.8075609, lng: 18.1690378 },
    { lat: 48.807562, lng: 18.1690764 },
    { lat: 48.8075601, lng: 18.1699834 },
    { lat: 48.8078895, lng: 18.1700566 },
    { lat: 48.8080251, lng: 18.1700791 },
    { lat: 48.8081603, lng: 18.1700172 },
    { lat: 48.808408, lng: 18.1699114 },
    { lat: 48.8086468, lng: 18.1698054 },
    { lat: 48.8088922, lng: 18.169697 },
    { lat: 48.8091473, lng: 18.1695827 },
    { lat: 48.8094465, lng: 18.1709724 },
    { lat: 48.8097036, lng: 18.1721635 },
    { lat: 48.8097641, lng: 18.1724709 },
    { lat: 48.8098371, lng: 18.172854 },
    { lat: 48.8099263, lng: 18.1733241 },
    { lat: 48.8099469, lng: 18.1733483 },
    { lat: 48.8099514, lng: 18.1733692 },
    { lat: 48.8108305, lng: 18.1728233 },
    { lat: 48.810992, lng: 18.172744 },
    { lat: 48.8117592, lng: 18.1725864 },
    { lat: 48.8123461, lng: 18.1722385 },
    { lat: 48.8127186, lng: 18.1720833 },
    { lat: 48.8129494, lng: 18.1720754 },
    { lat: 48.8132642, lng: 18.1721512 },
    { lat: 48.8136532, lng: 18.1723404 },
    { lat: 48.8138078, lng: 18.1723432 },
    { lat: 48.8146494, lng: 18.1718879 },
    { lat: 48.8148553, lng: 18.1717403 },
    { lat: 48.8149532, lng: 18.171523 },
    { lat: 48.8150476, lng: 18.1714743 },
    { lat: 48.815685, lng: 18.1708852 },
    { lat: 48.8161326, lng: 18.1706019 },
    { lat: 48.8168798, lng: 18.170492 },
    { lat: 48.8172574, lng: 18.1702655 },
    { lat: 48.8182863, lng: 18.1695408 },
    { lat: 48.8185571, lng: 18.1692826 },
    { lat: 48.8188386, lng: 18.1689976 },
    { lat: 48.8192784, lng: 18.1684646 },
    { lat: 48.819825, lng: 18.1680031 },
    { lat: 48.8200739, lng: 18.1677238 },
    { lat: 48.8204702, lng: 18.1671779 },
    { lat: 48.8207785, lng: 18.1668123 },
    { lat: 48.8213642, lng: 18.165952 },
    { lat: 48.8219364, lng: 18.1653653 },
    { lat: 48.822303, lng: 18.16505 },
    { lat: 48.8226446, lng: 18.1648107 },
    { lat: 48.8228731, lng: 18.1646823 },
    { lat: 48.8230198, lng: 18.1646264 },
    { lat: 48.8233149, lng: 18.1645762 },
    { lat: 48.823504, lng: 18.1645884 },
    { lat: 48.8237829, lng: 18.164646 },
    { lat: 48.8242026, lng: 18.1646671 },
    { lat: 48.8248427, lng: 18.1647697 },
    { lat: 48.8255392, lng: 18.1649107 },
    { lat: 48.8258058, lng: 18.1649158 },
    { lat: 48.8260616, lng: 18.1648909 },
    { lat: 48.8263518, lng: 18.164835 },
    { lat: 48.8265966, lng: 18.1647381 },
    { lat: 48.8273613, lng: 18.1644381 },
    { lat: 48.8277926, lng: 18.1642825 },
    { lat: 48.8283318, lng: 18.1640668 },
    { lat: 48.8284721, lng: 18.1639935 },
    { lat: 48.8284629, lng: 18.1639693 },
    { lat: 48.8285243, lng: 18.163882 },
    { lat: 48.8287188, lng: 18.1637903 },
    { lat: 48.8288482, lng: 18.1637041 },
    { lat: 48.8289742, lng: 18.1636222 },
    { lat: 48.8292644, lng: 18.1634308 },
    { lat: 48.8294581, lng: 18.1632841 },
    { lat: 48.8296117, lng: 18.1631714 },
    { lat: 48.8297103, lng: 18.1630982 },
    { lat: 48.8299178, lng: 18.1630184 },
    { lat: 48.8299337, lng: 18.1630185 },
    { lat: 48.8301154, lng: 18.1630008 },
    { lat: 48.8304408, lng: 18.1629681 },
    { lat: 48.8304767, lng: 18.1629466 },
    { lat: 48.8306075, lng: 18.1628658 },
    { lat: 48.8307197, lng: 18.1627568 },
    { lat: 48.8308266, lng: 18.1626552 },
    { lat: 48.8309431, lng: 18.1625431 },
    { lat: 48.8311266, lng: 18.1624157 },
    { lat: 48.8318302, lng: 18.1619174 },
    { lat: 48.8319269, lng: 18.1618519 },
    { lat: 48.8320743, lng: 18.1617472 },
    { lat: 48.8322239, lng: 18.1616425 },
    { lat: 48.8324939, lng: 18.1614538 },
    { lat: 48.83344, lng: 18.1608925 },
    { lat: 48.8350375, lng: 18.1604588 },
    { lat: 48.8360882, lng: 18.1603046 },
    { lat: 48.8364711, lng: 18.1600149 },
    { lat: 48.8369257, lng: 18.1596234 },
    { lat: 48.8373738, lng: 18.1592549 },
    { lat: 48.837486, lng: 18.1592038 },
    { lat: 48.8375377, lng: 18.1591819 },
    { lat: 48.8375961, lng: 18.1591573 },
    { lat: 48.8376005, lng: 18.1591553 },
    { lat: 48.8380112, lng: 18.1589784 },
    { lat: 48.8382893, lng: 18.1588108 },
    { lat: 48.8389322, lng: 18.1589008 },
    { lat: 48.8393081, lng: 18.1589441 },
    { lat: 48.8396625, lng: 18.1589838 },
    { lat: 48.8399978, lng: 18.1590662 },
    { lat: 48.8404571, lng: 18.1591823 },
    { lat: 48.840931, lng: 18.1592388 },
    { lat: 48.8414515, lng: 18.1593633 },
    { lat: 48.8419286, lng: 18.159476 },
    { lat: 48.8423351, lng: 18.1595016 },
    { lat: 48.8430098, lng: 18.159576 },
    { lat: 48.8434741, lng: 18.1596234 },
    { lat: 48.8437987, lng: 18.1596545 },
    { lat: 48.8440823, lng: 18.1596022 },
    { lat: 48.8444239, lng: 18.1593058 },
    { lat: 48.845015, lng: 18.1591053 },
    { lat: 48.8454268, lng: 18.1590457 },
    { lat: 48.8457679, lng: 18.158797 },
    { lat: 48.8465668, lng: 18.1580541 },
    { lat: 48.8465915, lng: 18.1580315 },
    { lat: 48.8466675, lng: 18.1579587 },
    { lat: 48.84709, lng: 18.1571675 },
    { lat: 48.847222, lng: 18.1566008 },
    { lat: 48.8471786, lng: 18.1558287 },
    { lat: 48.8477089, lng: 18.1549296 },
    { lat: 48.8480239, lng: 18.1545217 },
    { lat: 48.8484042, lng: 18.1541326 },
    { lat: 48.848616, lng: 18.1534396 },
    { lat: 48.8490076, lng: 18.1529533 },
    { lat: 48.8493612, lng: 18.1525499 },
    { lat: 48.849643, lng: 18.1523688 },
    { lat: 48.8499959, lng: 18.152149 },
    { lat: 48.85017, lng: 18.1519216 },
    { lat: 48.8505638, lng: 18.1513742 },
    { lat: 48.8512954, lng: 18.1509518 },
    { lat: 48.8517994, lng: 18.1511389 },
    { lat: 48.8522086, lng: 18.1511316 },
    { lat: 48.8527172, lng: 18.1515356 },
    { lat: 48.8531679, lng: 18.1520323 },
    { lat: 48.8532666, lng: 18.1518852 },
    { lat: 48.8532906, lng: 18.1518505 },
    { lat: 48.8534729, lng: 18.1515891 },
    { lat: 48.8536164, lng: 18.1512196 },
    { lat: 48.8536573, lng: 18.1506953 },
    { lat: 48.8538187, lng: 18.1501507 },
    { lat: 48.8547674, lng: 18.149365 },
    { lat: 48.855463, lng: 18.1490029 },
    { lat: 48.8559221, lng: 18.1486861 },
    { lat: 48.8563384, lng: 18.1481075 },
    { lat: 48.8564815, lng: 18.1479191 },
    { lat: 48.8570994, lng: 18.1473475 },
    { lat: 48.8572303, lng: 18.1473051 },
    { lat: 48.8572834, lng: 18.1472869 },
    { lat: 48.8574332, lng: 18.147243 },
    { lat: 48.8576847, lng: 18.1473762 },
    { lat: 48.8580623, lng: 18.1472326 },
    { lat: 48.8585438, lng: 18.147307 },
    { lat: 48.8589308, lng: 18.1473641 },
    { lat: 48.8592901, lng: 18.1470788 },
    { lat: 48.8595986, lng: 18.1467748 },
    { lat: 48.8596253, lng: 18.1467501 },
    { lat: 48.8597569, lng: 18.1466248 },
    { lat: 48.8598186, lng: 18.1465599 },
    { lat: 48.8603383, lng: 18.1461408 },
    { lat: 48.8603956, lng: 18.1460977 },
    { lat: 48.8605, lng: 18.1461075 },
    { lat: 48.8608305, lng: 18.1461428 },
    { lat: 48.861094, lng: 18.1460343 },
    { lat: 48.8616315, lng: 18.1462962 },
    { lat: 48.8621029, lng: 18.145882 },
    { lat: 48.8625044, lng: 18.1455832 },
    { lat: 48.8630366, lng: 18.1453236 },
    { lat: 48.8632123, lng: 18.1452985 },
    { lat: 48.8635423, lng: 18.1452475 },
    { lat: 48.8641055, lng: 18.144683 },
    { lat: 48.8645325, lng: 18.1442442 },
    { lat: 48.8648084, lng: 18.1439606 },
    { lat: 48.8650797, lng: 18.1436346 },
    { lat: 48.8652032, lng: 18.1434691 },
    { lat: 48.864794, lng: 18.1430098 },
    { lat: 48.8641554, lng: 18.1423657 },
    { lat: 48.8637246, lng: 18.1419029 },
    { lat: 48.8632106, lng: 18.1413859 },
    { lat: 48.8629616, lng: 18.1415094 },
    { lat: 48.862588, lng: 18.1414114 },
    { lat: 48.8618304, lng: 18.1411139 },
    { lat: 48.8611869, lng: 18.1411449 },
    { lat: 48.8608518, lng: 18.1406587 },
    { lat: 48.8607053, lng: 18.140018 },
    { lat: 48.8603707, lng: 18.1391723 },
    { lat: 48.8600301, lng: 18.1384521 },
    { lat: 48.8599725, lng: 18.138316 },
    { lat: 48.859847, lng: 18.1380177 },
    { lat: 48.8598188, lng: 18.1379598 },
    { lat: 48.8596593, lng: 18.1374886 },
    { lat: 48.8594655, lng: 18.1369678 },
    { lat: 48.859127, lng: 18.136129 },
    { lat: 48.8589088, lng: 18.135733 },
    { lat: 48.8588299, lng: 18.1356744 },
    { lat: 48.8583958, lng: 18.1353687 },
    { lat: 48.8582531, lng: 18.1352637 },
    { lat: 48.8581889, lng: 18.1352257 },
    { lat: 48.8577292, lng: 18.1350833 },
    { lat: 48.8573622, lng: 18.1349242 },
    { lat: 48.8572235, lng: 18.1347302 },
    { lat: 48.8571499, lng: 18.1345131 },
    { lat: 48.8571352, lng: 18.1344334 },
    { lat: 48.8569763, lng: 18.1336579 },
    { lat: 48.8570519, lng: 18.133188 },
    { lat: 48.8573255, lng: 18.1325768 },
    { lat: 48.8576401, lng: 18.1321041 },
    { lat: 48.8570309, lng: 18.1319315 },
    { lat: 48.8565708, lng: 18.1318035 },
    { lat: 48.8559454, lng: 18.1316814 },
    { lat: 48.8557259, lng: 18.1315333 },
    { lat: 48.8554087, lng: 18.1309491 },
    { lat: 48.8552361, lng: 18.1307732 },
    { lat: 48.8543863, lng: 18.1299356 },
    { lat: 48.8541781, lng: 18.1293623 },
    { lat: 48.8541573, lng: 18.1293336 },
    { lat: 48.8538791, lng: 18.1289155 },
    { lat: 48.853318, lng: 18.1290877 },
    { lat: 48.8531605, lng: 18.1290753 },
    { lat: 48.8528204, lng: 18.129045 },
    { lat: 48.8523898, lng: 18.1289404 },
    { lat: 48.85196, lng: 18.1288234 },
    { lat: 48.8515292, lng: 18.1284784 },
    { lat: 48.8513824, lng: 18.1282267 },
    { lat: 48.8513253, lng: 18.1279788 },
    { lat: 48.8512531, lng: 18.1278232 },
    { lat: 48.8510822, lng: 18.1275844 },
    { lat: 48.8506862, lng: 18.1271573 },
    { lat: 48.8505963, lng: 18.1271245 },
    { lat: 48.8500712, lng: 18.1272353 },
    { lat: 48.8495062, lng: 18.1269866 },
    { lat: 48.8492065, lng: 18.1268008 },
    { lat: 48.8489611, lng: 18.1261706 },
    { lat: 48.8488269, lng: 18.1260946 },
    { lat: 48.8483326, lng: 18.1259949 },
    { lat: 48.847906, lng: 18.1259184 },
    { lat: 48.8478248, lng: 18.1258995 },
    { lat: 48.8477473, lng: 18.1258289 },
    { lat: 48.8476057, lng: 18.1257023 },
    { lat: 48.8476059, lng: 18.1253927 },
    { lat: 48.8473787, lng: 18.1252305 },
    { lat: 48.8470575, lng: 18.1252375 },
    { lat: 48.8470246, lng: 18.1252682 },
    { lat: 48.8467374, lng: 18.1255366 },
    { lat: 48.8467332, lng: 18.1255404 },
    { lat: 48.8465284, lng: 18.1257494 },
    { lat: 48.8463539, lng: 18.1259274 },
    { lat: 48.8461183, lng: 18.1261476 },
    { lat: 48.8458473, lng: 18.1262822 },
    { lat: 48.8456812, lng: 18.1263577 },
    { lat: 48.8454416, lng: 18.1265029 },
    { lat: 48.8451917, lng: 18.1264953 },
    { lat: 48.8447714, lng: 18.1262138 },
    { lat: 48.8445238, lng: 18.1259924 },
    { lat: 48.8442943, lng: 18.1257915 },
    { lat: 48.8441883, lng: 18.1257945 },
    { lat: 48.8439908, lng: 18.1257932 },
    { lat: 48.8438395, lng: 18.1257535 },
    { lat: 48.8436052, lng: 18.1254476 },
    { lat: 48.8434998, lng: 18.125266 },
    { lat: 48.8434581, lng: 18.1251512 },
    { lat: 48.8434012, lng: 18.1251609 },
    { lat: 48.8433426, lng: 18.1251454 },
    { lat: 48.843292, lng: 18.1251454 },
    { lat: 48.8430162, lng: 18.124903 },
    { lat: 48.8429378, lng: 18.1249135 },
    { lat: 48.8428035, lng: 18.1248149 },
    { lat: 48.8425054, lng: 18.1246586 },
    { lat: 48.8423068, lng: 18.1244637 },
    { lat: 48.8421439, lng: 18.1242231 },
    { lat: 48.8416156, lng: 18.124027 },
    { lat: 48.841411, lng: 18.1237289 },
    { lat: 48.8413243, lng: 18.1236541 },
    { lat: 48.8412384, lng: 18.1235183 },
    { lat: 48.8411588, lng: 18.1233889 },
    { lat: 48.8410436, lng: 18.1233487 },
    { lat: 48.8407045, lng: 18.1233398 },
    { lat: 48.8405617, lng: 18.1233692 },
    { lat: 48.8401861, lng: 18.1233723 },
    { lat: 48.8397884, lng: 18.1233637 },
    { lat: 48.8396727, lng: 18.1233547 },
    { lat: 48.8394614, lng: 18.1233495 },
    { lat: 48.8392714, lng: 18.123343 },
    { lat: 48.8395432, lng: 18.1238244 },
    { lat: 48.8397281, lng: 18.1241709 },
    { lat: 48.8396767, lng: 18.1243927 },
    { lat: 48.8396854, lng: 18.1245442 },
    { lat: 48.8397235, lng: 18.1247508 },
    { lat: 48.8397794, lng: 18.1249211 },
    { lat: 48.8398125, lng: 18.1249813 },
    { lat: 48.8398781, lng: 18.1251007 },
    { lat: 48.8400391, lng: 18.1253493 },
    { lat: 48.8404782, lng: 18.1259551 },
    { lat: 48.8406187, lng: 18.1262373 },
    { lat: 48.8406627, lng: 18.126723 },
    { lat: 48.8406391, lng: 18.1270166 },
    { lat: 48.8406429, lng: 18.1277423 },
    { lat: 48.8405236, lng: 18.1280226 },
    { lat: 48.8404478, lng: 18.128387 },
    { lat: 48.8403705, lng: 18.1288232 },
    { lat: 48.8403446, lng: 18.1288045 },
    { lat: 48.8402126, lng: 18.1287672 },
    { lat: 48.8395211, lng: 18.1287343 },
    { lat: 48.8392529, lng: 18.1286646 },
    { lat: 48.8388622, lng: 18.1286178 },
    { lat: 48.8388503, lng: 18.1286666 },
    { lat: 48.8388194, lng: 18.1288907 },
    { lat: 48.8388086, lng: 18.1289584 },
    { lat: 48.8387625, lng: 18.1291745 },
    { lat: 48.8384373, lng: 18.1290953 },
    { lat: 48.8381927, lng: 18.1290014 },
    { lat: 48.8380151, lng: 18.1289963 },
    { lat: 48.8378424, lng: 18.1289387 },
    { lat: 48.837787, lng: 18.1289325 },
    { lat: 48.8376765, lng: 18.1289153 },
    { lat: 48.8373018, lng: 18.1288194 },
    { lat: 48.8369614, lng: 18.1287286 },
    { lat: 48.8367952, lng: 18.1287048 },
    { lat: 48.8366848, lng: 18.1285505 },
    { lat: 48.836304, lng: 18.1282103 },
    { lat: 48.8356746, lng: 18.1278847 },
    { lat: 48.8353456, lng: 18.1278185 },
    { lat: 48.8351403, lng: 18.1280912 },
    { lat: 48.8345161, lng: 18.1284967 },
    { lat: 48.83413, lng: 18.1285509 },
    { lat: 48.8338472, lng: 18.128734 },
    { lat: 48.8334646, lng: 18.1291052 },
    { lat: 48.8331142, lng: 18.1291919 },
    { lat: 48.832848, lng: 18.1294097 },
    { lat: 48.8327183, lng: 18.1296598 },
    { lat: 48.832585, lng: 18.1299501 },
    { lat: 48.8320581, lng: 18.1304959 },
    { lat: 48.8317301, lng: 18.1305252 },
    { lat: 48.8312454, lng: 18.1304736 },
    { lat: 48.8309819, lng: 18.1304274 },
    { lat: 48.8304591, lng: 18.1302444 },
    { lat: 48.8300658, lng: 18.1302918 },
    { lat: 48.8297473, lng: 18.1303291 },
    { lat: 48.8294688, lng: 18.1303511 },
    { lat: 48.8289519, lng: 18.1303884 },
    { lat: 48.8285768, lng: 18.1307908 },
    { lat: 48.8284987, lng: 18.1308832 },
    { lat: 48.8283846, lng: 18.130999 },
    { lat: 48.8281365, lng: 18.1312723 },
    { lat: 48.82775, lng: 18.1316898 },
    { lat: 48.8272791, lng: 18.132097 },
    { lat: 48.8266271, lng: 18.1326633 },
    { lat: 48.8259525, lng: 18.1331942 },
    { lat: 48.8257981, lng: 18.1332348 },
    { lat: 48.8252652, lng: 18.1333469 },
    { lat: 48.8246875, lng: 18.1331143 },
    { lat: 48.8244994, lng: 18.1330437 },
    { lat: 48.824382, lng: 18.1329468 },
    { lat: 48.8239388, lng: 18.1326415 },
    { lat: 48.8234396, lng: 18.1322617 },
    { lat: 48.8231954, lng: 18.1320797 },
    { lat: 48.8230199, lng: 18.1319529 },
    { lat: 48.8226586, lng: 18.1316794 },
    { lat: 48.8225236, lng: 18.1315856 },
    { lat: 48.822034, lng: 18.1314166 },
    { lat: 48.8216247, lng: 18.1312837 },
    { lat: 48.8213578, lng: 18.131235 },
    { lat: 48.8212724, lng: 18.1312808 },
    { lat: 48.8211146, lng: 18.1313641 },
    { lat: 48.821035, lng: 18.1314108 },
    { lat: 48.8209457, lng: 18.1314653 },
    { lat: 48.8207349, lng: 18.1317043 },
    { lat: 48.8203723, lng: 18.1321267 },
    { lat: 48.8201735, lng: 18.1321041 },
    { lat: 48.8199407, lng: 18.1320767 },
    { lat: 48.819935, lng: 18.1320764 },
    { lat: 48.8192076, lng: 18.1319873 },
    { lat: 48.8188266, lng: 18.1323073 },
    { lat: 48.8185741, lng: 18.1325249 },
    { lat: 48.818123, lng: 18.1330572 },
    { lat: 48.8173049, lng: 18.1339309 },
    { lat: 48.8168057, lng: 18.1346239 },
    { lat: 48.8157109, lng: 18.1353792 },
    { lat: 48.8150037, lng: 18.1358746 },
    { lat: 48.8145644, lng: 18.1361818 },
    { lat: 48.8144824, lng: 18.1357391 },
    { lat: 48.8143276, lng: 18.13521 },
    { lat: 48.8141016, lng: 18.1344444 },
    { lat: 48.8140735, lng: 18.1343884 },
    { lat: 48.8140025, lng: 18.1341941 },
    { lat: 48.8138508, lng: 18.1341888 },
    { lat: 48.8135382, lng: 18.1341692 },
    { lat: 48.8133083, lng: 18.1342323 },
    { lat: 48.8132894, lng: 18.1342581 },
    { lat: 48.8132581, lng: 18.1343006 },
    { lat: 48.8132298, lng: 18.1343387 },
    { lat: 48.8132173, lng: 18.1343561 },
    { lat: 48.8127968, lng: 18.134673 },
    { lat: 48.812672, lng: 18.1347802 },
    { lat: 48.8125977, lng: 18.1348409 },
    { lat: 48.812503, lng: 18.1349198 },
    { lat: 48.8123284, lng: 18.1350615 },
    { lat: 48.8121895, lng: 18.1351788 },
    { lat: 48.8118347, lng: 18.1354626 },
    { lat: 48.8114736, lng: 18.1357566 },
    { lat: 48.8112007, lng: 18.1360281 },
    { lat: 48.8110673, lng: 18.1357347 },
    { lat: 48.8106208, lng: 18.1359941 },
    { lat: 48.8105296, lng: 18.1360174 },
    { lat: 48.8104813, lng: 18.1360205 },
    { lat: 48.8103596, lng: 18.1361388 },
    { lat: 48.8101931, lng: 18.1362862 },
    { lat: 48.8100773, lng: 18.1364094 },
    { lat: 48.8099858, lng: 18.1365028 },
    { lat: 48.8099129, lng: 18.1365712 },
    { lat: 48.8097306, lng: 18.1367358 },
    { lat: 48.8096426, lng: 18.1368126 },
    { lat: 48.8092228, lng: 18.1371663 },
    { lat: 48.8090381, lng: 18.1366986 },
    { lat: 48.8090313, lng: 18.1366815 },
    { lat: 48.8089776, lng: 18.1365544 },
    { lat: 48.8088143, lng: 18.1366782 },
    { lat: 48.8081388, lng: 18.137113 },
    { lat: 48.8081169, lng: 18.137127 },
    { lat: 48.8079872, lng: 18.1372069 },
    { lat: 48.8079473, lng: 18.1372366 },
    { lat: 48.8077819, lng: 18.1372629 },
    { lat: 48.807554, lng: 18.1373816 },
    { lat: 48.8070128, lng: 18.1376443 },
    { lat: 48.8069006, lng: 18.1376549 },
    { lat: 48.8065947, lng: 18.137751 },
    { lat: 48.8064017, lng: 18.1378687 },
    { lat: 48.8063223, lng: 18.1379175 },
    { lat: 48.8061499, lng: 18.1380392 },
    { lat: 48.8059681, lng: 18.1381353 },
    { lat: 48.8056691, lng: 18.1383491 },
    { lat: 48.8055233, lng: 18.1384608 },
    { lat: 48.8053535, lng: 18.138628 },
    { lat: 48.8052441, lng: 18.1385512 },
    { lat: 48.8050071, lng: 18.1386292 },
    { lat: 48.8049688, lng: 18.1386694 },
    { lat: 48.8049135, lng: 18.1387276 },
    { lat: 48.8049008, lng: 18.1387407 },
    { lat: 48.8047622, lng: 18.1389403 },
    { lat: 48.8051401, lng: 18.1396179 },
    { lat: 48.8057639, lng: 18.1406295 },
    { lat: 48.8057778, lng: 18.1406527 },
    { lat: 48.8061226, lng: 18.1412294 },
    { lat: 48.8062746, lng: 18.1414574 },
    { lat: 48.8066326, lng: 18.1420643 },
    { lat: 48.806612, lng: 18.1420817 },
    { lat: 48.8054961, lng: 18.1430199 },
    { lat: 48.8056106, lng: 18.1436899 },
    { lat: 48.8056393, lng: 18.1439131 },
    { lat: 48.8050227, lng: 18.1437465 },
    { lat: 48.8043985, lng: 18.1436088 },
    { lat: 48.8033607, lng: 18.1438307 },
    { lat: 48.8033569, lng: 18.1438219 },
    { lat: 48.8029571, lng: 18.1429216 },
    { lat: 48.8025524, lng: 18.142365 },
    { lat: 48.8024048, lng: 18.1421924 },
    { lat: 48.8019445, lng: 18.1426021 },
    { lat: 48.8015638, lng: 18.1429455 },
    { lat: 48.8012853, lng: 18.1432456 },
    { lat: 48.8003773, lng: 18.144262 },
    { lat: 48.8001399, lng: 18.1445484 },
    { lat: 48.7998013, lng: 18.1449577 },
    { lat: 48.7996246, lng: 18.1451802 },
  ],
  Omšenie: [
    { lat: 48.8812084, lng: 18.1928176 },
    { lat: 48.8811865, lng: 18.192848 },
    { lat: 48.8811307, lng: 18.1929181 },
    { lat: 48.8809974, lng: 18.1930709 },
    { lat: 48.8806377, lng: 18.1935099 },
    { lat: 48.8802077, lng: 18.1940979 },
    { lat: 48.879851, lng: 18.1946264 },
    { lat: 48.8797354, lng: 18.1947765 },
    { lat: 48.8795327, lng: 18.1953718 },
    { lat: 48.8794944, lng: 18.196139 },
    { lat: 48.8794204, lng: 18.1963445 },
    { lat: 48.8791203, lng: 18.196621 },
    { lat: 48.8787803, lng: 18.1967523 },
    { lat: 48.8784675, lng: 18.1969434 },
    { lat: 48.8781638, lng: 18.1974161 },
    { lat: 48.8778805, lng: 18.1978854 },
    { lat: 48.8774726, lng: 18.1985859 },
    { lat: 48.8772015, lng: 18.1985579 },
    { lat: 48.8769737, lng: 18.1985406 },
    { lat: 48.8764818, lng: 18.1985638 },
    { lat: 48.8761249, lng: 18.1985162 },
    { lat: 48.8758487, lng: 18.198462 },
    { lat: 48.8753852, lng: 18.19837 },
    { lat: 48.8750855, lng: 18.1982779 },
    { lat: 48.8746508, lng: 18.1981356 },
    { lat: 48.8743993, lng: 18.1980583 },
    { lat: 48.8743396, lng: 18.19804 },
    { lat: 48.8742581, lng: 18.1982357 },
    { lat: 48.8742831, lng: 18.1982959 },
    { lat: 48.8741832, lng: 18.1984008 },
    { lat: 48.8741779, lng: 18.1984861 },
    { lat: 48.8742515, lng: 18.1985904 },
    { lat: 48.8742657, lng: 18.1988954 },
    { lat: 48.8741807, lng: 18.1991285 },
    { lat: 48.8741433, lng: 18.1993015 },
    { lat: 48.8741466, lng: 18.1993599 },
    { lat: 48.8742422, lng: 18.1994311 },
    { lat: 48.8742977, lng: 18.1995532 },
    { lat: 48.8742671, lng: 18.1998645 },
    { lat: 48.874281, lng: 18.2000698 },
    { lat: 48.8742557, lng: 18.200219 },
    { lat: 48.8742437, lng: 18.2004207 },
    { lat: 48.8743128, lng: 18.2005668 },
    { lat: 48.8743059, lng: 18.2008957 },
    { lat: 48.8742242, lng: 18.2010674 },
    { lat: 48.8741741, lng: 18.2017486 },
    { lat: 48.8740111, lng: 18.2019934 },
    { lat: 48.8740314, lng: 18.2022123 },
    { lat: 48.8739507, lng: 18.2022982 },
    { lat: 48.8738847, lng: 18.2024667 },
    { lat: 48.873891, lng: 18.2026344 },
    { lat: 48.8739779, lng: 18.2026573 },
    { lat: 48.8739945, lng: 18.2027107 },
    { lat: 48.8738488, lng: 18.2031963 },
    { lat: 48.8737964, lng: 18.2033265 },
    { lat: 48.8737218, lng: 18.2039141 },
    { lat: 48.8737322, lng: 18.2040963 },
    { lat: 48.8737562, lng: 18.2042419 },
    { lat: 48.8734928, lng: 18.2045848 },
    { lat: 48.873286, lng: 18.2048376 },
    { lat: 48.8732422, lng: 18.2051763 },
    { lat: 48.8733543, lng: 18.2054974 },
    { lat: 48.8733342, lng: 18.2056589 },
    { lat: 48.8732903, lng: 18.2057797 },
    { lat: 48.873264, lng: 18.2060101 },
    { lat: 48.873235, lng: 18.2060153 },
    { lat: 48.8732595, lng: 18.2062247 },
    { lat: 48.8732381, lng: 18.2062271 },
    { lat: 48.8732267, lng: 18.2064032 },
    { lat: 48.8731862, lng: 18.2065225 },
    { lat: 48.8728651, lng: 18.2069422 },
    { lat: 48.8728088, lng: 18.2072023 },
    { lat: 48.8729099, lng: 18.2077256 },
    { lat: 48.873165, lng: 18.2083778 },
    { lat: 48.8736622, lng: 18.2093744 },
    { lat: 48.8738145, lng: 18.2105402 },
    { lat: 48.8737002, lng: 18.2113194 },
    { lat: 48.8737627, lng: 18.2123747 },
    { lat: 48.873661, lng: 18.213081 },
    { lat: 48.8733438, lng: 18.2139092 },
    { lat: 48.8733195, lng: 18.2139181 },
    { lat: 48.8733064, lng: 18.2139044 },
    { lat: 48.8732892, lng: 18.2139178 },
    { lat: 48.8733334, lng: 18.213941 },
    { lat: 48.8734615, lng: 18.2144081 },
    { lat: 48.8732205, lng: 18.214829 },
    { lat: 48.8729304, lng: 18.2159898 },
    { lat: 48.8729215, lng: 18.21621 },
    { lat: 48.8729112, lng: 18.216444 },
    { lat: 48.8728935, lng: 18.2168037 },
    { lat: 48.8728723, lng: 18.2175216 },
    { lat: 48.8726026, lng: 18.2190403 },
    { lat: 48.8724199, lng: 18.2196116 },
    { lat: 48.8724387, lng: 18.2197041 },
    { lat: 48.8725046, lng: 18.2200256 },
    { lat: 48.8727534, lng: 18.2203542 },
    { lat: 48.872819, lng: 18.2206911 },
    { lat: 48.8724323, lng: 18.2213882 },
    { lat: 48.8720083, lng: 18.2219668 },
    { lat: 48.8715927, lng: 18.2228275 },
    { lat: 48.8715426, lng: 18.2233922 },
    { lat: 48.8716582, lng: 18.224053 },
    { lat: 48.8717487, lng: 18.2247881 },
    { lat: 48.8716498, lng: 18.2260796 },
    { lat: 48.8718435, lng: 18.2276543 },
    { lat: 48.871979, lng: 18.228361 },
    { lat: 48.872111, lng: 18.228761 },
    { lat: 48.872121, lng: 18.228797 },
    { lat: 48.872775, lng: 18.230888 },
    { lat: 48.872807, lng: 18.230988 },
    { lat: 48.872937, lng: 18.231403 },
    { lat: 48.873728, lng: 18.235239 },
    { lat: 48.87386, lng: 18.237307 },
    { lat: 48.874013, lng: 18.238314 },
    { lat: 48.874336, lng: 18.239805 },
    { lat: 48.875426, lng: 18.241387 },
    { lat: 48.875897, lng: 18.242636 },
    { lat: 48.876326, lng: 18.243715 },
    { lat: 48.876738, lng: 18.244845 },
    { lat: 48.877583, lng: 18.247052 },
    { lat: 48.878402, lng: 18.249268 },
    { lat: 48.878813, lng: 18.251312 },
    { lat: 48.879284, lng: 18.253764 },
    { lat: 48.879614, lng: 18.255471 },
    { lat: 48.880101, lng: 18.258156 },
    { lat: 48.8802868, lng: 18.2580593 },
    { lat: 48.8813923, lng: 18.2574421 },
    { lat: 48.8815268, lng: 18.2573668 },
    { lat: 48.8825318, lng: 18.2571985 },
    { lat: 48.883318, lng: 18.2570582 },
    { lat: 48.8850048, lng: 18.2571554 },
    { lat: 48.8854397, lng: 18.2570653 },
    { lat: 48.8863088, lng: 18.257867 },
    { lat: 48.8885688, lng: 18.2591185 },
    { lat: 48.8896035, lng: 18.2599736 },
    { lat: 48.8897305, lng: 18.2600818 },
    { lat: 48.8897708, lng: 18.2601167 },
    { lat: 48.8898144, lng: 18.260149 },
    { lat: 48.890723, lng: 18.2609102 },
    { lat: 48.8907998, lng: 18.2608919 },
    { lat: 48.8914994, lng: 18.2607134 },
    { lat: 48.891535, lng: 18.2607238 },
    { lat: 48.8915764, lng: 18.260736 },
    { lat: 48.8916221, lng: 18.2607508 },
    { lat: 48.8917626, lng: 18.2607981 },
    { lat: 48.8922829, lng: 18.2609413 },
    { lat: 48.8928803, lng: 18.260831 },
    { lat: 48.8929797, lng: 18.2608123 },
    { lat: 48.8936363, lng: 18.2607513 },
    { lat: 48.8937765, lng: 18.2607376 },
    { lat: 48.8940257, lng: 18.2606341 },
    { lat: 48.8949946, lng: 18.260235 },
    { lat: 48.895108, lng: 18.2600248 },
    { lat: 48.8952278, lng: 18.2598901 },
    { lat: 48.8954179, lng: 18.2596784 },
    { lat: 48.8954974, lng: 18.2595877 },
    { lat: 48.8956586, lng: 18.2594494 },
    { lat: 48.89593, lng: 18.2592196 },
    { lat: 48.8960147, lng: 18.2591407 },
    { lat: 48.8960937, lng: 18.2590207 },
    { lat: 48.8962199, lng: 18.259058 },
    { lat: 48.8963497, lng: 18.2589987 },
    { lat: 48.8965101, lng: 18.2587841 },
    { lat: 48.8966932, lng: 18.2586318 },
    { lat: 48.8967942, lng: 18.2586211 },
    { lat: 48.8972063, lng: 18.2584877 },
    { lat: 48.8974614, lng: 18.25835 },
    { lat: 48.8976362, lng: 18.2582511 },
    { lat: 48.8978376, lng: 18.2579792 },
    { lat: 48.8982408, lng: 18.2577891 },
    { lat: 48.8988753, lng: 18.2575588 },
    { lat: 48.8990153, lng: 18.2573232 },
    { lat: 48.8990635, lng: 18.257276 },
    { lat: 48.899196, lng: 18.2571443 },
    { lat: 48.8992774, lng: 18.2569289 },
    { lat: 48.8993411, lng: 18.2567606 },
    { lat: 48.8994157, lng: 18.2565633 },
    { lat: 48.8994816, lng: 18.2564324 },
    { lat: 48.8996344, lng: 18.256127 },
    { lat: 48.8998609, lng: 18.256059 },
    { lat: 48.8999585, lng: 18.2560294 },
    { lat: 48.9001226, lng: 18.2559803 },
    { lat: 48.9002003, lng: 18.2559569 },
    { lat: 48.9002889, lng: 18.2557907 },
    { lat: 48.9003332, lng: 18.2556167 },
    { lat: 48.9004661, lng: 18.2551131 },
    { lat: 48.9010184, lng: 18.2540908 },
    { lat: 48.9012177, lng: 18.2539654 },
    { lat: 48.9013247, lng: 18.2540422 },
    { lat: 48.9014825, lng: 18.2541568 },
    { lat: 48.9016665, lng: 18.254336 },
    { lat: 48.9019242, lng: 18.2544282 },
    { lat: 48.9020458, lng: 18.2543406 },
    { lat: 48.9020994, lng: 18.2543322 },
    { lat: 48.9020047, lng: 18.2548083 },
    { lat: 48.9019842, lng: 18.2553048 },
    { lat: 48.9018888, lng: 18.2556468 },
    { lat: 48.9019091, lng: 18.2556894 },
    { lat: 48.9019451, lng: 18.2557643 },
    { lat: 48.9022412, lng: 18.2557948 },
    { lat: 48.9022423, lng: 18.2557946 },
    { lat: 48.9022628, lng: 18.255797 },
    { lat: 48.9023115, lng: 18.2558218 },
    { lat: 48.9023232, lng: 18.2558278 },
    { lat: 48.9023626, lng: 18.2558471 },
    { lat: 48.9023868, lng: 18.2564317 },
    { lat: 48.9024551, lng: 18.2565598 },
    { lat: 48.9026498, lng: 18.256633 },
    { lat: 48.9026764, lng: 18.2566427 },
    { lat: 48.9027943, lng: 18.2567708 },
    { lat: 48.9028845, lng: 18.25683 },
    { lat: 48.903042, lng: 18.2571595 },
    { lat: 48.9032419, lng: 18.2572822 },
    { lat: 48.903368, lng: 18.2574547 },
    { lat: 48.9033989, lng: 18.2577243 },
    { lat: 48.9035685, lng: 18.2579873 },
    { lat: 48.9039982, lng: 18.2579429 },
    { lat: 48.904059, lng: 18.2580978 },
    { lat: 48.9040921, lng: 18.2581649 },
    { lat: 48.904176, lng: 18.2581934 },
    { lat: 48.90432, lng: 18.2581057 },
    { lat: 48.9045304, lng: 18.2581969 },
    { lat: 48.9048608, lng: 18.258691 },
    { lat: 48.9049067, lng: 18.2587832 },
    { lat: 48.9049794, lng: 18.2589308 },
    { lat: 48.9049832, lng: 18.2589393 },
    { lat: 48.9049861, lng: 18.258949 },
    { lat: 48.9050518, lng: 18.2592105 },
    { lat: 48.9055134, lng: 18.2595906 },
    { lat: 48.9056748, lng: 18.2599672 },
    { lat: 48.9058924, lng: 18.259927 },
    { lat: 48.9061134, lng: 18.2600057 },
    { lat: 48.9061785, lng: 18.2601163 },
    { lat: 48.9061816, lng: 18.2603906 },
    { lat: 48.9062021, lng: 18.2604148 },
    { lat: 48.9075001, lng: 18.2603431 },
    { lat: 48.9075538, lng: 18.2601937 },
    { lat: 48.907898, lng: 18.2592603 },
    { lat: 48.9080945, lng: 18.2587285 },
    { lat: 48.9081514, lng: 18.2585758 },
    { lat: 48.9082258, lng: 18.2583792 },
    { lat: 48.9083017, lng: 18.2582459 },
    { lat: 48.9085276, lng: 18.2578531 },
    { lat: 48.9086219, lng: 18.2576851 },
    { lat: 48.9086756, lng: 18.2575981 },
    { lat: 48.9087501, lng: 18.2574782 },
    { lat: 48.9088173, lng: 18.2573708 },
    { lat: 48.9088573, lng: 18.2573171 },
    { lat: 48.9089004, lng: 18.2572448 },
    { lat: 48.9089762, lng: 18.2571298 },
    { lat: 48.9090125, lng: 18.2570738 },
    { lat: 48.909026, lng: 18.2570537 },
    { lat: 48.909103, lng: 18.2569349 },
    { lat: 48.909244, lng: 18.2567171 },
    { lat: 48.9094563, lng: 18.2564415 },
    { lat: 48.9094756, lng: 18.256407 },
    { lat: 48.909906, lng: 18.2557001 },
    { lat: 48.9100064, lng: 18.2555403 },
    { lat: 48.9103353, lng: 18.2545589 },
    { lat: 48.9113531, lng: 18.2542421 },
    { lat: 48.9113733, lng: 18.2542395 },
    { lat: 48.9113963, lng: 18.2542074 },
    { lat: 48.9115267, lng: 18.2540393 },
    { lat: 48.911598, lng: 18.2539464 },
    { lat: 48.9116477, lng: 18.253884 },
    { lat: 48.9118099, lng: 18.2538603 },
    { lat: 48.912184, lng: 18.2532571 },
    { lat: 48.9123171, lng: 18.2532361 },
    { lat: 48.9124909, lng: 18.2532159 },
    { lat: 48.9126936, lng: 18.2531926 },
    { lat: 48.9129226, lng: 18.2531691 },
    { lat: 48.9133337, lng: 18.2531193 },
    { lat: 48.9133582, lng: 18.2531164 },
    { lat: 48.913805, lng: 18.253063 },
    { lat: 48.913519, lng: 18.25253 },
    { lat: 48.913285, lng: 18.252103 },
    { lat: 48.913114, lng: 18.251626 },
    { lat: 48.913085, lng: 18.251548 },
    { lat: 48.912778, lng: 18.250864 },
    { lat: 48.912714, lng: 18.25028 },
    { lat: 48.912568, lng: 18.249594 },
    { lat: 48.912741, lng: 18.249074 },
    { lat: 48.912989, lng: 18.248649 },
    { lat: 48.913104, lng: 18.247609 },
    { lat: 48.913187, lng: 18.247419 },
    { lat: 48.913479, lng: 18.24691 },
    { lat: 48.913469, lng: 18.246554 },
    { lat: 48.91361, lng: 18.246185 },
    { lat: 48.913776, lng: 18.24567 },
    { lat: 48.913992, lng: 18.24496 },
    { lat: 48.914225, lng: 18.24419 },
    { lat: 48.91449, lng: 18.243745 },
    { lat: 48.914652, lng: 18.243284 },
    { lat: 48.914658, lng: 18.242795 },
    { lat: 48.91494, lng: 18.241834 },
    { lat: 48.915048, lng: 18.241436 },
    { lat: 48.9152, lng: 18.241087 },
    { lat: 48.915343, lng: 18.240716 },
    { lat: 48.915469, lng: 18.240473 },
    { lat: 48.915584, lng: 18.240236 },
    { lat: 48.915655, lng: 18.239715 },
    { lat: 48.915784, lng: 18.2394 },
    { lat: 48.915913, lng: 18.23922 },
    { lat: 48.9159943, lng: 18.2391352 },
    { lat: 48.916121, lng: 18.239003 },
    { lat: 48.916268, lng: 18.238692 },
    { lat: 48.916593, lng: 18.238137 },
    { lat: 48.916809, lng: 18.23746 },
    { lat: 48.916939, lng: 18.237058 },
    { lat: 48.917039, lng: 18.236519 },
    { lat: 48.917267, lng: 18.235982 },
    { lat: 48.917239, lng: 18.235759 },
    { lat: 48.917211, lng: 18.235592 },
    { lat: 48.917239, lng: 18.235067 },
    { lat: 48.91719, lng: 18.234437 },
    { lat: 48.91721, lng: 18.234042 },
    { lat: 48.917223, lng: 18.232834 },
    { lat: 48.91728, lng: 18.232179 },
    { lat: 48.917368, lng: 18.231489 },
    { lat: 48.917548, lng: 18.230839 },
    { lat: 48.917683, lng: 18.230227 },
    { lat: 48.917805, lng: 18.229809 },
    { lat: 48.917971, lng: 18.229258 },
    { lat: 48.91811, lng: 18.228679 },
    { lat: 48.9181, lng: 18.228046 },
    { lat: 48.918377, lng: 18.227315 },
    { lat: 48.918521, lng: 18.227269 },
    { lat: 48.918543, lng: 18.226749 },
    { lat: 48.918576, lng: 18.22638 },
    { lat: 48.918741, lng: 18.225824 },
    { lat: 48.918897, lng: 18.225498 },
    { lat: 48.919331, lng: 18.224395 },
    { lat: 48.91962, lng: 18.223086 },
    { lat: 48.919685, lng: 18.222777 },
    { lat: 48.919905, lng: 18.222134 },
    { lat: 48.919814, lng: 18.221778 },
    { lat: 48.919676, lng: 18.221498 },
    { lat: 48.919508, lng: 18.220903 },
    { lat: 48.9193805, lng: 18.2204175 },
    { lat: 48.919333, lng: 18.220248 },
    { lat: 48.919043, lng: 18.219392 },
    { lat: 48.918871, lng: 18.218944 },
    { lat: 48.918844, lng: 18.218872 },
    { lat: 48.91844, lng: 18.217837 },
    { lat: 48.918221, lng: 18.217197 },
    { lat: 48.917281, lng: 18.214015 },
    { lat: 48.917448, lng: 18.213506 },
    { lat: 48.917965, lng: 18.212782 },
    { lat: 48.918026, lng: 18.212076 },
    { lat: 48.918603, lng: 18.21088 },
    { lat: 48.918869, lng: 18.210397 },
    { lat: 48.919066, lng: 18.208905 },
    { lat: 48.919229, lng: 18.208559 },
    { lat: 48.919939, lng: 18.208167 },
    { lat: 48.920518, lng: 18.207944 },
    { lat: 48.921046, lng: 18.207312 },
    { lat: 48.9208602, lng: 18.2058434 },
    { lat: 48.920776, lng: 18.205178 },
    { lat: 48.920672, lng: 18.204543 },
    { lat: 48.920636, lng: 18.204304 },
    { lat: 48.920117, lng: 18.202874 },
    { lat: 48.919953, lng: 18.202548 },
    { lat: 48.919809, lng: 18.202309 },
    { lat: 48.919541, lng: 18.201866 },
    { lat: 48.919196, lng: 18.20149 },
    { lat: 48.918951, lng: 18.201179 },
    { lat: 48.91827, lng: 18.199974 },
    { lat: 48.918096, lng: 18.19971 },
    { lat: 48.917759, lng: 18.199149 },
    { lat: 48.917536, lng: 18.198806 },
    { lat: 48.917279, lng: 18.19846 },
    { lat: 48.916965, lng: 18.198236 },
    { lat: 48.916684, lng: 18.197895 },
    { lat: 48.916129, lng: 18.197317 },
    { lat: 48.915828, lng: 18.197064 },
    { lat: 48.915294, lng: 18.196205 },
    { lat: 48.915344, lng: 18.195842 },
    { lat: 48.915396, lng: 18.195596 },
    { lat: 48.91565, lng: 18.194474 },
    { lat: 48.915747, lng: 18.193744 },
    { lat: 48.915457, lng: 18.193243 },
    { lat: 48.915072, lng: 18.192821 },
    { lat: 48.914967, lng: 18.192707 },
    { lat: 48.91489, lng: 18.192601 },
    { lat: 48.914727, lng: 18.192154 },
    { lat: 48.914672, lng: 18.191937 },
    { lat: 48.914235, lng: 18.191753 },
    { lat: 48.914194, lng: 18.191525 },
    { lat: 48.913826, lng: 18.191398 },
    { lat: 48.913811, lng: 18.190784 },
    { lat: 48.913729, lng: 18.1905 },
    { lat: 48.913782, lng: 18.19038 },
    { lat: 48.914053, lng: 18.189824 },
    { lat: 48.91409, lng: 18.189721 },
    { lat: 48.914178, lng: 18.189576 },
    { lat: 48.914288, lng: 18.18916 },
    { lat: 48.91429, lng: 18.18883 },
    { lat: 48.914263, lng: 18.188451 },
    { lat: 48.914181, lng: 18.188278 },
    { lat: 48.914073, lng: 18.188045 },
    { lat: 48.913582, lng: 18.186984 },
    { lat: 48.913472, lng: 18.186633 },
    { lat: 48.91337, lng: 18.186348 },
    { lat: 48.91294, lng: 18.185556 },
    { lat: 48.912741, lng: 18.18498 },
    { lat: 48.91222, lng: 18.184211 },
    { lat: 48.911713, lng: 18.183855 },
    { lat: 48.9117, lng: 18.183838 },
    { lat: 48.911528, lng: 18.1845956 },
    { lat: 48.9112564, lng: 18.1848388 },
    { lat: 48.9112006, lng: 18.184909 },
    { lat: 48.9107527, lng: 18.1854805 },
    { lat: 48.9104189, lng: 18.1856799 },
    { lat: 48.9098197, lng: 18.1859341 },
    { lat: 48.9093119, lng: 18.1862502 },
    { lat: 48.9091138, lng: 18.1863739 },
    { lat: 48.9088712, lng: 18.186514 },
    { lat: 48.908561, lng: 18.1861805 },
    { lat: 48.9082059, lng: 18.1856961 },
    { lat: 48.9077972, lng: 18.1851206 },
    { lat: 48.9077873, lng: 18.1851058 },
    { lat: 48.9077775, lng: 18.185091 },
    { lat: 48.9075259, lng: 18.1846995 },
    { lat: 48.9075183, lng: 18.1846872 },
    { lat: 48.9073562, lng: 18.1844371 },
    { lat: 48.9069654, lng: 18.1838797 },
    { lat: 48.9068248, lng: 18.1842218 },
    { lat: 48.906504, lng: 18.1849841 },
    { lat: 48.9064501, lng: 18.185105 },
    { lat: 48.9063689, lng: 18.1852638 },
    { lat: 48.9062918, lng: 18.1853949 },
    { lat: 48.9062134, lng: 18.1855251 },
    { lat: 48.9061231, lng: 18.1856608 },
    { lat: 48.9061303, lng: 18.185667 },
    { lat: 48.9061071, lng: 18.1857364 },
    { lat: 48.9058992, lng: 18.1863202 },
    { lat: 48.9058185, lng: 18.1866236 },
    { lat: 48.9056938, lng: 18.1871824 },
    { lat: 48.9056261, lng: 18.1874604 },
    { lat: 48.9055233, lng: 18.1878904 },
    { lat: 48.9054971, lng: 18.1878461 },
    { lat: 48.9054387, lng: 18.1878704 },
    { lat: 48.9053874, lng: 18.1878212 },
    { lat: 48.9053388, lng: 18.1880173 },
    { lat: 48.9053148, lng: 18.1880507 },
    { lat: 48.9052243, lng: 18.188205 },
    { lat: 48.9052701, lng: 18.1886487 },
    { lat: 48.9051779, lng: 18.1886742 },
    { lat: 48.9051733, lng: 18.1886927 },
    { lat: 48.9051686, lng: 18.1887091 },
    { lat: 48.9051558, lng: 18.1887582 },
    { lat: 48.9054268, lng: 18.1889401 },
    { lat: 48.9055091, lng: 18.1889396 },
    { lat: 48.9056523, lng: 18.1888971 },
    { lat: 48.9056556, lng: 18.1888772 },
    { lat: 48.9057363, lng: 18.1888278 },
    { lat: 48.9057639, lng: 18.1888353 },
    { lat: 48.9057953, lng: 18.1888724 },
    { lat: 48.9057893, lng: 18.1889241 },
    { lat: 48.9059284, lng: 18.1889323 },
    { lat: 48.9059316, lng: 18.1888892 },
    { lat: 48.9059776, lng: 18.1888634 },
    { lat: 48.9060621, lng: 18.1888817 },
    { lat: 48.9062073, lng: 18.1890343 },
    { lat: 48.9062066, lng: 18.1890584 },
    { lat: 48.9062055, lng: 18.1890808 },
    { lat: 48.9062842, lng: 18.1892526 },
    { lat: 48.906309, lng: 18.1892895 },
    { lat: 48.9063669, lng: 18.1893762 },
    { lat: 48.9063637, lng: 18.1894183 },
    { lat: 48.9064241, lng: 18.1895071 },
    { lat: 48.9065054, lng: 18.1895678 },
    { lat: 48.9065868, lng: 18.1896277 },
    { lat: 48.9066592, lng: 18.1896328 },
    { lat: 48.9070547, lng: 18.1896595 },
    { lat: 48.9070662, lng: 18.1896454 },
    { lat: 48.9070883, lng: 18.1896181 },
    { lat: 48.9071072, lng: 18.1895944 },
    { lat: 48.9070616, lng: 18.1894878 },
    { lat: 48.9071653, lng: 18.1895635 },
    { lat: 48.9072581, lng: 18.1897467 },
    { lat: 48.9072671, lng: 18.1897638 },
    { lat: 48.9073176, lng: 18.1899178 },
    { lat: 48.9073728, lng: 18.190073 },
    { lat: 48.9073955, lng: 18.190135 },
    { lat: 48.9075791, lng: 18.1905024 },
    { lat: 48.90763, lng: 18.190603 },
    { lat: 48.9076352, lng: 18.1906152 },
    { lat: 48.9079004, lng: 18.1911656 },
    { lat: 48.9080938, lng: 18.1915662 },
    { lat: 48.9081052, lng: 18.1916074 },
    { lat: 48.9081284, lng: 18.191657 },
    { lat: 48.9081533, lng: 18.1918162 },
    { lat: 48.9085307, lng: 18.1927518 },
    { lat: 48.9085489, lng: 18.1928304 },
    { lat: 48.9085622, lng: 18.1928473 },
    { lat: 48.9085806, lng: 18.1928714 },
    { lat: 48.9085878, lng: 18.1928809 },
    { lat: 48.9086145, lng: 18.1929133 },
    { lat: 48.9086414, lng: 18.1930654 },
    { lat: 48.9086867, lng: 18.1932653 },
    { lat: 48.9087684, lng: 18.1936312 },
    { lat: 48.9087692, lng: 18.1937605 },
    { lat: 48.9087638, lng: 18.1938068 },
    { lat: 48.9087427, lng: 18.1938878 },
    { lat: 48.9087265, lng: 18.1939233 },
    { lat: 48.9087013, lng: 18.1939532 },
    { lat: 48.9086905, lng: 18.1939621 },
    { lat: 48.9086217, lng: 18.1940207 },
    { lat: 48.9086129, lng: 18.194028 },
    { lat: 48.9081078, lng: 18.1943701 },
    { lat: 48.9081256, lng: 18.1941845 },
    { lat: 48.9081093, lng: 18.1939994 },
    { lat: 48.9081016, lng: 18.1939071 },
    { lat: 48.9080862, lng: 18.1937373 },
    { lat: 48.9080627, lng: 18.1935823 },
    { lat: 48.9080462, lng: 18.1934897 },
    { lat: 48.907896, lng: 18.1928991 },
    { lat: 48.9078041, lng: 18.1926727 },
    { lat: 48.9077379, lng: 18.1925218 },
    { lat: 48.9076689, lng: 18.1923824 },
    { lat: 48.9075176, lng: 18.1920444 },
    { lat: 48.9074542, lng: 18.191845 },
    { lat: 48.9074164, lng: 18.1917969 },
    { lat: 48.9074145, lng: 18.1918001 },
    { lat: 48.907368, lng: 18.1917768 },
    { lat: 48.9073201, lng: 18.1917512 },
    { lat: 48.9069023, lng: 18.1911944 },
    { lat: 48.9067914, lng: 18.190972 },
    { lat: 48.9067663, lng: 18.191009 },
    { lat: 48.9067146, lng: 18.1909516 },
    { lat: 48.9066391, lng: 18.1908927 },
    { lat: 48.9065349, lng: 18.1908071 },
    { lat: 48.9065499, lng: 18.190756 },
    { lat: 48.9063602, lng: 18.1906562 },
    { lat: 48.9062051, lng: 18.1906279 },
    { lat: 48.9060756, lng: 18.1905345 },
    { lat: 48.9059907, lng: 18.1904514 },
    { lat: 48.9057735, lng: 18.1902638 },
    { lat: 48.9054286, lng: 18.1899772 },
    { lat: 48.9052761, lng: 18.189931 },
    { lat: 48.9051053, lng: 18.1899055 },
    { lat: 48.904818, lng: 18.1899344 },
    { lat: 48.9046512, lng: 18.1900357 },
    { lat: 48.9046645, lng: 18.1900899 },
    { lat: 48.9045549, lng: 18.1902242 },
    { lat: 48.9044045, lng: 18.1904546 },
    { lat: 48.9043608, lng: 18.1905379 },
    { lat: 48.9041198, lng: 18.1910593 },
    { lat: 48.9040925, lng: 18.1911156 },
    { lat: 48.9040647, lng: 18.1911587 },
    { lat: 48.9040606, lng: 18.1911643 },
    { lat: 48.9040521, lng: 18.1911776 },
    { lat: 48.904019, lng: 18.1912275 },
    { lat: 48.9040157, lng: 18.1912323 },
    { lat: 48.9037406, lng: 18.1916664 },
    { lat: 48.9037454, lng: 18.1916718 },
    { lat: 48.9036151, lng: 18.1918781 },
    { lat: 48.9034898, lng: 18.1920765 },
    { lat: 48.9034827, lng: 18.1920885 },
    { lat: 48.9032145, lng: 18.1925073 },
    { lat: 48.9031897, lng: 18.1925483 },
    { lat: 48.9027666, lng: 18.1932565 },
    { lat: 48.9026089, lng: 18.1934578 },
    { lat: 48.9025428, lng: 18.1936247 },
    { lat: 48.9025303, lng: 18.1936213 },
    { lat: 48.9023639, lng: 18.1940059 },
    { lat: 48.9021602, lng: 18.1944657 },
    { lat: 48.9021054, lng: 18.1945952 },
    { lat: 48.902092, lng: 18.1946171 },
    { lat: 48.9021878, lng: 18.1946909 },
    { lat: 48.9022878, lng: 18.1947863 },
    { lat: 48.9021935, lng: 18.195009 },
    { lat: 48.9020876, lng: 18.1952865 },
    { lat: 48.9019817, lng: 18.1953486 },
    { lat: 48.9019301, lng: 18.1954554 },
    { lat: 48.901869, lng: 18.1954481 },
    { lat: 48.9018562, lng: 18.1955029 },
    { lat: 48.9017464, lng: 18.1953976 },
    { lat: 48.9016507, lng: 18.1954392 },
    { lat: 48.9016104, lng: 18.1955249 },
    { lat: 48.9016442, lng: 18.195566 },
    { lat: 48.9016061, lng: 18.1956478 },
    { lat: 48.9015662, lng: 18.195619 },
    { lat: 48.9014412, lng: 18.1958801 },
    { lat: 48.9012134, lng: 18.1964527 },
    { lat: 48.9010428, lng: 18.1967633 },
    { lat: 48.9010119, lng: 18.1967361 },
    { lat: 48.900957, lng: 18.1968625 },
    { lat: 48.9009501, lng: 18.1968992 },
    { lat: 48.900929, lng: 18.1970641 },
    { lat: 48.9006932, lng: 18.196865 },
    { lat: 48.9006633, lng: 18.1968381 },
    { lat: 48.9006063, lng: 18.1967889 },
    { lat: 48.9005943, lng: 18.1967776 },
    { lat: 48.9007146, lng: 18.1964705 },
    { lat: 48.9007556, lng: 18.1963808 },
    { lat: 48.9008409, lng: 18.1962157 },
    { lat: 48.9009464, lng: 18.1960267 },
    { lat: 48.9012168, lng: 18.1955675 },
    { lat: 48.9012547, lng: 18.1955026 },
    { lat: 48.9015626, lng: 18.1949677 },
    { lat: 48.9018984, lng: 18.19437 },
    { lat: 48.9016863, lng: 18.1941523 },
    { lat: 48.9018285, lng: 18.1937979 },
    { lat: 48.9019676, lng: 18.1934492 },
    { lat: 48.9020486, lng: 18.1932498 },
    { lat: 48.902102, lng: 18.1931176 },
    { lat: 48.9021287, lng: 18.1930513 },
    { lat: 48.9022699, lng: 18.1927009 },
    { lat: 48.9023693, lng: 18.1924478 },
    { lat: 48.9021007, lng: 18.1922907 },
    { lat: 48.9022172, lng: 18.191903 },
    { lat: 48.9022193, lng: 18.1918977 },
    { lat: 48.9023348, lng: 18.1915116 },
    { lat: 48.9023583, lng: 18.1914307 },
    { lat: 48.9023592, lng: 18.191427 },
    { lat: 48.9023803, lng: 18.1913614 },
    { lat: 48.9024568, lng: 18.1914177 },
    { lat: 48.9024625, lng: 18.1913972 },
    { lat: 48.9025461, lng: 18.1911649 },
    { lat: 48.9025889, lng: 18.1910459 },
    { lat: 48.9026317, lng: 18.1909258 },
    { lat: 48.9026766, lng: 18.1908022 },
    { lat: 48.9029986, lng: 18.1899061 },
    { lat: 48.9030541, lng: 18.1897528 },
    { lat: 48.9030912, lng: 18.1896503 },
    { lat: 48.9031232, lng: 18.18956 },
    { lat: 48.9031631, lng: 18.1894486 },
    { lat: 48.9032, lng: 18.1893461 },
    { lat: 48.9032079, lng: 18.1893257 },
    { lat: 48.9030398, lng: 18.1891876 },
    { lat: 48.9029663, lng: 18.1891263 },
    { lat: 48.902688, lng: 18.1888983 },
    { lat: 48.9025341, lng: 18.1887713 },
    { lat: 48.9024168, lng: 18.1886723 },
    { lat: 48.9023878, lng: 18.188797 },
    { lat: 48.9022621, lng: 18.1887501 },
    { lat: 48.902141, lng: 18.1887041 },
    { lat: 48.9022432, lng: 18.1884202 },
    { lat: 48.902226, lng: 18.1884174 },
    { lat: 48.9022137, lng: 18.1883602 },
    { lat: 48.9022179, lng: 18.1883342 },
    { lat: 48.9022438, lng: 18.1880395 },
    { lat: 48.9022491, lng: 18.1879764 },
    { lat: 48.9023567, lng: 18.1875311 },
    { lat: 48.9024766, lng: 18.1868277 },
    { lat: 48.9024909, lng: 18.1866805 },
    { lat: 48.9025111, lng: 18.1864641 },
    { lat: 48.9026498, lng: 18.1863432 },
    { lat: 48.9026988, lng: 18.1862948 },
    { lat: 48.902795, lng: 18.1861996 },
    { lat: 48.9029771, lng: 18.1856396 },
    { lat: 48.9030173, lng: 18.1854134 },
    { lat: 48.9030726, lng: 18.1853182 },
    { lat: 48.9031381, lng: 18.185241 },
    { lat: 48.9032591, lng: 18.1851074 },
    { lat: 48.9033253, lng: 18.1850836 },
    { lat: 48.903526, lng: 18.1850194 },
    { lat: 48.9036138, lng: 18.1849974 },
    { lat: 48.9036606, lng: 18.184828 },
    { lat: 48.9035575, lng: 18.1846623 },
    { lat: 48.9035569, lng: 18.1845167 },
    { lat: 48.9036442, lng: 18.1842878 },
    { lat: 48.9037809, lng: 18.1841145 },
    { lat: 48.9039094, lng: 18.1840709 },
    { lat: 48.9040748, lng: 18.1841223 },
    { lat: 48.9041116, lng: 18.1841335 },
    { lat: 48.9044058, lng: 18.1843439 },
    { lat: 48.9045189, lng: 18.1839951 },
    { lat: 48.9047662, lng: 18.1837242 },
    { lat: 48.9048901, lng: 18.1836199 },
    { lat: 48.9049962, lng: 18.1835602 },
    { lat: 48.9050225, lng: 18.1835092 },
    { lat: 48.9051599, lng: 18.1833292 },
    { lat: 48.9051786, lng: 18.18332 },
    { lat: 48.9055382, lng: 18.1829205 },
    { lat: 48.9056402, lng: 18.1827736 },
    { lat: 48.9056931, lng: 18.1826741 },
    { lat: 48.9058866, lng: 18.1824867 },
    { lat: 48.9060034, lng: 18.1824576 },
    { lat: 48.9062542, lng: 18.1824428 },
    { lat: 48.906525, lng: 18.1820289 },
    { lat: 48.9066768, lng: 18.1818428 },
    { lat: 48.9067485, lng: 18.1816963 },
    { lat: 48.9067989, lng: 18.1815522 },
    { lat: 48.9068333, lng: 18.181246 },
    { lat: 48.9068067, lng: 18.1812161 },
    { lat: 48.906751, lng: 18.1811507 },
    { lat: 48.9067352, lng: 18.1811323 },
    { lat: 48.90656, lng: 18.1809302 },
    { lat: 48.9065443, lng: 18.1809129 },
    { lat: 48.9064658, lng: 18.1808218 },
    { lat: 48.9064624, lng: 18.1808205 },
    { lat: 48.906445, lng: 18.1808142 },
    { lat: 48.9057699, lng: 18.1805709 },
    { lat: 48.9052563, lng: 18.1801177 },
    { lat: 48.9046225, lng: 18.1797133 },
    { lat: 48.9046085, lng: 18.1797041 },
    { lat: 48.9039241, lng: 18.1792404 },
    { lat: 48.9034407, lng: 18.1790745 },
    { lat: 48.9028785, lng: 18.1785839 },
    { lat: 48.9026382, lng: 18.1781914 },
    { lat: 48.9023693, lng: 18.1777133 },
    { lat: 48.9016164, lng: 18.1768668 },
    { lat: 48.9012856, lng: 18.1764715 },
    { lat: 48.9012675, lng: 18.1764492 },
    { lat: 48.9012226, lng: 18.176396 },
    { lat: 48.901084, lng: 18.176259 },
    { lat: 48.9007903, lng: 18.1760195 },
    { lat: 48.9001883, lng: 18.1758155 },
    { lat: 48.8995545, lng: 18.1755249 },
    { lat: 48.8995301, lng: 18.1755135 },
    { lat: 48.899251, lng: 18.1755271 },
    { lat: 48.8990884, lng: 18.1757218 },
    { lat: 48.8984559, lng: 18.1761825 },
    { lat: 48.8978678, lng: 18.1769012 },
    { lat: 48.897372, lng: 18.1775445 },
    { lat: 48.8968396, lng: 18.1778215 },
    { lat: 48.8967079, lng: 18.1786107 },
    { lat: 48.896672, lng: 18.178761 },
    { lat: 48.8966259, lng: 18.178954 },
    { lat: 48.8965389, lng: 18.1797147 },
    { lat: 48.8964821, lng: 18.1802778 },
    { lat: 48.8964538, lng: 18.1805541 },
    { lat: 48.8963292, lng: 18.1812118 },
    { lat: 48.8959642, lng: 18.1817122 },
    { lat: 48.8957168, lng: 18.1820024 },
    { lat: 48.895792, lng: 18.182306 },
    { lat: 48.8955514, lng: 18.1822058 },
    { lat: 48.8954977, lng: 18.182843 },
    { lat: 48.8954449, lng: 18.1834546 },
    { lat: 48.8953449, lng: 18.1837759 },
    { lat: 48.8950533, lng: 18.1846944 },
    { lat: 48.8947917, lng: 18.1848531 },
    { lat: 48.8942908, lng: 18.1851108 },
    { lat: 48.893741, lng: 18.1852817 },
    { lat: 48.8936948, lng: 18.1853007 },
    { lat: 48.8935293, lng: 18.1853677 },
    { lat: 48.8934083, lng: 18.1855204 },
    { lat: 48.8933831, lng: 18.1855345 },
    { lat: 48.8933108, lng: 18.1855738 },
    { lat: 48.8929923, lng: 18.1857622 },
    { lat: 48.8927425, lng: 18.1858723 },
    { lat: 48.8921508, lng: 18.1860799 },
    { lat: 48.8918819, lng: 18.1864862 },
    { lat: 48.8911966, lng: 18.186992 },
    { lat: 48.890962, lng: 18.1872309 },
    { lat: 48.890317, lng: 18.1872767 },
    { lat: 48.8900348, lng: 18.1872745 },
    { lat: 48.8897172, lng: 18.1870303 },
    { lat: 48.8895208, lng: 18.1868934 },
    { lat: 48.8891943, lng: 18.186942 },
    { lat: 48.88838, lng: 18.1870502 },
    { lat: 48.8880225, lng: 18.1872316 },
    { lat: 48.8875909, lng: 18.1877493 },
    { lat: 48.8873961, lng: 18.1879558 },
    { lat: 48.8871015, lng: 18.1881506 },
    { lat: 48.8864907, lng: 18.1883998 },
    { lat: 48.8861172, lng: 18.1886736 },
    { lat: 48.8858183, lng: 18.1888141 },
    { lat: 48.8854649, lng: 18.1889677 },
    { lat: 48.8849543, lng: 18.1891928 },
    { lat: 48.8847983, lng: 18.1892887 },
    { lat: 48.8845616, lng: 18.1894853 },
    { lat: 48.8841689, lng: 18.1896834 },
    { lat: 48.8839146, lng: 18.189694 },
    { lat: 48.8835875, lng: 18.1895411 },
    { lat: 48.8829202, lng: 18.1903028 },
    { lat: 48.8831522, lng: 18.1906652 },
    { lat: 48.882648, lng: 18.1912989 },
    { lat: 48.8824839, lng: 18.1914665 },
    { lat: 48.8820838, lng: 18.1918502 },
    { lat: 48.8815174, lng: 18.1923014 },
    { lat: 48.8812848, lng: 18.1926325 },
    { lat: 48.881287, lng: 18.1927122 },
    { lat: 48.8812453, lng: 18.1927718 },
    { lat: 48.8812084, lng: 18.1928176 },
  ],
  PetrovaLehota: [
    { lat: 48.8812084, lng: 18.1928176 },
    { lat: 48.8812453, lng: 18.1927718 },
    { lat: 48.881287, lng: 18.1927122 },
    { lat: 48.8812848, lng: 18.1926325 },
    { lat: 48.8815174, lng: 18.1923014 },
    { lat: 48.8820838, lng: 18.1918502 },
    { lat: 48.8824839, lng: 18.1914665 },
    { lat: 48.882648, lng: 18.1912989 },
    { lat: 48.8831522, lng: 18.1906652 },
    { lat: 48.8829202, lng: 18.1903028 },
    { lat: 48.8835875, lng: 18.1895411 },
    { lat: 48.8839146, lng: 18.189694 },
    { lat: 48.8841689, lng: 18.1896834 },
    { lat: 48.8845616, lng: 18.1894853 },
    { lat: 48.8847983, lng: 18.1892887 },
    { lat: 48.8849543, lng: 18.1891928 },
    { lat: 48.8854649, lng: 18.1889677 },
    { lat: 48.8858183, lng: 18.1888141 },
    { lat: 48.8861172, lng: 18.1886736 },
    { lat: 48.8864907, lng: 18.1883998 },
    { lat: 48.8871015, lng: 18.1881506 },
    { lat: 48.8873961, lng: 18.1879558 },
    { lat: 48.8875909, lng: 18.1877493 },
    { lat: 48.8880225, lng: 18.1872316 },
    { lat: 48.88838, lng: 18.1870502 },
    { lat: 48.8891943, lng: 18.186942 },
    { lat: 48.8895208, lng: 18.1868934 },
    { lat: 48.8897172, lng: 18.1870303 },
    { lat: 48.8900348, lng: 18.1872745 },
    { lat: 48.890317, lng: 18.1872767 },
    { lat: 48.890962, lng: 18.1872309 },
    { lat: 48.8911966, lng: 18.186992 },
    { lat: 48.8918819, lng: 18.1864862 },
    { lat: 48.8921508, lng: 18.1860799 },
    { lat: 48.8927425, lng: 18.1858723 },
    { lat: 48.8929923, lng: 18.1857622 },
    { lat: 48.8933108, lng: 18.1855738 },
    { lat: 48.8933831, lng: 18.1855345 },
    { lat: 48.8934083, lng: 18.1855204 },
    { lat: 48.8935293, lng: 18.1853677 },
    { lat: 48.8936948, lng: 18.1853007 },
    { lat: 48.893741, lng: 18.1852817 },
    { lat: 48.8942908, lng: 18.1851108 },
    { lat: 48.8947917, lng: 18.1848531 },
    { lat: 48.8950533, lng: 18.1846944 },
    { lat: 48.8953449, lng: 18.1837759 },
    { lat: 48.8954449, lng: 18.1834546 },
    { lat: 48.8954977, lng: 18.182843 },
    { lat: 48.8955514, lng: 18.1822058 },
    { lat: 48.895792, lng: 18.182306 },
    { lat: 48.8955381, lng: 18.1820318 },
    { lat: 48.8953349, lng: 18.1818747 },
    { lat: 48.8950527, lng: 18.1817158 },
    { lat: 48.8945501, lng: 18.1815327 },
    { lat: 48.8941982, lng: 18.1813963 },
    { lat: 48.8941111, lng: 18.1813748 },
    { lat: 48.8937815, lng: 18.181313 },
    { lat: 48.8933469, lng: 18.1812098 },
    { lat: 48.8928964, lng: 18.181032 },
    { lat: 48.8928619, lng: 18.1810003 },
    { lat: 48.8924159, lng: 18.180563 },
    { lat: 48.8921824, lng: 18.1802686 },
    { lat: 48.8917078, lng: 18.1799229 },
    { lat: 48.8913027, lng: 18.1795519 },
    { lat: 48.8911082, lng: 18.1793474 },
    { lat: 48.8909777, lng: 18.1792169 },
    { lat: 48.8909457, lng: 18.1791914 },
    { lat: 48.8906409, lng: 18.178952 },
    { lat: 48.8905589, lng: 18.1788215 },
    { lat: 48.8905188, lng: 18.1787526 },
    { lat: 48.8904661, lng: 18.1786595 },
    { lat: 48.8904373, lng: 18.1786103 },
    { lat: 48.8903932, lng: 18.1785297 },
    { lat: 48.8896964, lng: 18.1774988 },
    { lat: 48.8894418, lng: 18.1770975 },
    { lat: 48.8890526, lng: 18.176666 },
    { lat: 48.8888477, lng: 18.176479 },
    { lat: 48.8886768, lng: 18.1763105 },
    { lat: 48.8885074, lng: 18.1760754 },
    { lat: 48.888101, lng: 18.1756208 },
    { lat: 48.8882305, lng: 18.1751249 },
    { lat: 48.8881993, lng: 18.1748182 },
    { lat: 48.8881283, lng: 18.1742908 },
    { lat: 48.8881164, lng: 18.1737929 },
    { lat: 48.8879687, lng: 18.1733997 },
    { lat: 48.8878844, lng: 18.1731918 },
    { lat: 48.8878337, lng: 18.1727011 },
    { lat: 48.8878317, lng: 18.1726863 },
    { lat: 48.887851, lng: 18.1722169 },
    { lat: 48.8878829, lng: 18.1713016 },
    { lat: 48.8878645, lng: 18.1704352 },
    { lat: 48.8878522, lng: 18.1702375 },
    { lat: 48.8876805, lng: 18.1695509 },
    { lat: 48.887416, lng: 18.1689742 },
    { lat: 48.8874072, lng: 18.1689571 },
    { lat: 48.8871885, lng: 18.1685311 },
    { lat: 48.8868843, lng: 18.1682085 },
    { lat: 48.8866607, lng: 18.1679308 },
    { lat: 48.8863182, lng: 18.1673508 },
    { lat: 48.8862047, lng: 18.1669689 },
    { lat: 48.8860863, lng: 18.1666569 },
    { lat: 48.8856986, lng: 18.1662117 },
    { lat: 48.8853645, lng: 18.1656259 },
    { lat: 48.884906, lng: 18.1650721 },
    { lat: 48.8848012, lng: 18.1651696 },
    { lat: 48.8847098, lng: 18.1651924 },
    { lat: 48.8845506, lng: 18.1651698 },
    { lat: 48.8843188, lng: 18.1651811 },
    { lat: 48.8841277, lng: 18.1651147 },
    { lat: 48.8839541, lng: 18.1650979 },
    { lat: 48.8837481, lng: 18.1651248 },
    { lat: 48.883429, lng: 18.1653242 },
    { lat: 48.8826371, lng: 18.165271 },
    { lat: 48.8825601, lng: 18.1652277 },
    { lat: 48.8823024, lng: 18.1649087 },
    { lat: 48.8820195, lng: 18.1647178 },
    { lat: 48.88178, lng: 18.1645179 },
    { lat: 48.8815964, lng: 18.1642486 },
    { lat: 48.8813214, lng: 18.1639997 },
    { lat: 48.8811993, lng: 18.1638423 },
    { lat: 48.881104, lng: 18.163637 },
    { lat: 48.8807658, lng: 18.1631304 },
    { lat: 48.8805609, lng: 18.1629245 },
    { lat: 48.8800372, lng: 18.1622977 },
    { lat: 48.8795481, lng: 18.1617003 },
    { lat: 48.8794623, lng: 18.1615457 },
    { lat: 48.8793696, lng: 18.1612099 },
    { lat: 48.879268, lng: 18.1610918 },
    { lat: 48.8791052, lng: 18.1608477 },
    { lat: 48.8789185, lng: 18.1600579 },
    { lat: 48.8786466, lng: 18.1595508 },
    { lat: 48.8784696, lng: 18.1588691 },
    { lat: 48.8783913, lng: 18.1586056 },
    { lat: 48.8783386, lng: 18.158319 },
    { lat: 48.8783016, lng: 18.157542 },
    { lat: 48.8781378, lng: 18.1564247 },
    { lat: 48.8781002, lng: 18.1557905 },
    { lat: 48.8780183, lng: 18.1553916 },
    { lat: 48.8777399, lng: 18.1550048 },
    { lat: 48.8774954, lng: 18.1541897 },
    { lat: 48.8771563, lng: 18.1529685 },
    { lat: 48.8770175, lng: 18.1526958 },
    { lat: 48.8764418, lng: 18.1525947 },
    { lat: 48.8760757, lng: 18.1522737 },
    { lat: 48.8758933, lng: 18.1521259 },
    { lat: 48.8755846, lng: 18.1519751 },
    { lat: 48.875138, lng: 18.1516092 },
    { lat: 48.8749407, lng: 18.1511449 },
    { lat: 48.8745039, lng: 18.1512782 },
    { lat: 48.8739937, lng: 18.1513743 },
    { lat: 48.873651, lng: 18.1513811 },
    { lat: 48.8729958, lng: 18.1515419 },
    { lat: 48.872373, lng: 18.1516041 },
    { lat: 48.8720321, lng: 18.1515228 },
    { lat: 48.8717684, lng: 18.1514894 },
    { lat: 48.87153, lng: 18.1515418 },
    { lat: 48.8713123, lng: 18.1515646 },
    { lat: 48.8710661, lng: 18.1514822 },
    { lat: 48.8709113, lng: 18.1513816 },
    { lat: 48.8706831, lng: 18.1513198 },
    { lat: 48.8702375, lng: 18.15138 },
    { lat: 48.870018, lng: 18.1513909 },
    { lat: 48.8697407, lng: 18.1515306 },
    { lat: 48.8695302, lng: 18.1516359 },
    { lat: 48.8692758, lng: 18.1518457 },
    { lat: 48.8688967, lng: 18.1518448 },
    { lat: 48.8682891, lng: 18.1519164 },
    { lat: 48.8678673, lng: 18.1520151 },
    { lat: 48.8677563, lng: 18.152399 },
    { lat: 48.8674777, lng: 18.152403 },
    { lat: 48.8670846, lng: 18.1525319 },
    { lat: 48.8665611, lng: 18.1529824 },
    { lat: 48.8663227, lng: 18.1532312 },
    { lat: 48.8655953, lng: 18.1538073 },
    { lat: 48.8651616, lng: 18.1540333 },
    { lat: 48.8645351, lng: 18.1542856 },
    { lat: 48.8643412, lng: 18.1540723 },
    { lat: 48.8642983, lng: 18.154074 },
    { lat: 48.8641187, lng: 18.1542279 },
    { lat: 48.8638239, lng: 18.1548473 },
    { lat: 48.8633721, lng: 18.1557993 },
    { lat: 48.8632565, lng: 18.1562791 },
    { lat: 48.8632551, lng: 18.156565 },
    { lat: 48.8632403, lng: 18.15662 },
    { lat: 48.8632028, lng: 18.1566952 },
    { lat: 48.8630778, lng: 18.1568935 },
    { lat: 48.862994, lng: 18.1570078 },
    { lat: 48.8628961, lng: 18.1570885 },
    { lat: 48.8627343, lng: 18.157277 },
    { lat: 48.8626629, lng: 18.1573306 },
    { lat: 48.8626032, lng: 18.1574094 },
    { lat: 48.862577, lng: 18.1574473 },
    { lat: 48.8625014, lng: 18.1575272 },
    { lat: 48.8624038, lng: 18.1576341 },
    { lat: 48.8623435, lng: 18.157703 },
    { lat: 48.8623243, lng: 18.1577235 },
    { lat: 48.8622922, lng: 18.1577532 },
    { lat: 48.8621706, lng: 18.1579123 },
    { lat: 48.8621392, lng: 18.1579749 },
    { lat: 48.8621229, lng: 18.1580068 },
    { lat: 48.8620189, lng: 18.1581392 },
    { lat: 48.8619288, lng: 18.1582576 },
    { lat: 48.8618842, lng: 18.158366 },
    { lat: 48.8618036, lng: 18.1585347 },
    { lat: 48.8617712, lng: 18.1585996 },
    { lat: 48.86169, lng: 18.1586602 },
    { lat: 48.8616467, lng: 18.1586716 },
    { lat: 48.8615267, lng: 18.1587435 },
    { lat: 48.8614549, lng: 18.1587691 },
    { lat: 48.8613236, lng: 18.1588374 },
    { lat: 48.861292, lng: 18.1588187 },
    { lat: 48.8611536, lng: 18.1588675 },
    { lat: 48.8611136, lng: 18.1588377 },
    { lat: 48.8609862, lng: 18.1588585 },
    { lat: 48.8608062, lng: 18.1588874 },
    { lat: 48.8607274, lng: 18.1589107 },
    { lat: 48.8606011, lng: 18.1589146 },
    { lat: 48.8604585, lng: 18.1589263 },
    { lat: 48.8602647, lng: 18.1589108 },
    { lat: 48.8601948, lng: 18.1589136 },
    { lat: 48.8601776, lng: 18.1589051 },
    { lat: 48.8601305, lng: 18.1588727 },
    { lat: 48.8600296, lng: 18.1588247 },
    { lat: 48.8600123, lng: 18.1588188 },
    { lat: 48.859826, lng: 18.1588561 },
    { lat: 48.8598277, lng: 18.1588863 },
    { lat: 48.8598299, lng: 18.1589046 },
    { lat: 48.8598416, lng: 18.1589887 },
    { lat: 48.8598468, lng: 18.1590411 },
    { lat: 48.8598745, lng: 18.1592266 },
    { lat: 48.860059, lng: 18.1603497 },
    { lat: 48.8602282, lng: 18.1615454 },
    { lat: 48.8599801, lng: 18.1615281 },
    { lat: 48.8596643, lng: 18.1620383 },
    { lat: 48.8593612, lng: 18.1622018 },
    { lat: 48.859041, lng: 18.1623621 },
    { lat: 48.8587144, lng: 18.1628444 },
    { lat: 48.8587673, lng: 18.1628438 },
    { lat: 48.8594289, lng: 18.1628669 },
    { lat: 48.8598694, lng: 18.1624663 },
    { lat: 48.8603444, lng: 18.1625793 },
    { lat: 48.8610547, lng: 18.1634486 },
    { lat: 48.8609064, lng: 18.1647928 },
    { lat: 48.8613388, lng: 18.1653451 },
    { lat: 48.8613618, lng: 18.16537 },
    { lat: 48.8609031, lng: 18.1668672 },
    { lat: 48.8607607, lng: 18.1672767 },
    { lat: 48.8606948, lng: 18.167599 },
    { lat: 48.8606598, lng: 18.1678362 },
    { lat: 48.8606451, lng: 18.1680292 },
    { lat: 48.8606495, lng: 18.1683217 },
    { lat: 48.8606767, lng: 18.1685966 },
    { lat: 48.8607745, lng: 18.1690012 },
    { lat: 48.8609628, lng: 18.1695074 },
    { lat: 48.8611604, lng: 18.1697855 },
    { lat: 48.8614324, lng: 18.1701967 },
    { lat: 48.8611478, lng: 18.1714253 },
    { lat: 48.8609974, lng: 18.1722641 },
    { lat: 48.8607843, lng: 18.1738767 },
    { lat: 48.8607124, lng: 18.1741919 },
    { lat: 48.8606812, lng: 18.174964 },
    { lat: 48.8607249, lng: 18.1758431 },
    { lat: 48.8604468, lng: 18.1768732 },
    { lat: 48.8603254, lng: 18.1770764 },
    { lat: 48.860097, lng: 18.177361 },
    { lat: 48.8600531, lng: 18.17746 },
    { lat: 48.8598748, lng: 18.1776782 },
    { lat: 48.8598253, lng: 18.1777184 },
    { lat: 48.8595826, lng: 18.1779139 },
    { lat: 48.8594076, lng: 18.1781259 },
    { lat: 48.8594068, lng: 18.1781697 },
    { lat: 48.8594037, lng: 18.1781981 },
    { lat: 48.8593478, lng: 18.1782054 },
    { lat: 48.8593227, lng: 18.1782206 },
    { lat: 48.859291, lng: 18.1782578 },
    { lat: 48.8592555, lng: 18.1783266 },
    { lat: 48.8591738, lng: 18.1784191 },
    { lat: 48.8591211, lng: 18.1784608 },
    { lat: 48.8589499, lng: 18.1785684 },
    { lat: 48.8588209, lng: 18.1787385 },
    { lat: 48.8586038, lng: 18.179099 },
    { lat: 48.8584969, lng: 18.1791844 },
    { lat: 48.8584109, lng: 18.1793173 },
    { lat: 48.8581768, lng: 18.1794858 },
    { lat: 48.8581256, lng: 18.179517 },
    { lat: 48.85776, lng: 18.1794961 },
    { lat: 48.8576435, lng: 18.1795706 },
    { lat: 48.8576204, lng: 18.1795633 },
    { lat: 48.8575783, lng: 18.1794771 },
    { lat: 48.857449, lng: 18.1795078 },
    { lat: 48.8573685, lng: 18.1796371 },
    { lat: 48.8572833, lng: 18.1796483 },
    { lat: 48.8572097, lng: 18.1797204 },
    { lat: 48.8570563, lng: 18.1796231 },
    { lat: 48.8568847, lng: 18.1796043 },
    { lat: 48.8568188, lng: 18.1796914 },
    { lat: 48.8566976, lng: 18.1797419 },
    { lat: 48.8565467, lng: 18.1796683 },
    { lat: 48.8564514, lng: 18.179582 },
    { lat: 48.8563877, lng: 18.1794936 },
    { lat: 48.8563199, lng: 18.179494 },
    { lat: 48.8562068, lng: 18.1794312 },
    { lat: 48.8561445, lng: 18.179467 },
    { lat: 48.8560591, lng: 18.1794335 },
    { lat: 48.8559634, lng: 18.1793558 },
    { lat: 48.8559368, lng: 18.1793457 },
    { lat: 48.8558513, lng: 18.179352 },
    { lat: 48.8557991, lng: 18.1793277 },
    { lat: 48.8557103, lng: 18.1793341 },
    { lat: 48.8556464, lng: 18.1792995 },
    { lat: 48.8555514, lng: 18.1791401 },
    { lat: 48.8554354, lng: 18.1791834 },
    { lat: 48.8554335, lng: 18.1791507 },
    { lat: 48.8554158, lng: 18.1789986 },
    { lat: 48.8548675, lng: 18.1792348 },
    { lat: 48.8545954, lng: 18.1795678 },
    { lat: 48.8542637, lng: 18.1801573 },
    { lat: 48.854019, lng: 18.1804742 },
    { lat: 48.8539231, lng: 18.1806313 },
    { lat: 48.8539291, lng: 18.1806738 },
    { lat: 48.8540356, lng: 18.180663 },
    { lat: 48.854154, lng: 18.1806839 },
    { lat: 48.8542066, lng: 18.1807511 },
    { lat: 48.8542327, lng: 18.1807566 },
    { lat: 48.8542392, lng: 18.1808077 },
    { lat: 48.8542365, lng: 18.18086 },
    { lat: 48.8533985, lng: 18.1818293 },
    { lat: 48.8531531, lng: 18.182072 },
    { lat: 48.8529343, lng: 18.1822937 },
    { lat: 48.8527626, lng: 18.1824662 },
    { lat: 48.8525542, lng: 18.1826291 },
    { lat: 48.8524766, lng: 18.1826931 },
    { lat: 48.8524108, lng: 18.1827455 },
    { lat: 48.8523108, lng: 18.1828487 },
    { lat: 48.8521274, lng: 18.183113 },
    { lat: 48.8520303, lng: 18.1831932 },
    { lat: 48.8520119, lng: 18.1832221 },
    { lat: 48.8519763, lng: 18.1832748 },
    { lat: 48.8519454, lng: 18.183324 },
    { lat: 48.851986, lng: 18.1833817 },
    { lat: 48.8520315, lng: 18.183428 },
    { lat: 48.8520675, lng: 18.1834666 },
    { lat: 48.8521237, lng: 18.1835219 },
    { lat: 48.8522141, lng: 18.1836218 },
    { lat: 48.8522423, lng: 18.18366 },
    { lat: 48.8523122, lng: 18.1837006 },
    { lat: 48.8524177, lng: 18.1837665 },
    { lat: 48.852557, lng: 18.1837757 },
    { lat: 48.8526683, lng: 18.1837854 },
    { lat: 48.8527453, lng: 18.1837921 },
    { lat: 48.8527747, lng: 18.183795 },
    { lat: 48.8528226, lng: 18.1838009 },
    { lat: 48.8529182, lng: 18.1838176 },
    { lat: 48.8529502, lng: 18.1838238 },
    { lat: 48.8529751, lng: 18.1838255 },
    { lat: 48.8530071, lng: 18.1838299 },
    { lat: 48.8530343, lng: 18.1838352 },
    { lat: 48.8530722, lng: 18.1838435 },
    { lat: 48.8530576, lng: 18.1839852 },
    { lat: 48.8528799, lng: 18.1856246 },
    { lat: 48.8528754, lng: 18.1856647 },
    { lat: 48.8529323, lng: 18.1856708 },
    { lat: 48.8530224, lng: 18.1857087 },
    { lat: 48.8530933, lng: 18.1857448 },
    { lat: 48.8531829, lng: 18.1857534 },
    { lat: 48.8532329, lng: 18.1857748 },
    { lat: 48.853378, lng: 18.1858076 },
    { lat: 48.8535805, lng: 18.1858378 },
    { lat: 48.8536247, lng: 18.1858406 },
    { lat: 48.8536868, lng: 18.1858026 },
    { lat: 48.853746, lng: 18.1858901 },
    { lat: 48.8538966, lng: 18.1858581 },
    { lat: 48.8539833, lng: 18.1858566 },
    { lat: 48.854052, lng: 18.1859329 },
    { lat: 48.854144, lng: 18.1859411 },
    { lat: 48.8542093, lng: 18.1859587 },
    { lat: 48.8542683, lng: 18.1859645 },
    { lat: 48.8543381, lng: 18.1859241 },
    { lat: 48.8543741, lng: 18.1859212 },
    { lat: 48.8545929, lng: 18.1859804 },
    { lat: 48.8547557, lng: 18.1860643 },
    { lat: 48.8548077, lng: 18.1860642 },
    { lat: 48.8549205, lng: 18.1861622 },
    { lat: 48.8550427, lng: 18.1862274 },
    { lat: 48.8552025, lng: 18.186354 },
    { lat: 48.8552844, lng: 18.1864051 },
    { lat: 48.8553821, lng: 18.1864965 },
    { lat: 48.8555115, lng: 18.1866281 },
    { lat: 48.8556761, lng: 18.1867609 },
    { lat: 48.8558372, lng: 18.1869148 },
    { lat: 48.855868, lng: 18.1869817 },
    { lat: 48.8558846, lng: 18.1869534 },
    { lat: 48.8559196, lng: 18.1868756 },
    { lat: 48.8562599, lng: 18.1873406 },
    { lat: 48.8566168, lng: 18.1878361 },
    { lat: 48.856905, lng: 18.186762 },
    { lat: 48.856947, lng: 18.1867891 },
    { lat: 48.856974, lng: 18.186806 },
    { lat: 48.8569894, lng: 18.1868183 },
    { lat: 48.8570197, lng: 18.1868365 },
    { lat: 48.8575587, lng: 18.1872004 },
    { lat: 48.8582776, lng: 18.1875384 },
    { lat: 48.858401, lng: 18.1875844 },
    { lat: 48.858469, lng: 18.1876066 },
    { lat: 48.8590009, lng: 18.1877941 },
    { lat: 48.8596297, lng: 18.1879917 },
    { lat: 48.8599952, lng: 18.1880538 },
    { lat: 48.8605593, lng: 18.1881306 },
    { lat: 48.8607394, lng: 18.1881208 },
    { lat: 48.8610318, lng: 18.1880822 },
    { lat: 48.8616067, lng: 18.187935 },
    { lat: 48.8618728, lng: 18.1878343 },
    { lat: 48.8621984, lng: 18.1874516 },
    { lat: 48.8623538, lng: 18.1873286 },
    { lat: 48.8625757, lng: 18.1873011 },
    { lat: 48.8629749, lng: 18.1874172 },
    { lat: 48.8633291, lng: 18.1874543 },
    { lat: 48.8632099, lng: 18.1880513 },
    { lat: 48.8630503, lng: 18.1883753 },
    { lat: 48.8630678, lng: 18.1883837 },
    { lat: 48.8630487, lng: 18.1885041 },
    { lat: 48.8630024, lng: 18.188521 },
    { lat: 48.8629513, lng: 18.1885747 },
    { lat: 48.862821, lng: 18.1889417 },
    { lat: 48.862699, lng: 18.1890359 },
    { lat: 48.8625519, lng: 18.1893653 },
    { lat: 48.8626297, lng: 18.1893812 },
    { lat: 48.8626687, lng: 18.1893936 },
    { lat: 48.8628431, lng: 18.1894244 },
    { lat: 48.8629907, lng: 18.1894207 },
    { lat: 48.8631327, lng: 18.1893775 },
    { lat: 48.8633251, lng: 18.1892912 },
    { lat: 48.863571, lng: 18.1891703 },
    { lat: 48.8638365, lng: 18.1890377 },
    { lat: 48.8638868, lng: 18.1890293 },
    { lat: 48.8644383, lng: 18.1887673 },
    { lat: 48.8645582, lng: 18.1887339 },
    { lat: 48.8646633, lng: 18.1887566 },
    { lat: 48.8650533, lng: 18.1888657 },
    { lat: 48.865442, lng: 18.1889764 },
    { lat: 48.8654934, lng: 18.1890058 },
    { lat: 48.8657005, lng: 18.1890551 },
    { lat: 48.8660768, lng: 18.1891238 },
    { lat: 48.8661852, lng: 18.1891446 },
    { lat: 48.8663549, lng: 18.1891727 },
    { lat: 48.866388, lng: 18.1891777 },
    { lat: 48.8664336, lng: 18.1891849 },
    { lat: 48.8664609, lng: 18.1891903 },
    { lat: 48.8665088, lng: 18.1891965 },
    { lat: 48.8665405, lng: 18.1892009 },
    { lat: 48.8668147, lng: 18.1892411 },
    { lat: 48.8668649, lng: 18.1892502 },
    { lat: 48.8669047, lng: 18.1892554 },
    { lat: 48.8669677, lng: 18.1892538 },
    { lat: 48.8670893, lng: 18.1892468 },
    { lat: 48.8672784, lng: 18.1892376 },
    { lat: 48.8673119, lng: 18.1892321 },
    { lat: 48.8673386, lng: 18.1892278 },
    { lat: 48.8675078, lng: 18.1892055 },
    { lat: 48.8675111, lng: 18.1892051 },
    { lat: 48.8675983, lng: 18.1891921 },
    { lat: 48.8676882, lng: 18.1891835 },
    { lat: 48.8677408, lng: 18.1891783 },
    { lat: 48.8677961, lng: 18.1891778 },
    { lat: 48.8678921, lng: 18.1891777 },
    { lat: 48.8682834, lng: 18.189173 },
    { lat: 48.868451, lng: 18.1891839 },
    { lat: 48.8686486, lng: 18.1892241 },
    { lat: 48.8688435, lng: 18.1892787 },
    { lat: 48.8690001, lng: 18.1893125 },
    { lat: 48.8691372, lng: 18.1893442 },
    { lat: 48.8693621, lng: 18.1893497 },
    { lat: 48.8693898, lng: 18.1893417 },
    { lat: 48.8694498, lng: 18.1893241 },
    { lat: 48.869624, lng: 18.1892732 },
    { lat: 48.8696811, lng: 18.1892642 },
    { lat: 48.8697259, lng: 18.1892572 },
    { lat: 48.8698477, lng: 18.1892375 },
    { lat: 48.8700395, lng: 18.1892373 },
    { lat: 48.870052, lng: 18.1892371 },
    { lat: 48.8701252, lng: 18.1892561 },
    { lat: 48.8702776, lng: 18.1892955 },
    { lat: 48.8704503, lng: 18.1893171 },
    { lat: 48.8706404, lng: 18.1892888 },
    { lat: 48.8708219, lng: 18.1892244 },
    { lat: 48.8710335, lng: 18.1891179 },
    { lat: 48.8711689, lng: 18.1890588 },
    { lat: 48.8712938, lng: 18.1889567 },
    { lat: 48.8713625, lng: 18.1888555 },
    { lat: 48.8714681, lng: 18.1887469 },
    { lat: 48.871585, lng: 18.1886616 },
    { lat: 48.8717067, lng: 18.1886187 },
    { lat: 48.8718251, lng: 18.1885779 },
    { lat: 48.8719672, lng: 18.1884785 },
    { lat: 48.872119, lng: 18.1884502 },
    { lat: 48.8722665, lng: 18.188482 },
    { lat: 48.8724394, lng: 18.1885646 },
    { lat: 48.8726245, lng: 18.188605 },
    { lat: 48.8730686, lng: 18.1887744 },
    { lat: 48.8735138, lng: 18.1889798 },
    { lat: 48.8736733, lng: 18.1890849 },
    { lat: 48.8737622, lng: 18.1891786 },
    { lat: 48.8739036, lng: 18.1893234 },
    { lat: 48.874003, lng: 18.189444 },
    { lat: 48.874049, lng: 18.1894996 },
    { lat: 48.874055, lng: 18.1895063 },
    { lat: 48.8743601, lng: 18.1899227 },
    { lat: 48.8743906, lng: 18.1899642 },
    { lat: 48.8744238, lng: 18.1900104 },
    { lat: 48.8746197, lng: 18.1898479 },
    { lat: 48.8746825, lng: 18.1898597 },
    { lat: 48.8744297, lng: 18.1901351 },
    { lat: 48.8744307, lng: 18.1901521 },
    { lat: 48.8745134, lng: 18.1902376 },
    { lat: 48.8746561, lng: 18.1902633 },
    { lat: 48.8746746, lng: 18.1902938 },
    { lat: 48.8746679, lng: 18.190432 },
    { lat: 48.874713, lng: 18.1906461 },
    { lat: 48.8748303, lng: 18.1907242 },
    { lat: 48.874871, lng: 18.1907077 },
    { lat: 48.8749252, lng: 18.1906264 },
    { lat: 48.874924, lng: 18.1905688 },
    { lat: 48.874867, lng: 18.1905183 },
    { lat: 48.8748507, lng: 18.1904326 },
    { lat: 48.8748993, lng: 18.190353 },
    { lat: 48.8750546, lng: 18.1903856 },
    { lat: 48.8751934, lng: 18.1903476 },
    { lat: 48.8751402, lng: 18.1902449 },
    { lat: 48.8751524, lng: 18.1901823 },
    { lat: 48.8752, lng: 18.1901284 },
    { lat: 48.8753227, lng: 18.1900835 },
    { lat: 48.8754352, lng: 18.1900991 },
    { lat: 48.8755704, lng: 18.1900727 },
    { lat: 48.875584, lng: 18.18992 },
    { lat: 48.8756505, lng: 18.1897603 },
    { lat: 48.8757059, lng: 18.1897401 },
    { lat: 48.8759817, lng: 18.1898277 },
    { lat: 48.8760186, lng: 18.1897429 },
    { lat: 48.8760469, lng: 18.1897449 },
    { lat: 48.8761222, lng: 18.189816 },
    { lat: 48.8762043, lng: 18.1898704 },
    { lat: 48.8762818, lng: 18.1898659 },
    { lat: 48.8763194, lng: 18.1899322 },
    { lat: 48.876359, lng: 18.1901679 },
    { lat: 48.8765307, lng: 18.1903872 },
    { lat: 48.8766845, lng: 18.1904347 },
    { lat: 48.8769552, lng: 18.190352 },
    { lat: 48.8771004, lng: 18.1903687 },
    { lat: 48.8772369, lng: 18.1905052 },
    { lat: 48.8774284, lng: 18.1905746 },
    { lat: 48.8775312, lng: 18.1906391 },
    { lat: 48.8776276, lng: 18.1908035 },
    { lat: 48.8776197, lng: 18.1908999 },
    { lat: 48.8776585, lng: 18.1909507 },
    { lat: 48.8777116, lng: 18.1909484 },
    { lat: 48.8778339, lng: 18.1910358 },
    { lat: 48.877946, lng: 18.1910447 },
    { lat: 48.8781132, lng: 18.1912632 },
    { lat: 48.8781054, lng: 18.1913832 },
    { lat: 48.878186, lng: 18.1914891 },
    { lat: 48.8783154, lng: 18.1914616 },
    { lat: 48.878349, lng: 18.1913227 },
    { lat: 48.8783828, lng: 18.1913007 },
    { lat: 48.8784911, lng: 18.1913789 },
    { lat: 48.8785121, lng: 18.1915475 },
    { lat: 48.8784963, lng: 18.1916267 },
    { lat: 48.878517, lng: 18.1916903 },
    { lat: 48.8785504, lng: 18.1917622 },
    { lat: 48.8786362, lng: 18.1918018 },
    { lat: 48.8787682, lng: 18.1917819 },
    { lat: 48.8791437, lng: 18.1918363 },
    { lat: 48.8792945, lng: 18.1917906 },
    { lat: 48.8795704, lng: 18.1918797 },
    { lat: 48.879723, lng: 18.1918652 },
    { lat: 48.8799286, lng: 18.1917897 },
    { lat: 48.8801409, lng: 18.191813 },
    { lat: 48.8803275, lng: 18.1919576 },
    { lat: 48.8804741, lng: 18.1919945 },
    { lat: 48.8806349, lng: 18.1919869 },
    { lat: 48.8807664, lng: 18.1921155 },
    { lat: 48.8808542, lng: 18.1922078 },
    { lat: 48.8809099, lng: 18.1921973 },
    { lat: 48.8810118, lng: 18.1920447 },
    { lat: 48.8810644, lng: 18.1920593 },
    { lat: 48.8811106, lng: 18.192155 },
    { lat: 48.8811171, lng: 18.1923463 },
    { lat: 48.881157, lng: 18.1925895 },
    { lat: 48.8812084, lng: 18.1928176 },
  ],
  Svinná: [
    { lat: 48.806069, lng: 18.156608 },
    { lat: 48.805949, lng: 18.1565981 },
    { lat: 48.8057984, lng: 18.1564552 },
    { lat: 48.805409, lng: 18.1560027 },
    { lat: 48.8050174, lng: 18.155494 },
    { lat: 48.8046999, lng: 18.1550585 },
    { lat: 48.8046742, lng: 18.1550221 },
    { lat: 48.8044199, lng: 18.1544519 },
    { lat: 48.8042822, lng: 18.1542181 },
    { lat: 48.8040106, lng: 18.1536964 },
    { lat: 48.8038337, lng: 18.1534066 },
    { lat: 48.8036425, lng: 18.1530278 },
    { lat: 48.8035652, lng: 18.1528633 },
    { lat: 48.8033399, lng: 18.1524604 },
    { lat: 48.8031356, lng: 18.1520479 },
    { lat: 48.802966, lng: 18.1516909 },
    { lat: 48.802748, lng: 18.1513364 },
    { lat: 48.8027186, lng: 18.1512984 },
    { lat: 48.8026903, lng: 18.1512589 },
    { lat: 48.8024207, lng: 18.1506366 },
    { lat: 48.8024126, lng: 18.1506112 },
    { lat: 48.8024004, lng: 18.150596 },
    { lat: 48.8023801, lng: 18.1505554 },
    { lat: 48.8023737, lng: 18.1505427 },
    { lat: 48.8023623, lng: 18.1505213 },
    { lat: 48.8021685, lng: 18.1501764 },
    { lat: 48.8019847, lng: 18.1498475 },
    { lat: 48.8015788, lng: 18.1491514 },
    { lat: 48.8013903, lng: 18.1487998 },
    { lat: 48.8012445, lng: 18.1485435 },
    { lat: 48.8011317, lng: 18.148368 },
    { lat: 48.8007846, lng: 18.147871 },
    { lat: 48.8004443, lng: 18.1473509 },
    { lat: 48.8002923, lng: 18.1469476 },
    { lat: 48.8001875, lng: 18.1466369 },
    { lat: 48.8001536, lng: 18.1465207 },
    { lat: 48.7999888, lng: 18.1460146 },
    { lat: 48.7998893, lng: 18.1457175 },
    { lat: 48.7996246, lng: 18.1451802 },
    { lat: 48.79944, lng: 18.1449169 },
    { lat: 48.7990264, lng: 18.1443776 },
    { lat: 48.7989302, lng: 18.1442561 },
    { lat: 48.7988082, lng: 18.1441005 },
    { lat: 48.7987653, lng: 18.1440425 },
    { lat: 48.7987171, lng: 18.1439852 },
    { lat: 48.7985727, lng: 18.1437956 },
    { lat: 48.7984802, lng: 18.1436955 },
    { lat: 48.798304, lng: 18.1437901 },
    { lat: 48.798047, lng: 18.1439341 },
    { lat: 48.7980039, lng: 18.1439692 },
    { lat: 48.7977385, lng: 18.1441956 },
    { lat: 48.7973241, lng: 18.1445623 },
    { lat: 48.7967545, lng: 18.1450563 },
    { lat: 48.7964513, lng: 18.1453947 },
    { lat: 48.7958089, lng: 18.1461281 },
    { lat: 48.7957013, lng: 18.146258 },
    { lat: 48.7953028, lng: 18.145711 },
    { lat: 48.7949845, lng: 18.1452976 },
    { lat: 48.7948699, lng: 18.1453282 },
    { lat: 48.7945066, lng: 18.145425 },
    { lat: 48.7940894, lng: 18.1455498 },
    { lat: 48.7940011, lng: 18.1455848 },
    { lat: 48.7938297, lng: 18.1455846 },
    { lat: 48.7932546, lng: 18.145614 },
    { lat: 48.7933002, lng: 18.1453474 },
    { lat: 48.7934755, lng: 18.1444588 },
    { lat: 48.7934381, lng: 18.1443975 },
    { lat: 48.7933942, lng: 18.1443207 },
    { lat: 48.7933508, lng: 18.1442534 },
    { lat: 48.7930025, lng: 18.1436539 },
    { lat: 48.7925545, lng: 18.14346 },
    { lat: 48.7925191, lng: 18.1434328 },
    { lat: 48.7924944, lng: 18.1434182 },
    { lat: 48.7924557, lng: 18.1434123 },
    { lat: 48.7924866, lng: 18.143284 },
    { lat: 48.7926578, lng: 18.1425569 },
    { lat: 48.7927326, lng: 18.1422682 },
    { lat: 48.7927667, lng: 18.1421394 },
    { lat: 48.7930042, lng: 18.1411938 },
    { lat: 48.7930201, lng: 18.1410553 },
    { lat: 48.7931842, lng: 18.1403825 },
    { lat: 48.7932401, lng: 18.1401439 },
    { lat: 48.7934691, lng: 18.1391865 },
    { lat: 48.7936006, lng: 18.1387291 },
    { lat: 48.7936438, lng: 18.1385981 },
    { lat: 48.793677, lng: 18.1384885 },
    { lat: 48.7938468, lng: 18.1380891 },
    { lat: 48.794198, lng: 18.1372586 },
    { lat: 48.7941123, lng: 18.1371622 },
    { lat: 48.7933112, lng: 18.1362672 },
    { lat: 48.7928973, lng: 18.135805 },
    { lat: 48.7928705, lng: 18.135771 },
    { lat: 48.7927225, lng: 18.1355927 },
    { lat: 48.7914436, lng: 18.1342254 },
    { lat: 48.7914135, lng: 18.1341915 },
    { lat: 48.7913612, lng: 18.134129 },
    { lat: 48.7913383, lng: 18.1341023 },
    { lat: 48.7905059, lng: 18.133393 },
    { lat: 48.7901948, lng: 18.1331251 },
    { lat: 48.7898618, lng: 18.1328509 },
    { lat: 48.7895893, lng: 18.1326251 },
    { lat: 48.7893505, lng: 18.1324231 },
    { lat: 48.7891786, lng: 18.1328218 },
    { lat: 48.7888437, lng: 18.1336246 },
    { lat: 48.7887148, lng: 18.1339331 },
    { lat: 48.788467, lng: 18.134525 },
    { lat: 48.7883394, lng: 18.134853 },
    { lat: 48.7880788, lng: 18.1355174 },
    { lat: 48.78801, lng: 18.13579 },
    { lat: 48.7879842, lng: 18.1359283 },
    { lat: 48.7879714, lng: 18.135997 },
    { lat: 48.7878004, lng: 18.1369018 },
    { lat: 48.787792, lng: 18.1369531 },
    { lat: 48.7877802, lng: 18.1370223 },
    { lat: 48.7877616, lng: 18.137146 },
    { lat: 48.7876342, lng: 18.1373068 },
    { lat: 48.7875406, lng: 18.1374255 },
    { lat: 48.787506, lng: 18.1373922 },
    { lat: 48.7874891, lng: 18.1373737 },
    { lat: 48.7874736, lng: 18.1373586 },
    { lat: 48.7873728, lng: 18.1372557 },
    { lat: 48.7861165, lng: 18.1374808 },
    { lat: 48.7859331, lng: 18.1374912 },
    { lat: 48.7857972, lng: 18.1375218 },
    { lat: 48.7856828, lng: 18.1374368 },
    { lat: 48.7854749, lng: 18.13722 },
    { lat: 48.7852312, lng: 18.137062 },
    { lat: 48.7846102, lng: 18.136769 },
    { lat: 48.7843972, lng: 18.1365776 },
    { lat: 48.7840671, lng: 18.1377161 },
    { lat: 48.7837553, lng: 18.138762 },
    { lat: 48.7833834, lng: 18.1383802 },
    { lat: 48.7826878, lng: 18.1377085 },
    { lat: 48.7822363, lng: 18.1372997 },
    { lat: 48.7821955, lng: 18.1379575 },
    { lat: 48.7821351, lng: 18.1386289 },
    { lat: 48.7819646, lng: 18.1387636 },
    { lat: 48.7819044, lng: 18.1388134 },
    { lat: 48.7820081, lng: 18.1392809 },
    { lat: 48.7821057, lng: 18.1400309 },
    { lat: 48.7820255, lng: 18.1405357 },
    { lat: 48.7819438, lng: 18.1408968 },
    { lat: 48.7817617, lng: 18.1416671 },
    { lat: 48.7817043, lng: 18.1419216 },
    { lat: 48.7816899, lng: 18.1420656 },
    { lat: 48.7816333, lng: 18.1428392 },
    { lat: 48.7816305, lng: 18.1431419 },
    { lat: 48.7816464, lng: 18.1433173 },
    { lat: 48.7817979, lng: 18.1438708 },
    { lat: 48.7818068, lng: 18.1443566 },
    { lat: 48.7817442, lng: 18.1448133 },
    { lat: 48.7816944, lng: 18.1452404 },
    { lat: 48.781668, lng: 18.1454829 },
    { lat: 48.7816529, lng: 18.1455568 },
    { lat: 48.7814977, lng: 18.1463442 },
    { lat: 48.7814444, lng: 18.1466118 },
    { lat: 48.7809458, lng: 18.1466335 },
    { lat: 48.7808004, lng: 18.1465608 },
    { lat: 48.7805658, lng: 18.1466016 },
    { lat: 48.7803425, lng: 18.1467382 },
    { lat: 48.7802974, lng: 18.1467599 },
    { lat: 48.7801014, lng: 18.1469409 },
    { lat: 48.7797907, lng: 18.1468393 },
    { lat: 48.7797206, lng: 18.1468164 },
    { lat: 48.7796288, lng: 18.1467863 },
    { lat: 48.7795601, lng: 18.1468087 },
    { lat: 48.7791775, lng: 18.1469492 },
    { lat: 48.7787691, lng: 18.1471028 },
    { lat: 48.7787351, lng: 18.1471614 },
    { lat: 48.7786968, lng: 18.1472394 },
    { lat: 48.7786544, lng: 18.1473238 },
    { lat: 48.778622, lng: 18.1473882 },
    { lat: 48.7788903, lng: 18.1478532 },
    { lat: 48.7789341, lng: 18.1479272 },
    { lat: 48.7789441, lng: 18.1479452 },
    { lat: 48.7786824, lng: 18.1481208 },
    { lat: 48.7783056, lng: 18.1483769 },
    { lat: 48.7779776, lng: 18.1485975 },
    { lat: 48.7779689, lng: 18.1486029 },
    { lat: 48.7779421, lng: 18.1486247 },
    { lat: 48.7778904, lng: 18.1486509 },
    { lat: 48.7775554, lng: 18.1487708 },
    { lat: 48.7768649, lng: 18.1490237 },
    { lat: 48.7770692, lng: 18.1498038 },
    { lat: 48.7771356, lng: 18.150175 },
    { lat: 48.7771596, lng: 18.1504505 },
    { lat: 48.7771337, lng: 18.1505867 },
    { lat: 48.7770149, lng: 18.1507566 },
    { lat: 48.7770103, lng: 18.1512614 },
    { lat: 48.7768357, lng: 18.1515629 },
    { lat: 48.7774003, lng: 18.1535389 },
    { lat: 48.777315, lng: 18.1537198 },
    { lat: 48.7768099, lng: 18.1528493 },
    { lat: 48.7764405, lng: 18.1531963 },
    { lat: 48.776253, lng: 18.1533106 },
    { lat: 48.7761424, lng: 18.1533853 },
    { lat: 48.7758791, lng: 18.1530277 },
    { lat: 48.7757596, lng: 18.1531502 },
    { lat: 48.7756469, lng: 18.1532495 },
    { lat: 48.7757753, lng: 18.1536941 },
    { lat: 48.7755539, lng: 18.1537874 },
    { lat: 48.775504, lng: 18.1538048 },
    { lat: 48.7752099, lng: 18.1540061 },
    { lat: 48.7750591, lng: 18.1541091 },
    { lat: 48.774662, lng: 18.153388 },
    { lat: 48.774535, lng: 18.153591 },
    { lat: 48.774597, lng: 18.153847 },
    { lat: 48.774631, lng: 18.153988 },
    { lat: 48.77463, lng: 18.154007 },
    { lat: 48.774578, lng: 18.154101 },
    { lat: 48.774569, lng: 18.154118 },
    { lat: 48.774505, lng: 18.154148 },
    { lat: 48.774424, lng: 18.154187 },
    { lat: 48.774342, lng: 18.154226 },
    { lat: 48.774269, lng: 18.154261 },
    { lat: 48.774176, lng: 18.154306 },
    { lat: 48.774108, lng: 18.154338 },
    { lat: 48.774349, lng: 18.154885 },
    { lat: 48.774598, lng: 18.15545 },
    { lat: 48.774809, lng: 18.155931 },
    { lat: 48.774843, lng: 18.156008 },
    { lat: 48.77485, lng: 18.156024 },
    { lat: 48.774865, lng: 18.156052 },
    { lat: 48.774959, lng: 18.156249 },
    { lat: 48.775161, lng: 18.15666 },
    { lat: 48.775378, lng: 18.157096 },
    { lat: 48.77521, lng: 18.15716 },
    { lat: 48.775078, lng: 18.15721 },
    { lat: 48.775011, lng: 18.157315 },
    { lat: 48.774945, lng: 18.157418 },
    { lat: 48.774818, lng: 18.157615 },
    { lat: 48.774741, lng: 18.15773 },
    { lat: 48.774657, lng: 18.157855 },
    { lat: 48.774639, lng: 18.157859 },
    { lat: 48.774473, lng: 18.157893 },
    { lat: 48.774253, lng: 18.158022 },
    { lat: 48.773928, lng: 18.15841 },
    { lat: 48.773768, lng: 18.158184 },
    { lat: 48.773662, lng: 18.158037 },
    { lat: 48.773545, lng: 18.157871 },
    { lat: 48.773306, lng: 18.158147 },
    { lat: 48.773611, lng: 18.158882 },
    { lat: 48.773126, lng: 18.159199 },
    { lat: 48.772857, lng: 18.159545 },
    { lat: 48.772723, lng: 18.159797 },
    { lat: 48.77292, lng: 18.160179 },
    { lat: 48.772955, lng: 18.160248 },
    { lat: 48.773598, lng: 18.16151 },
    { lat: 48.773693, lng: 18.161697 },
    { lat: 48.773909, lng: 18.162116 },
    { lat: 48.77401, lng: 18.162297 },
    { lat: 48.774088, lng: 18.162441 },
    { lat: 48.774231, lng: 18.162701 },
    { lat: 48.774316, lng: 18.162872 },
    { lat: 48.77433, lng: 18.162888 },
    { lat: 48.774335, lng: 18.162904 },
    { lat: 48.774932, lng: 18.163931 },
    { lat: 48.776422, lng: 18.166348 },
    { lat: 48.775951, lng: 18.167904 },
    { lat: 48.775919, lng: 18.16801 },
    { lat: 48.775879, lng: 18.168107 },
    { lat: 48.775867, lng: 18.168141 },
    { lat: 48.775734, lng: 18.168481 },
    { lat: 48.775724, lng: 18.168506 },
    { lat: 48.7757, lng: 18.16856 },
    { lat: 48.775541, lng: 18.168921 },
    { lat: 48.775336, lng: 18.169387 },
    { lat: 48.775249, lng: 18.169582 },
    { lat: 48.775219, lng: 18.169649 },
    { lat: 48.77519, lng: 18.169716 },
    { lat: 48.775142, lng: 18.169825 },
    { lat: 48.775016, lng: 18.169964 },
    { lat: 48.774841, lng: 18.170158 },
    { lat: 48.774781, lng: 18.170064 },
    { lat: 48.774735, lng: 18.169992 },
    { lat: 48.774143, lng: 18.169049 },
    { lat: 48.774055, lng: 18.168846 },
    { lat: 48.773749, lng: 18.167722 },
    { lat: 48.773527, lng: 18.167248 },
    { lat: 48.77354, lng: 18.167162 },
    { lat: 48.773566, lng: 18.166998 },
    { lat: 48.77358, lng: 18.166909 },
    { lat: 48.773594, lng: 18.166816 },
    { lat: 48.773593, lng: 18.166789 },
    { lat: 48.773566, lng: 18.166459 },
    { lat: 48.773493, lng: 18.166348 },
    { lat: 48.773444, lng: 18.166536 },
    { lat: 48.773432, lng: 18.166582 },
    { lat: 48.773435, lng: 18.166667 },
    { lat: 48.77344, lng: 18.16679 },
    { lat: 48.773446, lng: 18.166906 },
    { lat: 48.773427, lng: 18.166976 },
    { lat: 48.773382, lng: 18.167143 },
    { lat: 48.773378, lng: 18.16725 },
    { lat: 48.773369, lng: 18.167477 },
    { lat: 48.773301, lng: 18.167525 },
    { lat: 48.773224, lng: 18.167468 },
    { lat: 48.772965, lng: 18.16728 },
    { lat: 48.772858, lng: 18.167334 },
    { lat: 48.772662, lng: 18.167326 },
    { lat: 48.7724, lng: 18.166937 },
    { lat: 48.77199, lng: 18.167448 },
    { lat: 48.771948, lng: 18.167443 },
    { lat: 48.771852, lng: 18.167428 },
    { lat: 48.771833, lng: 18.167589 },
    { lat: 48.771699, lng: 18.167772 },
    { lat: 48.771807, lng: 18.168016 },
    { lat: 48.771873, lng: 18.168039 },
    { lat: 48.771946, lng: 18.168064 },
    { lat: 48.772061, lng: 18.168252 },
    { lat: 48.772602, lng: 18.167732 },
    { lat: 48.773041, lng: 18.168822 },
    { lat: 48.773239, lng: 18.169227 },
    { lat: 48.773007, lng: 18.169701 },
    { lat: 48.772286, lng: 18.170443 },
    { lat: 48.771948, lng: 18.170709 },
    { lat: 48.771504, lng: 18.171059 },
    { lat: 48.771442, lng: 18.171147 },
    { lat: 48.771126, lng: 18.171583 },
    { lat: 48.771035, lng: 18.171707 },
    { lat: 48.770848, lng: 18.171122 },
    { lat: 48.770758, lng: 18.170844 },
    { lat: 48.770834, lng: 18.170689 },
    { lat: 48.770931, lng: 18.170486 },
    { lat: 48.770951, lng: 18.169583 },
    { lat: 48.771064, lng: 18.169416 },
    { lat: 48.771126, lng: 18.169326 },
    { lat: 48.771059, lng: 18.1692 },
    { lat: 48.770974, lng: 18.169042 },
    { lat: 48.77085, lng: 18.168999 },
    { lat: 48.770777, lng: 18.168895 },
    { lat: 48.770731, lng: 18.168829 },
    { lat: 48.770537, lng: 18.16762 },
    { lat: 48.769698, lng: 18.168689 },
    { lat: 48.769187, lng: 18.169369 },
    { lat: 48.768999, lng: 18.169608 },
    { lat: 48.76908, lng: 18.170429 },
    { lat: 48.769109, lng: 18.171196 },
    { lat: 48.769113, lng: 18.171254 },
    { lat: 48.769172, lng: 18.172271 },
    { lat: 48.769217, lng: 18.172784 },
    { lat: 48.769229, lng: 18.172916 },
    { lat: 48.769243, lng: 18.173063 },
    { lat: 48.76927, lng: 18.173227 },
    { lat: 48.769282, lng: 18.173444 },
    { lat: 48.769483, lng: 18.173415 },
    { lat: 48.769707, lng: 18.173393 },
    { lat: 48.769881, lng: 18.173842 },
    { lat: 48.770083, lng: 18.173938 },
    { lat: 48.770159, lng: 18.173975 },
    { lat: 48.770339, lng: 18.174147 },
    { lat: 48.770393, lng: 18.174228 },
    { lat: 48.770398, lng: 18.174237 },
    { lat: 48.770484, lng: 18.174406 },
    { lat: 48.770501, lng: 18.174427 },
    { lat: 48.770621, lng: 18.174588 },
    { lat: 48.770718, lng: 18.174716 },
    { lat: 48.770729, lng: 18.174724 },
    { lat: 48.770861, lng: 18.174808 },
    { lat: 48.770841, lng: 18.174834 },
    { lat: 48.770799, lng: 18.174884 },
    { lat: 48.770789, lng: 18.174897 },
    { lat: 48.77078, lng: 18.174908 },
    { lat: 48.770734, lng: 18.174947 },
    { lat: 48.770756, lng: 18.174974 },
    { lat: 48.770798, lng: 18.175024 },
    { lat: 48.770856, lng: 18.175109 },
    { lat: 48.770923, lng: 18.175275 },
    { lat: 48.770951, lng: 18.175359 },
    { lat: 48.770996, lng: 18.175446 },
    { lat: 48.771116, lng: 18.175682 },
    { lat: 48.771252, lng: 18.175721 },
    { lat: 48.771394, lng: 18.175936 },
    { lat: 48.771463, lng: 18.176109 },
    { lat: 48.771548, lng: 18.176163 },
    { lat: 48.771677, lng: 18.176133 },
    { lat: 48.771826, lng: 18.176065 },
    { lat: 48.771902, lng: 18.176119 },
    { lat: 48.77217, lng: 18.176243 },
    { lat: 48.772318, lng: 18.176237 },
    { lat: 48.772342, lng: 18.176306 },
    { lat: 48.772514, lng: 18.176566 },
    { lat: 48.772616, lng: 18.176877 },
    { lat: 48.772723, lng: 18.177009 },
    { lat: 48.772718, lng: 18.17706 },
    { lat: 48.772705, lng: 18.17721 },
    { lat: 48.772968, lng: 18.177678 },
    { lat: 48.773134, lng: 18.177692 },
    { lat: 48.773268, lng: 18.17784 },
    { lat: 48.773351, lng: 18.178048 },
    { lat: 48.773416, lng: 18.178149 },
    { lat: 48.773523, lng: 18.178163 },
    { lat: 48.773867, lng: 18.178031 },
    { lat: 48.774062, lng: 18.178344 },
    { lat: 48.774211, lng: 18.17836 },
    { lat: 48.774464, lng: 18.178555 },
    { lat: 48.77456, lng: 18.178794 },
    { lat: 48.774653, lng: 18.17886 },
    { lat: 48.774805, lng: 18.17883 },
    { lat: 48.774975, lng: 18.178639 },
    { lat: 48.775156, lng: 18.17851 },
    { lat: 48.77541, lng: 18.178489 },
    { lat: 48.77546, lng: 18.178455 },
    { lat: 48.775597, lng: 18.178522 },
    { lat: 48.77571, lng: 18.178595 },
    { lat: 48.775993, lng: 18.178554 },
    { lat: 48.776196, lng: 18.178622 },
    { lat: 48.776306, lng: 18.178659 },
    { lat: 48.776465, lng: 18.178749 },
    { lat: 48.776527, lng: 18.17883 },
    { lat: 48.777126, lng: 18.178396 },
    { lat: 48.777293, lng: 18.177983 },
    { lat: 48.777351, lng: 18.177836 },
    { lat: 48.777414, lng: 18.177783 },
    { lat: 48.777536, lng: 18.177756 },
    { lat: 48.777584, lng: 18.177768 },
    { lat: 48.777755, lng: 18.177809 },
    { lat: 48.778009, lng: 18.177869 },
    { lat: 48.778152, lng: 18.177901 },
    { lat: 48.778423, lng: 18.177757 },
    { lat: 48.778464, lng: 18.177744 },
    { lat: 48.778819, lng: 18.17774 },
    { lat: 48.77909, lng: 18.177653 },
    { lat: 48.779172, lng: 18.177666 },
    { lat: 48.779205, lng: 18.177689 },
    { lat: 48.779307, lng: 18.177759 },
    { lat: 48.779391, lng: 18.177818 },
    { lat: 48.77944, lng: 18.177821 },
    { lat: 48.779533, lng: 18.177824 },
    { lat: 48.779569, lng: 18.177789 },
    { lat: 48.779633, lng: 18.177788 },
    { lat: 48.779771, lng: 18.177901 },
    { lat: 48.779749, lng: 18.17793 },
    { lat: 48.779747, lng: 18.177934 },
    { lat: 48.779745, lng: 18.177936 },
    { lat: 48.779579, lng: 18.178126 },
    { lat: 48.779566, lng: 18.178141 },
    { lat: 48.779515, lng: 18.17822 },
    { lat: 48.779494, lng: 18.178252 },
    { lat: 48.779461, lng: 18.178305 },
    { lat: 48.77946, lng: 18.178307 },
    { lat: 48.779422, lng: 18.178367 },
    { lat: 48.779361, lng: 18.178464 },
    { lat: 48.779216, lng: 18.178735 },
    { lat: 48.779045, lng: 18.179027 },
    { lat: 48.7790459, lng: 18.1790629 },
    { lat: 48.7791499, lng: 18.1793816 },
    { lat: 48.7792289, lng: 18.179637 },
    { lat: 48.7793106, lng: 18.1798774 },
    { lat: 48.7793867, lng: 18.1801228 },
    { lat: 48.7794652, lng: 18.180369 },
    { lat: 48.7795457, lng: 18.1806285 },
    { lat: 48.7795824, lng: 18.1808759 },
    { lat: 48.7796006, lng: 18.1809948 },
    { lat: 48.7796283, lng: 18.1811228 },
    { lat: 48.7796631, lng: 18.1814145 },
    { lat: 48.779671, lng: 18.1815108 },
    { lat: 48.7796906, lng: 18.1816384 },
    { lat: 48.7797351, lng: 18.1819177 },
    { lat: 48.7798086, lng: 18.1823935 },
    { lat: 48.7800482, lng: 18.1830636 },
    { lat: 48.7803035, lng: 18.1844007 },
    { lat: 48.7805703, lng: 18.1844302 },
    { lat: 48.7806242, lng: 18.1844267 },
    { lat: 48.7806939, lng: 18.1844207 },
    { lat: 48.7807344, lng: 18.184419 },
    { lat: 48.7808663, lng: 18.184417 },
    { lat: 48.7811839, lng: 18.1842492 },
    { lat: 48.7814047, lng: 18.184142 },
    { lat: 48.7814266, lng: 18.184132 },
    { lat: 48.7815473, lng: 18.1840728 },
    { lat: 48.7817032, lng: 18.183995 },
    { lat: 48.781792, lng: 18.1839521 },
    { lat: 48.781881, lng: 18.1839093 },
    { lat: 48.7820184, lng: 18.1838482 },
    { lat: 48.7821434, lng: 18.1837845 },
    { lat: 48.7822755, lng: 18.1837252 },
    { lat: 48.7824072, lng: 18.1836649 },
    { lat: 48.7824977, lng: 18.1836269 },
    { lat: 48.7825369, lng: 18.1836006 },
    { lat: 48.7826774, lng: 18.1835524 },
    { lat: 48.7828701, lng: 18.18347 },
    { lat: 48.7831782, lng: 18.1833743 },
    { lat: 48.7833322, lng: 18.1833225 },
    { lat: 48.7836853, lng: 18.183242 },
    { lat: 48.7838728, lng: 18.1832079 },
    { lat: 48.7840615, lng: 18.1831727 },
    { lat: 48.7842466, lng: 18.1831343 },
    { lat: 48.7844332, lng: 18.1831015 },
    { lat: 48.7845314, lng: 18.1830817 },
    { lat: 48.7846284, lng: 18.1830632 },
    { lat: 48.7847771, lng: 18.1830386 },
    { lat: 48.784853, lng: 18.183025 },
    { lat: 48.7849246, lng: 18.1830123 },
    { lat: 48.785024, lng: 18.1829974 },
    { lat: 48.7851235, lng: 18.1829793 },
    { lat: 48.7852195, lng: 18.182963 },
    { lat: 48.785244, lng: 18.182958 },
    { lat: 48.7857464, lng: 18.1829397 },
    { lat: 48.7859115, lng: 18.1829652 },
    { lat: 48.7860208, lng: 18.1829826 },
    { lat: 48.7861905, lng: 18.18301 },
    { lat: 48.7864103, lng: 18.1830454 },
    { lat: 48.7865686, lng: 18.1830714 },
    { lat: 48.7866925, lng: 18.183088 },
    { lat: 48.7869122, lng: 18.1830608 },
    { lat: 48.7871115, lng: 18.1830349 },
    { lat: 48.7872189, lng: 18.1830185 },
    { lat: 48.787435, lng: 18.18299 },
    { lat: 48.7875448, lng: 18.1829756 },
    { lat: 48.7876535, lng: 18.1829619 },
    { lat: 48.7878363, lng: 18.182941 },
    { lat: 48.7879538, lng: 18.1829238 },
    { lat: 48.7882472, lng: 18.1828872 },
    { lat: 48.7883789, lng: 18.1828412 },
    { lat: 48.7884949, lng: 18.1827994 },
    { lat: 48.7886419, lng: 18.1827442 },
    { lat: 48.788695, lng: 18.1827258 },
    { lat: 48.7891991, lng: 18.1824453 },
    { lat: 48.7897129, lng: 18.1821585 },
    { lat: 48.7897452, lng: 18.1821525 },
    { lat: 48.7902041, lng: 18.1820851 },
    { lat: 48.7912043, lng: 18.1819378 },
    { lat: 48.7917473, lng: 18.1818601 },
    { lat: 48.7925553, lng: 18.1817463 },
    { lat: 48.7924051, lng: 18.1811946 },
    { lat: 48.7923262, lng: 18.180884 },
    { lat: 48.7922793, lng: 18.180694 },
    { lat: 48.7921695, lng: 18.1802572 },
    { lat: 48.7920306, lng: 18.1797104 },
    { lat: 48.7919305, lng: 18.1793225 },
    { lat: 48.7917763, lng: 18.1787444 },
    { lat: 48.7914156, lng: 18.1773601 },
    { lat: 48.7914081, lng: 18.1773286 },
    { lat: 48.7913972, lng: 18.1772953 },
    { lat: 48.791377, lng: 18.1772182 },
    { lat: 48.7913608, lng: 18.1771505 },
    { lat: 48.7913566, lng: 18.1771357 },
    { lat: 48.7913441, lng: 18.1770783 },
    { lat: 48.7916265, lng: 18.1767515 },
    { lat: 48.7918171, lng: 18.1765338 },
    { lat: 48.7922457, lng: 18.1760405 },
    { lat: 48.7922583, lng: 18.1760256 },
    { lat: 48.7923574, lng: 18.1759231 },
    { lat: 48.7924119, lng: 18.1758669 },
    { lat: 48.7925896, lng: 18.1757425 },
    { lat: 48.792857, lng: 18.1756016 },
    { lat: 48.7932523, lng: 18.1753965 },
    { lat: 48.793629, lng: 18.1751958 },
    { lat: 48.7940819, lng: 18.1749677 },
    { lat: 48.7941181, lng: 18.1749508 },
    { lat: 48.7946723, lng: 18.1746593 },
    { lat: 48.7948148, lng: 18.1745828 },
    { lat: 48.7948585, lng: 18.1745617 },
    { lat: 48.7948762, lng: 18.1745525 },
    { lat: 48.7949189, lng: 18.1745308 },
    { lat: 48.7953975, lng: 18.1742979 },
    { lat: 48.7956862, lng: 18.1741571 },
    { lat: 48.7959776, lng: 18.1739868 },
    { lat: 48.7962613, lng: 18.1738173 },
    { lat: 48.7963591, lng: 18.173755 },
    { lat: 48.7964898, lng: 18.1736708 },
    { lat: 48.7964944, lng: 18.1737504 },
    { lat: 48.7964968, lng: 18.1737758 },
    { lat: 48.7965013, lng: 18.1738536 },
    { lat: 48.7967421, lng: 18.173626 },
    { lat: 48.7973281, lng: 18.1729466 },
    { lat: 48.7980461, lng: 18.1721312 },
    { lat: 48.7984193, lng: 18.1716953 },
    { lat: 48.7987135, lng: 18.1714166 },
    { lat: 48.7994271, lng: 18.1707181 },
    { lat: 48.7994463, lng: 18.1706984 },
    { lat: 48.79946, lng: 18.1706826 },
    { lat: 48.7994761, lng: 18.1706658 },
    { lat: 48.7995165, lng: 18.1706247 },
    { lat: 48.7998305, lng: 18.1703168 },
    { lat: 48.799965, lng: 18.1701842 },
    { lat: 48.8000323, lng: 18.1701184 },
    { lat: 48.8002747, lng: 18.1698808 },
    { lat: 48.801028, lng: 18.1691652 },
    { lat: 48.8015586, lng: 18.1686412 },
    { lat: 48.8015937, lng: 18.1686061 },
    { lat: 48.8015183, lng: 18.1685692 },
    { lat: 48.8014844, lng: 18.1685526 },
    { lat: 48.8014367, lng: 18.1685245 },
    { lat: 48.8014206, lng: 18.1685195 },
    { lat: 48.8015531, lng: 18.168353 },
    { lat: 48.8017347, lng: 18.1681364 },
    { lat: 48.801939, lng: 18.1679029 },
    { lat: 48.8020954, lng: 18.1673667 },
    { lat: 48.8021578, lng: 18.167138 },
    { lat: 48.8021633, lng: 18.1671129 },
    { lat: 48.8022672, lng: 18.1667262 },
    { lat: 48.802371, lng: 18.1662543 },
    { lat: 48.8023651, lng: 18.16625 },
    { lat: 48.8023603, lng: 18.1662449 },
    { lat: 48.8025474, lng: 18.1658125 },
    { lat: 48.8028277, lng: 18.1652724 },
    { lat: 48.8029802, lng: 18.1649813 },
    { lat: 48.8029056, lng: 18.1649027 },
    { lat: 48.8029709, lng: 18.1646861 },
    { lat: 48.80306, lng: 18.1644467 },
    { lat: 48.8031491, lng: 18.164291 },
    { lat: 48.8033058, lng: 18.164093 },
    { lat: 48.8035008, lng: 18.1639323 },
    { lat: 48.8038742, lng: 18.1636366 },
    { lat: 48.8037532, lng: 18.1634374 },
    { lat: 48.8038851, lng: 18.1631264 },
    { lat: 48.8039703, lng: 18.1628982 },
    { lat: 48.8041096, lng: 18.1624391 },
    { lat: 48.8042321, lng: 18.1620392 },
    { lat: 48.8043775, lng: 18.1616659 },
    { lat: 48.8043849, lng: 18.1616746 },
    { lat: 48.804391, lng: 18.1616816 },
    { lat: 48.8045468, lng: 18.1614319 },
    { lat: 48.8046583, lng: 18.1611361 },
    { lat: 48.8049064, lng: 18.1608785 },
    { lat: 48.8055451, lng: 18.1603511 },
    { lat: 48.8055401, lng: 18.1603406 },
    { lat: 48.8055363, lng: 18.1603326 },
    { lat: 48.8050758, lng: 18.159394 },
    { lat: 48.8050425, lng: 18.1593243 },
    { lat: 48.8052695, lng: 18.1590541 },
    { lat: 48.8054929, lng: 18.1588619 },
    { lat: 48.8057775, lng: 18.1585532 },
    { lat: 48.8060127, lng: 18.1582898 },
    { lat: 48.8061121, lng: 18.1581148 },
    { lat: 48.806164, lng: 18.157978 },
    { lat: 48.8061741, lng: 18.1577794 },
    { lat: 48.8060708, lng: 18.1571626 },
    { lat: 48.806063, lng: 18.1570122 },
    { lat: 48.806069, lng: 18.156608 },
  ],
  VeľkáHradná: [
    { lat: 48.7775777, lng: 18.0562827 },
    { lat: 48.7775353, lng: 18.0565592 },
    { lat: 48.7775247, lng: 18.0566483 },
    { lat: 48.7774269, lng: 18.0582144 },
    { lat: 48.7773204, lng: 18.0597442 },
    { lat: 48.7773144, lng: 18.0597947 },
    { lat: 48.7776311, lng: 18.0610776 },
    { lat: 48.778091, lng: 18.0629546 },
    { lat: 48.7782968, lng: 18.0634401 },
    { lat: 48.7783795, lng: 18.0639868 },
    { lat: 48.778437, lng: 18.0644629 },
    { lat: 48.7784213, lng: 18.0647566 },
    { lat: 48.7784105, lng: 18.0649757 },
    { lat: 48.7784124, lng: 18.0651615 },
    { lat: 48.7784154, lng: 18.0653296 },
    { lat: 48.7783872, lng: 18.0655581 },
    { lat: 48.7783645, lng: 18.0657515 },
    { lat: 48.7783455, lng: 18.0659087 },
    { lat: 48.7784627, lng: 18.0669439 },
    { lat: 48.7785446, lng: 18.0676661 },
    { lat: 48.7786446, lng: 18.0678721 },
    { lat: 48.7786399, lng: 18.0679085 },
    { lat: 48.7785898, lng: 18.0682845 },
    { lat: 48.7785439, lng: 18.0687154 },
    { lat: 48.7784258, lng: 18.0692025 },
    { lat: 48.7783274, lng: 18.0695256 },
    { lat: 48.7780477, lng: 18.0698376 },
    { lat: 48.7778155, lng: 18.0702659 },
    { lat: 48.7776993, lng: 18.0712129 },
    { lat: 48.7777618, lng: 18.0716214 },
    { lat: 48.7778003, lng: 18.0721097 },
    { lat: 48.7778604, lng: 18.0726312 },
    { lat: 48.7779869, lng: 18.0732326 },
    { lat: 48.7780536, lng: 18.073697 },
    { lat: 48.7781264, lng: 18.0741886 },
    { lat: 48.7781396, lng: 18.0743951 },
    { lat: 48.7780682, lng: 18.0748505 },
    { lat: 48.778056, lng: 18.0754905 },
    { lat: 48.7780166, lng: 18.0758808 },
    { lat: 48.7780756, lng: 18.0762712 },
    { lat: 48.7781655, lng: 18.0764166 },
    { lat: 48.7782613, lng: 18.0769949 },
    { lat: 48.7786682, lng: 18.0776592 },
    { lat: 48.7787711, lng: 18.0780681 },
    { lat: 48.779122, lng: 18.0788756 },
    { lat: 48.7793151, lng: 18.0790719 },
    { lat: 48.7795703, lng: 18.0797312 },
    { lat: 48.7796342, lng: 18.0799137 },
    { lat: 48.779726, lng: 18.0799594 },
    { lat: 48.7798559, lng: 18.0801743 },
    { lat: 48.7799341, lng: 18.0804694 },
    { lat: 48.7799832, lng: 18.0806321 },
    { lat: 48.7801463, lng: 18.0809144 },
    { lat: 48.7802997, lng: 18.0811637 },
    { lat: 48.7803221, lng: 18.0811993 },
    { lat: 48.7804262, lng: 18.081803 },
    { lat: 48.7803726, lng: 18.0822772 },
    { lat: 48.7803277, lng: 18.0826464 },
    { lat: 48.7802345, lng: 18.0833134 },
    { lat: 48.7801165, lng: 18.0837614 },
    { lat: 48.7801629, lng: 18.084151 },
    { lat: 48.7801498, lng: 18.0843156 },
    { lat: 48.7801417, lng: 18.084444 },
    { lat: 48.7801953, lng: 18.084668 },
    { lat: 48.7801496, lng: 18.0851214 },
    { lat: 48.7802074, lng: 18.0853162 },
    { lat: 48.780136, lng: 18.085431 },
    { lat: 48.7801995, lng: 18.0857442 },
    { lat: 48.7801945, lng: 18.0860272 },
    { lat: 48.7802408, lng: 18.0864096 },
    { lat: 48.7803127, lng: 18.086984 },
    { lat: 48.7803394, lng: 18.0874417 },
    { lat: 48.7803455, lng: 18.0879719 },
    { lat: 48.7802007, lng: 18.0884479 },
    { lat: 48.7802192, lng: 18.0887639 },
    { lat: 48.779989, lng: 18.0893651 },
    { lat: 48.7798231, lng: 18.0895003 },
    { lat: 48.7798247, lng: 18.0897595 },
    { lat: 48.7797385, lng: 18.0901371 },
    { lat: 48.7795797, lng: 18.0905091 },
    { lat: 48.7794842, lng: 18.0909992 },
    { lat: 48.779341, lng: 18.0912563 },
    { lat: 48.7792778, lng: 18.0913123 },
    { lat: 48.7792729, lng: 18.0913244 },
    { lat: 48.7792672, lng: 18.0913445 },
    { lat: 48.7792508, lng: 18.091371 },
    { lat: 48.7792428, lng: 18.0913889 },
    { lat: 48.7792346, lng: 18.0914022 },
    { lat: 48.7791727, lng: 18.091598 },
    { lat: 48.779185, lng: 18.0927396 },
    { lat: 48.7791813, lng: 18.0927888 },
    { lat: 48.7791723, lng: 18.0928297 },
    { lat: 48.7790719, lng: 18.0929648 },
    { lat: 48.7789085, lng: 18.0931444 },
    { lat: 48.7787775, lng: 18.0931812 },
    { lat: 48.7786694, lng: 18.0933216 },
    { lat: 48.7785636, lng: 18.0934006 },
    { lat: 48.7785463, lng: 18.0936845 },
    { lat: 48.7785295, lng: 18.0938216 },
    { lat: 48.7784409, lng: 18.0940059 },
    { lat: 48.7783983, lng: 18.0941272 },
    { lat: 48.7783947, lng: 18.0943162 },
    { lat: 48.7784122, lng: 18.0945189 },
    { lat: 48.7783422, lng: 18.0946929 },
    { lat: 48.7782905, lng: 18.0947946 },
    { lat: 48.7782573, lng: 18.0949798 },
    { lat: 48.7781923, lng: 18.0951223 },
    { lat: 48.7780974, lng: 18.0952963 },
    { lat: 48.7779855, lng: 18.0954086 },
    { lat: 48.7779006, lng: 18.0954808 },
    { lat: 48.7777458, lng: 18.0955362 },
    { lat: 48.7777381, lng: 18.095598 },
    { lat: 48.7778089, lng: 18.0956888 },
    { lat: 48.7778529, lng: 18.0958428 },
    { lat: 48.7778486, lng: 18.0959633 },
    { lat: 48.7778224, lng: 18.0960745 },
    { lat: 48.7777683, lng: 18.0960187 },
    { lat: 48.777702, lng: 18.0960419 },
    { lat: 48.7776652, lng: 18.096128 },
    { lat: 48.7776108, lng: 18.0962974 },
    { lat: 48.7775586, lng: 18.0963509 },
    { lat: 48.7774439, lng: 18.0963762 },
    { lat: 48.7773228, lng: 18.0963874 },
    { lat: 48.7772262, lng: 18.0965148 },
    { lat: 48.7771468, lng: 18.0966832 },
    { lat: 48.7770686, lng: 18.0967527 },
    { lat: 48.776887, lng: 18.0968707 },
    { lat: 48.7768645, lng: 18.0971979 },
    { lat: 48.7764585, lng: 18.0975716 },
    { lat: 48.7764658, lng: 18.0978308 },
    { lat: 48.776256, lng: 18.0979526 },
    { lat: 48.7762508, lng: 18.0982112 },
    { lat: 48.7760406, lng: 18.098434 },
    { lat: 48.7759275, lng: 18.0985671 },
    { lat: 48.7758752, lng: 18.0988307 },
    { lat: 48.7757218, lng: 18.0988519 },
    { lat: 48.775614, lng: 18.0989983 },
    { lat: 48.7755085, lng: 18.0991441 },
    { lat: 48.7754312, lng: 18.0992496 },
    { lat: 48.7752522, lng: 18.099525 },
    { lat: 48.7752013, lng: 18.0996427 },
    { lat: 48.7751785, lng: 18.0998314 },
    { lat: 48.7751422, lng: 18.099886 },
    { lat: 48.7751401, lng: 18.1001779 },
    { lat: 48.7750859, lng: 18.1002961 },
    { lat: 48.7750284, lng: 18.1004114 },
    { lat: 48.7750388, lng: 18.1004158 },
    { lat: 48.775038, lng: 18.1004395 },
    { lat: 48.775013, lng: 18.100436 },
    { lat: 48.7749707, lng: 18.1005251 },
    { lat: 48.774982, lng: 18.1005253 },
    { lat: 48.7749777, lng: 18.1005484 },
    { lat: 48.7749585, lng: 18.1005474 },
    { lat: 48.7749251, lng: 18.1006149 },
    { lat: 48.7748934, lng: 18.1006728 },
    { lat: 48.7746881, lng: 18.100902 },
    { lat: 48.7743836, lng: 18.101294 },
    { lat: 48.7743154, lng: 18.1012888 },
    { lat: 48.7742611, lng: 18.1013991 },
    { lat: 48.7742362, lng: 18.1014582 },
    { lat: 48.7741614, lng: 18.1015469 },
    { lat: 48.7741426, lng: 18.1015934 },
    { lat: 48.7739262, lng: 18.1017153 },
    { lat: 48.7738592, lng: 18.101745 },
    { lat: 48.7738279, lng: 18.1019839 },
    { lat: 48.7737109, lng: 18.1022239 },
    { lat: 48.7736514, lng: 18.1023059 },
    { lat: 48.7735018, lng: 18.1022557 },
    { lat: 48.7733577, lng: 18.1024159 },
    { lat: 48.773286, lng: 18.1025811 },
    { lat: 48.7730276, lng: 18.1026968 },
    { lat: 48.7730129, lng: 18.102736 },
    { lat: 48.7728901, lng: 18.103011 },
    { lat: 48.7728703, lng: 18.1030165 },
    { lat: 48.7725463, lng: 18.1035603 },
    { lat: 48.7724094, lng: 18.1035324 },
    { lat: 48.7722177, lng: 18.1038446 },
    { lat: 48.7724149, lng: 18.104365 },
    { lat: 48.7725029, lng: 18.1045185 },
    { lat: 48.7725217, lng: 18.1045496 },
    { lat: 48.7725488, lng: 18.104628 },
    { lat: 48.7725541, lng: 18.1047972 },
    { lat: 48.7724993, lng: 18.104977 },
    { lat: 48.7721428, lng: 18.1050596 },
    { lat: 48.772094, lng: 18.1051105 },
    { lat: 48.7720412, lng: 18.1056162 },
    { lat: 48.7719707, lng: 18.1058038 },
    { lat: 48.7718817, lng: 18.1064264 },
    { lat: 48.7715596, lng: 18.107367 },
    { lat: 48.7714683, lng: 18.1076808 },
    { lat: 48.7713721, lng: 18.1078106 },
    { lat: 48.7713736, lng: 18.1081245 },
    { lat: 48.7714386, lng: 18.108835 },
    { lat: 48.7712147, lng: 18.1091149 },
    { lat: 48.7712306, lng: 18.1091568 },
    { lat: 48.7711006, lng: 18.1096568 },
    { lat: 48.7710784, lng: 18.1100711 },
    { lat: 48.7709479, lng: 18.1104055 },
    { lat: 48.7711988, lng: 18.1107427 },
    { lat: 48.771345, lng: 18.111008 },
    { lat: 48.771397, lng: 18.1113921 },
    { lat: 48.7713994, lng: 18.111649 },
    { lat: 48.7714874, lng: 18.1118806 },
    { lat: 48.7715093, lng: 18.112083 },
    { lat: 48.7715455, lng: 18.1122374 },
    { lat: 48.7715486, lng: 18.1124084 },
    { lat: 48.7715248, lng: 18.1125636 },
    { lat: 48.7716042, lng: 18.1126854 },
    { lat: 48.7718107, lng: 18.1126865 },
    { lat: 48.771956, lng: 18.1130092 },
    { lat: 48.7719922, lng: 18.1131879 },
    { lat: 48.7721183, lng: 18.113375 },
    { lat: 48.7721391, lng: 18.1135379 },
    { lat: 48.7722702, lng: 18.1138106 },
    { lat: 48.7724053, lng: 18.1139421 },
    { lat: 48.7724349, lng: 18.1141582 },
    { lat: 48.7725535, lng: 18.1142784 },
    { lat: 48.7726155, lng: 18.1144332 },
    { lat: 48.7725918, lng: 18.1148182 },
    { lat: 48.7726514, lng: 18.1149526 },
    { lat: 48.7726389, lng: 18.1152623 },
    { lat: 48.7726994, lng: 18.1155458 },
    { lat: 48.7726992, lng: 18.1158113 },
    { lat: 48.7726255, lng: 18.1161947 },
    { lat: 48.7725426, lng: 18.1163989 },
    { lat: 48.7725359, lng: 18.1165561 },
    { lat: 48.7724987, lng: 18.1166523 },
    { lat: 48.7726093, lng: 18.11679 },
    { lat: 48.7726177, lng: 18.1168733 },
    { lat: 48.7726067, lng: 18.1169771 },
    { lat: 48.7725537, lng: 18.1171133 },
    { lat: 48.7723831, lng: 18.1172065 },
    { lat: 48.772085, lng: 18.1174548 },
    { lat: 48.7719235, lng: 18.1174552 },
    { lat: 48.7717769, lng: 18.117533 },
    { lat: 48.7716425, lng: 18.1175119 },
    { lat: 48.7715517, lng: 18.1176217 },
    { lat: 48.7712834, lng: 18.1182649 },
    { lat: 48.7713225, lng: 18.1184128 },
    { lat: 48.7712824, lng: 18.1187316 },
    { lat: 48.7713843, lng: 18.1190285 },
    { lat: 48.7715253, lng: 18.1191275 },
    { lat: 48.7715797, lng: 18.1192855 },
    { lat: 48.771591, lng: 18.1194396 },
    { lat: 48.7715252, lng: 18.1199185 },
    { lat: 48.771454, lng: 18.1199956 },
    { lat: 48.7713099, lng: 18.119925 },
    { lat: 48.7711874, lng: 18.1206114 },
    { lat: 48.7710792, lng: 18.1208254 },
    { lat: 48.7711347, lng: 18.1209461 },
    { lat: 48.7712622, lng: 18.1209664 },
    { lat: 48.7712814, lng: 18.1210239 },
    { lat: 48.7712515, lng: 18.1212869 },
    { lat: 48.7712256, lng: 18.1213258 },
    { lat: 48.7712205, lng: 18.1213354 },
    { lat: 48.7711644, lng: 18.1214198 },
    { lat: 48.7711332, lng: 18.1215016 },
    { lat: 48.7711085, lng: 18.1215639 },
    { lat: 48.7710417, lng: 18.121733 },
    { lat: 48.7710688, lng: 18.12185 },
    { lat: 48.7710806, lng: 18.1218978 },
    { lat: 48.7710551, lng: 18.1220819 },
    { lat: 48.771026, lng: 18.1221627 },
    { lat: 48.7709979, lng: 18.1222415 },
    { lat: 48.7710024, lng: 18.122339 },
    { lat: 48.7710079, lng: 18.1224745 },
    { lat: 48.7709755, lng: 18.1225343 },
    { lat: 48.77086, lng: 18.122745 },
    { lat: 48.7708264, lng: 18.1229444 },
    { lat: 48.7707524, lng: 18.1230867 },
    { lat: 48.7707244, lng: 18.1230893 },
    { lat: 48.7707053, lng: 18.1230897 },
    { lat: 48.7706578, lng: 18.123091 },
    { lat: 48.7706491, lng: 18.1230954 },
    { lat: 48.7706267, lng: 18.1230962 },
    { lat: 48.7705995, lng: 18.1230966 },
    { lat: 48.7705761, lng: 18.1230997 },
    { lat: 48.770477, lng: 18.1231047 },
    { lat: 48.7704409, lng: 18.1231059 },
    { lat: 48.7704151, lng: 18.1231094 },
    { lat: 48.7703259, lng: 18.1231094 },
    { lat: 48.7702764, lng: 18.1231117 },
    { lat: 48.7702, lng: 18.1231151 },
    { lat: 48.7700694, lng: 18.1231214 },
    { lat: 48.7700425, lng: 18.1231228 },
    { lat: 48.7695396, lng: 18.1228806 },
    { lat: 48.7693337, lng: 18.1227728 },
    { lat: 48.7690468, lng: 18.1227312 },
    { lat: 48.7685981, lng: 18.1228341 },
    { lat: 48.7683623, lng: 18.1226595 },
    { lat: 48.7681918, lng: 18.1224636 },
    { lat: 48.7680451, lng: 18.1223285 },
    { lat: 48.7677355, lng: 18.1223032 },
    { lat: 48.7674094, lng: 18.1224417 },
    { lat: 48.7673413, lng: 18.1224705 },
    { lat: 48.7673099, lng: 18.1225161 },
    { lat: 48.7672218, lng: 18.1226474 },
    { lat: 48.7671539, lng: 18.1227397 },
    { lat: 48.7671013, lng: 18.1228072 },
    { lat: 48.7670456, lng: 18.1230339 },
    { lat: 48.7668638, lng: 18.1233228 },
    { lat: 48.7667477, lng: 18.1234044 },
    { lat: 48.766633, lng: 18.1235674 },
    { lat: 48.7664098, lng: 18.123937 },
    { lat: 48.7662668, lng: 18.1241957 },
    { lat: 48.7660845, lng: 18.1243794 },
    { lat: 48.7659159, lng: 18.1246845 },
    { lat: 48.7653018, lng: 18.1258232 },
    { lat: 48.7648122, lng: 18.126079 },
    { lat: 48.7638812, lng: 18.1265676 },
    { lat: 48.7633774, lng: 18.1269837 },
    { lat: 48.7633124, lng: 18.1269924 },
    { lat: 48.7627896, lng: 18.1268115 },
    { lat: 48.761951, lng: 18.1265206 },
    { lat: 48.7618385, lng: 18.1265624 },
    { lat: 48.7617435, lng: 18.126698 },
    { lat: 48.7616103, lng: 18.1272015 },
    { lat: 48.7615628, lng: 18.127588 },
    { lat: 48.7615527, lng: 18.1276677 },
    { lat: 48.761553, lng: 18.1276884 },
    { lat: 48.7618, lng: 18.1282315 },
    { lat: 48.7618943, lng: 18.128788 },
    { lat: 48.7620027, lng: 18.1290025 },
    { lat: 48.7622437, lng: 18.1293246 },
    { lat: 48.7623196, lng: 18.1296576 },
    { lat: 48.7623602, lng: 18.1297541 },
    { lat: 48.7624329, lng: 18.1299197 },
    { lat: 48.7624731, lng: 18.1302406 },
    { lat: 48.7623078, lng: 18.1302114 },
    { lat: 48.7611218, lng: 18.1296887 },
    { lat: 48.7599188, lng: 18.1297287 },
    { lat: 48.759854, lng: 18.1305142 },
    { lat: 48.7597842, lng: 18.1313737 },
    { lat: 48.7597162, lng: 18.1322229 },
    { lat: 48.7596532, lng: 18.1329667 },
    { lat: 48.759615, lng: 18.133577 },
    { lat: 48.759596, lng: 18.134344 },
    { lat: 48.759576, lng: 18.135118 },
    { lat: 48.759458, lng: 18.135751 },
    { lat: 48.759336, lng: 18.136376 },
    { lat: 48.75916, lng: 18.137734 },
    { lat: 48.759123, lng: 18.137892 },
    { lat: 48.75896, lng: 18.138593 },
    { lat: 48.758718, lng: 18.139589 },
    { lat: 48.758595, lng: 18.140103 },
    { lat: 48.758511, lng: 18.140511 },
    { lat: 48.758421, lng: 18.140919 },
    { lat: 48.758345, lng: 18.141318 },
    { lat: 48.758376, lng: 18.14229 },
    { lat: 48.7584, lng: 18.143269 },
    { lat: 48.758561, lng: 18.144119 },
    { lat: 48.758724, lng: 18.145017 },
    { lat: 48.758759, lng: 18.145156 },
    { lat: 48.758939, lng: 18.145932 },
    { lat: 48.759016, lng: 18.146351 },
    { lat: 48.759173, lng: 18.146846 },
    { lat: 48.759344, lng: 18.14739 },
    { lat: 48.759558, lng: 18.147657 },
    { lat: 48.75972, lng: 18.147876 },
    { lat: 48.759733, lng: 18.147922 },
    { lat: 48.759779, lng: 18.148151 },
    { lat: 48.75983, lng: 18.148371 },
    { lat: 48.759865, lng: 18.148683 },
    { lat: 48.760023, lng: 18.14912 },
    { lat: 48.760141, lng: 18.14944 },
    { lat: 48.76017, lng: 18.149518 },
    { lat: 48.760247, lng: 18.149674 },
    { lat: 48.760498, lng: 18.150147 },
    { lat: 48.760656, lng: 18.150492 },
    { lat: 48.760859, lng: 18.151275 },
    { lat: 48.760981, lng: 18.152144 },
    { lat: 48.760992, lng: 18.152202 },
    { lat: 48.761178, lng: 18.152972 },
    { lat: 48.761255, lng: 18.153201 },
    { lat: 48.761366, lng: 18.15337 },
    { lat: 48.761424, lng: 18.153445 },
    { lat: 48.761457, lng: 18.153488 },
    { lat: 48.76166, lng: 18.153749 },
    { lat: 48.762081, lng: 18.154204 },
    { lat: 48.762806, lng: 18.154976 },
    { lat: 48.763407, lng: 18.155622 },
    { lat: 48.76367, lng: 18.156047 },
    { lat: 48.76403, lng: 18.156849 },
    { lat: 48.764143, lng: 18.157071 },
    { lat: 48.764418, lng: 18.15753 },
    { lat: 48.76477, lng: 18.156708 },
    { lat: 48.765028, lng: 18.157012 },
    { lat: 48.76538, lng: 18.157105 },
    { lat: 48.765543, lng: 18.157217 },
    { lat: 48.76566, lng: 18.157323 },
    { lat: 48.765824, lng: 18.15751 },
    { lat: 48.765835, lng: 18.157561 },
    { lat: 48.765845, lng: 18.157628 },
    { lat: 48.765853, lng: 18.157693 },
    { lat: 48.766241, lng: 18.158068 },
    { lat: 48.766331, lng: 18.157983 },
    { lat: 48.766637, lng: 18.157693 },
    { lat: 48.766671, lng: 18.157663 },
    { lat: 48.766737, lng: 18.157626 },
    { lat: 48.766829, lng: 18.157614 },
    { lat: 48.767138, lng: 18.157674 },
    { lat: 48.767466, lng: 18.157739 },
    { lat: 48.767535, lng: 18.157727 },
    { lat: 48.767563, lng: 18.15772 },
    { lat: 48.767617, lng: 18.157695 },
    { lat: 48.767629, lng: 18.157687 },
    { lat: 48.767843, lng: 18.157529 },
    { lat: 48.768009, lng: 18.157239 },
    { lat: 48.768041, lng: 18.156865 },
    { lat: 48.768241, lng: 18.156549 },
    { lat: 48.768432, lng: 18.156303 },
    { lat: 48.768451, lng: 18.156264 },
    { lat: 48.768486, lng: 18.156217 },
    { lat: 48.76853, lng: 18.156116 },
    { lat: 48.768606, lng: 18.155932 },
    { lat: 48.768706, lng: 18.155791 },
    { lat: 48.769063, lng: 18.155236 },
    { lat: 48.769332, lng: 18.154753 },
    { lat: 48.769506, lng: 18.154209 },
    { lat: 48.769601, lng: 18.153927 },
    { lat: 48.769667, lng: 18.153758 },
    { lat: 48.769733, lng: 18.15384 },
    { lat: 48.769805, lng: 18.153738 },
    { lat: 48.76986, lng: 18.153562 },
    { lat: 48.769956, lng: 18.153323 },
    { lat: 48.769968, lng: 18.153294 },
    { lat: 48.770167, lng: 18.152904 },
    { lat: 48.770184, lng: 18.152886 },
    { lat: 48.770252, lng: 18.152815 },
    { lat: 48.770415, lng: 18.152642 },
    { lat: 48.770535, lng: 18.152513 },
    { lat: 48.77068, lng: 18.152263 },
    { lat: 48.770888, lng: 18.152262 },
    { lat: 48.770947, lng: 18.152244 },
    { lat: 48.771162, lng: 18.152179 },
    { lat: 48.771323, lng: 18.151989 },
    { lat: 48.771425, lng: 18.151916 },
    { lat: 48.771518, lng: 18.151849 },
    { lat: 48.771822, lng: 18.151452 },
    { lat: 48.771847, lng: 18.151408 },
    { lat: 48.771985, lng: 18.151169 },
    { lat: 48.772079, lng: 18.151 },
    { lat: 48.772181, lng: 18.1508 },
    { lat: 48.772216, lng: 18.150727 },
    { lat: 48.772336, lng: 18.150651 },
    { lat: 48.77256, lng: 18.150507 },
    { lat: 48.772767, lng: 18.150269 },
    { lat: 48.772903, lng: 18.150233 },
    { lat: 48.77334, lng: 18.151047 },
    { lat: 48.773386, lng: 18.151131 },
    { lat: 48.773595, lng: 18.151652 },
    { lat: 48.774131, lng: 18.152517 },
    { lat: 48.774662, lng: 18.153388 },
    { lat: 48.7750591, lng: 18.1541091 },
    { lat: 48.7752099, lng: 18.1540061 },
    { lat: 48.775504, lng: 18.1538048 },
    { lat: 48.7755539, lng: 18.1537874 },
    { lat: 48.7757753, lng: 18.1536941 },
    { lat: 48.7756469, lng: 18.1532495 },
    { lat: 48.7757596, lng: 18.1531502 },
    { lat: 48.7758791, lng: 18.1530277 },
    { lat: 48.7761424, lng: 18.1533853 },
    { lat: 48.776253, lng: 18.1533106 },
    { lat: 48.7764405, lng: 18.1531963 },
    { lat: 48.7768099, lng: 18.1528493 },
    { lat: 48.777315, lng: 18.1537198 },
    { lat: 48.7774003, lng: 18.1535389 },
    { lat: 48.7768357, lng: 18.1515629 },
    { lat: 48.7770103, lng: 18.1512614 },
    { lat: 48.7770149, lng: 18.1507566 },
    { lat: 48.7771337, lng: 18.1505867 },
    { lat: 48.7771596, lng: 18.1504505 },
    { lat: 48.7771356, lng: 18.150175 },
    { lat: 48.7770692, lng: 18.1498038 },
    { lat: 48.7768649, lng: 18.1490237 },
    { lat: 48.7775554, lng: 18.1487708 },
    { lat: 48.7778904, lng: 18.1486509 },
    { lat: 48.7779421, lng: 18.1486247 },
    { lat: 48.7779689, lng: 18.1486029 },
    { lat: 48.7779776, lng: 18.1485975 },
    { lat: 48.7783056, lng: 18.1483769 },
    { lat: 48.7786824, lng: 18.1481208 },
    { lat: 48.7789441, lng: 18.1479452 },
    { lat: 48.7789341, lng: 18.1479272 },
    { lat: 48.7788903, lng: 18.1478532 },
    { lat: 48.778622, lng: 18.1473882 },
    { lat: 48.7786544, lng: 18.1473238 },
    { lat: 48.7786968, lng: 18.1472394 },
    { lat: 48.7787351, lng: 18.1471614 },
    { lat: 48.7787691, lng: 18.1471028 },
    { lat: 48.7791775, lng: 18.1469492 },
    { lat: 48.7795601, lng: 18.1468087 },
    { lat: 48.7796288, lng: 18.1467863 },
    { lat: 48.7797206, lng: 18.1468164 },
    { lat: 48.7797907, lng: 18.1468393 },
    { lat: 48.7801014, lng: 18.1469409 },
    { lat: 48.7802974, lng: 18.1467599 },
    { lat: 48.7803425, lng: 18.1467382 },
    { lat: 48.7805658, lng: 18.1466016 },
    { lat: 48.7808004, lng: 18.1465608 },
    { lat: 48.7809458, lng: 18.1466335 },
    { lat: 48.7814444, lng: 18.1466118 },
    { lat: 48.7814977, lng: 18.1463442 },
    { lat: 48.7816529, lng: 18.1455568 },
    { lat: 48.781668, lng: 18.1454829 },
    { lat: 48.7816944, lng: 18.1452404 },
    { lat: 48.7817442, lng: 18.1448133 },
    { lat: 48.7818068, lng: 18.1443566 },
    { lat: 48.7817979, lng: 18.1438708 },
    { lat: 48.7816464, lng: 18.1433173 },
    { lat: 48.7816305, lng: 18.1431419 },
    { lat: 48.7816333, lng: 18.1428392 },
    { lat: 48.7816899, lng: 18.1420656 },
    { lat: 48.7817043, lng: 18.1419216 },
    { lat: 48.7817617, lng: 18.1416671 },
    { lat: 48.7819438, lng: 18.1408968 },
    { lat: 48.7820255, lng: 18.1405357 },
    { lat: 48.7821057, lng: 18.1400309 },
    { lat: 48.7820081, lng: 18.1392809 },
    { lat: 48.7819044, lng: 18.1388134 },
    { lat: 48.7819646, lng: 18.1387636 },
    { lat: 48.7821351, lng: 18.1386289 },
    { lat: 48.7821955, lng: 18.1379575 },
    { lat: 48.7822363, lng: 18.1372997 },
    { lat: 48.7826878, lng: 18.1377085 },
    { lat: 48.7833834, lng: 18.1383802 },
    { lat: 48.7837553, lng: 18.138762 },
    { lat: 48.7840671, lng: 18.1377161 },
    { lat: 48.7843972, lng: 18.1365776 },
    { lat: 48.7846102, lng: 18.136769 },
    { lat: 48.7852312, lng: 18.137062 },
    { lat: 48.7854749, lng: 18.13722 },
    { lat: 48.7856828, lng: 18.1374368 },
    { lat: 48.7857972, lng: 18.1375218 },
    { lat: 48.7859331, lng: 18.1374912 },
    { lat: 48.7861165, lng: 18.1374808 },
    { lat: 48.7873728, lng: 18.1372557 },
    { lat: 48.7874736, lng: 18.1373586 },
    { lat: 48.7874891, lng: 18.1373737 },
    { lat: 48.787506, lng: 18.1373922 },
    { lat: 48.7875406, lng: 18.1374255 },
    { lat: 48.7876342, lng: 18.1373068 },
    { lat: 48.7877616, lng: 18.137146 },
    { lat: 48.7877802, lng: 18.1370223 },
    { lat: 48.787792, lng: 18.1369531 },
    { lat: 48.7878004, lng: 18.1369018 },
    { lat: 48.7879714, lng: 18.135997 },
    { lat: 48.7879842, lng: 18.1359283 },
    { lat: 48.78801, lng: 18.13579 },
    { lat: 48.7880788, lng: 18.1355174 },
    { lat: 48.7883394, lng: 18.134853 },
    { lat: 48.788467, lng: 18.134525 },
    { lat: 48.7887148, lng: 18.1339331 },
    { lat: 48.7888437, lng: 18.1336246 },
    { lat: 48.7891786, lng: 18.1328218 },
    { lat: 48.7893505, lng: 18.1324231 },
    { lat: 48.7895428, lng: 18.1319055 },
    { lat: 48.789689, lng: 18.1317966 },
    { lat: 48.7896942, lng: 18.1317726 },
    { lat: 48.7897382, lng: 18.1315975 },
    { lat: 48.7897393, lng: 18.1315952 },
    { lat: 48.7897429, lng: 18.1315801 },
    { lat: 48.7897759, lng: 18.131449 },
    { lat: 48.7897363, lng: 18.1311933 },
    { lat: 48.7898307, lng: 18.1309911 },
    { lat: 48.7900044, lng: 18.1310306 },
    { lat: 48.7900423, lng: 18.1310392 },
    { lat: 48.7900238, lng: 18.1309572 },
    { lat: 48.7900496, lng: 18.1308969 },
    { lat: 48.7901441, lng: 18.1308285 },
    { lat: 48.7901421, lng: 18.1307181 },
    { lat: 48.7901587, lng: 18.130498 },
    { lat: 48.7900702, lng: 18.1303956 },
    { lat: 48.7900765, lng: 18.130093 },
    { lat: 48.7901894, lng: 18.1299188 },
    { lat: 48.7901882, lng: 18.1297837 },
    { lat: 48.7900447, lng: 18.1297029 },
    { lat: 48.7900427, lng: 18.1295922 },
    { lat: 48.7901382, lng: 18.1295061 },
    { lat: 48.7901689, lng: 18.1294512 },
    { lat: 48.7901391, lng: 18.1293832 },
    { lat: 48.7902136, lng: 18.1293061 },
    { lat: 48.7901812, lng: 18.1289997 },
    { lat: 48.7901231, lng: 18.1287766 },
    { lat: 48.7900895, lng: 18.1286458 },
    { lat: 48.7900707, lng: 18.1285753 },
    { lat: 48.7901005, lng: 18.1284851 },
    { lat: 48.7901569, lng: 18.1283119 },
    { lat: 48.790176, lng: 18.1282503 },
    { lat: 48.7902192, lng: 18.1281185 },
    { lat: 48.7902117, lng: 18.1279535 },
    { lat: 48.7903152, lng: 18.12789 },
    { lat: 48.7903261, lng: 18.1277847 },
    { lat: 48.7902316, lng: 18.1276731 },
    { lat: 48.7901551, lng: 18.1275609 },
    { lat: 48.7902131, lng: 18.1271416 },
    { lat: 48.7903212, lng: 18.1268244 },
    { lat: 48.7903603, lng: 18.1264474 },
    { lat: 48.7904341, lng: 18.1262658 },
    { lat: 48.7904329, lng: 18.1260487 },
    { lat: 48.7904313, lng: 18.1256148 },
    { lat: 48.7905152, lng: 18.1253863 },
    { lat: 48.7903696, lng: 18.1251541 },
    { lat: 48.7899279, lng: 18.124447 },
    { lat: 48.7899887, lng: 18.1241708 },
    { lat: 48.7901421, lng: 18.1239164 },
    { lat: 48.7902362, lng: 18.1235388 },
    { lat: 48.7902948, lng: 18.1234562 },
    { lat: 48.7903023, lng: 18.1233532 },
    { lat: 48.7901593, lng: 18.1232445 },
    { lat: 48.7898714, lng: 18.1230272 },
    { lat: 48.7899249, lng: 18.1227431 },
    { lat: 48.7899705, lng: 18.1223849 },
    { lat: 48.7899678, lng: 18.1221014 },
    { lat: 48.7899659, lng: 18.1218168 },
    { lat: 48.7899001, lng: 18.1217146 },
    { lat: 48.7896138, lng: 18.1212713 },
    { lat: 48.7895714, lng: 18.1212058 },
    { lat: 48.789381, lng: 18.1212096 },
    { lat: 48.7892338, lng: 18.121105 },
    { lat: 48.7891009, lng: 18.121166 },
    { lat: 48.7890555, lng: 18.1211252 },
    { lat: 48.7889258, lng: 18.1210101 },
    { lat: 48.7889558, lng: 18.1209033 },
    { lat: 48.7889293, lng: 18.1206997 },
    { lat: 48.7888579, lng: 18.1206197 },
    { lat: 48.7887372, lng: 18.1204837 },
    { lat: 48.7886523, lng: 18.1204388 },
    { lat: 48.7885998, lng: 18.1204103 },
    { lat: 48.7886825, lng: 18.1202426 },
    { lat: 48.7886253, lng: 18.120092 },
    { lat: 48.788537, lng: 18.1200089 },
    { lat: 48.7885059, lng: 18.1199791 },
    { lat: 48.7884495, lng: 18.1197851 },
    { lat: 48.7884042, lng: 18.1196283 },
    { lat: 48.7884535, lng: 18.1194263 },
    { lat: 48.7884389, lng: 18.1192328 },
    { lat: 48.7884589, lng: 18.1187996 },
    { lat: 48.7884938, lng: 18.1186067 },
    { lat: 48.7885232, lng: 18.1185101 },
    { lat: 48.7883666, lng: 18.1184362 },
    { lat: 48.7882709, lng: 18.1182266 },
    { lat: 48.7881805, lng: 18.1180877 },
    { lat: 48.7881696, lng: 18.1178626 },
    { lat: 48.7881344, lng: 18.1177424 },
    { lat: 48.7881026, lng: 18.1176417 },
    { lat: 48.7880994, lng: 18.1175101 },
    { lat: 48.7881115, lng: 18.1173482 },
    { lat: 48.7880585, lng: 18.1172546 },
    { lat: 48.7880002, lng: 18.1170448 },
    { lat: 48.7878734, lng: 18.1169211 },
    { lat: 48.7878553, lng: 18.1168623 },
    { lat: 48.7878459, lng: 18.1166649 },
    { lat: 48.7877847, lng: 18.1165804 },
    { lat: 48.7876387, lng: 18.1165343 },
    { lat: 48.7875742, lng: 18.1163969 },
    { lat: 48.7875939, lng: 18.1162669 },
    { lat: 48.7876214, lng: 18.1161018 },
    { lat: 48.7876156, lng: 18.1160224 },
    { lat: 48.7875759, lng: 18.1159425 },
    { lat: 48.7874754, lng: 18.1158042 },
    { lat: 48.7875075, lng: 18.1155784 },
    { lat: 48.787535, lng: 18.1154913 },
    { lat: 48.7875311, lng: 18.1153269 },
    { lat: 48.7874363, lng: 18.1152855 },
    { lat: 48.7872552, lng: 18.1152956 },
    { lat: 48.7871701, lng: 18.1152511 },
    { lat: 48.7871273, lng: 18.11525 },
    { lat: 48.7870707, lng: 18.1152697 },
    { lat: 48.787045, lng: 18.1154099 },
    { lat: 48.7870061, lng: 18.1154197 },
    { lat: 48.7869779, lng: 18.1152442 },
    { lat: 48.7868794, lng: 18.1148873 },
    { lat: 48.7868847, lng: 18.1148039 },
    { lat: 48.7868566, lng: 18.1146344 },
    { lat: 48.7868165, lng: 18.1145829 },
    { lat: 48.7866476, lng: 18.1145696 },
    { lat: 48.786526, lng: 18.1145164 },
    { lat: 48.7864836, lng: 18.1141988 },
    { lat: 48.7864044, lng: 18.1139421 },
    { lat: 48.7862424, lng: 18.1135807 },
    { lat: 48.7861972, lng: 18.1133287 },
    { lat: 48.7859106, lng: 18.1131336 },
    { lat: 48.785689, lng: 18.1128561 },
    { lat: 48.7855695, lng: 18.1126433 },
    { lat: 48.7855064, lng: 18.1125319 },
    { lat: 48.7854667, lng: 18.1123317 },
    { lat: 48.7855349, lng: 18.1121269 },
    { lat: 48.7855523, lng: 18.1119832 },
    { lat: 48.7855259, lng: 18.1117405 },
    { lat: 48.7855191, lng: 18.1116813 },
    { lat: 48.7854787, lng: 18.1115711 },
    { lat: 48.785382, lng: 18.1114416 },
    { lat: 48.7853863, lng: 18.1113011 },
    { lat: 48.7853808, lng: 18.1112624 },
    { lat: 48.785372, lng: 18.1112099 },
    { lat: 48.7853501, lng: 18.1111051 },
    { lat: 48.7853312, lng: 18.1110128 },
    { lat: 48.7853296, lng: 18.1110048 },
    { lat: 48.7852712, lng: 18.1109729 },
    { lat: 48.7851921, lng: 18.1109294 },
    { lat: 48.7850278, lng: 18.1107845 },
    { lat: 48.7849506, lng: 18.1107379 },
    { lat: 48.7848806, lng: 18.1106606 },
    { lat: 48.7848452, lng: 18.1105576 },
    { lat: 48.7848358, lng: 18.1104318 },
    { lat: 48.7848168, lng: 18.1103009 },
    { lat: 48.7847774, lng: 18.1102038 },
    { lat: 48.78475, lng: 18.110103 },
    { lat: 48.7847397, lng: 18.1099422 },
    { lat: 48.784711, lng: 18.1094908 },
    { lat: 48.7846995, lng: 18.1094108 },
    { lat: 48.7846737, lng: 18.1093169 },
    { lat: 48.7846322, lng: 18.1091657 },
    { lat: 48.7846059, lng: 18.1090836 },
    { lat: 48.7846007, lng: 18.1090296 },
    { lat: 48.7845943, lng: 18.1089778 },
    { lat: 48.7845859, lng: 18.1088931 },
    { lat: 48.7845648, lng: 18.1087059 },
    { lat: 48.7845435, lng: 18.1085147 },
    { lat: 48.7845245, lng: 18.1083423 },
    { lat: 48.7843674, lng: 18.1078143 },
    { lat: 48.784195, lng: 18.1077614 },
    { lat: 48.784057, lng: 18.1077186 },
    { lat: 48.7839656, lng: 18.1076396 },
    { lat: 48.7839847, lng: 18.1074846 },
    { lat: 48.7839684, lng: 18.107204 },
    { lat: 48.78397, lng: 18.1071913 },
    { lat: 48.7838833, lng: 18.1069649 },
    { lat: 48.7838723, lng: 18.1067362 },
    { lat: 48.7838864, lng: 18.106593 },
    { lat: 48.7839246, lng: 18.1064312 },
    { lat: 48.7839308, lng: 18.106056 },
    { lat: 48.7838455, lng: 18.1057321 },
    { lat: 48.7838451, lng: 18.1056488 },
    { lat: 48.7838975, lng: 18.1055412 },
    { lat: 48.7839668, lng: 18.1053176 },
    { lat: 48.7839659, lng: 18.1050708 },
    { lat: 48.7839449, lng: 18.1049981 },
    { lat: 48.7839493, lng: 18.1049599 },
    { lat: 48.7841184, lng: 18.1048594 },
    { lat: 48.7841381, lng: 18.1045758 },
    { lat: 48.784055, lng: 18.104408 },
    { lat: 48.7840079, lng: 18.1042786 },
    { lat: 48.7839912, lng: 18.1040683 },
    { lat: 48.7840152, lng: 18.1039778 },
    { lat: 48.7841333, lng: 18.1039352 },
    { lat: 48.7842256, lng: 18.1038506 },
    { lat: 48.7842568, lng: 18.103729 },
    { lat: 48.7843083, lng: 18.1035858 },
    { lat: 48.7844046, lng: 18.1034763 },
    { lat: 48.7844529, lng: 18.1033765 },
    { lat: 48.784465, lng: 18.1030807 },
    { lat: 48.7846164, lng: 18.1027738 },
    { lat: 48.7845135, lng: 18.1026716 },
    { lat: 48.7845779, lng: 18.1025954 },
    { lat: 48.7845831, lng: 18.1025882 },
    { lat: 48.78464, lng: 18.1025205 },
    { lat: 48.7846486, lng: 18.1025097 },
    { lat: 48.7846779, lng: 18.1024743 },
    { lat: 48.7847921, lng: 18.1021681 },
    { lat: 48.7848926, lng: 18.1019743 },
    { lat: 48.7849484, lng: 18.1017915 },
    { lat: 48.7850402, lng: 18.101488 },
    { lat: 48.7852081, lng: 18.1010937 },
    { lat: 48.7853187, lng: 18.1009397 },
    { lat: 48.7853552, lng: 18.1007338 },
    { lat: 48.7853246, lng: 18.1005013 },
    { lat: 48.785338, lng: 18.1002436 },
    { lat: 48.7853602, lng: 18.1000445 },
    { lat: 48.7853404, lng: 18.0998804 },
    { lat: 48.785309, lng: 18.0996327 },
    { lat: 48.7852986, lng: 18.0995521 },
    { lat: 48.7852189, lng: 18.0992271 },
    { lat: 48.7851776, lng: 18.0990626 },
    { lat: 48.7851562, lng: 18.0987165 },
    { lat: 48.7851151, lng: 18.0983763 },
    { lat: 48.785169, lng: 18.0980445 },
    { lat: 48.7851274, lng: 18.0977362 },
    { lat: 48.785151, lng: 18.0973874 },
    { lat: 48.7851643, lng: 18.0971895 },
    { lat: 48.7851873, lng: 18.0967935 },
    { lat: 48.7852796, lng: 18.0963814 },
    { lat: 48.785276, lng: 18.0959911 },
    { lat: 48.7852765, lng: 18.0958815 },
    { lat: 48.7853343, lng: 18.0954826 },
    { lat: 48.7853476, lng: 18.0953989 },
    { lat: 48.785461, lng: 18.095003 },
    { lat: 48.7855991, lng: 18.0946624 },
    { lat: 48.7855657, lng: 18.094459 },
    { lat: 48.785575, lng: 18.0943629 },
    { lat: 48.7855761, lng: 18.0943452 },
    { lat: 48.7856927, lng: 18.0936937 },
    { lat: 48.7857976, lng: 18.093174 },
    { lat: 48.7858425, lng: 18.092897 },
    { lat: 48.78587, lng: 18.0927126 },
    { lat: 48.7858915, lng: 18.0925738 },
    { lat: 48.7859382, lng: 18.0921384 },
    { lat: 48.7859422, lng: 18.0918215 },
    { lat: 48.7859447, lng: 18.0916709 },
    { lat: 48.7859733, lng: 18.0912888 },
    { lat: 48.7860869, lng: 18.0909547 },
    { lat: 48.7862171, lng: 18.0906732 },
    { lat: 48.7862482, lng: 18.0903552 },
    { lat: 48.7863493, lng: 18.0900954 },
    { lat: 48.7863755, lng: 18.0898869 },
    { lat: 48.7863072, lng: 18.0897017 },
    { lat: 48.7864275, lng: 18.0894462 },
    { lat: 48.7866258, lng: 18.0891881 },
    { lat: 48.7867006, lng: 18.0889233 },
    { lat: 48.786725, lng: 18.0887972 },
    { lat: 48.7867758, lng: 18.0885292 },
    { lat: 48.7868134, lng: 18.0883441 },
    { lat: 48.7869181, lng: 18.0879901 },
    { lat: 48.7870265, lng: 18.0877767 },
    { lat: 48.7871279, lng: 18.087602 },
    { lat: 48.7873229, lng: 18.0873451 },
    { lat: 48.7874908, lng: 18.0870331 },
    { lat: 48.7875852, lng: 18.0868326 },
    { lat: 48.7877442, lng: 18.0865794 },
    { lat: 48.7878219, lng: 18.0863267 },
    { lat: 48.7877189, lng: 18.0861089 },
    { lat: 48.7881124, lng: 18.0857725 },
    { lat: 48.788192, lng: 18.0854916 },
    { lat: 48.7885933, lng: 18.0851959 },
    { lat: 48.7887057, lng: 18.0849751 },
    { lat: 48.7890687, lng: 18.0844483 },
    { lat: 48.7893302, lng: 18.0839792 },
    { lat: 48.7895168, lng: 18.0837355 },
    { lat: 48.7895831, lng: 18.0835967 },
    { lat: 48.7896695, lng: 18.0832573 },
    { lat: 48.7896788, lng: 18.0832224 },
    { lat: 48.7896758, lng: 18.0827461 },
    { lat: 48.7896533, lng: 18.0824431 },
    { lat: 48.7896074, lng: 18.0818279 },
    { lat: 48.7896195, lng: 18.0814398 },
    { lat: 48.7895678, lng: 18.0808833 },
    { lat: 48.7896733, lng: 18.0803907 },
    { lat: 48.7896133, lng: 18.0795755 },
    { lat: 48.7896229, lng: 18.0794679 },
    { lat: 48.7896392, lng: 18.0793058 },
    { lat: 48.7896413, lng: 18.0792815 },
    { lat: 48.7896892, lng: 18.0787701 },
    { lat: 48.7897048, lng: 18.0781871 },
    { lat: 48.7896948, lng: 18.0779801 },
    { lat: 48.7897931, lng: 18.0774985 },
    { lat: 48.789807, lng: 18.0770048 },
    { lat: 48.7899064, lng: 18.0767148 },
    { lat: 48.7898642, lng: 18.0763207 },
    { lat: 48.7899203, lng: 18.0758157 },
    { lat: 48.7899251, lng: 18.074722 },
    { lat: 48.7899266, lng: 18.0743402 },
    { lat: 48.7899308, lng: 18.0741242 },
    { lat: 48.7899407, lng: 18.0735789 },
    { lat: 48.7898515, lng: 18.0730214 },
    { lat: 48.7897431, lng: 18.0725953 },
    { lat: 48.7895547, lng: 18.0719227 },
    { lat: 48.789439, lng: 18.0711987 },
    { lat: 48.7893745, lng: 18.0707915 },
    { lat: 48.7892702, lng: 18.0703595 },
    { lat: 48.7891558, lng: 18.0700821 },
    { lat: 48.7890231, lng: 18.0697624 },
    { lat: 48.7889678, lng: 18.0696288 },
    { lat: 48.7885894, lng: 18.0687168 },
    { lat: 48.7884202, lng: 18.068315 },
    { lat: 48.7881511, lng: 18.0677477 },
    { lat: 48.7880207, lng: 18.0674875 },
    { lat: 48.787649, lng: 18.066746 },
    { lat: 48.7873229, lng: 18.0657294 },
    { lat: 48.787154, lng: 18.0646962 },
    { lat: 48.7870711, lng: 18.0635521 },
    { lat: 48.7869801, lng: 18.0624795 },
    { lat: 48.7867727, lng: 18.0616581 },
    { lat: 48.7864054, lng: 18.0607812 },
    { lat: 48.785866, lng: 18.0599929 },
    { lat: 48.7854411, lng: 18.0596184 },
    { lat: 48.7851599, lng: 18.0594056 },
    { lat: 48.7844768, lng: 18.0594759 },
    { lat: 48.7835957, lng: 18.0597715 },
    { lat: 48.7835094, lng: 18.0598006 },
    { lat: 48.7832317, lng: 18.0599116 },
    { lat: 48.7829901, lng: 18.0600103 },
    { lat: 48.7827155, lng: 18.0600579 },
    { lat: 48.7823113, lng: 18.0600378 },
    { lat: 48.7820719, lng: 18.0599393 },
    { lat: 48.7816805, lng: 18.0594805 },
    { lat: 48.7816096, lng: 18.0593512 },
    { lat: 48.7814859, lng: 18.0591252 },
    { lat: 48.7813371, lng: 18.0587819 },
    { lat: 48.7813251, lng: 18.0585982 },
    { lat: 48.7813717, lng: 18.0582602 },
    { lat: 48.7814319, lng: 18.0581283 },
    { lat: 48.7812367, lng: 18.0577448 },
    { lat: 48.7806722, lng: 18.057184 },
    { lat: 48.7801313, lng: 18.0568537 },
    { lat: 48.7798193, lng: 18.0568649 },
    { lat: 48.7795759, lng: 18.0567555 },
    { lat: 48.7792738, lng: 18.0565274 },
    { lat: 48.778983, lng: 18.056365 },
    { lat: 48.7783168, lng: 18.0563762 },
    { lat: 48.7779594, lng: 18.0562872 },
    { lat: 48.7778744, lng: 18.0562814 },
    { lat: 48.7775777, lng: 18.0562827 },
  ],
  Soblahov: [
    { lat: 48.8821205, lng: 18.0707691 },
    { lat: 48.8816454, lng: 18.070753 },
    { lat: 48.8810495, lng: 18.0707506 },
    { lat: 48.8806838, lng: 18.0707324 },
    { lat: 48.8801506, lng: 18.0706438 },
    { lat: 48.8796253, lng: 18.0705222 },
    { lat: 48.8794165, lng: 18.0705187 },
    { lat: 48.8789148, lng: 18.070509 },
    { lat: 48.8788984, lng: 18.0704628 },
    { lat: 48.878645, lng: 18.0707023 },
    { lat: 48.8784183, lng: 18.0709357 },
    { lat: 48.8782311, lng: 18.0711509 },
    { lat: 48.8780309, lng: 18.0713345 },
    { lat: 48.8778012, lng: 18.0715357 },
    { lat: 48.8776084, lng: 18.0716967 },
    { lat: 48.877304, lng: 18.0719513 },
    { lat: 48.8770208, lng: 18.0721464 },
    { lat: 48.8767894, lng: 18.0722983 },
    { lat: 48.8764482, lng: 18.0724862 },
    { lat: 48.8758718, lng: 18.0728203 },
    { lat: 48.8754877, lng: 18.0730616 },
    { lat: 48.8751854, lng: 18.0732596 },
    { lat: 48.8751243, lng: 18.0731979 },
    { lat: 48.8749988, lng: 18.072365 },
    { lat: 48.8749499, lng: 18.0721074 },
    { lat: 48.874943, lng: 18.0716605 },
    { lat: 48.8749719, lng: 18.0710547 },
    { lat: 48.8749813, lng: 18.07054 },
    { lat: 48.8750313, lng: 18.0700607 },
    { lat: 48.8750198, lng: 18.0699049 },
    { lat: 48.8750053, lng: 18.06981 },
    { lat: 48.8749662, lng: 18.0697034 },
    { lat: 48.8749007, lng: 18.0695462 },
    { lat: 48.8748368, lng: 18.0694193 },
    { lat: 48.8747027, lng: 18.0691738 },
    { lat: 48.8746191, lng: 18.0689968 },
    { lat: 48.8746417, lng: 18.0691121 },
    { lat: 48.8744648, lng: 18.0691775 },
    { lat: 48.8743663, lng: 18.0692083 },
    { lat: 48.8742683, lng: 18.0692301 },
    { lat: 48.8742348, lng: 18.0692363 },
    { lat: 48.8742008, lng: 18.0692373 },
    { lat: 48.8741349, lng: 18.0692458 },
    { lat: 48.8740665, lng: 18.0692532 },
    { lat: 48.8738227, lng: 18.0692742 },
    { lat: 48.873806, lng: 18.0692761 },
    { lat: 48.8737658, lng: 18.0692635 },
    { lat: 48.8733071, lng: 18.069119 },
    { lat: 48.8731568, lng: 18.0690805 },
    { lat: 48.8731191, lng: 18.0690712 },
    { lat: 48.8730757, lng: 18.0690602 },
    { lat: 48.8728524, lng: 18.0689862 },
    { lat: 48.8726568, lng: 18.0689214 },
    { lat: 48.8725706, lng: 18.0687412 },
    { lat: 48.8724872, lng: 18.0685692 },
    { lat: 48.8724098, lng: 18.0684068 },
    { lat: 48.8723316, lng: 18.0682456 },
    { lat: 48.8722609, lng: 18.0680963 },
    { lat: 48.8722169, lng: 18.068014 },
    { lat: 48.8721732, lng: 18.0679321 },
    { lat: 48.8720834, lng: 18.0677825 },
    { lat: 48.8719945, lng: 18.0676342 },
    { lat: 48.8718968, lng: 18.0674871 },
    { lat: 48.8718226, lng: 18.0673759 },
    { lat: 48.8717969, lng: 18.0673245 },
    { lat: 48.8717153, lng: 18.0671623 },
    { lat: 48.8716397, lng: 18.06701 },
    { lat: 48.8715512, lng: 18.066847 },
    { lat: 48.8714676, lng: 18.0666919 },
    { lat: 48.8713752, lng: 18.0665193 },
    { lat: 48.8713016, lng: 18.0663804 },
    { lat: 48.8712153, lng: 18.0664093 },
    { lat: 48.8711478, lng: 18.066432 },
    { lat: 48.8710615, lng: 18.0664611 },
    { lat: 48.8709706, lng: 18.0664916 },
    { lat: 48.8708477, lng: 18.0665329 },
    { lat: 48.8707186, lng: 18.0666029 },
    { lat: 48.8705896, lng: 18.066673 },
    { lat: 48.8704617, lng: 18.0667422 },
    { lat: 48.8703402, lng: 18.0668083 },
    { lat: 48.8703196, lng: 18.0668197 },
    { lat: 48.8702845, lng: 18.0668384 },
    { lat: 48.8701482, lng: 18.0668456 },
    { lat: 48.8699752, lng: 18.0668549 },
    { lat: 48.8698288, lng: 18.0668624 },
    { lat: 48.8698052, lng: 18.0668634 },
    { lat: 48.8695699, lng: 18.0668147 },
    { lat: 48.8694018, lng: 18.06678 },
    { lat: 48.8693482, lng: 18.066671 },
    { lat: 48.8692459, lng: 18.0664665 },
    { lat: 48.8692408, lng: 18.0664571 },
    { lat: 48.8691767, lng: 18.0663274 },
    { lat: 48.8691029, lng: 18.0661814 },
    { lat: 48.8690351, lng: 18.0660449 },
    { lat: 48.8689646, lng: 18.0659028 },
    { lat: 48.8688957, lng: 18.0657647 },
    { lat: 48.868821, lng: 18.0656077 },
    { lat: 48.8687496, lng: 18.0654667 },
    { lat: 48.868675, lng: 18.0653173 },
    { lat: 48.8686626, lng: 18.0652924 },
    { lat: 48.868582, lng: 18.0651326 },
    { lat: 48.8685017, lng: 18.0649724 },
    { lat: 48.8684197, lng: 18.0648085 },
    { lat: 48.8683278, lng: 18.0646244 },
    { lat: 48.8682358, lng: 18.064441 },
    { lat: 48.8681554, lng: 18.0642801 },
    { lat: 48.8680747, lng: 18.0641178 },
    { lat: 48.8679637, lng: 18.0638976 },
    { lat: 48.8679011, lng: 18.0637734 },
    { lat: 48.8678738, lng: 18.0636355 },
    { lat: 48.8678334, lng: 18.0634294 },
    { lat: 48.8677971, lng: 18.0632339 },
    { lat: 48.8677539, lng: 18.0630124 },
    { lat: 48.8677121, lng: 18.0629937 },
    { lat: 48.867576, lng: 18.0631568 },
    { lat: 48.8674844, lng: 18.0632692 },
    { lat: 48.8673832, lng: 18.0633907 },
    { lat: 48.8672736, lng: 18.0635219 },
    { lat: 48.8672277, lng: 18.0632797 },
    { lat: 48.8672144, lng: 18.0632055 },
    { lat: 48.8671999, lng: 18.0631318 },
    { lat: 48.8671702, lng: 18.0629719 },
    { lat: 48.8671339, lng: 18.0627754 },
    { lat: 48.8670927, lng: 18.0625529 },
    { lat: 48.8670511, lng: 18.0623259 },
    { lat: 48.8671018, lng: 18.0622273 },
    { lat: 48.8672237, lng: 18.061994 },
    { lat: 48.8673218, lng: 18.0618041 },
    { lat: 48.8674385, lng: 18.0615801 },
    { lat: 48.8674411, lng: 18.0614099 },
    { lat: 48.8674439, lng: 18.0612281 },
    { lat: 48.8674466, lng: 18.0610603 },
    { lat: 48.8674488, lng: 18.0609073 },
    { lat: 48.8674511, lng: 18.0607328 },
    { lat: 48.867454, lng: 18.0605732 },
    { lat: 48.8674539, lng: 18.06055 },
    { lat: 48.8674572, lng: 18.0603929 },
    { lat: 48.8674579, lng: 18.0603476 },
    { lat: 48.86746, lng: 18.0601903 },
    { lat: 48.86746, lng: 18.0601541 },
    { lat: 48.867463, lng: 18.0600093 },
    { lat: 48.8674633, lng: 18.059997 },
    { lat: 48.8673765, lng: 18.059847 },
    { lat: 48.8673452, lng: 18.0597931 },
    { lat: 48.8672409, lng: 18.0596115 },
    { lat: 48.8671454, lng: 18.0594469 },
    { lat: 48.8671267, lng: 18.0594142 },
    { lat: 48.8670425, lng: 18.0592679 },
    { lat: 48.8669659, lng: 18.0591355 },
    { lat: 48.8669569, lng: 18.0591191 },
    { lat: 48.8669184, lng: 18.0590956 },
    { lat: 48.8667005, lng: 18.0590569 },
    { lat: 48.8663862, lng: 18.0590662 },
    { lat: 48.8670214, lng: 18.058581 },
    { lat: 48.8673091, lng: 18.0584627 },
    { lat: 48.8672811, lng: 18.0580646 },
    { lat: 48.8679997, lng: 18.0580184 },
    { lat: 48.8679642, lng: 18.0574339 },
    { lat: 48.8679413, lng: 18.0572513 },
    { lat: 48.8679395, lng: 18.0572411 },
    { lat: 48.8679096, lng: 18.0572107 },
    { lat: 48.8676043, lng: 18.0571471 },
    { lat: 48.8671885, lng: 18.0570612 },
    { lat: 48.8671189, lng: 18.0570476 },
    { lat: 48.8669396, lng: 18.0570112 },
    { lat: 48.8668996, lng: 18.057023 },
    { lat: 48.866815, lng: 18.0570598 },
    { lat: 48.8666223, lng: 18.0571424 },
    { lat: 48.8665013, lng: 18.057215 },
    { lat: 48.8662767, lng: 18.0573499 },
    { lat: 48.8661342, lng: 18.0574246 },
    { lat: 48.8658869, lng: 18.0575554 },
    { lat: 48.8658537, lng: 18.0575656 },
    { lat: 48.8657106, lng: 18.0576096 },
    { lat: 48.8656102, lng: 18.0576271 },
    { lat: 48.8654514, lng: 18.0576535 },
    { lat: 48.8653449, lng: 18.0576646 },
    { lat: 48.8651273, lng: 18.0576878 },
    { lat: 48.8650766, lng: 18.0576914 },
    { lat: 48.8649138, lng: 18.0577058 },
    { lat: 48.8645973, lng: 18.0577362 },
    { lat: 48.8641687, lng: 18.0577785 },
    { lat: 48.8640292, lng: 18.0578034 },
    { lat: 48.8637778, lng: 18.0578476 },
    { lat: 48.8636891, lng: 18.0578556 },
    { lat: 48.8634905, lng: 18.0578737 },
    { lat: 48.863426, lng: 18.0578896 },
    { lat: 48.8632502, lng: 18.0579326 },
    { lat: 48.8631807, lng: 18.057962 },
    { lat: 48.8630024, lng: 18.058038 },
    { lat: 48.8628157, lng: 18.0581664 },
    { lat: 48.8625272, lng: 18.058365 },
    { lat: 48.8624725, lng: 18.0584151 },
    { lat: 48.8623225, lng: 18.0585514 },
    { lat: 48.8621955, lng: 18.0586767 },
    { lat: 48.8620821, lng: 18.0587637 },
    { lat: 48.8618694, lng: 18.0589274 },
    { lat: 48.8618438, lng: 18.0589506 },
    { lat: 48.8617228, lng: 18.0590611 },
    { lat: 48.8614228, lng: 18.0593177 },
    { lat: 48.8610263, lng: 18.0596581 },
    { lat: 48.8606612, lng: 18.0599732 },
    { lat: 48.8601981, lng: 18.0603724 },
    { lat: 48.860059, lng: 18.060504 },
    { lat: 48.859826, lng: 18.0607257 },
    { lat: 48.859781, lng: 18.0607658 },
    { lat: 48.8596394, lng: 18.0608913 },
    { lat: 48.8595277, lng: 18.0609902 },
    { lat: 48.8595257, lng: 18.0609561 },
    { lat: 48.8595254, lng: 18.0609515 },
    { lat: 48.8593973, lng: 18.0587035 },
    { lat: 48.8594049, lng: 18.0586401 },
    { lat: 48.8594408, lng: 18.0583894 },
    { lat: 48.8594538, lng: 18.0582077 },
    { lat: 48.8594856, lng: 18.0577458 },
    { lat: 48.8594786, lng: 18.0577026 },
    { lat: 48.8594944, lng: 18.0573578 },
    { lat: 48.8594939, lng: 18.0572533 },
    { lat: 48.859487, lng: 18.0569216 },
    { lat: 48.859478, lng: 18.0567891 },
    { lat: 48.8594381, lng: 18.0565117 },
    { lat: 48.8593863, lng: 18.0562441 },
    { lat: 48.8593881, lng: 18.056198 },
    { lat: 48.8593482, lng: 18.055941 },
    { lat: 48.8593122, lng: 18.0556534 },
    { lat: 48.8592729, lng: 18.0554271 },
    { lat: 48.8592617, lng: 18.0553132 },
    { lat: 48.8592585, lng: 18.0550438 },
    { lat: 48.8592647, lng: 18.0548999 },
    { lat: 48.8592793, lng: 18.0547463 },
    { lat: 48.8593168, lng: 18.0545589 },
    { lat: 48.8593626, lng: 18.0544154 },
    { lat: 48.8593934, lng: 18.054345 },
    { lat: 48.8594847, lng: 18.0541707 },
    { lat: 48.8596181, lng: 18.0538079 },
    { lat: 48.8596739, lng: 18.0536018 },
    { lat: 48.8597287, lng: 18.053364 },
    { lat: 48.859764, lng: 18.0532159 },
    { lat: 48.859782, lng: 18.0531762 },
    { lat: 48.8598618, lng: 18.0528418 },
    { lat: 48.8598668, lng: 18.0528146 },
    { lat: 48.8599164, lng: 18.0526401 },
    { lat: 48.8599583, lng: 18.052447 },
    { lat: 48.8599856, lng: 18.0522993 },
    { lat: 48.8599866, lng: 18.0522558 },
    { lat: 48.8599917, lng: 18.052078 },
    { lat: 48.8599881, lng: 18.0518785 },
    { lat: 48.8599623, lng: 18.0514397 },
    { lat: 48.8599651, lng: 18.0513332 },
    { lat: 48.8600269, lng: 18.051041 },
    { lat: 48.8600621, lng: 18.0509668 },
    { lat: 48.8600922, lng: 18.0508276 },
    { lat: 48.8601846, lng: 18.0504961 },
    { lat: 48.8602144, lng: 18.05039 },
    { lat: 48.8602488, lng: 18.0502434 },
    { lat: 48.8601358, lng: 18.0501841 },
    { lat: 48.8601155, lng: 18.0501646 },
    { lat: 48.8595773, lng: 18.0502819 },
    { lat: 48.8594925, lng: 18.0503001 },
    { lat: 48.8594825, lng: 18.0503026 },
    { lat: 48.8594025, lng: 18.0503216 },
    { lat: 48.8593444, lng: 18.0503344 },
    { lat: 48.8591996, lng: 18.0503661 },
    { lat: 48.8590782, lng: 18.0503929 },
    { lat: 48.8590303, lng: 18.0504036 },
    { lat: 48.8590324, lng: 18.0506521 },
    { lat: 48.8590305, lng: 18.0509459 },
    { lat: 48.8590354, lng: 18.0511477 },
    { lat: 48.8590069, lng: 18.0511408 },
    { lat: 48.8581022, lng: 18.0509935 },
    { lat: 48.8578379, lng: 18.0509507 },
    { lat: 48.8578276, lng: 18.0509492 },
    { lat: 48.8566698, lng: 18.0507604 },
    { lat: 48.8564169, lng: 18.0507192 },
    { lat: 48.8563525, lng: 18.0507376 },
    { lat: 48.855943, lng: 18.0507397 },
    { lat: 48.8559466, lng: 18.052014 },
    { lat: 48.8559091, lng: 18.0520076 },
    { lat: 48.8559116, lng: 18.0520517 },
    { lat: 48.8559447, lng: 18.0529418 },
    { lat: 48.8559845, lng: 18.0533149 },
    { lat: 48.8560605, lng: 18.053457 },
    { lat: 48.8560953, lng: 18.053663 },
    { lat: 48.8560244, lng: 18.0538783 },
    { lat: 48.8560137, lng: 18.0540059 },
    { lat: 48.8559579, lng: 18.0540342 },
    { lat: 48.8558255, lng: 18.0541076 },
    { lat: 48.8557883, lng: 18.0542025 },
    { lat: 48.8557982, lng: 18.0546979 },
    { lat: 48.8558198, lng: 18.0557214 },
    { lat: 48.8558204, lng: 18.0557515 },
    { lat: 48.8554719, lng: 18.0563342 },
    { lat: 48.8553426, lng: 18.0565516 },
    { lat: 48.8553251, lng: 18.05656 },
    { lat: 48.8549761, lng: 18.0566908 },
    { lat: 48.8549104, lng: 18.0566671 },
    { lat: 48.8548496, lng: 18.0568243 },
    { lat: 48.8548491, lng: 18.0569119 },
    { lat: 48.8548508, lng: 18.0569614 },
    { lat: 48.8548612, lng: 18.0570217 },
    { lat: 48.8548738, lng: 18.0570634 },
    { lat: 48.8549102, lng: 18.0571839 },
    { lat: 48.8549472, lng: 18.0573151 },
    { lat: 48.8549641, lng: 18.0573931 },
    { lat: 48.8550252, lng: 18.0575479 },
    { lat: 48.8550649, lng: 18.0576494 },
    { lat: 48.855085, lng: 18.0577015 },
    { lat: 48.8551221, lng: 18.0578187 },
    { lat: 48.855151, lng: 18.057903 },
    { lat: 48.8551679, lng: 18.0579613 },
    { lat: 48.855215, lng: 18.0581327 },
    { lat: 48.8552406, lng: 18.0582375 },
    { lat: 48.8553078, lng: 18.0585206 },
    { lat: 48.8553883, lng: 18.0589509 },
    { lat: 48.8554032, lng: 18.059031 },
    { lat: 48.8554228, lng: 18.0591513 },
    { lat: 48.855431, lng: 18.059215 },
    { lat: 48.8554495, lng: 18.0592799 },
    { lat: 48.8554633, lng: 18.0593433 },
    { lat: 48.8554719, lng: 18.0593762 },
    { lat: 48.8556138, lng: 18.059332 },
    { lat: 48.855684, lng: 18.0595096 },
    { lat: 48.855743, lng: 18.059669 },
    { lat: 48.8557706, lng: 18.0597714 },
    { lat: 48.8558177, lng: 18.0599428 },
    { lat: 48.8554439, lng: 18.06013 },
    { lat: 48.8551051, lng: 18.0602247 },
    { lat: 48.8546907, lng: 18.0603357 },
    { lat: 48.8541739, lng: 18.060412 },
    { lat: 48.8532961, lng: 18.0604932 },
    { lat: 48.8527283, lng: 18.0604929 },
    { lat: 48.8525249, lng: 18.0605048 },
    { lat: 48.8522762, lng: 18.0604788 },
    { lat: 48.852248, lng: 18.0605582 },
    { lat: 48.8522624, lng: 18.0607068 },
    { lat: 48.8523024, lng: 18.0608121 },
    { lat: 48.8523628, lng: 18.0609764 },
    { lat: 48.8524004, lng: 18.0610197 },
    { lat: 48.8523873, lng: 18.0610265 },
    { lat: 48.8524158, lng: 18.0612824 },
    { lat: 48.8525427, lng: 18.0624078 },
    { lat: 48.852607, lng: 18.062969 },
    { lat: 48.8525795, lng: 18.0629587 },
    { lat: 48.8527628, lng: 18.0631817 },
    { lat: 48.8509068, lng: 18.0629195 },
    { lat: 48.8508896, lng: 18.0629165 },
    { lat: 48.8508221, lng: 18.0628983 },
    { lat: 48.8500484, lng: 18.0627544 },
    { lat: 48.8499223, lng: 18.0627781 },
    { lat: 48.8496949, lng: 18.0629406 },
    { lat: 48.8496819, lng: 18.0629491 },
    { lat: 48.8496885, lng: 18.0629855 },
    { lat: 48.8492947, lng: 18.0632592 },
    { lat: 48.8490392, lng: 18.0633663 },
    { lat: 48.8486463, lng: 18.0635345 },
    { lat: 48.8485468, lng: 18.0635314 },
    { lat: 48.8485569, lng: 18.0635906 },
    { lat: 48.8485534, lng: 18.0637255 },
    { lat: 48.8485524, lng: 18.0638772 },
    { lat: 48.8484817, lng: 18.0642895 },
    { lat: 48.8482288, lng: 18.0655034 },
    { lat: 48.8481876, lng: 18.0657046 },
    { lat: 48.8480442, lng: 18.0662041 },
    { lat: 48.8476349, lng: 18.0670015 },
    { lat: 48.8473916, lng: 18.0675688 },
    { lat: 48.8473743, lng: 18.0676396 },
    { lat: 48.8472729, lng: 18.0681065 },
    { lat: 48.847263, lng: 18.0682272 },
    { lat: 48.8472131, lng: 18.0687448 },
    { lat: 48.8471614, lng: 18.0693441 },
    { lat: 48.8470827, lng: 18.0697743 },
    { lat: 48.8470033, lng: 18.0702117 },
    { lat: 48.8469321, lng: 18.071173 },
    { lat: 48.8466565, lng: 18.0721334 },
    { lat: 48.8465347, lng: 18.0723482 },
    { lat: 48.8465185, lng: 18.0723988 },
    { lat: 48.8464993, lng: 18.0724594 },
    { lat: 48.8464365, lng: 18.0726183 },
    { lat: 48.8464223, lng: 18.0726664 },
    { lat: 48.8464068, lng: 18.0727445 },
    { lat: 48.8462978, lng: 18.0731988 },
    { lat: 48.8462169, lng: 18.0735131 },
    { lat: 48.8459788, lng: 18.0745176 },
    { lat: 48.8458674, lng: 18.0749664 },
    { lat: 48.8458868, lng: 18.0753956 },
    { lat: 48.8459073, lng: 18.07596 },
    { lat: 48.8458605, lng: 18.0762795 },
    { lat: 48.8457372, lng: 18.0770263 },
    { lat: 48.8456124, lng: 18.0776511 },
    { lat: 48.8454011, lng: 18.0786737 },
    { lat: 48.8452139, lng: 18.0795817 },
    { lat: 48.8450404, lng: 18.0802994 },
    { lat: 48.844969, lng: 18.0806439 },
    { lat: 48.8448084, lng: 18.081352 },
    { lat: 48.8447591, lng: 18.0815729 },
    { lat: 48.84463, lng: 18.0820871 },
    { lat: 48.844322, lng: 18.0831711 },
    { lat: 48.8440217, lng: 18.0837466 },
    { lat: 48.843892, lng: 18.0839593 },
    { lat: 48.8437818, lng: 18.0841625 },
    { lat: 48.8437962, lng: 18.0841774 },
    { lat: 48.8437192, lng: 18.0844987 },
    { lat: 48.8438608, lng: 18.0847012 },
    { lat: 48.84406, lng: 18.084792 },
    { lat: 48.844262, lng: 18.084694 },
    { lat: 48.8445174, lng: 18.0848167 },
    { lat: 48.8446259, lng: 18.0849537 },
    { lat: 48.8440713, lng: 18.0856203 },
    { lat: 48.8439486, lng: 18.0857027 },
    { lat: 48.8438922, lng: 18.0858987 },
    { lat: 48.843685, lng: 18.0861371 },
    { lat: 48.8436115, lng: 18.0863079 },
    { lat: 48.8435212, lng: 18.0864636 },
    { lat: 48.8434831, lng: 18.0866239 },
    { lat: 48.8433338, lng: 18.086809 },
    { lat: 48.8433169, lng: 18.0870012 },
    { lat: 48.8432302, lng: 18.0871615 },
    { lat: 48.8430492, lng: 18.0873871 },
    { lat: 48.8428937, lng: 18.0873718 },
    { lat: 48.8427456, lng: 18.087445 },
    { lat: 48.8427087, lng: 18.0874327 },
    { lat: 48.8426905, lng: 18.0874276 },
    { lat: 48.8426011, lng: 18.0874249 },
    { lat: 48.8421127, lng: 18.0882205 },
    { lat: 48.8417569, lng: 18.0885645 },
    { lat: 48.8415137, lng: 18.0887687 },
    { lat: 48.8412923, lng: 18.0890355 },
    { lat: 48.8410028, lng: 18.0893706 },
    { lat: 48.8409463, lng: 18.0902587 },
    { lat: 48.8409326, lng: 18.0906848 },
    { lat: 48.8409797, lng: 18.0915695 },
    { lat: 48.8410299, lng: 18.0930299 },
    { lat: 48.8405095, lng: 18.0949625 },
    { lat: 48.8402348, lng: 18.0962138 },
    { lat: 48.8404416, lng: 18.0972065 },
    { lat: 48.8408595, lng: 18.0984532 },
    { lat: 48.8406692, lng: 18.0988269 },
    { lat: 48.8400222, lng: 18.1000975 },
    { lat: 48.8400396, lng: 18.100222 },
    { lat: 48.8394186, lng: 18.1007417 },
    { lat: 48.8388867, lng: 18.1010242 },
    { lat: 48.838366, lng: 18.1013252 },
    { lat: 48.8380683, lng: 18.1015044 },
    { lat: 48.8377096, lng: 18.1016848 },
    { lat: 48.8372755, lng: 18.1019976 },
    { lat: 48.8370612, lng: 18.1021194 },
    { lat: 48.8376109, lng: 18.102815 },
    { lat: 48.8379043, lng: 18.1031842 },
    { lat: 48.8380941, lng: 18.1034422 },
    { lat: 48.8381647, lng: 18.1034285 },
    { lat: 48.838689, lng: 18.1039284 },
    { lat: 48.8388715, lng: 18.1041742 },
    { lat: 48.8391036, lng: 18.1044212 },
    { lat: 48.8392263, lng: 18.1045298 },
    { lat: 48.8394284, lng: 18.1047063 },
    { lat: 48.8396152, lng: 18.1049704 },
    { lat: 48.8400966, lng: 18.1056198 },
    { lat: 48.8401469, lng: 18.1057281 },
    { lat: 48.8409523, lng: 18.1070392 },
    { lat: 48.8412554, lng: 18.1075186 },
    { lat: 48.8414369, lng: 18.1079221 },
    { lat: 48.8416289, lng: 18.1080408 },
    { lat: 48.8419063, lng: 18.1080167 },
    { lat: 48.842067, lng: 18.1079648 },
    { lat: 48.8424872, lng: 18.108363 },
    { lat: 48.8427294, lng: 18.1085875 },
    { lat: 48.8428197, lng: 18.1089162 },
    { lat: 48.8430119, lng: 18.1096228 },
    { lat: 48.8435015, lng: 18.1104904 },
    { lat: 48.8439183, lng: 18.1105372 },
    { lat: 48.8441365, lng: 18.1105081 },
    { lat: 48.8442762, lng: 18.1105024 },
    { lat: 48.844732, lng: 18.1104239 },
    { lat: 48.8448625, lng: 18.1107279 },
    { lat: 48.8450784, lng: 18.1113136 },
    { lat: 48.8452395, lng: 18.1118342 },
    { lat: 48.8454217, lng: 18.1122315 },
    { lat: 48.8454856, lng: 18.1123778 },
    { lat: 48.8457442, lng: 18.112808 },
    { lat: 48.8460219, lng: 18.1132772 },
    { lat: 48.8460576, lng: 18.1133245 },
    { lat: 48.8462861, lng: 18.1136271 },
    { lat: 48.8464423, lng: 18.1138707 },
    { lat: 48.8464806, lng: 18.1139265 },
    { lat: 48.8467608, lng: 18.1146505 },
    { lat: 48.846895, lng: 18.1152095 },
    { lat: 48.8471721, lng: 18.1158637 },
    { lat: 48.8471932, lng: 18.115934 },
    { lat: 48.8469136, lng: 18.1165811 },
    { lat: 48.8468809, lng: 18.1166307 },
    { lat: 48.8464719, lng: 18.1172523 },
    { lat: 48.8464227, lng: 18.117317 },
    { lat: 48.846198, lng: 18.1176669 },
    { lat: 48.8459924, lng: 18.1179734 },
    { lat: 48.8458514, lng: 18.1182054 },
    { lat: 48.8458386, lng: 18.1182372 },
    { lat: 48.8457087, lng: 18.1185859 },
    { lat: 48.8456667, lng: 18.1187377 },
    { lat: 48.8455827, lng: 18.1193677 },
    { lat: 48.8454849, lng: 18.1202127 },
    { lat: 48.8454346, lng: 18.1210169 },
    { lat: 48.8452637, lng: 18.12235 },
    { lat: 48.8451743, lng: 18.123258 },
    { lat: 48.8451393, lng: 18.1235511 },
    { lat: 48.845056, lng: 18.1242752 },
    { lat: 48.8449197, lng: 18.1254642 },
    { lat: 48.8448577, lng: 18.125834 },
    { lat: 48.8447714, lng: 18.1262138 },
    { lat: 48.8451917, lng: 18.1264953 },
    { lat: 48.8454416, lng: 18.1265029 },
    { lat: 48.8456812, lng: 18.1263577 },
    { lat: 48.8458473, lng: 18.1262822 },
    { lat: 48.8461183, lng: 18.1261476 },
    { lat: 48.8463539, lng: 18.1259274 },
    { lat: 48.8465284, lng: 18.1257494 },
    { lat: 48.8467332, lng: 18.1255404 },
    { lat: 48.8467374, lng: 18.1255366 },
    { lat: 48.8470246, lng: 18.1252682 },
    { lat: 48.8470575, lng: 18.1252375 },
    { lat: 48.8473787, lng: 18.1252305 },
    { lat: 48.8476059, lng: 18.1253927 },
    { lat: 48.8476057, lng: 18.1257023 },
    { lat: 48.8477473, lng: 18.1258289 },
    { lat: 48.8478248, lng: 18.1258995 },
    { lat: 48.847906, lng: 18.1259184 },
    { lat: 48.8483326, lng: 18.1259949 },
    { lat: 48.8488269, lng: 18.1260946 },
    { lat: 48.8489611, lng: 18.1261706 },
    { lat: 48.8492065, lng: 18.1268008 },
    { lat: 48.8495062, lng: 18.1269866 },
    { lat: 48.8500712, lng: 18.1272353 },
    { lat: 48.8505963, lng: 18.1271245 },
    { lat: 48.8506862, lng: 18.1271573 },
    { lat: 48.8510822, lng: 18.1275844 },
    { lat: 48.8512531, lng: 18.1278232 },
    { lat: 48.8513253, lng: 18.1279788 },
    { lat: 48.8513824, lng: 18.1282267 },
    { lat: 48.8515292, lng: 18.1284784 },
    { lat: 48.85196, lng: 18.1288234 },
    { lat: 48.8523898, lng: 18.1289404 },
    { lat: 48.8528204, lng: 18.129045 },
    { lat: 48.8531605, lng: 18.1290753 },
    { lat: 48.853318, lng: 18.1290877 },
    { lat: 48.8538791, lng: 18.1289155 },
    { lat: 48.8541573, lng: 18.1293336 },
    { lat: 48.8541781, lng: 18.1293623 },
    { lat: 48.8543863, lng: 18.1299356 },
    { lat: 48.8552361, lng: 18.1307732 },
    { lat: 48.8554087, lng: 18.1309491 },
    { lat: 48.8557259, lng: 18.1315333 },
    { lat: 48.8559454, lng: 18.1316814 },
    { lat: 48.8565708, lng: 18.1318035 },
    { lat: 48.8570309, lng: 18.1319315 },
    { lat: 48.8576401, lng: 18.1321041 },
    { lat: 48.8573255, lng: 18.1325768 },
    { lat: 48.8570519, lng: 18.133188 },
    { lat: 48.8569763, lng: 18.1336579 },
    { lat: 48.8571352, lng: 18.1344334 },
    { lat: 48.8571499, lng: 18.1345131 },
    { lat: 48.8572235, lng: 18.1347302 },
    { lat: 48.8573622, lng: 18.1349242 },
    { lat: 48.8577292, lng: 18.1350833 },
    { lat: 48.8581889, lng: 18.1352257 },
    { lat: 48.8582531, lng: 18.1352637 },
    { lat: 48.8583958, lng: 18.1353687 },
    { lat: 48.8588299, lng: 18.1356744 },
    { lat: 48.8589088, lng: 18.135733 },
    { lat: 48.859127, lng: 18.136129 },
    { lat: 48.8594655, lng: 18.1369678 },
    { lat: 48.8599521, lng: 18.1367382 },
    { lat: 48.8607134, lng: 18.136614 },
    { lat: 48.8607325, lng: 18.1364154 },
    { lat: 48.8612734, lng: 18.1363234 },
    { lat: 48.8613027, lng: 18.1360697 },
    { lat: 48.8613956, lng: 18.1361147 },
    { lat: 48.8615855, lng: 18.1359472 },
    { lat: 48.8617608, lng: 18.1366725 },
    { lat: 48.8618687, lng: 18.1371148 },
    { lat: 48.8618681, lng: 18.137532 },
    { lat: 48.8618488, lng: 18.1382726 },
    { lat: 48.8623594, lng: 18.1381441 },
    { lat: 48.8627977, lng: 18.1379367 },
    { lat: 48.8629579, lng: 18.1378007 },
    { lat: 48.8632737, lng: 18.1375254 },
    { lat: 48.8635277, lng: 18.1375859 },
    { lat: 48.8637178, lng: 18.1374555 },
    { lat: 48.8637927, lng: 18.1374054 },
    { lat: 48.8641379, lng: 18.137152 },
    { lat: 48.864138, lng: 18.1366466 },
    { lat: 48.8641347, lng: 18.1361403 },
    { lat: 48.8642472, lng: 18.1361149 },
    { lat: 48.8647265, lng: 18.136307 },
    { lat: 48.8649903, lng: 18.1362608 },
    { lat: 48.8650075, lng: 18.1363811 },
    { lat: 48.865637, lng: 18.1362436 },
    { lat: 48.865889, lng: 18.135956 },
    { lat: 48.8664003, lng: 18.1359775 },
    { lat: 48.8662515, lng: 18.1358042 },
    { lat: 48.8662082, lng: 18.1353504 },
    { lat: 48.8661657, lng: 18.1349073 },
    { lat: 48.8660677, lng: 18.1344433 },
    { lat: 48.8659599, lng: 18.1340243 },
    { lat: 48.8657659, lng: 18.1332669 },
    { lat: 48.865638, lng: 18.1324796 },
    { lat: 48.8659428, lng: 18.1319003 },
    { lat: 48.8660989, lng: 18.1316717 },
    { lat: 48.8663816, lng: 18.1311559 },
    { lat: 48.8668074, lng: 18.1305956 },
    { lat: 48.867417, lng: 18.1302524 },
    { lat: 48.8676001, lng: 18.1303714 },
    { lat: 48.8679039, lng: 18.1301839 },
    { lat: 48.8679553, lng: 18.130152 },
    { lat: 48.8684193, lng: 18.1297081 },
    { lat: 48.8686934, lng: 18.1293372 },
    { lat: 48.8689356, lng: 18.128474 },
    { lat: 48.8691624, lng: 18.1274778 },
    { lat: 48.869329, lng: 18.1269308 },
    { lat: 48.8693546, lng: 18.1265514 },
    { lat: 48.8693749, lng: 18.1260859 },
    { lat: 48.8695404, lng: 18.1254369 },
    { lat: 48.869708, lng: 18.1249234 },
    { lat: 48.8698645, lng: 18.1244689 },
    { lat: 48.8696854, lng: 18.1241414 },
    { lat: 48.869334, lng: 18.1236484 },
    { lat: 48.869246, lng: 18.1232605 },
    { lat: 48.8691784, lng: 18.1227755 },
    { lat: 48.869167, lng: 18.1226946 },
    { lat: 48.8691629, lng: 18.122684 },
    { lat: 48.8691537, lng: 18.1226609 },
    { lat: 48.8691353, lng: 18.122616 },
    { lat: 48.8690695, lng: 18.1224563 },
    { lat: 48.8687984, lng: 18.1221003 },
    { lat: 48.8689304, lng: 18.1216529 },
    { lat: 48.8689555, lng: 18.1212653 },
    { lat: 48.8688269, lng: 18.1206466 },
    { lat: 48.8687103, lng: 18.1200556 },
    { lat: 48.8685997, lng: 18.1197431 },
    { lat: 48.8684433, lng: 18.119265 },
    { lat: 48.8684715, lng: 18.1188017 },
    { lat: 48.8684721, lng: 18.1187923 },
    { lat: 48.8684877, lng: 18.1184948 },
    { lat: 48.8685581, lng: 18.118156 },
    { lat: 48.8685571, lng: 18.1179995 },
    { lat: 48.8685566, lng: 18.1179913 },
    { lat: 48.8685314, lng: 18.1178298 },
    { lat: 48.8684909, lng: 18.1175807 },
    { lat: 48.8683884, lng: 18.116866 },
    { lat: 48.8683392, lng: 18.1168539 },
    { lat: 48.8683277, lng: 18.1168515 },
    { lat: 48.8682401, lng: 18.116641 },
    { lat: 48.8682095, lng: 18.1164447 },
    { lat: 48.8681669, lng: 18.1161793 },
    { lat: 48.8682076, lng: 18.1157527 },
    { lat: 48.8682016, lng: 18.1152628 },
    { lat: 48.868114, lng: 18.1150526 },
    { lat: 48.8680666, lng: 18.1149367 },
    { lat: 48.8680653, lng: 18.1149347 },
    { lat: 48.8679776, lng: 18.1143371 },
    { lat: 48.8679617, lng: 18.1143148 },
    { lat: 48.8678197, lng: 18.1137194 },
    { lat: 48.8677194, lng: 18.1129624 },
    { lat: 48.8675797, lng: 18.112071 },
    { lat: 48.8673921, lng: 18.1113494 },
    { lat: 48.8672227, lng: 18.1104372 },
    { lat: 48.8667794, lng: 18.1096411 },
    { lat: 48.8663979, lng: 18.1091204 },
    { lat: 48.8662203, lng: 18.1086229 },
    { lat: 48.8659549, lng: 18.1081748 },
    { lat: 48.866081, lng: 18.1069113 },
    { lat: 48.8667525, lng: 18.1057477 },
    { lat: 48.8670938, lng: 18.1050375 },
    { lat: 48.8675553, lng: 18.1048812 },
    { lat: 48.8678822, lng: 18.1046984 },
    { lat: 48.8680131, lng: 18.104563 },
    { lat: 48.8681559, lng: 18.1037219 },
    { lat: 48.8684363, lng: 18.1030134 },
    { lat: 48.8688841, lng: 18.1019796 },
    { lat: 48.8691525, lng: 18.1012604 },
    { lat: 48.8693169, lng: 18.1009814 },
    { lat: 48.8693444, lng: 18.1009336 },
    { lat: 48.8696914, lng: 18.1005933 },
    { lat: 48.8697299, lng: 18.1005559 },
    { lat: 48.8699633, lng: 18.100377 },
    { lat: 48.870229, lng: 18.1002902 },
    { lat: 48.8708813, lng: 18.1000556 },
    { lat: 48.8714962, lng: 18.0995338 },
    { lat: 48.8721467, lng: 18.0990363 },
    { lat: 48.8726137, lng: 18.0988365 },
    { lat: 48.8728996, lng: 18.0988036 },
    { lat: 48.8731627, lng: 18.0987906 },
    { lat: 48.8739258, lng: 18.0988506 },
    { lat: 48.8737679, lng: 18.098292 },
    { lat: 48.8738512, lng: 18.098212 },
    { lat: 48.8739283, lng: 18.098139 },
    { lat: 48.8743807, lng: 18.0976317 },
    { lat: 48.874922, lng: 18.0970255 },
    { lat: 48.8750374, lng: 18.0968964 },
    { lat: 48.8752176, lng: 18.0967127 },
    { lat: 48.8752517, lng: 18.0966777 },
    { lat: 48.8756223, lng: 18.0962997 },
    { lat: 48.8762901, lng: 18.0956538 },
    { lat: 48.8769185, lng: 18.0950502 },
    { lat: 48.8774328, lng: 18.0945637 },
    { lat: 48.8779733, lng: 18.0940585 },
    { lat: 48.8782074, lng: 18.0941233 },
    { lat: 48.878472, lng: 18.0938795 },
    { lat: 48.8789813, lng: 18.0935779 },
    { lat: 48.8794017, lng: 18.0932749 },
    { lat: 48.8797142, lng: 18.0931194 },
    { lat: 48.8802883, lng: 18.0929801 },
    { lat: 48.8806332, lng: 18.092954 },
    { lat: 48.8813827, lng: 18.0928576 },
    { lat: 48.8822232, lng: 18.0925832 },
    { lat: 48.8824045, lng: 18.0922477 },
    { lat: 48.8826762, lng: 18.0916803 },
    { lat: 48.8827782, lng: 18.0908946 },
    { lat: 48.882927, lng: 18.0903314 },
    { lat: 48.8827433, lng: 18.0899875 },
    { lat: 48.8827011, lng: 18.0898024 },
    { lat: 48.8826862, lng: 18.0894329 },
    { lat: 48.8824778, lng: 18.0892654 },
    { lat: 48.8823139, lng: 18.0890105 },
    { lat: 48.8820582, lng: 18.0881448 },
    { lat: 48.8820999, lng: 18.0879517 },
    { lat: 48.8819451, lng: 18.0877143 },
    { lat: 48.8819891, lng: 18.0876937 },
    { lat: 48.8819935, lng: 18.0876931 },
    { lat: 48.882038, lng: 18.0873727 },
    { lat: 48.8821061, lng: 18.0871455 },
    { lat: 48.8822016, lng: 18.0869093 },
    { lat: 48.8823834, lng: 18.0865798 },
    { lat: 48.8825696, lng: 18.0861726 },
    { lat: 48.8826131, lng: 18.0860714 },
    { lat: 48.8826328, lng: 18.0859985 },
    { lat: 48.8826812, lng: 18.0857657 },
    { lat: 48.8827246, lng: 18.0856179 },
    { lat: 48.8827318, lng: 18.0855886 },
    { lat: 48.8827733, lng: 18.0854271 },
    { lat: 48.882817, lng: 18.0851512 },
    { lat: 48.8828288, lng: 18.0848911 },
    { lat: 48.8827165, lng: 18.0846487 },
    { lat: 48.8826333, lng: 18.0845968 },
    { lat: 48.881852, lng: 18.0833727 },
    { lat: 48.8815121, lng: 18.0829633 },
    { lat: 48.8813436, lng: 18.0820848 },
    { lat: 48.8815966, lng: 18.0814897 },
    { lat: 48.8813974, lng: 18.0803974 },
    { lat: 48.8813866, lng: 18.0790908 },
    { lat: 48.8816131, lng: 18.0786197 },
    { lat: 48.8839232, lng: 18.0784835 },
    { lat: 48.8837394, lng: 18.078103 },
    { lat: 48.8832157, lng: 18.0779481 },
    { lat: 48.881731, lng: 18.0775088 },
    { lat: 48.8818881, lng: 18.0769549 },
    { lat: 48.8829184, lng: 18.0741691 },
    { lat: 48.8827587, lng: 18.0731966 },
    { lat: 48.8827535, lng: 18.072989 },
    { lat: 48.882746, lng: 18.0726501 },
    { lat: 48.8827424, lng: 18.0724924 },
    { lat: 48.8827059, lng: 18.0723901 },
    { lat: 48.8826845, lng: 18.0723306 },
    { lat: 48.882663, lng: 18.0722704 },
    { lat: 48.8826401, lng: 18.0722068 },
    { lat: 48.8826388, lng: 18.0722051 },
    { lat: 48.8821205, lng: 18.0707691 },
  ],
  TrenčianskaTurná: [
    { lat: 48.8522762, lng: 18.0604788 },
    { lat: 48.8522337, lng: 18.060564 },
    { lat: 48.8521549, lng: 18.060608 },
    { lat: 48.8519229, lng: 18.0606528 },
    { lat: 48.8517327, lng: 18.06072 },
    { lat: 48.8515237, lng: 18.0607534 },
    { lat: 48.8511829, lng: 18.0606753 },
    { lat: 48.8510765, lng: 18.0605757 },
    { lat: 48.8509619, lng: 18.060585 },
    { lat: 48.8508479, lng: 18.0606222 },
    { lat: 48.8506134, lng: 18.0606279 },
    { lat: 48.8505252, lng: 18.0605265 },
    { lat: 48.85045, lng: 18.060422 },
    { lat: 48.8503879, lng: 18.0604185 },
    { lat: 48.8504001, lng: 18.0602603 },
    { lat: 48.8504327, lng: 18.060009 },
    { lat: 48.8504273, lng: 18.0599373 },
    { lat: 48.8504013, lng: 18.0597595 },
    { lat: 48.8504365, lng: 18.0596508 },
    { lat: 48.8504564, lng: 18.05947 },
    { lat: 48.8504642, lng: 18.0593515 },
    { lat: 48.8505532, lng: 18.0592521 },
    { lat: 48.8506215, lng: 18.0591264 },
    { lat: 48.8506192, lng: 18.0589948 },
    { lat: 48.8505619, lng: 18.0589563 },
    { lat: 48.8504639, lng: 18.0588462 },
    { lat: 48.8504488, lng: 18.058783 },
    { lat: 48.8504325, lng: 18.0586781 },
    { lat: 48.8503734, lng: 18.058533 },
    { lat: 48.8503663, lng: 18.0584526 },
    { lat: 48.8504015, lng: 18.0582057 },
    { lat: 48.8504128, lng: 18.0580314 },
    { lat: 48.8504974, lng: 18.0578563 },
    { lat: 48.8504688, lng: 18.0577558 },
    { lat: 48.8504436, lng: 18.0575765 },
    { lat: 48.8505013, lng: 18.0573666 },
    { lat: 48.8504933, lng: 18.0572093 },
    { lat: 48.8504958, lng: 18.0571394 },
    { lat: 48.8505327, lng: 18.0569976 },
    { lat: 48.8505626, lng: 18.0567735 },
    { lat: 48.8505608, lng: 18.056706 },
    { lat: 48.850523, lng: 18.0564449 },
    { lat: 48.8505159, lng: 18.0563444 },
    { lat: 48.8505276, lng: 18.0561963 },
    { lat: 48.8505193, lng: 18.0561321 },
    { lat: 48.8504539, lng: 18.0560578 },
    { lat: 48.850431, lng: 18.0560136 },
    { lat: 48.8504563, lng: 18.0558084 },
    { lat: 48.8504535, lng: 18.0557608 },
    { lat: 48.8504335, lng: 18.0556889 },
    { lat: 48.850386, lng: 18.0555936 },
    { lat: 48.8503579, lng: 18.0554963 },
    { lat: 48.8503044, lng: 18.0553956 },
    { lat: 48.8503184, lng: 18.0552099 },
    { lat: 48.8502781, lng: 18.0551204 },
    { lat: 48.8502827, lng: 18.0550416 },
    { lat: 48.8502806, lng: 18.0548925 },
    { lat: 48.8502314, lng: 18.0547275 },
    { lat: 48.8501699, lng: 18.0546583 },
    { lat: 48.8502219, lng: 18.0544474 },
    { lat: 48.8502322, lng: 18.0543726 },
    { lat: 48.8502426, lng: 18.0543407 },
    { lat: 48.8502105, lng: 18.0542741 },
    { lat: 48.8502221, lng: 18.0542016 },
    { lat: 48.8502165, lng: 18.0540694 },
    { lat: 48.8501981, lng: 18.0539256 },
    { lat: 48.8501211, lng: 18.0537898 },
    { lat: 48.8500983, lng: 18.0537277 },
    { lat: 48.8500845, lng: 18.053646 },
    { lat: 48.8500772, lng: 18.0535413 },
    { lat: 48.850046, lng: 18.053448 },
    { lat: 48.8500062, lng: 18.0534641 },
    { lat: 48.849924, lng: 18.0532197 },
    { lat: 48.8499572, lng: 18.0528773 },
    { lat: 48.8499821, lng: 18.0526091 },
    { lat: 48.8499803, lng: 18.0521392 },
    { lat: 48.8499506, lng: 18.0520551 },
    { lat: 48.8499177, lng: 18.0518782 },
    { lat: 48.849928, lng: 18.0518238 },
    { lat: 48.8499793, lng: 18.0515456 },
    { lat: 48.850005, lng: 18.0507896 },
    { lat: 48.8499135, lng: 18.0507306 },
    { lat: 48.8498396, lng: 18.0506993 },
    { lat: 48.8497794, lng: 18.0505612 },
    { lat: 48.8497524, lng: 18.0504068 },
    { lat: 48.8496845, lng: 18.0500394 },
    { lat: 48.8498112, lng: 18.0497768 },
    { lat: 48.849854, lng: 18.0496191 },
    { lat: 48.8498591, lng: 18.0494765 },
    { lat: 48.8497171, lng: 18.049362 },
    { lat: 48.8496848, lng: 18.0492341 },
    { lat: 48.8496233, lng: 18.0489973 },
    { lat: 48.8495976, lng: 18.0488857 },
    { lat: 48.849631, lng: 18.0484913 },
    { lat: 48.8496349, lng: 18.0484442 },
    { lat: 48.8496483, lng: 18.0482865 },
    { lat: 48.8498122, lng: 18.0479649 },
    { lat: 48.8498186, lng: 18.0478999 },
    { lat: 48.84984, lng: 18.0475146 },
    { lat: 48.849851, lng: 18.0472999 },
    { lat: 48.8498741, lng: 18.0468826 },
    { lat: 48.8499041, lng: 18.0467617 },
    { lat: 48.8499192, lng: 18.0466733 },
    { lat: 48.8499732, lng: 18.0466904 },
    { lat: 48.8502461, lng: 18.0467829 },
    { lat: 48.8502784, lng: 18.0467922 },
    { lat: 48.8502829, lng: 18.0467171 },
    { lat: 48.8502962, lng: 18.0464795 },
    { lat: 48.850314, lng: 18.0463796 },
    { lat: 48.8503604, lng: 18.0461146 },
    { lat: 48.8504036, lng: 18.0458683 },
    { lat: 48.850407, lng: 18.0458496 },
    { lat: 48.8504193, lng: 18.0457928 },
    { lat: 48.8504088, lng: 18.0455741 },
    { lat: 48.8504047, lng: 18.0454643 },
    { lat: 48.8502758, lng: 18.0453824 },
    { lat: 48.8502784, lng: 18.0453506 },
    { lat: 48.8502872, lng: 18.0452716 },
    { lat: 48.8503178, lng: 18.0450027 },
    { lat: 48.8503481, lng: 18.0447341 },
    { lat: 48.8503797, lng: 18.0444625 },
    { lat: 48.8503927, lng: 18.0443009 },
    { lat: 48.8502992, lng: 18.0442595 },
    { lat: 48.8503028, lng: 18.0441145 },
    { lat: 48.8502079, lng: 18.0438446 },
    { lat: 48.8501994, lng: 18.0437002 },
    { lat: 48.8502636, lng: 18.0433508 },
    { lat: 48.8502607, lng: 18.0432816 },
    { lat: 48.8501998, lng: 18.0431659 },
    { lat: 48.8501559, lng: 18.0424397 },
    { lat: 48.8501242, lng: 18.0422863 },
    { lat: 48.850111, lng: 18.0420415 },
    { lat: 48.8501236, lng: 18.0418696 },
    { lat: 48.8501492, lng: 18.0414798 },
    { lat: 48.8501524, lng: 18.0414382 },
    { lat: 48.8502104, lng: 18.040485 },
    { lat: 48.8501426, lng: 18.0403297 },
    { lat: 48.8500296, lng: 18.0402342 },
    { lat: 48.8500053, lng: 18.0401049 },
    { lat: 48.8499947, lng: 18.0400622 },
    { lat: 48.849994, lng: 18.0400508 },
    { lat: 48.8499835, lng: 18.0400035 },
    { lat: 48.8499747, lng: 18.039951 },
    { lat: 48.8499954, lng: 18.0397643 },
    { lat: 48.8498743, lng: 18.0394535 },
    { lat: 48.8497279, lng: 18.0392486 },
    { lat: 48.8498246, lng: 18.0390674 },
    { lat: 48.849803, lng: 18.0388521 },
    { lat: 48.8497939, lng: 18.038758 },
    { lat: 48.8498014, lng: 18.0387126 },
    { lat: 48.8498081, lng: 18.0386744 },
    { lat: 48.8498077, lng: 18.0386662 },
    { lat: 48.8498027, lng: 18.0386182 },
    { lat: 48.8497943, lng: 18.0385157 },
    { lat: 48.8497981, lng: 18.0385034 },
    { lat: 48.8498041, lng: 18.0384893 },
    { lat: 48.8498536, lng: 18.038354 },
    { lat: 48.8497718, lng: 18.0381328 },
    { lat: 48.8497596, lng: 18.0380972 },
    { lat: 48.8496362, lng: 18.0378985 },
    { lat: 48.8496137, lng: 18.0375342 },
    { lat: 48.8496487, lng: 18.0372084 },
    { lat: 48.8497466, lng: 18.0369361 },
    { lat: 48.8497118, lng: 18.0366899 },
    { lat: 48.849786, lng: 18.0365309 },
    { lat: 48.8498122, lng: 18.0364589 },
    { lat: 48.8498122, lng: 18.0364574 },
    { lat: 48.8498823, lng: 18.0362462 },
    { lat: 48.8498831, lng: 18.0362447 },
    { lat: 48.8499388, lng: 18.0360766 },
    { lat: 48.8500736, lng: 18.0355667 },
    { lat: 48.8501546, lng: 18.0352551 },
    { lat: 48.8502986, lng: 18.0347276 },
    { lat: 48.8504142, lng: 18.034297 },
    { lat: 48.8505831, lng: 18.0338875 },
    { lat: 48.8505643, lng: 18.0337968 },
    { lat: 48.8506878, lng: 18.0334189 },
    { lat: 48.8508125, lng: 18.0327613 },
    { lat: 48.8508301, lng: 18.0317935 },
    { lat: 48.850876, lng: 18.031038 },
    { lat: 48.850883, lng: 18.0305429 },
    { lat: 48.8509429, lng: 18.0301983 },
    { lat: 48.8509456, lng: 18.0300958 },
    { lat: 48.8509527, lng: 18.0300637 },
    { lat: 48.8509372, lng: 18.0299482 },
    { lat: 48.8509608, lng: 18.0299281 },
    { lat: 48.85102, lng: 18.0299373 },
    { lat: 48.8510632, lng: 18.0299437 },
    { lat: 48.8511226, lng: 18.0299529 },
    { lat: 48.8512117, lng: 18.0299516 },
    { lat: 48.8514123, lng: 18.0299481 },
    { lat: 48.851556, lng: 18.0299341 },
    { lat: 48.8518119, lng: 18.0299095 },
    { lat: 48.8518921, lng: 18.0299112 },
    { lat: 48.8520842, lng: 18.0299161 },
    { lat: 48.8521641, lng: 18.0298906 },
    { lat: 48.8523523, lng: 18.0298297 },
    { lat: 48.8524316, lng: 18.029815 },
    { lat: 48.8526225, lng: 18.0297803 },
    { lat: 48.8527016, lng: 18.0297606 },
    { lat: 48.8528906, lng: 18.0297136 },
    { lat: 48.852968, lng: 18.029687 },
    { lat: 48.8531539, lng: 18.0296228 },
    { lat: 48.8532314, lng: 18.0295969 },
    { lat: 48.8534172, lng: 18.0295353 },
    { lat: 48.8535936, lng: 18.029462 },
    { lat: 48.8538766, lng: 18.0293449 },
    { lat: 48.8538777, lng: 18.029344 },
    { lat: 48.8538885, lng: 18.0287591 },
    { lat: 48.8539016, lng: 18.0280047 },
    { lat: 48.8539037, lng: 18.0278844 },
    { lat: 48.8539092, lng: 18.0275955 },
    { lat: 48.8538397, lng: 18.027087 },
    { lat: 48.8538336, lng: 18.0270387 },
    { lat: 48.853748, lng: 18.0264131 },
    { lat: 48.8536937, lng: 18.0259879 },
    { lat: 48.8536187, lng: 18.0254009 },
    { lat: 48.8536172, lng: 18.0253965 },
    { lat: 48.853593, lng: 18.0254277 },
    { lat: 48.8534871, lng: 18.0255639 },
    { lat: 48.853484, lng: 18.0255675 },
    { lat: 48.8534457, lng: 18.0255526 },
    { lat: 48.853454, lng: 18.02554 },
    { lat: 48.8534811, lng: 18.025502 },
    { lat: 48.8534775, lng: 18.0254971 },
    { lat: 48.8534651, lng: 18.0254808 },
    { lat: 48.8534493, lng: 18.0254604 },
    { lat: 48.8533916, lng: 18.0255334 },
    { lat: 48.8534286, lng: 18.0254148 },
    { lat: 48.8534784, lng: 18.0252436 },
    { lat: 48.8534856, lng: 18.0252139 },
    { lat: 48.8535216, lng: 18.0250779 },
    { lat: 48.8536144, lng: 18.0249679 },
    { lat: 48.8536338, lng: 18.0247219 },
    { lat: 48.8536291, lng: 18.0246021 },
    { lat: 48.8536281, lng: 18.0244693 },
    { lat: 48.853631, lng: 18.024366 },
    { lat: 48.8536547, lng: 18.0242338 },
    { lat: 48.8537092, lng: 18.0237784 },
    { lat: 48.8538416, lng: 18.023267 },
    { lat: 48.8538757, lng: 18.02308 },
    { lat: 48.8539265, lng: 18.0229065 },
    { lat: 48.8539899, lng: 18.0227571 },
    { lat: 48.8540682, lng: 18.0225931 },
    { lat: 48.8541186, lng: 18.0224128 },
    { lat: 48.8542061, lng: 18.0222687 },
    { lat: 48.8542976, lng: 18.0221191 },
    { lat: 48.8543892, lng: 18.021969 },
    { lat: 48.8544898, lng: 18.0218375 },
    { lat: 48.8546001, lng: 18.0216986 },
    { lat: 48.8546166, lng: 18.0216698 },
    { lat: 48.8547961, lng: 18.0215366 },
    { lat: 48.8549485, lng: 18.0213855 },
    { lat: 48.8551206, lng: 18.0217385 },
    { lat: 48.8551642, lng: 18.0216552 },
    { lat: 48.8552933, lng: 18.0216227 },
    { lat: 48.855511, lng: 18.0212388 },
    { lat: 48.8555161, lng: 18.0212295 },
    { lat: 48.8555324, lng: 18.0212008 },
    { lat: 48.8557656, lng: 18.0210599 },
    { lat: 48.8559401, lng: 18.0209549 },
    { lat: 48.8561056, lng: 18.0208548 },
    { lat: 48.8561712, lng: 18.0208158 },
    { lat: 48.8562648, lng: 18.0207952 },
    { lat: 48.8564163, lng: 18.0207615 },
    { lat: 48.8565153, lng: 18.0207395 },
    { lat: 48.8565313, lng: 18.0207423 },
    { lat: 48.8565691, lng: 18.0207497 },
    { lat: 48.8566796, lng: 18.0207703 },
    { lat: 48.8567312, lng: 18.0207422 },
    { lat: 48.8567564, lng: 18.0207287 },
    { lat: 48.8568752, lng: 18.0205621 },
    { lat: 48.8570242, lng: 18.0205047 },
    { lat: 48.8570549, lng: 18.0204926 },
    { lat: 48.8571743, lng: 18.0204478 },
    { lat: 48.8573245, lng: 18.0203916 },
    { lat: 48.8574746, lng: 18.0203351 },
    { lat: 48.8575253, lng: 18.020316 },
    { lat: 48.8576244, lng: 18.0202725 },
    { lat: 48.8577741, lng: 18.0202071 },
    { lat: 48.8579237, lng: 18.0201417 },
    { lat: 48.8580755, lng: 18.020076 },
    { lat: 48.8582242, lng: 18.0200107 },
    { lat: 48.8582889, lng: 18.0199819 },
    { lat: 48.8583187, lng: 18.0199692 },
    { lat: 48.8585395, lng: 18.0200006 },
    { lat: 48.8585718, lng: 18.0198583 },
    { lat: 48.8589323, lng: 18.0198102 },
    { lat: 48.8591421, lng: 18.0189847 },
    { lat: 48.859153, lng: 18.0189405 },
    { lat: 48.8592074, lng: 18.0187317 },
    { lat: 48.8592175, lng: 18.0186956 },
    { lat: 48.8592402, lng: 18.018638 },
    { lat: 48.8592659, lng: 18.0184249 },
    { lat: 48.8592131, lng: 18.0184632 },
    { lat: 48.859113, lng: 18.0184907 },
    { lat: 48.8590628, lng: 18.0185025 },
    { lat: 48.8582279, lng: 18.0181238 },
    { lat: 48.8582434, lng: 18.0180776 },
    { lat: 48.8585211, lng: 18.0171393 },
    { lat: 48.8587612, lng: 18.0163337 },
    { lat: 48.8590841, lng: 18.0152448 },
    { lat: 48.8592185, lng: 18.0147853 },
    { lat: 48.8593163, lng: 18.0144545 },
    { lat: 48.8594396, lng: 18.0140382 },
    { lat: 48.8595585, lng: 18.0136446 },
    { lat: 48.8598808, lng: 18.0126213 },
    { lat: 48.8599845, lng: 18.0123693 },
    { lat: 48.8600132, lng: 18.0122998 },
    { lat: 48.8602452, lng: 18.0117931 },
    { lat: 48.8604401, lng: 18.0114057 },
    { lat: 48.8606082, lng: 18.0111758 },
    { lat: 48.860968, lng: 18.0105205 },
    { lat: 48.861114, lng: 18.0103175 },
    { lat: 48.8612542, lng: 18.0100351 },
    { lat: 48.8617109, lng: 18.0087286 },
    { lat: 48.8619061, lng: 18.0080379 },
    { lat: 48.862137, lng: 18.007493 },
    { lat: 48.8623406, lng: 18.0071567 },
    { lat: 48.8625194, lng: 18.0069561 },
    { lat: 48.8626604, lng: 18.0068001 },
    { lat: 48.8627223, lng: 18.0067243 },
    { lat: 48.8627714, lng: 18.0066538 },
    { lat: 48.8629051, lng: 18.0064546 },
    { lat: 48.8629342, lng: 18.0064708 },
    { lat: 48.8629959, lng: 18.0062703 },
    { lat: 48.8630088, lng: 18.0062402 },
    { lat: 48.8630656, lng: 18.0060973 },
    { lat: 48.8630773, lng: 18.0059868 },
    { lat: 48.8630789, lng: 18.0059181 },
    { lat: 48.8630868, lng: 18.0057267 },
    { lat: 48.8630917, lng: 18.0055819 },
    { lat: 48.8631018, lng: 18.0054637 },
    { lat: 48.8631131, lng: 18.0052955 },
    { lat: 48.8631179, lng: 18.0052403 },
    { lat: 48.8631804, lng: 18.0045802 },
    { lat: 48.8631874, lng: 18.0041057 },
    { lat: 48.8631694, lng: 18.004049 },
    { lat: 48.8631224, lng: 18.0039787 },
    { lat: 48.8630547, lng: 18.003866 },
    { lat: 48.8630042, lng: 18.003775 },
    { lat: 48.8629739, lng: 18.0037956 },
    { lat: 48.8629419, lng: 18.0038099 },
    { lat: 48.8623921, lng: 18.0028909 },
    { lat: 48.8622095, lng: 18.0025855 },
    { lat: 48.8615238, lng: 18.0014308 },
    { lat: 48.8612191, lng: 18.0009144 },
    { lat: 48.8611084, lng: 18.0011617 },
    { lat: 48.8606566, lng: 18.0021697 },
    { lat: 48.8604682, lng: 18.002591 },
    { lat: 48.8603677, lng: 18.002819 },
    { lat: 48.8601113, lng: 18.0033875 },
    { lat: 48.8599892, lng: 18.0036715 },
    { lat: 48.8595505, lng: 18.0031833 },
    { lat: 48.8590951, lng: 18.0026759 },
    { lat: 48.8588716, lng: 18.0024274 },
    { lat: 48.8588509, lng: 18.0024026 },
    { lat: 48.8587751, lng: 18.0023193 },
    { lat: 48.8587446, lng: 18.0022847 },
    { lat: 48.858729, lng: 18.0022682 },
    { lat: 48.8566271, lng: 17.9998546 },
    { lat: 48.8560792, lng: 18.0015436 },
    { lat: 48.8552612, lng: 18.0010328 },
    { lat: 48.8550537, lng: 18.0009033 },
    { lat: 48.8542603, lng: 18.0004081 },
    { lat: 48.8541947, lng: 18.0003675 },
    { lat: 48.8542581, lng: 18.000103 },
    { lat: 48.8543239, lng: 17.9997844 },
    { lat: 48.8544262, lng: 17.9994706 },
    { lat: 48.8545272, lng: 17.9992705 },
    { lat: 48.8546832, lng: 17.999044 },
    { lat: 48.8549458, lng: 17.9986384 },
    { lat: 48.8550453, lng: 17.9984328 },
    { lat: 48.8551087, lng: 17.9983418 },
    { lat: 48.8551336, lng: 17.9981094 },
    { lat: 48.8551185, lng: 17.9977021 },
    { lat: 48.8551385, lng: 17.9974123 },
    { lat: 48.8551378, lng: 17.9973615 },
    { lat: 48.8551947, lng: 17.9971592 },
    { lat: 48.8552552, lng: 17.9969434 },
    { lat: 48.8552614, lng: 17.9965881 },
    { lat: 48.8552634, lng: 17.9964896 },
    { lat: 48.8552653, lng: 17.9963894 },
    { lat: 48.8552326, lng: 17.9963881 },
    { lat: 48.8552236, lng: 17.9963875 },
    { lat: 48.8551807, lng: 17.9963862 },
    { lat: 48.8550148, lng: 17.9964094 },
    { lat: 48.8549074, lng: 17.9964251 },
    { lat: 48.8545146, lng: 17.9964813 },
    { lat: 48.8537751, lng: 17.9966101 },
    { lat: 48.8537674, lng: 17.9966115 },
    { lat: 48.8537497, lng: 17.9965999 },
    { lat: 48.853065, lng: 17.9961478 },
    { lat: 48.8522661, lng: 17.9956176 },
    { lat: 48.8518092, lng: 17.9953177 },
    { lat: 48.851783, lng: 17.9956538 },
    { lat: 48.8517752, lng: 17.9958312 },
    { lat: 48.8517223, lng: 17.9962875 },
    { lat: 48.8516431, lng: 17.9966115 },
    { lat: 48.8515111, lng: 17.9969003 },
    { lat: 48.8509265, lng: 17.9978531 },
    { lat: 48.8506932, lng: 17.9982589 },
    { lat: 48.8504062, lng: 17.9986931 },
    { lat: 48.8502025, lng: 17.9988756 },
    { lat: 48.8500036, lng: 17.9989847 },
    { lat: 48.8498022, lng: 17.998806 },
    { lat: 48.8495031, lng: 17.998919 },
    { lat: 48.8493242, lng: 17.9989852 },
    { lat: 48.8490995, lng: 17.9991167 },
    { lat: 48.8487887, lng: 17.999361 },
    { lat: 48.8483817, lng: 17.9996886 },
    { lat: 48.8478815, lng: 17.999782 },
    { lat: 48.8478327, lng: 17.9997796 },
    { lat: 48.8476494, lng: 17.9997716 },
    { lat: 48.8476401, lng: 17.9997667 },
    { lat: 48.8467357, lng: 17.9991868 },
    { lat: 48.8466734, lng: 17.9991454 },
    { lat: 48.8462254, lng: 17.9992044 },
    { lat: 48.8462109, lng: 17.9992071 },
    { lat: 48.8461538, lng: 17.9991998 },
    { lat: 48.8461154, lng: 17.9991964 },
    { lat: 48.8460509, lng: 17.9991923 },
    { lat: 48.8460235, lng: 17.9992053 },
    { lat: 48.8458913, lng: 17.9992813 },
    { lat: 48.8457906, lng: 17.9993351 },
    { lat: 48.8456498, lng: 17.9993926 },
    { lat: 48.8455674, lng: 17.9994163 },
    { lat: 48.8454789, lng: 17.9994423 },
    { lat: 48.845335, lng: 17.9994726 },
    { lat: 48.8451024, lng: 17.9994357 },
    { lat: 48.8448793, lng: 17.9993818 },
    { lat: 48.8446033, lng: 17.9992945 },
    { lat: 48.8438961, lng: 17.999073 },
    { lat: 48.8437275, lng: 17.9994094 },
    { lat: 48.843603, lng: 17.9995969 },
    { lat: 48.8433832, lng: 17.9999277 },
    { lat: 48.8433058, lng: 18.00003 },
    { lat: 48.843237, lng: 18.0001421 },
    { lat: 48.8431823, lng: 18.0002485 },
    { lat: 48.8431488, lng: 18.0003146 },
    { lat: 48.843101, lng: 18.0004584 },
    { lat: 48.8430962, lng: 18.0004723 },
    { lat: 48.8430283, lng: 18.0004532 },
    { lat: 48.8430011, lng: 18.0004486 },
    { lat: 48.8428524, lng: 18.0004183 },
    { lat: 48.8428212, lng: 18.0004028 },
    { lat: 48.8427659, lng: 18.0004992 },
    { lat: 48.8426044, lng: 18.0006877 },
    { lat: 48.8425761, lng: 18.0008216 },
    { lat: 48.842314, lng: 18.0011593 },
    { lat: 48.8422202, lng: 18.0013855 },
    { lat: 48.8421234, lng: 18.0015803 },
    { lat: 48.8420136, lng: 18.0018245 },
    { lat: 48.8418892, lng: 18.0020525 },
    { lat: 48.8417472, lng: 18.002186 },
    { lat: 48.8414226, lng: 18.0023483 },
    { lat: 48.8414093, lng: 18.0025228 },
    { lat: 48.841357, lng: 18.0026306 },
    { lat: 48.8412046, lng: 18.0027462 },
    { lat: 48.8410198, lng: 18.0030562 },
    { lat: 48.8410238, lng: 18.0031448 },
    { lat: 48.841118, lng: 18.0033048 },
    { lat: 48.8410781, lng: 18.0033947 },
    { lat: 48.8408759, lng: 18.0034867 },
    { lat: 48.8407871, lng: 18.0035292 },
    { lat: 48.8407447, lng: 18.0035392 },
    { lat: 48.8406767, lng: 18.0035545 },
    { lat: 48.8405802, lng: 18.0035454 },
    { lat: 48.840443, lng: 18.0036661 },
    { lat: 48.8403185, lng: 18.0036239 },
    { lat: 48.8402935, lng: 18.0036771 },
    { lat: 48.8403313, lng: 18.003936 },
    { lat: 48.8402164, lng: 18.0041297 },
    { lat: 48.8401355, lng: 18.0041725 },
    { lat: 48.8400275, lng: 18.0044101 },
    { lat: 48.8398319, lng: 18.0043269 },
    { lat: 48.8396302, lng: 18.0043507 },
    { lat: 48.8395629, lng: 18.0043978 },
    { lat: 48.8395421, lng: 18.0044658 },
    { lat: 48.8396049, lng: 18.0046483 },
    { lat: 48.8395316, lng: 18.0048596 },
    { lat: 48.8393476, lng: 18.0049913 },
    { lat: 48.8393403, lng: 18.0050607 },
    { lat: 48.8394584, lng: 18.0053421 },
    { lat: 48.8394714, lng: 18.0054457 },
    { lat: 48.8394381, lng: 18.0057598 },
    { lat: 48.8393845, lng: 18.0058491 },
    { lat: 48.8393784, lng: 18.0058972 },
    { lat: 48.8394291, lng: 18.0061838 },
    { lat: 48.8393832, lng: 18.0063387 },
    { lat: 48.8393212, lng: 18.0065513 },
    { lat: 48.8392903, lng: 18.0065648 },
    { lat: 48.839263, lng: 18.0065427 },
    { lat: 48.8391872, lng: 18.0064806 },
    { lat: 48.8391151, lng: 18.0063697 },
    { lat: 48.8391041, lng: 18.0063353 },
    { lat: 48.839074, lng: 18.0063219 },
    { lat: 48.8390418, lng: 18.0065057 },
    { lat: 48.8389438, lng: 18.0065287 },
    { lat: 48.8388108, lng: 18.0066434 },
    { lat: 48.83873, lng: 18.0066315 },
    { lat: 48.8387028, lng: 18.0066678 },
    { lat: 48.8386981, lng: 18.0067042 },
    { lat: 48.8386939, lng: 18.0068266 },
    { lat: 48.8386935, lng: 18.0069514 },
    { lat: 48.8386164, lng: 18.0072122 },
    { lat: 48.8385843, lng: 18.0072245 },
    { lat: 48.8385012, lng: 18.0071354 },
    { lat: 48.8384655, lng: 18.0071435 },
    { lat: 48.8384376, lng: 18.007167 },
    { lat: 48.8384363, lng: 18.0072019 },
    { lat: 48.8384455, lng: 18.0072412 },
    { lat: 48.8385169, lng: 18.0073608 },
    { lat: 48.8384713, lng: 18.0074705 },
    { lat: 48.8384847, lng: 18.0075243 },
    { lat: 48.8386035, lng: 18.0075855 },
    { lat: 48.8386449, lng: 18.0076774 },
    { lat: 48.8386253, lng: 18.0080716 },
    { lat: 48.8385892, lng: 18.0081654 },
    { lat: 48.8384262, lng: 18.0082918 },
    { lat: 48.8382726, lng: 18.0083466 },
    { lat: 48.8382382, lng: 18.0083774 },
    { lat: 48.8382436, lng: 18.0084881 },
    { lat: 48.8384222, lng: 18.0087015 },
    { lat: 48.8383965, lng: 18.0087816 },
    { lat: 48.8382808, lng: 18.0088292 },
    { lat: 48.8382569, lng: 18.0090574 },
    { lat: 48.8381099, lng: 18.009091 },
    { lat: 48.8378921, lng: 18.0093559 },
    { lat: 48.8377988, lng: 18.009366 },
    { lat: 48.837585, lng: 18.0095874 },
    { lat: 48.837575, lng: 18.0096661 },
    { lat: 48.837586, lng: 18.009852 },
    { lat: 48.8375008, lng: 18.0099395 },
    { lat: 48.8374145, lng: 18.0098917 },
    { lat: 48.8373597, lng: 18.009744 },
    { lat: 48.8371784, lng: 18.0096181 },
    { lat: 48.8370433, lng: 18.009721 },
    { lat: 48.8368166, lng: 18.0095905 },
    { lat: 48.8366456, lng: 18.0095223 },
    { lat: 48.8365126, lng: 18.0095661 },
    { lat: 48.836394, lng: 18.0097161 },
    { lat: 48.8361266, lng: 18.009814 },
    { lat: 48.8359332, lng: 18.009746 },
    { lat: 48.8356953, lng: 18.0097703 },
    { lat: 48.8355064, lng: 18.009687 },
    { lat: 48.8354329, lng: 18.0097041 },
    { lat: 48.8353333, lng: 18.0098925 },
    { lat: 48.835206, lng: 18.0098345 },
    { lat: 48.8351605, lng: 18.0098704 },
    { lat: 48.8351368, lng: 18.0100613 },
    { lat: 48.8350871, lng: 18.0102522 },
    { lat: 48.8348863, lng: 18.0103489 },
    { lat: 48.8348117, lng: 18.0105754 },
    { lat: 48.834833, lng: 18.0106513 },
    { lat: 48.8347221, lng: 18.0109884 },
    { lat: 48.8346764, lng: 18.0110003 },
    { lat: 48.8345482, lng: 18.0109683 },
    { lat: 48.8344695, lng: 18.010937 },
    { lat: 48.8344048, lng: 18.0109863 },
    { lat: 48.8343716, lng: 18.0110578 },
    { lat: 48.8343456, lng: 18.0112458 },
    { lat: 48.8342166, lng: 18.0113557 },
    { lat: 48.8341531, lng: 18.0114453 },
    { lat: 48.834173, lng: 18.0116314 },
    { lat: 48.8341421, lng: 18.0118681 },
    { lat: 48.8340667, lng: 18.0121416 },
    { lat: 48.8339841, lng: 18.0123494 },
    { lat: 48.8339022, lng: 18.0125286 },
    { lat: 48.8337105, lng: 18.0126459 },
    { lat: 48.8335522, lng: 18.0127927 },
    { lat: 48.8334687, lng: 18.0132346 },
    { lat: 48.8332702, lng: 18.0134492 },
    { lat: 48.8331541, lng: 18.013509 },
    { lat: 48.8329883, lng: 18.0137016 },
    { lat: 48.8329061, lng: 18.01376 },
    { lat: 48.832843, lng: 18.0139739 },
    { lat: 48.8325274, lng: 18.0140395 },
    { lat: 48.8322725, lng: 18.0138473 },
    { lat: 48.8318411, lng: 18.0143048 },
    { lat: 48.831662, lng: 18.0142713 },
    { lat: 48.831324, lng: 18.0143945 },
    { lat: 48.8310859, lng: 18.0144915 },
    { lat: 48.830922, lng: 18.0148322 },
    { lat: 48.8306347, lng: 18.0151459 },
    { lat: 48.8306233, lng: 18.0153942 },
    { lat: 48.8306448, lng: 18.0155496 },
    { lat: 48.8302292, lng: 18.0164062 },
    { lat: 48.8301043, lng: 18.016431 },
    { lat: 48.8300997, lng: 18.0167339 },
    { lat: 48.8299665, lng: 18.0169848 },
    { lat: 48.8298451, lng: 18.0173175 },
    { lat: 48.8296341, lng: 18.0175284 },
    { lat: 48.8295183, lng: 18.0175387 },
    { lat: 48.8293617, lng: 18.0174453 },
    { lat: 48.8287966, lng: 18.0181769 },
    { lat: 48.8286564, lng: 18.018327 },
    { lat: 48.8286704, lng: 18.0184884 },
    { lat: 48.8285868, lng: 18.0188866 },
    { lat: 48.8283215, lng: 18.0188885 },
    { lat: 48.8281569, lng: 18.0191404 },
    { lat: 48.8279023, lng: 18.0197211 },
    { lat: 48.8277463, lng: 18.0199263 },
    { lat: 48.8275523, lng: 18.020046 },
    { lat: 48.8273569, lng: 18.0202533 },
    { lat: 48.827135, lng: 18.020317 },
    { lat: 48.8271213, lng: 18.0203927 },
    { lat: 48.8272577, lng: 18.0205401 },
    { lat: 48.8272696, lng: 18.0206463 },
    { lat: 48.8272065, lng: 18.020879 },
    { lat: 48.826999, lng: 18.0209376 },
    { lat: 48.826848, lng: 18.0206961 },
    { lat: 48.8267846, lng: 18.0207674 },
    { lat: 48.8266954, lng: 18.0209184 },
    { lat: 48.8265196, lng: 18.0211149 },
    { lat: 48.8262684, lng: 18.0211266 },
    { lat: 48.8261473, lng: 18.021311 },
    { lat: 48.8258132, lng: 18.021195 },
    { lat: 48.8256686, lng: 18.0212701 },
    { lat: 48.8254631, lng: 18.0215747 },
    { lat: 48.825218, lng: 18.0216093 },
    { lat: 48.8246819, lng: 18.0218956 },
    { lat: 48.8244125, lng: 18.0219389 },
    { lat: 48.8239807, lng: 18.0219675 },
    { lat: 48.8237686, lng: 18.0218526 },
    { lat: 48.8233628, lng: 18.021632 },
    { lat: 48.823104, lng: 18.0217605 },
    { lat: 48.8225201, lng: 18.0222137 },
    { lat: 48.8224427, lng: 18.0222607 },
    { lat: 48.8224014, lng: 18.022343 },
    { lat: 48.8223618, lng: 18.0224561 },
    { lat: 48.8222724, lng: 18.0226047 },
    { lat: 48.8221667, lng: 18.022634 },
    { lat: 48.8220561, lng: 18.0225949 },
    { lat: 48.8219134, lng: 18.0226256 },
    { lat: 48.8217492, lng: 18.0226718 },
    { lat: 48.8215667, lng: 18.0227538 },
    { lat: 48.82121, lng: 18.0225252 },
    { lat: 48.8208676, lng: 18.0226495 },
    { lat: 48.8206439, lng: 18.0225702 },
    { lat: 48.8205078, lng: 18.0226369 },
    { lat: 48.8198634, lng: 18.0218874 },
    { lat: 48.8197156, lng: 18.0218913 },
    { lat: 48.8195579, lng: 18.0217797 },
    { lat: 48.8195007, lng: 18.0217287 },
    { lat: 48.8192465, lng: 18.021381 },
    { lat: 48.8191518, lng: 18.021094 },
    { lat: 48.8188819, lng: 18.0209002 },
    { lat: 48.8185436, lng: 18.0206945 },
    { lat: 48.8184015, lng: 18.020659 },
    { lat: 48.8180168, lng: 18.0205487 },
    { lat: 48.817485, lng: 18.0202367 },
    { lat: 48.8173294, lng: 18.0203939 },
    { lat: 48.8172557, lng: 18.0203473 },
    { lat: 48.8170202, lng: 18.0201836 },
    { lat: 48.8169537, lng: 18.0200511 },
    { lat: 48.8165391, lng: 18.0196219 },
    { lat: 48.816026, lng: 18.0197436 },
    { lat: 48.8158015, lng: 18.0195738 },
    { lat: 48.8156903, lng: 18.0197743 },
    { lat: 48.8156372, lng: 18.019851 },
    { lat: 48.8155228, lng: 18.0199219 },
    { lat: 48.8150067, lng: 18.0198961 },
    { lat: 48.8146734, lng: 18.0198506 },
    { lat: 48.814618, lng: 18.0197904 },
    { lat: 48.8142628, lng: 18.0194126 },
    { lat: 48.8141298, lng: 18.0195327 },
    { lat: 48.8136795, lng: 18.0191667 },
    { lat: 48.8136307, lng: 18.0189731 },
    { lat: 48.81346, lng: 18.018931 },
    { lat: 48.8132457, lng: 18.0191831 },
    { lat: 48.8130744, lng: 18.0191848 },
    { lat: 48.8125551, lng: 18.0192787 },
    { lat: 48.8124248, lng: 18.0193873 },
    { lat: 48.8123482, lng: 18.0193668 },
    { lat: 48.8122687, lng: 18.0193783 },
    { lat: 48.8121856, lng: 18.019445 },
    { lat: 48.8121622, lng: 18.0196792 },
    { lat: 48.812, lng: 18.019724 },
    { lat: 48.8118049, lng: 18.0199359 },
    { lat: 48.8114598, lng: 18.0200549 },
    { lat: 48.8113928, lng: 18.0199895 },
    { lat: 48.8110804, lng: 18.0202039 },
    { lat: 48.8108567, lng: 18.020144 },
    { lat: 48.8105963, lng: 18.020264 },
    { lat: 48.8105648, lng: 18.0203023 },
    { lat: 48.8103626, lng: 18.0205116 },
    { lat: 48.810221, lng: 18.0204444 },
    { lat: 48.8101278, lng: 18.0205881 },
    { lat: 48.810035, lng: 18.0205071 },
    { lat: 48.8098678, lng: 18.0206959 },
    { lat: 48.8097213, lng: 18.0207372 },
    { lat: 48.8096598, lng: 18.0206889 },
    { lat: 48.8095308, lng: 18.0208364 },
    { lat: 48.8094973, lng: 18.0211098 },
    { lat: 48.8093456, lng: 18.0212177 },
    { lat: 48.8092788, lng: 18.0213875 },
    { lat: 48.8089238, lng: 18.0217201 },
    { lat: 48.8087646, lng: 18.0216629 },
    { lat: 48.8085828, lng: 18.0218686 },
    { lat: 48.8083013, lng: 18.0219411 },
    { lat: 48.808086, lng: 18.0221549 },
    { lat: 48.8077369, lng: 18.0224159 },
    { lat: 48.8076302, lng: 18.0226158 },
    { lat: 48.8076584, lng: 18.0228447 },
    { lat: 48.8074299, lng: 18.023065 },
    { lat: 48.8072845, lng: 18.0230703 },
    { lat: 48.806953, lng: 18.0233239 },
    { lat: 48.8068393, lng: 18.023462 },
    { lat: 48.80662, lng: 18.0235329 },
    { lat: 48.8064634, lng: 18.0238057 },
    { lat: 48.8063182, lng: 18.0239288 },
    { lat: 48.8062838, lng: 18.0240513 },
    { lat: 48.8060667, lng: 18.0243889 },
    { lat: 48.8059719, lng: 18.02445 },
    { lat: 48.8058677, lng: 18.02452 },
    { lat: 48.8057528, lng: 18.0245971 },
    { lat: 48.8056016, lng: 18.0247926 },
    { lat: 48.8053825, lng: 18.0249224 },
    { lat: 48.8052232, lng: 18.0250736 },
    { lat: 48.8052081, lng: 18.0250849 },
    { lat: 48.8051702, lng: 18.0250926 },
    { lat: 48.8050039, lng: 18.0250885 },
    { lat: 48.8048336, lng: 18.0252408 },
    { lat: 48.8045958, lng: 18.0254183 },
    { lat: 48.8043831, lng: 18.0254857 },
    { lat: 48.8043041, lng: 18.0256213 },
    { lat: 48.804288, lng: 18.0259017 },
    { lat: 48.8040924, lng: 18.0261468 },
    { lat: 48.80392, lng: 18.0262672 },
    { lat: 48.8038262, lng: 18.0264381 },
    { lat: 48.8037197, lng: 18.0265642 },
    { lat: 48.8034966, lng: 18.0265306 },
    { lat: 48.8032994, lng: 18.0269378 },
    { lat: 48.8032065, lng: 18.027163 },
    { lat: 48.8029617, lng: 18.0272233 },
    { lat: 48.8028885, lng: 18.0273574 },
    { lat: 48.8027886, lng: 18.0273877 },
    { lat: 48.8026039, lng: 18.027625 },
    { lat: 48.8025731, lng: 18.0276954 },
    { lat: 48.8024546, lng: 18.0279247 },
    { lat: 48.8024422, lng: 18.0281001 },
    { lat: 48.8022939, lng: 18.0282991 },
    { lat: 48.8022211, lng: 18.028368 },
    { lat: 48.8019272, lng: 18.0286662 },
    { lat: 48.8018347, lng: 18.0287069 },
    { lat: 48.8016958, lng: 18.0288933 },
    { lat: 48.8015648, lng: 18.0289908 },
    { lat: 48.8013845, lng: 18.0290695 },
    { lat: 48.801279, lng: 18.0293093 },
    { lat: 48.8011163, lng: 18.0293066 },
    { lat: 48.8009829, lng: 18.0294588 },
    { lat: 48.800687, lng: 18.0297594 },
    { lat: 48.8004604, lng: 18.0301878 },
    { lat: 48.8004227, lng: 18.0304681 },
    { lat: 48.8002606, lng: 18.0306271 },
    { lat: 48.800202, lng: 18.0307231 },
    { lat: 48.8001681, lng: 18.0307785 },
    { lat: 48.8000116, lng: 18.0308634 },
    { lat: 48.7999576, lng: 18.0308263 },
    { lat: 48.7998957, lng: 18.0308883 },
    { lat: 48.799768, lng: 18.03098 },
    { lat: 48.7996649, lng: 18.0308966 },
    { lat: 48.7995592, lng: 18.030869 },
    { lat: 48.7994343, lng: 18.0308357 },
    { lat: 48.7993518, lng: 18.0307369 },
    { lat: 48.7993184, lng: 18.0307064 },
    { lat: 48.7991935, lng: 18.0306183 },
    { lat: 48.7991088, lng: 18.0306745 },
    { lat: 48.7990779, lng: 18.0307642 },
    { lat: 48.7989143, lng: 18.0308013 },
    { lat: 48.7988376, lng: 18.0308597 },
    { lat: 48.798814, lng: 18.0308414 },
    { lat: 48.7987798, lng: 18.0308542 },
    { lat: 48.7985837, lng: 18.030861 },
    { lat: 48.7985381, lng: 18.0308697 },
    { lat: 48.7984985, lng: 18.0308883 },
    { lat: 48.7983199, lng: 18.0308834 },
    { lat: 48.7982234, lng: 18.0308964 },
    { lat: 48.7981417, lng: 18.0309267 },
    { lat: 48.7980889, lng: 18.0309851 },
    { lat: 48.7979378, lng: 18.0309292 },
    { lat: 48.7978761, lng: 18.0308609 },
    { lat: 48.7977377, lng: 18.0308889 },
    { lat: 48.7976166, lng: 18.0309371 },
    { lat: 48.7975442, lng: 18.0309365 },
    { lat: 48.7974537, lng: 18.0309902 },
    { lat: 48.7972755, lng: 18.0309524 },
    { lat: 48.7972768, lng: 18.0309178 },
    { lat: 48.7972238, lng: 18.0308806 },
    { lat: 48.7971547, lng: 18.0309322 },
    { lat: 48.796958, lng: 18.0308903 },
    { lat: 48.7969263, lng: 18.0309057 },
    { lat: 48.7967154, lng: 18.0308547 },
    { lat: 48.7965302, lng: 18.0307741 },
    { lat: 48.7964001, lng: 18.030729 },
    { lat: 48.7960944, lng: 18.0306951 },
    { lat: 48.7958792, lng: 18.0307037 },
    { lat: 48.7956424, lng: 18.0305703 },
    { lat: 48.7954835, lng: 18.0305176 },
    { lat: 48.795245, lng: 18.0304363 },
    { lat: 48.7951424, lng: 18.0303607 },
    { lat: 48.7950063, lng: 18.0301582 },
    { lat: 48.7948855, lng: 18.0300618 },
    { lat: 48.794778, lng: 18.02998 },
    { lat: 48.7946817, lng: 18.0298584 },
    { lat: 48.7945791, lng: 18.0298393 },
    { lat: 48.7944155, lng: 18.0296516 },
    { lat: 48.7942814, lng: 18.0296507 },
    { lat: 48.7941696, lng: 18.0295971 },
    { lat: 48.7940357, lng: 18.0295239 },
    { lat: 48.7938891, lng: 18.0294878 },
    { lat: 48.7937157, lng: 18.0294182 },
    { lat: 48.7936266, lng: 18.0294757 },
    { lat: 48.7934648, lng: 18.0295075 },
    { lat: 48.7933496, lng: 18.0295839 },
    { lat: 48.7931292, lng: 18.0300178 },
    { lat: 48.7930682, lng: 18.0300736 },
    { lat: 48.7929772, lng: 18.0302559 },
    { lat: 48.7930185, lng: 18.0304587 },
    { lat: 48.7930274, lng: 18.0306103 },
    { lat: 48.7929931, lng: 18.0307345 },
    { lat: 48.7929636, lng: 18.0307349 },
    { lat: 48.7928772, lng: 18.0309707 },
    { lat: 48.7928145, lng: 18.0311907 },
    { lat: 48.792732, lng: 18.0314764 },
    { lat: 48.7926655, lng: 18.0315355 },
    { lat: 48.7926444, lng: 18.0315798 },
    { lat: 48.7924489, lng: 18.0317662 },
    { lat: 48.7923927, lng: 18.0318482 },
    { lat: 48.7923707, lng: 18.0319357 },
    { lat: 48.7923348, lng: 18.0320121 },
    { lat: 48.7922615, lng: 18.0322053 },
    { lat: 48.7921629, lng: 18.0325636 },
    { lat: 48.792118, lng: 18.0327605 },
    { lat: 48.7920802, lng: 18.0329023 },
    { lat: 48.7920121, lng: 18.0330876 },
    { lat: 48.7919791, lng: 18.0331973 },
    { lat: 48.7919252, lng: 18.0333578 },
    { lat: 48.7919599, lng: 18.033429 },
    { lat: 48.7920016, lng: 18.0334871 },
    { lat: 48.7919253, lng: 18.0337057 },
    { lat: 48.7919217, lng: 18.0338168 },
    { lat: 48.791858, lng: 18.0339997 },
    { lat: 48.7917593, lng: 18.0344289 },
    { lat: 48.7917629, lng: 18.0345304 },
    { lat: 48.7917468, lng: 18.0347957 },
    { lat: 48.7916905, lng: 18.0349876 },
    { lat: 48.791569, lng: 18.0352825 },
    { lat: 48.7914614, lng: 18.0359857 },
    { lat: 48.7914, lng: 18.0364163 },
    { lat: 48.7913366, lng: 18.0365695 },
    { lat: 48.7913389, lng: 18.0366447 },
    { lat: 48.7913169, lng: 18.0367683 },
    { lat: 48.7912781, lng: 18.0368408 },
    { lat: 48.791278, lng: 18.0369521 },
    { lat: 48.7913013, lng: 18.0370224 },
    { lat: 48.7913115, lng: 18.0370987 },
    { lat: 48.7912969, lng: 18.0372556 },
    { lat: 48.7912599, lng: 18.0374511 },
    { lat: 48.791205, lng: 18.0375712 },
    { lat: 48.7911525, lng: 18.0377515 },
    { lat: 48.7910468, lng: 18.0378374 },
    { lat: 48.790917, lng: 18.0380668 },
    { lat: 48.7909369, lng: 18.0381965 },
    { lat: 48.7909384, lng: 18.0382779 },
    { lat: 48.7909463, lng: 18.0384111 },
    { lat: 48.7909634, lng: 18.0385706 },
    { lat: 48.7909505, lng: 18.038636 },
    { lat: 48.7909009, lng: 18.0387336 },
    { lat: 48.7908696, lng: 18.0387564 },
    { lat: 48.790795, lng: 18.0389118 },
    { lat: 48.7907016, lng: 18.0389928 },
    { lat: 48.7906109, lng: 18.0389463 },
    { lat: 48.7904698, lng: 18.0389066 },
    { lat: 48.7904192, lng: 18.0388146 },
    { lat: 48.7903732, lng: 18.0388001 },
    { lat: 48.7903216, lng: 18.0388439 },
    { lat: 48.7902113, lng: 18.0388473 },
    { lat: 48.7900653, lng: 18.0389583 },
    { lat: 48.7900092, lng: 18.0389809 },
    { lat: 48.7899226, lng: 18.0389285 },
    { lat: 48.7898121, lng: 18.0389509 },
    { lat: 48.7897606, lng: 18.0390126 },
    { lat: 48.7897211, lng: 18.0390329 },
    { lat: 48.7894875, lng: 18.0390544 },
    { lat: 48.7893621, lng: 18.0390892 },
    { lat: 48.7890946, lng: 18.0394341 },
    { lat: 48.7889766, lng: 18.0394626 },
    { lat: 48.7888501, lng: 18.039286 },
    { lat: 48.7886794, lng: 18.0392829 },
    { lat: 48.7883792, lng: 18.0393025 },
    { lat: 48.7883558, lng: 18.0392706 },
    { lat: 48.7882208, lng: 18.0393908 },
    { lat: 48.7881205, lng: 18.0394706 },
    { lat: 48.7880451, lng: 18.0395523 },
    { lat: 48.7879334, lng: 18.0396475 },
    { lat: 48.7878474, lng: 18.0397776 },
    { lat: 48.7878213, lng: 18.0398323 },
    { lat: 48.7877397, lng: 18.0399811 },
    { lat: 48.7876289, lng: 18.0401517 },
    { lat: 48.7873905, lng: 18.0405095 },
    { lat: 48.7871875, lng: 18.0406643 },
    { lat: 48.7869995, lng: 18.0408467 },
    { lat: 48.7869198, lng: 18.0408539 },
    { lat: 48.7868095, lng: 18.0409933 },
    { lat: 48.7867547, lng: 18.0412111 },
    { lat: 48.7866607, lng: 18.0413616 },
    { lat: 48.7864696, lng: 18.0416644 },
    { lat: 48.7864488, lng: 18.0417323 },
    { lat: 48.7862685, lng: 18.0419835 },
    { lat: 48.7861895, lng: 18.0420797 },
    { lat: 48.7857843, lng: 18.0425616 },
    { lat: 48.7855489, lng: 18.0428177 },
    { lat: 48.7852948, lng: 18.0430645 },
    { lat: 48.7852209, lng: 18.0431307 },
    { lat: 48.785144, lng: 18.0432076 },
    { lat: 48.7849584, lng: 18.0433696 },
    { lat: 48.7848143, lng: 18.0434528 },
    { lat: 48.7847066, lng: 18.0435227 },
    { lat: 48.7842665, lng: 18.0438093 },
    { lat: 48.7840345, lng: 18.043957 },
    { lat: 48.7835549, lng: 18.0443958 },
    { lat: 48.7828845, lng: 18.0449892 },
    { lat: 48.7827481, lng: 18.0452226 },
    { lat: 48.7824548, lng: 18.0456844 },
    { lat: 48.7828136, lng: 18.0458584 },
    { lat: 48.7832753, lng: 18.0461083 },
    { lat: 48.7834773, lng: 18.0462421 },
    { lat: 48.7839648, lng: 18.0465859 },
    { lat: 48.7842564, lng: 18.0468437 },
    { lat: 48.7845937, lng: 18.0469966 },
    { lat: 48.7853557, lng: 18.0470568 },
    { lat: 48.7854351, lng: 18.0470637 },
    { lat: 48.785467, lng: 18.0472416 },
    { lat: 48.7856304, lng: 18.0481386 },
    { lat: 48.7857756, lng: 18.0484 },
    { lat: 48.7859597, lng: 18.0487113 },
    { lat: 48.786058, lng: 18.0488874 },
    { lat: 48.7862121, lng: 18.0491673 },
    { lat: 48.7863682, lng: 18.0493804 },
    { lat: 48.7865097, lng: 18.0495836 },
    { lat: 48.7867305, lng: 18.0500156 },
    { lat: 48.7871167, lng: 18.0502897 },
    { lat: 48.7875277, lng: 18.0504863 },
    { lat: 48.7877491, lng: 18.0504505 },
    { lat: 48.7880169, lng: 18.0504724 },
    { lat: 48.7882533, lng: 18.050407 },
    { lat: 48.7883909, lng: 18.0503859 },
    { lat: 48.7885899, lng: 18.0503717 },
    { lat: 48.7888078, lng: 18.050414 },
    { lat: 48.7890295, lng: 18.0505362 },
    { lat: 48.7891966, lng: 18.0507106 },
    { lat: 48.7893165, lng: 18.0509644 },
    { lat: 48.7893509, lng: 18.0510302 },
    { lat: 48.7893767, lng: 18.0511438 },
    { lat: 48.7897273, lng: 18.0519277 },
    { lat: 48.7900215, lng: 18.0522858 },
    { lat: 48.7903109, lng: 18.0526954 },
    { lat: 48.7904073, lng: 18.0528946 },
    { lat: 48.7906542, lng: 18.0531413 },
    { lat: 48.7908777, lng: 18.0534109 },
    { lat: 48.7911941, lng: 18.0536271 },
    { lat: 48.7912046, lng: 18.053597 },
    { lat: 48.7913442, lng: 18.0536305 },
    { lat: 48.7915697, lng: 18.0537011 },
    { lat: 48.7917143, lng: 18.0537436 },
    { lat: 48.7921642, lng: 18.0541592 },
    { lat: 48.7926149, lng: 18.0548184 },
    { lat: 48.7930975, lng: 18.0548501 },
    { lat: 48.7933495, lng: 18.0545098 },
    { lat: 48.7934457, lng: 18.0542621 },
    { lat: 48.7935598, lng: 18.0540144 },
    { lat: 48.793753, lng: 18.0542122 },
    { lat: 48.7941592, lng: 18.0543634 },
    { lat: 48.7943054, lng: 18.0542023 },
    { lat: 48.7944742, lng: 18.0540203 },
    { lat: 48.794547, lng: 18.053955 },
    { lat: 48.7945797, lng: 18.053529 },
    { lat: 48.794602, lng: 18.0533334 },
    { lat: 48.7946413, lng: 18.0533295 },
    { lat: 48.7950402, lng: 18.0526842 },
    { lat: 48.7952007, lng: 18.0525341 },
    { lat: 48.7958675, lng: 18.052129 },
    { lat: 48.7963014, lng: 18.0518673 },
    { lat: 48.7966988, lng: 18.051928 },
    { lat: 48.7968705, lng: 18.0516833 },
    { lat: 48.7971442, lng: 18.0512853 },
    { lat: 48.7973098, lng: 18.0512054 },
    { lat: 48.7977017, lng: 18.0509211 },
    { lat: 48.7979343, lng: 18.0508092 },
    { lat: 48.797991, lng: 18.0502578 },
    { lat: 48.7983074, lng: 18.0501673 },
    { lat: 48.798735, lng: 18.0500474 },
    { lat: 48.7990619, lng: 18.0496386 },
    { lat: 48.7993698, lng: 18.0493835 },
    { lat: 48.7995922, lng: 18.048983 },
    { lat: 48.7998669, lng: 18.0488763 },
    { lat: 48.8003544, lng: 18.0487011 },
    { lat: 48.8003531, lng: 18.0486579 },
    { lat: 48.8011513, lng: 18.0473018 },
    { lat: 48.8020123, lng: 18.0458605 },
    { lat: 48.8025363, lng: 18.0449819 },
    { lat: 48.8026072, lng: 18.0448237 },
    { lat: 48.8026902, lng: 18.0447191 },
    { lat: 48.8026935, lng: 18.0447548 },
    { lat: 48.8031845, lng: 18.0444299 },
    { lat: 48.8033254, lng: 18.0443887 },
    { lat: 48.8034279, lng: 18.0443666 },
    { lat: 48.80374, lng: 18.0443966 },
    { lat: 48.8042253, lng: 18.0443974 },
    { lat: 48.8044388, lng: 18.0444972 },
    { lat: 48.8047168, lng: 18.044579 },
    { lat: 48.8049266, lng: 18.0446714 },
    { lat: 48.8052339, lng: 18.044718 },
    { lat: 48.8056719, lng: 18.0450085 },
    { lat: 48.8059617, lng: 18.045098 },
    { lat: 48.8066144, lng: 18.0452575 },
    { lat: 48.8070283, lng: 18.0454273 },
    { lat: 48.8072515, lng: 18.0455361 },
    { lat: 48.8074236, lng: 18.0455463 },
    { lat: 48.8076246, lng: 18.0456259 },
    { lat: 48.8078348, lng: 18.0455739 },
    { lat: 48.8080418, lng: 18.0454254 },
    { lat: 48.8083437, lng: 18.0453027 },
    { lat: 48.8087739, lng: 18.0453232 },
    { lat: 48.8090864, lng: 18.0452162 },
    { lat: 48.8092191, lng: 18.0451798 },
    { lat: 48.8093996, lng: 18.0451408 },
    { lat: 48.8100045, lng: 18.0444284 },
    { lat: 48.8101305, lng: 18.0444035 },
    { lat: 48.8103709, lng: 18.0444818 },
    { lat: 48.81058, lng: 18.0445994 },
    { lat: 48.8109331, lng: 18.0445231 },
    { lat: 48.8110007, lng: 18.0445011 },
    { lat: 48.811159, lng: 18.0443706 },
    { lat: 48.8112408, lng: 18.0439757 },
    { lat: 48.8114218, lng: 18.0437361 },
    { lat: 48.8116583, lng: 18.0433204 },
    { lat: 48.8117444, lng: 18.0431563 },
    { lat: 48.8120137, lng: 18.0427644 },
    { lat: 48.8123436, lng: 18.0422892 },
    { lat: 48.8126811, lng: 18.0419647 },
    { lat: 48.8128629, lng: 18.041734 },
    { lat: 48.8134888, lng: 18.0412865 },
    { lat: 48.8138008, lng: 18.041142 },
    { lat: 48.8140542, lng: 18.0404604 },
    { lat: 48.8141133, lng: 18.0403719 },
    { lat: 48.8142014, lng: 18.0402569 },
    { lat: 48.8146322, lng: 18.0400018 },
    { lat: 48.814894, lng: 18.0404238 },
    { lat: 48.8151733, lng: 18.040894 },
    { lat: 48.8154973, lng: 18.0418713 },
    { lat: 48.8155971, lng: 18.0420526 },
    { lat: 48.8157877, lng: 18.042226 },
    { lat: 48.815875, lng: 18.0423102 },
    { lat: 48.8160686, lng: 18.0425495 },
    { lat: 48.8162346, lng: 18.0427438 },
    { lat: 48.8164919, lng: 18.0432624 },
    { lat: 48.8167434, lng: 18.0434326 },
    { lat: 48.8169552, lng: 18.0435792 },
    { lat: 48.8171677, lng: 18.0437389 },
    { lat: 48.8174676, lng: 18.0440416 },
    { lat: 48.8178871, lng: 18.0441298 },
    { lat: 48.8180563, lng: 18.0441464 },
    { lat: 48.8184227, lng: 18.0442771 },
    { lat: 48.8185862, lng: 18.0444467 },
    { lat: 48.8187272, lng: 18.0446168 },
    { lat: 48.818971, lng: 18.0448654 },
    { lat: 48.8192599, lng: 18.045041 },
    { lat: 48.8195175, lng: 18.0451997 },
    { lat: 48.8199257, lng: 18.0453438 },
    { lat: 48.8205117, lng: 18.0455252 },
    { lat: 48.8208751, lng: 18.0456002 },
    { lat: 48.8212146, lng: 18.0457518 },
    { lat: 48.8215399, lng: 18.0458136 },
    { lat: 48.821723, lng: 18.0458552 },
    { lat: 48.8218653, lng: 18.045893 },
    { lat: 48.8222643, lng: 18.0460022 },
    { lat: 48.8225045, lng: 18.046151 },
    { lat: 48.8226333, lng: 18.0462494 },
    { lat: 48.8231539, lng: 18.0466209 },
    { lat: 48.8232603, lng: 18.0467007 },
    { lat: 48.823377, lng: 18.0467319 },
    { lat: 48.8241567, lng: 18.0469403 },
    { lat: 48.8244765, lng: 18.0469255 },
    { lat: 48.8248548, lng: 18.0469121 },
    { lat: 48.8251455, lng: 18.0469403 },
    { lat: 48.8252131, lng: 18.0469401 },
    { lat: 48.8253814, lng: 18.046963 },
    { lat: 48.8257414, lng: 18.0472092 },
    { lat: 48.8258631, lng: 18.047325 },
    { lat: 48.8260264, lng: 18.0474144 },
    { lat: 48.8262927, lng: 18.0474717 },
    { lat: 48.826799, lng: 18.0474232 },
    { lat: 48.8272946, lng: 18.0474248 },
    { lat: 48.8276643, lng: 18.0472443 },
    { lat: 48.8278882, lng: 18.0470024 },
    { lat: 48.8279407, lng: 18.0469538 },
    { lat: 48.8286222, lng: 18.0462032 },
    { lat: 48.8289877, lng: 18.0456812 },
    { lat: 48.8295568, lng: 18.0450346 },
    { lat: 48.8295719, lng: 18.045059 },
    { lat: 48.8296086, lng: 18.0450519 },
    { lat: 48.8296356, lng: 18.0450483 },
    { lat: 48.8299468, lng: 18.0450071 },
    { lat: 48.8302434, lng: 18.0446941 },
    { lat: 48.8303326, lng: 18.0446374 },
    { lat: 48.8304344, lng: 18.0446394 },
    { lat: 48.8306355, lng: 18.0447219 },
    { lat: 48.8307217, lng: 18.0447887 },
    { lat: 48.830817, lng: 18.0449081 },
    { lat: 48.8308667, lng: 18.0449497 },
    { lat: 48.8311044, lng: 18.0450742 },
    { lat: 48.8314725, lng: 18.0450612 },
    { lat: 48.8316838, lng: 18.0451423 },
    { lat: 48.8316864, lng: 18.0452039 },
    { lat: 48.8316928, lng: 18.0452765 },
    { lat: 48.8316994, lng: 18.0453329 },
    { lat: 48.8318404, lng: 18.0464438 },
    { lat: 48.8318749, lng: 18.0467217 },
    { lat: 48.831973, lng: 18.0471434 },
    { lat: 48.8321386, lng: 18.0476205 },
    { lat: 48.8324367, lng: 18.0482939 },
    { lat: 48.8328465, lng: 18.0491432 },
    { lat: 48.8331291, lng: 18.049727 },
    { lat: 48.8338703, lng: 18.0495308 },
    { lat: 48.8339926, lng: 18.0495197 },
    { lat: 48.8343753, lng: 18.0495961 },
    { lat: 48.8344244, lng: 18.0495866 },
    { lat: 48.834717, lng: 18.0495554 },
    { lat: 48.8352663, lng: 18.0495509 },
    { lat: 48.8356446, lng: 18.0497253 },
    { lat: 48.8362022, lng: 18.0496685 },
    { lat: 48.8366593, lng: 18.0495912 },
    { lat: 48.8372789, lng: 18.0496514 },
    { lat: 48.8376098, lng: 18.0494431 },
    { lat: 48.838584, lng: 18.048658 },
    { lat: 48.8397572, lng: 18.0474204 },
    { lat: 48.8401944, lng: 18.0465049 },
    { lat: 48.8411332, lng: 18.0443289 },
    { lat: 48.8413107, lng: 18.0440248 },
    { lat: 48.841495, lng: 18.0437248 },
    { lat: 48.841612, lng: 18.0436033 },
    { lat: 48.8417652, lng: 18.0439635 },
    { lat: 48.8420012, lng: 18.0445043 },
    { lat: 48.8425301, lng: 18.0449607 },
    { lat: 48.842837, lng: 18.0453974 },
    { lat: 48.8431676, lng: 18.0459576 },
    { lat: 48.8432439, lng: 18.0459484 },
    { lat: 48.843639, lng: 18.0458532 },
    { lat: 48.844259, lng: 18.0456313 },
    { lat: 48.8444425, lng: 18.0455815 },
    { lat: 48.844568, lng: 18.0455488 },
    { lat: 48.8447447, lng: 18.0475026 },
    { lat: 48.8453908, lng: 18.0472804 },
    { lat: 48.8456595, lng: 18.0485313 },
    { lat: 48.8457886, lng: 18.0485576 },
    { lat: 48.8458377, lng: 18.0485689 },
    { lat: 48.845818, lng: 18.0489066 },
    { lat: 48.8458521, lng: 18.0490238 },
    { lat: 48.8460453, lng: 18.0491812 },
    { lat: 48.8460693, lng: 18.0493203 },
    { lat: 48.8460002, lng: 18.0494883 },
    { lat: 48.8460216, lng: 18.0498928 },
    { lat: 48.845973, lng: 18.0500087 },
    { lat: 48.8459764, lng: 18.0502752 },
    { lat: 48.8459376, lng: 18.050327 },
    { lat: 48.8459488, lng: 18.0504226 },
    { lat: 48.8460858, lng: 18.0506202 },
    { lat: 48.8459367, lng: 18.050985 },
    { lat: 48.8460176, lng: 18.0512475 },
    { lat: 48.8460823, lng: 18.0515032 },
    { lat: 48.8463388, lng: 18.0518162 },
    { lat: 48.8464612, lng: 18.0519241 },
    { lat: 48.8465188, lng: 18.0521363 },
    { lat: 48.8465336, lng: 18.0522712 },
    { lat: 48.8465696, lng: 18.0523853 },
    { lat: 48.8465887, lng: 18.0525566 },
    { lat: 48.8465517, lng: 18.0529471 },
    { lat: 48.8464222, lng: 18.0532394 },
    { lat: 48.8462805, lng: 18.0534806 },
    { lat: 48.8463282, lng: 18.0537161 },
    { lat: 48.8462598, lng: 18.0540306 },
    { lat: 48.8462686, lng: 18.0541809 },
    { lat: 48.8462777, lng: 18.0543757 },
    { lat: 48.8462426, lng: 18.0544514 },
    { lat: 48.8462049, lng: 18.0546342 },
    { lat: 48.8460294, lng: 18.0550491 },
    { lat: 48.8460618, lng: 18.0552705 },
    { lat: 48.8460297, lng: 18.0553404 },
    { lat: 48.8458279, lng: 18.0553647 },
    { lat: 48.8457269, lng: 18.0554894 },
    { lat: 48.8456889, lng: 18.0556873 },
    { lat: 48.8456073, lng: 18.0557395 },
    { lat: 48.8456743, lng: 18.0558265 },
    { lat: 48.8454147, lng: 18.0563638 },
    { lat: 48.8453662, lng: 18.0566132 },
    { lat: 48.8453161, lng: 18.0570098 },
    { lat: 48.8453318, lng: 18.0575118 },
    { lat: 48.8453782, lng: 18.0577048 },
    { lat: 48.8453974, lng: 18.0577628 },
    { lat: 48.8455251, lng: 18.0578234 },
    { lat: 48.845608, lng: 18.0582737 },
    { lat: 48.8457565, lng: 18.0587296 },
    { lat: 48.8459493, lng: 18.0593794 },
    { lat: 48.8460189, lng: 18.0596628 },
    { lat: 48.8461906, lng: 18.0604739 },
    { lat: 48.8462016, lng: 18.0605255 },
    { lat: 48.8462454, lng: 18.0607163 },
    { lat: 48.8463219, lng: 18.0609805 },
    { lat: 48.8465182, lng: 18.0615012 },
    { lat: 48.8466609, lng: 18.0617773 },
    { lat: 48.8467107, lng: 18.0618767 },
    { lat: 48.8468136, lng: 18.0620137 },
    { lat: 48.8470255, lng: 18.0622815 },
    { lat: 48.8471136, lng: 18.0624166 },
    { lat: 48.847285, lng: 18.0626849 },
    { lat: 48.8474315, lng: 18.0628941 },
    { lat: 48.8475142, lng: 18.0630127 },
    { lat: 48.8477517, lng: 18.0633492 },
    { lat: 48.8478177, lng: 18.0634352 },
    { lat: 48.847856, lng: 18.0634545 },
    { lat: 48.8479209, lng: 18.0634848 },
    { lat: 48.8479581, lng: 18.0635027 },
    { lat: 48.8480989, lng: 18.0634949 },
    { lat: 48.84845, lng: 18.0634617 },
    { lat: 48.8484563, lng: 18.0635289 },
    { lat: 48.8484991, lng: 18.0635292 },
    { lat: 48.8485468, lng: 18.0635314 },
    { lat: 48.8486463, lng: 18.0635345 },
    { lat: 48.8490392, lng: 18.0633663 },
    { lat: 48.8492947, lng: 18.0632592 },
    { lat: 48.8496885, lng: 18.0629855 },
    { lat: 48.8496819, lng: 18.0629491 },
    { lat: 48.8496949, lng: 18.0629406 },
    { lat: 48.8499223, lng: 18.0627781 },
    { lat: 48.8500484, lng: 18.0627544 },
    { lat: 48.8508221, lng: 18.0628983 },
    { lat: 48.8508896, lng: 18.0629165 },
    { lat: 48.8509068, lng: 18.0629195 },
    { lat: 48.8527628, lng: 18.0631817 },
    { lat: 48.8525795, lng: 18.0629587 },
    { lat: 48.852607, lng: 18.062969 },
    { lat: 48.8525427, lng: 18.0624078 },
    { lat: 48.8524158, lng: 18.0612824 },
    { lat: 48.8523873, lng: 18.0610265 },
    { lat: 48.8524004, lng: 18.0610197 },
    { lat: 48.8523628, lng: 18.0609764 },
    { lat: 48.8523024, lng: 18.0608121 },
    { lat: 48.8522624, lng: 18.0607068 },
    { lat: 48.852248, lng: 18.0605582 },
    { lat: 48.8522762, lng: 18.0604788 },
  ],
  TrenčianskeJastrabie: [
    { lat: 48.7775777, lng: 18.0562827 },
    { lat: 48.7778744, lng: 18.0562814 },
    { lat: 48.7779594, lng: 18.0562872 },
    { lat: 48.7783168, lng: 18.0563762 },
    { lat: 48.778983, lng: 18.056365 },
    { lat: 48.7792738, lng: 18.0565274 },
    { lat: 48.7795759, lng: 18.0567555 },
    { lat: 48.7798193, lng: 18.0568649 },
    { lat: 48.7801313, lng: 18.0568537 },
    { lat: 48.7806722, lng: 18.057184 },
    { lat: 48.7812367, lng: 18.0577448 },
    { lat: 48.7814319, lng: 18.0581283 },
    { lat: 48.7813717, lng: 18.0582602 },
    { lat: 48.7813251, lng: 18.0585982 },
    { lat: 48.7813371, lng: 18.0587819 },
    { lat: 48.7814859, lng: 18.0591252 },
    { lat: 48.7816096, lng: 18.0593512 },
    { lat: 48.7816805, lng: 18.0594805 },
    { lat: 48.7820719, lng: 18.0599393 },
    { lat: 48.7823113, lng: 18.0600378 },
    { lat: 48.7827155, lng: 18.0600579 },
    { lat: 48.7829901, lng: 18.0600103 },
    { lat: 48.7832317, lng: 18.0599116 },
    { lat: 48.7835094, lng: 18.0598006 },
    { lat: 48.7835957, lng: 18.0597715 },
    { lat: 48.7844768, lng: 18.0594759 },
    { lat: 48.7851599, lng: 18.0594056 },
    { lat: 48.7854411, lng: 18.0596184 },
    { lat: 48.785866, lng: 18.0599929 },
    { lat: 48.7864054, lng: 18.0607812 },
    { lat: 48.7867727, lng: 18.0616581 },
    { lat: 48.7869801, lng: 18.0624795 },
    { lat: 48.7870711, lng: 18.0635521 },
    { lat: 48.787154, lng: 18.0646962 },
    { lat: 48.7873229, lng: 18.0657294 },
    { lat: 48.787649, lng: 18.066746 },
    { lat: 48.7880207, lng: 18.0674875 },
    { lat: 48.7881511, lng: 18.0677477 },
    { lat: 48.7884202, lng: 18.068315 },
    { lat: 48.7885894, lng: 18.0687168 },
    { lat: 48.7889678, lng: 18.0696288 },
    { lat: 48.7890231, lng: 18.0697624 },
    { lat: 48.7891558, lng: 18.0700821 },
    { lat: 48.7892702, lng: 18.0703595 },
    { lat: 48.7893745, lng: 18.0707915 },
    { lat: 48.789439, lng: 18.0711987 },
    { lat: 48.7895547, lng: 18.0719227 },
    { lat: 48.7897431, lng: 18.0725953 },
    { lat: 48.7898515, lng: 18.0730214 },
    { lat: 48.7899407, lng: 18.0735789 },
    { lat: 48.7899308, lng: 18.0741242 },
    { lat: 48.7899266, lng: 18.0743402 },
    { lat: 48.7899251, lng: 18.074722 },
    { lat: 48.7899203, lng: 18.0758157 },
    { lat: 48.7898642, lng: 18.0763207 },
    { lat: 48.7899064, lng: 18.0767148 },
    { lat: 48.789807, lng: 18.0770048 },
    { lat: 48.7897931, lng: 18.0774985 },
    { lat: 48.7896948, lng: 18.0779801 },
    { lat: 48.7897048, lng: 18.0781871 },
    { lat: 48.7896892, lng: 18.0787701 },
    { lat: 48.7896413, lng: 18.0792815 },
    { lat: 48.7896392, lng: 18.0793058 },
    { lat: 48.7896229, lng: 18.0794679 },
    { lat: 48.7896133, lng: 18.0795755 },
    { lat: 48.7896733, lng: 18.0803907 },
    { lat: 48.7895678, lng: 18.0808833 },
    { lat: 48.7896195, lng: 18.0814398 },
    { lat: 48.7896074, lng: 18.0818279 },
    { lat: 48.7896533, lng: 18.0824431 },
    { lat: 48.7896758, lng: 18.0827461 },
    { lat: 48.7896788, lng: 18.0832224 },
    { lat: 48.7896695, lng: 18.0832573 },
    { lat: 48.7895831, lng: 18.0835967 },
    { lat: 48.7895168, lng: 18.0837355 },
    { lat: 48.7893302, lng: 18.0839792 },
    { lat: 48.7890687, lng: 18.0844483 },
    { lat: 48.7887057, lng: 18.0849751 },
    { lat: 48.7885933, lng: 18.0851959 },
    { lat: 48.788192, lng: 18.0854916 },
    { lat: 48.7881124, lng: 18.0857725 },
    { lat: 48.7877189, lng: 18.0861089 },
    { lat: 48.7878219, lng: 18.0863267 },
    { lat: 48.7877442, lng: 18.0865794 },
    { lat: 48.7875852, lng: 18.0868326 },
    { lat: 48.7874908, lng: 18.0870331 },
    { lat: 48.7873229, lng: 18.0873451 },
    { lat: 48.7871279, lng: 18.087602 },
    { lat: 48.7870265, lng: 18.0877767 },
    { lat: 48.7869181, lng: 18.0879901 },
    { lat: 48.7868134, lng: 18.0883441 },
    { lat: 48.7867758, lng: 18.0885292 },
    { lat: 48.786725, lng: 18.0887972 },
    { lat: 48.7867006, lng: 18.0889233 },
    { lat: 48.7866258, lng: 18.0891881 },
    { lat: 48.7864275, lng: 18.0894462 },
    { lat: 48.7863072, lng: 18.0897017 },
    { lat: 48.7863755, lng: 18.0898869 },
    { lat: 48.7863493, lng: 18.0900954 },
    { lat: 48.7862482, lng: 18.0903552 },
    { lat: 48.7862171, lng: 18.0906732 },
    { lat: 48.7860869, lng: 18.0909547 },
    { lat: 48.7859733, lng: 18.0912888 },
    { lat: 48.7859447, lng: 18.0916709 },
    { lat: 48.7859422, lng: 18.0918215 },
    { lat: 48.7859382, lng: 18.0921384 },
    { lat: 48.7858915, lng: 18.0925738 },
    { lat: 48.78587, lng: 18.0927126 },
    { lat: 48.7858425, lng: 18.092897 },
    { lat: 48.7857976, lng: 18.093174 },
    { lat: 48.7856927, lng: 18.0936937 },
    { lat: 48.7855761, lng: 18.0943452 },
    { lat: 48.785575, lng: 18.0943629 },
    { lat: 48.7855657, lng: 18.094459 },
    { lat: 48.7855991, lng: 18.0946624 },
    { lat: 48.785461, lng: 18.095003 },
    { lat: 48.7853476, lng: 18.0953989 },
    { lat: 48.7853343, lng: 18.0954826 },
    { lat: 48.7852765, lng: 18.0958815 },
    { lat: 48.785276, lng: 18.0959911 },
    { lat: 48.7852796, lng: 18.0963814 },
    { lat: 48.7851873, lng: 18.0967935 },
    { lat: 48.7851643, lng: 18.0971895 },
    { lat: 48.785151, lng: 18.0973874 },
    { lat: 48.7851274, lng: 18.0977362 },
    { lat: 48.785169, lng: 18.0980445 },
    { lat: 48.7851151, lng: 18.0983763 },
    { lat: 48.7851562, lng: 18.0987165 },
    { lat: 48.7851776, lng: 18.0990626 },
    { lat: 48.7852189, lng: 18.0992271 },
    { lat: 48.7852986, lng: 18.0995521 },
    { lat: 48.785309, lng: 18.0996327 },
    { lat: 48.7853404, lng: 18.0998804 },
    { lat: 48.7853602, lng: 18.1000445 },
    { lat: 48.785338, lng: 18.1002436 },
    { lat: 48.7853246, lng: 18.1005013 },
    { lat: 48.7853552, lng: 18.1007338 },
    { lat: 48.7853187, lng: 18.1009397 },
    { lat: 48.7852081, lng: 18.1010937 },
    { lat: 48.7850402, lng: 18.101488 },
    { lat: 48.7849484, lng: 18.1017915 },
    { lat: 48.7848926, lng: 18.1019743 },
    { lat: 48.7847921, lng: 18.1021681 },
    { lat: 48.7846779, lng: 18.1024743 },
    { lat: 48.7846486, lng: 18.1025097 },
    { lat: 48.78464, lng: 18.1025205 },
    { lat: 48.7845831, lng: 18.1025882 },
    { lat: 48.7845779, lng: 18.1025954 },
    { lat: 48.7845135, lng: 18.1026716 },
    { lat: 48.7846164, lng: 18.1027738 },
    { lat: 48.784465, lng: 18.1030807 },
    { lat: 48.7844529, lng: 18.1033765 },
    { lat: 48.7844046, lng: 18.1034763 },
    { lat: 48.7843083, lng: 18.1035858 },
    { lat: 48.7842568, lng: 18.103729 },
    { lat: 48.7842256, lng: 18.1038506 },
    { lat: 48.7841333, lng: 18.1039352 },
    { lat: 48.7840152, lng: 18.1039778 },
    { lat: 48.7839912, lng: 18.1040683 },
    { lat: 48.7840079, lng: 18.1042786 },
    { lat: 48.784055, lng: 18.104408 },
    { lat: 48.7841381, lng: 18.1045758 },
    { lat: 48.7841184, lng: 18.1048594 },
    { lat: 48.7839493, lng: 18.1049599 },
    { lat: 48.7839449, lng: 18.1049981 },
    { lat: 48.7839659, lng: 18.1050708 },
    { lat: 48.7839668, lng: 18.1053176 },
    { lat: 48.7838975, lng: 18.1055412 },
    { lat: 48.7838451, lng: 18.1056488 },
    { lat: 48.7838455, lng: 18.1057321 },
    { lat: 48.7839308, lng: 18.106056 },
    { lat: 48.7839246, lng: 18.1064312 },
    { lat: 48.7838864, lng: 18.106593 },
    { lat: 48.7838723, lng: 18.1067362 },
    { lat: 48.7838833, lng: 18.1069649 },
    { lat: 48.78397, lng: 18.1071913 },
    { lat: 48.7839684, lng: 18.107204 },
    { lat: 48.7839847, lng: 18.1074846 },
    { lat: 48.7839656, lng: 18.1076396 },
    { lat: 48.784057, lng: 18.1077186 },
    { lat: 48.784195, lng: 18.1077614 },
    { lat: 48.7843674, lng: 18.1078143 },
    { lat: 48.7845245, lng: 18.1083423 },
    { lat: 48.7845435, lng: 18.1085147 },
    { lat: 48.7845648, lng: 18.1087059 },
    { lat: 48.7845859, lng: 18.1088931 },
    { lat: 48.7845943, lng: 18.1089778 },
    { lat: 48.7846007, lng: 18.1090296 },
    { lat: 48.7846059, lng: 18.1090836 },
    { lat: 48.7846322, lng: 18.1091657 },
    { lat: 48.7846737, lng: 18.1093169 },
    { lat: 48.7846995, lng: 18.1094108 },
    { lat: 48.784711, lng: 18.1094908 },
    { lat: 48.7847397, lng: 18.1099422 },
    { lat: 48.78475, lng: 18.110103 },
    { lat: 48.7847774, lng: 18.1102038 },
    { lat: 48.7848168, lng: 18.1103009 },
    { lat: 48.7848358, lng: 18.1104318 },
    { lat: 48.7848452, lng: 18.1105576 },
    { lat: 48.7848806, lng: 18.1106606 },
    { lat: 48.7849506, lng: 18.1107379 },
    { lat: 48.7850278, lng: 18.1107845 },
    { lat: 48.7851921, lng: 18.1109294 },
    { lat: 48.7852712, lng: 18.1109729 },
    { lat: 48.7853296, lng: 18.1110048 },
    { lat: 48.7853312, lng: 18.1110128 },
    { lat: 48.7853501, lng: 18.1111051 },
    { lat: 48.785372, lng: 18.1112099 },
    { lat: 48.7853808, lng: 18.1112624 },
    { lat: 48.7853863, lng: 18.1113011 },
    { lat: 48.785382, lng: 18.1114416 },
    { lat: 48.7854787, lng: 18.1115711 },
    { lat: 48.7855191, lng: 18.1116813 },
    { lat: 48.7855259, lng: 18.1117405 },
    { lat: 48.7855523, lng: 18.1119832 },
    { lat: 48.7855349, lng: 18.1121269 },
    { lat: 48.7854667, lng: 18.1123317 },
    { lat: 48.7855064, lng: 18.1125319 },
    { lat: 48.7855695, lng: 18.1126433 },
    { lat: 48.785689, lng: 18.1128561 },
    { lat: 48.7859106, lng: 18.1131336 },
    { lat: 48.7861972, lng: 18.1133287 },
    { lat: 48.7862424, lng: 18.1135807 },
    { lat: 48.7864044, lng: 18.1139421 },
    { lat: 48.7864836, lng: 18.1141988 },
    { lat: 48.786526, lng: 18.1145164 },
    { lat: 48.7866476, lng: 18.1145696 },
    { lat: 48.7868165, lng: 18.1145829 },
    { lat: 48.7868566, lng: 18.1146344 },
    { lat: 48.7868847, lng: 18.1148039 },
    { lat: 48.7868794, lng: 18.1148873 },
    { lat: 48.7869779, lng: 18.1152442 },
    { lat: 48.7870061, lng: 18.1154197 },
    { lat: 48.787045, lng: 18.1154099 },
    { lat: 48.7870707, lng: 18.1152697 },
    { lat: 48.7871273, lng: 18.11525 },
    { lat: 48.7871701, lng: 18.1152511 },
    { lat: 48.7872552, lng: 18.1152956 },
    { lat: 48.7874363, lng: 18.1152855 },
    { lat: 48.7875311, lng: 18.1153269 },
    { lat: 48.787535, lng: 18.1154913 },
    { lat: 48.7875075, lng: 18.1155784 },
    { lat: 48.7874754, lng: 18.1158042 },
    { lat: 48.7875759, lng: 18.1159425 },
    { lat: 48.7876156, lng: 18.1160224 },
    { lat: 48.7876214, lng: 18.1161018 },
    { lat: 48.7875939, lng: 18.1162669 },
    { lat: 48.7875742, lng: 18.1163969 },
    { lat: 48.7876387, lng: 18.1165343 },
    { lat: 48.7877847, lng: 18.1165804 },
    { lat: 48.7878459, lng: 18.1166649 },
    { lat: 48.7878553, lng: 18.1168623 },
    { lat: 48.7878734, lng: 18.1169211 },
    { lat: 48.7880002, lng: 18.1170448 },
    { lat: 48.7880585, lng: 18.1172546 },
    { lat: 48.7881115, lng: 18.1173482 },
    { lat: 48.7880994, lng: 18.1175101 },
    { lat: 48.7881026, lng: 18.1176417 },
    { lat: 48.7881344, lng: 18.1177424 },
    { lat: 48.7881696, lng: 18.1178626 },
    { lat: 48.7881805, lng: 18.1180877 },
    { lat: 48.7882709, lng: 18.1182266 },
    { lat: 48.7883666, lng: 18.1184362 },
    { lat: 48.7885232, lng: 18.1185101 },
    { lat: 48.7884938, lng: 18.1186067 },
    { lat: 48.7884589, lng: 18.1187996 },
    { lat: 48.7884389, lng: 18.1192328 },
    { lat: 48.7884535, lng: 18.1194263 },
    { lat: 48.7884042, lng: 18.1196283 },
    { lat: 48.7884495, lng: 18.1197851 },
    { lat: 48.7885059, lng: 18.1199791 },
    { lat: 48.788537, lng: 18.1200089 },
    { lat: 48.7886253, lng: 18.120092 },
    { lat: 48.7886825, lng: 18.1202426 },
    { lat: 48.7885998, lng: 18.1204103 },
    { lat: 48.7886523, lng: 18.1204388 },
    { lat: 48.7887372, lng: 18.1204837 },
    { lat: 48.7888579, lng: 18.1206197 },
    { lat: 48.7889293, lng: 18.1206997 },
    { lat: 48.7889558, lng: 18.1209033 },
    { lat: 48.7889258, lng: 18.1210101 },
    { lat: 48.7890555, lng: 18.1211252 },
    { lat: 48.7891009, lng: 18.121166 },
    { lat: 48.7892338, lng: 18.121105 },
    { lat: 48.789381, lng: 18.1212096 },
    { lat: 48.7895714, lng: 18.1212058 },
    { lat: 48.7896138, lng: 18.1212713 },
    { lat: 48.7899001, lng: 18.1217146 },
    { lat: 48.7899659, lng: 18.1218168 },
    { lat: 48.7899678, lng: 18.1221014 },
    { lat: 48.7899705, lng: 18.1223849 },
    { lat: 48.7899249, lng: 18.1227431 },
    { lat: 48.7898714, lng: 18.1230272 },
    { lat: 48.7901593, lng: 18.1232445 },
    { lat: 48.7903023, lng: 18.1233532 },
    { lat: 48.7902948, lng: 18.1234562 },
    { lat: 48.7902362, lng: 18.1235388 },
    { lat: 48.7901421, lng: 18.1239164 },
    { lat: 48.7899887, lng: 18.1241708 },
    { lat: 48.7899279, lng: 18.124447 },
    { lat: 48.7903696, lng: 18.1251541 },
    { lat: 48.7905152, lng: 18.1253863 },
    { lat: 48.7904313, lng: 18.1256148 },
    { lat: 48.7904329, lng: 18.1260487 },
    { lat: 48.7904341, lng: 18.1262658 },
    { lat: 48.7903603, lng: 18.1264474 },
    { lat: 48.7903212, lng: 18.1268244 },
    { lat: 48.7902131, lng: 18.1271416 },
    { lat: 48.7901551, lng: 18.1275609 },
    { lat: 48.7902316, lng: 18.1276731 },
    { lat: 48.7903261, lng: 18.1277847 },
    { lat: 48.7903152, lng: 18.12789 },
    { lat: 48.7902117, lng: 18.1279535 },
    { lat: 48.7902192, lng: 18.1281185 },
    { lat: 48.790176, lng: 18.1282503 },
    { lat: 48.7901569, lng: 18.1283119 },
    { lat: 48.7901005, lng: 18.1284851 },
    { lat: 48.7900707, lng: 18.1285753 },
    { lat: 48.7900895, lng: 18.1286458 },
    { lat: 48.7901231, lng: 18.1287766 },
    { lat: 48.7901812, lng: 18.1289997 },
    { lat: 48.7902136, lng: 18.1293061 },
    { lat: 48.7901391, lng: 18.1293832 },
    { lat: 48.7901689, lng: 18.1294512 },
    { lat: 48.7901382, lng: 18.1295061 },
    { lat: 48.7900427, lng: 18.1295922 },
    { lat: 48.7900447, lng: 18.1297029 },
    { lat: 48.7901882, lng: 18.1297837 },
    { lat: 48.7901894, lng: 18.1299188 },
    { lat: 48.7900765, lng: 18.130093 },
    { lat: 48.7900702, lng: 18.1303956 },
    { lat: 48.7901587, lng: 18.130498 },
    { lat: 48.7901421, lng: 18.1307181 },
    { lat: 48.7901441, lng: 18.1308285 },
    { lat: 48.7900496, lng: 18.1308969 },
    { lat: 48.7900238, lng: 18.1309572 },
    { lat: 48.7900423, lng: 18.1310392 },
    { lat: 48.7900044, lng: 18.1310306 },
    { lat: 48.7898307, lng: 18.1309911 },
    { lat: 48.7897363, lng: 18.1311933 },
    { lat: 48.7897759, lng: 18.131449 },
    { lat: 48.7897429, lng: 18.1315801 },
    { lat: 48.7897393, lng: 18.1315952 },
    { lat: 48.7897382, lng: 18.1315975 },
    { lat: 48.7896942, lng: 18.1317726 },
    { lat: 48.789689, lng: 18.1317966 },
    { lat: 48.7895428, lng: 18.1319055 },
    { lat: 48.7893505, lng: 18.1324231 },
    { lat: 48.7895893, lng: 18.1326251 },
    { lat: 48.7898618, lng: 18.1328509 },
    { lat: 48.7901948, lng: 18.1331251 },
    { lat: 48.7905059, lng: 18.133393 },
    { lat: 48.7913383, lng: 18.1341023 },
    { lat: 48.7913612, lng: 18.134129 },
    { lat: 48.7914135, lng: 18.1341915 },
    { lat: 48.7914436, lng: 18.1342254 },
    { lat: 48.7927225, lng: 18.1355927 },
    { lat: 48.7928705, lng: 18.135771 },
    { lat: 48.7928973, lng: 18.135805 },
    { lat: 48.7933112, lng: 18.1362672 },
    { lat: 48.7941123, lng: 18.1371622 },
    { lat: 48.794198, lng: 18.1372586 },
    { lat: 48.7942421, lng: 18.1371629 },
    { lat: 48.7943403, lng: 18.1369473 },
    { lat: 48.7943793, lng: 18.1368615 },
    { lat: 48.7945589, lng: 18.1364769 },
    { lat: 48.7945762, lng: 18.1364442 },
    { lat: 48.7946719, lng: 18.1362629 },
    { lat: 48.794683, lng: 18.1362424 },
    { lat: 48.7947842, lng: 18.1361005 },
    { lat: 48.7948057, lng: 18.1360429 },
    { lat: 48.7949839, lng: 18.1358241 },
    { lat: 48.7950714, lng: 18.1357184 },
    { lat: 48.795119, lng: 18.135662 },
    { lat: 48.7951279, lng: 18.1356608 },
    { lat: 48.7951937, lng: 18.1355894 },
    { lat: 48.7952744, lng: 18.1355046 },
    { lat: 48.795417, lng: 18.1353535 },
    { lat: 48.795822, lng: 18.1349236 },
    { lat: 48.7961971, lng: 18.1345208 },
    { lat: 48.7964944, lng: 18.1342007 },
    { lat: 48.7966029, lng: 18.1340864 },
    { lat: 48.7966208, lng: 18.1340274 },
    { lat: 48.7967299, lng: 18.1338655 },
    { lat: 48.7968955, lng: 18.1335496 },
    { lat: 48.797227, lng: 18.1329199 },
    { lat: 48.7973112, lng: 18.1327577 },
    { lat: 48.7975779, lng: 18.1322601 },
    { lat: 48.7977883, lng: 18.1318599 },
    { lat: 48.7979109, lng: 18.1316374 },
    { lat: 48.7979759, lng: 18.1315511 },
    { lat: 48.7980487, lng: 18.1314276 },
    { lat: 48.798185, lng: 18.1312466 },
    { lat: 48.7983525, lng: 18.1310797 },
    { lat: 48.7984251, lng: 18.1310078 },
    { lat: 48.7984615, lng: 18.1309747 },
    { lat: 48.7986244, lng: 18.1308277 },
    { lat: 48.7986824, lng: 18.1307753 },
    { lat: 48.7988886, lng: 18.1305964 },
    { lat: 48.7990299, lng: 18.1304431 },
    { lat: 48.7991698, lng: 18.1303063 },
    { lat: 48.7996754, lng: 18.1298217 },
    { lat: 48.7999423, lng: 18.1295815 },
    { lat: 48.8001257, lng: 18.1294125 },
    { lat: 48.800459, lng: 18.1290921 },
    { lat: 48.8007932, lng: 18.1287839 },
    { lat: 48.8009673, lng: 18.1286343 },
    { lat: 48.8011034, lng: 18.1285257 },
    { lat: 48.8012099, lng: 18.128397 },
    { lat: 48.8012699, lng: 18.1283056 },
    { lat: 48.8013846, lng: 18.1281596 },
    { lat: 48.8014111, lng: 18.1281318 },
    { lat: 48.8015812, lng: 18.1278564 },
    { lat: 48.8016277, lng: 18.1278173 },
    { lat: 48.8017886, lng: 18.1274608 },
    { lat: 48.8018091, lng: 18.1274073 },
    { lat: 48.8019807, lng: 18.1270597 },
    { lat: 48.8020583, lng: 18.126917 },
    { lat: 48.8020897, lng: 18.1268545 },
    { lat: 48.8022174, lng: 18.1266073 },
    { lat: 48.8022728, lng: 18.1264907 },
    { lat: 48.8024278, lng: 18.1261658 },
    { lat: 48.8024829, lng: 18.1260686 },
    { lat: 48.8025571, lng: 18.1258883 },
    { lat: 48.802661, lng: 18.1256544 },
    { lat: 48.8027403, lng: 18.1253696 },
    { lat: 48.802853, lng: 18.1249776 },
    { lat: 48.8029436, lng: 18.1246316 },
    { lat: 48.8029334, lng: 18.124615 },
    { lat: 48.8026909, lng: 18.1245192 },
    { lat: 48.8023544, lng: 18.124381 },
    { lat: 48.8021127, lng: 18.1242796 },
    { lat: 48.8018011, lng: 18.1241421 },
    { lat: 48.8012456, lng: 18.1239018 },
    { lat: 48.8010302, lng: 18.1238284 },
    { lat: 48.8008564, lng: 18.1237667 },
    { lat: 48.8007174, lng: 18.1237279 },
    { lat: 48.8003629, lng: 18.1236262 },
    { lat: 48.8002474, lng: 18.1235994 },
    { lat: 48.8001413, lng: 18.1235798 },
    { lat: 48.799998, lng: 18.1235441 },
    { lat: 48.7996544, lng: 18.1234743 },
    { lat: 48.7995079, lng: 18.1234404 },
    { lat: 48.7991216, lng: 18.1233351 },
    { lat: 48.7992138, lng: 18.1231159 },
    { lat: 48.7992994, lng: 18.1228994 },
    { lat: 48.799368, lng: 18.1227202 },
    { lat: 48.799501, lng: 18.1223882 },
    { lat: 48.7995132, lng: 18.1223626 },
    { lat: 48.7995543, lng: 18.1222769 },
    { lat: 48.7996203, lng: 18.1221314 },
    { lat: 48.7996735, lng: 18.1220166 },
    { lat: 48.7997075, lng: 18.121943 },
    { lat: 48.7997157, lng: 18.1219247 },
    { lat: 48.7997813, lng: 18.1218122 },
    { lat: 48.7998798, lng: 18.121643 },
    { lat: 48.7999065, lng: 18.1215986 },
    { lat: 48.8000049, lng: 18.1216392 },
    { lat: 48.8000584, lng: 18.1216489 },
    { lat: 48.8001165, lng: 18.1216594 },
    { lat: 48.8001419, lng: 18.1216492 },
    { lat: 48.8002091, lng: 18.121621 },
    { lat: 48.8002631, lng: 18.1215987 },
    { lat: 48.800343, lng: 18.1215566 },
    { lat: 48.800468, lng: 18.1214913 },
    { lat: 48.8005206, lng: 18.1214642 },
    { lat: 48.8006502, lng: 18.1213668 },
    { lat: 48.8007551, lng: 18.1212873 },
    { lat: 48.8008136, lng: 18.1212437 },
    { lat: 48.8010626, lng: 18.1213172 },
    { lat: 48.8016094, lng: 18.1214442 },
    { lat: 48.8016244, lng: 18.1214472 },
    { lat: 48.8016424, lng: 18.1214469 },
    { lat: 48.8016369, lng: 18.1214899 },
    { lat: 48.801617, lng: 18.1216526 },
    { lat: 48.801606, lng: 18.1217929 },
    { lat: 48.8015971, lng: 18.1219287 },
    { lat: 48.8017553, lng: 18.1219732 },
    { lat: 48.8020785, lng: 18.1220382 },
    { lat: 48.8024077, lng: 18.1221111 },
    { lat: 48.8024255, lng: 18.1221084 },
    { lat: 48.8024425, lng: 18.1219733 },
    { lat: 48.8024521, lng: 18.1218661 },
    { lat: 48.8024719, lng: 18.121702 },
    { lat: 48.8024741, lng: 18.1216784 },
    { lat: 48.8024947, lng: 18.1215081 },
    { lat: 48.8022526, lng: 18.1214237 },
    { lat: 48.8022701, lng: 18.1212574 },
    { lat: 48.8022855, lng: 18.1211361 },
    { lat: 48.8023064, lng: 18.1209508 },
    { lat: 48.8023153, lng: 18.1208715 },
    { lat: 48.8023301, lng: 18.1207192 },
    { lat: 48.8023431, lng: 18.1206087 },
    { lat: 48.802351, lng: 18.1205324 },
    { lat: 48.802354, lng: 18.1205077 },
    { lat: 48.8023596, lng: 18.1204497 },
    { lat: 48.8023646, lng: 18.1203964 },
    { lat: 48.8023696, lng: 18.1203492 },
    { lat: 48.8023783, lng: 18.1202829 },
    { lat: 48.8023881, lng: 18.1201999 },
    { lat: 48.8023991, lng: 18.1201 },
    { lat: 48.8024233, lng: 18.1199142 },
    { lat: 48.8027628, lng: 18.1200462 },
    { lat: 48.8028393, lng: 18.1196333 },
    { lat: 48.8029181, lng: 18.1192252 },
    { lat: 48.8030154, lng: 18.118721 },
    { lat: 48.8023249, lng: 18.1182212 },
    { lat: 48.8020789, lng: 18.1180449 },
    { lat: 48.8021242, lng: 18.1178921 },
    { lat: 48.8022373, lng: 18.1175076 },
    { lat: 48.8023447, lng: 18.1171421 },
    { lat: 48.8023729, lng: 18.1170463 },
    { lat: 48.8024238, lng: 18.1169694 },
    { lat: 48.8024756, lng: 18.1168916 },
    { lat: 48.8025369, lng: 18.1167989 },
    { lat: 48.8026084, lng: 18.1166913 },
    { lat: 48.8026394, lng: 18.1166628 },
    { lat: 48.8027348, lng: 18.116576 },
    { lat: 48.8029566, lng: 18.1163721 },
    { lat: 48.8029947, lng: 18.1163477 },
    { lat: 48.8030066, lng: 18.1163404 },
    { lat: 48.8030164, lng: 18.1163348 },
    { lat: 48.8030439, lng: 18.116319 },
    { lat: 48.8030743, lng: 18.1163013 },
    { lat: 48.8030885, lng: 18.1162933 },
    { lat: 48.8030842, lng: 18.1162771 },
    { lat: 48.8030191, lng: 18.1159904 },
    { lat: 48.8030474, lng: 18.115973 },
    { lat: 48.8030455, lng: 18.1159615 },
    { lat: 48.8030126, lng: 18.1159759 },
    { lat: 48.8029449, lng: 18.1156874 },
    { lat: 48.8029486, lng: 18.1156733 },
    { lat: 48.8029299, lng: 18.1156042 },
    { lat: 48.8028589, lng: 18.1153341 },
    { lat: 48.8028474, lng: 18.1152919 },
    { lat: 48.8028388, lng: 18.1152576 },
    { lat: 48.8028369, lng: 18.1152447 },
    { lat: 48.8028087, lng: 18.1150533 },
    { lat: 48.8027963, lng: 18.1149744 },
    { lat: 48.8027881, lng: 18.11493 },
    { lat: 48.8027411, lng: 18.1146839 },
    { lat: 48.8027376, lng: 18.1146643 },
    { lat: 48.802678, lng: 18.1142971 },
    { lat: 48.802594, lng: 18.1138623 },
    { lat: 48.8026092, lng: 18.1137736 },
    { lat: 48.8025999, lng: 18.1136907 },
    { lat: 48.8025997, lng: 18.1136879 },
    { lat: 48.8025994, lng: 18.1136829 },
    { lat: 48.8025974, lng: 18.1136119 },
    { lat: 48.8025638, lng: 18.1134804 },
    { lat: 48.8025625, lng: 18.1134755 },
    { lat: 48.8025579, lng: 18.1134575 },
    { lat: 48.8025561, lng: 18.113447 },
    { lat: 48.8025791, lng: 18.1132764 },
    { lat: 48.8026316, lng: 18.1131298 },
    { lat: 48.8026961, lng: 18.1129623 },
    { lat: 48.8027303, lng: 18.1128675 },
    { lat: 48.8027028, lng: 18.1126675 },
    { lat: 48.8026962, lng: 18.1126515 },
    { lat: 48.8026553, lng: 18.1125506 },
    { lat: 48.8026198, lng: 18.1124648 },
    { lat: 48.8026131, lng: 18.112446 },
    { lat: 48.8025864, lng: 18.1123529 },
    { lat: 48.8025905, lng: 18.1123463 },
    { lat: 48.8027071, lng: 18.1122165 },
    { lat: 48.8027238, lng: 18.112195 },
    { lat: 48.8027414, lng: 18.1121687 },
    { lat: 48.8028351, lng: 18.1120319 },
    { lat: 48.8029132, lng: 18.1119388 },
    { lat: 48.8030006, lng: 18.1117747 },
    { lat: 48.8030855, lng: 18.1116445 },
    { lat: 48.8031054, lng: 18.1116171 },
    { lat: 48.803143, lng: 18.1115645 },
    { lat: 48.8031769, lng: 18.111488 },
    { lat: 48.8031838, lng: 18.1114724 },
    { lat: 48.8032508, lng: 18.1112824 },
    { lat: 48.8032777, lng: 18.1112054 },
    { lat: 48.8032991, lng: 18.111146 },
    { lat: 48.8033678, lng: 18.1109488 },
    { lat: 48.803428, lng: 18.1107797 },
    { lat: 48.8034289, lng: 18.110776 },
    { lat: 48.8034831, lng: 18.1106016 },
    { lat: 48.803484, lng: 18.1105975 },
    { lat: 48.8035202, lng: 18.1105043 },
    { lat: 48.8035496, lng: 18.1104284 },
    { lat: 48.8035628, lng: 18.1103253 },
    { lat: 48.8035952, lng: 18.1102605 },
    { lat: 48.8035974, lng: 18.1102574 },
    { lat: 48.8036643, lng: 18.1101113 },
    { lat: 48.8036725, lng: 18.1100941 },
    { lat: 48.8036734, lng: 18.1100922 },
    { lat: 48.8036865, lng: 18.1100461 },
    { lat: 48.8036888, lng: 18.1100436 },
    { lat: 48.8037026, lng: 18.1100289 },
    { lat: 48.8037218, lng: 18.1099937 },
    { lat: 48.8037231, lng: 18.1099349 },
    { lat: 48.8037355, lng: 18.1098799 },
    { lat: 48.8037472, lng: 18.1098296 },
    { lat: 48.8037617, lng: 18.1097647 },
    { lat: 48.8037711, lng: 18.1096947 },
    { lat: 48.8037702, lng: 18.1096597 },
    { lat: 48.8037628, lng: 18.1095114 },
    { lat: 48.8037632, lng: 18.1095021 },
    { lat: 48.8037721, lng: 18.1093419 },
    { lat: 48.8037731, lng: 18.1093232 },
    { lat: 48.8037886, lng: 18.1091428 },
    { lat: 48.8038316, lng: 18.1090483 },
    { lat: 48.8038112, lng: 18.1089494 },
    { lat: 48.8038051, lng: 18.108923 },
    { lat: 48.8038297, lng: 18.1088202 },
    { lat: 48.8038392, lng: 18.1087895 },
    { lat: 48.8038408, lng: 18.1087589 },
    { lat: 48.8038497, lng: 18.1085425 },
    { lat: 48.8038532, lng: 18.108469 },
    { lat: 48.8038551, lng: 18.1084248 },
    { lat: 48.8038552, lng: 18.1084051 },
    { lat: 48.8038609, lng: 18.1082106 },
    { lat: 48.8038618, lng: 18.1081697 },
    { lat: 48.8039009, lng: 18.1080087 },
    { lat: 48.8039034, lng: 18.1079944 },
    { lat: 48.8039311, lng: 18.1078286 },
    { lat: 48.8039395, lng: 18.1077985 },
    { lat: 48.8039781, lng: 18.1076701 },
    { lat: 48.8039987, lng: 18.1075761 },
    { lat: 48.804026, lng: 18.1074808 },
    { lat: 48.8040325, lng: 18.1074585 },
    { lat: 48.8040608, lng: 18.107343 },
    { lat: 48.8041305, lng: 18.1071837 },
    { lat: 48.8041013, lng: 18.1071296 },
    { lat: 48.8041564, lng: 18.107008 },
    { lat: 48.8041938, lng: 18.1068939 },
    { lat: 48.804212, lng: 18.1068413 },
    { lat: 48.8042081, lng: 18.1068307 },
    { lat: 48.8041974, lng: 18.1068014 },
    { lat: 48.8043136, lng: 18.1063359 },
    { lat: 48.8043642, lng: 18.1061991 },
    { lat: 48.8043733, lng: 18.1061603 },
    { lat: 48.8043701, lng: 18.1061053 },
    { lat: 48.804324, lng: 18.1060892 },
    { lat: 48.8043353, lng: 18.1060104 },
    { lat: 48.8042944, lng: 18.1059102 },
    { lat: 48.8042758, lng: 18.1057638 },
    { lat: 48.8041923, lng: 18.1055698 },
    { lat: 48.8042205, lng: 18.1055496 },
    { lat: 48.8042493, lng: 18.1055049 },
    { lat: 48.8041415, lng: 18.1052981 },
    { lat: 48.8041342, lng: 18.1051727 },
    { lat: 48.8040847, lng: 18.1050615 },
    { lat: 48.8040594, lng: 18.1050334 },
    { lat: 48.8040402, lng: 18.1049765 },
    { lat: 48.8040207, lng: 18.1049125 },
    { lat: 48.8040055, lng: 18.1048071 },
    { lat: 48.8039964, lng: 18.1047478 },
    { lat: 48.8039828, lng: 18.1046483 },
    { lat: 48.8039729, lng: 18.1046117 },
    { lat: 48.8039319, lng: 18.1044529 },
    { lat: 48.8039319, lng: 18.1043179 },
    { lat: 48.8039378, lng: 18.1042444 },
    { lat: 48.8039396, lng: 18.1041583 },
    { lat: 48.8039394, lng: 18.1040989 },
    { lat: 48.8039331, lng: 18.1035645 },
    { lat: 48.8039292, lng: 18.1034365 },
    { lat: 48.8039091, lng: 18.1032283 },
    { lat: 48.8039151, lng: 18.1030603 },
    { lat: 48.8039189, lng: 18.1029506 },
    { lat: 48.8039095, lng: 18.1028874 },
    { lat: 48.8039013, lng: 18.1028237 },
    { lat: 48.8038975, lng: 18.1027573 },
    { lat: 48.8038981, lng: 18.1027479 },
    { lat: 48.8038973, lng: 18.1027341 },
    { lat: 48.8038897, lng: 18.1026442 },
    { lat: 48.8038892, lng: 18.1026356 },
    { lat: 48.803862, lng: 18.1026514 },
    { lat: 48.803814, lng: 18.10268 },
    { lat: 48.8038085, lng: 18.1026836 },
    { lat: 48.8037935, lng: 18.1025607 },
    { lat: 48.8038097, lng: 18.1024898 },
    { lat: 48.8038295, lng: 18.1022899 },
    { lat: 48.8038332, lng: 18.1022547 },
    { lat: 48.8038387, lng: 18.1021959 },
    { lat: 48.8038535, lng: 18.1019061 },
    { lat: 48.8038602, lng: 18.1017488 },
    { lat: 48.8038691, lng: 18.1015725 },
    { lat: 48.8038701, lng: 18.1015541 },
    { lat: 48.8038815, lng: 18.1014008 },
    { lat: 48.8039414, lng: 18.1011293 },
    { lat: 48.8039864, lng: 18.1009311 },
    { lat: 48.8039977, lng: 18.1008576 },
    { lat: 48.8040883, lng: 18.1005333 },
    { lat: 48.8043511, lng: 18.0997156 },
    { lat: 48.8046239, lng: 18.0992274 },
    { lat: 48.8046249, lng: 18.0991871 },
    { lat: 48.8046302, lng: 18.0991245 },
    { lat: 48.8046333, lng: 18.0990808 },
    { lat: 48.8046406, lng: 18.0989742 },
    { lat: 48.80465, lng: 18.0988831 },
    { lat: 48.8046543, lng: 18.0988392 },
    { lat: 48.8046969, lng: 18.0987601 },
    { lat: 48.8047179, lng: 18.0987702 },
    { lat: 48.8047436, lng: 18.0987864 },
    { lat: 48.8047671, lng: 18.0987217 },
    { lat: 48.8048901, lng: 18.0983376 },
    { lat: 48.804902, lng: 18.098307 },
    { lat: 48.8050401, lng: 18.0978689 },
    { lat: 48.8050767, lng: 18.097742 },
    { lat: 48.8051876, lng: 18.0973598 },
    { lat: 48.8054977, lng: 18.0962905 },
    { lat: 48.8056135, lng: 18.0957985 },
    { lat: 48.80585, lng: 18.0949027 },
    { lat: 48.806041, lng: 18.0941525 },
    { lat: 48.806277, lng: 18.0932441 },
    { lat: 48.8063767, lng: 18.0930393 },
    { lat: 48.8064041, lng: 18.0929869 },
    { lat: 48.8063918, lng: 18.0929699 },
    { lat: 48.806403, lng: 18.0929477 },
    { lat: 48.8065046, lng: 18.0928163 },
    { lat: 48.8066033, lng: 18.0928213 },
    { lat: 48.8067007, lng: 18.092712 },
    { lat: 48.8068361, lng: 18.0923455 },
    { lat: 48.8069848, lng: 18.0920886 },
    { lat: 48.8070335, lng: 18.0917452 },
    { lat: 48.8070008, lng: 18.0916662 },
    { lat: 48.8069907, lng: 18.0914362 },
    { lat: 48.8070111, lng: 18.091243 },
    { lat: 48.8070112, lng: 18.0909935 },
    { lat: 48.806886, lng: 18.0908423 },
    { lat: 48.8068558, lng: 18.0906125 },
    { lat: 48.8069215, lng: 18.0904255 },
    { lat: 48.8069062, lng: 18.0901429 },
    { lat: 48.8072504, lng: 18.0900274 },
    { lat: 48.8074191, lng: 18.090037 },
    { lat: 48.8075423, lng: 18.0899632 },
    { lat: 48.8076691, lng: 18.0897372 },
    { lat: 48.808045, lng: 18.088674 },
    { lat: 48.8080665, lng: 18.0882336 },
    { lat: 48.808333, lng: 18.0878478 },
    { lat: 48.8084176, lng: 18.0872475 },
    { lat: 48.8084862, lng: 18.0866289 },
    { lat: 48.8087022, lng: 18.0861521 },
    { lat: 48.8087344, lng: 18.0855251 },
    { lat: 48.8088709, lng: 18.0849225 },
    { lat: 48.8089302, lng: 18.0839303 },
    { lat: 48.8088545, lng: 18.0825788 },
    { lat: 48.8087049, lng: 18.0815236 },
    { lat: 48.8087196, lng: 18.0808288 },
    { lat: 48.8086591, lng: 18.0802175 },
    { lat: 48.8085831, lng: 18.0799613 },
    { lat: 48.8085269, lng: 18.0793641 },
    { lat: 48.808663, lng: 18.0790509 },
    { lat: 48.808461, lng: 18.0785495 },
    { lat: 48.8084783, lng: 18.0783438 },
    { lat: 48.8083721, lng: 18.0779921 },
    { lat: 48.8083897, lng: 18.0775407 },
    { lat: 48.8083406, lng: 18.0772795 },
    { lat: 48.8080024, lng: 18.0767284 },
    { lat: 48.8078412, lng: 18.0761316 },
    { lat: 48.8074466, lng: 18.075273 },
    { lat: 48.807129, lng: 18.0747624 },
    { lat: 48.806784, lng: 18.0738635 },
    { lat: 48.8066251, lng: 18.0735564 },
    { lat: 48.8064745, lng: 18.0733146 },
    { lat: 48.8062694, lng: 18.0724155 },
    { lat: 48.8064454, lng: 18.0716808 },
    { lat: 48.8064086, lng: 18.0700109 },
    { lat: 48.8062677, lng: 18.0694552 },
    { lat: 48.8062, lng: 18.068513 },
    { lat: 48.8060231, lng: 18.0677251 },
    { lat: 48.8057431, lng: 18.0669739 },
    { lat: 48.8054359, lng: 18.0655809 },
    { lat: 48.8050892, lng: 18.0647907 },
    { lat: 48.8049447, lng: 18.0637092 },
    { lat: 48.8050892, lng: 18.062305 },
    { lat: 48.8052137, lng: 18.0614831 },
    { lat: 48.8047498, lng: 18.0600374 },
    { lat: 48.8042797, lng: 18.0593924 },
    { lat: 48.8041403, lng: 18.0584796 },
    { lat: 48.8032952, lng: 18.0579058 },
    { lat: 48.8027644, lng: 18.0577827 },
    { lat: 48.8020897, lng: 18.0573844 },
    { lat: 48.8014046, lng: 18.0572273 },
    { lat: 48.8009941, lng: 18.0570423 },
    { lat: 48.800473, lng: 18.056546 },
    { lat: 48.8000919, lng: 18.056071 },
    { lat: 48.7995513, lng: 18.0556858 },
    { lat: 48.7991415, lng: 18.0551657 },
    { lat: 48.798344, lng: 18.0545935 },
    { lat: 48.7980355, lng: 18.0545107 },
    { lat: 48.7975652, lng: 18.0540578 },
    { lat: 48.7971697, lng: 18.0540834 },
    { lat: 48.7969256, lng: 18.0538087 },
    { lat: 48.7963593, lng: 18.0537181 },
    { lat: 48.7961024, lng: 18.0536689 },
    { lat: 48.7957915, lng: 18.0535825 },
    { lat: 48.7954079, lng: 18.053642 },
    { lat: 48.7952155, lng: 18.0536725 },
    { lat: 48.7948315, lng: 18.0538226 },
    { lat: 48.794632, lng: 18.0538856 },
    { lat: 48.7946072, lng: 18.0539018 },
    { lat: 48.794547, lng: 18.053955 },
    { lat: 48.7944742, lng: 18.0540203 },
    { lat: 48.7943054, lng: 18.0542023 },
    { lat: 48.7941592, lng: 18.0543634 },
    { lat: 48.793753, lng: 18.0542122 },
    { lat: 48.7935598, lng: 18.0540144 },
    { lat: 48.7934457, lng: 18.0542621 },
    { lat: 48.7933495, lng: 18.0545098 },
    { lat: 48.7930975, lng: 18.0548501 },
    { lat: 48.7926149, lng: 18.0548184 },
    { lat: 48.7921642, lng: 18.0541592 },
    { lat: 48.7917143, lng: 18.0537436 },
    { lat: 48.7915697, lng: 18.0537011 },
    { lat: 48.7913442, lng: 18.0536305 },
    { lat: 48.7912046, lng: 18.053597 },
    { lat: 48.7911941, lng: 18.0536271 },
    { lat: 48.7908777, lng: 18.0534109 },
    { lat: 48.7906542, lng: 18.0531413 },
    { lat: 48.7904073, lng: 18.0528946 },
    { lat: 48.7903109, lng: 18.0526954 },
    { lat: 48.7900215, lng: 18.0522858 },
    { lat: 48.7897273, lng: 18.0519277 },
    { lat: 48.7893767, lng: 18.0511438 },
    { lat: 48.7893509, lng: 18.0510302 },
    { lat: 48.7893165, lng: 18.0509644 },
    { lat: 48.7891966, lng: 18.0507106 },
    { lat: 48.7890295, lng: 18.0505362 },
    { lat: 48.7888078, lng: 18.050414 },
    { lat: 48.7885899, lng: 18.0503717 },
    { lat: 48.7883909, lng: 18.0503859 },
    { lat: 48.7882533, lng: 18.050407 },
    { lat: 48.7880169, lng: 18.0504724 },
    { lat: 48.7877491, lng: 18.0504505 },
    { lat: 48.7875277, lng: 18.0504863 },
    { lat: 48.7871167, lng: 18.0502897 },
    { lat: 48.7867305, lng: 18.0500156 },
    { lat: 48.7865097, lng: 18.0495836 },
    { lat: 48.7863682, lng: 18.0493804 },
    { lat: 48.7862121, lng: 18.0491673 },
    { lat: 48.786058, lng: 18.0488874 },
    { lat: 48.7859597, lng: 18.0487113 },
    { lat: 48.7857756, lng: 18.0484 },
    { lat: 48.7856304, lng: 18.0481386 },
    { lat: 48.785467, lng: 18.0472416 },
    { lat: 48.7854351, lng: 18.0470637 },
    { lat: 48.7853557, lng: 18.0470568 },
    { lat: 48.7845937, lng: 18.0469966 },
    { lat: 48.7842564, lng: 18.0468437 },
    { lat: 48.7839648, lng: 18.0465859 },
    { lat: 48.7834773, lng: 18.0462421 },
    { lat: 48.7832753, lng: 18.0461083 },
    { lat: 48.7828136, lng: 18.0458584 },
    { lat: 48.7824548, lng: 18.0456844 },
    { lat: 48.7821123, lng: 18.0455638 },
    { lat: 48.7818924, lng: 18.0454878 },
    { lat: 48.7818543, lng: 18.0454747 },
    { lat: 48.7818036, lng: 18.0454572 },
    { lat: 48.781272, lng: 18.0452811 },
    { lat: 48.7810781, lng: 18.0449594 },
    { lat: 48.7809339, lng: 18.0447164 },
    { lat: 48.780814, lng: 18.0446524 },
    { lat: 48.7804829, lng: 18.0444741 },
    { lat: 48.7803519, lng: 18.0444348 },
    { lat: 48.7799478, lng: 18.0443361 },
    { lat: 48.7798154, lng: 18.0442927 },
    { lat: 48.7793954, lng: 18.0440488 },
    { lat: 48.7793316, lng: 18.0440118 },
    { lat: 48.7787855, lng: 18.0428411 },
    { lat: 48.7785601, lng: 18.0423873 },
    { lat: 48.7782617, lng: 18.0420145 },
    { lat: 48.7779234, lng: 18.0417709 },
    { lat: 48.7775856, lng: 18.0412464 },
    { lat: 48.7770257, lng: 18.040478 },
    { lat: 48.7764927, lng: 18.0405312 },
    { lat: 48.7761451, lng: 18.040704 },
    { lat: 48.7762974, lng: 18.0413713 },
    { lat: 48.7763261, lng: 18.0417631 },
    { lat: 48.7763557, lng: 18.0422669 },
    { lat: 48.7763811, lng: 18.0427945 },
    { lat: 48.7764204, lng: 18.0435392 },
    { lat: 48.7764536, lng: 18.0440672 },
    { lat: 48.776483, lng: 18.0446437 },
    { lat: 48.7765672, lng: 18.0454607 },
    { lat: 48.7766324, lng: 18.0461096 },
    { lat: 48.7767077, lng: 18.0467554 },
    { lat: 48.7767179, lng: 18.0468732 },
    { lat: 48.7767348, lng: 18.0471965 },
    { lat: 48.7768074, lng: 18.0485479 },
    { lat: 48.7768435, lng: 18.0493149 },
    { lat: 48.7768529, lng: 18.0494596 },
    { lat: 48.7768609, lng: 18.0495552 },
    { lat: 48.7768959, lng: 18.0499774 },
    { lat: 48.7770195, lng: 18.0515255 },
    { lat: 48.7770332, lng: 18.0516819 },
    { lat: 48.7770724, lng: 18.0521156 },
    { lat: 48.7770932, lng: 18.05249 },
    { lat: 48.7771131, lng: 18.0529622 },
    { lat: 48.7771828, lng: 18.0536491 },
    { lat: 48.7775569, lng: 18.0562339 },
    { lat: 48.7775777, lng: 18.0562827 },
  ],
  TrenčianskeMitice: [
    { lat: 48.8447714, lng: 18.1262138 },
    { lat: 48.8448577, lng: 18.125834 },
    { lat: 48.8449197, lng: 18.1254642 },
    { lat: 48.845056, lng: 18.1242752 },
    { lat: 48.8451393, lng: 18.1235511 },
    { lat: 48.8451743, lng: 18.123258 },
    { lat: 48.8452637, lng: 18.12235 },
    { lat: 48.8454346, lng: 18.1210169 },
    { lat: 48.8454849, lng: 18.1202127 },
    { lat: 48.8455827, lng: 18.1193677 },
    { lat: 48.8456667, lng: 18.1187377 },
    { lat: 48.8457087, lng: 18.1185859 },
    { lat: 48.8458386, lng: 18.1182372 },
    { lat: 48.8458514, lng: 18.1182054 },
    { lat: 48.8459924, lng: 18.1179734 },
    { lat: 48.846198, lng: 18.1176669 },
    { lat: 48.8464227, lng: 18.117317 },
    { lat: 48.8464719, lng: 18.1172523 },
    { lat: 48.8468809, lng: 18.1166307 },
    { lat: 48.8469136, lng: 18.1165811 },
    { lat: 48.8471932, lng: 18.115934 },
    { lat: 48.8471721, lng: 18.1158637 },
    { lat: 48.846895, lng: 18.1152095 },
    { lat: 48.8467608, lng: 18.1146505 },
    { lat: 48.8464806, lng: 18.1139265 },
    { lat: 48.8464423, lng: 18.1138707 },
    { lat: 48.8462861, lng: 18.1136271 },
    { lat: 48.8460576, lng: 18.1133245 },
    { lat: 48.8460219, lng: 18.1132772 },
    { lat: 48.8457442, lng: 18.112808 },
    { lat: 48.8454856, lng: 18.1123778 },
    { lat: 48.8454217, lng: 18.1122315 },
    { lat: 48.8452395, lng: 18.1118342 },
    { lat: 48.8450784, lng: 18.1113136 },
    { lat: 48.8448625, lng: 18.1107279 },
    { lat: 48.844732, lng: 18.1104239 },
    { lat: 48.8442762, lng: 18.1105024 },
    { lat: 48.8441365, lng: 18.1105081 },
    { lat: 48.8439183, lng: 18.1105372 },
    { lat: 48.8435015, lng: 18.1104904 },
    { lat: 48.8430119, lng: 18.1096228 },
    { lat: 48.8428197, lng: 18.1089162 },
    { lat: 48.8427294, lng: 18.1085875 },
    { lat: 48.8424872, lng: 18.108363 },
    { lat: 48.842067, lng: 18.1079648 },
    { lat: 48.8419063, lng: 18.1080167 },
    { lat: 48.8416289, lng: 18.1080408 },
    { lat: 48.8414369, lng: 18.1079221 },
    { lat: 48.8412554, lng: 18.1075186 },
    { lat: 48.8409523, lng: 18.1070392 },
    { lat: 48.8401469, lng: 18.1057281 },
    { lat: 48.8400966, lng: 18.1056198 },
    { lat: 48.8396152, lng: 18.1049704 },
    { lat: 48.8394284, lng: 18.1047063 },
    { lat: 48.8392263, lng: 18.1045298 },
    { lat: 48.8391036, lng: 18.1044212 },
    { lat: 48.8388715, lng: 18.1041742 },
    { lat: 48.838689, lng: 18.1039284 },
    { lat: 48.8381647, lng: 18.1034285 },
    { lat: 48.8380941, lng: 18.1034422 },
    { lat: 48.8379043, lng: 18.1031842 },
    { lat: 48.8376109, lng: 18.102815 },
    { lat: 48.8370612, lng: 18.1021194 },
    { lat: 48.8365215, lng: 18.1012404 },
    { lat: 48.8360701, lng: 18.1002149 },
    { lat: 48.8358643, lng: 18.1000518 },
    { lat: 48.8355077, lng: 18.0998004 },
    { lat: 48.8351919, lng: 18.0995711 },
    { lat: 48.8350313, lng: 18.0995048 },
    { lat: 48.8347965, lng: 18.0994652 },
    { lat: 48.8345864, lng: 18.0994435 },
    { lat: 48.8343254, lng: 18.0993222 },
    { lat: 48.8342534, lng: 18.0992688 },
    { lat: 48.8339997, lng: 18.0990581 },
    { lat: 48.8334594, lng: 18.0989491 },
    { lat: 48.8331572, lng: 18.098954 },
    { lat: 48.8327439, lng: 18.0986958 },
    { lat: 48.8327135, lng: 18.0986773 },
    { lat: 48.8326428, lng: 18.0986237 },
    { lat: 48.8323873, lng: 18.0986593 },
    { lat: 48.8324067, lng: 18.0980986 },
    { lat: 48.8322167, lng: 18.0974932 },
    { lat: 48.8318547, lng: 18.0969739 },
    { lat: 48.8315616, lng: 18.0963809 },
    { lat: 48.8312958, lng: 18.0959991 },
    { lat: 48.8309541, lng: 18.0954846 },
    { lat: 48.8310592, lng: 18.0952703 },
    { lat: 48.8310686, lng: 18.0952006 },
    { lat: 48.831084, lng: 18.0946136 },
    { lat: 48.8310858, lng: 18.0941782 },
    { lat: 48.8306605, lng: 18.0935993 },
    { lat: 48.8304282, lng: 18.0932747 },
    { lat: 48.8300289, lng: 18.0929112 },
    { lat: 48.8297816, lng: 18.0927913 },
    { lat: 48.8292819, lng: 18.092702 },
    { lat: 48.8289305, lng: 18.0926051 },
    { lat: 48.8288109, lng: 18.0926171 },
    { lat: 48.8287963, lng: 18.0926187 },
    { lat: 48.8287852, lng: 18.0926195 },
    { lat: 48.8285455, lng: 18.0925717 },
    { lat: 48.8277627, lng: 18.0920202 },
    { lat: 48.8273965, lng: 18.0926094 },
    { lat: 48.8267923, lng: 18.0936279 },
    { lat: 48.8259809, lng: 18.0946537 },
    { lat: 48.8253302, lng: 18.0949506 },
    { lat: 48.825057, lng: 18.0948518 },
    { lat: 48.8246955, lng: 18.0947125 },
    { lat: 48.8241632, lng: 18.0945426 },
    { lat: 48.8237207, lng: 18.0944646 },
    { lat: 48.8236883, lng: 18.0944683 },
    { lat: 48.8234236, lng: 18.0946136 },
    { lat: 48.8233559, lng: 18.0946728 },
    { lat: 48.822718, lng: 18.0952098 },
    { lat: 48.8226409, lng: 18.0952627 },
    { lat: 48.8219248, lng: 18.0956965 },
    { lat: 48.8213581, lng: 18.0960237 },
    { lat: 48.8212785, lng: 18.0960698 },
    { lat: 48.820482, lng: 18.0966154 },
    { lat: 48.8199197, lng: 18.0969989 },
    { lat: 48.8198678, lng: 18.0970374 },
    { lat: 48.8194628, lng: 18.0967868 },
    { lat: 48.8186672, lng: 18.0965948 },
    { lat: 48.8179494, lng: 18.0964767 },
    { lat: 48.8178505, lng: 18.0964627 },
    { lat: 48.8178103, lng: 18.0964519 },
    { lat: 48.8175288, lng: 18.0964047 },
    { lat: 48.8170375, lng: 18.0963225 },
    { lat: 48.8163033, lng: 18.0962344 },
    { lat: 48.816135, lng: 18.0962108 },
    { lat: 48.8156465, lng: 18.0961751 },
    { lat: 48.8155082, lng: 18.0961646 },
    { lat: 48.8142796, lng: 18.0960742 },
    { lat: 48.8122101, lng: 18.0959227 },
    { lat: 48.8118768, lng: 18.0958971 },
    { lat: 48.8108598, lng: 18.0959314 },
    { lat: 48.8099221, lng: 18.096032 },
    { lat: 48.8091804, lng: 18.0957746 },
    { lat: 48.8089748, lng: 18.0956928 },
    { lat: 48.80881, lng: 18.0955598 },
    { lat: 48.8082379, lng: 18.0951329 },
    { lat: 48.8082016, lng: 18.0951099 },
    { lat: 48.8080917, lng: 18.0950268 },
    { lat: 48.8080514, lng: 18.0949925 },
    { lat: 48.8071213, lng: 18.0942178 },
    { lat: 48.8068308, lng: 18.0939566 },
    { lat: 48.8067154, lng: 18.0937383 },
    { lat: 48.8066811, lng: 18.093672 },
    { lat: 48.8065995, lng: 18.0934545 },
    { lat: 48.8065215, lng: 18.0931992 },
    { lat: 48.8064041, lng: 18.0929869 },
    { lat: 48.8063767, lng: 18.0930393 },
    { lat: 48.806277, lng: 18.0932441 },
    { lat: 48.806041, lng: 18.0941525 },
    { lat: 48.80585, lng: 18.0949027 },
    { lat: 48.8056135, lng: 18.0957985 },
    { lat: 48.8054977, lng: 18.0962905 },
    { lat: 48.8051876, lng: 18.0973598 },
    { lat: 48.8050767, lng: 18.097742 },
    { lat: 48.8050401, lng: 18.0978689 },
    { lat: 48.804902, lng: 18.098307 },
    { lat: 48.8048901, lng: 18.0983376 },
    { lat: 48.8047671, lng: 18.0987217 },
    { lat: 48.8047436, lng: 18.0987864 },
    { lat: 48.8047179, lng: 18.0987702 },
    { lat: 48.8046969, lng: 18.0987601 },
    { lat: 48.8046543, lng: 18.0988392 },
    { lat: 48.80465, lng: 18.0988831 },
    { lat: 48.8046406, lng: 18.0989742 },
    { lat: 48.8046333, lng: 18.0990808 },
    { lat: 48.8046302, lng: 18.0991245 },
    { lat: 48.8046249, lng: 18.0991871 },
    { lat: 48.8046239, lng: 18.0992274 },
    { lat: 48.8043511, lng: 18.0997156 },
    { lat: 48.8040883, lng: 18.1005333 },
    { lat: 48.8039977, lng: 18.1008576 },
    { lat: 48.8039864, lng: 18.1009311 },
    { lat: 48.8039414, lng: 18.1011293 },
    { lat: 48.8038815, lng: 18.1014008 },
    { lat: 48.8038701, lng: 18.1015541 },
    { lat: 48.8038691, lng: 18.1015725 },
    { lat: 48.8038602, lng: 18.1017488 },
    { lat: 48.8038535, lng: 18.1019061 },
    { lat: 48.8038387, lng: 18.1021959 },
    { lat: 48.8038332, lng: 18.1022547 },
    { lat: 48.8038295, lng: 18.1022899 },
    { lat: 48.8038097, lng: 18.1024898 },
    { lat: 48.8037935, lng: 18.1025607 },
    { lat: 48.8038085, lng: 18.1026836 },
    { lat: 48.803814, lng: 18.10268 },
    { lat: 48.803862, lng: 18.1026514 },
    { lat: 48.8038892, lng: 18.1026356 },
    { lat: 48.8038897, lng: 18.1026442 },
    { lat: 48.8038973, lng: 18.1027341 },
    { lat: 48.8038981, lng: 18.1027479 },
    { lat: 48.8038975, lng: 18.1027573 },
    { lat: 48.8039013, lng: 18.1028237 },
    { lat: 48.8039095, lng: 18.1028874 },
    { lat: 48.8039189, lng: 18.1029506 },
    { lat: 48.8039151, lng: 18.1030603 },
    { lat: 48.8039091, lng: 18.1032283 },
    { lat: 48.8039292, lng: 18.1034365 },
    { lat: 48.8039331, lng: 18.1035645 },
    { lat: 48.8039394, lng: 18.1040989 },
    { lat: 48.8039396, lng: 18.1041583 },
    { lat: 48.8039378, lng: 18.1042444 },
    { lat: 48.8039319, lng: 18.1043179 },
    { lat: 48.8039319, lng: 18.1044529 },
    { lat: 48.8039729, lng: 18.1046117 },
    { lat: 48.8039828, lng: 18.1046483 },
    { lat: 48.8039964, lng: 18.1047478 },
    { lat: 48.8040055, lng: 18.1048071 },
    { lat: 48.8040207, lng: 18.1049125 },
    { lat: 48.8040402, lng: 18.1049765 },
    { lat: 48.8040594, lng: 18.1050334 },
    { lat: 48.8040847, lng: 18.1050615 },
    { lat: 48.8041342, lng: 18.1051727 },
    { lat: 48.8041415, lng: 18.1052981 },
    { lat: 48.8042493, lng: 18.1055049 },
    { lat: 48.8042205, lng: 18.1055496 },
    { lat: 48.8041923, lng: 18.1055698 },
    { lat: 48.8042758, lng: 18.1057638 },
    { lat: 48.8042944, lng: 18.1059102 },
    { lat: 48.8043353, lng: 18.1060104 },
    { lat: 48.804324, lng: 18.1060892 },
    { lat: 48.8043701, lng: 18.1061053 },
    { lat: 48.8043733, lng: 18.1061603 },
    { lat: 48.8043642, lng: 18.1061991 },
    { lat: 48.8043136, lng: 18.1063359 },
    { lat: 48.8041974, lng: 18.1068014 },
    { lat: 48.8042081, lng: 18.1068307 },
    { lat: 48.804212, lng: 18.1068413 },
    { lat: 48.8041938, lng: 18.1068939 },
    { lat: 48.8041564, lng: 18.107008 },
    { lat: 48.8041013, lng: 18.1071296 },
    { lat: 48.8041305, lng: 18.1071837 },
    { lat: 48.8040608, lng: 18.107343 },
    { lat: 48.8040325, lng: 18.1074585 },
    { lat: 48.804026, lng: 18.1074808 },
    { lat: 48.8039987, lng: 18.1075761 },
    { lat: 48.8039781, lng: 18.1076701 },
    { lat: 48.8039395, lng: 18.1077985 },
    { lat: 48.8039311, lng: 18.1078286 },
    { lat: 48.8039034, lng: 18.1079944 },
    { lat: 48.8039009, lng: 18.1080087 },
    { lat: 48.8038618, lng: 18.1081697 },
    { lat: 48.8038609, lng: 18.1082106 },
    { lat: 48.8038552, lng: 18.1084051 },
    { lat: 48.8038551, lng: 18.1084248 },
    { lat: 48.8038532, lng: 18.108469 },
    { lat: 48.8038497, lng: 18.1085425 },
    { lat: 48.8038408, lng: 18.1087589 },
    { lat: 48.8038392, lng: 18.1087895 },
    { lat: 48.8038297, lng: 18.1088202 },
    { lat: 48.8038051, lng: 18.108923 },
    { lat: 48.8038112, lng: 18.1089494 },
    { lat: 48.8038316, lng: 18.1090483 },
    { lat: 48.8037886, lng: 18.1091428 },
    { lat: 48.8037731, lng: 18.1093232 },
    { lat: 48.8037721, lng: 18.1093419 },
    { lat: 48.8037632, lng: 18.1095021 },
    { lat: 48.8037628, lng: 18.1095114 },
    { lat: 48.8037702, lng: 18.1096597 },
    { lat: 48.8037711, lng: 18.1096947 },
    { lat: 48.8037617, lng: 18.1097647 },
    { lat: 48.8037472, lng: 18.1098296 },
    { lat: 48.8037355, lng: 18.1098799 },
    { lat: 48.8037231, lng: 18.1099349 },
    { lat: 48.8037218, lng: 18.1099937 },
    { lat: 48.8037026, lng: 18.1100289 },
    { lat: 48.8036888, lng: 18.1100436 },
    { lat: 48.8036865, lng: 18.1100461 },
    { lat: 48.8036734, lng: 18.1100922 },
    { lat: 48.8036725, lng: 18.1100941 },
    { lat: 48.8036643, lng: 18.1101113 },
    { lat: 48.8035974, lng: 18.1102574 },
    { lat: 48.8035952, lng: 18.1102605 },
    { lat: 48.8035628, lng: 18.1103253 },
    { lat: 48.8035496, lng: 18.1104284 },
    { lat: 48.8035202, lng: 18.1105043 },
    { lat: 48.803484, lng: 18.1105975 },
    { lat: 48.8034831, lng: 18.1106016 },
    { lat: 48.8034289, lng: 18.110776 },
    { lat: 48.803428, lng: 18.1107797 },
    { lat: 48.8033678, lng: 18.1109488 },
    { lat: 48.8032991, lng: 18.111146 },
    { lat: 48.8032777, lng: 18.1112054 },
    { lat: 48.8032508, lng: 18.1112824 },
    { lat: 48.8031838, lng: 18.1114724 },
    { lat: 48.8031769, lng: 18.111488 },
    { lat: 48.803143, lng: 18.1115645 },
    { lat: 48.8031054, lng: 18.1116171 },
    { lat: 48.8030855, lng: 18.1116445 },
    { lat: 48.8030006, lng: 18.1117747 },
    { lat: 48.8029132, lng: 18.1119388 },
    { lat: 48.8028351, lng: 18.1120319 },
    { lat: 48.8027414, lng: 18.1121687 },
    { lat: 48.8027238, lng: 18.112195 },
    { lat: 48.8027071, lng: 18.1122165 },
    { lat: 48.8025905, lng: 18.1123463 },
    { lat: 48.8025864, lng: 18.1123529 },
    { lat: 48.8026131, lng: 18.112446 },
    { lat: 48.8026198, lng: 18.1124648 },
    { lat: 48.8026553, lng: 18.1125506 },
    { lat: 48.8026962, lng: 18.1126515 },
    { lat: 48.8027028, lng: 18.1126675 },
    { lat: 48.8027303, lng: 18.1128675 },
    { lat: 48.8026961, lng: 18.1129623 },
    { lat: 48.8026316, lng: 18.1131298 },
    { lat: 48.8025791, lng: 18.1132764 },
    { lat: 48.8025561, lng: 18.113447 },
    { lat: 48.8025579, lng: 18.1134575 },
    { lat: 48.8025625, lng: 18.1134755 },
    { lat: 48.8025638, lng: 18.1134804 },
    { lat: 48.8025974, lng: 18.1136119 },
    { lat: 48.8025994, lng: 18.1136829 },
    { lat: 48.8025997, lng: 18.1136879 },
    { lat: 48.8025999, lng: 18.1136907 },
    { lat: 48.8026092, lng: 18.1137736 },
    { lat: 48.802594, lng: 18.1138623 },
    { lat: 48.802678, lng: 18.1142971 },
    { lat: 48.8027376, lng: 18.1146643 },
    { lat: 48.8027411, lng: 18.1146839 },
    { lat: 48.8027881, lng: 18.11493 },
    { lat: 48.8027963, lng: 18.1149744 },
    { lat: 48.8028087, lng: 18.1150533 },
    { lat: 48.8028369, lng: 18.1152447 },
    { lat: 48.8028388, lng: 18.1152576 },
    { lat: 48.8028474, lng: 18.1152919 },
    { lat: 48.8028589, lng: 18.1153341 },
    { lat: 48.8029299, lng: 18.1156042 },
    { lat: 48.8029486, lng: 18.1156733 },
    { lat: 48.8029449, lng: 18.1156874 },
    { lat: 48.8030126, lng: 18.1159759 },
    { lat: 48.8030455, lng: 18.1159615 },
    { lat: 48.8030474, lng: 18.115973 },
    { lat: 48.8030191, lng: 18.1159904 },
    { lat: 48.8030842, lng: 18.1162771 },
    { lat: 48.8030885, lng: 18.1162933 },
    { lat: 48.8030743, lng: 18.1163013 },
    { lat: 48.8030439, lng: 18.116319 },
    { lat: 48.8030164, lng: 18.1163348 },
    { lat: 48.8030066, lng: 18.1163404 },
    { lat: 48.8029947, lng: 18.1163477 },
    { lat: 48.8029566, lng: 18.1163721 },
    { lat: 48.8027348, lng: 18.116576 },
    { lat: 48.8026394, lng: 18.1166628 },
    { lat: 48.8026084, lng: 18.1166913 },
    { lat: 48.8025369, lng: 18.1167989 },
    { lat: 48.8024756, lng: 18.1168916 },
    { lat: 48.8024238, lng: 18.1169694 },
    { lat: 48.8023729, lng: 18.1170463 },
    { lat: 48.8023447, lng: 18.1171421 },
    { lat: 48.8022373, lng: 18.1175076 },
    { lat: 48.8021242, lng: 18.1178921 },
    { lat: 48.8020789, lng: 18.1180449 },
    { lat: 48.8023249, lng: 18.1182212 },
    { lat: 48.8030154, lng: 18.118721 },
    { lat: 48.8029181, lng: 18.1192252 },
    { lat: 48.8028393, lng: 18.1196333 },
    { lat: 48.8027628, lng: 18.1200462 },
    { lat: 48.8024233, lng: 18.1199142 },
    { lat: 48.8023991, lng: 18.1201 },
    { lat: 48.8023881, lng: 18.1201999 },
    { lat: 48.8023783, lng: 18.1202829 },
    { lat: 48.8023696, lng: 18.1203492 },
    { lat: 48.8023646, lng: 18.1203964 },
    { lat: 48.8023596, lng: 18.1204497 },
    { lat: 48.802354, lng: 18.1205077 },
    { lat: 48.802351, lng: 18.1205324 },
    { lat: 48.8023431, lng: 18.1206087 },
    { lat: 48.8023301, lng: 18.1207192 },
    { lat: 48.8023153, lng: 18.1208715 },
    { lat: 48.8023064, lng: 18.1209508 },
    { lat: 48.8022855, lng: 18.1211361 },
    { lat: 48.8022701, lng: 18.1212574 },
    { lat: 48.8022526, lng: 18.1214237 },
    { lat: 48.8024947, lng: 18.1215081 },
    { lat: 48.8024741, lng: 18.1216784 },
    { lat: 48.8024719, lng: 18.121702 },
    { lat: 48.8024521, lng: 18.1218661 },
    { lat: 48.8024425, lng: 18.1219733 },
    { lat: 48.8024255, lng: 18.1221084 },
    { lat: 48.8024077, lng: 18.1221111 },
    { lat: 48.8020785, lng: 18.1220382 },
    { lat: 48.8017553, lng: 18.1219732 },
    { lat: 48.8015971, lng: 18.1219287 },
    { lat: 48.801606, lng: 18.1217929 },
    { lat: 48.801617, lng: 18.1216526 },
    { lat: 48.8016369, lng: 18.1214899 },
    { lat: 48.8016424, lng: 18.1214469 },
    { lat: 48.8016244, lng: 18.1214472 },
    { lat: 48.8016094, lng: 18.1214442 },
    { lat: 48.8010626, lng: 18.1213172 },
    { lat: 48.8008136, lng: 18.1212437 },
    { lat: 48.8007551, lng: 18.1212873 },
    { lat: 48.8006502, lng: 18.1213668 },
    { lat: 48.8005206, lng: 18.1214642 },
    { lat: 48.800468, lng: 18.1214913 },
    { lat: 48.800343, lng: 18.1215566 },
    { lat: 48.8002631, lng: 18.1215987 },
    { lat: 48.8002091, lng: 18.121621 },
    { lat: 48.8001419, lng: 18.1216492 },
    { lat: 48.8001165, lng: 18.1216594 },
    { lat: 48.8000584, lng: 18.1216489 },
    { lat: 48.8000049, lng: 18.1216392 },
    { lat: 48.7999065, lng: 18.1215986 },
    { lat: 48.7998798, lng: 18.121643 },
    { lat: 48.7997813, lng: 18.1218122 },
    { lat: 48.7997157, lng: 18.1219247 },
    { lat: 48.7997075, lng: 18.121943 },
    { lat: 48.7996735, lng: 18.1220166 },
    { lat: 48.7996203, lng: 18.1221314 },
    { lat: 48.7995543, lng: 18.1222769 },
    { lat: 48.7995132, lng: 18.1223626 },
    { lat: 48.799501, lng: 18.1223882 },
    { lat: 48.799368, lng: 18.1227202 },
    { lat: 48.7992994, lng: 18.1228994 },
    { lat: 48.7992138, lng: 18.1231159 },
    { lat: 48.7991216, lng: 18.1233351 },
    { lat: 48.7995079, lng: 18.1234404 },
    { lat: 48.7996544, lng: 18.1234743 },
    { lat: 48.799998, lng: 18.1235441 },
    { lat: 48.8001413, lng: 18.1235798 },
    { lat: 48.8002474, lng: 18.1235994 },
    { lat: 48.8003629, lng: 18.1236262 },
    { lat: 48.8007174, lng: 18.1237279 },
    { lat: 48.8008564, lng: 18.1237667 },
    { lat: 48.8010302, lng: 18.1238284 },
    { lat: 48.8012456, lng: 18.1239018 },
    { lat: 48.8018011, lng: 18.1241421 },
    { lat: 48.8021127, lng: 18.1242796 },
    { lat: 48.8023544, lng: 18.124381 },
    { lat: 48.8026909, lng: 18.1245192 },
    { lat: 48.8029334, lng: 18.124615 },
    { lat: 48.8029436, lng: 18.1246316 },
    { lat: 48.802853, lng: 18.1249776 },
    { lat: 48.8027403, lng: 18.1253696 },
    { lat: 48.802661, lng: 18.1256544 },
    { lat: 48.8025571, lng: 18.1258883 },
    { lat: 48.8024829, lng: 18.1260686 },
    { lat: 48.8024278, lng: 18.1261658 },
    { lat: 48.8022728, lng: 18.1264907 },
    { lat: 48.8022174, lng: 18.1266073 },
    { lat: 48.8020897, lng: 18.1268545 },
    { lat: 48.8020583, lng: 18.126917 },
    { lat: 48.8019807, lng: 18.1270597 },
    { lat: 48.8018091, lng: 18.1274073 },
    { lat: 48.8017886, lng: 18.1274608 },
    { lat: 48.8016277, lng: 18.1278173 },
    { lat: 48.8015812, lng: 18.1278564 },
    { lat: 48.8014111, lng: 18.1281318 },
    { lat: 48.8013846, lng: 18.1281596 },
    { lat: 48.8012699, lng: 18.1283056 },
    { lat: 48.8012099, lng: 18.128397 },
    { lat: 48.8011034, lng: 18.1285257 },
    { lat: 48.8009673, lng: 18.1286343 },
    { lat: 48.8007932, lng: 18.1287839 },
    { lat: 48.800459, lng: 18.1290921 },
    { lat: 48.8001257, lng: 18.1294125 },
    { lat: 48.7999423, lng: 18.1295815 },
    { lat: 48.7996754, lng: 18.1298217 },
    { lat: 48.7991698, lng: 18.1303063 },
    { lat: 48.7990299, lng: 18.1304431 },
    { lat: 48.7988886, lng: 18.1305964 },
    { lat: 48.7986824, lng: 18.1307753 },
    { lat: 48.7986244, lng: 18.1308277 },
    { lat: 48.7984615, lng: 18.1309747 },
    { lat: 48.7984251, lng: 18.1310078 },
    { lat: 48.7983525, lng: 18.1310797 },
    { lat: 48.798185, lng: 18.1312466 },
    { lat: 48.7980487, lng: 18.1314276 },
    { lat: 48.7979759, lng: 18.1315511 },
    { lat: 48.7979109, lng: 18.1316374 },
    { lat: 48.7977883, lng: 18.1318599 },
    { lat: 48.7975779, lng: 18.1322601 },
    { lat: 48.7973112, lng: 18.1327577 },
    { lat: 48.797227, lng: 18.1329199 },
    { lat: 48.7968955, lng: 18.1335496 },
    { lat: 48.7967299, lng: 18.1338655 },
    { lat: 48.7966208, lng: 18.1340274 },
    { lat: 48.7966029, lng: 18.1340864 },
    { lat: 48.7964944, lng: 18.1342007 },
    { lat: 48.7961971, lng: 18.1345208 },
    { lat: 48.795822, lng: 18.1349236 },
    { lat: 48.795417, lng: 18.1353535 },
    { lat: 48.7952744, lng: 18.1355046 },
    { lat: 48.7951937, lng: 18.1355894 },
    { lat: 48.7951279, lng: 18.1356608 },
    { lat: 48.795119, lng: 18.135662 },
    { lat: 48.7950714, lng: 18.1357184 },
    { lat: 48.7949839, lng: 18.1358241 },
    { lat: 48.7948057, lng: 18.1360429 },
    { lat: 48.7947842, lng: 18.1361005 },
    { lat: 48.794683, lng: 18.1362424 },
    { lat: 48.7946719, lng: 18.1362629 },
    { lat: 48.7945762, lng: 18.1364442 },
    { lat: 48.7945589, lng: 18.1364769 },
    { lat: 48.7943793, lng: 18.1368615 },
    { lat: 48.7943403, lng: 18.1369473 },
    { lat: 48.7942421, lng: 18.1371629 },
    { lat: 48.794198, lng: 18.1372586 },
    { lat: 48.7938468, lng: 18.1380891 },
    { lat: 48.793677, lng: 18.1384885 },
    { lat: 48.7936438, lng: 18.1385981 },
    { lat: 48.7936006, lng: 18.1387291 },
    { lat: 48.7934691, lng: 18.1391865 },
    { lat: 48.7932401, lng: 18.1401439 },
    { lat: 48.7931842, lng: 18.1403825 },
    { lat: 48.7930201, lng: 18.1410553 },
    { lat: 48.7930042, lng: 18.1411938 },
    { lat: 48.7927667, lng: 18.1421394 },
    { lat: 48.7927326, lng: 18.1422682 },
    { lat: 48.7926578, lng: 18.1425569 },
    { lat: 48.7924866, lng: 18.143284 },
    { lat: 48.7924557, lng: 18.1434123 },
    { lat: 48.7924944, lng: 18.1434182 },
    { lat: 48.7925191, lng: 18.1434328 },
    { lat: 48.7925545, lng: 18.14346 },
    { lat: 48.7930025, lng: 18.1436539 },
    { lat: 48.7933508, lng: 18.1442534 },
    { lat: 48.7933942, lng: 18.1443207 },
    { lat: 48.7934381, lng: 18.1443975 },
    { lat: 48.7934755, lng: 18.1444588 },
    { lat: 48.7933002, lng: 18.1453474 },
    { lat: 48.7932546, lng: 18.145614 },
    { lat: 48.7938297, lng: 18.1455846 },
    { lat: 48.7940011, lng: 18.1455848 },
    { lat: 48.7940894, lng: 18.1455498 },
    { lat: 48.7945066, lng: 18.145425 },
    { lat: 48.7948699, lng: 18.1453282 },
    { lat: 48.7949845, lng: 18.1452976 },
    { lat: 48.7953028, lng: 18.145711 },
    { lat: 48.7957013, lng: 18.146258 },
    { lat: 48.7958089, lng: 18.1461281 },
    { lat: 48.7964513, lng: 18.1453947 },
    { lat: 48.7967545, lng: 18.1450563 },
    { lat: 48.7973241, lng: 18.1445623 },
    { lat: 48.7977385, lng: 18.1441956 },
    { lat: 48.7980039, lng: 18.1439692 },
    { lat: 48.798047, lng: 18.1439341 },
    { lat: 48.798304, lng: 18.1437901 },
    { lat: 48.7984802, lng: 18.1436955 },
    { lat: 48.7985727, lng: 18.1437956 },
    { lat: 48.7987171, lng: 18.1439852 },
    { lat: 48.7987653, lng: 18.1440425 },
    { lat: 48.7988082, lng: 18.1441005 },
    { lat: 48.7989302, lng: 18.1442561 },
    { lat: 48.7990264, lng: 18.1443776 },
    { lat: 48.79944, lng: 18.1449169 },
    { lat: 48.7996246, lng: 18.1451802 },
    { lat: 48.7998013, lng: 18.1449577 },
    { lat: 48.8001399, lng: 18.1445484 },
    { lat: 48.8003773, lng: 18.144262 },
    { lat: 48.8012853, lng: 18.1432456 },
    { lat: 48.8015638, lng: 18.1429455 },
    { lat: 48.8019445, lng: 18.1426021 },
    { lat: 48.8024048, lng: 18.1421924 },
    { lat: 48.8025524, lng: 18.142365 },
    { lat: 48.8029571, lng: 18.1429216 },
    { lat: 48.8033569, lng: 18.1438219 },
    { lat: 48.8033607, lng: 18.1438307 },
    { lat: 48.8043985, lng: 18.1436088 },
    { lat: 48.8050227, lng: 18.1437465 },
    { lat: 48.8056393, lng: 18.1439131 },
    { lat: 48.8056106, lng: 18.1436899 },
    { lat: 48.8054961, lng: 18.1430199 },
    { lat: 48.806612, lng: 18.1420817 },
    { lat: 48.8066326, lng: 18.1420643 },
    { lat: 48.8062746, lng: 18.1414574 },
    { lat: 48.8061226, lng: 18.1412294 },
    { lat: 48.8057778, lng: 18.1406527 },
    { lat: 48.8057639, lng: 18.1406295 },
    { lat: 48.8051401, lng: 18.1396179 },
    { lat: 48.8047622, lng: 18.1389403 },
    { lat: 48.8049008, lng: 18.1387407 },
    { lat: 48.8049135, lng: 18.1387276 },
    { lat: 48.8049688, lng: 18.1386694 },
    { lat: 48.8050071, lng: 18.1386292 },
    { lat: 48.8052441, lng: 18.1385512 },
    { lat: 48.8053535, lng: 18.138628 },
    { lat: 48.8055233, lng: 18.1384608 },
    { lat: 48.8056691, lng: 18.1383491 },
    { lat: 48.8059681, lng: 18.1381353 },
    { lat: 48.8061499, lng: 18.1380392 },
    { lat: 48.8063223, lng: 18.1379175 },
    { lat: 48.8064017, lng: 18.1378687 },
    { lat: 48.8065947, lng: 18.137751 },
    { lat: 48.8069006, lng: 18.1376549 },
    { lat: 48.8070128, lng: 18.1376443 },
    { lat: 48.807554, lng: 18.1373816 },
    { lat: 48.8077819, lng: 18.1372629 },
    { lat: 48.8079473, lng: 18.1372366 },
    { lat: 48.8079872, lng: 18.1372069 },
    { lat: 48.8081169, lng: 18.137127 },
    { lat: 48.8081388, lng: 18.137113 },
    { lat: 48.8088143, lng: 18.1366782 },
    { lat: 48.8089776, lng: 18.1365544 },
    { lat: 48.8090313, lng: 18.1366815 },
    { lat: 48.8090381, lng: 18.1366986 },
    { lat: 48.8092228, lng: 18.1371663 },
    { lat: 48.8096426, lng: 18.1368126 },
    { lat: 48.8097306, lng: 18.1367358 },
    { lat: 48.8099129, lng: 18.1365712 },
    { lat: 48.8099858, lng: 18.1365028 },
    { lat: 48.8100773, lng: 18.1364094 },
    { lat: 48.8101931, lng: 18.1362862 },
    { lat: 48.8103596, lng: 18.1361388 },
    { lat: 48.8104813, lng: 18.1360205 },
    { lat: 48.8105296, lng: 18.1360174 },
    { lat: 48.8106208, lng: 18.1359941 },
    { lat: 48.8110673, lng: 18.1357347 },
    { lat: 48.8112007, lng: 18.1360281 },
    { lat: 48.8114736, lng: 18.1357566 },
    { lat: 48.8118347, lng: 18.1354626 },
    { lat: 48.8121895, lng: 18.1351788 },
    { lat: 48.8123284, lng: 18.1350615 },
    { lat: 48.812503, lng: 18.1349198 },
    { lat: 48.8125977, lng: 18.1348409 },
    { lat: 48.812672, lng: 18.1347802 },
    { lat: 48.8127968, lng: 18.134673 },
    { lat: 48.8132173, lng: 18.1343561 },
    { lat: 48.8132298, lng: 18.1343387 },
    { lat: 48.8132581, lng: 18.1343006 },
    { lat: 48.8132894, lng: 18.1342581 },
    { lat: 48.8133083, lng: 18.1342323 },
    { lat: 48.8135382, lng: 18.1341692 },
    { lat: 48.8138508, lng: 18.1341888 },
    { lat: 48.8140025, lng: 18.1341941 },
    { lat: 48.8140735, lng: 18.1343884 },
    { lat: 48.8141016, lng: 18.1344444 },
    { lat: 48.8143276, lng: 18.13521 },
    { lat: 48.8144824, lng: 18.1357391 },
    { lat: 48.8145644, lng: 18.1361818 },
    { lat: 48.8150037, lng: 18.1358746 },
    { lat: 48.8157109, lng: 18.1353792 },
    { lat: 48.8168057, lng: 18.1346239 },
    { lat: 48.8173049, lng: 18.1339309 },
    { lat: 48.818123, lng: 18.1330572 },
    { lat: 48.8185741, lng: 18.1325249 },
    { lat: 48.8188266, lng: 18.1323073 },
    { lat: 48.8192076, lng: 18.1319873 },
    { lat: 48.819935, lng: 18.1320764 },
    { lat: 48.8199407, lng: 18.1320767 },
    { lat: 48.8201735, lng: 18.1321041 },
    { lat: 48.8203723, lng: 18.1321267 },
    { lat: 48.8207349, lng: 18.1317043 },
    { lat: 48.8209457, lng: 18.1314653 },
    { lat: 48.821035, lng: 18.1314108 },
    { lat: 48.8211146, lng: 18.1313641 },
    { lat: 48.8212724, lng: 18.1312808 },
    { lat: 48.8213578, lng: 18.131235 },
    { lat: 48.8216247, lng: 18.1312837 },
    { lat: 48.822034, lng: 18.1314166 },
    { lat: 48.8225236, lng: 18.1315856 },
    { lat: 48.8226586, lng: 18.1316794 },
    { lat: 48.8230199, lng: 18.1319529 },
    { lat: 48.8231954, lng: 18.1320797 },
    { lat: 48.8234396, lng: 18.1322617 },
    { lat: 48.8239388, lng: 18.1326415 },
    { lat: 48.824382, lng: 18.1329468 },
    { lat: 48.8244994, lng: 18.1330437 },
    { lat: 48.8246875, lng: 18.1331143 },
    { lat: 48.8252652, lng: 18.1333469 },
    { lat: 48.8257981, lng: 18.1332348 },
    { lat: 48.8259525, lng: 18.1331942 },
    { lat: 48.8266271, lng: 18.1326633 },
    { lat: 48.8272791, lng: 18.132097 },
    { lat: 48.82775, lng: 18.1316898 },
    { lat: 48.8281365, lng: 18.1312723 },
    { lat: 48.8283846, lng: 18.130999 },
    { lat: 48.8284987, lng: 18.1308832 },
    { lat: 48.8285768, lng: 18.1307908 },
    { lat: 48.8289519, lng: 18.1303884 },
    { lat: 48.8294688, lng: 18.1303511 },
    { lat: 48.8297473, lng: 18.1303291 },
    { lat: 48.8300658, lng: 18.1302918 },
    { lat: 48.8304591, lng: 18.1302444 },
    { lat: 48.8309819, lng: 18.1304274 },
    { lat: 48.8312454, lng: 18.1304736 },
    { lat: 48.8317301, lng: 18.1305252 },
    { lat: 48.8320581, lng: 18.1304959 },
    { lat: 48.832585, lng: 18.1299501 },
    { lat: 48.8327183, lng: 18.1296598 },
    { lat: 48.832848, lng: 18.1294097 },
    { lat: 48.8331142, lng: 18.1291919 },
    { lat: 48.8334646, lng: 18.1291052 },
    { lat: 48.8338472, lng: 18.128734 },
    { lat: 48.83413, lng: 18.1285509 },
    { lat: 48.8345161, lng: 18.1284967 },
    { lat: 48.8351403, lng: 18.1280912 },
    { lat: 48.8353456, lng: 18.1278185 },
    { lat: 48.8356746, lng: 18.1278847 },
    { lat: 48.836304, lng: 18.1282103 },
    { lat: 48.8366848, lng: 18.1285505 },
    { lat: 48.8367952, lng: 18.1287048 },
    { lat: 48.8369614, lng: 18.1287286 },
    { lat: 48.8373018, lng: 18.1288194 },
    { lat: 48.8376765, lng: 18.1289153 },
    { lat: 48.837787, lng: 18.1289325 },
    { lat: 48.8378424, lng: 18.1289387 },
    { lat: 48.8380151, lng: 18.1289963 },
    { lat: 48.8381927, lng: 18.1290014 },
    { lat: 48.8384373, lng: 18.1290953 },
    { lat: 48.8387625, lng: 18.1291745 },
    { lat: 48.8388086, lng: 18.1289584 },
    { lat: 48.8388194, lng: 18.1288907 },
    { lat: 48.8388503, lng: 18.1286666 },
    { lat: 48.8388622, lng: 18.1286178 },
    { lat: 48.8392529, lng: 18.1286646 },
    { lat: 48.8395211, lng: 18.1287343 },
    { lat: 48.8402126, lng: 18.1287672 },
    { lat: 48.8403446, lng: 18.1288045 },
    { lat: 48.8403705, lng: 18.1288232 },
    { lat: 48.8404478, lng: 18.128387 },
    { lat: 48.8405236, lng: 18.1280226 },
    { lat: 48.8406429, lng: 18.1277423 },
    { lat: 48.8406391, lng: 18.1270166 },
    { lat: 48.8406627, lng: 18.126723 },
    { lat: 48.8406187, lng: 18.1262373 },
    { lat: 48.8404782, lng: 18.1259551 },
    { lat: 48.8400391, lng: 18.1253493 },
    { lat: 48.8398781, lng: 18.1251007 },
    { lat: 48.8398125, lng: 18.1249813 },
    { lat: 48.8397794, lng: 18.1249211 },
    { lat: 48.8397235, lng: 18.1247508 },
    { lat: 48.8396854, lng: 18.1245442 },
    { lat: 48.8396767, lng: 18.1243927 },
    { lat: 48.8397281, lng: 18.1241709 },
    { lat: 48.8395432, lng: 18.1238244 },
    { lat: 48.8392714, lng: 18.123343 },
    { lat: 48.8394614, lng: 18.1233495 },
    { lat: 48.8396727, lng: 18.1233547 },
    { lat: 48.8397884, lng: 18.1233637 },
    { lat: 48.8401861, lng: 18.1233723 },
    { lat: 48.8405617, lng: 18.1233692 },
    { lat: 48.8407045, lng: 18.1233398 },
    { lat: 48.8410436, lng: 18.1233487 },
    { lat: 48.8411588, lng: 18.1233889 },
    { lat: 48.8412384, lng: 18.1235183 },
    { lat: 48.8413243, lng: 18.1236541 },
    { lat: 48.841411, lng: 18.1237289 },
    { lat: 48.8416156, lng: 18.124027 },
    { lat: 48.8421439, lng: 18.1242231 },
    { lat: 48.8423068, lng: 18.1244637 },
    { lat: 48.8425054, lng: 18.1246586 },
    { lat: 48.8428035, lng: 18.1248149 },
    { lat: 48.8429378, lng: 18.1249135 },
    { lat: 48.8430162, lng: 18.124903 },
    { lat: 48.843292, lng: 18.1251454 },
    { lat: 48.8433426, lng: 18.1251454 },
    { lat: 48.8434012, lng: 18.1251609 },
    { lat: 48.8434581, lng: 18.1251512 },
    { lat: 48.8434998, lng: 18.125266 },
    { lat: 48.8436052, lng: 18.1254476 },
    { lat: 48.8438395, lng: 18.1257535 },
    { lat: 48.8439908, lng: 18.1257932 },
    { lat: 48.8441883, lng: 18.1257945 },
    { lat: 48.8442943, lng: 18.1257915 },
    { lat: 48.8445238, lng: 18.1259924 },
    { lat: 48.8447714, lng: 18.1262138 },
  ],
  MníchovaLehota: [
    { lat: 48.8064041, lng: 18.0929869 },
    { lat: 48.8065215, lng: 18.0931992 },
    { lat: 48.8065995, lng: 18.0934545 },
    { lat: 48.8066811, lng: 18.093672 },
    { lat: 48.8067154, lng: 18.0937383 },
    { lat: 48.8068308, lng: 18.0939566 },
    { lat: 48.8071213, lng: 18.0942178 },
    { lat: 48.8080514, lng: 18.0949925 },
    { lat: 48.8080917, lng: 18.0950268 },
    { lat: 48.8082016, lng: 18.0951099 },
    { lat: 48.8082379, lng: 18.0951329 },
    { lat: 48.80881, lng: 18.0955598 },
    { lat: 48.8089748, lng: 18.0956928 },
    { lat: 48.8091804, lng: 18.0957746 },
    { lat: 48.8099221, lng: 18.096032 },
    { lat: 48.8108598, lng: 18.0959314 },
    { lat: 48.8118768, lng: 18.0958971 },
    { lat: 48.8122101, lng: 18.0959227 },
    { lat: 48.8142796, lng: 18.0960742 },
    { lat: 48.8155082, lng: 18.0961646 },
    { lat: 48.8156465, lng: 18.0961751 },
    { lat: 48.816135, lng: 18.0962108 },
    { lat: 48.8163033, lng: 18.0962344 },
    { lat: 48.8170375, lng: 18.0963225 },
    { lat: 48.8175288, lng: 18.0964047 },
    { lat: 48.8178103, lng: 18.0964519 },
    { lat: 48.8178505, lng: 18.0964627 },
    { lat: 48.8179494, lng: 18.0964767 },
    { lat: 48.8186672, lng: 18.0965948 },
    { lat: 48.8194628, lng: 18.0967868 },
    { lat: 48.8198678, lng: 18.0970374 },
    { lat: 48.8199197, lng: 18.0969989 },
    { lat: 48.820482, lng: 18.0966154 },
    { lat: 48.8212785, lng: 18.0960698 },
    { lat: 48.8213581, lng: 18.0960237 },
    { lat: 48.8219248, lng: 18.0956965 },
    { lat: 48.8226409, lng: 18.0952627 },
    { lat: 48.822718, lng: 18.0952098 },
    { lat: 48.8233559, lng: 18.0946728 },
    { lat: 48.8234236, lng: 18.0946136 },
    { lat: 48.8236883, lng: 18.0944683 },
    { lat: 48.8237207, lng: 18.0944646 },
    { lat: 48.8241632, lng: 18.0945426 },
    { lat: 48.8246955, lng: 18.0947125 },
    { lat: 48.825057, lng: 18.0948518 },
    { lat: 48.8253302, lng: 18.0949506 },
    { lat: 48.8259809, lng: 18.0946537 },
    { lat: 48.8267923, lng: 18.0936279 },
    { lat: 48.8273965, lng: 18.0926094 },
    { lat: 48.8277627, lng: 18.0920202 },
    { lat: 48.8285455, lng: 18.0925717 },
    { lat: 48.8287852, lng: 18.0926195 },
    { lat: 48.8287963, lng: 18.0926187 },
    { lat: 48.8288109, lng: 18.0926171 },
    { lat: 48.8289305, lng: 18.0926051 },
    { lat: 48.8292819, lng: 18.092702 },
    { lat: 48.8297816, lng: 18.0927913 },
    { lat: 48.8300289, lng: 18.0929112 },
    { lat: 48.8304282, lng: 18.0932747 },
    { lat: 48.8306605, lng: 18.0935993 },
    { lat: 48.8310858, lng: 18.0941782 },
    { lat: 48.831084, lng: 18.0946136 },
    { lat: 48.8310686, lng: 18.0952006 },
    { lat: 48.8310592, lng: 18.0952703 },
    { lat: 48.8309541, lng: 18.0954846 },
    { lat: 48.8312958, lng: 18.0959991 },
    { lat: 48.8315616, lng: 18.0963809 },
    { lat: 48.8318547, lng: 18.0969739 },
    { lat: 48.8322167, lng: 18.0974932 },
    { lat: 48.8324067, lng: 18.0980986 },
    { lat: 48.8323873, lng: 18.0986593 },
    { lat: 48.8326428, lng: 18.0986237 },
    { lat: 48.8327135, lng: 18.0986773 },
    { lat: 48.8327439, lng: 18.0986958 },
    { lat: 48.8331572, lng: 18.098954 },
    { lat: 48.8334594, lng: 18.0989491 },
    { lat: 48.8339997, lng: 18.0990581 },
    { lat: 48.8342534, lng: 18.0992688 },
    { lat: 48.8343254, lng: 18.0993222 },
    { lat: 48.8345864, lng: 18.0994435 },
    { lat: 48.8347965, lng: 18.0994652 },
    { lat: 48.8350313, lng: 18.0995048 },
    { lat: 48.8351919, lng: 18.0995711 },
    { lat: 48.8355077, lng: 18.0998004 },
    { lat: 48.8358643, lng: 18.1000518 },
    { lat: 48.8360701, lng: 18.1002149 },
    { lat: 48.8365215, lng: 18.1012404 },
    { lat: 48.8370612, lng: 18.1021194 },
    { lat: 48.8372755, lng: 18.1019976 },
    { lat: 48.8377096, lng: 18.1016848 },
    { lat: 48.8380683, lng: 18.1015044 },
    { lat: 48.838366, lng: 18.1013252 },
    { lat: 48.8388867, lng: 18.1010242 },
    { lat: 48.8394186, lng: 18.1007417 },
    { lat: 48.8400396, lng: 18.100222 },
    { lat: 48.8400222, lng: 18.1000975 },
    { lat: 48.8406692, lng: 18.0988269 },
    { lat: 48.8408595, lng: 18.0984532 },
    { lat: 48.8404416, lng: 18.0972065 },
    { lat: 48.8402348, lng: 18.0962138 },
    { lat: 48.8405095, lng: 18.0949625 },
    { lat: 48.8410299, lng: 18.0930299 },
    { lat: 48.8409797, lng: 18.0915695 },
    { lat: 48.8409326, lng: 18.0906848 },
    { lat: 48.8409463, lng: 18.0902587 },
    { lat: 48.8410028, lng: 18.0893706 },
    { lat: 48.8412923, lng: 18.0890355 },
    { lat: 48.8415137, lng: 18.0887687 },
    { lat: 48.8417569, lng: 18.0885645 },
    { lat: 48.8421127, lng: 18.0882205 },
    { lat: 48.8426011, lng: 18.0874249 },
    { lat: 48.8426905, lng: 18.0874276 },
    { lat: 48.8427087, lng: 18.0874327 },
    { lat: 48.8427456, lng: 18.087445 },
    { lat: 48.8428937, lng: 18.0873718 },
    { lat: 48.8430492, lng: 18.0873871 },
    { lat: 48.8432302, lng: 18.0871615 },
    { lat: 48.8433169, lng: 18.0870012 },
    { lat: 48.8433338, lng: 18.086809 },
    { lat: 48.8434831, lng: 18.0866239 },
    { lat: 48.8435212, lng: 18.0864636 },
    { lat: 48.8436115, lng: 18.0863079 },
    { lat: 48.843685, lng: 18.0861371 },
    { lat: 48.8438922, lng: 18.0858987 },
    { lat: 48.8439486, lng: 18.0857027 },
    { lat: 48.8440713, lng: 18.0856203 },
    { lat: 48.8446259, lng: 18.0849537 },
    { lat: 48.8445174, lng: 18.0848167 },
    { lat: 48.844262, lng: 18.084694 },
    { lat: 48.84406, lng: 18.084792 },
    { lat: 48.8438608, lng: 18.0847012 },
    { lat: 48.8437192, lng: 18.0844987 },
    { lat: 48.8437962, lng: 18.0841774 },
    { lat: 48.8437818, lng: 18.0841625 },
    { lat: 48.843892, lng: 18.0839593 },
    { lat: 48.8440217, lng: 18.0837466 },
    { lat: 48.844322, lng: 18.0831711 },
    { lat: 48.84463, lng: 18.0820871 },
    { lat: 48.8447591, lng: 18.0815729 },
    { lat: 48.8448084, lng: 18.081352 },
    { lat: 48.844969, lng: 18.0806439 },
    { lat: 48.8450404, lng: 18.0802994 },
    { lat: 48.8452139, lng: 18.0795817 },
    { lat: 48.8454011, lng: 18.0786737 },
    { lat: 48.8456124, lng: 18.0776511 },
    { lat: 48.8457372, lng: 18.0770263 },
    { lat: 48.8458605, lng: 18.0762795 },
    { lat: 48.8459073, lng: 18.07596 },
    { lat: 48.8458868, lng: 18.0753956 },
    { lat: 48.8458674, lng: 18.0749664 },
    { lat: 48.8459788, lng: 18.0745176 },
    { lat: 48.8462169, lng: 18.0735131 },
    { lat: 48.8462978, lng: 18.0731988 },
    { lat: 48.8464068, lng: 18.0727445 },
    { lat: 48.8464223, lng: 18.0726664 },
    { lat: 48.8464365, lng: 18.0726183 },
    { lat: 48.8464993, lng: 18.0724594 },
    { lat: 48.8465185, lng: 18.0723988 },
    { lat: 48.8465347, lng: 18.0723482 },
    { lat: 48.8466565, lng: 18.0721334 },
    { lat: 48.8469321, lng: 18.071173 },
    { lat: 48.8470033, lng: 18.0702117 },
    { lat: 48.8470827, lng: 18.0697743 },
    { lat: 48.8471614, lng: 18.0693441 },
    { lat: 48.8472131, lng: 18.0687448 },
    { lat: 48.847263, lng: 18.0682272 },
    { lat: 48.8472729, lng: 18.0681065 },
    { lat: 48.8473743, lng: 18.0676396 },
    { lat: 48.8473916, lng: 18.0675688 },
    { lat: 48.8476349, lng: 18.0670015 },
    { lat: 48.8480442, lng: 18.0662041 },
    { lat: 48.8481876, lng: 18.0657046 },
    { lat: 48.8482288, lng: 18.0655034 },
    { lat: 48.8484817, lng: 18.0642895 },
    { lat: 48.8485524, lng: 18.0638772 },
    { lat: 48.8485534, lng: 18.0637255 },
    { lat: 48.8485569, lng: 18.0635906 },
    { lat: 48.8485468, lng: 18.0635314 },
    { lat: 48.8484991, lng: 18.0635292 },
    { lat: 48.8484563, lng: 18.0635289 },
    { lat: 48.84845, lng: 18.0634617 },
    { lat: 48.8480989, lng: 18.0634949 },
    { lat: 48.8479581, lng: 18.0635027 },
    { lat: 48.8479209, lng: 18.0634848 },
    { lat: 48.847856, lng: 18.0634545 },
    { lat: 48.8478177, lng: 18.0634352 },
    { lat: 48.8477517, lng: 18.0633492 },
    { lat: 48.8475142, lng: 18.0630127 },
    { lat: 48.8474315, lng: 18.0628941 },
    { lat: 48.847285, lng: 18.0626849 },
    { lat: 48.8471136, lng: 18.0624166 },
    { lat: 48.8470255, lng: 18.0622815 },
    { lat: 48.8468136, lng: 18.0620137 },
    { lat: 48.8467107, lng: 18.0618767 },
    { lat: 48.8466609, lng: 18.0617773 },
    { lat: 48.8465182, lng: 18.0615012 },
    { lat: 48.8463219, lng: 18.0609805 },
    { lat: 48.8462454, lng: 18.0607163 },
    { lat: 48.8462016, lng: 18.0605255 },
    { lat: 48.8461906, lng: 18.0604739 },
    { lat: 48.8460189, lng: 18.0596628 },
    { lat: 48.8459493, lng: 18.0593794 },
    { lat: 48.8457565, lng: 18.0587296 },
    { lat: 48.845608, lng: 18.0582737 },
    { lat: 48.8455251, lng: 18.0578234 },
    { lat: 48.8453974, lng: 18.0577628 },
    { lat: 48.8453782, lng: 18.0577048 },
    { lat: 48.8453318, lng: 18.0575118 },
    { lat: 48.8453161, lng: 18.0570098 },
    { lat: 48.8453662, lng: 18.0566132 },
    { lat: 48.8454147, lng: 18.0563638 },
    { lat: 48.8456743, lng: 18.0558265 },
    { lat: 48.8456073, lng: 18.0557395 },
    { lat: 48.8456889, lng: 18.0556873 },
    { lat: 48.8457269, lng: 18.0554894 },
    { lat: 48.8458279, lng: 18.0553647 },
    { lat: 48.8460297, lng: 18.0553404 },
    { lat: 48.8460618, lng: 18.0552705 },
    { lat: 48.8460294, lng: 18.0550491 },
    { lat: 48.8462049, lng: 18.0546342 },
    { lat: 48.8462426, lng: 18.0544514 },
    { lat: 48.8462777, lng: 18.0543757 },
    { lat: 48.8462686, lng: 18.0541809 },
    { lat: 48.8462598, lng: 18.0540306 },
    { lat: 48.8463282, lng: 18.0537161 },
    { lat: 48.8462805, lng: 18.0534806 },
    { lat: 48.8464222, lng: 18.0532394 },
    { lat: 48.8465517, lng: 18.0529471 },
    { lat: 48.8465887, lng: 18.0525566 },
    { lat: 48.8465696, lng: 18.0523853 },
    { lat: 48.8465336, lng: 18.0522712 },
    { lat: 48.8465188, lng: 18.0521363 },
    { lat: 48.8464612, lng: 18.0519241 },
    { lat: 48.8463388, lng: 18.0518162 },
    { lat: 48.8460823, lng: 18.0515032 },
    { lat: 48.8460176, lng: 18.0512475 },
    { lat: 48.8459367, lng: 18.050985 },
    { lat: 48.8460858, lng: 18.0506202 },
    { lat: 48.8459488, lng: 18.0504226 },
    { lat: 48.8459376, lng: 18.050327 },
    { lat: 48.8459764, lng: 18.0502752 },
    { lat: 48.845973, lng: 18.0500087 },
    { lat: 48.8460216, lng: 18.0498928 },
    { lat: 48.8460002, lng: 18.0494883 },
    { lat: 48.8460693, lng: 18.0493203 },
    { lat: 48.8460453, lng: 18.0491812 },
    { lat: 48.8458521, lng: 18.0490238 },
    { lat: 48.845818, lng: 18.0489066 },
    { lat: 48.8458377, lng: 18.0485689 },
    { lat: 48.8457886, lng: 18.0485576 },
    { lat: 48.8456595, lng: 18.0485313 },
    { lat: 48.8453908, lng: 18.0472804 },
    { lat: 48.8447447, lng: 18.0475026 },
    { lat: 48.844568, lng: 18.0455488 },
    { lat: 48.8444425, lng: 18.0455815 },
    { lat: 48.844259, lng: 18.0456313 },
    { lat: 48.843639, lng: 18.0458532 },
    { lat: 48.8432439, lng: 18.0459484 },
    { lat: 48.8431676, lng: 18.0459576 },
    { lat: 48.842837, lng: 18.0453974 },
    { lat: 48.8425301, lng: 18.0449607 },
    { lat: 48.8420012, lng: 18.0445043 },
    { lat: 48.8417652, lng: 18.0439635 },
    { lat: 48.841612, lng: 18.0436033 },
    { lat: 48.841495, lng: 18.0437248 },
    { lat: 48.8413107, lng: 18.0440248 },
    { lat: 48.8411332, lng: 18.0443289 },
    { lat: 48.8401944, lng: 18.0465049 },
    { lat: 48.8397572, lng: 18.0474204 },
    { lat: 48.838584, lng: 18.048658 },
    { lat: 48.8376098, lng: 18.0494431 },
    { lat: 48.8372789, lng: 18.0496514 },
    { lat: 48.8366593, lng: 18.0495912 },
    { lat: 48.8362022, lng: 18.0496685 },
    { lat: 48.8356446, lng: 18.0497253 },
    { lat: 48.8352663, lng: 18.0495509 },
    { lat: 48.834717, lng: 18.0495554 },
    { lat: 48.8344244, lng: 18.0495866 },
    { lat: 48.8343753, lng: 18.0495961 },
    { lat: 48.8339926, lng: 18.0495197 },
    { lat: 48.8338703, lng: 18.0495308 },
    { lat: 48.8331291, lng: 18.049727 },
    { lat: 48.8328465, lng: 18.0491432 },
    { lat: 48.8324367, lng: 18.0482939 },
    { lat: 48.8321386, lng: 18.0476205 },
    { lat: 48.831973, lng: 18.0471434 },
    { lat: 48.8318749, lng: 18.0467217 },
    { lat: 48.8318404, lng: 18.0464438 },
    { lat: 48.8316994, lng: 18.0453329 },
    { lat: 48.8316928, lng: 18.0452765 },
    { lat: 48.8316864, lng: 18.0452039 },
    { lat: 48.8316838, lng: 18.0451423 },
    { lat: 48.8314725, lng: 18.0450612 },
    { lat: 48.8311044, lng: 18.0450742 },
    { lat: 48.8308667, lng: 18.0449497 },
    { lat: 48.830817, lng: 18.0449081 },
    { lat: 48.8307217, lng: 18.0447887 },
    { lat: 48.8306355, lng: 18.0447219 },
    { lat: 48.8304344, lng: 18.0446394 },
    { lat: 48.8303326, lng: 18.0446374 },
    { lat: 48.8302434, lng: 18.0446941 },
    { lat: 48.8299468, lng: 18.0450071 },
    { lat: 48.8296356, lng: 18.0450483 },
    { lat: 48.8296086, lng: 18.0450519 },
    { lat: 48.8295719, lng: 18.045059 },
    { lat: 48.8295568, lng: 18.0450346 },
    { lat: 48.8289877, lng: 18.0456812 },
    { lat: 48.8286222, lng: 18.0462032 },
    { lat: 48.8279407, lng: 18.0469538 },
    { lat: 48.8278882, lng: 18.0470024 },
    { lat: 48.8276643, lng: 18.0472443 },
    { lat: 48.8272946, lng: 18.0474248 },
    { lat: 48.826799, lng: 18.0474232 },
    { lat: 48.8262927, lng: 18.0474717 },
    { lat: 48.8260264, lng: 18.0474144 },
    { lat: 48.8258631, lng: 18.047325 },
    { lat: 48.8257414, lng: 18.0472092 },
    { lat: 48.8253814, lng: 18.046963 },
    { lat: 48.8252131, lng: 18.0469401 },
    { lat: 48.8251455, lng: 18.0469403 },
    { lat: 48.8248548, lng: 18.0469121 },
    { lat: 48.8244765, lng: 18.0469255 },
    { lat: 48.8241567, lng: 18.0469403 },
    { lat: 48.823377, lng: 18.0467319 },
    { lat: 48.8232603, lng: 18.0467007 },
    { lat: 48.8231539, lng: 18.0466209 },
    { lat: 48.8226333, lng: 18.0462494 },
    { lat: 48.8225045, lng: 18.046151 },
    { lat: 48.8222643, lng: 18.0460022 },
    { lat: 48.8218653, lng: 18.045893 },
    { lat: 48.821723, lng: 18.0458552 },
    { lat: 48.8215399, lng: 18.0458136 },
    { lat: 48.8212146, lng: 18.0457518 },
    { lat: 48.8208751, lng: 18.0456002 },
    { lat: 48.8205117, lng: 18.0455252 },
    { lat: 48.8199257, lng: 18.0453438 },
    { lat: 48.8195175, lng: 18.0451997 },
    { lat: 48.8192599, lng: 18.045041 },
    { lat: 48.818971, lng: 18.0448654 },
    { lat: 48.8187272, lng: 18.0446168 },
    { lat: 48.8185862, lng: 18.0444467 },
    { lat: 48.8184227, lng: 18.0442771 },
    { lat: 48.8180563, lng: 18.0441464 },
    { lat: 48.8178871, lng: 18.0441298 },
    { lat: 48.8174676, lng: 18.0440416 },
    { lat: 48.8171677, lng: 18.0437389 },
    { lat: 48.8169552, lng: 18.0435792 },
    { lat: 48.8167434, lng: 18.0434326 },
    { lat: 48.8164919, lng: 18.0432624 },
    { lat: 48.8162346, lng: 18.0427438 },
    { lat: 48.8160686, lng: 18.0425495 },
    { lat: 48.815875, lng: 18.0423102 },
    { lat: 48.8157877, lng: 18.042226 },
    { lat: 48.8155971, lng: 18.0420526 },
    { lat: 48.8154973, lng: 18.0418713 },
    { lat: 48.8151733, lng: 18.040894 },
    { lat: 48.814894, lng: 18.0404238 },
    { lat: 48.8146322, lng: 18.0400018 },
    { lat: 48.8142014, lng: 18.0402569 },
    { lat: 48.8141133, lng: 18.0403719 },
    { lat: 48.8140542, lng: 18.0404604 },
    { lat: 48.8138008, lng: 18.041142 },
    { lat: 48.8134888, lng: 18.0412865 },
    { lat: 48.8128629, lng: 18.041734 },
    { lat: 48.8126811, lng: 18.0419647 },
    { lat: 48.8123436, lng: 18.0422892 },
    { lat: 48.8120137, lng: 18.0427644 },
    { lat: 48.8117444, lng: 18.0431563 },
    { lat: 48.8116583, lng: 18.0433204 },
    { lat: 48.8114218, lng: 18.0437361 },
    { lat: 48.8112408, lng: 18.0439757 },
    { lat: 48.811159, lng: 18.0443706 },
    { lat: 48.8110007, lng: 18.0445011 },
    { lat: 48.8109331, lng: 18.0445231 },
    { lat: 48.81058, lng: 18.0445994 },
    { lat: 48.8103709, lng: 18.0444818 },
    { lat: 48.8101305, lng: 18.0444035 },
    { lat: 48.8100045, lng: 18.0444284 },
    { lat: 48.8093996, lng: 18.0451408 },
    { lat: 48.8092191, lng: 18.0451798 },
    { lat: 48.8090864, lng: 18.0452162 },
    { lat: 48.8087739, lng: 18.0453232 },
    { lat: 48.8083437, lng: 18.0453027 },
    { lat: 48.8080418, lng: 18.0454254 },
    { lat: 48.8078348, lng: 18.0455739 },
    { lat: 48.8076246, lng: 18.0456259 },
    { lat: 48.8074236, lng: 18.0455463 },
    { lat: 48.8072515, lng: 18.0455361 },
    { lat: 48.8070283, lng: 18.0454273 },
    { lat: 48.8066144, lng: 18.0452575 },
    { lat: 48.8059617, lng: 18.045098 },
    { lat: 48.8056719, lng: 18.0450085 },
    { lat: 48.8052339, lng: 18.044718 },
    { lat: 48.8049266, lng: 18.0446714 },
    { lat: 48.8047168, lng: 18.044579 },
    { lat: 48.8044388, lng: 18.0444972 },
    { lat: 48.8042253, lng: 18.0443974 },
    { lat: 48.80374, lng: 18.0443966 },
    { lat: 48.8034279, lng: 18.0443666 },
    { lat: 48.8033254, lng: 18.0443887 },
    { lat: 48.8031845, lng: 18.0444299 },
    { lat: 48.8026935, lng: 18.0447548 },
    { lat: 48.8026902, lng: 18.0447191 },
    { lat: 48.8026072, lng: 18.0448237 },
    { lat: 48.8025363, lng: 18.0449819 },
    { lat: 48.8020123, lng: 18.0458605 },
    { lat: 48.8011513, lng: 18.0473018 },
    { lat: 48.8003531, lng: 18.0486579 },
    { lat: 48.8003544, lng: 18.0487011 },
    { lat: 48.7998669, lng: 18.0488763 },
    { lat: 48.7995922, lng: 18.048983 },
    { lat: 48.7993698, lng: 18.0493835 },
    { lat: 48.7990619, lng: 18.0496386 },
    { lat: 48.798735, lng: 18.0500474 },
    { lat: 48.7983074, lng: 18.0501673 },
    { lat: 48.797991, lng: 18.0502578 },
    { lat: 48.7979343, lng: 18.0508092 },
    { lat: 48.7977017, lng: 18.0509211 },
    { lat: 48.7973098, lng: 18.0512054 },
    { lat: 48.7971442, lng: 18.0512853 },
    { lat: 48.7968705, lng: 18.0516833 },
    { lat: 48.7966988, lng: 18.051928 },
    { lat: 48.7963014, lng: 18.0518673 },
    { lat: 48.7958675, lng: 18.052129 },
    { lat: 48.7952007, lng: 18.0525341 },
    { lat: 48.7950402, lng: 18.0526842 },
    { lat: 48.7946413, lng: 18.0533295 },
    { lat: 48.794602, lng: 18.0533334 },
    { lat: 48.7945797, lng: 18.053529 },
    { lat: 48.794547, lng: 18.053955 },
    { lat: 48.7946072, lng: 18.0539018 },
    { lat: 48.794632, lng: 18.0538856 },
    { lat: 48.7948315, lng: 18.0538226 },
    { lat: 48.7952155, lng: 18.0536725 },
    { lat: 48.7954079, lng: 18.053642 },
    { lat: 48.7957915, lng: 18.0535825 },
    { lat: 48.7961024, lng: 18.0536689 },
    { lat: 48.7963593, lng: 18.0537181 },
    { lat: 48.7969256, lng: 18.0538087 },
    { lat: 48.7971697, lng: 18.0540834 },
    { lat: 48.7975652, lng: 18.0540578 },
    { lat: 48.7980355, lng: 18.0545107 },
    { lat: 48.798344, lng: 18.0545935 },
    { lat: 48.7991415, lng: 18.0551657 },
    { lat: 48.7995513, lng: 18.0556858 },
    { lat: 48.8000919, lng: 18.056071 },
    { lat: 48.800473, lng: 18.056546 },
    { lat: 48.8009941, lng: 18.0570423 },
    { lat: 48.8014046, lng: 18.0572273 },
    { lat: 48.8020897, lng: 18.0573844 },
    { lat: 48.8027644, lng: 18.0577827 },
    { lat: 48.8032952, lng: 18.0579058 },
    { lat: 48.8041403, lng: 18.0584796 },
    { lat: 48.8042797, lng: 18.0593924 },
    { lat: 48.8047498, lng: 18.0600374 },
    { lat: 48.8052137, lng: 18.0614831 },
    { lat: 48.8050892, lng: 18.062305 },
    { lat: 48.8049447, lng: 18.0637092 },
    { lat: 48.8050892, lng: 18.0647907 },
    { lat: 48.8054359, lng: 18.0655809 },
    { lat: 48.8057431, lng: 18.0669739 },
    { lat: 48.8060231, lng: 18.0677251 },
    { lat: 48.8062, lng: 18.068513 },
    { lat: 48.8062677, lng: 18.0694552 },
    { lat: 48.8064086, lng: 18.0700109 },
    { lat: 48.8064454, lng: 18.0716808 },
    { lat: 48.8062694, lng: 18.0724155 },
    { lat: 48.8064745, lng: 18.0733146 },
    { lat: 48.8066251, lng: 18.0735564 },
    { lat: 48.806784, lng: 18.0738635 },
    { lat: 48.807129, lng: 18.0747624 },
    { lat: 48.8074466, lng: 18.075273 },
    { lat: 48.8078412, lng: 18.0761316 },
    { lat: 48.8080024, lng: 18.0767284 },
    { lat: 48.8083406, lng: 18.0772795 },
    { lat: 48.8083897, lng: 18.0775407 },
    { lat: 48.8083721, lng: 18.0779921 },
    { lat: 48.8084783, lng: 18.0783438 },
    { lat: 48.808461, lng: 18.0785495 },
    { lat: 48.808663, lng: 18.0790509 },
    { lat: 48.8085269, lng: 18.0793641 },
    { lat: 48.8085831, lng: 18.0799613 },
    { lat: 48.8086591, lng: 18.0802175 },
    { lat: 48.8087196, lng: 18.0808288 },
    { lat: 48.8087049, lng: 18.0815236 },
    { lat: 48.8088545, lng: 18.0825788 },
    { lat: 48.8089302, lng: 18.0839303 },
    { lat: 48.8088709, lng: 18.0849225 },
    { lat: 48.8087344, lng: 18.0855251 },
    { lat: 48.8087022, lng: 18.0861521 },
    { lat: 48.8084862, lng: 18.0866289 },
    { lat: 48.8084176, lng: 18.0872475 },
    { lat: 48.808333, lng: 18.0878478 },
    { lat: 48.8080665, lng: 18.0882336 },
    { lat: 48.808045, lng: 18.088674 },
    { lat: 48.8076691, lng: 18.0897372 },
    { lat: 48.8075423, lng: 18.0899632 },
    { lat: 48.8074191, lng: 18.090037 },
    { lat: 48.8072504, lng: 18.0900274 },
    { lat: 48.8069062, lng: 18.0901429 },
    { lat: 48.8069215, lng: 18.0904255 },
    { lat: 48.8068558, lng: 18.0906125 },
    { lat: 48.806886, lng: 18.0908423 },
    { lat: 48.8070112, lng: 18.0909935 },
    { lat: 48.8070111, lng: 18.091243 },
    { lat: 48.8069907, lng: 18.0914362 },
    { lat: 48.8070008, lng: 18.0916662 },
    { lat: 48.8070335, lng: 18.0917452 },
    { lat: 48.8069848, lng: 18.0920886 },
    { lat: 48.8068361, lng: 18.0923455 },
    { lat: 48.8067007, lng: 18.092712 },
    { lat: 48.8066033, lng: 18.0928213 },
    { lat: 48.8065046, lng: 18.0928163 },
    { lat: 48.806403, lng: 18.0929477 },
    { lat: 48.8063918, lng: 18.0929699 },
    { lat: 48.8064041, lng: 18.0929869 },
  ],
  Opatovce: [
    { lat: 48.8674275, lng: 17.9630938 },
    { lat: 48.8669445, lng: 17.9622419 },
    { lat: 48.8668685, lng: 17.9621405 },
    { lat: 48.8667983, lng: 17.9620629 },
    { lat: 48.8667804, lng: 17.9620431 },
    { lat: 48.8667526, lng: 17.9620132 },
    { lat: 48.8666981, lng: 17.961893 },
    { lat: 48.8662827, lng: 17.9609646 },
    { lat: 48.865849, lng: 17.9598555 },
    { lat: 48.8656957, lng: 17.9594806 },
    { lat: 48.8656221, lng: 17.9593247 },
    { lat: 48.8650856, lng: 17.9580855 },
    { lat: 48.8649681, lng: 17.9581023 },
    { lat: 48.8649122, lng: 17.9581099 },
    { lat: 48.8648226, lng: 17.9581225 },
    { lat: 48.8647858, lng: 17.9581279 },
    { lat: 48.8647701, lng: 17.9581304 },
    { lat: 48.8647019, lng: 17.9581412 },
    { lat: 48.8646851, lng: 17.9581438 },
    { lat: 48.8646694, lng: 17.9581463 },
    { lat: 48.8632867, lng: 17.9583542 },
    { lat: 48.8612697, lng: 17.9587322 },
    { lat: 48.8615117, lng: 17.9590462 },
    { lat: 48.8615014, lng: 17.9590612 },
    { lat: 48.8614887, lng: 17.9590784 },
    { lat: 48.8614081, lng: 17.9591822 },
    { lat: 48.8613945, lng: 17.9591998 },
    { lat: 48.8597779, lng: 17.9612846 },
    { lat: 48.8596858, lng: 17.9614062 },
    { lat: 48.8594532, lng: 17.9617075 },
    { lat: 48.8592929, lng: 17.9619147 },
    { lat: 48.8589398, lng: 17.962373 },
    { lat: 48.8585633, lng: 17.9628599 },
    { lat: 48.8581988, lng: 17.9633319 },
    { lat: 48.8569849, lng: 17.9623954 },
    { lat: 48.8569413, lng: 17.9623629 },
    { lat: 48.856643, lng: 17.9621309 },
    { lat: 48.856552, lng: 17.9620609 },
    { lat: 48.8566589, lng: 17.9629485 },
    { lat: 48.8566996, lng: 17.9632909 },
    { lat: 48.8568093, lng: 17.9642047 },
    { lat: 48.8569421, lng: 17.9653024 },
    { lat: 48.8570323, lng: 17.9671439 },
    { lat: 48.8570531, lng: 17.9672457 },
    { lat: 48.8570747, lng: 17.9673628 },
    { lat: 48.8570993, lng: 17.9674903 },
    { lat: 48.8571186, lng: 17.967588 },
    { lat: 48.8571284, lng: 17.967643 },
    { lat: 48.8571795, lng: 17.9679489 },
    { lat: 48.8572779, lng: 17.968542 },
    { lat: 48.8573212, lng: 17.9687974 },
    { lat: 48.8573317, lng: 17.9688587 },
    { lat: 48.8573488, lng: 17.9689556 },
    { lat: 48.8574985, lng: 17.9698238 },
    { lat: 48.8575123, lng: 17.9699058 },
    { lat: 48.8575388, lng: 17.9701409 },
    { lat: 48.8575415, lng: 17.970171 },
    { lat: 48.857759, lng: 17.9721776 },
    { lat: 48.8577178, lng: 17.9725861 },
    { lat: 48.8576512, lng: 17.9730432 },
    { lat: 48.8575989, lng: 17.9734177 },
    { lat: 48.8574383, lng: 17.9745934 },
    { lat: 48.8573792, lng: 17.9750037 },
    { lat: 48.8573626, lng: 17.9751432 },
    { lat: 48.8573477, lng: 17.9752352 },
    { lat: 48.857343, lng: 17.9752677 },
    { lat: 48.8573405, lng: 17.9752853 },
    { lat: 48.857335, lng: 17.9753237 },
    { lat: 48.8573239, lng: 17.9754037 },
    { lat: 48.8571376, lng: 17.9753846 },
    { lat: 48.8570533, lng: 17.9753506 },
    { lat: 48.8569415, lng: 17.9753124 },
    { lat: 48.8569172, lng: 17.9754731 },
    { lat: 48.8568924, lng: 17.9756062 },
    { lat: 48.8568667, lng: 17.9756713 },
    { lat: 48.8567225, lng: 17.9759784 },
    { lat: 48.8566323, lng: 17.9761133 },
    { lat: 48.8565318, lng: 17.9761887 },
    { lat: 48.8563822, lng: 17.9763101 },
    { lat: 48.8562875, lng: 17.9763488 },
    { lat: 48.8561972, lng: 17.9763701 },
    { lat: 48.8560479, lng: 17.9764008 },
    { lat: 48.855869, lng: 17.9763556 },
    { lat: 48.8557656, lng: 17.9762862 },
    { lat: 48.8556584, lng: 17.9762105 },
    { lat: 48.8556028, lng: 17.9763772 },
    { lat: 48.8555633, lng: 17.9765084 },
    { lat: 48.8554906, lng: 17.9767101 },
    { lat: 48.8554262, lng: 17.9768604 },
    { lat: 48.85541, lng: 17.9768952 },
    { lat: 48.8553529, lng: 17.9770521 },
    { lat: 48.8553441, lng: 17.9770755 },
    { lat: 48.8552748, lng: 17.9772237 },
    { lat: 48.85527, lng: 17.9772347 },
    { lat: 48.8551082, lng: 17.9776091 },
    { lat: 48.8551042, lng: 17.9776175 },
    { lat: 48.8550472, lng: 17.9777246 },
    { lat: 48.8552364, lng: 17.9778482 },
    { lat: 48.855567, lng: 17.9780591 },
    { lat: 48.8556985, lng: 17.978143 },
    { lat: 48.8559461, lng: 17.9783011 },
    { lat: 48.8559263, lng: 17.9783668 },
    { lat: 48.8562143, lng: 17.9787122 },
    { lat: 48.8562312, lng: 17.9787332 },
    { lat: 48.8564343, lng: 17.9789797 },
    { lat: 48.8565836, lng: 17.9791562 },
    { lat: 48.8566178, lng: 17.9791981 },
    { lat: 48.8568074, lng: 17.9794289 },
    { lat: 48.8566939, lng: 17.9798172 },
    { lat: 48.8565612, lng: 17.9802825 },
    { lat: 48.8563235, lng: 17.9811088 },
    { lat: 48.8560364, lng: 17.9821113 },
    { lat: 48.8560289, lng: 17.9821367 },
    { lat: 48.8560242, lng: 17.9821531 },
    { lat: 48.8559572, lng: 17.9823916 },
    { lat: 48.8558541, lng: 17.9827663 },
    { lat: 48.8557885, lng: 17.9829931 },
    { lat: 48.8558036, lng: 17.9829996 },
    { lat: 48.8558445, lng: 17.9830238 },
    { lat: 48.855862, lng: 17.9830354 },
    { lat: 48.8559023, lng: 17.9830644 },
    { lat: 48.8562, lng: 17.9832912 },
    { lat: 48.8563667, lng: 17.9834184 },
    { lat: 48.8565547, lng: 17.9835623 },
    { lat: 48.8567406, lng: 17.9837091 },
    { lat: 48.8569173, lng: 17.9838538 },
    { lat: 48.857095, lng: 17.9839952 },
    { lat: 48.8572744, lng: 17.9841447 },
    { lat: 48.857452, lng: 17.9842882 },
    { lat: 48.8575595, lng: 17.9843865 },
    { lat: 48.8576774, lng: 17.9844909 },
    { lat: 48.8580236, lng: 17.9847904 },
    { lat: 48.8580854, lng: 17.9848996 },
    { lat: 48.8591201, lng: 17.9863804 },
    { lat: 48.8592127, lng: 17.9865137 },
    { lat: 48.8593974, lng: 17.9867778 },
    { lat: 48.8598951, lng: 17.9874625 },
    { lat: 48.8604217, lng: 17.9882354 },
    { lat: 48.860617, lng: 17.9885211 },
    { lat: 48.8606638, lng: 17.9885899 },
    { lat: 48.8606798, lng: 17.9886114 },
    { lat: 48.8610142, lng: 17.9890542 },
    { lat: 48.8611483, lng: 17.9892424 },
    { lat: 48.8613778, lng: 17.989551 },
    { lat: 48.861592, lng: 17.9898334 },
    { lat: 48.861689, lng: 17.9899665 },
    { lat: 48.8616974, lng: 17.9899764 },
    { lat: 48.8617742, lng: 17.9899549 },
    { lat: 48.8618279, lng: 17.9899322 },
    { lat: 48.8620968, lng: 17.9898196 },
    { lat: 48.8633081, lng: 17.9893111 },
    { lat: 48.8636716, lng: 17.9891585 },
    { lat: 48.8636915, lng: 17.9891504 },
    { lat: 48.8638342, lng: 17.9893482 },
    { lat: 48.8640357, lng: 17.9896474 },
    { lat: 48.8643418, lng: 17.9900675 },
    { lat: 48.8652109, lng: 17.9912549 },
    { lat: 48.8654349, lng: 17.9915664 },
    { lat: 48.8655443, lng: 17.9917165 },
    { lat: 48.8657125, lng: 17.9919492 },
    { lat: 48.8658638, lng: 17.9921591 },
    { lat: 48.866246, lng: 17.9926872 },
    { lat: 48.8662805, lng: 17.9927352 },
    { lat: 48.866401, lng: 17.992901 },
    { lat: 48.8665226, lng: 17.9930687 },
    { lat: 48.8671863, lng: 17.9939869 },
    { lat: 48.8671889, lng: 17.9939902 },
    { lat: 48.8671961, lng: 17.9940007 },
    { lat: 48.8672595, lng: 17.9938286 },
    { lat: 48.8673488, lng: 17.9936028 },
    { lat: 48.8674971, lng: 17.9932317 },
    { lat: 48.867549, lng: 17.9930773 },
    { lat: 48.8676027, lng: 17.9929169 },
    { lat: 48.8677278, lng: 17.9923015 },
    { lat: 48.8677844, lng: 17.9919425 },
    { lat: 48.8678589, lng: 17.9914674 },
    { lat: 48.8679263, lng: 17.9910395 },
    { lat: 48.8679294, lng: 17.9909989 },
    { lat: 48.867935, lng: 17.9909387 },
    { lat: 48.8679784, lng: 17.9904316 },
    { lat: 48.8679385, lng: 17.9898336 },
    { lat: 48.8679087, lng: 17.989391 },
    { lat: 48.8678973, lng: 17.9892144 },
    { lat: 48.8679086, lng: 17.9891207 },
    { lat: 48.8679563, lng: 17.9888206 },
    { lat: 48.8679562, lng: 17.9887991 },
    { lat: 48.8681834, lng: 17.987279 },
    { lat: 48.8684005, lng: 17.9856838 },
    { lat: 48.8684065, lng: 17.9856335 },
    { lat: 48.868416, lng: 17.9856032 },
    { lat: 48.868511, lng: 17.9853218 },
    { lat: 48.868942, lng: 17.9843084 },
    { lat: 48.8690241, lng: 17.9841129 },
    { lat: 48.8691568, lng: 17.9838188 },
    { lat: 48.8691747, lng: 17.9837773 },
    { lat: 48.869188, lng: 17.9837547 },
    { lat: 48.8691972, lng: 17.9837384 },
    { lat: 48.8695178, lng: 17.9831685 },
    { lat: 48.8695469, lng: 17.9830684 },
    { lat: 48.8695536, lng: 17.9828592 },
    { lat: 48.8695627, lng: 17.982745 },
    { lat: 48.8695647, lng: 17.9827232 },
    { lat: 48.869124, lng: 17.982542 },
    { lat: 48.8685537, lng: 17.9822887 },
    { lat: 48.8685545, lng: 17.9822631 },
    { lat: 48.8685772, lng: 17.9811056 },
    { lat: 48.868607, lng: 17.9799482 },
    { lat: 48.8686924, lng: 17.9798273 },
    { lat: 48.8692595, lng: 17.9790373 },
    { lat: 48.8692731, lng: 17.9790186 },
    { lat: 48.8693001, lng: 17.9789794 },
    { lat: 48.8694488, lng: 17.9787666 },
    { lat: 48.8695673, lng: 17.9785963 },
    { lat: 48.8697381, lng: 17.9783537 },
    { lat: 48.869878, lng: 17.9781589 },
    { lat: 48.8700668, lng: 17.9779005 },
    { lat: 48.8703327, lng: 17.9769207 },
    { lat: 48.870812, lng: 17.9751437 },
    { lat: 48.8708522, lng: 17.9750038 },
    { lat: 48.8708841, lng: 17.9748951 },
    { lat: 48.8712385, lng: 17.9736769 },
    { lat: 48.8712504, lng: 17.9736312 },
    { lat: 48.8712629, lng: 17.9735937 },
    { lat: 48.8713454, lng: 17.9733103 },
    { lat: 48.8714921, lng: 17.9728138 },
    { lat: 48.8715217, lng: 17.9727216 },
    { lat: 48.8710881, lng: 17.9722823 },
    { lat: 48.8705941, lng: 17.9718122 },
    { lat: 48.8704497, lng: 17.9716638 },
    { lat: 48.8701686, lng: 17.9713948 },
    { lat: 48.8699936, lng: 17.9712226 },
    { lat: 48.8699204, lng: 17.971149 },
    { lat: 48.8699306, lng: 17.9711297 },
    { lat: 48.8704962, lng: 17.9699713 },
    { lat: 48.8702882, lng: 17.9697591 },
    { lat: 48.8702773, lng: 17.9697469 },
    { lat: 48.8701309, lng: 17.9695794 },
    { lat: 48.8697339, lng: 17.9691277 },
    { lat: 48.8695777, lng: 17.9686671 },
    { lat: 48.8693831, lng: 17.9681265 },
    { lat: 48.8693138, lng: 17.967966 },
    { lat: 48.8691342, lng: 17.9675624 },
    { lat: 48.8690684, lng: 17.9674269 },
    { lat: 48.8690475, lng: 17.9673767 },
    { lat: 48.8689502, lng: 17.9671641 },
    { lat: 48.8687442, lng: 17.9666587 },
    { lat: 48.8689498, lng: 17.96617 },
    { lat: 48.8686257, lng: 17.9658055 },
    { lat: 48.8684615, lng: 17.9656422 },
    { lat: 48.8684272, lng: 17.9655576 },
    { lat: 48.8682599, lng: 17.9651581 },
    { lat: 48.8681966, lng: 17.9650051 },
    { lat: 48.8680418, lng: 17.9646401 },
    { lat: 48.867926, lng: 17.964364 },
    { lat: 48.8676659, lng: 17.9637474 },
    { lat: 48.8675382, lng: 17.9634375 },
    { lat: 48.8674275, lng: 17.9630938 },
  ],
};

export default UnitsTrenčín;
