import ScrollFilters from "./ScrollFilters";

export default function Filter({ filters, setFilters, filterForSale }) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col justify-center items-center gap-3 text-sm"
    >
      <ScrollFilters filters={filters} setFilters={setFilters} filterForSale={filterForSale} />
    </div>
  );
}
