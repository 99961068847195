const UnitsHumenné = {
  NižnéLadičkovce: [
    { lat: 49.0280228, lng: 21.8818275 },
    { lat: 49.0273757, lng: 21.8822177 },
    { lat: 49.0264277, lng: 21.8820417 },
    { lat: 49.0259339, lng: 21.8817799 },
    { lat: 49.0248827, lng: 21.8825587 },
    { lat: 49.0222888, lng: 21.8840726 },
    { lat: 49.0219691, lng: 21.8845067 },
    { lat: 49.0210384, lng: 21.8852865 },
    { lat: 49.0201269, lng: 21.8854922 },
    { lat: 49.0174697, lng: 21.8845065 },
    { lat: 49.0173417, lng: 21.8846082 },
    { lat: 49.0170196, lng: 21.8867275 },
    { lat: 49.0168636, lng: 21.886833 },
    { lat: 49.0160323, lng: 21.886648 },
    { lat: 49.0149056, lng: 21.8867984 },
    { lat: 49.0137816, lng: 21.8871031 },
    { lat: 49.0109732, lng: 21.8903263 },
    { lat: 49.0092978, lng: 21.8911483 },
    { lat: 49.0086409, lng: 21.8910576 },
    { lat: 49.0069868, lng: 21.8913054 },
    { lat: 49.005184, lng: 21.8907936 },
    { lat: 49.0028551, lng: 21.8924135 },
    { lat: 49.0016652, lng: 21.8933223 },
    { lat: 49.0002263, lng: 21.8941592 },
    { lat: 48.9992034, lng: 21.8941454 },
    { lat: 48.9988005, lng: 21.8941302 },
    { lat: 48.9999515, lng: 21.8963262 },
    { lat: 48.9999185, lng: 21.8966749 },
    { lat: 48.9992232, lng: 21.8974226 },
    { lat: 48.9981723, lng: 21.8981311 },
    { lat: 48.9981036, lng: 21.8995598 },
    { lat: 48.9977143, lng: 21.9028574 },
    { lat: 48.9964227, lng: 21.9040523 },
    { lat: 48.9960562, lng: 21.9063418 },
    { lat: 48.9958852, lng: 21.9071076 },
    { lat: 48.9957971, lng: 21.9084316 },
    { lat: 48.9957807, lng: 21.9102477 },
    { lat: 48.9957544, lng: 21.9103187 },
    { lat: 48.9964365, lng: 21.9108695 },
    { lat: 48.9978159, lng: 21.9109698 },
    { lat: 48.9989074, lng: 21.9099145 },
    { lat: 49.0000252, lng: 21.9094965 },
    { lat: 49.0003906, lng: 21.9094853 },
    { lat: 49.0010251, lng: 21.9096241 },
    { lat: 49.0015829, lng: 21.9103817 },
    { lat: 49.0025527, lng: 21.9119617 },
    { lat: 49.0036453, lng: 21.9132236 },
    { lat: 49.0046633, lng: 21.9151603 },
    { lat: 49.0050169, lng: 21.9164791 },
    { lat: 49.0052998, lng: 21.9179371 },
    { lat: 49.0060307, lng: 21.9191416 },
    { lat: 49.0062643, lng: 21.919351 },
    { lat: 49.0063342, lng: 21.9197124 },
    { lat: 49.007321, lng: 21.9211685 },
    { lat: 49.0079475, lng: 21.919856 },
    { lat: 49.0084813, lng: 21.9186281 },
    { lat: 49.0086234, lng: 21.9181942 },
    { lat: 49.0120191, lng: 21.9199762 },
    { lat: 49.0125879, lng: 21.9203697 },
    { lat: 49.0137986, lng: 21.9208753 },
    { lat: 49.0143801, lng: 21.9214118 },
    { lat: 49.0151092, lng: 21.9217796 },
    { lat: 49.0156758, lng: 21.9223085 },
    { lat: 49.0158666, lng: 21.9223397 },
    { lat: 49.016526, lng: 21.922665 },
    { lat: 49.0172184, lng: 21.9228186 },
    { lat: 49.0183534, lng: 21.922738 },
    { lat: 49.0185679, lng: 21.922765 },
    { lat: 49.0192543, lng: 21.9224552 },
    { lat: 49.0196803, lng: 21.9219951 },
    { lat: 49.0195392, lng: 21.9217069 },
    { lat: 49.0212551, lng: 21.9200125 },
    { lat: 49.0238486, lng: 21.9192286 },
    { lat: 49.0275026, lng: 21.9201066 },
    { lat: 49.0284019, lng: 21.9200428 },
    { lat: 49.0297524, lng: 21.9206505 },
    { lat: 49.0311771, lng: 21.9199085 },
    { lat: 49.0315992, lng: 21.9194235 },
    { lat: 49.0318358, lng: 21.9193568 },
    { lat: 49.0330122, lng: 21.918451 },
    { lat: 49.0339424, lng: 21.9174536 },
    { lat: 49.0341455, lng: 21.9165833 },
    { lat: 49.0343746, lng: 21.9151733 },
    { lat: 49.0345174, lng: 21.9136468 },
    { lat: 49.034534, lng: 21.9122854 },
    { lat: 49.0341181, lng: 21.9099234 },
    { lat: 49.0337956, lng: 21.9084085 },
    { lat: 49.0328317, lng: 21.9047459 },
    { lat: 49.0328313, lng: 21.9036522 },
    { lat: 49.0324018, lng: 21.9021877 },
    { lat: 49.0321211, lng: 21.9008206 },
    { lat: 49.0315075, lng: 21.8989209 },
    { lat: 49.0304931, lng: 21.896603 },
    { lat: 49.0302994, lng: 21.8963709 },
    { lat: 49.0302874, lng: 21.8962619 },
    { lat: 49.0302401, lng: 21.8962532 },
    { lat: 49.029862, lng: 21.8941961 },
    { lat: 49.0297336, lng: 21.893258 },
    { lat: 49.0296489, lng: 21.8922643 },
    { lat: 49.0297671, lng: 21.8919982 },
    { lat: 49.0295103, lng: 21.8905098 },
    { lat: 49.0292294, lng: 21.8883848 },
    { lat: 49.0287531, lng: 21.8868124 },
    { lat: 49.0285007, lng: 21.8854611 },
    { lat: 49.0283439, lng: 21.8832619 },
    { lat: 49.0285169, lng: 21.8829445 },
    { lat: 49.0280228, lng: 21.8818275 },
  ],
  Valaškovce: [
    { lat: 48.934502, lng: 22.116962 },
    { lat: 48.933198, lng: 22.11686 },
    { lat: 48.933113, lng: 22.116853 },
    { lat: 48.932603, lng: 22.116916 },
    { lat: 48.931724, lng: 22.116901 },
    { lat: 48.931794, lng: 22.115841 },
    { lat: 48.932334, lng: 22.113544 },
    { lat: 48.932209, lng: 22.112866 },
    { lat: 48.932236, lng: 22.112083 },
    { lat: 48.932063, lng: 22.111473 },
    { lat: 48.931761, lng: 22.109515 },
    { lat: 48.931929, lng: 22.108559 },
    { lat: 48.932591, lng: 22.107094 },
    { lat: 48.932943, lng: 22.105665 },
    { lat: 48.933403, lng: 22.104833 },
    { lat: 48.933945, lng: 22.103543 },
    { lat: 48.93397, lng: 22.103481 },
    { lat: 48.934002, lng: 22.103405 },
    { lat: 48.934086, lng: 22.103205 },
    { lat: 48.934211, lng: 22.102908 },
    { lat: 48.934654, lng: 22.101848 },
    { lat: 48.934654, lng: 22.101846 },
    { lat: 48.934357, lng: 22.10145 },
    { lat: 48.93422, lng: 22.101267 },
    { lat: 48.93412, lng: 22.101136 },
    { lat: 48.934033, lng: 22.10102 },
    { lat: 48.93387, lng: 22.100803 },
    { lat: 48.933648, lng: 22.100506 },
    { lat: 48.933198, lng: 22.099906 },
    { lat: 48.933217, lng: 22.099891 },
    { lat: 48.938966, lng: 22.095581 },
    { lat: 48.939007, lng: 22.095551 },
    { lat: 48.941689, lng: 22.093541 },
    { lat: 48.942132, lng: 22.093208 },
    { lat: 48.942637, lng: 22.092828 },
    { lat: 48.942635, lng: 22.092822 },
    { lat: 48.942278, lng: 22.091791 },
    { lat: 48.942176, lng: 22.091494 },
    { lat: 48.942044, lng: 22.091116 },
    { lat: 48.941959, lng: 22.090866 },
    { lat: 48.941396, lng: 22.089244 },
    { lat: 48.939234, lng: 22.082998 },
    { lat: 48.939316, lng: 22.082925 },
    { lat: 48.943101, lng: 22.079558 },
    { lat: 48.943668, lng: 22.079053 },
    { lat: 48.942089, lng: 22.074392 },
    { lat: 48.941818, lng: 22.073592 },
    { lat: 48.941175, lng: 22.072128 },
    { lat: 48.940975, lng: 22.072718 },
    { lat: 48.940956, lng: 22.072777 },
    { lat: 48.940952, lng: 22.072786 },
    { lat: 48.9401, lng: 22.073524 },
    { lat: 48.939644, lng: 22.07296 },
    { lat: 48.939619, lng: 22.072928 },
    { lat: 48.939406, lng: 22.072663 },
    { lat: 48.939218, lng: 22.072186 },
    { lat: 48.939036, lng: 22.071685 },
    { lat: 48.938982, lng: 22.071536 },
    { lat: 48.938859, lng: 22.071196 },
    { lat: 48.938867, lng: 22.071179 },
    { lat: 48.938989, lng: 22.07097 },
    { lat: 48.939079, lng: 22.070814 },
    { lat: 48.939183, lng: 22.07067 },
    { lat: 48.939822, lng: 22.069784 },
    { lat: 48.939815, lng: 22.069766 },
    { lat: 48.93927, lng: 22.068397 },
    { lat: 48.9387967, lng: 22.0690914 },
    { lat: 48.9368795, lng: 22.0711037 },
    { lat: 48.9363045, lng: 22.071855 },
    { lat: 48.9359542, lng: 22.0726351 },
    { lat: 48.9322459, lng: 22.0693803 },
    { lat: 48.9304377, lng: 22.0679378 },
    { lat: 48.9285508, lng: 22.0684278 },
    { lat: 48.9276038, lng: 22.0685673 },
    { lat: 48.9260736, lng: 22.0664763 },
    { lat: 48.9252043, lng: 22.0651586 },
    { lat: 48.9238334, lng: 22.0632282 },
    { lat: 48.9233367, lng: 22.0626413 },
    { lat: 48.9233307, lng: 22.0631389 },
    { lat: 48.9231893, lng: 22.0636266 },
    { lat: 48.9228251, lng: 22.0640576 },
    { lat: 48.9227687, lng: 22.0643826 },
    { lat: 48.9226857, lng: 22.0643535 },
    { lat: 48.9224218, lng: 22.0627602 },
    { lat: 48.922421, lng: 22.0620163 },
    { lat: 48.922201, lng: 22.0587299 },
    { lat: 48.9220552, lng: 22.0578563 },
    { lat: 48.9223986, lng: 22.0575885 },
    { lat: 48.9229546, lng: 22.0569967 },
    { lat: 48.9249256, lng: 22.0557831 },
    { lat: 48.9255675, lng: 22.0554809 },
    { lat: 48.9268507, lng: 22.0542506 },
    { lat: 48.9274168, lng: 22.0540796 },
    { lat: 48.9278524, lng: 22.0540918 },
    { lat: 48.927688, lng: 22.0536122 },
    { lat: 48.9277133, lng: 22.053487 },
    { lat: 48.9274948, lng: 22.0528173 },
    { lat: 48.9271494, lng: 22.052299 },
    { lat: 48.9271064, lng: 22.0516382 },
    { lat: 48.9274922, lng: 22.0514885 },
    { lat: 48.9273992, lng: 22.050941 },
    { lat: 48.9272683, lng: 22.0507382 },
    { lat: 48.9271217, lng: 22.0499726 },
    { lat: 48.9271777, lng: 22.0493957 },
    { lat: 48.9271212, lng: 22.0487664 },
    { lat: 48.926737, lng: 22.0480904 },
    { lat: 48.9265889, lng: 22.0469743 },
    { lat: 48.9264031, lng: 22.0460785 },
    { lat: 48.9263676, lng: 22.0452069 },
    { lat: 48.9262055, lng: 22.0451504 },
    { lat: 48.9251108, lng: 22.0456186 },
    { lat: 48.9248801, lng: 22.045087 },
    { lat: 48.9246893, lng: 22.0448264 },
    { lat: 48.9246594, lng: 22.0443265 },
    { lat: 48.924391, lng: 22.0438306 },
    { lat: 48.9242017, lng: 22.0433478 },
    { lat: 48.9247167, lng: 22.0430607 },
    { lat: 48.925516, lng: 22.0424825 },
    { lat: 48.9254004, lng: 22.041742 },
    { lat: 48.9249296, lng: 22.0415316 },
    { lat: 48.9244936, lng: 22.0415922 },
    { lat: 48.9240252, lng: 22.0418577 },
    { lat: 48.9228883, lng: 22.0420079 },
    { lat: 48.9213514, lng: 22.0419469 },
    { lat: 48.9204458, lng: 22.0415631 },
    { lat: 48.9197617, lng: 22.04146 },
    { lat: 48.917717, lng: 22.0418927 },
    { lat: 48.9157532, lng: 22.042135 },
    { lat: 48.9153123, lng: 22.0422763 },
    { lat: 48.9154598, lng: 22.0406843 },
    { lat: 48.9166842, lng: 22.0394063 },
    { lat: 48.9174732, lng: 22.0381141 },
    { lat: 48.917617, lng: 22.0375844 },
    { lat: 48.9173378, lng: 22.0357576 },
    { lat: 48.9167515, lng: 22.0347622 },
    { lat: 48.9168755, lng: 22.0343534 },
    { lat: 48.9161855, lng: 22.0330272 },
    { lat: 48.9162998, lng: 22.0327745 },
    { lat: 48.917311, lng: 22.0327231 },
    { lat: 48.9175606, lng: 22.0327722 },
    { lat: 48.9178821, lng: 22.0317837 },
    { lat: 48.916434, lng: 22.0273778 },
    { lat: 48.9151276, lng: 22.0251194 },
    { lat: 48.9187966, lng: 22.0202737 },
    { lat: 48.9176244, lng: 22.0186845 },
    { lat: 48.9171672, lng: 22.0179686 },
    { lat: 48.9161767, lng: 22.0168261 },
    { lat: 48.9137565, lng: 22.0189398 },
    { lat: 48.911282, lng: 22.0209847 },
    { lat: 48.9073782, lng: 22.0244528 },
    { lat: 48.9052089, lng: 22.0265196 },
    { lat: 48.9047643, lng: 22.0268797 },
    { lat: 48.9043499, lng: 22.0248103 },
    { lat: 48.9042884, lng: 22.0244977 },
    { lat: 48.9040493, lng: 22.0240061 },
    { lat: 48.9035346, lng: 22.0235231 },
    { lat: 48.9035293, lng: 22.023341 },
    { lat: 48.9033154, lng: 22.0223639 },
    { lat: 48.9031465, lng: 22.0221699 },
    { lat: 48.9030895, lng: 22.0214426 },
    { lat: 48.9031887, lng: 22.0208872 },
    { lat: 48.9026638, lng: 22.0203497 },
    { lat: 48.9026052, lng: 22.0204313 },
    { lat: 48.9024755, lng: 22.0202333 },
    { lat: 48.9019732, lng: 22.0194654 },
    { lat: 48.9017715, lng: 22.0194087 },
    { lat: 48.9014471, lng: 22.0198086 },
    { lat: 48.9013754, lng: 22.020233 },
    { lat: 48.9008971, lng: 22.0194313 },
    { lat: 48.9006706, lng: 22.0188607 },
    { lat: 48.9003277, lng: 22.0182696 },
    { lat: 48.900114, lng: 22.018176 },
    { lat: 48.9000624, lng: 22.0182711 },
    { lat: 48.8999669, lng: 22.0181845 },
    { lat: 48.8995, lng: 22.0175702 },
    { lat: 48.8995312, lng: 22.017517 },
    { lat: 48.8991611, lng: 22.0172817 },
    { lat: 48.8990757, lng: 22.0170076 },
    { lat: 48.898977, lng: 22.0169709 },
    { lat: 48.899033, lng: 22.0168684 },
    { lat: 48.899005, lng: 22.0167841 },
    { lat: 48.8993507, lng: 22.016097 },
    { lat: 48.8994379, lng: 22.0161261 },
    { lat: 48.899998, lng: 22.0151002 },
    { lat: 48.9005094, lng: 22.0149995 },
    { lat: 48.9006524, lng: 22.0150909 },
    { lat: 48.9007674, lng: 22.0146837 },
    { lat: 48.9009353, lng: 22.014463 },
    { lat: 48.9010093, lng: 22.0139973 },
    { lat: 48.9008281, lng: 22.0132477 },
    { lat: 48.9007911, lng: 22.0128801 },
    { lat: 48.9008822, lng: 22.012186 },
    { lat: 48.9010955, lng: 22.0114272 },
    { lat: 48.9013352, lng: 22.0111561 },
    { lat: 48.9014665, lng: 22.0108634 },
    { lat: 48.9015617, lng: 22.0105635 },
    { lat: 48.9015956, lng: 22.0101526 },
    { lat: 48.9013041, lng: 22.0088056 },
    { lat: 48.9013941, lng: 22.0080673 },
    { lat: 48.9015258, lng: 22.0076108 },
    { lat: 48.9017501, lng: 22.0072093 },
    { lat: 48.901874, lng: 22.0066139 },
    { lat: 48.9019833, lng: 22.0058851 },
    { lat: 48.9019645, lng: 22.0048783 },
    { lat: 48.9020127, lng: 22.0047791 },
    { lat: 48.9022779, lng: 22.0046795 },
    { lat: 48.9024288, lng: 22.0045491 },
    { lat: 48.9024889, lng: 22.0042372 },
    { lat: 48.9026829, lng: 22.0037036 },
    { lat: 48.902632, lng: 22.0034136 },
    { lat: 48.9026894, lng: 22.0031394 },
    { lat: 48.9025465, lng: 22.0032339 },
    { lat: 48.9021377, lng: 22.0032611 },
    { lat: 48.902029, lng: 22.0029951 },
    { lat: 48.9014595, lng: 22.0042333 },
    { lat: 48.9012349, lng: 22.0046235 },
    { lat: 48.9005017, lng: 22.0033781 },
    { lat: 48.9006652, lng: 22.0029281 },
    { lat: 48.9014762, lng: 22.0017761 },
    { lat: 48.9007582, lng: 22.0001506 },
    { lat: 48.9001106, lng: 21.9994176 },
    { lat: 48.8953794, lng: 21.9977074 },
    { lat: 48.8954888, lng: 21.996473 },
    { lat: 48.8952441, lng: 21.9951125 },
    { lat: 48.8952241, lng: 21.9945724 },
    { lat: 48.8955517, lng: 21.9937986 },
    { lat: 48.8955484, lng: 21.9926015 },
    { lat: 48.895428, lng: 21.9920077 },
    { lat: 48.8956574, lng: 21.9918164 },
    { lat: 48.8956254, lng: 21.9907886 },
    { lat: 48.8952717, lng: 21.9871793 },
    { lat: 48.8952491, lng: 21.9868556 },
    { lat: 48.894336, lng: 21.9875112 },
    { lat: 48.8910746, lng: 21.968809 },
    { lat: 48.8903212, lng: 21.9683188 },
    { lat: 48.8899097, lng: 21.9689262 },
    { lat: 48.8886948, lng: 21.9691438 },
    { lat: 48.8879473, lng: 21.9694017 },
    { lat: 48.8873389, lng: 21.9689039 },
    { lat: 48.8868769, lng: 21.9682699 },
    { lat: 48.8857704, lng: 21.9673787 },
    { lat: 48.8847814, lng: 21.9667318 },
    { lat: 48.8849631, lng: 21.9662075 },
    { lat: 48.8854098, lng: 21.9652117 },
    { lat: 48.8855605, lng: 21.9647471 },
    { lat: 48.8864278, lng: 21.9634354 },
    { lat: 48.8861467, lng: 21.9633906 },
    { lat: 48.8856994, lng: 21.9633192 },
    { lat: 48.8845957, lng: 21.9627163 },
    { lat: 48.8836854, lng: 21.962382 },
    { lat: 48.8834594, lng: 21.962397 },
    { lat: 48.8829655, lng: 21.9627081 },
    { lat: 48.8824207, lng: 21.962876 },
    { lat: 48.8818329, lng: 21.9629362 },
    { lat: 48.8813394, lng: 21.963323 },
    { lat: 48.8808832, lng: 21.9641598 },
    { lat: 48.8804029, lng: 21.9641128 },
    { lat: 48.880014, lng: 21.9639908 },
    { lat: 48.8796463, lng: 21.9639931 },
    { lat: 48.8785322, lng: 21.9638344 },
    { lat: 48.877888, lng: 21.9640372 },
    { lat: 48.8774425, lng: 21.9639438 },
    { lat: 48.8771237, lng: 21.9631085 },
    { lat: 48.8767843, lng: 21.9625743 },
    { lat: 48.8764637, lng: 21.9621161 },
    { lat: 48.8759351, lng: 21.9616137 },
    { lat: 48.8750287, lng: 21.9620783 },
    { lat: 48.874547, lng: 21.960301 },
    { lat: 48.8741671, lng: 21.9605821 },
    { lat: 48.8742276, lng: 21.9608938 },
    { lat: 48.8726008, lng: 21.9615666 },
    { lat: 48.8706228, lng: 21.9630028 },
    { lat: 48.8693173, lng: 21.9640267 },
    { lat: 48.8692555, lng: 21.964171 },
    { lat: 48.8690863, lng: 21.9641888 },
    { lat: 48.8689031, lng: 21.9642957 },
    { lat: 48.8692651, lng: 21.965645 },
    { lat: 48.8691008, lng: 21.9659241 },
    { lat: 48.8687171, lng: 21.9663268 },
    { lat: 48.8677579, lng: 21.9666592 },
    { lat: 48.8665894, lng: 21.9671728 },
    { lat: 48.8653522, lng: 21.967453 },
    { lat: 48.8652265, lng: 21.9685959 },
    { lat: 48.8645653, lng: 21.9701622 },
    { lat: 48.8628431, lng: 21.9693865 },
    { lat: 48.8621774, lng: 21.9692531 },
    { lat: 48.8613457, lng: 21.9689241 },
    { lat: 48.8600117, lng: 21.9686067 },
    { lat: 48.8576015, lng: 21.9676712 },
    { lat: 48.8576012, lng: 21.9677916 },
    { lat: 48.8574009, lng: 21.967879 },
    { lat: 48.8574166, lng: 21.9681529 },
    { lat: 48.8573446, lng: 21.9683302 },
    { lat: 48.8573221, lng: 21.9691088 },
    { lat: 48.8573743, lng: 21.9692687 },
    { lat: 48.8573758, lng: 21.9695483 },
    { lat: 48.8572764, lng: 21.9698087 },
    { lat: 48.857096, lng: 21.9699328 },
    { lat: 48.8569864, lng: 21.9701805 },
    { lat: 48.8568334, lng: 21.9703625 },
    { lat: 48.8526377, lng: 21.9684547 },
    { lat: 48.8518666, lng: 21.9681551 },
    { lat: 48.8514321, lng: 21.9685523 },
    { lat: 48.8513703, lng: 21.9699281 },
    { lat: 48.8505758, lng: 21.9705539 },
    { lat: 48.8499476, lng: 21.9713066 },
    { lat: 48.848967, lng: 21.97221 },
    { lat: 48.848942, lng: 21.972518 },
    { lat: 48.848943, lng: 21.972942 },
    { lat: 48.8489, lng: 21.973169 },
    { lat: 48.84892, lng: 21.973651 },
    { lat: 48.848808, lng: 21.974242 },
    { lat: 48.848475, lng: 21.97515 },
    { lat: 48.84832, lng: 21.976271 },
    { lat: 48.848155, lng: 21.976739 },
    { lat: 48.848144, lng: 21.977134 },
    { lat: 48.848076, lng: 21.978001 },
    { lat: 48.847824, lng: 21.97956 },
    { lat: 48.847878, lng: 21.98063 },
    { lat: 48.847853, lng: 21.981126 },
    { lat: 48.847768, lng: 21.981799 },
    { lat: 48.847789, lng: 21.982643 },
    { lat: 48.847743, lng: 21.98417 },
    { lat: 48.84773, lng: 21.984363 },
    { lat: 48.847782, lng: 21.986572 },
    { lat: 48.847959, lng: 21.9874 },
    { lat: 48.847883, lng: 21.988182 },
    { lat: 48.847837, lng: 21.988522 },
    { lat: 48.847565, lng: 21.989929 },
    { lat: 48.847489, lng: 21.991091 },
    { lat: 48.847481, lng: 21.991203 },
    { lat: 48.84468, lng: 21.991146 },
    { lat: 48.841847, lng: 21.991083 },
    { lat: 48.83971, lng: 21.991052 },
    { lat: 48.839632, lng: 21.992781 },
    { lat: 48.839625, lng: 21.992918 },
    { lat: 48.839536, lng: 21.994883 },
    { lat: 48.839503, lng: 21.995632 },
    { lat: 48.839435, lng: 21.99714 },
    { lat: 48.839412, lng: 21.997976 },
    { lat: 48.839408, lng: 21.998111 },
    { lat: 48.839399, lng: 21.99844 },
    { lat: 48.839369, lng: 21.998937 },
    { lat: 48.839372, lng: 21.999009 },
    { lat: 48.839297, lng: 22.000638 },
    { lat: 48.839267, lng: 22.00172 },
    { lat: 48.839265, lng: 22.001786 },
    { lat: 48.839253, lng: 22.002217 },
    { lat: 48.839214, lng: 22.003617 },
    { lat: 48.83908, lng: 22.006225 },
    { lat: 48.839071, lng: 22.007489 },
    { lat: 48.838953, lng: 22.009263 },
    { lat: 48.838947, lng: 22.010014 },
    { lat: 48.838942, lng: 22.010432 },
    { lat: 48.838908, lng: 22.011579 },
    { lat: 48.838867, lng: 22.013207 },
    { lat: 48.838779, lng: 22.014439 },
    { lat: 48.838759, lng: 22.014891 },
    { lat: 48.838777, lng: 22.015956 },
    { lat: 48.838672, lng: 22.017414 },
    { lat: 48.838644, lng: 22.018672 },
    { lat: 48.838703, lng: 22.019625 },
    { lat: 48.838691, lng: 22.019916 },
    { lat: 48.838692, lng: 22.020634 },
    { lat: 48.838693, lng: 22.021115 },
    { lat: 48.838674, lng: 22.023004 },
    { lat: 48.838669, lng: 22.023104 },
    { lat: 48.838675, lng: 22.023412 },
    { lat: 48.838685, lng: 22.023761 },
    { lat: 48.838712, lng: 22.024619 },
    { lat: 48.838654, lng: 22.025521 },
    { lat: 48.838681, lng: 22.026139 },
    { lat: 48.838685, lng: 22.026205 },
    { lat: 48.838686, lng: 22.026216 },
    { lat: 48.838692, lng: 22.026249 },
    { lat: 48.838687, lng: 22.026665 },
    { lat: 48.838676, lng: 22.027752 },
    { lat: 48.838679, lng: 22.028704 },
    { lat: 48.838715, lng: 22.029866 },
    { lat: 48.838797, lng: 22.030481 },
    { lat: 48.838821, lng: 22.030594 },
    { lat: 48.838836, lng: 22.030736 },
    { lat: 48.838845, lng: 22.030837 },
    { lat: 48.838851, lng: 22.030882 },
    { lat: 48.838982, lng: 22.03143 },
    { lat: 48.839233, lng: 22.032468 },
    { lat: 48.839548, lng: 22.034377 },
    { lat: 48.839764, lng: 22.035855 },
    { lat: 48.839813, lng: 22.036105 },
    { lat: 48.840068, lng: 22.037412 },
    { lat: 48.840096, lng: 22.037559 },
    { lat: 48.840102, lng: 22.037592 },
    { lat: 48.840133, lng: 22.038077 },
    { lat: 48.840511, lng: 22.039897 },
    { lat: 48.840841, lng: 22.041659 },
    { lat: 48.840972, lng: 22.042387 },
    { lat: 48.841182, lng: 22.043392 },
    { lat: 48.841419, lng: 22.044515 },
    { lat: 48.84179, lng: 22.046373 },
    { lat: 48.84207, lng: 22.047793 },
    { lat: 48.842083, lng: 22.047836 },
    { lat: 48.842406, lng: 22.04942 },
    { lat: 48.842426, lng: 22.04952 },
    { lat: 48.842512, lng: 22.049945 },
    { lat: 48.842878, lng: 22.051436 },
    { lat: 48.843039, lng: 22.052133 },
    { lat: 48.843158, lng: 22.052647 },
    { lat: 48.843193, lng: 22.052801 },
    { lat: 48.843318, lng: 22.053338 },
    { lat: 48.843344, lng: 22.05346 },
    { lat: 48.843473, lng: 22.054063 },
    { lat: 48.843487, lng: 22.05413 },
    { lat: 48.843651, lng: 22.054887 },
    { lat: 48.843758, lng: 22.055478 },
    { lat: 48.84379, lng: 22.055653 },
    { lat: 48.843947, lng: 22.056506 },
    { lat: 48.844092, lng: 22.05745 },
    { lat: 48.844103, lng: 22.057485 },
    { lat: 48.844415, lng: 22.059025 },
    { lat: 48.844971, lng: 22.061746 },
    { lat: 48.844986, lng: 22.061824 },
    { lat: 48.845079, lng: 22.062278 },
    { lat: 48.845384, lng: 22.06371 },
    { lat: 48.845582, lng: 22.064635 },
    { lat: 48.845676, lng: 22.065093 },
    { lat: 48.845696, lng: 22.06519 },
    { lat: 48.845932, lng: 22.066336 },
    { lat: 48.845943, lng: 22.066388 },
    { lat: 48.846004, lng: 22.066691 },
    { lat: 48.846019, lng: 22.066763 },
    { lat: 48.846613, lng: 22.069665 },
    { lat: 48.846902, lng: 22.071181 },
    { lat: 48.846993, lng: 22.071657 },
    { lat: 48.846333, lng: 22.073713 },
    { lat: 48.846061, lng: 22.074449 },
    { lat: 48.845639, lng: 22.075586 },
    { lat: 48.845567, lng: 22.075871 },
    { lat: 48.845381, lng: 22.076606 },
    { lat: 48.845332, lng: 22.079134 },
    { lat: 48.84526, lng: 22.081162 },
    { lat: 48.845176, lng: 22.084612 },
    { lat: 48.845171, lng: 22.085078 },
    { lat: 48.845151, lng: 22.08698 },
    { lat: 48.845136, lng: 22.087222 },
    { lat: 48.845132, lng: 22.087282 },
    { lat: 48.845127, lng: 22.087383 },
    { lat: 48.84509, lng: 22.088009 },
    { lat: 48.845086, lng: 22.088074 },
    { lat: 48.845045, lng: 22.088761 },
    { lat: 48.845042, lng: 22.08885 },
    { lat: 48.845038, lng: 22.088956 },
    { lat: 48.844953, lng: 22.0916 },
    { lat: 48.844885, lng: 22.092732 },
    { lat: 48.84483, lng: 22.094147 },
    { lat: 48.844758, lng: 22.09617 },
    { lat: 48.844701, lng: 22.098006 },
    { lat: 48.844674, lng: 22.098845 },
    { lat: 48.844644, lng: 22.099764 },
    { lat: 48.844668, lng: 22.10225 },
    { lat: 48.844572, lng: 22.105043 },
    { lat: 48.84456, lng: 22.106986 },
    { lat: 48.844575, lng: 22.108198 },
    { lat: 48.844575, lng: 22.108284 },
    { lat: 48.844577, lng: 22.108351 },
    { lat: 48.844569, lng: 22.10864 },
    { lat: 48.844568, lng: 22.108706 },
    { lat: 48.844567, lng: 22.108786 },
    { lat: 48.844553, lng: 22.109377 },
    { lat: 48.844531, lng: 22.111246 },
    { lat: 48.844528, lng: 22.111607 },
    { lat: 48.844527, lng: 22.111714 },
    { lat: 48.844524, lng: 22.11208 },
    { lat: 48.844523, lng: 22.112145 },
    { lat: 48.844514, lng: 22.113058 },
    { lat: 48.844492, lng: 22.115708 },
    { lat: 48.844451, lng: 22.119282 },
    { lat: 48.844448, lng: 22.119514 },
    { lat: 48.844465, lng: 22.12298 },
    { lat: 48.84449, lng: 22.12584 },
    { lat: 48.844556, lng: 22.129209 },
    { lat: 48.844629, lng: 22.131222 },
    { lat: 48.844698, lng: 22.131594 },
    { lat: 48.845676, lng: 22.131423 },
    { lat: 48.845881, lng: 22.131219 },
    { lat: 48.846097, lng: 22.131276 },
    { lat: 48.846287, lng: 22.13108 },
    { lat: 48.846465, lng: 22.13117 },
    { lat: 48.84686, lng: 22.130911 },
    { lat: 48.847252, lng: 22.131049 },
    { lat: 48.847998, lng: 22.130979 },
    { lat: 48.848516, lng: 22.130813 },
    { lat: 48.848689, lng: 22.131216 },
    { lat: 48.849206, lng: 22.131278 },
    { lat: 48.849437, lng: 22.131296 },
    { lat: 48.849618, lng: 22.131492 },
    { lat: 48.850402, lng: 22.131474 },
    { lat: 48.850628, lng: 22.131652 },
    { lat: 48.850678, lng: 22.131691 },
    { lat: 48.850894, lng: 22.131861 },
    { lat: 48.851079, lng: 22.13207 },
    { lat: 48.851204, lng: 22.132351 },
    { lat: 48.851386, lng: 22.132448 },
    { lat: 48.851606, lng: 22.132547 },
    { lat: 48.85208, lng: 22.132682 },
    { lat: 48.852314, lng: 22.13286 },
    { lat: 48.852695, lng: 22.13259 },
    { lat: 48.853023, lng: 22.132189 },
    { lat: 48.853205, lng: 22.132116 },
    { lat: 48.853302, lng: 22.132136 },
    { lat: 48.853485, lng: 22.132173 },
    { lat: 48.853516, lng: 22.13218 },
    { lat: 48.853651, lng: 22.131906 },
    { lat: 48.853946, lng: 22.131853 },
    { lat: 48.854133, lng: 22.13175 },
    { lat: 48.854245, lng: 22.131799 },
    { lat: 48.854273, lng: 22.131811 },
    { lat: 48.854537, lng: 22.131651 },
    { lat: 48.854911, lng: 22.131131 },
    { lat: 48.855197, lng: 22.131188 },
    { lat: 48.855353, lng: 22.131037 },
    { lat: 48.855399, lng: 22.130914 },
    { lat: 48.855455, lng: 22.130868 },
    { lat: 48.855533, lng: 22.130806 },
    { lat: 48.855638, lng: 22.130723 },
    { lat: 48.855889, lng: 22.13078 },
    { lat: 48.856039, lng: 22.130934 },
    { lat: 48.856049, lng: 22.130936 },
    { lat: 48.856096, lng: 22.130944 },
    { lat: 48.856232, lng: 22.13097 },
    { lat: 48.856269, lng: 22.130977 },
    { lat: 48.856758, lng: 22.13071 },
    { lat: 48.85696, lng: 22.130522 },
    { lat: 48.857192, lng: 22.130403 },
    { lat: 48.857264, lng: 22.130227 },
    { lat: 48.857522, lng: 22.130133 },
    { lat: 48.857701, lng: 22.130249 },
    { lat: 48.858086, lng: 22.13021 },
    { lat: 48.858295, lng: 22.130116 },
    { lat: 48.858543, lng: 22.130165 },
    { lat: 48.858891, lng: 22.130127 },
    { lat: 48.858979, lng: 22.130116 },
    { lat: 48.859277, lng: 22.130214 },
    { lat: 48.859556, lng: 22.130116 },
    { lat: 48.859815, lng: 22.130209 },
    { lat: 48.860072, lng: 22.130302 },
    { lat: 48.860323, lng: 22.130364 },
    { lat: 48.860625, lng: 22.130439 },
    { lat: 48.86089, lng: 22.130395 },
    { lat: 48.861084, lng: 22.130504 },
    { lat: 48.861561, lng: 22.130997 },
    { lat: 48.861818, lng: 22.131112 },
    { lat: 48.862034, lng: 22.131316 },
    { lat: 48.862489, lng: 22.131327 },
    { lat: 48.862632, lng: 22.131456 },
    { lat: 48.863119, lng: 22.131346 },
    { lat: 48.863416, lng: 22.131528 },
    { lat: 48.86358, lng: 22.131423 },
    { lat: 48.864312, lng: 22.131134 },
    { lat: 48.864489, lng: 22.131138 },
    { lat: 48.864656, lng: 22.130978 },
    { lat: 48.865004, lng: 22.130941 },
    { lat: 48.865323, lng: 22.130995 },
    { lat: 48.865565, lng: 22.131101 },
    { lat: 48.865595, lng: 22.131113 },
    { lat: 48.865683, lng: 22.131152 },
    { lat: 48.866186, lng: 22.131107 },
    { lat: 48.866214, lng: 22.131098 },
    { lat: 48.866253, lng: 22.131084 },
    { lat: 48.866666, lng: 22.130942 },
    { lat: 48.866911, lng: 22.130934 },
    { lat: 48.867248, lng: 22.130653 },
    { lat: 48.86805, lng: 22.130678 },
    { lat: 48.868536, lng: 22.130555 },
    { lat: 48.868966, lng: 22.130598 },
    { lat: 48.869585, lng: 22.130782 },
    { lat: 48.869711, lng: 22.130773 },
    { lat: 48.869759, lng: 22.13077 },
    { lat: 48.869777, lng: 22.130769 },
    { lat: 48.870168, lng: 22.130744 },
    { lat: 48.870315, lng: 22.130665 },
    { lat: 48.870356, lng: 22.130643 },
    { lat: 48.870618, lng: 22.130503 },
    { lat: 48.87065, lng: 22.130486 },
    { lat: 48.871505, lng: 22.13003 },
    { lat: 48.871904, lng: 22.129999 },
    { lat: 48.872425, lng: 22.129783 },
    { lat: 48.872772, lng: 22.12973 },
    { lat: 48.873094, lng: 22.128901 },
    { lat: 48.8735, lng: 22.128215 },
    { lat: 48.874563, lng: 22.127521 },
    { lat: 48.874573, lng: 22.127514 },
    { lat: 48.874597, lng: 22.127497 },
    { lat: 48.875735, lng: 22.126641 },
    { lat: 48.875769, lng: 22.126242 },
    { lat: 48.875749, lng: 22.125824 },
    { lat: 48.875775, lng: 22.125251 },
    { lat: 48.875622, lng: 22.124389 },
    { lat: 48.875775, lng: 22.123832 },
    { lat: 48.87594, lng: 22.123743 },
    { lat: 48.876076, lng: 22.123913 },
    { lat: 48.87664, lng: 22.124289 },
    { lat: 48.876788, lng: 22.124265 },
    { lat: 48.877378, lng: 22.124438 },
    { lat: 48.877876, lng: 22.124493 },
    { lat: 48.878222, lng: 22.124443 },
    { lat: 48.878625, lng: 22.124425 },
    { lat: 48.87895, lng: 22.124544 },
    { lat: 48.879209, lng: 22.124743 },
    { lat: 48.879688, lng: 22.124824 },
    { lat: 48.880045, lng: 22.124777 },
    { lat: 48.880607, lng: 22.12491 },
    { lat: 48.880956, lng: 22.125117 },
    { lat: 48.881149, lng: 22.125145 },
    { lat: 48.881651, lng: 22.125054 },
    { lat: 48.882299, lng: 22.125071 },
    { lat: 48.883788, lng: 22.125463 },
    { lat: 48.884917, lng: 22.125539 },
    { lat: 48.885411, lng: 22.125456 },
    { lat: 48.886389, lng: 22.1259 },
    { lat: 48.886694, lng: 22.125769 },
    { lat: 48.886975, lng: 22.125793 },
    { lat: 48.887195, lng: 22.126082 },
    { lat: 48.887358, lng: 22.126113 },
    { lat: 48.88749, lng: 22.126351 },
    { lat: 48.887713, lng: 22.126396 },
    { lat: 48.887735, lng: 22.126371 },
    { lat: 48.887979, lng: 22.126111 },
    { lat: 48.888417, lng: 22.126867 },
    { lat: 48.888445, lng: 22.126668 },
    { lat: 48.888883, lng: 22.127171 },
    { lat: 48.889081, lng: 22.127397 },
    { lat: 48.889148, lng: 22.127473 },
    { lat: 48.889195, lng: 22.127528 },
    { lat: 48.889246, lng: 22.128283 },
    { lat: 48.889261, lng: 22.129127 },
    { lat: 48.889281, lng: 22.129765 },
    { lat: 48.888915, lng: 22.130312 },
    { lat: 48.888644, lng: 22.13091 },
    { lat: 48.888562, lng: 22.13167 },
    { lat: 48.888623, lng: 22.13237 },
    { lat: 48.888734, lng: 22.132603 },
    { lat: 48.8890365, lng: 22.1331383 },
    { lat: 48.8890386, lng: 22.1338052 },
    { lat: 48.8893037, lng: 22.1350513 },
    { lat: 48.8895939, lng: 22.1357853 },
    { lat: 48.889636, lng: 22.1366255 },
    { lat: 48.8897186, lng: 22.137179 },
    { lat: 48.8899805, lng: 22.1380429 },
    { lat: 48.8902943, lng: 22.1385738 },
    { lat: 48.8902028, lng: 22.1392431 },
    { lat: 48.8903403, lng: 22.1407138 },
    { lat: 48.8903171, lng: 22.141412 },
    { lat: 48.8901117, lng: 22.1423526 },
    { lat: 48.8904583, lng: 22.1441996 },
    { lat: 48.8903002, lng: 22.1457836 },
    { lat: 48.8906401, lng: 22.146618 },
    { lat: 48.8911555, lng: 22.1472057 },
    { lat: 48.8914527, lng: 22.1477582 },
    { lat: 48.8916612, lng: 22.1494782 },
    { lat: 48.8917923, lng: 22.1501826 },
    { lat: 48.8925224, lng: 22.151649 },
    { lat: 48.8928165, lng: 22.1524577 },
    { lat: 48.8931533, lng: 22.153109 },
    { lat: 48.8931332, lng: 22.1539947 },
    { lat: 48.8930305, lng: 22.1547143 },
    { lat: 48.8935089, lng: 22.1565838 },
    { lat: 48.8938991, lng: 22.1570196 },
    { lat: 48.894338, lng: 22.1573299 },
    { lat: 48.8949634, lng: 22.157423 },
    { lat: 48.895722, lng: 22.1576863 },
    { lat: 48.8963051, lng: 22.1577569 },
    { lat: 48.8976469, lng: 22.1584948 },
    { lat: 48.8982936, lng: 22.1590276 },
    { lat: 48.8999623, lng: 22.1595974 },
    { lat: 48.9011211, lng: 22.1598596 },
    { lat: 48.9018965, lng: 22.1597385 },
    { lat: 48.9023662, lng: 22.1597648 },
    { lat: 48.9028072, lng: 22.1600653 },
    { lat: 48.9038429, lng: 22.1603942 },
    { lat: 48.9048174, lng: 22.1602167 },
    { lat: 48.9061614, lng: 22.1621296 },
    { lat: 48.9068102, lng: 22.1626073 },
    { lat: 48.9069503, lng: 22.1636561 },
    { lat: 48.9070459, lng: 22.1638581 },
    { lat: 48.9072275, lng: 22.1648092 },
    { lat: 48.9079012, lng: 22.1665267 },
    { lat: 48.9085581, lng: 22.1676083 },
    { lat: 48.9092305, lng: 22.1682851 },
    { lat: 48.9093432, lng: 22.1688348 },
    { lat: 48.9103937, lng: 22.1699136 },
    { lat: 48.9108975, lng: 22.1721218 },
    { lat: 48.9112596, lng: 22.1725572 },
    { lat: 48.9120169, lng: 22.1732604 },
    { lat: 48.9124848, lng: 22.1726243 },
    { lat: 48.9136999, lng: 22.1729383 },
    { lat: 48.9139023, lng: 22.1736642 },
    { lat: 48.9143011, lng: 22.1741407 },
    { lat: 48.9151728, lng: 22.1754665 },
    { lat: 48.9166572, lng: 22.1785104 },
    { lat: 48.9172629, lng: 22.1794677 },
    { lat: 48.9176472, lng: 22.1798058 },
    { lat: 48.9177872, lng: 22.1801142 },
    { lat: 48.9178651, lng: 22.180481 },
    { lat: 48.9175092, lng: 22.1819398 },
    { lat: 48.9187067, lng: 22.1834966 },
    { lat: 48.9198332, lng: 22.1842197 },
    { lat: 48.9202265, lng: 22.1839576 },
    { lat: 48.9209007, lng: 22.1840778 },
    { lat: 48.9212256, lng: 22.1838741 },
    { lat: 48.9224404, lng: 22.1836308 },
    { lat: 48.9238083, lng: 22.1847247 },
    { lat: 48.9246712, lng: 22.1858651 },
    { lat: 48.9256863, lng: 22.1882666 },
    { lat: 48.9261338, lng: 22.1886563 },
    { lat: 48.9269307, lng: 22.1885867 },
    { lat: 48.9291452, lng: 22.1886284 },
    { lat: 48.929273, lng: 22.1918562 },
    { lat: 48.929633, lng: 22.190429 },
    { lat: 48.929669, lng: 22.190297 },
    { lat: 48.929829, lng: 22.189948 },
    { lat: 48.929955, lng: 22.189673 },
    { lat: 48.930144, lng: 22.188789 },
    { lat: 48.930313, lng: 22.187682 },
    { lat: 48.930507, lng: 22.186905 },
    { lat: 48.930768, lng: 22.186055 },
    { lat: 48.930809, lng: 22.185455 },
    { lat: 48.93092, lng: 22.184834 },
    { lat: 48.930958, lng: 22.184622 },
    { lat: 48.93124, lng: 22.184151 },
    { lat: 48.93125, lng: 22.184137 },
    { lat: 48.93168, lng: 22.183529 },
    { lat: 48.932001, lng: 22.183283 },
    { lat: 48.932167, lng: 22.183155 },
    { lat: 48.932978, lng: 22.182582 },
    { lat: 48.933405, lng: 22.182329 },
    { lat: 48.934117, lng: 22.181862 },
    { lat: 48.934714, lng: 22.181416 },
    { lat: 48.935602, lng: 22.180474 },
    { lat: 48.935689, lng: 22.180381 },
    { lat: 48.936059, lng: 22.179363 },
    { lat: 48.936294, lng: 22.177743 },
    { lat: 48.936632, lng: 22.176542 },
    { lat: 48.93682, lng: 22.17569 },
    { lat: 48.937341, lng: 22.174904 },
    { lat: 48.937516, lng: 22.174552 },
    { lat: 48.937775, lng: 22.174029 },
    { lat: 48.937795, lng: 22.17398 },
    { lat: 48.937808, lng: 22.173947 },
    { lat: 48.937821, lng: 22.173917 },
    { lat: 48.938038, lng: 22.173383 },
    { lat: 48.938032, lng: 22.173235 },
    { lat: 48.937996, lng: 22.172209 },
    { lat: 48.937961, lng: 22.171569 },
    { lat: 48.937943, lng: 22.171009 },
    { lat: 48.938013, lng: 22.170409 },
    { lat: 48.938042, lng: 22.168792 },
    { lat: 48.937958, lng: 22.16817 },
    { lat: 48.93816, lng: 22.166907 },
    { lat: 48.938167, lng: 22.166862 },
    { lat: 48.938224, lng: 22.166503 },
    { lat: 48.93831, lng: 22.166297 },
    { lat: 48.938303, lng: 22.166146 },
    { lat: 48.938298, lng: 22.166036 },
    { lat: 48.938349, lng: 22.165869 },
    { lat: 48.938361, lng: 22.165712 },
    { lat: 48.938364, lng: 22.16567 },
    { lat: 48.938335, lng: 22.165573 },
    { lat: 48.938309, lng: 22.165488 },
    { lat: 48.938313, lng: 22.16546 },
    { lat: 48.938372, lng: 22.16507 },
    { lat: 48.938327, lng: 22.165017 },
    { lat: 48.938345, lng: 22.164742 },
    { lat: 48.93828, lng: 22.164416 },
    { lat: 48.938212, lng: 22.164336 },
    { lat: 48.938176, lng: 22.164232 },
    { lat: 48.938166, lng: 22.164205 },
    { lat: 48.93817, lng: 22.164185 },
    { lat: 48.938218, lng: 22.163956 },
    { lat: 48.938208, lng: 22.163867 },
    { lat: 48.937921, lng: 22.163906 },
    { lat: 48.937903, lng: 22.163909 },
    { lat: 48.937769, lng: 22.163927 },
    { lat: 48.937724, lng: 22.163977 },
    { lat: 48.937469, lng: 22.164261 },
    { lat: 48.937474, lng: 22.164304 },
    { lat: 48.937497, lng: 22.164503 },
    { lat: 48.937504, lng: 22.164567 },
    { lat: 48.937568, lng: 22.165099 },
    { lat: 48.937581, lng: 22.165202 },
    { lat: 48.937734, lng: 22.166462 },
    { lat: 48.937278, lng: 22.166425 },
    { lat: 48.937002, lng: 22.166154 },
    { lat: 48.936664, lng: 22.165835 },
    { lat: 48.936591, lng: 22.165765 },
    { lat: 48.936362, lng: 22.16554 },
    { lat: 48.936174, lng: 22.165355 },
    { lat: 48.936169, lng: 22.165351 },
    { lat: 48.93616, lng: 22.165342 },
    { lat: 48.936083, lng: 22.165265 },
    { lat: 48.936054, lng: 22.165238 },
    { lat: 48.936223, lng: 22.164744 },
    { lat: 48.93624, lng: 22.164689 },
    { lat: 48.936323, lng: 22.164442 },
    { lat: 48.936377, lng: 22.164284 },
    { lat: 48.936396, lng: 22.164229 },
    { lat: 48.936662, lng: 22.164187 },
    { lat: 48.93702, lng: 22.164131 },
    { lat: 48.937245, lng: 22.163748 },
    { lat: 48.937466, lng: 22.163725 },
    { lat: 48.937519, lng: 22.163719 },
    { lat: 48.937673, lng: 22.163629 },
    { lat: 48.937852, lng: 22.16368 },
    { lat: 48.938167, lng: 22.163643 },
    { lat: 48.938082, lng: 22.163288 },
    { lat: 48.938, lng: 22.162941 },
    { lat: 48.937907, lng: 22.162629 },
    { lat: 48.937887, lng: 22.162568 },
    { lat: 48.937833, lng: 22.162391 },
    { lat: 48.937784, lng: 22.162391 },
    { lat: 48.937813, lng: 22.16216 },
    { lat: 48.937909, lng: 22.162023 },
    { lat: 48.938032, lng: 22.16185 },
    { lat: 48.938043, lng: 22.161721 },
    { lat: 48.938049, lng: 22.161647 },
    { lat: 48.938025, lng: 22.16147 },
    { lat: 48.938054, lng: 22.161382 },
    { lat: 48.938082, lng: 22.1613 },
    { lat: 48.938184, lng: 22.161 },
    { lat: 48.938182, lng: 22.160901 },
    { lat: 48.938181, lng: 22.160882 },
    { lat: 48.938181, lng: 22.160847 },
    { lat: 48.938176, lng: 22.16066 },
    { lat: 48.938173, lng: 22.160531 },
    { lat: 48.938166, lng: 22.160201 },
    { lat: 48.938159, lng: 22.159959 },
    { lat: 48.938157, lng: 22.159879 },
    { lat: 48.938153, lng: 22.159707 },
    { lat: 48.938153, lng: 22.159684 },
    { lat: 48.938151, lng: 22.159606 },
    { lat: 48.938149, lng: 22.159542 },
    { lat: 48.938149, lng: 22.159534 },
    { lat: 48.938149, lng: 22.15952 },
    { lat: 48.938146, lng: 22.159416 },
    { lat: 48.938145, lng: 22.159345 },
    { lat: 48.938141, lng: 22.159194 },
    { lat: 48.93814, lng: 22.159141 },
    { lat: 48.938168, lng: 22.159075 },
    { lat: 48.93819, lng: 22.158948 },
    { lat: 48.938263, lng: 22.158793 },
    { lat: 48.938326, lng: 22.158603 },
    { lat: 48.938416, lng: 22.158487 },
    { lat: 48.938473, lng: 22.158415 },
    { lat: 48.938507, lng: 22.15828 },
    { lat: 48.938648, lng: 22.158094 },
    { lat: 48.938764, lng: 22.157758 },
    { lat: 48.938716, lng: 22.157753 },
    { lat: 48.938602, lng: 22.157824 },
    { lat: 48.93822, lng: 22.157715 },
    { lat: 48.938034, lng: 22.157568 },
    { lat: 48.937945, lng: 22.157303 },
    { lat: 48.93782, lng: 22.157047 },
    { lat: 48.93761, lng: 22.156523 },
    { lat: 48.937447, lng: 22.156242 },
    { lat: 48.937353, lng: 22.156155 },
    { lat: 48.937291, lng: 22.15604 },
    { lat: 48.937265, lng: 22.155842 },
    { lat: 48.937084, lng: 22.155677 },
    { lat: 48.937005, lng: 22.155599 },
    { lat: 48.936924, lng: 22.155465 },
    { lat: 48.93687, lng: 22.155353 },
    { lat: 48.936858, lng: 22.155284 },
    { lat: 48.936845, lng: 22.155208 },
    { lat: 48.936819, lng: 22.155046 },
    { lat: 48.936785, lng: 22.154848 },
    { lat: 48.936778, lng: 22.154803 },
    { lat: 48.93682, lng: 22.154732 },
    { lat: 48.936832, lng: 22.154713 },
    { lat: 48.936945, lng: 22.154681 },
    { lat: 48.937205, lng: 22.154607 },
    { lat: 48.93746, lng: 22.154508 },
    { lat: 48.937493, lng: 22.154522 },
    { lat: 48.937642, lng: 22.154587 },
    { lat: 48.937809, lng: 22.15454 },
    { lat: 48.937849, lng: 22.154513 },
    { lat: 48.937856, lng: 22.154509 },
    { lat: 48.937922, lng: 22.154464 },
    { lat: 48.938027, lng: 22.154466 },
    { lat: 48.938095, lng: 22.154394 },
    { lat: 48.938099, lng: 22.154338 },
    { lat: 48.938101, lng: 22.154314 },
    { lat: 48.937996, lng: 22.154201 },
    { lat: 48.937907, lng: 22.154283 },
    { lat: 48.937754, lng: 22.154425 },
    { lat: 48.937702, lng: 22.15442 },
    { lat: 48.937634, lng: 22.154414 },
    { lat: 48.937576, lng: 22.154364 },
    { lat: 48.937532, lng: 22.154329 },
    { lat: 48.937377, lng: 22.154207 },
    { lat: 48.937271, lng: 22.154233 },
    { lat: 48.937244, lng: 22.154177 },
    { lat: 48.937099, lng: 22.153902 },
    { lat: 48.936839, lng: 22.153865 },
    { lat: 48.936789, lng: 22.153787 },
    { lat: 48.936597, lng: 22.153654 },
    { lat: 48.936531, lng: 22.153671 },
    { lat: 48.936474, lng: 22.153433 },
    { lat: 48.936466, lng: 22.153402 },
    { lat: 48.936359, lng: 22.153413 },
    { lat: 48.936318, lng: 22.153401 },
    { lat: 48.936246, lng: 22.153303 },
    { lat: 48.936097, lng: 22.153101 },
    { lat: 48.935905, lng: 22.15307 },
    { lat: 48.935856, lng: 22.1531 },
    { lat: 48.935704, lng: 22.153189 },
    { lat: 48.935552, lng: 22.153227 },
    { lat: 48.935379, lng: 22.15315 },
    { lat: 48.93524, lng: 22.152793 },
    { lat: 48.935207, lng: 22.152711 },
    { lat: 48.935105, lng: 22.152728 },
    { lat: 48.934896, lng: 22.152662 },
    { lat: 48.934829, lng: 22.152622 },
    { lat: 48.934613, lng: 22.152482 },
    { lat: 48.934355, lng: 22.152313 },
    { lat: 48.934301, lng: 22.152327 },
    { lat: 48.934188, lng: 22.152355 },
    { lat: 48.934198, lng: 22.152295 },
    { lat: 48.934193, lng: 22.15221 },
    { lat: 48.934215, lng: 22.152162 },
    { lat: 48.934223, lng: 22.152148 },
    { lat: 48.934242, lng: 22.152108 },
    { lat: 48.93426, lng: 22.15207 },
    { lat: 48.93429, lng: 22.152009 },
    { lat: 48.934291, lng: 22.152008 },
    { lat: 48.934503, lng: 22.151693 },
    { lat: 48.934635, lng: 22.151497 },
    { lat: 48.935094, lng: 22.151069 },
    { lat: 48.935275, lng: 22.1509 },
    { lat: 48.935889, lng: 22.150384 },
    { lat: 48.935985, lng: 22.150369 },
    { lat: 48.937175, lng: 22.150175 },
    { lat: 48.937261, lng: 22.150161 },
    { lat: 48.937842, lng: 22.150308 },
    { lat: 48.938395, lng: 22.150318 },
    { lat: 48.938893, lng: 22.150569 },
    { lat: 48.939529, lng: 22.151055 },
    { lat: 48.939885, lng: 22.151326 },
    { lat: 48.940071, lng: 22.15144 },
    { lat: 48.940734, lng: 22.151847 },
    { lat: 48.940922, lng: 22.151921 },
    { lat: 48.940953, lng: 22.151933 },
    { lat: 48.940962, lng: 22.151936 },
    { lat: 48.941387, lng: 22.152104 },
    { lat: 48.941436, lng: 22.152168 },
    { lat: 48.942492, lng: 22.153591 },
    { lat: 48.942569, lng: 22.153694 },
    { lat: 48.94265, lng: 22.153835 },
    { lat: 48.943108, lng: 22.154633 },
    { lat: 48.94346, lng: 22.155179 },
    { lat: 48.94349, lng: 22.155264 },
    { lat: 48.943501, lng: 22.155298 },
    { lat: 48.943514, lng: 22.155335 },
    { lat: 48.943537, lng: 22.155343 },
    { lat: 48.943596, lng: 22.155364 },
    { lat: 48.943817, lng: 22.155445 },
    { lat: 48.943986, lng: 22.155644 },
    { lat: 48.944096, lng: 22.155889 },
    { lat: 48.944156, lng: 22.156021 },
    { lat: 48.944405, lng: 22.156365 },
    { lat: 48.944564, lng: 22.156584 },
    { lat: 48.944827, lng: 22.156697 },
    { lat: 48.945128, lng: 22.156827 },
    { lat: 48.945332, lng: 22.156866 },
    { lat: 48.94563, lng: 22.156851 },
    { lat: 48.945635, lng: 22.156851 },
    { lat: 48.945697, lng: 22.156848 },
    { lat: 48.945749, lng: 22.156845 },
    { lat: 48.945802, lng: 22.156843 },
    { lat: 48.945905, lng: 22.156837 },
    { lat: 48.945966, lng: 22.156834 },
    { lat: 48.946006, lng: 22.156847 },
    { lat: 48.946153, lng: 22.156892 },
    { lat: 48.946176, lng: 22.156899 },
    { lat: 48.946192, lng: 22.156853 },
    { lat: 48.946206, lng: 22.156818 },
    { lat: 48.946231, lng: 22.156754 },
    { lat: 48.946249, lng: 22.156706 },
    { lat: 48.946254, lng: 22.156692 },
    { lat: 48.946255, lng: 22.156689 },
    { lat: 48.94626, lng: 22.156673 },
    { lat: 48.946397, lng: 22.15664 },
    { lat: 48.946408, lng: 22.156635 },
    { lat: 48.946495, lng: 22.156603 },
    { lat: 48.946508, lng: 22.156596 },
    { lat: 48.946608, lng: 22.156538 },
    { lat: 48.946608, lng: 22.156527 },
    { lat: 48.946706, lng: 22.156483 },
    { lat: 48.946729, lng: 22.156471 },
    { lat: 48.946758, lng: 22.156455 },
    { lat: 48.946794, lng: 22.156437 },
    { lat: 48.946881, lng: 22.15639 },
    { lat: 48.946883, lng: 22.156392 },
    { lat: 48.946887, lng: 22.156402 },
    { lat: 48.947078, lng: 22.156328 },
    { lat: 48.947075, lng: 22.156302 },
    { lat: 48.947123, lng: 22.156269 },
    { lat: 48.947196, lng: 22.156222 },
    { lat: 48.947204, lng: 22.156207 },
    { lat: 48.947244, lng: 22.156145 },
    { lat: 48.947276, lng: 22.156092 },
    { lat: 48.947337, lng: 22.156 },
    { lat: 48.947434, lng: 22.155798 },
    { lat: 48.947451, lng: 22.155763 },
    { lat: 48.947464, lng: 22.155736 },
    { lat: 48.947475, lng: 22.155713 },
    { lat: 48.947516, lng: 22.155603 },
    { lat: 48.947556, lng: 22.155494 },
    { lat: 48.947561, lng: 22.155479 },
    { lat: 48.947606, lng: 22.15536 },
    { lat: 48.947604, lng: 22.155335 },
    { lat: 48.947682, lng: 22.155115 },
    { lat: 48.947686, lng: 22.155106 },
    { lat: 48.947692, lng: 22.15509 },
    { lat: 48.947712, lng: 22.155075 },
    { lat: 48.947734, lng: 22.155057 },
    { lat: 48.947748, lng: 22.155046 },
    { lat: 48.94792, lng: 22.154911 },
    { lat: 48.948038, lng: 22.154761 },
    { lat: 48.948061, lng: 22.154716 },
    { lat: 48.948151, lng: 22.1547 },
    { lat: 48.948197, lng: 22.154599 },
    { lat: 48.948259, lng: 22.154464 },
    { lat: 48.948294, lng: 22.154386 },
    { lat: 48.948321, lng: 22.154327 },
    { lat: 48.948764, lng: 22.153484 },
    { lat: 48.948803, lng: 22.153446 },
    { lat: 48.948813, lng: 22.153437 },
    { lat: 48.948837, lng: 22.153415 },
    { lat: 48.94885, lng: 22.153404 },
    { lat: 48.949079, lng: 22.152965 },
    { lat: 48.948994, lng: 22.152868 },
    { lat: 48.94908, lng: 22.152675 },
    { lat: 48.949157, lng: 22.152504 },
    { lat: 48.949254, lng: 22.152291 },
    { lat: 48.949305, lng: 22.151796 },
    { lat: 48.949324, lng: 22.151619 },
    { lat: 48.949361, lng: 22.151635 },
    { lat: 48.949668, lng: 22.151772 },
    { lat: 48.949728, lng: 22.151799 },
    { lat: 48.949861, lng: 22.15186 },
    { lat: 48.949908, lng: 22.151881 },
    { lat: 48.949922, lng: 22.151888 },
    { lat: 48.949982, lng: 22.151914 },
    { lat: 48.9500977, lng: 22.1517174 },
    { lat: 48.9501206, lng: 22.1516911 },
    { lat: 48.9501634, lng: 22.151642 },
    { lat: 48.9501823, lng: 22.1515844 },
    { lat: 48.9502289, lng: 22.1514073 },
    { lat: 48.9504189, lng: 22.1511145 },
    { lat: 48.950432, lng: 22.1510981 },
    { lat: 48.9504583, lng: 22.151063 },
    { lat: 48.9506485, lng: 22.1506758 },
    { lat: 48.9506858, lng: 22.1503417 },
    { lat: 48.9508976, lng: 22.1500194 },
    { lat: 48.9510732, lng: 22.1498265 },
    { lat: 48.9510875, lng: 22.1498114 },
    { lat: 48.9512793, lng: 22.1496996 },
    { lat: 48.9513389, lng: 22.1495955 },
    { lat: 48.9513888, lng: 22.1495213 },
    { lat: 48.9514187, lng: 22.1493948 },
    { lat: 48.9514477, lng: 22.1492924 },
    { lat: 48.9514534, lng: 22.1492971 },
    { lat: 48.9514857, lng: 22.1492352 },
    { lat: 48.9514874, lng: 22.1492076 },
    { lat: 48.9514882, lng: 22.1491914 },
    { lat: 48.9514917, lng: 22.1490546 },
    { lat: 48.9514953, lng: 22.1489144 },
    { lat: 48.9514847, lng: 22.1488946 },
    { lat: 48.9514877, lng: 22.1488805 },
    { lat: 48.9514737, lng: 22.148858 },
    { lat: 48.9514918, lng: 22.1487629 },
    { lat: 48.951484, lng: 22.1486695 },
    { lat: 48.9514978, lng: 22.1485847 },
    { lat: 48.9514762, lng: 22.148578 },
    { lat: 48.9514808, lng: 22.1484815 },
    { lat: 48.9516083, lng: 22.1483598 },
    { lat: 48.9517104, lng: 22.1481067 },
    { lat: 48.9518732, lng: 22.147888 },
    { lat: 48.9522151, lng: 22.1471577 },
    { lat: 48.9522709, lng: 22.1471869 },
    { lat: 48.9523404, lng: 22.1468912 },
    { lat: 48.9523379, lng: 22.1468326 },
    { lat: 48.9523319, lng: 22.1467145 },
    { lat: 48.9523276, lng: 22.1466202 },
    { lat: 48.9523232, lng: 22.1465314 },
    { lat: 48.9523151, lng: 22.1463751 },
    { lat: 48.9523431, lng: 22.1462698 },
    { lat: 48.952373, lng: 22.1462099 },
    { lat: 48.9524474, lng: 22.1460631 },
    { lat: 48.9524945, lng: 22.1460098 },
    { lat: 48.9526, lng: 22.1459061 },
    { lat: 48.9527521, lng: 22.1454292 },
    { lat: 48.9528172, lng: 22.1453681 },
    { lat: 48.9528511, lng: 22.145337 },
    { lat: 48.952951, lng: 22.1453234 },
    { lat: 48.9528558, lng: 22.1451005 },
    { lat: 48.9530883, lng: 22.1447396 },
    { lat: 48.9532587, lng: 22.1444735 },
    { lat: 48.9536247, lng: 22.1437698 },
    { lat: 48.9537163, lng: 22.1435927 },
    { lat: 48.9538943, lng: 22.1437969 },
    { lat: 48.9542378, lng: 22.1438237 },
    { lat: 48.9545102, lng: 22.1438725 },
    { lat: 48.954414, lng: 22.1436024 },
    { lat: 48.9540361, lng: 22.1425421 },
    { lat: 48.9539712, lng: 22.1423137 },
    { lat: 48.9538083, lng: 22.1417827 },
    { lat: 48.9534466, lng: 22.1407341 },
    { lat: 48.9533819, lng: 22.1407615 },
    { lat: 48.9523395, lng: 22.1411997 },
    { lat: 48.9518873, lng: 22.141543 },
    { lat: 48.9511328, lng: 22.1421791 },
    { lat: 48.9507955, lng: 22.1421899 },
    { lat: 48.9504122, lng: 22.1417594 },
    { lat: 48.9500641, lng: 22.1410584 },
    { lat: 48.9496993, lng: 22.1403177 },
    { lat: 48.9494172, lng: 22.1397348 },
    { lat: 48.9488254, lng: 22.1395331 },
    { lat: 48.9485229, lng: 22.1389577 },
    { lat: 48.9480221, lng: 22.1380236 },
    { lat: 48.9475989, lng: 22.1373792 },
    { lat: 48.9473586, lng: 22.1370578 },
    { lat: 48.9468535, lng: 22.1363749 },
    { lat: 48.9463956, lng: 22.1357395 },
    { lat: 48.9460217, lng: 22.1352231 },
    { lat: 48.9457578, lng: 22.1348107 },
    { lat: 48.9456196, lng: 22.1348707 },
    { lat: 48.9450997, lng: 22.1350953 },
    { lat: 48.9442935, lng: 22.1354439 },
    { lat: 48.9438032, lng: 22.1356429 },
    { lat: 48.9435738, lng: 22.1356513 },
    { lat: 48.9420071, lng: 22.1355604 },
    { lat: 48.9408393, lng: 22.1347705 },
    { lat: 48.9407194, lng: 22.1347415 },
    { lat: 48.9406542, lng: 22.1347364 },
    { lat: 48.9405954, lng: 22.1347317 },
    { lat: 48.940573, lng: 22.1347297 },
    { lat: 48.9405041, lng: 22.1342289 },
    { lat: 48.9402827, lng: 22.1337051 },
    { lat: 48.9399648, lng: 22.1331926 },
    { lat: 48.9393362, lng: 22.1321747 },
    { lat: 48.9393041, lng: 22.1310369 },
    { lat: 48.9393112, lng: 22.1310061 },
    { lat: 48.9393428, lng: 22.1308673 },
    { lat: 48.9396852, lng: 22.1295352 },
    { lat: 48.9396852, lng: 22.1295349 },
    { lat: 48.9397111, lng: 22.129433 },
    { lat: 48.9400554, lng: 22.1276986 },
    { lat: 48.9403539, lng: 22.1248142 },
    { lat: 48.9403883, lng: 22.1240757 },
    { lat: 48.9404317, lng: 22.1226511 },
    { lat: 48.9410635, lng: 22.1207033 },
    { lat: 48.9413589, lng: 22.1197797 },
    { lat: 48.9413319, lng: 22.1197737 },
    { lat: 48.9410209, lng: 22.1196577 },
    { lat: 48.9409325, lng: 22.1196266 },
    { lat: 48.9407474, lng: 22.1195528 },
    { lat: 48.9402531, lng: 22.1193798 },
    { lat: 48.9399763, lng: 22.1192827 },
    { lat: 48.9395726, lng: 22.1191441 },
    { lat: 48.939158, lng: 22.11901 },
    { lat: 48.9388211, lng: 22.1188979 },
    { lat: 48.9383858, lng: 22.1187456 },
    { lat: 48.9379227, lng: 22.118568 },
    { lat: 48.9374543, lng: 22.1184 },
    { lat: 48.937199, lng: 22.1183111 },
    { lat: 48.9367842, lng: 22.118167 },
    { lat: 48.9363792, lng: 22.1180242 },
    { lat: 48.9356963, lng: 22.1177798 },
    { lat: 48.9349372, lng: 22.1174972 },
    { lat: 48.9345573, lng: 22.1173573 },
    { lat: 48.934502, lng: 22.116962 },
  ],
  Pakostov: [
    { lat: 49.0711131, lng: 21.8236388 },
    { lat: 49.0716183, lng: 21.8239506 },
    { lat: 49.0720071, lng: 21.8246305 },
    { lat: 49.0729337, lng: 21.8253135 },
    { lat: 49.0730294, lng: 21.8261926 },
    { lat: 49.0735127, lng: 21.8272645 },
    { lat: 49.0742641, lng: 21.8287097 },
    { lat: 49.074509, lng: 21.8300911 },
    { lat: 49.074283, lng: 21.830843 },
    { lat: 49.0738894, lng: 21.8314136 },
    { lat: 49.0736352, lng: 21.8330763 },
    { lat: 49.0739241, lng: 21.8343837 },
    { lat: 49.0744488, lng: 21.8349262 },
    { lat: 49.0751396, lng: 21.8350591 },
    { lat: 49.075523, lng: 21.8361478 },
    { lat: 49.0756404, lng: 21.8369097 },
    { lat: 49.0767692, lng: 21.8389885 },
    { lat: 49.0769296, lng: 21.8398021 },
    { lat: 49.0781743, lng: 21.8411393 },
    { lat: 49.07797, lng: 21.8434411 },
    { lat: 49.0781537, lng: 21.8448586 },
    { lat: 49.0785877, lng: 21.845468 },
    { lat: 49.0796148, lng: 21.8464759 },
    { lat: 49.0807901, lng: 21.8465117 },
    { lat: 49.0833148, lng: 21.8470653 },
    { lat: 49.084097, lng: 21.846074 },
    { lat: 49.0861834, lng: 21.8465455 },
    { lat: 49.0862979, lng: 21.8475849 },
    { lat: 49.0865017, lng: 21.8486049 },
    { lat: 49.086988, lng: 21.8487035 },
    { lat: 49.0875086, lng: 21.8485251 },
    { lat: 49.090268, lng: 21.8464675 },
    { lat: 49.0916226, lng: 21.8466947 },
    { lat: 49.0924258, lng: 21.8471323 },
    { lat: 49.0926974, lng: 21.8455255 },
    { lat: 49.0934138, lng: 21.8471914 },
    { lat: 49.0948953, lng: 21.847461 },
    { lat: 49.0968636, lng: 21.8472359 },
    { lat: 49.0970708, lng: 21.8465337 },
    { lat: 49.0976275, lng: 21.8462296 },
    { lat: 49.0981201, lng: 21.8457373 },
    { lat: 49.0985372, lng: 21.8454229 },
    { lat: 49.0989578, lng: 21.8452866 },
    { lat: 49.1014398, lng: 21.8456964 },
    { lat: 49.102, lng: 21.847592 },
    { lat: 49.1026728, lng: 21.8478626 },
    { lat: 49.1032308, lng: 21.8479942 },
    { lat: 49.1035693, lng: 21.8482592 },
    { lat: 49.1040827, lng: 21.8507809 },
    { lat: 49.1047283, lng: 21.851662 },
    { lat: 49.106294, lng: 21.8523609 },
    { lat: 49.1075371, lng: 21.8517936 },
    { lat: 49.1088418, lng: 21.8506424 },
    { lat: 49.1089539, lng: 21.851948 },
    { lat: 49.1092282, lng: 21.8522502 },
    { lat: 49.1111524, lng: 21.8497694 },
    { lat: 49.113494, lng: 21.8484348 },
    { lat: 49.1143419, lng: 21.8494775 },
    { lat: 49.1148657, lng: 21.849821 },
    { lat: 49.1163025, lng: 21.8537956 },
    { lat: 49.11681, lng: 21.8549042 },
    { lat: 49.1173269, lng: 21.8596481 },
    { lat: 49.1181811, lng: 21.8642224 },
    { lat: 49.11984, lng: 21.863688 },
    { lat: 49.120046, lng: 21.862868 },
    { lat: 49.120175, lng: 21.861744 },
    { lat: 49.120188, lng: 21.861604 },
    { lat: 49.120247, lng: 21.86118 },
    { lat: 49.120295, lng: 21.860458 },
    { lat: 49.120481, lng: 21.859314 },
    { lat: 49.120824, lng: 21.857836 },
    { lat: 49.121063, lng: 21.85737 },
    { lat: 49.12109, lng: 21.857077 },
    { lat: 49.120508, lng: 21.856103 },
    { lat: 49.120157, lng: 21.855752 },
    { lat: 49.119936, lng: 21.855053 },
    { lat: 49.119924, lng: 21.854584 },
    { lat: 49.119366, lng: 21.853315 },
    { lat: 49.119177, lng: 21.852932 },
    { lat: 49.118927, lng: 21.852607 },
    { lat: 49.118609, lng: 21.852395 },
    { lat: 49.118644, lng: 21.851836 },
    { lat: 49.118549, lng: 21.851781 },
    { lat: 49.118504, lng: 21.851721 },
    { lat: 49.118779, lng: 21.851134 },
    { lat: 49.119066, lng: 21.850824 },
    { lat: 49.119643, lng: 21.850624 },
    { lat: 49.120621, lng: 21.849014 },
    { lat: 49.12128, lng: 21.847756 },
    { lat: 49.121717, lng: 21.846063 },
    { lat: 49.121774, lng: 21.845911 },
    { lat: 49.121874, lng: 21.845548 },
    { lat: 49.1213419, lng: 21.8442892 },
    { lat: 49.1200623, lng: 21.8422497 },
    { lat: 49.119676, lng: 21.842049 },
    { lat: 49.1194191, lng: 21.84182 },
    { lat: 49.1193177, lng: 21.8416339 },
    { lat: 49.1193505, lng: 21.8414632 },
    { lat: 49.1191057, lng: 21.8413481 },
    { lat: 49.1189706, lng: 21.8410398 },
    { lat: 49.1186461, lng: 21.8410623 },
    { lat: 49.1178427, lng: 21.840793 },
    { lat: 49.1175987, lng: 21.8406147 },
    { lat: 49.1171722, lng: 21.8399977 },
    { lat: 49.1171365, lng: 21.8400949 },
    { lat: 49.1171093, lng: 21.8400427 },
    { lat: 49.1168931, lng: 21.8405224 },
    { lat: 49.1168638, lng: 21.8404713 },
    { lat: 49.1158796, lng: 21.8417939 },
    { lat: 49.1157331, lng: 21.841558 },
    { lat: 49.1159318, lng: 21.8409322 },
    { lat: 49.1158392, lng: 21.8408231 },
    { lat: 49.1156883, lng: 21.8407761 },
    { lat: 49.1156985, lng: 21.8404283 },
    { lat: 49.1156009, lng: 21.8400032 },
    { lat: 49.1155731, lng: 21.8396206 },
    { lat: 49.1153837, lng: 21.839118 },
    { lat: 49.1154058, lng: 21.838888 },
    { lat: 49.1155219, lng: 21.8386703 },
    { lat: 49.1151351, lng: 21.8382387 },
    { lat: 49.1155751, lng: 21.8371065 },
    { lat: 49.1156529, lng: 21.8372459 },
    { lat: 49.1159928, lng: 21.836729 },
    { lat: 49.1160467, lng: 21.8360217 },
    { lat: 49.1158472, lng: 21.8357861 },
    { lat: 49.1157758, lng: 21.835989 },
    { lat: 49.1156684, lng: 21.8360575 },
    { lat: 49.115527, lng: 21.8360681 },
    { lat: 49.1154315, lng: 21.8359826 },
    { lat: 49.1155209, lng: 21.835389 },
    { lat: 49.1153105, lng: 21.8354774 },
    { lat: 49.1152829, lng: 21.8352826 },
    { lat: 49.1150987, lng: 21.8357722 },
    { lat: 49.1148198, lng: 21.8359931 },
    { lat: 49.1142777, lng: 21.8362588 },
    { lat: 49.1141528, lng: 21.8362627 },
    { lat: 49.1140537, lng: 21.8361652 },
    { lat: 49.1138265, lng: 21.8356879 },
    { lat: 49.1132697, lng: 21.8358632 },
    { lat: 49.1130089, lng: 21.8356482 },
    { lat: 49.1127506, lng: 21.8353171 },
    { lat: 49.1126204, lng: 21.8353235 },
    { lat: 49.1124845, lng: 21.8354666 },
    { lat: 49.112456, lng: 21.8356697 },
    { lat: 49.1119259, lng: 21.8356162 },
    { lat: 49.1116882, lng: 21.835821 },
    { lat: 49.1116258, lng: 21.8357529 },
    { lat: 49.1115871, lng: 21.8359509 },
    { lat: 49.1114644, lng: 21.8360401 },
    { lat: 49.1121636, lng: 21.8336103 },
    { lat: 49.1123663, lng: 21.8332309 },
    { lat: 49.1127108, lng: 21.8323206 },
    { lat: 49.1128456, lng: 21.8311747 },
    { lat: 49.1131325, lng: 21.8303051 },
    { lat: 49.1133173, lng: 21.8293505 },
    { lat: 49.1138619, lng: 21.8273029 },
    { lat: 49.113985, lng: 21.8247525 },
    { lat: 49.1139327, lng: 21.8238576 },
    { lat: 49.114154, lng: 21.8237543 },
    { lat: 49.1141735, lng: 21.8219337 },
    { lat: 49.1145424, lng: 21.8216764 },
    { lat: 49.1148787, lng: 21.8216286 },
    { lat: 49.1149334, lng: 21.8212662 },
    { lat: 49.1149225, lng: 21.819837 },
    { lat: 49.1152125, lng: 21.8197033 },
    { lat: 49.1154719, lng: 21.8191596 },
    { lat: 49.1156463, lng: 21.8170244 },
    { lat: 49.1155287, lng: 21.8158745 },
    { lat: 49.1151918, lng: 21.8153708 },
    { lat: 49.1152517, lng: 21.8148616 },
    { lat: 49.1152511, lng: 21.8139255 },
    { lat: 49.115144, lng: 21.8131297 },
    { lat: 49.1151577, lng: 21.8125299 },
    { lat: 49.1151209, lng: 21.8121907 },
    { lat: 49.1151801, lng: 21.8117469 },
    { lat: 49.115362, lng: 21.8114235 },
    { lat: 49.1155429, lng: 21.8108543 },
    { lat: 49.1143171, lng: 21.8089957 },
    { lat: 49.113771, lng: 21.8087151 },
    { lat: 49.1135675, lng: 21.8079865 },
    { lat: 49.1133836, lng: 21.8078303 },
    { lat: 49.1130594, lng: 21.8060429 },
    { lat: 49.1125498, lng: 21.8053073 },
    { lat: 49.1118482, lng: 21.8049316 },
    { lat: 49.110801, lng: 21.8047533 },
    { lat: 49.1094164, lng: 21.8037208 },
    { lat: 49.1057053, lng: 21.8046762 },
    { lat: 49.1054169, lng: 21.8044786 },
    { lat: 49.1052264, lng: 21.8041612 },
    { lat: 49.1046926, lng: 21.8041847 },
    { lat: 49.1043274, lng: 21.8041069 },
    { lat: 49.1040808, lng: 21.8041888 },
    { lat: 49.1038238, lng: 21.8048663 },
    { lat: 49.1034105, lng: 21.8044101 },
    { lat: 49.1030126, lng: 21.8045533 },
    { lat: 49.1026549, lng: 21.8039042 },
    { lat: 49.1021727, lng: 21.8026645 },
    { lat: 49.0997454, lng: 21.8022324 },
    { lat: 49.0989391, lng: 21.8029827 },
    { lat: 49.0963151, lng: 21.8027843 },
    { lat: 49.0946932, lng: 21.802742 },
    { lat: 49.0938003, lng: 21.8031862 },
    { lat: 49.0927528, lng: 21.8030056 },
    { lat: 49.0919508, lng: 21.8027865 },
    { lat: 49.090209, lng: 21.8032481 },
    { lat: 49.0878875, lng: 21.8039958 },
    { lat: 49.0866637, lng: 21.8045626 },
    { lat: 49.0849502, lng: 21.8055054 },
    { lat: 49.0838783, lng: 21.8056815 },
    { lat: 49.0805972, lng: 21.8080767 },
    { lat: 49.0804388, lng: 21.8084336 },
    { lat: 49.0796652, lng: 21.8097887 },
    { lat: 49.0795888, lng: 21.8101877 },
    { lat: 49.0792893, lng: 21.8134305 },
    { lat: 49.0796373, lng: 21.8130981 },
    { lat: 49.0795162, lng: 21.8134208 },
    { lat: 49.0784857, lng: 21.8139322 },
    { lat: 49.0775694, lng: 21.8145685 },
    { lat: 49.0771757, lng: 21.8150026 },
    { lat: 49.0770553, lng: 21.8154332 },
    { lat: 49.0767064, lng: 21.8161258 },
    { lat: 49.0764532, lng: 21.8168517 },
    { lat: 49.0750655, lng: 21.8173055 },
    { lat: 49.0734537, lng: 21.8187511 },
    { lat: 49.0729468, lng: 21.8199863 },
    { lat: 49.072465, lng: 21.8209734 },
    { lat: 49.0719819, lng: 21.8216893 },
    { lat: 49.0715714, lng: 21.822655 },
    { lat: 49.0711131, lng: 21.8236388 },
  ],
  NechválovaPolianka: [
    { lat: 49.053212, lng: 22.094718 },
    { lat: 49.054105, lng: 22.09463 },
    { lat: 49.054654, lng: 22.094625 },
    { lat: 49.055513, lng: 22.095186 },
    { lat: 49.056182, lng: 22.095158 },
    { lat: 49.057324, lng: 22.095852 },
    { lat: 49.057505, lng: 22.096128 },
    { lat: 49.057855, lng: 22.096665 },
    { lat: 49.058676, lng: 22.097994 },
    { lat: 49.059579, lng: 22.099501 },
    { lat: 49.060305, lng: 22.100848 },
    { lat: 49.060808, lng: 22.101725 },
    { lat: 49.061323, lng: 22.10262 },
    { lat: 49.061459, lng: 22.102892 },
    { lat: 49.062048, lng: 22.104069 },
    { lat: 49.063067, lng: 22.105961 },
    { lat: 49.063483, lng: 22.106683 },
    { lat: 49.063559, lng: 22.106951 },
    { lat: 49.06369, lng: 22.107409 },
    { lat: 49.063166, lng: 22.107976 },
    { lat: 49.064477, lng: 22.108972 },
    { lat: 49.065176, lng: 22.109328 },
    { lat: 49.066041, lng: 22.109864 },
    { lat: 49.066209, lng: 22.109916 },
    { lat: 49.066324, lng: 22.11019 },
    { lat: 49.066631, lng: 22.110681 },
    { lat: 49.066802, lng: 22.111159 },
    { lat: 49.066946, lng: 22.111465 },
    { lat: 49.066966, lng: 22.111724 },
    { lat: 49.066985, lng: 22.112387 },
    { lat: 49.066852, lng: 22.112721 },
    { lat: 49.066814, lng: 22.113158 },
    { lat: 49.066714, lng: 22.113514 },
    { lat: 49.066694, lng: 22.113707 },
    { lat: 49.06661, lng: 22.114169 },
    { lat: 49.066482, lng: 22.115051 },
    { lat: 49.066208, lng: 22.115765 },
    { lat: 49.066043, lng: 22.116108 },
    { lat: 49.065793, lng: 22.116683 },
    { lat: 49.065542, lng: 22.117096 },
    { lat: 49.065364, lng: 22.117391 },
    { lat: 49.065592, lng: 22.117566 },
    { lat: 49.065801, lng: 22.118063 },
    { lat: 49.066109, lng: 22.118636 },
    { lat: 49.066831, lng: 22.120306 },
    { lat: 49.067657, lng: 22.121924 },
    { lat: 49.067426, lng: 22.122532 },
    { lat: 49.06793, lng: 22.123781 },
    { lat: 49.068382, lng: 22.125165 },
    { lat: 49.068733, lng: 22.126357 },
    { lat: 49.068865, lng: 22.126799 },
    { lat: 49.069503, lng: 22.125871 },
    { lat: 49.07008, lng: 22.12699 },
    { lat: 49.070464, lng: 22.128035 },
    { lat: 49.07091, lng: 22.12925 },
    { lat: 49.072072, lng: 22.131562 },
    { lat: 49.072962, lng: 22.133537 },
    { lat: 49.073647, lng: 22.13401 },
    { lat: 49.074634, lng: 22.133448 },
    { lat: 49.076173, lng: 22.133108 },
    { lat: 49.076932, lng: 22.132678 },
    { lat: 49.077688, lng: 22.132073 },
    { lat: 49.078367, lng: 22.132046 },
    { lat: 49.079369, lng: 22.131824 },
    { lat: 49.079885, lng: 22.13177 },
    { lat: 49.08029, lng: 22.131768 },
    { lat: 49.080425, lng: 22.131785 },
    { lat: 49.080674, lng: 22.131938 },
    { lat: 49.080817, lng: 22.131861 },
    { lat: 49.082257, lng: 22.131982 },
    { lat: 49.082665, lng: 22.131959 },
    { lat: 49.083304, lng: 22.131683 },
    { lat: 49.083771, lng: 22.131483 },
    { lat: 49.084086, lng: 22.131397 },
    { lat: 49.084306, lng: 22.131275 },
    { lat: 49.084709, lng: 22.130885 },
    { lat: 49.084774, lng: 22.130834 },
    { lat: 49.08518, lng: 22.13055 },
    { lat: 49.085638, lng: 22.130421 },
    { lat: 49.086174, lng: 22.130223 },
    { lat: 49.086753, lng: 22.130072 },
    { lat: 49.087256, lng: 22.129898 },
    { lat: 49.087836, lng: 22.129574 },
    { lat: 49.088868, lng: 22.129174 },
    { lat: 49.089913, lng: 22.128793 },
    { lat: 49.091351, lng: 22.127873 },
    { lat: 49.092226, lng: 22.127519 },
    { lat: 49.093412, lng: 22.127184 },
    { lat: 49.093731, lng: 22.12662 },
    { lat: 49.094963, lng: 22.125328 },
    { lat: 49.095882, lng: 22.124263 },
    { lat: 49.097144, lng: 22.122052 },
    { lat: 49.097113, lng: 22.12204 },
    { lat: 49.097165, lng: 22.121551 },
    { lat: 49.097248, lng: 22.121137 },
    { lat: 49.097287, lng: 22.12088 },
    { lat: 49.097352, lng: 22.120628 },
    { lat: 49.097584, lng: 22.119834 },
    { lat: 49.09776, lng: 22.119377 },
    { lat: 49.097865, lng: 22.119145 },
    { lat: 49.09808, lng: 22.118737 },
    { lat: 49.098185, lng: 22.118364 },
    { lat: 49.098563, lng: 22.116442 },
    { lat: 49.09859, lng: 22.116463 },
    { lat: 49.098625, lng: 22.116193 },
    { lat: 49.098597, lng: 22.11518 },
    { lat: 49.098599, lng: 22.114301 },
    { lat: 49.098645, lng: 22.113949 },
    { lat: 49.098783, lng: 22.113477 },
    { lat: 49.09773, lng: 22.111152 },
    { lat: 49.097607, lng: 22.108952 },
    { lat: 49.097636, lng: 22.108663 },
    { lat: 49.097689, lng: 22.108407 },
    { lat: 49.097998, lng: 22.10764 },
    { lat: 49.098199, lng: 22.107382 },
    { lat: 49.098805, lng: 22.105956 },
    { lat: 49.099565, lng: 22.105136 },
    { lat: 49.0989809, lng: 22.1043427 },
    { lat: 49.0959223, lng: 22.1002886 },
    { lat: 49.0947998, lng: 22.0986513 },
    { lat: 49.0946604, lng: 22.0985143 },
    { lat: 49.0945509, lng: 22.097445 },
    { lat: 49.0945974, lng: 22.0970254 },
    { lat: 49.0943538, lng: 22.0968477 },
    { lat: 49.0935987, lng: 22.0959227 },
    { lat: 49.0934997, lng: 22.0955433 },
    { lat: 49.0933625, lng: 22.0954414 },
    { lat: 49.0930386, lng: 22.0955889 },
    { lat: 49.0928773, lng: 22.0955632 },
    { lat: 49.0924685, lng: 22.0959285 },
    { lat: 49.0922445, lng: 22.0962594 },
    { lat: 49.0919762, lng: 22.0958936 },
    { lat: 49.0915083, lng: 22.0957486 },
    { lat: 49.0896048, lng: 22.0939053 },
    { lat: 49.0893944, lng: 22.0935683 },
    { lat: 49.0889526, lng: 22.093531 },
    { lat: 49.0877321, lng: 22.0920928 },
    { lat: 49.0869437, lng: 22.0916599 },
    { lat: 49.0834896, lng: 22.0865814 },
    { lat: 49.0815025, lng: 22.0841588 },
    { lat: 49.0805693, lng: 22.0834115 },
    { lat: 49.0789009, lng: 22.0823456 },
    { lat: 49.078849, lng: 22.0823832 },
    { lat: 49.075049, lng: 22.080114 },
    { lat: 49.0733051, lng: 22.0798534 },
    { lat: 49.0716373, lng: 22.0794801 },
    { lat: 49.069858, lng: 22.0792969 },
    { lat: 49.0686173, lng: 22.0792446 },
    { lat: 49.0671683, lng: 22.0793636 },
    { lat: 49.0662321, lng: 22.0793549 },
    { lat: 49.06497, lng: 22.0796857 },
    { lat: 49.0641467, lng: 22.0796307 },
    { lat: 49.0627213, lng: 22.0785247 },
    { lat: 49.0619493, lng: 22.0781229 },
    { lat: 49.0615679, lng: 22.0777804 },
    { lat: 49.0609123, lng: 22.0784547 },
    { lat: 49.060937, lng: 22.0785509 },
    { lat: 49.0601037, lng: 22.0799801 },
    { lat: 49.0591293, lng: 22.0812072 },
    { lat: 49.0586364, lng: 22.082002 },
    { lat: 49.0584232, lng: 22.083129 },
    { lat: 49.0579011, lng: 22.0843542 },
    { lat: 49.0574337, lng: 22.0859017 },
    { lat: 49.0568406, lng: 22.0868005 },
    { lat: 49.0571088, lng: 22.0873913 },
    { lat: 49.0567431, lng: 22.0878638 },
    { lat: 49.0550715, lng: 22.089091 },
    { lat: 49.0542831, lng: 22.0892906 },
    { lat: 49.053622, lng: 22.0896325 },
    { lat: 49.0532457, lng: 22.0896064 },
    { lat: 49.0523716, lng: 22.0898401 },
    { lat: 49.051712, lng: 22.0901941 },
    { lat: 49.0516795, lng: 22.0910152 },
    { lat: 49.0518239, lng: 22.091372 },
    { lat: 49.0521913, lng: 22.0920222 },
    { lat: 49.0529759, lng: 22.092714 },
    { lat: 49.053212, lng: 22.094718 },
  ],
  Lukačovce: [
    { lat: 49.020812, lng: 21.774112 },
    { lat: 49.020648, lng: 21.773847 },
    { lat: 49.02048, lng: 21.773489 },
    { lat: 49.020147, lng: 21.773181 },
    { lat: 49.019879, lng: 21.772895 },
    { lat: 49.019636, lng: 21.772509 },
    { lat: 49.01943, lng: 21.772372 },
    { lat: 49.019073, lng: 21.772021 },
    { lat: 49.018783, lng: 21.771703 },
    { lat: 49.018556, lng: 21.771346 },
    { lat: 49.01856, lng: 21.771024 },
    { lat: 49.018182, lng: 21.770647 },
    { lat: 49.017905, lng: 21.770215 },
    { lat: 49.017458, lng: 21.76968 },
    { lat: 49.017206, lng: 21.769457 },
    { lat: 49.017175, lng: 21.769416 },
    { lat: 49.016829, lng: 21.769252 },
    { lat: 49.016423, lng: 21.768907 },
    { lat: 49.016314, lng: 21.768589 },
    { lat: 49.016306, lng: 21.768287 },
    { lat: 49.016202, lng: 21.768061 },
    { lat: 49.015794, lng: 21.767762 },
    { lat: 49.015537, lng: 21.767521 },
    { lat: 49.015137, lng: 21.767034 },
    { lat: 49.014867, lng: 21.766719 },
    { lat: 49.014554, lng: 21.766443 },
    { lat: 49.014366, lng: 21.766436 },
    { lat: 49.014172, lng: 21.766302 },
    { lat: 49.014026, lng: 21.766087 },
    { lat: 49.01387, lng: 21.765962 },
    { lat: 49.013643, lng: 21.765867 },
    { lat: 49.013749, lng: 21.766234 },
    { lat: 49.013534, lng: 21.76663 },
    { lat: 49.013449, lng: 21.766512 },
    { lat: 49.013441, lng: 21.766295 },
    { lat: 49.013327, lng: 21.766144 },
    { lat: 49.013033, lng: 21.766204 },
    { lat: 49.012815, lng: 21.766435 },
    { lat: 49.012865, lng: 21.766708 },
    { lat: 49.013068, lng: 21.766795 },
    { lat: 49.01306, lng: 21.767091 },
    { lat: 49.012881, lng: 21.767415 },
    { lat: 49.012878, lng: 21.767583 },
    { lat: 49.012678, lng: 21.767622 },
    { lat: 49.012616, lng: 21.767206 },
    { lat: 49.012507, lng: 21.767035 },
    { lat: 49.012317, lng: 21.766847 },
    { lat: 49.012217, lng: 21.76681 },
    { lat: 49.012075, lng: 21.766759 },
    { lat: 49.011907, lng: 21.766625 },
    { lat: 49.011728, lng: 21.766082 },
    { lat: 49.011591, lng: 21.765916 },
    { lat: 49.011653, lng: 21.765707 },
    { lat: 49.011744, lng: 21.765486 },
    { lat: 49.011848, lng: 21.765045 },
    { lat: 49.011708, lng: 21.764872 },
    { lat: 49.011603, lng: 21.764693 },
    { lat: 49.011507, lng: 21.764526 },
    { lat: 49.011458, lng: 21.764508 },
    { lat: 49.011172, lng: 21.764403 },
    { lat: 49.011195, lng: 21.764216 },
    { lat: 49.011168, lng: 21.764122 },
    { lat: 49.010553, lng: 21.764418 },
    { lat: 49.009985, lng: 21.764602 },
    { lat: 49.009793, lng: 21.764519 },
    { lat: 49.009016, lng: 21.764607 },
    { lat: 49.008905, lng: 21.764609 },
    { lat: 49.00886, lng: 21.764588 },
    { lat: 49.008405, lng: 21.764585 },
    { lat: 49.008188, lng: 21.764635 },
    { lat: 49.007953, lng: 21.764538 },
    { lat: 49.007815, lng: 21.764523 },
    { lat: 49.007527, lng: 21.764594 },
    { lat: 49.007187, lng: 21.7645 },
    { lat: 49.006641, lng: 21.76465 },
    { lat: 49.006672, lng: 21.765127 },
    { lat: 49.006549, lng: 21.76526 },
    { lat: 49.006394, lng: 21.765358 },
    { lat: 49.006283, lng: 21.765558 },
    { lat: 49.006024, lng: 21.766142 },
    { lat: 49.00585, lng: 21.766419 },
    { lat: 49.005932, lng: 21.766882 },
    { lat: 49.005945, lng: 21.767374 },
    { lat: 49.005084, lng: 21.769761 },
    { lat: 49.004805, lng: 21.770446 },
    { lat: 49.004751, lng: 21.771004 },
    { lat: 49.004782, lng: 21.771327 },
    { lat: 49.004699, lng: 21.771439 },
    { lat: 49.004532, lng: 21.771847 },
    { lat: 49.004423, lng: 21.772055 },
    { lat: 49.004139, lng: 21.772369 },
    { lat: 49.004081, lng: 21.77273 },
    { lat: 49.003998, lng: 21.773044 },
    { lat: 49.003661, lng: 21.773923 },
    { lat: 49.0036347, lng: 21.7741935 },
    { lat: 49.0036028, lng: 21.7746755 },
    { lat: 49.003511, lng: 21.774774 },
    { lat: 49.0037419, lng: 21.7763139 },
    { lat: 49.0038849, lng: 21.7769129 },
    { lat: 49.0039701, lng: 21.7770331 },
    { lat: 49.0046732, lng: 21.777677 },
    { lat: 49.0054741, lng: 21.7785364 },
    { lat: 49.005708, lng: 21.7788705 },
    { lat: 49.0062759, lng: 21.7805009 },
    { lat: 49.0062364, lng: 21.7808358 },
    { lat: 49.0062995, lng: 21.7808427 },
    { lat: 49.0064611, lng: 21.7809085 },
    { lat: 49.0067652, lng: 21.7813523 },
    { lat: 49.0068654, lng: 21.7817375 },
    { lat: 49.0071725, lng: 21.7822138 },
    { lat: 49.0071819, lng: 21.782492 },
    { lat: 49.0073317, lng: 21.7828825 },
    { lat: 49.0072799, lng: 21.7833962 },
    { lat: 49.0073565, lng: 21.7840018 },
    { lat: 49.0076597, lng: 21.7847972 },
    { lat: 49.0074107, lng: 21.7854669 },
    { lat: 49.0073089, lng: 21.7869941 },
    { lat: 49.0075769, lng: 21.7869654 },
    { lat: 49.008484, lng: 21.7862068 },
    { lat: 49.0094299, lng: 21.7855125 },
    { lat: 49.0097063, lng: 21.7851558 },
    { lat: 49.0097824, lng: 21.7848824 },
    { lat: 49.0096168, lng: 21.7836257 },
    { lat: 49.0096288, lng: 21.7835268 },
    { lat: 49.0098931, lng: 21.783475 },
    { lat: 49.0108024, lng: 21.7838844 },
    { lat: 49.0114619, lng: 21.7843236 },
    { lat: 49.0114004, lng: 21.7850268 },
    { lat: 49.01179, lng: 21.7870949 },
    { lat: 49.0117014, lng: 21.7879714 },
    { lat: 49.0136936, lng: 21.7875866 },
    { lat: 49.0152044, lng: 21.78874 },
    { lat: 49.0160673, lng: 21.7895225 },
    { lat: 49.0175329, lng: 21.7903723 },
    { lat: 49.0181035, lng: 21.7907754 },
    { lat: 49.0186369, lng: 21.7912507 },
    { lat: 49.0190687, lng: 21.7912963 },
    { lat: 49.0199609, lng: 21.7915546 },
    { lat: 49.0203111, lng: 21.7919145 },
    { lat: 49.0206091, lng: 21.793289 },
    { lat: 49.0196626, lng: 21.794261 },
    { lat: 49.0200897, lng: 21.7950664 },
    { lat: 49.0195356, lng: 21.7958199 },
    { lat: 49.0193589, lng: 21.7957327 },
    { lat: 49.0190577, lng: 21.797254 },
    { lat: 49.0181301, lng: 21.798613 },
    { lat: 49.0179523, lng: 21.7986893 },
    { lat: 49.0178639, lng: 21.7989706 },
    { lat: 49.0189376, lng: 21.8007635 },
    { lat: 49.0196049, lng: 21.8017685 },
    { lat: 49.0205097, lng: 21.8016066 },
    { lat: 49.0221305, lng: 21.8014693 },
    { lat: 49.0221615, lng: 21.8024893 },
    { lat: 49.0243703, lng: 21.8022529 },
    { lat: 49.0243795, lng: 21.8033402 },
    { lat: 49.0245299, lng: 21.8035419 },
    { lat: 49.0245574, lng: 21.8050809 },
    { lat: 49.0247738, lng: 21.8049636 },
    { lat: 49.0250356, lng: 21.8051231 },
    { lat: 49.0252129, lng: 21.8049773 },
    { lat: 49.0254011, lng: 21.8051187 },
    { lat: 49.0255422, lng: 21.805397 },
    { lat: 49.0257417, lng: 21.8054538 },
    { lat: 49.0258551, lng: 21.8054072 },
    { lat: 49.0258458, lng: 21.8053039 },
    { lat: 49.0257321, lng: 21.8051752 },
    { lat: 49.0257904, lng: 21.8050753 },
    { lat: 49.0259351, lng: 21.8052372 },
    { lat: 49.0261598, lng: 21.8049706 },
    { lat: 49.0262113, lng: 21.805045 },
    { lat: 49.0265196, lng: 21.8051799 },
    { lat: 49.0265897, lng: 21.8053649 },
    { lat: 49.0265334, lng: 21.8056203 },
    { lat: 49.0266848, lng: 21.8056872 },
    { lat: 49.0272289, lng: 21.8056778 },
    { lat: 49.0273408, lng: 21.8058686 },
    { lat: 49.0276093, lng: 21.8059855 },
    { lat: 49.0276874, lng: 21.8061801 },
    { lat: 49.0283339, lng: 21.8069478 },
    { lat: 49.0289228, lng: 21.8073562 },
    { lat: 49.0296135, lng: 21.8090007 },
    { lat: 49.0302702, lng: 21.8101654 },
    { lat: 49.0316655, lng: 21.810922 },
    { lat: 49.0322921, lng: 21.8110035 },
    { lat: 49.0326155, lng: 21.8108504 },
    { lat: 49.0328554, lng: 21.810993 },
    { lat: 49.0339986, lng: 21.8101787 },
    { lat: 49.0348329, lng: 21.8100365 },
    { lat: 49.035799, lng: 21.8095971 },
    { lat: 49.036831, lng: 21.8092688 },
    { lat: 49.0375212, lng: 21.8086415 },
    { lat: 49.0384158, lng: 21.8085585 },
    { lat: 49.0386341, lng: 21.8089519 },
    { lat: 49.03923, lng: 21.8088151 },
    { lat: 49.0393498, lng: 21.808706 },
    { lat: 49.0403028, lng: 21.8087596 },
    { lat: 49.0405049, lng: 21.8089999 },
    { lat: 49.0413162, lng: 21.8090989 },
    { lat: 49.0418061, lng: 21.8085564 },
    { lat: 49.0424321, lng: 21.8080975 },
    { lat: 49.042364, lng: 21.80777 },
    { lat: 49.0418829, lng: 21.8075722 },
    { lat: 49.0412053, lng: 21.8070299 },
    { lat: 49.041113, lng: 21.8060817 },
    { lat: 49.0403939, lng: 21.8053307 },
    { lat: 49.0395534, lng: 21.805108 },
    { lat: 49.0399264, lng: 21.8033997 },
    { lat: 49.0401027, lng: 21.8034791 },
    { lat: 49.0403321, lng: 21.8032519 },
    { lat: 49.0405264, lng: 21.8028587 },
    { lat: 49.0406171, lng: 21.8028013 },
    { lat: 49.0410672, lng: 21.8028423 },
    { lat: 49.0417133, lng: 21.8023879 },
    { lat: 49.0419323, lng: 21.8020692 },
    { lat: 49.0422293, lng: 21.8021123 },
    { lat: 49.0423524, lng: 21.8019988 },
    { lat: 49.042372, lng: 21.8017534 },
    { lat: 49.0430336, lng: 21.8015526 },
    { lat: 49.0433816, lng: 21.8013477 },
    { lat: 49.0434061, lng: 21.8006954 },
    { lat: 49.0433701, lng: 21.8003896 },
    { lat: 49.0435312, lng: 21.8000464 },
    { lat: 49.0433114, lng: 21.7959235 },
    { lat: 49.0432772, lng: 21.7933187 },
    { lat: 49.0433387, lng: 21.7918003 },
    { lat: 49.0433146, lng: 21.7915637 },
    { lat: 49.0433629, lng: 21.791 },
    { lat: 49.0432852, lng: 21.7890995 },
    { lat: 49.043421, lng: 21.7883143 },
    { lat: 49.0433906, lng: 21.787278 },
    { lat: 49.0434692, lng: 21.7864622 },
    { lat: 49.043206, lng: 21.7864544 },
    { lat: 49.0431943, lng: 21.7861797 },
    { lat: 49.0421101, lng: 21.7846888 },
    { lat: 49.0418973, lng: 21.7840774 },
    { lat: 49.0418967, lng: 21.7840093 },
    { lat: 49.0413071, lng: 21.7845197 },
    { lat: 49.041184, lng: 21.7845928 },
    { lat: 49.0408935, lng: 21.7845361 },
    { lat: 49.0404812, lng: 21.7840372 },
    { lat: 49.0397667, lng: 21.7840292 },
    { lat: 49.0393112, lng: 21.78416 },
    { lat: 49.0388947, lng: 21.7841519 },
    { lat: 49.0379813, lng: 21.7836399 },
    { lat: 49.0378436, lng: 21.7831076 },
    { lat: 49.0376819, lng: 21.7828198 },
    { lat: 49.037083, lng: 21.7827589 },
    { lat: 49.0368685, lng: 21.7828604 },
    { lat: 49.0364744, lng: 21.7824562 },
    { lat: 49.0364508, lng: 21.7820668 },
    { lat: 49.0364014, lng: 21.781902 },
    { lat: 49.0360576, lng: 21.7820067 },
    { lat: 49.0358974, lng: 21.7826292 },
    { lat: 49.0353096, lng: 21.782526 },
    { lat: 49.0347314, lng: 21.781925 },
    { lat: 49.0324056, lng: 21.7810839 },
    { lat: 49.0309971, lng: 21.7806067 },
    { lat: 49.0283397, lng: 21.7800357 },
    { lat: 49.0282038, lng: 21.7799142 },
    { lat: 49.0281577, lng: 21.7794424 },
    { lat: 49.026264, lng: 21.7788287 },
    { lat: 49.0261469, lng: 21.7783992 },
    { lat: 49.0259087, lng: 21.7781057 },
    { lat: 49.0256033, lng: 21.7779576 },
    { lat: 49.0254304, lng: 21.7777121 },
    { lat: 49.0249038, lng: 21.7775333 },
    { lat: 49.024701, lng: 21.7773936 },
    { lat: 49.0233618, lng: 21.7759554 },
    { lat: 49.0232244, lng: 21.7759909 },
    { lat: 49.0228936, lng: 21.7763404 },
    { lat: 49.022752, lng: 21.7762995 },
    { lat: 49.0226142, lng: 21.7760595 },
    { lat: 49.0224475, lng: 21.7755832 },
    { lat: 49.0222174, lng: 21.7751757 },
    { lat: 49.0221069, lng: 21.7747845 },
    { lat: 49.0217849, lng: 21.7749023 },
    { lat: 49.0216052, lng: 21.774611 },
    { lat: 49.0215705, lng: 21.7743572 },
    { lat: 49.021374, lng: 21.7740745 },
    { lat: 49.0212634, lng: 21.774269 },
    { lat: 49.0211582, lng: 21.7743396 },
    { lat: 49.0210577, lng: 21.7742804 },
    { lat: 49.0209794, lng: 21.7743325 },
    { lat: 49.020812, lng: 21.774112 },
  ],
  Udavské: [
    { lat: 48.9771463, lng: 21.9267372 },
    { lat: 48.9770926, lng: 21.9267503 },
    { lat: 48.9768628, lng: 21.9271456 },
    { lat: 48.9765984, lng: 21.9274598 },
    { lat: 48.9765492, lng: 21.9276073 },
    { lat: 48.9765409, lng: 21.9279523 },
    { lat: 48.9764378, lng: 21.9282311 },
    { lat: 48.9755422, lng: 21.929268 },
    { lat: 48.9751072, lng: 21.9295011 },
    { lat: 48.974802, lng: 21.9297568 },
    { lat: 48.9738699, lng: 21.9309455 },
    { lat: 48.9734577, lng: 21.9310114 },
    { lat: 48.973034, lng: 21.9313967 },
    { lat: 48.9720848, lng: 21.931983 },
    { lat: 48.971377, lng: 21.9323366 },
    { lat: 48.9706174, lng: 21.9332303 },
    { lat: 48.9704452, lng: 21.9336784 },
    { lat: 48.9703119, lng: 21.9338816 },
    { lat: 48.969439, lng: 21.9344201 },
    { lat: 48.9691174, lng: 21.9344729 },
    { lat: 48.9687242, lng: 21.9344049 },
    { lat: 48.9684209, lng: 21.9345498 },
    { lat: 48.9680735, lng: 21.9349185 },
    { lat: 48.9680796, lng: 21.9349865 },
    { lat: 48.9680579, lng: 21.9352008 },
    { lat: 48.9681696, lng: 21.9352123 },
    { lat: 48.9682372, lng: 21.9353988 },
    { lat: 48.9681932, lng: 21.9356201 },
    { lat: 48.9682303, lng: 21.9359341 },
    { lat: 48.968192, lng: 21.9365177 },
    { lat: 48.9682799, lng: 21.9377016 },
    { lat: 48.9679439, lng: 21.9404741 },
    { lat: 48.9677059, lng: 21.9411214 },
    { lat: 48.9675204, lng: 21.9413135 },
    { lat: 48.9672825, lng: 21.9416998 },
    { lat: 48.9671683, lng: 21.9420354 },
    { lat: 48.9669482, lng: 21.9424536 },
    { lat: 48.9663628, lng: 21.9442915 },
    { lat: 48.9646291, lng: 21.9463239 },
    { lat: 48.9642289, lng: 21.9476112 },
    { lat: 48.9643819, lng: 21.9481516 },
    { lat: 48.9650777, lng: 21.9479511 },
    { lat: 48.9650469, lng: 21.9529307 },
    { lat: 48.9649644, lng: 21.9545875 },
    { lat: 48.9641184, lng: 21.9560459 },
    { lat: 48.9628456, lng: 21.9554308 },
    { lat: 48.9628342, lng: 21.9558829 },
    { lat: 48.9612911, lng: 21.955343 },
    { lat: 48.9607802, lng: 21.9552812 },
    { lat: 48.9607618, lng: 21.9563207 },
    { lat: 48.9595922, lng: 21.9563319 },
    { lat: 48.9595235, lng: 21.9588246 },
    { lat: 48.9595375, lng: 21.961725 },
    { lat: 48.9595085, lng: 21.9626416 },
    { lat: 48.9594468, lng: 21.963148 },
    { lat: 48.9589608, lng: 21.9653419 },
    { lat: 48.9588054, lng: 21.9667922 },
    { lat: 48.9582989, lng: 21.9687453 },
    { lat: 48.9581733, lng: 21.9690337 },
    { lat: 48.957095, lng: 21.9729882 },
    { lat: 48.9568409, lng: 21.9739092 },
    { lat: 48.9573435, lng: 21.9758697 },
    { lat: 48.9590447, lng: 21.9752 },
    { lat: 48.9593682, lng: 21.9751269 },
    { lat: 48.9606218, lng: 21.9871351 },
    { lat: 48.9610221, lng: 21.9910329 },
    { lat: 48.9612535, lng: 21.9912146 },
    { lat: 48.9614388, lng: 21.9924438 },
    { lat: 48.9614296, lng: 21.9936775 },
    { lat: 48.9618273, lng: 21.9936121 },
    { lat: 48.9625766, lng: 21.9932947 },
    { lat: 48.9635046, lng: 21.9931612 },
    { lat: 48.9636956, lng: 21.9930566 },
    { lat: 48.964664, lng: 21.9932842 },
    { lat: 48.9649607, lng: 21.9927689 },
    { lat: 48.9652402, lng: 21.9927463 },
    { lat: 48.9654946, lng: 21.9923848 },
    { lat: 48.96584, lng: 21.9925719 },
    { lat: 48.9666602, lng: 21.9921154 },
    { lat: 48.9670313, lng: 21.9920194 },
    { lat: 48.9670891, lng: 21.9921296 },
    { lat: 48.9673064, lng: 21.9922359 },
    { lat: 48.9674644, lng: 21.9924804 },
    { lat: 48.9677586, lng: 21.9933807 },
    { lat: 48.9679951, lng: 21.9934891 },
    { lat: 48.9685144, lng: 21.9932888 },
    { lat: 48.9709079, lng: 21.992916 },
    { lat: 48.9718577, lng: 21.9946817 },
    { lat: 48.9718587, lng: 21.9946361 },
    { lat: 48.9722096, lng: 21.9946798 },
    { lat: 48.9722358, lng: 21.9961688 },
    { lat: 48.9726788, lng: 21.9961185 },
    { lat: 48.9731113, lng: 21.9980333 },
    { lat: 48.9741032, lng: 21.9979758 },
    { lat: 48.9742533, lng: 21.9992427 },
    { lat: 48.9752471, lng: 21.9991765 },
    { lat: 48.9755559, lng: 21.9990134 },
    { lat: 48.9762225, lng: 21.9991407 },
    { lat: 48.9766737, lng: 21.9991429 },
    { lat: 48.9763552, lng: 21.9966439 },
    { lat: 48.9771129, lng: 21.9967643 },
    { lat: 48.9805487, lng: 21.9967888 },
    { lat: 48.9804757, lng: 21.9954096 },
    { lat: 48.980531, lng: 21.9947932 },
    { lat: 48.9805766, lng: 21.9942856 },
    { lat: 48.9806979, lng: 21.9940041 },
    { lat: 48.9808121, lng: 21.9938827 },
    { lat: 48.9809245, lng: 21.9930993 },
    { lat: 48.9815947, lng: 21.9911977 },
    { lat: 48.983595, lng: 21.9925934 },
    { lat: 48.9843809, lng: 21.9923532 },
    { lat: 48.9847892, lng: 21.9921239 },
    { lat: 48.9852607, lng: 21.9918098 },
    { lat: 48.9860414, lng: 21.991114 },
    { lat: 48.986767, lng: 21.9911186 },
    { lat: 48.9871317, lng: 21.9908989 },
    { lat: 48.9876524, lng: 21.9908041 },
    { lat: 48.9881881, lng: 21.9909089 },
    { lat: 48.9883374, lng: 21.9907085 },
    { lat: 48.9886539, lng: 21.990493 },
    { lat: 48.9888745, lng: 21.9902212 },
    { lat: 48.9891509, lng: 21.99022 },
    { lat: 48.990604, lng: 21.9886843 },
    { lat: 48.990687, lng: 21.9883583 },
    { lat: 48.9930259, lng: 21.9847377 },
    { lat: 48.9938869, lng: 21.9831001 },
    { lat: 48.9941081, lng: 21.9827677 },
    { lat: 48.9944147, lng: 21.9826508 },
    { lat: 48.9951656, lng: 21.9815281 },
    { lat: 48.9955514, lng: 21.980614 },
    { lat: 48.9955751, lng: 21.9805686 },
    { lat: 48.9951046, lng: 21.9802323 },
    { lat: 48.9945819, lng: 21.9797472 },
    { lat: 48.9939224, lng: 21.9790348 },
    { lat: 48.9930907, lng: 21.9765029 },
    { lat: 48.9925347, lng: 21.97602 },
    { lat: 48.9913491, lng: 21.9753393 },
    { lat: 48.9913065, lng: 21.9721862 },
    { lat: 48.9914086, lng: 21.9712087 },
    { lat: 48.9913338, lng: 21.9706847 },
    { lat: 48.9910018, lng: 21.9700373 },
    { lat: 48.9903663, lng: 21.969212 },
    { lat: 48.9902166, lng: 21.9681567 },
    { lat: 48.9899013, lng: 21.9675479 },
    { lat: 48.9871357, lng: 21.9643209 },
    { lat: 48.9867886, lng: 21.963475 },
    { lat: 48.986656, lng: 21.9629793 },
    { lat: 48.9865978, lng: 21.9623015 },
    { lat: 48.9864728, lng: 21.9620246 },
    { lat: 48.9857976, lng: 21.9583205 },
    { lat: 48.9856657, lng: 21.957992 },
    { lat: 48.9859328, lng: 21.9558276 },
    { lat: 48.9858322, lng: 21.955799 },
    { lat: 48.9856216, lng: 21.952018 },
    { lat: 48.9856849, lng: 21.9519384 },
    { lat: 48.9847449, lng: 21.9406547 },
    { lat: 48.9846283, lng: 21.9401747 },
    { lat: 48.9844401, lng: 21.9399807 },
    { lat: 48.9841862, lng: 21.9392664 },
    { lat: 48.9837728, lng: 21.9396945 },
    { lat: 48.9836076, lng: 21.9392517 },
    { lat: 48.9828103, lng: 21.9381743 },
    { lat: 48.9819665, lng: 21.9383033 },
    { lat: 48.9819953, lng: 21.9364728 },
    { lat: 48.9819548, lng: 21.935168 },
    { lat: 48.9817596, lng: 21.9334732 },
    { lat: 48.9819735, lng: 21.9310368 },
    { lat: 48.9815699, lng: 21.9307302 },
    { lat: 48.9812411, lng: 21.9303849 },
    { lat: 48.9809645, lng: 21.9300954 },
    { lat: 48.9796887, lng: 21.9285015 },
    { lat: 48.9796615, lng: 21.9285485 },
    { lat: 48.9793814, lng: 21.9282685 },
    { lat: 48.9790218, lng: 21.9280703 },
    { lat: 48.9787979, lng: 21.927973 },
    { lat: 48.978247, lng: 21.9278021 },
    { lat: 48.9782477, lng: 21.9273759 },
    { lat: 48.977925, lng: 21.9272437 },
    { lat: 48.9774153, lng: 21.9272398 },
    { lat: 48.9771463, lng: 21.9267372 },
  ],
  Topoľovka: [
    { lat: 48.915461, lng: 21.8249291 },
    { lat: 48.9156294, lng: 21.8249133 },
    { lat: 48.9164586, lng: 21.824791 },
    { lat: 48.9171028, lng: 21.8241249 },
    { lat: 48.9175588, lng: 21.8235275 },
    { lat: 48.9186036, lng: 21.8240383 },
    { lat: 48.9186445, lng: 21.8237057 },
    { lat: 48.9202923, lng: 21.8239173 },
    { lat: 48.921094, lng: 21.8239435 },
    { lat: 48.9212494, lng: 21.823817 },
    { lat: 48.9212741, lng: 21.8233805 },
    { lat: 48.9213186, lng: 21.8238429 },
    { lat: 48.9220252, lng: 21.8240179 },
    { lat: 48.9225395, lng: 21.8236785 },
    { lat: 48.9232522, lng: 21.8237473 },
    { lat: 48.9234966, lng: 21.8239342 },
    { lat: 48.9248759, lng: 21.8239349 },
    { lat: 48.9253133, lng: 21.82436 },
    { lat: 48.9255969, lng: 21.8243227 },
    { lat: 48.9263157, lng: 21.8240623 },
    { lat: 48.9264167, lng: 21.823622 },
    { lat: 48.9286491, lng: 21.8248145 },
    { lat: 48.929499, lng: 21.8248368 },
    { lat: 48.9301478, lng: 21.8250407 },
    { lat: 48.9302592, lng: 21.8250004 },
    { lat: 48.9310938, lng: 21.8242115 },
    { lat: 48.931408, lng: 21.8247925 },
    { lat: 48.9316484, lng: 21.8240805 },
    { lat: 48.9331348, lng: 21.826101 },
    { lat: 48.934002, lng: 21.8256129 },
    { lat: 48.9346369, lng: 21.8245406 },
    { lat: 48.9348931, lng: 21.8245307 },
    { lat: 48.9349155, lng: 21.825352 },
    { lat: 48.9353735, lng: 21.828624 },
    { lat: 48.9360589, lng: 21.8286398 },
    { lat: 48.93615, lng: 21.8268918 },
    { lat: 48.9365827, lng: 21.824873 },
    { lat: 48.9365313, lng: 21.8242338 },
    { lat: 48.9362915, lng: 21.8240967 },
    { lat: 48.9362466, lng: 21.8228904 },
    { lat: 48.9366068, lng: 21.8228521 },
    { lat: 48.9368605, lng: 21.8227013 },
    { lat: 48.9366575, lng: 21.8195146 },
    { lat: 48.9365664, lng: 21.8186437 },
    { lat: 48.9361385, lng: 21.8186238 },
    { lat: 48.936141, lng: 21.8156409 },
    { lat: 48.9364154, lng: 21.8124321 },
    { lat: 48.9364939, lng: 21.8122514 },
    { lat: 48.9364164, lng: 21.8108212 },
    { lat: 48.9355341, lng: 21.8103323 },
    { lat: 48.9355869, lng: 21.8095967 },
    { lat: 48.9361919, lng: 21.8095468 },
    { lat: 48.9362198, lng: 21.8071593 },
    { lat: 48.9365744, lng: 21.8071677 },
    { lat: 48.9364639, lng: 21.8048235 },
    { lat: 48.9370728, lng: 21.8039278 },
    { lat: 48.9376944, lng: 21.8031763 },
    { lat: 48.9413787, lng: 21.7993816 },
    { lat: 48.9436327, lng: 21.7975062 },
    { lat: 48.944804, lng: 21.796404 },
    { lat: 48.944546, lng: 21.795215 },
    { lat: 48.94439, lng: 21.794036 },
    { lat: 48.944183, lng: 21.79302 },
    { lat: 48.944072, lng: 21.792276 },
    { lat: 48.943977, lng: 21.791646 },
    { lat: 48.943826, lng: 21.790949 },
    { lat: 48.943646, lng: 21.789681 },
    { lat: 48.943569, lng: 21.789407 },
    { lat: 48.943438, lng: 21.78913 },
    { lat: 48.943177, lng: 21.78865 },
    { lat: 48.943067, lng: 21.788448 },
    { lat: 48.942937, lng: 21.788209 },
    { lat: 48.942843, lng: 21.788024 },
    { lat: 48.942819, lng: 21.787977 },
    { lat: 48.942346, lng: 21.78705 },
    { lat: 48.94187, lng: 21.786137 },
    { lat: 48.94096, lng: 21.78398 },
    { lat: 48.940855, lng: 21.783365 },
    { lat: 48.940785, lng: 21.782392 },
    { lat: 48.939555, lng: 21.78221 },
    { lat: 48.938869, lng: 21.781474 },
    { lat: 48.938519, lng: 21.781316 },
    { lat: 48.937436, lng: 21.780833 },
    { lat: 48.936706, lng: 21.7806 },
    { lat: 48.936111, lng: 21.780411 },
    { lat: 48.933887, lng: 21.779943 },
    { lat: 48.933318, lng: 21.77965 },
    { lat: 48.933141, lng: 21.779479 },
    { lat: 48.931876, lng: 21.778257 },
    { lat: 48.9307722, lng: 21.7794111 },
    { lat: 48.9303248, lng: 21.7807598 },
    { lat: 48.9299767, lng: 21.7822817 },
    { lat: 48.9291979, lng: 21.7838586 },
    { lat: 48.9290296, lng: 21.7843893 },
    { lat: 48.9293965, lng: 21.7859843 },
    { lat: 48.9289235, lng: 21.7866092 },
    { lat: 48.9286298, lng: 21.7868609 },
    { lat: 48.9281095, lng: 21.7870587 },
    { lat: 48.9277725, lng: 21.7866057 },
    { lat: 48.9275834, lng: 21.7862518 },
    { lat: 48.9271686, lng: 21.7878578 },
    { lat: 48.926933, lng: 21.7891241 },
    { lat: 48.9268117, lng: 21.7894774 },
    { lat: 48.926635, lng: 21.7897714 },
    { lat: 48.9279552, lng: 21.7909197 },
    { lat: 48.9275982, lng: 21.7918073 },
    { lat: 48.9273519, lng: 21.7917184 },
    { lat: 48.9260181, lng: 21.7904482 },
    { lat: 48.9257596, lng: 21.7905389 },
    { lat: 48.9254114, lng: 21.793399 },
    { lat: 48.9238681, lng: 21.7946066 },
    { lat: 48.9216073, lng: 21.7950996 },
    { lat: 48.9196562, lng: 21.7951676 },
    { lat: 48.9181998, lng: 21.7954913 },
    { lat: 48.9182874, lng: 21.7965627 },
    { lat: 48.9184676, lng: 21.7972157 },
    { lat: 48.9180369, lng: 21.7976465 },
    { lat: 48.9177979, lng: 21.7978025 },
    { lat: 48.9170936, lng: 21.7986622 },
    { lat: 48.917228, lng: 21.8006271 },
    { lat: 48.917616, lng: 21.8007494 },
    { lat: 48.9173233, lng: 21.8018586 },
    { lat: 48.9162241, lng: 21.8027608 },
    { lat: 48.9156425, lng: 21.8033204 },
    { lat: 48.9152712, lng: 21.8048771 },
    { lat: 48.9141546, lng: 21.8045896 },
    { lat: 48.9136063, lng: 21.8051332 },
    { lat: 48.9136489, lng: 21.8060835 },
    { lat: 48.912177, lng: 21.807623 },
    { lat: 48.9120414, lng: 21.8076401 },
    { lat: 48.9124736, lng: 21.8092916 },
    { lat: 48.9127965, lng: 21.8096445 },
    { lat: 48.9139793, lng: 21.810391 },
    { lat: 48.913862, lng: 21.8133701 },
    { lat: 48.9137788, lng: 21.8134531 },
    { lat: 48.9118761, lng: 21.8088155 },
    { lat: 48.9100327, lng: 21.8102949 },
    { lat: 48.9093016, lng: 21.8111687 },
    { lat: 48.907963, lng: 21.8133788 },
    { lat: 48.9074603, lng: 21.8142095 },
    { lat: 48.9079283, lng: 21.8159814 },
    { lat: 48.9082438, lng: 21.8169589 },
    { lat: 48.9089223, lng: 21.8172685 },
    { lat: 48.9090992, lng: 21.8175387 },
    { lat: 48.9090847, lng: 21.819014 },
    { lat: 48.9104226, lng: 21.8204655 },
    { lat: 48.9108248, lng: 21.8205767 },
    { lat: 48.9119948, lng: 21.8214404 },
    { lat: 48.9135885, lng: 21.8229029 },
    { lat: 48.9139515, lng: 21.8227651 },
    { lat: 48.9153071, lng: 21.8249379 },
    { lat: 48.915461, lng: 21.8249291 },
  ],
  Rohožník: [
    { lat: 49.1187926, lng: 21.7729821 },
    { lat: 49.1190279, lng: 21.7722995 },
    { lat: 49.1191562, lng: 21.7717458 },
    { lat: 49.1193481, lng: 21.7713391 },
    { lat: 49.1193132, lng: 21.7706589 },
    { lat: 49.119936, lng: 21.7700826 },
    { lat: 49.1201742, lng: 21.7697718 },
    { lat: 49.1206066, lng: 21.768635 },
    { lat: 49.121061, lng: 21.7680306 },
    { lat: 49.1208181, lng: 21.7667873 },
    { lat: 49.121149, lng: 21.7667162 },
    { lat: 49.1218511, lng: 21.7661616 },
    { lat: 49.1222063, lng: 21.7656763 },
    { lat: 49.1222803, lng: 21.7653266 },
    { lat: 49.1225164, lng: 21.7647674 },
    { lat: 49.1222309, lng: 21.76482 },
    { lat: 49.1221696, lng: 21.7646223 },
    { lat: 49.1221395, lng: 21.7642122 },
    { lat: 49.121975, lng: 21.7632981 },
    { lat: 49.1221835, lng: 21.7623236 },
    { lat: 49.1222842, lng: 21.7620506 },
    { lat: 49.1223356, lng: 21.7616115 },
    { lat: 49.122562, lng: 21.7614547 },
    { lat: 49.1230915, lng: 21.7612665 },
    { lat: 49.1233165, lng: 21.7613091 },
    { lat: 49.1241823, lng: 21.7611284 },
    { lat: 49.1242559, lng: 21.7610575 },
    { lat: 49.124316, lng: 21.7608624 },
    { lat: 49.1246495, lng: 21.7607166 },
    { lat: 49.1248232, lng: 21.760562 },
    { lat: 49.1251125, lng: 21.7604384 },
    { lat: 49.1254659, lng: 21.760443 },
    { lat: 49.1256676, lng: 21.7603754 },
    { lat: 49.1264838, lng: 21.7596729 },
    { lat: 49.1270293, lng: 21.7589837 },
    { lat: 49.1271513, lng: 21.7580585 },
    { lat: 49.127381, lng: 21.7569615 },
    { lat: 49.1274431, lng: 21.7544984 },
    { lat: 49.127343, lng: 21.7544108 },
    { lat: 49.1273813, lng: 21.7533449 },
    { lat: 49.1274602, lng: 21.7530534 },
    { lat: 49.1275891, lng: 21.7518376 },
    { lat: 49.1275998, lng: 21.7511796 },
    { lat: 49.1271631, lng: 21.7506234 },
    { lat: 49.1270087, lng: 21.7499695 },
    { lat: 49.1272109, lng: 21.7491022 },
    { lat: 49.1273922, lng: 21.748683 },
    { lat: 49.1276179, lng: 21.7474846 },
    { lat: 49.127834, lng: 21.745932 },
    { lat: 49.123494, lng: 21.747904 },
    { lat: 49.12218, lng: 21.747542 },
    { lat: 49.121966, lng: 21.747929 },
    { lat: 49.121954, lng: 21.747976 },
    { lat: 49.121638, lng: 21.748119 },
    { lat: 49.121226, lng: 21.748093 },
    { lat: 49.121071, lng: 21.747977 },
    { lat: 49.120762, lng: 21.747728 },
    { lat: 49.120936, lng: 21.748522 },
    { lat: 49.120817, lng: 21.750421 },
    { lat: 49.120107, lng: 21.749957 },
    { lat: 49.119897, lng: 21.749819 },
    { lat: 49.119463, lng: 21.749564 },
    { lat: 49.119125, lng: 21.74921 },
    { lat: 49.118243, lng: 21.748609 },
    { lat: 49.115834, lng: 21.748706 },
    { lat: 49.115357, lng: 21.748435 },
    { lat: 49.112665, lng: 21.748618 },
    { lat: 49.112625, lng: 21.748558 },
    { lat: 49.111462, lng: 21.747785 },
    { lat: 49.111053, lng: 21.747799 },
    { lat: 49.110672, lng: 21.747814 },
    { lat: 49.10948, lng: 21.747861 },
    { lat: 49.109341, lng: 21.747866 },
    { lat: 49.108157, lng: 21.748171 },
    { lat: 49.10761, lng: 21.74828 },
    { lat: 49.107572, lng: 21.748287 },
    { lat: 49.107551, lng: 21.748291 },
    { lat: 49.106725, lng: 21.748509 },
    { lat: 49.106158, lng: 21.749358 },
    { lat: 49.105176, lng: 21.750057 },
    { lat: 49.104528, lng: 21.750783 },
    { lat: 49.103403, lng: 21.751623 },
    { lat: 49.102989, lng: 21.752374 },
    { lat: 49.1030016, lng: 21.7524295 },
    { lat: 49.1028067, lng: 21.7527186 },
    { lat: 49.1029664, lng: 21.7530521 },
    { lat: 49.1028177, lng: 21.7539009 },
    { lat: 49.1027901, lng: 21.754776 },
    { lat: 49.1026469, lng: 21.7556138 },
    { lat: 49.1029237, lng: 21.755785 },
    { lat: 49.103112, lng: 21.756956 },
    { lat: 49.1033113, lng: 21.7574335 },
    { lat: 49.1033273, lng: 21.7576997 },
    { lat: 49.1035319, lng: 21.7585484 },
    { lat: 49.1038113, lng: 21.758946 },
    { lat: 49.1040726, lng: 21.7591753 },
    { lat: 49.1040779, lng: 21.7595437 },
    { lat: 49.1043334, lng: 21.7599736 },
    { lat: 49.1043188, lng: 21.7602348 },
    { lat: 49.104498, lng: 21.7608905 },
    { lat: 49.1043174, lng: 21.7614153 },
    { lat: 49.1044, lng: 21.7617438 },
    { lat: 49.1049787, lng: 21.7621027 },
    { lat: 49.1053888, lng: 21.7625921 },
    { lat: 49.1053376, lng: 21.7628681 },
    { lat: 49.1055729, lng: 21.7629686 },
    { lat: 49.1056044, lng: 21.7633505 },
    { lat: 49.1057309, lng: 21.763536 },
    { lat: 49.1060751, lng: 21.7637523 },
    { lat: 49.1065884, lng: 21.7642775 },
    { lat: 49.1067814, lng: 21.7643899 },
    { lat: 49.1076175, lng: 21.7661557 },
    { lat: 49.1080505, lng: 21.7673482 },
    { lat: 49.108456, lng: 21.7675863 },
    { lat: 49.1088252, lng: 21.768064 },
    { lat: 49.1089957, lng: 21.7686486 },
    { lat: 49.1090895, lng: 21.7698622 },
    { lat: 49.1094683, lng: 21.7704976 },
    { lat: 49.109661, lng: 21.7705477 },
    { lat: 49.1098179, lng: 21.7704799 },
    { lat: 49.1101246, lng: 21.7700412 },
    { lat: 49.1105515, lng: 21.7701488 },
    { lat: 49.1109467, lng: 21.7701635 },
    { lat: 49.1122664, lng: 21.7705553 },
    { lat: 49.1133972, lng: 21.7706452 },
    { lat: 49.1139726, lng: 21.7712303 },
    { lat: 49.1140876, lng: 21.7714998 },
    { lat: 49.1142743, lng: 21.7715793 },
    { lat: 49.114492, lng: 21.7717711 },
    { lat: 49.1149585, lng: 21.7718896 },
    { lat: 49.1156979, lng: 21.77186 },
    { lat: 49.1161512, lng: 21.7717703 },
    { lat: 49.1164321, lng: 21.7715877 },
    { lat: 49.1165143, lng: 21.7716383 },
    { lat: 49.1168326, lng: 21.7715877 },
    { lat: 49.1170778, lng: 21.7717175 },
    { lat: 49.1173518, lng: 21.771963 },
    { lat: 49.117745, lng: 21.7721513 },
    { lat: 49.1185436, lng: 21.772752 },
    { lat: 49.1187926, lng: 21.7729821 },
  ],
  Gruzovce: [
    { lat: 48.9968892, lng: 21.8801714 },
    { lat: 48.99663, lng: 21.8788651 },
    { lat: 48.9964161, lng: 21.8785936 },
    { lat: 48.9963077, lng: 21.8783162 },
    { lat: 48.9957646, lng: 21.8778773 },
    { lat: 48.995494, lng: 21.8774518 },
    { lat: 48.9952886, lng: 21.8772525 },
    { lat: 48.9949481, lng: 21.8766438 },
    { lat: 48.9949356, lng: 21.8763748 },
    { lat: 48.9947917, lng: 21.8760161 },
    { lat: 48.9947979, lng: 21.8758628 },
    { lat: 48.9942215, lng: 21.8741646 },
    { lat: 48.994138, lng: 21.8741143 },
    { lat: 48.9940695, lng: 21.873941 },
    { lat: 48.9937767, lng: 21.8737938 },
    { lat: 48.9936017, lng: 21.8736286 },
    { lat: 48.9935192, lng: 21.8733995 },
    { lat: 48.9933525, lng: 21.8732693 },
    { lat: 48.9932577, lng: 21.8730717 },
    { lat: 48.9930925, lng: 21.8730276 },
    { lat: 48.9927704, lng: 21.8723563 },
    { lat: 48.9928096, lng: 21.8720338 },
    { lat: 48.9926934, lng: 21.8719295 },
    { lat: 48.9926679, lng: 21.8715513 },
    { lat: 48.9925907, lng: 21.8715269 },
    { lat: 48.992502, lng: 21.8716166 },
    { lat: 48.9922895, lng: 21.871177 },
    { lat: 48.9923017, lng: 21.8710463 },
    { lat: 48.9922347, lng: 21.8709668 },
    { lat: 48.9921734, lng: 21.8710417 },
    { lat: 48.9918246, lng: 21.8705442 },
    { lat: 48.9917503, lng: 21.8705432 },
    { lat: 48.9917239, lng: 21.8703939 },
    { lat: 48.9906508, lng: 21.8696865 },
    { lat: 48.9905322, lng: 21.8697121 },
    { lat: 48.990387, lng: 21.8695752 },
    { lat: 48.9901807, lng: 21.8695985 },
    { lat: 48.9900991, lng: 21.8695341 },
    { lat: 48.9899983, lng: 21.8693341 },
    { lat: 48.9897035, lng: 21.8690237 },
    { lat: 48.9895367, lng: 21.8687014 },
    { lat: 48.9894844, lng: 21.868721 },
    { lat: 48.9894431, lng: 21.8686461 },
    { lat: 48.9894476, lng: 21.8684721 },
    { lat: 48.9893046, lng: 21.8681939 },
    { lat: 48.9891433, lng: 21.8682661 },
    { lat: 48.9889626, lng: 21.8680676 },
    { lat: 48.9889058, lng: 21.8681687 },
    { lat: 48.9887755, lng: 21.8678755 },
    { lat: 48.9886143, lng: 21.8678126 },
    { lat: 48.9884962, lng: 21.8673292 },
    { lat: 48.987966, lng: 21.8667837 },
    { lat: 48.9877313, lng: 21.8662295 },
    { lat: 48.9875117, lng: 21.8661332 },
    { lat: 48.9870587, lng: 21.8656186 },
    { lat: 48.9869074, lng: 21.8655434 },
    { lat: 48.9868668, lng: 21.8656718 },
    { lat: 48.9865316, lng: 21.8652367 },
    { lat: 48.986367, lng: 21.8651769 },
    { lat: 48.9859689, lng: 21.8648755 },
    { lat: 48.9859046, lng: 21.864996 },
    { lat: 48.9858355, lng: 21.8648865 },
    { lat: 48.9857376, lng: 21.8641803 },
    { lat: 48.9855777, lng: 21.8641765 },
    { lat: 48.9854025, lng: 21.8640535 },
    { lat: 48.9850757, lng: 21.864033 },
    { lat: 48.9849556, lng: 21.8639531 },
    { lat: 48.9849594, lng: 21.8637956 },
    { lat: 48.9849074, lng: 21.8637002 },
    { lat: 48.9840368, lng: 21.863519 },
    { lat: 48.9839884, lng: 21.8632139 },
    { lat: 48.9840541, lng: 21.8627476 },
    { lat: 48.9840301, lng: 21.8626835 },
    { lat: 48.9838227, lng: 21.8622724 },
    { lat: 48.983655, lng: 21.8621824 },
    { lat: 48.9834917, lng: 21.8620011 },
    { lat: 48.9833374, lng: 21.8616818 },
    { lat: 48.9832173, lng: 21.8616509 },
    { lat: 48.9823287, lng: 21.8602421 },
    { lat: 48.9822121, lng: 21.8599019 },
    { lat: 48.9821642, lng: 21.8595301 },
    { lat: 48.9820228, lng: 21.859051 },
    { lat: 48.9812167, lng: 21.8590724 },
    { lat: 48.9804003, lng: 21.8587736 },
    { lat: 48.9801408, lng: 21.8588326 },
    { lat: 48.9800629, lng: 21.8587816 },
    { lat: 48.9799824, lng: 21.858843 },
    { lat: 48.9798044, lng: 21.8587861 },
    { lat: 48.9786301, lng: 21.858835 },
    { lat: 48.9784642, lng: 21.8589003 },
    { lat: 48.976783, lng: 21.8587881 },
    { lat: 48.9762841, lng: 21.8589053 },
    { lat: 48.9751689, lng: 21.8590136 },
    { lat: 48.9745565, lng: 21.859265 },
    { lat: 48.9743633, lng: 21.8592305 },
    { lat: 48.9740327, lng: 21.8593653 },
    { lat: 48.9736035, lng: 21.8593476 },
    { lat: 48.9733996, lng: 21.8591534 },
    { lat: 48.9728848, lng: 21.8600048 },
    { lat: 48.9729227, lng: 21.8603456 },
    { lat: 48.9725906, lng: 21.8608636 },
    { lat: 48.9720896, lng: 21.8613371 },
    { lat: 48.971127, lng: 21.8619241 },
    { lat: 48.9707277, lng: 21.8618319 },
    { lat: 48.9705154, lng: 21.8620217 },
    { lat: 48.9697763, lng: 21.8623302 },
    { lat: 48.9691686, lng: 21.8624476 },
    { lat: 48.9687412, lng: 21.8610915 },
    { lat: 48.9680983, lng: 21.8611522 },
    { lat: 48.9670591, lng: 21.8611193 },
    { lat: 48.9663154, lng: 21.8608903 },
    { lat: 48.9662251, lng: 21.8609726 },
    { lat: 48.9660987, lng: 21.8616972 },
    { lat: 48.9657223, lng: 21.8611483 },
    { lat: 48.9656326, lng: 21.8612893 },
    { lat: 48.9654595, lng: 21.8613796 },
    { lat: 48.9653282, lng: 21.8617006 },
    { lat: 48.9651269, lng: 21.8619688 },
    { lat: 48.964934, lng: 21.8626958 },
    { lat: 48.9643047, lng: 21.8641327 },
    { lat: 48.9639508, lng: 21.8654326 },
    { lat: 48.9640283, lng: 21.8660839 },
    { lat: 48.9641208, lng: 21.8676405 },
    { lat: 48.9641351, lng: 21.8691762 },
    { lat: 48.9639452, lng: 21.8718333 },
    { lat: 48.9639295, lng: 21.8720526 },
    { lat: 48.9640367, lng: 21.8732162 },
    { lat: 48.9639021, lng: 21.8743407 },
    { lat: 48.9640312, lng: 21.8749929 },
    { lat: 48.9643569, lng: 21.8749259 },
    { lat: 48.964996, lng: 21.8751505 },
    { lat: 48.96626, lng: 21.8760258 },
    { lat: 48.9685025, lng: 21.8766212 },
    { lat: 48.9688026, lng: 21.8763913 },
    { lat: 48.9689921, lng: 21.8766798 },
    { lat: 48.9701461, lng: 21.8760998 },
    { lat: 48.9709189, lng: 21.8774685 },
    { lat: 48.9713775, lng: 21.8773227 },
    { lat: 48.9715892, lng: 21.8774661 },
    { lat: 48.9717656, lng: 21.8781178 },
    { lat: 48.9742944, lng: 21.8777269 },
    { lat: 48.9762197, lng: 21.8769068 },
    { lat: 48.9771783, lng: 21.8768213 },
    { lat: 48.9787048, lng: 21.8763838 },
    { lat: 48.9800011, lng: 21.8758666 },
    { lat: 48.9804706, lng: 21.8772575 },
    { lat: 48.9812327, lng: 21.8778987 },
    { lat: 48.9813407, lng: 21.8778075 },
    { lat: 48.9815197, lng: 21.8778178 },
    { lat: 48.9817096, lng: 21.8775463 },
    { lat: 48.9820603, lng: 21.8773599 },
    { lat: 48.9823221, lng: 21.8771243 },
    { lat: 48.9825939, lng: 21.8767972 },
    { lat: 48.9827718, lng: 21.876444 },
    { lat: 48.9830777, lng: 21.8761797 },
    { lat: 48.9834983, lng: 21.8762592 },
    { lat: 48.9838722, lng: 21.8765489 },
    { lat: 48.9839633, lng: 21.8768114 },
    { lat: 48.9842039, lng: 21.8769832 },
    { lat: 48.9846652, lng: 21.8770762 },
    { lat: 48.985492, lng: 21.8774277 },
    { lat: 48.9858712, lng: 21.8779635 },
    { lat: 48.9862433, lng: 21.8783088 },
    { lat: 48.9862112, lng: 21.8786445 },
    { lat: 48.9865404, lng: 21.8795124 },
    { lat: 48.9862776, lng: 21.8798856 },
    { lat: 48.9863697, lng: 21.8803651 },
    { lat: 48.9871561, lng: 21.880861 },
    { lat: 48.9873321, lng: 21.8811909 },
    { lat: 48.9879681, lng: 21.881648 },
    { lat: 48.9881335, lng: 21.881961 },
    { lat: 48.9881178, lng: 21.8828953 },
    { lat: 48.9881609, lng: 21.8833939 },
    { lat: 48.9883469, lng: 21.8837218 },
    { lat: 48.9883671, lng: 21.8842117 },
    { lat: 48.9885738, lng: 21.8849575 },
    { lat: 48.9889419, lng: 21.8854539 },
    { lat: 48.9893192, lng: 21.8855626 },
    { lat: 48.9894317, lng: 21.8846734 },
    { lat: 48.9904113, lng: 21.8854618 },
    { lat: 48.9904879, lng: 21.8854706 },
    { lat: 48.9906842, lng: 21.8860254 },
    { lat: 48.9908261, lng: 21.8866121 },
    { lat: 48.9909976, lng: 21.887923 },
    { lat: 48.9910613, lng: 21.8890161 },
    { lat: 48.9913841, lng: 21.8891867 },
    { lat: 48.991523, lng: 21.8895726 },
    { lat: 48.992426, lng: 21.8887584 },
    { lat: 48.9930251, lng: 21.8883838 },
    { lat: 48.9939559, lng: 21.8875984 },
    { lat: 48.9947928, lng: 21.8871153 },
    { lat: 48.9953942, lng: 21.8868785 },
    { lat: 48.9956249, lng: 21.8866974 },
    { lat: 48.9959621, lng: 21.8867329 },
    { lat: 48.9957502, lng: 21.8861839 },
    { lat: 48.9961285, lng: 21.885574 },
    { lat: 48.9959938, lng: 21.8845666 },
    { lat: 48.996305, lng: 21.8832635 },
    { lat: 48.996169, lng: 21.8830884 },
    { lat: 48.9946286, lng: 21.8818248 },
    { lat: 48.9940909, lng: 21.881032 },
    { lat: 48.9947395, lng: 21.8807888 },
    { lat: 48.9953844, lng: 21.8807123 },
    { lat: 48.9959443, lng: 21.8805671 },
    { lat: 48.9968892, lng: 21.8801714 },
  ],
  Koškovce: [
    { lat: 49.0714435, lng: 21.9130102 },
    { lat: 49.0709596, lng: 21.9113816 },
    { lat: 49.0713027, lng: 21.9107123 },
    { lat: 49.071384, lng: 21.9101447 },
    { lat: 49.071186, lng: 21.9095158 },
    { lat: 49.0708995, lng: 21.9082223 },
    { lat: 49.0707177, lng: 21.9064438 },
    { lat: 49.0705858, lng: 21.9055868 },
    { lat: 49.067559, lng: 21.9037532 },
    { lat: 49.0669095, lng: 21.9054358 },
    { lat: 49.0664794, lng: 21.9067152 },
    { lat: 49.0660371, lng: 21.9074734 },
    { lat: 49.0650571, lng: 21.9075042 },
    { lat: 49.0646372, lng: 21.9073141 },
    { lat: 49.0643199, lng: 21.9067284 },
    { lat: 49.0640277, lng: 21.9067418 },
    { lat: 49.0628031, lng: 21.9075544 },
    { lat: 49.0620757, lng: 21.9069894 },
    { lat: 49.0618756, lng: 21.9069924 },
    { lat: 49.0613246, lng: 21.907636 },
    { lat: 49.0610763, lng: 21.907686 },
    { lat: 49.0606702, lng: 21.908093 },
    { lat: 49.0604532, lng: 21.9084542 },
    { lat: 49.0599234, lng: 21.9095732 },
    { lat: 49.0597603, lng: 21.9101096 },
    { lat: 49.0593384, lng: 21.9103349 },
    { lat: 49.0592552, lng: 21.9106927 },
    { lat: 49.0593214, lng: 21.9111809 },
    { lat: 49.0592131, lng: 21.9118342 },
    { lat: 49.0591218, lng: 21.9133553 },
    { lat: 49.0593493, lng: 21.914044 },
    { lat: 49.0593404, lng: 21.9145868 },
    { lat: 49.0587579, lng: 21.9165319 },
    { lat: 49.0583177, lng: 21.9172338 },
    { lat: 49.0583155, lng: 21.9173254 },
    { lat: 49.0573309, lng: 21.9176729 },
    { lat: 49.0549046, lng: 21.9195625 },
    { lat: 49.0537495, lng: 21.9198771 },
    { lat: 49.0535699, lng: 21.9201138 },
    { lat: 49.0533392, lng: 21.920153 },
    { lat: 49.0530708, lng: 21.9204012 },
    { lat: 49.0527919, lng: 21.9202675 },
    { lat: 49.0522565, lng: 21.9205832 },
    { lat: 49.0520118, lng: 21.9206454 },
    { lat: 49.0520256, lng: 21.9208418 },
    { lat: 49.0517249, lng: 21.9210973 },
    { lat: 49.0515305, lng: 21.9208726 },
    { lat: 49.050726, lng: 21.9213634 },
    { lat: 49.0502143, lng: 21.921545 },
    { lat: 49.0498631, lng: 21.9219408 },
    { lat: 49.0494642, lng: 21.9220962 },
    { lat: 49.0492187, lng: 21.9223951 },
    { lat: 49.0489276, lng: 21.922546 },
    { lat: 49.0489293, lng: 21.92284 },
    { lat: 49.048858, lng: 21.9229972 },
    { lat: 49.0486835, lng: 21.9230403 },
    { lat: 49.0483189, lng: 21.9233902 },
    { lat: 49.0480755, lng: 21.9233745 },
    { lat: 49.0478452, lng: 21.9237062 },
    { lat: 49.0477013, lng: 21.9237027 },
    { lat: 49.0475295, lng: 21.923808 },
    { lat: 49.0470944, lng: 21.9246758 },
    { lat: 49.0470527, lng: 21.924585 },
    { lat: 49.0470597, lng: 21.9244587 },
    { lat: 49.0469181, lng: 21.9244601 },
    { lat: 49.0466997, lng: 21.9245878 },
    { lat: 49.0465274, lng: 21.9248032 },
    { lat: 49.0463455, lng: 21.925268 },
    { lat: 49.0457534, lng: 21.9261972 },
    { lat: 49.0451872, lng: 21.9272639 },
    { lat: 49.0448597, lng: 21.9280728 },
    { lat: 49.0448288, lng: 21.9280545 },
    { lat: 49.0447083, lng: 21.928402 },
    { lat: 49.0446107, lng: 21.9290036 },
    { lat: 49.0444433, lng: 21.9293234 },
    { lat: 49.0443537, lng: 21.9292475 },
    { lat: 49.0442462, lng: 21.9293136 },
    { lat: 49.0440438, lng: 21.9291854 },
    { lat: 49.0439503, lng: 21.929274 },
    { lat: 49.0438629, lng: 21.9295967 },
    { lat: 49.0438685, lng: 21.9297714 },
    { lat: 49.0439544, lng: 21.9297976 },
    { lat: 49.0440406, lng: 21.9299551 },
    { lat: 49.0439223, lng: 21.9301745 },
    { lat: 49.0439298, lng: 21.9307019 },
    { lat: 49.0438051, lng: 21.9317041 },
    { lat: 49.0434625, lng: 21.9319002 },
    { lat: 49.0433326, lng: 21.9323821 },
    { lat: 49.0433086, lng: 21.9326753 },
    { lat: 49.0434863, lng: 21.932636 },
    { lat: 49.0435684, lng: 21.9327726 },
    { lat: 49.0436758, lng: 21.933519 },
    { lat: 49.0432492, lng: 21.9336791 },
    { lat: 49.0430459, lng: 21.9341013 },
    { lat: 49.0430661, lng: 21.9346011 },
    { lat: 49.0431086, lng: 21.9346821 },
    { lat: 49.0426374, lng: 21.9351341 },
    { lat: 49.0424113, lng: 21.9350453 },
    { lat: 49.042299, lng: 21.9351389 },
    { lat: 49.0423266, lng: 21.9356594 },
    { lat: 49.0421799, lng: 21.9359614 },
    { lat: 49.0420673, lng: 21.9361809 },
    { lat: 49.0418248, lng: 21.9361152 },
    { lat: 49.0418507, lng: 21.9364748 },
    { lat: 49.0420842, lng: 21.9365492 },
    { lat: 49.042337, lng: 21.9368556 },
    { lat: 49.0422908, lng: 21.9370271 },
    { lat: 49.0418877, lng: 21.9371948 },
    { lat: 49.0417131, lng: 21.9374565 },
    { lat: 49.0413318, lng: 21.9373246 },
    { lat: 49.0412268, lng: 21.9373493 },
    { lat: 49.0411776, lng: 21.9376357 },
    { lat: 49.0412287, lng: 21.9379293 },
    { lat: 49.0413123, lng: 21.938031 },
    { lat: 49.0414713, lng: 21.9380466 },
    { lat: 49.0413096, lng: 21.9386883 },
    { lat: 49.0411553, lng: 21.9389955 },
    { lat: 49.0408809, lng: 21.9391841 },
    { lat: 49.0408128, lng: 21.9392912 },
    { lat: 49.0406508, lng: 21.9398408 },
    { lat: 49.0401836, lng: 21.9402269 },
    { lat: 49.0399794, lng: 21.9411547 },
    { lat: 49.0398234, lng: 21.9414888 },
    { lat: 49.0396989, lng: 21.941553 },
    { lat: 49.0395912, lng: 21.9417936 },
    { lat: 49.0394676, lng: 21.9418348 },
    { lat: 49.0394122, lng: 21.9420567 },
    { lat: 49.0394671, lng: 21.9423195 },
    { lat: 49.0393844, lng: 21.9427036 },
    { lat: 49.0394478, lng: 21.9430355 },
    { lat: 49.0393554, lng: 21.9431674 },
    { lat: 49.0390312, lng: 21.9432021 },
    { lat: 49.0389768, lng: 21.9434114 },
    { lat: 49.0390327, lng: 21.94426 },
    { lat: 49.0389287, lng: 21.944371 },
    { lat: 49.038684, lng: 21.9453032 },
    { lat: 49.0387176, lng: 21.9456111 },
    { lat: 49.0385642, lng: 21.9462799 },
    { lat: 49.038439, lng: 21.9466298 },
    { lat: 49.0376371, lng: 21.9616147 },
    { lat: 49.0373729, lng: 21.961894 },
    { lat: 49.0374084, lng: 21.9643911 },
    { lat: 49.0375284, lng: 21.9658391 },
    { lat: 49.0382222, lng: 21.9661122 },
    { lat: 49.0381942, lng: 21.9677992 },
    { lat: 49.0383309, lng: 21.9685206 },
    { lat: 49.0391969, lng: 21.9691992 },
    { lat: 49.0398231, lng: 21.9695045 },
    { lat: 49.0404422, lng: 21.9707113 },
    { lat: 49.0406853, lng: 21.9706767 },
    { lat: 49.041265, lng: 21.9724274 },
    { lat: 49.0424088, lng: 21.9738457 },
    { lat: 49.0429912, lng: 21.9746646 },
    { lat: 49.0432432, lng: 21.9753957 },
    { lat: 49.0435789, lng: 21.9758793 },
    { lat: 49.0442332, lng: 21.9763696 },
    { lat: 49.0444973, lng: 21.976776 },
    { lat: 49.0447908, lng: 21.9770498 },
    { lat: 49.0460483, lng: 21.9768591 },
    { lat: 49.0462151, lng: 21.9777514 },
    { lat: 49.0463506, lng: 21.9791439 },
    { lat: 49.046453, lng: 21.979696 },
    { lat: 49.0465002, lng: 21.9811728 },
    { lat: 49.0463513, lng: 21.9816596 },
    { lat: 49.0462781, lng: 21.9823002 },
    { lat: 49.0464917, lng: 21.9826172 },
    { lat: 49.0471234, lng: 21.9838455 },
    { lat: 49.0472876, lng: 21.9843421 },
    { lat: 49.0474786, lng: 21.9865451 },
    { lat: 49.0479688, lng: 21.9876911 },
    { lat: 49.0483115, lng: 21.9881364 },
    { lat: 49.0482829, lng: 21.989175 },
    { lat: 49.0483104, lng: 21.9903048 },
    { lat: 49.0485028, lng: 21.9907232 },
    { lat: 49.0487877, lng: 21.9911557 },
    { lat: 49.0489529, lng: 21.9911926 },
    { lat: 49.0492448, lng: 21.9908498 },
    { lat: 49.0493909, lng: 21.9905676 },
    { lat: 49.0496687, lng: 21.9902827 },
    { lat: 49.050071, lng: 21.9893925 },
    { lat: 49.0503612, lng: 21.9889803 },
    { lat: 49.0504167, lng: 21.9887222 },
    { lat: 49.0511584, lng: 21.9875407 },
    { lat: 49.0521673, lng: 21.9873069 },
    { lat: 49.0524678, lng: 21.9861151 },
    { lat: 49.0524318, lng: 21.984961 },
    { lat: 49.0522557, lng: 21.9835982 },
    { lat: 49.0523401, lng: 21.983328 },
    { lat: 49.0527705, lng: 21.9829437 },
    { lat: 49.053595, lng: 21.9819793 },
    { lat: 49.0538098, lng: 21.9779779 },
    { lat: 49.0538136, lng: 21.9754286 },
    { lat: 49.0538657, lng: 21.9748881 },
    { lat: 49.0539312, lng: 21.974898 },
    { lat: 49.0539529, lng: 21.9747755 },
    { lat: 49.0539681, lng: 21.9724619 },
    { lat: 49.0539027, lng: 21.9718506 },
    { lat: 49.0539507, lng: 21.9714247 },
    { lat: 49.0539665, lng: 21.9699674 },
    { lat: 49.0540158, lng: 21.9694041 },
    { lat: 49.0539789, lng: 21.9689092 },
    { lat: 49.0541039, lng: 21.9679983 },
    { lat: 49.0541334, lng: 21.9666852 },
    { lat: 49.0540586, lng: 21.9664811 },
    { lat: 49.0542823, lng: 21.9656966 },
    { lat: 49.0547845, lng: 21.9655292 },
    { lat: 49.0548238, lng: 21.9657113 },
    { lat: 49.0551014, lng: 21.9656578 },
    { lat: 49.0552417, lng: 21.9653744 },
    { lat: 49.0557539, lng: 21.9655264 },
    { lat: 49.0563862, lng: 21.965258 },
    { lat: 49.0588435, lng: 21.9634571 },
    { lat: 49.0592037, lng: 21.9618692 },
    { lat: 49.0592861, lng: 21.9610134 },
    { lat: 49.059375, lng: 21.9608355 },
    { lat: 49.0595943, lng: 21.9600544 },
    { lat: 49.0596602, lng: 21.9599918 },
    { lat: 49.0600721, lng: 21.9602746 },
    { lat: 49.0607972, lng: 21.9601148 },
    { lat: 49.0615667, lng: 21.9597589 },
    { lat: 49.0617434, lng: 21.9588934 },
    { lat: 49.0617326, lng: 21.9583248 },
    { lat: 49.0618662, lng: 21.9574021 },
    { lat: 49.0614337, lng: 21.9571919 },
    { lat: 49.0613274, lng: 21.9572116 },
    { lat: 49.0612236, lng: 21.9573804 },
    { lat: 49.0610463, lng: 21.9573987 },
    { lat: 49.0609374, lng: 21.9565868 },
    { lat: 49.0613715, lng: 21.9559914 },
    { lat: 49.0614172, lng: 21.9558345 },
    { lat: 49.0612984, lng: 21.9553101 },
    { lat: 49.0614958, lng: 21.9545133 },
    { lat: 49.0614728, lng: 21.9541244 },
    { lat: 49.0616397, lng: 21.9538326 },
    { lat: 49.061637, lng: 21.9532614 },
    { lat: 49.0617201, lng: 21.9529829 },
    { lat: 49.0617402, lng: 21.9525173 },
    { lat: 49.0616718, lng: 21.9523444 },
    { lat: 49.0616795, lng: 21.9517493 },
    { lat: 49.0615452, lng: 21.9510052 },
    { lat: 49.0616265, lng: 21.9505575 },
    { lat: 49.0619613, lng: 21.9498666 },
    { lat: 49.0619415, lng: 21.9495617 },
    { lat: 49.0620455, lng: 21.9493542 },
    { lat: 49.0620045, lng: 21.9491543 },
    { lat: 49.0620568, lng: 21.9481304 },
    { lat: 49.0623269, lng: 21.9472756 },
    { lat: 49.0624757, lng: 21.9470594 },
    { lat: 49.0625875, lng: 21.9466613 },
    { lat: 49.0627135, lng: 21.94603 },
    { lat: 49.0631986, lng: 21.9444491 },
    { lat: 49.0633237, lng: 21.943553 },
    { lat: 49.0633203, lng: 21.941551 },
    { lat: 49.0637957, lng: 21.9399067 },
    { lat: 49.0641212, lng: 21.9380637 },
    { lat: 49.0643626, lng: 21.9372746 },
    { lat: 49.0646009, lng: 21.9368578 },
    { lat: 49.0646609, lng: 21.9366072 },
    { lat: 49.0655354, lng: 21.9359467 },
    { lat: 49.0665009, lng: 21.9347913 },
    { lat: 49.0672013, lng: 21.9338273 },
    { lat: 49.0676803, lng: 21.9333624 },
    { lat: 49.0683452, lng: 21.9328721 },
    { lat: 49.0694253, lng: 21.9323421 },
    { lat: 49.0700324, lng: 21.9319218 },
    { lat: 49.0706442, lng: 21.9318235 },
    { lat: 49.0709649, lng: 21.9318789 },
    { lat: 49.071352, lng: 21.9317464 },
    { lat: 49.0714265, lng: 21.9318426 },
    { lat: 49.072717, lng: 21.9312771 },
    { lat: 49.073792, lng: 21.9309884 },
    { lat: 49.0753941, lng: 21.9283874 },
    { lat: 49.0737084, lng: 21.9276803 },
    { lat: 49.0720164, lng: 21.9287452 },
    { lat: 49.0708591, lng: 21.9264076 },
    { lat: 49.0699251, lng: 21.9239069 },
    { lat: 49.0689171, lng: 21.9220965 },
    { lat: 49.0663547, lng: 21.9183572 },
    { lat: 49.0661074, lng: 21.9170661 },
    { lat: 49.0668374, lng: 21.9164352 },
    { lat: 49.0688263, lng: 21.9142382 },
    { lat: 49.0693822, lng: 21.913839 },
    { lat: 49.0702812, lng: 21.9134411 },
    { lat: 49.070648, lng: 21.9135389 },
    { lat: 49.0713028, lng: 21.9132767 },
    { lat: 49.0714435, lng: 21.9130102 },
  ],
  Kochanovce: [
    { lat: 48.9418566, lng: 21.9425314 },
    { lat: 48.9419099, lng: 21.9426014 },
    { lat: 48.9421843, lng: 21.9429642 },
    { lat: 48.9424083, lng: 21.9436878 },
    { lat: 48.9429565, lng: 21.9445174 },
    { lat: 48.9431329, lng: 21.9446941 },
    { lat: 48.9437548, lng: 21.9450453 },
    { lat: 48.9445446, lng: 21.9457745 },
    { lat: 48.9446586, lng: 21.9462468 },
    { lat: 48.9447225, lng: 21.9463308 },
    { lat: 48.9448461, lng: 21.9463742 },
    { lat: 48.9450199, lng: 21.9463024 },
    { lat: 48.9457518, lng: 21.9467866 },
    { lat: 48.9461822, lng: 21.9468611 },
    { lat: 48.9470245, lng: 21.947757 },
    { lat: 48.9470374, lng: 21.9491426 },
    { lat: 48.9475832, lng: 21.9496507 },
    { lat: 48.9478529, lng: 21.9498164 },
    { lat: 48.9478395, lng: 21.949912 },
    { lat: 48.9481001, lng: 21.9499959 },
    { lat: 48.948369, lng: 21.950172 },
    { lat: 48.9488048, lng: 21.9502874 },
    { lat: 48.9490556, lng: 21.9539101 },
    { lat: 48.9492811, lng: 21.9558892 },
    { lat: 48.948937, lng: 21.9627242 },
    { lat: 48.9489081, lng: 21.9641051 },
    { lat: 48.9493692, lng: 21.9681229 },
    { lat: 48.9494787, lng: 21.9680913 },
    { lat: 48.9494821, lng: 21.9690062 },
    { lat: 48.9496782, lng: 21.9692048 },
    { lat: 48.9503725, lng: 21.9696714 },
    { lat: 48.9501838, lng: 21.970278 },
    { lat: 48.9496846, lng: 21.9710643 },
    { lat: 48.9490914, lng: 21.9721923 },
    { lat: 48.9494349, lng: 21.9729398 },
    { lat: 48.9491561, lng: 21.9736334 },
    { lat: 48.9494493, lng: 21.9741225 },
    { lat: 48.949823, lng: 21.9745941 },
    { lat: 48.9498781, lng: 21.974824 },
    { lat: 48.9500445, lng: 21.9751781 },
    { lat: 48.9501246, lng: 21.9751979 },
    { lat: 48.9506046, lng: 21.9744521 },
    { lat: 48.9511158, lng: 21.9744214 },
    { lat: 48.9568409, lng: 21.9739092 },
    { lat: 48.957095, lng: 21.9729882 },
    { lat: 48.9581733, lng: 21.9690337 },
    { lat: 48.9582989, lng: 21.9687453 },
    { lat: 48.9588054, lng: 21.9667922 },
    { lat: 48.9589608, lng: 21.9653419 },
    { lat: 48.9594468, lng: 21.963148 },
    { lat: 48.9595085, lng: 21.9626416 },
    { lat: 48.9595375, lng: 21.961725 },
    { lat: 48.9595235, lng: 21.9588246 },
    { lat: 48.9595922, lng: 21.9563319 },
    { lat: 48.9607618, lng: 21.9563207 },
    { lat: 48.9607802, lng: 21.9552812 },
    { lat: 48.9612911, lng: 21.955343 },
    { lat: 48.9628342, lng: 21.9558829 },
    { lat: 48.9628456, lng: 21.9554308 },
    { lat: 48.9641184, lng: 21.9560459 },
    { lat: 48.9649644, lng: 21.9545875 },
    { lat: 48.9650469, lng: 21.9529307 },
    { lat: 48.9650777, lng: 21.9479511 },
    { lat: 48.9643819, lng: 21.9481516 },
    { lat: 48.9642289, lng: 21.9476112 },
    { lat: 48.9646291, lng: 21.9463239 },
    { lat: 48.9663628, lng: 21.9442915 },
    { lat: 48.9669482, lng: 21.9424536 },
    { lat: 48.9671683, lng: 21.9420354 },
    { lat: 48.9672825, lng: 21.9416998 },
    { lat: 48.9675204, lng: 21.9413135 },
    { lat: 48.9677059, lng: 21.9411214 },
    { lat: 48.9679439, lng: 21.9404741 },
    { lat: 48.9682799, lng: 21.9377016 },
    { lat: 48.968192, lng: 21.9365177 },
    { lat: 48.9682303, lng: 21.9359341 },
    { lat: 48.9681932, lng: 21.9356201 },
    { lat: 48.9682372, lng: 21.9353988 },
    { lat: 48.9681696, lng: 21.9352123 },
    { lat: 48.9680579, lng: 21.9352008 },
    { lat: 48.9680796, lng: 21.9349865 },
    { lat: 48.9679951, lng: 21.9349817 },
    { lat: 48.9678193, lng: 21.9349624 },
    { lat: 48.9677291, lng: 21.9348738 },
    { lat: 48.967298, lng: 21.9342674 },
    { lat: 48.9672201, lng: 21.9340814 },
    { lat: 48.9671424, lng: 21.9336208 },
    { lat: 48.9644412, lng: 21.9335605 },
    { lat: 48.9624809, lng: 21.9323537 },
    { lat: 48.9617604, lng: 21.9320654 },
    { lat: 48.960693, lng: 21.9320709 },
    { lat: 48.958096, lng: 21.932532 },
    { lat: 48.9574135, lng: 21.9327269 },
    { lat: 48.956436, lng: 21.9332159 },
    { lat: 48.9550873, lng: 21.934128 },
    { lat: 48.9548001, lng: 21.9343815 },
    { lat: 48.9540549, lng: 21.9344533 },
    { lat: 48.9507513, lng: 21.9359472 },
    { lat: 48.9501709, lng: 21.9360374 },
    { lat: 48.9496444, lng: 21.9362219 },
    { lat: 48.9464814, lng: 21.9375793 },
    { lat: 48.9436889, lng: 21.9384133 },
    { lat: 48.9433154, lng: 21.9395927 },
    { lat: 48.9418566, lng: 21.9425314 },
  ],
  Zubné: [
    { lat: 49.075049, lng: 22.080114 },
    { lat: 49.0747688, lng: 22.0794982 },
    { lat: 49.07463, lng: 22.0785196 },
    { lat: 49.074536, lng: 22.0782094 },
    { lat: 49.0745449, lng: 22.0773899 },
    { lat: 49.0741854, lng: 22.0764098 },
    { lat: 49.0737535, lng: 22.0755556 },
    { lat: 49.0737234, lng: 22.0754686 },
    { lat: 49.0737847, lng: 22.0752131 },
    { lat: 49.0736764, lng: 22.0750461 },
    { lat: 49.0732299, lng: 22.0747236 },
    { lat: 49.0732482, lng: 22.0745422 },
    { lat: 49.0726865, lng: 22.0732826 },
    { lat: 49.0725236, lng: 22.0722074 },
    { lat: 49.072517, lng: 22.0696478 },
    { lat: 49.0723782, lng: 22.0667741 },
    { lat: 49.0727231, lng: 22.0664707 },
    { lat: 49.0734891, lng: 22.066053 },
    { lat: 49.0727437, lng: 22.0610432 },
    { lat: 49.0721606, lng: 22.0598822 },
    { lat: 49.0720988, lng: 22.0577117 },
    { lat: 49.0719014, lng: 22.0575468 },
    { lat: 49.0717796, lng: 22.057339 },
    { lat: 49.0718891, lng: 22.0562786 },
    { lat: 49.0719831, lng: 22.0561063 },
    { lat: 49.0719467, lng: 22.0557642 },
    { lat: 49.0720503, lng: 22.0551598 },
    { lat: 49.0722495, lng: 22.0548733 },
    { lat: 49.0722747, lng: 22.0547026 },
    { lat: 49.0721564, lng: 22.0545541 },
    { lat: 49.0721628, lng: 22.0544006 },
    { lat: 49.0723207, lng: 22.0540902 },
    { lat: 49.0722842, lng: 22.0534106 },
    { lat: 49.0723584, lng: 22.0529358 },
    { lat: 49.0725314, lng: 22.0524011 },
    { lat: 49.0724583, lng: 22.0520737 },
    { lat: 49.0724139, lng: 22.0520987 },
    { lat: 49.0722781, lng: 22.0513059 },
    { lat: 49.0718851, lng: 22.0504408 },
    { lat: 49.0712975, lng: 22.0461843 },
    { lat: 49.0711364, lng: 22.044571 },
    { lat: 49.0708296, lng: 22.0423176 },
    { lat: 49.0708412, lng: 22.0417319 },
    { lat: 49.0707356, lng: 22.0411666 },
    { lat: 49.0707556, lng: 22.0404585 },
    { lat: 49.0703062, lng: 22.0391102 },
    { lat: 49.070043, lng: 22.0386922 },
    { lat: 49.069667, lng: 22.0377348 },
    { lat: 49.0693642, lng: 22.0373029 },
    { lat: 49.0691658, lng: 22.0368163 },
    { lat: 49.0688996, lng: 22.0364573 },
    { lat: 49.0685731, lng: 22.0362595 },
    { lat: 49.0683815, lng: 22.0357658 },
    { lat: 49.0685271, lng: 22.0350364 },
    { lat: 49.0684816, lng: 22.034645 },
    { lat: 49.0685634, lng: 22.0338409 },
    { lat: 49.0682339, lng: 22.0325252 },
    { lat: 49.068259, lng: 22.0318795 },
    { lat: 49.068164, lng: 22.0310174 },
    { lat: 49.067986, lng: 22.0305424 },
    { lat: 49.0664409, lng: 22.0308346 },
    { lat: 49.0658205, lng: 22.0310854 },
    { lat: 49.0652795, lng: 22.03174 },
    { lat: 49.0649087, lng: 22.0319913 },
    { lat: 49.0645021, lng: 22.0320609 },
    { lat: 49.0642658, lng: 22.0319678 },
    { lat: 49.063859, lng: 22.0315145 },
    { lat: 49.0635655, lng: 22.0317966 },
    { lat: 49.0634709, lng: 22.0317434 },
    { lat: 49.0629655, lng: 22.0313996 },
    { lat: 49.062827, lng: 22.0311566 },
    { lat: 49.0622205, lng: 22.0306821 },
    { lat: 49.0613788, lng: 22.0302899 },
    { lat: 49.0610501, lng: 22.0295776 },
    { lat: 49.0608159, lng: 22.0292886 },
    { lat: 49.0600058, lng: 22.0286214 },
    { lat: 49.0595634, lng: 22.0280898 },
    { lat: 49.0594431, lng: 22.0280474 },
    { lat: 49.0587875, lng: 22.0273548 },
    { lat: 49.0585111, lng: 22.0267619 },
    { lat: 49.0580441, lng: 22.0261897 },
    { lat: 49.0573959, lng: 22.0256097 },
    { lat: 49.057228, lng: 22.0253221 },
    { lat: 49.056934, lng: 22.0253015 },
    { lat: 49.0567745, lng: 22.0253599 },
    { lat: 49.0563482, lng: 22.024004 },
    { lat: 49.0561374, lng: 22.0226806 },
    { lat: 49.0559265, lng: 22.0223302 },
    { lat: 49.0558826, lng: 22.0220934 },
    { lat: 49.0559372, lng: 22.021934 },
    { lat: 49.0558823, lng: 22.021846 },
    { lat: 49.055718, lng: 22.0205824 },
    { lat: 49.0555984, lng: 22.0203822 },
    { lat: 49.0555468, lng: 22.0201093 },
    { lat: 49.0554826, lng: 22.0200601 },
    { lat: 49.0555379, lng: 22.0199677 },
    { lat: 49.0555047, lng: 22.0193922 },
    { lat: 49.0553651, lng: 22.0192895 },
    { lat: 49.055395, lng: 22.0186635 },
    { lat: 49.0553362, lng: 22.0182318 },
    { lat: 49.054169, lng: 22.0179671 },
    { lat: 49.0536878, lng: 22.018018 },
    { lat: 49.0529377, lng: 22.0174679 },
    { lat: 49.0523713, lng: 22.0168304 },
    { lat: 49.051049, lng: 22.0162459 },
    { lat: 49.0506752, lng: 22.0159516 },
    { lat: 49.0501016, lng: 22.0156262 },
    { lat: 49.0491056, lng: 22.0169068 },
    { lat: 49.0487786, lng: 22.0171065 },
    { lat: 49.0479675, lng: 22.0170565 },
    { lat: 49.0472247, lng: 22.0160664 },
    { lat: 49.0463193, lng: 22.0152228 },
    { lat: 49.0463018, lng: 22.0151997 },
    { lat: 49.0460915, lng: 22.0152943 },
    { lat: 49.0459756, lng: 22.0152413 },
    { lat: 49.0458088, lng: 22.015469 },
    { lat: 49.0457668, lng: 22.0161002 },
    { lat: 49.0454937, lng: 22.0165694 },
    { lat: 49.0454584, lng: 22.0168869 },
    { lat: 49.0452827, lng: 22.0172019 },
    { lat: 49.0453843, lng: 22.0175347 },
    { lat: 49.0453157, lng: 22.0175858 },
    { lat: 49.0453295, lng: 22.0177374 },
    { lat: 49.0452694, lng: 22.0178125 },
    { lat: 49.0452606, lng: 22.0179593 },
    { lat: 49.0451858, lng: 22.0179821 },
    { lat: 49.0451862, lng: 22.0180986 },
    { lat: 49.0450796, lng: 22.0181035 },
    { lat: 49.0451071, lng: 22.0182658 },
    { lat: 49.0450378, lng: 22.0184719 },
    { lat: 49.0450935, lng: 22.0187309 },
    { lat: 49.0451863, lng: 22.0188907 },
    { lat: 49.0451236, lng: 22.0189504 },
    { lat: 49.0451243, lng: 22.0192552 },
    { lat: 49.0449994, lng: 22.01935 },
    { lat: 49.0450774, lng: 22.0195056 },
    { lat: 49.04477, lng: 22.02019 },
    { lat: 49.0446029, lng: 22.0203445 },
    { lat: 49.0446601, lng: 22.0205288 },
    { lat: 49.0445999, lng: 22.0206039 },
    { lat: 49.0445293, lng: 22.0209402 },
    { lat: 49.0443215, lng: 22.0209407 },
    { lat: 49.0442153, lng: 22.0211144 },
    { lat: 49.044196, lng: 22.0213378 },
    { lat: 49.0442877, lng: 22.0215048 },
    { lat: 49.0442345, lng: 22.0216738 },
    { lat: 49.044267, lng: 22.0217623 },
    { lat: 49.0442222, lng: 22.0218673 },
    { lat: 49.0440555, lng: 22.021768 },
    { lat: 49.0440489, lng: 22.0218663 },
    { lat: 49.0439461, lng: 22.0219831 },
    { lat: 49.0439336, lng: 22.022119 },
    { lat: 49.0435431, lng: 22.0223452 },
    { lat: 49.0434503, lng: 22.0227383 },
    { lat: 49.0433067, lng: 22.0228915 },
    { lat: 49.0434073, lng: 22.0230569 },
    { lat: 49.0434141, lng: 22.0231519 },
    { lat: 49.0432512, lng: 22.0233481 },
    { lat: 49.0433592, lng: 22.023772 },
    { lat: 49.0433268, lng: 22.0240599 },
    { lat: 49.0432219, lng: 22.0239503 },
    { lat: 49.0429494, lng: 22.0239401 },
    { lat: 49.0428969, lng: 22.0240011 },
    { lat: 49.0428483, lng: 22.023903 },
    { lat: 49.0427524, lng: 22.0240281 },
    { lat: 49.0426586, lng: 22.0242449 },
    { lat: 49.0427436, lng: 22.0243083 },
    { lat: 49.0427258, lng: 22.0243752 },
    { lat: 49.042587, lng: 22.0244934 },
    { lat: 49.0426686, lng: 22.0248847 },
    { lat: 49.0426085, lng: 22.0250128 },
    { lat: 49.0425436, lng: 22.0249322 },
    { lat: 49.0425796, lng: 22.0248398 },
    { lat: 49.0425108, lng: 22.0248325 },
    { lat: 49.0422403, lng: 22.0251785 },
    { lat: 49.0420973, lng: 22.0252661 },
    { lat: 49.0419829, lng: 22.0250975 },
    { lat: 49.0418004, lng: 22.0251245 },
    { lat: 49.0417235, lng: 22.0250639 },
    { lat: 49.0416163, lng: 22.0251939 },
    { lat: 49.0414706, lng: 22.0251203 },
    { lat: 49.0413425, lng: 22.0253142 },
    { lat: 49.0411711, lng: 22.0252933 },
    { lat: 49.0410662, lng: 22.0253673 },
    { lat: 49.0409699, lng: 22.0256272 },
    { lat: 49.0409462, lng: 22.0259437 },
    { lat: 49.0407822, lng: 22.0260546 },
    { lat: 49.0408152, lng: 22.0261646 },
    { lat: 49.0407719, lng: 22.0263223 },
    { lat: 49.0406966, lng: 22.0263258 },
    { lat: 49.0406581, lng: 22.0262257 },
    { lat: 49.0405046, lng: 22.0262042 },
    { lat: 49.040255, lng: 22.0266253 },
    { lat: 49.0402434, lng: 22.0269354 },
    { lat: 49.0400881, lng: 22.0271226 },
    { lat: 49.0400235, lng: 22.0273377 },
    { lat: 49.0399194, lng: 22.0273123 },
    { lat: 49.0398265, lng: 22.0277025 },
    { lat: 49.0399404, lng: 22.0277155 },
    { lat: 49.0400787, lng: 22.0279946 },
    { lat: 49.0399642, lng: 22.0281846 },
    { lat: 49.0398544, lng: 22.0285363 },
    { lat: 49.0395436, lng: 22.028759 },
    { lat: 49.0395218, lng: 22.0289667 },
    { lat: 49.0394501, lng: 22.0291231 },
    { lat: 49.0392637, lng: 22.0291342 },
    { lat: 49.0392818, lng: 22.0295153 },
    { lat: 49.0392386, lng: 22.029726 },
    { lat: 49.0390871, lng: 22.0299729 },
    { lat: 49.0388805, lng: 22.0300862 },
    { lat: 49.0387758, lng: 22.0303587 },
    { lat: 49.0387215, lng: 22.0303153 },
    { lat: 49.038566, lng: 22.0301911 },
    { lat: 49.0385424, lng: 22.030053 },
    { lat: 49.0385618, lng: 22.0299879 },
    { lat: 49.0385847, lng: 22.0299022 },
    { lat: 49.0384621, lng: 22.0299467 },
    { lat: 49.0383504, lng: 22.0298812 },
    { lat: 49.0383851, lng: 22.0302051 },
    { lat: 49.0382575, lng: 22.0303689 },
    { lat: 49.0381654, lng: 22.0303324 },
    { lat: 49.0381021, lng: 22.0304557 },
    { lat: 49.0379932, lng: 22.030513 },
    { lat: 49.0380105, lng: 22.0306246 },
    { lat: 49.0379722, lng: 22.0307171 },
    { lat: 49.0378334, lng: 22.0307388 },
    { lat: 49.0375797, lng: 22.0310476 },
    { lat: 49.03737, lng: 22.0311607 },
    { lat: 49.0370284, lng: 22.0311768 },
    { lat: 49.0369293, lng: 22.0314078 },
    { lat: 49.0362483, lng: 22.0318544 },
    { lat: 49.0361942, lng: 22.0323582 },
    { lat: 49.0358646, lng: 22.0326002 },
    { lat: 49.0358271, lng: 22.0329041 },
    { lat: 49.0358578, lng: 22.0331121 },
    { lat: 49.0355343, lng: 22.0334517 },
    { lat: 49.0353652, lng: 22.0333485 },
    { lat: 49.0352294, lng: 22.0331219 },
    { lat: 49.0350795, lng: 22.0333446 },
    { lat: 49.034897, lng: 22.0334233 },
    { lat: 49.0348019, lng: 22.0339171 },
    { lat: 49.034635, lng: 22.0338067 },
    { lat: 49.034563, lng: 22.0339401 },
    { lat: 49.0345683, lng: 22.0340728 },
    { lat: 49.0343391, lng: 22.0343658 },
    { lat: 49.0342946, lng: 22.0345708 },
    { lat: 49.0340683, lng: 22.0347465 },
    { lat: 49.0337656, lng: 22.0345081 },
    { lat: 49.0336005, lng: 22.0347478 },
    { lat: 49.0335068, lng: 22.0351587 },
    { lat: 49.0333556, lng: 22.0352808 },
    { lat: 49.0332267, lng: 22.0353005 },
    { lat: 49.0332094, lng: 22.0354728 },
    { lat: 49.0333112, lng: 22.0357751 },
    { lat: 49.0333109, lng: 22.0360009 },
    { lat: 49.0330136, lng: 22.036449 },
    { lat: 49.0328952, lng: 22.036485 },
    { lat: 49.0327781, lng: 22.0368585 },
    { lat: 49.0325971, lng: 22.0370449 },
    { lat: 49.0325439, lng: 22.037364 },
    { lat: 49.0324144, lng: 22.0374985 },
    { lat: 49.0324301, lng: 22.0380045 },
    { lat: 49.0322569, lng: 22.0381911 },
    { lat: 49.0321922, lng: 22.0384528 },
    { lat: 49.032235, lng: 22.0390211 },
    { lat: 49.032009, lng: 22.0393566 },
    { lat: 49.0318572, lng: 22.0401984 },
    { lat: 49.0314458, lng: 22.0404904 },
    { lat: 49.0310634, lng: 22.0405862 },
    { lat: 49.0308136, lng: 22.04049 },
    { lat: 49.029879, lng: 22.0406808 },
    { lat: 49.0285128, lng: 22.0412583 },
    { lat: 49.027682, lng: 22.0410413 },
    { lat: 49.0263307, lng: 22.0413064 },
    { lat: 49.0260542, lng: 22.0442313 },
    { lat: 49.0260097, lng: 22.04509 },
    { lat: 49.0260729, lng: 22.0467962 },
    { lat: 49.0261722, lng: 22.0473691 },
    { lat: 49.0273761, lng: 22.0479112 },
    { lat: 49.0284346, lng: 22.0485222 },
    { lat: 49.0289486, lng: 22.0486186 },
    { lat: 49.0290935, lng: 22.0488439 },
    { lat: 49.0293837, lng: 22.0489831 },
    { lat: 49.0296445, lng: 22.0492146 },
    { lat: 49.029983, lng: 22.0493543 },
    { lat: 49.0300401, lng: 22.0494361 },
    { lat: 49.0318001, lng: 22.0504164 },
    { lat: 49.0319929, lng: 22.0504738 },
    { lat: 49.0323942, lng: 22.050799 },
    { lat: 49.0327163, lng: 22.052424 },
    { lat: 49.0327123, lng: 22.052583 },
    { lat: 49.0328351, lng: 22.0530165 },
    { lat: 49.0329378, lng: 22.0537338 },
    { lat: 49.0330922, lng: 22.0543598 },
    { lat: 49.0331439, lng: 22.0548778 },
    { lat: 49.0333904, lng: 22.0559711 },
    { lat: 49.0334799, lng: 22.0565634 },
    { lat: 49.0335719, lng: 22.0568429 },
    { lat: 49.0339216, lng: 22.0589113 },
    { lat: 49.0340261, lng: 22.059192 },
    { lat: 49.0341828, lng: 22.0604889 },
    { lat: 49.034678, lng: 22.0628666 },
    { lat: 49.0347308, lng: 22.0633842 },
    { lat: 49.0349158, lng: 22.0642545 },
    { lat: 49.0349105, lng: 22.0644067 },
    { lat: 49.0350894, lng: 22.0651751 },
    { lat: 49.0350547, lng: 22.0652699 },
    { lat: 49.035098, lng: 22.0655339 },
    { lat: 49.0352519, lng: 22.0657641 },
    { lat: 49.0357899, lng: 22.0659694 },
    { lat: 49.0364697, lng: 22.0668824 },
    { lat: 49.0367006, lng: 22.0673293 },
    { lat: 49.0367769, lng: 22.0676019 },
    { lat: 49.0366909, lng: 22.068339 },
    { lat: 49.0366809, lng: 22.0688242 },
    { lat: 49.0369375, lng: 22.0692561 },
    { lat: 49.037409, lng: 22.0696905 },
    { lat: 49.0375079, lng: 22.0698722 },
    { lat: 49.0374787, lng: 22.0704015 },
    { lat: 49.0369076, lng: 22.0718839 },
    { lat: 49.036981, lng: 22.0721771 },
    { lat: 49.0369609, lng: 22.072284 },
    { lat: 49.0371983, lng: 22.0739494 },
    { lat: 49.0371443, lng: 22.0747514 },
    { lat: 49.0373528, lng: 22.0758671 },
    { lat: 49.0371389, lng: 22.076239 },
    { lat: 49.0367691, lng: 22.0771971 },
    { lat: 49.0361283, lng: 22.0784943 },
    { lat: 49.0359841, lng: 22.0786743 },
    { lat: 49.035561, lng: 22.0788637 },
    { lat: 49.0350349, lng: 22.0793008 },
    { lat: 49.034379, lng: 22.0797205 },
    { lat: 49.0342861, lng: 22.0798396 },
    { lat: 49.0339013, lng: 22.0809321 },
    { lat: 49.0337866, lng: 22.0815011 },
    { lat: 49.0336764, lng: 22.0825459 },
    { lat: 49.0336646, lng: 22.0830961 },
    { lat: 49.0338986, lng: 22.0834307 },
    { lat: 49.0341688, lng: 22.0836357 },
    { lat: 49.0346679, lng: 22.0843419 },
    { lat: 49.0347839, lng: 22.0846331 },
    { lat: 49.0353663, lng: 22.0850956 },
    { lat: 49.035524, lng: 22.0853946 },
    { lat: 49.035721, lng: 22.0859728 },
    { lat: 49.0359343, lng: 22.0862946 },
    { lat: 49.0362136, lng: 22.0865923 },
    { lat: 49.036514, lng: 22.0871194 },
    { lat: 49.0367537, lng: 22.0878394 },
    { lat: 49.0368808, lng: 22.0885613 },
    { lat: 49.0370287, lng: 22.0890174 },
    { lat: 49.0371186, lng: 22.0895854 },
    { lat: 49.0372801, lng: 22.0899975 },
    { lat: 49.0373229, lng: 22.090335 },
    { lat: 49.0372884, lng: 22.0904383 },
    { lat: 49.036401, lng: 22.091304 },
    { lat: 49.037222, lng: 22.092179 },
    { lat: 49.037362, lng: 22.09234 },
    { lat: 49.038258, lng: 22.093863 },
    { lat: 49.038633, lng: 22.094199 },
    { lat: 49.038727, lng: 22.094271 },
    { lat: 49.038844, lng: 22.094451 },
    { lat: 49.038997, lng: 22.094602 },
    { lat: 49.039103, lng: 22.094633 },
    { lat: 49.039282, lng: 22.094802 },
    { lat: 49.039467, lng: 22.09502 },
    { lat: 49.039546, lng: 22.095206 },
    { lat: 49.039622, lng: 22.095448 },
    { lat: 49.039708, lng: 22.095769 },
    { lat: 49.039863, lng: 22.096046 },
    { lat: 49.040115, lng: 22.096414 },
    { lat: 49.040424, lng: 22.096963 },
    { lat: 49.040622, lng: 22.097231 },
    { lat: 49.041557, lng: 22.098783 },
    { lat: 49.041782, lng: 22.098732 },
    { lat: 49.04181, lng: 22.09875 },
    { lat: 49.041825, lng: 22.098766 },
    { lat: 49.041857, lng: 22.098904 },
    { lat: 49.041849, lng: 22.099089 },
    { lat: 49.042048, lng: 22.099368 },
    { lat: 49.042218, lng: 22.099482 },
    { lat: 49.042383, lng: 22.099694 },
    { lat: 49.042578, lng: 22.100002 },
    { lat: 49.042813, lng: 22.10009 },
    { lat: 49.04307, lng: 22.100142 },
    { lat: 49.043302, lng: 22.099364 },
    { lat: 49.043505, lng: 22.09884 },
    { lat: 49.043589, lng: 22.098209 },
    { lat: 49.04385, lng: 22.097519 },
    { lat: 49.043879, lng: 22.096754 },
    { lat: 49.043938, lng: 22.096331 },
    { lat: 49.044617, lng: 22.096627 },
    { lat: 49.0451228, lng: 22.096712 },
    { lat: 49.045403, lng: 22.096757 },
    { lat: 49.0456223, lng: 22.0966889 },
    { lat: 49.0459047, lng: 22.0966011 },
    { lat: 49.046198, lng: 22.096508 },
    { lat: 49.0462402, lng: 22.0964587 },
    { lat: 49.0464582, lng: 22.0961903 },
    { lat: 49.046776, lng: 22.09633 },
    { lat: 49.047526, lng: 22.096179 },
    { lat: 49.047686, lng: 22.09607 },
    { lat: 49.047813, lng: 22.095948 },
    { lat: 49.048054, lng: 22.095873 },
    { lat: 49.048231, lng: 22.095853 },
    { lat: 49.048584, lng: 22.095572 },
    { lat: 49.049223, lng: 22.095282 },
    { lat: 49.049325, lng: 22.095271 },
    { lat: 49.049324, lng: 22.095227 },
    { lat: 49.049883, lng: 22.095396 },
    { lat: 49.050295, lng: 22.095725 },
    { lat: 49.050555, lng: 22.09572 },
    { lat: 49.050791, lng: 22.095673 },
    { lat: 49.051826, lng: 22.095018 },
    { lat: 49.052215, lng: 22.094708 },
    { lat: 49.052409, lng: 22.094676 },
    { lat: 49.052736, lng: 22.094622 },
    { lat: 49.052843, lng: 22.094648 },
    { lat: 49.052933, lng: 22.094665 },
    { lat: 49.053212, lng: 22.094718 },
    { lat: 49.0529759, lng: 22.092714 },
    { lat: 49.0521913, lng: 22.0920222 },
    { lat: 49.0518239, lng: 22.091372 },
    { lat: 49.0516795, lng: 22.0910152 },
    { lat: 49.051712, lng: 22.0901941 },
    { lat: 49.0523716, lng: 22.0898401 },
    { lat: 49.0532457, lng: 22.0896064 },
    { lat: 49.053622, lng: 22.0896325 },
    { lat: 49.0542831, lng: 22.0892906 },
    { lat: 49.0550715, lng: 22.089091 },
    { lat: 49.0567431, lng: 22.0878638 },
    { lat: 49.0571088, lng: 22.0873913 },
    { lat: 49.0568406, lng: 22.0868005 },
    { lat: 49.0574337, lng: 22.0859017 },
    { lat: 49.0579011, lng: 22.0843542 },
    { lat: 49.0584232, lng: 22.083129 },
    { lat: 49.0586364, lng: 22.082002 },
    { lat: 49.0591293, lng: 22.0812072 },
    { lat: 49.0601037, lng: 22.0799801 },
    { lat: 49.060937, lng: 22.0785509 },
    { lat: 49.0609123, lng: 22.0784547 },
    { lat: 49.0615679, lng: 22.0777804 },
    { lat: 49.0619493, lng: 22.0781229 },
    { lat: 49.0627213, lng: 22.0785247 },
    { lat: 49.0641467, lng: 22.0796307 },
    { lat: 49.06497, lng: 22.0796857 },
    { lat: 49.0662321, lng: 22.0793549 },
    { lat: 49.0671683, lng: 22.0793636 },
    { lat: 49.0686173, lng: 22.0792446 },
    { lat: 49.069858, lng: 22.0792969 },
    { lat: 49.0716373, lng: 22.0794801 },
    { lat: 49.0733051, lng: 22.0798534 },
    { lat: 49.075049, lng: 22.080114 },
  ],
  Dedačov: [
    { lat: 49.0375284, lng: 21.9658391 },
    { lat: 49.0364811, lng: 21.9657496 },
    { lat: 49.036222, lng: 21.9658234 },
    { lat: 49.036097, lng: 21.9660887 },
    { lat: 49.0358967, lng: 21.9659954 },
    { lat: 49.0352547, lng: 21.9654989 },
    { lat: 49.0343446, lng: 21.9652653 },
    { lat: 49.0332612, lng: 21.9654922 },
    { lat: 49.032959, lng: 21.9656449 },
    { lat: 49.0329239, lng: 21.9657222 },
    { lat: 49.0327909, lng: 21.9656699 },
    { lat: 49.0326694, lng: 21.9649405 },
    { lat: 49.0323999, lng: 21.9644952 },
    { lat: 49.0323479, lng: 21.9643082 },
    { lat: 49.0322105, lng: 21.9641554 },
    { lat: 49.0321697, lng: 21.9638763 },
    { lat: 49.0323328, lng: 21.9635989 },
    { lat: 49.0323942, lng: 21.9622544 },
    { lat: 49.0324136, lng: 21.9625806 },
    { lat: 49.032447, lng: 21.9623855 },
    { lat: 49.032404, lng: 21.9621542 },
    { lat: 49.0321055, lng: 21.9621784 },
    { lat: 49.0319082, lng: 21.9619752 },
    { lat: 49.0318054, lng: 21.9620506 },
    { lat: 49.0315532, lng: 21.9620421 },
    { lat: 49.0314628, lng: 21.9622114 },
    { lat: 49.0309852, lng: 21.9623117 },
    { lat: 49.0308434, lng: 21.9625471 },
    { lat: 49.0305862, lng: 21.9627815 },
    { lat: 49.0303585, lng: 21.9627622 },
    { lat: 49.0298551, lng: 21.9623761 },
    { lat: 49.0291693, lng: 21.9614796 },
    { lat: 49.0289071, lng: 21.9616562 },
    { lat: 49.0281961, lng: 21.9624258 },
    { lat: 49.0276089, lng: 21.9629704 },
    { lat: 49.0270039, lng: 21.9632998 },
    { lat: 49.0264112, lng: 21.9635318 },
    { lat: 49.0260482, lng: 21.9634951 },
    { lat: 49.0255361, lng: 21.9631637 },
    { lat: 49.0253697, lng: 21.9629904 },
    { lat: 49.0248358, lng: 21.9628659 },
    { lat: 49.0231825, lng: 21.9641539 },
    { lat: 49.0215014, lng: 21.9649976 },
    { lat: 49.0199422, lng: 21.966127 },
    { lat: 49.0190639, lng: 21.9673328 },
    { lat: 49.0210991, lng: 21.9686198 },
    { lat: 49.0212763, lng: 21.968829 },
    { lat: 49.0213525, lng: 21.9690042 },
    { lat: 49.0215856, lng: 21.9691956 },
    { lat: 49.0217544, lng: 21.9692448 },
    { lat: 49.0218489, lng: 21.9694255 },
    { lat: 49.0219453, lng: 21.9694164 },
    { lat: 49.0225875, lng: 21.9692781 },
    { lat: 49.0225913, lng: 21.9693367 },
    { lat: 49.0223358, lng: 21.9697035 },
    { lat: 49.0223373, lng: 21.9699855 },
    { lat: 49.02225, lng: 21.9703725 },
    { lat: 49.0221661, lng: 21.9711224 },
    { lat: 49.0219049, lng: 21.9719851 },
    { lat: 49.0220362, lng: 21.9733012 },
    { lat: 49.0222752, lng: 21.9749255 },
    { lat: 49.0228258, lng: 21.9775694 },
    { lat: 49.0230109, lng: 21.9781374 },
    { lat: 49.022972, lng: 21.9789857 },
    { lat: 49.0227827, lng: 21.9790208 },
    { lat: 49.0226188, lng: 21.9794928 },
    { lat: 49.0227296, lng: 21.9800195 },
    { lat: 49.0227547, lng: 21.9804012 },
    { lat: 49.0229055, lng: 21.9805908 },
    { lat: 49.0232501, lng: 21.9812967 },
    { lat: 49.0233213, lng: 21.9816321 },
    { lat: 49.0238126, lng: 21.9826777 },
    { lat: 49.0241183, lng: 21.9829956 },
    { lat: 49.0242423, lng: 21.9830441 },
    { lat: 49.024302, lng: 21.9832375 },
    { lat: 49.0245624, lng: 21.9835367 },
    { lat: 49.0244585, lng: 21.9849942 },
    { lat: 49.0244896, lng: 21.987097 },
    { lat: 49.0246281, lng: 21.9881551 },
    { lat: 49.024857, lng: 21.9885016 },
    { lat: 49.0251187, lng: 21.9886216 },
    { lat: 49.0256373, lng: 21.9887199 },
    { lat: 49.026678, lng: 21.9891487 },
    { lat: 49.0274128, lng: 21.9891524 },
    { lat: 49.0279253, lng: 21.9889173 },
    { lat: 49.0280819, lng: 21.9889203 },
    { lat: 49.0285205, lng: 21.9892368 },
    { lat: 49.0291813, lng: 21.9890776 },
    { lat: 49.0297759, lng: 21.9892979 },
    { lat: 49.029942, lng: 21.9894168 },
    { lat: 49.0300401, lng: 21.9896034 },
    { lat: 49.0301372, lng: 21.9902209 },
    { lat: 49.0302958, lng: 21.9904529 },
    { lat: 49.0305622, lng: 21.9905436 },
    { lat: 49.0307336, lng: 21.9907379 },
    { lat: 49.031576, lng: 21.9924016 },
    { lat: 49.0317567, lng: 21.9928434 },
    { lat: 49.0319461, lng: 21.9936877 },
    { lat: 49.0323579, lng: 21.9944822 },
    { lat: 49.0325528, lng: 21.9950555 },
    { lat: 49.0326801, lng: 21.9952853 },
    { lat: 49.032956, lng: 21.9964168 },
    { lat: 49.0333642, lng: 21.9975194 },
    { lat: 49.0334993, lng: 21.9980345 },
    { lat: 49.033489, lng: 21.9982975 },
    { lat: 49.0332403, lng: 21.9988477 },
    { lat: 49.0331821, lng: 21.9990904 },
    { lat: 49.0332126, lng: 21.9993762 },
    { lat: 49.0342178, lng: 21.9993663 },
    { lat: 49.0346401, lng: 21.9994311 },
    { lat: 49.0348007, lng: 21.9995583 },
    { lat: 49.0349106, lng: 21.9997754 },
    { lat: 49.0352315, lng: 22.0007106 },
    { lat: 49.0355417, lng: 22.0027397 },
    { lat: 49.0355943, lng: 22.0036136 },
    { lat: 49.0370718, lng: 22.0034952 },
    { lat: 49.0376362, lng: 22.0035768 },
    { lat: 49.0379439, lng: 22.0031924 },
    { lat: 49.0380844, lng: 22.0032817 },
    { lat: 49.0385914, lng: 22.0029427 },
    { lat: 49.038916, lng: 22.0028754 },
    { lat: 49.0397513, lng: 22.0031837 },
    { lat: 49.0404368, lng: 22.0038494 },
    { lat: 49.0412165, lng: 22.0042301 },
    { lat: 49.0420865, lng: 22.0044831 },
    { lat: 49.0429748, lng: 22.0044315 },
    { lat: 49.0433049, lng: 22.0046807 },
    { lat: 49.0434173, lng: 22.0045425 },
    { lat: 49.0436316, lng: 22.0047001 },
    { lat: 49.0436819, lng: 22.0046474 },
    { lat: 49.0436312, lng: 22.0039952 },
    { lat: 49.0434909, lng: 22.0038654 },
    { lat: 49.0433857, lng: 22.0033769 },
    { lat: 49.0434608, lng: 22.0030403 },
    { lat: 49.0434422, lng: 22.0022292 },
    { lat: 49.043342, lng: 22.0020889 },
    { lat: 49.0432899, lng: 22.0015673 },
    { lat: 49.0434959, lng: 22.0011506 },
    { lat: 49.0434996, lng: 22.0008822 },
    { lat: 49.0434005, lng: 22.0006468 },
    { lat: 49.0433938, lng: 22.0001485 },
    { lat: 49.0435127, lng: 21.9999019 },
    { lat: 49.0434182, lng: 21.9989693 },
    { lat: 49.0434615, lng: 21.9986284 },
    { lat: 49.0434303, lng: 21.9979948 },
    { lat: 49.043718, lng: 21.9974286 },
    { lat: 49.0440152, lng: 21.9971071 },
    { lat: 49.0442563, lng: 21.9964396 },
    { lat: 49.0445514, lng: 21.996177 },
    { lat: 49.0445604, lng: 21.9958972 },
    { lat: 49.0455896, lng: 21.9945566 },
    { lat: 49.0458354, lng: 21.9943994 },
    { lat: 49.0460422, lng: 21.9941127 },
    { lat: 49.0470009, lng: 21.9931117 },
    { lat: 49.0476131, lng: 21.9929413 },
    { lat: 49.0477761, lng: 21.9927514 },
    { lat: 49.0481615, lng: 21.9925973 },
    { lat: 49.0483515, lng: 21.9923586 },
    { lat: 49.0487255, lng: 21.9921058 },
    { lat: 49.0488831, lng: 21.9915576 },
    { lat: 49.0488841, lng: 21.9913686 },
    { lat: 49.0489524, lng: 21.9912618 },
    { lat: 49.0489529, lng: 21.9911926 },
    { lat: 49.0487877, lng: 21.9911557 },
    { lat: 49.0485028, lng: 21.9907232 },
    { lat: 49.0483104, lng: 21.9903048 },
    { lat: 49.0482829, lng: 21.989175 },
    { lat: 49.0483115, lng: 21.9881364 },
    { lat: 49.0479688, lng: 21.9876911 },
    { lat: 49.0474786, lng: 21.9865451 },
    { lat: 49.0472876, lng: 21.9843421 },
    { lat: 49.0471234, lng: 21.9838455 },
    { lat: 49.0464917, lng: 21.9826172 },
    { lat: 49.0462781, lng: 21.9823002 },
    { lat: 49.0463513, lng: 21.9816596 },
    { lat: 49.0465002, lng: 21.9811728 },
    { lat: 49.046453, lng: 21.979696 },
    { lat: 49.0463506, lng: 21.9791439 },
    { lat: 49.0462151, lng: 21.9777514 },
    { lat: 49.0460483, lng: 21.9768591 },
    { lat: 49.0447908, lng: 21.9770498 },
    { lat: 49.0444973, lng: 21.976776 },
    { lat: 49.0442332, lng: 21.9763696 },
    { lat: 49.0435789, lng: 21.9758793 },
    { lat: 49.0432432, lng: 21.9753957 },
    { lat: 49.0429912, lng: 21.9746646 },
    { lat: 49.0424088, lng: 21.9738457 },
    { lat: 49.041265, lng: 21.9724274 },
    { lat: 49.0406853, lng: 21.9706767 },
    { lat: 49.0404422, lng: 21.9707113 },
    { lat: 49.0398231, lng: 21.9695045 },
    { lat: 49.0391969, lng: 21.9691992 },
    { lat: 49.0383309, lng: 21.9685206 },
    { lat: 49.0381942, lng: 21.9677992 },
    { lat: 49.0382222, lng: 21.9661122 },
    { lat: 49.0375284, lng: 21.9658391 },
  ],
  Adidovce: [
    { lat: 49.0413144, lng: 22.0096179 },
    { lat: 49.0409667, lng: 22.010406 },
    { lat: 49.0398749, lng: 22.0109781 },
    { lat: 49.0392686, lng: 22.0113931 },
    { lat: 49.039053, lng: 22.0120577 },
    { lat: 49.0373307, lng: 22.0134375 },
    { lat: 49.0367647, lng: 22.0137581 },
    { lat: 49.0359918, lng: 22.0132382 },
    { lat: 49.0356011, lng: 22.0128539 },
    { lat: 49.0350173, lng: 22.0129403 },
    { lat: 49.0341461, lng: 22.0138466 },
    { lat: 49.0338076, lng: 22.0126385 },
    { lat: 49.0328089, lng: 22.0133478 },
    { lat: 49.0323906, lng: 22.0138534 },
    { lat: 49.0315991, lng: 22.011736 },
    { lat: 49.0313618, lng: 22.0119534 },
    { lat: 49.0306335, lng: 22.0126326 },
    { lat: 49.0298028, lng: 22.0134056 },
    { lat: 49.0295915, lng: 22.013645 },
    { lat: 49.0294464, lng: 22.0141557 },
    { lat: 49.0294329, lng: 22.0147178 },
    { lat: 49.0296986, lng: 22.0152839 },
    { lat: 49.0296075, lng: 22.0159346 },
    { lat: 49.0292625, lng: 22.0160442 },
    { lat: 49.0280748, lng: 22.0145607 },
    { lat: 49.0278217, lng: 22.0144663 },
    { lat: 49.0272902, lng: 22.0145322 },
    { lat: 49.0266871, lng: 22.0141086 },
    { lat: 49.0262864, lng: 22.0141367 },
    { lat: 49.0259548, lng: 22.0140603 },
    { lat: 49.0253902, lng: 22.0142413 },
    { lat: 49.0243097, lng: 22.0137989 },
    { lat: 49.0236412, lng: 22.0141489 },
    { lat: 49.0234708, lng: 22.014204 },
    { lat: 49.0232069, lng: 22.0142893 },
    { lat: 49.022619, lng: 22.0142576 },
    { lat: 49.0219299, lng: 22.0143177 },
    { lat: 49.0215797, lng: 22.0144394 },
    { lat: 49.0213577, lng: 22.0146163 },
    { lat: 49.0207877, lng: 22.0153223 },
    { lat: 49.0202666, lng: 22.0158212 },
    { lat: 49.0198822, lng: 22.0159191 },
    { lat: 49.0197905, lng: 22.0159094 },
    { lat: 49.0194355, lng: 22.0158716 },
    { lat: 49.0188047, lng: 22.0156428 },
    { lat: 49.0185532, lng: 22.0157597 },
    { lat: 49.0179804, lng: 22.0158856 },
    { lat: 49.0167876, lng: 22.0157727 },
    { lat: 49.0161411, lng: 22.0158809 },
    { lat: 49.0157654, lng: 22.0157829 },
    { lat: 49.0155485, lng: 22.0160247 },
    { lat: 49.0151038, lng: 22.0163266 },
    { lat: 49.0148727, lng: 22.0165857 },
    { lat: 49.0145344, lng: 22.0167891 },
    { lat: 49.0142054, lng: 22.0170949 },
    { lat: 49.014079, lng: 22.0173065 },
    { lat: 49.0134285, lng: 22.0178922 },
    { lat: 49.0128174, lng: 22.0189468 },
    { lat: 49.0124195, lng: 22.0192883 },
    { lat: 49.0119888, lng: 22.0195273 },
    { lat: 49.0110642, lng: 22.0196688 },
    { lat: 49.0103788, lng: 22.0198837 },
    { lat: 49.0101929, lng: 22.0198199 },
    { lat: 49.0099593, lng: 22.0198351 },
    { lat: 49.0097297, lng: 22.0197379 },
    { lat: 49.0089304, lng: 22.0205951 },
    { lat: 49.0086778, lng: 22.0212638 },
    { lat: 49.0073411, lng: 22.0233422 },
    { lat: 49.0065037, lng: 22.0224337 },
    { lat: 49.0036241, lng: 22.0271455 },
    { lat: 49.0023497, lng: 22.0300433 },
    { lat: 49.001338, lng: 22.0325897 },
    { lat: 48.999225, lng: 22.0315833 },
    { lat: 48.9988663, lng: 22.0331339 },
    { lat: 48.9987505, lng: 22.0340223 },
    { lat: 48.9993732, lng: 22.0400499 },
    { lat: 48.999513, lng: 22.040481 },
    { lat: 48.9995405, lng: 22.0407857 },
    { lat: 48.9994922, lng: 22.0412082 },
    { lat: 48.9995381, lng: 22.0416757 },
    { lat: 48.9989933, lng: 22.0445172 },
    { lat: 48.9988675, lng: 22.0457997 },
    { lat: 48.9988786, lng: 22.0464514 },
    { lat: 48.9987189, lng: 22.0476689 },
    { lat: 48.9986109, lng: 22.0478138 },
    { lat: 48.9983107, lng: 22.0490888 },
    { lat: 48.998154, lng: 22.049595 },
    { lat: 48.998181, lng: 22.049581 },
    { lat: 48.998367, lng: 22.049655 },
    { lat: 48.998902, lng: 22.049874 },
    { lat: 48.999465, lng: 22.049697 },
    { lat: 48.999888, lng: 22.049907 },
    { lat: 49.000444, lng: 22.050205 },
    { lat: 49.000693, lng: 22.050372 },
    { lat: 49.000923, lng: 22.050519 },
    { lat: 49.001309, lng: 22.050735 },
    { lat: 49.001667, lng: 22.051005 },
    { lat: 49.001961, lng: 22.051237 },
    { lat: 49.002085, lng: 22.051342 },
    { lat: 49.002295, lng: 22.051586 },
    { lat: 49.002655, lng: 22.052014 },
    { lat: 49.002969, lng: 22.05212 },
    { lat: 49.003139, lng: 22.052224 },
    { lat: 49.003398, lng: 22.052595 },
    { lat: 49.003809, lng: 22.052766 },
    { lat: 49.004046, lng: 22.052873 },
    { lat: 49.004875, lng: 22.053383 },
    { lat: 49.005123, lng: 22.053575 },
    { lat: 49.00531, lng: 22.053825 },
    { lat: 49.00572, lng: 22.05424 },
    { lat: 49.005965, lng: 22.054226 },
    { lat: 49.006291, lng: 22.0543 },
    { lat: 49.006463, lng: 22.054408 },
    { lat: 49.006757, lng: 22.054773 },
    { lat: 49.006926, lng: 22.054998 },
    { lat: 49.006935, lng: 22.054975 },
    { lat: 49.007261, lng: 22.055475 },
    { lat: 49.007464, lng: 22.056232 },
    { lat: 49.007469, lng: 22.056258 },
    { lat: 49.007726, lng: 22.056297 },
    { lat: 49.008058, lng: 22.056292 },
    { lat: 49.008074, lng: 22.056338 },
    { lat: 49.007975, lng: 22.057178 },
    { lat: 49.007991, lng: 22.057183 },
    { lat: 49.008034, lng: 22.05763 },
    { lat: 49.00813, lng: 22.057821 },
    { lat: 49.008213, lng: 22.057896 },
    { lat: 49.008389, lng: 22.058148 },
    { lat: 49.00864, lng: 22.058694 },
    { lat: 49.008711, lng: 22.05887 },
    { lat: 49.008899, lng: 22.059197 },
    { lat: 49.008991, lng: 22.059341 },
    { lat: 49.009144, lng: 22.05964 },
    { lat: 49.009344, lng: 22.059937 },
    { lat: 49.00958, lng: 22.060135 },
    { lat: 49.00971, lng: 22.06039 },
    { lat: 49.009772, lng: 22.060807 },
    { lat: 49.009904, lng: 22.06095 },
    { lat: 49.009926, lng: 22.061105 },
    { lat: 49.009995, lng: 22.061589 },
    { lat: 49.010005, lng: 22.061875 },
    { lat: 49.00995, lng: 22.062198 },
    { lat: 49.009921, lng: 22.062438 },
    { lat: 49.009773, lng: 22.062852 },
    { lat: 49.009672, lng: 22.06317 },
    { lat: 49.009687, lng: 22.063458 },
    { lat: 49.00967, lng: 22.063454 },
    { lat: 49.009662, lng: 22.063847 },
    { lat: 49.009621, lng: 22.064159 },
    { lat: 49.009562, lng: 22.064342 },
    { lat: 49.009628, lng: 22.064984 },
    { lat: 49.009527, lng: 22.065173 },
    { lat: 49.009467, lng: 22.065403 },
    { lat: 49.009434, lng: 22.06567 },
    { lat: 49.00942, lng: 22.065825 },
    { lat: 49.009412, lng: 22.066102 },
    { lat: 49.009385, lng: 22.066214 },
    { lat: 49.009415, lng: 22.066526 },
    { lat: 49.009605, lng: 22.066809 },
    { lat: 49.009724, lng: 22.066874 },
    { lat: 49.009858, lng: 22.067296 },
    { lat: 49.009902, lng: 22.067856 },
    { lat: 49.00982, lng: 22.068107 },
    { lat: 49.009749, lng: 22.068273 },
    { lat: 49.009663, lng: 22.068372 },
    { lat: 49.009457, lng: 22.068768 },
    { lat: 49.009345, lng: 22.069026 },
    { lat: 49.009088, lng: 22.069312 },
    { lat: 49.008986, lng: 22.069458 },
    { lat: 49.008941, lng: 22.069733 },
    { lat: 49.008727, lng: 22.070194 },
    { lat: 49.00868, lng: 22.070284 },
    { lat: 49.008574, lng: 22.070678 },
    { lat: 49.008398, lng: 22.071013 },
    { lat: 49.008328, lng: 22.071484 },
    { lat: 49.00823, lng: 22.071833 },
    { lat: 49.008186, lng: 22.071965 },
    { lat: 49.008063, lng: 22.072208 },
    { lat: 49.008025, lng: 22.072322 },
    { lat: 49.007936, lng: 22.072629 },
    { lat: 49.007886, lng: 22.072768 },
    { lat: 49.007838, lng: 22.072925 },
    { lat: 49.007702, lng: 22.073333 },
    { lat: 49.007589, lng: 22.073663 },
    { lat: 49.007453, lng: 22.074099 },
    { lat: 49.007354, lng: 22.074338 },
    { lat: 49.007293, lng: 22.074498 },
    { lat: 49.007158, lng: 22.074991 },
    { lat: 49.007007, lng: 22.075224 },
    { lat: 49.006937, lng: 22.07562 },
    { lat: 49.006867, lng: 22.075894 },
    { lat: 49.00677, lng: 22.076066 },
    { lat: 49.006521, lng: 22.076502 },
    { lat: 49.006429, lng: 22.076848 },
    { lat: 49.006375, lng: 22.077094 },
    { lat: 49.006314, lng: 22.077278 },
    { lat: 49.006252, lng: 22.077472 },
    { lat: 49.006011, lng: 22.078171 },
    { lat: 49.005951, lng: 22.078295 },
    { lat: 49.005906, lng: 22.078405 },
    { lat: 49.006145, lng: 22.079525 },
    { lat: 49.006425, lng: 22.079796 },
    { lat: 49.007235, lng: 22.080262 },
    { lat: 49.009303, lng: 22.080799 },
    { lat: 49.010913, lng: 22.080936 },
    { lat: 49.010513, lng: 22.081786 },
    { lat: 49.01058, lng: 22.083306 },
    { lat: 49.010834, lng: 22.083764 },
    { lat: 49.010837, lng: 22.083768 },
    { lat: 49.010841, lng: 22.083776 },
    { lat: 49.010949, lng: 22.083971 },
    { lat: 49.010759, lng: 22.085165 },
    { lat: 49.010974, lng: 22.085261 },
    { lat: 49.011098, lng: 22.086015 },
    { lat: 49.011104, lng: 22.08606 },
    { lat: 49.011183, lng: 22.086541 },
    { lat: 49.011202, lng: 22.086607 },
    { lat: 49.01128, lng: 22.086896 },
    { lat: 49.011467, lng: 22.08703 },
    { lat: 49.011404, lng: 22.087282 },
    { lat: 49.011305, lng: 22.087517 },
    { lat: 49.011323, lng: 22.08782 },
    { lat: 49.011373, lng: 22.088644 },
    { lat: 49.011868, lng: 22.089103 },
    { lat: 49.011896, lng: 22.08913 },
    { lat: 49.012088, lng: 22.089308 },
    { lat: 49.012068, lng: 22.089544 },
    { lat: 49.011895, lng: 22.089916 },
    { lat: 49.01194, lng: 22.089982 },
    { lat: 49.012103, lng: 22.09022 },
    { lat: 49.012243, lng: 22.090555 },
    { lat: 49.012283, lng: 22.09065 },
    { lat: 49.012648, lng: 22.09098 },
    { lat: 49.012834, lng: 22.091436 },
    { lat: 49.013233, lng: 22.091637 },
    { lat: 49.013233, lng: 22.091638 },
    { lat: 49.013264, lng: 22.091969 },
    { lat: 49.013886, lng: 22.09263 },
    { lat: 49.013971, lng: 22.09303 },
    { lat: 49.014316, lng: 22.093099 },
    { lat: 49.014374, lng: 22.093731 },
    { lat: 49.014957, lng: 22.094182 },
    { lat: 49.015007, lng: 22.094221 },
    { lat: 49.015126, lng: 22.094338 },
    { lat: 49.015349, lng: 22.094559 },
    { lat: 49.01556, lng: 22.094767 },
    { lat: 49.015806, lng: 22.095086 },
    { lat: 49.016143, lng: 22.095523 },
    { lat: 49.016182, lng: 22.095688 },
    { lat: 49.016282, lng: 22.096111 },
    { lat: 49.016331, lng: 22.096317 },
    { lat: 49.016444, lng: 22.096397 },
    { lat: 49.016491, lng: 22.09643 },
    { lat: 49.016532, lng: 22.096459 },
    { lat: 49.016559, lng: 22.096478 },
    { lat: 49.016586, lng: 22.096497 },
    { lat: 49.016718, lng: 22.096591 },
    { lat: 49.016951, lng: 22.096859 },
    { lat: 49.01708, lng: 22.097007 },
    { lat: 49.017111, lng: 22.097204 },
    { lat: 49.017169, lng: 22.097576 },
    { lat: 49.017687, lng: 22.098247 },
    { lat: 49.017731, lng: 22.0986 },
    { lat: 49.017738, lng: 22.098601 },
    { lat: 49.017771, lng: 22.098647 },
    { lat: 49.018846, lng: 22.100169 },
    { lat: 49.019114, lng: 22.100003 },
    { lat: 49.019152, lng: 22.100079 },
    { lat: 49.019276, lng: 22.100327 },
    { lat: 49.019388, lng: 22.100553 },
    { lat: 49.019446, lng: 22.10067 },
    { lat: 49.0195, lng: 22.100778 },
    { lat: 49.019521, lng: 22.100817 },
    { lat: 49.019566, lng: 22.100898 },
    { lat: 49.019694, lng: 22.101131 },
    { lat: 49.019885, lng: 22.10181 },
    { lat: 49.020162, lng: 22.101904 },
    { lat: 49.020392, lng: 22.101982 },
    { lat: 49.020472, lng: 22.102242 },
    { lat: 49.020556, lng: 22.102305 },
    { lat: 49.020676, lng: 22.102393 },
    { lat: 49.02062, lng: 22.10266 },
    { lat: 49.020782, lng: 22.102625 },
    { lat: 49.020846, lng: 22.102612 },
    { lat: 49.020837, lng: 22.102657 },
    { lat: 49.020822, lng: 22.102734 },
    { lat: 49.020788, lng: 22.102912 },
    { lat: 49.020828, lng: 22.103024 },
    { lat: 49.020893, lng: 22.103205 },
    { lat: 49.020903, lng: 22.103231 },
    { lat: 49.020919, lng: 22.103273 },
    { lat: 49.021027, lng: 22.103572 },
    { lat: 49.020866, lng: 22.103696 },
    { lat: 49.020888, lng: 22.103789 },
    { lat: 49.021007, lng: 22.10427 },
    { lat: 49.020907, lng: 22.104516 },
    { lat: 49.021052, lng: 22.104922 },
    { lat: 49.02117, lng: 22.105253 },
    { lat: 49.02114, lng: 22.105278 },
    { lat: 49.021019, lng: 22.105379 },
    { lat: 49.02103, lng: 22.105407 },
    { lat: 49.021039, lng: 22.105427 },
    { lat: 49.021054, lng: 22.105464 },
    { lat: 49.021156, lng: 22.105704 },
    { lat: 49.021144, lng: 22.105903 },
    { lat: 49.021122, lng: 22.10598 },
    { lat: 49.021082, lng: 22.106212 },
    { lat: 49.021063, lng: 22.106265 },
    { lat: 49.021064, lng: 22.106299 },
    { lat: 49.021008, lng: 22.106406 },
    { lat: 49.020802, lng: 22.106796 },
    { lat: 49.020848, lng: 22.107027 },
    { lat: 49.020862, lng: 22.107093 },
    { lat: 49.020789, lng: 22.107354 },
    { lat: 49.020769, lng: 22.107425 },
    { lat: 49.020741, lng: 22.107527 },
    { lat: 49.020681, lng: 22.107748 },
    { lat: 49.020658, lng: 22.107828 },
    { lat: 49.020687, lng: 22.107861 },
    { lat: 49.020786, lng: 22.107977 },
    { lat: 49.020772, lng: 22.108 },
    { lat: 49.020673, lng: 22.108158 },
    { lat: 49.020712, lng: 22.10819 },
    { lat: 49.02073, lng: 22.108204 },
    { lat: 49.020752, lng: 22.108222 },
    { lat: 49.02079, lng: 22.108253 },
    { lat: 49.020746, lng: 22.108691 },
    { lat: 49.020676, lng: 22.108944 },
    { lat: 49.020797, lng: 22.109138 },
    { lat: 49.020573, lng: 22.109379 },
    { lat: 49.020424, lng: 22.110156 },
    { lat: 49.020502, lng: 22.110366 },
    { lat: 49.02048, lng: 22.11049 },
    { lat: 49.020434, lng: 22.110752 },
    { lat: 49.020571, lng: 22.110807 },
    { lat: 49.020621, lng: 22.110827 },
    { lat: 49.020629, lng: 22.110831 },
    { lat: 49.021451, lng: 22.111166 },
    { lat: 49.021653, lng: 22.111248 },
    { lat: 49.022334, lng: 22.113035 },
    { lat: 49.023468, lng: 22.115014 },
    { lat: 49.024614, lng: 22.115109 },
    { lat: 49.024633, lng: 22.11511 },
    { lat: 49.024646, lng: 22.114776 },
    { lat: 49.024628, lng: 22.114573 },
    { lat: 49.024582, lng: 22.114447 },
    { lat: 49.024434, lng: 22.114177 },
    { lat: 49.024381, lng: 22.113977 },
    { lat: 49.024397, lng: 22.113728 },
    { lat: 49.024353, lng: 22.113419 },
    { lat: 49.024294, lng: 22.113031 },
    { lat: 49.024264, lng: 22.112492 },
    { lat: 49.024291, lng: 22.112367 },
    { lat: 49.024505, lng: 22.111677 },
    { lat: 49.024656, lng: 22.111246 },
    { lat: 49.024977, lng: 22.109738 },
    { lat: 49.025039, lng: 22.109433 },
    { lat: 49.025082, lng: 22.109118 },
    { lat: 49.025134, lng: 22.10896 },
    { lat: 49.025349, lng: 22.108544 },
    { lat: 49.025591, lng: 22.108245 },
    { lat: 49.025706, lng: 22.108083 },
    { lat: 49.025818, lng: 22.107907 },
    { lat: 49.025938, lng: 22.107491 },
    { lat: 49.026025, lng: 22.107238 },
    { lat: 49.026315, lng: 22.106807 },
    { lat: 49.026703, lng: 22.106219 },
    { lat: 49.026866, lng: 22.105899 },
    { lat: 49.027038, lng: 22.105489 },
    { lat: 49.027177, lng: 22.105273 },
    { lat: 49.027323, lng: 22.105095 },
    { lat: 49.027637, lng: 22.104815 },
    { lat: 49.027805, lng: 22.10465 },
    { lat: 49.028122, lng: 22.104282 },
    { lat: 49.028409, lng: 22.103899 },
    { lat: 49.028662, lng: 22.103464 },
    { lat: 49.028811, lng: 22.1032 },
    { lat: 49.029349, lng: 22.102532 },
    { lat: 49.029588, lng: 22.102363 },
    { lat: 49.029746, lng: 22.102218 },
    { lat: 49.030021, lng: 22.10207 },
    { lat: 49.030213, lng: 22.101888 },
    { lat: 49.030248, lng: 22.101834 },
    { lat: 49.030418, lng: 22.101739 },
    { lat: 49.030744, lng: 22.101505 },
    { lat: 49.030987, lng: 22.101292 },
    { lat: 49.031239, lng: 22.100963 },
    { lat: 49.031498, lng: 22.100682 },
    { lat: 49.032595, lng: 22.100028 },
    { lat: 49.032744, lng: 22.099899 },
    { lat: 49.032923, lng: 22.099858 },
    { lat: 49.033099, lng: 22.099796 },
    { lat: 49.03335, lng: 22.099643 },
    { lat: 49.033424, lng: 22.099558 },
    { lat: 49.03357, lng: 22.09908 },
    { lat: 49.03374, lng: 22.098857 },
    { lat: 49.033925, lng: 22.098696 },
    { lat: 49.034232, lng: 22.098563 },
    { lat: 49.034618, lng: 22.098405 },
    { lat: 49.03496, lng: 22.098087 },
    { lat: 49.035043, lng: 22.097895 },
    { lat: 49.03516, lng: 22.097722 },
    { lat: 49.035342, lng: 22.097637 },
    { lat: 49.035602, lng: 22.097417 },
    { lat: 49.036044, lng: 22.096742 },
    { lat: 49.036312, lng: 22.096268 },
    { lat: 49.036351, lng: 22.095942 },
    { lat: 49.03638, lng: 22.095329 },
    { lat: 49.036376, lng: 22.094823 },
    { lat: 49.03628, lng: 22.094161 },
    { lat: 49.036191, lng: 22.09379 },
    { lat: 49.036192, lng: 22.093458 },
    { lat: 49.036199, lng: 22.093326 },
    { lat: 49.036129, lng: 22.092884 },
    { lat: 49.03606, lng: 22.092573 },
    { lat: 49.036052, lng: 22.092215 },
    { lat: 49.036117, lng: 22.092035 },
    { lat: 49.03623, lng: 22.091754 },
    { lat: 49.036305, lng: 22.091553 },
    { lat: 49.036386, lng: 22.091308 },
    { lat: 49.036389, lng: 22.091292 },
    { lat: 49.036401, lng: 22.091304 },
    { lat: 49.0372884, lng: 22.0904383 },
    { lat: 49.0373229, lng: 22.090335 },
    { lat: 49.0372801, lng: 22.0899975 },
    { lat: 49.0371186, lng: 22.0895854 },
    { lat: 49.0370287, lng: 22.0890174 },
    { lat: 49.0368808, lng: 22.0885613 },
    { lat: 49.0367537, lng: 22.0878394 },
    { lat: 49.036514, lng: 22.0871194 },
    { lat: 49.0362136, lng: 22.0865923 },
    { lat: 49.0359343, lng: 22.0862946 },
    { lat: 49.035721, lng: 22.0859728 },
    { lat: 49.035524, lng: 22.0853946 },
    { lat: 49.0353663, lng: 22.0850956 },
    { lat: 49.0347839, lng: 22.0846331 },
    { lat: 49.0346679, lng: 22.0843419 },
    { lat: 49.0341688, lng: 22.0836357 },
    { lat: 49.0338986, lng: 22.0834307 },
    { lat: 49.0336646, lng: 22.0830961 },
    { lat: 49.0336764, lng: 22.0825459 },
    { lat: 49.0337866, lng: 22.0815011 },
    { lat: 49.0339013, lng: 22.0809321 },
    { lat: 49.0342861, lng: 22.0798396 },
    { lat: 49.034379, lng: 22.0797205 },
    { lat: 49.0350349, lng: 22.0793008 },
    { lat: 49.035561, lng: 22.0788637 },
    { lat: 49.0359841, lng: 22.0786743 },
    { lat: 49.0361283, lng: 22.0784943 },
    { lat: 49.0367691, lng: 22.0771971 },
    { lat: 49.0371389, lng: 22.076239 },
    { lat: 49.0373528, lng: 22.0758671 },
    { lat: 49.0371443, lng: 22.0747514 },
    { lat: 49.0371983, lng: 22.0739494 },
    { lat: 49.0369609, lng: 22.072284 },
    { lat: 49.036981, lng: 22.0721771 },
    { lat: 49.0369076, lng: 22.0718839 },
    { lat: 49.0374787, lng: 22.0704015 },
    { lat: 49.0375079, lng: 22.0698722 },
    { lat: 49.037409, lng: 22.0696905 },
    { lat: 49.0369375, lng: 22.0692561 },
    { lat: 49.0366809, lng: 22.0688242 },
    { lat: 49.0366909, lng: 22.068339 },
    { lat: 49.0367769, lng: 22.0676019 },
    { lat: 49.0367006, lng: 22.0673293 },
    { lat: 49.0364697, lng: 22.0668824 },
    { lat: 49.0357899, lng: 22.0659694 },
    { lat: 49.0352519, lng: 22.0657641 },
    { lat: 49.035098, lng: 22.0655339 },
    { lat: 49.0350547, lng: 22.0652699 },
    { lat: 49.0350894, lng: 22.0651751 },
    { lat: 49.0349105, lng: 22.0644067 },
    { lat: 49.0349158, lng: 22.0642545 },
    { lat: 49.0347308, lng: 22.0633842 },
    { lat: 49.034678, lng: 22.0628666 },
    { lat: 49.0341828, lng: 22.0604889 },
    { lat: 49.0340261, lng: 22.059192 },
    { lat: 49.0339216, lng: 22.0589113 },
    { lat: 49.0335719, lng: 22.0568429 },
    { lat: 49.0334799, lng: 22.0565634 },
    { lat: 49.0333904, lng: 22.0559711 },
    { lat: 49.0331439, lng: 22.0548778 },
    { lat: 49.0330922, lng: 22.0543598 },
    { lat: 49.0329378, lng: 22.0537338 },
    { lat: 49.0328351, lng: 22.0530165 },
    { lat: 49.0327123, lng: 22.052583 },
    { lat: 49.0327163, lng: 22.052424 },
    { lat: 49.0323942, lng: 22.050799 },
    { lat: 49.0319929, lng: 22.0504738 },
    { lat: 49.0318001, lng: 22.0504164 },
    { lat: 49.0300401, lng: 22.0494361 },
    { lat: 49.029983, lng: 22.0493543 },
    { lat: 49.0296445, lng: 22.0492146 },
    { lat: 49.0293837, lng: 22.0489831 },
    { lat: 49.0290935, lng: 22.0488439 },
    { lat: 49.0289486, lng: 22.0486186 },
    { lat: 49.0284346, lng: 22.0485222 },
    { lat: 49.0273761, lng: 22.0479112 },
    { lat: 49.0261722, lng: 22.0473691 },
    { lat: 49.0260729, lng: 22.0467962 },
    { lat: 49.0260097, lng: 22.04509 },
    { lat: 49.0260542, lng: 22.0442313 },
    { lat: 49.0263307, lng: 22.0413064 },
    { lat: 49.027682, lng: 22.0410413 },
    { lat: 49.0285128, lng: 22.0412583 },
    { lat: 49.029879, lng: 22.0406808 },
    { lat: 49.0308136, lng: 22.04049 },
    { lat: 49.0310634, lng: 22.0405862 },
    { lat: 49.0314458, lng: 22.0404904 },
    { lat: 49.0318572, lng: 22.0401984 },
    { lat: 49.032009, lng: 22.0393566 },
    { lat: 49.032235, lng: 22.0390211 },
    { lat: 49.0321922, lng: 22.0384528 },
    { lat: 49.0322569, lng: 22.0381911 },
    { lat: 49.0324301, lng: 22.0380045 },
    { lat: 49.0324144, lng: 22.0374985 },
    { lat: 49.0325439, lng: 22.037364 },
    { lat: 49.0325971, lng: 22.0370449 },
    { lat: 49.0327781, lng: 22.0368585 },
    { lat: 49.0328952, lng: 22.036485 },
    { lat: 49.0330136, lng: 22.036449 },
    { lat: 49.0333109, lng: 22.0360009 },
    { lat: 49.0333112, lng: 22.0357751 },
    { lat: 49.0332094, lng: 22.0354728 },
    { lat: 49.0332267, lng: 22.0353005 },
    { lat: 49.0333556, lng: 22.0352808 },
    { lat: 49.0335068, lng: 22.0351587 },
    { lat: 49.0336005, lng: 22.0347478 },
    { lat: 49.0337656, lng: 22.0345081 },
    { lat: 49.0340683, lng: 22.0347465 },
    { lat: 49.0342946, lng: 22.0345708 },
    { lat: 49.0343391, lng: 22.0343658 },
    { lat: 49.0345683, lng: 22.0340728 },
    { lat: 49.034563, lng: 22.0339401 },
    { lat: 49.034635, lng: 22.0338067 },
    { lat: 49.0348019, lng: 22.0339171 },
    { lat: 49.034897, lng: 22.0334233 },
    { lat: 49.0350795, lng: 22.0333446 },
    { lat: 49.0352294, lng: 22.0331219 },
    { lat: 49.0353652, lng: 22.0333485 },
    { lat: 49.0355343, lng: 22.0334517 },
    { lat: 49.0358578, lng: 22.0331121 },
    { lat: 49.0358271, lng: 22.0329041 },
    { lat: 49.0358646, lng: 22.0326002 },
    { lat: 49.0361942, lng: 22.0323582 },
    { lat: 49.0362483, lng: 22.0318544 },
    { lat: 49.0369293, lng: 22.0314078 },
    { lat: 49.0370284, lng: 22.0311768 },
    { lat: 49.03737, lng: 22.0311607 },
    { lat: 49.0375797, lng: 22.0310476 },
    { lat: 49.0378334, lng: 22.0307388 },
    { lat: 49.0379722, lng: 22.0307171 },
    { lat: 49.0380105, lng: 22.0306246 },
    { lat: 49.0379932, lng: 22.030513 },
    { lat: 49.0381021, lng: 22.0304557 },
    { lat: 49.0381654, lng: 22.0303324 },
    { lat: 49.0382575, lng: 22.0303689 },
    { lat: 49.0383851, lng: 22.0302051 },
    { lat: 49.0383504, lng: 22.0298812 },
    { lat: 49.0384621, lng: 22.0299467 },
    { lat: 49.0385847, lng: 22.0299022 },
    { lat: 49.0385618, lng: 22.0299879 },
    { lat: 49.0385424, lng: 22.030053 },
    { lat: 49.038566, lng: 22.0301911 },
    { lat: 49.0387215, lng: 22.0303153 },
    { lat: 49.0387758, lng: 22.0303587 },
    { lat: 49.0388805, lng: 22.0300862 },
    { lat: 49.0390871, lng: 22.0299729 },
    { lat: 49.0392386, lng: 22.029726 },
    { lat: 49.0392818, lng: 22.0295153 },
    { lat: 49.0392637, lng: 22.0291342 },
    { lat: 49.0394501, lng: 22.0291231 },
    { lat: 49.0395218, lng: 22.0289667 },
    { lat: 49.0395436, lng: 22.028759 },
    { lat: 49.0398544, lng: 22.0285363 },
    { lat: 49.0399642, lng: 22.0281846 },
    { lat: 49.0400787, lng: 22.0279946 },
    { lat: 49.0399404, lng: 22.0277155 },
    { lat: 49.0398265, lng: 22.0277025 },
    { lat: 49.0399194, lng: 22.0273123 },
    { lat: 49.0400235, lng: 22.0273377 },
    { lat: 49.0400881, lng: 22.0271226 },
    { lat: 49.0402434, lng: 22.0269354 },
    { lat: 49.040255, lng: 22.0266253 },
    { lat: 49.0405046, lng: 22.0262042 },
    { lat: 49.0406581, lng: 22.0262257 },
    { lat: 49.0406966, lng: 22.0263258 },
    { lat: 49.0407719, lng: 22.0263223 },
    { lat: 49.0408152, lng: 22.0261646 },
    { lat: 49.0407822, lng: 22.0260546 },
    { lat: 49.0409462, lng: 22.0259437 },
    { lat: 49.0409699, lng: 22.0256272 },
    { lat: 49.0410662, lng: 22.0253673 },
    { lat: 49.0411711, lng: 22.0252933 },
    { lat: 49.0413425, lng: 22.0253142 },
    { lat: 49.0414706, lng: 22.0251203 },
    { lat: 49.0416163, lng: 22.0251939 },
    { lat: 49.0417235, lng: 22.0250639 },
    { lat: 49.0418004, lng: 22.0251245 },
    { lat: 49.0419829, lng: 22.0250975 },
    { lat: 49.0420973, lng: 22.0252661 },
    { lat: 49.0422403, lng: 22.0251785 },
    { lat: 49.0425108, lng: 22.0248325 },
    { lat: 49.0425796, lng: 22.0248398 },
    { lat: 49.0425436, lng: 22.0249322 },
    { lat: 49.0426085, lng: 22.0250128 },
    { lat: 49.0426686, lng: 22.0248847 },
    { lat: 49.042587, lng: 22.0244934 },
    { lat: 49.0427258, lng: 22.0243752 },
    { lat: 49.0427436, lng: 22.0243083 },
    { lat: 49.0426586, lng: 22.0242449 },
    { lat: 49.0427524, lng: 22.0240281 },
    { lat: 49.0428483, lng: 22.023903 },
    { lat: 49.0428969, lng: 22.0240011 },
    { lat: 49.0429494, lng: 22.0239401 },
    { lat: 49.0432219, lng: 22.0239503 },
    { lat: 49.0433268, lng: 22.0240599 },
    { lat: 49.0433592, lng: 22.023772 },
    { lat: 49.0432512, lng: 22.0233481 },
    { lat: 49.0434141, lng: 22.0231519 },
    { lat: 49.0434073, lng: 22.0230569 },
    { lat: 49.0433067, lng: 22.0228915 },
    { lat: 49.0434503, lng: 22.0227383 },
    { lat: 49.0435431, lng: 22.0223452 },
    { lat: 49.0439336, lng: 22.022119 },
    { lat: 49.0439461, lng: 22.0219831 },
    { lat: 49.0440489, lng: 22.0218663 },
    { lat: 49.0440555, lng: 22.021768 },
    { lat: 49.0442222, lng: 22.0218673 },
    { lat: 49.044267, lng: 22.0217623 },
    { lat: 49.0442345, lng: 22.0216738 },
    { lat: 49.0442877, lng: 22.0215048 },
    { lat: 49.044196, lng: 22.0213378 },
    { lat: 49.0442153, lng: 22.0211144 },
    { lat: 49.0443215, lng: 22.0209407 },
    { lat: 49.0445293, lng: 22.0209402 },
    { lat: 49.0445999, lng: 22.0206039 },
    { lat: 49.0446601, lng: 22.0205288 },
    { lat: 49.0446029, lng: 22.0203445 },
    { lat: 49.04477, lng: 22.02019 },
    { lat: 49.0450774, lng: 22.0195056 },
    { lat: 49.0449994, lng: 22.01935 },
    { lat: 49.0451243, lng: 22.0192552 },
    { lat: 49.0451236, lng: 22.0189504 },
    { lat: 49.0451863, lng: 22.0188907 },
    { lat: 49.0450935, lng: 22.0187309 },
    { lat: 49.0450378, lng: 22.0184719 },
    { lat: 49.0451071, lng: 22.0182658 },
    { lat: 49.0450796, lng: 22.0181035 },
    { lat: 49.0451862, lng: 22.0180986 },
    { lat: 49.0451858, lng: 22.0179821 },
    { lat: 49.0452606, lng: 22.0179593 },
    { lat: 49.0452694, lng: 22.0178125 },
    { lat: 49.0453295, lng: 22.0177374 },
    { lat: 49.0453157, lng: 22.0175858 },
    { lat: 49.0453843, lng: 22.0175347 },
    { lat: 49.0452827, lng: 22.0172019 },
    { lat: 49.0454584, lng: 22.0168869 },
    { lat: 49.0454937, lng: 22.0165694 },
    { lat: 49.0457668, lng: 22.0161002 },
    { lat: 49.0458088, lng: 22.015469 },
    { lat: 49.0459756, lng: 22.0152413 },
    { lat: 49.0460915, lng: 22.0152943 },
    { lat: 49.0463018, lng: 22.0151997 },
    { lat: 49.0462833, lng: 22.0151771 },
    { lat: 49.0461957, lng: 22.0150425 },
    { lat: 49.0459112, lng: 22.0149064 },
    { lat: 49.0456498, lng: 22.0149859 },
    { lat: 49.045506, lng: 22.0151313 },
    { lat: 49.0452797, lng: 22.0149335 },
    { lat: 49.0451173, lng: 22.0149157 },
    { lat: 49.0446667, lng: 22.0145156 },
    { lat: 49.04442, lng: 22.0144007 },
    { lat: 49.0441272, lng: 22.0141547 },
    { lat: 49.0439494, lng: 22.0138803 },
    { lat: 49.0437687, lng: 22.0132396 },
    { lat: 49.043508, lng: 22.0126433 },
    { lat: 49.0433029, lng: 22.0116605 },
    { lat: 49.0430628, lng: 22.0112649 },
    { lat: 49.0428416, lng: 22.0110941 },
    { lat: 49.0424524, lng: 22.0110036 },
    { lat: 49.0423838, lng: 22.0110415 },
    { lat: 49.0420364, lng: 22.0108183 },
    { lat: 49.0420185, lng: 22.0105952 },
    { lat: 49.0418799, lng: 22.0101638 },
    { lat: 49.0417064, lng: 22.0099232 },
    { lat: 49.0413144, lng: 22.0096179 },
  ],
  Rovné: [
    { lat: 48.9860414, lng: 21.991114 },
    { lat: 48.9852607, lng: 21.9918098 },
    { lat: 48.9847892, lng: 21.9921239 },
    { lat: 48.9843809, lng: 21.9923532 },
    { lat: 48.983595, lng: 21.9925934 },
    { lat: 48.9815947, lng: 21.9911977 },
    { lat: 48.9809245, lng: 21.9930993 },
    { lat: 48.9808121, lng: 21.9938827 },
    { lat: 48.9806979, lng: 21.9940041 },
    { lat: 48.9805766, lng: 21.9942856 },
    { lat: 48.980531, lng: 21.9947932 },
    { lat: 48.9804757, lng: 21.9954096 },
    { lat: 48.9805487, lng: 21.9967888 },
    { lat: 48.9771129, lng: 21.9967643 },
    { lat: 48.9763552, lng: 21.9966439 },
    { lat: 48.9766737, lng: 21.9991429 },
    { lat: 48.9762225, lng: 21.9991407 },
    { lat: 48.9755559, lng: 21.9990134 },
    { lat: 48.9752471, lng: 21.9991765 },
    { lat: 48.9742533, lng: 21.9992427 },
    { lat: 48.9741032, lng: 21.9979758 },
    { lat: 48.9731113, lng: 21.9980333 },
    { lat: 48.9726788, lng: 21.9961185 },
    { lat: 48.9722358, lng: 21.9961688 },
    { lat: 48.9722096, lng: 21.9946798 },
    { lat: 48.9718587, lng: 21.9946361 },
    { lat: 48.9718577, lng: 21.9946817 },
    { lat: 48.9709079, lng: 21.992916 },
    { lat: 48.9685144, lng: 21.9932888 },
    { lat: 48.9679951, lng: 21.9934891 },
    { lat: 48.9677586, lng: 21.9933807 },
    { lat: 48.9674644, lng: 21.9924804 },
    { lat: 48.9673064, lng: 21.9922359 },
    { lat: 48.9670891, lng: 21.9921296 },
    { lat: 48.9670313, lng: 21.9920194 },
    { lat: 48.9666602, lng: 21.9921154 },
    { lat: 48.96584, lng: 21.9925719 },
    { lat: 48.9654946, lng: 21.9923848 },
    { lat: 48.9652402, lng: 21.9927463 },
    { lat: 48.9649607, lng: 21.9927689 },
    { lat: 48.964664, lng: 21.9932842 },
    { lat: 48.9636956, lng: 21.9930566 },
    { lat: 48.9635046, lng: 21.9931612 },
    { lat: 48.9625766, lng: 21.9932947 },
    { lat: 48.9618273, lng: 21.9936121 },
    { lat: 48.9614296, lng: 21.9936775 },
    { lat: 48.9614388, lng: 21.9924438 },
    { lat: 48.9612535, lng: 21.9912146 },
    { lat: 48.9610221, lng: 21.9910329 },
    { lat: 48.9605194, lng: 21.9909524 },
    { lat: 48.960333, lng: 21.9910588 },
    { lat: 48.9602127, lng: 21.9910183 },
    { lat: 48.9601654, lng: 21.9907286 },
    { lat: 48.9598623, lng: 21.9908083 },
    { lat: 48.9596639, lng: 21.9909734 },
    { lat: 48.960107, lng: 21.9954371 },
    { lat: 48.9597878, lng: 21.9955875 },
    { lat: 48.959834, lng: 21.9958193 },
    { lat: 48.9596022, lng: 21.9959627 },
    { lat: 48.9596224, lng: 21.9960456 },
    { lat: 48.9594815, lng: 21.9961746 },
    { lat: 48.9594806, lng: 21.9963192 },
    { lat: 48.9600166, lng: 21.9985667 },
    { lat: 48.959804, lng: 21.9990211 },
    { lat: 48.9596019, lng: 21.9992193 },
    { lat: 48.959249, lng: 22.0000706 },
    { lat: 48.9589675, lng: 22.0004236 },
    { lat: 48.9590778, lng: 22.0006576 },
    { lat: 48.9591212, lng: 22.0009583 },
    { lat: 48.9593366, lng: 22.0013059 },
    { lat: 48.9596526, lng: 22.0016311 },
    { lat: 48.9599722, lng: 22.002143 },
    { lat: 48.960303, lng: 22.003253 },
    { lat: 48.9603877, lng: 22.0034104 },
    { lat: 48.9606192, lng: 22.0036355 },
    { lat: 48.9607502, lng: 22.0043891 },
    { lat: 48.9610838, lng: 22.0053044 },
    { lat: 48.9614232, lng: 22.0065865 },
    { lat: 48.9619052, lng: 22.0093416 },
    { lat: 48.9620497, lng: 22.0105674 },
    { lat: 48.9622003, lng: 22.0111704 },
    { lat: 48.9625044, lng: 22.0119777 },
    { lat: 48.9625943, lng: 22.0129954 },
    { lat: 48.9628551, lng: 22.0139603 },
    { lat: 48.9627648, lng: 22.0150106 },
    { lat: 48.9625435, lng: 22.0157703 },
    { lat: 48.9622888, lng: 22.0159338 },
    { lat: 48.96227, lng: 22.0160426 },
    { lat: 48.962379, lng: 22.016218 },
    { lat: 48.962302, lng: 22.018031 },
    { lat: 48.962647, lng: 22.018989 },
    { lat: 48.962667, lng: 22.019043 },
    { lat: 48.962963, lng: 22.019869 },
    { lat: 48.96385, lng: 22.022283 },
    { lat: 48.963941, lng: 22.022569 },
    { lat: 48.964253, lng: 22.023545 },
    { lat: 48.964289, lng: 22.023723 },
    { lat: 48.964447, lng: 22.024237 },
    { lat: 48.964517, lng: 22.024513 },
    { lat: 48.964595, lng: 22.025051 },
    { lat: 48.96486, lng: 22.026208 },
    { lat: 48.964894, lng: 22.026403 },
    { lat: 48.965129, lng: 22.026764 },
    { lat: 48.965222, lng: 22.027572 },
    { lat: 48.96514, lng: 22.027879 },
    { lat: 48.964974, lng: 22.028498 },
    { lat: 48.964953, lng: 22.028575 },
    { lat: 48.964859, lng: 22.028925 },
    { lat: 48.964997, lng: 22.02885 },
    { lat: 48.965094, lng: 22.028681 },
    { lat: 48.965444, lng: 22.028538 },
    { lat: 48.96606, lng: 22.028494 },
    { lat: 48.966096, lng: 22.028242 },
    { lat: 48.966293, lng: 22.028397 },
    { lat: 48.966476, lng: 22.028247 },
    { lat: 48.966811, lng: 22.028262 },
    { lat: 48.96679, lng: 22.028092 },
    { lat: 48.966878, lng: 22.027985 },
    { lat: 48.967019, lng: 22.027811 },
    { lat: 48.967384, lng: 22.027713 },
    { lat: 48.9674, lng: 22.027496 },
    { lat: 48.967439, lng: 22.027471 },
    { lat: 48.967444, lng: 22.027469 },
    { lat: 48.967558, lng: 22.027346 },
    { lat: 48.967742, lng: 22.027427 },
    { lat: 48.967928, lng: 22.027373 },
    { lat: 48.968387, lng: 22.027427 },
    { lat: 48.968474, lng: 22.027296 },
    { lat: 48.968563, lng: 22.027338 },
    { lat: 48.968546, lng: 22.027441 },
    { lat: 48.968949, lng: 22.027536 },
    { lat: 48.969326, lng: 22.027656 },
    { lat: 48.969197, lng: 22.028987 },
    { lat: 48.969311, lng: 22.029546 },
    { lat: 48.969627, lng: 22.030294 },
    { lat: 48.969887, lng: 22.031127 },
    { lat: 48.969929, lng: 22.031151 },
    { lat: 48.970235, lng: 22.031346 },
    { lat: 48.970755, lng: 22.031625 },
    { lat: 48.970967, lng: 22.031784 },
    { lat: 48.971084, lng: 22.031917 },
    { lat: 48.971132, lng: 22.031972 },
    { lat: 48.971383, lng: 22.032257 },
    { lat: 48.971449, lng: 22.032388 },
    { lat: 48.97147, lng: 22.032535 },
    { lat: 48.971786, lng: 22.033316 },
    { lat: 48.972293, lng: 22.034268 },
    { lat: 48.972299, lng: 22.034281 },
    { lat: 48.972717, lng: 22.035148 },
    { lat: 48.97296, lng: 22.035386 },
    { lat: 48.973107, lng: 22.035878 },
    { lat: 48.973929, lng: 22.037086 },
    { lat: 48.974117, lng: 22.037668 },
    { lat: 48.974474, lng: 22.038018 },
    { lat: 48.974555, lng: 22.038097 },
    { lat: 48.975011, lng: 22.038627 },
    { lat: 48.975383, lng: 22.039466 },
    { lat: 48.975402, lng: 22.039506 },
    { lat: 48.975625, lng: 22.040011 },
    { lat: 48.975672, lng: 22.040114 },
    { lat: 48.975818, lng: 22.042725 },
    { lat: 48.975987, lng: 22.043127 },
    { lat: 48.976552, lng: 22.044157 },
    { lat: 48.977281, lng: 22.045635 },
    { lat: 48.977723, lng: 22.046559 },
    { lat: 48.978147, lng: 22.047151 },
    { lat: 48.978251, lng: 22.04765 },
    { lat: 48.978381, lng: 22.047959 },
    { lat: 48.978569, lng: 22.048799 },
    { lat: 48.978637, lng: 22.049098 },
    { lat: 48.978771, lng: 22.049383 },
    { lat: 48.978875, lng: 22.04946 },
    { lat: 48.978878, lng: 22.049488 },
    { lat: 48.97918, lng: 22.04939 },
    { lat: 48.97995, lng: 22.049793 },
    { lat: 48.980226, lng: 22.049883 },
    { lat: 48.980597, lng: 22.049838 },
    { lat: 48.980768, lng: 22.049805 },
    { lat: 48.981595, lng: 22.049636 },
    { lat: 48.982192, lng: 22.049514 },
    { lat: 48.9822432, lng: 22.0491925 },
    { lat: 48.9822817, lng: 22.0483989 },
    { lat: 48.9825129, lng: 22.0478637 },
    { lat: 48.9826122, lng: 22.0474532 },
    { lat: 48.9828833, lng: 22.0469817 },
    { lat: 48.9832877, lng: 22.045875 },
    { lat: 48.9829948, lng: 22.0445898 },
    { lat: 48.9828273, lng: 22.0436089 },
    { lat: 48.9826443, lng: 22.0432509 },
    { lat: 48.9819912, lng: 22.0413855 },
    { lat: 48.9826197, lng: 22.0373446 },
    { lat: 48.9818225, lng: 22.0362322 },
    { lat: 48.9820154, lng: 22.0353029 },
    { lat: 48.9821007, lng: 22.0348918 },
    { lat: 48.9823098, lng: 22.0340588 },
    { lat: 48.9824784, lng: 22.0333872 },
    { lat: 48.9827109, lng: 22.0324607 },
    { lat: 48.983162, lng: 22.0306638 },
    { lat: 48.9844795, lng: 22.026425 },
    { lat: 48.9854384, lng: 22.0237018 },
    { lat: 48.9868127, lng: 22.0203393 },
    { lat: 48.9876605, lng: 22.0171078 },
    { lat: 48.9875971, lng: 22.0165838 },
    { lat: 48.9857981, lng: 22.0120118 },
    { lat: 48.9855799, lng: 22.0115466 },
    { lat: 48.9852725, lng: 22.0113273 },
    { lat: 48.9849088, lng: 22.0110685 },
    { lat: 48.9845839, lng: 22.0108362 },
    { lat: 48.9842813, lng: 22.0106204 },
    { lat: 48.9838443, lng: 22.0103086 },
    { lat: 48.9835184, lng: 22.0100762 },
    { lat: 48.9838898, lng: 22.0067915 },
    { lat: 48.983922, lng: 22.0054761 },
    { lat: 48.9825567, lng: 22.0049768 },
    { lat: 48.9846867, lng: 22.0031506 },
    { lat: 48.9851395, lng: 22.0028978 },
    { lat: 48.985408, lng: 22.0026087 },
    { lat: 48.9855509, lng: 22.0025646 },
    { lat: 48.9858312, lng: 22.0022566 },
    { lat: 48.9860147, lng: 22.0019319 },
    { lat: 48.9866035, lng: 22.0015435 },
    { lat: 48.9870198, lng: 22.0010317 },
    { lat: 48.9874672, lng: 22.0007433 },
    { lat: 48.9875923, lng: 22.0005264 },
    { lat: 48.9875974, lng: 22.0003202 },
    { lat: 48.9885571, lng: 21.9973717 },
    { lat: 48.9883373, lng: 21.9968238 },
    { lat: 48.9882793, lng: 21.9966793 },
    { lat: 48.9883963, lng: 21.9954815 },
    { lat: 48.9880029, lng: 21.9945241 },
    { lat: 48.9878529, lng: 21.994462 },
    { lat: 48.9876203, lng: 21.9939626 },
    { lat: 48.9867179, lng: 21.9929819 },
    { lat: 48.9866959, lng: 21.9927264 },
    { lat: 48.9866252, lng: 21.9926401 },
    { lat: 48.9864821, lng: 21.9920297 },
    { lat: 48.9860414, lng: 21.991114 },
  ],
  Veľopolie: [
    { lat: 48.9771463, lng: 21.9267372 },
    { lat: 48.9774153, lng: 21.9272398 },
    { lat: 48.977925, lng: 21.9272437 },
    { lat: 48.9782477, lng: 21.9273759 },
    { lat: 48.978247, lng: 21.9278021 },
    { lat: 48.9787979, lng: 21.927973 },
    { lat: 48.9790218, lng: 21.9280703 },
    { lat: 48.9793814, lng: 21.9282685 },
    { lat: 48.9796615, lng: 21.9285485 },
    { lat: 48.9796887, lng: 21.9285015 },
    { lat: 48.9809645, lng: 21.9300954 },
    { lat: 48.9812411, lng: 21.9303849 },
    { lat: 48.9815699, lng: 21.9307302 },
    { lat: 48.9819735, lng: 21.9310368 },
    { lat: 48.9817596, lng: 21.9334732 },
    { lat: 48.9819548, lng: 21.935168 },
    { lat: 48.9819953, lng: 21.9364728 },
    { lat: 48.9819665, lng: 21.9383033 },
    { lat: 48.9828103, lng: 21.9381743 },
    { lat: 48.9836076, lng: 21.9392517 },
    { lat: 48.9837728, lng: 21.9396945 },
    { lat: 48.9841862, lng: 21.9392664 },
    { lat: 48.9844401, lng: 21.9399807 },
    { lat: 48.9846283, lng: 21.9401747 },
    { lat: 48.9847449, lng: 21.9406547 },
    { lat: 48.9856849, lng: 21.9519384 },
    { lat: 48.9856216, lng: 21.952018 },
    { lat: 48.9858322, lng: 21.955799 },
    { lat: 48.9859328, lng: 21.9558276 },
    { lat: 48.9856657, lng: 21.957992 },
    { lat: 48.9857976, lng: 21.9583205 },
    { lat: 48.9864728, lng: 21.9620246 },
    { lat: 48.9865978, lng: 21.9623015 },
    { lat: 48.986656, lng: 21.9629793 },
    { lat: 48.9867886, lng: 21.963475 },
    { lat: 48.9871357, lng: 21.9643209 },
    { lat: 48.9899013, lng: 21.9675479 },
    { lat: 48.9902166, lng: 21.9681567 },
    { lat: 48.9903663, lng: 21.969212 },
    { lat: 48.9910018, lng: 21.9700373 },
    { lat: 48.9913338, lng: 21.9706847 },
    { lat: 48.9914086, lng: 21.9712087 },
    { lat: 48.9913065, lng: 21.9721862 },
    { lat: 48.9913491, lng: 21.9753393 },
    { lat: 48.9925347, lng: 21.97602 },
    { lat: 48.9930907, lng: 21.9765029 },
    { lat: 48.9939224, lng: 21.9790348 },
    { lat: 48.9945819, lng: 21.9797472 },
    { lat: 48.9951046, lng: 21.9802323 },
    { lat: 48.9955751, lng: 21.9805686 },
    { lat: 48.9955514, lng: 21.980614 },
    { lat: 48.9961272, lng: 21.9808816 },
    { lat: 48.9962085, lng: 21.9817162 },
    { lat: 48.9966086, lng: 21.9820314 },
    { lat: 48.9969898, lng: 21.9821198 },
    { lat: 48.9975658, lng: 21.9824838 },
    { lat: 48.9978126, lng: 21.982501 },
    { lat: 48.9985106, lng: 21.9839138 },
    { lat: 48.998415, lng: 21.9840023 },
    { lat: 48.9985503, lng: 21.9842447 },
    { lat: 48.9991537, lng: 21.9848587 },
    { lat: 48.9994579, lng: 21.9849737 },
    { lat: 48.9996649, lng: 21.9845221 },
    { lat: 48.999588, lng: 21.9839969 },
    { lat: 49.0000241, lng: 21.9833837 },
    { lat: 49.0002003, lng: 21.9832229 },
    { lat: 49.0005835, lng: 21.9830279 },
    { lat: 49.0010198, lng: 21.9824232 },
    { lat: 49.0009845, lng: 21.9817245 },
    { lat: 49.0011367, lng: 21.981641 },
    { lat: 49.0016462, lng: 21.9817738 },
    { lat: 49.0019203, lng: 21.981993 },
    { lat: 49.0021425, lng: 21.9820202 },
    { lat: 49.0023136, lng: 21.9821162 },
    { lat: 49.0025153, lng: 21.9817669 },
    { lat: 49.0029733, lng: 21.9817299 },
    { lat: 49.0040736, lng: 21.9813635 },
    { lat: 49.0046507, lng: 21.9813184 },
    { lat: 49.0049442, lng: 21.9816385 },
    { lat: 49.0049803, lng: 21.9813237 },
    { lat: 49.004997, lng: 21.9811759 },
    { lat: 49.0048028, lng: 21.9796356 },
    { lat: 49.0050799, lng: 21.9794683 },
    { lat: 49.0068217, lng: 21.9793988 },
    { lat: 49.0074259, lng: 21.9790367 },
    { lat: 49.0082858, lng: 21.9790579 },
    { lat: 49.0084287, lng: 21.9789663 },
    { lat: 49.0093166, lng: 21.9779759 },
    { lat: 49.0097279, lng: 21.9776853 },
    { lat: 49.0099948, lng: 21.9777444 },
    { lat: 49.0101775, lng: 21.9779486 },
    { lat: 49.0106939, lng: 21.977995 },
    { lat: 49.011152, lng: 21.9778669 },
    { lat: 49.0121635, lng: 21.9781114 },
    { lat: 49.0124604, lng: 21.9780204 },
    { lat: 49.0128134, lng: 21.978191 },
    { lat: 49.0144973, lng: 21.9771425 },
    { lat: 49.0156205, lng: 21.976288 },
    { lat: 49.0161263, lng: 21.9760941 },
    { lat: 49.0163026, lng: 21.9759429 },
    { lat: 49.0163696, lng: 21.9754238 },
    { lat: 49.0174507, lng: 21.9749687 },
    { lat: 49.0175216, lng: 21.9748328 },
    { lat: 49.017995, lng: 21.9743662 },
    { lat: 49.0183682, lng: 21.9743615 },
    { lat: 49.0187704, lng: 21.9737872 },
    { lat: 49.0189126, lng: 21.9734444 },
    { lat: 49.0192993, lng: 21.9731046 },
    { lat: 49.0195869, lng: 21.9725873 },
    { lat: 49.019865, lng: 21.9724611 },
    { lat: 49.0200875, lng: 21.9721324 },
    { lat: 49.0203714, lng: 21.9712375 },
    { lat: 49.0206304, lng: 21.9711114 },
    { lat: 49.0207791, lng: 21.970704 },
    { lat: 49.020975, lng: 21.9705811 },
    { lat: 49.0209667, lng: 21.9704654 },
    { lat: 49.021285, lng: 21.9701037 },
    { lat: 49.0214367, lng: 21.9700893 },
    { lat: 49.0216151, lng: 21.9696215 },
    { lat: 49.0217184, lng: 21.9696608 },
    { lat: 49.0218997, lng: 21.9695745 },
    { lat: 49.0219453, lng: 21.9694164 },
    { lat: 49.0218489, lng: 21.9694255 },
    { lat: 49.0217544, lng: 21.9692448 },
    { lat: 49.0215856, lng: 21.9691956 },
    { lat: 49.0213525, lng: 21.9690042 },
    { lat: 49.0212763, lng: 21.968829 },
    { lat: 49.0210991, lng: 21.9686198 },
    { lat: 49.0190639, lng: 21.9673328 },
    { lat: 49.0189022, lng: 21.9675727 },
    { lat: 49.0187538, lng: 21.9678894 },
    { lat: 49.017937, lng: 21.9689328 },
    { lat: 49.0178488, lng: 21.9692381 },
    { lat: 49.0170667, lng: 21.9696402 },
    { lat: 49.016865, lng: 21.9698534 },
    { lat: 49.0162727, lng: 21.9702379 },
    { lat: 49.0159656, lng: 21.9702768 },
    { lat: 49.015466, lng: 21.9705023 },
    { lat: 49.0148443, lng: 21.9705641 },
    { lat: 49.0146391, lng: 21.9704517 },
    { lat: 49.0126411, lng: 21.969975 },
    { lat: 49.0117781, lng: 21.9696976 },
    { lat: 49.0115887, lng: 21.9694522 },
    { lat: 49.0112434, lng: 21.9694972 },
    { lat: 49.0112191, lng: 21.969375 },
    { lat: 49.0108177, lng: 21.9695196 },
    { lat: 49.0105621, lng: 21.968547 },
    { lat: 49.0105496, lng: 21.9682195 },
    { lat: 49.0099732, lng: 21.96537 },
    { lat: 49.0099626, lng: 21.964989 },
    { lat: 49.0098337, lng: 21.9643672 },
    { lat: 49.009701, lng: 21.9640042 },
    { lat: 49.0096585, lng: 21.9635975 },
    { lat: 49.0097214, lng: 21.9626781 },
    { lat: 49.0097369, lng: 21.9612973 },
    { lat: 49.0099125, lng: 21.9601225 },
    { lat: 49.0100631, lng: 21.9583334 },
    { lat: 49.0100329, lng: 21.9577998 },
    { lat: 49.0100684, lng: 21.95705 },
    { lat: 49.0101406, lng: 21.9567381 },
    { lat: 49.0100148, lng: 21.9563791 },
    { lat: 49.0099001, lng: 21.9561986 },
    { lat: 49.0096406, lng: 21.9561633 },
    { lat: 49.0089937, lng: 21.9563482 },
    { lat: 49.0085567, lng: 21.9565483 },
    { lat: 49.0082779, lng: 21.9561873 },
    { lat: 49.0076003, lng: 21.9561736 },
    { lat: 49.0072278, lng: 21.9563013 },
    { lat: 49.0071509, lng: 21.9560151 },
    { lat: 49.0062154, lng: 21.9562548 },
    { lat: 49.0054289, lng: 21.9567027 },
    { lat: 49.0046995, lng: 21.9569582 },
    { lat: 49.0040792, lng: 21.9562069 },
    { lat: 49.0038406, lng: 21.9554237 },
    { lat: 49.0037219, lng: 21.9553964 },
    { lat: 49.0036811, lng: 21.9545196 },
    { lat: 49.0024772, lng: 21.9513296 },
    { lat: 48.9999294, lng: 21.9500541 },
    { lat: 48.9990259, lng: 21.9494523 },
    { lat: 48.999015, lng: 21.9493734 },
    { lat: 48.9979237, lng: 21.9485012 },
    { lat: 48.9978256, lng: 21.9482213 },
    { lat: 48.9971033, lng: 21.9484471 },
    { lat: 48.9965383, lng: 21.9482946 },
    { lat: 48.9964282, lng: 21.9482107 },
    { lat: 48.9966058, lng: 21.9475181 },
    { lat: 48.9960835, lng: 21.9476927 },
    { lat: 48.9960785, lng: 21.9475275 },
    { lat: 48.9960223, lng: 21.9473996 },
    { lat: 48.9951481, lng: 21.9473432 },
    { lat: 48.9952008, lng: 21.9471611 },
    { lat: 48.9946833, lng: 21.9470138 },
    { lat: 48.9946781, lng: 21.9466229 },
    { lat: 48.994494, lng: 21.9452805 },
    { lat: 48.9936191, lng: 21.9447443 },
    { lat: 48.9929808, lng: 21.9462648 },
    { lat: 48.9925218, lng: 21.9461736 },
    { lat: 48.9920391, lng: 21.9455787 },
    { lat: 48.9916047, lng: 21.9451584 },
    { lat: 48.9911187, lng: 21.9450193 },
    { lat: 48.9907982, lng: 21.9446232 },
    { lat: 48.9905045, lng: 21.9444309 },
    { lat: 48.989725, lng: 21.9441585 },
    { lat: 48.9896883, lng: 21.9440478 },
    { lat: 48.9891053, lng: 21.9441174 },
    { lat: 48.9886346, lng: 21.9416043 },
    { lat: 48.9881404, lng: 21.9394622 },
    { lat: 48.9875283, lng: 21.9391434 },
    { lat: 48.9873002, lng: 21.9374285 },
    { lat: 48.9863752, lng: 21.9373211 },
    { lat: 48.9861181, lng: 21.936529 },
    { lat: 48.9861915, lng: 21.93586 },
    { lat: 48.9861354, lng: 21.9356881 },
    { lat: 48.986156, lng: 21.9352482 },
    { lat: 48.9860905, lng: 21.9351055 },
    { lat: 48.9860926, lng: 21.9348314 },
    { lat: 48.9859233, lng: 21.9339497 },
    { lat: 48.9862003, lng: 21.9334582 },
    { lat: 48.9864179, lng: 21.93271 },
    { lat: 48.9866519, lng: 21.9323957 },
    { lat: 48.9870597, lng: 21.9316101 },
    { lat: 48.98747, lng: 21.9306539 },
    { lat: 48.9875833, lng: 21.9302359 },
    { lat: 48.9879566, lng: 21.9295991 },
    { lat: 48.9880285, lng: 21.9290512 },
    { lat: 48.9878691, lng: 21.9290189 },
    { lat: 48.9877694, lng: 21.9287657 },
    { lat: 48.9874625, lng: 21.9288134 },
    { lat: 48.9873329, lng: 21.9285415 },
    { lat: 48.9870775, lng: 21.9282604 },
    { lat: 48.9868877, lng: 21.9282287 },
    { lat: 48.9867388, lng: 21.9276743 },
    { lat: 48.9865711, lng: 21.9276725 },
    { lat: 48.9865193, lng: 21.9278048 },
    { lat: 48.9864766, lng: 21.9277585 },
    { lat: 48.9865284, lng: 21.9275378 },
    { lat: 48.986506, lng: 21.9274911 },
    { lat: 48.9864225, lng: 21.9275267 },
    { lat: 48.9862603, lng: 21.9273852 },
    { lat: 48.9861625, lng: 21.927383 },
    { lat: 48.9860758, lng: 21.9270284 },
    { lat: 48.9859427, lng: 21.9268692 },
    { lat: 48.9859737, lng: 21.9267152 },
    { lat: 48.9858495, lng: 21.9264301 },
    { lat: 48.9860581, lng: 21.9261387 },
    { lat: 48.9861147, lng: 21.9256126 },
    { lat: 48.9860142, lng: 21.9252756 },
    { lat: 48.986389, lng: 21.9237573 },
    { lat: 48.9863441, lng: 21.9236231 },
    { lat: 48.9864657, lng: 21.9230943 },
    { lat: 48.9866232, lng: 21.923049 },
    { lat: 48.9867226, lng: 21.9226597 },
    { lat: 48.9868822, lng: 21.9225652 },
    { lat: 48.9869724, lng: 21.9222968 },
    { lat: 48.9869688, lng: 21.9217934 },
    { lat: 48.987156, lng: 21.9215896 },
    { lat: 48.9871539, lng: 21.9211932 },
    { lat: 48.9873001, lng: 21.9209599 },
    { lat: 48.9876768, lng: 21.9205943 },
    { lat: 48.9881306, lng: 21.9200323 },
    { lat: 48.9883066, lng: 21.9199161 },
    { lat: 48.9885229, lng: 21.9195705 },
    { lat: 48.9886292, lng: 21.9195547 },
    { lat: 48.9888835, lng: 21.919194 },
    { lat: 48.9888338, lng: 21.9190529 },
    { lat: 48.988283, lng: 21.9183512 },
    { lat: 48.9874089, lng: 21.9169086 },
    { lat: 48.9868687, lng: 21.9154603 },
    { lat: 48.9862993, lng: 21.9121266 },
    { lat: 48.9862356, lng: 21.912107 },
    { lat: 48.9857323, lng: 21.9122168 },
    { lat: 48.9852775, lng: 21.9121575 },
    { lat: 48.9847769, lng: 21.9118036 },
    { lat: 48.9842728, lng: 21.9112519 },
    { lat: 48.9837188, lng: 21.9103407 },
    { lat: 48.9833112, lng: 21.9099131 },
    { lat: 48.9810808, lng: 21.9094357 },
    { lat: 48.9800652, lng: 21.9094394 },
    { lat: 48.9798026, lng: 21.9092867 },
    { lat: 48.9797873, lng: 21.9093084 },
    { lat: 48.9797349, lng: 21.9107603 },
    { lat: 48.9795263, lng: 21.9122145 },
    { lat: 48.9795797, lng: 21.9129071 },
    { lat: 48.9796861, lng: 21.9133425 },
    { lat: 48.9795833, lng: 21.9143923 },
    { lat: 48.9796252, lng: 21.9144594 },
    { lat: 48.9795482, lng: 21.9146572 },
    { lat: 48.9794682, lng: 21.9157265 },
    { lat: 48.979406, lng: 21.9158024 },
    { lat: 48.9793971, lng: 21.9161725 },
    { lat: 48.9792275, lng: 21.9165433 },
    { lat: 48.9792487, lng: 21.9167583 },
    { lat: 48.9791878, lng: 21.9169713 },
    { lat: 48.97925, lng: 21.9172141 },
    { lat: 48.9791542, lng: 21.9177013 },
    { lat: 48.9788111, lng: 21.9184226 },
    { lat: 48.9785826, lng: 21.9184065 },
    { lat: 48.9785751, lng: 21.9184771 },
    { lat: 48.9786958, lng: 21.9186653 },
    { lat: 48.9785728, lng: 21.9189664 },
    { lat: 48.9788488, lng: 21.9192505 },
    { lat: 48.9787523, lng: 21.9193975 },
    { lat: 48.9787275, lng: 21.9196557 },
    { lat: 48.9788057, lng: 21.9198149 },
    { lat: 48.9787321, lng: 21.9198402 },
    { lat: 48.9786891, lng: 21.9202807 },
    { lat: 48.9784416, lng: 21.9205997 },
    { lat: 48.9782933, lng: 21.9206469 },
    { lat: 48.9782434, lng: 21.9210344 },
    { lat: 48.9781422, lng: 21.9209933 },
    { lat: 48.977995, lng: 21.9214974 },
    { lat: 48.9778583, lng: 21.9217452 },
    { lat: 48.9777608, lng: 21.9222472 },
    { lat: 48.9773936, lng: 21.9234006 },
    { lat: 48.9770363, lng: 21.9239594 },
    { lat: 48.9770229, lng: 21.9241858 },
    { lat: 48.9768914, lng: 21.9244612 },
    { lat: 48.9767304, lng: 21.9245432 },
    { lat: 48.976546, lng: 21.9250906 },
    { lat: 48.9765581, lng: 21.9252635 },
    { lat: 48.9764562, lng: 21.9253647 },
    { lat: 48.9771463, lng: 21.9267372 },
  ],
  Brestov: [
    { lat: 48.9475479, lng: 21.8798582 },
    { lat: 48.9480023, lng: 21.8827379 },
    { lat: 48.9479699, lng: 21.8836782 },
    { lat: 48.948141, lng: 21.8858191 },
    { lat: 48.9482535, lng: 21.8887063 },
    { lat: 48.9485901, lng: 21.8923117 },
    { lat: 48.948818, lng: 21.8921599 },
    { lat: 48.9488804, lng: 21.8927651 },
    { lat: 48.9488106, lng: 21.8933797 },
    { lat: 48.9488257, lng: 21.8941164 },
    { lat: 48.9487951, lng: 21.8943229 },
    { lat: 48.9495251, lng: 21.8964206 },
    { lat: 48.9503323, lng: 21.8961353 },
    { lat: 48.9513832, lng: 21.8961006 },
    { lat: 48.9518187, lng: 21.8961919 },
    { lat: 48.9520292, lng: 21.8959302 },
    { lat: 48.9531389, lng: 21.8957772 },
    { lat: 48.9532283, lng: 21.8959274 },
    { lat: 48.9534936, lng: 21.8961499 },
    { lat: 48.9535543, lng: 21.8967212 },
    { lat: 48.9561277, lng: 21.8968467 },
    { lat: 48.9569524, lng: 21.8967576 },
    { lat: 48.9570516, lng: 21.8985508 },
    { lat: 48.9579429, lng: 21.8986533 },
    { lat: 48.9590138, lng: 21.8977222 },
    { lat: 48.9595275, lng: 21.8993955 },
    { lat: 48.9603164, lng: 21.8989283 },
    { lat: 48.9608682, lng: 21.9008236 },
    { lat: 48.9623874, lng: 21.8979903 },
    { lat: 48.9633577, lng: 21.8991703 },
    { lat: 48.9637632, lng: 21.9003145 },
    { lat: 48.9644335, lng: 21.900301 },
    { lat: 48.965139, lng: 21.8998733 },
    { lat: 48.9658511, lng: 21.9004044 },
    { lat: 48.9654923, lng: 21.9022389 },
    { lat: 48.9672047, lng: 21.9021218 },
    { lat: 48.9672453, lng: 21.9025244 },
    { lat: 48.9685917, lng: 21.9025625 },
    { lat: 48.9692902, lng: 21.902696 },
    { lat: 48.9698628, lng: 21.9029205 },
    { lat: 48.9703745, lng: 21.9029212 },
    { lat: 48.9708278, lng: 21.9030893 },
    { lat: 48.9710336, lng: 21.9034436 },
    { lat: 48.9714182, lng: 21.9034868 },
    { lat: 48.9722304, lng: 21.9034497 },
    { lat: 48.9726468, lng: 21.9033217 },
    { lat: 48.9728796, lng: 21.9034965 },
    { lat: 48.9731154, lng: 21.9039296 },
    { lat: 48.974009, lng: 21.9044825 },
    { lat: 48.9748328, lng: 21.9048092 },
    { lat: 48.9753185, lng: 21.9048437 },
    { lat: 48.975649, lng: 21.9051532 },
    { lat: 48.9757277, lng: 21.9054129 },
    { lat: 48.975722, lng: 21.9056843 },
    { lat: 48.9758318, lng: 21.9063765 },
    { lat: 48.9758496, lng: 21.906952 },
    { lat: 48.97599, lng: 21.9077929 },
    { lat: 48.9760796, lng: 21.9079635 },
    { lat: 48.9763946, lng: 21.9082743 },
    { lat: 48.9766554, lng: 21.908223 },
    { lat: 48.9768951, lng: 21.9083147 },
    { lat: 48.9771933, lng: 21.9085402 },
    { lat: 48.9777261, lng: 21.9084392 },
    { lat: 48.9782361, lng: 21.9085421 },
    { lat: 48.9794715, lng: 21.9090008 },
    { lat: 48.9798026, lng: 21.9092867 },
    { lat: 48.9800652, lng: 21.9094394 },
    { lat: 48.9810808, lng: 21.9094357 },
    { lat: 48.9833112, lng: 21.9099131 },
    { lat: 48.9837188, lng: 21.9103407 },
    { lat: 48.9842728, lng: 21.9112519 },
    { lat: 48.9847769, lng: 21.9118036 },
    { lat: 48.9852775, lng: 21.9121575 },
    { lat: 48.9857323, lng: 21.9122168 },
    { lat: 48.9862356, lng: 21.912107 },
    { lat: 48.9862993, lng: 21.9121266 },
    { lat: 48.9867899, lng: 21.9119138 },
    { lat: 48.987184, lng: 21.9116592 },
    { lat: 48.9875302, lng: 21.9112903 },
    { lat: 48.9880028, lng: 21.9109431 },
    { lat: 48.988531, lng: 21.9109214 },
    { lat: 48.9895383, lng: 21.9110776 },
    { lat: 48.9900433, lng: 21.9110229 },
    { lat: 48.9903946, lng: 21.9109062 },
    { lat: 48.990768, lng: 21.9105855 },
    { lat: 48.9915584, lng: 21.9101285 },
    { lat: 48.9924281, lng: 21.9093284 },
    { lat: 48.9930891, lng: 21.9092274 },
    { lat: 48.9935888, lng: 21.9093184 },
    { lat: 48.9937509, lng: 21.9092778 },
    { lat: 48.9950015, lng: 21.9099422 },
    { lat: 48.9957807, lng: 21.9102477 },
    { lat: 48.9957971, lng: 21.9084316 },
    { lat: 48.9958852, lng: 21.9071076 },
    { lat: 48.9960562, lng: 21.9063418 },
    { lat: 48.9964227, lng: 21.9040523 },
    { lat: 48.9977143, lng: 21.9028574 },
    { lat: 48.9981036, lng: 21.8995598 },
    { lat: 48.9981723, lng: 21.8981311 },
    { lat: 48.9992232, lng: 21.8974226 },
    { lat: 48.9999185, lng: 21.8966749 },
    { lat: 48.9999515, lng: 21.8963262 },
    { lat: 48.9988005, lng: 21.8941302 },
    { lat: 48.9981527, lng: 21.8929331 },
    { lat: 48.9991003, lng: 21.8916211 },
    { lat: 48.9984481, lng: 21.8908739 },
    { lat: 48.9977813, lng: 21.8897192 },
    { lat: 48.9971815, lng: 21.8885108 },
    { lat: 48.9959621, lng: 21.8867329 },
    { lat: 48.9956249, lng: 21.8866974 },
    { lat: 48.9953942, lng: 21.8868785 },
    { lat: 48.9947928, lng: 21.8871153 },
    { lat: 48.9939559, lng: 21.8875984 },
    { lat: 48.9930251, lng: 21.8883838 },
    { lat: 48.992426, lng: 21.8887584 },
    { lat: 48.991523, lng: 21.8895726 },
    { lat: 48.9913841, lng: 21.8891867 },
    { lat: 48.9910613, lng: 21.8890161 },
    { lat: 48.9909976, lng: 21.887923 },
    { lat: 48.9908261, lng: 21.8866121 },
    { lat: 48.9906842, lng: 21.8860254 },
    { lat: 48.9904879, lng: 21.8854706 },
    { lat: 48.9904113, lng: 21.8854618 },
    { lat: 48.9894317, lng: 21.8846734 },
    { lat: 48.9893192, lng: 21.8855626 },
    { lat: 48.9889419, lng: 21.8854539 },
    { lat: 48.9885738, lng: 21.8849575 },
    { lat: 48.9883671, lng: 21.8842117 },
    { lat: 48.9883469, lng: 21.8837218 },
    { lat: 48.9881609, lng: 21.8833939 },
    { lat: 48.9881178, lng: 21.8828953 },
    { lat: 48.9881335, lng: 21.881961 },
    { lat: 48.9879681, lng: 21.881648 },
    { lat: 48.9873321, lng: 21.8811909 },
    { lat: 48.9871561, lng: 21.880861 },
    { lat: 48.9863697, lng: 21.8803651 },
    { lat: 48.9862776, lng: 21.8798856 },
    { lat: 48.9865404, lng: 21.8795124 },
    { lat: 48.9862112, lng: 21.8786445 },
    { lat: 48.9862433, lng: 21.8783088 },
    { lat: 48.9858712, lng: 21.8779635 },
    { lat: 48.985492, lng: 21.8774277 },
    { lat: 48.9846652, lng: 21.8770762 },
    { lat: 48.9842039, lng: 21.8769832 },
    { lat: 48.9839633, lng: 21.8768114 },
    { lat: 48.9838722, lng: 21.8765489 },
    { lat: 48.9834983, lng: 21.8762592 },
    { lat: 48.9830777, lng: 21.8761797 },
    { lat: 48.9827718, lng: 21.876444 },
    { lat: 48.9825939, lng: 21.8767972 },
    { lat: 48.9823221, lng: 21.8771243 },
    { lat: 48.9820603, lng: 21.8773599 },
    { lat: 48.9817096, lng: 21.8775463 },
    { lat: 48.9815197, lng: 21.8778178 },
    { lat: 48.9813407, lng: 21.8778075 },
    { lat: 48.9812327, lng: 21.8778987 },
    { lat: 48.9804706, lng: 21.8772575 },
    { lat: 48.9800011, lng: 21.8758666 },
    { lat: 48.9787048, lng: 21.8763838 },
    { lat: 48.9771783, lng: 21.8768213 },
    { lat: 48.9762197, lng: 21.8769068 },
    { lat: 48.9742944, lng: 21.8777269 },
    { lat: 48.9717656, lng: 21.8781178 },
    { lat: 48.9715892, lng: 21.8774661 },
    { lat: 48.9713775, lng: 21.8773227 },
    { lat: 48.9709189, lng: 21.8774685 },
    { lat: 48.9701461, lng: 21.8760998 },
    { lat: 48.9689921, lng: 21.8766798 },
    { lat: 48.9688026, lng: 21.8763913 },
    { lat: 48.9685025, lng: 21.8766212 },
    { lat: 48.96626, lng: 21.8760258 },
    { lat: 48.964996, lng: 21.8751505 },
    { lat: 48.9647495, lng: 21.8755079 },
    { lat: 48.9634725, lng: 21.8761598 },
    { lat: 48.9621948, lng: 21.8769728 },
    { lat: 48.9613692, lng: 21.8774981 },
    { lat: 48.9601857, lng: 21.8779844 },
    { lat: 48.9591557, lng: 21.8782194 },
    { lat: 48.9591793, lng: 21.8778125 },
    { lat: 48.9590162, lng: 21.8777311 },
    { lat: 48.9583648, lng: 21.8776329 },
    { lat: 48.958065, lng: 21.8775223 },
    { lat: 48.9579694, lng: 21.8773994 },
    { lat: 48.9573445, lng: 21.8771024 },
    { lat: 48.9570261, lng: 21.877058 },
    { lat: 48.9568244, lng: 21.8769596 },
    { lat: 48.9566294, lng: 21.8769835 },
    { lat: 48.9563002, lng: 21.8767848 },
    { lat: 48.9561518, lng: 21.8767889 },
    { lat: 48.9559164, lng: 21.8766803 },
    { lat: 48.9557375, lng: 21.8768107 },
    { lat: 48.955622, lng: 21.8767365 },
    { lat: 48.955363, lng: 21.8767775 },
    { lat: 48.9551804, lng: 21.8766296 },
    { lat: 48.9550854, lng: 21.8766971 },
    { lat: 48.9549358, lng: 21.8765584 },
    { lat: 48.9546165, lng: 21.8764787 },
    { lat: 48.954519, lng: 21.8766315 },
    { lat: 48.9543251, lng: 21.8765237 },
    { lat: 48.9538352, lng: 21.8764099 },
    { lat: 48.9538455, lng: 21.8766845 },
    { lat: 48.9537757, lng: 21.8768612 },
    { lat: 48.9535015, lng: 21.8767852 },
    { lat: 48.9533221, lng: 21.8764954 },
    { lat: 48.9529421, lng: 21.8768133 },
    { lat: 48.9527596, lng: 21.8767618 },
    { lat: 48.95241, lng: 21.8769931 },
    { lat: 48.9523283, lng: 21.8771857 },
    { lat: 48.9521037, lng: 21.8771939 },
    { lat: 48.9510965, lng: 21.8779751 },
    { lat: 48.9505526, lng: 21.878575 },
    { lat: 48.9502055, lng: 21.8787739 },
    { lat: 48.9489728, lng: 21.8793099 },
    { lat: 48.9487811, lng: 21.8792996 },
    { lat: 48.9486494, lng: 21.8794204 },
    { lat: 48.9483475, lng: 21.8794044 },
    { lat: 48.9482037, lng: 21.8796383 },
    { lat: 48.948065, lng: 21.879446 },
    { lat: 48.947821, lng: 21.8794106 },
    { lat: 48.9477724, lng: 21.879621 },
    { lat: 48.9475479, lng: 21.8798582 },
  ],
  ModranadCirochou: [
    { lat: 48.9254004, lng: 22.041742 },
    { lat: 48.925516, lng: 22.0424825 },
    { lat: 48.9247167, lng: 22.0430607 },
    { lat: 48.9242017, lng: 22.0433478 },
    { lat: 48.924391, lng: 22.0438306 },
    { lat: 48.9246594, lng: 22.0443265 },
    { lat: 48.9246893, lng: 22.0448264 },
    { lat: 48.9248801, lng: 22.045087 },
    { lat: 48.9251108, lng: 22.0456186 },
    { lat: 48.9262055, lng: 22.0451504 },
    { lat: 48.9263676, lng: 22.0452069 },
    { lat: 48.9264031, lng: 22.0460785 },
    { lat: 48.9265889, lng: 22.0469743 },
    { lat: 48.926737, lng: 22.0480904 },
    { lat: 48.9271212, lng: 22.0487664 },
    { lat: 48.9271777, lng: 22.0493957 },
    { lat: 48.9271217, lng: 22.0499726 },
    { lat: 48.9272683, lng: 22.0507382 },
    { lat: 48.9273992, lng: 22.050941 },
    { lat: 48.9274922, lng: 22.0514885 },
    { lat: 48.9271064, lng: 22.0516382 },
    { lat: 48.9271494, lng: 22.052299 },
    { lat: 48.9274948, lng: 22.0528173 },
    { lat: 48.9277133, lng: 22.053487 },
    { lat: 48.927688, lng: 22.0536122 },
    { lat: 48.9278524, lng: 22.0540918 },
    { lat: 48.9274168, lng: 22.0540796 },
    { lat: 48.9268507, lng: 22.0542506 },
    { lat: 48.9255675, lng: 22.0554809 },
    { lat: 48.9249256, lng: 22.0557831 },
    { lat: 48.9229546, lng: 22.0569967 },
    { lat: 48.9223986, lng: 22.0575885 },
    { lat: 48.9220552, lng: 22.0578563 },
    { lat: 48.922201, lng: 22.0587299 },
    { lat: 48.922421, lng: 22.0620163 },
    { lat: 48.9224218, lng: 22.0627602 },
    { lat: 48.9226857, lng: 22.0643535 },
    { lat: 48.9227687, lng: 22.0643826 },
    { lat: 48.9228251, lng: 22.0640576 },
    { lat: 48.9231893, lng: 22.0636266 },
    { lat: 48.9233307, lng: 22.0631389 },
    { lat: 48.9233367, lng: 22.0626413 },
    { lat: 48.9238334, lng: 22.0632282 },
    { lat: 48.9252043, lng: 22.0651586 },
    { lat: 48.9260736, lng: 22.0664763 },
    { lat: 48.9276038, lng: 22.0685673 },
    { lat: 48.9285508, lng: 22.0684278 },
    { lat: 48.9304377, lng: 22.0679378 },
    { lat: 48.9322459, lng: 22.0693803 },
    { lat: 48.9359542, lng: 22.0726351 },
    { lat: 48.9363045, lng: 22.071855 },
    { lat: 48.9368795, lng: 22.0711037 },
    { lat: 48.9387967, lng: 22.0690914 },
    { lat: 48.93927, lng: 22.068397 },
    { lat: 48.939186, lng: 22.068087 },
    { lat: 48.939489, lng: 22.06764 },
    { lat: 48.939666, lng: 22.067322 },
    { lat: 48.939797, lng: 22.067093 },
    { lat: 48.939834, lng: 22.06703 },
    { lat: 48.940052, lng: 22.06665 },
    { lat: 48.940057, lng: 22.066642 },
    { lat: 48.940162, lng: 22.066405 },
    { lat: 48.940303, lng: 22.066085 },
    { lat: 48.940426, lng: 22.065906 },
    { lat: 48.940541, lng: 22.06564 },
    { lat: 48.940654, lng: 22.065294 },
    { lat: 48.940762, lng: 22.065132 },
    { lat: 48.94084, lng: 22.065059 },
    { lat: 48.940917, lng: 22.064941 },
    { lat: 48.941001, lng: 22.064856 },
    { lat: 48.941087, lng: 22.064803 },
    { lat: 48.941171, lng: 22.06475 },
    { lat: 48.941255, lng: 22.064705 },
    { lat: 48.941359, lng: 22.064592 },
    { lat: 48.941549, lng: 22.064474 },
    { lat: 48.941986, lng: 22.063926 },
    { lat: 48.942368, lng: 22.06362 },
    { lat: 48.942676, lng: 22.063391 },
    { lat: 48.942989, lng: 22.063109 },
    { lat: 48.943071, lng: 22.063013 },
    { lat: 48.943267, lng: 22.062832 },
    { lat: 48.943415, lng: 22.062728 },
    { lat: 48.943565, lng: 22.062625 },
    { lat: 48.943703, lng: 22.062552 },
    { lat: 48.943995, lng: 22.06231 },
    { lat: 48.94407, lng: 22.0622 },
    { lat: 48.94422, lng: 22.06202 },
    { lat: 48.944409, lng: 22.061853 },
    { lat: 48.944752, lng: 22.061601 },
    { lat: 48.944775, lng: 22.061173 },
    { lat: 48.944821, lng: 22.060539 },
    { lat: 48.94483, lng: 22.060289 },
    { lat: 48.944853, lng: 22.060126 },
    { lat: 48.944894, lng: 22.059946 },
    { lat: 48.944935, lng: 22.059736 },
    { lat: 48.944981, lng: 22.059636 },
    { lat: 48.945027, lng: 22.059581 },
    { lat: 48.945169, lng: 22.059413 },
    { lat: 48.945234, lng: 22.059356 },
    { lat: 48.94543, lng: 22.059258 },
    { lat: 48.945467, lng: 22.059247 },
    { lat: 48.945547, lng: 22.059196 },
    { lat: 48.94565, lng: 22.059176 },
    { lat: 48.945697, lng: 22.059189 },
    { lat: 48.945782, lng: 22.059214 },
    { lat: 48.945828, lng: 22.059191 },
    { lat: 48.945898, lng: 22.059201 },
    { lat: 48.94605, lng: 22.059169 },
    { lat: 48.946213, lng: 22.059104 },
    { lat: 48.946375, lng: 22.058997 },
    { lat: 48.946501, lng: 22.058939 },
    { lat: 48.946615, lng: 22.058878 },
    { lat: 48.946684, lng: 22.058851 },
    { lat: 48.946782, lng: 22.058861 },
    { lat: 48.946898, lng: 22.058812 },
    { lat: 48.946998, lng: 22.058711 },
    { lat: 48.947114, lng: 22.058629 },
    { lat: 48.947144, lng: 22.058603 },
    { lat: 48.947232, lng: 22.058523 },
    { lat: 48.947458, lng: 22.058379 },
    { lat: 48.947562, lng: 22.05825 },
    { lat: 48.947629, lng: 22.058166 },
    { lat: 48.947578, lng: 22.057974 },
    { lat: 48.947583, lng: 22.057918 },
    { lat: 48.947592, lng: 22.057793 },
    { lat: 48.947539, lng: 22.057344 },
    { lat: 48.947507, lng: 22.057196 },
    { lat: 48.947444, lng: 22.056991 },
    { lat: 48.947471, lng: 22.056825 },
    { lat: 48.947484, lng: 22.05668 },
    { lat: 48.947422, lng: 22.056205 },
    { lat: 48.947576, lng: 22.055823 },
    { lat: 48.947672, lng: 22.055673 },
    { lat: 48.947708, lng: 22.05558 },
    { lat: 48.94772, lng: 22.055359 },
    { lat: 48.947697, lng: 22.05499 },
    { lat: 48.947714, lng: 22.054892 },
    { lat: 48.947758, lng: 22.054844 },
    { lat: 48.947809, lng: 22.054748 },
    { lat: 48.947862, lng: 22.05439 },
    { lat: 48.947882, lng: 22.054095 },
    { lat: 48.947908, lng: 22.05397 },
    { lat: 48.947893, lng: 22.053821 },
    { lat: 48.94789, lng: 22.05378 },
    { lat: 48.947912, lng: 22.0537 },
    { lat: 48.94795, lng: 22.053645 },
    { lat: 48.947966, lng: 22.053543 },
    { lat: 48.947966, lng: 22.053469 },
    { lat: 48.947986, lng: 22.05337 },
    { lat: 48.948027, lng: 22.053277 },
    { lat: 48.948069, lng: 22.053069 },
    { lat: 48.948081, lng: 22.052996 },
    { lat: 48.948078, lng: 22.052888 },
    { lat: 48.948089, lng: 22.052791 },
    { lat: 48.948089, lng: 22.05259 },
    { lat: 48.948068, lng: 22.05241 },
    { lat: 48.948069, lng: 22.052132 },
    { lat: 48.948077, lng: 22.051791 },
    { lat: 48.948088, lng: 22.051723 },
    { lat: 48.948121, lng: 22.051651 },
    { lat: 48.948224, lng: 22.051497 },
    { lat: 48.948323, lng: 22.051376 },
    { lat: 48.948477, lng: 22.051187 },
    { lat: 48.94832, lng: 22.050958 },
    { lat: 48.948356, lng: 22.050806 },
    { lat: 48.948437, lng: 22.050581 },
    { lat: 48.948579, lng: 22.050249 },
    { lat: 48.948855, lng: 22.049722 },
    { lat: 48.948959, lng: 22.049508 },
    { lat: 48.949162, lng: 22.049157 },
    { lat: 48.949215, lng: 22.049098 },
    { lat: 48.949577, lng: 22.048643 },
    { lat: 48.949409, lng: 22.0482 },
    { lat: 48.949466, lng: 22.047798 },
    { lat: 48.949428, lng: 22.047659 },
    { lat: 48.949492, lng: 22.047378 },
    { lat: 48.949624, lng: 22.047445 },
    { lat: 48.949657, lng: 22.047283 },
    { lat: 48.949708, lng: 22.047235 },
    { lat: 48.949777, lng: 22.047325 },
    { lat: 48.949822, lng: 22.047216 },
    { lat: 48.949906, lng: 22.047252 },
    { lat: 48.949939, lng: 22.047152 },
    { lat: 48.949877, lng: 22.047046 },
    { lat: 48.949888, lng: 22.046965 },
    { lat: 48.949931, lng: 22.046933 },
    { lat: 48.949925, lng: 22.046562 },
    { lat: 48.949944, lng: 22.046245 },
    { lat: 48.949972, lng: 22.046106 },
    { lat: 48.950001, lng: 22.045746 },
    { lat: 48.950034, lng: 22.045468 },
    { lat: 48.950197, lng: 22.044869 },
    { lat: 48.950284, lng: 22.044673 },
    { lat: 48.950341, lng: 22.044509 },
    { lat: 48.950376, lng: 22.044343 },
    { lat: 48.950428, lng: 22.044182 },
    { lat: 48.950468, lng: 22.044091 },
    { lat: 48.950534, lng: 22.043833 },
    { lat: 48.950654, lng: 22.043526 },
    { lat: 48.9507, lng: 22.043353 },
    { lat: 48.950915, lng: 22.043058 },
    { lat: 48.950968, lng: 22.043112 },
    { lat: 48.951028, lng: 22.042999 },
    { lat: 48.951129, lng: 22.042809 },
    { lat: 48.951103, lng: 22.042733 },
    { lat: 48.950907, lng: 22.042184 },
    { lat: 48.950777, lng: 22.041879 },
    { lat: 48.950339, lng: 22.040679 },
    { lat: 48.950092, lng: 22.039925 },
    { lat: 48.949837, lng: 22.03906 },
    { lat: 48.949846, lng: 22.038279 },
    { lat: 48.949866, lng: 22.038245 },
    { lat: 48.949913, lng: 22.038159 },
    { lat: 48.949931, lng: 22.038127 },
    { lat: 48.94999, lng: 22.038023 },
    { lat: 48.949574, lng: 22.037411 },
    { lat: 48.949563, lng: 22.037363 },
    { lat: 48.949547, lng: 22.037299 },
    { lat: 48.949464, lng: 22.036953 },
    { lat: 48.949247, lng: 22.036526 },
    { lat: 48.949182, lng: 22.036239 },
    { lat: 48.949065, lng: 22.035898 },
    { lat: 48.948983, lng: 22.035706 },
    { lat: 48.948937, lng: 22.035433 },
    { lat: 48.948919, lng: 22.035229 },
    { lat: 48.948879, lng: 22.035089 },
    { lat: 48.948843, lng: 22.034828 },
    { lat: 48.948794, lng: 22.034563 },
    { lat: 48.948792, lng: 22.034315 },
    { lat: 48.948744, lng: 22.033968 },
    { lat: 48.948776, lng: 22.033797 },
    { lat: 48.948796, lng: 22.033217 },
    { lat: 48.948602, lng: 22.032564 },
    { lat: 48.948588, lng: 22.032264 },
    { lat: 48.948637, lng: 22.031948 },
    { lat: 48.948688, lng: 22.031749 },
    { lat: 48.948797, lng: 22.031594 },
    { lat: 48.948687, lng: 22.031111 },
    { lat: 48.94862, lng: 22.030882 },
    { lat: 48.94859, lng: 22.030805 },
    { lat: 48.948556, lng: 22.030696 },
    { lat: 48.948471, lng: 22.030429 },
    { lat: 48.94833, lng: 22.030136 },
    { lat: 48.948106, lng: 22.029318 },
    { lat: 48.949042, lng: 22.028566 },
    { lat: 48.948903, lng: 22.028022 },
    { lat: 48.94886, lng: 22.027854 },
    { lat: 48.948844, lng: 22.027657 },
    { lat: 48.948787, lng: 22.027347 },
    { lat: 48.948585, lng: 22.026516 },
    { lat: 48.948564, lng: 22.026383 },
    { lat: 48.948529, lng: 22.026164 },
    { lat: 48.948479, lng: 22.02594 },
    { lat: 48.948462, lng: 22.025804 },
    { lat: 48.948373, lng: 22.025346 },
    { lat: 48.94835, lng: 22.025216 },
    { lat: 48.948292, lng: 22.024939 },
    { lat: 48.948806, lng: 22.024618 },
    { lat: 48.94885, lng: 22.024585 },
    { lat: 48.949157, lng: 22.024353 },
    { lat: 48.949121, lng: 22.024219 },
    { lat: 48.949045, lng: 22.023913 },
    { lat: 48.948992, lng: 22.023681 },
    { lat: 48.948971, lng: 22.023463 },
    { lat: 48.948948, lng: 22.023311 },
    { lat: 48.949338, lng: 22.02307 },
    { lat: 48.950178, lng: 22.022554 },
    { lat: 48.950505, lng: 22.022356 },
    { lat: 48.95071, lng: 22.022248 },
    { lat: 48.950833, lng: 22.022216 },
    { lat: 48.950927, lng: 22.022136 },
    { lat: 48.951033, lng: 22.021979 },
    { lat: 48.951118, lng: 22.021859 },
    { lat: 48.951295, lng: 22.02177 },
    { lat: 48.951522, lng: 22.021689 },
    { lat: 48.9511183, lng: 22.0217062 },
    { lat: 48.9504606, lng: 22.0219078 },
    { lat: 48.9487364, lng: 22.0216611 },
    { lat: 48.9487179, lng: 22.0216585 },
    { lat: 48.9492576, lng: 22.0185357 },
    { lat: 48.9486574, lng: 22.0184636 },
    { lat: 48.947424, lng: 22.0163906 },
    { lat: 48.9463476, lng: 22.0181164 },
    { lat: 48.9461542, lng: 22.0181111 },
    { lat: 48.9453318, lng: 22.0185316 },
    { lat: 48.9452824, lng: 22.0184868 },
    { lat: 48.9450926, lng: 22.018541 },
    { lat: 48.9450364, lng: 22.018683 },
    { lat: 48.9445007, lng: 22.0189487 },
    { lat: 48.9441215, lng: 22.019225 },
    { lat: 48.9441277, lng: 22.0195295 },
    { lat: 48.944028, lng: 22.0195505 },
    { lat: 48.9439924, lng: 22.0194222 },
    { lat: 48.9438517, lng: 22.0195116 },
    { lat: 48.9438082, lng: 22.0196701 },
    { lat: 48.9437496, lng: 22.019712 },
    { lat: 48.9437571, lng: 22.0199273 },
    { lat: 48.9436916, lng: 22.0200054 },
    { lat: 48.9436735, lng: 22.0201445 },
    { lat: 48.9435094, lng: 22.0203383 },
    { lat: 48.9433305, lng: 22.0201891 },
    { lat: 48.9432285, lng: 22.0202999 },
    { lat: 48.9431162, lng: 22.0204543 },
    { lat: 48.9431126, lng: 22.0205755 },
    { lat: 48.9429412, lng: 22.0209854 },
    { lat: 48.9419404, lng: 22.0208483 },
    { lat: 48.9413956, lng: 22.021117 },
    { lat: 48.9412607, lng: 22.0212608 },
    { lat: 48.9409939, lng: 22.0212016 },
    { lat: 48.9407612, lng: 22.0215341 },
    { lat: 48.9405076, lng: 22.021652 },
    { lat: 48.9403866, lng: 22.0218115 },
    { lat: 48.9402393, lng: 22.0218616 },
    { lat: 48.9400099, lng: 22.0220933 },
    { lat: 48.9399643, lng: 22.0221685 },
    { lat: 48.939994, lng: 22.0223232 },
    { lat: 48.9397075, lng: 22.0224261 },
    { lat: 48.9396386, lng: 22.0226979 },
    { lat: 48.9394731, lng: 22.0227347 },
    { lat: 48.9392982, lng: 22.0226246 },
    { lat: 48.939095, lng: 22.0228677 },
    { lat: 48.9389863, lng: 22.0228877 },
    { lat: 48.9388227, lng: 22.0230507 },
    { lat: 48.9387161, lng: 22.0230609 },
    { lat: 48.9386746, lng: 22.0231663 },
    { lat: 48.9384539, lng: 22.0233875 },
    { lat: 48.9383114, lng: 22.024144 },
    { lat: 48.9380466, lng: 22.0242671 },
    { lat: 48.9379774, lng: 22.0242413 },
    { lat: 48.9378851, lng: 22.0245183 },
    { lat: 48.9379158, lng: 22.0247159 },
    { lat: 48.938, lng: 22.0248522 },
    { lat: 48.93788, lng: 22.0250492 },
    { lat: 48.9378572, lng: 22.0251764 },
    { lat: 48.9377141, lng: 22.0253525 },
    { lat: 48.937794, lng: 22.0253534 },
    { lat: 48.937621, lng: 22.0257416 },
    { lat: 48.9376838, lng: 22.0258744 },
    { lat: 48.9376521, lng: 22.0260436 },
    { lat: 48.9375593, lng: 22.0259776 },
    { lat: 48.9376953, lng: 22.0265754 },
    { lat: 48.9378078, lng: 22.0266271 },
    { lat: 48.9378281, lng: 22.0267265 },
    { lat: 48.9376503, lng: 22.0269082 },
    { lat: 48.9376774, lng: 22.0270541 },
    { lat: 48.9376174, lng: 22.0270295 },
    { lat: 48.9375616, lng: 22.0271489 },
    { lat: 48.9376246, lng: 22.0271439 },
    { lat: 48.9377368, lng: 22.0272683 },
    { lat: 48.9377413, lng: 22.0273668 },
    { lat: 48.9377016, lng: 22.0273558 },
    { lat: 48.9376361, lng: 22.0276174 },
    { lat: 48.9376734, lng: 22.0276811 },
    { lat: 48.9377258, lng: 22.0276163 },
    { lat: 48.9377663, lng: 22.0277024 },
    { lat: 48.9376523, lng: 22.0279685 },
    { lat: 48.9377342, lng: 22.0280033 },
    { lat: 48.9373558, lng: 22.0287303 },
    { lat: 48.9372855, lng: 22.0286222 },
    { lat: 48.9372661, lng: 22.0286973 },
    { lat: 48.9369442, lng: 22.0290136 },
    { lat: 48.9369361, lng: 22.0293307 },
    { lat: 48.9368643, lng: 22.029432 },
    { lat: 48.9368429, lng: 22.0296697 },
    { lat: 48.9367217, lng: 22.0298141 },
    { lat: 48.9365977, lng: 22.0301377 },
    { lat: 48.9365268, lng: 22.0301269 },
    { lat: 48.9364957, lng: 22.0303326 },
    { lat: 48.9363462, lng: 22.030576 },
    { lat: 48.9363732, lng: 22.0307587 },
    { lat: 48.9363174, lng: 22.0307797 },
    { lat: 48.9363141, lng: 22.0309173 },
    { lat: 48.9361623, lng: 22.0309253 },
    { lat: 48.9361225, lng: 22.0309977 },
    { lat: 48.9361521, lng: 22.0311585 },
    { lat: 48.9360069, lng: 22.0311994 },
    { lat: 48.9359728, lng: 22.0312786 },
    { lat: 48.935425, lng: 22.0316979 },
    { lat: 48.9354138, lng: 22.0319765 },
    { lat: 48.9352952, lng: 22.0321917 },
    { lat: 48.9352786, lng: 22.0323418 },
    { lat: 48.9352021, lng: 22.0324362 },
    { lat: 48.9350829, lng: 22.032339 },
    { lat: 48.9349595, lng: 22.0324514 },
    { lat: 48.9348763, lng: 22.0326399 },
    { lat: 48.9348788, lng: 22.0327425 },
    { lat: 48.9346061, lng: 22.0331468 },
    { lat: 48.9346492, lng: 22.0333387 },
    { lat: 48.9345228, lng: 22.0336981 },
    { lat: 48.9342565, lng: 22.0338989 },
    { lat: 48.934073, lng: 22.0343602 },
    { lat: 48.9338871, lng: 22.0343931 },
    { lat: 48.933586, lng: 22.0347826 },
    { lat: 48.9335627, lng: 22.0349409 },
    { lat: 48.933464, lng: 22.0350387 },
    { lat: 48.9333807, lng: 22.0352712 },
    { lat: 48.9333533, lng: 22.0354856 },
    { lat: 48.9331613, lng: 22.0356955 },
    { lat: 48.933148, lng: 22.0358382 },
    { lat: 48.9329845, lng: 22.0360562 },
    { lat: 48.9328674, lng: 22.0360978 },
    { lat: 48.9327883, lng: 22.0360328 },
    { lat: 48.9326085, lng: 22.0361826 },
    { lat: 48.9325585, lng: 22.0362977 },
    { lat: 48.9323583, lng: 22.0363911 },
    { lat: 48.9319694, lng: 22.036785 },
    { lat: 48.931776, lng: 22.0372522 },
    { lat: 48.9313821, lng: 22.0377573 },
    { lat: 48.9312934, lng: 22.0380513 },
    { lat: 48.9310799, lng: 22.0381998 },
    { lat: 48.9309479, lng: 22.0381305 },
    { lat: 48.9308114, lng: 22.0383563 },
    { lat: 48.9307855, lng: 22.0384937 },
    { lat: 48.9305009, lng: 22.0387749 },
    { lat: 48.930398, lng: 22.039031 },
    { lat: 48.9297639, lng: 22.0397514 },
    { lat: 48.9298343, lng: 22.0398199 },
    { lat: 48.9294485, lng: 22.0400508 },
    { lat: 48.9294286, lng: 22.0401639 },
    { lat: 48.9292957, lng: 22.0402911 },
    { lat: 48.9290612, lng: 22.0403613 },
    { lat: 48.9287598, lng: 22.0403139 },
    { lat: 48.92845, lng: 22.0404899 },
    { lat: 48.9282776, lng: 22.0403778 },
    { lat: 48.9282282, lng: 22.0404796 },
    { lat: 48.9280377, lng: 22.0406053 },
    { lat: 48.9280196, lng: 22.0407337 },
    { lat: 48.9273264, lng: 22.0412439 },
    { lat: 48.9271517, lng: 22.0412231 },
    { lat: 48.927106, lng: 22.0411065 },
    { lat: 48.9264674, lng: 22.0412499 },
    { lat: 48.926412, lng: 22.0413388 },
    { lat: 48.9261666, lng: 22.0413759 },
    { lat: 48.926042, lng: 22.0415753 },
    { lat: 48.9255815, lng: 22.0417474 },
    { lat: 48.9254004, lng: 22.041742 },
  ],
  Sopkovce: [
    { lat: 49.0225266, lng: 21.8557868 },
    { lat: 49.0222192, lng: 21.8554641 },
    { lat: 49.022065, lng: 21.8553699 },
    { lat: 49.0218555, lng: 21.8550952 },
    { lat: 49.021658, lng: 21.8554187 },
    { lat: 49.0215, lng: 21.8551865 },
    { lat: 49.0211843, lng: 21.855332 },
    { lat: 49.0210825, lng: 21.8552678 },
    { lat: 49.0205046, lng: 21.8552794 },
    { lat: 49.0203885, lng: 21.8551375 },
    { lat: 49.0201981, lng: 21.8551208 },
    { lat: 49.0198263, lng: 21.8552295 },
    { lat: 49.0197327, lng: 21.8554031 },
    { lat: 49.0196793, lng: 21.8552901 },
    { lat: 49.019368, lng: 21.8556992 },
    { lat: 49.0192313, lng: 21.8557635 },
    { lat: 49.0190592, lng: 21.855678 },
    { lat: 49.0184139, lng: 21.8559957 },
    { lat: 49.0182035, lng: 21.8560017 },
    { lat: 49.0179622, lng: 21.8561499 },
    { lat: 49.0178419, lng: 21.8563334 },
    { lat: 49.0175221, lng: 21.8564899 },
    { lat: 49.0174192, lng: 21.8563867 },
    { lat: 49.0174197, lng: 21.8560544 },
    { lat: 49.0175451, lng: 21.8557678 },
    { lat: 49.0175617, lng: 21.8556242 },
    { lat: 49.0171932, lng: 21.8552442 },
    { lat: 49.017083, lng: 21.8550289 },
    { lat: 49.0156538, lng: 21.8561895 },
    { lat: 49.0151629, lng: 21.8564801 },
    { lat: 49.014823, lng: 21.8566095 },
    { lat: 49.0145983, lng: 21.8560075 },
    { lat: 49.0141961, lng: 21.8562427 },
    { lat: 49.012929, lng: 21.8571887 },
    { lat: 49.011767, lng: 21.8583828 },
    { lat: 49.0116589, lng: 21.8581574 },
    { lat: 49.010986, lng: 21.8585468 },
    { lat: 49.0105825, lng: 21.8583857 },
    { lat: 49.0103459, lng: 21.8584158 },
    { lat: 49.0101306, lng: 21.8589176 },
    { lat: 49.0094138, lng: 21.8592624 },
    { lat: 49.0092988, lng: 21.8594748 },
    { lat: 49.0089928, lng: 21.8597398 },
    { lat: 49.0089527, lng: 21.8594909 },
    { lat: 49.0087574, lng: 21.8590241 },
    { lat: 49.0083759, lng: 21.8591029 },
    { lat: 49.0081188, lng: 21.8598279 },
    { lat: 49.0078443, lng: 21.8600504 },
    { lat: 49.0073041, lng: 21.8607477 },
    { lat: 49.0070128, lng: 21.8615822 },
    { lat: 49.0069233, lng: 21.862222 },
    { lat: 49.0070431, lng: 21.8623715 },
    { lat: 49.0072406, lng: 21.862887 },
    { lat: 49.007343, lng: 21.8634542 },
    { lat: 49.0068552, lng: 21.8636069 },
    { lat: 49.0071046, lng: 21.8645593 },
    { lat: 49.0069696, lng: 21.8646934 },
    { lat: 49.0071653, lng: 21.8656668 },
    { lat: 49.0070704, lng: 21.8660031 },
    { lat: 49.0075663, lng: 21.8678526 },
    { lat: 49.0070987, lng: 21.8684394 },
    { lat: 49.0071693, lng: 21.8686907 },
    { lat: 49.0062853, lng: 21.8698484 },
    { lat: 49.0060741, lng: 21.8703968 },
    { lat: 49.0060935, lng: 21.8706504 },
    { lat: 49.0061397, lng: 21.8712474 },
    { lat: 49.0059624, lng: 21.8722787 },
    { lat: 49.0058266, lng: 21.872779 },
    { lat: 49.0055173, lng: 21.8733142 },
    { lat: 49.0053181, lng: 21.8741012 },
    { lat: 49.0051338, lng: 21.8742869 },
    { lat: 49.0045119, lng: 21.874518 },
    { lat: 49.0036039, lng: 21.8753505 },
    { lat: 49.0035451, lng: 21.875599 },
    { lat: 49.0034385, lng: 21.8757464 },
    { lat: 49.0031669, lng: 21.8760036 },
    { lat: 49.0028882, lng: 21.8762674 },
    { lat: 49.0022711, lng: 21.8765587 },
    { lat: 49.0021653, lng: 21.8766792 },
    { lat: 49.0013249, lng: 21.8772071 },
    { lat: 49.0013212, lng: 21.8772449 },
    { lat: 49.0013114, lng: 21.8773437 },
    { lat: 49.0007797, lng: 21.8781999 },
    { lat: 48.9992765, lng: 21.8789787 },
    { lat: 48.9980155, lng: 21.8797848 },
    { lat: 48.9977045, lng: 21.8798074 },
    { lat: 48.9968892, lng: 21.8801714 },
    { lat: 48.9959443, lng: 21.8805671 },
    { lat: 48.9953844, lng: 21.8807123 },
    { lat: 48.9947395, lng: 21.8807888 },
    { lat: 48.9940909, lng: 21.881032 },
    { lat: 48.9946286, lng: 21.8818248 },
    { lat: 48.996169, lng: 21.8830884 },
    { lat: 48.996305, lng: 21.8832635 },
    { lat: 48.9959938, lng: 21.8845666 },
    { lat: 48.9961285, lng: 21.885574 },
    { lat: 48.9957502, lng: 21.8861839 },
    { lat: 48.9959621, lng: 21.8867329 },
    { lat: 48.9971815, lng: 21.8885108 },
    { lat: 48.9977813, lng: 21.8897192 },
    { lat: 48.9984481, lng: 21.8908739 },
    { lat: 48.9991003, lng: 21.8916211 },
    { lat: 48.9981527, lng: 21.8929331 },
    { lat: 48.9988005, lng: 21.8941302 },
    { lat: 48.9992034, lng: 21.8941454 },
    { lat: 49.0002263, lng: 21.8941592 },
    { lat: 49.0016652, lng: 21.8933223 },
    { lat: 49.0028551, lng: 21.8924135 },
    { lat: 49.005184, lng: 21.8907936 },
    { lat: 49.0069868, lng: 21.8913054 },
    { lat: 49.0086409, lng: 21.8910576 },
    { lat: 49.0092978, lng: 21.8911483 },
    { lat: 49.0109732, lng: 21.8903263 },
    { lat: 49.0137816, lng: 21.8871031 },
    { lat: 49.0149056, lng: 21.8867984 },
    { lat: 49.0160323, lng: 21.886648 },
    { lat: 49.0168636, lng: 21.886833 },
    { lat: 49.0170196, lng: 21.8867275 },
    { lat: 49.0173417, lng: 21.8846082 },
    { lat: 49.0174697, lng: 21.8845065 },
    { lat: 49.0201269, lng: 21.8854922 },
    { lat: 49.0210384, lng: 21.8852865 },
    { lat: 49.0219691, lng: 21.8845067 },
    { lat: 49.0222888, lng: 21.8840726 },
    { lat: 49.0248827, lng: 21.8825587 },
    { lat: 49.0259339, lng: 21.8817799 },
    { lat: 49.0264277, lng: 21.8820417 },
    { lat: 49.0273757, lng: 21.8822177 },
    { lat: 49.0280228, lng: 21.8818275 },
    { lat: 49.0292788, lng: 21.8818482 },
    { lat: 49.0295109, lng: 21.8808904 },
    { lat: 49.030844, lng: 21.880271 },
    { lat: 49.0309904, lng: 21.8786794 },
    { lat: 49.0312058, lng: 21.8786148 },
    { lat: 49.031191, lng: 21.878302 },
    { lat: 49.0317717, lng: 21.8772836 },
    { lat: 49.0326676, lng: 21.8765115 },
    { lat: 49.0327682, lng: 21.8753262 },
    { lat: 49.0326477, lng: 21.8752329 },
    { lat: 49.0332128, lng: 21.8745021 },
    { lat: 49.0342439, lng: 21.8740891 },
    { lat: 49.0346486, lng: 21.8737606 },
    { lat: 49.0348056, lng: 21.8740977 },
    { lat: 49.0354648, lng: 21.8739256 },
    { lat: 49.0361408, lng: 21.873526 },
    { lat: 49.0362604, lng: 21.8713665 },
    { lat: 49.0358933, lng: 21.8711805 },
    { lat: 49.0351381, lng: 21.8697431 },
    { lat: 49.0346147, lng: 21.8689336 },
    { lat: 49.0340587, lng: 21.8688799 },
    { lat: 49.0331774, lng: 21.8691673 },
    { lat: 49.0328276, lng: 21.866472 },
    { lat: 49.0324102, lng: 21.8656684 },
    { lat: 49.0324496, lng: 21.8651886 },
    { lat: 49.0318775, lng: 21.8631732 },
    { lat: 49.0313626, lng: 21.8628372 },
    { lat: 49.0290836, lng: 21.8598407 },
    { lat: 49.0288164, lng: 21.8586237 },
    { lat: 49.0288969, lng: 21.8574099 },
    { lat: 49.0283213, lng: 21.8564592 },
    { lat: 49.0277185, lng: 21.8556753 },
    { lat: 49.0266796, lng: 21.8560382 },
    { lat: 49.0252825, lng: 21.8555623 },
    { lat: 49.0236481, lng: 21.8559614 },
    { lat: 49.0225266, lng: 21.8557868 },
  ],
  Kamienka: [
    { lat: 48.9209082, lng: 21.9860778 },
    { lat: 48.9205268, lng: 21.9852044 },
    { lat: 48.9200423, lng: 21.9846681 },
    { lat: 48.9199697, lng: 21.9843696 },
    { lat: 48.9198463, lng: 21.984104 },
    { lat: 48.9197535, lng: 21.9833485 },
    { lat: 48.9197347, lng: 21.9824868 },
    { lat: 48.9194965, lng: 21.9817199 },
    { lat: 48.9194138, lng: 21.9816243 },
    { lat: 48.9191433, lng: 21.9813117 },
    { lat: 48.9190939, lng: 21.9810394 },
    { lat: 48.9186548, lng: 21.9805195 },
    { lat: 48.9184838, lng: 21.9802363 },
    { lat: 48.9184125, lng: 21.9798984 },
    { lat: 48.9185241, lng: 21.9789968 },
    { lat: 48.9183067, lng: 21.9776014 },
    { lat: 48.9183721, lng: 21.9770587 },
    { lat: 48.9170221, lng: 21.9791704 },
    { lat: 48.9160566, lng: 21.9804731 },
    { lat: 48.9153691, lng: 21.981017 },
    { lat: 48.9148868, lng: 21.9812169 },
    { lat: 48.9142345, lng: 21.9813589 },
    { lat: 48.9138788, lng: 21.9815782 },
    { lat: 48.913898, lng: 21.9816218 },
    { lat: 48.9116886, lng: 21.9840503 },
    { lat: 48.9115172, lng: 21.9837982 },
    { lat: 48.9112676, lng: 21.9838963 },
    { lat: 48.9111143, lng: 21.9836507 },
    { lat: 48.9105571, lng: 21.9834997 },
    { lat: 48.9104935, lng: 21.9832981 },
    { lat: 48.9101369, lng: 21.9829248 },
    { lat: 48.9088364, lng: 21.982739 },
    { lat: 48.9083417, lng: 21.9821475 },
    { lat: 48.9078994, lng: 21.9821904 },
    { lat: 48.9074873, lng: 21.981851 },
    { lat: 48.9070567, lng: 21.9818135 },
    { lat: 48.9064964, lng: 21.9815347 },
    { lat: 48.9064682, lng: 21.9812109 },
    { lat: 48.906343, lng: 21.9807414 },
    { lat: 48.9063899, lng: 21.980586 },
    { lat: 48.9063565, lng: 21.9805014 },
    { lat: 48.9064209, lng: 21.9799685 },
    { lat: 48.906628, lng: 21.9800219 },
    { lat: 48.906625, lng: 21.9798984 },
    { lat: 48.9063644, lng: 21.9793639 },
    { lat: 48.9049902, lng: 21.9805664 },
    { lat: 48.9047504, lng: 21.9806089 },
    { lat: 48.9043858, lng: 21.9810539 },
    { lat: 48.9042265, lng: 21.9810768 },
    { lat: 48.9038972, lng: 21.9813132 },
    { lat: 48.8991702, lng: 21.9840065 },
    { lat: 48.8952491, lng: 21.9868556 },
    { lat: 48.8952717, lng: 21.9871793 },
    { lat: 48.8956254, lng: 21.9907886 },
    { lat: 48.8956574, lng: 21.9918164 },
    { lat: 48.895428, lng: 21.9920077 },
    { lat: 48.8955484, lng: 21.9926015 },
    { lat: 48.8955517, lng: 21.9937986 },
    { lat: 48.8952241, lng: 21.9945724 },
    { lat: 48.8952441, lng: 21.9951125 },
    { lat: 48.8954888, lng: 21.996473 },
    { lat: 48.8953794, lng: 21.9977074 },
    { lat: 48.9001106, lng: 21.9994176 },
    { lat: 48.9007582, lng: 22.0001506 },
    { lat: 48.9014762, lng: 22.0017761 },
    { lat: 48.9006652, lng: 22.0029281 },
    { lat: 48.9005017, lng: 22.0033781 },
    { lat: 48.9012349, lng: 22.0046235 },
    { lat: 48.9014595, lng: 22.0042333 },
    { lat: 48.902029, lng: 22.0029951 },
    { lat: 48.9021377, lng: 22.0032611 },
    { lat: 48.9025465, lng: 22.0032339 },
    { lat: 48.9026894, lng: 22.0031394 },
    { lat: 48.902632, lng: 22.0034136 },
    { lat: 48.9026829, lng: 22.0037036 },
    { lat: 48.9024889, lng: 22.0042372 },
    { lat: 48.9024288, lng: 22.0045491 },
    { lat: 48.9022779, lng: 22.0046795 },
    { lat: 48.9020127, lng: 22.0047791 },
    { lat: 48.9019645, lng: 22.0048783 },
    { lat: 48.9019833, lng: 22.0058851 },
    { lat: 48.901874, lng: 22.0066139 },
    { lat: 48.9017501, lng: 22.0072093 },
    { lat: 48.9015258, lng: 22.0076108 },
    { lat: 48.9013941, lng: 22.0080673 },
    { lat: 48.9013041, lng: 22.0088056 },
    { lat: 48.9015956, lng: 22.0101526 },
    { lat: 48.9015617, lng: 22.0105635 },
    { lat: 48.9014665, lng: 22.0108634 },
    { lat: 48.9013352, lng: 22.0111561 },
    { lat: 48.9010955, lng: 22.0114272 },
    { lat: 48.9008822, lng: 22.012186 },
    { lat: 48.9007911, lng: 22.0128801 },
    { lat: 48.9008281, lng: 22.0132477 },
    { lat: 48.9010093, lng: 22.0139973 },
    { lat: 48.9009353, lng: 22.014463 },
    { lat: 48.9007674, lng: 22.0146837 },
    { lat: 48.9006524, lng: 22.0150909 },
    { lat: 48.9005094, lng: 22.0149995 },
    { lat: 48.899998, lng: 22.0151002 },
    { lat: 48.8994379, lng: 22.0161261 },
    { lat: 48.8993507, lng: 22.016097 },
    { lat: 48.899005, lng: 22.0167841 },
    { lat: 48.899033, lng: 22.0168684 },
    { lat: 48.898977, lng: 22.0169709 },
    { lat: 48.8990757, lng: 22.0170076 },
    { lat: 48.8991611, lng: 22.0172817 },
    { lat: 48.8995312, lng: 22.017517 },
    { lat: 48.8995, lng: 22.0175702 },
    { lat: 48.8999669, lng: 22.0181845 },
    { lat: 48.9000624, lng: 22.0182711 },
    { lat: 48.900114, lng: 22.018176 },
    { lat: 48.9003277, lng: 22.0182696 },
    { lat: 48.9006706, lng: 22.0188607 },
    { lat: 48.9008971, lng: 22.0194313 },
    { lat: 48.9013754, lng: 22.020233 },
    { lat: 48.9014471, lng: 22.0198086 },
    { lat: 48.9017715, lng: 22.0194087 },
    { lat: 48.9019732, lng: 22.0194654 },
    { lat: 48.9024755, lng: 22.0202333 },
    { lat: 48.9026052, lng: 22.0204313 },
    { lat: 48.9026638, lng: 22.0203497 },
    { lat: 48.9031887, lng: 22.0208872 },
    { lat: 48.9030895, lng: 22.0214426 },
    { lat: 48.9031465, lng: 22.0221699 },
    { lat: 48.9033154, lng: 22.0223639 },
    { lat: 48.9035293, lng: 22.023341 },
    { lat: 48.9035346, lng: 22.0235231 },
    { lat: 48.9040493, lng: 22.0240061 },
    { lat: 48.9042884, lng: 22.0244977 },
    { lat: 48.9049236, lng: 22.0203912 },
    { lat: 48.9061927, lng: 22.0180364 },
    { lat: 48.908917, lng: 22.0150621 },
    { lat: 48.9110105, lng: 22.0142305 },
    { lat: 48.9125643, lng: 22.014903 },
    { lat: 48.9126154, lng: 22.0149699 },
    { lat: 48.9132425, lng: 22.014623 },
    { lat: 48.9136273, lng: 22.0139274 },
    { lat: 48.9139006, lng: 22.0135521 },
    { lat: 48.914254, lng: 22.0133335 },
    { lat: 48.9146296, lng: 22.0128215 },
    { lat: 48.9147039, lng: 22.0126299 },
    { lat: 48.9146062, lng: 22.0124988 },
    { lat: 48.9150284, lng: 22.0104825 },
    { lat: 48.9155243, lng: 22.0089473 },
    { lat: 48.9157881, lng: 22.0082799 },
    { lat: 48.9160557, lng: 22.0072525 },
    { lat: 48.9161775, lng: 22.0056041 },
    { lat: 48.91738, lng: 22.0053275 },
    { lat: 48.9174268, lng: 22.0030857 },
    { lat: 48.9173865, lng: 21.9986617 },
    { lat: 48.9176231, lng: 21.9971041 },
    { lat: 48.9180045, lng: 21.995685 },
    { lat: 48.918131, lng: 21.9953806 },
    { lat: 48.9181762, lng: 21.9940487 },
    { lat: 48.9181582, lng: 21.9937722 },
    { lat: 48.9180363, lng: 21.9936159 },
    { lat: 48.9181169, lng: 21.9924111 },
    { lat: 48.9183359, lng: 21.9910987 },
    { lat: 48.918263, lng: 21.9899587 },
    { lat: 48.9209082, lng: 21.9860778 },
  ],
  ZbudskéDlhé: [
    { lat: 49.0633203, lng: 21.941551 },
    { lat: 49.0635532, lng: 21.9416463 },
    { lat: 49.0636466, lng: 21.9433311 },
    { lat: 49.0638379, lng: 21.9452901 },
    { lat: 49.0646229, lng: 21.9473238 },
    { lat: 49.0653359, lng: 21.9490011 },
    { lat: 49.0659053, lng: 21.950695 },
    { lat: 49.065237, lng: 21.9519679 },
    { lat: 49.0630497, lng: 21.9576344 },
    { lat: 49.0628626, lng: 21.9585364 },
    { lat: 49.0628806, lng: 21.9596298 },
    { lat: 49.0627626, lng: 21.9597649 },
    { lat: 49.0628059, lng: 21.9600702 },
    { lat: 49.0629117, lng: 21.9603443 },
    { lat: 49.063073, lng: 21.9623226 },
    { lat: 49.063002, lng: 21.9629601 },
    { lat: 49.0631707, lng: 21.9639655 },
    { lat: 49.0640985, lng: 21.963908 },
    { lat: 49.0644685, lng: 21.9651438 },
    { lat: 49.0652404, lng: 21.9652476 },
    { lat: 49.0654975, lng: 21.9663288 },
    { lat: 49.0659556, lng: 21.9675722 },
    { lat: 49.0665993, lng: 21.9689633 },
    { lat: 49.0674314, lng: 21.9687186 },
    { lat: 49.0677155, lng: 21.968837 },
    { lat: 49.06811, lng: 21.9686026 },
    { lat: 49.0687407, lng: 21.9688594 },
    { lat: 49.0690324, lng: 21.9694236 },
    { lat: 49.0692319, lng: 21.9694474 },
    { lat: 49.0694067, lng: 21.9702894 },
    { lat: 49.0701079, lng: 21.9704899 },
    { lat: 49.0703335, lng: 21.970786 },
    { lat: 49.0716825, lng: 21.9711276 },
    { lat: 49.0717193, lng: 21.971754 },
    { lat: 49.0726014, lng: 21.9726306 },
    { lat: 49.0736561, lng: 21.9727554 },
    { lat: 49.0746206, lng: 21.9723239 },
    { lat: 49.075077, lng: 21.9717135 },
    { lat: 49.0758112, lng: 21.9710569 },
    { lat: 49.0765655, lng: 21.9702139 },
    { lat: 49.0766262, lng: 21.9702582 },
    { lat: 49.0767911, lng: 21.9701106 },
    { lat: 49.0768294, lng: 21.9701526 },
    { lat: 49.0770644, lng: 21.9699218 },
    { lat: 49.077573, lng: 21.9696543 },
    { lat: 49.0776283, lng: 21.9697082 },
    { lat: 49.0778541, lng: 21.9695612 },
    { lat: 49.0778402, lng: 21.9694966 },
    { lat: 49.0783084, lng: 21.9693265 },
    { lat: 49.0783914, lng: 21.9693559 },
    { lat: 49.0784228, lng: 21.9694456 },
    { lat: 49.0791282, lng: 21.9695027 },
    { lat: 49.0799148, lng: 21.9699746 },
    { lat: 49.0804889, lng: 21.9700378 },
    { lat: 49.0814292, lng: 21.9705426 },
    { lat: 49.0818065, lng: 21.9708394 },
    { lat: 49.0823245, lng: 21.9709991 },
    { lat: 49.0824737, lng: 21.9722217 },
    { lat: 49.0831791, lng: 21.9740655 },
    { lat: 49.0838143, lng: 21.975515 },
    { lat: 49.084011, lng: 21.9748784 },
    { lat: 49.0843518, lng: 21.9741844 },
    { lat: 49.084626, lng: 21.9738035 },
    { lat: 49.0848883, lng: 21.9730781 },
    { lat: 49.0854071, lng: 21.9729539 },
    { lat: 49.0854342, lng: 21.9725881 },
    { lat: 49.0857497, lng: 21.9718844 },
    { lat: 49.0866466, lng: 21.971578 },
    { lat: 49.087309, lng: 21.9708434 },
    { lat: 49.0871411, lng: 21.9704148 },
    { lat: 49.0875891, lng: 21.9700125 },
    { lat: 49.0876054, lng: 21.9697151 },
    { lat: 49.0875658, lng: 21.9697063 },
    { lat: 49.0878067, lng: 21.9694837 },
    { lat: 49.0880462, lng: 21.9694356 },
    { lat: 49.0882841, lng: 21.9691813 },
    { lat: 49.0885985, lng: 21.9690281 },
    { lat: 49.0888814, lng: 21.9690472 },
    { lat: 49.0891788, lng: 21.968933 },
    { lat: 49.0896749, lng: 21.9692059 },
    { lat: 49.089858, lng: 21.9694287 },
    { lat: 49.0899776, lng: 21.9699021 },
    { lat: 49.0913083, lng: 21.9695364 },
    { lat: 49.0918324, lng: 21.9692119 },
    { lat: 49.0927877, lng: 21.9684082 },
    { lat: 49.0939418, lng: 21.9676728 },
    { lat: 49.0950162, lng: 21.9668999 },
    { lat: 49.0957616, lng: 21.9665218 },
    { lat: 49.0968655, lng: 21.9658523 },
    { lat: 49.0967014, lng: 21.965268 },
    { lat: 49.097928, lng: 21.9644627 },
    { lat: 49.098779, lng: 21.9637587 },
    { lat: 49.098883, lng: 21.962322 },
    { lat: 49.0997221, lng: 21.9612688 },
    { lat: 49.0998865, lng: 21.9607249 },
    { lat: 49.1001554, lng: 21.9601309 },
    { lat: 49.1004003, lng: 21.9597583 },
    { lat: 49.100558, lng: 21.959144 },
    { lat: 49.100596, lng: 21.958477 },
    { lat: 49.100798, lng: 21.958332 },
    { lat: 49.100808, lng: 21.958162 },
    { lat: 49.100694, lng: 21.957106 },
    { lat: 49.100402, lng: 21.956637 },
    { lat: 49.100292, lng: 21.956141 },
    { lat: 49.100284, lng: 21.95484 },
    { lat: 49.100245, lng: 21.953535 },
    { lat: 49.100222, lng: 21.952625 },
    { lat: 49.099891, lng: 21.951907 },
    { lat: 49.099886, lng: 21.951724 },
    { lat: 49.099822, lng: 21.951564 },
    { lat: 49.099764, lng: 21.951253 },
    { lat: 49.099558, lng: 21.950961 },
    { lat: 49.099447, lng: 21.950765 },
    { lat: 49.099534, lng: 21.95066 },
    { lat: 49.099553, lng: 21.950645 },
    { lat: 49.099535, lng: 21.950536 },
    { lat: 49.099469, lng: 21.950477 },
    { lat: 49.09919, lng: 21.949956 },
    { lat: 49.099046, lng: 21.949502 },
    { lat: 49.099121, lng: 21.948993 },
    { lat: 49.099091, lng: 21.948647 },
    { lat: 49.099104, lng: 21.948645 },
    { lat: 49.098861, lng: 21.947255 },
    { lat: 49.0978177, lng: 21.9477669 },
    { lat: 49.0964874, lng: 21.9479555 },
    { lat: 49.0962814, lng: 21.9477667 },
    { lat: 49.0947166, lng: 21.9476238 },
    { lat: 49.0929004, lng: 21.9486877 },
    { lat: 49.0920617, lng: 21.9495695 },
    { lat: 49.0915533, lng: 21.9499309 },
    { lat: 49.0908895, lng: 21.951248 },
    { lat: 49.0905697, lng: 21.951772 },
    { lat: 49.089793, lng: 21.952391 },
    { lat: 49.0887685, lng: 21.952674 },
    { lat: 49.0877756, lng: 21.9525047 },
    { lat: 49.0872693, lng: 21.9525445 },
    { lat: 49.0864122, lng: 21.9508958 },
    { lat: 49.0852974, lng: 21.9491158 },
    { lat: 49.0849647, lng: 21.9480347 },
    { lat: 49.0848932, lng: 21.9476982 },
    { lat: 49.0849014, lng: 21.9473884 },
    { lat: 49.0841172, lng: 21.945032 },
    { lat: 49.0833838, lng: 21.9428317 },
    { lat: 49.0844871, lng: 21.9416976 },
    { lat: 49.0838158, lng: 21.9406134 },
    { lat: 49.0831115, lng: 21.9392429 },
    { lat: 49.0820843, lng: 21.9384185 },
    { lat: 49.0804446, lng: 21.9363011 },
    { lat: 49.0792487, lng: 21.9350226 },
    { lat: 49.0767024, lng: 21.9328151 },
    { lat: 49.076036, lng: 21.9299036 },
    { lat: 49.0760494, lng: 21.9297611 },
    { lat: 49.0753941, lng: 21.9283874 },
    { lat: 49.073792, lng: 21.9309884 },
    { lat: 49.072717, lng: 21.9312771 },
    { lat: 49.0714265, lng: 21.9318426 },
    { lat: 49.071352, lng: 21.9317464 },
    { lat: 49.0709649, lng: 21.9318789 },
    { lat: 49.0706442, lng: 21.9318235 },
    { lat: 49.0700324, lng: 21.9319218 },
    { lat: 49.0694253, lng: 21.9323421 },
    { lat: 49.0683452, lng: 21.9328721 },
    { lat: 49.0676803, lng: 21.9333624 },
    { lat: 49.0672013, lng: 21.9338273 },
    { lat: 49.0665009, lng: 21.9347913 },
    { lat: 49.0655354, lng: 21.9359467 },
    { lat: 49.0646609, lng: 21.9366072 },
    { lat: 49.0646009, lng: 21.9368578 },
    { lat: 49.0643626, lng: 21.9372746 },
    { lat: 49.0641212, lng: 21.9380637 },
    { lat: 49.0637957, lng: 21.9399067 },
    { lat: 49.0633203, lng: 21.941551 },
  ],
  NižnáSitnica: [
    { lat: 49.0792893, lng: 21.8134305 },
    { lat: 49.0795888, lng: 21.8101877 },
    { lat: 49.0796652, lng: 21.8097887 },
    { lat: 49.0804388, lng: 21.8084336 },
    { lat: 49.0805972, lng: 21.8080767 },
    { lat: 49.0838783, lng: 21.8056815 },
    { lat: 49.0849502, lng: 21.8055054 },
    { lat: 49.0866637, lng: 21.8045626 },
    { lat: 49.0878875, lng: 21.8039958 },
    { lat: 49.0877575, lng: 21.8016324 },
    { lat: 49.0876592, lng: 21.8009231 },
    { lat: 49.0878587, lng: 21.7986411 },
    { lat: 49.0873719, lng: 21.7980058 },
    { lat: 49.0865488, lng: 21.7964691 },
    { lat: 49.0864593, lng: 21.7936727 },
    { lat: 49.0862046, lng: 21.7916719 },
    { lat: 49.0865014, lng: 21.7895466 },
    { lat: 49.0868834, lng: 21.7875108 },
    { lat: 49.0869359, lng: 21.7865871 },
    { lat: 49.0871736, lng: 21.7857864 },
    { lat: 49.0878601, lng: 21.7860075 },
    { lat: 49.0876524, lng: 21.7822 },
    { lat: 49.0881421, lng: 21.7802287 },
    { lat: 49.0885105, lng: 21.7763403 },
    { lat: 49.0893238, lng: 21.7751797 },
    { lat: 49.0901394, lng: 21.7728697 },
    { lat: 49.0903343, lng: 21.77206 },
    { lat: 49.0900366, lng: 21.7690345 },
    { lat: 49.0900745, lng: 21.7690228 },
    { lat: 49.090036, lng: 21.7683734 },
    { lat: 49.0905809, lng: 21.7667983 },
    { lat: 49.0910404, lng: 21.7652883 },
    { lat: 49.09177, lng: 21.764241 },
    { lat: 49.0922899, lng: 21.7639925 },
    { lat: 49.09295, lng: 21.7635111 },
    { lat: 49.0942534, lng: 21.7631125 },
    { lat: 49.0944357, lng: 21.7628986 },
    { lat: 49.0951251, lng: 21.7627248 },
    { lat: 49.0958247, lng: 21.7628494 },
    { lat: 49.0961883, lng: 21.7631944 },
    { lat: 49.0966004, lng: 21.7632953 },
    { lat: 49.0968711, lng: 21.7631104 },
    { lat: 49.0969437, lng: 21.7631824 },
    { lat: 49.0974418, lng: 21.7629603 },
    { lat: 49.0976007, lng: 21.7631384 },
    { lat: 49.0977071, lng: 21.7629467 },
    { lat: 49.0979102, lng: 21.7630578 },
    { lat: 49.0981057, lng: 21.7630089 },
    { lat: 49.0981615, lng: 21.7624325 },
    { lat: 49.098346, lng: 21.7622228 },
    { lat: 49.0983584, lng: 21.7615378 },
    { lat: 49.0986017, lng: 21.7614217 },
    { lat: 49.0987129, lng: 21.7611985 },
    { lat: 49.0991685, lng: 21.7592806 },
    { lat: 49.0993489, lng: 21.7589943 },
    { lat: 49.0993573, lng: 21.7587579 },
    { lat: 49.0993129, lng: 21.7586579 },
    { lat: 49.0995016, lng: 21.7581352 },
    { lat: 49.0999575, lng: 21.7573301 },
    { lat: 49.1001526, lng: 21.7571373 },
    { lat: 49.1002484, lng: 21.7560789 },
    { lat: 49.1002263, lng: 21.7556706 },
    { lat: 49.1007652, lng: 21.7548568 },
    { lat: 49.1007271, lng: 21.7540102 },
    { lat: 49.101183, lng: 21.7536351 },
    { lat: 49.10082, lng: 21.7531371 },
    { lat: 49.1004634, lng: 21.7528438 },
    { lat: 49.1003384, lng: 21.7528324 },
    { lat: 49.1002964, lng: 21.7526964 },
    { lat: 49.1001958, lng: 21.7527191 },
    { lat: 49.0995889, lng: 21.7522285 },
    { lat: 49.0994852, lng: 21.7522679 },
    { lat: 49.0986874, lng: 21.7516106 },
    { lat: 49.0985813, lng: 21.7513856 },
    { lat: 49.0983796, lng: 21.7511678 },
    { lat: 49.0983122, lng: 21.7509964 },
    { lat: 49.097722, lng: 21.750376 },
    { lat: 49.097602, lng: 21.749946 },
    { lat: 49.097533, lng: 21.75011 },
    { lat: 49.097013, lng: 21.750463 },
    { lat: 49.09658, lng: 21.750898 },
    { lat: 49.096436, lng: 21.750995 },
    { lat: 49.095964, lng: 21.751326 },
    { lat: 49.095769, lng: 21.751489 },
    { lat: 49.095436, lng: 21.75183 },
    { lat: 49.094838, lng: 21.752357 },
    { lat: 49.09424, lng: 21.752929 },
    { lat: 49.093997, lng: 21.753056 },
    { lat: 49.093395, lng: 21.75352 },
    { lat: 49.093236, lng: 21.753872 },
    { lat: 49.092728, lng: 21.754583 },
    { lat: 49.092008, lng: 21.755288 },
    { lat: 49.091471, lng: 21.755554 },
    { lat: 49.091296, lng: 21.755683 },
    { lat: 49.090665, lng: 21.755464 },
    { lat: 49.090219, lng: 21.755469 },
    { lat: 49.090056, lng: 21.755604 },
    { lat: 49.089776, lng: 21.75559 },
    { lat: 49.089295, lng: 21.755757 },
    { lat: 49.089128, lng: 21.755606 },
    { lat: 49.088998, lng: 21.755596 },
    { lat: 49.088806, lng: 21.755697 },
    { lat: 49.088654, lng: 21.755699 },
    { lat: 49.088508, lng: 21.755599 },
    { lat: 49.087962, lng: 21.755503 },
    { lat: 49.087652, lng: 21.755649 },
    { lat: 49.087678, lng: 21.755837 },
    { lat: 49.087803, lng: 21.756278 },
    { lat: 49.087784, lng: 21.756552 },
    { lat: 49.087772, lng: 21.756905 },
    { lat: 49.087804, lng: 21.758066 },
    { lat: 49.088004, lng: 21.758773 },
    { lat: 49.088021, lng: 21.759373 },
    { lat: 49.08798, lng: 21.760226 },
    { lat: 49.08791, lng: 21.761268 },
    { lat: 49.087847, lng: 21.761828 },
    { lat: 49.087592, lng: 21.76166 },
    { lat: 49.087207, lng: 21.761549 },
    { lat: 49.086693, lng: 21.761532 },
    { lat: 49.086254, lng: 21.761719 },
    { lat: 49.085694, lng: 21.761998 },
    { lat: 49.084952, lng: 21.762215 },
    { lat: 49.084549, lng: 21.762504 },
    { lat: 49.084419, lng: 21.762553 },
    { lat: 49.083816, lng: 21.763143 },
    { lat: 49.083702, lng: 21.763308 },
    { lat: 49.083073, lng: 21.764048 },
    { lat: 49.08284, lng: 21.76439 },
    { lat: 49.08264, lng: 21.764644 },
    { lat: 49.082492, lng: 21.764928 },
    { lat: 49.082211, lng: 21.765331 },
    { lat: 49.081815, lng: 21.765794 },
    { lat: 49.081284, lng: 21.766259 },
    { lat: 49.080049, lng: 21.767252 },
    { lat: 49.07969, lng: 21.767459 },
    { lat: 49.07914, lng: 21.767901 },
    { lat: 49.078674, lng: 21.768212 },
    { lat: 49.078225, lng: 21.768658 },
    { lat: 49.078109, lng: 21.768809 },
    { lat: 49.077845, lng: 21.768976 },
    { lat: 49.077719, lng: 21.768922 },
    { lat: 49.077439, lng: 21.768857 },
    { lat: 49.077011, lng: 21.768856 },
    { lat: 49.076456, lng: 21.768585 },
    { lat: 49.075816, lng: 21.768309 },
    { lat: 49.075408, lng: 21.767812 },
    { lat: 49.075149, lng: 21.767374 },
    { lat: 49.074878, lng: 21.767077 },
    { lat: 49.074529, lng: 21.766446 },
    { lat: 49.074378, lng: 21.766149 },
    { lat: 49.07419, lng: 21.765741 },
    { lat: 49.07398, lng: 21.765421 },
    { lat: 49.073808, lng: 21.765487 },
    { lat: 49.073666, lng: 21.765716 },
    { lat: 49.073501, lng: 21.765802 },
    { lat: 49.073273, lng: 21.765817 },
    { lat: 49.073202, lng: 21.765919 },
    { lat: 49.073124, lng: 21.766379 },
    { lat: 49.07303, lng: 21.766597 },
    { lat: 49.072907, lng: 21.766743 },
    { lat: 49.072892, lng: 21.767005 },
    { lat: 49.072508, lng: 21.767222 },
    { lat: 49.072264, lng: 21.767193 },
    { lat: 49.071772, lng: 21.767517 },
    { lat: 49.071756, lng: 21.767532 },
    { lat: 49.071678, lng: 21.7674 },
    { lat: 49.071643, lng: 21.767412 },
    { lat: 49.071626, lng: 21.767426 },
    { lat: 49.071649, lng: 21.767572 },
    { lat: 49.071582, lng: 21.767721 },
    { lat: 49.071469, lng: 21.767715 },
    { lat: 49.071463, lng: 21.767817 },
    { lat: 49.071452, lng: 21.767843 },
    { lat: 49.071376, lng: 21.767821 },
    { lat: 49.071302, lng: 21.767923 },
    { lat: 49.070966, lng: 21.768064 },
    { lat: 49.070801, lng: 21.767855 },
    { lat: 49.070703, lng: 21.767887 },
    { lat: 49.070611, lng: 21.767744 },
    { lat: 49.070517, lng: 21.767797 },
    { lat: 49.070462, lng: 21.767712 },
    { lat: 49.07023, lng: 21.767902 },
    { lat: 49.070054, lng: 21.767742 },
    { lat: 49.069835, lng: 21.767843 },
    { lat: 49.069596, lng: 21.768028 },
    { lat: 49.069587, lng: 21.768002 },
    { lat: 49.069371, lng: 21.767975 },
    { lat: 49.069282, lng: 21.768027 },
    { lat: 49.069291, lng: 21.768273 },
    { lat: 49.069149, lng: 21.768194 },
    { lat: 49.069059, lng: 21.768189 },
    { lat: 49.069018, lng: 21.768335 },
    { lat: 49.069008, lng: 21.768359 },
    { lat: 49.068881, lng: 21.768207 },
    { lat: 49.068657, lng: 21.76822 },
    { lat: 49.0686, lng: 21.768311 },
    { lat: 49.068008, lng: 21.768203 },
    { lat: 49.067566, lng: 21.76879 },
    { lat: 49.06753, lng: 21.768792 },
    { lat: 49.067427, lng: 21.768658 },
    { lat: 49.067391, lng: 21.768648 },
    { lat: 49.067218, lng: 21.768891 },
    { lat: 49.067182, lng: 21.768883 },
    { lat: 49.067128, lng: 21.768766 },
    { lat: 49.067088, lng: 21.768755 },
    { lat: 49.066969, lng: 21.768922 },
    { lat: 49.066712, lng: 21.768998 },
    { lat: 49.06661, lng: 21.76947 },
    { lat: 49.066591, lng: 21.769488 },
    { lat: 49.066579, lng: 21.769474 },
    { lat: 49.066421, lng: 21.769303 },
    { lat: 49.066404, lng: 21.769304 },
    { lat: 49.06621, lng: 21.769617 },
    { lat: 49.066161, lng: 21.769971 },
    { lat: 49.066341, lng: 21.770115 },
    { lat: 49.06631, lng: 21.770212 },
    { lat: 49.066188, lng: 21.770319 },
    { lat: 49.065965, lng: 21.770317 },
    { lat: 49.065957, lng: 21.770336 },
    { lat: 49.065995, lng: 21.770623 },
    { lat: 49.065953, lng: 21.770711 },
    { lat: 49.065872, lng: 21.770665 },
    { lat: 49.065746, lng: 21.770937 },
    { lat: 49.065834, lng: 21.771076 },
    { lat: 49.065776, lng: 21.771251 },
    { lat: 49.065583, lng: 21.771481 },
    { lat: 49.065589, lng: 21.771825 },
    { lat: 49.065571, lng: 21.771814 },
    { lat: 49.065113, lng: 21.773241 },
    { lat: 49.065062, lng: 21.773388 },
    { lat: 49.064976, lng: 21.773482 },
    { lat: 49.064828, lng: 21.773894 },
    { lat: 49.064636, lng: 21.774221 },
    { lat: 49.064453, lng: 21.774529 },
    { lat: 49.064273, lng: 21.77513 },
    { lat: 49.064128, lng: 21.775399 },
    { lat: 49.0640303, lng: 21.7759196 },
    { lat: 49.0638109, lng: 21.7762648 },
    { lat: 49.0637438, lng: 21.7763187 },
    { lat: 49.0635764, lng: 21.776237 },
    { lat: 49.0635843, lng: 21.7767571 },
    { lat: 49.0636899, lng: 21.7770079 },
    { lat: 49.0636812, lng: 21.7771971 },
    { lat: 49.0634538, lng: 21.7772726 },
    { lat: 49.0633467, lng: 21.7775201 },
    { lat: 49.063079, lng: 21.7778194 },
    { lat: 49.0630952, lng: 21.7776305 },
    { lat: 49.0629032, lng: 21.7777698 },
    { lat: 49.0628902, lng: 21.7778309 },
    { lat: 49.0629727, lng: 21.7779302 },
    { lat: 49.0629435, lng: 21.778064 },
    { lat: 49.0627655, lng: 21.7779636 },
    { lat: 49.062593, lng: 21.7780738 },
    { lat: 49.0626198, lng: 21.7782878 },
    { lat: 49.0625404, lng: 21.7785132 },
    { lat: 49.0624595, lng: 21.7782258 },
    { lat: 49.0622928, lng: 21.7782613 },
    { lat: 49.0622347, lng: 21.7783531 },
    { lat: 49.062092, lng: 21.7783245 },
    { lat: 49.0621086, lng: 21.7784813 },
    { lat: 49.0620484, lng: 21.7785847 },
    { lat: 49.0619251, lng: 21.7785204 },
    { lat: 49.0618479, lng: 21.7787963 },
    { lat: 49.0619598, lng: 21.7789032 },
    { lat: 49.0619548, lng: 21.779142 },
    { lat: 49.0618196, lng: 21.7792177 },
    { lat: 49.0616453, lng: 21.7791332 },
    { lat: 49.0616671, lng: 21.7794888 },
    { lat: 49.0615657, lng: 21.7794881 },
    { lat: 49.0613886, lng: 21.7796402 },
    { lat: 49.0615058, lng: 21.7800345 },
    { lat: 49.0615014, lng: 21.7801667 },
    { lat: 49.0614465, lng: 21.7802651 },
    { lat: 49.0612317, lng: 21.7803549 },
    { lat: 49.0611989, lng: 21.7805144 },
    { lat: 49.0612283, lng: 21.780645 },
    { lat: 49.0611611, lng: 21.7808807 },
    { lat: 49.0609833, lng: 21.7809123 },
    { lat: 49.0608531, lng: 21.7813169 },
    { lat: 49.0606156, lng: 21.781176 },
    { lat: 49.060102, lng: 21.7817194 },
    { lat: 49.0600355, lng: 21.7822686 },
    { lat: 49.0600443, lng: 21.7826042 },
    { lat: 49.0599155, lng: 21.7827533 },
    { lat: 49.0597084, lng: 21.7827128 },
    { lat: 49.0593834, lng: 21.7830108 },
    { lat: 49.0593658, lng: 21.78316 },
    { lat: 49.0592856, lng: 21.7832298 },
    { lat: 49.0591194, lng: 21.7832029 },
    { lat: 49.0590697, lng: 21.7837508 },
    { lat: 49.058979, lng: 21.7840716 },
    { lat: 49.0586581, lng: 21.7841835 },
    { lat: 49.0583817, lng: 21.7846293 },
    { lat: 49.0580462, lng: 21.7847446 },
    { lat: 49.0580494, lng: 21.7849116 },
    { lat: 49.0579868, lng: 21.785052 },
    { lat: 49.0578288, lng: 21.7851667 },
    { lat: 49.0576743, lng: 21.7854511 },
    { lat: 49.0574622, lng: 21.7855988 },
    { lat: 49.057349, lng: 21.7859651 },
    { lat: 49.0572132, lng: 21.7860196 },
    { lat: 49.0571255, lng: 21.7861895 },
    { lat: 49.0571861, lng: 21.7863157 },
    { lat: 49.0569871, lng: 21.7866218 },
    { lat: 49.0567179, lng: 21.7869932 },
    { lat: 49.0565955, lng: 21.7870436 },
    { lat: 49.0564172, lng: 21.7873636 },
    { lat: 49.056086, lng: 21.7877362 },
    { lat: 49.0558494, lng: 21.787899 },
    { lat: 49.0558736, lng: 21.787961 },
    { lat: 49.056135, lng: 21.7882382 },
    { lat: 49.0564184, lng: 21.7884019 },
    { lat: 49.0562232, lng: 21.789286 },
    { lat: 49.0577853, lng: 21.7894832 },
    { lat: 49.057962, lng: 21.7893175 },
    { lat: 49.0585235, lng: 21.789365 },
    { lat: 49.0588845, lng: 21.7898357 },
    { lat: 49.0587577, lng: 21.790452 },
    { lat: 49.0589442, lng: 21.7905703 },
    { lat: 49.0594587, lng: 21.7911102 },
    { lat: 49.0599509, lng: 21.7913463 },
    { lat: 49.0604841, lng: 21.7911717 },
    { lat: 49.0608117, lng: 21.7914421 },
    { lat: 49.0610461, lng: 21.7921855 },
    { lat: 49.0613094, lng: 21.7925846 },
    { lat: 49.061638, lng: 21.7928467 },
    { lat: 49.0619746, lng: 21.7929817 },
    { lat: 49.0624318, lng: 21.7935212 },
    { lat: 49.0636264, lng: 21.7945569 },
    { lat: 49.0634186, lng: 21.7948262 },
    { lat: 49.0634298, lng: 21.795209 },
    { lat: 49.0637586, lng: 21.7956964 },
    { lat: 49.0640251, lng: 21.7953051 },
    { lat: 49.06421, lng: 21.7952352 },
    { lat: 49.0643003, lng: 21.7952896 },
    { lat: 49.064889, lng: 21.796044 },
    { lat: 49.0656595, lng: 21.7965134 },
    { lat: 49.0657205, lng: 21.7966095 },
    { lat: 49.0657872, lng: 21.7969269 },
    { lat: 49.0660496, lng: 21.7970165 },
    { lat: 49.066213, lng: 21.7972115 },
    { lat: 49.0662278, lng: 21.7975995 },
    { lat: 49.0661293, lng: 21.797924 },
    { lat: 49.0661955, lng: 21.7982569 },
    { lat: 49.0664323, lng: 21.7982462 },
    { lat: 49.0667652, lng: 21.7978024 },
    { lat: 49.0670152, lng: 21.7977278 },
    { lat: 49.0672662, lng: 21.7978264 },
    { lat: 49.0679031, lng: 21.7982583 },
    { lat: 49.0680634, lng: 21.799067 },
    { lat: 49.0685496, lng: 21.7995873 },
    { lat: 49.0686533, lng: 21.799905 },
    { lat: 49.0685332, lng: 21.8002172 },
    { lat: 49.0685753, lng: 21.8004927 },
    { lat: 49.0688906, lng: 21.8004192 },
    { lat: 49.0690112, lng: 21.8006433 },
    { lat: 49.0690851, lng: 21.8012348 },
    { lat: 49.0690149, lng: 21.8016526 },
    { lat: 49.0687746, lng: 21.8019681 },
    { lat: 49.0690249, lng: 21.8022924 },
    { lat: 49.0692257, lng: 21.8027535 },
    { lat: 49.0695796, lng: 21.8027301 },
    { lat: 49.0696455, lng: 21.8029367 },
    { lat: 49.0693601, lng: 21.8040725 },
    { lat: 49.0695452, lng: 21.8041356 },
    { lat: 49.0697291, lng: 21.8044672 },
    { lat: 49.0697482, lng: 21.8046304 },
    { lat: 49.0701608, lng: 21.8052309 },
    { lat: 49.0704423, lng: 21.8052434 },
    { lat: 49.0706603, lng: 21.8056687 },
    { lat: 49.0710169, lng: 21.8060107 },
    { lat: 49.0711817, lng: 21.8059505 },
    { lat: 49.0713172, lng: 21.8060561 },
    { lat: 49.0714841, lng: 21.8066739 },
    { lat: 49.0717388, lng: 21.8068699 },
    { lat: 49.0720902, lng: 21.8069317 },
    { lat: 49.0724476, lng: 21.8071341 },
    { lat: 49.0733151, lng: 21.8078792 },
    { lat: 49.0735604, lng: 21.8083111 },
    { lat: 49.0735646, lng: 21.808693 },
    { lat: 49.0739037, lng: 21.8095789 },
    { lat: 49.0738215, lng: 21.8099262 },
    { lat: 49.0738916, lng: 21.8102409 },
    { lat: 49.0741757, lng: 21.8104869 },
    { lat: 49.0745307, lng: 21.8105527 },
    { lat: 49.0752711, lng: 21.8099055 },
    { lat: 49.0756241, lng: 21.8096823 },
    { lat: 49.07573, lng: 21.8093419 },
    { lat: 49.0764628, lng: 21.809403 },
    { lat: 49.0768279, lng: 21.8098651 },
    { lat: 49.0771278, lng: 21.8101133 },
    { lat: 49.0771625, lng: 21.8102322 },
    { lat: 49.0772487, lng: 21.8102134 },
    { lat: 49.077308, lng: 21.810555 },
    { lat: 49.0770901, lng: 21.811168 },
    { lat: 49.0771272, lng: 21.8119107 },
    { lat: 49.0778668, lng: 21.8128423 },
    { lat: 49.078315, lng: 21.8130752 },
    { lat: 49.078806, lng: 21.8130579 },
    { lat: 49.0792893, lng: 21.8134305 },
  ],
  Černina: [
    { lat: 49.0796148, lng: 21.8464759 },
    { lat: 49.0785877, lng: 21.845468 },
    { lat: 49.0781537, lng: 21.8448586 },
    { lat: 49.07797, lng: 21.8434411 },
    { lat: 49.0781743, lng: 21.8411393 },
    { lat: 49.0769296, lng: 21.8398021 },
    { lat: 49.0767692, lng: 21.8389885 },
    { lat: 49.0756404, lng: 21.8369097 },
    { lat: 49.075523, lng: 21.8361478 },
    { lat: 49.0751396, lng: 21.8350591 },
    { lat: 49.0744488, lng: 21.8349262 },
    { lat: 49.0739241, lng: 21.8343837 },
    { lat: 49.0736352, lng: 21.8330763 },
    { lat: 49.0738894, lng: 21.8314136 },
    { lat: 49.074283, lng: 21.830843 },
    { lat: 49.074509, lng: 21.8300911 },
    { lat: 49.0742641, lng: 21.8287097 },
    { lat: 49.0735127, lng: 21.8272645 },
    { lat: 49.0730294, lng: 21.8261926 },
    { lat: 49.0729337, lng: 21.8253135 },
    { lat: 49.0720071, lng: 21.8246305 },
    { lat: 49.0716183, lng: 21.8239506 },
    { lat: 49.0711131, lng: 21.8236388 },
    { lat: 49.0704319, lng: 21.8242224 },
    { lat: 49.0700109, lng: 21.8242152 },
    { lat: 49.0694274, lng: 21.8235721 },
    { lat: 49.0692523, lng: 21.8215835 },
    { lat: 49.0682552, lng: 21.8215266 },
    { lat: 49.0675191, lng: 21.8221144 },
    { lat: 49.0670711, lng: 21.8215784 },
    { lat: 49.0664495, lng: 21.8206376 },
    { lat: 49.0647907, lng: 21.8174369 },
    { lat: 49.063073, lng: 21.8182658 },
    { lat: 49.06179, lng: 21.8164975 },
    { lat: 49.0603791, lng: 21.8154937 },
    { lat: 49.0603931, lng: 21.8139465 },
    { lat: 49.059104, lng: 21.8126393 },
    { lat: 49.0581122, lng: 21.813008 },
    { lat: 49.0572822, lng: 21.8130057 },
    { lat: 49.0566181, lng: 21.8128698 },
    { lat: 49.0563086, lng: 21.8122969 },
    { lat: 49.0556883, lng: 21.810544 },
    { lat: 49.0550637, lng: 21.8095274 },
    { lat: 49.0542204, lng: 21.80868 },
    { lat: 49.0534156, lng: 21.808833 },
    { lat: 49.050849, lng: 21.8083746 },
    { lat: 49.047572, lng: 21.8076759 },
    { lat: 49.0462405, lng: 21.8066464 },
    { lat: 49.0450309, lng: 21.8062432 },
    { lat: 49.0442623, lng: 21.8077556 },
    { lat: 49.0434081, lng: 21.8080013 },
    { lat: 49.0420603, lng: 21.8087656 },
    { lat: 49.0418061, lng: 21.8085564 },
    { lat: 49.0413162, lng: 21.8090989 },
    { lat: 49.0405049, lng: 21.8089999 },
    { lat: 49.0403028, lng: 21.8087596 },
    { lat: 49.0393498, lng: 21.808706 },
    { lat: 49.03923, lng: 21.8088151 },
    { lat: 49.0386341, lng: 21.8089519 },
    { lat: 49.0384158, lng: 21.8085585 },
    { lat: 49.0375212, lng: 21.8086415 },
    { lat: 49.036831, lng: 21.8092688 },
    { lat: 49.035799, lng: 21.8095971 },
    { lat: 49.0348329, lng: 21.8100365 },
    { lat: 49.0339986, lng: 21.8101787 },
    { lat: 49.0328554, lng: 21.810993 },
    { lat: 49.0326155, lng: 21.8108504 },
    { lat: 49.0322921, lng: 21.8110035 },
    { lat: 49.0316655, lng: 21.810922 },
    { lat: 49.0302702, lng: 21.8101654 },
    { lat: 49.0305698, lng: 21.8105274 },
    { lat: 49.0302821, lng: 21.8115334 },
    { lat: 49.0303148, lng: 21.8123215 },
    { lat: 49.0305929, lng: 21.8132824 },
    { lat: 49.0308044, lng: 21.8152766 },
    { lat: 49.0306897, lng: 21.8160664 },
    { lat: 49.0307666, lng: 21.8172132 },
    { lat: 49.030118, lng: 21.8171818 },
    { lat: 49.030395, lng: 21.8185396 },
    { lat: 49.0309733, lng: 21.819593 },
    { lat: 49.0311553, lng: 21.820274 },
    { lat: 49.031546, lng: 21.8207719 },
    { lat: 49.0315333, lng: 21.8209741 },
    { lat: 49.0313771, lng: 21.8215897 },
    { lat: 49.0318128, lng: 21.8213457 },
    { lat: 49.032067, lng: 21.8210828 },
    { lat: 49.032385, lng: 21.8212871 },
    { lat: 49.0337717, lng: 21.8205797 },
    { lat: 49.0341785, lng: 21.8204306 },
    { lat: 49.0356652, lng: 21.8209333 },
    { lat: 49.0360713, lng: 21.8207229 },
    { lat: 49.0365483, lng: 21.8210264 },
    { lat: 49.0365839, lng: 21.8210544 },
    { lat: 49.0374599, lng: 21.8217431 },
    { lat: 49.037537, lng: 21.8225904 },
    { lat: 49.0374364, lng: 21.8235687 },
    { lat: 49.0371143, lng: 21.825172 },
    { lat: 49.0380012, lng: 21.8260154 },
    { lat: 49.0383889, lng: 21.8261779 },
    { lat: 49.038566, lng: 21.8264549 },
    { lat: 49.0385816, lng: 21.8268401 },
    { lat: 49.0388692, lng: 21.827136 },
    { lat: 49.039023, lng: 21.8274681 },
    { lat: 49.0392169, lng: 21.828837 },
    { lat: 49.0391777, lng: 21.830589 },
    { lat: 49.0393823, lng: 21.8315033 },
    { lat: 49.0393789, lng: 21.8327709 },
    { lat: 49.0400092, lng: 21.8325233 },
    { lat: 49.040013, lng: 21.8329748 },
    { lat: 49.0401574, lng: 21.8333818 },
    { lat: 49.0400955, lng: 21.8335597 },
    { lat: 49.0401524, lng: 21.8344997 },
    { lat: 49.040377, lng: 21.8345702 },
    { lat: 49.0410415, lng: 21.8343872 },
    { lat: 49.0413048, lng: 21.8344781 },
    { lat: 49.0415221, lng: 21.8343945 },
    { lat: 49.0421149, lng: 21.8333961 },
    { lat: 49.0423945, lng: 21.8332396 },
    { lat: 49.0427207, lng: 21.8330169 },
    { lat: 49.042878, lng: 21.8326181 },
    { lat: 49.0438101, lng: 21.8324754 },
    { lat: 49.0445027, lng: 21.8321108 },
    { lat: 49.045124, lng: 21.8324287 },
    { lat: 49.0454323, lng: 21.8329597 },
    { lat: 49.0459825, lng: 21.8330055 },
    { lat: 49.0461768, lng: 21.8331277 },
    { lat: 49.0468023, lng: 21.833307 },
    { lat: 49.0474339, lng: 21.8336323 },
    { lat: 49.049813, lng: 21.8337137 },
    { lat: 49.0497994, lng: 21.8338429 },
    { lat: 49.0522489, lng: 21.8333822 },
    { lat: 49.0532235, lng: 21.8329662 },
    { lat: 49.0533074, lng: 21.8329924 },
    { lat: 49.0541164, lng: 21.8327814 },
    { lat: 49.0545438, lng: 21.8327365 },
    { lat: 49.0553341, lng: 21.8328892 },
    { lat: 49.0560588, lng: 21.8325448 },
    { lat: 49.0566001, lng: 21.8322105 },
    { lat: 49.0577014, lng: 21.8313781 },
    { lat: 49.0583359, lng: 21.8306437 },
    { lat: 49.0591152, lng: 21.8304491 },
    { lat: 49.0597309, lng: 21.8313016 },
    { lat: 49.0594398, lng: 21.8326199 },
    { lat: 49.0599647, lng: 21.8341324 },
    { lat: 49.0631504, lng: 21.833523 },
    { lat: 49.0638394, lng: 21.8349 },
    { lat: 49.0654478, lng: 21.8359478 },
    { lat: 49.0661278, lng: 21.8367536 },
    { lat: 49.0666198, lng: 21.8378188 },
    { lat: 49.0665382, lng: 21.8390204 },
    { lat: 49.066681, lng: 21.8393298 },
    { lat: 49.0679458, lng: 21.8417561 },
    { lat: 49.0683466, lng: 21.842077 },
    { lat: 49.0690377, lng: 21.8431076 },
    { lat: 49.0690516, lng: 21.8433356 },
    { lat: 49.0695164, lng: 21.8440528 },
    { lat: 49.0699119, lng: 21.8447788 },
    { lat: 49.0705062, lng: 21.8449275 },
    { lat: 49.0719039, lng: 21.8462682 },
    { lat: 49.0723993, lng: 21.8469474 },
    { lat: 49.0739501, lng: 21.8474998 },
    { lat: 49.0745821, lng: 21.847575 },
    { lat: 49.0757389, lng: 21.8470632 },
    { lat: 49.0766192, lng: 21.846344 },
    { lat: 49.0771863, lng: 21.8482379 },
    { lat: 49.0775331, lng: 21.8490867 },
    { lat: 49.078072, lng: 21.8490889 },
    { lat: 49.0783227, lng: 21.84799 },
    { lat: 49.0791592, lng: 21.8476641 },
    { lat: 49.0796148, lng: 21.8464759 },
  ],
  Jasenov: [
    { lat: 48.9125815, lng: 21.924351 },
    { lat: 48.914276, lng: 21.9240092 },
    { lat: 48.914542, lng: 21.9241616 },
    { lat: 48.9143319, lng: 21.924492 },
    { lat: 48.9147086, lng: 21.9248504 },
    { lat: 48.9150749, lng: 21.9246988 },
    { lat: 48.9153671, lng: 21.9243247 },
    { lat: 48.9157582, lng: 21.9239513 },
    { lat: 48.9158441, lng: 21.9228799 },
    { lat: 48.9157017, lng: 21.9220054 },
    { lat: 48.9157416, lng: 21.9207667 },
    { lat: 48.9160557, lng: 21.9198395 },
    { lat: 48.9158492, lng: 21.9194061 },
    { lat: 48.9156321, lng: 21.9186912 },
    { lat: 48.9157832, lng: 21.9178077 },
    { lat: 48.9162191, lng: 21.9167571 },
    { lat: 48.91626, lng: 21.9163206 },
    { lat: 48.9171141, lng: 21.9145842 },
    { lat: 48.9188689, lng: 21.9105076 },
    { lat: 48.9192323, lng: 21.9088076 },
    { lat: 48.9184543, lng: 21.9083352 },
    { lat: 48.9179501, lng: 21.9079205 },
    { lat: 48.9174714, lng: 21.9076356 },
    { lat: 48.9172384, lng: 21.9076659 },
    { lat: 48.916943, lng: 21.9075049 },
    { lat: 48.9167062, lng: 21.9075294 },
    { lat: 48.9165792, lng: 21.9073153 },
    { lat: 48.9166722, lng: 21.9069366 },
    { lat: 48.9174211, lng: 21.904784 },
    { lat: 48.9193468, lng: 21.9022337 },
    { lat: 48.9187722, lng: 21.901928 },
    { lat: 48.917998, lng: 21.9010731 },
    { lat: 48.9178965, lng: 21.9008797 },
    { lat: 48.9176293, lng: 21.8996999 },
    { lat: 48.917341, lng: 21.8988415 },
    { lat: 48.9172597, lng: 21.8989142 },
    { lat: 48.9169222, lng: 21.8974427 },
    { lat: 48.9171859, lng: 21.896265 },
    { lat: 48.917081, lng: 21.8948265 },
    { lat: 48.9177377, lng: 21.8932589 },
    { lat: 48.9177385, lng: 21.8925832 },
    { lat: 48.9176103, lng: 21.8922287 },
    { lat: 48.9170258, lng: 21.8915711 },
    { lat: 48.9161793, lng: 21.8909741 },
    { lat: 48.9149417, lng: 21.8906549 },
    { lat: 48.913728, lng: 21.8897253 },
    { lat: 48.9130852, lng: 21.8879768 },
    { lat: 48.9126675, lng: 21.8874754 },
    { lat: 48.9126167, lng: 21.8862252 },
    { lat: 48.9127103, lng: 21.8853879 },
    { lat: 48.9131876, lng: 21.883937 },
    { lat: 48.9132358, lng: 21.8832272 },
    { lat: 48.9128977, lng: 21.8814205 },
    { lat: 48.9126337, lng: 21.8804199 },
    { lat: 48.9121674, lng: 21.8792509 },
    { lat: 48.9117941, lng: 21.8779738 },
    { lat: 48.9117099, lng: 21.8770453 },
    { lat: 48.9117381, lng: 21.8763821 },
    { lat: 48.9118476, lng: 21.8759157 },
    { lat: 48.9121661, lng: 21.8751636 },
    { lat: 48.9126046, lng: 21.8744552 },
    { lat: 48.9128145, lng: 21.873866 },
    { lat: 48.912538, lng: 21.8735621 },
    { lat: 48.9115266, lng: 21.8727205 },
    { lat: 48.9104238, lng: 21.8719988 },
    { lat: 48.9093404, lng: 21.8714136 },
    { lat: 48.908398, lng: 21.8712489 },
    { lat: 48.907318, lng: 21.8700135 },
    { lat: 48.9070921, lng: 21.8695382 },
    { lat: 48.9068502, lng: 21.868789 },
    { lat: 48.9067282, lng: 21.8679701 },
    { lat: 48.9063498, lng: 21.8668055 },
    { lat: 48.9052031, lng: 21.866708 },
    { lat: 48.9042863, lng: 21.8662229 },
    { lat: 48.903732, lng: 21.8645592 },
    { lat: 48.9030544, lng: 21.8636235 },
    { lat: 48.9027332, lng: 21.863332 },
    { lat: 48.9012469, lng: 21.8634498 },
    { lat: 48.9011456, lng: 21.8637558 },
    { lat: 48.9007715, lng: 21.8642183 },
    { lat: 48.9001025, lng: 21.8645887 },
    { lat: 48.8990063, lng: 21.8646037 },
    { lat: 48.8989013, lng: 21.8647644 },
    { lat: 48.8972831, lng: 21.865941 },
    { lat: 48.8965722, lng: 21.8669133 },
    { lat: 48.895633, lng: 21.8677043 },
    { lat: 48.8948541, lng: 21.8680456 },
    { lat: 48.892553, lng: 21.86817 },
    { lat: 48.892189, lng: 21.868938 },
    { lat: 48.89184, lng: 21.8696 },
    { lat: 48.891745, lng: 21.869992 },
    { lat: 48.891421, lng: 21.871203 },
    { lat: 48.89149, lng: 21.871586 },
    { lat: 48.891383, lng: 21.872362 },
    { lat: 48.891298, lng: 21.873304 },
    { lat: 48.891018, lng: 21.874577 },
    { lat: 48.890645, lng: 21.876106 },
    { lat: 48.890292, lng: 21.877119 },
    { lat: 48.890212, lng: 21.878748 },
    { lat: 48.890097, lng: 21.879535 },
    { lat: 48.890011, lng: 21.880693 },
    { lat: 48.889877, lng: 21.880993 },
    { lat: 48.889754, lng: 21.881271 },
    { lat: 48.889639, lng: 21.881729 },
    { lat: 48.889741, lng: 21.882511 },
    { lat: 48.88975, lng: 21.882869 },
    { lat: 48.889688, lng: 21.883271 },
    { lat: 48.889437, lng: 21.885017 },
    { lat: 48.888805, lng: 21.885714 },
    { lat: 48.888417, lng: 21.88619 },
    { lat: 48.888336, lng: 21.886489 },
    { lat: 48.888372, lng: 21.887191 },
    { lat: 48.888528, lng: 21.887815 },
    { lat: 48.888411, lng: 21.888051 },
    { lat: 48.887977, lng: 21.888654 },
    { lat: 48.887806, lng: 21.888625 },
    { lat: 48.887552, lng: 21.888836 },
    { lat: 48.887249, lng: 21.888788 },
    { lat: 48.886741, lng: 21.888878 },
    { lat: 48.886328, lng: 21.889242 },
    { lat: 48.886266, lng: 21.889494 },
    { lat: 48.886266, lng: 21.889794 },
    { lat: 48.886244, lng: 21.890474 },
    { lat: 48.88611, lng: 21.891326 },
    { lat: 48.886034, lng: 21.891565 },
    { lat: 48.885905, lng: 21.892092 },
    { lat: 48.885884, lng: 21.892183 },
    { lat: 48.88579, lng: 21.892566 },
    { lat: 48.885584, lng: 21.893173 },
    { lat: 48.88531, lng: 21.893704 },
    { lat: 48.884746, lng: 21.894545 },
    { lat: 48.884856, lng: 21.895005 },
    { lat: 48.884885, lng: 21.895874 },
    { lat: 48.884882, lng: 21.896589 },
    { lat: 48.885069, lng: 21.897139 },
    { lat: 48.885117, lng: 21.897281 },
    { lat: 48.88498, lng: 21.898276 },
    { lat: 48.885008, lng: 21.899419 },
    { lat: 48.884213, lng: 21.900408 },
    { lat: 48.883912, lng: 21.901261 },
    { lat: 48.883504, lng: 21.902054 },
    { lat: 48.883171, lng: 21.902283 },
    { lat: 48.882757, lng: 21.902514 },
    { lat: 48.882419, lng: 21.902934 },
    { lat: 48.882239, lng: 21.903366 },
    { lat: 48.882085, lng: 21.904023 },
    { lat: 48.882136, lng: 21.904354 },
    { lat: 48.882204, lng: 21.904667 },
    { lat: 48.8822, lng: 21.905082 },
    { lat: 48.882206, lng: 21.905933 },
    { lat: 48.882318, lng: 21.906462 },
    { lat: 48.88226, lng: 21.906846 },
    { lat: 48.882104, lng: 21.90709 },
    { lat: 48.881956, lng: 21.908579 },
    { lat: 48.88167, lng: 21.909101 },
    { lat: 48.881553, lng: 21.910035 },
    { lat: 48.881657, lng: 21.910494 },
    { lat: 48.881668, lng: 21.911221 },
    { lat: 48.881678, lng: 21.911993 },
    { lat: 48.881485, lng: 21.912863 },
    { lat: 48.881186, lng: 21.913756 },
    { lat: 48.88084, lng: 21.914374 },
    { lat: 48.880534, lng: 21.914879 },
    { lat: 48.880112, lng: 21.916147 },
    { lat: 48.879713, lng: 21.916654 },
    { lat: 48.87874, lng: 21.917579 },
    { lat: 48.877906, lng: 21.917997 },
    { lat: 48.877299, lng: 21.918165 },
    { lat: 48.877614, lng: 21.9188633 },
    { lat: 48.8781251, lng: 21.9189224 },
    { lat: 48.8786399, lng: 21.9188634 },
    { lat: 48.8788642, lng: 21.9190564 },
    { lat: 48.8793921, lng: 21.9189866 },
    { lat: 48.8807804, lng: 21.9199316 },
    { lat: 48.8819299, lng: 21.9191212 },
    { lat: 48.8826926, lng: 21.9191262 },
    { lat: 48.8833572, lng: 21.9188484 },
    { lat: 48.8837362, lng: 21.9183923 },
    { lat: 48.884372, lng: 21.917408 },
    { lat: 48.8850436, lng: 21.9169229 },
    { lat: 48.8857576, lng: 21.9166407 },
    { lat: 48.8860245, lng: 21.916343 },
    { lat: 48.8865904, lng: 21.9159371 },
    { lat: 48.8869522, lng: 21.9155216 },
    { lat: 48.8891618, lng: 21.9182605 },
    { lat: 48.888989, lng: 21.9206115 },
    { lat: 48.8902115, lng: 21.9224845 },
    { lat: 48.8907541, lng: 21.9232261 },
    { lat: 48.8914655, lng: 21.9243634 },
    { lat: 48.8930788, lng: 21.9261259 },
    { lat: 48.8931933, lng: 21.9257596 },
    { lat: 48.8935225, lng: 21.9261511 },
    { lat: 48.8938699, lng: 21.9264124 },
    { lat: 48.8942823, lng: 21.9268384 },
    { lat: 48.8944813, lng: 21.9268792 },
    { lat: 48.8946983, lng: 21.9267834 },
    { lat: 48.894945, lng: 21.9259959 },
    { lat: 48.8950654, lng: 21.9254185 },
    { lat: 48.8952903, lng: 21.9248267 },
    { lat: 48.8961275, lng: 21.9248897 },
    { lat: 48.896567, lng: 21.9248287 },
    { lat: 48.8970323, lng: 21.9241762 },
    { lat: 48.8979809, lng: 21.9231631 },
    { lat: 48.8984546, lng: 21.9229424 },
    { lat: 48.8987121, lng: 21.9227109 },
    { lat: 48.8988117, lng: 21.9217473 },
    { lat: 48.898922, lng: 21.9212104 },
    { lat: 48.8990773, lng: 21.9207667 },
    { lat: 48.899756, lng: 21.9202878 },
    { lat: 48.9005068, lng: 21.9199034 },
    { lat: 48.9009799, lng: 21.9198361 },
    { lat: 48.901431, lng: 21.9201094 },
    { lat: 48.9017245, lng: 21.9205122 },
    { lat: 48.901884, lng: 21.9209035 },
    { lat: 48.9023242, lng: 21.9212742 },
    { lat: 48.9028904, lng: 21.92201 },
    { lat: 48.9033723, lng: 21.9231993 },
    { lat: 48.9042689, lng: 21.9240513 },
    { lat: 48.9058893, lng: 21.9241686 },
    { lat: 48.90638, lng: 21.9244461 },
    { lat: 48.9079097, lng: 21.9245296 },
    { lat: 48.9083419, lng: 21.924721 },
    { lat: 48.9086969, lng: 21.9251912 },
    { lat: 48.9095654, lng: 21.9256876 },
    { lat: 48.9100382, lng: 21.9251673 },
    { lat: 48.9111346, lng: 21.9251031 },
    { lat: 48.9111417, lng: 21.9251592 },
    { lat: 48.9125815, lng: 21.924351 },
  ],
  VyšnýHrušov: [
    { lat: 49.0298028, lng: 22.0134056 },
    { lat: 49.0276888, lng: 22.0127336 },
    { lat: 49.0270727, lng: 22.012472 },
    { lat: 49.0264512, lng: 22.0117903 },
    { lat: 49.0255115, lng: 22.0094971 },
    { lat: 49.0248127, lng: 22.0075811 },
    { lat: 49.0245455, lng: 22.0065858 },
    { lat: 49.0243388, lng: 22.0060422 },
    { lat: 49.0233597, lng: 22.0062455 },
    { lat: 49.0228845, lng: 22.0064072 },
    { lat: 49.0225054, lng: 22.0064034 },
    { lat: 49.0217635, lng: 22.0061443 },
    { lat: 49.0214654, lng: 22.0061474 },
    { lat: 49.0212675, lng: 22.006199 },
    { lat: 49.0210339, lng: 22.0063963 },
    { lat: 49.0204229, lng: 22.0066137 },
    { lat: 49.0202253, lng: 22.0067793 },
    { lat: 49.0199274, lng: 22.0068755 },
    { lat: 49.0193208, lng: 22.0069092 },
    { lat: 49.0191471, lng: 22.0070254 },
    { lat: 49.0187782, lng: 22.0071202 },
    { lat: 49.0186045, lng: 22.0069565 },
    { lat: 49.0182375, lng: 22.0070817 },
    { lat: 49.01785, lng: 22.0070648 },
    { lat: 49.0174748, lng: 22.0071811 },
    { lat: 49.0163591, lng: 22.0078274 },
    { lat: 49.0162428, lng: 22.0079504 },
    { lat: 49.0160541, lng: 22.0079133 },
    { lat: 49.0156111, lng: 22.0080173 },
    { lat: 49.0153818, lng: 22.0081176 },
    { lat: 49.0152797, lng: 22.0083079 },
    { lat: 49.0149745, lng: 22.0084346 },
    { lat: 49.0148507, lng: 22.0084326 },
    { lat: 49.0146863, lng: 22.0081981 },
    { lat: 49.0144929, lng: 22.0081029 },
    { lat: 49.0142961, lng: 22.0081519 },
    { lat: 49.014215, lng: 22.0083751 },
    { lat: 49.0139425, lng: 22.0085971 },
    { lat: 49.013801, lng: 22.0088877 },
    { lat: 49.0137731, lng: 22.0092623 },
    { lat: 49.013563, lng: 22.0094138 },
    { lat: 49.01329, lng: 22.0094811 },
    { lat: 49.0130634, lng: 22.0094088 },
    { lat: 49.0129024, lng: 22.0091645 },
    { lat: 49.0124339, lng: 22.0094215 },
    { lat: 49.012235, lng: 22.0094327 },
    { lat: 49.0121052, lng: 22.0098327 },
    { lat: 49.0117613, lng: 22.0103084 },
    { lat: 49.012022, lng: 22.0094149 },
    { lat: 49.0120264, lng: 22.008292 },
    { lat: 49.0118269, lng: 22.007493 },
    { lat: 49.0109566, lng: 22.0070406 },
    { lat: 49.0104286, lng: 22.0058662 },
    { lat: 49.0103676, lng: 22.0054307 },
    { lat: 49.0098842, lng: 22.0037803 },
    { lat: 49.0095673, lng: 22.0038816 },
    { lat: 49.0095356, lng: 22.0034996 },
    { lat: 49.0081272, lng: 22.0035442 },
    { lat: 49.0071756, lng: 22.0038248 },
    { lat: 49.0063312, lng: 22.0039266 },
    { lat: 49.006075, lng: 22.0016262 },
    { lat: 49.0061999, lng: 22.0014419 },
    { lat: 49.006222, lng: 22.0005883 },
    { lat: 49.0060892, lng: 21.9998448 },
    { lat: 49.0060608, lng: 21.9993272 },
    { lat: 49.0061565, lng: 21.999008 },
    { lat: 49.0060456, lng: 21.9988365 },
    { lat: 49.0059663, lng: 21.9983097 },
    { lat: 49.0057243, lng: 21.9983054 },
    { lat: 49.0048457, lng: 21.9979306 },
    { lat: 49.0042136, lng: 21.9979314 },
    { lat: 49.0040479, lng: 21.9976831 },
    { lat: 49.0043557, lng: 21.9964205 },
    { lat: 49.0042388, lng: 21.9961007 },
    { lat: 49.0042229, lng: 21.9957229 },
    { lat: 49.0039482, lng: 21.9939166 },
    { lat: 49.0038763, lng: 21.9923013 },
    { lat: 49.0039188, lng: 21.9909121 },
    { lat: 49.0039974, lng: 21.9902631 },
    { lat: 49.0038464, lng: 21.9891822 },
    { lat: 49.0037922, lng: 21.9871557 },
    { lat: 49.0040529, lng: 21.9856732 },
    { lat: 49.0040234, lng: 21.985663 },
    { lat: 49.0042424, lng: 21.9843605 },
    { lat: 49.0043528, lng: 21.9834002 },
    { lat: 49.0049442, lng: 21.9816385 },
    { lat: 49.0046507, lng: 21.9813184 },
    { lat: 49.0040736, lng: 21.9813635 },
    { lat: 49.0029733, lng: 21.9817299 },
    { lat: 49.0025153, lng: 21.9817669 },
    { lat: 49.0023136, lng: 21.9821162 },
    { lat: 49.0021425, lng: 21.9820202 },
    { lat: 49.0019203, lng: 21.981993 },
    { lat: 49.0016462, lng: 21.9817738 },
    { lat: 49.0011367, lng: 21.981641 },
    { lat: 49.0009845, lng: 21.9817245 },
    { lat: 49.0010198, lng: 21.9824232 },
    { lat: 49.0005835, lng: 21.9830279 },
    { lat: 49.0002003, lng: 21.9832229 },
    { lat: 49.0000241, lng: 21.9833837 },
    { lat: 48.999588, lng: 21.9839969 },
    { lat: 48.9996649, lng: 21.9845221 },
    { lat: 48.9994579, lng: 21.9849737 },
    { lat: 48.9991537, lng: 21.9848587 },
    { lat: 48.9985503, lng: 21.9842447 },
    { lat: 48.998415, lng: 21.9840023 },
    { lat: 48.9985106, lng: 21.9839138 },
    { lat: 48.9978126, lng: 21.982501 },
    { lat: 48.9975658, lng: 21.9824838 },
    { lat: 48.9969898, lng: 21.9821198 },
    { lat: 48.9966086, lng: 21.9820314 },
    { lat: 48.9962085, lng: 21.9817162 },
    { lat: 48.9961272, lng: 21.9808816 },
    { lat: 48.9955514, lng: 21.980614 },
    { lat: 48.9951656, lng: 21.9815281 },
    { lat: 48.9944147, lng: 21.9826508 },
    { lat: 48.9941081, lng: 21.9827677 },
    { lat: 48.9938869, lng: 21.9831001 },
    { lat: 48.9930259, lng: 21.9847377 },
    { lat: 48.990687, lng: 21.9883583 },
    { lat: 48.990604, lng: 21.9886843 },
    { lat: 48.9891509, lng: 21.99022 },
    { lat: 48.9888745, lng: 21.9902212 },
    { lat: 48.9886539, lng: 21.990493 },
    { lat: 48.9883374, lng: 21.9907085 },
    { lat: 48.9881881, lng: 21.9909089 },
    { lat: 48.9876524, lng: 21.9908041 },
    { lat: 48.9871317, lng: 21.9908989 },
    { lat: 48.986767, lng: 21.9911186 },
    { lat: 48.9860414, lng: 21.991114 },
    { lat: 48.9864821, lng: 21.9920297 },
    { lat: 48.9866252, lng: 21.9926401 },
    { lat: 48.9866959, lng: 21.9927264 },
    { lat: 48.9867179, lng: 21.9929819 },
    { lat: 48.9876203, lng: 21.9939626 },
    { lat: 48.9878529, lng: 21.994462 },
    { lat: 48.9880029, lng: 21.9945241 },
    { lat: 48.9883963, lng: 21.9954815 },
    { lat: 48.9882793, lng: 21.9966793 },
    { lat: 48.9883373, lng: 21.9968238 },
    { lat: 48.9885571, lng: 21.9973717 },
    { lat: 48.9875974, lng: 22.0003202 },
    { lat: 48.9875923, lng: 22.0005264 },
    { lat: 48.9874672, lng: 22.0007433 },
    { lat: 48.9870198, lng: 22.0010317 },
    { lat: 48.9866035, lng: 22.0015435 },
    { lat: 48.9860147, lng: 22.0019319 },
    { lat: 48.9858312, lng: 22.0022566 },
    { lat: 48.9855509, lng: 22.0025646 },
    { lat: 48.985408, lng: 22.0026087 },
    { lat: 48.9851395, lng: 22.0028978 },
    { lat: 48.9846867, lng: 22.0031506 },
    { lat: 48.9825567, lng: 22.0049768 },
    { lat: 48.983922, lng: 22.0054761 },
    { lat: 48.9838898, lng: 22.0067915 },
    { lat: 48.9835184, lng: 22.0100762 },
    { lat: 48.9838443, lng: 22.0103086 },
    { lat: 48.9842813, lng: 22.0106204 },
    { lat: 48.9845839, lng: 22.0108362 },
    { lat: 48.9849088, lng: 22.0110685 },
    { lat: 48.9852725, lng: 22.0113273 },
    { lat: 48.9855799, lng: 22.0115466 },
    { lat: 48.9857981, lng: 22.0120118 },
    { lat: 48.9875971, lng: 22.0165838 },
    { lat: 48.9876605, lng: 22.0171078 },
    { lat: 48.9868127, lng: 22.0203393 },
    { lat: 48.9854384, lng: 22.0237018 },
    { lat: 48.9844795, lng: 22.026425 },
    { lat: 48.983162, lng: 22.0306638 },
    { lat: 48.9827109, lng: 22.0324607 },
    { lat: 48.9824784, lng: 22.0333872 },
    { lat: 48.9823098, lng: 22.0340588 },
    { lat: 48.9821007, lng: 22.0348918 },
    { lat: 48.9820154, lng: 22.0353029 },
    { lat: 48.9818225, lng: 22.0362322 },
    { lat: 48.9826197, lng: 22.0373446 },
    { lat: 48.9819912, lng: 22.0413855 },
    { lat: 48.9826443, lng: 22.0432509 },
    { lat: 48.9828273, lng: 22.0436089 },
    { lat: 48.9829948, lng: 22.0445898 },
    { lat: 48.9832877, lng: 22.045875 },
    { lat: 48.9828833, lng: 22.0469817 },
    { lat: 48.9826122, lng: 22.0474532 },
    { lat: 48.9825129, lng: 22.0478637 },
    { lat: 48.9822817, lng: 22.0483989 },
    { lat: 48.9822432, lng: 22.0491925 },
    { lat: 48.982192, lng: 22.049514 },
    { lat: 48.9822, lng: 22.049514 },
    { lat: 48.982213, lng: 22.049513 },
    { lat: 48.982242, lng: 22.049505 },
    { lat: 48.982467, lng: 22.049402 },
    { lat: 48.982688, lng: 22.049199 },
    { lat: 48.98293, lng: 22.049035 },
    { lat: 48.983151, lng: 22.04896 },
    { lat: 48.983381, lng: 22.048846 },
    { lat: 48.983619, lng: 22.048625 },
    { lat: 48.983823, lng: 22.0485 },
    { lat: 48.984031, lng: 22.048352 },
    { lat: 48.984444, lng: 22.048183 },
    { lat: 48.984769, lng: 22.048209 },
    { lat: 48.985078, lng: 22.048081 },
    { lat: 48.985311, lng: 22.047937 },
    { lat: 48.986108, lng: 22.047553 },
    { lat: 48.986462, lng: 22.047344 },
    { lat: 48.986802, lng: 22.047252 },
    { lat: 48.987076, lng: 22.047328 },
    { lat: 48.987793, lng: 22.047041 },
    { lat: 48.988091, lng: 22.046959 },
    { lat: 48.988293, lng: 22.046804 },
    { lat: 48.988549, lng: 22.046894 },
    { lat: 48.98904, lng: 22.046825 },
    { lat: 48.989176, lng: 22.046871 },
    { lat: 48.989497, lng: 22.046969 },
    { lat: 48.989879, lng: 22.04702 },
    { lat: 48.990224, lng: 22.047005 },
    { lat: 48.990494, lng: 22.047178 },
    { lat: 48.990757, lng: 22.047398 },
    { lat: 48.990763, lng: 22.047381 },
    { lat: 48.990843, lng: 22.047443 },
    { lat: 48.991306, lng: 22.047799 },
    { lat: 48.991917, lng: 22.04827 },
    { lat: 48.99231, lng: 22.048628 },
    { lat: 48.993201, lng: 22.04886 },
    { lat: 48.993396, lng: 22.048942 },
    { lat: 48.993391, lng: 22.04898 },
    { lat: 48.994577, lng: 22.04959 },
    { lat: 48.995107, lng: 22.049884 },
    { lat: 48.995112, lng: 22.049854 },
    { lat: 48.995423, lng: 22.050015 },
    { lat: 48.995843, lng: 22.050061 },
    { lat: 48.995847, lng: 22.050097 },
    { lat: 48.996205, lng: 22.050089 },
    { lat: 48.996455, lng: 22.049946 },
    { lat: 48.997206, lng: 22.049896 },
    { lat: 48.997716, lng: 22.049694 },
    { lat: 48.998116, lng: 22.049616 },
    { lat: 48.998148, lng: 22.049609 },
    { lat: 48.998154, lng: 22.049595 },
    { lat: 48.9983107, lng: 22.0490888 },
    { lat: 48.9986109, lng: 22.0478138 },
    { lat: 48.9987189, lng: 22.0476689 },
    { lat: 48.9988786, lng: 22.0464514 },
    { lat: 48.9988675, lng: 22.0457997 },
    { lat: 48.9989933, lng: 22.0445172 },
    { lat: 48.9995381, lng: 22.0416757 },
    { lat: 48.9994922, lng: 22.0412082 },
    { lat: 48.9995405, lng: 22.0407857 },
    { lat: 48.999513, lng: 22.040481 },
    { lat: 48.9993732, lng: 22.0400499 },
    { lat: 48.9987505, lng: 22.0340223 },
    { lat: 48.9988663, lng: 22.0331339 },
    { lat: 48.999225, lng: 22.0315833 },
    { lat: 49.001338, lng: 22.0325897 },
    { lat: 49.0023497, lng: 22.0300433 },
    { lat: 49.0036241, lng: 22.0271455 },
    { lat: 49.0065037, lng: 22.0224337 },
    { lat: 49.0073411, lng: 22.0233422 },
    { lat: 49.0086778, lng: 22.0212638 },
    { lat: 49.0089304, lng: 22.0205951 },
    { lat: 49.0097297, lng: 22.0197379 },
    { lat: 49.0099593, lng: 22.0198351 },
    { lat: 49.0101929, lng: 22.0198199 },
    { lat: 49.0103788, lng: 22.0198837 },
    { lat: 49.0110642, lng: 22.0196688 },
    { lat: 49.0119888, lng: 22.0195273 },
    { lat: 49.0124195, lng: 22.0192883 },
    { lat: 49.0128174, lng: 22.0189468 },
    { lat: 49.0134285, lng: 22.0178922 },
    { lat: 49.014079, lng: 22.0173065 },
    { lat: 49.0142054, lng: 22.0170949 },
    { lat: 49.0145344, lng: 22.0167891 },
    { lat: 49.0148727, lng: 22.0165857 },
    { lat: 49.0151038, lng: 22.0163266 },
    { lat: 49.0155485, lng: 22.0160247 },
    { lat: 49.0157654, lng: 22.0157829 },
    { lat: 49.0161411, lng: 22.0158809 },
    { lat: 49.0167876, lng: 22.0157727 },
    { lat: 49.0179804, lng: 22.0158856 },
    { lat: 49.0185532, lng: 22.0157597 },
    { lat: 49.0188047, lng: 22.0156428 },
    { lat: 49.0194355, lng: 22.0158716 },
    { lat: 49.0197905, lng: 22.0159094 },
    { lat: 49.0198822, lng: 22.0159191 },
    { lat: 49.0202666, lng: 22.0158212 },
    { lat: 49.0207877, lng: 22.0153223 },
    { lat: 49.0213577, lng: 22.0146163 },
    { lat: 49.0215797, lng: 22.0144394 },
    { lat: 49.0219299, lng: 22.0143177 },
    { lat: 49.022619, lng: 22.0142576 },
    { lat: 49.0232069, lng: 22.0142893 },
    { lat: 49.0234708, lng: 22.014204 },
    { lat: 49.0236412, lng: 22.0141489 },
    { lat: 49.0243097, lng: 22.0137989 },
    { lat: 49.0253902, lng: 22.0142413 },
    { lat: 49.0259548, lng: 22.0140603 },
    { lat: 49.0262864, lng: 22.0141367 },
    { lat: 49.0266871, lng: 22.0141086 },
    { lat: 49.0272902, lng: 22.0145322 },
    { lat: 49.0278217, lng: 22.0144663 },
    { lat: 49.0280748, lng: 22.0145607 },
    { lat: 49.0292625, lng: 22.0160442 },
    { lat: 49.0296075, lng: 22.0159346 },
    { lat: 49.0296986, lng: 22.0152839 },
    { lat: 49.0294329, lng: 22.0147178 },
    { lat: 49.0294464, lng: 22.0141557 },
    { lat: 49.0295915, lng: 22.013645 },
    { lat: 49.0298028, lng: 22.0134056 },
  ],
  Turcovce: [
    { lat: 49.0421149, lng: 21.8333961 },
    { lat: 49.0421119, lng: 21.8336716 },
    { lat: 49.0421628, lng: 21.8340268 },
    { lat: 49.0419977, lng: 21.8342167 },
    { lat: 49.0421687, lng: 21.8342595 },
    { lat: 49.0423072, lng: 21.8347475 },
    { lat: 49.0427355, lng: 21.8358659 },
    { lat: 49.0427752, lng: 21.8360181 },
    { lat: 49.0427402, lng: 21.8361424 },
    { lat: 49.0428878, lng: 21.8361989 },
    { lat: 49.0429409, lng: 21.8363729 },
    { lat: 49.0429066, lng: 21.8364889 },
    { lat: 49.0430585, lng: 21.8368854 },
    { lat: 49.0432094, lng: 21.8370167 },
    { lat: 49.0432578, lng: 21.8374147 },
    { lat: 49.0435639, lng: 21.8380799 },
    { lat: 49.0435808, lng: 21.8382058 },
    { lat: 49.0428386, lng: 21.8389144 },
    { lat: 49.042623, lng: 21.8399828 },
    { lat: 49.0403812, lng: 21.8420066 },
    { lat: 49.0403673, lng: 21.8429085 },
    { lat: 49.0401619, lng: 21.8439806 },
    { lat: 49.0400259, lng: 21.84526 },
    { lat: 49.039511, lng: 21.8480327 },
    { lat: 49.0394464, lng: 21.8486751 },
    { lat: 49.0395374, lng: 21.8501605 },
    { lat: 49.0390402, lng: 21.8533279 },
    { lat: 49.0388709, lng: 21.8556379 },
    { lat: 49.0391074, lng: 21.8560929 },
    { lat: 49.0395069, lng: 21.8566655 },
    { lat: 49.0398922, lng: 21.8570045 },
    { lat: 49.0410627, lng: 21.8586148 },
    { lat: 49.0415802, lng: 21.859101 },
    { lat: 49.0419697, lng: 21.8598593 },
    { lat: 49.0418176, lng: 21.861613 },
    { lat: 49.0415776, lng: 21.8654798 },
    { lat: 49.0410875, lng: 21.8662469 },
    { lat: 49.0409315, lng: 21.8701481 },
    { lat: 49.0422466, lng: 21.8711332 },
    { lat: 49.0429317, lng: 21.8719269 },
    { lat: 49.042372, lng: 21.8733241 },
    { lat: 49.0421986, lng: 21.8739743 },
    { lat: 49.0427392, lng: 21.8741828 },
    { lat: 49.0429997, lng: 21.8742123 },
    { lat: 49.0436194, lng: 21.8741594 },
    { lat: 49.0464657, lng: 21.8735562 },
    { lat: 49.0471899, lng: 21.8733275 },
    { lat: 49.0479305, lng: 21.8727242 },
    { lat: 49.0487698, lng: 21.8723769 },
    { lat: 49.0493343, lng: 21.8715229 },
    { lat: 49.050256, lng: 21.8714127 },
    { lat: 49.0507184, lng: 21.8712346 },
    { lat: 49.0512247, lng: 21.8711499 },
    { lat: 49.0517618, lng: 21.8711284 },
    { lat: 49.0521498, lng: 21.8711737 },
    { lat: 49.0525079, lng: 21.871314 },
    { lat: 49.053187, lng: 21.8717873 },
    { lat: 49.0538741, lng: 21.8716472 },
    { lat: 49.0546927, lng: 21.8704406 },
    { lat: 49.0562509, lng: 21.8694821 },
    { lat: 49.0566079, lng: 21.8699395 },
    { lat: 49.0571359, lng: 21.8700878 },
    { lat: 49.0576459, lng: 21.8699262 },
    { lat: 49.0583656, lng: 21.8693013 },
    { lat: 49.0585985, lng: 21.8691689 },
    { lat: 49.05933, lng: 21.8695351 },
    { lat: 49.0608337, lng: 21.8706346 },
    { lat: 49.0620885, lng: 21.8722979 },
    { lat: 49.0621993, lng: 21.8741828 },
    { lat: 49.0621271, lng: 21.8743488 },
    { lat: 49.0622517, lng: 21.8746952 },
    { lat: 49.0623798, lng: 21.8747271 },
    { lat: 49.0624036, lng: 21.8748768 },
    { lat: 49.0653903, lng: 21.8756386 },
    { lat: 49.0669528, lng: 21.8757298 },
    { lat: 49.0671005, lng: 21.8761347 },
    { lat: 49.0681242, lng: 21.8764039 },
    { lat: 49.06828, lng: 21.8768791 },
    { lat: 49.0690817, lng: 21.8774831 },
    { lat: 49.0697702, lng: 21.877705 },
    { lat: 49.0704726, lng: 21.8782155 },
    { lat: 49.0721441, lng: 21.87901 },
    { lat: 49.0728382, lng: 21.8779468 },
    { lat: 49.0733236, lng: 21.8770834 },
    { lat: 49.0735852, lng: 21.8757815 },
    { lat: 49.0742764, lng: 21.8740736 },
    { lat: 49.0742584, lng: 21.8728254 },
    { lat: 49.0737246, lng: 21.8711673 },
    { lat: 49.0737, lng: 21.8705082 },
    { lat: 49.0734435, lng: 21.8692253 },
    { lat: 49.0722885, lng: 21.8681667 },
    { lat: 49.0719772, lng: 21.8677814 },
    { lat: 49.0716355, lng: 21.861874 },
    { lat: 49.0715174, lng: 21.8613859 },
    { lat: 49.071198, lng: 21.8609857 },
    { lat: 49.0706851, lng: 21.8601557 },
    { lat: 49.0706653, lng: 21.859426 },
    { lat: 49.0705813, lng: 21.8591246 },
    { lat: 49.070449, lng: 21.8582169 },
    { lat: 49.0707055, lng: 21.8576008 },
    { lat: 49.0714554, lng: 21.8569663 },
    { lat: 49.0722912, lng: 21.8561334 },
    { lat: 49.0732039, lng: 21.8553565 },
    { lat: 49.0745538, lng: 21.8546103 },
    { lat: 49.075381, lng: 21.8539184 },
    { lat: 49.0762172, lng: 21.8519601 },
    { lat: 49.0774142, lng: 21.8509542 },
    { lat: 49.0781178, lng: 21.8500966 },
    { lat: 49.0780815, lng: 21.8500341 },
    { lat: 49.0775331, lng: 21.8490867 },
    { lat: 49.0771863, lng: 21.8482379 },
    { lat: 49.0766192, lng: 21.846344 },
    { lat: 49.0757389, lng: 21.8470632 },
    { lat: 49.0745821, lng: 21.847575 },
    { lat: 49.0739501, lng: 21.8474998 },
    { lat: 49.0723993, lng: 21.8469474 },
    { lat: 49.0719039, lng: 21.8462682 },
    { lat: 49.0705062, lng: 21.8449275 },
    { lat: 49.0699119, lng: 21.8447788 },
    { lat: 49.0695164, lng: 21.8440528 },
    { lat: 49.0690516, lng: 21.8433356 },
    { lat: 49.0690377, lng: 21.8431076 },
    { lat: 49.0683466, lng: 21.842077 },
    { lat: 49.0679458, lng: 21.8417561 },
    { lat: 49.066681, lng: 21.8393298 },
    { lat: 49.0665382, lng: 21.8390204 },
    { lat: 49.0666198, lng: 21.8378188 },
    { lat: 49.0661278, lng: 21.8367536 },
    { lat: 49.0654478, lng: 21.8359478 },
    { lat: 49.0638394, lng: 21.8349 },
    { lat: 49.0631504, lng: 21.833523 },
    { lat: 49.0599647, lng: 21.8341324 },
    { lat: 49.0594398, lng: 21.8326199 },
    { lat: 49.0597309, lng: 21.8313016 },
    { lat: 49.0591152, lng: 21.8304491 },
    { lat: 49.0583359, lng: 21.8306437 },
    { lat: 49.0577014, lng: 21.8313781 },
    { lat: 49.0566001, lng: 21.8322105 },
    { lat: 49.0560588, lng: 21.8325448 },
    { lat: 49.0553341, lng: 21.8328892 },
    { lat: 49.0545438, lng: 21.8327365 },
    { lat: 49.0541164, lng: 21.8327814 },
    { lat: 49.0533074, lng: 21.8329924 },
    { lat: 49.0532235, lng: 21.8329662 },
    { lat: 49.0522489, lng: 21.8333822 },
    { lat: 49.0497994, lng: 21.8338429 },
    { lat: 49.049813, lng: 21.8337137 },
    { lat: 49.0474339, lng: 21.8336323 },
    { lat: 49.0468023, lng: 21.833307 },
    { lat: 49.0461768, lng: 21.8331277 },
    { lat: 49.0459825, lng: 21.8330055 },
    { lat: 49.0454323, lng: 21.8329597 },
    { lat: 49.045124, lng: 21.8324287 },
    { lat: 49.0445027, lng: 21.8321108 },
    { lat: 49.0438101, lng: 21.8324754 },
    { lat: 49.042878, lng: 21.8326181 },
    { lat: 49.0427207, lng: 21.8330169 },
    { lat: 49.0423945, lng: 21.8332396 },
    { lat: 49.0421149, lng: 21.8333961 },
  ],
  Jankovce: [
    { lat: 49.0418967, lng: 21.7840093 },
    { lat: 49.0418973, lng: 21.7840774 },
    { lat: 49.0421101, lng: 21.7846888 },
    { lat: 49.0431943, lng: 21.7861797 },
    { lat: 49.043206, lng: 21.7864544 },
    { lat: 49.0434692, lng: 21.7864622 },
    { lat: 49.0433906, lng: 21.787278 },
    { lat: 49.043421, lng: 21.7883143 },
    { lat: 49.0432852, lng: 21.7890995 },
    { lat: 49.0433629, lng: 21.791 },
    { lat: 49.0433146, lng: 21.7915637 },
    { lat: 49.0433387, lng: 21.7918003 },
    { lat: 49.0432772, lng: 21.7933187 },
    { lat: 49.0433114, lng: 21.7959235 },
    { lat: 49.0435312, lng: 21.8000464 },
    { lat: 49.0433701, lng: 21.8003896 },
    { lat: 49.0434061, lng: 21.8006954 },
    { lat: 49.0433816, lng: 21.8013477 },
    { lat: 49.0430336, lng: 21.8015526 },
    { lat: 49.042372, lng: 21.8017534 },
    { lat: 49.0423524, lng: 21.8019988 },
    { lat: 49.0422293, lng: 21.8021123 },
    { lat: 49.0419323, lng: 21.8020692 },
    { lat: 49.0417133, lng: 21.8023879 },
    { lat: 49.0410672, lng: 21.8028423 },
    { lat: 49.0406171, lng: 21.8028013 },
    { lat: 49.0405264, lng: 21.8028587 },
    { lat: 49.0403321, lng: 21.8032519 },
    { lat: 49.0401027, lng: 21.8034791 },
    { lat: 49.0399264, lng: 21.8033997 },
    { lat: 49.0395534, lng: 21.805108 },
    { lat: 49.0403939, lng: 21.8053307 },
    { lat: 49.041113, lng: 21.8060817 },
    { lat: 49.0412053, lng: 21.8070299 },
    { lat: 49.0418829, lng: 21.8075722 },
    { lat: 49.042364, lng: 21.80777 },
    { lat: 49.0424321, lng: 21.8080975 },
    { lat: 49.0418061, lng: 21.8085564 },
    { lat: 49.0420603, lng: 21.8087656 },
    { lat: 49.0434081, lng: 21.8080013 },
    { lat: 49.0442623, lng: 21.8077556 },
    { lat: 49.0450309, lng: 21.8062432 },
    { lat: 49.0462405, lng: 21.8066464 },
    { lat: 49.047572, lng: 21.8076759 },
    { lat: 49.050849, lng: 21.8083746 },
    { lat: 49.0534156, lng: 21.808833 },
    { lat: 49.0542204, lng: 21.80868 },
    { lat: 49.0550637, lng: 21.8095274 },
    { lat: 49.0556883, lng: 21.810544 },
    { lat: 49.0563086, lng: 21.8122969 },
    { lat: 49.0566181, lng: 21.8128698 },
    { lat: 49.0572822, lng: 21.8130057 },
    { lat: 49.0581122, lng: 21.813008 },
    { lat: 49.059104, lng: 21.8126393 },
    { lat: 49.0603931, lng: 21.8139465 },
    { lat: 49.0603791, lng: 21.8154937 },
    { lat: 49.06179, lng: 21.8164975 },
    { lat: 49.063073, lng: 21.8182658 },
    { lat: 49.0647907, lng: 21.8174369 },
    { lat: 49.0664495, lng: 21.8206376 },
    { lat: 49.0670711, lng: 21.8215784 },
    { lat: 49.0675191, lng: 21.8221144 },
    { lat: 49.0682552, lng: 21.8215266 },
    { lat: 49.0692523, lng: 21.8215835 },
    { lat: 49.0694274, lng: 21.8235721 },
    { lat: 49.0700109, lng: 21.8242152 },
    { lat: 49.0704319, lng: 21.8242224 },
    { lat: 49.0711131, lng: 21.8236388 },
    { lat: 49.0715714, lng: 21.822655 },
    { lat: 49.0719819, lng: 21.8216893 },
    { lat: 49.072465, lng: 21.8209734 },
    { lat: 49.0729468, lng: 21.8199863 },
    { lat: 49.0734537, lng: 21.8187511 },
    { lat: 49.0750655, lng: 21.8173055 },
    { lat: 49.0764532, lng: 21.8168517 },
    { lat: 49.0767064, lng: 21.8161258 },
    { lat: 49.0770553, lng: 21.8154332 },
    { lat: 49.0771757, lng: 21.8150026 },
    { lat: 49.0775694, lng: 21.8145685 },
    { lat: 49.0784857, lng: 21.8139322 },
    { lat: 49.0795162, lng: 21.8134208 },
    { lat: 49.0796373, lng: 21.8130981 },
    { lat: 49.0792893, lng: 21.8134305 },
    { lat: 49.078806, lng: 21.8130579 },
    { lat: 49.078315, lng: 21.8130752 },
    { lat: 49.0778668, lng: 21.8128423 },
    { lat: 49.0771272, lng: 21.8119107 },
    { lat: 49.0770901, lng: 21.811168 },
    { lat: 49.077308, lng: 21.810555 },
    { lat: 49.0772487, lng: 21.8102134 },
    { lat: 49.0771625, lng: 21.8102322 },
    { lat: 49.0771278, lng: 21.8101133 },
    { lat: 49.0768279, lng: 21.8098651 },
    { lat: 49.0764628, lng: 21.809403 },
    { lat: 49.07573, lng: 21.8093419 },
    { lat: 49.0756241, lng: 21.8096823 },
    { lat: 49.0752711, lng: 21.8099055 },
    { lat: 49.0745307, lng: 21.8105527 },
    { lat: 49.0741757, lng: 21.8104869 },
    { lat: 49.0738916, lng: 21.8102409 },
    { lat: 49.0738215, lng: 21.8099262 },
    { lat: 49.0739037, lng: 21.8095789 },
    { lat: 49.0735646, lng: 21.808693 },
    { lat: 49.0735604, lng: 21.8083111 },
    { lat: 49.0733151, lng: 21.8078792 },
    { lat: 49.0724476, lng: 21.8071341 },
    { lat: 49.0720902, lng: 21.8069317 },
    { lat: 49.0717388, lng: 21.8068699 },
    { lat: 49.0714841, lng: 21.8066739 },
    { lat: 49.0713172, lng: 21.8060561 },
    { lat: 49.0711817, lng: 21.8059505 },
    { lat: 49.0710169, lng: 21.8060107 },
    { lat: 49.0706603, lng: 21.8056687 },
    { lat: 49.0704423, lng: 21.8052434 },
    { lat: 49.0701608, lng: 21.8052309 },
    { lat: 49.0697482, lng: 21.8046304 },
    { lat: 49.0697291, lng: 21.8044672 },
    { lat: 49.0695452, lng: 21.8041356 },
    { lat: 49.0693601, lng: 21.8040725 },
    { lat: 49.0696455, lng: 21.8029367 },
    { lat: 49.0695796, lng: 21.8027301 },
    { lat: 49.0692257, lng: 21.8027535 },
    { lat: 49.0690249, lng: 21.8022924 },
    { lat: 49.0687746, lng: 21.8019681 },
    { lat: 49.0690149, lng: 21.8016526 },
    { lat: 49.0690851, lng: 21.8012348 },
    { lat: 49.0690112, lng: 21.8006433 },
    { lat: 49.0688906, lng: 21.8004192 },
    { lat: 49.0685753, lng: 21.8004927 },
    { lat: 49.0685332, lng: 21.8002172 },
    { lat: 49.0686533, lng: 21.799905 },
    { lat: 49.0685496, lng: 21.7995873 },
    { lat: 49.0680634, lng: 21.799067 },
    { lat: 49.0679031, lng: 21.7982583 },
    { lat: 49.0672662, lng: 21.7978264 },
    { lat: 49.0670152, lng: 21.7977278 },
    { lat: 49.0667652, lng: 21.7978024 },
    { lat: 49.0664323, lng: 21.7982462 },
    { lat: 49.0661955, lng: 21.7982569 },
    { lat: 49.0661293, lng: 21.797924 },
    { lat: 49.0662278, lng: 21.7975995 },
    { lat: 49.066213, lng: 21.7972115 },
    { lat: 49.0660496, lng: 21.7970165 },
    { lat: 49.0657872, lng: 21.7969269 },
    { lat: 49.0657205, lng: 21.7966095 },
    { lat: 49.0656595, lng: 21.7965134 },
    { lat: 49.064889, lng: 21.796044 },
    { lat: 49.0643003, lng: 21.7952896 },
    { lat: 49.06421, lng: 21.7952352 },
    { lat: 49.0640251, lng: 21.7953051 },
    { lat: 49.0637586, lng: 21.7956964 },
    { lat: 49.0634298, lng: 21.795209 },
    { lat: 49.0634186, lng: 21.7948262 },
    { lat: 49.0636264, lng: 21.7945569 },
    { lat: 49.0624318, lng: 21.7935212 },
    { lat: 49.0619746, lng: 21.7929817 },
    { lat: 49.061638, lng: 21.7928467 },
    { lat: 49.0613094, lng: 21.7925846 },
    { lat: 49.0610461, lng: 21.7921855 },
    { lat: 49.0608117, lng: 21.7914421 },
    { lat: 49.0604841, lng: 21.7911717 },
    { lat: 49.0599509, lng: 21.7913463 },
    { lat: 49.0594587, lng: 21.7911102 },
    { lat: 49.0589442, lng: 21.7905703 },
    { lat: 49.0587577, lng: 21.790452 },
    { lat: 49.0588845, lng: 21.7898357 },
    { lat: 49.0585235, lng: 21.789365 },
    { lat: 49.057962, lng: 21.7893175 },
    { lat: 49.0577853, lng: 21.7894832 },
    { lat: 49.0562232, lng: 21.789286 },
    { lat: 49.0564184, lng: 21.7884019 },
    { lat: 49.056135, lng: 21.7882382 },
    { lat: 49.0558736, lng: 21.787961 },
    { lat: 49.0555781, lng: 21.788098 },
    { lat: 49.055298, lng: 21.7881859 },
    { lat: 49.0549657, lng: 21.7886055 },
    { lat: 49.054584, lng: 21.7885935 },
    { lat: 49.0543879, lng: 21.7884518 },
    { lat: 49.0544393, lng: 21.7882163 },
    { lat: 49.0545608, lng: 21.7881293 },
    { lat: 49.0546254, lng: 21.7879777 },
    { lat: 49.0544541, lng: 21.7874095 },
    { lat: 49.0535019, lng: 21.7873882 },
    { lat: 49.0531701, lng: 21.7873021 },
    { lat: 49.0530043, lng: 21.7874595 },
    { lat: 49.0522326, lng: 21.787589 },
    { lat: 49.0523035, lng: 21.7873699 },
    { lat: 49.0524122, lng: 21.7873551 },
    { lat: 49.0526202, lng: 21.7870527 },
    { lat: 49.052442, lng: 21.7865991 },
    { lat: 49.0521437, lng: 21.7862882 },
    { lat: 49.0517811, lng: 21.786233 },
    { lat: 49.051586, lng: 21.7865612 },
    { lat: 49.0515783, lng: 21.7868729 },
    { lat: 49.0513576, lng: 21.7870746 },
    { lat: 49.0506579, lng: 21.7871183 },
    { lat: 49.0508181, lng: 21.7870071 },
    { lat: 49.0506837, lng: 21.7866441 },
    { lat: 49.0504782, lng: 21.7864844 },
    { lat: 49.0503511, lng: 21.7860965 },
    { lat: 49.0499314, lng: 21.7857937 },
    { lat: 49.0496654, lng: 21.7861595 },
    { lat: 49.0496606, lng: 21.7865794 },
    { lat: 49.0495898, lng: 21.7868397 },
    { lat: 49.0492773, lng: 21.7867227 },
    { lat: 49.0490752, lng: 21.7863512 },
    { lat: 49.0493012, lng: 21.7859531 },
    { lat: 49.0491585, lng: 21.7853187 },
    { lat: 49.0487756, lng: 21.7847824 },
    { lat: 49.048615, lng: 21.7848352 },
    { lat: 49.0485006, lng: 21.7851566 },
    { lat: 49.0485109, lng: 21.7855038 },
    { lat: 49.0484448, lng: 21.7856459 },
    { lat: 49.0484901, lng: 21.7859978 },
    { lat: 49.0482235, lng: 21.7859985 },
    { lat: 49.0482158, lng: 21.7854439 },
    { lat: 49.0473339, lng: 21.7846721 },
    { lat: 49.0466304, lng: 21.784266 },
    { lat: 49.0462673, lng: 21.7845329 },
    { lat: 49.0460253, lng: 21.7844016 },
    { lat: 49.0458067, lng: 21.7845576 },
    { lat: 49.0449868, lng: 21.7846115 },
    { lat: 49.0446459, lng: 21.7846931 },
    { lat: 49.0438552, lng: 21.7845706 },
    { lat: 49.0437832, lng: 21.784583 },
    { lat: 49.0432032, lng: 21.7843286 },
    { lat: 49.0429688, lng: 21.7843514 },
    { lat: 49.0422134, lng: 21.7838592 },
    { lat: 49.0418961, lng: 21.7839415 },
    { lat: 49.0418967, lng: 21.7840093 },
  ],
  Baškovce: [
    { lat: 49.0421149, lng: 21.8333961 },
    { lat: 49.0415221, lng: 21.8343945 },
    { lat: 49.0413048, lng: 21.8344781 },
    { lat: 49.0410415, lng: 21.8343872 },
    { lat: 49.040377, lng: 21.8345702 },
    { lat: 49.0401524, lng: 21.8344997 },
    { lat: 49.0400955, lng: 21.8335597 },
    { lat: 49.0401574, lng: 21.8333818 },
    { lat: 49.040013, lng: 21.8329748 },
    { lat: 49.0400092, lng: 21.8325233 },
    { lat: 49.0393789, lng: 21.8327709 },
    { lat: 49.0393823, lng: 21.8315033 },
    { lat: 49.0391777, lng: 21.830589 },
    { lat: 49.0392169, lng: 21.828837 },
    { lat: 49.039023, lng: 21.8274681 },
    { lat: 49.0388692, lng: 21.827136 },
    { lat: 49.0385816, lng: 21.8268401 },
    { lat: 49.038566, lng: 21.8264549 },
    { lat: 49.0383889, lng: 21.8261779 },
    { lat: 49.0380012, lng: 21.8260154 },
    { lat: 49.0371143, lng: 21.825172 },
    { lat: 49.0374364, lng: 21.8235687 },
    { lat: 49.037537, lng: 21.8225904 },
    { lat: 49.0374599, lng: 21.8217431 },
    { lat: 49.0365839, lng: 21.8210544 },
    { lat: 49.0365483, lng: 21.8210264 },
    { lat: 49.0360713, lng: 21.8207229 },
    { lat: 49.0356652, lng: 21.8209333 },
    { lat: 49.0341785, lng: 21.8204306 },
    { lat: 49.0337717, lng: 21.8205797 },
    { lat: 49.032385, lng: 21.8212871 },
    { lat: 49.032067, lng: 21.8210828 },
    { lat: 49.0318128, lng: 21.8213457 },
    { lat: 49.0313771, lng: 21.8215897 },
    { lat: 49.0315333, lng: 21.8209741 },
    { lat: 49.031546, lng: 21.8207719 },
    { lat: 49.0311553, lng: 21.820274 },
    { lat: 49.0309733, lng: 21.819593 },
    { lat: 49.030395, lng: 21.8185396 },
    { lat: 49.030118, lng: 21.8171818 },
    { lat: 49.0307666, lng: 21.8172132 },
    { lat: 49.0306897, lng: 21.8160664 },
    { lat: 49.0308044, lng: 21.8152766 },
    { lat: 49.0300757, lng: 21.8155489 },
    { lat: 49.0287714, lng: 21.8152295 },
    { lat: 49.0286119, lng: 21.8158058 },
    { lat: 49.0286855, lng: 21.8162068 },
    { lat: 49.0287871, lng: 21.8174385 },
    { lat: 49.0286411, lng: 21.8182366 },
    { lat: 49.028458, lng: 21.8185931 },
    { lat: 49.0284018, lng: 21.8189829 },
    { lat: 49.0272143, lng: 21.8192572 },
    { lat: 49.0255688, lng: 21.8203108 },
    { lat: 49.0253976, lng: 21.8204824 },
    { lat: 49.0239856, lng: 21.8213019 },
    { lat: 49.0238163, lng: 21.8241903 },
    { lat: 49.0236303, lng: 21.8256606 },
    { lat: 49.0236797, lng: 21.8260957 },
    { lat: 49.0233186, lng: 21.8265251 },
    { lat: 49.02319, lng: 21.8268202 },
    { lat: 49.0227138, lng: 21.827149 },
    { lat: 49.022451, lng: 21.8274346 },
    { lat: 49.0221503, lng: 21.8279389 },
    { lat: 49.021519, lng: 21.8284117 },
    { lat: 49.020773, lng: 21.8292769 },
    { lat: 49.0205908, lng: 21.8309773 },
    { lat: 49.0206997, lng: 21.832021 },
    { lat: 49.0201174, lng: 21.8334728 },
    { lat: 49.019876, lng: 21.8345904 },
    { lat: 49.0200815, lng: 21.8345678 },
    { lat: 49.0199874, lng: 21.835683 },
    { lat: 49.0200625, lng: 21.8358918 },
    { lat: 49.0197907, lng: 21.837219 },
    { lat: 49.0192232, lng: 21.8376739 },
    { lat: 49.0185182, lng: 21.8379964 },
    { lat: 49.018504, lng: 21.8384069 },
    { lat: 49.0187109, lng: 21.8400192 },
    { lat: 49.0186336, lng: 21.8406062 },
    { lat: 49.0187584, lng: 21.8421786 },
    { lat: 49.0185911, lng: 21.8434583 },
    { lat: 49.0188407, lng: 21.8435417 },
    { lat: 49.0186825, lng: 21.8438682 },
    { lat: 49.0185253, lng: 21.8440528 },
    { lat: 49.0186353, lng: 21.8443538 },
    { lat: 49.0189491, lng: 21.8445671 },
    { lat: 49.0190333, lng: 21.8452678 },
    { lat: 49.0195055, lng: 21.8452975 },
    { lat: 49.0196433, lng: 21.8462874 },
    { lat: 49.0198801, lng: 21.8471357 },
    { lat: 49.020249, lng: 21.8481049 },
    { lat: 49.0215865, lng: 21.8488471 },
    { lat: 49.0222375, lng: 21.8493613 },
    { lat: 49.0224113, lng: 21.8510112 },
    { lat: 49.0225149, lng: 21.852685 },
    { lat: 49.0224257, lng: 21.8532078 },
    { lat: 49.022556, lng: 21.8556098 },
    { lat: 49.0225266, lng: 21.8557868 },
    { lat: 49.0236481, lng: 21.8559614 },
    { lat: 49.0252825, lng: 21.8555623 },
    { lat: 49.0266796, lng: 21.8560382 },
    { lat: 49.0277185, lng: 21.8556753 },
    { lat: 49.0283213, lng: 21.8564592 },
    { lat: 49.0288969, lng: 21.8574099 },
    { lat: 49.0288164, lng: 21.8586237 },
    { lat: 49.0290836, lng: 21.8598407 },
    { lat: 49.0313626, lng: 21.8628372 },
    { lat: 49.0318775, lng: 21.8631732 },
    { lat: 49.0324496, lng: 21.8651886 },
    { lat: 49.0324102, lng: 21.8656684 },
    { lat: 49.0328276, lng: 21.866472 },
    { lat: 49.0331774, lng: 21.8691673 },
    { lat: 49.0340587, lng: 21.8688799 },
    { lat: 49.0346147, lng: 21.8689336 },
    { lat: 49.0351381, lng: 21.8697431 },
    { lat: 49.0358933, lng: 21.8711805 },
    { lat: 49.0362604, lng: 21.8713665 },
    { lat: 49.0377982, lng: 21.8697365 },
    { lat: 49.0382741, lng: 21.8696512 },
    { lat: 49.0389022, lng: 21.8693973 },
    { lat: 49.0395102, lng: 21.8695971 },
    { lat: 49.0403669, lng: 21.8697113 },
    { lat: 49.0409315, lng: 21.8701481 },
    { lat: 49.0410875, lng: 21.8662469 },
    { lat: 49.0415776, lng: 21.8654798 },
    { lat: 49.0418176, lng: 21.861613 },
    { lat: 49.0419697, lng: 21.8598593 },
    { lat: 49.0415802, lng: 21.859101 },
    { lat: 49.0410627, lng: 21.8586148 },
    { lat: 49.0398922, lng: 21.8570045 },
    { lat: 49.0395069, lng: 21.8566655 },
    { lat: 49.0391074, lng: 21.8560929 },
    { lat: 49.0388709, lng: 21.8556379 },
    { lat: 49.0390402, lng: 21.8533279 },
    { lat: 49.0395374, lng: 21.8501605 },
    { lat: 49.0394464, lng: 21.8486751 },
    { lat: 49.039511, lng: 21.8480327 },
    { lat: 49.0400259, lng: 21.84526 },
    { lat: 49.0401619, lng: 21.8439806 },
    { lat: 49.0403673, lng: 21.8429085 },
    { lat: 49.0403812, lng: 21.8420066 },
    { lat: 49.042623, lng: 21.8399828 },
    { lat: 49.0428386, lng: 21.8389144 },
    { lat: 49.0435808, lng: 21.8382058 },
    { lat: 49.0435639, lng: 21.8380799 },
    { lat: 49.0432578, lng: 21.8374147 },
    { lat: 49.0432094, lng: 21.8370167 },
    { lat: 49.0430585, lng: 21.8368854 },
    { lat: 49.0429066, lng: 21.8364889 },
    { lat: 49.0429409, lng: 21.8363729 },
    { lat: 49.0428878, lng: 21.8361989 },
    { lat: 49.0427402, lng: 21.8361424 },
    { lat: 49.0427752, lng: 21.8360181 },
    { lat: 49.0427355, lng: 21.8358659 },
    { lat: 49.0423072, lng: 21.8347475 },
    { lat: 49.0421687, lng: 21.8342595 },
    { lat: 49.0419977, lng: 21.8342167 },
    { lat: 49.0421628, lng: 21.8340268 },
    { lat: 49.0421119, lng: 21.8336716 },
    { lat: 49.0421149, lng: 21.8333961 },
  ],
  Košarovce: [
    { lat: 49.0418967, lng: 21.7840093 },
    { lat: 49.0418961, lng: 21.7839415 },
    { lat: 49.0422134, lng: 21.7838592 },
    { lat: 49.0429688, lng: 21.7843514 },
    { lat: 49.0432032, lng: 21.7843286 },
    { lat: 49.0437832, lng: 21.784583 },
    { lat: 49.0438552, lng: 21.7845706 },
    { lat: 49.0446459, lng: 21.7846931 },
    { lat: 49.0449868, lng: 21.7846115 },
    { lat: 49.0458067, lng: 21.7845576 },
    { lat: 49.0460253, lng: 21.7844016 },
    { lat: 49.0462673, lng: 21.7845329 },
    { lat: 49.0466304, lng: 21.784266 },
    { lat: 49.0473339, lng: 21.7846721 },
    { lat: 49.0482158, lng: 21.7854439 },
    { lat: 49.0482235, lng: 21.7859985 },
    { lat: 49.0484901, lng: 21.7859978 },
    { lat: 49.0484448, lng: 21.7856459 },
    { lat: 49.0485109, lng: 21.7855038 },
    { lat: 49.0485006, lng: 21.7851566 },
    { lat: 49.048615, lng: 21.7848352 },
    { lat: 49.0487756, lng: 21.7847824 },
    { lat: 49.0491585, lng: 21.7853187 },
    { lat: 49.0493012, lng: 21.7859531 },
    { lat: 49.0490752, lng: 21.7863512 },
    { lat: 49.0492773, lng: 21.7867227 },
    { lat: 49.0495898, lng: 21.7868397 },
    { lat: 49.0496606, lng: 21.7865794 },
    { lat: 49.0496654, lng: 21.7861595 },
    { lat: 49.0499314, lng: 21.7857937 },
    { lat: 49.0503511, lng: 21.7860965 },
    { lat: 49.0504782, lng: 21.7864844 },
    { lat: 49.0506837, lng: 21.7866441 },
    { lat: 49.0508181, lng: 21.7870071 },
    { lat: 49.0506579, lng: 21.7871183 },
    { lat: 49.0513576, lng: 21.7870746 },
    { lat: 49.0515783, lng: 21.7868729 },
    { lat: 49.051586, lng: 21.7865612 },
    { lat: 49.0517811, lng: 21.786233 },
    { lat: 49.0521437, lng: 21.7862882 },
    { lat: 49.052442, lng: 21.7865991 },
    { lat: 49.0526202, lng: 21.7870527 },
    { lat: 49.0524122, lng: 21.7873551 },
    { lat: 49.0523035, lng: 21.7873699 },
    { lat: 49.0522326, lng: 21.787589 },
    { lat: 49.0530043, lng: 21.7874595 },
    { lat: 49.0531701, lng: 21.7873021 },
    { lat: 49.0535019, lng: 21.7873882 },
    { lat: 49.0544541, lng: 21.7874095 },
    { lat: 49.0546254, lng: 21.7879777 },
    { lat: 49.0545608, lng: 21.7881293 },
    { lat: 49.0544393, lng: 21.7882163 },
    { lat: 49.0543879, lng: 21.7884518 },
    { lat: 49.054584, lng: 21.7885935 },
    { lat: 49.0549657, lng: 21.7886055 },
    { lat: 49.055298, lng: 21.7881859 },
    { lat: 49.0555781, lng: 21.788098 },
    { lat: 49.0558736, lng: 21.787961 },
    { lat: 49.0558494, lng: 21.787899 },
    { lat: 49.056086, lng: 21.7877362 },
    { lat: 49.0564172, lng: 21.7873636 },
    { lat: 49.0565955, lng: 21.7870436 },
    { lat: 49.0567179, lng: 21.7869932 },
    { lat: 49.0569871, lng: 21.7866218 },
    { lat: 49.0571861, lng: 21.7863157 },
    { lat: 49.0571255, lng: 21.7861895 },
    { lat: 49.0572132, lng: 21.7860196 },
    { lat: 49.057349, lng: 21.7859651 },
    { lat: 49.0574622, lng: 21.7855988 },
    { lat: 49.0576743, lng: 21.7854511 },
    { lat: 49.0578288, lng: 21.7851667 },
    { lat: 49.0579868, lng: 21.785052 },
    { lat: 49.0580494, lng: 21.7849116 },
    { lat: 49.0580462, lng: 21.7847446 },
    { lat: 49.0583817, lng: 21.7846293 },
    { lat: 49.0586581, lng: 21.7841835 },
    { lat: 49.058979, lng: 21.7840716 },
    { lat: 49.0590697, lng: 21.7837508 },
    { lat: 49.0591194, lng: 21.7832029 },
    { lat: 49.0592856, lng: 21.7832298 },
    { lat: 49.0593658, lng: 21.78316 },
    { lat: 49.0593834, lng: 21.7830108 },
    { lat: 49.0597084, lng: 21.7827128 },
    { lat: 49.0599155, lng: 21.7827533 },
    { lat: 49.0600443, lng: 21.7826042 },
    { lat: 49.0600355, lng: 21.7822686 },
    { lat: 49.060102, lng: 21.7817194 },
    { lat: 49.0606156, lng: 21.781176 },
    { lat: 49.0608531, lng: 21.7813169 },
    { lat: 49.0609833, lng: 21.7809123 },
    { lat: 49.0611611, lng: 21.7808807 },
    { lat: 49.0612283, lng: 21.780645 },
    { lat: 49.0611989, lng: 21.7805144 },
    { lat: 49.0612317, lng: 21.7803549 },
    { lat: 49.0614465, lng: 21.7802651 },
    { lat: 49.0615014, lng: 21.7801667 },
    { lat: 49.0615058, lng: 21.7800345 },
    { lat: 49.0613886, lng: 21.7796402 },
    { lat: 49.0615657, lng: 21.7794881 },
    { lat: 49.0616671, lng: 21.7794888 },
    { lat: 49.0616453, lng: 21.7791332 },
    { lat: 49.0618196, lng: 21.7792177 },
    { lat: 49.0619548, lng: 21.779142 },
    { lat: 49.0619598, lng: 21.7789032 },
    { lat: 49.0618479, lng: 21.7787963 },
    { lat: 49.0619251, lng: 21.7785204 },
    { lat: 49.0620484, lng: 21.7785847 },
    { lat: 49.0621086, lng: 21.7784813 },
    { lat: 49.062092, lng: 21.7783245 },
    { lat: 49.0622347, lng: 21.7783531 },
    { lat: 49.0622928, lng: 21.7782613 },
    { lat: 49.0624595, lng: 21.7782258 },
    { lat: 49.0625404, lng: 21.7785132 },
    { lat: 49.0626198, lng: 21.7782878 },
    { lat: 49.062593, lng: 21.7780738 },
    { lat: 49.0627655, lng: 21.7779636 },
    { lat: 49.0629435, lng: 21.778064 },
    { lat: 49.0629727, lng: 21.7779302 },
    { lat: 49.0628902, lng: 21.7778309 },
    { lat: 49.0629032, lng: 21.7777698 },
    { lat: 49.0630952, lng: 21.7776305 },
    { lat: 49.063079, lng: 21.7778194 },
    { lat: 49.0633467, lng: 21.7775201 },
    { lat: 49.0634538, lng: 21.7772726 },
    { lat: 49.0636812, lng: 21.7771971 },
    { lat: 49.0636899, lng: 21.7770079 },
    { lat: 49.0635843, lng: 21.7767571 },
    { lat: 49.0635764, lng: 21.776237 },
    { lat: 49.0637438, lng: 21.7763187 },
    { lat: 49.0638109, lng: 21.7762648 },
    { lat: 49.0640303, lng: 21.7759196 },
    { lat: 49.064128, lng: 21.775399 },
    { lat: 49.064075, lng: 21.775529 },
    { lat: 49.063331, lng: 21.77565 },
    { lat: 49.063189, lng: 21.775878 },
    { lat: 49.062916, lng: 21.776136 },
    { lat: 49.06251, lng: 21.775946 },
    { lat: 49.062227, lng: 21.775343 },
    { lat: 49.062116, lng: 21.775032 },
    { lat: 49.062018, lng: 21.774721 },
    { lat: 49.06196, lng: 21.774413 },
    { lat: 49.061926, lng: 21.774069 },
    { lat: 49.061873, lng: 21.773833 },
    { lat: 49.061916, lng: 21.77356 },
    { lat: 49.061836, lng: 21.773433 },
    { lat: 49.061821, lng: 21.773277 },
    { lat: 49.061792, lng: 21.772989 },
    { lat: 49.061483, lng: 21.772176 },
    { lat: 49.061245, lng: 21.771892 },
    { lat: 49.061198, lng: 21.770452 },
    { lat: 49.060866, lng: 21.769197 },
    { lat: 49.060445, lng: 21.768353 },
    { lat: 49.06026, lng: 21.768189 },
    { lat: 49.060148, lng: 21.768061 },
    { lat: 49.059836, lng: 21.767668 },
    { lat: 49.059456, lng: 21.76731 },
    { lat: 49.058957, lng: 21.76684 },
    { lat: 49.058572, lng: 21.766371 },
    { lat: 49.05813, lng: 21.76595 },
    { lat: 49.057984, lng: 21.765812 },
    { lat: 49.057771, lng: 21.765612 },
    { lat: 49.057629, lng: 21.765479 },
    { lat: 49.057063, lng: 21.76465 },
    { lat: 49.056668, lng: 21.76412 },
    { lat: 49.056185, lng: 21.763635 },
    { lat: 49.055789, lng: 21.763112 },
    { lat: 49.055372, lng: 21.762398 },
    { lat: 49.055201, lng: 21.762222 },
    { lat: 49.055054, lng: 21.762082 },
    { lat: 49.054542, lng: 21.761661 },
    { lat: 49.054505, lng: 21.761614 },
    { lat: 49.054354, lng: 21.76131 },
    { lat: 49.053788, lng: 21.760659 },
    { lat: 49.053734, lng: 21.76061 },
    { lat: 49.053023, lng: 21.760403 },
    { lat: 49.052735, lng: 21.760319 },
    { lat: 49.052352, lng: 21.760258 },
    { lat: 49.052152, lng: 21.760208 },
    { lat: 49.050773, lng: 21.760033 },
    { lat: 49.050043, lng: 21.760102 },
    { lat: 49.049248, lng: 21.76012 },
    { lat: 49.048603, lng: 21.759904 },
    { lat: 49.048349, lng: 21.759715 },
    { lat: 49.047871, lng: 21.759042 },
    { lat: 49.047573, lng: 21.759094 },
    { lat: 49.047126, lng: 21.758948 },
    { lat: 49.047115, lng: 21.757954 },
    { lat: 49.046417, lng: 21.757685 },
    { lat: 49.045726, lng: 21.756746 },
    { lat: 49.04549, lng: 21.756425 },
    { lat: 49.045325, lng: 21.756041 },
    { lat: 49.045303, lng: 21.755497 },
    { lat: 49.045221, lng: 21.754858 },
    { lat: 49.045106, lng: 21.754864 },
    { lat: 49.044912, lng: 21.754992 },
    { lat: 49.044189, lng: 21.755047 },
    { lat: 49.044081, lng: 21.754618 },
    { lat: 49.043518, lng: 21.754549 },
    { lat: 49.042836, lng: 21.754298 },
    { lat: 49.042489, lng: 21.754236 },
    { lat: 49.041992, lng: 21.754191 },
    { lat: 49.041607, lng: 21.75422 },
    { lat: 49.041146, lng: 21.755149 },
    { lat: 49.040717, lng: 21.755142 },
    { lat: 49.040005, lng: 21.754401 },
    { lat: 49.039762, lng: 21.754286 },
    { lat: 49.039528, lng: 21.753986 },
    { lat: 49.039253, lng: 21.75273 },
    { lat: 49.039244, lng: 21.752185 },
    { lat: 49.03896, lng: 21.751276 },
    { lat: 49.038831, lng: 21.751482 },
    { lat: 49.037622, lng: 21.752243 },
    { lat: 49.037177, lng: 21.752116 },
    { lat: 49.036596, lng: 21.7518 },
    { lat: 49.036391, lng: 21.751965 },
    { lat: 49.035791, lng: 21.751925 },
    { lat: 49.035552, lng: 21.752265 },
    { lat: 49.035278, lng: 21.752492 },
    { lat: 49.034875, lng: 21.752511 },
    { lat: 49.034352, lng: 21.75272 },
    { lat: 49.034128, lng: 21.752879 },
    { lat: 49.033874, lng: 21.752966 },
    { lat: 49.033491, lng: 21.75325 },
    { lat: 49.033301, lng: 21.753302 },
    { lat: 49.033023, lng: 21.753318 },
    { lat: 49.032843, lng: 21.753028 },
    { lat: 49.032834, lng: 21.75281 },
    { lat: 49.032601, lng: 21.752577 },
    { lat: 49.032374, lng: 21.752259 },
    { lat: 49.032668, lng: 21.751224 },
    { lat: 49.032902, lng: 21.750247 },
    { lat: 49.032865, lng: 21.749892 },
    { lat: 49.032686, lng: 21.749595 },
    { lat: 49.032154, lng: 21.749898 },
    { lat: 49.031507, lng: 21.750432 },
    { lat: 49.031133, lng: 21.750622 },
    { lat: 49.03066, lng: 21.750801 },
    { lat: 49.030117, lng: 21.75084 },
    { lat: 49.029957, lng: 21.750939 },
    { lat: 49.029795, lng: 21.751005 },
    { lat: 49.029073, lng: 21.751462 },
    { lat: 49.028755, lng: 21.75164 },
    { lat: 49.028374, lng: 21.751768 },
    { lat: 49.028093, lng: 21.751946 },
    { lat: 49.027619, lng: 21.752247 },
    { lat: 49.027439, lng: 21.752363 },
    { lat: 49.027054, lng: 21.752388 },
    { lat: 49.026739, lng: 21.752442 },
    { lat: 49.026587, lng: 21.752383 },
    { lat: 49.02655, lng: 21.752512 },
    { lat: 49.026922, lng: 21.753286 },
    { lat: 49.027082, lng: 21.754141 },
    { lat: 49.027292, lng: 21.754344 },
    { lat: 49.027823, lng: 21.754475 },
    { lat: 49.02791, lng: 21.754718 },
    { lat: 49.027684, lng: 21.754913 },
    { lat: 49.02755, lng: 21.755766 },
    { lat: 49.027412, lng: 21.756121 },
    { lat: 49.027246, lng: 21.756736 },
    { lat: 49.027236, lng: 21.756771 },
    { lat: 49.027311, lng: 21.75722 },
    { lat: 49.027582, lng: 21.757704 },
    { lat: 49.027847, lng: 21.758354 },
    { lat: 49.028131, lng: 21.758618 },
    { lat: 49.028299, lng: 21.758658 },
    { lat: 49.028312, lng: 21.758977 },
    { lat: 49.028337, lng: 21.759655 },
    { lat: 49.027678, lng: 21.760968 },
    { lat: 49.027566, lng: 21.761082 },
    { lat: 49.027186, lng: 21.76147 },
    { lat: 49.026982, lng: 21.761601 },
    { lat: 49.026764, lng: 21.761638 },
    { lat: 49.026628, lng: 21.761598 },
    { lat: 49.026488, lng: 21.761637 },
    { lat: 49.026499, lng: 21.761666 },
    { lat: 49.026671, lng: 21.762264 },
    { lat: 49.026507, lng: 21.762443 },
    { lat: 49.025492, lng: 21.763119 },
    { lat: 49.024816, lng: 21.763458 },
    { lat: 49.024626, lng: 21.763472 },
    { lat: 49.024491, lng: 21.763619 },
    { lat: 49.023988, lng: 21.763799 },
    { lat: 49.023813, lng: 21.763798 },
    { lat: 49.02366, lng: 21.764091 },
    { lat: 49.023524, lng: 21.764127 },
    { lat: 49.023558, lng: 21.764227 },
    { lat: 49.02365, lng: 21.764503 },
    { lat: 49.023672, lng: 21.764571 },
    { lat: 49.023875, lng: 21.765182 },
    { lat: 49.023852, lng: 21.766595 },
    { lat: 49.023767, lng: 21.768012 },
    { lat: 49.023234, lng: 21.769871 },
    { lat: 49.022462, lng: 21.771441 },
    { lat: 49.022447, lng: 21.77147 },
    { lat: 49.022432, lng: 21.771496 },
    { lat: 49.022418, lng: 21.771523 },
    { lat: 49.022336, lng: 21.771649 },
    { lat: 49.021374, lng: 21.773133 },
    { lat: 49.021216, lng: 21.773474 },
    { lat: 49.020971, lng: 21.774008 },
    { lat: 49.020812, lng: 21.774112 },
    { lat: 49.0209794, lng: 21.7743325 },
    { lat: 49.0210577, lng: 21.7742804 },
    { lat: 49.0211582, lng: 21.7743396 },
    { lat: 49.0212634, lng: 21.774269 },
    { lat: 49.021374, lng: 21.7740745 },
    { lat: 49.0215705, lng: 21.7743572 },
    { lat: 49.0216052, lng: 21.774611 },
    { lat: 49.0217849, lng: 21.7749023 },
    { lat: 49.0221069, lng: 21.7747845 },
    { lat: 49.0222174, lng: 21.7751757 },
    { lat: 49.0224475, lng: 21.7755832 },
    { lat: 49.0226142, lng: 21.7760595 },
    { lat: 49.022752, lng: 21.7762995 },
    { lat: 49.0228936, lng: 21.7763404 },
    { lat: 49.0232244, lng: 21.7759909 },
    { lat: 49.0233618, lng: 21.7759554 },
    { lat: 49.024701, lng: 21.7773936 },
    { lat: 49.0249038, lng: 21.7775333 },
    { lat: 49.0254304, lng: 21.7777121 },
    { lat: 49.0256033, lng: 21.7779576 },
    { lat: 49.0259087, lng: 21.7781057 },
    { lat: 49.0261469, lng: 21.7783992 },
    { lat: 49.026264, lng: 21.7788287 },
    { lat: 49.0281577, lng: 21.7794424 },
    { lat: 49.0282038, lng: 21.7799142 },
    { lat: 49.0283397, lng: 21.7800357 },
    { lat: 49.0309971, lng: 21.7806067 },
    { lat: 49.0324056, lng: 21.7810839 },
    { lat: 49.0347314, lng: 21.781925 },
    { lat: 49.0353096, lng: 21.782526 },
    { lat: 49.0358974, lng: 21.7826292 },
    { lat: 49.0360576, lng: 21.7820067 },
    { lat: 49.0364014, lng: 21.781902 },
    { lat: 49.0364508, lng: 21.7820668 },
    { lat: 49.0364744, lng: 21.7824562 },
    { lat: 49.0368685, lng: 21.7828604 },
    { lat: 49.037083, lng: 21.7827589 },
    { lat: 49.0376819, lng: 21.7828198 },
    { lat: 49.0378436, lng: 21.7831076 },
    { lat: 49.0379813, lng: 21.7836399 },
    { lat: 49.0388947, lng: 21.7841519 },
    { lat: 49.0393112, lng: 21.78416 },
    { lat: 49.0397667, lng: 21.7840292 },
    { lat: 49.0404812, lng: 21.7840372 },
    { lat: 49.0408935, lng: 21.7845361 },
    { lat: 49.041184, lng: 21.7845928 },
    { lat: 49.0413071, lng: 21.7845197 },
    { lat: 49.0418967, lng: 21.7840093 },
  ],
  Jabloň: [
    { lat: 49.0463018, lng: 22.0151997 },
    { lat: 49.0463193, lng: 22.0152228 },
    { lat: 49.0472247, lng: 22.0160664 },
    { lat: 49.0479675, lng: 22.0170565 },
    { lat: 49.0487786, lng: 22.0171065 },
    { lat: 49.0491056, lng: 22.0169068 },
    { lat: 49.0501016, lng: 22.0156262 },
    { lat: 49.0506752, lng: 22.0159516 },
    { lat: 49.051049, lng: 22.0162459 },
    { lat: 49.0523713, lng: 22.0168304 },
    { lat: 49.0529377, lng: 22.0174679 },
    { lat: 49.0536878, lng: 22.018018 },
    { lat: 49.054169, lng: 22.0179671 },
    { lat: 49.0553362, lng: 22.0182318 },
    { lat: 49.0553559, lng: 22.0182052 },
    { lat: 49.0552553, lng: 22.0179046 },
    { lat: 49.0557363, lng: 22.0171906 },
    { lat: 49.0565118, lng: 22.0170271 },
    { lat: 49.0570137, lng: 22.0170435 },
    { lat: 49.0574357, lng: 22.0168624 },
    { lat: 49.0577464, lng: 22.0165883 },
    { lat: 49.058633, lng: 22.0162812 },
    { lat: 49.0590107, lng: 22.0162188 },
    { lat: 49.0597173, lng: 22.0162263 },
    { lat: 49.0602527, lng: 22.0158632 },
    { lat: 49.0610387, lng: 22.0147285 },
    { lat: 49.0613377, lng: 22.0140733 },
    { lat: 49.061395, lng: 22.0132825 },
    { lat: 49.0611787, lng: 22.0126128 },
    { lat: 49.0615646, lng: 22.0116421 },
    { lat: 49.0619325, lng: 22.0115548 },
    { lat: 49.062175, lng: 22.0109753 },
    { lat: 49.0623105, lng: 22.0108106 },
    { lat: 49.0626452, lng: 22.0098679 },
    { lat: 49.0636492, lng: 22.0075655 },
    { lat: 49.0638938, lng: 22.0068046 },
    { lat: 49.0640838, lng: 22.0063779 },
    { lat: 49.0645187, lng: 22.0057175 },
    { lat: 49.0647249, lng: 22.0052723 },
    { lat: 49.0658609, lng: 22.0044319 },
    { lat: 49.0665021, lng: 22.0040662 },
    { lat: 49.0669501, lng: 22.0033366 },
    { lat: 49.0670605, lng: 22.0029702 },
    { lat: 49.0671889, lng: 22.0027442 },
    { lat: 49.0672907, lng: 22.0021222 },
    { lat: 49.0674175, lng: 22.0018295 },
    { lat: 49.0674399, lng: 22.000618 },
    { lat: 49.0677048, lng: 22.0000439 },
    { lat: 49.0684656, lng: 21.9993227 },
    { lat: 49.0686399, lng: 21.9990869 },
    { lat: 49.0690494, lng: 21.9989078 },
    { lat: 49.0694798, lng: 21.9984714 },
    { lat: 49.069689, lng: 21.9984266 },
    { lat: 49.0700484, lng: 21.998222 },
    { lat: 49.0703369, lng: 21.9982402 },
    { lat: 49.0706648, lng: 21.9981274 },
    { lat: 49.0712902, lng: 21.9981309 },
    { lat: 49.0719265, lng: 21.9978365 },
    { lat: 49.0722791, lng: 21.9978173 },
    { lat: 49.0722522, lng: 21.9963803 },
    { lat: 49.0729047, lng: 21.9968978 },
    { lat: 49.0735274, lng: 21.9969269 },
    { lat: 49.0737936, lng: 21.9970465 },
    { lat: 49.0741089, lng: 21.9969659 },
    { lat: 49.0744295, lng: 21.997618 },
    { lat: 49.0759326, lng: 21.9992602 },
    { lat: 49.0767568, lng: 21.9996554 },
    { lat: 49.0771368, lng: 22.0000664 },
    { lat: 49.0783316, lng: 22.000914 },
    { lat: 49.0786248, lng: 22.0017411 },
    { lat: 49.0801039, lng: 22.003636 },
    { lat: 49.0812156, lng: 22.0037515 },
    { lat: 49.081506, lng: 22.0038963 },
    { lat: 49.0817437, lng: 22.0041455 },
    { lat: 49.0831735, lng: 22.0049849 },
    { lat: 49.082533, lng: 22.0064468 },
    { lat: 49.0808656, lng: 22.0071281 },
    { lat: 49.0809465, lng: 22.0071667 },
    { lat: 49.0809416, lng: 22.0072469 },
    { lat: 49.0811898, lng: 22.0073751 },
    { lat: 49.0816071, lng: 22.00737 },
    { lat: 49.0820764, lng: 22.0071097 },
    { lat: 49.0823492, lng: 22.0072677 },
    { lat: 49.0824956, lng: 22.0070467 },
    { lat: 49.0830885, lng: 22.00705 },
    { lat: 49.0832821, lng: 22.0069189 },
    { lat: 49.083949, lng: 22.0067753 },
    { lat: 49.0841682, lng: 22.0065355 },
    { lat: 49.0844455, lng: 22.0066541 },
    { lat: 49.0845765, lng: 22.0065384 },
    { lat: 49.0847849, lng: 22.0065019 },
    { lat: 49.0848313, lng: 22.0063719 },
    { lat: 49.0853051, lng: 22.0064806 },
    { lat: 49.0860327, lng: 22.0061491 },
    { lat: 49.0865005, lng: 22.0064192 },
    { lat: 49.086744, lng: 22.0064023 },
    { lat: 49.0868329, lng: 22.006355 },
    { lat: 49.0869048, lng: 22.0061209 },
    { lat: 49.0867874, lng: 22.0058691 },
    { lat: 49.0868043, lng: 22.0054096 },
    { lat: 49.0869266, lng: 22.0050665 },
    { lat: 49.0868286, lng: 22.0046898 },
    { lat: 49.0869046, lng: 22.004528 },
    { lat: 49.0869065, lng: 22.0042397 },
    { lat: 49.0867411, lng: 22.0040929 },
    { lat: 49.0867215, lng: 22.0038016 },
    { lat: 49.0868674, lng: 22.0035113 },
    { lat: 49.0867515, lng: 22.0033201 },
    { lat: 49.0867556, lng: 22.0030206 },
    { lat: 49.086632, lng: 22.0022007 },
    { lat: 49.0865488, lng: 22.0018695 },
    { lat: 49.086389, lng: 22.0015035 },
    { lat: 49.0864764, lng: 22.0013931 },
    { lat: 49.0865348, lng: 22.0007392 },
    { lat: 49.0863957, lng: 22.0003889 },
    { lat: 49.0864866, lng: 22.000143 },
    { lat: 49.0864716, lng: 22.0000778 },
    { lat: 49.0862427, lng: 21.9998266 },
    { lat: 49.0859443, lng: 22.0000982 },
    { lat: 49.0858963, lng: 21.9999627 },
    { lat: 49.0859686, lng: 21.9996594 },
    { lat: 49.0858959, lng: 21.9994923 },
    { lat: 49.0856974, lng: 21.9996043 },
    { lat: 49.0856918, lng: 21.9994216 },
    { lat: 49.0854802, lng: 21.9989491 },
    { lat: 49.0853608, lng: 21.9988955 },
    { lat: 49.0853974, lng: 21.9985992 },
    { lat: 49.0852599, lng: 21.9979847 },
    { lat: 49.0851951, lng: 21.9979152 },
    { lat: 49.0848516, lng: 21.9979912 },
    { lat: 49.0848841, lng: 21.9977074 },
    { lat: 49.0847049, lng: 21.9976457 },
    { lat: 49.0844298, lng: 21.9976655 },
    { lat: 49.084525, lng: 21.9973228 },
    { lat: 49.0844219, lng: 21.9971937 },
    { lat: 49.0844311, lng: 21.9968817 },
    { lat: 49.0842682, lng: 21.9970265 },
    { lat: 49.0841989, lng: 21.9969981 },
    { lat: 49.0841418, lng: 21.9966357 },
    { lat: 49.0843, lng: 21.9964345 },
    { lat: 49.0840398, lng: 21.9963303 },
    { lat: 49.0841955, lng: 21.9960241 },
    { lat: 49.0838869, lng: 21.9954588 },
    { lat: 49.0837512, lng: 21.9953731 },
    { lat: 49.0834939, lng: 21.9958519 },
    { lat: 49.083375, lng: 21.9959186 },
    { lat: 49.0832735, lng: 21.996084 },
    { lat: 49.0832609, lng: 21.9962167 },
    { lat: 49.0831247, lng: 21.9962064 },
    { lat: 49.0826937, lng: 21.9957865 },
    { lat: 49.0825411, lng: 21.9958001 },
    { lat: 49.0822739, lng: 21.9956889 },
    { lat: 49.0822275, lng: 21.9955831 },
    { lat: 49.082284, lng: 21.9955028 },
    { lat: 49.082246, lng: 21.9952757 },
    { lat: 49.0821662, lng: 21.995231 },
    { lat: 49.0819516, lng: 21.995336 },
    { lat: 49.081818, lng: 21.9952471 },
    { lat: 49.0818053, lng: 21.9951032 },
    { lat: 49.0819474, lng: 21.9947916 },
    { lat: 49.0818994, lng: 21.9946213 },
    { lat: 49.0817851, lng: 21.9945445 },
    { lat: 49.0815401, lng: 21.9947826 },
    { lat: 49.0811838, lng: 21.9944765 },
    { lat: 49.0810186, lng: 21.9942046 },
    { lat: 49.0809138, lng: 21.9935904 },
    { lat: 49.0807838, lng: 21.9937086 },
    { lat: 49.0806663, lng: 21.9931713 },
    { lat: 49.0807854, lng: 21.9930279 },
    { lat: 49.080903, lng: 21.9924102 },
    { lat: 49.081061, lng: 21.9923371 },
    { lat: 49.0810636, lng: 21.991986 },
    { lat: 49.0810178, lng: 21.9917715 },
    { lat: 49.0810429, lng: 21.9915548 },
    { lat: 49.0810891, lng: 21.991554 },
    { lat: 49.0812405, lng: 21.9906097 },
    { lat: 49.0815739, lng: 21.9892908 },
    { lat: 49.0816744, lng: 21.9890799 },
    { lat: 49.0818312, lng: 21.9891421 },
    { lat: 49.0818661, lng: 21.9892876 },
    { lat: 49.0820863, lng: 21.9893663 },
    { lat: 49.0823953, lng: 21.9892129 },
    { lat: 49.0825506, lng: 21.9883874 },
    { lat: 49.0829502, lng: 21.9884423 },
    { lat: 49.0831012, lng: 21.9882246 },
    { lat: 49.0833513, lng: 21.9870479 },
    { lat: 49.084008, lng: 21.9869408 },
    { lat: 49.0844747, lng: 21.9826098 },
    { lat: 49.0846443, lng: 21.9805314 },
    { lat: 49.0840037, lng: 21.9770052 },
    { lat: 49.0838572, lng: 21.976537 },
    { lat: 49.0838143, lng: 21.975515 },
    { lat: 49.0831791, lng: 21.9740655 },
    { lat: 49.0824737, lng: 21.9722217 },
    { lat: 49.0823245, lng: 21.9709991 },
    { lat: 49.0818065, lng: 21.9708394 },
    { lat: 49.0814292, lng: 21.9705426 },
    { lat: 49.0804889, lng: 21.9700378 },
    { lat: 49.0799148, lng: 21.9699746 },
    { lat: 49.0791282, lng: 21.9695027 },
    { lat: 49.0784228, lng: 21.9694456 },
    { lat: 49.0783914, lng: 21.9693559 },
    { lat: 49.0783084, lng: 21.9693265 },
    { lat: 49.0778402, lng: 21.9694966 },
    { lat: 49.0778541, lng: 21.9695612 },
    { lat: 49.0776283, lng: 21.9697082 },
    { lat: 49.077573, lng: 21.9696543 },
    { lat: 49.0770644, lng: 21.9699218 },
    { lat: 49.0768294, lng: 21.9701526 },
    { lat: 49.0767911, lng: 21.9701106 },
    { lat: 49.0766262, lng: 21.9702582 },
    { lat: 49.0765655, lng: 21.9702139 },
    { lat: 49.0758112, lng: 21.9710569 },
    { lat: 49.075077, lng: 21.9717135 },
    { lat: 49.0746206, lng: 21.9723239 },
    { lat: 49.0736561, lng: 21.9727554 },
    { lat: 49.0726014, lng: 21.9726306 },
    { lat: 49.0717193, lng: 21.971754 },
    { lat: 49.0716825, lng: 21.9711276 },
    { lat: 49.0703335, lng: 21.970786 },
    { lat: 49.0701079, lng: 21.9704899 },
    { lat: 49.0694067, lng: 21.9702894 },
    { lat: 49.0692319, lng: 21.9694474 },
    { lat: 49.0690324, lng: 21.9694236 },
    { lat: 49.0687407, lng: 21.9688594 },
    { lat: 49.06811, lng: 21.9686026 },
    { lat: 49.0677155, lng: 21.968837 },
    { lat: 49.0674314, lng: 21.9687186 },
    { lat: 49.0665993, lng: 21.9689633 },
    { lat: 49.0659556, lng: 21.9675722 },
    { lat: 49.0654975, lng: 21.9663288 },
    { lat: 49.0652404, lng: 21.9652476 },
    { lat: 49.0644685, lng: 21.9651438 },
    { lat: 49.0640985, lng: 21.963908 },
    { lat: 49.0631707, lng: 21.9639655 },
    { lat: 49.063002, lng: 21.9629601 },
    { lat: 49.063073, lng: 21.9623226 },
    { lat: 49.0629117, lng: 21.9603443 },
    { lat: 49.0628059, lng: 21.9600702 },
    { lat: 49.0627626, lng: 21.9597649 },
    { lat: 49.0628806, lng: 21.9596298 },
    { lat: 49.0628626, lng: 21.9585364 },
    { lat: 49.0630497, lng: 21.9576344 },
    { lat: 49.065237, lng: 21.9519679 },
    { lat: 49.0659053, lng: 21.950695 },
    { lat: 49.0653359, lng: 21.9490011 },
    { lat: 49.0646229, lng: 21.9473238 },
    { lat: 49.0638379, lng: 21.9452901 },
    { lat: 49.0636466, lng: 21.9433311 },
    { lat: 49.0635532, lng: 21.9416463 },
    { lat: 49.0633203, lng: 21.941551 },
    { lat: 49.0633237, lng: 21.943553 },
    { lat: 49.0631986, lng: 21.9444491 },
    { lat: 49.0627135, lng: 21.94603 },
    { lat: 49.0625875, lng: 21.9466613 },
    { lat: 49.0624757, lng: 21.9470594 },
    { lat: 49.0623269, lng: 21.9472756 },
    { lat: 49.0620568, lng: 21.9481304 },
    { lat: 49.0620045, lng: 21.9491543 },
    { lat: 49.0620455, lng: 21.9493542 },
    { lat: 49.0619415, lng: 21.9495617 },
    { lat: 49.0619613, lng: 21.9498666 },
    { lat: 49.0616265, lng: 21.9505575 },
    { lat: 49.0615452, lng: 21.9510052 },
    { lat: 49.0616795, lng: 21.9517493 },
    { lat: 49.0616718, lng: 21.9523444 },
    { lat: 49.0617402, lng: 21.9525173 },
    { lat: 49.0617201, lng: 21.9529829 },
    { lat: 49.061637, lng: 21.9532614 },
    { lat: 49.0616397, lng: 21.9538326 },
    { lat: 49.0614728, lng: 21.9541244 },
    { lat: 49.0614958, lng: 21.9545133 },
    { lat: 49.0612984, lng: 21.9553101 },
    { lat: 49.0614172, lng: 21.9558345 },
    { lat: 49.0613715, lng: 21.9559914 },
    { lat: 49.0609374, lng: 21.9565868 },
    { lat: 49.0610463, lng: 21.9573987 },
    { lat: 49.0612236, lng: 21.9573804 },
    { lat: 49.0613274, lng: 21.9572116 },
    { lat: 49.0614337, lng: 21.9571919 },
    { lat: 49.0618662, lng: 21.9574021 },
    { lat: 49.0617326, lng: 21.9583248 },
    { lat: 49.0617434, lng: 21.9588934 },
    { lat: 49.0615667, lng: 21.9597589 },
    { lat: 49.0607972, lng: 21.9601148 },
    { lat: 49.0600721, lng: 21.9602746 },
    { lat: 49.0596602, lng: 21.9599918 },
    { lat: 49.0595943, lng: 21.9600544 },
    { lat: 49.059375, lng: 21.9608355 },
    { lat: 49.0592861, lng: 21.9610134 },
    { lat: 49.0592037, lng: 21.9618692 },
    { lat: 49.0588435, lng: 21.9634571 },
    { lat: 49.0563862, lng: 21.965258 },
    { lat: 49.0557539, lng: 21.9655264 },
    { lat: 49.0552417, lng: 21.9653744 },
    { lat: 49.0551014, lng: 21.9656578 },
    { lat: 49.0548238, lng: 21.9657113 },
    { lat: 49.0547845, lng: 21.9655292 },
    { lat: 49.0542823, lng: 21.9656966 },
    { lat: 49.0540586, lng: 21.9664811 },
    { lat: 49.0541334, lng: 21.9666852 },
    { lat: 49.0541039, lng: 21.9679983 },
    { lat: 49.0539789, lng: 21.9689092 },
    { lat: 49.0540158, lng: 21.9694041 },
    { lat: 49.0539665, lng: 21.9699674 },
    { lat: 49.0539507, lng: 21.9714247 },
    { lat: 49.0539027, lng: 21.9718506 },
    { lat: 49.0539681, lng: 21.9724619 },
    { lat: 49.0539529, lng: 21.9747755 },
    { lat: 49.0539312, lng: 21.974898 },
    { lat: 49.0538657, lng: 21.9748881 },
    { lat: 49.0538136, lng: 21.9754286 },
    { lat: 49.0538098, lng: 21.9779779 },
    { lat: 49.053595, lng: 21.9819793 },
    { lat: 49.0527705, lng: 21.9829437 },
    { lat: 49.0523401, lng: 21.983328 },
    { lat: 49.0522557, lng: 21.9835982 },
    { lat: 49.0524318, lng: 21.984961 },
    { lat: 49.0524678, lng: 21.9861151 },
    { lat: 49.0521673, lng: 21.9873069 },
    { lat: 49.0511584, lng: 21.9875407 },
    { lat: 49.0504167, lng: 21.9887222 },
    { lat: 49.0503612, lng: 21.9889803 },
    { lat: 49.050071, lng: 21.9893925 },
    { lat: 49.0496687, lng: 21.9902827 },
    { lat: 49.0493909, lng: 21.9905676 },
    { lat: 49.0492448, lng: 21.9908498 },
    { lat: 49.0489529, lng: 21.9911926 },
    { lat: 49.0489524, lng: 21.9912618 },
    { lat: 49.0488841, lng: 21.9913686 },
    { lat: 49.0488831, lng: 21.9915576 },
    { lat: 49.0487255, lng: 21.9921058 },
    { lat: 49.0483515, lng: 21.9923586 },
    { lat: 49.0481615, lng: 21.9925973 },
    { lat: 49.0477761, lng: 21.9927514 },
    { lat: 49.0476131, lng: 21.9929413 },
    { lat: 49.0470009, lng: 21.9931117 },
    { lat: 49.0460422, lng: 21.9941127 },
    { lat: 49.0458354, lng: 21.9943994 },
    { lat: 49.0455896, lng: 21.9945566 },
    { lat: 49.0445604, lng: 21.9958972 },
    { lat: 49.0445514, lng: 21.996177 },
    { lat: 49.0442563, lng: 21.9964396 },
    { lat: 49.0440152, lng: 21.9971071 },
    { lat: 49.043718, lng: 21.9974286 },
    { lat: 49.0434303, lng: 21.9979948 },
    { lat: 49.0434615, lng: 21.9986284 },
    { lat: 49.0434182, lng: 21.9989693 },
    { lat: 49.0435127, lng: 21.9999019 },
    { lat: 49.0433938, lng: 22.0001485 },
    { lat: 49.0434005, lng: 22.0006468 },
    { lat: 49.0434996, lng: 22.0008822 },
    { lat: 49.0434959, lng: 22.0011506 },
    { lat: 49.0432899, lng: 22.0015673 },
    { lat: 49.043342, lng: 22.0020889 },
    { lat: 49.0434422, lng: 22.0022292 },
    { lat: 49.0434608, lng: 22.0030403 },
    { lat: 49.0433857, lng: 22.0033769 },
    { lat: 49.0434909, lng: 22.0038654 },
    { lat: 49.0436312, lng: 22.0039952 },
    { lat: 49.0436819, lng: 22.0046474 },
    { lat: 49.0436316, lng: 22.0047001 },
    { lat: 49.0434173, lng: 22.0045425 },
    { lat: 49.0433049, lng: 22.0046807 },
    { lat: 49.0430977, lng: 22.0049986 },
    { lat: 49.0429347, lng: 22.0055574 },
    { lat: 49.0428074, lng: 22.0056936 },
    { lat: 49.0426171, lng: 22.0062317 },
    { lat: 49.0423415, lng: 22.0066107 },
    { lat: 49.0421608, lng: 22.0071263 },
    { lat: 49.0417897, lng: 22.0077355 },
    { lat: 49.0414449, lng: 22.0088759 },
    { lat: 49.0414758, lng: 22.0091306 },
    { lat: 49.0413144, lng: 22.0096179 },
    { lat: 49.0417064, lng: 22.0099232 },
    { lat: 49.0418799, lng: 22.0101638 },
    { lat: 49.0420185, lng: 22.0105952 },
    { lat: 49.0420364, lng: 22.0108183 },
    { lat: 49.0423838, lng: 22.0110415 },
    { lat: 49.0424524, lng: 22.0110036 },
    { lat: 49.0428416, lng: 22.0110941 },
    { lat: 49.0430628, lng: 22.0112649 },
    { lat: 49.0433029, lng: 22.0116605 },
    { lat: 49.043508, lng: 22.0126433 },
    { lat: 49.0437687, lng: 22.0132396 },
    { lat: 49.0439494, lng: 22.0138803 },
    { lat: 49.0441272, lng: 22.0141547 },
    { lat: 49.04442, lng: 22.0144007 },
    { lat: 49.0446667, lng: 22.0145156 },
    { lat: 49.0451173, lng: 22.0149157 },
    { lat: 49.0452797, lng: 22.0149335 },
    { lat: 49.045506, lng: 22.0151313 },
    { lat: 49.0456498, lng: 22.0149859 },
    { lat: 49.0459112, lng: 22.0149064 },
    { lat: 49.0461957, lng: 22.0150425 },
    { lat: 49.0462833, lng: 22.0151771 },
    { lat: 49.0463018, lng: 22.0151997 },
  ],
  SlovenskáVolová: [
    { lat: 48.9639508, lng: 21.8654326 },
    { lat: 48.9643047, lng: 21.8641327 },
    { lat: 48.964934, lng: 21.8626958 },
    { lat: 48.9651269, lng: 21.8619688 },
    { lat: 48.9653282, lng: 21.8617006 },
    { lat: 48.9654595, lng: 21.8613796 },
    { lat: 48.9656326, lng: 21.8612893 },
    { lat: 48.9657223, lng: 21.8611483 },
    { lat: 48.9660987, lng: 21.8616972 },
    { lat: 48.9662251, lng: 21.8609726 },
    { lat: 48.9663154, lng: 21.8608903 },
    { lat: 48.9670591, lng: 21.8611193 },
    { lat: 48.9680983, lng: 21.8611522 },
    { lat: 48.9687412, lng: 21.8610915 },
    { lat: 48.9691686, lng: 21.8624476 },
    { lat: 48.9697763, lng: 21.8623302 },
    { lat: 48.9705154, lng: 21.8620217 },
    { lat: 48.9707277, lng: 21.8618319 },
    { lat: 48.971127, lng: 21.8619241 },
    { lat: 48.9720896, lng: 21.8613371 },
    { lat: 48.9725906, lng: 21.8608636 },
    { lat: 48.9729227, lng: 21.8603456 },
    { lat: 48.9728848, lng: 21.8600048 },
    { lat: 48.9733996, lng: 21.8591534 },
    { lat: 48.9736035, lng: 21.8593476 },
    { lat: 48.9740327, lng: 21.8593653 },
    { lat: 48.9743633, lng: 21.8592305 },
    { lat: 48.9745565, lng: 21.859265 },
    { lat: 48.9751689, lng: 21.8590136 },
    { lat: 48.9762841, lng: 21.8589053 },
    { lat: 48.976783, lng: 21.8587881 },
    { lat: 48.9784642, lng: 21.8589003 },
    { lat: 48.9786301, lng: 21.858835 },
    { lat: 48.9798044, lng: 21.8587861 },
    { lat: 48.9799824, lng: 21.858843 },
    { lat: 48.9800629, lng: 21.8587816 },
    { lat: 48.9801408, lng: 21.8588326 },
    { lat: 48.9804003, lng: 21.8587736 },
    { lat: 48.9812167, lng: 21.8590724 },
    { lat: 48.9820228, lng: 21.859051 },
    { lat: 48.9821642, lng: 21.8595301 },
    { lat: 48.9822121, lng: 21.8599019 },
    { lat: 48.9823287, lng: 21.8602421 },
    { lat: 48.9832173, lng: 21.8616509 },
    { lat: 48.9833374, lng: 21.8616818 },
    { lat: 48.9834917, lng: 21.8620011 },
    { lat: 48.983655, lng: 21.8621824 },
    { lat: 48.9838227, lng: 21.8622724 },
    { lat: 48.9840301, lng: 21.8626835 },
    { lat: 48.9840541, lng: 21.8627476 },
    { lat: 48.9839884, lng: 21.8632139 },
    { lat: 48.9840368, lng: 21.863519 },
    { lat: 48.9849074, lng: 21.8637002 },
    { lat: 48.9849594, lng: 21.8637956 },
    { lat: 48.9849556, lng: 21.8639531 },
    { lat: 48.9850757, lng: 21.864033 },
    { lat: 48.9854025, lng: 21.8640535 },
    { lat: 48.9855777, lng: 21.8641765 },
    { lat: 48.9857376, lng: 21.8641803 },
    { lat: 48.9858355, lng: 21.8648865 },
    { lat: 48.9859046, lng: 21.864996 },
    { lat: 48.9859689, lng: 21.8648755 },
    { lat: 48.986367, lng: 21.8651769 },
    { lat: 48.9865316, lng: 21.8652367 },
    { lat: 48.9868668, lng: 21.8656718 },
    { lat: 48.9869074, lng: 21.8655434 },
    { lat: 48.9870587, lng: 21.8656186 },
    { lat: 48.9875117, lng: 21.8661332 },
    { lat: 48.9877313, lng: 21.8662295 },
    { lat: 48.987966, lng: 21.8667837 },
    { lat: 48.9884962, lng: 21.8673292 },
    { lat: 48.9886143, lng: 21.8678126 },
    { lat: 48.9887755, lng: 21.8678755 },
    { lat: 48.9889058, lng: 21.8681687 },
    { lat: 48.9889626, lng: 21.8680676 },
    { lat: 48.9891433, lng: 21.8682661 },
    { lat: 48.9893046, lng: 21.8681939 },
    { lat: 48.9894476, lng: 21.8684721 },
    { lat: 48.9894431, lng: 21.8686461 },
    { lat: 48.9894844, lng: 21.868721 },
    { lat: 48.9895367, lng: 21.8687014 },
    { lat: 48.9897035, lng: 21.8690237 },
    { lat: 48.9899983, lng: 21.8693341 },
    { lat: 48.9900991, lng: 21.8695341 },
    { lat: 48.9901807, lng: 21.8695985 },
    { lat: 48.990387, lng: 21.8695752 },
    { lat: 48.9905322, lng: 21.8697121 },
    { lat: 48.9906508, lng: 21.8696865 },
    { lat: 48.9917239, lng: 21.8703939 },
    { lat: 48.9917503, lng: 21.8705432 },
    { lat: 48.9918246, lng: 21.8705442 },
    { lat: 48.9921734, lng: 21.8710417 },
    { lat: 48.9922347, lng: 21.8709668 },
    { lat: 48.9923017, lng: 21.8710463 },
    { lat: 48.9922895, lng: 21.871177 },
    { lat: 48.992502, lng: 21.8716166 },
    { lat: 48.9925907, lng: 21.8715269 },
    { lat: 48.9926679, lng: 21.8715513 },
    { lat: 48.9926934, lng: 21.8719295 },
    { lat: 48.9928096, lng: 21.8720338 },
    { lat: 48.9927704, lng: 21.8723563 },
    { lat: 48.9930925, lng: 21.8730276 },
    { lat: 48.9932577, lng: 21.8730717 },
    { lat: 48.9933525, lng: 21.8732693 },
    { lat: 48.9935192, lng: 21.8733995 },
    { lat: 48.9936017, lng: 21.8736286 },
    { lat: 48.9937767, lng: 21.8737938 },
    { lat: 48.9940695, lng: 21.873941 },
    { lat: 48.994138, lng: 21.8741143 },
    { lat: 48.9942215, lng: 21.8741646 },
    { lat: 48.9947979, lng: 21.8758628 },
    { lat: 48.9947917, lng: 21.8760161 },
    { lat: 48.9949356, lng: 21.8763748 },
    { lat: 48.9949481, lng: 21.8766438 },
    { lat: 48.9952886, lng: 21.8772525 },
    { lat: 48.995494, lng: 21.8774518 },
    { lat: 48.9957646, lng: 21.8778773 },
    { lat: 48.9963077, lng: 21.8783162 },
    { lat: 48.9964161, lng: 21.8785936 },
    { lat: 48.99663, lng: 21.8788651 },
    { lat: 48.9968892, lng: 21.8801714 },
    { lat: 48.9977045, lng: 21.8798074 },
    { lat: 48.9980155, lng: 21.8797848 },
    { lat: 48.9992765, lng: 21.8789787 },
    { lat: 49.0007797, lng: 21.8781999 },
    { lat: 49.0013114, lng: 21.8773437 },
    { lat: 49.0013212, lng: 21.8772449 },
    { lat: 49.0013249, lng: 21.8772071 },
    { lat: 49.0013766, lng: 21.8766806 },
    { lat: 49.0012512, lng: 21.8757701 },
    { lat: 49.0014108, lng: 21.8756304 },
    { lat: 49.0012, lng: 21.874421 },
    { lat: 49.0007121, lng: 21.8721722 },
    { lat: 49.0014712, lng: 21.8704875 },
    { lat: 49.00181, lng: 21.8698782 },
    { lat: 49.0015241, lng: 21.8680095 },
    { lat: 49.0000914, lng: 21.8665676 },
    { lat: 48.9999355, lng: 21.8660182 },
    { lat: 48.9998946, lng: 21.8648914 },
    { lat: 48.9997442, lng: 21.8642457 },
    { lat: 48.9993825, lng: 21.8636032 },
    { lat: 48.9980825, lng: 21.8634487 },
    { lat: 48.9978861, lng: 21.8649129 },
    { lat: 48.9967354, lng: 21.8654102 },
    { lat: 48.9964706, lng: 21.8653029 },
    { lat: 48.9953123, lng: 21.8616069 },
    { lat: 48.9946374, lng: 21.8592348 },
    { lat: 48.993963, lng: 21.85846 },
    { lat: 48.99282, lng: 21.8583658 },
    { lat: 48.9928401, lng: 21.8578342 },
    { lat: 48.9929888, lng: 21.8572254 },
    { lat: 48.9929043, lng: 21.8564283 },
    { lat: 48.9925058, lng: 21.8560611 },
    { lat: 48.9920721, lng: 21.8548946 },
    { lat: 48.9916076, lng: 21.8531389 },
    { lat: 48.9909971, lng: 21.8524937 },
    { lat: 48.9909058, lng: 21.8522247 },
    { lat: 48.9907688, lng: 21.851439 },
    { lat: 48.9901785, lng: 21.8504459 },
    { lat: 48.9899277, lng: 21.8499196 },
    { lat: 48.9894834, lng: 21.8486883 },
    { lat: 48.9892461, lng: 21.8478652 },
    { lat: 48.9890111, lng: 21.8467251 },
    { lat: 48.9884914, lng: 21.8428256 },
    { lat: 48.9882611, lng: 21.8406905 },
    { lat: 48.9871725, lng: 21.8369044 },
    { lat: 48.9867936, lng: 21.8361683 },
    { lat: 48.9870709, lng: 21.8357962 },
    { lat: 48.9869001, lng: 21.8340971 },
    { lat: 48.9867764, lng: 21.8334411 },
    { lat: 48.9868175, lng: 21.8315021 },
    { lat: 48.9863314, lng: 21.8301023 },
    { lat: 48.9858702, lng: 21.8290929 },
    { lat: 48.9855516, lng: 21.8280843 },
    { lat: 48.9852855, lng: 21.8270632 },
    { lat: 48.9848086, lng: 21.8247078 },
    { lat: 48.9847827, lng: 21.8242724 },
    { lat: 48.9850773, lng: 21.8237491 },
    { lat: 48.985228, lng: 21.8232673 },
    { lat: 48.9853102, lng: 21.8227105 },
    { lat: 48.9853691, lng: 21.8218055 },
    { lat: 48.9851949, lng: 21.8215963 },
    { lat: 48.9843112, lng: 21.821307 },
    { lat: 48.9833542, lng: 21.82128 },
    { lat: 48.9824558, lng: 21.8213813 },
    { lat: 48.9818218, lng: 21.8217476 },
    { lat: 48.9816751, lng: 21.8210337 },
    { lat: 48.9812134, lng: 21.8211479 },
    { lat: 48.9809924, lng: 21.8215167 },
    { lat: 48.9808683, lng: 21.8215929 },
    { lat: 48.9807507, lng: 21.8219147 },
    { lat: 48.9803937, lng: 21.8219468 },
    { lat: 48.9800898, lng: 21.8224531 },
    { lat: 48.9798591, lng: 21.8230216 },
    { lat: 48.9794712, lng: 21.8235479 },
    { lat: 48.9792357, lng: 21.8239673 },
    { lat: 48.9779644, lng: 21.8245298 },
    { lat: 48.9777169, lng: 21.8243108 },
    { lat: 48.976983, lng: 21.8244727 },
    { lat: 48.9766214, lng: 21.8243636 },
    { lat: 48.9763249, lng: 21.8241657 },
    { lat: 48.9758932, lng: 21.8241342 },
    { lat: 48.9753185, lng: 21.8236141 },
    { lat: 48.9749606, lng: 21.824478 },
    { lat: 48.9745339, lng: 21.8240752 },
    { lat: 48.9737708, lng: 21.8237548 },
    { lat: 48.9736714, lng: 21.8236494 },
    { lat: 48.9736626, lng: 21.8234787 },
    { lat: 48.9732342, lng: 21.8229196 },
    { lat: 48.9726341, lng: 21.822294 },
    { lat: 48.9719721, lng: 21.8220997 },
    { lat: 48.9717049, lng: 21.8221679 },
    { lat: 48.9714773, lng: 21.8216272 },
    { lat: 48.971195, lng: 21.8212842 },
    { lat: 48.9710626, lng: 21.8211217 },
    { lat: 48.9692814, lng: 21.8238479 },
    { lat: 48.9678834, lng: 21.8306939 },
    { lat: 48.9669084, lng: 21.8308418 },
    { lat: 48.9667315, lng: 21.8313915 },
    { lat: 48.9674942, lng: 21.8330088 },
    { lat: 48.9672018, lng: 21.8335794 },
    { lat: 48.9653073, lng: 21.8359762 },
    { lat: 48.9636339, lng: 21.8335896 },
    { lat: 48.9635257, lng: 21.8343668 },
    { lat: 48.9629446, lng: 21.8352183 },
    { lat: 48.9627174, lng: 21.8356123 },
    { lat: 48.9630298, lng: 21.8362088 },
    { lat: 48.9629675, lng: 21.836582 },
    { lat: 48.9630395, lng: 21.8370624 },
    { lat: 48.9629958, lng: 21.8373357 },
    { lat: 48.9628779, lng: 21.837783 },
    { lat: 48.9622238, lng: 21.8386785 },
    { lat: 48.9624111, lng: 21.8388786 },
    { lat: 48.9627667, lng: 21.8394976 },
    { lat: 48.9631554, lng: 21.8395598 },
    { lat: 48.963245, lng: 21.8394159 },
    { lat: 48.9637073, lng: 21.8391064 },
    { lat: 48.9638909, lng: 21.8391123 },
    { lat: 48.964145, lng: 21.8394162 },
    { lat: 48.9646769, lng: 21.8392808 },
    { lat: 48.9651039, lng: 21.8393944 },
    { lat: 48.9650753, lng: 21.8399886 },
    { lat: 48.9651321, lng: 21.8406203 },
    { lat: 48.9653372, lng: 21.8417671 },
    { lat: 48.9653858, lng: 21.8424831 },
    { lat: 48.965602, lng: 21.8430531 },
    { lat: 48.9657024, lng: 21.8438147 },
    { lat: 48.9663224, lng: 21.8440809 },
    { lat: 48.9663125, lng: 21.8461949 },
    { lat: 48.966155, lng: 21.846714 },
    { lat: 48.9657982, lng: 21.8470144 },
    { lat: 48.9658458, lng: 21.8473327 },
    { lat: 48.9655438, lng: 21.8480212 },
    { lat: 48.9648015, lng: 21.8485097 },
    { lat: 48.9647398, lng: 21.8486859 },
    { lat: 48.9644088, lng: 21.8488118 },
    { lat: 48.9642359, lng: 21.8485614 },
    { lat: 48.9639943, lng: 21.8486151 },
    { lat: 48.9638781, lng: 21.84856 },
    { lat: 48.96347, lng: 21.8488727 },
    { lat: 48.9633826, lng: 21.8498116 },
    { lat: 48.9629145, lng: 21.8500258 },
    { lat: 48.9626988, lng: 21.8499855 },
    { lat: 48.9625988, lng: 21.8510064 },
    { lat: 48.9623634, lng: 21.8510367 },
    { lat: 48.9623259, lng: 21.852206 },
    { lat: 48.962206, lng: 21.8531449 },
    { lat: 48.9621373, lng: 21.8535854 },
    { lat: 48.9620129, lng: 21.8537803 },
    { lat: 48.9619573, lng: 21.8541974 },
    { lat: 48.962024, lng: 21.8548683 },
    { lat: 48.9621628, lng: 21.854839 },
    { lat: 48.9627134, lng: 21.8559603 },
    { lat: 48.9628776, lng: 21.8564432 },
    { lat: 48.9629967, lng: 21.8565293 },
    { lat: 48.9633965, lng: 21.857294 },
    { lat: 48.9633829, lng: 21.8578139 },
    { lat: 48.9635424, lng: 21.858731 },
    { lat: 48.9635594, lng: 21.8597023 },
    { lat: 48.963773, lng: 21.860487 },
    { lat: 48.9638244, lng: 21.8610979 },
    { lat: 48.9639546, lng: 21.8613877 },
    { lat: 48.9638818, lng: 21.8616652 },
    { lat: 48.9639357, lng: 21.8624088 },
    { lat: 48.9637694, lng: 21.8640038 },
    { lat: 48.9639508, lng: 21.8654326 },
  ],
  Víťazovce: [
    { lat: 49.0302702, lng: 21.8101654 },
    { lat: 49.0296135, lng: 21.8090007 },
    { lat: 49.0289228, lng: 21.8073562 },
    { lat: 49.0283339, lng: 21.8069478 },
    { lat: 49.0276874, lng: 21.8061801 },
    { lat: 49.0276093, lng: 21.8059855 },
    { lat: 49.0273408, lng: 21.8058686 },
    { lat: 49.0272289, lng: 21.8056778 },
    { lat: 49.0266848, lng: 21.8056872 },
    { lat: 49.0265334, lng: 21.8056203 },
    { lat: 49.0265897, lng: 21.8053649 },
    { lat: 49.0265196, lng: 21.8051799 },
    { lat: 49.0262113, lng: 21.805045 },
    { lat: 49.0261598, lng: 21.8049706 },
    { lat: 49.0259351, lng: 21.8052372 },
    { lat: 49.0257904, lng: 21.8050753 },
    { lat: 49.0257321, lng: 21.8051752 },
    { lat: 49.0258458, lng: 21.8053039 },
    { lat: 49.0258551, lng: 21.8054072 },
    { lat: 49.0257417, lng: 21.8054538 },
    { lat: 49.0255422, lng: 21.805397 },
    { lat: 49.0254011, lng: 21.8051187 },
    { lat: 49.0252129, lng: 21.8049773 },
    { lat: 49.0250356, lng: 21.8051231 },
    { lat: 49.0247738, lng: 21.8049636 },
    { lat: 49.0245574, lng: 21.8050809 },
    { lat: 49.0245299, lng: 21.8035419 },
    { lat: 49.0243795, lng: 21.8033402 },
    { lat: 49.0243703, lng: 21.8022529 },
    { lat: 49.0221615, lng: 21.8024893 },
    { lat: 49.0221305, lng: 21.8014693 },
    { lat: 49.0205097, lng: 21.8016066 },
    { lat: 49.0196049, lng: 21.8017685 },
    { lat: 49.0189376, lng: 21.8007635 },
    { lat: 49.0178639, lng: 21.7989706 },
    { lat: 49.0179523, lng: 21.7986893 },
    { lat: 49.0181301, lng: 21.798613 },
    { lat: 49.0190577, lng: 21.797254 },
    { lat: 49.0193589, lng: 21.7957327 },
    { lat: 49.0195356, lng: 21.7958199 },
    { lat: 49.0200897, lng: 21.7950664 },
    { lat: 49.0196626, lng: 21.794261 },
    { lat: 49.0206091, lng: 21.793289 },
    { lat: 49.0203111, lng: 21.7919145 },
    { lat: 49.0199609, lng: 21.7915546 },
    { lat: 49.0190687, lng: 21.7912963 },
    { lat: 49.0186369, lng: 21.7912507 },
    { lat: 49.0181035, lng: 21.7907754 },
    { lat: 49.0175329, lng: 21.7903723 },
    { lat: 49.0160673, lng: 21.7895225 },
    { lat: 49.0152044, lng: 21.78874 },
    { lat: 49.0136936, lng: 21.7875866 },
    { lat: 49.0117014, lng: 21.7879714 },
    { lat: 49.01179, lng: 21.7870949 },
    { lat: 49.0114004, lng: 21.7850268 },
    { lat: 49.0114619, lng: 21.7843236 },
    { lat: 49.0108024, lng: 21.7838844 },
    { lat: 49.0098931, lng: 21.783475 },
    { lat: 49.0096288, lng: 21.7835268 },
    { lat: 49.0096168, lng: 21.7836257 },
    { lat: 49.0097824, lng: 21.7848824 },
    { lat: 49.0097063, lng: 21.7851558 },
    { lat: 49.0094299, lng: 21.7855125 },
    { lat: 49.008484, lng: 21.7862068 },
    { lat: 49.0075769, lng: 21.7869654 },
    { lat: 49.0073089, lng: 21.7869941 },
    { lat: 49.0074107, lng: 21.7854669 },
    { lat: 49.0076597, lng: 21.7847972 },
    { lat: 49.0073565, lng: 21.7840018 },
    { lat: 49.0072799, lng: 21.7833962 },
    { lat: 49.0073317, lng: 21.7828825 },
    { lat: 49.0071819, lng: 21.782492 },
    { lat: 49.0071725, lng: 21.7822138 },
    { lat: 49.0068654, lng: 21.7817375 },
    { lat: 49.0067652, lng: 21.7813523 },
    { lat: 49.0064611, lng: 21.7809085 },
    { lat: 49.0062995, lng: 21.7808427 },
    { lat: 49.0062364, lng: 21.7808358 },
    { lat: 49.0061377, lng: 21.7817493 },
    { lat: 49.0060328, lng: 21.7819911 },
    { lat: 49.0062587, lng: 21.7824171 },
    { lat: 49.0063099, lng: 21.7835538 },
    { lat: 49.0064405, lng: 21.7839866 },
    { lat: 49.0063285, lng: 21.784696 },
    { lat: 49.0052588, lng: 21.7871932 },
    { lat: 49.0050773, lng: 21.787177 },
    { lat: 49.0050058, lng: 21.7877148 },
    { lat: 49.0048677, lng: 21.7882835 },
    { lat: 49.0045505, lng: 21.7889265 },
    { lat: 49.0043847, lng: 21.7894306 },
    { lat: 49.0045454, lng: 21.7895968 },
    { lat: 49.0043725, lng: 21.7901282 },
    { lat: 49.0040086, lng: 21.7917898 },
    { lat: 49.0035958, lng: 21.7927358 },
    { lat: 49.0035449, lng: 21.7942724 },
    { lat: 49.0034561, lng: 21.7944583 },
    { lat: 49.0029435, lng: 21.7950292 },
    { lat: 49.002462, lng: 21.7959299 },
    { lat: 49.0018474, lng: 21.7975179 },
    { lat: 49.0014901, lng: 21.79896 },
    { lat: 49.0015855, lng: 21.7989464 },
    { lat: 49.0014149, lng: 21.8006413 },
    { lat: 49.0013248, lng: 21.801293 },
    { lat: 49.0011836, lng: 21.8019178 },
    { lat: 49.0011271, lng: 21.8027262 },
    { lat: 49.0008788, lng: 21.8045078 },
    { lat: 49.0009053, lng: 21.805446 },
    { lat: 49.000774, lng: 21.8058902 },
    { lat: 49.0007207, lng: 21.8058712 },
    { lat: 49.0006003, lng: 21.8064381 },
    { lat: 48.9998889, lng: 21.8081966 },
    { lat: 48.9992125, lng: 21.8100471 },
    { lat: 48.9988983, lng: 21.8114177 },
    { lat: 48.9987935, lng: 21.8123698 },
    { lat: 48.9987296, lng: 21.8124574 },
    { lat: 48.998598, lng: 21.8126391 },
    { lat: 49.0004163, lng: 21.8133882 },
    { lat: 49.0037804, lng: 21.8149262 },
    { lat: 49.004372, lng: 21.8152347 },
    { lat: 49.0042565, lng: 21.8155515 },
    { lat: 49.0044065, lng: 21.8156443 },
    { lat: 49.0050771, lng: 21.815697 },
    { lat: 49.0050984, lng: 21.8162203 },
    { lat: 49.0052897, lng: 21.8169175 },
    { lat: 49.0054469, lng: 21.8172525 },
    { lat: 49.0055229, lng: 21.817756 },
    { lat: 49.0057877, lng: 21.8188187 },
    { lat: 49.0063664, lng: 21.8193527 },
    { lat: 49.0065525, lng: 21.8197236 },
    { lat: 49.0073339, lng: 21.819966 },
    { lat: 49.0080598, lng: 21.8205454 },
    { lat: 49.0087824, lng: 21.8202936 },
    { lat: 49.0091402, lng: 21.8203009 },
    { lat: 49.0091277, lng: 21.8224717 },
    { lat: 49.0095955, lng: 21.8222474 },
    { lat: 49.0106886, lng: 21.821884 },
    { lat: 49.0121314, lng: 21.8214813 },
    { lat: 49.0131171, lng: 21.8213604 },
    { lat: 49.0146315, lng: 21.8215817 },
    { lat: 49.0151062, lng: 21.8214092 },
    { lat: 49.0156085, lng: 21.8213033 },
    { lat: 49.0161452, lng: 21.8212666 },
    { lat: 49.016655, lng: 21.8210558 },
    { lat: 49.0164979, lng: 21.819855 },
    { lat: 49.0164079, lng: 21.8186726 },
    { lat: 49.0166754, lng: 21.8176704 },
    { lat: 49.017106, lng: 21.8180478 },
    { lat: 49.0173984, lng: 21.8182263 },
    { lat: 49.0177033, lng: 21.8182654 },
    { lat: 49.017861, lng: 21.818104 },
    { lat: 49.0177741, lng: 21.8172663 },
    { lat: 49.0175538, lng: 21.8166182 },
    { lat: 49.0182597, lng: 21.8168584 },
    { lat: 49.0188239, lng: 21.8169261 },
    { lat: 49.0188295, lng: 21.8173167 },
    { lat: 49.0192702, lng: 21.8168732 },
    { lat: 49.0194196, lng: 21.8169098 },
    { lat: 49.0196193, lng: 21.8167086 },
    { lat: 49.0197429, lng: 21.8158851 },
    { lat: 49.0199748, lng: 21.8155013 },
    { lat: 49.0208565, lng: 21.8157023 },
    { lat: 49.0212337, lng: 21.8155037 },
    { lat: 49.0214161, lng: 21.8142932 },
    { lat: 49.0216291, lng: 21.8140435 },
    { lat: 49.0219397, lng: 21.81405 },
    { lat: 49.0219706, lng: 21.81446 },
    { lat: 49.0222743, lng: 21.8147629 },
    { lat: 49.0227435, lng: 21.8149029 },
    { lat: 49.0232792, lng: 21.8149554 },
    { lat: 49.0244741, lng: 21.8155742 },
    { lat: 49.0255572, lng: 21.8157789 },
    { lat: 49.0256077, lng: 21.815553 },
    { lat: 49.026009, lng: 21.8159404 },
    { lat: 49.0264686, lng: 21.8157817 },
    { lat: 49.0265128, lng: 21.8149783 },
    { lat: 49.0271976, lng: 21.8153158 },
    { lat: 49.0275475, lng: 21.8149637 },
    { lat: 49.02841, lng: 21.814827 },
    { lat: 49.0287085, lng: 21.8151035 },
    { lat: 49.0287714, lng: 21.8152295 },
    { lat: 49.0300757, lng: 21.8155489 },
    { lat: 49.0308044, lng: 21.8152766 },
    { lat: 49.0305929, lng: 21.8132824 },
    { lat: 49.0303148, lng: 21.8123215 },
    { lat: 49.0302821, lng: 21.8115334 },
    { lat: 49.0305698, lng: 21.8105274 },
    { lat: 49.0302702, lng: 21.8101654 },
  ],
  Ptičie: [
    { lat: 48.9125815, lng: 21.924351 },
    { lat: 48.9111417, lng: 21.9251592 },
    { lat: 48.9111346, lng: 21.9251031 },
    { lat: 48.9100382, lng: 21.9251673 },
    { lat: 48.9095654, lng: 21.9256876 },
    { lat: 48.9086969, lng: 21.9251912 },
    { lat: 48.9083419, lng: 21.924721 },
    { lat: 48.9079097, lng: 21.9245296 },
    { lat: 48.90638, lng: 21.9244461 },
    { lat: 48.9058893, lng: 21.9241686 },
    { lat: 48.9042689, lng: 21.9240513 },
    { lat: 48.9033723, lng: 21.9231993 },
    { lat: 48.9028904, lng: 21.92201 },
    { lat: 48.9023242, lng: 21.9212742 },
    { lat: 48.901884, lng: 21.9209035 },
    { lat: 48.9017245, lng: 21.9205122 },
    { lat: 48.901431, lng: 21.9201094 },
    { lat: 48.9009799, lng: 21.9198361 },
    { lat: 48.9005068, lng: 21.9199034 },
    { lat: 48.899756, lng: 21.9202878 },
    { lat: 48.8990773, lng: 21.9207667 },
    { lat: 48.898922, lng: 21.9212104 },
    { lat: 48.8988117, lng: 21.9217473 },
    { lat: 48.8987121, lng: 21.9227109 },
    { lat: 48.8984546, lng: 21.9229424 },
    { lat: 48.8979809, lng: 21.9231631 },
    { lat: 48.8970323, lng: 21.9241762 },
    { lat: 48.896567, lng: 21.9248287 },
    { lat: 48.8961275, lng: 21.9248897 },
    { lat: 48.8952903, lng: 21.9248267 },
    { lat: 48.8950654, lng: 21.9254185 },
    { lat: 48.894945, lng: 21.9259959 },
    { lat: 48.8946983, lng: 21.9267834 },
    { lat: 48.8954297, lng: 21.9279761 },
    { lat: 48.8951012, lng: 21.9305383 },
    { lat: 48.8951395, lng: 21.9310802 },
    { lat: 48.8954127, lng: 21.932468 },
    { lat: 48.8957833, lng: 21.9337059 },
    { lat: 48.8958198, lng: 21.9353515 },
    { lat: 48.8961358, lng: 21.935259 },
    { lat: 48.896278, lng: 21.9350568 },
    { lat: 48.8965312, lng: 21.9376284 },
    { lat: 48.8966359, lng: 21.9378105 },
    { lat: 48.8965891, lng: 21.9380606 },
    { lat: 48.8966529, lng: 21.9381799 },
    { lat: 48.8966444, lng: 21.9383302 },
    { lat: 48.8963012, lng: 21.9385121 },
    { lat: 48.8980169, lng: 21.9446282 },
    { lat: 48.8984775, lng: 21.9454646 },
    { lat: 48.8983799, lng: 21.9456039 },
    { lat: 48.8969496, lng: 21.9465837 },
    { lat: 48.895351, lng: 21.9487392 },
    { lat: 48.8951541, lng: 21.9497339 },
    { lat: 48.895239, lng: 21.950068 },
    { lat: 48.8948008, lng: 21.9514075 },
    { lat: 48.8942882, lng: 21.9518761 },
    { lat: 48.8941744, lng: 21.9524581 },
    { lat: 48.8941267, lng: 21.9533964 },
    { lat: 48.8943102, lng: 21.9538968 },
    { lat: 48.8941262, lng: 21.9545943 },
    { lat: 48.8941584, lng: 21.9549922 },
    { lat: 48.8940356, lng: 21.9554389 },
    { lat: 48.8940399, lng: 21.9557468 },
    { lat: 48.8938875, lng: 21.9562277 },
    { lat: 48.8933802, lng: 21.956998 },
    { lat: 48.8931209, lng: 21.9581113 },
    { lat: 48.8928846, lng: 21.9586599 },
    { lat: 48.8924895, lng: 21.9598681 },
    { lat: 48.8921463, lng: 21.9612332 },
    { lat: 48.8891347, lng: 21.9617636 },
    { lat: 48.8884916, lng: 21.9616471 },
    { lat: 48.8861467, lng: 21.9633906 },
    { lat: 48.8864278, lng: 21.9634354 },
    { lat: 48.8855605, lng: 21.9647471 },
    { lat: 48.8854098, lng: 21.9652117 },
    { lat: 48.8849631, lng: 21.9662075 },
    { lat: 48.8847814, lng: 21.9667318 },
    { lat: 48.8857704, lng: 21.9673787 },
    { lat: 48.8868769, lng: 21.9682699 },
    { lat: 48.8873389, lng: 21.9689039 },
    { lat: 48.8879473, lng: 21.9694017 },
    { lat: 48.8886948, lng: 21.9691438 },
    { lat: 48.8899097, lng: 21.9689262 },
    { lat: 48.8903212, lng: 21.9683188 },
    { lat: 48.8910746, lng: 21.968809 },
    { lat: 48.894336, lng: 21.9875112 },
    { lat: 48.8952491, lng: 21.9868556 },
    { lat: 48.8991702, lng: 21.9840065 },
    { lat: 48.9038972, lng: 21.9813132 },
    { lat: 48.9042265, lng: 21.9810768 },
    { lat: 48.9043858, lng: 21.9810539 },
    { lat: 48.9047504, lng: 21.9806089 },
    { lat: 48.9049902, lng: 21.9805664 },
    { lat: 48.9063644, lng: 21.9793639 },
    { lat: 48.906625, lng: 21.9798984 },
    { lat: 48.906628, lng: 21.9800219 },
    { lat: 48.9064209, lng: 21.9799685 },
    { lat: 48.9063565, lng: 21.9805014 },
    { lat: 48.9063899, lng: 21.980586 },
    { lat: 48.906343, lng: 21.9807414 },
    { lat: 48.9064682, lng: 21.9812109 },
    { lat: 48.9064964, lng: 21.9815347 },
    { lat: 48.9070567, lng: 21.9818135 },
    { lat: 48.9074873, lng: 21.981851 },
    { lat: 48.9078994, lng: 21.9821904 },
    { lat: 48.9083417, lng: 21.9821475 },
    { lat: 48.9088364, lng: 21.982739 },
    { lat: 48.9101369, lng: 21.9829248 },
    { lat: 48.9104935, lng: 21.9832981 },
    { lat: 48.9105571, lng: 21.9834997 },
    { lat: 48.9111143, lng: 21.9836507 },
    { lat: 48.9112676, lng: 21.9838963 },
    { lat: 48.9115172, lng: 21.9837982 },
    { lat: 48.9116886, lng: 21.9840503 },
    { lat: 48.913898, lng: 21.9816218 },
    { lat: 48.9138788, lng: 21.9815782 },
    { lat: 48.9142345, lng: 21.9813589 },
    { lat: 48.9148868, lng: 21.9812169 },
    { lat: 48.9153691, lng: 21.981017 },
    { lat: 48.9160566, lng: 21.9804731 },
    { lat: 48.9170221, lng: 21.9791704 },
    { lat: 48.9183721, lng: 21.9770587 },
    { lat: 48.918147, lng: 21.9767331 },
    { lat: 48.9172536, lng: 21.9758577 },
    { lat: 48.9168418, lng: 21.9749854 },
    { lat: 48.916438, lng: 21.9746696 },
    { lat: 48.9164057, lng: 21.97445 },
    { lat: 48.916429, lng: 21.9738919 },
    { lat: 48.9166031, lng: 21.9732347 },
    { lat: 48.9163244, lng: 21.9725595 },
    { lat: 48.9159338, lng: 21.9718596 },
    { lat: 48.9160038, lng: 21.9710935 },
    { lat: 48.9162865, lng: 21.9701969 },
    { lat: 48.9163952, lng: 21.9683532 },
    { lat: 48.9163298, lng: 21.9676226 },
    { lat: 48.9161298, lng: 21.9663923 },
    { lat: 48.9163319, lng: 21.9658318 },
    { lat: 48.9168585, lng: 21.9654293 },
    { lat: 48.9171111, lng: 21.9646818 },
    { lat: 48.9171496, lng: 21.9637783 },
    { lat: 48.9172432, lng: 21.9633281 },
    { lat: 48.9171953, lng: 21.9629359 },
    { lat: 48.9169436, lng: 21.9625465 },
    { lat: 48.9170256, lng: 21.961265 },
    { lat: 48.9169134, lng: 21.9602281 },
    { lat: 48.9167922, lng: 21.9596903 },
    { lat: 48.9168679, lng: 21.9592518 },
    { lat: 48.9168861, lng: 21.9588084 },
    { lat: 48.9171817, lng: 21.957567 },
    { lat: 48.9167247, lng: 21.9568269 },
    { lat: 48.916372, lng: 21.9561178 },
    { lat: 48.915517, lng: 21.9558868 },
    { lat: 48.9150463, lng: 21.9558246 },
    { lat: 48.9148562, lng: 21.95555 },
    { lat: 48.9144849, lng: 21.9553201 },
    { lat: 48.9143121, lng: 21.9525622 },
    { lat: 48.9145985, lng: 21.9523592 },
    { lat: 48.9147264, lng: 21.9510831 },
    { lat: 48.9148445, lng: 21.949943 },
    { lat: 48.9148822, lng: 21.9489241 },
    { lat: 48.9145819, lng: 21.9470704 },
    { lat: 48.9148259, lng: 21.9457558 },
    { lat: 48.9149492, lng: 21.9433851 },
    { lat: 48.9148291, lng: 21.9426688 },
    { lat: 48.9146305, lng: 21.9419589 },
    { lat: 48.9144001, lng: 21.9407901 },
    { lat: 48.9141075, lng: 21.939936 },
    { lat: 48.9133614, lng: 21.9381531 },
    { lat: 48.9132861, lng: 21.9377046 },
    { lat: 48.9134306, lng: 21.9375418 },
    { lat: 48.9133557, lng: 21.9363906 },
    { lat: 48.9132644, lng: 21.9363397 },
    { lat: 48.9128629, lng: 21.9318274 },
    { lat: 48.9126163, lng: 21.9317325 },
    { lat: 48.912424, lng: 21.9317832 },
    { lat: 48.9122908, lng: 21.9312627 },
    { lat: 48.9121021, lng: 21.9311426 },
    { lat: 48.9117333, lng: 21.9300606 },
    { lat: 48.9123891, lng: 21.9249811 },
    { lat: 48.9124594, lng: 21.9246452 },
    { lat: 48.9125815, lng: 21.924351 },
  ],
  Hudcovce: [
    { lat: 48.9074603, lng: 21.8142095 },
    { lat: 48.907963, lng: 21.8133788 },
    { lat: 48.9093016, lng: 21.8111687 },
    { lat: 48.9100327, lng: 21.8102949 },
    { lat: 48.9118761, lng: 21.8088155 },
    { lat: 48.9137788, lng: 21.8134531 },
    { lat: 48.913862, lng: 21.8133701 },
    { lat: 48.9139793, lng: 21.810391 },
    { lat: 48.9127965, lng: 21.8096445 },
    { lat: 48.9124736, lng: 21.8092916 },
    { lat: 48.9120414, lng: 21.8076401 },
    { lat: 48.912177, lng: 21.807623 },
    { lat: 48.9136489, lng: 21.8060835 },
    { lat: 48.9136063, lng: 21.8051332 },
    { lat: 48.9141546, lng: 21.8045896 },
    { lat: 48.9152712, lng: 21.8048771 },
    { lat: 48.9156425, lng: 21.8033204 },
    { lat: 48.9162241, lng: 21.8027608 },
    { lat: 48.9173233, lng: 21.8018586 },
    { lat: 48.917616, lng: 21.8007494 },
    { lat: 48.917228, lng: 21.8006271 },
    { lat: 48.9170936, lng: 21.7986622 },
    { lat: 48.9177979, lng: 21.7978025 },
    { lat: 48.9180369, lng: 21.7976465 },
    { lat: 48.9184676, lng: 21.7972157 },
    { lat: 48.9182874, lng: 21.7965627 },
    { lat: 48.9181998, lng: 21.7954913 },
    { lat: 48.9196562, lng: 21.7951676 },
    { lat: 48.9216073, lng: 21.7950996 },
    { lat: 48.9238681, lng: 21.7946066 },
    { lat: 48.9254114, lng: 21.793399 },
    { lat: 48.9257596, lng: 21.7905389 },
    { lat: 48.9260181, lng: 21.7904482 },
    { lat: 48.9273519, lng: 21.7917184 },
    { lat: 48.9275982, lng: 21.7918073 },
    { lat: 48.9279552, lng: 21.7909197 },
    { lat: 48.926635, lng: 21.7897714 },
    { lat: 48.9268117, lng: 21.7894774 },
    { lat: 48.926933, lng: 21.7891241 },
    { lat: 48.9271686, lng: 21.7878578 },
    { lat: 48.9275834, lng: 21.7862518 },
    { lat: 48.9277725, lng: 21.7866057 },
    { lat: 48.9281095, lng: 21.7870587 },
    { lat: 48.9286298, lng: 21.7868609 },
    { lat: 48.9289235, lng: 21.7866092 },
    { lat: 48.9293965, lng: 21.7859843 },
    { lat: 48.9290296, lng: 21.7843893 },
    { lat: 48.9291979, lng: 21.7838586 },
    { lat: 48.9299767, lng: 21.7822817 },
    { lat: 48.9303248, lng: 21.7807598 },
    { lat: 48.9307722, lng: 21.7794111 },
    { lat: 48.931876, lng: 21.778257 },
    { lat: 48.93136, lng: 21.777662 },
    { lat: 48.930113, lng: 21.776902 },
    { lat: 48.929842, lng: 21.776656 },
    { lat: 48.929274, lng: 21.776019 },
    { lat: 48.927546, lng: 21.775539 },
    { lat: 48.925385, lng: 21.776467 },
    { lat: 48.924704, lng: 21.779116 },
    { lat: 48.92467, lng: 21.77914 },
    { lat: 48.92378, lng: 21.779735 },
    { lat: 48.92283, lng: 21.779892 },
    { lat: 48.921929, lng: 21.780014 },
    { lat: 48.920992, lng: 21.780149 },
    { lat: 48.920022, lng: 21.780266 },
    { lat: 48.919144, lng: 21.780342 },
    { lat: 48.91855, lng: 21.780083 },
    { lat: 48.917757, lng: 21.780112 },
    { lat: 48.917528, lng: 21.780119 },
    { lat: 48.916591, lng: 21.780188 },
    { lat: 48.916237, lng: 21.780224 },
    { lat: 48.915616, lng: 21.780302 },
    { lat: 48.914198, lng: 21.779924 },
    { lat: 48.913529, lng: 21.779949 },
    { lat: 48.912337, lng: 21.780397 },
    { lat: 48.911577, lng: 21.780355 },
    { lat: 48.910939, lng: 21.777441 },
    { lat: 48.910875, lng: 21.77646 },
    { lat: 48.908761, lng: 21.777738 },
    { lat: 48.908739, lng: 21.777668 },
    { lat: 48.908704, lng: 21.777573 },
    { lat: 48.908678, lng: 21.777503 },
    { lat: 48.907881, lng: 21.775377 },
    { lat: 48.905699, lng: 21.776426 },
    { lat: 48.905635, lng: 21.776455 },
    { lat: 48.904626, lng: 21.777189 },
    { lat: 48.904613, lng: 21.777198 },
    { lat: 48.904558, lng: 21.777244 },
    { lat: 48.904549, lng: 21.777251 },
    { lat: 48.90453, lng: 21.777266 },
    { lat: 48.904493, lng: 21.777295 },
    { lat: 48.90444, lng: 21.777336 },
    { lat: 48.904403, lng: 21.777365 },
    { lat: 48.904686, lng: 21.778534 },
    { lat: 48.903483, lng: 21.779137 },
    { lat: 48.903362, lng: 21.779199 },
    { lat: 48.903464, lng: 21.779812 },
    { lat: 48.903439, lng: 21.780033 },
    { lat: 48.903415, lng: 21.780059 },
    { lat: 48.903473, lng: 21.780156 },
    { lat: 48.903466, lng: 21.780421 },
    { lat: 48.903466, lng: 21.780817 },
    { lat: 48.903464, lng: 21.781067 },
    { lat: 48.903511, lng: 21.781435 },
    { lat: 48.903472, lng: 21.781768 },
    { lat: 48.903446, lng: 21.781929 },
    { lat: 48.903471, lng: 21.782135 },
    { lat: 48.903584, lng: 21.782501 },
    { lat: 48.903727, lng: 21.782903 },
    { lat: 48.903894, lng: 21.783375 },
    { lat: 48.903944, lng: 21.783631 },
    { lat: 48.904286, lng: 21.784867 },
    { lat: 48.904519, lng: 21.785633 },
    { lat: 48.90458, lng: 21.786045 },
    { lat: 48.904515, lng: 21.786091 },
    { lat: 48.903484, lng: 21.786793 },
    { lat: 48.901878, lng: 21.788746 },
    { lat: 48.901298, lng: 21.789199 },
    { lat: 48.90012, lng: 21.78902 },
    { lat: 48.898838, lng: 21.788579 },
    { lat: 48.898562, lng: 21.788326 },
    { lat: 48.897948, lng: 21.787818 },
    { lat: 48.897018, lng: 21.787165 },
    { lat: 48.896652, lng: 21.787034 },
    { lat: 48.89635, lng: 21.787032 },
    { lat: 48.895916, lng: 21.78706 },
    { lat: 48.895867, lng: 21.78715 },
    { lat: 48.895893, lng: 21.787876 },
    { lat: 48.895961, lng: 21.788115 },
    { lat: 48.896005, lng: 21.788253 },
    { lat: 48.896083, lng: 21.788466 },
    { lat: 48.896083, lng: 21.788606 },
    { lat: 48.896004, lng: 21.788701 },
    { lat: 48.895841, lng: 21.788703 },
    { lat: 48.895769, lng: 21.788731 },
    { lat: 48.895682, lng: 21.788952 },
    { lat: 48.895469, lng: 21.789171 },
    { lat: 48.895432, lng: 21.789329 },
    { lat: 48.895439, lng: 21.789551 },
    { lat: 48.895257, lng: 21.789993 },
    { lat: 48.895132, lng: 21.790165 },
    { lat: 48.895082, lng: 21.790369 },
    { lat: 48.894894, lng: 21.790589 },
    { lat: 48.894816, lng: 21.790959 },
    { lat: 48.89463, lng: 21.791126 },
    { lat: 48.894418, lng: 21.791238 },
    { lat: 48.894337, lng: 21.791285 },
    { lat: 48.894149, lng: 21.791218 },
    { lat: 48.894022, lng: 21.791343 },
    { lat: 48.893983, lng: 21.791427 },
    { lat: 48.894059, lng: 21.79154 },
    { lat: 48.894128, lng: 21.791599 },
    { lat: 48.894572, lng: 21.791712 },
    { lat: 48.895068, lng: 21.791835 },
    { lat: 48.895167, lng: 21.791943 },
    { lat: 48.895271, lng: 21.792018 },
    { lat: 48.895295, lng: 21.792093 },
    { lat: 48.895461, lng: 21.792158 },
    { lat: 48.89552, lng: 21.792565 },
    { lat: 48.895594, lng: 21.792744 },
    { lat: 48.895943, lng: 21.793206 },
    { lat: 48.896299, lng: 21.793137 },
    { lat: 48.89658, lng: 21.793051 },
    { lat: 48.896993, lng: 21.7929 },
    { lat: 48.897726, lng: 21.792646 },
    { lat: 48.89775, lng: 21.793179 },
    { lat: 48.897756, lng: 21.79331 },
    { lat: 48.897768, lng: 21.793593 },
    { lat: 48.897782, lng: 21.793862 },
    { lat: 48.897785, lng: 21.793893 },
    { lat: 48.897802, lng: 21.794078 },
    { lat: 48.897862, lng: 21.794712 },
    { lat: 48.897866, lng: 21.795456 },
    { lat: 48.897906, lng: 21.795901 },
    { lat: 48.89794, lng: 21.796084 },
    { lat: 48.897994, lng: 21.79649 },
    { lat: 48.898021, lng: 21.796779 },
    { lat: 48.898028, lng: 21.79703 },
    { lat: 48.898029, lng: 21.797114 },
    { lat: 48.898146, lng: 21.79729 },
    { lat: 48.898195, lng: 21.797366 },
    { lat: 48.898507, lng: 21.79754 },
    { lat: 48.898648, lng: 21.797617 },
    { lat: 48.898903, lng: 21.797725 },
    { lat: 48.899288, lng: 21.7979 },
    { lat: 48.899296, lng: 21.797904 },
    { lat: 48.899731, lng: 21.798031 },
    { lat: 48.899937, lng: 21.798045 },
    { lat: 48.900031, lng: 21.79809 },
    { lat: 48.900163, lng: 21.798151 },
    { lat: 48.900469, lng: 21.798397 },
    { lat: 48.900733, lng: 21.798567 },
    { lat: 48.900787, lng: 21.798612 },
    { lat: 48.900928, lng: 21.798741 },
    { lat: 48.901046, lng: 21.798914 },
    { lat: 48.901123, lng: 21.799084 },
    { lat: 48.901227, lng: 21.799317 },
    { lat: 48.901383, lng: 21.799637 },
    { lat: 48.901346, lng: 21.80058 },
    { lat: 48.90131, lng: 21.801504 },
    { lat: 48.901301, lng: 21.801742 },
    { lat: 48.901222, lng: 21.803741 },
    { lat: 48.901303, lng: 21.804842 },
    { lat: 48.901327, lng: 21.805174 },
    { lat: 48.901369, lng: 21.805456 },
    { lat: 48.901375, lng: 21.805484 },
    { lat: 48.901411, lng: 21.805651 },
    { lat: 48.901381, lng: 21.806586 },
    { lat: 48.901382, lng: 21.806621 },
    { lat: 48.901451, lng: 21.806881 },
    { lat: 48.90158, lng: 21.807047 },
    { lat: 48.901674, lng: 21.807198 },
    { lat: 48.901792, lng: 21.807337 },
    { lat: 48.901855, lng: 21.807596 },
    { lat: 48.901922, lng: 21.807788 },
    { lat: 48.901965, lng: 21.807981 },
    { lat: 48.901949, lng: 21.808378 },
    { lat: 48.901961, lng: 21.809053 },
    { lat: 48.901882, lng: 21.809461 },
    { lat: 48.901914, lng: 21.810049 },
    { lat: 48.901985, lng: 21.810706 },
    { lat: 48.901926, lng: 21.810763 },
    { lat: 48.901596, lng: 21.81107 },
    { lat: 48.9013365, lng: 21.8120189 },
    { lat: 48.9026039, lng: 21.8130358 },
    { lat: 48.9023686, lng: 21.8141158 },
    { lat: 48.9028225, lng: 21.8156993 },
    { lat: 48.9052763, lng: 21.8165264 },
    { lat: 48.9058847, lng: 21.8145762 },
    { lat: 48.9074603, lng: 21.8142095 },
  ],
  Porúbka: [
    { lat: 48.8741671, lng: 21.9605821 },
    { lat: 48.8741766, lng: 21.9604707 },
    { lat: 48.8741084, lng: 21.9598478 },
    { lat: 48.8738241, lng: 21.9595878 },
    { lat: 48.8736452, lng: 21.9585888 },
    { lat: 48.8731592, lng: 21.9570845 },
    { lat: 48.8729686, lng: 21.9570225 },
    { lat: 48.8728666, lng: 21.956761 },
    { lat: 48.8730831, lng: 21.9565546 },
    { lat: 48.8729769, lng: 21.9562162 },
    { lat: 48.8726987, lng: 21.9548427 },
    { lat: 48.8725364, lng: 21.9546976 },
    { lat: 48.8720868, lng: 21.954665 },
    { lat: 48.8718391, lng: 21.9544757 },
    { lat: 48.8716374, lng: 21.9537265 },
    { lat: 48.8709535, lng: 21.9477031 },
    { lat: 48.8712024, lng: 21.94645 },
    { lat: 48.8705794, lng: 21.9454158 },
    { lat: 48.869661, lng: 21.944727 },
    { lat: 48.868903, lng: 21.945857 },
    { lat: 48.868738, lng: 21.945784 },
    { lat: 48.868833, lng: 21.945308 },
    { lat: 48.868602, lng: 21.945003 },
    { lat: 48.868404, lng: 21.945071 },
    { lat: 48.867998, lng: 21.945284 },
    { lat: 48.86753, lng: 21.945368 },
    { lat: 48.867225, lng: 21.945256 },
    { lat: 48.866964, lng: 21.945185 },
    { lat: 48.866674, lng: 21.94524 },
    { lat: 48.866295, lng: 21.944863 },
    { lat: 48.865559, lng: 21.944489 },
    { lat: 48.865667, lng: 21.943959 },
    { lat: 48.865158, lng: 21.94378 },
    { lat: 48.864483, lng: 21.944885 },
    { lat: 48.863259, lng: 21.944736 },
    { lat: 48.862595, lng: 21.944295 },
    { lat: 48.862568, lng: 21.944277 },
    { lat: 48.861582, lng: 21.943622 },
    { lat: 48.861454, lng: 21.943631 },
    { lat: 48.861097, lng: 21.943949 },
    { lat: 48.860871, lng: 21.944323 },
    { lat: 48.860691, lng: 21.944461 },
    { lat: 48.86062, lng: 21.944515 },
    { lat: 48.860486, lng: 21.944617 },
    { lat: 48.860033, lng: 21.944851 },
    { lat: 48.859801, lng: 21.944743 },
    { lat: 48.859632, lng: 21.944828 },
    { lat: 48.859569, lng: 21.944959 },
    { lat: 48.859215, lng: 21.945033 },
    { lat: 48.859147, lng: 21.94525 },
    { lat: 48.858694, lng: 21.945477 },
    { lat: 48.858476, lng: 21.94567 },
    { lat: 48.858456, lng: 21.945842 },
    { lat: 48.858109, lng: 21.946082 },
    { lat: 48.857773, lng: 21.946481 },
    { lat: 48.857538, lng: 21.947067 },
    { lat: 48.856419, lng: 21.947113 },
    { lat: 48.855817, lng: 21.947341 },
    { lat: 48.85463, lng: 21.947702 },
    { lat: 48.853894, lng: 21.947415 },
    { lat: 48.853315, lng: 21.94719 },
    { lat: 48.852575, lng: 21.946902 },
    { lat: 48.851809, lng: 21.946618 },
    { lat: 48.851731, lng: 21.947444 },
    { lat: 48.851613, lng: 21.948711 },
    { lat: 48.851584, lng: 21.949019 },
    { lat: 48.851459, lng: 21.949602 },
    { lat: 48.851406, lng: 21.949875 },
    { lat: 48.851319, lng: 21.950025 },
    { lat: 48.851255, lng: 21.950376 },
    { lat: 48.851081, lng: 21.950486 },
    { lat: 48.851, lng: 21.950668 },
    { lat: 48.850898, lng: 21.950815 },
    { lat: 48.850697, lng: 21.951081 },
    { lat: 48.850477, lng: 21.951315 },
    { lat: 48.850293, lng: 21.951722 },
    { lat: 48.850201, lng: 21.951862 },
    { lat: 48.850179, lng: 21.951894 },
    { lat: 48.850122, lng: 21.951891 },
    { lat: 48.850056, lng: 21.951936 },
    { lat: 48.849844, lng: 21.952355 },
    { lat: 48.849706, lng: 21.952542 },
    { lat: 48.849601, lng: 21.952709 },
    { lat: 48.849395, lng: 21.952789 },
    { lat: 48.849145, lng: 21.952694 },
    { lat: 48.849017, lng: 21.952703 },
    { lat: 48.848924, lng: 21.952891 },
    { lat: 48.849063, lng: 21.953177 },
    { lat: 48.8492, lng: 21.953574 },
    { lat: 48.849333, lng: 21.953639 },
    { lat: 48.849342, lng: 21.953643 },
    { lat: 48.849341, lng: 21.954005 },
    { lat: 48.84959, lng: 21.954327 },
    { lat: 48.849595, lng: 21.954499 },
    { lat: 48.849636, lng: 21.954656 },
    { lat: 48.849636, lng: 21.955084 },
    { lat: 48.849437, lng: 21.95533 },
    { lat: 48.849381, lng: 21.95548 },
    { lat: 48.849418, lng: 21.955975 },
    { lat: 48.849143, lng: 21.956226 },
    { lat: 48.849132, lng: 21.956305 },
    { lat: 48.849041, lng: 21.956377 },
    { lat: 48.848985, lng: 21.956526 },
    { lat: 48.848986, lng: 21.956939 },
    { lat: 48.848906, lng: 21.957032 },
    { lat: 48.848866, lng: 21.956969 },
    { lat: 48.848685, lng: 21.957074 },
    { lat: 48.848648, lng: 21.957359 },
    { lat: 48.848577, lng: 21.95751 },
    { lat: 48.848481, lng: 21.957776 },
    { lat: 48.848297, lng: 21.957862 },
    { lat: 48.848252, lng: 21.957986 },
    { lat: 48.848253, lng: 21.958352 },
    { lat: 48.848164, lng: 21.95855 },
    { lat: 48.847985, lng: 21.958744 },
    { lat: 48.847908, lng: 21.959014 },
    { lat: 48.847905, lng: 21.959582 },
    { lat: 48.847874, lng: 21.95967 },
    { lat: 48.847851, lng: 21.959874 },
    { lat: 48.847758, lng: 21.960048 },
    { lat: 48.84758, lng: 21.960198 },
    { lat: 48.84761, lng: 21.960529 },
    { lat: 48.847716, lng: 21.960739 },
    { lat: 48.847718, lng: 21.960844 },
    { lat: 48.847624, lng: 21.961152 },
    { lat: 48.847598, lng: 21.961234 },
    { lat: 48.847477, lng: 21.961384 },
    { lat: 48.847514, lng: 21.961443 },
    { lat: 48.847485, lng: 21.961594 },
    { lat: 48.847467, lng: 21.961919 },
    { lat: 48.847522, lng: 21.961927 },
    { lat: 48.84756, lng: 21.962016 },
    { lat: 48.847524, lng: 21.962122 },
    { lat: 48.847523, lng: 21.962272 },
    { lat: 48.847594, lng: 21.962366 },
    { lat: 48.847691, lng: 21.962577 },
    { lat: 48.847701, lng: 21.962682 },
    { lat: 48.847655, lng: 21.962787 },
    { lat: 48.847649, lng: 21.963086 },
    { lat: 48.847563, lng: 21.963196 },
    { lat: 48.847536, lng: 21.963402 },
    { lat: 48.847508, lng: 21.9634 },
    { lat: 48.847495, lng: 21.96349 },
    { lat: 48.847466, lng: 21.963681 },
    { lat: 48.847399, lng: 21.963743 },
    { lat: 48.847367, lng: 21.963904 },
    { lat: 48.84739, lng: 21.964051 },
    { lat: 48.847449, lng: 21.964088 },
    { lat: 48.847434, lng: 21.964233 },
    { lat: 48.847463, lng: 21.964338 },
    { lat: 48.847491, lng: 21.964555 },
    { lat: 48.847459, lng: 21.964656 },
    { lat: 48.847483, lng: 21.964851 },
    { lat: 48.847455, lng: 21.965027 },
    { lat: 48.847511, lng: 21.965206 },
    { lat: 48.847623, lng: 21.965319 },
    { lat: 48.847672, lng: 21.965301 },
    { lat: 48.847665, lng: 21.965533 },
    { lat: 48.847624, lng: 21.965651 },
    { lat: 48.847677, lng: 21.965998 },
    { lat: 48.847648, lng: 21.966265 },
    { lat: 48.847645, lng: 21.966298 },
    { lat: 48.847643, lng: 21.966311 },
    { lat: 48.847528, lng: 21.966355 },
    { lat: 48.847496, lng: 21.966487 },
    { lat: 48.847402, lng: 21.966601 },
    { lat: 48.84729, lng: 21.966894 },
    { lat: 48.84726, lng: 21.967084 },
    { lat: 48.847272, lng: 21.967175 },
    { lat: 48.8473, lng: 21.967404 },
    { lat: 48.847231, lng: 21.967573 },
    { lat: 48.84721, lng: 21.967901 },
    { lat: 48.847179, lng: 21.968101 },
    { lat: 48.847159, lng: 21.968234 },
    { lat: 48.847135, lng: 21.968271 },
    { lat: 48.847076, lng: 21.968462 },
    { lat: 48.846934, lng: 21.968812 },
    { lat: 48.84668, lng: 21.969314 },
    { lat: 48.846662, lng: 21.969311 },
    { lat: 48.846571, lng: 21.969552 },
    { lat: 48.846563, lng: 21.969615 },
    { lat: 48.846504, lng: 21.969937 },
    { lat: 48.846421, lng: 21.970516 },
    { lat: 48.846313, lng: 21.971292 },
    { lat: 48.846287, lng: 21.972181 },
    { lat: 48.846319, lng: 21.972207 },
    { lat: 48.847348, lng: 21.972573 },
    { lat: 48.847474, lng: 21.972583 },
    { lat: 48.84766, lng: 21.972599 },
    { lat: 48.848147, lng: 21.972465 },
    { lat: 48.848299, lng: 21.972401 },
    { lat: 48.848492, lng: 21.972245 },
    { lat: 48.848975, lng: 21.972112 },
    { lat: 48.848967, lng: 21.97221 },
    { lat: 48.8499476, lng: 21.9713066 },
    { lat: 48.8505758, lng: 21.9705539 },
    { lat: 48.8513703, lng: 21.9699281 },
    { lat: 48.8514321, lng: 21.9685523 },
    { lat: 48.8518666, lng: 21.9681551 },
    { lat: 48.8526377, lng: 21.9684547 },
    { lat: 48.8568334, lng: 21.9703625 },
    { lat: 48.8569864, lng: 21.9701805 },
    { lat: 48.857096, lng: 21.9699328 },
    { lat: 48.8572764, lng: 21.9698087 },
    { lat: 48.8573758, lng: 21.9695483 },
    { lat: 48.8573743, lng: 21.9692687 },
    { lat: 48.8573221, lng: 21.9691088 },
    { lat: 48.8573446, lng: 21.9683302 },
    { lat: 48.8574166, lng: 21.9681529 },
    { lat: 48.8574009, lng: 21.967879 },
    { lat: 48.8576012, lng: 21.9677916 },
    { lat: 48.8576015, lng: 21.9676712 },
    { lat: 48.8600117, lng: 21.9686067 },
    { lat: 48.8613457, lng: 21.9689241 },
    { lat: 48.8621774, lng: 21.9692531 },
    { lat: 48.8628431, lng: 21.9693865 },
    { lat: 48.8645653, lng: 21.9701622 },
    { lat: 48.8652265, lng: 21.9685959 },
    { lat: 48.8653522, lng: 21.967453 },
    { lat: 48.8665894, lng: 21.9671728 },
    { lat: 48.8677579, lng: 21.9666592 },
    { lat: 48.8687171, lng: 21.9663268 },
    { lat: 48.8691008, lng: 21.9659241 },
    { lat: 48.8692651, lng: 21.965645 },
    { lat: 48.8689031, lng: 21.9642957 },
    { lat: 48.8690863, lng: 21.9641888 },
    { lat: 48.8692555, lng: 21.964171 },
    { lat: 48.8693173, lng: 21.9640267 },
    { lat: 48.8706228, lng: 21.9630028 },
    { lat: 48.8726008, lng: 21.9615666 },
    { lat: 48.8742276, lng: 21.9608938 },
    { lat: 48.8741671, lng: 21.9605821 },
  ],
  Ľubiša: [
    { lat: 48.9957807, lng: 21.9102477 },
    { lat: 48.9950015, lng: 21.9099422 },
    { lat: 48.9937509, lng: 21.9092778 },
    { lat: 48.9935888, lng: 21.9093184 },
    { lat: 48.9930891, lng: 21.9092274 },
    { lat: 48.9924281, lng: 21.9093284 },
    { lat: 48.9915584, lng: 21.9101285 },
    { lat: 48.990768, lng: 21.9105855 },
    { lat: 48.9903946, lng: 21.9109062 },
    { lat: 48.9900433, lng: 21.9110229 },
    { lat: 48.9895383, lng: 21.9110776 },
    { lat: 48.988531, lng: 21.9109214 },
    { lat: 48.9880028, lng: 21.9109431 },
    { lat: 48.9875302, lng: 21.9112903 },
    { lat: 48.987184, lng: 21.9116592 },
    { lat: 48.9867899, lng: 21.9119138 },
    { lat: 48.9862993, lng: 21.9121266 },
    { lat: 48.9868687, lng: 21.9154603 },
    { lat: 48.9874089, lng: 21.9169086 },
    { lat: 48.988283, lng: 21.9183512 },
    { lat: 48.9888338, lng: 21.9190529 },
    { lat: 48.9888835, lng: 21.919194 },
    { lat: 48.9886292, lng: 21.9195547 },
    { lat: 48.9885229, lng: 21.9195705 },
    { lat: 48.9883066, lng: 21.9199161 },
    { lat: 48.9881306, lng: 21.9200323 },
    { lat: 48.9876768, lng: 21.9205943 },
    { lat: 48.9873001, lng: 21.9209599 },
    { lat: 48.9871539, lng: 21.9211932 },
    { lat: 48.987156, lng: 21.9215896 },
    { lat: 48.9869688, lng: 21.9217934 },
    { lat: 48.9869724, lng: 21.9222968 },
    { lat: 48.9868822, lng: 21.9225652 },
    { lat: 48.9867226, lng: 21.9226597 },
    { lat: 48.9866232, lng: 21.923049 },
    { lat: 48.9864657, lng: 21.9230943 },
    { lat: 48.9863441, lng: 21.9236231 },
    { lat: 48.986389, lng: 21.9237573 },
    { lat: 48.9860142, lng: 21.9252756 },
    { lat: 48.9861147, lng: 21.9256126 },
    { lat: 48.9860581, lng: 21.9261387 },
    { lat: 48.9858495, lng: 21.9264301 },
    { lat: 48.9859737, lng: 21.9267152 },
    { lat: 48.9859427, lng: 21.9268692 },
    { lat: 48.9860758, lng: 21.9270284 },
    { lat: 48.9861625, lng: 21.927383 },
    { lat: 48.9862603, lng: 21.9273852 },
    { lat: 48.9864225, lng: 21.9275267 },
    { lat: 48.986506, lng: 21.9274911 },
    { lat: 48.9865284, lng: 21.9275378 },
    { lat: 48.9864766, lng: 21.9277585 },
    { lat: 48.9865193, lng: 21.9278048 },
    { lat: 48.9865711, lng: 21.9276725 },
    { lat: 48.9867388, lng: 21.9276743 },
    { lat: 48.9868877, lng: 21.9282287 },
    { lat: 48.9870775, lng: 21.9282604 },
    { lat: 48.9873329, lng: 21.9285415 },
    { lat: 48.9874625, lng: 21.9288134 },
    { lat: 48.9877694, lng: 21.9287657 },
    { lat: 48.9878691, lng: 21.9290189 },
    { lat: 48.9880285, lng: 21.9290512 },
    { lat: 48.9879566, lng: 21.9295991 },
    { lat: 48.9875833, lng: 21.9302359 },
    { lat: 48.98747, lng: 21.9306539 },
    { lat: 48.9870597, lng: 21.9316101 },
    { lat: 48.9866519, lng: 21.9323957 },
    { lat: 48.9864179, lng: 21.93271 },
    { lat: 48.9862003, lng: 21.9334582 },
    { lat: 48.9859233, lng: 21.9339497 },
    { lat: 48.9860926, lng: 21.9348314 },
    { lat: 48.9860905, lng: 21.9351055 },
    { lat: 48.986156, lng: 21.9352482 },
    { lat: 48.9861354, lng: 21.9356881 },
    { lat: 48.9861915, lng: 21.93586 },
    { lat: 48.9861181, lng: 21.936529 },
    { lat: 48.9863752, lng: 21.9373211 },
    { lat: 48.9873002, lng: 21.9374285 },
    { lat: 48.9875283, lng: 21.9391434 },
    { lat: 48.9881404, lng: 21.9394622 },
    { lat: 48.9886346, lng: 21.9416043 },
    { lat: 48.9891053, lng: 21.9441174 },
    { lat: 48.9896883, lng: 21.9440478 },
    { lat: 48.989725, lng: 21.9441585 },
    { lat: 48.9905045, lng: 21.9444309 },
    { lat: 48.9907982, lng: 21.9446232 },
    { lat: 48.9911187, lng: 21.9450193 },
    { lat: 48.9916047, lng: 21.9451584 },
    { lat: 48.9920391, lng: 21.9455787 },
    { lat: 48.9925218, lng: 21.9461736 },
    { lat: 48.9929808, lng: 21.9462648 },
    { lat: 48.9936191, lng: 21.9447443 },
    { lat: 48.994494, lng: 21.9452805 },
    { lat: 48.9946781, lng: 21.9466229 },
    { lat: 48.9946833, lng: 21.9470138 },
    { lat: 48.9952008, lng: 21.9471611 },
    { lat: 48.9951481, lng: 21.9473432 },
    { lat: 48.9960223, lng: 21.9473996 },
    { lat: 48.9960785, lng: 21.9475275 },
    { lat: 48.9960835, lng: 21.9476927 },
    { lat: 48.9966058, lng: 21.9475181 },
    { lat: 48.9964282, lng: 21.9482107 },
    { lat: 48.9965383, lng: 21.9482946 },
    { lat: 48.9971033, lng: 21.9484471 },
    { lat: 48.9978256, lng: 21.9482213 },
    { lat: 48.9979237, lng: 21.9485012 },
    { lat: 48.999015, lng: 21.9493734 },
    { lat: 48.9990259, lng: 21.9494523 },
    { lat: 48.9999294, lng: 21.9500541 },
    { lat: 49.0024772, lng: 21.9513296 },
    { lat: 49.0036811, lng: 21.9545196 },
    { lat: 49.0037219, lng: 21.9553964 },
    { lat: 49.0038406, lng: 21.9554237 },
    { lat: 49.0040792, lng: 21.9562069 },
    { lat: 49.0046995, lng: 21.9569582 },
    { lat: 49.0054289, lng: 21.9567027 },
    { lat: 49.0062154, lng: 21.9562548 },
    { lat: 49.0071509, lng: 21.9560151 },
    { lat: 49.0072278, lng: 21.9563013 },
    { lat: 49.0076003, lng: 21.9561736 },
    { lat: 49.0082779, lng: 21.9561873 },
    { lat: 49.0085567, lng: 21.9565483 },
    { lat: 49.0089937, lng: 21.9563482 },
    { lat: 49.0096406, lng: 21.9561633 },
    { lat: 49.0099001, lng: 21.9561986 },
    { lat: 49.0100148, lng: 21.9563791 },
    { lat: 49.0101406, lng: 21.9567381 },
    { lat: 49.0100684, lng: 21.95705 },
    { lat: 49.0100329, lng: 21.9577998 },
    { lat: 49.0100631, lng: 21.9583334 },
    { lat: 49.0099125, lng: 21.9601225 },
    { lat: 49.0097369, lng: 21.9612973 },
    { lat: 49.0097214, lng: 21.9626781 },
    { lat: 49.0096585, lng: 21.9635975 },
    { lat: 49.009701, lng: 21.9640042 },
    { lat: 49.0098337, lng: 21.9643672 },
    { lat: 49.0099626, lng: 21.964989 },
    { lat: 49.0099732, lng: 21.96537 },
    { lat: 49.0105496, lng: 21.9682195 },
    { lat: 49.0105621, lng: 21.968547 },
    { lat: 49.0108177, lng: 21.9695196 },
    { lat: 49.0112191, lng: 21.969375 },
    { lat: 49.0112434, lng: 21.9694972 },
    { lat: 49.0115887, lng: 21.9694522 },
    { lat: 49.0117781, lng: 21.9696976 },
    { lat: 49.0126411, lng: 21.969975 },
    { lat: 49.0146391, lng: 21.9704517 },
    { lat: 49.0148443, lng: 21.9705641 },
    { lat: 49.015466, lng: 21.9705023 },
    { lat: 49.0159656, lng: 21.9702768 },
    { lat: 49.0162727, lng: 21.9702379 },
    { lat: 49.016865, lng: 21.9698534 },
    { lat: 49.0170667, lng: 21.9696402 },
    { lat: 49.0178488, lng: 21.9692381 },
    { lat: 49.017937, lng: 21.9689328 },
    { lat: 49.0187538, lng: 21.9678894 },
    { lat: 49.0189022, lng: 21.9675727 },
    { lat: 49.0190639, lng: 21.9673328 },
    { lat: 49.0188894, lng: 21.9669553 },
    { lat: 49.0185585, lng: 21.9672235 },
    { lat: 49.0182019, lng: 21.9667636 },
    { lat: 49.0179577, lng: 21.9668513 },
    { lat: 49.0177509, lng: 21.9665803 },
    { lat: 49.017753, lng: 21.9662534 },
    { lat: 49.01758, lng: 21.9658507 },
    { lat: 49.0173083, lng: 21.9654605 },
    { lat: 49.0172367, lng: 21.9655667 },
    { lat: 49.0169258, lng: 21.9652819 },
    { lat: 49.0167563, lng: 21.96502 },
    { lat: 49.0166715, lng: 21.9645442 },
    { lat: 49.0165544, lng: 21.9643076 },
    { lat: 49.016402, lng: 21.9635056 },
    { lat: 49.0163318, lng: 21.963405 },
    { lat: 49.0161829, lng: 21.9626975 },
    { lat: 49.016138, lng: 21.9622453 },
    { lat: 49.0160765, lng: 21.9622162 },
    { lat: 49.0159916, lng: 21.962015 },
    { lat: 49.0159925, lng: 21.9617254 },
    { lat: 49.0160789, lng: 21.9615857 },
    { lat: 49.0161241, lng: 21.9611355 },
    { lat: 49.0160704, lng: 21.9606878 },
    { lat: 49.0161008, lng: 21.9602747 },
    { lat: 49.0160359, lng: 21.9597964 },
    { lat: 49.0160546, lng: 21.9590428 },
    { lat: 49.0161408, lng: 21.9588483 },
    { lat: 49.0158779, lng: 21.9581771 },
    { lat: 49.0158715, lng: 21.9580036 },
    { lat: 49.0157708, lng: 21.9579359 },
    { lat: 49.0158743, lng: 21.9578476 },
    { lat: 49.0157969, lng: 21.9573575 },
    { lat: 49.0158452, lng: 21.9571666 },
    { lat: 49.0157842, lng: 21.9570647 },
    { lat: 49.0157679, lng: 21.9569101 },
    { lat: 49.0155674, lng: 21.9566281 },
    { lat: 49.0152704, lng: 21.9567131 },
    { lat: 49.0148928, lng: 21.9569518 },
    { lat: 49.0147967, lng: 21.9550638 },
    { lat: 49.0147277, lng: 21.9545997 },
    { lat: 49.0148397, lng: 21.9544382 },
    { lat: 49.0149992, lng: 21.9540231 },
    { lat: 49.0151644, lng: 21.9534238 },
    { lat: 49.0150381, lng: 21.9526795 },
    { lat: 49.0163888, lng: 21.947947 },
    { lat: 49.0172562, lng: 21.9461317 },
    { lat: 49.0173509, lng: 21.946052 },
    { lat: 49.0177003, lng: 21.943812 },
    { lat: 49.0177984, lng: 21.9421406 },
    { lat: 49.017877, lng: 21.9403196 },
    { lat: 49.0177702, lng: 21.9386475 },
    { lat: 49.0180736, lng: 21.9382699 },
    { lat: 49.0181867, lng: 21.938249 },
    { lat: 49.0184821, lng: 21.9377411 },
    { lat: 49.0188004, lng: 21.937468 },
    { lat: 49.0189246, lng: 21.9372591 },
    { lat: 49.0196046, lng: 21.9368341 },
    { lat: 49.0198421, lng: 21.936838 },
    { lat: 49.0203744, lng: 21.9367085 },
    { lat: 49.0204606, lng: 21.9365161 },
    { lat: 49.0209643, lng: 21.936099 },
    { lat: 49.0210213, lng: 21.9361226 },
    { lat: 49.0209819, lng: 21.9351271 },
    { lat: 49.0212629, lng: 21.9322323 },
    { lat: 49.0216046, lng: 21.9313202 },
    { lat: 49.0215619, lng: 21.9312707 },
    { lat: 49.0214962, lng: 21.9305821 },
    { lat: 49.0213168, lng: 21.9303247 },
    { lat: 49.0212545, lng: 21.9299914 },
    { lat: 49.0209672, lng: 21.9297918 },
    { lat: 49.0207926, lng: 21.9294729 },
    { lat: 49.0207431, lng: 21.929022 },
    { lat: 49.0205303, lng: 21.9284268 },
    { lat: 49.0203696, lng: 21.9282977 },
    { lat: 49.020138, lng: 21.9279423 },
    { lat: 49.0196379, lng: 21.9275497 },
    { lat: 49.0195804, lng: 21.9271419 },
    { lat: 49.0194366, lng: 21.9268764 },
    { lat: 49.0193427, lng: 21.9265435 },
    { lat: 49.0193684, lng: 21.9263524 },
    { lat: 49.0190901, lng: 21.9255621 },
    { lat: 49.0188675, lng: 21.9245396 },
    { lat: 49.0186828, lng: 21.924165 },
    { lat: 49.0185142, lng: 21.9239482 },
    { lat: 49.0183671, lng: 21.9235948 },
    { lat: 49.0182964, lng: 21.9231538 },
    { lat: 49.0183534, lng: 21.922738 },
    { lat: 49.0172184, lng: 21.9228186 },
    { lat: 49.016526, lng: 21.922665 },
    { lat: 49.0158666, lng: 21.9223397 },
    { lat: 49.0156758, lng: 21.9223085 },
    { lat: 49.0151092, lng: 21.9217796 },
    { lat: 49.0143801, lng: 21.9214118 },
    { lat: 49.0137986, lng: 21.9208753 },
    { lat: 49.0125879, lng: 21.9203697 },
    { lat: 49.0120191, lng: 21.9199762 },
    { lat: 49.0086234, lng: 21.9181942 },
    { lat: 49.0084813, lng: 21.9186281 },
    { lat: 49.0079475, lng: 21.919856 },
    { lat: 49.007321, lng: 21.9211685 },
    { lat: 49.0063342, lng: 21.9197124 },
    { lat: 49.0062643, lng: 21.919351 },
    { lat: 49.0060307, lng: 21.9191416 },
    { lat: 49.0052998, lng: 21.9179371 },
    { lat: 49.0050169, lng: 21.9164791 },
    { lat: 49.0046633, lng: 21.9151603 },
    { lat: 49.0036453, lng: 21.9132236 },
    { lat: 49.0025527, lng: 21.9119617 },
    { lat: 49.0015829, lng: 21.9103817 },
    { lat: 49.0010251, lng: 21.9096241 },
    { lat: 49.0003906, lng: 21.9094853 },
    { lat: 49.0000252, lng: 21.9094965 },
    { lat: 48.9989074, lng: 21.9099145 },
    { lat: 48.9978159, lng: 21.9109698 },
    { lat: 48.9964365, lng: 21.9108695 },
    { lat: 48.9957544, lng: 21.9103187 },
    { lat: 48.9957807, lng: 21.9102477 },
  ],
  HažínnadCirochou: [
    { lat: 48.9354976, lng: 21.9400476 },
    { lat: 48.9352793, lng: 21.9397713 },
    { lat: 48.9328121, lng: 21.9366832 },
    { lat: 48.9312329, lng: 21.9372775 },
    { lat: 48.9306276, lng: 21.9375877 },
    { lat: 48.9303648, lng: 21.9368322 },
    { lat: 48.9300386, lng: 21.9369282 },
    { lat: 48.9295247, lng: 21.9369358 },
    { lat: 48.9267944, lng: 21.9384124 },
    { lat: 48.9261667, lng: 21.9386441 },
    { lat: 48.9255746, lng: 21.9387567 },
    { lat: 48.925295, lng: 21.938877 },
    { lat: 48.9249813, lng: 21.9390958 },
    { lat: 48.9249432, lng: 21.9389703 },
    { lat: 48.9246104, lng: 21.9392444 },
    { lat: 48.9242471, lng: 21.9397446 },
    { lat: 48.9236946, lng: 21.9403321 },
    { lat: 48.9232944, lng: 21.940296 },
    { lat: 48.9228556, lng: 21.9406992 },
    { lat: 48.9227647, lng: 21.9410628 },
    { lat: 48.9223697, lng: 21.9416889 },
    { lat: 48.922, lng: 21.942067 },
    { lat: 48.9218919, lng: 21.9423347 },
    { lat: 48.9218317, lng: 21.9428535 },
    { lat: 48.9215515, lng: 21.9437087 },
    { lat: 48.9215329, lng: 21.943958 },
    { lat: 48.9213904, lng: 21.9441489 },
    { lat: 48.920744, lng: 21.9444424 },
    { lat: 48.9206959, lng: 21.9447259 },
    { lat: 48.9204296, lng: 21.9450992 },
    { lat: 48.9201911, lng: 21.9460038 },
    { lat: 48.9200215, lng: 21.9462427 },
    { lat: 48.919726, lng: 21.9472053 },
    { lat: 48.9195999, lng: 21.9474722 },
    { lat: 48.9193126, lng: 21.9477314 },
    { lat: 48.9181984, lng: 21.9481127 },
    { lat: 48.9173755, lng: 21.9480463 },
    { lat: 48.9169272, lng: 21.9481531 },
    { lat: 48.916627, lng: 21.9480226 },
    { lat: 48.9164907, lng: 21.9481995 },
    { lat: 48.9161679, lng: 21.9489275 },
    { lat: 48.91621, lng: 21.9498943 },
    { lat: 48.915846, lng: 21.9508715 },
    { lat: 48.9155388, lng: 21.9514598 },
    { lat: 48.9145985, lng: 21.9523592 },
    { lat: 48.9143121, lng: 21.9525622 },
    { lat: 48.9144849, lng: 21.9553201 },
    { lat: 48.9148562, lng: 21.95555 },
    { lat: 48.9150463, lng: 21.9558246 },
    { lat: 48.915517, lng: 21.9558868 },
    { lat: 48.916372, lng: 21.9561178 },
    { lat: 48.9167247, lng: 21.9568269 },
    { lat: 48.9171817, lng: 21.957567 },
    { lat: 48.9168861, lng: 21.9588084 },
    { lat: 48.9168679, lng: 21.9592518 },
    { lat: 48.9167922, lng: 21.9596903 },
    { lat: 48.9169134, lng: 21.9602281 },
    { lat: 48.9170256, lng: 21.961265 },
    { lat: 48.9169436, lng: 21.9625465 },
    { lat: 48.9171953, lng: 21.9629359 },
    { lat: 48.9172432, lng: 21.9633281 },
    { lat: 48.9171496, lng: 21.9637783 },
    { lat: 48.9171111, lng: 21.9646818 },
    { lat: 48.9168585, lng: 21.9654293 },
    { lat: 48.9163319, lng: 21.9658318 },
    { lat: 48.9161298, lng: 21.9663923 },
    { lat: 48.9163298, lng: 21.9676226 },
    { lat: 48.9163952, lng: 21.9683532 },
    { lat: 48.9162865, lng: 21.9701969 },
    { lat: 48.9160038, lng: 21.9710935 },
    { lat: 48.9159338, lng: 21.9718596 },
    { lat: 48.9163244, lng: 21.9725595 },
    { lat: 48.9166031, lng: 21.9732347 },
    { lat: 48.916429, lng: 21.9738919 },
    { lat: 48.9164057, lng: 21.97445 },
    { lat: 48.916438, lng: 21.9746696 },
    { lat: 48.9168418, lng: 21.9749854 },
    { lat: 48.9172536, lng: 21.9758577 },
    { lat: 48.918147, lng: 21.9767331 },
    { lat: 48.9183721, lng: 21.9770587 },
    { lat: 48.9183067, lng: 21.9776014 },
    { lat: 48.9185241, lng: 21.9789968 },
    { lat: 48.9184125, lng: 21.9798984 },
    { lat: 48.9184838, lng: 21.9802363 },
    { lat: 48.9186548, lng: 21.9805195 },
    { lat: 48.9190939, lng: 21.9810394 },
    { lat: 48.9191433, lng: 21.9813117 },
    { lat: 48.9194138, lng: 21.9816243 },
    { lat: 48.9194965, lng: 21.9817199 },
    { lat: 48.9197347, lng: 21.9824868 },
    { lat: 48.9197535, lng: 21.9833485 },
    { lat: 48.9198463, lng: 21.984104 },
    { lat: 48.9199697, lng: 21.9843696 },
    { lat: 48.9200423, lng: 21.9846681 },
    { lat: 48.9205268, lng: 21.9852044 },
    { lat: 48.9209082, lng: 21.9860778 },
    { lat: 48.9211684, lng: 21.9864641 },
    { lat: 48.9220373, lng: 21.9871802 },
    { lat: 48.9223895, lng: 21.9872748 },
    { lat: 48.9225609, lng: 21.9871993 },
    { lat: 48.9231311, lng: 21.9872881 },
    { lat: 48.9233516, lng: 21.987516 },
    { lat: 48.9236688, lng: 21.9885251 },
    { lat: 48.9259737, lng: 21.9894065 },
    { lat: 48.9269315, lng: 21.9896616 },
    { lat: 48.9269753, lng: 21.9901124 },
    { lat: 48.9270891, lng: 21.9900337 },
    { lat: 48.9271254, lng: 21.9898607 },
    { lat: 48.9273665, lng: 21.9896922 },
    { lat: 48.9276221, lng: 21.9890629 },
    { lat: 48.9282605, lng: 21.9885752 },
    { lat: 48.928583, lng: 21.9886024 },
    { lat: 48.9287783, lng: 21.9878599 },
    { lat: 48.9287831, lng: 21.9876882 },
    { lat: 48.929333, lng: 21.9875442 },
    { lat: 48.9293465, lng: 21.9872176 },
    { lat: 48.9296226, lng: 21.9871061 },
    { lat: 48.9297163, lng: 21.9864272 },
    { lat: 48.929885, lng: 21.9863963 },
    { lat: 48.9302192, lng: 21.9863352 },
    { lat: 48.9303899, lng: 21.986596 },
    { lat: 48.9313662, lng: 21.9863256 },
    { lat: 48.9315111, lng: 21.9861797 },
    { lat: 48.9316821, lng: 21.9862234 },
    { lat: 48.9317539, lng: 21.9861718 },
    { lat: 48.931967, lng: 21.9866041 },
    { lat: 48.9321829, lng: 21.9869221 },
    { lat: 48.932276, lng: 21.9869616 },
    { lat: 48.9327362, lng: 21.9866004 },
    { lat: 48.9333628, lng: 21.9859573 },
    { lat: 48.9333146, lng: 21.9856845 },
    { lat: 48.9337551, lng: 21.984293 },
    { lat: 48.9340181, lng: 21.9838224 },
    { lat: 48.9339979, lng: 21.9835083 },
    { lat: 48.9341462, lng: 21.9832191 },
    { lat: 48.934715, lng: 21.9827004 },
    { lat: 48.934837, lng: 21.9823494 },
    { lat: 48.9347654, lng: 21.9819591 },
    { lat: 48.9348366, lng: 21.9818814 },
    { lat: 48.9348527, lng: 21.9817496 },
    { lat: 48.9351919, lng: 21.9815955 },
    { lat: 48.9353318, lng: 21.9812957 },
    { lat: 48.9355284, lng: 21.9810556 },
    { lat: 48.9357659, lng: 21.9810135 },
    { lat: 48.9360018, lng: 21.9807712 },
    { lat: 48.9360558, lng: 21.9805889 },
    { lat: 48.9363718, lng: 21.9805385 },
    { lat: 48.9364816, lng: 21.9806103 },
    { lat: 48.9366406, lng: 21.9805781 },
    { lat: 48.9368221, lng: 21.9801367 },
    { lat: 48.9367664, lng: 21.9798862 },
    { lat: 48.9367981, lng: 21.9796686 },
    { lat: 48.9369583, lng: 21.9793204 },
    { lat: 48.9370351, lng: 21.9789611 },
    { lat: 48.9371604, lng: 21.9789379 },
    { lat: 48.9372692, lng: 21.9791089 },
    { lat: 48.9372997, lng: 21.9790238 },
    { lat: 48.937377, lng: 21.9790041 },
    { lat: 48.9374312, lng: 21.9787437 },
    { lat: 48.9377588, lng: 21.9782934 },
    { lat: 48.9378686, lng: 21.9783677 },
    { lat: 48.9381486, lng: 21.9781815 },
    { lat: 48.9382565, lng: 21.9779496 },
    { lat: 48.9383491, lng: 21.9773726 },
    { lat: 48.938395, lng: 21.9773139 },
    { lat: 48.9385513, lng: 21.9775448 },
    { lat: 48.9388732, lng: 21.9776833 },
    { lat: 48.9391528, lng: 21.9775701 },
    { lat: 48.9394589, lng: 21.9778562 },
    { lat: 48.9395797, lng: 21.9777846 },
    { lat: 48.9399171, lng: 21.9772296 },
    { lat: 48.9400182, lng: 21.9769515 },
    { lat: 48.9400424, lng: 21.9766994 },
    { lat: 48.9388726, lng: 21.9745025 },
    { lat: 48.9382657, lng: 21.9732387 },
    { lat: 48.9356263, lng: 21.9681471 },
    { lat: 48.9353561, lng: 21.9677714 },
    { lat: 48.9337726, lng: 21.9641752 },
    { lat: 48.9335253, lng: 21.9637285 },
    { lat: 48.9336657, lng: 21.9622633 },
    { lat: 48.9346722, lng: 21.9572885 },
    { lat: 48.9354038, lng: 21.9559427 },
    { lat: 48.9356596, lng: 21.955594 },
    { lat: 48.9356209, lng: 21.954077 },
    { lat: 48.9359186, lng: 21.9531523 },
    { lat: 48.9362526, lng: 21.9528288 },
    { lat: 48.9364272, lng: 21.9524378 },
    { lat: 48.9364969, lng: 21.9508914 },
    { lat: 48.9366835, lng: 21.9500231 },
    { lat: 48.9365628, lng: 21.9466479 },
    { lat: 48.9356666, lng: 21.9410511 },
    { lat: 48.9354976, lng: 21.9400476 },
  ],
  Myslina: [
    { lat: 48.9437639, lng: 21.8336614 },
    { lat: 48.9436412, lng: 21.834353 },
    { lat: 48.943271, lng: 21.8364534 },
    { lat: 48.9432016, lng: 21.83751 },
    { lat: 48.9423896, lng: 21.8379962 },
    { lat: 48.9410863, lng: 21.8397675 },
    { lat: 48.9408728, lng: 21.8399906 },
    { lat: 48.940638, lng: 21.8399605 },
    { lat: 48.9405025, lng: 21.8401546 },
    { lat: 48.9403154, lng: 21.8401486 },
    { lat: 48.9400203, lng: 21.8403972 },
    { lat: 48.9399068, lng: 21.8406566 },
    { lat: 48.9397264, lng: 21.8405105 },
    { lat: 48.9395702, lng: 21.8404744 },
    { lat: 48.9395761, lng: 21.8415768 },
    { lat: 48.9391755, lng: 21.8428 },
    { lat: 48.939053, lng: 21.8433719 },
    { lat: 48.9387494, lng: 21.8434182 },
    { lat: 48.9378957, lng: 21.8453473 },
    { lat: 48.9376912, lng: 21.8456671 },
    { lat: 48.9376259, lng: 21.8460145 },
    { lat: 48.9362447, lng: 21.8455896 },
    { lat: 48.9350353, lng: 21.845888 },
    { lat: 48.9347693, lng: 21.8458264 },
    { lat: 48.9345196, lng: 21.8467879 },
    { lat: 48.9338539, lng: 21.8480739 },
    { lat: 48.933087, lng: 21.8493951 },
    { lat: 48.9303284, lng: 21.8501355 },
    { lat: 48.9306285, lng: 21.8551673 },
    { lat: 48.9300253, lng: 21.855029 },
    { lat: 48.9299869, lng: 21.8551948 },
    { lat: 48.9307895, lng: 21.8618212 },
    { lat: 48.9309889, lng: 21.8631929 },
    { lat: 48.9313, lng: 21.8648075 },
    { lat: 48.9313166, lng: 21.8648849 },
    { lat: 48.9319389, lng: 21.8677577 },
    { lat: 48.9320464, lng: 21.8676458 },
    { lat: 48.9324518, lng: 21.8705758 },
    { lat: 48.9334045, lng: 21.8701761 },
    { lat: 48.9344047, lng: 21.8698698 },
    { lat: 48.9352613, lng: 21.8698531 },
    { lat: 48.9370937, lng: 21.86905 },
    { lat: 48.9376078, lng: 21.8686165 },
    { lat: 48.9381587, lng: 21.8684693 },
    { lat: 48.938643, lng: 21.8684921 },
    { lat: 48.9402381, lng: 21.8682195 },
    { lat: 48.9407135, lng: 21.867858 },
    { lat: 48.9411396, lng: 21.8677146 },
    { lat: 48.9415802, lng: 21.8705185 },
    { lat: 48.9427544, lng: 21.8701618 },
    { lat: 48.942984, lng: 21.8718016 },
    { lat: 48.9432434, lng: 21.8718215 },
    { lat: 48.9437292, lng: 21.8720435 },
    { lat: 48.9445841, lng: 21.8720395 },
    { lat: 48.9448093, lng: 21.8718667 },
    { lat: 48.9451421, lng: 21.8719137 },
    { lat: 48.9459849, lng: 21.8717397 },
    { lat: 48.9468655, lng: 21.8719518 },
    { lat: 48.9471271, lng: 21.8723358 },
    { lat: 48.9474978, lng: 21.8746969 },
    { lat: 48.9475577, lng: 21.878027 },
    { lat: 48.9473784, lng: 21.878764 },
    { lat: 48.9475479, lng: 21.8798582 },
    { lat: 48.9477724, lng: 21.879621 },
    { lat: 48.947821, lng: 21.8794106 },
    { lat: 48.948065, lng: 21.879446 },
    { lat: 48.9482037, lng: 21.8796383 },
    { lat: 48.9483475, lng: 21.8794044 },
    { lat: 48.9486494, lng: 21.8794204 },
    { lat: 48.9487811, lng: 21.8792996 },
    { lat: 48.9489728, lng: 21.8793099 },
    { lat: 48.9502055, lng: 21.8787739 },
    { lat: 48.9505526, lng: 21.878575 },
    { lat: 48.9510965, lng: 21.8779751 },
    { lat: 48.9521037, lng: 21.8771939 },
    { lat: 48.9523283, lng: 21.8771857 },
    { lat: 48.95241, lng: 21.8769931 },
    { lat: 48.9527596, lng: 21.8767618 },
    { lat: 48.9529421, lng: 21.8768133 },
    { lat: 48.9533221, lng: 21.8764954 },
    { lat: 48.9535015, lng: 21.8767852 },
    { lat: 48.9537757, lng: 21.8768612 },
    { lat: 48.9538455, lng: 21.8766845 },
    { lat: 48.9538352, lng: 21.8764099 },
    { lat: 48.9543251, lng: 21.8765237 },
    { lat: 48.954519, lng: 21.8766315 },
    { lat: 48.9546165, lng: 21.8764787 },
    { lat: 48.9549358, lng: 21.8765584 },
    { lat: 48.9550854, lng: 21.8766971 },
    { lat: 48.9551804, lng: 21.8766296 },
    { lat: 48.955363, lng: 21.8767775 },
    { lat: 48.955622, lng: 21.8767365 },
    { lat: 48.9557375, lng: 21.8768107 },
    { lat: 48.9559164, lng: 21.8766803 },
    { lat: 48.9561518, lng: 21.8767889 },
    { lat: 48.9563002, lng: 21.8767848 },
    { lat: 48.9566294, lng: 21.8769835 },
    { lat: 48.9568244, lng: 21.8769596 },
    { lat: 48.9570261, lng: 21.877058 },
    { lat: 48.9573445, lng: 21.8771024 },
    { lat: 48.9579694, lng: 21.8773994 },
    { lat: 48.958065, lng: 21.8775223 },
    { lat: 48.9583648, lng: 21.8776329 },
    { lat: 48.9590162, lng: 21.8777311 },
    { lat: 48.9591793, lng: 21.8778125 },
    { lat: 48.9591557, lng: 21.8782194 },
    { lat: 48.9601857, lng: 21.8779844 },
    { lat: 48.9613692, lng: 21.8774981 },
    { lat: 48.9621948, lng: 21.8769728 },
    { lat: 48.9634725, lng: 21.8761598 },
    { lat: 48.9647495, lng: 21.8755079 },
    { lat: 48.964996, lng: 21.8751505 },
    { lat: 48.9643569, lng: 21.8749259 },
    { lat: 48.9640312, lng: 21.8749929 },
    { lat: 48.9639021, lng: 21.8743407 },
    { lat: 48.9640367, lng: 21.8732162 },
    { lat: 48.9639295, lng: 21.8720526 },
    { lat: 48.9639452, lng: 21.8718333 },
    { lat: 48.9641351, lng: 21.8691762 },
    { lat: 48.9641208, lng: 21.8676405 },
    { lat: 48.9640283, lng: 21.8660839 },
    { lat: 48.9639508, lng: 21.8654326 },
    { lat: 48.9637694, lng: 21.8640038 },
    { lat: 48.9639357, lng: 21.8624088 },
    { lat: 48.9638818, lng: 21.8616652 },
    { lat: 48.9639546, lng: 21.8613877 },
    { lat: 48.9638244, lng: 21.8610979 },
    { lat: 48.963773, lng: 21.860487 },
    { lat: 48.9635594, lng: 21.8597023 },
    { lat: 48.9635424, lng: 21.858731 },
    { lat: 48.9633829, lng: 21.8578139 },
    { lat: 48.9633965, lng: 21.857294 },
    { lat: 48.9629967, lng: 21.8565293 },
    { lat: 48.9628776, lng: 21.8564432 },
    { lat: 48.9627134, lng: 21.8559603 },
    { lat: 48.9621628, lng: 21.854839 },
    { lat: 48.962024, lng: 21.8548683 },
    { lat: 48.9619573, lng: 21.8541974 },
    { lat: 48.9620129, lng: 21.8537803 },
    { lat: 48.9621373, lng: 21.8535854 },
    { lat: 48.962206, lng: 21.8531449 },
    { lat: 48.9623259, lng: 21.852206 },
    { lat: 48.9623634, lng: 21.8510367 },
    { lat: 48.9625988, lng: 21.8510064 },
    { lat: 48.9626988, lng: 21.8499855 },
    { lat: 48.9629145, lng: 21.8500258 },
    { lat: 48.9633826, lng: 21.8498116 },
    { lat: 48.96347, lng: 21.8488727 },
    { lat: 48.9638781, lng: 21.84856 },
    { lat: 48.9639943, lng: 21.8486151 },
    { lat: 48.9642359, lng: 21.8485614 },
    { lat: 48.9644088, lng: 21.8488118 },
    { lat: 48.9647398, lng: 21.8486859 },
    { lat: 48.9648015, lng: 21.8485097 },
    { lat: 48.9655438, lng: 21.8480212 },
    { lat: 48.9658458, lng: 21.8473327 },
    { lat: 48.9657982, lng: 21.8470144 },
    { lat: 48.966155, lng: 21.846714 },
    { lat: 48.9663125, lng: 21.8461949 },
    { lat: 48.9663224, lng: 21.8440809 },
    { lat: 48.9657024, lng: 21.8438147 },
    { lat: 48.965602, lng: 21.8430531 },
    { lat: 48.9653858, lng: 21.8424831 },
    { lat: 48.9653372, lng: 21.8417671 },
    { lat: 48.9651321, lng: 21.8406203 },
    { lat: 48.9650753, lng: 21.8399886 },
    { lat: 48.9651039, lng: 21.8393944 },
    { lat: 48.9646769, lng: 21.8392808 },
    { lat: 48.964145, lng: 21.8394162 },
    { lat: 48.9638909, lng: 21.8391123 },
    { lat: 48.9637073, lng: 21.8391064 },
    { lat: 48.963245, lng: 21.8394159 },
    { lat: 48.9631554, lng: 21.8395598 },
    { lat: 48.9627667, lng: 21.8394976 },
    { lat: 48.9624111, lng: 21.8388786 },
    { lat: 48.9622238, lng: 21.8386785 },
    { lat: 48.9628779, lng: 21.837783 },
    { lat: 48.9629958, lng: 21.8373357 },
    { lat: 48.9630395, lng: 21.8370624 },
    { lat: 48.9629675, lng: 21.836582 },
    { lat: 48.9630298, lng: 21.8362088 },
    { lat: 48.9627174, lng: 21.8356123 },
    { lat: 48.9618178, lng: 21.8357091 },
    { lat: 48.9612721, lng: 21.8356671 },
    { lat: 48.9606626, lng: 21.8353227 },
    { lat: 48.9602368, lng: 21.8349535 },
    { lat: 48.9595486, lng: 21.8350474 },
    { lat: 48.9574706, lng: 21.835914 },
    { lat: 48.9566389, lng: 21.8352808 },
    { lat: 48.9563711, lng: 21.8352824 },
    { lat: 48.9558999, lng: 21.8354188 },
    { lat: 48.9547394, lng: 21.8353453 },
    { lat: 48.9544997, lng: 21.8354301 },
    { lat: 48.9534844, lng: 21.8346704 },
    { lat: 48.9531211, lng: 21.8344912 },
    { lat: 48.9512933, lng: 21.8338918 },
    { lat: 48.9510093, lng: 21.8337383 },
    { lat: 48.9500845, lng: 21.8335603 },
    { lat: 48.9497752, lng: 21.8336907 },
    { lat: 48.9493171, lng: 21.8337229 },
    { lat: 48.9489058, lng: 21.8339177 },
    { lat: 48.9466052, lng: 21.8340398 },
    { lat: 48.9437639, lng: 21.8336614 },
  ],
  Maškovce: [
    { lat: 49.0219453, lng: 21.9694164 },
    { lat: 49.0218997, lng: 21.9695745 },
    { lat: 49.0217184, lng: 21.9696608 },
    { lat: 49.0216151, lng: 21.9696215 },
    { lat: 49.0214367, lng: 21.9700893 },
    { lat: 49.021285, lng: 21.9701037 },
    { lat: 49.0209667, lng: 21.9704654 },
    { lat: 49.020975, lng: 21.9705811 },
    { lat: 49.0207791, lng: 21.970704 },
    { lat: 49.0206304, lng: 21.9711114 },
    { lat: 49.0203714, lng: 21.9712375 },
    { lat: 49.0200875, lng: 21.9721324 },
    { lat: 49.019865, lng: 21.9724611 },
    { lat: 49.0195869, lng: 21.9725873 },
    { lat: 49.0192993, lng: 21.9731046 },
    { lat: 49.0189126, lng: 21.9734444 },
    { lat: 49.0187704, lng: 21.9737872 },
    { lat: 49.0183682, lng: 21.9743615 },
    { lat: 49.017995, lng: 21.9743662 },
    { lat: 49.0175216, lng: 21.9748328 },
    { lat: 49.0174507, lng: 21.9749687 },
    { lat: 49.0163696, lng: 21.9754238 },
    { lat: 49.0163026, lng: 21.9759429 },
    { lat: 49.0161263, lng: 21.9760941 },
    { lat: 49.0156205, lng: 21.976288 },
    { lat: 49.0144973, lng: 21.9771425 },
    { lat: 49.0128134, lng: 21.978191 },
    { lat: 49.0124604, lng: 21.9780204 },
    { lat: 49.0121635, lng: 21.9781114 },
    { lat: 49.011152, lng: 21.9778669 },
    { lat: 49.0106939, lng: 21.977995 },
    { lat: 49.0101775, lng: 21.9779486 },
    { lat: 49.0099948, lng: 21.9777444 },
    { lat: 49.0097279, lng: 21.9776853 },
    { lat: 49.0093166, lng: 21.9779759 },
    { lat: 49.0084287, lng: 21.9789663 },
    { lat: 49.0082858, lng: 21.9790579 },
    { lat: 49.0074259, lng: 21.9790367 },
    { lat: 49.0068217, lng: 21.9793988 },
    { lat: 49.0050799, lng: 21.9794683 },
    { lat: 49.0048028, lng: 21.9796356 },
    { lat: 49.004997, lng: 21.9811759 },
    { lat: 49.0049803, lng: 21.9813237 },
    { lat: 49.0049442, lng: 21.9816385 },
    { lat: 49.0043528, lng: 21.9834002 },
    { lat: 49.0042424, lng: 21.9843605 },
    { lat: 49.0040234, lng: 21.985663 },
    { lat: 49.0040529, lng: 21.9856732 },
    { lat: 49.0037922, lng: 21.9871557 },
    { lat: 49.0038464, lng: 21.9891822 },
    { lat: 49.0039974, lng: 21.9902631 },
    { lat: 49.0039188, lng: 21.9909121 },
    { lat: 49.0038763, lng: 21.9923013 },
    { lat: 49.0039482, lng: 21.9939166 },
    { lat: 49.0042229, lng: 21.9957229 },
    { lat: 49.0042388, lng: 21.9961007 },
    { lat: 49.0043557, lng: 21.9964205 },
    { lat: 49.0040479, lng: 21.9976831 },
    { lat: 49.0042136, lng: 21.9979314 },
    { lat: 49.0048457, lng: 21.9979306 },
    { lat: 49.0057243, lng: 21.9983054 },
    { lat: 49.0059663, lng: 21.9983097 },
    { lat: 49.0060456, lng: 21.9988365 },
    { lat: 49.0061565, lng: 21.999008 },
    { lat: 49.0060608, lng: 21.9993272 },
    { lat: 49.0060892, lng: 21.9998448 },
    { lat: 49.006222, lng: 22.0005883 },
    { lat: 49.0061999, lng: 22.0014419 },
    { lat: 49.006075, lng: 22.0016262 },
    { lat: 49.0063312, lng: 22.0039266 },
    { lat: 49.0071756, lng: 22.0038248 },
    { lat: 49.0081272, lng: 22.0035442 },
    { lat: 49.0095356, lng: 22.0034996 },
    { lat: 49.0095673, lng: 22.0038816 },
    { lat: 49.0098842, lng: 22.0037803 },
    { lat: 49.0103676, lng: 22.0054307 },
    { lat: 49.0104286, lng: 22.0058662 },
    { lat: 49.0109566, lng: 22.0070406 },
    { lat: 49.0118269, lng: 22.007493 },
    { lat: 49.0120264, lng: 22.008292 },
    { lat: 49.012022, lng: 22.0094149 },
    { lat: 49.0117613, lng: 22.0103084 },
    { lat: 49.0121052, lng: 22.0098327 },
    { lat: 49.012235, lng: 22.0094327 },
    { lat: 49.0124339, lng: 22.0094215 },
    { lat: 49.0129024, lng: 22.0091645 },
    { lat: 49.0130634, lng: 22.0094088 },
    { lat: 49.01329, lng: 22.0094811 },
    { lat: 49.013563, lng: 22.0094138 },
    { lat: 49.0137731, lng: 22.0092623 },
    { lat: 49.013801, lng: 22.0088877 },
    { lat: 49.0139425, lng: 22.0085971 },
    { lat: 49.014215, lng: 22.0083751 },
    { lat: 49.0142961, lng: 22.0081519 },
    { lat: 49.0144929, lng: 22.0081029 },
    { lat: 49.0146863, lng: 22.0081981 },
    { lat: 49.0148507, lng: 22.0084326 },
    { lat: 49.0149745, lng: 22.0084346 },
    { lat: 49.0152797, lng: 22.0083079 },
    { lat: 49.0153818, lng: 22.0081176 },
    { lat: 49.0156111, lng: 22.0080173 },
    { lat: 49.0160541, lng: 22.0079133 },
    { lat: 49.0162428, lng: 22.0079504 },
    { lat: 49.0163591, lng: 22.0078274 },
    { lat: 49.0174748, lng: 22.0071811 },
    { lat: 49.01785, lng: 22.0070648 },
    { lat: 49.0182375, lng: 22.0070817 },
    { lat: 49.0186045, lng: 22.0069565 },
    { lat: 49.0187782, lng: 22.0071202 },
    { lat: 49.0191471, lng: 22.0070254 },
    { lat: 49.0193208, lng: 22.0069092 },
    { lat: 49.0199274, lng: 22.0068755 },
    { lat: 49.0202253, lng: 22.0067793 },
    { lat: 49.0204229, lng: 22.0066137 },
    { lat: 49.0210339, lng: 22.0063963 },
    { lat: 49.0212675, lng: 22.006199 },
    { lat: 49.0214654, lng: 22.0061474 },
    { lat: 49.0217635, lng: 22.0061443 },
    { lat: 49.0225054, lng: 22.0064034 },
    { lat: 49.0228845, lng: 22.0064072 },
    { lat: 49.0233597, lng: 22.0062455 },
    { lat: 49.0243388, lng: 22.0060422 },
    { lat: 49.0245455, lng: 22.0065858 },
    { lat: 49.0248127, lng: 22.0075811 },
    { lat: 49.0255115, lng: 22.0094971 },
    { lat: 49.0264512, lng: 22.0117903 },
    { lat: 49.0270727, lng: 22.012472 },
    { lat: 49.0276888, lng: 22.0127336 },
    { lat: 49.0298028, lng: 22.0134056 },
    { lat: 49.0306335, lng: 22.0126326 },
    { lat: 49.0313618, lng: 22.0119534 },
    { lat: 49.0315991, lng: 22.011736 },
    { lat: 49.0323906, lng: 22.0138534 },
    { lat: 49.0328089, lng: 22.0133478 },
    { lat: 49.0338076, lng: 22.0126385 },
    { lat: 49.0341461, lng: 22.0138466 },
    { lat: 49.0350173, lng: 22.0129403 },
    { lat: 49.0356011, lng: 22.0128539 },
    { lat: 49.0359918, lng: 22.0132382 },
    { lat: 49.0367647, lng: 22.0137581 },
    { lat: 49.0373307, lng: 22.0134375 },
    { lat: 49.039053, lng: 22.0120577 },
    { lat: 49.0392686, lng: 22.0113931 },
    { lat: 49.0398749, lng: 22.0109781 },
    { lat: 49.0409667, lng: 22.010406 },
    { lat: 49.0413144, lng: 22.0096179 },
    { lat: 49.0414758, lng: 22.0091306 },
    { lat: 49.0414449, lng: 22.0088759 },
    { lat: 49.0417897, lng: 22.0077355 },
    { lat: 49.0421608, lng: 22.0071263 },
    { lat: 49.0423415, lng: 22.0066107 },
    { lat: 49.0426171, lng: 22.0062317 },
    { lat: 49.0428074, lng: 22.0056936 },
    { lat: 49.0429347, lng: 22.0055574 },
    { lat: 49.0430977, lng: 22.0049986 },
    { lat: 49.0433049, lng: 22.0046807 },
    { lat: 49.0429748, lng: 22.0044315 },
    { lat: 49.0420865, lng: 22.0044831 },
    { lat: 49.0412165, lng: 22.0042301 },
    { lat: 49.0404368, lng: 22.0038494 },
    { lat: 49.0397513, lng: 22.0031837 },
    { lat: 49.038916, lng: 22.0028754 },
    { lat: 49.0385914, lng: 22.0029427 },
    { lat: 49.0380844, lng: 22.0032817 },
    { lat: 49.0379439, lng: 22.0031924 },
    { lat: 49.0376362, lng: 22.0035768 },
    { lat: 49.0370718, lng: 22.0034952 },
    { lat: 49.0355943, lng: 22.0036136 },
    { lat: 49.0355417, lng: 22.0027397 },
    { lat: 49.0352315, lng: 22.0007106 },
    { lat: 49.0349106, lng: 21.9997754 },
    { lat: 49.0348007, lng: 21.9995583 },
    { lat: 49.0346401, lng: 21.9994311 },
    { lat: 49.0342178, lng: 21.9993663 },
    { lat: 49.0332126, lng: 21.9993762 },
    { lat: 49.0331821, lng: 21.9990904 },
    { lat: 49.0332403, lng: 21.9988477 },
    { lat: 49.033489, lng: 21.9982975 },
    { lat: 49.0334993, lng: 21.9980345 },
    { lat: 49.0333642, lng: 21.9975194 },
    { lat: 49.032956, lng: 21.9964168 },
    { lat: 49.0326801, lng: 21.9952853 },
    { lat: 49.0325528, lng: 21.9950555 },
    { lat: 49.0323579, lng: 21.9944822 },
    { lat: 49.0319461, lng: 21.9936877 },
    { lat: 49.0317567, lng: 21.9928434 },
    { lat: 49.031576, lng: 21.9924016 },
    { lat: 49.0307336, lng: 21.9907379 },
    { lat: 49.0305622, lng: 21.9905436 },
    { lat: 49.0302958, lng: 21.9904529 },
    { lat: 49.0301372, lng: 21.9902209 },
    { lat: 49.0300401, lng: 21.9896034 },
    { lat: 49.029942, lng: 21.9894168 },
    { lat: 49.0297759, lng: 21.9892979 },
    { lat: 49.0291813, lng: 21.9890776 },
    { lat: 49.0285205, lng: 21.9892368 },
    { lat: 49.0280819, lng: 21.9889203 },
    { lat: 49.0279253, lng: 21.9889173 },
    { lat: 49.0274128, lng: 21.9891524 },
    { lat: 49.026678, lng: 21.9891487 },
    { lat: 49.0256373, lng: 21.9887199 },
    { lat: 49.0251187, lng: 21.9886216 },
    { lat: 49.024857, lng: 21.9885016 },
    { lat: 49.0246281, lng: 21.9881551 },
    { lat: 49.0244896, lng: 21.987097 },
    { lat: 49.0244585, lng: 21.9849942 },
    { lat: 49.0245624, lng: 21.9835367 },
    { lat: 49.024302, lng: 21.9832375 },
    { lat: 49.0242423, lng: 21.9830441 },
    { lat: 49.0241183, lng: 21.9829956 },
    { lat: 49.0238126, lng: 21.9826777 },
    { lat: 49.0233213, lng: 21.9816321 },
    { lat: 49.0232501, lng: 21.9812967 },
    { lat: 49.0229055, lng: 21.9805908 },
    { lat: 49.0227547, lng: 21.9804012 },
    { lat: 49.0227296, lng: 21.9800195 },
    { lat: 49.0226188, lng: 21.9794928 },
    { lat: 49.0227827, lng: 21.9790208 },
    { lat: 49.022972, lng: 21.9789857 },
    { lat: 49.0230109, lng: 21.9781374 },
    { lat: 49.0228258, lng: 21.9775694 },
    { lat: 49.0222752, lng: 21.9749255 },
    { lat: 49.0220362, lng: 21.9733012 },
    { lat: 49.0219049, lng: 21.9719851 },
    { lat: 49.0221661, lng: 21.9711224 },
    { lat: 49.02225, lng: 21.9703725 },
    { lat: 49.0223373, lng: 21.9699855 },
    { lat: 49.0223358, lng: 21.9697035 },
    { lat: 49.0225913, lng: 21.9693367 },
    { lat: 49.0225875, lng: 21.9692781 },
    { lat: 49.0219453, lng: 21.9694164 },
  ],
  Závadka: [
    { lat: 48.9437639, lng: 21.8336614 },
    { lat: 48.9436919, lng: 21.8334832 },
    { lat: 48.9386402, lng: 21.8292723 },
    { lat: 48.937702, lng: 21.8287055 },
    { lat: 48.9370798, lng: 21.8287024 },
    { lat: 48.9367077, lng: 21.82858 },
    { lat: 48.9360589, lng: 21.8286398 },
    { lat: 48.9353735, lng: 21.828624 },
    { lat: 48.9349155, lng: 21.825352 },
    { lat: 48.9348931, lng: 21.8245307 },
    { lat: 48.9346369, lng: 21.8245406 },
    { lat: 48.934002, lng: 21.8256129 },
    { lat: 48.9331348, lng: 21.826101 },
    { lat: 48.9316484, lng: 21.8240805 },
    { lat: 48.931408, lng: 21.8247925 },
    { lat: 48.9310938, lng: 21.8242115 },
    { lat: 48.9302592, lng: 21.8250004 },
    { lat: 48.9301478, lng: 21.8250407 },
    { lat: 48.929499, lng: 21.8248368 },
    { lat: 48.9286491, lng: 21.8248145 },
    { lat: 48.9264167, lng: 21.823622 },
    { lat: 48.9263157, lng: 21.8240623 },
    { lat: 48.9255969, lng: 21.8243227 },
    { lat: 48.9253133, lng: 21.82436 },
    { lat: 48.9248759, lng: 21.8239349 },
    { lat: 48.9234966, lng: 21.8239342 },
    { lat: 48.9232522, lng: 21.8237473 },
    { lat: 48.9225395, lng: 21.8236785 },
    { lat: 48.9220252, lng: 21.8240179 },
    { lat: 48.9213186, lng: 21.8238429 },
    { lat: 48.9212741, lng: 21.8233805 },
    { lat: 48.9212494, lng: 21.823817 },
    { lat: 48.921094, lng: 21.8239435 },
    { lat: 48.9202923, lng: 21.8239173 },
    { lat: 48.9186445, lng: 21.8237057 },
    { lat: 48.9186036, lng: 21.8240383 },
    { lat: 48.9175588, lng: 21.8235275 },
    { lat: 48.9171028, lng: 21.8241249 },
    { lat: 48.9164586, lng: 21.824791 },
    { lat: 48.9156294, lng: 21.8249133 },
    { lat: 48.915461, lng: 21.8249291 },
    { lat: 48.915505, lng: 21.8256271 },
    { lat: 48.9164465, lng: 21.8276241 },
    { lat: 48.917216, lng: 21.8290222 },
    { lat: 48.9183425, lng: 21.8306521 },
    { lat: 48.9194089, lng: 21.8313917 },
    { lat: 48.9192612, lng: 21.8325529 },
    { lat: 48.9192205, lng: 21.8360764 },
    { lat: 48.9194121, lng: 21.837401 },
    { lat: 48.9199625, lng: 21.8395514 },
    { lat: 48.9204766, lng: 21.8413455 },
    { lat: 48.9189203, lng: 21.8428542 },
    { lat: 48.9186874, lng: 21.8440397 },
    { lat: 48.9184324, lng: 21.8475909 },
    { lat: 48.9181687, lng: 21.8497704 },
    { lat: 48.9182434, lng: 21.8497892 },
    { lat: 48.9179251, lng: 21.8536012 },
    { lat: 48.9185952, lng: 21.8581997 },
    { lat: 48.9187638, lng: 21.8588121 },
    { lat: 48.9188346, lng: 21.8590698 },
    { lat: 48.9190404, lng: 21.8592483 },
    { lat: 48.9189894, lng: 21.8594548 },
    { lat: 48.9190254, lng: 21.8596294 },
    { lat: 48.9194345, lng: 21.860888 },
    { lat: 48.9196328, lng: 21.8618288 },
    { lat: 48.9200525, lng: 21.8630596 },
    { lat: 48.920507, lng: 21.864078 },
    { lat: 48.9208175, lng: 21.8644271 },
    { lat: 48.9212242, lng: 21.8650678 },
    { lat: 48.922033, lng: 21.8655136 },
    { lat: 48.9222477, lng: 21.8655017 },
    { lat: 48.9225085, lng: 21.8652749 },
    { lat: 48.9226597, lng: 21.8653805 },
    { lat: 48.9229551, lng: 21.8659834 },
    { lat: 48.9235069, lng: 21.8657879 },
    { lat: 48.9239613, lng: 21.8658274 },
    { lat: 48.9243044, lng: 21.8657428 },
    { lat: 48.9262059, lng: 21.8648687 },
    { lat: 48.9263677, lng: 21.8656562 },
    { lat: 48.9287992, lng: 21.8651491 },
    { lat: 48.9290176, lng: 21.8655144 },
    { lat: 48.9290223, lng: 21.8656072 },
    { lat: 48.9310738, lng: 21.8650598 },
    { lat: 48.9313, lng: 21.8648075 },
    { lat: 48.9309889, lng: 21.8631929 },
    { lat: 48.9307895, lng: 21.8618212 },
    { lat: 48.9299869, lng: 21.8551948 },
    { lat: 48.9300253, lng: 21.855029 },
    { lat: 48.9306285, lng: 21.8551673 },
    { lat: 48.9303284, lng: 21.8501355 },
    { lat: 48.933087, lng: 21.8493951 },
    { lat: 48.9338539, lng: 21.8480739 },
    { lat: 48.9345196, lng: 21.8467879 },
    { lat: 48.9347693, lng: 21.8458264 },
    { lat: 48.9350353, lng: 21.845888 },
    { lat: 48.9362447, lng: 21.8455896 },
    { lat: 48.9376259, lng: 21.8460145 },
    { lat: 48.9376912, lng: 21.8456671 },
    { lat: 48.9378957, lng: 21.8453473 },
    { lat: 48.9387494, lng: 21.8434182 },
    { lat: 48.939053, lng: 21.8433719 },
    { lat: 48.9391755, lng: 21.8428 },
    { lat: 48.9395761, lng: 21.8415768 },
    { lat: 48.9395702, lng: 21.8404744 },
    { lat: 48.9397264, lng: 21.8405105 },
    { lat: 48.9399068, lng: 21.8406566 },
    { lat: 48.9400203, lng: 21.8403972 },
    { lat: 48.9403154, lng: 21.8401486 },
    { lat: 48.9405025, lng: 21.8401546 },
    { lat: 48.940638, lng: 21.8399605 },
    { lat: 48.9408728, lng: 21.8399906 },
    { lat: 48.9410863, lng: 21.8397675 },
    { lat: 48.9423896, lng: 21.8379962 },
    { lat: 48.9432016, lng: 21.83751 },
    { lat: 48.943271, lng: 21.8364534 },
    { lat: 48.9436412, lng: 21.834353 },
    { lat: 48.9437639, lng: 21.8336614 },
  ],
  Hrubov: [
    { lat: 49.0825354, lng: 21.8975497 },
    { lat: 49.0826253, lng: 21.897454 },
    { lat: 49.0831139, lng: 21.8969343 },
    { lat: 49.084034, lng: 21.8973342 },
    { lat: 49.0857965, lng: 21.8992507 },
    { lat: 49.0860627, lng: 21.9011199 },
    { lat: 49.0866045, lng: 21.9015075 },
    { lat: 49.0869012, lng: 21.9027936 },
    { lat: 49.0876424, lng: 21.8995716 },
    { lat: 49.0887231, lng: 21.8976779 },
    { lat: 49.089497, lng: 21.8973227 },
    { lat: 49.0908421, lng: 21.8970006 },
    { lat: 49.0921233, lng: 21.8969976 },
    { lat: 49.0931458, lng: 21.8953006 },
    { lat: 49.0931146, lng: 21.8946082 },
    { lat: 49.0938374, lng: 21.8952925 },
    { lat: 49.0962513, lng: 21.8949854 },
    { lat: 49.0978768, lng: 21.8941848 },
    { lat: 49.0989115, lng: 21.8944043 },
    { lat: 49.0993881, lng: 21.8955097 },
    { lat: 49.0998688, lng: 21.8950358 },
    { lat: 49.100231, lng: 21.895098 },
    { lat: 49.100385, lng: 21.894891 },
    { lat: 49.10042, lng: 21.894822 },
    { lat: 49.10046, lng: 21.894745 },
    { lat: 49.10315, lng: 21.889505 },
    { lat: 49.106903, lng: 21.885594 },
    { lat: 49.107293, lng: 21.884701 },
    { lat: 49.107331, lng: 21.884614 },
    { lat: 49.107763, lng: 21.883628 },
    { lat: 49.107799, lng: 21.883545 },
    { lat: 49.109749, lng: 21.879093 },
    { lat: 49.109766, lng: 21.879053 },
    { lat: 49.111256, lng: 21.875647 },
    { lat: 49.111367, lng: 21.875364 },
    { lat: 49.111617, lng: 21.874708 },
    { lat: 49.11216, lng: 21.872767 },
    { lat: 49.112974, lng: 21.872244 },
    { lat: 49.113009, lng: 21.872222 },
    { lat: 49.114437, lng: 21.871955 },
    { lat: 49.115467, lng: 21.871866 },
    { lat: 49.115947, lng: 21.871916 },
    { lat: 49.116436, lng: 21.871967 },
    { lat: 49.116607, lng: 21.869852 },
    { lat: 49.116507, lng: 21.869285 },
    { lat: 49.116572, lng: 21.868611 },
    { lat: 49.116918, lng: 21.868302 },
    { lat: 49.117147, lng: 21.867508 },
    { lat: 49.11727, lng: 21.866993 },
    { lat: 49.11819, lng: 21.867017 },
    { lat: 49.118915, lng: 21.866342 },
    { lat: 49.119095, lng: 21.865806 },
    { lat: 49.11917, lng: 21.865584 },
    { lat: 49.119167, lng: 21.865554 },
    { lat: 49.119053, lng: 21.864636 },
    { lat: 49.11984, lng: 21.863688 },
    { lat: 49.1181811, lng: 21.8642224 },
    { lat: 49.1173269, lng: 21.8596481 },
    { lat: 49.11681, lng: 21.8549042 },
    { lat: 49.1163025, lng: 21.8537956 },
    { lat: 49.1148657, lng: 21.849821 },
    { lat: 49.1143419, lng: 21.8494775 },
    { lat: 49.113494, lng: 21.8484348 },
    { lat: 49.1111524, lng: 21.8497694 },
    { lat: 49.1092282, lng: 21.8522502 },
    { lat: 49.1089539, lng: 21.851948 },
    { lat: 49.1088418, lng: 21.8506424 },
    { lat: 49.1075371, lng: 21.8517936 },
    { lat: 49.106294, lng: 21.8523609 },
    { lat: 49.1047283, lng: 21.851662 },
    { lat: 49.1040827, lng: 21.8507809 },
    { lat: 49.1035693, lng: 21.8482592 },
    { lat: 49.1032308, lng: 21.8479942 },
    { lat: 49.1026728, lng: 21.8478626 },
    { lat: 49.102, lng: 21.847592 },
    { lat: 49.1014398, lng: 21.8456964 },
    { lat: 49.0989578, lng: 21.8452866 },
    { lat: 49.0985372, lng: 21.8454229 },
    { lat: 49.0981201, lng: 21.8457373 },
    { lat: 49.0976275, lng: 21.8462296 },
    { lat: 49.0970708, lng: 21.8465337 },
    { lat: 49.0968636, lng: 21.8472359 },
    { lat: 49.0948953, lng: 21.847461 },
    { lat: 49.0934138, lng: 21.8471914 },
    { lat: 49.0926974, lng: 21.8455255 },
    { lat: 49.0924258, lng: 21.8471323 },
    { lat: 49.0916226, lng: 21.8466947 },
    { lat: 49.090268, lng: 21.8464675 },
    { lat: 49.0875086, lng: 21.8485251 },
    { lat: 49.086988, lng: 21.8487035 },
    { lat: 49.0865017, lng: 21.8486049 },
    { lat: 49.0862979, lng: 21.8475849 },
    { lat: 49.0861834, lng: 21.8465455 },
    { lat: 49.084097, lng: 21.846074 },
    { lat: 49.0833148, lng: 21.8470653 },
    { lat: 49.0807901, lng: 21.8465117 },
    { lat: 49.0796148, lng: 21.8464759 },
    { lat: 49.0791592, lng: 21.8476641 },
    { lat: 49.0783227, lng: 21.84799 },
    { lat: 49.078072, lng: 21.8490889 },
    { lat: 49.0775331, lng: 21.8490867 },
    { lat: 49.0780815, lng: 21.8500341 },
    { lat: 49.0781178, lng: 21.8500966 },
    { lat: 49.0774142, lng: 21.8509542 },
    { lat: 49.0762172, lng: 21.8519601 },
    { lat: 49.075381, lng: 21.8539184 },
    { lat: 49.0745538, lng: 21.8546103 },
    { lat: 49.0732039, lng: 21.8553565 },
    { lat: 49.0722912, lng: 21.8561334 },
    { lat: 49.0714554, lng: 21.8569663 },
    { lat: 49.0707055, lng: 21.8576008 },
    { lat: 49.070449, lng: 21.8582169 },
    { lat: 49.0705813, lng: 21.8591246 },
    { lat: 49.0706653, lng: 21.859426 },
    { lat: 49.0706851, lng: 21.8601557 },
    { lat: 49.071198, lng: 21.8609857 },
    { lat: 49.0715174, lng: 21.8613859 },
    { lat: 49.0716355, lng: 21.861874 },
    { lat: 49.0719772, lng: 21.8677814 },
    { lat: 49.0722885, lng: 21.8681667 },
    { lat: 49.0734435, lng: 21.8692253 },
    { lat: 49.0737, lng: 21.8705082 },
    { lat: 49.0737246, lng: 21.8711673 },
    { lat: 49.0742584, lng: 21.8728254 },
    { lat: 49.0742764, lng: 21.8740736 },
    { lat: 49.0735852, lng: 21.8757815 },
    { lat: 49.0733236, lng: 21.8770834 },
    { lat: 49.0742553, lng: 21.8778087 },
    { lat: 49.0756619, lng: 21.8800551 },
    { lat: 49.0761219, lng: 21.8819135 },
    { lat: 49.0770122, lng: 21.884111 },
    { lat: 49.0777002, lng: 21.8842774 },
    { lat: 49.0779575, lng: 21.8851511 },
    { lat: 49.0784381, lng: 21.8861004 },
    { lat: 49.0787247, lng: 21.8872967 },
    { lat: 49.0787347, lng: 21.8885793 },
    { lat: 49.0788621, lng: 21.889889 },
    { lat: 49.0797629, lng: 21.8921956 },
    { lat: 49.0800473, lng: 21.8944177 },
    { lat: 49.0809525, lng: 21.896103 },
    { lat: 49.0825354, lng: 21.8975497 },
  ],
  SlovenskéKrivé: [
    { lat: 49.0553362, lng: 22.0182318 },
    { lat: 49.055395, lng: 22.0186635 },
    { lat: 49.0553651, lng: 22.0192895 },
    { lat: 49.0555047, lng: 22.0193922 },
    { lat: 49.0555379, lng: 22.0199677 },
    { lat: 49.0554826, lng: 22.0200601 },
    { lat: 49.0555468, lng: 22.0201093 },
    { lat: 49.0555984, lng: 22.0203822 },
    { lat: 49.055718, lng: 22.0205824 },
    { lat: 49.0558823, lng: 22.021846 },
    { lat: 49.0559372, lng: 22.021934 },
    { lat: 49.0558826, lng: 22.0220934 },
    { lat: 49.0559265, lng: 22.0223302 },
    { lat: 49.0561374, lng: 22.0226806 },
    { lat: 49.0563482, lng: 22.024004 },
    { lat: 49.0567745, lng: 22.0253599 },
    { lat: 49.056934, lng: 22.0253015 },
    { lat: 49.057228, lng: 22.0253221 },
    { lat: 49.0573959, lng: 22.0256097 },
    { lat: 49.0580441, lng: 22.0261897 },
    { lat: 49.0585111, lng: 22.0267619 },
    { lat: 49.0587875, lng: 22.0273548 },
    { lat: 49.0594431, lng: 22.0280474 },
    { lat: 49.0595634, lng: 22.0280898 },
    { lat: 49.0600058, lng: 22.0286214 },
    { lat: 49.0608159, lng: 22.0292886 },
    { lat: 49.0610501, lng: 22.0295776 },
    { lat: 49.0613788, lng: 22.0302899 },
    { lat: 49.0622205, lng: 22.0306821 },
    { lat: 49.062827, lng: 22.0311566 },
    { lat: 49.0629655, lng: 22.0313996 },
    { lat: 49.0634709, lng: 22.0317434 },
    { lat: 49.0635655, lng: 22.0317966 },
    { lat: 49.063859, lng: 22.0315145 },
    { lat: 49.0642658, lng: 22.0319678 },
    { lat: 49.0645021, lng: 22.0320609 },
    { lat: 49.0649087, lng: 22.0319913 },
    { lat: 49.0652795, lng: 22.03174 },
    { lat: 49.0658205, lng: 22.0310854 },
    { lat: 49.0664409, lng: 22.0308346 },
    { lat: 49.067986, lng: 22.0305424 },
    { lat: 49.068164, lng: 22.0310174 },
    { lat: 49.0694017, lng: 22.0318812 },
    { lat: 49.0705771, lng: 22.0329634 },
    { lat: 49.0716511, lng: 22.0338429 },
    { lat: 49.0730609, lng: 22.0323679 },
    { lat: 49.0747863, lng: 22.0326931 },
    { lat: 49.075661, lng: 22.0323061 },
    { lat: 49.076338, lng: 22.0321605 },
    { lat: 49.078498, lng: 22.0319887 },
    { lat: 49.079613, lng: 22.0327006 },
    { lat: 49.0810212, lng: 22.032474 },
    { lat: 49.0810757, lng: 22.0317024 },
    { lat: 49.081684, lng: 22.0320611 },
    { lat: 49.0819985, lng: 22.0314853 },
    { lat: 49.0820256, lng: 22.0312115 },
    { lat: 49.0830766, lng: 22.0310877 },
    { lat: 49.0838056, lng: 22.0294091 },
    { lat: 49.0842337, lng: 22.0290573 },
    { lat: 49.0846666, lng: 22.0288703 },
    { lat: 49.0849074, lng: 22.0266303 },
    { lat: 49.0865588, lng: 22.0228551 },
    { lat: 49.0867131, lng: 22.0226261 },
    { lat: 49.0867992, lng: 22.0220945 },
    { lat: 49.0870153, lng: 22.0214947 },
    { lat: 49.0868301, lng: 22.0207152 },
    { lat: 49.087086, lng: 22.0201734 },
    { lat: 49.087106, lng: 22.0199781 },
    { lat: 49.0871795, lng: 22.0198111 },
    { lat: 49.087411, lng: 22.0195197 },
    { lat: 49.0874701, lng: 22.0190384 },
    { lat: 49.0872719, lng: 22.0186555 },
    { lat: 49.087449, lng: 22.0178787 },
    { lat: 49.0872196, lng: 22.0169987 },
    { lat: 49.0873633, lng: 22.0161922 },
    { lat: 49.0871102, lng: 22.0157758 },
    { lat: 49.0871322, lng: 22.0156116 },
    { lat: 49.0868395, lng: 22.0149975 },
    { lat: 49.0865995, lng: 22.014323 },
    { lat: 49.0866521, lng: 22.0138471 },
    { lat: 49.0865909, lng: 22.0131784 },
    { lat: 49.0867453, lng: 22.0128177 },
    { lat: 49.0868905, lng: 22.0128778 },
    { lat: 49.0869496, lng: 22.01215 },
    { lat: 49.0865914, lng: 22.0117534 },
    { lat: 49.0866216, lng: 22.0116584 },
    { lat: 49.0865103, lng: 22.0112868 },
    { lat: 49.0865508, lng: 22.0111004 },
    { lat: 49.0865104, lng: 22.010919 },
    { lat: 49.0865645, lng: 22.01079 },
    { lat: 49.0865215, lng: 22.0105875 },
    { lat: 49.0866744, lng: 22.0103023 },
    { lat: 49.0866034, lng: 22.0101638 },
    { lat: 49.0866436, lng: 22.0099663 },
    { lat: 49.0865197, lng: 22.009726 },
    { lat: 49.086554, lng: 22.0095665 },
    { lat: 49.0867194, lng: 22.0093789 },
    { lat: 49.0867326, lng: 22.0089999 },
    { lat: 49.0867104, lng: 22.0087227 },
    { lat: 49.086576, lng: 22.0084676 },
    { lat: 49.0865422, lng: 22.008144 },
    { lat: 49.0864432, lng: 22.0079579 },
    { lat: 49.0864081, lng: 22.0069756 },
    { lat: 49.0865005, lng: 22.0064192 },
    { lat: 49.0860327, lng: 22.0061491 },
    { lat: 49.0853051, lng: 22.0064806 },
    { lat: 49.0848313, lng: 22.0063719 },
    { lat: 49.0847849, lng: 22.0065019 },
    { lat: 49.0845765, lng: 22.0065384 },
    { lat: 49.0844455, lng: 22.0066541 },
    { lat: 49.0841682, lng: 22.0065355 },
    { lat: 49.083949, lng: 22.0067753 },
    { lat: 49.0832821, lng: 22.0069189 },
    { lat: 49.0830885, lng: 22.00705 },
    { lat: 49.0824956, lng: 22.0070467 },
    { lat: 49.0823492, lng: 22.0072677 },
    { lat: 49.0820764, lng: 22.0071097 },
    { lat: 49.0816071, lng: 22.00737 },
    { lat: 49.0811898, lng: 22.0073751 },
    { lat: 49.0809416, lng: 22.0072469 },
    { lat: 49.0809465, lng: 22.0071667 },
    { lat: 49.0808656, lng: 22.0071281 },
    { lat: 49.082533, lng: 22.0064468 },
    { lat: 49.0831735, lng: 22.0049849 },
    { lat: 49.0817437, lng: 22.0041455 },
    { lat: 49.081506, lng: 22.0038963 },
    { lat: 49.0812156, lng: 22.0037515 },
    { lat: 49.0801039, lng: 22.003636 },
    { lat: 49.0786248, lng: 22.0017411 },
    { lat: 49.0783316, lng: 22.000914 },
    { lat: 49.0771368, lng: 22.0000664 },
    { lat: 49.0767568, lng: 21.9996554 },
    { lat: 49.0759326, lng: 21.9992602 },
    { lat: 49.0744295, lng: 21.997618 },
    { lat: 49.0741089, lng: 21.9969659 },
    { lat: 49.0737936, lng: 21.9970465 },
    { lat: 49.0735274, lng: 21.9969269 },
    { lat: 49.0729047, lng: 21.9968978 },
    { lat: 49.0722522, lng: 21.9963803 },
    { lat: 49.0722791, lng: 21.9978173 },
    { lat: 49.0719265, lng: 21.9978365 },
    { lat: 49.0712902, lng: 21.9981309 },
    { lat: 49.0706648, lng: 21.9981274 },
    { lat: 49.0703369, lng: 21.9982402 },
    { lat: 49.0700484, lng: 21.998222 },
    { lat: 49.069689, lng: 21.9984266 },
    { lat: 49.0694798, lng: 21.9984714 },
    { lat: 49.0690494, lng: 21.9989078 },
    { lat: 49.0686399, lng: 21.9990869 },
    { lat: 49.0684656, lng: 21.9993227 },
    { lat: 49.0677048, lng: 22.0000439 },
    { lat: 49.0674399, lng: 22.000618 },
    { lat: 49.0674175, lng: 22.0018295 },
    { lat: 49.0672907, lng: 22.0021222 },
    { lat: 49.0671889, lng: 22.0027442 },
    { lat: 49.0670605, lng: 22.0029702 },
    { lat: 49.0669501, lng: 22.0033366 },
    { lat: 49.0665021, lng: 22.0040662 },
    { lat: 49.0658609, lng: 22.0044319 },
    { lat: 49.0647249, lng: 22.0052723 },
    { lat: 49.0645187, lng: 22.0057175 },
    { lat: 49.0640838, lng: 22.0063779 },
    { lat: 49.0638938, lng: 22.0068046 },
    { lat: 49.0636492, lng: 22.0075655 },
    { lat: 49.0626452, lng: 22.0098679 },
    { lat: 49.0623105, lng: 22.0108106 },
    { lat: 49.062175, lng: 22.0109753 },
    { lat: 49.0619325, lng: 22.0115548 },
    { lat: 49.0615646, lng: 22.0116421 },
    { lat: 49.0611787, lng: 22.0126128 },
    { lat: 49.061395, lng: 22.0132825 },
    { lat: 49.0613377, lng: 22.0140733 },
    { lat: 49.0610387, lng: 22.0147285 },
    { lat: 49.0602527, lng: 22.0158632 },
    { lat: 49.0597173, lng: 22.0162263 },
    { lat: 49.0590107, lng: 22.0162188 },
    { lat: 49.058633, lng: 22.0162812 },
    { lat: 49.0577464, lng: 22.0165883 },
    { lat: 49.0574357, lng: 22.0168624 },
    { lat: 49.0570137, lng: 22.0170435 },
    { lat: 49.0565118, lng: 22.0170271 },
    { lat: 49.0557363, lng: 22.0171906 },
    { lat: 49.0552553, lng: 22.0179046 },
    { lat: 49.0553559, lng: 22.0182052 },
    { lat: 49.0553362, lng: 22.0182318 },
  ],
  Lackovce: [
    { lat: 48.9418566, lng: 21.9425314 },
    { lat: 48.9417816, lng: 21.9424169 },
    { lat: 48.9409243, lng: 21.9411864 },
    { lat: 48.9404583, lng: 21.9406356 },
    { lat: 48.9400941, lng: 21.9390624 },
    { lat: 48.9381888, lng: 21.9392024 },
    { lat: 48.9381945, lng: 21.9393481 },
    { lat: 48.9383511, lng: 21.9397563 },
    { lat: 48.9383973, lng: 21.9401655 },
    { lat: 48.9363309, lng: 21.940398 },
    { lat: 48.9354976, lng: 21.9400476 },
    { lat: 48.9356666, lng: 21.9410511 },
    { lat: 48.9365628, lng: 21.9466479 },
    { lat: 48.9366835, lng: 21.9500231 },
    { lat: 48.9364969, lng: 21.9508914 },
    { lat: 48.9364272, lng: 21.9524378 },
    { lat: 48.9362526, lng: 21.9528288 },
    { lat: 48.9359186, lng: 21.9531523 },
    { lat: 48.9356209, lng: 21.954077 },
    { lat: 48.9356596, lng: 21.955594 },
    { lat: 48.9354038, lng: 21.9559427 },
    { lat: 48.9346722, lng: 21.9572885 },
    { lat: 48.9336657, lng: 21.9622633 },
    { lat: 48.9335253, lng: 21.9637285 },
    { lat: 48.9337726, lng: 21.9641752 },
    { lat: 48.9353561, lng: 21.9677714 },
    { lat: 48.9356263, lng: 21.9681471 },
    { lat: 48.9382657, lng: 21.9732387 },
    { lat: 48.9388726, lng: 21.9745025 },
    { lat: 48.9400424, lng: 21.9766994 },
    { lat: 48.9404112, lng: 21.977061 },
    { lat: 48.9404295, lng: 21.9770274 },
    { lat: 48.9406457, lng: 21.9773565 },
    { lat: 48.9408244, lng: 21.9772126 },
    { lat: 48.9409648, lng: 21.977941 },
    { lat: 48.941539, lng: 21.9771818 },
    { lat: 48.9418909, lng: 21.9781803 },
    { lat: 48.942241, lng: 21.9787412 },
    { lat: 48.9423261, lng: 21.9791325 },
    { lat: 48.9426254, lng: 21.9796881 },
    { lat: 48.942947, lng: 21.9799144 },
    { lat: 48.9430893, lng: 21.9802191 },
    { lat: 48.9441302, lng: 21.9817487 },
    { lat: 48.9445606, lng: 21.9826497 },
    { lat: 48.9448798, lng: 21.983595 },
    { lat: 48.945131, lng: 21.9836109 },
    { lat: 48.9450752, lng: 21.9834232 },
    { lat: 48.9447927, lng: 21.9824738 },
    { lat: 48.9450643, lng: 21.9824102 },
    { lat: 48.9454402, lng: 21.9815412 },
    { lat: 48.9448559, lng: 21.9803293 },
    { lat: 48.9450813, lng: 21.9801222 },
    { lat: 48.9448358, lng: 21.9795997 },
    { lat: 48.9449511, lng: 21.9788886 },
    { lat: 48.9448717, lng: 21.9785438 },
    { lat: 48.9446667, lng: 21.9780762 },
    { lat: 48.9451633, lng: 21.9769496 },
    { lat: 48.945944, lng: 21.9758587 },
    { lat: 48.9469937, lng: 21.9750331 },
    { lat: 48.9477446, lng: 21.9745175 },
    { lat: 48.9491309, lng: 21.9735668 },
    { lat: 48.9491561, lng: 21.9736334 },
    { lat: 48.9494349, lng: 21.9729398 },
    { lat: 48.9490914, lng: 21.9721923 },
    { lat: 48.9496846, lng: 21.9710643 },
    { lat: 48.9501838, lng: 21.970278 },
    { lat: 48.9503725, lng: 21.9696714 },
    { lat: 48.9496782, lng: 21.9692048 },
    { lat: 48.9494821, lng: 21.9690062 },
    { lat: 48.9494787, lng: 21.9680913 },
    { lat: 48.9493692, lng: 21.9681229 },
    { lat: 48.9489081, lng: 21.9641051 },
    { lat: 48.948937, lng: 21.9627242 },
    { lat: 48.9492811, lng: 21.9558892 },
    { lat: 48.9490556, lng: 21.9539101 },
    { lat: 48.9488048, lng: 21.9502874 },
    { lat: 48.948369, lng: 21.950172 },
    { lat: 48.9481001, lng: 21.9499959 },
    { lat: 48.9478395, lng: 21.949912 },
    { lat: 48.9478529, lng: 21.9498164 },
    { lat: 48.9475832, lng: 21.9496507 },
    { lat: 48.9470374, lng: 21.9491426 },
    { lat: 48.9470245, lng: 21.947757 },
    { lat: 48.9461822, lng: 21.9468611 },
    { lat: 48.9457518, lng: 21.9467866 },
    { lat: 48.9450199, lng: 21.9463024 },
    { lat: 48.9448461, lng: 21.9463742 },
    { lat: 48.9447225, lng: 21.9463308 },
    { lat: 48.9446586, lng: 21.9462468 },
    { lat: 48.9445446, lng: 21.9457745 },
    { lat: 48.9437548, lng: 21.9450453 },
    { lat: 48.9431329, lng: 21.9446941 },
    { lat: 48.9429565, lng: 21.9445174 },
    { lat: 48.9424083, lng: 21.9436878 },
    { lat: 48.9421843, lng: 21.9429642 },
    { lat: 48.9419099, lng: 21.9426014 },
    { lat: 48.9418566, lng: 21.9425314 },
  ],
  Karná: [
    { lat: 48.998598, lng: 21.8126391 },
    { lat: 48.9987296, lng: 21.8124574 },
    { lat: 48.9987935, lng: 21.8123698 },
    { lat: 48.9988983, lng: 21.8114177 },
    { lat: 48.9992125, lng: 21.8100471 },
    { lat: 48.9998889, lng: 21.8081966 },
    { lat: 49.0006003, lng: 21.8064381 },
    { lat: 49.0007207, lng: 21.8058712 },
    { lat: 49.000774, lng: 21.8058902 },
    { lat: 49.0009053, lng: 21.805446 },
    { lat: 49.0008788, lng: 21.8045078 },
    { lat: 49.0011271, lng: 21.8027262 },
    { lat: 49.0011836, lng: 21.8019178 },
    { lat: 49.0013248, lng: 21.801293 },
    { lat: 49.0014149, lng: 21.8006413 },
    { lat: 49.0015855, lng: 21.7989464 },
    { lat: 49.0014901, lng: 21.79896 },
    { lat: 49.0018474, lng: 21.7975179 },
    { lat: 49.002462, lng: 21.7959299 },
    { lat: 49.0029435, lng: 21.7950292 },
    { lat: 49.0034561, lng: 21.7944583 },
    { lat: 49.0035449, lng: 21.7942724 },
    { lat: 49.0035958, lng: 21.7927358 },
    { lat: 49.0040086, lng: 21.7917898 },
    { lat: 49.0043725, lng: 21.7901282 },
    { lat: 49.0045454, lng: 21.7895968 },
    { lat: 49.0043847, lng: 21.7894306 },
    { lat: 49.0045505, lng: 21.7889265 },
    { lat: 49.0048677, lng: 21.7882835 },
    { lat: 49.0050058, lng: 21.7877148 },
    { lat: 49.0050773, lng: 21.787177 },
    { lat: 49.0052588, lng: 21.7871932 },
    { lat: 49.0063285, lng: 21.784696 },
    { lat: 49.0064405, lng: 21.7839866 },
    { lat: 49.0063099, lng: 21.7835538 },
    { lat: 49.0062587, lng: 21.7824171 },
    { lat: 49.0060328, lng: 21.7819911 },
    { lat: 49.0061377, lng: 21.7817493 },
    { lat: 49.0062364, lng: 21.7808358 },
    { lat: 49.0062759, lng: 21.7805009 },
    { lat: 49.005708, lng: 21.7788705 },
    { lat: 49.0054741, lng: 21.7785364 },
    { lat: 49.0046732, lng: 21.777677 },
    { lat: 49.0039701, lng: 21.7770331 },
    { lat: 49.0038849, lng: 21.7769129 },
    { lat: 49.0037419, lng: 21.7763139 },
    { lat: 49.003511, lng: 21.774774 },
    { lat: 49.002268, lng: 21.775668 },
    { lat: 49.001565, lng: 21.775946 },
    { lat: 49.000849, lng: 21.77605 },
    { lat: 49.000695, lng: 21.776318 },
    { lat: 49.000303, lng: 21.776999 },
    { lat: 48.999978, lng: 21.777575 },
    { lat: 48.999747, lng: 21.777968 },
    { lat: 48.999694, lng: 21.778063 },
    { lat: 48.998448, lng: 21.777459 },
    { lat: 48.99763, lng: 21.776778 },
    { lat: 48.997432, lng: 21.776697 },
    { lat: 48.997397, lng: 21.77668 },
    { lat: 48.996063, lng: 21.776273 },
    { lat: 48.995535, lng: 21.776031 },
    { lat: 48.994428, lng: 21.775551 },
    { lat: 48.993518, lng: 21.775424 },
    { lat: 48.991828, lng: 21.774448 },
    { lat: 48.991368, lng: 21.774409 },
    { lat: 48.991205, lng: 21.774427 },
    { lat: 48.989879, lng: 21.77454 },
    { lat: 48.988509, lng: 21.774526 },
    { lat: 48.987453, lng: 21.774439 },
    { lat: 48.986971, lng: 21.775232 },
    { lat: 48.986662, lng: 21.775873 },
    { lat: 48.986021, lng: 21.77705 },
    { lat: 48.984835, lng: 21.778584 },
    { lat: 48.984394, lng: 21.779057 },
    { lat: 48.984169, lng: 21.779422 },
    { lat: 48.983671, lng: 21.780952 },
    { lat: 48.983235, lng: 21.782 },
    { lat: 48.982548, lng: 21.783969 },
    { lat: 48.981481, lng: 21.784959 },
    { lat: 48.981366, lng: 21.785081 },
    { lat: 48.9812, lng: 21.785217 },
    { lat: 48.980786, lng: 21.785347 },
    { lat: 48.980626, lng: 21.785917 },
    { lat: 48.980405, lng: 21.786591 },
    { lat: 48.979424, lng: 21.787717 },
    { lat: 48.978974, lng: 21.788387 },
    { lat: 48.978578, lng: 21.789097 },
    { lat: 48.97792, lng: 21.789812 },
    { lat: 48.977174, lng: 21.790094 },
    { lat: 48.97624, lng: 21.79023 },
    { lat: 48.975514, lng: 21.790182 },
    { lat: 48.974988, lng: 21.790501 },
    { lat: 48.974465, lng: 21.792011 },
    { lat: 48.973914, lng: 21.793642 },
    { lat: 48.9737, lng: 21.793934 },
    { lat: 48.973777, lng: 21.794911 },
    { lat: 48.973721, lng: 21.795194 },
    { lat: 48.973367, lng: 21.795572 },
    { lat: 48.972798, lng: 21.79727 },
    { lat: 48.972195, lng: 21.797865 },
    { lat: 48.972161, lng: 21.798047 },
    { lat: 48.971812, lng: 21.798019 },
    { lat: 48.971596, lng: 21.797836 },
    { lat: 48.971256, lng: 21.798075 },
    { lat: 48.971186, lng: 21.798905 },
    { lat: 48.970898, lng: 21.799276 },
    { lat: 48.970721, lng: 21.799161 },
    { lat: 48.970541, lng: 21.799414 },
    { lat: 48.970425, lng: 21.799451 },
    { lat: 48.970357, lng: 21.799316 },
    { lat: 48.970065, lng: 21.799499 },
    { lat: 48.970047, lng: 21.799744 },
    { lat: 48.96935, lng: 21.800152 },
    { lat: 48.969365, lng: 21.800359 },
    { lat: 48.968871, lng: 21.800587 },
    { lat: 48.968618, lng: 21.800286 },
    { lat: 48.967711, lng: 21.800732 },
    { lat: 48.967813, lng: 21.80112 },
    { lat: 48.9694113, lng: 21.8057769 },
    { lat: 48.9697382, lng: 21.8077062 },
    { lat: 48.9695979, lng: 21.812051 },
    { lat: 48.9696912, lng: 21.8133014 },
    { lat: 48.9704039, lng: 21.818317 },
    { lat: 48.9706893, lng: 21.8198231 },
    { lat: 48.9710626, lng: 21.8211217 },
    { lat: 48.971195, lng: 21.8212842 },
    { lat: 48.9714773, lng: 21.8216272 },
    { lat: 48.9717049, lng: 21.8221679 },
    { lat: 48.9719721, lng: 21.8220997 },
    { lat: 48.9726341, lng: 21.822294 },
    { lat: 48.9732342, lng: 21.8229196 },
    { lat: 48.9736626, lng: 21.8234787 },
    { lat: 48.9736714, lng: 21.8236494 },
    { lat: 48.9737708, lng: 21.8237548 },
    { lat: 48.9745339, lng: 21.8240752 },
    { lat: 48.9749606, lng: 21.824478 },
    { lat: 48.9753185, lng: 21.8236141 },
    { lat: 48.9758932, lng: 21.8241342 },
    { lat: 48.9763249, lng: 21.8241657 },
    { lat: 48.9766214, lng: 21.8243636 },
    { lat: 48.976983, lng: 21.8244727 },
    { lat: 48.9777169, lng: 21.8243108 },
    { lat: 48.9779644, lng: 21.8245298 },
    { lat: 48.9792357, lng: 21.8239673 },
    { lat: 48.9794712, lng: 21.8235479 },
    { lat: 48.9798591, lng: 21.8230216 },
    { lat: 48.9800898, lng: 21.8224531 },
    { lat: 48.9803937, lng: 21.8219468 },
    { lat: 48.9807507, lng: 21.8219147 },
    { lat: 48.9808683, lng: 21.8215929 },
    { lat: 48.9809924, lng: 21.8215167 },
    { lat: 48.9812134, lng: 21.8211479 },
    { lat: 48.9816751, lng: 21.8210337 },
    { lat: 48.9818218, lng: 21.8217476 },
    { lat: 48.9824558, lng: 21.8213813 },
    { lat: 48.9833542, lng: 21.82128 },
    { lat: 48.9843112, lng: 21.821307 },
    { lat: 48.9851949, lng: 21.8215963 },
    { lat: 48.9860865, lng: 21.8219274 },
    { lat: 48.9866333, lng: 21.821933 },
    { lat: 48.9874719, lng: 21.8220853 },
    { lat: 48.9884343, lng: 21.8224559 },
    { lat: 48.9885322, lng: 21.8221019 },
    { lat: 48.9892485, lng: 21.8218804 },
    { lat: 48.989396, lng: 21.8219246 },
    { lat: 48.9907917, lng: 21.8210023 },
    { lat: 48.9908261, lng: 21.8202014 },
    { lat: 48.9920391, lng: 21.8193405 },
    { lat: 48.9931584, lng: 21.8186442 },
    { lat: 48.9938333, lng: 21.8184262 },
    { lat: 48.9956657, lng: 21.8167256 },
    { lat: 48.9964612, lng: 21.8157409 },
    { lat: 48.9969014, lng: 21.813924 },
    { lat: 48.9970637, lng: 21.813857 },
    { lat: 48.9972436, lng: 21.8138964 },
    { lat: 48.9976679, lng: 21.8137674 },
    { lat: 48.9978993, lng: 21.8135819 },
    { lat: 48.9985387, lng: 21.8128683 },
    { lat: 48.998598, lng: 21.8126391 },
  ],
  HrabovecnadLaborcom: [
    { lat: 49.0825354, lng: 21.8975497 },
    { lat: 49.0812942, lng: 21.8988274 },
    { lat: 49.0807033, lng: 21.89908 },
    { lat: 49.0800691, lng: 21.8990366 },
    { lat: 49.0781474, lng: 21.8997349 },
    { lat: 49.0772167, lng: 21.9014643 },
    { lat: 49.0745703, lng: 21.9045274 },
    { lat: 49.0739091, lng: 21.9068624 },
    { lat: 49.0731717, lng: 21.9104696 },
    { lat: 49.0718946, lng: 21.9124255 },
    { lat: 49.0715426, lng: 21.9128818 },
    { lat: 49.0714435, lng: 21.9130102 },
    { lat: 49.0713028, lng: 21.9132767 },
    { lat: 49.070648, lng: 21.9135389 },
    { lat: 49.0702812, lng: 21.9134411 },
    { lat: 49.0693822, lng: 21.913839 },
    { lat: 49.0688263, lng: 21.9142382 },
    { lat: 49.0668374, lng: 21.9164352 },
    { lat: 49.0661074, lng: 21.9170661 },
    { lat: 49.0663547, lng: 21.9183572 },
    { lat: 49.0689171, lng: 21.9220965 },
    { lat: 49.0699251, lng: 21.9239069 },
    { lat: 49.0708591, lng: 21.9264076 },
    { lat: 49.0720164, lng: 21.9287452 },
    { lat: 49.0737084, lng: 21.9276803 },
    { lat: 49.0753941, lng: 21.9283874 },
    { lat: 49.0760494, lng: 21.9297611 },
    { lat: 49.076036, lng: 21.9299036 },
    { lat: 49.0767024, lng: 21.9328151 },
    { lat: 49.0792487, lng: 21.9350226 },
    { lat: 49.0804446, lng: 21.9363011 },
    { lat: 49.0820843, lng: 21.9384185 },
    { lat: 49.0831115, lng: 21.9392429 },
    { lat: 49.0838158, lng: 21.9406134 },
    { lat: 49.0844871, lng: 21.9416976 },
    { lat: 49.0833838, lng: 21.9428317 },
    { lat: 49.0841172, lng: 21.945032 },
    { lat: 49.0849014, lng: 21.9473884 },
    { lat: 49.0848932, lng: 21.9476982 },
    { lat: 49.0849647, lng: 21.9480347 },
    { lat: 49.0852974, lng: 21.9491158 },
    { lat: 49.0864122, lng: 21.9508958 },
    { lat: 49.0872693, lng: 21.9525445 },
    { lat: 49.0877756, lng: 21.9525047 },
    { lat: 49.0887685, lng: 21.952674 },
    { lat: 49.089793, lng: 21.952391 },
    { lat: 49.0905697, lng: 21.951772 },
    { lat: 49.0908895, lng: 21.951248 },
    { lat: 49.0915533, lng: 21.9499309 },
    { lat: 49.0920617, lng: 21.9495695 },
    { lat: 49.0929004, lng: 21.9486877 },
    { lat: 49.0947166, lng: 21.9476238 },
    { lat: 49.0962814, lng: 21.9477667 },
    { lat: 49.0964874, lng: 21.9479555 },
    { lat: 49.0978177, lng: 21.9477669 },
    { lat: 49.098861, lng: 21.947255 },
    { lat: 49.099343, lng: 21.946848 },
    { lat: 49.100939, lng: 21.945941 },
    { lat: 49.101565, lng: 21.941571 },
    { lat: 49.102472, lng: 21.9404 },
    { lat: 49.102738, lng: 21.940248 },
    { lat: 49.102943, lng: 21.940143 },
    { lat: 49.103415, lng: 21.940172 },
    { lat: 49.106207, lng: 21.941107 },
    { lat: 49.105365, lng: 21.939726 },
    { lat: 49.105285, lng: 21.93793 },
    { lat: 49.106529, lng: 21.936658 },
    { lat: 49.107404, lng: 21.936258 },
    { lat: 49.111022, lng: 21.934715 },
    { lat: 49.1111, lng: 21.934682 },
    { lat: 49.11159, lng: 21.93246 },
    { lat: 49.113378, lng: 21.931913 },
    { lat: 49.113001, lng: 21.929864 },
    { lat: 49.112852, lng: 21.929651 },
    { lat: 49.113434, lng: 21.929198 },
    { lat: 49.112972, lng: 21.927115 },
    { lat: 49.112957, lng: 21.92707 },
    { lat: 49.112742, lng: 21.926442 },
    { lat: 49.112708, lng: 21.926137 },
    { lat: 49.112611, lng: 21.925838 },
    { lat: 49.112524, lng: 21.925511 },
    { lat: 49.112279, lng: 21.924241 },
    { lat: 49.112239, lng: 21.923856 },
    { lat: 49.112051, lng: 21.923252 },
    { lat: 49.112098, lng: 21.92291 },
    { lat: 49.112139, lng: 21.922518 },
    { lat: 49.112004, lng: 21.921421 },
    { lat: 49.112038, lng: 21.920995 },
    { lat: 49.112075, lng: 21.920615 },
    { lat: 49.112116, lng: 21.920335 },
    { lat: 49.112943, lng: 21.917301 },
    { lat: 49.11289, lng: 21.916614 },
    { lat: 49.112127, lng: 21.913864 },
    { lat: 49.111204, lng: 21.912882 },
    { lat: 49.110828, lng: 21.91236 },
    { lat: 49.110456, lng: 21.911812 },
    { lat: 49.109255, lng: 21.909415 },
    { lat: 49.108911, lng: 21.908029 },
    { lat: 49.108281, lng: 21.907803 },
    { lat: 49.108121, lng: 21.907905 },
    { lat: 49.107973, lng: 21.907895 },
    { lat: 49.107883, lng: 21.907806 },
    { lat: 49.1077, lng: 21.907864 },
    { lat: 49.107325, lng: 21.907595 },
    { lat: 49.107011, lng: 21.907243 },
    { lat: 49.106808, lng: 21.907037 },
    { lat: 49.106713, lng: 21.906719 },
    { lat: 49.106367, lng: 21.906198 },
    { lat: 49.10612, lng: 21.90612 },
    { lat: 49.105717, lng: 21.905477 },
    { lat: 49.105504, lng: 21.905264 },
    { lat: 49.105272, lng: 21.905034 },
    { lat: 49.105073, lng: 21.904714 },
    { lat: 49.104945, lng: 21.904365 },
    { lat: 49.104421, lng: 21.903846 },
    { lat: 49.104349, lng: 21.903759 },
    { lat: 49.103752, lng: 21.903272 },
    { lat: 49.103656, lng: 21.903251 },
    { lat: 49.103329, lng: 21.903124 },
    { lat: 49.102775, lng: 21.903364 },
    { lat: 49.102587, lng: 21.903154 },
    { lat: 49.102424, lng: 21.90258 },
    { lat: 49.102239, lng: 21.902302 },
    { lat: 49.101848, lng: 21.90205 },
    { lat: 49.101545, lng: 21.901528 },
    { lat: 49.1009, lng: 21.901017 },
    { lat: 49.10068, lng: 21.90073 },
    { lat: 49.1006, lng: 21.900283 },
    { lat: 49.100549, lng: 21.899641 },
    { lat: 49.100482, lng: 21.89906 },
    { lat: 49.100446, lng: 21.898627 },
    { lat: 49.100356, lng: 21.897998 },
    { lat: 49.100258, lng: 21.897374 },
    { lat: 49.100078, lng: 21.895783 },
    { lat: 49.100231, lng: 21.895098 },
    { lat: 49.0998688, lng: 21.8950358 },
    { lat: 49.0993881, lng: 21.8955097 },
    { lat: 49.0989115, lng: 21.8944043 },
    { lat: 49.0978768, lng: 21.8941848 },
    { lat: 49.0962513, lng: 21.8949854 },
    { lat: 49.0938374, lng: 21.8952925 },
    { lat: 49.0931146, lng: 21.8946082 },
    { lat: 49.0931458, lng: 21.8953006 },
    { lat: 49.0921233, lng: 21.8969976 },
    { lat: 49.0908421, lng: 21.8970006 },
    { lat: 49.089497, lng: 21.8973227 },
    { lat: 49.0887231, lng: 21.8976779 },
    { lat: 49.0876424, lng: 21.8995716 },
    { lat: 49.0869012, lng: 21.9027936 },
    { lat: 49.0866045, lng: 21.9015075 },
    { lat: 49.0860627, lng: 21.9011199 },
    { lat: 49.0857965, lng: 21.8992507 },
    { lat: 49.084034, lng: 21.8973342 },
    { lat: 49.0831139, lng: 21.8969343 },
    { lat: 49.0826253, lng: 21.897454 },
    { lat: 49.0825354, lng: 21.8975497 },
  ],
  KamenicanadCirochou: [
    { lat: 48.9568409, lng: 21.9739092 },
    { lat: 48.9511158, lng: 21.9744214 },
    { lat: 48.9506046, lng: 21.9744521 },
    { lat: 48.9501246, lng: 21.9751979 },
    { lat: 48.9500445, lng: 21.9751781 },
    { lat: 48.9498781, lng: 21.974824 },
    { lat: 48.949823, lng: 21.9745941 },
    { lat: 48.9494493, lng: 21.9741225 },
    { lat: 48.9491561, lng: 21.9736334 },
    { lat: 48.9491309, lng: 21.9735668 },
    { lat: 48.9477446, lng: 21.9745175 },
    { lat: 48.9469937, lng: 21.9750331 },
    { lat: 48.945944, lng: 21.9758587 },
    { lat: 48.9451633, lng: 21.9769496 },
    { lat: 48.9446667, lng: 21.9780762 },
    { lat: 48.9448717, lng: 21.9785438 },
    { lat: 48.9449511, lng: 21.9788886 },
    { lat: 48.9448358, lng: 21.9795997 },
    { lat: 48.9450813, lng: 21.9801222 },
    { lat: 48.9448559, lng: 21.9803293 },
    { lat: 48.9454402, lng: 21.9815412 },
    { lat: 48.9450643, lng: 21.9824102 },
    { lat: 48.9447927, lng: 21.9824738 },
    { lat: 48.9450752, lng: 21.9834232 },
    { lat: 48.945131, lng: 21.9836109 },
    { lat: 48.9448798, lng: 21.983595 },
    { lat: 48.9445606, lng: 21.9826497 },
    { lat: 48.9441302, lng: 21.9817487 },
    { lat: 48.9430893, lng: 21.9802191 },
    { lat: 48.942947, lng: 21.9799144 },
    { lat: 48.9426254, lng: 21.9796881 },
    { lat: 48.9423261, lng: 21.9791325 },
    { lat: 48.942241, lng: 21.9787412 },
    { lat: 48.9418909, lng: 21.9781803 },
    { lat: 48.941539, lng: 21.9771818 },
    { lat: 48.9409648, lng: 21.977941 },
    { lat: 48.9408244, lng: 21.9772126 },
    { lat: 48.9406457, lng: 21.9773565 },
    { lat: 48.9404295, lng: 21.9770274 },
    { lat: 48.9404112, lng: 21.977061 },
    { lat: 48.9400424, lng: 21.9766994 },
    { lat: 48.9400182, lng: 21.9769515 },
    { lat: 48.9399171, lng: 21.9772296 },
    { lat: 48.9395797, lng: 21.9777846 },
    { lat: 48.9394589, lng: 21.9778562 },
    { lat: 48.9391528, lng: 21.9775701 },
    { lat: 48.9388732, lng: 21.9776833 },
    { lat: 48.9385513, lng: 21.9775448 },
    { lat: 48.938395, lng: 21.9773139 },
    { lat: 48.9383491, lng: 21.9773726 },
    { lat: 48.9382565, lng: 21.9779496 },
    { lat: 48.9381486, lng: 21.9781815 },
    { lat: 48.9378686, lng: 21.9783677 },
    { lat: 48.9377588, lng: 21.9782934 },
    { lat: 48.9374312, lng: 21.9787437 },
    { lat: 48.937377, lng: 21.9790041 },
    { lat: 48.9372997, lng: 21.9790238 },
    { lat: 48.9372692, lng: 21.9791089 },
    { lat: 48.9371604, lng: 21.9789379 },
    { lat: 48.9370351, lng: 21.9789611 },
    { lat: 48.9369583, lng: 21.9793204 },
    { lat: 48.9367981, lng: 21.9796686 },
    { lat: 48.9367664, lng: 21.9798862 },
    { lat: 48.9368221, lng: 21.9801367 },
    { lat: 48.9366406, lng: 21.9805781 },
    { lat: 48.9364816, lng: 21.9806103 },
    { lat: 48.9363718, lng: 21.9805385 },
    { lat: 48.9360558, lng: 21.9805889 },
    { lat: 48.9360018, lng: 21.9807712 },
    { lat: 48.9357659, lng: 21.9810135 },
    { lat: 48.9355284, lng: 21.9810556 },
    { lat: 48.9353318, lng: 21.9812957 },
    { lat: 48.9351919, lng: 21.9815955 },
    { lat: 48.9348527, lng: 21.9817496 },
    { lat: 48.9348366, lng: 21.9818814 },
    { lat: 48.9347654, lng: 21.9819591 },
    { lat: 48.934837, lng: 21.9823494 },
    { lat: 48.934715, lng: 21.9827004 },
    { lat: 48.9341462, lng: 21.9832191 },
    { lat: 48.9339979, lng: 21.9835083 },
    { lat: 48.9340181, lng: 21.9838224 },
    { lat: 48.9337551, lng: 21.984293 },
    { lat: 48.9333146, lng: 21.9856845 },
    { lat: 48.9333628, lng: 21.9859573 },
    { lat: 48.9327362, lng: 21.9866004 },
    { lat: 48.932276, lng: 21.9869616 },
    { lat: 48.9321829, lng: 21.9869221 },
    { lat: 48.931967, lng: 21.9866041 },
    { lat: 48.9317539, lng: 21.9861718 },
    { lat: 48.9316821, lng: 21.9862234 },
    { lat: 48.9315111, lng: 21.9861797 },
    { lat: 48.9313662, lng: 21.9863256 },
    { lat: 48.9303899, lng: 21.986596 },
    { lat: 48.9302192, lng: 21.9863352 },
    { lat: 48.929885, lng: 21.9863963 },
    { lat: 48.9297163, lng: 21.9864272 },
    { lat: 48.9296226, lng: 21.9871061 },
    { lat: 48.9293465, lng: 21.9872176 },
    { lat: 48.929333, lng: 21.9875442 },
    { lat: 48.9287831, lng: 21.9876882 },
    { lat: 48.9287783, lng: 21.9878599 },
    { lat: 48.928583, lng: 21.9886024 },
    { lat: 48.9282605, lng: 21.9885752 },
    { lat: 48.9276221, lng: 21.9890629 },
    { lat: 48.9273665, lng: 21.9896922 },
    { lat: 48.9271254, lng: 21.9898607 },
    { lat: 48.9270891, lng: 21.9900337 },
    { lat: 48.9269753, lng: 21.9901124 },
    { lat: 48.9269315, lng: 21.9896616 },
    { lat: 48.9259737, lng: 21.9894065 },
    { lat: 48.9236688, lng: 21.9885251 },
    { lat: 48.9233516, lng: 21.987516 },
    { lat: 48.9231311, lng: 21.9872881 },
    { lat: 48.9225609, lng: 21.9871993 },
    { lat: 48.9223895, lng: 21.9872748 },
    { lat: 48.9220373, lng: 21.9871802 },
    { lat: 48.9211684, lng: 21.9864641 },
    { lat: 48.9209082, lng: 21.9860778 },
    { lat: 48.918263, lng: 21.9899587 },
    { lat: 48.9183359, lng: 21.9910987 },
    { lat: 48.9181169, lng: 21.9924111 },
    { lat: 48.9180363, lng: 21.9936159 },
    { lat: 48.9181582, lng: 21.9937722 },
    { lat: 48.9181762, lng: 21.9940487 },
    { lat: 48.918131, lng: 21.9953806 },
    { lat: 48.9180045, lng: 21.995685 },
    { lat: 48.9176231, lng: 21.9971041 },
    { lat: 48.9173865, lng: 21.9986617 },
    { lat: 48.9174268, lng: 22.0030857 },
    { lat: 48.91738, lng: 22.0053275 },
    { lat: 48.9161775, lng: 22.0056041 },
    { lat: 48.9160557, lng: 22.0072525 },
    { lat: 48.9157881, lng: 22.0082799 },
    { lat: 48.9155243, lng: 22.0089473 },
    { lat: 48.9150284, lng: 22.0104825 },
    { lat: 48.9146062, lng: 22.0124988 },
    { lat: 48.9147039, lng: 22.0126299 },
    { lat: 48.9146296, lng: 22.0128215 },
    { lat: 48.914254, lng: 22.0133335 },
    { lat: 48.9139006, lng: 22.0135521 },
    { lat: 48.9136273, lng: 22.0139274 },
    { lat: 48.9132425, lng: 22.014623 },
    { lat: 48.9126154, lng: 22.0149699 },
    { lat: 48.9125643, lng: 22.014903 },
    { lat: 48.9110105, lng: 22.0142305 },
    { lat: 48.908917, lng: 22.0150621 },
    { lat: 48.9061927, lng: 22.0180364 },
    { lat: 48.9049236, lng: 22.0203912 },
    { lat: 48.9042884, lng: 22.0244977 },
    { lat: 48.9043499, lng: 22.0248103 },
    { lat: 48.9047643, lng: 22.0268797 },
    { lat: 48.9052089, lng: 22.0265196 },
    { lat: 48.9073782, lng: 22.0244528 },
    { lat: 48.911282, lng: 22.0209847 },
    { lat: 48.9137565, lng: 22.0189398 },
    { lat: 48.9161767, lng: 22.0168261 },
    { lat: 48.9171672, lng: 22.0179686 },
    { lat: 48.9176244, lng: 22.0186845 },
    { lat: 48.9187966, lng: 22.0202737 },
    { lat: 48.9151276, lng: 22.0251194 },
    { lat: 48.916434, lng: 22.0273778 },
    { lat: 48.9178821, lng: 22.0317837 },
    { lat: 48.9175606, lng: 22.0327722 },
    { lat: 48.917311, lng: 22.0327231 },
    { lat: 48.9162998, lng: 22.0327745 },
    { lat: 48.9161855, lng: 22.0330272 },
    { lat: 48.9168755, lng: 22.0343534 },
    { lat: 48.9167515, lng: 22.0347622 },
    { lat: 48.9173378, lng: 22.0357576 },
    { lat: 48.917617, lng: 22.0375844 },
    { lat: 48.9174732, lng: 22.0381141 },
    { lat: 48.9166842, lng: 22.0394063 },
    { lat: 48.9154598, lng: 22.0406843 },
    { lat: 48.9153123, lng: 22.0422763 },
    { lat: 48.9157532, lng: 22.042135 },
    { lat: 48.917717, lng: 22.0418927 },
    { lat: 48.9197617, lng: 22.04146 },
    { lat: 48.9204458, lng: 22.0415631 },
    { lat: 48.9213514, lng: 22.0419469 },
    { lat: 48.9228883, lng: 22.0420079 },
    { lat: 48.9240252, lng: 22.0418577 },
    { lat: 48.9244936, lng: 22.0415922 },
    { lat: 48.9249296, lng: 22.0415316 },
    { lat: 48.9254004, lng: 22.041742 },
    { lat: 48.9255815, lng: 22.0417474 },
    { lat: 48.926042, lng: 22.0415753 },
    { lat: 48.9261666, lng: 22.0413759 },
    { lat: 48.926412, lng: 22.0413388 },
    { lat: 48.9264674, lng: 22.0412499 },
    { lat: 48.927106, lng: 22.0411065 },
    { lat: 48.9271517, lng: 22.0412231 },
    { lat: 48.9273264, lng: 22.0412439 },
    { lat: 48.9280196, lng: 22.0407337 },
    { lat: 48.9280377, lng: 22.0406053 },
    { lat: 48.9282282, lng: 22.0404796 },
    { lat: 48.9282776, lng: 22.0403778 },
    { lat: 48.92845, lng: 22.0404899 },
    { lat: 48.9287598, lng: 22.0403139 },
    { lat: 48.9290612, lng: 22.0403613 },
    { lat: 48.9292957, lng: 22.0402911 },
    { lat: 48.9294286, lng: 22.0401639 },
    { lat: 48.9294485, lng: 22.0400508 },
    { lat: 48.9298343, lng: 22.0398199 },
    { lat: 48.9297639, lng: 22.0397514 },
    { lat: 48.930398, lng: 22.039031 },
    { lat: 48.9305009, lng: 22.0387749 },
    { lat: 48.9307855, lng: 22.0384937 },
    { lat: 48.9308114, lng: 22.0383563 },
    { lat: 48.9309479, lng: 22.0381305 },
    { lat: 48.9310799, lng: 22.0381998 },
    { lat: 48.9312934, lng: 22.0380513 },
    { lat: 48.9313821, lng: 22.0377573 },
    { lat: 48.931776, lng: 22.0372522 },
    { lat: 48.9319694, lng: 22.036785 },
    { lat: 48.9323583, lng: 22.0363911 },
    { lat: 48.9325585, lng: 22.0362977 },
    { lat: 48.9326085, lng: 22.0361826 },
    { lat: 48.9327883, lng: 22.0360328 },
    { lat: 48.9328674, lng: 22.0360978 },
    { lat: 48.9329845, lng: 22.0360562 },
    { lat: 48.933148, lng: 22.0358382 },
    { lat: 48.9331613, lng: 22.0356955 },
    { lat: 48.9333533, lng: 22.0354856 },
    { lat: 48.9333807, lng: 22.0352712 },
    { lat: 48.933464, lng: 22.0350387 },
    { lat: 48.9335627, lng: 22.0349409 },
    { lat: 48.933586, lng: 22.0347826 },
    { lat: 48.9338871, lng: 22.0343931 },
    { lat: 48.934073, lng: 22.0343602 },
    { lat: 48.9342565, lng: 22.0338989 },
    { lat: 48.9345228, lng: 22.0336981 },
    { lat: 48.9346492, lng: 22.0333387 },
    { lat: 48.9346061, lng: 22.0331468 },
    { lat: 48.9348788, lng: 22.0327425 },
    { lat: 48.9348763, lng: 22.0326399 },
    { lat: 48.9349595, lng: 22.0324514 },
    { lat: 48.9350829, lng: 22.032339 },
    { lat: 48.9352021, lng: 22.0324362 },
    { lat: 48.9352786, lng: 22.0323418 },
    { lat: 48.9352952, lng: 22.0321917 },
    { lat: 48.9354138, lng: 22.0319765 },
    { lat: 48.935425, lng: 22.0316979 },
    { lat: 48.9359728, lng: 22.0312786 },
    { lat: 48.9360069, lng: 22.0311994 },
    { lat: 48.9361521, lng: 22.0311585 },
    { lat: 48.9361225, lng: 22.0309977 },
    { lat: 48.9361623, lng: 22.0309253 },
    { lat: 48.9363141, lng: 22.0309173 },
    { lat: 48.9363174, lng: 22.0307797 },
    { lat: 48.9363732, lng: 22.0307587 },
    { lat: 48.9363462, lng: 22.030576 },
    { lat: 48.9364957, lng: 22.0303326 },
    { lat: 48.9365268, lng: 22.0301269 },
    { lat: 48.9365977, lng: 22.0301377 },
    { lat: 48.9367217, lng: 22.0298141 },
    { lat: 48.9368429, lng: 22.0296697 },
    { lat: 48.9368643, lng: 22.029432 },
    { lat: 48.9369361, lng: 22.0293307 },
    { lat: 48.9369442, lng: 22.0290136 },
    { lat: 48.9372661, lng: 22.0286973 },
    { lat: 48.9372855, lng: 22.0286222 },
    { lat: 48.9373558, lng: 22.0287303 },
    { lat: 48.9377342, lng: 22.0280033 },
    { lat: 48.9376523, lng: 22.0279685 },
    { lat: 48.9377663, lng: 22.0277024 },
    { lat: 48.9377258, lng: 22.0276163 },
    { lat: 48.9376734, lng: 22.0276811 },
    { lat: 48.9376361, lng: 22.0276174 },
    { lat: 48.9377016, lng: 22.0273558 },
    { lat: 48.9377413, lng: 22.0273668 },
    { lat: 48.9377368, lng: 22.0272683 },
    { lat: 48.9376246, lng: 22.0271439 },
    { lat: 48.9375616, lng: 22.0271489 },
    { lat: 48.9376174, lng: 22.0270295 },
    { lat: 48.9376774, lng: 22.0270541 },
    { lat: 48.9376503, lng: 22.0269082 },
    { lat: 48.9378281, lng: 22.0267265 },
    { lat: 48.9378078, lng: 22.0266271 },
    { lat: 48.9376953, lng: 22.0265754 },
    { lat: 48.9375593, lng: 22.0259776 },
    { lat: 48.9376521, lng: 22.0260436 },
    { lat: 48.9376838, lng: 22.0258744 },
    { lat: 48.937621, lng: 22.0257416 },
    { lat: 48.937794, lng: 22.0253534 },
    { lat: 48.9377141, lng: 22.0253525 },
    { lat: 48.9378572, lng: 22.0251764 },
    { lat: 48.93788, lng: 22.0250492 },
    { lat: 48.938, lng: 22.0248522 },
    { lat: 48.9379158, lng: 22.0247159 },
    { lat: 48.9378851, lng: 22.0245183 },
    { lat: 48.9379774, lng: 22.0242413 },
    { lat: 48.9380466, lng: 22.0242671 },
    { lat: 48.9383114, lng: 22.024144 },
    { lat: 48.9384539, lng: 22.0233875 },
    { lat: 48.9386746, lng: 22.0231663 },
    { lat: 48.9387161, lng: 22.0230609 },
    { lat: 48.9388227, lng: 22.0230507 },
    { lat: 48.9389863, lng: 22.0228877 },
    { lat: 48.939095, lng: 22.0228677 },
    { lat: 48.9392982, lng: 22.0226246 },
    { lat: 48.9394731, lng: 22.0227347 },
    { lat: 48.9396386, lng: 22.0226979 },
    { lat: 48.9397075, lng: 22.0224261 },
    { lat: 48.939994, lng: 22.0223232 },
    { lat: 48.9399643, lng: 22.0221685 },
    { lat: 48.9400099, lng: 22.0220933 },
    { lat: 48.9402393, lng: 22.0218616 },
    { lat: 48.9403866, lng: 22.0218115 },
    { lat: 48.9405076, lng: 22.021652 },
    { lat: 48.9407612, lng: 22.0215341 },
    { lat: 48.9409939, lng: 22.0212016 },
    { lat: 48.9412607, lng: 22.0212608 },
    { lat: 48.9413956, lng: 22.021117 },
    { lat: 48.9419404, lng: 22.0208483 },
    { lat: 48.9429412, lng: 22.0209854 },
    { lat: 48.9431126, lng: 22.0205755 },
    { lat: 48.9431162, lng: 22.0204543 },
    { lat: 48.9432285, lng: 22.0202999 },
    { lat: 48.9433305, lng: 22.0201891 },
    { lat: 48.9435094, lng: 22.0203383 },
    { lat: 48.9436735, lng: 22.0201445 },
    { lat: 48.9436916, lng: 22.0200054 },
    { lat: 48.9437571, lng: 22.0199273 },
    { lat: 48.9437496, lng: 22.019712 },
    { lat: 48.9438082, lng: 22.0196701 },
    { lat: 48.9438517, lng: 22.0195116 },
    { lat: 48.9439924, lng: 22.0194222 },
    { lat: 48.944028, lng: 22.0195505 },
    { lat: 48.9441277, lng: 22.0195295 },
    { lat: 48.9441215, lng: 22.019225 },
    { lat: 48.9445007, lng: 22.0189487 },
    { lat: 48.9450364, lng: 22.018683 },
    { lat: 48.9450926, lng: 22.018541 },
    { lat: 48.9452824, lng: 22.0184868 },
    { lat: 48.9453318, lng: 22.0185316 },
    { lat: 48.9461542, lng: 22.0181111 },
    { lat: 48.9463476, lng: 22.0181164 },
    { lat: 48.947424, lng: 22.0163906 },
    { lat: 48.9486574, lng: 22.0184636 },
    { lat: 48.9492576, lng: 22.0185357 },
    { lat: 48.9487179, lng: 22.0216585 },
    { lat: 48.9487364, lng: 22.0216611 },
    { lat: 48.9504606, lng: 22.0219078 },
    { lat: 48.9511183, lng: 22.0217062 },
    { lat: 48.951522, lng: 22.021689 },
    { lat: 48.951636, lng: 22.021765 },
    { lat: 48.951685, lng: 22.021844 },
    { lat: 48.951816, lng: 22.021979 },
    { lat: 48.952137, lng: 22.021724 },
    { lat: 48.952095, lng: 22.021449 },
    { lat: 48.952167, lng: 22.021366 },
    { lat: 48.952414, lng: 22.021235 },
    { lat: 48.952593, lng: 22.021598 },
    { lat: 48.95276, lng: 22.021851 },
    { lat: 48.952822, lng: 22.021977 },
    { lat: 48.952892, lng: 22.022162 },
    { lat: 48.952744, lng: 22.022278 },
    { lat: 48.952791, lng: 22.022497 },
    { lat: 48.952812, lng: 22.022673 },
    { lat: 48.952982, lng: 22.02292 },
    { lat: 48.953476, lng: 22.022499 },
    { lat: 48.953857, lng: 22.02173 },
    { lat: 48.953984, lng: 22.021675 },
    { lat: 48.954088, lng: 22.021367 },
    { lat: 48.954031, lng: 22.020558 },
    { lat: 48.953854, lng: 22.020298 },
    { lat: 48.953463, lng: 22.019995 },
    { lat: 48.953278, lng: 22.019543 },
    { lat: 48.953262, lng: 22.019465 },
    { lat: 48.953254, lng: 22.019426 },
    { lat: 48.954455, lng: 22.018379 },
    { lat: 48.954544, lng: 22.018319 },
    { lat: 48.955758, lng: 22.01727 },
    { lat: 48.956048, lng: 22.01702 },
    { lat: 48.956257, lng: 22.016858 },
    { lat: 48.956347, lng: 22.016791 },
    { lat: 48.956399, lng: 22.016783 },
    { lat: 48.956424, lng: 22.01678 },
    { lat: 48.956546, lng: 22.016762 },
    { lat: 48.95666, lng: 22.016774 },
    { lat: 48.956712, lng: 22.01678 },
    { lat: 48.956846, lng: 22.016796 },
    { lat: 48.956864, lng: 22.016723 },
    { lat: 48.956958, lng: 22.016605 },
    { lat: 48.95698, lng: 22.016489 },
    { lat: 48.957153, lng: 22.016536 },
    { lat: 48.957159, lng: 22.016473 },
    { lat: 48.957553, lng: 22.016169 },
    { lat: 48.957638, lng: 22.016226 },
    { lat: 48.957935, lng: 22.016304 },
    { lat: 48.957981, lng: 22.016351 },
    { lat: 48.958237, lng: 22.016298 },
    { lat: 48.95831, lng: 22.016393 },
    { lat: 48.95844, lng: 22.016394 },
    { lat: 48.958636, lng: 22.016563 },
    { lat: 48.958766, lng: 22.016741 },
    { lat: 48.958912, lng: 22.016628 },
    { lat: 48.959065, lng: 22.016382 },
    { lat: 48.959161, lng: 22.016453 },
    { lat: 48.959493, lng: 22.016388 },
    { lat: 48.959646, lng: 22.016402 },
    { lat: 48.959676, lng: 22.016485 },
    { lat: 48.959765, lng: 22.016453 },
    { lat: 48.959865, lng: 22.016417 },
    { lat: 48.959896, lng: 22.016471 },
    { lat: 48.960069, lng: 22.016619 },
    { lat: 48.960148, lng: 22.017042 },
    { lat: 48.960203, lng: 22.017212 },
    { lat: 48.961008, lng: 22.016597 },
    { lat: 48.961359, lng: 22.016364 },
    { lat: 48.961817, lng: 22.016195 },
    { lat: 48.962262, lng: 22.016122 },
    { lat: 48.962374, lng: 22.016215 },
    { lat: 48.962379, lng: 22.016218 },
    { lat: 48.96227, lng: 22.0160426 },
    { lat: 48.9622888, lng: 22.0159338 },
    { lat: 48.9625435, lng: 22.0157703 },
    { lat: 48.9627648, lng: 22.0150106 },
    { lat: 48.9628551, lng: 22.0139603 },
    { lat: 48.9625943, lng: 22.0129954 },
    { lat: 48.9625044, lng: 22.0119777 },
    { lat: 48.9622003, lng: 22.0111704 },
    { lat: 48.9620497, lng: 22.0105674 },
    { lat: 48.9619052, lng: 22.0093416 },
    { lat: 48.9614232, lng: 22.0065865 },
    { lat: 48.9610838, lng: 22.0053044 },
    { lat: 48.9607502, lng: 22.0043891 },
    { lat: 48.9606192, lng: 22.0036355 },
    { lat: 48.9603877, lng: 22.0034104 },
    { lat: 48.960303, lng: 22.003253 },
    { lat: 48.9599722, lng: 22.002143 },
    { lat: 48.9596526, lng: 22.0016311 },
    { lat: 48.9593366, lng: 22.0013059 },
    { lat: 48.9591212, lng: 22.0009583 },
    { lat: 48.9590778, lng: 22.0006576 },
    { lat: 48.9589675, lng: 22.0004236 },
    { lat: 48.959249, lng: 22.0000706 },
    { lat: 48.9596019, lng: 21.9992193 },
    { lat: 48.959804, lng: 21.9990211 },
    { lat: 48.9600166, lng: 21.9985667 },
    { lat: 48.9594806, lng: 21.9963192 },
    { lat: 48.9594815, lng: 21.9961746 },
    { lat: 48.9596224, lng: 21.9960456 },
    { lat: 48.9596022, lng: 21.9959627 },
    { lat: 48.959834, lng: 21.9958193 },
    { lat: 48.9597878, lng: 21.9955875 },
    { lat: 48.960107, lng: 21.9954371 },
    { lat: 48.9596639, lng: 21.9909734 },
    { lat: 48.9598623, lng: 21.9908083 },
    { lat: 48.9601654, lng: 21.9907286 },
    { lat: 48.9602127, lng: 21.9910183 },
    { lat: 48.960333, lng: 21.9910588 },
    { lat: 48.9605194, lng: 21.9909524 },
    { lat: 48.9610221, lng: 21.9910329 },
    { lat: 48.9606218, lng: 21.9871351 },
    { lat: 48.9593682, lng: 21.9751269 },
    { lat: 48.9590447, lng: 21.9752 },
    { lat: 48.9573435, lng: 21.9758697 },
    { lat: 48.9568409, lng: 21.9739092 },
  ],
  Závada: [
    { lat: 49.181898, lng: 21.764636 },
    { lat: 49.181892, lng: 21.764706 },
    { lat: 49.181201, lng: 21.764383 },
    { lat: 49.180573, lng: 21.76361 },
    { lat: 49.180107, lng: 21.762679 },
    { lat: 49.179721, lng: 21.762801 },
    { lat: 49.179241, lng: 21.762792 },
    { lat: 49.179219, lng: 21.762648 },
    { lat: 49.176832, lng: 21.762729 },
    { lat: 49.176494, lng: 21.762844 },
    { lat: 49.176009, lng: 21.763109 },
    { lat: 49.175923, lng: 21.763154 },
    { lat: 49.175683, lng: 21.76328 },
    { lat: 49.175435, lng: 21.763376 },
    { lat: 49.173915, lng: 21.764465 },
    { lat: 49.173478, lng: 21.764651 },
    { lat: 49.173175, lng: 21.764571 },
    { lat: 49.172988, lng: 21.764807 },
    { lat: 49.172721, lng: 21.764745 },
    { lat: 49.172583, lng: 21.764831 },
    { lat: 49.172162, lng: 21.764823 },
    { lat: 49.171876, lng: 21.764779 },
    { lat: 49.171766, lng: 21.764838 },
    { lat: 49.170647, lng: 21.765 },
    { lat: 49.170226, lng: 21.765304 },
    { lat: 49.170225, lng: 21.765305 },
    { lat: 49.16992, lng: 21.765344 },
    { lat: 49.169901, lng: 21.765408 },
    { lat: 49.169625, lng: 21.765632 },
    { lat: 49.169188, lng: 21.765692 },
    { lat: 49.168773, lng: 21.765848 },
    { lat: 49.168492, lng: 21.766061 },
    { lat: 49.168305, lng: 21.765942 },
    { lat: 49.167916, lng: 21.76618 },
    { lat: 49.167625, lng: 21.766296 },
    { lat: 49.1674254, lng: 21.7667282 },
    { lat: 49.1671569, lng: 21.7670879 },
    { lat: 49.1654215, lng: 21.7711762 },
    { lat: 49.1644351, lng: 21.7736287 },
    { lat: 49.1627753, lng: 21.7760539 },
    { lat: 49.1620983, lng: 21.777605 },
    { lat: 49.1613519, lng: 21.7790877 },
    { lat: 49.1611338, lng: 21.779437 },
    { lat: 49.1608998, lng: 21.7796561 },
    { lat: 49.1601807, lng: 21.7809754 },
    { lat: 49.1595364, lng: 21.7823192 },
    { lat: 49.1594188, lng: 21.7827256 },
    { lat: 49.1594492, lng: 21.7831601 },
    { lat: 49.159284, lng: 21.7837739 },
    { lat: 49.1592227, lng: 21.785781 },
    { lat: 49.1594648, lng: 21.7869901 },
    { lat: 49.1593811, lng: 21.7881381 },
    { lat: 49.1594414, lng: 21.7885531 },
    { lat: 49.1593122, lng: 21.7892496 },
    { lat: 49.159133, lng: 21.7897999 },
    { lat: 49.1592576, lng: 21.7907376 },
    { lat: 49.15923, lng: 21.7921032 },
    { lat: 49.1591276, lng: 21.7931782 },
    { lat: 49.1593719, lng: 21.7944846 },
    { lat: 49.1596297, lng: 21.7963491 },
    { lat: 49.1596754, lng: 21.7971133 },
    { lat: 49.1598573, lng: 21.798315 },
    { lat: 49.1600184, lng: 21.7988914 },
    { lat: 49.1602127, lng: 21.7999565 },
    { lat: 49.1604099, lng: 21.801751 },
    { lat: 49.1605652, lng: 21.8024874 },
    { lat: 49.1606552, lng: 21.8031861 },
    { lat: 49.160692, lng: 21.803695 },
    { lat: 49.161385, lng: 21.804188 },
    { lat: 49.161717, lng: 21.804254 },
    { lat: 49.161944, lng: 21.804421 },
    { lat: 49.162306, lng: 21.804494 },
    { lat: 49.162581, lng: 21.804526 },
    { lat: 49.163154, lng: 21.804452 },
    { lat: 49.164216, lng: 21.804171 },
    { lat: 49.164709, lng: 21.803916 },
    { lat: 49.165147, lng: 21.803607 },
    { lat: 49.166052, lng: 21.803187 },
    { lat: 49.166511, lng: 21.803018 },
    { lat: 49.166562, lng: 21.803045 },
    { lat: 49.166903, lng: 21.803222 },
    { lat: 49.167278, lng: 21.803485 },
    { lat: 49.167519, lng: 21.803558 },
    { lat: 49.167579, lng: 21.803576 },
    { lat: 49.167726, lng: 21.803621 },
    { lat: 49.168194, lng: 21.803339 },
    { lat: 49.168737, lng: 21.803124 },
    { lat: 49.169316, lng: 21.803198 },
    { lat: 49.170043, lng: 21.803424 },
    { lat: 49.170442, lng: 21.803578 },
    { lat: 49.171254, lng: 21.803506 },
    { lat: 49.171436, lng: 21.803504 },
    { lat: 49.171797, lng: 21.803628 },
    { lat: 49.172269, lng: 21.8037 },
    { lat: 49.172916, lng: 21.803949 },
    { lat: 49.173267, lng: 21.804047 },
    { lat: 49.174258, lng: 21.804756 },
    { lat: 49.175165, lng: 21.805387 },
    { lat: 49.175681, lng: 21.805135 },
    { lat: 49.178263, lng: 21.803298 },
    { lat: 49.178397, lng: 21.803203 },
    { lat: 49.179002, lng: 21.802394 },
    { lat: 49.179463, lng: 21.802088 },
    { lat: 49.180226, lng: 21.801561 },
    { lat: 49.180872, lng: 21.801073 },
    { lat: 49.181466, lng: 21.800703 },
    { lat: 49.181933, lng: 21.800376 },
    { lat: 49.182156, lng: 21.799997 },
    { lat: 49.182677, lng: 21.799746 },
    { lat: 49.183126, lng: 21.799443 },
    { lat: 49.183804, lng: 21.798947 },
    { lat: 49.1845, lng: 21.798737 },
    { lat: 49.18489, lng: 21.798536 },
    { lat: 49.185292, lng: 21.798211 },
    { lat: 49.185645, lng: 21.79814 },
    { lat: 49.186077, lng: 21.79782 },
    { lat: 49.186402, lng: 21.797829 },
    { lat: 49.186729, lng: 21.797484 },
    { lat: 49.187418, lng: 21.796811 },
    { lat: 49.187794, lng: 21.796309 },
    { lat: 49.188602, lng: 21.795463 },
    { lat: 49.188917, lng: 21.794952 },
    { lat: 49.189282, lng: 21.794448 },
    { lat: 49.189435, lng: 21.794406 },
    { lat: 49.189796, lng: 21.794144 },
    { lat: 49.190306, lng: 21.793408 },
    { lat: 49.190917, lng: 21.792965 },
    { lat: 49.191496, lng: 21.792457 },
    { lat: 49.191973, lng: 21.792278 },
    { lat: 49.192599, lng: 21.792355 },
    { lat: 49.193105, lng: 21.792218 },
    { lat: 49.19331, lng: 21.792131 },
    { lat: 49.194151, lng: 21.791816 },
    { lat: 49.194689, lng: 21.791493 },
    { lat: 49.195165, lng: 21.791163 },
    { lat: 49.195825, lng: 21.790873 },
    { lat: 49.196374, lng: 21.790557 },
    { lat: 49.196839, lng: 21.79025 },
    { lat: 49.197237, lng: 21.790103 },
    { lat: 49.197475, lng: 21.790088 },
    { lat: 49.197647, lng: 21.78983 },
    { lat: 49.197932, lng: 21.789685 },
    { lat: 49.198335, lng: 21.78942 },
    { lat: 49.198078, lng: 21.788331 },
    { lat: 49.197852, lng: 21.787785 },
    { lat: 49.197246, lng: 21.787677 },
    { lat: 49.196721, lng: 21.787816 },
    { lat: 49.196515, lng: 21.787928 },
    { lat: 49.195692, lng: 21.788149 },
    { lat: 49.195112, lng: 21.788235 },
    { lat: 49.193695, lng: 21.788258 },
    { lat: 49.193529, lng: 21.788037 },
    { lat: 49.193605, lng: 21.787869 },
    { lat: 49.193786, lng: 21.787117 },
    { lat: 49.193801, lng: 21.786956 },
    { lat: 49.194007, lng: 21.786381 },
    { lat: 49.194134, lng: 21.786261 },
    { lat: 49.19406, lng: 21.78565 },
    { lat: 49.194088, lng: 21.785417 },
    { lat: 49.194111, lng: 21.784513 },
    { lat: 49.194092, lng: 21.784301 },
    { lat: 49.193933, lng: 21.784063 },
    { lat: 49.193815, lng: 21.783693 },
    { lat: 49.193757, lng: 21.783148 },
    { lat: 49.193742, lng: 21.782826 },
    { lat: 49.193688, lng: 21.782579 },
    { lat: 49.193174, lng: 21.781862 },
    { lat: 49.19311, lng: 21.781703 },
    { lat: 49.192739, lng: 21.781509 },
    { lat: 49.192596, lng: 21.781454 },
    { lat: 49.192415, lng: 21.781495 },
    { lat: 49.192217, lng: 21.781583 },
    { lat: 49.192122, lng: 21.78158 },
    { lat: 49.191968, lng: 21.781467 },
    { lat: 49.191823, lng: 21.781312 },
    { lat: 49.19163, lng: 21.781241 },
    { lat: 49.191138, lng: 21.781214 },
    { lat: 49.191445, lng: 21.780474 },
    { lat: 49.191347, lng: 21.779185 },
    { lat: 49.19178, lng: 21.778229 },
    { lat: 49.192075, lng: 21.777535 },
    { lat: 49.192262, lng: 21.776652 },
    { lat: 49.192406, lng: 21.776064 },
    { lat: 49.192984, lng: 21.774965 },
    { lat: 49.193004, lng: 21.774426 },
    { lat: 49.192707, lng: 21.773643 },
    { lat: 49.192227, lng: 21.773195 },
    { lat: 49.192005, lng: 21.773028 },
    { lat: 49.19164, lng: 21.772667 },
    { lat: 49.191344, lng: 21.772331 },
    { lat: 49.19094, lng: 21.771908 },
    { lat: 49.190511, lng: 21.771492 },
    { lat: 49.190227, lng: 21.770659 },
    { lat: 49.189339, lng: 21.7697 },
    { lat: 49.18906, lng: 21.76954 },
    { lat: 49.18886, lng: 21.769335 },
    { lat: 49.188688, lng: 21.769081 },
    { lat: 49.188221, lng: 21.768631 },
    { lat: 49.18749, lng: 21.768267 },
    { lat: 49.187177, lng: 21.768056 },
    { lat: 49.186873, lng: 21.767905 },
    { lat: 49.185976, lng: 21.767245 },
    { lat: 49.185614, lng: 21.767061 },
    { lat: 49.18486, lng: 21.766575 },
    { lat: 49.184614, lng: 21.766221 },
    { lat: 49.184177, lng: 21.765951 },
    { lat: 49.183577, lng: 21.765812 },
    { lat: 49.182809, lng: 21.765083 },
    { lat: 49.182563, lng: 21.76505 },
    { lat: 49.182181, lng: 21.764808 },
    { lat: 49.181898, lng: 21.764636 },
  ],
  Ohradzany: [
    { lat: 48.998598, lng: 21.8126391 },
    { lat: 48.9985387, lng: 21.8128683 },
    { lat: 48.9978993, lng: 21.8135819 },
    { lat: 48.9976679, lng: 21.8137674 },
    { lat: 48.9972436, lng: 21.8138964 },
    { lat: 48.9970637, lng: 21.813857 },
    { lat: 48.9969014, lng: 21.813924 },
    { lat: 48.9964612, lng: 21.8157409 },
    { lat: 48.9956657, lng: 21.8167256 },
    { lat: 48.9938333, lng: 21.8184262 },
    { lat: 48.9931584, lng: 21.8186442 },
    { lat: 48.9920391, lng: 21.8193405 },
    { lat: 48.9908261, lng: 21.8202014 },
    { lat: 48.9907917, lng: 21.8210023 },
    { lat: 48.989396, lng: 21.8219246 },
    { lat: 48.9892485, lng: 21.8218804 },
    { lat: 48.9885322, lng: 21.8221019 },
    { lat: 48.9884343, lng: 21.8224559 },
    { lat: 48.9874719, lng: 21.8220853 },
    { lat: 48.9866333, lng: 21.821933 },
    { lat: 48.9860865, lng: 21.8219274 },
    { lat: 48.9851949, lng: 21.8215963 },
    { lat: 48.9853691, lng: 21.8218055 },
    { lat: 48.9853102, lng: 21.8227105 },
    { lat: 48.985228, lng: 21.8232673 },
    { lat: 48.9850773, lng: 21.8237491 },
    { lat: 48.9847827, lng: 21.8242724 },
    { lat: 48.9848086, lng: 21.8247078 },
    { lat: 48.9852855, lng: 21.8270632 },
    { lat: 48.9855516, lng: 21.8280843 },
    { lat: 48.9858702, lng: 21.8290929 },
    { lat: 48.9863314, lng: 21.8301023 },
    { lat: 48.9868175, lng: 21.8315021 },
    { lat: 48.9867764, lng: 21.8334411 },
    { lat: 48.9869001, lng: 21.8340971 },
    { lat: 48.9870709, lng: 21.8357962 },
    { lat: 48.9867936, lng: 21.8361683 },
    { lat: 48.9871725, lng: 21.8369044 },
    { lat: 48.9882611, lng: 21.8406905 },
    { lat: 48.9884914, lng: 21.8428256 },
    { lat: 48.9890111, lng: 21.8467251 },
    { lat: 48.9892461, lng: 21.8478652 },
    { lat: 48.9894834, lng: 21.8486883 },
    { lat: 48.9899277, lng: 21.8499196 },
    { lat: 48.9901785, lng: 21.8504459 },
    { lat: 48.9907688, lng: 21.851439 },
    { lat: 48.9909058, lng: 21.8522247 },
    { lat: 48.9909971, lng: 21.8524937 },
    { lat: 48.9916076, lng: 21.8531389 },
    { lat: 48.9920721, lng: 21.8548946 },
    { lat: 48.9925058, lng: 21.8560611 },
    { lat: 48.9929043, lng: 21.8564283 },
    { lat: 48.9929888, lng: 21.8572254 },
    { lat: 48.9928401, lng: 21.8578342 },
    { lat: 48.99282, lng: 21.8583658 },
    { lat: 48.993963, lng: 21.85846 },
    { lat: 48.9946374, lng: 21.8592348 },
    { lat: 48.9953123, lng: 21.8616069 },
    { lat: 48.9964706, lng: 21.8653029 },
    { lat: 48.9967354, lng: 21.8654102 },
    { lat: 48.9978861, lng: 21.8649129 },
    { lat: 48.9980825, lng: 21.8634487 },
    { lat: 48.9993825, lng: 21.8636032 },
    { lat: 48.9997442, lng: 21.8642457 },
    { lat: 48.9998946, lng: 21.8648914 },
    { lat: 48.9999355, lng: 21.8660182 },
    { lat: 49.0000914, lng: 21.8665676 },
    { lat: 49.0015241, lng: 21.8680095 },
    { lat: 49.00181, lng: 21.8698782 },
    { lat: 49.0014712, lng: 21.8704875 },
    { lat: 49.0007121, lng: 21.8721722 },
    { lat: 49.0012, lng: 21.874421 },
    { lat: 49.0014108, lng: 21.8756304 },
    { lat: 49.0012512, lng: 21.8757701 },
    { lat: 49.0013766, lng: 21.8766806 },
    { lat: 49.0013249, lng: 21.8772071 },
    { lat: 49.0021653, lng: 21.8766792 },
    { lat: 49.0022711, lng: 21.8765587 },
    { lat: 49.0028882, lng: 21.8762674 },
    { lat: 49.0031669, lng: 21.8760036 },
    { lat: 49.0034385, lng: 21.8757464 },
    { lat: 49.0035451, lng: 21.875599 },
    { lat: 49.0036039, lng: 21.8753505 },
    { lat: 49.0045119, lng: 21.874518 },
    { lat: 49.0051338, lng: 21.8742869 },
    { lat: 49.0053181, lng: 21.8741012 },
    { lat: 49.0055173, lng: 21.8733142 },
    { lat: 49.0058266, lng: 21.872779 },
    { lat: 49.0059624, lng: 21.8722787 },
    { lat: 49.0061397, lng: 21.8712474 },
    { lat: 49.0060935, lng: 21.8706504 },
    { lat: 49.0060741, lng: 21.8703968 },
    { lat: 49.0062853, lng: 21.8698484 },
    { lat: 49.0071693, lng: 21.8686907 },
    { lat: 49.0070987, lng: 21.8684394 },
    { lat: 49.0075663, lng: 21.8678526 },
    { lat: 49.0070704, lng: 21.8660031 },
    { lat: 49.0071653, lng: 21.8656668 },
    { lat: 49.0069696, lng: 21.8646934 },
    { lat: 49.0071046, lng: 21.8645593 },
    { lat: 49.0068552, lng: 21.8636069 },
    { lat: 49.007343, lng: 21.8634542 },
    { lat: 49.0072406, lng: 21.862887 },
    { lat: 49.0070431, lng: 21.8623715 },
    { lat: 49.0069233, lng: 21.862222 },
    { lat: 49.0070128, lng: 21.8615822 },
    { lat: 49.0073041, lng: 21.8607477 },
    { lat: 49.0078443, lng: 21.8600504 },
    { lat: 49.0081188, lng: 21.8598279 },
    { lat: 49.0083759, lng: 21.8591029 },
    { lat: 49.0087574, lng: 21.8590241 },
    { lat: 49.0089527, lng: 21.8594909 },
    { lat: 49.0089928, lng: 21.8597398 },
    { lat: 49.0092988, lng: 21.8594748 },
    { lat: 49.0094138, lng: 21.8592624 },
    { lat: 49.0101306, lng: 21.8589176 },
    { lat: 49.0103459, lng: 21.8584158 },
    { lat: 49.0105825, lng: 21.8583857 },
    { lat: 49.010986, lng: 21.8585468 },
    { lat: 49.0116589, lng: 21.8581574 },
    { lat: 49.011767, lng: 21.8583828 },
    { lat: 49.012929, lng: 21.8571887 },
    { lat: 49.0141961, lng: 21.8562427 },
    { lat: 49.0145983, lng: 21.8560075 },
    { lat: 49.014823, lng: 21.8566095 },
    { lat: 49.0151629, lng: 21.8564801 },
    { lat: 49.0156538, lng: 21.8561895 },
    { lat: 49.017083, lng: 21.8550289 },
    { lat: 49.0171932, lng: 21.8552442 },
    { lat: 49.0175617, lng: 21.8556242 },
    { lat: 49.0175451, lng: 21.8557678 },
    { lat: 49.0174197, lng: 21.8560544 },
    { lat: 49.0174192, lng: 21.8563867 },
    { lat: 49.0175221, lng: 21.8564899 },
    { lat: 49.0178419, lng: 21.8563334 },
    { lat: 49.0179622, lng: 21.8561499 },
    { lat: 49.0182035, lng: 21.8560017 },
    { lat: 49.0184139, lng: 21.8559957 },
    { lat: 49.0190592, lng: 21.855678 },
    { lat: 49.0192313, lng: 21.8557635 },
    { lat: 49.019368, lng: 21.8556992 },
    { lat: 49.0196793, lng: 21.8552901 },
    { lat: 49.0197327, lng: 21.8554031 },
    { lat: 49.0198263, lng: 21.8552295 },
    { lat: 49.0201981, lng: 21.8551208 },
    { lat: 49.0203885, lng: 21.8551375 },
    { lat: 49.0205046, lng: 21.8552794 },
    { lat: 49.0210825, lng: 21.8552678 },
    { lat: 49.0211843, lng: 21.855332 },
    { lat: 49.0215, lng: 21.8551865 },
    { lat: 49.021658, lng: 21.8554187 },
    { lat: 49.0218555, lng: 21.8550952 },
    { lat: 49.022065, lng: 21.8553699 },
    { lat: 49.0222192, lng: 21.8554641 },
    { lat: 49.0225266, lng: 21.8557868 },
    { lat: 49.022556, lng: 21.8556098 },
    { lat: 49.0224257, lng: 21.8532078 },
    { lat: 49.0225149, lng: 21.852685 },
    { lat: 49.0224113, lng: 21.8510112 },
    { lat: 49.0222375, lng: 21.8493613 },
    { lat: 49.0215865, lng: 21.8488471 },
    { lat: 49.020249, lng: 21.8481049 },
    { lat: 49.0198801, lng: 21.8471357 },
    { lat: 49.0196433, lng: 21.8462874 },
    { lat: 49.0195055, lng: 21.8452975 },
    { lat: 49.0190333, lng: 21.8452678 },
    { lat: 49.0189491, lng: 21.8445671 },
    { lat: 49.0186353, lng: 21.8443538 },
    { lat: 49.0185253, lng: 21.8440528 },
    { lat: 49.0186825, lng: 21.8438682 },
    { lat: 49.0188407, lng: 21.8435417 },
    { lat: 49.0185911, lng: 21.8434583 },
    { lat: 49.0187584, lng: 21.8421786 },
    { lat: 49.0186336, lng: 21.8406062 },
    { lat: 49.0187109, lng: 21.8400192 },
    { lat: 49.018504, lng: 21.8384069 },
    { lat: 49.0185182, lng: 21.8379964 },
    { lat: 49.0192232, lng: 21.8376739 },
    { lat: 49.0197907, lng: 21.837219 },
    { lat: 49.0200625, lng: 21.8358918 },
    { lat: 49.0199874, lng: 21.835683 },
    { lat: 49.0200815, lng: 21.8345678 },
    { lat: 49.019876, lng: 21.8345904 },
    { lat: 49.0201174, lng: 21.8334728 },
    { lat: 49.0206997, lng: 21.832021 },
    { lat: 49.0205908, lng: 21.8309773 },
    { lat: 49.020773, lng: 21.8292769 },
    { lat: 49.021519, lng: 21.8284117 },
    { lat: 49.0221503, lng: 21.8279389 },
    { lat: 49.022451, lng: 21.8274346 },
    { lat: 49.0227138, lng: 21.827149 },
    { lat: 49.02319, lng: 21.8268202 },
    { lat: 49.0233186, lng: 21.8265251 },
    { lat: 49.0236797, lng: 21.8260957 },
    { lat: 49.0236303, lng: 21.8256606 },
    { lat: 49.0238163, lng: 21.8241903 },
    { lat: 49.0239856, lng: 21.8213019 },
    { lat: 49.0253976, lng: 21.8204824 },
    { lat: 49.0255688, lng: 21.8203108 },
    { lat: 49.0272143, lng: 21.8192572 },
    { lat: 49.0284018, lng: 21.8189829 },
    { lat: 49.028458, lng: 21.8185931 },
    { lat: 49.0286411, lng: 21.8182366 },
    { lat: 49.0287871, lng: 21.8174385 },
    { lat: 49.0286855, lng: 21.8162068 },
    { lat: 49.0286119, lng: 21.8158058 },
    { lat: 49.0287714, lng: 21.8152295 },
    { lat: 49.0287085, lng: 21.8151035 },
    { lat: 49.02841, lng: 21.814827 },
    { lat: 49.0275475, lng: 21.8149637 },
    { lat: 49.0271976, lng: 21.8153158 },
    { lat: 49.0265128, lng: 21.8149783 },
    { lat: 49.0264686, lng: 21.8157817 },
    { lat: 49.026009, lng: 21.8159404 },
    { lat: 49.0256077, lng: 21.815553 },
    { lat: 49.0255572, lng: 21.8157789 },
    { lat: 49.0244741, lng: 21.8155742 },
    { lat: 49.0232792, lng: 21.8149554 },
    { lat: 49.0227435, lng: 21.8149029 },
    { lat: 49.0222743, lng: 21.8147629 },
    { lat: 49.0219706, lng: 21.81446 },
    { lat: 49.0219397, lng: 21.81405 },
    { lat: 49.0216291, lng: 21.8140435 },
    { lat: 49.0214161, lng: 21.8142932 },
    { lat: 49.0212337, lng: 21.8155037 },
    { lat: 49.0208565, lng: 21.8157023 },
    { lat: 49.0199748, lng: 21.8155013 },
    { lat: 49.0197429, lng: 21.8158851 },
    { lat: 49.0196193, lng: 21.8167086 },
    { lat: 49.0194196, lng: 21.8169098 },
    { lat: 49.0192702, lng: 21.8168732 },
    { lat: 49.0188295, lng: 21.8173167 },
    { lat: 49.0188239, lng: 21.8169261 },
    { lat: 49.0182597, lng: 21.8168584 },
    { lat: 49.0175538, lng: 21.8166182 },
    { lat: 49.0177741, lng: 21.8172663 },
    { lat: 49.017861, lng: 21.818104 },
    { lat: 49.0177033, lng: 21.8182654 },
    { lat: 49.0173984, lng: 21.8182263 },
    { lat: 49.017106, lng: 21.8180478 },
    { lat: 49.0166754, lng: 21.8176704 },
    { lat: 49.0164079, lng: 21.8186726 },
    { lat: 49.0164979, lng: 21.819855 },
    { lat: 49.016655, lng: 21.8210558 },
    { lat: 49.0161452, lng: 21.8212666 },
    { lat: 49.0156085, lng: 21.8213033 },
    { lat: 49.0151062, lng: 21.8214092 },
    { lat: 49.0146315, lng: 21.8215817 },
    { lat: 49.0131171, lng: 21.8213604 },
    { lat: 49.0121314, lng: 21.8214813 },
    { lat: 49.0106886, lng: 21.821884 },
    { lat: 49.0095955, lng: 21.8222474 },
    { lat: 49.0091277, lng: 21.8224717 },
    { lat: 49.0091402, lng: 21.8203009 },
    { lat: 49.0087824, lng: 21.8202936 },
    { lat: 49.0080598, lng: 21.8205454 },
    { lat: 49.0073339, lng: 21.819966 },
    { lat: 49.0065525, lng: 21.8197236 },
    { lat: 49.0063664, lng: 21.8193527 },
    { lat: 49.0057877, lng: 21.8188187 },
    { lat: 49.0055229, lng: 21.817756 },
    { lat: 49.0054469, lng: 21.8172525 },
    { lat: 49.0052897, lng: 21.8169175 },
    { lat: 49.0050984, lng: 21.8162203 },
    { lat: 49.0050771, lng: 21.815697 },
    { lat: 49.0044065, lng: 21.8156443 },
    { lat: 49.0042565, lng: 21.8155515 },
    { lat: 49.004372, lng: 21.8152347 },
    { lat: 49.0037804, lng: 21.8149262 },
    { lat: 49.0004163, lng: 21.8133882 },
    { lat: 48.998598, lng: 21.8126391 },
  ],
  VyšnáJablonka: [
    { lat: 49.2030915, lng: 22.1139427 },
    { lat: 49.2033478, lng: 22.1133801 },
    { lat: 49.2035571, lng: 22.1121877 },
    { lat: 49.2038634, lng: 22.1114327 },
    { lat: 49.204039, lng: 22.110662 },
    { lat: 49.203945, lng: 22.109815 },
    { lat: 49.203769, lng: 22.108712 },
    { lat: 49.203896, lng: 22.107875 },
    { lat: 49.204087, lng: 22.106737 },
    { lat: 49.203368, lng: 22.106183 },
    { lat: 49.20236, lng: 22.10568 },
    { lat: 49.201467, lng: 22.105316 },
    { lat: 49.200916, lng: 22.104711 },
    { lat: 49.200138, lng: 22.10351 },
    { lat: 49.199626, lng: 22.102698 },
    { lat: 49.198892, lng: 22.101816 },
    { lat: 49.198407, lng: 22.100912 },
    { lat: 49.197624, lng: 22.100653 },
    { lat: 49.196528, lng: 22.100444 },
    { lat: 49.195994, lng: 22.099667 },
    { lat: 49.195105, lng: 22.09937 },
    { lat: 49.194442, lng: 22.099831 },
    { lat: 49.193791, lng: 22.100032 },
    { lat: 49.193128, lng: 22.100068 },
    { lat: 49.192176, lng: 22.100622 },
    { lat: 49.191351, lng: 22.10081 },
    { lat: 49.190429, lng: 22.100766 },
    { lat: 49.189512, lng: 22.100631 },
    { lat: 49.18862, lng: 22.10049 },
    { lat: 49.187442, lng: 22.099465 },
    { lat: 49.186573, lng: 22.098666 },
    { lat: 49.185934, lng: 22.098601 },
    { lat: 49.184992, lng: 22.098426 },
    { lat: 49.184497, lng: 22.098082 },
    { lat: 49.183744, lng: 22.097474 },
    { lat: 49.183887, lng: 22.096135 },
    { lat: 49.183522, lng: 22.095257 },
    { lat: 49.18336, lng: 22.094689 },
    { lat: 49.183457, lng: 22.093361 },
    { lat: 49.183262, lng: 22.091767 },
    { lat: 49.182352, lng: 22.090987 },
    { lat: 49.181737, lng: 22.089829 },
    { lat: 49.180336, lng: 22.089362 },
    { lat: 49.178853, lng: 22.088287 },
    { lat: 49.178037, lng: 22.08755 },
    { lat: 49.177148, lng: 22.087114 },
    { lat: 49.17544, lng: 22.086813 },
    { lat: 49.175131, lng: 22.086599 },
    { lat: 49.174677, lng: 22.086342 },
    { lat: 49.173959, lng: 22.085812 },
    { lat: 49.173338, lng: 22.084149 },
    { lat: 49.173306, lng: 22.084145 },
    { lat: 49.172103, lng: 22.08397 },
    { lat: 49.169611, lng: 22.083828 },
    { lat: 49.168762, lng: 22.083244 },
    { lat: 49.166934, lng: 22.081681 },
    { lat: 49.165853, lng: 22.080643 },
    { lat: 49.165085, lng: 22.078715 },
    { lat: 49.164146, lng: 22.077639 },
    { lat: 49.163221, lng: 22.076642 },
    { lat: 49.160576, lng: 22.076469 },
    { lat: 49.160236, lng: 22.076451 },
    { lat: 49.160101, lng: 22.076365 },
    { lat: 49.159689, lng: 22.077062 },
    { lat: 49.15914, lng: 22.077829 },
    { lat: 49.158476, lng: 22.078913 },
    { lat: 49.157449, lng: 22.080281 },
    { lat: 49.1530132, lng: 22.0752871 },
    { lat: 49.1527199, lng: 22.0759593 },
    { lat: 49.151978, lng: 22.0759306 },
    { lat: 49.1506101, lng: 22.0779692 },
    { lat: 49.150066, lng: 22.0799431 },
    { lat: 49.1493842, lng: 22.0789748 },
    { lat: 49.1489489, lng: 22.0805065 },
    { lat: 49.1490313, lng: 22.0806623 },
    { lat: 49.1488424, lng: 22.0815155 },
    { lat: 49.1485175, lng: 22.0816727 },
    { lat: 49.1483472, lng: 22.0822687 },
    { lat: 49.1482024, lng: 22.0839073 },
    { lat: 49.1472627, lng: 22.0852675 },
    { lat: 49.1474655, lng: 22.0859031 },
    { lat: 49.1471216, lng: 22.0862503 },
    { lat: 49.1472305, lng: 22.0865343 },
    { lat: 49.1464263, lng: 22.0871526 },
    { lat: 49.1453515, lng: 22.0881716 },
    { lat: 49.1455021, lng: 22.0901292 },
    { lat: 49.1454513, lng: 22.090413 },
    { lat: 49.1442844, lng: 22.0901928 },
    { lat: 49.1431894, lng: 22.0914653 },
    { lat: 49.1432302, lng: 22.0921884 },
    { lat: 49.1428943, lng: 22.093071 },
    { lat: 49.1430487, lng: 22.0942387 },
    { lat: 49.142548, lng: 22.0950901 },
    { lat: 49.1423404, lng: 22.0953539 },
    { lat: 49.1418559, lng: 22.0969929 },
    { lat: 49.1415313, lng: 22.0979294 },
    { lat: 49.1405806, lng: 22.1002647 },
    { lat: 49.1398251, lng: 22.0997544 },
    { lat: 49.1393931, lng: 22.1009047 },
    { lat: 49.1384368, lng: 22.1024789 },
    { lat: 49.137901, lng: 22.103948 },
    { lat: 49.1376111, lng: 22.1061272 },
    { lat: 49.138347, lng: 22.1073434 },
    { lat: 49.1385836, lng: 22.1099689 },
    { lat: 49.1382111, lng: 22.1102481 },
    { lat: 49.1372929, lng: 22.1092184 },
    { lat: 49.136737, lng: 22.110817 },
    { lat: 49.138432, lng: 22.112295 },
    { lat: 49.138896, lng: 22.113204 },
    { lat: 49.139285, lng: 22.113832 },
    { lat: 49.140004, lng: 22.114633 },
    { lat: 49.139072, lng: 22.116706 },
    { lat: 49.13925, lng: 22.119264 },
    { lat: 49.139985, lng: 22.120895 },
    { lat: 49.140948, lng: 22.121784 },
    { lat: 49.141014, lng: 22.123647 },
    { lat: 49.141017, lng: 22.123754 },
    { lat: 49.140382, lng: 22.127688 },
    { lat: 49.141103, lng: 22.12828 },
    { lat: 49.142995, lng: 22.128448 },
    { lat: 49.143926, lng: 22.12784 },
    { lat: 49.145144, lng: 22.128323 },
    { lat: 49.146454, lng: 22.128302 },
    { lat: 49.14855, lng: 22.128039 },
    { lat: 49.150014, lng: 22.126978 },
    { lat: 49.151833, lng: 22.126555 },
    { lat: 49.152962, lng: 22.126962 },
    { lat: 49.153898, lng: 22.127433 },
    { lat: 49.155179, lng: 22.128189 },
    { lat: 49.157612, lng: 22.127899 },
    { lat: 49.15759, lng: 22.128379 },
    { lat: 49.157636, lng: 22.128565 },
    { lat: 49.157891, lng: 22.128644 },
    { lat: 49.158075, lng: 22.128842 },
    { lat: 49.158167, lng: 22.129222 },
    { lat: 49.158151, lng: 22.129831 },
    { lat: 49.158227, lng: 22.130305 },
    { lat: 49.158617, lng: 22.131298 },
    { lat: 49.158826, lng: 22.131534 },
    { lat: 49.158861, lng: 22.131505 },
    { lat: 49.158917, lng: 22.131583 },
    { lat: 49.159093, lng: 22.131658 },
    { lat: 49.159415, lng: 22.131969 },
    { lat: 49.159441, lng: 22.132066 },
    { lat: 49.159508, lng: 22.132245 },
    { lat: 49.159482, lng: 22.132341 },
    { lat: 49.159543, lng: 22.132422 },
    { lat: 49.159527, lng: 22.13248 },
    { lat: 49.159582, lng: 22.132662 },
    { lat: 49.1597, lng: 22.132842 },
    { lat: 49.159864, lng: 22.132993 },
    { lat: 49.15984, lng: 22.133161 },
    { lat: 49.159866, lng: 22.133274 },
    { lat: 49.15983, lng: 22.133337 },
    { lat: 49.15996, lng: 22.133493 },
    { lat: 49.160072, lng: 22.133895 },
    { lat: 49.160313, lng: 22.134026 },
    { lat: 49.160704, lng: 22.133922 },
    { lat: 49.161097, lng: 22.133661 },
    { lat: 49.1622, lng: 22.133743 },
    { lat: 49.162273, lng: 22.133747 },
    { lat: 49.162663, lng: 22.133728 },
    { lat: 49.163099, lng: 22.134045 },
    { lat: 49.16349, lng: 22.134433 },
    { lat: 49.164138, lng: 22.134708 },
    { lat: 49.164536, lng: 22.134628 },
    { lat: 49.164945, lng: 22.134811 },
    { lat: 49.165094, lng: 22.13492 },
    { lat: 49.165379, lng: 22.13517 },
    { lat: 49.165541, lng: 22.135238 },
    { lat: 49.166197, lng: 22.135592 },
    { lat: 49.166789, lng: 22.135535 },
    { lat: 49.166956, lng: 22.13543 },
    { lat: 49.16738, lng: 22.135284 },
    { lat: 49.167788, lng: 22.135307 },
    { lat: 49.168134, lng: 22.13528 },
    { lat: 49.168489, lng: 22.135353 },
    { lat: 49.168738, lng: 22.135342 },
    { lat: 49.168773, lng: 22.135617 },
    { lat: 49.168745, lng: 22.136511 },
    { lat: 49.168937, lng: 22.13664 },
    { lat: 49.168948, lng: 22.137242 },
    { lat: 49.168938, lng: 22.137727 },
    { lat: 49.168958, lng: 22.138087 },
    { lat: 49.169172, lng: 22.138263 },
    { lat: 49.169587, lng: 22.138225 },
    { lat: 49.169787, lng: 22.138184 },
    { lat: 49.170003, lng: 22.138034 },
    { lat: 49.170628, lng: 22.137861 },
    { lat: 49.171075, lng: 22.137934 },
    { lat: 49.171693, lng: 22.13796 },
    { lat: 49.172196, lng: 22.13807 },
    { lat: 49.172613, lng: 22.138263 },
    { lat: 49.172919, lng: 22.138304 },
    { lat: 49.173862, lng: 22.137927 },
    { lat: 49.174175, lng: 22.137777 },
    { lat: 49.17457, lng: 22.137709 },
    { lat: 49.175007, lng: 22.137931 },
    { lat: 49.175371, lng: 22.138506 },
    { lat: 49.175514, lng: 22.139286 },
    { lat: 49.17555, lng: 22.1396 },
    { lat: 49.175718, lng: 22.139953 },
    { lat: 49.176234, lng: 22.140466 },
    { lat: 49.176576, lng: 22.141012 },
    { lat: 49.176871, lng: 22.141385 },
    { lat: 49.177109, lng: 22.141616 },
    { lat: 49.177528, lng: 22.142123 },
    { lat: 49.177756, lng: 22.142688 },
    { lat: 49.177769, lng: 22.143243 },
    { lat: 49.177893, lng: 22.144135 },
    { lat: 49.177643, lng: 22.144636 },
    { lat: 49.177467, lng: 22.145386 },
    { lat: 49.177495, lng: 22.146029 },
    { lat: 49.177656, lng: 22.146163 },
    { lat: 49.177751, lng: 22.146386 },
    { lat: 49.177845, lng: 22.146567 },
    { lat: 49.177925, lng: 22.146741 },
    { lat: 49.178034, lng: 22.146955 },
    { lat: 49.178157, lng: 22.147236 },
    { lat: 49.178315, lng: 22.147468 },
    { lat: 49.178753, lng: 22.148284 },
    { lat: 49.178859, lng: 22.148159 },
    { lat: 49.178932, lng: 22.147959 },
    { lat: 49.178942, lng: 22.147856 },
    { lat: 49.178953, lng: 22.147807 },
    { lat: 49.179292, lng: 22.148225 },
    { lat: 49.179367, lng: 22.148552 },
    { lat: 49.179408, lng: 22.148985 },
    { lat: 49.179812, lng: 22.148865 },
    { lat: 49.180043, lng: 22.14892 },
    { lat: 49.18036, lng: 22.149104 },
    { lat: 49.180736, lng: 22.14912 },
    { lat: 49.181079, lng: 22.149271 },
    { lat: 49.181424, lng: 22.149524 },
    { lat: 49.182127, lng: 22.149721 },
    { lat: 49.182732, lng: 22.149602 },
    { lat: 49.182874, lng: 22.149809 },
    { lat: 49.183418, lng: 22.150016 },
    { lat: 49.183823, lng: 22.150267 },
    { lat: 49.184117, lng: 22.150778 },
    { lat: 49.184286, lng: 22.150908 },
    { lat: 49.18457, lng: 22.150774 },
    { lat: 49.184951, lng: 22.150757 },
    { lat: 49.185137, lng: 22.150911 },
    { lat: 49.185508, lng: 22.150791 },
    { lat: 49.185815, lng: 22.150592 },
    { lat: 49.186145, lng: 22.150428 },
    { lat: 49.186494, lng: 22.15042 },
    { lat: 49.186523, lng: 22.150442 },
    { lat: 49.186659, lng: 22.150643 },
    { lat: 49.186977, lng: 22.150649 },
    { lat: 49.1872, lng: 22.150741 },
    { lat: 49.187361, lng: 22.151048 },
    { lat: 49.18743, lng: 22.151381 },
    { lat: 49.187759, lng: 22.151526 },
    { lat: 49.187875, lng: 22.151721 },
    { lat: 49.187951, lng: 22.152041 },
    { lat: 49.188184, lng: 22.152632 },
    { lat: 49.188297, lng: 22.153109 },
    { lat: 49.18838, lng: 22.153351 },
    { lat: 49.188559, lng: 22.153587 },
    { lat: 49.1886156, lng: 22.1538328 },
    { lat: 49.1890331, lng: 22.1528369 },
    { lat: 49.1894732, lng: 22.1519411 },
    { lat: 49.1899307, lng: 22.1512314 },
    { lat: 49.1899718, lng: 22.1510187 },
    { lat: 49.1903729, lng: 22.1504161 },
    { lat: 49.1905955, lng: 22.1498108 },
    { lat: 49.1906607, lng: 22.1495382 },
    { lat: 49.1909389, lng: 22.1482988 },
    { lat: 49.1912098, lng: 22.147181 },
    { lat: 49.1916353, lng: 22.1455451 },
    { lat: 49.1920237, lng: 22.1449534 },
    { lat: 49.1924, lng: 22.143985 },
    { lat: 49.1927444, lng: 22.143104 },
    { lat: 49.193192, lng: 22.143018 },
    { lat: 49.193583, lng: 22.142943 },
    { lat: 49.1936312, lng: 22.1429356 },
    { lat: 49.1944682, lng: 22.1432045 },
    { lat: 49.1951563, lng: 22.1431725 },
    { lat: 49.1958439, lng: 22.1431995 },
    { lat: 49.1964991, lng: 22.1433627 },
    { lat: 49.19703, lng: 22.143466 },
    { lat: 49.1975407, lng: 22.1435494 },
    { lat: 49.198336, lng: 22.1435827 },
    { lat: 49.1988119, lng: 22.1435632 },
    { lat: 49.1992729, lng: 22.1434835 },
    { lat: 49.1997272, lng: 22.1434695 },
    { lat: 49.2001571, lng: 22.1438676 },
    { lat: 49.2005139, lng: 22.1438057 },
    { lat: 49.200478, lng: 22.142756 },
    { lat: 49.200455, lng: 22.141869 },
    { lat: 49.2004604, lng: 22.1412109 },
    { lat: 49.2002478, lng: 22.1399004 },
    { lat: 49.2002325, lng: 22.1391573 },
    { lat: 49.2001658, lng: 22.1383483 },
    { lat: 49.200488, lng: 22.137536 },
    { lat: 49.20059, lng: 22.137092 },
    { lat: 49.2006935, lng: 22.1366851 },
    { lat: 49.2006977, lng: 22.1360042 },
    { lat: 49.2007678, lng: 22.1354403 },
    { lat: 49.200618, lng: 22.1345546 },
    { lat: 49.2006275, lng: 22.1337906 },
    { lat: 49.2005143, lng: 22.1329752 },
    { lat: 49.2003961, lng: 22.1321226 },
    { lat: 49.200254, lng: 22.131505 },
    { lat: 49.2001491, lng: 22.1309933 },
    { lat: 49.2002288, lng: 22.1299788 },
    { lat: 49.200431, lng: 22.12914 },
    { lat: 49.200522, lng: 22.128286 },
    { lat: 49.200531, lng: 22.127626 },
    { lat: 49.2006568, lng: 22.1270664 },
    { lat: 49.2009438, lng: 22.1264797 },
    { lat: 49.2011569, lng: 22.1259311 },
    { lat: 49.201419, lng: 22.1255353 },
    { lat: 49.2017445, lng: 22.1251318 },
    { lat: 49.2019596, lng: 22.1247335 },
    { lat: 49.2021588, lng: 22.1244516 },
    { lat: 49.2019358, lng: 22.1238475 },
    { lat: 49.2015914, lng: 22.123025 },
    { lat: 49.2014998, lng: 22.1225629 },
    { lat: 49.2014926, lng: 22.1218733 },
    { lat: 49.2014419, lng: 22.1214219 },
    { lat: 49.2013368, lng: 22.1207598 },
    { lat: 49.2011768, lng: 22.1198881 },
    { lat: 49.201064, lng: 22.1198193 },
    { lat: 49.2009545, lng: 22.1190651 },
    { lat: 49.2010467, lng: 22.1175657 },
    { lat: 49.2019633, lng: 22.1171207 },
    { lat: 49.2021171, lng: 22.1166144 },
    { lat: 49.202165, lng: 22.116449 },
    { lat: 49.202375, lng: 22.115553 },
    { lat: 49.2025557, lng: 22.1146616 },
    { lat: 49.2030915, lng: 22.1139427 },
  ],
  Lieskovec: [
    { lat: 48.9710626, lng: 21.8211217 },
    { lat: 48.9706893, lng: 21.8198231 },
    { lat: 48.9704039, lng: 21.818317 },
    { lat: 48.9696912, lng: 21.8133014 },
    { lat: 48.9695979, lng: 21.812051 },
    { lat: 48.9697382, lng: 21.8077062 },
    { lat: 48.9694113, lng: 21.8057769 },
    { lat: 48.967813, lng: 21.80112 },
    { lat: 48.967289, lng: 21.800489 },
    { lat: 48.966606, lng: 21.799782 },
    { lat: 48.966505, lng: 21.79974 },
    { lat: 48.965749, lng: 21.799451 },
    { lat: 48.965014, lng: 21.799035 },
    { lat: 48.964252, lng: 21.79882 },
    { lat: 48.963656, lng: 21.798422 },
    { lat: 48.963037, lng: 21.798241 },
    { lat: 48.961267, lng: 21.797423 },
    { lat: 48.959375, lng: 21.797059 },
    { lat: 48.958651, lng: 21.797288 },
    { lat: 48.957426, lng: 21.796469 },
    { lat: 48.9571, lng: 21.796249 },
    { lat: 48.95625, lng: 21.795874 },
    { lat: 48.956229, lng: 21.795865 },
    { lat: 48.956081, lng: 21.795809 },
    { lat: 48.955611, lng: 21.795078 },
    { lat: 48.953282, lng: 21.794075 },
    { lat: 48.952534, lng: 21.793873 },
    { lat: 48.950298, lng: 21.793576 },
    { lat: 48.948639, lng: 21.793949 },
    { lat: 48.948243, lng: 21.793739 },
    { lat: 48.9482, lng: 21.793716 },
    { lat: 48.947619, lng: 21.794264 },
    { lat: 48.946655, lng: 21.795005 },
    { lat: 48.944804, lng: 21.796404 },
    { lat: 48.9436327, lng: 21.7975062 },
    { lat: 48.9413787, lng: 21.7993816 },
    { lat: 48.9376944, lng: 21.8031763 },
    { lat: 48.9370728, lng: 21.8039278 },
    { lat: 48.9364639, lng: 21.8048235 },
    { lat: 48.9365744, lng: 21.8071677 },
    { lat: 48.9362198, lng: 21.8071593 },
    { lat: 48.9361919, lng: 21.8095468 },
    { lat: 48.9355869, lng: 21.8095967 },
    { lat: 48.9355341, lng: 21.8103323 },
    { lat: 48.9364164, lng: 21.8108212 },
    { lat: 48.9364939, lng: 21.8122514 },
    { lat: 48.9364154, lng: 21.8124321 },
    { lat: 48.936141, lng: 21.8156409 },
    { lat: 48.9361385, lng: 21.8186238 },
    { lat: 48.9365664, lng: 21.8186437 },
    { lat: 48.9366575, lng: 21.8195146 },
    { lat: 48.9368605, lng: 21.8227013 },
    { lat: 48.9366068, lng: 21.8228521 },
    { lat: 48.9362466, lng: 21.8228904 },
    { lat: 48.9362915, lng: 21.8240967 },
    { lat: 48.9365313, lng: 21.8242338 },
    { lat: 48.9365827, lng: 21.824873 },
    { lat: 48.93615, lng: 21.8268918 },
    { lat: 48.9360589, lng: 21.8286398 },
    { lat: 48.9367077, lng: 21.82858 },
    { lat: 48.9370798, lng: 21.8287024 },
    { lat: 48.937702, lng: 21.8287055 },
    { lat: 48.9386402, lng: 21.8292723 },
    { lat: 48.9436919, lng: 21.8334832 },
    { lat: 48.9437639, lng: 21.8336614 },
    { lat: 48.9466052, lng: 21.8340398 },
    { lat: 48.9489058, lng: 21.8339177 },
    { lat: 48.9493171, lng: 21.8337229 },
    { lat: 48.9497752, lng: 21.8336907 },
    { lat: 48.9500845, lng: 21.8335603 },
    { lat: 48.9510093, lng: 21.8337383 },
    { lat: 48.9512933, lng: 21.8338918 },
    { lat: 48.9531211, lng: 21.8344912 },
    { lat: 48.9534844, lng: 21.8346704 },
    { lat: 48.9544997, lng: 21.8354301 },
    { lat: 48.9547394, lng: 21.8353453 },
    { lat: 48.9558999, lng: 21.8354188 },
    { lat: 48.9563711, lng: 21.8352824 },
    { lat: 48.9566389, lng: 21.8352808 },
    { lat: 48.9574706, lng: 21.835914 },
    { lat: 48.9595486, lng: 21.8350474 },
    { lat: 48.9602368, lng: 21.8349535 },
    { lat: 48.9606626, lng: 21.8353227 },
    { lat: 48.9612721, lng: 21.8356671 },
    { lat: 48.9618178, lng: 21.8357091 },
    { lat: 48.9627174, lng: 21.8356123 },
    { lat: 48.9629446, lng: 21.8352183 },
    { lat: 48.9635257, lng: 21.8343668 },
    { lat: 48.9636339, lng: 21.8335896 },
    { lat: 48.9653073, lng: 21.8359762 },
    { lat: 48.9672018, lng: 21.8335794 },
    { lat: 48.9674942, lng: 21.8330088 },
    { lat: 48.9667315, lng: 21.8313915 },
    { lat: 48.9669084, lng: 21.8308418 },
    { lat: 48.9678834, lng: 21.8306939 },
    { lat: 48.9692814, lng: 21.8238479 },
    { lat: 48.9710626, lng: 21.8211217 },
  ],
  Humenné: [
    { lat: 48.9798026, lng: 21.9092867 },
    { lat: 48.9794715, lng: 21.9090008 },
    { lat: 48.9782361, lng: 21.9085421 },
    { lat: 48.9777261, lng: 21.9084392 },
    { lat: 48.9771933, lng: 21.9085402 },
    { lat: 48.9768951, lng: 21.9083147 },
    { lat: 48.9766554, lng: 21.908223 },
    { lat: 48.9763946, lng: 21.9082743 },
    { lat: 48.9760796, lng: 21.9079635 },
    { lat: 48.97599, lng: 21.9077929 },
    { lat: 48.9758496, lng: 21.906952 },
    { lat: 48.9758318, lng: 21.9063765 },
    { lat: 48.975722, lng: 21.9056843 },
    { lat: 48.9757277, lng: 21.9054129 },
    { lat: 48.975649, lng: 21.9051532 },
    { lat: 48.9753185, lng: 21.9048437 },
    { lat: 48.9748328, lng: 21.9048092 },
    { lat: 48.974009, lng: 21.9044825 },
    { lat: 48.9731154, lng: 21.9039296 },
    { lat: 48.9728796, lng: 21.9034965 },
    { lat: 48.9726468, lng: 21.9033217 },
    { lat: 48.9722304, lng: 21.9034497 },
    { lat: 48.9714182, lng: 21.9034868 },
    { lat: 48.9710336, lng: 21.9034436 },
    { lat: 48.9708278, lng: 21.9030893 },
    { lat: 48.9703745, lng: 21.9029212 },
    { lat: 48.9698628, lng: 21.9029205 },
    { lat: 48.9692902, lng: 21.902696 },
    { lat: 48.9685917, lng: 21.9025625 },
    { lat: 48.9672453, lng: 21.9025244 },
    { lat: 48.9672047, lng: 21.9021218 },
    { lat: 48.9654923, lng: 21.9022389 },
    { lat: 48.9658511, lng: 21.9004044 },
    { lat: 48.965139, lng: 21.8998733 },
    { lat: 48.9644335, lng: 21.900301 },
    { lat: 48.9637632, lng: 21.9003145 },
    { lat: 48.9633577, lng: 21.8991703 },
    { lat: 48.9623874, lng: 21.8979903 },
    { lat: 48.9608682, lng: 21.9008236 },
    { lat: 48.9603164, lng: 21.8989283 },
    { lat: 48.9595275, lng: 21.8993955 },
    { lat: 48.9590138, lng: 21.8977222 },
    { lat: 48.9579429, lng: 21.8986533 },
    { lat: 48.9570516, lng: 21.8985508 },
    { lat: 48.9569524, lng: 21.8967576 },
    { lat: 48.9561277, lng: 21.8968467 },
    { lat: 48.9535543, lng: 21.8967212 },
    { lat: 48.9534936, lng: 21.8961499 },
    { lat: 48.9532283, lng: 21.8959274 },
    { lat: 48.9531389, lng: 21.8957772 },
    { lat: 48.9520292, lng: 21.8959302 },
    { lat: 48.9518187, lng: 21.8961919 },
    { lat: 48.9513832, lng: 21.8961006 },
    { lat: 48.9503323, lng: 21.8961353 },
    { lat: 48.9495251, lng: 21.8964206 },
    { lat: 48.9487951, lng: 21.8943229 },
    { lat: 48.9488257, lng: 21.8941164 },
    { lat: 48.9488106, lng: 21.8933797 },
    { lat: 48.9488804, lng: 21.8927651 },
    { lat: 48.948818, lng: 21.8921599 },
    { lat: 48.9485901, lng: 21.8923117 },
    { lat: 48.9482535, lng: 21.8887063 },
    { lat: 48.948141, lng: 21.8858191 },
    { lat: 48.9479699, lng: 21.8836782 },
    { lat: 48.9480023, lng: 21.8827379 },
    { lat: 48.9475479, lng: 21.8798582 },
    { lat: 48.9473784, lng: 21.878764 },
    { lat: 48.9475577, lng: 21.878027 },
    { lat: 48.9474978, lng: 21.8746969 },
    { lat: 48.9471271, lng: 21.8723358 },
    { lat: 48.9468655, lng: 21.8719518 },
    { lat: 48.9459849, lng: 21.8717397 },
    { lat: 48.9451421, lng: 21.8719137 },
    { lat: 48.9448093, lng: 21.8718667 },
    { lat: 48.9445841, lng: 21.8720395 },
    { lat: 48.9437292, lng: 21.8720435 },
    { lat: 48.9432434, lng: 21.8718215 },
    { lat: 48.942984, lng: 21.8718016 },
    { lat: 48.9427544, lng: 21.8701618 },
    { lat: 48.9415802, lng: 21.8705185 },
    { lat: 48.9411396, lng: 21.8677146 },
    { lat: 48.9407135, lng: 21.867858 },
    { lat: 48.9402381, lng: 21.8682195 },
    { lat: 48.938643, lng: 21.8684921 },
    { lat: 48.9381587, lng: 21.8684693 },
    { lat: 48.9376078, lng: 21.8686165 },
    { lat: 48.9370937, lng: 21.86905 },
    { lat: 48.9352613, lng: 21.8698531 },
    { lat: 48.9344047, lng: 21.8698698 },
    { lat: 48.9334045, lng: 21.8701761 },
    { lat: 48.9324518, lng: 21.8705758 },
    { lat: 48.9320464, lng: 21.8676458 },
    { lat: 48.9319389, lng: 21.8677577 },
    { lat: 48.9313166, lng: 21.8648849 },
    { lat: 48.9313, lng: 21.8648075 },
    { lat: 48.9310738, lng: 21.8650598 },
    { lat: 48.9290223, lng: 21.8656072 },
    { lat: 48.9290176, lng: 21.8655144 },
    { lat: 48.9287992, lng: 21.8651491 },
    { lat: 48.9263677, lng: 21.8656562 },
    { lat: 48.9262059, lng: 21.8648687 },
    { lat: 48.9243044, lng: 21.8657428 },
    { lat: 48.9239613, lng: 21.8658274 },
    { lat: 48.9235069, lng: 21.8657879 },
    { lat: 48.9229551, lng: 21.8659834 },
    { lat: 48.9226597, lng: 21.8653805 },
    { lat: 48.9225085, lng: 21.8652749 },
    { lat: 48.9222477, lng: 21.8655017 },
    { lat: 48.922033, lng: 21.8655136 },
    { lat: 48.9212242, lng: 21.8650678 },
    { lat: 48.9208175, lng: 21.8644271 },
    { lat: 48.920507, lng: 21.864078 },
    { lat: 48.9200525, lng: 21.8630596 },
    { lat: 48.9196328, lng: 21.8618288 },
    { lat: 48.9194345, lng: 21.860888 },
    { lat: 48.9190254, lng: 21.8596294 },
    { lat: 48.9189894, lng: 21.8594548 },
    { lat: 48.9190404, lng: 21.8592483 },
    { lat: 48.9188346, lng: 21.8590698 },
    { lat: 48.9185899, lng: 21.8597534 },
    { lat: 48.9181995, lng: 21.8604982 },
    { lat: 48.9179867, lng: 21.8607114 },
    { lat: 48.9159481, lng: 21.856704 },
    { lat: 48.9158151, lng: 21.8568205 },
    { lat: 48.9158005, lng: 21.8567842 },
    { lat: 48.9123662, lng: 21.8596874 },
    { lat: 48.911999, lng: 21.8602877 },
    { lat: 48.9120155, lng: 21.8604473 },
    { lat: 48.9104435, lng: 21.8610831 },
    { lat: 48.909803, lng: 21.8656507 },
    { lat: 48.9096671, lng: 21.8663199 },
    { lat: 48.9093075, lng: 21.8665563 },
    { lat: 48.9080803, lng: 21.8677459 },
    { lat: 48.9069949, lng: 21.8672249 },
    { lat: 48.9063498, lng: 21.8668055 },
    { lat: 48.9067282, lng: 21.8679701 },
    { lat: 48.9068502, lng: 21.868789 },
    { lat: 48.9070921, lng: 21.8695382 },
    { lat: 48.907318, lng: 21.8700135 },
    { lat: 48.908398, lng: 21.8712489 },
    { lat: 48.9093404, lng: 21.8714136 },
    { lat: 48.9104238, lng: 21.8719988 },
    { lat: 48.9115266, lng: 21.8727205 },
    { lat: 48.912538, lng: 21.8735621 },
    { lat: 48.9128145, lng: 21.873866 },
    { lat: 48.9126046, lng: 21.8744552 },
    { lat: 48.9121661, lng: 21.8751636 },
    { lat: 48.9118476, lng: 21.8759157 },
    { lat: 48.9117381, lng: 21.8763821 },
    { lat: 48.9117099, lng: 21.8770453 },
    { lat: 48.9117941, lng: 21.8779738 },
    { lat: 48.9121674, lng: 21.8792509 },
    { lat: 48.9126337, lng: 21.8804199 },
    { lat: 48.9128977, lng: 21.8814205 },
    { lat: 48.9132358, lng: 21.8832272 },
    { lat: 48.9131876, lng: 21.883937 },
    { lat: 48.9127103, lng: 21.8853879 },
    { lat: 48.9126167, lng: 21.8862252 },
    { lat: 48.9126675, lng: 21.8874754 },
    { lat: 48.9130852, lng: 21.8879768 },
    { lat: 48.913728, lng: 21.8897253 },
    { lat: 48.9149417, lng: 21.8906549 },
    { lat: 48.9161793, lng: 21.8909741 },
    { lat: 48.9170258, lng: 21.8915711 },
    { lat: 48.9176103, lng: 21.8922287 },
    { lat: 48.9177385, lng: 21.8925832 },
    { lat: 48.9177377, lng: 21.8932589 },
    { lat: 48.917081, lng: 21.8948265 },
    { lat: 48.9171859, lng: 21.896265 },
    { lat: 48.9169222, lng: 21.8974427 },
    { lat: 48.9172597, lng: 21.8989142 },
    { lat: 48.917341, lng: 21.8988415 },
    { lat: 48.9176293, lng: 21.8996999 },
    { lat: 48.9178965, lng: 21.9008797 },
    { lat: 48.917998, lng: 21.9010731 },
    { lat: 48.9187722, lng: 21.901928 },
    { lat: 48.9193468, lng: 21.9022337 },
    { lat: 48.9174211, lng: 21.904784 },
    { lat: 48.9166722, lng: 21.9069366 },
    { lat: 48.9165792, lng: 21.9073153 },
    { lat: 48.9167062, lng: 21.9075294 },
    { lat: 48.916943, lng: 21.9075049 },
    { lat: 48.9172384, lng: 21.9076659 },
    { lat: 48.9174714, lng: 21.9076356 },
    { lat: 48.9179501, lng: 21.9079205 },
    { lat: 48.9184543, lng: 21.9083352 },
    { lat: 48.9192323, lng: 21.9088076 },
    { lat: 48.9188689, lng: 21.9105076 },
    { lat: 48.9171141, lng: 21.9145842 },
    { lat: 48.91626, lng: 21.9163206 },
    { lat: 48.9162191, lng: 21.9167571 },
    { lat: 48.9157832, lng: 21.9178077 },
    { lat: 48.9156321, lng: 21.9186912 },
    { lat: 48.9158492, lng: 21.9194061 },
    { lat: 48.9160557, lng: 21.9198395 },
    { lat: 48.9157416, lng: 21.9207667 },
    { lat: 48.9157017, lng: 21.9220054 },
    { lat: 48.9158441, lng: 21.9228799 },
    { lat: 48.9157582, lng: 21.9239513 },
    { lat: 48.9153671, lng: 21.9243247 },
    { lat: 48.9150749, lng: 21.9246988 },
    { lat: 48.9147086, lng: 21.9248504 },
    { lat: 48.9143319, lng: 21.924492 },
    { lat: 48.914542, lng: 21.9241616 },
    { lat: 48.914276, lng: 21.9240092 },
    { lat: 48.9125815, lng: 21.924351 },
    { lat: 48.9124594, lng: 21.9246452 },
    { lat: 48.9123891, lng: 21.9249811 },
    { lat: 48.9117333, lng: 21.9300606 },
    { lat: 48.9121021, lng: 21.9311426 },
    { lat: 48.9122908, lng: 21.9312627 },
    { lat: 48.912424, lng: 21.9317832 },
    { lat: 48.9126163, lng: 21.9317325 },
    { lat: 48.9128629, lng: 21.9318274 },
    { lat: 48.9132644, lng: 21.9363397 },
    { lat: 48.9133557, lng: 21.9363906 },
    { lat: 48.9134306, lng: 21.9375418 },
    { lat: 48.9132861, lng: 21.9377046 },
    { lat: 48.9133614, lng: 21.9381531 },
    { lat: 48.9141075, lng: 21.939936 },
    { lat: 48.9144001, lng: 21.9407901 },
    { lat: 48.9146305, lng: 21.9419589 },
    { lat: 48.9148291, lng: 21.9426688 },
    { lat: 48.9149492, lng: 21.9433851 },
    { lat: 48.9148259, lng: 21.9457558 },
    { lat: 48.9145819, lng: 21.9470704 },
    { lat: 48.9148822, lng: 21.9489241 },
    { lat: 48.9148445, lng: 21.949943 },
    { lat: 48.9147264, lng: 21.9510831 },
    { lat: 48.9145985, lng: 21.9523592 },
    { lat: 48.9155388, lng: 21.9514598 },
    { lat: 48.915846, lng: 21.9508715 },
    { lat: 48.91621, lng: 21.9498943 },
    { lat: 48.9161679, lng: 21.9489275 },
    { lat: 48.9164907, lng: 21.9481995 },
    { lat: 48.916627, lng: 21.9480226 },
    { lat: 48.9169272, lng: 21.9481531 },
    { lat: 48.9173755, lng: 21.9480463 },
    { lat: 48.9181984, lng: 21.9481127 },
    { lat: 48.9193126, lng: 21.9477314 },
    { lat: 48.9195999, lng: 21.9474722 },
    { lat: 48.919726, lng: 21.9472053 },
    { lat: 48.9200215, lng: 21.9462427 },
    { lat: 48.9201911, lng: 21.9460038 },
    { lat: 48.9204296, lng: 21.9450992 },
    { lat: 48.9206959, lng: 21.9447259 },
    { lat: 48.920744, lng: 21.9444424 },
    { lat: 48.9213904, lng: 21.9441489 },
    { lat: 48.9215329, lng: 21.943958 },
    { lat: 48.9215515, lng: 21.9437087 },
    { lat: 48.9218317, lng: 21.9428535 },
    { lat: 48.9218919, lng: 21.9423347 },
    { lat: 48.922, lng: 21.942067 },
    { lat: 48.9223697, lng: 21.9416889 },
    { lat: 48.9227647, lng: 21.9410628 },
    { lat: 48.9228556, lng: 21.9406992 },
    { lat: 48.9232944, lng: 21.940296 },
    { lat: 48.9236946, lng: 21.9403321 },
    { lat: 48.9242471, lng: 21.9397446 },
    { lat: 48.9246104, lng: 21.9392444 },
    { lat: 48.9249432, lng: 21.9389703 },
    { lat: 48.9249813, lng: 21.9390958 },
    { lat: 48.925295, lng: 21.938877 },
    { lat: 48.9255746, lng: 21.9387567 },
    { lat: 48.9261667, lng: 21.9386441 },
    { lat: 48.9267944, lng: 21.9384124 },
    { lat: 48.9295247, lng: 21.9369358 },
    { lat: 48.9300386, lng: 21.9369282 },
    { lat: 48.9303648, lng: 21.9368322 },
    { lat: 48.9306276, lng: 21.9375877 },
    { lat: 48.9312329, lng: 21.9372775 },
    { lat: 48.9328121, lng: 21.9366832 },
    { lat: 48.9352793, lng: 21.9397713 },
    { lat: 48.9354976, lng: 21.9400476 },
    { lat: 48.9363309, lng: 21.940398 },
    { lat: 48.9383973, lng: 21.9401655 },
    { lat: 48.9383511, lng: 21.9397563 },
    { lat: 48.9381945, lng: 21.9393481 },
    { lat: 48.9381888, lng: 21.9392024 },
    { lat: 48.9400941, lng: 21.9390624 },
    { lat: 48.9404583, lng: 21.9406356 },
    { lat: 48.9409243, lng: 21.9411864 },
    { lat: 48.9417816, lng: 21.9424169 },
    { lat: 48.9418566, lng: 21.9425314 },
    { lat: 48.9433154, lng: 21.9395927 },
    { lat: 48.9436889, lng: 21.9384133 },
    { lat: 48.9464814, lng: 21.9375793 },
    { lat: 48.9496444, lng: 21.9362219 },
    { lat: 48.9501709, lng: 21.9360374 },
    { lat: 48.9507513, lng: 21.9359472 },
    { lat: 48.9540549, lng: 21.9344533 },
    { lat: 48.9548001, lng: 21.9343815 },
    { lat: 48.9550873, lng: 21.934128 },
    { lat: 48.956436, lng: 21.9332159 },
    { lat: 48.9574135, lng: 21.9327269 },
    { lat: 48.958096, lng: 21.932532 },
    { lat: 48.960693, lng: 21.9320709 },
    { lat: 48.9617604, lng: 21.9320654 },
    { lat: 48.9624809, lng: 21.9323537 },
    { lat: 48.9644412, lng: 21.9335605 },
    { lat: 48.9671424, lng: 21.9336208 },
    { lat: 48.9672201, lng: 21.9340814 },
    { lat: 48.967298, lng: 21.9342674 },
    { lat: 48.9677291, lng: 21.9348738 },
    { lat: 48.9678193, lng: 21.9349624 },
    { lat: 48.9679951, lng: 21.9349817 },
    { lat: 48.9680796, lng: 21.9349865 },
    { lat: 48.9680735, lng: 21.9349185 },
    { lat: 48.9684209, lng: 21.9345498 },
    { lat: 48.9687242, lng: 21.9344049 },
    { lat: 48.9691174, lng: 21.9344729 },
    { lat: 48.969439, lng: 21.9344201 },
    { lat: 48.9703119, lng: 21.9338816 },
    { lat: 48.9704452, lng: 21.9336784 },
    { lat: 48.9706174, lng: 21.9332303 },
    { lat: 48.971377, lng: 21.9323366 },
    { lat: 48.9720848, lng: 21.931983 },
    { lat: 48.973034, lng: 21.9313967 },
    { lat: 48.9734577, lng: 21.9310114 },
    { lat: 48.9738699, lng: 21.9309455 },
    { lat: 48.974802, lng: 21.9297568 },
    { lat: 48.9751072, lng: 21.9295011 },
    { lat: 48.9755422, lng: 21.929268 },
    { lat: 48.9764378, lng: 21.9282311 },
    { lat: 48.9765409, lng: 21.9279523 },
    { lat: 48.9765492, lng: 21.9276073 },
    { lat: 48.9765984, lng: 21.9274598 },
    { lat: 48.9768628, lng: 21.9271456 },
    { lat: 48.9770926, lng: 21.9267503 },
    { lat: 48.9771463, lng: 21.9267372 },
    { lat: 48.9764562, lng: 21.9253647 },
    { lat: 48.9765581, lng: 21.9252635 },
    { lat: 48.976546, lng: 21.9250906 },
    { lat: 48.9767304, lng: 21.9245432 },
    { lat: 48.9768914, lng: 21.9244612 },
    { lat: 48.9770229, lng: 21.9241858 },
    { lat: 48.9770363, lng: 21.9239594 },
    { lat: 48.9773936, lng: 21.9234006 },
    { lat: 48.9777608, lng: 21.9222472 },
    { lat: 48.9778583, lng: 21.9217452 },
    { lat: 48.977995, lng: 21.9214974 },
    { lat: 48.9781422, lng: 21.9209933 },
    { lat: 48.9782434, lng: 21.9210344 },
    { lat: 48.9782933, lng: 21.9206469 },
    { lat: 48.9784416, lng: 21.9205997 },
    { lat: 48.9786891, lng: 21.9202807 },
    { lat: 48.9787321, lng: 21.9198402 },
    { lat: 48.9788057, lng: 21.9198149 },
    { lat: 48.9787275, lng: 21.9196557 },
    { lat: 48.9787523, lng: 21.9193975 },
    { lat: 48.9788488, lng: 21.9192505 },
    { lat: 48.9785728, lng: 21.9189664 },
    { lat: 48.9786958, lng: 21.9186653 },
    { lat: 48.9785751, lng: 21.9184771 },
    { lat: 48.9785826, lng: 21.9184065 },
    { lat: 48.9788111, lng: 21.9184226 },
    { lat: 48.9791542, lng: 21.9177013 },
    { lat: 48.97925, lng: 21.9172141 },
    { lat: 48.9791878, lng: 21.9169713 },
    { lat: 48.9792487, lng: 21.9167583 },
    { lat: 48.9792275, lng: 21.9165433 },
    { lat: 48.9793971, lng: 21.9161725 },
    { lat: 48.979406, lng: 21.9158024 },
    { lat: 48.9794682, lng: 21.9157265 },
    { lat: 48.9795482, lng: 21.9146572 },
    { lat: 48.9796252, lng: 21.9144594 },
    { lat: 48.9795833, lng: 21.9143923 },
    { lat: 48.9796861, lng: 21.9133425 },
    { lat: 48.9795797, lng: 21.9129071 },
    { lat: 48.9795263, lng: 21.9122145 },
    { lat: 48.9797349, lng: 21.9107603 },
    { lat: 48.9797873, lng: 21.9093084 },
    { lat: 48.9798026, lng: 21.9092867 },
  ],
  NižnáJablonka: [
    { lat: 49.108165, lng: 22.104305 },
    { lat: 49.108284, lng: 22.104409 },
    { lat: 49.109084, lng: 22.104921 },
    { lat: 49.10977, lng: 22.105093 },
    { lat: 49.110855, lng: 22.104831 },
    { lat: 49.111154, lng: 22.105048 },
    { lat: 49.111432, lng: 22.105285 },
    { lat: 49.111842, lng: 22.105377 },
    { lat: 49.112231, lng: 22.105428 },
    { lat: 49.1125, lng: 22.10554 },
    { lat: 49.11267, lng: 22.105609 },
    { lat: 49.113347, lng: 22.105767 },
    { lat: 49.11373, lng: 22.105984 },
    { lat: 49.114738, lng: 22.10625 },
    { lat: 49.115503, lng: 22.106533 },
    { lat: 49.116137, lng: 22.106877 },
    { lat: 49.116611, lng: 22.107209 },
    { lat: 49.116816, lng: 22.107199 },
    { lat: 49.117159, lng: 22.107193 },
    { lat: 49.117366, lng: 22.107249 },
    { lat: 49.117939, lng: 22.107287 },
    { lat: 49.118248, lng: 22.107335 },
    { lat: 49.118468, lng: 22.107234 },
    { lat: 49.118791, lng: 22.107008 },
    { lat: 49.119082, lng: 22.106928 },
    { lat: 49.11927, lng: 22.106822 },
    { lat: 49.119606, lng: 22.106861 },
    { lat: 49.119947, lng: 22.107019 },
    { lat: 49.120177, lng: 22.107197 },
    { lat: 49.120424, lng: 22.107286 },
    { lat: 49.120684, lng: 22.107275 },
    { lat: 49.12117, lng: 22.107165 },
    { lat: 49.121759, lng: 22.107079 },
    { lat: 49.121978, lng: 22.107024 },
    { lat: 49.122449, lng: 22.10672 },
    { lat: 49.122913, lng: 22.106545 },
    { lat: 49.123273, lng: 22.106509 },
    { lat: 49.123698, lng: 22.106548 },
    { lat: 49.124204, lng: 22.106385 },
    { lat: 49.124516, lng: 22.106177 },
    { lat: 49.12527, lng: 22.105948 },
    { lat: 49.125711, lng: 22.106001 },
    { lat: 49.126501, lng: 22.10598 },
    { lat: 49.127004, lng: 22.104887 },
    { lat: 49.12706, lng: 22.104468 },
    { lat: 49.127223, lng: 22.104487 },
    { lat: 49.127825, lng: 22.104557 },
    { lat: 49.127848, lng: 22.10456 },
    { lat: 49.127961, lng: 22.104573 },
    { lat: 49.127964, lng: 22.10457 },
    { lat: 49.130334, lng: 22.102509 },
    { lat: 49.130513, lng: 22.103338 },
    { lat: 49.131529, lng: 22.103034 },
    { lat: 49.132415, lng: 22.105446 },
    { lat: 49.132026, lng: 22.106714 },
    { lat: 49.132702, lng: 22.107203 },
    { lat: 49.133535, lng: 22.107537 },
    { lat: 49.1336, lng: 22.107514 },
    { lat: 49.133976, lng: 22.106466 },
    { lat: 49.134033, lng: 22.106394 },
    { lat: 49.13467, lng: 22.107865 },
    { lat: 49.13479, lng: 22.10846 },
    { lat: 49.135042, lng: 22.10901 },
    { lat: 49.135566, lng: 22.109671 },
    { lat: 49.135887, lng: 22.10989 },
    { lat: 49.136131, lng: 22.109955 },
    { lat: 49.136737, lng: 22.110817 },
    { lat: 49.1372929, lng: 22.1092184 },
    { lat: 49.1382111, lng: 22.1102481 },
    { lat: 49.1385836, lng: 22.1099689 },
    { lat: 49.138347, lng: 22.1073434 },
    { lat: 49.1376111, lng: 22.1061272 },
    { lat: 49.137901, lng: 22.103948 },
    { lat: 49.1384368, lng: 22.1024789 },
    { lat: 49.1393931, lng: 22.1009047 },
    { lat: 49.1398251, lng: 22.0997544 },
    { lat: 49.1405806, lng: 22.1002647 },
    { lat: 49.1415313, lng: 22.0979294 },
    { lat: 49.1418559, lng: 22.0969929 },
    { lat: 49.1423404, lng: 22.0953539 },
    { lat: 49.142548, lng: 22.0950901 },
    { lat: 49.1430487, lng: 22.0942387 },
    { lat: 49.1428943, lng: 22.093071 },
    { lat: 49.1432302, lng: 22.0921884 },
    { lat: 49.1431894, lng: 22.0914653 },
    { lat: 49.1442844, lng: 22.0901928 },
    { lat: 49.1454513, lng: 22.090413 },
    { lat: 49.1455021, lng: 22.0901292 },
    { lat: 49.1453515, lng: 22.0881716 },
    { lat: 49.1464263, lng: 22.0871526 },
    { lat: 49.1472305, lng: 22.0865343 },
    { lat: 49.1471216, lng: 22.0862503 },
    { lat: 49.1474655, lng: 22.0859031 },
    { lat: 49.1472627, lng: 22.0852675 },
    { lat: 49.1482024, lng: 22.0839073 },
    { lat: 49.1483472, lng: 22.0822687 },
    { lat: 49.1485175, lng: 22.0816727 },
    { lat: 49.1488424, lng: 22.0815155 },
    { lat: 49.1490313, lng: 22.0806623 },
    { lat: 49.1489489, lng: 22.0805065 },
    { lat: 49.1493842, lng: 22.0789748 },
    { lat: 49.150066, lng: 22.0799431 },
    { lat: 49.1506101, lng: 22.0779692 },
    { lat: 49.151978, lng: 22.0759306 },
    { lat: 49.1527199, lng: 22.0759593 },
    { lat: 49.1530132, lng: 22.0752871 },
    { lat: 49.150093, lng: 22.072641 },
    { lat: 49.149629, lng: 22.071944 },
    { lat: 49.14941, lng: 22.070975 },
    { lat: 49.14938, lng: 22.069864 },
    { lat: 49.149358, lng: 22.069066 },
    { lat: 49.14922, lng: 22.068168 },
    { lat: 49.149119, lng: 22.067785 },
    { lat: 49.148452, lng: 22.066899 },
    { lat: 49.148246, lng: 22.066624 },
    { lat: 49.147586, lng: 22.066087 },
    { lat: 49.146198, lng: 22.065034 },
    { lat: 49.145668, lng: 22.063881 },
    { lat: 49.144748, lng: 22.06188 },
    { lat: 49.14367, lng: 22.061361 },
    { lat: 49.142889, lng: 22.061004 },
    { lat: 49.1428, lng: 22.060963 },
    { lat: 49.142496, lng: 22.060203 },
    { lat: 49.142092, lng: 22.058795 },
    { lat: 49.141725, lng: 22.057459 },
    { lat: 49.142154, lng: 22.056031 },
    { lat: 49.141139, lng: 22.05535 },
    { lat: 49.14123, lng: 22.054429 },
    { lat: 49.141173, lng: 22.053208 },
    { lat: 49.141128, lng: 22.051428 },
    { lat: 49.140822, lng: 22.049897 },
    { lat: 49.140567, lng: 22.04853 },
    { lat: 49.140134, lng: 22.047486 },
    { lat: 49.139179, lng: 22.046278 },
    { lat: 49.138818, lng: 22.045421 },
    { lat: 49.138279, lng: 22.044578 },
    { lat: 49.1379665, lng: 22.0448547 },
    { lat: 49.1376077, lng: 22.0454235 },
    { lat: 49.137135, lng: 22.046496 },
    { lat: 49.1369163, lng: 22.0468526 },
    { lat: 49.1367184, lng: 22.046943 },
    { lat: 49.1350458, lng: 22.0485884 },
    { lat: 49.1356014, lng: 22.0492918 },
    { lat: 49.1342994, lng: 22.0509234 },
    { lat: 49.1336973, lng: 22.0513299 },
    { lat: 49.1333552, lng: 22.0520766 },
    { lat: 49.1321994, lng: 22.0533749 },
    { lat: 49.1319383, lng: 22.0536038 },
    { lat: 49.1315293, lng: 22.0549883 },
    { lat: 49.1308149, lng: 22.0561153 },
    { lat: 49.1296742, lng: 22.0581895 },
    { lat: 49.1287761, lng: 22.0594949 },
    { lat: 49.1289496, lng: 22.0599706 },
    { lat: 49.1278417, lng: 22.0597361 },
    { lat: 49.1274052, lng: 22.0597763 },
    { lat: 49.1273394, lng: 22.0599416 },
    { lat: 49.1269138, lng: 22.0599751 },
    { lat: 49.1265304, lng: 22.0608301 },
    { lat: 49.1260804, lng: 22.0613943 },
    { lat: 49.1257486, lng: 22.0622016 },
    { lat: 49.1248806, lng: 22.0637293 },
    { lat: 49.1244167, lng: 22.0641832 },
    { lat: 49.1236417, lng: 22.0655057 },
    { lat: 49.1234255, lng: 22.066396 },
    { lat: 49.1217842, lng: 22.0705698 },
    { lat: 49.119543, lng: 22.0737734 },
    { lat: 49.1194049, lng: 22.0731195 },
    { lat: 49.1189517, lng: 22.072641 },
    { lat: 49.1186043, lng: 22.0724112 },
    { lat: 49.1184409, lng: 22.0734446 },
    { lat: 49.1182164, lng: 22.0761312 },
    { lat: 49.1182388, lng: 22.0770301 },
    { lat: 49.1178807, lng: 22.0777776 },
    { lat: 49.1174749, lng: 22.078976 },
    { lat: 49.1174502, lng: 22.0794481 },
    { lat: 49.1175707, lng: 22.080653 },
    { lat: 49.1170171, lng: 22.0813134 },
    { lat: 49.1168664, lng: 22.0818868 },
    { lat: 49.1166466, lng: 22.0823415 },
    { lat: 49.1163167, lng: 22.0832748 },
    { lat: 49.1162281, lng: 22.0839187 },
    { lat: 49.1160926, lng: 22.0844596 },
    { lat: 49.1163306, lng: 22.0865912 },
    { lat: 49.1153933, lng: 22.0896389 },
    { lat: 49.1151951, lng: 22.0900597 },
    { lat: 49.1143701, lng: 22.0911405 },
    { lat: 49.1137069, lng: 22.0916305 },
    { lat: 49.1134414, lng: 22.0919164 },
    { lat: 49.1128006, lng: 22.0922615 },
    { lat: 49.1124779, lng: 22.0923164 },
    { lat: 49.1108218, lng: 22.0932025 },
    { lat: 49.1106985, lng: 22.0936522 },
    { lat: 49.1107197, lng: 22.0944151 },
    { lat: 49.1108531, lng: 22.0953498 },
    { lat: 49.1109285, lng: 22.0971318 },
    { lat: 49.1104692, lng: 22.0984086 },
    { lat: 49.1101865, lng: 22.0990601 },
    { lat: 49.109971, lng: 22.0998989 },
    { lat: 49.10961, lng: 22.1006678 },
    { lat: 49.1093335, lng: 22.1014417 },
    { lat: 49.1088333, lng: 22.1030904 },
    { lat: 49.1085344, lng: 22.1038745 },
    { lat: 49.108165, lng: 22.104305 },
  ],
  VyšnéLadičkovce: [
    { lat: 49.0339424, lng: 21.9174536 },
    { lat: 49.0339502, lng: 21.9174575 },
    { lat: 49.0360689, lng: 21.9184204 },
    { lat: 49.0367094, lng: 21.9180388 },
    { lat: 49.0382964, lng: 21.9186103 },
    { lat: 49.0400688, lng: 21.9178817 },
    { lat: 49.0405594, lng: 21.9180258 },
    { lat: 49.0407933, lng: 21.9182933 },
    { lat: 49.0412234, lng: 21.9179418 },
    { lat: 49.0420573, lng: 21.9177803 },
    { lat: 49.0436716, lng: 21.9184617 },
    { lat: 49.0437295, lng: 21.9186563 },
    { lat: 49.0439856, lng: 21.9187368 },
    { lat: 49.044143, lng: 21.9186044 },
    { lat: 49.0444054, lng: 21.9188327 },
    { lat: 49.0459405, lng: 21.9180586 },
    { lat: 49.0462838, lng: 21.9180721 },
    { lat: 49.0465096, lng: 21.9182882 },
    { lat: 49.0464992, lng: 21.9193032 },
    { lat: 49.0466609, lng: 21.9197022 },
    { lat: 49.0465288, lng: 21.920356 },
    { lat: 49.046522, lng: 21.9209804 },
    { lat: 49.0467736, lng: 21.9211928 },
    { lat: 49.0466978, lng: 21.9215342 },
    { lat: 49.046795, lng: 21.9220009 },
    { lat: 49.0467569, lng: 21.9223731 },
    { lat: 49.0468457, lng: 21.9225017 },
    { lat: 49.0468522, lng: 21.9230378 },
    { lat: 49.0470698, lng: 21.9242767 },
    { lat: 49.0470597, lng: 21.9244587 },
    { lat: 49.0470527, lng: 21.924585 },
    { lat: 49.0470944, lng: 21.9246758 },
    { lat: 49.0475295, lng: 21.923808 },
    { lat: 49.0477013, lng: 21.9237027 },
    { lat: 49.0478452, lng: 21.9237062 },
    { lat: 49.0480755, lng: 21.9233745 },
    { lat: 49.0483189, lng: 21.9233902 },
    { lat: 49.0486835, lng: 21.9230403 },
    { lat: 49.048858, lng: 21.9229972 },
    { lat: 49.0489293, lng: 21.92284 },
    { lat: 49.0489276, lng: 21.922546 },
    { lat: 49.0492187, lng: 21.9223951 },
    { lat: 49.0494642, lng: 21.9220962 },
    { lat: 49.0498631, lng: 21.9219408 },
    { lat: 49.0502143, lng: 21.921545 },
    { lat: 49.050726, lng: 21.9213634 },
    { lat: 49.0515305, lng: 21.9208726 },
    { lat: 49.0517249, lng: 21.9210973 },
    { lat: 49.0520256, lng: 21.9208418 },
    { lat: 49.0520118, lng: 21.9206454 },
    { lat: 49.0522565, lng: 21.9205832 },
    { lat: 49.0527919, lng: 21.9202675 },
    { lat: 49.0530708, lng: 21.9204012 },
    { lat: 49.0533392, lng: 21.920153 },
    { lat: 49.0535699, lng: 21.9201138 },
    { lat: 49.0537495, lng: 21.9198771 },
    { lat: 49.0549046, lng: 21.9195625 },
    { lat: 49.0573309, lng: 21.9176729 },
    { lat: 49.0583155, lng: 21.9173254 },
    { lat: 49.0583177, lng: 21.9172338 },
    { lat: 49.0587579, lng: 21.9165319 },
    { lat: 49.0593404, lng: 21.9145868 },
    { lat: 49.0593493, lng: 21.914044 },
    { lat: 49.0591218, lng: 21.9133553 },
    { lat: 49.0592131, lng: 21.9118342 },
    { lat: 49.0593214, lng: 21.9111809 },
    { lat: 49.0592552, lng: 21.9106927 },
    { lat: 49.0593384, lng: 21.9103349 },
    { lat: 49.0597603, lng: 21.9101096 },
    { lat: 49.0599234, lng: 21.9095732 },
    { lat: 49.0604532, lng: 21.9084542 },
    { lat: 49.0606702, lng: 21.908093 },
    { lat: 49.0610763, lng: 21.907686 },
    { lat: 49.0613246, lng: 21.907636 },
    { lat: 49.0618756, lng: 21.9069924 },
    { lat: 49.0620757, lng: 21.9069894 },
    { lat: 49.0628031, lng: 21.9075544 },
    { lat: 49.0640277, lng: 21.9067418 },
    { lat: 49.0643199, lng: 21.9067284 },
    { lat: 49.0646372, lng: 21.9073141 },
    { lat: 49.0650571, lng: 21.9075042 },
    { lat: 49.0660371, lng: 21.9074734 },
    { lat: 49.0664794, lng: 21.9067152 },
    { lat: 49.0669095, lng: 21.9054358 },
    { lat: 49.067559, lng: 21.9037532 },
    { lat: 49.0705858, lng: 21.9055868 },
    { lat: 49.0707177, lng: 21.9064438 },
    { lat: 49.0708995, lng: 21.9082223 },
    { lat: 49.071186, lng: 21.9095158 },
    { lat: 49.071384, lng: 21.9101447 },
    { lat: 49.0713027, lng: 21.9107123 },
    { lat: 49.0709596, lng: 21.9113816 },
    { lat: 49.0714435, lng: 21.9130102 },
    { lat: 49.0715426, lng: 21.9128818 },
    { lat: 49.0718946, lng: 21.9124255 },
    { lat: 49.0731717, lng: 21.9104696 },
    { lat: 49.0739091, lng: 21.9068624 },
    { lat: 49.0745703, lng: 21.9045274 },
    { lat: 49.0772167, lng: 21.9014643 },
    { lat: 49.0781474, lng: 21.8997349 },
    { lat: 49.0800691, lng: 21.8990366 },
    { lat: 49.0807033, lng: 21.89908 },
    { lat: 49.0812942, lng: 21.8988274 },
    { lat: 49.0825354, lng: 21.8975497 },
    { lat: 49.0809525, lng: 21.896103 },
    { lat: 49.0800473, lng: 21.8944177 },
    { lat: 49.0797629, lng: 21.8921956 },
    { lat: 49.0788621, lng: 21.889889 },
    { lat: 49.0787347, lng: 21.8885793 },
    { lat: 49.0787247, lng: 21.8872967 },
    { lat: 49.0784381, lng: 21.8861004 },
    { lat: 49.0779575, lng: 21.8851511 },
    { lat: 49.0777002, lng: 21.8842774 },
    { lat: 49.0770122, lng: 21.884111 },
    { lat: 49.0761219, lng: 21.8819135 },
    { lat: 49.0756619, lng: 21.8800551 },
    { lat: 49.0742553, lng: 21.8778087 },
    { lat: 49.0733236, lng: 21.8770834 },
    { lat: 49.0728382, lng: 21.8779468 },
    { lat: 49.0721441, lng: 21.87901 },
    { lat: 49.0704726, lng: 21.8782155 },
    { lat: 49.0697702, lng: 21.877705 },
    { lat: 49.0690817, lng: 21.8774831 },
    { lat: 49.06828, lng: 21.8768791 },
    { lat: 49.0681242, lng: 21.8764039 },
    { lat: 49.0671005, lng: 21.8761347 },
    { lat: 49.0669528, lng: 21.8757298 },
    { lat: 49.0653903, lng: 21.8756386 },
    { lat: 49.0624036, lng: 21.8748768 },
    { lat: 49.0623798, lng: 21.8747271 },
    { lat: 49.0622517, lng: 21.8746952 },
    { lat: 49.0621271, lng: 21.8743488 },
    { lat: 49.0621993, lng: 21.8741828 },
    { lat: 49.0620885, lng: 21.8722979 },
    { lat: 49.0608337, lng: 21.8706346 },
    { lat: 49.05933, lng: 21.8695351 },
    { lat: 49.0585985, lng: 21.8691689 },
    { lat: 49.0583656, lng: 21.8693013 },
    { lat: 49.0576459, lng: 21.8699262 },
    { lat: 49.0571359, lng: 21.8700878 },
    { lat: 49.0566079, lng: 21.8699395 },
    { lat: 49.0562509, lng: 21.8694821 },
    { lat: 49.0546927, lng: 21.8704406 },
    { lat: 49.0538741, lng: 21.8716472 },
    { lat: 49.053187, lng: 21.8717873 },
    { lat: 49.0525079, lng: 21.871314 },
    { lat: 49.0521498, lng: 21.8711737 },
    { lat: 49.0517618, lng: 21.8711284 },
    { lat: 49.0512247, lng: 21.8711499 },
    { lat: 49.0507184, lng: 21.8712346 },
    { lat: 49.050256, lng: 21.8714127 },
    { lat: 49.0493343, lng: 21.8715229 },
    { lat: 49.0487698, lng: 21.8723769 },
    { lat: 49.0479305, lng: 21.8727242 },
    { lat: 49.0471899, lng: 21.8733275 },
    { lat: 49.0464657, lng: 21.8735562 },
    { lat: 49.0436194, lng: 21.8741594 },
    { lat: 49.0429997, lng: 21.8742123 },
    { lat: 49.0427392, lng: 21.8741828 },
    { lat: 49.0421986, lng: 21.8739743 },
    { lat: 49.042372, lng: 21.8733241 },
    { lat: 49.0429317, lng: 21.8719269 },
    { lat: 49.0422466, lng: 21.8711332 },
    { lat: 49.0409315, lng: 21.8701481 },
    { lat: 49.0403669, lng: 21.8697113 },
    { lat: 49.0395102, lng: 21.8695971 },
    { lat: 49.0389022, lng: 21.8693973 },
    { lat: 49.0382741, lng: 21.8696512 },
    { lat: 49.0377982, lng: 21.8697365 },
    { lat: 49.0362604, lng: 21.8713665 },
    { lat: 49.0361408, lng: 21.873526 },
    { lat: 49.0354648, lng: 21.8739256 },
    { lat: 49.0348056, lng: 21.8740977 },
    { lat: 49.0346486, lng: 21.8737606 },
    { lat: 49.0342439, lng: 21.8740891 },
    { lat: 49.0332128, lng: 21.8745021 },
    { lat: 49.0326477, lng: 21.8752329 },
    { lat: 49.0327682, lng: 21.8753262 },
    { lat: 49.0326676, lng: 21.8765115 },
    { lat: 49.0317717, lng: 21.8772836 },
    { lat: 49.031191, lng: 21.878302 },
    { lat: 49.0312058, lng: 21.8786148 },
    { lat: 49.0309904, lng: 21.8786794 },
    { lat: 49.030844, lng: 21.880271 },
    { lat: 49.0295109, lng: 21.8808904 },
    { lat: 49.0292788, lng: 21.8818482 },
    { lat: 49.0280228, lng: 21.8818275 },
    { lat: 49.0285169, lng: 21.8829445 },
    { lat: 49.0283439, lng: 21.8832619 },
    { lat: 49.0285007, lng: 21.8854611 },
    { lat: 49.0287531, lng: 21.8868124 },
    { lat: 49.0292294, lng: 21.8883848 },
    { lat: 49.0295103, lng: 21.8905098 },
    { lat: 49.0297671, lng: 21.8919982 },
    { lat: 49.0296489, lng: 21.8922643 },
    { lat: 49.0297336, lng: 21.893258 },
    { lat: 49.029862, lng: 21.8941961 },
    { lat: 49.0302401, lng: 21.8962532 },
    { lat: 49.0302874, lng: 21.8962619 },
    { lat: 49.0302994, lng: 21.8963709 },
    { lat: 49.0304931, lng: 21.896603 },
    { lat: 49.0315075, lng: 21.8989209 },
    { lat: 49.0321211, lng: 21.9008206 },
    { lat: 49.0324018, lng: 21.9021877 },
    { lat: 49.0328313, lng: 21.9036522 },
    { lat: 49.0328317, lng: 21.9047459 },
    { lat: 49.0337956, lng: 21.9084085 },
    { lat: 49.0341181, lng: 21.9099234 },
    { lat: 49.034534, lng: 21.9122854 },
    { lat: 49.0345174, lng: 21.9136468 },
    { lat: 49.0343746, lng: 21.9151733 },
    { lat: 49.0341455, lng: 21.9165833 },
    { lat: 49.0339424, lng: 21.9174536 },
  ],
  Hankovce: [
    { lat: 49.0470597, lng: 21.9244587 },
    { lat: 49.0470698, lng: 21.9242767 },
    { lat: 49.0468522, lng: 21.9230378 },
    { lat: 49.0468457, lng: 21.9225017 },
    { lat: 49.0467569, lng: 21.9223731 },
    { lat: 49.046795, lng: 21.9220009 },
    { lat: 49.0466978, lng: 21.9215342 },
    { lat: 49.0467736, lng: 21.9211928 },
    { lat: 49.046522, lng: 21.9209804 },
    { lat: 49.0465288, lng: 21.920356 },
    { lat: 49.0466609, lng: 21.9197022 },
    { lat: 49.0464992, lng: 21.9193032 },
    { lat: 49.0465096, lng: 21.9182882 },
    { lat: 49.0462838, lng: 21.9180721 },
    { lat: 49.0459405, lng: 21.9180586 },
    { lat: 49.0444054, lng: 21.9188327 },
    { lat: 49.044143, lng: 21.9186044 },
    { lat: 49.0439856, lng: 21.9187368 },
    { lat: 49.0437295, lng: 21.9186563 },
    { lat: 49.0436716, lng: 21.9184617 },
    { lat: 49.0420573, lng: 21.9177803 },
    { lat: 49.0412234, lng: 21.9179418 },
    { lat: 49.0407933, lng: 21.9182933 },
    { lat: 49.0405594, lng: 21.9180258 },
    { lat: 49.0400688, lng: 21.9178817 },
    { lat: 49.0382964, lng: 21.9186103 },
    { lat: 49.0367094, lng: 21.9180388 },
    { lat: 49.0360689, lng: 21.9184204 },
    { lat: 49.0339502, lng: 21.9174575 },
    { lat: 49.0339424, lng: 21.9174536 },
    { lat: 49.0330122, lng: 21.918451 },
    { lat: 49.0318358, lng: 21.9193568 },
    { lat: 49.0315992, lng: 21.9194235 },
    { lat: 49.0311771, lng: 21.9199085 },
    { lat: 49.0297524, lng: 21.9206505 },
    { lat: 49.0284019, lng: 21.9200428 },
    { lat: 49.0275026, lng: 21.9201066 },
    { lat: 49.0238486, lng: 21.9192286 },
    { lat: 49.0212551, lng: 21.9200125 },
    { lat: 49.0195392, lng: 21.9217069 },
    { lat: 49.0196803, lng: 21.9219951 },
    { lat: 49.0192543, lng: 21.9224552 },
    { lat: 49.0185679, lng: 21.922765 },
    { lat: 49.0183534, lng: 21.922738 },
    { lat: 49.0182964, lng: 21.9231538 },
    { lat: 49.0183671, lng: 21.9235948 },
    { lat: 49.0185142, lng: 21.9239482 },
    { lat: 49.0186828, lng: 21.924165 },
    { lat: 49.0188675, lng: 21.9245396 },
    { lat: 49.0190901, lng: 21.9255621 },
    { lat: 49.0193684, lng: 21.9263524 },
    { lat: 49.0193427, lng: 21.9265435 },
    { lat: 49.0194366, lng: 21.9268764 },
    { lat: 49.0195804, lng: 21.9271419 },
    { lat: 49.0196379, lng: 21.9275497 },
    { lat: 49.020138, lng: 21.9279423 },
    { lat: 49.0203696, lng: 21.9282977 },
    { lat: 49.0205303, lng: 21.9284268 },
    { lat: 49.0207431, lng: 21.929022 },
    { lat: 49.0207926, lng: 21.9294729 },
    { lat: 49.0209672, lng: 21.9297918 },
    { lat: 49.0212545, lng: 21.9299914 },
    { lat: 49.0213168, lng: 21.9303247 },
    { lat: 49.0214962, lng: 21.9305821 },
    { lat: 49.0215619, lng: 21.9312707 },
    { lat: 49.0216046, lng: 21.9313202 },
    { lat: 49.0212629, lng: 21.9322323 },
    { lat: 49.0209819, lng: 21.9351271 },
    { lat: 49.0210213, lng: 21.9361226 },
    { lat: 49.0209643, lng: 21.936099 },
    { lat: 49.0204606, lng: 21.9365161 },
    { lat: 49.0203744, lng: 21.9367085 },
    { lat: 49.0198421, lng: 21.936838 },
    { lat: 49.0196046, lng: 21.9368341 },
    { lat: 49.0189246, lng: 21.9372591 },
    { lat: 49.0188004, lng: 21.937468 },
    { lat: 49.0184821, lng: 21.9377411 },
    { lat: 49.0181867, lng: 21.938249 },
    { lat: 49.0180736, lng: 21.9382699 },
    { lat: 49.0177702, lng: 21.9386475 },
    { lat: 49.017877, lng: 21.9403196 },
    { lat: 49.0177984, lng: 21.9421406 },
    { lat: 49.0177003, lng: 21.943812 },
    { lat: 49.0173509, lng: 21.946052 },
    { lat: 49.0172562, lng: 21.9461317 },
    { lat: 49.0163888, lng: 21.947947 },
    { lat: 49.0150381, lng: 21.9526795 },
    { lat: 49.0151644, lng: 21.9534238 },
    { lat: 49.0149992, lng: 21.9540231 },
    { lat: 49.0148397, lng: 21.9544382 },
    { lat: 49.0147277, lng: 21.9545997 },
    { lat: 49.0147967, lng: 21.9550638 },
    { lat: 49.0148928, lng: 21.9569518 },
    { lat: 49.0152704, lng: 21.9567131 },
    { lat: 49.0155674, lng: 21.9566281 },
    { lat: 49.0157679, lng: 21.9569101 },
    { lat: 49.0157842, lng: 21.9570647 },
    { lat: 49.0158452, lng: 21.9571666 },
    { lat: 49.0157969, lng: 21.9573575 },
    { lat: 49.0158743, lng: 21.9578476 },
    { lat: 49.0157708, lng: 21.9579359 },
    { lat: 49.0158715, lng: 21.9580036 },
    { lat: 49.0158779, lng: 21.9581771 },
    { lat: 49.0161408, lng: 21.9588483 },
    { lat: 49.0160546, lng: 21.9590428 },
    { lat: 49.0160359, lng: 21.9597964 },
    { lat: 49.0161008, lng: 21.9602747 },
    { lat: 49.0160704, lng: 21.9606878 },
    { lat: 49.0161241, lng: 21.9611355 },
    { lat: 49.0160789, lng: 21.9615857 },
    { lat: 49.0159925, lng: 21.9617254 },
    { lat: 49.0159916, lng: 21.962015 },
    { lat: 49.0160765, lng: 21.9622162 },
    { lat: 49.016138, lng: 21.9622453 },
    { lat: 49.0161829, lng: 21.9626975 },
    { lat: 49.0163318, lng: 21.963405 },
    { lat: 49.016402, lng: 21.9635056 },
    { lat: 49.0165544, lng: 21.9643076 },
    { lat: 49.0166715, lng: 21.9645442 },
    { lat: 49.0167563, lng: 21.96502 },
    { lat: 49.0169258, lng: 21.9652819 },
    { lat: 49.0172367, lng: 21.9655667 },
    { lat: 49.0173083, lng: 21.9654605 },
    { lat: 49.01758, lng: 21.9658507 },
    { lat: 49.017753, lng: 21.9662534 },
    { lat: 49.0177509, lng: 21.9665803 },
    { lat: 49.0179577, lng: 21.9668513 },
    { lat: 49.0182019, lng: 21.9667636 },
    { lat: 49.0185585, lng: 21.9672235 },
    { lat: 49.0188894, lng: 21.9669553 },
    { lat: 49.0190639, lng: 21.9673328 },
    { lat: 49.0199422, lng: 21.966127 },
    { lat: 49.0215014, lng: 21.9649976 },
    { lat: 49.0231825, lng: 21.9641539 },
    { lat: 49.0248358, lng: 21.9628659 },
    { lat: 49.0253697, lng: 21.9629904 },
    { lat: 49.0255361, lng: 21.9631637 },
    { lat: 49.0260482, lng: 21.9634951 },
    { lat: 49.0264112, lng: 21.9635318 },
    { lat: 49.0270039, lng: 21.9632998 },
    { lat: 49.0276089, lng: 21.9629704 },
    { lat: 49.0281961, lng: 21.9624258 },
    { lat: 49.0289071, lng: 21.9616562 },
    { lat: 49.0291693, lng: 21.9614796 },
    { lat: 49.0298551, lng: 21.9623761 },
    { lat: 49.0303585, lng: 21.9627622 },
    { lat: 49.0305862, lng: 21.9627815 },
    { lat: 49.0308434, lng: 21.9625471 },
    { lat: 49.0309852, lng: 21.9623117 },
    { lat: 49.0314628, lng: 21.9622114 },
    { lat: 49.0315532, lng: 21.9620421 },
    { lat: 49.0318054, lng: 21.9620506 },
    { lat: 49.0319082, lng: 21.9619752 },
    { lat: 49.0321055, lng: 21.9621784 },
    { lat: 49.032404, lng: 21.9621542 },
    { lat: 49.032447, lng: 21.9623855 },
    { lat: 49.0324136, lng: 21.9625806 },
    { lat: 49.0323942, lng: 21.9622544 },
    { lat: 49.0323328, lng: 21.9635989 },
    { lat: 49.0321697, lng: 21.9638763 },
    { lat: 49.0322105, lng: 21.9641554 },
    { lat: 49.0323479, lng: 21.9643082 },
    { lat: 49.0323999, lng: 21.9644952 },
    { lat: 49.0326694, lng: 21.9649405 },
    { lat: 49.0327909, lng: 21.9656699 },
    { lat: 49.0329239, lng: 21.9657222 },
    { lat: 49.032959, lng: 21.9656449 },
    { lat: 49.0332612, lng: 21.9654922 },
    { lat: 49.0343446, lng: 21.9652653 },
    { lat: 49.0352547, lng: 21.9654989 },
    { lat: 49.0358967, lng: 21.9659954 },
    { lat: 49.036097, lng: 21.9660887 },
    { lat: 49.036222, lng: 21.9658234 },
    { lat: 49.0364811, lng: 21.9657496 },
    { lat: 49.0375284, lng: 21.9658391 },
    { lat: 49.0374084, lng: 21.9643911 },
    { lat: 49.0373729, lng: 21.961894 },
    { lat: 49.0376371, lng: 21.9616147 },
    { lat: 49.038439, lng: 21.9466298 },
    { lat: 49.0385642, lng: 21.9462799 },
    { lat: 49.0387176, lng: 21.9456111 },
    { lat: 49.038684, lng: 21.9453032 },
    { lat: 49.0389287, lng: 21.944371 },
    { lat: 49.0390327, lng: 21.94426 },
    { lat: 49.0389768, lng: 21.9434114 },
    { lat: 49.0390312, lng: 21.9432021 },
    { lat: 49.0393554, lng: 21.9431674 },
    { lat: 49.0394478, lng: 21.9430355 },
    { lat: 49.0393844, lng: 21.9427036 },
    { lat: 49.0394671, lng: 21.9423195 },
    { lat: 49.0394122, lng: 21.9420567 },
    { lat: 49.0394676, lng: 21.9418348 },
    { lat: 49.0395912, lng: 21.9417936 },
    { lat: 49.0396989, lng: 21.941553 },
    { lat: 49.0398234, lng: 21.9414888 },
    { lat: 49.0399794, lng: 21.9411547 },
    { lat: 49.0401836, lng: 21.9402269 },
    { lat: 49.0406508, lng: 21.9398408 },
    { lat: 49.0408128, lng: 21.9392912 },
    { lat: 49.0408809, lng: 21.9391841 },
    { lat: 49.0411553, lng: 21.9389955 },
    { lat: 49.0413096, lng: 21.9386883 },
    { lat: 49.0414713, lng: 21.9380466 },
    { lat: 49.0413123, lng: 21.938031 },
    { lat: 49.0412287, lng: 21.9379293 },
    { lat: 49.0411776, lng: 21.9376357 },
    { lat: 49.0412268, lng: 21.9373493 },
    { lat: 49.0413318, lng: 21.9373246 },
    { lat: 49.0417131, lng: 21.9374565 },
    { lat: 49.0418877, lng: 21.9371948 },
    { lat: 49.0422908, lng: 21.9370271 },
    { lat: 49.042337, lng: 21.9368556 },
    { lat: 49.0420842, lng: 21.9365492 },
    { lat: 49.0418507, lng: 21.9364748 },
    { lat: 49.0418248, lng: 21.9361152 },
    { lat: 49.0420673, lng: 21.9361809 },
    { lat: 49.0421799, lng: 21.9359614 },
    { lat: 49.0423266, lng: 21.9356594 },
    { lat: 49.042299, lng: 21.9351389 },
    { lat: 49.0424113, lng: 21.9350453 },
    { lat: 49.0426374, lng: 21.9351341 },
    { lat: 49.0431086, lng: 21.9346821 },
    { lat: 49.0430661, lng: 21.9346011 },
    { lat: 49.0430459, lng: 21.9341013 },
    { lat: 49.0432492, lng: 21.9336791 },
    { lat: 49.0436758, lng: 21.933519 },
    { lat: 49.0435684, lng: 21.9327726 },
    { lat: 49.0434863, lng: 21.932636 },
    { lat: 49.0433086, lng: 21.9326753 },
    { lat: 49.0433326, lng: 21.9323821 },
    { lat: 49.0434625, lng: 21.9319002 },
    { lat: 49.0438051, lng: 21.9317041 },
    { lat: 49.0439298, lng: 21.9307019 },
    { lat: 49.0439223, lng: 21.9301745 },
    { lat: 49.0440406, lng: 21.9299551 },
    { lat: 49.0439544, lng: 21.9297976 },
    { lat: 49.0438685, lng: 21.9297714 },
    { lat: 49.0438629, lng: 21.9295967 },
    { lat: 49.0439503, lng: 21.929274 },
    { lat: 49.0440438, lng: 21.9291854 },
    { lat: 49.0442462, lng: 21.9293136 },
    { lat: 49.0443537, lng: 21.9292475 },
    { lat: 49.0444433, lng: 21.9293234 },
    { lat: 49.0446107, lng: 21.9290036 },
    { lat: 49.0447083, lng: 21.928402 },
    { lat: 49.0448288, lng: 21.9280545 },
    { lat: 49.0448597, lng: 21.9280728 },
    { lat: 49.0451872, lng: 21.9272639 },
    { lat: 49.0457534, lng: 21.9261972 },
    { lat: 49.0463455, lng: 21.925268 },
    { lat: 49.0465274, lng: 21.9248032 },
    { lat: 49.0466997, lng: 21.9245878 },
    { lat: 49.0469181, lng: 21.9244601 },
    { lat: 49.0470597, lng: 21.9244587 },
  ],
  Brekov: [
    { lat: 48.9188346, lng: 21.8590698 },
    { lat: 48.9187638, lng: 21.8588121 },
    { lat: 48.9185952, lng: 21.8581997 },
    { lat: 48.9179251, lng: 21.8536012 },
    { lat: 48.9182434, lng: 21.8497892 },
    { lat: 48.9181687, lng: 21.8497704 },
    { lat: 48.9184324, lng: 21.8475909 },
    { lat: 48.9186874, lng: 21.8440397 },
    { lat: 48.9189203, lng: 21.8428542 },
    { lat: 48.9204766, lng: 21.8413455 },
    { lat: 48.9199625, lng: 21.8395514 },
    { lat: 48.9194121, lng: 21.837401 },
    { lat: 48.9192205, lng: 21.8360764 },
    { lat: 48.9192612, lng: 21.8325529 },
    { lat: 48.9194089, lng: 21.8313917 },
    { lat: 48.9183425, lng: 21.8306521 },
    { lat: 48.917216, lng: 21.8290222 },
    { lat: 48.9164465, lng: 21.8276241 },
    { lat: 48.915505, lng: 21.8256271 },
    { lat: 48.915461, lng: 21.8249291 },
    { lat: 48.9153071, lng: 21.8249379 },
    { lat: 48.9139515, lng: 21.8227651 },
    { lat: 48.9135885, lng: 21.8229029 },
    { lat: 48.9119948, lng: 21.8214404 },
    { lat: 48.9108248, lng: 21.8205767 },
    { lat: 48.9104226, lng: 21.8204655 },
    { lat: 48.9090847, lng: 21.819014 },
    { lat: 48.9090992, lng: 21.8175387 },
    { lat: 48.9089223, lng: 21.8172685 },
    { lat: 48.9082438, lng: 21.8169589 },
    { lat: 48.9079283, lng: 21.8159814 },
    { lat: 48.9074603, lng: 21.8142095 },
    { lat: 48.9058847, lng: 21.8145762 },
    { lat: 48.9052763, lng: 21.8165264 },
    { lat: 48.9028225, lng: 21.8156993 },
    { lat: 48.9023686, lng: 21.8141158 },
    { lat: 48.9026039, lng: 21.8130358 },
    { lat: 48.9013365, lng: 21.8120189 },
    { lat: 48.901596, lng: 21.81107 },
    { lat: 48.901369, lng: 21.811246 },
    { lat: 48.901121, lng: 21.811439 },
    { lat: 48.900661, lng: 21.811913 },
    { lat: 48.900255, lng: 21.813 },
    { lat: 48.900113, lng: 21.814816 },
    { lat: 48.899973, lng: 21.81563 },
    { lat: 48.899792, lng: 21.81684 },
    { lat: 48.899701, lng: 21.81773 },
    { lat: 48.899455, lng: 21.818922 },
    { lat: 48.898964, lng: 21.819653 },
    { lat: 48.899096, lng: 21.820901 },
    { lat: 48.89911, lng: 21.821053 },
    { lat: 48.898799, lng: 21.821106 },
    { lat: 48.898435, lng: 21.821258 },
    { lat: 48.898229, lng: 21.821241 },
    { lat: 48.898085, lng: 21.821199 },
    { lat: 48.897791, lng: 21.821127 },
    { lat: 48.897653, lng: 21.820995 },
    { lat: 48.897568, lng: 21.820913 },
    { lat: 48.897425, lng: 21.820967 },
    { lat: 48.897313, lng: 21.820958 },
    { lat: 48.897211, lng: 21.821062 },
    { lat: 48.897105, lng: 21.821206 },
    { lat: 48.896388, lng: 21.821395 },
    { lat: 48.896158, lng: 21.821451 },
    { lat: 48.896081, lng: 21.821399 },
    { lat: 48.895875, lng: 21.821394 },
    { lat: 48.895657, lng: 21.821598 },
    { lat: 48.895592, lng: 21.8217 },
    { lat: 48.895446, lng: 21.821696 },
    { lat: 48.89536, lng: 21.821684 },
    { lat: 48.895075, lng: 21.821707 },
    { lat: 48.89495, lng: 21.821682 },
    { lat: 48.894851, lng: 21.821717 },
    { lat: 48.89466, lng: 21.821736 },
    { lat: 48.894417, lng: 21.821892 },
    { lat: 48.894157, lng: 21.821963 },
    { lat: 48.893976, lng: 21.822083 },
    { lat: 48.893909, lng: 21.822178 },
    { lat: 48.893722, lng: 21.822403 },
    { lat: 48.893468, lng: 21.822593 },
    { lat: 48.893124, lng: 21.822811 },
    { lat: 48.892801, lng: 21.823044 },
    { lat: 48.892702, lng: 21.823143 },
    { lat: 48.892801, lng: 21.823446 },
    { lat: 48.892754, lng: 21.823555 },
    { lat: 48.892594, lng: 21.823712 },
    { lat: 48.89218, lng: 21.823882 },
    { lat: 48.891703, lng: 21.823912 },
    { lat: 48.891645, lng: 21.823928 },
    { lat: 48.891548, lng: 21.823631 },
    { lat: 48.890919, lng: 21.82401 },
    { lat: 48.890472, lng: 21.824266 },
    { lat: 48.889955, lng: 21.824771 },
    { lat: 48.889142, lng: 21.825576 },
    { lat: 48.889067, lng: 21.825753 },
    { lat: 48.888917, lng: 21.826034 },
    { lat: 48.888738, lng: 21.826547 },
    { lat: 48.888647, lng: 21.82683 },
    { lat: 48.888682, lng: 21.826943 },
    { lat: 48.888663, lng: 21.827024 },
    { lat: 48.888758, lng: 21.82721 },
    { lat: 48.889227, lng: 21.827644 },
    { lat: 48.889272, lng: 21.827686 },
    { lat: 48.889641, lng: 21.828237 },
    { lat: 48.88974, lng: 21.82848 },
    { lat: 48.889942, lng: 21.828897 },
    { lat: 48.890121, lng: 21.829298 },
    { lat: 48.890371, lng: 21.829831 },
    { lat: 48.890495, lng: 21.830133 },
    { lat: 48.890787, lng: 21.830722 },
    { lat: 48.890941, lng: 21.831036 },
    { lat: 48.890961, lng: 21.831101 },
    { lat: 48.890992, lng: 21.831209 },
    { lat: 48.891014, lng: 21.831284 },
    { lat: 48.891016, lng: 21.831293 },
    { lat: 48.891023, lng: 21.831311 },
    { lat: 48.891077, lng: 21.831497 },
    { lat: 48.891068, lng: 21.831506 },
    { lat: 48.891004, lng: 21.831569 },
    { lat: 48.891008, lng: 21.831706 },
    { lat: 48.89107, lng: 21.831996 },
    { lat: 48.891104, lng: 21.832331 },
    { lat: 48.891147, lng: 21.83242 },
    { lat: 48.891229, lng: 21.83259 },
    { lat: 48.891342, lng: 21.832796 },
    { lat: 48.891337, lng: 21.832808 },
    { lat: 48.891319, lng: 21.832846 },
    { lat: 48.89112, lng: 21.832911 },
    { lat: 48.890682, lng: 21.833059 },
    { lat: 48.890272, lng: 21.83315 },
    { lat: 48.890085, lng: 21.833155 },
    { lat: 48.889977, lng: 21.833156 },
    { lat: 48.889867, lng: 21.833139 },
    { lat: 48.889816, lng: 21.833158 },
    { lat: 48.889803, lng: 21.833082 },
    { lat: 48.889739, lng: 21.832695 },
    { lat: 48.889299, lng: 21.832743 },
    { lat: 48.889228, lng: 21.832749 },
    { lat: 48.889213, lng: 21.832751 },
    { lat: 48.889101, lng: 21.832766 },
    { lat: 48.889087, lng: 21.832768 },
    { lat: 48.889034, lng: 21.832775 },
    { lat: 48.888976, lng: 21.832782 },
    { lat: 48.88891, lng: 21.832789 },
    { lat: 48.888782, lng: 21.832783 },
    { lat: 48.888745, lng: 21.832782 },
    { lat: 48.888674, lng: 21.832778 },
    { lat: 48.888644, lng: 21.832777 },
    { lat: 48.888577, lng: 21.832774 },
    { lat: 48.888507, lng: 21.832771 },
    { lat: 48.888476, lng: 21.832769 },
    { lat: 48.888246, lng: 21.832759 },
    { lat: 48.887563, lng: 21.832263 },
    { lat: 48.887307, lng: 21.83219 },
    { lat: 48.887277, lng: 21.832075 },
    { lat: 48.886803, lng: 21.831957 },
    { lat: 48.886845, lng: 21.832505 },
    { lat: 48.886705, lng: 21.832741 },
    { lat: 48.886634, lng: 21.832891 },
    { lat: 48.886606, lng: 21.832956 },
    { lat: 48.886585, lng: 21.833004 },
    { lat: 48.886548, lng: 21.833191 },
    { lat: 48.886534, lng: 21.833358 },
    { lat: 48.886538, lng: 21.833636 },
    { lat: 48.886549, lng: 21.833861 },
    { lat: 48.886592, lng: 21.834079 },
    { lat: 48.886605, lng: 21.834171 },
    { lat: 48.886614, lng: 21.834224 },
    { lat: 48.886622, lng: 21.834279 },
    { lat: 48.886622, lng: 21.834286 },
    { lat: 48.886604, lng: 21.834394 },
    { lat: 48.886607, lng: 21.834455 },
    { lat: 48.886608, lng: 21.834486 },
    { lat: 48.886608, lng: 21.834505 },
    { lat: 48.886611, lng: 21.834553 },
    { lat: 48.886612, lng: 21.834584 },
    { lat: 48.886705, lng: 21.834844 },
    { lat: 48.886741, lng: 21.834988 },
    { lat: 48.886784, lng: 21.835221 },
    { lat: 48.88681, lng: 21.835295 },
    { lat: 48.88752, lng: 21.835381 },
    { lat: 48.88829, lng: 21.835414 },
    { lat: 48.88897, lng: 21.835392 },
    { lat: 48.889025, lng: 21.835893 },
    { lat: 48.889328, lng: 21.838184 },
    { lat: 48.889395, lng: 21.838348 },
    { lat: 48.889473, lng: 21.838678 },
    { lat: 48.889747, lng: 21.839842 },
    { lat: 48.889729, lng: 21.840504 },
    { lat: 48.890763, lng: 21.840657 },
    { lat: 48.891157, lng: 21.840789 },
    { lat: 48.892367, lng: 21.841235 },
    { lat: 48.892325, lng: 21.841604 },
    { lat: 48.892077, lng: 21.844019 },
    { lat: 48.892316, lng: 21.844906 },
    { lat: 48.892841, lng: 21.846338 },
    { lat: 48.893715, lng: 21.846877 },
    { lat: 48.89377, lng: 21.84747 },
    { lat: 48.893899, lng: 21.848835 },
    { lat: 48.89398, lng: 21.849451 },
    { lat: 48.893705, lng: 21.850304 },
    { lat: 48.893401, lng: 21.851378 },
    { lat: 48.893477, lng: 21.851932 },
    { lat: 48.893475, lng: 21.853047 },
    { lat: 48.893499, lng: 21.853968 },
    { lat: 48.893563, lng: 21.855341 },
    { lat: 48.8935195, lng: 21.8565947 },
    { lat: 48.893361, lng: 21.857788 },
    { lat: 48.893145, lng: 21.858752 },
    { lat: 48.892967, lng: 21.861889 },
    { lat: 48.892917, lng: 21.864165 },
    { lat: 48.892627, lng: 21.867164 },
    { lat: 48.892553, lng: 21.86817 },
    { lat: 48.8948541, lng: 21.8680456 },
    { lat: 48.895633, lng: 21.8677043 },
    { lat: 48.8965722, lng: 21.8669133 },
    { lat: 48.8972831, lng: 21.865941 },
    { lat: 48.8989013, lng: 21.8647644 },
    { lat: 48.8990063, lng: 21.8646037 },
    { lat: 48.9001025, lng: 21.8645887 },
    { lat: 48.9007715, lng: 21.8642183 },
    { lat: 48.9011456, lng: 21.8637558 },
    { lat: 48.9012469, lng: 21.8634498 },
    { lat: 48.9027332, lng: 21.863332 },
    { lat: 48.9030544, lng: 21.8636235 },
    { lat: 48.903732, lng: 21.8645592 },
    { lat: 48.9042863, lng: 21.8662229 },
    { lat: 48.9052031, lng: 21.866708 },
    { lat: 48.9063498, lng: 21.8668055 },
    { lat: 48.9069949, lng: 21.8672249 },
    { lat: 48.9080803, lng: 21.8677459 },
    { lat: 48.9093075, lng: 21.8665563 },
    { lat: 48.9096671, lng: 21.8663199 },
    { lat: 48.909803, lng: 21.8656507 },
    { lat: 48.9104435, lng: 21.8610831 },
    { lat: 48.9120155, lng: 21.8604473 },
    { lat: 48.911999, lng: 21.8602877 },
    { lat: 48.9123662, lng: 21.8596874 },
    { lat: 48.9158005, lng: 21.8567842 },
    { lat: 48.9158151, lng: 21.8568205 },
    { lat: 48.9159481, lng: 21.856704 },
    { lat: 48.9179867, lng: 21.8607114 },
    { lat: 48.9181995, lng: 21.8604982 },
    { lat: 48.9185899, lng: 21.8597534 },
    { lat: 48.9188346, lng: 21.8590698 },
  ],
  Papín: [
    { lat: 49.068164, lng: 22.0310174 },
    { lat: 49.068259, lng: 22.0318795 },
    { lat: 49.0682339, lng: 22.0325252 },
    { lat: 49.0685634, lng: 22.0338409 },
    { lat: 49.0684816, lng: 22.034645 },
    { lat: 49.0685271, lng: 22.0350364 },
    { lat: 49.0683815, lng: 22.0357658 },
    { lat: 49.0685731, lng: 22.0362595 },
    { lat: 49.0688996, lng: 22.0364573 },
    { lat: 49.0691658, lng: 22.0368163 },
    { lat: 49.0693642, lng: 22.0373029 },
    { lat: 49.069667, lng: 22.0377348 },
    { lat: 49.070043, lng: 22.0386922 },
    { lat: 49.0703062, lng: 22.0391102 },
    { lat: 49.0707556, lng: 22.0404585 },
    { lat: 49.0707356, lng: 22.0411666 },
    { lat: 49.0708412, lng: 22.0417319 },
    { lat: 49.0708296, lng: 22.0423176 },
    { lat: 49.0711364, lng: 22.044571 },
    { lat: 49.0712975, lng: 22.0461843 },
    { lat: 49.0718851, lng: 22.0504408 },
    { lat: 49.0722781, lng: 22.0513059 },
    { lat: 49.0724139, lng: 22.0520987 },
    { lat: 49.0724583, lng: 22.0520737 },
    { lat: 49.0725314, lng: 22.0524011 },
    { lat: 49.0723584, lng: 22.0529358 },
    { lat: 49.0722842, lng: 22.0534106 },
    { lat: 49.0723207, lng: 22.0540902 },
    { lat: 49.0721628, lng: 22.0544006 },
    { lat: 49.0721564, lng: 22.0545541 },
    { lat: 49.0722747, lng: 22.0547026 },
    { lat: 49.0722495, lng: 22.0548733 },
    { lat: 49.0720503, lng: 22.0551598 },
    { lat: 49.0719467, lng: 22.0557642 },
    { lat: 49.0719831, lng: 22.0561063 },
    { lat: 49.0718891, lng: 22.0562786 },
    { lat: 49.0717796, lng: 22.057339 },
    { lat: 49.0719014, lng: 22.0575468 },
    { lat: 49.0720988, lng: 22.0577117 },
    { lat: 49.0721606, lng: 22.0598822 },
    { lat: 49.0727437, lng: 22.0610432 },
    { lat: 49.0734891, lng: 22.066053 },
    { lat: 49.0727231, lng: 22.0664707 },
    { lat: 49.0723782, lng: 22.0667741 },
    { lat: 49.072517, lng: 22.0696478 },
    { lat: 49.0725236, lng: 22.0722074 },
    { lat: 49.0726865, lng: 22.0732826 },
    { lat: 49.0732482, lng: 22.0745422 },
    { lat: 49.0732299, lng: 22.0747236 },
    { lat: 49.0736764, lng: 22.0750461 },
    { lat: 49.0737847, lng: 22.0752131 },
    { lat: 49.0737234, lng: 22.0754686 },
    { lat: 49.0737535, lng: 22.0755556 },
    { lat: 49.0741854, lng: 22.0764098 },
    { lat: 49.0745449, lng: 22.0773899 },
    { lat: 49.074536, lng: 22.0782094 },
    { lat: 49.07463, lng: 22.0785196 },
    { lat: 49.0747688, lng: 22.0794982 },
    { lat: 49.075049, lng: 22.080114 },
    { lat: 49.078849, lng: 22.0823832 },
    { lat: 49.0789009, lng: 22.0823456 },
    { lat: 49.0805693, lng: 22.0834115 },
    { lat: 49.0815025, lng: 22.0841588 },
    { lat: 49.0834896, lng: 22.0865814 },
    { lat: 49.0869437, lng: 22.0916599 },
    { lat: 49.0877321, lng: 22.0920928 },
    { lat: 49.0889526, lng: 22.093531 },
    { lat: 49.0893944, lng: 22.0935683 },
    { lat: 49.0896048, lng: 22.0939053 },
    { lat: 49.0915083, lng: 22.0957486 },
    { lat: 49.0919762, lng: 22.0958936 },
    { lat: 49.0922445, lng: 22.0962594 },
    { lat: 49.0924685, lng: 22.0959285 },
    { lat: 49.0928773, lng: 22.0955632 },
    { lat: 49.0930386, lng: 22.0955889 },
    { lat: 49.0933625, lng: 22.0954414 },
    { lat: 49.0934997, lng: 22.0955433 },
    { lat: 49.0935987, lng: 22.0959227 },
    { lat: 49.0943538, lng: 22.0968477 },
    { lat: 49.0945974, lng: 22.0970254 },
    { lat: 49.0945509, lng: 22.097445 },
    { lat: 49.0946604, lng: 22.0985143 },
    { lat: 49.0947998, lng: 22.0986513 },
    { lat: 49.0959223, lng: 22.1002886 },
    { lat: 49.0989809, lng: 22.1043427 },
    { lat: 49.099565, lng: 22.105136 },
    { lat: 49.100714, lng: 22.10379 },
    { lat: 49.101138, lng: 22.103293 },
    { lat: 49.102133, lng: 22.102979 },
    { lat: 49.102272, lng: 22.103024 },
    { lat: 49.102621, lng: 22.103202 },
    { lat: 49.102789, lng: 22.103225 },
    { lat: 49.103097, lng: 22.103294 },
    { lat: 49.10325, lng: 22.10342 },
    { lat: 49.103602, lng: 22.103648 },
    { lat: 49.10396, lng: 22.103664 },
    { lat: 49.104254, lng: 22.103642 },
    { lat: 49.104612, lng: 22.103535 },
    { lat: 49.104807, lng: 22.103691 },
    { lat: 49.104961, lng: 22.103629 },
    { lat: 49.105204, lng: 22.103736 },
    { lat: 49.105315, lng: 22.103604 },
    { lat: 49.105591, lng: 22.103723 },
    { lat: 49.10583, lng: 22.10395 },
    { lat: 49.106043, lng: 22.104054 },
    { lat: 49.106231, lng: 22.104103 },
    { lat: 49.106887, lng: 22.104148 },
    { lat: 49.107188, lng: 22.104551 },
    { lat: 49.107231, lng: 22.104581 },
    { lat: 49.107426, lng: 22.104417 },
    { lat: 49.107907, lng: 22.104403 },
    { lat: 49.108165, lng: 22.104305 },
    { lat: 49.1085344, lng: 22.1038745 },
    { lat: 49.1088333, lng: 22.1030904 },
    { lat: 49.1093335, lng: 22.1014417 },
    { lat: 49.10961, lng: 22.1006678 },
    { lat: 49.109971, lng: 22.0998989 },
    { lat: 49.1101865, lng: 22.0990601 },
    { lat: 49.1104692, lng: 22.0984086 },
    { lat: 49.1109285, lng: 22.0971318 },
    { lat: 49.1108531, lng: 22.0953498 },
    { lat: 49.1107197, lng: 22.0944151 },
    { lat: 49.1106985, lng: 22.0936522 },
    { lat: 49.1108218, lng: 22.0932025 },
    { lat: 49.1124779, lng: 22.0923164 },
    { lat: 49.1128006, lng: 22.0922615 },
    { lat: 49.1134414, lng: 22.0919164 },
    { lat: 49.1137069, lng: 22.0916305 },
    { lat: 49.1143701, lng: 22.0911405 },
    { lat: 49.1151951, lng: 22.0900597 },
    { lat: 49.1153933, lng: 22.0896389 },
    { lat: 49.1163306, lng: 22.0865912 },
    { lat: 49.1160926, lng: 22.0844596 },
    { lat: 49.1162281, lng: 22.0839187 },
    { lat: 49.1163167, lng: 22.0832748 },
    { lat: 49.1166466, lng: 22.0823415 },
    { lat: 49.1168664, lng: 22.0818868 },
    { lat: 49.1170171, lng: 22.0813134 },
    { lat: 49.1175707, lng: 22.080653 },
    { lat: 49.1174502, lng: 22.0794481 },
    { lat: 49.1174749, lng: 22.078976 },
    { lat: 49.1178807, lng: 22.0777776 },
    { lat: 49.1182388, lng: 22.0770301 },
    { lat: 49.1182164, lng: 22.0761312 },
    { lat: 49.1184409, lng: 22.0734446 },
    { lat: 49.1186043, lng: 22.0724112 },
    { lat: 49.1189517, lng: 22.072641 },
    { lat: 49.1194049, lng: 22.0731195 },
    { lat: 49.119543, lng: 22.0737734 },
    { lat: 49.1217842, lng: 22.0705698 },
    { lat: 49.1234255, lng: 22.066396 },
    { lat: 49.1236417, lng: 22.0655057 },
    { lat: 49.1244167, lng: 22.0641832 },
    { lat: 49.1248806, lng: 22.0637293 },
    { lat: 49.1257486, lng: 22.0622016 },
    { lat: 49.1260804, lng: 22.0613943 },
    { lat: 49.1265304, lng: 22.0608301 },
    { lat: 49.1269138, lng: 22.0599751 },
    { lat: 49.1273394, lng: 22.0599416 },
    { lat: 49.1274052, lng: 22.0597763 },
    { lat: 49.1278417, lng: 22.0597361 },
    { lat: 49.1289496, lng: 22.0599706 },
    { lat: 49.1287761, lng: 22.0594949 },
    { lat: 49.1296742, lng: 22.0581895 },
    { lat: 49.1308149, lng: 22.0561153 },
    { lat: 49.1315293, lng: 22.0549883 },
    { lat: 49.1319383, lng: 22.0536038 },
    { lat: 49.1321994, lng: 22.0533749 },
    { lat: 49.1333552, lng: 22.0520766 },
    { lat: 49.1336973, lng: 22.0513299 },
    { lat: 49.1342994, lng: 22.0509234 },
    { lat: 49.1356014, lng: 22.0492918 },
    { lat: 49.1350458, lng: 22.0485884 },
    { lat: 49.1347639, lng: 22.0486072 },
    { lat: 49.134455, lng: 22.0485284 },
    { lat: 49.1341733, lng: 22.0481339 },
    { lat: 49.1337615, lng: 22.0477468 },
    { lat: 49.1334439, lng: 22.0476294 },
    { lat: 49.1326163, lng: 22.0474971 },
    { lat: 49.1320478, lng: 22.0472394 },
    { lat: 49.1313076, lng: 22.046435 },
    { lat: 49.1310154, lng: 22.0458331 },
    { lat: 49.1307322, lng: 22.0455596 },
    { lat: 49.1301975, lng: 22.0448825 },
    { lat: 49.1297332, lng: 22.0439058 },
    { lat: 49.1296776, lng: 22.0432255 },
    { lat: 49.1297228, lng: 22.0429489 },
    { lat: 49.1295548, lng: 22.0423667 },
    { lat: 49.1293374, lng: 22.0420286 },
    { lat: 49.1287493, lng: 22.0414608 },
    { lat: 49.1284196, lng: 22.0413175 },
    { lat: 49.1279582, lng: 22.041398 },
    { lat: 49.1270949, lng: 22.0412333 },
    { lat: 49.1261574, lng: 22.0408289 },
    { lat: 49.1254475, lng: 22.0397454 },
    { lat: 49.1250485, lng: 22.0396595 },
    { lat: 49.1247969, lng: 22.0390611 },
    { lat: 49.1233573, lng: 22.0402418 },
    { lat: 49.1223726, lng: 22.0409216 },
    { lat: 49.1207723, lng: 22.0389544 },
    { lat: 49.1203855, lng: 22.0378282 },
    { lat: 49.1196959, lng: 22.0369861 },
    { lat: 49.1188846, lng: 22.0357418 },
    { lat: 49.118431, lng: 22.0348883 },
    { lat: 49.116224, lng: 22.0328561 },
    { lat: 49.1145633, lng: 22.0323604 },
    { lat: 49.113935, lng: 22.0328075 },
    { lat: 49.1131642, lng: 22.0328429 },
    { lat: 49.1114793, lng: 22.0334059 },
    { lat: 49.1113233, lng: 22.034123 },
    { lat: 49.1111535, lng: 22.0343543 },
    { lat: 49.1109883, lng: 22.0344529 },
    { lat: 49.1103266, lng: 22.0343023 },
    { lat: 49.1097296, lng: 22.0338375 },
    { lat: 49.107963, lng: 22.0331453 },
    { lat: 49.107038, lng: 22.0346367 },
    { lat: 49.1055485, lng: 22.033499 },
    { lat: 49.1054393, lng: 22.0327853 },
    { lat: 49.1041652, lng: 22.03261 },
    { lat: 49.1032117, lng: 22.032625 },
    { lat: 49.1030697, lng: 22.032475 },
    { lat: 49.1019242, lng: 22.0328252 },
    { lat: 49.1015952, lng: 22.0328474 },
    { lat: 49.1009505, lng: 22.0325567 },
    { lat: 49.1008967, lng: 22.0324681 },
    { lat: 49.1006336, lng: 22.03257 },
    { lat: 49.100595, lng: 22.032369 },
    { lat: 49.1003727, lng: 22.0324255 },
    { lat: 49.0998214, lng: 22.0312444 },
    { lat: 49.0992618, lng: 22.0313617 },
    { lat: 49.0985183, lng: 22.0310797 },
    { lat: 49.0970639, lng: 22.0299526 },
    { lat: 49.0960406, lng: 22.0295448 },
    { lat: 49.0928334, lng: 22.0278812 },
    { lat: 49.0895013, lng: 22.0266896 },
    { lat: 49.0893481, lng: 22.0258886 },
    { lat: 49.0889988, lng: 22.0268866 },
    { lat: 49.0887837, lng: 22.0288411 },
    { lat: 49.0879979, lng: 22.028947 },
    { lat: 49.0879339, lng: 22.0280692 },
    { lat: 49.0872687, lng: 22.0280001 },
    { lat: 49.087151, lng: 22.0287207 },
    { lat: 49.0846666, lng: 22.0288703 },
    { lat: 49.0842337, lng: 22.0290573 },
    { lat: 49.0838056, lng: 22.0294091 },
    { lat: 49.0830766, lng: 22.0310877 },
    { lat: 49.0820256, lng: 22.0312115 },
    { lat: 49.0819985, lng: 22.0314853 },
    { lat: 49.081684, lng: 22.0320611 },
    { lat: 49.0810757, lng: 22.0317024 },
    { lat: 49.0810212, lng: 22.032474 },
    { lat: 49.079613, lng: 22.0327006 },
    { lat: 49.078498, lng: 22.0319887 },
    { lat: 49.076338, lng: 22.0321605 },
    { lat: 49.075661, lng: 22.0323061 },
    { lat: 49.0747863, lng: 22.0326931 },
    { lat: 49.0730609, lng: 22.0323679 },
    { lat: 49.0716511, lng: 22.0338429 },
    { lat: 49.0705771, lng: 22.0329634 },
    { lat: 49.0694017, lng: 22.0318812 },
    { lat: 49.068164, lng: 22.0310174 },
  ],
  Chlmec: [
    { lat: 48.8741671, lng: 21.9605821 },
    { lat: 48.874547, lng: 21.960301 },
    { lat: 48.8750287, lng: 21.9620783 },
    { lat: 48.8759351, lng: 21.9616137 },
    { lat: 48.8764637, lng: 21.9621161 },
    { lat: 48.8767843, lng: 21.9625743 },
    { lat: 48.8771237, lng: 21.9631085 },
    { lat: 48.8774425, lng: 21.9639438 },
    { lat: 48.877888, lng: 21.9640372 },
    { lat: 48.8785322, lng: 21.9638344 },
    { lat: 48.8796463, lng: 21.9639931 },
    { lat: 48.880014, lng: 21.9639908 },
    { lat: 48.8804029, lng: 21.9641128 },
    { lat: 48.8808832, lng: 21.9641598 },
    { lat: 48.8813394, lng: 21.963323 },
    { lat: 48.8818329, lng: 21.9629362 },
    { lat: 48.8824207, lng: 21.962876 },
    { lat: 48.8829655, lng: 21.9627081 },
    { lat: 48.8834594, lng: 21.962397 },
    { lat: 48.8836854, lng: 21.962382 },
    { lat: 48.8845957, lng: 21.9627163 },
    { lat: 48.8856994, lng: 21.9633192 },
    { lat: 48.8861467, lng: 21.9633906 },
    { lat: 48.8884916, lng: 21.9616471 },
    { lat: 48.8891347, lng: 21.9617636 },
    { lat: 48.8921463, lng: 21.9612332 },
    { lat: 48.8924895, lng: 21.9598681 },
    { lat: 48.8928846, lng: 21.9586599 },
    { lat: 48.8931209, lng: 21.9581113 },
    { lat: 48.8933802, lng: 21.956998 },
    { lat: 48.8938875, lng: 21.9562277 },
    { lat: 48.8940399, lng: 21.9557468 },
    { lat: 48.8940356, lng: 21.9554389 },
    { lat: 48.8941584, lng: 21.9549922 },
    { lat: 48.8941262, lng: 21.9545943 },
    { lat: 48.8943102, lng: 21.9538968 },
    { lat: 48.8941267, lng: 21.9533964 },
    { lat: 48.8941744, lng: 21.9524581 },
    { lat: 48.8942882, lng: 21.9518761 },
    { lat: 48.8948008, lng: 21.9514075 },
    { lat: 48.895239, lng: 21.950068 },
    { lat: 48.8951541, lng: 21.9497339 },
    { lat: 48.895351, lng: 21.9487392 },
    { lat: 48.8969496, lng: 21.9465837 },
    { lat: 48.8983799, lng: 21.9456039 },
    { lat: 48.8984775, lng: 21.9454646 },
    { lat: 48.8980169, lng: 21.9446282 },
    { lat: 48.8963012, lng: 21.9385121 },
    { lat: 48.8966444, lng: 21.9383302 },
    { lat: 48.8966529, lng: 21.9381799 },
    { lat: 48.8965891, lng: 21.9380606 },
    { lat: 48.8966359, lng: 21.9378105 },
    { lat: 48.8965312, lng: 21.9376284 },
    { lat: 48.896278, lng: 21.9350568 },
    { lat: 48.8961358, lng: 21.935259 },
    { lat: 48.8958198, lng: 21.9353515 },
    { lat: 48.8957833, lng: 21.9337059 },
    { lat: 48.8954127, lng: 21.932468 },
    { lat: 48.8951395, lng: 21.9310802 },
    { lat: 48.8951012, lng: 21.9305383 },
    { lat: 48.8954297, lng: 21.9279761 },
    { lat: 48.8946983, lng: 21.9267834 },
    { lat: 48.8944813, lng: 21.9268792 },
    { lat: 48.8942823, lng: 21.9268384 },
    { lat: 48.8938699, lng: 21.9264124 },
    { lat: 48.8935225, lng: 21.9261511 },
    { lat: 48.8931933, lng: 21.9257596 },
    { lat: 48.8930788, lng: 21.9261259 },
    { lat: 48.8914655, lng: 21.9243634 },
    { lat: 48.8907541, lng: 21.9232261 },
    { lat: 48.8902115, lng: 21.9224845 },
    { lat: 48.888989, lng: 21.9206115 },
    { lat: 48.8891618, lng: 21.9182605 },
    { lat: 48.8869522, lng: 21.9155216 },
    { lat: 48.8865904, lng: 21.9159371 },
    { lat: 48.8860245, lng: 21.916343 },
    { lat: 48.8857576, lng: 21.9166407 },
    { lat: 48.8850436, lng: 21.9169229 },
    { lat: 48.884372, lng: 21.917408 },
    { lat: 48.8837362, lng: 21.9183923 },
    { lat: 48.8833572, lng: 21.9188484 },
    { lat: 48.8826926, lng: 21.9191262 },
    { lat: 48.8819299, lng: 21.9191212 },
    { lat: 48.8807804, lng: 21.9199316 },
    { lat: 48.8793921, lng: 21.9189866 },
    { lat: 48.8788642, lng: 21.9190564 },
    { lat: 48.8786399, lng: 21.9188634 },
    { lat: 48.8781251, lng: 21.9189224 },
    { lat: 48.877614, lng: 21.9188633 },
    { lat: 48.877299, lng: 21.918165 },
    { lat: 48.87682, lng: 21.918951 },
    { lat: 48.876541, lng: 21.920258 },
    { lat: 48.876144, lng: 21.921063 },
    { lat: 48.875694, lng: 21.921305 },
    { lat: 48.875394, lng: 21.921448 },
    { lat: 48.875124, lng: 21.92217 },
    { lat: 48.874572, lng: 21.923311 },
    { lat: 48.874268, lng: 21.924364 },
    { lat: 48.874319, lng: 21.925195 },
    { lat: 48.874271, lng: 21.926035 },
    { lat: 48.874267, lng: 21.926057 },
    { lat: 48.874042, lng: 21.927302 },
    { lat: 48.87383, lng: 21.927985 },
    { lat: 48.87379, lng: 21.928773 },
    { lat: 48.873435, lng: 21.929391 },
    { lat: 48.87273, lng: 21.930185 },
    { lat: 48.871988, lng: 21.930724 },
    { lat: 48.871535, lng: 21.931276 },
    { lat: 48.871509, lng: 21.93197 },
    { lat: 48.871534, lng: 21.933064 },
    { lat: 48.871529, lng: 21.933175 },
    { lat: 48.871492, lng: 21.933841 },
    { lat: 48.871438, lng: 21.934543 },
    { lat: 48.871437, lng: 21.935596 },
    { lat: 48.871453, lng: 21.936005 },
    { lat: 48.871503, lng: 21.936746 },
    { lat: 48.871383, lng: 21.938164 },
    { lat: 48.871069, lng: 21.939509 },
    { lat: 48.870999, lng: 21.940022 },
    { lat: 48.870926, lng: 21.940556 },
    { lat: 48.870871, lng: 21.941095 },
    { lat: 48.870862, lng: 21.941183 },
    { lat: 48.870355, lng: 21.942341 },
    { lat: 48.87024, lng: 21.943007 },
    { lat: 48.870265, lng: 21.943759 },
    { lat: 48.869661, lng: 21.944727 },
    { lat: 48.8705794, lng: 21.9454158 },
    { lat: 48.8712024, lng: 21.94645 },
    { lat: 48.8709535, lng: 21.9477031 },
    { lat: 48.8716374, lng: 21.9537265 },
    { lat: 48.8718391, lng: 21.9544757 },
    { lat: 48.8720868, lng: 21.954665 },
    { lat: 48.8725364, lng: 21.9546976 },
    { lat: 48.8726987, lng: 21.9548427 },
    { lat: 48.8729769, lng: 21.9562162 },
    { lat: 48.8730831, lng: 21.9565546 },
    { lat: 48.8728666, lng: 21.956761 },
    { lat: 48.8729686, lng: 21.9570225 },
    { lat: 48.8731592, lng: 21.9570845 },
    { lat: 48.8736452, lng: 21.9585888 },
    { lat: 48.8738241, lng: 21.9595878 },
    { lat: 48.8741084, lng: 21.9598478 },
    { lat: 48.8741766, lng: 21.9604707 },
    { lat: 48.8741671, lng: 21.9605821 },
  ],
  RuskáKajňa: [
    { lat: 49.1172936, lng: 21.8078706 },
    { lat: 49.1163312, lng: 21.8091479 },
    { lat: 49.1155429, lng: 21.8108543 },
    { lat: 49.115362, lng: 21.8114235 },
    { lat: 49.1151801, lng: 21.8117469 },
    { lat: 49.1151209, lng: 21.8121907 },
    { lat: 49.1151577, lng: 21.8125299 },
    { lat: 49.115144, lng: 21.8131297 },
    { lat: 49.1152511, lng: 21.8139255 },
    { lat: 49.1152517, lng: 21.8148616 },
    { lat: 49.1151918, lng: 21.8153708 },
    { lat: 49.1155287, lng: 21.8158745 },
    { lat: 49.1156463, lng: 21.8170244 },
    { lat: 49.1154719, lng: 21.8191596 },
    { lat: 49.1152125, lng: 21.8197033 },
    { lat: 49.1149225, lng: 21.819837 },
    { lat: 49.1149334, lng: 21.8212662 },
    { lat: 49.1148787, lng: 21.8216286 },
    { lat: 49.1145424, lng: 21.8216764 },
    { lat: 49.1141735, lng: 21.8219337 },
    { lat: 49.114154, lng: 21.8237543 },
    { lat: 49.1139327, lng: 21.8238576 },
    { lat: 49.113985, lng: 21.8247525 },
    { lat: 49.1138619, lng: 21.8273029 },
    { lat: 49.1133173, lng: 21.8293505 },
    { lat: 49.1131325, lng: 21.8303051 },
    { lat: 49.1128456, lng: 21.8311747 },
    { lat: 49.1127108, lng: 21.8323206 },
    { lat: 49.1123663, lng: 21.8332309 },
    { lat: 49.1121636, lng: 21.8336103 },
    { lat: 49.1114644, lng: 21.8360401 },
    { lat: 49.1115871, lng: 21.8359509 },
    { lat: 49.1116258, lng: 21.8357529 },
    { lat: 49.1116882, lng: 21.835821 },
    { lat: 49.1119259, lng: 21.8356162 },
    { lat: 49.112456, lng: 21.8356697 },
    { lat: 49.1124845, lng: 21.8354666 },
    { lat: 49.1126204, lng: 21.8353235 },
    { lat: 49.1127506, lng: 21.8353171 },
    { lat: 49.1130089, lng: 21.8356482 },
    { lat: 49.1132697, lng: 21.8358632 },
    { lat: 49.1138265, lng: 21.8356879 },
    { lat: 49.1140537, lng: 21.8361652 },
    { lat: 49.1141528, lng: 21.8362627 },
    { lat: 49.1142777, lng: 21.8362588 },
    { lat: 49.1148198, lng: 21.8359931 },
    { lat: 49.1150987, lng: 21.8357722 },
    { lat: 49.1152829, lng: 21.8352826 },
    { lat: 49.1153105, lng: 21.8354774 },
    { lat: 49.1155209, lng: 21.835389 },
    { lat: 49.1154315, lng: 21.8359826 },
    { lat: 49.115527, lng: 21.8360681 },
    { lat: 49.1156684, lng: 21.8360575 },
    { lat: 49.1157758, lng: 21.835989 },
    { lat: 49.1158472, lng: 21.8357861 },
    { lat: 49.1160467, lng: 21.8360217 },
    { lat: 49.1159928, lng: 21.836729 },
    { lat: 49.1156529, lng: 21.8372459 },
    { lat: 49.1155751, lng: 21.8371065 },
    { lat: 49.1151351, lng: 21.8382387 },
    { lat: 49.1155219, lng: 21.8386703 },
    { lat: 49.1154058, lng: 21.838888 },
    { lat: 49.1153837, lng: 21.839118 },
    { lat: 49.1155731, lng: 21.8396206 },
    { lat: 49.1156009, lng: 21.8400032 },
    { lat: 49.1156985, lng: 21.8404283 },
    { lat: 49.1156883, lng: 21.8407761 },
    { lat: 49.1158392, lng: 21.8408231 },
    { lat: 49.1159318, lng: 21.8409322 },
    { lat: 49.1157331, lng: 21.841558 },
    { lat: 49.1158796, lng: 21.8417939 },
    { lat: 49.1168638, lng: 21.8404713 },
    { lat: 49.1168931, lng: 21.8405224 },
    { lat: 49.1171093, lng: 21.8400427 },
    { lat: 49.1171365, lng: 21.8400949 },
    { lat: 49.1171722, lng: 21.8399977 },
    { lat: 49.1175987, lng: 21.8406147 },
    { lat: 49.1178427, lng: 21.840793 },
    { lat: 49.1186461, lng: 21.8410623 },
    { lat: 49.1189706, lng: 21.8410398 },
    { lat: 49.1191057, lng: 21.8413481 },
    { lat: 49.1193505, lng: 21.8414632 },
    { lat: 49.1193177, lng: 21.8416339 },
    { lat: 49.1194191, lng: 21.84182 },
    { lat: 49.119676, lng: 21.842049 },
    { lat: 49.1200623, lng: 21.8422497 },
    { lat: 49.1213419, lng: 21.8442892 },
    { lat: 49.121874, lng: 21.845548 },
    { lat: 49.121944, lng: 21.845371 },
    { lat: 49.121972, lng: 21.845301 },
    { lat: 49.12241, lng: 21.844396 },
    { lat: 49.122589, lng: 21.84402 },
    { lat: 49.122848, lng: 21.843477 },
    { lat: 49.123436, lng: 21.842245 },
    { lat: 49.123513, lng: 21.842178 },
    { lat: 49.123552, lng: 21.842194 },
    { lat: 49.123678, lng: 21.842246 },
    { lat: 49.123866, lng: 21.842323 },
    { lat: 49.124166, lng: 21.842492 },
    { lat: 49.124197, lng: 21.842509 },
    { lat: 49.12425, lng: 21.842539 },
    { lat: 49.124795, lng: 21.842182 },
    { lat: 49.125147, lng: 21.841739 },
    { lat: 49.125313, lng: 21.841655 },
    { lat: 49.126001, lng: 21.84106 },
    { lat: 49.126397, lng: 21.840883 },
    { lat: 49.126685, lng: 21.840817 },
    { lat: 49.126734, lng: 21.840806 },
    { lat: 49.126915, lng: 21.840765 },
    { lat: 49.127296, lng: 21.840776 },
    { lat: 49.127584, lng: 21.840451 },
    { lat: 49.127956, lng: 21.839799 },
    { lat: 49.128121, lng: 21.839694 },
    { lat: 49.128475, lng: 21.839468 },
    { lat: 49.128694, lng: 21.839426 },
    { lat: 49.129084, lng: 21.839137 },
    { lat: 49.129326, lng: 21.838891 },
    { lat: 49.129427, lng: 21.83866 },
    { lat: 49.12955, lng: 21.838417 },
    { lat: 49.129637, lng: 21.838176 },
    { lat: 49.1298, lng: 21.837804 },
    { lat: 49.130012, lng: 21.837006 },
    { lat: 49.130366, lng: 21.836395 },
    { lat: 49.130406, lng: 21.836166 },
    { lat: 49.130579, lng: 21.835646 },
    { lat: 49.130741, lng: 21.835037 },
    { lat: 49.131014, lng: 21.833846 },
    { lat: 49.131158, lng: 21.833331 },
    { lat: 49.131216, lng: 21.833211 },
    { lat: 49.131242, lng: 21.833158 },
    { lat: 49.131342, lng: 21.832958 },
    { lat: 49.131399, lng: 21.832846 },
    { lat: 49.131478, lng: 21.832687 },
    { lat: 49.131603, lng: 21.832454 },
    { lat: 49.131788, lng: 21.832345 },
    { lat: 49.131929, lng: 21.832281 },
    { lat: 49.132091, lng: 21.832041 },
    { lat: 49.132359, lng: 21.832113 },
    { lat: 49.132442, lng: 21.831999 },
    { lat: 49.132497, lng: 21.832004 },
    { lat: 49.132774, lng: 21.832005 },
    { lat: 49.133218, lng: 21.831665 },
    { lat: 49.133484, lng: 21.831634 },
    { lat: 49.133803, lng: 21.831434 },
    { lat: 49.133994, lng: 21.831465 },
    { lat: 49.134257, lng: 21.831187 },
    { lat: 49.134556, lng: 21.830986 },
    { lat: 49.134931, lng: 21.830237 },
    { lat: 49.135103, lng: 21.830162 },
    { lat: 49.13525, lng: 21.829891 },
    { lat: 49.135334, lng: 21.829845 },
    { lat: 49.135403, lng: 21.829671 },
    { lat: 49.13583, lng: 21.829151 },
    { lat: 49.135354, lng: 21.828697 },
    { lat: 49.135328, lng: 21.828268 },
    { lat: 49.135105, lng: 21.827191 },
    { lat: 49.135108, lng: 21.827155 },
    { lat: 49.135114, lng: 21.827081 },
    { lat: 49.135169, lng: 21.826426 },
    { lat: 49.135159, lng: 21.826014 },
    { lat: 49.135064, lng: 21.825193 },
    { lat: 49.135221, lng: 21.824339 },
    { lat: 49.135251, lng: 21.824179 },
    { lat: 49.135489, lng: 21.823881 },
    { lat: 49.135659, lng: 21.823509 },
    { lat: 49.135864, lng: 21.822723 },
    { lat: 49.136124, lng: 21.822531 },
    { lat: 49.136421, lng: 21.822379 },
    { lat: 49.136672, lng: 21.82233 },
    { lat: 49.136851, lng: 21.821834 },
    { lat: 49.137145, lng: 21.8215 },
    { lat: 49.137857, lng: 21.820382 },
    { lat: 49.138154, lng: 21.820163 },
    { lat: 49.138878, lng: 21.819812 },
    { lat: 49.139158, lng: 21.819463 },
    { lat: 49.139189, lng: 21.819425 },
    { lat: 49.139244, lng: 21.819357 },
    { lat: 49.139396, lng: 21.818465 },
    { lat: 49.139235, lng: 21.818125 },
    { lat: 49.139138, lng: 21.817708 },
    { lat: 49.139193, lng: 21.817096 },
    { lat: 49.139307, lng: 21.816424 },
    { lat: 49.139213, lng: 21.815663 },
    { lat: 49.139225, lng: 21.815638 },
    { lat: 49.1387285, lng: 21.8157139 },
    { lat: 49.1384088, lng: 21.8155772 },
    { lat: 49.1372171, lng: 21.8154701 },
    { lat: 49.1365011, lng: 21.8145802 },
    { lat: 49.1362765, lng: 21.8143723 },
    { lat: 49.1358787, lng: 21.8143018 },
    { lat: 49.1352248, lng: 21.8135069 },
    { lat: 49.1347664, lng: 21.8130897 },
    { lat: 49.1340214, lng: 21.8126854 },
    { lat: 49.1333289, lng: 21.8125312 },
    { lat: 49.1329593, lng: 21.8123767 },
    { lat: 49.1308742, lng: 21.8122605 },
    { lat: 49.128875, lng: 21.8120258 },
    { lat: 49.1280163, lng: 21.8117548 },
    { lat: 49.1266539, lng: 21.8104797 },
    { lat: 49.1263391, lng: 21.8099286 },
    { lat: 49.1257543, lng: 21.8097131 },
    { lat: 49.1252209, lng: 21.8097502 },
    { lat: 49.1248622, lng: 21.8094507 },
    { lat: 49.1238858, lng: 21.8088399 },
    { lat: 49.1233242, lng: 21.8087002 },
    { lat: 49.1226291, lng: 21.8083987 },
    { lat: 49.1222318, lng: 21.8083956 },
    { lat: 49.1217789, lng: 21.8082372 },
    { lat: 49.1215353, lng: 21.8082148 },
    { lat: 49.1213054, lng: 21.8082865 },
    { lat: 49.1205156, lng: 21.8082349 },
    { lat: 49.1202743, lng: 21.8083377 },
    { lat: 49.1196036, lng: 21.808159 },
    { lat: 49.1191619, lng: 21.8081694 },
    { lat: 49.1186545, lng: 21.8078641 },
    { lat: 49.1181103, lng: 21.8077406 },
    { lat: 49.1176709, lng: 21.8078852 },
    { lat: 49.1172936, lng: 21.8078706 },
  ],
  RuskáPoruba: [
    { lat: 49.167625, lng: 21.766296 },
    { lat: 49.167602, lng: 21.76627 },
    { lat: 49.167193, lng: 21.766301 },
    { lat: 49.166727, lng: 21.766375 },
    { lat: 49.166265, lng: 21.766632 },
    { lat: 49.1658, lng: 21.766722 },
    { lat: 49.165479, lng: 21.766834 },
    { lat: 49.165196, lng: 21.766702 },
    { lat: 49.164563, lng: 21.765938 },
    { lat: 49.164237, lng: 21.765736 },
    { lat: 49.163778, lng: 21.765099 },
    { lat: 49.163415, lng: 21.764794 },
    { lat: 49.163007, lng: 21.764498 },
    { lat: 49.16249, lng: 21.764278 },
    { lat: 49.162153, lng: 21.764083 },
    { lat: 49.16139, lng: 21.764107 },
    { lat: 49.161029, lng: 21.764392 },
    { lat: 49.160706, lng: 21.764809 },
    { lat: 49.16045, lng: 21.76517 },
    { lat: 49.159407, lng: 21.766194 },
    { lat: 49.15934, lng: 21.766289 },
    { lat: 49.159064, lng: 21.766682 },
    { lat: 49.159005, lng: 21.766913 },
    { lat: 49.158806, lng: 21.766881 },
    { lat: 49.158662, lng: 21.767033 },
    { lat: 49.158572, lng: 21.767026 },
    { lat: 49.158508, lng: 21.76718 },
    { lat: 49.158483, lng: 21.767266 },
    { lat: 49.158306, lng: 21.767277 },
    { lat: 49.158072, lng: 21.767472 },
    { lat: 49.15766, lng: 21.767695 },
    { lat: 49.157604, lng: 21.76768 },
    { lat: 49.157359, lng: 21.767595 },
    { lat: 49.156393, lng: 21.768424 },
    { lat: 49.156046, lng: 21.768712 },
    { lat: 49.155949, lng: 21.768744 },
    { lat: 49.155905, lng: 21.768758 },
    { lat: 49.155702, lng: 21.768824 },
    { lat: 49.154915, lng: 21.769053 },
    { lat: 49.154271, lng: 21.769136 },
    { lat: 49.15392, lng: 21.769421 },
    { lat: 49.153509, lng: 21.769358 },
    { lat: 49.152876, lng: 21.769164 },
    { lat: 49.152591, lng: 21.768821 },
    { lat: 49.152499, lng: 21.768629 },
    { lat: 49.152333, lng: 21.768287 },
    { lat: 49.152306, lng: 21.768231 },
    { lat: 49.151831, lng: 21.768187 },
    { lat: 49.151521, lng: 21.768199 },
    { lat: 49.151301, lng: 21.768215 },
    { lat: 49.15075, lng: 21.768176 },
    { lat: 49.15036, lng: 21.768345 },
    { lat: 49.149572, lng: 21.768463 },
    { lat: 49.149205, lng: 21.7682 },
    { lat: 49.149016, lng: 21.767712 },
    { lat: 49.148817, lng: 21.767365 },
    { lat: 49.148706, lng: 21.766856 },
    { lat: 49.148587, lng: 21.766048 },
    { lat: 49.148323, lng: 21.7656 },
    { lat: 49.147892, lng: 21.765172 },
    { lat: 49.1467098, lng: 21.765497 },
    { lat: 49.146078, lng: 21.7661141 },
    { lat: 49.1459026, lng: 21.7666785 },
    { lat: 49.1457997, lng: 21.7672108 },
    { lat: 49.1453129, lng: 21.7685128 },
    { lat: 49.1452704, lng: 21.7690299 },
    { lat: 49.1453196, lng: 21.769531 },
    { lat: 49.1455643, lng: 21.7703788 },
    { lat: 49.1458128, lng: 21.7710104 },
    { lat: 49.1459106, lng: 21.7716556 },
    { lat: 49.1452984, lng: 21.7722925 },
    { lat: 49.145187, lng: 21.772548 },
    { lat: 49.1444733, lng: 21.7732162 },
    { lat: 49.1441262, lng: 21.7739146 },
    { lat: 49.1436838, lng: 21.7742484 },
    { lat: 49.1433726, lng: 21.7742637 },
    { lat: 49.143037, lng: 21.7743761 },
    { lat: 49.1424231, lng: 21.7748234 },
    { lat: 49.1417316, lng: 21.7753919 },
    { lat: 49.1416451, lng: 21.7755182 },
    { lat: 49.1413769, lng: 21.7756311 },
    { lat: 49.1399942, lng: 21.7757106 },
    { lat: 49.1395485, lng: 21.7763894 },
    { lat: 49.1393706, lng: 21.7767709 },
    { lat: 49.1390608, lng: 21.7770578 },
    { lat: 49.1386803, lng: 21.777299 },
    { lat: 49.1379373, lng: 21.7780993 },
    { lat: 49.137788, lng: 21.7781508 },
    { lat: 49.1375219, lng: 21.7779966 },
    { lat: 49.1372042, lng: 21.7780644 },
    { lat: 49.1369856, lng: 21.7783127 },
    { lat: 49.1366299, lng: 21.7785531 },
    { lat: 49.1350282, lng: 21.7790347 },
    { lat: 49.1341742, lng: 21.7792048 },
    { lat: 49.1333134, lng: 21.7796235 },
    { lat: 49.1307192, lng: 21.7811244 },
    { lat: 49.1284226, lng: 21.7821272 },
    { lat: 49.1267029, lng: 21.7831694 },
    { lat: 49.1258379, lng: 21.7833892 },
    { lat: 49.1252787, lng: 21.7834202 },
    { lat: 49.1243681, lng: 21.7831034 },
    { lat: 49.1232226, lng: 21.7845534 },
    { lat: 49.1225995, lng: 21.7852006 },
    { lat: 49.1222377, lng: 21.7853091 },
    { lat: 49.1219864, lng: 21.7855441 },
    { lat: 49.121735, lng: 21.7855647 },
    { lat: 49.1210816, lng: 21.7865244 },
    { lat: 49.1210135, lng: 21.7865892 },
    { lat: 49.1207496, lng: 21.7866016 },
    { lat: 49.1206827, lng: 21.7867102 },
    { lat: 49.1205326, lng: 21.7873014 },
    { lat: 49.1204877, lng: 21.7876834 },
    { lat: 49.1203638, lng: 21.787985 },
    { lat: 49.1200026, lng: 21.7884515 },
    { lat: 49.1197807, lng: 21.7883974 },
    { lat: 49.1193959, lng: 21.7883991 },
    { lat: 49.1192338, lng: 21.7884775 },
    { lat: 49.1186971, lng: 21.788431 },
    { lat: 49.1186577, lng: 21.7893815 },
    { lat: 49.118913, lng: 21.7898038 },
    { lat: 49.1188843, lng: 21.7904325 },
    { lat: 49.118737, lng: 21.7912103 },
    { lat: 49.1186145, lng: 21.7915205 },
    { lat: 49.1185288, lng: 21.7919426 },
    { lat: 49.1183932, lng: 21.7931682 },
    { lat: 49.1184854, lng: 21.793642 },
    { lat: 49.1184762, lng: 21.7944118 },
    { lat: 49.1183365, lng: 21.7947481 },
    { lat: 49.1182699, lng: 21.7952157 },
    { lat: 49.1181001, lng: 21.7974216 },
    { lat: 49.1179049, lng: 21.7990892 },
    { lat: 49.117645, lng: 21.8006472 },
    { lat: 49.1173633, lng: 21.8019247 },
    { lat: 49.1173136, lng: 21.8026965 },
    { lat: 49.1170797, lng: 21.8039599 },
    { lat: 49.1173044, lng: 21.8045145 },
    { lat: 49.1170436, lng: 21.8055662 },
    { lat: 49.1172085, lng: 21.8060611 },
    { lat: 49.117026, lng: 21.8063149 },
    { lat: 49.117052, lng: 21.8064092 },
    { lat: 49.116994, lng: 21.8065527 },
    { lat: 49.1170913, lng: 21.807232 },
    { lat: 49.1172318, lng: 21.8075676 },
    { lat: 49.1172936, lng: 21.8078706 },
    { lat: 49.1176709, lng: 21.8078852 },
    { lat: 49.1181103, lng: 21.8077406 },
    { lat: 49.1186545, lng: 21.8078641 },
    { lat: 49.1191619, lng: 21.8081694 },
    { lat: 49.1196036, lng: 21.808159 },
    { lat: 49.1202743, lng: 21.8083377 },
    { lat: 49.1205156, lng: 21.8082349 },
    { lat: 49.1213054, lng: 21.8082865 },
    { lat: 49.1215353, lng: 21.8082148 },
    { lat: 49.1217789, lng: 21.8082372 },
    { lat: 49.1222318, lng: 21.8083956 },
    { lat: 49.1226291, lng: 21.8083987 },
    { lat: 49.1233242, lng: 21.8087002 },
    { lat: 49.1238858, lng: 21.8088399 },
    { lat: 49.1248622, lng: 21.8094507 },
    { lat: 49.1252209, lng: 21.8097502 },
    { lat: 49.1257543, lng: 21.8097131 },
    { lat: 49.1263391, lng: 21.8099286 },
    { lat: 49.1266539, lng: 21.8104797 },
    { lat: 49.1280163, lng: 21.8117548 },
    { lat: 49.128875, lng: 21.8120258 },
    { lat: 49.1308742, lng: 21.8122605 },
    { lat: 49.1329593, lng: 21.8123767 },
    { lat: 49.1333289, lng: 21.8125312 },
    { lat: 49.1340214, lng: 21.8126854 },
    { lat: 49.1347664, lng: 21.8130897 },
    { lat: 49.1352248, lng: 21.8135069 },
    { lat: 49.1358787, lng: 21.8143018 },
    { lat: 49.1362765, lng: 21.8143723 },
    { lat: 49.1365011, lng: 21.8145802 },
    { lat: 49.1372171, lng: 21.8154701 },
    { lat: 49.1384088, lng: 21.8155772 },
    { lat: 49.1387285, lng: 21.8157139 },
    { lat: 49.139225, lng: 21.815638 },
    { lat: 49.140321, lng: 21.814183 },
    { lat: 49.140975, lng: 21.813769 },
    { lat: 49.141307, lng: 21.813484 },
    { lat: 49.141491, lng: 21.813277 },
    { lat: 49.141713, lng: 21.813095 },
    { lat: 49.142246, lng: 21.813076 },
    { lat: 49.142721, lng: 21.812953 },
    { lat: 49.143097, lng: 21.812335 },
    { lat: 49.143617, lng: 21.811286 },
    { lat: 49.143869, lng: 21.810863 },
    { lat: 49.144213, lng: 21.81062 },
    { lat: 49.144434, lng: 21.810388 },
    { lat: 49.144934, lng: 21.810183 },
    { lat: 49.145305, lng: 21.810119 },
    { lat: 49.145535, lng: 21.810042 },
    { lat: 49.145832, lng: 21.810054 },
    { lat: 49.146161, lng: 21.809956 },
    { lat: 49.146735, lng: 21.809691 },
    { lat: 49.147114, lng: 21.809447 },
    { lat: 49.147569, lng: 21.809039 },
    { lat: 49.148019, lng: 21.808888 },
    { lat: 49.148478, lng: 21.808639 },
    { lat: 49.148769, lng: 21.808293 },
    { lat: 49.14907, lng: 21.807842 },
    { lat: 49.149291, lng: 21.807702 },
    { lat: 49.149443, lng: 21.807683 },
    { lat: 49.149606, lng: 21.80775 },
    { lat: 49.15002, lng: 21.807999 },
    { lat: 49.150508, lng: 21.808397 },
    { lat: 49.15082, lng: 21.808719 },
    { lat: 49.150866, lng: 21.808766 },
    { lat: 49.150937, lng: 21.808824 },
    { lat: 49.151243, lng: 21.809002 },
    { lat: 49.151498, lng: 21.808707 },
    { lat: 49.151604, lng: 21.808434 },
    { lat: 49.15192, lng: 21.808042 },
    { lat: 49.152036, lng: 21.807801 },
    { lat: 49.152285, lng: 21.807671 },
    { lat: 49.152461, lng: 21.807338 },
    { lat: 49.15286, lng: 21.806801 },
    { lat: 49.153313, lng: 21.806512 },
    { lat: 49.153513, lng: 21.806012 },
    { lat: 49.15361, lng: 21.805946 },
    { lat: 49.153775, lng: 21.805674 },
    { lat: 49.154014, lng: 21.805532 },
    { lat: 49.154178, lng: 21.80529 },
    { lat: 49.154342, lng: 21.804983 },
    { lat: 49.154458, lng: 21.804818 },
    { lat: 49.154505, lng: 21.80469 },
    { lat: 49.154678, lng: 21.804492 },
    { lat: 49.154796, lng: 21.804263 },
    { lat: 49.155141, lng: 21.803705 },
    { lat: 49.155173, lng: 21.803426 },
    { lat: 49.15591, lng: 21.803864 },
    { lat: 49.156259, lng: 21.804188 },
    { lat: 49.156391, lng: 21.804525 },
    { lat: 49.156583, lng: 21.804553 },
    { lat: 49.156648, lng: 21.804931 },
    { lat: 49.156964, lng: 21.805621 },
    { lat: 49.157271, lng: 21.806066 },
    { lat: 49.157489, lng: 21.806306 },
    { lat: 49.157747, lng: 21.806427 },
    { lat: 49.157942, lng: 21.806642 },
    { lat: 49.158007, lng: 21.806581 },
    { lat: 49.158324, lng: 21.806255 },
    { lat: 49.158386, lng: 21.806111 },
    { lat: 49.158434, lng: 21.805996 },
    { lat: 49.15853, lng: 21.805772 },
    { lat: 49.158805, lng: 21.805508 },
    { lat: 49.15921, lng: 21.805064 },
    { lat: 49.15957, lng: 21.804599 },
    { lat: 49.16058, lng: 21.803916 },
    { lat: 49.160692, lng: 21.803695 },
    { lat: 49.1606552, lng: 21.8031861 },
    { lat: 49.1605652, lng: 21.8024874 },
    { lat: 49.1604099, lng: 21.801751 },
    { lat: 49.1602127, lng: 21.7999565 },
    { lat: 49.1600184, lng: 21.7988914 },
    { lat: 49.1598573, lng: 21.798315 },
    { lat: 49.1596754, lng: 21.7971133 },
    { lat: 49.1596297, lng: 21.7963491 },
    { lat: 49.1593719, lng: 21.7944846 },
    { lat: 49.1591276, lng: 21.7931782 },
    { lat: 49.15923, lng: 21.7921032 },
    { lat: 49.1592576, lng: 21.7907376 },
    { lat: 49.159133, lng: 21.7897999 },
    { lat: 49.1593122, lng: 21.7892496 },
    { lat: 49.1594414, lng: 21.7885531 },
    { lat: 49.1593811, lng: 21.7881381 },
    { lat: 49.1594648, lng: 21.7869901 },
    { lat: 49.1592227, lng: 21.785781 },
    { lat: 49.159284, lng: 21.7837739 },
    { lat: 49.1594492, lng: 21.7831601 },
    { lat: 49.1594188, lng: 21.7827256 },
    { lat: 49.1595364, lng: 21.7823192 },
    { lat: 49.1601807, lng: 21.7809754 },
    { lat: 49.1608998, lng: 21.7796561 },
    { lat: 49.1611338, lng: 21.779437 },
    { lat: 49.1613519, lng: 21.7790877 },
    { lat: 49.1620983, lng: 21.777605 },
    { lat: 49.1627753, lng: 21.7760539 },
    { lat: 49.1644351, lng: 21.7736287 },
    { lat: 49.1654215, lng: 21.7711762 },
    { lat: 49.1671569, lng: 21.7670879 },
    { lat: 49.1674254, lng: 21.7667282 },
    { lat: 49.167625, lng: 21.766296 },
  ],
  VyšnáSitnica: [
    { lat: 49.1187926, lng: 21.7729821 },
    { lat: 49.1185436, lng: 21.772752 },
    { lat: 49.117745, lng: 21.7721513 },
    { lat: 49.1173518, lng: 21.771963 },
    { lat: 49.1170778, lng: 21.7717175 },
    { lat: 49.1168326, lng: 21.7715877 },
    { lat: 49.1165143, lng: 21.7716383 },
    { lat: 49.1164321, lng: 21.7715877 },
    { lat: 49.1161512, lng: 21.7717703 },
    { lat: 49.1156979, lng: 21.77186 },
    { lat: 49.1149585, lng: 21.7718896 },
    { lat: 49.114492, lng: 21.7717711 },
    { lat: 49.1142743, lng: 21.7715793 },
    { lat: 49.1140876, lng: 21.7714998 },
    { lat: 49.1139726, lng: 21.7712303 },
    { lat: 49.1133972, lng: 21.7706452 },
    { lat: 49.1122664, lng: 21.7705553 },
    { lat: 49.1109467, lng: 21.7701635 },
    { lat: 49.1105515, lng: 21.7701488 },
    { lat: 49.1101246, lng: 21.7700412 },
    { lat: 49.1098179, lng: 21.7704799 },
    { lat: 49.109661, lng: 21.7705477 },
    { lat: 49.1094683, lng: 21.7704976 },
    { lat: 49.1090895, lng: 21.7698622 },
    { lat: 49.1089957, lng: 21.7686486 },
    { lat: 49.1088252, lng: 21.768064 },
    { lat: 49.108456, lng: 21.7675863 },
    { lat: 49.1080505, lng: 21.7673482 },
    { lat: 49.1076175, lng: 21.7661557 },
    { lat: 49.1067814, lng: 21.7643899 },
    { lat: 49.1065884, lng: 21.7642775 },
    { lat: 49.1060751, lng: 21.7637523 },
    { lat: 49.1057309, lng: 21.763536 },
    { lat: 49.1056044, lng: 21.7633505 },
    { lat: 49.1055729, lng: 21.7629686 },
    { lat: 49.1053376, lng: 21.7628681 },
    { lat: 49.1053888, lng: 21.7625921 },
    { lat: 49.1049787, lng: 21.7621027 },
    { lat: 49.1044, lng: 21.7617438 },
    { lat: 49.1043174, lng: 21.7614153 },
    { lat: 49.104498, lng: 21.7608905 },
    { lat: 49.1043188, lng: 21.7602348 },
    { lat: 49.1043334, lng: 21.7599736 },
    { lat: 49.1040779, lng: 21.7595437 },
    { lat: 49.1040726, lng: 21.7591753 },
    { lat: 49.1038113, lng: 21.758946 },
    { lat: 49.1035319, lng: 21.7585484 },
    { lat: 49.1033273, lng: 21.7576997 },
    { lat: 49.1033113, lng: 21.7574335 },
    { lat: 49.103112, lng: 21.756956 },
    { lat: 49.1029237, lng: 21.755785 },
    { lat: 49.1026469, lng: 21.7556138 },
    { lat: 49.1027901, lng: 21.754776 },
    { lat: 49.1028177, lng: 21.7539009 },
    { lat: 49.1029664, lng: 21.7530521 },
    { lat: 49.1028067, lng: 21.7527186 },
    { lat: 49.1030016, lng: 21.7524295 },
    { lat: 49.102989, lng: 21.752374 },
    { lat: 49.10288, lng: 21.752208 },
    { lat: 49.101659, lng: 21.749685 },
    { lat: 49.100605, lng: 21.748401 },
    { lat: 49.100306, lng: 21.747329 },
    { lat: 49.100333, lng: 21.747042 },
    { lat: 49.100122, lng: 21.74699 },
    { lat: 49.099981, lng: 21.74702 },
    { lat: 49.099908, lng: 21.746884 },
    { lat: 49.099787, lng: 21.74683 },
    { lat: 49.099536, lng: 21.74697 },
    { lat: 49.099112, lng: 21.746745 },
    { lat: 49.09897, lng: 21.746809 },
    { lat: 49.098591, lng: 21.747007 },
    { lat: 49.098318, lng: 21.746993 },
    { lat: 49.098136, lng: 21.747133 },
    { lat: 49.097946, lng: 21.74701 },
    { lat: 49.097796, lng: 21.747009 },
    { lat: 49.097746, lng: 21.746999 },
    { lat: 49.097768, lng: 21.747094 },
    { lat: 49.09776, lng: 21.747308 },
    { lat: 49.09773, lng: 21.747416 },
    { lat: 49.097735, lng: 21.747472 },
    { lat: 49.097824, lng: 21.747663 },
    { lat: 49.09775, lng: 21.748131 },
    { lat: 49.097821, lng: 21.748301 },
    { lat: 49.097839, lng: 21.748671 },
    { lat: 49.09788, lng: 21.748744 },
    { lat: 49.097668, lng: 21.749126 },
    { lat: 49.097638, lng: 21.74947 },
    { lat: 49.097604, lng: 21.749717 },
    { lat: 49.097646, lng: 21.749804 },
    { lat: 49.097602, lng: 21.749946 },
    { lat: 49.097722, lng: 21.750376 },
    { lat: 49.0983122, lng: 21.7509964 },
    { lat: 49.0983796, lng: 21.7511678 },
    { lat: 49.0985813, lng: 21.7513856 },
    { lat: 49.0986874, lng: 21.7516106 },
    { lat: 49.0994852, lng: 21.7522679 },
    { lat: 49.0995889, lng: 21.7522285 },
    { lat: 49.1001958, lng: 21.7527191 },
    { lat: 49.1002964, lng: 21.7526964 },
    { lat: 49.1003384, lng: 21.7528324 },
    { lat: 49.1004634, lng: 21.7528438 },
    { lat: 49.10082, lng: 21.7531371 },
    { lat: 49.101183, lng: 21.7536351 },
    { lat: 49.1007271, lng: 21.7540102 },
    { lat: 49.1007652, lng: 21.7548568 },
    { lat: 49.1002263, lng: 21.7556706 },
    { lat: 49.1002484, lng: 21.7560789 },
    { lat: 49.1001526, lng: 21.7571373 },
    { lat: 49.0999575, lng: 21.7573301 },
    { lat: 49.0995016, lng: 21.7581352 },
    { lat: 49.0993129, lng: 21.7586579 },
    { lat: 49.0993573, lng: 21.7587579 },
    { lat: 49.0993489, lng: 21.7589943 },
    { lat: 49.0991685, lng: 21.7592806 },
    { lat: 49.0987129, lng: 21.7611985 },
    { lat: 49.0986017, lng: 21.7614217 },
    { lat: 49.0983584, lng: 21.7615378 },
    { lat: 49.098346, lng: 21.7622228 },
    { lat: 49.0981615, lng: 21.7624325 },
    { lat: 49.0981057, lng: 21.7630089 },
    { lat: 49.0979102, lng: 21.7630578 },
    { lat: 49.0977071, lng: 21.7629467 },
    { lat: 49.0976007, lng: 21.7631384 },
    { lat: 49.0974418, lng: 21.7629603 },
    { lat: 49.0969437, lng: 21.7631824 },
    { lat: 49.0968711, lng: 21.7631104 },
    { lat: 49.0966004, lng: 21.7632953 },
    { lat: 49.0961883, lng: 21.7631944 },
    { lat: 49.0958247, lng: 21.7628494 },
    { lat: 49.0951251, lng: 21.7627248 },
    { lat: 49.0944357, lng: 21.7628986 },
    { lat: 49.0942534, lng: 21.7631125 },
    { lat: 49.09295, lng: 21.7635111 },
    { lat: 49.0922899, lng: 21.7639925 },
    { lat: 49.09177, lng: 21.764241 },
    { lat: 49.0910404, lng: 21.7652883 },
    { lat: 49.0905809, lng: 21.7667983 },
    { lat: 49.090036, lng: 21.7683734 },
    { lat: 49.0900745, lng: 21.7690228 },
    { lat: 49.0900366, lng: 21.7690345 },
    { lat: 49.0903343, lng: 21.77206 },
    { lat: 49.0901394, lng: 21.7728697 },
    { lat: 49.0893238, lng: 21.7751797 },
    { lat: 49.0885105, lng: 21.7763403 },
    { lat: 49.0881421, lng: 21.7802287 },
    { lat: 49.0876524, lng: 21.7822 },
    { lat: 49.0878601, lng: 21.7860075 },
    { lat: 49.0871736, lng: 21.7857864 },
    { lat: 49.0869359, lng: 21.7865871 },
    { lat: 49.0868834, lng: 21.7875108 },
    { lat: 49.0865014, lng: 21.7895466 },
    { lat: 49.0862046, lng: 21.7916719 },
    { lat: 49.0864593, lng: 21.7936727 },
    { lat: 49.0865488, lng: 21.7964691 },
    { lat: 49.0873719, lng: 21.7980058 },
    { lat: 49.0878587, lng: 21.7986411 },
    { lat: 49.0876592, lng: 21.8009231 },
    { lat: 49.0877575, lng: 21.8016324 },
    { lat: 49.0878875, lng: 21.8039958 },
    { lat: 49.090209, lng: 21.8032481 },
    { lat: 49.0919508, lng: 21.8027865 },
    { lat: 49.0927528, lng: 21.8030056 },
    { lat: 49.0938003, lng: 21.8031862 },
    { lat: 49.0946932, lng: 21.802742 },
    { lat: 49.0963151, lng: 21.8027843 },
    { lat: 49.0989391, lng: 21.8029827 },
    { lat: 49.0997454, lng: 21.8022324 },
    { lat: 49.1021727, lng: 21.8026645 },
    { lat: 49.1026549, lng: 21.8039042 },
    { lat: 49.1030126, lng: 21.8045533 },
    { lat: 49.1034105, lng: 21.8044101 },
    { lat: 49.1038238, lng: 21.8048663 },
    { lat: 49.1040808, lng: 21.8041888 },
    { lat: 49.1043274, lng: 21.8041069 },
    { lat: 49.1046926, lng: 21.8041847 },
    { lat: 49.1052264, lng: 21.8041612 },
    { lat: 49.1054169, lng: 21.8044786 },
    { lat: 49.1057053, lng: 21.8046762 },
    { lat: 49.1094164, lng: 21.8037208 },
    { lat: 49.110801, lng: 21.8047533 },
    { lat: 49.1118482, lng: 21.8049316 },
    { lat: 49.1125498, lng: 21.8053073 },
    { lat: 49.1130594, lng: 21.8060429 },
    { lat: 49.1133836, lng: 21.8078303 },
    { lat: 49.1135675, lng: 21.8079865 },
    { lat: 49.113771, lng: 21.8087151 },
    { lat: 49.1143171, lng: 21.8089957 },
    { lat: 49.1155429, lng: 21.8108543 },
    { lat: 49.1163312, lng: 21.8091479 },
    { lat: 49.1172936, lng: 21.8078706 },
    { lat: 49.1172318, lng: 21.8075676 },
    { lat: 49.1170913, lng: 21.807232 },
    { lat: 49.116994, lng: 21.8065527 },
    { lat: 49.117052, lng: 21.8064092 },
    { lat: 49.117026, lng: 21.8063149 },
    { lat: 49.1172085, lng: 21.8060611 },
    { lat: 49.1170436, lng: 21.8055662 },
    { lat: 49.1173044, lng: 21.8045145 },
    { lat: 49.1170797, lng: 21.8039599 },
    { lat: 49.1173136, lng: 21.8026965 },
    { lat: 49.1173633, lng: 21.8019247 },
    { lat: 49.117645, lng: 21.8006472 },
    { lat: 49.1179049, lng: 21.7990892 },
    { lat: 49.1181001, lng: 21.7974216 },
    { lat: 49.1182699, lng: 21.7952157 },
    { lat: 49.1183365, lng: 21.7947481 },
    { lat: 49.1184762, lng: 21.7944118 },
    { lat: 49.1184854, lng: 21.793642 },
    { lat: 49.1183932, lng: 21.7931682 },
    { lat: 49.1185288, lng: 21.7919426 },
    { lat: 49.1186145, lng: 21.7915205 },
    { lat: 49.118737, lng: 21.7912103 },
    { lat: 49.1188843, lng: 21.7904325 },
    { lat: 49.118913, lng: 21.7898038 },
    { lat: 49.1186577, lng: 21.7893815 },
    { lat: 49.118302, lng: 21.7887648 },
    { lat: 49.1180826, lng: 21.7884129 },
    { lat: 49.1164904, lng: 21.7874948 },
    { lat: 49.1165541, lng: 21.7872195 },
    { lat: 49.116478, lng: 21.7864624 },
    { lat: 49.1166655, lng: 21.7838214 },
    { lat: 49.1175867, lng: 21.7816195 },
    { lat: 49.1190279, lng: 21.7776256 },
    { lat: 49.1190974, lng: 21.7747818 },
    { lat: 49.119035, lng: 21.7743338 },
    { lat: 49.1187409, lng: 21.7739664 },
    { lat: 49.1187926, lng: 21.7729821 },
  ],
  Prituľany: [
    { lat: 49.1187926, lng: 21.7729821 },
    { lat: 49.1187409, lng: 21.7739664 },
    { lat: 49.119035, lng: 21.7743338 },
    { lat: 49.1190974, lng: 21.7747818 },
    { lat: 49.1190279, lng: 21.7776256 },
    { lat: 49.1175867, lng: 21.7816195 },
    { lat: 49.1166655, lng: 21.7838214 },
    { lat: 49.116478, lng: 21.7864624 },
    { lat: 49.1165541, lng: 21.7872195 },
    { lat: 49.1164904, lng: 21.7874948 },
    { lat: 49.1180826, lng: 21.7884129 },
    { lat: 49.118302, lng: 21.7887648 },
    { lat: 49.1186577, lng: 21.7893815 },
    { lat: 49.1186971, lng: 21.788431 },
    { lat: 49.1192338, lng: 21.7884775 },
    { lat: 49.1193959, lng: 21.7883991 },
    { lat: 49.1197807, lng: 21.7883974 },
    { lat: 49.1200026, lng: 21.7884515 },
    { lat: 49.1203638, lng: 21.787985 },
    { lat: 49.1204877, lng: 21.7876834 },
    { lat: 49.1205326, lng: 21.7873014 },
    { lat: 49.1206827, lng: 21.7867102 },
    { lat: 49.1207496, lng: 21.7866016 },
    { lat: 49.1210135, lng: 21.7865892 },
    { lat: 49.1210816, lng: 21.7865244 },
    { lat: 49.121735, lng: 21.7855647 },
    { lat: 49.1219864, lng: 21.7855441 },
    { lat: 49.1222377, lng: 21.7853091 },
    { lat: 49.1225995, lng: 21.7852006 },
    { lat: 49.1232226, lng: 21.7845534 },
    { lat: 49.1243681, lng: 21.7831034 },
    { lat: 49.1252787, lng: 21.7834202 },
    { lat: 49.1258379, lng: 21.7833892 },
    { lat: 49.1267029, lng: 21.7831694 },
    { lat: 49.1284226, lng: 21.7821272 },
    { lat: 49.1307192, lng: 21.7811244 },
    { lat: 49.1333134, lng: 21.7796235 },
    { lat: 49.1341742, lng: 21.7792048 },
    { lat: 49.1350282, lng: 21.7790347 },
    { lat: 49.1366299, lng: 21.7785531 },
    { lat: 49.1369856, lng: 21.7783127 },
    { lat: 49.1372042, lng: 21.7780644 },
    { lat: 49.1375219, lng: 21.7779966 },
    { lat: 49.137788, lng: 21.7781508 },
    { lat: 49.1379373, lng: 21.7780993 },
    { lat: 49.1386803, lng: 21.777299 },
    { lat: 49.1390608, lng: 21.7770578 },
    { lat: 49.1393706, lng: 21.7767709 },
    { lat: 49.1395485, lng: 21.7763894 },
    { lat: 49.1399942, lng: 21.7757106 },
    { lat: 49.1413769, lng: 21.7756311 },
    { lat: 49.1416451, lng: 21.7755182 },
    { lat: 49.1417316, lng: 21.7753919 },
    { lat: 49.1424231, lng: 21.7748234 },
    { lat: 49.143037, lng: 21.7743761 },
    { lat: 49.1433726, lng: 21.7742637 },
    { lat: 49.1436838, lng: 21.7742484 },
    { lat: 49.1441262, lng: 21.7739146 },
    { lat: 49.1444733, lng: 21.7732162 },
    { lat: 49.145187, lng: 21.772548 },
    { lat: 49.1452984, lng: 21.7722925 },
    { lat: 49.1459106, lng: 21.7716556 },
    { lat: 49.1458128, lng: 21.7710104 },
    { lat: 49.1455643, lng: 21.7703788 },
    { lat: 49.1453196, lng: 21.769531 },
    { lat: 49.1452704, lng: 21.7690299 },
    { lat: 49.1453129, lng: 21.7685128 },
    { lat: 49.1457997, lng: 21.7672108 },
    { lat: 49.1459026, lng: 21.7666785 },
    { lat: 49.146078, lng: 21.7661141 },
    { lat: 49.1467098, lng: 21.765497 },
    { lat: 49.147892, lng: 21.765172 },
    { lat: 49.148347, lng: 21.764779 },
    { lat: 49.148182, lng: 21.764307 },
    { lat: 49.148069, lng: 21.763235 },
    { lat: 49.148393, lng: 21.762804 },
    { lat: 49.148619, lng: 21.762435 },
    { lat: 49.148774, lng: 21.762254 },
    { lat: 49.1488, lng: 21.762208 },
    { lat: 49.14864, lng: 21.761659 },
    { lat: 49.148582, lng: 21.761407 },
    { lat: 49.148691, lng: 21.761188 },
    { lat: 49.148861, lng: 21.760996 },
    { lat: 49.148856, lng: 21.760757 },
    { lat: 49.148847, lng: 21.760387 },
    { lat: 49.14896, lng: 21.760046 },
    { lat: 49.148991, lng: 21.760044 },
    { lat: 49.148911, lng: 21.759774 },
    { lat: 49.148657, lng: 21.759241 },
    { lat: 49.148461, lng: 21.759061 },
    { lat: 49.148287, lng: 21.758907 },
    { lat: 49.148244, lng: 21.758867 },
    { lat: 49.148194, lng: 21.758823 },
    { lat: 49.14817, lng: 21.758843 },
    { lat: 49.148145, lng: 21.758471 },
    { lat: 49.14803, lng: 21.758476 },
    { lat: 49.148057, lng: 21.757988 },
    { lat: 49.148206, lng: 21.757348 },
    { lat: 49.148233, lng: 21.756694 },
    { lat: 49.148249, lng: 21.756133 },
    { lat: 49.147986, lng: 21.754962 },
    { lat: 49.148, lng: 21.754656 },
    { lat: 49.148015, lng: 21.753843 },
    { lat: 49.148019, lng: 21.753573 },
    { lat: 49.147371, lng: 21.753841 },
    { lat: 49.147114, lng: 21.753918 },
    { lat: 49.146918, lng: 21.753976 },
    { lat: 49.146428, lng: 21.754189 },
    { lat: 49.146141, lng: 21.754429 },
    { lat: 49.14527, lng: 21.754911 },
    { lat: 49.144774, lng: 21.754978 },
    { lat: 49.144471, lng: 21.754896 },
    { lat: 49.144246, lng: 21.754731 },
    { lat: 49.144107, lng: 21.754432 },
    { lat: 49.14399, lng: 21.754307 },
    { lat: 49.143877, lng: 21.754322 },
    { lat: 49.143803, lng: 21.754157 },
    { lat: 49.143642, lng: 21.753855 },
    { lat: 49.143508, lng: 21.753892 },
    { lat: 49.143273, lng: 21.753701 },
    { lat: 49.142596, lng: 21.753666 },
    { lat: 49.142431, lng: 21.753742 },
    { lat: 49.141878, lng: 21.754058 },
    { lat: 49.141699, lng: 21.754142 },
    { lat: 49.141511, lng: 21.753948 },
    { lat: 49.141547, lng: 21.753688 },
    { lat: 49.141523, lng: 21.753385 },
    { lat: 49.141492, lng: 21.75339 },
    { lat: 49.141444, lng: 21.753281 },
    { lat: 49.141478, lng: 21.753165 },
    { lat: 49.141027, lng: 21.751377 },
    { lat: 49.140722, lng: 21.750917 },
    { lat: 49.140518, lng: 21.750549 },
    { lat: 49.140295, lng: 21.75026 },
    { lat: 49.139995, lng: 21.749783 },
    { lat: 49.139726, lng: 21.749505 },
    { lat: 49.139424, lng: 21.748889 },
    { lat: 49.13909, lng: 21.748315 },
    { lat: 49.138951, lng: 21.748143 },
    { lat: 49.139005, lng: 21.747621 },
    { lat: 49.139011, lng: 21.747436 },
    { lat: 49.139013, lng: 21.746716 },
    { lat: 49.139275, lng: 21.746061 },
    { lat: 49.139468, lng: 21.74518 },
    { lat: 49.139528, lng: 21.744954 },
    { lat: 49.139582, lng: 21.74436 },
    { lat: 49.139603, lng: 21.744037 },
    { lat: 49.139322, lng: 21.744077 },
    { lat: 49.138969, lng: 21.744049 },
    { lat: 49.138505, lng: 21.744125 },
    { lat: 49.138086, lng: 21.744126 },
    { lat: 49.137462, lng: 21.74437 },
    { lat: 49.136714, lng: 21.743298 },
    { lat: 49.136512, lng: 21.743196 },
    { lat: 49.136347, lng: 21.743244 },
    { lat: 49.136082, lng: 21.743755 },
    { lat: 49.135895, lng: 21.743387 },
    { lat: 49.135517, lng: 21.742341 },
    { lat: 49.135181, lng: 21.742561 },
    { lat: 49.134914, lng: 21.742592 },
    { lat: 49.134262, lng: 21.742774 },
    { lat: 49.134006, lng: 21.742875 },
    { lat: 49.13341, lng: 21.743187 },
    { lat: 49.133265, lng: 21.743087 },
    { lat: 49.132856, lng: 21.74298 },
    { lat: 49.132543, lng: 21.742753 },
    { lat: 49.132351, lng: 21.742857 },
    { lat: 49.132314, lng: 21.742733 },
    { lat: 49.132003, lng: 21.742777 },
    { lat: 49.131382, lng: 21.742764 },
    { lat: 49.129822, lng: 21.742644 },
    { lat: 49.127834, lng: 21.745932 },
    { lat: 49.1276179, lng: 21.7474846 },
    { lat: 49.1273922, lng: 21.748683 },
    { lat: 49.1272109, lng: 21.7491022 },
    { lat: 49.1270087, lng: 21.7499695 },
    { lat: 49.1271631, lng: 21.7506234 },
    { lat: 49.1275998, lng: 21.7511796 },
    { lat: 49.1275891, lng: 21.7518376 },
    { lat: 49.1274602, lng: 21.7530534 },
    { lat: 49.1273813, lng: 21.7533449 },
    { lat: 49.127343, lng: 21.7544108 },
    { lat: 49.1274431, lng: 21.7544984 },
    { lat: 49.127381, lng: 21.7569615 },
    { lat: 49.1271513, lng: 21.7580585 },
    { lat: 49.1270293, lng: 21.7589837 },
    { lat: 49.1264838, lng: 21.7596729 },
    { lat: 49.1256676, lng: 21.7603754 },
    { lat: 49.1254659, lng: 21.760443 },
    { lat: 49.1251125, lng: 21.7604384 },
    { lat: 49.1248232, lng: 21.760562 },
    { lat: 49.1246495, lng: 21.7607166 },
    { lat: 49.124316, lng: 21.7608624 },
    { lat: 49.1242559, lng: 21.7610575 },
    { lat: 49.1241823, lng: 21.7611284 },
    { lat: 49.1233165, lng: 21.7613091 },
    { lat: 49.1230915, lng: 21.7612665 },
    { lat: 49.122562, lng: 21.7614547 },
    { lat: 49.1223356, lng: 21.7616115 },
    { lat: 49.1222842, lng: 21.7620506 },
    { lat: 49.1221835, lng: 21.7623236 },
    { lat: 49.121975, lng: 21.7632981 },
    { lat: 49.1221395, lng: 21.7642122 },
    { lat: 49.1221696, lng: 21.7646223 },
    { lat: 49.1222309, lng: 21.76482 },
    { lat: 49.1225164, lng: 21.7647674 },
    { lat: 49.1222803, lng: 21.7653266 },
    { lat: 49.1222063, lng: 21.7656763 },
    { lat: 49.1218511, lng: 21.7661616 },
    { lat: 49.121149, lng: 21.7667162 },
    { lat: 49.1208181, lng: 21.7667873 },
    { lat: 49.121061, lng: 21.7680306 },
    { lat: 49.1206066, lng: 21.768635 },
    { lat: 49.1201742, lng: 21.7697718 },
    { lat: 49.119936, lng: 21.7700826 },
    { lat: 49.1193132, lng: 21.7706589 },
    { lat: 49.1193481, lng: 21.7713391 },
    { lat: 49.1191562, lng: 21.7717458 },
    { lat: 49.1190279, lng: 21.7722995 },
    { lat: 49.1187926, lng: 21.7729821 },
  ],
  RokytovpriHumennom: [
    { lat: 49.129217, lng: 21.991838 },
    { lat: 49.129216, lng: 21.991825 },
    { lat: 49.129218, lng: 21.991812 },
    { lat: 49.129259, lng: 21.991736 },
    { lat: 49.129274, lng: 21.991645 },
    { lat: 49.129843, lng: 21.990467 },
    { lat: 49.129868, lng: 21.990195 },
    { lat: 49.130144, lng: 21.989062 },
    { lat: 49.130449, lng: 21.987918 },
    { lat: 49.130732, lng: 21.986708 },
    { lat: 49.130991, lng: 21.986609 },
    { lat: 49.131166, lng: 21.985886 },
    { lat: 49.131198, lng: 21.985359 },
    { lat: 49.131399, lng: 21.984682 },
    { lat: 49.130986, lng: 21.984392 },
    { lat: 49.130319, lng: 21.983855 },
    { lat: 49.129712, lng: 21.983559 },
    { lat: 49.128961, lng: 21.983798 },
    { lat: 49.128959, lng: 21.98224 },
    { lat: 49.129058, lng: 21.981224 },
    { lat: 49.129116, lng: 21.98049 },
    { lat: 49.129258, lng: 21.979298 },
    { lat: 49.129632, lng: 21.977339 },
    { lat: 49.130116, lng: 21.975476 },
    { lat: 49.130753, lng: 21.973742 },
    { lat: 49.131098, lng: 21.972158 },
    { lat: 49.130367, lng: 21.971002 },
    { lat: 49.130336, lng: 21.970953 },
    { lat: 49.130099, lng: 21.970578 },
    { lat: 49.129755, lng: 21.9705 },
    { lat: 49.129441, lng: 21.969915 },
    { lat: 49.128975, lng: 21.969297 },
    { lat: 49.128402, lng: 21.969098 },
    { lat: 49.128369, lng: 21.968721 },
    { lat: 49.12666, lng: 21.966071 },
    { lat: 49.126384, lng: 21.965956 },
    { lat: 49.126181, lng: 21.965623 },
    { lat: 49.125887, lng: 21.965387 },
    { lat: 49.124924, lng: 21.965023 },
    { lat: 49.124939, lng: 21.964991 },
    { lat: 49.124911, lng: 21.964994 },
    { lat: 49.124491, lng: 21.965067 },
    { lat: 49.123509, lng: 21.965485 },
    { lat: 49.123008, lng: 21.965879 },
    { lat: 49.122703, lng: 21.966215 },
    { lat: 49.122202, lng: 21.966718 },
    { lat: 49.121375, lng: 21.967551 },
    { lat: 49.120096, lng: 21.967709 },
    { lat: 49.119626, lng: 21.967917 },
    { lat: 49.118043, lng: 21.96879 },
    { lat: 49.116491, lng: 21.969631 },
    { lat: 49.11628, lng: 21.969082 },
    { lat: 49.114842, lng: 21.968875 },
    { lat: 49.114312, lng: 21.969048 },
    { lat: 49.111803, lng: 21.968284 },
    { lat: 49.111319, lng: 21.968239 },
    { lat: 49.110887, lng: 21.968426 },
    { lat: 49.110851, lng: 21.968681 },
    { lat: 49.110345, lng: 21.968888 },
    { lat: 49.109813, lng: 21.968864 },
    { lat: 49.108863, lng: 21.968213 },
    { lat: 49.107682, lng: 21.967249 },
    { lat: 49.106588, lng: 21.967227 },
    { lat: 49.106138, lng: 21.966295 },
    { lat: 49.106093, lng: 21.96589 },
    { lat: 49.106083, lng: 21.965788 },
    { lat: 49.105945, lng: 21.964529 },
    { lat: 49.105745, lng: 21.964187 },
    { lat: 49.105183, lng: 21.964068 },
    { lat: 49.104683, lng: 21.963956 },
    { lat: 49.104159, lng: 21.963996 },
    { lat: 49.103854, lng: 21.964249 },
    { lat: 49.103308, lng: 21.964413 },
    { lat: 49.103164, lng: 21.964071 },
    { lat: 49.102889, lng: 21.964073 },
    { lat: 49.102254, lng: 21.963475 },
    { lat: 49.101718, lng: 21.963008 },
    { lat: 49.10155, lng: 21.962719 },
    { lat: 49.101633, lng: 21.962181 },
    { lat: 49.101391, lng: 21.962217 },
    { lat: 49.101144, lng: 21.961992 },
    { lat: 49.101176, lng: 21.961639 },
    { lat: 49.101381, lng: 21.961065 },
    { lat: 49.101257, lng: 21.960869 },
    { lat: 49.101402, lng: 21.96033 },
    { lat: 49.101011, lng: 21.959294 },
    { lat: 49.100921, lng: 21.958849 },
    { lat: 49.100558, lng: 21.959144 },
    { lat: 49.1004003, lng: 21.9597583 },
    { lat: 49.1001554, lng: 21.9601309 },
    { lat: 49.0998865, lng: 21.9607249 },
    { lat: 49.0997221, lng: 21.9612688 },
    { lat: 49.098883, lng: 21.962322 },
    { lat: 49.098779, lng: 21.9637587 },
    { lat: 49.097928, lng: 21.9644627 },
    { lat: 49.0967014, lng: 21.965268 },
    { lat: 49.0968655, lng: 21.9658523 },
    { lat: 49.0957616, lng: 21.9665218 },
    { lat: 49.0950162, lng: 21.9668999 },
    { lat: 49.0939418, lng: 21.9676728 },
    { lat: 49.0927877, lng: 21.9684082 },
    { lat: 49.0918324, lng: 21.9692119 },
    { lat: 49.0913083, lng: 21.9695364 },
    { lat: 49.0899776, lng: 21.9699021 },
    { lat: 49.089858, lng: 21.9694287 },
    { lat: 49.0896749, lng: 21.9692059 },
    { lat: 49.0891788, lng: 21.968933 },
    { lat: 49.0888814, lng: 21.9690472 },
    { lat: 49.0885985, lng: 21.9690281 },
    { lat: 49.0882841, lng: 21.9691813 },
    { lat: 49.0880462, lng: 21.9694356 },
    { lat: 49.0878067, lng: 21.9694837 },
    { lat: 49.0875658, lng: 21.9697063 },
    { lat: 49.0876054, lng: 21.9697151 },
    { lat: 49.0875891, lng: 21.9700125 },
    { lat: 49.0871411, lng: 21.9704148 },
    { lat: 49.087309, lng: 21.9708434 },
    { lat: 49.0866466, lng: 21.971578 },
    { lat: 49.0857497, lng: 21.9718844 },
    { lat: 49.0854342, lng: 21.9725881 },
    { lat: 49.0854071, lng: 21.9729539 },
    { lat: 49.0848883, lng: 21.9730781 },
    { lat: 49.084626, lng: 21.9738035 },
    { lat: 49.0843518, lng: 21.9741844 },
    { lat: 49.084011, lng: 21.9748784 },
    { lat: 49.0838143, lng: 21.975515 },
    { lat: 49.0838572, lng: 21.976537 },
    { lat: 49.0840037, lng: 21.9770052 },
    { lat: 49.0846443, lng: 21.9805314 },
    { lat: 49.0844747, lng: 21.9826098 },
    { lat: 49.084008, lng: 21.9869408 },
    { lat: 49.0833513, lng: 21.9870479 },
    { lat: 49.0831012, lng: 21.9882246 },
    { lat: 49.0829502, lng: 21.9884423 },
    { lat: 49.0825506, lng: 21.9883874 },
    { lat: 49.0823953, lng: 21.9892129 },
    { lat: 49.0820863, lng: 21.9893663 },
    { lat: 49.0818661, lng: 21.9892876 },
    { lat: 49.0818312, lng: 21.9891421 },
    { lat: 49.0816744, lng: 21.9890799 },
    { lat: 49.0815739, lng: 21.9892908 },
    { lat: 49.0812405, lng: 21.9906097 },
    { lat: 49.0810891, lng: 21.991554 },
    { lat: 49.0810429, lng: 21.9915548 },
    { lat: 49.0810178, lng: 21.9917715 },
    { lat: 49.0810636, lng: 21.991986 },
    { lat: 49.081061, lng: 21.9923371 },
    { lat: 49.080903, lng: 21.9924102 },
    { lat: 49.0807854, lng: 21.9930279 },
    { lat: 49.0806663, lng: 21.9931713 },
    { lat: 49.0807838, lng: 21.9937086 },
    { lat: 49.0809138, lng: 21.9935904 },
    { lat: 49.0810186, lng: 21.9942046 },
    { lat: 49.0811838, lng: 21.9944765 },
    { lat: 49.0815401, lng: 21.9947826 },
    { lat: 49.0817851, lng: 21.9945445 },
    { lat: 49.0818994, lng: 21.9946213 },
    { lat: 49.0819474, lng: 21.9947916 },
    { lat: 49.0818053, lng: 21.9951032 },
    { lat: 49.081818, lng: 21.9952471 },
    { lat: 49.0819516, lng: 21.995336 },
    { lat: 49.0821662, lng: 21.995231 },
    { lat: 49.082246, lng: 21.9952757 },
    { lat: 49.082284, lng: 21.9955028 },
    { lat: 49.0822275, lng: 21.9955831 },
    { lat: 49.0822739, lng: 21.9956889 },
    { lat: 49.0825411, lng: 21.9958001 },
    { lat: 49.0826937, lng: 21.9957865 },
    { lat: 49.0831247, lng: 21.9962064 },
    { lat: 49.0832609, lng: 21.9962167 },
    { lat: 49.0832735, lng: 21.996084 },
    { lat: 49.083375, lng: 21.9959186 },
    { lat: 49.0834939, lng: 21.9958519 },
    { lat: 49.0837512, lng: 21.9953731 },
    { lat: 49.0838869, lng: 21.9954588 },
    { lat: 49.0841955, lng: 21.9960241 },
    { lat: 49.0840398, lng: 21.9963303 },
    { lat: 49.0843, lng: 21.9964345 },
    { lat: 49.0841418, lng: 21.9966357 },
    { lat: 49.0841989, lng: 21.9969981 },
    { lat: 49.0842682, lng: 21.9970265 },
    { lat: 49.0844311, lng: 21.9968817 },
    { lat: 49.0844219, lng: 21.9971937 },
    { lat: 49.084525, lng: 21.9973228 },
    { lat: 49.0844298, lng: 21.9976655 },
    { lat: 49.0847049, lng: 21.9976457 },
    { lat: 49.0848841, lng: 21.9977074 },
    { lat: 49.0848516, lng: 21.9979912 },
    { lat: 49.0851951, lng: 21.9979152 },
    { lat: 49.0852599, lng: 21.9979847 },
    { lat: 49.0853974, lng: 21.9985992 },
    { lat: 49.0853608, lng: 21.9988955 },
    { lat: 49.0854802, lng: 21.9989491 },
    { lat: 49.0856918, lng: 21.9994216 },
    { lat: 49.0856974, lng: 21.9996043 },
    { lat: 49.0858959, lng: 21.9994923 },
    { lat: 49.0859686, lng: 21.9996594 },
    { lat: 49.0858963, lng: 21.9999627 },
    { lat: 49.0859443, lng: 22.0000982 },
    { lat: 49.0862427, lng: 21.9998266 },
    { lat: 49.0864716, lng: 22.0000778 },
    { lat: 49.0864866, lng: 22.000143 },
    { lat: 49.0863957, lng: 22.0003889 },
    { lat: 49.0865348, lng: 22.0007392 },
    { lat: 49.0864764, lng: 22.0013931 },
    { lat: 49.086389, lng: 22.0015035 },
    { lat: 49.0865488, lng: 22.0018695 },
    { lat: 49.086632, lng: 22.0022007 },
    { lat: 49.0867556, lng: 22.0030206 },
    { lat: 49.0867515, lng: 22.0033201 },
    { lat: 49.0868674, lng: 22.0035113 },
    { lat: 49.0867215, lng: 22.0038016 },
    { lat: 49.0867411, lng: 22.0040929 },
    { lat: 49.0869065, lng: 22.0042397 },
    { lat: 49.0869046, lng: 22.004528 },
    { lat: 49.0868286, lng: 22.0046898 },
    { lat: 49.0869266, lng: 22.0050665 },
    { lat: 49.0868043, lng: 22.0054096 },
    { lat: 49.0867874, lng: 22.0058691 },
    { lat: 49.0869048, lng: 22.0061209 },
    { lat: 49.0868329, lng: 22.006355 },
    { lat: 49.086744, lng: 22.0064023 },
    { lat: 49.0865005, lng: 22.0064192 },
    { lat: 49.0864081, lng: 22.0069756 },
    { lat: 49.0864432, lng: 22.0079579 },
    { lat: 49.0865422, lng: 22.008144 },
    { lat: 49.086576, lng: 22.0084676 },
    { lat: 49.0867104, lng: 22.0087227 },
    { lat: 49.0867326, lng: 22.0089999 },
    { lat: 49.0867194, lng: 22.0093789 },
    { lat: 49.086554, lng: 22.0095665 },
    { lat: 49.0865197, lng: 22.009726 },
    { lat: 49.0866436, lng: 22.0099663 },
    { lat: 49.0866034, lng: 22.0101638 },
    { lat: 49.0866744, lng: 22.0103023 },
    { lat: 49.0865215, lng: 22.0105875 },
    { lat: 49.0865645, lng: 22.01079 },
    { lat: 49.0865104, lng: 22.010919 },
    { lat: 49.0865508, lng: 22.0111004 },
    { lat: 49.0865103, lng: 22.0112868 },
    { lat: 49.0866216, lng: 22.0116584 },
    { lat: 49.0865914, lng: 22.0117534 },
    { lat: 49.0869496, lng: 22.01215 },
    { lat: 49.0868905, lng: 22.0128778 },
    { lat: 49.0867453, lng: 22.0128177 },
    { lat: 49.0865909, lng: 22.0131784 },
    { lat: 49.0866521, lng: 22.0138471 },
    { lat: 49.0865995, lng: 22.014323 },
    { lat: 49.0868395, lng: 22.0149975 },
    { lat: 49.0871322, lng: 22.0156116 },
    { lat: 49.0871102, lng: 22.0157758 },
    { lat: 49.0873633, lng: 22.0161922 },
    { lat: 49.0872196, lng: 22.0169987 },
    { lat: 49.087449, lng: 22.0178787 },
    { lat: 49.0872719, lng: 22.0186555 },
    { lat: 49.0874701, lng: 22.0190384 },
    { lat: 49.087411, lng: 22.0195197 },
    { lat: 49.0871795, lng: 22.0198111 },
    { lat: 49.087106, lng: 22.0199781 },
    { lat: 49.087086, lng: 22.0201734 },
    { lat: 49.0868301, lng: 22.0207152 },
    { lat: 49.0870153, lng: 22.0214947 },
    { lat: 49.0867992, lng: 22.0220945 },
    { lat: 49.0867131, lng: 22.0226261 },
    { lat: 49.0865588, lng: 22.0228551 },
    { lat: 49.0849074, lng: 22.0266303 },
    { lat: 49.0846666, lng: 22.0288703 },
    { lat: 49.087151, lng: 22.0287207 },
    { lat: 49.0872687, lng: 22.0280001 },
    { lat: 49.0879339, lng: 22.0280692 },
    { lat: 49.0879979, lng: 22.028947 },
    { lat: 49.0887837, lng: 22.0288411 },
    { lat: 49.0889988, lng: 22.0268866 },
    { lat: 49.0893481, lng: 22.0258886 },
    { lat: 49.0895013, lng: 22.0266896 },
    { lat: 49.0928334, lng: 22.0278812 },
    { lat: 49.0960406, lng: 22.0295448 },
    { lat: 49.0970639, lng: 22.0299526 },
    { lat: 49.0985183, lng: 22.0310797 },
    { lat: 49.0992618, lng: 22.0313617 },
    { lat: 49.0998214, lng: 22.0312444 },
    { lat: 49.1003727, lng: 22.0324255 },
    { lat: 49.100595, lng: 22.032369 },
    { lat: 49.1006336, lng: 22.03257 },
    { lat: 49.1008967, lng: 22.0324681 },
    { lat: 49.1009505, lng: 22.0325567 },
    { lat: 49.1015952, lng: 22.0328474 },
    { lat: 49.1019242, lng: 22.0328252 },
    { lat: 49.1030697, lng: 22.032475 },
    { lat: 49.1032117, lng: 22.032625 },
    { lat: 49.1041652, lng: 22.03261 },
    { lat: 49.1054393, lng: 22.0327853 },
    { lat: 49.1055485, lng: 22.033499 },
    { lat: 49.107038, lng: 22.0346367 },
    { lat: 49.107963, lng: 22.0331453 },
    { lat: 49.1097296, lng: 22.0338375 },
    { lat: 49.1103266, lng: 22.0343023 },
    { lat: 49.1109883, lng: 22.0344529 },
    { lat: 49.1111535, lng: 22.0343543 },
    { lat: 49.1113233, lng: 22.034123 },
    { lat: 49.1114793, lng: 22.0334059 },
    { lat: 49.1131642, lng: 22.0328429 },
    { lat: 49.113935, lng: 22.0328075 },
    { lat: 49.1145633, lng: 22.0323604 },
    { lat: 49.116224, lng: 22.0328561 },
    { lat: 49.118431, lng: 22.0348883 },
    { lat: 49.1188846, lng: 22.0357418 },
    { lat: 49.1196959, lng: 22.0369861 },
    { lat: 49.1203855, lng: 22.0378282 },
    { lat: 49.1207723, lng: 22.0389544 },
    { lat: 49.1223726, lng: 22.0409216 },
    { lat: 49.1233573, lng: 22.0402418 },
    { lat: 49.1247969, lng: 22.0390611 },
    { lat: 49.1250485, lng: 22.0396595 },
    { lat: 49.1254475, lng: 22.0397454 },
    { lat: 49.1261574, lng: 22.0408289 },
    { lat: 49.1270949, lng: 22.0412333 },
    { lat: 49.1279582, lng: 22.041398 },
    { lat: 49.1284196, lng: 22.0413175 },
    { lat: 49.1287493, lng: 22.0414608 },
    { lat: 49.1293374, lng: 22.0420286 },
    { lat: 49.1295548, lng: 22.0423667 },
    { lat: 49.1297228, lng: 22.0429489 },
    { lat: 49.1296776, lng: 22.0432255 },
    { lat: 49.1297332, lng: 22.0439058 },
    { lat: 49.1301975, lng: 22.0448825 },
    { lat: 49.1307322, lng: 22.0455596 },
    { lat: 49.1310154, lng: 22.0458331 },
    { lat: 49.1313076, lng: 22.046435 },
    { lat: 49.1320478, lng: 22.0472394 },
    { lat: 49.1326163, lng: 22.0474971 },
    { lat: 49.1334439, lng: 22.0476294 },
    { lat: 49.1337615, lng: 22.0477468 },
    { lat: 49.1341733, lng: 22.0481339 },
    { lat: 49.134455, lng: 22.0485284 },
    { lat: 49.1347639, lng: 22.0486072 },
    { lat: 49.1350458, lng: 22.0485884 },
    { lat: 49.1367184, lng: 22.046943 },
    { lat: 49.1369163, lng: 22.0468526 },
    { lat: 49.137135, lng: 22.046496 },
    { lat: 49.1376077, lng: 22.0454235 },
    { lat: 49.1379665, lng: 22.0448547 },
    { lat: 49.138279, lng: 22.044578 },
    { lat: 49.13904, lng: 22.042606 },
    { lat: 49.139138, lng: 22.041781 },
    { lat: 49.13937, lng: 22.0409 },
    { lat: 49.138932, lng: 22.039209 },
    { lat: 49.138372, lng: 22.038656 },
    { lat: 49.137938, lng: 22.037644 },
    { lat: 49.137281, lng: 22.036427 },
    { lat: 49.136583, lng: 22.034767 },
    { lat: 49.136291, lng: 22.032404 },
    { lat: 49.135896, lng: 22.031096 },
    { lat: 49.135364, lng: 22.029039 },
    { lat: 49.135272, lng: 22.027078 },
    { lat: 49.134593, lng: 22.025832 },
    { lat: 49.133628, lng: 22.024871 },
    { lat: 49.133163, lng: 22.024103 },
    { lat: 49.132342, lng: 22.022617 },
    { lat: 49.131519, lng: 22.020901 },
    { lat: 49.130609, lng: 22.019584 },
    { lat: 49.130218, lng: 22.018543 },
    { lat: 49.129722, lng: 22.017421 },
    { lat: 49.129481, lng: 22.016343 },
    { lat: 49.128596, lng: 22.015663 },
    { lat: 49.129456, lng: 22.013434 },
    { lat: 49.129952, lng: 22.012149 },
    { lat: 49.13018, lng: 22.010553 },
    { lat: 49.130264, lng: 22.010011 },
    { lat: 49.130354, lng: 22.008722 },
    { lat: 49.130422, lng: 22.008148 },
    { lat: 49.130742, lng: 22.006344 },
    { lat: 49.131428, lng: 22.002514 },
    { lat: 49.131729, lng: 21.999864 },
    { lat: 49.13175, lng: 21.998742 },
    { lat: 49.131796, lng: 21.996871 },
    { lat: 49.131795, lng: 21.996792 },
    { lat: 49.131795, lng: 21.996778 },
    { lat: 49.131793, lng: 21.996695 },
    { lat: 49.131792, lng: 21.996664 },
    { lat: 49.131779, lng: 21.99655 },
    { lat: 49.131748, lng: 21.996281 },
    { lat: 49.1316, lng: 21.995005 },
    { lat: 49.131418, lng: 21.994826 },
    { lat: 49.130281, lng: 21.993945 },
    { lat: 49.129686, lng: 21.99318 },
    { lat: 49.12964, lng: 21.993121 },
    { lat: 49.129583, lng: 21.992742 },
    { lat: 49.129577, lng: 21.992589 },
    { lat: 49.129575, lng: 21.992481 },
    { lat: 49.129376, lng: 21.992117 },
    { lat: 49.129217, lng: 21.991838 },
  ],
};

export default UnitsHumenné;
