const UnitsMichalovce = {
  Michalovce: [
    { lat: 48.7761178, lng: 21.8537682 },
    { lat: 48.7757296, lng: 21.8534133 },
    { lat: 48.7750063, lng: 21.8543368 },
    { lat: 48.7754993, lng: 21.8550911 },
    { lat: 48.7755026, lng: 21.8553103 },
    { lat: 48.7753542, lng: 21.855701 },
    { lat: 48.774866, lng: 21.856061 },
    { lat: 48.7745113, lng: 21.8564838 },
    { lat: 48.7736091, lng: 21.85688 },
    { lat: 48.7737857, lng: 21.8573593 },
    { lat: 48.7736431, lng: 21.8576804 },
    { lat: 48.7731775, lng: 21.8582478 },
    { lat: 48.7733634, lng: 21.8591809 },
    { lat: 48.7722397, lng: 21.8598001 },
    { lat: 48.7724455, lng: 21.8612891 },
    { lat: 48.7723316, lng: 21.8618051 },
    { lat: 48.7719454, lng: 21.8621869 },
    { lat: 48.771308, lng: 21.8629533 },
    { lat: 48.7713931, lng: 21.8632482 },
    { lat: 48.7704766, lng: 21.863966 },
    { lat: 48.7706563, lng: 21.8667208 },
    { lat: 48.770708, lng: 21.8668073 },
    { lat: 48.7701747, lng: 21.8674223 },
    { lat: 48.7695896, lng: 21.8675411 },
    { lat: 48.7688869, lng: 21.8678308 },
    { lat: 48.7683285, lng: 21.8677694 },
    { lat: 48.768301, lng: 21.8678377 },
    { lat: 48.7681995, lng: 21.8679602 },
    { lat: 48.7680972, lng: 21.8683103 },
    { lat: 48.7677771, lng: 21.8652087 },
    { lat: 48.7631742, lng: 21.8662903 },
    { lat: 48.7631458, lng: 21.8668026 },
    { lat: 48.7621069, lng: 21.8664334 },
    { lat: 48.7620371, lng: 21.8665612 },
    { lat: 48.7620016, lng: 21.8670622 },
    { lat: 48.7618899, lng: 21.8674046 },
    { lat: 48.7619564, lng: 21.8681984 },
    { lat: 48.7617719, lng: 21.8689005 },
    { lat: 48.7616032, lng: 21.8691247 },
    { lat: 48.7612343, lng: 21.869208 },
    { lat: 48.7611331, lng: 21.8692977 },
    { lat: 48.7611088, lng: 21.8695006 },
    { lat: 48.7607471, lng: 21.8697364 },
    { lat: 48.7602765, lng: 21.8695518 },
    { lat: 48.7600617, lng: 21.8684968 },
    { lat: 48.7603754, lng: 21.8674348 },
    { lat: 48.7607084, lng: 21.8667785 },
    { lat: 48.760912, lng: 21.8661112 },
    { lat: 48.76087, lng: 21.8649619 },
    { lat: 48.7605468, lng: 21.8647666 },
    { lat: 48.7602277, lng: 21.8648259 },
    { lat: 48.7598676, lng: 21.8649946 },
    { lat: 48.7592536, lng: 21.8648355 },
    { lat: 48.7588945, lng: 21.8648227 },
    { lat: 48.7585876, lng: 21.8649263 },
    { lat: 48.7584034, lng: 21.8651032 },
    { lat: 48.7579631, lng: 21.8652285 },
    { lat: 48.7578382, lng: 21.8648226 },
    { lat: 48.7576119, lng: 21.8643381 },
    { lat: 48.7571793, lng: 21.8642722 },
    { lat: 48.7565186, lng: 21.8643469 },
    { lat: 48.7564163, lng: 21.8634295 },
    { lat: 48.7558486, lng: 21.8636221 },
    { lat: 48.7558112, lng: 21.8634781 },
    { lat: 48.7554486, lng: 21.8632434 },
    { lat: 48.7553509, lng: 21.8633803 },
    { lat: 48.755186, lng: 21.8638339 },
    { lat: 48.7549315, lng: 21.8638762 },
    { lat: 48.7547315, lng: 21.8637903 },
    { lat: 48.7544898, lng: 21.8635377 },
    { lat: 48.7543117, lng: 21.8635229 },
    { lat: 48.7535725, lng: 21.8636475 },
    { lat: 48.7527458, lng: 21.8641811 },
    { lat: 48.7511392, lng: 21.8636921 },
    { lat: 48.7510451, lng: 21.8634897 },
    { lat: 48.7506806, lng: 21.8640223 },
    { lat: 48.7503637, lng: 21.8643382 },
    { lat: 48.7470053, lng: 21.866308 },
    { lat: 48.7461556, lng: 21.8666832 },
    { lat: 48.7452768, lng: 21.8664606 },
    { lat: 48.7365896, lng: 21.8593054 },
    { lat: 48.7347976, lng: 21.8591337 },
    { lat: 48.7342099, lng: 21.8592548 },
    { lat: 48.7341216, lng: 21.8629952 },
    { lat: 48.7311741, lng: 21.862912 },
    { lat: 48.7312368, lng: 21.8641724 },
    { lat: 48.7312417, lng: 21.8656331 },
    { lat: 48.7309025, lng: 21.8660122 },
    { lat: 48.7310064, lng: 21.8672969 },
    { lat: 48.7293618, lng: 21.8675691 },
    { lat: 48.7294058, lng: 21.8678349 },
    { lat: 48.7293705, lng: 21.8682125 },
    { lat: 48.729479, lng: 21.8687133 },
    { lat: 48.7296977, lng: 21.8735671 },
    { lat: 48.7285242, lng: 21.8747114 },
    { lat: 48.7284145, lng: 21.874819 },
    { lat: 48.7285892, lng: 21.8765449 },
    { lat: 48.7284499, lng: 21.876557 },
    { lat: 48.7285072, lng: 21.8790245 },
    { lat: 48.7287703, lng: 21.8798105 },
    { lat: 48.7288154, lng: 21.8802551 },
    { lat: 48.7287323, lng: 21.8804848 },
    { lat: 48.7287014, lng: 21.8813041 },
    { lat: 48.728686, lng: 21.8848487 },
    { lat: 48.7293561, lng: 21.8848814 },
    { lat: 48.7292554, lng: 21.8887404 },
    { lat: 48.729845, lng: 21.8888821 },
    { lat: 48.7297224, lng: 21.8903467 },
    { lat: 48.730095, lng: 21.8903165 },
    { lat: 48.7309298, lng: 21.8903996 },
    { lat: 48.730846, lng: 21.8917037 },
    { lat: 48.7312164, lng: 21.8919869 },
    { lat: 48.7318489, lng: 21.8922652 },
    { lat: 48.732006, lng: 21.895211 },
    { lat: 48.7320853, lng: 21.8958119 },
    { lat: 48.7325665, lng: 21.8958517 },
    { lat: 48.7326339, lng: 21.8959778 },
    { lat: 48.7326958, lng: 21.8962928 },
    { lat: 48.7320677, lng: 21.8972454 },
    { lat: 48.7313009, lng: 21.8985496 },
    { lat: 48.7300296, lng: 21.8985498 },
    { lat: 48.7285723, lng: 21.8990168 },
    { lat: 48.7284361, lng: 21.8989748 },
    { lat: 48.726411, lng: 21.9000277 },
    { lat: 48.7267703, lng: 21.9035149 },
    { lat: 48.7259308, lng: 21.9034177 },
    { lat: 48.7256929, lng: 21.9032601 },
    { lat: 48.7247633, lng: 21.903291 },
    { lat: 48.7239798, lng: 21.9041622 },
    { lat: 48.7228641, lng: 21.9050575 },
    { lat: 48.7227414, lng: 21.9050543 },
    { lat: 48.7210276, lng: 21.9057899 },
    { lat: 48.7206671, lng: 21.9058161 },
    { lat: 48.7188995, lng: 21.9064291 },
    { lat: 48.7165119, lng: 21.9079766 },
    { lat: 48.7159346, lng: 21.9082304 },
    { lat: 48.7155007, lng: 21.9083338 },
    { lat: 48.7142273, lng: 21.9089142 },
    { lat: 48.7141505, lng: 21.9089083 },
    { lat: 48.7139778, lng: 21.9085666 },
    { lat: 48.7138106, lng: 21.9085004 },
    { lat: 48.7133026, lng: 21.9087406 },
    { lat: 48.7125783, lng: 21.9091934 },
    { lat: 48.7122996, lng: 21.9095187 },
    { lat: 48.7117202, lng: 21.909961 },
    { lat: 48.7103058, lng: 21.9106143 },
    { lat: 48.7098709, lng: 21.9106814 },
    { lat: 48.7095088, lng: 21.9109035 },
    { lat: 48.7090927, lng: 21.9113129 },
    { lat: 48.7098523, lng: 21.9154278 },
    { lat: 48.7104762, lng: 21.9188029 },
    { lat: 48.7110173, lng: 21.9205856 },
    { lat: 48.7114036, lng: 21.9215016 },
    { lat: 48.7116178, lng: 21.9221451 },
    { lat: 48.7123187, lng: 21.9246992 },
    { lat: 48.7123884, lng: 21.9255497 },
    { lat: 48.71217, lng: 21.9261664 },
    { lat: 48.7126093, lng: 21.9273541 },
    { lat: 48.71313, lng: 21.9281607 },
    { lat: 48.7128132, lng: 21.9283591 },
    { lat: 48.7128255, lng: 21.9283969 },
    { lat: 48.7130536, lng: 21.9286567 },
    { lat: 48.7132949, lng: 21.9284929 },
    { lat: 48.713976, lng: 21.9283352 },
    { lat: 48.7160793, lng: 21.9276526 },
    { lat: 48.7163355, lng: 21.9276785 },
    { lat: 48.7167047, lng: 21.9278212 },
    { lat: 48.7175914, lng: 21.9285965 },
    { lat: 48.7179288, lng: 21.928776 },
    { lat: 48.7183676, lng: 21.9287715 },
    { lat: 48.7186744, lng: 21.9285879 },
    { lat: 48.7189311, lng: 21.9293955 },
    { lat: 48.7190519, lng: 21.9299532 },
    { lat: 48.7192454, lng: 21.9304986 },
    { lat: 48.7197237, lng: 21.9314022 },
    { lat: 48.7205901, lng: 21.9326195 },
    { lat: 48.7210107, lng: 21.9335627 },
    { lat: 48.7220333, lng: 21.9336453 },
    { lat: 48.7220425, lng: 21.933788 },
    { lat: 48.7220431, lng: 21.9368744 },
    { lat: 48.7217282, lng: 21.9371835 },
    { lat: 48.7215028, lng: 21.9376659 },
    { lat: 48.7213079, lng: 21.9381951 },
    { lat: 48.7212152, lng: 21.9388053 },
    { lat: 48.7204108, lng: 21.940649 },
    { lat: 48.7195779, lng: 21.9418953 },
    { lat: 48.7198289, lng: 21.9426188 },
    { lat: 48.7197172, lng: 21.9427898 },
    { lat: 48.7196263, lng: 21.9431612 },
    { lat: 48.7191915, lng: 21.9443471 },
    { lat: 48.7191599, lng: 21.9454736 },
    { lat: 48.7189462, lng: 21.9472364 },
    { lat: 48.7189196, lng: 21.9477929 },
    { lat: 48.7189736, lng: 21.9482876 },
    { lat: 48.7188853, lng: 21.9489424 },
    { lat: 48.7187264, lng: 21.9496158 },
    { lat: 48.7182414, lng: 21.9510987 },
    { lat: 48.717719, lng: 21.9523938 },
    { lat: 48.7176971, lng: 21.9524125 },
    { lat: 48.718509, lng: 21.9565228 },
    { lat: 48.7217478, lng: 21.9612812 },
    { lat: 48.72171, lng: 21.9615263 },
    { lat: 48.7217013, lng: 21.961586 },
    { lat: 48.7216649, lng: 21.961753 },
    { lat: 48.7218409, lng: 21.9632686 },
    { lat: 48.7217887, lng: 21.9637514 },
    { lat: 48.7217976, lng: 21.9641571 },
    { lat: 48.7223265, lng: 21.9691631 },
    { lat: 48.7223926, lng: 21.9691476 },
    { lat: 48.7228279, lng: 21.9715091 },
    { lat: 48.7236489, lng: 21.9714577 },
    { lat: 48.7254281, lng: 21.9761356 },
    { lat: 48.7253841, lng: 21.9773619 },
    { lat: 48.7255307, lng: 21.9782394 },
    { lat: 48.7260898, lng: 21.9781983 },
    { lat: 48.7264684, lng: 21.9782689 },
    { lat: 48.7262903, lng: 21.9787981 },
    { lat: 48.7268871, lng: 21.978778 },
    { lat: 48.727187, lng: 21.9788541 },
    { lat: 48.7274529, lng: 21.9787797 },
    { lat: 48.7278188, lng: 21.9788742 },
    { lat: 48.7287219, lng: 21.9779574 },
    { lat: 48.7290102, lng: 21.9777869 },
    { lat: 48.7291883, lng: 21.9777527 },
    { lat: 48.7293393, lng: 21.9779975 },
    { lat: 48.7294988, lng: 21.9780822 },
    { lat: 48.7296747, lng: 21.9779604 },
    { lat: 48.7299752, lng: 21.9783287 },
    { lat: 48.7305506, lng: 21.9785404 },
    { lat: 48.7307036, lng: 21.9786269 },
    { lat: 48.7313449, lng: 21.9779886 },
    { lat: 48.7304648, lng: 21.9706428 },
    { lat: 48.7304792, lng: 21.9702614 },
    { lat: 48.7307055, lng: 21.9699091 },
    { lat: 48.7342446, lng: 21.9673187 },
    { lat: 48.7371124, lng: 21.9641946 },
    { lat: 48.7374346, lng: 21.9639462 },
    { lat: 48.7375641, lng: 21.9637681 },
    { lat: 48.7385774, lng: 21.9727613 },
    { lat: 48.7402569, lng: 21.9703937 },
    { lat: 48.7410818, lng: 21.9694062 },
    { lat: 48.7438329, lng: 21.9662963 },
    { lat: 48.744288, lng: 21.9659576 },
    { lat: 48.743444, lng: 21.9634006 },
    { lat: 48.7427208, lng: 21.9609096 },
    { lat: 48.7438301, lng: 21.9599602 },
    { lat: 48.7441625, lng: 21.9597974 },
    { lat: 48.7447421, lng: 21.9597642 },
    { lat: 48.7457646, lng: 21.959991 },
    { lat: 48.7461703, lng: 21.9599241 },
    { lat: 48.746532, lng: 21.9596844 },
    { lat: 48.7467093, lng: 21.9594833 },
    { lat: 48.747035, lng: 21.9587783 },
    { lat: 48.7471659, lng: 21.9582127 },
    { lat: 48.7477258, lng: 21.9554779 },
    { lat: 48.7478404, lng: 21.9551114 },
    { lat: 48.7486126, lng: 21.9532773 },
    { lat: 48.7494394, lng: 21.9520002 },
    { lat: 48.7489102, lng: 21.9503631 },
    { lat: 48.7529992, lng: 21.9452033 },
    { lat: 48.7598206, lng: 21.958275 },
    { lat: 48.7601414, lng: 21.9595945 },
    { lat: 48.7607893, lng: 21.9604067 },
    { lat: 48.761381, lng: 21.9613091 },
    { lat: 48.7620494, lng: 21.9621794 },
    { lat: 48.7627244, lng: 21.9632787 },
    { lat: 48.7634509, lng: 21.9632698 },
    { lat: 48.7637805, lng: 21.9633585 },
    { lat: 48.7640005, lng: 21.9632469 },
    { lat: 48.7643424, lng: 21.9635192 },
    { lat: 48.7668796, lng: 21.962131 },
    { lat: 48.7685199, lng: 21.9612335 },
    { lat: 48.769464, lng: 21.9607838 },
    { lat: 48.766439, lng: 21.9562936 },
    { lat: 48.7660871, lng: 21.9548031 },
    { lat: 48.7661063, lng: 21.9534498 },
    { lat: 48.7664952, lng: 21.9527548 },
    { lat: 48.7668592, lng: 21.951837 },
    { lat: 48.7667627, lng: 21.951437 },
    { lat: 48.7666067, lng: 21.9512669 },
    { lat: 48.7665736, lng: 21.9510256 },
    { lat: 48.7668296, lng: 21.9497394 },
    { lat: 48.7668461, lng: 21.9494528 },
    { lat: 48.7667488, lng: 21.9480744 },
    { lat: 48.7708404, lng: 21.9475921 },
    { lat: 48.7699851, lng: 21.9457636 },
    { lat: 48.7714641, lng: 21.9440121 },
    { lat: 48.772531, lng: 21.9427486 },
    { lat: 48.7729524, lng: 21.9423301 },
    { lat: 48.7748006, lng: 21.9397516 },
    { lat: 48.7750703, lng: 21.94033 },
    { lat: 48.7754267, lng: 21.940677 },
    { lat: 48.7761684, lng: 21.9418248 },
    { lat: 48.7772323, lng: 21.9423943 },
    { lat: 48.7777365, lng: 21.9425941 },
    { lat: 48.7779379, lng: 21.9424089 },
    { lat: 48.778713, lng: 21.9420473 },
    { lat: 48.7797404, lng: 21.9412503 },
    { lat: 48.7811395, lng: 21.9404295 },
    { lat: 48.7818679, lng: 21.9395528 },
    { lat: 48.7835056, lng: 21.940168 },
    { lat: 48.783824, lng: 21.9402237 },
    { lat: 48.7852039, lng: 21.9397492 },
    { lat: 48.785877, lng: 21.9392716 },
    { lat: 48.7873302, lng: 21.9384551 },
    { lat: 48.7883375, lng: 21.9377961 },
    { lat: 48.7895058, lng: 21.94134 },
    { lat: 48.7898089, lng: 21.9404604 },
    { lat: 48.789975, lng: 21.9413906 },
    { lat: 48.790709, lng: 21.9411817 },
    { lat: 48.7913547, lng: 21.9405504 },
    { lat: 48.7916677, lng: 21.9412868 },
    { lat: 48.7917284, lng: 21.9423208 },
    { lat: 48.792844, lng: 21.9414101 },
    { lat: 48.7934631, lng: 21.9405145 },
    { lat: 48.7937681, lng: 21.9402577 },
    { lat: 48.7946929, lng: 21.9391352 },
    { lat: 48.7953725, lng: 21.9377872 },
    { lat: 48.7960935, lng: 21.9359303 },
    { lat: 48.7964525, lng: 21.93482 },
    { lat: 48.7969304, lng: 21.9348141 },
    { lat: 48.7973092, lng: 21.9323215 },
    { lat: 48.7975681, lng: 21.9298115 },
    { lat: 48.797535, lng: 21.9244544 },
    { lat: 48.7975758, lng: 21.9242829 },
    { lat: 48.7975676, lng: 21.9218446 },
    { lat: 48.7974723, lng: 21.9185956 },
    { lat: 48.7974022, lng: 21.9152256 },
    { lat: 48.7974333, lng: 21.9115839 },
    { lat: 48.7963838, lng: 21.9023129 },
    { lat: 48.7961829, lng: 21.8995792 },
    { lat: 48.7955895, lng: 21.8999576 },
    { lat: 48.7954351, lng: 21.8955903 },
    { lat: 48.7953861, lng: 21.8924105 },
    { lat: 48.7951272, lng: 21.8923609 },
    { lat: 48.7949959, lng: 21.8907358 },
    { lat: 48.794858, lng: 21.8890166 },
    { lat: 48.7945696, lng: 21.8894044 },
    { lat: 48.7942379, lng: 21.8898126 },
    { lat: 48.7938408, lng: 21.8899838 },
    { lat: 48.7930786, lng: 21.8897027 },
    { lat: 48.7924951, lng: 21.8889107 },
    { lat: 48.7922938, lng: 21.8884275 },
    { lat: 48.7922185, lng: 21.8880341 },
    { lat: 48.791999, lng: 21.8881175 },
    { lat: 48.7916084, lng: 21.888107 },
    { lat: 48.7915456, lng: 21.8883829 },
    { lat: 48.790674, lng: 21.8885253 },
    { lat: 48.7901903, lng: 21.8888799 },
    { lat: 48.7898406, lng: 21.8889327 },
    { lat: 48.7894083, lng: 21.888692 },
    { lat: 48.7889525, lng: 21.8883346 },
    { lat: 48.7887074, lng: 21.8883832 },
    { lat: 48.7874624, lng: 21.8874706 },
    { lat: 48.7866375, lng: 21.8887397 },
    { lat: 48.7865304, lng: 21.888905 },
    { lat: 48.7864317, lng: 21.8886093 },
    { lat: 48.7863972, lng: 21.8882268 },
    { lat: 48.786299, lng: 21.8882107 },
    { lat: 48.7849326, lng: 21.8838021 },
    { lat: 48.7846284, lng: 21.8841791 },
    { lat: 48.7843293, lng: 21.8835226 },
    { lat: 48.7844407, lng: 21.8833892 },
    { lat: 48.7842396, lng: 21.8829147 },
    { lat: 48.7839355, lng: 21.8818412 },
    { lat: 48.7835324, lng: 21.8799715 },
    { lat: 48.7834208, lng: 21.8787741 },
    { lat: 48.7835293, lng: 21.878746 },
    { lat: 48.7835063, lng: 21.8784634 },
    { lat: 48.7837604, lng: 21.8784075 },
    { lat: 48.7840892, lng: 21.8785528 },
    { lat: 48.7843894, lng: 21.8791232 },
    { lat: 48.7845648, lng: 21.8790298 },
    { lat: 48.7847178, lng: 21.8787277 },
    { lat: 48.7847061, lng: 21.8786171 },
    { lat: 48.7847753, lng: 21.8785484 },
    { lat: 48.7851142, lng: 21.878519 },
    { lat: 48.7852559, lng: 21.8784269 },
    { lat: 48.7852722, lng: 21.8783618 },
    { lat: 48.7836, lng: 21.8710206 },
    { lat: 48.7832494, lng: 21.868437 },
    { lat: 48.7828767, lng: 21.8686047 },
    { lat: 48.7827449, lng: 21.8678919 },
    { lat: 48.7825679, lng: 21.8679664 },
    { lat: 48.7823781, lng: 21.8675423 },
    { lat: 48.781136, lng: 21.8681523 },
    { lat: 48.7808285, lng: 21.8681735 },
    { lat: 48.7802589, lng: 21.8684298 },
    { lat: 48.7790295, lng: 21.8690554 },
    { lat: 48.7760999, lng: 21.8539519 },
    { lat: 48.7761178, lng: 21.8537682 },
  ],
  Sliepkovce: [
    { lat: 48.6593429, lng: 21.9233168 },
    { lat: 48.6589154, lng: 21.923405 },
    { lat: 48.657842, lng: 21.923579 },
    { lat: 48.6572087, lng: 21.9215728 },
    { lat: 48.6570031, lng: 21.9211316 },
    { lat: 48.655725, lng: 21.922118 },
    { lat: 48.6556363, lng: 21.9220306 },
    { lat: 48.6552723, lng: 21.9212053 },
    { lat: 48.6547785, lng: 21.92134 },
    { lat: 48.654184, lng: 21.921361 },
    { lat: 48.653978, lng: 21.920817 },
    { lat: 48.6534594, lng: 21.9199758 },
    { lat: 48.6535113, lng: 21.9197063 },
    { lat: 48.6534957, lng: 21.918379 },
    { lat: 48.6530556, lng: 21.9183239 },
    { lat: 48.6518463, lng: 21.9183257 },
    { lat: 48.651278, lng: 21.9185405 },
    { lat: 48.6510407, lng: 21.9187704 },
    { lat: 48.6507687, lng: 21.9192208 },
    { lat: 48.650308, lng: 21.9203217 },
    { lat: 48.6507669, lng: 21.9219651 },
    { lat: 48.6514593, lng: 21.9239996 },
    { lat: 48.6519082, lng: 21.9255187 },
    { lat: 48.6515226, lng: 21.9268252 },
    { lat: 48.65092, lng: 21.9274215 },
    { lat: 48.6503589, lng: 21.9282 },
    { lat: 48.6499723, lng: 21.9283502 },
    { lat: 48.6494767, lng: 21.9281402 },
    { lat: 48.6490116, lng: 21.9278121 },
    { lat: 48.6486095, lng: 21.9273933 },
    { lat: 48.648455, lng: 21.926883 },
    { lat: 48.6483194, lng: 21.9267706 },
    { lat: 48.6475432, lng: 21.9269057 },
    { lat: 48.647371, lng: 21.9270323 },
    { lat: 48.646917, lng: 21.9266964 },
    { lat: 48.6465638, lng: 21.926346 },
    { lat: 48.646297, lng: 21.9261962 },
    { lat: 48.645953, lng: 21.9262034 },
    { lat: 48.6447757, lng: 21.9266835 },
    { lat: 48.6423286, lng: 21.9286819 },
    { lat: 48.6406354, lng: 21.9294859 },
    { lat: 48.6394094, lng: 21.9315554 },
    { lat: 48.638524, lng: 21.932625 },
    { lat: 48.638222, lng: 21.9332808 },
    { lat: 48.6381125, lng: 21.9338888 },
    { lat: 48.6380299, lng: 21.9348169 },
    { lat: 48.6386077, lng: 21.9360086 },
    { lat: 48.6386573, lng: 21.9363248 },
    { lat: 48.6385095, lng: 21.936711 },
    { lat: 48.6372943, lng: 21.9379623 },
    { lat: 48.6379279, lng: 21.9393606 },
    { lat: 48.6386406, lng: 21.9384429 },
    { lat: 48.6395363, lng: 21.9370665 },
    { lat: 48.640069, lng: 21.9377571 },
    { lat: 48.6403052, lng: 21.9382085 },
    { lat: 48.6404909, lng: 21.938707 },
    { lat: 48.6388466, lng: 21.9415972 },
    { lat: 48.638874, lng: 21.9421078 },
    { lat: 48.6431949, lng: 21.9427813 },
    { lat: 48.6452714, lng: 21.9415489 },
    { lat: 48.6500652, lng: 21.9458828 },
    { lat: 48.6516358, lng: 21.9546421 },
    { lat: 48.6517032, lng: 21.9548208 },
    { lat: 48.6517493, lng: 21.9555945 },
    { lat: 48.6517852, lng: 21.9556338 },
    { lat: 48.6517046, lng: 21.9557408 },
    { lat: 48.6516732, lng: 21.955966 },
    { lat: 48.6517309, lng: 21.9561616 },
    { lat: 48.6518619, lng: 21.9562741 },
    { lat: 48.6520413, lng: 21.9567955 },
    { lat: 48.6522167, lng: 21.9570115 },
    { lat: 48.6530385, lng: 21.9569023 },
    { lat: 48.653685, lng: 21.9570645 },
    { lat: 48.6539259, lng: 21.9568 },
    { lat: 48.6541339, lng: 21.9568886 },
    { lat: 48.6544155, lng: 21.9565428 },
    { lat: 48.6549446, lng: 21.9561962 },
    { lat: 48.6555009, lng: 21.9563098 },
    { lat: 48.6557101, lng: 21.9560756 },
    { lat: 48.6557012, lng: 21.9557199 },
    { lat: 48.6558683, lng: 21.9556136 },
    { lat: 48.6561718, lng: 21.9556466 },
    { lat: 48.6563698, lng: 21.9555137 },
    { lat: 48.6564831, lng: 21.9552724 },
    { lat: 48.6565353, lng: 21.9553823 },
    { lat: 48.6567375, lng: 21.955464 },
    { lat: 48.6568256, lng: 21.955649 },
    { lat: 48.6572149, lng: 21.9557003 },
    { lat: 48.6576528, lng: 21.9555356 },
    { lat: 48.6578906, lng: 21.9551012 },
    { lat: 48.659658, lng: 21.9623881 },
    { lat: 48.6724301, lng: 21.95535 },
    { lat: 48.672432, lng: 21.9549723 },
    { lat: 48.672607, lng: 21.9546345 },
    { lat: 48.6728002, lng: 21.9543933 },
    { lat: 48.673151, lng: 21.9532088 },
    { lat: 48.6734065, lng: 21.9533975 },
    { lat: 48.673576, lng: 21.9535217 },
    { lat: 48.6737004, lng: 21.9530096 },
    { lat: 48.6741527, lng: 21.9518303 },
    { lat: 48.674598, lng: 21.9509233 },
    { lat: 48.6750573, lng: 21.9503028 },
    { lat: 48.67608, lng: 21.9493594 },
    { lat: 48.6784131, lng: 21.9481415 },
    { lat: 48.6783739, lng: 21.9480212 },
    { lat: 48.6783266, lng: 21.9478762 },
    { lat: 48.6780546, lng: 21.9471609 },
    { lat: 48.6782397, lng: 21.9429029 },
    { lat: 48.6737811, lng: 21.9434274 },
    { lat: 48.6736166, lng: 21.9451285 },
    { lat: 48.6652435, lng: 21.9424306 },
    { lat: 48.66424, lng: 21.938876 },
    { lat: 48.6634886, lng: 21.937084 },
    { lat: 48.6632769, lng: 21.936263 },
    { lat: 48.6628864, lng: 21.9352732 },
    { lat: 48.6625658, lng: 21.934283 },
    { lat: 48.6623956, lng: 21.933334 },
    { lat: 48.6620806, lng: 21.9322513 },
    { lat: 48.6593429, lng: 21.9233168 },
  ],
  BánovcenadOndavou: [
    { lat: 48.6542875, lng: 21.7955536 },
    { lat: 48.6550637, lng: 21.7958082 },
    { lat: 48.6557018, lng: 21.7964141 },
    { lat: 48.6566725, lng: 21.7971275 },
    { lat: 48.6569814, lng: 21.7970681 },
    { lat: 48.6572013, lng: 21.7972649 },
    { lat: 48.6578429, lng: 21.7971073 },
    { lat: 48.6584238, lng: 21.7970701 },
    { lat: 48.6586957, lng: 21.7967129 },
    { lat: 48.6588002, lng: 21.7966255 },
    { lat: 48.6588954, lng: 21.7966511 },
    { lat: 48.6589576, lng: 21.7967499 },
    { lat: 48.6589946, lng: 21.7969648 },
    { lat: 48.6589262, lng: 21.7973582 },
    { lat: 48.6589792, lng: 21.7977874 },
    { lat: 48.6592793, lng: 21.7977766 },
    { lat: 48.6598866, lng: 21.798416 },
    { lat: 48.6600727, lng: 21.7984722 },
    { lat: 48.6606698, lng: 21.7981653 },
    { lat: 48.6619607, lng: 21.804015 },
    { lat: 48.6624337, lng: 21.8039221 },
    { lat: 48.6625719, lng: 21.8038949 },
    { lat: 48.6629412, lng: 21.8052392 },
    { lat: 48.6642928, lng: 21.8083054 },
    { lat: 48.6646462, lng: 21.8092624 },
    { lat: 48.665044, lng: 21.8105829 },
    { lat: 48.6647568, lng: 21.8118603 },
    { lat: 48.6646499, lng: 21.8147833 },
    { lat: 48.6664302, lng: 21.8204108 },
    { lat: 48.6675043, lng: 21.8226509 },
    { lat: 48.667439, lng: 21.8227317 },
    { lat: 48.6697877, lng: 21.8270052 },
    { lat: 48.6706238, lng: 21.8282289 },
    { lat: 48.6708461, lng: 21.8286361 },
    { lat: 48.6729365, lng: 21.834103 },
    { lat: 48.6736284, lng: 21.8360884 },
    { lat: 48.6739865, lng: 21.8371161 },
    { lat: 48.6742677, lng: 21.8381521 },
    { lat: 48.6753485, lng: 21.8437977 },
    { lat: 48.6763995, lng: 21.8495981 },
    { lat: 48.6766743, lng: 21.8510934 },
    { lat: 48.6772299, lng: 21.8506976 },
    { lat: 48.6789014, lng: 21.8494221 },
    { lat: 48.6789777, lng: 21.8496736 },
    { lat: 48.6793722, lng: 21.8494834 },
    { lat: 48.6793905, lng: 21.8495379 },
    { lat: 48.6822473, lng: 21.849812 },
    { lat: 48.6834591, lng: 21.8492555 },
    { lat: 48.6836202, lng: 21.8429397 },
    { lat: 48.6846183, lng: 21.8426494 },
    { lat: 48.6857076, lng: 21.8422256 },
    { lat: 48.685783, lng: 21.8424426 },
    { lat: 48.6864252, lng: 21.8420402 },
    { lat: 48.6866039, lng: 21.8427339 },
    { lat: 48.6889096, lng: 21.8415817 },
    { lat: 48.6895154, lng: 21.841212 },
    { lat: 48.6914499, lng: 21.8398007 },
    { lat: 48.6920623, lng: 21.8396413 },
    { lat: 48.6926289, lng: 21.8393188 },
    { lat: 48.6939776, lng: 21.8383984 },
    { lat: 48.6958858, lng: 21.8369706 },
    { lat: 48.6976501, lng: 21.8352385 },
    { lat: 48.69749, lng: 21.8349656 },
    { lat: 48.6970101, lng: 21.8343757 },
    { lat: 48.6956894, lng: 21.8306929 },
    { lat: 48.6961586, lng: 21.8303043 },
    { lat: 48.695585, lng: 21.8284965 },
    { lat: 48.6949595, lng: 21.8268506 },
    { lat: 48.694302, lng: 21.8253084 },
    { lat: 48.694669, lng: 21.8249815 },
    { lat: 48.6942316, lng: 21.8238173 },
    { lat: 48.694261, lng: 21.8236977 },
    { lat: 48.6942082, lng: 21.8234352 },
    { lat: 48.6940134, lng: 21.8228674 },
    { lat: 48.6925342, lng: 21.8193337 },
    { lat: 48.6923157, lng: 21.8195436 },
    { lat: 48.6906245, lng: 21.8161145 },
    { lat: 48.6893069, lng: 21.8123106 },
    { lat: 48.688775, lng: 21.8104316 },
    { lat: 48.6885859, lng: 21.8099533 },
    { lat: 48.6885911, lng: 21.8097187 },
    { lat: 48.6866305, lng: 21.80474 },
    { lat: 48.6865433, lng: 21.8047655 },
    { lat: 48.6861126, lng: 21.8053264 },
    { lat: 48.6858496, lng: 21.8053396 },
    { lat: 48.6856664, lng: 21.8050845 },
    { lat: 48.6853983, lng: 21.8048563 },
    { lat: 48.6850369, lng: 21.8046785 },
    { lat: 48.6849537, lng: 21.8045061 },
    { lat: 48.68469, lng: 21.8046646 },
    { lat: 48.6844038, lng: 21.8050812 },
    { lat: 48.6842111, lng: 21.8052369 },
    { lat: 48.6836693, lng: 21.8052112 },
    { lat: 48.6835164, lng: 21.8053043 },
    { lat: 48.6832198, lng: 21.8053584 },
    { lat: 48.6828761, lng: 21.8055911 },
    { lat: 48.6822732, lng: 21.8055615 },
    { lat: 48.6818627, lng: 21.8058669 },
    { lat: 48.6814402, lng: 21.8059722 },
    { lat: 48.6814005, lng: 21.8059683 },
    { lat: 48.6813521, lng: 21.8059561 },
    { lat: 48.6808521, lng: 21.8052921 },
    { lat: 48.6806396, lng: 21.8052531 },
    { lat: 48.6802708, lng: 21.8054738 },
    { lat: 48.6800369, lng: 21.8046269 },
    { lat: 48.6799006, lng: 21.8043549 },
    { lat: 48.6797477, lng: 21.804107 },
    { lat: 48.6796329, lng: 21.8040177 },
    { lat: 48.6793212, lng: 21.8039267 },
    { lat: 48.6792991, lng: 21.8037749 },
    { lat: 48.6794236, lng: 21.8033264 },
    { lat: 48.6788828, lng: 21.8001222 },
    { lat: 48.678922, lng: 21.8000715 },
    { lat: 48.6757953, lng: 21.7816706 },
    { lat: 48.6757364, lng: 21.7813142 },
    { lat: 48.6732254, lng: 21.7810178 },
    { lat: 48.6732294, lng: 21.7817686 },
    { lat: 48.6731447, lng: 21.7816694 },
    { lat: 48.6681273, lng: 21.7829634 },
    { lat: 48.6674911, lng: 21.7833662 },
    { lat: 48.6648887, lng: 21.7842119 },
    { lat: 48.6642867, lng: 21.7842527 },
    { lat: 48.6638273, lng: 21.7841931 },
    { lat: 48.6636387, lng: 21.7839169 },
    { lat: 48.6631373, lng: 21.7819686 },
    { lat: 48.6629891, lng: 21.781597 },
    { lat: 48.6622352, lng: 21.7819285 },
    { lat: 48.6590534, lng: 21.7829461 },
    { lat: 48.6551366, lng: 21.7836533 },
    { lat: 48.6548541, lng: 21.783867 },
    { lat: 48.6547617, lng: 21.7838128 },
    { lat: 48.6541611, lng: 21.7839528 },
    { lat: 48.6535578, lng: 21.7841566 },
    { lat: 48.6502595, lng: 21.7849756 },
    { lat: 48.642802, lng: 21.786737 },
    { lat: 48.64237, lng: 21.789796 },
    { lat: 48.64238, lng: 21.790111 },
    { lat: 48.642397, lng: 21.790366 },
    { lat: 48.642392, lng: 21.790494 },
    { lat: 48.642364, lng: 21.791046 },
    { lat: 48.642361, lng: 21.791355 },
    { lat: 48.643001, lng: 21.791305 },
    { lat: 48.643302, lng: 21.791303 },
    { lat: 48.643883, lng: 21.791357 },
    { lat: 48.644385, lng: 21.791333 },
    { lat: 48.644909, lng: 21.791392 },
    { lat: 48.646339, lng: 21.791432 },
    { lat: 48.647308, lng: 21.7914 },
    { lat: 48.647821, lng: 21.791382 },
    { lat: 48.648553, lng: 21.791545 },
    { lat: 48.649243, lng: 21.791912 },
    { lat: 48.649716, lng: 21.791913 },
    { lat: 48.650525, lng: 21.791634 },
    { lat: 48.65058, lng: 21.791951 },
    { lat: 48.650607, lng: 21.79208 },
    { lat: 48.650889, lng: 21.792623 },
    { lat: 48.65092, lng: 21.792682 },
    { lat: 48.650988, lng: 21.792813 },
    { lat: 48.650953, lng: 21.793017 },
    { lat: 48.650936, lng: 21.793117 },
    { lat: 48.650938, lng: 21.7932 },
    { lat: 48.650956, lng: 21.793493 },
    { lat: 48.650933, lng: 21.79382 },
    { lat: 48.65093, lng: 21.793971 },
    { lat: 48.651026, lng: 21.79422 },
    { lat: 48.651073, lng: 21.794281 },
    { lat: 48.651114, lng: 21.794407 },
    { lat: 48.6512246, lng: 21.7941161 },
    { lat: 48.6514896, lng: 21.7940569 },
    { lat: 48.6518368, lng: 21.7940347 },
    { lat: 48.6525152, lng: 21.7942104 },
    { lat: 48.6535374, lng: 21.7939766 },
    { lat: 48.6542247, lng: 21.7942301 },
    { lat: 48.6544059, lng: 21.7944078 },
    { lat: 48.6541617, lng: 21.7948737 },
    { lat: 48.6541622, lng: 21.7951085 },
    { lat: 48.6542875, lng: 21.7955536 },
  ],
  Slavkovce: [
    { lat: 48.6095218, lng: 21.8785567 },
    { lat: 48.608501, lng: 21.8776526 },
    { lat: 48.6070216, lng: 21.8757686 },
    { lat: 48.6065248, lng: 21.8755639 },
    { lat: 48.6063502, lng: 21.8753811 },
    { lat: 48.6058967, lng: 21.8776601 },
    { lat: 48.6056315, lng: 21.8788572 },
    { lat: 48.6055125, lng: 21.8787781 },
    { lat: 48.605423, lng: 21.8789304 },
    { lat: 48.605009, lng: 21.8801638 },
    { lat: 48.6047501, lng: 21.8805109 },
    { lat: 48.6042189, lng: 21.8809896 },
    { lat: 48.6035579, lng: 21.8818005 },
    { lat: 48.6033352, lng: 21.8821568 },
    { lat: 48.6029189, lng: 21.8830806 },
    { lat: 48.6026353, lng: 21.8840405 },
    { lat: 48.6015318, lng: 21.8843533 },
    { lat: 48.6007367, lng: 21.8846781 },
    { lat: 48.6002013, lng: 21.8839777 },
    { lat: 48.5999816, lng: 21.8841813 },
    { lat: 48.5998319, lng: 21.8839586 },
    { lat: 48.5982363, lng: 21.8858422 },
    { lat: 48.5981759, lng: 21.8856811 },
    { lat: 48.5973826, lng: 21.8861981 },
    { lat: 48.596099, lng: 21.8871692 },
    { lat: 48.5963256, lng: 21.8880318 },
    { lat: 48.5958332, lng: 21.8884854 },
    { lat: 48.5960047, lng: 21.8891646 },
    { lat: 48.595917, lng: 21.8892127 },
    { lat: 48.5962242, lng: 21.8903244 },
    { lat: 48.5960723, lng: 21.8904494 },
    { lat: 48.5962138, lng: 21.8911002 },
    { lat: 48.5968584, lng: 21.8954735 },
    { lat: 48.5971703, lng: 21.8969941 },
    { lat: 48.597006, lng: 21.8976515 },
    { lat: 48.5965157, lng: 21.8989906 },
    { lat: 48.596381, lng: 21.8990428 },
    { lat: 48.5964564, lng: 21.8994862 },
    { lat: 48.596245, lng: 21.8995805 },
    { lat: 48.597224, lng: 21.9040644 },
    { lat: 48.5968077, lng: 21.9042434 },
    { lat: 48.5971108, lng: 21.9053284 },
    { lat: 48.596985, lng: 21.9054238 },
    { lat: 48.5975319, lng: 21.9092027 },
    { lat: 48.59707, lng: 21.9093569 },
    { lat: 48.5973971, lng: 21.9108521 },
    { lat: 48.5965893, lng: 21.9145923 },
    { lat: 48.5968343, lng: 21.9177424 },
    { lat: 48.5971459, lng: 21.9204587 },
    { lat: 48.5973609, lng: 21.9207385 },
    { lat: 48.5976778, lng: 21.9228166 },
    { lat: 48.596707, lng: 21.9232187 },
    { lat: 48.5972316, lng: 21.92489 },
    { lat: 48.5976785, lng: 21.9257935 },
    { lat: 48.5974451, lng: 21.9260475 },
    { lat: 48.5970817, lng: 21.9262662 },
    { lat: 48.5974246, lng: 21.9291581 },
    { lat: 48.5977632, lng: 21.9292073 },
    { lat: 48.5979057, lng: 21.9293247 },
    { lat: 48.5980024, lng: 21.9295022 },
    { lat: 48.5989949, lng: 21.9296477 },
    { lat: 48.5988567, lng: 21.9304578 },
    { lat: 48.5988221, lng: 21.9326648 },
    { lat: 48.5994948, lng: 21.933066 },
    { lat: 48.5995511, lng: 21.9341437 },
    { lat: 48.5995334, lng: 21.9349604 },
    { lat: 48.5995509, lng: 21.9354324 },
    { lat: 48.5996096, lng: 21.9357515 },
    { lat: 48.5996194, lng: 21.9364166 },
    { lat: 48.5995808, lng: 21.9370273 },
    { lat: 48.5994535, lng: 21.9378278 },
    { lat: 48.599073, lng: 21.9390782 },
    { lat: 48.5987766, lng: 21.9393311 },
    { lat: 48.5985602, lng: 21.9396675 },
    { lat: 48.598507, lng: 21.9403306 },
    { lat: 48.5983422, lng: 21.9413383 },
    { lat: 48.5983467, lng: 21.9417912 },
    { lat: 48.5983512, lng: 21.9417917 },
    { lat: 48.5985996, lng: 21.9418234 },
    { lat: 48.5991506, lng: 21.9422633 },
    { lat: 48.5990736, lng: 21.9430574 },
    { lat: 48.5992135, lng: 21.943251 },
    { lat: 48.5993783, lng: 21.9433148 },
    { lat: 48.5997604, lng: 21.9433535 },
    { lat: 48.600214, lng: 21.9434941 },
    { lat: 48.6005703, lng: 21.9433846 },
    { lat: 48.601026, lng: 21.9435272 },
    { lat: 48.6020678, lng: 21.9434356 },
    { lat: 48.6030935, lng: 21.9437 },
    { lat: 48.6036729, lng: 21.9435661 },
    { lat: 48.6043984, lng: 21.943608 },
    { lat: 48.6050412, lng: 21.9432224 },
    { lat: 48.6051884, lng: 21.9430754 },
    { lat: 48.6051757, lng: 21.9428394 },
    { lat: 48.6058106, lng: 21.9426348 },
    { lat: 48.6060383, lng: 21.9420687 },
    { lat: 48.6058606, lng: 21.9418442 },
    { lat: 48.6057957, lng: 21.9415888 },
    { lat: 48.6069858, lng: 21.9409981 },
    { lat: 48.6069397, lng: 21.9405507 },
    { lat: 48.606818, lng: 21.940135 },
    { lat: 48.6068516, lng: 21.940046 },
    { lat: 48.607074, lng: 21.9398503 },
    { lat: 48.6072402, lng: 21.939795 },
    { lat: 48.6077319, lng: 21.9398388 },
    { lat: 48.6081605, lng: 21.9400205 },
    { lat: 48.6088236, lng: 21.9401328 },
    { lat: 48.6091239, lng: 21.9396305 },
    { lat: 48.6093775, lng: 21.9390654 },
    { lat: 48.6095774, lng: 21.9390067 },
    { lat: 48.6101215, lng: 21.9391684 },
    { lat: 48.6102354, lng: 21.9394523 },
    { lat: 48.6099618, lng: 21.9398401 },
    { lat: 48.6105094, lng: 21.9396987 },
    { lat: 48.6107348, lng: 21.9394939 },
    { lat: 48.611186, lng: 21.9393619 },
    { lat: 48.6114475, lng: 21.9394299 },
    { lat: 48.6116093, lng: 21.9397825 },
    { lat: 48.6118078, lng: 21.9400176 },
    { lat: 48.612228, lng: 21.9403133 },
    { lat: 48.6127421, lng: 21.9404536 },
    { lat: 48.6131202, lng: 21.9403226 },
    { lat: 48.613474, lng: 21.9399817 },
    { lat: 48.6140052, lng: 21.9396336 },
    { lat: 48.6143989, lng: 21.9395881 },
    { lat: 48.6152188, lng: 21.9379618 },
    { lat: 48.6153163, lng: 21.9377671 },
    { lat: 48.6161739, lng: 21.9377299 },
    { lat: 48.6161665, lng: 21.937078 },
    { lat: 48.6161128, lng: 21.9366832 },
    { lat: 48.6165273, lng: 21.9362567 },
    { lat: 48.6165552, lng: 21.935938 },
    { lat: 48.6166964, lng: 21.9358713 },
    { lat: 48.6167156, lng: 21.9355121 },
    { lat: 48.6169192, lng: 21.9351489 },
    { lat: 48.6170288, lng: 21.93437 },
    { lat: 48.617198, lng: 21.933708 },
    { lat: 48.6172911, lng: 21.9324045 },
    { lat: 48.6172077, lng: 21.9314966 },
    { lat: 48.6172217, lng: 21.9308057 },
    { lat: 48.6169921, lng: 21.9287414 },
    { lat: 48.6173981, lng: 21.9286012 },
    { lat: 48.6173984, lng: 21.928295 },
    { lat: 48.6180394, lng: 21.9282872 },
    { lat: 48.6179849, lng: 21.9261211 },
    { lat: 48.618024, lng: 21.9249949 },
    { lat: 48.6180687, lng: 21.924175 },
    { lat: 48.6182252, lng: 21.9239616 },
    { lat: 48.6179384, lng: 21.9227101 },
    { lat: 48.6177783, lng: 21.9202841 },
    { lat: 48.6191577, lng: 21.9192229 },
    { lat: 48.6189859, lng: 21.9186077 },
    { lat: 48.6190661, lng: 21.9185373 },
    { lat: 48.618916, lng: 21.9179781 },
    { lat: 48.6190687, lng: 21.9178335 },
    { lat: 48.6188198, lng: 21.9169767 },
    { lat: 48.6191829, lng: 21.916607 },
    { lat: 48.6189808, lng: 21.9157333 },
    { lat: 48.6190897, lng: 21.9156406 },
    { lat: 48.6188114, lng: 21.9150873 },
    { lat: 48.6205321, lng: 21.9137714 },
    { lat: 48.6198484, lng: 21.9107094 },
    { lat: 48.6195131, lng: 21.9084452 },
    { lat: 48.6199694, lng: 21.9081587 },
    { lat: 48.6194459, lng: 21.905883 },
    { lat: 48.6190372, lng: 21.905643 },
    { lat: 48.618778, lng: 21.905584 },
    { lat: 48.617899, lng: 21.9056976 },
    { lat: 48.6161783, lng: 21.9054099 },
    { lat: 48.6153328, lng: 21.9055621 },
    { lat: 48.6146904, lng: 21.9057837 },
    { lat: 48.6143433, lng: 21.905809 },
    { lat: 48.6135211, lng: 21.9028013 },
    { lat: 48.6143836, lng: 21.9024245 },
    { lat: 48.6143006, lng: 21.9021285 },
    { lat: 48.6141588, lng: 21.9021687 },
    { lat: 48.61364, lng: 21.9000807 },
    { lat: 48.6138174, lng: 21.9000673 },
    { lat: 48.6134951, lng: 21.8987118 },
    { lat: 48.6136978, lng: 21.8986731 },
    { lat: 48.6135581, lng: 21.8980461 },
    { lat: 48.6137491, lng: 21.8979921 },
    { lat: 48.6132798, lng: 21.8960837 },
    { lat: 48.613445, lng: 21.8959885 },
    { lat: 48.613322, lng: 21.8945639 },
    { lat: 48.6142411, lng: 21.894024 },
    { lat: 48.6139438, lng: 21.8929582 },
    { lat: 48.6141493, lng: 21.8928073 },
    { lat: 48.6136711, lng: 21.8908202 },
    { lat: 48.6137297, lng: 21.890777 },
    { lat: 48.6133623, lng: 21.8894216 },
    { lat: 48.6130173, lng: 21.8886065 },
    { lat: 48.6123313, lng: 21.886357 },
    { lat: 48.6120371, lng: 21.8856769 },
    { lat: 48.6104283, lng: 21.8837797 },
    { lat: 48.6099015, lng: 21.8830638 },
    { lat: 48.6096196, lng: 21.8823809 },
    { lat: 48.6093808, lng: 21.8809133 },
    { lat: 48.609461, lng: 21.8790832 },
    { lat: 48.6095218, lng: 21.8785567 },
  ],
  Závadka: [
    { lat: 48.7732275, lng: 22.0519568 },
    { lat: 48.7714151, lng: 22.0525085 },
    { lat: 48.7698956, lng: 22.0525651 },
    { lat: 48.7664013, lng: 22.0518955 },
    { lat: 48.7643358, lng: 22.0511799 },
    { lat: 48.757686, lng: 22.0496081 },
    { lat: 48.7573232, lng: 22.0495788 },
    { lat: 48.7551254, lng: 22.0504336 },
    { lat: 48.7537709, lng: 22.0510377 },
    { lat: 48.7531711, lng: 22.0517782 },
    { lat: 48.7505834, lng: 22.0564459 },
    { lat: 48.7501445, lng: 22.0570471 },
    { lat: 48.74964, lng: 22.057395 },
    { lat: 48.750556, lng: 22.059221 },
    { lat: 48.751161, lng: 22.06073 },
    { lat: 48.751305, lng: 22.061184 },
    { lat: 48.751333, lng: 22.06144 },
    { lat: 48.751442, lng: 22.062535 },
    { lat: 48.751829, lng: 22.063451 },
    { lat: 48.752462, lng: 22.064849 },
    { lat: 48.752893, lng: 22.066351 },
    { lat: 48.752694, lng: 22.067473 },
    { lat: 48.75276, lng: 22.067821 },
    { lat: 48.752946, lng: 22.069209 },
    { lat: 48.753187, lng: 22.0705 },
    { lat: 48.753587, lng: 22.072734 },
    { lat: 48.753599, lng: 22.0728 },
    { lat: 48.754179, lng: 22.073127 },
    { lat: 48.754575, lng: 22.076019 },
    { lat: 48.75517, lng: 22.078204 },
    { lat: 48.755198, lng: 22.078268 },
    { lat: 48.755217, lng: 22.078335 },
    { lat: 48.755227, lng: 22.078405 },
    { lat: 48.753947, lng: 22.080224 },
    { lat: 48.754381, lng: 22.082338 },
    { lat: 48.754753, lng: 22.084464 },
    { lat: 48.75499, lng: 22.08578 },
    { lat: 48.755256, lng: 22.085661 },
    { lat: 48.755836, lng: 22.088846 },
    { lat: 48.756342, lng: 22.092516 },
    { lat: 48.757866, lng: 22.0919 },
    { lat: 48.757871, lng: 22.091966 },
    { lat: 48.757875, lng: 22.092042 },
    { lat: 48.757972, lng: 22.093783 },
    { lat: 48.757975, lng: 22.096143 },
    { lat: 48.75873, lng: 22.095838 },
    { lat: 48.759316, lng: 22.097418 },
    { lat: 48.759297, lng: 22.098998 },
    { lat: 48.760437, lng: 22.09889 },
    { lat: 48.761699, lng: 22.098728 },
    { lat: 48.762382, lng: 22.098577 },
    { lat: 48.763275, lng: 22.09688 },
    { lat: 48.763388, lng: 22.096192 },
    { lat: 48.763459, lng: 22.096179 },
    { lat: 48.763527, lng: 22.095803 },
    { lat: 48.763639, lng: 22.095477 },
    { lat: 48.76321, lng: 22.095456 },
    { lat: 48.762919, lng: 22.095476 },
    { lat: 48.762839, lng: 22.094573 },
    { lat: 48.762784, lng: 22.093821 },
    { lat: 48.762605, lng: 22.092582 },
    { lat: 48.762926, lng: 22.092483 },
    { lat: 48.762694, lng: 22.090814 },
    { lat: 48.762579, lng: 22.089797 },
    { lat: 48.762492, lng: 22.088827 },
    { lat: 48.7624, lng: 22.087721 },
    { lat: 48.762349, lng: 22.087182 },
    { lat: 48.762255, lng: 22.086691 },
    { lat: 48.762371, lng: 22.086621 },
    { lat: 48.762303, lng: 22.086342 },
    { lat: 48.762245, lng: 22.085985 },
    { lat: 48.762088, lng: 22.084225 },
    { lat: 48.761973, lng: 22.083163 },
    { lat: 48.761802, lng: 22.081969 },
    { lat: 48.761605, lng: 22.080935 },
    { lat: 48.761453, lng: 22.07984 },
    { lat: 48.761358, lng: 22.079294 },
    { lat: 48.761856, lng: 22.079313 },
    { lat: 48.761988, lng: 22.079265 },
    { lat: 48.761941, lng: 22.079122 },
    { lat: 48.762123, lng: 22.078995 },
    { lat: 48.762751, lng: 22.078724 },
    { lat: 48.763413, lng: 22.078329 },
    { lat: 48.764086, lng: 22.078025 },
    { lat: 48.764336, lng: 22.077824 },
    { lat: 48.764622, lng: 22.077709 },
    { lat: 48.764889, lng: 22.077737 },
    { lat: 48.765107, lng: 22.077714 },
    { lat: 48.765872, lng: 22.077546 },
    { lat: 48.76611, lng: 22.077431 },
    { lat: 48.766094, lng: 22.077413 },
    { lat: 48.766284, lng: 22.077363 },
    { lat: 48.766979, lng: 22.077176 },
    { lat: 48.7676215, lng: 22.0773564 },
    { lat: 48.7692224, lng: 22.0772243 },
    { lat: 48.7704296, lng: 22.0765235 },
    { lat: 48.7699426, lng: 22.0736424 },
    { lat: 48.7700967, lng: 22.0735509 },
    { lat: 48.7694958, lng: 22.0698562 },
    { lat: 48.7697797, lng: 22.0697815 },
    { lat: 48.7700445, lng: 22.0697977 },
    { lat: 48.7700312, lng: 22.0697136 },
    { lat: 48.7702903, lng: 22.0694887 },
    { lat: 48.7701569, lng: 22.0689946 },
    { lat: 48.7698468, lng: 22.0657084 },
    { lat: 48.7695649, lng: 22.0644554 },
    { lat: 48.770045, lng: 22.0643967 },
    { lat: 48.7697381, lng: 22.0619094 },
    { lat: 48.7695843, lng: 22.0613127 },
    { lat: 48.7701645, lng: 22.0607864 },
    { lat: 48.7711761, lng: 22.0602817 },
    { lat: 48.7706945, lng: 22.0575002 },
    { lat: 48.7705717, lng: 22.0571182 },
    { lat: 48.7706191, lng: 22.057081 },
    { lat: 48.7706351, lng: 22.056005 },
    { lat: 48.7712044, lng: 22.0562063 },
    { lat: 48.7713375, lng: 22.0539188 },
    { lat: 48.7717705, lng: 22.0535862 },
    { lat: 48.7718248, lng: 22.0539327 },
    { lat: 48.771954, lng: 22.0540619 },
    { lat: 48.772171, lng: 22.0541587 },
    { lat: 48.7724448, lng: 22.0544985 },
    { lat: 48.7739973, lng: 22.054745 },
    { lat: 48.7734936, lng: 22.0529193 },
    { lat: 48.7732275, lng: 22.0519568 },
  ],
  Lúčky: [
    { lat: 48.7927733, lng: 22.0412501 },
    { lat: 48.7905297, lng: 22.0275193 },
    { lat: 48.7895576, lng: 22.0217583 },
    { lat: 48.7887174, lng: 22.0167999 },
    { lat: 48.7750786, lng: 22.0224665 },
    { lat: 48.7748126, lng: 22.0223054 },
    { lat: 48.7732535, lng: 22.0233231 },
    { lat: 48.7710036, lng: 22.0245324 },
    { lat: 48.7710386, lng: 22.0246734 },
    { lat: 48.7703044, lng: 22.0250647 },
    { lat: 48.7700732, lng: 22.0251738 },
    { lat: 48.7691949, lng: 22.0251825 },
    { lat: 48.7684686, lng: 22.0254321 },
    { lat: 48.7665355, lng: 22.0263868 },
    { lat: 48.7664059, lng: 22.026516 },
    { lat: 48.7663685, lng: 22.0265487 },
    { lat: 48.7648395, lng: 22.0274197 },
    { lat: 48.7630878, lng: 22.0281578 },
    { lat: 48.7620784, lng: 22.0288843 },
    { lat: 48.7617807, lng: 22.0290345 },
    { lat: 48.759539, lng: 22.0293743 },
    { lat: 48.7585738, lng: 22.0300691 },
    { lat: 48.7563684, lng: 22.0312478 },
    { lat: 48.7551128, lng: 22.0323104 },
    { lat: 48.7554482, lng: 22.0334312 },
    { lat: 48.7534292, lng: 22.0344622 },
    { lat: 48.7532361, lng: 22.0356442 },
    { lat: 48.7520089, lng: 22.0372313 },
    { lat: 48.751616, lng: 22.0388079 },
    { lat: 48.7510588, lng: 22.0398663 },
    { lat: 48.7514832, lng: 22.0424901 },
    { lat: 48.7516295, lng: 22.0441221 },
    { lat: 48.751762, lng: 22.0449575 },
    { lat: 48.7523119, lng: 22.0479416 },
    { lat: 48.7531479, lng: 22.0516975 },
    { lat: 48.7531711, lng: 22.0517782 },
    { lat: 48.7537709, lng: 22.0510377 },
    { lat: 48.7551254, lng: 22.0504336 },
    { lat: 48.7573232, lng: 22.0495788 },
    { lat: 48.757686, lng: 22.0496081 },
    { lat: 48.7643358, lng: 22.0511799 },
    { lat: 48.7664013, lng: 22.0518955 },
    { lat: 48.7698956, lng: 22.0525651 },
    { lat: 48.7714151, lng: 22.0525085 },
    { lat: 48.7732275, lng: 22.0519568 },
    { lat: 48.7759373, lng: 22.0514467 },
    { lat: 48.7760142, lng: 22.0518308 },
    { lat: 48.7761048, lng: 22.0518141 },
    { lat: 48.776074, lng: 22.0514156 },
    { lat: 48.7805048, lng: 22.0503886 },
    { lat: 48.7808611, lng: 22.0502378 },
    { lat: 48.781508, lng: 22.0498188 },
    { lat: 48.7815171, lng: 22.0495865 },
    { lat: 48.7825008, lng: 22.0489542 },
    { lat: 48.7822242, lng: 22.0480278 },
    { lat: 48.782446, lng: 22.0477024 },
    { lat: 48.7830411, lng: 22.0471903 },
    { lat: 48.7833004, lng: 22.0467961 },
    { lat: 48.7835071, lng: 22.0466007 },
    { lat: 48.7835955, lng: 22.046385 },
    { lat: 48.7835842, lng: 22.0461991 },
    { lat: 48.7837146, lng: 22.0459658 },
    { lat: 48.7839991, lng: 22.0457186 },
    { lat: 48.7840436, lng: 22.045373 },
    { lat: 48.7840304, lng: 22.0446889 },
    { lat: 48.7898566, lng: 22.042514 },
    { lat: 48.7927733, lng: 22.0412501 },
  ],
  Klokočov: [
    { lat: 48.7927733, lng: 22.0412501 },
    { lat: 48.7928113, lng: 22.0446901 },
    { lat: 48.7962528, lng: 22.0465463 },
    { lat: 48.7963175, lng: 22.0467961 },
    { lat: 48.7963878, lng: 22.0483203 },
    { lat: 48.796268, lng: 22.0496142 },
    { lat: 48.7999992, lng: 22.0505494 },
    { lat: 48.8008613, lng: 22.0507089 },
    { lat: 48.8060672, lng: 22.0512767 },
    { lat: 48.8062267, lng: 22.0514995 },
    { lat: 48.8073769, lng: 22.0520401 },
    { lat: 48.807889, lng: 22.0525651 },
    { lat: 48.8086307, lng: 22.0529155 },
    { lat: 48.8088047, lng: 22.0525244 },
    { lat: 48.8090956, lng: 22.0522711 },
    { lat: 48.8099318, lng: 22.0523833 },
    { lat: 48.8105386, lng: 22.0519848 },
    { lat: 48.8115092, lng: 22.0511727 },
    { lat: 48.8128365, lng: 22.0501936 },
    { lat: 48.8135996, lng: 22.0501666 },
    { lat: 48.8188174, lng: 22.0477155 },
    { lat: 48.8212779, lng: 22.0466969 },
    { lat: 48.8250485, lng: 22.0450163 },
    { lat: 48.8269745, lng: 22.0443197 },
    { lat: 48.8279681, lng: 22.0443452 },
    { lat: 48.8306218, lng: 22.042808 },
    { lat: 48.8378435, lng: 22.0388377 },
    { lat: 48.8383363, lng: 22.0386576 },
    { lat: 48.8385438, lng: 22.0384469 },
    { lat: 48.840102, lng: 22.037592 },
    { lat: 48.840096, lng: 22.037559 },
    { lat: 48.840068, lng: 22.037412 },
    { lat: 48.839813, lng: 22.036105 },
    { lat: 48.839764, lng: 22.035855 },
    { lat: 48.839548, lng: 22.034377 },
    { lat: 48.839233, lng: 22.032468 },
    { lat: 48.838982, lng: 22.03143 },
    { lat: 48.838851, lng: 22.030882 },
    { lat: 48.838845, lng: 22.030837 },
    { lat: 48.838836, lng: 22.030736 },
    { lat: 48.838821, lng: 22.030594 },
    { lat: 48.838797, lng: 22.030481 },
    { lat: 48.838715, lng: 22.029866 },
    { lat: 48.838679, lng: 22.028704 },
    { lat: 48.838676, lng: 22.027752 },
    { lat: 48.838687, lng: 22.026665 },
    { lat: 48.838692, lng: 22.026249 },
    { lat: 48.838686, lng: 22.026216 },
    { lat: 48.838685, lng: 22.026205 },
    { lat: 48.838681, lng: 22.026139 },
    { lat: 48.838654, lng: 22.025521 },
    { lat: 48.838712, lng: 22.024619 },
    { lat: 48.838685, lng: 22.023761 },
    { lat: 48.838675, lng: 22.023412 },
    { lat: 48.838669, lng: 22.023104 },
    { lat: 48.838674, lng: 22.023004 },
    { lat: 48.838693, lng: 22.021115 },
    { lat: 48.838692, lng: 22.020634 },
    { lat: 48.838691, lng: 22.019916 },
    { lat: 48.838703, lng: 22.019625 },
    { lat: 48.838644, lng: 22.018672 },
    { lat: 48.838672, lng: 22.017414 },
    { lat: 48.838777, lng: 22.015956 },
    { lat: 48.838759, lng: 22.014891 },
    { lat: 48.8360872, lng: 22.0148654 },
    { lat: 48.8350617, lng: 22.015015 },
    { lat: 48.8345721, lng: 22.015374 },
    { lat: 48.8328616, lng: 22.0152001 },
    { lat: 48.8293106, lng: 22.0149823 },
    { lat: 48.8255949, lng: 22.0148784 },
    { lat: 48.8232528, lng: 22.014694 },
    { lat: 48.8218108, lng: 22.0146501 },
    { lat: 48.8191456, lng: 22.0149503 },
    { lat: 48.8183151, lng: 22.0149408 },
    { lat: 48.8131126, lng: 22.014521 },
    { lat: 48.8094728, lng: 22.0145353 },
    { lat: 48.8041111, lng: 22.0173004 },
    { lat: 48.8004072, lng: 22.0188428 },
    { lat: 48.7960967, lng: 22.0200015 },
    { lat: 48.7895576, lng: 22.0217583 },
    { lat: 48.7905297, lng: 22.0275193 },
    { lat: 48.7927733, lng: 22.0412501 },
  ],
  Stretava: [
    { lat: 48.6415993, lng: 21.9639438 },
    { lat: 48.6413467, lng: 21.9640509 },
    { lat: 48.6400798, lng: 21.9646369 },
    { lat: 48.6396824, lng: 21.9649353 },
    { lat: 48.6394942, lng: 21.9651985 },
    { lat: 48.6389858, lng: 21.9666546 },
    { lat: 48.6387914, lng: 21.9668011 },
    { lat: 48.6385362, lng: 21.9668884 },
    { lat: 48.6378048, lng: 21.9664389 },
    { lat: 48.6376824, lng: 21.966444 },
    { lat: 48.6373023, lng: 21.9667621 },
    { lat: 48.6371156, lng: 21.9677618 },
    { lat: 48.636841, lng: 21.9682204 },
    { lat: 48.6366081, lng: 21.9682112 },
    { lat: 48.6360908, lng: 21.9677702 },
    { lat: 48.6356553, lng: 21.9676212 },
    { lat: 48.6353126, lng: 21.9677273 },
    { lat: 48.6346973, lng: 21.9686416 },
    { lat: 48.6340303, lng: 21.9690558 },
    { lat: 48.6334073, lng: 21.9692951 },
    { lat: 48.6328862, lng: 21.9696425 },
    { lat: 48.632669, lng: 21.9700964 },
    { lat: 48.6325458, lng: 21.9709836 },
    { lat: 48.6323018, lng: 21.9713053 },
    { lat: 48.631635, lng: 21.9716352 },
    { lat: 48.6315213, lng: 21.9718109 },
    { lat: 48.6313954, lng: 21.9727256 },
    { lat: 48.6311254, lng: 21.9734948 },
    { lat: 48.6306881, lng: 21.9739589 },
    { lat: 48.6301188, lng: 21.9739615 },
    { lat: 48.6294353, lng: 21.9742528 },
    { lat: 48.629351, lng: 21.974579 },
    { lat: 48.6294042, lng: 21.9750889 },
    { lat: 48.6298354, lng: 21.9758246 },
    { lat: 48.6298894, lng: 21.9761018 },
    { lat: 48.6298099, lng: 21.9763875 },
    { lat: 48.6295969, lng: 21.9766972 },
    { lat: 48.6289995, lng: 21.9768293 },
    { lat: 48.6285809, lng: 21.976734 },
    { lat: 48.6287229, lng: 21.9781528 },
    { lat: 48.6290464, lng: 21.9801883 },
    { lat: 48.6296012, lng: 21.9825636 },
    { lat: 48.6300853, lng: 21.9908496 },
    { lat: 48.6302255, lng: 21.9935331 },
    { lat: 48.6300791, lng: 21.9936177 },
    { lat: 48.6294032, lng: 21.9931246 },
    { lat: 48.6297744, lng: 21.998483 },
    { lat: 48.6285458, lng: 21.9976458 },
    { lat: 48.6265434, lng: 21.9965959 },
    { lat: 48.6252974, lng: 21.999002 },
    { lat: 48.6247853, lng: 21.9984873 },
    { lat: 48.6240016, lng: 22.0003768 },
    { lat: 48.6239135, lng: 22.000321 },
    { lat: 48.6211053, lng: 22.0060894 },
    { lat: 48.6212732, lng: 22.0061497 },
    { lat: 48.6212869, lng: 22.0068064 },
    { lat: 48.6215177, lng: 22.0075904 },
    { lat: 48.6217211, lng: 22.0073074 },
    { lat: 48.6220613, lng: 22.0070961 },
    { lat: 48.6223797, lng: 22.0071449 },
    { lat: 48.6227701, lng: 22.0073742 },
    { lat: 48.6234124, lng: 22.0079703 },
    { lat: 48.6236421, lng: 22.0083498 },
    { lat: 48.6237391, lng: 22.0087309 },
    { lat: 48.6239034, lng: 22.0102095 },
    { lat: 48.6241371, lng: 22.0107057 },
    { lat: 48.6245025, lng: 22.0109279 },
    { lat: 48.6259838, lng: 22.0104057 },
    { lat: 48.6267016, lng: 22.0103189 },
    { lat: 48.6268025, lng: 22.0103961 },
    { lat: 48.6274724, lng: 22.0105096 },
    { lat: 48.6281763, lng: 22.0104993 },
    { lat: 48.62848, lng: 22.0102623 },
    { lat: 48.6286245, lng: 22.009781 },
    { lat: 48.6282645, lng: 22.0079845 },
    { lat: 48.628552, lng: 22.0076435 },
    { lat: 48.6288621, lng: 22.0076287 },
    { lat: 48.6294043, lng: 22.0078134 },
    { lat: 48.6305923, lng: 22.0086879 },
    { lat: 48.6315638, lng: 22.0088995 },
    { lat: 48.6318836, lng: 22.0087715 },
    { lat: 48.6322393, lng: 22.0082314 },
    { lat: 48.6330655, lng: 22.0071864 },
    { lat: 48.6337702, lng: 22.0059675 },
    { lat: 48.6341241, lng: 22.0054894 },
    { lat: 48.6342622, lng: 22.0053412 },
    { lat: 48.6349728, lng: 22.0048667 },
    { lat: 48.6350046, lng: 22.0046547 },
    { lat: 48.6354214, lng: 22.0049043 },
    { lat: 48.6355583, lng: 22.004892 },
    { lat: 48.6364508, lng: 22.0051816 },
    { lat: 48.6366226, lng: 22.0053047 },
    { lat: 48.6370721, lng: 22.0059807 },
    { lat: 48.6376761, lng: 22.0065315 },
    { lat: 48.6383528, lng: 22.0070106 },
    { lat: 48.6388265, lng: 22.0070646 },
    { lat: 48.6392773, lng: 22.0068642 },
    { lat: 48.6394527, lng: 22.0068633 },
    { lat: 48.6411139, lng: 22.0071753 },
    { lat: 48.641524, lng: 22.0075675 },
    { lat: 48.6416272, lng: 22.0079211 },
    { lat: 48.6423345, lng: 22.0094817 },
    { lat: 48.642591, lng: 22.0099359 },
    { lat: 48.6428609, lng: 22.0102549 },
    { lat: 48.6431631, lng: 22.0104284 },
    { lat: 48.6439957, lng: 22.0105728 },
    { lat: 48.6449087, lng: 22.0105914 },
    { lat: 48.6454303, lng: 22.0106748 },
    { lat: 48.6459452, lng: 22.010893 },
    { lat: 48.6466788, lng: 22.0114993 },
    { lat: 48.6476142, lng: 22.0121681 },
    { lat: 48.6481167, lng: 22.012793 },
    { lat: 48.6482873, lng: 22.0132844 },
    { lat: 48.6483963, lng: 22.0138852 },
    { lat: 48.6483559, lng: 22.0147156 },
    { lat: 48.648259, lng: 22.0149868 },
    { lat: 48.6482836, lng: 22.0150772 },
    { lat: 48.6468986, lng: 22.0168232 },
    { lat: 48.6468089, lng: 22.0171572 },
    { lat: 48.6469157, lng: 22.0177087 },
    { lat: 48.6470444, lng: 22.0181456 },
    { lat: 48.647339, lng: 22.0184608 },
    { lat: 48.6482197, lng: 22.0185002 },
    { lat: 48.6486685, lng: 22.0183163 },
    { lat: 48.6490822, lng: 22.018024 },
    { lat: 48.6490957, lng: 22.0180763 },
    { lat: 48.6491059, lng: 22.0181163 },
    { lat: 48.6491855, lng: 22.0180667 },
    { lat: 48.6494929, lng: 22.0180286 },
    { lat: 48.6497521, lng: 22.0182384 },
    { lat: 48.6500045, lng: 22.0186204 },
    { lat: 48.6501232, lng: 22.0185515 },
    { lat: 48.650198, lng: 22.0183887 },
    { lat: 48.6505265, lng: 22.0183947 },
    { lat: 48.6505807, lng: 22.0184515 },
    { lat: 48.6507367, lng: 22.0183795 },
    { lat: 48.6512124, lng: 22.0184314 },
    { lat: 48.6513391, lng: 22.0177665 },
    { lat: 48.6520909, lng: 22.0172652 },
    { lat: 48.6526817, lng: 22.017046 },
    { lat: 48.6534185, lng: 22.0164885 },
    { lat: 48.6538961, lng: 22.0160539 },
    { lat: 48.6540344, lng: 22.0156844 },
    { lat: 48.6544905, lng: 22.0149166 },
    { lat: 48.6559407, lng: 22.0138647 },
    { lat: 48.6561185, lng: 22.0134012 },
    { lat: 48.6562707, lng: 22.0132849 },
    { lat: 48.6564956, lng: 22.0124911 },
    { lat: 48.6579395, lng: 22.0106663 },
    { lat: 48.6595243, lng: 22.0094199 },
    { lat: 48.6597853, lng: 22.0092798 },
    { lat: 48.6606451, lng: 22.0089718 },
    { lat: 48.6613247, lng: 22.0089344 },
    { lat: 48.6616121, lng: 22.0090899 },
    { lat: 48.6619836, lng: 22.0090151 },
    { lat: 48.6620861, lng: 22.0088774 },
    { lat: 48.6624155, lng: 22.0081381 },
    { lat: 48.6625618, lng: 22.0079987 },
    { lat: 48.663216, lng: 22.0077886 },
    { lat: 48.6636247, lng: 22.0077193 },
    { lat: 48.6637937, lng: 22.0077743 },
    { lat: 48.6638221, lng: 22.0076446 },
    { lat: 48.6638505, lng: 22.0075149 },
    { lat: 48.6638092, lng: 22.0073008 },
    { lat: 48.6638272, lng: 22.0070222 },
    { lat: 48.6637411, lng: 22.0065865 },
    { lat: 48.6632323, lng: 22.0021958 },
    { lat: 48.6609199, lng: 22.0035976 },
    { lat: 48.6607679, lng: 22.0029062 },
    { lat: 48.65834, lng: 22.0033543 },
    { lat: 48.6579976, lng: 22.0015377 },
    { lat: 48.6572274, lng: 22.0019678 },
    { lat: 48.6566618, lng: 22.0008284 },
    { lat: 48.6564738, lng: 22.0011527 },
    { lat: 48.6558188, lng: 21.9996255 },
    { lat: 48.6542604, lng: 22.0009834 },
    { lat: 48.6516847, lng: 21.9949021 },
    { lat: 48.6514453, lng: 21.9948166 },
    { lat: 48.6510479, lng: 21.9952127 },
    { lat: 48.6508108, lng: 21.9946784 },
    { lat: 48.6507372, lng: 21.9947512 },
    { lat: 48.6507028, lng: 21.9946774 },
    { lat: 48.6504777, lng: 21.9948974 },
    { lat: 48.6490719, lng: 21.9917048 },
    { lat: 48.6486878, lng: 21.9920585 },
    { lat: 48.6489189, lng: 21.9929912 },
    { lat: 48.6470426, lng: 21.9946942 },
    { lat: 48.6459131, lng: 21.9915707 },
    { lat: 48.6454611, lng: 21.9904814 },
    { lat: 48.6462127, lng: 21.9899399 },
    { lat: 48.646075, lng: 21.9899216 },
    { lat: 48.6459924, lng: 21.9896282 },
    { lat: 48.6457621, lng: 21.9887766 },
    { lat: 48.6452616, lng: 21.9865312 },
    { lat: 48.6453035, lng: 21.9865011 },
    { lat: 48.6451145, lng: 21.9854972 },
    { lat: 48.644756, lng: 21.9830912 },
    { lat: 48.6446407, lng: 21.982018 },
    { lat: 48.6442931, lng: 21.9798328 },
    { lat: 48.643142, lng: 21.9763559 },
    { lat: 48.6433496, lng: 21.975879 },
    { lat: 48.6440335, lng: 21.972963 },
    { lat: 48.6436972, lng: 21.9719635 },
    { lat: 48.6415993, lng: 21.9639438 },
  ],
  Hatalov: [
    { lat: 48.6440118, lng: 21.861933 },
    { lat: 48.6444281, lng: 21.8641243 },
    { lat: 48.6446626, lng: 21.8643617 },
    { lat: 48.6443946, lng: 21.8647488 },
    { lat: 48.644667, lng: 21.8651936 },
    { lat: 48.6448811, lng: 21.8650757 },
    { lat: 48.6464676, lng: 21.8739024 },
    { lat: 48.647617, lng: 21.876397 },
    { lat: 48.6478831, lng: 21.8762459 },
    { lat: 48.6479169, lng: 21.8764229 },
    { lat: 48.6484748, lng: 21.877166 },
    { lat: 48.6488092, lng: 21.8779737 },
    { lat: 48.6490657, lng: 21.8783231 },
    { lat: 48.6492486, lng: 21.8811644 },
    { lat: 48.6494591, lng: 21.8813016 },
    { lat: 48.6494782, lng: 21.8816075 },
    { lat: 48.649441, lng: 21.8818691 },
    { lat: 48.6492627, lng: 21.8820314 },
    { lat: 48.6491209, lng: 21.8824256 },
    { lat: 48.649062, lng: 21.8827589 },
    { lat: 48.6490653, lng: 21.8833333 },
    { lat: 48.6491659, lng: 21.8840948 },
    { lat: 48.6492205, lng: 21.8851839 },
    { lat: 48.6491764, lng: 21.8860041 },
    { lat: 48.6492833, lng: 21.8868959 },
    { lat: 48.649252, lng: 21.8877398 },
    { lat: 48.649153, lng: 21.8881867 },
    { lat: 48.648727, lng: 21.8891678 },
    { lat: 48.6485114, lng: 21.8894478 },
    { lat: 48.6479655, lng: 21.8899711 },
    { lat: 48.6475208, lng: 21.8910119 },
    { lat: 48.6471554, lng: 21.8912459 },
    { lat: 48.6468544, lng: 21.8915337 },
    { lat: 48.6467875, lng: 21.8917766 },
    { lat: 48.6468569, lng: 21.8923361 },
    { lat: 48.6471782, lng: 21.8928448 },
    { lat: 48.6478586, lng: 21.8948329 },
    { lat: 48.6479161, lng: 21.8951017 },
    { lat: 48.6478989, lng: 21.8952678 },
    { lat: 48.6477836, lng: 21.8955545 },
    { lat: 48.6474685, lng: 21.8957726 },
    { lat: 48.6468133, lng: 21.895934 },
    { lat: 48.6464208, lng: 21.8959368 },
    { lat: 48.6455863, lng: 21.896344 },
    { lat: 48.6454717, lng: 21.8964837 },
    { lat: 48.6453125, lng: 21.8968608 },
    { lat: 48.6448853, lng: 21.8980648 },
    { lat: 48.6443878, lng: 21.8987218 },
    { lat: 48.6437028, lng: 21.8993491 },
    { lat: 48.6436618, lng: 21.8994578 },
    { lat: 48.6437783, lng: 21.9002426 },
    { lat: 48.6437992, lng: 21.901066 },
    { lat: 48.6437335, lng: 21.9014497 },
    { lat: 48.6435449, lng: 21.901822 },
    { lat: 48.6434573, lng: 21.9019068 },
    { lat: 48.643157, lng: 21.9019095 },
    { lat: 48.642929, lng: 21.9017953 },
    { lat: 48.6427218, lng: 21.9015661 },
    { lat: 48.6423272, lng: 21.9010923 },
    { lat: 48.641891, lng: 21.9008735 },
    { lat: 48.6414743, lng: 21.9009534 },
    { lat: 48.6412331, lng: 21.9012398 },
    { lat: 48.6410301, lng: 21.9016684 },
    { lat: 48.6409465, lng: 21.9021812 },
    { lat: 48.6410184, lng: 21.9027583 },
    { lat: 48.6416914, lng: 21.9040114 },
    { lat: 48.6418455, lng: 21.9044565 },
    { lat: 48.6420744, lng: 21.9056758 },
    { lat: 48.6421186, lng: 21.9065242 },
    { lat: 48.6420394, lng: 21.9071797 },
    { lat: 48.6417887, lng: 21.9083844 },
    { lat: 48.641839, lng: 21.9105959 },
    { lat: 48.6425064, lng: 21.9130138 },
    { lat: 48.6448588, lng: 21.9121709 },
    { lat: 48.6448682, lng: 21.911249 },
    { lat: 48.6446821, lng: 21.9095365 },
    { lat: 48.6446285, lng: 21.9085353 },
    { lat: 48.6440514, lng: 21.9064871 },
    { lat: 48.6468876, lng: 21.9044014 },
    { lat: 48.6470577, lng: 21.9044532 },
    { lat: 48.6471665, lng: 21.9042246 },
    { lat: 48.647397, lng: 21.9039537 },
    { lat: 48.6485038, lng: 21.9032366 },
    { lat: 48.649038, lng: 21.9033111 },
    { lat: 48.649477, lng: 21.9031463 },
    { lat: 48.6499282, lng: 21.9027534 },
    { lat: 48.6504448, lng: 21.9020996 },
    { lat: 48.6505283, lng: 21.9017941 },
    { lat: 48.6511881, lng: 21.9018592 },
    { lat: 48.6513293, lng: 21.9020599 },
    { lat: 48.6515647, lng: 21.9021125 },
    { lat: 48.6519577, lng: 21.9020862 },
    { lat: 48.6527297, lng: 21.9017399 },
    { lat: 48.6534228, lng: 21.9044933 },
    { lat: 48.6535114, lng: 21.9045747 },
    { lat: 48.6543586, lng: 21.9068923 },
    { lat: 48.6548742, lng: 21.9084605 },
    { lat: 48.6553922, lng: 21.9102603 },
    { lat: 48.6558211, lng: 21.9123161 },
    { lat: 48.6541594, lng: 21.9131593 },
    { lat: 48.6541858, lng: 21.9134467 },
    { lat: 48.6544113, lng: 21.9144043 },
    { lat: 48.6545749, lng: 21.9155826 },
    { lat: 48.6539621, lng: 21.9161757 },
    { lat: 48.6535792, lng: 21.91665 },
    { lat: 48.6534483, lng: 21.9171688 },
    { lat: 48.6534957, lng: 21.918379 },
    { lat: 48.6535113, lng: 21.9197063 },
    { lat: 48.6534594, lng: 21.9199758 },
    { lat: 48.653978, lng: 21.920817 },
    { lat: 48.654184, lng: 21.921361 },
    { lat: 48.6547785, lng: 21.92134 },
    { lat: 48.6552723, lng: 21.9212053 },
    { lat: 48.6556363, lng: 21.9220306 },
    { lat: 48.655725, lng: 21.922118 },
    { lat: 48.6570031, lng: 21.9211316 },
    { lat: 48.6572087, lng: 21.9215728 },
    { lat: 48.657842, lng: 21.923579 },
    { lat: 48.6589154, lng: 21.923405 },
    { lat: 48.6593429, lng: 21.9233168 },
    { lat: 48.6598581, lng: 21.9230947 },
    { lat: 48.6601743, lng: 21.9223784 },
    { lat: 48.6602419, lng: 21.9222909 },
    { lat: 48.6605249, lng: 21.9221818 },
    { lat: 48.6607652, lng: 21.9223467 },
    { lat: 48.6611139, lng: 21.923686 },
    { lat: 48.6615841, lng: 21.9240011 },
    { lat: 48.6622741, lng: 21.9242832 },
    { lat: 48.6625841, lng: 21.9243487 },
    { lat: 48.6635814, lng: 21.923614 },
    { lat: 48.6638894, lng: 21.9232443 },
    { lat: 48.6643321, lng: 21.9225108 },
    { lat: 48.6648388, lng: 21.921944 },
    { lat: 48.6649403, lng: 21.9219129 },
    { lat: 48.6653008, lng: 21.9220648 },
    { lat: 48.6660167, lng: 21.922711 },
    { lat: 48.666226, lng: 21.9225306 },
    { lat: 48.6663328, lng: 21.9221736 },
    { lat: 48.6663729, lng: 21.9214431 },
    { lat: 48.6664371, lng: 21.9210811 },
    { lat: 48.6667246, lng: 21.9199933 },
    { lat: 48.6672625, lng: 21.919372 },
    { lat: 48.6680198, lng: 21.9188078 },
    { lat: 48.6683244, lng: 21.9181732 },
    { lat: 48.6684462, lng: 21.9176444 },
    { lat: 48.6683814, lng: 21.9169079 },
    { lat: 48.6681737, lng: 21.9163361 },
    { lat: 48.6680974, lng: 21.9159013 },
    { lat: 48.6681357, lng: 21.9156802 },
    { lat: 48.6682513, lng: 21.9154035 },
    { lat: 48.6684358, lng: 21.9153167 },
    { lat: 48.6692914, lng: 21.9155654 },
    { lat: 48.6694182, lng: 21.9155058 },
    { lat: 48.6695106, lng: 21.9153278 },
    { lat: 48.6695371, lng: 21.9147341 },
    { lat: 48.6693834, lng: 21.9139887 },
    { lat: 48.66955, lng: 21.9122569 },
    { lat: 48.6689898, lng: 21.9119926 },
    { lat: 48.6685698, lng: 21.9118851 },
    { lat: 48.6682801, lng: 21.9119136 },
    { lat: 48.6680558, lng: 21.9118421 },
    { lat: 48.6680401, lng: 21.9117615 },
    { lat: 48.6680256, lng: 21.9113203 },
    { lat: 48.6685281, lng: 21.9105045 },
    { lat: 48.6684537, lng: 21.910098 },
    { lat: 48.6680652, lng: 21.9091915 },
    { lat: 48.6680036, lng: 21.9088085 },
    { lat: 48.6682208, lng: 21.9081867 },
    { lat: 48.6680714, lng: 21.9061496 },
    { lat: 48.6682406, lng: 21.905633 },
    { lat: 48.6682546, lng: 21.9053848 },
    { lat: 48.6681777, lng: 21.9045657 },
    { lat: 48.6680349, lng: 21.9039902 },
    { lat: 48.6678315, lng: 21.9039539 },
    { lat: 48.6671689, lng: 21.9005431 },
    { lat: 48.6682103, lng: 21.9000829 },
    { lat: 48.6700266, lng: 21.8991308 },
    { lat: 48.6698261, lng: 21.8984113 },
    { lat: 48.6694272, lng: 21.8979475 },
    { lat: 48.6692199, lng: 21.8977573 },
    { lat: 48.6690232, lng: 21.8976694 },
    { lat: 48.6689988, lng: 21.8976 },
    { lat: 48.6706986, lng: 21.8969268 },
    { lat: 48.6706157, lng: 21.8963618 },
    { lat: 48.6706157, lng: 21.8945896 },
    { lat: 48.6708065, lng: 21.8936023 },
    { lat: 48.670779, lng: 21.8932689 },
    { lat: 48.6704539, lng: 21.8918616 },
    { lat: 48.6704394, lng: 21.8914673 },
    { lat: 48.6706797, lng: 21.8908294 },
    { lat: 48.6715879, lng: 21.8893801 },
    { lat: 48.671386, lng: 21.8888743 },
    { lat: 48.6711948, lng: 21.8882187 },
    { lat: 48.670929, lng: 21.8867061 },
    { lat: 48.67037, lng: 21.8862673 },
    { lat: 48.670142, lng: 21.8861919 },
    { lat: 48.6694285, lng: 21.8861785 },
    { lat: 48.6693933, lng: 21.885861 },
    { lat: 48.6694568, lng: 21.8858349 },
    { lat: 48.669439, lng: 21.8857095 },
    { lat: 48.6689549, lng: 21.8858263 },
    { lat: 48.6685606, lng: 21.8857634 },
    { lat: 48.6680412, lng: 21.8855128 },
    { lat: 48.6678564, lng: 21.8853196 },
    { lat: 48.6668711, lng: 21.8845828 },
    { lat: 48.666444, lng: 21.8845133 },
    { lat: 48.6654351, lng: 21.8854403 },
    { lat: 48.6645898, lng: 21.885646 },
    { lat: 48.6636629, lng: 21.8855204 },
    { lat: 48.6630989, lng: 21.885902 },
    { lat: 48.6629308, lng: 21.8844217 },
    { lat: 48.6628714, lng: 21.883507 },
    { lat: 48.6626181, lng: 21.8818313 },
    { lat: 48.6614601, lng: 21.8816917 },
    { lat: 48.6573945, lng: 21.8812016 },
    { lat: 48.6554978, lng: 21.8753476 },
    { lat: 48.6551677, lng: 21.8744523 },
    { lat: 48.6558556, lng: 21.8736312 },
    { lat: 48.6566621, lng: 21.8732725 },
    { lat: 48.6573469, lng: 21.8728685 },
    { lat: 48.6584733, lng: 21.8729227 },
    { lat: 48.658282, lng: 21.8723485 },
    { lat: 48.6589759, lng: 21.8723447 },
    { lat: 48.6596936, lng: 21.8722566 },
    { lat: 48.6595388, lng: 21.8714372 },
    { lat: 48.6601059, lng: 21.8706888 },
    { lat: 48.660605, lng: 21.8704977 },
    { lat: 48.6597111, lng: 21.8654786 },
    { lat: 48.6590362, lng: 21.8642046 },
    { lat: 48.6588812, lng: 21.8637329 },
    { lat: 48.6589114, lng: 21.8631621 },
    { lat: 48.6597754, lng: 21.8597398 },
    { lat: 48.6598493, lng: 21.8594233 },
    { lat: 48.6598676, lng: 21.8590824 },
    { lat: 48.6598363, lng: 21.8584807 },
    { lat: 48.6596116, lng: 21.8573587 },
    { lat: 48.6594393, lng: 21.8571788 },
    { lat: 48.6590592, lng: 21.8570473 },
    { lat: 48.6589279, lng: 21.8569266 },
    { lat: 48.6587193, lng: 21.8563901 },
    { lat: 48.6586596, lng: 21.8559487 },
    { lat: 48.6586564, lng: 21.8549066 },
    { lat: 48.6586567, lng: 21.854875 },
    { lat: 48.656503, lng: 21.8555981 },
    { lat: 48.6564266, lng: 21.8553869 },
    { lat: 48.6528098, lng: 21.8582734 },
    { lat: 48.6526902, lng: 21.8581782 },
    { lat: 48.6510403, lng: 21.8595042 },
    { lat: 48.6509347, lng: 21.8592934 },
    { lat: 48.6483699, lng: 21.8609215 },
    { lat: 48.6480103, lng: 21.8611109 },
    { lat: 48.6469914, lng: 21.8613946 },
    { lat: 48.644089, lng: 21.8619217 },
    { lat: 48.6440118, lng: 21.861933 },
  ],
  Staré: [
    { lat: 48.890292, lng: 21.877119 },
    { lat: 48.8897901, lng: 21.876747 },
    { lat: 48.8874015, lng: 21.8754984 },
    { lat: 48.8867347, lng: 21.8750198 },
    { lat: 48.885766, lng: 21.8744853 },
    { lat: 48.8840627, lng: 21.873239 },
    { lat: 48.8833568, lng: 21.8726437 },
    { lat: 48.8834822, lng: 21.8723579 },
    { lat: 48.8825238, lng: 21.8712135 },
    { lat: 48.8824659, lng: 21.8710139 },
    { lat: 48.8827663, lng: 21.8707 },
    { lat: 48.8826817, lng: 21.870166 },
    { lat: 48.8823394, lng: 21.8695823 },
    { lat: 48.8814497, lng: 21.8701185 },
    { lat: 48.8811669, lng: 21.8698777 },
    { lat: 48.8810589, lng: 21.8700549 },
    { lat: 48.8805393, lng: 21.8695264 },
    { lat: 48.8806922, lng: 21.8690403 },
    { lat: 48.8794237, lng: 21.8675628 },
    { lat: 48.8791078, lng: 21.8671267 },
    { lat: 48.8787794, lng: 21.8664709 },
    { lat: 48.8784231, lng: 21.8661698 },
    { lat: 48.8782669, lng: 21.8658244 },
    { lat: 48.8781479, lng: 21.8651771 },
    { lat: 48.8780359, lng: 21.8651043 },
    { lat: 48.8778106, lng: 21.8647476 },
    { lat: 48.8778157, lng: 21.8645861 },
    { lat: 48.8775935, lng: 21.864081 },
    { lat: 48.8773417, lng: 21.8639625 },
    { lat: 48.8771709, lng: 21.8637902 },
    { lat: 48.8770044, lng: 21.8634827 },
    { lat: 48.876839, lng: 21.8634395 },
    { lat: 48.876813, lng: 21.8633442 },
    { lat: 48.8766706, lng: 21.8633161 },
    { lat: 48.8763168, lng: 21.8630327 },
    { lat: 48.8757308, lng: 21.8629868 },
    { lat: 48.8753529, lng: 21.8627731 },
    { lat: 48.8752884, lng: 21.8628058 },
    { lat: 48.8751581, lng: 21.8626791 },
    { lat: 48.8750327, lng: 21.8626965 },
    { lat: 48.8746078, lng: 21.8623162 },
    { lat: 48.8745202, lng: 21.8620133 },
    { lat: 48.874059, lng: 21.8616684 },
    { lat: 48.8738647, lng: 21.8616316 },
    { lat: 48.8737886, lng: 21.861521 },
    { lat: 48.873629, lng: 21.8616144 },
    { lat: 48.8733834, lng: 21.8614655 },
    { lat: 48.8733268, lng: 21.8615835 },
    { lat: 48.8730394, lng: 21.8614271 },
    { lat: 48.8729009, lng: 21.8614238 },
    { lat: 48.8729043, lng: 21.8613797 },
    { lat: 48.8722337, lng: 21.8608924 },
    { lat: 48.8716692, lng: 21.8616528 },
    { lat: 48.8701309, lng: 21.8607044 },
    { lat: 48.8696599, lng: 21.8602826 },
    { lat: 48.8693899, lng: 21.8599294 },
    { lat: 48.8694684, lng: 21.8598747 },
    { lat: 48.8685704, lng: 21.8588491 },
    { lat: 48.8684737, lng: 21.8585064 },
    { lat: 48.8684185, lng: 21.8579694 },
    { lat: 48.868313, lng: 21.857494 },
    { lat: 48.8678878, lng: 21.8577173 },
    { lat: 48.8673225, lng: 21.8578735 },
    { lat: 48.8669937, lng: 21.8575527 },
    { lat: 48.8669638, lng: 21.8564718 },
    { lat: 48.8670006, lng: 21.8556731 },
    { lat: 48.8666125, lng: 21.8553641 },
    { lat: 48.8663038, lng: 21.8553058 },
    { lat: 48.8658695, lng: 21.8554747 },
    { lat: 48.8651778, lng: 21.8558341 },
    { lat: 48.8650588, lng: 21.8559705 },
    { lat: 48.8648303, lng: 21.8560532 },
    { lat: 48.864644, lng: 21.8562064 },
    { lat: 48.8643209, lng: 21.8563734 },
    { lat: 48.8641855, lng: 21.8563517 },
    { lat: 48.8633701, lng: 21.8568495 },
    { lat: 48.8628554, lng: 21.856862 },
    { lat: 48.8622934, lng: 21.8565764 },
    { lat: 48.8613541, lng: 21.856711 },
    { lat: 48.8606384, lng: 21.8566563 },
    { lat: 48.8598138, lng: 21.8584569 },
    { lat: 48.8591171, lng: 21.8585715 },
    { lat: 48.8568563, lng: 21.8551631 },
    { lat: 48.8556677, lng: 21.8536931 },
    { lat: 48.8483149, lng: 21.8568887 },
    { lat: 48.8432477, lng: 21.8585383 },
    { lat: 48.8394384, lng: 21.8569525 },
    { lat: 48.8386011, lng: 21.8573785 },
    { lat: 48.8379587, lng: 21.8578552 },
    { lat: 48.8378016, lng: 21.8579151 },
    { lat: 48.8372761, lng: 21.8579132 },
    { lat: 48.8319134, lng: 21.8589162 },
    { lat: 48.8284215, lng: 21.855058 },
    { lat: 48.8283577, lng: 21.8549879 },
    { lat: 48.828345, lng: 21.855015 },
    { lat: 48.8276312, lng: 21.8564402 },
    { lat: 48.8276154, lng: 21.85666 },
    { lat: 48.827839, lng: 21.8572156 },
    { lat: 48.8286956, lng: 21.8579868 },
    { lat: 48.8292994, lng: 21.8583201 },
    { lat: 48.8293346, lng: 21.8586837 },
    { lat: 48.8290865, lng: 21.8594498 },
    { lat: 48.8287197, lng: 21.859671 },
    { lat: 48.8284765, lng: 21.8596578 },
    { lat: 48.8279357, lng: 21.8593254 },
    { lat: 48.827846, lng: 21.8593274 },
    { lat: 48.8276837, lng: 21.8594523 },
    { lat: 48.8275348, lng: 21.8597856 },
    { lat: 48.8274707, lng: 21.8600557 },
    { lat: 48.8274554, lng: 21.8604618 },
    { lat: 48.8273535, lng: 21.8608356 },
    { lat: 48.8271062, lng: 21.8611029 },
    { lat: 48.826855, lng: 21.8611776 },
    { lat: 48.8266593, lng: 21.8609656 },
    { lat: 48.8262882, lng: 21.8603926 },
    { lat: 48.8262238, lng: 21.8602543 },
    { lat: 48.8262323, lng: 21.8601071 },
    { lat: 48.8263007, lng: 21.8598763 },
    { lat: 48.8270192, lng: 21.8590679 },
    { lat: 48.8271015, lng: 21.8586832 },
    { lat: 48.827027, lng: 21.8584983 },
    { lat: 48.8268817, lng: 21.8584469 },
    { lat: 48.8265383, lng: 21.858571 },
    { lat: 48.8262864, lng: 21.858749 },
    { lat: 48.8258138, lng: 21.8602309 },
    { lat: 48.8255653, lng: 21.8614276 },
    { lat: 48.8254234, lng: 21.8623842 },
    { lat: 48.8253422, lng: 21.8625553 },
    { lat: 48.8251977, lng: 21.862703 },
    { lat: 48.8245198, lng: 21.8623305 },
    { lat: 48.8240432, lng: 21.8625609 },
    { lat: 48.8238661, lng: 21.8627605 },
    { lat: 48.8236882, lng: 21.8637663 },
    { lat: 48.8230666, lng: 21.8646717 },
    { lat: 48.8260348, lng: 21.8692228 },
    { lat: 48.8288312, lng: 21.8708445 },
    { lat: 48.8291785, lng: 21.8709667 },
    { lat: 48.82934, lng: 21.8712086 },
    { lat: 48.8296238, lng: 21.8720192 },
    { lat: 48.8296004, lng: 21.8723752 },
    { lat: 48.8296334, lng: 21.8726182 },
    { lat: 48.8292354, lng: 21.8727975 },
    { lat: 48.8291977, lng: 21.8733927 },
    { lat: 48.8287832, lng: 21.875099 },
    { lat: 48.8289087, lng: 21.8753873 },
    { lat: 48.828908, lng: 21.8757951 },
    { lat: 48.8286242, lng: 21.8764433 },
    { lat: 48.8288229, lng: 21.8771354 },
    { lat: 48.8298148, lng: 21.880488 },
    { lat: 48.8304019, lng: 21.8818912 },
    { lat: 48.8308084, lng: 21.8836377 },
    { lat: 48.831251, lng: 21.8834777 },
    { lat: 48.8313639, lng: 21.8842456 },
    { lat: 48.8313866, lng: 21.8856332 },
    { lat: 48.8311677, lng: 21.886666 },
    { lat: 48.830878, lng: 21.8874013 },
    { lat: 48.8305465, lng: 21.889499 },
    { lat: 48.8302656, lng: 21.8901691 },
    { lat: 48.830541, lng: 21.8921151 },
    { lat: 48.8317106, lng: 21.8929026 },
    { lat: 48.8329683, lng: 21.8939225 },
    { lat: 48.8339162, lng: 21.8942655 },
    { lat: 48.8341957, lng: 21.8945071 },
    { lat: 48.8342814, lng: 21.8945089 },
    { lat: 48.834366, lng: 21.8946568 },
    { lat: 48.8346005, lng: 21.8948053 },
    { lat: 48.8347962, lng: 21.8950289 },
    { lat: 48.8349426, lng: 21.8950415 },
    { lat: 48.835438, lng: 21.8954544 },
    { lat: 48.8363386, lng: 21.8960092 },
    { lat: 48.836498, lng: 21.8960403 },
    { lat: 48.8367142, lng: 21.8962259 },
    { lat: 48.8375209, lng: 21.8965912 },
    { lat: 48.837845, lng: 21.8966377 },
    { lat: 48.8382172, lng: 21.8968132 },
    { lat: 48.8389428, lng: 21.8975246 },
    { lat: 48.8394776, lng: 21.8978082 },
    { lat: 48.8396829, lng: 21.8978259 },
    { lat: 48.8398168, lng: 21.8977456 },
    { lat: 48.8399134, lng: 21.8977811 },
    { lat: 48.8406488, lng: 21.8988376 },
    { lat: 48.8411264, lng: 21.8991285 },
    { lat: 48.8417271, lng: 21.8993918 },
    { lat: 48.8423632, lng: 21.8998624 },
    { lat: 48.8421753, lng: 21.8995433 },
    { lat: 48.841971, lng: 21.899388 },
    { lat: 48.8418793, lng: 21.897722 },
    { lat: 48.8422509, lng: 21.8976486 },
    { lat: 48.8435063, lng: 21.8970693 },
    { lat: 48.845224, lng: 21.8961328 },
    { lat: 48.8454134, lng: 21.8961025 },
    { lat: 48.8457742, lng: 21.8958287 },
    { lat: 48.847123, lng: 21.8951067 },
    { lat: 48.8471039, lng: 21.8944474 },
    { lat: 48.8480538, lng: 21.8934011 },
    { lat: 48.8490089, lng: 21.8926912 },
    { lat: 48.8492909, lng: 21.8926262 },
    { lat: 48.850633, lng: 21.8925864 },
    { lat: 48.8509399, lng: 21.8924928 },
    { lat: 48.851714, lng: 21.8924975 },
    { lat: 48.8521181, lng: 21.8925951 },
    { lat: 48.8522438, lng: 21.8924006 },
    { lat: 48.8524603, lng: 21.8923416 },
    { lat: 48.8525666, lng: 21.8925829 },
    { lat: 48.8538018, lng: 21.89245 },
    { lat: 48.85379, lng: 21.8930447 },
    { lat: 48.8542804, lng: 21.892874 },
    { lat: 48.8550248, lng: 21.8928997 },
    { lat: 48.8557233, lng: 21.8932063 },
    { lat: 48.8562118, lng: 21.8933112 },
    { lat: 48.8563122, lng: 21.893899 },
    { lat: 48.8570933, lng: 21.8954226 },
    { lat: 48.8574078, lng: 21.8958516 },
    { lat: 48.857441, lng: 21.8965877 },
    { lat: 48.8577926, lng: 21.8962926 },
    { lat: 48.8582135, lng: 21.8962142 },
    { lat: 48.8583313, lng: 21.8959787 },
    { lat: 48.8585844, lng: 21.8959776 },
    { lat: 48.8586776, lng: 21.8960626 },
    { lat: 48.858824, lng: 21.8960287 },
    { lat: 48.858987, lng: 21.8961535 },
    { lat: 48.8591297, lng: 21.8963728 },
    { lat: 48.8594366, lng: 21.8964904 },
    { lat: 48.8595054, lng: 21.8963239 },
    { lat: 48.8597866, lng: 21.89632 },
    { lat: 48.8599489, lng: 21.8966078 },
    { lat: 48.8608093, lng: 21.8966447 },
    { lat: 48.8621952, lng: 21.8972626 },
    { lat: 48.8621764, lng: 21.8979886 },
    { lat: 48.8625427, lng: 21.8981478 },
    { lat: 48.8641074, lng: 21.8984171 },
    { lat: 48.8661062, lng: 21.898896 },
    { lat: 48.8666633, lng: 21.8992163 },
    { lat: 48.8673443, lng: 21.9004834 },
    { lat: 48.8686575, lng: 21.9018826 },
    { lat: 48.8698746, lng: 21.9017452 },
    { lat: 48.8733182, lng: 21.9017513 },
    { lat: 48.874528, lng: 21.9015461 },
    { lat: 48.874748, lng: 21.9013525 },
    { lat: 48.8750924, lng: 21.9014022 },
    { lat: 48.8752623, lng: 21.9013623 },
    { lat: 48.8753163, lng: 21.9014089 },
    { lat: 48.8756875, lng: 21.9011354 },
    { lat: 48.8758553, lng: 21.9010974 },
    { lat: 48.8760489, lng: 21.9007896 },
    { lat: 48.8762967, lng: 21.900718 },
    { lat: 48.878864, lng: 21.9017091 },
    { lat: 48.8799111, lng: 21.9017838 },
    { lat: 48.8807659, lng: 21.9014662 },
    { lat: 48.8811121, lng: 21.9011949 },
    { lat: 48.8812167, lng: 21.90092 },
    { lat: 48.8815602, lng: 21.900448 },
    { lat: 48.8817129, lng: 21.899948 },
    { lat: 48.8821761, lng: 21.898999 },
    { lat: 48.8822658, lng: 21.8986386 },
    { lat: 48.8824482, lng: 21.8983738 },
    { lat: 48.8826136, lng: 21.8983324 },
    { lat: 48.8828731, lng: 21.8985375 },
    { lat: 48.8832028, lng: 21.8985427 },
    { lat: 48.8842052, lng: 21.8992129 },
    { lat: 48.8845559, lng: 21.8992011 },
    { lat: 48.8849603, lng: 21.8993642 },
    { lat: 48.885008, lng: 21.899419 },
    { lat: 48.88498, lng: 21.898276 },
    { lat: 48.885117, lng: 21.897281 },
    { lat: 48.885069, lng: 21.897139 },
    { lat: 48.884882, lng: 21.896589 },
    { lat: 48.884885, lng: 21.895874 },
    { lat: 48.884856, lng: 21.895005 },
    { lat: 48.884746, lng: 21.894545 },
    { lat: 48.88531, lng: 21.893704 },
    { lat: 48.885584, lng: 21.893173 },
    { lat: 48.88579, lng: 21.892566 },
    { lat: 48.885884, lng: 21.892183 },
    { lat: 48.885905, lng: 21.892092 },
    { lat: 48.886034, lng: 21.891565 },
    { lat: 48.88611, lng: 21.891326 },
    { lat: 48.886244, lng: 21.890474 },
    { lat: 48.886266, lng: 21.889794 },
    { lat: 48.886266, lng: 21.889494 },
    { lat: 48.886328, lng: 21.889242 },
    { lat: 48.886741, lng: 21.888878 },
    { lat: 48.887249, lng: 21.888788 },
    { lat: 48.887552, lng: 21.888836 },
    { lat: 48.887806, lng: 21.888625 },
    { lat: 48.887977, lng: 21.888654 },
    { lat: 48.888411, lng: 21.888051 },
    { lat: 48.888528, lng: 21.887815 },
    { lat: 48.888372, lng: 21.887191 },
    { lat: 48.888336, lng: 21.886489 },
    { lat: 48.888417, lng: 21.88619 },
    { lat: 48.888805, lng: 21.885714 },
    { lat: 48.889437, lng: 21.885017 },
    { lat: 48.889688, lng: 21.883271 },
    { lat: 48.88975, lng: 21.882869 },
    { lat: 48.889741, lng: 21.882511 },
    { lat: 48.889639, lng: 21.881729 },
    { lat: 48.889754, lng: 21.881271 },
    { lat: 48.889877, lng: 21.880993 },
    { lat: 48.890011, lng: 21.880693 },
    { lat: 48.890097, lng: 21.879535 },
    { lat: 48.890212, lng: 21.878748 },
    { lat: 48.890292, lng: 21.877119 },
  ],
  Kačanov: [
    { lat: 48.6221065, lng: 21.8555526 },
    { lat: 48.6221244, lng: 21.8555516 },
    { lat: 48.6222465, lng: 21.8554445 },
    { lat: 48.6216581, lng: 21.8530371 },
    { lat: 48.6199048, lng: 21.8447862 },
    { lat: 48.6201144, lng: 21.8447597 },
    { lat: 48.620154, lng: 21.8448953 },
    { lat: 48.6204949, lng: 21.844722 },
    { lat: 48.6202298, lng: 21.843434 },
    { lat: 48.6197912, lng: 21.8417434 },
    { lat: 48.6198945, lng: 21.8415638 },
    { lat: 48.6197057, lng: 21.8406163 },
    { lat: 48.6191639, lng: 21.8366412 },
    { lat: 48.6187317, lng: 21.8349851 },
    { lat: 48.6176786, lng: 21.835847 },
    { lat: 48.61445, lng: 21.8365558 },
    { lat: 48.6135993, lng: 21.832427 },
    { lat: 48.6129362, lng: 21.8285108 },
    { lat: 48.612707, lng: 21.8258923 },
    { lat: 48.6118864, lng: 21.8206431 },
    { lat: 48.610931, lng: 21.8139117 },
    { lat: 48.6106192, lng: 21.812058 },
    { lat: 48.610359, lng: 21.809806 },
    { lat: 48.6098408, lng: 21.8066446 },
    { lat: 48.6097365, lng: 21.8056258 },
    { lat: 48.609118, lng: 21.800972 },
    { lat: 48.609097, lng: 21.800981 },
    { lat: 48.607236, lng: 21.801222 },
    { lat: 48.607084, lng: 21.801279 },
    { lat: 48.606897, lng: 21.801349 },
    { lat: 48.606854, lng: 21.801364 },
    { lat: 48.606697, lng: 21.801379 },
    { lat: 48.606558, lng: 21.801392 },
    { lat: 48.60635, lng: 21.801412 },
    { lat: 48.606286, lng: 21.801418 },
    { lat: 48.606007, lng: 21.801582 },
    { lat: 48.606001, lng: 21.801585 },
    { lat: 48.605859, lng: 21.80169 },
    { lat: 48.605747, lng: 21.801772 },
    { lat: 48.605625, lng: 21.801869 },
    { lat: 48.605606, lng: 21.801883 },
    { lat: 48.605173, lng: 21.802121 },
    { lat: 48.605169, lng: 21.802131 },
    { lat: 48.605084, lng: 21.802308 },
    { lat: 48.60496, lng: 21.802527 },
    { lat: 48.604784, lng: 21.802747 },
    { lat: 48.604453, lng: 21.802963 },
    { lat: 48.604135, lng: 21.803088 },
    { lat: 48.604129, lng: 21.803091 },
    { lat: 48.603984, lng: 21.803178 },
    { lat: 48.603753, lng: 21.803454 },
    { lat: 48.603645, lng: 21.803544 },
    { lat: 48.603622, lng: 21.803564 },
    { lat: 48.603526, lng: 21.803644 },
    { lat: 48.603489, lng: 21.803674 },
    { lat: 48.603289, lng: 21.803842 },
    { lat: 48.603268, lng: 21.80411 },
    { lat: 48.603259, lng: 21.804235 },
    { lat: 48.603259, lng: 21.804237 },
    { lat: 48.603256, lng: 21.804278 },
    { lat: 48.603021, lng: 21.804884 },
    { lat: 48.602918, lng: 21.805065 },
    { lat: 48.60288, lng: 21.80509 },
    { lat: 48.602844, lng: 21.805112 },
    { lat: 48.602721, lng: 21.805191 },
    { lat: 48.602716, lng: 21.805195 },
    { lat: 48.602506, lng: 21.805383 },
    { lat: 48.602401, lng: 21.805478 },
    { lat: 48.602106, lng: 21.805742 },
    { lat: 48.6021, lng: 21.805747 },
    { lat: 48.602122, lng: 21.80589 },
    { lat: 48.602194, lng: 21.806076 },
    { lat: 48.602187, lng: 21.806126 },
    { lat: 48.602173, lng: 21.806221 },
    { lat: 48.602113, lng: 21.806542 },
    { lat: 48.601653, lng: 21.806821 },
    { lat: 48.601625, lng: 21.806827 },
    { lat: 48.60162, lng: 21.806828 },
    { lat: 48.601343, lng: 21.806883 },
    { lat: 48.601329, lng: 21.806885 },
    { lat: 48.601293, lng: 21.806966 },
    { lat: 48.60127, lng: 21.807021 },
    { lat: 48.601046, lng: 21.807527 },
    { lat: 48.600799, lng: 21.807427 },
    { lat: 48.600779, lng: 21.807419 },
    { lat: 48.600754, lng: 21.807409 },
    { lat: 48.600679, lng: 21.80743 },
    { lat: 48.600433, lng: 21.807641 },
    { lat: 48.600383, lng: 21.807671 },
    { lat: 48.600308, lng: 21.807717 },
    { lat: 48.600262, lng: 21.807795 },
    { lat: 48.600216, lng: 21.80787 },
    { lat: 48.599917, lng: 21.808332 },
    { lat: 48.599559, lng: 21.808646 },
    { lat: 48.599409, lng: 21.80871 },
    { lat: 48.599318, lng: 21.808749 },
    { lat: 48.598927, lng: 21.809132 },
    { lat: 48.598711, lng: 21.809117 },
    { lat: 48.598058, lng: 21.808934 },
    { lat: 48.597974, lng: 21.808935 },
    { lat: 48.597881, lng: 21.809008 },
    { lat: 48.597787, lng: 21.809084 },
    { lat: 48.597665, lng: 21.809077 },
    { lat: 48.597469, lng: 21.809117 },
    { lat: 48.597271, lng: 21.809158 },
    { lat: 48.597216, lng: 21.80917 },
    { lat: 48.596764, lng: 21.809564 },
    { lat: 48.596576, lng: 21.809556 },
    { lat: 48.596477, lng: 21.809687 },
    { lat: 48.59645, lng: 21.809723 },
    { lat: 48.596341, lng: 21.809868 },
    { lat: 48.596263, lng: 21.809892 },
    { lat: 48.595989, lng: 21.810053 },
    { lat: 48.595912, lng: 21.81009 },
    { lat: 48.595839, lng: 21.810059 },
    { lat: 48.595768, lng: 21.81003 },
    { lat: 48.595718, lng: 21.810047 },
    { lat: 48.595699, lng: 21.810053 },
    { lat: 48.595666, lng: 21.810065 },
    { lat: 48.595362, lng: 21.810171 },
    { lat: 48.595361, lng: 21.810171 },
    { lat: 48.595106, lng: 21.810385 },
    { lat: 48.595015, lng: 21.810449 },
    { lat: 48.594789, lng: 21.810608 },
    { lat: 48.59474, lng: 21.810677 },
    { lat: 48.594729, lng: 21.810692 },
    { lat: 48.594711, lng: 21.810717 },
    { lat: 48.5947526, lng: 21.8110703 },
    { lat: 48.5957173, lng: 21.8132883 },
    { lat: 48.5974423, lng: 21.8185045 },
    { lat: 48.5978138, lng: 21.8192509 },
    { lat: 48.5981927, lng: 21.8218302 },
    { lat: 48.5986732, lng: 21.8241224 },
    { lat: 48.5992442, lng: 21.8243214 },
    { lat: 48.6000416, lng: 21.8266404 },
    { lat: 48.6009981, lng: 21.8289813 },
    { lat: 48.6022523, lng: 21.8330892 },
    { lat: 48.6025436, lng: 21.8338227 },
    { lat: 48.6030495, lng: 21.8348913 },
    { lat: 48.6032447, lng: 21.8356899 },
    { lat: 48.6036846, lng: 21.8357819 },
    { lat: 48.603837, lng: 21.8360115 },
    { lat: 48.604073, lng: 21.8370497 },
    { lat: 48.6042764, lng: 21.8375105 },
    { lat: 48.604547, lng: 21.8386634 },
    { lat: 48.6047572, lng: 21.8391778 },
    { lat: 48.6054142, lng: 21.8420427 },
    { lat: 48.6058087, lng: 21.8429839 },
    { lat: 48.605982, lng: 21.8432869 },
    { lat: 48.6064521, lng: 21.8445899 },
    { lat: 48.6065304, lng: 21.8448753 },
    { lat: 48.6066537, lng: 21.8462065 },
    { lat: 48.6072885, lng: 21.8485286 },
    { lat: 48.6076754, lng: 21.8495237 },
    { lat: 48.6088726, lng: 21.8530155 },
    { lat: 48.6086847, lng: 21.8531421 },
    { lat: 48.6104349, lng: 21.8586769 },
    { lat: 48.6107678, lng: 21.8600258 },
    { lat: 48.6109667, lng: 21.8604994 },
    { lat: 48.6110641, lng: 21.8604344 },
    { lat: 48.6116655, lng: 21.859408 },
    { lat: 48.6115192, lng: 21.8590187 },
    { lat: 48.6128854, lng: 21.858116 },
    { lat: 48.613701, lng: 21.8576807 },
    { lat: 48.6150139, lng: 21.8568501 },
    { lat: 48.6151055, lng: 21.8570818 },
    { lat: 48.6157858, lng: 21.8569435 },
    { lat: 48.6163035, lng: 21.8570355 },
    { lat: 48.6164164, lng: 21.8569613 },
    { lat: 48.6171831, lng: 21.8568058 },
    { lat: 48.6201116, lng: 21.8554174 },
    { lat: 48.6203867, lng: 21.8563952 },
    { lat: 48.621371, lng: 21.8559129 },
    { lat: 48.6221065, lng: 21.8555526 },
  ],
  TušickáNováVes: [
    { lat: 48.7217752, lng: 21.7783863 },
    { lat: 48.7218897, lng: 21.778166 },
    { lat: 48.7221746, lng: 21.7781367 },
    { lat: 48.7224368, lng: 21.7783087 },
    { lat: 48.7225164, lng: 21.7787243 },
    { lat: 48.7226386, lng: 21.7789229 },
    { lat: 48.7228157, lng: 21.7790252 },
    { lat: 48.7230963, lng: 21.7788276 },
    { lat: 48.7232968, lng: 21.7785383 },
    { lat: 48.7236221, lng: 21.7784097 },
    { lat: 48.7239498, lng: 21.7780899 },
    { lat: 48.7240889, lng: 21.7777309 },
    { lat: 48.7241813, lng: 21.7770537 },
    { lat: 48.7241813, lng: 21.7767597 },
    { lat: 48.7244837, lng: 21.7766135 },
    { lat: 48.7247908, lng: 21.7770829 },
    { lat: 48.7250249, lng: 21.7770908 },
    { lat: 48.7253306, lng: 21.7769024 },
    { lat: 48.7255934, lng: 21.7768463 },
    { lat: 48.7259148, lng: 21.7770461 },
    { lat: 48.7259844, lng: 21.7772072 },
    { lat: 48.7261138, lng: 21.7772069 },
    { lat: 48.7268551, lng: 21.7760586 },
    { lat: 48.7271252, lng: 21.7752944 },
    { lat: 48.7273639, lng: 21.7750394 },
    { lat: 48.7276723, lng: 21.7748822 },
    { lat: 48.7279253, lng: 21.7745359 },
    { lat: 48.727979, lng: 21.7742654 },
    { lat: 48.7278648, lng: 21.7739494 },
    { lat: 48.7279111, lng: 21.7735243 },
    { lat: 48.7278358, lng: 21.7731896 },
    { lat: 48.7273512, lng: 21.7723487 },
    { lat: 48.7273653, lng: 21.7720331 },
    { lat: 48.7273745, lng: 21.7718291 },
    { lat: 48.727341, lng: 21.7710707 },
    { lat: 48.7270887, lng: 21.770293 },
    { lat: 48.7268979, lng: 21.7695049 },
    { lat: 48.726869, lng: 21.7693823 },
    { lat: 48.7269299, lng: 21.7693505 },
    { lat: 48.7212104, lng: 21.7488519 },
    { lat: 48.7212422, lng: 21.7488158 },
    { lat: 48.7165153, lng: 21.7319947 },
    { lat: 48.7162979, lng: 21.7322745 },
    { lat: 48.7160156, lng: 21.7319882 },
    { lat: 48.7158755, lng: 21.7314595 },
    { lat: 48.7158037, lng: 21.7309598 },
    { lat: 48.7158139, lng: 21.7302166 },
    { lat: 48.7159331, lng: 21.7300447 },
    { lat: 48.7160855, lng: 21.7295113 },
    { lat: 48.7160802, lng: 21.7293139 },
    { lat: 48.7161699, lng: 21.729012 },
    { lat: 48.7162837, lng: 21.7290154 },
    { lat: 48.7162994, lng: 21.7291024 },
    { lat: 48.7162926, lng: 21.7288467 },
    { lat: 48.71621, lng: 21.728462 },
    { lat: 48.716107, lng: 21.728509 },
    { lat: 48.715795, lng: 21.728658 },
    { lat: 48.715581, lng: 21.72899 },
    { lat: 48.715385, lng: 21.729415 },
    { lat: 48.714991, lng: 21.729779 },
    { lat: 48.71471, lng: 21.729842 },
    { lat: 48.714483, lng: 21.729791 },
    { lat: 48.714228, lng: 21.729588 },
    { lat: 48.713816, lng: 21.729137 },
    { lat: 48.713674, lng: 21.728755 },
    { lat: 48.713593, lng: 21.728688 },
    { lat: 48.713465, lng: 21.728605 },
    { lat: 48.713197, lng: 21.728446 },
    { lat: 48.712915, lng: 21.728509 },
    { lat: 48.712275, lng: 21.728844 },
    { lat: 48.712173, lng: 21.728947 },
    { lat: 48.712077, lng: 21.729067 },
    { lat: 48.712022, lng: 21.729245 },
    { lat: 48.712122, lng: 21.729994 },
    { lat: 48.712339, lng: 21.730685 },
    { lat: 48.712513, lng: 21.730922 },
    { lat: 48.712796, lng: 21.73123 },
    { lat: 48.713127, lng: 21.73158 },
    { lat: 48.71317, lng: 21.731765 },
    { lat: 48.713133, lng: 21.732221 },
    { lat: 48.713078, lng: 21.732576 },
    { lat: 48.712971, lng: 21.732796 },
    { lat: 48.712227, lng: 21.733615 },
    { lat: 48.711938, lng: 21.733611 },
    { lat: 48.711854, lng: 21.733684 },
    { lat: 48.711767, lng: 21.733682 },
    { lat: 48.711385, lng: 21.733766 },
    { lat: 48.711314, lng: 21.733848 },
    { lat: 48.711163, lng: 21.734367 },
    { lat: 48.710652, lng: 21.735251 },
    { lat: 48.710539, lng: 21.735334 },
    { lat: 48.710348, lng: 21.735601 },
    { lat: 48.710015, lng: 21.73559 },
    { lat: 48.709729, lng: 21.735396 },
    { lat: 48.709607, lng: 21.735214 },
    { lat: 48.709455, lng: 21.734879 },
    { lat: 48.709362, lng: 21.734545 },
    { lat: 48.709297, lng: 21.734307 },
    { lat: 48.70926, lng: 21.733882 },
    { lat: 48.709275, lng: 21.733657 },
    { lat: 48.709333, lng: 21.733561 },
    { lat: 48.709189, lng: 21.732814 },
    { lat: 48.709083, lng: 21.732624 },
    { lat: 48.708884, lng: 21.732721 },
    { lat: 48.708683, lng: 21.732976 },
    { lat: 48.708554, lng: 21.733275 },
    { lat: 48.708474, lng: 21.733459 },
    { lat: 48.708312, lng: 21.733748 },
    { lat: 48.708121, lng: 21.733913 },
    { lat: 48.707864, lng: 21.733805 },
    { lat: 48.707596, lng: 21.733524 },
    { lat: 48.706938, lng: 21.732522 },
    { lat: 48.706578, lng: 21.732188 },
    { lat: 48.706189, lng: 21.732034 },
    { lat: 48.706184, lng: 21.732032 },
    { lat: 48.7060843, lng: 21.7340343 },
    { lat: 48.7061134, lng: 21.7348297 },
    { lat: 48.7067088, lng: 21.7379084 },
    { lat: 48.7061191, lng: 21.7381977 },
    { lat: 48.7064195, lng: 21.7397524 },
    { lat: 48.7061334, lng: 21.7406664 },
    { lat: 48.7058268, lng: 21.7407325 },
    { lat: 48.7058894, lng: 21.7410524 },
    { lat: 48.7061082, lng: 21.7433505 },
    { lat: 48.7065031, lng: 21.7457972 },
    { lat: 48.7075663, lng: 21.7480315 },
    { lat: 48.7076168, lng: 21.7484056 },
    { lat: 48.7082051, lng: 21.7502582 },
    { lat: 48.7080137, lng: 21.7504287 },
    { lat: 48.7080591, lng: 21.7504409 },
    { lat: 48.7108731, lng: 21.7560704 },
    { lat: 48.7114535, lng: 21.7571235 },
    { lat: 48.711413, lng: 21.7573743 },
    { lat: 48.7119573, lng: 21.7585515 },
    { lat: 48.7128289, lng: 21.7606678 },
    { lat: 48.7131791, lng: 21.7613548 },
    { lat: 48.7134642, lng: 21.7613646 },
    { lat: 48.7134479, lng: 21.7614351 },
    { lat: 48.715301, lng: 21.7677635 },
    { lat: 48.7154373, lng: 21.7680235 },
    { lat: 48.7157762, lng: 21.7694331 },
    { lat: 48.7200844, lng: 21.7758384 },
    { lat: 48.7217752, lng: 21.7783863 },
  ],
  Drahňov: [
    { lat: 48.5654038, lng: 21.9427825 },
    { lat: 48.5654218, lng: 21.9434563 },
    { lat: 48.5655435, lng: 21.94405 },
    { lat: 48.5656386, lng: 21.9442499 },
    { lat: 48.5662718, lng: 21.9449197 },
    { lat: 48.566356, lng: 21.9451781 },
    { lat: 48.5663893, lng: 21.9455282 },
    { lat: 48.5662777, lng: 21.9457881 },
    { lat: 48.5660411, lng: 21.9460836 },
    { lat: 48.5657492, lng: 21.9461559 },
    { lat: 48.5653878, lng: 21.9460507 },
    { lat: 48.5647746, lng: 21.9456455 },
    { lat: 48.5641534, lng: 21.9447768 },
    { lat: 48.5637248, lng: 21.9445962 },
    { lat: 48.563407, lng: 21.9448519 },
    { lat: 48.563182, lng: 21.9453956 },
    { lat: 48.5631163, lng: 21.9460937 },
    { lat: 48.5630013, lng: 21.9467083 },
    { lat: 48.5626642, lng: 21.9479897 },
    { lat: 48.561325, lng: 21.950898 },
    { lat: 48.5602621, lng: 21.9513052 },
    { lat: 48.5598005, lng: 21.9510242 },
    { lat: 48.559205, lng: 21.9512302 },
    { lat: 48.5591075, lng: 21.9513849 },
    { lat: 48.5590493, lng: 21.9522544 },
    { lat: 48.5592494, lng: 21.9527928 },
    { lat: 48.5604985, lng: 21.9540148 },
    { lat: 48.5610563, lng: 21.9546867 },
    { lat: 48.5617347, lng: 21.9571245 },
    { lat: 48.5620246, lng: 21.9576039 },
    { lat: 48.562723, lng: 21.9609903 },
    { lat: 48.5616576, lng: 21.9609879 },
    { lat: 48.5611809, lng: 21.9612713 },
    { lat: 48.5605917, lng: 21.9608315 },
    { lat: 48.56034, lng: 21.9607484 },
    { lat: 48.56011, lng: 21.9607806 },
    { lat: 48.5599076, lng: 21.9609632 },
    { lat: 48.5597783, lng: 21.9612344 },
    { lat: 48.5597922, lng: 21.9617947 },
    { lat: 48.5600173, lng: 21.963242 },
    { lat: 48.5596513, lng: 21.9644499 },
    { lat: 48.5593465, lng: 21.9647743 },
    { lat: 48.5587142, lng: 21.9651327 },
    { lat: 48.5583509, lng: 21.9650907 },
    { lat: 48.5578602, lng: 21.9648961 },
    { lat: 48.5568851, lng: 21.9647313 },
    { lat: 48.5561034, lng: 21.9649128 },
    { lat: 48.5556069, lng: 21.9654429 },
    { lat: 48.555605, lng: 21.9661849 },
    { lat: 48.5561912, lng: 21.9666838 },
    { lat: 48.5564616, lng: 21.9667026 },
    { lat: 48.5570456, lng: 21.9662485 },
    { lat: 48.5572869, lng: 21.9665478 },
    { lat: 48.5572025, lng: 21.967043 },
    { lat: 48.5564924, lng: 21.9674019 },
    { lat: 48.5563341, lng: 21.9678625 },
    { lat: 48.5565938, lng: 21.9683997 },
    { lat: 48.5570947, lng: 21.9687295 },
    { lat: 48.5581411, lng: 21.9691862 },
    { lat: 48.5587794, lng: 21.9692941 },
    { lat: 48.5594019, lng: 21.9691319 },
    { lat: 48.560013, lng: 21.9690928 },
    { lat: 48.560328, lng: 21.9692758 },
    { lat: 48.5605216, lng: 21.9696406 },
    { lat: 48.5599415, lng: 21.9722019 },
    { lat: 48.5600926, lng: 21.9741676 },
    { lat: 48.5598935, lng: 21.9746202 },
    { lat: 48.5583007, lng: 21.9754044 },
    { lat: 48.5580823, lng: 21.9763538 },
    { lat: 48.558109, lng: 21.9767941 },
    { lat: 48.5583598, lng: 21.977469 },
    { lat: 48.5592761, lng: 21.9790405 },
    { lat: 48.559477, lng: 21.9792043 },
    { lat: 48.5600091, lng: 21.979292 },
    { lat: 48.5607752, lng: 21.979157 },
    { lat: 48.5618538, lng: 21.9785137 },
    { lat: 48.5626391, lng: 21.9782845 },
    { lat: 48.5631039, lng: 21.978294 },
    { lat: 48.5636225, lng: 21.9786516 },
    { lat: 48.5647225, lng: 21.9798269 },
    { lat: 48.5651789, lng: 21.9800393 },
    { lat: 48.5656299, lng: 21.9798119 },
    { lat: 48.5658318, lng: 21.9793275 },
    { lat: 48.5659172, lng: 21.9786933 },
    { lat: 48.5657938, lng: 21.9777917 },
    { lat: 48.565819, lng: 21.9772195 },
    { lat: 48.5664113, lng: 21.976788 },
    { lat: 48.5671723, lng: 21.9763983 },
    { lat: 48.5675201, lng: 21.9789563 },
    { lat: 48.5679164, lng: 21.9801105 },
    { lat: 48.5684934, lng: 21.9808333 },
    { lat: 48.5691762, lng: 21.9811878 },
    { lat: 48.5700386, lng: 21.9813085 },
    { lat: 48.570767, lng: 21.9809725 },
    { lat: 48.5721156, lng: 21.9809622 },
    { lat: 48.5724129, lng: 21.9805129 },
    { lat: 48.5730205, lng: 21.9793353 },
    { lat: 48.5732822, lng: 21.979051 },
    { lat: 48.5735845, lng: 21.9789889 },
    { lat: 48.5739104, lng: 21.9791525 },
    { lat: 48.5739852, lng: 21.9793671 },
    { lat: 48.5739999, lng: 21.9799137 },
    { lat: 48.5735665, lng: 21.9814065 },
    { lat: 48.5733631, lng: 21.9818663 },
    { lat: 48.5736395, lng: 21.9824426 },
    { lat: 48.5739642, lng: 21.9834304 },
    { lat: 48.5742702, lng: 21.983252 },
    { lat: 48.575359, lng: 21.9832854 },
    { lat: 48.5765841, lng: 21.9835617 },
    { lat: 48.5767475, lng: 21.983889 },
    { lat: 48.5767263, lng: 21.9845879 },
    { lat: 48.5757184, lng: 21.9859595 },
    { lat: 48.5750271, lng: 21.9857502 },
    { lat: 48.5747523, lng: 21.9860111 },
    { lat: 48.5751711, lng: 21.9878917 },
    { lat: 48.5754651, lng: 21.988687 },
    { lat: 48.5759957, lng: 21.9898567 },
    { lat: 48.57677, lng: 21.9912393 },
    { lat: 48.5772915, lng: 21.9920944 },
    { lat: 48.5778386, lng: 21.9928415 },
    { lat: 48.5785414, lng: 21.9941956 },
    { lat: 48.5789157, lng: 21.9947286 },
    { lat: 48.5793095, lng: 21.9950615 },
    { lat: 48.5799363, lng: 21.9951547 },
    { lat: 48.5806041, lng: 21.9947249 },
    { lat: 48.5810536, lng: 21.9940233 },
    { lat: 48.5815429, lng: 21.9927813 },
    { lat: 48.5817, lng: 21.9921251 },
    { lat: 48.5816013, lng: 21.991457 },
    { lat: 48.5816107, lng: 21.9909669 },
    { lat: 48.5814009, lng: 21.9905363 },
    { lat: 48.5823802, lng: 21.9896442 },
    { lat: 48.5827135, lng: 21.9893405 },
    { lat: 48.5844014, lng: 21.9889409 },
    { lat: 48.5851483, lng: 21.9886205 },
    { lat: 48.5858463, lng: 21.9880062 },
    { lat: 48.5866676, lng: 21.9868214 },
    { lat: 48.5877191, lng: 21.9854364 },
    { lat: 48.5887235, lng: 21.9846531 },
    { lat: 48.5895136, lng: 21.9845317 },
    { lat: 48.5913037, lng: 21.984914 },
    { lat: 48.5929826, lng: 21.9852495 },
    { lat: 48.5970191, lng: 21.9880441 },
    { lat: 48.5978295, lng: 21.9890625 },
    { lat: 48.5981653, lng: 21.9900441 },
    { lat: 48.5987582, lng: 21.9901794 },
    { lat: 48.5992636, lng: 21.990191 },
    { lat: 48.6007835, lng: 21.9896503 },
    { lat: 48.6023105, lng: 21.9882981 },
    { lat: 48.6025572, lng: 21.9882633 },
    { lat: 48.6027888, lng: 21.9883932 },
    { lat: 48.6028736, lng: 21.9886845 },
    { lat: 48.6026438, lng: 21.9902794 },
    { lat: 48.6026928, lng: 21.990802 },
    { lat: 48.6028957, lng: 21.9911365 },
    { lat: 48.6030534, lng: 21.9911949 },
    { lat: 48.6035713, lng: 21.9911172 },
    { lat: 48.6044882, lng: 21.9905288 },
    { lat: 48.6053151, lng: 21.9907538 },
    { lat: 48.6055892, lng: 21.99056 },
    { lat: 48.6056164, lng: 21.990155 },
    { lat: 48.605442, lng: 21.9892818 },
    { lat: 48.6054793, lng: 21.9889684 },
    { lat: 48.6059022, lng: 21.9881933 },
    { lat: 48.6065058, lng: 21.9875786 },
    { lat: 48.6067395, lng: 21.9870636 },
    { lat: 48.6069078, lng: 21.9869081 },
    { lat: 48.6074782, lng: 21.9867326 },
    { lat: 48.6083808, lng: 21.9860656 },
    { lat: 48.610201, lng: 21.9849661 },
    { lat: 48.6121651, lng: 21.9836874 },
    { lat: 48.6115651, lng: 21.9820303 },
    { lat: 48.6115345, lng: 21.9816979 },
    { lat: 48.6108342, lng: 21.9798101 },
    { lat: 48.6105867, lng: 21.978531 },
    { lat: 48.6102909, lng: 21.9779903 },
    { lat: 48.6098732, lng: 21.9774769 },
    { lat: 48.6097089, lng: 21.9774293 },
    { lat: 48.6096112, lng: 21.9772976 },
    { lat: 48.6092811, lng: 21.976325 },
    { lat: 48.6093188, lng: 21.9758462 },
    { lat: 48.6095337, lng: 21.9754801 },
    { lat: 48.6099136, lng: 21.9751512 },
    { lat: 48.611204, lng: 21.9745614 },
    { lat: 48.6134488, lng: 21.9745396 },
    { lat: 48.6136977, lng: 21.9747261 },
    { lat: 48.6137328, lng: 21.9744212 },
    { lat: 48.614055, lng: 21.9733511 },
    { lat: 48.6142878, lng: 21.9729878 },
    { lat: 48.6146362, lng: 21.9726041 },
    { lat: 48.6147471, lng: 21.9723625 },
    { lat: 48.6147766, lng: 21.9718678 },
    { lat: 48.6146316, lng: 21.971514 },
    { lat: 48.6145099, lng: 21.9713698 },
    { lat: 48.6143727, lng: 21.9708289 },
    { lat: 48.614388, lng: 21.9699421 },
    { lat: 48.6144614, lng: 21.9692312 },
    { lat: 48.6147627, lng: 21.9684044 },
    { lat: 48.6150879, lng: 21.9679551 },
    { lat: 48.615567, lng: 21.9678538 },
    { lat: 48.6162285, lng: 21.9675385 },
    { lat: 48.6164341, lng: 21.9675695 },
    { lat: 48.6166254, lng: 21.9674439 },
    { lat: 48.6169258, lng: 21.9668542 },
    { lat: 48.6169741, lng: 21.9665795 },
    { lat: 48.6169993, lng: 21.9652764 },
    { lat: 48.6168399, lng: 21.9648389 },
    { lat: 48.616793, lng: 21.9644028 },
    { lat: 48.6167129, lng: 21.9642622 },
    { lat: 48.6165561, lng: 21.9641922 },
    { lat: 48.6158861, lng: 21.9632601 },
    { lat: 48.6155835, lng: 21.9627229 },
    { lat: 48.6155375, lng: 21.9625424 },
    { lat: 48.6153388, lng: 21.9622401 },
    { lat: 48.6151143, lng: 21.9620846 },
    { lat: 48.6148556, lng: 21.962127 },
    { lat: 48.6144963, lng: 21.9620684 },
    { lat: 48.6143651, lng: 21.9619492 },
    { lat: 48.6139211, lng: 21.961825 },
    { lat: 48.6139687, lng: 21.9617089 },
    { lat: 48.6140277, lng: 21.9610457 },
    { lat: 48.614104, lng: 21.9608999 },
    { lat: 48.6150046, lng: 21.9598421 },
    { lat: 48.616338, lng: 21.9584541 },
    { lat: 48.6164173, lng: 21.9583813 },
    { lat: 48.6166451, lng: 21.958137 },
    { lat: 48.6168372, lng: 21.9577563 },
    { lat: 48.6170066, lng: 21.9570158 },
    { lat: 48.6171758, lng: 21.9567673 },
    { lat: 48.6175531, lng: 21.9565162 },
    { lat: 48.617896, lng: 21.956143 },
    { lat: 48.6181589, lng: 21.9561733 },
    { lat: 48.6182974, lng: 21.956095 },
    { lat: 48.6184298, lng: 21.9559368 },
    { lat: 48.6185171, lng: 21.9556467 },
    { lat: 48.6187632, lng: 21.955279 },
    { lat: 48.6186535, lng: 21.9545766 },
    { lat: 48.6182594, lng: 21.9539337 },
    { lat: 48.6179843, lng: 21.9538199 },
    { lat: 48.617884, lng: 21.953449 },
    { lat: 48.6177292, lng: 21.9532896 },
    { lat: 48.6176642, lng: 21.9530284 },
    { lat: 48.6172532, lng: 21.9524281 },
    { lat: 48.6170037, lng: 21.9515722 },
    { lat: 48.6165597, lng: 21.9510979 },
    { lat: 48.6164719, lng: 21.9510912 },
    { lat: 48.6162874, lng: 21.9509135 },
    { lat: 48.6158407, lng: 21.9502751 },
    { lat: 48.6157874, lng: 21.9500844 },
    { lat: 48.6157495, lng: 21.9495575 },
    { lat: 48.6155443, lng: 21.9489967 },
    { lat: 48.6146749, lng: 21.9476531 },
    { lat: 48.614015, lng: 21.9470869 },
    { lat: 48.6137678, lng: 21.9464653 },
    { lat: 48.6137283, lng: 21.9461401 },
    { lat: 48.6138421, lng: 21.944846 },
    { lat: 48.614171, lng: 21.9444618 },
    { lat: 48.6145572, lng: 21.9432111 },
    { lat: 48.6145505, lng: 21.9428119 },
    { lat: 48.6146075, lng: 21.9423955 },
    { lat: 48.6145236, lng: 21.9422265 },
    { lat: 48.6142325, lng: 21.9420585 },
    { lat: 48.6144947, lng: 21.9406286 },
    { lat: 48.6145363, lng: 21.9399067 },
    { lat: 48.6143989, lng: 21.9395881 },
    { lat: 48.6140052, lng: 21.9396336 },
    { lat: 48.613474, lng: 21.9399817 },
    { lat: 48.6131202, lng: 21.9403226 },
    { lat: 48.6127421, lng: 21.9404536 },
    { lat: 48.612228, lng: 21.9403133 },
    { lat: 48.6118078, lng: 21.9400176 },
    { lat: 48.6116093, lng: 21.9397825 },
    { lat: 48.6114475, lng: 21.9394299 },
    { lat: 48.611186, lng: 21.9393619 },
    { lat: 48.6107348, lng: 21.9394939 },
    { lat: 48.6105094, lng: 21.9396987 },
    { lat: 48.6099618, lng: 21.9398401 },
    { lat: 48.6102354, lng: 21.9394523 },
    { lat: 48.6101215, lng: 21.9391684 },
    { lat: 48.6095774, lng: 21.9390067 },
    { lat: 48.6093775, lng: 21.9390654 },
    { lat: 48.6091239, lng: 21.9396305 },
    { lat: 48.6088236, lng: 21.9401328 },
    { lat: 48.6081605, lng: 21.9400205 },
    { lat: 48.6077319, lng: 21.9398388 },
    { lat: 48.6072402, lng: 21.939795 },
    { lat: 48.607074, lng: 21.9398503 },
    { lat: 48.6068516, lng: 21.940046 },
    { lat: 48.606818, lng: 21.940135 },
    { lat: 48.6069397, lng: 21.9405507 },
    { lat: 48.6069858, lng: 21.9409981 },
    { lat: 48.6057957, lng: 21.9415888 },
    { lat: 48.6058606, lng: 21.9418442 },
    { lat: 48.6060383, lng: 21.9420687 },
    { lat: 48.6058106, lng: 21.9426348 },
    { lat: 48.6051757, lng: 21.9428394 },
    { lat: 48.6051884, lng: 21.9430754 },
    { lat: 48.6050412, lng: 21.9432224 },
    { lat: 48.6043984, lng: 21.943608 },
    { lat: 48.6036729, lng: 21.9435661 },
    { lat: 48.6030935, lng: 21.9437 },
    { lat: 48.6020678, lng: 21.9434356 },
    { lat: 48.601026, lng: 21.9435272 },
    { lat: 48.6005703, lng: 21.9433846 },
    { lat: 48.600214, lng: 21.9434941 },
    { lat: 48.5997604, lng: 21.9433535 },
    { lat: 48.5993783, lng: 21.9433148 },
    { lat: 48.5992135, lng: 21.943251 },
    { lat: 48.5990736, lng: 21.9430574 },
    { lat: 48.5991506, lng: 21.9422633 },
    { lat: 48.5985996, lng: 21.9418234 },
    { lat: 48.5983512, lng: 21.9417917 },
    { lat: 48.5983467, lng: 21.9417912 },
    { lat: 48.5981216, lng: 21.9417422 },
    { lat: 48.5975618, lng: 21.9422073 },
    { lat: 48.5971805, lng: 21.9430592 },
    { lat: 48.5968553, lng: 21.9435074 },
    { lat: 48.5964613, lng: 21.9446279 },
    { lat: 48.5963169, lng: 21.9452339 },
    { lat: 48.5962527, lng: 21.9459082 },
    { lat: 48.5964783, lng: 21.9463812 },
    { lat: 48.5958927, lng: 21.9468502 },
    { lat: 48.5958924, lng: 21.9469334 },
    { lat: 48.5953109, lng: 21.9472596 },
    { lat: 48.5949737, lng: 21.9473591 },
    { lat: 48.5948329, lng: 21.9485612 },
    { lat: 48.5945916, lng: 21.9487188 },
    { lat: 48.5938521, lng: 21.9499651 },
    { lat: 48.5936428, lng: 21.9500996 },
    { lat: 48.5930201, lng: 21.9500756 },
    { lat: 48.5925889, lng: 21.9502891 },
    { lat: 48.5914648, lng: 21.9505032 },
    { lat: 48.5909911, lng: 21.9509053 },
    { lat: 48.5904366, lng: 21.9512253 },
    { lat: 48.5900658, lng: 21.9516493 },
    { lat: 48.5899567, lng: 21.9519657 },
    { lat: 48.590016, lng: 21.9521791 },
    { lat: 48.590356, lng: 21.952823 },
    { lat: 48.5903443, lng: 21.9533478 },
    { lat: 48.5902705, lng: 21.953546 },
    { lat: 48.590121, lng: 21.9537307 },
    { lat: 48.5899385, lng: 21.9538046 },
    { lat: 48.5897688, lng: 21.9537705 },
    { lat: 48.5890722, lng: 21.9529959 },
    { lat: 48.5888703, lng: 21.9528876 },
    { lat: 48.5882333, lng: 21.9534123 },
    { lat: 48.5876871, lng: 21.9536593 },
    { lat: 48.5869695, lng: 21.9538026 },
    { lat: 48.5866123, lng: 21.9540908 },
    { lat: 48.5860477, lng: 21.9541874 },
    { lat: 48.5858494, lng: 21.9538611 },
    { lat: 48.5858409, lng: 21.9536551 },
    { lat: 48.58617, lng: 21.9529155 },
    { lat: 48.5863041, lng: 21.9524727 },
    { lat: 48.5862738, lng: 21.9517474 },
    { lat: 48.5862074, lng: 21.9514761 },
    { lat: 48.585875, lng: 21.9507868 },
    { lat: 48.5856359, lng: 21.9507105 },
    { lat: 48.585294, lng: 21.9508976 },
    { lat: 48.5849037, lng: 21.9513528 },
    { lat: 48.5843649, lng: 21.9518002 },
    { lat: 48.584067, lng: 21.9522178 },
    { lat: 48.5838254, lng: 21.9524554 },
    { lat: 48.5833983, lng: 21.95261 },
    { lat: 48.5823787, lng: 21.952504 },
    { lat: 48.5818494, lng: 21.9519818 },
    { lat: 48.5817422, lng: 21.9519648 },
    { lat: 48.5809567, lng: 21.9522755 },
    { lat: 48.5800459, lng: 21.9522425 },
    { lat: 48.5795853, lng: 21.9519997 },
    { lat: 48.5789199, lng: 21.9515304 },
    { lat: 48.5781755, lng: 21.9500961 },
    { lat: 48.5776394, lng: 21.9496703 },
    { lat: 48.5772582, lng: 21.9492763 },
    { lat: 48.5766343, lng: 21.9479852 },
    { lat: 48.5755327, lng: 21.9464885 },
    { lat: 48.5753962, lng: 21.9462038 },
    { lat: 48.5747852, lng: 21.946387 },
    { lat: 48.5745663, lng: 21.9466705 },
    { lat: 48.574159, lng: 21.9485136 },
    { lat: 48.5738732, lng: 21.949144 },
    { lat: 48.5731104, lng: 21.9499453 },
    { lat: 48.5725145, lng: 21.9502782 },
    { lat: 48.5718457, lng: 21.9503936 },
    { lat: 48.5717, lng: 21.9502765 },
    { lat: 48.5711129, lng: 21.9483944 },
    { lat: 48.5714542, lng: 21.9477797 },
    { lat: 48.5718338, lng: 21.9465859 },
    { lat: 48.5719938, lng: 21.9458305 },
    { lat: 48.5719843, lng: 21.9454498 },
    { lat: 48.5717824, lng: 21.9451999 },
    { lat: 48.5715281, lng: 21.9452861 },
    { lat: 48.5711962, lng: 21.9455607 },
    { lat: 48.5706279, lng: 21.9467719 },
    { lat: 48.5702514, lng: 21.9471858 },
    { lat: 48.5699418, lng: 21.9471366 },
    { lat: 48.5696086, lng: 21.9464529 },
    { lat: 48.5696184, lng: 21.9459881 },
    { lat: 48.5703024, lng: 21.9441909 },
    { lat: 48.5704782, lng: 21.9434367 },
    { lat: 48.5704456, lng: 21.9429893 },
    { lat: 48.5702072, lng: 21.9426302 },
    { lat: 48.5688796, lng: 21.9430765 },
    { lat: 48.5667944, lng: 21.9432799 },
    { lat: 48.5655638, lng: 21.9427883 },
    { lat: 48.5654038, lng: 21.9427825 },
  ],
  Ložín: [
    { lat: 48.6542875, lng: 21.7955536 },
    { lat: 48.6542191, lng: 21.7957317 },
    { lat: 48.6537097, lng: 21.7958216 },
    { lat: 48.6535932, lng: 21.7959293 },
    { lat: 48.6532643, lng: 21.7966919 },
    { lat: 48.6525814, lng: 21.7971622 },
    { lat: 48.6519566, lng: 21.7980023 },
    { lat: 48.6516963, lng: 21.7984609 },
    { lat: 48.6515504, lng: 21.7985155 },
    { lat: 48.6510513, lng: 21.798487 },
    { lat: 48.650698, lng: 21.7985746 },
    { lat: 48.649865, lng: 21.7990293 },
    { lat: 48.6494007, lng: 21.7991236 },
    { lat: 48.6492813, lng: 21.7992001 },
    { lat: 48.6491923, lng: 21.7994566 },
    { lat: 48.6490664, lng: 21.7995442 },
    { lat: 48.6483867, lng: 21.7996716 },
    { lat: 48.6482569, lng: 21.7997815 },
    { lat: 48.6480423, lng: 21.8001405 },
    { lat: 48.6471557, lng: 21.800688 },
    { lat: 48.64698, lng: 21.8007231 },
    { lat: 48.6464037, lng: 21.8004124 },
    { lat: 48.646355, lng: 21.8003189 },
    { lat: 48.6462191, lng: 21.7993436 },
    { lat: 48.6459316, lng: 21.7990143 },
    { lat: 48.6457576, lng: 21.7994214 },
    { lat: 48.6453667, lng: 21.7994828 },
    { lat: 48.6452737, lng: 21.7996648 },
    { lat: 48.6450669, lng: 21.7998526 },
    { lat: 48.6449573, lng: 21.7998278 },
    { lat: 48.6447646, lng: 21.7996436 },
    { lat: 48.6445914, lng: 21.7997817 },
    { lat: 48.645062, lng: 21.8002602 },
    { lat: 48.6453467, lng: 21.8007758 },
    { lat: 48.6473538, lng: 21.805892 },
    { lat: 48.6476373, lng: 21.8069239 },
    { lat: 48.6478198, lng: 21.8078338 },
    { lat: 48.6482175, lng: 21.80897 },
    { lat: 48.6488106, lng: 21.8110861 },
    { lat: 48.6491275, lng: 21.8119368 },
    { lat: 48.6498339, lng: 21.8141235 },
    { lat: 48.6502518, lng: 21.8157371 },
    { lat: 48.6504686, lng: 21.8168447 },
    { lat: 48.6512687, lng: 21.8220787 },
    { lat: 48.6510477, lng: 21.8282462 },
    { lat: 48.6510929, lng: 21.8295981 },
    { lat: 48.6516224, lng: 21.8331771 },
    { lat: 48.6525816, lng: 21.8365466 },
    { lat: 48.6535487, lng: 21.8402153 },
    { lat: 48.6540564, lng: 21.8427107 },
    { lat: 48.6539997, lng: 21.8427304 },
    { lat: 48.6541987, lng: 21.8440352 },
    { lat: 48.6547441, lng: 21.8462498 },
    { lat: 48.6559968, lng: 21.8526316 },
    { lat: 48.6564266, lng: 21.8553869 },
    { lat: 48.656503, lng: 21.8555981 },
    { lat: 48.6586567, lng: 21.854875 },
    { lat: 48.6589135, lng: 21.8547578 },
    { lat: 48.6604757, lng: 21.8539931 },
    { lat: 48.6615592, lng: 21.8532527 },
    { lat: 48.661781, lng: 21.8539639 },
    { lat: 48.6627845, lng: 21.8534423 },
    { lat: 48.662841, lng: 21.8536664 },
    { lat: 48.6638772, lng: 21.8533571 },
    { lat: 48.665492, lng: 21.8530267 },
    { lat: 48.6653997, lng: 21.8545134 },
    { lat: 48.6684704, lng: 21.8542257 },
    { lat: 48.669287, lng: 21.8540062 },
    { lat: 48.6697381, lng: 21.8521232 },
    { lat: 48.671434, lng: 21.8516181 },
    { lat: 48.6720948, lng: 21.8516839 },
    { lat: 48.6722754, lng: 21.8516089 },
    { lat: 48.6726673, lng: 21.8511925 },
    { lat: 48.673523, lng: 21.8506076 },
    { lat: 48.6737505, lng: 21.8506615 },
    { lat: 48.6745969, lng: 21.8505007 },
    { lat: 48.6749317, lng: 21.8512243 },
    { lat: 48.6751355, lng: 21.851884 },
    { lat: 48.6759019, lng: 21.8515923 },
    { lat: 48.6766743, lng: 21.8510934 },
    { lat: 48.6763995, lng: 21.8495981 },
    { lat: 48.6753485, lng: 21.8437977 },
    { lat: 48.6742677, lng: 21.8381521 },
    { lat: 48.6739865, lng: 21.8371161 },
    { lat: 48.6736284, lng: 21.8360884 },
    { lat: 48.6729365, lng: 21.834103 },
    { lat: 48.6708461, lng: 21.8286361 },
    { lat: 48.6706238, lng: 21.8282289 },
    { lat: 48.6697877, lng: 21.8270052 },
    { lat: 48.667439, lng: 21.8227317 },
    { lat: 48.6675043, lng: 21.8226509 },
    { lat: 48.6664302, lng: 21.8204108 },
    { lat: 48.6646499, lng: 21.8147833 },
    { lat: 48.6647568, lng: 21.8118603 },
    { lat: 48.665044, lng: 21.8105829 },
    { lat: 48.6646462, lng: 21.8092624 },
    { lat: 48.6642928, lng: 21.8083054 },
    { lat: 48.6629412, lng: 21.8052392 },
    { lat: 48.6625719, lng: 21.8038949 },
    { lat: 48.6624337, lng: 21.8039221 },
    { lat: 48.6619607, lng: 21.804015 },
    { lat: 48.6606698, lng: 21.7981653 },
    { lat: 48.6600727, lng: 21.7984722 },
    { lat: 48.6598866, lng: 21.798416 },
    { lat: 48.6592793, lng: 21.7977766 },
    { lat: 48.6589792, lng: 21.7977874 },
    { lat: 48.6589262, lng: 21.7973582 },
    { lat: 48.6589946, lng: 21.7969648 },
    { lat: 48.6589576, lng: 21.7967499 },
    { lat: 48.6588954, lng: 21.7966511 },
    { lat: 48.6588002, lng: 21.7966255 },
    { lat: 48.6586957, lng: 21.7967129 },
    { lat: 48.6584238, lng: 21.7970701 },
    { lat: 48.6578429, lng: 21.7971073 },
    { lat: 48.6572013, lng: 21.7972649 },
    { lat: 48.6569814, lng: 21.7970681 },
    { lat: 48.6566725, lng: 21.7971275 },
    { lat: 48.6557018, lng: 21.7964141 },
    { lat: 48.6550637, lng: 21.7958082 },
    { lat: 48.6542875, lng: 21.7955536 },
  ],
  MaléRaškovce: [
    { lat: 48.5853562, lng: 21.8691473 },
    { lat: 48.5838675, lng: 21.8699238 },
    { lat: 48.5829382, lng: 21.8703184 },
    { lat: 48.5829099, lng: 21.8712364 },
    { lat: 48.5779279, lng: 21.871268 },
    { lat: 48.5778985, lng: 21.8707286 },
    { lat: 48.5759632, lng: 21.8719008 },
    { lat: 48.5751704, lng: 21.8698996 },
    { lat: 48.5741485, lng: 21.8711592 },
    { lat: 48.5739584, lng: 21.8714705 },
    { lat: 48.5738736, lng: 21.8714442 },
    { lat: 48.5738667, lng: 21.8714417 },
    { lat: 48.5736751, lng: 21.8720888 },
    { lat: 48.5733455, lng: 21.8728789 },
    { lat: 48.5735939, lng: 21.8744017 },
    { lat: 48.573308, lng: 21.8910338 },
    { lat: 48.5728752, lng: 21.8911377 },
    { lat: 48.5727715, lng: 21.8958603 },
    { lat: 48.5729934, lng: 21.8974285 },
    { lat: 48.5731955, lng: 21.8988568 },
    { lat: 48.573289, lng: 21.8992617 },
    { lat: 48.5739855, lng: 21.900909 },
    { lat: 48.5740588, lng: 21.9011783 },
    { lat: 48.5741206, lng: 21.902108 },
    { lat: 48.5740418, lng: 21.9030774 },
    { lat: 48.5740563, lng: 21.9037416 },
    { lat: 48.5741202, lng: 21.9043116 },
    { lat: 48.5742947, lng: 21.905065 },
    { lat: 48.5740033, lng: 21.9052857 },
    { lat: 48.5731463, lng: 21.9057407 },
    { lat: 48.5734845, lng: 21.9086116 },
    { lat: 48.5737108, lng: 21.9112363 },
    { lat: 48.5720102, lng: 21.9115613 },
    { lat: 48.5694535, lng: 21.9118951 },
    { lat: 48.5699269, lng: 21.9153917 },
    { lat: 48.570249, lng: 21.9154496 },
    { lat: 48.5710292, lng: 21.915251 },
    { lat: 48.5711537, lng: 21.915328 },
    { lat: 48.5719857, lng: 21.9159712 },
    { lat: 48.5723074, lng: 21.9163801 },
    { lat: 48.5728795, lng: 21.9168446 },
    { lat: 48.5730342, lng: 21.9170883 },
    { lat: 48.573164, lng: 21.9175061 },
    { lat: 48.573139, lng: 21.9179921 },
    { lat: 48.5730748, lng: 21.9182935 },
    { lat: 48.5728395, lng: 21.9185546 },
    { lat: 48.572439, lng: 21.9186514 },
    { lat: 48.5716106, lng: 21.919031 },
    { lat: 48.5714552, lng: 21.9193394 },
    { lat: 48.5714761, lng: 21.9197673 },
    { lat: 48.5716181, lng: 21.9201318 },
    { lat: 48.5719236, lng: 21.9206475 },
    { lat: 48.5720822, lng: 21.9210921 },
    { lat: 48.5721055, lng: 21.9215188 },
    { lat: 48.57206, lng: 21.9217715 },
    { lat: 48.5719074, lng: 21.9220982 },
    { lat: 48.5717156, lng: 21.9222594 },
    { lat: 48.5714884, lng: 21.9222639 },
    { lat: 48.571133, lng: 21.9220402 },
    { lat: 48.5708825, lng: 21.9219593 },
    { lat: 48.5688278, lng: 21.9225253 },
    { lat: 48.5684485, lng: 21.9224312 },
    { lat: 48.5683539, lng: 21.9228312 },
    { lat: 48.5684992, lng: 21.9231877 },
    { lat: 48.5686863, lng: 21.9234732 },
    { lat: 48.5691291, lng: 21.9236379 },
    { lat: 48.5692333, lng: 21.9237943 },
    { lat: 48.5692931, lng: 21.9240271 },
    { lat: 48.5693371, lng: 21.9243429 },
    { lat: 48.5692386, lng: 21.925119 },
    { lat: 48.5691713, lng: 21.926246 },
    { lat: 48.5705143, lng: 21.9257508 },
    { lat: 48.5707042, lng: 21.9266716 },
    { lat: 48.5707379, lng: 21.9272079 },
    { lat: 48.570644, lng: 21.9284402 },
    { lat: 48.5706749, lng: 21.9286821 },
    { lat: 48.5710285, lng: 21.9299031 },
    { lat: 48.569123, lng: 21.9377096 },
    { lat: 48.5690711, lng: 21.9377932 },
    { lat: 48.5687945, lng: 21.9374413 },
    { lat: 48.5687519, lng: 21.9375745 },
    { lat: 48.5686843, lng: 21.9374863 },
    { lat: 48.5687295, lng: 21.9373594 },
    { lat: 48.5685628, lng: 21.9371693 },
    { lat: 48.5685152, lng: 21.9371152 },
    { lat: 48.5683926, lng: 21.9374724 },
    { lat: 48.5679654, lng: 21.9380249 },
    { lat: 48.5668086, lng: 21.9387291 },
    { lat: 48.5661198, lng: 21.9395199 },
    { lat: 48.5657756, lng: 21.9402424 },
    { lat: 48.5655622, lng: 21.9410221 },
    { lat: 48.565477, lng: 21.9414798 },
    { lat: 48.5654081, lng: 21.9423692 },
    { lat: 48.5654038, lng: 21.9427825 },
    { lat: 48.5655638, lng: 21.9427883 },
    { lat: 48.5667944, lng: 21.9432799 },
    { lat: 48.5688796, lng: 21.9430765 },
    { lat: 48.5702072, lng: 21.9426302 },
    { lat: 48.5704456, lng: 21.9429893 },
    { lat: 48.5704782, lng: 21.9434367 },
    { lat: 48.5703024, lng: 21.9441909 },
    { lat: 48.5696184, lng: 21.9459881 },
    { lat: 48.5696086, lng: 21.9464529 },
    { lat: 48.5699418, lng: 21.9471366 },
    { lat: 48.5702514, lng: 21.9471858 },
    { lat: 48.5706279, lng: 21.9467719 },
    { lat: 48.5711962, lng: 21.9455607 },
    { lat: 48.5715281, lng: 21.9452861 },
    { lat: 48.5717824, lng: 21.9451999 },
    { lat: 48.5719843, lng: 21.9454498 },
    { lat: 48.5719938, lng: 21.9458305 },
    { lat: 48.5718338, lng: 21.9465859 },
    { lat: 48.5714542, lng: 21.9477797 },
    { lat: 48.5711129, lng: 21.9483944 },
    { lat: 48.5717, lng: 21.9502765 },
    { lat: 48.5718457, lng: 21.9503936 },
    { lat: 48.5725145, lng: 21.9502782 },
    { lat: 48.5731104, lng: 21.9499453 },
    { lat: 48.5738732, lng: 21.949144 },
    { lat: 48.574159, lng: 21.9485136 },
    { lat: 48.5745663, lng: 21.9466705 },
    { lat: 48.5747852, lng: 21.946387 },
    { lat: 48.5753962, lng: 21.9462038 },
    { lat: 48.5756063, lng: 21.9461024 },
    { lat: 48.5762384, lng: 21.946501 },
    { lat: 48.5770708, lng: 21.9465034 },
    { lat: 48.5781781, lng: 21.9466431 },
    { lat: 48.5782768, lng: 21.9467732 },
    { lat: 48.5786506, lng: 21.947007 },
    { lat: 48.5793342, lng: 21.9473065 },
    { lat: 48.5803971, lng: 21.9470312 },
    { lat: 48.5808887, lng: 21.9463101 },
    { lat: 48.5813284, lng: 21.9460378 },
    { lat: 48.581707, lng: 21.9459705 },
    { lat: 48.5818845, lng: 21.9458666 },
    { lat: 48.5824795, lng: 21.9450558 },
    { lat: 48.5820564, lng: 21.9437467 },
    { lat: 48.5819598, lng: 21.942988 },
    { lat: 48.5813328, lng: 21.9429703 },
    { lat: 48.5808419, lng: 21.9406629 },
    { lat: 48.580757, lng: 21.940461 },
    { lat: 48.5806882, lng: 21.9399639 },
    { lat: 48.5804944, lng: 21.9397284 },
    { lat: 48.5803276, lng: 21.9387267 },
    { lat: 48.5801445, lng: 21.9383343 },
    { lat: 48.5799701, lng: 21.9380653 },
    { lat: 48.5798484, lng: 21.9379688 },
    { lat: 48.5798661, lng: 21.9370118 },
    { lat: 48.5800687, lng: 21.9363856 },
    { lat: 48.5801603, lng: 21.9359128 },
    { lat: 48.5808935, lng: 21.9343809 },
    { lat: 48.5809332, lng: 21.9339427 },
    { lat: 48.5807147, lng: 21.9338894 },
    { lat: 48.580807, lng: 21.9328147 },
    { lat: 48.5816775, lng: 21.9322673 },
    { lat: 48.5816258, lng: 21.9315525 },
    { lat: 48.5820705, lng: 21.930543 },
    { lat: 48.5822848, lng: 21.9299335 },
    { lat: 48.5824023, lng: 21.9293757 },
    { lat: 48.5829054, lng: 21.9293853 },
    { lat: 48.5831662, lng: 21.9291896 },
    { lat: 48.5831456, lng: 21.9283369 },
    { lat: 48.5827881, lng: 21.9280781 },
    { lat: 48.5830667, lng: 21.9271686 },
    { lat: 48.5831629, lng: 21.9266074 },
    { lat: 48.5830927, lng: 21.9259153 },
    { lat: 48.5830923, lng: 21.9253653 },
    { lat: 48.5836809, lng: 21.925149 },
    { lat: 48.5846189, lng: 21.9246516 },
    { lat: 48.585249, lng: 21.9246521 },
    { lat: 48.5858607, lng: 21.9245098 },
    { lat: 48.586582, lng: 21.9251891 },
    { lat: 48.5871769, lng: 21.9255762 },
    { lat: 48.5868278, lng: 21.9209686 },
    { lat: 48.5867302, lng: 21.9203133 },
    { lat: 48.5869494, lng: 21.9202176 },
    { lat: 48.586859, lng: 21.9195671 },
    { lat: 48.5860509, lng: 21.9167964 },
    { lat: 48.5858164, lng: 21.9158384 },
    { lat: 48.5870392, lng: 21.9146343 },
    { lat: 48.5866308, lng: 21.9133896 },
    { lat: 48.5860796, lng: 21.9105011 },
    { lat: 48.5869142, lng: 21.9101386 },
    { lat: 48.586251, lng: 21.9069197 },
    { lat: 48.587489, lng: 21.9065418 },
    { lat: 48.5872379, lng: 21.9044025 },
    { lat: 48.5870266, lng: 21.9036532 },
    { lat: 48.586738, lng: 21.9029201 },
    { lat: 48.587249, lng: 21.9024898 },
    { lat: 48.5874866, lng: 21.9024132 },
    { lat: 48.5876123, lng: 21.9027968 },
    { lat: 48.5879932, lng: 21.9025126 },
    { lat: 48.586803, lng: 21.8999005 },
    { lat: 48.5884466, lng: 21.898433 },
    { lat: 48.5893869, lng: 21.8985137 },
    { lat: 48.5896538, lng: 21.8983117 },
    { lat: 48.5892241, lng: 21.8964478 },
    { lat: 48.5887225, lng: 21.8938926 },
    { lat: 48.5883966, lng: 21.8925384 },
    { lat: 48.5883518, lng: 21.8918814 },
    { lat: 48.5883406, lng: 21.8893246 },
    { lat: 48.5882844, lng: 21.888534 },
    { lat: 48.5881978, lng: 21.8836795 },
    { lat: 48.5880834, lng: 21.8803025 },
    { lat: 48.5879896, lng: 21.8795372 },
    { lat: 48.5879078, lng: 21.879162 },
    { lat: 48.5874683, lng: 21.8779442 },
    { lat: 48.5868884, lng: 21.8765185 },
    { lat: 48.5870638, lng: 21.876379 },
    { lat: 48.5859559, lng: 21.873401 },
    { lat: 48.5854312, lng: 21.8722013 },
    { lat: 48.5860108, lng: 21.8714252 },
    { lat: 48.5857983, lng: 21.8706868 },
    { lat: 48.5853562, lng: 21.8691473 },
  ],
  Kusín: [
    { lat: 48.7934661, lng: 22.0611151 },
    { lat: 48.7943049, lng: 22.0618619 },
    { lat: 48.7951133, lng: 22.0624613 },
    { lat: 48.7959519, lng: 22.063236 },
    { lat: 48.79619, lng: 22.0635426 },
    { lat: 48.7964793, lng: 22.0642179 },
    { lat: 48.7967238, lng: 22.065079 },
    { lat: 48.7978926, lng: 22.0667366 },
    { lat: 48.798998, lng: 22.0676585 },
    { lat: 48.799733, lng: 22.0685799 },
    { lat: 48.8002689, lng: 22.0693959 },
    { lat: 48.8033347, lng: 22.0697102 },
    { lat: 48.8037972, lng: 22.070136 },
    { lat: 48.804819, lng: 22.0701163 },
    { lat: 48.8048777, lng: 22.0708778 },
    { lat: 48.8045969, lng: 22.0731623 },
    { lat: 48.804219, lng: 22.0738245 },
    { lat: 48.8049123, lng: 22.0735626 },
    { lat: 48.8052613, lng: 22.073854 },
    { lat: 48.8052892, lng: 22.0742261 },
    { lat: 48.8056563, lng: 22.0745772 },
    { lat: 48.8059039, lng: 22.0751925 },
    { lat: 48.8065058, lng: 22.0743475 },
    { lat: 48.8067884, lng: 22.0740694 },
    { lat: 48.8073379, lng: 22.0741408 },
    { lat: 48.8074822, lng: 22.0748645 },
    { lat: 48.8079919, lng: 22.0743567 },
    { lat: 48.8084503, lng: 22.0735688 },
    { lat: 48.8089905, lng: 22.073914 },
    { lat: 48.8098646, lng: 22.0749099 },
    { lat: 48.8118393, lng: 22.0743718 },
    { lat: 48.8129847, lng: 22.0737691 },
    { lat: 48.8136718, lng: 22.0738098 },
    { lat: 48.8147968, lng: 22.0730146 },
    { lat: 48.8160815, lng: 22.0714151 },
    { lat: 48.8162576, lng: 22.0714862 },
    { lat: 48.8163362, lng: 22.0713406 },
    { lat: 48.816445, lng: 22.0715134 },
    { lat: 48.816602, lng: 22.0713096 },
    { lat: 48.8167931, lng: 22.0713927 },
    { lat: 48.8169401, lng: 22.0713322 },
    { lat: 48.8172414, lng: 22.0718505 },
    { lat: 48.8174411, lng: 22.0719606 },
    { lat: 48.8179218, lng: 22.0720721 },
    { lat: 48.8181364, lng: 22.0719697 },
    { lat: 48.8183309, lng: 22.0720087 },
    { lat: 48.8187877, lng: 22.07178 },
    { lat: 48.8188486, lng: 22.0715575 },
    { lat: 48.819206, lng: 22.0713513 },
    { lat: 48.8192885, lng: 22.0709877 },
    { lat: 48.819339, lng: 22.0709379 },
    { lat: 48.8194534, lng: 22.070923 },
    { lat: 48.8195382, lng: 22.0710794 },
    { lat: 48.8196268, lng: 22.0711174 },
    { lat: 48.8198846, lng: 22.0711233 },
    { lat: 48.8202677, lng: 22.0712955 },
    { lat: 48.8205325, lng: 22.0707535 },
    { lat: 48.8206684, lng: 22.0706907 },
    { lat: 48.8211697, lng: 22.0709175 },
    { lat: 48.8212109, lng: 22.0712719 },
    { lat: 48.821629, lng: 22.0713972 },
    { lat: 48.821941, lng: 22.0711385 },
    { lat: 48.8224939, lng: 22.0714428 },
    { lat: 48.8229368, lng: 22.0714325 },
    { lat: 48.8236279, lng: 22.071639 },
    { lat: 48.8238257, lng: 22.0719166 },
    { lat: 48.8239331, lng: 22.0719432 },
    { lat: 48.8246809, lng: 22.0717418 },
    { lat: 48.8258135, lng: 22.071645 },
    { lat: 48.8265833, lng: 22.0714259 },
    { lat: 48.8267907, lng: 22.0712536 },
    { lat: 48.8276109, lng: 22.0709239 },
    { lat: 48.8283933, lng: 22.0707719 },
    { lat: 48.8288828, lng: 22.0705396 },
    { lat: 48.8295463, lng: 22.0704426 },
    { lat: 48.8357427, lng: 22.0701485 },
    { lat: 48.8412788, lng: 22.0712676 },
    { lat: 48.846993, lng: 22.071657 },
    { lat: 48.846902, lng: 22.071181 },
    { lat: 48.846613, lng: 22.069665 },
    { lat: 48.846019, lng: 22.066763 },
    { lat: 48.846004, lng: 22.066691 },
    { lat: 48.845943, lng: 22.066388 },
    { lat: 48.845932, lng: 22.066336 },
    { lat: 48.845696, lng: 22.06519 },
    { lat: 48.845676, lng: 22.065093 },
    { lat: 48.845582, lng: 22.064635 },
    { lat: 48.845384, lng: 22.06371 },
    { lat: 48.845079, lng: 22.062278 },
    { lat: 48.844986, lng: 22.061824 },
    { lat: 48.844971, lng: 22.061746 },
    { lat: 48.844415, lng: 22.059025 },
    { lat: 48.844103, lng: 22.057485 },
    { lat: 48.844092, lng: 22.05745 },
    { lat: 48.843947, lng: 22.056506 },
    { lat: 48.84379, lng: 22.055653 },
    { lat: 48.843758, lng: 22.055478 },
    { lat: 48.843651, lng: 22.054887 },
    { lat: 48.843487, lng: 22.05413 },
    { lat: 48.843473, lng: 22.054063 },
    { lat: 48.843344, lng: 22.05346 },
    { lat: 48.843318, lng: 22.053338 },
    { lat: 48.843193, lng: 22.052801 },
    { lat: 48.843158, lng: 22.052647 },
    { lat: 48.843039, lng: 22.052133 },
    { lat: 48.842878, lng: 22.051436 },
    { lat: 48.842512, lng: 22.049945 },
    { lat: 48.842426, lng: 22.04952 },
    { lat: 48.842406, lng: 22.04942 },
    { lat: 48.842083, lng: 22.047836 },
    { lat: 48.84207, lng: 22.047793 },
    { lat: 48.84179, lng: 22.046373 },
    { lat: 48.841419, lng: 22.044515 },
    { lat: 48.841182, lng: 22.043392 },
    { lat: 48.840972, lng: 22.042387 },
    { lat: 48.840841, lng: 22.041659 },
    { lat: 48.840511, lng: 22.039897 },
    { lat: 48.840133, lng: 22.038077 },
    { lat: 48.840102, lng: 22.037592 },
    { lat: 48.8385438, lng: 22.0384469 },
    { lat: 48.8383363, lng: 22.0386576 },
    { lat: 48.8378435, lng: 22.0388377 },
    { lat: 48.8306218, lng: 22.042808 },
    { lat: 48.8279681, lng: 22.0443452 },
    { lat: 48.8269745, lng: 22.0443197 },
    { lat: 48.8250485, lng: 22.0450163 },
    { lat: 48.8212779, lng: 22.0466969 },
    { lat: 48.8188174, lng: 22.0477155 },
    { lat: 48.8135996, lng: 22.0501666 },
    { lat: 48.8128365, lng: 22.0501936 },
    { lat: 48.8115092, lng: 22.0511727 },
    { lat: 48.8105386, lng: 22.0519848 },
    { lat: 48.8099318, lng: 22.0523833 },
    { lat: 48.8090956, lng: 22.0522711 },
    { lat: 48.8088047, lng: 22.0525244 },
    { lat: 48.8086307, lng: 22.0529155 },
    { lat: 48.807889, lng: 22.0525651 },
    { lat: 48.8073769, lng: 22.0520401 },
    { lat: 48.8062267, lng: 22.0514995 },
    { lat: 48.8060672, lng: 22.0512767 },
    { lat: 48.8008613, lng: 22.0507089 },
    { lat: 48.7999992, lng: 22.0505494 },
    { lat: 48.796268, lng: 22.0496142 },
    { lat: 48.7934661, lng: 22.0611151 },
  ],
  Oreské: [
    { lat: 48.852575, lng: 21.946902 },
    { lat: 48.853315, lng: 21.94719 },
    { lat: 48.853894, lng: 21.947415 },
    { lat: 48.85463, lng: 21.947702 },
    { lat: 48.855817, lng: 21.947341 },
    { lat: 48.856419, lng: 21.947113 },
    { lat: 48.857538, lng: 21.947067 },
    { lat: 48.857773, lng: 21.946481 },
    { lat: 48.858109, lng: 21.946082 },
    { lat: 48.858456, lng: 21.945842 },
    { lat: 48.858476, lng: 21.94567 },
    { lat: 48.858694, lng: 21.945477 },
    { lat: 48.859147, lng: 21.94525 },
    { lat: 48.859215, lng: 21.945033 },
    { lat: 48.859569, lng: 21.944959 },
    { lat: 48.859632, lng: 21.944828 },
    { lat: 48.859801, lng: 21.944743 },
    { lat: 48.860033, lng: 21.944851 },
    { lat: 48.860486, lng: 21.944617 },
    { lat: 48.86062, lng: 21.944515 },
    { lat: 48.860691, lng: 21.944461 },
    { lat: 48.860871, lng: 21.944323 },
    { lat: 48.861097, lng: 21.943949 },
    { lat: 48.861454, lng: 21.943631 },
    { lat: 48.861582, lng: 21.943622 },
    { lat: 48.862568, lng: 21.944277 },
    { lat: 48.862595, lng: 21.944295 },
    { lat: 48.863259, lng: 21.944736 },
    { lat: 48.864483, lng: 21.944885 },
    { lat: 48.865158, lng: 21.94378 },
    { lat: 48.865667, lng: 21.943959 },
    { lat: 48.865559, lng: 21.944489 },
    { lat: 48.866295, lng: 21.944863 },
    { lat: 48.866674, lng: 21.94524 },
    { lat: 48.866964, lng: 21.945185 },
    { lat: 48.867225, lng: 21.945256 },
    { lat: 48.86753, lng: 21.945368 },
    { lat: 48.867998, lng: 21.945284 },
    { lat: 48.868404, lng: 21.945071 },
    { lat: 48.868602, lng: 21.945003 },
    { lat: 48.868833, lng: 21.945308 },
    { lat: 48.868738, lng: 21.945784 },
    { lat: 48.868903, lng: 21.945857 },
    { lat: 48.869661, lng: 21.944727 },
    { lat: 48.870265, lng: 21.943759 },
    { lat: 48.87024, lng: 21.943007 },
    { lat: 48.870355, lng: 21.942341 },
    { lat: 48.870862, lng: 21.941183 },
    { lat: 48.870871, lng: 21.941095 },
    { lat: 48.870926, lng: 21.940556 },
    { lat: 48.870999, lng: 21.940022 },
    { lat: 48.871069, lng: 21.939509 },
    { lat: 48.871383, lng: 21.938164 },
    { lat: 48.871503, lng: 21.936746 },
    { lat: 48.871453, lng: 21.936005 },
    { lat: 48.871437, lng: 21.935596 },
    { lat: 48.871438, lng: 21.934543 },
    { lat: 48.871492, lng: 21.933841 },
    { lat: 48.871529, lng: 21.933175 },
    { lat: 48.871534, lng: 21.933064 },
    { lat: 48.871509, lng: 21.93197 },
    { lat: 48.871535, lng: 21.931276 },
    { lat: 48.871988, lng: 21.930724 },
    { lat: 48.87273, lng: 21.930185 },
    { lat: 48.873435, lng: 21.929391 },
    { lat: 48.87379, lng: 21.928773 },
    { lat: 48.87383, lng: 21.927985 },
    { lat: 48.874042, lng: 21.927302 },
    { lat: 48.874267, lng: 21.926057 },
    { lat: 48.874271, lng: 21.926035 },
    { lat: 48.874319, lng: 21.925195 },
    { lat: 48.874268, lng: 21.924364 },
    { lat: 48.874572, lng: 21.923311 },
    { lat: 48.875124, lng: 21.92217 },
    { lat: 48.875394, lng: 21.921448 },
    { lat: 48.875694, lng: 21.921305 },
    { lat: 48.876144, lng: 21.921063 },
    { lat: 48.876541, lng: 21.920258 },
    { lat: 48.87682, lng: 21.918951 },
    { lat: 48.877299, lng: 21.918165 },
    { lat: 48.877906, lng: 21.917997 },
    { lat: 48.87874, lng: 21.917579 },
    { lat: 48.879713, lng: 21.916654 },
    { lat: 48.880112, lng: 21.916147 },
    { lat: 48.880534, lng: 21.914879 },
    { lat: 48.88084, lng: 21.914374 },
    { lat: 48.881186, lng: 21.913756 },
    { lat: 48.881485, lng: 21.912863 },
    { lat: 48.881678, lng: 21.911993 },
    { lat: 48.881668, lng: 21.911221 },
    { lat: 48.881657, lng: 21.910494 },
    { lat: 48.881553, lng: 21.910035 },
    { lat: 48.88167, lng: 21.909101 },
    { lat: 48.881956, lng: 21.908579 },
    { lat: 48.882104, lng: 21.90709 },
    { lat: 48.88226, lng: 21.906846 },
    { lat: 48.882318, lng: 21.906462 },
    { lat: 48.882206, lng: 21.905933 },
    { lat: 48.8822, lng: 21.905082 },
    { lat: 48.882204, lng: 21.904667 },
    { lat: 48.882136, lng: 21.904354 },
    { lat: 48.882085, lng: 21.904023 },
    { lat: 48.882239, lng: 21.903366 },
    { lat: 48.882419, lng: 21.902934 },
    { lat: 48.882757, lng: 21.902514 },
    { lat: 48.883171, lng: 21.902283 },
    { lat: 48.883504, lng: 21.902054 },
    { lat: 48.883912, lng: 21.901261 },
    { lat: 48.884213, lng: 21.900408 },
    { lat: 48.885008, lng: 21.899419 },
    { lat: 48.8849603, lng: 21.8993642 },
    { lat: 48.8845559, lng: 21.8992011 },
    { lat: 48.8842052, lng: 21.8992129 },
    { lat: 48.8832028, lng: 21.8985427 },
    { lat: 48.8828731, lng: 21.8985375 },
    { lat: 48.8826136, lng: 21.8983324 },
    { lat: 48.8824482, lng: 21.8983738 },
    { lat: 48.8822658, lng: 21.8986386 },
    { lat: 48.8821761, lng: 21.898999 },
    { lat: 48.8817129, lng: 21.899948 },
    { lat: 48.8815602, lng: 21.900448 },
    { lat: 48.8812167, lng: 21.90092 },
    { lat: 48.8811121, lng: 21.9011949 },
    { lat: 48.8807659, lng: 21.9014662 },
    { lat: 48.8799111, lng: 21.9017838 },
    { lat: 48.878864, lng: 21.9017091 },
    { lat: 48.8762967, lng: 21.900718 },
    { lat: 48.8760489, lng: 21.9007896 },
    { lat: 48.8758553, lng: 21.9010974 },
    { lat: 48.8756875, lng: 21.9011354 },
    { lat: 48.8753163, lng: 21.9014089 },
    { lat: 48.8752623, lng: 21.9013623 },
    { lat: 48.8750924, lng: 21.9014022 },
    { lat: 48.874748, lng: 21.9013525 },
    { lat: 48.874528, lng: 21.9015461 },
    { lat: 48.8733182, lng: 21.9017513 },
    { lat: 48.8698746, lng: 21.9017452 },
    { lat: 48.8686575, lng: 21.9018826 },
    { lat: 48.8673443, lng: 21.9004834 },
    { lat: 48.8666633, lng: 21.8992163 },
    { lat: 48.8661062, lng: 21.898896 },
    { lat: 48.8641074, lng: 21.8984171 },
    { lat: 48.8625427, lng: 21.8981478 },
    { lat: 48.8621764, lng: 21.8979886 },
    { lat: 48.8621952, lng: 21.8972626 },
    { lat: 48.8608093, lng: 21.8966447 },
    { lat: 48.8599489, lng: 21.8966078 },
    { lat: 48.8597866, lng: 21.89632 },
    { lat: 48.8595054, lng: 21.8963239 },
    { lat: 48.8594366, lng: 21.8964904 },
    { lat: 48.8591297, lng: 21.8963728 },
    { lat: 48.858987, lng: 21.8961535 },
    { lat: 48.858824, lng: 21.8960287 },
    { lat: 48.8586776, lng: 21.8960626 },
    { lat: 48.8585844, lng: 21.8959776 },
    { lat: 48.8583313, lng: 21.8959787 },
    { lat: 48.8582135, lng: 21.8962142 },
    { lat: 48.8577926, lng: 21.8962926 },
    { lat: 48.857441, lng: 21.8965877 },
    { lat: 48.8574078, lng: 21.8958516 },
    { lat: 48.8570933, lng: 21.8954226 },
    { lat: 48.8563122, lng: 21.893899 },
    { lat: 48.8562118, lng: 21.8933112 },
    { lat: 48.8557233, lng: 21.8932063 },
    { lat: 48.8550248, lng: 21.8928997 },
    { lat: 48.8542804, lng: 21.892874 },
    { lat: 48.85379, lng: 21.8930447 },
    { lat: 48.8538018, lng: 21.89245 },
    { lat: 48.8525666, lng: 21.8925829 },
    { lat: 48.8524603, lng: 21.8923416 },
    { lat: 48.8522438, lng: 21.8924006 },
    { lat: 48.8521181, lng: 21.8925951 },
    { lat: 48.851714, lng: 21.8924975 },
    { lat: 48.8509399, lng: 21.8924928 },
    { lat: 48.850633, lng: 21.8925864 },
    { lat: 48.8492909, lng: 21.8926262 },
    { lat: 48.8490089, lng: 21.8926912 },
    { lat: 48.8480538, lng: 21.8934011 },
    { lat: 48.8471039, lng: 21.8944474 },
    { lat: 48.847123, lng: 21.8951067 },
    { lat: 48.8457742, lng: 21.8958287 },
    { lat: 48.8454134, lng: 21.8961025 },
    { lat: 48.845224, lng: 21.8961328 },
    { lat: 48.8435063, lng: 21.8970693 },
    { lat: 48.8422509, lng: 21.8976486 },
    { lat: 48.8418793, lng: 21.897722 },
    { lat: 48.841971, lng: 21.899388 },
    { lat: 48.8421753, lng: 21.8995433 },
    { lat: 48.8423632, lng: 21.8998624 },
    { lat: 48.8417271, lng: 21.8993918 },
    { lat: 48.8411264, lng: 21.8991285 },
    { lat: 48.8406488, lng: 21.8988376 },
    { lat: 48.8399134, lng: 21.8977811 },
    { lat: 48.8398168, lng: 21.8977456 },
    { lat: 48.8396829, lng: 21.8978259 },
    { lat: 48.8394776, lng: 21.8978082 },
    { lat: 48.8389428, lng: 21.8975246 },
    { lat: 48.8382172, lng: 21.8968132 },
    { lat: 48.837845, lng: 21.8966377 },
    { lat: 48.8375209, lng: 21.8965912 },
    { lat: 48.8367142, lng: 21.8962259 },
    { lat: 48.836498, lng: 21.8960403 },
    { lat: 48.8363386, lng: 21.8960092 },
    { lat: 48.835438, lng: 21.8954544 },
    { lat: 48.8349426, lng: 21.8950415 },
    { lat: 48.8347962, lng: 21.8950289 },
    { lat: 48.8346005, lng: 21.8948053 },
    { lat: 48.834366, lng: 21.8946568 },
    { lat: 48.8342814, lng: 21.8945089 },
    { lat: 48.8341957, lng: 21.8945071 },
    { lat: 48.8339162, lng: 21.8942655 },
    { lat: 48.8338016, lng: 21.8958205 },
    { lat: 48.8328088, lng: 21.8970918 },
    { lat: 48.8320191, lng: 21.898955 },
    { lat: 48.8315135, lng: 21.9006254 },
    { lat: 48.8311574, lng: 21.902301 },
    { lat: 48.8310432, lng: 21.9036972 },
    { lat: 48.8323473, lng: 21.9059858 },
    { lat: 48.8323583, lng: 21.9067307 },
    { lat: 48.8324811, lng: 21.9074138 },
    { lat: 48.8327506, lng: 21.9079281 },
    { lat: 48.8329129, lng: 21.908786 },
    { lat: 48.8351147, lng: 21.9083485 },
    { lat: 48.8355807, lng: 21.9082258 },
    { lat: 48.8359862, lng: 21.9080338 },
    { lat: 48.8364092, lng: 21.9095077 },
    { lat: 48.8372048, lng: 21.909601 },
    { lat: 48.8370943, lng: 21.9106237 },
    { lat: 48.8375314, lng: 21.9123003 },
    { lat: 48.8386811, lng: 21.9119393 },
    { lat: 48.8405006, lng: 21.9116064 },
    { lat: 48.8411881, lng: 21.9112085 },
    { lat: 48.8420905, lng: 21.9115303 },
    { lat: 48.842509, lng: 21.9120668 },
    { lat: 48.8430813, lng: 21.9129912 },
    { lat: 48.843775, lng: 21.9136101 },
    { lat: 48.8443357, lng: 21.9139806 },
    { lat: 48.8448072, lng: 21.913993 },
    { lat: 48.847263, lng: 21.9154742 },
    { lat: 48.8479114, lng: 21.9157625 },
    { lat: 48.8490865, lng: 21.9166359 },
    { lat: 48.8502415, lng: 21.9132192 },
    { lat: 48.8505842, lng: 21.9134802 },
    { lat: 48.8508918, lng: 21.9129149 },
    { lat: 48.8512523, lng: 21.9129744 },
    { lat: 48.8517212, lng: 21.912837 },
    { lat: 48.8522196, lng: 21.9129707 },
    { lat: 48.8522362, lng: 21.9138545 },
    { lat: 48.8519862, lng: 21.9148341 },
    { lat: 48.8518307, lng: 21.9152674 },
    { lat: 48.8518014, lng: 21.9158496 },
    { lat: 48.8518389, lng: 21.9164011 },
    { lat: 48.8525803, lng: 21.9166776 },
    { lat: 48.8525067, lng: 21.9172838 },
    { lat: 48.8534107, lng: 21.9174895 },
    { lat: 48.8533433, lng: 21.9182039 },
    { lat: 48.853541, lng: 21.9185097 },
    { lat: 48.8527108, lng: 21.9188701 },
    { lat: 48.852721, lng: 21.9190492 },
    { lat: 48.8529909, lng: 21.9190508 },
    { lat: 48.8531885, lng: 21.9200674 },
    { lat: 48.8535765, lng: 21.9205923 },
    { lat: 48.854029, lng: 21.9209177 },
    { lat: 48.8539817, lng: 21.9217785 },
    { lat: 48.8545057, lng: 21.9221241 },
    { lat: 48.8555163, lng: 21.9225403 },
    { lat: 48.8554713, lng: 21.9238471 },
    { lat: 48.8592909, lng: 21.926565 },
    { lat: 48.8597169, lng: 21.9269549 },
    { lat: 48.8590561, lng: 21.9288627 },
    { lat: 48.8587805, lng: 21.9300771 },
    { lat: 48.8584028, lng: 21.9310833 },
    { lat: 48.8579485, lng: 21.9320336 },
    { lat: 48.8558553, lng: 21.9379932 },
    { lat: 48.8556078, lng: 21.9393476 },
    { lat: 48.855228, lng: 21.9403106 },
    { lat: 48.8543114, lng: 21.9417137 },
    { lat: 48.8539137, lng: 21.9425944 },
    { lat: 48.8537969, lng: 21.9436413 },
    { lat: 48.8534378, lng: 21.9447663 },
    { lat: 48.852575, lng: 21.946902 },
  ],
  Zalužice: [
    { lat: 48.7329004, lng: 22.0211139 },
    { lat: 48.7325585, lng: 22.0215772 },
    { lat: 48.7323168, lng: 22.0221362 },
    { lat: 48.7322213, lng: 22.022609 },
    { lat: 48.7322016, lng: 22.0229055 },
    { lat: 48.7322838, lng: 22.0245365 },
    { lat: 48.7329354, lng: 22.0267735 },
    { lat: 48.7331452, lng: 22.0274953 },
    { lat: 48.7332839, lng: 22.0279679 },
    { lat: 48.7380996, lng: 22.0270856 },
    { lat: 48.7377281, lng: 22.0237334 },
    { lat: 48.7377607, lng: 22.0233656 },
    { lat: 48.7379464, lng: 22.0228079 },
    { lat: 48.7383641, lng: 22.0221287 },
    { lat: 48.7384888, lng: 22.0216635 },
    { lat: 48.7385555, lng: 22.0207032 },
    { lat: 48.7386822, lng: 22.0204075 },
    { lat: 48.7388583, lng: 22.0202513 },
    { lat: 48.7402374, lng: 22.0196451 },
    { lat: 48.740326, lng: 22.019585 },
    { lat: 48.7404683, lng: 22.0192413 },
    { lat: 48.7407571, lng: 22.0170531 },
    { lat: 48.7407635, lng: 22.0167619 },
    { lat: 48.7404451, lng: 22.0113998 },
    { lat: 48.7405457, lng: 22.0110481 },
    { lat: 48.7412258, lng: 22.0098213 },
    { lat: 48.7420268, lng: 22.0080328 },
    { lat: 48.7469373, lng: 22.0037116 },
    { lat: 48.748384, lng: 22.0027418 },
    { lat: 48.7497774, lng: 22.0018569 },
    { lat: 48.751104, lng: 22.0013618 },
    { lat: 48.7516701, lng: 22.0012799 },
    { lat: 48.7518657, lng: 22.0015092 },
    { lat: 48.7520181, lng: 22.0015742 },
    { lat: 48.7529221, lng: 22.0010028 },
    { lat: 48.7520539, lng: 22.0074612 },
    { lat: 48.7517867, lng: 22.0098823 },
    { lat: 48.7518263, lng: 22.0110558 },
    { lat: 48.7519588, lng: 22.0119157 },
    { lat: 48.752241, lng: 22.0129259 },
    { lat: 48.7529095, lng: 22.0125276 },
    { lat: 48.7540971, lng: 22.0120056 },
    { lat: 48.7557096, lng: 22.0111889 },
    { lat: 48.7564133, lng: 22.0107582 },
    { lat: 48.7579278, lng: 22.0101287 },
    { lat: 48.7601592, lng: 22.0090093 },
    { lat: 48.7635715, lng: 22.0081006 },
    { lat: 48.7643229, lng: 22.0128103 },
    { lat: 48.7661244, lng: 22.0246938 },
    { lat: 48.7663685, lng: 22.0265487 },
    { lat: 48.7664059, lng: 22.026516 },
    { lat: 48.7665355, lng: 22.0263868 },
    { lat: 48.7684686, lng: 22.0254321 },
    { lat: 48.7691949, lng: 22.0251825 },
    { lat: 48.7700732, lng: 22.0251738 },
    { lat: 48.7703044, lng: 22.0250647 },
    { lat: 48.7710386, lng: 22.0246734 },
    { lat: 48.7710036, lng: 22.0245324 },
    { lat: 48.7732535, lng: 22.0233231 },
    { lat: 48.7748126, lng: 22.0223054 },
    { lat: 48.7750786, lng: 22.0224665 },
    { lat: 48.7887174, lng: 22.0167999 },
    { lat: 48.7838315, lng: 22.0035594 },
    { lat: 48.7834532, lng: 22.0016112 },
    { lat: 48.7803739, lng: 21.9857449 },
    { lat: 48.7777747, lng: 21.9702373 },
    { lat: 48.7715992, lng: 21.9651543 },
    { lat: 48.7707305, lng: 21.9671435 },
    { lat: 48.7685199, lng: 21.9612335 },
    { lat: 48.7668796, lng: 21.962131 },
    { lat: 48.7643424, lng: 21.9635192 },
    { lat: 48.7640005, lng: 21.9632469 },
    { lat: 48.7637805, lng: 21.9633585 },
    { lat: 48.7634509, lng: 21.9632698 },
    { lat: 48.7627244, lng: 21.9632787 },
    { lat: 48.7620494, lng: 21.9621794 },
    { lat: 48.761381, lng: 21.9613091 },
    { lat: 48.7607893, lng: 21.9604067 },
    { lat: 48.7601414, lng: 21.9595945 },
    { lat: 48.7598206, lng: 21.958275 },
    { lat: 48.7529992, lng: 21.9452033 },
    { lat: 48.7489102, lng: 21.9503631 },
    { lat: 48.7494394, lng: 21.9520002 },
    { lat: 48.7486126, lng: 21.9532773 },
    { lat: 48.7478404, lng: 21.9551114 },
    { lat: 48.7477258, lng: 21.9554779 },
    { lat: 48.7471659, lng: 21.9582127 },
    { lat: 48.747035, lng: 21.9587783 },
    { lat: 48.7467093, lng: 21.9594833 },
    { lat: 48.746532, lng: 21.9596844 },
    { lat: 48.7461703, lng: 21.9599241 },
    { lat: 48.7457646, lng: 21.959991 },
    { lat: 48.7447421, lng: 21.9597642 },
    { lat: 48.7441625, lng: 21.9597974 },
    { lat: 48.7438301, lng: 21.9599602 },
    { lat: 48.7427208, lng: 21.9609096 },
    { lat: 48.743444, lng: 21.9634006 },
    { lat: 48.744288, lng: 21.9659576 },
    { lat: 48.7438329, lng: 21.9662963 },
    { lat: 48.7410818, lng: 21.9694062 },
    { lat: 48.7402569, lng: 21.9703937 },
    { lat: 48.7385774, lng: 21.9727613 },
    { lat: 48.7375641, lng: 21.9637681 },
    { lat: 48.7374346, lng: 21.9639462 },
    { lat: 48.7371124, lng: 21.9641946 },
    { lat: 48.7342446, lng: 21.9673187 },
    { lat: 48.7307055, lng: 21.9699091 },
    { lat: 48.7304792, lng: 21.9702614 },
    { lat: 48.7304648, lng: 21.9706428 },
    { lat: 48.7313449, lng: 21.9779886 },
    { lat: 48.7307036, lng: 21.9786269 },
    { lat: 48.7311862, lng: 21.98036 },
    { lat: 48.7319596, lng: 21.9828109 },
    { lat: 48.7318144, lng: 21.9829456 },
    { lat: 48.7329469, lng: 21.9858153 },
    { lat: 48.7333689, lng: 21.9870991 },
    { lat: 48.733649, lng: 21.9881478 },
    { lat: 48.734951, lng: 21.9942244 },
    { lat: 48.7349099, lng: 21.9945653 },
    { lat: 48.7347962, lng: 21.9949772 },
    { lat: 48.7355225, lng: 21.9989071 },
    { lat: 48.7357767, lng: 22.0006551 },
    { lat: 48.7360605, lng: 22.0016767 },
    { lat: 48.7364092, lng: 22.0042526 },
    { lat: 48.7365946, lng: 22.0047116 },
    { lat: 48.7376389, lng: 22.0067029 },
    { lat: 48.7375447, lng: 22.006902 },
    { lat: 48.7358926, lng: 22.009225 },
    { lat: 48.7356849, lng: 22.0096086 },
    { lat: 48.7350246, lng: 22.0112222 },
    { lat: 48.7348431, lng: 22.0113963 },
    { lat: 48.7345873, lng: 22.0120751 },
    { lat: 48.7351062, lng: 22.0125027 },
    { lat: 48.7356998, lng: 22.0128047 },
    { lat: 48.7352975, lng: 22.0139792 },
    { lat: 48.7340636, lng: 22.0161168 },
    { lat: 48.734165, lng: 22.016445 },
    { lat: 48.7340414, lng: 22.0170524 },
    { lat: 48.7342063, lng: 22.0192504 },
    { lat: 48.7341752, lng: 22.019405 },
    { lat: 48.7341009, lng: 22.0195895 },
    { lat: 48.7329004, lng: 22.0211139 },
  ],
  VeľkéSlemence: [
    { lat: 48.5023035, lng: 22.1062316 },
    { lat: 48.5011688, lng: 22.105966 },
    { lat: 48.5007905, lng: 22.1056618 },
    { lat: 48.5001683, lng: 22.1062733 },
    { lat: 48.5000278, lng: 22.1065237 },
    { lat: 48.499676, lng: 22.1069182 },
    { lat: 48.4990892, lng: 22.1071245 },
    { lat: 48.498071, lng: 22.10673 },
    { lat: 48.4963318, lng: 22.1065894 },
    { lat: 48.4953861, lng: 22.1060811 },
    { lat: 48.4951686, lng: 22.106873 },
    { lat: 48.4948337, lng: 22.1073655 },
    { lat: 48.4941563, lng: 22.1077745 },
    { lat: 48.4937777, lng: 22.1078955 },
    { lat: 48.4933321, lng: 22.1077934 },
    { lat: 48.4923802, lng: 22.1104021 },
    { lat: 48.4919696, lng: 22.1110744 },
    { lat: 48.4917328, lng: 22.1113371 },
    { lat: 48.4915118, lng: 22.1114039 },
    { lat: 48.4910084, lng: 22.1113358 },
    { lat: 48.4905523, lng: 22.1101193 },
    { lat: 48.4906487, lng: 22.1095797 },
    { lat: 48.4906088, lng: 22.1082328 },
    { lat: 48.4904706, lng: 22.1080505 },
    { lat: 48.4902382, lng: 22.1080204 },
    { lat: 48.4893352, lng: 22.1088897 },
    { lat: 48.4886489, lng: 22.1098853 },
    { lat: 48.487422, lng: 22.1107548 },
    { lat: 48.4865586, lng: 22.1104885 },
    { lat: 48.4863422, lng: 22.1103271 },
    { lat: 48.484667, lng: 22.1099429 },
    { lat: 48.4841787, lng: 22.1103176 },
    { lat: 48.4840599, lng: 22.1109083 },
    { lat: 48.4843353, lng: 22.1116208 },
    { lat: 48.4845798, lng: 22.1125396 },
    { lat: 48.4846649, lng: 22.1131875 },
    { lat: 48.4844568, lng: 22.1140996 },
    { lat: 48.4838672, lng: 22.1134702 },
    { lat: 48.4830289, lng: 22.1133642 },
    { lat: 48.482572, lng: 22.1130727 },
    { lat: 48.4823306, lng: 22.1127147 },
    { lat: 48.4815124, lng: 22.1128322 },
    { lat: 48.4806451, lng: 22.1128324 },
    { lat: 48.4803375, lng: 22.1134318 },
    { lat: 48.4805048, lng: 22.1137987 },
    { lat: 48.4811022, lng: 22.1146212 },
    { lat: 48.4814766, lng: 22.1149909 },
    { lat: 48.4819361, lng: 22.1158667 },
    { lat: 48.4825638, lng: 22.1173951 },
    { lat: 48.4828663, lng: 22.1178261 },
    { lat: 48.4831225, lng: 22.1183347 },
    { lat: 48.4837789, lng: 22.1200854 },
    { lat: 48.484063, lng: 22.1203947 },
    { lat: 48.4847088, lng: 22.1208748 },
    { lat: 48.4853384, lng: 22.1207214 },
    { lat: 48.4853929, lng: 22.1207834 },
    { lat: 48.4856762, lng: 22.1216849 },
    { lat: 48.4857172, lng: 22.1222359 },
    { lat: 48.4856875, lng: 22.1226452 },
    { lat: 48.4862927, lng: 22.1236513 },
    { lat: 48.4858337, lng: 22.1260894 },
    { lat: 48.4854994, lng: 22.1263157 },
    { lat: 48.4846699, lng: 22.1264474 },
    { lat: 48.4843487, lng: 22.1267527 },
    { lat: 48.4841351, lng: 22.127285 },
    { lat: 48.484065, lng: 22.1286645 },
    { lat: 48.4839741, lng: 22.1292937 },
    { lat: 48.4830598, lng: 22.1306535 },
    { lat: 48.4830351, lng: 22.1308445 },
    { lat: 48.4830972, lng: 22.1309529 },
    { lat: 48.4834437, lng: 22.1309501 },
    { lat: 48.4842048, lng: 22.1311175 },
    { lat: 48.4845248, lng: 22.1301878 },
    { lat: 48.4847465, lng: 22.1297119 },
    { lat: 48.4850113, lng: 22.1292929 },
    { lat: 48.4858133, lng: 22.1291546 },
    { lat: 48.4858694, lng: 22.1291449 },
    { lat: 48.4860709, lng: 22.1292962 },
    { lat: 48.4863822, lng: 22.1298555 },
    { lat: 48.486627, lng: 22.131034 },
    { lat: 48.4868395, lng: 22.1315107 },
    { lat: 48.4868985, lng: 22.1315338 },
    { lat: 48.4869948, lng: 22.1314205 },
    { lat: 48.4874012, lng: 22.1314323 },
    { lat: 48.4876085, lng: 22.1316344 },
    { lat: 48.4877269, lng: 22.1316093 },
    { lat: 48.4878748, lng: 22.1312905 },
    { lat: 48.4881786, lng: 22.1310587 },
    { lat: 48.4885953, lng: 22.1309732 },
    { lat: 48.4887484, lng: 22.1306998 },
    { lat: 48.4892055, lng: 22.1301379 },
    { lat: 48.4894898, lng: 22.130408 },
    { lat: 48.4895844, lng: 22.1308934 },
    { lat: 48.4902261, lng: 22.1322165 },
    { lat: 48.4903494, lng: 22.1322436 },
    { lat: 48.49043, lng: 22.1321734 },
    { lat: 48.4906479, lng: 22.1316863 },
    { lat: 48.4908706, lng: 22.1314897 },
    { lat: 48.4915637, lng: 22.1315724 },
    { lat: 48.492125, lng: 22.1316394 },
    { lat: 48.4924102, lng: 22.1318563 },
    { lat: 48.4924565, lng: 22.1320109 },
    { lat: 48.4924477, lng: 22.1325015 },
    { lat: 48.4919926, lng: 22.1328076 },
    { lat: 48.4911287, lng: 22.134491 },
    { lat: 48.4911455, lng: 22.1345848 },
    { lat: 48.49238, lng: 22.1349861 },
    { lat: 48.4925053, lng: 22.1351937 },
    { lat: 48.4923496, lng: 22.1355598 },
    { lat: 48.4917725, lng: 22.1357106 },
    { lat: 48.4911307, lng: 22.1363974 },
    { lat: 48.4911643, lng: 22.1365833 },
    { lat: 48.4918859, lng: 22.1367571 },
    { lat: 48.4920046, lng: 22.1369253 },
    { lat: 48.4920757, lng: 22.1375581 },
    { lat: 48.4919611, lng: 22.1387653 },
    { lat: 48.4918743, lng: 22.1403449 },
    { lat: 48.4920961, lng: 22.1405966 },
    { lat: 48.4924872, lng: 22.1400032 },
    { lat: 48.4927438, lng: 22.1398183 },
    { lat: 48.4931932, lng: 22.1404191 },
    { lat: 48.4931919, lng: 22.1416661 },
    { lat: 48.4936415, lng: 22.1433861 },
    { lat: 48.4946099, lng: 22.1446312 },
    { lat: 48.4934092, lng: 22.1459696 },
    { lat: 48.4931927, lng: 22.1458423 },
    { lat: 48.4931303, lng: 22.1455811 },
    { lat: 48.4927891, lng: 22.1453756 },
    { lat: 48.4923017, lng: 22.1453588 },
    { lat: 48.4917577, lng: 22.1460581 },
    { lat: 48.491161, lng: 22.1466613 },
    { lat: 48.4907911, lng: 22.1469138 },
    { lat: 48.4901591, lng: 22.1448375 },
    { lat: 48.4898251, lng: 22.1443633 },
    { lat: 48.4892566, lng: 22.1441529 },
    { lat: 48.4889569, lng: 22.1441807 },
    { lat: 48.4877767, lng: 22.144942 },
    { lat: 48.4873881, lng: 22.1451153 },
    { lat: 48.486445, lng: 22.145824 },
    { lat: 48.486248, lng: 22.1458602 },
    { lat: 48.4854344, lng: 22.1456114 },
    { lat: 48.4849771, lng: 22.145683 },
    { lat: 48.4834852, lng: 22.1463496 },
    { lat: 48.4833993, lng: 22.146474 },
    { lat: 48.4833858, lng: 22.1474849 },
    { lat: 48.4834875, lng: 22.1480376 },
    { lat: 48.4837343, lng: 22.1487331 },
    { lat: 48.483711, lng: 22.148821 },
    { lat: 48.490249, lng: 22.150357 },
    { lat: 48.509803, lng: 22.154561 },
    { lat: 48.5102114, lng: 22.1544009 },
    { lat: 48.5111145, lng: 22.154047 },
    { lat: 48.5112923, lng: 22.1539658 },
    { lat: 48.5121873, lng: 22.1534854 },
    { lat: 48.512821, lng: 22.153156 },
    { lat: 48.514988, lng: 22.155583 },
    { lat: 48.524819, lng: 22.157786 },
    { lat: 48.5258747, lng: 22.1506958 },
    { lat: 48.5243271, lng: 22.1500328 },
    { lat: 48.5211059, lng: 22.148124 },
    { lat: 48.5193589, lng: 22.1459432 },
    { lat: 48.5176647, lng: 22.1437164 },
    { lat: 48.5160123, lng: 22.1412587 },
    { lat: 48.5159824, lng: 22.14109 },
    { lat: 48.5159962, lng: 22.1409667 },
    { lat: 48.5167243, lng: 22.1344669 },
    { lat: 48.5166566, lng: 22.1343633 },
    { lat: 48.5157926, lng: 22.1342183 },
    { lat: 48.5150372, lng: 22.1344329 },
    { lat: 48.514575, lng: 22.1340558 },
    { lat: 48.51447, lng: 22.1329817 },
    { lat: 48.5142481, lng: 22.1319099 },
    { lat: 48.5141178, lng: 22.1315833 },
    { lat: 48.5134797, lng: 22.1314703 },
    { lat: 48.5134095, lng: 22.1313612 },
    { lat: 48.5134129, lng: 22.1309754 },
    { lat: 48.5135005, lng: 22.1307288 },
    { lat: 48.5141768, lng: 22.1301163 },
    { lat: 48.5143228, lng: 22.1293837 },
    { lat: 48.5139169, lng: 22.1283399 },
    { lat: 48.5137378, lng: 22.1281415 },
    { lat: 48.5133855, lng: 22.1282787 },
    { lat: 48.512681, lng: 22.1274955 },
    { lat: 48.5121381, lng: 22.126653 },
    { lat: 48.5120709, lng: 22.1261798 },
    { lat: 48.5119088, lng: 22.1256013 },
    { lat: 48.5114229, lng: 22.1247941 },
    { lat: 48.5114386, lng: 22.1244488 },
    { lat: 48.5111713, lng: 22.123757 },
    { lat: 48.5112345, lng: 22.1228958 },
    { lat: 48.5112198, lng: 22.1207459 },
    { lat: 48.5111942, lng: 22.1207107 },
    { lat: 48.5106062, lng: 22.1199045 },
    { lat: 48.5103599, lng: 22.1193855 },
    { lat: 48.5101024, lng: 22.1190473 },
    { lat: 48.5098047, lng: 22.118779 },
    { lat: 48.5091138, lng: 22.1185398 },
    { lat: 48.5077965, lng: 22.1171439 },
    { lat: 48.5069866, lng: 22.115667 },
    { lat: 48.5065653, lng: 22.1158853 },
    { lat: 48.50643, lng: 22.1158723 },
    { lat: 48.5061179, lng: 22.1153743 },
    { lat: 48.5056748, lng: 22.1148573 },
    { lat: 48.5053137, lng: 22.1134183 },
    { lat: 48.504854, lng: 22.1131974 },
    { lat: 48.5036127, lng: 22.1110818 },
    { lat: 48.5031269, lng: 22.1088472 },
    { lat: 48.5030079, lng: 22.1086187 },
    { lat: 48.5027701, lng: 22.1083207 },
    { lat: 48.502303, lng: 22.1081176 },
    { lat: 48.5017425, lng: 22.1082984 },
    { lat: 48.5013404, lng: 22.1082206 },
    { lat: 48.5011195, lng: 22.107252 },
    { lat: 48.5013885, lng: 22.1068336 },
    { lat: 48.5023035, lng: 22.1062316 },
  ],
  PorubapodVihorlatom: [
    { lat: 48.8935089, lng: 22.1565838 },
    { lat: 48.8930305, lng: 22.1547143 },
    { lat: 48.8931332, lng: 22.1539947 },
    { lat: 48.8931533, lng: 22.153109 },
    { lat: 48.8928165, lng: 22.1524577 },
    { lat: 48.8925224, lng: 22.151649 },
    { lat: 48.8917923, lng: 22.1501826 },
    { lat: 48.8916612, lng: 22.1494782 },
    { lat: 48.8914527, lng: 22.1477582 },
    { lat: 48.8911555, lng: 22.1472057 },
    { lat: 48.8906401, lng: 22.146618 },
    { lat: 48.8903002, lng: 22.1457836 },
    { lat: 48.8904583, lng: 22.1441996 },
    { lat: 48.8901117, lng: 22.1423526 },
    { lat: 48.8903171, lng: 22.141412 },
    { lat: 48.8903403, lng: 22.1407138 },
    { lat: 48.8902028, lng: 22.1392431 },
    { lat: 48.8902943, lng: 22.1385738 },
    { lat: 48.8899805, lng: 22.1380429 },
    { lat: 48.8897186, lng: 22.137179 },
    { lat: 48.889636, lng: 22.1366255 },
    { lat: 48.8895939, lng: 22.1357853 },
    { lat: 48.8893037, lng: 22.1350513 },
    { lat: 48.8890386, lng: 22.1338052 },
    { lat: 48.8890365, lng: 22.1331383 },
    { lat: 48.888734, lng: 22.132603 },
    { lat: 48.888623, lng: 22.13237 },
    { lat: 48.888562, lng: 22.13167 },
    { lat: 48.888644, lng: 22.13091 },
    { lat: 48.888915, lng: 22.130312 },
    { lat: 48.889281, lng: 22.129765 },
    { lat: 48.889261, lng: 22.129127 },
    { lat: 48.889246, lng: 22.128283 },
    { lat: 48.889195, lng: 22.127528 },
    { lat: 48.889148, lng: 22.127473 },
    { lat: 48.889081, lng: 22.127397 },
    { lat: 48.888883, lng: 22.127171 },
    { lat: 48.888445, lng: 22.126668 },
    { lat: 48.888417, lng: 22.126867 },
    { lat: 48.887979, lng: 22.126111 },
    { lat: 48.887735, lng: 22.126371 },
    { lat: 48.887713, lng: 22.126396 },
    { lat: 48.88749, lng: 22.126351 },
    { lat: 48.887358, lng: 22.126113 },
    { lat: 48.887195, lng: 22.126082 },
    { lat: 48.886975, lng: 22.125793 },
    { lat: 48.886694, lng: 22.125769 },
    { lat: 48.886389, lng: 22.1259 },
    { lat: 48.885411, lng: 22.125456 },
    { lat: 48.884917, lng: 22.125539 },
    { lat: 48.883788, lng: 22.125463 },
    { lat: 48.882299, lng: 22.125071 },
    { lat: 48.881651, lng: 22.125054 },
    { lat: 48.881149, lng: 22.125145 },
    { lat: 48.880956, lng: 22.125117 },
    { lat: 48.880607, lng: 22.12491 },
    { lat: 48.880045, lng: 22.124777 },
    { lat: 48.879688, lng: 22.124824 },
    { lat: 48.879209, lng: 22.124743 },
    { lat: 48.87895, lng: 22.124544 },
    { lat: 48.878625, lng: 22.124425 },
    { lat: 48.878222, lng: 22.124443 },
    { lat: 48.877876, lng: 22.124493 },
    { lat: 48.877378, lng: 22.124438 },
    { lat: 48.876788, lng: 22.124265 },
    { lat: 48.87664, lng: 22.124289 },
    { lat: 48.876076, lng: 22.123913 },
    { lat: 48.87594, lng: 22.123743 },
    { lat: 48.875775, lng: 22.123832 },
    { lat: 48.875622, lng: 22.124389 },
    { lat: 48.875775, lng: 22.125251 },
    { lat: 48.875749, lng: 22.125824 },
    { lat: 48.875769, lng: 22.126242 },
    { lat: 48.875735, lng: 22.126641 },
    { lat: 48.874597, lng: 22.127497 },
    { lat: 48.874573, lng: 22.127514 },
    { lat: 48.874563, lng: 22.127521 },
    { lat: 48.8735, lng: 22.128215 },
    { lat: 48.873094, lng: 22.128901 },
    { lat: 48.872772, lng: 22.12973 },
    { lat: 48.872425, lng: 22.129783 },
    { lat: 48.871904, lng: 22.129999 },
    { lat: 48.871505, lng: 22.13003 },
    { lat: 48.87065, lng: 22.130486 },
    { lat: 48.870618, lng: 22.130503 },
    { lat: 48.870356, lng: 22.130643 },
    { lat: 48.870315, lng: 22.130665 },
    { lat: 48.870168, lng: 22.130744 },
    { lat: 48.869777, lng: 22.130769 },
    { lat: 48.869759, lng: 22.13077 },
    { lat: 48.869711, lng: 22.130773 },
    { lat: 48.869585, lng: 22.130782 },
    { lat: 48.868966, lng: 22.130598 },
    { lat: 48.868536, lng: 22.130555 },
    { lat: 48.86805, lng: 22.130678 },
    { lat: 48.867248, lng: 22.130653 },
    { lat: 48.866911, lng: 22.130934 },
    { lat: 48.866666, lng: 22.130942 },
    { lat: 48.866253, lng: 22.131084 },
    { lat: 48.866214, lng: 22.131098 },
    { lat: 48.866186, lng: 22.131107 },
    { lat: 48.865683, lng: 22.131152 },
    { lat: 48.865595, lng: 22.131113 },
    { lat: 48.865565, lng: 22.131101 },
    { lat: 48.865323, lng: 22.130995 },
    { lat: 48.865004, lng: 22.130941 },
    { lat: 48.864656, lng: 22.130978 },
    { lat: 48.864489, lng: 22.131138 },
    { lat: 48.864312, lng: 22.131134 },
    { lat: 48.86358, lng: 22.131423 },
    { lat: 48.863416, lng: 22.131528 },
    { lat: 48.863119, lng: 22.131346 },
    { lat: 48.862632, lng: 22.131456 },
    { lat: 48.862489, lng: 22.131327 },
    { lat: 48.862034, lng: 22.131316 },
    { lat: 48.861818, lng: 22.131112 },
    { lat: 48.861561, lng: 22.130997 },
    { lat: 48.861084, lng: 22.130504 },
    { lat: 48.86089, lng: 22.130395 },
    { lat: 48.860625, lng: 22.130439 },
    { lat: 48.860323, lng: 22.130364 },
    { lat: 48.860072, lng: 22.130302 },
    { lat: 48.859815, lng: 22.130209 },
    { lat: 48.859556, lng: 22.130116 },
    { lat: 48.859277, lng: 22.130214 },
    { lat: 48.858979, lng: 22.130116 },
    { lat: 48.858891, lng: 22.130127 },
    { lat: 48.858543, lng: 22.130165 },
    { lat: 48.858295, lng: 22.130116 },
    { lat: 48.858086, lng: 22.13021 },
    { lat: 48.857701, lng: 22.130249 },
    { lat: 48.857522, lng: 22.130133 },
    { lat: 48.857264, lng: 22.130227 },
    { lat: 48.857192, lng: 22.130403 },
    { lat: 48.85696, lng: 22.130522 },
    { lat: 48.856758, lng: 22.13071 },
    { lat: 48.856269, lng: 22.130977 },
    { lat: 48.856232, lng: 22.13097 },
    { lat: 48.856096, lng: 22.130944 },
    { lat: 48.856049, lng: 22.130936 },
    { lat: 48.856039, lng: 22.130934 },
    { lat: 48.855889, lng: 22.13078 },
    { lat: 48.855638, lng: 22.130723 },
    { lat: 48.855533, lng: 22.130806 },
    { lat: 48.855455, lng: 22.130868 },
    { lat: 48.855399, lng: 22.130914 },
    { lat: 48.855353, lng: 22.131037 },
    { lat: 48.855197, lng: 22.131188 },
    { lat: 48.854911, lng: 22.131131 },
    { lat: 48.854537, lng: 22.131651 },
    { lat: 48.854273, lng: 22.131811 },
    { lat: 48.854245, lng: 22.131799 },
    { lat: 48.854133, lng: 22.13175 },
    { lat: 48.853946, lng: 22.131853 },
    { lat: 48.853651, lng: 22.131906 },
    { lat: 48.853516, lng: 22.13218 },
    { lat: 48.853485, lng: 22.132173 },
    { lat: 48.853302, lng: 22.132136 },
    { lat: 48.853205, lng: 22.132116 },
    { lat: 48.853023, lng: 22.132189 },
    { lat: 48.852695, lng: 22.13259 },
    { lat: 48.852314, lng: 22.13286 },
    { lat: 48.85208, lng: 22.132682 },
    { lat: 48.851606, lng: 22.132547 },
    { lat: 48.851386, lng: 22.132448 },
    { lat: 48.851204, lng: 22.132351 },
    { lat: 48.851079, lng: 22.13207 },
    { lat: 48.850894, lng: 22.131861 },
    { lat: 48.850678, lng: 22.131691 },
    { lat: 48.850628, lng: 22.131652 },
    { lat: 48.850402, lng: 22.131474 },
    { lat: 48.849618, lng: 22.131492 },
    { lat: 48.849437, lng: 22.131296 },
    { lat: 48.849206, lng: 22.131278 },
    { lat: 48.848689, lng: 22.131216 },
    { lat: 48.848516, lng: 22.130813 },
    { lat: 48.847998, lng: 22.130979 },
    { lat: 48.847252, lng: 22.131049 },
    { lat: 48.84686, lng: 22.130911 },
    { lat: 48.846465, lng: 22.13117 },
    { lat: 48.846287, lng: 22.13108 },
    { lat: 48.846097, lng: 22.131276 },
    { lat: 48.845881, lng: 22.131219 },
    { lat: 48.845676, lng: 22.131423 },
    { lat: 48.844698, lng: 22.131594 },
    { lat: 48.8442811, lng: 22.1315881 },
    { lat: 48.843886, lng: 22.1317681 },
    { lat: 48.8435287, lng: 22.1318361 },
    { lat: 48.8433763, lng: 22.1319521 },
    { lat: 48.8431037, lng: 22.1317967 },
    { lat: 48.8424858, lng: 22.1318707 },
    { lat: 48.8417231, lng: 22.1314358 },
    { lat: 48.8415235, lng: 22.1314611 },
    { lat: 48.841285, lng: 22.1313182 },
    { lat: 48.8408832, lng: 22.1309186 },
    { lat: 48.8405085, lng: 22.1302816 },
    { lat: 48.8402914, lng: 22.1301904 },
    { lat: 48.8400116, lng: 22.1302478 },
    { lat: 48.8398667, lng: 22.1304048 },
    { lat: 48.8397538, lng: 22.1303327 },
    { lat: 48.8396003, lng: 22.1305033 },
    { lat: 48.8394447, lng: 22.1303406 },
    { lat: 48.8391617, lng: 22.1305025 },
    { lat: 48.8390269, lng: 22.1303222 },
    { lat: 48.8386407, lng: 22.1303075 },
    { lat: 48.8384649, lng: 22.1301955 },
    { lat: 48.8383972, lng: 22.130277 },
    { lat: 48.8382544, lng: 22.1301375 },
    { lat: 48.8380273, lng: 22.1300486 },
    { lat: 48.8378946, lng: 22.1298975 },
    { lat: 48.8376948, lng: 22.1299235 },
    { lat: 48.8375531, lng: 22.1297772 },
    { lat: 48.8371134, lng: 22.1298228 },
    { lat: 48.8369536, lng: 22.1297314 },
    { lat: 48.8368726, lng: 22.1297775 },
    { lat: 48.8368098, lng: 22.1297337 },
    { lat: 48.8368059, lng: 22.129615 },
    { lat: 48.8367067, lng: 22.1296568 },
    { lat: 48.8365836, lng: 22.1295816 },
    { lat: 48.8364004, lng: 22.1296431 },
    { lat: 48.8362353, lng: 22.129607 },
    { lat: 48.83593, lng: 22.1291466 },
    { lat: 48.8358457, lng: 22.1291536 },
    { lat: 48.8356115, lng: 22.1289937 },
    { lat: 48.8355091, lng: 22.1290378 },
    { lat: 48.8354674, lng: 22.1291317 },
    { lat: 48.8352373, lng: 22.1291105 },
    { lat: 48.835115, lng: 22.1293127 },
    { lat: 48.8349453, lng: 22.1293617 },
    { lat: 48.8348548, lng: 22.1292941 },
    { lat: 48.8346775, lng: 22.1293114 },
    { lat: 48.8345558, lng: 22.1290152 },
    { lat: 48.8344576, lng: 22.128999 },
    { lat: 48.8343923, lng: 22.128805 },
    { lat: 48.8342212, lng: 22.1287056 },
    { lat: 48.8341609, lng: 22.1287649 },
    { lat: 48.8339936, lng: 22.1285443 },
    { lat: 48.8339289, lng: 22.1282803 },
    { lat: 48.8337925, lng: 22.1282591 },
    { lat: 48.8336023, lng: 22.1279693 },
    { lat: 48.8334729, lng: 22.1275846 },
    { lat: 48.8335618, lng: 22.1273435 },
    { lat: 48.8335033, lng: 22.1271474 },
    { lat: 48.8333844, lng: 22.1272048 },
    { lat: 48.8331833, lng: 22.1271209 },
    { lat: 48.8330512, lng: 22.1268638 },
    { lat: 48.8328175, lng: 22.1267306 },
    { lat: 48.8327331, lng: 22.1267262 },
    { lat: 48.8326936, lng: 22.1268112 },
    { lat: 48.8324214, lng: 22.1269643 },
    { lat: 48.8323647, lng: 22.1267988 },
    { lat: 48.8322906, lng: 22.126756 },
    { lat: 48.8321552, lng: 22.1268821 },
    { lat: 48.8318306, lng: 22.1268502 },
    { lat: 48.8317288, lng: 22.1266893 },
    { lat: 48.8313144, lng: 22.1263719 },
    { lat: 48.8311422, lng: 22.1262748 },
    { lat: 48.8309718, lng: 22.1262974 },
    { lat: 48.8308681, lng: 22.1262369 },
    { lat: 48.8308113, lng: 22.1260754 },
    { lat: 48.830665, lng: 22.1259279 },
    { lat: 48.8301398, lng: 22.125735 },
    { lat: 48.8299599, lng: 22.1254976 },
    { lat: 48.8299583, lng: 22.1252899 },
    { lat: 48.8298741, lng: 22.1250931 },
    { lat: 48.8297236, lng: 22.1250072 },
    { lat: 48.8294907, lng: 22.1246231 },
    { lat: 48.829326, lng: 22.1245059 },
    { lat: 48.8292336, lng: 22.1240592 },
    { lat: 48.8291045, lng: 22.1239829 },
    { lat: 48.8289571, lng: 22.1236331 },
    { lat: 48.8287627, lng: 22.1228278 },
    { lat: 48.8285661, lng: 22.1229401 },
    { lat: 48.8285446, lng: 22.1226828 },
    { lat: 48.8283812, lng: 22.1225326 },
    { lat: 48.828277, lng: 22.1225497 },
    { lat: 48.8281919, lng: 22.1222286 },
    { lat: 48.8281217, lng: 22.1222588 },
    { lat: 48.8278769, lng: 22.1226571 },
    { lat: 48.8277899, lng: 22.1226971 },
    { lat: 48.8276254, lng: 22.122627 },
    { lat: 48.8274778, lng: 22.1228581 },
    { lat: 48.8274164, lng: 22.1228328 },
    { lat: 48.8272694, lng: 22.1225115 },
    { lat: 48.8271597, lng: 22.122486 },
    { lat: 48.8270102, lng: 22.1221495 },
    { lat: 48.8268371, lng: 22.1222081 },
    { lat: 48.8265715, lng: 22.1220595 },
    { lat: 48.8264573, lng: 22.121789 },
    { lat: 48.8260223, lng: 22.1215642 },
    { lat: 48.8258653, lng: 22.1213409 },
    { lat: 48.8257694, lng: 22.1212829 },
    { lat: 48.8256459, lng: 22.1213437 },
    { lat: 48.8255955, lng: 22.1213029 },
    { lat: 48.8255591, lng: 22.1211364 },
    { lat: 48.8253114, lng: 22.1209893 },
    { lat: 48.8251157, lng: 22.1209859 },
    { lat: 48.8250255, lng: 22.1206863 },
    { lat: 48.8250134, lng: 22.1203581 },
    { lat: 48.8248881, lng: 22.120242 },
    { lat: 48.8248343, lng: 22.1202985 },
    { lat: 48.8247197, lng: 22.1202571 },
    { lat: 48.824657, lng: 22.1197922 },
    { lat: 48.824539, lng: 22.1196985 },
    { lat: 48.8242209, lng: 22.1197095 },
    { lat: 48.8239108, lng: 22.1194827 },
    { lat: 48.8232941, lng: 22.1193662 },
    { lat: 48.8230266, lng: 22.1188526 },
    { lat: 48.822811, lng: 22.1189202 },
    { lat: 48.8225232, lng: 22.1185834 },
    { lat: 48.822363, lng: 22.1185174 },
    { lat: 48.8221038, lng: 22.118159 },
    { lat: 48.8220993, lng: 22.1180678 },
    { lat: 48.8220037, lng: 22.1180212 },
    { lat: 48.8215995, lng: 22.1171848 },
    { lat: 48.820915, lng: 22.1166308 },
    { lat: 48.8206776, lng: 22.1166189 },
    { lat: 48.8205114, lng: 22.1164468 },
    { lat: 48.8200396, lng: 22.1163294 },
    { lat: 48.8199148, lng: 22.1161833 },
    { lat: 48.8198487, lng: 22.1161987 },
    { lat: 48.8197934, lng: 22.1163851 },
    { lat: 48.8194664, lng: 22.1163023 },
    { lat: 48.8194347, lng: 22.1164358 },
    { lat: 48.8193053, lng: 22.1165783 },
    { lat: 48.8190097, lng: 22.1165881 },
    { lat: 48.8187278, lng: 22.1163159 },
    { lat: 48.8185398, lng: 22.1158756 },
    { lat: 48.8182896, lng: 22.1156617 },
    { lat: 48.8180975, lng: 22.1157723 },
    { lat: 48.8180052, lng: 22.115673 },
    { lat: 48.8180247, lng: 22.115541 },
    { lat: 48.8177954, lng: 22.1152106 },
    { lat: 48.8173278, lng: 22.1151322 },
    { lat: 48.8171925, lng: 22.1152605 },
    { lat: 48.8169078, lng: 22.1151612 },
    { lat: 48.816684, lng: 22.1153999 },
    { lat: 48.8165007, lng: 22.1152116 },
    { lat: 48.8164078, lng: 22.1149493 },
    { lat: 48.815432, lng: 22.1149692 },
    { lat: 48.814575, lng: 22.1146809 },
    { lat: 48.8139609, lng: 22.1143948 },
    { lat: 48.8136019, lng: 22.114201 },
    { lat: 48.8133982, lng: 22.1139079 },
    { lat: 48.8128745, lng: 22.1134985 },
    { lat: 48.8125786, lng: 22.1129091 },
    { lat: 48.8121582, lng: 22.1124028 },
    { lat: 48.8118854, lng: 22.1123789 },
    { lat: 48.8115203, lng: 22.1117772 },
    { lat: 48.8114096, lng: 22.1117992 },
    { lat: 48.811429, lng: 22.111706 },
    { lat: 48.810853, lng: 22.111856 },
    { lat: 48.810138, lng: 22.111618 },
    { lat: 48.807868, lng: 22.110117 },
    { lat: 48.807794, lng: 22.110287 },
    { lat: 48.807435, lng: 22.112037 },
    { lat: 48.80714, lng: 22.113622 },
    { lat: 48.807104, lng: 22.113816 },
    { lat: 48.807132, lng: 22.114551 },
    { lat: 48.807776, lng: 22.115525 },
    { lat: 48.808075, lng: 22.115891 },
    { lat: 48.808226, lng: 22.11593 },
    { lat: 48.808226, lng: 22.116146 },
    { lat: 48.80816, lng: 22.116219 },
    { lat: 48.808333, lng: 22.116191 },
    { lat: 48.808735, lng: 22.116041 },
    { lat: 48.809037, lng: 22.116529 },
    { lat: 48.809071, lng: 22.117022 },
    { lat: 48.80896, lng: 22.11746 },
    { lat: 48.808712, lng: 22.117454 },
    { lat: 48.808483, lng: 22.11729 },
    { lat: 48.808485, lng: 22.117549 },
    { lat: 48.808531, lng: 22.117747 },
    { lat: 48.809379, lng: 22.118381 },
    { lat: 48.809658, lng: 22.118195 },
    { lat: 48.809848, lng: 22.118205 },
    { lat: 48.809908, lng: 22.118595 },
    { lat: 48.809893, lng: 22.118753 },
    { lat: 48.809663, lng: 22.118893 },
    { lat: 48.809729, lng: 22.119247 },
    { lat: 48.80994, lng: 22.119497 },
    { lat: 48.810332, lng: 22.119822 },
    { lat: 48.810795, lng: 22.119812 },
    { lat: 48.81094, lng: 22.120116 },
    { lat: 48.81076, lng: 22.120488 },
    { lat: 48.811154, lng: 22.120568 },
    { lat: 48.811576, lng: 22.121369 },
    { lat: 48.811883, lng: 22.122075 },
    { lat: 48.812056, lng: 22.122689 },
    { lat: 48.811978, lng: 22.123052 },
    { lat: 48.81179, lng: 22.123194 },
    { lat: 48.811889, lng: 22.123307 },
    { lat: 48.812023, lng: 22.123302 },
    { lat: 48.812353, lng: 22.123657 },
    { lat: 48.812358, lng: 22.124055 },
    { lat: 48.812831, lng: 22.123992 },
    { lat: 48.812699, lng: 22.124891 },
    { lat: 48.812493, lng: 22.125401 },
    { lat: 48.812922, lng: 22.125852 },
    { lat: 48.812825, lng: 22.126389 },
    { lat: 48.812749, lng: 22.126996 },
    { lat: 48.81291, lng: 22.127415 },
    { lat: 48.812813, lng: 22.12766 },
    { lat: 48.812695, lng: 22.127806 },
    { lat: 48.812659, lng: 22.128001 },
    { lat: 48.812787, lng: 22.128364 },
    { lat: 48.812701, lng: 22.128597 },
    { lat: 48.812858, lng: 22.129027 },
    { lat: 48.812735, lng: 22.12939 },
    { lat: 48.812838, lng: 22.12978 },
    { lat: 48.812879, lng: 22.130248 },
    { lat: 48.812964, lng: 22.130651 },
    { lat: 48.813079, lng: 22.131287 },
    { lat: 48.813064, lng: 22.132416 },
    { lat: 48.812848, lng: 22.132809 },
    { lat: 48.81282, lng: 22.133268 },
    { lat: 48.812615, lng: 22.1334 },
    { lat: 48.812796, lng: 22.133703 },
    { lat: 48.812745, lng: 22.133906 },
    { lat: 48.812617, lng: 22.134058 },
    { lat: 48.812727, lng: 22.134256 },
    { lat: 48.812913, lng: 22.134377 },
    { lat: 48.812831, lng: 22.134705 },
    { lat: 48.813061, lng: 22.135007 },
    { lat: 48.813022, lng: 22.135654 },
    { lat: 48.812938, lng: 22.135809 },
    { lat: 48.813013, lng: 22.136001 },
    { lat: 48.812998, lng: 22.136471 },
    { lat: 48.812887, lng: 22.136456 },
    { lat: 48.812908, lng: 22.136706 },
    { lat: 48.812739, lng: 22.136994 },
    { lat: 48.812813, lng: 22.137317 },
    { lat: 48.812641, lng: 22.137767 },
    { lat: 48.81212, lng: 22.137681 },
    { lat: 48.811977, lng: 22.137553 },
    { lat: 48.811834, lng: 22.138062 },
    { lat: 48.811489, lng: 22.13799 },
    { lat: 48.81113, lng: 22.138071 },
    { lat: 48.811173, lng: 22.137666 },
    { lat: 48.811058, lng: 22.137605 },
    { lat: 48.810907, lng: 22.138383 },
    { lat: 48.811008, lng: 22.138461 },
    { lat: 48.811027, lng: 22.138712 },
    { lat: 48.81101, lng: 22.138974 },
    { lat: 48.810846, lng: 22.13921 },
    { lat: 48.810966, lng: 22.139692 },
    { lat: 48.810859, lng: 22.140202 },
    { lat: 48.810551, lng: 22.140565 },
    { lat: 48.81034, lng: 22.140564 },
    { lat: 48.810187, lng: 22.140755 },
    { lat: 48.810376, lng: 22.141108 },
    { lat: 48.810346, lng: 22.141457 },
    { lat: 48.810416, lng: 22.141666 },
    { lat: 48.81032, lng: 22.142695 },
    { lat: 48.810292, lng: 22.143322 },
    { lat: 48.810337, lng: 22.143692 },
    { lat: 48.810291, lng: 22.143821 },
    { lat: 48.810359, lng: 22.144782 },
    { lat: 48.810648, lng: 22.145222 },
    { lat: 48.81129, lng: 22.145696 },
    { lat: 48.811285, lng: 22.145674 },
    { lat: 48.813195, lng: 22.146964 },
    { lat: 48.813529, lng: 22.147003 },
    { lat: 48.813805, lng: 22.147036 },
    { lat: 48.813813, lng: 22.146781 },
    { lat: 48.813821, lng: 22.146787 },
    { lat: 48.813968, lng: 22.145604 },
    { lat: 48.814284, lng: 22.145847 },
    { lat: 48.814269, lng: 22.146022 },
    { lat: 48.814433, lng: 22.146064 },
    { lat: 48.814685, lng: 22.146263 },
    { lat: 48.81455, lng: 22.146823 },
    { lat: 48.81454, lng: 22.146872 },
    { lat: 48.81513, lng: 22.146973 },
    { lat: 48.815256, lng: 22.146926 },
    { lat: 48.816025, lng: 22.147332 },
    { lat: 48.81601, lng: 22.147438 },
    { lat: 48.815996, lng: 22.147545 },
    { lat: 48.81598, lng: 22.147668 },
    { lat: 48.815898, lng: 22.148276 },
    { lat: 48.815724, lng: 22.149103 },
    { lat: 48.815543, lng: 22.150123 },
    { lat: 48.816278, lng: 22.150072 },
    { lat: 48.816545, lng: 22.148516 },
    { lat: 48.816912, lng: 22.148731 },
    { lat: 48.817264, lng: 22.148815 },
    { lat: 48.817236, lng: 22.14914 },
    { lat: 48.817237, lng: 22.149148 },
    { lat: 48.817194, lng: 22.149648 },
    { lat: 48.817177, lng: 22.149788 },
    { lat: 48.817312, lng: 22.149864 },
    { lat: 48.817613, lng: 22.14994 },
    { lat: 48.8179, lng: 22.149929 },
    { lat: 48.8188, lng: 22.14985 },
    { lat: 48.819104, lng: 22.149788 },
    { lat: 48.819096, lng: 22.149975 },
    { lat: 48.81927, lng: 22.150091 },
    { lat: 48.819383, lng: 22.150057 },
    { lat: 48.819615, lng: 22.150159 },
    { lat: 48.819827, lng: 22.150312 },
    { lat: 48.819766, lng: 22.150701 },
    { lat: 48.820314, lng: 22.151005 },
    { lat: 48.820443, lng: 22.151101 },
    { lat: 48.82066, lng: 22.151084 },
    { lat: 48.820918, lng: 22.151149 },
    { lat: 48.82085, lng: 22.152087 },
    { lat: 48.821007, lng: 22.152056 },
    { lat: 48.820941, lng: 22.152317 },
    { lat: 48.820771, lng: 22.152362 },
    { lat: 48.820822, lng: 22.153785 },
    { lat: 48.821402, lng: 22.153674 },
    { lat: 48.821648, lng: 22.153702 },
    { lat: 48.821894, lng: 22.153653 },
    { lat: 48.822042, lng: 22.153504 },
    { lat: 48.822123, lng: 22.152984 },
    { lat: 48.822143, lng: 22.152927 },
    { lat: 48.822494, lng: 22.153025 },
    { lat: 48.822537, lng: 22.152835 },
    { lat: 48.823777, lng: 22.15299 },
    { lat: 48.824634, lng: 22.153079 },
    { lat: 48.824886, lng: 22.153105 },
    { lat: 48.824905, lng: 22.153079 },
    { lat: 48.824932, lng: 22.153039 },
    { lat: 48.825021, lng: 22.152915 },
    { lat: 48.825218, lng: 22.152636 },
    { lat: 48.825583, lng: 22.152643 },
    { lat: 48.826276, lng: 22.152605 },
    { lat: 48.826517, lng: 22.152476 },
    { lat: 48.826762, lng: 22.151837 },
    { lat: 48.826888, lng: 22.151869 },
    { lat: 48.826968, lng: 22.151705 },
    { lat: 48.827224, lng: 22.151788 },
    { lat: 48.827382, lng: 22.151752 },
    { lat: 48.827641, lng: 22.151109 },
    { lat: 48.827583, lng: 22.150888 },
    { lat: 48.827521, lng: 22.150857 },
    { lat: 48.827477, lng: 22.150836 },
    { lat: 48.827552, lng: 22.149901 },
    { lat: 48.828164, lng: 22.150245 },
    { lat: 48.82831, lng: 22.149904 },
    { lat: 48.829235, lng: 22.150215 },
    { lat: 48.829372, lng: 22.150079 },
    { lat: 48.829689, lng: 22.150151 },
    { lat: 48.829902, lng: 22.149372 },
    { lat: 48.82997, lng: 22.149045 },
    { lat: 48.829991, lng: 22.148709 },
    { lat: 48.830018, lng: 22.148646 },
    { lat: 48.830023, lng: 22.148603 },
    { lat: 48.830016, lng: 22.148422 },
    { lat: 48.830291, lng: 22.148405 },
    { lat: 48.830695, lng: 22.14863 },
    { lat: 48.830951, lng: 22.148647 },
    { lat: 48.830996, lng: 22.14874 },
    { lat: 48.830994, lng: 22.148858 },
    { lat: 48.830992, lng: 22.149198 },
    { lat: 48.831041, lng: 22.14934 },
    { lat: 48.831052, lng: 22.149872 },
    { lat: 48.832164, lng: 22.150414 },
    { lat: 48.83224, lng: 22.150451 },
    { lat: 48.832363, lng: 22.149792 },
    { lat: 48.832626, lng: 22.149936 },
    { lat: 48.832745, lng: 22.150001 },
    { lat: 48.833024, lng: 22.150154 },
    { lat: 48.832999, lng: 22.150255 },
    { lat: 48.833259, lng: 22.150435 },
    { lat: 48.833673, lng: 22.150775 },
    { lat: 48.834003, lng: 22.151551 },
    { lat: 48.834001, lng: 22.151686 },
    { lat: 48.834197, lng: 22.151239 },
    { lat: 48.834545, lng: 22.151377 },
    { lat: 48.834841, lng: 22.151539 },
    { lat: 48.835264, lng: 22.151675 },
    { lat: 48.836685, lng: 22.152344 },
    { lat: 48.837239, lng: 22.152511 },
    { lat: 48.837812, lng: 22.152838 },
    { lat: 48.838403, lng: 22.152979 },
    { lat: 48.838893, lng: 22.153535 },
    { lat: 48.839252, lng: 22.15413 },
    { lat: 48.839366, lng: 22.154361 },
    { lat: 48.839437, lng: 22.154433 },
    { lat: 48.839582, lng: 22.154578 },
    { lat: 48.839804, lng: 22.155103 },
    { lat: 48.839736, lng: 22.155272 },
    { lat: 48.839593, lng: 22.155619 },
    { lat: 48.839812, lng: 22.156009 },
    { lat: 48.840026, lng: 22.156063 },
    { lat: 48.840069, lng: 22.156073 },
    { lat: 48.840189, lng: 22.156142 },
    { lat: 48.840453, lng: 22.156089 },
    { lat: 48.840634, lng: 22.156247 },
    { lat: 48.84099, lng: 22.156361 },
    { lat: 48.84099, lng: 22.156412 },
    { lat: 48.841554, lng: 22.156475 },
    { lat: 48.842127, lng: 22.156412 },
    { lat: 48.843284, lng: 22.156491 },
    { lat: 48.843661, lng: 22.156632 },
    { lat: 48.84385, lng: 22.156704 },
    { lat: 48.84405, lng: 22.15659 },
    { lat: 48.844301, lng: 22.156448 },
    { lat: 48.844575, lng: 22.156463 },
    { lat: 48.844665, lng: 22.156414 },
    { lat: 48.844687, lng: 22.156418 },
    { lat: 48.844865, lng: 22.156449 },
    { lat: 48.84566, lng: 22.155824 },
    { lat: 48.846379, lng: 22.155631 },
    { lat: 48.846886, lng: 22.155701 },
    { lat: 48.847215, lng: 22.155618 },
    { lat: 48.847375, lng: 22.155747 },
    { lat: 48.848353, lng: 22.156193 },
    { lat: 48.849935, lng: 22.156983 },
    { lat: 48.850286, lng: 22.157076 },
    { lat: 48.850421, lng: 22.157002 },
    { lat: 48.851174, lng: 22.157838 },
    { lat: 48.851398, lng: 22.158087 },
    { lat: 48.851529, lng: 22.158238 },
    { lat: 48.851811, lng: 22.158569 },
    { lat: 48.851853, lng: 22.158659 },
    { lat: 48.852092, lng: 22.158827 },
    { lat: 48.852514, lng: 22.159321 },
    { lat: 48.852619, lng: 22.159716 },
    { lat: 48.853086, lng: 22.160417 },
    { lat: 48.853277, lng: 22.160959 },
    { lat: 48.853519, lng: 22.161201 },
    { lat: 48.853809, lng: 22.161018 },
    { lat: 48.854024, lng: 22.161016 },
    { lat: 48.854633, lng: 22.161196 },
    { lat: 48.854839, lng: 22.161343 },
    { lat: 48.854913, lng: 22.16145 },
    { lat: 48.855005, lng: 22.161464 },
    { lat: 48.855221, lng: 22.16154 },
    { lat: 48.85556, lng: 22.161784 },
    { lat: 48.855624, lng: 22.161901 },
    { lat: 48.855741, lng: 22.161988 },
    { lat: 48.856767, lng: 22.162048 },
    { lat: 48.857087, lng: 22.161829 },
    { lat: 48.857355, lng: 22.16185 },
    { lat: 48.857564, lng: 22.161925 },
    { lat: 48.857788, lng: 22.16197 },
    { lat: 48.858144, lng: 22.162446 },
    { lat: 48.858222, lng: 22.162479 },
    { lat: 48.85851, lng: 22.162718 },
    { lat: 48.858596, lng: 22.162889 },
    { lat: 48.858669, lng: 22.163203 },
    { lat: 48.858781, lng: 22.163393 },
    { lat: 48.858929, lng: 22.163477 },
    { lat: 48.859369, lng: 22.163887 },
    { lat: 48.859686, lng: 22.164035 },
    { lat: 48.8599, lng: 22.16402 },
    { lat: 48.860236, lng: 22.164078 },
    { lat: 48.860453, lng: 22.164038 },
    { lat: 48.860578, lng: 22.163898 },
    { lat: 48.860773, lng: 22.163862 },
    { lat: 48.861033, lng: 22.163946 },
    { lat: 48.861293, lng: 22.163976 },
    { lat: 48.86162, lng: 22.16421 },
    { lat: 48.861763, lng: 22.164234 },
    { lat: 48.862127, lng: 22.163753 },
    { lat: 48.862219, lng: 22.163733 },
    { lat: 48.86315, lng: 22.164014 },
    { lat: 48.863351, lng: 22.164244 },
    { lat: 48.863433, lng: 22.164506 },
    { lat: 48.86353, lng: 22.165107 },
    { lat: 48.86372, lng: 22.165531 },
    { lat: 48.864321, lng: 22.165809 },
    { lat: 48.864751, lng: 22.165886 },
    { lat: 48.86497, lng: 22.165883 },
    { lat: 48.865399, lng: 22.165878 },
    { lat: 48.86584, lng: 22.165783 },
    { lat: 48.865943, lng: 22.165692 },
    { lat: 48.86629, lng: 22.165681 },
    { lat: 48.866822, lng: 22.165828 },
    { lat: 48.866874, lng: 22.165861 },
    { lat: 48.867183, lng: 22.166064 },
    { lat: 48.867489, lng: 22.165759 },
    { lat: 48.867715, lng: 22.165625 },
    { lat: 48.867865, lng: 22.16549 },
    { lat: 48.868152, lng: 22.165546 },
    { lat: 48.868302, lng: 22.165705 },
    { lat: 48.868432, lng: 22.165755 },
    { lat: 48.86888, lng: 22.165693 },
    { lat: 48.86922, lng: 22.165647 },
    { lat: 48.869323, lng: 22.165719 },
    { lat: 48.869595, lng: 22.165769 },
    { lat: 48.869783, lng: 22.165978 },
    { lat: 48.869927, lng: 22.166028 },
    { lat: 48.870093, lng: 22.166048 },
    { lat: 48.870191, lng: 22.165978 },
    { lat: 48.870342, lng: 22.165759 },
    { lat: 48.870406, lng: 22.165732 },
    { lat: 48.870635, lng: 22.165766 },
    { lat: 48.870798, lng: 22.1659 },
    { lat: 48.870946, lng: 22.165919 },
    { lat: 48.871225, lng: 22.165841 },
    { lat: 48.87152, lng: 22.165685 },
    { lat: 48.871805, lng: 22.165344 },
    { lat: 48.871891, lng: 22.165087 },
    { lat: 48.872004, lng: 22.164997 },
    { lat: 48.87218, lng: 22.164402 },
    { lat: 48.872195, lng: 22.16442 },
    { lat: 48.872425, lng: 22.164443 },
    { lat: 48.873088, lng: 22.164739 },
    { lat: 48.873131, lng: 22.164891 },
    { lat: 48.873218, lng: 22.165199 },
    { lat: 48.873579, lng: 22.165642 },
    { lat: 48.873682, lng: 22.165556 },
    { lat: 48.873826, lng: 22.165432 },
    { lat: 48.874045, lng: 22.165169 },
    { lat: 48.874331, lng: 22.164964 },
    { lat: 48.875065, lng: 22.165255 },
    { lat: 48.875589, lng: 22.165212 },
    { lat: 48.875739, lng: 22.165321 },
    { lat: 48.876206, lng: 22.165661 },
    { lat: 48.876769, lng: 22.165969 },
    { lat: 48.877106, lng: 22.165991 },
    { lat: 48.877379, lng: 22.165911 },
    { lat: 48.877539, lng: 22.166185 },
    { lat: 48.878213, lng: 22.165724 },
    { lat: 48.878482, lng: 22.16554 },
    { lat: 48.878662, lng: 22.165634 },
    { lat: 48.879184, lng: 22.165602 },
    { lat: 48.879444, lng: 22.16567 },
    { lat: 48.879891, lng: 22.165718 },
    { lat: 48.88041, lng: 22.165869 },
    { lat: 48.880724, lng: 22.16587 },
    { lat: 48.880988, lng: 22.165949 },
    { lat: 48.881439, lng: 22.166191 },
    { lat: 48.881629, lng: 22.166361 },
    { lat: 48.881752, lng: 22.166301 },
    { lat: 48.881892, lng: 22.166233 },
    { lat: 48.881982, lng: 22.165963 },
    { lat: 48.882298, lng: 22.165694 },
    { lat: 48.882839, lng: 22.165656 },
    { lat: 48.883146, lng: 22.165154 },
    { lat: 48.88359, lng: 22.164836 },
    { lat: 48.883773, lng: 22.164176 },
    { lat: 48.884388, lng: 22.163441 },
    { lat: 48.884591, lng: 22.163077 },
    { lat: 48.884623, lng: 22.16271 },
    { lat: 48.884618, lng: 22.162127 },
    { lat: 48.884574, lng: 22.161469 },
    { lat: 48.884908, lng: 22.161122 },
    { lat: 48.885584, lng: 22.16062 },
    { lat: 48.885641, lng: 22.160578 },
    { lat: 48.885962, lng: 22.160339 },
    { lat: 48.886044, lng: 22.160471 },
    { lat: 48.886075, lng: 22.160534 },
    { lat: 48.886453, lng: 22.161163 },
    { lat: 48.887045, lng: 22.16136 },
    { lat: 48.887172, lng: 22.161395 },
    { lat: 48.887482, lng: 22.161495 },
    { lat: 48.889463, lng: 22.158958 },
    { lat: 48.890986, lng: 22.158074 },
    { lat: 48.891255, lng: 22.158408 },
    { lat: 48.891876, lng: 22.158225 },
    { lat: 48.892182, lng: 22.157542 },
    { lat: 48.892408, lng: 22.15741 },
    { lat: 48.892691, lng: 22.157475 },
    { lat: 48.89329, lng: 22.156661 },
    { lat: 48.8935089, lng: 22.1565838 },
  ],
  Budince: [
    { lat: 48.5508849, lng: 22.1082837 },
    { lat: 48.5508645, lng: 22.108277 },
    { lat: 48.5463326, lng: 22.1116308 },
    { lat: 48.5463656, lng: 22.1121676 },
    { lat: 48.5443681, lng: 22.1128982 },
    { lat: 48.542121, lng: 22.1135317 },
    { lat: 48.5420607, lng: 22.1139343 },
    { lat: 48.5421254, lng: 22.1142972 },
    { lat: 48.5423434, lng: 22.114906 },
    { lat: 48.5427731, lng: 22.1155764 },
    { lat: 48.5428494, lng: 22.1163331 },
    { lat: 48.5426112, lng: 22.1186319 },
    { lat: 48.5425283, lng: 22.1186932 },
    { lat: 48.5419383, lng: 22.1185258 },
    { lat: 48.5408234, lng: 22.117946 },
    { lat: 48.5403526, lng: 22.1177837 },
    { lat: 48.5380542, lng: 22.117298 },
    { lat: 48.5380906, lng: 22.1194169 },
    { lat: 48.5384362, lng: 22.1197066 },
    { lat: 48.5388726, lng: 22.1200411 },
    { lat: 48.5392385, lng: 22.1206382 },
    { lat: 48.5396669, lng: 22.1210527 },
    { lat: 48.5399851, lng: 22.1220552 },
    { lat: 48.5403376, lng: 22.1225 },
    { lat: 48.5405443, lng: 22.1229714 },
    { lat: 48.5409326, lng: 22.1231712 },
    { lat: 48.5416333, lng: 22.1233089 },
    { lat: 48.5418544, lng: 22.1247259 },
    { lat: 48.5418464, lng: 22.1252475 },
    { lat: 48.5417218, lng: 22.1261245 },
    { lat: 48.5412628, lng: 22.1273704 },
    { lat: 48.5405391, lng: 22.1285051 },
    { lat: 48.5403746, lng: 22.1288784 },
    { lat: 48.5398986, lng: 22.1303044 },
    { lat: 48.5397693, lng: 22.13088 },
    { lat: 48.5395607, lng: 22.1313447 },
    { lat: 48.5392687, lng: 22.1325653 },
    { lat: 48.5393715, lng: 22.1327274 },
    { lat: 48.5398869, lng: 22.1331235 },
    { lat: 48.5404026, lng: 22.1330874 },
    { lat: 48.541862, lng: 22.1332079 },
    { lat: 48.5430489, lng: 22.1326848 },
    { lat: 48.5436393, lng: 22.1316748 },
    { lat: 48.5441981, lng: 22.1310846 },
    { lat: 48.5452012, lng: 22.1306964 },
    { lat: 48.5453775, lng: 22.1306282 },
    { lat: 48.5455336, lng: 22.130709 },
    { lat: 48.5457336, lng: 22.130653 },
    { lat: 48.5463867, lng: 22.1300566 },
    { lat: 48.5465958, lng: 22.1304815 },
    { lat: 48.5466662, lng: 22.1310408 },
    { lat: 48.546828, lng: 22.1312271 },
    { lat: 48.5472437, lng: 22.1312916 },
    { lat: 48.5474688, lng: 22.1311597 },
    { lat: 48.5475807, lng: 22.1311775 },
    { lat: 48.5477228, lng: 22.1312953 },
    { lat: 48.5482513, lng: 22.1314771 },
    { lat: 48.5492286, lng: 22.132143 },
    { lat: 48.5497773, lng: 22.1321718 },
    { lat: 48.5503142, lng: 22.131948 },
    { lat: 48.5508483, lng: 22.1318447 },
    { lat: 48.551514, lng: 22.1315441 },
    { lat: 48.5526926, lng: 22.1312404 },
    { lat: 48.5521237, lng: 22.1294357 },
    { lat: 48.552183, lng: 22.1293214 },
    { lat: 48.5521144, lng: 22.1291795 },
    { lat: 48.552251, lng: 22.1290526 },
    { lat: 48.5516461, lng: 22.1271011 },
    { lat: 48.5518259, lng: 22.1270485 },
    { lat: 48.5512373, lng: 22.1250184 },
    { lat: 48.5517096, lng: 22.1249141 },
    { lat: 48.5519815, lng: 22.1246152 },
    { lat: 48.5527589, lng: 22.1244758 },
    { lat: 48.5536566, lng: 22.1239476 },
    { lat: 48.5541972, lng: 22.1233997 },
    { lat: 48.5538802, lng: 22.1227877 },
    { lat: 48.5537355, lng: 22.1223782 },
    { lat: 48.5529629, lng: 22.1197042 },
    { lat: 48.5526384, lng: 22.1191069 },
    { lat: 48.5527945, lng: 22.1188564 },
    { lat: 48.5526208, lng: 22.118309 },
    { lat: 48.5518993, lng: 22.1152847 },
    { lat: 48.5524465, lng: 22.1151078 },
    { lat: 48.5531289, lng: 22.1144787 },
    { lat: 48.5525779, lng: 22.1116609 },
    { lat: 48.5524293, lng: 22.111758 },
    { lat: 48.5521987, lng: 22.1118048 },
    { lat: 48.5517539, lng: 22.1120253 },
    { lat: 48.551632, lng: 22.1116238 },
    { lat: 48.5517096, lng: 22.1114773 },
    { lat: 48.5512523, lng: 22.1101696 },
    { lat: 48.5509086, lng: 22.1087571 },
    { lat: 48.5509824, lng: 22.1086889 },
    { lat: 48.5508849, lng: 22.1082837 },
  ],
  Bajany: [
    { lat: 48.5803236, lng: 22.1101488 },
    { lat: 48.5800036, lng: 22.1106946 },
    { lat: 48.5809772, lng: 22.1127264 },
    { lat: 48.5822447, lng: 22.1159615 },
    { lat: 48.5834111, lng: 22.1211307 },
    { lat: 48.584154, lng: 22.1253136 },
    { lat: 48.5842728, lng: 22.1259674 },
    { lat: 48.5843141, lng: 22.1262022 },
    { lat: 48.5863499, lng: 22.1258028 },
    { lat: 48.5872716, lng: 22.125775 },
    { lat: 48.5881539, lng: 22.1265992 },
    { lat: 48.5890072, lng: 22.127549 },
    { lat: 48.5903296, lng: 22.127285 },
    { lat: 48.5914923, lng: 22.1283673 },
    { lat: 48.5930907, lng: 22.1300987 },
    { lat: 48.593712, lng: 22.130887 },
    { lat: 48.593936, lng: 22.130767 },
    { lat: 48.594138, lng: 22.130641 },
    { lat: 48.594241, lng: 22.13059 },
    { lat: 48.594688, lng: 22.130318 },
    { lat: 48.595387, lng: 22.129985 },
    { lat: 48.595977, lng: 22.129756 },
    { lat: 48.597707, lng: 22.12954 },
    { lat: 48.59777, lng: 22.129547 },
    { lat: 48.59781, lng: 22.129556 },
    { lat: 48.5979, lng: 22.129577 },
    { lat: 48.598064, lng: 22.129666 },
    { lat: 48.598277, lng: 22.129761 },
    { lat: 48.598454, lng: 22.129827 },
    { lat: 48.598642, lng: 22.129901 },
    { lat: 48.598862, lng: 22.129932 },
    { lat: 48.599024, lng: 22.129915 },
    { lat: 48.59913, lng: 22.129863 },
    { lat: 48.599381, lng: 22.129739 },
    { lat: 48.599672, lng: 22.129587 },
    { lat: 48.600034, lng: 22.129355 },
    { lat: 48.600329, lng: 22.129222 },
    { lat: 48.601293, lng: 22.129112 },
    { lat: 48.601854, lng: 22.129081 },
    { lat: 48.602164, lng: 22.129118 },
    { lat: 48.602448, lng: 22.129159 },
    { lat: 48.602572, lng: 22.129176 },
    { lat: 48.60285, lng: 22.129214 },
    { lat: 48.603643, lng: 22.129134 },
    { lat: 48.603901, lng: 22.129027 },
    { lat: 48.60424, lng: 22.128733 },
    { lat: 48.604444, lng: 22.12863 },
    { lat: 48.604578, lng: 22.128551 },
    { lat: 48.60463, lng: 22.128524 },
    { lat: 48.604984, lng: 22.12837 },
    { lat: 48.605035, lng: 22.128348 },
    { lat: 48.605107, lng: 22.128317 },
    { lat: 48.605176, lng: 22.128287 },
    { lat: 48.605538, lng: 22.128212 },
    { lat: 48.605763, lng: 22.128221 },
    { lat: 48.605985, lng: 22.12807 },
    { lat: 48.606077, lng: 22.128006 },
    { lat: 48.606201, lng: 22.127994 },
    { lat: 48.60632, lng: 22.12798 },
    { lat: 48.606378, lng: 22.127977 },
    { lat: 48.606427, lng: 22.127969 },
    { lat: 48.606298, lng: 22.127347 },
    { lat: 48.606247, lng: 22.126915 },
    { lat: 48.606236, lng: 22.125588 },
    { lat: 48.60627, lng: 22.125155 },
    { lat: 48.606508, lng: 22.124701 },
    { lat: 48.6067912, lng: 22.1238779 },
    { lat: 48.6068676, lng: 22.123304 },
    { lat: 48.6067974, lng: 22.122856 },
    { lat: 48.6066052, lng: 22.1222368 },
    { lat: 48.6062963, lng: 22.1214883 },
    { lat: 48.6062085, lng: 22.1207615 },
    { lat: 48.6062511, lng: 22.1205193 },
    { lat: 48.6063961, lng: 22.1201782 },
    { lat: 48.6065486, lng: 22.1200158 },
    { lat: 48.6068068, lng: 22.1199416 },
    { lat: 48.6071393, lng: 22.1199761 },
    { lat: 48.6075544, lng: 22.120256 },
    { lat: 48.6079987, lng: 22.120409 },
    { lat: 48.6082808, lng: 22.1203452 },
    { lat: 48.6084707, lng: 22.1201911 },
    { lat: 48.6088136, lng: 22.119656 },
    { lat: 48.6089458, lng: 22.1191097 },
    { lat: 48.6089606, lng: 22.1187765 },
    { lat: 48.6086332, lng: 22.1187791 },
    { lat: 48.6084105, lng: 22.1183488 },
    { lat: 48.6072599, lng: 22.1140973 },
    { lat: 48.6064607, lng: 22.1120717 },
    { lat: 48.6062277, lng: 22.1113434 },
    { lat: 48.6062014, lng: 22.1103301 },
    { lat: 48.6063416, lng: 22.1100162 },
    { lat: 48.6071184, lng: 22.1089523 },
    { lat: 48.60774, lng: 22.1079692 },
    { lat: 48.6086202, lng: 22.1068033 },
    { lat: 48.6075464, lng: 22.1063974 },
    { lat: 48.6073141, lng: 22.1060864 },
    { lat: 48.6065862, lng: 22.1025639 },
    { lat: 48.6064537, lng: 22.1023816 },
    { lat: 48.6064752, lng: 22.1021924 },
    { lat: 48.604722, lng: 22.0986516 },
    { lat: 48.6040108, lng: 22.0990176 },
    { lat: 48.6038122, lng: 22.0981344 },
    { lat: 48.6032582, lng: 22.0972096 },
    { lat: 48.602909, lng: 22.0961468 },
    { lat: 48.6023066, lng: 22.093986 },
    { lat: 48.6012042, lng: 22.0946933 },
    { lat: 48.5988453, lng: 22.0964528 },
    { lat: 48.5976958, lng: 22.0969859 },
    { lat: 48.5926526, lng: 22.1008195 },
    { lat: 48.5921344, lng: 22.1008371 },
    { lat: 48.5913721, lng: 22.1012266 },
    { lat: 48.5899916, lng: 22.102649 },
    { lat: 48.5885314, lng: 22.1042567 },
    { lat: 48.5878738, lng: 22.1050392 },
    { lat: 48.5867788, lng: 22.1068813 },
    { lat: 48.5860647, lng: 22.1074586 },
    { lat: 48.5848657, lng: 22.1080889 },
    { lat: 48.5812516, lng: 22.1090479 },
    { lat: 48.5810087, lng: 22.1089522 },
    { lat: 48.5809929, lng: 22.1090052 },
    { lat: 48.5803236, lng: 22.1101488 },
  ],
  Lesné: [
    { lat: 48.8001984, lng: 21.8388889 },
    { lat: 48.799686, lng: 21.8373037 },
    { lat: 48.7996591, lng: 21.8372215 },
    { lat: 48.8033785, lng: 21.8339151 },
    { lat: 48.8049514, lng: 21.8324051 },
    { lat: 48.8058413, lng: 21.8310086 },
    { lat: 48.8064918, lng: 21.8201743 },
    { lat: 48.8064588, lng: 21.8166069 },
    { lat: 48.806447, lng: 21.8164163 },
    { lat: 48.8061092, lng: 21.816445 },
    { lat: 48.8053721, lng: 21.8133676 },
    { lat: 48.8052813, lng: 21.8133391 },
    { lat: 48.8052026, lng: 21.8131686 },
    { lat: 48.8049731, lng: 21.8124282 },
    { lat: 48.8046434, lng: 21.8108783 },
    { lat: 48.8047212, lng: 21.8105339 },
    { lat: 48.804796, lng: 21.8104273 },
    { lat: 48.8051595, lng: 21.8102564 },
    { lat: 48.8052674, lng: 21.8075394 },
    { lat: 48.806047, lng: 21.80698 },
    { lat: 48.80613, lng: 21.805925 },
    { lat: 48.806095, lng: 21.805891 },
    { lat: 48.805749, lng: 21.805542 },
    { lat: 48.805719, lng: 21.805496 },
    { lat: 48.805205, lng: 21.804712 },
    { lat: 48.804707, lng: 21.804392 },
    { lat: 48.804268, lng: 21.803719 },
    { lat: 48.804253, lng: 21.803715 },
    { lat: 48.803745, lng: 21.803576 },
    { lat: 48.803712, lng: 21.803567 },
    { lat: 48.80256, lng: 21.803387 },
    { lat: 48.801859, lng: 21.803248 },
    { lat: 48.801147, lng: 21.803149 },
    { lat: 48.80076, lng: 21.803096 },
    { lat: 48.80073, lng: 21.80308 },
    { lat: 48.800689, lng: 21.803058 },
    { lat: 48.800607, lng: 21.803016 },
    { lat: 48.800556, lng: 21.802989 },
    { lat: 48.800539, lng: 21.802981 },
    { lat: 48.800561, lng: 21.802903 },
    { lat: 48.801075, lng: 21.80097 },
    { lat: 48.801333, lng: 21.800175 },
    { lat: 48.801387, lng: 21.800008 },
    { lat: 48.79883, lng: 21.800433 },
    { lat: 48.798802, lng: 21.800437 },
    { lat: 48.797929, lng: 21.800582 },
    { lat: 48.797872, lng: 21.800563 },
    { lat: 48.797826, lng: 21.800351 },
    { lat: 48.79682, lng: 21.800369 },
    { lat: 48.796689, lng: 21.800632 },
    { lat: 48.795948, lng: 21.800863 },
    { lat: 48.794616, lng: 21.801178 },
    { lat: 48.793767, lng: 21.801583 },
    { lat: 48.792912, lng: 21.80174 },
    { lat: 48.792166, lng: 21.801919 },
    { lat: 48.791611, lng: 21.802049 },
    { lat: 48.790537, lng: 21.802342 },
    { lat: 48.7902968, lng: 21.8026088 },
    { lat: 48.7896939, lng: 21.8028764 },
    { lat: 48.7894983, lng: 21.8027964 },
    { lat: 48.7893746, lng: 21.8028377 },
    { lat: 48.7889828, lng: 21.8036925 },
    { lat: 48.7888139, lng: 21.8043308 },
    { lat: 48.7886596, lng: 21.8046687 },
    { lat: 48.7883671, lng: 21.8046248 },
    { lat: 48.7880254, lng: 21.8044686 },
    { lat: 48.7878657, lng: 21.8046362 },
    { lat: 48.7878919, lng: 21.8048252 },
    { lat: 48.7877856, lng: 21.8051872 },
    { lat: 48.7878232, lng: 21.8059424 },
    { lat: 48.7877563, lng: 21.8061341 },
    { lat: 48.787662, lng: 21.8061418 },
    { lat: 48.7873523, lng: 21.8058286 },
    { lat: 48.7872684, lng: 21.8058478 },
    { lat: 48.7870221, lng: 21.8062828 },
    { lat: 48.78685, lng: 21.8068882 },
    { lat: 48.7868368, lng: 21.8070677 },
    { lat: 48.7869227, lng: 21.8072524 },
    { lat: 48.7869242, lng: 21.8074453 },
    { lat: 48.7867277, lng: 21.807974 },
    { lat: 48.7864809, lng: 21.808221 },
    { lat: 48.7862939, lng: 21.8086414 },
    { lat: 48.7861378, lng: 21.8088198 },
    { lat: 48.7858409, lng: 21.8093409 },
    { lat: 48.7856825, lng: 21.8098306 },
    { lat: 48.7854848, lng: 21.8100165 },
    { lat: 48.7856094, lng: 21.8106911 },
    { lat: 48.785589, lng: 21.8109924 },
    { lat: 48.7854184, lng: 21.8114827 },
    { lat: 48.7853686, lng: 21.8118178 },
    { lat: 48.7849273, lng: 21.8121075 },
    { lat: 48.7848422, lng: 21.8122506 },
    { lat: 48.7848341, lng: 21.8124088 },
    { lat: 48.7845675, lng: 21.8128456 },
    { lat: 48.7841285, lng: 21.8133138 },
    { lat: 48.7839385, lng: 21.8136137 },
    { lat: 48.7839647, lng: 21.8137581 },
    { lat: 48.7838722, lng: 21.8141018 },
    { lat: 48.7838253, lng: 21.8141998 },
    { lat: 48.7837122, lng: 21.8142579 },
    { lat: 48.7836973, lng: 21.8145539 },
    { lat: 48.7834974, lng: 21.8147827 },
    { lat: 48.7833416, lng: 21.8156688 },
    { lat: 48.7830993, lng: 21.8160421 },
    { lat: 48.783109, lng: 21.8163251 },
    { lat: 48.7832156, lng: 21.8167868 },
    { lat: 48.7833563, lng: 21.8171391 },
    { lat: 48.7834593, lng: 21.8172111 },
    { lat: 48.7834646, lng: 21.817626 },
    { lat: 48.7834092, lng: 21.8177866 },
    { lat: 48.7834531, lng: 21.8178793 },
    { lat: 48.7833348, lng: 21.8179987 },
    { lat: 48.783404, lng: 21.8181951 },
    { lat: 48.7832148, lng: 21.8184435 },
    { lat: 48.7831492, lng: 21.8182609 },
    { lat: 48.7830587, lng: 21.8184147 },
    { lat: 48.7831762, lng: 21.8185646 },
    { lat: 48.7831747, lng: 21.8187256 },
    { lat: 48.7831046, lng: 21.818885 },
    { lat: 48.7832484, lng: 21.8189649 },
    { lat: 48.7831447, lng: 21.8191644 },
    { lat: 48.7828263, lng: 21.8192622 },
    { lat: 48.7828614, lng: 21.8194773 },
    { lat: 48.782961, lng: 21.8196373 },
    { lat: 48.7829285, lng: 21.8198087 },
    { lat: 48.7828415, lng: 21.8198017 },
    { lat: 48.7828168, lng: 21.8201917 },
    { lat: 48.7827186, lng: 21.8201797 },
    { lat: 48.7825299, lng: 21.8204902 },
    { lat: 48.7822613, lng: 21.8204598 },
    { lat: 48.7822705, lng: 21.8205955 },
    { lat: 48.782494, lng: 21.8207988 },
    { lat: 48.7824762, lng: 21.8211562 },
    { lat: 48.782257, lng: 21.8211571 },
    { lat: 48.7821465, lng: 21.8212336 },
    { lat: 48.7820904, lng: 21.8211146 },
    { lat: 48.7820212, lng: 21.8212175 },
    { lat: 48.7820365, lng: 21.8214617 },
    { lat: 48.7819519, lng: 21.8214542 },
    { lat: 48.7819482, lng: 21.8216114 },
    { lat: 48.7820282, lng: 21.8217069 },
    { lat: 48.7820363, lng: 21.8217984 },
    { lat: 48.7819257, lng: 21.8218703 },
    { lat: 48.7819157, lng: 21.8221402 },
    { lat: 48.7818557, lng: 21.8222989 },
    { lat: 48.7819644, lng: 21.8224997 },
    { lat: 48.7819199, lng: 21.8226918 },
    { lat: 48.7817737, lng: 21.8228282 },
    { lat: 48.7818627, lng: 21.8231308 },
    { lat: 48.7816668, lng: 21.8232598 },
    { lat: 48.7816076, lng: 21.8236675 },
    { lat: 48.7816429, lng: 21.8240289 },
    { lat: 48.7821235, lng: 21.8251129 },
    { lat: 48.7822108, lng: 21.82558 },
    { lat: 48.7821076, lng: 21.8259846 },
    { lat: 48.7821947, lng: 21.8261222 },
    { lat: 48.7822881, lng: 21.8264348 },
    { lat: 48.7822767, lng: 21.82686 },
    { lat: 48.7823566, lng: 21.8269926 },
    { lat: 48.7822975, lng: 21.8270528 },
    { lat: 48.7822958, lng: 21.8272034 },
    { lat: 48.78248, lng: 21.8277604 },
    { lat: 48.7823924, lng: 21.8281113 },
    { lat: 48.7823997, lng: 21.8284826 },
    { lat: 48.7823636, lng: 21.8286459 },
    { lat: 48.7824811, lng: 21.829544 },
    { lat: 48.7823856, lng: 21.8297587 },
    { lat: 48.782376, lng: 21.8299084 },
    { lat: 48.7821745, lng: 21.830165 },
    { lat: 48.7820516, lng: 21.8305897 },
    { lat: 48.7820593, lng: 21.8308436 },
    { lat: 48.7821706, lng: 21.8311445 },
    { lat: 48.7821302, lng: 21.8312778 },
    { lat: 48.782173, lng: 21.8315474 },
    { lat: 48.7821488, lng: 21.8320879 },
    { lat: 48.7820613, lng: 21.8324872 },
    { lat: 48.782154, lng: 21.8330257 },
    { lat: 48.7823207, lng: 21.8333808 },
    { lat: 48.7823735, lng: 21.8336381 },
    { lat: 48.7823533, lng: 21.8339043 },
    { lat: 48.7824409, lng: 21.8349871 },
    { lat: 48.7824449, lng: 21.8351849 },
    { lat: 48.7823097, lng: 21.8360016 },
    { lat: 48.782332, lng: 21.8388726 },
    { lat: 48.7823615, lng: 21.8391826 },
    { lat: 48.7827367, lng: 21.8409505 },
    { lat: 48.7828626, lng: 21.8419926 },
    { lat: 48.7828462, lng: 21.8436238 },
    { lat: 48.7827186, lng: 21.8446562 },
    { lat: 48.7828039, lng: 21.8446918 },
    { lat: 48.7853143, lng: 21.8439229 },
    { lat: 48.7881931, lng: 21.8428803 },
    { lat: 48.7891071, lng: 21.8430268 },
    { lat: 48.7956606, lng: 21.8395148 },
    { lat: 48.8003568, lng: 21.8394451 },
    { lat: 48.8001984, lng: 21.8388889 },
  ],
  Vinné: [
    { lat: 48.7838315, lng: 22.0035594 },
    { lat: 48.7862899, lng: 22.0028934 },
    { lat: 48.7992943, lng: 21.9993692 },
    { lat: 48.7996976, lng: 21.9995354 },
    { lat: 48.7999287, lng: 21.9995078 },
    { lat: 48.8001233, lng: 21.999417 },
    { lat: 48.8007081, lng: 21.9987709 },
    { lat: 48.8013967, lng: 21.9990042 },
    { lat: 48.8019694, lng: 21.9990128 },
    { lat: 48.8028198, lng: 21.9982255 },
    { lat: 48.8034602, lng: 21.9977824 },
    { lat: 48.8043777, lng: 21.997533 },
    { lat: 48.8047931, lng: 21.9972226 },
    { lat: 48.8054746, lng: 21.9958775 },
    { lat: 48.8058646, lng: 21.994218 },
    { lat: 48.8064992, lng: 21.9935354 },
    { lat: 48.8072868, lng: 21.9916526 },
    { lat: 48.8071673, lng: 21.9911422 },
    { lat: 48.8065913, lng: 21.9893952 },
    { lat: 48.8069094, lng: 21.9891971 },
    { lat: 48.8086388, lng: 21.988667 },
    { lat: 48.8096479, lng: 21.9890792 },
    { lat: 48.8102649, lng: 21.9888743 },
    { lat: 48.8108888, lng: 21.9888114 },
    { lat: 48.8121886, lng: 21.9892962 },
    { lat: 48.8127063, lng: 21.9892603 },
    { lat: 48.8146015, lng: 21.9885596 },
    { lat: 48.8168082, lng: 21.9895149 },
    { lat: 48.8179591, lng: 21.9914423 },
    { lat: 48.8179807, lng: 21.9913633 },
    { lat: 48.8187633, lng: 21.990941 },
    { lat: 48.8187644, lng: 21.9908891 },
    { lat: 48.8189063, lng: 21.9909447 },
    { lat: 48.8193557, lng: 21.9906435 },
    { lat: 48.8329614, lng: 21.9904699 },
    { lat: 48.8358816, lng: 21.9909756 },
    { lat: 48.8364464, lng: 21.9908425 },
    { lat: 48.8374313, lng: 21.9905008 },
    { lat: 48.8374514, lng: 21.9904432 },
    { lat: 48.8414347, lng: 21.9907771 },
    { lat: 48.847489, lng: 21.991091 },
    { lat: 48.847565, lng: 21.989929 },
    { lat: 48.847837, lng: 21.988522 },
    { lat: 48.847883, lng: 21.988182 },
    { lat: 48.847959, lng: 21.9874 },
    { lat: 48.847782, lng: 21.986572 },
    { lat: 48.84773, lng: 21.984363 },
    { lat: 48.847743, lng: 21.98417 },
    { lat: 48.847789, lng: 21.982643 },
    { lat: 48.847768, lng: 21.981799 },
    { lat: 48.847853, lng: 21.981126 },
    { lat: 48.847878, lng: 21.98063 },
    { lat: 48.847824, lng: 21.97956 },
    { lat: 48.848076, lng: 21.978001 },
    { lat: 48.848144, lng: 21.977134 },
    { lat: 48.848155, lng: 21.976739 },
    { lat: 48.84832, lng: 21.976271 },
    { lat: 48.848475, lng: 21.97515 },
    { lat: 48.848808, lng: 21.974242 },
    { lat: 48.84892, lng: 21.973651 },
    { lat: 48.8489, lng: 21.973169 },
    { lat: 48.848943, lng: 21.972942 },
    { lat: 48.848942, lng: 21.972518 },
    { lat: 48.848967, lng: 21.97221 },
    { lat: 48.848975, lng: 21.972112 },
    { lat: 48.848492, lng: 21.972245 },
    { lat: 48.848299, lng: 21.972401 },
    { lat: 48.848147, lng: 21.972465 },
    { lat: 48.84766, lng: 21.972599 },
    { lat: 48.847474, lng: 21.972583 },
    { lat: 48.847348, lng: 21.972573 },
    { lat: 48.846319, lng: 21.972207 },
    { lat: 48.846287, lng: 21.972181 },
    { lat: 48.846313, lng: 21.971292 },
    { lat: 48.846421, lng: 21.970516 },
    { lat: 48.846504, lng: 21.969937 },
    { lat: 48.846563, lng: 21.969615 },
    { lat: 48.846571, lng: 21.969552 },
    { lat: 48.846662, lng: 21.969311 },
    { lat: 48.84668, lng: 21.969314 },
    { lat: 48.846934, lng: 21.968812 },
    { lat: 48.847076, lng: 21.968462 },
    { lat: 48.847135, lng: 21.968271 },
    { lat: 48.847159, lng: 21.968234 },
    { lat: 48.847179, lng: 21.968101 },
    { lat: 48.84721, lng: 21.967901 },
    { lat: 48.847231, lng: 21.967573 },
    { lat: 48.8473, lng: 21.967404 },
    { lat: 48.847272, lng: 21.967175 },
    { lat: 48.84726, lng: 21.967084 },
    { lat: 48.84729, lng: 21.966894 },
    { lat: 48.847402, lng: 21.966601 },
    { lat: 48.847496, lng: 21.966487 },
    { lat: 48.847528, lng: 21.966355 },
    { lat: 48.847643, lng: 21.966311 },
    { lat: 48.847645, lng: 21.966298 },
    { lat: 48.847648, lng: 21.966265 },
    { lat: 48.847677, lng: 21.965998 },
    { lat: 48.847624, lng: 21.965651 },
    { lat: 48.847665, lng: 21.965533 },
    { lat: 48.847672, lng: 21.965301 },
    { lat: 48.847623, lng: 21.965319 },
    { lat: 48.847511, lng: 21.965206 },
    { lat: 48.847455, lng: 21.965027 },
    { lat: 48.847483, lng: 21.964851 },
    { lat: 48.847459, lng: 21.964656 },
    { lat: 48.847491, lng: 21.964555 },
    { lat: 48.847463, lng: 21.964338 },
    { lat: 48.847434, lng: 21.964233 },
    { lat: 48.847449, lng: 21.964088 },
    { lat: 48.84739, lng: 21.964051 },
    { lat: 48.847367, lng: 21.963904 },
    { lat: 48.847399, lng: 21.963743 },
    { lat: 48.847466, lng: 21.963681 },
    { lat: 48.847495, lng: 21.96349 },
    { lat: 48.847508, lng: 21.9634 },
    { lat: 48.847536, lng: 21.963402 },
    { lat: 48.847563, lng: 21.963196 },
    { lat: 48.847649, lng: 21.963086 },
    { lat: 48.847655, lng: 21.962787 },
    { lat: 48.847701, lng: 21.962682 },
    { lat: 48.847691, lng: 21.962577 },
    { lat: 48.847594, lng: 21.962366 },
    { lat: 48.847523, lng: 21.962272 },
    { lat: 48.847524, lng: 21.962122 },
    { lat: 48.84756, lng: 21.962016 },
    { lat: 48.847522, lng: 21.961927 },
    { lat: 48.847467, lng: 21.961919 },
    { lat: 48.847485, lng: 21.961594 },
    { lat: 48.847514, lng: 21.961443 },
    { lat: 48.847477, lng: 21.961384 },
    { lat: 48.847598, lng: 21.961234 },
    { lat: 48.847624, lng: 21.961152 },
    { lat: 48.847718, lng: 21.960844 },
    { lat: 48.847716, lng: 21.960739 },
    { lat: 48.84761, lng: 21.960529 },
    { lat: 48.84758, lng: 21.960198 },
    { lat: 48.847758, lng: 21.960048 },
    { lat: 48.847851, lng: 21.959874 },
    { lat: 48.847874, lng: 21.95967 },
    { lat: 48.847905, lng: 21.959582 },
    { lat: 48.847908, lng: 21.959014 },
    { lat: 48.847985, lng: 21.958744 },
    { lat: 48.848164, lng: 21.95855 },
    { lat: 48.848253, lng: 21.958352 },
    { lat: 48.848252, lng: 21.957986 },
    { lat: 48.848297, lng: 21.957862 },
    { lat: 48.848481, lng: 21.957776 },
    { lat: 48.848577, lng: 21.95751 },
    { lat: 48.848648, lng: 21.957359 },
    { lat: 48.848685, lng: 21.957074 },
    { lat: 48.848866, lng: 21.956969 },
    { lat: 48.848906, lng: 21.957032 },
    { lat: 48.848986, lng: 21.956939 },
    { lat: 48.848985, lng: 21.956526 },
    { lat: 48.849041, lng: 21.956377 },
    { lat: 48.849132, lng: 21.956305 },
    { lat: 48.849143, lng: 21.956226 },
    { lat: 48.849418, lng: 21.955975 },
    { lat: 48.849381, lng: 21.95548 },
    { lat: 48.849437, lng: 21.95533 },
    { lat: 48.849636, lng: 21.955084 },
    { lat: 48.849636, lng: 21.954656 },
    { lat: 48.849595, lng: 21.954499 },
    { lat: 48.84959, lng: 21.954327 },
    { lat: 48.849341, lng: 21.954005 },
    { lat: 48.849342, lng: 21.953643 },
    { lat: 48.849333, lng: 21.953639 },
    { lat: 48.8492, lng: 21.953574 },
    { lat: 48.849063, lng: 21.953177 },
    { lat: 48.848924, lng: 21.952891 },
    { lat: 48.849017, lng: 21.952703 },
    { lat: 48.849145, lng: 21.952694 },
    { lat: 48.849395, lng: 21.952789 },
    { lat: 48.849601, lng: 21.952709 },
    { lat: 48.849706, lng: 21.952542 },
    { lat: 48.849844, lng: 21.952355 },
    { lat: 48.850056, lng: 21.951936 },
    { lat: 48.850122, lng: 21.951891 },
    { lat: 48.850179, lng: 21.951894 },
    { lat: 48.850201, lng: 21.951862 },
    { lat: 48.850293, lng: 21.951722 },
    { lat: 48.850477, lng: 21.951315 },
    { lat: 48.850697, lng: 21.951081 },
    { lat: 48.850898, lng: 21.950815 },
    { lat: 48.851, lng: 21.950668 },
    { lat: 48.851081, lng: 21.950486 },
    { lat: 48.851255, lng: 21.950376 },
    { lat: 48.851319, lng: 21.950025 },
    { lat: 48.851406, lng: 21.949875 },
    { lat: 48.851459, lng: 21.949602 },
    { lat: 48.8506095, lng: 21.9495494 },
    { lat: 48.849544, lng: 21.9499448 },
    { lat: 48.8477302, lng: 21.9501118 },
    { lat: 48.846051, lng: 21.9501243 },
    { lat: 48.8435189, lng: 21.9497169 },
    { lat: 48.8428593, lng: 21.9499227 },
    { lat: 48.8405568, lng: 21.9504265 },
    { lat: 48.8390152, lng: 21.9500022 },
    { lat: 48.8377138, lng: 21.9500114 },
    { lat: 48.8369985, lng: 21.949873 },
    { lat: 48.8349938, lng: 21.9464829 },
    { lat: 48.8345872, lng: 21.9456049 },
    { lat: 48.8338547, lng: 21.9450493 },
    { lat: 48.8314489, lng: 21.9445676 },
    { lat: 48.830421, lng: 21.9440768 },
    { lat: 48.8296334, lng: 21.9439829 },
    { lat: 48.8269199, lng: 21.9428433 },
    { lat: 48.8268031, lng: 21.9433505 },
    { lat: 48.8260881, lng: 21.944542 },
    { lat: 48.8260014, lng: 21.9457144 },
    { lat: 48.8243925, lng: 21.9468297 },
    { lat: 48.8230771, lng: 21.9468187 },
    { lat: 48.8218404, lng: 21.9477521 },
    { lat: 48.8189825, lng: 21.9482645 },
    { lat: 48.8163236, lng: 21.9439019 },
    { lat: 48.8161641, lng: 21.9439998 },
    { lat: 48.8161564, lng: 21.9440995 },
    { lat: 48.8165918, lng: 21.944912 },
    { lat: 48.8166315, lng: 21.9451075 },
    { lat: 48.8166094, lng: 21.9453094 },
    { lat: 48.8167192, lng: 21.9458721 },
    { lat: 48.8165619, lng: 21.9485924 },
    { lat: 48.8162833, lng: 21.9485144 },
    { lat: 48.815081, lng: 21.947802 },
    { lat: 48.8124188, lng: 21.948099 },
    { lat: 48.8121652, lng: 21.9484404 },
    { lat: 48.8121221, lng: 21.948329 },
    { lat: 48.8106779, lng: 21.9482472 },
    { lat: 48.8041818, lng: 21.9473071 },
    { lat: 48.8039909, lng: 21.9439771 },
    { lat: 48.802067, lng: 21.9446414 },
    { lat: 48.8011655, lng: 21.9396643 },
    { lat: 48.7995479, lng: 21.9400701 },
    { lat: 48.7987177, lng: 21.9403834 },
    { lat: 48.7976154, lng: 21.9355741 },
    { lat: 48.7969054, lng: 21.9351152 },
    { lat: 48.7964525, lng: 21.93482 },
    { lat: 48.7960935, lng: 21.9359303 },
    { lat: 48.7953725, lng: 21.9377872 },
    { lat: 48.7946929, lng: 21.9391352 },
    { lat: 48.7937681, lng: 21.9402577 },
    { lat: 48.7934631, lng: 21.9405145 },
    { lat: 48.792844, lng: 21.9414101 },
    { lat: 48.7917284, lng: 21.9423208 },
    { lat: 48.7916677, lng: 21.9412868 },
    { lat: 48.7913547, lng: 21.9405504 },
    { lat: 48.790709, lng: 21.9411817 },
    { lat: 48.789975, lng: 21.9413906 },
    { lat: 48.7898089, lng: 21.9404604 },
    { lat: 48.7895058, lng: 21.94134 },
    { lat: 48.7883375, lng: 21.9377961 },
    { lat: 48.7873302, lng: 21.9384551 },
    { lat: 48.785877, lng: 21.9392716 },
    { lat: 48.7852039, lng: 21.9397492 },
    { lat: 48.783824, lng: 21.9402237 },
    { lat: 48.7835056, lng: 21.940168 },
    { lat: 48.7818679, lng: 21.9395528 },
    { lat: 48.7811395, lng: 21.9404295 },
    { lat: 48.7797404, lng: 21.9412503 },
    { lat: 48.778713, lng: 21.9420473 },
    { lat: 48.7779379, lng: 21.9424089 },
    { lat: 48.7777365, lng: 21.9425941 },
    { lat: 48.7772323, lng: 21.9423943 },
    { lat: 48.7761684, lng: 21.9418248 },
    { lat: 48.7754267, lng: 21.940677 },
    { lat: 48.7750703, lng: 21.94033 },
    { lat: 48.7748006, lng: 21.9397516 },
    { lat: 48.7729524, lng: 21.9423301 },
    { lat: 48.772531, lng: 21.9427486 },
    { lat: 48.7714641, lng: 21.9440121 },
    { lat: 48.7699851, lng: 21.9457636 },
    { lat: 48.7708404, lng: 21.9475921 },
    { lat: 48.7667488, lng: 21.9480744 },
    { lat: 48.7668461, lng: 21.9494528 },
    { lat: 48.7668296, lng: 21.9497394 },
    { lat: 48.7665736, lng: 21.9510256 },
    { lat: 48.7666067, lng: 21.9512669 },
    { lat: 48.7667627, lng: 21.951437 },
    { lat: 48.7668592, lng: 21.951837 },
    { lat: 48.7664952, lng: 21.9527548 },
    { lat: 48.7661063, lng: 21.9534498 },
    { lat: 48.7660871, lng: 21.9548031 },
    { lat: 48.766439, lng: 21.9562936 },
    { lat: 48.769464, lng: 21.9607838 },
    { lat: 48.7685199, lng: 21.9612335 },
    { lat: 48.7707305, lng: 21.9671435 },
    { lat: 48.7715992, lng: 21.9651543 },
    { lat: 48.7777747, lng: 21.9702373 },
    { lat: 48.7803739, lng: 21.9857449 },
    { lat: 48.7834532, lng: 22.0016112 },
    { lat: 48.7838315, lng: 22.0035594 },
  ],
  Laškovce: [
    { lat: 48.6973117, lng: 21.8592745 },
    { lat: 48.6973094, lng: 21.8593593 },
    { lat: 48.6973181, lng: 21.8603129 },
    { lat: 48.6969726, lng: 21.8606569 },
    { lat: 48.6969592, lng: 21.8613179 },
    { lat: 48.6967455, lng: 21.8616239 },
    { lat: 48.6970044, lng: 21.8632518 },
    { lat: 48.6970728, lng: 21.8633824 },
    { lat: 48.6970832, lng: 21.8632565 },
    { lat: 48.6981604, lng: 21.8638084 },
    { lat: 48.6987541, lng: 21.8642356 },
    { lat: 48.6988905, lng: 21.8642425 },
    { lat: 48.6990509, lng: 21.8641348 },
    { lat: 48.6993792, lng: 21.8623317 },
    { lat: 48.6995536, lng: 21.861933 },
    { lat: 48.6997134, lng: 21.8618054 },
    { lat: 48.6999829, lng: 21.861707 },
    { lat: 48.7008119, lng: 21.8615654 },
    { lat: 48.7010367, lng: 21.8623041 },
    { lat: 48.7012354, lng: 21.8625499 },
    { lat: 48.7015635, lng: 21.8619231 },
    { lat: 48.7017791, lng: 21.8618642 },
    { lat: 48.7020173, lng: 21.8618944 },
    { lat: 48.7022073, lng: 21.86163 },
    { lat: 48.7023895, lng: 21.8613159 },
    { lat: 48.7027596, lng: 21.8599116 },
    { lat: 48.7029595, lng: 21.85959 },
    { lat: 48.7032203, lng: 21.8592795 },
    { lat: 48.7036438, lng: 21.8589074 },
    { lat: 48.7045175, lng: 21.8582303 },
    { lat: 48.7049419, lng: 21.8581118 },
    { lat: 48.7056179, lng: 21.8581102 },
    { lat: 48.7055802, lng: 21.8577324 },
    { lat: 48.7056058, lng: 21.857676 },
    { lat: 48.7059294, lng: 21.8581037 },
    { lat: 48.7060123, lng: 21.8581287 },
    { lat: 48.7061445, lng: 21.8580619 },
    { lat: 48.7065131, lng: 21.8575238 },
    { lat: 48.7066708, lng: 21.8574415 },
    { lat: 48.7068338, lng: 21.8574839 },
    { lat: 48.7070866, lng: 21.8584764 },
    { lat: 48.7071906, lng: 21.8586253 },
    { lat: 48.7074094, lng: 21.8587813 },
    { lat: 48.7077711, lng: 21.8588487 },
    { lat: 48.7080235, lng: 21.8588172 },
    { lat: 48.7081424, lng: 21.8587219 },
    { lat: 48.7082971, lng: 21.8584752 },
    { lat: 48.7084437, lng: 21.8580057 },
    { lat: 48.7085093, lng: 21.8579349 },
    { lat: 48.7087976, lng: 21.857861 },
    { lat: 48.7088069, lng: 21.8577838 },
    { lat: 48.7089455, lng: 21.856607 },
    { lat: 48.7088278, lng: 21.8557993 },
    { lat: 48.708833, lng: 21.8554794 },
    { lat: 48.7087041, lng: 21.8540529 },
    { lat: 48.708593, lng: 21.8538824 },
    { lat: 48.7081082, lng: 21.8522329 },
    { lat: 48.7078227, lng: 21.851674 },
    { lat: 48.7081388, lng: 21.8511041 },
    { lat: 48.7079302, lng: 21.8486817 },
    { lat: 48.7084238, lng: 21.8484521 },
    { lat: 48.7085615, lng: 21.8475083 },
    { lat: 48.7087399, lng: 21.847709 },
    { lat: 48.708883, lng: 21.8471958 },
    { lat: 48.7089817, lng: 21.8472655 },
    { lat: 48.7091101, lng: 21.8467967 },
    { lat: 48.7093296, lng: 21.8469373 },
    { lat: 48.7097295, lng: 21.8458141 },
    { lat: 48.7109382, lng: 21.8431318 },
    { lat: 48.7089437, lng: 21.8409227 },
    { lat: 48.7099629, lng: 21.8392168 },
    { lat: 48.7093986, lng: 21.8373248 },
    { lat: 48.7088439, lng: 21.8373626 },
    { lat: 48.7086444, lng: 21.8375713 },
    { lat: 48.7085244, lng: 21.8377986 },
    { lat: 48.7082316, lng: 21.8378778 },
    { lat: 48.7081388, lng: 21.8382933 },
    { lat: 48.7078463, lng: 21.8383312 },
    { lat: 48.7077853, lng: 21.8386714 },
    { lat: 48.7071082, lng: 21.8391998 },
    { lat: 48.7070159, lng: 21.8393302 },
    { lat: 48.7067017, lng: 21.8390578 },
    { lat: 48.7065294, lng: 21.8392681 },
    { lat: 48.7064481, lng: 21.8391251 },
    { lat: 48.7051494, lng: 21.8386262 },
    { lat: 48.7040009, lng: 21.8380707 },
    { lat: 48.7024854, lng: 21.8371904 },
    { lat: 48.7001354, lng: 21.8362077 },
    { lat: 48.6998231, lng: 21.8361824 },
    { lat: 48.6985085, lng: 21.835551 },
    { lat: 48.6977545, lng: 21.8352726 },
    { lat: 48.6976501, lng: 21.8352385 },
    { lat: 48.6958858, lng: 21.8369706 },
    { lat: 48.6939776, lng: 21.8383984 },
    { lat: 48.6926289, lng: 21.8393188 },
    { lat: 48.6920623, lng: 21.8396413 },
    { lat: 48.6914499, lng: 21.8398007 },
    { lat: 48.6895154, lng: 21.841212 },
    { lat: 48.6889096, lng: 21.8415817 },
    { lat: 48.6866039, lng: 21.8427339 },
    { lat: 48.6864252, lng: 21.8420402 },
    { lat: 48.685783, lng: 21.8424426 },
    { lat: 48.6857076, lng: 21.8422256 },
    { lat: 48.6846183, lng: 21.8426494 },
    { lat: 48.6836202, lng: 21.8429397 },
    { lat: 48.6834591, lng: 21.8492555 },
    { lat: 48.6836081, lng: 21.8491837 },
    { lat: 48.685676, lng: 21.8480577 },
    { lat: 48.6857551, lng: 21.848465 },
    { lat: 48.6859336, lng: 21.8484018 },
    { lat: 48.6859663, lng: 21.8485811 },
    { lat: 48.6872225, lng: 21.8484765 },
    { lat: 48.6872679, lng: 21.8486295 },
    { lat: 48.6881119, lng: 21.8482006 },
    { lat: 48.6883896, lng: 21.8494526 },
    { lat: 48.6887844, lng: 21.849194 },
    { lat: 48.6889281, lng: 21.8496555 },
    { lat: 48.6892392, lng: 21.849461 },
    { lat: 48.6893344, lng: 21.8497523 },
    { lat: 48.6901917, lng: 21.8492743 },
    { lat: 48.6902697, lng: 21.8495093 },
    { lat: 48.6915277, lng: 21.8488631 },
    { lat: 48.6921402, lng: 21.8509739 },
    { lat: 48.6927414, lng: 21.850649 },
    { lat: 48.6930019, lng: 21.850586 },
    { lat: 48.6931747, lng: 21.85061 },
    { lat: 48.6935509, lng: 21.8509746 },
    { lat: 48.694017, lng: 21.8511128 },
    { lat: 48.6943509, lng: 21.8511523 },
    { lat: 48.6950739, lng: 21.8510525 },
    { lat: 48.695934, lng: 21.8513779 },
    { lat: 48.6960345, lng: 21.851481 },
    { lat: 48.6960696, lng: 21.852402 },
    { lat: 48.6964871, lng: 21.8525017 },
    { lat: 48.696988, lng: 21.8528573 },
    { lat: 48.697315, lng: 21.8554687 },
    { lat: 48.6977094, lng: 21.8592572 },
    { lat: 48.6973117, lng: 21.8592745 },
  ],
  JastrabiepriMichalovciach: [
    { lat: 48.7329354, lng: 22.0267735 },
    { lat: 48.7322838, lng: 22.0245365 },
    { lat: 48.7322016, lng: 22.0229055 },
    { lat: 48.7322213, lng: 22.022609 },
    { lat: 48.7323168, lng: 22.0221362 },
    { lat: 48.7325585, lng: 22.0215772 },
    { lat: 48.7329004, lng: 22.0211139 },
    { lat: 48.7312001, lng: 22.0194138 },
    { lat: 48.7308995, lng: 22.0190324 },
    { lat: 48.7297545, lng: 22.0196918 },
    { lat: 48.728404, lng: 22.0197161 },
    { lat: 48.7265153, lng: 22.0186968 },
    { lat: 48.7198723, lng: 22.0234941 },
    { lat: 48.719802, lng: 22.0234243 },
    { lat: 48.7197732, lng: 22.0212691 },
    { lat: 48.7195457, lng: 22.0169034 },
    { lat: 48.7181483, lng: 22.0112567 },
    { lat: 48.7085592, lng: 22.0106434 },
    { lat: 48.7084593, lng: 22.0106069 },
    { lat: 48.7084264, lng: 22.0106885 },
    { lat: 48.7083533, lng: 22.0108739 },
    { lat: 48.7083738, lng: 22.0114323 },
    { lat: 48.7083108, lng: 22.0122628 },
    { lat: 48.7082436, lng: 22.0123684 },
    { lat: 48.7078727, lng: 22.0125671 },
    { lat: 48.7077051, lng: 22.0127524 },
    { lat: 48.7076741, lng: 22.0129593 },
    { lat: 48.7078964, lng: 22.0143741 },
    { lat: 48.7078961, lng: 22.0145921 },
    { lat: 48.7078167, lng: 22.0148454 },
    { lat: 48.7071758, lng: 22.0158726 },
    { lat: 48.7070283, lng: 22.0162822 },
    { lat: 48.7067618, lng: 22.0165203 },
    { lat: 48.7063991, lng: 22.0166786 },
    { lat: 48.7062708, lng: 22.0168215 },
    { lat: 48.7062102, lng: 22.0169651 },
    { lat: 48.7062019, lng: 22.0171294 },
    { lat: 48.7063604, lng: 22.017871 },
    { lat: 48.7063586, lng: 22.0181141 },
    { lat: 48.7063, lng: 22.018294 },
    { lat: 48.7058847, lng: 22.0189371 },
    { lat: 48.7058271, lng: 22.0191173 },
    { lat: 48.7060581, lng: 22.0200579 },
    { lat: 48.7061041, lng: 22.0206494 },
    { lat: 48.7061738, lng: 22.0209775 },
    { lat: 48.7064345, lng: 22.0218901 },
    { lat: 48.7066454, lng: 22.0231171 },
    { lat: 48.706739, lng: 22.0243318 },
    { lat: 48.7067542, lng: 22.0255639 },
    { lat: 48.7069704, lng: 22.0261264 },
    { lat: 48.7074761, lng: 22.0269365 },
    { lat: 48.707637, lng: 22.0274992 },
    { lat: 48.7077282, lng: 22.028099 },
    { lat: 48.7077266, lng: 22.0282184 },
    { lat: 48.7074606, lng: 22.0290423 },
    { lat: 48.7072753, lng: 22.0292352 },
    { lat: 48.7075032, lng: 22.0295453 },
    { lat: 48.7076872, lng: 22.0301748 },
    { lat: 48.707996, lng: 22.0306582 },
    { lat: 48.7081399, lng: 22.0310744 },
    { lat: 48.7081393, lng: 22.0313793 },
    { lat: 48.7080719, lng: 22.0315668 },
    { lat: 48.7071211, lng: 22.0327672 },
    { lat: 48.7070241, lng: 22.0330746 },
    { lat: 48.7070128, lng: 22.0337769 },
    { lat: 48.7069322, lng: 22.0340709 },
    { lat: 48.7065596, lng: 22.0345694 },
    { lat: 48.7065068, lng: 22.0348562 },
    { lat: 48.7065606, lng: 22.0350829 },
    { lat: 48.7066951, lng: 22.0352896 },
    { lat: 48.7070327, lng: 22.0355765 },
    { lat: 48.7072218, lng: 22.0359468 },
    { lat: 48.7072275, lng: 22.036417 },
    { lat: 48.7070607, lng: 22.0371064 },
    { lat: 48.7070122, lng: 22.0375191 },
    { lat: 48.7070303, lng: 22.0378981 },
    { lat: 48.7071694, lng: 22.0382993 },
    { lat: 48.7074446, lng: 22.0386527 },
    { lat: 48.707551, lng: 22.0388741 },
    { lat: 48.7077083, lng: 22.0397469 },
    { lat: 48.707874, lng: 22.0400384 },
    { lat: 48.7083276, lng: 22.0400102 },
    { lat: 48.7089904, lng: 22.0398323 },
    { lat: 48.7099504, lng: 22.0403173 },
    { lat: 48.7105956, lng: 22.0412776 },
    { lat: 48.7109537, lng: 22.0419931 },
    { lat: 48.7110137, lng: 22.042191 },
    { lat: 48.7110345, lng: 22.042499 },
    { lat: 48.7106901, lng: 22.0481346 },
    { lat: 48.7138583, lng: 22.046476 },
    { lat: 48.7143652, lng: 22.0461339 },
    { lat: 48.7175612, lng: 22.0426354 },
    { lat: 48.7179193, lng: 22.0421896 },
    { lat: 48.7180536, lng: 22.0418726 },
    { lat: 48.7184072, lng: 22.0418934 },
    { lat: 48.719358, lng: 22.050034 },
    { lat: 48.7217354, lng: 22.0497278 },
    { lat: 48.7217287, lng: 22.0499601 },
    { lat: 48.7217846, lng: 22.0501359 },
    { lat: 48.7222514, lng: 22.0504134 },
    { lat: 48.7224616, lng: 22.0504521 },
    { lat: 48.7232008, lng: 22.0503246 },
    { lat: 48.7241931, lng: 22.0495359 },
    { lat: 48.7241377, lng: 22.0491941 },
    { lat: 48.7241377, lng: 22.048769 },
    { lat: 48.7241967, lng: 22.0484216 },
    { lat: 48.7243158, lng: 22.0481308 },
    { lat: 48.725441, lng: 22.0466563 },
    { lat: 48.7257458, lng: 22.0464603 },
    { lat: 48.7259229, lng: 22.0464317 },
    { lat: 48.727328, lng: 22.0465771 },
    { lat: 48.7275085, lng: 22.0465081 },
    { lat: 48.7277489, lng: 22.0463089 },
    { lat: 48.7303982, lng: 22.042161 },
    { lat: 48.7299586, lng: 22.0399746 },
    { lat: 48.7295253, lng: 22.0372634 },
    { lat: 48.7289754, lng: 22.0344746 },
    { lat: 48.7284847, lng: 22.0316217 },
    { lat: 48.7280896, lng: 22.0288739 },
    { lat: 48.7330731, lng: 22.0280024 },
    { lat: 48.73306, lng: 22.0278823 },
    { lat: 48.7329354, lng: 22.0267735 },
  ],
  Zbudza: [
    { lat: 48.794858, lng: 21.8890166 },
    { lat: 48.7949959, lng: 21.8907358 },
    { lat: 48.7951272, lng: 21.8923609 },
    { lat: 48.7953861, lng: 21.8924105 },
    { lat: 48.7954351, lng: 21.8955903 },
    { lat: 48.7955895, lng: 21.8999576 },
    { lat: 48.7961829, lng: 21.8995792 },
    { lat: 48.7963838, lng: 21.9023129 },
    { lat: 48.7974333, lng: 21.9115839 },
    { lat: 48.7974022, lng: 21.9152256 },
    { lat: 48.7984016, lng: 21.9142588 },
    { lat: 48.8007365, lng: 21.9121394 },
    { lat: 48.8018113, lng: 21.9109954 },
    { lat: 48.8024536, lng: 21.9101008 },
    { lat: 48.8038364, lng: 21.9096623 },
    { lat: 48.8047331, lng: 21.9092708 },
    { lat: 48.8060275, lng: 21.9084005 },
    { lat: 48.8063124, lng: 21.909398 },
    { lat: 48.807037, lng: 21.9102958 },
    { lat: 48.8077588, lng: 21.9113034 },
    { lat: 48.808586, lng: 21.9116321 },
    { lat: 48.808815, lng: 21.9119729 },
    { lat: 48.8096081, lng: 21.9135778 },
    { lat: 48.8098222, lng: 21.9143107 },
    { lat: 48.8112865, lng: 21.9139343 },
    { lat: 48.8117488, lng: 21.9149662 },
    { lat: 48.8119264, lng: 21.9150481 },
    { lat: 48.8138562, lng: 21.9151649 },
    { lat: 48.8138348, lng: 21.91583 },
    { lat: 48.8139885, lng: 21.9159068 },
    { lat: 48.816613, lng: 21.9163523 },
    { lat: 48.8175049, lng: 21.9168352 },
    { lat: 48.8176394, lng: 21.9178073 },
    { lat: 48.8192256, lng: 21.9176281 },
    { lat: 48.8195982, lng: 21.9178612 },
    { lat: 48.8197011, lng: 21.9181944 },
    { lat: 48.8206115, lng: 21.9186074 },
    { lat: 48.8223567, lng: 21.9192123 },
    { lat: 48.8224425, lng: 21.9197557 },
    { lat: 48.8229283, lng: 21.9214088 },
    { lat: 48.8228912, lng: 21.9207019 },
    { lat: 48.8235463, lng: 21.9203079 },
    { lat: 48.8239872, lng: 21.9194572 },
    { lat: 48.8243489, lng: 21.9188951 },
    { lat: 48.8245077, lng: 21.9180942 },
    { lat: 48.8248919, lng: 21.9175415 },
    { lat: 48.825137, lng: 21.9173146 },
    { lat: 48.8253451, lng: 21.9172191 },
    { lat: 48.8259504, lng: 21.9163256 },
    { lat: 48.8263796, lng: 21.9155425 },
    { lat: 48.8264662, lng: 21.9148356 },
    { lat: 48.8265612, lng: 21.9146745 },
    { lat: 48.8268003, lng: 21.9139841 },
    { lat: 48.8270088, lng: 21.9129218 },
    { lat: 48.8273449, lng: 21.9117481 },
    { lat: 48.8276286, lng: 21.911585 },
    { lat: 48.8280953, lng: 21.9114445 },
    { lat: 48.8283128, lng: 21.9115058 },
    { lat: 48.8296322, lng: 21.9106463 },
    { lat: 48.8310069, lng: 21.9081911 },
    { lat: 48.8312018, lng: 21.9054769 },
    { lat: 48.8310432, lng: 21.9036972 },
    { lat: 48.8311574, lng: 21.902301 },
    { lat: 48.8315135, lng: 21.9006254 },
    { lat: 48.8320191, lng: 21.898955 },
    { lat: 48.8328088, lng: 21.8970918 },
    { lat: 48.8338016, lng: 21.8958205 },
    { lat: 48.8339162, lng: 21.8942655 },
    { lat: 48.8329683, lng: 21.8939225 },
    { lat: 48.8317106, lng: 21.8929026 },
    { lat: 48.830541, lng: 21.8921151 },
    { lat: 48.8302656, lng: 21.8901691 },
    { lat: 48.8305465, lng: 21.889499 },
    { lat: 48.830878, lng: 21.8874013 },
    { lat: 48.8311677, lng: 21.886666 },
    { lat: 48.8313866, lng: 21.8856332 },
    { lat: 48.8313639, lng: 21.8842456 },
    { lat: 48.831251, lng: 21.8834777 },
    { lat: 48.8308084, lng: 21.8836377 },
    { lat: 48.8304019, lng: 21.8818912 },
    { lat: 48.8298148, lng: 21.880488 },
    { lat: 48.8288229, lng: 21.8771354 },
    { lat: 48.8279899, lng: 21.8767249 },
    { lat: 48.8269182, lng: 21.8759817 },
    { lat: 48.8251913, lng: 21.8742533 },
    { lat: 48.8243518, lng: 21.8738551 },
    { lat: 48.8239776, lng: 21.8730758 },
    { lat: 48.8233469, lng: 21.8722927 },
    { lat: 48.8220496, lng: 21.8730315 },
    { lat: 48.8216293, lng: 21.8740682 },
    { lat: 48.8209999, lng: 21.8750175 },
    { lat: 48.8208867, lng: 21.8763942 },
    { lat: 48.8204001, lng: 21.8777438 },
    { lat: 48.8203777, lng: 21.8785865 },
    { lat: 48.8200196, lng: 21.879501 },
    { lat: 48.8196055, lng: 21.8798932 },
    { lat: 48.8189701, lng: 21.8801209 },
    { lat: 48.8185276, lng: 21.88002 },
    { lat: 48.8181601, lng: 21.8802145 },
    { lat: 48.817095, lng: 21.8801275 },
    { lat: 48.8159553, lng: 21.8787141 },
    { lat: 48.815566, lng: 21.8787911 },
    { lat: 48.8150052, lng: 21.8793035 },
    { lat: 48.8138311, lng: 21.8806017 },
    { lat: 48.8137365, lng: 21.8824533 },
    { lat: 48.8142448, lng: 21.8830204 },
    { lat: 48.8133164, lng: 21.8850855 },
    { lat: 48.8128462, lng: 21.8858418 },
    { lat: 48.8119763, lng: 21.8864953 },
    { lat: 48.8112336, lng: 21.8875482 },
    { lat: 48.8090791, lng: 21.8894488 },
    { lat: 48.8075075, lng: 21.889931 },
    { lat: 48.8067233, lng: 21.8897493 },
    { lat: 48.806248, lng: 21.8894101 },
    { lat: 48.8051589, lng: 21.8889178 },
    { lat: 48.8048894, lng: 21.8885933 },
    { lat: 48.8042355, lng: 21.8880433 },
    { lat: 48.8039317, lng: 21.8881294 },
    { lat: 48.8034947, lng: 21.8874451 },
    { lat: 48.8028595, lng: 21.8867979 },
    { lat: 48.8018318, lng: 21.8861541 },
    { lat: 48.8014114, lng: 21.8859819 },
    { lat: 48.8007131, lng: 21.8860011 },
    { lat: 48.8003442, lng: 21.8863513 },
    { lat: 48.7990733, lng: 21.8872517 },
    { lat: 48.7973764, lng: 21.887192 },
    { lat: 48.7962178, lng: 21.8881675 },
    { lat: 48.794858, lng: 21.8890166 },
  ],
  Ruská: [
    { lat: 48.5503142, lng: 22.131948 },
    { lat: 48.5497773, lng: 22.1321718 },
    { lat: 48.5492286, lng: 22.132143 },
    { lat: 48.5482513, lng: 22.1314771 },
    { lat: 48.5477228, lng: 22.1312953 },
    { lat: 48.5475807, lng: 22.1311775 },
    { lat: 48.5474688, lng: 22.1311597 },
    { lat: 48.5472437, lng: 22.1312916 },
    { lat: 48.546828, lng: 22.1312271 },
    { lat: 48.5466662, lng: 22.1310408 },
    { lat: 48.5465958, lng: 22.1304815 },
    { lat: 48.5463867, lng: 22.1300566 },
    { lat: 48.5457336, lng: 22.130653 },
    { lat: 48.5455336, lng: 22.130709 },
    { lat: 48.5453775, lng: 22.1306282 },
    { lat: 48.5452012, lng: 22.1306964 },
    { lat: 48.5441981, lng: 22.1310846 },
    { lat: 48.5436393, lng: 22.1316748 },
    { lat: 48.5430489, lng: 22.1326848 },
    { lat: 48.541862, lng: 22.1332079 },
    { lat: 48.5404026, lng: 22.1330874 },
    { lat: 48.5398869, lng: 22.1331235 },
    { lat: 48.5393715, lng: 22.1327274 },
    { lat: 48.5392687, lng: 22.1325653 },
    { lat: 48.5395607, lng: 22.1313447 },
    { lat: 48.5397693, lng: 22.13088 },
    { lat: 48.5398986, lng: 22.1303044 },
    { lat: 48.5403746, lng: 22.1288784 },
    { lat: 48.5405391, lng: 22.1285051 },
    { lat: 48.5412628, lng: 22.1273704 },
    { lat: 48.5417218, lng: 22.1261245 },
    { lat: 48.5418464, lng: 22.1252475 },
    { lat: 48.5418544, lng: 22.1247259 },
    { lat: 48.5416333, lng: 22.1233089 },
    { lat: 48.5409326, lng: 22.1231712 },
    { lat: 48.5405443, lng: 22.1229714 },
    { lat: 48.5403376, lng: 22.1225 },
    { lat: 48.5399851, lng: 22.1220552 },
    { lat: 48.5396669, lng: 22.1210527 },
    { lat: 48.5392385, lng: 22.1206382 },
    { lat: 48.5388726, lng: 22.1200411 },
    { lat: 48.5384362, lng: 22.1197066 },
    { lat: 48.5380906, lng: 22.1194169 },
    { lat: 48.5377639, lng: 22.120347 },
    { lat: 48.5372601, lng: 22.1220819 },
    { lat: 48.5369896, lng: 22.1221556 },
    { lat: 48.5362736, lng: 22.1247586 },
    { lat: 48.5354071, lng: 22.1267587 },
    { lat: 48.5358744, lng: 22.1275069 },
    { lat: 48.536136, lng: 22.1285408 },
    { lat: 48.5360471, lng: 22.1288731 },
    { lat: 48.5348527, lng: 22.1308011 },
    { lat: 48.5343309, lng: 22.1319149 },
    { lat: 48.5339309, lng: 22.1334236 },
    { lat: 48.533798, lng: 22.1333185 },
    { lat: 48.5336754, lng: 22.1335571 },
    { lat: 48.5333502, lng: 22.1338935 },
    { lat: 48.5322012, lng: 22.1331026 },
    { lat: 48.5320165, lng: 22.1330464 },
    { lat: 48.5318308, lng: 22.1334243 },
    { lat: 48.5317478, lng: 22.1333911 },
    { lat: 48.5314443, lng: 22.1340688 },
    { lat: 48.5311258, lng: 22.1338266 },
    { lat: 48.5306107, lng: 22.1336335 },
    { lat: 48.5296565, lng: 22.1325158 },
    { lat: 48.5296803, lng: 22.1322327 },
    { lat: 48.5295018, lng: 22.1320598 },
    { lat: 48.5293919, lng: 22.132027 },
    { lat: 48.5295447, lng: 22.1313967 },
    { lat: 48.5293606, lng: 22.131124 },
    { lat: 48.5293151, lng: 22.1303268 },
    { lat: 48.528962, lng: 22.1299566 },
    { lat: 48.5289228, lng: 22.1298132 },
    { lat: 48.5293778, lng: 22.1285483 },
    { lat: 48.5288313, lng: 22.1273064 },
    { lat: 48.5285843, lng: 22.1264668 },
    { lat: 48.5271124, lng: 22.1259599 },
    { lat: 48.5265593, lng: 22.1256461 },
    { lat: 48.5243706, lng: 22.1270511 },
    { lat: 48.5243082, lng: 22.1269231 },
    { lat: 48.5232156, lng: 22.1271029 },
    { lat: 48.5220098, lng: 22.1271589 },
    { lat: 48.5218677, lng: 22.1270493 },
    { lat: 48.5215558, lng: 22.1258818 },
    { lat: 48.5210795, lng: 22.125718 },
    { lat: 48.5207202, lng: 22.1233054 },
    { lat: 48.5200826, lng: 22.1222631 },
    { lat: 48.5187866, lng: 22.1183858 },
    { lat: 48.5183231, lng: 22.1180397 },
    { lat: 48.518113, lng: 22.1176794 },
    { lat: 48.5180771, lng: 22.1172925 },
    { lat: 48.5183248, lng: 22.1167311 },
    { lat: 48.5183022, lng: 22.1164438 },
    { lat: 48.518133, lng: 22.1163233 },
    { lat: 48.5169274, lng: 22.1149106 },
    { lat: 48.5167483, lng: 22.1147509 },
    { lat: 48.516335, lng: 22.1146882 },
    { lat: 48.516236, lng: 22.1144476 },
    { lat: 48.5161957, lng: 22.1141705 },
    { lat: 48.5161138, lng: 22.114177 },
    { lat: 48.5158925, lng: 22.1136669 },
    { lat: 48.5158211, lng: 22.1135966 },
    { lat: 48.5147344, lng: 22.1130192 },
    { lat: 48.5145489, lng: 22.1126881 },
    { lat: 48.5143552, lng: 22.1119236 },
    { lat: 48.5142476, lng: 22.1117956 },
    { lat: 48.5141392, lng: 22.1118404 },
    { lat: 48.5139791, lng: 22.1119065 },
    { lat: 48.5133536, lng: 22.1126711 },
    { lat: 48.5131628, lng: 22.1127785 },
    { lat: 48.5128983, lng: 22.1126263 },
    { lat: 48.5126542, lng: 22.1123005 },
    { lat: 48.51248, lng: 22.1122101 },
    { lat: 48.5116635, lng: 22.1126015 },
    { lat: 48.5115683, lng: 22.1125633 },
    { lat: 48.5112519, lng: 22.1116031 },
    { lat: 48.5112597, lng: 22.110785 },
    { lat: 48.5109688, lng: 22.110235 },
    { lat: 48.5108743, lng: 22.1101379 },
    { lat: 48.5105653, lng: 22.1086789 },
    { lat: 48.5106665, lng: 22.1079164 },
    { lat: 48.5105829, lng: 22.1078475 },
    { lat: 48.5103513, lng: 22.1074274 },
    { lat: 48.5101133, lng: 22.1073586 },
    { lat: 48.509619, lng: 22.1073794 },
    { lat: 48.5094309, lng: 22.1076587 },
    { lat: 48.5092556, lng: 22.1077639 },
    { lat: 48.5090602, lng: 22.1075856 },
    { lat: 48.5088593, lng: 22.1070844 },
    { lat: 48.508665, lng: 22.1062814 },
    { lat: 48.5084772, lng: 22.1058565 },
    { lat: 48.5076059, lng: 22.1046481 },
    { lat: 48.5072943, lng: 22.1038318 },
    { lat: 48.5070424, lng: 22.1033175 },
    { lat: 48.5068243, lng: 22.1031383 },
    { lat: 48.5065099, lng: 22.1030126 },
    { lat: 48.5061743, lng: 22.1021462 },
    { lat: 48.5060754, lng: 22.1017648 },
    { lat: 48.5054654, lng: 22.1009029 },
    { lat: 48.504743, lng: 22.0996626 },
    { lat: 48.5045342, lng: 22.0990583 },
    { lat: 48.503863, lng: 22.0980788 },
    { lat: 48.5031107, lng: 22.0984225 },
    { lat: 48.5028503, lng: 22.0984464 },
    { lat: 48.5026053, lng: 22.0983645 },
    { lat: 48.5022291, lng: 22.0981066 },
    { lat: 48.5020765, lng: 22.0974057 },
    { lat: 48.5017142, lng: 22.0968928 },
    { lat: 48.5009915, lng: 22.0972388 },
    { lat: 48.5004557, lng: 22.0981728 },
    { lat: 48.5003514, lng: 22.0990457 },
    { lat: 48.5004116, lng: 22.1005417 },
    { lat: 48.5004867, lng: 22.1010517 },
    { lat: 48.5006798, lng: 22.1016532 },
    { lat: 48.5014194, lng: 22.1030022 },
    { lat: 48.5018102, lng: 22.1042142 },
    { lat: 48.5019452, lng: 22.1052062 },
    { lat: 48.5023035, lng: 22.1062316 },
    { lat: 48.5013885, lng: 22.1068336 },
    { lat: 48.5011195, lng: 22.107252 },
    { lat: 48.5013404, lng: 22.1082206 },
    { lat: 48.5017425, lng: 22.1082984 },
    { lat: 48.502303, lng: 22.1081176 },
    { lat: 48.5027701, lng: 22.1083207 },
    { lat: 48.5030079, lng: 22.1086187 },
    { lat: 48.5031269, lng: 22.1088472 },
    { lat: 48.5036127, lng: 22.1110818 },
    { lat: 48.504854, lng: 22.1131974 },
    { lat: 48.5053137, lng: 22.1134183 },
    { lat: 48.5056748, lng: 22.1148573 },
    { lat: 48.5061179, lng: 22.1153743 },
    { lat: 48.50643, lng: 22.1158723 },
    { lat: 48.5065653, lng: 22.1158853 },
    { lat: 48.5069866, lng: 22.115667 },
    { lat: 48.5077965, lng: 22.1171439 },
    { lat: 48.5091138, lng: 22.1185398 },
    { lat: 48.5098047, lng: 22.118779 },
    { lat: 48.5101024, lng: 22.1190473 },
    { lat: 48.5103599, lng: 22.1193855 },
    { lat: 48.5106062, lng: 22.1199045 },
    { lat: 48.5111942, lng: 22.1207107 },
    { lat: 48.5112198, lng: 22.1207459 },
    { lat: 48.5112345, lng: 22.1228958 },
    { lat: 48.5111713, lng: 22.123757 },
    { lat: 48.5114386, lng: 22.1244488 },
    { lat: 48.5114229, lng: 22.1247941 },
    { lat: 48.5119088, lng: 22.1256013 },
    { lat: 48.5120709, lng: 22.1261798 },
    { lat: 48.5121381, lng: 22.126653 },
    { lat: 48.512681, lng: 22.1274955 },
    { lat: 48.5133855, lng: 22.1282787 },
    { lat: 48.5137378, lng: 22.1281415 },
    { lat: 48.5139169, lng: 22.1283399 },
    { lat: 48.5143228, lng: 22.1293837 },
    { lat: 48.5141768, lng: 22.1301163 },
    { lat: 48.5135005, lng: 22.1307288 },
    { lat: 48.5134129, lng: 22.1309754 },
    { lat: 48.5134095, lng: 22.1313612 },
    { lat: 48.5134797, lng: 22.1314703 },
    { lat: 48.5141178, lng: 22.1315833 },
    { lat: 48.5142481, lng: 22.1319099 },
    { lat: 48.51447, lng: 22.1329817 },
    { lat: 48.514575, lng: 22.1340558 },
    { lat: 48.5150372, lng: 22.1344329 },
    { lat: 48.5157926, lng: 22.1342183 },
    { lat: 48.5166566, lng: 22.1343633 },
    { lat: 48.5167243, lng: 22.1344669 },
    { lat: 48.5159962, lng: 22.1409667 },
    { lat: 48.5159824, lng: 22.14109 },
    { lat: 48.5160123, lng: 22.1412587 },
    { lat: 48.5176647, lng: 22.1437164 },
    { lat: 48.5193589, lng: 22.1459432 },
    { lat: 48.5211059, lng: 22.148124 },
    { lat: 48.5243271, lng: 22.1500328 },
    { lat: 48.5258747, lng: 22.1506958 },
    { lat: 48.524819, lng: 22.157786 },
    { lat: 48.527041, lng: 22.158285 },
    { lat: 48.5449493, lng: 22.1594592 },
    { lat: 48.5623344, lng: 22.1606312 },
    { lat: 48.5624015, lng: 22.1606441 },
    { lat: 48.5625428, lng: 22.1606731 },
    { lat: 48.5626471, lng: 22.1606943 },
    { lat: 48.5661035, lng: 22.1608994 },
    { lat: 48.5672614, lng: 22.1609681 },
    { lat: 48.5673811, lng: 22.1587199 },
    { lat: 48.567978, lng: 22.1562297 },
    { lat: 48.568038, lng: 22.1555642 },
    { lat: 48.567983, lng: 22.1551877 },
    { lat: 48.5674445, lng: 22.1536463 },
    { lat: 48.5670486, lng: 22.1528175 },
    { lat: 48.5667104, lng: 22.1521095 },
    { lat: 48.566136, lng: 22.1511183 },
    { lat: 48.565853, lng: 22.1504098 },
    { lat: 48.5657091, lng: 22.1498307 },
    { lat: 48.5636846, lng: 22.149548 },
    { lat: 48.563484, lng: 22.148127 },
    { lat: 48.562352, lng: 22.1486513 },
    { lat: 48.5607753, lng: 22.1452792 },
    { lat: 48.5610501, lng: 22.1450552 },
    { lat: 48.5605016, lng: 22.1439112 },
    { lat: 48.5603693, lng: 22.1439296 },
    { lat: 48.5601991, lng: 22.1435345 },
    { lat: 48.5599906, lng: 22.1438022 },
    { lat: 48.5595851, lng: 22.1440751 },
    { lat: 48.5584611, lng: 22.1419869 },
    { lat: 48.5581415, lng: 22.1419377 },
    { lat: 48.5567802, lng: 22.1414892 },
    { lat: 48.5564136, lng: 22.1414972 },
    { lat: 48.5553273, lng: 22.1420881 },
    { lat: 48.5548448, lng: 22.1425759 },
    { lat: 48.5537005, lng: 22.1431875 },
    { lat: 48.5534616, lng: 22.1426366 },
    { lat: 48.5528197, lng: 22.1407256 },
    { lat: 48.5525995, lng: 22.1403065 },
    { lat: 48.5528864, lng: 22.140068 },
    { lat: 48.5522759, lng: 22.1383421 },
    { lat: 48.5509263, lng: 22.1350371 },
    { lat: 48.5509752, lng: 22.1350103 },
    { lat: 48.5508669, lng: 22.1346532 },
    { lat: 48.5503168, lng: 22.1319667 },
    { lat: 48.5503142, lng: 22.131948 },
  ],
  Žbince: [
    { lat: 48.6973117, lng: 21.8592745 },
    { lat: 48.6977094, lng: 21.8592572 },
    { lat: 48.697315, lng: 21.8554687 },
    { lat: 48.696988, lng: 21.8528573 },
    { lat: 48.6964871, lng: 21.8525017 },
    { lat: 48.6960696, lng: 21.852402 },
    { lat: 48.6960345, lng: 21.851481 },
    { lat: 48.695934, lng: 21.8513779 },
    { lat: 48.6950739, lng: 21.8510525 },
    { lat: 48.6943509, lng: 21.8511523 },
    { lat: 48.694017, lng: 21.8511128 },
    { lat: 48.6935509, lng: 21.8509746 },
    { lat: 48.6931747, lng: 21.85061 },
    { lat: 48.6930019, lng: 21.850586 },
    { lat: 48.6927414, lng: 21.850649 },
    { lat: 48.6921402, lng: 21.8509739 },
    { lat: 48.6915277, lng: 21.8488631 },
    { lat: 48.6902697, lng: 21.8495093 },
    { lat: 48.6901917, lng: 21.8492743 },
    { lat: 48.6893344, lng: 21.8497523 },
    { lat: 48.6892392, lng: 21.849461 },
    { lat: 48.6889281, lng: 21.8496555 },
    { lat: 48.6887844, lng: 21.849194 },
    { lat: 48.6883896, lng: 21.8494526 },
    { lat: 48.6881119, lng: 21.8482006 },
    { lat: 48.6872679, lng: 21.8486295 },
    { lat: 48.6872225, lng: 21.8484765 },
    { lat: 48.6859663, lng: 21.8485811 },
    { lat: 48.6859336, lng: 21.8484018 },
    { lat: 48.6857551, lng: 21.848465 },
    { lat: 48.685676, lng: 21.8480577 },
    { lat: 48.6836081, lng: 21.8491837 },
    { lat: 48.6834591, lng: 21.8492555 },
    { lat: 48.6822473, lng: 21.849812 },
    { lat: 48.6793905, lng: 21.8495379 },
    { lat: 48.6793722, lng: 21.8494834 },
    { lat: 48.6789777, lng: 21.8496736 },
    { lat: 48.6789014, lng: 21.8494221 },
    { lat: 48.6772299, lng: 21.8506976 },
    { lat: 48.6766743, lng: 21.8510934 },
    { lat: 48.6759019, lng: 21.8515923 },
    { lat: 48.6751355, lng: 21.851884 },
    { lat: 48.6749317, lng: 21.8512243 },
    { lat: 48.6745969, lng: 21.8505007 },
    { lat: 48.6737505, lng: 21.8506615 },
    { lat: 48.673523, lng: 21.8506076 },
    { lat: 48.6726673, lng: 21.8511925 },
    { lat: 48.6722754, lng: 21.8516089 },
    { lat: 48.6720948, lng: 21.8516839 },
    { lat: 48.671434, lng: 21.8516181 },
    { lat: 48.6697381, lng: 21.8521232 },
    { lat: 48.669287, lng: 21.8540062 },
    { lat: 48.6684704, lng: 21.8542257 },
    { lat: 48.6653997, lng: 21.8545134 },
    { lat: 48.665492, lng: 21.8530267 },
    { lat: 48.6638772, lng: 21.8533571 },
    { lat: 48.662841, lng: 21.8536664 },
    { lat: 48.6627845, lng: 21.8534423 },
    { lat: 48.661781, lng: 21.8539639 },
    { lat: 48.6615592, lng: 21.8532527 },
    { lat: 48.6604757, lng: 21.8539931 },
    { lat: 48.6589135, lng: 21.8547578 },
    { lat: 48.6586567, lng: 21.854875 },
    { lat: 48.6586564, lng: 21.8549066 },
    { lat: 48.6586596, lng: 21.8559487 },
    { lat: 48.6587193, lng: 21.8563901 },
    { lat: 48.6589279, lng: 21.8569266 },
    { lat: 48.6590592, lng: 21.8570473 },
    { lat: 48.6594393, lng: 21.8571788 },
    { lat: 48.6596116, lng: 21.8573587 },
    { lat: 48.6598363, lng: 21.8584807 },
    { lat: 48.6598676, lng: 21.8590824 },
    { lat: 48.6598493, lng: 21.8594233 },
    { lat: 48.6597754, lng: 21.8597398 },
    { lat: 48.6589114, lng: 21.8631621 },
    { lat: 48.6588812, lng: 21.8637329 },
    { lat: 48.6590362, lng: 21.8642046 },
    { lat: 48.6597111, lng: 21.8654786 },
    { lat: 48.660605, lng: 21.8704977 },
    { lat: 48.6601059, lng: 21.8706888 },
    { lat: 48.6595388, lng: 21.8714372 },
    { lat: 48.6596936, lng: 21.8722566 },
    { lat: 48.6589759, lng: 21.8723447 },
    { lat: 48.658282, lng: 21.8723485 },
    { lat: 48.6584733, lng: 21.8729227 },
    { lat: 48.6573469, lng: 21.8728685 },
    { lat: 48.6566621, lng: 21.8732725 },
    { lat: 48.6558556, lng: 21.8736312 },
    { lat: 48.6551677, lng: 21.8744523 },
    { lat: 48.6554978, lng: 21.8753476 },
    { lat: 48.6573945, lng: 21.8812016 },
    { lat: 48.6614601, lng: 21.8816917 },
    { lat: 48.6626181, lng: 21.8818313 },
    { lat: 48.6628714, lng: 21.883507 },
    { lat: 48.6629308, lng: 21.8844217 },
    { lat: 48.6630989, lng: 21.885902 },
    { lat: 48.6636629, lng: 21.8855204 },
    { lat: 48.6645898, lng: 21.885646 },
    { lat: 48.6654351, lng: 21.8854403 },
    { lat: 48.666444, lng: 21.8845133 },
    { lat: 48.6668711, lng: 21.8845828 },
    { lat: 48.6678564, lng: 21.8853196 },
    { lat: 48.6680412, lng: 21.8855128 },
    { lat: 48.6685606, lng: 21.8857634 },
    { lat: 48.6689549, lng: 21.8858263 },
    { lat: 48.669439, lng: 21.8857095 },
    { lat: 48.6694568, lng: 21.8858349 },
    { lat: 48.6693933, lng: 21.885861 },
    { lat: 48.6694285, lng: 21.8861785 },
    { lat: 48.670142, lng: 21.8861919 },
    { lat: 48.67037, lng: 21.8862673 },
    { lat: 48.670929, lng: 21.8867061 },
    { lat: 48.6711948, lng: 21.8882187 },
    { lat: 48.671386, lng: 21.8888743 },
    { lat: 48.6715879, lng: 21.8893801 },
    { lat: 48.6706797, lng: 21.8908294 },
    { lat: 48.6704394, lng: 21.8914673 },
    { lat: 48.6704539, lng: 21.8918616 },
    { lat: 48.670779, lng: 21.8932689 },
    { lat: 48.6708065, lng: 21.8936023 },
    { lat: 48.6706157, lng: 21.8945896 },
    { lat: 48.6706157, lng: 21.8963618 },
    { lat: 48.6706986, lng: 21.8969268 },
    { lat: 48.6689988, lng: 21.8976 },
    { lat: 48.6690232, lng: 21.8976694 },
    { lat: 48.6692199, lng: 21.8977573 },
    { lat: 48.6694272, lng: 21.8979475 },
    { lat: 48.6698261, lng: 21.8984113 },
    { lat: 48.6700266, lng: 21.8991308 },
    { lat: 48.6682103, lng: 21.9000829 },
    { lat: 48.6671689, lng: 21.9005431 },
    { lat: 48.6678315, lng: 21.9039539 },
    { lat: 48.6680349, lng: 21.9039902 },
    { lat: 48.6681777, lng: 21.9045657 },
    { lat: 48.6682546, lng: 21.9053848 },
    { lat: 48.6682406, lng: 21.905633 },
    { lat: 48.6680714, lng: 21.9061496 },
    { lat: 48.6682208, lng: 21.9081867 },
    { lat: 48.6680036, lng: 21.9088085 },
    { lat: 48.6680652, lng: 21.9091915 },
    { lat: 48.6684537, lng: 21.910098 },
    { lat: 48.6685281, lng: 21.9105045 },
    { lat: 48.6680256, lng: 21.9113203 },
    { lat: 48.6680401, lng: 21.9117615 },
    { lat: 48.6680558, lng: 21.9118421 },
    { lat: 48.6682801, lng: 21.9119136 },
    { lat: 48.6685698, lng: 21.9118851 },
    { lat: 48.6689898, lng: 21.9119926 },
    { lat: 48.66955, lng: 21.9122569 },
    { lat: 48.6693834, lng: 21.9139887 },
    { lat: 48.6695371, lng: 21.9147341 },
    { lat: 48.6695106, lng: 21.9153278 },
    { lat: 48.6694182, lng: 21.9155058 },
    { lat: 48.6692914, lng: 21.9155654 },
    { lat: 48.6684358, lng: 21.9153167 },
    { lat: 48.6682513, lng: 21.9154035 },
    { lat: 48.6681357, lng: 21.9156802 },
    { lat: 48.6680974, lng: 21.9159013 },
    { lat: 48.6681737, lng: 21.9163361 },
    { lat: 48.6683814, lng: 21.9169079 },
    { lat: 48.6684462, lng: 21.9176444 },
    { lat: 48.6683244, lng: 21.9181732 },
    { lat: 48.6680198, lng: 21.9188078 },
    { lat: 48.6672625, lng: 21.919372 },
    { lat: 48.6667246, lng: 21.9199933 },
    { lat: 48.6664371, lng: 21.9210811 },
    { lat: 48.6663729, lng: 21.9214431 },
    { lat: 48.6663328, lng: 21.9221736 },
    { lat: 48.666226, lng: 21.9225306 },
    { lat: 48.6660167, lng: 21.922711 },
    { lat: 48.6653008, lng: 21.9220648 },
    { lat: 48.6649403, lng: 21.9219129 },
    { lat: 48.6648388, lng: 21.921944 },
    { lat: 48.6643321, lng: 21.9225108 },
    { lat: 48.6638894, lng: 21.9232443 },
    { lat: 48.6635814, lng: 21.923614 },
    { lat: 48.6625841, lng: 21.9243487 },
    { lat: 48.6622741, lng: 21.9242832 },
    { lat: 48.6615841, lng: 21.9240011 },
    { lat: 48.6611139, lng: 21.923686 },
    { lat: 48.6607652, lng: 21.9223467 },
    { lat: 48.6605249, lng: 21.9221818 },
    { lat: 48.6602419, lng: 21.9222909 },
    { lat: 48.6601743, lng: 21.9223784 },
    { lat: 48.6598581, lng: 21.9230947 },
    { lat: 48.6593429, lng: 21.9233168 },
    { lat: 48.6620806, lng: 21.9322513 },
    { lat: 48.6623956, lng: 21.933334 },
    { lat: 48.6625658, lng: 21.934283 },
    { lat: 48.6628864, lng: 21.9352732 },
    { lat: 48.6632769, lng: 21.936263 },
    { lat: 48.6634886, lng: 21.937084 },
    { lat: 48.66424, lng: 21.938876 },
    { lat: 48.6652435, lng: 21.9424306 },
    { lat: 48.6736166, lng: 21.9451285 },
    { lat: 48.6737811, lng: 21.9434274 },
    { lat: 48.6782397, lng: 21.9429029 },
    { lat: 48.6780546, lng: 21.9471609 },
    { lat: 48.6783266, lng: 21.9478762 },
    { lat: 48.6783739, lng: 21.9480212 },
    { lat: 48.6784131, lng: 21.9481415 },
    { lat: 48.6797623, lng: 21.9474375 },
    { lat: 48.6803648, lng: 21.9470085 },
    { lat: 48.6803726, lng: 21.9470016 },
    { lat: 48.6809595, lng: 21.946452 },
    { lat: 48.6812188, lng: 21.9460735 },
    { lat: 48.6824265, lng: 21.9451963 },
    { lat: 48.683462, lng: 21.9441334 },
    { lat: 48.6839056, lng: 21.9439251 },
    { lat: 48.6843732, lng: 21.9435916 },
    { lat: 48.6847286, lng: 21.9430875 },
    { lat: 48.6848649, lng: 21.9422876 },
    { lat: 48.6850879, lng: 21.9397301 },
    { lat: 48.6852402, lng: 21.9384874 },
    { lat: 48.6852101, lng: 21.9360793 },
    { lat: 48.685292, lng: 21.9357079 },
    { lat: 48.6855155, lng: 21.9354616 },
    { lat: 48.6853093, lng: 21.9349592 },
    { lat: 48.684942, lng: 21.9342593 },
    { lat: 48.6844752, lng: 21.9331478 },
    { lat: 48.6843803, lng: 21.9303499 },
    { lat: 48.6842191, lng: 21.9288514 },
    { lat: 48.6840525, lng: 21.9279122 },
    { lat: 48.6836183, lng: 21.9260302 },
    { lat: 48.6831731, lng: 21.9230891 },
    { lat: 48.6834563, lng: 21.9216481 },
    { lat: 48.683469, lng: 21.9208983 },
    { lat: 48.6839101, lng: 21.9202806 },
    { lat: 48.6844309, lng: 21.9193379 },
    { lat: 48.6847636, lng: 21.9191461 },
    { lat: 48.6851038, lng: 21.9178256 },
    { lat: 48.6854385, lng: 21.9168693 },
    { lat: 48.6851795, lng: 21.9160981 },
    { lat: 48.6851637, lng: 21.9140864 },
    { lat: 48.6850963, lng: 21.9140525 },
    { lat: 48.6850961, lng: 21.9139511 },
    { lat: 48.6851059, lng: 21.9137141 },
    { lat: 48.6852298, lng: 21.9131402 },
    { lat: 48.6852326, lng: 21.9128111 },
    { lat: 48.6854503, lng: 21.9125621 },
    { lat: 48.6856088, lng: 21.9122023 },
    { lat: 48.6853479, lng: 21.9111332 },
    { lat: 48.6859848, lng: 21.9107825 },
    { lat: 48.6859177, lng: 21.9102264 },
    { lat: 48.6856908, lng: 21.9096993 },
    { lat: 48.6855973, lng: 21.9090795 },
    { lat: 48.6855235, lng: 21.9088712 },
    { lat: 48.6859698, lng: 21.9029001 },
    { lat: 48.6858491, lng: 21.9020818 },
    { lat: 48.6857441, lng: 21.9018913 },
    { lat: 48.6855869, lng: 21.9015508 },
    { lat: 48.6847557, lng: 21.898584 },
    { lat: 48.6852942, lng: 21.8981676 },
    { lat: 48.6843162, lng: 21.8948765 },
    { lat: 48.6828743, lng: 21.895949 },
    { lat: 48.6826711, lng: 21.8949455 },
    { lat: 48.6826605, lng: 21.8943152 },
    { lat: 48.6826911, lng: 21.8936099 },
    { lat: 48.6828802, lng: 21.8929033 },
    { lat: 48.6828916, lng: 21.8924267 },
    { lat: 48.6814183, lng: 21.8931533 },
    { lat: 48.6814745, lng: 21.8935448 },
    { lat: 48.6809094, lng: 21.8937929 },
    { lat: 48.6800254, lng: 21.8940289 },
    { lat: 48.67902, lng: 21.8945318 },
    { lat: 48.6785476, lng: 21.8952455 },
    { lat: 48.6780008, lng: 21.8916988 },
    { lat: 48.6776737, lng: 21.8879017 },
    { lat: 48.6777504, lng: 21.887914 },
    { lat: 48.6777443, lng: 21.887804 },
    { lat: 48.6744353, lng: 21.8874878 },
    { lat: 48.674134, lng: 21.8850613 },
    { lat: 48.6742188, lng: 21.8848566 },
    { lat: 48.674034, lng: 21.8839682 },
    { lat: 48.6739302, lng: 21.883115 },
    { lat: 48.6738698, lng: 21.882067 },
    { lat: 48.673999, lng: 21.8820609 },
    { lat: 48.6739886, lng: 21.8805975 },
    { lat: 48.6738352, lng: 21.8795688 },
    { lat: 48.6742404, lng: 21.8779053 },
    { lat: 48.674189, lng: 21.8776531 },
    { lat: 48.6737922, lng: 21.8769145 },
    { lat: 48.6736142, lng: 21.8764625 },
    { lat: 48.6734873, lng: 21.8759434 },
    { lat: 48.673477, lng: 21.8751901 },
    { lat: 48.6735193, lng: 21.8747349 },
    { lat: 48.6737885, lng: 21.8734235 },
    { lat: 48.6738494, lng: 21.8732138 },
    { lat: 48.6741273, lng: 21.8733414 },
    { lat: 48.6745101, lng: 21.8732664 },
    { lat: 48.6761404, lng: 21.8723123 },
    { lat: 48.6763917, lng: 21.8721104 },
    { lat: 48.6767756, lng: 21.8719407 },
    { lat: 48.6773473, lng: 21.8704976 },
    { lat: 48.6777088, lng: 21.869939 },
    { lat: 48.6778736, lng: 21.8698752 },
    { lat: 48.6789405, lng: 21.8698916 },
    { lat: 48.6796067, lng: 21.8697722 },
    { lat: 48.6799484, lng: 21.8694048 },
    { lat: 48.6800805, lng: 21.8683711 },
    { lat: 48.6802336, lng: 21.8676761 },
    { lat: 48.6810102, lng: 21.8661985 },
    { lat: 48.6813553, lng: 21.8657914 },
    { lat: 48.6815772, lng: 21.8657552 },
    { lat: 48.6822005, lng: 21.8658494 },
    { lat: 48.6823973, lng: 21.8659764 },
    { lat: 48.6830961, lng: 21.8674671 },
    { lat: 48.6831542, lng: 21.8675463 },
    { lat: 48.6836148, lng: 21.8677359 },
    { lat: 48.6844735, lng: 21.8672638 },
    { lat: 48.6846951, lng: 21.8667851 },
    { lat: 48.6851657, lng: 21.8666198 },
    { lat: 48.6853525, lng: 21.8664508 },
    { lat: 48.6859191, lng: 21.8654261 },
    { lat: 48.6862469, lng: 21.8649622 },
    { lat: 48.6874481, lng: 21.864156 },
    { lat: 48.6877968, lng: 21.8642392 },
    { lat: 48.6881305, lng: 21.8641335 },
    { lat: 48.6882678, lng: 21.8639179 },
    { lat: 48.6885588, lng: 21.8626817 },
    { lat: 48.6887782, lng: 21.8624196 },
    { lat: 48.689283, lng: 21.8620553 },
    { lat: 48.6897221, lng: 21.8618893 },
    { lat: 48.6903255, lng: 21.8611582 },
    { lat: 48.69048, lng: 21.860693 },
    { lat: 48.6905854, lng: 21.860548 },
    { lat: 48.6915671, lng: 21.8601824 },
    { lat: 48.6918997, lng: 21.860128 },
    { lat: 48.6924342, lng: 21.8598631 },
    { lat: 48.6927621, lng: 21.860017 },
    { lat: 48.6929677, lng: 21.8600092 },
    { lat: 48.694414, lng: 21.8593322 },
    { lat: 48.6948988, lng: 21.8595473 },
    { lat: 48.6950524, lng: 21.8593948 },
    { lat: 48.6950113, lng: 21.8592373 },
    { lat: 48.6950229, lng: 21.8588617 },
    { lat: 48.6951281, lng: 21.8585817 },
    { lat: 48.6952706, lng: 21.8583835 },
    { lat: 48.6955139, lng: 21.8582656 },
    { lat: 48.6960505, lng: 21.8582209 },
    { lat: 48.6966678, lng: 21.8580775 },
    { lat: 48.6968088, lng: 21.8582256 },
    { lat: 48.6969234, lng: 21.8589133 },
    { lat: 48.6970888, lng: 21.8591401 },
    { lat: 48.6972776, lng: 21.8592601 },
    { lat: 48.6973117, lng: 21.8592745 },
  ],
  Lastomír: [
    { lat: 48.7090927, lng: 21.9113129 },
    { lat: 48.7086902, lng: 21.9100777 },
    { lat: 48.7084976, lng: 21.9093479 },
    { lat: 48.7085383, lng: 21.9093199 },
    { lat: 48.7083649, lng: 21.9089135 },
    { lat: 48.7082419, lng: 21.9083162 },
    { lat: 48.7080974, lng: 21.9081218 },
    { lat: 48.7080761, lng: 21.907988 },
    { lat: 48.7079989, lng: 21.9079166 },
    { lat: 48.7079187, lng: 21.9076815 },
    { lat: 48.7074274, lng: 21.9072882 },
    { lat: 48.7073623, lng: 21.9071673 },
    { lat: 48.7072993, lng: 21.9068115 },
    { lat: 48.7073264, lng: 21.9067248 },
    { lat: 48.7071189, lng: 21.9061291 },
    { lat: 48.706943, lng: 21.9052218 },
    { lat: 48.7069306, lng: 21.9046384 },
    { lat: 48.7069863, lng: 21.9045742 },
    { lat: 48.706896, lng: 21.9040259 },
    { lat: 48.7068378, lng: 21.9037746 },
    { lat: 48.7065263, lng: 21.9038176 },
    { lat: 48.7062098, lng: 21.903848 },
    { lat: 48.7061523, lng: 21.903759 },
    { lat: 48.7061334, lng: 21.903587 },
    { lat: 48.7056518, lng: 21.903451 },
    { lat: 48.704858, lng: 21.9036037 },
    { lat: 48.7048, lng: 21.9031298 },
    { lat: 48.7051531, lng: 21.9029976 },
    { lat: 48.704866, lng: 21.9019642 },
    { lat: 48.7051103, lng: 21.9016163 },
    { lat: 48.7052359, lng: 21.9012881 },
    { lat: 48.7052159, lng: 21.9009435 },
    { lat: 48.7050314, lng: 21.9006322 },
    { lat: 48.7048527, lng: 21.9004623 },
    { lat: 48.704072, lng: 21.9004205 },
    { lat: 48.7041185, lng: 21.8992304 },
    { lat: 48.7040812, lng: 21.8987378 },
    { lat: 48.7041832, lng: 21.8984171 },
    { lat: 48.7041727, lng: 21.8982179 },
    { lat: 48.7040542, lng: 21.8978469 },
    { lat: 48.7033567, lng: 21.8975439 },
    { lat: 48.7033023, lng: 21.895575 },
    { lat: 48.7027161, lng: 21.891364 },
    { lat: 48.7024428, lng: 21.8897312 },
    { lat: 48.7025895, lng: 21.8896568 },
    { lat: 48.7021917, lng: 21.8884405 },
    { lat: 48.7017594, lng: 21.8889998 },
    { lat: 48.7013219, lng: 21.8898892 },
    { lat: 48.7011288, lng: 21.8901679 },
    { lat: 48.7007492, lng: 21.8903672 },
    { lat: 48.6995143, lng: 21.8906996 },
    { lat: 48.6990965, lng: 21.8905917 },
    { lat: 48.6989146, lng: 21.8869388 },
    { lat: 48.6988337, lng: 21.8860115 },
    { lat: 48.6984718, lng: 21.8859809 },
    { lat: 48.6982143, lng: 21.8862081 },
    { lat: 48.6981412, lng: 21.8863391 },
    { lat: 48.6981141, lng: 21.8861516 },
    { lat: 48.6979185, lng: 21.8869133 },
    { lat: 48.6978532, lng: 21.8881112 },
    { lat: 48.6975413, lng: 21.8890281 },
    { lat: 48.6975831, lng: 21.8892515 },
    { lat: 48.697377, lng: 21.8894176 },
    { lat: 48.6964889, lng: 21.8894044 },
    { lat: 48.6959962, lng: 21.8892293 },
    { lat: 48.6959014, lng: 21.8890792 },
    { lat: 48.6951047, lng: 21.8887216 },
    { lat: 48.6948699, lng: 21.888685 },
    { lat: 48.6947283, lng: 21.8887808 },
    { lat: 48.6942732, lng: 21.8886737 },
    { lat: 48.6933011, lng: 21.8881779 },
    { lat: 48.6928956, lng: 21.8878507 },
    { lat: 48.6923388, lng: 21.8876747 },
    { lat: 48.6919587, lng: 21.8876352 },
    { lat: 48.6917051, lng: 21.8877036 },
    { lat: 48.6907512, lng: 21.8877548 },
    { lat: 48.6891108, lng: 21.8870841 },
    { lat: 48.6888047, lng: 21.8870336 },
    { lat: 48.6882449, lng: 21.8872299 },
    { lat: 48.687055, lng: 21.8879491 },
    { lat: 48.6865158, lng: 21.8881146 },
    { lat: 48.6856786, lng: 21.8882377 },
    { lat: 48.6868251, lng: 21.8937318 },
    { lat: 48.6879934, lng: 21.8990686 },
    { lat: 48.6881203, lng: 21.8994187 },
    { lat: 48.6876996, lng: 21.8997738 },
    { lat: 48.6874924, lng: 21.9004712 },
    { lat: 48.6873658, lng: 21.9006316 },
    { lat: 48.6870772, lng: 21.9009616 },
    { lat: 48.6867716, lng: 21.9011081 },
    { lat: 48.6868329, lng: 21.9018419 },
    { lat: 48.6858569, lng: 21.9018606 },
    { lat: 48.6857441, lng: 21.9018913 },
    { lat: 48.6858491, lng: 21.9020818 },
    { lat: 48.6859698, lng: 21.9029001 },
    { lat: 48.6855235, lng: 21.9088712 },
    { lat: 48.6855973, lng: 21.9090795 },
    { lat: 48.6856908, lng: 21.9096993 },
    { lat: 48.6859177, lng: 21.9102264 },
    { lat: 48.6859848, lng: 21.9107825 },
    { lat: 48.6853479, lng: 21.9111332 },
    { lat: 48.6856088, lng: 21.9122023 },
    { lat: 48.6854503, lng: 21.9125621 },
    { lat: 48.6852326, lng: 21.9128111 },
    { lat: 48.6852298, lng: 21.9131402 },
    { lat: 48.6851059, lng: 21.9137141 },
    { lat: 48.6850961, lng: 21.9139511 },
    { lat: 48.6850963, lng: 21.9140525 },
    { lat: 48.6851637, lng: 21.9140864 },
    { lat: 48.6851795, lng: 21.9160981 },
    { lat: 48.6854385, lng: 21.9168693 },
    { lat: 48.6851038, lng: 21.9178256 },
    { lat: 48.6847636, lng: 21.9191461 },
    { lat: 48.6844309, lng: 21.9193379 },
    { lat: 48.6839101, lng: 21.9202806 },
    { lat: 48.683469, lng: 21.9208983 },
    { lat: 48.6834563, lng: 21.9216481 },
    { lat: 48.6831731, lng: 21.9230891 },
    { lat: 48.6836183, lng: 21.9260302 },
    { lat: 48.6840525, lng: 21.9279122 },
    { lat: 48.6842191, lng: 21.9288514 },
    { lat: 48.6843803, lng: 21.9303499 },
    { lat: 48.6844752, lng: 21.9331478 },
    { lat: 48.684942, lng: 21.9342593 },
    { lat: 48.6853093, lng: 21.9349592 },
    { lat: 48.6855155, lng: 21.9354616 },
    { lat: 48.685292, lng: 21.9357079 },
    { lat: 48.6852101, lng: 21.9360793 },
    { lat: 48.6852402, lng: 21.9384874 },
    { lat: 48.6850879, lng: 21.9397301 },
    { lat: 48.6848649, lng: 21.9422876 },
    { lat: 48.6847286, lng: 21.9430875 },
    { lat: 48.6843732, lng: 21.9435916 },
    { lat: 48.6839056, lng: 21.9439251 },
    { lat: 48.683462, lng: 21.9441334 },
    { lat: 48.6824265, lng: 21.9451963 },
    { lat: 48.6812188, lng: 21.9460735 },
    { lat: 48.6809595, lng: 21.946452 },
    { lat: 48.6803726, lng: 21.9470016 },
    { lat: 48.6804546, lng: 21.9472746 },
    { lat: 48.6809194, lng: 21.9491719 },
    { lat: 48.684814, lng: 21.9469625 },
    { lat: 48.6849148, lng: 21.9469476 },
    { lat: 48.6851516, lng: 21.9470558 },
    { lat: 48.6855523, lng: 21.9470152 },
    { lat: 48.6859269, lng: 21.9474717 },
    { lat: 48.6860038, lng: 21.9474431 },
    { lat: 48.6860906, lng: 21.9472677 },
    { lat: 48.6863776, lng: 21.947047 },
    { lat: 48.6865852, lng: 21.9466649 },
    { lat: 48.6870982, lng: 21.9464004 },
    { lat: 48.6875613, lng: 21.9458877 },
    { lat: 48.6876601, lng: 21.9459244 },
    { lat: 48.6879485, lng: 21.9462612 },
    { lat: 48.6883194, lng: 21.9462443 },
    { lat: 48.6887181, lng: 21.9459917 },
    { lat: 48.688984, lng: 21.9459641 },
    { lat: 48.6891543, lng: 21.9460256 },
    { lat: 48.6892934, lng: 21.9459664 },
    { lat: 48.6894354, lng: 21.9457917 },
    { lat: 48.6896026, lng: 21.9457291 },
    { lat: 48.6902465, lng: 21.9458844 },
    { lat: 48.6904057, lng: 21.9457752 },
    { lat: 48.6905297, lng: 21.9457839 },
    { lat: 48.6909573, lng: 21.9461508 },
    { lat: 48.6911408, lng: 21.9462045 },
    { lat: 48.6915478, lng: 21.9458783 },
    { lat: 48.6917616, lng: 21.9458768 },
    { lat: 48.691935, lng: 21.946108 },
    { lat: 48.6920579, lng: 21.946205 },
    { lat: 48.6922441, lng: 21.9462321 },
    { lat: 48.6929957, lng: 21.9469164 },
    { lat: 48.6931859, lng: 21.9469651 },
    { lat: 48.6934065, lng: 21.9468756 },
    { lat: 48.693384, lng: 21.9484137 },
    { lat: 48.6931935, lng: 21.9490737 },
    { lat: 48.6936017, lng: 21.9496583 },
    { lat: 48.694122, lng: 21.949581 },
    { lat: 48.6943464, lng: 21.9516339 },
    { lat: 48.6954379, lng: 21.9515608 },
    { lat: 48.6955196, lng: 21.9519084 },
    { lat: 48.6963266, lng: 21.9518276 },
    { lat: 48.6968154, lng: 21.951672 },
    { lat: 48.6974351, lng: 21.951348 },
    { lat: 48.6978806, lng: 21.9513077 },
    { lat: 48.6983894, lng: 21.9507799 },
    { lat: 48.6991229, lng: 21.9502842 },
    { lat: 48.7017449, lng: 21.9490995 },
    { lat: 48.7026349, lng: 21.949009 },
    { lat: 48.7034385, lng: 21.9491617 },
    { lat: 48.7050959, lng: 21.9502659 },
    { lat: 48.7076164, lng: 21.9523252 },
    { lat: 48.7083056, lng: 21.9527548 },
    { lat: 48.7083572, lng: 21.9530215 },
    { lat: 48.7104623, lng: 21.951818 },
    { lat: 48.71416, lng: 21.9498684 },
    { lat: 48.7145758, lng: 21.9497162 },
    { lat: 48.7176971, lng: 21.9524125 },
    { lat: 48.717719, lng: 21.9523938 },
    { lat: 48.7182414, lng: 21.9510987 },
    { lat: 48.7187264, lng: 21.9496158 },
    { lat: 48.7188853, lng: 21.9489424 },
    { lat: 48.7189736, lng: 21.9482876 },
    { lat: 48.7189196, lng: 21.9477929 },
    { lat: 48.7189462, lng: 21.9472364 },
    { lat: 48.7191599, lng: 21.9454736 },
    { lat: 48.7191915, lng: 21.9443471 },
    { lat: 48.7196263, lng: 21.9431612 },
    { lat: 48.7197172, lng: 21.9427898 },
    { lat: 48.7198289, lng: 21.9426188 },
    { lat: 48.7195779, lng: 21.9418953 },
    { lat: 48.7204108, lng: 21.940649 },
    { lat: 48.7212152, lng: 21.9388053 },
    { lat: 48.7213079, lng: 21.9381951 },
    { lat: 48.7215028, lng: 21.9376659 },
    { lat: 48.7217282, lng: 21.9371835 },
    { lat: 48.7220431, lng: 21.9368744 },
    { lat: 48.7220425, lng: 21.933788 },
    { lat: 48.7220333, lng: 21.9336453 },
    { lat: 48.7210107, lng: 21.9335627 },
    { lat: 48.7205901, lng: 21.9326195 },
    { lat: 48.7197237, lng: 21.9314022 },
    { lat: 48.7192454, lng: 21.9304986 },
    { lat: 48.7190519, lng: 21.9299532 },
    { lat: 48.7189311, lng: 21.9293955 },
    { lat: 48.7186744, lng: 21.9285879 },
    { lat: 48.7183676, lng: 21.9287715 },
    { lat: 48.7179288, lng: 21.928776 },
    { lat: 48.7175914, lng: 21.9285965 },
    { lat: 48.7167047, lng: 21.9278212 },
    { lat: 48.7163355, lng: 21.9276785 },
    { lat: 48.7160793, lng: 21.9276526 },
    { lat: 48.713976, lng: 21.9283352 },
    { lat: 48.7132949, lng: 21.9284929 },
    { lat: 48.7130536, lng: 21.9286567 },
    { lat: 48.7128255, lng: 21.9283969 },
    { lat: 48.7128132, lng: 21.9283591 },
    { lat: 48.71313, lng: 21.9281607 },
    { lat: 48.7126093, lng: 21.9273541 },
    { lat: 48.71217, lng: 21.9261664 },
    { lat: 48.7123884, lng: 21.9255497 },
    { lat: 48.7123187, lng: 21.9246992 },
    { lat: 48.7116178, lng: 21.9221451 },
    { lat: 48.7114036, lng: 21.9215016 },
    { lat: 48.7110173, lng: 21.9205856 },
    { lat: 48.7104762, lng: 21.9188029 },
    { lat: 48.7098523, lng: 21.9154278 },
    { lat: 48.7090927, lng: 21.9113129 },
  ],
  MaťovskéVojkovce: [
    { lat: 48.5842728, lng: 22.1259674 },
    { lat: 48.584154, lng: 22.1253136 },
    { lat: 48.5834111, lng: 22.1211307 },
    { lat: 48.5822447, lng: 22.1159615 },
    { lat: 48.5809772, lng: 22.1127264 },
    { lat: 48.5800036, lng: 22.1106946 },
    { lat: 48.5799463, lng: 22.110742 },
    { lat: 48.5798381, lng: 22.110831 },
    { lat: 48.579384, lng: 22.1104695 },
    { lat: 48.5789071, lng: 22.1099462 },
    { lat: 48.5776413, lng: 22.1096967 },
    { lat: 48.5759692, lng: 22.1090038 },
    { lat: 48.5746087, lng: 22.1086955 },
    { lat: 48.5738896, lng: 22.1087294 },
    { lat: 48.5717475, lng: 22.1090055 },
    { lat: 48.5707775, lng: 22.1089964 },
    { lat: 48.5697716, lng: 22.1090737 },
    { lat: 48.5692082, lng: 22.109182 },
    { lat: 48.5682947, lng: 22.109508 },
    { lat: 48.5676189, lng: 22.1095659 },
    { lat: 48.5635679, lng: 22.1096017 },
    { lat: 48.5622907, lng: 22.1098676 },
    { lat: 48.5615592, lng: 22.1099064 },
    { lat: 48.5604071, lng: 22.1103296 },
    { lat: 48.5605063, lng: 22.1109099 },
    { lat: 48.5605761, lng: 22.111768 },
    { lat: 48.5606222, lng: 22.1140557 },
    { lat: 48.5603281, lng: 22.1155715 },
    { lat: 48.5602295, lng: 22.1155395 },
    { lat: 48.5600708, lng: 22.1171083 },
    { lat: 48.5594577, lng: 22.1193541 },
    { lat: 48.5593131, lng: 22.1202915 },
    { lat: 48.5588128, lng: 22.1201549 },
    { lat: 48.5585242, lng: 22.119894 },
    { lat: 48.5583171, lng: 22.1195961 },
    { lat: 48.5567075, lng: 22.1158228 },
    { lat: 48.5563901, lng: 22.1151999 },
    { lat: 48.5560971, lng: 22.1147479 },
    { lat: 48.5557624, lng: 22.1144299 },
    { lat: 48.5564445, lng: 22.1128246 },
    { lat: 48.5568421, lng: 22.1120321 },
    { lat: 48.5568621, lng: 22.1121188 },
    { lat: 48.5570483, lng: 22.1117214 },
    { lat: 48.5570366, lng: 22.1116517 },
    { lat: 48.5576754, lng: 22.1103558 },
    { lat: 48.5577586, lng: 22.1100638 },
    { lat: 48.5570671, lng: 22.1082688 },
    { lat: 48.5566266, lng: 22.1086717 },
    { lat: 48.5550339, lng: 22.109824 },
    { lat: 48.5548557, lng: 22.1100857 },
    { lat: 48.5544089, lng: 22.1105581 },
    { lat: 48.5538436, lng: 22.1108181 },
    { lat: 48.5537223, lng: 22.1107713 },
    { lat: 48.5534003, lng: 22.1108098 },
    { lat: 48.5525779, lng: 22.1116609 },
    { lat: 48.5531289, lng: 22.1144787 },
    { lat: 48.5524465, lng: 22.1151078 },
    { lat: 48.5518993, lng: 22.1152847 },
    { lat: 48.5526208, lng: 22.118309 },
    { lat: 48.5527945, lng: 22.1188564 },
    { lat: 48.5526384, lng: 22.1191069 },
    { lat: 48.5529629, lng: 22.1197042 },
    { lat: 48.5537355, lng: 22.1223782 },
    { lat: 48.5538802, lng: 22.1227877 },
    { lat: 48.5541972, lng: 22.1233997 },
    { lat: 48.5536566, lng: 22.1239476 },
    { lat: 48.5527589, lng: 22.1244758 },
    { lat: 48.5519815, lng: 22.1246152 },
    { lat: 48.5517096, lng: 22.1249141 },
    { lat: 48.5512373, lng: 22.1250184 },
    { lat: 48.5518259, lng: 22.1270485 },
    { lat: 48.5516461, lng: 22.1271011 },
    { lat: 48.552251, lng: 22.1290526 },
    { lat: 48.5521144, lng: 22.1291795 },
    { lat: 48.552183, lng: 22.1293214 },
    { lat: 48.5521237, lng: 22.1294357 },
    { lat: 48.5526926, lng: 22.1312404 },
    { lat: 48.551514, lng: 22.1315441 },
    { lat: 48.5508483, lng: 22.1318447 },
    { lat: 48.5503142, lng: 22.131948 },
    { lat: 48.5503168, lng: 22.1319667 },
    { lat: 48.5508669, lng: 22.1346532 },
    { lat: 48.5509752, lng: 22.1350103 },
    { lat: 48.5509263, lng: 22.1350371 },
    { lat: 48.5522759, lng: 22.1383421 },
    { lat: 48.5528864, lng: 22.140068 },
    { lat: 48.5525995, lng: 22.1403065 },
    { lat: 48.5528197, lng: 22.1407256 },
    { lat: 48.5534616, lng: 22.1426366 },
    { lat: 48.5537005, lng: 22.1431875 },
    { lat: 48.5548448, lng: 22.1425759 },
    { lat: 48.5553273, lng: 22.1420881 },
    { lat: 48.5564136, lng: 22.1414972 },
    { lat: 48.5567802, lng: 22.1414892 },
    { lat: 48.5581415, lng: 22.1419377 },
    { lat: 48.5584611, lng: 22.1419869 },
    { lat: 48.5595851, lng: 22.1440751 },
    { lat: 48.5599906, lng: 22.1438022 },
    { lat: 48.5601991, lng: 22.1435345 },
    { lat: 48.5603693, lng: 22.1439296 },
    { lat: 48.5605016, lng: 22.1439112 },
    { lat: 48.5610501, lng: 22.1450552 },
    { lat: 48.5607753, lng: 22.1452792 },
    { lat: 48.562352, lng: 22.1486513 },
    { lat: 48.563484, lng: 22.148127 },
    { lat: 48.5636846, lng: 22.149548 },
    { lat: 48.5657091, lng: 22.1498307 },
    { lat: 48.565853, lng: 22.1504098 },
    { lat: 48.566136, lng: 22.1511183 },
    { lat: 48.5667104, lng: 22.1521095 },
    { lat: 48.5670486, lng: 22.1528175 },
    { lat: 48.5674445, lng: 22.1536463 },
    { lat: 48.567983, lng: 22.1551877 },
    { lat: 48.568038, lng: 22.1555642 },
    { lat: 48.567978, lng: 22.1562297 },
    { lat: 48.5673811, lng: 22.1587199 },
    { lat: 48.5672614, lng: 22.1609681 },
    { lat: 48.57713, lng: 22.166018 },
    { lat: 48.575144, lng: 22.158334 },
    { lat: 48.575096, lng: 22.158146 },
    { lat: 48.575068, lng: 22.158005 },
    { lat: 48.573906, lng: 22.153466 },
    { lat: 48.573958, lng: 22.15344 },
    { lat: 48.575701, lng: 22.152931 },
    { lat: 48.576694, lng: 22.152361 },
    { lat: 48.577647, lng: 22.151651 },
    { lat: 48.578125, lng: 22.151452 },
    { lat: 48.578211, lng: 22.151449 },
    { lat: 48.578786, lng: 22.151279 },
    { lat: 48.579227, lng: 22.151148 },
    { lat: 48.579318, lng: 22.151123 },
    { lat: 48.579435, lng: 22.151092 },
    { lat: 48.5809, lng: 22.150702 },
    { lat: 48.581632, lng: 22.150437 },
    { lat: 48.583263, lng: 22.149836 },
    { lat: 48.583353, lng: 22.149852 },
    { lat: 48.583367, lng: 22.149854 },
    { lat: 48.583437, lng: 22.149866 },
    { lat: 48.583453, lng: 22.149869 },
    { lat: 48.583485, lng: 22.149875 },
    { lat: 48.5844, lng: 22.150006 },
    { lat: 48.585145, lng: 22.150163 },
    { lat: 48.585775, lng: 22.15028 },
    { lat: 48.586914, lng: 22.150536 },
    { lat: 48.587254, lng: 22.150603 },
    { lat: 48.587342, lng: 22.150606 },
    { lat: 48.587428, lng: 22.150582 },
    { lat: 48.587537, lng: 22.150501 },
    { lat: 48.587781, lng: 22.150258 },
    { lat: 48.587856, lng: 22.150183 },
    { lat: 48.587936, lng: 22.150103 },
    { lat: 48.588287, lng: 22.149753 },
    { lat: 48.588352, lng: 22.149687 },
    { lat: 48.588578, lng: 22.149455 },
    { lat: 48.588844, lng: 22.149183 },
    { lat: 48.58893, lng: 22.149096 },
    { lat: 48.589095, lng: 22.148927 },
    { lat: 48.589351, lng: 22.148667 },
    { lat: 48.5896, lng: 22.148415 },
    { lat: 48.589795, lng: 22.148217 },
    { lat: 48.590008, lng: 22.148004 },
    { lat: 48.590452, lng: 22.147559 },
    { lat: 48.590644, lng: 22.147366 },
    { lat: 48.590759, lng: 22.147251 },
    { lat: 48.5909, lng: 22.147107 },
    { lat: 48.591734, lng: 22.146263 },
    { lat: 48.591819, lng: 22.146256 },
    { lat: 48.591876, lng: 22.146252 },
    { lat: 48.591902, lng: 22.146262 },
    { lat: 48.591966, lng: 22.146286 },
    { lat: 48.592028, lng: 22.146223 },
    { lat: 48.592093, lng: 22.146124 },
    { lat: 48.592185, lng: 22.146052 },
    { lat: 48.592261, lng: 22.145998 },
    { lat: 48.592343, lng: 22.145988 },
    { lat: 48.592504, lng: 22.14608 },
    { lat: 48.593419, lng: 22.146427 },
    { lat: 48.59358, lng: 22.146409 },
    { lat: 48.593618, lng: 22.146405 },
    { lat: 48.5938, lng: 22.146455 },
    { lat: 48.594477, lng: 22.146801 },
    { lat: 48.594554, lng: 22.146811 },
    { lat: 48.594624, lng: 22.146773 },
    { lat: 48.594686, lng: 22.14665 },
    { lat: 48.594852, lng: 22.14625 },
    { lat: 48.59513, lng: 22.145487 },
    { lat: 48.595248, lng: 22.145162 },
    { lat: 48.595288, lng: 22.145053 },
    { lat: 48.5953, lng: 22.145018 },
    { lat: 48.595311, lng: 22.144987 },
    { lat: 48.595359, lng: 22.14485 },
    { lat: 48.595388, lng: 22.144762 },
    { lat: 48.595446, lng: 22.144486 },
    { lat: 48.595492, lng: 22.144218 },
    { lat: 48.595491, lng: 22.143857 },
    { lat: 48.595472, lng: 22.143617 },
    { lat: 48.595433, lng: 22.143387 },
    { lat: 48.595236, lng: 22.142871 },
    { lat: 48.595143, lng: 22.142655 },
    { lat: 48.59506, lng: 22.142464 },
    { lat: 48.595042, lng: 22.142423 },
    { lat: 48.595014, lng: 22.142359 },
    { lat: 48.594976, lng: 22.142252 },
    { lat: 48.594922, lng: 22.142095 },
    { lat: 48.594873, lng: 22.141861 },
    { lat: 48.594871, lng: 22.141745 },
    { lat: 48.594907, lng: 22.141625 },
    { lat: 48.594913, lng: 22.14161 },
    { lat: 48.594952, lng: 22.14152 },
    { lat: 48.594963, lng: 22.141494 },
    { lat: 48.595016, lng: 22.141371 },
    { lat: 48.595154, lng: 22.141056 },
    { lat: 48.595447, lng: 22.140377 },
    { lat: 48.595712, lng: 22.139674 },
    { lat: 48.595786, lng: 22.139481 },
    { lat: 48.595946, lng: 22.139058 },
    { lat: 48.596026, lng: 22.138804 },
    { lat: 48.596181, lng: 22.138313 },
    { lat: 48.596511, lng: 22.137115 },
    { lat: 48.596641, lng: 22.136784 },
    { lat: 48.596694, lng: 22.136647 },
    { lat: 48.596744, lng: 22.136519 },
    { lat: 48.596282, lng: 22.136165 },
    { lat: 48.596048, lng: 22.136076 },
    { lat: 48.595943, lng: 22.135714 },
    { lat: 48.595855, lng: 22.135421 },
    { lat: 48.595769, lng: 22.135195 },
    { lat: 48.595674, lng: 22.134983 },
    { lat: 48.595567, lng: 22.134772 },
    { lat: 48.595351, lng: 22.134411 },
    { lat: 48.595037, lng: 22.13393 },
    { lat: 48.594574, lng: 22.133148 },
    { lat: 48.594407, lng: 22.132789 },
    { lat: 48.593886, lng: 22.131355 },
    { lat: 48.593712, lng: 22.130887 },
    { lat: 48.5930907, lng: 22.1300987 },
    { lat: 48.5914923, lng: 22.1283673 },
    { lat: 48.5903296, lng: 22.127285 },
    { lat: 48.5890072, lng: 22.127549 },
    { lat: 48.5881539, lng: 22.1265992 },
    { lat: 48.5872716, lng: 22.125775 },
    { lat: 48.5863499, lng: 22.1258028 },
    { lat: 48.5843141, lng: 22.1262022 },
    { lat: 48.5842728, lng: 22.1259674 },
  ],
  Beša: [
    { lat: 48.509372, lng: 21.977732 },
    { lat: 48.5095279, lng: 21.9776118 },
    { lat: 48.5100368, lng: 21.977629 },
    { lat: 48.5104881, lng: 21.9761398 },
    { lat: 48.5112047, lng: 21.9748617 },
    { lat: 48.5116253, lng: 21.9748171 },
    { lat: 48.5143397, lng: 21.9763991 },
    { lat: 48.5147027, lng: 21.9763759 },
    { lat: 48.5151026, lng: 21.9755838 },
    { lat: 48.5151531, lng: 21.974806 },
    { lat: 48.5194704, lng: 21.9714365 },
    { lat: 48.5217596, lng: 21.9737426 },
    { lat: 48.5243618, lng: 21.9738317 },
    { lat: 48.5265212, lng: 21.9743563 },
    { lat: 48.5265693, lng: 21.9741193 },
    { lat: 48.5278831, lng: 21.973845 },
    { lat: 48.5284477, lng: 21.9732475 },
    { lat: 48.5289764, lng: 21.9730158 },
    { lat: 48.5295549, lng: 21.9733464 },
    { lat: 48.5299133, lng: 21.9732855 },
    { lat: 48.530239, lng: 21.9729009 },
    { lat: 48.5303229, lng: 21.972111 },
    { lat: 48.5326803, lng: 21.9724924 },
    { lat: 48.5330486, lng: 21.9730556 },
    { lat: 48.5336297, lng: 21.9749397 },
    { lat: 48.5339783, lng: 21.975345 },
    { lat: 48.5364507, lng: 21.9766005 },
    { lat: 48.5379142, lng: 21.9786408 },
    { lat: 48.5387632, lng: 21.9790827 },
    { lat: 48.5391668, lng: 21.9789756 },
    { lat: 48.5394865, lng: 21.9793013 },
    { lat: 48.5396201, lng: 21.9793378 },
    { lat: 48.5399429, lng: 21.9790652 },
    { lat: 48.5402969, lng: 21.9793204 },
    { lat: 48.540305, lng: 21.9794258 },
    { lat: 48.5420008, lng: 21.9792603 },
    { lat: 48.5448589, lng: 21.9788309 },
    { lat: 48.5446377, lng: 21.9779433 },
    { lat: 48.5455793, lng: 21.9772989 },
    { lat: 48.5474225, lng: 21.9763259 },
    { lat: 48.5474637, lng: 21.9763037 },
    { lat: 48.5474624, lng: 21.9762992 },
    { lat: 48.5465749, lng: 21.973074 },
    { lat: 48.5454031, lng: 21.9690566 },
    { lat: 48.5456201, lng: 21.968417 },
    { lat: 48.5436338, lng: 21.9655424 },
    { lat: 48.546978, lng: 21.9639418 },
    { lat: 48.5468558, lng: 21.9611041 },
    { lat: 48.5465768, lng: 21.959347 },
    { lat: 48.5465199, lng: 21.9586427 },
    { lat: 48.5458538, lng: 21.9547127 },
    { lat: 48.5456121, lng: 21.9521061 },
    { lat: 48.5470111, lng: 21.9511382 },
    { lat: 48.5466624, lng: 21.9496064 },
    { lat: 48.5483548, lng: 21.9483245 },
    { lat: 48.5491057, lng: 21.9475743 },
    { lat: 48.5485478, lng: 21.9465013 },
    { lat: 48.5474769, lng: 21.945111 },
    { lat: 48.5511445, lng: 21.94063 },
    { lat: 48.5517554, lng: 21.9400026 },
    { lat: 48.553197, lng: 21.9383123 },
    { lat: 48.5572329, lng: 21.9351567 },
    { lat: 48.5579944, lng: 21.9340692 },
    { lat: 48.5573424, lng: 21.9330588 },
    { lat: 48.5572241, lng: 21.9318495 },
    { lat: 48.5572936, lng: 21.9309382 },
    { lat: 48.55766, lng: 21.9302117 },
    { lat: 48.5575894, lng: 21.9300942 },
    { lat: 48.5573675, lng: 21.9297266 },
    { lat: 48.5571789, lng: 21.9295566 },
    { lat: 48.5567343, lng: 21.9303547 },
    { lat: 48.5563246, lng: 21.9308781 },
    { lat: 48.5562147, lng: 21.9307168 },
    { lat: 48.5563368, lng: 21.9306061 },
    { lat: 48.5569517, lng: 21.9293352 },
    { lat: 48.5563824, lng: 21.9290608 },
    { lat: 48.5559594, lng: 21.9287114 },
    { lat: 48.5553833, lng: 21.9284812 },
    { lat: 48.5553032, lng: 21.9286523 },
    { lat: 48.5552185, lng: 21.9286394 },
    { lat: 48.5551888, lng: 21.928396 },
    { lat: 48.553435, lng: 21.9276433 },
    { lat: 48.551621, lng: 21.9267729 },
    { lat: 48.5504137, lng: 21.9260983 },
    { lat: 48.5492143, lng: 21.9240343 },
    { lat: 48.5490761, lng: 21.9241363 },
    { lat: 48.5490053, lng: 21.9240011 },
    { lat: 48.5490972, lng: 21.923851 },
    { lat: 48.5479273, lng: 21.9218057 },
    { lat: 48.5466632, lng: 21.9211033 },
    { lat: 48.5465921, lng: 21.9204714 },
    { lat: 48.544373, lng: 21.9212578 },
    { lat: 48.5436625, lng: 21.9213595 },
    { lat: 48.5432188, lng: 21.9215738 },
    { lat: 48.5432481, lng: 21.9217085 },
    { lat: 48.542887, lng: 21.921972 },
    { lat: 48.5412009, lng: 21.9219424 },
    { lat: 48.5408827, lng: 21.9216358 },
    { lat: 48.5404989, lng: 21.9215893 },
    { lat: 48.5391255, lng: 21.9222149 },
    { lat: 48.5383942, lng: 21.9223097 },
    { lat: 48.5382244, lng: 21.9224465 },
    { lat: 48.536443, lng: 21.9246485 },
    { lat: 48.5361903, lng: 21.9248929 },
    { lat: 48.5358582, lng: 21.9250621 },
    { lat: 48.5343929, lng: 21.9254412 },
    { lat: 48.5326521, lng: 21.9260078 },
    { lat: 48.5289624, lng: 21.9270431 },
    { lat: 48.5245858, lng: 21.9278406 },
    { lat: 48.5239862, lng: 21.9278848 },
    { lat: 48.522281, lng: 21.9282558 },
    { lat: 48.5228069, lng: 21.9217814 },
    { lat: 48.5228263, lng: 21.9215428 },
    { lat: 48.5216335, lng: 21.9166885 },
    { lat: 48.5216235, lng: 21.9166464 },
    { lat: 48.5214929, lng: 21.9166844 },
    { lat: 48.5209892, lng: 21.9172268 },
    { lat: 48.520516, lng: 21.9174679 },
    { lat: 48.5189486, lng: 21.9180375 },
    { lat: 48.5166204, lng: 21.9182844 },
    { lat: 48.5156445, lng: 21.9185305 },
    { lat: 48.5150889, lng: 21.9183991 },
    { lat: 48.5143804, lng: 21.9178829 },
    { lat: 48.5134609, lng: 21.9181321 },
    { lat: 48.510926, lng: 21.9196859 },
    { lat: 48.5099238, lng: 21.9200491 },
    { lat: 48.5091103, lng: 21.9211994 },
    { lat: 48.5086488, lng: 21.9212765 },
    { lat: 48.5071626, lng: 21.9211434 },
    { lat: 48.5053703, lng: 21.9216326 },
    { lat: 48.5050734, lng: 21.921591 },
    { lat: 48.503015, lng: 21.921868 },
    { lat: 48.503034, lng: 21.923086 },
    { lat: 48.503039, lng: 21.924093 },
    { lat: 48.503067, lng: 21.925468 },
    { lat: 48.503085, lng: 21.926956 },
    { lat: 48.503122, lng: 21.928663 },
    { lat: 48.503139, lng: 21.930287 },
    { lat: 48.503156, lng: 21.931561 },
    { lat: 48.503173, lng: 21.932779 },
    { lat: 48.503192, lng: 21.934302 },
    { lat: 48.503223, lng: 21.936134 },
    { lat: 48.503264, lng: 21.93735 },
    { lat: 48.503297, lng: 21.939021 },
    { lat: 48.503311, lng: 21.940374 },
    { lat: 48.503325, lng: 21.941179 },
    { lat: 48.503391, lng: 21.942255 },
    { lat: 48.503512, lng: 21.94346 },
    { lat: 48.503635, lng: 21.944663 },
    { lat: 48.50384, lng: 21.946443 },
    { lat: 48.503973, lng: 21.947726 },
    { lat: 48.504159, lng: 21.949646 },
    { lat: 48.504344, lng: 21.951249 },
    { lat: 48.504368, lng: 21.951765 },
    { lat: 48.50438, lng: 21.951831 },
    { lat: 48.504562, lng: 21.952844 },
    { lat: 48.504753, lng: 21.954441 },
    { lat: 48.504938, lng: 21.956026 },
    { lat: 48.505174, lng: 21.958029 },
    { lat: 48.505369, lng: 21.95962 },
    { lat: 48.505513, lng: 21.960599 },
    { lat: 48.505532, lng: 21.960712 },
    { lat: 48.505556, lng: 21.960977 },
    { lat: 48.505578, lng: 21.961207 },
    { lat: 48.505604, lng: 21.961474 },
    { lat: 48.505637, lng: 21.961814 },
    { lat: 48.505663, lng: 21.962098 },
    { lat: 48.505795, lng: 21.963324 },
    { lat: 48.505844, lng: 21.963846 },
    { lat: 48.505937, lng: 21.964521 },
    { lat: 48.506136, lng: 21.966394 },
    { lat: 48.506164, lng: 21.96669 },
    { lat: 48.506215, lng: 21.967225 },
    { lat: 48.50628, lng: 21.96759 },
    { lat: 48.506465, lng: 21.969077 },
    { lat: 48.506523, lng: 21.969497 },
    { lat: 48.506715, lng: 21.971054 },
    { lat: 48.5068, lng: 21.971735 },
    { lat: 48.506855, lng: 21.972254 },
    { lat: 48.506938, lng: 21.972567 },
    { lat: 48.506995, lng: 21.973304 },
    { lat: 48.507029, lng: 21.97372 },
    { lat: 48.507159, lng: 21.974647 },
    { lat: 48.507269, lng: 21.975141 },
    { lat: 48.508299, lng: 21.976298 },
    { lat: 48.508543, lng: 21.97652 },
    { lat: 48.50876, lng: 21.976721 },
    { lat: 48.509083, lng: 21.977313 },
    { lat: 48.509372, lng: 21.977732 },
  ],
  Šamudovce: [
    { lat: 48.6970728, lng: 21.8633824 },
    { lat: 48.696782, lng: 21.8663844 },
    { lat: 48.6967429, lng: 21.8674049 },
    { lat: 48.6963897, lng: 21.8686367 },
    { lat: 48.6966213, lng: 21.8699917 },
    { lat: 48.6970002, lng: 21.8730639 },
    { lat: 48.6972995, lng: 21.8749961 },
    { lat: 48.6975981, lng: 21.8783622 },
    { lat: 48.697934, lng: 21.8842554 },
    { lat: 48.6980583, lng: 21.8855548 },
    { lat: 48.6981141, lng: 21.8861516 },
    { lat: 48.6981412, lng: 21.8863391 },
    { lat: 48.6982143, lng: 21.8862081 },
    { lat: 48.6984718, lng: 21.8859809 },
    { lat: 48.6988337, lng: 21.8860115 },
    { lat: 48.6989146, lng: 21.8869388 },
    { lat: 48.6990965, lng: 21.8905917 },
    { lat: 48.6995143, lng: 21.8906996 },
    { lat: 48.7007492, lng: 21.8903672 },
    { lat: 48.7011288, lng: 21.8901679 },
    { lat: 48.7013219, lng: 21.8898892 },
    { lat: 48.7017594, lng: 21.8889998 },
    { lat: 48.7021917, lng: 21.8884405 },
    { lat: 48.7025895, lng: 21.8896568 },
    { lat: 48.7024428, lng: 21.8897312 },
    { lat: 48.7027161, lng: 21.891364 },
    { lat: 48.7033023, lng: 21.895575 },
    { lat: 48.7033567, lng: 21.8975439 },
    { lat: 48.7040542, lng: 21.8978469 },
    { lat: 48.7041727, lng: 21.8982179 },
    { lat: 48.7041832, lng: 21.8984171 },
    { lat: 48.7040812, lng: 21.8987378 },
    { lat: 48.7041185, lng: 21.8992304 },
    { lat: 48.704072, lng: 21.9004205 },
    { lat: 48.7048527, lng: 21.9004623 },
    { lat: 48.7050314, lng: 21.9006322 },
    { lat: 48.7052159, lng: 21.9009435 },
    { lat: 48.7052359, lng: 21.9012881 },
    { lat: 48.7051103, lng: 21.9016163 },
    { lat: 48.704866, lng: 21.9019642 },
    { lat: 48.7051531, lng: 21.9029976 },
    { lat: 48.7048, lng: 21.9031298 },
    { lat: 48.704858, lng: 21.9036037 },
    { lat: 48.7056518, lng: 21.903451 },
    { lat: 48.7061334, lng: 21.903587 },
    { lat: 48.7061523, lng: 21.903759 },
    { lat: 48.7062098, lng: 21.903848 },
    { lat: 48.7065263, lng: 21.9038176 },
    { lat: 48.7068378, lng: 21.9037746 },
    { lat: 48.7080611, lng: 21.9035259 },
    { lat: 48.7087388, lng: 21.9031904 },
    { lat: 48.7090312, lng: 21.9032746 },
    { lat: 48.7093778, lng: 21.9038096 },
    { lat: 48.7098203, lng: 21.9032037 },
    { lat: 48.7103706, lng: 21.9033857 },
    { lat: 48.7105208, lng: 21.9033677 },
    { lat: 48.7109529, lng: 21.9031899 },
    { lat: 48.7111434, lng: 21.9028929 },
    { lat: 48.7112897, lng: 21.9024046 },
    { lat: 48.7110621, lng: 21.9021307 },
    { lat: 48.7102358, lng: 21.9015697 },
    { lat: 48.7100814, lng: 21.9013407 },
    { lat: 48.7099623, lng: 21.9009468 },
    { lat: 48.7099974, lng: 21.9002003 },
    { lat: 48.7100504, lng: 21.9000302 },
    { lat: 48.7102954, lng: 21.8998871 },
    { lat: 48.7106673, lng: 21.8998271 },
    { lat: 48.7105425, lng: 21.8978673 },
    { lat: 48.7104245, lng: 21.8970834 },
    { lat: 48.709834, lng: 21.8969081 },
    { lat: 48.7094878, lng: 21.895944 },
    { lat: 48.7104323, lng: 21.8953012 },
    { lat: 48.7109431, lng: 21.8961432 },
    { lat: 48.7110762, lng: 21.8954042 },
    { lat: 48.7114032, lng: 21.89495 },
    { lat: 48.710892, lng: 21.8920516 },
    { lat: 48.7111334, lng: 21.8919852 },
    { lat: 48.7111988, lng: 21.891908 },
    { lat: 48.7109906, lng: 21.8908002 },
    { lat: 48.7111375, lng: 21.8906915 },
    { lat: 48.7110723, lng: 21.8902515 },
    { lat: 48.7125912, lng: 21.8898142 },
    { lat: 48.7141566, lng: 21.889085 },
    { lat: 48.7139811, lng: 21.887449 },
    { lat: 48.7139996, lng: 21.8850772 },
    { lat: 48.7134583, lng: 21.8849455 },
    { lat: 48.7122803, lng: 21.8844551 },
    { lat: 48.7123497, lng: 21.8850118 },
    { lat: 48.7108704, lng: 21.8847038 },
    { lat: 48.710492, lng: 21.8824361 },
    { lat: 48.7101556, lng: 21.8799597 },
    { lat: 48.7105845, lng: 21.8797491 },
    { lat: 48.7128382, lng: 21.8790219 },
    { lat: 48.7128477, lng: 21.8790801 },
    { lat: 48.7136414, lng: 21.8793292 },
    { lat: 48.7145084, lng: 21.8793086 },
    { lat: 48.7145406, lng: 21.8795611 },
    { lat: 48.7151568, lng: 21.8790617 },
    { lat: 48.7156657, lng: 21.8785522 },
    { lat: 48.7162604, lng: 21.877701 },
    { lat: 48.7165657, lng: 21.8775813 },
    { lat: 48.7169091, lng: 21.8775032 },
    { lat: 48.7167684, lng: 21.8765702 },
    { lat: 48.716571, lng: 21.874613 },
    { lat: 48.7181808, lng: 21.8742576 },
    { lat: 48.7182214, lng: 21.8741758 },
    { lat: 48.7184483, lng: 21.874165 },
    { lat: 48.718722, lng: 21.8737771 },
    { lat: 48.7185299, lng: 21.8735735 },
    { lat: 48.7182802, lng: 21.8734449 },
    { lat: 48.7173584, lng: 21.872675 },
    { lat: 48.7158926, lng: 21.871223 },
    { lat: 48.7152775, lng: 21.8668465 },
    { lat: 48.7091817, lng: 21.8577455 },
    { lat: 48.7091001, lng: 21.8577702 },
    { lat: 48.7087976, lng: 21.857861 },
    { lat: 48.7085093, lng: 21.8579349 },
    { lat: 48.7084437, lng: 21.8580057 },
    { lat: 48.7082971, lng: 21.8584752 },
    { lat: 48.7081424, lng: 21.8587219 },
    { lat: 48.7080235, lng: 21.8588172 },
    { lat: 48.7077711, lng: 21.8588487 },
    { lat: 48.7074094, lng: 21.8587813 },
    { lat: 48.7071906, lng: 21.8586253 },
    { lat: 48.7070866, lng: 21.8584764 },
    { lat: 48.7068338, lng: 21.8574839 },
    { lat: 48.7066708, lng: 21.8574415 },
    { lat: 48.7065131, lng: 21.8575238 },
    { lat: 48.7061445, lng: 21.8580619 },
    { lat: 48.7060123, lng: 21.8581287 },
    { lat: 48.7059294, lng: 21.8581037 },
    { lat: 48.7056058, lng: 21.857676 },
    { lat: 48.7055802, lng: 21.8577324 },
    { lat: 48.7056179, lng: 21.8581102 },
    { lat: 48.7049419, lng: 21.8581118 },
    { lat: 48.7045175, lng: 21.8582303 },
    { lat: 48.7036438, lng: 21.8589074 },
    { lat: 48.7032203, lng: 21.8592795 },
    { lat: 48.7029595, lng: 21.85959 },
    { lat: 48.7027596, lng: 21.8599116 },
    { lat: 48.7023895, lng: 21.8613159 },
    { lat: 48.7022073, lng: 21.86163 },
    { lat: 48.7020173, lng: 21.8618944 },
    { lat: 48.7017791, lng: 21.8618642 },
    { lat: 48.7015635, lng: 21.8619231 },
    { lat: 48.7012354, lng: 21.8625499 },
    { lat: 48.7010367, lng: 21.8623041 },
    { lat: 48.7008119, lng: 21.8615654 },
    { lat: 48.6999829, lng: 21.861707 },
    { lat: 48.6997134, lng: 21.8618054 },
    { lat: 48.6995536, lng: 21.861933 },
    { lat: 48.6993792, lng: 21.8623317 },
    { lat: 48.6990509, lng: 21.8641348 },
    { lat: 48.6988905, lng: 21.8642425 },
    { lat: 48.6987541, lng: 21.8642356 },
    { lat: 48.6981604, lng: 21.8638084 },
    { lat: 48.6970832, lng: 21.8632565 },
    { lat: 48.6970728, lng: 21.8633824 },
  ],
  Budkovce: [
    { lat: 48.659658, lng: 21.9623881 },
    { lat: 48.6578906, lng: 21.9551012 },
    { lat: 48.6576528, lng: 21.9555356 },
    { lat: 48.6572149, lng: 21.9557003 },
    { lat: 48.6568256, lng: 21.955649 },
    { lat: 48.6567375, lng: 21.955464 },
    { lat: 48.6565353, lng: 21.9553823 },
    { lat: 48.6564831, lng: 21.9552724 },
    { lat: 48.6563698, lng: 21.9555137 },
    { lat: 48.6561718, lng: 21.9556466 },
    { lat: 48.6558683, lng: 21.9556136 },
    { lat: 48.6557012, lng: 21.9557199 },
    { lat: 48.6557101, lng: 21.9560756 },
    { lat: 48.6555009, lng: 21.9563098 },
    { lat: 48.6549446, lng: 21.9561962 },
    { lat: 48.6544155, lng: 21.9565428 },
    { lat: 48.6541339, lng: 21.9568886 },
    { lat: 48.6539259, lng: 21.9568 },
    { lat: 48.653685, lng: 21.9570645 },
    { lat: 48.6530385, lng: 21.9569023 },
    { lat: 48.6522167, lng: 21.9570115 },
    { lat: 48.6520413, lng: 21.9567955 },
    { lat: 48.6518619, lng: 21.9562741 },
    { lat: 48.6517309, lng: 21.9561616 },
    { lat: 48.6516732, lng: 21.955966 },
    { lat: 48.6517046, lng: 21.9557408 },
    { lat: 48.6517852, lng: 21.9556338 },
    { lat: 48.6517493, lng: 21.9555945 },
    { lat: 48.6517032, lng: 21.9548208 },
    { lat: 48.6516358, lng: 21.9546421 },
    { lat: 48.6500652, lng: 21.9458828 },
    { lat: 48.6452714, lng: 21.9415489 },
    { lat: 48.6431949, lng: 21.9427813 },
    { lat: 48.638874, lng: 21.9421078 },
    { lat: 48.6388466, lng: 21.9415972 },
    { lat: 48.6404909, lng: 21.938707 },
    { lat: 48.6403052, lng: 21.9382085 },
    { lat: 48.640069, lng: 21.9377571 },
    { lat: 48.6395363, lng: 21.9370665 },
    { lat: 48.6386406, lng: 21.9384429 },
    { lat: 48.6379279, lng: 21.9393606 },
    { lat: 48.6372943, lng: 21.9379623 },
    { lat: 48.6385095, lng: 21.936711 },
    { lat: 48.6386573, lng: 21.9363248 },
    { lat: 48.6386077, lng: 21.9360086 },
    { lat: 48.6380299, lng: 21.9348169 },
    { lat: 48.6381125, lng: 21.9338888 },
    { lat: 48.638222, lng: 21.9332808 },
    { lat: 48.638524, lng: 21.932625 },
    { lat: 48.6394094, lng: 21.9315554 },
    { lat: 48.6406354, lng: 21.9294859 },
    { lat: 48.6423286, lng: 21.9286819 },
    { lat: 48.6447757, lng: 21.9266835 },
    { lat: 48.645953, lng: 21.9262034 },
    { lat: 48.646297, lng: 21.9261962 },
    { lat: 48.6465638, lng: 21.926346 },
    { lat: 48.646917, lng: 21.9266964 },
    { lat: 48.647371, lng: 21.9270323 },
    { lat: 48.6475432, lng: 21.9269057 },
    { lat: 48.6483194, lng: 21.9267706 },
    { lat: 48.648455, lng: 21.926883 },
    { lat: 48.6486095, lng: 21.9273933 },
    { lat: 48.6490116, lng: 21.9278121 },
    { lat: 48.6494767, lng: 21.9281402 },
    { lat: 48.6499723, lng: 21.9283502 },
    { lat: 48.6503589, lng: 21.9282 },
    { lat: 48.65092, lng: 21.9274215 },
    { lat: 48.6515226, lng: 21.9268252 },
    { lat: 48.6519082, lng: 21.9255187 },
    { lat: 48.6514593, lng: 21.9239996 },
    { lat: 48.6507669, lng: 21.9219651 },
    { lat: 48.650308, lng: 21.9203217 },
    { lat: 48.6507687, lng: 21.9192208 },
    { lat: 48.6510407, lng: 21.9187704 },
    { lat: 48.651278, lng: 21.9185405 },
    { lat: 48.6518463, lng: 21.9183257 },
    { lat: 48.6530556, lng: 21.9183239 },
    { lat: 48.6534957, lng: 21.918379 },
    { lat: 48.6534483, lng: 21.9171688 },
    { lat: 48.6535792, lng: 21.91665 },
    { lat: 48.6539621, lng: 21.9161757 },
    { lat: 48.6545749, lng: 21.9155826 },
    { lat: 48.6544113, lng: 21.9144043 },
    { lat: 48.6541858, lng: 21.9134467 },
    { lat: 48.6541594, lng: 21.9131593 },
    { lat: 48.6558211, lng: 21.9123161 },
    { lat: 48.6553922, lng: 21.9102603 },
    { lat: 48.6548742, lng: 21.9084605 },
    { lat: 48.6543586, lng: 21.9068923 },
    { lat: 48.6535114, lng: 21.9045747 },
    { lat: 48.6534228, lng: 21.9044933 },
    { lat: 48.6527297, lng: 21.9017399 },
    { lat: 48.6519577, lng: 21.9020862 },
    { lat: 48.6515647, lng: 21.9021125 },
    { lat: 48.6513293, lng: 21.9020599 },
    { lat: 48.6511881, lng: 21.9018592 },
    { lat: 48.6505283, lng: 21.9017941 },
    { lat: 48.6504448, lng: 21.9020996 },
    { lat: 48.6499282, lng: 21.9027534 },
    { lat: 48.649477, lng: 21.9031463 },
    { lat: 48.649038, lng: 21.9033111 },
    { lat: 48.6485038, lng: 21.9032366 },
    { lat: 48.647397, lng: 21.9039537 },
    { lat: 48.6471665, lng: 21.9042246 },
    { lat: 48.6470577, lng: 21.9044532 },
    { lat: 48.6468876, lng: 21.9044014 },
    { lat: 48.6440514, lng: 21.9064871 },
    { lat: 48.6446285, lng: 21.9085353 },
    { lat: 48.6446821, lng: 21.9095365 },
    { lat: 48.6448682, lng: 21.911249 },
    { lat: 48.6448588, lng: 21.9121709 },
    { lat: 48.6425064, lng: 21.9130138 },
    { lat: 48.641839, lng: 21.9105959 },
    { lat: 48.6417887, lng: 21.9083844 },
    { lat: 48.6420394, lng: 21.9071797 },
    { lat: 48.6421186, lng: 21.9065242 },
    { lat: 48.6420744, lng: 21.9056758 },
    { lat: 48.6418455, lng: 21.9044565 },
    { lat: 48.6416914, lng: 21.9040114 },
    { lat: 48.6410184, lng: 21.9027583 },
    { lat: 48.6409465, lng: 21.9021812 },
    { lat: 48.6410301, lng: 21.9016684 },
    { lat: 48.6412331, lng: 21.9012398 },
    { lat: 48.6414743, lng: 21.9009534 },
    { lat: 48.641891, lng: 21.9008735 },
    { lat: 48.6423272, lng: 21.9010923 },
    { lat: 48.6423948, lng: 21.900865 },
    { lat: 48.6419972, lng: 21.8994288 },
    { lat: 48.6395221, lng: 21.9007115 },
    { lat: 48.6395423, lng: 21.9000939 },
    { lat: 48.639643, lng: 21.8991401 },
    { lat: 48.6395896, lng: 21.8984111 },
    { lat: 48.6391446, lng: 21.895991 },
    { lat: 48.6391536, lng: 21.895549 },
    { lat: 48.6390012, lng: 21.8947362 },
    { lat: 48.6348883, lng: 21.8970406 },
    { lat: 48.6340431, lng: 21.8945418 },
    { lat: 48.6319401, lng: 21.8949363 },
    { lat: 48.6297524, lng: 21.8952126 },
    { lat: 48.6256448, lng: 21.8964829 },
    { lat: 48.6246905, lng: 21.8928038 },
    { lat: 48.6231498, lng: 21.8935743 },
    { lat: 48.6222745, lng: 21.8905285 },
    { lat: 48.6186037, lng: 21.8910184 },
    { lat: 48.6185318, lng: 21.8907979 },
    { lat: 48.6181757, lng: 21.8909042 },
    { lat: 48.617972, lng: 21.8910426 },
    { lat: 48.6175532, lng: 21.8915554 },
    { lat: 48.6167874, lng: 21.8894962 },
    { lat: 48.6165204, lng: 21.8889926 },
    { lat: 48.6149769, lng: 21.890357 },
    { lat: 48.6142205, lng: 21.8906558 },
    { lat: 48.6137297, lng: 21.890777 },
    { lat: 48.6136711, lng: 21.8908202 },
    { lat: 48.6141493, lng: 21.8928073 },
    { lat: 48.6139438, lng: 21.8929582 },
    { lat: 48.6142411, lng: 21.894024 },
    { lat: 48.613322, lng: 21.8945639 },
    { lat: 48.613445, lng: 21.8959885 },
    { lat: 48.6132798, lng: 21.8960837 },
    { lat: 48.6137491, lng: 21.8979921 },
    { lat: 48.6135581, lng: 21.8980461 },
    { lat: 48.6136978, lng: 21.8986731 },
    { lat: 48.6134951, lng: 21.8987118 },
    { lat: 48.6138174, lng: 21.9000673 },
    { lat: 48.61364, lng: 21.9000807 },
    { lat: 48.6141588, lng: 21.9021687 },
    { lat: 48.6143006, lng: 21.9021285 },
    { lat: 48.6143836, lng: 21.9024245 },
    { lat: 48.6135211, lng: 21.9028013 },
    { lat: 48.6143433, lng: 21.905809 },
    { lat: 48.6146904, lng: 21.9057837 },
    { lat: 48.6153328, lng: 21.9055621 },
    { lat: 48.6161783, lng: 21.9054099 },
    { lat: 48.617899, lng: 21.9056976 },
    { lat: 48.618778, lng: 21.905584 },
    { lat: 48.6190372, lng: 21.905643 },
    { lat: 48.6194459, lng: 21.905883 },
    { lat: 48.6199694, lng: 21.9081587 },
    { lat: 48.6195131, lng: 21.9084452 },
    { lat: 48.6198484, lng: 21.9107094 },
    { lat: 48.6205321, lng: 21.9137714 },
    { lat: 48.6188114, lng: 21.9150873 },
    { lat: 48.6190897, lng: 21.9156406 },
    { lat: 48.6189808, lng: 21.9157333 },
    { lat: 48.6191829, lng: 21.916607 },
    { lat: 48.6188198, lng: 21.9169767 },
    { lat: 48.6190687, lng: 21.9178335 },
    { lat: 48.618916, lng: 21.9179781 },
    { lat: 48.6190661, lng: 21.9185373 },
    { lat: 48.6189859, lng: 21.9186077 },
    { lat: 48.6191577, lng: 21.9192229 },
    { lat: 48.6177783, lng: 21.9202841 },
    { lat: 48.6179384, lng: 21.9227101 },
    { lat: 48.6182252, lng: 21.9239616 },
    { lat: 48.6180687, lng: 21.924175 },
    { lat: 48.618024, lng: 21.9249949 },
    { lat: 48.6179849, lng: 21.9261211 },
    { lat: 48.6180394, lng: 21.9282872 },
    { lat: 48.6173984, lng: 21.928295 },
    { lat: 48.6173981, lng: 21.9286012 },
    { lat: 48.6169921, lng: 21.9287414 },
    { lat: 48.6172217, lng: 21.9308057 },
    { lat: 48.6172077, lng: 21.9314966 },
    { lat: 48.6172911, lng: 21.9324045 },
    { lat: 48.617198, lng: 21.933708 },
    { lat: 48.6170288, lng: 21.93437 },
    { lat: 48.6169192, lng: 21.9351489 },
    { lat: 48.6167156, lng: 21.9355121 },
    { lat: 48.6166964, lng: 21.9358713 },
    { lat: 48.6165552, lng: 21.935938 },
    { lat: 48.6165273, lng: 21.9362567 },
    { lat: 48.6161128, lng: 21.9366832 },
    { lat: 48.6161665, lng: 21.937078 },
    { lat: 48.6161739, lng: 21.9377299 },
    { lat: 48.6153163, lng: 21.9377671 },
    { lat: 48.6152188, lng: 21.9379618 },
    { lat: 48.6143989, lng: 21.9395881 },
    { lat: 48.6145363, lng: 21.9399067 },
    { lat: 48.6144947, lng: 21.9406286 },
    { lat: 48.6142325, lng: 21.9420585 },
    { lat: 48.6145236, lng: 21.9422265 },
    { lat: 48.6146075, lng: 21.9423955 },
    { lat: 48.6145505, lng: 21.9428119 },
    { lat: 48.6145572, lng: 21.9432111 },
    { lat: 48.614171, lng: 21.9444618 },
    { lat: 48.6138421, lng: 21.944846 },
    { lat: 48.6137283, lng: 21.9461401 },
    { lat: 48.6137678, lng: 21.9464653 },
    { lat: 48.614015, lng: 21.9470869 },
    { lat: 48.6146749, lng: 21.9476531 },
    { lat: 48.6155443, lng: 21.9489967 },
    { lat: 48.6157495, lng: 21.9495575 },
    { lat: 48.6157874, lng: 21.9500844 },
    { lat: 48.6158407, lng: 21.9502751 },
    { lat: 48.6162874, lng: 21.9509135 },
    { lat: 48.6164719, lng: 21.9510912 },
    { lat: 48.6165597, lng: 21.9510979 },
    { lat: 48.6170037, lng: 21.9515722 },
    { lat: 48.6172532, lng: 21.9524281 },
    { lat: 48.6176642, lng: 21.9530284 },
    { lat: 48.6177292, lng: 21.9532896 },
    { lat: 48.617884, lng: 21.953449 },
    { lat: 48.6179843, lng: 21.9538199 },
    { lat: 48.6182594, lng: 21.9539337 },
    { lat: 48.6186535, lng: 21.9545766 },
    { lat: 48.6187632, lng: 21.955279 },
    { lat: 48.6185171, lng: 21.9556467 },
    { lat: 48.6184298, lng: 21.9559368 },
    { lat: 48.6182974, lng: 21.956095 },
    { lat: 48.6181589, lng: 21.9561733 },
    { lat: 48.617896, lng: 21.956143 },
    { lat: 48.6175531, lng: 21.9565162 },
    { lat: 48.6171758, lng: 21.9567673 },
    { lat: 48.6170066, lng: 21.9570158 },
    { lat: 48.6168372, lng: 21.9577563 },
    { lat: 48.6166451, lng: 21.958137 },
    { lat: 48.6164173, lng: 21.9583813 },
    { lat: 48.6168543, lng: 21.9593535 },
    { lat: 48.6168886, lng: 21.9611904 },
    { lat: 48.6181104, lng: 21.9605542 },
    { lat: 48.6190153, lng: 21.96016 },
    { lat: 48.6191782, lng: 21.9633312 },
    { lat: 48.6188956, lng: 21.9700326 },
    { lat: 48.6205555, lng: 21.9702345 },
    { lat: 48.6203596, lng: 21.9767324 },
    { lat: 48.6205296, lng: 21.9776412 },
    { lat: 48.6222431, lng: 21.9770158 },
    { lat: 48.6230241, lng: 21.9775692 },
    { lat: 48.6229108, lng: 21.9778131 },
    { lat: 48.6235639, lng: 21.9782949 },
    { lat: 48.6238246, lng: 21.9785992 },
    { lat: 48.6244153, lng: 21.9785101 },
    { lat: 48.6249362, lng: 21.9782426 },
    { lat: 48.6256811, lng: 21.9787078 },
    { lat: 48.6263017, lng: 21.979276 },
    { lat: 48.6265544, lng: 21.9794482 },
    { lat: 48.6266945, lng: 21.9794709 },
    { lat: 48.6268832, lng: 21.9794087 },
    { lat: 48.6271038, lng: 21.9791513 },
    { lat: 48.6275179, lng: 21.9771914 },
    { lat: 48.6277385, lng: 21.9767402 },
    { lat: 48.6280185, lng: 21.9763741 },
    { lat: 48.6284138, lng: 21.9765752 },
    { lat: 48.6285809, lng: 21.976734 },
    { lat: 48.6289995, lng: 21.9768293 },
    { lat: 48.6295969, lng: 21.9766972 },
    { lat: 48.6298099, lng: 21.9763875 },
    { lat: 48.6298894, lng: 21.9761018 },
    { lat: 48.6298354, lng: 21.9758246 },
    { lat: 48.6294042, lng: 21.9750889 },
    { lat: 48.629351, lng: 21.974579 },
    { lat: 48.6294353, lng: 21.9742528 },
    { lat: 48.6301188, lng: 21.9739615 },
    { lat: 48.6306881, lng: 21.9739589 },
    { lat: 48.6311254, lng: 21.9734948 },
    { lat: 48.6313954, lng: 21.9727256 },
    { lat: 48.6315213, lng: 21.9718109 },
    { lat: 48.631635, lng: 21.9716352 },
    { lat: 48.6323018, lng: 21.9713053 },
    { lat: 48.6325458, lng: 21.9709836 },
    { lat: 48.632669, lng: 21.9700964 },
    { lat: 48.6328862, lng: 21.9696425 },
    { lat: 48.6334073, lng: 21.9692951 },
    { lat: 48.6340303, lng: 21.9690558 },
    { lat: 48.6346973, lng: 21.9686416 },
    { lat: 48.6353126, lng: 21.9677273 },
    { lat: 48.6356553, lng: 21.9676212 },
    { lat: 48.6360908, lng: 21.9677702 },
    { lat: 48.6366081, lng: 21.9682112 },
    { lat: 48.636841, lng: 21.9682204 },
    { lat: 48.6371156, lng: 21.9677618 },
    { lat: 48.6373023, lng: 21.9667621 },
    { lat: 48.6376824, lng: 21.966444 },
    { lat: 48.6378048, lng: 21.9664389 },
    { lat: 48.6385362, lng: 21.9668884 },
    { lat: 48.6387914, lng: 21.9668011 },
    { lat: 48.6389858, lng: 21.9666546 },
    { lat: 48.6394942, lng: 21.9651985 },
    { lat: 48.6396824, lng: 21.9649353 },
    { lat: 48.6400798, lng: 21.9646369 },
    { lat: 48.6413467, lng: 21.9640509 },
    { lat: 48.6415993, lng: 21.9639438 },
    { lat: 48.6430507, lng: 21.9633151 },
    { lat: 48.6432863, lng: 21.963337 },
    { lat: 48.6437103, lng: 21.9640582 },
    { lat: 48.6437855, lng: 21.9648629 },
    { lat: 48.6439869, lng: 21.9650941 },
    { lat: 48.6452717, lng: 21.9654114 },
    { lat: 48.6460672, lng: 21.9655109 },
    { lat: 48.646854, lng: 21.9651203 },
    { lat: 48.6472608, lng: 21.9651059 },
    { lat: 48.6475669, lng: 21.9653309 },
    { lat: 48.6481461, lng: 21.9661456 },
    { lat: 48.6501025, lng: 21.9680978 },
    { lat: 48.6501859, lng: 21.967927 },
    { lat: 48.6501376, lng: 21.9678778 },
    { lat: 48.6501305, lng: 21.9675519 },
    { lat: 48.6506034, lng: 21.9672961 },
    { lat: 48.659658, lng: 21.9623881 },
  ],
  Vrbnica: [
    { lat: 48.6970728, lng: 21.8633824 },
    { lat: 48.6970044, lng: 21.8632518 },
    { lat: 48.6967455, lng: 21.8616239 },
    { lat: 48.6969592, lng: 21.8613179 },
    { lat: 48.6969726, lng: 21.8606569 },
    { lat: 48.6973181, lng: 21.8603129 },
    { lat: 48.6973094, lng: 21.8593593 },
    { lat: 48.6973117, lng: 21.8592745 },
    { lat: 48.6972776, lng: 21.8592601 },
    { lat: 48.6970888, lng: 21.8591401 },
    { lat: 48.6969234, lng: 21.8589133 },
    { lat: 48.6968088, lng: 21.8582256 },
    { lat: 48.6966678, lng: 21.8580775 },
    { lat: 48.6960505, lng: 21.8582209 },
    { lat: 48.6955139, lng: 21.8582656 },
    { lat: 48.6952706, lng: 21.8583835 },
    { lat: 48.6951281, lng: 21.8585817 },
    { lat: 48.6950229, lng: 21.8588617 },
    { lat: 48.6950113, lng: 21.8592373 },
    { lat: 48.6950524, lng: 21.8593948 },
    { lat: 48.6948988, lng: 21.8595473 },
    { lat: 48.694414, lng: 21.8593322 },
    { lat: 48.6929677, lng: 21.8600092 },
    { lat: 48.6927621, lng: 21.860017 },
    { lat: 48.6924342, lng: 21.8598631 },
    { lat: 48.6918997, lng: 21.860128 },
    { lat: 48.6915671, lng: 21.8601824 },
    { lat: 48.6905854, lng: 21.860548 },
    { lat: 48.69048, lng: 21.860693 },
    { lat: 48.6903255, lng: 21.8611582 },
    { lat: 48.6897221, lng: 21.8618893 },
    { lat: 48.689283, lng: 21.8620553 },
    { lat: 48.6887782, lng: 21.8624196 },
    { lat: 48.6885588, lng: 21.8626817 },
    { lat: 48.6882678, lng: 21.8639179 },
    { lat: 48.6881305, lng: 21.8641335 },
    { lat: 48.6877968, lng: 21.8642392 },
    { lat: 48.6874481, lng: 21.864156 },
    { lat: 48.6862469, lng: 21.8649622 },
    { lat: 48.6859191, lng: 21.8654261 },
    { lat: 48.6853525, lng: 21.8664508 },
    { lat: 48.6851657, lng: 21.8666198 },
    { lat: 48.6846951, lng: 21.8667851 },
    { lat: 48.6844735, lng: 21.8672638 },
    { lat: 48.6836148, lng: 21.8677359 },
    { lat: 48.6831542, lng: 21.8675463 },
    { lat: 48.6830961, lng: 21.8674671 },
    { lat: 48.6823973, lng: 21.8659764 },
    { lat: 48.6822005, lng: 21.8658494 },
    { lat: 48.6815772, lng: 21.8657552 },
    { lat: 48.6813553, lng: 21.8657914 },
    { lat: 48.6810102, lng: 21.8661985 },
    { lat: 48.6802336, lng: 21.8676761 },
    { lat: 48.6800805, lng: 21.8683711 },
    { lat: 48.6799484, lng: 21.8694048 },
    { lat: 48.6796067, lng: 21.8697722 },
    { lat: 48.6789405, lng: 21.8698916 },
    { lat: 48.6778736, lng: 21.8698752 },
    { lat: 48.6777088, lng: 21.869939 },
    { lat: 48.6773473, lng: 21.8704976 },
    { lat: 48.6767756, lng: 21.8719407 },
    { lat: 48.6763917, lng: 21.8721104 },
    { lat: 48.6761404, lng: 21.8723123 },
    { lat: 48.6745101, lng: 21.8732664 },
    { lat: 48.6741273, lng: 21.8733414 },
    { lat: 48.6738494, lng: 21.8732138 },
    { lat: 48.6737885, lng: 21.8734235 },
    { lat: 48.6735193, lng: 21.8747349 },
    { lat: 48.673477, lng: 21.8751901 },
    { lat: 48.6734873, lng: 21.8759434 },
    { lat: 48.6736142, lng: 21.8764625 },
    { lat: 48.6737922, lng: 21.8769145 },
    { lat: 48.674189, lng: 21.8776531 },
    { lat: 48.6742404, lng: 21.8779053 },
    { lat: 48.6738352, lng: 21.8795688 },
    { lat: 48.6739886, lng: 21.8805975 },
    { lat: 48.673999, lng: 21.8820609 },
    { lat: 48.6738698, lng: 21.882067 },
    { lat: 48.6739302, lng: 21.883115 },
    { lat: 48.674034, lng: 21.8839682 },
    { lat: 48.6742188, lng: 21.8848566 },
    { lat: 48.674134, lng: 21.8850613 },
    { lat: 48.6744353, lng: 21.8874878 },
    { lat: 48.6777443, lng: 21.887804 },
    { lat: 48.6777504, lng: 21.887914 },
    { lat: 48.6776737, lng: 21.8879017 },
    { lat: 48.6780008, lng: 21.8916988 },
    { lat: 48.6785476, lng: 21.8952455 },
    { lat: 48.67902, lng: 21.8945318 },
    { lat: 48.6800254, lng: 21.8940289 },
    { lat: 48.6809094, lng: 21.8937929 },
    { lat: 48.6814745, lng: 21.8935448 },
    { lat: 48.6814183, lng: 21.8931533 },
    { lat: 48.6828916, lng: 21.8924267 },
    { lat: 48.6828802, lng: 21.8929033 },
    { lat: 48.6826911, lng: 21.8936099 },
    { lat: 48.6826605, lng: 21.8943152 },
    { lat: 48.6826711, lng: 21.8949455 },
    { lat: 48.6828743, lng: 21.895949 },
    { lat: 48.6843162, lng: 21.8948765 },
    { lat: 48.6852942, lng: 21.8981676 },
    { lat: 48.6847557, lng: 21.898584 },
    { lat: 48.6855869, lng: 21.9015508 },
    { lat: 48.6857441, lng: 21.9018913 },
    { lat: 48.6858569, lng: 21.9018606 },
    { lat: 48.6868329, lng: 21.9018419 },
    { lat: 48.6867716, lng: 21.9011081 },
    { lat: 48.6870772, lng: 21.9009616 },
    { lat: 48.6873658, lng: 21.9006316 },
    { lat: 48.6874924, lng: 21.9004712 },
    { lat: 48.6876996, lng: 21.8997738 },
    { lat: 48.6881203, lng: 21.8994187 },
    { lat: 48.6879934, lng: 21.8990686 },
    { lat: 48.6868251, lng: 21.8937318 },
    { lat: 48.6856786, lng: 21.8882377 },
    { lat: 48.6865158, lng: 21.8881146 },
    { lat: 48.687055, lng: 21.8879491 },
    { lat: 48.6882449, lng: 21.8872299 },
    { lat: 48.6888047, lng: 21.8870336 },
    { lat: 48.6891108, lng: 21.8870841 },
    { lat: 48.6907512, lng: 21.8877548 },
    { lat: 48.6917051, lng: 21.8877036 },
    { lat: 48.6919587, lng: 21.8876352 },
    { lat: 48.6923388, lng: 21.8876747 },
    { lat: 48.6928956, lng: 21.8878507 },
    { lat: 48.6933011, lng: 21.8881779 },
    { lat: 48.6942732, lng: 21.8886737 },
    { lat: 48.6947283, lng: 21.8887808 },
    { lat: 48.6948699, lng: 21.888685 },
    { lat: 48.6951047, lng: 21.8887216 },
    { lat: 48.6959014, lng: 21.8890792 },
    { lat: 48.6959962, lng: 21.8892293 },
    { lat: 48.6964889, lng: 21.8894044 },
    { lat: 48.697377, lng: 21.8894176 },
    { lat: 48.6975831, lng: 21.8892515 },
    { lat: 48.6975413, lng: 21.8890281 },
    { lat: 48.6978532, lng: 21.8881112 },
    { lat: 48.6979185, lng: 21.8869133 },
    { lat: 48.6981141, lng: 21.8861516 },
    { lat: 48.6980583, lng: 21.8855548 },
    { lat: 48.697934, lng: 21.8842554 },
    { lat: 48.6975981, lng: 21.8783622 },
    { lat: 48.6972995, lng: 21.8749961 },
    { lat: 48.6970002, lng: 21.8730639 },
    { lat: 48.6966213, lng: 21.8699917 },
    { lat: 48.6963897, lng: 21.8686367 },
    { lat: 48.6967429, lng: 21.8674049 },
    { lat: 48.696782, lng: 21.8663844 },
    { lat: 48.6970728, lng: 21.8633824 },
  ],
  VeľkéKapušany: [
    { lat: 48.5508849, lng: 22.1082837 },
    { lat: 48.5509824, lng: 22.1086889 },
    { lat: 48.5509086, lng: 22.1087571 },
    { lat: 48.5512523, lng: 22.1101696 },
    { lat: 48.5517096, lng: 22.1114773 },
    { lat: 48.551632, lng: 22.1116238 },
    { lat: 48.5517539, lng: 22.1120253 },
    { lat: 48.5521987, lng: 22.1118048 },
    { lat: 48.5524293, lng: 22.111758 },
    { lat: 48.5525779, lng: 22.1116609 },
    { lat: 48.5534003, lng: 22.1108098 },
    { lat: 48.5537223, lng: 22.1107713 },
    { lat: 48.5538436, lng: 22.1108181 },
    { lat: 48.5544089, lng: 22.1105581 },
    { lat: 48.5548557, lng: 22.1100857 },
    { lat: 48.5550339, lng: 22.109824 },
    { lat: 48.5566266, lng: 22.1086717 },
    { lat: 48.5570671, lng: 22.1082688 },
    { lat: 48.5577586, lng: 22.1100638 },
    { lat: 48.5576754, lng: 22.1103558 },
    { lat: 48.5570366, lng: 22.1116517 },
    { lat: 48.5570483, lng: 22.1117214 },
    { lat: 48.5568621, lng: 22.1121188 },
    { lat: 48.5568421, lng: 22.1120321 },
    { lat: 48.5564445, lng: 22.1128246 },
    { lat: 48.5557624, lng: 22.1144299 },
    { lat: 48.5560971, lng: 22.1147479 },
    { lat: 48.5563901, lng: 22.1151999 },
    { lat: 48.5567075, lng: 22.1158228 },
    { lat: 48.5583171, lng: 22.1195961 },
    { lat: 48.5585242, lng: 22.119894 },
    { lat: 48.5588128, lng: 22.1201549 },
    { lat: 48.5593131, lng: 22.1202915 },
    { lat: 48.5594577, lng: 22.1193541 },
    { lat: 48.5600708, lng: 22.1171083 },
    { lat: 48.5602295, lng: 22.1155395 },
    { lat: 48.5603281, lng: 22.1155715 },
    { lat: 48.5606222, lng: 22.1140557 },
    { lat: 48.5605761, lng: 22.111768 },
    { lat: 48.5605063, lng: 22.1109099 },
    { lat: 48.5604071, lng: 22.1103296 },
    { lat: 48.5615592, lng: 22.1099064 },
    { lat: 48.5622907, lng: 22.1098676 },
    { lat: 48.5635679, lng: 22.1096017 },
    { lat: 48.5676189, lng: 22.1095659 },
    { lat: 48.5682947, lng: 22.109508 },
    { lat: 48.5692082, lng: 22.109182 },
    { lat: 48.5697716, lng: 22.1090737 },
    { lat: 48.5707775, lng: 22.1089964 },
    { lat: 48.5717475, lng: 22.1090055 },
    { lat: 48.5738896, lng: 22.1087294 },
    { lat: 48.5746087, lng: 22.1086955 },
    { lat: 48.5759692, lng: 22.1090038 },
    { lat: 48.5776413, lng: 22.1096967 },
    { lat: 48.5789071, lng: 22.1099462 },
    { lat: 48.579384, lng: 22.1104695 },
    { lat: 48.5798381, lng: 22.110831 },
    { lat: 48.5799463, lng: 22.110742 },
    { lat: 48.5800036, lng: 22.1106946 },
    { lat: 48.5803236, lng: 22.1101488 },
    { lat: 48.5797484, lng: 22.1093163 },
    { lat: 48.5799206, lng: 22.1083721 },
    { lat: 48.5799113, lng: 22.1073995 },
    { lat: 48.5798415, lng: 22.1068302 },
    { lat: 48.5795498, lng: 22.1060086 },
    { lat: 48.5798785, lng: 22.1048221 },
    { lat: 48.5807659, lng: 22.1033946 },
    { lat: 48.581024, lng: 22.102644 },
    { lat: 48.5809732, lng: 22.1018843 },
    { lat: 48.5805997, lng: 22.1006403 },
    { lat: 48.5805143, lng: 22.0995028 },
    { lat: 48.5812025, lng: 22.0984546 },
    { lat: 48.5818591, lng: 22.097912 },
    { lat: 48.5822778, lng: 22.0972571 },
    { lat: 48.5825925, lng: 22.0965807 },
    { lat: 48.5828906, lng: 22.0961092 },
    { lat: 48.5836513, lng: 22.0946485 },
    { lat: 48.583701, lng: 22.0942792 },
    { lat: 48.5836385, lng: 22.0925896 },
    { lat: 48.5831494, lng: 22.0914169 },
    { lat: 48.5827782, lng: 22.0908067 },
    { lat: 48.5824304, lng: 22.0905128 },
    { lat: 48.5818489, lng: 22.0902255 },
    { lat: 48.5805906, lng: 22.0902969 },
    { lat: 48.5797517, lng: 22.0895947 },
    { lat: 48.5788071, lng: 22.089132 },
    { lat: 48.5778799, lng: 22.0885028 },
    { lat: 48.5773337, lng: 22.0885743 },
    { lat: 48.5767463, lng: 22.0883751 },
    { lat: 48.5761846, lng: 22.0880789 },
    { lat: 48.5755859, lng: 22.0874058 },
    { lat: 48.5751441, lng: 22.0872772 },
    { lat: 48.5746827, lng: 22.0867949 },
    { lat: 48.574531, lng: 22.0862838 },
    { lat: 48.5744071, lng: 22.0853765 },
    { lat: 48.5742963, lng: 22.084883 },
    { lat: 48.5738831, lng: 22.0838742 },
    { lat: 48.5737815, lng: 22.083149 },
    { lat: 48.5738156, lng: 22.082414 },
    { lat: 48.5737436, lng: 22.0816968 },
    { lat: 48.5739045, lng: 22.0810902 },
    { lat: 48.5741783, lng: 22.0807301 },
    { lat: 48.5745566, lng: 22.0803221 },
    { lat: 48.5750664, lng: 22.0800927 },
    { lat: 48.5755392, lng: 22.0797747 },
    { lat: 48.5757365, lng: 22.0794664 },
    { lat: 48.5759042, lng: 22.0791365 },
    { lat: 48.5760617, lng: 22.078625 },
    { lat: 48.5760841, lng: 22.0783381 },
    { lat: 48.576053, lng: 22.0780982 },
    { lat: 48.5760373, lng: 22.0779769 },
    { lat: 48.575957, lng: 22.0777212 },
    { lat: 48.5756044, lng: 22.0770903 },
    { lat: 48.5748699, lng: 22.0759564 },
    { lat: 48.5745481, lng: 22.0755789 },
    { lat: 48.5737212, lng: 22.0750092 },
    { lat: 48.5735543, lng: 22.0744254 },
    { lat: 48.5735676, lng: 22.0734844 },
    { lat: 48.5737668, lng: 22.0722143 },
    { lat: 48.5737093, lng: 22.0720087 },
    { lat: 48.5730817, lng: 22.0697664 },
    { lat: 48.5728894, lng: 22.0694917 },
    { lat: 48.5715653, lng: 22.0685957 },
    { lat: 48.5698313, lng: 22.0681895 },
    { lat: 48.569028, lng: 22.0674841 },
    { lat: 48.568873, lng: 22.0670756 },
    { lat: 48.5686922, lng: 22.0646474 },
    { lat: 48.5684907, lng: 22.0606128 },
    { lat: 48.5685148, lng: 22.0604876 },
    { lat: 48.56844, lng: 22.0592457 },
    { lat: 48.5682896, lng: 22.0557553 },
    { lat: 48.5681207, lng: 22.0551355 },
    { lat: 48.5678706, lng: 22.0545191 },
    { lat: 48.5674811, lng: 22.0540349 },
    { lat: 48.565573, lng: 22.0521095 },
    { lat: 48.5642966, lng: 22.0513329 },
    { lat: 48.5640277, lng: 22.0510987 },
    { lat: 48.5622676, lng: 22.0487374 },
    { lat: 48.5629925, lng: 22.0473609 },
    { lat: 48.5648966, lng: 22.0426821 },
    { lat: 48.5600599, lng: 22.0386082 },
    { lat: 48.5596236, lng: 22.0395061 },
    { lat: 48.5594668, lng: 22.0405059 },
    { lat: 48.5600996, lng: 22.0429972 },
    { lat: 48.5604695, lng: 22.0439624 },
    { lat: 48.5594259, lng: 22.0448215 },
    { lat: 48.5578629, lng: 22.046426 },
    { lat: 48.5479368, lng: 22.0526407 },
    { lat: 48.5471073, lng: 22.0529093 },
    { lat: 48.5462311, lng: 22.0529068 },
    { lat: 48.5454952, lng: 22.0523921 },
    { lat: 48.5408647, lng: 22.0514 },
    { lat: 48.540355, lng: 22.051114 },
    { lat: 48.5403014, lng: 22.0509956 },
    { lat: 48.5396589, lng: 22.0514073 },
    { lat: 48.5394273, lng: 22.0507601 },
    { lat: 48.5390228, lng: 22.0511013 },
    { lat: 48.5388573, lng: 22.0506791 },
    { lat: 48.5387543, lng: 22.0507479 },
    { lat: 48.5381039, lng: 22.0486394 },
    { lat: 48.5356045, lng: 22.0502795 },
    { lat: 48.5354115, lng: 22.0498815 },
    { lat: 48.5352481, lng: 22.0486602 },
    { lat: 48.5352478, lng: 22.0481771 },
    { lat: 48.5350087, lng: 22.0473184 },
    { lat: 48.5350258, lng: 22.0468127 },
    { lat: 48.5354079, lng: 22.0454976 },
    { lat: 48.5349626, lng: 22.0454081 },
    { lat: 48.5347413, lng: 22.0452821 },
    { lat: 48.5346036, lng: 22.0448446 },
    { lat: 48.5342906, lng: 22.0442711 },
    { lat: 48.5340496, lng: 22.0436145 },
    { lat: 48.5338735, lng: 22.0428443 },
    { lat: 48.5335393, lng: 22.0423303 },
    { lat: 48.5331303, lng: 22.0413652 },
    { lat: 48.5331071, lng: 22.0412029 },
    { lat: 48.5318068, lng: 22.0413016 },
    { lat: 48.531492, lng: 22.0403733 },
    { lat: 48.5314529, lng: 22.0391761 },
    { lat: 48.5313559, lng: 22.0387538 },
    { lat: 48.5312269, lng: 22.0388563 },
    { lat: 48.530886, lng: 22.0395576 },
    { lat: 48.5306748, lng: 22.0397995 },
    { lat: 48.5304417, lng: 22.0402106 },
    { lat: 48.5301232, lng: 22.0411746 },
    { lat: 48.5299485, lng: 22.0415821 },
    { lat: 48.5298164, lng: 22.0417061 },
    { lat: 48.5297295, lng: 22.0417857 },
    { lat: 48.5293432, lng: 22.0419081 },
    { lat: 48.5280796, lng: 22.0420775 },
    { lat: 48.5279115, lng: 22.0421566 },
    { lat: 48.5272674, lng: 22.0427282 },
    { lat: 48.5272709, lng: 22.043156 },
    { lat: 48.5269983, lng: 22.0431802 },
    { lat: 48.5267161, lng: 22.0430534 },
    { lat: 48.5265733, lng: 22.0428535 },
    { lat: 48.526538, lng: 22.0428041 },
    { lat: 48.5262528, lng: 22.0421699 },
    { lat: 48.5258321, lng: 22.0417082 },
    { lat: 48.5257326, lng: 22.0415028 },
    { lat: 48.5257052, lng: 22.0412055 },
    { lat: 48.5257487, lng: 22.0408231 },
    { lat: 48.5260693, lng: 22.0400336 },
    { lat: 48.5262112, lng: 22.0398512 },
    { lat: 48.5263801, lng: 22.0397656 },
    { lat: 48.5267131, lng: 22.0401416 },
    { lat: 48.5268372, lng: 22.0401965 },
    { lat: 48.5272245, lng: 22.0399308 },
    { lat: 48.5272663, lng: 22.0391573 },
    { lat: 48.5272249, lng: 22.0388394 },
    { lat: 48.526766, lng: 22.0375373 },
    { lat: 48.5259529, lng: 22.036342 },
    { lat: 48.5255806, lng: 22.0361115 },
    { lat: 48.525262, lng: 22.035311 },
    { lat: 48.5245817, lng: 22.0343973 },
    { lat: 48.5241577, lng: 22.033663 },
    { lat: 48.5238665, lng: 22.0339441 },
    { lat: 48.5233022, lng: 22.0343321 },
    { lat: 48.521705, lng: 22.0332773 },
    { lat: 48.5210131, lng: 22.033364 },
    { lat: 48.5205313, lng: 22.0335438 },
    { lat: 48.5199135, lng: 22.0334877 },
    { lat: 48.5196508, lng: 22.0336941 },
    { lat: 48.5190826, lng: 22.0338758 },
    { lat: 48.5189454, lng: 22.0338777 },
    { lat: 48.5183711, lng: 22.0335743 },
    { lat: 48.5175648, lng: 22.0335416 },
    { lat: 48.517548, lng: 22.0342845 },
    { lat: 48.5174559, lng: 22.0346682 },
    { lat: 48.5172654, lng: 22.0350651 },
    { lat: 48.5169781, lng: 22.0353193 },
    { lat: 48.5163578, lng: 22.0355862 },
    { lat: 48.5160817, lng: 22.0358373 },
    { lat: 48.5159846, lng: 22.0361006 },
    { lat: 48.5159694, lng: 22.0364574 },
    { lat: 48.5158742, lng: 22.0365655 },
    { lat: 48.5156725, lng: 22.0365021 },
    { lat: 48.5154607, lng: 22.0360297 },
    { lat: 48.515193, lng: 22.03557 },
    { lat: 48.514999, lng: 22.035627 },
    { lat: 48.514714, lng: 22.035671 },
    { lat: 48.514488, lng: 22.035725 },
    { lat: 48.514324, lng: 22.035767 },
    { lat: 48.514117, lng: 22.035877 },
    { lat: 48.513771, lng: 22.036069 },
    { lat: 48.513638, lng: 22.036163 },
    { lat: 48.513498, lng: 22.036344 },
    { lat: 48.513365, lng: 22.036528 },
    { lat: 48.513212, lng: 22.036677 },
    { lat: 48.512912, lng: 22.036875 },
    { lat: 48.512785, lng: 22.036969 },
    { lat: 48.512537, lng: 22.037052 },
    { lat: 48.512316, lng: 22.037101 },
    { lat: 48.512137, lng: 22.037132 },
    { lat: 48.511977, lng: 22.037172 },
    { lat: 48.511783, lng: 22.037212 },
    { lat: 48.511552, lng: 22.037357 },
    { lat: 48.511262, lng: 22.037543 },
    { lat: 48.510815, lng: 22.037852 },
    { lat: 48.51044, lng: 22.038115 },
    { lat: 48.510017, lng: 22.038428 },
    { lat: 48.509715, lng: 22.038598 },
    { lat: 48.50941, lng: 22.038779 },
    { lat: 48.509042, lng: 22.039041 },
    { lat: 48.508778, lng: 22.03924 },
    { lat: 48.508606, lng: 22.039355 },
    { lat: 48.508482, lng: 22.03945 },
    { lat: 48.508348, lng: 22.039578 },
    { lat: 48.508169, lng: 22.039897 },
    { lat: 48.50807, lng: 22.040125 },
    { lat: 48.507931, lng: 22.040459 },
    { lat: 48.507807, lng: 22.040772 },
    { lat: 48.507676, lng: 22.041122 },
    { lat: 48.507609, lng: 22.041287 },
    { lat: 48.507659, lng: 22.041695 },
    { lat: 48.507677, lng: 22.041871 },
    { lat: 48.507706, lng: 22.042024 },
    { lat: 48.507735, lng: 22.042414 },
    { lat: 48.507779, lng: 22.042842 },
    { lat: 48.507806, lng: 22.043057 },
    { lat: 48.507822, lng: 22.043198 },
    { lat: 48.507884, lng: 22.043475 },
    { lat: 48.507995, lng: 22.043978 },
    { lat: 48.508075, lng: 22.044342 },
    { lat: 48.508099, lng: 22.044516 },
    { lat: 48.508077, lng: 22.044694 },
    { lat: 48.507995, lng: 22.045012 },
    { lat: 48.507929, lng: 22.045211 },
    { lat: 48.507837, lng: 22.045447 },
    { lat: 48.507727, lng: 22.045654 },
    { lat: 48.507533, lng: 22.046001 },
    { lat: 48.507438, lng: 22.046129 },
    { lat: 48.507234, lng: 22.046437 },
    { lat: 48.507183, lng: 22.046596 },
    { lat: 48.507077, lng: 22.046935 },
    { lat: 48.50699, lng: 22.047249 },
    { lat: 48.506938, lng: 22.047553 },
    { lat: 48.506924, lng: 22.047693 },
    { lat: 48.50684, lng: 22.047996 },
    { lat: 48.506768, lng: 22.048193 },
    { lat: 48.506702, lng: 22.048356 },
    { lat: 48.506657, lng: 22.04845 },
    { lat: 48.506527, lng: 22.048579 },
    { lat: 48.506371, lng: 22.04866 },
    { lat: 48.506278, lng: 22.048712 },
    { lat: 48.506082, lng: 22.048718 },
    { lat: 48.50571, lng: 22.048641 },
    { lat: 48.505503, lng: 22.048541 },
    { lat: 48.5053, lng: 22.048427 },
    { lat: 48.5051, lng: 22.048301 },
    { lat: 48.504989, lng: 22.048559 },
    { lat: 48.504378, lng: 22.049261 },
    { lat: 48.504165, lng: 22.049315 },
    { lat: 48.504154, lng: 22.049508 },
    { lat: 48.504104, lng: 22.04991 },
    { lat: 48.50408, lng: 22.050427 },
    { lat: 48.504104, lng: 22.0508 },
    { lat: 48.504175, lng: 22.05118 },
    { lat: 48.504217, lng: 22.051413 },
    { lat: 48.504287, lng: 22.051707 },
    { lat: 48.504364, lng: 22.05193 },
    { lat: 48.504397, lng: 22.052066 },
    { lat: 48.504397, lng: 22.052182 },
    { lat: 48.504373, lng: 22.052386 },
    { lat: 48.504305, lng: 22.052552 },
    { lat: 48.50424, lng: 22.052637 },
    { lat: 48.504159, lng: 22.052688 },
    { lat: 48.50402, lng: 22.05273 },
    { lat: 48.503835, lng: 22.052672 },
    { lat: 48.503732, lng: 22.052621 },
    { lat: 48.503628, lng: 22.052498 },
    { lat: 48.503571, lng: 22.052398 },
    { lat: 48.503466, lng: 22.052261 },
    { lat: 48.503307, lng: 22.052063 },
    { lat: 48.503144, lng: 22.051882 },
    { lat: 48.503085, lng: 22.051804 },
    { lat: 48.502909, lng: 22.051658 },
    { lat: 48.502794, lng: 22.051606 },
    { lat: 48.50262, lng: 22.051611 },
    { lat: 48.502522, lng: 22.05164 },
    { lat: 48.502419, lng: 22.051771 },
    { lat: 48.502298, lng: 22.051922 },
    { lat: 48.502236, lng: 22.051998 },
    { lat: 48.502172, lng: 22.052131 },
    { lat: 48.502131, lng: 22.052239 },
    { lat: 48.502052, lng: 22.052595 },
    { lat: 48.501974, lng: 22.052974 },
    { lat: 48.501938, lng: 22.053215 },
    { lat: 48.501855, lng: 22.053646 },
    { lat: 48.501818, lng: 22.053883 },
    { lat: 48.50179, lng: 22.053986 },
    { lat: 48.501693, lng: 22.054176 },
    { lat: 48.501584, lng: 22.054398 },
    { lat: 48.501505, lng: 22.054543 },
    { lat: 48.50141, lng: 22.054658 },
    { lat: 48.501292, lng: 22.054785 },
    { lat: 48.501156, lng: 22.05488 },
    { lat: 48.501031, lng: 22.054972 },
    { lat: 48.500912, lng: 22.054992 },
    { lat: 48.50071, lng: 22.055011 },
    { lat: 48.500602, lng: 22.054984 },
    { lat: 48.500431, lng: 22.054947 },
    { lat: 48.500333, lng: 22.055196 },
    { lat: 48.500255, lng: 22.055364 },
    { lat: 48.50006, lng: 22.055827 },
    { lat: 48.4998293, lng: 22.0568694 },
    { lat: 48.5003055, lng: 22.0588415 },
    { lat: 48.5009993, lng: 22.0597607 },
    { lat: 48.5021307, lng: 22.0603642 },
    { lat: 48.5026603, lng: 22.060451 },
    { lat: 48.5041818, lng: 22.0603763 },
    { lat: 48.504432, lng: 22.0605354 },
    { lat: 48.5038715, lng: 22.0612236 },
    { lat: 48.5025977, lng: 22.0625718 },
    { lat: 48.5029546, lng: 22.063044 },
    { lat: 48.5032284, lng: 22.0638616 },
    { lat: 48.504087, lng: 22.0635421 },
    { lat: 48.504511, lng: 22.0654148 },
    { lat: 48.505191, lng: 22.0671693 },
    { lat: 48.5057058, lng: 22.0681903 },
    { lat: 48.5064776, lng: 22.0694577 },
    { lat: 48.5079124, lng: 22.0703196 },
    { lat: 48.5092855, lng: 22.0701613 },
    { lat: 48.5104239, lng: 22.069832 },
    { lat: 48.5114876, lng: 22.0699542 },
    { lat: 48.5124575, lng: 22.0705274 },
    { lat: 48.5138672, lng: 22.07176 },
    { lat: 48.5139653, lng: 22.0749636 },
    { lat: 48.5151574, lng: 22.0753669 },
    { lat: 48.5160818, lng: 22.0759247 },
    { lat: 48.5172995, lng: 22.0773631 },
    { lat: 48.51763, lng: 22.0790903 },
    { lat: 48.5179891, lng: 22.0797617 },
    { lat: 48.5182608, lng: 22.080017 },
    { lat: 48.5185737, lng: 22.0796956 },
    { lat: 48.5186751, lng: 22.0796554 },
    { lat: 48.5188659, lng: 22.0801163 },
    { lat: 48.5194063, lng: 22.0842805 },
    { lat: 48.5229843, lng: 22.0853938 },
    { lat: 48.5278809, lng: 22.0891198 },
    { lat: 48.5291335, lng: 22.0895768 },
    { lat: 48.5286673, lng: 22.093574 },
    { lat: 48.5292071, lng: 22.0938033 },
    { lat: 48.5291533, lng: 22.0943741 },
    { lat: 48.5294341, lng: 22.0945566 },
    { lat: 48.5308293, lng: 22.0951779 },
    { lat: 48.5308837, lng: 22.0956257 },
    { lat: 48.5320499, lng: 22.0978817 },
    { lat: 48.5326494, lng: 22.0994963 },
    { lat: 48.5331469, lng: 22.0992218 },
    { lat: 48.534816, lng: 22.0987452 },
    { lat: 48.5352297, lng: 22.0985832 },
    { lat: 48.5352525, lng: 22.0985038 },
    { lat: 48.5359446, lng: 22.0985131 },
    { lat: 48.5360942, lng: 22.0986091 },
    { lat: 48.53625, lng: 22.1000124 },
    { lat: 48.5389082, lng: 22.0997276 },
    { lat: 48.5389349, lng: 22.1001404 },
    { lat: 48.5419976, lng: 22.1002186 },
    { lat: 48.5452686, lng: 22.0992065 },
    { lat: 48.5462796, lng: 22.0991972 },
    { lat: 48.5477106, lng: 22.0985366 },
    { lat: 48.5489317, lng: 22.0977524 },
    { lat: 48.5495624, lng: 22.0971724 },
    { lat: 48.5498294, lng: 22.0978064 },
    { lat: 48.5503619, lng: 22.0972995 },
    { lat: 48.5508626, lng: 22.0987304 },
    { lat: 48.5524084, lng: 22.0976453 },
    { lat: 48.5531661, lng: 22.1016179 },
    { lat: 48.5531639, lng: 22.102184 },
    { lat: 48.5530519, lng: 22.1025884 },
    { lat: 48.5523549, lng: 22.1039854 },
    { lat: 48.5522325, lng: 22.1040924 },
    { lat: 48.5522079, lng: 22.1042879 },
    { lat: 48.5522941, lng: 22.1045038 },
    { lat: 48.5525268, lng: 22.1066409 },
    { lat: 48.5520125, lng: 22.1068247 },
    { lat: 48.5518134, lng: 22.1070644 },
    { lat: 48.5516789, lng: 22.107509 },
    { lat: 48.5508849, lng: 22.1082837 },
  ],
  PavlovcenadUhom: [
    { lat: 48.6239135, lng: 22.000321 },
    { lat: 48.6212491, lng: 21.9986427 },
    { lat: 48.6119015, lng: 21.9962453 },
    { lat: 48.6114775, lng: 21.9960183 },
    { lat: 48.6111239, lng: 21.9956822 },
    { lat: 48.6105638, lng: 21.9953637 },
    { lat: 48.6092768, lng: 21.9951352 },
    { lat: 48.6086789, lng: 21.9954804 },
    { lat: 48.6084965, lng: 21.9955183 },
    { lat: 48.6083801, lng: 21.9962812 },
    { lat: 48.6082763, lng: 21.9962675 },
    { lat: 48.6083586, lng: 21.996645 },
    { lat: 48.6106601, lng: 22.0022772 },
    { lat: 48.6105282, lng: 22.0023997 },
    { lat: 48.608137, lng: 22.0020062 },
    { lat: 48.6060825, lng: 22.0023101 },
    { lat: 48.6053168, lng: 22.0041165 },
    { lat: 48.605267, lng: 22.0041548 },
    { lat: 48.6052391, lng: 22.0042087 },
    { lat: 48.6077361, lng: 22.0095246 },
    { lat: 48.6084569, lng: 22.010573 },
    { lat: 48.6084675, lng: 22.0106876 },
    { lat: 48.6108494, lng: 22.0141602 },
    { lat: 48.6115834, lng: 22.0156158 },
    { lat: 48.6119531, lng: 22.0161513 },
    { lat: 48.6138743, lng: 22.0199244 },
    { lat: 48.6129143, lng: 22.0212456 },
    { lat: 48.6122994, lng: 22.0218221 },
    { lat: 48.6114098, lng: 22.0224334 },
    { lat: 48.6105514, lng: 22.0203004 },
    { lat: 48.6096952, lng: 22.0205771 },
    { lat: 48.6089723, lng: 22.0212904 },
    { lat: 48.6083706, lng: 22.0217118 },
    { lat: 48.6081051, lng: 22.0225896 },
    { lat: 48.6076971, lng: 22.0228806 },
    { lat: 48.6067664, lng: 22.0230916 },
    { lat: 48.6062736, lng: 22.0239709 },
    { lat: 48.6060263, lng: 22.0288394 },
    { lat: 48.6051561, lng: 22.0416838 },
    { lat: 48.5933975, lng: 22.0522259 },
    { lat: 48.5925614, lng: 22.0531096 },
    { lat: 48.5925356, lng: 22.0530641 },
    { lat: 48.5916614, lng: 22.0541282 },
    { lat: 48.5918449, lng: 22.0544181 },
    { lat: 48.5856818, lng: 22.0585515 },
    { lat: 48.5872931, lng: 22.0618959 },
    { lat: 48.5894746, lng: 22.0648196 },
    { lat: 48.5909766, lng: 22.0680663 },
    { lat: 48.5913522, lng: 22.0687683 },
    { lat: 48.5916167, lng: 22.0694301 },
    { lat: 48.5923464, lng: 22.0720939 },
    { lat: 48.5935843, lng: 22.0756897 },
    { lat: 48.5894903, lng: 22.0782208 },
    { lat: 48.59098, lng: 22.0814408 },
    { lat: 48.5950625, lng: 22.0899166 },
    { lat: 48.5937171, lng: 22.0914317 },
    { lat: 48.5928335, lng: 22.0925951 },
    { lat: 48.5916587, lng: 22.0920107 },
    { lat: 48.5885141, lng: 22.0867805 },
    { lat: 48.5853651, lng: 22.0883669 },
    { lat: 48.5844219, lng: 22.0886843 },
    { lat: 48.5844413, lng: 22.0897374 },
    { lat: 48.5829175, lng: 22.0898012 },
    { lat: 48.5825367, lng: 22.090058 },
    { lat: 48.5824304, lng: 22.0905128 },
    { lat: 48.5827782, lng: 22.0908067 },
    { lat: 48.5831494, lng: 22.0914169 },
    { lat: 48.5836385, lng: 22.0925896 },
    { lat: 48.583701, lng: 22.0942792 },
    { lat: 48.5836513, lng: 22.0946485 },
    { lat: 48.5828906, lng: 22.0961092 },
    { lat: 48.5825925, lng: 22.0965807 },
    { lat: 48.5822778, lng: 22.0972571 },
    { lat: 48.5818591, lng: 22.097912 },
    { lat: 48.5812025, lng: 22.0984546 },
    { lat: 48.5805143, lng: 22.0995028 },
    { lat: 48.5805997, lng: 22.1006403 },
    { lat: 48.5809732, lng: 22.1018843 },
    { lat: 48.581024, lng: 22.102644 },
    { lat: 48.5807659, lng: 22.1033946 },
    { lat: 48.5798785, lng: 22.1048221 },
    { lat: 48.5795498, lng: 22.1060086 },
    { lat: 48.5798415, lng: 22.1068302 },
    { lat: 48.5799113, lng: 22.1073995 },
    { lat: 48.5799206, lng: 22.1083721 },
    { lat: 48.5797484, lng: 22.1093163 },
    { lat: 48.5803236, lng: 22.1101488 },
    { lat: 48.5809929, lng: 22.1090052 },
    { lat: 48.5810087, lng: 22.1089522 },
    { lat: 48.5812516, lng: 22.1090479 },
    { lat: 48.5848657, lng: 22.1080889 },
    { lat: 48.5860647, lng: 22.1074586 },
    { lat: 48.5867788, lng: 22.1068813 },
    { lat: 48.5878738, lng: 22.1050392 },
    { lat: 48.5885314, lng: 22.1042567 },
    { lat: 48.5899916, lng: 22.102649 },
    { lat: 48.5913721, lng: 22.1012266 },
    { lat: 48.5921344, lng: 22.1008371 },
    { lat: 48.5926526, lng: 22.1008195 },
    { lat: 48.5976958, lng: 22.0969859 },
    { lat: 48.5988453, lng: 22.0964528 },
    { lat: 48.6012042, lng: 22.0946933 },
    { lat: 48.6023066, lng: 22.093986 },
    { lat: 48.602909, lng: 22.0961468 },
    { lat: 48.6032582, lng: 22.0972096 },
    { lat: 48.6038122, lng: 22.0981344 },
    { lat: 48.6040108, lng: 22.0990176 },
    { lat: 48.604722, lng: 22.0986516 },
    { lat: 48.6047884, lng: 22.098647 },
    { lat: 48.6050376, lng: 22.0986293 },
    { lat: 48.610906, lng: 22.0891787 },
    { lat: 48.6119084, lng: 22.088431 },
    { lat: 48.6130756, lng: 22.0884547 },
    { lat: 48.6143917, lng: 22.0883075 },
    { lat: 48.6152888, lng: 22.088424 },
    { lat: 48.6164782, lng: 22.0882552 },
    { lat: 48.6180032, lng: 22.0878119 },
    { lat: 48.6188853, lng: 22.0877244 },
    { lat: 48.6194512, lng: 22.0875427 },
    { lat: 48.6219319, lng: 22.0862715 },
    { lat: 48.6214504, lng: 22.0850446 },
    { lat: 48.6203345, lng: 22.0824917 },
    { lat: 48.6198773, lng: 22.0812376 },
    { lat: 48.6218851, lng: 22.0799071 },
    { lat: 48.6221957, lng: 22.0804403 },
    { lat: 48.6255391, lng: 22.0780822 },
    { lat: 48.6257245, lng: 22.0783129 },
    { lat: 48.6326976, lng: 22.073077 },
    { lat: 48.6349257, lng: 22.0721613 },
    { lat: 48.6355082, lng: 22.0699413 },
    { lat: 48.6362777, lng: 22.0676399 },
    { lat: 48.6365164, lng: 22.0665703 },
    { lat: 48.6384408, lng: 22.070837 },
    { lat: 48.6430392, lng: 22.0695118 },
    { lat: 48.6472936, lng: 22.0680487 },
    { lat: 48.6479126, lng: 22.0652003 },
    { lat: 48.6494865, lng: 22.0575971 },
    { lat: 48.6499379, lng: 22.0556958 },
    { lat: 48.6503427, lng: 22.0533406 },
    { lat: 48.6493108, lng: 22.0502376 },
    { lat: 48.6492509, lng: 22.0497198 },
    { lat: 48.6502758, lng: 22.03276 },
    { lat: 48.6506454, lng: 22.023652 },
    { lat: 48.6503619, lng: 22.0224926 },
    { lat: 48.6491059, lng: 22.0181163 },
    { lat: 48.6490957, lng: 22.0180763 },
    { lat: 48.6490822, lng: 22.018024 },
    { lat: 48.6486685, lng: 22.0183163 },
    { lat: 48.6482197, lng: 22.0185002 },
    { lat: 48.647339, lng: 22.0184608 },
    { lat: 48.6470444, lng: 22.0181456 },
    { lat: 48.6469157, lng: 22.0177087 },
    { lat: 48.6468089, lng: 22.0171572 },
    { lat: 48.6468986, lng: 22.0168232 },
    { lat: 48.6482836, lng: 22.0150772 },
    { lat: 48.648259, lng: 22.0149868 },
    { lat: 48.6483559, lng: 22.0147156 },
    { lat: 48.6483963, lng: 22.0138852 },
    { lat: 48.6482873, lng: 22.0132844 },
    { lat: 48.6481167, lng: 22.012793 },
    { lat: 48.6476142, lng: 22.0121681 },
    { lat: 48.6466788, lng: 22.0114993 },
    { lat: 48.6459452, lng: 22.010893 },
    { lat: 48.6454303, lng: 22.0106748 },
    { lat: 48.6449087, lng: 22.0105914 },
    { lat: 48.6439957, lng: 22.0105728 },
    { lat: 48.6431631, lng: 22.0104284 },
    { lat: 48.6428609, lng: 22.0102549 },
    { lat: 48.642591, lng: 22.0099359 },
    { lat: 48.6423345, lng: 22.0094817 },
    { lat: 48.6416272, lng: 22.0079211 },
    { lat: 48.641524, lng: 22.0075675 },
    { lat: 48.6411139, lng: 22.0071753 },
    { lat: 48.6394527, lng: 22.0068633 },
    { lat: 48.6392773, lng: 22.0068642 },
    { lat: 48.6388265, lng: 22.0070646 },
    { lat: 48.6383528, lng: 22.0070106 },
    { lat: 48.6376761, lng: 22.0065315 },
    { lat: 48.6370721, lng: 22.0059807 },
    { lat: 48.6366226, lng: 22.0053047 },
    { lat: 48.6364508, lng: 22.0051816 },
    { lat: 48.6355583, lng: 22.004892 },
    { lat: 48.6354214, lng: 22.0049043 },
    { lat: 48.6350046, lng: 22.0046547 },
    { lat: 48.6349728, lng: 22.0048667 },
    { lat: 48.6342622, lng: 22.0053412 },
    { lat: 48.6341241, lng: 22.0054894 },
    { lat: 48.6337702, lng: 22.0059675 },
    { lat: 48.6330655, lng: 22.0071864 },
    { lat: 48.6322393, lng: 22.0082314 },
    { lat: 48.6318836, lng: 22.0087715 },
    { lat: 48.6315638, lng: 22.0088995 },
    { lat: 48.6305923, lng: 22.0086879 },
    { lat: 48.6294043, lng: 22.0078134 },
    { lat: 48.6288621, lng: 22.0076287 },
    { lat: 48.628552, lng: 22.0076435 },
    { lat: 48.6282645, lng: 22.0079845 },
    { lat: 48.6286245, lng: 22.009781 },
    { lat: 48.62848, lng: 22.0102623 },
    { lat: 48.6281763, lng: 22.0104993 },
    { lat: 48.6274724, lng: 22.0105096 },
    { lat: 48.6268025, lng: 22.0103961 },
    { lat: 48.6267016, lng: 22.0103189 },
    { lat: 48.6259838, lng: 22.0104057 },
    { lat: 48.6245025, lng: 22.0109279 },
    { lat: 48.6241371, lng: 22.0107057 },
    { lat: 48.6239034, lng: 22.0102095 },
    { lat: 48.6237391, lng: 22.0087309 },
    { lat: 48.6236421, lng: 22.0083498 },
    { lat: 48.6234124, lng: 22.0079703 },
    { lat: 48.6227701, lng: 22.0073742 },
    { lat: 48.6223797, lng: 22.0071449 },
    { lat: 48.6220613, lng: 22.0070961 },
    { lat: 48.6217211, lng: 22.0073074 },
    { lat: 48.6215177, lng: 22.0075904 },
    { lat: 48.6212869, lng: 22.0068064 },
    { lat: 48.6212732, lng: 22.0061497 },
    { lat: 48.6211053, lng: 22.0060894 },
    { lat: 48.6239135, lng: 22.000321 },
  ],
  Vojany: [
    { lat: 48.5594019, lng: 21.9691319 },
    { lat: 48.5587879, lng: 21.9698164 },
    { lat: 48.5587222, lng: 21.9698968 },
    { lat: 48.5585484, lng: 21.9699151 },
    { lat: 48.558519, lng: 21.9700488 },
    { lat: 48.5587105, lng: 21.9709188 },
    { lat: 48.556044, lng: 21.9726754 },
    { lat: 48.5559968, lng: 21.9725399 },
    { lat: 48.5552754, lng: 21.9731163 },
    { lat: 48.5548318, lng: 21.9724748 },
    { lat: 48.5546117, lng: 21.9727133 },
    { lat: 48.5544464, lng: 21.9728075 },
    { lat: 48.5537371, lng: 21.9698326 },
    { lat: 48.5537789, lng: 21.969799 },
    { lat: 48.5535778, lng: 21.9689443 },
    { lat: 48.5534875, lng: 21.9688002 },
    { lat: 48.553092, lng: 21.9671422 },
    { lat: 48.5531494, lng: 21.9670981 },
    { lat: 48.5530276, lng: 21.9665869 },
    { lat: 48.5525981, lng: 21.9647863 },
    { lat: 48.552522, lng: 21.9648445 },
    { lat: 48.5518094, lng: 21.9617476 },
    { lat: 48.5514064, lng: 21.9618743 },
    { lat: 48.5514037, lng: 21.9619924 },
    { lat: 48.5513049, lng: 21.9620495 },
    { lat: 48.5515974, lng: 21.9632704 },
    { lat: 48.5516431, lng: 21.9632518 },
    { lat: 48.5513868, lng: 21.9672397 },
    { lat: 48.5494788, lng: 21.9717742 },
    { lat: 48.5488847, lng: 21.9713652 },
    { lat: 48.548424, lng: 21.9694326 },
    { lat: 48.5463469, lng: 21.9705529 },
    { lat: 48.5476847, lng: 21.9761869 },
    { lat: 48.5474637, lng: 21.9763037 },
    { lat: 48.5474225, lng: 21.9763259 },
    { lat: 48.5455793, lng: 21.9772989 },
    { lat: 48.5456587, lng: 21.9776474 },
    { lat: 48.5464615, lng: 21.9810856 },
    { lat: 48.545186, lng: 21.981776 },
    { lat: 48.545757, lng: 21.9841707 },
    { lat: 48.5454625, lng: 21.9843207 },
    { lat: 48.5457695, lng: 21.9855941 },
    { lat: 48.5450446, lng: 21.9859858 },
    { lat: 48.5453759, lng: 21.9873742 },
    { lat: 48.5466585, lng: 21.9866964 },
    { lat: 48.5471622, lng: 21.9888431 },
    { lat: 48.5474166, lng: 21.9899276 },
    { lat: 48.5504986, lng: 21.9882689 },
    { lat: 48.5506255, lng: 21.9885331 },
    { lat: 48.5508864, lng: 21.9896299 },
    { lat: 48.5510951, lng: 21.9895167 },
    { lat: 48.5532277, lng: 21.9939746 },
    { lat: 48.5527156, lng: 21.9945649 },
    { lat: 48.5562784, lng: 22.0018464 },
    { lat: 48.5587596, lng: 22.0070647 },
    { lat: 48.559041, lng: 22.0077536 },
    { lat: 48.5619259, lng: 22.0139927 },
    { lat: 48.56373, lng: 22.0177504 },
    { lat: 48.5664686, lng: 22.0170383 },
    { lat: 48.5664901, lng: 22.0171384 },
    { lat: 48.5668816, lng: 22.0170865 },
    { lat: 48.5667021, lng: 22.0180767 },
    { lat: 48.5667303, lng: 22.0191869 },
    { lat: 48.568415, lng: 22.0182465 },
    { lat: 48.5695281, lng: 22.0214083 },
    { lat: 48.5744858, lng: 22.0179952 },
    { lat: 48.5749293, lng: 22.0201735 },
    { lat: 48.5749538, lng: 22.0201643 },
    { lat: 48.5752514, lng: 22.0200526 },
    { lat: 48.575436, lng: 22.0206066 },
    { lat: 48.5761027, lng: 22.019802 },
    { lat: 48.5786373, lng: 22.0178307 },
    { lat: 48.5785975, lng: 22.0173014 },
    { lat: 48.5803595, lng: 22.0152398 },
    { lat: 48.5806934, lng: 22.0155507 },
    { lat: 48.5815207, lng: 22.0157595 },
    { lat: 48.5820526, lng: 22.0155229 },
    { lat: 48.5827749, lng: 22.0146071 },
    { lat: 48.5828198, lng: 22.014418 },
    { lat: 48.5829408, lng: 22.0143413 },
    { lat: 48.5830673, lng: 22.0144143 },
    { lat: 48.5831246, lng: 22.013768 },
    { lat: 48.5831844, lng: 22.013591 },
    { lat: 48.5836587, lng: 22.0129272 },
    { lat: 48.5828889, lng: 22.0101024 },
    { lat: 48.5854879, lng: 22.0087862 },
    { lat: 48.5857916, lng: 22.0098409 },
    { lat: 48.5859564, lng: 22.0100922 },
    { lat: 48.5860882, lng: 22.0100551 },
    { lat: 48.5863654, lng: 22.0094726 },
    { lat: 48.588567, lng: 22.005663 },
    { lat: 48.589863, lng: 22.003236 },
    { lat: 48.5904324, lng: 22.001123 },
    { lat: 48.5917577, lng: 21.9991146 },
    { lat: 48.59232, lng: 21.997455 },
    { lat: 48.5925345, lng: 21.9971665 },
    { lat: 48.5927134, lng: 21.9972649 },
    { lat: 48.5939591, lng: 22.0005345 },
    { lat: 48.5939358, lng: 22.0007252 },
    { lat: 48.5947276, lng: 22.002786 },
    { lat: 48.5953275, lng: 22.0018983 },
    { lat: 48.5965741, lng: 22.0000536 },
    { lat: 48.5962531, lng: 21.9992981 },
    { lat: 48.5951896, lng: 21.9963047 },
    { lat: 48.5948558, lng: 21.995173 },
    { lat: 48.5939831, lng: 21.9926684 },
    { lat: 48.5916064, lng: 21.9884733 },
    { lat: 48.5913037, lng: 21.984914 },
    { lat: 48.5895136, lng: 21.9845317 },
    { lat: 48.5887235, lng: 21.9846531 },
    { lat: 48.5877191, lng: 21.9854364 },
    { lat: 48.5866676, lng: 21.9868214 },
    { lat: 48.5858463, lng: 21.9880062 },
    { lat: 48.5851483, lng: 21.9886205 },
    { lat: 48.5844014, lng: 21.9889409 },
    { lat: 48.5827135, lng: 21.9893405 },
    { lat: 48.5823802, lng: 21.9896442 },
    { lat: 48.5814009, lng: 21.9905363 },
    { lat: 48.5816107, lng: 21.9909669 },
    { lat: 48.5816013, lng: 21.991457 },
    { lat: 48.5817, lng: 21.9921251 },
    { lat: 48.5815429, lng: 21.9927813 },
    { lat: 48.5810536, lng: 21.9940233 },
    { lat: 48.5806041, lng: 21.9947249 },
    { lat: 48.5799363, lng: 21.9951547 },
    { lat: 48.5793095, lng: 21.9950615 },
    { lat: 48.5789157, lng: 21.9947286 },
    { lat: 48.5785414, lng: 21.9941956 },
    { lat: 48.5778386, lng: 21.9928415 },
    { lat: 48.5772915, lng: 21.9920944 },
    { lat: 48.57677, lng: 21.9912393 },
    { lat: 48.5759957, lng: 21.9898567 },
    { lat: 48.5754651, lng: 21.988687 },
    { lat: 48.5751711, lng: 21.9878917 },
    { lat: 48.5747523, lng: 21.9860111 },
    { lat: 48.5750271, lng: 21.9857502 },
    { lat: 48.5757184, lng: 21.9859595 },
    { lat: 48.5767263, lng: 21.9845879 },
    { lat: 48.5767475, lng: 21.983889 },
    { lat: 48.5765841, lng: 21.9835617 },
    { lat: 48.575359, lng: 21.9832854 },
    { lat: 48.5742702, lng: 21.983252 },
    { lat: 48.5739642, lng: 21.9834304 },
    { lat: 48.5736395, lng: 21.9824426 },
    { lat: 48.5733631, lng: 21.9818663 },
    { lat: 48.5735665, lng: 21.9814065 },
    { lat: 48.5739999, lng: 21.9799137 },
    { lat: 48.5739852, lng: 21.9793671 },
    { lat: 48.5739104, lng: 21.9791525 },
    { lat: 48.5735845, lng: 21.9789889 },
    { lat: 48.5732822, lng: 21.979051 },
    { lat: 48.5730205, lng: 21.9793353 },
    { lat: 48.5724129, lng: 21.9805129 },
    { lat: 48.5721156, lng: 21.9809622 },
    { lat: 48.570767, lng: 21.9809725 },
    { lat: 48.5700386, lng: 21.9813085 },
    { lat: 48.5691762, lng: 21.9811878 },
    { lat: 48.5684934, lng: 21.9808333 },
    { lat: 48.5679164, lng: 21.9801105 },
    { lat: 48.5675201, lng: 21.9789563 },
    { lat: 48.5671723, lng: 21.9763983 },
    { lat: 48.5664113, lng: 21.976788 },
    { lat: 48.565819, lng: 21.9772195 },
    { lat: 48.5657938, lng: 21.9777917 },
    { lat: 48.5659172, lng: 21.9786933 },
    { lat: 48.5658318, lng: 21.9793275 },
    { lat: 48.5656299, lng: 21.9798119 },
    { lat: 48.5651789, lng: 21.9800393 },
    { lat: 48.5647225, lng: 21.9798269 },
    { lat: 48.5636225, lng: 21.9786516 },
    { lat: 48.5631039, lng: 21.978294 },
    { lat: 48.5626391, lng: 21.9782845 },
    { lat: 48.5618538, lng: 21.9785137 },
    { lat: 48.5607752, lng: 21.979157 },
    { lat: 48.5600091, lng: 21.979292 },
    { lat: 48.559477, lng: 21.9792043 },
    { lat: 48.5592761, lng: 21.9790405 },
    { lat: 48.5583598, lng: 21.977469 },
    { lat: 48.558109, lng: 21.9767941 },
    { lat: 48.5580823, lng: 21.9763538 },
    { lat: 48.5583007, lng: 21.9754044 },
    { lat: 48.5598935, lng: 21.9746202 },
    { lat: 48.5600926, lng: 21.9741676 },
    { lat: 48.5599415, lng: 21.9722019 },
    { lat: 48.5605216, lng: 21.9696406 },
    { lat: 48.560328, lng: 21.9692758 },
    { lat: 48.560013, lng: 21.9690928 },
    { lat: 48.5594019, lng: 21.9691319 },
  ],
  VeľkéRaškovce: [
    { lat: 48.5216335, lng: 21.9166885 },
    { lat: 48.5228263, lng: 21.9215428 },
    { lat: 48.5228069, lng: 21.9217814 },
    { lat: 48.522281, lng: 21.9282558 },
    { lat: 48.5239862, lng: 21.9278848 },
    { lat: 48.5245858, lng: 21.9278406 },
    { lat: 48.5289624, lng: 21.9270431 },
    { lat: 48.5326521, lng: 21.9260078 },
    { lat: 48.5343929, lng: 21.9254412 },
    { lat: 48.5358582, lng: 21.9250621 },
    { lat: 48.5361903, lng: 21.9248929 },
    { lat: 48.536443, lng: 21.9246485 },
    { lat: 48.5382244, lng: 21.9224465 },
    { lat: 48.5383942, lng: 21.9223097 },
    { lat: 48.5391255, lng: 21.9222149 },
    { lat: 48.5404989, lng: 21.9215893 },
    { lat: 48.5408827, lng: 21.9216358 },
    { lat: 48.5412009, lng: 21.9219424 },
    { lat: 48.542887, lng: 21.921972 },
    { lat: 48.5432481, lng: 21.9217085 },
    { lat: 48.5432188, lng: 21.9215738 },
    { lat: 48.5436625, lng: 21.9213595 },
    { lat: 48.544373, lng: 21.9212578 },
    { lat: 48.5465921, lng: 21.9204714 },
    { lat: 48.5466632, lng: 21.9211033 },
    { lat: 48.5479273, lng: 21.9218057 },
    { lat: 48.5490972, lng: 21.923851 },
    { lat: 48.5490053, lng: 21.9240011 },
    { lat: 48.5490761, lng: 21.9241363 },
    { lat: 48.5492143, lng: 21.9240343 },
    { lat: 48.5504137, lng: 21.9260983 },
    { lat: 48.551621, lng: 21.9267729 },
    { lat: 48.553435, lng: 21.9276433 },
    { lat: 48.5551888, lng: 21.928396 },
    { lat: 48.5552185, lng: 21.9286394 },
    { lat: 48.5553032, lng: 21.9286523 },
    { lat: 48.5553833, lng: 21.9284812 },
    { lat: 48.5559594, lng: 21.9287114 },
    { lat: 48.5563824, lng: 21.9290608 },
    { lat: 48.5569517, lng: 21.9293352 },
    { lat: 48.5563368, lng: 21.9306061 },
    { lat: 48.5562147, lng: 21.9307168 },
    { lat: 48.5563246, lng: 21.9308781 },
    { lat: 48.5567343, lng: 21.9303547 },
    { lat: 48.5571789, lng: 21.9295566 },
    { lat: 48.5573675, lng: 21.9297266 },
    { lat: 48.5575894, lng: 21.9300942 },
    { lat: 48.557622, lng: 21.9300388 },
    { lat: 48.5582095, lng: 21.9290275 },
    { lat: 48.5590466, lng: 21.9300132 },
    { lat: 48.5594961, lng: 21.9303952 },
    { lat: 48.5597826, lng: 21.9305554 },
    { lat: 48.561344, lng: 21.9305948 },
    { lat: 48.5620214, lng: 21.9308275 },
    { lat: 48.5624949, lng: 21.9312231 },
    { lat: 48.5627213, lng: 21.9316883 },
    { lat: 48.5628445, lng: 21.9326967 },
    { lat: 48.5627335, lng: 21.9334211 },
    { lat: 48.5625112, lng: 21.9339843 },
    { lat: 48.562333, lng: 21.9346327 },
    { lat: 48.5622937, lng: 21.9351329 },
    { lat: 48.5623405, lng: 21.9357386 },
    { lat: 48.5624058, lng: 21.9359618 },
    { lat: 48.5626234, lng: 21.9363391 },
    { lat: 48.5628999, lng: 21.9366007 },
    { lat: 48.563295, lng: 21.936743 },
    { lat: 48.5640571, lng: 21.9367692 },
    { lat: 48.5660705, lng: 21.9361285 },
    { lat: 48.5669543, lng: 21.9359289 },
    { lat: 48.5677258, lng: 21.935869 },
    { lat: 48.568201, lng: 21.9360263 },
    { lat: 48.5684825, lng: 21.936348 },
    { lat: 48.5686072, lng: 21.936742 },
    { lat: 48.5686308, lng: 21.9370148 },
    { lat: 48.5685628, lng: 21.9371693 },
    { lat: 48.5687295, lng: 21.9373594 },
    { lat: 48.5686843, lng: 21.9374863 },
    { lat: 48.5687519, lng: 21.9375745 },
    { lat: 48.5687945, lng: 21.9374413 },
    { lat: 48.5690711, lng: 21.9377932 },
    { lat: 48.569123, lng: 21.9377096 },
    { lat: 48.5710285, lng: 21.9299031 },
    { lat: 48.5706749, lng: 21.9286821 },
    { lat: 48.570644, lng: 21.9284402 },
    { lat: 48.5707379, lng: 21.9272079 },
    { lat: 48.5707042, lng: 21.9266716 },
    { lat: 48.5705143, lng: 21.9257508 },
    { lat: 48.5691713, lng: 21.926246 },
    { lat: 48.5692386, lng: 21.925119 },
    { lat: 48.5693371, lng: 21.9243429 },
    { lat: 48.5692931, lng: 21.9240271 },
    { lat: 48.5692333, lng: 21.9237943 },
    { lat: 48.5691291, lng: 21.9236379 },
    { lat: 48.5686863, lng: 21.9234732 },
    { lat: 48.5684992, lng: 21.9231877 },
    { lat: 48.5683539, lng: 21.9228312 },
    { lat: 48.5684485, lng: 21.9224312 },
    { lat: 48.5688278, lng: 21.9225253 },
    { lat: 48.5708825, lng: 21.9219593 },
    { lat: 48.571133, lng: 21.9220402 },
    { lat: 48.5714884, lng: 21.9222639 },
    { lat: 48.5717156, lng: 21.9222594 },
    { lat: 48.5719074, lng: 21.9220982 },
    { lat: 48.57206, lng: 21.9217715 },
    { lat: 48.5721055, lng: 21.9215188 },
    { lat: 48.5720822, lng: 21.9210921 },
    { lat: 48.5719236, lng: 21.9206475 },
    { lat: 48.5716181, lng: 21.9201318 },
    { lat: 48.5714761, lng: 21.9197673 },
    { lat: 48.5714552, lng: 21.9193394 },
    { lat: 48.5716106, lng: 21.919031 },
    { lat: 48.572439, lng: 21.9186514 },
    { lat: 48.5728395, lng: 21.9185546 },
    { lat: 48.5730748, lng: 21.9182935 },
    { lat: 48.573139, lng: 21.9179921 },
    { lat: 48.573164, lng: 21.9175061 },
    { lat: 48.5730342, lng: 21.9170883 },
    { lat: 48.5728795, lng: 21.9168446 },
    { lat: 48.5723074, lng: 21.9163801 },
    { lat: 48.5719857, lng: 21.9159712 },
    { lat: 48.5711537, lng: 21.915328 },
    { lat: 48.5710292, lng: 21.915251 },
    { lat: 48.570249, lng: 21.9154496 },
    { lat: 48.5699269, lng: 21.9153917 },
    { lat: 48.5694535, lng: 21.9118951 },
    { lat: 48.5720102, lng: 21.9115613 },
    { lat: 48.5737108, lng: 21.9112363 },
    { lat: 48.5734845, lng: 21.9086116 },
    { lat: 48.5731463, lng: 21.9057407 },
    { lat: 48.5740033, lng: 21.9052857 },
    { lat: 48.5742947, lng: 21.905065 },
    { lat: 48.5741202, lng: 21.9043116 },
    { lat: 48.5740563, lng: 21.9037416 },
    { lat: 48.5740418, lng: 21.9030774 },
    { lat: 48.5741206, lng: 21.902108 },
    { lat: 48.5740588, lng: 21.9011783 },
    { lat: 48.5739855, lng: 21.900909 },
    { lat: 48.573289, lng: 21.8992617 },
    { lat: 48.5731955, lng: 21.8988568 },
    { lat: 48.5729934, lng: 21.8974285 },
    { lat: 48.5727715, lng: 21.8958603 },
    { lat: 48.5728752, lng: 21.8911377 },
    { lat: 48.573308, lng: 21.8910338 },
    { lat: 48.5735939, lng: 21.8744017 },
    { lat: 48.5733455, lng: 21.8728789 },
    { lat: 48.5736751, lng: 21.8720888 },
    { lat: 48.5738667, lng: 21.8714417 },
    { lat: 48.5734493, lng: 21.8713101 },
    { lat: 48.5715869, lng: 21.870723 },
    { lat: 48.5712055, lng: 21.870724 },
    { lat: 48.5706771, lng: 21.8709569 },
    { lat: 48.569732, lng: 21.8716098 },
    { lat: 48.5695988, lng: 21.8717209 },
    { lat: 48.5693788, lng: 21.8747716 },
    { lat: 48.5681229, lng: 21.8755065 },
    { lat: 48.566631, lng: 21.8762028 },
    { lat: 48.5669178, lng: 21.8714636 },
    { lat: 48.566147, lng: 21.8716677 },
    { lat: 48.5660876, lng: 21.8715035 },
    { lat: 48.5644449, lng: 21.8728171 },
    { lat: 48.5642376, lng: 21.8723191 },
    { lat: 48.562661, lng: 21.8733976 },
    { lat: 48.5626076, lng: 21.8732448 },
    { lat: 48.5624637, lng: 21.8733412 },
    { lat: 48.5612144, lng: 21.8743697 },
    { lat: 48.5608481, lng: 21.873425 },
    { lat: 48.5591103, lng: 21.8748008 },
    { lat: 48.5582002, lng: 21.8753758 },
    { lat: 48.5576842, lng: 21.8757833 },
    { lat: 48.5574764, lng: 21.8759261 },
    { lat: 48.5581408, lng: 21.8780606 },
    { lat: 48.557724, lng: 21.8784318 },
    { lat: 48.5580693, lng: 21.8797538 },
    { lat: 48.5590269, lng: 21.8829716 },
    { lat: 48.5589246, lng: 21.8830632 },
    { lat: 48.5591021, lng: 21.8842348 },
    { lat: 48.5588034, lng: 21.8845199 },
    { lat: 48.558801, lng: 21.8849612 },
    { lat: 48.5587382, lng: 21.8850135 },
    { lat: 48.5591993, lng: 21.8872107 },
    { lat: 48.558858, lng: 21.8898341 },
    { lat: 48.5586073, lng: 21.8906828 },
    { lat: 48.5583586, lng: 21.8903232 },
    { lat: 48.5576024, lng: 21.8923036 },
    { lat: 48.5574476, lng: 21.892103 },
    { lat: 48.557326, lng: 21.8922723 },
    { lat: 48.5572657, lng: 21.8921994 },
    { lat: 48.5558963, lng: 21.8934332 },
    { lat: 48.5546694, lng: 21.8946873 },
    { lat: 48.5545079, lng: 21.8943923 },
    { lat: 48.5541679, lng: 21.8947412 },
    { lat: 48.5539103, lng: 21.894347 },
    { lat: 48.5524741, lng: 21.8961675 },
    { lat: 48.5515512, lng: 21.8975247 },
    { lat: 48.5512245, lng: 21.8970262 },
    { lat: 48.5500767, lng: 21.8986491 },
    { lat: 48.5498219, lng: 21.8989383 },
    { lat: 48.5497418, lng: 21.8991048 },
    { lat: 48.5496665, lng: 21.8999106 },
    { lat: 48.5492109, lng: 21.9003473 },
    { lat: 48.5483674, lng: 21.901332 },
    { lat: 48.547637, lng: 21.9027869 },
    { lat: 48.5471724, lng: 21.9034936 },
    { lat: 48.5469059, lng: 21.9038065 },
    { lat: 48.5456702, lng: 21.904238 },
    { lat: 48.5450337, lng: 21.9047749 },
    { lat: 48.5448355, lng: 21.9048147 },
    { lat: 48.5448253, lng: 21.9057903 },
    { lat: 48.5447189, lng: 21.9063783 },
    { lat: 48.543917, lng: 21.9082543 },
    { lat: 48.5438048, lng: 21.9086631 },
    { lat: 48.5437622, lng: 21.9090752 },
    { lat: 48.543778, lng: 21.9094272 },
    { lat: 48.543867, lng: 21.9098815 },
    { lat: 48.5440162, lng: 21.9101683 },
    { lat: 48.5445058, lng: 21.9106209 },
    { lat: 48.5448013, lng: 21.9107385 },
    { lat: 48.5452975, lng: 21.9107448 },
    { lat: 48.5453234, lng: 21.9117659 },
    { lat: 48.5451367, lng: 21.9120834 },
    { lat: 48.545006, lng: 21.9124718 },
    { lat: 48.5446686, lng: 21.9126552 },
    { lat: 48.5443641, lng: 21.9125392 },
    { lat: 48.5440485, lng: 21.911936 },
    { lat: 48.5437618, lng: 21.9116745 },
    { lat: 48.5417552, lng: 21.9116019 },
    { lat: 48.5408802, lng: 21.9120139 },
    { lat: 48.5400739, lng: 21.9132701 },
    { lat: 48.539538, lng: 21.913614 },
    { lat: 48.5385306, lng: 21.9136753 },
    { lat: 48.5382612, lng: 21.9135282 },
    { lat: 48.5371627, lng: 21.9133736 },
    { lat: 48.5359488, lng: 21.9135949 },
    { lat: 48.5350535, lng: 21.9134691 },
    { lat: 48.5347121, lng: 21.9137628 },
    { lat: 48.5343577, lng: 21.9143837 },
    { lat: 48.5334964, lng: 21.9151555 },
    { lat: 48.5329171, lng: 21.9154198 },
    { lat: 48.5324949, lng: 21.9157226 },
    { lat: 48.5320994, lng: 21.9157911 },
    { lat: 48.5315126, lng: 21.9150985 },
    { lat: 48.531311, lng: 21.9144161 },
    { lat: 48.53106, lng: 21.914279 },
    { lat: 48.5302199, lng: 21.9161207 },
    { lat: 48.5302608, lng: 21.9167122 },
    { lat: 48.5301521, lng: 21.9172128 },
    { lat: 48.5297719, lng: 21.917843 },
    { lat: 48.5290339, lng: 21.9180613 },
    { lat: 48.5287855, lng: 21.918252 },
    { lat: 48.5280384, lng: 21.9185581 },
    { lat: 48.5270663, lng: 21.9191398 },
    { lat: 48.5262738, lng: 21.9193868 },
    { lat: 48.525546, lng: 21.9192052 },
    { lat: 48.5254216, lng: 21.9190026 },
    { lat: 48.5251729, lng: 21.9189572 },
    { lat: 48.5236103, lng: 21.9168658 },
    { lat: 48.5233706, lng: 21.9164658 },
    { lat: 48.5225059, lng: 21.9163939 },
    { lat: 48.5216997, lng: 21.9165917 },
    { lat: 48.5216335, lng: 21.9166885 },
  ],
  Tušice: [
    { lat: 48.741604, lng: 21.759802 },
    { lat: 48.741433, lng: 21.759008 },
    { lat: 48.741305, lng: 21.758399 },
    { lat: 48.741302, lng: 21.758388 },
    { lat: 48.741283, lng: 21.758297 },
    { lat: 48.74124, lng: 21.758118 },
    { lat: 48.741232, lng: 21.758084 },
    { lat: 48.741152, lng: 21.757816 },
    { lat: 48.740756, lng: 21.756494 },
    { lat: 48.740271, lng: 21.754879 },
    { lat: 48.739942, lng: 21.753837 },
    { lat: 48.739721, lng: 21.753111 },
    { lat: 48.739538, lng: 21.752494 },
    { lat: 48.739246, lng: 21.751514 },
    { lat: 48.739235, lng: 21.751473 },
    { lat: 48.738736, lng: 21.749785 },
    { lat: 48.738684, lng: 21.749608 },
    { lat: 48.737874, lng: 21.746869 },
    { lat: 48.737819, lng: 21.746714 },
    { lat: 48.737751, lng: 21.746455 },
    { lat: 48.73754, lng: 21.746627 },
    { lat: 48.737383, lng: 21.74599 },
    { lat: 48.737266, lng: 21.745386 },
    { lat: 48.737128, lng: 21.744711 },
    { lat: 48.737101, lng: 21.744552 },
    { lat: 48.737081, lng: 21.744406 },
    { lat: 48.737082, lng: 21.744274 },
    { lat: 48.737086, lng: 21.744045 },
    { lat: 48.737071, lng: 21.743893 },
    { lat: 48.736749, lng: 21.743856 },
    { lat: 48.736649, lng: 21.74082 },
    { lat: 48.73668, lng: 21.740174 },
    { lat: 48.736687, lng: 21.740042 },
    { lat: 48.736705, lng: 21.739666 },
    { lat: 48.736759, lng: 21.738644 },
    { lat: 48.736965, lng: 21.738856 },
    { lat: 48.73702, lng: 21.73878 },
    { lat: 48.736511, lng: 21.735724 },
    { lat: 48.736527, lng: 21.735604 },
    { lat: 48.7362, lng: 21.735858 },
    { lat: 48.735982, lng: 21.734717 },
    { lat: 48.736387, lng: 21.733685 },
    { lat: 48.736083, lng: 21.731705 },
    { lat: 48.736073, lng: 21.731638 },
    { lat: 48.736062, lng: 21.731572 },
    { lat: 48.73595, lng: 21.730025 },
    { lat: 48.735537, lng: 21.727458 },
    { lat: 48.735521, lng: 21.72736 },
    { lat: 48.735537, lng: 21.727121 },
    { lat: 48.735322, lng: 21.724719 },
    { lat: 48.735169, lng: 21.722632 },
    { lat: 48.735158, lng: 21.722471 },
    { lat: 48.735152, lng: 21.722405 },
    { lat: 48.735143, lng: 21.722273 },
    { lat: 48.735138, lng: 21.722206 },
    { lat: 48.735035, lng: 21.720785 },
    { lat: 48.733919, lng: 21.717223 },
    { lat: 48.733858, lng: 21.717028 },
    { lat: 48.733347, lng: 21.714767 },
    { lat: 48.732398, lng: 21.71572 },
    { lat: 48.731884, lng: 21.716248 },
    { lat: 48.731699, lng: 21.716395 },
    { lat: 48.73147, lng: 21.716484 },
    { lat: 48.731376, lng: 21.716501 },
    { lat: 48.731329, lng: 21.71651 },
    { lat: 48.731246, lng: 21.716711 },
    { lat: 48.731225, lng: 21.716909 },
    { lat: 48.731196, lng: 21.717157 },
    { lat: 48.731201, lng: 21.717579 },
    { lat: 48.731219, lng: 21.717698 },
    { lat: 48.731283, lng: 21.718114 },
    { lat: 48.731291, lng: 21.718173 },
    { lat: 48.731405, lng: 21.719014 },
    { lat: 48.731514, lng: 21.719816 },
    { lat: 48.73155, lng: 21.720173 },
    { lat: 48.731599, lng: 21.720342 },
    { lat: 48.731817, lng: 21.720691 },
    { lat: 48.731842, lng: 21.720742 },
    { lat: 48.731996, lng: 21.720797 },
    { lat: 48.732593, lng: 21.720452 },
    { lat: 48.732828, lng: 21.720422 },
    { lat: 48.732964, lng: 21.720726 },
    { lat: 48.733097, lng: 21.722159 },
    { lat: 48.733075, lng: 21.722257 },
    { lat: 48.733054, lng: 21.72235 },
    { lat: 48.733023, lng: 21.722484 },
    { lat: 48.732778, lng: 21.722724 },
    { lat: 48.732454, lng: 21.722861 },
    { lat: 48.732228, lng: 21.722881 },
    { lat: 48.731734, lng: 21.722887 },
    { lat: 48.731556, lng: 21.723064 },
    { lat: 48.730578, lng: 21.723381 },
    { lat: 48.7304, lng: 21.722664 },
    { lat: 48.730433, lng: 21.722196 },
    { lat: 48.730163, lng: 21.722087 },
    { lat: 48.729553, lng: 21.721842 },
    { lat: 48.729344, lng: 21.721825 },
    { lat: 48.72895, lng: 21.721793 },
    { lat: 48.728137, lng: 21.721308 },
    { lat: 48.727855, lng: 21.721495 },
    { lat: 48.727716, lng: 21.721769 },
    { lat: 48.727569, lng: 21.721999 },
    { lat: 48.727372, lng: 21.722308 },
    { lat: 48.727235, lng: 21.722773 },
    { lat: 48.727145, lng: 21.723073 },
    { lat: 48.727045, lng: 21.723398 },
    { lat: 48.72699, lng: 21.724396 },
    { lat: 48.726841, lng: 21.72466 },
    { lat: 48.726723, lng: 21.724643 },
    { lat: 48.726375, lng: 21.724586 },
    { lat: 48.725623, lng: 21.724231 },
    { lat: 48.725192, lng: 21.723838 },
    { lat: 48.725166, lng: 21.723824 },
    { lat: 48.725041, lng: 21.723814 },
    { lat: 48.724719, lng: 21.723844 },
    { lat: 48.724314, lng: 21.723746 },
    { lat: 48.723949, lng: 21.723698 },
    { lat: 48.723394, lng: 21.723825 },
    { lat: 48.723318, lng: 21.723842 },
    { lat: 48.723284, lng: 21.723791 },
    { lat: 48.723098, lng: 21.724074 },
    { lat: 48.722963, lng: 21.724281 },
    { lat: 48.72155, lng: 21.726363 },
    { lat: 48.720683, lng: 21.727615 },
    { lat: 48.720592, lng: 21.727826 },
    { lat: 48.720559, lng: 21.727978 },
    { lat: 48.720161, lng: 21.727918 },
    { lat: 48.719737, lng: 21.727956 },
    { lat: 48.719323, lng: 21.72803 },
    { lat: 48.718831, lng: 21.728177 },
    { lat: 48.718417, lng: 21.72824 },
    { lat: 48.718054, lng: 21.728376 },
    { lat: 48.717754, lng: 21.728624 },
    { lat: 48.716309, lng: 21.728445 },
    { lat: 48.71621, lng: 21.728462 },
    { lat: 48.7162926, lng: 21.7288467 },
    { lat: 48.7162994, lng: 21.7291024 },
    { lat: 48.7162837, lng: 21.7290154 },
    { lat: 48.7161699, lng: 21.729012 },
    { lat: 48.7160802, lng: 21.7293139 },
    { lat: 48.7160855, lng: 21.7295113 },
    { lat: 48.7159331, lng: 21.7300447 },
    { lat: 48.7158139, lng: 21.7302166 },
    { lat: 48.7158037, lng: 21.7309598 },
    { lat: 48.7158755, lng: 21.7314595 },
    { lat: 48.7160156, lng: 21.7319882 },
    { lat: 48.7162979, lng: 21.7322745 },
    { lat: 48.7165153, lng: 21.7319947 },
    { lat: 48.7212422, lng: 21.7488158 },
    { lat: 48.7212104, lng: 21.7488519 },
    { lat: 48.7269299, lng: 21.7693505 },
    { lat: 48.726869, lng: 21.7693823 },
    { lat: 48.7268979, lng: 21.7695049 },
    { lat: 48.7270887, lng: 21.770293 },
    { lat: 48.727341, lng: 21.7710707 },
    { lat: 48.7273745, lng: 21.7718291 },
    { lat: 48.728055, lng: 21.7714313 },
    { lat: 48.7285804, lng: 21.7718999 },
    { lat: 48.7287958, lng: 21.7719692 },
    { lat: 48.7291034, lng: 21.7718138 },
    { lat: 48.7293137, lng: 21.771558 },
    { lat: 48.7292938, lng: 21.7709274 },
    { lat: 48.7304808, lng: 21.7699551 },
    { lat: 48.730721, lng: 21.7701427 },
    { lat: 48.7308962, lng: 21.7704742 },
    { lat: 48.7310818, lng: 21.7706879 },
    { lat: 48.7313813, lng: 21.7706919 },
    { lat: 48.7314714, lng: 21.7706145 },
    { lat: 48.7315078, lng: 21.7704113 },
    { lat: 48.7312682, lng: 21.7698202 },
    { lat: 48.731177, lng: 21.7693496 },
    { lat: 48.7313367, lng: 21.7689248 },
    { lat: 48.731662, lng: 21.7685872 },
    { lat: 48.7320552, lng: 21.7689936 },
    { lat: 48.7322484, lng: 21.7691053 },
    { lat: 48.7324909, lng: 21.7690033 },
    { lat: 48.7326628, lng: 21.7688265 },
    { lat: 48.7327643, lng: 21.7685373 },
    { lat: 48.732743, lng: 21.7683676 },
    { lat: 48.7325236, lng: 21.7679859 },
    { lat: 48.732494, lng: 21.7675455 },
    { lat: 48.7329287, lng: 21.7670099 },
    { lat: 48.733001, lng: 21.7666413 },
    { lat: 48.7339091, lng: 21.7656715 },
    { lat: 48.7340948, lng: 21.7657992 },
    { lat: 48.7355429, lng: 21.7646202 },
    { lat: 48.7356764, lng: 21.7646859 },
    { lat: 48.7372466, lng: 21.763702 },
    { lat: 48.7380179, lng: 21.7635088 },
    { lat: 48.7379465, lng: 21.7630212 },
    { lat: 48.7380854, lng: 21.762152 },
    { lat: 48.7383096, lng: 21.7620361 },
    { lat: 48.7387385, lng: 21.7613194 },
    { lat: 48.741604, lng: 21.759802 },
  ],
  Markovce: [
    { lat: 48.6110641, lng: 21.8604344 },
    { lat: 48.6109667, lng: 21.8604994 },
    { lat: 48.6107678, lng: 21.8600258 },
    { lat: 48.6104349, lng: 21.8586769 },
    { lat: 48.6086847, lng: 21.8531421 },
    { lat: 48.6088726, lng: 21.8530155 },
    { lat: 48.6076754, lng: 21.8495237 },
    { lat: 48.6072885, lng: 21.8485286 },
    { lat: 48.6066537, lng: 21.8462065 },
    { lat: 48.6065304, lng: 21.8448753 },
    { lat: 48.6064521, lng: 21.8445899 },
    { lat: 48.605982, lng: 21.8432869 },
    { lat: 48.6058087, lng: 21.8429839 },
    { lat: 48.6054142, lng: 21.8420427 },
    { lat: 48.6047572, lng: 21.8391778 },
    { lat: 48.604547, lng: 21.8386634 },
    { lat: 48.6042764, lng: 21.8375105 },
    { lat: 48.604073, lng: 21.8370497 },
    { lat: 48.603837, lng: 21.8360115 },
    { lat: 48.6036846, lng: 21.8357819 },
    { lat: 48.6032447, lng: 21.8356899 },
    { lat: 48.6030495, lng: 21.8348913 },
    { lat: 48.6025436, lng: 21.8338227 },
    { lat: 48.6022523, lng: 21.8330892 },
    { lat: 48.6009981, lng: 21.8289813 },
    { lat: 48.6000416, lng: 21.8266404 },
    { lat: 48.5992442, lng: 21.8243214 },
    { lat: 48.5986732, lng: 21.8241224 },
    { lat: 48.5981927, lng: 21.8218302 },
    { lat: 48.5978138, lng: 21.8192509 },
    { lat: 48.5974423, lng: 21.8185045 },
    { lat: 48.5957173, lng: 21.8132883 },
    { lat: 48.5947526, lng: 21.8110703 },
    { lat: 48.5947203, lng: 21.8111241 },
    { lat: 48.5944976, lng: 21.8114273 },
    { lat: 48.5941753, lng: 21.8115418 },
    { lat: 48.5936626, lng: 21.8120225 },
    { lat: 48.5935169, lng: 21.8120515 },
    { lat: 48.5930633, lng: 21.8117788 },
    { lat: 48.5925152, lng: 21.8123758 },
    { lat: 48.5923161, lng: 21.8124594 },
    { lat: 48.5920021, lng: 21.8122016 },
    { lat: 48.5914022, lng: 21.8122777 },
    { lat: 48.5910552, lng: 21.8121679 },
    { lat: 48.5909901, lng: 21.8120968 },
    { lat: 48.5907328, lng: 21.8121025 },
    { lat: 48.5903954, lng: 21.8125771 },
    { lat: 48.5903085, lng: 21.8125716 },
    { lat: 48.5898326, lng: 21.8128277 },
    { lat: 48.5897214, lng: 21.8129672 },
    { lat: 48.5896651, lng: 21.813139 },
    { lat: 48.5894243, lng: 21.8133079 },
    { lat: 48.5893415, lng: 21.81345 },
    { lat: 48.5894425, lng: 21.8144735 },
    { lat: 48.5887244, lng: 21.8151484 },
    { lat: 48.5882995, lng: 21.8159401 },
    { lat: 48.588053, lng: 21.8160703 },
    { lat: 48.5877231, lng: 21.8163571 },
    { lat: 48.5875745, lng: 21.8163549 },
    { lat: 48.5870026, lng: 21.8165516 },
    { lat: 48.5864032, lng: 21.8169088 },
    { lat: 48.5861522, lng: 21.8169507 },
    { lat: 48.5860116, lng: 21.8168194 },
    { lat: 48.5860021, lng: 21.8163302 },
    { lat: 48.5859096, lng: 21.8162366 },
    { lat: 48.5858226, lng: 21.8162638 },
    { lat: 48.5857725, lng: 21.8164117 },
    { lat: 48.5856204, lng: 21.8164019 },
    { lat: 48.5853672, lng: 21.8166622 },
    { lat: 48.5852919, lng: 21.8168322 },
    { lat: 48.5848795, lng: 21.8169828 },
    { lat: 48.5843467, lng: 21.8173417 },
    { lat: 48.5842927, lng: 21.8173421 },
    { lat: 48.5842167, lng: 21.8171487 },
    { lat: 48.5837767, lng: 21.8169317 },
    { lat: 48.5834792, lng: 21.8169142 },
    { lat: 48.5832932, lng: 21.8166769 },
    { lat: 48.5831513, lng: 21.8167151 },
    { lat: 48.5829535, lng: 21.8165538 },
    { lat: 48.5828221, lng: 21.8165222 },
    { lat: 48.5826842, lng: 21.816622 },
    { lat: 48.5823176, lng: 21.8166303 },
    { lat: 48.5821495, lng: 21.8167838 },
    { lat: 48.582053, lng: 21.8166282 },
    { lat: 48.5819078, lng: 21.8165757 },
    { lat: 48.581623, lng: 21.8167024 },
    { lat: 48.5806482, lng: 21.8165503 },
    { lat: 48.5801435, lng: 21.8166971 },
    { lat: 48.5800131, lng: 21.8165343 },
    { lat: 48.5798678, lng: 21.8164847 },
    { lat: 48.5792068, lng: 21.8166767 },
    { lat: 48.5792224, lng: 21.8167138 },
    { lat: 48.5794326, lng: 21.8175679 },
    { lat: 48.5805198, lng: 21.8210338 },
    { lat: 48.5807732, lng: 21.822159 },
    { lat: 48.5810918, lng: 21.8230319 },
    { lat: 48.5812493, lng: 21.8232045 },
    { lat: 48.5818267, lng: 21.825024 },
    { lat: 48.5821676, lng: 21.8267098 },
    { lat: 48.5821959, lng: 21.8270257 },
    { lat: 48.5825081, lng: 21.8283429 },
    { lat: 48.5825768, lng: 21.8292951 },
    { lat: 48.5829751, lng: 21.8311938 },
    { lat: 48.5831268, lng: 21.8314535 },
    { lat: 48.5834747, lng: 21.8318515 },
    { lat: 48.5836216, lng: 21.8321758 },
    { lat: 48.5839914, lng: 21.8327268 },
    { lat: 48.584214, lng: 21.8328103 },
    { lat: 48.5851188, lng: 21.8336456 },
    { lat: 48.5853481, lng: 21.8342397 },
    { lat: 48.5857591, lng: 21.8347698 },
    { lat: 48.5859398, lng: 21.8353199 },
    { lat: 48.586121, lng: 21.835672 },
    { lat: 48.5865436, lng: 21.8361758 },
    { lat: 48.5868381, lng: 21.8366848 },
    { lat: 48.5868296, lng: 21.8368313 },
    { lat: 48.5869507, lng: 21.8370832 },
    { lat: 48.5873289, lng: 21.8376462 },
    { lat: 48.5876846, lng: 21.8390015 },
    { lat: 48.5884226, lng: 21.8422161 },
    { lat: 48.5888327, lng: 21.8422374 },
    { lat: 48.589772, lng: 21.8467025 },
    { lat: 48.5899921, lng: 21.8474291 },
    { lat: 48.5902822, lng: 21.8475538 },
    { lat: 48.5907964, lng: 21.8497792 },
    { lat: 48.5910425, lng: 21.8514681 },
    { lat: 48.5918557, lng: 21.8559018 },
    { lat: 48.5924455, lng: 21.8585811 },
    { lat: 48.5928128, lng: 21.8596069 },
    { lat: 48.592928, lng: 21.8595405 },
    { lat: 48.593096, lng: 21.8599866 },
    { lat: 48.5937513, lng: 21.8621077 },
    { lat: 48.5941371, lng: 21.8621473 },
    { lat: 48.5947463, lng: 21.8622962 },
    { lat: 48.5952843, lng: 21.8621769 },
    { lat: 48.5954672, lng: 21.8622039 },
    { lat: 48.5956062, lng: 21.8622725 },
    { lat: 48.5959666, lng: 21.8627188 },
    { lat: 48.5967102, lng: 21.8632071 },
    { lat: 48.5982105, lng: 21.8637944 },
    { lat: 48.5984595, lng: 21.8642902 },
    { lat: 48.5989361, lng: 21.8656752 },
    { lat: 48.5996199, lng: 21.8672035 },
    { lat: 48.5999017, lng: 21.8674913 },
    { lat: 48.6003383, lng: 21.8677935 },
    { lat: 48.600869, lng: 21.8680043 },
    { lat: 48.602119, lng: 21.8692743 },
    { lat: 48.6031554, lng: 21.8701682 },
    { lat: 48.6038503, lng: 21.8712055 },
    { lat: 48.6047562, lng: 21.8727929 },
    { lat: 48.6051751, lng: 21.8739056 },
    { lat: 48.6063502, lng: 21.8753811 },
    { lat: 48.6065248, lng: 21.8755639 },
    { lat: 48.6070216, lng: 21.8757686 },
    { lat: 48.608501, lng: 21.8776526 },
    { lat: 48.6095218, lng: 21.8785567 },
    { lat: 48.6099437, lng: 21.8775836 },
    { lat: 48.6104987, lng: 21.8767664 },
    { lat: 48.6109559, lng: 21.8756846 },
    { lat: 48.6112747, lng: 21.8752615 },
    { lat: 48.6116708, lng: 21.8750432 },
    { lat: 48.6116413, lng: 21.8732734 },
    { lat: 48.6129479, lng: 21.8725047 },
    { lat: 48.6126734, lng: 21.8721979 },
    { lat: 48.6125638, lng: 21.87196 },
    { lat: 48.6122009, lng: 21.8697417 },
    { lat: 48.6121178, lng: 21.8688286 },
    { lat: 48.612179, lng: 21.8684464 },
    { lat: 48.6120148, lng: 21.8663935 },
    { lat: 48.6122663, lng: 21.8653194 },
    { lat: 48.6120313, lng: 21.8640991 },
    { lat: 48.6114579, lng: 21.8620531 },
    { lat: 48.6111521, lng: 21.860704 },
    { lat: 48.6110641, lng: 21.8604344 },
  ],
  ČiernePole: [
    { lat: 48.5685148, lng: 22.0604876 },
    { lat: 48.5684907, lng: 22.0606128 },
    { lat: 48.5686922, lng: 22.0646474 },
    { lat: 48.568873, lng: 22.0670756 },
    { lat: 48.569028, lng: 22.0674841 },
    { lat: 48.5698313, lng: 22.0681895 },
    { lat: 48.5715653, lng: 22.0685957 },
    { lat: 48.5728894, lng: 22.0694917 },
    { lat: 48.5730817, lng: 22.0697664 },
    { lat: 48.5737093, lng: 22.0720087 },
    { lat: 48.5737668, lng: 22.0722143 },
    { lat: 48.5735676, lng: 22.0734844 },
    { lat: 48.5735543, lng: 22.0744254 },
    { lat: 48.5737212, lng: 22.0750092 },
    { lat: 48.5745481, lng: 22.0755789 },
    { lat: 48.5748699, lng: 22.0759564 },
    { lat: 48.5756044, lng: 22.0770903 },
    { lat: 48.575957, lng: 22.0777212 },
    { lat: 48.5760373, lng: 22.0779769 },
    { lat: 48.576053, lng: 22.0780982 },
    { lat: 48.5760841, lng: 22.0783381 },
    { lat: 48.5760617, lng: 22.078625 },
    { lat: 48.5759042, lng: 22.0791365 },
    { lat: 48.5757365, lng: 22.0794664 },
    { lat: 48.5755392, lng: 22.0797747 },
    { lat: 48.5750664, lng: 22.0800927 },
    { lat: 48.5745566, lng: 22.0803221 },
    { lat: 48.5741783, lng: 22.0807301 },
    { lat: 48.5739045, lng: 22.0810902 },
    { lat: 48.5737436, lng: 22.0816968 },
    { lat: 48.5738156, lng: 22.082414 },
    { lat: 48.5737815, lng: 22.083149 },
    { lat: 48.5738831, lng: 22.0838742 },
    { lat: 48.5742963, lng: 22.084883 },
    { lat: 48.5744071, lng: 22.0853765 },
    { lat: 48.574531, lng: 22.0862838 },
    { lat: 48.5746827, lng: 22.0867949 },
    { lat: 48.5751441, lng: 22.0872772 },
    { lat: 48.5755859, lng: 22.0874058 },
    { lat: 48.5761846, lng: 22.0880789 },
    { lat: 48.5767463, lng: 22.0883751 },
    { lat: 48.5773337, lng: 22.0885743 },
    { lat: 48.5778799, lng: 22.0885028 },
    { lat: 48.5788071, lng: 22.089132 },
    { lat: 48.5797517, lng: 22.0895947 },
    { lat: 48.5805906, lng: 22.0902969 },
    { lat: 48.5818489, lng: 22.0902255 },
    { lat: 48.5824304, lng: 22.0905128 },
    { lat: 48.5825367, lng: 22.090058 },
    { lat: 48.5829175, lng: 22.0898012 },
    { lat: 48.5844413, lng: 22.0897374 },
    { lat: 48.5844219, lng: 22.0886843 },
    { lat: 48.5853651, lng: 22.0883669 },
    { lat: 48.5885141, lng: 22.0867805 },
    { lat: 48.5916587, lng: 22.0920107 },
    { lat: 48.5928335, lng: 22.0925951 },
    { lat: 48.5937171, lng: 22.0914317 },
    { lat: 48.5950625, lng: 22.0899166 },
    { lat: 48.59098, lng: 22.0814408 },
    { lat: 48.5894903, lng: 22.0782208 },
    { lat: 48.5935843, lng: 22.0756897 },
    { lat: 48.5923464, lng: 22.0720939 },
    { lat: 48.5916167, lng: 22.0694301 },
    { lat: 48.5913522, lng: 22.0687683 },
    { lat: 48.5909766, lng: 22.0680663 },
    { lat: 48.5894746, lng: 22.0648196 },
    { lat: 48.5872931, lng: 22.0618959 },
    { lat: 48.5856818, lng: 22.0585515 },
    { lat: 48.5843531, lng: 22.0552707 },
    { lat: 48.5832081, lng: 22.0524267 },
    { lat: 48.5821222, lng: 22.0500265 },
    { lat: 48.582091, lng: 22.0500499 },
    { lat: 48.5790293, lng: 22.0523455 },
    { lat: 48.5759029, lng: 22.053827 },
    { lat: 48.5748971, lng: 22.0548071 },
    { lat: 48.5721285, lng: 22.0583464 },
    { lat: 48.5700944, lng: 22.0598885 },
    { lat: 48.5685148, lng: 22.0604876 },
  ],
  Malčice: [
    { lat: 48.5738667, lng: 21.8714417 },
    { lat: 48.5738736, lng: 21.8714442 },
    { lat: 48.5739584, lng: 21.8714705 },
    { lat: 48.5741485, lng: 21.8711592 },
    { lat: 48.5751704, lng: 21.8698996 },
    { lat: 48.5759632, lng: 21.8719008 },
    { lat: 48.5778985, lng: 21.8707286 },
    { lat: 48.5779279, lng: 21.871268 },
    { lat: 48.5829099, lng: 21.8712364 },
    { lat: 48.5829382, lng: 21.8703184 },
    { lat: 48.5838675, lng: 21.8699238 },
    { lat: 48.5853562, lng: 21.8691473 },
    { lat: 48.5851931, lng: 21.8681405 },
    { lat: 48.5872939, lng: 21.8668023 },
    { lat: 48.5880455, lng: 21.8661426 },
    { lat: 48.5897565, lng: 21.8650008 },
    { lat: 48.5904071, lng: 21.864439 },
    { lat: 48.59006, lng: 21.8635022 },
    { lat: 48.5904362, lng: 21.863611 },
    { lat: 48.5906904, lng: 21.8635589 },
    { lat: 48.5913717, lng: 21.8631903 },
    { lat: 48.5921801, lng: 21.8629163 },
    { lat: 48.5929047, lng: 21.8624344 },
    { lat: 48.593329, lng: 21.8622237 },
    { lat: 48.5937513, lng: 21.8621077 },
    { lat: 48.593096, lng: 21.8599866 },
    { lat: 48.592928, lng: 21.8595405 },
    { lat: 48.5928128, lng: 21.8596069 },
    { lat: 48.5924455, lng: 21.8585811 },
    { lat: 48.5918557, lng: 21.8559018 },
    { lat: 48.5910425, lng: 21.8514681 },
    { lat: 48.5907964, lng: 21.8497792 },
    { lat: 48.5902822, lng: 21.8475538 },
    { lat: 48.5899921, lng: 21.8474291 },
    { lat: 48.589772, lng: 21.8467025 },
    { lat: 48.5888327, lng: 21.8422374 },
    { lat: 48.5884226, lng: 21.8422161 },
    { lat: 48.5876846, lng: 21.8390015 },
    { lat: 48.5873289, lng: 21.8376462 },
    { lat: 48.5869507, lng: 21.8370832 },
    { lat: 48.5868296, lng: 21.8368313 },
    { lat: 48.5868381, lng: 21.8366848 },
    { lat: 48.5865436, lng: 21.8361758 },
    { lat: 48.586121, lng: 21.835672 },
    { lat: 48.5859398, lng: 21.8353199 },
    { lat: 48.5857591, lng: 21.8347698 },
    { lat: 48.5853481, lng: 21.8342397 },
    { lat: 48.5851188, lng: 21.8336456 },
    { lat: 48.584214, lng: 21.8328103 },
    { lat: 48.5839914, lng: 21.8327268 },
    { lat: 48.5836216, lng: 21.8321758 },
    { lat: 48.5834747, lng: 21.8318515 },
    { lat: 48.5831268, lng: 21.8314535 },
    { lat: 48.5829751, lng: 21.8311938 },
    { lat: 48.5825768, lng: 21.8292951 },
    { lat: 48.5825081, lng: 21.8283429 },
    { lat: 48.5821959, lng: 21.8270257 },
    { lat: 48.5821676, lng: 21.8267098 },
    { lat: 48.5818267, lng: 21.825024 },
    { lat: 48.5812493, lng: 21.8232045 },
    { lat: 48.5810918, lng: 21.8230319 },
    { lat: 48.5807732, lng: 21.822159 },
    { lat: 48.5805198, lng: 21.8210338 },
    { lat: 48.5794326, lng: 21.8175679 },
    { lat: 48.5792224, lng: 21.8167138 },
    { lat: 48.5792068, lng: 21.8166767 },
    { lat: 48.5798678, lng: 21.8164847 },
    { lat: 48.5800131, lng: 21.8165343 },
    { lat: 48.5801435, lng: 21.8166971 },
    { lat: 48.5806482, lng: 21.8165503 },
    { lat: 48.581623, lng: 21.8167024 },
    { lat: 48.5819078, lng: 21.8165757 },
    { lat: 48.582053, lng: 21.8166282 },
    { lat: 48.5821495, lng: 21.8167838 },
    { lat: 48.5823176, lng: 21.8166303 },
    { lat: 48.5826842, lng: 21.816622 },
    { lat: 48.5828221, lng: 21.8165222 },
    { lat: 48.5829535, lng: 21.8165538 },
    { lat: 48.5831513, lng: 21.8167151 },
    { lat: 48.5832932, lng: 21.8166769 },
    { lat: 48.5834792, lng: 21.8169142 },
    { lat: 48.5837767, lng: 21.8169317 },
    { lat: 48.5842167, lng: 21.8171487 },
    { lat: 48.5842927, lng: 21.8173421 },
    { lat: 48.5843467, lng: 21.8173417 },
    { lat: 48.5848795, lng: 21.8169828 },
    { lat: 48.5852919, lng: 21.8168322 },
    { lat: 48.5853672, lng: 21.8166622 },
    { lat: 48.5856204, lng: 21.8164019 },
    { lat: 48.5857725, lng: 21.8164117 },
    { lat: 48.5858226, lng: 21.8162638 },
    { lat: 48.5859096, lng: 21.8162366 },
    { lat: 48.5860021, lng: 21.8163302 },
    { lat: 48.5860116, lng: 21.8168194 },
    { lat: 48.5861522, lng: 21.8169507 },
    { lat: 48.5864032, lng: 21.8169088 },
    { lat: 48.5870026, lng: 21.8165516 },
    { lat: 48.5875745, lng: 21.8163549 },
    { lat: 48.5877231, lng: 21.8163571 },
    { lat: 48.588053, lng: 21.8160703 },
    { lat: 48.5882995, lng: 21.8159401 },
    { lat: 48.5887244, lng: 21.8151484 },
    { lat: 48.5894425, lng: 21.8144735 },
    { lat: 48.5893415, lng: 21.81345 },
    { lat: 48.5894243, lng: 21.8133079 },
    { lat: 48.5896651, lng: 21.813139 },
    { lat: 48.5897214, lng: 21.8129672 },
    { lat: 48.5898326, lng: 21.8128277 },
    { lat: 48.5903085, lng: 21.8125716 },
    { lat: 48.5903954, lng: 21.8125771 },
    { lat: 48.5907328, lng: 21.8121025 },
    { lat: 48.5909901, lng: 21.8120968 },
    { lat: 48.5910552, lng: 21.8121679 },
    { lat: 48.5914022, lng: 21.8122777 },
    { lat: 48.5920021, lng: 21.8122016 },
    { lat: 48.5923161, lng: 21.8124594 },
    { lat: 48.5925152, lng: 21.8123758 },
    { lat: 48.5930633, lng: 21.8117788 },
    { lat: 48.5935169, lng: 21.8120515 },
    { lat: 48.5936626, lng: 21.8120225 },
    { lat: 48.5941753, lng: 21.8115418 },
    { lat: 48.5944976, lng: 21.8114273 },
    { lat: 48.5947203, lng: 21.8111241 },
    { lat: 48.5947526, lng: 21.8110703 },
    { lat: 48.594711, lng: 21.810717 },
    { lat: 48.594673, lng: 21.810522 },
    { lat: 48.594651, lng: 21.808658 },
    { lat: 48.594644, lng: 21.808652 },
    { lat: 48.594645, lng: 21.808462 },
    { lat: 48.594626, lng: 21.808269 },
    { lat: 48.594564, lng: 21.807651 },
    { lat: 48.594487, lng: 21.807046 },
    { lat: 48.594497, lng: 21.806948 },
    { lat: 48.59451, lng: 21.806827 },
    { lat: 48.594517, lng: 21.806768 },
    { lat: 48.594697, lng: 21.805417 },
    { lat: 48.594677, lng: 21.805237 },
    { lat: 48.594765, lng: 21.801985 },
    { lat: 48.594822, lng: 21.799834 },
    { lat: 48.594829, lng: 21.79958 },
    { lat: 48.594831, lng: 21.799517 },
    { lat: 48.594837, lng: 21.799341 },
    { lat: 48.594812, lng: 21.799195 },
    { lat: 48.594801, lng: 21.799126 },
    { lat: 48.594775, lng: 21.798967 },
    { lat: 48.594646, lng: 21.798179 },
    { lat: 48.59465, lng: 21.797947 },
    { lat: 48.594236, lng: 21.798273 },
    { lat: 48.593641, lng: 21.798478 },
    { lat: 48.592545, lng: 21.799186 },
    { lat: 48.591812, lng: 21.799456 },
    { lat: 48.591295, lng: 21.799858 },
    { lat: 48.5902125, lng: 21.7997329 },
    { lat: 48.589954, lng: 21.799703 },
    { lat: 48.5899225, lng: 21.799728 },
    { lat: 48.589764, lng: 21.799854 },
    { lat: 48.589127, lng: 21.799908 },
    { lat: 48.588529, lng: 21.799947 },
    { lat: 48.5881494, lng: 21.7999774 },
    { lat: 48.587307, lng: 21.800045 },
    { lat: 48.5865656, lng: 21.8001134 },
    { lat: 48.586115, lng: 21.800155 },
    { lat: 48.584881, lng: 21.800262 },
    { lat: 48.5839433, lng: 21.8003458 },
    { lat: 48.582744, lng: 21.800453 },
    { lat: 48.581586, lng: 21.800556 },
    { lat: 48.580463, lng: 21.800654 },
    { lat: 48.579342, lng: 21.800752 },
    { lat: 48.578278, lng: 21.800845 },
    { lat: 48.577615, lng: 21.800902 },
    { lat: 48.576979, lng: 21.800959 },
    { lat: 48.576488, lng: 21.801002 },
    { lat: 48.575815, lng: 21.80106 },
    { lat: 48.575291, lng: 21.801105 },
    { lat: 48.574391, lng: 21.801182 },
    { lat: 48.572876, lng: 21.801326 },
    { lat: 48.570765, lng: 21.80153 },
    { lat: 48.569737, lng: 21.801563 },
    { lat: 48.5691993, lng: 21.8016018 },
    { lat: 48.568212, lng: 21.801673 },
    { lat: 48.567685, lng: 21.801695 },
    { lat: 48.567171, lng: 21.801718 },
    { lat: 48.566776, lng: 21.801735 },
    { lat: 48.566265, lng: 21.80181 },
    { lat: 48.565652, lng: 21.8019 },
    { lat: 48.565224, lng: 21.801963 },
    { lat: 48.565248, lng: 21.802319 },
    { lat: 48.565302, lng: 21.802559 },
    { lat: 48.565463, lng: 21.802716 },
    { lat: 48.565875, lng: 21.803116 },
    { lat: 48.565924, lng: 21.803214 },
    { lat: 48.565944, lng: 21.803531 },
    { lat: 48.565947, lng: 21.803589 },
    { lat: 48.565949, lng: 21.803612 },
    { lat: 48.565963, lng: 21.80372 },
    { lat: 48.566035, lng: 21.804277 },
    { lat: 48.566056, lng: 21.804445 },
    { lat: 48.566254, lng: 21.804778 },
    { lat: 48.566484, lng: 21.804962 },
    { lat: 48.566951, lng: 21.805022 },
    { lat: 48.567079, lng: 21.805081 },
    { lat: 48.567143, lng: 21.805173 },
    { lat: 48.567171, lng: 21.805313 },
    { lat: 48.567111, lng: 21.806246 },
    { lat: 48.567117, lng: 21.806541 },
    { lat: 48.567312, lng: 21.806926 },
    { lat: 48.567431, lng: 21.807314 },
    { lat: 48.567441, lng: 21.807484 },
    { lat: 48.567344, lng: 21.807844 },
    { lat: 48.5671, lng: 21.808166 },
    { lat: 48.566989, lng: 21.808237 },
    { lat: 48.566883, lng: 21.808254 },
    { lat: 48.566472, lng: 21.808165 },
    { lat: 48.566201, lng: 21.808229 },
    { lat: 48.566088, lng: 21.808371 },
    { lat: 48.566011, lng: 21.808607 },
    { lat: 48.566008, lng: 21.808832 },
    { lat: 48.566246, lng: 21.809554 },
    { lat: 48.566423, lng: 21.810398 },
    { lat: 48.566415, lng: 21.81069 },
    { lat: 48.566392, lng: 21.811171 },
    { lat: 48.566257, lng: 21.812018 },
    { lat: 48.566164, lng: 21.812323 },
    { lat: 48.566003, lng: 21.812565 },
    { lat: 48.565615, lng: 21.812882 },
    { lat: 48.565216, lng: 21.812933 },
    { lat: 48.565163, lng: 21.812939 },
    { lat: 48.564416, lng: 21.813403 },
    { lat: 48.564111, lng: 21.813462 },
    { lat: 48.564105, lng: 21.813378 },
    { lat: 48.564082, lng: 21.812992 },
    { lat: 48.564051, lng: 21.812474 },
    { lat: 48.564046, lng: 21.812392 },
    { lat: 48.56404, lng: 21.81229 },
    { lat: 48.564038, lng: 21.812251 },
    { lat: 48.564034, lng: 21.81219 },
    { lat: 48.56403, lng: 21.812131 },
    { lat: 48.564027, lng: 21.812073 },
    { lat: 48.564018, lng: 21.811913 },
    { lat: 48.563964, lng: 21.811042 },
    { lat: 48.563922, lng: 21.810336 },
    { lat: 48.563859, lng: 21.809306 },
    { lat: 48.563787, lng: 21.808097 },
    { lat: 48.563716, lng: 21.806924 },
    { lat: 48.563652, lng: 21.805878 },
    { lat: 48.563167, lng: 21.805947 },
    { lat: 48.562946, lng: 21.805978 },
    { lat: 48.562496, lng: 21.806042 },
    { lat: 48.562128, lng: 21.806094 },
    { lat: 48.561786, lng: 21.806142 },
    { lat: 48.561545, lng: 21.806177 },
    { lat: 48.561172, lng: 21.80623 },
    { lat: 48.560907, lng: 21.806267 },
    { lat: 48.560387, lng: 21.806341 },
    { lat: 48.560003, lng: 21.806396 },
    { lat: 48.559524, lng: 21.806463 },
    { lat: 48.558746, lng: 21.806575 },
    { lat: 48.558217, lng: 21.80665 },
    { lat: 48.557745, lng: 21.806718 },
    { lat: 48.556394, lng: 21.806896 },
    { lat: 48.555537, lng: 21.807045 },
    { lat: 48.554366, lng: 21.807274 },
    { lat: 48.553203, lng: 21.807508 },
    { lat: 48.552348, lng: 21.807685 },
    { lat: 48.55184, lng: 21.807772 },
    { lat: 48.550854, lng: 21.807942 },
    { lat: 48.549476, lng: 21.808159 },
    { lat: 48.549382, lng: 21.808177 },
    { lat: 48.546462, lng: 21.808606 },
    { lat: 48.546386, lng: 21.808618 },
    { lat: 48.545736, lng: 21.808714 },
    { lat: 48.543558, lng: 21.809034 },
    { lat: 48.543501, lng: 21.809037 },
    { lat: 48.543452, lng: 21.809045 },
    { lat: 48.541759, lng: 21.809299 },
    { lat: 48.541647, lng: 21.809315 },
    { lat: 48.54158, lng: 21.809326 },
    { lat: 48.540463, lng: 21.80949 },
    { lat: 48.53965, lng: 21.80961 },
    { lat: 48.538445, lng: 21.809787 },
    { lat: 48.536249, lng: 21.812914 },
    { lat: 48.535284, lng: 21.814287 },
    { lat: 48.535258, lng: 21.81434 },
    { lat: 48.535202, lng: 21.814456 },
    { lat: 48.534971, lng: 21.815548 },
    { lat: 48.534951, lng: 21.815751 },
    { lat: 48.534957, lng: 21.815873 },
    { lat: 48.534971, lng: 21.816346 },
    { lat: 48.534927, lng: 21.817346 },
    { lat: 48.534881, lng: 21.817676 },
    { lat: 48.534879, lng: 21.817689 },
    { lat: 48.534872, lng: 21.817734 },
    { lat: 48.534852, lng: 21.817879 },
    { lat: 48.534852, lng: 21.817893 },
    { lat: 48.534847, lng: 21.81794 },
    { lat: 48.534844, lng: 21.817966 },
    { lat: 48.534745, lng: 21.819155 },
    { lat: 48.534633, lng: 21.819702 },
    { lat: 48.534609, lng: 21.819909 },
    { lat: 48.534606, lng: 21.820151 },
    { lat: 48.534684, lng: 21.820475 },
    { lat: 48.534762, lng: 21.820862 },
    { lat: 48.534823, lng: 21.82126 },
    { lat: 48.534989, lng: 21.821725 },
    { lat: 48.535062, lng: 21.822161 },
    { lat: 48.53511, lng: 21.822585 },
    { lat: 48.535187, lng: 21.822897 },
    { lat: 48.535245, lng: 21.822962 },
    { lat: 48.535381, lng: 21.823011 },
    { lat: 48.535462, lng: 21.82297 },
    { lat: 48.536081, lng: 21.822439 },
    { lat: 48.536413, lng: 21.822059 },
    { lat: 48.536603, lng: 21.822022 },
    { lat: 48.53697, lng: 21.822214 },
    { lat: 48.537319, lng: 21.822535 },
    { lat: 48.537615, lng: 21.822731 },
    { lat: 48.537828, lng: 21.822929 },
    { lat: 48.537881, lng: 21.823049 },
    { lat: 48.537954, lng: 21.823216 },
    { lat: 48.537912, lng: 21.823523 },
    { lat: 48.537809, lng: 21.823643 },
    { lat: 48.537504, lng: 21.823704 },
    { lat: 48.537446, lng: 21.823752 },
    { lat: 48.537329, lng: 21.823969 },
    { lat: 48.537299, lng: 21.824153 },
    { lat: 48.537326, lng: 21.824265 },
    { lat: 48.537391, lng: 21.824426 },
    { lat: 48.537514, lng: 21.824609 },
    { lat: 48.537601, lng: 21.824861 },
    { lat: 48.53758, lng: 21.82501 },
    { lat: 48.537527, lng: 21.825121 },
    { lat: 48.537512, lng: 21.825128 },
    { lat: 48.537417, lng: 21.825174 },
    { lat: 48.537345, lng: 21.825186 },
    { lat: 48.537149, lng: 21.825083 },
    { lat: 48.53693, lng: 21.825156 },
    { lat: 48.536753, lng: 21.8253 },
    { lat: 48.536571, lng: 21.825583 },
    { lat: 48.536262, lng: 21.825753 },
    { lat: 48.536121, lng: 21.825928 },
    { lat: 48.535942, lng: 21.8263 },
    { lat: 48.535778, lng: 21.826534 },
    { lat: 48.535584, lng: 21.827031 },
    { lat: 48.535444, lng: 21.827304 },
    { lat: 48.53534, lng: 21.827576 },
    { lat: 48.535207, lng: 21.827729 },
    { lat: 48.535016, lng: 21.827816 },
    { lat: 48.534733, lng: 21.827857 },
    { lat: 48.534672, lng: 21.827912 },
    { lat: 48.53445, lng: 21.828381 },
    { lat: 48.534257, lng: 21.82847 },
    { lat: 48.534065, lng: 21.828314 },
    { lat: 48.534031, lng: 21.828272 },
    { lat: 48.533993, lng: 21.82818 },
    { lat: 48.53389, lng: 21.827717 },
    { lat: 48.533791, lng: 21.82765 },
    { lat: 48.533651, lng: 21.827728 },
    { lat: 48.533349, lng: 21.827818 },
    { lat: 48.533209, lng: 21.827871 },
    { lat: 48.532996, lng: 21.827953 },
    { lat: 48.532827, lng: 21.827974 },
    { lat: 48.532679, lng: 21.827947 },
    { lat: 48.532429, lng: 21.828121 },
    { lat: 48.532273, lng: 21.828108 },
    { lat: 48.532135, lng: 21.828008 },
    { lat: 48.532032, lng: 21.827994 },
    { lat: 48.53189, lng: 21.828024 },
    { lat: 48.531786, lng: 21.828168 },
    { lat: 48.5318, lng: 21.82824 },
    { lat: 48.531805, lng: 21.828268 },
    { lat: 48.531825, lng: 21.828342 },
    { lat: 48.532142, lng: 21.828932 },
    { lat: 48.533076, lng: 21.830638 },
    { lat: 48.533743, lng: 21.831868 },
    { lat: 48.534283, lng: 21.832881 },
    { lat: 48.534303, lng: 21.832919 },
    { lat: 48.534613, lng: 21.833479 },
    { lat: 48.535126, lng: 21.834437 },
    { lat: 48.534761, lng: 21.835116 },
    { lat: 48.534694, lng: 21.835244 },
    { lat: 48.534664, lng: 21.835299 },
    { lat: 48.5348969, lng: 21.8358229 },
    { lat: 48.5350074, lng: 21.8367013 },
    { lat: 48.5351238, lng: 21.8369352 },
    { lat: 48.5353503, lng: 21.8371678 },
    { lat: 48.535815, lng: 21.8372945 },
    { lat: 48.5362569, lng: 21.8371982 },
    { lat: 48.5366084, lng: 21.8369162 },
    { lat: 48.5372354, lng: 21.8364043 },
    { lat: 48.5375111, lng: 21.8360643 },
    { lat: 48.5377385, lng: 21.8356331 },
    { lat: 48.5377764, lng: 21.835667 },
    { lat: 48.5383563, lng: 21.835247 },
    { lat: 48.5385515, lng: 21.8352298 },
    { lat: 48.5390483, lng: 21.8354316 },
    { lat: 48.5396464, lng: 21.8361029 },
    { lat: 48.5399206, lng: 21.83614 },
    { lat: 48.5400506, lng: 21.8360361 },
    { lat: 48.5401283, lng: 21.835859 },
    { lat: 48.5401383, lng: 21.8356823 },
    { lat: 48.5401045, lng: 21.8355043 },
    { lat: 48.5399323, lng: 21.8352844 },
    { lat: 48.5398584, lng: 21.8349546 },
    { lat: 48.539995, lng: 21.8345394 },
    { lat: 48.5404957, lng: 21.8345393 },
    { lat: 48.5411339, lng: 21.8341229 },
    { lat: 48.5413456, lng: 21.8341296 },
    { lat: 48.5418989, lng: 21.8343377 },
    { lat: 48.5420442, lng: 21.8346501 },
    { lat: 48.5421063, lng: 21.8349972 },
    { lat: 48.5421191, lng: 21.8354448 },
    { lat: 48.5422024, lng: 21.8357573 },
    { lat: 48.5422943, lng: 21.8358737 },
    { lat: 48.5424436, lng: 21.8359011 },
    { lat: 48.5425917, lng: 21.8357958 },
    { lat: 48.5428526, lng: 21.8344945 },
    { lat: 48.5429896, lng: 21.8342709 },
    { lat: 48.5431129, lng: 21.8342501 },
    { lat: 48.5432294, lng: 21.8343622 },
    { lat: 48.5432652, lng: 21.835051 },
    { lat: 48.5433983, lng: 21.8354067 },
    { lat: 48.5435212, lng: 21.8355025 },
    { lat: 48.5436595, lng: 21.8354979 },
    { lat: 48.5438044, lng: 21.8354041 },
    { lat: 48.5441436, lng: 21.8345117 },
    { lat: 48.5444665, lng: 21.8345648 },
    { lat: 48.5446323, lng: 21.8346651 },
    { lat: 48.5450307, lng: 21.8353656 },
    { lat: 48.5477127, lng: 21.8382633 },
    { lat: 48.5487236, lng: 21.8378462 },
    { lat: 48.5500034, lng: 21.8376146 },
    { lat: 48.5512066, lng: 21.8375455 },
    { lat: 48.5514451, lng: 21.8379789 },
    { lat: 48.5516339, lng: 21.8386666 },
    { lat: 48.5523234, lng: 21.8407386 },
    { lat: 48.5525209, lng: 21.8409354 },
    { lat: 48.5531172, lng: 21.8412029 },
    { lat: 48.5534125, lng: 21.8414341 },
    { lat: 48.5538673, lng: 21.8425704 },
    { lat: 48.5544851, lng: 21.8428426 },
    { lat: 48.5547146, lng: 21.8431456 },
    { lat: 48.5547177, lng: 21.8433943 },
    { lat: 48.5544101, lng: 21.8442047 },
    { lat: 48.5544327, lng: 21.8448117 },
    { lat: 48.5543437, lng: 21.8458102 },
    { lat: 48.5541965, lng: 21.8462997 },
    { lat: 48.5544645, lng: 21.8462619 },
    { lat: 48.5550753, lng: 21.8459136 },
    { lat: 48.5559129, lng: 21.8455856 },
    { lat: 48.5564449, lng: 21.8471848 },
    { lat: 48.5565843, lng: 21.8477519 },
    { lat: 48.5569696, lng: 21.8477279 },
    { lat: 48.5573391, lng: 21.8479207 },
    { lat: 48.5577319, lng: 21.8484407 },
    { lat: 48.5584236, lng: 21.8500487 },
    { lat: 48.5587968, lng: 21.8512524 },
    { lat: 48.5589214, lng: 21.8517665 },
    { lat: 48.5589914, lng: 21.8528598 },
    { lat: 48.5591301, lng: 21.8533109 },
    { lat: 48.5592711, lng: 21.8535413 },
    { lat: 48.5598608, lng: 21.8539461 },
    { lat: 48.5602496, lng: 21.8544522 },
    { lat: 48.560357, lng: 21.8546831 },
    { lat: 48.5606186, lng: 21.8558402 },
    { lat: 48.5605278, lng: 21.8565981 },
    { lat: 48.5608736, lng: 21.8568698 },
    { lat: 48.5605528, lng: 21.8606704 },
    { lat: 48.5607363, lng: 21.8640341 },
    { lat: 48.5610143, lng: 21.8665741 },
    { lat: 48.5608057, lng: 21.8666036 },
    { lat: 48.5581769, lng: 21.8682838 },
    { lat: 48.558495, lng: 21.8694536 },
    { lat: 48.5575735, lng: 21.8701057 },
    { lat: 48.556398, lng: 21.8708109 },
    { lat: 48.5565174, lng: 21.8713491 },
    { lat: 48.5568087, lng: 21.8732297 },
    { lat: 48.5568907, lng: 21.8737061 },
    { lat: 48.5570359, lng: 21.874192 },
    { lat: 48.5576585, lng: 21.8756985 },
    { lat: 48.5576842, lng: 21.8757833 },
    { lat: 48.5582002, lng: 21.8753758 },
    { lat: 48.5591103, lng: 21.8748008 },
    { lat: 48.5608481, lng: 21.873425 },
    { lat: 48.5612144, lng: 21.8743697 },
    { lat: 48.5624637, lng: 21.8733412 },
    { lat: 48.5626076, lng: 21.8732448 },
    { lat: 48.562661, lng: 21.8733976 },
    { lat: 48.5642376, lng: 21.8723191 },
    { lat: 48.5644449, lng: 21.8728171 },
    { lat: 48.5660876, lng: 21.8715035 },
    { lat: 48.566147, lng: 21.8716677 },
    { lat: 48.5669178, lng: 21.8714636 },
    { lat: 48.566631, lng: 21.8762028 },
    { lat: 48.5681229, lng: 21.8755065 },
    { lat: 48.5693788, lng: 21.8747716 },
    { lat: 48.5695988, lng: 21.8717209 },
    { lat: 48.569732, lng: 21.8716098 },
    { lat: 48.5706771, lng: 21.8709569 },
    { lat: 48.5712055, lng: 21.870724 },
    { lat: 48.5715869, lng: 21.870723 },
    { lat: 48.5734493, lng: 21.8713101 },
    { lat: 48.5738667, lng: 21.8714417 },
  ],
  Dúbravka: [
    { lat: 48.6433488, lng: 21.8588583 },
    { lat: 48.64333, lng: 21.8588697 },
    { lat: 48.6408745, lng: 21.8602483 },
    { lat: 48.6395933, lng: 21.861087 },
    { lat: 48.6393143, lng: 21.860685 },
    { lat: 48.6391885, lng: 21.8605707 },
    { lat: 48.6391115, lng: 21.8605927 },
    { lat: 48.6383975, lng: 21.8609973 },
    { lat: 48.6359085, lng: 21.8621638 },
    { lat: 48.6355979, lng: 21.8624271 },
    { lat: 48.6340684, lng: 21.8631436 },
    { lat: 48.6337675, lng: 21.8617271 },
    { lat: 48.6322791, lng: 21.8624654 },
    { lat: 48.6320249, lng: 21.8613688 },
    { lat: 48.6318457, lng: 21.8614626 },
    { lat: 48.6307704, lng: 21.8620252 },
    { lat: 48.6304483, lng: 21.8606139 },
    { lat: 48.6297827, lng: 21.8608929 },
    { lat: 48.6297327, lng: 21.8607468 },
    { lat: 48.6279075, lng: 21.8616428 },
    { lat: 48.6273356, lng: 21.8595265 },
    { lat: 48.6269195, lng: 21.8598823 },
    { lat: 48.6250981, lng: 21.8610653 },
    { lat: 48.6250717, lng: 21.86092 },
    { lat: 48.6238399, lng: 21.8617055 },
    { lat: 48.6234317, lng: 21.8603183 },
    { lat: 48.6230648, lng: 21.8594351 },
    { lat: 48.6227857, lng: 21.8586005 },
    { lat: 48.6221065, lng: 21.8555526 },
    { lat: 48.621371, lng: 21.8559129 },
    { lat: 48.6203867, lng: 21.8563952 },
    { lat: 48.6201116, lng: 21.8554174 },
    { lat: 48.6171831, lng: 21.8568058 },
    { lat: 48.6164164, lng: 21.8569613 },
    { lat: 48.6163035, lng: 21.8570355 },
    { lat: 48.6157858, lng: 21.8569435 },
    { lat: 48.6151055, lng: 21.8570818 },
    { lat: 48.6150139, lng: 21.8568501 },
    { lat: 48.613701, lng: 21.8576807 },
    { lat: 48.6128854, lng: 21.858116 },
    { lat: 48.6115192, lng: 21.8590187 },
    { lat: 48.6116655, lng: 21.859408 },
    { lat: 48.6110641, lng: 21.8604344 },
    { lat: 48.6111521, lng: 21.860704 },
    { lat: 48.6114579, lng: 21.8620531 },
    { lat: 48.6120313, lng: 21.8640991 },
    { lat: 48.6122663, lng: 21.8653194 },
    { lat: 48.6120148, lng: 21.8663935 },
    { lat: 48.612179, lng: 21.8684464 },
    { lat: 48.6121178, lng: 21.8688286 },
    { lat: 48.6122009, lng: 21.8697417 },
    { lat: 48.6125638, lng: 21.87196 },
    { lat: 48.6126734, lng: 21.8721979 },
    { lat: 48.6129479, lng: 21.8725047 },
    { lat: 48.6116413, lng: 21.8732734 },
    { lat: 48.6116708, lng: 21.8750432 },
    { lat: 48.6112747, lng: 21.8752615 },
    { lat: 48.6109559, lng: 21.8756846 },
    { lat: 48.6104987, lng: 21.8767664 },
    { lat: 48.6099437, lng: 21.8775836 },
    { lat: 48.6095218, lng: 21.8785567 },
    { lat: 48.609461, lng: 21.8790832 },
    { lat: 48.6093808, lng: 21.8809133 },
    { lat: 48.6096196, lng: 21.8823809 },
    { lat: 48.6099015, lng: 21.8830638 },
    { lat: 48.6104283, lng: 21.8837797 },
    { lat: 48.6120371, lng: 21.8856769 },
    { lat: 48.6123313, lng: 21.886357 },
    { lat: 48.6130173, lng: 21.8886065 },
    { lat: 48.6133623, lng: 21.8894216 },
    { lat: 48.6137297, lng: 21.890777 },
    { lat: 48.6142205, lng: 21.8906558 },
    { lat: 48.6149769, lng: 21.890357 },
    { lat: 48.6165204, lng: 21.8889926 },
    { lat: 48.6167874, lng: 21.8894962 },
    { lat: 48.6175532, lng: 21.8915554 },
    { lat: 48.617972, lng: 21.8910426 },
    { lat: 48.6181757, lng: 21.8909042 },
    { lat: 48.6185318, lng: 21.8907979 },
    { lat: 48.6186037, lng: 21.8910184 },
    { lat: 48.6222745, lng: 21.8905285 },
    { lat: 48.6231498, lng: 21.8935743 },
    { lat: 48.6246905, lng: 21.8928038 },
    { lat: 48.6256448, lng: 21.8964829 },
    { lat: 48.6297524, lng: 21.8952126 },
    { lat: 48.6319401, lng: 21.8949363 },
    { lat: 48.6340431, lng: 21.8945418 },
    { lat: 48.6348883, lng: 21.8970406 },
    { lat: 48.6390012, lng: 21.8947362 },
    { lat: 48.6391536, lng: 21.895549 },
    { lat: 48.6391446, lng: 21.895991 },
    { lat: 48.6395896, lng: 21.8984111 },
    { lat: 48.639643, lng: 21.8991401 },
    { lat: 48.6395423, lng: 21.9000939 },
    { lat: 48.6395221, lng: 21.9007115 },
    { lat: 48.6419972, lng: 21.8994288 },
    { lat: 48.6423948, lng: 21.900865 },
    { lat: 48.6423272, lng: 21.9010923 },
    { lat: 48.6427218, lng: 21.9015661 },
    { lat: 48.642929, lng: 21.9017953 },
    { lat: 48.643157, lng: 21.9019095 },
    { lat: 48.6434573, lng: 21.9019068 },
    { lat: 48.6435449, lng: 21.901822 },
    { lat: 48.6437335, lng: 21.9014497 },
    { lat: 48.6437992, lng: 21.901066 },
    { lat: 48.6437783, lng: 21.9002426 },
    { lat: 48.6436618, lng: 21.8994578 },
    { lat: 48.6437028, lng: 21.8993491 },
    { lat: 48.6443878, lng: 21.8987218 },
    { lat: 48.6448853, lng: 21.8980648 },
    { lat: 48.6453125, lng: 21.8968608 },
    { lat: 48.6454717, lng: 21.8964837 },
    { lat: 48.6455863, lng: 21.896344 },
    { lat: 48.6464208, lng: 21.8959368 },
    { lat: 48.6468133, lng: 21.895934 },
    { lat: 48.6474685, lng: 21.8957726 },
    { lat: 48.6477836, lng: 21.8955545 },
    { lat: 48.6478989, lng: 21.8952678 },
    { lat: 48.6479161, lng: 21.8951017 },
    { lat: 48.6478586, lng: 21.8948329 },
    { lat: 48.6471782, lng: 21.8928448 },
    { lat: 48.6468569, lng: 21.8923361 },
    { lat: 48.6467875, lng: 21.8917766 },
    { lat: 48.6468544, lng: 21.8915337 },
    { lat: 48.6471554, lng: 21.8912459 },
    { lat: 48.6475208, lng: 21.8910119 },
    { lat: 48.6479655, lng: 21.8899711 },
    { lat: 48.6485114, lng: 21.8894478 },
    { lat: 48.648727, lng: 21.8891678 },
    { lat: 48.649153, lng: 21.8881867 },
    { lat: 48.649252, lng: 21.8877398 },
    { lat: 48.6492833, lng: 21.8868959 },
    { lat: 48.6491764, lng: 21.8860041 },
    { lat: 48.6492205, lng: 21.8851839 },
    { lat: 48.6491659, lng: 21.8840948 },
    { lat: 48.6490653, lng: 21.8833333 },
    { lat: 48.649062, lng: 21.8827589 },
    { lat: 48.6491209, lng: 21.8824256 },
    { lat: 48.6492627, lng: 21.8820314 },
    { lat: 48.649441, lng: 21.8818691 },
    { lat: 48.6494782, lng: 21.8816075 },
    { lat: 48.6494591, lng: 21.8813016 },
    { lat: 48.6492486, lng: 21.8811644 },
    { lat: 48.6490657, lng: 21.8783231 },
    { lat: 48.6488092, lng: 21.8779737 },
    { lat: 48.6484748, lng: 21.877166 },
    { lat: 48.6479169, lng: 21.8764229 },
    { lat: 48.6478831, lng: 21.8762459 },
    { lat: 48.647617, lng: 21.876397 },
    { lat: 48.6464676, lng: 21.8739024 },
    { lat: 48.6448811, lng: 21.8650757 },
    { lat: 48.644667, lng: 21.8651936 },
    { lat: 48.6443946, lng: 21.8647488 },
    { lat: 48.6446626, lng: 21.8643617 },
    { lat: 48.6444281, lng: 21.8641243 },
    { lat: 48.6440118, lng: 21.861933 },
    { lat: 48.643919, lng: 21.8615229 },
    { lat: 48.6433488, lng: 21.8588583 },
  ],
  Strážske: [
    { lat: 48.895867, lng: 21.78715 },
    { lat: 48.895849, lng: 21.787029 },
    { lat: 48.89482, lng: 21.786323 },
    { lat: 48.894722, lng: 21.78627 },
    { lat: 48.89458, lng: 21.786164 },
    { lat: 48.894373, lng: 21.786002 },
    { lat: 48.894031, lng: 21.785778 },
    { lat: 48.893614, lng: 21.785616 },
    { lat: 48.893032, lng: 21.785362 },
    { lat: 48.892831, lng: 21.785319 },
    { lat: 48.892567, lng: 21.785235 },
    { lat: 48.892321, lng: 21.785221 },
    { lat: 48.892177, lng: 21.78518 },
    { lat: 48.892055, lng: 21.785104 },
    { lat: 48.891869, lng: 21.785069 },
    { lat: 48.891645, lng: 21.785078 },
    { lat: 48.891435, lng: 21.78503 },
    { lat: 48.891287, lng: 21.785039 },
    { lat: 48.89128, lng: 21.785077 },
    { lat: 48.891086, lng: 21.785091 },
    { lat: 48.891067, lng: 21.785069 },
    { lat: 48.890905, lng: 21.785169 },
    { lat: 48.890774, lng: 21.785361 },
    { lat: 48.890522, lng: 21.786026 },
    { lat: 48.89052, lng: 21.786166 },
    { lat: 48.890432, lng: 21.786307 },
    { lat: 48.890312, lng: 21.786588 },
    { lat: 48.890296, lng: 21.786576 },
    { lat: 48.89, lng: 21.786309 },
    { lat: 48.889976, lng: 21.786283 },
    { lat: 48.889828, lng: 21.78634 },
    { lat: 48.889616, lng: 21.786325 },
    { lat: 48.889173, lng: 21.786371 },
    { lat: 48.888942, lng: 21.786311 },
    { lat: 48.888618, lng: 21.78636 },
    { lat: 48.8883, lng: 21.786473 },
    { lat: 48.888153, lng: 21.786581 },
    { lat: 48.887805, lng: 21.786809 },
    { lat: 48.887718, lng: 21.786826 },
    { lat: 48.887608, lng: 21.786787 },
    { lat: 48.887424, lng: 21.786787 },
    { lat: 48.887325, lng: 21.786831 },
    { lat: 48.887068, lng: 21.787035 },
    { lat: 48.886934, lng: 21.787284 },
    { lat: 48.886872, lng: 21.787362 },
    { lat: 48.886502, lng: 21.787419 },
    { lat: 48.886284, lng: 21.787352 },
    { lat: 48.886047, lng: 21.787345 },
    { lat: 48.885882, lng: 21.78747 },
    { lat: 48.885746, lng: 21.787525 },
    { lat: 48.885469, lng: 21.787494 },
    { lat: 48.88531, lng: 21.787565 },
    { lat: 48.88512, lng: 21.787709 },
    { lat: 48.884862, lng: 21.787779 },
    { lat: 48.884684, lng: 21.787776 },
    { lat: 48.884562, lng: 21.787831 },
    { lat: 48.884437, lng: 21.787902 },
    { lat: 48.88379, lng: 21.788189 },
    { lat: 48.883489, lng: 21.788164 },
    { lat: 48.88317, lng: 21.788089 },
    { lat: 48.882988, lng: 21.788097 },
    { lat: 48.882755, lng: 21.788133 },
    { lat: 48.882604, lng: 21.788123 },
    { lat: 48.882272, lng: 21.78809 },
    { lat: 48.882275, lng: 21.788057 },
    { lat: 48.882268, lng: 21.788034 },
    { lat: 48.882248, lng: 21.787834 },
    { lat: 48.882198, lng: 21.787064 },
    { lat: 48.882138, lng: 21.785832 },
    { lat: 48.881909, lng: 21.784432 },
    { lat: 48.881602, lng: 21.783968 },
    { lat: 48.881543, lng: 21.783908 },
    { lat: 48.88152, lng: 21.783831 },
    { lat: 48.881487, lng: 21.783777 },
    { lat: 48.88146, lng: 21.783649 },
    { lat: 48.881253, lng: 21.78342 },
    { lat: 48.88116, lng: 21.783527 },
    { lat: 48.881033, lng: 21.783569 },
    { lat: 48.880844, lng: 21.783727 },
    { lat: 48.880648, lng: 21.783704 },
    { lat: 48.880526, lng: 21.783832 },
    { lat: 48.880442, lng: 21.783827 },
    { lat: 48.880409, lng: 21.783777 },
    { lat: 48.88028, lng: 21.783785 },
    { lat: 48.88004, lng: 21.784029 },
    { lat: 48.879803, lng: 21.784197 },
    { lat: 48.879702, lng: 21.784068 },
    { lat: 48.879602, lng: 21.784066 },
    { lat: 48.8795, lng: 21.784086 },
    { lat: 48.879475, lng: 21.784028 },
    { lat: 48.879368, lng: 21.784118 },
    { lat: 48.879119, lng: 21.78416 },
    { lat: 48.878967, lng: 21.784245 },
    { lat: 48.878964, lng: 21.784338 },
    { lat: 48.87886, lng: 21.7844 },
    { lat: 48.878776, lng: 21.784441 },
    { lat: 48.87874, lng: 21.784554 },
    { lat: 48.87867, lng: 21.784498 },
    { lat: 48.878481, lng: 21.784506 },
    { lat: 48.878425, lng: 21.784575 },
    { lat: 48.878282, lng: 21.784633 },
    { lat: 48.87825, lng: 21.784783 },
    { lat: 48.878153, lng: 21.784877 },
    { lat: 48.878123, lng: 21.784803 },
    { lat: 48.877982, lng: 21.78482 },
    { lat: 48.877919, lng: 21.784736 },
    { lat: 48.877795, lng: 21.784844 },
    { lat: 48.877418, lng: 21.784824 },
    { lat: 48.877361, lng: 21.784969 },
    { lat: 48.877303, lng: 21.78501 },
    { lat: 48.877317, lng: 21.78514 },
    { lat: 48.877246, lng: 21.785119 },
    { lat: 48.877222, lng: 21.78522 },
    { lat: 48.877198, lng: 21.78529 },
    { lat: 48.877155, lng: 21.78533 },
    { lat: 48.877144, lng: 21.785311 },
    { lat: 48.877027, lng: 21.785284 },
    { lat: 48.877002, lng: 21.785457 },
    { lat: 48.876975, lng: 21.785598 },
    { lat: 48.876923, lng: 21.785632 },
    { lat: 48.876844, lng: 21.785762 },
    { lat: 48.87684, lng: 21.785847 },
    { lat: 48.876675, lng: 21.785892 },
    { lat: 48.876636, lng: 21.786032 },
    { lat: 48.876503, lng: 21.786113 },
    { lat: 48.87648, lng: 21.786264 },
    { lat: 48.876275, lng: 21.786319 },
    { lat: 48.876099, lng: 21.786473 },
    { lat: 48.876011, lng: 21.786627 },
    { lat: 48.875948, lng: 21.786765 },
    { lat: 48.875908, lng: 21.786894 },
    { lat: 48.875838, lng: 21.787139 },
    { lat: 48.875617, lng: 21.787597 },
    { lat: 48.875114, lng: 21.788123 },
    { lat: 48.874946, lng: 21.788238 },
    { lat: 48.874821, lng: 21.788281 },
    { lat: 48.874421, lng: 21.788294 },
    { lat: 48.874288, lng: 21.788317 },
    { lat: 48.873975, lng: 21.788436 },
    { lat: 48.873797, lng: 21.788467 },
    { lat: 48.873621, lng: 21.788499 },
    { lat: 48.873426, lng: 21.788494 },
    { lat: 48.87305, lng: 21.788302 },
    { lat: 48.872984, lng: 21.788319 },
    { lat: 48.872896, lng: 21.78834 },
    { lat: 48.872806, lng: 21.788362 },
    { lat: 48.872732, lng: 21.788362 },
    { lat: 48.872684, lng: 21.78833 },
    { lat: 48.872481, lng: 21.788106 },
    { lat: 48.872299, lng: 21.78794 },
    { lat: 48.871995, lng: 21.787782 },
    { lat: 48.871783, lng: 21.787729 },
    { lat: 48.87146, lng: 21.787736 },
    { lat: 48.87126, lng: 21.787668 },
    { lat: 48.871133, lng: 21.787626 },
    { lat: 48.871094, lng: 21.787922 },
    { lat: 48.871087, lng: 21.788687 },
    { lat: 48.871005, lng: 21.789171 },
    { lat: 48.870842, lng: 21.789458 },
    { lat: 48.870685, lng: 21.789888 },
    { lat: 48.870558, lng: 21.790168 },
    { lat: 48.870447, lng: 21.790306 },
    { lat: 48.870204, lng: 21.790717 },
    { lat: 48.870077, lng: 21.791066 },
    { lat: 48.869752, lng: 21.791437 },
    { lat: 48.869668, lng: 21.791515 },
    { lat: 48.869315, lng: 21.791805 },
    { lat: 48.869191, lng: 21.791855 },
    { lat: 48.869146, lng: 21.791887 },
    { lat: 48.869091, lng: 21.791982 },
    { lat: 48.868795, lng: 21.792198 },
    { lat: 48.868674, lng: 21.792213 },
    { lat: 48.868093, lng: 21.792458 },
    { lat: 48.867764, lng: 21.792553 },
    { lat: 48.867454, lng: 21.792701 },
    { lat: 48.867152, lng: 21.792765 },
    { lat: 48.866961, lng: 21.792939 },
    { lat: 48.866809, lng: 21.792856 },
    { lat: 48.866361, lng: 21.792966 },
    { lat: 48.866164, lng: 21.793086 },
    { lat: 48.865513, lng: 21.79331 },
    { lat: 48.865339, lng: 21.793356 },
    { lat: 48.864381, lng: 21.793621 },
    { lat: 48.864206, lng: 21.7946789 },
    { lat: 48.8641571, lng: 21.7947483 },
    { lat: 48.8638041, lng: 21.7948774 },
    { lat: 48.8637578, lng: 21.7949656 },
    { lat: 48.8635888, lng: 21.7949943 },
    { lat: 48.8635277, lng: 21.7950627 },
    { lat: 48.8630118, lng: 21.7950872 },
    { lat: 48.861968, lng: 21.7955201 },
    { lat: 48.8615937, lng: 21.7955759 },
    { lat: 48.861208, lng: 21.7963157 },
    { lat: 48.8612892, lng: 21.7965363 },
    { lat: 48.8612201, lng: 21.7966048 },
    { lat: 48.8612165, lng: 21.7967665 },
    { lat: 48.8611494, lng: 21.7969127 },
    { lat: 48.8611793, lng: 21.7972361 },
    { lat: 48.8607086, lng: 21.7970935 },
    { lat: 48.8602479, lng: 21.7971562 },
    { lat: 48.8598669, lng: 21.7974331 },
    { lat: 48.8589436, lng: 21.7977887 },
    { lat: 48.8563164, lng: 21.798289 },
    { lat: 48.8561924, lng: 21.7985319 },
    { lat: 48.8558808, lng: 21.7987045 },
    { lat: 48.855702, lng: 21.7987117 },
    { lat: 48.8554671, lng: 21.7990241 },
    { lat: 48.8552692, lng: 21.7990277 },
    { lat: 48.854969, lng: 21.7988593 },
    { lat: 48.8548521, lng: 21.7989517 },
    { lat: 48.8544645, lng: 21.7990135 },
    { lat: 48.8543861, lng: 21.7991983 },
    { lat: 48.8540581, lng: 21.798915 },
    { lat: 48.8539085, lng: 21.7989176 },
    { lat: 48.8537525, lng: 21.7992745 },
    { lat: 48.8537481, lng: 21.7995366 },
    { lat: 48.8536543, lng: 21.7996469 },
    { lat: 48.8533675, lng: 21.7997413 },
    { lat: 48.8531134, lng: 21.8000041 },
    { lat: 48.8528001, lng: 21.8002004 },
    { lat: 48.8526853, lng: 21.7999762 },
    { lat: 48.8523035, lng: 21.8000955 },
    { lat: 48.8520266, lng: 21.7997864 },
    { lat: 48.8518096, lng: 21.7999148 },
    { lat: 48.8517342, lng: 21.8001779 },
    { lat: 48.8517517, lng: 21.8005069 },
    { lat: 48.8516513, lng: 21.8005794 },
    { lat: 48.85166, lng: 21.8008664 },
    { lat: 48.8513761, lng: 21.8008088 },
    { lat: 48.8511375, lng: 21.8011518 },
    { lat: 48.8511525, lng: 21.8013827 },
    { lat: 48.8510561, lng: 21.8013949 },
    { lat: 48.851025, lng: 21.8014939 },
    { lat: 48.8509622, lng: 21.8014988 },
    { lat: 48.8509128, lng: 21.8016375 },
    { lat: 48.8509356, lng: 21.8018151 },
    { lat: 48.85085, lng: 21.8020785 },
    { lat: 48.8507767, lng: 21.8020228 },
    { lat: 48.8506987, lng: 21.8024387 },
    { lat: 48.8507444, lng: 21.8027271 },
    { lat: 48.8506865, lng: 21.8032174 },
    { lat: 48.8506204, lng: 21.8034943 },
    { lat: 48.8503818, lng: 21.8040469 },
    { lat: 48.8504375, lng: 21.8042475 },
    { lat: 48.8504108, lng: 21.8043456 },
    { lat: 48.8503505, lng: 21.8043545 },
    { lat: 48.8503399, lng: 21.8045552 },
    { lat: 48.8502639, lng: 21.8047509 },
    { lat: 48.8503688, lng: 21.8048039 },
    { lat: 48.8504395, lng: 21.804965 },
    { lat: 48.8504137, lng: 21.8050977 },
    { lat: 48.8504809, lng: 21.8051337 },
    { lat: 48.8504631, lng: 21.8052265 },
    { lat: 48.8505391, lng: 21.8052941 },
    { lat: 48.850493, lng: 21.8053787 },
    { lat: 48.8504434, lng: 21.8053334 },
    { lat: 48.8503676, lng: 21.8053998 },
    { lat: 48.8504027, lng: 21.8056234 },
    { lat: 48.8505012, lng: 21.8057747 },
    { lat: 48.8504772, lng: 21.8060219 },
    { lat: 48.8503919, lng: 21.8061242 },
    { lat: 48.850272, lng: 21.8065682 },
    { lat: 48.8503744, lng: 21.8067457 },
    { lat: 48.8503395, lng: 21.8069171 },
    { lat: 48.8504299, lng: 21.8070128 },
    { lat: 48.8505407, lng: 21.8072916 },
    { lat: 48.8508323, lng: 21.8076899 },
    { lat: 48.8508042, lng: 21.8079127 },
    { lat: 48.8509434, lng: 21.8080726 },
    { lat: 48.8509791, lng: 21.8081934 },
    { lat: 48.8511083, lng: 21.8080938 },
    { lat: 48.8511192, lng: 21.8082161 },
    { lat: 48.8513358, lng: 21.8082774 },
    { lat: 48.8514697, lng: 21.8081962 },
    { lat: 48.8516558, lng: 21.8082968 },
    { lat: 48.851713, lng: 21.8082477 },
    { lat: 48.8518979, lng: 21.8083513 },
    { lat: 48.8519386, lng: 21.8084471 },
    { lat: 48.8520119, lng: 21.808412 },
    { lat: 48.8523468, lng: 21.8085699 },
    { lat: 48.8523627, lng: 21.8088308 },
    { lat: 48.8524716, lng: 21.8088189 },
    { lat: 48.8525871, lng: 21.8092432 },
    { lat: 48.8527599, lng: 21.8093989 },
    { lat: 48.8528331, lng: 21.8093581 },
    { lat: 48.8528917, lng: 21.8093996 },
    { lat: 48.8528328, lng: 21.8097814 },
    { lat: 48.8529452, lng: 21.8102516 },
    { lat: 48.8529716, lng: 21.8106116 },
    { lat: 48.8530851, lng: 21.8108695 },
    { lat: 48.8532, lng: 21.8109633 },
    { lat: 48.8534789, lng: 21.810912 },
    { lat: 48.8534381, lng: 21.8111199 },
    { lat: 48.8536997, lng: 21.8151685 },
    { lat: 48.8537772, lng: 21.8193217 },
    { lat: 48.8536938, lng: 21.8194863 },
    { lat: 48.8536967, lng: 21.8197708 },
    { lat: 48.8537884, lng: 21.820099 },
    { lat: 48.8539335, lng: 21.8230483 },
    { lat: 48.8536617, lng: 21.8234127 },
    { lat: 48.850708, lng: 21.8266813 },
    { lat: 48.8510051, lng: 21.8287801 },
    { lat: 48.8508463, lng: 21.8288577 },
    { lat: 48.8508952, lng: 21.8296164 },
    { lat: 48.8490845, lng: 21.8304615 },
    { lat: 48.8489954, lng: 21.830455 },
    { lat: 48.8487116, lng: 21.8309195 },
    { lat: 48.848698, lng: 21.8311321 },
    { lat: 48.84883, lng: 21.8314514 },
    { lat: 48.8490833, lng: 21.8317661 },
    { lat: 48.8492876, lng: 21.8319193 },
    { lat: 48.8494733, lng: 21.8327102 },
    { lat: 48.8499402, lng: 21.8329701 },
    { lat: 48.8500289, lng: 21.8324797 },
    { lat: 48.8499058, lng: 21.8318566 },
    { lat: 48.8499802, lng: 21.8315978 },
    { lat: 48.8501083, lng: 21.8313756 },
    { lat: 48.8503108, lng: 21.8313764 },
    { lat: 48.8507576, lng: 21.8316867 },
    { lat: 48.8515852, lng: 21.831459 },
    { lat: 48.8516754, lng: 21.8312429 },
    { lat: 48.8516157, lng: 21.8310682 },
    { lat: 48.8514343, lng: 21.8309293 },
    { lat: 48.8513808, lng: 21.8303816 },
    { lat: 48.851415, lng: 21.8301806 },
    { lat: 48.851547, lng: 21.830071 },
    { lat: 48.8517894, lng: 21.8304767 },
    { lat: 48.851888, lng: 21.8307698 },
    { lat: 48.8520693, lng: 21.8308144 },
    { lat: 48.8524895, lng: 21.8305366 },
    { lat: 48.8525558, lng: 21.8311881 },
    { lat: 48.8526619, lng: 21.8314622 },
    { lat: 48.8528709, lng: 21.8315413 },
    { lat: 48.853342, lng: 21.8313504 },
    { lat: 48.8537053, lng: 21.8314335 },
    { lat: 48.8542776, lng: 21.8309505 },
    { lat: 48.854803, lng: 21.8306914 },
    { lat: 48.8549898, lng: 21.8309504 },
    { lat: 48.8553934, lng: 21.8330316 },
    { lat: 48.8554648, lng: 21.8330478 },
    { lat: 48.8557228, lng: 21.8346308 },
    { lat: 48.8561987, lng: 21.8345936 },
    { lat: 48.8562854, lng: 21.8357316 },
    { lat: 48.8564988, lng: 21.8364121 },
    { lat: 48.8565599, lng: 21.8369054 },
    { lat: 48.8566173, lng: 21.8397885 },
    { lat: 48.856721, lng: 21.840449 },
    { lat: 48.8568219, lng: 21.8428355 },
    { lat: 48.8569269, lng: 21.8439843 },
    { lat: 48.8570565, lng: 21.8449158 },
    { lat: 48.8574716, lng: 21.8465279 },
    { lat: 48.8565785, lng: 21.8467996 },
    { lat: 48.8566997, lng: 21.8476155 },
    { lat: 48.8563173, lng: 21.847926 },
    { lat: 48.8559314, lng: 21.8483647 },
    { lat: 48.8552749, lng: 21.8493483 },
    { lat: 48.8549658, lng: 21.8500985 },
    { lat: 48.854781, lng: 21.8510061 },
    { lat: 48.854818, lng: 21.8518326 },
    { lat: 48.8549319, lng: 21.8523311 },
    { lat: 48.8554329, lng: 21.8532686 },
    { lat: 48.8556677, lng: 21.8536931 },
    { lat: 48.8568563, lng: 21.8551631 },
    { lat: 48.8591171, lng: 21.8585715 },
    { lat: 48.8598138, lng: 21.8584569 },
    { lat: 48.8606384, lng: 21.8566563 },
    { lat: 48.8613541, lng: 21.856711 },
    { lat: 48.8622934, lng: 21.8565764 },
    { lat: 48.8628554, lng: 21.856862 },
    { lat: 48.8633701, lng: 21.8568495 },
    { lat: 48.8641855, lng: 21.8563517 },
    { lat: 48.8643209, lng: 21.8563734 },
    { lat: 48.864644, lng: 21.8562064 },
    { lat: 48.8648303, lng: 21.8560532 },
    { lat: 48.8650588, lng: 21.8559705 },
    { lat: 48.8651778, lng: 21.8558341 },
    { lat: 48.8658695, lng: 21.8554747 },
    { lat: 48.8663038, lng: 21.8553058 },
    { lat: 48.8666125, lng: 21.8553641 },
    { lat: 48.8670006, lng: 21.8556731 },
    { lat: 48.8669638, lng: 21.8564718 },
    { lat: 48.8669937, lng: 21.8575527 },
    { lat: 48.8673225, lng: 21.8578735 },
    { lat: 48.8678878, lng: 21.8577173 },
    { lat: 48.868313, lng: 21.857494 },
    { lat: 48.8684185, lng: 21.8579694 },
    { lat: 48.8684737, lng: 21.8585064 },
    { lat: 48.8685704, lng: 21.8588491 },
    { lat: 48.8694684, lng: 21.8598747 },
    { lat: 48.8693899, lng: 21.8599294 },
    { lat: 48.8696599, lng: 21.8602826 },
    { lat: 48.8701309, lng: 21.8607044 },
    { lat: 48.8716692, lng: 21.8616528 },
    { lat: 48.8722337, lng: 21.8608924 },
    { lat: 48.8729043, lng: 21.8613797 },
    { lat: 48.8729009, lng: 21.8614238 },
    { lat: 48.8730394, lng: 21.8614271 },
    { lat: 48.8733268, lng: 21.8615835 },
    { lat: 48.8733834, lng: 21.8614655 },
    { lat: 48.873629, lng: 21.8616144 },
    { lat: 48.8737886, lng: 21.861521 },
    { lat: 48.8738647, lng: 21.8616316 },
    { lat: 48.874059, lng: 21.8616684 },
    { lat: 48.8745202, lng: 21.8620133 },
    { lat: 48.8746078, lng: 21.8623162 },
    { lat: 48.8750327, lng: 21.8626965 },
    { lat: 48.8751581, lng: 21.8626791 },
    { lat: 48.8752884, lng: 21.8628058 },
    { lat: 48.8753529, lng: 21.8627731 },
    { lat: 48.8757308, lng: 21.8629868 },
    { lat: 48.8763168, lng: 21.8630327 },
    { lat: 48.8766706, lng: 21.8633161 },
    { lat: 48.876813, lng: 21.8633442 },
    { lat: 48.876839, lng: 21.8634395 },
    { lat: 48.8770044, lng: 21.8634827 },
    { lat: 48.8771709, lng: 21.8637902 },
    { lat: 48.8773417, lng: 21.8639625 },
    { lat: 48.8775935, lng: 21.864081 },
    { lat: 48.8778157, lng: 21.8645861 },
    { lat: 48.8778106, lng: 21.8647476 },
    { lat: 48.8780359, lng: 21.8651043 },
    { lat: 48.8781479, lng: 21.8651771 },
    { lat: 48.8782669, lng: 21.8658244 },
    { lat: 48.8784231, lng: 21.8661698 },
    { lat: 48.8787794, lng: 21.8664709 },
    { lat: 48.8791078, lng: 21.8671267 },
    { lat: 48.8794237, lng: 21.8675628 },
    { lat: 48.8806922, lng: 21.8690403 },
    { lat: 48.8805393, lng: 21.8695264 },
    { lat: 48.8810589, lng: 21.8700549 },
    { lat: 48.8811669, lng: 21.8698777 },
    { lat: 48.8814497, lng: 21.8701185 },
    { lat: 48.8823394, lng: 21.8695823 },
    { lat: 48.8826817, lng: 21.870166 },
    { lat: 48.8827663, lng: 21.8707 },
    { lat: 48.8824659, lng: 21.8710139 },
    { lat: 48.8825238, lng: 21.8712135 },
    { lat: 48.8834822, lng: 21.8723579 },
    { lat: 48.8833568, lng: 21.8726437 },
    { lat: 48.8840627, lng: 21.873239 },
    { lat: 48.885766, lng: 21.8744853 },
    { lat: 48.8867347, lng: 21.8750198 },
    { lat: 48.8874015, lng: 21.8754984 },
    { lat: 48.8897901, lng: 21.876747 },
    { lat: 48.890292, lng: 21.877119 },
    { lat: 48.890645, lng: 21.876106 },
    { lat: 48.891018, lng: 21.874577 },
    { lat: 48.891298, lng: 21.873304 },
    { lat: 48.891383, lng: 21.872362 },
    { lat: 48.89149, lng: 21.871586 },
    { lat: 48.891421, lng: 21.871203 },
    { lat: 48.891745, lng: 21.869992 },
    { lat: 48.89184, lng: 21.8696 },
    { lat: 48.892189, lng: 21.868938 },
    { lat: 48.892553, lng: 21.86817 },
    { lat: 48.892627, lng: 21.867164 },
    { lat: 48.892917, lng: 21.864165 },
    { lat: 48.892967, lng: 21.861889 },
    { lat: 48.893145, lng: 21.858752 },
    { lat: 48.893361, lng: 21.857788 },
    { lat: 48.8935195, lng: 21.8565947 },
    { lat: 48.893563, lng: 21.855341 },
    { lat: 48.893499, lng: 21.853968 },
    { lat: 48.893475, lng: 21.853047 },
    { lat: 48.893477, lng: 21.851932 },
    { lat: 48.893401, lng: 21.851378 },
    { lat: 48.893705, lng: 21.850304 },
    { lat: 48.89398, lng: 21.849451 },
    { lat: 48.893899, lng: 21.848835 },
    { lat: 48.89377, lng: 21.84747 },
    { lat: 48.893715, lng: 21.846877 },
    { lat: 48.892841, lng: 21.846338 },
    { lat: 48.892316, lng: 21.844906 },
    { lat: 48.892077, lng: 21.844019 },
    { lat: 48.892325, lng: 21.841604 },
    { lat: 48.892367, lng: 21.841235 },
    { lat: 48.891157, lng: 21.840789 },
    { lat: 48.890763, lng: 21.840657 },
    { lat: 48.889729, lng: 21.840504 },
    { lat: 48.889747, lng: 21.839842 },
    { lat: 48.889473, lng: 21.838678 },
    { lat: 48.889395, lng: 21.838348 },
    { lat: 48.889328, lng: 21.838184 },
    { lat: 48.889025, lng: 21.835893 },
    { lat: 48.88897, lng: 21.835392 },
    { lat: 48.88829, lng: 21.835414 },
    { lat: 48.88752, lng: 21.835381 },
    { lat: 48.88681, lng: 21.835295 },
    { lat: 48.886784, lng: 21.835221 },
    { lat: 48.886741, lng: 21.834988 },
    { lat: 48.886705, lng: 21.834844 },
    { lat: 48.886612, lng: 21.834584 },
    { lat: 48.886611, lng: 21.834553 },
    { lat: 48.886608, lng: 21.834505 },
    { lat: 48.886608, lng: 21.834486 },
    { lat: 48.886607, lng: 21.834455 },
    { lat: 48.886604, lng: 21.834394 },
    { lat: 48.886622, lng: 21.834286 },
    { lat: 48.886622, lng: 21.834279 },
    { lat: 48.886614, lng: 21.834224 },
    { lat: 48.886605, lng: 21.834171 },
    { lat: 48.886592, lng: 21.834079 },
    { lat: 48.886549, lng: 21.833861 },
    { lat: 48.886538, lng: 21.833636 },
    { lat: 48.886534, lng: 21.833358 },
    { lat: 48.886548, lng: 21.833191 },
    { lat: 48.886585, lng: 21.833004 },
    { lat: 48.886606, lng: 21.832956 },
    { lat: 48.886634, lng: 21.832891 },
    { lat: 48.886705, lng: 21.832741 },
    { lat: 48.886845, lng: 21.832505 },
    { lat: 48.886803, lng: 21.831957 },
    { lat: 48.887277, lng: 21.832075 },
    { lat: 48.887307, lng: 21.83219 },
    { lat: 48.887563, lng: 21.832263 },
    { lat: 48.888246, lng: 21.832759 },
    { lat: 48.888476, lng: 21.832769 },
    { lat: 48.888507, lng: 21.832771 },
    { lat: 48.888577, lng: 21.832774 },
    { lat: 48.888644, lng: 21.832777 },
    { lat: 48.888674, lng: 21.832778 },
    { lat: 48.888745, lng: 21.832782 },
    { lat: 48.888782, lng: 21.832783 },
    { lat: 48.88891, lng: 21.832789 },
    { lat: 48.888976, lng: 21.832782 },
    { lat: 48.889034, lng: 21.832775 },
    { lat: 48.889087, lng: 21.832768 },
    { lat: 48.889101, lng: 21.832766 },
    { lat: 48.889213, lng: 21.832751 },
    { lat: 48.889228, lng: 21.832749 },
    { lat: 48.889299, lng: 21.832743 },
    { lat: 48.889739, lng: 21.832695 },
    { lat: 48.889803, lng: 21.833082 },
    { lat: 48.889816, lng: 21.833158 },
    { lat: 48.889867, lng: 21.833139 },
    { lat: 48.889977, lng: 21.833156 },
    { lat: 48.890085, lng: 21.833155 },
    { lat: 48.890272, lng: 21.83315 },
    { lat: 48.890682, lng: 21.833059 },
    { lat: 48.89112, lng: 21.832911 },
    { lat: 48.891319, lng: 21.832846 },
    { lat: 48.891337, lng: 21.832808 },
    { lat: 48.891342, lng: 21.832796 },
    { lat: 48.891229, lng: 21.83259 },
    { lat: 48.891147, lng: 21.83242 },
    { lat: 48.891104, lng: 21.832331 },
    { lat: 48.89107, lng: 21.831996 },
    { lat: 48.891008, lng: 21.831706 },
    { lat: 48.891004, lng: 21.831569 },
    { lat: 48.891068, lng: 21.831506 },
    { lat: 48.891077, lng: 21.831497 },
    { lat: 48.891023, lng: 21.831311 },
    { lat: 48.891016, lng: 21.831293 },
    { lat: 48.891014, lng: 21.831284 },
    { lat: 48.890992, lng: 21.831209 },
    { lat: 48.890961, lng: 21.831101 },
    { lat: 48.890941, lng: 21.831036 },
    { lat: 48.890787, lng: 21.830722 },
    { lat: 48.890495, lng: 21.830133 },
    { lat: 48.890371, lng: 21.829831 },
    { lat: 48.890121, lng: 21.829298 },
    { lat: 48.889942, lng: 21.828897 },
    { lat: 48.88974, lng: 21.82848 },
    { lat: 48.889641, lng: 21.828237 },
    { lat: 48.889272, lng: 21.827686 },
    { lat: 48.889227, lng: 21.827644 },
    { lat: 48.888758, lng: 21.82721 },
    { lat: 48.888663, lng: 21.827024 },
    { lat: 48.888682, lng: 21.826943 },
    { lat: 48.888647, lng: 21.82683 },
    { lat: 48.888738, lng: 21.826547 },
    { lat: 48.888917, lng: 21.826034 },
    { lat: 48.889067, lng: 21.825753 },
    { lat: 48.889142, lng: 21.825576 },
    { lat: 48.889955, lng: 21.824771 },
    { lat: 48.890472, lng: 21.824266 },
    { lat: 48.890919, lng: 21.82401 },
    { lat: 48.891548, lng: 21.823631 },
    { lat: 48.891645, lng: 21.823928 },
    { lat: 48.891703, lng: 21.823912 },
    { lat: 48.89218, lng: 21.823882 },
    { lat: 48.892594, lng: 21.823712 },
    { lat: 48.892754, lng: 21.823555 },
    { lat: 48.892801, lng: 21.823446 },
    { lat: 48.892702, lng: 21.823143 },
    { lat: 48.892801, lng: 21.823044 },
    { lat: 48.893124, lng: 21.822811 },
    { lat: 48.893468, lng: 21.822593 },
    { lat: 48.893722, lng: 21.822403 },
    { lat: 48.893909, lng: 21.822178 },
    { lat: 48.893976, lng: 21.822083 },
    { lat: 48.894157, lng: 21.821963 },
    { lat: 48.894417, lng: 21.821892 },
    { lat: 48.89466, lng: 21.821736 },
    { lat: 48.894851, lng: 21.821717 },
    { lat: 48.89495, lng: 21.821682 },
    { lat: 48.895075, lng: 21.821707 },
    { lat: 48.89536, lng: 21.821684 },
    { lat: 48.895446, lng: 21.821696 },
    { lat: 48.895592, lng: 21.8217 },
    { lat: 48.895657, lng: 21.821598 },
    { lat: 48.895875, lng: 21.821394 },
    { lat: 48.896081, lng: 21.821399 },
    { lat: 48.896158, lng: 21.821451 },
    { lat: 48.896388, lng: 21.821395 },
    { lat: 48.897105, lng: 21.821206 },
    { lat: 48.897211, lng: 21.821062 },
    { lat: 48.897313, lng: 21.820958 },
    { lat: 48.897425, lng: 21.820967 },
    { lat: 48.897568, lng: 21.820913 },
    { lat: 48.897653, lng: 21.820995 },
    { lat: 48.897791, lng: 21.821127 },
    { lat: 48.898085, lng: 21.821199 },
    { lat: 48.898229, lng: 21.821241 },
    { lat: 48.898435, lng: 21.821258 },
    { lat: 48.898799, lng: 21.821106 },
    { lat: 48.89911, lng: 21.821053 },
    { lat: 48.899096, lng: 21.820901 },
    { lat: 48.898964, lng: 21.819653 },
    { lat: 48.899455, lng: 21.818922 },
    { lat: 48.899701, lng: 21.81773 },
    { lat: 48.899792, lng: 21.81684 },
    { lat: 48.899973, lng: 21.81563 },
    { lat: 48.900113, lng: 21.814816 },
    { lat: 48.900255, lng: 21.813 },
    { lat: 48.900661, lng: 21.811913 },
    { lat: 48.901121, lng: 21.811439 },
    { lat: 48.901369, lng: 21.811246 },
    { lat: 48.901596, lng: 21.81107 },
    { lat: 48.901926, lng: 21.810763 },
    { lat: 48.901985, lng: 21.810706 },
    { lat: 48.901914, lng: 21.810049 },
    { lat: 48.901882, lng: 21.809461 },
    { lat: 48.901961, lng: 21.809053 },
    { lat: 48.901949, lng: 21.808378 },
    { lat: 48.901965, lng: 21.807981 },
    { lat: 48.901922, lng: 21.807788 },
    { lat: 48.901855, lng: 21.807596 },
    { lat: 48.901792, lng: 21.807337 },
    { lat: 48.901674, lng: 21.807198 },
    { lat: 48.90158, lng: 21.807047 },
    { lat: 48.901451, lng: 21.806881 },
    { lat: 48.901382, lng: 21.806621 },
    { lat: 48.901381, lng: 21.806586 },
    { lat: 48.901411, lng: 21.805651 },
    { lat: 48.901375, lng: 21.805484 },
    { lat: 48.901369, lng: 21.805456 },
    { lat: 48.901327, lng: 21.805174 },
    { lat: 48.901303, lng: 21.804842 },
    { lat: 48.901222, lng: 21.803741 },
    { lat: 48.901301, lng: 21.801742 },
    { lat: 48.90131, lng: 21.801504 },
    { lat: 48.901346, lng: 21.80058 },
    { lat: 48.901383, lng: 21.799637 },
    { lat: 48.901227, lng: 21.799317 },
    { lat: 48.901123, lng: 21.799084 },
    { lat: 48.901046, lng: 21.798914 },
    { lat: 48.900928, lng: 21.798741 },
    { lat: 48.900787, lng: 21.798612 },
    { lat: 48.900733, lng: 21.798567 },
    { lat: 48.900469, lng: 21.798397 },
    { lat: 48.900163, lng: 21.798151 },
    { lat: 48.900031, lng: 21.79809 },
    { lat: 48.899937, lng: 21.798045 },
    { lat: 48.899731, lng: 21.798031 },
    { lat: 48.899296, lng: 21.797904 },
    { lat: 48.899288, lng: 21.7979 },
    { lat: 48.898903, lng: 21.797725 },
    { lat: 48.898648, lng: 21.797617 },
    { lat: 48.898507, lng: 21.79754 },
    { lat: 48.898195, lng: 21.797366 },
    { lat: 48.898146, lng: 21.79729 },
    { lat: 48.898029, lng: 21.797114 },
    { lat: 48.898028, lng: 21.79703 },
    { lat: 48.898021, lng: 21.796779 },
    { lat: 48.897994, lng: 21.79649 },
    { lat: 48.89794, lng: 21.796084 },
    { lat: 48.897906, lng: 21.795901 },
    { lat: 48.897866, lng: 21.795456 },
    { lat: 48.897862, lng: 21.794712 },
    { lat: 48.897802, lng: 21.794078 },
    { lat: 48.897785, lng: 21.793893 },
    { lat: 48.897782, lng: 21.793862 },
    { lat: 48.897768, lng: 21.793593 },
    { lat: 48.897756, lng: 21.79331 },
    { lat: 48.89775, lng: 21.793179 },
    { lat: 48.897726, lng: 21.792646 },
    { lat: 48.896993, lng: 21.7929 },
    { lat: 48.89658, lng: 21.793051 },
    { lat: 48.896299, lng: 21.793137 },
    { lat: 48.895943, lng: 21.793206 },
    { lat: 48.895594, lng: 21.792744 },
    { lat: 48.89552, lng: 21.792565 },
    { lat: 48.895461, lng: 21.792158 },
    { lat: 48.895295, lng: 21.792093 },
    { lat: 48.895271, lng: 21.792018 },
    { lat: 48.895167, lng: 21.791943 },
    { lat: 48.895068, lng: 21.791835 },
    { lat: 48.894572, lng: 21.791712 },
    { lat: 48.894128, lng: 21.791599 },
    { lat: 48.894059, lng: 21.79154 },
    { lat: 48.893983, lng: 21.791427 },
    { lat: 48.894022, lng: 21.791343 },
    { lat: 48.894149, lng: 21.791218 },
    { lat: 48.894337, lng: 21.791285 },
    { lat: 48.894418, lng: 21.791238 },
    { lat: 48.89463, lng: 21.791126 },
    { lat: 48.894816, lng: 21.790959 },
    { lat: 48.894894, lng: 21.790589 },
    { lat: 48.895082, lng: 21.790369 },
    { lat: 48.895132, lng: 21.790165 },
    { lat: 48.895257, lng: 21.789993 },
    { lat: 48.895439, lng: 21.789551 },
    { lat: 48.895432, lng: 21.789329 },
    { lat: 48.895469, lng: 21.789171 },
    { lat: 48.895682, lng: 21.788952 },
    { lat: 48.895769, lng: 21.788731 },
    { lat: 48.895841, lng: 21.788703 },
    { lat: 48.896004, lng: 21.788701 },
    { lat: 48.896083, lng: 21.788606 },
    { lat: 48.896083, lng: 21.788466 },
    { lat: 48.896005, lng: 21.788253 },
    { lat: 48.895961, lng: 21.788115 },
    { lat: 48.895893, lng: 21.787876 },
    { lat: 48.895867, lng: 21.78715 },
  ],
  NacinaVes: [
    { lat: 48.8321434, lng: 21.829065 },
    { lat: 48.8322466, lng: 21.828915 },
    { lat: 48.8324685, lng: 21.8287529 },
    { lat: 48.8336012, lng: 21.8286635 },
    { lat: 48.8342383, lng: 21.8284589 },
    { lat: 48.8356518, lng: 21.8278754 },
    { lat: 48.8364992, lng: 21.8274093 },
    { lat: 48.83639, lng: 21.8270991 },
    { lat: 48.8359704, lng: 21.8270972 },
    { lat: 48.8355571, lng: 21.8267765 },
    { lat: 48.8354347, lng: 21.826532 },
    { lat: 48.8354154, lng: 21.82626 },
    { lat: 48.8351938, lng: 21.8256496 },
    { lat: 48.8344921, lng: 21.8245772 },
    { lat: 48.83431, lng: 21.8245831 },
    { lat: 48.8342568, lng: 21.8243941 },
    { lat: 48.8342239, lng: 21.8238268 },
    { lat: 48.8338743, lng: 21.8218369 },
    { lat: 48.8342618, lng: 21.8216803 },
    { lat: 48.8343867, lng: 21.8208137 },
    { lat: 48.8344171, lng: 21.8199048 },
    { lat: 48.8342849, lng: 21.818455 },
    { lat: 48.8338897, lng: 21.8170527 },
    { lat: 48.8327595, lng: 21.8171583 },
    { lat: 48.8327335, lng: 21.8163332 },
    { lat: 48.833985, lng: 21.8163125 },
    { lat: 48.8340464, lng: 21.8139599 },
    { lat: 48.8337203, lng: 21.8135744 },
    { lat: 48.8329876, lng: 21.8130397 },
    { lat: 48.8327277, lng: 21.8126903 },
    { lat: 48.8326446, lng: 21.8123981 },
    { lat: 48.8324875, lng: 21.8107274 },
    { lat: 48.8324012, lng: 21.8103912 },
    { lat: 48.8323525, lng: 21.8089579 },
    { lat: 48.8320589, lng: 21.8079232 },
    { lat: 48.8316127, lng: 21.8072918 },
    { lat: 48.8319343, lng: 21.8062884 },
    { lat: 48.8321537, lng: 21.80577 },
    { lat: 48.8325018, lng: 21.805361 },
    { lat: 48.8325217, lng: 21.804785 },
    { lat: 48.8325834, lng: 21.8046934 },
    { lat: 48.8325599, lng: 21.8044788 },
    { lat: 48.8326053, lng: 21.8041965 },
    { lat: 48.8327205, lng: 21.8039575 },
    { lat: 48.8327786, lng: 21.8036837 },
    { lat: 48.8327272, lng: 21.8030486 },
    { lat: 48.832602, lng: 21.8026076 },
    { lat: 48.8323513, lng: 21.8010219 },
    { lat: 48.8321374, lng: 21.8001098 },
    { lat: 48.832028, lng: 21.7979068 },
    { lat: 48.831723, lng: 21.796966 },
    { lat: 48.830851, lng: 21.796725 },
    { lat: 48.829658, lng: 21.796456 },
    { lat: 48.828812, lng: 21.796184 },
    { lat: 48.828, lng: 21.79622 },
    { lat: 48.826706, lng: 21.795667 },
    { lat: 48.82603, lng: 21.795721 },
    { lat: 48.825739, lng: 21.795883 },
    { lat: 48.825239, lng: 21.795991 },
    { lat: 48.824795, lng: 21.795489 },
    { lat: 48.824368, lng: 21.794903 },
    { lat: 48.824032, lng: 21.794221 },
    { lat: 48.823908, lng: 21.794151 },
    { lat: 48.82367, lng: 21.793902 },
    { lat: 48.82332, lng: 21.793494 },
    { lat: 48.822821, lng: 21.793176 },
    { lat: 48.822603, lng: 21.793022 },
    { lat: 48.822532, lng: 21.792917 },
    { lat: 48.822105, lng: 21.792694 },
    { lat: 48.822044, lng: 21.79265 },
    { lat: 48.821833, lng: 21.792495 },
    { lat: 48.8215, lng: 21.792836 },
    { lat: 48.821393, lng: 21.792925 },
    { lat: 48.821298, lng: 21.792963 },
    { lat: 48.821075, lng: 21.793082 },
    { lat: 48.820901, lng: 21.793152 },
    { lat: 48.820772, lng: 21.793201 },
    { lat: 48.820635, lng: 21.793257 },
    { lat: 48.820478, lng: 21.793319 },
    { lat: 48.820419, lng: 21.793296 },
    { lat: 48.820234, lng: 21.793489 },
    { lat: 48.819918, lng: 21.793736 },
    { lat: 48.819432, lng: 21.794474 },
    { lat: 48.819383, lng: 21.794551 },
    { lat: 48.819076, lng: 21.795035 },
    { lat: 48.818789, lng: 21.795452 },
    { lat: 48.818633, lng: 21.795666 },
    { lat: 48.818543, lng: 21.795774 },
    { lat: 48.818391, lng: 21.796159 },
    { lat: 48.818242, lng: 21.796516 },
    { lat: 48.818144, lng: 21.796613 },
    { lat: 48.817751, lng: 21.797023 },
    { lat: 48.817724, lng: 21.797073 },
    { lat: 48.817484, lng: 21.797432 },
    { lat: 48.817213, lng: 21.797837 },
    { lat: 48.817076, lng: 21.798043 },
    { lat: 48.816936, lng: 21.798277 },
    { lat: 48.816924, lng: 21.798272 },
    { lat: 48.81672, lng: 21.798427 },
    { lat: 48.816621, lng: 21.798517 },
    { lat: 48.816515, lng: 21.79859 },
    { lat: 48.816407, lng: 21.79868 },
    { lat: 48.816282, lng: 21.798776 },
    { lat: 48.8162, lng: 21.798843 },
    { lat: 48.816155, lng: 21.79888 },
    { lat: 48.816054, lng: 21.798969 },
    { lat: 48.815971, lng: 21.799044 },
    { lat: 48.815816, lng: 21.799218 },
    { lat: 48.815719, lng: 21.79926 },
    { lat: 48.815694, lng: 21.799282 },
    { lat: 48.81559, lng: 21.799364 },
    { lat: 48.815508, lng: 21.799431 },
    { lat: 48.815322, lng: 21.799587 },
    { lat: 48.815124, lng: 21.79975 },
    { lat: 48.815, lng: 21.799854 },
    { lat: 48.814913, lng: 21.799921 },
    { lat: 48.814828, lng: 21.799995 },
    { lat: 48.81474, lng: 21.800075 },
    { lat: 48.814673, lng: 21.800153 },
    { lat: 48.814606, lng: 21.800244 },
    { lat: 48.814544, lng: 21.800336 },
    { lat: 48.814445, lng: 21.800465 },
    { lat: 48.814277, lng: 21.800686 },
    { lat: 48.814133, lng: 21.800887 },
    { lat: 48.814013, lng: 21.800956 },
    { lat: 48.813876, lng: 21.80102 },
    { lat: 48.813732, lng: 21.801086 },
    { lat: 48.813664, lng: 21.801191 },
    { lat: 48.813386, lng: 21.801587 },
    { lat: 48.813079, lng: 21.802014 },
    { lat: 48.812988, lng: 21.802224 },
    { lat: 48.812933, lng: 21.802267 },
    { lat: 48.812537, lng: 21.802704 },
    { lat: 48.812125, lng: 21.803165 },
    { lat: 48.812024, lng: 21.803275 },
    { lat: 48.811837, lng: 21.803617 },
    { lat: 48.81178, lng: 21.80371 },
    { lat: 48.811414, lng: 21.804029 },
    { lat: 48.811207, lng: 21.804192 },
    { lat: 48.811182, lng: 21.804214 },
    { lat: 48.810911, lng: 21.804311 },
    { lat: 48.810669, lng: 21.804424 },
    { lat: 48.810216, lng: 21.804573 },
    { lat: 48.810057, lng: 21.804644 },
    { lat: 48.809811, lng: 21.804781 },
    { lat: 48.809637, lng: 21.804867 },
    { lat: 48.809575, lng: 21.80491 },
    { lat: 48.809503, lng: 21.804939 },
    { lat: 48.809258, lng: 21.805037 },
    { lat: 48.809005, lng: 21.805142 },
    { lat: 48.808945, lng: 21.805141 },
    { lat: 48.808881, lng: 21.805222 },
    { lat: 48.80876, lng: 21.805342 },
    { lat: 48.808595, lng: 21.805547 },
    { lat: 48.80834, lng: 21.805739 },
    { lat: 48.808287, lng: 21.805795 },
    { lat: 48.80807, lng: 21.805906 },
    { lat: 48.807634, lng: 21.806109 },
    { lat: 48.807519, lng: 21.806177 },
    { lat: 48.807105, lng: 21.806353 },
    { lat: 48.806942, lng: 21.806423 },
    { lat: 48.806579, lng: 21.806517 },
    { lat: 48.806365, lng: 21.8067 },
    { lat: 48.806047, lng: 21.80698 },
    { lat: 48.8052674, lng: 21.8075394 },
    { lat: 48.8051595, lng: 21.8102564 },
    { lat: 48.804796, lng: 21.8104273 },
    { lat: 48.8047212, lng: 21.8105339 },
    { lat: 48.8046434, lng: 21.8108783 },
    { lat: 48.8049731, lng: 21.8124282 },
    { lat: 48.8052026, lng: 21.8131686 },
    { lat: 48.8052813, lng: 21.8133391 },
    { lat: 48.8053721, lng: 21.8133676 },
    { lat: 48.8061092, lng: 21.816445 },
    { lat: 48.806447, lng: 21.8164163 },
    { lat: 48.8064588, lng: 21.8166069 },
    { lat: 48.8064918, lng: 21.8201743 },
    { lat: 48.8058413, lng: 21.8310086 },
    { lat: 48.8049514, lng: 21.8324051 },
    { lat: 48.8033785, lng: 21.8339151 },
    { lat: 48.7996591, lng: 21.8372215 },
    { lat: 48.799686, lng: 21.8373037 },
    { lat: 48.8001984, lng: 21.8388889 },
    { lat: 48.8002431, lng: 21.8389665 },
    { lat: 48.8005423, lng: 21.8394909 },
    { lat: 48.8005807, lng: 21.8401466 },
    { lat: 48.8006367, lng: 21.8403506 },
    { lat: 48.8013512, lng: 21.8413219 },
    { lat: 48.8016745, lng: 21.841898 },
    { lat: 48.8018401, lng: 21.8423061 },
    { lat: 48.8020843, lng: 21.8426999 },
    { lat: 48.8023096, lng: 21.8433636 },
    { lat: 48.8025883, lng: 21.8436604 },
    { lat: 48.8033864, lng: 21.845605 },
    { lat: 48.8037142, lng: 21.8467062 },
    { lat: 48.8042059, lng: 21.8480251 },
    { lat: 48.8047278, lng: 21.8489868 },
    { lat: 48.8057115, lng: 21.850315 },
    { lat: 48.8062101, lng: 21.8515526 },
    { lat: 48.8063912, lng: 21.8522518 },
    { lat: 48.8064354, lng: 21.8527898 },
    { lat: 48.8065616, lng: 21.853322 },
    { lat: 48.8064867, lng: 21.8537743 },
    { lat: 48.8066534, lng: 21.8540904 },
    { lat: 48.8067042, lng: 21.8547091 },
    { lat: 48.8070636, lng: 21.8549977 },
    { lat: 48.807269, lng: 21.8556341 },
    { lat: 48.8073895, lng: 21.8558569 },
    { lat: 48.8075595, lng: 21.8565664 },
    { lat: 48.8076863, lng: 21.8568608 },
    { lat: 48.8078588, lng: 21.8580138 },
    { lat: 48.8084127, lng: 21.8599603 },
    { lat: 48.8085687, lng: 21.8608698 },
    { lat: 48.807523, lng: 21.8614601 },
    { lat: 48.8077674, lng: 21.8616506 },
    { lat: 48.807815, lng: 21.8619237 },
    { lat: 48.8083053, lng: 21.8625334 },
    { lat: 48.8084526, lng: 21.8631008 },
    { lat: 48.8086774, lng: 21.8634006 },
    { lat: 48.8087922, lng: 21.863355 },
    { lat: 48.8089446, lng: 21.8631583 },
    { lat: 48.8090092, lng: 21.8633059 },
    { lat: 48.8099239, lng: 21.8626055 },
    { lat: 48.810238, lng: 21.8652996 },
    { lat: 48.809717, lng: 21.8657349 },
    { lat: 48.8093101, lng: 21.8659718 },
    { lat: 48.8093402, lng: 21.8664372 },
    { lat: 48.8089995, lng: 21.8665825 },
    { lat: 48.8085559, lng: 21.8665674 },
    { lat: 48.80778, lng: 21.8663966 },
    { lat: 48.8076204, lng: 21.8662768 },
    { lat: 48.8074314, lng: 21.8660113 },
    { lat: 48.8071903, lng: 21.8653437 },
    { lat: 48.8070439, lng: 21.8645418 },
    { lat: 48.8068004, lng: 21.8647438 },
    { lat: 48.8067334, lng: 21.864852 },
    { lat: 48.8067442, lng: 21.8649159 },
    { lat: 48.8057529, lng: 21.8657895 },
    { lat: 48.805868, lng: 21.8663706 },
    { lat: 48.8059961, lng: 21.8682213 },
    { lat: 48.8069907, lng: 21.8709079 },
    { lat: 48.8073032, lng: 21.8719093 },
    { lat: 48.8076993, lng: 21.8725317 },
    { lat: 48.8082362, lng: 21.8724157 },
    { lat: 48.8085613, lng: 21.8724527 },
    { lat: 48.8086666, lng: 21.8729666 },
    { lat: 48.8084729, lng: 21.8731382 },
    { lat: 48.8086139, lng: 21.8737317 },
    { lat: 48.8085955, lng: 21.8745882 },
    { lat: 48.8084869, lng: 21.8748779 },
    { lat: 48.8082755, lng: 21.8751037 },
    { lat: 48.8082981, lng: 21.8754151 },
    { lat: 48.8085726, lng: 21.8753737 },
    { lat: 48.808868, lng: 21.8754381 },
    { lat: 48.8091572, lng: 21.8758802 },
    { lat: 48.809453, lng: 21.8768119 },
    { lat: 48.809761, lng: 21.8781217 },
    { lat: 48.8104487, lng: 21.8791069 },
    { lat: 48.8113301, lng: 21.881162 },
    { lat: 48.812723, lng: 21.883338 },
    { lat: 48.8131778, lng: 21.8818041 },
    { lat: 48.8136369, lng: 21.8806116 },
    { lat: 48.8137495, lng: 21.8803987 },
    { lat: 48.8138311, lng: 21.8806017 },
    { lat: 48.8150052, lng: 21.8793035 },
    { lat: 48.815566, lng: 21.8787911 },
    { lat: 48.8159553, lng: 21.8787141 },
    { lat: 48.817095, lng: 21.8801275 },
    { lat: 48.8181601, lng: 21.8802145 },
    { lat: 48.8185276, lng: 21.88002 },
    { lat: 48.8189701, lng: 21.8801209 },
    { lat: 48.8196055, lng: 21.8798932 },
    { lat: 48.8200196, lng: 21.879501 },
    { lat: 48.8203777, lng: 21.8785865 },
    { lat: 48.8204001, lng: 21.8777438 },
    { lat: 48.8208867, lng: 21.8763942 },
    { lat: 48.8209999, lng: 21.8750175 },
    { lat: 48.8216293, lng: 21.8740682 },
    { lat: 48.8220496, lng: 21.8730315 },
    { lat: 48.8233469, lng: 21.8722927 },
    { lat: 48.8239776, lng: 21.8730758 },
    { lat: 48.8243518, lng: 21.8738551 },
    { lat: 48.8251913, lng: 21.8742533 },
    { lat: 48.8269182, lng: 21.8759817 },
    { lat: 48.8279899, lng: 21.8767249 },
    { lat: 48.8288229, lng: 21.8771354 },
    { lat: 48.8286242, lng: 21.8764433 },
    { lat: 48.828908, lng: 21.8757951 },
    { lat: 48.8289087, lng: 21.8753873 },
    { lat: 48.8287832, lng: 21.875099 },
    { lat: 48.8291977, lng: 21.8733927 },
    { lat: 48.8292354, lng: 21.8727975 },
    { lat: 48.8296334, lng: 21.8726182 },
    { lat: 48.8296004, lng: 21.8723752 },
    { lat: 48.8296238, lng: 21.8720192 },
    { lat: 48.82934, lng: 21.8712086 },
    { lat: 48.8291785, lng: 21.8709667 },
    { lat: 48.8288312, lng: 21.8708445 },
    { lat: 48.8260348, lng: 21.8692228 },
    { lat: 48.8230666, lng: 21.8646717 },
    { lat: 48.8236882, lng: 21.8637663 },
    { lat: 48.8238661, lng: 21.8627605 },
    { lat: 48.8240432, lng: 21.8625609 },
    { lat: 48.8245198, lng: 21.8623305 },
    { lat: 48.8251977, lng: 21.862703 },
    { lat: 48.8253422, lng: 21.8625553 },
    { lat: 48.8254234, lng: 21.8623842 },
    { lat: 48.8255653, lng: 21.8614276 },
    { lat: 48.8258138, lng: 21.8602309 },
    { lat: 48.8262864, lng: 21.858749 },
    { lat: 48.8265383, lng: 21.858571 },
    { lat: 48.8268817, lng: 21.8584469 },
    { lat: 48.827027, lng: 21.8584983 },
    { lat: 48.8271015, lng: 21.8586832 },
    { lat: 48.8270192, lng: 21.8590679 },
    { lat: 48.8263007, lng: 21.8598763 },
    { lat: 48.8262323, lng: 21.8601071 },
    { lat: 48.8262238, lng: 21.8602543 },
    { lat: 48.8262882, lng: 21.8603926 },
    { lat: 48.8266593, lng: 21.8609656 },
    { lat: 48.826855, lng: 21.8611776 },
    { lat: 48.8271062, lng: 21.8611029 },
    { lat: 48.8273535, lng: 21.8608356 },
    { lat: 48.8274554, lng: 21.8604618 },
    { lat: 48.8274707, lng: 21.8600557 },
    { lat: 48.8275348, lng: 21.8597856 },
    { lat: 48.8276837, lng: 21.8594523 },
    { lat: 48.827846, lng: 21.8593274 },
    { lat: 48.8279357, lng: 21.8593254 },
    { lat: 48.8284765, lng: 21.8596578 },
    { lat: 48.8287197, lng: 21.859671 },
    { lat: 48.8290865, lng: 21.8594498 },
    { lat: 48.8293346, lng: 21.8586837 },
    { lat: 48.8292994, lng: 21.8583201 },
    { lat: 48.8286956, lng: 21.8579868 },
    { lat: 48.827839, lng: 21.8572156 },
    { lat: 48.8276154, lng: 21.85666 },
    { lat: 48.8276312, lng: 21.8564402 },
    { lat: 48.828345, lng: 21.855015 },
    { lat: 48.8283577, lng: 21.8549879 },
    { lat: 48.8285217, lng: 21.8545787 },
    { lat: 48.8286739, lng: 21.8538499 },
    { lat: 48.8293158, lng: 21.8530423 },
    { lat: 48.8296503, lng: 21.8528776 },
    { lat: 48.8296637, lng: 21.8527005 },
    { lat: 48.8304166, lng: 21.8516621 },
    { lat: 48.830781, lng: 21.8514413 },
    { lat: 48.8310908, lng: 21.8509725 },
    { lat: 48.8314518, lng: 21.8506213 },
    { lat: 48.8324508, lng: 21.8501878 },
    { lat: 48.8327325, lng: 21.8501594 },
    { lat: 48.8317345, lng: 21.8455992 },
    { lat: 48.8316977, lng: 21.8456174 },
    { lat: 48.8311365, lng: 21.8431297 },
    { lat: 48.8301306, lng: 21.838456 },
    { lat: 48.8299974, lng: 21.8380893 },
    { lat: 48.8298884, lng: 21.8379212 },
    { lat: 48.8284001, lng: 21.840012 },
    { lat: 48.8264433, lng: 21.8420889 },
    { lat: 48.8261201, lng: 21.8407245 },
    { lat: 48.8258041, lng: 21.8382004 },
    { lat: 48.8254791, lng: 21.8370704 },
    { lat: 48.8243078, lng: 21.8319269 },
    { lat: 48.8241046, lng: 21.8304591 },
    { lat: 48.8263832, lng: 21.8323094 },
    { lat: 48.827191, lng: 21.8343591 },
    { lat: 48.8289548, lng: 21.8319601 },
    { lat: 48.8321038, lng: 21.8290102 },
    { lat: 48.8321434, lng: 21.829065 },
  ],
  Moravany: [
    { lat: 48.7466563, lng: 21.819177 },
    { lat: 48.7465168, lng: 21.8190494 },
    { lat: 48.746151, lng: 21.7992562 },
    { lat: 48.7464955, lng: 21.7991771 },
    { lat: 48.7465018, lng: 21.7982649 },
    { lat: 48.7466776, lng: 21.7982733 },
    { lat: 48.7468163, lng: 21.798718 },
    { lat: 48.7468735, lng: 21.7999099 },
    { lat: 48.7469504, lng: 21.8001366 },
    { lat: 48.74697, lng: 21.800975 },
    { lat: 48.7468094, lng: 21.8009465 },
    { lat: 48.7467232, lng: 21.8013912 },
    { lat: 48.7467013, lng: 21.8017532 },
    { lat: 48.7467671, lng: 21.8020348 },
    { lat: 48.7469707, lng: 21.802038 },
    { lat: 48.746977, lng: 21.8018034 },
    { lat: 48.7473314, lng: 21.8016353 },
    { lat: 48.7473327, lng: 21.8012499 },
    { lat: 48.7479956, lng: 21.8015207 },
    { lat: 48.7483254, lng: 21.8013933 },
    { lat: 48.7484046, lng: 21.8018431 },
    { lat: 48.7478802, lng: 21.8025236 },
    { lat: 48.7482062, lng: 21.8033344 },
    { lat: 48.7485508, lng: 21.8037398 },
    { lat: 48.7488897, lng: 21.8038707 },
    { lat: 48.7485959, lng: 21.8052451 },
    { lat: 48.7494746, lng: 21.8055911 },
    { lat: 48.7494429, lng: 21.8060586 },
    { lat: 48.7500578, lng: 21.80586 },
    { lat: 48.7505784, lng: 21.8060275 },
    { lat: 48.7506255, lng: 21.8067559 },
    { lat: 48.7509516, lng: 21.8067036 },
    { lat: 48.7512476, lng: 21.806754 },
    { lat: 48.7512435, lng: 21.8073721 },
    { lat: 48.7515828, lng: 21.8073928 },
    { lat: 48.7517485, lng: 21.8073665 },
    { lat: 48.7523286, lng: 21.8069996 },
    { lat: 48.75268, lng: 21.8070143 },
    { lat: 48.7535306, lng: 21.8067631 },
    { lat: 48.7534249, lng: 21.8061622 },
    { lat: 48.7537445, lng: 21.8059078 },
    { lat: 48.7543648, lng: 21.80571 },
    { lat: 48.7545061, lng: 21.8054348 },
    { lat: 48.7548713, lng: 21.8052472 },
    { lat: 48.7549602, lng: 21.8056366 },
    { lat: 48.7551593, lng: 21.8055431 },
    { lat: 48.7553019, lng: 21.805578 },
    { lat: 48.7552801, lng: 21.8063751 },
    { lat: 48.7555449, lng: 21.8064388 },
    { lat: 48.7557167, lng: 21.807675 },
    { lat: 48.7561375, lng: 21.807503 },
    { lat: 48.7561785, lng: 21.8073943 },
    { lat: 48.7567363, lng: 21.8072141 },
    { lat: 48.7567457, lng: 21.8070952 },
    { lat: 48.7569067, lng: 21.8071059 },
    { lat: 48.7572018, lng: 21.8072911 },
    { lat: 48.7583487, lng: 21.8075275 },
    { lat: 48.7584267, lng: 21.8073267 },
    { lat: 48.7586548, lng: 21.8071865 },
    { lat: 48.7586585, lng: 21.807023 },
    { lat: 48.7587617, lng: 21.8071448 },
    { lat: 48.7587608, lng: 21.8072376 },
    { lat: 48.7589876, lng: 21.8073463 },
    { lat: 48.758899, lng: 21.8078362 },
    { lat: 48.7590674, lng: 21.8079463 },
    { lat: 48.7593209, lng: 21.8080032 },
    { lat: 48.7594909, lng: 21.8079684 },
    { lat: 48.7587117, lng: 21.8024255 },
    { lat: 48.7559822, lng: 21.7839421 },
    { lat: 48.7570491, lng: 21.7787024 },
    { lat: 48.7568619, lng: 21.7750997 },
    { lat: 48.756552, lng: 21.766266 },
    { lat: 48.755769, lng: 21.765734 },
    { lat: 48.755326, lng: 21.765396 },
    { lat: 48.753864, lng: 21.764329 },
    { lat: 48.753482, lng: 21.764034 },
    { lat: 48.753146, lng: 21.763798 },
    { lat: 48.752902, lng: 21.763558 },
    { lat: 48.752698, lng: 21.763413 },
    { lat: 48.752228, lng: 21.763137 },
    { lat: 48.752035, lng: 21.763032 },
    { lat: 48.751731, lng: 21.762815 },
    { lat: 48.751532, lng: 21.76261 },
    { lat: 48.751464, lng: 21.76252 },
    { lat: 48.751107, lng: 21.762162 },
    { lat: 48.748334, lng: 21.760132 },
    { lat: 48.746366, lng: 21.758723 },
    { lat: 48.746341, lng: 21.758716 },
    { lat: 48.746208, lng: 21.758672 },
    { lat: 48.745756, lng: 21.758525 },
    { lat: 48.745097, lng: 21.758311 },
    { lat: 48.744848, lng: 21.758232 },
    { lat: 48.744816, lng: 21.758201 },
    { lat: 48.744388, lng: 21.758297 },
    { lat: 48.74409, lng: 21.758341 },
    { lat: 48.743728, lng: 21.758439 },
    { lat: 48.741604, lng: 21.759802 },
    { lat: 48.7387385, lng: 21.7613194 },
    { lat: 48.7383096, lng: 21.7620361 },
    { lat: 48.7380854, lng: 21.762152 },
    { lat: 48.7379465, lng: 21.7630212 },
    { lat: 48.7380179, lng: 21.7635088 },
    { lat: 48.7372466, lng: 21.763702 },
    { lat: 48.7356764, lng: 21.7646859 },
    { lat: 48.7355429, lng: 21.7646202 },
    { lat: 48.7340948, lng: 21.7657992 },
    { lat: 48.7339091, lng: 21.7656715 },
    { lat: 48.733001, lng: 21.7666413 },
    { lat: 48.7329287, lng: 21.7670099 },
    { lat: 48.732494, lng: 21.7675455 },
    { lat: 48.7325236, lng: 21.7679859 },
    { lat: 48.732743, lng: 21.7683676 },
    { lat: 48.7327643, lng: 21.7685373 },
    { lat: 48.7326628, lng: 21.7688265 },
    { lat: 48.7324909, lng: 21.7690033 },
    { lat: 48.7322484, lng: 21.7691053 },
    { lat: 48.7320552, lng: 21.7689936 },
    { lat: 48.731662, lng: 21.7685872 },
    { lat: 48.7313367, lng: 21.7689248 },
    { lat: 48.731177, lng: 21.7693496 },
    { lat: 48.7312682, lng: 21.7698202 },
    { lat: 48.7315078, lng: 21.7704113 },
    { lat: 48.7314714, lng: 21.7706145 },
    { lat: 48.7313813, lng: 21.7706919 },
    { lat: 48.7310818, lng: 21.7706879 },
    { lat: 48.7308962, lng: 21.7704742 },
    { lat: 48.730721, lng: 21.7701427 },
    { lat: 48.7304808, lng: 21.7699551 },
    { lat: 48.7292938, lng: 21.7709274 },
    { lat: 48.7293137, lng: 21.771558 },
    { lat: 48.7291034, lng: 21.7718138 },
    { lat: 48.7287958, lng: 21.7719692 },
    { lat: 48.7285804, lng: 21.7718999 },
    { lat: 48.728055, lng: 21.7714313 },
    { lat: 48.7273745, lng: 21.7718291 },
    { lat: 48.7273653, lng: 21.7720331 },
    { lat: 48.7273512, lng: 21.7723487 },
    { lat: 48.7278358, lng: 21.7731896 },
    { lat: 48.7279111, lng: 21.7735243 },
    { lat: 48.7278648, lng: 21.7739494 },
    { lat: 48.727979, lng: 21.7742654 },
    { lat: 48.7279253, lng: 21.7745359 },
    { lat: 48.7276723, lng: 21.7748822 },
    { lat: 48.7273639, lng: 21.7750394 },
    { lat: 48.7271252, lng: 21.7752944 },
    { lat: 48.7268551, lng: 21.7760586 },
    { lat: 48.7261138, lng: 21.7772069 },
    { lat: 48.7259844, lng: 21.7772072 },
    { lat: 48.7259148, lng: 21.7770461 },
    { lat: 48.7255934, lng: 21.7768463 },
    { lat: 48.7253306, lng: 21.7769024 },
    { lat: 48.7250249, lng: 21.7770908 },
    { lat: 48.7247908, lng: 21.7770829 },
    { lat: 48.7244837, lng: 21.7766135 },
    { lat: 48.7241813, lng: 21.7767597 },
    { lat: 48.7241813, lng: 21.7770537 },
    { lat: 48.7240889, lng: 21.7777309 },
    { lat: 48.7239498, lng: 21.7780899 },
    { lat: 48.7236221, lng: 21.7784097 },
    { lat: 48.7232968, lng: 21.7785383 },
    { lat: 48.7230963, lng: 21.7788276 },
    { lat: 48.7228157, lng: 21.7790252 },
    { lat: 48.7226386, lng: 21.7789229 },
    { lat: 48.7225164, lng: 21.7787243 },
    { lat: 48.7224368, lng: 21.7783087 },
    { lat: 48.7221746, lng: 21.7781367 },
    { lat: 48.7218897, lng: 21.778166 },
    { lat: 48.7217752, lng: 21.7783863 },
    { lat: 48.7217665, lng: 21.7785301 },
    { lat: 48.7221034, lng: 21.7802087 },
    { lat: 48.7221276, lng: 21.7808318 },
    { lat: 48.7222509, lng: 21.7811561 },
    { lat: 48.722258, lng: 21.7813342 },
    { lat: 48.7221773, lng: 21.7817339 },
    { lat: 48.7218879, lng: 21.7821051 },
    { lat: 48.7216703, lng: 21.7820509 },
    { lat: 48.7214108, lng: 21.7814166 },
    { lat: 48.7212814, lng: 21.7813748 },
    { lat: 48.7206513, lng: 21.7817181 },
    { lat: 48.7205971, lng: 21.7818336 },
    { lat: 48.7205058, lng: 21.7823412 },
    { lat: 48.7204962, lng: 21.7830077 },
    { lat: 48.7205576, lng: 21.78346 },
    { lat: 48.7204855, lng: 21.7843925 },
    { lat: 48.7201261, lng: 21.7852286 },
    { lat: 48.7201224, lng: 21.7853839 },
    { lat: 48.7204009, lng: 21.7860947 },
    { lat: 48.7205992, lng: 21.7877253 },
    { lat: 48.7202257, lng: 21.7884607 },
    { lat: 48.7201507, lng: 21.7887734 },
    { lat: 48.7201494, lng: 21.7897544 },
    { lat: 48.7201107, lng: 21.789906 },
    { lat: 48.7200305, lng: 21.7900322 },
    { lat: 48.7200048, lng: 21.7900727 },
    { lat: 48.7189713, lng: 21.7911641 },
    { lat: 48.7185244, lng: 21.7916359 },
    { lat: 48.7184998, lng: 21.7916619 },
    { lat: 48.7182655, lng: 21.7918245 },
    { lat: 48.7180131, lng: 21.7919024 },
    { lat: 48.7179579, lng: 21.7919018 },
    { lat: 48.7177398, lng: 21.7918992 },
    { lat: 48.7174829, lng: 21.7918041 },
    { lat: 48.7171133, lng: 21.7914814 },
    { lat: 48.716204, lng: 21.7898391 },
    { lat: 48.7160247, lng: 21.7897362 },
    { lat: 48.7159303, lng: 21.789777 },
    { lat: 48.715889, lng: 21.7897949 },
    { lat: 48.7153084, lng: 21.7903959 },
    { lat: 48.7150072, lng: 21.7909996 },
    { lat: 48.7149778, lng: 21.7910358 },
    { lat: 48.71446, lng: 21.7916737 },
    { lat: 48.7141047, lng: 21.7926705 },
    { lat: 48.7140274, lng: 21.7928077 },
    { lat: 48.7139531, lng: 21.7929396 },
    { lat: 48.7138215, lng: 21.7930273 },
    { lat: 48.7129329, lng: 21.7931141 },
    { lat: 48.7127072, lng: 21.7930489 },
    { lat: 48.7126963, lng: 21.793269 },
    { lat: 48.7128647, lng: 21.7946721 },
    { lat: 48.7131844, lng: 21.7957962 },
    { lat: 48.7139954, lng: 21.7995652 },
    { lat: 48.714072, lng: 21.8000405 },
    { lat: 48.7140815, lng: 21.8010887 },
    { lat: 48.7141446, lng: 21.801698 },
    { lat: 48.7142355, lng: 21.8023342 },
    { lat: 48.7145354, lng: 21.8034328 },
    { lat: 48.7148671, lng: 21.8039829 },
    { lat: 48.7151761, lng: 21.8052142 },
    { lat: 48.7152784, lng: 21.8054767 },
    { lat: 48.715779, lng: 21.8063272 },
    { lat: 48.7162531, lng: 21.8073377 },
    { lat: 48.7164274, lng: 21.8073334 },
    { lat: 48.7176199, lng: 21.8092295 },
    { lat: 48.7179731, lng: 21.810169 },
    { lat: 48.7183437, lng: 21.8108844 },
    { lat: 48.7185129, lng: 21.8115502 },
    { lat: 48.7190452, lng: 21.8131025 },
    { lat: 48.7190249, lng: 21.8133623 },
    { lat: 48.719162, lng: 21.8144807 },
    { lat: 48.7191775, lng: 21.8150752 },
    { lat: 48.7196579, lng: 21.816151 },
    { lat: 48.7198807, lng: 21.816711 },
    { lat: 48.7199013, lng: 21.8168612 },
    { lat: 48.7198495, lng: 21.8172862 },
    { lat: 48.7195455, lng: 21.8181412 },
    { lat: 48.7195875, lng: 21.8188491 },
    { lat: 48.7195476, lng: 21.8192209 },
    { lat: 48.7196043, lng: 21.8195771 },
    { lat: 48.7198352, lng: 21.8199346 },
    { lat: 48.7199429, lng: 21.820484 },
    { lat: 48.7202493, lng: 21.8212788 },
    { lat: 48.7205995, lng: 21.8219382 },
    { lat: 48.72073, lng: 21.8228472 },
    { lat: 48.7207503, lng: 21.8235397 },
    { lat: 48.7207141, lng: 21.8238407 },
    { lat: 48.720708, lng: 21.8238635 },
    { lat: 48.721171, lng: 21.8235421 },
    { lat: 48.7223962, lng: 21.8284833 },
    { lat: 48.7230925, lng: 21.8309963 },
    { lat: 48.7232035, lng: 21.8306755 },
    { lat: 48.7233053, lng: 21.8305662 },
    { lat: 48.7235188, lng: 21.8305086 },
    { lat: 48.7240238, lng: 21.8297714 },
    { lat: 48.7242935, lng: 21.8294996 },
    { lat: 48.7248989, lng: 21.829075 },
    { lat: 48.725464, lng: 21.8284304 },
    { lat: 48.7272532, lng: 21.8267982 },
    { lat: 48.7288223, lng: 21.8251447 },
    { lat: 48.7293329, lng: 21.8244462 },
    { lat: 48.7295923, lng: 21.8239957 },
    { lat: 48.7297055, lng: 21.8232435 },
    { lat: 48.7301305, lng: 21.8223608 },
    { lat: 48.7305265, lng: 21.8219321 },
    { lat: 48.7310834, lng: 21.821846 },
    { lat: 48.731402, lng: 21.821378 },
    { lat: 48.7317317, lng: 21.8211638 },
    { lat: 48.7324469, lng: 21.8216235 },
    { lat: 48.7329729, lng: 21.8213107 },
    { lat: 48.7332774, lng: 21.8213332 },
    { lat: 48.7335366, lng: 21.8212661 },
    { lat: 48.7340751, lng: 21.8209932 },
    { lat: 48.7350785, lng: 21.8201638 },
    { lat: 48.7358477, lng: 21.8202417 },
    { lat: 48.7367685, lng: 21.8198701 },
    { lat: 48.7376858, lng: 21.8196698 },
    { lat: 48.7379206, lng: 21.8199592 },
    { lat: 48.7382466, lng: 21.8205125 },
    { lat: 48.7385021, lng: 21.8215085 },
    { lat: 48.7393979, lng: 21.8217425 },
    { lat: 48.7395104, lng: 21.8218717 },
    { lat: 48.7397299, lng: 21.8217431 },
    { lat: 48.7398339, lng: 21.8220171 },
    { lat: 48.7402802, lng: 21.8222711 },
    { lat: 48.7403455, lng: 21.8225705 },
    { lat: 48.7406585, lng: 21.8229315 },
    { lat: 48.740623, lng: 21.8232099 },
    { lat: 48.7406569, lng: 21.8236516 },
    { lat: 48.7408742, lng: 21.824085 },
    { lat: 48.7409164, lng: 21.8245833 },
    { lat: 48.7410414, lng: 21.8247213 },
    { lat: 48.7411307, lng: 21.8251298 },
    { lat: 48.7414448, lng: 21.8259577 },
    { lat: 48.7416794, lng: 21.825979 },
    { lat: 48.741977, lng: 21.8259165 },
    { lat: 48.7420955, lng: 21.8254956 },
    { lat: 48.7420603, lng: 21.825265 },
    { lat: 48.7424134, lng: 21.8249203 },
    { lat: 48.7425056, lng: 21.8245652 },
    { lat: 48.7427928, lng: 21.8246704 },
    { lat: 48.742891, lng: 21.8245477 },
    { lat: 48.743007, lng: 21.8246374 },
    { lat: 48.7429598, lng: 21.8239925 },
    { lat: 48.7432778, lng: 21.8241982 },
    { lat: 48.7433068, lng: 21.824056 },
    { lat: 48.7432226, lng: 21.8239345 },
    { lat: 48.7434545, lng: 21.823895 },
    { lat: 48.7436574, lng: 21.8233932 },
    { lat: 48.7437885, lng: 21.8232846 },
    { lat: 48.743906, lng: 21.8232979 },
    { lat: 48.7443217, lng: 21.8230187 },
    { lat: 48.7443657, lng: 21.8228528 },
    { lat: 48.7445999, lng: 21.8228215 },
    { lat: 48.7446125, lng: 21.8226058 },
    { lat: 48.7448193, lng: 21.8226462 },
    { lat: 48.7450612, lng: 21.8224739 },
    { lat: 48.7452757, lng: 21.8221564 },
    { lat: 48.7454476, lng: 21.8221926 },
    { lat: 48.7454675, lng: 21.8218349 },
    { lat: 48.7456053, lng: 21.8215027 },
    { lat: 48.7458642, lng: 21.8211608 },
    { lat: 48.7458385, lng: 21.8206069 },
    { lat: 48.7459284, lng: 21.8204736 },
    { lat: 48.7461373, lng: 21.820422 },
    { lat: 48.7462287, lng: 21.8200369 },
    { lat: 48.7464625, lng: 21.8199011 },
    { lat: 48.7466651, lng: 21.8195129 },
    { lat: 48.7465766, lng: 21.8193125 },
    { lat: 48.7466563, lng: 21.819177 },
  ],
  RakovecnadOndavou: [
    { lat: 48.7546966, lng: 21.8190805 },
    { lat: 48.7547392, lng: 21.8190327 },
    { lat: 48.7645278, lng: 21.8185051 },
    { lat: 48.7812407, lng: 21.8177652 },
    { lat: 48.7834646, lng: 21.817626 },
    { lat: 48.7834593, lng: 21.8172111 },
    { lat: 48.7833563, lng: 21.8171391 },
    { lat: 48.7832156, lng: 21.8167868 },
    { lat: 48.783109, lng: 21.8163251 },
    { lat: 48.7830993, lng: 21.8160421 },
    { lat: 48.7833416, lng: 21.8156688 },
    { lat: 48.7834974, lng: 21.8147827 },
    { lat: 48.7836973, lng: 21.8145539 },
    { lat: 48.7837122, lng: 21.8142579 },
    { lat: 48.7838253, lng: 21.8141998 },
    { lat: 48.7838722, lng: 21.8141018 },
    { lat: 48.7839647, lng: 21.8137581 },
    { lat: 48.7839385, lng: 21.8136137 },
    { lat: 48.7841285, lng: 21.8133138 },
    { lat: 48.7845675, lng: 21.8128456 },
    { lat: 48.7848341, lng: 21.8124088 },
    { lat: 48.7848422, lng: 21.8122506 },
    { lat: 48.7849273, lng: 21.8121075 },
    { lat: 48.7853686, lng: 21.8118178 },
    { lat: 48.7854184, lng: 21.8114827 },
    { lat: 48.785589, lng: 21.8109924 },
    { lat: 48.7856094, lng: 21.8106911 },
    { lat: 48.7854848, lng: 21.8100165 },
    { lat: 48.7856825, lng: 21.8098306 },
    { lat: 48.7858409, lng: 21.8093409 },
    { lat: 48.7861378, lng: 21.8088198 },
    { lat: 48.7862939, lng: 21.8086414 },
    { lat: 48.7864809, lng: 21.808221 },
    { lat: 48.7867277, lng: 21.807974 },
    { lat: 48.7869242, lng: 21.8074453 },
    { lat: 48.7869227, lng: 21.8072524 },
    { lat: 48.7868368, lng: 21.8070677 },
    { lat: 48.78685, lng: 21.8068882 },
    { lat: 48.7870221, lng: 21.8062828 },
    { lat: 48.7872684, lng: 21.8058478 },
    { lat: 48.7873523, lng: 21.8058286 },
    { lat: 48.787662, lng: 21.8061418 },
    { lat: 48.7877563, lng: 21.8061341 },
    { lat: 48.7878232, lng: 21.8059424 },
    { lat: 48.7877856, lng: 21.8051872 },
    { lat: 48.7878919, lng: 21.8048252 },
    { lat: 48.7878657, lng: 21.8046362 },
    { lat: 48.7880254, lng: 21.8044686 },
    { lat: 48.7883671, lng: 21.8046248 },
    { lat: 48.7886596, lng: 21.8046687 },
    { lat: 48.7888139, lng: 21.8043308 },
    { lat: 48.7889828, lng: 21.8036925 },
    { lat: 48.7893746, lng: 21.8028377 },
    { lat: 48.7894983, lng: 21.8027964 },
    { lat: 48.7896939, lng: 21.8028764 },
    { lat: 48.7902968, lng: 21.8026088 },
    { lat: 48.790537, lng: 21.802342 },
    { lat: 48.790558, lng: 21.802272 },
    { lat: 48.790714, lng: 21.801752 },
    { lat: 48.790728, lng: 21.801258 },
    { lat: 48.791348, lng: 21.799885 },
    { lat: 48.791376, lng: 21.799417 },
    { lat: 48.791608, lng: 21.798629 },
    { lat: 48.791818, lng: 21.797949 },
    { lat: 48.791979, lng: 21.797429 },
    { lat: 48.792061, lng: 21.797195 },
    { lat: 48.792224, lng: 21.796555 },
    { lat: 48.79209, lng: 21.795955 },
    { lat: 48.791927, lng: 21.795387 },
    { lat: 48.79187, lng: 21.795033 },
    { lat: 48.79181, lng: 21.794661 },
    { lat: 48.792172, lng: 21.793187 },
    { lat: 48.79209, lng: 21.792449 },
    { lat: 48.792051, lng: 21.791331 },
    { lat: 48.7918, lng: 21.790625 },
    { lat: 48.791559, lng: 21.789138 },
    { lat: 48.791022, lng: 21.788345 },
    { lat: 48.790905, lng: 21.788172 },
    { lat: 48.790903, lng: 21.787593 },
    { lat: 48.790725, lng: 21.786499 },
    { lat: 48.790406, lng: 21.785792 },
    { lat: 48.790031, lng: 21.785123 },
    { lat: 48.789825, lng: 21.784886 },
    { lat: 48.789678, lng: 21.784421 },
    { lat: 48.789431, lng: 21.783804 },
    { lat: 48.789274, lng: 21.782936 },
    { lat: 48.788991, lng: 21.782478 },
    { lat: 48.788634, lng: 21.781927 },
    { lat: 48.788492, lng: 21.781484 },
    { lat: 48.788349, lng: 21.780915 },
    { lat: 48.788484, lng: 21.780381 },
    { lat: 48.78827, lng: 21.780079 },
    { lat: 48.788286, lng: 21.779718 },
    { lat: 48.788195, lng: 21.779461 },
    { lat: 48.787942, lng: 21.778528 },
    { lat: 48.788072, lng: 21.778493 },
    { lat: 48.7882, lng: 21.778458 },
    { lat: 48.788151, lng: 21.778355 },
    { lat: 48.788108, lng: 21.778262 },
    { lat: 48.78808, lng: 21.7782 },
    { lat: 48.787856, lng: 21.778005 },
    { lat: 48.787743, lng: 21.777906 },
    { lat: 48.78763, lng: 21.775596 },
    { lat: 48.787595, lng: 21.774494 },
    { lat: 48.787499, lng: 21.772628 },
    { lat: 48.787474, lng: 21.772204 },
    { lat: 48.787376, lng: 21.770214 },
    { lat: 48.787375, lng: 21.77019 },
    { lat: 48.786643, lng: 21.770426 },
    { lat: 48.785696, lng: 21.77075 },
    { lat: 48.783828, lng: 21.771413 },
    { lat: 48.783819, lng: 21.771378 },
    { lat: 48.783801, lng: 21.771304 },
    { lat: 48.783299, lng: 21.768616 },
    { lat: 48.782629, lng: 21.765888 },
    { lat: 48.782073, lng: 21.763233 },
    { lat: 48.781618, lng: 21.761028 },
    { lat: 48.781616, lng: 21.761017 },
    { lat: 48.781594, lng: 21.760918 },
    { lat: 48.78157, lng: 21.760805 },
    { lat: 48.781548, lng: 21.760703 },
    { lat: 48.781679, lng: 21.760647 },
    { lat: 48.781738, lng: 21.76051 },
    { lat: 48.781673, lng: 21.760345 },
    { lat: 48.781214, lng: 21.760053 },
    { lat: 48.780997, lng: 21.759693 },
    { lat: 48.780885, lng: 21.7593 },
    { lat: 48.780727, lng: 21.758756 },
    { lat: 48.780703, lng: 21.758421 },
    { lat: 48.780867, lng: 21.757738 },
    { lat: 48.7808, lng: 21.756099 },
    { lat: 48.780797, lng: 21.756036 },
    { lat: 48.779304, lng: 21.756153 },
    { lat: 48.778898, lng: 21.756194 },
    { lat: 48.777584, lng: 21.756329 },
    { lat: 48.776996, lng: 21.756321 },
    { lat: 48.775617, lng: 21.756403 },
    { lat: 48.774734, lng: 21.756472 },
    { lat: 48.774874, lng: 21.757427 },
    { lat: 48.774908, lng: 21.757553 },
    { lat: 48.774556, lng: 21.758001 },
    { lat: 48.77421, lng: 21.758532 },
    { lat: 48.774169, lng: 21.758559 },
    { lat: 48.774112, lng: 21.758594 },
    { lat: 48.773951, lng: 21.758528 },
    { lat: 48.773635, lng: 21.75825 },
    { lat: 48.773351, lng: 21.757995 },
    { lat: 48.772526, lng: 21.757422 },
    { lat: 48.772414, lng: 21.757332 },
    { lat: 48.77233, lng: 21.75725 },
    { lat: 48.772069, lng: 21.75686 },
    { lat: 48.772, lng: 21.756755 },
    { lat: 48.77191, lng: 21.756546 },
    { lat: 48.771851, lng: 21.756411 },
    { lat: 48.771821, lng: 21.756342 },
    { lat: 48.771764, lng: 21.756141 },
    { lat: 48.771647, lng: 21.755818 },
    { lat: 48.771065, lng: 21.755878 },
    { lat: 48.770887, lng: 21.755957 },
    { lat: 48.769965, lng: 21.756563 },
    { lat: 48.769777, lng: 21.756646 },
    { lat: 48.769903, lng: 21.756988 },
    { lat: 48.769915, lng: 21.75707 },
    { lat: 48.769933, lng: 21.757184 },
    { lat: 48.76996, lng: 21.757298 },
    { lat: 48.769972, lng: 21.757354 },
    { lat: 48.769998, lng: 21.757464 },
    { lat: 48.770016, lng: 21.757509 },
    { lat: 48.770086, lng: 21.757693 },
    { lat: 48.770124, lng: 21.757855 },
    { lat: 48.770147, lng: 21.758135 },
    { lat: 48.770138, lng: 21.758206 },
    { lat: 48.770079, lng: 21.758267 },
    { lat: 48.769937, lng: 21.758308 },
    { lat: 48.769619, lng: 21.758274 },
    { lat: 48.769428, lng: 21.758184 },
    { lat: 48.769343, lng: 21.75813 },
    { lat: 48.769142, lng: 21.758051 },
    { lat: 48.768971, lng: 21.757968 },
    { lat: 48.768853, lng: 21.757989 },
    { lat: 48.768802, lng: 21.758003 },
    { lat: 48.768745, lng: 21.758119 },
    { lat: 48.768764, lng: 21.758606 },
    { lat: 48.76883, lng: 21.759046 },
    { lat: 48.768835, lng: 21.759382 },
    { lat: 48.768781, lng: 21.759719 },
    { lat: 48.768762, lng: 21.759998 },
    { lat: 48.768776, lng: 21.760117 },
    { lat: 48.768857, lng: 21.760573 },
    { lat: 48.768879, lng: 21.760743 },
    { lat: 48.768884, lng: 21.760949 },
    { lat: 48.768864, lng: 21.761163 },
    { lat: 48.768815, lng: 21.761204 },
    { lat: 48.768718, lng: 21.761263 },
    { lat: 48.768627, lng: 21.761255 },
    { lat: 48.768377, lng: 21.761163 },
    { lat: 48.768275, lng: 21.761124 },
    { lat: 48.768213, lng: 21.761109 },
    { lat: 48.768089, lng: 21.761097 },
    { lat: 48.767996, lng: 21.761144 },
    { lat: 48.767962, lng: 21.76117 },
    { lat: 48.767863, lng: 21.761378 },
    { lat: 48.767741, lng: 21.76202 },
    { lat: 48.76769, lng: 21.762196 },
    { lat: 48.767532, lng: 21.762303 },
    { lat: 48.767261, lng: 21.762397 },
    { lat: 48.767105, lng: 21.762449 },
    { lat: 48.76702, lng: 21.762566 },
    { lat: 48.766943, lng: 21.762841 },
    { lat: 48.766912, lng: 21.762931 },
    { lat: 48.766787, lng: 21.763271 },
    { lat: 48.766763, lng: 21.763395 },
    { lat: 48.766766, lng: 21.763676 },
    { lat: 48.766775, lng: 21.763718 },
    { lat: 48.766839, lng: 21.763836 },
    { lat: 48.766929, lng: 21.764007 },
    { lat: 48.766992, lng: 21.764273 },
    { lat: 48.766983, lng: 21.764418 },
    { lat: 48.766938, lng: 21.764553 },
    { lat: 48.766867, lng: 21.764595 },
    { lat: 48.766836, lng: 21.764602 },
    { lat: 48.766789, lng: 21.764619 },
    { lat: 48.766678, lng: 21.764615 },
    { lat: 48.766578, lng: 21.764607 },
    { lat: 48.76641, lng: 21.764535 },
    { lat: 48.766349, lng: 21.764533 },
    { lat: 48.76628, lng: 21.764511 },
    { lat: 48.766127, lng: 21.764485 },
    { lat: 48.766057, lng: 21.764486 },
    { lat: 48.765969, lng: 21.764507 },
    { lat: 48.765892, lng: 21.764565 },
    { lat: 48.765798, lng: 21.764673 },
    { lat: 48.765712, lng: 21.764931 },
    { lat: 48.765682, lng: 21.765026 },
    { lat: 48.765641, lng: 21.765235 },
    { lat: 48.765612, lng: 21.765326 },
    { lat: 48.765603, lng: 21.765391 },
    { lat: 48.765556, lng: 21.76546 },
    { lat: 48.765493, lng: 21.765499 },
    { lat: 48.765394, lng: 21.765566 },
    { lat: 48.765374, lng: 21.765562 },
    { lat: 48.765172, lng: 21.765696 },
    { lat: 48.765113, lng: 21.765746 },
    { lat: 48.76504, lng: 21.765824 },
    { lat: 48.764974, lng: 21.765903 },
    { lat: 48.764795, lng: 21.76621 },
    { lat: 48.764695, lng: 21.766354 },
    { lat: 48.764547, lng: 21.766412 },
    { lat: 48.76439, lng: 21.766372 },
    { lat: 48.763947, lng: 21.766278 },
    { lat: 48.763871, lng: 21.766252 },
    { lat: 48.761306, lng: 21.766016 },
    { lat: 48.761052, lng: 21.766029 },
    { lat: 48.756765, lng: 21.766254 },
    { lat: 48.756754, lng: 21.766255 },
    { lat: 48.756552, lng: 21.766266 },
    { lat: 48.7568619, lng: 21.7750997 },
    { lat: 48.7570491, lng: 21.7787024 },
    { lat: 48.7559822, lng: 21.7839421 },
    { lat: 48.7587117, lng: 21.8024255 },
    { lat: 48.7594909, lng: 21.8079684 },
    { lat: 48.7593209, lng: 21.8080032 },
    { lat: 48.7590674, lng: 21.8079463 },
    { lat: 48.758899, lng: 21.8078362 },
    { lat: 48.7589876, lng: 21.8073463 },
    { lat: 48.7587608, lng: 21.8072376 },
    { lat: 48.7587617, lng: 21.8071448 },
    { lat: 48.7586585, lng: 21.807023 },
    { lat: 48.7586548, lng: 21.8071865 },
    { lat: 48.7584267, lng: 21.8073267 },
    { lat: 48.7583487, lng: 21.8075275 },
    { lat: 48.7572018, lng: 21.8072911 },
    { lat: 48.7569067, lng: 21.8071059 },
    { lat: 48.7567457, lng: 21.8070952 },
    { lat: 48.7567363, lng: 21.8072141 },
    { lat: 48.7561785, lng: 21.8073943 },
    { lat: 48.7561375, lng: 21.807503 },
    { lat: 48.7557167, lng: 21.807675 },
    { lat: 48.7555449, lng: 21.8064388 },
    { lat: 48.7552801, lng: 21.8063751 },
    { lat: 48.7553019, lng: 21.805578 },
    { lat: 48.7551593, lng: 21.8055431 },
    { lat: 48.7549602, lng: 21.8056366 },
    { lat: 48.7548713, lng: 21.8052472 },
    { lat: 48.7545061, lng: 21.8054348 },
    { lat: 48.7543648, lng: 21.80571 },
    { lat: 48.7537445, lng: 21.8059078 },
    { lat: 48.7534249, lng: 21.8061622 },
    { lat: 48.7535306, lng: 21.8067631 },
    { lat: 48.75268, lng: 21.8070143 },
    { lat: 48.7523286, lng: 21.8069996 },
    { lat: 48.7517485, lng: 21.8073665 },
    { lat: 48.7515828, lng: 21.8073928 },
    { lat: 48.7512435, lng: 21.8073721 },
    { lat: 48.7512476, lng: 21.806754 },
    { lat: 48.7509516, lng: 21.8067036 },
    { lat: 48.7506255, lng: 21.8067559 },
    { lat: 48.7505784, lng: 21.8060275 },
    { lat: 48.7500578, lng: 21.80586 },
    { lat: 48.7494429, lng: 21.8060586 },
    { lat: 48.7494746, lng: 21.8055911 },
    { lat: 48.7485959, lng: 21.8052451 },
    { lat: 48.7488897, lng: 21.8038707 },
    { lat: 48.7485508, lng: 21.8037398 },
    { lat: 48.7482062, lng: 21.8033344 },
    { lat: 48.7478802, lng: 21.8025236 },
    { lat: 48.7484046, lng: 21.8018431 },
    { lat: 48.7483254, lng: 21.8013933 },
    { lat: 48.7479956, lng: 21.8015207 },
    { lat: 48.7473327, lng: 21.8012499 },
    { lat: 48.7473314, lng: 21.8016353 },
    { lat: 48.746977, lng: 21.8018034 },
    { lat: 48.7469707, lng: 21.802038 },
    { lat: 48.7467671, lng: 21.8020348 },
    { lat: 48.7467013, lng: 21.8017532 },
    { lat: 48.7467232, lng: 21.8013912 },
    { lat: 48.7468094, lng: 21.8009465 },
    { lat: 48.74697, lng: 21.800975 },
    { lat: 48.7469504, lng: 21.8001366 },
    { lat: 48.7468735, lng: 21.7999099 },
    { lat: 48.7468163, lng: 21.798718 },
    { lat: 48.7466776, lng: 21.7982733 },
    { lat: 48.7465018, lng: 21.7982649 },
    { lat: 48.7464955, lng: 21.7991771 },
    { lat: 48.746151, lng: 21.7992562 },
    { lat: 48.7465168, lng: 21.8190494 },
    { lat: 48.7466563, lng: 21.819177 },
    { lat: 48.7473885, lng: 21.8181795 },
    { lat: 48.7475098, lng: 21.8178671 },
    { lat: 48.7477863, lng: 21.8173854 },
    { lat: 48.7477697, lng: 21.8172638 },
    { lat: 48.7479175, lng: 21.8169381 },
    { lat: 48.748093, lng: 21.8168856 },
    { lat: 48.7481577, lng: 21.8169548 },
    { lat: 48.7482972, lng: 21.8167807 },
    { lat: 48.7484924, lng: 21.8167174 },
    { lat: 48.7485931, lng: 21.8164376 },
    { lat: 48.7489628, lng: 21.8162484 },
    { lat: 48.7490312, lng: 21.8161502 },
    { lat: 48.7490276, lng: 21.8158838 },
    { lat: 48.7491775, lng: 21.8158096 },
    { lat: 48.7492378, lng: 21.8156631 },
    { lat: 48.7494304, lng: 21.815457 },
    { lat: 48.7497377, lng: 21.8153339 },
    { lat: 48.7500272, lng: 21.8152988 },
    { lat: 48.7500384, lng: 21.8150846 },
    { lat: 48.750433, lng: 21.8148572 },
    { lat: 48.75086, lng: 21.814487 },
    { lat: 48.7514894, lng: 21.8146866 },
    { lat: 48.7519005, lng: 21.8143148 },
    { lat: 48.7521632, lng: 21.8144607 },
    { lat: 48.752277, lng: 21.8143851 },
    { lat: 48.7523436, lng: 21.8144357 },
    { lat: 48.7524985, lng: 21.8143352 },
    { lat: 48.7525794, lng: 21.8143753 },
    { lat: 48.752879, lng: 21.8141285 },
    { lat: 48.7535145, lng: 21.8140365 },
    { lat: 48.7535493, lng: 21.8158969 },
    { lat: 48.7537445, lng: 21.8171634 },
    { lat: 48.7541984, lng: 21.8180117 },
    { lat: 48.7546966, lng: 21.8190805 },
  ],
  Voľa: [
    { lat: 48.8321434, lng: 21.829065 },
    { lat: 48.8321038, lng: 21.8290102 },
    { lat: 48.8289548, lng: 21.8319601 },
    { lat: 48.827191, lng: 21.8343591 },
    { lat: 48.8263832, lng: 21.8323094 },
    { lat: 48.8241046, lng: 21.8304591 },
    { lat: 48.8243078, lng: 21.8319269 },
    { lat: 48.8254791, lng: 21.8370704 },
    { lat: 48.8258041, lng: 21.8382004 },
    { lat: 48.8261201, lng: 21.8407245 },
    { lat: 48.8264433, lng: 21.8420889 },
    { lat: 48.8284001, lng: 21.840012 },
    { lat: 48.8298884, lng: 21.8379212 },
    { lat: 48.8299974, lng: 21.8380893 },
    { lat: 48.8301306, lng: 21.838456 },
    { lat: 48.8311365, lng: 21.8431297 },
    { lat: 48.8316977, lng: 21.8456174 },
    { lat: 48.8317345, lng: 21.8455992 },
    { lat: 48.8327325, lng: 21.8501594 },
    { lat: 48.8324508, lng: 21.8501878 },
    { lat: 48.8314518, lng: 21.8506213 },
    { lat: 48.8310908, lng: 21.8509725 },
    { lat: 48.830781, lng: 21.8514413 },
    { lat: 48.8304166, lng: 21.8516621 },
    { lat: 48.8296637, lng: 21.8527005 },
    { lat: 48.8296503, lng: 21.8528776 },
    { lat: 48.8293158, lng: 21.8530423 },
    { lat: 48.8286739, lng: 21.8538499 },
    { lat: 48.8285217, lng: 21.8545787 },
    { lat: 48.8283577, lng: 21.8549879 },
    { lat: 48.8284215, lng: 21.855058 },
    { lat: 48.8319134, lng: 21.8589162 },
    { lat: 48.8372761, lng: 21.8579132 },
    { lat: 48.8378016, lng: 21.8579151 },
    { lat: 48.8379587, lng: 21.8578552 },
    { lat: 48.8386011, lng: 21.8573785 },
    { lat: 48.8394384, lng: 21.8569525 },
    { lat: 48.8432477, lng: 21.8585383 },
    { lat: 48.8483149, lng: 21.8568887 },
    { lat: 48.8556677, lng: 21.8536931 },
    { lat: 48.8554329, lng: 21.8532686 },
    { lat: 48.8549319, lng: 21.8523311 },
    { lat: 48.854818, lng: 21.8518326 },
    { lat: 48.854781, lng: 21.8510061 },
    { lat: 48.8549658, lng: 21.8500985 },
    { lat: 48.8552749, lng: 21.8493483 },
    { lat: 48.8559314, lng: 21.8483647 },
    { lat: 48.8563173, lng: 21.847926 },
    { lat: 48.8566997, lng: 21.8476155 },
    { lat: 48.8565785, lng: 21.8467996 },
    { lat: 48.8574716, lng: 21.8465279 },
    { lat: 48.8570565, lng: 21.8449158 },
    { lat: 48.8569269, lng: 21.8439843 },
    { lat: 48.8568219, lng: 21.8428355 },
    { lat: 48.856721, lng: 21.840449 },
    { lat: 48.8566173, lng: 21.8397885 },
    { lat: 48.8565599, lng: 21.8369054 },
    { lat: 48.8564988, lng: 21.8364121 },
    { lat: 48.8562854, lng: 21.8357316 },
    { lat: 48.8561987, lng: 21.8345936 },
    { lat: 48.8557228, lng: 21.8346308 },
    { lat: 48.8554648, lng: 21.8330478 },
    { lat: 48.8553934, lng: 21.8330316 },
    { lat: 48.8549898, lng: 21.8309504 },
    { lat: 48.854803, lng: 21.8306914 },
    { lat: 48.8542776, lng: 21.8309505 },
    { lat: 48.8537053, lng: 21.8314335 },
    { lat: 48.853342, lng: 21.8313504 },
    { lat: 48.8528709, lng: 21.8315413 },
    { lat: 48.8526619, lng: 21.8314622 },
    { lat: 48.8525558, lng: 21.8311881 },
    { lat: 48.8524895, lng: 21.8305366 },
    { lat: 48.8520693, lng: 21.8308144 },
    { lat: 48.851888, lng: 21.8307698 },
    { lat: 48.8517894, lng: 21.8304767 },
    { lat: 48.851547, lng: 21.830071 },
    { lat: 48.851415, lng: 21.8301806 },
    { lat: 48.8513808, lng: 21.8303816 },
    { lat: 48.8514343, lng: 21.8309293 },
    { lat: 48.8516157, lng: 21.8310682 },
    { lat: 48.8516754, lng: 21.8312429 },
    { lat: 48.8515852, lng: 21.831459 },
    { lat: 48.8507576, lng: 21.8316867 },
    { lat: 48.8503108, lng: 21.8313764 },
    { lat: 48.8501083, lng: 21.8313756 },
    { lat: 48.8499802, lng: 21.8315978 },
    { lat: 48.8499058, lng: 21.8318566 },
    { lat: 48.8500289, lng: 21.8324797 },
    { lat: 48.8499402, lng: 21.8329701 },
    { lat: 48.8494733, lng: 21.8327102 },
    { lat: 48.8492876, lng: 21.8319193 },
    { lat: 48.8490833, lng: 21.8317661 },
    { lat: 48.84883, lng: 21.8314514 },
    { lat: 48.848698, lng: 21.8311321 },
    { lat: 48.8487116, lng: 21.8309195 },
    { lat: 48.8489954, lng: 21.830455 },
    { lat: 48.8490008, lng: 21.8303158 },
    { lat: 48.848923, lng: 21.8302164 },
    { lat: 48.848788, lng: 21.8302655 },
    { lat: 48.8481077, lng: 21.8296201 },
    { lat: 48.8479952, lng: 21.8296182 },
    { lat: 48.8472976, lng: 21.8303158 },
    { lat: 48.8453323, lng: 21.8315315 },
    { lat: 48.8448428, lng: 21.8314816 },
    { lat: 48.8442436, lng: 21.8315458 },
    { lat: 48.8440802, lng: 21.831443 },
    { lat: 48.8439599, lng: 21.8311847 },
    { lat: 48.8436181, lng: 21.8311497 },
    { lat: 48.8435095, lng: 21.8313004 },
    { lat: 48.8434784, lng: 21.8317465 },
    { lat: 48.8433997, lng: 21.8319666 },
    { lat: 48.8430569, lng: 21.8324209 },
    { lat: 48.8428934, lng: 21.8323145 },
    { lat: 48.8428583, lng: 21.8319169 },
    { lat: 48.8427751, lng: 21.831744 },
    { lat: 48.8426075, lng: 21.8316164 },
    { lat: 48.8424449, lng: 21.83159 },
    { lat: 48.8421359, lng: 21.8318673 },
    { lat: 48.842046, lng: 21.8318251 },
    { lat: 48.8420092, lng: 21.8315794 },
    { lat: 48.8420486, lng: 21.8311417 },
    { lat: 48.8419891, lng: 21.8310188 },
    { lat: 48.8418317, lng: 21.8309303 },
    { lat: 48.8417037, lng: 21.8306861 },
    { lat: 48.8416347, lng: 21.8303595 },
    { lat: 48.8414407, lng: 21.8299946 },
    { lat: 48.841381, lng: 21.8299928 },
    { lat: 48.8406508, lng: 21.8303344 },
    { lat: 48.8403957, lng: 21.830562 },
    { lat: 48.839725, lng: 21.8309429 },
    { lat: 48.8395485, lng: 21.8311741 },
    { lat: 48.8393309, lng: 21.8317223 },
    { lat: 48.8393017, lng: 21.8319419 },
    { lat: 48.8394876, lng: 21.8327315 },
    { lat: 48.8394624, lng: 21.8328862 },
    { lat: 48.8393761, lng: 21.8329849 },
    { lat: 48.8391637, lng: 21.832904 },
    { lat: 48.8389274, lng: 21.8326898 },
    { lat: 48.8385952, lng: 21.8321942 },
    { lat: 48.8385352, lng: 21.8320084 },
    { lat: 48.8385504, lng: 21.8317661 },
    { lat: 48.8386231, lng: 21.8315775 },
    { lat: 48.8385122, lng: 21.8310291 },
    { lat: 48.8385512, lng: 21.8307593 },
    { lat: 48.8387785, lng: 21.8302787 },
    { lat: 48.8387216, lng: 21.8293829 },
    { lat: 48.838649, lng: 21.829189 },
    { lat: 48.8384486, lng: 21.829133 },
    { lat: 48.8382036, lng: 21.8295436 },
    { lat: 48.837885, lng: 21.8297 },
    { lat: 48.8377156, lng: 21.8298962 },
    { lat: 48.8374206, lng: 21.8305397 },
    { lat: 48.8369831, lng: 21.8308506 },
    { lat: 48.8366159, lng: 21.8308721 },
    { lat: 48.8360977, lng: 21.8304497 },
    { lat: 48.835799, lng: 21.8303042 },
    { lat: 48.8356033, lng: 21.8304769 },
    { lat: 48.8355221, lng: 21.8309441 },
    { lat: 48.8353583, lng: 21.831312 },
    { lat: 48.8346641, lng: 21.8315696 },
    { lat: 48.8343921, lng: 21.8315336 },
    { lat: 48.8342082, lng: 21.831432 },
    { lat: 48.8338914, lng: 21.8310502 },
    { lat: 48.8333376, lng: 21.8305214 },
    { lat: 48.8321434, lng: 21.829065 },
  ],
  Petrikovce: [
    { lat: 48.5411588, lng: 21.8680722 },
    { lat: 48.5413031, lng: 21.8685231 },
    { lat: 48.5426053, lng: 21.8677599 },
    { lat: 48.5435299, lng: 21.8671329 },
    { lat: 48.5438147, lng: 21.8685475 },
    { lat: 48.5453524, lng: 21.8677908 },
    { lat: 48.5457257, lng: 21.8691789 },
    { lat: 48.5463805, lng: 21.8709861 },
    { lat: 48.547958, lng: 21.8697686 },
    { lat: 48.5486601, lng: 21.8691532 },
    { lat: 48.5492274, lng: 21.8706516 },
    { lat: 48.5505707, lng: 21.869561 },
    { lat: 48.550739, lng: 21.8698885 },
    { lat: 48.5524137, lng: 21.8683833 },
    { lat: 48.5528323, lng: 21.8695251 },
    { lat: 48.5532789, lng: 21.8709731 },
    { lat: 48.5540069, lng: 21.8729009 },
    { lat: 48.5546757, lng: 21.8724408 },
    { lat: 48.5548882, lng: 21.8731436 },
    { lat: 48.5550378, lng: 21.8730447 },
    { lat: 48.5552669, lng: 21.8737789 },
    { lat: 48.5561231, lng: 21.8732095 },
    { lat: 48.5564059, lng: 21.8741072 },
    { lat: 48.5568907, lng: 21.8737061 },
    { lat: 48.5568087, lng: 21.8732297 },
    { lat: 48.5565174, lng: 21.8713491 },
    { lat: 48.556398, lng: 21.8708109 },
    { lat: 48.5575735, lng: 21.8701057 },
    { lat: 48.558495, lng: 21.8694536 },
    { lat: 48.5581769, lng: 21.8682838 },
    { lat: 48.5608057, lng: 21.8666036 },
    { lat: 48.5610143, lng: 21.8665741 },
    { lat: 48.5607363, lng: 21.8640341 },
    { lat: 48.5605528, lng: 21.8606704 },
    { lat: 48.5608736, lng: 21.8568698 },
    { lat: 48.5605278, lng: 21.8565981 },
    { lat: 48.5606186, lng: 21.8558402 },
    { lat: 48.560357, lng: 21.8546831 },
    { lat: 48.5602496, lng: 21.8544522 },
    { lat: 48.5598608, lng: 21.8539461 },
    { lat: 48.5592711, lng: 21.8535413 },
    { lat: 48.5591301, lng: 21.8533109 },
    { lat: 48.5589914, lng: 21.8528598 },
    { lat: 48.5589214, lng: 21.8517665 },
    { lat: 48.5587968, lng: 21.8512524 },
    { lat: 48.5584236, lng: 21.8500487 },
    { lat: 48.5577319, lng: 21.8484407 },
    { lat: 48.5573391, lng: 21.8479207 },
    { lat: 48.5569696, lng: 21.8477279 },
    { lat: 48.5565843, lng: 21.8477519 },
    { lat: 48.5564449, lng: 21.8471848 },
    { lat: 48.5559129, lng: 21.8455856 },
    { lat: 48.5550753, lng: 21.8459136 },
    { lat: 48.5544645, lng: 21.8462619 },
    { lat: 48.5541965, lng: 21.8462997 },
    { lat: 48.5543437, lng: 21.8458102 },
    { lat: 48.5544327, lng: 21.8448117 },
    { lat: 48.5544101, lng: 21.8442047 },
    { lat: 48.5547177, lng: 21.8433943 },
    { lat: 48.5547146, lng: 21.8431456 },
    { lat: 48.5544851, lng: 21.8428426 },
    { lat: 48.5538673, lng: 21.8425704 },
    { lat: 48.5534125, lng: 21.8414341 },
    { lat: 48.5531172, lng: 21.8412029 },
    { lat: 48.5525209, lng: 21.8409354 },
    { lat: 48.5523234, lng: 21.8407386 },
    { lat: 48.5516339, lng: 21.8386666 },
    { lat: 48.5514451, lng: 21.8379789 },
    { lat: 48.5512066, lng: 21.8375455 },
    { lat: 48.5500034, lng: 21.8376146 },
    { lat: 48.5487236, lng: 21.8378462 },
    { lat: 48.5477127, lng: 21.8382633 },
    { lat: 48.5450307, lng: 21.8353656 },
    { lat: 48.5446323, lng: 21.8346651 },
    { lat: 48.5444665, lng: 21.8345648 },
    { lat: 48.5441436, lng: 21.8345117 },
    { lat: 48.5438044, lng: 21.8354041 },
    { lat: 48.5436595, lng: 21.8354979 },
    { lat: 48.5435212, lng: 21.8355025 },
    { lat: 48.5433983, lng: 21.8354067 },
    { lat: 48.5432652, lng: 21.835051 },
    { lat: 48.5432294, lng: 21.8343622 },
    { lat: 48.5431129, lng: 21.8342501 },
    { lat: 48.5429896, lng: 21.8342709 },
    { lat: 48.5428526, lng: 21.8344945 },
    { lat: 48.5425917, lng: 21.8357958 },
    { lat: 48.5424436, lng: 21.8359011 },
    { lat: 48.5422943, lng: 21.8358737 },
    { lat: 48.5422024, lng: 21.8357573 },
    { lat: 48.5421191, lng: 21.8354448 },
    { lat: 48.5421063, lng: 21.8349972 },
    { lat: 48.5420442, lng: 21.8346501 },
    { lat: 48.5418989, lng: 21.8343377 },
    { lat: 48.5413456, lng: 21.8341296 },
    { lat: 48.5411339, lng: 21.8341229 },
    { lat: 48.5404957, lng: 21.8345393 },
    { lat: 48.539995, lng: 21.8345394 },
    { lat: 48.5398584, lng: 21.8349546 },
    { lat: 48.5399323, lng: 21.8352844 },
    { lat: 48.5401045, lng: 21.8355043 },
    { lat: 48.5401383, lng: 21.8356823 },
    { lat: 48.5401283, lng: 21.835859 },
    { lat: 48.5400506, lng: 21.8360361 },
    { lat: 48.5399206, lng: 21.83614 },
    { lat: 48.5396464, lng: 21.8361029 },
    { lat: 48.5390483, lng: 21.8354316 },
    { lat: 48.5385515, lng: 21.8352298 },
    { lat: 48.5383563, lng: 21.835247 },
    { lat: 48.5377764, lng: 21.835667 },
    { lat: 48.5377385, lng: 21.8356331 },
    { lat: 48.5375111, lng: 21.8360643 },
    { lat: 48.5372354, lng: 21.8364043 },
    { lat: 48.5366084, lng: 21.8369162 },
    { lat: 48.5362569, lng: 21.8371982 },
    { lat: 48.535815, lng: 21.8372945 },
    { lat: 48.5353503, lng: 21.8371678 },
    { lat: 48.5351238, lng: 21.8369352 },
    { lat: 48.5350074, lng: 21.8367013 },
    { lat: 48.5348969, lng: 21.8358229 },
    { lat: 48.534664, lng: 21.835299 },
    { lat: 48.534491, lng: 21.835179 },
    { lat: 48.534276, lng: 21.835179 },
    { lat: 48.534191, lng: 21.83526 },
    { lat: 48.533966, lng: 21.835477 },
    { lat: 48.533789, lng: 21.835551 },
    { lat: 48.533784, lng: 21.835553 },
    { lat: 48.533625, lng: 21.83562 },
    { lat: 48.5336293, lng: 21.8375667 },
    { lat: 48.5337145, lng: 21.8390313 },
    { lat: 48.5338091, lng: 21.840025 },
    { lat: 48.534013, lng: 21.8409051 },
    { lat: 48.534206, lng: 21.8412739 },
    { lat: 48.5344618, lng: 21.8415588 },
    { lat: 48.5348662, lng: 21.8417867 },
    { lat: 48.535336, lng: 21.8418582 },
    { lat: 48.5363859, lng: 21.8414343 },
    { lat: 48.5366069, lng: 21.8414916 },
    { lat: 48.5369308, lng: 21.8417076 },
    { lat: 48.5372204, lng: 21.842332 },
    { lat: 48.5375563, lng: 21.8435293 },
    { lat: 48.5376418, lng: 21.8440923 },
    { lat: 48.5377087, lng: 21.8450717 },
    { lat: 48.5375084, lng: 21.8459412 },
    { lat: 48.5371209, lng: 21.8467449 },
    { lat: 48.5369439, lng: 21.8469921 },
    { lat: 48.5367698, lng: 21.8471341 },
    { lat: 48.5369749, lng: 21.8477085 },
    { lat: 48.5370929, lng: 21.8482234 },
    { lat: 48.5372095, lng: 21.8484289 },
    { lat: 48.536561, lng: 21.8511099 },
    { lat: 48.5363369, lng: 21.8517997 },
    { lat: 48.5359516, lng: 21.8525163 },
    { lat: 48.5350907, lng: 21.8533285 },
    { lat: 48.5348432, lng: 21.8534594 },
    { lat: 48.534976, lng: 21.8541652 },
    { lat: 48.5356222, lng: 21.8561828 },
    { lat: 48.5363382, lng: 21.8575097 },
    { lat: 48.5369305, lng: 21.8583161 },
    { lat: 48.5373428, lng: 21.8587744 },
    { lat: 48.5378242, lng: 21.8591563 },
    { lat: 48.5380198, lng: 21.8594552 },
    { lat: 48.5379933, lng: 21.8598263 },
    { lat: 48.5374904, lng: 21.8609218 },
    { lat: 48.5376342, lng: 21.8611707 },
    { lat: 48.5379137, lng: 21.8618879 },
    { lat: 48.5384772, lng: 21.8635494 },
    { lat: 48.5397476, lng: 21.8670073 },
    { lat: 48.5406893, lng: 21.8663073 },
    { lat: 48.5410205, lng: 21.8675516 },
    { lat: 48.5411588, lng: 21.8680722 },
  ],
  Suché: [
    { lat: 48.7546966, lng: 21.8190805 },
    { lat: 48.7551566, lng: 21.8201177 },
    { lat: 48.7557479, lng: 21.8210534 },
    { lat: 48.755949, lng: 21.8216433 },
    { lat: 48.7560877, lng: 21.8224794 },
    { lat: 48.7568976, lng: 21.8245095 },
    { lat: 48.757552, lng: 21.8253638 },
    { lat: 48.7586451, lng: 21.8256526 },
    { lat: 48.7592166, lng: 21.8259186 },
    { lat: 48.7597364, lng: 21.8263063 },
    { lat: 48.7600112, lng: 21.8264055 },
    { lat: 48.7586041, lng: 21.827415 },
    { lat: 48.7584631, lng: 21.828005 },
    { lat: 48.7584224, lng: 21.8292999 },
    { lat: 48.7585661, lng: 21.8292871 },
    { lat: 48.7587202, lng: 21.8300656 },
    { lat: 48.7586443, lng: 21.8312207 },
    { lat: 48.7588296, lng: 21.8329879 },
    { lat: 48.759109, lng: 21.8346097 },
    { lat: 48.7596049, lng: 21.8346071 },
    { lat: 48.7598394, lng: 21.8349334 },
    { lat: 48.7599571, lng: 21.8349514 },
    { lat: 48.7600431, lng: 21.835499 },
    { lat: 48.7602148, lng: 21.8354842 },
    { lat: 48.7604692, lng: 21.8366137 },
    { lat: 48.7607723, lng: 21.8368486 },
    { lat: 48.7609314, lng: 21.8370856 },
    { lat: 48.7610934, lng: 21.8370033 },
    { lat: 48.7611969, lng: 21.8373523 },
    { lat: 48.7613457, lng: 21.8373157 },
    { lat: 48.7613192, lng: 21.8377701 },
    { lat: 48.7612053, lng: 21.8382429 },
    { lat: 48.7611662, lng: 21.8389904 },
    { lat: 48.7610775, lng: 21.8393448 },
    { lat: 48.7613216, lng: 21.8404343 },
    { lat: 48.7609906, lng: 21.8411652 },
    { lat: 48.7614077, lng: 21.8420263 },
    { lat: 48.7611443, lng: 21.8421991 },
    { lat: 48.7607228, lng: 21.8429482 },
    { lat: 48.7608285, lng: 21.843122 },
    { lat: 48.7607001, lng: 21.8436395 },
    { lat: 48.7605119, lng: 21.8440642 },
    { lat: 48.7605883, lng: 21.8441478 },
    { lat: 48.7603396, lng: 21.8442305 },
    { lat: 48.7603132, lng: 21.8441686 },
    { lat: 48.7602488, lng: 21.8442462 },
    { lat: 48.7600186, lng: 21.8448293 },
    { lat: 48.7598749, lng: 21.8454528 },
    { lat: 48.7598355, lng: 21.8454074 },
    { lat: 48.7590205, lng: 21.8464748 },
    { lat: 48.7585623, lng: 21.8471643 },
    { lat: 48.7575542, lng: 21.8482513 },
    { lat: 48.7569429, lng: 21.84946 },
    { lat: 48.7575049, lng: 21.8507939 },
    { lat: 48.7578267, lng: 21.8504016 },
    { lat: 48.7578517, lng: 21.8504561 },
    { lat: 48.7579649, lng: 21.8507415 },
    { lat: 48.7585514, lng: 21.8535202 },
    { lat: 48.7590805, lng: 21.854624 },
    { lat: 48.7598333, lng: 21.8548938 },
    { lat: 48.7604046, lng: 21.8547123 },
    { lat: 48.7604503, lng: 21.8553097 },
    { lat: 48.7609162, lng: 21.8552666 },
    { lat: 48.7610211, lng: 21.8553196 },
    { lat: 48.761091, lng: 21.8554624 },
    { lat: 48.7611687, lng: 21.8562117 },
    { lat: 48.7613838, lng: 21.8560881 },
    { lat: 48.7616001, lng: 21.8561022 },
    { lat: 48.7616655, lng: 21.8562802 },
    { lat: 48.7616328, lng: 21.8569785 },
    { lat: 48.7616681, lng: 21.8571258 },
    { lat: 48.7618165, lng: 21.8572954 },
    { lat: 48.761927, lng: 21.8573459 },
    { lat: 48.7622254, lng: 21.8570528 },
    { lat: 48.7623549, lng: 21.8570188 },
    { lat: 48.7624889, lng: 21.8571054 },
    { lat: 48.7627477, lng: 21.8574607 },
    { lat: 48.7628547, lng: 21.8573763 },
    { lat: 48.762889, lng: 21.8571778 },
    { lat: 48.7630489, lng: 21.857008 },
    { lat: 48.763155, lng: 21.8570245 },
    { lat: 48.7632323, lng: 21.8571437 },
    { lat: 48.7634616, lng: 21.8572686 },
    { lat: 48.7636345, lng: 21.8573405 },
    { lat: 48.763691, lng: 21.8573086 },
    { lat: 48.7636744, lng: 21.8571855 },
    { lat: 48.7634173, lng: 21.8568569 },
    { lat: 48.7634177, lng: 21.8567813 },
    { lat: 48.7634714, lng: 21.8566879 },
    { lat: 48.7636862, lng: 21.8567248 },
    { lat: 48.7637995, lng: 21.856623 },
    { lat: 48.7639444, lng: 21.8562243 },
    { lat: 48.763961, lng: 21.8560354 },
    { lat: 48.763867, lng: 21.8557075 },
    { lat: 48.7638873, lng: 21.8555355 },
    { lat: 48.7640161, lng: 21.8554624 },
    { lat: 48.7642304, lng: 21.8556652 },
    { lat: 48.7644099, lng: 21.8560822 },
    { lat: 48.7645897, lng: 21.8562082 },
    { lat: 48.7647541, lng: 21.8562113 },
    { lat: 48.7652449, lng: 21.8558284 },
    { lat: 48.7661681, lng: 21.8554161 },
    { lat: 48.7663088, lng: 21.8560754 },
    { lat: 48.7668794, lng: 21.8560864 },
    { lat: 48.7669707, lng: 21.8565775 },
    { lat: 48.7671075, lng: 21.8569913 },
    { lat: 48.7671865, lng: 21.8571414 },
    { lat: 48.7674123, lng: 21.8573421 },
    { lat: 48.7674507, lng: 21.8573049 },
    { lat: 48.7675866, lng: 21.8575651 },
    { lat: 48.767447, lng: 21.8577302 },
    { lat: 48.7674184, lng: 21.857973 },
    { lat: 48.7675652, lng: 21.8587889 },
    { lat: 48.7675322, lng: 21.8593389 },
    { lat: 48.7675703, lng: 21.8599073 },
    { lat: 48.7676189, lng: 21.8600906 },
    { lat: 48.7678778, lng: 21.8604912 },
    { lat: 48.7680672, lng: 21.86099 },
    { lat: 48.7680592, lng: 21.8612006 },
    { lat: 48.7679657, lng: 21.8613337 },
    { lat: 48.7680583, lng: 21.8623985 },
    { lat: 48.7681865, lng: 21.8624815 },
    { lat: 48.7686861, lng: 21.8642393 },
    { lat: 48.7689803, lng: 21.8641389 },
    { lat: 48.7691943, lng: 21.8653343 },
    { lat: 48.7685854, lng: 21.8658043 },
    { lat: 48.7678785, lng: 21.8657894 },
    { lat: 48.7678472, lng: 21.8658836 },
    { lat: 48.7679775, lng: 21.866535 },
    { lat: 48.768262, lng: 21.8673599 },
    { lat: 48.7682877, lng: 21.867796 },
    { lat: 48.768301, lng: 21.8678377 },
    { lat: 48.7683285, lng: 21.8677694 },
    { lat: 48.7688869, lng: 21.8678308 },
    { lat: 48.7695896, lng: 21.8675411 },
    { lat: 48.7701747, lng: 21.8674223 },
    { lat: 48.770708, lng: 21.8668073 },
    { lat: 48.7706563, lng: 21.8667208 },
    { lat: 48.7704766, lng: 21.863966 },
    { lat: 48.7713931, lng: 21.8632482 },
    { lat: 48.771308, lng: 21.8629533 },
    { lat: 48.7719454, lng: 21.8621869 },
    { lat: 48.7723316, lng: 21.8618051 },
    { lat: 48.7724455, lng: 21.8612891 },
    { lat: 48.7722397, lng: 21.8598001 },
    { lat: 48.7733634, lng: 21.8591809 },
    { lat: 48.7731775, lng: 21.8582478 },
    { lat: 48.7736431, lng: 21.8576804 },
    { lat: 48.7737857, lng: 21.8573593 },
    { lat: 48.7736091, lng: 21.85688 },
    { lat: 48.7745113, lng: 21.8564838 },
    { lat: 48.774866, lng: 21.856061 },
    { lat: 48.7753542, lng: 21.855701 },
    { lat: 48.7755026, lng: 21.8553103 },
    { lat: 48.7754993, lng: 21.8550911 },
    { lat: 48.7750063, lng: 21.8543368 },
    { lat: 48.7757296, lng: 21.8534133 },
    { lat: 48.7761178, lng: 21.8537682 },
    { lat: 48.7763285, lng: 21.8537355 },
    { lat: 48.7765559, lng: 21.8536133 },
    { lat: 48.7768761, lng: 21.853113 },
    { lat: 48.7770814, lng: 21.8530028 },
    { lat: 48.7772762, lng: 21.8530955 },
    { lat: 48.777329, lng: 21.8533178 },
    { lat: 48.7773031, lng: 21.8536214 },
    { lat: 48.7775699, lng: 21.8534977 },
    { lat: 48.7776988, lng: 21.8533053 },
    { lat: 48.7779123, lng: 21.8527231 },
    { lat: 48.7780655, lng: 21.852209 },
    { lat: 48.778164, lng: 21.8514467 },
    { lat: 48.7784345, lng: 21.8509845 },
    { lat: 48.7784069, lng: 21.8504789 },
    { lat: 48.7784905, lng: 21.8501826 },
    { lat: 48.7787165, lng: 21.8498304 },
    { lat: 48.7784212, lng: 21.8493752 },
    { lat: 48.7782613, lng: 21.8492362 },
    { lat: 48.7780847, lng: 21.8488404 },
    { lat: 48.7781708, lng: 21.8482118 },
    { lat: 48.778292, lng: 21.8477181 },
    { lat: 48.778645, lng: 21.847448 },
    { lat: 48.7791532, lng: 21.8473085 },
    { lat: 48.7794035, lng: 21.8469749 },
    { lat: 48.7794939, lng: 21.8469442 },
    { lat: 48.7799395, lng: 21.847082 },
    { lat: 48.7801062, lng: 21.8470451 },
    { lat: 48.7802347, lng: 21.8469291 },
    { lat: 48.7809021, lng: 21.8456319 },
    { lat: 48.7812969, lng: 21.8450597 },
    { lat: 48.7817245, lng: 21.8450598 },
    { lat: 48.7826575, lng: 21.8446005 },
    { lat: 48.7827186, lng: 21.8446562 },
    { lat: 48.7828462, lng: 21.8436238 },
    { lat: 48.7828626, lng: 21.8419926 },
    { lat: 48.7827367, lng: 21.8409505 },
    { lat: 48.7823615, lng: 21.8391826 },
    { lat: 48.782332, lng: 21.8388726 },
    { lat: 48.7823097, lng: 21.8360016 },
    { lat: 48.7824449, lng: 21.8351849 },
    { lat: 48.7824409, lng: 21.8349871 },
    { lat: 48.7823533, lng: 21.8339043 },
    { lat: 48.7823735, lng: 21.8336381 },
    { lat: 48.7823207, lng: 21.8333808 },
    { lat: 48.782154, lng: 21.8330257 },
    { lat: 48.7820613, lng: 21.8324872 },
    { lat: 48.7821488, lng: 21.8320879 },
    { lat: 48.782173, lng: 21.8315474 },
    { lat: 48.7821302, lng: 21.8312778 },
    { lat: 48.7821706, lng: 21.8311445 },
    { lat: 48.7820593, lng: 21.8308436 },
    { lat: 48.7820516, lng: 21.8305897 },
    { lat: 48.7821745, lng: 21.830165 },
    { lat: 48.782376, lng: 21.8299084 },
    { lat: 48.7823856, lng: 21.8297587 },
    { lat: 48.7824811, lng: 21.829544 },
    { lat: 48.7823636, lng: 21.8286459 },
    { lat: 48.7823997, lng: 21.8284826 },
    { lat: 48.7823924, lng: 21.8281113 },
    { lat: 48.78248, lng: 21.8277604 },
    { lat: 48.7822958, lng: 21.8272034 },
    { lat: 48.7822975, lng: 21.8270528 },
    { lat: 48.7823566, lng: 21.8269926 },
    { lat: 48.7822767, lng: 21.82686 },
    { lat: 48.7822881, lng: 21.8264348 },
    { lat: 48.7821947, lng: 21.8261222 },
    { lat: 48.7821076, lng: 21.8259846 },
    { lat: 48.7822108, lng: 21.82558 },
    { lat: 48.7821235, lng: 21.8251129 },
    { lat: 48.7816429, lng: 21.8240289 },
    { lat: 48.7816076, lng: 21.8236675 },
    { lat: 48.7816668, lng: 21.8232598 },
    { lat: 48.7818627, lng: 21.8231308 },
    { lat: 48.7817737, lng: 21.8228282 },
    { lat: 48.7819199, lng: 21.8226918 },
    { lat: 48.7819644, lng: 21.8224997 },
    { lat: 48.7818557, lng: 21.8222989 },
    { lat: 48.7819157, lng: 21.8221402 },
    { lat: 48.7819257, lng: 21.8218703 },
    { lat: 48.7820363, lng: 21.8217984 },
    { lat: 48.7820282, lng: 21.8217069 },
    { lat: 48.7819482, lng: 21.8216114 },
    { lat: 48.7819519, lng: 21.8214542 },
    { lat: 48.7820365, lng: 21.8214617 },
    { lat: 48.7820212, lng: 21.8212175 },
    { lat: 48.7820904, lng: 21.8211146 },
    { lat: 48.7821465, lng: 21.8212336 },
    { lat: 48.782257, lng: 21.8211571 },
    { lat: 48.7824762, lng: 21.8211562 },
    { lat: 48.782494, lng: 21.8207988 },
    { lat: 48.7822705, lng: 21.8205955 },
    { lat: 48.7822613, lng: 21.8204598 },
    { lat: 48.7825299, lng: 21.8204902 },
    { lat: 48.7827186, lng: 21.8201797 },
    { lat: 48.7828168, lng: 21.8201917 },
    { lat: 48.7828415, lng: 21.8198017 },
    { lat: 48.7829285, lng: 21.8198087 },
    { lat: 48.782961, lng: 21.8196373 },
    { lat: 48.7828614, lng: 21.8194773 },
    { lat: 48.7828263, lng: 21.8192622 },
    { lat: 48.7831447, lng: 21.8191644 },
    { lat: 48.7832484, lng: 21.8189649 },
    { lat: 48.7831046, lng: 21.818885 },
    { lat: 48.7831747, lng: 21.8187256 },
    { lat: 48.7831762, lng: 21.8185646 },
    { lat: 48.7830587, lng: 21.8184147 },
    { lat: 48.7831492, lng: 21.8182609 },
    { lat: 48.7832148, lng: 21.8184435 },
    { lat: 48.783404, lng: 21.8181951 },
    { lat: 48.7833348, lng: 21.8179987 },
    { lat: 48.7834531, lng: 21.8178793 },
    { lat: 48.7834092, lng: 21.8177866 },
    { lat: 48.7834646, lng: 21.817626 },
    { lat: 48.7812407, lng: 21.8177652 },
    { lat: 48.7645278, lng: 21.8185051 },
    { lat: 48.7547392, lng: 21.8190327 },
    { lat: 48.7546966, lng: 21.8190805 },
  ],
  Falkušovce: [
    { lat: 48.6433488, lng: 21.8588583 },
    { lat: 48.642842, lng: 21.8563423 },
    { lat: 48.6429988, lng: 21.8560613 },
    { lat: 48.6401903, lng: 21.8433178 },
    { lat: 48.639882, lng: 21.8420542 },
    { lat: 48.639142, lng: 21.8395865 },
    { lat: 48.6387683, lng: 21.8381017 },
    { lat: 48.6380904, lng: 21.8360778 },
    { lat: 48.6378545, lng: 21.8354892 },
    { lat: 48.6374832, lng: 21.8348359 },
    { lat: 48.6370103, lng: 21.8357243 },
    { lat: 48.6367359, lng: 21.835908 },
    { lat: 48.6357785, lng: 21.8357355 },
    { lat: 48.635638, lng: 21.8354813 },
    { lat: 48.6353342, lng: 21.8345669 },
    { lat: 48.6314737, lng: 21.8267017 },
    { lat: 48.6297293, lng: 21.8232658 },
    { lat: 48.6289217, lng: 21.8215044 },
    { lat: 48.6288933, lng: 21.8207614 },
    { lat: 48.6289752, lng: 21.8194157 },
    { lat: 48.6293305, lng: 21.8185034 },
    { lat: 48.6295165, lng: 21.8181701 },
    { lat: 48.6299477, lng: 21.8171017 },
    { lat: 48.6301917, lng: 21.8166224 },
    { lat: 48.6301384, lng: 21.8162158 },
    { lat: 48.6299821, lng: 21.815793 },
    { lat: 48.6295528, lng: 21.8150353 },
    { lat: 48.6292602, lng: 21.8139936 },
    { lat: 48.629086, lng: 21.8135722 },
    { lat: 48.6288536, lng: 21.8131603 },
    { lat: 48.6282086, lng: 21.8123764 },
    { lat: 48.6277537, lng: 21.8110694 },
    { lat: 48.6276173, lng: 21.8108837 },
    { lat: 48.6272704, lng: 21.8106579 },
    { lat: 48.62695, lng: 21.8102786 },
    { lat: 48.6268877, lng: 21.8101312 },
    { lat: 48.6266468, lng: 21.8095285 },
    { lat: 48.6266638, lng: 21.8092672 },
    { lat: 48.6269513, lng: 21.8084338 },
    { lat: 48.6268526, lng: 21.8080581 },
    { lat: 48.6261343, lng: 21.8087723 },
    { lat: 48.6259726, lng: 21.8088275 },
    { lat: 48.6253053, lng: 21.8084341 },
    { lat: 48.621978, lng: 21.797758 },
    { lat: 48.620442, lng: 21.79812 },
    { lat: 48.619939, lng: 21.798277 },
    { lat: 48.619492, lng: 21.798416 },
    { lat: 48.619241, lng: 21.79844 },
    { lat: 48.619049, lng: 21.798458 },
    { lat: 48.618291, lng: 21.798597 },
    { lat: 48.616492, lng: 21.798993 },
    { lat: 48.615962, lng: 21.799151 },
    { lat: 48.61457, lng: 21.799348 },
    { lat: 48.614513, lng: 21.799448 },
    { lat: 48.613915, lng: 21.799579 },
    { lat: 48.612268, lng: 21.800025 },
    { lat: 48.611552, lng: 21.800224 },
    { lat: 48.610751, lng: 21.800423 },
    { lat: 48.609924, lng: 21.800637 },
    { lat: 48.609105, lng: 21.800852 },
    { lat: 48.609116, lng: 21.8009 },
    { lat: 48.609114, lng: 21.800939 },
    { lat: 48.609118, lng: 21.800972 },
    { lat: 48.6097365, lng: 21.8056258 },
    { lat: 48.6098408, lng: 21.8066446 },
    { lat: 48.610359, lng: 21.809806 },
    { lat: 48.6106192, lng: 21.812058 },
    { lat: 48.610931, lng: 21.8139117 },
    { lat: 48.6118864, lng: 21.8206431 },
    { lat: 48.612707, lng: 21.8258923 },
    { lat: 48.6129362, lng: 21.8285108 },
    { lat: 48.6135993, lng: 21.832427 },
    { lat: 48.61445, lng: 21.8365558 },
    { lat: 48.6176786, lng: 21.835847 },
    { lat: 48.6187317, lng: 21.8349851 },
    { lat: 48.6191639, lng: 21.8366412 },
    { lat: 48.6197057, lng: 21.8406163 },
    { lat: 48.6198945, lng: 21.8415638 },
    { lat: 48.6197912, lng: 21.8417434 },
    { lat: 48.6202298, lng: 21.843434 },
    { lat: 48.6204949, lng: 21.844722 },
    { lat: 48.620154, lng: 21.8448953 },
    { lat: 48.6201144, lng: 21.8447597 },
    { lat: 48.6199048, lng: 21.8447862 },
    { lat: 48.6216581, lng: 21.8530371 },
    { lat: 48.6222465, lng: 21.8554445 },
    { lat: 48.6221244, lng: 21.8555516 },
    { lat: 48.6221065, lng: 21.8555526 },
    { lat: 48.6227857, lng: 21.8586005 },
    { lat: 48.6230648, lng: 21.8594351 },
    { lat: 48.6234317, lng: 21.8603183 },
    { lat: 48.6238399, lng: 21.8617055 },
    { lat: 48.6250717, lng: 21.86092 },
    { lat: 48.6250981, lng: 21.8610653 },
    { lat: 48.6269195, lng: 21.8598823 },
    { lat: 48.6273356, lng: 21.8595265 },
    { lat: 48.6279075, lng: 21.8616428 },
    { lat: 48.6297327, lng: 21.8607468 },
    { lat: 48.6297827, lng: 21.8608929 },
    { lat: 48.6304483, lng: 21.8606139 },
    { lat: 48.6307704, lng: 21.8620252 },
    { lat: 48.6318457, lng: 21.8614626 },
    { lat: 48.6320249, lng: 21.8613688 },
    { lat: 48.6322791, lng: 21.8624654 },
    { lat: 48.6337675, lng: 21.8617271 },
    { lat: 48.6340684, lng: 21.8631436 },
    { lat: 48.6355979, lng: 21.8624271 },
    { lat: 48.6359085, lng: 21.8621638 },
    { lat: 48.6383975, lng: 21.8609973 },
    { lat: 48.6391115, lng: 21.8605927 },
    { lat: 48.6391885, lng: 21.8605707 },
    { lat: 48.6393143, lng: 21.860685 },
    { lat: 48.6395933, lng: 21.861087 },
    { lat: 48.6408745, lng: 21.8602483 },
    { lat: 48.64333, lng: 21.8588697 },
    { lat: 48.6433488, lng: 21.8588583 },
  ],
  Jovsa: [
    { lat: 48.846993, lng: 22.071657 },
    { lat: 48.8412788, lng: 22.0712676 },
    { lat: 48.8357427, lng: 22.0701485 },
    { lat: 48.8295463, lng: 22.0704426 },
    { lat: 48.8288828, lng: 22.0705396 },
    { lat: 48.8283933, lng: 22.0707719 },
    { lat: 48.8276109, lng: 22.0709239 },
    { lat: 48.8267907, lng: 22.0712536 },
    { lat: 48.8265833, lng: 22.0714259 },
    { lat: 48.8258135, lng: 22.071645 },
    { lat: 48.8246809, lng: 22.0717418 },
    { lat: 48.8239331, lng: 22.0719432 },
    { lat: 48.8238257, lng: 22.0719166 },
    { lat: 48.8236279, lng: 22.071639 },
    { lat: 48.8229368, lng: 22.0714325 },
    { lat: 48.8224939, lng: 22.0714428 },
    { lat: 48.821941, lng: 22.0711385 },
    { lat: 48.821629, lng: 22.0713972 },
    { lat: 48.8212109, lng: 22.0712719 },
    { lat: 48.8211697, lng: 22.0709175 },
    { lat: 48.8206684, lng: 22.0706907 },
    { lat: 48.8205325, lng: 22.0707535 },
    { lat: 48.8202677, lng: 22.0712955 },
    { lat: 48.8198846, lng: 22.0711233 },
    { lat: 48.8196268, lng: 22.0711174 },
    { lat: 48.8195382, lng: 22.0710794 },
    { lat: 48.8194534, lng: 22.070923 },
    { lat: 48.819339, lng: 22.0709379 },
    { lat: 48.8192885, lng: 22.0709877 },
    { lat: 48.819206, lng: 22.0713513 },
    { lat: 48.8188486, lng: 22.0715575 },
    { lat: 48.8187877, lng: 22.07178 },
    { lat: 48.8183309, lng: 22.0720087 },
    { lat: 48.8181364, lng: 22.0719697 },
    { lat: 48.8179218, lng: 22.0720721 },
    { lat: 48.8174411, lng: 22.0719606 },
    { lat: 48.8172414, lng: 22.0718505 },
    { lat: 48.8169401, lng: 22.0713322 },
    { lat: 48.8167931, lng: 22.0713927 },
    { lat: 48.816602, lng: 22.0713096 },
    { lat: 48.816445, lng: 22.0715134 },
    { lat: 48.8163362, lng: 22.0713406 },
    { lat: 48.8162576, lng: 22.0714862 },
    { lat: 48.8160815, lng: 22.0714151 },
    { lat: 48.8147968, lng: 22.0730146 },
    { lat: 48.8136718, lng: 22.0738098 },
    { lat: 48.8129847, lng: 22.0737691 },
    { lat: 48.8118393, lng: 22.0743718 },
    { lat: 48.8098646, lng: 22.0749099 },
    { lat: 48.8089905, lng: 22.073914 },
    { lat: 48.8084503, lng: 22.0735688 },
    { lat: 48.8079919, lng: 22.0743567 },
    { lat: 48.8074822, lng: 22.0748645 },
    { lat: 48.8073379, lng: 22.0741408 },
    { lat: 48.8067884, lng: 22.0740694 },
    { lat: 48.8065058, lng: 22.0743475 },
    { lat: 48.8059039, lng: 22.0751925 },
    { lat: 48.8056563, lng: 22.0745772 },
    { lat: 48.8052892, lng: 22.0742261 },
    { lat: 48.8052613, lng: 22.073854 },
    { lat: 48.8049123, lng: 22.0735626 },
    { lat: 48.804219, lng: 22.0738245 },
    { lat: 48.8045969, lng: 22.0731623 },
    { lat: 48.8048777, lng: 22.0708778 },
    { lat: 48.804819, lng: 22.0701163 },
    { lat: 48.8037972, lng: 22.070136 },
    { lat: 48.8033347, lng: 22.0697102 },
    { lat: 48.8002689, lng: 22.0693959 },
    { lat: 48.799733, lng: 22.0685799 },
    { lat: 48.798998, lng: 22.0676585 },
    { lat: 48.7978926, lng: 22.0667366 },
    { lat: 48.7967238, lng: 22.065079 },
    { lat: 48.7964793, lng: 22.0642179 },
    { lat: 48.79619, lng: 22.0635426 },
    { lat: 48.7959519, lng: 22.063236 },
    { lat: 48.7951133, lng: 22.0624613 },
    { lat: 48.7943049, lng: 22.0618619 },
    { lat: 48.7934661, lng: 22.0611151 },
    { lat: 48.7932306, lng: 22.0649635 },
    { lat: 48.7922627, lng: 22.0757026 },
    { lat: 48.7920973, lng: 22.0771732 },
    { lat: 48.7920289, lng: 22.0773703 },
    { lat: 48.7920027, lng: 22.0781626 },
    { lat: 48.792616, lng: 22.083615 },
    { lat: 48.792686, lng: 22.0836 },
    { lat: 48.793417, lng: 22.085754 },
    { lat: 48.794717, lng: 22.08835 },
    { lat: 48.79528, lng: 22.087489 },
    { lat: 48.796232, lng: 22.088431 },
    { lat: 48.79657, lng: 22.08733 },
    { lat: 48.796636, lng: 22.087359 },
    { lat: 48.798572, lng: 22.089324 },
    { lat: 48.798605, lng: 22.090095 },
    { lat: 48.798398, lng: 22.090859 },
    { lat: 48.798773, lng: 22.091417 },
    { lat: 48.798693, lng: 22.091584 },
    { lat: 48.800752, lng: 22.094174 },
    { lat: 48.802349, lng: 22.096177 },
    { lat: 48.802318, lng: 22.096237 },
    { lat: 48.801342, lng: 22.098047 },
    { lat: 48.802086, lng: 22.099303 },
    { lat: 48.802116, lng: 22.099353 },
    { lat: 48.802134, lng: 22.099384 },
    { lat: 48.802274, lng: 22.09967 },
    { lat: 48.802312, lng: 22.099742 },
    { lat: 48.802817, lng: 22.100715 },
    { lat: 48.804161, lng: 22.102257 },
    { lat: 48.805002, lng: 22.102998 },
    { lat: 48.805061, lng: 22.10305 },
    { lat: 48.805797, lng: 22.103698 },
    { lat: 48.807124, lng: 22.104772 },
    { lat: 48.807544, lng: 22.10721 },
    { lat: 48.807811, lng: 22.107444 },
    { lat: 48.808643, lng: 22.108382 },
    { lat: 48.808709, lng: 22.108452 },
    { lat: 48.809511, lng: 22.109307 },
    { lat: 48.809629, lng: 22.109727 },
    { lat: 48.809919, lng: 22.11003 },
    { lat: 48.81005, lng: 22.110354 },
    { lat: 48.810719, lng: 22.110819 },
    { lat: 48.811429, lng: 22.111706 },
    { lat: 48.8114096, lng: 22.1117992 },
    { lat: 48.8115203, lng: 22.1117772 },
    { lat: 48.8118854, lng: 22.1123789 },
    { lat: 48.8121582, lng: 22.1124028 },
    { lat: 48.8125786, lng: 22.1129091 },
    { lat: 48.8128745, lng: 22.1134985 },
    { lat: 48.8133982, lng: 22.1139079 },
    { lat: 48.8136019, lng: 22.114201 },
    { lat: 48.8139609, lng: 22.1143948 },
    { lat: 48.814575, lng: 22.1146809 },
    { lat: 48.815432, lng: 22.1149692 },
    { lat: 48.8164078, lng: 22.1149493 },
    { lat: 48.8165007, lng: 22.1152116 },
    { lat: 48.816684, lng: 22.1153999 },
    { lat: 48.8169078, lng: 22.1151612 },
    { lat: 48.8171925, lng: 22.1152605 },
    { lat: 48.8173278, lng: 22.1151322 },
    { lat: 48.8177954, lng: 22.1152106 },
    { lat: 48.8180247, lng: 22.115541 },
    { lat: 48.8180052, lng: 22.115673 },
    { lat: 48.8180975, lng: 22.1157723 },
    { lat: 48.8182896, lng: 22.1156617 },
    { lat: 48.8185398, lng: 22.1158756 },
    { lat: 48.8187278, lng: 22.1163159 },
    { lat: 48.8190097, lng: 22.1165881 },
    { lat: 48.8193053, lng: 22.1165783 },
    { lat: 48.8194347, lng: 22.1164358 },
    { lat: 48.8194664, lng: 22.1163023 },
    { lat: 48.8197934, lng: 22.1163851 },
    { lat: 48.8198487, lng: 22.1161987 },
    { lat: 48.8199148, lng: 22.1161833 },
    { lat: 48.8200396, lng: 22.1163294 },
    { lat: 48.8205114, lng: 22.1164468 },
    { lat: 48.8206776, lng: 22.1166189 },
    { lat: 48.820915, lng: 22.1166308 },
    { lat: 48.8215995, lng: 22.1171848 },
    { lat: 48.8220037, lng: 22.1180212 },
    { lat: 48.8220993, lng: 22.1180678 },
    { lat: 48.8221038, lng: 22.118159 },
    { lat: 48.822363, lng: 22.1185174 },
    { lat: 48.8225232, lng: 22.1185834 },
    { lat: 48.822811, lng: 22.1189202 },
    { lat: 48.8230266, lng: 22.1188526 },
    { lat: 48.8232941, lng: 22.1193662 },
    { lat: 48.8239108, lng: 22.1194827 },
    { lat: 48.8242209, lng: 22.1197095 },
    { lat: 48.824539, lng: 22.1196985 },
    { lat: 48.824657, lng: 22.1197922 },
    { lat: 48.8247197, lng: 22.1202571 },
    { lat: 48.8248343, lng: 22.1202985 },
    { lat: 48.8248881, lng: 22.120242 },
    { lat: 48.8250134, lng: 22.1203581 },
    { lat: 48.8250255, lng: 22.1206863 },
    { lat: 48.8251157, lng: 22.1209859 },
    { lat: 48.8253114, lng: 22.1209893 },
    { lat: 48.8255591, lng: 22.1211364 },
    { lat: 48.8255955, lng: 22.1213029 },
    { lat: 48.8256459, lng: 22.1213437 },
    { lat: 48.8257694, lng: 22.1212829 },
    { lat: 48.8258653, lng: 22.1213409 },
    { lat: 48.8260223, lng: 22.1215642 },
    { lat: 48.8264573, lng: 22.121789 },
    { lat: 48.8265715, lng: 22.1220595 },
    { lat: 48.8268371, lng: 22.1222081 },
    { lat: 48.8270102, lng: 22.1221495 },
    { lat: 48.8271597, lng: 22.122486 },
    { lat: 48.8272694, lng: 22.1225115 },
    { lat: 48.8274164, lng: 22.1228328 },
    { lat: 48.8274778, lng: 22.1228581 },
    { lat: 48.8276254, lng: 22.122627 },
    { lat: 48.8277899, lng: 22.1226971 },
    { lat: 48.8278769, lng: 22.1226571 },
    { lat: 48.8281217, lng: 22.1222588 },
    { lat: 48.8281919, lng: 22.1222286 },
    { lat: 48.828277, lng: 22.1225497 },
    { lat: 48.8283812, lng: 22.1225326 },
    { lat: 48.8285446, lng: 22.1226828 },
    { lat: 48.8285661, lng: 22.1229401 },
    { lat: 48.8287627, lng: 22.1228278 },
    { lat: 48.8289571, lng: 22.1236331 },
    { lat: 48.8291045, lng: 22.1239829 },
    { lat: 48.8292336, lng: 22.1240592 },
    { lat: 48.829326, lng: 22.1245059 },
    { lat: 48.8294907, lng: 22.1246231 },
    { lat: 48.8297236, lng: 22.1250072 },
    { lat: 48.8298741, lng: 22.1250931 },
    { lat: 48.8299583, lng: 22.1252899 },
    { lat: 48.8299599, lng: 22.1254976 },
    { lat: 48.8301398, lng: 22.125735 },
    { lat: 48.830665, lng: 22.1259279 },
    { lat: 48.8308113, lng: 22.1260754 },
    { lat: 48.8308681, lng: 22.1262369 },
    { lat: 48.8309718, lng: 22.1262974 },
    { lat: 48.8311422, lng: 22.1262748 },
    { lat: 48.8313144, lng: 22.1263719 },
    { lat: 48.8317288, lng: 22.1266893 },
    { lat: 48.8318306, lng: 22.1268502 },
    { lat: 48.8321552, lng: 22.1268821 },
    { lat: 48.8322906, lng: 22.126756 },
    { lat: 48.8323647, lng: 22.1267988 },
    { lat: 48.8324214, lng: 22.1269643 },
    { lat: 48.8326936, lng: 22.1268112 },
    { lat: 48.8327331, lng: 22.1267262 },
    { lat: 48.8328175, lng: 22.1267306 },
    { lat: 48.8330512, lng: 22.1268638 },
    { lat: 48.8331833, lng: 22.1271209 },
    { lat: 48.8333844, lng: 22.1272048 },
    { lat: 48.8335033, lng: 22.1271474 },
    { lat: 48.8335618, lng: 22.1273435 },
    { lat: 48.8334729, lng: 22.1275846 },
    { lat: 48.8336023, lng: 22.1279693 },
    { lat: 48.8337925, lng: 22.1282591 },
    { lat: 48.8339289, lng: 22.1282803 },
    { lat: 48.8339936, lng: 22.1285443 },
    { lat: 48.8341609, lng: 22.1287649 },
    { lat: 48.8342212, lng: 22.1287056 },
    { lat: 48.8343923, lng: 22.128805 },
    { lat: 48.8344576, lng: 22.128999 },
    { lat: 48.8345558, lng: 22.1290152 },
    { lat: 48.8346775, lng: 22.1293114 },
    { lat: 48.8348548, lng: 22.1292941 },
    { lat: 48.8349453, lng: 22.1293617 },
    { lat: 48.835115, lng: 22.1293127 },
    { lat: 48.8352373, lng: 22.1291105 },
    { lat: 48.8354674, lng: 22.1291317 },
    { lat: 48.8355091, lng: 22.1290378 },
    { lat: 48.8356115, lng: 22.1289937 },
    { lat: 48.8358457, lng: 22.1291536 },
    { lat: 48.83593, lng: 22.1291466 },
    { lat: 48.8362353, lng: 22.129607 },
    { lat: 48.8364004, lng: 22.1296431 },
    { lat: 48.8365836, lng: 22.1295816 },
    { lat: 48.8367067, lng: 22.1296568 },
    { lat: 48.8368059, lng: 22.129615 },
    { lat: 48.8368098, lng: 22.1297337 },
    { lat: 48.8368726, lng: 22.1297775 },
    { lat: 48.8369536, lng: 22.1297314 },
    { lat: 48.8371134, lng: 22.1298228 },
    { lat: 48.8375531, lng: 22.1297772 },
    { lat: 48.8376948, lng: 22.1299235 },
    { lat: 48.8378946, lng: 22.1298975 },
    { lat: 48.8380273, lng: 22.1300486 },
    { lat: 48.8382544, lng: 22.1301375 },
    { lat: 48.8383972, lng: 22.130277 },
    { lat: 48.8384649, lng: 22.1301955 },
    { lat: 48.8386407, lng: 22.1303075 },
    { lat: 48.8390269, lng: 22.1303222 },
    { lat: 48.8391617, lng: 22.1305025 },
    { lat: 48.8394447, lng: 22.1303406 },
    { lat: 48.8396003, lng: 22.1305033 },
    { lat: 48.8397538, lng: 22.1303327 },
    { lat: 48.8398667, lng: 22.1304048 },
    { lat: 48.8400116, lng: 22.1302478 },
    { lat: 48.8402914, lng: 22.1301904 },
    { lat: 48.8405085, lng: 22.1302816 },
    { lat: 48.8408832, lng: 22.1309186 },
    { lat: 48.841285, lng: 22.1313182 },
    { lat: 48.8415235, lng: 22.1314611 },
    { lat: 48.8417231, lng: 22.1314358 },
    { lat: 48.8424858, lng: 22.1318707 },
    { lat: 48.8431037, lng: 22.1317967 },
    { lat: 48.8433763, lng: 22.1319521 },
    { lat: 48.8435287, lng: 22.1318361 },
    { lat: 48.843886, lng: 22.1317681 },
    { lat: 48.8442811, lng: 22.1315881 },
    { lat: 48.844698, lng: 22.131594 },
    { lat: 48.844629, lng: 22.131222 },
    { lat: 48.844556, lng: 22.129209 },
    { lat: 48.84449, lng: 22.12584 },
    { lat: 48.844465, lng: 22.12298 },
    { lat: 48.844448, lng: 22.119514 },
    { lat: 48.844451, lng: 22.119282 },
    { lat: 48.844492, lng: 22.115708 },
    { lat: 48.844514, lng: 22.113058 },
    { lat: 48.844523, lng: 22.112145 },
    { lat: 48.844524, lng: 22.11208 },
    { lat: 48.844527, lng: 22.111714 },
    { lat: 48.844528, lng: 22.111607 },
    { lat: 48.844531, lng: 22.111246 },
    { lat: 48.844553, lng: 22.109377 },
    { lat: 48.844567, lng: 22.108786 },
    { lat: 48.844568, lng: 22.108706 },
    { lat: 48.844569, lng: 22.10864 },
    { lat: 48.844577, lng: 22.108351 },
    { lat: 48.844575, lng: 22.108284 },
    { lat: 48.844575, lng: 22.108198 },
    { lat: 48.84456, lng: 22.106986 },
    { lat: 48.844572, lng: 22.105043 },
    { lat: 48.844668, lng: 22.10225 },
    { lat: 48.844644, lng: 22.099764 },
    { lat: 48.844674, lng: 22.098845 },
    { lat: 48.844701, lng: 22.098006 },
    { lat: 48.844758, lng: 22.09617 },
    { lat: 48.84483, lng: 22.094147 },
    { lat: 48.844885, lng: 22.092732 },
    { lat: 48.844953, lng: 22.0916 },
    { lat: 48.845038, lng: 22.088956 },
    { lat: 48.845042, lng: 22.08885 },
    { lat: 48.845045, lng: 22.088761 },
    { lat: 48.845086, lng: 22.088074 },
    { lat: 48.84509, lng: 22.088009 },
    { lat: 48.845127, lng: 22.087383 },
    { lat: 48.845132, lng: 22.087282 },
    { lat: 48.845136, lng: 22.087222 },
    { lat: 48.845151, lng: 22.08698 },
    { lat: 48.845171, lng: 22.085078 },
    { lat: 48.845176, lng: 22.084612 },
    { lat: 48.84526, lng: 22.081162 },
    { lat: 48.845332, lng: 22.079134 },
    { lat: 48.845381, lng: 22.076606 },
    { lat: 48.845567, lng: 22.075871 },
    { lat: 48.845639, lng: 22.075586 },
    { lat: 48.846061, lng: 22.074449 },
    { lat: 48.846333, lng: 22.073713 },
    { lat: 48.846993, lng: 22.071657 },
  ],
  KapušianskeKľačany: [
    { lat: 48.476927, lng: 22.114008 },
    { lat: 48.4782896, lng: 22.113505 },
    { lat: 48.4792421, lng: 22.1134403 },
    { lat: 48.4797315, lng: 22.1134918 },
    { lat: 48.4798172, lng: 22.1133578 },
    { lat: 48.4803375, lng: 22.1134318 },
    { lat: 48.4806451, lng: 22.1128324 },
    { lat: 48.4815124, lng: 22.1128322 },
    { lat: 48.4823306, lng: 22.1127147 },
    { lat: 48.482572, lng: 22.1130727 },
    { lat: 48.4830289, lng: 22.1133642 },
    { lat: 48.4838672, lng: 22.1134702 },
    { lat: 48.4844568, lng: 22.1140996 },
    { lat: 48.4846649, lng: 22.1131875 },
    { lat: 48.4845798, lng: 22.1125396 },
    { lat: 48.4843353, lng: 22.1116208 },
    { lat: 48.4840599, lng: 22.1109083 },
    { lat: 48.4841787, lng: 22.1103176 },
    { lat: 48.484667, lng: 22.1099429 },
    { lat: 48.4863422, lng: 22.1103271 },
    { lat: 48.4865586, lng: 22.1104885 },
    { lat: 48.487422, lng: 22.1107548 },
    { lat: 48.4886489, lng: 22.1098853 },
    { lat: 48.4893352, lng: 22.1088897 },
    { lat: 48.4902382, lng: 22.1080204 },
    { lat: 48.4904706, lng: 22.1080505 },
    { lat: 48.4906088, lng: 22.1082328 },
    { lat: 48.4906487, lng: 22.1095797 },
    { lat: 48.4905523, lng: 22.1101193 },
    { lat: 48.4910084, lng: 22.1113358 },
    { lat: 48.4915118, lng: 22.1114039 },
    { lat: 48.4917328, lng: 22.1113371 },
    { lat: 48.4919696, lng: 22.1110744 },
    { lat: 48.4923802, lng: 22.1104021 },
    { lat: 48.4933321, lng: 22.1077934 },
    { lat: 48.4937777, lng: 22.1078955 },
    { lat: 48.4941563, lng: 22.1077745 },
    { lat: 48.4948337, lng: 22.1073655 },
    { lat: 48.4951686, lng: 22.106873 },
    { lat: 48.4953861, lng: 22.1060811 },
    { lat: 48.4963318, lng: 22.1065894 },
    { lat: 48.498071, lng: 22.10673 },
    { lat: 48.4990892, lng: 22.1071245 },
    { lat: 48.499676, lng: 22.1069182 },
    { lat: 48.5000278, lng: 22.1065237 },
    { lat: 48.5001683, lng: 22.1062733 },
    { lat: 48.5007905, lng: 22.1056618 },
    { lat: 48.5011688, lng: 22.105966 },
    { lat: 48.5023035, lng: 22.1062316 },
    { lat: 48.5019452, lng: 22.1052062 },
    { lat: 48.5018102, lng: 22.1042142 },
    { lat: 48.5014194, lng: 22.1030022 },
    { lat: 48.5006798, lng: 22.1016532 },
    { lat: 48.5004867, lng: 22.1010517 },
    { lat: 48.5004116, lng: 22.1005417 },
    { lat: 48.5003514, lng: 22.0990457 },
    { lat: 48.5004557, lng: 22.0981728 },
    { lat: 48.5009915, lng: 22.0972388 },
    { lat: 48.5017142, lng: 22.0968928 },
    { lat: 48.5020765, lng: 22.0974057 },
    { lat: 48.5022291, lng: 22.0981066 },
    { lat: 48.5026053, lng: 22.0983645 },
    { lat: 48.5028503, lng: 22.0984464 },
    { lat: 48.5031107, lng: 22.0984225 },
    { lat: 48.503863, lng: 22.0980788 },
    { lat: 48.5045342, lng: 22.0990583 },
    { lat: 48.504743, lng: 22.0996626 },
    { lat: 48.5054654, lng: 22.1009029 },
    { lat: 48.5060754, lng: 22.1017648 },
    { lat: 48.5061743, lng: 22.1021462 },
    { lat: 48.5065099, lng: 22.1030126 },
    { lat: 48.5068243, lng: 22.1031383 },
    { lat: 48.5070424, lng: 22.1033175 },
    { lat: 48.5072943, lng: 22.1038318 },
    { lat: 48.5076059, lng: 22.1046481 },
    { lat: 48.5084772, lng: 22.1058565 },
    { lat: 48.508665, lng: 22.1062814 },
    { lat: 48.5088593, lng: 22.1070844 },
    { lat: 48.5090602, lng: 22.1075856 },
    { lat: 48.5092556, lng: 22.1077639 },
    { lat: 48.5094309, lng: 22.1076587 },
    { lat: 48.509619, lng: 22.1073794 },
    { lat: 48.5101133, lng: 22.1073586 },
    { lat: 48.5103513, lng: 22.1074274 },
    { lat: 48.5105829, lng: 22.1078475 },
    { lat: 48.5106665, lng: 22.1079164 },
    { lat: 48.5105653, lng: 22.1086789 },
    { lat: 48.5108743, lng: 22.1101379 },
    { lat: 48.5109688, lng: 22.110235 },
    { lat: 48.5112597, lng: 22.110785 },
    { lat: 48.5112519, lng: 22.1116031 },
    { lat: 48.5115683, lng: 22.1125633 },
    { lat: 48.5116635, lng: 22.1126015 },
    { lat: 48.51248, lng: 22.1122101 },
    { lat: 48.5126542, lng: 22.1123005 },
    { lat: 48.5128983, lng: 22.1126263 },
    { lat: 48.5131628, lng: 22.1127785 },
    { lat: 48.5133536, lng: 22.1126711 },
    { lat: 48.5139791, lng: 22.1119065 },
    { lat: 48.5141392, lng: 22.1118404 },
    { lat: 48.5142476, lng: 22.1117956 },
    { lat: 48.5143552, lng: 22.1119236 },
    { lat: 48.5145489, lng: 22.1126881 },
    { lat: 48.5147344, lng: 22.1130192 },
    { lat: 48.5158211, lng: 22.1135966 },
    { lat: 48.5158925, lng: 22.1136669 },
    { lat: 48.5161138, lng: 22.114177 },
    { lat: 48.5161957, lng: 22.1141705 },
    { lat: 48.516236, lng: 22.1144476 },
    { lat: 48.516335, lng: 22.1146882 },
    { lat: 48.5167483, lng: 22.1147509 },
    { lat: 48.5169274, lng: 22.1149106 },
    { lat: 48.518133, lng: 22.1163233 },
    { lat: 48.5183022, lng: 22.1164438 },
    { lat: 48.5183248, lng: 22.1167311 },
    { lat: 48.5180771, lng: 22.1172925 },
    { lat: 48.518113, lng: 22.1176794 },
    { lat: 48.5183231, lng: 22.1180397 },
    { lat: 48.5187866, lng: 22.1183858 },
    { lat: 48.5200826, lng: 22.1222631 },
    { lat: 48.5207202, lng: 22.1233054 },
    { lat: 48.5210795, lng: 22.125718 },
    { lat: 48.5215558, lng: 22.1258818 },
    { lat: 48.5218677, lng: 22.1270493 },
    { lat: 48.5220098, lng: 22.1271589 },
    { lat: 48.5232156, lng: 22.1271029 },
    { lat: 48.5243082, lng: 22.1269231 },
    { lat: 48.5243706, lng: 22.1270511 },
    { lat: 48.5265593, lng: 22.1256461 },
    { lat: 48.5271124, lng: 22.1259599 },
    { lat: 48.5285843, lng: 22.1264668 },
    { lat: 48.5288313, lng: 22.1273064 },
    { lat: 48.5293778, lng: 22.1285483 },
    { lat: 48.5289228, lng: 22.1298132 },
    { lat: 48.528962, lng: 22.1299566 },
    { lat: 48.5293151, lng: 22.1303268 },
    { lat: 48.5293606, lng: 22.131124 },
    { lat: 48.5295447, lng: 22.1313967 },
    { lat: 48.5293919, lng: 22.132027 },
    { lat: 48.5295018, lng: 22.1320598 },
    { lat: 48.5296803, lng: 22.1322327 },
    { lat: 48.5296565, lng: 22.1325158 },
    { lat: 48.5306107, lng: 22.1336335 },
    { lat: 48.5311258, lng: 22.1338266 },
    { lat: 48.5314443, lng: 22.1340688 },
    { lat: 48.5317478, lng: 22.1333911 },
    { lat: 48.5318308, lng: 22.1334243 },
    { lat: 48.5320165, lng: 22.1330464 },
    { lat: 48.5322012, lng: 22.1331026 },
    { lat: 48.5333502, lng: 22.1338935 },
    { lat: 48.5336754, lng: 22.1335571 },
    { lat: 48.533798, lng: 22.1333185 },
    { lat: 48.5339309, lng: 22.1334236 },
    { lat: 48.5343309, lng: 22.1319149 },
    { lat: 48.5348527, lng: 22.1308011 },
    { lat: 48.5360471, lng: 22.1288731 },
    { lat: 48.536136, lng: 22.1285408 },
    { lat: 48.5358744, lng: 22.1275069 },
    { lat: 48.5354071, lng: 22.1267587 },
    { lat: 48.5362736, lng: 22.1247586 },
    { lat: 48.5369896, lng: 22.1221556 },
    { lat: 48.5372601, lng: 22.1220819 },
    { lat: 48.5377639, lng: 22.120347 },
    { lat: 48.5380906, lng: 22.1194169 },
    { lat: 48.5380542, lng: 22.117298 },
    { lat: 48.5403526, lng: 22.1177837 },
    { lat: 48.5408234, lng: 22.117946 },
    { lat: 48.5419383, lng: 22.1185258 },
    { lat: 48.5425283, lng: 22.1186932 },
    { lat: 48.5426112, lng: 22.1186319 },
    { lat: 48.5428494, lng: 22.1163331 },
    { lat: 48.5427731, lng: 22.1155764 },
    { lat: 48.5423434, lng: 22.114906 },
    { lat: 48.5421254, lng: 22.1142972 },
    { lat: 48.5420607, lng: 22.1139343 },
    { lat: 48.542121, lng: 22.1135317 },
    { lat: 48.5443681, lng: 22.1128982 },
    { lat: 48.5463656, lng: 22.1121676 },
    { lat: 48.5463326, lng: 22.1116308 },
    { lat: 48.5508645, lng: 22.108277 },
    { lat: 48.5508849, lng: 22.1082837 },
    { lat: 48.5516789, lng: 22.107509 },
    { lat: 48.5518134, lng: 22.1070644 },
    { lat: 48.5520125, lng: 22.1068247 },
    { lat: 48.5525268, lng: 22.1066409 },
    { lat: 48.5522941, lng: 22.1045038 },
    { lat: 48.5522079, lng: 22.1042879 },
    { lat: 48.5522325, lng: 22.1040924 },
    { lat: 48.5523549, lng: 22.1039854 },
    { lat: 48.5530519, lng: 22.1025884 },
    { lat: 48.5531639, lng: 22.102184 },
    { lat: 48.5531661, lng: 22.1016179 },
    { lat: 48.5524084, lng: 22.0976453 },
    { lat: 48.5508626, lng: 22.0987304 },
    { lat: 48.5503619, lng: 22.0972995 },
    { lat: 48.5498294, lng: 22.0978064 },
    { lat: 48.5495624, lng: 22.0971724 },
    { lat: 48.5489317, lng: 22.0977524 },
    { lat: 48.5477106, lng: 22.0985366 },
    { lat: 48.5462796, lng: 22.0991972 },
    { lat: 48.5452686, lng: 22.0992065 },
    { lat: 48.5419976, lng: 22.1002186 },
    { lat: 48.5389349, lng: 22.1001404 },
    { lat: 48.5389082, lng: 22.0997276 },
    { lat: 48.53625, lng: 22.1000124 },
    { lat: 48.5360942, lng: 22.0986091 },
    { lat: 48.5359446, lng: 22.0985131 },
    { lat: 48.5352525, lng: 22.0985038 },
    { lat: 48.5352297, lng: 22.0985832 },
    { lat: 48.534816, lng: 22.0987452 },
    { lat: 48.5331469, lng: 22.0992218 },
    { lat: 48.5326494, lng: 22.0994963 },
    { lat: 48.5320499, lng: 22.0978817 },
    { lat: 48.5308837, lng: 22.0956257 },
    { lat: 48.5308293, lng: 22.0951779 },
    { lat: 48.5294341, lng: 22.0945566 },
    { lat: 48.5291533, lng: 22.0943741 },
    { lat: 48.5292071, lng: 22.0938033 },
    { lat: 48.5286673, lng: 22.093574 },
    { lat: 48.5291335, lng: 22.0895768 },
    { lat: 48.5278809, lng: 22.0891198 },
    { lat: 48.5229843, lng: 22.0853938 },
    { lat: 48.5194063, lng: 22.0842805 },
    { lat: 48.5188659, lng: 22.0801163 },
    { lat: 48.5186751, lng: 22.0796554 },
    { lat: 48.5185737, lng: 22.0796956 },
    { lat: 48.5182608, lng: 22.080017 },
    { lat: 48.5179891, lng: 22.0797617 },
    { lat: 48.51763, lng: 22.0790903 },
    { lat: 48.5172995, lng: 22.0773631 },
    { lat: 48.5160818, lng: 22.0759247 },
    { lat: 48.5151574, lng: 22.0753669 },
    { lat: 48.5139653, lng: 22.0749636 },
    { lat: 48.5138672, lng: 22.07176 },
    { lat: 48.5124575, lng: 22.0705274 },
    { lat: 48.5114876, lng: 22.0699542 },
    { lat: 48.5104239, lng: 22.069832 },
    { lat: 48.5092855, lng: 22.0701613 },
    { lat: 48.5079124, lng: 22.0703196 },
    { lat: 48.5064776, lng: 22.0694577 },
    { lat: 48.5057058, lng: 22.0681903 },
    { lat: 48.505191, lng: 22.0671693 },
    { lat: 48.504511, lng: 22.0654148 },
    { lat: 48.504087, lng: 22.0635421 },
    { lat: 48.5032284, lng: 22.0638616 },
    { lat: 48.5029546, lng: 22.063044 },
    { lat: 48.5025977, lng: 22.0625718 },
    { lat: 48.5038715, lng: 22.0612236 },
    { lat: 48.504432, lng: 22.0605354 },
    { lat: 48.5041818, lng: 22.0603763 },
    { lat: 48.5026603, lng: 22.060451 },
    { lat: 48.5021307, lng: 22.0603642 },
    { lat: 48.5009993, lng: 22.0597607 },
    { lat: 48.5003055, lng: 22.0588415 },
    { lat: 48.4998293, lng: 22.0568694 },
    { lat: 48.50006, lng: 22.055827 },
    { lat: 48.500013, lng: 22.055586 },
    { lat: 48.499912, lng: 22.055209 },
    { lat: 48.499849, lng: 22.055 },
    { lat: 48.499465, lng: 22.053464 },
    { lat: 48.498712, lng: 22.054101 },
    { lat: 48.498632, lng: 22.054186 },
    { lat: 48.497928, lng: 22.054915 },
    { lat: 48.497835, lng: 22.055063 },
    { lat: 48.49778, lng: 22.055152 },
    { lat: 48.497197, lng: 22.056076 },
    { lat: 48.49663, lng: 22.056954 },
    { lat: 48.496072, lng: 22.057795 },
    { lat: 48.495275, lng: 22.058874 },
    { lat: 48.49487, lng: 22.059625 },
    { lat: 48.494413, lng: 22.061236 },
    { lat: 48.494014, lng: 22.063081 },
    { lat: 48.493969, lng: 22.063385 },
    { lat: 48.493973, lng: 22.064018 },
    { lat: 48.493927, lng: 22.064216 },
    { lat: 48.494004, lng: 22.066228 },
    { lat: 48.49391, lng: 22.06759 },
    { lat: 48.493641, lng: 22.068761 },
    { lat: 48.493406, lng: 22.069782 },
    { lat: 48.492787, lng: 22.070877 },
    { lat: 48.491972, lng: 22.071992 },
    { lat: 48.491661, lng: 22.072412 },
    { lat: 48.490924, lng: 22.073122 },
    { lat: 48.490484, lng: 22.073426 },
    { lat: 48.49025, lng: 22.073633 },
    { lat: 48.489617, lng: 22.074192 },
    { lat: 48.488144, lng: 22.075518 },
    { lat: 48.487045, lng: 22.076563 },
    { lat: 48.485847, lng: 22.077782 },
    { lat: 48.485608, lng: 22.078026 },
    { lat: 48.484382, lng: 22.079638 },
    { lat: 48.483845, lng: 22.0802896 },
    { lat: 48.482871, lng: 22.081605 },
    { lat: 48.482709, lng: 22.081851 },
    { lat: 48.482576, lng: 22.082054 },
    { lat: 48.482364, lng: 22.082376 },
    { lat: 48.48226, lng: 22.082534 },
    { lat: 48.482209, lng: 22.082612 },
    { lat: 48.482171, lng: 22.08267 },
    { lat: 48.482141, lng: 22.082717 },
    { lat: 48.482125, lng: 22.08274 },
    { lat: 48.48195, lng: 22.083015 },
    { lat: 48.481871, lng: 22.083138 },
    { lat: 48.481832, lng: 22.083199 },
    { lat: 48.481538, lng: 22.08368 },
    { lat: 48.481346, lng: 22.08397 },
    { lat: 48.480968, lng: 22.084585 },
    { lat: 48.480391, lng: 22.085472 },
    { lat: 48.480372, lng: 22.085501 },
    { lat: 48.480351, lng: 22.085534 },
    { lat: 48.480122, lng: 22.085886 },
    { lat: 48.480101, lng: 22.085917 },
    { lat: 48.480076, lng: 22.085956 },
    { lat: 48.479597, lng: 22.086692 },
    { lat: 48.479402, lng: 22.086981 },
    { lat: 48.479338, lng: 22.087076 },
    { lat: 48.479313, lng: 22.087113 },
    { lat: 48.47901, lng: 22.087621 },
    { lat: 48.478853, lng: 22.087885 },
    { lat: 48.478674, lng: 22.088184 },
    { lat: 48.477265, lng: 22.089706 },
    { lat: 48.47709, lng: 22.089875 },
    { lat: 48.476608, lng: 22.090342 },
    { lat: 48.476292, lng: 22.090647 },
    { lat: 48.475268, lng: 22.092681 },
    { lat: 48.475067, lng: 22.093725 },
    { lat: 48.475103, lng: 22.095574 },
    { lat: 48.475383, lng: 22.097203 },
    { lat: 48.475412, lng: 22.097369 },
    { lat: 48.475451, lng: 22.097593 },
    { lat: 48.475668, lng: 22.098821 },
    { lat: 48.475746, lng: 22.10018 },
    { lat: 48.47577, lng: 22.101884 },
    { lat: 48.475966, lng: 22.102832 },
    { lat: 48.476368, lng: 22.103932 },
    { lat: 48.477004, lng: 22.106316 },
    { lat: 48.477083, lng: 22.106961 },
    { lat: 48.477137, lng: 22.107585 },
    { lat: 48.477205, lng: 22.108925 },
    { lat: 48.477306, lng: 22.110522 },
    { lat: 48.477285, lng: 22.112072 },
    { lat: 48.477151, lng: 22.113238 },
    { lat: 48.477058, lng: 22.113557 },
    { lat: 48.476963, lng: 22.113882 },
    { lat: 48.476927, lng: 22.114008 },
  ],
  Trhovište: [
    { lat: 48.7127072, lng: 21.7930489 },
    { lat: 48.7123307, lng: 21.7929749 },
    { lat: 48.7120088, lng: 21.7927552 },
    { lat: 48.7116991, lng: 21.7926177 },
    { lat: 48.7116201, lng: 21.7926503 },
    { lat: 48.7114942, lng: 21.7927808 },
    { lat: 48.7112949, lng: 21.7929875 },
    { lat: 48.7109296, lng: 21.7935153 },
    { lat: 48.7106556, lng: 21.793755 },
    { lat: 48.7099043, lng: 21.7948317 },
    { lat: 48.7097618, lng: 21.7948837 },
    { lat: 48.7094854, lng: 21.7948176 },
    { lat: 48.7092016, lng: 21.7950174 },
    { lat: 48.7091746, lng: 21.7952055 },
    { lat: 48.709175, lng: 21.7958896 },
    { lat: 48.7090774, lng: 21.7957729 },
    { lat: 48.7089021, lng: 21.7953075 },
    { lat: 48.7062705, lng: 21.787685 },
    { lat: 48.7036337, lng: 21.78881 },
    { lat: 48.7025358, lng: 21.7880842 },
    { lat: 48.7024368, lng: 21.7873567 },
    { lat: 48.7021798, lng: 21.7864443 },
    { lat: 48.7021184, lng: 21.7859487 },
    { lat: 48.7021935, lng: 21.7852963 },
    { lat: 48.701007, lng: 21.7833036 },
    { lat: 48.7007329, lng: 21.7831103 },
    { lat: 48.6999353, lng: 21.7832352 },
    { lat: 48.6978481, lng: 21.7838315 },
    { lat: 48.6971162, lng: 21.7843164 },
    { lat: 48.6959463, lng: 21.7852992 },
    { lat: 48.6947031, lng: 21.7868023 },
    { lat: 48.6942659, lng: 21.7859998 },
    { lat: 48.6939084, lng: 21.7855024 },
    { lat: 48.6934506, lng: 21.7849495 },
    { lat: 48.6931952, lng: 21.7848217 },
    { lat: 48.6929204, lng: 21.7849418 },
    { lat: 48.6926858, lng: 21.7852515 },
    { lat: 48.6925026, lng: 21.7856891 },
    { lat: 48.6923744, lng: 21.7858182 },
    { lat: 48.6923082, lng: 21.7860807 },
    { lat: 48.6920282, lng: 21.7861713 },
    { lat: 48.6918231, lng: 21.7859468 },
    { lat: 48.6917168, lng: 21.7857178 },
    { lat: 48.6915011, lng: 21.7837558 },
    { lat: 48.6915329, lng: 21.7832988 },
    { lat: 48.6916851, lng: 21.7830194 },
    { lat: 48.6925459, lng: 21.7827611 },
    { lat: 48.6926736, lng: 21.7826142 },
    { lat: 48.6926925, lng: 21.7823944 },
    { lat: 48.692768, lng: 21.7823147 },
    { lat: 48.6925964, lng: 21.7820281 },
    { lat: 48.6917212, lng: 21.7816975 },
    { lat: 48.6914916, lng: 21.7814834 },
    { lat: 48.6912112, lng: 21.7802725 },
    { lat: 48.691047, lng: 21.7799355 },
    { lat: 48.6906714, lng: 21.7794343 },
    { lat: 48.6902478, lng: 21.7786408 },
    { lat: 48.6899817, lng: 21.7783666 },
    { lat: 48.6898837, lng: 21.7778081 },
    { lat: 48.6895973, lng: 21.777861 },
    { lat: 48.6839647, lng: 21.7798483 },
    { lat: 48.6840732, lng: 21.7804656 },
    { lat: 48.6840262, lng: 21.7819645 },
    { lat: 48.6837293, lng: 21.7822302 },
    { lat: 48.6833852, lng: 21.7824015 },
    { lat: 48.68236, lng: 21.7825215 },
    { lat: 48.6820435, lng: 21.7823383 },
    { lat: 48.6815881, lng: 21.7811524 },
    { lat: 48.6814718, lng: 21.7807118 },
    { lat: 48.67809, lng: 21.7816536 },
    { lat: 48.6757364, lng: 21.7813142 },
    { lat: 48.6757953, lng: 21.7816706 },
    { lat: 48.678922, lng: 21.8000715 },
    { lat: 48.6788828, lng: 21.8001222 },
    { lat: 48.6794236, lng: 21.8033264 },
    { lat: 48.6792991, lng: 21.8037749 },
    { lat: 48.6793212, lng: 21.8039267 },
    { lat: 48.6796329, lng: 21.8040177 },
    { lat: 48.6797477, lng: 21.804107 },
    { lat: 48.6799006, lng: 21.8043549 },
    { lat: 48.6800369, lng: 21.8046269 },
    { lat: 48.6802708, lng: 21.8054738 },
    { lat: 48.6806396, lng: 21.8052531 },
    { lat: 48.6808521, lng: 21.8052921 },
    { lat: 48.6813521, lng: 21.8059561 },
    { lat: 48.6814005, lng: 21.8059683 },
    { lat: 48.6814402, lng: 21.8059722 },
    { lat: 48.6818627, lng: 21.8058669 },
    { lat: 48.6822732, lng: 21.8055615 },
    { lat: 48.6828761, lng: 21.8055911 },
    { lat: 48.6832198, lng: 21.8053584 },
    { lat: 48.6835164, lng: 21.8053043 },
    { lat: 48.6836693, lng: 21.8052112 },
    { lat: 48.6842111, lng: 21.8052369 },
    { lat: 48.6844038, lng: 21.8050812 },
    { lat: 48.68469, lng: 21.8046646 },
    { lat: 48.6849537, lng: 21.8045061 },
    { lat: 48.6850369, lng: 21.8046785 },
    { lat: 48.6853983, lng: 21.8048563 },
    { lat: 48.6856664, lng: 21.8050845 },
    { lat: 48.6858496, lng: 21.8053396 },
    { lat: 48.6861126, lng: 21.8053264 },
    { lat: 48.6865433, lng: 21.8047655 },
    { lat: 48.6866305, lng: 21.80474 },
    { lat: 48.6885911, lng: 21.8097187 },
    { lat: 48.6885859, lng: 21.8099533 },
    { lat: 48.688775, lng: 21.8104316 },
    { lat: 48.6893069, lng: 21.8123106 },
    { lat: 48.6906245, lng: 21.8161145 },
    { lat: 48.6923157, lng: 21.8195436 },
    { lat: 48.6925342, lng: 21.8193337 },
    { lat: 48.6940134, lng: 21.8228674 },
    { lat: 48.6942082, lng: 21.8234352 },
    { lat: 48.694261, lng: 21.8236977 },
    { lat: 48.6942316, lng: 21.8238173 },
    { lat: 48.694669, lng: 21.8249815 },
    { lat: 48.694302, lng: 21.8253084 },
    { lat: 48.6949595, lng: 21.8268506 },
    { lat: 48.695585, lng: 21.8284965 },
    { lat: 48.6961586, lng: 21.8303043 },
    { lat: 48.6956894, lng: 21.8306929 },
    { lat: 48.6970101, lng: 21.8343757 },
    { lat: 48.69749, lng: 21.8349656 },
    { lat: 48.6976501, lng: 21.8352385 },
    { lat: 48.6977545, lng: 21.8352726 },
    { lat: 48.6985085, lng: 21.835551 },
    { lat: 48.6998231, lng: 21.8361824 },
    { lat: 48.7001354, lng: 21.8362077 },
    { lat: 48.7024854, lng: 21.8371904 },
    { lat: 48.7040009, lng: 21.8380707 },
    { lat: 48.7051494, lng: 21.8386262 },
    { lat: 48.7064481, lng: 21.8391251 },
    { lat: 48.7065294, lng: 21.8392681 },
    { lat: 48.7067017, lng: 21.8390578 },
    { lat: 48.7070159, lng: 21.8393302 },
    { lat: 48.7071082, lng: 21.8391998 },
    { lat: 48.7077853, lng: 21.8386714 },
    { lat: 48.7078463, lng: 21.8383312 },
    { lat: 48.7081388, lng: 21.8382933 },
    { lat: 48.7082316, lng: 21.8378778 },
    { lat: 48.7085244, lng: 21.8377986 },
    { lat: 48.7086444, lng: 21.8375713 },
    { lat: 48.7088439, lng: 21.8373626 },
    { lat: 48.7093986, lng: 21.8373248 },
    { lat: 48.7096106, lng: 21.8372086 },
    { lat: 48.7100304, lng: 21.8373983 },
    { lat: 48.7102334, lng: 21.8372377 },
    { lat: 48.7106899, lng: 21.8370569 },
    { lat: 48.7110165, lng: 21.8373721 },
    { lat: 48.7113215, lng: 21.8371118 },
    { lat: 48.7115282, lng: 21.8370643 },
    { lat: 48.7117119, lng: 21.8368144 },
    { lat: 48.7118542, lng: 21.8364925 },
    { lat: 48.7122834, lng: 21.8364697 },
    { lat: 48.7123734, lng: 21.8363767 },
    { lat: 48.7123749, lng: 21.8360884 },
    { lat: 48.7124625, lng: 21.8358667 },
    { lat: 48.7125643, lng: 21.8357164 },
    { lat: 48.7127488, lng: 21.8357133 },
    { lat: 48.7128051, lng: 21.8352846 },
    { lat: 48.7128747, lng: 21.8351056 },
    { lat: 48.7128643, lng: 21.8350828 },
    { lat: 48.7128247, lng: 21.8349957 },
    { lat: 48.7134256, lng: 21.8338308 },
    { lat: 48.7137636, lng: 21.8334995 },
    { lat: 48.7138659, lng: 21.8334322 },
    { lat: 48.7141994, lng: 21.8332126 },
    { lat: 48.7153983, lng: 21.8319375 },
    { lat: 48.7161275, lng: 21.8308662 },
    { lat: 48.718324, lng: 21.8282319 },
    { lat: 48.7186615, lng: 21.8279283 },
    { lat: 48.7191258, lng: 21.8278323 },
    { lat: 48.7194782, lng: 21.8274491 },
    { lat: 48.7203406, lng: 21.8252656 },
    { lat: 48.720708, lng: 21.8238635 },
    { lat: 48.7207141, lng: 21.8238407 },
    { lat: 48.7207503, lng: 21.8235397 },
    { lat: 48.72073, lng: 21.8228472 },
    { lat: 48.7205995, lng: 21.8219382 },
    { lat: 48.7202493, lng: 21.8212788 },
    { lat: 48.7199429, lng: 21.820484 },
    { lat: 48.7198352, lng: 21.8199346 },
    { lat: 48.7196043, lng: 21.8195771 },
    { lat: 48.7195476, lng: 21.8192209 },
    { lat: 48.7195875, lng: 21.8188491 },
    { lat: 48.7195455, lng: 21.8181412 },
    { lat: 48.7198495, lng: 21.8172862 },
    { lat: 48.7199013, lng: 21.8168612 },
    { lat: 48.7198807, lng: 21.816711 },
    { lat: 48.7196579, lng: 21.816151 },
    { lat: 48.7191775, lng: 21.8150752 },
    { lat: 48.719162, lng: 21.8144807 },
    { lat: 48.7190249, lng: 21.8133623 },
    { lat: 48.7190452, lng: 21.8131025 },
    { lat: 48.7185129, lng: 21.8115502 },
    { lat: 48.7183437, lng: 21.8108844 },
    { lat: 48.7179731, lng: 21.810169 },
    { lat: 48.7176199, lng: 21.8092295 },
    { lat: 48.7164274, lng: 21.8073334 },
    { lat: 48.7162531, lng: 21.8073377 },
    { lat: 48.715779, lng: 21.8063272 },
    { lat: 48.7152784, lng: 21.8054767 },
    { lat: 48.7151761, lng: 21.8052142 },
    { lat: 48.7148671, lng: 21.8039829 },
    { lat: 48.7145354, lng: 21.8034328 },
    { lat: 48.7142355, lng: 21.8023342 },
    { lat: 48.7141446, lng: 21.801698 },
    { lat: 48.7140815, lng: 21.8010887 },
    { lat: 48.714072, lng: 21.8000405 },
    { lat: 48.7139954, lng: 21.7995652 },
    { lat: 48.7131844, lng: 21.7957962 },
    { lat: 48.7128647, lng: 21.7946721 },
    { lat: 48.7126963, lng: 21.793269 },
    { lat: 48.7127072, lng: 21.7930489 },
  ],
  Palín: [
    { lat: 48.659658, lng: 21.9623881 },
    { lat: 48.6506034, lng: 21.9672961 },
    { lat: 48.6501305, lng: 21.9675519 },
    { lat: 48.6501376, lng: 21.9678778 },
    { lat: 48.6501859, lng: 21.967927 },
    { lat: 48.6501025, lng: 21.9680978 },
    { lat: 48.6481461, lng: 21.9661456 },
    { lat: 48.6475669, lng: 21.9653309 },
    { lat: 48.6472608, lng: 21.9651059 },
    { lat: 48.646854, lng: 21.9651203 },
    { lat: 48.6460672, lng: 21.9655109 },
    { lat: 48.6452717, lng: 21.9654114 },
    { lat: 48.6439869, lng: 21.9650941 },
    { lat: 48.6437855, lng: 21.9648629 },
    { lat: 48.6437103, lng: 21.9640582 },
    { lat: 48.6432863, lng: 21.963337 },
    { lat: 48.6430507, lng: 21.9633151 },
    { lat: 48.6415993, lng: 21.9639438 },
    { lat: 48.6436972, lng: 21.9719635 },
    { lat: 48.6440335, lng: 21.972963 },
    { lat: 48.6433496, lng: 21.975879 },
    { lat: 48.643142, lng: 21.9763559 },
    { lat: 48.6442931, lng: 21.9798328 },
    { lat: 48.6446407, lng: 21.982018 },
    { lat: 48.644756, lng: 21.9830912 },
    { lat: 48.6451145, lng: 21.9854972 },
    { lat: 48.6453035, lng: 21.9865011 },
    { lat: 48.6452616, lng: 21.9865312 },
    { lat: 48.6457621, lng: 21.9887766 },
    { lat: 48.6459924, lng: 21.9896282 },
    { lat: 48.646075, lng: 21.9899216 },
    { lat: 48.6462127, lng: 21.9899399 },
    { lat: 48.6454611, lng: 21.9904814 },
    { lat: 48.6459131, lng: 21.9915707 },
    { lat: 48.6470426, lng: 21.9946942 },
    { lat: 48.6489189, lng: 21.9929912 },
    { lat: 48.6486878, lng: 21.9920585 },
    { lat: 48.6490719, lng: 21.9917048 },
    { lat: 48.6504777, lng: 21.9948974 },
    { lat: 48.6507028, lng: 21.9946774 },
    { lat: 48.6507372, lng: 21.9947512 },
    { lat: 48.6508108, lng: 21.9946784 },
    { lat: 48.6510479, lng: 21.9952127 },
    { lat: 48.6514453, lng: 21.9948166 },
    { lat: 48.6516847, lng: 21.9949021 },
    { lat: 48.6542604, lng: 22.0009834 },
    { lat: 48.6558188, lng: 21.9996255 },
    { lat: 48.6564738, lng: 22.0011527 },
    { lat: 48.6566618, lng: 22.0008284 },
    { lat: 48.6572274, lng: 22.0019678 },
    { lat: 48.6579976, lng: 22.0015377 },
    { lat: 48.65834, lng: 22.0033543 },
    { lat: 48.6607679, lng: 22.0029062 },
    { lat: 48.6609199, lng: 22.0035976 },
    { lat: 48.6632323, lng: 22.0021958 },
    { lat: 48.6637411, lng: 22.0065865 },
    { lat: 48.6638272, lng: 22.0070222 },
    { lat: 48.6638092, lng: 22.0073008 },
    { lat: 48.6638505, lng: 22.0075149 },
    { lat: 48.6638221, lng: 22.0076446 },
    { lat: 48.6640056, lng: 22.0076991 },
    { lat: 48.6640441, lng: 22.0077048 },
    { lat: 48.6646482, lng: 22.0077936 },
    { lat: 48.6653968, lng: 22.007774 },
    { lat: 48.6659673, lng: 22.0076365 },
    { lat: 48.6659559, lng: 22.0075012 },
    { lat: 48.666043, lng: 22.0071946 },
    { lat: 48.6663828, lng: 22.0070173 },
    { lat: 48.6666045, lng: 22.0073872 },
    { lat: 48.6694459, lng: 22.0062762 },
    { lat: 48.6697791, lng: 22.0060473 },
    { lat: 48.6697275, lng: 22.0058302 },
    { lat: 48.6699812, lng: 22.0055307 },
    { lat: 48.6702855, lng: 22.0049507 },
    { lat: 48.6715258, lng: 22.0013043 },
    { lat: 48.6721147, lng: 22.0002193 },
    { lat: 48.6727365, lng: 21.9994258 },
    { lat: 48.6736533, lng: 21.9985175 },
    { lat: 48.6741671, lng: 21.9982215 },
    { lat: 48.6772735, lng: 21.9968192 },
    { lat: 48.6781558, lng: 21.9963278 },
    { lat: 48.6783421, lng: 21.9960781 },
    { lat: 48.6784778, lng: 21.9957377 },
    { lat: 48.6786526, lng: 21.9946586 },
    { lat: 48.6785818, lng: 21.9940212 },
    { lat: 48.6781207, lng: 21.985789 },
    { lat: 48.6789433, lng: 21.9719764 },
    { lat: 48.6795047, lng: 21.965214 },
    { lat: 48.6795747, lng: 21.9649093 },
    { lat: 48.6793229, lng: 21.9646401 },
    { lat: 48.679314, lng: 21.9629715 },
    { lat: 48.6793705, lng: 21.9616665 },
    { lat: 48.6795891, lng: 21.960767 },
    { lat: 48.6798774, lng: 21.9581075 },
    { lat: 48.6793508, lng: 21.9579202 },
    { lat: 48.6792234, lng: 21.9557446 },
    { lat: 48.6740824, lng: 21.9538945 },
    { lat: 48.673576, lng: 21.9535217 },
    { lat: 48.6734065, lng: 21.9533975 },
    { lat: 48.673151, lng: 21.9532088 },
    { lat: 48.6728002, lng: 21.9543933 },
    { lat: 48.672607, lng: 21.9546345 },
    { lat: 48.672432, lng: 21.9549723 },
    { lat: 48.6724301, lng: 21.95535 },
    { lat: 48.659658, lng: 21.9623881 },
  ],
  Iňačovce: [
    { lat: 48.7106901, lng: 22.0481346 },
    { lat: 48.7110345, lng: 22.042499 },
    { lat: 48.7110137, lng: 22.042191 },
    { lat: 48.7109537, lng: 22.0419931 },
    { lat: 48.7105956, lng: 22.0412776 },
    { lat: 48.7099504, lng: 22.0403173 },
    { lat: 48.7089904, lng: 22.0398323 },
    { lat: 48.7083276, lng: 22.0400102 },
    { lat: 48.707874, lng: 22.0400384 },
    { lat: 48.7077083, lng: 22.0397469 },
    { lat: 48.707551, lng: 22.0388741 },
    { lat: 48.7074446, lng: 22.0386527 },
    { lat: 48.7071694, lng: 22.0382993 },
    { lat: 48.7070303, lng: 22.0378981 },
    { lat: 48.7070122, lng: 22.0375191 },
    { lat: 48.7070607, lng: 22.0371064 },
    { lat: 48.7072275, lng: 22.036417 },
    { lat: 48.7072218, lng: 22.0359468 },
    { lat: 48.7070327, lng: 22.0355765 },
    { lat: 48.7066951, lng: 22.0352896 },
    { lat: 48.7065606, lng: 22.0350829 },
    { lat: 48.7065068, lng: 22.0348562 },
    { lat: 48.7065596, lng: 22.0345694 },
    { lat: 48.7069322, lng: 22.0340709 },
    { lat: 48.7070128, lng: 22.0337769 },
    { lat: 48.7070241, lng: 22.0330746 },
    { lat: 48.7071211, lng: 22.0327672 },
    { lat: 48.7080719, lng: 22.0315668 },
    { lat: 48.7081393, lng: 22.0313793 },
    { lat: 48.7081399, lng: 22.0310744 },
    { lat: 48.707996, lng: 22.0306582 },
    { lat: 48.7076872, lng: 22.0301748 },
    { lat: 48.7075032, lng: 22.0295453 },
    { lat: 48.7072753, lng: 22.0292352 },
    { lat: 48.7074606, lng: 22.0290423 },
    { lat: 48.7077266, lng: 22.0282184 },
    { lat: 48.7077282, lng: 22.028099 },
    { lat: 48.707637, lng: 22.0274992 },
    { lat: 48.7074761, lng: 22.0269365 },
    { lat: 48.7069704, lng: 22.0261264 },
    { lat: 48.7067542, lng: 22.0255639 },
    { lat: 48.706739, lng: 22.0243318 },
    { lat: 48.7066454, lng: 22.0231171 },
    { lat: 48.7064345, lng: 22.0218901 },
    { lat: 48.7061738, lng: 22.0209775 },
    { lat: 48.7061041, lng: 22.0206494 },
    { lat: 48.7060581, lng: 22.0200579 },
    { lat: 48.7058271, lng: 22.0191173 },
    { lat: 48.7058847, lng: 22.0189371 },
    { lat: 48.7063, lng: 22.018294 },
    { lat: 48.7063586, lng: 22.0181141 },
    { lat: 48.7063604, lng: 22.017871 },
    { lat: 48.7062019, lng: 22.0171294 },
    { lat: 48.7062102, lng: 22.0169651 },
    { lat: 48.7062708, lng: 22.0168215 },
    { lat: 48.7063991, lng: 22.0166786 },
    { lat: 48.7067618, lng: 22.0165203 },
    { lat: 48.7070283, lng: 22.0162822 },
    { lat: 48.7071758, lng: 22.0158726 },
    { lat: 48.7078167, lng: 22.0148454 },
    { lat: 48.7078961, lng: 22.0145921 },
    { lat: 48.7078964, lng: 22.0143741 },
    { lat: 48.7076741, lng: 22.0129593 },
    { lat: 48.7077051, lng: 22.0127524 },
    { lat: 48.7078727, lng: 22.0125671 },
    { lat: 48.7082436, lng: 22.0123684 },
    { lat: 48.7083108, lng: 22.0122628 },
    { lat: 48.7083738, lng: 22.0114323 },
    { lat: 48.7083533, lng: 22.0108739 },
    { lat: 48.7084264, lng: 22.0106885 },
    { lat: 48.7084593, lng: 22.0106069 },
    { lat: 48.7085971, lng: 22.0098894 },
    { lat: 48.7085539, lng: 22.0093148 },
    { lat: 48.7076193, lng: 22.0044407 },
    { lat: 48.7073832, lng: 22.0037992 },
    { lat: 48.7072285, lng: 22.0025353 },
    { lat: 48.7065596, lng: 22.001315 },
    { lat: 48.7062717, lng: 22.0010408 },
    { lat: 48.7056825, lng: 22.0007265 },
    { lat: 48.7056361, lng: 22.0003939 },
    { lat: 48.7057231, lng: 21.9996305 },
    { lat: 48.7055532, lng: 21.9987115 },
    { lat: 48.7058034, lng: 21.9978092 },
    { lat: 48.705745, lng: 21.995058 },
    { lat: 48.70606, lng: 21.9946475 },
    { lat: 48.7061962, lng: 21.9942335 },
    { lat: 48.7061755, lng: 21.9938052 },
    { lat: 48.7065347, lng: 21.9930895 },
    { lat: 48.706762, lng: 21.9922668 },
    { lat: 48.7070394, lng: 21.9917833 },
    { lat: 48.7073326, lng: 21.9908971 },
    { lat: 48.7076194, lng: 21.990299 },
    { lat: 48.7054796, lng: 21.9885129 },
    { lat: 48.705374, lng: 21.9887396 },
    { lat: 48.7034272, lng: 21.9875763 },
    { lat: 48.7016419, lng: 21.9889906 },
    { lat: 48.7004751, lng: 21.9905364 },
    { lat: 48.6991601, lng: 21.9924626 },
    { lat: 48.6961468, lng: 21.9950463 },
    { lat: 48.6966297, lng: 21.9962934 },
    { lat: 48.6965173, lng: 21.9967537 },
    { lat: 48.6965957, lng: 21.9983962 },
    { lat: 48.6961151, lng: 21.9981097 },
    { lat: 48.6957842, lng: 21.9980175 },
    { lat: 48.6956128, lng: 21.9980438 },
    { lat: 48.6952502, lng: 21.9982535 },
    { lat: 48.6934088, lng: 22.0004622 },
    { lat: 48.6921584, lng: 22.0019616 },
    { lat: 48.6929862, lng: 22.0041169 },
    { lat: 48.6927487, lng: 22.0045193 },
    { lat: 48.6921405, lng: 22.0053195 },
    { lat: 48.6928358, lng: 22.0064315 },
    { lat: 48.6927856, lng: 22.006539 },
    { lat: 48.6928651, lng: 22.0066585 },
    { lat: 48.6920171, lng: 22.0074552 },
    { lat: 48.6924478, lng: 22.0085105 },
    { lat: 48.6923611, lng: 22.0085993 },
    { lat: 48.6923862, lng: 22.0086994 },
    { lat: 48.6922217, lng: 22.0088271 },
    { lat: 48.6922697, lng: 22.0090915 },
    { lat: 48.6921872, lng: 22.0091221 },
    { lat: 48.6920416, lng: 22.0086926 },
    { lat: 48.6919028, lng: 22.0088086 },
    { lat: 48.6921614, lng: 22.0097277 },
    { lat: 48.6923624, lng: 22.0115987 },
    { lat: 48.6921732, lng: 22.0117288 },
    { lat: 48.692191, lng: 22.0121394 },
    { lat: 48.6914363, lng: 22.0127433 },
    { lat: 48.6884573, lng: 22.0299584 },
    { lat: 48.6869815, lng: 22.0386153 },
    { lat: 48.6870155, lng: 22.0386195 },
    { lat: 48.6861883, lng: 22.0431316 },
    { lat: 48.6865796, lng: 22.0430339 },
    { lat: 48.6870138, lng: 22.0430737 },
    { lat: 48.687121, lng: 22.0431397 },
    { lat: 48.6873448, lng: 22.0433667 },
    { lat: 48.6878053, lng: 22.0441737 },
    { lat: 48.6882149, lng: 22.0446016 },
    { lat: 48.6885824, lng: 22.0448278 },
    { lat: 48.6894248, lng: 22.0449979 },
    { lat: 48.6901198, lng: 22.04523 },
    { lat: 48.6908239, lng: 22.045265 },
    { lat: 48.6908426, lng: 22.0454345 },
    { lat: 48.6906517, lng: 22.047317 },
    { lat: 48.6904631, lng: 22.0479939 },
    { lat: 48.6898294, lng: 22.0497508 },
    { lat: 48.6893323, lng: 22.0509719 },
    { lat: 48.6892371, lng: 22.0513638 },
    { lat: 48.6891784, lng: 22.0518715 },
    { lat: 48.6887149, lng: 22.052479 },
    { lat: 48.6883391, lng: 22.0525241 },
    { lat: 48.6859733, lng: 22.0522351 },
    { lat: 48.6855034, lng: 22.0525767 },
    { lat: 48.6853505, lng: 22.0525292 },
    { lat: 48.6848242, lng: 22.0519742 },
    { lat: 48.6844867, lng: 22.0519752 },
    { lat: 48.6839398, lng: 22.0521533 },
    { lat: 48.683344, lng: 22.0524992 },
    { lat: 48.6828427, lng: 22.0529354 },
    { lat: 48.6824345, lng: 22.0534593 },
    { lat: 48.6821549, lng: 22.0535743 },
    { lat: 48.6819898, lng: 22.053395 },
    { lat: 48.681765, lng: 22.0520415 },
    { lat: 48.681417, lng: 22.051471 },
    { lat: 48.6812174, lng: 22.051404 },
    { lat: 48.6809219, lng: 22.0514209 },
    { lat: 48.6806981, lng: 22.051514 },
    { lat: 48.6803599, lng: 22.0511057 },
    { lat: 48.680165, lng: 22.0509591 },
    { lat: 48.6799638, lng: 22.0508277 },
    { lat: 48.6797399, lng: 22.0508179 },
    { lat: 48.6795525, lng: 22.0506997 },
    { lat: 48.679404, lng: 22.0504662 },
    { lat: 48.6790308, lng: 22.0493935 },
    { lat: 48.6787614, lng: 22.0491434 },
    { lat: 48.678442, lng: 22.0487199 },
    { lat: 48.6783702, lng: 22.0484505 },
    { lat: 48.6784027, lng: 22.0482141 },
    { lat: 48.6788099, lng: 22.0479326 },
    { lat: 48.6789038, lng: 22.0477203 },
    { lat: 48.6789121, lng: 22.0474614 },
    { lat: 48.6783133, lng: 22.0469796 },
    { lat: 48.6778624, lng: 22.0465185 },
    { lat: 48.6773612, lng: 22.0465855 },
    { lat: 48.6771934, lng: 22.0465303 },
    { lat: 48.6770244, lng: 22.0463805 },
    { lat: 48.6768863, lng: 22.0461567 },
    { lat: 48.6768241, lng: 22.0459167 },
    { lat: 48.6769573, lng: 22.0451821 },
    { lat: 48.6769511, lng: 22.0450227 },
    { lat: 48.676862, lng: 22.0448645 },
    { lat: 48.6764675, lng: 22.0446467 },
    { lat: 48.6759731, lng: 22.0445838 },
    { lat: 48.6757099, lng: 22.0438326 },
    { lat: 48.6755899, lng: 22.0436595 },
    { lat: 48.6750512, lng: 22.043431 },
    { lat: 48.6749181, lng: 22.0434647 },
    { lat: 48.6746438, lng: 22.0446899 },
    { lat: 48.6744919, lng: 22.0449684 },
    { lat: 48.6742276, lng: 22.0450641 },
    { lat: 48.6739597, lng: 22.0448222 },
    { lat: 48.6733528, lng: 22.0434376 },
    { lat: 48.6729495, lng: 22.0434715 },
    { lat: 48.6726497, lng: 22.0433435 },
    { lat: 48.6725233, lng: 22.0433803 },
    { lat: 48.6723925, lng: 22.043879 },
    { lat: 48.6722655, lng: 22.0439792 },
    { lat: 48.6720624, lng: 22.0439068 },
    { lat: 48.6716076, lng: 22.0435606 },
    { lat: 48.6713943, lng: 22.0438143 },
    { lat: 48.6710649, lng: 22.0443816 },
    { lat: 48.6709155, lng: 22.0444385 },
    { lat: 48.6707837, lng: 22.0443397 },
    { lat: 48.6707146, lng: 22.0441752 },
    { lat: 48.6707121, lng: 22.0435628 },
    { lat: 48.6705891, lng: 22.043409 },
    { lat: 48.6704462, lng: 22.0434543 },
    { lat: 48.6703384, lng: 22.043646 },
    { lat: 48.6703008, lng: 22.0442278 },
    { lat: 48.6701907, lng: 22.0443349 },
    { lat: 48.6700126, lng: 22.0443579 },
    { lat: 48.6699077, lng: 22.0442612 },
    { lat: 48.6697751, lng: 22.0434253 },
    { lat: 48.6697088, lng: 22.0432845 },
    { lat: 48.6695606, lng: 22.0432496 },
    { lat: 48.6693229, lng: 22.0433726 },
    { lat: 48.6687742, lng: 22.0434334 },
    { lat: 48.6685447, lng: 22.0436613 },
    { lat: 48.6681432, lng: 22.0438065 },
    { lat: 48.6681612, lng: 22.0447401 },
    { lat: 48.6683884, lng: 22.0461849 },
    { lat: 48.6683871, lng: 22.0466501 },
    { lat: 48.668328, lng: 22.0471442 },
    { lat: 48.66803, lng: 22.0485901 },
    { lat: 48.6687489, lng: 22.0492927 },
    { lat: 48.668891, lng: 22.0495934 },
    { lat: 48.6690268, lng: 22.0502312 },
    { lat: 48.6692496, lng: 22.0505678 },
    { lat: 48.6694804, lng: 22.0507526 },
    { lat: 48.6698368, lng: 22.0508915 },
    { lat: 48.6700318, lng: 22.050864 },
    { lat: 48.6700889, lng: 22.0510812 },
    { lat: 48.6699028, lng: 22.0516648 },
    { lat: 48.6702039, lng: 22.051886 },
    { lat: 48.6706438, lng: 22.0518975 },
    { lat: 48.6708338, lng: 22.0520237 },
    { lat: 48.6708102, lng: 22.052639 },
    { lat: 48.6713423, lng: 22.05287 },
    { lat: 48.6713367, lng: 22.0533415 },
    { lat: 48.6714094, lng: 22.0542575 },
    { lat: 48.6715887, lng: 22.055167 },
    { lat: 48.6714586, lng: 22.0561682 },
    { lat: 48.6712726, lng: 22.056436 },
    { lat: 48.6711098, lng: 22.0568194 },
    { lat: 48.6694209, lng: 22.0584435 },
    { lat: 48.6687177, lng: 22.059377 },
    { lat: 48.668783, lng: 22.059424 },
    { lat: 48.6690075, lng: 22.0598733 },
    { lat: 48.6689258, lng: 22.060454 },
    { lat: 48.6687128, lng: 22.0609115 },
    { lat: 48.6688023, lng: 22.0617366 },
    { lat: 48.668968, lng: 22.0622642 },
    { lat: 48.6688711, lng: 22.0627268 },
    { lat: 48.668929, lng: 22.0629795 },
    { lat: 48.6690725, lng: 22.0631948 },
    { lat: 48.6691641, lng: 22.0632211 },
    { lat: 48.6699687, lng: 22.0639331 },
    { lat: 48.6700762, lng: 22.0640985 },
    { lat: 48.670148, lng: 22.0652665 },
    { lat: 48.6703137, lng: 22.0655155 },
    { lat: 48.6708329, lng: 22.0658242 },
    { lat: 48.671038, lng: 22.0661227 },
    { lat: 48.6713024, lng: 22.0663627 },
    { lat: 48.6714392, lng: 22.0670984 },
    { lat: 48.6713791, lng: 22.0671682 },
    { lat: 48.6714773, lng: 22.0676046 },
    { lat: 48.6721301, lng: 22.0679957 },
    { lat: 48.6723201, lng: 22.0684683 },
    { lat: 48.6725605, lng: 22.068593 },
    { lat: 48.67285, lng: 22.0683679 },
    { lat: 48.6733631, lng: 22.068614 },
    { lat: 48.6734453, lng: 22.0694677 },
    { lat: 48.6734267, lng: 22.0699136 },
    { lat: 48.6735939, lng: 22.070322 },
    { lat: 48.6736694, lng: 22.0707489 },
    { lat: 48.6735106, lng: 22.0712038 },
    { lat: 48.6732884, lng: 22.0716035 },
    { lat: 48.6732954, lng: 22.0720445 },
    { lat: 48.6727473, lng: 22.0729723 },
    { lat: 48.6724194, lng: 22.0738899 },
    { lat: 48.6718214, lng: 22.0746217 },
    { lat: 48.6716994, lng: 22.0748846 },
    { lat: 48.6716681, lng: 22.0752234 },
    { lat: 48.6718828, lng: 22.0756338 },
    { lat: 48.6716736, lng: 22.0760676 },
    { lat: 48.6717255, lng: 22.076494 },
    { lat: 48.6718605, lng: 22.0766874 },
    { lat: 48.6721904, lng: 22.0767912 },
    { lat: 48.6722846, lng: 22.0770641 },
    { lat: 48.6722874, lng: 22.0775576 },
    { lat: 48.6721514, lng: 22.0780326 },
    { lat: 48.6717789, lng: 22.078497 },
    { lat: 48.671737, lng: 22.0787748 },
    { lat: 48.6711626, lng: 22.0789775 },
    { lat: 48.6708838, lng: 22.0792279 },
    { lat: 48.6708643, lng: 22.0794478 },
    { lat: 48.6707185, lng: 22.0796507 },
    { lat: 48.670659, lng: 22.07966 },
    { lat: 48.670644, lng: 22.079739 },
    { lat: 48.670631, lng: 22.079803 },
    { lat: 48.670437, lng: 22.080795 },
    { lat: 48.67087, lng: 22.081668 },
    { lat: 48.671658, lng: 22.082569 },
    { lat: 48.672283, lng: 22.082615 },
    { lat: 48.672541, lng: 22.082829 },
    { lat: 48.672538, lng: 22.083693 },
    { lat: 48.672885, lng: 22.084597 },
    { lat: 48.673381, lng: 22.084431 },
    { lat: 48.673528, lng: 22.084227 },
    { lat: 48.673581, lng: 22.084178 },
    { lat: 48.673828, lng: 22.083904 },
    { lat: 48.673835, lng: 22.083168 },
    { lat: 48.674492, lng: 22.081497 },
    { lat: 48.674769, lng: 22.081064 },
    { lat: 48.675334, lng: 22.081101 },
    { lat: 48.676245, lng: 22.080179 },
    { lat: 48.676398, lng: 22.08067 },
    { lat: 48.676848, lng: 22.080742 },
    { lat: 48.677059, lng: 22.080555 },
    { lat: 48.678288, lng: 22.08057 },
    { lat: 48.678379, lng: 22.080565 },
    { lat: 48.6784, lng: 22.080564 },
    { lat: 48.678879, lng: 22.079329 },
    { lat: 48.679155, lng: 22.07949 },
    { lat: 48.679241, lng: 22.077868 },
    { lat: 48.67911, lng: 22.07714 },
    { lat: 48.678791, lng: 22.076743 },
    { lat: 48.679563, lng: 22.075482 },
    { lat: 48.680202, lng: 22.074228 },
    { lat: 48.680216, lng: 22.074201 },
    { lat: 48.680284, lng: 22.074066 },
    { lat: 48.680352, lng: 22.074045 },
    { lat: 48.681413, lng: 22.074105 },
    { lat: 48.681468, lng: 22.074122 },
    { lat: 48.681609, lng: 22.074167 },
    { lat: 48.682641, lng: 22.074494 },
    { lat: 48.683402, lng: 22.074735 },
    { lat: 48.683456, lng: 22.074765 },
    { lat: 48.683782, lng: 22.074856 },
    { lat: 48.68381, lng: 22.074876 },
    { lat: 48.686258, lng: 22.079921 },
    { lat: 48.68647, lng: 22.080357 },
    { lat: 48.687663, lng: 22.081843 },
    { lat: 48.687715, lng: 22.081909 },
    { lat: 48.687758, lng: 22.081962 },
    { lat: 48.68782, lng: 22.082045 },
    { lat: 48.687655, lng: 22.082357 },
    { lat: 48.687592, lng: 22.082835 },
    { lat: 48.687637, lng: 22.083257 },
    { lat: 48.687756, lng: 22.083564 },
    { lat: 48.689655, lng: 22.086761 },
    { lat: 48.69082, lng: 22.088696 },
    { lat: 48.701604, lng: 22.081992 },
    { lat: 48.701614, lng: 22.07933 },
    { lat: 48.701605, lng: 22.077219 },
    { lat: 48.701602, lng: 22.075585 },
    { lat: 48.701586, lng: 22.073206 },
    { lat: 48.702363, lng: 22.073423 },
    { lat: 48.7024, lng: 22.073342 },
    { lat: 48.702438, lng: 22.07326 },
    { lat: 48.70247, lng: 22.073189 },
    { lat: 48.7033069, lng: 22.0697564 },
    { lat: 48.7034308, lng: 22.0685873 },
    { lat: 48.7030604, lng: 22.0664016 },
    { lat: 48.7052468, lng: 22.063331 },
    { lat: 48.7066105, lng: 22.0612763 },
    { lat: 48.7068571, lng: 22.0607268 },
    { lat: 48.7069669, lng: 22.060006 },
    { lat: 48.7069581, lng: 22.0573396 },
    { lat: 48.7070937, lng: 22.0568004 },
    { lat: 48.707259, lng: 22.0564676 },
    { lat: 48.7074147, lng: 22.0562461 },
    { lat: 48.7099412, lng: 22.0538642 },
    { lat: 48.7101023, lng: 22.0535922 },
    { lat: 48.7103195, lng: 22.0530272 },
    { lat: 48.7105575, lng: 22.0526782 },
    { lat: 48.7105196, lng: 22.0506804 },
    { lat: 48.7106288, lng: 22.0489597 },
    { lat: 48.7106901, lng: 22.0481346 },
  ],
  PustéČemerné: [
    { lat: 48.8321434, lng: 21.829065 },
    { lat: 48.8333376, lng: 21.8305214 },
    { lat: 48.8338914, lng: 21.8310502 },
    { lat: 48.8342082, lng: 21.831432 },
    { lat: 48.8343921, lng: 21.8315336 },
    { lat: 48.8346641, lng: 21.8315696 },
    { lat: 48.8353583, lng: 21.831312 },
    { lat: 48.8355221, lng: 21.8309441 },
    { lat: 48.8356033, lng: 21.8304769 },
    { lat: 48.835799, lng: 21.8303042 },
    { lat: 48.8360977, lng: 21.8304497 },
    { lat: 48.8366159, lng: 21.8308721 },
    { lat: 48.8369831, lng: 21.8308506 },
    { lat: 48.8374206, lng: 21.8305397 },
    { lat: 48.8377156, lng: 21.8298962 },
    { lat: 48.837885, lng: 21.8297 },
    { lat: 48.8382036, lng: 21.8295436 },
    { lat: 48.8384486, lng: 21.829133 },
    { lat: 48.838649, lng: 21.829189 },
    { lat: 48.8387216, lng: 21.8293829 },
    { lat: 48.8387785, lng: 21.8302787 },
    { lat: 48.8385512, lng: 21.8307593 },
    { lat: 48.8385122, lng: 21.8310291 },
    { lat: 48.8386231, lng: 21.8315775 },
    { lat: 48.8385504, lng: 21.8317661 },
    { lat: 48.8385352, lng: 21.8320084 },
    { lat: 48.8385952, lng: 21.8321942 },
    { lat: 48.8389274, lng: 21.8326898 },
    { lat: 48.8391637, lng: 21.832904 },
    { lat: 48.8393761, lng: 21.8329849 },
    { lat: 48.8394624, lng: 21.8328862 },
    { lat: 48.8394876, lng: 21.8327315 },
    { lat: 48.8393017, lng: 21.8319419 },
    { lat: 48.8393309, lng: 21.8317223 },
    { lat: 48.8395485, lng: 21.8311741 },
    { lat: 48.839725, lng: 21.8309429 },
    { lat: 48.8403957, lng: 21.830562 },
    { lat: 48.8406508, lng: 21.8303344 },
    { lat: 48.841381, lng: 21.8299928 },
    { lat: 48.8414407, lng: 21.8299946 },
    { lat: 48.8416347, lng: 21.8303595 },
    { lat: 48.8417037, lng: 21.8306861 },
    { lat: 48.8418317, lng: 21.8309303 },
    { lat: 48.8419891, lng: 21.8310188 },
    { lat: 48.8420486, lng: 21.8311417 },
    { lat: 48.8420092, lng: 21.8315794 },
    { lat: 48.842046, lng: 21.8318251 },
    { lat: 48.8421359, lng: 21.8318673 },
    { lat: 48.8424449, lng: 21.83159 },
    { lat: 48.8426075, lng: 21.8316164 },
    { lat: 48.8427751, lng: 21.831744 },
    { lat: 48.8428583, lng: 21.8319169 },
    { lat: 48.8428934, lng: 21.8323145 },
    { lat: 48.8430569, lng: 21.8324209 },
    { lat: 48.8433997, lng: 21.8319666 },
    { lat: 48.8434784, lng: 21.8317465 },
    { lat: 48.8435095, lng: 21.8313004 },
    { lat: 48.8436181, lng: 21.8311497 },
    { lat: 48.8439599, lng: 21.8311847 },
    { lat: 48.8440802, lng: 21.831443 },
    { lat: 48.8442436, lng: 21.8315458 },
    { lat: 48.8448428, lng: 21.8314816 },
    { lat: 48.8453323, lng: 21.8315315 },
    { lat: 48.8472976, lng: 21.8303158 },
    { lat: 48.8479952, lng: 21.8296182 },
    { lat: 48.8481077, lng: 21.8296201 },
    { lat: 48.848788, lng: 21.8302655 },
    { lat: 48.848923, lng: 21.8302164 },
    { lat: 48.8490008, lng: 21.8303158 },
    { lat: 48.8489954, lng: 21.830455 },
    { lat: 48.8490845, lng: 21.8304615 },
    { lat: 48.8508952, lng: 21.8296164 },
    { lat: 48.8508463, lng: 21.8288577 },
    { lat: 48.8510051, lng: 21.8287801 },
    { lat: 48.850708, lng: 21.8266813 },
    { lat: 48.8536617, lng: 21.8234127 },
    { lat: 48.8539335, lng: 21.8230483 },
    { lat: 48.8537884, lng: 21.820099 },
    { lat: 48.8536967, lng: 21.8197708 },
    { lat: 48.8536938, lng: 21.8194863 },
    { lat: 48.8537772, lng: 21.8193217 },
    { lat: 48.8536997, lng: 21.8151685 },
    { lat: 48.8534381, lng: 21.8111199 },
    { lat: 48.8534789, lng: 21.810912 },
    { lat: 48.8532, lng: 21.8109633 },
    { lat: 48.8530851, lng: 21.8108695 },
    { lat: 48.8529716, lng: 21.8106116 },
    { lat: 48.8529452, lng: 21.8102516 },
    { lat: 48.8528328, lng: 21.8097814 },
    { lat: 48.8528917, lng: 21.8093996 },
    { lat: 48.8528331, lng: 21.8093581 },
    { lat: 48.8527599, lng: 21.8093989 },
    { lat: 48.8525871, lng: 21.8092432 },
    { lat: 48.8524716, lng: 21.8088189 },
    { lat: 48.8523627, lng: 21.8088308 },
    { lat: 48.8523468, lng: 21.8085699 },
    { lat: 48.8520119, lng: 21.808412 },
    { lat: 48.8519386, lng: 21.8084471 },
    { lat: 48.8518979, lng: 21.8083513 },
    { lat: 48.851713, lng: 21.8082477 },
    { lat: 48.8516558, lng: 21.8082968 },
    { lat: 48.8514697, lng: 21.8081962 },
    { lat: 48.8513358, lng: 21.8082774 },
    { lat: 48.8511192, lng: 21.8082161 },
    { lat: 48.8511083, lng: 21.8080938 },
    { lat: 48.8509791, lng: 21.8081934 },
    { lat: 48.8509434, lng: 21.8080726 },
    { lat: 48.8508042, lng: 21.8079127 },
    { lat: 48.8508323, lng: 21.8076899 },
    { lat: 48.8505407, lng: 21.8072916 },
    { lat: 48.8504299, lng: 21.8070128 },
    { lat: 48.8503395, lng: 21.8069171 },
    { lat: 48.8503744, lng: 21.8067457 },
    { lat: 48.850272, lng: 21.8065682 },
    { lat: 48.8503919, lng: 21.8061242 },
    { lat: 48.8504772, lng: 21.8060219 },
    { lat: 48.8505012, lng: 21.8057747 },
    { lat: 48.8504027, lng: 21.8056234 },
    { lat: 48.8503676, lng: 21.8053998 },
    { lat: 48.8504434, lng: 21.8053334 },
    { lat: 48.850493, lng: 21.8053787 },
    { lat: 48.8505391, lng: 21.8052941 },
    { lat: 48.8504631, lng: 21.8052265 },
    { lat: 48.8504809, lng: 21.8051337 },
    { lat: 48.8504137, lng: 21.8050977 },
    { lat: 48.8504395, lng: 21.804965 },
    { lat: 48.8503688, lng: 21.8048039 },
    { lat: 48.8502639, lng: 21.8047509 },
    { lat: 48.8503399, lng: 21.8045552 },
    { lat: 48.8503505, lng: 21.8043545 },
    { lat: 48.8504108, lng: 21.8043456 },
    { lat: 48.8504375, lng: 21.8042475 },
    { lat: 48.8503818, lng: 21.8040469 },
    { lat: 48.8506204, lng: 21.8034943 },
    { lat: 48.8506865, lng: 21.8032174 },
    { lat: 48.8507444, lng: 21.8027271 },
    { lat: 48.8506987, lng: 21.8024387 },
    { lat: 48.8507767, lng: 21.8020228 },
    { lat: 48.85085, lng: 21.8020785 },
    { lat: 48.8509356, lng: 21.8018151 },
    { lat: 48.8509128, lng: 21.8016375 },
    { lat: 48.8509622, lng: 21.8014988 },
    { lat: 48.851025, lng: 21.8014939 },
    { lat: 48.8510561, lng: 21.8013949 },
    { lat: 48.8511525, lng: 21.8013827 },
    { lat: 48.8511375, lng: 21.8011518 },
    { lat: 48.8513761, lng: 21.8008088 },
    { lat: 48.85166, lng: 21.8008664 },
    { lat: 48.8516513, lng: 21.8005794 },
    { lat: 48.8517517, lng: 21.8005069 },
    { lat: 48.8517342, lng: 21.8001779 },
    { lat: 48.8518096, lng: 21.7999148 },
    { lat: 48.8520266, lng: 21.7997864 },
    { lat: 48.8523035, lng: 21.8000955 },
    { lat: 48.8526853, lng: 21.7999762 },
    { lat: 48.8528001, lng: 21.8002004 },
    { lat: 48.8531134, lng: 21.8000041 },
    { lat: 48.8533675, lng: 21.7997413 },
    { lat: 48.8536543, lng: 21.7996469 },
    { lat: 48.8537481, lng: 21.7995366 },
    { lat: 48.8537525, lng: 21.7992745 },
    { lat: 48.8539085, lng: 21.7989176 },
    { lat: 48.8540581, lng: 21.798915 },
    { lat: 48.8543861, lng: 21.7991983 },
    { lat: 48.8544645, lng: 21.7990135 },
    { lat: 48.8548521, lng: 21.7989517 },
    { lat: 48.854969, lng: 21.7988593 },
    { lat: 48.8552692, lng: 21.7990277 },
    { lat: 48.8554671, lng: 21.7990241 },
    { lat: 48.855702, lng: 21.7987117 },
    { lat: 48.8558808, lng: 21.7987045 },
    { lat: 48.8561924, lng: 21.7985319 },
    { lat: 48.8563164, lng: 21.798289 },
    { lat: 48.8589436, lng: 21.7977887 },
    { lat: 48.8598669, lng: 21.7974331 },
    { lat: 48.8602479, lng: 21.7971562 },
    { lat: 48.8607086, lng: 21.7970935 },
    { lat: 48.8611793, lng: 21.7972361 },
    { lat: 48.8611494, lng: 21.7969127 },
    { lat: 48.8612165, lng: 21.7967665 },
    { lat: 48.8612201, lng: 21.7966048 },
    { lat: 48.8612892, lng: 21.7965363 },
    { lat: 48.861208, lng: 21.7963157 },
    { lat: 48.8615937, lng: 21.7955759 },
    { lat: 48.861968, lng: 21.7955201 },
    { lat: 48.8630118, lng: 21.7950872 },
    { lat: 48.8635277, lng: 21.7950627 },
    { lat: 48.8635888, lng: 21.7949943 },
    { lat: 48.8637578, lng: 21.7949656 },
    { lat: 48.8638041, lng: 21.7948774 },
    { lat: 48.8641571, lng: 21.7947483 },
    { lat: 48.864206, lng: 21.7946789 },
    { lat: 48.864381, lng: 21.793621 },
    { lat: 48.863632, lng: 21.792183 },
    { lat: 48.863484, lng: 21.791654 },
    { lat: 48.863393, lng: 21.791176 },
    { lat: 48.863373, lng: 21.789973 },
    { lat: 48.863357, lng: 21.789934 },
    { lat: 48.863319, lng: 21.789842 },
    { lat: 48.86307, lng: 21.789242 },
    { lat: 48.862832, lng: 21.78844 },
    { lat: 48.862788, lng: 21.788393 },
    { lat: 48.862569, lng: 21.788156 },
    { lat: 48.862453, lng: 21.787761 },
    { lat: 48.862063, lng: 21.786994 },
    { lat: 48.861745, lng: 21.786684 },
    { lat: 48.861412, lng: 21.786771 },
    { lat: 48.861036, lng: 21.786758 },
    { lat: 48.860558, lng: 21.786577 },
    { lat: 48.860291, lng: 21.786375 },
    { lat: 48.859979, lng: 21.786397 },
    { lat: 48.859124, lng: 21.786807 },
    { lat: 48.858747, lng: 21.786856 },
    { lat: 48.858319, lng: 21.786997 },
    { lat: 48.858073, lng: 21.786918 },
    { lat: 48.857806, lng: 21.786923 },
    { lat: 48.857422, lng: 21.786752 },
    { lat: 48.857074, lng: 21.786839 },
    { lat: 48.856786, lng: 21.786958 },
    { lat: 48.856501, lng: 21.786978 },
    { lat: 48.856317, lng: 21.787064 },
    { lat: 48.856137, lng: 21.787267 },
    { lat: 48.855965, lng: 21.787417 },
    { lat: 48.855661, lng: 21.787529 },
    { lat: 48.855147, lng: 21.787708 },
    { lat: 48.854761, lng: 21.787921 },
    { lat: 48.854384, lng: 21.788028 },
    { lat: 48.853918, lng: 21.788159 },
    { lat: 48.853503, lng: 21.788418 },
    { lat: 48.853308, lng: 21.788604 },
    { lat: 48.853191, lng: 21.788752 },
    { lat: 48.853037, lng: 21.789073 },
    { lat: 48.852953, lng: 21.78922 },
    { lat: 48.852801, lng: 21.789344 },
    { lat: 48.852518, lng: 21.789467 },
    { lat: 48.852326, lng: 21.789568 },
    { lat: 48.852121, lng: 21.789614 },
    { lat: 48.8519, lng: 21.78962 },
    { lat: 48.851773, lng: 21.789653 },
    { lat: 48.851446, lng: 21.790112 },
    { lat: 48.851283, lng: 21.790185 },
    { lat: 48.850931, lng: 21.790267 },
    { lat: 48.850767, lng: 21.790504 },
    { lat: 48.850656, lng: 21.790564 },
    { lat: 48.850535, lng: 21.790549 },
    { lat: 48.850277, lng: 21.790507 },
    { lat: 48.850068, lng: 21.790645 },
    { lat: 48.849999, lng: 21.79069 },
    { lat: 48.849932, lng: 21.790736 },
    { lat: 48.849861, lng: 21.790769 },
    { lat: 48.849685, lng: 21.790853 },
    { lat: 48.849471, lng: 21.790809 },
    { lat: 48.849275, lng: 21.790831 },
    { lat: 48.849131, lng: 21.790929 },
    { lat: 48.848794, lng: 21.79106 },
    { lat: 48.848572, lng: 21.791199 },
    { lat: 48.848196, lng: 21.791221 },
    { lat: 48.84776, lng: 21.791132 },
    { lat: 48.847383, lng: 21.791105 },
    { lat: 48.847133, lng: 21.791215 },
    { lat: 48.846915, lng: 21.791383 },
    { lat: 48.846746, lng: 21.79156 },
    { lat: 48.846523, lng: 21.791872 },
    { lat: 48.846211, lng: 21.792214 },
    { lat: 48.846025, lng: 21.792328 },
    { lat: 48.84594, lng: 21.792381 },
    { lat: 48.845492, lng: 21.792911 },
    { lat: 48.845472, lng: 21.792926 },
    { lat: 48.845214, lng: 21.793131 },
    { lat: 48.844822, lng: 21.793441 },
    { lat: 48.844192, lng: 21.793603 },
    { lat: 48.844034, lng: 21.793643 },
    { lat: 48.843256, lng: 21.794003 },
    { lat: 48.843173, lng: 21.794027 },
    { lat: 48.84238, lng: 21.79426 },
    { lat: 48.84149, lng: 21.794534 },
    { lat: 48.84071, lng: 21.7951 },
    { lat: 48.839489, lng: 21.795545 },
    { lat: 48.83873, lng: 21.795652 },
    { lat: 48.838208, lng: 21.795973 },
    { lat: 48.838001, lng: 21.796101 },
    { lat: 48.837891, lng: 21.796227 },
    { lat: 48.837671, lng: 21.796482 },
    { lat: 48.837411, lng: 21.796781 },
    { lat: 48.837282, lng: 21.79693 },
    { lat: 48.83726, lng: 21.796956 },
    { lat: 48.837165, lng: 21.797061 },
    { lat: 48.836911, lng: 21.797342 },
    { lat: 48.83644, lng: 21.797863 },
    { lat: 48.83644, lng: 21.797757 },
    { lat: 48.835848, lng: 21.797817 },
    { lat: 48.834749, lng: 21.797459 },
    { lat: 48.833745, lng: 21.796923 },
    { lat: 48.833514, lng: 21.796898 },
    { lat: 48.833193, lng: 21.796862 },
    { lat: 48.832947, lng: 21.796835 },
    { lat: 48.832934, lng: 21.796836 },
    { lat: 48.832904, lng: 21.796837 },
    { lat: 48.83281, lng: 21.79684 },
    { lat: 48.832578, lng: 21.796849 },
    { lat: 48.832404, lng: 21.796854 },
    { lat: 48.831723, lng: 21.796966 },
    { lat: 48.832028, lng: 21.7979068 },
    { lat: 48.8321374, lng: 21.8001098 },
    { lat: 48.8323513, lng: 21.8010219 },
    { lat: 48.832602, lng: 21.8026076 },
    { lat: 48.8327272, lng: 21.8030486 },
    { lat: 48.8327786, lng: 21.8036837 },
    { lat: 48.8327205, lng: 21.8039575 },
    { lat: 48.8326053, lng: 21.8041965 },
    { lat: 48.8325599, lng: 21.8044788 },
    { lat: 48.8325834, lng: 21.8046934 },
    { lat: 48.8325217, lng: 21.804785 },
    { lat: 48.8325018, lng: 21.805361 },
    { lat: 48.8321537, lng: 21.80577 },
    { lat: 48.8319343, lng: 21.8062884 },
    { lat: 48.8316127, lng: 21.8072918 },
    { lat: 48.8320589, lng: 21.8079232 },
    { lat: 48.8323525, lng: 21.8089579 },
    { lat: 48.8324012, lng: 21.8103912 },
    { lat: 48.8324875, lng: 21.8107274 },
    { lat: 48.8326446, lng: 21.8123981 },
    { lat: 48.8327277, lng: 21.8126903 },
    { lat: 48.8329876, lng: 21.8130397 },
    { lat: 48.8337203, lng: 21.8135744 },
    { lat: 48.8340464, lng: 21.8139599 },
    { lat: 48.833985, lng: 21.8163125 },
    { lat: 48.8327335, lng: 21.8163332 },
    { lat: 48.8327595, lng: 21.8171583 },
    { lat: 48.8338897, lng: 21.8170527 },
    { lat: 48.8342849, lng: 21.818455 },
    { lat: 48.8344171, lng: 21.8199048 },
    { lat: 48.8343867, lng: 21.8208137 },
    { lat: 48.8342618, lng: 21.8216803 },
    { lat: 48.8338743, lng: 21.8218369 },
    { lat: 48.8342239, lng: 21.8238268 },
    { lat: 48.8342568, lng: 21.8243941 },
    { lat: 48.83431, lng: 21.8245831 },
    { lat: 48.8344921, lng: 21.8245772 },
    { lat: 48.8351938, lng: 21.8256496 },
    { lat: 48.8354154, lng: 21.82626 },
    { lat: 48.8354347, lng: 21.826532 },
    { lat: 48.8355571, lng: 21.8267765 },
    { lat: 48.8359704, lng: 21.8270972 },
    { lat: 48.83639, lng: 21.8270991 },
    { lat: 48.8364992, lng: 21.8274093 },
    { lat: 48.8356518, lng: 21.8278754 },
    { lat: 48.8342383, lng: 21.8284589 },
    { lat: 48.8336012, lng: 21.8286635 },
    { lat: 48.8324685, lng: 21.8287529 },
    { lat: 48.8322466, lng: 21.828915 },
    { lat: 48.8321434, lng: 21.829065 },
  ],
  Hnojné: [
    { lat: 48.796268, lng: 22.0496142 },
    { lat: 48.7963878, lng: 22.0483203 },
    { lat: 48.7963175, lng: 22.0467961 },
    { lat: 48.7962528, lng: 22.0465463 },
    { lat: 48.7928113, lng: 22.0446901 },
    { lat: 48.7927733, lng: 22.0412501 },
    { lat: 48.7898566, lng: 22.042514 },
    { lat: 48.7840304, lng: 22.0446889 },
    { lat: 48.7840436, lng: 22.045373 },
    { lat: 48.7839991, lng: 22.0457186 },
    { lat: 48.7837146, lng: 22.0459658 },
    { lat: 48.7835842, lng: 22.0461991 },
    { lat: 48.7835955, lng: 22.046385 },
    { lat: 48.7835071, lng: 22.0466007 },
    { lat: 48.7833004, lng: 22.0467961 },
    { lat: 48.7830411, lng: 22.0471903 },
    { lat: 48.782446, lng: 22.0477024 },
    { lat: 48.7822242, lng: 22.0480278 },
    { lat: 48.7825008, lng: 22.0489542 },
    { lat: 48.7815171, lng: 22.0495865 },
    { lat: 48.781508, lng: 22.0498188 },
    { lat: 48.7808611, lng: 22.0502378 },
    { lat: 48.7805048, lng: 22.0503886 },
    { lat: 48.776074, lng: 22.0514156 },
    { lat: 48.7761048, lng: 22.0518141 },
    { lat: 48.7760142, lng: 22.0518308 },
    { lat: 48.7759373, lng: 22.0514467 },
    { lat: 48.7732275, lng: 22.0519568 },
    { lat: 48.7734936, lng: 22.0529193 },
    { lat: 48.7739973, lng: 22.054745 },
    { lat: 48.7724448, lng: 22.0544985 },
    { lat: 48.772171, lng: 22.0541587 },
    { lat: 48.771954, lng: 22.0540619 },
    { lat: 48.7718248, lng: 22.0539327 },
    { lat: 48.7717705, lng: 22.0535862 },
    { lat: 48.7713375, lng: 22.0539188 },
    { lat: 48.7712044, lng: 22.0562063 },
    { lat: 48.7706351, lng: 22.056005 },
    { lat: 48.7706191, lng: 22.057081 },
    { lat: 48.7705717, lng: 22.0571182 },
    { lat: 48.7706945, lng: 22.0575002 },
    { lat: 48.7711761, lng: 22.0602817 },
    { lat: 48.7701645, lng: 22.0607864 },
    { lat: 48.7695843, lng: 22.0613127 },
    { lat: 48.7697381, lng: 22.0619094 },
    { lat: 48.770045, lng: 22.0643967 },
    { lat: 48.7695649, lng: 22.0644554 },
    { lat: 48.7698468, lng: 22.0657084 },
    { lat: 48.7701569, lng: 22.0689946 },
    { lat: 48.7702903, lng: 22.0694887 },
    { lat: 48.7700312, lng: 22.0697136 },
    { lat: 48.7700445, lng: 22.0697977 },
    { lat: 48.7697797, lng: 22.0697815 },
    { lat: 48.7694958, lng: 22.0698562 },
    { lat: 48.7700967, lng: 22.0735509 },
    { lat: 48.7699426, lng: 22.0736424 },
    { lat: 48.7704296, lng: 22.0765235 },
    { lat: 48.7692224, lng: 22.0772243 },
    { lat: 48.7676215, lng: 22.0773564 },
    { lat: 48.766979, lng: 22.077176 },
    { lat: 48.767088, lng: 22.07729 },
    { lat: 48.767434, lng: 22.078145 },
    { lat: 48.770953, lng: 22.07762 },
    { lat: 48.775005, lng: 22.077014 },
    { lat: 48.775161, lng: 22.076453 },
    { lat: 48.775167, lng: 22.076454 },
    { lat: 48.775232, lng: 22.076461 },
    { lat: 48.775951, lng: 22.076547 },
    { lat: 48.776129, lng: 22.07663 },
    { lat: 48.776225, lng: 22.076682 },
    { lat: 48.776284, lng: 22.076714 },
    { lat: 48.776354, lng: 22.076764 },
    { lat: 48.776506, lng: 22.076887 },
    { lat: 48.776659, lng: 22.077078 },
    { lat: 48.777134, lng: 22.078123 },
    { lat: 48.777259, lng: 22.078282 },
    { lat: 48.777339, lng: 22.078345 },
    { lat: 48.777461, lng: 22.078368 },
    { lat: 48.777596, lng: 22.078454 },
    { lat: 48.777683, lng: 22.079015 },
    { lat: 48.777843, lng: 22.079633 },
    { lat: 48.778718, lng: 22.079429 },
    { lat: 48.778773, lng: 22.079484 },
    { lat: 48.778953, lng: 22.079305 },
    { lat: 48.778962, lng: 22.07987 },
    { lat: 48.778787, lng: 22.07998 },
    { lat: 48.779064, lng: 22.080313 },
    { lat: 48.779114, lng: 22.080745 },
    { lat: 48.779315, lng: 22.081197 },
    { lat: 48.779479, lng: 22.081774 },
    { lat: 48.779375, lng: 22.08258 },
    { lat: 48.779426, lng: 22.083097 },
    { lat: 48.779689, lng: 22.083095 },
    { lat: 48.779724, lng: 22.083098 },
    { lat: 48.780123, lng: 22.083129 },
    { lat: 48.780762, lng: 22.083327 },
    { lat: 48.781025, lng: 22.08332 },
    { lat: 48.781068, lng: 22.083319 },
    { lat: 48.78118, lng: 22.083316 },
    { lat: 48.781287, lng: 22.083314 },
    { lat: 48.781841, lng: 22.083876 },
    { lat: 48.781898, lng: 22.084416 },
    { lat: 48.782486, lng: 22.085583 },
    { lat: 48.782581, lng: 22.08569 },
    { lat: 48.782702, lng: 22.085827 },
    { lat: 48.783007, lng: 22.086517 },
    { lat: 48.783058, lng: 22.086631 },
    { lat: 48.783343, lng: 22.087278 },
    { lat: 48.783745, lng: 22.08755 },
    { lat: 48.783846, lng: 22.087618 },
    { lat: 48.784157, lng: 22.087646 },
    { lat: 48.784371, lng: 22.087476 },
    { lat: 48.784593, lng: 22.087146 },
    { lat: 48.784816, lng: 22.087107 },
    { lat: 48.784993, lng: 22.087217 },
    { lat: 48.785091, lng: 22.087544 },
    { lat: 48.785805, lng: 22.087843 },
    { lat: 48.786521, lng: 22.088394 },
    { lat: 48.787112, lng: 22.088499 },
    { lat: 48.787902, lng: 22.089515 },
    { lat: 48.78883, lng: 22.091531 },
    { lat: 48.789024, lng: 22.091743 },
    { lat: 48.789423, lng: 22.091514 },
    { lat: 48.789723, lng: 22.090752 },
    { lat: 48.790099, lng: 22.089531 },
    { lat: 48.790333, lng: 22.088773 },
    { lat: 48.790402, lng: 22.088195 },
    { lat: 48.791215, lng: 22.087726 },
    { lat: 48.791656, lng: 22.08673 },
    { lat: 48.792616, lng: 22.083615 },
    { lat: 48.7920027, lng: 22.0781626 },
    { lat: 48.7920289, lng: 22.0773703 },
    { lat: 48.7920973, lng: 22.0771732 },
    { lat: 48.7922627, lng: 22.0757026 },
    { lat: 48.7932306, lng: 22.0649635 },
    { lat: 48.7934661, lng: 22.0611151 },
    { lat: 48.796268, lng: 22.0496142 },
  ],
  Stretavka: [
    { lat: 48.6164173, lng: 21.9583813 },
    { lat: 48.616338, lng: 21.9584541 },
    { lat: 48.6150046, lng: 21.9598421 },
    { lat: 48.614104, lng: 21.9608999 },
    { lat: 48.6140277, lng: 21.9610457 },
    { lat: 48.6139687, lng: 21.9617089 },
    { lat: 48.6139211, lng: 21.961825 },
    { lat: 48.6143651, lng: 21.9619492 },
    { lat: 48.6144963, lng: 21.9620684 },
    { lat: 48.6148556, lng: 21.962127 },
    { lat: 48.6151143, lng: 21.9620846 },
    { lat: 48.6153388, lng: 21.9622401 },
    { lat: 48.6155375, lng: 21.9625424 },
    { lat: 48.6155835, lng: 21.9627229 },
    { lat: 48.6158861, lng: 21.9632601 },
    { lat: 48.6165561, lng: 21.9641922 },
    { lat: 48.6167129, lng: 21.9642622 },
    { lat: 48.616793, lng: 21.9644028 },
    { lat: 48.6168399, lng: 21.9648389 },
    { lat: 48.6169993, lng: 21.9652764 },
    { lat: 48.6169741, lng: 21.9665795 },
    { lat: 48.6169258, lng: 21.9668542 },
    { lat: 48.6166254, lng: 21.9674439 },
    { lat: 48.6164341, lng: 21.9675695 },
    { lat: 48.6162285, lng: 21.9675385 },
    { lat: 48.615567, lng: 21.9678538 },
    { lat: 48.6150879, lng: 21.9679551 },
    { lat: 48.6147627, lng: 21.9684044 },
    { lat: 48.6144614, lng: 21.9692312 },
    { lat: 48.614388, lng: 21.9699421 },
    { lat: 48.6143727, lng: 21.9708289 },
    { lat: 48.6145099, lng: 21.9713698 },
    { lat: 48.6146316, lng: 21.971514 },
    { lat: 48.6147766, lng: 21.9718678 },
    { lat: 48.6147471, lng: 21.9723625 },
    { lat: 48.6146362, lng: 21.9726041 },
    { lat: 48.6142878, lng: 21.9729878 },
    { lat: 48.614055, lng: 21.9733511 },
    { lat: 48.6137328, lng: 21.9744212 },
    { lat: 48.6136977, lng: 21.9747261 },
    { lat: 48.6134488, lng: 21.9745396 },
    { lat: 48.611204, lng: 21.9745614 },
    { lat: 48.6099136, lng: 21.9751512 },
    { lat: 48.6095337, lng: 21.9754801 },
    { lat: 48.6093188, lng: 21.9758462 },
    { lat: 48.6092811, lng: 21.976325 },
    { lat: 48.6096112, lng: 21.9772976 },
    { lat: 48.6097089, lng: 21.9774293 },
    { lat: 48.6098732, lng: 21.9774769 },
    { lat: 48.6102909, lng: 21.9779903 },
    { lat: 48.6105867, lng: 21.978531 },
    { lat: 48.6108342, lng: 21.9798101 },
    { lat: 48.6115345, lng: 21.9816979 },
    { lat: 48.6115651, lng: 21.9820303 },
    { lat: 48.6121651, lng: 21.9836874 },
    { lat: 48.610201, lng: 21.9849661 },
    { lat: 48.6083808, lng: 21.9860656 },
    { lat: 48.6074782, lng: 21.9867326 },
    { lat: 48.6069078, lng: 21.9869081 },
    { lat: 48.6067395, lng: 21.9870636 },
    { lat: 48.6065058, lng: 21.9875786 },
    { lat: 48.6059022, lng: 21.9881933 },
    { lat: 48.6054793, lng: 21.9889684 },
    { lat: 48.605442, lng: 21.9892818 },
    { lat: 48.6056164, lng: 21.990155 },
    { lat: 48.6055892, lng: 21.99056 },
    { lat: 48.6053151, lng: 21.9907538 },
    { lat: 48.6044882, lng: 21.9905288 },
    { lat: 48.6035713, lng: 21.9911172 },
    { lat: 48.6030534, lng: 21.9911949 },
    { lat: 48.6028957, lng: 21.9911365 },
    { lat: 48.6026928, lng: 21.990802 },
    { lat: 48.6026438, lng: 21.9902794 },
    { lat: 48.6028736, lng: 21.9886845 },
    { lat: 48.6027888, lng: 21.9883932 },
    { lat: 48.6025572, lng: 21.9882633 },
    { lat: 48.6023105, lng: 21.9882981 },
    { lat: 48.6007835, lng: 21.9896503 },
    { lat: 48.5992636, lng: 21.990191 },
    { lat: 48.5987582, lng: 21.9901794 },
    { lat: 48.5981653, lng: 21.9900441 },
    { lat: 48.5978758, lng: 21.9902572 },
    { lat: 48.5999207, lng: 21.9943666 },
    { lat: 48.6007441, lng: 21.995642 },
    { lat: 48.601344, lng: 21.9962522 },
    { lat: 48.603756, lng: 21.9974927 },
    { lat: 48.604234, lng: 21.9979516 },
    { lat: 48.6045303, lng: 21.9983708 },
    { lat: 48.6047719, lng: 21.9988717 },
    { lat: 48.6049375, lng: 21.9994692 },
    { lat: 48.60512, lng: 22.0006373 },
    { lat: 48.6054018, lng: 22.0028606 },
    { lat: 48.605267, lng: 22.0041548 },
    { lat: 48.6053168, lng: 22.0041165 },
    { lat: 48.6060825, lng: 22.0023101 },
    { lat: 48.608137, lng: 22.0020062 },
    { lat: 48.6105282, lng: 22.0023997 },
    { lat: 48.6106601, lng: 22.0022772 },
    { lat: 48.6083586, lng: 21.996645 },
    { lat: 48.6082763, lng: 21.9962675 },
    { lat: 48.6083801, lng: 21.9962812 },
    { lat: 48.6084965, lng: 21.9955183 },
    { lat: 48.6086789, lng: 21.9954804 },
    { lat: 48.6092768, lng: 21.9951352 },
    { lat: 48.6105638, lng: 21.9953637 },
    { lat: 48.6111239, lng: 21.9956822 },
    { lat: 48.6114775, lng: 21.9960183 },
    { lat: 48.6119015, lng: 21.9962453 },
    { lat: 48.6212491, lng: 21.9986427 },
    { lat: 48.6239135, lng: 22.000321 },
    { lat: 48.6240016, lng: 22.0003768 },
    { lat: 48.6247853, lng: 21.9984873 },
    { lat: 48.6252974, lng: 21.999002 },
    { lat: 48.6265434, lng: 21.9965959 },
    { lat: 48.6285458, lng: 21.9976458 },
    { lat: 48.6297744, lng: 21.998483 },
    { lat: 48.6294032, lng: 21.9931246 },
    { lat: 48.6300791, lng: 21.9936177 },
    { lat: 48.6302255, lng: 21.9935331 },
    { lat: 48.6300853, lng: 21.9908496 },
    { lat: 48.6296012, lng: 21.9825636 },
    { lat: 48.6290464, lng: 21.9801883 },
    { lat: 48.6287229, lng: 21.9781528 },
    { lat: 48.6285809, lng: 21.976734 },
    { lat: 48.6284138, lng: 21.9765752 },
    { lat: 48.6280185, lng: 21.9763741 },
    { lat: 48.6277385, lng: 21.9767402 },
    { lat: 48.6275179, lng: 21.9771914 },
    { lat: 48.6271038, lng: 21.9791513 },
    { lat: 48.6268832, lng: 21.9794087 },
    { lat: 48.6266945, lng: 21.9794709 },
    { lat: 48.6265544, lng: 21.9794482 },
    { lat: 48.6263017, lng: 21.979276 },
    { lat: 48.6256811, lng: 21.9787078 },
    { lat: 48.6249362, lng: 21.9782426 },
    { lat: 48.6244153, lng: 21.9785101 },
    { lat: 48.6238246, lng: 21.9785992 },
    { lat: 48.6235639, lng: 21.9782949 },
    { lat: 48.6229108, lng: 21.9778131 },
    { lat: 48.6230241, lng: 21.9775692 },
    { lat: 48.6222431, lng: 21.9770158 },
    { lat: 48.6205296, lng: 21.9776412 },
    { lat: 48.6203596, lng: 21.9767324 },
    { lat: 48.6205555, lng: 21.9702345 },
    { lat: 48.6188956, lng: 21.9700326 },
    { lat: 48.6191782, lng: 21.9633312 },
    { lat: 48.6190153, lng: 21.96016 },
    { lat: 48.6181104, lng: 21.9605542 },
    { lat: 48.6168886, lng: 21.9611904 },
    { lat: 48.6168543, lng: 21.9593535 },
    { lat: 48.6164173, lng: 21.9583813 },
  ],
  Krásnovce: [
    { lat: 48.7169091, lng: 21.8775032 },
    { lat: 48.7165657, lng: 21.8775813 },
    { lat: 48.7162604, lng: 21.877701 },
    { lat: 48.7156657, lng: 21.8785522 },
    { lat: 48.7151568, lng: 21.8790617 },
    { lat: 48.7145406, lng: 21.8795611 },
    { lat: 48.7145084, lng: 21.8793086 },
    { lat: 48.7136414, lng: 21.8793292 },
    { lat: 48.7128477, lng: 21.8790801 },
    { lat: 48.7128382, lng: 21.8790219 },
    { lat: 48.7105845, lng: 21.8797491 },
    { lat: 48.7101556, lng: 21.8799597 },
    { lat: 48.710492, lng: 21.8824361 },
    { lat: 48.7108704, lng: 21.8847038 },
    { lat: 48.7123497, lng: 21.8850118 },
    { lat: 48.7122803, lng: 21.8844551 },
    { lat: 48.7134583, lng: 21.8849455 },
    { lat: 48.7139996, lng: 21.8850772 },
    { lat: 48.7139811, lng: 21.887449 },
    { lat: 48.7141566, lng: 21.889085 },
    { lat: 48.7125912, lng: 21.8898142 },
    { lat: 48.7110723, lng: 21.8902515 },
    { lat: 48.7111375, lng: 21.8906915 },
    { lat: 48.7109906, lng: 21.8908002 },
    { lat: 48.7111988, lng: 21.891908 },
    { lat: 48.7111334, lng: 21.8919852 },
    { lat: 48.710892, lng: 21.8920516 },
    { lat: 48.7114032, lng: 21.89495 },
    { lat: 48.7110762, lng: 21.8954042 },
    { lat: 48.7109431, lng: 21.8961432 },
    { lat: 48.7104323, lng: 21.8953012 },
    { lat: 48.7094878, lng: 21.895944 },
    { lat: 48.709834, lng: 21.8969081 },
    { lat: 48.7104245, lng: 21.8970834 },
    { lat: 48.7105425, lng: 21.8978673 },
    { lat: 48.7106673, lng: 21.8998271 },
    { lat: 48.7102954, lng: 21.8998871 },
    { lat: 48.7100504, lng: 21.9000302 },
    { lat: 48.7099974, lng: 21.9002003 },
    { lat: 48.7099623, lng: 21.9009468 },
    { lat: 48.7100814, lng: 21.9013407 },
    { lat: 48.7102358, lng: 21.9015697 },
    { lat: 48.7110621, lng: 21.9021307 },
    { lat: 48.7112897, lng: 21.9024046 },
    { lat: 48.7111434, lng: 21.9028929 },
    { lat: 48.7109529, lng: 21.9031899 },
    { lat: 48.7105208, lng: 21.9033677 },
    { lat: 48.7103706, lng: 21.9033857 },
    { lat: 48.7098203, lng: 21.9032037 },
    { lat: 48.7093778, lng: 21.9038096 },
    { lat: 48.7090312, lng: 21.9032746 },
    { lat: 48.7087388, lng: 21.9031904 },
    { lat: 48.7080611, lng: 21.9035259 },
    { lat: 48.7068378, lng: 21.9037746 },
    { lat: 48.706896, lng: 21.9040259 },
    { lat: 48.7069863, lng: 21.9045742 },
    { lat: 48.7069306, lng: 21.9046384 },
    { lat: 48.706943, lng: 21.9052218 },
    { lat: 48.7071189, lng: 21.9061291 },
    { lat: 48.7073264, lng: 21.9067248 },
    { lat: 48.7072993, lng: 21.9068115 },
    { lat: 48.7073623, lng: 21.9071673 },
    { lat: 48.7074274, lng: 21.9072882 },
    { lat: 48.7079187, lng: 21.9076815 },
    { lat: 48.7079989, lng: 21.9079166 },
    { lat: 48.7080761, lng: 21.907988 },
    { lat: 48.7080974, lng: 21.9081218 },
    { lat: 48.7082419, lng: 21.9083162 },
    { lat: 48.7083649, lng: 21.9089135 },
    { lat: 48.7085383, lng: 21.9093199 },
    { lat: 48.7084976, lng: 21.9093479 },
    { lat: 48.7086902, lng: 21.9100777 },
    { lat: 48.7090927, lng: 21.9113129 },
    { lat: 48.7095088, lng: 21.9109035 },
    { lat: 48.7098709, lng: 21.9106814 },
    { lat: 48.7103058, lng: 21.9106143 },
    { lat: 48.7117202, lng: 21.909961 },
    { lat: 48.7122996, lng: 21.9095187 },
    { lat: 48.7125783, lng: 21.9091934 },
    { lat: 48.7133026, lng: 21.9087406 },
    { lat: 48.7138106, lng: 21.9085004 },
    { lat: 48.7139778, lng: 21.9085666 },
    { lat: 48.7141505, lng: 21.9089083 },
    { lat: 48.7142273, lng: 21.9089142 },
    { lat: 48.7155007, lng: 21.9083338 },
    { lat: 48.7159346, lng: 21.9082304 },
    { lat: 48.7165119, lng: 21.9079766 },
    { lat: 48.7188995, lng: 21.9064291 },
    { lat: 48.7206671, lng: 21.9058161 },
    { lat: 48.7210276, lng: 21.9057899 },
    { lat: 48.7227414, lng: 21.9050543 },
    { lat: 48.7228641, lng: 21.9050575 },
    { lat: 48.7239798, lng: 21.9041622 },
    { lat: 48.7247633, lng: 21.903291 },
    { lat: 48.7256929, lng: 21.9032601 },
    { lat: 48.7259308, lng: 21.9034177 },
    { lat: 48.7267703, lng: 21.9035149 },
    { lat: 48.726411, lng: 21.9000277 },
    { lat: 48.7284361, lng: 21.8989748 },
    { lat: 48.7285723, lng: 21.8990168 },
    { lat: 48.7300296, lng: 21.8985498 },
    { lat: 48.7313009, lng: 21.8985496 },
    { lat: 48.7320677, lng: 21.8972454 },
    { lat: 48.7326958, lng: 21.8962928 },
    { lat: 48.7326339, lng: 21.8959778 },
    { lat: 48.7325665, lng: 21.8958517 },
    { lat: 48.7320853, lng: 21.8958119 },
    { lat: 48.732006, lng: 21.895211 },
    { lat: 48.7318489, lng: 21.8922652 },
    { lat: 48.7312164, lng: 21.8919869 },
    { lat: 48.730846, lng: 21.8917037 },
    { lat: 48.7309298, lng: 21.8903996 },
    { lat: 48.730095, lng: 21.8903165 },
    { lat: 48.7297224, lng: 21.8903467 },
    { lat: 48.729845, lng: 21.8888821 },
    { lat: 48.7292554, lng: 21.8887404 },
    { lat: 48.7293561, lng: 21.8848814 },
    { lat: 48.728686, lng: 21.8848487 },
    { lat: 48.7287014, lng: 21.8813041 },
    { lat: 48.7287323, lng: 21.8804848 },
    { lat: 48.7288154, lng: 21.8802551 },
    { lat: 48.7287703, lng: 21.8798105 },
    { lat: 48.7285072, lng: 21.8790245 },
    { lat: 48.7284499, lng: 21.876557 },
    { lat: 48.7285892, lng: 21.8765449 },
    { lat: 48.7284145, lng: 21.874819 },
    { lat: 48.7285242, lng: 21.8747114 },
    { lat: 48.7285219, lng: 21.8746667 },
    { lat: 48.7276737, lng: 21.8745776 },
    { lat: 48.726794, lng: 21.8747558 },
    { lat: 48.7263422, lng: 21.8747775 },
    { lat: 48.726227, lng: 21.8739233 },
    { lat: 48.7246261, lng: 21.8737855 },
    { lat: 48.7237881, lng: 21.8739267 },
    { lat: 48.7232802, lng: 21.8740758 },
    { lat: 48.723281, lng: 21.8742385 },
    { lat: 48.7219636, lng: 21.8744212 },
    { lat: 48.7209355, lng: 21.8746409 },
    { lat: 48.7209581, lng: 21.8754768 },
    { lat: 48.7199235, lng: 21.8757588 },
    { lat: 48.7191475, lng: 21.8760827 },
    { lat: 48.7192375, lng: 21.8773557 },
    { lat: 48.7185318, lng: 21.8771646 },
    { lat: 48.7181611, lng: 21.8771438 },
    { lat: 48.7169091, lng: 21.8775032 },
  ],
  Bracovce: [
    { lat: 48.6433488, lng: 21.8588583 },
    { lat: 48.643919, lng: 21.8615229 },
    { lat: 48.6440118, lng: 21.861933 },
    { lat: 48.644089, lng: 21.8619217 },
    { lat: 48.6469914, lng: 21.8613946 },
    { lat: 48.6480103, lng: 21.8611109 },
    { lat: 48.6483699, lng: 21.8609215 },
    { lat: 48.6509347, lng: 21.8592934 },
    { lat: 48.6510403, lng: 21.8595042 },
    { lat: 48.6526902, lng: 21.8581782 },
    { lat: 48.6528098, lng: 21.8582734 },
    { lat: 48.6564266, lng: 21.8553869 },
    { lat: 48.6559968, lng: 21.8526316 },
    { lat: 48.6547441, lng: 21.8462498 },
    { lat: 48.6541987, lng: 21.8440352 },
    { lat: 48.6539997, lng: 21.8427304 },
    { lat: 48.6540564, lng: 21.8427107 },
    { lat: 48.6535487, lng: 21.8402153 },
    { lat: 48.6525816, lng: 21.8365466 },
    { lat: 48.6516224, lng: 21.8331771 },
    { lat: 48.6510929, lng: 21.8295981 },
    { lat: 48.6510477, lng: 21.8282462 },
    { lat: 48.6512687, lng: 21.8220787 },
    { lat: 48.6504686, lng: 21.8168447 },
    { lat: 48.6502518, lng: 21.8157371 },
    { lat: 48.6498339, lng: 21.8141235 },
    { lat: 48.6491275, lng: 21.8119368 },
    { lat: 48.6488106, lng: 21.8110861 },
    { lat: 48.6482175, lng: 21.80897 },
    { lat: 48.6478198, lng: 21.8078338 },
    { lat: 48.6476373, lng: 21.8069239 },
    { lat: 48.6473538, lng: 21.805892 },
    { lat: 48.6453467, lng: 21.8007758 },
    { lat: 48.645062, lng: 21.8002602 },
    { lat: 48.6445914, lng: 21.7997817 },
    { lat: 48.6447646, lng: 21.7996436 },
    { lat: 48.6449573, lng: 21.7998278 },
    { lat: 48.6450669, lng: 21.7998526 },
    { lat: 48.6452737, lng: 21.7996648 },
    { lat: 48.6453667, lng: 21.7994828 },
    { lat: 48.6457576, lng: 21.7994214 },
    { lat: 48.6459316, lng: 21.7990143 },
    { lat: 48.6462191, lng: 21.7993436 },
    { lat: 48.646355, lng: 21.8003189 },
    { lat: 48.6464037, lng: 21.8004124 },
    { lat: 48.64698, lng: 21.8007231 },
    { lat: 48.6471557, lng: 21.800688 },
    { lat: 48.6480423, lng: 21.8001405 },
    { lat: 48.6482569, lng: 21.7997815 },
    { lat: 48.6483867, lng: 21.7996716 },
    { lat: 48.6490664, lng: 21.7995442 },
    { lat: 48.6491923, lng: 21.7994566 },
    { lat: 48.6492813, lng: 21.7992001 },
    { lat: 48.6494007, lng: 21.7991236 },
    { lat: 48.649865, lng: 21.7990293 },
    { lat: 48.650698, lng: 21.7985746 },
    { lat: 48.6510513, lng: 21.798487 },
    { lat: 48.6515504, lng: 21.7985155 },
    { lat: 48.6516963, lng: 21.7984609 },
    { lat: 48.6519566, lng: 21.7980023 },
    { lat: 48.6525814, lng: 21.7971622 },
    { lat: 48.6532643, lng: 21.7966919 },
    { lat: 48.6535932, lng: 21.7959293 },
    { lat: 48.6537097, lng: 21.7958216 },
    { lat: 48.6542191, lng: 21.7957317 },
    { lat: 48.6542875, lng: 21.7955536 },
    { lat: 48.6541622, lng: 21.7951085 },
    { lat: 48.6541617, lng: 21.7948737 },
    { lat: 48.6544059, lng: 21.7944078 },
    { lat: 48.6542247, lng: 21.7942301 },
    { lat: 48.6535374, lng: 21.7939766 },
    { lat: 48.6525152, lng: 21.7942104 },
    { lat: 48.6518368, lng: 21.7940347 },
    { lat: 48.6514896, lng: 21.7940569 },
    { lat: 48.6512246, lng: 21.7941161 },
    { lat: 48.651114, lng: 21.794407 },
    { lat: 48.651114, lng: 21.794409 },
    { lat: 48.651114, lng: 21.79441 },
    { lat: 48.649044, lng: 21.796122 },
    { lat: 48.648941, lng: 21.796189 },
    { lat: 48.648845, lng: 21.796252 },
    { lat: 48.648666, lng: 21.796328 },
    { lat: 48.647942, lng: 21.796326 },
    { lat: 48.64794, lng: 21.796326 },
    { lat: 48.647029, lng: 21.796324 },
    { lat: 48.644874, lng: 21.796346 },
    { lat: 48.642054, lng: 21.796375 },
    { lat: 48.639261, lng: 21.796415 },
    { lat: 48.638899, lng: 21.79642 },
    { lat: 48.638713, lng: 21.796423 },
    { lat: 48.636346, lng: 21.796457 },
    { lat: 48.635897, lng: 21.796463 },
    { lat: 48.635432, lng: 21.79647 },
    { lat: 48.635372, lng: 21.79647 },
    { lat: 48.635351, lng: 21.796471 },
    { lat: 48.634988, lng: 21.796476 },
    { lat: 48.634827, lng: 21.796478 },
    { lat: 48.633628, lng: 21.796547 },
    { lat: 48.633445, lng: 21.796558 },
    { lat: 48.633263, lng: 21.796568 },
    { lat: 48.632005, lng: 21.796641 },
    { lat: 48.631484, lng: 21.796654 },
    { lat: 48.631009, lng: 21.796665 },
    { lat: 48.630994, lng: 21.796666 },
    { lat: 48.630554, lng: 21.796676 },
    { lat: 48.630451, lng: 21.796679 },
    { lat: 48.630221, lng: 21.796684 },
    { lat: 48.630054, lng: 21.796688 },
    { lat: 48.629728, lng: 21.796696 },
    { lat: 48.629489, lng: 21.796702 },
    { lat: 48.62923, lng: 21.796709 },
    { lat: 48.628869, lng: 21.796717 },
    { lat: 48.627907, lng: 21.796741 },
    { lat: 48.627671, lng: 21.796747 },
    { lat: 48.627471, lng: 21.796752 },
    { lat: 48.627373, lng: 21.796751 },
    { lat: 48.627353, lng: 21.796751 },
    { lat: 48.626936, lng: 21.796751 },
    { lat: 48.626845, lng: 21.796753 },
    { lat: 48.626593, lng: 21.796759 },
    { lat: 48.626531, lng: 21.79676 },
    { lat: 48.626416, lng: 21.796763 },
    { lat: 48.626285, lng: 21.796766 },
    { lat: 48.625843, lng: 21.796864 },
    { lat: 48.625803, lng: 21.796873 },
    { lat: 48.625778, lng: 21.796878 },
    { lat: 48.625612, lng: 21.79691 },
    { lat: 48.625482, lng: 21.796934 },
    { lat: 48.625307, lng: 21.796967 },
    { lat: 48.625278, lng: 21.796977 },
    { lat: 48.624975, lng: 21.797069 },
    { lat: 48.624335, lng: 21.797205 },
    { lat: 48.624063, lng: 21.797277 },
    { lat: 48.623736, lng: 21.797364 },
    { lat: 48.623256, lng: 21.797466 },
    { lat: 48.623086, lng: 21.7975 },
    { lat: 48.622778, lng: 21.797562 },
    { lat: 48.62276, lng: 21.797565 },
    { lat: 48.622721, lng: 21.797573 },
    { lat: 48.622631, lng: 21.797591 },
    { lat: 48.622586, lng: 21.7976 },
    { lat: 48.622519, lng: 21.797613 },
    { lat: 48.62203, lng: 21.797743 },
    { lat: 48.621978, lng: 21.797758 },
    { lat: 48.6253053, lng: 21.8084341 },
    { lat: 48.6259726, lng: 21.8088275 },
    { lat: 48.6261343, lng: 21.8087723 },
    { lat: 48.6268526, lng: 21.8080581 },
    { lat: 48.6269513, lng: 21.8084338 },
    { lat: 48.6266638, lng: 21.8092672 },
    { lat: 48.6266468, lng: 21.8095285 },
    { lat: 48.6268877, lng: 21.8101312 },
    { lat: 48.62695, lng: 21.8102786 },
    { lat: 48.6272704, lng: 21.8106579 },
    { lat: 48.6276173, lng: 21.8108837 },
    { lat: 48.6277537, lng: 21.8110694 },
    { lat: 48.6282086, lng: 21.8123764 },
    { lat: 48.6288536, lng: 21.8131603 },
    { lat: 48.629086, lng: 21.8135722 },
    { lat: 48.6292602, lng: 21.8139936 },
    { lat: 48.6295528, lng: 21.8150353 },
    { lat: 48.6299821, lng: 21.815793 },
    { lat: 48.6301384, lng: 21.8162158 },
    { lat: 48.6301917, lng: 21.8166224 },
    { lat: 48.6299477, lng: 21.8171017 },
    { lat: 48.6295165, lng: 21.8181701 },
    { lat: 48.6293305, lng: 21.8185034 },
    { lat: 48.6289752, lng: 21.8194157 },
    { lat: 48.6288933, lng: 21.8207614 },
    { lat: 48.6289217, lng: 21.8215044 },
    { lat: 48.6297293, lng: 21.8232658 },
    { lat: 48.6314737, lng: 21.8267017 },
    { lat: 48.6353342, lng: 21.8345669 },
    { lat: 48.635638, lng: 21.8354813 },
    { lat: 48.6357785, lng: 21.8357355 },
    { lat: 48.6367359, lng: 21.835908 },
    { lat: 48.6370103, lng: 21.8357243 },
    { lat: 48.6374832, lng: 21.8348359 },
    { lat: 48.6378545, lng: 21.8354892 },
    { lat: 48.6380904, lng: 21.8360778 },
    { lat: 48.6387683, lng: 21.8381017 },
    { lat: 48.639142, lng: 21.8395865 },
    { lat: 48.639882, lng: 21.8420542 },
    { lat: 48.6401903, lng: 21.8433178 },
    { lat: 48.6429988, lng: 21.8560613 },
    { lat: 48.642842, lng: 21.8563423 },
    { lat: 48.6433488, lng: 21.8588583 },
  ],
  ZemplínskaŠiroká: [
    { lat: 48.7255307, lng: 21.9782394 },
    { lat: 48.7253841, lng: 21.9773619 },
    { lat: 48.7254281, lng: 21.9761356 },
    { lat: 48.7236489, lng: 21.9714577 },
    { lat: 48.7228279, lng: 21.9715091 },
    { lat: 48.7223926, lng: 21.9691476 },
    { lat: 48.7223265, lng: 21.9691631 },
    { lat: 48.7217976, lng: 21.9641571 },
    { lat: 48.7217887, lng: 21.9637514 },
    { lat: 48.7218409, lng: 21.9632686 },
    { lat: 48.7216649, lng: 21.961753 },
    { lat: 48.7217013, lng: 21.961586 },
    { lat: 48.72171, lng: 21.9615263 },
    { lat: 48.7217478, lng: 21.9612812 },
    { lat: 48.718509, lng: 21.9565228 },
    { lat: 48.7176971, lng: 21.9524125 },
    { lat: 48.7145758, lng: 21.9497162 },
    { lat: 48.71416, lng: 21.9498684 },
    { lat: 48.7104623, lng: 21.951818 },
    { lat: 48.7083572, lng: 21.9530215 },
    { lat: 48.7083056, lng: 21.9527548 },
    { lat: 48.7076164, lng: 21.9523252 },
    { lat: 48.7050959, lng: 21.9502659 },
    { lat: 48.7034385, lng: 21.9491617 },
    { lat: 48.7026349, lng: 21.949009 },
    { lat: 48.7017449, lng: 21.9490995 },
    { lat: 48.6991229, lng: 21.9502842 },
    { lat: 48.6983894, lng: 21.9507799 },
    { lat: 48.6978806, lng: 21.9513077 },
    { lat: 48.6974351, lng: 21.951348 },
    { lat: 48.6968154, lng: 21.951672 },
    { lat: 48.6963266, lng: 21.9518276 },
    { lat: 48.6955196, lng: 21.9519084 },
    { lat: 48.6954379, lng: 21.9515608 },
    { lat: 48.6943464, lng: 21.9516339 },
    { lat: 48.694122, lng: 21.949581 },
    { lat: 48.6936017, lng: 21.9496583 },
    { lat: 48.6931935, lng: 21.9490737 },
    { lat: 48.693384, lng: 21.9484137 },
    { lat: 48.6934065, lng: 21.9468756 },
    { lat: 48.6931859, lng: 21.9469651 },
    { lat: 48.6929957, lng: 21.9469164 },
    { lat: 48.6922441, lng: 21.9462321 },
    { lat: 48.6920579, lng: 21.946205 },
    { lat: 48.691935, lng: 21.946108 },
    { lat: 48.6917616, lng: 21.9458768 },
    { lat: 48.6915478, lng: 21.9458783 },
    { lat: 48.6911408, lng: 21.9462045 },
    { lat: 48.6909573, lng: 21.9461508 },
    { lat: 48.6905297, lng: 21.9457839 },
    { lat: 48.6904057, lng: 21.9457752 },
    { lat: 48.6902465, lng: 21.9458844 },
    { lat: 48.6896026, lng: 21.9457291 },
    { lat: 48.6894354, lng: 21.9457917 },
    { lat: 48.6892934, lng: 21.9459664 },
    { lat: 48.6891543, lng: 21.9460256 },
    { lat: 48.688984, lng: 21.9459641 },
    { lat: 48.6887181, lng: 21.9459917 },
    { lat: 48.6883194, lng: 21.9462443 },
    { lat: 48.6879485, lng: 21.9462612 },
    { lat: 48.6876601, lng: 21.9459244 },
    { lat: 48.6875613, lng: 21.9458877 },
    { lat: 48.6870982, lng: 21.9464004 },
    { lat: 48.6865852, lng: 21.9466649 },
    { lat: 48.6863776, lng: 21.947047 },
    { lat: 48.6860906, lng: 21.9472677 },
    { lat: 48.6860038, lng: 21.9474431 },
    { lat: 48.6859269, lng: 21.9474717 },
    { lat: 48.6855523, lng: 21.9470152 },
    { lat: 48.6851516, lng: 21.9470558 },
    { lat: 48.6849148, lng: 21.9469476 },
    { lat: 48.684814, lng: 21.9469625 },
    { lat: 48.6809194, lng: 21.9491719 },
    { lat: 48.6804546, lng: 21.9472746 },
    { lat: 48.6803726, lng: 21.9470016 },
    { lat: 48.6803648, lng: 21.9470085 },
    { lat: 48.6797623, lng: 21.9474375 },
    { lat: 48.6784131, lng: 21.9481415 },
    { lat: 48.67608, lng: 21.9493594 },
    { lat: 48.6750573, lng: 21.9503028 },
    { lat: 48.674598, lng: 21.9509233 },
    { lat: 48.6741527, lng: 21.9518303 },
    { lat: 48.6737004, lng: 21.9530096 },
    { lat: 48.673576, lng: 21.9535217 },
    { lat: 48.6740824, lng: 21.9538945 },
    { lat: 48.6792234, lng: 21.9557446 },
    { lat: 48.6793508, lng: 21.9579202 },
    { lat: 48.6798774, lng: 21.9581075 },
    { lat: 48.6795891, lng: 21.960767 },
    { lat: 48.6793705, lng: 21.9616665 },
    { lat: 48.679314, lng: 21.9629715 },
    { lat: 48.6793229, lng: 21.9646401 },
    { lat: 48.6795747, lng: 21.9649093 },
    { lat: 48.6795047, lng: 21.965214 },
    { lat: 48.6789433, lng: 21.9719764 },
    { lat: 48.6781207, lng: 21.985789 },
    { lat: 48.6785818, lng: 21.9940212 },
    { lat: 48.6786526, lng: 21.9946586 },
    { lat: 48.6784778, lng: 21.9957377 },
    { lat: 48.6783421, lng: 21.9960781 },
    { lat: 48.6781558, lng: 21.9963278 },
    { lat: 48.6772735, lng: 21.9968192 },
    { lat: 48.6741671, lng: 21.9982215 },
    { lat: 48.6736533, lng: 21.9985175 },
    { lat: 48.6727365, lng: 21.9994258 },
    { lat: 48.6721147, lng: 22.0002193 },
    { lat: 48.6715258, lng: 22.0013043 },
    { lat: 48.6702855, lng: 22.0049507 },
    { lat: 48.6699812, lng: 22.0055307 },
    { lat: 48.6697275, lng: 22.0058302 },
    { lat: 48.6697791, lng: 22.0060473 },
    { lat: 48.6698303, lng: 22.0062644 },
    { lat: 48.6707669, lng: 22.0059175 },
    { lat: 48.6712154, lng: 22.0059064 },
    { lat: 48.6716611, lng: 22.0060072 },
    { lat: 48.6721475, lng: 22.0063026 },
    { lat: 48.672501, lng: 22.0066844 },
    { lat: 48.6728311, lng: 22.00716 },
    { lat: 48.6732504, lng: 22.007515 },
    { lat: 48.6752668, lng: 22.0073109 },
    { lat: 48.6769116, lng: 22.0076852 },
    { lat: 48.6777216, lng: 22.0076352 },
    { lat: 48.6782736, lng: 22.0074894 },
    { lat: 48.6788022, lng: 22.0072521 },
    { lat: 48.6794472, lng: 22.00681 },
    { lat: 48.6798358, lng: 22.0064556 },
    { lat: 48.6807343, lng: 22.0053433 },
    { lat: 48.6826871, lng: 22.0022975 },
    { lat: 48.6830989, lng: 22.0019427 },
    { lat: 48.6834985, lng: 22.0017218 },
    { lat: 48.6861355, lng: 22.0012168 },
    { lat: 48.6863755, lng: 22.0012397 },
    { lat: 48.6898467, lng: 22.0022814 },
    { lat: 48.6908345, lng: 22.00252 },
    { lat: 48.6913663, lng: 22.0024622 },
    { lat: 48.6917754, lng: 22.0022646 },
    { lat: 48.6921584, lng: 22.0019616 },
    { lat: 48.6934088, lng: 22.0004622 },
    { lat: 48.6952502, lng: 21.9982535 },
    { lat: 48.6956128, lng: 21.9980438 },
    { lat: 48.6957842, lng: 21.9980175 },
    { lat: 48.6961151, lng: 21.9981097 },
    { lat: 48.6965957, lng: 21.9983962 },
    { lat: 48.6965173, lng: 21.9967537 },
    { lat: 48.6966297, lng: 21.9962934 },
    { lat: 48.6961468, lng: 21.9950463 },
    { lat: 48.6991601, lng: 21.9924626 },
    { lat: 48.7004751, lng: 21.9905364 },
    { lat: 48.7016419, lng: 21.9889906 },
    { lat: 48.7034272, lng: 21.9875763 },
    { lat: 48.705374, lng: 21.9887396 },
    { lat: 48.7054796, lng: 21.9885129 },
    { lat: 48.7076194, lng: 21.990299 },
    { lat: 48.7082505, lng: 21.9886053 },
    { lat: 48.708384, lng: 21.9884059 },
    { lat: 48.7093045, lng: 21.9876839 },
    { lat: 48.7103093, lng: 21.9858209 },
    { lat: 48.7107265, lng: 21.98554 },
    { lat: 48.7113697, lng: 21.9853072 },
    { lat: 48.7129639, lng: 21.9849922 },
    { lat: 48.7165524, lng: 21.9839862 },
    { lat: 48.719829, lng: 21.9833354 },
    { lat: 48.7235076, lng: 21.9814227 },
    { lat: 48.7245173, lng: 21.9809384 },
    { lat: 48.7258739, lng: 21.98039 },
    { lat: 48.7255756, lng: 21.9791034 },
    { lat: 48.7254448, lng: 21.9782753 },
    { lat: 48.7255307, lng: 21.9782394 },
  ],
  Oborín: [
    { lat: 48.533625, lng: 21.83562 },
    { lat: 48.533617, lng: 21.835478 },
    { lat: 48.533608, lng: 21.835424 },
    { lat: 48.533583, lng: 21.835266 },
    { lat: 48.533578, lng: 21.835237 },
    { lat: 48.53357, lng: 21.835209 },
    { lat: 48.533536, lng: 21.835089 },
    { lat: 48.533465, lng: 21.834936 },
    { lat: 48.533356, lng: 21.834786 },
    { lat: 48.533324, lng: 21.834742 },
    { lat: 48.533168, lng: 21.834654 },
    { lat: 48.533112, lng: 21.834652 },
    { lat: 48.532992, lng: 21.834649 },
    { lat: 48.532888, lng: 21.834646 },
    { lat: 48.532567, lng: 21.83474 },
    { lat: 48.532429, lng: 21.834812 },
    { lat: 48.532424, lng: 21.834816 },
    { lat: 48.532336, lng: 21.834887 },
    { lat: 48.532326, lng: 21.834898 },
    { lat: 48.532209, lng: 21.835026 },
    { lat: 48.531848, lng: 21.835424 },
    { lat: 48.531606, lng: 21.835698 },
    { lat: 48.531463, lng: 21.835814 },
    { lat: 48.531434, lng: 21.835838 },
    { lat: 48.531406, lng: 21.835861 },
    { lat: 48.531169, lng: 21.836039 },
    { lat: 48.530795, lng: 21.836184 },
    { lat: 48.530708, lng: 21.836179 },
    { lat: 48.530606, lng: 21.836174 },
    { lat: 48.53049, lng: 21.836143 },
    { lat: 48.530376, lng: 21.836107 },
    { lat: 48.530273, lng: 21.836002 },
    { lat: 48.530245, lng: 21.835975 },
    { lat: 48.530152, lng: 21.83588 },
    { lat: 48.530115, lng: 21.835842 },
    { lat: 48.529962, lng: 21.835507 },
    { lat: 48.529877, lng: 21.834988 },
    { lat: 48.529916, lng: 21.834573 },
    { lat: 48.52998, lng: 21.834298 },
    { lat: 48.529981, lng: 21.834293 },
    { lat: 48.529985, lng: 21.834282 },
    { lat: 48.530126, lng: 21.833894 },
    { lat: 48.530213, lng: 21.833719 },
    { lat: 48.530217, lng: 21.83371 },
    { lat: 48.530229, lng: 21.833694 },
    { lat: 48.530589, lng: 21.833217 },
    { lat: 48.530709, lng: 21.833091 },
    { lat: 48.530714, lng: 21.833086 },
    { lat: 48.531268, lng: 21.83251 },
    { lat: 48.531323, lng: 21.832453 },
    { lat: 48.531699, lng: 21.832007 },
    { lat: 48.531795, lng: 21.831827 },
    { lat: 48.531806, lng: 21.831803 },
    { lat: 48.531946, lng: 21.831493 },
    { lat: 48.531949, lng: 21.831481 },
    { lat: 48.532002, lng: 21.831252 },
    { lat: 48.532056, lng: 21.830898 },
    { lat: 48.532061, lng: 21.830545 },
    { lat: 48.532051, lng: 21.830351 },
    { lat: 48.53205, lng: 21.830348 },
    { lat: 48.532024, lng: 21.830202 },
    { lat: 48.531981, lng: 21.830091 },
    { lat: 48.531957, lng: 21.830061 },
    { lat: 48.531946, lng: 21.830049 },
    { lat: 48.531925, lng: 21.830024 },
    { lat: 48.531878, lng: 21.829986 },
    { lat: 48.531792, lng: 21.829919 },
    { lat: 48.531738, lng: 21.8299 },
    { lat: 48.531687, lng: 21.829883 },
    { lat: 48.531599, lng: 21.829885 },
    { lat: 48.531325, lng: 21.829941 },
    { lat: 48.5312, lng: 21.829978 },
    { lat: 48.531191, lng: 21.82998 },
    { lat: 48.530915, lng: 21.830061 },
    { lat: 48.530912, lng: 21.830063 },
    { lat: 48.530799, lng: 21.830114 },
    { lat: 48.530136, lng: 21.830587 },
    { lat: 48.52959, lng: 21.831073 },
    { lat: 48.529573, lng: 21.831088 },
    { lat: 48.529112, lng: 21.831521 },
    { lat: 48.528726, lng: 21.831807 },
    { lat: 48.528725, lng: 21.831808 },
    { lat: 48.5284, lng: 21.832031 },
    { lat: 48.528397, lng: 21.832032 },
    { lat: 48.52806, lng: 21.832093 },
    { lat: 48.527915, lng: 21.832073 },
    { lat: 48.52776, lng: 21.832032 },
    { lat: 48.527606, lng: 21.831954 },
    { lat: 48.527588, lng: 21.831945 },
    { lat: 48.527335, lng: 21.831699 },
    { lat: 48.527255, lng: 21.831606 },
    { lat: 48.527183, lng: 21.831521 },
    { lat: 48.527117, lng: 21.831444 },
    { lat: 48.526826, lng: 21.830994 },
    { lat: 48.526757, lng: 21.830888 },
    { lat: 48.526208, lng: 21.829929 },
    { lat: 48.525966, lng: 21.829478 },
    { lat: 48.525778, lng: 21.829124 },
    { lat: 48.525607, lng: 21.828804 },
    { lat: 48.525501, lng: 21.828584 },
    { lat: 48.525419, lng: 21.828413 },
    { lat: 48.525029, lng: 21.827539 },
    { lat: 48.52469, lng: 21.826806 },
    { lat: 48.524635, lng: 21.826688 },
    { lat: 48.524487, lng: 21.82637 },
    { lat: 48.524108, lng: 21.82553 },
    { lat: 48.523627, lng: 21.824449 },
    { lat: 48.523623, lng: 21.824442 },
    { lat: 48.523339, lng: 21.82382 },
    { lat: 48.5233, lng: 21.823733 },
    { lat: 48.52325, lng: 21.823635 },
    { lat: 48.522937, lng: 21.823025 },
    { lat: 48.522844, lng: 21.822892 },
    { lat: 48.522779, lng: 21.822844 },
    { lat: 48.52275, lng: 21.822823 },
    { lat: 48.52266, lng: 21.822809 },
    { lat: 48.522526, lng: 21.822844 },
    { lat: 48.522461, lng: 21.822876 },
    { lat: 48.522417, lng: 21.822897 },
    { lat: 48.522282, lng: 21.823036 },
    { lat: 48.522124, lng: 21.823281 },
    { lat: 48.522076, lng: 21.823516 },
    { lat: 48.522088, lng: 21.823798 },
    { lat: 48.522331, lng: 21.825028 },
    { lat: 48.522486, lng: 21.825608 },
    { lat: 48.522779, lng: 21.826684 },
    { lat: 48.523128, lng: 21.827567 },
    { lat: 48.523369, lng: 21.828038 },
    { lat: 48.523783, lng: 21.828655 },
    { lat: 48.523962, lng: 21.828956 },
    { lat: 48.524219, lng: 21.829428 },
    { lat: 48.524441, lng: 21.829836 },
    { lat: 48.524445, lng: 21.82985 },
    { lat: 48.524568, lng: 21.830235 },
    { lat: 48.524598, lng: 21.830398 },
    { lat: 48.524667, lng: 21.83077 },
    { lat: 48.524677, lng: 21.830952 },
    { lat: 48.524707, lng: 21.831445 },
    { lat: 48.524717, lng: 21.831607 },
    { lat: 48.524658, lng: 21.831767 },
    { lat: 48.524639, lng: 21.831818 },
    { lat: 48.524637, lng: 21.831822 },
    { lat: 48.524539, lng: 21.831992 },
    { lat: 48.524509, lng: 21.832042 },
    { lat: 48.524393, lng: 21.832172 },
    { lat: 48.524345, lng: 21.832209 },
    { lat: 48.524342, lng: 21.832211 },
    { lat: 48.524289, lng: 21.832252 },
    { lat: 48.524139, lng: 21.832307 },
    { lat: 48.524088, lng: 21.832314 },
    { lat: 48.524067, lng: 21.832318 },
    { lat: 48.524054, lng: 21.83232 },
    { lat: 48.523999, lng: 21.832326 },
    { lat: 48.523976, lng: 21.832329 },
    { lat: 48.523965, lng: 21.83233 },
    { lat: 48.523678, lng: 21.832294 },
    { lat: 48.523665, lng: 21.832292 },
    { lat: 48.523647, lng: 21.83229 },
    { lat: 48.523436, lng: 21.832198 },
    { lat: 48.523433, lng: 21.832196 },
    { lat: 48.523304, lng: 21.832134 },
    { lat: 48.522775, lng: 21.83188 },
    { lat: 48.52277, lng: 21.831876 },
    { lat: 48.522528, lng: 21.831704 },
    { lat: 48.522385, lng: 21.831586 },
    { lat: 48.522377, lng: 21.831578 },
    { lat: 48.522247, lng: 21.831471 },
    { lat: 48.52213, lng: 21.831374 },
    { lat: 48.521816, lng: 21.831104 },
    { lat: 48.521529, lng: 21.830863 },
    { lat: 48.521094, lng: 21.830489 },
    { lat: 48.521029, lng: 21.830433 },
    { lat: 48.520953, lng: 21.83037 },
    { lat: 48.520882, lng: 21.83031 },
    { lat: 48.520491, lng: 21.829924 },
    { lat: 48.520452, lng: 21.829834 },
    { lat: 48.52045, lng: 21.829832 },
    { lat: 48.520415, lng: 21.829749 },
    { lat: 48.520371, lng: 21.82965 },
    { lat: 48.520341, lng: 21.829562 },
    { lat: 48.520307, lng: 21.829464 },
    { lat: 48.520283, lng: 21.829396 },
    { lat: 48.520283, lng: 21.829395 },
    { lat: 48.52028, lng: 21.829389 },
    { lat: 48.520256, lng: 21.829318 },
    { lat: 48.520242, lng: 21.829132 },
    { lat: 48.520244, lng: 21.829118 },
    { lat: 48.520292, lng: 21.828878 },
    { lat: 48.520373, lng: 21.828637 },
    { lat: 48.52038, lng: 21.828627 },
    { lat: 48.52041, lng: 21.828568 },
    { lat: 48.520705, lng: 21.828052 },
    { lat: 48.520727, lng: 21.828012 },
    { lat: 48.521044, lng: 21.827513 },
    { lat: 48.52106, lng: 21.827485 },
    { lat: 48.521149, lng: 21.827328 },
    { lat: 48.521316, lng: 21.827006 },
    { lat: 48.52139, lng: 21.826843 },
    { lat: 48.521395, lng: 21.826831 },
    { lat: 48.521398, lng: 21.826815 },
    { lat: 48.521504, lng: 21.826278 },
    { lat: 48.521503, lng: 21.82601 },
    { lat: 48.521482, lng: 21.825777 },
    { lat: 48.521355, lng: 21.825385 },
    { lat: 48.521308, lng: 21.825295 },
    { lat: 48.521286, lng: 21.825254 },
    { lat: 48.52108, lng: 21.825018 },
    { lat: 48.520792, lng: 21.824707 },
    { lat: 48.520651, lng: 21.824432 },
    { lat: 48.520306, lng: 21.823801 },
    { lat: 48.520202, lng: 21.823609 },
    { lat: 48.519918, lng: 21.823064 },
    { lat: 48.519914, lng: 21.823057 },
    { lat: 48.519725, lng: 21.822689 },
    { lat: 48.5194, lng: 21.8222 },
    { lat: 48.519041, lng: 21.821683 },
    { lat: 48.518471, lng: 21.821056 },
    { lat: 48.518287, lng: 21.82091 },
    { lat: 48.518286, lng: 21.820909 },
    { lat: 48.518013, lng: 21.820797 },
    { lat: 48.517897, lng: 21.820777 },
    { lat: 48.517765, lng: 21.820803 },
    { lat: 48.517662, lng: 21.820864 },
    { lat: 48.517354, lng: 21.821161 },
    { lat: 48.517004, lng: 21.821294 },
    { lat: 48.516919, lng: 21.821309 },
    { lat: 48.516842, lng: 21.821271 },
    { lat: 48.516764, lng: 21.821207 },
    { lat: 48.516687, lng: 21.821034 },
    { lat: 48.51667, lng: 21.82086 },
    { lat: 48.516666, lng: 21.820736 },
    { lat: 48.516693, lng: 21.820646 },
    { lat: 48.516697, lng: 21.820634 },
    { lat: 48.516713, lng: 21.820599 },
    { lat: 48.51675, lng: 21.82052 },
    { lat: 48.516821, lng: 21.820372 },
    { lat: 48.516837, lng: 21.820337 },
    { lat: 48.516842, lng: 21.820331 },
    { lat: 48.517277, lng: 21.819882 },
    { lat: 48.517611, lng: 21.819627 },
    { lat: 48.518055, lng: 21.819299 },
    { lat: 48.518261, lng: 21.819133 },
    { lat: 48.518455, lng: 21.818955 },
    { lat: 48.518457, lng: 21.818954 },
    { lat: 48.5185, lng: 21.818895 },
    { lat: 48.518642, lng: 21.818677 },
    { lat: 48.518764, lng: 21.818324 },
    { lat: 48.518821, lng: 21.818056 },
    { lat: 48.518833, lng: 21.817935 },
    { lat: 48.518845, lng: 21.817807 },
    { lat: 48.518837, lng: 21.817611 },
    { lat: 48.518834, lng: 21.817549 },
    { lat: 48.518759, lng: 21.817139 },
    { lat: 48.518544, lng: 21.816658 },
    { lat: 48.518543, lng: 21.816656 },
    { lat: 48.518491, lng: 21.816569 },
    { lat: 48.518263, lng: 21.816184 },
    { lat: 48.518204, lng: 21.816084 },
    { lat: 48.518153, lng: 21.816004 },
    { lat: 48.517953, lng: 21.815685 },
    { lat: 48.517858, lng: 21.815526 },
    { lat: 48.517751, lng: 21.815178 },
    { lat: 48.517744, lng: 21.81515 },
    { lat: 48.517695, lng: 21.814994 },
    { lat: 48.517695, lng: 21.81499 },
    { lat: 48.517682, lng: 21.814801 },
    { lat: 48.517696, lng: 21.814651 },
    { lat: 48.517767, lng: 21.814482 },
    { lat: 48.517957, lng: 21.814179 },
    { lat: 48.518213, lng: 21.813952 },
    { lat: 48.518326, lng: 21.813851 },
    { lat: 48.518328, lng: 21.81385 },
    { lat: 48.518564, lng: 21.813677 },
    { lat: 48.518684, lng: 21.81357 },
    { lat: 48.518685, lng: 21.81357 },
    { lat: 48.518732, lng: 21.813455 },
    { lat: 48.518733, lng: 21.813449 },
    { lat: 48.518756, lng: 21.813292 },
    { lat: 48.518779, lng: 21.81311 },
    { lat: 48.518779, lng: 21.813098 },
    { lat: 48.518779, lng: 21.813096 },
    { lat: 48.518746, lng: 21.812852 },
    { lat: 48.518686, lng: 21.81264 },
    { lat: 48.518549, lng: 21.812421 },
    { lat: 48.518444, lng: 21.812325 },
    { lat: 48.518234, lng: 21.812254 },
    { lat: 48.518232, lng: 21.812254 },
    { lat: 48.517777, lng: 21.812129 },
    { lat: 48.517776, lng: 21.812129 },
    { lat: 48.517648, lng: 21.812108 },
    { lat: 48.517376, lng: 21.812088 },
    { lat: 48.517374, lng: 21.812088 },
    { lat: 48.517182, lng: 21.812048 },
    { lat: 48.516918, lng: 21.811957 },
    { lat: 48.51675, lng: 21.811857 },
    { lat: 48.516539, lng: 21.811664 },
    { lat: 48.516407, lng: 21.811544 },
    { lat: 48.516147, lng: 21.811112 },
    { lat: 48.516077, lng: 21.810976 },
    { lat: 48.516014, lng: 21.810852 },
    { lat: 48.515954, lng: 21.810634 },
    { lat: 48.515951, lng: 21.810628 },
    { lat: 48.515919, lng: 21.810527 },
    { lat: 48.51588, lng: 21.810409 },
    { lat: 48.515875, lng: 21.810396 },
    { lat: 48.515805, lng: 21.810187 },
    { lat: 48.515771, lng: 21.810086 },
    { lat: 48.515675, lng: 21.809745 },
    { lat: 48.515653, lng: 21.809666 },
    { lat: 48.515627, lng: 21.809577 },
    { lat: 48.515507, lng: 21.809228 },
    { lat: 48.515428, lng: 21.809102 },
    { lat: 48.515291, lng: 21.808888 },
    { lat: 48.515191, lng: 21.808747 },
    { lat: 48.515118, lng: 21.808643 },
    { lat: 48.515047, lng: 21.80854 },
    { lat: 48.515042, lng: 21.808326 },
    { lat: 48.514776, lng: 21.808328 },
    { lat: 48.51459, lng: 21.808321 },
    { lat: 48.514401, lng: 21.808313 },
    { lat: 48.514195, lng: 21.808314 },
    { lat: 48.513934, lng: 21.808327 },
    { lat: 48.51345, lng: 21.808376 },
    { lat: 48.513303, lng: 21.808378 },
    { lat: 48.51293, lng: 21.808388 },
    { lat: 48.512859, lng: 21.808392 },
    { lat: 48.512518, lng: 21.808414 },
    { lat: 48.512381, lng: 21.808422 },
    { lat: 48.51218, lng: 21.808437 },
    { lat: 48.512075, lng: 21.808446 },
    { lat: 48.51199, lng: 21.808469 },
    { lat: 48.511938, lng: 21.808483 },
    { lat: 48.511893, lng: 21.808496 },
    { lat: 48.511742, lng: 21.808551 },
    { lat: 48.511516, lng: 21.808633 },
    { lat: 48.510901, lng: 21.808897 },
    { lat: 48.510385, lng: 21.809137 },
    { lat: 48.509867, lng: 21.809388 },
    { lat: 48.509862, lng: 21.809391 },
    { lat: 48.509395, lng: 21.809615 },
    { lat: 48.50939, lng: 21.809617 },
    { lat: 48.509183, lng: 21.809717 },
    { lat: 48.509084, lng: 21.809763 },
    { lat: 48.509063, lng: 21.809772 },
    { lat: 48.508774, lng: 21.809906 },
    { lat: 48.508715, lng: 21.809931 },
    { lat: 48.508699, lng: 21.809938 },
    { lat: 48.508695, lng: 21.809939 },
    { lat: 48.508649, lng: 21.809964 },
    { lat: 48.508641, lng: 21.809968 },
    { lat: 48.508626, lng: 21.809976 },
    { lat: 48.50862, lng: 21.80998 },
    { lat: 48.508386, lng: 21.810093 },
    { lat: 48.508372, lng: 21.810043 },
    { lat: 48.50818, lng: 21.810187 },
    { lat: 48.508049, lng: 21.810319 },
    { lat: 48.507973, lng: 21.810396 },
    { lat: 48.507906, lng: 21.810463 },
    { lat: 48.507798, lng: 21.810573 },
    { lat: 48.507704, lng: 21.810668 },
    { lat: 48.507534, lng: 21.810842 },
    { lat: 48.507263, lng: 21.811119 },
    { lat: 48.507028, lng: 21.811373 },
    { lat: 48.506926, lng: 21.811489 },
    { lat: 48.506858, lng: 21.811568 },
    { lat: 48.506765, lng: 21.811658 },
    { lat: 48.506686, lng: 21.811728 },
    { lat: 48.50637, lng: 21.812055 },
    { lat: 48.506345, lng: 21.8121 },
    { lat: 48.505877, lng: 21.812583 },
    { lat: 48.50566, lng: 21.812811 },
    { lat: 48.505542, lng: 21.812936 },
    { lat: 48.505461, lng: 21.813025 },
    { lat: 48.505178, lng: 21.813246 },
    { lat: 48.504987, lng: 21.8134 },
    { lat: 48.504855, lng: 21.813501 },
    { lat: 48.504713, lng: 21.813572 },
    { lat: 48.504511, lng: 21.813749 },
    { lat: 48.50451, lng: 21.81375 },
    { lat: 48.504192, lng: 21.813977 },
    { lat: 48.503669, lng: 21.814399 },
    { lat: 48.502978, lng: 21.814996 },
    { lat: 48.50297, lng: 21.815003 },
    { lat: 48.502966, lng: 21.815007 },
    { lat: 48.502722, lng: 21.815226 },
    { lat: 48.502598, lng: 21.815337 },
    { lat: 48.502437, lng: 21.815495 },
    { lat: 48.502368, lng: 21.815563 },
    { lat: 48.502052, lng: 21.815873 },
    { lat: 48.501122, lng: 21.816833 },
    { lat: 48.500968, lng: 21.816988 },
    { lat: 48.50082, lng: 21.817138 },
    { lat: 48.500644, lng: 21.817325 },
    { lat: 48.500581, lng: 21.817393 },
    { lat: 48.500408, lng: 21.81754 },
    { lat: 48.500389, lng: 21.817556 },
    { lat: 48.500049, lng: 21.817811 },
    { lat: 48.499837, lng: 21.817977 },
    { lat: 48.499637, lng: 21.818133 },
    { lat: 48.499583, lng: 21.818189 },
    { lat: 48.499252, lng: 21.818529 },
    { lat: 48.499206, lng: 21.818577 },
    { lat: 48.499137, lng: 21.818656 },
    { lat: 48.498933, lng: 21.818961 },
    { lat: 48.498825, lng: 21.819251 },
    { lat: 48.498671, lng: 21.819664 },
    { lat: 48.498622, lng: 21.819796 },
    { lat: 48.498559, lng: 21.819956 },
    { lat: 48.498422, lng: 21.820308 },
    { lat: 48.498422, lng: 21.820312 },
    { lat: 48.498404, lng: 21.820428 },
    { lat: 48.498391, lng: 21.820591 },
    { lat: 48.498311, lng: 21.821194 },
    { lat: 48.498096, lng: 21.822204 },
    { lat: 48.498063, lng: 21.822351 },
    { lat: 48.497971, lng: 21.822552 },
    { lat: 48.497937, lng: 21.822627 },
    { lat: 48.497619, lng: 21.822986 },
    { lat: 48.497485, lng: 21.823112 },
    { lat: 48.4974, lng: 21.823191 },
    { lat: 48.49723, lng: 21.823353 },
    { lat: 48.496643, lng: 21.823417 },
    { lat: 48.496496, lng: 21.823434 },
    { lat: 48.496242, lng: 21.823556 },
    { lat: 48.495694, lng: 21.823732 },
    { lat: 48.495684, lng: 21.823735 },
    { lat: 48.495397, lng: 21.823808 },
    { lat: 48.495061, lng: 21.824004 },
    { lat: 48.49487, lng: 21.824104 },
    { lat: 48.494455, lng: 21.824404 },
    { lat: 48.494134, lng: 21.824616 },
    { lat: 48.493945, lng: 21.824753 },
    { lat: 48.493801, lng: 21.824858 },
    { lat: 48.493546, lng: 21.825064 },
    { lat: 48.493486, lng: 21.825112 },
    { lat: 48.493306, lng: 21.825253 },
    { lat: 48.493228, lng: 21.825315 },
    { lat: 48.493162, lng: 21.825366 },
    { lat: 48.49262, lng: 21.825791 },
    { lat: 48.49243, lng: 21.825935 },
    { lat: 48.492278, lng: 21.826051 },
    { lat: 48.491858, lng: 21.826322 },
    { lat: 48.491574, lng: 21.826502 },
    { lat: 48.491232, lng: 21.826672 },
    { lat: 48.491077, lng: 21.826733 },
    { lat: 48.491006, lng: 21.826761 },
    { lat: 48.490529, lng: 21.826853 },
    { lat: 48.49045, lng: 21.826854 },
    { lat: 48.490427, lng: 21.826854 },
    { lat: 48.490222, lng: 21.826822 },
    { lat: 48.489891, lng: 21.826678 },
    { lat: 48.489674, lng: 21.826507 },
    { lat: 48.489646, lng: 21.826485 },
    { lat: 48.489621, lng: 21.82645 },
    { lat: 48.48938, lng: 21.826104 },
    { lat: 48.489313, lng: 21.825956 },
    { lat: 48.489154, lng: 21.825599 },
    { lat: 48.489143, lng: 21.825577 },
    { lat: 48.489046, lng: 21.825361 },
    { lat: 48.48904, lng: 21.825349 },
    { lat: 48.488462, lng: 21.823859 },
    { lat: 48.488355, lng: 21.823581 },
    { lat: 48.48832, lng: 21.823492 },
    { lat: 48.488227, lng: 21.823251 },
    { lat: 48.487843, lng: 21.822431 },
    { lat: 48.487807, lng: 21.822354 },
    { lat: 48.487559, lng: 21.821885 },
    { lat: 48.487517, lng: 21.821809 },
    { lat: 48.487101, lng: 21.82128 },
    { lat: 48.486775, lng: 21.821004 },
    { lat: 48.486751, lng: 21.820983 },
    { lat: 48.486012, lng: 21.820761 },
    { lat: 48.485764, lng: 21.820713 },
    { lat: 48.485535, lng: 21.820692 },
    { lat: 48.485451, lng: 21.820684 },
    { lat: 48.485339, lng: 21.820681 },
    { lat: 48.485233, lng: 21.820699 },
    { lat: 48.485086, lng: 21.820726 },
    { lat: 48.484485, lng: 21.820838 },
    { lat: 48.484267, lng: 21.820954 },
    { lat: 48.4838, lng: 21.821202 },
    { lat: 48.483152, lng: 21.821733 },
    { lat: 48.482743, lng: 21.822161 },
    { lat: 48.48242, lng: 21.822499 },
    { lat: 48.482097, lng: 21.822701 },
    { lat: 48.481998, lng: 21.822763 },
    { lat: 48.481736, lng: 21.822922 },
    { lat: 48.481631, lng: 21.822986 },
    { lat: 48.48135, lng: 21.823117 },
    { lat: 48.481286, lng: 21.823132 },
    { lat: 48.480896, lng: 21.823226 },
    { lat: 48.480683, lng: 21.823167 },
    { lat: 48.480381, lng: 21.82303 },
    { lat: 48.480381, lng: 21.823029 },
    { lat: 48.480332, lng: 21.823001 },
    { lat: 48.480134, lng: 21.822818 },
    { lat: 48.479749, lng: 21.822396 },
    { lat: 48.479712, lng: 21.822352 },
    { lat: 48.47954, lng: 21.822149 },
    { lat: 48.479283, lng: 21.821824 },
    { lat: 48.479261, lng: 21.821796 },
    { lat: 48.479189, lng: 21.821689 },
    { lat: 48.478982, lng: 21.821331 },
    { lat: 48.478889, lng: 21.821162 },
    { lat: 48.478678, lng: 21.820706 },
    { lat: 48.478673, lng: 21.820696 },
    { lat: 48.478592, lng: 21.820504 },
    { lat: 48.478513, lng: 21.820301 },
    { lat: 48.478512, lng: 21.820298 },
    { lat: 48.478501, lng: 21.820268 },
    { lat: 48.478272, lng: 21.819643 },
    { lat: 48.478225, lng: 21.819507 },
    { lat: 48.478081, lng: 21.819129 },
    { lat: 48.477907, lng: 21.8187 },
    { lat: 48.477892, lng: 21.818663 },
    { lat: 48.477769, lng: 21.818403 },
    { lat: 48.477644, lng: 21.818151 },
    { lat: 48.477433, lng: 21.817806 },
    { lat: 48.477264, lng: 21.817561 },
    { lat: 48.477233, lng: 21.817517 },
    { lat: 48.477122, lng: 21.817373 },
    { lat: 48.477028, lng: 21.817254 },
    { lat: 48.476949, lng: 21.817187 },
    { lat: 48.476886, lng: 21.817132 },
    { lat: 48.476719, lng: 21.81699 },
    { lat: 48.476609, lng: 21.816926 },
    { lat: 48.476391, lng: 21.816797 },
    { lat: 48.476221, lng: 21.816696 },
    { lat: 48.476219, lng: 21.816695 },
    { lat: 48.476064, lng: 21.816636 },
    { lat: 48.475891, lng: 21.816584 },
    { lat: 48.475091, lng: 21.81664 },
    { lat: 48.474995, lng: 21.816647 },
    { lat: 48.474648, lng: 21.816729 },
    { lat: 48.474617, lng: 21.816767 },
    { lat: 48.474444, lng: 21.816979 },
    { lat: 48.474355, lng: 21.817087 },
    { lat: 48.474249, lng: 21.817265 },
    { lat: 48.474245, lng: 21.81727 },
    { lat: 48.474202, lng: 21.817343 },
    { lat: 48.474175, lng: 21.817387 },
    { lat: 48.474076, lng: 21.817552 },
    { lat: 48.473869, lng: 21.817897 },
    { lat: 48.473867, lng: 21.817903 },
    { lat: 48.473795, lng: 21.81813 },
    { lat: 48.473704, lng: 21.818574 },
    { lat: 48.473694, lng: 21.818735 },
    { lat: 48.473716, lng: 21.819236 },
    { lat: 48.473716, lng: 21.819624 },
    { lat: 48.473714, lng: 21.820189 },
    { lat: 48.473713, lng: 21.820243 },
    { lat: 48.473713, lng: 21.820286 },
    { lat: 48.473715, lng: 21.820641 },
    { lat: 48.473714, lng: 21.821065 },
    { lat: 48.473714, lng: 21.821454 },
    { lat: 48.473708, lng: 21.821619 },
    { lat: 48.473709, lng: 21.821877 },
    { lat: 48.473688, lng: 21.822348 },
    { lat: 48.473683, lng: 21.822617 },
    { lat: 48.473673, lng: 21.823019 },
    { lat: 48.473673, lng: 21.823033 },
    { lat: 48.473668, lng: 21.823115 },
    { lat: 48.473624, lng: 21.823864 },
    { lat: 48.473607, lng: 21.8241 },
    { lat: 48.473591, lng: 21.824317 },
    { lat: 48.473591, lng: 21.824319 },
    { lat: 48.473518, lng: 21.824743 },
    { lat: 48.473442, lng: 21.825165 },
    { lat: 48.473277, lng: 21.825598 },
    { lat: 48.47313, lng: 21.825858 },
    { lat: 48.473129, lng: 21.82586 },
    { lat: 48.472944, lng: 21.826102 },
    { lat: 48.472741, lng: 21.826346 },
    { lat: 48.472682, lng: 21.826379 },
    { lat: 48.472474, lng: 21.826461 },
    { lat: 48.472304, lng: 21.826448 },
    { lat: 48.472233, lng: 21.826443 },
    { lat: 48.471975, lng: 21.826237 },
    { lat: 48.471951, lng: 21.826199 },
    { lat: 48.471815, lng: 21.825987 },
    { lat: 48.471712, lng: 21.825794 },
    { lat: 48.471682, lng: 21.825738 },
    { lat: 48.471681, lng: 21.825734 },
    { lat: 48.471653, lng: 21.825646 },
    { lat: 48.471612, lng: 21.825547 },
    { lat: 48.471519, lng: 21.825141 },
    { lat: 48.471518, lng: 21.825125 },
    { lat: 48.471496, lng: 21.824942 },
    { lat: 48.471486, lng: 21.82486 },
    { lat: 48.471471, lng: 21.824736 },
    { lat: 48.471465, lng: 21.824671 },
    { lat: 48.471456, lng: 21.824278 },
    { lat: 48.47145, lng: 21.82402 },
    { lat: 48.471444, lng: 21.823531 },
    { lat: 48.471443, lng: 21.823446 },
    { lat: 48.471443, lng: 21.823445 },
    { lat: 48.471441, lng: 21.82323 },
    { lat: 48.471433, lng: 21.823158 },
    { lat: 48.471445, lng: 21.822805 },
    { lat: 48.471477, lng: 21.822443 },
    { lat: 48.471524, lng: 21.822097 },
    { lat: 48.471644, lng: 21.821686 },
    { lat: 48.471837, lng: 21.821151 },
    { lat: 48.47199, lng: 21.820735 },
    { lat: 48.472015, lng: 21.820689 },
    { lat: 48.472143, lng: 21.820447 },
    { lat: 48.472223, lng: 21.820298 },
    { lat: 48.47236, lng: 21.819832 },
    { lat: 48.472422, lng: 21.81963 },
    { lat: 48.472476, lng: 21.81945 },
    { lat: 48.4726, lng: 21.818914 },
    { lat: 48.472604, lng: 21.818833 },
    { lat: 48.472603, lng: 21.8188 },
    { lat: 48.472597, lng: 21.818566 },
    { lat: 48.472593, lng: 21.818427 },
    { lat: 48.472591, lng: 21.818332 },
    { lat: 48.472561, lng: 21.817994 },
    { lat: 48.47253, lng: 21.817768 },
    { lat: 48.472497, lng: 21.817484 },
    { lat: 48.472434, lng: 21.817265 },
    { lat: 48.472425, lng: 21.817239 },
    { lat: 48.472402, lng: 21.817173 },
    { lat: 48.472386, lng: 21.817124 },
    { lat: 48.472348, lng: 21.817027 },
    { lat: 48.472155, lng: 21.816663 },
    { lat: 48.472148, lng: 21.816648 },
    { lat: 48.471982, lng: 21.816381 },
    { lat: 48.471866, lng: 21.816193 },
    { lat: 48.471555, lng: 21.815611 },
    { lat: 48.471374, lng: 21.815122 },
    { lat: 48.47135, lng: 21.815023 },
    { lat: 48.471337, lng: 21.814972 },
    { lat: 48.471319, lng: 21.814839 },
    { lat: 48.471323, lng: 21.814758 },
    { lat: 48.471373, lng: 21.814391 },
    { lat: 48.471402, lng: 21.814305 },
    { lat: 48.471611, lng: 21.813945 },
    { lat: 48.471669, lng: 21.813903 },
    { lat: 48.471762, lng: 21.813836 },
    { lat: 48.471763, lng: 21.813836 },
    { lat: 48.471827, lng: 21.813811 },
    { lat: 48.472049, lng: 21.81377 },
    { lat: 48.472051, lng: 21.813769 },
    { lat: 48.472286, lng: 21.813808 },
    { lat: 48.472463, lng: 21.813885 },
    { lat: 48.472664, lng: 21.813972 },
    { lat: 48.4729, lng: 21.814079 },
    { lat: 48.472946, lng: 21.814108 },
    { lat: 48.473136, lng: 21.814226 },
    { lat: 48.473141, lng: 21.814229 },
    { lat: 48.473212, lng: 21.814288 },
    { lat: 48.473757, lng: 21.814665 },
    { lat: 48.473811, lng: 21.8147 },
    { lat: 48.473953, lng: 21.814794 },
    { lat: 48.474128, lng: 21.814851 },
    { lat: 48.474526, lng: 21.814888 },
    { lat: 48.474673, lng: 21.814836 },
    { lat: 48.474718, lng: 21.814826 },
    { lat: 48.474792, lng: 21.814811 },
    { lat: 48.474808, lng: 21.814789 },
    { lat: 48.474956, lng: 21.814591 },
    { lat: 48.47509, lng: 21.814375 },
    { lat: 48.475102, lng: 21.814355 },
    { lat: 48.475215, lng: 21.814029 },
    { lat: 48.475216, lng: 21.814021 },
    { lat: 48.475261, lng: 21.813731 },
    { lat: 48.475259, lng: 21.813405 },
    { lat: 48.475258, lng: 21.813313 },
    { lat: 48.475244, lng: 21.813138 },
    { lat: 48.475193, lng: 21.812978 },
    { lat: 48.475184, lng: 21.812952 },
    { lat: 48.475119, lng: 21.812753 },
    { lat: 48.475081, lng: 21.812639 },
    { lat: 48.475054, lng: 21.812577 },
    { lat: 48.474915, lng: 21.812295 },
    { lat: 48.474863, lng: 21.812229 },
    { lat: 48.474644, lng: 21.811953 },
    { lat: 48.474573, lng: 21.811864 },
    { lat: 48.47453, lng: 21.811823 },
    { lat: 48.474477, lng: 21.811771 },
    { lat: 48.474476, lng: 21.811771 },
    { lat: 48.474377, lng: 21.811688 },
    { lat: 48.474146, lng: 21.811578 },
    { lat: 48.474061, lng: 21.811547 },
    { lat: 48.47393, lng: 21.811522 },
    { lat: 48.473929, lng: 21.811522 },
    { lat: 48.473796, lng: 21.811516 },
    { lat: 48.473633, lng: 21.811522 },
    { lat: 48.473222, lng: 21.811531 },
    { lat: 48.472987, lng: 21.811536 },
    { lat: 48.47273, lng: 21.811541 },
    { lat: 48.472696, lng: 21.811543 },
    { lat: 48.472563, lng: 21.811553 },
    { lat: 48.472462, lng: 21.811552 },
    { lat: 48.472348, lng: 21.811554 },
    { lat: 48.472223, lng: 21.811556 },
    { lat: 48.472127, lng: 21.811557 },
    { lat: 48.472047, lng: 21.811558 },
    { lat: 48.471682, lng: 21.811564 },
    { lat: 48.47154, lng: 21.811565 },
    { lat: 48.470471, lng: 21.811575 },
    { lat: 48.470451, lng: 21.811569 },
    { lat: 48.47031, lng: 21.811531 },
    { lat: 48.470196, lng: 21.8115 },
    { lat: 48.470137, lng: 21.811484 },
    { lat: 48.469782, lng: 21.811362 },
    { lat: 48.469751, lng: 21.81135 },
    { lat: 48.469673, lng: 21.811322 },
    { lat: 48.469604, lng: 21.811281 },
    { lat: 48.469477, lng: 21.811171 },
    { lat: 48.469302, lng: 21.810991 },
    { lat: 48.469275, lng: 21.810961 },
    { lat: 48.469117, lng: 21.810797 },
    { lat: 48.468893, lng: 21.810565 },
    { lat: 48.468734, lng: 21.810377 },
    { lat: 48.468628, lng: 21.810279 },
    { lat: 48.468519, lng: 21.810153 },
    { lat: 48.468295, lng: 21.809954 },
    { lat: 48.468139, lng: 21.809822 },
    { lat: 48.46806, lng: 21.809759 },
    { lat: 48.467862, lng: 21.809652 },
    { lat: 48.467861, lng: 21.809651 },
    { lat: 48.467722, lng: 21.809586 },
    { lat: 48.467453, lng: 21.809528 },
    { lat: 48.467363, lng: 21.809505 },
    { lat: 48.467271, lng: 21.809518 },
    { lat: 48.467187, lng: 21.809537 },
    { lat: 48.467182, lng: 21.809538 },
    { lat: 48.467138, lng: 21.809556 },
    { lat: 48.467005, lng: 21.809589 },
    { lat: 48.466929, lng: 21.809626 },
    { lat: 48.466853, lng: 21.809675 },
    { lat: 48.466782, lng: 21.809742 },
    { lat: 48.466656, lng: 21.809859 },
    { lat: 48.466455, lng: 21.810185 },
    { lat: 48.466319, lng: 21.810557 },
    { lat: 48.466217, lng: 21.81123 },
    { lat: 48.466202, lng: 21.811701 },
    { lat: 48.46634, lng: 21.812312 },
    { lat: 48.466484, lng: 21.812799 },
    { lat: 48.466682, lng: 21.813392 },
    { lat: 48.466699, lng: 21.813491 },
    { lat: 48.46682, lng: 21.813934 },
    { lat: 48.466994, lng: 21.814359 },
    { lat: 48.46723, lng: 21.814904 },
    { lat: 48.467594, lng: 21.815559 },
    { lat: 48.467828, lng: 21.815989 },
    { lat: 48.46807, lng: 21.816463 },
    { lat: 48.468073, lng: 21.816469 },
    { lat: 48.468132, lng: 21.816618 },
    { lat: 48.4682, lng: 21.81696 },
    { lat: 48.468244, lng: 21.817186 },
    { lat: 48.468256, lng: 21.817348 },
    { lat: 48.468269, lng: 21.817704 },
    { lat: 48.468272, lng: 21.817772 },
    { lat: 48.468241, lng: 21.818069 },
    { lat: 48.468217, lng: 21.818249 },
    { lat: 48.468149, lng: 21.818372 },
    { lat: 48.468094, lng: 21.818527 },
    { lat: 48.468087, lng: 21.818543 },
    { lat: 48.467952, lng: 21.818847 },
    { lat: 48.467856, lng: 21.81888 },
    { lat: 48.467786, lng: 21.81892 },
    { lat: 48.467661, lng: 21.818969 },
    { lat: 48.467439, lng: 21.819064 },
    { lat: 48.466959, lng: 21.819285 },
    { lat: 48.466656, lng: 21.819428 },
    { lat: 48.46626, lng: 21.819626 },
    { lat: 48.466187, lng: 21.820067 },
    { lat: 48.466276, lng: 21.820003 },
    { lat: 48.466423, lng: 21.82072 },
    { lat: 48.466596, lng: 21.821557 },
    { lat: 48.466671, lng: 21.822002 },
    { lat: 48.466732, lng: 21.822649 },
    { lat: 48.4668, lng: 21.823605 },
    { lat: 48.466855, lng: 21.824139 },
    { lat: 48.466951, lng: 21.82491 },
    { lat: 48.46705, lng: 21.825521 },
    { lat: 48.467054, lng: 21.82555 },
    { lat: 48.46711, lng: 21.826008 },
    { lat: 48.467267, lng: 21.827211 },
    { lat: 48.467226, lng: 21.827445 },
    { lat: 48.466998, lng: 21.827689 },
    { lat: 48.466721, lng: 21.827974 },
    { lat: 48.466416, lng: 21.828084 },
    { lat: 48.466066, lng: 21.827929 },
    { lat: 48.465937, lng: 21.827788 },
    { lat: 48.465836, lng: 21.827644 },
    { lat: 48.465549, lng: 21.827032 },
    { lat: 48.465305, lng: 21.826327 },
    { lat: 48.465125, lng: 21.825766 },
    { lat: 48.46474, lng: 21.824494 },
    { lat: 48.464313, lng: 21.823073 },
    { lat: 48.464229, lng: 21.822746 },
    { lat: 48.464126, lng: 21.822599 },
    { lat: 48.464033, lng: 21.822468 },
    { lat: 48.463839, lng: 21.822409 },
    { lat: 48.46375, lng: 21.822456 },
    { lat: 48.463532, lng: 21.822834 },
    { lat: 48.463364, lng: 21.823355 },
    { lat: 48.463333, lng: 21.823689 },
    { lat: 48.463276, lng: 21.824515 },
    { lat: 48.463231, lng: 21.825048 },
    { lat: 48.463065, lng: 21.825805 },
    { lat: 48.462865, lng: 21.826434 },
    { lat: 48.462714, lng: 21.826724 },
    { lat: 48.462261, lng: 21.827022 },
    { lat: 48.461867, lng: 21.827277 },
    { lat: 48.461616, lng: 21.827439 },
    { lat: 48.461596, lng: 21.827449 },
    { lat: 48.460674, lng: 21.827896 },
    { lat: 48.460541, lng: 21.827965 },
    { lat: 48.460516, lng: 21.827994 },
    { lat: 48.460186, lng: 21.82837 },
    { lat: 48.460168, lng: 21.8284 },
    { lat: 48.460088, lng: 21.828527 },
    { lat: 48.459915, lng: 21.828838 },
    { lat: 48.459661, lng: 21.829684 },
    { lat: 48.459613, lng: 21.829852 },
    { lat: 48.45957, lng: 21.830117 },
    { lat: 48.459526, lng: 21.830388 },
    { lat: 48.459485, lng: 21.830985 },
    { lat: 48.459492, lng: 21.83184 },
    { lat: 48.459573, lng: 21.832994 },
    { lat: 48.459611, lng: 21.833923 },
    { lat: 48.45969, lng: 21.83473 },
    { lat: 48.459896, lng: 21.835459 },
    { lat: 48.460201, lng: 21.835853 },
    { lat: 48.460425, lng: 21.835997 },
    { lat: 48.46077, lng: 21.836095 },
    { lat: 48.461098, lng: 21.836109 },
    { lat: 48.461455, lng: 21.835996 },
    { lat: 48.46174, lng: 21.835812 },
    { lat: 48.461992, lng: 21.835656 },
    { lat: 48.46215, lng: 21.835444 },
    { lat: 48.462253, lng: 21.83521 },
    { lat: 48.462418, lng: 21.834824 },
    { lat: 48.462483, lng: 21.834436 },
    { lat: 48.462546, lng: 21.833966 },
    { lat: 48.462492, lng: 21.833109 },
    { lat: 48.46258, lng: 21.832863 },
    { lat: 48.462637, lng: 21.832759 },
    { lat: 48.462851, lng: 21.832671 },
    { lat: 48.463111, lng: 21.832688 },
    { lat: 48.463418, lng: 21.832724 },
    { lat: 48.463635, lng: 21.83296 },
    { lat: 48.46467, lng: 21.834136 },
    { lat: 48.465205, lng: 21.834584 },
    { lat: 48.466832, lng: 21.835685 },
    { lat: 48.467289, lng: 21.836058 },
    { lat: 48.467523, lng: 21.836359 },
    { lat: 48.467659, lng: 21.836771 },
    { lat: 48.467671, lng: 21.836805 },
    { lat: 48.467792, lng: 21.837171 },
    { lat: 48.467823, lng: 21.837662 },
    { lat: 48.467798, lng: 21.838205 },
    { lat: 48.467714, lng: 21.838681 },
    { lat: 48.467468, lng: 21.839816 },
    { lat: 48.467467, lng: 21.840006 },
    { lat: 48.467688, lng: 21.840642 },
    { lat: 48.4679, lng: 21.840868 },
    { lat: 48.468455, lng: 21.841209 },
    { lat: 48.469087, lng: 21.841253 },
    { lat: 48.469545, lng: 21.841134 },
    { lat: 48.469564, lng: 21.841128 },
    { lat: 48.469787, lng: 21.841055 },
    { lat: 48.470021, lng: 21.84094 },
    { lat: 48.470095, lng: 21.840892 },
    { lat: 48.470138, lng: 21.840848 },
    { lat: 48.470212, lng: 21.840745 },
    { lat: 48.470394, lng: 21.840515 },
    { lat: 48.470512, lng: 21.840366 },
    { lat: 48.470716, lng: 21.840097 },
    { lat: 48.470816, lng: 21.839963 },
    { lat: 48.471062, lng: 21.839655 },
    { lat: 48.471084, lng: 21.839639 },
    { lat: 48.471201, lng: 21.839589 },
    { lat: 48.47141, lng: 21.839515 },
    { lat: 48.471599, lng: 21.839433 },
    { lat: 48.471657, lng: 21.839417 },
    { lat: 48.471712, lng: 21.839411 },
    { lat: 48.471852, lng: 21.839405 },
    { lat: 48.472135, lng: 21.839404 },
    { lat: 48.472448, lng: 21.839432 },
    { lat: 48.472798, lng: 21.83941 },
    { lat: 48.47285, lng: 21.839403 },
    { lat: 48.472933, lng: 21.839399 },
    { lat: 48.473144, lng: 21.839188 },
    { lat: 48.47319, lng: 21.839143 },
    { lat: 48.473272, lng: 21.839061 },
    { lat: 48.473367, lng: 21.838968 },
    { lat: 48.47341, lng: 21.838926 },
    { lat: 48.473488, lng: 21.838848 },
    { lat: 48.473523, lng: 21.838786 },
    { lat: 48.473553, lng: 21.838733 },
    { lat: 48.473584, lng: 21.838673 },
    { lat: 48.473627, lng: 21.838598 },
    { lat: 48.473722, lng: 21.838457 },
    { lat: 48.473847, lng: 21.838268 },
    { lat: 48.473917, lng: 21.838158 },
    { lat: 48.474004, lng: 21.838026 },
    { lat: 48.474099, lng: 21.83788 },
    { lat: 48.474138, lng: 21.837823 },
    { lat: 48.474235, lng: 21.837674 },
    { lat: 48.474301, lng: 21.837587 },
    { lat: 48.474477, lng: 21.837448 },
    { lat: 48.474553, lng: 21.837396 },
    { lat: 48.474595, lng: 21.837364 },
    { lat: 48.474604, lng: 21.837359 },
    { lat: 48.47474, lng: 21.837341 },
    { lat: 48.474875, lng: 21.83732 },
    { lat: 48.475008, lng: 21.837304 },
    { lat: 48.475065, lng: 21.837292 },
    { lat: 48.475141, lng: 21.837319 },
    { lat: 48.475204, lng: 21.837344 },
    { lat: 48.475334, lng: 21.8374 },
    { lat: 48.47545, lng: 21.837442 },
    { lat: 48.475508, lng: 21.837481 },
    { lat: 48.475644, lng: 21.8376 },
    { lat: 48.475735, lng: 21.837681 },
    { lat: 48.47597, lng: 21.838158 },
    { lat: 48.476122, lng: 21.838467 },
    { lat: 48.476215, lng: 21.838663 },
    { lat: 48.476304, lng: 21.839662 },
    { lat: 48.476373, lng: 21.84036 },
    { lat: 48.476206, lng: 21.841417 },
    { lat: 48.475735, lng: 21.842355 },
    { lat: 48.475648, lng: 21.842634 },
    { lat: 48.475534, lng: 21.842989 },
    { lat: 48.475892, lng: 21.843239 },
    { lat: 48.47603, lng: 21.843288 },
    { lat: 48.476075, lng: 21.843301 },
    { lat: 48.476086, lng: 21.843303 },
    { lat: 48.476128, lng: 21.843312 },
    { lat: 48.476397, lng: 21.843367 },
    { lat: 48.47666, lng: 21.843424 },
    { lat: 48.476887, lng: 21.843418 },
    { lat: 48.477014, lng: 21.843408 },
    { lat: 48.477066, lng: 21.843406 },
    { lat: 48.477127, lng: 21.843398 },
    { lat: 48.477202, lng: 21.843387 },
    { lat: 48.477355, lng: 21.843366 },
    { lat: 48.477505, lng: 21.843353 },
    { lat: 48.477625, lng: 21.843353 },
    { lat: 48.477977, lng: 21.843368 },
    { lat: 48.47842, lng: 21.8434 },
    { lat: 48.478953, lng: 21.843414 },
    { lat: 48.47922, lng: 21.843378 },
    { lat: 48.479326, lng: 21.843377 },
    { lat: 48.479462, lng: 21.843394 },
    { lat: 48.479585, lng: 21.843436 },
    { lat: 48.47972, lng: 21.843477 },
    { lat: 48.479801, lng: 21.843518 },
    { lat: 48.479831, lng: 21.843539 },
    { lat: 48.480075, lng: 21.843704 },
    { lat: 48.480116, lng: 21.843734 },
    { lat: 48.480388, lng: 21.844057 },
    { lat: 48.480679, lng: 21.844737 },
    { lat: 48.480709, lng: 21.844808 },
    { lat: 48.480799, lng: 21.845084 },
    { lat: 48.480937, lng: 21.845372 },
    { lat: 48.48105, lng: 21.845593 },
    { lat: 48.481215, lng: 21.845861 },
    { lat: 48.481427, lng: 21.84611 },
    { lat: 48.481564, lng: 21.846276 },
    { lat: 48.4819, lng: 21.846485 },
    { lat: 48.482209, lng: 21.846649 },
    { lat: 48.482574, lng: 21.846647 },
    { lat: 48.482704, lng: 21.846615 },
    { lat: 48.482945, lng: 21.846533 },
    { lat: 48.483341, lng: 21.846173 },
    { lat: 48.48343, lng: 21.846093 },
    { lat: 48.483664, lng: 21.845801 },
    { lat: 48.483905, lng: 21.845468 },
    { lat: 48.484115, lng: 21.845149 },
    { lat: 48.484342, lng: 21.844804 },
    { lat: 48.484509, lng: 21.844556 },
    { lat: 48.484591, lng: 21.844398 },
    { lat: 48.484671, lng: 21.84425 },
    { lat: 48.484853, lng: 21.843975 },
    { lat: 48.484957, lng: 21.84382 },
    { lat: 48.485071, lng: 21.843689 },
    { lat: 48.485282, lng: 21.843465 },
    { lat: 48.485321, lng: 21.843434 },
    { lat: 48.485586, lng: 21.843358 },
    { lat: 48.485628, lng: 21.843355 },
    { lat: 48.485804, lng: 21.843425 },
    { lat: 48.485907, lng: 21.843569 },
    { lat: 48.485941, lng: 21.843757 },
    { lat: 48.485945, lng: 21.843882 },
    { lat: 48.485907, lng: 21.844284 },
    { lat: 48.485854, lng: 21.844399 },
    { lat: 48.485743, lng: 21.844645 },
    { lat: 48.485609, lng: 21.844928 },
    { lat: 48.485502, lng: 21.845099 },
    { lat: 48.485352, lng: 21.845328 },
    { lat: 48.485276, lng: 21.845467 },
    { lat: 48.485105, lng: 21.845792 },
    { lat: 48.484957, lng: 21.846204 },
    { lat: 48.48486, lng: 21.846482 },
    { lat: 48.484728, lng: 21.846975 },
    { lat: 48.484662, lng: 21.847326 },
    { lat: 48.48459, lng: 21.847702 },
    { lat: 48.484562, lng: 21.847864 },
    { lat: 48.484531, lng: 21.848125 },
    { lat: 48.484531, lng: 21.848162 },
    { lat: 48.484571, lng: 21.848485 },
    { lat: 48.484597, lng: 21.848687 },
    { lat: 48.484605, lng: 21.84875 },
    { lat: 48.484624, lng: 21.848844 },
    { lat: 48.484631, lng: 21.848882 },
    { lat: 48.484686, lng: 21.849075 },
    { lat: 48.484729, lng: 21.849223 },
    { lat: 48.484833, lng: 21.849442 },
    { lat: 48.484862, lng: 21.849501 },
    { lat: 48.484935, lng: 21.849603 },
    { lat: 48.48497, lng: 21.849652 },
    { lat: 48.485154, lng: 21.849816 },
    { lat: 48.485274, lng: 21.849918 },
    { lat: 48.485579, lng: 21.850104 },
    { lat: 48.485719, lng: 21.850117 },
    { lat: 48.486044, lng: 21.850078 },
    { lat: 48.486139, lng: 21.850064 },
    { lat: 48.486198, lng: 21.850052 },
    { lat: 48.48643, lng: 21.849946 },
    { lat: 48.48648, lng: 21.849931 },
    { lat: 48.48672, lng: 21.849845 },
    { lat: 48.486807, lng: 21.849815 },
    { lat: 48.486947, lng: 21.849781 },
    { lat: 48.48709, lng: 21.849742 },
    { lat: 48.487229, lng: 21.84974 },
    { lat: 48.487303, lng: 21.849744 },
    { lat: 48.487537, lng: 21.849833 },
    { lat: 48.487659, lng: 21.84988 },
    { lat: 48.487682, lng: 21.849897 },
    { lat: 48.487964, lng: 21.850159 },
    { lat: 48.488143, lng: 21.850357 },
    { lat: 48.488289, lng: 21.850534 },
    { lat: 48.488394, lng: 21.85075 },
    { lat: 48.488481, lng: 21.850938 },
    { lat: 48.488621, lng: 21.851392 },
    { lat: 48.488662, lng: 21.851527 },
    { lat: 48.488684, lng: 21.851696 },
    { lat: 48.488714, lng: 21.851949 },
    { lat: 48.488686, lng: 21.85234 },
    { lat: 48.48867, lng: 21.852545 },
    { lat: 48.488655, lng: 21.852632 },
    { lat: 48.488606, lng: 21.852839 },
    { lat: 48.488573, lng: 21.852988 },
    { lat: 48.488386, lng: 21.853422 },
    { lat: 48.488306, lng: 21.853598 },
    { lat: 48.488218, lng: 21.853733 },
    { lat: 48.488191, lng: 21.853768 },
    { lat: 48.488113, lng: 21.853869 },
    { lat: 48.488009, lng: 21.853981 },
    { lat: 48.487923, lng: 21.854071 },
    { lat: 48.487815, lng: 21.854185 },
    { lat: 48.487527, lng: 21.854383 },
    { lat: 48.487372, lng: 21.854489 },
    { lat: 48.487222, lng: 21.854588 },
    { lat: 48.487147, lng: 21.854591 },
    { lat: 48.487025, lng: 21.854599 },
    { lat: 48.486897, lng: 21.854611 },
    { lat: 48.486673, lng: 21.854633 },
    { lat: 48.486638, lng: 21.854623 },
    { lat: 48.486248, lng: 21.854485 },
    { lat: 48.48621, lng: 21.854468 },
    { lat: 48.485988, lng: 21.854294 },
    { lat: 48.485522, lng: 21.853896 },
    { lat: 48.4854, lng: 21.853852 },
    { lat: 48.485288, lng: 21.853832 },
    { lat: 48.485229, lng: 21.853826 },
    { lat: 48.485188, lng: 21.853844 },
    { lat: 48.485063, lng: 21.853907 },
    { lat: 48.484902, lng: 21.854062 },
    { lat: 48.484834, lng: 21.854131 },
    { lat: 48.484764, lng: 21.854232 },
    { lat: 48.484739, lng: 21.854286 },
    { lat: 48.484669, lng: 21.854464 },
    { lat: 48.484648, lng: 21.854528 },
    { lat: 48.484616, lng: 21.854827 },
    { lat: 48.484601, lng: 21.855061 },
    { lat: 48.484598, lng: 21.855164 },
    { lat: 48.484597, lng: 21.855209 },
    { lat: 48.484622, lng: 21.85545 },
    { lat: 48.484637, lng: 21.855516 },
    { lat: 48.484683, lng: 21.855657 },
    { lat: 48.484762, lng: 21.855902 },
    { lat: 48.484796, lng: 21.855994 },
    { lat: 48.484829, lng: 21.856064 },
    { lat: 48.484867, lng: 21.856128 },
    { lat: 48.484968, lng: 21.85625 },
    { lat: 48.48508, lng: 21.856387 },
    { lat: 48.485206, lng: 21.856512 },
    { lat: 48.485262, lng: 21.856557 },
    { lat: 48.485425, lng: 21.85667 },
    { lat: 48.485491, lng: 21.856716 },
    { lat: 48.485762, lng: 21.856825 },
    { lat: 48.485947, lng: 21.856896 },
    { lat: 48.486488, lng: 21.85668 },
    { lat: 48.486748, lng: 21.856576 },
    { lat: 48.487074, lng: 21.85632 },
    { lat: 48.487122, lng: 21.85632 },
    { lat: 48.487531, lng: 21.856377 },
    { lat: 48.487576, lng: 21.85639 },
    { lat: 48.487662, lng: 21.856425 },
    { lat: 48.487772, lng: 21.856469 },
    { lat: 48.487803, lng: 21.856533 },
    { lat: 48.487812, lng: 21.856552 },
    { lat: 48.487943, lng: 21.856808 },
    { lat: 48.488064, lng: 21.857291 },
    { lat: 48.488071, lng: 21.857702 },
    { lat: 48.487954, lng: 21.858464 },
    { lat: 48.487913, lng: 21.85884 },
    { lat: 48.487854, lng: 21.85946 },
    { lat: 48.487869, lng: 21.859741 },
    { lat: 48.487882, lng: 21.860005 },
    { lat: 48.488084, lng: 21.861089 },
    { lat: 48.488095, lng: 21.861145 },
    { lat: 48.4882, lng: 21.861492 },
    { lat: 48.488352, lng: 21.86202 },
    { lat: 48.4885, lng: 21.86259 },
    { lat: 48.488548, lng: 21.862771 },
    { lat: 48.488564, lng: 21.862852 },
    { lat: 48.488631, lng: 21.863282 },
    { lat: 48.488678, lng: 21.863778 },
    { lat: 48.488705, lng: 21.864086 },
    { lat: 48.488715, lng: 21.864327 },
    { lat: 48.48872, lng: 21.864735 },
    { lat: 48.488684, lng: 21.865204 },
    { lat: 48.48861, lng: 21.865796 },
    { lat: 48.488598, lng: 21.86586 },
    { lat: 48.488526, lng: 21.866134 },
    { lat: 48.488511, lng: 21.866739 },
    { lat: 48.488528, lng: 21.866891 },
    { lat: 48.488632, lng: 21.867073 },
    { lat: 48.488664, lng: 21.867105 },
    { lat: 48.488765, lng: 21.867183 },
    { lat: 48.488855, lng: 21.867249 },
    { lat: 48.489027, lng: 21.86733 },
    { lat: 48.48917, lng: 21.867339 },
    { lat: 48.489284, lng: 21.867295 },
    { lat: 48.48942, lng: 21.867239 },
    { lat: 48.489539, lng: 21.867178 },
    { lat: 48.489662, lng: 21.867111 },
    { lat: 48.489685, lng: 21.867092 },
    { lat: 48.489734, lng: 21.866993 },
    { lat: 48.489765, lng: 21.866959 },
    { lat: 48.489841, lng: 21.86686 },
    { lat: 48.489928, lng: 21.866733 },
    { lat: 48.490011, lng: 21.866569 },
    { lat: 48.49005, lng: 21.866376 },
    { lat: 48.490092, lng: 21.86568 },
    { lat: 48.489962, lng: 21.864796 },
    { lat: 48.48991, lng: 21.864313 },
    { lat: 48.489861, lng: 21.863927 },
    { lat: 48.490034, lng: 21.86318 },
    { lat: 48.490241, lng: 21.862716 },
    { lat: 48.490458, lng: 21.862398 },
    { lat: 48.490895, lng: 21.862074 },
    { lat: 48.491412, lng: 21.861826 },
    { lat: 48.491791, lng: 21.861823 },
    { lat: 48.492319, lng: 21.862157 },
    { lat: 48.492613, lng: 21.862672 },
    { lat: 48.49273, lng: 21.863357 },
    { lat: 48.49273, lng: 21.863788 },
    { lat: 48.492731, lng: 21.864126 },
    { lat: 48.492484, lng: 21.864957 },
    { lat: 48.492323, lng: 21.865522 },
    { lat: 48.492248, lng: 21.866211 },
    { lat: 48.492252, lng: 21.867131 },
    { lat: 48.492287, lng: 21.867657 },
    { lat: 48.492173, lng: 21.86872 },
    { lat: 48.49193, lng: 21.869217 },
    { lat: 48.491905, lng: 21.869277 },
    { lat: 48.491856, lng: 21.869509 },
    { lat: 48.491836, lng: 21.869611 },
    { lat: 48.491847, lng: 21.869674 },
    { lat: 48.491892, lng: 21.869887 },
    { lat: 48.4919, lng: 21.86997 },
    { lat: 48.491908, lng: 21.870025 },
    { lat: 48.492008, lng: 21.870274 },
    { lat: 48.492267, lng: 21.870513 },
    { lat: 48.492655, lng: 21.870661 },
    { lat: 48.492761, lng: 21.870695 },
    { lat: 48.493182, lng: 21.870832 },
    { lat: 48.493328, lng: 21.870873 },
    { lat: 48.493667, lng: 21.870951 },
    { lat: 48.494044, lng: 21.871096 },
    { lat: 48.494277, lng: 21.871215 },
    { lat: 48.494696, lng: 21.871453 },
    { lat: 48.4950851, lng: 21.8716824 },
    { lat: 48.495212, lng: 21.871814 },
    { lat: 48.49556, lng: 21.872354 },
    { lat: 48.495869, lng: 21.872917 },
    { lat: 48.496031, lng: 21.873207 },
    { lat: 48.496435, lng: 21.874123 },
    { lat: 48.496776, lng: 21.875157 },
    { lat: 48.497011, lng: 21.875599 },
    { lat: 48.497298, lng: 21.875747 },
    { lat: 48.497441, lng: 21.875748 },
    { lat: 48.497557, lng: 21.875745 },
    { lat: 48.497798, lng: 21.875646 },
    { lat: 48.498121, lng: 21.875372 },
    { lat: 48.498287, lng: 21.875161 },
    { lat: 48.498565, lng: 21.874246 },
    { lat: 48.498822, lng: 21.873185 },
    { lat: 48.498912, lng: 21.872392 },
    { lat: 48.49905, lng: 21.871655 },
    { lat: 48.499155, lng: 21.871433 },
    { lat: 48.499306, lng: 21.871258 },
    { lat: 48.499443, lng: 21.871259 },
    { lat: 48.499564, lng: 21.871322 },
    { lat: 48.499877, lng: 21.871611 },
    { lat: 48.500309, lng: 21.872436 },
    { lat: 48.500434, lng: 21.872689 },
    { lat: 48.500643, lng: 21.873057 },
    { lat: 48.50083, lng: 21.873354 },
    { lat: 48.500993, lng: 21.873616 },
    { lat: 48.501137, lng: 21.873842 },
    { lat: 48.501269, lng: 21.874026 },
    { lat: 48.501407, lng: 21.874223 },
    { lat: 48.501575, lng: 21.874465 },
    { lat: 48.501717, lng: 21.87464 },
    { lat: 48.501834, lng: 21.87477 },
    { lat: 48.501872, lng: 21.874813 },
    { lat: 48.502, lng: 21.874964 },
    { lat: 48.502161, lng: 21.875155 },
    { lat: 48.502205, lng: 21.875209 },
    { lat: 48.502311, lng: 21.87534 },
    { lat: 48.502494, lng: 21.875555 },
    { lat: 48.502684, lng: 21.875776 },
    { lat: 48.502828, lng: 21.875904 },
    { lat: 48.503001, lng: 21.876057 },
    { lat: 48.503142, lng: 21.87617 },
    { lat: 48.503372, lng: 21.876421 },
    { lat: 48.503587, lng: 21.876669 },
    { lat: 48.503684, lng: 21.876807 },
    { lat: 48.503722, lng: 21.876972 },
    { lat: 48.503765, lng: 21.877183 },
    { lat: 48.503751, lng: 21.877336 },
    { lat: 48.503683, lng: 21.877506 },
    { lat: 48.503611, lng: 21.877647 },
    { lat: 48.503486, lng: 21.877772 },
    { lat: 48.503176, lng: 21.877894 },
    { lat: 48.503008, lng: 21.877887 },
    { lat: 48.502871, lng: 21.877893 },
    { lat: 48.502714, lng: 21.877825 },
    { lat: 48.502692, lng: 21.877817 },
    { lat: 48.502614, lng: 21.877791 },
    { lat: 48.50259, lng: 21.877775 },
    { lat: 48.502554, lng: 21.877748 },
    { lat: 48.502466, lng: 21.877722 },
    { lat: 48.502291, lng: 21.877602 },
    { lat: 48.502116, lng: 21.877483 },
    { lat: 48.502115, lng: 21.877482 },
    { lat: 48.501991, lng: 21.877403 },
    { lat: 48.501857, lng: 21.877311 },
    { lat: 48.501704, lng: 21.877202 },
    { lat: 48.50154, lng: 21.877076 },
    { lat: 48.501402, lng: 21.876974 },
    { lat: 48.501245, lng: 21.87685 },
    { lat: 48.501116, lng: 21.87677 },
    { lat: 48.500985, lng: 21.876682 },
    { lat: 48.500977, lng: 21.876677 },
    { lat: 48.500972, lng: 21.876674 },
    { lat: 48.500857, lng: 21.876605 },
    { lat: 48.500729, lng: 21.876515 },
    { lat: 48.500638, lng: 21.876484 },
    { lat: 48.500488, lng: 21.876427 },
    { lat: 48.500412, lng: 21.876413 },
    { lat: 48.500269, lng: 21.876387 },
    { lat: 48.50016, lng: 21.876391 },
    { lat: 48.500009, lng: 21.876393 },
    { lat: 48.499909, lng: 21.876433 },
    { lat: 48.499828, lng: 21.87646 },
    { lat: 48.499727, lng: 21.876533 },
    { lat: 48.499613, lng: 21.876636 },
    { lat: 48.499559, lng: 21.876693 },
    { lat: 48.499441, lng: 21.876861 },
    { lat: 48.499336, lng: 21.877048 },
    { lat: 48.499266, lng: 21.877249 },
    { lat: 48.499216, lng: 21.877453 },
    { lat: 48.499194, lng: 21.877573 },
    { lat: 48.499163, lng: 21.877861 },
    { lat: 48.499159, lng: 21.878001 },
    { lat: 48.499153, lng: 21.878277 },
    { lat: 48.499165, lng: 21.878432 },
    { lat: 48.499177, lng: 21.878548 },
    { lat: 48.499254, lng: 21.878932 },
    { lat: 48.499301, lng: 21.879148 },
    { lat: 48.499379, lng: 21.879392 },
    { lat: 48.499473, lng: 21.879645 },
    { lat: 48.499643, lng: 21.879929 },
    { lat: 48.499805, lng: 21.88019 },
    { lat: 48.499918, lng: 21.880321 },
    { lat: 48.500076, lng: 21.880502 },
    { lat: 48.500215, lng: 21.880649 },
    { lat: 48.500225, lng: 21.88066 },
    { lat: 48.500229, lng: 21.880664 },
    { lat: 48.500291, lng: 21.880745 },
    { lat: 48.500492, lng: 21.880958 },
    { lat: 48.500681, lng: 21.881065 },
    { lat: 48.500946, lng: 21.881224 },
    { lat: 48.501139, lng: 21.88134 },
    { lat: 48.501259, lng: 21.881394 },
    { lat: 48.501472, lng: 21.881502 },
    { lat: 48.501483, lng: 21.881508 },
    { lat: 48.501497, lng: 21.881515 },
    { lat: 48.501658, lng: 21.881591 },
    { lat: 48.501759, lng: 21.881643 },
    { lat: 48.501912, lng: 21.881805 },
    { lat: 48.501994, lng: 21.881895 },
    { lat: 48.502082, lng: 21.881994 },
    { lat: 48.502153, lng: 21.8821 },
    { lat: 48.502239, lng: 21.882406 },
    { lat: 48.502257, lng: 21.882609 },
    { lat: 48.502241, lng: 21.882852 },
    { lat: 48.502196, lng: 21.883019 },
    { lat: 48.502099, lng: 21.883157 },
    { lat: 48.501869, lng: 21.883481 },
    { lat: 48.501584, lng: 21.883584 },
    { lat: 48.501416, lng: 21.883603 },
    { lat: 48.501207, lng: 21.883589 },
    { lat: 48.501015, lng: 21.883391 },
    { lat: 48.500881, lng: 21.883196 },
    { lat: 48.500616, lng: 21.882754 },
    { lat: 48.500388, lng: 21.882384 },
    { lat: 48.500207, lng: 21.882141 },
    { lat: 48.499961, lng: 21.881986 },
    { lat: 48.499647, lng: 21.882026 },
    { lat: 48.499391, lng: 21.882278 },
    { lat: 48.499266, lng: 21.882468 },
    { lat: 48.49922, lng: 21.882658 },
    { lat: 48.499308, lng: 21.883257 },
    { lat: 48.499418, lng: 21.883889 },
    { lat: 48.499422, lng: 21.883911 },
    { lat: 48.499481, lng: 21.884224 },
    { lat: 48.499536, lng: 21.884565 },
    { lat: 48.49963, lng: 21.88532 },
    { lat: 48.499655, lng: 21.885622 },
    { lat: 48.499694, lng: 21.886622 },
    { lat: 48.499705, lng: 21.886854 },
    { lat: 48.49967, lng: 21.887201 },
    { lat: 48.499629, lng: 21.887534 },
    { lat: 48.499526, lng: 21.887947 },
    { lat: 48.499477, lng: 21.88815 },
    { lat: 48.499403, lng: 21.888459 },
    { lat: 48.49927, lng: 21.888989 },
    { lat: 48.499243, lng: 21.889102 },
    { lat: 48.49912, lng: 21.889597 },
    { lat: 48.49911, lng: 21.889753 },
    { lat: 48.499069, lng: 21.890291 },
    { lat: 48.499083, lng: 21.89046 },
    { lat: 48.499193, lng: 21.890972 },
    { lat: 48.499367, lng: 21.8914 },
    { lat: 48.499469, lng: 21.89155 },
    { lat: 48.499585, lng: 21.891615 },
    { lat: 48.49977, lng: 21.891705 },
    { lat: 48.500023, lng: 21.891677 },
    { lat: 48.500139, lng: 21.891639 },
    { lat: 48.500314, lng: 21.891525 },
    { lat: 48.50068, lng: 21.891265 },
    { lat: 48.500832, lng: 21.890938 },
    { lat: 48.500929, lng: 21.890712 },
    { lat: 48.501023, lng: 21.890355 },
    { lat: 48.501127, lng: 21.889979 },
    { lat: 48.5012, lng: 21.889598 },
    { lat: 48.50128, lng: 21.888875 },
    { lat: 48.501356, lng: 21.888651 },
    { lat: 48.501608, lng: 21.888356 },
    { lat: 48.501689, lng: 21.888297 },
    { lat: 48.501839, lng: 21.888216 },
    { lat: 48.502093, lng: 21.888257 },
    { lat: 48.502193, lng: 21.888314 },
    { lat: 48.502344, lng: 21.888388 },
    { lat: 48.502429, lng: 21.888452 },
    { lat: 48.502559, lng: 21.88859 },
    { lat: 48.502812, lng: 21.888993 },
    { lat: 48.502966, lng: 21.88926 },
    { lat: 48.503142, lng: 21.889752 },
    { lat: 48.503226, lng: 21.889969 },
    { lat: 48.503264, lng: 21.890122 },
    { lat: 48.50331, lng: 21.890347 },
    { lat: 48.503357, lng: 21.890568 },
    { lat: 48.503366, lng: 21.890743 },
    { lat: 48.503376, lng: 21.890975 },
    { lat: 48.503381, lng: 21.891119 },
    { lat: 48.503354, lng: 21.891399 },
    { lat: 48.50333, lng: 21.891626 },
    { lat: 48.503245, lng: 21.89195 },
    { lat: 48.503197, lng: 21.892133 },
    { lat: 48.503196, lng: 21.892136 },
    { lat: 48.503136, lng: 21.892363 },
    { lat: 48.502947, lng: 21.89276 },
    { lat: 48.502798, lng: 21.892978 },
    { lat: 48.502676, lng: 21.893259 },
    { lat: 48.502544, lng: 21.893473 },
    { lat: 48.502408, lng: 21.893635 },
    { lat: 48.502243, lng: 21.893856 },
    { lat: 48.502115, lng: 21.894027 },
    { lat: 48.502029, lng: 21.894157 },
    { lat: 48.501995, lng: 21.894254 },
    { lat: 48.50198, lng: 21.894368 },
    { lat: 48.501978, lng: 21.894479 },
    { lat: 48.502027, lng: 21.894634 },
    { lat: 48.502118, lng: 21.894768 },
    { lat: 48.502216, lng: 21.894877 },
    { lat: 48.502331, lng: 21.894897 },
    { lat: 48.502644, lng: 21.894921 },
    { lat: 48.502774, lng: 21.894893 },
    { lat: 48.503035, lng: 21.89476 },
    { lat: 48.503237, lng: 21.894681 },
    { lat: 48.503439, lng: 21.894587 },
    { lat: 48.503616, lng: 21.894494 },
    { lat: 48.503843, lng: 21.894334 },
    { lat: 48.50401, lng: 21.894215 },
    { lat: 48.504142, lng: 21.894115 },
    { lat: 48.504267, lng: 21.894087 },
    { lat: 48.504447, lng: 21.89404 },
    { lat: 48.504599, lng: 21.894134 },
    { lat: 48.50473, lng: 21.894214 },
    { lat: 48.504781, lng: 21.894269 },
    { lat: 48.504853, lng: 21.894435 },
    { lat: 48.5049, lng: 21.894576 },
    { lat: 48.504917, lng: 21.894818 },
    { lat: 48.504909, lng: 21.895105 },
    { lat: 48.504862, lng: 21.895463 },
    { lat: 48.504785, lng: 21.895777 },
    { lat: 48.50462, lng: 21.89638 },
    { lat: 48.50429, lng: 21.897214 },
    { lat: 48.504209, lng: 21.897478 },
    { lat: 48.504127, lng: 21.897741 },
    { lat: 48.504062, lng: 21.897997 },
    { lat: 48.504007, lng: 21.898305 },
    { lat: 48.503996, lng: 21.898606 },
    { lat: 48.504032, lng: 21.899012 },
    { lat: 48.504126, lng: 21.899293 },
    { lat: 48.504296, lng: 21.899656 },
    { lat: 48.504436, lng: 21.899823 },
    { lat: 48.50453, lng: 21.899932 },
    { lat: 48.504757, lng: 21.900147 },
    { lat: 48.504909, lng: 21.900255 },
    { lat: 48.505107, lng: 21.900373 },
    { lat: 48.505278, lng: 21.900388 },
    { lat: 48.50543, lng: 21.900385 },
    { lat: 48.505662, lng: 21.900429 },
    { lat: 48.506131, lng: 21.900381 },
    { lat: 48.506287, lng: 21.900362 },
    { lat: 48.506777, lng: 21.900248 },
    { lat: 48.507313, lng: 21.900064 },
    { lat: 48.507696, lng: 21.899929 },
    { lat: 48.507945, lng: 21.899715 },
    { lat: 48.508361, lng: 21.899279 },
    { lat: 48.508707, lng: 21.899135 },
    { lat: 48.50886, lng: 21.899146 },
    { lat: 48.508973, lng: 21.899231 },
    { lat: 48.509255, lng: 21.899624 },
    { lat: 48.509303, lng: 21.899745 },
    { lat: 48.509356, lng: 21.899962 },
    { lat: 48.509444, lng: 21.900505 },
    { lat: 48.509439, lng: 21.900877 },
    { lat: 48.509409, lng: 21.901182 },
    { lat: 48.509312, lng: 21.901744 },
    { lat: 48.509251, lng: 21.902136 },
    { lat: 48.508949, lng: 21.902304 },
    { lat: 48.508839, lng: 21.902359 },
    { lat: 48.508049, lng: 21.902378 },
    { lat: 48.507183, lng: 21.902465 },
    { lat: 48.506879, lng: 21.902699 },
    { lat: 48.506544, lng: 21.903042 },
    { lat: 48.506355, lng: 21.903434 },
    { lat: 48.506061, lng: 21.904048 },
    { lat: 48.505814, lng: 21.904547 },
    { lat: 48.50564, lng: 21.904803 },
    { lat: 48.505254, lng: 21.905384 },
    { lat: 48.505003, lng: 21.905561 },
    { lat: 48.504775, lng: 21.905721 },
    { lat: 48.504466, lng: 21.905897 },
    { lat: 48.504141, lng: 21.905974 },
    { lat: 48.503737, lng: 21.905976 },
    { lat: 48.50368, lng: 21.905955 },
    { lat: 48.503587, lng: 21.905914 },
    { lat: 48.50325, lng: 21.905827 },
    { lat: 48.503202, lng: 21.905813 },
    { lat: 48.503117, lng: 21.90578 },
    { lat: 48.502934, lng: 21.905623 },
    { lat: 48.502403, lng: 21.905139 },
    { lat: 48.501716, lng: 21.904442 },
    { lat: 48.501259, lng: 21.904029 },
    { lat: 48.500932, lng: 21.903829 },
    { lat: 48.500513, lng: 21.903653 },
    { lat: 48.500073, lng: 21.903503 },
    { lat: 48.499724, lng: 21.903354 },
    { lat: 48.4996, lng: 21.903378 },
    { lat: 48.499393, lng: 21.903458 },
    { lat: 48.499216, lng: 21.903685 },
    { lat: 48.499154, lng: 21.904029 },
    { lat: 48.499113, lng: 21.904342 },
    { lat: 48.499178, lng: 21.904767 },
    { lat: 48.499223, lng: 21.90505 },
    { lat: 48.499505, lng: 21.905852 },
    { lat: 48.499871, lng: 21.906532 },
    { lat: 48.500388, lng: 21.907412 },
    { lat: 48.500541, lng: 21.907657 },
    { lat: 48.500769, lng: 21.90801 },
    { lat: 48.501234, lng: 21.908664 },
    { lat: 48.501962, lng: 21.909495 },
    { lat: 48.502298, lng: 21.909604 },
    { lat: 48.502467, lng: 21.909683 },
    { lat: 48.50263, lng: 21.909755 },
    { lat: 48.502896, lng: 21.909916 },
    { lat: 48.502936, lng: 21.909935 },
    { lat: 48.503116, lng: 21.909878 },
    { lat: 48.503216, lng: 21.909765 },
    { lat: 48.503297, lng: 21.909641 },
    { lat: 48.503457, lng: 21.909501 },
    { lat: 48.503543, lng: 21.90943 },
    { lat: 48.503592, lng: 21.909389 },
    { lat: 48.503666, lng: 21.90928 },
    { lat: 48.503788, lng: 21.90911 },
    { lat: 48.503964, lng: 21.908787 },
    { lat: 48.504269, lng: 21.908069 },
    { lat: 48.504549, lng: 21.907854 },
    { lat: 48.50477, lng: 21.907661 },
    { lat: 48.504833, lng: 21.907615 },
    { lat: 48.504946, lng: 21.907597 },
    { lat: 48.505117, lng: 21.907615 },
    { lat: 48.505291, lng: 21.90783 },
    { lat: 48.505381, lng: 21.908048 },
    { lat: 48.505492, lng: 21.908695 },
    { lat: 48.505368, lng: 21.909184 },
    { lat: 48.505167, lng: 21.90996 },
    { lat: 48.504435, lng: 21.911588 },
    { lat: 48.504132, lng: 21.912451 },
    { lat: 48.503822, lng: 21.913849 },
    { lat: 48.503731, lng: 21.914594 },
    { lat: 48.503648, lng: 21.915038 },
    { lat: 48.503451, lng: 21.915838 },
    { lat: 48.503133, lng: 21.916892 },
    { lat: 48.502898, lng: 21.91777 },
    { lat: 48.502684, lng: 21.918444 },
    { lat: 48.502503, lng: 21.918849 },
    { lat: 48.502397, lng: 21.918939 },
    { lat: 48.502313, lng: 21.918977 },
    { lat: 48.502258, lng: 21.91895 },
    { lat: 48.502133, lng: 21.918833 },
    { lat: 48.50194, lng: 21.918545 },
    { lat: 48.501806, lng: 21.918094 },
    { lat: 48.50176, lng: 21.917456 },
    { lat: 48.501723, lng: 21.916934 },
    { lat: 48.501723, lng: 21.916746 },
    { lat: 48.501727, lng: 21.916178 },
    { lat: 48.50173, lng: 21.915806 },
    { lat: 48.50178, lng: 21.915582 },
    { lat: 48.501823, lng: 21.915393 },
    { lat: 48.501827, lng: 21.915379 },
    { lat: 48.501921, lng: 21.914953 },
    { lat: 48.501966, lng: 21.914768 },
    { lat: 48.50221, lng: 21.914421 },
    { lat: 48.502389, lng: 21.914045 },
    { lat: 48.502565, lng: 21.913692 },
    { lat: 48.502757, lng: 21.913311 },
    { lat: 48.502953, lng: 21.912916 },
    { lat: 48.503166, lng: 21.912487 },
    { lat: 48.503197, lng: 21.912311 },
    { lat: 48.503183, lng: 21.912116 },
    { lat: 48.5031085, lng: 21.9107965 },
    { lat: 48.502873, lng: 21.911336 },
    { lat: 48.502816, lng: 21.911221 },
    { lat: 48.502744, lng: 21.911253 },
    { lat: 48.502184, lng: 21.911594 },
    { lat: 48.501737, lng: 21.911882 },
    { lat: 48.501448, lng: 21.912062 },
    { lat: 48.50101, lng: 21.912486 },
    { lat: 48.500754, lng: 21.912757 },
    { lat: 48.500436, lng: 21.913174 },
    { lat: 48.500234, lng: 21.913481 },
    { lat: 48.500079, lng: 21.913717 },
    { lat: 48.499996, lng: 21.91392 },
    { lat: 48.499893, lng: 21.91433 },
    { lat: 48.499856, lng: 21.914679 },
    { lat: 48.499851, lng: 21.914888 },
    { lat: 48.499834, lng: 21.915434 },
    { lat: 48.499835, lng: 21.915771 },
    { lat: 48.499819, lng: 21.916088 },
    { lat: 48.499804, lng: 21.916534 },
    { lat: 48.499796, lng: 21.917024 },
    { lat: 48.499772, lng: 21.917363 },
    { lat: 48.49975, lng: 21.917582 },
    { lat: 48.499692, lng: 21.917747 },
    { lat: 48.499483, lng: 21.917964 },
    { lat: 48.499261, lng: 21.918048 },
    { lat: 48.499193, lng: 21.918029 },
    { lat: 48.498991, lng: 21.917957 },
    { lat: 48.498911, lng: 21.917906 },
    { lat: 48.498664, lng: 21.917751 },
    { lat: 48.498627, lng: 21.917715 },
    { lat: 48.49833, lng: 21.917341 },
    { lat: 48.49819, lng: 21.917136 },
    { lat: 48.49798, lng: 21.916919 },
    { lat: 48.497814, lng: 21.916753 },
    { lat: 48.49768, lng: 21.916696 },
    { lat: 48.49748, lng: 21.916725 },
    { lat: 48.497342, lng: 21.916809 },
    { lat: 48.497256, lng: 21.916946 },
    { lat: 48.497222, lng: 21.917064 },
    { lat: 48.497224, lng: 21.917155 },
    { lat: 48.497219, lng: 21.917424 },
    { lat: 48.497254, lng: 21.91752 },
    { lat: 48.497256, lng: 21.917527 },
    { lat: 48.497315, lng: 21.917696 },
    { lat: 48.497382, lng: 21.917867 },
    { lat: 48.497624, lng: 21.918197 },
    { lat: 48.497786, lng: 21.918503 },
    { lat: 48.497908, lng: 21.91873 },
    { lat: 48.498055, lng: 21.91919 },
    { lat: 48.49807, lng: 21.919448 },
    { lat: 48.497972, lng: 21.919684 },
    { lat: 48.497912, lng: 21.919845 },
    { lat: 48.497825, lng: 21.919985 },
    { lat: 48.498056, lng: 21.920372 },
    { lat: 48.49808, lng: 21.920403 },
    { lat: 48.498089, lng: 21.920415 },
    { lat: 48.498229, lng: 21.920487 },
    { lat: 48.498371, lng: 21.9202 },
    { lat: 48.498444, lng: 21.920224 },
    { lat: 48.498712, lng: 21.920281 },
    { lat: 48.498804, lng: 21.920309 },
    { lat: 48.498814, lng: 21.920325 },
    { lat: 48.498834, lng: 21.920356 },
    { lat: 48.498908, lng: 21.920462 },
    { lat: 48.498948, lng: 21.92052 },
    { lat: 48.499048, lng: 21.920584 },
    { lat: 48.499141, lng: 21.920153 },
    { lat: 48.499253, lng: 21.920231 },
    { lat: 48.499504, lng: 21.920382 },
    { lat: 48.499525, lng: 21.920403 },
    { lat: 48.499624, lng: 21.920502 },
    { lat: 48.499979, lng: 21.920865 },
    { lat: 48.500337, lng: 21.921237 },
    { lat: 48.500408, lng: 21.92161 },
    { lat: 48.500543, lng: 21.921963 },
    { lat: 48.500617, lng: 21.922155 },
    { lat: 48.500652, lng: 21.922251 },
    { lat: 48.500657, lng: 21.92225 },
    { lat: 48.500819, lng: 21.922219 },
    { lat: 48.501533, lng: 21.922083 },
    { lat: 48.501906, lng: 21.922013 },
    { lat: 48.502063, lng: 21.922001 },
    { lat: 48.502204, lng: 21.92199 },
    { lat: 48.502356, lng: 21.921978 },
    { lat: 48.502817, lng: 21.921929 },
    { lat: 48.503015, lng: 21.921868 },
    { lat: 48.5050734, lng: 21.921591 },
    { lat: 48.5053703, lng: 21.9216326 },
    { lat: 48.5071626, lng: 21.9211434 },
    { lat: 48.5086488, lng: 21.9212765 },
    { lat: 48.5091103, lng: 21.9211994 },
    { lat: 48.5099238, lng: 21.9200491 },
    { lat: 48.510926, lng: 21.9196859 },
    { lat: 48.5134609, lng: 21.9181321 },
    { lat: 48.5143804, lng: 21.9178829 },
    { lat: 48.5150889, lng: 21.9183991 },
    { lat: 48.5156445, lng: 21.9185305 },
    { lat: 48.5166204, lng: 21.9182844 },
    { lat: 48.5189486, lng: 21.9180375 },
    { lat: 48.520516, lng: 21.9174679 },
    { lat: 48.5209892, lng: 21.9172268 },
    { lat: 48.5214929, lng: 21.9166844 },
    { lat: 48.5216235, lng: 21.9166464 },
    { lat: 48.5216335, lng: 21.9166885 },
    { lat: 48.5216997, lng: 21.9165917 },
    { lat: 48.5225059, lng: 21.9163939 },
    { lat: 48.5233706, lng: 21.9164658 },
    { lat: 48.5236103, lng: 21.9168658 },
    { lat: 48.5251729, lng: 21.9189572 },
    { lat: 48.5254216, lng: 21.9190026 },
    { lat: 48.525546, lng: 21.9192052 },
    { lat: 48.5262738, lng: 21.9193868 },
    { lat: 48.5270663, lng: 21.9191398 },
    { lat: 48.5280384, lng: 21.9185581 },
    { lat: 48.5287855, lng: 21.918252 },
    { lat: 48.5290339, lng: 21.9180613 },
    { lat: 48.5297719, lng: 21.917843 },
    { lat: 48.5301521, lng: 21.9172128 },
    { lat: 48.5302608, lng: 21.9167122 },
    { lat: 48.5302199, lng: 21.9161207 },
    { lat: 48.53106, lng: 21.914279 },
    { lat: 48.531311, lng: 21.9144161 },
    { lat: 48.5315126, lng: 21.9150985 },
    { lat: 48.5320994, lng: 21.9157911 },
    { lat: 48.5324949, lng: 21.9157226 },
    { lat: 48.5329171, lng: 21.9154198 },
    { lat: 48.5334964, lng: 21.9151555 },
    { lat: 48.5343577, lng: 21.9143837 },
    { lat: 48.5347121, lng: 21.9137628 },
    { lat: 48.5350535, lng: 21.9134691 },
    { lat: 48.5359488, lng: 21.9135949 },
    { lat: 48.5371627, lng: 21.9133736 },
    { lat: 48.5382612, lng: 21.9135282 },
    { lat: 48.5385306, lng: 21.9136753 },
    { lat: 48.539538, lng: 21.913614 },
    { lat: 48.5400739, lng: 21.9132701 },
    { lat: 48.5408802, lng: 21.9120139 },
    { lat: 48.5417552, lng: 21.9116019 },
    { lat: 48.5437618, lng: 21.9116745 },
    { lat: 48.5440485, lng: 21.911936 },
    { lat: 48.5443641, lng: 21.9125392 },
    { lat: 48.5446686, lng: 21.9126552 },
    { lat: 48.545006, lng: 21.9124718 },
    { lat: 48.5451367, lng: 21.9120834 },
    { lat: 48.5453234, lng: 21.9117659 },
    { lat: 48.5452975, lng: 21.9107448 },
    { lat: 48.5448013, lng: 21.9107385 },
    { lat: 48.5445058, lng: 21.9106209 },
    { lat: 48.5440162, lng: 21.9101683 },
    { lat: 48.543867, lng: 21.9098815 },
    { lat: 48.543778, lng: 21.9094272 },
    { lat: 48.5437622, lng: 21.9090752 },
    { lat: 48.5438048, lng: 21.9086631 },
    { lat: 48.543917, lng: 21.9082543 },
    { lat: 48.5447189, lng: 21.9063783 },
    { lat: 48.5448253, lng: 21.9057903 },
    { lat: 48.5448355, lng: 21.9048147 },
    { lat: 48.5450337, lng: 21.9047749 },
    { lat: 48.5456702, lng: 21.904238 },
    { lat: 48.5469059, lng: 21.9038065 },
    { lat: 48.5471724, lng: 21.9034936 },
    { lat: 48.547637, lng: 21.9027869 },
    { lat: 48.5483674, lng: 21.901332 },
    { lat: 48.5492109, lng: 21.9003473 },
    { lat: 48.5496665, lng: 21.8999106 },
    { lat: 48.5497418, lng: 21.8991048 },
    { lat: 48.5498219, lng: 21.8989383 },
    { lat: 48.5500767, lng: 21.8986491 },
    { lat: 48.5512245, lng: 21.8970262 },
    { lat: 48.5515512, lng: 21.8975247 },
    { lat: 48.5524741, lng: 21.8961675 },
    { lat: 48.5539103, lng: 21.894347 },
    { lat: 48.5541679, lng: 21.8947412 },
    { lat: 48.5545079, lng: 21.8943923 },
    { lat: 48.5546694, lng: 21.8946873 },
    { lat: 48.5558963, lng: 21.8934332 },
    { lat: 48.5572657, lng: 21.8921994 },
    { lat: 48.557326, lng: 21.8922723 },
    { lat: 48.5574476, lng: 21.892103 },
    { lat: 48.5576024, lng: 21.8923036 },
    { lat: 48.5583586, lng: 21.8903232 },
    { lat: 48.5586073, lng: 21.8906828 },
    { lat: 48.558858, lng: 21.8898341 },
    { lat: 48.5591993, lng: 21.8872107 },
    { lat: 48.5587382, lng: 21.8850135 },
    { lat: 48.558801, lng: 21.8849612 },
    { lat: 48.5588034, lng: 21.8845199 },
    { lat: 48.5591021, lng: 21.8842348 },
    { lat: 48.5589246, lng: 21.8830632 },
    { lat: 48.5590269, lng: 21.8829716 },
    { lat: 48.5580693, lng: 21.8797538 },
    { lat: 48.557724, lng: 21.8784318 },
    { lat: 48.5581408, lng: 21.8780606 },
    { lat: 48.5574764, lng: 21.8759261 },
    { lat: 48.5576842, lng: 21.8757833 },
    { lat: 48.5576585, lng: 21.8756985 },
    { lat: 48.5570359, lng: 21.874192 },
    { lat: 48.5568907, lng: 21.8737061 },
    { lat: 48.5564059, lng: 21.8741072 },
    { lat: 48.5561231, lng: 21.8732095 },
    { lat: 48.5552669, lng: 21.8737789 },
    { lat: 48.5550378, lng: 21.8730447 },
    { lat: 48.5548882, lng: 21.8731436 },
    { lat: 48.5546757, lng: 21.8724408 },
    { lat: 48.5540069, lng: 21.8729009 },
    { lat: 48.5532789, lng: 21.8709731 },
    { lat: 48.5528323, lng: 21.8695251 },
    { lat: 48.5524137, lng: 21.8683833 },
    { lat: 48.550739, lng: 21.8698885 },
    { lat: 48.5505707, lng: 21.869561 },
    { lat: 48.5492274, lng: 21.8706516 },
    { lat: 48.5486601, lng: 21.8691532 },
    { lat: 48.547958, lng: 21.8697686 },
    { lat: 48.5463805, lng: 21.8709861 },
    { lat: 48.5457257, lng: 21.8691789 },
    { lat: 48.5453524, lng: 21.8677908 },
    { lat: 48.5438147, lng: 21.8685475 },
    { lat: 48.5435299, lng: 21.8671329 },
    { lat: 48.5426053, lng: 21.8677599 },
    { lat: 48.5413031, lng: 21.8685231 },
    { lat: 48.5411588, lng: 21.8680722 },
    { lat: 48.5410205, lng: 21.8675516 },
    { lat: 48.5406893, lng: 21.8663073 },
    { lat: 48.5397476, lng: 21.8670073 },
    { lat: 48.5384772, lng: 21.8635494 },
    { lat: 48.5379137, lng: 21.8618879 },
    { lat: 48.5376342, lng: 21.8611707 },
    { lat: 48.5374904, lng: 21.8609218 },
    { lat: 48.5379933, lng: 21.8598263 },
    { lat: 48.5380198, lng: 21.8594552 },
    { lat: 48.5378242, lng: 21.8591563 },
    { lat: 48.5373428, lng: 21.8587744 },
    { lat: 48.5369305, lng: 21.8583161 },
    { lat: 48.5363382, lng: 21.8575097 },
    { lat: 48.5356222, lng: 21.8561828 },
    { lat: 48.534976, lng: 21.8541652 },
    { lat: 48.5348432, lng: 21.8534594 },
    { lat: 48.5350907, lng: 21.8533285 },
    { lat: 48.5359516, lng: 21.8525163 },
    { lat: 48.5363369, lng: 21.8517997 },
    { lat: 48.536561, lng: 21.8511099 },
    { lat: 48.5372095, lng: 21.8484289 },
    { lat: 48.5370929, lng: 21.8482234 },
    { lat: 48.5369749, lng: 21.8477085 },
    { lat: 48.5367698, lng: 21.8471341 },
    { lat: 48.5369439, lng: 21.8469921 },
    { lat: 48.5371209, lng: 21.8467449 },
    { lat: 48.5375084, lng: 21.8459412 },
    { lat: 48.5377087, lng: 21.8450717 },
    { lat: 48.5376418, lng: 21.8440923 },
    { lat: 48.5375563, lng: 21.8435293 },
    { lat: 48.5372204, lng: 21.842332 },
    { lat: 48.5369308, lng: 21.8417076 },
    { lat: 48.5366069, lng: 21.8414916 },
    { lat: 48.5363859, lng: 21.8414343 },
    { lat: 48.535336, lng: 21.8418582 },
    { lat: 48.5348662, lng: 21.8417867 },
    { lat: 48.5344618, lng: 21.8415588 },
    { lat: 48.534206, lng: 21.8412739 },
    { lat: 48.534013, lng: 21.8409051 },
    { lat: 48.5338091, lng: 21.840025 },
    { lat: 48.5337145, lng: 21.8390313 },
    { lat: 48.5336293, lng: 21.8375667 },
    { lat: 48.533625, lng: 21.83562 },
  ],
  ZemplínskeKopčany: [
    { lat: 48.5853562, lng: 21.8691473 },
    { lat: 48.5857983, lng: 21.8706868 },
    { lat: 48.5860108, lng: 21.8714252 },
    { lat: 48.5854312, lng: 21.8722013 },
    { lat: 48.5859559, lng: 21.873401 },
    { lat: 48.5870638, lng: 21.876379 },
    { lat: 48.5868884, lng: 21.8765185 },
    { lat: 48.5874683, lng: 21.8779442 },
    { lat: 48.5879078, lng: 21.879162 },
    { lat: 48.5879896, lng: 21.8795372 },
    { lat: 48.5880834, lng: 21.8803025 },
    { lat: 48.5881978, lng: 21.8836795 },
    { lat: 48.5882844, lng: 21.888534 },
    { lat: 48.5883406, lng: 21.8893246 },
    { lat: 48.5883518, lng: 21.8918814 },
    { lat: 48.5883966, lng: 21.8925384 },
    { lat: 48.5887225, lng: 21.8938926 },
    { lat: 48.5892241, lng: 21.8964478 },
    { lat: 48.5896538, lng: 21.8983117 },
    { lat: 48.5893869, lng: 21.8985137 },
    { lat: 48.5884466, lng: 21.898433 },
    { lat: 48.586803, lng: 21.8999005 },
    { lat: 48.5879932, lng: 21.9025126 },
    { lat: 48.5876123, lng: 21.9027968 },
    { lat: 48.5874866, lng: 21.9024132 },
    { lat: 48.587249, lng: 21.9024898 },
    { lat: 48.586738, lng: 21.9029201 },
    { lat: 48.5870266, lng: 21.9036532 },
    { lat: 48.5872379, lng: 21.9044025 },
    { lat: 48.587489, lng: 21.9065418 },
    { lat: 48.586251, lng: 21.9069197 },
    { lat: 48.5869142, lng: 21.9101386 },
    { lat: 48.5860796, lng: 21.9105011 },
    { lat: 48.5866308, lng: 21.9133896 },
    { lat: 48.5870392, lng: 21.9146343 },
    { lat: 48.5858164, lng: 21.9158384 },
    { lat: 48.5860509, lng: 21.9167964 },
    { lat: 48.586859, lng: 21.9195671 },
    { lat: 48.5869494, lng: 21.9202176 },
    { lat: 48.5867302, lng: 21.9203133 },
    { lat: 48.5868278, lng: 21.9209686 },
    { lat: 48.5871769, lng: 21.9255762 },
    { lat: 48.586582, lng: 21.9251891 },
    { lat: 48.5858607, lng: 21.9245098 },
    { lat: 48.585249, lng: 21.9246521 },
    { lat: 48.5846189, lng: 21.9246516 },
    { lat: 48.5836809, lng: 21.925149 },
    { lat: 48.5830923, lng: 21.9253653 },
    { lat: 48.5830927, lng: 21.9259153 },
    { lat: 48.5831629, lng: 21.9266074 },
    { lat: 48.5830667, lng: 21.9271686 },
    { lat: 48.5827881, lng: 21.9280781 },
    { lat: 48.5831456, lng: 21.9283369 },
    { lat: 48.5831662, lng: 21.9291896 },
    { lat: 48.5829054, lng: 21.9293853 },
    { lat: 48.5824023, lng: 21.9293757 },
    { lat: 48.5822848, lng: 21.9299335 },
    { lat: 48.5820705, lng: 21.930543 },
    { lat: 48.5816258, lng: 21.9315525 },
    { lat: 48.5816775, lng: 21.9322673 },
    { lat: 48.580807, lng: 21.9328147 },
    { lat: 48.5807147, lng: 21.9338894 },
    { lat: 48.5809332, lng: 21.9339427 },
    { lat: 48.5808935, lng: 21.9343809 },
    { lat: 48.5801603, lng: 21.9359128 },
    { lat: 48.5800687, lng: 21.9363856 },
    { lat: 48.5798661, lng: 21.9370118 },
    { lat: 48.5798484, lng: 21.9379688 },
    { lat: 48.5799701, lng: 21.9380653 },
    { lat: 48.5801445, lng: 21.9383343 },
    { lat: 48.5803276, lng: 21.9387267 },
    { lat: 48.5804944, lng: 21.9397284 },
    { lat: 48.5806882, lng: 21.9399639 },
    { lat: 48.580757, lng: 21.940461 },
    { lat: 48.5808419, lng: 21.9406629 },
    { lat: 48.5813328, lng: 21.9429703 },
    { lat: 48.5819598, lng: 21.942988 },
    { lat: 48.5820564, lng: 21.9437467 },
    { lat: 48.5824795, lng: 21.9450558 },
    { lat: 48.5818845, lng: 21.9458666 },
    { lat: 48.581707, lng: 21.9459705 },
    { lat: 48.5813284, lng: 21.9460378 },
    { lat: 48.5808887, lng: 21.9463101 },
    { lat: 48.5803971, lng: 21.9470312 },
    { lat: 48.5793342, lng: 21.9473065 },
    { lat: 48.5786506, lng: 21.947007 },
    { lat: 48.5782768, lng: 21.9467732 },
    { lat: 48.5781781, lng: 21.9466431 },
    { lat: 48.5770708, lng: 21.9465034 },
    { lat: 48.5762384, lng: 21.946501 },
    { lat: 48.5756063, lng: 21.9461024 },
    { lat: 48.5753962, lng: 21.9462038 },
    { lat: 48.5755327, lng: 21.9464885 },
    { lat: 48.5766343, lng: 21.9479852 },
    { lat: 48.5772582, lng: 21.9492763 },
    { lat: 48.5776394, lng: 21.9496703 },
    { lat: 48.5781755, lng: 21.9500961 },
    { lat: 48.5789199, lng: 21.9515304 },
    { lat: 48.5795853, lng: 21.9519997 },
    { lat: 48.5800459, lng: 21.9522425 },
    { lat: 48.5809567, lng: 21.9522755 },
    { lat: 48.5817422, lng: 21.9519648 },
    { lat: 48.5818494, lng: 21.9519818 },
    { lat: 48.5823787, lng: 21.952504 },
    { lat: 48.5833983, lng: 21.95261 },
    { lat: 48.5838254, lng: 21.9524554 },
    { lat: 48.584067, lng: 21.9522178 },
    { lat: 48.5843649, lng: 21.9518002 },
    { lat: 48.5849037, lng: 21.9513528 },
    { lat: 48.585294, lng: 21.9508976 },
    { lat: 48.5856359, lng: 21.9507105 },
    { lat: 48.585875, lng: 21.9507868 },
    { lat: 48.5862074, lng: 21.9514761 },
    { lat: 48.5862738, lng: 21.9517474 },
    { lat: 48.5863041, lng: 21.9524727 },
    { lat: 48.58617, lng: 21.9529155 },
    { lat: 48.5858409, lng: 21.9536551 },
    { lat: 48.5858494, lng: 21.9538611 },
    { lat: 48.5860477, lng: 21.9541874 },
    { lat: 48.5866123, lng: 21.9540908 },
    { lat: 48.5869695, lng: 21.9538026 },
    { lat: 48.5876871, lng: 21.9536593 },
    { lat: 48.5882333, lng: 21.9534123 },
    { lat: 48.5888703, lng: 21.9528876 },
    { lat: 48.5890722, lng: 21.9529959 },
    { lat: 48.5897688, lng: 21.9537705 },
    { lat: 48.5899385, lng: 21.9538046 },
    { lat: 48.590121, lng: 21.9537307 },
    { lat: 48.5902705, lng: 21.953546 },
    { lat: 48.5903443, lng: 21.9533478 },
    { lat: 48.590356, lng: 21.952823 },
    { lat: 48.590016, lng: 21.9521791 },
    { lat: 48.5899567, lng: 21.9519657 },
    { lat: 48.5900658, lng: 21.9516493 },
    { lat: 48.5904366, lng: 21.9512253 },
    { lat: 48.5909911, lng: 21.9509053 },
    { lat: 48.5914648, lng: 21.9505032 },
    { lat: 48.5925889, lng: 21.9502891 },
    { lat: 48.5930201, lng: 21.9500756 },
    { lat: 48.5936428, lng: 21.9500996 },
    { lat: 48.5938521, lng: 21.9499651 },
    { lat: 48.5945916, lng: 21.9487188 },
    { lat: 48.5948329, lng: 21.9485612 },
    { lat: 48.5949737, lng: 21.9473591 },
    { lat: 48.5953109, lng: 21.9472596 },
    { lat: 48.5958924, lng: 21.9469334 },
    { lat: 48.5958927, lng: 21.9468502 },
    { lat: 48.5964783, lng: 21.9463812 },
    { lat: 48.5962527, lng: 21.9459082 },
    { lat: 48.5963169, lng: 21.9452339 },
    { lat: 48.5964613, lng: 21.9446279 },
    { lat: 48.5968553, lng: 21.9435074 },
    { lat: 48.5971805, lng: 21.9430592 },
    { lat: 48.5975618, lng: 21.9422073 },
    { lat: 48.5981216, lng: 21.9417422 },
    { lat: 48.5983467, lng: 21.9417912 },
    { lat: 48.5983422, lng: 21.9413383 },
    { lat: 48.598507, lng: 21.9403306 },
    { lat: 48.5985602, lng: 21.9396675 },
    { lat: 48.5987766, lng: 21.9393311 },
    { lat: 48.599073, lng: 21.9390782 },
    { lat: 48.5994535, lng: 21.9378278 },
    { lat: 48.5995808, lng: 21.9370273 },
    { lat: 48.5996194, lng: 21.9364166 },
    { lat: 48.5996096, lng: 21.9357515 },
    { lat: 48.5995509, lng: 21.9354324 },
    { lat: 48.5995334, lng: 21.9349604 },
    { lat: 48.5995511, lng: 21.9341437 },
    { lat: 48.5994948, lng: 21.933066 },
    { lat: 48.5988221, lng: 21.9326648 },
    { lat: 48.5988567, lng: 21.9304578 },
    { lat: 48.5989949, lng: 21.9296477 },
    { lat: 48.5980024, lng: 21.9295022 },
    { lat: 48.5979057, lng: 21.9293247 },
    { lat: 48.5977632, lng: 21.9292073 },
    { lat: 48.5974246, lng: 21.9291581 },
    { lat: 48.5970817, lng: 21.9262662 },
    { lat: 48.5974451, lng: 21.9260475 },
    { lat: 48.5976785, lng: 21.9257935 },
    { lat: 48.5972316, lng: 21.92489 },
    { lat: 48.596707, lng: 21.9232187 },
    { lat: 48.5976778, lng: 21.9228166 },
    { lat: 48.5973609, lng: 21.9207385 },
    { lat: 48.5971459, lng: 21.9204587 },
    { lat: 48.5968343, lng: 21.9177424 },
    { lat: 48.5965893, lng: 21.9145923 },
    { lat: 48.5973971, lng: 21.9108521 },
    { lat: 48.59707, lng: 21.9093569 },
    { lat: 48.5975319, lng: 21.9092027 },
    { lat: 48.596985, lng: 21.9054238 },
    { lat: 48.5971108, lng: 21.9053284 },
    { lat: 48.5968077, lng: 21.9042434 },
    { lat: 48.597224, lng: 21.9040644 },
    { lat: 48.596245, lng: 21.8995805 },
    { lat: 48.5964564, lng: 21.8994862 },
    { lat: 48.596381, lng: 21.8990428 },
    { lat: 48.5965157, lng: 21.8989906 },
    { lat: 48.597006, lng: 21.8976515 },
    { lat: 48.5971703, lng: 21.8969941 },
    { lat: 48.5968584, lng: 21.8954735 },
    { lat: 48.5962138, lng: 21.8911002 },
    { lat: 48.5960723, lng: 21.8904494 },
    { lat: 48.5962242, lng: 21.8903244 },
    { lat: 48.595917, lng: 21.8892127 },
    { lat: 48.5960047, lng: 21.8891646 },
    { lat: 48.5958332, lng: 21.8884854 },
    { lat: 48.5963256, lng: 21.8880318 },
    { lat: 48.596099, lng: 21.8871692 },
    { lat: 48.5973826, lng: 21.8861981 },
    { lat: 48.5981759, lng: 21.8856811 },
    { lat: 48.5982363, lng: 21.8858422 },
    { lat: 48.5998319, lng: 21.8839586 },
    { lat: 48.5999816, lng: 21.8841813 },
    { lat: 48.6002013, lng: 21.8839777 },
    { lat: 48.6007367, lng: 21.8846781 },
    { lat: 48.6015318, lng: 21.8843533 },
    { lat: 48.6026353, lng: 21.8840405 },
    { lat: 48.6029189, lng: 21.8830806 },
    { lat: 48.6033352, lng: 21.8821568 },
    { lat: 48.6035579, lng: 21.8818005 },
    { lat: 48.6042189, lng: 21.8809896 },
    { lat: 48.6047501, lng: 21.8805109 },
    { lat: 48.605009, lng: 21.8801638 },
    { lat: 48.605423, lng: 21.8789304 },
    { lat: 48.6055125, lng: 21.8787781 },
    { lat: 48.6056315, lng: 21.8788572 },
    { lat: 48.6058967, lng: 21.8776601 },
    { lat: 48.6063502, lng: 21.8753811 },
    { lat: 48.6051751, lng: 21.8739056 },
    { lat: 48.6047562, lng: 21.8727929 },
    { lat: 48.6038503, lng: 21.8712055 },
    { lat: 48.6031554, lng: 21.8701682 },
    { lat: 48.602119, lng: 21.8692743 },
    { lat: 48.600869, lng: 21.8680043 },
    { lat: 48.6003383, lng: 21.8677935 },
    { lat: 48.5999017, lng: 21.8674913 },
    { lat: 48.5996199, lng: 21.8672035 },
    { lat: 48.5989361, lng: 21.8656752 },
    { lat: 48.5984595, lng: 21.8642902 },
    { lat: 48.5982105, lng: 21.8637944 },
    { lat: 48.5967102, lng: 21.8632071 },
    { lat: 48.5959666, lng: 21.8627188 },
    { lat: 48.5956062, lng: 21.8622725 },
    { lat: 48.5954672, lng: 21.8622039 },
    { lat: 48.5952843, lng: 21.8621769 },
    { lat: 48.5947463, lng: 21.8622962 },
    { lat: 48.5941371, lng: 21.8621473 },
    { lat: 48.5937513, lng: 21.8621077 },
    { lat: 48.593329, lng: 21.8622237 },
    { lat: 48.5929047, lng: 21.8624344 },
    { lat: 48.5921801, lng: 21.8629163 },
    { lat: 48.5913717, lng: 21.8631903 },
    { lat: 48.5906904, lng: 21.8635589 },
    { lat: 48.5904362, lng: 21.863611 },
    { lat: 48.59006, lng: 21.8635022 },
    { lat: 48.5904071, lng: 21.864439 },
    { lat: 48.5897565, lng: 21.8650008 },
    { lat: 48.5880455, lng: 21.8661426 },
    { lat: 48.5872939, lng: 21.8668023 },
    { lat: 48.5851931, lng: 21.8681405 },
    { lat: 48.5853562, lng: 21.8691473 },
  ],
  Ižkovce: [
    { lat: 48.5575894, lng: 21.9300942 },
    { lat: 48.55766, lng: 21.9302117 },
    { lat: 48.5572936, lng: 21.9309382 },
    { lat: 48.5572241, lng: 21.9318495 },
    { lat: 48.5573424, lng: 21.9330588 },
    { lat: 48.5579944, lng: 21.9340692 },
    { lat: 48.5572329, lng: 21.9351567 },
    { lat: 48.553197, lng: 21.9383123 },
    { lat: 48.5517554, lng: 21.9400026 },
    { lat: 48.5511445, lng: 21.94063 },
    { lat: 48.5474769, lng: 21.945111 },
    { lat: 48.5485478, lng: 21.9465013 },
    { lat: 48.5491057, lng: 21.9475743 },
    { lat: 48.5483548, lng: 21.9483245 },
    { lat: 48.5466624, lng: 21.9496064 },
    { lat: 48.5470111, lng: 21.9511382 },
    { lat: 48.5456121, lng: 21.9521061 },
    { lat: 48.5458538, lng: 21.9547127 },
    { lat: 48.5465199, lng: 21.9586427 },
    { lat: 48.5465768, lng: 21.959347 },
    { lat: 48.5468558, lng: 21.9611041 },
    { lat: 48.546978, lng: 21.9639418 },
    { lat: 48.5436338, lng: 21.9655424 },
    { lat: 48.5456201, lng: 21.968417 },
    { lat: 48.5454031, lng: 21.9690566 },
    { lat: 48.5465749, lng: 21.973074 },
    { lat: 48.5474624, lng: 21.9762992 },
    { lat: 48.5474637, lng: 21.9763037 },
    { lat: 48.5476847, lng: 21.9761869 },
    { lat: 48.5463469, lng: 21.9705529 },
    { lat: 48.548424, lng: 21.9694326 },
    { lat: 48.5488847, lng: 21.9713652 },
    { lat: 48.5494788, lng: 21.9717742 },
    { lat: 48.5513868, lng: 21.9672397 },
    { lat: 48.5516431, lng: 21.9632518 },
    { lat: 48.5515974, lng: 21.9632704 },
    { lat: 48.5513049, lng: 21.9620495 },
    { lat: 48.5514037, lng: 21.9619924 },
    { lat: 48.5514064, lng: 21.9618743 },
    { lat: 48.5518094, lng: 21.9617476 },
    { lat: 48.552522, lng: 21.9648445 },
    { lat: 48.5525981, lng: 21.9647863 },
    { lat: 48.5530276, lng: 21.9665869 },
    { lat: 48.5531494, lng: 21.9670981 },
    { lat: 48.553092, lng: 21.9671422 },
    { lat: 48.5534875, lng: 21.9688002 },
    { lat: 48.5535778, lng: 21.9689443 },
    { lat: 48.5537789, lng: 21.969799 },
    { lat: 48.5537371, lng: 21.9698326 },
    { lat: 48.5544464, lng: 21.9728075 },
    { lat: 48.5546117, lng: 21.9727133 },
    { lat: 48.5548318, lng: 21.9724748 },
    { lat: 48.5552754, lng: 21.9731163 },
    { lat: 48.5559968, lng: 21.9725399 },
    { lat: 48.556044, lng: 21.9726754 },
    { lat: 48.5587105, lng: 21.9709188 },
    { lat: 48.558519, lng: 21.9700488 },
    { lat: 48.5585484, lng: 21.9699151 },
    { lat: 48.5587222, lng: 21.9698968 },
    { lat: 48.5587879, lng: 21.9698164 },
    { lat: 48.5594019, lng: 21.9691319 },
    { lat: 48.5587794, lng: 21.9692941 },
    { lat: 48.5581411, lng: 21.9691862 },
    { lat: 48.5570947, lng: 21.9687295 },
    { lat: 48.5565938, lng: 21.9683997 },
    { lat: 48.5563341, lng: 21.9678625 },
    { lat: 48.5564924, lng: 21.9674019 },
    { lat: 48.5572025, lng: 21.967043 },
    { lat: 48.5572869, lng: 21.9665478 },
    { lat: 48.5570456, lng: 21.9662485 },
    { lat: 48.5564616, lng: 21.9667026 },
    { lat: 48.5561912, lng: 21.9666838 },
    { lat: 48.555605, lng: 21.9661849 },
    { lat: 48.5556069, lng: 21.9654429 },
    { lat: 48.5561034, lng: 21.9649128 },
    { lat: 48.5568851, lng: 21.9647313 },
    { lat: 48.5578602, lng: 21.9648961 },
    { lat: 48.5583509, lng: 21.9650907 },
    { lat: 48.5587142, lng: 21.9651327 },
    { lat: 48.5593465, lng: 21.9647743 },
    { lat: 48.5596513, lng: 21.9644499 },
    { lat: 48.5600173, lng: 21.963242 },
    { lat: 48.5597922, lng: 21.9617947 },
    { lat: 48.5597783, lng: 21.9612344 },
    { lat: 48.5599076, lng: 21.9609632 },
    { lat: 48.56011, lng: 21.9607806 },
    { lat: 48.56034, lng: 21.9607484 },
    { lat: 48.5605917, lng: 21.9608315 },
    { lat: 48.5611809, lng: 21.9612713 },
    { lat: 48.5616576, lng: 21.9609879 },
    { lat: 48.562723, lng: 21.9609903 },
    { lat: 48.5620246, lng: 21.9576039 },
    { lat: 48.5617347, lng: 21.9571245 },
    { lat: 48.5610563, lng: 21.9546867 },
    { lat: 48.5604985, lng: 21.9540148 },
    { lat: 48.5592494, lng: 21.9527928 },
    { lat: 48.5590493, lng: 21.9522544 },
    { lat: 48.5591075, lng: 21.9513849 },
    { lat: 48.559205, lng: 21.9512302 },
    { lat: 48.5598005, lng: 21.9510242 },
    { lat: 48.5602621, lng: 21.9513052 },
    { lat: 48.561325, lng: 21.950898 },
    { lat: 48.5626642, lng: 21.9479897 },
    { lat: 48.5630013, lng: 21.9467083 },
    { lat: 48.5631163, lng: 21.9460937 },
    { lat: 48.563182, lng: 21.9453956 },
    { lat: 48.563407, lng: 21.9448519 },
    { lat: 48.5637248, lng: 21.9445962 },
    { lat: 48.5641534, lng: 21.9447768 },
    { lat: 48.5647746, lng: 21.9456455 },
    { lat: 48.5653878, lng: 21.9460507 },
    { lat: 48.5657492, lng: 21.9461559 },
    { lat: 48.5660411, lng: 21.9460836 },
    { lat: 48.5662777, lng: 21.9457881 },
    { lat: 48.5663893, lng: 21.9455282 },
    { lat: 48.566356, lng: 21.9451781 },
    { lat: 48.5662718, lng: 21.9449197 },
    { lat: 48.5656386, lng: 21.9442499 },
    { lat: 48.5655435, lng: 21.94405 },
    { lat: 48.5654218, lng: 21.9434563 },
    { lat: 48.5654038, lng: 21.9427825 },
    { lat: 48.5654081, lng: 21.9423692 },
    { lat: 48.565477, lng: 21.9414798 },
    { lat: 48.5655622, lng: 21.9410221 },
    { lat: 48.5657756, lng: 21.9402424 },
    { lat: 48.5661198, lng: 21.9395199 },
    { lat: 48.5668086, lng: 21.9387291 },
    { lat: 48.5679654, lng: 21.9380249 },
    { lat: 48.5683926, lng: 21.9374724 },
    { lat: 48.5685152, lng: 21.9371152 },
    { lat: 48.5685628, lng: 21.9371693 },
    { lat: 48.5686308, lng: 21.9370148 },
    { lat: 48.5686072, lng: 21.936742 },
    { lat: 48.5684825, lng: 21.936348 },
    { lat: 48.568201, lng: 21.9360263 },
    { lat: 48.5677258, lng: 21.935869 },
    { lat: 48.5669543, lng: 21.9359289 },
    { lat: 48.5660705, lng: 21.9361285 },
    { lat: 48.5640571, lng: 21.9367692 },
    { lat: 48.563295, lng: 21.936743 },
    { lat: 48.5628999, lng: 21.9366007 },
    { lat: 48.5626234, lng: 21.9363391 },
    { lat: 48.5624058, lng: 21.9359618 },
    { lat: 48.5623405, lng: 21.9357386 },
    { lat: 48.5622937, lng: 21.9351329 },
    { lat: 48.562333, lng: 21.9346327 },
    { lat: 48.5625112, lng: 21.9339843 },
    { lat: 48.5627335, lng: 21.9334211 },
    { lat: 48.5628445, lng: 21.9326967 },
    { lat: 48.5627213, lng: 21.9316883 },
    { lat: 48.5624949, lng: 21.9312231 },
    { lat: 48.5620214, lng: 21.9308275 },
    { lat: 48.561344, lng: 21.9305948 },
    { lat: 48.5597826, lng: 21.9305554 },
    { lat: 48.5594961, lng: 21.9303952 },
    { lat: 48.5590466, lng: 21.9300132 },
    { lat: 48.5582095, lng: 21.9290275 },
    { lat: 48.557622, lng: 21.9300388 },
    { lat: 48.5575894, lng: 21.9300942 },
  ],
  KrišovskáLiesková: [
    { lat: 48.5749293, lng: 22.0201735 },
    { lat: 48.5751199, lng: 22.0214876 },
    { lat: 48.5748544, lng: 22.0215812 },
    { lat: 48.5696308, lng: 22.0251617 },
    { lat: 48.5688878, lng: 22.0265805 },
    { lat: 48.5672461, lng: 22.0274895 },
    { lat: 48.5670994, lng: 22.0275125 },
    { lat: 48.5672749, lng: 22.0320996 },
    { lat: 48.5673385, lng: 22.032771 },
    { lat: 48.5673078, lng: 22.0328554 },
    { lat: 48.5672444, lng: 22.0329321 },
    { lat: 48.5665679, lng: 22.0328683 },
    { lat: 48.5657517, lng: 22.0327444 },
    { lat: 48.5652085, lng: 22.032802 },
    { lat: 48.5648116, lng: 22.0329499 },
    { lat: 48.5635585, lng: 22.0336888 },
    { lat: 48.5633327, lng: 22.0338951 },
    { lat: 48.5620824, lng: 22.0359084 },
    { lat: 48.5609469, lng: 22.0372415 },
    { lat: 48.5602354, lng: 22.0383283 },
    { lat: 48.5600599, lng: 22.0386082 },
    { lat: 48.5648966, lng: 22.0426821 },
    { lat: 48.5629925, lng: 22.0473609 },
    { lat: 48.5622676, lng: 22.0487374 },
    { lat: 48.5640277, lng: 22.0510987 },
    { lat: 48.5642966, lng: 22.0513329 },
    { lat: 48.565573, lng: 22.0521095 },
    { lat: 48.5674811, lng: 22.0540349 },
    { lat: 48.5678706, lng: 22.0545191 },
    { lat: 48.5681207, lng: 22.0551355 },
    { lat: 48.5682896, lng: 22.0557553 },
    { lat: 48.56844, lng: 22.0592457 },
    { lat: 48.5685148, lng: 22.0604876 },
    { lat: 48.5700944, lng: 22.0598885 },
    { lat: 48.5721285, lng: 22.0583464 },
    { lat: 48.5748971, lng: 22.0548071 },
    { lat: 48.5759029, lng: 22.053827 },
    { lat: 48.5790293, lng: 22.0523455 },
    { lat: 48.582091, lng: 22.0500499 },
    { lat: 48.5821222, lng: 22.0500265 },
    { lat: 48.5832081, lng: 22.0524267 },
    { lat: 48.5843531, lng: 22.0552707 },
    { lat: 48.5856818, lng: 22.0585515 },
    { lat: 48.5918449, lng: 22.0544181 },
    { lat: 48.5916614, lng: 22.0541282 },
    { lat: 48.5925356, lng: 22.0530641 },
    { lat: 48.5925614, lng: 22.0531096 },
    { lat: 48.5933975, lng: 22.0522259 },
    { lat: 48.6051561, lng: 22.0416838 },
    { lat: 48.6060263, lng: 22.0288394 },
    { lat: 48.6062736, lng: 22.0239709 },
    { lat: 48.6067664, lng: 22.0230916 },
    { lat: 48.6076971, lng: 22.0228806 },
    { lat: 48.6081051, lng: 22.0225896 },
    { lat: 48.6083706, lng: 22.0217118 },
    { lat: 48.6089723, lng: 22.0212904 },
    { lat: 48.6096952, lng: 22.0205771 },
    { lat: 48.6105514, lng: 22.0203004 },
    { lat: 48.6114098, lng: 22.0224334 },
    { lat: 48.6122994, lng: 22.0218221 },
    { lat: 48.6129143, lng: 22.0212456 },
    { lat: 48.6138743, lng: 22.0199244 },
    { lat: 48.6119531, lng: 22.0161513 },
    { lat: 48.6115834, lng: 22.0156158 },
    { lat: 48.6108494, lng: 22.0141602 },
    { lat: 48.6084675, lng: 22.0106876 },
    { lat: 48.6084569, lng: 22.010573 },
    { lat: 48.6077361, lng: 22.0095246 },
    { lat: 48.6052391, lng: 22.0042087 },
    { lat: 48.605267, lng: 22.0041548 },
    { lat: 48.6054018, lng: 22.0028606 },
    { lat: 48.60512, lng: 22.0006373 },
    { lat: 48.6049375, lng: 21.9994692 },
    { lat: 48.6047719, lng: 21.9988717 },
    { lat: 48.6045303, lng: 21.9983708 },
    { lat: 48.604234, lng: 21.9979516 },
    { lat: 48.603756, lng: 21.9974927 },
    { lat: 48.601344, lng: 21.9962522 },
    { lat: 48.6007441, lng: 21.995642 },
    { lat: 48.5999207, lng: 21.9943666 },
    { lat: 48.5978758, lng: 21.9902572 },
    { lat: 48.5981653, lng: 21.9900441 },
    { lat: 48.5978295, lng: 21.9890625 },
    { lat: 48.5970191, lng: 21.9880441 },
    { lat: 48.5929826, lng: 21.9852495 },
    { lat: 48.5913037, lng: 21.984914 },
    { lat: 48.5916064, lng: 21.9884733 },
    { lat: 48.5939831, lng: 21.9926684 },
    { lat: 48.5948558, lng: 21.995173 },
    { lat: 48.5951896, lng: 21.9963047 },
    { lat: 48.5962531, lng: 21.9992981 },
    { lat: 48.5965741, lng: 22.0000536 },
    { lat: 48.5953275, lng: 22.0018983 },
    { lat: 48.5947276, lng: 22.002786 },
    { lat: 48.5939358, lng: 22.0007252 },
    { lat: 48.5939591, lng: 22.0005345 },
    { lat: 48.5927134, lng: 21.9972649 },
    { lat: 48.5925345, lng: 21.9971665 },
    { lat: 48.59232, lng: 21.997455 },
    { lat: 48.5917577, lng: 21.9991146 },
    { lat: 48.5904324, lng: 22.001123 },
    { lat: 48.589863, lng: 22.003236 },
    { lat: 48.588567, lng: 22.005663 },
    { lat: 48.5863654, lng: 22.0094726 },
    { lat: 48.5860882, lng: 22.0100551 },
    { lat: 48.5859564, lng: 22.0100922 },
    { lat: 48.5857916, lng: 22.0098409 },
    { lat: 48.5854879, lng: 22.0087862 },
    { lat: 48.5828889, lng: 22.0101024 },
    { lat: 48.5836587, lng: 22.0129272 },
    { lat: 48.5831844, lng: 22.013591 },
    { lat: 48.5831246, lng: 22.013768 },
    { lat: 48.5830673, lng: 22.0144143 },
    { lat: 48.5829408, lng: 22.0143413 },
    { lat: 48.5828198, lng: 22.014418 },
    { lat: 48.5827749, lng: 22.0146071 },
    { lat: 48.5820526, lng: 22.0155229 },
    { lat: 48.5815207, lng: 22.0157595 },
    { lat: 48.5806934, lng: 22.0155507 },
    { lat: 48.5803595, lng: 22.0152398 },
    { lat: 48.5785975, lng: 22.0173014 },
    { lat: 48.5786373, lng: 22.0178307 },
    { lat: 48.5761027, lng: 22.019802 },
    { lat: 48.575436, lng: 22.0206066 },
    { lat: 48.5752514, lng: 22.0200526 },
    { lat: 48.5749538, lng: 22.0201643 },
    { lat: 48.5749293, lng: 22.0201735 },
  ],
  Čičarovce: [
    { lat: 48.5749293, lng: 22.0201735 },
    { lat: 48.5744858, lng: 22.0179952 },
    { lat: 48.5695281, lng: 22.0214083 },
    { lat: 48.568415, lng: 22.0182465 },
    { lat: 48.5667303, lng: 22.0191869 },
    { lat: 48.5667021, lng: 22.0180767 },
    { lat: 48.5668816, lng: 22.0170865 },
    { lat: 48.5664901, lng: 22.0171384 },
    { lat: 48.5664686, lng: 22.0170383 },
    { lat: 48.56373, lng: 22.0177504 },
    { lat: 48.5619259, lng: 22.0139927 },
    { lat: 48.559041, lng: 22.0077536 },
    { lat: 48.5587596, lng: 22.0070647 },
    { lat: 48.5562784, lng: 22.0018464 },
    { lat: 48.5527156, lng: 21.9945649 },
    { lat: 48.5532277, lng: 21.9939746 },
    { lat: 48.5510951, lng: 21.9895167 },
    { lat: 48.5508864, lng: 21.9896299 },
    { lat: 48.5506255, lng: 21.9885331 },
    { lat: 48.5504986, lng: 21.9882689 },
    { lat: 48.5474166, lng: 21.9899276 },
    { lat: 48.5471622, lng: 21.9888431 },
    { lat: 48.5466585, lng: 21.9866964 },
    { lat: 48.5453759, lng: 21.9873742 },
    { lat: 48.5450446, lng: 21.9859858 },
    { lat: 48.5457695, lng: 21.9855941 },
    { lat: 48.5454625, lng: 21.9843207 },
    { lat: 48.545757, lng: 21.9841707 },
    { lat: 48.545186, lng: 21.981776 },
    { lat: 48.5464615, lng: 21.9810856 },
    { lat: 48.5456587, lng: 21.9776474 },
    { lat: 48.5455793, lng: 21.9772989 },
    { lat: 48.5446377, lng: 21.9779433 },
    { lat: 48.5448589, lng: 21.9788309 },
    { lat: 48.5420008, lng: 21.9792603 },
    { lat: 48.540305, lng: 21.9794258 },
    { lat: 48.5402969, lng: 21.9793204 },
    { lat: 48.5399429, lng: 21.9790652 },
    { lat: 48.5396201, lng: 21.9793378 },
    { lat: 48.5394865, lng: 21.9793013 },
    { lat: 48.5391668, lng: 21.9789756 },
    { lat: 48.5387632, lng: 21.9790827 },
    { lat: 48.5379142, lng: 21.9786408 },
    { lat: 48.5364507, lng: 21.9766005 },
    { lat: 48.5339783, lng: 21.975345 },
    { lat: 48.5336297, lng: 21.9749397 },
    { lat: 48.5330486, lng: 21.9730556 },
    { lat: 48.5326803, lng: 21.9724924 },
    { lat: 48.5303229, lng: 21.972111 },
    { lat: 48.530239, lng: 21.9729009 },
    { lat: 48.5299133, lng: 21.9732855 },
    { lat: 48.5295549, lng: 21.9733464 },
    { lat: 48.5289764, lng: 21.9730158 },
    { lat: 48.5284477, lng: 21.9732475 },
    { lat: 48.5278831, lng: 21.973845 },
    { lat: 48.5265693, lng: 21.9741193 },
    { lat: 48.5265212, lng: 21.9743563 },
    { lat: 48.5243618, lng: 21.9738317 },
    { lat: 48.5217596, lng: 21.9737426 },
    { lat: 48.5194704, lng: 21.9714365 },
    { lat: 48.5151531, lng: 21.974806 },
    { lat: 48.5151026, lng: 21.9755838 },
    { lat: 48.5147027, lng: 21.9763759 },
    { lat: 48.5143397, lng: 21.9763991 },
    { lat: 48.5116253, lng: 21.9748171 },
    { lat: 48.5112047, lng: 21.9748617 },
    { lat: 48.5104881, lng: 21.9761398 },
    { lat: 48.5100368, lng: 21.977629 },
    { lat: 48.5095279, lng: 21.9776118 },
    { lat: 48.509372, lng: 21.977732 },
    { lat: 48.50938, lng: 21.978458 },
    { lat: 48.509302, lng: 21.978546 },
    { lat: 48.509288, lng: 21.978543 },
    { lat: 48.509242, lng: 21.978534 },
    { lat: 48.509151, lng: 21.978517 },
    { lat: 48.509069, lng: 21.978501 },
    { lat: 48.509001, lng: 21.978862 },
    { lat: 48.50879, lng: 21.979298 },
    { lat: 48.508614, lng: 21.979362 },
    { lat: 48.508421, lng: 21.979329 },
    { lat: 48.508253, lng: 21.979187 },
    { lat: 48.507891, lng: 21.979099 },
    { lat: 48.507652, lng: 21.979226 },
    { lat: 48.507598, lng: 21.979316 },
    { lat: 48.5075, lng: 21.979483 },
    { lat: 48.507405, lng: 21.979843 },
    { lat: 48.507358, lng: 21.980761 },
    { lat: 48.507297, lng: 21.981678 },
    { lat: 48.507303, lng: 21.982415 },
    { lat: 48.507233, lng: 21.982791 },
    { lat: 48.507338, lng: 21.983689 },
    { lat: 48.507422, lng: 21.98444 },
    { lat: 48.507451, lng: 21.985183 },
    { lat: 48.507431, lng: 21.985531 },
    { lat: 48.50739, lng: 21.985883 },
    { lat: 48.507341, lng: 21.986157 },
    { lat: 48.507315, lng: 21.986304 },
    { lat: 48.507293, lng: 21.986447 },
    { lat: 48.507284, lng: 21.986506 },
    { lat: 48.507247, lng: 21.98661 },
    { lat: 48.507084, lng: 21.98707 },
    { lat: 48.506872, lng: 21.987505 },
    { lat: 48.506527, lng: 21.987952 },
    { lat: 48.506235, lng: 21.988494 },
    { lat: 48.50613, lng: 21.98883 },
    { lat: 48.506105, lng: 21.989029 },
    { lat: 48.506186, lng: 21.989649 },
    { lat: 48.506427, lng: 21.989897 },
    { lat: 48.506779, lng: 21.990003 },
    { lat: 48.507035, lng: 21.990188 },
    { lat: 48.507258, lng: 21.990532 },
    { lat: 48.507293, lng: 21.990604 },
    { lat: 48.507351, lng: 21.990725 },
    { lat: 48.507392, lng: 21.990828 },
    { lat: 48.507495, lng: 21.991154 },
    { lat: 48.507593, lng: 21.991609 },
    { lat: 48.50755, lng: 21.992351 },
    { lat: 48.507533, lng: 21.992679 },
    { lat: 48.507469, lng: 21.992971 },
    { lat: 48.50744, lng: 21.993106 },
    { lat: 48.50742, lng: 21.993199 },
    { lat: 48.507404, lng: 21.993274 },
    { lat: 48.507328, lng: 21.993762 },
    { lat: 48.507201, lng: 21.994585 },
    { lat: 48.507092, lng: 21.995204 },
    { lat: 48.506943, lng: 21.995933 },
    { lat: 48.50681, lng: 21.996453 },
    { lat: 48.506435, lng: 21.997244 },
    { lat: 48.506307, lng: 21.997806 },
    { lat: 48.506105, lng: 21.998477 },
    { lat: 48.505908, lng: 21.999059 },
    { lat: 48.505783, lng: 21.999729 },
    { lat: 48.505681, lng: 22.000625 },
    { lat: 48.505687, lng: 22.000802 },
    { lat: 48.505722, lng: 22.001102 },
    { lat: 48.505825, lng: 22.001334 },
    { lat: 48.505913, lng: 22.001432 },
    { lat: 48.506211, lng: 22.001485 },
    { lat: 48.506335, lng: 22.001377 },
    { lat: 48.506441, lng: 22.001217 },
    { lat: 48.506495, lng: 22.001145 },
    { lat: 48.506615, lng: 22.000577 },
    { lat: 48.506624, lng: 22.000293 },
    { lat: 48.506771, lng: 21.999748 },
    { lat: 48.506886, lng: 21.999302 },
    { lat: 48.506983, lng: 21.999024 },
    { lat: 48.507219, lng: 21.998377 },
    { lat: 48.50738, lng: 21.998309 },
    { lat: 48.507524, lng: 21.998317 },
    { lat: 48.507683, lng: 21.998408 },
    { lat: 48.507781, lng: 21.998508 },
    { lat: 48.507793, lng: 21.99852 },
    { lat: 48.507834, lng: 21.998562 },
    { lat: 48.507846, lng: 21.998574 },
    { lat: 48.50797, lng: 21.998918 },
    { lat: 48.507992, lng: 21.999135 },
    { lat: 48.507983, lng: 21.999403 },
    { lat: 48.508085, lng: 22.000462 },
    { lat: 48.508138, lng: 22.001378 },
    { lat: 48.50814, lng: 22.001572 },
    { lat: 48.508266, lng: 22.002444 },
    { lat: 48.508527, lng: 22.003089 },
    { lat: 48.508592, lng: 22.003178 },
    { lat: 48.508612, lng: 22.003206 },
    { lat: 48.508689, lng: 22.003312 },
    { lat: 48.508741, lng: 22.003385 },
    { lat: 48.508927, lng: 22.003486 },
    { lat: 48.509126, lng: 22.003556 },
    { lat: 48.509339, lng: 22.003534 },
    { lat: 48.509598, lng: 22.003471 },
    { lat: 48.510071, lng: 22.002962 },
    { lat: 48.510726, lng: 22.002102 },
    { lat: 48.510941, lng: 22.0019 },
    { lat: 48.511216, lng: 22.001722 },
    { lat: 48.511541, lng: 22.00171 },
    { lat: 48.511726, lng: 22.001826 },
    { lat: 48.511875, lng: 22.001987 },
    { lat: 48.512008, lng: 22.002261 },
    { lat: 48.512088, lng: 22.002544 },
    { lat: 48.512181, lng: 22.003109 },
    { lat: 48.512196, lng: 22.003417 },
    { lat: 48.512192, lng: 22.003911 },
    { lat: 48.51212, lng: 22.00487 },
    { lat: 48.511999, lng: 22.005454 },
    { lat: 48.511851, lng: 22.005836 },
    { lat: 48.511723, lng: 22.006193 },
    { lat: 48.511513, lng: 22.006573 },
    { lat: 48.511323, lng: 22.006783 },
    { lat: 48.511033, lng: 22.007056 },
    { lat: 48.510409, lng: 22.00755 },
    { lat: 48.510252, lng: 22.007705 },
    { lat: 48.510132, lng: 22.007924 },
    { lat: 48.510072, lng: 22.008094 },
    { lat: 48.510046, lng: 22.008163 },
    { lat: 48.510501, lng: 22.009118 },
    { lat: 48.51087, lng: 22.009683 },
    { lat: 48.512436, lng: 22.01184 },
    { lat: 48.512548, lng: 22.011765 },
    { lat: 48.512796, lng: 22.011599 },
    { lat: 48.513046, lng: 22.01146 },
    { lat: 48.513365, lng: 22.011191 },
    { lat: 48.513668, lng: 22.010751 },
    { lat: 48.513835, lng: 22.010518 },
    { lat: 48.513931, lng: 22.010456 },
    { lat: 48.514054, lng: 22.010619 },
    { lat: 48.514268, lng: 22.010989 },
    { lat: 48.51445, lng: 22.011391 },
    { lat: 48.514605, lng: 22.011862 },
    { lat: 48.514666, lng: 22.012207 },
    { lat: 48.514745, lng: 22.012712 },
    { lat: 48.514787, lng: 22.012947 },
    { lat: 48.514825, lng: 22.013381 },
    { lat: 48.514851, lng: 22.013851 },
    { lat: 48.514831, lng: 22.014335 },
    { lat: 48.514818, lng: 22.014713 },
    { lat: 48.514742, lng: 22.015107 },
    { lat: 48.514603, lng: 22.015643 },
    { lat: 48.514409, lng: 22.016384 },
    { lat: 48.514274, lng: 22.016866 },
    { lat: 48.514157, lng: 22.017138 },
    { lat: 48.514121, lng: 22.017202 },
    { lat: 48.513982, lng: 22.017485 },
    { lat: 48.513784, lng: 22.017825 },
    { lat: 48.513708, lng: 22.017999 },
    { lat: 48.513604, lng: 22.018198 },
    { lat: 48.513557, lng: 22.018262 },
    { lat: 48.513438, lng: 22.018428 },
    { lat: 48.513208, lng: 22.018707 },
    { lat: 48.512985, lng: 22.01898 },
    { lat: 48.512825, lng: 22.019174 },
    { lat: 48.512718, lng: 22.019306 },
    { lat: 48.512629, lng: 22.019415 },
    { lat: 48.512526, lng: 22.019607 },
    { lat: 48.512443, lng: 22.019808 },
    { lat: 48.51236, lng: 22.02003 },
    { lat: 48.512383, lng: 22.020249 },
    { lat: 48.512439, lng: 22.020497 },
    { lat: 48.51252, lng: 22.020729 },
    { lat: 48.51263, lng: 22.02091 },
    { lat: 48.512762, lng: 22.021098 },
    { lat: 48.512924, lng: 22.021227 },
    { lat: 48.51305, lng: 22.021284 },
    { lat: 48.513237, lng: 22.021304 },
    { lat: 48.51345, lng: 22.021305 },
    { lat: 48.513517, lng: 22.021306 },
    { lat: 48.513787, lng: 22.02131 },
    { lat: 48.513954, lng: 22.021304 },
    { lat: 48.514026, lng: 22.021309 },
    { lat: 48.514146, lng: 22.021306 },
    { lat: 48.514381, lng: 22.021298 },
    { lat: 48.514861, lng: 22.021287 },
    { lat: 48.51526, lng: 22.021273 },
    { lat: 48.51531, lng: 22.02127 },
    { lat: 48.515411, lng: 22.021351 },
    { lat: 48.515497, lng: 22.021459 },
    { lat: 48.515583, lng: 22.021543 },
    { lat: 48.515646, lng: 22.021682 },
    { lat: 48.515678, lng: 22.021831 },
    { lat: 48.515659, lng: 22.021975 },
    { lat: 48.515579, lng: 22.022157 },
    { lat: 48.515474, lng: 22.02232 },
    { lat: 48.515361, lng: 22.022424 },
    { lat: 48.515277, lng: 22.02252 },
    { lat: 48.515184, lng: 22.022584 },
    { lat: 48.514965, lng: 22.022724 },
    { lat: 48.514847, lng: 22.022787 },
    { lat: 48.514687, lng: 22.022867 },
    { lat: 48.514583, lng: 22.022908 },
    { lat: 48.514411, lng: 22.022991 },
    { lat: 48.514279, lng: 22.023088 },
    { lat: 48.514135, lng: 22.023205 },
    { lat: 48.514049, lng: 22.023251 },
    { lat: 48.514004, lng: 22.02329 },
    { lat: 48.51395, lng: 22.023319 },
    { lat: 48.513806, lng: 22.023399 },
    { lat: 48.513725, lng: 22.023508 },
    { lat: 48.513695, lng: 22.02389 },
    { lat: 48.513649, lng: 22.024805 },
    { lat: 48.513588, lng: 22.025657 },
    { lat: 48.513554, lng: 22.026279 },
    { lat: 48.513508, lng: 22.027143 },
    { lat: 48.513484, lng: 22.027584 },
    { lat: 48.513456, lng: 22.028058 },
    { lat: 48.513542, lng: 22.028087 },
    { lat: 48.513609, lng: 22.028099 },
    { lat: 48.513734, lng: 22.028123 },
    { lat: 48.513839, lng: 22.028126 },
    { lat: 48.514127, lng: 22.028212 },
    { lat: 48.514528, lng: 22.028339 },
    { lat: 48.514732, lng: 22.028431 },
    { lat: 48.514934, lng: 22.028527 },
    { lat: 48.515013, lng: 22.028605 },
    { lat: 48.515117, lng: 22.028748 },
    { lat: 48.515193, lng: 22.028884 },
    { lat: 48.515263, lng: 22.029049 },
    { lat: 48.515294, lng: 22.029213 },
    { lat: 48.515337, lng: 22.02952 },
    { lat: 48.515407, lng: 22.030011 },
    { lat: 48.515475, lng: 22.030539 },
    { lat: 48.515546, lng: 22.031215 },
    { lat: 48.515586, lng: 22.031614 },
    { lat: 48.515595, lng: 22.032104 },
    { lat: 48.515601, lng: 22.032569 },
    { lat: 48.515608, lng: 22.033196 },
    { lat: 48.515612, lng: 22.033694 },
    { lat: 48.5156087, lng: 22.0340651 },
    { lat: 48.515607, lng: 22.034256 },
    { lat: 48.515599, lng: 22.034624 },
    { lat: 48.515586, lng: 22.034773 },
    { lat: 48.515525, lng: 22.035016 },
    { lat: 48.515453, lng: 22.035198 },
    { lat: 48.515401, lng: 22.035329 },
    { lat: 48.515296, lng: 22.035482 },
    { lat: 48.515193, lng: 22.03557 },
    { lat: 48.5154607, lng: 22.0360297 },
    { lat: 48.5156725, lng: 22.0365021 },
    { lat: 48.5158742, lng: 22.0365655 },
    { lat: 48.5159694, lng: 22.0364574 },
    { lat: 48.5159846, lng: 22.0361006 },
    { lat: 48.5160817, lng: 22.0358373 },
    { lat: 48.5163578, lng: 22.0355862 },
    { lat: 48.5169781, lng: 22.0353193 },
    { lat: 48.5172654, lng: 22.0350651 },
    { lat: 48.5174559, lng: 22.0346682 },
    { lat: 48.517548, lng: 22.0342845 },
    { lat: 48.5175648, lng: 22.0335416 },
    { lat: 48.5183711, lng: 22.0335743 },
    { lat: 48.5189454, lng: 22.0338777 },
    { lat: 48.5190826, lng: 22.0338758 },
    { lat: 48.5196508, lng: 22.0336941 },
    { lat: 48.5199135, lng: 22.0334877 },
    { lat: 48.5205313, lng: 22.0335438 },
    { lat: 48.5210131, lng: 22.033364 },
    { lat: 48.521705, lng: 22.0332773 },
    { lat: 48.5233022, lng: 22.0343321 },
    { lat: 48.5238665, lng: 22.0339441 },
    { lat: 48.5241577, lng: 22.033663 },
    { lat: 48.5245817, lng: 22.0343973 },
    { lat: 48.525262, lng: 22.035311 },
    { lat: 48.5255806, lng: 22.0361115 },
    { lat: 48.5259529, lng: 22.036342 },
    { lat: 48.526766, lng: 22.0375373 },
    { lat: 48.5272249, lng: 22.0388394 },
    { lat: 48.5272663, lng: 22.0391573 },
    { lat: 48.5272245, lng: 22.0399308 },
    { lat: 48.5268372, lng: 22.0401965 },
    { lat: 48.5267131, lng: 22.0401416 },
    { lat: 48.5263801, lng: 22.0397656 },
    { lat: 48.5262112, lng: 22.0398512 },
    { lat: 48.5260693, lng: 22.0400336 },
    { lat: 48.5257487, lng: 22.0408231 },
    { lat: 48.5257052, lng: 22.0412055 },
    { lat: 48.5257326, lng: 22.0415028 },
    { lat: 48.5258321, lng: 22.0417082 },
    { lat: 48.5262528, lng: 22.0421699 },
    { lat: 48.526538, lng: 22.0428041 },
    { lat: 48.5265733, lng: 22.0428535 },
    { lat: 48.5267161, lng: 22.0430534 },
    { lat: 48.5269983, lng: 22.0431802 },
    { lat: 48.5272709, lng: 22.043156 },
    { lat: 48.5272674, lng: 22.0427282 },
    { lat: 48.5279115, lng: 22.0421566 },
    { lat: 48.5280796, lng: 22.0420775 },
    { lat: 48.5293432, lng: 22.0419081 },
    { lat: 48.5297295, lng: 22.0417857 },
    { lat: 48.5298164, lng: 22.0417061 },
    { lat: 48.5299485, lng: 22.0415821 },
    { lat: 48.5301232, lng: 22.0411746 },
    { lat: 48.5304417, lng: 22.0402106 },
    { lat: 48.5306748, lng: 22.0397995 },
    { lat: 48.530886, lng: 22.0395576 },
    { lat: 48.5312269, lng: 22.0388563 },
    { lat: 48.5313559, lng: 22.0387538 },
    { lat: 48.5314529, lng: 22.0391761 },
    { lat: 48.531492, lng: 22.0403733 },
    { lat: 48.5318068, lng: 22.0413016 },
    { lat: 48.5331071, lng: 22.0412029 },
    { lat: 48.5331303, lng: 22.0413652 },
    { lat: 48.5335393, lng: 22.0423303 },
    { lat: 48.5338735, lng: 22.0428443 },
    { lat: 48.5340496, lng: 22.0436145 },
    { lat: 48.5342906, lng: 22.0442711 },
    { lat: 48.5346036, lng: 22.0448446 },
    { lat: 48.5347413, lng: 22.0452821 },
    { lat: 48.5349626, lng: 22.0454081 },
    { lat: 48.5354079, lng: 22.0454976 },
    { lat: 48.5350258, lng: 22.0468127 },
    { lat: 48.5350087, lng: 22.0473184 },
    { lat: 48.5352478, lng: 22.0481771 },
    { lat: 48.5352481, lng: 22.0486602 },
    { lat: 48.5354115, lng: 22.0498815 },
    { lat: 48.5356045, lng: 22.0502795 },
    { lat: 48.5381039, lng: 22.0486394 },
    { lat: 48.5387543, lng: 22.0507479 },
    { lat: 48.5388573, lng: 22.0506791 },
    { lat: 48.5390228, lng: 22.0511013 },
    { lat: 48.5394273, lng: 22.0507601 },
    { lat: 48.5396589, lng: 22.0514073 },
    { lat: 48.5403014, lng: 22.0509956 },
    { lat: 48.540355, lng: 22.051114 },
    { lat: 48.5408647, lng: 22.0514 },
    { lat: 48.5454952, lng: 22.0523921 },
    { lat: 48.5462311, lng: 22.0529068 },
    { lat: 48.5471073, lng: 22.0529093 },
    { lat: 48.5479368, lng: 22.0526407 },
    { lat: 48.5578629, lng: 22.046426 },
    { lat: 48.5594259, lng: 22.0448215 },
    { lat: 48.5604695, lng: 22.0439624 },
    { lat: 48.5600996, lng: 22.0429972 },
    { lat: 48.5594668, lng: 22.0405059 },
    { lat: 48.5596236, lng: 22.0395061 },
    { lat: 48.5600599, lng: 22.0386082 },
    { lat: 48.5602354, lng: 22.0383283 },
    { lat: 48.5609469, lng: 22.0372415 },
    { lat: 48.5620824, lng: 22.0359084 },
    { lat: 48.5633327, lng: 22.0338951 },
    { lat: 48.5635585, lng: 22.0336888 },
    { lat: 48.5648116, lng: 22.0329499 },
    { lat: 48.5652085, lng: 22.032802 },
    { lat: 48.5657517, lng: 22.0327444 },
    { lat: 48.5665679, lng: 22.0328683 },
    { lat: 48.5672444, lng: 22.0329321 },
    { lat: 48.5673078, lng: 22.0328554 },
    { lat: 48.5673385, lng: 22.032771 },
    { lat: 48.5672749, lng: 22.0320996 },
    { lat: 48.5670994, lng: 22.0275125 },
    { lat: 48.5672461, lng: 22.0274895 },
    { lat: 48.5688878, lng: 22.0265805 },
    { lat: 48.5696308, lng: 22.0251617 },
    { lat: 48.5748544, lng: 22.0215812 },
    { lat: 48.5751199, lng: 22.0214876 },
    { lat: 48.5749293, lng: 22.0201735 },
  ],
  Horovce: [
    { lat: 48.7217752, lng: 21.7783863 },
    { lat: 48.7200844, lng: 21.7758384 },
    { lat: 48.7157762, lng: 21.7694331 },
    { lat: 48.7154373, lng: 21.7680235 },
    { lat: 48.715301, lng: 21.7677635 },
    { lat: 48.7134479, lng: 21.7614351 },
    { lat: 48.7134642, lng: 21.7613646 },
    { lat: 48.7131791, lng: 21.7613548 },
    { lat: 48.7128289, lng: 21.7606678 },
    { lat: 48.7119573, lng: 21.7585515 },
    { lat: 48.711413, lng: 21.7573743 },
    { lat: 48.7114535, lng: 21.7571235 },
    { lat: 48.7108731, lng: 21.7560704 },
    { lat: 48.7080591, lng: 21.7504409 },
    { lat: 48.7080137, lng: 21.7504287 },
    { lat: 48.7082051, lng: 21.7502582 },
    { lat: 48.7076168, lng: 21.7484056 },
    { lat: 48.7075663, lng: 21.7480315 },
    { lat: 48.7065031, lng: 21.7457972 },
    { lat: 48.7061082, lng: 21.7433505 },
    { lat: 48.7058894, lng: 21.7410524 },
    { lat: 48.7058268, lng: 21.7407325 },
    { lat: 48.7061334, lng: 21.7406664 },
    { lat: 48.7064195, lng: 21.7397524 },
    { lat: 48.7061191, lng: 21.7381977 },
    { lat: 48.7067088, lng: 21.7379084 },
    { lat: 48.7061134, lng: 21.7348297 },
    { lat: 48.7060843, lng: 21.7340343 },
    { lat: 48.706184, lng: 21.732032 },
    { lat: 48.70588, lng: 21.73196 },
    { lat: 48.705811, lng: 21.731944 },
    { lat: 48.705642, lng: 21.731917 },
    { lat: 48.705567, lng: 21.731917 },
    { lat: 48.705522, lng: 21.731916 },
    { lat: 48.705275, lng: 21.731974 },
    { lat: 48.705255, lng: 21.731969 },
    { lat: 48.70508, lng: 21.73193 },
    { lat: 48.704977, lng: 21.731854 },
    { lat: 48.704908, lng: 21.731803 },
    { lat: 48.704708, lng: 21.731739 },
    { lat: 48.704645, lng: 21.731719 },
    { lat: 48.704235, lng: 21.731416 },
    { lat: 48.703469, lng: 21.731333 },
    { lat: 48.70327, lng: 21.731721 },
    { lat: 48.703103, lng: 21.732015 },
    { lat: 48.702878, lng: 21.732369 },
    { lat: 48.702827, lng: 21.732409 },
    { lat: 48.702774, lng: 21.73245 },
    { lat: 48.702704, lng: 21.732457 },
    { lat: 48.702628, lng: 21.732466 },
    { lat: 48.70211, lng: 21.73226 },
    { lat: 48.701976, lng: 21.732491 },
    { lat: 48.701946, lng: 21.73297 },
    { lat: 48.701982, lng: 21.733436 },
    { lat: 48.702009, lng: 21.733787 },
    { lat: 48.70206, lng: 21.734086 },
    { lat: 48.702083, lng: 21.734226 },
    { lat: 48.702017, lng: 21.734523 },
    { lat: 48.701841, lng: 21.734828 },
    { lat: 48.701809, lng: 21.734884 },
    { lat: 48.701713, lng: 21.734999 },
    { lat: 48.701551, lng: 21.735196 },
    { lat: 48.701439, lng: 21.73533 },
    { lat: 48.701429, lng: 21.735352 },
    { lat: 48.701336, lng: 21.735558 },
    { lat: 48.701189, lng: 21.735773 },
    { lat: 48.701159, lng: 21.735817 },
    { lat: 48.700671, lng: 21.736015 },
    { lat: 48.700553, lng: 21.735908 },
    { lat: 48.700538, lng: 21.735951 },
    { lat: 48.700526, lng: 21.735991 },
    { lat: 48.700516, lng: 21.736081 },
    { lat: 48.7005, lng: 21.73621 },
    { lat: 48.70052, lng: 21.736495 },
    { lat: 48.700531, lng: 21.736662 },
    { lat: 48.700568, lng: 21.736943 },
    { lat: 48.700609, lng: 21.737264 },
    { lat: 48.700615, lng: 21.737622 },
    { lat: 48.700622, lng: 21.738161 },
    { lat: 48.700647, lng: 21.738278 },
    { lat: 48.70067, lng: 21.738389 },
    { lat: 48.700656, lng: 21.738834 },
    { lat: 48.700563, lng: 21.739154 },
    { lat: 48.700551, lng: 21.739557 },
    { lat: 48.70055, lng: 21.739574 },
    { lat: 48.700484, lng: 21.740232 },
    { lat: 48.700343, lng: 21.740932 },
    { lat: 48.700322, lng: 21.741209 },
    { lat: 48.700296, lng: 21.74148 },
    { lat: 48.700263, lng: 21.741533 },
    { lat: 48.700152, lng: 21.741717 },
    { lat: 48.699968, lng: 21.741942 },
    { lat: 48.699572, lng: 21.742239 },
    { lat: 48.699053, lng: 21.74241 },
    { lat: 48.699039, lng: 21.742419 },
    { lat: 48.699015, lng: 21.742435 },
    { lat: 48.698984, lng: 21.742458 },
    { lat: 48.698952, lng: 21.742519 },
    { lat: 48.698926, lng: 21.742566 },
    { lat: 48.698867, lng: 21.742585 },
    { lat: 48.698557, lng: 21.74268 },
    { lat: 48.6983, lng: 21.742758 },
    { lat: 48.698043, lng: 21.743469 },
    { lat: 48.697901, lng: 21.743736 },
    { lat: 48.697881, lng: 21.743786 },
    { lat: 48.697793, lng: 21.744001 },
    { lat: 48.697764, lng: 21.744215 },
    { lat: 48.69774, lng: 21.744399 },
    { lat: 48.697756, lng: 21.744666 },
    { lat: 48.697762, lng: 21.744762 },
    { lat: 48.697702, lng: 21.744919 },
    { lat: 48.69757, lng: 21.745146 },
    { lat: 48.697546, lng: 21.745187 },
    { lat: 48.69753, lng: 21.745235 },
    { lat: 48.697391, lng: 21.745681 },
    { lat: 48.697376, lng: 21.745848 },
    { lat: 48.69738, lng: 21.745861 },
    { lat: 48.697433, lng: 21.746063 },
    { lat: 48.697547, lng: 21.746316 },
    { lat: 48.697618, lng: 21.746475 },
    { lat: 48.697642, lng: 21.74664 },
    { lat: 48.697661, lng: 21.746783 },
    { lat: 48.697672, lng: 21.746864 },
    { lat: 48.697697, lng: 21.746961 },
    { lat: 48.697721, lng: 21.747052 },
    { lat: 48.697709, lng: 21.747118 },
    { lat: 48.697689, lng: 21.747226 },
    { lat: 48.697604, lng: 21.747545 },
    { lat: 48.697609, lng: 21.747614 },
    { lat: 48.697617, lng: 21.747773 },
    { lat: 48.69766, lng: 21.748001 },
    { lat: 48.697657, lng: 21.748082 },
    { lat: 48.697651, lng: 21.748263 },
    { lat: 48.697567, lng: 21.748428 },
    { lat: 48.697517, lng: 21.748526 },
    { lat: 48.697513, lng: 21.748594 },
    { lat: 48.697509, lng: 21.748657 },
    { lat: 48.697501, lng: 21.74871 },
    { lat: 48.697494, lng: 21.748835 },
    { lat: 48.697492, lng: 21.748866 },
    { lat: 48.697491, lng: 21.748882 },
    { lat: 48.697489, lng: 21.749069 },
    { lat: 48.697487, lng: 21.74914 },
    { lat: 48.697473, lng: 21.749276 },
    { lat: 48.69744, lng: 21.749586 },
    { lat: 48.697558, lng: 21.749753 },
    { lat: 48.697578, lng: 21.749964 },
    { lat: 48.69759, lng: 21.750098 },
    { lat: 48.697535, lng: 21.750296 },
    { lat: 48.697435, lng: 21.750505 },
    { lat: 48.697351, lng: 21.750681 },
    { lat: 48.69728, lng: 21.750774 },
    { lat: 48.697049, lng: 21.751 },
    { lat: 48.696982, lng: 21.751162 },
    { lat: 48.696897, lng: 21.751434 },
    { lat: 48.696808, lng: 21.75172 },
    { lat: 48.696766, lng: 21.751807 },
    { lat: 48.696706, lng: 21.75193 },
    { lat: 48.696673, lng: 21.752027 },
    { lat: 48.696644, lng: 21.752115 },
    { lat: 48.696575, lng: 21.752544 },
    { lat: 48.696451, lng: 21.7529 },
    { lat: 48.696425, lng: 21.753051 },
    { lat: 48.696398, lng: 21.75333 },
    { lat: 48.696369, lng: 21.753456 },
    { lat: 48.696191, lng: 21.753698 },
    { lat: 48.69613, lng: 21.753811 },
    { lat: 48.696134, lng: 21.753976 },
    { lat: 48.696024, lng: 21.754453 },
    { lat: 48.695992, lng: 21.75459 },
    { lat: 48.695818, lng: 21.755166 },
    { lat: 48.69529, lng: 21.756003 },
    { lat: 48.695254, lng: 21.756061 },
    { lat: 48.694994, lng: 21.756281 },
    { lat: 48.69494, lng: 21.756463 },
    { lat: 48.694869, lng: 21.756541 },
    { lat: 48.694801, lng: 21.756615 },
    { lat: 48.694421, lng: 21.757145 },
    { lat: 48.694347, lng: 21.757161 },
    { lat: 48.694239, lng: 21.757196 },
    { lat: 48.694198, lng: 21.757283 },
    { lat: 48.694113, lng: 21.757468 },
    { lat: 48.693914, lng: 21.757723 },
    { lat: 48.693887, lng: 21.757977 },
    { lat: 48.693863, lng: 21.758214 },
    { lat: 48.693481, lng: 21.758798 },
    { lat: 48.692918, lng: 21.759098 },
    { lat: 48.692816, lng: 21.759125 },
    { lat: 48.692626, lng: 21.75929 },
    { lat: 48.692609, lng: 21.759395 },
    { lat: 48.692589, lng: 21.759531 },
    { lat: 48.692556, lng: 21.759619 },
    { lat: 48.692552, lng: 21.75963 },
    { lat: 48.691821, lng: 21.759913 },
    { lat: 48.69169, lng: 21.759697 },
    { lat: 48.691631, lng: 21.75967 },
    { lat: 48.691603, lng: 21.759657 },
    { lat: 48.6915, lng: 21.759676 },
    { lat: 48.691457, lng: 21.759683 },
    { lat: 48.691429, lng: 21.759689 },
    { lat: 48.691425, lng: 21.759689 },
    { lat: 48.69105, lng: 21.760131 },
    { lat: 48.690937, lng: 21.760264 },
    { lat: 48.690713, lng: 21.760231 },
    { lat: 48.690713, lng: 21.76023 },
    { lat: 48.690635, lng: 21.76029 },
    { lat: 48.690589, lng: 21.760324 },
    { lat: 48.690424, lng: 21.760482 },
    { lat: 48.689852, lng: 21.760218 },
    { lat: 48.689709, lng: 21.760152 },
    { lat: 48.689642, lng: 21.760137 },
    { lat: 48.689556, lng: 21.760118 },
    { lat: 48.689417, lng: 21.760082 },
    { lat: 48.689314, lng: 21.760058 },
    { lat: 48.689259, lng: 21.760045 },
    { lat: 48.688797, lng: 21.76035 },
    { lat: 48.688744, lng: 21.760412 },
    { lat: 48.688713, lng: 21.760448 },
    { lat: 48.688663, lng: 21.760509 },
    { lat: 48.688618, lng: 21.760558 },
    { lat: 48.688601, lng: 21.760577 },
    { lat: 48.688506, lng: 21.76083 },
    { lat: 48.688388, lng: 21.760903 },
    { lat: 48.688354, lng: 21.7609 },
    { lat: 48.688213, lng: 21.760889 },
    { lat: 48.68809, lng: 21.760893 },
    { lat: 48.687949, lng: 21.761055 },
    { lat: 48.687937, lng: 21.761058 },
    { lat: 48.687874, lng: 21.761073 },
    { lat: 48.687804, lng: 21.76105 },
    { lat: 48.687772, lng: 21.761039 },
    { lat: 48.687453, lng: 21.761134 },
    { lat: 48.687356, lng: 21.761163 },
    { lat: 48.687208, lng: 21.761159 },
    { lat: 48.686983, lng: 21.761153 },
    { lat: 48.686845, lng: 21.761066 },
    { lat: 48.686696, lng: 21.760974 },
    { lat: 48.68655, lng: 21.760873 },
    { lat: 48.686391, lng: 21.760765 },
    { lat: 48.685821, lng: 21.760365 },
    { lat: 48.685783, lng: 21.760338 },
    { lat: 48.685582, lng: 21.760193 },
    { lat: 48.68555, lng: 21.760206 },
    { lat: 48.68551, lng: 21.760249 },
    { lat: 48.685444, lng: 21.760314 },
    { lat: 48.685118, lng: 21.760618 },
    { lat: 48.684888, lng: 21.761029 },
    { lat: 48.684865, lng: 21.761039 },
    { lat: 48.684791, lng: 21.761069 },
    { lat: 48.684481, lng: 21.761007 },
    { lat: 48.684362, lng: 21.760983 },
    { lat: 48.684203, lng: 21.760845 },
    { lat: 48.683933, lng: 21.760963 },
    { lat: 48.683793, lng: 21.761069 },
    { lat: 48.6836, lng: 21.761216 },
    { lat: 48.683515, lng: 21.761186 },
    { lat: 48.683424, lng: 21.761154 },
    { lat: 48.683165, lng: 21.760888 },
    { lat: 48.683158, lng: 21.760884 },
    { lat: 48.682759, lng: 21.760697 },
    { lat: 48.682065, lng: 21.760244 },
    { lat: 48.68205, lng: 21.760255 },
    { lat: 48.681966, lng: 21.76031 },
    { lat: 48.681369, lng: 21.760452 },
    { lat: 48.680951, lng: 21.760552 },
    { lat: 48.680917, lng: 21.760602 },
    { lat: 48.680835, lng: 21.760722 },
    { lat: 48.680696, lng: 21.761011 },
    { lat: 48.680615, lng: 21.761255 },
    { lat: 48.680529, lng: 21.761516 },
    { lat: 48.68045, lng: 21.761628 },
    { lat: 48.680382, lng: 21.761726 },
    { lat: 48.680261, lng: 21.761776 },
    { lat: 48.679748, lng: 21.761875 },
    { lat: 48.67973, lng: 21.761895 },
    { lat: 48.679673, lng: 21.761959 },
    { lat: 48.679659, lng: 21.761991 },
    { lat: 48.679627, lng: 21.762064 },
    { lat: 48.679449, lng: 21.76219 },
    { lat: 48.678972, lng: 21.762257 },
    { lat: 48.67881, lng: 21.762304 },
    { lat: 48.678751, lng: 21.762321 },
    { lat: 48.678614, lng: 21.762321 },
    { lat: 48.678422, lng: 21.762395 },
    { lat: 48.678232, lng: 21.762408 },
    { lat: 48.678189, lng: 21.762433 },
    { lat: 48.678118, lng: 21.762473 },
    { lat: 48.678083, lng: 21.762528 },
    { lat: 48.678038, lng: 21.762595 },
    { lat: 48.677881, lng: 21.762748 },
    { lat: 48.677887, lng: 21.762861 },
    { lat: 48.677906, lng: 21.763263 },
    { lat: 48.677787, lng: 21.763485 },
    { lat: 48.677752, lng: 21.763618 },
    { lat: 48.67771, lng: 21.763786 },
    { lat: 48.677568, lng: 21.764341 },
    { lat: 48.67754, lng: 21.764543 },
    { lat: 48.677523, lng: 21.764667 },
    { lat: 48.677346, lng: 21.764971 },
    { lat: 48.677191, lng: 21.765228 },
    { lat: 48.677131, lng: 21.765627 },
    { lat: 48.677109, lng: 21.765843 },
    { lat: 48.677085, lng: 21.766096 },
    { lat: 48.677052, lng: 21.766346 },
    { lat: 48.677016, lng: 21.766612 },
    { lat: 48.676968, lng: 21.766607 },
    { lat: 48.676955, lng: 21.766605 },
    { lat: 48.676838, lng: 21.766608 },
    { lat: 48.675762, lng: 21.766568 },
    { lat: 48.675733, lng: 21.766541 },
    { lat: 48.675318, lng: 21.766588 },
    { lat: 48.674669, lng: 21.767231 },
    { lat: 48.674286, lng: 21.767475 },
    { lat: 48.674269, lng: 21.767441 },
    { lat: 48.674142, lng: 21.767182 },
    { lat: 48.673772, lng: 21.767265 },
    { lat: 48.673613, lng: 21.76732 },
    { lat: 48.67356, lng: 21.767339 },
    { lat: 48.673002, lng: 21.767919 },
    { lat: 48.672615, lng: 21.768295 },
    { lat: 48.672488, lng: 21.768421 },
    { lat: 48.671808, lng: 21.769102 },
    { lat: 48.671322, lng: 21.769607 },
    { lat: 48.670791, lng: 21.770158 },
    { lat: 48.670206, lng: 21.770029 },
    { lat: 48.669455, lng: 21.769548 },
    { lat: 48.668689, lng: 21.768378 },
    { lat: 48.668457, lng: 21.76843 },
    { lat: 48.668029, lng: 21.768527 },
    { lat: 48.667021, lng: 21.769559 },
    { lat: 48.666948, lng: 21.769687 },
    { lat: 48.66676, lng: 21.770016 },
    { lat: 48.666795, lng: 21.77104 },
    { lat: 48.6668, lng: 21.771173 },
    { lat: 48.666706, lng: 21.771582 },
    { lat: 48.666269, lng: 21.771761 },
    { lat: 48.665522, lng: 21.772042 },
    { lat: 48.665462, lng: 21.772063 },
    { lat: 48.665258, lng: 21.772132 },
    { lat: 48.664806, lng: 21.772562 },
    { lat: 48.664235, lng: 21.773009 },
    { lat: 48.663842, lng: 21.773317 },
    { lat: 48.66379, lng: 21.773459 },
    { lat: 48.663697, lng: 21.773501 },
    { lat: 48.663591, lng: 21.773548 },
    { lat: 48.663591, lng: 21.773547 },
    { lat: 48.663543, lng: 21.773411 },
    { lat: 48.663528, lng: 21.773444 },
    { lat: 48.663469, lng: 21.773563 },
    { lat: 48.662593, lng: 21.775333 },
    { lat: 48.66188, lng: 21.77488 },
    { lat: 48.661497, lng: 21.774786 },
    { lat: 48.661094, lng: 21.774794 },
    { lat: 48.660728, lng: 21.774866 },
    { lat: 48.660574, lng: 21.775079 },
    { lat: 48.660474, lng: 21.775099 },
    { lat: 48.660482, lng: 21.775132 },
    { lat: 48.66051, lng: 21.775247 },
    { lat: 48.660492, lng: 21.775341 },
    { lat: 48.660398, lng: 21.775497 },
    { lat: 48.660283, lng: 21.775627 },
    { lat: 48.658619, lng: 21.777536 },
    { lat: 48.658147, lng: 21.778077 },
    { lat: 48.6581, lng: 21.778128 },
    { lat: 48.658042, lng: 21.77819 },
    { lat: 48.657967, lng: 21.778224 },
    { lat: 48.657721, lng: 21.778296 },
    { lat: 48.657666, lng: 21.778312 },
    { lat: 48.653675, lng: 21.77948 },
    { lat: 48.65352, lng: 21.779479 },
    { lat: 48.653513, lng: 21.779477 },
    { lat: 48.653459, lng: 21.779464 },
    { lat: 48.65335, lng: 21.779489 },
    { lat: 48.653345, lng: 21.77949 },
    { lat: 48.653312, lng: 21.779496 },
    { lat: 48.653108, lng: 21.779599 },
    { lat: 48.652818, lng: 21.779746 },
    { lat: 48.652778, lng: 21.779833 },
    { lat: 48.652773, lng: 21.779997 },
    { lat: 48.652895, lng: 21.780554 },
    { lat: 48.652892, lng: 21.780616 },
    { lat: 48.65289, lng: 21.780673 },
    { lat: 48.652876, lng: 21.780712 },
    { lat: 48.652864, lng: 21.780749 },
    { lat: 48.652768, lng: 21.780884 },
    { lat: 48.65267, lng: 21.780945 },
    { lat: 48.65265, lng: 21.780957 },
    { lat: 48.652318, lng: 21.780995 },
    { lat: 48.651942, lng: 21.780994 },
    { lat: 48.643437, lng: 21.782973 },
    { lat: 48.643424, lng: 21.78298 },
    { lat: 48.643349, lng: 21.783019 },
    { lat: 48.643304, lng: 21.783111 },
    { lat: 48.643203, lng: 21.78388 },
    { lat: 48.643197, lng: 21.783931 },
    { lat: 48.643195, lng: 21.783941 },
    { lat: 48.643175, lng: 21.784044 },
    { lat: 48.642802, lng: 21.786737 },
    { lat: 48.6502595, lng: 21.7849756 },
    { lat: 48.6535578, lng: 21.7841566 },
    { lat: 48.6541611, lng: 21.7839528 },
    { lat: 48.6547617, lng: 21.7838128 },
    { lat: 48.6548541, lng: 21.783867 },
    { lat: 48.6551366, lng: 21.7836533 },
    { lat: 48.6590534, lng: 21.7829461 },
    { lat: 48.6622352, lng: 21.7819285 },
    { lat: 48.6629891, lng: 21.781597 },
    { lat: 48.6631373, lng: 21.7819686 },
    { lat: 48.6636387, lng: 21.7839169 },
    { lat: 48.6638273, lng: 21.7841931 },
    { lat: 48.6642867, lng: 21.7842527 },
    { lat: 48.6648887, lng: 21.7842119 },
    { lat: 48.6674911, lng: 21.7833662 },
    { lat: 48.6681273, lng: 21.7829634 },
    { lat: 48.6731447, lng: 21.7816694 },
    { lat: 48.6732294, lng: 21.7817686 },
    { lat: 48.6732254, lng: 21.7810178 },
    { lat: 48.6757364, lng: 21.7813142 },
    { lat: 48.67809, lng: 21.7816536 },
    { lat: 48.6814718, lng: 21.7807118 },
    { lat: 48.6815881, lng: 21.7811524 },
    { lat: 48.6820435, lng: 21.7823383 },
    { lat: 48.68236, lng: 21.7825215 },
    { lat: 48.6833852, lng: 21.7824015 },
    { lat: 48.6837293, lng: 21.7822302 },
    { lat: 48.6840262, lng: 21.7819645 },
    { lat: 48.6840732, lng: 21.7804656 },
    { lat: 48.6839647, lng: 21.7798483 },
    { lat: 48.6895973, lng: 21.777861 },
    { lat: 48.6898837, lng: 21.7778081 },
    { lat: 48.6899817, lng: 21.7783666 },
    { lat: 48.6902478, lng: 21.7786408 },
    { lat: 48.6906714, lng: 21.7794343 },
    { lat: 48.691047, lng: 21.7799355 },
    { lat: 48.6912112, lng: 21.7802725 },
    { lat: 48.6914916, lng: 21.7814834 },
    { lat: 48.6917212, lng: 21.7816975 },
    { lat: 48.6925964, lng: 21.7820281 },
    { lat: 48.692768, lng: 21.7823147 },
    { lat: 48.6926925, lng: 21.7823944 },
    { lat: 48.6926736, lng: 21.7826142 },
    { lat: 48.6925459, lng: 21.7827611 },
    { lat: 48.6916851, lng: 21.7830194 },
    { lat: 48.6915329, lng: 21.7832988 },
    { lat: 48.6915011, lng: 21.7837558 },
    { lat: 48.6917168, lng: 21.7857178 },
    { lat: 48.6918231, lng: 21.7859468 },
    { lat: 48.6920282, lng: 21.7861713 },
    { lat: 48.6923082, lng: 21.7860807 },
    { lat: 48.6923744, lng: 21.7858182 },
    { lat: 48.6925026, lng: 21.7856891 },
    { lat: 48.6926858, lng: 21.7852515 },
    { lat: 48.6929204, lng: 21.7849418 },
    { lat: 48.6931952, lng: 21.7848217 },
    { lat: 48.6934506, lng: 21.7849495 },
    { lat: 48.6939084, lng: 21.7855024 },
    { lat: 48.6942659, lng: 21.7859998 },
    { lat: 48.6947031, lng: 21.7868023 },
    { lat: 48.6959463, lng: 21.7852992 },
    { lat: 48.6971162, lng: 21.7843164 },
    { lat: 48.6978481, lng: 21.7838315 },
    { lat: 48.6999353, lng: 21.7832352 },
    { lat: 48.7007329, lng: 21.7831103 },
    { lat: 48.701007, lng: 21.7833036 },
    { lat: 48.7021935, lng: 21.7852963 },
    { lat: 48.7021184, lng: 21.7859487 },
    { lat: 48.7021798, lng: 21.7864443 },
    { lat: 48.7024368, lng: 21.7873567 },
    { lat: 48.7025358, lng: 21.7880842 },
    { lat: 48.7036337, lng: 21.78881 },
    { lat: 48.7062705, lng: 21.787685 },
    { lat: 48.7089021, lng: 21.7953075 },
    { lat: 48.7090774, lng: 21.7957729 },
    { lat: 48.709175, lng: 21.7958896 },
    { lat: 48.7091746, lng: 21.7952055 },
    { lat: 48.7092016, lng: 21.7950174 },
    { lat: 48.7094854, lng: 21.7948176 },
    { lat: 48.7097618, lng: 21.7948837 },
    { lat: 48.7099043, lng: 21.7948317 },
    { lat: 48.7106556, lng: 21.793755 },
    { lat: 48.7109296, lng: 21.7935153 },
    { lat: 48.7112949, lng: 21.7929875 },
    { lat: 48.7114942, lng: 21.7927808 },
    { lat: 48.7116201, lng: 21.7926503 },
    { lat: 48.7116991, lng: 21.7926177 },
    { lat: 48.7120088, lng: 21.7927552 },
    { lat: 48.7123307, lng: 21.7929749 },
    { lat: 48.7127072, lng: 21.7930489 },
    { lat: 48.7129329, lng: 21.7931141 },
    { lat: 48.7138215, lng: 21.7930273 },
    { lat: 48.7139531, lng: 21.7929396 },
    { lat: 48.7140274, lng: 21.7928077 },
    { lat: 48.7141047, lng: 21.7926705 },
    { lat: 48.71446, lng: 21.7916737 },
    { lat: 48.7149778, lng: 21.7910358 },
    { lat: 48.7150072, lng: 21.7909996 },
    { lat: 48.7153084, lng: 21.7903959 },
    { lat: 48.715889, lng: 21.7897949 },
    { lat: 48.7159303, lng: 21.789777 },
    { lat: 48.7160247, lng: 21.7897362 },
    { lat: 48.716204, lng: 21.7898391 },
    { lat: 48.7171133, lng: 21.7914814 },
    { lat: 48.7174829, lng: 21.7918041 },
    { lat: 48.7177398, lng: 21.7918992 },
    { lat: 48.7179579, lng: 21.7919018 },
    { lat: 48.7180131, lng: 21.7919024 },
    { lat: 48.7182655, lng: 21.7918245 },
    { lat: 48.7184998, lng: 21.7916619 },
    { lat: 48.7185244, lng: 21.7916359 },
    { lat: 48.7189713, lng: 21.7911641 },
    { lat: 48.7200048, lng: 21.7900727 },
    { lat: 48.7200305, lng: 21.7900322 },
    { lat: 48.7201107, lng: 21.789906 },
    { lat: 48.7201494, lng: 21.7897544 },
    { lat: 48.7201507, lng: 21.7887734 },
    { lat: 48.7202257, lng: 21.7884607 },
    { lat: 48.7205992, lng: 21.7877253 },
    { lat: 48.7204009, lng: 21.7860947 },
    { lat: 48.7201224, lng: 21.7853839 },
    { lat: 48.7201261, lng: 21.7852286 },
    { lat: 48.7204855, lng: 21.7843925 },
    { lat: 48.7205576, lng: 21.78346 },
    { lat: 48.7204962, lng: 21.7830077 },
    { lat: 48.7205058, lng: 21.7823412 },
    { lat: 48.7205971, lng: 21.7818336 },
    { lat: 48.7206513, lng: 21.7817181 },
    { lat: 48.7212814, lng: 21.7813748 },
    { lat: 48.7214108, lng: 21.7814166 },
    { lat: 48.7216703, lng: 21.7820509 },
    { lat: 48.7218879, lng: 21.7821051 },
    { lat: 48.7221773, lng: 21.7817339 },
    { lat: 48.722258, lng: 21.7813342 },
    { lat: 48.7222509, lng: 21.7811561 },
    { lat: 48.7221276, lng: 21.7808318 },
    { lat: 48.7221034, lng: 21.7802087 },
    { lat: 48.7217665, lng: 21.7785301 },
    { lat: 48.7217752, lng: 21.7783863 },
  ],
  Čečehov: [
    { lat: 48.7076194, lng: 21.990299 },
    { lat: 48.7073326, lng: 21.9908971 },
    { lat: 48.7070394, lng: 21.9917833 },
    { lat: 48.706762, lng: 21.9922668 },
    { lat: 48.7065347, lng: 21.9930895 },
    { lat: 48.7061755, lng: 21.9938052 },
    { lat: 48.7061962, lng: 21.9942335 },
    { lat: 48.70606, lng: 21.9946475 },
    { lat: 48.705745, lng: 21.995058 },
    { lat: 48.7058034, lng: 21.9978092 },
    { lat: 48.7055532, lng: 21.9987115 },
    { lat: 48.7057231, lng: 21.9996305 },
    { lat: 48.7056361, lng: 22.0003939 },
    { lat: 48.7056825, lng: 22.0007265 },
    { lat: 48.7062717, lng: 22.0010408 },
    { lat: 48.7065596, lng: 22.001315 },
    { lat: 48.7072285, lng: 22.0025353 },
    { lat: 48.7073832, lng: 22.0037992 },
    { lat: 48.7076193, lng: 22.0044407 },
    { lat: 48.7085539, lng: 22.0093148 },
    { lat: 48.7085971, lng: 22.0098894 },
    { lat: 48.7084593, lng: 22.0106069 },
    { lat: 48.7085592, lng: 22.0106434 },
    { lat: 48.7181483, lng: 22.0112567 },
    { lat: 48.7195457, lng: 22.0169034 },
    { lat: 48.7197732, lng: 22.0212691 },
    { lat: 48.719802, lng: 22.0234243 },
    { lat: 48.7198723, lng: 22.0234941 },
    { lat: 48.7265153, lng: 22.0186968 },
    { lat: 48.728404, lng: 22.0197161 },
    { lat: 48.7297545, lng: 22.0196918 },
    { lat: 48.7308995, lng: 22.0190324 },
    { lat: 48.7312001, lng: 22.0194138 },
    { lat: 48.7329004, lng: 22.0211139 },
    { lat: 48.7341009, lng: 22.0195895 },
    { lat: 48.7341752, lng: 22.019405 },
    { lat: 48.7342063, lng: 22.0192504 },
    { lat: 48.7340414, lng: 22.0170524 },
    { lat: 48.734165, lng: 22.016445 },
    { lat: 48.7340636, lng: 22.0161168 },
    { lat: 48.7352975, lng: 22.0139792 },
    { lat: 48.7356998, lng: 22.0128047 },
    { lat: 48.7351062, lng: 22.0125027 },
    { lat: 48.7345873, lng: 22.0120751 },
    { lat: 48.7348431, lng: 22.0113963 },
    { lat: 48.7350246, lng: 22.0112222 },
    { lat: 48.7356849, lng: 22.0096086 },
    { lat: 48.7358926, lng: 22.009225 },
    { lat: 48.7375447, lng: 22.006902 },
    { lat: 48.7376389, lng: 22.0067029 },
    { lat: 48.7365946, lng: 22.0047116 },
    { lat: 48.7364092, lng: 22.0042526 },
    { lat: 48.7360605, lng: 22.0016767 },
    { lat: 48.7357767, lng: 22.0006551 },
    { lat: 48.7355225, lng: 21.9989071 },
    { lat: 48.7347962, lng: 21.9949772 },
    { lat: 48.7349099, lng: 21.9945653 },
    { lat: 48.734951, lng: 21.9942244 },
    { lat: 48.733649, lng: 21.9881478 },
    { lat: 48.7333689, lng: 21.9870991 },
    { lat: 48.7329469, lng: 21.9858153 },
    { lat: 48.7318144, lng: 21.9829456 },
    { lat: 48.7319596, lng: 21.9828109 },
    { lat: 48.7311862, lng: 21.98036 },
    { lat: 48.7307036, lng: 21.9786269 },
    { lat: 48.7305506, lng: 21.9785404 },
    { lat: 48.7299752, lng: 21.9783287 },
    { lat: 48.7296747, lng: 21.9779604 },
    { lat: 48.7294988, lng: 21.9780822 },
    { lat: 48.7293393, lng: 21.9779975 },
    { lat: 48.7291883, lng: 21.9777527 },
    { lat: 48.7290102, lng: 21.9777869 },
    { lat: 48.7287219, lng: 21.9779574 },
    { lat: 48.7278188, lng: 21.9788742 },
    { lat: 48.7274529, lng: 21.9787797 },
    { lat: 48.727187, lng: 21.9788541 },
    { lat: 48.7268871, lng: 21.978778 },
    { lat: 48.7262903, lng: 21.9787981 },
    { lat: 48.7264684, lng: 21.9782689 },
    { lat: 48.7260898, lng: 21.9781983 },
    { lat: 48.7255307, lng: 21.9782394 },
    { lat: 48.7254448, lng: 21.9782753 },
    { lat: 48.7255756, lng: 21.9791034 },
    { lat: 48.7258739, lng: 21.98039 },
    { lat: 48.7245173, lng: 21.9809384 },
    { lat: 48.7235076, lng: 21.9814227 },
    { lat: 48.719829, lng: 21.9833354 },
    { lat: 48.7165524, lng: 21.9839862 },
    { lat: 48.7129639, lng: 21.9849922 },
    { lat: 48.7113697, lng: 21.9853072 },
    { lat: 48.7107265, lng: 21.98554 },
    { lat: 48.7103093, lng: 21.9858209 },
    { lat: 48.7093045, lng: 21.9876839 },
    { lat: 48.708384, lng: 21.9884059 },
    { lat: 48.7082505, lng: 21.9886053 },
    { lat: 48.7076194, lng: 21.990299 },
  ],
  Ptrukša: [
    { lat: 48.4476677, lng: 22.1405621 },
    { lat: 48.452671, lng: 22.14153 },
    { lat: 48.4601866, lng: 22.1432949 },
    { lat: 48.4607635, lng: 22.1434304 },
    { lat: 48.4775896, lng: 22.1473828 },
    { lat: 48.483711, lng: 22.148821 },
    { lat: 48.4837343, lng: 22.1487331 },
    { lat: 48.4834875, lng: 22.1480376 },
    { lat: 48.4833858, lng: 22.1474849 },
    { lat: 48.4833993, lng: 22.146474 },
    { lat: 48.4834852, lng: 22.1463496 },
    { lat: 48.4849771, lng: 22.145683 },
    { lat: 48.4854344, lng: 22.1456114 },
    { lat: 48.486248, lng: 22.1458602 },
    { lat: 48.486445, lng: 22.145824 },
    { lat: 48.4873881, lng: 22.1451153 },
    { lat: 48.4877767, lng: 22.144942 },
    { lat: 48.4889569, lng: 22.1441807 },
    { lat: 48.4892566, lng: 22.1441529 },
    { lat: 48.4898251, lng: 22.1443633 },
    { lat: 48.4901591, lng: 22.1448375 },
    { lat: 48.4907911, lng: 22.1469138 },
    { lat: 48.491161, lng: 22.1466613 },
    { lat: 48.4917577, lng: 22.1460581 },
    { lat: 48.4923017, lng: 22.1453588 },
    { lat: 48.4927891, lng: 22.1453756 },
    { lat: 48.4931303, lng: 22.1455811 },
    { lat: 48.4931927, lng: 22.1458423 },
    { lat: 48.4934092, lng: 22.1459696 },
    { lat: 48.4946099, lng: 22.1446312 },
    { lat: 48.4936415, lng: 22.1433861 },
    { lat: 48.4931919, lng: 22.1416661 },
    { lat: 48.4931932, lng: 22.1404191 },
    { lat: 48.4927438, lng: 22.1398183 },
    { lat: 48.4924872, lng: 22.1400032 },
    { lat: 48.4920961, lng: 22.1405966 },
    { lat: 48.4918743, lng: 22.1403449 },
    { lat: 48.4919611, lng: 22.1387653 },
    { lat: 48.4920757, lng: 22.1375581 },
    { lat: 48.4920046, lng: 22.1369253 },
    { lat: 48.4918859, lng: 22.1367571 },
    { lat: 48.4911643, lng: 22.1365833 },
    { lat: 48.4911307, lng: 22.1363974 },
    { lat: 48.4917725, lng: 22.1357106 },
    { lat: 48.4923496, lng: 22.1355598 },
    { lat: 48.4925053, lng: 22.1351937 },
    { lat: 48.49238, lng: 22.1349861 },
    { lat: 48.4911455, lng: 22.1345848 },
    { lat: 48.4911287, lng: 22.134491 },
    { lat: 48.4919926, lng: 22.1328076 },
    { lat: 48.4924477, lng: 22.1325015 },
    { lat: 48.4924565, lng: 22.1320109 },
    { lat: 48.4924102, lng: 22.1318563 },
    { lat: 48.492125, lng: 22.1316394 },
    { lat: 48.4915637, lng: 22.1315724 },
    { lat: 48.4908706, lng: 22.1314897 },
    { lat: 48.4906479, lng: 22.1316863 },
    { lat: 48.49043, lng: 22.1321734 },
    { lat: 48.4903494, lng: 22.1322436 },
    { lat: 48.4902261, lng: 22.1322165 },
    { lat: 48.4895844, lng: 22.1308934 },
    { lat: 48.4894898, lng: 22.130408 },
    { lat: 48.4892055, lng: 22.1301379 },
    { lat: 48.4887484, lng: 22.1306998 },
    { lat: 48.4885953, lng: 22.1309732 },
    { lat: 48.4881786, lng: 22.1310587 },
    { lat: 48.4878748, lng: 22.1312905 },
    { lat: 48.4877269, lng: 22.1316093 },
    { lat: 48.4876085, lng: 22.1316344 },
    { lat: 48.4874012, lng: 22.1314323 },
    { lat: 48.4869948, lng: 22.1314205 },
    { lat: 48.4868985, lng: 22.1315338 },
    { lat: 48.4868395, lng: 22.1315107 },
    { lat: 48.486627, lng: 22.131034 },
    { lat: 48.4863822, lng: 22.1298555 },
    { lat: 48.4860709, lng: 22.1292962 },
    { lat: 48.4858694, lng: 22.1291449 },
    { lat: 48.4858133, lng: 22.1291546 },
    { lat: 48.4850113, lng: 22.1292929 },
    { lat: 48.4847465, lng: 22.1297119 },
    { lat: 48.4845248, lng: 22.1301878 },
    { lat: 48.4842048, lng: 22.1311175 },
    { lat: 48.4834437, lng: 22.1309501 },
    { lat: 48.4830972, lng: 22.1309529 },
    { lat: 48.4830351, lng: 22.1308445 },
    { lat: 48.4830598, lng: 22.1306535 },
    { lat: 48.4839741, lng: 22.1292937 },
    { lat: 48.484065, lng: 22.1286645 },
    { lat: 48.4841351, lng: 22.127285 },
    { lat: 48.4843487, lng: 22.1267527 },
    { lat: 48.4846699, lng: 22.1264474 },
    { lat: 48.4854994, lng: 22.1263157 },
    { lat: 48.4858337, lng: 22.1260894 },
    { lat: 48.4862927, lng: 22.1236513 },
    { lat: 48.4856875, lng: 22.1226452 },
    { lat: 48.4857172, lng: 22.1222359 },
    { lat: 48.4856762, lng: 22.1216849 },
    { lat: 48.4853929, lng: 22.1207834 },
    { lat: 48.4853384, lng: 22.1207214 },
    { lat: 48.4847088, lng: 22.1208748 },
    { lat: 48.484063, lng: 22.1203947 },
    { lat: 48.4837789, lng: 22.1200854 },
    { lat: 48.4831225, lng: 22.1183347 },
    { lat: 48.4828663, lng: 22.1178261 },
    { lat: 48.4825638, lng: 22.1173951 },
    { lat: 48.4819361, lng: 22.1158667 },
    { lat: 48.4814766, lng: 22.1149909 },
    { lat: 48.4811022, lng: 22.1146212 },
    { lat: 48.4805048, lng: 22.1137987 },
    { lat: 48.4803375, lng: 22.1134318 },
    { lat: 48.4798172, lng: 22.1133578 },
    { lat: 48.4797315, lng: 22.1134918 },
    { lat: 48.4792421, lng: 22.1134403 },
    { lat: 48.4782896, lng: 22.113505 },
    { lat: 48.476927, lng: 22.114008 },
    { lat: 48.476723, lng: 22.115425 },
    { lat: 48.476467, lng: 22.115913 },
    { lat: 48.475439, lng: 22.118232 },
    { lat: 48.474813, lng: 22.119358 },
    { lat: 48.474407, lng: 22.120082 },
    { lat: 48.474401, lng: 22.120094 },
    { lat: 48.473959, lng: 22.120708 },
    { lat: 48.473831, lng: 22.120878 },
    { lat: 48.472447, lng: 22.122635 },
    { lat: 48.472065, lng: 22.12313 },
    { lat: 48.471635, lng: 22.12341 },
    { lat: 48.47131, lng: 22.123784 },
    { lat: 48.470777, lng: 22.124196 },
    { lat: 48.469968, lng: 22.124581 },
    { lat: 48.469165, lng: 22.124881 },
    { lat: 48.468166, lng: 22.125255 },
    { lat: 48.465743, lng: 22.126122 },
    { lat: 48.463491, lng: 22.126567 },
    { lat: 48.4633082, lng: 22.1268442 },
    { lat: 48.463078, lng: 22.127093 },
    { lat: 48.46273, lng: 22.127326 },
    { lat: 48.462034, lng: 22.127791 },
    { lat: 48.461665, lng: 22.12815 },
    { lat: 48.461489, lng: 22.128321 },
    { lat: 48.461256, lng: 22.12846 },
    { lat: 48.459577, lng: 22.129978 },
    { lat: 48.459063, lng: 22.130302 },
    { lat: 48.458455, lng: 22.13053 },
    { lat: 48.4583653, lng: 22.130492 },
    { lat: 48.45764, lng: 22.130828 },
    { lat: 48.457203, lng: 22.130996 },
    { lat: 48.455952, lng: 22.13147 },
    { lat: 48.454676, lng: 22.131946 },
    { lat: 48.453279, lng: 22.132422 },
    { lat: 48.452763, lng: 22.132592 },
    { lat: 48.452758, lng: 22.132578 },
    { lat: 48.450998, lng: 22.133222 },
    { lat: 48.45078, lng: 22.13337 },
    { lat: 48.449257, lng: 22.133904 },
    { lat: 48.448514, lng: 22.134217 },
    { lat: 48.448266, lng: 22.134424 },
    { lat: 48.448017, lng: 22.134631 },
    { lat: 48.447754, lng: 22.135063 },
    { lat: 48.447565, lng: 22.135556 },
    { lat: 48.447272, lng: 22.136189 },
    { lat: 48.447061, lng: 22.137023 },
    { lat: 48.446825, lng: 22.13789 },
    { lat: 48.446822, lng: 22.138539 },
    { lat: 48.4476704, lng: 22.1402907 },
    { lat: 48.4476677, lng: 22.1405621 },
  ],
  Kaluža: [
    { lat: 48.7895576, lng: 22.0217583 },
    { lat: 48.7960967, lng: 22.0200015 },
    { lat: 48.8004072, lng: 22.0188428 },
    { lat: 48.8041111, lng: 22.0173004 },
    { lat: 48.8094728, lng: 22.0145353 },
    { lat: 48.8131126, lng: 22.014521 },
    { lat: 48.8183151, lng: 22.0149408 },
    { lat: 48.8191456, lng: 22.0149503 },
    { lat: 48.8218108, lng: 22.0146501 },
    { lat: 48.8232528, lng: 22.014694 },
    { lat: 48.8255949, lng: 22.0148784 },
    { lat: 48.8293106, lng: 22.0149823 },
    { lat: 48.8328616, lng: 22.0152001 },
    { lat: 48.8345721, lng: 22.015374 },
    { lat: 48.8350617, lng: 22.015015 },
    { lat: 48.8360872, lng: 22.0148654 },
    { lat: 48.838759, lng: 22.014891 },
    { lat: 48.838779, lng: 22.014439 },
    { lat: 48.838867, lng: 22.013207 },
    { lat: 48.838908, lng: 22.011579 },
    { lat: 48.838942, lng: 22.010432 },
    { lat: 48.838947, lng: 22.010014 },
    { lat: 48.838953, lng: 22.009263 },
    { lat: 48.839071, lng: 22.007489 },
    { lat: 48.83908, lng: 22.006225 },
    { lat: 48.839214, lng: 22.003617 },
    { lat: 48.839253, lng: 22.002217 },
    { lat: 48.839265, lng: 22.001786 },
    { lat: 48.839267, lng: 22.00172 },
    { lat: 48.839297, lng: 22.000638 },
    { lat: 48.839372, lng: 21.999009 },
    { lat: 48.839369, lng: 21.998937 },
    { lat: 48.839399, lng: 21.99844 },
    { lat: 48.839408, lng: 21.998111 },
    { lat: 48.839412, lng: 21.997976 },
    { lat: 48.839435, lng: 21.99714 },
    { lat: 48.839503, lng: 21.995632 },
    { lat: 48.839536, lng: 21.994883 },
    { lat: 48.839625, lng: 21.992918 },
    { lat: 48.839632, lng: 21.992781 },
    { lat: 48.83971, lng: 21.991052 },
    { lat: 48.841847, lng: 21.991083 },
    { lat: 48.84468, lng: 21.991146 },
    { lat: 48.847481, lng: 21.991203 },
    { lat: 48.847489, lng: 21.991091 },
    { lat: 48.8414347, lng: 21.9907771 },
    { lat: 48.8374514, lng: 21.9904432 },
    { lat: 48.8374313, lng: 21.9905008 },
    { lat: 48.8364464, lng: 21.9908425 },
    { lat: 48.8358816, lng: 21.9909756 },
    { lat: 48.8329614, lng: 21.9904699 },
    { lat: 48.8193557, lng: 21.9906435 },
    { lat: 48.8189063, lng: 21.9909447 },
    { lat: 48.8187644, lng: 21.9908891 },
    { lat: 48.8187633, lng: 21.990941 },
    { lat: 48.8179807, lng: 21.9913633 },
    { lat: 48.8179591, lng: 21.9914423 },
    { lat: 48.8168082, lng: 21.9895149 },
    { lat: 48.8146015, lng: 21.9885596 },
    { lat: 48.8127063, lng: 21.9892603 },
    { lat: 48.8121886, lng: 21.9892962 },
    { lat: 48.8108888, lng: 21.9888114 },
    { lat: 48.8102649, lng: 21.9888743 },
    { lat: 48.8096479, lng: 21.9890792 },
    { lat: 48.8086388, lng: 21.988667 },
    { lat: 48.8069094, lng: 21.9891971 },
    { lat: 48.8065913, lng: 21.9893952 },
    { lat: 48.8071673, lng: 21.9911422 },
    { lat: 48.8072868, lng: 21.9916526 },
    { lat: 48.8064992, lng: 21.9935354 },
    { lat: 48.8058646, lng: 21.994218 },
    { lat: 48.8054746, lng: 21.9958775 },
    { lat: 48.8047931, lng: 21.9972226 },
    { lat: 48.8043777, lng: 21.997533 },
    { lat: 48.8034602, lng: 21.9977824 },
    { lat: 48.8028198, lng: 21.9982255 },
    { lat: 48.8019694, lng: 21.9990128 },
    { lat: 48.8013967, lng: 21.9990042 },
    { lat: 48.8007081, lng: 21.9987709 },
    { lat: 48.8001233, lng: 21.999417 },
    { lat: 48.7999287, lng: 21.9995078 },
    { lat: 48.7996976, lng: 21.9995354 },
    { lat: 48.7992943, lng: 21.9993692 },
    { lat: 48.7862899, lng: 22.0028934 },
    { lat: 48.7838315, lng: 22.0035594 },
    { lat: 48.7887174, lng: 22.0167999 },
    { lat: 48.7895576, lng: 22.0217583 },
  ],
  Hažín: [
    { lat: 48.7106901, lng: 22.0481346 },
    { lat: 48.7106288, lng: 22.0489597 },
    { lat: 48.7105196, lng: 22.0506804 },
    { lat: 48.7105575, lng: 22.0526782 },
    { lat: 48.7103195, lng: 22.0530272 },
    { lat: 48.7101023, lng: 22.0535922 },
    { lat: 48.7099412, lng: 22.0538642 },
    { lat: 48.7074147, lng: 22.0562461 },
    { lat: 48.707259, lng: 22.0564676 },
    { lat: 48.7070937, lng: 22.0568004 },
    { lat: 48.7069581, lng: 22.0573396 },
    { lat: 48.7069669, lng: 22.060006 },
    { lat: 48.7068571, lng: 22.0607268 },
    { lat: 48.7066105, lng: 22.0612763 },
    { lat: 48.7052468, lng: 22.063331 },
    { lat: 48.7030604, lng: 22.0664016 },
    { lat: 48.7034308, lng: 22.0685873 },
    { lat: 48.7033069, lng: 22.0697564 },
    { lat: 48.70247, lng: 22.073189 },
    { lat: 48.70278, lng: 22.073547 },
    { lat: 48.703196, lng: 22.074026 },
    { lat: 48.703701, lng: 22.074589 },
    { lat: 48.704739, lng: 22.075457 },
    { lat: 48.705528, lng: 22.076086 },
    { lat: 48.705847, lng: 22.076346 },
    { lat: 48.707123, lng: 22.077361 },
    { lat: 48.707447, lng: 22.077614 },
    { lat: 48.707935, lng: 22.07801 },
    { lat: 48.708467, lng: 22.078376 },
    { lat: 48.709685, lng: 22.078448 },
    { lat: 48.710586, lng: 22.078418 },
    { lat: 48.711139, lng: 22.078405 },
    { lat: 48.713057, lng: 22.078249 },
    { lat: 48.714074, lng: 22.07814 },
    { lat: 48.715111, lng: 22.078024 },
    { lat: 48.715996, lng: 22.077667 },
    { lat: 48.717032, lng: 22.077171 },
    { lat: 48.717614, lng: 22.076536 },
    { lat: 48.718258, lng: 22.07582 },
    { lat: 48.719081, lng: 22.074954 },
    { lat: 48.71944, lng: 22.074537 },
    { lat: 48.719816, lng: 22.074157 },
    { lat: 48.720557, lng: 22.073363 },
    { lat: 48.720808, lng: 22.073083 },
    { lat: 48.721341, lng: 22.072692 },
    { lat: 48.721944, lng: 22.072322 },
    { lat: 48.722194, lng: 22.072241 },
    { lat: 48.723081, lng: 22.072009 },
    { lat: 48.723522, lng: 22.071874 },
    { lat: 48.723935, lng: 22.07174 },
    { lat: 48.724074, lng: 22.071719 },
    { lat: 48.724837, lng: 22.071552 },
    { lat: 48.725731, lng: 22.07131 },
    { lat: 48.726615, lng: 22.071071 },
    { lat: 48.727032, lng: 22.070904 },
    { lat: 48.727486, lng: 22.070672 },
    { lat: 48.728353, lng: 22.070348 },
    { lat: 48.728358, lng: 22.070346 },
    { lat: 48.729234, lng: 22.069976 },
    { lat: 48.730085, lng: 22.06959 },
    { lat: 48.730974, lng: 22.069244 },
    { lat: 48.731842, lng: 22.068875 },
    { lat: 48.732642, lng: 22.068546 },
    { lat: 48.732726, lng: 22.068532 },
    { lat: 48.732927, lng: 22.068427 },
    { lat: 48.732954, lng: 22.068413 },
    { lat: 48.732984, lng: 22.068397 },
    { lat: 48.733012, lng: 22.068381 },
    { lat: 48.733543, lng: 22.067987 },
    { lat: 48.734334, lng: 22.067341 },
    { lat: 48.735106, lng: 22.066656 },
    { lat: 48.735576, lng: 22.06624 },
    { lat: 48.736654, lng: 22.065273 },
    { lat: 48.736658, lng: 22.065269 },
    { lat: 48.73743, lng: 22.064586 },
    { lat: 48.738199, lng: 22.063903 },
    { lat: 48.738802, lng: 22.063346 },
    { lat: 48.740514, lng: 22.061876 },
    { lat: 48.740949, lng: 22.061677 },
    { lat: 48.743587, lng: 22.060599 },
    { lat: 48.744556, lng: 22.060173 },
    { lat: 48.745176, lng: 22.059785 },
    { lat: 48.745203, lng: 22.05977 },
    { lat: 48.745261, lng: 22.059746 },
    { lat: 48.74622, lng: 22.059175 },
    { lat: 48.746963, lng: 22.058733 },
    { lat: 48.747678, lng: 22.058288 },
    { lat: 48.74856, lng: 22.057738 },
    { lat: 48.748615, lng: 22.057704 },
    { lat: 48.749683, lng: 22.057037 },
    { lat: 48.749675, lng: 22.057136 },
    { lat: 48.749701, lng: 22.057227 },
    { lat: 48.74964, lng: 22.057395 },
    { lat: 48.7501445, lng: 22.0570471 },
    { lat: 48.7505834, lng: 22.0564459 },
    { lat: 48.7531711, lng: 22.0517782 },
    { lat: 48.7531479, lng: 22.0516975 },
    { lat: 48.7523119, lng: 22.0479416 },
    { lat: 48.751762, lng: 22.0449575 },
    { lat: 48.7516295, lng: 22.0441221 },
    { lat: 48.7514832, lng: 22.0424901 },
    { lat: 48.7510588, lng: 22.0398663 },
    { lat: 48.751616, lng: 22.0388079 },
    { lat: 48.7520089, lng: 22.0372313 },
    { lat: 48.7532361, lng: 22.0356442 },
    { lat: 48.7534292, lng: 22.0344622 },
    { lat: 48.7554482, lng: 22.0334312 },
    { lat: 48.7551128, lng: 22.0323104 },
    { lat: 48.7563684, lng: 22.0312478 },
    { lat: 48.7585738, lng: 22.0300691 },
    { lat: 48.759539, lng: 22.0293743 },
    { lat: 48.7617807, lng: 22.0290345 },
    { lat: 48.7620784, lng: 22.0288843 },
    { lat: 48.7630878, lng: 22.0281578 },
    { lat: 48.7648395, lng: 22.0274197 },
    { lat: 48.7663685, lng: 22.0265487 },
    { lat: 48.7661244, lng: 22.0246938 },
    { lat: 48.7643229, lng: 22.0128103 },
    { lat: 48.7635715, lng: 22.0081006 },
    { lat: 48.7601592, lng: 22.0090093 },
    { lat: 48.7579278, lng: 22.0101287 },
    { lat: 48.7564133, lng: 22.0107582 },
    { lat: 48.7557096, lng: 22.0111889 },
    { lat: 48.7540971, lng: 22.0120056 },
    { lat: 48.7529095, lng: 22.0125276 },
    { lat: 48.752241, lng: 22.0129259 },
    { lat: 48.7519588, lng: 22.0119157 },
    { lat: 48.7518263, lng: 22.0110558 },
    { lat: 48.7517867, lng: 22.0098823 },
    { lat: 48.7520539, lng: 22.0074612 },
    { lat: 48.7529221, lng: 22.0010028 },
    { lat: 48.7520181, lng: 22.0015742 },
    { lat: 48.7518657, lng: 22.0015092 },
    { lat: 48.7516701, lng: 22.0012799 },
    { lat: 48.751104, lng: 22.0013618 },
    { lat: 48.7497774, lng: 22.0018569 },
    { lat: 48.748384, lng: 22.0027418 },
    { lat: 48.7469373, lng: 22.0037116 },
    { lat: 48.7420268, lng: 22.0080328 },
    { lat: 48.7412258, lng: 22.0098213 },
    { lat: 48.7405457, lng: 22.0110481 },
    { lat: 48.7404451, lng: 22.0113998 },
    { lat: 48.7407635, lng: 22.0167619 },
    { lat: 48.7407571, lng: 22.0170531 },
    { lat: 48.7404683, lng: 22.0192413 },
    { lat: 48.740326, lng: 22.019585 },
    { lat: 48.7402374, lng: 22.0196451 },
    { lat: 48.7388583, lng: 22.0202513 },
    { lat: 48.7386822, lng: 22.0204075 },
    { lat: 48.7385555, lng: 22.0207032 },
    { lat: 48.7384888, lng: 22.0216635 },
    { lat: 48.7383641, lng: 22.0221287 },
    { lat: 48.7379464, lng: 22.0228079 },
    { lat: 48.7377607, lng: 22.0233656 },
    { lat: 48.7377281, lng: 22.0237334 },
    { lat: 48.7380996, lng: 22.0270856 },
    { lat: 48.7332839, lng: 22.0279679 },
    { lat: 48.7331452, lng: 22.0274953 },
    { lat: 48.7329354, lng: 22.0267735 },
    { lat: 48.73306, lng: 22.0278823 },
    { lat: 48.7330731, lng: 22.0280024 },
    { lat: 48.7280896, lng: 22.0288739 },
    { lat: 48.7284847, lng: 22.0316217 },
    { lat: 48.7289754, lng: 22.0344746 },
    { lat: 48.7295253, lng: 22.0372634 },
    { lat: 48.7299586, lng: 22.0399746 },
    { lat: 48.7303982, lng: 22.042161 },
    { lat: 48.7277489, lng: 22.0463089 },
    { lat: 48.7275085, lng: 22.0465081 },
    { lat: 48.727328, lng: 22.0465771 },
    { lat: 48.7259229, lng: 22.0464317 },
    { lat: 48.7257458, lng: 22.0464603 },
    { lat: 48.725441, lng: 22.0466563 },
    { lat: 48.7243158, lng: 22.0481308 },
    { lat: 48.7241967, lng: 22.0484216 },
    { lat: 48.7241377, lng: 22.048769 },
    { lat: 48.7241377, lng: 22.0491941 },
    { lat: 48.7241931, lng: 22.0495359 },
    { lat: 48.7232008, lng: 22.0503246 },
    { lat: 48.7224616, lng: 22.0504521 },
    { lat: 48.7222514, lng: 22.0504134 },
    { lat: 48.7217846, lng: 22.0501359 },
    { lat: 48.7217287, lng: 22.0499601 },
    { lat: 48.7217354, lng: 22.0497278 },
    { lat: 48.719358, lng: 22.050034 },
    { lat: 48.7184072, lng: 22.0418934 },
    { lat: 48.7180536, lng: 22.0418726 },
    { lat: 48.7179193, lng: 22.0421896 },
    { lat: 48.7175612, lng: 22.0426354 },
    { lat: 48.7143652, lng: 22.0461339 },
    { lat: 48.7138583, lng: 22.046476 },
    { lat: 48.7106901, lng: 22.0481346 },
  ],
  Pozdišovce: [
    { lat: 48.7466563, lng: 21.819177 },
    { lat: 48.7465766, lng: 21.8193125 },
    { lat: 48.7466651, lng: 21.8195129 },
    { lat: 48.7464625, lng: 21.8199011 },
    { lat: 48.7462287, lng: 21.8200369 },
    { lat: 48.7461373, lng: 21.820422 },
    { lat: 48.7459284, lng: 21.8204736 },
    { lat: 48.7458385, lng: 21.8206069 },
    { lat: 48.7458642, lng: 21.8211608 },
    { lat: 48.7456053, lng: 21.8215027 },
    { lat: 48.7454675, lng: 21.8218349 },
    { lat: 48.7454476, lng: 21.8221926 },
    { lat: 48.7452757, lng: 21.8221564 },
    { lat: 48.7450612, lng: 21.8224739 },
    { lat: 48.7448193, lng: 21.8226462 },
    { lat: 48.7446125, lng: 21.8226058 },
    { lat: 48.7445999, lng: 21.8228215 },
    { lat: 48.7443657, lng: 21.8228528 },
    { lat: 48.7443217, lng: 21.8230187 },
    { lat: 48.743906, lng: 21.8232979 },
    { lat: 48.7437885, lng: 21.8232846 },
    { lat: 48.7436574, lng: 21.8233932 },
    { lat: 48.7434545, lng: 21.823895 },
    { lat: 48.7432226, lng: 21.8239345 },
    { lat: 48.7433068, lng: 21.824056 },
    { lat: 48.7432778, lng: 21.8241982 },
    { lat: 48.7429598, lng: 21.8239925 },
    { lat: 48.743007, lng: 21.8246374 },
    { lat: 48.742891, lng: 21.8245477 },
    { lat: 48.7427928, lng: 21.8246704 },
    { lat: 48.7425056, lng: 21.8245652 },
    { lat: 48.7424134, lng: 21.8249203 },
    { lat: 48.7420603, lng: 21.825265 },
    { lat: 48.7420955, lng: 21.8254956 },
    { lat: 48.741977, lng: 21.8259165 },
    { lat: 48.7416794, lng: 21.825979 },
    { lat: 48.7414448, lng: 21.8259577 },
    { lat: 48.7411307, lng: 21.8251298 },
    { lat: 48.7410414, lng: 21.8247213 },
    { lat: 48.7409164, lng: 21.8245833 },
    { lat: 48.7408742, lng: 21.824085 },
    { lat: 48.7406569, lng: 21.8236516 },
    { lat: 48.740623, lng: 21.8232099 },
    { lat: 48.7406585, lng: 21.8229315 },
    { lat: 48.7403455, lng: 21.8225705 },
    { lat: 48.7402802, lng: 21.8222711 },
    { lat: 48.7398339, lng: 21.8220171 },
    { lat: 48.7397299, lng: 21.8217431 },
    { lat: 48.7395104, lng: 21.8218717 },
    { lat: 48.7393979, lng: 21.8217425 },
    { lat: 48.7385021, lng: 21.8215085 },
    { lat: 48.7382466, lng: 21.8205125 },
    { lat: 48.7379206, lng: 21.8199592 },
    { lat: 48.7376858, lng: 21.8196698 },
    { lat: 48.7367685, lng: 21.8198701 },
    { lat: 48.7358477, lng: 21.8202417 },
    { lat: 48.7350785, lng: 21.8201638 },
    { lat: 48.7340751, lng: 21.8209932 },
    { lat: 48.7335366, lng: 21.8212661 },
    { lat: 48.7332774, lng: 21.8213332 },
    { lat: 48.7329729, lng: 21.8213107 },
    { lat: 48.7324469, lng: 21.8216235 },
    { lat: 48.7317317, lng: 21.8211638 },
    { lat: 48.731402, lng: 21.821378 },
    { lat: 48.7310834, lng: 21.821846 },
    { lat: 48.7305265, lng: 21.8219321 },
    { lat: 48.7301305, lng: 21.8223608 },
    { lat: 48.7297055, lng: 21.8232435 },
    { lat: 48.7295923, lng: 21.8239957 },
    { lat: 48.7293329, lng: 21.8244462 },
    { lat: 48.7288223, lng: 21.8251447 },
    { lat: 48.7272532, lng: 21.8267982 },
    { lat: 48.725464, lng: 21.8284304 },
    { lat: 48.7248989, lng: 21.829075 },
    { lat: 48.7242935, lng: 21.8294996 },
    { lat: 48.7240238, lng: 21.8297714 },
    { lat: 48.7235188, lng: 21.8305086 },
    { lat: 48.7233053, lng: 21.8305662 },
    { lat: 48.7232035, lng: 21.8306755 },
    { lat: 48.7230925, lng: 21.8309963 },
    { lat: 48.7223962, lng: 21.8284833 },
    { lat: 48.721171, lng: 21.8235421 },
    { lat: 48.720708, lng: 21.8238635 },
    { lat: 48.7203406, lng: 21.8252656 },
    { lat: 48.7194782, lng: 21.8274491 },
    { lat: 48.7191258, lng: 21.8278323 },
    { lat: 48.7186615, lng: 21.8279283 },
    { lat: 48.718324, lng: 21.8282319 },
    { lat: 48.7161275, lng: 21.8308662 },
    { lat: 48.7153983, lng: 21.8319375 },
    { lat: 48.7141994, lng: 21.8332126 },
    { lat: 48.7138659, lng: 21.8334322 },
    { lat: 48.7137636, lng: 21.8334995 },
    { lat: 48.7134256, lng: 21.8338308 },
    { lat: 48.7128247, lng: 21.8349957 },
    { lat: 48.7128643, lng: 21.8350828 },
    { lat: 48.7128747, lng: 21.8351056 },
    { lat: 48.7128051, lng: 21.8352846 },
    { lat: 48.7127488, lng: 21.8357133 },
    { lat: 48.7125643, lng: 21.8357164 },
    { lat: 48.7124625, lng: 21.8358667 },
    { lat: 48.7123749, lng: 21.8360884 },
    { lat: 48.7123734, lng: 21.8363767 },
    { lat: 48.7122834, lng: 21.8364697 },
    { lat: 48.7118542, lng: 21.8364925 },
    { lat: 48.7117119, lng: 21.8368144 },
    { lat: 48.7115282, lng: 21.8370643 },
    { lat: 48.7113215, lng: 21.8371118 },
    { lat: 48.7110165, lng: 21.8373721 },
    { lat: 48.7106899, lng: 21.8370569 },
    { lat: 48.7102334, lng: 21.8372377 },
    { lat: 48.7100304, lng: 21.8373983 },
    { lat: 48.7096106, lng: 21.8372086 },
    { lat: 48.7093986, lng: 21.8373248 },
    { lat: 48.7099629, lng: 21.8392168 },
    { lat: 48.7089437, lng: 21.8409227 },
    { lat: 48.7109382, lng: 21.8431318 },
    { lat: 48.7097295, lng: 21.8458141 },
    { lat: 48.7093296, lng: 21.8469373 },
    { lat: 48.7091101, lng: 21.8467967 },
    { lat: 48.7089817, lng: 21.8472655 },
    { lat: 48.708883, lng: 21.8471958 },
    { lat: 48.7087399, lng: 21.847709 },
    { lat: 48.7085615, lng: 21.8475083 },
    { lat: 48.7084238, lng: 21.8484521 },
    { lat: 48.7079302, lng: 21.8486817 },
    { lat: 48.7081388, lng: 21.8511041 },
    { lat: 48.7078227, lng: 21.851674 },
    { lat: 48.7081082, lng: 21.8522329 },
    { lat: 48.708593, lng: 21.8538824 },
    { lat: 48.7087041, lng: 21.8540529 },
    { lat: 48.708833, lng: 21.8554794 },
    { lat: 48.7088278, lng: 21.8557993 },
    { lat: 48.7089455, lng: 21.856607 },
    { lat: 48.7088069, lng: 21.8577838 },
    { lat: 48.7087976, lng: 21.857861 },
    { lat: 48.7091001, lng: 21.8577702 },
    { lat: 48.7091817, lng: 21.8577455 },
    { lat: 48.7152775, lng: 21.8668465 },
    { lat: 48.7158926, lng: 21.871223 },
    { lat: 48.7173584, lng: 21.872675 },
    { lat: 48.7182802, lng: 21.8734449 },
    { lat: 48.7185299, lng: 21.8735735 },
    { lat: 48.718722, lng: 21.8737771 },
    { lat: 48.7184483, lng: 21.874165 },
    { lat: 48.7182214, lng: 21.8741758 },
    { lat: 48.7181808, lng: 21.8742576 },
    { lat: 48.716571, lng: 21.874613 },
    { lat: 48.7167684, lng: 21.8765702 },
    { lat: 48.7169091, lng: 21.8775032 },
    { lat: 48.7181611, lng: 21.8771438 },
    { lat: 48.7185318, lng: 21.8771646 },
    { lat: 48.7192375, lng: 21.8773557 },
    { lat: 48.7191475, lng: 21.8760827 },
    { lat: 48.7199235, lng: 21.8757588 },
    { lat: 48.7209581, lng: 21.8754768 },
    { lat: 48.7209355, lng: 21.8746409 },
    { lat: 48.7219636, lng: 21.8744212 },
    { lat: 48.723281, lng: 21.8742385 },
    { lat: 48.7232802, lng: 21.8740758 },
    { lat: 48.7237881, lng: 21.8739267 },
    { lat: 48.7246261, lng: 21.8737855 },
    { lat: 48.726227, lng: 21.8739233 },
    { lat: 48.7263422, lng: 21.8747775 },
    { lat: 48.726794, lng: 21.8747558 },
    { lat: 48.7276737, lng: 21.8745776 },
    { lat: 48.7285219, lng: 21.8746667 },
    { lat: 48.7285242, lng: 21.8747114 },
    { lat: 48.7296977, lng: 21.8735671 },
    { lat: 48.729479, lng: 21.8687133 },
    { lat: 48.7293705, lng: 21.8682125 },
    { lat: 48.7294058, lng: 21.8678349 },
    { lat: 48.7293618, lng: 21.8675691 },
    { lat: 48.7310064, lng: 21.8672969 },
    { lat: 48.7309025, lng: 21.8660122 },
    { lat: 48.7312417, lng: 21.8656331 },
    { lat: 48.7312368, lng: 21.8641724 },
    { lat: 48.7311741, lng: 21.862912 },
    { lat: 48.7341216, lng: 21.8629952 },
    { lat: 48.7342099, lng: 21.8592548 },
    { lat: 48.7347976, lng: 21.8591337 },
    { lat: 48.7365896, lng: 21.8593054 },
    { lat: 48.7452768, lng: 21.8664606 },
    { lat: 48.7461556, lng: 21.8666832 },
    { lat: 48.7470053, lng: 21.866308 },
    { lat: 48.7503637, lng: 21.8643382 },
    { lat: 48.7506806, lng: 21.8640223 },
    { lat: 48.7510451, lng: 21.8634897 },
    { lat: 48.7511392, lng: 21.8636921 },
    { lat: 48.7527458, lng: 21.8641811 },
    { lat: 48.7535725, lng: 21.8636475 },
    { lat: 48.7543117, lng: 21.8635229 },
    { lat: 48.7544898, lng: 21.8635377 },
    { lat: 48.7547315, lng: 21.8637903 },
    { lat: 48.7549315, lng: 21.8638762 },
    { lat: 48.755186, lng: 21.8638339 },
    { lat: 48.7553509, lng: 21.8633803 },
    { lat: 48.7554486, lng: 21.8632434 },
    { lat: 48.7558112, lng: 21.8634781 },
    { lat: 48.7558486, lng: 21.8636221 },
    { lat: 48.7564163, lng: 21.8634295 },
    { lat: 48.7565186, lng: 21.8643469 },
    { lat: 48.7571793, lng: 21.8642722 },
    { lat: 48.7576119, lng: 21.8643381 },
    { lat: 48.7578382, lng: 21.8648226 },
    { lat: 48.7579631, lng: 21.8652285 },
    { lat: 48.7584034, lng: 21.8651032 },
    { lat: 48.7585876, lng: 21.8649263 },
    { lat: 48.7588945, lng: 21.8648227 },
    { lat: 48.7592536, lng: 21.8648355 },
    { lat: 48.7598676, lng: 21.8649946 },
    { lat: 48.7602277, lng: 21.8648259 },
    { lat: 48.7605468, lng: 21.8647666 },
    { lat: 48.76087, lng: 21.8649619 },
    { lat: 48.760912, lng: 21.8661112 },
    { lat: 48.7607084, lng: 21.8667785 },
    { lat: 48.7603754, lng: 21.8674348 },
    { lat: 48.7600617, lng: 21.8684968 },
    { lat: 48.7602765, lng: 21.8695518 },
    { lat: 48.7607471, lng: 21.8697364 },
    { lat: 48.7611088, lng: 21.8695006 },
    { lat: 48.7611331, lng: 21.8692977 },
    { lat: 48.7612343, lng: 21.869208 },
    { lat: 48.7616032, lng: 21.8691247 },
    { lat: 48.7617719, lng: 21.8689005 },
    { lat: 48.7619564, lng: 21.8681984 },
    { lat: 48.7618899, lng: 21.8674046 },
    { lat: 48.7620016, lng: 21.8670622 },
    { lat: 48.7620371, lng: 21.8665612 },
    { lat: 48.7621069, lng: 21.8664334 },
    { lat: 48.7631458, lng: 21.8668026 },
    { lat: 48.7631742, lng: 21.8662903 },
    { lat: 48.7677771, lng: 21.8652087 },
    { lat: 48.7680972, lng: 21.8683103 },
    { lat: 48.7681995, lng: 21.8679602 },
    { lat: 48.768301, lng: 21.8678377 },
    { lat: 48.7682877, lng: 21.867796 },
    { lat: 48.768262, lng: 21.8673599 },
    { lat: 48.7679775, lng: 21.866535 },
    { lat: 48.7678472, lng: 21.8658836 },
    { lat: 48.7678785, lng: 21.8657894 },
    { lat: 48.7685854, lng: 21.8658043 },
    { lat: 48.7691943, lng: 21.8653343 },
    { lat: 48.7689803, lng: 21.8641389 },
    { lat: 48.7686861, lng: 21.8642393 },
    { lat: 48.7681865, lng: 21.8624815 },
    { lat: 48.7680583, lng: 21.8623985 },
    { lat: 48.7679657, lng: 21.8613337 },
    { lat: 48.7680592, lng: 21.8612006 },
    { lat: 48.7680672, lng: 21.86099 },
    { lat: 48.7678778, lng: 21.8604912 },
    { lat: 48.7676189, lng: 21.8600906 },
    { lat: 48.7675703, lng: 21.8599073 },
    { lat: 48.7675322, lng: 21.8593389 },
    { lat: 48.7675652, lng: 21.8587889 },
    { lat: 48.7674184, lng: 21.857973 },
    { lat: 48.767447, lng: 21.8577302 },
    { lat: 48.7675866, lng: 21.8575651 },
    { lat: 48.7674507, lng: 21.8573049 },
    { lat: 48.7674123, lng: 21.8573421 },
    { lat: 48.7671865, lng: 21.8571414 },
    { lat: 48.7671075, lng: 21.8569913 },
    { lat: 48.7669707, lng: 21.8565775 },
    { lat: 48.7668794, lng: 21.8560864 },
    { lat: 48.7663088, lng: 21.8560754 },
    { lat: 48.7661681, lng: 21.8554161 },
    { lat: 48.7652449, lng: 21.8558284 },
    { lat: 48.7647541, lng: 21.8562113 },
    { lat: 48.7645897, lng: 21.8562082 },
    { lat: 48.7644099, lng: 21.8560822 },
    { lat: 48.7642304, lng: 21.8556652 },
    { lat: 48.7640161, lng: 21.8554624 },
    { lat: 48.7638873, lng: 21.8555355 },
    { lat: 48.763867, lng: 21.8557075 },
    { lat: 48.763961, lng: 21.8560354 },
    { lat: 48.7639444, lng: 21.8562243 },
    { lat: 48.7637995, lng: 21.856623 },
    { lat: 48.7636862, lng: 21.8567248 },
    { lat: 48.7634714, lng: 21.8566879 },
    { lat: 48.7634177, lng: 21.8567813 },
    { lat: 48.7634173, lng: 21.8568569 },
    { lat: 48.7636744, lng: 21.8571855 },
    { lat: 48.763691, lng: 21.8573086 },
    { lat: 48.7636345, lng: 21.8573405 },
    { lat: 48.7634616, lng: 21.8572686 },
    { lat: 48.7632323, lng: 21.8571437 },
    { lat: 48.763155, lng: 21.8570245 },
    { lat: 48.7630489, lng: 21.857008 },
    { lat: 48.762889, lng: 21.8571778 },
    { lat: 48.7628547, lng: 21.8573763 },
    { lat: 48.7627477, lng: 21.8574607 },
    { lat: 48.7624889, lng: 21.8571054 },
    { lat: 48.7623549, lng: 21.8570188 },
    { lat: 48.7622254, lng: 21.8570528 },
    { lat: 48.761927, lng: 21.8573459 },
    { lat: 48.7618165, lng: 21.8572954 },
    { lat: 48.7616681, lng: 21.8571258 },
    { lat: 48.7616328, lng: 21.8569785 },
    { lat: 48.7616655, lng: 21.8562802 },
    { lat: 48.7616001, lng: 21.8561022 },
    { lat: 48.7613838, lng: 21.8560881 },
    { lat: 48.7611687, lng: 21.8562117 },
    { lat: 48.761091, lng: 21.8554624 },
    { lat: 48.7610211, lng: 21.8553196 },
    { lat: 48.7609162, lng: 21.8552666 },
    { lat: 48.7604503, lng: 21.8553097 },
    { lat: 48.7604046, lng: 21.8547123 },
    { lat: 48.7598333, lng: 21.8548938 },
    { lat: 48.7590805, lng: 21.854624 },
    { lat: 48.7585514, lng: 21.8535202 },
    { lat: 48.7579649, lng: 21.8507415 },
    { lat: 48.7578517, lng: 21.8504561 },
    { lat: 48.7578267, lng: 21.8504016 },
    { lat: 48.7575049, lng: 21.8507939 },
    { lat: 48.7569429, lng: 21.84946 },
    { lat: 48.7575542, lng: 21.8482513 },
    { lat: 48.7585623, lng: 21.8471643 },
    { lat: 48.7590205, lng: 21.8464748 },
    { lat: 48.7598355, lng: 21.8454074 },
    { lat: 48.7598749, lng: 21.8454528 },
    { lat: 48.7600186, lng: 21.8448293 },
    { lat: 48.7602488, lng: 21.8442462 },
    { lat: 48.7603132, lng: 21.8441686 },
    { lat: 48.7603396, lng: 21.8442305 },
    { lat: 48.7605883, lng: 21.8441478 },
    { lat: 48.7605119, lng: 21.8440642 },
    { lat: 48.7607001, lng: 21.8436395 },
    { lat: 48.7608285, lng: 21.843122 },
    { lat: 48.7607228, lng: 21.8429482 },
    { lat: 48.7611443, lng: 21.8421991 },
    { lat: 48.7614077, lng: 21.8420263 },
    { lat: 48.7609906, lng: 21.8411652 },
    { lat: 48.7613216, lng: 21.8404343 },
    { lat: 48.7610775, lng: 21.8393448 },
    { lat: 48.7611662, lng: 21.8389904 },
    { lat: 48.7612053, lng: 21.8382429 },
    { lat: 48.7613192, lng: 21.8377701 },
    { lat: 48.7613457, lng: 21.8373157 },
    { lat: 48.7611969, lng: 21.8373523 },
    { lat: 48.7610934, lng: 21.8370033 },
    { lat: 48.7609314, lng: 21.8370856 },
    { lat: 48.7607723, lng: 21.8368486 },
    { lat: 48.7604692, lng: 21.8366137 },
    { lat: 48.7602148, lng: 21.8354842 },
    { lat: 48.7600431, lng: 21.835499 },
    { lat: 48.7599571, lng: 21.8349514 },
    { lat: 48.7598394, lng: 21.8349334 },
    { lat: 48.7596049, lng: 21.8346071 },
    { lat: 48.759109, lng: 21.8346097 },
    { lat: 48.7588296, lng: 21.8329879 },
    { lat: 48.7586443, lng: 21.8312207 },
    { lat: 48.7587202, lng: 21.8300656 },
    { lat: 48.7585661, lng: 21.8292871 },
    { lat: 48.7584224, lng: 21.8292999 },
    { lat: 48.7584631, lng: 21.828005 },
    { lat: 48.7586041, lng: 21.827415 },
    { lat: 48.7600112, lng: 21.8264055 },
    { lat: 48.7597364, lng: 21.8263063 },
    { lat: 48.7592166, lng: 21.8259186 },
    { lat: 48.7586451, lng: 21.8256526 },
    { lat: 48.757552, lng: 21.8253638 },
    { lat: 48.7568976, lng: 21.8245095 },
    { lat: 48.7560877, lng: 21.8224794 },
    { lat: 48.755949, lng: 21.8216433 },
    { lat: 48.7557479, lng: 21.8210534 },
    { lat: 48.7551566, lng: 21.8201177 },
    { lat: 48.7546966, lng: 21.8190805 },
    { lat: 48.7541984, lng: 21.8180117 },
    { lat: 48.7537445, lng: 21.8171634 },
    { lat: 48.7535493, lng: 21.8158969 },
    { lat: 48.7535145, lng: 21.8140365 },
    { lat: 48.752879, lng: 21.8141285 },
    { lat: 48.7525794, lng: 21.8143753 },
    { lat: 48.7524985, lng: 21.8143352 },
    { lat: 48.7523436, lng: 21.8144357 },
    { lat: 48.752277, lng: 21.8143851 },
    { lat: 48.7521632, lng: 21.8144607 },
    { lat: 48.7519005, lng: 21.8143148 },
    { lat: 48.7514894, lng: 21.8146866 },
    { lat: 48.75086, lng: 21.814487 },
    { lat: 48.750433, lng: 21.8148572 },
    { lat: 48.7500384, lng: 21.8150846 },
    { lat: 48.7500272, lng: 21.8152988 },
    { lat: 48.7497377, lng: 21.8153339 },
    { lat: 48.7494304, lng: 21.815457 },
    { lat: 48.7492378, lng: 21.8156631 },
    { lat: 48.7491775, lng: 21.8158096 },
    { lat: 48.7490276, lng: 21.8158838 },
    { lat: 48.7490312, lng: 21.8161502 },
    { lat: 48.7489628, lng: 21.8162484 },
    { lat: 48.7485931, lng: 21.8164376 },
    { lat: 48.7484924, lng: 21.8167174 },
    { lat: 48.7482972, lng: 21.8167807 },
    { lat: 48.7481577, lng: 21.8169548 },
    { lat: 48.748093, lng: 21.8168856 },
    { lat: 48.7479175, lng: 21.8169381 },
    { lat: 48.7477697, lng: 21.8172638 },
    { lat: 48.7477863, lng: 21.8173854 },
    { lat: 48.7475098, lng: 21.8178671 },
    { lat: 48.7473885, lng: 21.8181795 },
    { lat: 48.7466563, lng: 21.819177 },
  ],
  PetrovcenadLaborcom: [
    { lat: 48.8138311, lng: 21.8806017 },
    { lat: 48.8137495, lng: 21.8803987 },
    { lat: 48.8136369, lng: 21.8806116 },
    { lat: 48.8131778, lng: 21.8818041 },
    { lat: 48.812723, lng: 21.883338 },
    { lat: 48.8113301, lng: 21.881162 },
    { lat: 48.8104487, lng: 21.8791069 },
    { lat: 48.809761, lng: 21.8781217 },
    { lat: 48.809453, lng: 21.8768119 },
    { lat: 48.8091572, lng: 21.8758802 },
    { lat: 48.808868, lng: 21.8754381 },
    { lat: 48.8085726, lng: 21.8753737 },
    { lat: 48.8082981, lng: 21.8754151 },
    { lat: 48.8082755, lng: 21.8751037 },
    { lat: 48.8084869, lng: 21.8748779 },
    { lat: 48.8085955, lng: 21.8745882 },
    { lat: 48.8086139, lng: 21.8737317 },
    { lat: 48.8084729, lng: 21.8731382 },
    { lat: 48.8086666, lng: 21.8729666 },
    { lat: 48.8085613, lng: 21.8724527 },
    { lat: 48.8082362, lng: 21.8724157 },
    { lat: 48.8076993, lng: 21.8725317 },
    { lat: 48.8073032, lng: 21.8719093 },
    { lat: 48.8069907, lng: 21.8709079 },
    { lat: 48.8059961, lng: 21.8682213 },
    { lat: 48.805868, lng: 21.8663706 },
    { lat: 48.8057529, lng: 21.8657895 },
    { lat: 48.8067442, lng: 21.8649159 },
    { lat: 48.8067334, lng: 21.864852 },
    { lat: 48.8068004, lng: 21.8647438 },
    { lat: 48.8070439, lng: 21.8645418 },
    { lat: 48.8071903, lng: 21.8653437 },
    { lat: 48.8074314, lng: 21.8660113 },
    { lat: 48.8076204, lng: 21.8662768 },
    { lat: 48.80778, lng: 21.8663966 },
    { lat: 48.8085559, lng: 21.8665674 },
    { lat: 48.8089995, lng: 21.8665825 },
    { lat: 48.8093402, lng: 21.8664372 },
    { lat: 48.8093101, lng: 21.8659718 },
    { lat: 48.809717, lng: 21.8657349 },
    { lat: 48.810238, lng: 21.8652996 },
    { lat: 48.8099239, lng: 21.8626055 },
    { lat: 48.8090092, lng: 21.8633059 },
    { lat: 48.8089446, lng: 21.8631583 },
    { lat: 48.8087922, lng: 21.863355 },
    { lat: 48.8086774, lng: 21.8634006 },
    { lat: 48.8084526, lng: 21.8631008 },
    { lat: 48.8083053, lng: 21.8625334 },
    { lat: 48.807815, lng: 21.8619237 },
    { lat: 48.8077674, lng: 21.8616506 },
    { lat: 48.807523, lng: 21.8614601 },
    { lat: 48.8085687, lng: 21.8608698 },
    { lat: 48.8084127, lng: 21.8599603 },
    { lat: 48.8078588, lng: 21.8580138 },
    { lat: 48.8076863, lng: 21.8568608 },
    { lat: 48.8075595, lng: 21.8565664 },
    { lat: 48.8073895, lng: 21.8558569 },
    { lat: 48.807269, lng: 21.8556341 },
    { lat: 48.8070636, lng: 21.8549977 },
    { lat: 48.8067042, lng: 21.8547091 },
    { lat: 48.8066534, lng: 21.8540904 },
    { lat: 48.8064867, lng: 21.8537743 },
    { lat: 48.8065616, lng: 21.853322 },
    { lat: 48.8064354, lng: 21.8527898 },
    { lat: 48.8063912, lng: 21.8522518 },
    { lat: 48.8062101, lng: 21.8515526 },
    { lat: 48.8057115, lng: 21.850315 },
    { lat: 48.8047278, lng: 21.8489868 },
    { lat: 48.8042059, lng: 21.8480251 },
    { lat: 48.8037142, lng: 21.8467062 },
    { lat: 48.8033864, lng: 21.845605 },
    { lat: 48.8025883, lng: 21.8436604 },
    { lat: 48.8023096, lng: 21.8433636 },
    { lat: 48.8020843, lng: 21.8426999 },
    { lat: 48.8018401, lng: 21.8423061 },
    { lat: 48.8016745, lng: 21.841898 },
    { lat: 48.8013512, lng: 21.8413219 },
    { lat: 48.8006367, lng: 21.8403506 },
    { lat: 48.8005807, lng: 21.8401466 },
    { lat: 48.8005423, lng: 21.8394909 },
    { lat: 48.8002431, lng: 21.8389665 },
    { lat: 48.8001984, lng: 21.8388889 },
    { lat: 48.8003568, lng: 21.8394451 },
    { lat: 48.7956606, lng: 21.8395148 },
    { lat: 48.7891071, lng: 21.8430268 },
    { lat: 48.7881931, lng: 21.8428803 },
    { lat: 48.7853143, lng: 21.8439229 },
    { lat: 48.7828039, lng: 21.8446918 },
    { lat: 48.7827186, lng: 21.8446562 },
    { lat: 48.7826575, lng: 21.8446005 },
    { lat: 48.7817245, lng: 21.8450598 },
    { lat: 48.7812969, lng: 21.8450597 },
    { lat: 48.7809021, lng: 21.8456319 },
    { lat: 48.7802347, lng: 21.8469291 },
    { lat: 48.7801062, lng: 21.8470451 },
    { lat: 48.7799395, lng: 21.847082 },
    { lat: 48.7794939, lng: 21.8469442 },
    { lat: 48.7794035, lng: 21.8469749 },
    { lat: 48.7791532, lng: 21.8473085 },
    { lat: 48.778645, lng: 21.847448 },
    { lat: 48.778292, lng: 21.8477181 },
    { lat: 48.7781708, lng: 21.8482118 },
    { lat: 48.7780847, lng: 21.8488404 },
    { lat: 48.7782613, lng: 21.8492362 },
    { lat: 48.7784212, lng: 21.8493752 },
    { lat: 48.7787165, lng: 21.8498304 },
    { lat: 48.7784905, lng: 21.8501826 },
    { lat: 48.7784069, lng: 21.8504789 },
    { lat: 48.7784345, lng: 21.8509845 },
    { lat: 48.778164, lng: 21.8514467 },
    { lat: 48.7780655, lng: 21.852209 },
    { lat: 48.7779123, lng: 21.8527231 },
    { lat: 48.7776988, lng: 21.8533053 },
    { lat: 48.7775699, lng: 21.8534977 },
    { lat: 48.7773031, lng: 21.8536214 },
    { lat: 48.777329, lng: 21.8533178 },
    { lat: 48.7772762, lng: 21.8530955 },
    { lat: 48.7770814, lng: 21.8530028 },
    { lat: 48.7768761, lng: 21.853113 },
    { lat: 48.7765559, lng: 21.8536133 },
    { lat: 48.7763285, lng: 21.8537355 },
    { lat: 48.7761178, lng: 21.8537682 },
    { lat: 48.7760999, lng: 21.8539519 },
    { lat: 48.7790295, lng: 21.8690554 },
    { lat: 48.7802589, lng: 21.8684298 },
    { lat: 48.7808285, lng: 21.8681735 },
    { lat: 48.781136, lng: 21.8681523 },
    { lat: 48.7823781, lng: 21.8675423 },
    { lat: 48.7825679, lng: 21.8679664 },
    { lat: 48.7827449, lng: 21.8678919 },
    { lat: 48.7828767, lng: 21.8686047 },
    { lat: 48.7832494, lng: 21.868437 },
    { lat: 48.7836, lng: 21.8710206 },
    { lat: 48.7852722, lng: 21.8783618 },
    { lat: 48.7852559, lng: 21.8784269 },
    { lat: 48.7851142, lng: 21.878519 },
    { lat: 48.7847753, lng: 21.8785484 },
    { lat: 48.7847061, lng: 21.8786171 },
    { lat: 48.7847178, lng: 21.8787277 },
    { lat: 48.7845648, lng: 21.8790298 },
    { lat: 48.7843894, lng: 21.8791232 },
    { lat: 48.7840892, lng: 21.8785528 },
    { lat: 48.7837604, lng: 21.8784075 },
    { lat: 48.7835063, lng: 21.8784634 },
    { lat: 48.7835293, lng: 21.878746 },
    { lat: 48.7834208, lng: 21.8787741 },
    { lat: 48.7835324, lng: 21.8799715 },
    { lat: 48.7839355, lng: 21.8818412 },
    { lat: 48.7842396, lng: 21.8829147 },
    { lat: 48.7844407, lng: 21.8833892 },
    { lat: 48.7843293, lng: 21.8835226 },
    { lat: 48.7846284, lng: 21.8841791 },
    { lat: 48.7849326, lng: 21.8838021 },
    { lat: 48.786299, lng: 21.8882107 },
    { lat: 48.7863972, lng: 21.8882268 },
    { lat: 48.7864317, lng: 21.8886093 },
    { lat: 48.7865304, lng: 21.888905 },
    { lat: 48.7866375, lng: 21.8887397 },
    { lat: 48.7874624, lng: 21.8874706 },
    { lat: 48.7887074, lng: 21.8883832 },
    { lat: 48.7889525, lng: 21.8883346 },
    { lat: 48.7894083, lng: 21.888692 },
    { lat: 48.7898406, lng: 21.8889327 },
    { lat: 48.7901903, lng: 21.8888799 },
    { lat: 48.790674, lng: 21.8885253 },
    { lat: 48.7915456, lng: 21.8883829 },
    { lat: 48.7916084, lng: 21.888107 },
    { lat: 48.791999, lng: 21.8881175 },
    { lat: 48.7922185, lng: 21.8880341 },
    { lat: 48.7922938, lng: 21.8884275 },
    { lat: 48.7924951, lng: 21.8889107 },
    { lat: 48.7930786, lng: 21.8897027 },
    { lat: 48.7938408, lng: 21.8899838 },
    { lat: 48.7942379, lng: 21.8898126 },
    { lat: 48.7945696, lng: 21.8894044 },
    { lat: 48.794858, lng: 21.8890166 },
    { lat: 48.7962178, lng: 21.8881675 },
    { lat: 48.7973764, lng: 21.887192 },
    { lat: 48.7990733, lng: 21.8872517 },
    { lat: 48.8003442, lng: 21.8863513 },
    { lat: 48.8007131, lng: 21.8860011 },
    { lat: 48.8014114, lng: 21.8859819 },
    { lat: 48.8018318, lng: 21.8861541 },
    { lat: 48.8028595, lng: 21.8867979 },
    { lat: 48.8034947, lng: 21.8874451 },
    { lat: 48.8039317, lng: 21.8881294 },
    { lat: 48.8042355, lng: 21.8880433 },
    { lat: 48.8048894, lng: 21.8885933 },
    { lat: 48.8051589, lng: 21.8889178 },
    { lat: 48.806248, lng: 21.8894101 },
    { lat: 48.8067233, lng: 21.8897493 },
    { lat: 48.8075075, lng: 21.889931 },
    { lat: 48.8090791, lng: 21.8894488 },
    { lat: 48.8112336, lng: 21.8875482 },
    { lat: 48.8119763, lng: 21.8864953 },
    { lat: 48.8128462, lng: 21.8858418 },
    { lat: 48.8133164, lng: 21.8850855 },
    { lat: 48.8142448, lng: 21.8830204 },
    { lat: 48.8137365, lng: 21.8824533 },
    { lat: 48.8138311, lng: 21.8806017 },
  ],
  VysokánadUhom: [
    { lat: 48.6472936, lng: 22.0680487 },
    { lat: 48.6430392, lng: 22.0695118 },
    { lat: 48.6384408, lng: 22.070837 },
    { lat: 48.6365164, lng: 22.0665703 },
    { lat: 48.6362777, lng: 22.0676399 },
    { lat: 48.6355082, lng: 22.0699413 },
    { lat: 48.6349257, lng: 22.0721613 },
    { lat: 48.6326976, lng: 22.073077 },
    { lat: 48.6257245, lng: 22.0783129 },
    { lat: 48.6255391, lng: 22.0780822 },
    { lat: 48.6221957, lng: 22.0804403 },
    { lat: 48.6218851, lng: 22.0799071 },
    { lat: 48.6198773, lng: 22.0812376 },
    { lat: 48.6203345, lng: 22.0824917 },
    { lat: 48.6214504, lng: 22.0850446 },
    { lat: 48.6219319, lng: 22.0862715 },
    { lat: 48.6194512, lng: 22.0875427 },
    { lat: 48.6188853, lng: 22.0877244 },
    { lat: 48.6180032, lng: 22.0878119 },
    { lat: 48.6164782, lng: 22.0882552 },
    { lat: 48.6152888, lng: 22.088424 },
    { lat: 48.6143917, lng: 22.0883075 },
    { lat: 48.6130756, lng: 22.0884547 },
    { lat: 48.6119084, lng: 22.088431 },
    { lat: 48.610906, lng: 22.0891787 },
    { lat: 48.6050376, lng: 22.0986293 },
    { lat: 48.6047884, lng: 22.098647 },
    { lat: 48.604722, lng: 22.0986516 },
    { lat: 48.6064752, lng: 22.1021924 },
    { lat: 48.6064537, lng: 22.1023816 },
    { lat: 48.6065862, lng: 22.1025639 },
    { lat: 48.6073141, lng: 22.1060864 },
    { lat: 48.6075464, lng: 22.1063974 },
    { lat: 48.6086202, lng: 22.1068033 },
    { lat: 48.60774, lng: 22.1079692 },
    { lat: 48.6071184, lng: 22.1089523 },
    { lat: 48.6063416, lng: 22.1100162 },
    { lat: 48.6062014, lng: 22.1103301 },
    { lat: 48.6062277, lng: 22.1113434 },
    { lat: 48.6064607, lng: 22.1120717 },
    { lat: 48.6072599, lng: 22.1140973 },
    { lat: 48.6084105, lng: 22.1183488 },
    { lat: 48.6086332, lng: 22.1187791 },
    { lat: 48.6089606, lng: 22.1187765 },
    { lat: 48.6089458, lng: 22.1191097 },
    { lat: 48.6088136, lng: 22.119656 },
    { lat: 48.6084707, lng: 22.1201911 },
    { lat: 48.6082808, lng: 22.1203452 },
    { lat: 48.6079987, lng: 22.120409 },
    { lat: 48.6075544, lng: 22.120256 },
    { lat: 48.6071393, lng: 22.1199761 },
    { lat: 48.6068068, lng: 22.1199416 },
    { lat: 48.6065486, lng: 22.1200158 },
    { lat: 48.6063961, lng: 22.1201782 },
    { lat: 48.6062511, lng: 22.1205193 },
    { lat: 48.6062085, lng: 22.1207615 },
    { lat: 48.6062963, lng: 22.1214883 },
    { lat: 48.6066052, lng: 22.1222368 },
    { lat: 48.6067974, lng: 22.122856 },
    { lat: 48.6068676, lng: 22.123304 },
    { lat: 48.6067912, lng: 22.1238779 },
    { lat: 48.606508, lng: 22.124701 },
    { lat: 48.606561, lng: 22.124738 },
    { lat: 48.60661, lng: 22.124794 },
    { lat: 48.606757, lng: 22.124962 },
    { lat: 48.607021, lng: 22.125266 },
    { lat: 48.607176, lng: 22.125421 },
    { lat: 48.607322, lng: 22.125382 },
    { lat: 48.607509, lng: 22.12539 },
    { lat: 48.608814, lng: 22.125228 },
    { lat: 48.608842, lng: 22.125231 },
    { lat: 48.609752, lng: 22.125062 },
    { lat: 48.610182, lng: 22.125007 },
    { lat: 48.610221, lng: 22.125002 },
    { lat: 48.61186, lng: 22.125009 },
    { lat: 48.614484, lng: 22.125032 },
    { lat: 48.615295, lng: 22.125071 },
    { lat: 48.617257, lng: 22.125159 },
    { lat: 48.617553, lng: 22.125172 },
    { lat: 48.617721, lng: 22.12518 },
    { lat: 48.61791, lng: 22.125188 },
    { lat: 48.618138, lng: 22.1252 },
    { lat: 48.61826, lng: 22.125206 },
    { lat: 48.618335, lng: 22.12521 },
    { lat: 48.620655, lng: 22.12533 },
    { lat: 48.621847, lng: 22.125286 },
    { lat: 48.622657, lng: 22.12539 },
    { lat: 48.623189, lng: 22.125439 },
    { lat: 48.625234, lng: 22.125573 },
    { lat: 48.625318, lng: 22.125661 },
    { lat: 48.625537, lng: 22.125756 },
    { lat: 48.625868, lng: 22.12604 },
    { lat: 48.62602, lng: 22.126229 },
    { lat: 48.626147, lng: 22.126447 },
    { lat: 48.626202, lng: 22.126668 },
    { lat: 48.62636, lng: 22.127515 },
    { lat: 48.626418, lng: 22.128061 },
    { lat: 48.627865, lng: 22.128298 },
    { lat: 48.62786, lng: 22.128628 },
    { lat: 48.627858, lng: 22.128751 },
    { lat: 48.628062, lng: 22.128757 },
    { lat: 48.628264, lng: 22.12869 },
    { lat: 48.628703, lng: 22.128439 },
    { lat: 48.629192, lng: 22.128062 },
    { lat: 48.629501, lng: 22.127913 },
    { lat: 48.630322, lng: 22.127812 },
    { lat: 48.630798, lng: 22.12764 },
    { lat: 48.632151, lng: 22.126787 },
    { lat: 48.632679, lng: 22.126977 },
    { lat: 48.633495, lng: 22.12726 },
    { lat: 48.633426, lng: 22.126014 },
    { lat: 48.632925, lng: 22.125692 },
    { lat: 48.632151, lng: 22.126191 },
    { lat: 48.632158, lng: 22.125796 },
    { lat: 48.63219, lng: 22.12542 },
    { lat: 48.632147, lng: 22.124993 },
    { lat: 48.631993, lng: 22.124052 },
    { lat: 48.631676, lng: 22.122787 },
    { lat: 48.631551, lng: 22.122103 },
    { lat: 48.631509, lng: 22.121182 },
    { lat: 48.631359, lng: 22.120176 },
    { lat: 48.631318, lng: 22.119837 },
    { lat: 48.631214, lng: 22.118264 },
    { lat: 48.631157, lng: 22.11724 },
    { lat: 48.631025, lng: 22.116341 },
    { lat: 48.634094, lng: 22.115189 },
    { lat: 48.634193, lng: 22.115161 },
    { lat: 48.634248, lng: 22.115148 },
    { lat: 48.634361, lng: 22.11512 },
    { lat: 48.635272, lng: 22.114892 },
    { lat: 48.636275, lng: 22.114726 },
    { lat: 48.636682, lng: 22.114577 },
    { lat: 48.637002, lng: 22.114349 },
    { lat: 48.638055, lng: 22.113809 },
    { lat: 48.638457, lng: 22.11369 },
    { lat: 48.639352, lng: 22.113592 },
    { lat: 48.640187, lng: 22.11356 },
    { lat: 48.640469, lng: 22.113812 },
    { lat: 48.640674, lng: 22.114127 },
    { lat: 48.640732, lng: 22.114246 },
    { lat: 48.640792, lng: 22.114369 },
    { lat: 48.640837, lng: 22.114455 },
    { lat: 48.640858, lng: 22.114497 },
    { lat: 48.640938, lng: 22.114652 },
    { lat: 48.641139, lng: 22.115067 },
    { lat: 48.641304, lng: 22.114933 },
    { lat: 48.641481, lng: 22.114779 },
    { lat: 48.641606, lng: 22.11467 },
    { lat: 48.641799, lng: 22.114339 },
    { lat: 48.641956, lng: 22.113537 },
    { lat: 48.641997, lng: 22.113333 },
    { lat: 48.642059, lng: 22.113034 },
    { lat: 48.642069, lng: 22.112982 },
    { lat: 48.642164, lng: 22.112491 },
    { lat: 48.642317, lng: 22.112337 },
    { lat: 48.642566, lng: 22.112346 },
    { lat: 48.642599, lng: 22.112368 },
    { lat: 48.642625, lng: 22.112385 },
    { lat: 48.64278, lng: 22.112463 },
    { lat: 48.642854, lng: 22.112416 },
    { lat: 48.643235, lng: 22.112085 },
    { lat: 48.643389, lng: 22.112034 },
    { lat: 48.643678, lng: 22.112126 },
    { lat: 48.643942, lng: 22.111781 },
    { lat: 48.644292, lng: 22.111042 },
    { lat: 48.64431, lng: 22.110869 },
    { lat: 48.644321, lng: 22.110604 },
    { lat: 48.644324, lng: 22.110543 },
    { lat: 48.644334, lng: 22.11038 },
    { lat: 48.644337, lng: 22.110325 },
    { lat: 48.644341, lng: 22.110231 },
    { lat: 48.644341, lng: 22.110217 },
    { lat: 48.644334, lng: 22.110197 },
    { lat: 48.644536, lng: 22.110015 },
    { lat: 48.644693, lng: 22.110109 },
    { lat: 48.644731, lng: 22.110131 },
    { lat: 48.644876, lng: 22.110289 },
    { lat: 48.645232, lng: 22.110687 },
    { lat: 48.645375, lng: 22.110699 },
    { lat: 48.645504, lng: 22.11054 },
    { lat: 48.645912, lng: 22.109665 },
    { lat: 48.645877, lng: 22.109267 },
    { lat: 48.646366, lng: 22.108853 },
    { lat: 48.646444, lng: 22.108494 },
    { lat: 48.646466, lng: 22.108391 },
    { lat: 48.646632, lng: 22.108142 },
    { lat: 48.64675, lng: 22.107964 },
    { lat: 48.646646, lng: 22.107694 },
    { lat: 48.646618, lng: 22.107619 },
    { lat: 48.646571, lng: 22.107495 },
    { lat: 48.646741, lng: 22.106751 },
    { lat: 48.646855, lng: 22.106585 },
    { lat: 48.647054, lng: 22.106484 },
    { lat: 48.647213, lng: 22.106019 },
    { lat: 48.64753, lng: 22.10531 },
    { lat: 48.647508, lng: 22.105075 },
    { lat: 48.647506, lng: 22.104999 },
    { lat: 48.647504, lng: 22.104973 },
    { lat: 48.647479, lng: 22.10469 },
    { lat: 48.64747, lng: 22.104628 },
    { lat: 48.647448, lng: 22.104487 },
    { lat: 48.647441, lng: 22.104438 },
    { lat: 48.647404, lng: 22.10422 },
    { lat: 48.647769, lng: 22.103804 },
    { lat: 48.647975, lng: 22.103678 },
    { lat: 48.648287, lng: 22.103616 },
    { lat: 48.64843, lng: 22.103478 },
    { lat: 48.648489, lng: 22.10344 },
    { lat: 48.648589, lng: 22.103386 },
    { lat: 48.648751, lng: 22.103507 },
    { lat: 48.64877, lng: 22.10352 },
    { lat: 48.648994, lng: 22.103302 },
    { lat: 48.649035, lng: 22.103195 },
    { lat: 48.649115, lng: 22.103004 },
    { lat: 48.64935, lng: 22.102789 },
    { lat: 48.649496, lng: 22.102429 },
    { lat: 48.649716, lng: 22.102135 },
    { lat: 48.649807, lng: 22.101534 },
    { lat: 48.650258, lng: 22.101009 },
    { lat: 48.650559, lng: 22.100855 },
    { lat: 48.650991, lng: 22.10074 },
    { lat: 48.651108, lng: 22.10074 },
    { lat: 48.651139, lng: 22.10074 },
    { lat: 48.65117, lng: 22.10074 },
    { lat: 48.651255, lng: 22.100742 },
    { lat: 48.651594, lng: 22.100546 },
    { lat: 48.651701, lng: 22.10056 },
    { lat: 48.652125, lng: 22.100418 },
    { lat: 48.652301, lng: 22.100216 },
    { lat: 48.652627, lng: 22.099837 },
    { lat: 48.65286, lng: 22.099787 },
    { lat: 48.6531, lng: 22.099602 },
    { lat: 48.653142, lng: 22.099503 },
    { lat: 48.653315, lng: 22.09909 },
    { lat: 48.653359, lng: 22.099049 },
    { lat: 48.653432, lng: 22.09898 },
    { lat: 48.653506, lng: 22.09891 },
    { lat: 48.653601, lng: 22.09882 },
    { lat: 48.653939, lng: 22.098511 },
    { lat: 48.654332, lng: 22.098553 },
    { lat: 48.65459, lng: 22.098729 },
    { lat: 48.654692, lng: 22.098801 },
    { lat: 48.655259, lng: 22.098895 },
    { lat: 48.655363, lng: 22.098832 },
    { lat: 48.655559, lng: 22.098707 },
    { lat: 48.655795, lng: 22.098648 },
    { lat: 48.656332, lng: 22.098703 },
    { lat: 48.656782, lng: 22.098376 },
    { lat: 48.657028, lng: 22.098394 },
    { lat: 48.657047, lng: 22.09841 },
    { lat: 48.657058, lng: 22.098419 },
    { lat: 48.6577, lng: 22.0985 },
    { lat: 48.657981, lng: 22.098301 },
    { lat: 48.658087, lng: 22.098096 },
    { lat: 48.657943, lng: 22.097492 },
    { lat: 48.657754, lng: 22.097273 },
    { lat: 48.657743, lng: 22.09726 },
    { lat: 48.657638, lng: 22.097133 },
    { lat: 48.657617, lng: 22.097111 },
    { lat: 48.657187, lng: 22.096641 },
    { lat: 48.65697, lng: 22.096013 },
    { lat: 48.6568, lng: 22.095787 },
    { lat: 48.656343, lng: 22.095438 },
    { lat: 48.655988, lng: 22.095762 },
    { lat: 48.655832, lng: 22.095615 },
    { lat: 48.655675, lng: 22.095553 },
    { lat: 48.655258, lng: 22.095703 },
    { lat: 48.654966, lng: 22.095128 },
    { lat: 48.654429, lng: 22.094965 },
    { lat: 48.654135, lng: 22.095027 },
    { lat: 48.653878, lng: 22.095156 },
    { lat: 48.653611, lng: 22.095141 },
    { lat: 48.65338, lng: 22.095281 },
    { lat: 48.653264, lng: 22.095358 },
    { lat: 48.653207, lng: 22.095429 },
    { lat: 48.65308, lng: 22.095587 },
    { lat: 48.652913, lng: 22.095795 },
    { lat: 48.652884, lng: 22.095772 },
    { lat: 48.652801, lng: 22.095734 },
    { lat: 48.652271, lng: 22.09609 },
    { lat: 48.652096, lng: 22.095963 },
    { lat: 48.651995, lng: 22.095857 },
    { lat: 48.651895, lng: 22.095754 },
    { lat: 48.651884, lng: 22.095742 },
    { lat: 48.651738, lng: 22.095579 },
    { lat: 48.651419, lng: 22.095216 },
    { lat: 48.651868, lng: 22.094783 },
    { lat: 48.652002, lng: 22.094632 },
    { lat: 48.652631, lng: 22.094127 },
    { lat: 48.652939, lng: 22.093661 },
    { lat: 48.652464, lng: 22.092953 },
    { lat: 48.652348, lng: 22.092678 },
    { lat: 48.652287, lng: 22.092357 },
    { lat: 48.652282, lng: 22.092217 },
    { lat: 48.652386, lng: 22.092015 },
    { lat: 48.652644, lng: 22.091695 },
    { lat: 48.652962, lng: 22.091489 },
    { lat: 48.653099, lng: 22.091444 },
    { lat: 48.653141, lng: 22.091511 },
    { lat: 48.653311, lng: 22.091366 },
    { lat: 48.653475, lng: 22.091668 },
    { lat: 48.654167, lng: 22.0911 },
    { lat: 48.654251, lng: 22.090912 },
    { lat: 48.654115, lng: 22.090371 },
    { lat: 48.654556, lng: 22.089987 },
    { lat: 48.654763, lng: 22.089879 },
    { lat: 48.655023, lng: 22.089446 },
    { lat: 48.655141, lng: 22.089478 },
    { lat: 48.65555, lng: 22.089699 },
    { lat: 48.655656, lng: 22.089447 },
    { lat: 48.655749, lng: 22.089571 },
    { lat: 48.656039, lng: 22.089242 },
    { lat: 48.65628, lng: 22.089078 },
    { lat: 48.65646, lng: 22.088891 },
    { lat: 48.656457, lng: 22.088837 },
    { lat: 48.656479, lng: 22.08878 },
    { lat: 48.656557, lng: 22.088765 },
    { lat: 48.65665, lng: 22.0887 },
    { lat: 48.656731, lng: 22.088318 },
    { lat: 48.656757, lng: 22.088059 },
    { lat: 48.656827, lng: 22.087597 },
    { lat: 48.657017, lng: 22.08718 },
    { lat: 48.657043, lng: 22.087102 },
    { lat: 48.657048, lng: 22.087085 },
    { lat: 48.657064, lng: 22.087031 },
    { lat: 48.657086, lng: 22.086961 },
    { lat: 48.657161, lng: 22.086725 },
    { lat: 48.657224, lng: 22.086388 },
    { lat: 48.657304, lng: 22.086065 },
    { lat: 48.65736, lng: 22.085655 },
    { lat: 48.657384, lng: 22.085479 },
    { lat: 48.657528, lng: 22.085101 },
    { lat: 48.657604, lng: 22.084788 },
    { lat: 48.657665, lng: 22.084731 },
    { lat: 48.657725, lng: 22.084674 },
    { lat: 48.658096, lng: 22.084321 },
    { lat: 48.658342, lng: 22.083669 },
    { lat: 48.658226, lng: 22.083031 },
    { lat: 48.658051, lng: 22.082696 },
    { lat: 48.657944, lng: 22.082424 },
    { lat: 48.657799, lng: 22.08217 },
    { lat: 48.657691, lng: 22.081777 },
    { lat: 48.657633, lng: 22.08126 },
    { lat: 48.657684, lng: 22.081152 },
    { lat: 48.657803, lng: 22.080903 },
    { lat: 48.657807, lng: 22.080498 },
    { lat: 48.657776, lng: 22.080323 },
    { lat: 48.657669, lng: 22.079794 },
    { lat: 48.65765, lng: 22.07954 },
    { lat: 48.657648, lng: 22.079342 },
    { lat: 48.657666, lng: 22.078978 },
    { lat: 48.657658, lng: 22.078648 },
    { lat: 48.6576, lng: 22.07846 },
    { lat: 48.657514, lng: 22.078179 },
    { lat: 48.6573838, lng: 22.078177 },
    { lat: 48.6572938, lng: 22.0787437 },
    { lat: 48.6443926, lng: 22.0807318 },
    { lat: 48.6472121, lng: 22.0684053 },
    { lat: 48.6472936, lng: 22.0680487 },
  ],
  TrnavapriLaborci: [
    { lat: 48.7974022, lng: 21.9152256 },
    { lat: 48.7974723, lng: 21.9185956 },
    { lat: 48.7975676, lng: 21.9218446 },
    { lat: 48.7975758, lng: 21.9242829 },
    { lat: 48.797535, lng: 21.9244544 },
    { lat: 48.7975681, lng: 21.9298115 },
    { lat: 48.7973092, lng: 21.9323215 },
    { lat: 48.7969304, lng: 21.9348141 },
    { lat: 48.7964525, lng: 21.93482 },
    { lat: 48.7969054, lng: 21.9351152 },
    { lat: 48.7976154, lng: 21.9355741 },
    { lat: 48.7987177, lng: 21.9403834 },
    { lat: 48.7995479, lng: 21.9400701 },
    { lat: 48.8011655, lng: 21.9396643 },
    { lat: 48.802067, lng: 21.9446414 },
    { lat: 48.8039909, lng: 21.9439771 },
    { lat: 48.8041818, lng: 21.9473071 },
    { lat: 48.8106779, lng: 21.9482472 },
    { lat: 48.8121221, lng: 21.948329 },
    { lat: 48.8121652, lng: 21.9484404 },
    { lat: 48.8124188, lng: 21.948099 },
    { lat: 48.815081, lng: 21.947802 },
    { lat: 48.8162833, lng: 21.9485144 },
    { lat: 48.8165619, lng: 21.9485924 },
    { lat: 48.8167192, lng: 21.9458721 },
    { lat: 48.8166094, lng: 21.9453094 },
    { lat: 48.8166315, lng: 21.9451075 },
    { lat: 48.8165918, lng: 21.944912 },
    { lat: 48.8161564, lng: 21.9440995 },
    { lat: 48.8161641, lng: 21.9439998 },
    { lat: 48.8163236, lng: 21.9439019 },
    { lat: 48.8189825, lng: 21.9482645 },
    { lat: 48.8218404, lng: 21.9477521 },
    { lat: 48.8230771, lng: 21.9468187 },
    { lat: 48.8243925, lng: 21.9468297 },
    { lat: 48.8260014, lng: 21.9457144 },
    { lat: 48.8260881, lng: 21.944542 },
    { lat: 48.8268031, lng: 21.9433505 },
    { lat: 48.8269199, lng: 21.9428433 },
    { lat: 48.8296334, lng: 21.9439829 },
    { lat: 48.830421, lng: 21.9440768 },
    { lat: 48.8314489, lng: 21.9445676 },
    { lat: 48.8338547, lng: 21.9450493 },
    { lat: 48.8345872, lng: 21.9456049 },
    { lat: 48.8349938, lng: 21.9464829 },
    { lat: 48.8369985, lng: 21.949873 },
    { lat: 48.8377138, lng: 21.9500114 },
    { lat: 48.8390152, lng: 21.9500022 },
    { lat: 48.8405568, lng: 21.9504265 },
    { lat: 48.8428593, lng: 21.9499227 },
    { lat: 48.8435189, lng: 21.9497169 },
    { lat: 48.846051, lng: 21.9501243 },
    { lat: 48.8477302, lng: 21.9501118 },
    { lat: 48.849544, lng: 21.9499448 },
    { lat: 48.8506095, lng: 21.9495494 },
    { lat: 48.851459, lng: 21.949602 },
    { lat: 48.851584, lng: 21.949019 },
    { lat: 48.851613, lng: 21.948711 },
    { lat: 48.851731, lng: 21.947444 },
    { lat: 48.851809, lng: 21.946618 },
    { lat: 48.852575, lng: 21.946902 },
    { lat: 48.8534378, lng: 21.9447663 },
    { lat: 48.8537969, lng: 21.9436413 },
    { lat: 48.8539137, lng: 21.9425944 },
    { lat: 48.8543114, lng: 21.9417137 },
    { lat: 48.855228, lng: 21.9403106 },
    { lat: 48.8556078, lng: 21.9393476 },
    { lat: 48.8558553, lng: 21.9379932 },
    { lat: 48.8579485, lng: 21.9320336 },
    { lat: 48.8584028, lng: 21.9310833 },
    { lat: 48.8587805, lng: 21.9300771 },
    { lat: 48.8590561, lng: 21.9288627 },
    { lat: 48.8597169, lng: 21.9269549 },
    { lat: 48.8592909, lng: 21.926565 },
    { lat: 48.8554713, lng: 21.9238471 },
    { lat: 48.8555163, lng: 21.9225403 },
    { lat: 48.8545057, lng: 21.9221241 },
    { lat: 48.8539817, lng: 21.9217785 },
    { lat: 48.854029, lng: 21.9209177 },
    { lat: 48.8535765, lng: 21.9205923 },
    { lat: 48.8531885, lng: 21.9200674 },
    { lat: 48.8529909, lng: 21.9190508 },
    { lat: 48.852721, lng: 21.9190492 },
    { lat: 48.8527108, lng: 21.9188701 },
    { lat: 48.853541, lng: 21.9185097 },
    { lat: 48.8533433, lng: 21.9182039 },
    { lat: 48.8534107, lng: 21.9174895 },
    { lat: 48.8525067, lng: 21.9172838 },
    { lat: 48.8525803, lng: 21.9166776 },
    { lat: 48.8518389, lng: 21.9164011 },
    { lat: 48.8518014, lng: 21.9158496 },
    { lat: 48.8518307, lng: 21.9152674 },
    { lat: 48.8519862, lng: 21.9148341 },
    { lat: 48.8522362, lng: 21.9138545 },
    { lat: 48.8522196, lng: 21.9129707 },
    { lat: 48.8517212, lng: 21.912837 },
    { lat: 48.8512523, lng: 21.9129744 },
    { lat: 48.8508918, lng: 21.9129149 },
    { lat: 48.8505842, lng: 21.9134802 },
    { lat: 48.8502415, lng: 21.9132192 },
    { lat: 48.8490865, lng: 21.9166359 },
    { lat: 48.8479114, lng: 21.9157625 },
    { lat: 48.847263, lng: 21.9154742 },
    { lat: 48.8448072, lng: 21.913993 },
    { lat: 48.8443357, lng: 21.9139806 },
    { lat: 48.843775, lng: 21.9136101 },
    { lat: 48.8430813, lng: 21.9129912 },
    { lat: 48.842509, lng: 21.9120668 },
    { lat: 48.8420905, lng: 21.9115303 },
    { lat: 48.8411881, lng: 21.9112085 },
    { lat: 48.8405006, lng: 21.9116064 },
    { lat: 48.8386811, lng: 21.9119393 },
    { lat: 48.8375314, lng: 21.9123003 },
    { lat: 48.8370943, lng: 21.9106237 },
    { lat: 48.8372048, lng: 21.909601 },
    { lat: 48.8364092, lng: 21.9095077 },
    { lat: 48.8359862, lng: 21.9080338 },
    { lat: 48.8355807, lng: 21.9082258 },
    { lat: 48.8351147, lng: 21.9083485 },
    { lat: 48.8329129, lng: 21.908786 },
    { lat: 48.8327506, lng: 21.9079281 },
    { lat: 48.8324811, lng: 21.9074138 },
    { lat: 48.8323583, lng: 21.9067307 },
    { lat: 48.8323473, lng: 21.9059858 },
    { lat: 48.8310432, lng: 21.9036972 },
    { lat: 48.8312018, lng: 21.9054769 },
    { lat: 48.8310069, lng: 21.9081911 },
    { lat: 48.8296322, lng: 21.9106463 },
    { lat: 48.8283128, lng: 21.9115058 },
    { lat: 48.8280953, lng: 21.9114445 },
    { lat: 48.8276286, lng: 21.911585 },
    { lat: 48.8273449, lng: 21.9117481 },
    { lat: 48.8270088, lng: 21.9129218 },
    { lat: 48.8268003, lng: 21.9139841 },
    { lat: 48.8265612, lng: 21.9146745 },
    { lat: 48.8264662, lng: 21.9148356 },
    { lat: 48.8263796, lng: 21.9155425 },
    { lat: 48.8259504, lng: 21.9163256 },
    { lat: 48.8253451, lng: 21.9172191 },
    { lat: 48.825137, lng: 21.9173146 },
    { lat: 48.8248919, lng: 21.9175415 },
    { lat: 48.8245077, lng: 21.9180942 },
    { lat: 48.8243489, lng: 21.9188951 },
    { lat: 48.8239872, lng: 21.9194572 },
    { lat: 48.8235463, lng: 21.9203079 },
    { lat: 48.8228912, lng: 21.9207019 },
    { lat: 48.8229283, lng: 21.9214088 },
    { lat: 48.8224425, lng: 21.9197557 },
    { lat: 48.8223567, lng: 21.9192123 },
    { lat: 48.8206115, lng: 21.9186074 },
    { lat: 48.8197011, lng: 21.9181944 },
    { lat: 48.8195982, lng: 21.9178612 },
    { lat: 48.8192256, lng: 21.9176281 },
    { lat: 48.8176394, lng: 21.9178073 },
    { lat: 48.8175049, lng: 21.9168352 },
    { lat: 48.816613, lng: 21.9163523 },
    { lat: 48.8139885, lng: 21.9159068 },
    { lat: 48.8138348, lng: 21.91583 },
    { lat: 48.8138562, lng: 21.9151649 },
    { lat: 48.8119264, lng: 21.9150481 },
    { lat: 48.8117488, lng: 21.9149662 },
    { lat: 48.8112865, lng: 21.9139343 },
    { lat: 48.8098222, lng: 21.9143107 },
    { lat: 48.8096081, lng: 21.9135778 },
    { lat: 48.808815, lng: 21.9119729 },
    { lat: 48.808586, lng: 21.9116321 },
    { lat: 48.8077588, lng: 21.9113034 },
    { lat: 48.807037, lng: 21.9102958 },
    { lat: 48.8063124, lng: 21.909398 },
    { lat: 48.8060275, lng: 21.9084005 },
    { lat: 48.8047331, lng: 21.9092708 },
    { lat: 48.8038364, lng: 21.9096623 },
    { lat: 48.8024536, lng: 21.9101008 },
    { lat: 48.8018113, lng: 21.9109954 },
    { lat: 48.8007365, lng: 21.9121394 },
    { lat: 48.7984016, lng: 21.9142588 },
    { lat: 48.7974022, lng: 21.9152256 },
  ],
  Senné: [
    { lat: 48.6697791, lng: 22.0060473 },
    { lat: 48.6694459, lng: 22.0062762 },
    { lat: 48.6666045, lng: 22.0073872 },
    { lat: 48.6663828, lng: 22.0070173 },
    { lat: 48.666043, lng: 22.0071946 },
    { lat: 48.6659559, lng: 22.0075012 },
    { lat: 48.6659673, lng: 22.0076365 },
    { lat: 48.6653968, lng: 22.007774 },
    { lat: 48.6646482, lng: 22.0077936 },
    { lat: 48.6640441, lng: 22.0077048 },
    { lat: 48.6640056, lng: 22.0076991 },
    { lat: 48.6638221, lng: 22.0076446 },
    { lat: 48.6637937, lng: 22.0077743 },
    { lat: 48.6636247, lng: 22.0077193 },
    { lat: 48.663216, lng: 22.0077886 },
    { lat: 48.6625618, lng: 22.0079987 },
    { lat: 48.6624155, lng: 22.0081381 },
    { lat: 48.6620861, lng: 22.0088774 },
    { lat: 48.6619836, lng: 22.0090151 },
    { lat: 48.6616121, lng: 22.0090899 },
    { lat: 48.6613247, lng: 22.0089344 },
    { lat: 48.6606451, lng: 22.0089718 },
    { lat: 48.6597853, lng: 22.0092798 },
    { lat: 48.6595243, lng: 22.0094199 },
    { lat: 48.6579395, lng: 22.0106663 },
    { lat: 48.6564956, lng: 22.0124911 },
    { lat: 48.6562707, lng: 22.0132849 },
    { lat: 48.6561185, lng: 22.0134012 },
    { lat: 48.6559407, lng: 22.0138647 },
    { lat: 48.6544905, lng: 22.0149166 },
    { lat: 48.6540344, lng: 22.0156844 },
    { lat: 48.6538961, lng: 22.0160539 },
    { lat: 48.6534185, lng: 22.0164885 },
    { lat: 48.6526817, lng: 22.017046 },
    { lat: 48.6520909, lng: 22.0172652 },
    { lat: 48.6513391, lng: 22.0177665 },
    { lat: 48.6512124, lng: 22.0184314 },
    { lat: 48.6507367, lng: 22.0183795 },
    { lat: 48.6505807, lng: 22.0184515 },
    { lat: 48.6505265, lng: 22.0183947 },
    { lat: 48.650198, lng: 22.0183887 },
    { lat: 48.6501232, lng: 22.0185515 },
    { lat: 48.6500045, lng: 22.0186204 },
    { lat: 48.6497521, lng: 22.0182384 },
    { lat: 48.6494929, lng: 22.0180286 },
    { lat: 48.6491855, lng: 22.0180667 },
    { lat: 48.6491059, lng: 22.0181163 },
    { lat: 48.6503619, lng: 22.0224926 },
    { lat: 48.6506454, lng: 22.023652 },
    { lat: 48.6502758, lng: 22.03276 },
    { lat: 48.6492509, lng: 22.0497198 },
    { lat: 48.6493108, lng: 22.0502376 },
    { lat: 48.6503427, lng: 22.0533406 },
    { lat: 48.6499379, lng: 22.0556958 },
    { lat: 48.6494865, lng: 22.0575971 },
    { lat: 48.6479126, lng: 22.0652003 },
    { lat: 48.6472936, lng: 22.0680487 },
    { lat: 48.6472121, lng: 22.0684053 },
    { lat: 48.6443926, lng: 22.0807318 },
    { lat: 48.6572938, lng: 22.0787437 },
    { lat: 48.6573838, lng: 22.078177 },
    { lat: 48.657514, lng: 22.078179 },
    { lat: 48.657521, lng: 22.078176 },
    { lat: 48.657693, lng: 22.078123 },
    { lat: 48.657633, lng: 22.077683 },
    { lat: 48.65786, lng: 22.076124 },
    { lat: 48.658015, lng: 22.076171 },
    { lat: 48.658647, lng: 22.075624 },
    { lat: 48.659656, lng: 22.074971 },
    { lat: 48.660123, lng: 22.074748 },
    { lat: 48.660463, lng: 22.074403 },
    { lat: 48.660524, lng: 22.074142 },
    { lat: 48.661168, lng: 22.073742 },
    { lat: 48.661779, lng: 22.073794 },
    { lat: 48.66186, lng: 22.073435 },
    { lat: 48.662291, lng: 22.073038 },
    { lat: 48.662327, lng: 22.073005 },
    { lat: 48.662451, lng: 22.073481 },
    { lat: 48.662879, lng: 22.074689 },
    { lat: 48.663039, lng: 22.075297 },
    { lat: 48.663259, lng: 22.07568 },
    { lat: 48.663417, lng: 22.076121 },
    { lat: 48.663592, lng: 22.076606 },
    { lat: 48.663805, lng: 22.076873 },
    { lat: 48.664012, lng: 22.076861 },
    { lat: 48.664326, lng: 22.077054 },
    { lat: 48.664947, lng: 22.077132 },
    { lat: 48.665109, lng: 22.077223 },
    { lat: 48.665223, lng: 22.077286 },
    { lat: 48.665372, lng: 22.07737 },
    { lat: 48.665485, lng: 22.07746 },
    { lat: 48.666834, lng: 22.079345 },
    { lat: 48.667307, lng: 22.079933 },
    { lat: 48.667358, lng: 22.079995 },
    { lat: 48.667877, lng: 22.080641 },
    { lat: 48.667928, lng: 22.080714 },
    { lat: 48.668046, lng: 22.080863 },
    { lat: 48.668212, lng: 22.080914 },
    { lat: 48.668311, lng: 22.080884 },
    { lat: 48.668568, lng: 22.080581 },
    { lat: 48.669584, lng: 22.078561 },
    { lat: 48.669645, lng: 22.078605 },
    { lat: 48.669719, lng: 22.078657 },
    { lat: 48.670716, lng: 22.07937 },
    { lat: 48.670684, lng: 22.079536 },
    { lat: 48.670659, lng: 22.07966 },
    { lat: 48.6707185, lng: 22.0796507 },
    { lat: 48.6708643, lng: 22.0794478 },
    { lat: 48.6708838, lng: 22.0792279 },
    { lat: 48.6711626, lng: 22.0789775 },
    { lat: 48.671737, lng: 22.0787748 },
    { lat: 48.6717789, lng: 22.078497 },
    { lat: 48.6721514, lng: 22.0780326 },
    { lat: 48.6722874, lng: 22.0775576 },
    { lat: 48.6722846, lng: 22.0770641 },
    { lat: 48.6721904, lng: 22.0767912 },
    { lat: 48.6718605, lng: 22.0766874 },
    { lat: 48.6717255, lng: 22.076494 },
    { lat: 48.6716736, lng: 22.0760676 },
    { lat: 48.6718828, lng: 22.0756338 },
    { lat: 48.6716681, lng: 22.0752234 },
    { lat: 48.6716994, lng: 22.0748846 },
    { lat: 48.6718214, lng: 22.0746217 },
    { lat: 48.6724194, lng: 22.0738899 },
    { lat: 48.6727473, lng: 22.0729723 },
    { lat: 48.6732954, lng: 22.0720445 },
    { lat: 48.6732884, lng: 22.0716035 },
    { lat: 48.6735106, lng: 22.0712038 },
    { lat: 48.6736694, lng: 22.0707489 },
    { lat: 48.6735939, lng: 22.070322 },
    { lat: 48.6734267, lng: 22.0699136 },
    { lat: 48.6734453, lng: 22.0694677 },
    { lat: 48.6733631, lng: 22.068614 },
    { lat: 48.67285, lng: 22.0683679 },
    { lat: 48.6725605, lng: 22.068593 },
    { lat: 48.6723201, lng: 22.0684683 },
    { lat: 48.6721301, lng: 22.0679957 },
    { lat: 48.6714773, lng: 22.0676046 },
    { lat: 48.6713791, lng: 22.0671682 },
    { lat: 48.6714392, lng: 22.0670984 },
    { lat: 48.6713024, lng: 22.0663627 },
    { lat: 48.671038, lng: 22.0661227 },
    { lat: 48.6708329, lng: 22.0658242 },
    { lat: 48.6703137, lng: 22.0655155 },
    { lat: 48.670148, lng: 22.0652665 },
    { lat: 48.6700762, lng: 22.0640985 },
    { lat: 48.6699687, lng: 22.0639331 },
    { lat: 48.6691641, lng: 22.0632211 },
    { lat: 48.6690725, lng: 22.0631948 },
    { lat: 48.668929, lng: 22.0629795 },
    { lat: 48.6688711, lng: 22.0627268 },
    { lat: 48.668968, lng: 22.0622642 },
    { lat: 48.6688023, lng: 22.0617366 },
    { lat: 48.6687128, lng: 22.0609115 },
    { lat: 48.6689258, lng: 22.060454 },
    { lat: 48.6690075, lng: 22.0598733 },
    { lat: 48.668783, lng: 22.059424 },
    { lat: 48.6687177, lng: 22.059377 },
    { lat: 48.6694209, lng: 22.0584435 },
    { lat: 48.6711098, lng: 22.0568194 },
    { lat: 48.6712726, lng: 22.056436 },
    { lat: 48.6714586, lng: 22.0561682 },
    { lat: 48.6715887, lng: 22.055167 },
    { lat: 48.6714094, lng: 22.0542575 },
    { lat: 48.6713367, lng: 22.0533415 },
    { lat: 48.6713423, lng: 22.05287 },
    { lat: 48.6708102, lng: 22.052639 },
    { lat: 48.6708338, lng: 22.0520237 },
    { lat: 48.6706438, lng: 22.0518975 },
    { lat: 48.6702039, lng: 22.051886 },
    { lat: 48.6699028, lng: 22.0516648 },
    { lat: 48.6700889, lng: 22.0510812 },
    { lat: 48.6700318, lng: 22.050864 },
    { lat: 48.6698368, lng: 22.0508915 },
    { lat: 48.6694804, lng: 22.0507526 },
    { lat: 48.6692496, lng: 22.0505678 },
    { lat: 48.6690268, lng: 22.0502312 },
    { lat: 48.668891, lng: 22.0495934 },
    { lat: 48.6687489, lng: 22.0492927 },
    { lat: 48.66803, lng: 22.0485901 },
    { lat: 48.668328, lng: 22.0471442 },
    { lat: 48.6683871, lng: 22.0466501 },
    { lat: 48.6683884, lng: 22.0461849 },
    { lat: 48.6681612, lng: 22.0447401 },
    { lat: 48.6681432, lng: 22.0438065 },
    { lat: 48.6685447, lng: 22.0436613 },
    { lat: 48.6687742, lng: 22.0434334 },
    { lat: 48.6693229, lng: 22.0433726 },
    { lat: 48.6695606, lng: 22.0432496 },
    { lat: 48.6697088, lng: 22.0432845 },
    { lat: 48.6697751, lng: 22.0434253 },
    { lat: 48.6699077, lng: 22.0442612 },
    { lat: 48.6700126, lng: 22.0443579 },
    { lat: 48.6701907, lng: 22.0443349 },
    { lat: 48.6703008, lng: 22.0442278 },
    { lat: 48.6703384, lng: 22.043646 },
    { lat: 48.6704462, lng: 22.0434543 },
    { lat: 48.6705891, lng: 22.043409 },
    { lat: 48.6707121, lng: 22.0435628 },
    { lat: 48.6707146, lng: 22.0441752 },
    { lat: 48.6707837, lng: 22.0443397 },
    { lat: 48.6709155, lng: 22.0444385 },
    { lat: 48.6710649, lng: 22.0443816 },
    { lat: 48.6713943, lng: 22.0438143 },
    { lat: 48.6716076, lng: 22.0435606 },
    { lat: 48.6720624, lng: 22.0439068 },
    { lat: 48.6722655, lng: 22.0439792 },
    { lat: 48.6723925, lng: 22.043879 },
    { lat: 48.6725233, lng: 22.0433803 },
    { lat: 48.6726497, lng: 22.0433435 },
    { lat: 48.6729495, lng: 22.0434715 },
    { lat: 48.6733528, lng: 22.0434376 },
    { lat: 48.6739597, lng: 22.0448222 },
    { lat: 48.6742276, lng: 22.0450641 },
    { lat: 48.6744919, lng: 22.0449684 },
    { lat: 48.6746438, lng: 22.0446899 },
    { lat: 48.6749181, lng: 22.0434647 },
    { lat: 48.6750512, lng: 22.043431 },
    { lat: 48.6755899, lng: 22.0436595 },
    { lat: 48.6757099, lng: 22.0438326 },
    { lat: 48.6759731, lng: 22.0445838 },
    { lat: 48.6764675, lng: 22.0446467 },
    { lat: 48.676862, lng: 22.0448645 },
    { lat: 48.6769511, lng: 22.0450227 },
    { lat: 48.6769573, lng: 22.0451821 },
    { lat: 48.6768241, lng: 22.0459167 },
    { lat: 48.6768863, lng: 22.0461567 },
    { lat: 48.6770244, lng: 22.0463805 },
    { lat: 48.6771934, lng: 22.0465303 },
    { lat: 48.6773612, lng: 22.0465855 },
    { lat: 48.6778624, lng: 22.0465185 },
    { lat: 48.6783133, lng: 22.0469796 },
    { lat: 48.6789121, lng: 22.0474614 },
    { lat: 48.6789038, lng: 22.0477203 },
    { lat: 48.6788099, lng: 22.0479326 },
    { lat: 48.6784027, lng: 22.0482141 },
    { lat: 48.6783702, lng: 22.0484505 },
    { lat: 48.678442, lng: 22.0487199 },
    { lat: 48.6787614, lng: 22.0491434 },
    { lat: 48.6790308, lng: 22.0493935 },
    { lat: 48.679404, lng: 22.0504662 },
    { lat: 48.6795525, lng: 22.0506997 },
    { lat: 48.6797399, lng: 22.0508179 },
    { lat: 48.6799638, lng: 22.0508277 },
    { lat: 48.680165, lng: 22.0509591 },
    { lat: 48.6803599, lng: 22.0511057 },
    { lat: 48.6806981, lng: 22.051514 },
    { lat: 48.6809219, lng: 22.0514209 },
    { lat: 48.6812174, lng: 22.051404 },
    { lat: 48.681417, lng: 22.051471 },
    { lat: 48.681765, lng: 22.0520415 },
    { lat: 48.6819898, lng: 22.053395 },
    { lat: 48.6821549, lng: 22.0535743 },
    { lat: 48.6824345, lng: 22.0534593 },
    { lat: 48.6828427, lng: 22.0529354 },
    { lat: 48.683344, lng: 22.0524992 },
    { lat: 48.6839398, lng: 22.0521533 },
    { lat: 48.6844867, lng: 22.0519752 },
    { lat: 48.6848242, lng: 22.0519742 },
    { lat: 48.6853505, lng: 22.0525292 },
    { lat: 48.6855034, lng: 22.0525767 },
    { lat: 48.6859733, lng: 22.0522351 },
    { lat: 48.6883391, lng: 22.0525241 },
    { lat: 48.6887149, lng: 22.052479 },
    { lat: 48.6891784, lng: 22.0518715 },
    { lat: 48.6892371, lng: 22.0513638 },
    { lat: 48.6893323, lng: 22.0509719 },
    { lat: 48.6898294, lng: 22.0497508 },
    { lat: 48.6904631, lng: 22.0479939 },
    { lat: 48.6906517, lng: 22.047317 },
    { lat: 48.6908426, lng: 22.0454345 },
    { lat: 48.6908239, lng: 22.045265 },
    { lat: 48.6901198, lng: 22.04523 },
    { lat: 48.6894248, lng: 22.0449979 },
    { lat: 48.6885824, lng: 22.0448278 },
    { lat: 48.6882149, lng: 22.0446016 },
    { lat: 48.6878053, lng: 22.0441737 },
    { lat: 48.6873448, lng: 22.0433667 },
    { lat: 48.687121, lng: 22.0431397 },
    { lat: 48.6870138, lng: 22.0430737 },
    { lat: 48.6865796, lng: 22.0430339 },
    { lat: 48.6861883, lng: 22.0431316 },
    { lat: 48.6870155, lng: 22.0386195 },
    { lat: 48.6869815, lng: 22.0386153 },
    { lat: 48.6884573, lng: 22.0299584 },
    { lat: 48.6914363, lng: 22.0127433 },
    { lat: 48.692191, lng: 22.0121394 },
    { lat: 48.6921732, lng: 22.0117288 },
    { lat: 48.6923624, lng: 22.0115987 },
    { lat: 48.6921614, lng: 22.0097277 },
    { lat: 48.6919028, lng: 22.0088086 },
    { lat: 48.6920416, lng: 22.0086926 },
    { lat: 48.6921872, lng: 22.0091221 },
    { lat: 48.6922697, lng: 22.0090915 },
    { lat: 48.6922217, lng: 22.0088271 },
    { lat: 48.6923862, lng: 22.0086994 },
    { lat: 48.6923611, lng: 22.0085993 },
    { lat: 48.6924478, lng: 22.0085105 },
    { lat: 48.6920171, lng: 22.0074552 },
    { lat: 48.6928651, lng: 22.0066585 },
    { lat: 48.6927856, lng: 22.006539 },
    { lat: 48.6928358, lng: 22.0064315 },
    { lat: 48.6921405, lng: 22.0053195 },
    { lat: 48.6927487, lng: 22.0045193 },
    { lat: 48.6929862, lng: 22.0041169 },
    { lat: 48.6921584, lng: 22.0019616 },
    { lat: 48.6917754, lng: 22.0022646 },
    { lat: 48.6913663, lng: 22.0024622 },
    { lat: 48.6908345, lng: 22.00252 },
    { lat: 48.6898467, lng: 22.0022814 },
    { lat: 48.6863755, lng: 22.0012397 },
    { lat: 48.6861355, lng: 22.0012168 },
    { lat: 48.6834985, lng: 22.0017218 },
    { lat: 48.6830989, lng: 22.0019427 },
    { lat: 48.6826871, lng: 22.0022975 },
    { lat: 48.6807343, lng: 22.0053433 },
    { lat: 48.6798358, lng: 22.0064556 },
    { lat: 48.6794472, lng: 22.00681 },
    { lat: 48.6788022, lng: 22.0072521 },
    { lat: 48.6782736, lng: 22.0074894 },
    { lat: 48.6777216, lng: 22.0076352 },
    { lat: 48.6769116, lng: 22.0076852 },
    { lat: 48.6752668, lng: 22.0073109 },
    { lat: 48.6732504, lng: 22.007515 },
    { lat: 48.6728311, lng: 22.00716 },
    { lat: 48.672501, lng: 22.0066844 },
    { lat: 48.6721475, lng: 22.0063026 },
    { lat: 48.6716611, lng: 22.0060072 },
    { lat: 48.6712154, lng: 22.0059064 },
    { lat: 48.6707669, lng: 22.0059175 },
    { lat: 48.6698303, lng: 22.0062644 },
    { lat: 48.6697791, lng: 22.0060473 },
  ],
};

export default UnitsMichalovce;
