import { motion } from "framer-motion";
import MessageInput from "./MessageInput";
import ArrangeVisit from "./ArrangeVisit";
import PriceInput from "./PriceInput";
import BookInput from "./BookInput";
import Buy from "./Buy";
import Save from "./Save";

export default function Interest({ subMenu, inputValues, setInputValues, previous, size }) {
  return subMenu?.interest ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex items-start"
      initial={{ x: previous === "lv" || previous === "ow" ? 500 : -500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-base w-full flex flex-col items-center font-semibold gap-2 pb-2">
        <MessageInput inputValues={inputValues} setInputValues={setInputValues} size={size} />
        <ArrangeVisit inputValues={inputValues} setInputValues={setInputValues} size={size} />
        <PriceInput inputValues={inputValues} setInputValues={setInputValues} size={size} />
        <BookInput inputValues={inputValues} setInputValues={setInputValues} size={size} />
        <Buy size={size} />
        <Save size={size} />
      </div>
    </motion.div>
  ) : null;
}
