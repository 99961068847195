import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import UnitsKomárno from "../constants/Units/RegionNitra/UnitsKomarno";
import UnitsLevice from "../constants/Units/RegionNitra/UnitsLevice";
import UnitsNitra from "../constants/Units/RegionNitra/UnitsNitra";
import UnitsNovéZámky from "../constants/Units/RegionNitra/UnitsNoveZamky";
import UnitsŠaľa from "../constants/Units/RegionNitra/UnitsSala";
import UnitsTopoľčany from "../constants/Units/RegionNitra/UnitsTopolcany";
import UnitsZlatéMoravce from "../constants/Units/RegionNitra/UnitsZlateMoravce";
import { averagePriceService } from "../../services/averagePriceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function UnitsofNitraRegion({
  priceMap,
  genAvgPrice,
  setPMResult,
  PMResult,
  map,
  PMHistory,
  setPMHistory,
}) {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("length = ", Object.keys(genAvgPrice).length);
    if (Object.keys(genAvgPrice).length > 0) {
      // for (const k of Object.keys(genAvgPrice)) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 700 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
      // }
    } else return defaultColor;
  };

  const unitAvgPrice = async (parent, key) => {
    // console.log("parent = ", parent, "|  key = ", key);
    const request = await averagePriceService.getUnitAvgPrice(decodeURIComponent(parent), decodeURIComponent(key));
    const response = await request.json();
    setPMResult({ ...PMResult, active: true, data: response });
    // console.log("response = ", response);
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  return priceMap.city ? (
    <>
      {Object.keys(UnitsKomárno).map((key) => {
        if (priceMap.cityName === "DistrictKomárno") {
          return (
            <Polygon
              key={key}
              paths={UnitsKomárno[key]}
              options={{
                // strokeColor: "#2d3740",
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Komárno", key);
                setPMHistory({ ...PMHistory, city: "Komárno " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsLevice).map((key) => {
        if (priceMap.cityName === "DistrictLevice") {
          return (
            <Polygon
              key={key}
              paths={UnitsLevice[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Levice", key);
                setPMHistory({ ...PMHistory, city: "Levice " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsNitra).map((key) => {
        if (priceMap.cityName === "DistrictNitra") {
          return (
            <Polygon
              key={key}
              paths={UnitsNitra[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Nitra", key);
                setPMHistory({ ...PMHistory, city: "Nitra " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsNovéZámky).map((key) => {
        if (priceMap.cityName === "DistrictNovéZámky") {
          return (
            <Polygon
              key={key}
              paths={UnitsNovéZámky[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("NovéZámky", key);
                setPMHistory({ ...PMHistory, city: "NovéZámky " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsŠaľa).map((key) => {
        if (priceMap.cityName === "DistrictŠaľa") {
          return (
            <Polygon
              key={key}
              paths={UnitsŠaľa[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Šaľa", key);
                setPMHistory({ ...PMHistory, city: "Šaľa " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsTopoľčany).map((key) => {
        if (priceMap.cityName === "DistrictTopoľčany") {
          return (
            <Polygon
              key={key}
              paths={UnitsTopoľčany[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Topoľčany", key);
                setPMHistory({ ...PMHistory, city: "Topoľčany " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsZlatéMoravce).map((key) => {
        if (priceMap.cityName === "DistrictZlatéMoravce") {
          return (
            <Polygon
              key={key}
              paths={UnitsZlatéMoravce[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("ZlatéMoravce", key);
                setPMHistory({ ...PMHistory, city: "ZlatéMoravce " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-center text-sm rounded-lg opacity-100 transition-opacity duration-300"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name.replace("Ext", "")]?.obec
                .replace("nad", " nad")
                .replace("pod", " pod")
                .replace("pri", " pri")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                .replace("Ext", "")
                .replace("1", "")}
            </div>
            <div>za dom: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgDom} €</div>
            <div>za m² bytu: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgBytM2} €</div>
            <div>za m² pozemku: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgPozemokM2} €</div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
