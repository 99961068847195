const UnitsŽarnovica = {
  VeľkáLehota: [
    { lat: 48.476962, lng: 18.546591 },
    { lat: 48.47686, lng: 18.546538 },
    { lat: 48.476751, lng: 18.54648 },
    { lat: 48.476609, lng: 18.546404 },
    { lat: 48.476395, lng: 18.546288 },
    { lat: 48.476388, lng: 18.546281 },
    { lat: 48.475904, lng: 18.545903 },
    { lat: 48.475814, lng: 18.545832 },
    { lat: 48.475277, lng: 18.545384 },
    { lat: 48.47499, lng: 18.545146 },
    { lat: 48.474594, lng: 18.54458 },
    { lat: 48.474424, lng: 18.54434 },
    { lat: 48.474241, lng: 18.544218 },
    { lat: 48.473919, lng: 18.544001 },
    { lat: 48.473609, lng: 18.543453 },
    { lat: 48.473429, lng: 18.543129 },
    { lat: 48.472944, lng: 18.542687 },
    { lat: 48.472617, lng: 18.54239 },
    { lat: 48.472113, lng: 18.541926 },
    { lat: 48.471959, lng: 18.541788 },
    { lat: 48.471805, lng: 18.541574 },
    { lat: 48.471597, lng: 18.541267 },
    { lat: 48.471319, lng: 18.54087 },
    { lat: 48.471071, lng: 18.540504 },
    { lat: 48.47088, lng: 18.540229 },
    { lat: 48.470639, lng: 18.539894 },
    { lat: 48.470439, lng: 18.539617 },
    { lat: 48.470239, lng: 18.539346 },
    { lat: 48.470227, lng: 18.539333 },
    { lat: 48.470121, lng: 18.539185 },
    { lat: 48.469841, lng: 18.538805 },
    { lat: 48.469735, lng: 18.538664 },
    { lat: 48.469521, lng: 18.538215 },
    { lat: 48.469205, lng: 18.537564 },
    { lat: 48.469125, lng: 18.537397 },
    { lat: 48.468767, lng: 18.537074 },
    { lat: 48.468507, lng: 18.536648 },
    { lat: 48.468477, lng: 18.536454 },
    { lat: 48.468391, lng: 18.535956 },
    { lat: 48.468268, lng: 18.535214 },
    { lat: 48.468256, lng: 18.535142 },
    { lat: 48.468241, lng: 18.535012 },
    { lat: 48.468197, lng: 18.534618 },
    { lat: 48.468069, lng: 18.533505 },
    { lat: 48.467703, lng: 18.532892 },
    { lat: 48.467124, lng: 18.532394 },
    { lat: 48.467073, lng: 18.532295 },
    { lat: 48.466622, lng: 18.531414 },
    { lat: 48.466488, lng: 18.531148 },
    { lat: 48.465501, lng: 18.530706 },
    { lat: 48.465256, lng: 18.530594 },
    { lat: 48.464586, lng: 18.529994 },
    { lat: 48.464261, lng: 18.529699 },
    { lat: 48.463671, lng: 18.529652 },
    { lat: 48.46317, lng: 18.530081 },
    { lat: 48.46291, lng: 18.530305 },
    { lat: 48.462201, lng: 18.530909 },
    { lat: 48.462174, lng: 18.530882 },
    { lat: 48.462161, lng: 18.530869 },
    { lat: 48.462005, lng: 18.530697 },
    { lat: 48.461974, lng: 18.530663 },
    { lat: 48.461092, lng: 18.529688 },
    { lat: 48.459993, lng: 18.528458 },
    { lat: 48.458433, lng: 18.526712 },
    { lat: 48.457436, lng: 18.525602 },
    { lat: 48.457388, lng: 18.525548 },
    { lat: 48.456682, lng: 18.524753 },
    { lat: 48.455869, lng: 18.523834 },
    { lat: 48.455639, lng: 18.523701 },
    { lat: 48.453849, lng: 18.522635 },
    { lat: 48.453179, lng: 18.522241 },
    { lat: 48.452615, lng: 18.521909 },
    { lat: 48.452217, lng: 18.521672 },
    { lat: 48.451553, lng: 18.521272 },
    { lat: 48.451041, lng: 18.520971 },
    { lat: 48.451052, lng: 18.521255 },
    { lat: 48.451129, lng: 18.521434 },
    { lat: 48.451186, lng: 18.521463 },
    { lat: 48.451274, lng: 18.521914 },
    { lat: 48.451428, lng: 18.522331 },
    { lat: 48.45142, lng: 18.522446 },
    { lat: 48.451481, lng: 18.522763 },
    { lat: 48.451472, lng: 18.522931 },
    { lat: 48.451425, lng: 18.523033 },
    { lat: 48.451411, lng: 18.523398 },
    { lat: 48.45135, lng: 18.523736 },
    { lat: 48.451425, lng: 18.524131 },
    { lat: 48.451531, lng: 18.524472 },
    { lat: 48.451481, lng: 18.52476 },
    { lat: 48.451531, lng: 18.524907 },
    { lat: 48.451703, lng: 18.525158 },
    { lat: 48.451761, lng: 18.525302 },
    { lat: 48.451691, lng: 18.525649 },
    { lat: 48.451647, lng: 18.525975 },
    { lat: 48.451697, lng: 18.526255 },
    { lat: 48.451841, lng: 18.526434 },
    { lat: 48.4519, lng: 18.526545 },
    { lat: 48.45197, lng: 18.526957 },
    { lat: 48.452027, lng: 18.527059 },
    { lat: 48.45225, lng: 18.527215 },
    { lat: 48.452369, lng: 18.527481 },
    { lat: 48.452421, lng: 18.527714 },
    { lat: 48.452358, lng: 18.527911 },
    { lat: 48.452388, lng: 18.528076 },
    { lat: 48.452376, lng: 18.52823 },
    { lat: 48.452514, lng: 18.528505 },
    { lat: 48.452515, lng: 18.52862 },
    { lat: 48.452583, lng: 18.52874 },
    { lat: 48.452577, lng: 18.52893 },
    { lat: 48.452522, lng: 18.529194 },
    { lat: 48.452505, lng: 18.529603 },
    { lat: 48.452483, lng: 18.529968 },
    { lat: 48.452484, lng: 18.530312 },
    { lat: 48.452439, lng: 18.530684 },
    { lat: 48.452469, lng: 18.530917 },
    { lat: 48.45242, lng: 18.531177 },
    { lat: 48.452266, lng: 18.531844 },
    { lat: 48.452156, lng: 18.532058 },
    { lat: 48.452058, lng: 18.532512 },
    { lat: 48.452011, lng: 18.532638 },
    { lat: 48.451888, lng: 18.53271 },
    { lat: 48.451794, lng: 18.532746 },
    { lat: 48.451766, lng: 18.532853 },
    { lat: 48.451719, lng: 18.532969 },
    { lat: 48.451428, lng: 18.533021 },
    { lat: 48.451258, lng: 18.533258 },
    { lat: 48.451105, lng: 18.533409 },
    { lat: 48.450976, lng: 18.533503 },
    { lat: 48.450779, lng: 18.533552 },
    { lat: 48.450633, lng: 18.533559 },
    { lat: 48.45056, lng: 18.533456 },
    { lat: 48.450462, lng: 18.533429 },
    { lat: 48.450326, lng: 18.533297 },
    { lat: 48.450222, lng: 18.533344 },
    { lat: 48.450155, lng: 18.533468 },
    { lat: 48.450057, lng: 18.533521 },
    { lat: 48.449937, lng: 18.533643 },
    { lat: 48.449875, lng: 18.533786 },
    { lat: 48.449809, lng: 18.533812 },
    { lat: 48.449754, lng: 18.533861 },
    { lat: 48.449761, lng: 18.534111 },
    { lat: 48.449715, lng: 18.534198 },
    { lat: 48.449654, lng: 18.534233 },
    { lat: 48.44957, lng: 18.534235 },
    { lat: 48.449549, lng: 18.534228 },
    { lat: 48.449544, lng: 18.534144 },
    { lat: 48.449525, lng: 18.534118 },
    { lat: 48.449059, lng: 18.534442 },
    { lat: 48.448767, lng: 18.534498 },
    { lat: 48.448582, lng: 18.534413 },
    { lat: 48.448374, lng: 18.534395 },
    { lat: 48.44827, lng: 18.534298 },
    { lat: 48.448206, lng: 18.534343 },
    { lat: 48.44807, lng: 18.534239 },
    { lat: 48.447772, lng: 18.534374 },
    { lat: 48.447515, lng: 18.534288 },
    { lat: 48.447304, lng: 18.534338 },
    { lat: 48.447148, lng: 18.534448 },
    { lat: 48.447025, lng: 18.534467 },
    { lat: 48.446889, lng: 18.534453 },
    { lat: 48.446801, lng: 18.534485 },
    { lat: 48.446714, lng: 18.534517 },
    { lat: 48.446552, lng: 18.534551 },
    { lat: 48.446345, lng: 18.534518 },
    { lat: 48.446182, lng: 18.534534 },
    { lat: 48.44584, lng: 18.534334 },
    { lat: 48.445647, lng: 18.534418 },
    { lat: 48.445424, lng: 18.534604 },
    { lat: 48.445164, lng: 18.534576 },
    { lat: 48.444806, lng: 18.534609 },
    { lat: 48.444477, lng: 18.534574 },
    { lat: 48.444045, lng: 18.534685 },
    { lat: 48.443972, lng: 18.534668 },
    { lat: 48.443873, lng: 18.53468 },
    { lat: 48.443705, lng: 18.534546 },
    { lat: 48.443502, lng: 18.534484 },
    { lat: 48.44339, lng: 18.534523 },
    { lat: 48.443293, lng: 18.534626 },
    { lat: 48.443142, lng: 18.534704 },
    { lat: 48.442999, lng: 18.534607 },
    { lat: 48.442943, lng: 18.534529 },
    { lat: 48.442782, lng: 18.534503 },
    { lat: 48.442746, lng: 18.534451 },
    { lat: 48.442677, lng: 18.534414 },
    { lat: 48.442605, lng: 18.534422 },
    { lat: 48.442446, lng: 18.534526 },
    { lat: 48.442331, lng: 18.534644 },
    { lat: 48.442133, lng: 18.534702 },
    { lat: 48.442041, lng: 18.534623 },
    { lat: 48.441782, lng: 18.53452 },
    { lat: 48.441662, lng: 18.534583 },
    { lat: 48.441554, lng: 18.534571 },
    { lat: 48.4413, lng: 18.534768 },
    { lat: 48.441079, lng: 18.534903 },
    { lat: 48.440994, lng: 18.534923 },
    { lat: 48.440859, lng: 18.534785 },
    { lat: 48.440817, lng: 18.534761 },
    { lat: 48.440775, lng: 18.534792 },
    { lat: 48.440713, lng: 18.534839 },
    { lat: 48.440387, lng: 18.534806 },
    { lat: 48.440115, lng: 18.534988 },
    { lat: 48.439967, lng: 18.535009 },
    { lat: 48.439911, lng: 18.535093 },
    { lat: 48.439742, lng: 18.535181 },
    { lat: 48.439726, lng: 18.53519 },
    { lat: 48.439579, lng: 18.535101 },
    { lat: 48.439482, lng: 18.535108 },
    { lat: 48.439374, lng: 18.535321 },
    { lat: 48.439381, lng: 18.535731 },
    { lat: 48.439301, lng: 18.535963 },
    { lat: 48.439259, lng: 18.536224 },
    { lat: 48.439123, lng: 18.536687 },
    { lat: 48.439145, lng: 18.536822 },
    { lat: 48.43916, lng: 18.536864 },
    { lat: 48.439262, lng: 18.537353 },
    { lat: 48.439221, lng: 18.537787 },
    { lat: 48.439126, lng: 18.538052 },
    { lat: 48.439204, lng: 18.5384 },
    { lat: 48.439232, lng: 18.538874 },
    { lat: 48.439267, lng: 18.539043 },
    { lat: 48.439156, lng: 18.539403 },
    { lat: 48.439066, lng: 18.539675 },
    { lat: 48.439017, lng: 18.53975 },
    { lat: 48.439016, lng: 18.539905 },
    { lat: 48.439034, lng: 18.539999 },
    { lat: 48.439056, lng: 18.540064 },
    { lat: 48.439077, lng: 18.540219 },
    { lat: 48.438959, lng: 18.540346 },
    { lat: 48.438785, lng: 18.540359 },
    { lat: 48.438584, lng: 18.540511 },
    { lat: 48.438514, lng: 18.540747 },
    { lat: 48.438323, lng: 18.540974 },
    { lat: 48.438269, lng: 18.541041 },
    { lat: 48.438161, lng: 18.541022 },
    { lat: 48.438087, lng: 18.541113 },
    { lat: 48.438031, lng: 18.541284 },
    { lat: 48.437834, lng: 18.541705 },
    { lat: 48.437696, lng: 18.541852 },
    { lat: 48.437587, lng: 18.542007 },
    { lat: 48.437501, lng: 18.542261 },
    { lat: 48.437383, lng: 18.54236 },
    { lat: 48.437308, lng: 18.542485 },
    { lat: 48.437026, lng: 18.542802 },
    { lat: 48.436971, lng: 18.542925 },
    { lat: 48.436943, lng: 18.543194 },
    { lat: 48.436884, lng: 18.543336 },
    { lat: 48.436738, lng: 18.543526 },
    { lat: 48.436717, lng: 18.543726 },
    { lat: 48.4366, lng: 18.543918 },
    { lat: 48.43657, lng: 18.544071 },
    { lat: 48.436603, lng: 18.544229 },
    { lat: 48.436603, lng: 18.544339 },
    { lat: 48.436518, lng: 18.544551 },
    { lat: 48.436459, lng: 18.544588 },
    { lat: 48.436357, lng: 18.5446 },
    { lat: 48.436286, lng: 18.544806 },
    { lat: 48.436283, lng: 18.545048 },
    { lat: 48.436218, lng: 18.545259 },
    { lat: 48.436126, lng: 18.545452 },
    { lat: 48.436151, lng: 18.545636 },
    { lat: 48.436109, lng: 18.545731 },
    { lat: 48.436041, lng: 18.545805 },
    { lat: 48.435914, lng: 18.545825 },
    { lat: 48.435592, lng: 18.545988 },
    { lat: 48.435514, lng: 18.545901 },
    { lat: 48.435448, lng: 18.545908 },
    { lat: 48.435414, lng: 18.545941 },
    { lat: 48.435278, lng: 18.545965 },
    { lat: 48.435239, lng: 18.545902 },
    { lat: 48.435173, lng: 18.545869 },
    { lat: 48.434903, lng: 18.545988 },
    { lat: 48.434581, lng: 18.545998 },
    { lat: 48.434411, lng: 18.545827 },
    { lat: 48.43434, lng: 18.545857 },
    { lat: 48.434225, lng: 18.545823 },
    { lat: 48.434182, lng: 18.545875 },
    { lat: 48.433802, lng: 18.545838 },
    { lat: 48.433631, lng: 18.545896 },
    { lat: 48.433131, lng: 18.545834 },
    { lat: 48.432702, lng: 18.545936 },
    { lat: 48.432347, lng: 18.546194 },
    { lat: 48.432205, lng: 18.546191 },
    { lat: 48.432166, lng: 18.546158 },
    { lat: 48.432032, lng: 18.545963 },
    { lat: 48.431885, lng: 18.545829 },
    { lat: 48.431741, lng: 18.54571 },
    { lat: 48.431592, lng: 18.545519 },
    { lat: 48.431302, lng: 18.545202 },
    { lat: 48.431279, lng: 18.545166 },
    { lat: 48.431236, lng: 18.545002 },
    { lat: 48.431214, lng: 18.544918 },
    { lat: 48.431105, lng: 18.544629 },
    { lat: 48.431012, lng: 18.544507 },
    { lat: 48.43091, lng: 18.544344 },
    { lat: 48.430879, lng: 18.544352 },
    { lat: 48.430754, lng: 18.544331 },
    { lat: 48.430735, lng: 18.544306 },
    { lat: 48.430663, lng: 18.544151 },
    { lat: 48.430652, lng: 18.544013 },
    { lat: 48.43055, lng: 18.54394 },
    { lat: 48.430369, lng: 18.544079 },
    { lat: 48.4302, lng: 18.543981 },
    { lat: 48.430162, lng: 18.544031 },
    { lat: 48.430032, lng: 18.54405 },
    { lat: 48.430003, lng: 18.544055 },
    { lat: 48.429861, lng: 18.544079 },
    { lat: 48.429777, lng: 18.544124 },
    { lat: 48.429651, lng: 18.544048 },
    { lat: 48.429604, lng: 18.54402 },
    { lat: 48.429524, lng: 18.543949 },
    { lat: 48.429437, lng: 18.543866 },
    { lat: 48.429301, lng: 18.543711 },
    { lat: 48.429067, lng: 18.543686 },
    { lat: 48.428895, lng: 18.543623 },
    { lat: 48.428856, lng: 18.54354 },
    { lat: 48.428824, lng: 18.543454 },
    { lat: 48.428788, lng: 18.543405 },
    { lat: 48.428754, lng: 18.543412 },
    { lat: 48.42861, lng: 18.543289 },
    { lat: 48.428599, lng: 18.543206 },
    { lat: 48.428589, lng: 18.543194 },
    { lat: 48.428497, lng: 18.543173 },
    { lat: 48.428445, lng: 18.543147 },
    { lat: 48.428427, lng: 18.543127 },
    { lat: 48.428357, lng: 18.542974 },
    { lat: 48.428336, lng: 18.542926 },
    { lat: 48.428257, lng: 18.542733 },
    { lat: 48.428157, lng: 18.542693 },
    { lat: 48.428021, lng: 18.542544 },
    { lat: 48.427968, lng: 18.542475 },
    { lat: 48.427956, lng: 18.542452 },
    { lat: 48.427829, lng: 18.542075 },
    { lat: 48.427825, lng: 18.542025 },
    { lat: 48.427715, lng: 18.541846 },
    { lat: 48.427588, lng: 18.541706 },
    { lat: 48.427353, lng: 18.541358 },
    { lat: 48.427211, lng: 18.541346 },
    { lat: 48.427053, lng: 18.541397 },
    { lat: 48.426939, lng: 18.541497 },
    { lat: 48.42634, lng: 18.541557 },
    { lat: 48.426059, lng: 18.541474 },
    { lat: 48.425869, lng: 18.541475 },
    { lat: 48.425605, lng: 18.541476 },
    { lat: 48.425479, lng: 18.541323 },
    { lat: 48.425273, lng: 18.540934 },
    { lat: 48.425155, lng: 18.540822 },
    { lat: 48.425025, lng: 18.540807 },
    { lat: 48.424705, lng: 18.540819 },
    { lat: 48.424402, lng: 18.540708 },
    { lat: 48.424328, lng: 18.540633 },
    { lat: 48.424243, lng: 18.540395 },
    { lat: 48.424043, lng: 18.539677 },
    { lat: 48.423979, lng: 18.539584 },
    { lat: 48.423871, lng: 18.539523 },
    { lat: 48.423741, lng: 18.53935 },
    { lat: 48.423483, lng: 18.539159 },
    { lat: 48.423424, lng: 18.539052 },
    { lat: 48.423271, lng: 18.538922 },
    { lat: 48.423033, lng: 18.538907 },
    { lat: 48.422946, lng: 18.538773 },
    { lat: 48.422761, lng: 18.538745 },
    { lat: 48.422548, lng: 18.538592 },
    { lat: 48.422047, lng: 18.538529 },
    { lat: 48.421762, lng: 18.538363 },
    { lat: 48.421582, lng: 18.53847 },
    { lat: 48.421298, lng: 18.538334 },
    { lat: 48.421033, lng: 18.538452 },
    { lat: 48.420854, lng: 18.538259 },
    { lat: 48.420701, lng: 18.538231 },
    { lat: 48.420588, lng: 18.538121 },
    { lat: 48.420536, lng: 18.53793 },
    { lat: 48.420395, lng: 18.537852 },
    { lat: 48.419982, lng: 18.537511 },
    { lat: 48.419917, lng: 18.537394 },
    { lat: 48.419825, lng: 18.537336 },
    { lat: 48.419747, lng: 18.537331 },
    { lat: 48.419538, lng: 18.537262 },
    { lat: 48.419153, lng: 18.537706 },
    { lat: 48.418881, lng: 18.537914 },
    { lat: 48.41861, lng: 18.538104 },
    { lat: 48.418442, lng: 18.538262 },
    { lat: 48.418185, lng: 18.538392 },
    { lat: 48.418105, lng: 18.538508 },
    { lat: 48.417782, lng: 18.538781 },
    { lat: 48.417743, lng: 18.538855 },
    { lat: 48.417484, lng: 18.539171 },
    { lat: 48.417438, lng: 18.539336 },
    { lat: 48.4176835, lng: 18.5393918 },
    { lat: 48.418479, lng: 18.5392933 },
    { lat: 48.4192914, lng: 18.5399572 },
    { lat: 48.419638, lng: 18.5409718 },
    { lat: 48.4207761, lng: 18.5427159 },
    { lat: 48.4212683, lng: 18.5438776 },
    { lat: 48.4219238, lng: 18.545689 },
    { lat: 48.4227299, lng: 18.5467516 },
    { lat: 48.4231057, lng: 18.5470826 },
    { lat: 48.4239784, lng: 18.5476732 },
    { lat: 48.4245043, lng: 18.5476987 },
    { lat: 48.4254012, lng: 18.5476198 },
    { lat: 48.4256687, lng: 18.5474961 },
    { lat: 48.4259382, lng: 18.5475789 },
    { lat: 48.4261475, lng: 18.5481042 },
    { lat: 48.426201, lng: 18.5480536 },
    { lat: 48.4263679, lng: 18.5481966 },
    { lat: 48.4286109, lng: 18.5507606 },
    { lat: 48.4289984, lng: 18.5509773 },
    { lat: 48.4300722, lng: 18.5521306 },
    { lat: 48.4302726, lng: 18.5522682 },
    { lat: 48.4315947, lng: 18.5535475 },
    { lat: 48.4323663, lng: 18.5546584 },
    { lat: 48.4331854, lng: 18.5553035 },
    { lat: 48.4340585, lng: 18.5557564 },
    { lat: 48.4347021, lng: 18.5562218 },
    { lat: 48.4354655, lng: 18.5579068 },
    { lat: 48.4359993, lng: 18.5579336 },
    { lat: 48.4382611, lng: 18.5601032 },
    { lat: 48.4388284, lng: 18.5604328 },
    { lat: 48.4390741, lng: 18.5607989 },
    { lat: 48.4396743, lng: 18.5626811 },
    { lat: 48.4405283, lng: 18.562949 },
    { lat: 48.4409888, lng: 18.5631622 },
    { lat: 48.4413563, lng: 18.5634891 },
    { lat: 48.4417234, lng: 18.5638701 },
    { lat: 48.4425337, lng: 18.565156 },
    { lat: 48.4433287, lng: 18.5660193 },
    { lat: 48.4442304, lng: 18.5672701 },
    { lat: 48.4454502, lng: 18.5680825 },
    { lat: 48.4468451, lng: 18.5696265 },
    { lat: 48.4472327, lng: 18.5704435 },
    { lat: 48.4474141, lng: 18.5707107 },
    { lat: 48.4484985, lng: 18.5721222 },
    { lat: 48.450173, lng: 18.5739737 },
    { lat: 48.4506295, lng: 18.5746899 },
    { lat: 48.4509145, lng: 18.5748101 },
    { lat: 48.4523244, lng: 18.5757596 },
    { lat: 48.4526113, lng: 18.5762014 },
    { lat: 48.4532086, lng: 18.5775587 },
    { lat: 48.4536259, lng: 18.5782415 },
    { lat: 48.4538506, lng: 18.5793214 },
    { lat: 48.4541923, lng: 18.580416 },
    { lat: 48.4549959, lng: 18.5817917 },
    { lat: 48.4552397, lng: 18.5821053 },
    { lat: 48.4554072, lng: 18.5824692 },
    { lat: 48.4556428, lng: 18.5826932 },
    { lat: 48.4562586, lng: 18.5836845 },
    { lat: 48.4565864, lng: 18.5843403 },
    { lat: 48.4569012, lng: 18.5852341 },
    { lat: 48.4576732, lng: 18.585864 },
    { lat: 48.4582544, lng: 18.5862413 },
    { lat: 48.4585157, lng: 18.586652 },
    { lat: 48.4591763, lng: 18.5871813 },
    { lat: 48.4594009, lng: 18.5875186 },
    { lat: 48.4597687, lng: 18.5878316 },
    { lat: 48.4604226, lng: 18.5885284 },
    { lat: 48.4612649, lng: 18.5887745 },
    { lat: 48.4616709, lng: 18.5890636 },
    { lat: 48.4619187, lng: 18.5890801 },
    { lat: 48.462574, lng: 18.5893471 },
    { lat: 48.4634168, lng: 18.5895374 },
    { lat: 48.4638609, lng: 18.5893893 },
    { lat: 48.4649484, lng: 18.5896799 },
    { lat: 48.4669255, lng: 18.5900489 },
    { lat: 48.467927, lng: 18.5905449 },
    { lat: 48.468586, lng: 18.5909462 },
    { lat: 48.4699486, lng: 18.5919186 },
    { lat: 48.4706126, lng: 18.5927039 },
    { lat: 48.4711863, lng: 18.592943 },
    { lat: 48.4715564, lng: 18.5933436 },
    { lat: 48.4718441, lng: 18.5937565 },
    { lat: 48.4723348, lng: 18.5936741 },
    { lat: 48.4746398, lng: 18.5958678 },
    { lat: 48.475298, lng: 18.5962548 },
    { lat: 48.4777601, lng: 18.5971628 },
    { lat: 48.4782681, lng: 18.597378 },
    { lat: 48.477867, lng: 18.5963675 },
    { lat: 48.477867, lng: 18.5950184 },
    { lat: 48.4784051, lng: 18.5945648 },
    { lat: 48.4790895, lng: 18.5908834 },
    { lat: 48.4793272, lng: 18.5888264 },
    { lat: 48.4792159, lng: 18.5874831 },
    { lat: 48.4793852, lng: 18.5852457 },
    { lat: 48.4791826, lng: 18.5842155 },
    { lat: 48.4790152, lng: 18.5819526 },
    { lat: 48.4794669, lng: 18.5796001 },
    { lat: 48.4796962, lng: 18.5780069 },
    { lat: 48.4802766, lng: 18.5771287 },
    { lat: 48.4804049, lng: 18.5766685 },
    { lat: 48.4806817, lng: 18.5763261 },
    { lat: 48.4826856, lng: 18.5722621 },
    { lat: 48.4829352, lng: 18.5714609 },
    { lat: 48.4829093, lng: 18.5712536 },
    { lat: 48.482498, lng: 18.5711973 },
    { lat: 48.4825269, lng: 18.5708599 },
    { lat: 48.4827827, lng: 18.5702986 },
    { lat: 48.4828261, lng: 18.5700789 },
    { lat: 48.4826466, lng: 18.5696235 },
    { lat: 48.4828194, lng: 18.5693768 },
    { lat: 48.4828367, lng: 18.5686618 },
    { lat: 48.4828015, lng: 18.5684496 },
    { lat: 48.4824628, lng: 18.5679908 },
    { lat: 48.4820431, lng: 18.5677198 },
    { lat: 48.481554, lng: 18.567622 },
    { lat: 48.4811901, lng: 18.567963 },
    { lat: 48.480336, lng: 18.5681427 },
    { lat: 48.4803489, lng: 18.567968 },
    { lat: 48.4797842, lng: 18.5671265 },
    { lat: 48.4797919, lng: 18.5665052 },
    { lat: 48.4789173, lng: 18.5651321 },
    { lat: 48.4786835, lng: 18.5650824 },
    { lat: 48.477945, lng: 18.5653804 },
    { lat: 48.4775765, lng: 18.5652198 },
    { lat: 48.477208, lng: 18.5652887 },
    { lat: 48.4767018, lng: 18.5658109 },
    { lat: 48.4762747, lng: 18.5657157 },
    { lat: 48.4759566, lng: 18.5655296 },
    { lat: 48.475645, lng: 18.5654596 },
    { lat: 48.4755366, lng: 18.5653564 },
    { lat: 48.475311, lng: 18.5648574 },
    { lat: 48.4751826, lng: 18.5638082 },
    { lat: 48.475385, lng: 18.5629912 },
    { lat: 48.4752484, lng: 18.5623511 },
    { lat: 48.4751104, lng: 18.5622175 },
    { lat: 48.4749105, lng: 18.5621711 },
    { lat: 48.4749481, lng: 18.5617046 },
    { lat: 48.4752891, lng: 18.5618975 },
    { lat: 48.4756784, lng: 18.5622923 },
    { lat: 48.4755354, lng: 18.5632873 },
    { lat: 48.4755438, lng: 18.5641035 },
    { lat: 48.4756554, lng: 18.5647612 },
    { lat: 48.4758111, lng: 18.5651341 },
    { lat: 48.4765508, lng: 18.5652955 },
    { lat: 48.4769991, lng: 18.5652196 },
    { lat: 48.4773719, lng: 18.5650058 },
    { lat: 48.4790874, lng: 18.5648565 },
    { lat: 48.4797983, lng: 18.5651925 },
    { lat: 48.4801056, lng: 18.566528 },
    { lat: 48.4804313, lng: 18.5666435 },
    { lat: 48.4806691, lng: 18.5658565 },
    { lat: 48.4815107, lng: 18.5657992 },
    { lat: 48.4817138, lng: 18.5660457 },
    { lat: 48.4822722, lng: 18.5656757 },
    { lat: 48.482626, lng: 18.5652942 },
    { lat: 48.4827578, lng: 18.5650241 },
    { lat: 48.4827422, lng: 18.5645091 },
    { lat: 48.4829939, lng: 18.5639973 },
    { lat: 48.4831487, lng: 18.5638153 },
    { lat: 48.4833228, lng: 18.5633057 },
    { lat: 48.4835039, lng: 18.5630483 },
    { lat: 48.4835483, lng: 18.5624359 },
    { lat: 48.4839463, lng: 18.5620195 },
    { lat: 48.4840215, lng: 18.5617645 },
    { lat: 48.484016, lng: 18.5611892 },
    { lat: 48.4838731, lng: 18.5607009 },
    { lat: 48.4843945, lng: 18.5602724 },
    { lat: 48.484613, lng: 18.5598329 },
    { lat: 48.4846563, lng: 18.5591794 },
    { lat: 48.4845214, lng: 18.5586499 },
    { lat: 48.4847645, lng: 18.5581242 },
    { lat: 48.4847974, lng: 18.5579851 },
    { lat: 48.4847587, lng: 18.5577705 },
    { lat: 48.4848994, lng: 18.5576635 },
    { lat: 48.4849798, lng: 18.5571547 },
    { lat: 48.4849259, lng: 18.5569946 },
    { lat: 48.4850666, lng: 18.5557502 },
    { lat: 48.4849183, lng: 18.5547061 },
    { lat: 48.4849769, lng: 18.5547266 },
    { lat: 48.4851561, lng: 18.5537117 },
    { lat: 48.4851321, lng: 18.5534195 },
    { lat: 48.4850475, lng: 18.5532345 },
    { lat: 48.4848559, lng: 18.5524695 },
    { lat: 48.4848959, lng: 18.5520255 },
    { lat: 48.4847503, lng: 18.5519226 },
    { lat: 48.4842503, lng: 18.5502483 },
    { lat: 48.4839243, lng: 18.54973 },
    { lat: 48.4834194, lng: 18.5480479 },
    { lat: 48.482698, lng: 18.5470069 },
    { lat: 48.481713, lng: 18.5464245 },
    { lat: 48.4813954, lng: 18.5461229 },
    { lat: 48.4809934, lng: 18.5458481 },
    { lat: 48.4801238, lng: 18.5457482 },
    { lat: 48.4795971, lng: 18.5454792 },
    { lat: 48.4795201, lng: 18.5455374 },
    { lat: 48.4793445, lng: 18.5453996 },
    { lat: 48.4793393, lng: 18.5452217 },
    { lat: 48.4792225, lng: 18.5451093 },
    { lat: 48.4788051, lng: 18.5449409 },
    { lat: 48.4777299, lng: 18.5451246 },
    { lat: 48.4774687, lng: 18.5460389 },
    { lat: 48.4772615, lng: 18.5463554 },
    { lat: 48.476962, lng: 18.546591 },
  ],
  Hrabičov: [
    { lat: 48.55462, lng: 18.700326 },
    { lat: 48.554997, lng: 18.700434 },
    { lat: 48.555074, lng: 18.700453 },
    { lat: 48.556574, lng: 18.700816 },
    { lat: 48.55696, lng: 18.700836 },
    { lat: 48.557171, lng: 18.700951 },
    { lat: 48.559092, lng: 18.701012 },
    { lat: 48.560849, lng: 18.700714 },
    { lat: 48.561071, lng: 18.700808 },
    { lat: 48.561863, lng: 18.701289 },
    { lat: 48.56234, lng: 18.701612 },
    { lat: 48.562973, lng: 18.701602 },
    { lat: 48.56376, lng: 18.70191 },
    { lat: 48.564103, lng: 18.702045 },
    { lat: 48.564212, lng: 18.702117 },
    { lat: 48.564931, lng: 18.702594 },
    { lat: 48.565314, lng: 18.703139 },
    { lat: 48.565628, lng: 18.703198 },
    { lat: 48.566265, lng: 18.70283 },
    { lat: 48.566789, lng: 18.702226 },
    { lat: 48.568146, lng: 18.701556 },
    { lat: 48.568862, lng: 18.702156 },
    { lat: 48.569057, lng: 18.702319 },
    { lat: 48.569059, lng: 18.702322 },
    { lat: 48.569062, lng: 18.702324 },
    { lat: 48.569354, lng: 18.702568 },
    { lat: 48.569799, lng: 18.702941 },
    { lat: 48.570449, lng: 18.703668 },
    { lat: 48.5703249, lng: 18.7039249 },
    { lat: 48.571777, lng: 18.702349 },
    { lat: 48.572077, lng: 18.701951 },
    { lat: 48.5693415, lng: 18.6955388 },
    { lat: 48.5693368, lng: 18.6955279 },
    { lat: 48.5689047, lng: 18.6946195 },
    { lat: 48.5676082, lng: 18.6924259 },
    { lat: 48.5656309, lng: 18.6896114 },
    { lat: 48.5593699, lng: 18.6815609 },
    { lat: 48.5593324, lng: 18.6816413 },
    { lat: 48.5587181, lng: 18.6809522 },
    { lat: 48.5576812, lng: 18.6801406 },
    { lat: 48.5575017, lng: 18.6801164 },
    { lat: 48.5570528, lng: 18.6804543 },
    { lat: 48.5569599, lng: 18.6804058 },
    { lat: 48.5567833, lng: 18.6800555 },
    { lat: 48.5566574, lng: 18.679473 },
    { lat: 48.5561551, lng: 18.6782003 },
    { lat: 48.5558918, lng: 18.6777884 },
    { lat: 48.5556034, lng: 18.6777119 },
    { lat: 48.555488, lng: 18.6770093 },
    { lat: 48.5553967, lng: 18.6768388 },
    { lat: 48.5539032, lng: 18.6776813 },
    { lat: 48.5535832, lng: 18.6777917 },
    { lat: 48.5526034, lng: 18.6773506 },
    { lat: 48.5518629, lng: 18.6766648 },
    { lat: 48.5510716, lng: 18.6755562 },
    { lat: 48.5501828, lng: 18.6745685 },
    { lat: 48.5493855, lng: 18.6731581 },
    { lat: 48.5487024, lng: 18.672511 },
    { lat: 48.5485922, lng: 18.6723482 },
    { lat: 48.5471983, lng: 18.6692776 },
    { lat: 48.5468063, lng: 18.6687586 },
    { lat: 48.5462806, lng: 18.6664034 },
    { lat: 48.5462768, lng: 18.6660369 },
    { lat: 48.54725, lng: 18.6654451 },
    { lat: 48.5476325, lng: 18.6651287 },
    { lat: 48.5478636, lng: 18.6650668 },
    { lat: 48.5482103, lng: 18.6647801 },
    { lat: 48.5482665, lng: 18.6645864 },
    { lat: 48.5487258, lng: 18.6640051 },
    { lat: 48.548936, lng: 18.6636331 },
    { lat: 48.549105, lng: 18.6640518 },
    { lat: 48.5491055, lng: 18.6643127 },
    { lat: 48.5494865, lng: 18.6643529 },
    { lat: 48.5495355, lng: 18.664296 },
    { lat: 48.5503843, lng: 18.6641842 },
    { lat: 48.5504759, lng: 18.6635344 },
    { lat: 48.5506975, lng: 18.6629557 },
    { lat: 48.5507829, lng: 18.6625918 },
    { lat: 48.5508168, lng: 18.6617119 },
    { lat: 48.5507004, lng: 18.6607624 },
    { lat: 48.5507234, lng: 18.6602847 },
    { lat: 48.5506711, lng: 18.6583449 },
    { lat: 48.550471, lng: 18.6552914 },
    { lat: 48.5502882, lng: 18.6545109 },
    { lat: 48.5503117, lng: 18.6543632 },
    { lat: 48.5506634, lng: 18.6539818 },
    { lat: 48.5507939, lng: 18.6537 },
    { lat: 48.5511212, lng: 18.6522137 },
    { lat: 48.5512519, lng: 18.6519621 },
    { lat: 48.5513589, lng: 18.651917 },
    { lat: 48.5518213, lng: 18.6522105 },
    { lat: 48.5519709, lng: 18.6522222 },
    { lat: 48.5520624, lng: 18.6521847 },
    { lat: 48.5523899, lng: 18.651625 },
    { lat: 48.5526142, lng: 18.6514966 },
    { lat: 48.5532347, lng: 18.6513326 },
    { lat: 48.5536311, lng: 18.6513199 },
    { lat: 48.5540444, lng: 18.6513938 },
    { lat: 48.5542713, lng: 18.6515213 },
    { lat: 48.5548936, lng: 18.6521059 },
    { lat: 48.5550676, lng: 18.6521651 },
    { lat: 48.5551044, lng: 18.650822 },
    { lat: 48.5550737, lng: 18.6504619 },
    { lat: 48.5548467, lng: 18.6496981 },
    { lat: 48.5546071, lng: 18.6493745 },
    { lat: 48.5541191, lng: 18.6489225 },
    { lat: 48.5532378, lng: 18.64822 },
    { lat: 48.5525079, lng: 18.6474005 },
    { lat: 48.5522348, lng: 18.6470195 },
    { lat: 48.5521982, lng: 18.646779 },
    { lat: 48.5522213, lng: 18.6465181 },
    { lat: 48.5522119, lng: 18.6465103 },
    { lat: 48.5516829, lng: 18.6453986 },
    { lat: 48.5503545, lng: 18.6469943 },
    { lat: 48.5494601, lng: 18.6483772 },
    { lat: 48.5489144, lng: 18.6488873 },
    { lat: 48.5481585, lng: 18.6493429 },
    { lat: 48.5467279, lng: 18.6496295 },
    { lat: 48.5461041, lng: 18.6498771 },
    { lat: 48.5451892, lng: 18.6499392 },
    { lat: 48.5447476, lng: 18.6498229 },
    { lat: 48.5438284, lng: 18.6498207 },
    { lat: 48.542249, lng: 18.6500855 },
    { lat: 48.5411727, lng: 18.6500808 },
    { lat: 48.5408545, lng: 18.6502865 },
    { lat: 48.5400799, lng: 18.6510668 },
    { lat: 48.538986, lng: 18.6512592 },
    { lat: 48.5386616, lng: 18.6511639 },
    { lat: 48.5381798, lng: 18.6513457 },
    { lat: 48.5379427, lng: 18.6518031 },
    { lat: 48.5379041, lng: 18.6517345 },
    { lat: 48.5374753, lng: 18.6525737 },
    { lat: 48.5372509, lng: 18.6527822 },
    { lat: 48.536942, lng: 18.6523248 },
    { lat: 48.5366012, lng: 18.6521535 },
    { lat: 48.5361383, lng: 18.6521419 },
    { lat: 48.5359504, lng: 18.6521954 },
    { lat: 48.5352998, lng: 18.6521569 },
    { lat: 48.5350529, lng: 18.6528084 },
    { lat: 48.5346881, lng: 18.6515784 },
    { lat: 48.533822, lng: 18.6520242 },
    { lat: 48.5334709, lng: 18.6521015 },
    { lat: 48.5328097, lng: 18.6519486 },
    { lat: 48.5323553, lng: 18.6517232 },
    { lat: 48.5323623, lng: 18.6511363 },
    { lat: 48.532096, lng: 18.6507595 },
    { lat: 48.5314329, lng: 18.6506329 },
    { lat: 48.5308076, lng: 18.6503087 },
    { lat: 48.530691, lng: 18.6508706 },
    { lat: 48.5306689, lng: 18.6512544 },
    { lat: 48.5303933, lng: 18.6520449 },
    { lat: 48.5302655, lng: 18.6520167 },
    { lat: 48.5299708, lng: 18.6524522 },
    { lat: 48.5302626, lng: 18.6526743 },
    { lat: 48.52973, lng: 18.6536783 },
    { lat: 48.5296297, lng: 18.6535542 },
    { lat: 48.529371, lng: 18.6540088 },
    { lat: 48.5289662, lng: 18.6537585 },
    { lat: 48.5288064, lng: 18.6540352 },
    { lat: 48.5286727, lng: 18.6546355 },
    { lat: 48.5284666, lng: 18.6551459 },
    { lat: 48.528576, lng: 18.6563816 },
    { lat: 48.5285156, lng: 18.6567305 },
    { lat: 48.5286389, lng: 18.6576574 },
    { lat: 48.5279157, lng: 18.6585778 },
    { lat: 48.5281342, lng: 18.659055 },
    { lat: 48.5279662, lng: 18.6594953 },
    { lat: 48.527441, lng: 18.6591259 },
    { lat: 48.5263064, lng: 18.6586025 },
    { lat: 48.5260565, lng: 18.6585913 },
    { lat: 48.5260119, lng: 18.6586636 },
    { lat: 48.5260601, lng: 18.6593505 },
    { lat: 48.5258701, lng: 18.6602664 },
    { lat: 48.5259829, lng: 18.6607259 },
    { lat: 48.5256086, lng: 18.6615503 },
    { lat: 48.5249389, lng: 18.6622071 },
    { lat: 48.5243386, lng: 18.6630835 },
    { lat: 48.5241363, lng: 18.6634547 },
    { lat: 48.5242312, lng: 18.6635844 },
    { lat: 48.5241188, lng: 18.6640297 },
    { lat: 48.5228542, lng: 18.6641504 },
    { lat: 48.5222458, lng: 18.6658008 },
    { lat: 48.5221662, lng: 18.6662125 },
    { lat: 48.5220147, lng: 18.6664142 },
    { lat: 48.5219156, lng: 18.6668806 },
    { lat: 48.5206296, lng: 18.6702421 },
    { lat: 48.5203001, lng: 18.6713291 },
    { lat: 48.5196402, lng: 18.6718358 },
    { lat: 48.5195252, lng: 18.6718341 },
    { lat: 48.5197123, lng: 18.6727795 },
    { lat: 48.5194996, lng: 18.6730191 },
    { lat: 48.5187409, lng: 18.6731945 },
    { lat: 48.5181897, lng: 18.673118 },
    { lat: 48.5177614, lng: 18.6740704 },
    { lat: 48.5175711, lng: 18.6742013 },
    { lat: 48.5172191, lng: 18.6738823 },
    { lat: 48.5171193, lng: 18.6744003 },
    { lat: 48.5172218, lng: 18.6749424 },
    { lat: 48.5176764, lng: 18.6764849 },
    { lat: 48.5185456, lng: 18.6763065 },
    { lat: 48.5185456, lng: 18.6763912 },
    { lat: 48.5188344, lng: 18.6764138 },
    { lat: 48.5190971, lng: 18.6765392 },
    { lat: 48.5194566, lng: 18.6769765 },
    { lat: 48.5202787, lng: 18.6776894 },
    { lat: 48.5210947, lng: 18.6782717 },
    { lat: 48.5215491, lng: 18.6787498 },
    { lat: 48.5221474, lng: 18.6796678 },
    { lat: 48.5223048, lng: 18.6802055 },
    { lat: 48.5223728, lng: 18.6812034 },
    { lat: 48.5226639, lng: 18.6819444 },
    { lat: 48.5232254, lng: 18.6829939 },
    { lat: 48.523466, lng: 18.683825 },
    { lat: 48.5235135, lng: 18.6844621 },
    { lat: 48.5224121, lng: 18.6863478 },
    { lat: 48.5217301, lng: 18.6873437 },
    { lat: 48.5213655, lng: 18.6879949 },
    { lat: 48.5212957, lng: 18.6886085 },
    { lat: 48.5213456, lng: 18.6890995 },
    { lat: 48.5217655, lng: 18.6896723 },
    { lat: 48.5220967, lng: 18.6900065 },
    { lat: 48.5226937, lng: 18.6909183 },
    { lat: 48.522451, lng: 18.6922231 },
    { lat: 48.5228905, lng: 18.6929341 },
    { lat: 48.522962, lng: 18.6935125 },
    { lat: 48.5232192, lng: 18.693876 },
    { lat: 48.5237375, lng: 18.6938638 },
    { lat: 48.5241021, lng: 18.6940402 },
    { lat: 48.5242859, lng: 18.6940215 },
    { lat: 48.524414, lng: 18.6941138 },
    { lat: 48.5247725, lng: 18.6940918 },
    { lat: 48.5252657, lng: 18.6939496 },
    { lat: 48.5253901, lng: 18.6939989 },
    { lat: 48.5260641, lng: 18.6945841 },
    { lat: 48.5263123, lng: 18.6943317 },
    { lat: 48.5264266, lng: 18.6943567 },
    { lat: 48.5268532, lng: 18.694658 },
    { lat: 48.5271245, lng: 18.6947565 },
    { lat: 48.5273352, lng: 18.6947313 },
    { lat: 48.5279, lng: 18.6948544 },
    { lat: 48.5280469, lng: 18.694773 },
    { lat: 48.5282771, lng: 18.6951358 },
    { lat: 48.5283397, lng: 18.6959295 },
    { lat: 48.5291205, lng: 18.6973498 },
    { lat: 48.5293199, lng: 18.697473 },
    { lat: 48.5297144, lng: 18.6980191 },
    { lat: 48.5300344, lng: 18.6978011 },
    { lat: 48.5301999, lng: 18.6973516 },
    { lat: 48.5301929, lng: 18.6972201 },
    { lat: 48.5307225, lng: 18.6964714 },
    { lat: 48.5311382, lng: 18.696377 },
    { lat: 48.531414, lng: 18.6966265 },
    { lat: 48.532299, lng: 18.696389 },
    { lat: 48.532638, lng: 18.696553 },
    { lat: 48.532818, lng: 18.696432 },
    { lat: 48.533024, lng: 18.696206 },
    { lat: 48.533359, lng: 18.696179 },
    { lat: 48.53341, lng: 18.696028 },
    { lat: 48.533943, lng: 18.696119 },
    { lat: 48.533971, lng: 18.695524 },
    { lat: 48.534089, lng: 18.69553 },
    { lat: 48.534395, lng: 18.695425 },
    { lat: 48.534858, lng: 18.695899 },
    { lat: 48.534881, lng: 18.696286 },
    { lat: 48.535014, lng: 18.696301 },
    { lat: 48.535173, lng: 18.696779 },
    { lat: 48.535317, lng: 18.697193 },
    { lat: 48.53557, lng: 18.697598 },
    { lat: 48.535877, lng: 18.697875 },
    { lat: 48.536126, lng: 18.698002 },
    { lat: 48.536428, lng: 18.698185 },
    { lat: 48.536405, lng: 18.697942 },
    { lat: 48.536391, lng: 18.697806 },
    { lat: 48.536406, lng: 18.697693 },
    { lat: 48.536643, lng: 18.697636 },
    { lat: 48.536806, lng: 18.697709 },
    { lat: 48.536944, lng: 18.697796 },
    { lat: 48.537094, lng: 18.698062 },
    { lat: 48.53716, lng: 18.698258 },
    { lat: 48.537181, lng: 18.698592 },
    { lat: 48.537245, lng: 18.698584 },
    { lat: 48.537366, lng: 18.69852 },
    { lat: 48.537547, lng: 18.698042 },
    { lat: 48.538004, lng: 18.698165 },
    { lat: 48.538118, lng: 18.698368 },
    { lat: 48.538169, lng: 18.698355 },
    { lat: 48.538191, lng: 18.69843 },
    { lat: 48.538267, lng: 18.698734 },
    { lat: 48.538313, lng: 18.698862 },
    { lat: 48.538386, lng: 18.698988 },
    { lat: 48.538477, lng: 18.699048 },
    { lat: 48.538603, lng: 18.699132 },
    { lat: 48.53892, lng: 18.699352 },
    { lat: 48.539355, lng: 18.699557 },
    { lat: 48.539352, lng: 18.699547 },
    { lat: 48.539638, lng: 18.699644 },
    { lat: 48.539672, lng: 18.699766 },
    { lat: 48.539766, lng: 18.700035 },
    { lat: 48.540027, lng: 18.700342 },
    { lat: 48.540132, lng: 18.700524 },
    { lat: 48.540172, lng: 18.700592 },
    { lat: 48.540329, lng: 18.700696 },
    { lat: 48.540406, lng: 18.700759 },
    { lat: 48.540664, lng: 18.700824 },
    { lat: 48.540858, lng: 18.700835 },
    { lat: 48.541175, lng: 18.700828 },
    { lat: 48.541238, lng: 18.70084 },
    { lat: 48.541292, lng: 18.700857 },
    { lat: 48.541459, lng: 18.700819 },
    { lat: 48.541578, lng: 18.70073 },
    { lat: 48.541652, lng: 18.700602 },
    { lat: 48.541803, lng: 18.700219 },
    { lat: 48.541906, lng: 18.700066 },
    { lat: 48.54209, lng: 18.699566 },
    { lat: 48.542156, lng: 18.699385 },
    { lat: 48.542151, lng: 18.699321 },
    { lat: 48.542083, lng: 18.698456 },
    { lat: 48.542144, lng: 18.698236 },
    { lat: 48.54222, lng: 18.698209 },
    { lat: 48.542266, lng: 18.698537 },
    { lat: 48.542569, lng: 18.698594 },
    { lat: 48.542682, lng: 18.698681 },
    { lat: 48.542843, lng: 18.698573 },
    { lat: 48.543436, lng: 18.698528 },
    { lat: 48.546481, lng: 18.698304 },
    { lat: 48.547606, lng: 18.698222 },
    { lat: 48.548705, lng: 18.698051 },
    { lat: 48.549245, lng: 18.698061 },
    { lat: 48.550181, lng: 18.698517 },
    { lat: 48.551155, lng: 18.699072 },
    { lat: 48.551478, lng: 18.699427 },
    { lat: 48.551808, lng: 18.699346 },
    { lat: 48.552236, lng: 18.699332 },
    { lat: 48.55264, lng: 18.699432 },
    { lat: 48.553204, lng: 18.69985 },
    { lat: 48.55462, lng: 18.700326 },
  ],
  Žarnovica: [
    { lat: 48.4904764, lng: 18.6811143 },
    { lat: 48.489138, lng: 18.6808702 },
    { lat: 48.4888454, lng: 18.6809013 },
    { lat: 48.4887727, lng: 18.6808106 },
    { lat: 48.4884483, lng: 18.6808165 },
    { lat: 48.4880448, lng: 18.6806307 },
    { lat: 48.4879028, lng: 18.6806963 },
    { lat: 48.4874195, lng: 18.6806226 },
    { lat: 48.4874328, lng: 18.6806314 },
    { lat: 48.487321, lng: 18.680637 },
    { lat: 48.487136, lng: 18.6810776 },
    { lat: 48.4868954, lng: 18.681052 },
    { lat: 48.4866991, lng: 18.6808159 },
    { lat: 48.4850425, lng: 18.6818102 },
    { lat: 48.4844747, lng: 18.6818651 },
    { lat: 48.4840405, lng: 18.681485 },
    { lat: 48.4838019, lng: 18.680692 },
    { lat: 48.4833291, lng: 18.6797855 },
    { lat: 48.4831554, lng: 18.6791951 },
    { lat: 48.4832638, lng: 18.6786729 },
    { lat: 48.4830114, lng: 18.6780234 },
    { lat: 48.4828764, lng: 18.6778385 },
    { lat: 48.4823697, lng: 18.6774762 },
    { lat: 48.4819096, lng: 18.6769525 },
    { lat: 48.4816791, lng: 18.6771106 },
    { lat: 48.4813432, lng: 18.677537 },
    { lat: 48.4811536, lng: 18.6778934 },
    { lat: 48.480893, lng: 18.6781629 },
    { lat: 48.4806502, lng: 18.6780946 },
    { lat: 48.4802819, lng: 18.6781694 },
    { lat: 48.4800233, lng: 18.6781186 },
    { lat: 48.4799286, lng: 18.6769428 },
    { lat: 48.4795953, lng: 18.6765325 },
    { lat: 48.4785265, lng: 18.6757812 },
    { lat: 48.4784938, lng: 18.6755719 },
    { lat: 48.4786143, lng: 18.6752487 },
    { lat: 48.4786138, lng: 18.6750116 },
    { lat: 48.4782328, lng: 18.6737103 },
    { lat: 48.4782342, lng: 18.6728104 },
    { lat: 48.4780549, lng: 18.6725613 },
    { lat: 48.4779385, lng: 18.6725533 },
    { lat: 48.4773892, lng: 18.6721127 },
    { lat: 48.4767779, lng: 18.6701977 },
    { lat: 48.4763933, lng: 18.6688307 },
    { lat: 48.4755698, lng: 18.6681771 },
    { lat: 48.4742015, lng: 18.6678577 },
    { lat: 48.473369, lng: 18.6678351 },
    { lat: 48.4727276, lng: 18.6682627 },
    { lat: 48.4723755, lng: 18.6687608 },
    { lat: 48.4720964, lng: 18.6693605 },
    { lat: 48.4716115, lng: 18.6693838 },
    { lat: 48.4710898, lng: 18.6698349 },
    { lat: 48.4709383, lng: 18.6698085 },
    { lat: 48.4706075, lng: 18.6698839 },
    { lat: 48.4702233, lng: 18.6696394 },
    { lat: 48.4696455, lng: 18.6695261 },
    { lat: 48.4692755, lng: 18.6692949 },
    { lat: 48.4686893, lng: 18.6695302 },
    { lat: 48.4680619, lng: 18.6696298 },
    { lat: 48.4676931, lng: 18.669966 },
    { lat: 48.4673412, lng: 18.6700716 },
    { lat: 48.466462, lng: 18.6700817 },
    { lat: 48.4658708, lng: 18.6696992 },
    { lat: 48.4655055, lng: 18.6695783 },
    { lat: 48.4651676, lng: 18.669271 },
    { lat: 48.4645915, lng: 18.6690446 },
    { lat: 48.464516, lng: 18.6688963 },
    { lat: 48.4642508, lng: 18.6688304 },
    { lat: 48.4638763, lng: 18.6684537 },
    { lat: 48.4636056, lng: 18.6685359 },
    { lat: 48.4617702, lng: 18.6685714 },
    { lat: 48.4614295, lng: 18.668735 },
    { lat: 48.4605488, lng: 18.6688227 },
    { lat: 48.4594778, lng: 18.6691268 },
    { lat: 48.4590456, lng: 18.6695211 },
    { lat: 48.4586781, lng: 18.6696097 },
    { lat: 48.4579445, lng: 18.669934 },
    { lat: 48.456784, lng: 18.6706078 },
    { lat: 48.4557326, lng: 18.6711287 },
    { lat: 48.4558597, lng: 18.671335 },
    { lat: 48.4563501, lng: 18.6715594 },
    { lat: 48.4564998, lng: 18.6723095 },
    { lat: 48.4566173, lng: 18.6724643 },
    { lat: 48.4565981, lng: 18.6727595 },
    { lat: 48.4566768, lng: 18.6730061 },
    { lat: 48.4570465, lng: 18.6735653 },
    { lat: 48.4578512, lng: 18.6739752 },
    { lat: 48.4576024, lng: 18.6750994 },
    { lat: 48.4576773, lng: 18.6753419 },
    { lat: 48.4577917, lng: 18.6756855 },
    { lat: 48.457839, lng: 18.676842 },
    { lat: 48.459542, lng: 18.6778509 },
    { lat: 48.4600914, lng: 18.6792206 },
    { lat: 48.4605191, lng: 18.679941 },
    { lat: 48.4613826, lng: 18.6823757 },
    { lat: 48.4620027, lng: 18.6848372 },
    { lat: 48.4623562, lng: 18.6852645 },
    { lat: 48.4626084, lng: 18.6860162 },
    { lat: 48.4626352, lng: 18.6870861 },
    { lat: 48.4625704, lng: 18.6875012 },
    { lat: 48.4629139, lng: 18.6886068 },
    { lat: 48.4628018, lng: 18.6894849 },
    { lat: 48.4629854, lng: 18.6905355 },
    { lat: 48.4630096, lng: 18.6909438 },
    { lat: 48.4639096, lng: 18.6915151 },
    { lat: 48.4640063, lng: 18.691696 },
    { lat: 48.4642324, lng: 18.6919203 },
    { lat: 48.4642682, lng: 18.6920371 },
    { lat: 48.4644425, lng: 18.6921145 },
    { lat: 48.465668, lng: 18.6919361 },
    { lat: 48.4660286, lng: 18.6916349 },
    { lat: 48.466294, lng: 18.6915992 },
    { lat: 48.4667711, lng: 18.691748 },
    { lat: 48.467239, lng: 18.6921044 },
    { lat: 48.4674864, lng: 18.6924928 },
    { lat: 48.4677624, lng: 18.6928013 },
    { lat: 48.4683658, lng: 18.6931388 },
    { lat: 48.468632, lng: 18.6933526 },
    { lat: 48.4695495, lng: 18.6942912 },
    { lat: 48.4670586, lng: 18.6978664 },
    { lat: 48.4682241, lng: 18.7009175 },
    { lat: 48.4654041, lng: 18.7048387 },
    { lat: 48.4656712, lng: 18.7060403 },
    { lat: 48.4668884, lng: 18.7094495 },
    { lat: 48.4670253, lng: 18.7099426 },
    { lat: 48.4672137, lng: 18.7109416 },
    { lat: 48.4674414, lng: 18.7114053 },
    { lat: 48.4676408, lng: 18.7116748 },
    { lat: 48.4680814, lng: 18.7120492 },
    { lat: 48.4673729, lng: 18.7135043 },
    { lat: 48.4670978, lng: 18.714395 },
    { lat: 48.4670487, lng: 18.7150843 },
    { lat: 48.4670985, lng: 18.7161643 },
    { lat: 48.4668065, lng: 18.7162962 },
    { lat: 48.466171, lng: 18.7163709 },
    { lat: 48.4658653, lng: 18.7170261 },
    { lat: 48.4658827, lng: 18.7173915 },
    { lat: 48.4656594, lng: 18.7182176 },
    { lat: 48.4655709, lng: 18.7183985 },
    { lat: 48.4654142, lng: 18.7197571 },
    { lat: 48.4654491, lng: 18.7197369 },
    { lat: 48.4661638, lng: 18.7192621 },
    { lat: 48.4664036, lng: 18.7192357 },
    { lat: 48.4664561, lng: 18.7197766 },
    { lat: 48.4666592, lng: 18.7204541 },
    { lat: 48.4671023, lng: 18.720325 },
    { lat: 48.4673985, lng: 18.720762 },
    { lat: 48.4673005, lng: 18.7211676 },
    { lat: 48.4675162, lng: 18.7215567 },
    { lat: 48.4673566, lng: 18.7218624 },
    { lat: 48.4683387, lng: 18.7237226 },
    { lat: 48.4677485, lng: 18.7244394 },
    { lat: 48.4676392, lng: 18.7250553 },
    { lat: 48.467419, lng: 18.7258117 },
    { lat: 48.4674994, lng: 18.7257775 },
    { lat: 48.4675467, lng: 18.7262008 },
    { lat: 48.4678806, lng: 18.7261812 },
    { lat: 48.4685648, lng: 18.7257688 },
    { lat: 48.4689465, lng: 18.7256941 },
    { lat: 48.4692847, lng: 18.7259886 },
    { lat: 48.4694866, lng: 18.7265631 },
    { lat: 48.4697021, lng: 18.7269893 },
    { lat: 48.4702178, lng: 18.7273982 },
    { lat: 48.4704739, lng: 18.7275273 },
    { lat: 48.4701682, lng: 18.7279287 },
    { lat: 48.4699482, lng: 18.7283731 },
    { lat: 48.4700841, lng: 18.7287211 },
    { lat: 48.4697096, lng: 18.7293585 },
    { lat: 48.4695549, lng: 18.729778 },
    { lat: 48.4695167, lng: 18.7297165 },
    { lat: 48.4693422, lng: 18.7300395 },
    { lat: 48.4685997, lng: 18.7306721 },
    { lat: 48.4687771, lng: 18.7310172 },
    { lat: 48.4688012, lng: 18.7315563 },
    { lat: 48.468718, lng: 18.7320056 },
    { lat: 48.4684546, lng: 18.7325052 },
    { lat: 48.4677158, lng: 18.7328836 },
    { lat: 48.4674242, lng: 18.7332118 },
    { lat: 48.4676484, lng: 18.7346093 },
    { lat: 48.4675784, lng: 18.735031 },
    { lat: 48.4672983, lng: 18.7353237 },
    { lat: 48.4671264, lng: 18.7356339 },
    { lat: 48.4661364, lng: 18.7364653 },
    { lat: 48.4660195, lng: 18.7369381 },
    { lat: 48.4661333, lng: 18.7376579 },
    { lat: 48.4663007, lng: 18.7381581 },
    { lat: 48.4663841, lng: 18.7386431 },
    { lat: 48.4666127, lng: 18.7390631 },
    { lat: 48.4674132, lng: 18.7395282 },
    { lat: 48.4677643, lng: 18.7398738 },
    { lat: 48.4682031, lng: 18.7399402 },
    { lat: 48.4686554, lng: 18.7401532 },
    { lat: 48.4691324, lng: 18.7411749 },
    { lat: 48.4693158, lng: 18.7422098 },
    { lat: 48.4695129, lng: 18.7429286 },
    { lat: 48.469946, lng: 18.7434783 },
    { lat: 48.4700381, lng: 18.7441326 },
    { lat: 48.470242, lng: 18.7446174 },
    { lat: 48.47043, lng: 18.7456557 },
    { lat: 48.470394, lng: 18.7464871 },
    { lat: 48.4702121, lng: 18.7476706 },
    { lat: 48.4708246, lng: 18.7474821 },
    { lat: 48.4711869, lng: 18.747551 },
    { lat: 48.4716663, lng: 18.7474281 },
    { lat: 48.4730929, lng: 18.7474669 },
    { lat: 48.4740843, lng: 18.7490694 },
    { lat: 48.4749303, lng: 18.7510099 },
    { lat: 48.4748441, lng: 18.7533248 },
    { lat: 48.4746317, lng: 18.7548487 },
    { lat: 48.474031, lng: 18.7569475 },
    { lat: 48.4738194, lng: 18.7572955 },
    { lat: 48.4738739, lng: 18.7573878 },
    { lat: 48.473656, lng: 18.7580661 },
    { lat: 48.4729252, lng: 18.7593647 },
    { lat: 48.4730669, lng: 18.7596129 },
    { lat: 48.4733644, lng: 18.7599072 },
    { lat: 48.4735374, lng: 18.7608116 },
    { lat: 48.4735045, lng: 18.7608728 },
    { lat: 48.4735718, lng: 18.761015 },
    { lat: 48.4744345, lng: 18.7609293 },
    { lat: 48.4751225, lng: 18.760273 },
    { lat: 48.47514, lng: 18.7603666 },
    { lat: 48.4755653, lng: 18.7604323 },
    { lat: 48.4766187, lng: 18.7602025 },
    { lat: 48.4759358, lng: 18.7625163 },
    { lat: 48.4759299, lng: 18.7637066 },
    { lat: 48.4763624, lng: 18.7644002 },
    { lat: 48.4770523, lng: 18.765078 },
    { lat: 48.4772823, lng: 18.7654195 },
    { lat: 48.4774768, lng: 18.7658981 },
    { lat: 48.4776663, lng: 18.7671824 },
    { lat: 48.4780158, lng: 18.7673511 },
    { lat: 48.478423, lng: 18.7672232 },
    { lat: 48.4787279, lng: 18.7674025 },
    { lat: 48.4790959, lng: 18.7679234 },
    { lat: 48.4797423, lng: 18.7682876 },
    { lat: 48.4805151, lng: 18.7685931 },
    { lat: 48.4809262, lng: 18.7688827 },
    { lat: 48.4807702, lng: 18.7694946 },
    { lat: 48.4807339, lng: 18.7695935 },
    { lat: 48.4804419, lng: 18.7704173 },
    { lat: 48.4803591, lng: 18.7711536 },
    { lat: 48.4803478, lng: 18.7711363 },
    { lat: 48.480033, lng: 18.7715533 },
    { lat: 48.4800474, lng: 18.772255 },
    { lat: 48.4799399, lng: 18.7728703 },
    { lat: 48.4796896, lng: 18.7734032 },
    { lat: 48.4797128, lng: 18.7741198 },
    { lat: 48.4798716, lng: 18.774762 },
    { lat: 48.4797237, lng: 18.7757386 },
    { lat: 48.4794741, lng: 18.7765042 },
    { lat: 48.4794703, lng: 18.7768185 },
    { lat: 48.4796003, lng: 18.7772722 },
    { lat: 48.4798826, lng: 18.7776867 },
    { lat: 48.480838, lng: 18.778353 },
    { lat: 48.480815, lng: 18.777883 },
    { lat: 48.481144, lng: 18.777052 },
    { lat: 48.481353, lng: 18.776462 },
    { lat: 48.481534, lng: 18.775886 },
    { lat: 48.481677, lng: 18.775551 },
    { lat: 48.481749, lng: 18.775387 },
    { lat: 48.481762, lng: 18.775357 },
    { lat: 48.481873, lng: 18.775105 },
    { lat: 48.481967, lng: 18.774888 },
    { lat: 48.482022, lng: 18.774698 },
    { lat: 48.482035, lng: 18.774653 },
    { lat: 48.482076, lng: 18.774507 },
    { lat: 48.48209, lng: 18.774458 },
    { lat: 48.482176, lng: 18.774153 },
    { lat: 48.482194, lng: 18.774093 },
    { lat: 48.482221, lng: 18.773994 },
    { lat: 48.482288, lng: 18.773916 },
    { lat: 48.482345, lng: 18.773762 },
    { lat: 48.482431, lng: 18.773533 },
    { lat: 48.48247, lng: 18.773431 },
    { lat: 48.482481, lng: 18.77341 },
    { lat: 48.482639, lng: 18.773107 },
    { lat: 48.482686, lng: 18.773017 },
    { lat: 48.482716, lng: 18.772986 },
    { lat: 48.482825, lng: 18.772873 },
    { lat: 48.483696, lng: 18.771745 },
    { lat: 48.483993, lng: 18.771358 },
    { lat: 48.483999, lng: 18.771253 },
    { lat: 48.484011, lng: 18.771066 },
    { lat: 48.484106, lng: 18.770798 },
    { lat: 48.48408, lng: 18.770642 },
    { lat: 48.484552, lng: 18.769965 },
    { lat: 48.484543, lng: 18.769787 },
    { lat: 48.48492, lng: 18.769461 },
    { lat: 48.485935, lng: 18.769196 },
    { lat: 48.486309, lng: 18.768923 },
    { lat: 48.486371, lng: 18.768694 },
    { lat: 48.486556, lng: 18.767988 },
    { lat: 48.486628, lng: 18.76781 },
    { lat: 48.486904, lng: 18.767438 },
    { lat: 48.487068, lng: 18.767179 },
    { lat: 48.487461, lng: 18.766451 },
    { lat: 48.487607, lng: 18.765901 },
    { lat: 48.487752, lng: 18.765256 },
    { lat: 48.487872, lng: 18.76494 },
    { lat: 48.487949, lng: 18.764737 },
    { lat: 48.487883, lng: 18.763915 },
    { lat: 48.487884, lng: 18.763451 },
    { lat: 48.487884, lng: 18.763011 },
    { lat: 48.488186, lng: 18.762342 },
    { lat: 48.488623, lng: 18.761363 },
    { lat: 48.488832, lng: 18.760536 },
    { lat: 48.489051, lng: 18.759882 },
    { lat: 48.489312, lng: 18.759457 },
    { lat: 48.489581, lng: 18.759129 },
    { lat: 48.489886, lng: 18.758696 },
    { lat: 48.490017, lng: 18.758085 },
    { lat: 48.490145, lng: 18.75769 },
    { lat: 48.490429, lng: 18.757146 },
    { lat: 48.490723, lng: 18.756455 },
    { lat: 48.490031, lng: 18.756191 },
    { lat: 48.490044, lng: 18.755215 },
    { lat: 48.490302, lng: 18.754551 },
    { lat: 48.490056, lng: 18.752332 },
    { lat: 48.490292, lng: 18.751458 },
    { lat: 48.489892, lng: 18.749131 },
    { lat: 48.489852, lng: 18.74859 },
    { lat: 48.489866, lng: 18.748047 },
    { lat: 48.489928, lng: 18.747485 },
    { lat: 48.4896335, lng: 18.7470692 },
    { lat: 48.489176, lng: 18.746423 },
    { lat: 48.488924, lng: 18.745032 },
    { lat: 48.488698, lng: 18.744171 },
    { lat: 48.488665, lng: 18.743217 },
    { lat: 48.488665, lng: 18.743197 },
    { lat: 48.488624, lng: 18.742192 },
    { lat: 48.488615, lng: 18.742043 },
    { lat: 48.488605, lng: 18.74188 },
    { lat: 48.488544, lng: 18.741658 },
    { lat: 48.488584, lng: 18.74146 },
    { lat: 48.488617, lng: 18.74097 },
    { lat: 48.488619, lng: 18.740859 },
    { lat: 48.488698, lng: 18.740249 },
    { lat: 48.488716, lng: 18.739704 },
    { lat: 48.488924, lng: 18.739027 },
    { lat: 48.489243, lng: 18.73823 },
    { lat: 48.489495, lng: 18.736831 },
    { lat: 48.489517, lng: 18.736501 },
    { lat: 48.489572, lng: 18.736162 },
    { lat: 48.489718, lng: 18.735553 },
    { lat: 48.489764, lng: 18.735404 },
    { lat: 48.490138, lng: 18.734007 },
    { lat: 48.490137, lng: 18.733866 },
    { lat: 48.490083, lng: 18.732315 },
    { lat: 48.490079, lng: 18.73225 },
    { lat: 48.492566, lng: 18.731713 },
    { lat: 48.493245, lng: 18.731663 },
    { lat: 48.494278, lng: 18.73154 },
    { lat: 48.495616, lng: 18.73203 },
    { lat: 48.496588, lng: 18.732732 },
    { lat: 48.49662, lng: 18.73269 },
    { lat: 48.496622, lng: 18.732689 },
    { lat: 48.497061, lng: 18.732127 },
    { lat: 48.497063, lng: 18.732126 },
    { lat: 48.497131, lng: 18.732039 },
    { lat: 48.497171, lng: 18.731913 },
    { lat: 48.497285, lng: 18.731562 },
    { lat: 48.497387, lng: 18.731249 },
    { lat: 48.497424, lng: 18.731131 },
    { lat: 48.49745, lng: 18.731049 },
    { lat: 48.497496, lng: 18.73091 },
    { lat: 48.497561, lng: 18.730711 },
    { lat: 48.500048, lng: 18.731314 },
    { lat: 48.500653, lng: 18.731159 },
    { lat: 48.500813, lng: 18.731098 },
    { lat: 48.501027, lng: 18.731017 },
    { lat: 48.501125, lng: 18.730979 },
    { lat: 48.502119, lng: 18.730678 },
    { lat: 48.504326, lng: 18.730029 },
    { lat: 48.506961, lng: 18.729254 },
    { lat: 48.508437, lng: 18.729395 },
    { lat: 48.509884, lng: 18.729591 },
    { lat: 48.510869, lng: 18.730511 },
    { lat: 48.511312, lng: 18.730325 },
    { lat: 48.511345, lng: 18.730651 },
    { lat: 48.511369, lng: 18.73089 },
    { lat: 48.511298, lng: 18.73178 },
    { lat: 48.5112388, lng: 18.7325256 },
    { lat: 48.511228, lng: 18.732667 },
    { lat: 48.511229, lng: 18.732796 },
    { lat: 48.511714, lng: 18.732981 },
    { lat: 48.512406, lng: 18.733457 },
    { lat: 48.512502, lng: 18.733522 },
    { lat: 48.512633, lng: 18.733613 },
    { lat: 48.512726, lng: 18.733723 },
    { lat: 48.513153, lng: 18.734228 },
    { lat: 48.513637, lng: 18.734999 },
    { lat: 48.514156, lng: 18.736153 },
    { lat: 48.514207, lng: 18.736278 },
    { lat: 48.514483, lng: 18.736967 },
    { lat: 48.514507, lng: 18.737027 },
    { lat: 48.516737, lng: 18.735928 },
    { lat: 48.518042, lng: 18.735472 },
    { lat: 48.518207, lng: 18.735364 },
    { lat: 48.518512, lng: 18.735163 },
    { lat: 48.518582, lng: 18.735117 },
    { lat: 48.518861, lng: 18.734934 },
    { lat: 48.519425, lng: 18.734562 },
    { lat: 48.519468, lng: 18.734516 },
    { lat: 48.52048, lng: 18.733213 },
    { lat: 48.521203, lng: 18.731911 },
    { lat: 48.521316, lng: 18.7316 },
    { lat: 48.521667, lng: 18.730546 },
    { lat: 48.521532, lng: 18.728129 },
    { lat: 48.521766, lng: 18.726977 },
    { lat: 48.5232, lng: 18.725967 },
    { lat: 48.523836, lng: 18.725092 },
    { lat: 48.525932, lng: 18.72357 },
    { lat: 48.526, lng: 18.723448 },
    { lat: 48.526362, lng: 18.72279 },
    { lat: 48.526407, lng: 18.722708 },
    { lat: 48.526436, lng: 18.722656 },
    { lat: 48.526461, lng: 18.722626 },
    { lat: 48.526568, lng: 18.7225 },
    { lat: 48.526705, lng: 18.722338 },
    { lat: 48.526912, lng: 18.722092 },
    { lat: 48.527282, lng: 18.721911 },
    { lat: 48.527416, lng: 18.721845 },
    { lat: 48.527423, lng: 18.721841 },
    { lat: 48.527408, lng: 18.72179 },
    { lat: 48.527427, lng: 18.721779 },
    { lat: 48.527632, lng: 18.721666 },
    { lat: 48.527726, lng: 18.721614 },
    { lat: 48.527744, lng: 18.72159 },
    { lat: 48.528049, lng: 18.721165 },
    { lat: 48.52796, lng: 18.720781 },
    { lat: 48.527913, lng: 18.720579 },
    { lat: 48.52784, lng: 18.720463 },
    { lat: 48.527772, lng: 18.72034 },
    { lat: 48.527769, lng: 18.720334 },
    { lat: 48.527727, lng: 18.720258 },
    { lat: 48.527734, lng: 18.720212 },
    { lat: 48.527757, lng: 18.720057 },
    { lat: 48.527809, lng: 18.719714 },
    { lat: 48.527814, lng: 18.719645 },
    { lat: 48.527815, lng: 18.719637 },
    { lat: 48.527969, lng: 18.719024 },
    { lat: 48.52818, lng: 18.718408 },
    { lat: 48.528491, lng: 18.717501 },
    { lat: 48.528861, lng: 18.715775 },
    { lat: 48.528929, lng: 18.715621 },
    { lat: 48.528912, lng: 18.715568 },
    { lat: 48.529121, lng: 18.715472 },
    { lat: 48.529303, lng: 18.715389 },
    { lat: 48.529466, lng: 18.715184 },
    { lat: 48.529264, lng: 18.714965 },
    { lat: 48.529076, lng: 18.714761 },
    { lat: 48.529136, lng: 18.714406 },
    { lat: 48.52919, lng: 18.71407 },
    { lat: 48.52921, lng: 18.713942 },
    { lat: 48.529277, lng: 18.713523 },
    { lat: 48.529314, lng: 18.71328 },
    { lat: 48.529363, lng: 18.712954 },
    { lat: 48.528864, lng: 18.712026 },
    { lat: 48.528751, lng: 18.711631 },
    { lat: 48.528366, lng: 18.710508 },
    { lat: 48.52836, lng: 18.709125 },
    { lat: 48.528419, lng: 18.70907 },
    { lat: 48.528587, lng: 18.708911 },
    { lat: 48.529313, lng: 18.707842 },
    { lat: 48.529331, lng: 18.707837 },
    { lat: 48.529413, lng: 18.707814 },
    { lat: 48.529802, lng: 18.707331 },
    { lat: 48.530361, lng: 18.706638 },
    { lat: 48.530369, lng: 18.706628 },
    { lat: 48.53061, lng: 18.706327 },
    { lat: 48.530946, lng: 18.705046 },
    { lat: 48.531038, lng: 18.704646 },
    { lat: 48.531181, lng: 18.704019 },
    { lat: 48.531223, lng: 18.703835 },
    { lat: 48.531248, lng: 18.70366 },
    { lat: 48.531544, lng: 18.701551 },
    { lat: 48.53153, lng: 18.700958 },
    { lat: 48.531613, lng: 18.700307 },
    { lat: 48.531829, lng: 18.699736 },
    { lat: 48.531894, lng: 18.699564 },
    { lat: 48.531922, lng: 18.699342 },
    { lat: 48.532077, lng: 18.698101 },
    { lat: 48.532299, lng: 18.696389 },
    { lat: 48.531414, lng: 18.6966265 },
    { lat: 48.5311382, lng: 18.696377 },
    { lat: 48.5307225, lng: 18.6964714 },
    { lat: 48.5301929, lng: 18.6972201 },
    { lat: 48.5301999, lng: 18.6973516 },
    { lat: 48.5300344, lng: 18.6978011 },
    { lat: 48.5297144, lng: 18.6980191 },
    { lat: 48.5293199, lng: 18.697473 },
    { lat: 48.5291205, lng: 18.6973498 },
    { lat: 48.5283397, lng: 18.6959295 },
    { lat: 48.5282771, lng: 18.6951358 },
    { lat: 48.5280469, lng: 18.694773 },
    { lat: 48.5279, lng: 18.6948544 },
    { lat: 48.5273352, lng: 18.6947313 },
    { lat: 48.5271245, lng: 18.6947565 },
    { lat: 48.5268532, lng: 18.694658 },
    { lat: 48.5264266, lng: 18.6943567 },
    { lat: 48.5263123, lng: 18.6943317 },
    { lat: 48.5260641, lng: 18.6945841 },
    { lat: 48.5253901, lng: 18.6939989 },
    { lat: 48.5252657, lng: 18.6939496 },
    { lat: 48.5247725, lng: 18.6940918 },
    { lat: 48.524414, lng: 18.6941138 },
    { lat: 48.5242859, lng: 18.6940215 },
    { lat: 48.5241021, lng: 18.6940402 },
    { lat: 48.5237375, lng: 18.6938638 },
    { lat: 48.5232192, lng: 18.693876 },
    { lat: 48.522962, lng: 18.6935125 },
    { lat: 48.5228905, lng: 18.6929341 },
    { lat: 48.522451, lng: 18.6922231 },
    { lat: 48.5222051, lng: 18.6915271 },
    { lat: 48.5214628, lng: 18.6901519 },
    { lat: 48.5213842, lng: 18.6897992 },
    { lat: 48.5209984, lng: 18.6894396 },
    { lat: 48.5206067, lng: 18.6888403 },
    { lat: 48.5201497, lng: 18.6883286 },
    { lat: 48.5191955, lng: 18.6883936 },
    { lat: 48.5190331, lng: 18.6882461 },
    { lat: 48.518816, lng: 18.6882133 },
    { lat: 48.5184377, lng: 18.6885185 },
    { lat: 48.5177362, lng: 18.6886678 },
    { lat: 48.5176228, lng: 18.6886332 },
    { lat: 48.5169366, lng: 18.6888571 },
    { lat: 48.5157082, lng: 18.6891094 },
    { lat: 48.5153983, lng: 18.6888613 },
    { lat: 48.5152382, lng: 18.6888412 },
    { lat: 48.5146506, lng: 18.6891759 },
    { lat: 48.5139934, lng: 18.6896909 },
    { lat: 48.513435, lng: 18.6902345 },
    { lat: 48.5126276, lng: 18.6905741 },
    { lat: 48.5122184, lng: 18.6904533 },
    { lat: 48.5120369, lng: 18.6904752 },
    { lat: 48.5115516, lng: 18.6907033 },
    { lat: 48.5107807, lng: 18.6918481 },
    { lat: 48.5105714, lng: 18.6922405 },
    { lat: 48.5102703, lng: 18.6925138 },
    { lat: 48.5096898, lng: 18.692583 },
    { lat: 48.5090783, lng: 18.6924763 },
    { lat: 48.5086197, lng: 18.6921459 },
    { lat: 48.5083235, lng: 18.6921529 },
    { lat: 48.507984, lng: 18.6919603 },
    { lat: 48.5074019, lng: 18.6917711 },
    { lat: 48.5070522, lng: 18.6917458 },
    { lat: 48.5055191, lng: 18.6908299 },
    { lat: 48.5047122, lng: 18.6904837 },
    { lat: 48.5040057, lng: 18.6900311 },
    { lat: 48.5032005, lng: 18.6891057 },
    { lat: 48.5029361, lng: 18.6890321 },
    { lat: 48.5026925, lng: 18.6888443 },
    { lat: 48.502437, lng: 18.6884719 },
    { lat: 48.5013184, lng: 18.6884761 },
    { lat: 48.5000457, lng: 18.6886789 },
    { lat: 48.4992696, lng: 18.6888028 },
    { lat: 48.4965876, lng: 18.6896665 },
    { lat: 48.4964834, lng: 18.6898047 },
    { lat: 48.4962508, lng: 18.6899054 },
    { lat: 48.4961328, lng: 18.6900587 },
    { lat: 48.4957864, lng: 18.6897649 },
    { lat: 48.4950538, lng: 18.6888448 },
    { lat: 48.4949836, lng: 18.6885852 },
    { lat: 48.4950119, lng: 18.6882909 },
    { lat: 48.4950846, lng: 18.6881747 },
    { lat: 48.4950227, lng: 18.6878607 },
    { lat: 48.4949075, lng: 18.6878775 },
    { lat: 48.4948975, lng: 18.6880089 },
    { lat: 48.4944321, lng: 18.6880997 },
    { lat: 48.494069, lng: 18.6879126 },
    { lat: 48.4938424, lng: 18.6878913 },
    { lat: 48.4938367, lng: 18.687679 },
    { lat: 48.4936145, lng: 18.6875939 },
    { lat: 48.4932444, lng: 18.6875057 },
    { lat: 48.4931171, lng: 18.6875951 },
    { lat: 48.4926771, lng: 18.6875082 },
    { lat: 48.4922575, lng: 18.6870644 },
    { lat: 48.4922643, lng: 18.6866828 },
    { lat: 48.4919809, lng: 18.6857105 },
    { lat: 48.4917848, lng: 18.6857115 },
    { lat: 48.4918509, lng: 18.6849013 },
    { lat: 48.4919345, lng: 18.6844851 },
    { lat: 48.4918593, lng: 18.6840075 },
    { lat: 48.491836, lng: 18.6832106 },
    { lat: 48.4917612, lng: 18.68251 },
    { lat: 48.4915348, lng: 18.6819685 },
    { lat: 48.4908325, lng: 18.6811931 },
    { lat: 48.4904764, lng: 18.6811143 },
  ],
  RudnonadHronom: [
    { lat: 48.4232412, lng: 18.6690879 },
    { lat: 48.4227324, lng: 18.6707646 },
    { lat: 48.4228023, lng: 18.6710416 },
    { lat: 48.4248312, lng: 18.6736201 },
    { lat: 48.4251915, lng: 18.6739038 },
    { lat: 48.4242017, lng: 18.6761409 },
    { lat: 48.4240554, lng: 18.6766916 },
    { lat: 48.4237642, lng: 18.6771316 },
    { lat: 48.4233663, lng: 18.6767758 },
    { lat: 48.4232597, lng: 18.6767887 },
    { lat: 48.4225531, lng: 18.6774065 },
    { lat: 48.421122, lng: 18.6790488 },
    { lat: 48.4204686, lng: 18.6802005 },
    { lat: 48.4198311, lng: 18.6801108 },
    { lat: 48.4196229, lng: 18.6801818 },
    { lat: 48.417099, lng: 18.6803982 },
    { lat: 48.4160982, lng: 18.6800954 },
    { lat: 48.4153489, lng: 18.6802571 },
    { lat: 48.4147412, lng: 18.6800079 },
    { lat: 48.4142334, lng: 18.6799792 },
    { lat: 48.4138749, lng: 18.6800687 },
    { lat: 48.413211, lng: 18.6798864 },
    { lat: 48.4120678, lng: 18.6790758 },
    { lat: 48.4116424, lng: 18.6788827 },
    { lat: 48.4111155, lng: 18.6788797 },
    { lat: 48.4103201, lng: 18.6790633 },
    { lat: 48.4078232, lng: 18.6787912 },
    { lat: 48.4072798, lng: 18.6788829 },
    { lat: 48.4059633, lng: 18.6789159 },
    { lat: 48.4048402, lng: 18.6785247 },
    { lat: 48.4036605, lng: 18.6808128 },
    { lat: 48.4034536, lng: 18.6814366 },
    { lat: 48.4033102, lng: 18.6820624 },
    { lat: 48.4030732, lng: 18.6824162 },
    { lat: 48.4028166, lng: 18.6824092 },
    { lat: 48.4022186, lng: 18.6827609 },
    { lat: 48.4018817, lng: 18.6831456 },
    { lat: 48.401393, lng: 18.6834783 },
    { lat: 48.4010967, lng: 18.6836078 },
    { lat: 48.4005681, lng: 18.6843103 },
    { lat: 48.4002544, lng: 18.6846428 },
    { lat: 48.3997363, lng: 18.6854753 },
    { lat: 48.3992836, lng: 18.6857422 },
    { lat: 48.3984521, lng: 18.6870647 },
    { lat: 48.3978319, lng: 18.6876543 },
    { lat: 48.3971461, lng: 18.6876729 },
    { lat: 48.3966249, lng: 18.6883023 },
    { lat: 48.3953678, lng: 18.6890706 },
    { lat: 48.3944335, lng: 18.6902822 },
    { lat: 48.39388, lng: 18.6904772 },
    { lat: 48.3943286, lng: 18.6936276 },
    { lat: 48.394348, lng: 18.6943534 },
    { lat: 48.3940261, lng: 18.6951216 },
    { lat: 48.393109, lng: 18.698313 },
    { lat: 48.393759, lng: 18.700229 },
    { lat: 48.394144, lng: 18.700927 },
    { lat: 48.39455, lng: 18.702074 },
    { lat: 48.394831, lng: 18.702709 },
    { lat: 48.39488, lng: 18.702943 },
    { lat: 48.395404, lng: 18.704018 },
    { lat: 48.395799, lng: 18.704817 },
    { lat: 48.396032, lng: 18.705052 },
    { lat: 48.39619, lng: 18.705316 },
    { lat: 48.396323, lng: 18.705844 },
    { lat: 48.396316, lng: 18.706226 },
    { lat: 48.396334, lng: 18.70631 },
    { lat: 48.396346, lng: 18.706364 },
    { lat: 48.396439, lng: 18.706801 },
    { lat: 48.396683, lng: 18.707207 },
    { lat: 48.397032, lng: 18.707553 },
    { lat: 48.39724, lng: 18.707884 },
    { lat: 48.397501, lng: 18.708474 },
    { lat: 48.39794, lng: 18.709775 },
    { lat: 48.398069, lng: 18.710331 },
    { lat: 48.398137, lng: 18.710786 },
    { lat: 48.398444, lng: 18.711806 },
    { lat: 48.398947, lng: 18.712667 },
    { lat: 48.39897, lng: 18.712705 },
    { lat: 48.399803, lng: 18.71413 },
    { lat: 48.399827, lng: 18.714172 },
    { lat: 48.399946, lng: 18.714376 },
    { lat: 48.400664, lng: 18.715256 },
    { lat: 48.401305, lng: 18.715921 },
    { lat: 48.401504, lng: 18.716128 },
    { lat: 48.402024, lng: 18.716658 },
    { lat: 48.402171, lng: 18.716809 },
    { lat: 48.402711, lng: 18.717271 },
    { lat: 48.403114, lng: 18.717399 },
    { lat: 48.403224, lng: 18.717487 },
    { lat: 48.403558, lng: 18.717758 },
    { lat: 48.404094, lng: 18.717688 },
    { lat: 48.404648, lng: 18.717501 },
    { lat: 48.405025, lng: 18.717935 },
    { lat: 48.405438, lng: 18.718549 },
    { lat: 48.406275, lng: 18.719154 },
    { lat: 48.406463, lng: 18.719395 },
    { lat: 48.406869, lng: 18.719589 },
    { lat: 48.407682, lng: 18.720804 },
    { lat: 48.407703, lng: 18.720836 },
    { lat: 48.407726, lng: 18.720871 },
    { lat: 48.407761, lng: 18.720924 },
    { lat: 48.407808, lng: 18.720993 },
    { lat: 48.407813, lng: 18.721001 },
    { lat: 48.407841, lng: 18.721044 },
    { lat: 48.407843, lng: 18.721046 },
    { lat: 48.408068, lng: 18.721382 },
    { lat: 48.409138, lng: 18.723608 },
    { lat: 48.409968, lng: 18.724783 },
    { lat: 48.412036, lng: 18.726738 },
    { lat: 48.412588, lng: 18.727886 },
    { lat: 48.412608, lng: 18.727928 },
    { lat: 48.412825, lng: 18.728271 },
    { lat: 48.412905, lng: 18.728409 },
    { lat: 48.413381, lng: 18.728897 },
    { lat: 48.413894, lng: 18.729044 },
    { lat: 48.413912, lng: 18.72905 },
    { lat: 48.413998, lng: 18.729075 },
    { lat: 48.414014, lng: 18.729077 },
    { lat: 48.414025, lng: 18.729079 },
    { lat: 48.414049, lng: 18.729082 },
    { lat: 48.414059, lng: 18.729084 },
    { lat: 48.414342, lng: 18.729125 },
    { lat: 48.414675, lng: 18.729078 },
    { lat: 48.414925, lng: 18.729273 },
    { lat: 48.415358, lng: 18.729581 },
    { lat: 48.415972, lng: 18.730119 },
    { lat: 48.416777, lng: 18.731439 },
    { lat: 48.416345, lng: 18.731908 },
    { lat: 48.415689, lng: 18.732834 },
    { lat: 48.41538, lng: 18.73306 },
    { lat: 48.414748, lng: 18.733976 },
    { lat: 48.415218, lng: 18.734781 },
    { lat: 48.415545, lng: 18.735112 },
    { lat: 48.415534, lng: 18.736452 },
    { lat: 48.415584, lng: 18.736873 },
    { lat: 48.415533, lng: 18.737419 },
    { lat: 48.415608, lng: 18.737995 },
    { lat: 48.41562, lng: 18.739331 },
    { lat: 48.41556, lng: 18.739612 },
    { lat: 48.415585, lng: 18.739891 },
    { lat: 48.41557, lng: 18.740938 },
    { lat: 48.41549, lng: 18.741446 },
    { lat: 48.415711, lng: 18.742425 },
    { lat: 48.415888, lng: 18.742833 },
    { lat: 48.416048, lng: 18.742988 },
    { lat: 48.416496, lng: 18.744213 },
    { lat: 48.416522, lng: 18.745065 },
    { lat: 48.416562, lng: 18.745443 },
    { lat: 48.4167661, lng: 18.7457293 },
    { lat: 48.4172253, lng: 18.7459207 },
    { lat: 48.4173236, lng: 18.7471593 },
    { lat: 48.4178535, lng: 18.7482209 },
    { lat: 48.4200593, lng: 18.7492823 },
    { lat: 48.4207568, lng: 18.7491406 },
    { lat: 48.4211586, lng: 18.7482326 },
    { lat: 48.4215529, lng: 18.7470762 },
    { lat: 48.4220556, lng: 18.7460076 },
    { lat: 48.4223324, lng: 18.745275 },
    { lat: 48.4225218, lng: 18.7441456 },
    { lat: 48.4226826, lng: 18.7428208 },
    { lat: 48.4233422, lng: 18.742162 },
    { lat: 48.4239752, lng: 18.7411271 },
    { lat: 48.4240455, lng: 18.739856 },
    { lat: 48.4245707, lng: 18.7393411 },
    { lat: 48.4249503, lng: 18.738524 },
    { lat: 48.4254474, lng: 18.7378401 },
    { lat: 48.4256936, lng: 18.7372741 },
    { lat: 48.4260794, lng: 18.7358309 },
    { lat: 48.4260068, lng: 18.734486 },
    { lat: 48.4263521, lng: 18.7338111 },
    { lat: 48.4265893, lng: 18.7328633 },
    { lat: 48.4289957, lng: 18.730735 },
    { lat: 48.4309657, lng: 18.730203 },
    { lat: 48.4314196, lng: 18.7301688 },
    { lat: 48.4320637, lng: 18.7286649 },
    { lat: 48.4324827, lng: 18.7278912 },
    { lat: 48.4328668, lng: 18.7269515 },
    { lat: 48.4332914, lng: 18.7263464 },
    { lat: 48.4336495, lng: 18.72513 },
    { lat: 48.4335807, lng: 18.7237532 },
    { lat: 48.4336067, lng: 18.7223991 },
    { lat: 48.4339118, lng: 18.7215605 },
    { lat: 48.4345318, lng: 18.7209188 },
    { lat: 48.4349451, lng: 18.7202746 },
    { lat: 48.4361105, lng: 18.7195167 },
    { lat: 48.4368242, lng: 18.7192196 },
    { lat: 48.4375395, lng: 18.7186096 },
    { lat: 48.4372018, lng: 18.7175009 },
    { lat: 48.4368274, lng: 18.7167159 },
    { lat: 48.4364966, lng: 18.7162021 },
    { lat: 48.4361269, lng: 18.7154648 },
    { lat: 48.4360495, lng: 18.7147127 },
    { lat: 48.4357801, lng: 18.7141456 },
    { lat: 48.4357315, lng: 18.713867 },
    { lat: 48.4357689, lng: 18.7101908 },
    { lat: 48.4359708, lng: 18.70985 },
    { lat: 48.4362022, lng: 18.709772 },
    { lat: 48.4363735, lng: 18.709262 },
    { lat: 48.4365131, lng: 18.7091936 },
    { lat: 48.4366751, lng: 18.7087227 },
    { lat: 48.4368553, lng: 18.7085252 },
    { lat: 48.4369288, lng: 18.7079349 },
    { lat: 48.4369316, lng: 18.7071024 },
    { lat: 48.4372941, lng: 18.70599 },
    { lat: 48.436917, lng: 18.7048413 },
    { lat: 48.4366886, lng: 18.7038612 },
    { lat: 48.4371693, lng: 18.7017542 },
    { lat: 48.4381416, lng: 18.7008484 },
    { lat: 48.438628, lng: 18.7008302 },
    { lat: 48.4395258, lng: 18.6995025 },
    { lat: 48.439999, lng: 18.6995803 },
    { lat: 48.4405756, lng: 18.699521 },
    { lat: 48.4411806, lng: 18.6992999 },
    { lat: 48.4425603, lng: 18.6984815 },
    { lat: 48.4445488, lng: 18.6970486 },
    { lat: 48.4445254, lng: 18.6967615 },
    { lat: 48.4447978, lng: 18.6960125 },
    { lat: 48.4452104, lng: 18.6951001 },
    { lat: 48.4452905, lng: 18.6945523 },
    { lat: 48.4454258, lng: 18.6942357 },
    { lat: 48.4458355, lng: 18.6937351 },
    { lat: 48.446146, lng: 18.6934464 },
    { lat: 48.4462588, lng: 18.6932139 },
    { lat: 48.4466281, lng: 18.6927391 },
    { lat: 48.4475289, lng: 18.6921631 },
    { lat: 48.4476127, lng: 18.6896196 },
    { lat: 48.4470556, lng: 18.6874294 },
    { lat: 48.4473478, lng: 18.6870945 },
    { lat: 48.4477441, lng: 18.6867631 },
    { lat: 48.448194, lng: 18.6865325 },
    { lat: 48.4484575, lng: 18.6862707 },
    { lat: 48.4487596, lng: 18.6862026 },
    { lat: 48.4488071, lng: 18.6861747 },
    { lat: 48.4498956, lng: 18.6855343 },
    { lat: 48.4505871, lng: 18.6851984 },
    { lat: 48.4511791, lng: 18.6850692 },
    { lat: 48.4513572, lng: 18.6846244 },
    { lat: 48.4518864, lng: 18.6833027 },
    { lat: 48.4521229, lng: 18.6830897 },
    { lat: 48.4529609, lng: 18.6827492 },
    { lat: 48.4535024, lng: 18.6824157 },
    { lat: 48.4545731, lng: 18.682193 },
    { lat: 48.4561667, lng: 18.6810975 },
    { lat: 48.4566957, lng: 18.6805736 },
    { lat: 48.4567983, lng: 18.6797933 },
    { lat: 48.4568036, lng: 18.6788387 },
    { lat: 48.4569766, lng: 18.6783629 },
    { lat: 48.4570162, lng: 18.6776499 },
    { lat: 48.4571656, lng: 18.6771582 },
    { lat: 48.4571211, lng: 18.6766409 },
    { lat: 48.4576773, lng: 18.6753419 },
    { lat: 48.4576024, lng: 18.6750994 },
    { lat: 48.4578512, lng: 18.6739752 },
    { lat: 48.4570465, lng: 18.6735653 },
    { lat: 48.4566768, lng: 18.6730061 },
    { lat: 48.4565981, lng: 18.6727595 },
    { lat: 48.4566173, lng: 18.6724643 },
    { lat: 48.4564998, lng: 18.6723095 },
    { lat: 48.4563501, lng: 18.6715594 },
    { lat: 48.4558597, lng: 18.671335 },
    { lat: 48.4557326, lng: 18.6711287 },
    { lat: 48.4549934, lng: 18.6718999 },
    { lat: 48.4546613, lng: 18.672477 },
    { lat: 48.4544145, lng: 18.6726302 },
    { lat: 48.4540431, lng: 18.6723502 },
    { lat: 48.4538961, lng: 18.6720461 },
    { lat: 48.4535709, lng: 18.6716848 },
    { lat: 48.4532313, lng: 18.6716812 },
    { lat: 48.4527386, lng: 18.6720433 },
    { lat: 48.4523831, lng: 18.6718889 },
    { lat: 48.4517666, lng: 18.6717505 },
    { lat: 48.4506504, lng: 18.6718439 },
    { lat: 48.4491567, lng: 18.6728205 },
    { lat: 48.4492004, lng: 18.6729865 },
    { lat: 48.4491355, lng: 18.6732047 },
    { lat: 48.4489589, lng: 18.6732783 },
    { lat: 48.4488042, lng: 18.6735884 },
    { lat: 48.4484976, lng: 18.6737382 },
    { lat: 48.4480848, lng: 18.6744973 },
    { lat: 48.4476531, lng: 18.6751511 },
    { lat: 48.4476501, lng: 18.6755405 },
    { lat: 48.4473745, lng: 18.6759306 },
    { lat: 48.4470511, lng: 18.6761236 },
    { lat: 48.447013, lng: 18.6763165 },
    { lat: 48.4467452, lng: 18.676479 },
    { lat: 48.4466576, lng: 18.6768423 },
    { lat: 48.4459332, lng: 18.6779302 },
    { lat: 48.4457497, lng: 18.6780453 },
    { lat: 48.4456352, lng: 18.6781151 },
    { lat: 48.4447346, lng: 18.6784615 },
    { lat: 48.4445142, lng: 18.6786228 },
    { lat: 48.4438732, lng: 18.6793932 },
    { lat: 48.4428383, lng: 18.6798652 },
    { lat: 48.4422006, lng: 18.6805315 },
    { lat: 48.4421676, lng: 18.680566 },
    { lat: 48.441698, lng: 18.6807862 },
    { lat: 48.4414302, lng: 18.6808432 },
    { lat: 48.4400082, lng: 18.6804488 },
    { lat: 48.4386946, lng: 18.6804956 },
    { lat: 48.435799, lng: 18.6812468 },
    { lat: 48.4355912, lng: 18.6808607 },
    { lat: 48.4354772, lng: 18.6802295 },
    { lat: 48.4351527, lng: 18.6795496 },
    { lat: 48.4349569, lng: 18.6791108 },
    { lat: 48.4345991, lng: 18.6781121 },
    { lat: 48.4343696, lng: 18.6774903 },
    { lat: 48.434006, lng: 18.6769373 },
    { lat: 48.4337964, lng: 18.6764793 },
    { lat: 48.4334804, lng: 18.6759678 },
    { lat: 48.4332053, lng: 18.6764529 },
    { lat: 48.4329832, lng: 18.6765824 },
    { lat: 48.4325906, lng: 18.6759911 },
    { lat: 48.4319701, lng: 18.675421 },
    { lat: 48.4313715, lng: 18.6751533 },
    { lat: 48.4308407, lng: 18.6746561 },
    { lat: 48.4297882, lng: 18.6732232 },
    { lat: 48.4292781, lng: 18.6721028 },
    { lat: 48.4288842, lng: 18.6715106 },
    { lat: 48.4280016, lng: 18.6707202 },
    { lat: 48.4265106, lng: 18.6701765 },
    { lat: 48.4257716, lng: 18.6696891 },
    { lat: 48.4244643, lng: 18.6685746 },
    { lat: 48.424046, lng: 18.6684066 },
    { lat: 48.4238973, lng: 18.6684114 },
    { lat: 48.4234663, lng: 18.6681789 },
    { lat: 48.4232753, lng: 18.6689489 },
    { lat: 48.4232412, lng: 18.6690879 },
  ],
  Píla: [
    { lat: 48.575273, lng: 18.597696 },
    { lat: 48.5744305, lng: 18.5971692 },
    { lat: 48.573774, lng: 18.5970869 },
    { lat: 48.5735141, lng: 18.5969543 },
    { lat: 48.5727812, lng: 18.5962916 },
    { lat: 48.5705878, lng: 18.5967146 },
    { lat: 48.5701498, lng: 18.5965837 },
    { lat: 48.5693681, lng: 18.5957717 },
    { lat: 48.5695629, lng: 18.5950785 },
    { lat: 48.5696902, lng: 18.5944101 },
    { lat: 48.569425, lng: 18.5920342 },
    { lat: 48.5692061, lng: 18.5915547 },
    { lat: 48.56896, lng: 18.591179 },
    { lat: 48.5686457, lng: 18.5908744 },
    { lat: 48.5685199, lng: 18.5906135 },
    { lat: 48.5668302, lng: 18.5895716 },
    { lat: 48.5666465, lng: 18.5892001 },
    { lat: 48.5664065, lng: 18.5890419 },
    { lat: 48.5661641, lng: 18.5889789 },
    { lat: 48.5659516, lng: 18.5890524 },
    { lat: 48.56584, lng: 18.5891829 },
    { lat: 48.5652663, lng: 18.5891906 },
    { lat: 48.5646522, lng: 18.5890761 },
    { lat: 48.564119, lng: 18.5887508 },
    { lat: 48.5637754, lng: 18.588964 },
    { lat: 48.5636924, lng: 18.5889119 },
    { lat: 48.5636639, lng: 18.5887178 },
    { lat: 48.5633154, lng: 18.5887376 },
    { lat: 48.5631147, lng: 18.588575 },
    { lat: 48.5630072, lng: 18.5884024 },
    { lat: 48.5627733, lng: 18.5882902 },
    { lat: 48.5625624, lng: 18.588063 },
    { lat: 48.5622378, lng: 18.5876518 },
    { lat: 48.5621981, lng: 18.587479 },
    { lat: 48.5616957, lng: 18.5872012 },
    { lat: 48.5611778, lng: 18.5870962 },
    { lat: 48.5609656, lng: 18.5866748 },
    { lat: 48.5607572, lng: 18.5864106 },
    { lat: 48.5605163, lng: 18.586337 },
    { lat: 48.5603968, lng: 18.5860901 },
    { lat: 48.5601789, lng: 18.585878 },
    { lat: 48.5599338, lng: 18.5859769 },
    { lat: 48.5596823, lng: 18.5859769 },
    { lat: 48.559495, lng: 18.5857668 },
    { lat: 48.5587828, lng: 18.5858004 },
    { lat: 48.5587126, lng: 18.5860655 },
    { lat: 48.558345, lng: 18.5863176 },
    { lat: 48.5579465, lng: 18.5862079 },
    { lat: 48.5573331, lng: 18.586604 },
    { lat: 48.557189, lng: 18.5866096 },
    { lat: 48.5568662, lng: 18.586397 },
    { lat: 48.5565429, lng: 18.5867362 },
    { lat: 48.5559855, lng: 18.5876669 },
    { lat: 48.5558026, lng: 18.587751 },
    { lat: 48.5554636, lng: 18.5876756 },
    { lat: 48.5553793, lng: 18.5875547 },
    { lat: 48.5550018, lng: 18.5876453 },
    { lat: 48.5549161, lng: 18.5879176 },
    { lat: 48.5545532, lng: 18.5877992 },
    { lat: 48.5542849, lng: 18.5878422 },
    { lat: 48.5540379, lng: 18.587737 },
    { lat: 48.5536684, lng: 18.5878712 },
    { lat: 48.5535161, lng: 18.5882217 },
    { lat: 48.5534498, lng: 18.5882719 },
    { lat: 48.5519366, lng: 18.5884006 },
    { lat: 48.5517239, lng: 18.5885977 },
    { lat: 48.5516641, lng: 18.5885766 },
    { lat: 48.5515415, lng: 18.5883546 },
    { lat: 48.5509994, lng: 18.5882177 },
    { lat: 48.5507861, lng: 18.5883426 },
    { lat: 48.5502305, lng: 18.5884306 },
    { lat: 48.5499425, lng: 18.5883629 },
    { lat: 48.5496824, lng: 18.5883917 },
    { lat: 48.5486282, lng: 18.5886906 },
    { lat: 48.5482105, lng: 18.588746 },
    { lat: 48.5476792, lng: 18.5886829 },
    { lat: 48.5473674, lng: 18.5881991 },
    { lat: 48.5472161, lng: 18.5881935 },
    { lat: 48.5470102, lng: 18.5879141 },
    { lat: 48.546702, lng: 18.5878884 },
    { lat: 48.5465779, lng: 18.5874092 },
    { lat: 48.5463627, lng: 18.5872891 },
    { lat: 48.5460115, lng: 18.5873202 },
    { lat: 48.5457608, lng: 18.5871923 },
    { lat: 48.5455754, lng: 18.5872241 },
    { lat: 48.5452076, lng: 18.5870989 },
    { lat: 48.544915, lng: 18.5872154 },
    { lat: 48.5446736, lng: 18.5874231 },
    { lat: 48.5445219, lng: 18.5873937 },
    { lat: 48.5442718, lng: 18.5874576 },
    { lat: 48.543949, lng: 18.5877683 },
    { lat: 48.5437216, lng: 18.5876283 },
    { lat: 48.5433197, lng: 18.5877497 },
    { lat: 48.5426993, lng: 18.5874577 },
    { lat: 48.5421973, lng: 18.5875191 },
    { lat: 48.5419025, lng: 18.5874875 },
    { lat: 48.541534, lng: 18.5873531 },
    { lat: 48.5413484, lng: 18.5873827 },
    { lat: 48.5412331, lng: 18.58723 },
    { lat: 48.5410819, lng: 18.5872497 },
    { lat: 48.5408225, lng: 18.5869823 },
    { lat: 48.540676, lng: 18.5869213 },
    { lat: 48.5405037, lng: 18.5866994 },
    { lat: 48.5402455, lng: 18.5867416 },
    { lat: 48.540133, lng: 18.5866024 },
    { lat: 48.5398173, lng: 18.586438 },
    { lat: 48.5396429, lng: 18.5862395 },
    { lat: 48.5394366, lng: 18.5861564 },
    { lat: 48.5390577, lng: 18.5863657 },
    { lat: 48.5388755, lng: 18.5865839 },
    { lat: 48.5386443, lng: 18.5867316 },
    { lat: 48.538612, lng: 18.5868192 },
    { lat: 48.5386291, lng: 18.5871339 },
    { lat: 48.5384265, lng: 18.5871204 },
    { lat: 48.5382277, lng: 18.5872193 },
    { lat: 48.5380171, lng: 18.5871858 },
    { lat: 48.5375648, lng: 18.5873739 },
    { lat: 48.5372666, lng: 18.5873238 },
    { lat: 48.5370478, lng: 18.587177 },
    { lat: 48.5368396, lng: 18.5869386 },
    { lat: 48.536203, lng: 18.5871978 },
    { lat: 48.5360991, lng: 18.5875683 },
    { lat: 48.5356679, lng: 18.5876669 },
    { lat: 48.5355741, lng: 18.5875596 },
    { lat: 48.5354507, lng: 18.5875503 },
    { lat: 48.5352399, lng: 18.5879072 },
    { lat: 48.5347987, lng: 18.5881313 },
    { lat: 48.5344945, lng: 18.5881389 },
    { lat: 48.5341793, lng: 18.588294 },
    { lat: 48.5339645, lng: 18.5885991 },
    { lat: 48.5337491, lng: 18.5887627 },
    { lat: 48.5333986, lng: 18.5886464 },
    { lat: 48.5332036, lng: 18.5886668 },
    { lat: 48.5331258, lng: 18.5885842 },
    { lat: 48.5328305, lng: 18.5884864 },
    { lat: 48.5327065, lng: 18.5881757 },
    { lat: 48.5325918, lng: 18.5883699 },
    { lat: 48.5322895, lng: 18.5886399 },
    { lat: 48.5320469, lng: 18.5885762 },
    { lat: 48.5319578, lng: 18.5887811 },
    { lat: 48.5318978, lng: 18.5887778 },
    { lat: 48.5317335, lng: 18.5887251 },
    { lat: 48.531648, lng: 18.5884989 },
    { lat: 48.5315126, lng: 18.588517 },
    { lat: 48.5312376, lng: 18.5887505 },
    { lat: 48.5311357, lng: 18.5887624 },
    { lat: 48.5311, lng: 18.5889582 },
    { lat: 48.5309484, lng: 18.5890733 },
    { lat: 48.530749, lng: 18.5891409 },
    { lat: 48.5306294, lng: 18.5889535 },
    { lat: 48.5301487, lng: 18.5890547 },
    { lat: 48.5301063, lng: 18.5891279 },
    { lat: 48.5297794, lng: 18.5891695 },
    { lat: 48.5297051, lng: 18.5891943 },
    { lat: 48.5295518, lng: 18.5895281 },
    { lat: 48.5293361, lng: 18.5895034 },
    { lat: 48.5292277, lng: 18.5892517 },
    { lat: 48.5288859, lng: 18.5891261 },
    { lat: 48.5286827, lng: 18.5894362 },
    { lat: 48.5287118, lng: 18.5896148 },
    { lat: 48.528457, lng: 18.5896803 },
    { lat: 48.52835, lng: 18.5897899 },
    { lat: 48.5281461, lng: 18.5890436 },
    { lat: 48.5279999, lng: 18.5889925 },
    { lat: 48.5272352, lng: 18.5891166 },
    { lat: 48.526936, lng: 18.5890263 },
    { lat: 48.5264043, lng: 18.5885844 },
    { lat: 48.5258891, lng: 18.5883806 },
    { lat: 48.525628, lng: 18.5883921 },
    { lat: 48.5249233, lng: 18.5878985 },
    { lat: 48.5246768, lng: 18.5878235 },
    { lat: 48.5241276, lng: 18.5879904 },
    { lat: 48.5240535, lng: 18.5878938 },
    { lat: 48.5237417, lng: 18.5864318 },
    { lat: 48.52448, lng: 18.5838515 },
    { lat: 48.5237332, lng: 18.5834753 },
    { lat: 48.5236499, lng: 18.5830653 },
    { lat: 48.5234841, lng: 18.5828765 },
    { lat: 48.5233607, lng: 18.5829095 },
    { lat: 48.523297, lng: 18.5836251 },
    { lat: 48.5232678, lng: 18.5836102 },
    { lat: 48.5229196, lng: 18.5831791 },
    { lat: 48.5228119, lng: 18.5826498 },
    { lat: 48.5223017, lng: 18.5825814 },
    { lat: 48.5221757, lng: 18.5826691 },
    { lat: 48.5219671, lng: 18.5825059 },
    { lat: 48.5215778, lng: 18.581944 },
    { lat: 48.5216001, lng: 18.5813766 },
    { lat: 48.5214391, lng: 18.5808855 },
    { lat: 48.521046, lng: 18.580359 },
    { lat: 48.520941, lng: 18.5803367 },
    { lat: 48.5210196, lng: 18.5797702 },
    { lat: 48.5209591, lng: 18.5797357 },
    { lat: 48.5210566, lng: 18.5791195 },
    { lat: 48.5207341, lng: 18.5789148 },
    { lat: 48.5201732, lng: 18.5787872 },
    { lat: 48.5200696, lng: 18.5785392 },
    { lat: 48.519759, lng: 18.5784512 },
    { lat: 48.5191682, lng: 18.5778331 },
    { lat: 48.5189217, lng: 18.5774291 },
    { lat: 48.5188628, lng: 18.5772599 },
    { lat: 48.5186812, lng: 18.5756816 },
    { lat: 48.5185757, lng: 18.5754456 },
    { lat: 48.5183516, lng: 18.574477 },
    { lat: 48.5182515, lng: 18.5744011 },
    { lat: 48.5167466, lng: 18.5751226 },
    { lat: 48.5165356, lng: 18.5748074 },
    { lat: 48.5163189, lng: 18.5742873 },
    { lat: 48.5162136, lng: 18.574158 },
    { lat: 48.5154285, lng: 18.5741986 },
    { lat: 48.5147321, lng: 18.574398 },
    { lat: 48.5139727, lng: 18.5742455 },
    { lat: 48.5137405, lng: 18.5743745 },
    { lat: 48.5132344, lng: 18.5732189 },
    { lat: 48.512563, lng: 18.5726749 },
    { lat: 48.5125568, lng: 18.5727087 },
    { lat: 48.5125426, lng: 18.5729417 },
    { lat: 48.5120976, lng: 18.5743025 },
    { lat: 48.5110871, lng: 18.5772652 },
    { lat: 48.5106588, lng: 18.5783066 },
    { lat: 48.510522, lng: 18.5790064 },
    { lat: 48.5103767, lng: 18.5792559 },
    { lat: 48.5097219, lng: 18.5799538 },
    { lat: 48.5091351, lng: 18.5807148 },
    { lat: 48.5079235, lng: 18.5804803 },
    { lat: 48.5068022, lng: 18.5803751 },
    { lat: 48.5063081, lng: 18.5802301 },
    { lat: 48.5060857, lng: 18.5806004 },
    { lat: 48.5056946, lng: 18.5804188 },
    { lat: 48.5045618, lng: 18.5808276 },
    { lat: 48.5043602, lng: 18.5809983 },
    { lat: 48.5035679, lng: 18.5819221 },
    { lat: 48.5023343, lng: 18.5829046 },
    { lat: 48.5017809, lng: 18.5835941 },
    { lat: 48.5012288, lng: 18.5838335 },
    { lat: 48.5007947, lng: 18.5842078 },
    { lat: 48.500638, lng: 18.5841694 },
    { lat: 48.499933, lng: 18.5842747 },
    { lat: 48.4998107, lng: 18.5844079 },
    { lat: 48.4988407, lng: 18.5850341 },
    { lat: 48.4976747, lng: 18.5861983 },
    { lat: 48.4973263, lng: 18.5864709 },
    { lat: 48.4970616, lng: 18.5871622 },
    { lat: 48.495885, lng: 18.5878841 },
    { lat: 48.4955675, lng: 18.5880231 },
    { lat: 48.4950212, lng: 18.5884915 },
    { lat: 48.4947313, lng: 18.5902967 },
    { lat: 48.4945338, lng: 18.590625 },
    { lat: 48.494307, lng: 18.5908307 },
    { lat: 48.4933351, lng: 18.591484 },
    { lat: 48.4934104, lng: 18.5919125 },
    { lat: 48.4934199, lng: 18.5925223 },
    { lat: 48.4930825, lng: 18.593021 },
    { lat: 48.4918248, lng: 18.5952402 },
    { lat: 48.4913904, lng: 18.5964859 },
    { lat: 48.4913613, lng: 18.5968639 },
    { lat: 48.4911425, lng: 18.597278 },
    { lat: 48.4911249, lng: 18.5975159 },
    { lat: 48.4911769, lng: 18.5977247 },
    { lat: 48.4908611, lng: 18.598225 },
    { lat: 48.4908905, lng: 18.5984522 },
    { lat: 48.4910321, lng: 18.5987356 },
    { lat: 48.490975, lng: 18.5988902 },
    { lat: 48.4909935, lng: 18.5991887 },
    { lat: 48.4909384, lng: 18.5995664 },
    { lat: 48.49081, lng: 18.5998403 },
    { lat: 48.4908932, lng: 18.5998962 },
    { lat: 48.4909938, lng: 18.60019 },
    { lat: 48.4905818, lng: 18.6007864 },
    { lat: 48.4910192, lng: 18.6021145 },
    { lat: 48.491226, lng: 18.6034133 },
    { lat: 48.4915116, lng: 18.6038719 },
    { lat: 48.4907609, lng: 18.6050478 },
    { lat: 48.4897349, lng: 18.6063425 },
    { lat: 48.4892065, lng: 18.6078761 },
    { lat: 48.4891422, lng: 18.6087303 },
    { lat: 48.4895534, lng: 18.6092175 },
    { lat: 48.4896452, lng: 18.6095593 },
    { lat: 48.490038, lng: 18.6102301 },
    { lat: 48.4903077, lng: 18.6099823 },
    { lat: 48.4903624, lng: 18.6094963 },
    { lat: 48.4906487, lng: 18.6090352 },
    { lat: 48.4909999, lng: 18.6088513 },
    { lat: 48.4912694, lng: 18.6084776 },
    { lat: 48.4931959, lng: 18.6072503 },
    { lat: 48.493294, lng: 18.6072905 },
    { lat: 48.4937193, lng: 18.6069791 },
    { lat: 48.4936627, lng: 18.6067677 },
    { lat: 48.4943382, lng: 18.6062435 },
    { lat: 48.4948461, lng: 18.606731 },
    { lat: 48.4952752, lng: 18.6075923 },
    { lat: 48.4954446, lng: 18.6084726 },
    { lat: 48.4962798, lng: 18.6091252 },
    { lat: 48.4971648, lng: 18.6095156 },
    { lat: 48.4973349, lng: 18.6098638 },
    { lat: 48.4977777, lng: 18.6105414 },
    { lat: 48.4979208, lng: 18.6111032 },
    { lat: 48.4979296, lng: 18.6113038 },
    { lat: 48.498066, lng: 18.6115971 },
    { lat: 48.4983466, lng: 18.6127989 },
    { lat: 48.498277, lng: 18.6131812 },
    { lat: 48.4981362, lng: 18.6134951 },
    { lat: 48.4981933, lng: 18.6138206 },
    { lat: 48.498205, lng: 18.6142466 },
    { lat: 48.4979566, lng: 18.6154912 },
    { lat: 48.4979839, lng: 18.6160799 },
    { lat: 48.4981647, lng: 18.61663 },
    { lat: 48.4981447, lng: 18.6169899 },
    { lat: 48.4982204, lng: 18.6181168 },
    { lat: 48.4981731, lng: 18.6195978 },
    { lat: 48.4982288, lng: 18.6197716 },
    { lat: 48.4982528, lng: 18.6201538 },
    { lat: 48.4984103, lng: 18.62069 },
    { lat: 48.4986753, lng: 18.6212718 },
    { lat: 48.4987888, lng: 18.6216615 },
    { lat: 48.4993615, lng: 18.622721 },
    { lat: 48.4996352, lng: 18.6239237 },
    { lat: 48.499686, lng: 18.6253009 },
    { lat: 48.4997968, lng: 18.6256185 },
    { lat: 48.5000299, lng: 18.6258652 },
    { lat: 48.5005645, lng: 18.6262579 },
    { lat: 48.5008593, lng: 18.6268506 },
    { lat: 48.5012032, lng: 18.6270604 },
    { lat: 48.5012451, lng: 18.6273554 },
    { lat: 48.5011587, lng: 18.6276989 },
    { lat: 48.5013033, lng: 18.627742 },
    { lat: 48.5020378, lng: 18.6271635 },
    { lat: 48.5023764, lng: 18.6259136 },
    { lat: 48.5023994, lng: 18.6257415 },
    { lat: 48.5024412, lng: 18.6255258 },
    { lat: 48.5027393, lng: 18.6248442 },
    { lat: 48.5033164, lng: 18.6230617 },
    { lat: 48.5049224, lng: 18.6231675 },
    { lat: 48.5055092, lng: 18.623005 },
    { lat: 48.5058681, lng: 18.6229895 },
    { lat: 48.5061941, lng: 18.6230905 },
    { lat: 48.5064272, lng: 18.6234596 },
    { lat: 48.5064627, lng: 18.6241819 },
    { lat: 48.5067477, lng: 18.6243384 },
    { lat: 48.5071324, lng: 18.6246954 },
    { lat: 48.5080819, lng: 18.62482 },
    { lat: 48.5085109, lng: 18.6246374 },
    { lat: 48.5089505, lng: 18.6247984 },
    { lat: 48.5093133, lng: 18.6251873 },
    { lat: 48.5096708, lng: 18.6252741 },
    { lat: 48.5099895, lng: 18.6254724 },
    { lat: 48.5104269, lng: 18.6256365 },
    { lat: 48.5105784, lng: 18.6257912 },
    { lat: 48.5107846, lng: 18.6260769 },
    { lat: 48.5109162, lng: 18.6264265 },
    { lat: 48.5109647, lng: 18.6269876 },
    { lat: 48.5111363, lng: 18.6274735 },
    { lat: 48.5111122, lng: 18.628322 },
    { lat: 48.5114505, lng: 18.6286534 },
    { lat: 48.5119552, lng: 18.6280147 },
    { lat: 48.51203, lng: 18.6286914 },
    { lat: 48.511735, lng: 18.6294314 },
    { lat: 48.5116212, lng: 18.6302073 },
    { lat: 48.5114417, lng: 18.6308098 },
    { lat: 48.5121734, lng: 18.6310163 },
    { lat: 48.5121821, lng: 18.6311775 },
    { lat: 48.5127618, lng: 18.6314257 },
    { lat: 48.513594, lng: 18.6320088 },
    { lat: 48.5139237, lng: 18.631553 },
    { lat: 48.5141833, lng: 18.6310157 },
    { lat: 48.5146824, lng: 18.6307523 },
    { lat: 48.5150073, lng: 18.6292693 },
    { lat: 48.515189, lng: 18.6292503 },
    { lat: 48.5152508, lng: 18.6288059 },
    { lat: 48.5151837, lng: 18.6276074 },
    { lat: 48.5149727, lng: 18.6273144 },
    { lat: 48.5144838, lng: 18.6276197 },
    { lat: 48.5143761, lng: 18.6272924 },
    { lat: 48.5146246, lng: 18.6268835 },
    { lat: 48.5149416, lng: 18.6261962 },
    { lat: 48.5148106, lng: 18.6258366 },
    { lat: 48.5148229, lng: 18.6255825 },
    { lat: 48.5149691, lng: 18.6250729 },
    { lat: 48.5149038, lng: 18.6248056 },
    { lat: 48.5152681, lng: 18.6248065 },
    { lat: 48.5155798, lng: 18.6248982 },
    { lat: 48.5159626, lng: 18.625929 },
    { lat: 48.5164544, lng: 18.6259072 },
    { lat: 48.5162716, lng: 18.626534 },
    { lat: 48.5165451, lng: 18.6265219 },
    { lat: 48.5164346, lng: 18.6266681 },
    { lat: 48.5163916, lng: 18.6276282 },
    { lat: 48.5165323, lng: 18.6278533 },
    { lat: 48.5168068, lng: 18.6279645 },
    { lat: 48.5173295, lng: 18.6276811 },
    { lat: 48.5182193, lng: 18.6274047 },
    { lat: 48.5184193, lng: 18.6278968 },
    { lat: 48.5184861, lng: 18.6281928 },
    { lat: 48.5189684, lng: 18.6281196 },
    { lat: 48.5191335, lng: 18.6279215 },
    { lat: 48.5195386, lng: 18.6277328 },
    { lat: 48.5200862, lng: 18.6272862 },
    { lat: 48.5198037, lng: 18.6267956 },
    { lat: 48.520251, lng: 18.6260144 },
    { lat: 48.5201727, lng: 18.6256935 },
    { lat: 48.5204551, lng: 18.6254074 },
    { lat: 48.5203682, lng: 18.6251995 },
    { lat: 48.5211641, lng: 18.6243351 },
    { lat: 48.5214131, lng: 18.6241619 },
    { lat: 48.5214066, lng: 18.6240642 },
    { lat: 48.521581, lng: 18.6238873 },
    { lat: 48.5223203, lng: 18.6247547 },
    { lat: 48.5224652, lng: 18.6247021 },
    { lat: 48.522469, lng: 18.624623 },
    { lat: 48.5223272, lng: 18.6245268 },
    { lat: 48.5222717, lng: 18.624376 },
    { lat: 48.5223933, lng: 18.6240804 },
    { lat: 48.5225632, lng: 18.6227972 },
    { lat: 48.5227284, lng: 18.6228282 },
    { lat: 48.5229687, lng: 18.6224771 },
    { lat: 48.5228715, lng: 18.6222218 },
    { lat: 48.5231905, lng: 18.6220287 },
    { lat: 48.5235224, lng: 18.622056 },
    { lat: 48.5241061, lng: 18.6223375 },
    { lat: 48.5244335, lng: 18.6223654 },
    { lat: 48.5249128, lng: 18.6226544 },
    { lat: 48.5253939, lng: 18.623147 },
    { lat: 48.525631, lng: 18.6232347 },
    { lat: 48.5259655, lng: 18.6232663 },
    { lat: 48.5265839, lng: 18.6228967 },
    { lat: 48.5267363, lng: 18.6228603 },
    { lat: 48.5270735, lng: 18.623442 },
    { lat: 48.5273524, lng: 18.6233948 },
    { lat: 48.5273377, lng: 18.6231001 },
    { lat: 48.5272182, lng: 18.6227529 },
    { lat: 48.5271442, lng: 18.622444 },
    { lat: 48.5272205, lng: 18.622116 },
    { lat: 48.5273828, lng: 18.6215681 },
    { lat: 48.5276304, lng: 18.6210584 },
    { lat: 48.527731, lng: 18.6205401 },
    { lat: 48.528066, lng: 18.6200393 },
    { lat: 48.5284889, lng: 18.6195765 },
    { lat: 48.5284939, lng: 18.6198581 },
    { lat: 48.5282291, lng: 18.6206175 },
    { lat: 48.5283985, lng: 18.6207032 },
    { lat: 48.5283885, lng: 18.6211047 },
    { lat: 48.5285562, lng: 18.6212646 },
    { lat: 48.5288034, lng: 18.6213521 },
    { lat: 48.5289784, lng: 18.621541 },
    { lat: 48.5291066, lng: 18.6219103 },
    { lat: 48.529207, lng: 18.6220372 },
    { lat: 48.5298751, lng: 18.6216678 },
    { lat: 48.5303876, lng: 18.6233676 },
    { lat: 48.5323863, lng: 18.6216539 },
    { lat: 48.5328992, lng: 18.6212961 },
    { lat: 48.5336612, lng: 18.620492 },
    { lat: 48.5339413, lng: 18.6203965 },
    { lat: 48.5343605, lng: 18.6206609 },
    { lat: 48.5345285, lng: 18.6203452 },
    { lat: 48.5348547, lng: 18.6203288 },
    { lat: 48.5354171, lng: 18.6200289 },
    { lat: 48.5358623, lng: 18.6199178 },
    { lat: 48.5358345, lng: 18.620029 },
    { lat: 48.535712, lng: 18.6200412 },
    { lat: 48.5355352, lng: 18.6204433 },
    { lat: 48.5355838, lng: 18.6206299 },
    { lat: 48.5354407, lng: 18.6208638 },
    { lat: 48.5353218, lng: 18.621856 },
    { lat: 48.536616, lng: 18.6224727 },
    { lat: 48.5374333, lng: 18.6217558 },
    { lat: 48.5383726, lng: 18.6203744 },
    { lat: 48.5392019, lng: 18.6200614 },
    { lat: 48.5400289, lng: 18.6199234 },
    { lat: 48.5407872, lng: 18.6198668 },
    { lat: 48.5413121, lng: 18.6198976 },
    { lat: 48.5416651, lng: 18.6202928 },
    { lat: 48.542255, lng: 18.6200216 },
    { lat: 48.5439811, lng: 18.6197987 },
    { lat: 48.5453608, lng: 18.6203057 },
    { lat: 48.5457188, lng: 18.6205832 },
    { lat: 48.5462504, lng: 18.6207381 },
    { lat: 48.5464112, lng: 18.6212901 },
    { lat: 48.5460232, lng: 18.6230694 },
    { lat: 48.5460346, lng: 18.6232973 },
    { lat: 48.5462078, lng: 18.624041 },
    { lat: 48.5465819, lng: 18.6243686 },
    { lat: 48.546785, lng: 18.6250404 },
    { lat: 48.5468124, lng: 18.6257145 },
    { lat: 48.546934, lng: 18.6263378 },
    { lat: 48.5469247, lng: 18.6266259 },
    { lat: 48.5467997, lng: 18.6266302 },
    { lat: 48.5467832, lng: 18.6273505 },
    { lat: 48.5468743, lng: 18.6282855 },
    { lat: 48.5475104, lng: 18.6280942 },
    { lat: 48.5482183, lng: 18.6274597 },
    { lat: 48.5484335, lng: 18.6271411 },
    { lat: 48.5489471, lng: 18.6275663 },
    { lat: 48.5493735, lng: 18.6274005 },
    { lat: 48.5494278, lng: 18.6267556 },
    { lat: 48.549619, lng: 18.6270629 },
    { lat: 48.5497444, lng: 18.6270867 },
    { lat: 48.5500373, lng: 18.6268903 },
    { lat: 48.5504802, lng: 18.6269258 },
    { lat: 48.5506284, lng: 18.6270177 },
    { lat: 48.5515884, lng: 18.6272487 },
    { lat: 48.5514018, lng: 18.6266995 },
    { lat: 48.5511829, lng: 18.6248982 },
    { lat: 48.5531668, lng: 18.6255866 },
    { lat: 48.5543833, lng: 18.6269789 },
    { lat: 48.5541047, lng: 18.6281893 },
    { lat: 48.553946, lng: 18.6286783 },
    { lat: 48.5539498, lng: 18.629125 },
    { lat: 48.5542081, lng: 18.6295014 },
    { lat: 48.5538784, lng: 18.6311289 },
    { lat: 48.5545681, lng: 18.6317249 },
    { lat: 48.5546769, lng: 18.6320039 },
    { lat: 48.5550504, lng: 18.6324905 },
    { lat: 48.5555772, lng: 18.6324296 },
    { lat: 48.5558559, lng: 18.6321146 },
    { lat: 48.5559956, lng: 18.6314438 },
    { lat: 48.5563696, lng: 18.6319158 },
    { lat: 48.556539, lng: 18.632527 },
    { lat: 48.5572452, lng: 18.6337028 },
    { lat: 48.559024, lng: 18.6331382 },
    { lat: 48.5609942, lng: 18.6321135 },
    { lat: 48.5622096, lng: 18.6323127 },
    { lat: 48.5626508, lng: 18.6322327 },
    { lat: 48.5636122, lng: 18.631779 },
    { lat: 48.5644785, lng: 18.6314651 },
    { lat: 48.5646896, lng: 18.6314485 },
    { lat: 48.5643713, lng: 18.6295649 },
    { lat: 48.5642548, lng: 18.6286138 },
    { lat: 48.5642741, lng: 18.6259222 },
    { lat: 48.5652452, lng: 18.6258161 },
    { lat: 48.5658369, lng: 18.6256607 },
    { lat: 48.5662421, lng: 18.6262944 },
    { lat: 48.5665508, lng: 18.6259746 },
    { lat: 48.5667454, lng: 18.6249818 },
    { lat: 48.5668259, lng: 18.6238626 },
    { lat: 48.5670837, lng: 18.6230383 },
    { lat: 48.5673899, lng: 18.6227562 },
    { lat: 48.5678895, lng: 18.6215208 },
    { lat: 48.5681578, lng: 18.6203053 },
    { lat: 48.5684126, lng: 18.6197406 },
    { lat: 48.5689277, lng: 18.6187746 },
    { lat: 48.5692768, lng: 18.6183857 },
    { lat: 48.5695139, lng: 18.6179748 },
    { lat: 48.5697379, lng: 18.6172782 },
    { lat: 48.5698174, lng: 18.6165758 },
    { lat: 48.5697258, lng: 18.6153405 },
    { lat: 48.5702393, lng: 18.6148665 },
    { lat: 48.5706009, lng: 18.6141708 },
    { lat: 48.5710432, lng: 18.6137967 },
    { lat: 48.5716071, lng: 18.6131488 },
    { lat: 48.5721395, lng: 18.612337 },
    { lat: 48.5726813, lng: 18.6113038 },
    { lat: 48.5728469, lng: 18.6111521 },
    { lat: 48.5729391, lng: 18.6105638 },
    { lat: 48.5729369, lng: 18.610334 },
    { lat: 48.5730467, lng: 18.6100488 },
    { lat: 48.5737111, lng: 18.609403 },
    { lat: 48.5741444, lng: 18.6085947 },
    { lat: 48.5748022, lng: 18.607954 },
    { lat: 48.5752663, lng: 18.6078781 },
    { lat: 48.5759824, lng: 18.6071259 },
    { lat: 48.5765599, lng: 18.6064587 },
    { lat: 48.5780582, lng: 18.6042973 },
    { lat: 48.5782096, lng: 18.6038687 },
    { lat: 48.5785365, lng: 18.6033402 },
    { lat: 48.5786515, lng: 18.6029449 },
    { lat: 48.5789898, lng: 18.6011084 },
    { lat: 48.5791454, lng: 18.6007744 },
    { lat: 48.579237, lng: 18.600033 },
    { lat: 48.578826, lng: 18.599895 },
    { lat: 48.578642, lng: 18.599833 },
    { lat: 48.578358, lng: 18.599738 },
    { lat: 48.576966, lng: 18.598934 },
    { lat: 48.576837, lng: 18.598814 },
    { lat: 48.576688, lng: 18.598674 },
    { lat: 48.576199, lng: 18.598218 },
    { lat: 48.575273, lng: 18.597696 },
  ],
  Voznica: [
    { lat: 48.4576773, lng: 18.6753419 },
    { lat: 48.4571211, lng: 18.6766409 },
    { lat: 48.4571656, lng: 18.6771582 },
    { lat: 48.4570162, lng: 18.6776499 },
    { lat: 48.4569766, lng: 18.6783629 },
    { lat: 48.4568036, lng: 18.6788387 },
    { lat: 48.4567983, lng: 18.6797933 },
    { lat: 48.4566957, lng: 18.6805736 },
    { lat: 48.4561667, lng: 18.6810975 },
    { lat: 48.4545731, lng: 18.682193 },
    { lat: 48.4535024, lng: 18.6824157 },
    { lat: 48.4529609, lng: 18.6827492 },
    { lat: 48.4521229, lng: 18.6830897 },
    { lat: 48.4518864, lng: 18.6833027 },
    { lat: 48.4513572, lng: 18.6846244 },
    { lat: 48.4511791, lng: 18.6850692 },
    { lat: 48.4505871, lng: 18.6851984 },
    { lat: 48.4498956, lng: 18.6855343 },
    { lat: 48.4488071, lng: 18.6861747 },
    { lat: 48.4487596, lng: 18.6862026 },
    { lat: 48.4484575, lng: 18.6862707 },
    { lat: 48.448194, lng: 18.6865325 },
    { lat: 48.4477441, lng: 18.6867631 },
    { lat: 48.4473478, lng: 18.6870945 },
    { lat: 48.4470556, lng: 18.6874294 },
    { lat: 48.4476127, lng: 18.6896196 },
    { lat: 48.4475289, lng: 18.6921631 },
    { lat: 48.4466281, lng: 18.6927391 },
    { lat: 48.4462588, lng: 18.6932139 },
    { lat: 48.446146, lng: 18.6934464 },
    { lat: 48.4458355, lng: 18.6937351 },
    { lat: 48.4454258, lng: 18.6942357 },
    { lat: 48.4452905, lng: 18.6945523 },
    { lat: 48.4452104, lng: 18.6951001 },
    { lat: 48.4447978, lng: 18.6960125 },
    { lat: 48.4445254, lng: 18.6967615 },
    { lat: 48.4445488, lng: 18.6970486 },
    { lat: 48.4425603, lng: 18.6984815 },
    { lat: 48.4411806, lng: 18.6992999 },
    { lat: 48.4405756, lng: 18.699521 },
    { lat: 48.439999, lng: 18.6995803 },
    { lat: 48.4395258, lng: 18.6995025 },
    { lat: 48.438628, lng: 18.7008302 },
    { lat: 48.4381416, lng: 18.7008484 },
    { lat: 48.4371693, lng: 18.7017542 },
    { lat: 48.4366886, lng: 18.7038612 },
    { lat: 48.436917, lng: 18.7048413 },
    { lat: 48.4372941, lng: 18.70599 },
    { lat: 48.4369316, lng: 18.7071024 },
    { lat: 48.4369288, lng: 18.7079349 },
    { lat: 48.4368553, lng: 18.7085252 },
    { lat: 48.4366751, lng: 18.7087227 },
    { lat: 48.4365131, lng: 18.7091936 },
    { lat: 48.4363735, lng: 18.709262 },
    { lat: 48.4362022, lng: 18.709772 },
    { lat: 48.4359708, lng: 18.70985 },
    { lat: 48.4357689, lng: 18.7101908 },
    { lat: 48.4357315, lng: 18.713867 },
    { lat: 48.4357801, lng: 18.7141456 },
    { lat: 48.4360495, lng: 18.7147127 },
    { lat: 48.4361269, lng: 18.7154648 },
    { lat: 48.4364966, lng: 18.7162021 },
    { lat: 48.4368274, lng: 18.7167159 },
    { lat: 48.4372018, lng: 18.7175009 },
    { lat: 48.4375395, lng: 18.7186096 },
    { lat: 48.4368242, lng: 18.7192196 },
    { lat: 48.4361105, lng: 18.7195167 },
    { lat: 48.4349451, lng: 18.7202746 },
    { lat: 48.4345318, lng: 18.7209188 },
    { lat: 48.4339118, lng: 18.7215605 },
    { lat: 48.4336067, lng: 18.7223991 },
    { lat: 48.4335807, lng: 18.7237532 },
    { lat: 48.4336495, lng: 18.72513 },
    { lat: 48.4332914, lng: 18.7263464 },
    { lat: 48.4328668, lng: 18.7269515 },
    { lat: 48.4324827, lng: 18.7278912 },
    { lat: 48.4320637, lng: 18.7286649 },
    { lat: 48.4314196, lng: 18.7301688 },
    { lat: 48.4309657, lng: 18.730203 },
    { lat: 48.4289957, lng: 18.730735 },
    { lat: 48.4265893, lng: 18.7328633 },
    { lat: 48.4263521, lng: 18.7338111 },
    { lat: 48.4260068, lng: 18.734486 },
    { lat: 48.4260794, lng: 18.7358309 },
    { lat: 48.4256936, lng: 18.7372741 },
    { lat: 48.4254474, lng: 18.7378401 },
    { lat: 48.4249503, lng: 18.738524 },
    { lat: 48.4245707, lng: 18.7393411 },
    { lat: 48.4240455, lng: 18.739856 },
    { lat: 48.4239752, lng: 18.7411271 },
    { lat: 48.4233422, lng: 18.742162 },
    { lat: 48.4226826, lng: 18.7428208 },
    { lat: 48.4225218, lng: 18.7441456 },
    { lat: 48.4223324, lng: 18.745275 },
    { lat: 48.4220556, lng: 18.7460076 },
    { lat: 48.4215529, lng: 18.7470762 },
    { lat: 48.4211586, lng: 18.7482326 },
    { lat: 48.4207568, lng: 18.7491406 },
    { lat: 48.4200593, lng: 18.7492823 },
    { lat: 48.4178535, lng: 18.7482209 },
    { lat: 48.4173236, lng: 18.7471593 },
    { lat: 48.4172253, lng: 18.7459207 },
    { lat: 48.4167661, lng: 18.7457293 },
    { lat: 48.416562, lng: 18.745443 },
    { lat: 48.416456, lng: 18.74595 },
    { lat: 48.416356, lng: 18.746329 },
    { lat: 48.416012, lng: 18.746978 },
    { lat: 48.415898, lng: 18.747197 },
    { lat: 48.415767, lng: 18.747375 },
    { lat: 48.415572, lng: 18.747642 },
    { lat: 48.415557, lng: 18.747663 },
    { lat: 48.415261, lng: 18.747904 },
    { lat: 48.414395, lng: 18.748097 },
    { lat: 48.414057, lng: 18.748301 },
    { lat: 48.414006, lng: 18.748331 },
    { lat: 48.413546, lng: 18.748891 },
    { lat: 48.413391, lng: 18.749079 },
    { lat: 48.412741, lng: 18.749619 },
    { lat: 48.41198, lng: 18.750289 },
    { lat: 48.411569, lng: 18.750526 },
    { lat: 48.411162, lng: 18.750644 },
    { lat: 48.41208, lng: 18.752433 },
    { lat: 48.412115, lng: 18.752582 },
    { lat: 48.412131, lng: 18.752644 },
    { lat: 48.412361, lng: 18.753615 },
    { lat: 48.412395, lng: 18.753756 },
    { lat: 48.412568, lng: 18.754162 },
    { lat: 48.412843, lng: 18.754716 },
    { lat: 48.413521, lng: 18.755192 },
    { lat: 48.41409, lng: 18.755216 },
    { lat: 48.414146, lng: 18.755251 },
    { lat: 48.414255, lng: 18.755212 },
    { lat: 48.414416, lng: 18.755216 },
    { lat: 48.414602, lng: 18.755235 },
    { lat: 48.415095, lng: 18.755768 },
    { lat: 48.415129, lng: 18.755805 },
    { lat: 48.41528, lng: 18.755969 },
    { lat: 48.415383, lng: 18.756058 },
    { lat: 48.415411, lng: 18.756083 },
    { lat: 48.415451, lng: 18.756132 },
    { lat: 48.415522, lng: 18.756221 },
    { lat: 48.4159, lng: 18.756501 },
    { lat: 48.416012, lng: 18.756529 },
    { lat: 48.416249, lng: 18.756745 },
    { lat: 48.416481, lng: 18.757048 },
    { lat: 48.416532, lng: 18.757165 },
    { lat: 48.416711, lng: 18.757408 },
    { lat: 48.416926, lng: 18.757737 },
    { lat: 48.417181, lng: 18.758029 },
    { lat: 48.417304, lng: 18.758122 },
    { lat: 48.417728, lng: 18.758042 },
    { lat: 48.417828, lng: 18.758063 },
    { lat: 48.418218, lng: 18.75863 },
    { lat: 48.418345, lng: 18.758854 },
    { lat: 48.41838, lng: 18.758916 },
    { lat: 48.418383, lng: 18.75894 },
    { lat: 48.418531, lng: 18.759075 },
    { lat: 48.418663, lng: 18.759086 },
    { lat: 48.418721, lng: 18.759024 },
    { lat: 48.419545, lng: 18.75932 },
    { lat: 48.419791, lng: 18.759401 },
    { lat: 48.420051, lng: 18.759465 },
    { lat: 48.420375, lng: 18.759599 },
    { lat: 48.420553, lng: 18.759536 },
    { lat: 48.420686, lng: 18.759631 },
    { lat: 48.420754, lng: 18.759733 },
    { lat: 48.420941, lng: 18.759545 },
    { lat: 48.421045, lng: 18.75957 },
    { lat: 48.421388, lng: 18.75948 },
    { lat: 48.421474, lng: 18.75949 },
    { lat: 48.421537, lng: 18.759419 },
    { lat: 48.421652, lng: 18.759505 },
    { lat: 48.421755, lng: 18.759519 },
    { lat: 48.422067, lng: 18.759608 },
    { lat: 48.42231, lng: 18.759643 },
    { lat: 48.422405, lng: 18.759694 },
    { lat: 48.422518, lng: 18.759691 },
    { lat: 48.422584, lng: 18.75969 },
    { lat: 48.422837, lng: 18.759793 },
    { lat: 48.423053, lng: 18.759815 },
    { lat: 48.423603, lng: 18.76012 },
    { lat: 48.4237, lng: 18.760249 },
    { lat: 48.423952, lng: 18.760283 },
    { lat: 48.42425, lng: 18.760293 },
    { lat: 48.42434, lng: 18.760424 },
    { lat: 48.424419, lng: 18.76044 },
    { lat: 48.424521, lng: 18.760301 },
    { lat: 48.424594, lng: 18.760325 },
    { lat: 48.424822, lng: 18.760275 },
    { lat: 48.424974, lng: 18.760413 },
    { lat: 48.425092, lng: 18.760311 },
    { lat: 48.425185, lng: 18.760355 },
    { lat: 48.425299, lng: 18.760371 },
    { lat: 48.425432, lng: 18.760473 },
    { lat: 48.425667, lng: 18.760591 },
    { lat: 48.425816, lng: 18.760646 },
    { lat: 48.426097, lng: 18.760781 },
    { lat: 48.426221, lng: 18.760874 },
    { lat: 48.426374, lng: 18.760928 },
    { lat: 48.426449, lng: 18.76098 },
    { lat: 48.426603, lng: 18.761008 },
    { lat: 48.426753, lng: 18.760855 },
    { lat: 48.426828, lng: 18.760753 },
    { lat: 48.426899, lng: 18.760692 },
    { lat: 48.427, lng: 18.760473 },
    { lat: 48.427193, lng: 18.760544 },
    { lat: 48.427396, lng: 18.760562 },
    { lat: 48.427554, lng: 18.760504 },
    { lat: 48.427576, lng: 18.760515 },
    { lat: 48.427833, lng: 18.76042 },
    { lat: 48.42807, lng: 18.760403 },
    { lat: 48.42813, lng: 18.760389 },
    { lat: 48.428317, lng: 18.76047 },
    { lat: 48.428377, lng: 18.760559 },
    { lat: 48.428502, lng: 18.760609 },
    { lat: 48.428596, lng: 18.760696 },
    { lat: 48.428789, lng: 18.76075 },
    { lat: 48.428857, lng: 18.760823 },
    { lat: 48.428942, lng: 18.760832 },
    { lat: 48.42914, lng: 18.76107 },
    { lat: 48.429259, lng: 18.761042 },
    { lat: 48.42947, lng: 18.760889 },
    { lat: 48.429665, lng: 18.760878 },
    { lat: 48.429795, lng: 18.760907 },
    { lat: 48.429881, lng: 18.760926 },
    { lat: 48.430019, lng: 18.760953 },
    { lat: 48.430213, lng: 18.760983 },
    { lat: 48.430311, lng: 18.760972 },
    { lat: 48.430527, lng: 18.760988 },
    { lat: 48.430721, lng: 18.761081 },
    { lat: 48.430896, lng: 18.761151 },
    { lat: 48.431109, lng: 18.761179 },
    { lat: 48.431364, lng: 18.761191 },
    { lat: 48.431497, lng: 18.761321 },
    { lat: 48.431579, lng: 18.761352 },
    { lat: 48.431636, lng: 18.761515 },
    { lat: 48.431758, lng: 18.76173 },
    { lat: 48.431923, lng: 18.762019 },
    { lat: 48.431939, lng: 18.762094 },
    { lat: 48.431983, lng: 18.762327 },
    { lat: 48.432047, lng: 18.762374 },
    { lat: 48.43208, lng: 18.762399 },
    { lat: 48.432097, lng: 18.762415 },
    { lat: 48.432219, lng: 18.762533 },
    { lat: 48.432283, lng: 18.762562 },
    { lat: 48.432822, lng: 18.763226 },
    { lat: 48.432969, lng: 18.763281 },
    { lat: 48.433208, lng: 18.763468 },
    { lat: 48.433293, lng: 18.763545 },
    { lat: 48.433405, lng: 18.763589 },
    { lat: 48.433534, lng: 18.763666 },
    { lat: 48.4337, lng: 18.763837 },
    { lat: 48.433878, lng: 18.763916 },
    { lat: 48.434103, lng: 18.763885 },
    { lat: 48.434294, lng: 18.76395 },
    { lat: 48.434531, lng: 18.763916 },
    { lat: 48.434876, lng: 18.763946 },
    { lat: 48.435027, lng: 18.763918 },
    { lat: 48.435083, lng: 18.763938 },
    { lat: 48.43514, lng: 18.763959 },
    { lat: 48.435143, lng: 18.76396 },
    { lat: 48.435198, lng: 18.763934 },
    { lat: 48.435203, lng: 18.763934 },
    { lat: 48.435374, lng: 18.763913 },
    { lat: 48.435693, lng: 18.76382 },
    { lat: 48.435796, lng: 18.76385 },
    { lat: 48.435984, lng: 18.763699 },
    { lat: 48.436099, lng: 18.763746 },
    { lat: 48.436354, lng: 18.763984 },
    { lat: 48.436498, lng: 18.764199 },
    { lat: 48.43668, lng: 18.764383 },
    { lat: 48.43683, lng: 18.764371 },
    { lat: 48.436872, lng: 18.764417 },
    { lat: 48.436926, lng: 18.764512 },
    { lat: 48.437012, lng: 18.764737 },
    { lat: 48.437, lng: 18.764823 },
    { lat: 48.437033, lng: 18.764864 },
    { lat: 48.437088, lng: 18.764938 },
    { lat: 48.437158, lng: 18.765077 },
    { lat: 48.437217, lng: 18.765201 },
    { lat: 48.43725, lng: 18.765355 },
    { lat: 48.437258, lng: 18.765471 },
    { lat: 48.437429, lng: 18.765958 },
    { lat: 48.437579, lng: 18.766211 },
    { lat: 48.437654, lng: 18.766362 },
    { lat: 48.437717, lng: 18.766487 },
    { lat: 48.437873, lng: 18.766904 },
    { lat: 48.4381034, lng: 18.7668806 },
    { lat: 48.4381806, lng: 18.7668727 },
    { lat: 48.4382886, lng: 18.7667192 },
    { lat: 48.4386543, lng: 18.7664454 },
    { lat: 48.4389654, lng: 18.7657185 },
    { lat: 48.4392239, lng: 18.7647396 },
    { lat: 48.439361, lng: 18.7642204 },
    { lat: 48.4393657, lng: 18.763924 },
    { lat: 48.439559, lng: 18.7635713 },
    { lat: 48.4396652, lng: 18.7629342 },
    { lat: 48.4395576, lng: 18.7626691 },
    { lat: 48.4394474, lng: 18.7623976 },
    { lat: 48.4394163, lng: 18.7623208 },
    { lat: 48.4397779, lng: 18.7617248 },
    { lat: 48.439881, lng: 18.7615549 },
    { lat: 48.439885, lng: 18.7610407 },
    { lat: 48.4398856, lng: 18.7609626 },
    { lat: 48.4399027, lng: 18.7609371 },
    { lat: 48.4400832, lng: 18.7606673 },
    { lat: 48.4400906, lng: 18.7604462 },
    { lat: 48.440936, lng: 18.7603317 },
    { lat: 48.441408, lng: 18.7599809 },
    { lat: 48.4418595, lng: 18.759495 },
    { lat: 48.4426599, lng: 18.7600447 },
    { lat: 48.4431972, lng: 18.760053 },
    { lat: 48.443676, lng: 18.7597659 },
    { lat: 48.4440097, lng: 18.7597665 },
    { lat: 48.4453334, lng: 18.7601216 },
    { lat: 48.446205, lng: 18.7604405 },
    { lat: 48.4469237, lng: 18.7610672 },
    { lat: 48.4472031, lng: 18.7601663 },
    { lat: 48.4477157, lng: 18.7590227 },
    { lat: 48.4478836, lng: 18.7582751 },
    { lat: 48.4478907, lng: 18.7580071 },
    { lat: 48.4474511, lng: 18.7560892 },
    { lat: 48.4470403, lng: 18.7554226 },
    { lat: 48.4480795, lng: 18.7533893 },
    { lat: 48.4487894, lng: 18.7522922 },
    { lat: 48.4489885, lng: 18.7514553 },
    { lat: 48.449463, lng: 18.751066 },
    { lat: 48.4494226, lng: 18.7503042 },
    { lat: 48.4492795, lng: 18.7497078 },
    { lat: 48.4495359, lng: 18.7491194 },
    { lat: 48.4501309, lng: 18.7480484 },
    { lat: 48.4500216, lng: 18.7476672 },
    { lat: 48.4497799, lng: 18.7471285 },
    { lat: 48.4494209, lng: 18.7457146 },
    { lat: 48.4489677, lng: 18.7445479 },
    { lat: 48.4491164, lng: 18.7442907 },
    { lat: 48.4493073, lng: 18.7434877 },
    { lat: 48.4493931, lng: 18.7424045 },
    { lat: 48.4492404, lng: 18.741674 },
    { lat: 48.4495137, lng: 18.7407439 },
    { lat: 48.4497854, lng: 18.7403195 },
    { lat: 48.450356, lng: 18.7400649 },
    { lat: 48.4512535, lng: 18.7394837 },
    { lat: 48.4515687, lng: 18.7390288 },
    { lat: 48.4516713, lng: 18.7386463 },
    { lat: 48.4518849, lng: 18.7373996 },
    { lat: 48.4522003, lng: 18.7363969 },
    { lat: 48.4522394, lng: 18.735347 },
    { lat: 48.4524189, lng: 18.7349279 },
    { lat: 48.4527921, lng: 18.7344155 },
    { lat: 48.453171, lng: 18.7337771 },
    { lat: 48.4536379, lng: 18.7332677 },
    { lat: 48.4537133, lng: 18.7316716 },
    { lat: 48.4539067, lng: 18.7306866 },
    { lat: 48.4544844, lng: 18.7291008 },
    { lat: 48.4546822, lng: 18.7283015 },
    { lat: 48.4544449, lng: 18.7267852 },
    { lat: 48.454652, lng: 18.7261865 },
    { lat: 48.4550173, lng: 18.72582 },
    { lat: 48.455494, lng: 18.7251594 },
    { lat: 48.456052, lng: 18.7249196 },
    { lat: 48.4566545, lng: 18.7245221 },
    { lat: 48.4573113, lng: 18.7241897 },
    { lat: 48.4575759, lng: 18.7234189 },
    { lat: 48.4576753, lng: 18.7208593 },
    { lat: 48.4578914, lng: 18.7202797 },
    { lat: 48.4582669, lng: 18.7195779 },
    { lat: 48.4586403, lng: 18.7192011 },
    { lat: 48.4596005, lng: 18.7199287 },
    { lat: 48.4609359, lng: 18.719337 },
    { lat: 48.4614199, lng: 18.7189984 },
    { lat: 48.4619866, lng: 18.7183947 },
    { lat: 48.4623672, lng: 18.7187028 },
    { lat: 48.4625563, lng: 18.7191398 },
    { lat: 48.4629153, lng: 18.7192544 },
    { lat: 48.4630996, lng: 18.7188818 },
    { lat: 48.4633822, lng: 18.7189995 },
    { lat: 48.4636704, lng: 18.7188835 },
    { lat: 48.4639868, lng: 18.7188948 },
    { lat: 48.4643881, lng: 18.7192505 },
    { lat: 48.4654142, lng: 18.7197571 },
    { lat: 48.4655709, lng: 18.7183985 },
    { lat: 48.4656594, lng: 18.7182176 },
    { lat: 48.4658827, lng: 18.7173915 },
    { lat: 48.4658653, lng: 18.7170261 },
    { lat: 48.466171, lng: 18.7163709 },
    { lat: 48.4668065, lng: 18.7162962 },
    { lat: 48.4670985, lng: 18.7161643 },
    { lat: 48.4670487, lng: 18.7150843 },
    { lat: 48.4670978, lng: 18.714395 },
    { lat: 48.4673729, lng: 18.7135043 },
    { lat: 48.4680814, lng: 18.7120492 },
    { lat: 48.4676408, lng: 18.7116748 },
    { lat: 48.4674414, lng: 18.7114053 },
    { lat: 48.4672137, lng: 18.7109416 },
    { lat: 48.4670253, lng: 18.7099426 },
    { lat: 48.4668884, lng: 18.7094495 },
    { lat: 48.4656712, lng: 18.7060403 },
    { lat: 48.4654041, lng: 18.7048387 },
    { lat: 48.4682241, lng: 18.7009175 },
    { lat: 48.4670586, lng: 18.6978664 },
    { lat: 48.4695495, lng: 18.6942912 },
    { lat: 48.468632, lng: 18.6933526 },
    { lat: 48.4683658, lng: 18.6931388 },
    { lat: 48.4677624, lng: 18.6928013 },
    { lat: 48.4674864, lng: 18.6924928 },
    { lat: 48.467239, lng: 18.6921044 },
    { lat: 48.4667711, lng: 18.691748 },
    { lat: 48.466294, lng: 18.6915992 },
    { lat: 48.4660286, lng: 18.6916349 },
    { lat: 48.465668, lng: 18.6919361 },
    { lat: 48.4644425, lng: 18.6921145 },
    { lat: 48.4642682, lng: 18.6920371 },
    { lat: 48.4642324, lng: 18.6919203 },
    { lat: 48.4640063, lng: 18.691696 },
    { lat: 48.4639096, lng: 18.6915151 },
    { lat: 48.4630096, lng: 18.6909438 },
    { lat: 48.4629854, lng: 18.6905355 },
    { lat: 48.4628018, lng: 18.6894849 },
    { lat: 48.4629139, lng: 18.6886068 },
    { lat: 48.4625704, lng: 18.6875012 },
    { lat: 48.4626352, lng: 18.6870861 },
    { lat: 48.4626084, lng: 18.6860162 },
    { lat: 48.4623562, lng: 18.6852645 },
    { lat: 48.4620027, lng: 18.6848372 },
    { lat: 48.4613826, lng: 18.6823757 },
    { lat: 48.4605191, lng: 18.679941 },
    { lat: 48.4600914, lng: 18.6792206 },
    { lat: 48.459542, lng: 18.6778509 },
    { lat: 48.457839, lng: 18.676842 },
    { lat: 48.4577917, lng: 18.6756855 },
    { lat: 48.4576773, lng: 18.6753419 },
  ],
  OstrýGrúň: [
    { lat: 48.5572452, lng: 18.6337028 },
    { lat: 48.5570124, lng: 18.6347214 },
    { lat: 48.5568489, lng: 18.6350982 },
    { lat: 48.5566922, lng: 18.6352651 },
    { lat: 48.5562208, lng: 18.6354124 },
    { lat: 48.5555488, lng: 18.6364017 },
    { lat: 48.554565, lng: 18.6374612 },
    { lat: 48.5543274, lng: 18.6373848 },
    { lat: 48.5541616, lng: 18.6374282 },
    { lat: 48.553785, lng: 18.6392739 },
    { lat: 48.553846, lng: 18.6393174 },
    { lat: 48.5539146, lng: 18.6395244 },
    { lat: 48.5540236, lng: 18.6395566 },
    { lat: 48.5540401, lng: 18.6397184 },
    { lat: 48.5539347, lng: 18.6398138 },
    { lat: 48.5538949, lng: 18.6402879 },
    { lat: 48.554023, lng: 18.640634 },
    { lat: 48.5538561, lng: 18.6409093 },
    { lat: 48.5538739, lng: 18.6414294 },
    { lat: 48.5537551, lng: 18.641733 },
    { lat: 48.5536909, lng: 18.6429292 },
    { lat: 48.5535629, lng: 18.6431517 },
    { lat: 48.553438, lng: 18.6432186 },
    { lat: 48.5530676, lng: 18.6446148 },
    { lat: 48.5528253, lng: 18.6452696 },
    { lat: 48.5522937, lng: 18.6461415 },
    { lat: 48.5522213, lng: 18.6465181 },
    { lat: 48.5521982, lng: 18.646779 },
    { lat: 48.5522348, lng: 18.6470195 },
    { lat: 48.5525079, lng: 18.6474005 },
    { lat: 48.5532378, lng: 18.64822 },
    { lat: 48.5541191, lng: 18.6489225 },
    { lat: 48.5546071, lng: 18.6493745 },
    { lat: 48.5548467, lng: 18.6496981 },
    { lat: 48.5550737, lng: 18.6504619 },
    { lat: 48.5551044, lng: 18.650822 },
    { lat: 48.5550676, lng: 18.6521651 },
    { lat: 48.5548936, lng: 18.6521059 },
    { lat: 48.5542713, lng: 18.6515213 },
    { lat: 48.5540444, lng: 18.6513938 },
    { lat: 48.5536311, lng: 18.6513199 },
    { lat: 48.5532347, lng: 18.6513326 },
    { lat: 48.5526142, lng: 18.6514966 },
    { lat: 48.5523899, lng: 18.651625 },
    { lat: 48.5520624, lng: 18.6521847 },
    { lat: 48.5519709, lng: 18.6522222 },
    { lat: 48.5518213, lng: 18.6522105 },
    { lat: 48.5513589, lng: 18.651917 },
    { lat: 48.5512519, lng: 18.6519621 },
    { lat: 48.5511212, lng: 18.6522137 },
    { lat: 48.5507939, lng: 18.6537 },
    { lat: 48.5506634, lng: 18.6539818 },
    { lat: 48.5503117, lng: 18.6543632 },
    { lat: 48.5502882, lng: 18.6545109 },
    { lat: 48.550471, lng: 18.6552914 },
    { lat: 48.5506711, lng: 18.6583449 },
    { lat: 48.5507234, lng: 18.6602847 },
    { lat: 48.5507004, lng: 18.6607624 },
    { lat: 48.5508168, lng: 18.6617119 },
    { lat: 48.5507829, lng: 18.6625918 },
    { lat: 48.5506975, lng: 18.6629557 },
    { lat: 48.5504759, lng: 18.6635344 },
    { lat: 48.5503843, lng: 18.6641842 },
    { lat: 48.5495355, lng: 18.664296 },
    { lat: 48.5494865, lng: 18.6643529 },
    { lat: 48.5491055, lng: 18.6643127 },
    { lat: 48.549105, lng: 18.6640518 },
    { lat: 48.548936, lng: 18.6636331 },
    { lat: 48.5487258, lng: 18.6640051 },
    { lat: 48.5482665, lng: 18.6645864 },
    { lat: 48.5482103, lng: 18.6647801 },
    { lat: 48.5478636, lng: 18.6650668 },
    { lat: 48.5476325, lng: 18.6651287 },
    { lat: 48.54725, lng: 18.6654451 },
    { lat: 48.5462768, lng: 18.6660369 },
    { lat: 48.5462806, lng: 18.6664034 },
    { lat: 48.5468063, lng: 18.6687586 },
    { lat: 48.5471983, lng: 18.6692776 },
    { lat: 48.5485922, lng: 18.6723482 },
    { lat: 48.5487024, lng: 18.672511 },
    { lat: 48.5493855, lng: 18.6731581 },
    { lat: 48.5501828, lng: 18.6745685 },
    { lat: 48.5510716, lng: 18.6755562 },
    { lat: 48.5518629, lng: 18.6766648 },
    { lat: 48.5526034, lng: 18.6773506 },
    { lat: 48.5535832, lng: 18.6777917 },
    { lat: 48.5539032, lng: 18.6776813 },
    { lat: 48.5553967, lng: 18.6768388 },
    { lat: 48.555488, lng: 18.6770093 },
    { lat: 48.5556034, lng: 18.6777119 },
    { lat: 48.5558918, lng: 18.6777884 },
    { lat: 48.5561551, lng: 18.6782003 },
    { lat: 48.5566574, lng: 18.679473 },
    { lat: 48.5567833, lng: 18.6800555 },
    { lat: 48.5569599, lng: 18.6804058 },
    { lat: 48.5570528, lng: 18.6804543 },
    { lat: 48.5575017, lng: 18.6801164 },
    { lat: 48.5576812, lng: 18.6801406 },
    { lat: 48.5587181, lng: 18.6809522 },
    { lat: 48.5593324, lng: 18.6816413 },
    { lat: 48.5593699, lng: 18.6815609 },
    { lat: 48.5656309, lng: 18.6896114 },
    { lat: 48.5676082, lng: 18.6924259 },
    { lat: 48.5689047, lng: 18.6946195 },
    { lat: 48.5693368, lng: 18.6955279 },
    { lat: 48.5693415, lng: 18.6955388 },
    { lat: 48.572077, lng: 18.701951 },
    { lat: 48.572292, lng: 18.701652 },
    { lat: 48.572509, lng: 18.700919 },
    { lat: 48.573025, lng: 18.70013 },
    { lat: 48.573025, lng: 18.700135 },
    { lat: 48.573402, lng: 18.699838 },
    { lat: 48.573661, lng: 18.699353 },
    { lat: 48.573635, lng: 18.699 },
    { lat: 48.573628, lng: 18.698911 },
    { lat: 48.573678, lng: 18.698711 },
    { lat: 48.574085, lng: 18.698355 },
    { lat: 48.574127, lng: 18.698049 },
    { lat: 48.574391, lng: 18.697619 },
    { lat: 48.574357, lng: 18.697503 },
    { lat: 48.574426, lng: 18.697296 },
    { lat: 48.574702, lng: 18.697105 },
    { lat: 48.574904, lng: 18.696907 },
    { lat: 48.575118, lng: 18.69627 },
    { lat: 48.575149, lng: 18.695474 },
    { lat: 48.575444, lng: 18.695135 },
    { lat: 48.575534, lng: 18.694428 },
    { lat: 48.575765, lng: 18.693895 },
    { lat: 48.576103, lng: 18.693292 },
    { lat: 48.576269, lng: 18.693261 },
    { lat: 48.576505, lng: 18.693226 },
    { lat: 48.576786, lng: 18.692855 },
    { lat: 48.577244, lng: 18.692523 },
    { lat: 48.577267, lng: 18.692506 },
    { lat: 48.577279, lng: 18.692491 },
    { lat: 48.577614, lng: 18.69205 },
    { lat: 48.578128, lng: 18.691405 },
    { lat: 48.578408, lng: 18.69106 },
    { lat: 48.579109, lng: 18.690516 },
    { lat: 48.579521, lng: 18.690402 },
    { lat: 48.580708, lng: 18.688997 },
    { lat: 48.581282, lng: 18.688943 },
    { lat: 48.581577, lng: 18.689072 },
    { lat: 48.582024, lng: 18.688779 },
    { lat: 48.582243, lng: 18.688923 },
    { lat: 48.582502, lng: 18.689044 },
    { lat: 48.582683, lng: 18.689073 },
    { lat: 48.582893, lng: 18.688988 },
    { lat: 48.583064, lng: 18.688824 },
    { lat: 48.583333, lng: 18.688498 },
    { lat: 48.583405, lng: 18.688395 },
    { lat: 48.583748, lng: 18.687853 },
    { lat: 48.584306, lng: 18.687241 },
    { lat: 48.584769, lng: 18.687164 },
    { lat: 48.585136, lng: 18.687022 },
    { lat: 48.585489, lng: 18.686865 },
    { lat: 48.586237, lng: 18.686517 },
    { lat: 48.58695, lng: 18.686571 },
    { lat: 48.587389, lng: 18.686527 },
    { lat: 48.587642, lng: 18.685931 },
    { lat: 48.58856, lng: 18.685699 },
    { lat: 48.588958, lng: 18.68561 },
    { lat: 48.590877, lng: 18.685197 },
    { lat: 48.590987, lng: 18.685065 },
    { lat: 48.591023, lng: 18.685023 },
    { lat: 48.592037, lng: 18.683842 },
    { lat: 48.592463, lng: 18.683648 },
    { lat: 48.592917, lng: 18.683385 },
    { lat: 48.593191, lng: 18.682957 },
    { lat: 48.593615, lng: 18.682925 },
    { lat: 48.593931, lng: 18.682826 },
    { lat: 48.595074, lng: 18.682144 },
    { lat: 48.596003, lng: 18.681644 },
    { lat: 48.596329, lng: 18.681423 },
    { lat: 48.596628, lng: 18.681152 },
    { lat: 48.596957, lng: 18.680779 },
    { lat: 48.597449, lng: 18.680045 },
    { lat: 48.597778, lng: 18.679725 },
    { lat: 48.598366, lng: 18.679536 },
    { lat: 48.598447, lng: 18.679457 },
    { lat: 48.599139, lng: 18.678844 },
    { lat: 48.599342, lng: 18.678723 },
    { lat: 48.599711, lng: 18.678189 },
    { lat: 48.599834, lng: 18.678088 },
    { lat: 48.600423, lng: 18.677712 },
    { lat: 48.600809, lng: 18.677882 },
    { lat: 48.601614, lng: 18.677828 },
    { lat: 48.601944, lng: 18.677799 },
    { lat: 48.602774, lng: 18.677958 },
    { lat: 48.602996, lng: 18.678001 },
    { lat: 48.603165, lng: 18.677893 },
    { lat: 48.603593, lng: 18.677591 },
    { lat: 48.603911, lng: 18.677295 },
    { lat: 48.60421, lng: 18.677008 },
    { lat: 48.605126, lng: 18.677453 },
    { lat: 48.605582, lng: 18.6778 },
    { lat: 48.605916, lng: 18.677358 },
    { lat: 48.606323, lng: 18.677808 },
    { lat: 48.607451, lng: 18.677654 },
    { lat: 48.608886, lng: 18.67673 },
    { lat: 48.609577, lng: 18.676111 },
    { lat: 48.610157, lng: 18.676153 },
    { lat: 48.611031, lng: 18.675392 },
    { lat: 48.611249, lng: 18.675045 },
    { lat: 48.611583, lng: 18.674442 },
    { lat: 48.611928, lng: 18.673364 },
    { lat: 48.6128, lng: 18.672061 },
    { lat: 48.613912, lng: 18.670082 },
    { lat: 48.614474, lng: 18.669265 },
    { lat: 48.614583, lng: 18.669081 },
    { lat: 48.614614, lng: 18.669018 },
    { lat: 48.614634, lng: 18.668983 },
    { lat: 48.6145181, lng: 18.6687802 },
    { lat: 48.6144651, lng: 18.668443 },
    { lat: 48.6144236, lng: 18.6677915 },
    { lat: 48.6144375, lng: 18.6666436 },
    { lat: 48.6141303, lng: 18.6645477 },
    { lat: 48.614064, lng: 18.663818 },
    { lat: 48.6140217, lng: 18.6636371 },
    { lat: 48.6136337, lng: 18.663092 },
    { lat: 48.6132796, lng: 18.6621856 },
    { lat: 48.6119615, lng: 18.6622332 },
    { lat: 48.6122749, lng: 18.6639144 },
    { lat: 48.6122153, lng: 18.665815 },
    { lat: 48.6120164, lng: 18.6675735 },
    { lat: 48.6118736, lng: 18.6683401 },
    { lat: 48.6116945, lng: 18.6689157 },
    { lat: 48.6114761, lng: 18.6693864 },
    { lat: 48.6111302, lng: 18.6699406 },
    { lat: 48.6093239, lng: 18.6722856 },
    { lat: 48.6078756, lng: 18.6732975 },
    { lat: 48.607711, lng: 18.6733621 },
    { lat: 48.6075354, lng: 18.6733881 },
    { lat: 48.6073768, lng: 18.6732684 },
    { lat: 48.6069435, lng: 18.6732224 },
    { lat: 48.605867, lng: 18.6728779 },
    { lat: 48.605638, lng: 18.672729 },
    { lat: 48.605406, lng: 18.6724375 },
    { lat: 48.6052059, lng: 18.6723471 },
    { lat: 48.6046301, lng: 18.6723359 },
    { lat: 48.6036014, lng: 18.6727525 },
    { lat: 48.6016117, lng: 18.6724126 },
    { lat: 48.600059, lng: 18.6723121 },
    { lat: 48.5991178, lng: 18.6726824 },
    { lat: 48.5980415, lng: 18.6730125 },
    { lat: 48.5955466, lng: 18.6732221 },
    { lat: 48.5945446, lng: 18.6732321 },
    { lat: 48.5931058, lng: 18.6731341 },
    { lat: 48.5913685, lng: 18.6737579 },
    { lat: 48.5910758, lng: 18.6737914 },
    { lat: 48.5902833, lng: 18.6736646 },
    { lat: 48.5893489, lng: 18.6735136 },
    { lat: 48.5885738, lng: 18.6728515 },
    { lat: 48.5879747, lng: 18.6725514 },
    { lat: 48.5879708, lng: 18.672228 },
    { lat: 48.5883657, lng: 18.6712826 },
    { lat: 48.588008, lng: 18.671553 },
    { lat: 48.5877387, lng: 18.6718733 },
    { lat: 48.5874562, lng: 18.6720526 },
    { lat: 48.5865445, lng: 18.6723205 },
    { lat: 48.5855266, lng: 18.6729195 },
    { lat: 48.5853573, lng: 18.6731709 },
    { lat: 48.5819052, lng: 18.6693186 },
    { lat: 48.5817904, lng: 18.66909 },
    { lat: 48.5816943, lng: 18.6687081 },
    { lat: 48.5814995, lng: 18.6689669 },
    { lat: 48.5813741, lng: 18.668964 },
    { lat: 48.5811931, lng: 18.6691025 },
    { lat: 48.5810368, lng: 18.6687297 },
    { lat: 48.5807824, lng: 18.6683172 },
    { lat: 48.580715, lng: 18.6684486 },
    { lat: 48.5805253, lng: 18.6678986 },
    { lat: 48.5800338, lng: 18.665038 },
    { lat: 48.5801899, lng: 18.6629848 },
    { lat: 48.5801231, lng: 18.6618235 },
    { lat: 48.5805328, lng: 18.6589475 },
    { lat: 48.5814603, lng: 18.6559732 },
    { lat: 48.58325, lng: 18.6511156 },
    { lat: 48.5817041, lng: 18.6488538 },
    { lat: 48.5806489, lng: 18.6474802 },
    { lat: 48.5805212, lng: 18.6470334 },
    { lat: 48.5799554, lng: 18.646119 },
    { lat: 48.5793393, lng: 18.6447096 },
    { lat: 48.5784918, lng: 18.6430222 },
    { lat: 48.5778488, lng: 18.6434373 },
    { lat: 48.5765459, lng: 18.6430551 },
    { lat: 48.5764069, lng: 18.642968 },
    { lat: 48.5754572, lng: 18.6418371 },
    { lat: 48.5754242, lng: 18.6419569 },
    { lat: 48.5754686, lng: 18.6421933 },
    { lat: 48.575433, lng: 18.6424544 },
    { lat: 48.5754773, lng: 18.6427336 },
    { lat: 48.5751654, lng: 18.6424928 },
    { lat: 48.5747972, lng: 18.6417076 },
    { lat: 48.5746693, lng: 18.6413628 },
    { lat: 48.5745493, lng: 18.6405999 },
    { lat: 48.5741237, lng: 18.6401939 },
    { lat: 48.5737851, lng: 18.6397288 },
    { lat: 48.5732261, lng: 18.639421 },
    { lat: 48.5728282, lng: 18.6395719 },
    { lat: 48.5727089, lng: 18.6394105 },
    { lat: 48.5720392, lng: 18.6373185 },
    { lat: 48.5711858, lng: 18.6351065 },
    { lat: 48.5726608, lng: 18.6259386 },
    { lat: 48.5742857, lng: 18.6228978 },
    { lat: 48.5760193, lng: 18.6180304 },
    { lat: 48.5763872, lng: 18.6174072 },
    { lat: 48.5769069, lng: 18.6160452 },
    { lat: 48.5770311, lng: 18.6154437 },
    { lat: 48.5774261, lng: 18.6144253 },
    { lat: 48.580224, lng: 18.600565 },
    { lat: 48.580092, lng: 18.60049 },
    { lat: 48.579308, lng: 18.600057 },
    { lat: 48.579237, lng: 18.600033 },
    { lat: 48.5791454, lng: 18.6007744 },
    { lat: 48.5789898, lng: 18.6011084 },
    { lat: 48.5786515, lng: 18.6029449 },
    { lat: 48.5785365, lng: 18.6033402 },
    { lat: 48.5782096, lng: 18.6038687 },
    { lat: 48.5780582, lng: 18.6042973 },
    { lat: 48.5765599, lng: 18.6064587 },
    { lat: 48.5759824, lng: 18.6071259 },
    { lat: 48.5752663, lng: 18.6078781 },
    { lat: 48.5748022, lng: 18.607954 },
    { lat: 48.5741444, lng: 18.6085947 },
    { lat: 48.5737111, lng: 18.609403 },
    { lat: 48.5730467, lng: 18.6100488 },
    { lat: 48.5729369, lng: 18.610334 },
    { lat: 48.5729391, lng: 18.6105638 },
    { lat: 48.5728469, lng: 18.6111521 },
    { lat: 48.5726813, lng: 18.6113038 },
    { lat: 48.5721395, lng: 18.612337 },
    { lat: 48.5716071, lng: 18.6131488 },
    { lat: 48.5710432, lng: 18.6137967 },
    { lat: 48.5706009, lng: 18.6141708 },
    { lat: 48.5702393, lng: 18.6148665 },
    { lat: 48.5697258, lng: 18.6153405 },
    { lat: 48.5698174, lng: 18.6165758 },
    { lat: 48.5697379, lng: 18.6172782 },
    { lat: 48.5695139, lng: 18.6179748 },
    { lat: 48.5692768, lng: 18.6183857 },
    { lat: 48.5689277, lng: 18.6187746 },
    { lat: 48.5684126, lng: 18.6197406 },
    { lat: 48.5681578, lng: 18.6203053 },
    { lat: 48.5678895, lng: 18.6215208 },
    { lat: 48.5673899, lng: 18.6227562 },
    { lat: 48.5670837, lng: 18.6230383 },
    { lat: 48.5668259, lng: 18.6238626 },
    { lat: 48.5667454, lng: 18.6249818 },
    { lat: 48.5665508, lng: 18.6259746 },
    { lat: 48.5662421, lng: 18.6262944 },
    { lat: 48.5658369, lng: 18.6256607 },
    { lat: 48.5652452, lng: 18.6258161 },
    { lat: 48.5642741, lng: 18.6259222 },
    { lat: 48.5642548, lng: 18.6286138 },
    { lat: 48.5643713, lng: 18.6295649 },
    { lat: 48.5646896, lng: 18.6314485 },
    { lat: 48.5644785, lng: 18.6314651 },
    { lat: 48.5636122, lng: 18.631779 },
    { lat: 48.5626508, lng: 18.6322327 },
    { lat: 48.5622096, lng: 18.6323127 },
    { lat: 48.5609942, lng: 18.6321135 },
    { lat: 48.559024, lng: 18.6331382 },
    { lat: 48.5572452, lng: 18.6337028 },
  ],
  MaláLehota: [
    { lat: 48.528946, lng: 18.518049 },
    { lat: 48.52892, lng: 18.518023 },
    { lat: 48.52876, lng: 18.517903 },
    { lat: 48.528627, lng: 18.517909 },
    { lat: 48.528481, lng: 18.517888 },
    { lat: 48.528123, lng: 18.517444 },
    { lat: 48.528103, lng: 18.517184 },
    { lat: 48.528015, lng: 18.516956 },
    { lat: 48.527969, lng: 18.516748 },
    { lat: 48.527859, lng: 18.516357 },
    { lat: 48.527862, lng: 18.516141 },
    { lat: 48.527807, lng: 18.515923 },
    { lat: 48.527821, lng: 18.515763 },
    { lat: 48.527784, lng: 18.515649 },
    { lat: 48.527795, lng: 18.515381 },
    { lat: 48.527785, lng: 18.515211 },
    { lat: 48.527704, lng: 18.515084 },
    { lat: 48.527742, lng: 18.514774 },
    { lat: 48.527713, lng: 18.514558 },
    { lat: 48.527679, lng: 18.514419 },
    { lat: 48.527711, lng: 18.514166 },
    { lat: 48.527549, lng: 18.513772 },
    { lat: 48.52757, lng: 18.513441 },
    { lat: 48.527411, lng: 18.513213 },
    { lat: 48.527412, lng: 18.513093 },
    { lat: 48.527523, lng: 18.512953 },
    { lat: 48.527421, lng: 18.512439 },
    { lat: 48.527375, lng: 18.512384 },
    { lat: 48.527373, lng: 18.511687 },
    { lat: 48.527203, lng: 18.511501 },
    { lat: 48.527096, lng: 18.511525 },
    { lat: 48.526955, lng: 18.511386 },
    { lat: 48.526831, lng: 18.511158 },
    { lat: 48.52666, lng: 18.511067 },
    { lat: 48.526543, lng: 18.510872 },
    { lat: 48.526346, lng: 18.510452 },
    { lat: 48.52634, lng: 18.510346 },
    { lat: 48.526346, lng: 18.510212 },
    { lat: 48.526221, lng: 18.510127 },
    { lat: 48.526002, lng: 18.50988 },
    { lat: 48.525947, lng: 18.509737 },
    { lat: 48.526027, lng: 18.509531 },
    { lat: 48.526022, lng: 18.509415 },
    { lat: 48.525894, lng: 18.509165 },
    { lat: 48.525881, lng: 18.508908 },
    { lat: 48.525742, lng: 18.508658 },
    { lat: 48.525591, lng: 18.508497 },
    { lat: 48.525493, lng: 18.50831 },
    { lat: 48.525354, lng: 18.508274 },
    { lat: 48.525296, lng: 18.508213 },
    { lat: 48.525241, lng: 18.508128 },
    { lat: 48.525173, lng: 18.508034 },
    { lat: 48.525033, lng: 18.507999 },
    { lat: 48.524978, lng: 18.507871 },
    { lat: 48.524962, lng: 18.507846 },
    { lat: 48.524742, lng: 18.507535 },
    { lat: 48.524725, lng: 18.507343 },
    { lat: 48.524777, lng: 18.507174 },
    { lat: 48.524663, lng: 18.507038 },
    { lat: 48.524604, lng: 18.506725 },
    { lat: 48.524523, lng: 18.506546 },
    { lat: 48.524518, lng: 18.506369 },
    { lat: 48.524468, lng: 18.506204 },
    { lat: 48.524453, lng: 18.506156 },
    { lat: 48.524379, lng: 18.50598 },
    { lat: 48.524306, lng: 18.505702 },
    { lat: 48.524259, lng: 18.505591 },
    { lat: 48.524184, lng: 18.505281 },
    { lat: 48.524161, lng: 18.504991 },
    { lat: 48.524066, lng: 18.504698 },
    { lat: 48.523996, lng: 18.504529 },
    { lat: 48.523903, lng: 18.504378 },
    { lat: 48.523695, lng: 18.504098 },
    { lat: 48.523421, lng: 18.503913 },
    { lat: 48.523283, lng: 18.503646 },
    { lat: 48.523088, lng: 18.503555 },
    { lat: 48.522925, lng: 18.503415 },
    { lat: 48.522799, lng: 18.503211 },
    { lat: 48.52269, lng: 18.503114 },
    { lat: 48.522685, lng: 18.503001 },
    { lat: 48.52277, lng: 18.502677 },
    { lat: 48.522745, lng: 18.502569 },
    { lat: 48.522599, lng: 18.502402 },
    { lat: 48.52241, lng: 18.502349 },
    { lat: 48.522274, lng: 18.502165 },
    { lat: 48.522091, lng: 18.502267 },
    { lat: 48.521926, lng: 18.501973 },
    { lat: 48.521869, lng: 18.501881 },
    { lat: 48.521749, lng: 18.501719 },
    { lat: 48.521655, lng: 18.501661 },
    { lat: 48.521624, lng: 18.501526 },
    { lat: 48.521564, lng: 18.501384 },
    { lat: 48.521505, lng: 18.501169 },
    { lat: 48.521428, lng: 18.50096 },
    { lat: 48.52136, lng: 18.500757 },
    { lat: 48.521326, lng: 18.500663 },
    { lat: 48.521266, lng: 18.50056 },
    { lat: 48.521143, lng: 18.500415 },
    { lat: 48.521111, lng: 18.500376 },
    { lat: 48.521018, lng: 18.500186 },
    { lat: 48.520977, lng: 18.500034 },
    { lat: 48.520801, lng: 18.499884 },
    { lat: 48.52065, lng: 18.499742 },
    { lat: 48.520604, lng: 18.499747 },
    { lat: 48.520487, lng: 18.499765 },
    { lat: 48.520384, lng: 18.499583 },
    { lat: 48.520313, lng: 18.499535 },
    { lat: 48.520193, lng: 18.4995 },
    { lat: 48.520055, lng: 18.499258 },
    { lat: 48.519916, lng: 18.499292 },
    { lat: 48.519842, lng: 18.499199 },
    { lat: 48.519681, lng: 18.499129 },
    { lat: 48.51951, lng: 18.499114 },
    { lat: 48.519423, lng: 18.499056 },
    { lat: 48.519367, lng: 18.499064 },
    { lat: 48.519185, lng: 18.498955 },
    { lat: 48.519044, lng: 18.498915 },
    { lat: 48.518976, lng: 18.499059 },
    { lat: 48.518815, lng: 18.498853 },
    { lat: 48.518729, lng: 18.498601 },
    { lat: 48.518618, lng: 18.498527 },
    { lat: 48.518543, lng: 18.498374 },
    { lat: 48.51844, lng: 18.49831 },
    { lat: 48.5183, lng: 18.498314 },
    { lat: 48.518167, lng: 18.498123 },
    { lat: 48.517903, lng: 18.497891 },
    { lat: 48.517729, lng: 18.497471 },
    { lat: 48.517531, lng: 18.497159 },
    { lat: 48.517435, lng: 18.496851 },
    { lat: 48.517247, lng: 18.496649 },
    { lat: 48.517189, lng: 18.496425 },
    { lat: 48.517112, lng: 18.496374 },
    { lat: 48.517042, lng: 18.496251 },
    { lat: 48.517032, lng: 18.496079 },
    { lat: 48.516875, lng: 18.495953 },
    { lat: 48.516817, lng: 18.49576 },
    { lat: 48.516794, lng: 18.495506 },
    { lat: 48.516741, lng: 18.495332 },
    { lat: 48.51664, lng: 18.495187 },
    { lat: 48.516471, lng: 18.495108 },
    { lat: 48.516279, lng: 18.495059 },
    { lat: 48.516169, lng: 18.494993 },
    { lat: 48.515883, lng: 18.494731 },
    { lat: 48.515832, lng: 18.494679 },
    { lat: 48.51561, lng: 18.494399 },
    { lat: 48.515492, lng: 18.494039 },
    { lat: 48.51514, lng: 18.493635 },
    { lat: 48.514938, lng: 18.4934 },
    { lat: 48.514762, lng: 18.492964 },
    { lat: 48.514555, lng: 18.49277 },
    { lat: 48.514194, lng: 18.492637 },
    { lat: 48.513913, lng: 18.492336 },
    { lat: 48.513707, lng: 18.492097 },
    { lat: 48.513396, lng: 18.491688 },
    { lat: 48.513065, lng: 18.491182 },
    { lat: 48.512853, lng: 18.490907 },
    { lat: 48.512692, lng: 18.490674 },
    { lat: 48.512576, lng: 18.490624 },
    { lat: 48.51236, lng: 18.490217 },
    { lat: 48.512177, lng: 18.489997 },
    { lat: 48.512077, lng: 18.489778 },
    { lat: 48.51195, lng: 18.489481 },
    { lat: 48.511856, lng: 18.489214 },
    { lat: 48.511878, lng: 18.489194 },
    { lat: 48.511892, lng: 18.489215 },
    { lat: 48.511924, lng: 18.489202 },
    { lat: 48.511842, lng: 18.489099 },
    { lat: 48.511657, lng: 18.488963 },
    { lat: 48.511615, lng: 18.488909 },
    { lat: 48.511447, lng: 18.488522 },
    { lat: 48.511146, lng: 18.488146 },
    { lat: 48.510947, lng: 18.488044 },
    { lat: 48.510769, lng: 18.48789 },
    { lat: 48.510598, lng: 18.487715 },
    { lat: 48.510361, lng: 18.487538 },
    { lat: 48.510054, lng: 18.487459 },
    { lat: 48.509852, lng: 18.487412 },
    { lat: 48.509593, lng: 18.487413 },
    { lat: 48.509419, lng: 18.487417 },
    { lat: 48.509242, lng: 18.487305 },
    { lat: 48.509091, lng: 18.487032 },
    { lat: 48.508918, lng: 18.486664 },
    { lat: 48.508901, lng: 18.486696 },
    { lat: 48.508877, lng: 18.486743 },
    { lat: 48.508698, lng: 18.48669 },
    { lat: 48.508648, lng: 18.486668 },
    { lat: 48.508578, lng: 18.486638 },
    { lat: 48.50855, lng: 18.486628 },
    { lat: 48.508535, lng: 18.486621 },
    { lat: 48.508424, lng: 18.486564 },
    { lat: 48.508206, lng: 18.486557 },
    { lat: 48.508153, lng: 18.486568 },
    { lat: 48.508045, lng: 18.486589 },
    { lat: 48.50779, lng: 18.486516 },
    { lat: 48.507597, lng: 18.486148 },
    { lat: 48.507461, lng: 18.485994 },
    { lat: 48.507207, lng: 18.485603 },
    { lat: 48.506939, lng: 18.485293 },
    { lat: 48.50657, lng: 18.48497 },
    { lat: 48.506447, lng: 18.484716 },
    { lat: 48.506301, lng: 18.484662 },
    { lat: 48.506062, lng: 18.484366 },
    { lat: 48.505612, lng: 18.48408 },
    { lat: 48.505484, lng: 18.483916 },
    { lat: 48.505245, lng: 18.483772 },
    { lat: 48.504941, lng: 18.483519 },
    { lat: 48.504717, lng: 18.483345 },
    { lat: 48.504707, lng: 18.48326 },
    { lat: 48.504727, lng: 18.483168 },
    { lat: 48.504736, lng: 18.483139 },
    { lat: 48.504687, lng: 18.483099 },
    { lat: 48.50459, lng: 18.483036 },
    { lat: 48.504446, lng: 18.482817 },
    { lat: 48.504227, lng: 18.482385 },
    { lat: 48.504041, lng: 18.482214 },
    { lat: 48.503937, lng: 18.482134 },
    { lat: 48.503827, lng: 18.482049 },
    { lat: 48.503737, lng: 18.481982 },
    { lat: 48.503415, lng: 18.481892 },
    { lat: 48.503231, lng: 18.48184 },
    { lat: 48.502989, lng: 18.481822 },
    { lat: 48.502916, lng: 18.48182 },
    { lat: 48.50256, lng: 18.48184 },
    { lat: 48.50246, lng: 18.481842 },
    { lat: 48.502371, lng: 18.481845 },
    { lat: 48.502277, lng: 18.48184 },
    { lat: 48.501817, lng: 18.481817 },
    { lat: 48.50172, lng: 18.481855 },
    { lat: 48.5016, lng: 18.481693 },
    { lat: 48.501302, lng: 18.481638 },
    { lat: 48.501083, lng: 18.48168 },
    { lat: 48.500941, lng: 18.481538 },
    { lat: 48.500287, lng: 18.481401 },
    { lat: 48.500094, lng: 18.481294 },
    { lat: 48.499904, lng: 18.48117 },
    { lat: 48.499796, lng: 18.4811 },
    { lat: 48.499688, lng: 18.481346 },
    { lat: 48.499524, lng: 18.481481 },
    { lat: 48.499304, lng: 18.481788 },
    { lat: 48.499227, lng: 18.481935 },
    { lat: 48.499145, lng: 18.482009 },
    { lat: 48.499103, lng: 18.482127 },
    { lat: 48.498938, lng: 18.482473 },
    { lat: 48.499034, lng: 18.4828 },
    { lat: 48.499161, lng: 18.483314 },
    { lat: 48.499229, lng: 18.484609 },
    { lat: 48.499285, lng: 18.486006 },
    { lat: 48.499347, lng: 18.487428 },
    { lat: 48.499432, lng: 18.489267 },
    { lat: 48.499473, lng: 18.490117 },
    { lat: 48.499504, lng: 18.490938 },
    { lat: 48.499573, lng: 18.492767 },
    { lat: 48.499672, lng: 18.494656 },
    { lat: 48.499724, lng: 18.495674 },
    { lat: 48.50043, lng: 18.496197 },
    { lat: 48.50047, lng: 18.496221 },
    { lat: 48.50139, lng: 18.496214 },
    { lat: 48.501765, lng: 18.496219 },
    { lat: 48.502357, lng: 18.496307 },
    { lat: 48.50256, lng: 18.496297 },
    { lat: 48.502959, lng: 18.496183 },
    { lat: 48.503556, lng: 18.496163 },
    { lat: 48.504159, lng: 18.496139 },
    { lat: 48.5048, lng: 18.496123 },
    { lat: 48.505768, lng: 18.496092 },
    { lat: 48.506838, lng: 18.496063 },
    { lat: 48.506877, lng: 18.496064 },
    { lat: 48.506956, lng: 18.496334 },
    { lat: 48.507219, lng: 18.497968 },
    { lat: 48.507269, lng: 18.498344 },
    { lat: 48.507397, lng: 18.498935 },
    { lat: 48.507505, lng: 18.499531 },
    { lat: 48.507645, lng: 18.500149 },
    { lat: 48.507774, lng: 18.500717 },
    { lat: 48.507778, lng: 18.500782 },
    { lat: 48.507785, lng: 18.500902 },
    { lat: 48.507828, lng: 18.501385 },
    { lat: 48.507969, lng: 18.502005 },
    { lat: 48.508135, lng: 18.502707 },
    { lat: 48.508313, lng: 18.503481 },
    { lat: 48.508428, lng: 18.504135 },
    { lat: 48.508794, lng: 18.505805 },
    { lat: 48.509057, lng: 18.506874 },
    { lat: 48.509147, lng: 18.507346 },
    { lat: 48.509344, lng: 18.508358 },
    { lat: 48.509384, lng: 18.508609 },
    { lat: 48.509421, lng: 18.509141 },
    { lat: 48.509612, lng: 18.5105 },
    { lat: 48.509642, lng: 18.511726 },
    { lat: 48.509447, lng: 18.512752 },
    { lat: 48.509444, lng: 18.512787 },
    { lat: 48.509439, lng: 18.512837 },
    { lat: 48.509434, lng: 18.512895 },
    { lat: 48.509363, lng: 18.513777 },
    { lat: 48.509291, lng: 18.51473 },
    { lat: 48.509187, lng: 18.515942 },
    { lat: 48.509129, lng: 18.51685 },
    { lat: 48.509082, lng: 18.517417 },
    { lat: 48.509018, lng: 18.518146 },
    { lat: 48.508983, lng: 18.518632 },
    { lat: 48.508938, lng: 18.519106 },
    { lat: 48.50877, lng: 18.520075 },
    { lat: 48.508608, lng: 18.521399 },
    { lat: 48.508567, lng: 18.521736 },
    { lat: 48.50853, lng: 18.522193 },
    { lat: 48.508437, lng: 18.522645 },
    { lat: 48.508099, lng: 18.523045 },
    { lat: 48.507908, lng: 18.523104 },
    { lat: 48.507662, lng: 18.523946 },
    { lat: 48.507642, lng: 18.524027 },
    { lat: 48.507538, lng: 18.524426 },
    { lat: 48.507373, lng: 18.524364 },
    { lat: 48.507443, lng: 18.523685 },
    { lat: 48.507248, lng: 18.523512 },
    { lat: 48.507124, lng: 18.523529 },
    { lat: 48.506993, lng: 18.523593 },
    { lat: 48.506914, lng: 18.523758 },
    { lat: 48.506961, lng: 18.523885 },
    { lat: 48.506754, lng: 18.524221 },
    { lat: 48.506449, lng: 18.524666 },
    { lat: 48.506358, lng: 18.524904 },
    { lat: 48.506264, lng: 18.525092 },
    { lat: 48.506034, lng: 18.525399 },
    { lat: 48.505968, lng: 18.525555 },
    { lat: 48.505923, lng: 18.525663 },
    { lat: 48.506214, lng: 18.525586 },
    { lat: 48.506191, lng: 18.525758 },
    { lat: 48.505987, lng: 18.526367 },
    { lat: 48.50583, lng: 18.526688 },
    { lat: 48.505764, lng: 18.526972 },
    { lat: 48.505563, lng: 18.527311 },
    { lat: 48.50556, lng: 18.527336 },
    { lat: 48.505532, lng: 18.527571 },
    { lat: 48.505558, lng: 18.527721 },
    { lat: 48.505629, lng: 18.527792 },
    { lat: 48.505625, lng: 18.527825 },
    { lat: 48.505593, lng: 18.528043 },
    { lat: 48.505551, lng: 18.528367 },
    { lat: 48.50546, lng: 18.528628 },
    { lat: 48.505415, lng: 18.528793 },
    { lat: 48.50533, lng: 18.529139 },
    { lat: 48.505334, lng: 18.529333 },
    { lat: 48.505358, lng: 18.529435 },
    { lat: 48.505356, lng: 18.529546 },
    { lat: 48.505326, lng: 18.52966 },
    { lat: 48.505377, lng: 18.52985 },
    { lat: 48.505477, lng: 18.530039 },
    { lat: 48.505514, lng: 18.530203 },
    { lat: 48.505501, lng: 18.530336 },
    { lat: 48.505463, lng: 18.530461 },
    { lat: 48.505345, lng: 18.530604 },
    { lat: 48.505331, lng: 18.530617 },
    { lat: 48.505209, lng: 18.531015 },
    { lat: 48.505194, lng: 18.53106 },
    { lat: 48.505255, lng: 18.531249 },
    { lat: 48.505432, lng: 18.531718 },
    { lat: 48.505713, lng: 18.532165 },
    { lat: 48.505751, lng: 18.53222 },
    { lat: 48.506039, lng: 18.532328 },
    { lat: 48.506156, lng: 18.532371 },
    { lat: 48.506264, lng: 18.532423 },
    { lat: 48.506303, lng: 18.532392 },
    { lat: 48.506368, lng: 18.532342 },
    { lat: 48.506449, lng: 18.532308 },
    { lat: 48.506563, lng: 18.532258 },
    { lat: 48.506644, lng: 18.532234 },
    { lat: 48.506786, lng: 18.532191 },
    { lat: 48.506925, lng: 18.5321 },
    { lat: 48.506942, lng: 18.532099 },
    { lat: 48.507047, lng: 18.532101 },
    { lat: 48.507134, lng: 18.53222 },
    { lat: 48.507237, lng: 18.532199 },
    { lat: 48.507301, lng: 18.532019 },
    { lat: 48.507421, lng: 18.531981 },
    { lat: 48.507534, lng: 18.531954 },
    { lat: 48.507728, lng: 18.531907 },
    { lat: 48.507767, lng: 18.531898 },
    { lat: 48.507837, lng: 18.531972 },
    { lat: 48.507936, lng: 18.531982 },
    { lat: 48.507962, lng: 18.532086 },
    { lat: 48.507566, lng: 18.532469 },
    { lat: 48.507526, lng: 18.53249 },
    { lat: 48.507368, lng: 18.532548 },
    { lat: 48.50718, lng: 18.532725 },
    { lat: 48.50691, lng: 18.532989 },
    { lat: 48.506848, lng: 18.533052 },
    { lat: 48.507096, lng: 18.533315 },
    { lat: 48.50683, lng: 18.533442 },
    { lat: 48.506772, lng: 18.53347 },
    { lat: 48.506611, lng: 18.533506 },
    { lat: 48.506518, lng: 18.533527 },
    { lat: 48.50639, lng: 18.533543 },
    { lat: 48.506103, lng: 18.53358 },
    { lat: 48.506023, lng: 18.533591 },
    { lat: 48.50596, lng: 18.533599 },
    { lat: 48.50588, lng: 18.533605 },
    { lat: 48.505723, lng: 18.533564 },
    { lat: 48.505651, lng: 18.533547 },
    { lat: 48.505636, lng: 18.533547 },
    { lat: 48.505452, lng: 18.533589 },
    { lat: 48.505395, lng: 18.533604 },
    { lat: 48.505326, lng: 18.53362 },
    { lat: 48.505242, lng: 18.533633 },
    { lat: 48.505196, lng: 18.533639 },
    { lat: 48.505159, lng: 18.533659 },
    { lat: 48.505066, lng: 18.53368 },
    { lat: 48.505048, lng: 18.533683 },
    { lat: 48.504968, lng: 18.5337 },
    { lat: 48.504871, lng: 18.533726 },
    { lat: 48.504824, lng: 18.533741 },
    { lat: 48.504789, lng: 18.533752 },
    { lat: 48.504748, lng: 18.533765 },
    { lat: 48.5047, lng: 18.53378 },
    { lat: 48.504585, lng: 18.533829 },
    { lat: 48.504532, lng: 18.533864 },
    { lat: 48.504329, lng: 18.534033 },
    { lat: 48.504305, lng: 18.534053 },
    { lat: 48.504289, lng: 18.534076 },
    { lat: 48.504218, lng: 18.534174 },
    { lat: 48.50418, lng: 18.534219 },
    { lat: 48.504173, lng: 18.534226 },
    { lat: 48.504135, lng: 18.534273 },
    { lat: 48.50412, lng: 18.534296 },
    { lat: 48.504102, lng: 18.534316 },
    { lat: 48.504086, lng: 18.534337 },
    { lat: 48.504038, lng: 18.534394 },
    { lat: 48.503967, lng: 18.534483 },
    { lat: 48.503931, lng: 18.534531 },
    { lat: 48.503841, lng: 18.534651 },
    { lat: 48.503803, lng: 18.534696 },
    { lat: 48.503629, lng: 18.534901 },
    { lat: 48.503602, lng: 18.534931 },
    { lat: 48.503577, lng: 18.534959 },
    { lat: 48.503557, lng: 18.534982 },
    { lat: 48.503472, lng: 18.535079 },
    { lat: 48.503258, lng: 18.5351 },
    { lat: 48.503103, lng: 18.535111 },
    { lat: 48.502941, lng: 18.535446 },
    { lat: 48.502826, lng: 18.535736 },
    { lat: 48.502827, lng: 18.53575 },
    { lat: 48.502838, lng: 18.535842 },
    { lat: 48.502841, lng: 18.535958 },
    { lat: 48.50288, lng: 18.536007 },
    { lat: 48.502892, lng: 18.536019 },
    { lat: 48.503056, lng: 18.536118 },
    { lat: 48.503074, lng: 18.536133 },
    { lat: 48.503161, lng: 18.536169 },
    { lat: 48.503183, lng: 18.536178 },
    { lat: 48.503199, lng: 18.536185 },
    { lat: 48.503221, lng: 18.536195 },
    { lat: 48.503267, lng: 18.536212 },
    { lat: 48.503278, lng: 18.536219 },
    { lat: 48.503302, lng: 18.536229 },
    { lat: 48.503332, lng: 18.536253 },
    { lat: 48.50351, lng: 18.536383 },
    { lat: 48.503553, lng: 18.536454 },
    { lat: 48.503757, lng: 18.536798 },
    { lat: 48.503952, lng: 18.537034 },
    { lat: 48.503764, lng: 18.537352 },
    { lat: 48.50353, lng: 18.537766 },
    { lat: 48.503503, lng: 18.537815 },
    { lat: 48.503418, lng: 18.537734 },
    { lat: 48.503375, lng: 18.537694 },
    { lat: 48.503272, lng: 18.537554 },
    { lat: 48.503149, lng: 18.537481 },
    { lat: 48.50285, lng: 18.537391 },
    { lat: 48.502757, lng: 18.537366 },
    { lat: 48.502723, lng: 18.537354 },
    { lat: 48.502585, lng: 18.537189 },
    { lat: 48.502354, lng: 18.537186 },
    { lat: 48.502181, lng: 18.537052 },
    { lat: 48.5021, lng: 18.53699 },
    { lat: 48.501957, lng: 18.536958 },
    { lat: 48.501934, lng: 18.536958 },
    { lat: 48.501915, lng: 18.536962 },
    { lat: 48.501894, lng: 18.536969 },
    { lat: 48.501849, lng: 18.536973 },
    { lat: 48.50181, lng: 18.536978 },
    { lat: 48.501652, lng: 18.537021 },
    { lat: 48.50156, lng: 18.537023 },
    { lat: 48.501512, lng: 18.537024 },
    { lat: 48.501483, lng: 18.537039 },
    { lat: 48.501436, lng: 18.537072 },
    { lat: 48.501406, lng: 18.537081 },
    { lat: 48.501376, lng: 18.537084 },
    { lat: 48.501291, lng: 18.53718 },
    { lat: 48.501172, lng: 18.537315 },
    { lat: 48.501133, lng: 18.537363 },
    { lat: 48.501006, lng: 18.537665 },
    { lat: 48.500504, lng: 18.538056 },
    { lat: 48.500469, lng: 18.538087 },
    { lat: 48.500277, lng: 18.538348 },
    { lat: 48.50039, lng: 18.538637 },
    { lat: 48.500417, lng: 18.538705 },
    { lat: 48.500077, lng: 18.538502 },
    { lat: 48.500018, lng: 18.538468 },
    { lat: 48.499846, lng: 18.538516 },
    { lat: 48.499666, lng: 18.538522 },
    { lat: 48.499486, lng: 18.538567 },
    { lat: 48.49944, lng: 18.538606 },
    { lat: 48.499419, lng: 18.538626 },
    { lat: 48.499354, lng: 18.538657 },
    { lat: 48.499178, lng: 18.53881 },
    { lat: 48.499091, lng: 18.538822 },
    { lat: 48.498908, lng: 18.538778 },
    { lat: 48.498883, lng: 18.538773 },
    { lat: 48.498729, lng: 18.538746 },
    { lat: 48.498622, lng: 18.53877 },
    { lat: 48.49847, lng: 18.538726 },
    { lat: 48.498128, lng: 18.538627 },
    { lat: 48.497901, lng: 18.538596 },
    { lat: 48.497766, lng: 18.538621 },
    { lat: 48.497725, lng: 18.538619 },
    { lat: 48.497472, lng: 18.538591 },
    { lat: 48.49742, lng: 18.538587 },
    { lat: 48.497402, lng: 18.538572 },
    { lat: 48.497283, lng: 18.538462 },
    { lat: 48.497106, lng: 18.538413 },
    { lat: 48.497035, lng: 18.538402 },
    { lat: 48.497003, lng: 18.538415 },
    { lat: 48.496928, lng: 18.538464 },
    { lat: 48.496907, lng: 18.538464 },
    { lat: 48.49679, lng: 18.538437 },
    { lat: 48.496741, lng: 18.538377 },
    { lat: 48.496694, lng: 18.538353 },
    { lat: 48.496648, lng: 18.538284 },
    { lat: 48.496594, lng: 18.538263 },
    { lat: 48.496491, lng: 18.538207 },
    { lat: 48.496284, lng: 18.538321 },
    { lat: 48.496123, lng: 18.538474 },
    { lat: 48.496012, lng: 18.538403 },
    { lat: 48.495924, lng: 18.538327 },
    { lat: 48.495797, lng: 18.53829 },
    { lat: 48.495474, lng: 18.538168 },
    { lat: 48.495307, lng: 18.537899 },
    { lat: 48.495282, lng: 18.537862 },
    { lat: 48.49517, lng: 18.537785 },
    { lat: 48.49509, lng: 18.537733 },
    { lat: 48.49498, lng: 18.537703 },
    { lat: 48.494895, lng: 18.537689 },
    { lat: 48.494844, lng: 18.537686 },
    { lat: 48.494768, lng: 18.537679 },
    { lat: 48.49469, lng: 18.537669 },
    { lat: 48.494572, lng: 18.537654 },
    { lat: 48.494526, lng: 18.537649 },
    { lat: 48.494388, lng: 18.537624 },
    { lat: 48.494351, lng: 18.537619 },
    { lat: 48.494289, lng: 18.537522 },
    { lat: 48.494235, lng: 18.537542 },
    { lat: 48.494198, lng: 18.53753 },
    { lat: 48.493998, lng: 18.537467 },
    { lat: 48.493919, lng: 18.537443 },
    { lat: 48.493824, lng: 18.537413 },
    { lat: 48.493656, lng: 18.537413 },
    { lat: 48.493603, lng: 18.537413 },
    { lat: 48.493379, lng: 18.537317 },
    { lat: 48.493326, lng: 18.537306 },
    { lat: 48.492977, lng: 18.53722 },
    { lat: 48.492929, lng: 18.537207 },
    { lat: 48.492814, lng: 18.537205 },
    { lat: 48.492695, lng: 18.537257 },
    { lat: 48.492658, lng: 18.537277 },
    { lat: 48.492671, lng: 18.537389 },
    { lat: 48.492712, lng: 18.537712 },
    { lat: 48.49263, lng: 18.537613 },
    { lat: 48.492407, lng: 18.537352 },
    { lat: 48.492367, lng: 18.537322 },
    { lat: 48.492336, lng: 18.537304 },
    { lat: 48.492206, lng: 18.537374 },
    { lat: 48.492024, lng: 18.537337 },
    { lat: 48.491974, lng: 18.537335 },
    { lat: 48.491717, lng: 18.537061 },
    { lat: 48.491552, lng: 18.5369 },
    { lat: 48.491465, lng: 18.536818 },
    { lat: 48.491392, lng: 18.536748 },
    { lat: 48.491273, lng: 18.536552 },
    { lat: 48.491152, lng: 18.536353 },
    { lat: 48.491127, lng: 18.536313 },
    { lat: 48.490849, lng: 18.536217 },
    { lat: 48.490525, lng: 18.536254 },
    { lat: 48.490434, lng: 18.536254 },
    { lat: 48.490371, lng: 18.536257 },
    { lat: 48.490303, lng: 18.536172 },
    { lat: 48.490195, lng: 18.536046 },
    { lat: 48.490041, lng: 18.5361 },
    { lat: 48.489868, lng: 18.536058 },
    { lat: 48.489706, lng: 18.536056 },
    { lat: 48.489633, lng: 18.535981 },
    { lat: 48.489589, lng: 18.536001 },
    { lat: 48.489446, lng: 18.536071 },
    { lat: 48.489404, lng: 18.536093 },
    { lat: 48.489355, lng: 18.53612 },
    { lat: 48.489172, lng: 18.536225 },
    { lat: 48.489051, lng: 18.536295 },
    { lat: 48.488956, lng: 18.536392 },
    { lat: 48.488928, lng: 18.536422 },
    { lat: 48.488835, lng: 18.536474 },
    { lat: 48.488713, lng: 18.536541 },
    { lat: 48.488684, lng: 18.536558 },
    { lat: 48.488623, lng: 18.53653 },
    { lat: 48.488593, lng: 18.536516 },
    { lat: 48.488526, lng: 18.536486 },
    { lat: 48.488281, lng: 18.53644 },
    { lat: 48.488208, lng: 18.536467 },
    { lat: 48.488198, lng: 18.536469 },
    { lat: 48.488105, lng: 18.536457 },
    { lat: 48.487954, lng: 18.536437 },
    { lat: 48.48784, lng: 18.53645 },
    { lat: 48.487798, lng: 18.536487 },
    { lat: 48.487631, lng: 18.536626 },
    { lat: 48.487571, lng: 18.536587 },
    { lat: 48.487484, lng: 18.536629 },
    { lat: 48.487388, lng: 18.536562 },
    { lat: 48.487272, lng: 18.536455 },
    { lat: 48.487116, lng: 18.536311 },
    { lat: 48.486948, lng: 18.536204 },
    { lat: 48.486933, lng: 18.536196 },
    { lat: 48.48676, lng: 18.536172 },
    { lat: 48.486722, lng: 18.536167 },
    { lat: 48.486658, lng: 18.53614 },
    { lat: 48.486643, lng: 18.536109 },
    { lat: 48.486552, lng: 18.535949 },
    { lat: 48.486463, lng: 18.535789 },
    { lat: 48.486106, lng: 18.53535 },
    { lat: 48.486011, lng: 18.535173 },
    { lat: 48.485942, lng: 18.535051 },
    { lat: 48.485899, lng: 18.534967 },
    { lat: 48.485886, lng: 18.53496 },
    { lat: 48.485807, lng: 18.53492 },
    { lat: 48.485779, lng: 18.534908 },
    { lat: 48.485723, lng: 18.53487 },
    { lat: 48.485682, lng: 18.534848 },
    { lat: 48.485647, lng: 18.534815 },
    { lat: 48.485566, lng: 18.534749 },
    { lat: 48.485478, lng: 18.534687 },
    { lat: 48.485435, lng: 18.534654 },
    { lat: 48.485189, lng: 18.534461 },
    { lat: 48.485123, lng: 18.534424 },
    { lat: 48.484958, lng: 18.534322 },
    { lat: 48.484837, lng: 18.534264 },
    { lat: 48.484688, lng: 18.534201 },
    { lat: 48.484535, lng: 18.53413 },
    { lat: 48.484481, lng: 18.534107 },
    { lat: 48.484476, lng: 18.534038 },
    { lat: 48.484438, lng: 18.533952 },
    { lat: 48.48437, lng: 18.533817 },
    { lat: 48.484199, lng: 18.533743 },
    { lat: 48.484154, lng: 18.533728 },
    { lat: 48.484042, lng: 18.53352 },
    { lat: 48.483769, lng: 18.533509 },
    { lat: 48.483599, lng: 18.533553 },
    { lat: 48.483415, lng: 18.533499 },
    { lat: 48.483256, lng: 18.53338 },
    { lat: 48.483195, lng: 18.53341 },
    { lat: 48.483164, lng: 18.533411 },
    { lat: 48.482983, lng: 18.53339 },
    { lat: 48.4829, lng: 18.533343 },
    { lat: 48.482825, lng: 18.533306 },
    { lat: 48.482669, lng: 18.533241 },
    { lat: 48.48266, lng: 18.533237 },
    { lat: 48.482614, lng: 18.533216 },
    { lat: 48.482438, lng: 18.533159 },
    { lat: 48.482415, lng: 18.533153 },
    { lat: 48.4822, lng: 18.533161 },
    { lat: 48.482072, lng: 18.533175 },
    { lat: 48.481964, lng: 18.533176 },
    { lat: 48.481929, lng: 18.533175 },
    { lat: 48.481887, lng: 18.533172 },
    { lat: 48.481623, lng: 18.533313 },
    { lat: 48.481567, lng: 18.533349 },
    { lat: 48.481475, lng: 18.533303 },
    { lat: 48.481433, lng: 18.533284 },
    { lat: 48.481381, lng: 18.53326 },
    { lat: 48.481352, lng: 18.533266 },
    { lat: 48.481323, lng: 18.533273 },
    { lat: 48.481271, lng: 18.533284 },
    { lat: 48.481214, lng: 18.53331 },
    { lat: 48.481179, lng: 18.533328 },
    { lat: 48.481152, lng: 18.53332 },
    { lat: 48.481004, lng: 18.533278 },
    { lat: 48.480971, lng: 18.533269 },
    { lat: 48.480805, lng: 18.533256 },
    { lat: 48.480717, lng: 18.533255 },
    { lat: 48.480654, lng: 18.533291 },
    { lat: 48.480603, lng: 18.533326 },
    { lat: 48.480512, lng: 18.53339 },
    { lat: 48.480478, lng: 18.533415 },
    { lat: 48.48043, lng: 18.533399 },
    { lat: 48.480363, lng: 18.533377 },
    { lat: 48.480331, lng: 18.533356 },
    { lat: 48.480276, lng: 18.533314 },
    { lat: 48.480227, lng: 18.533277 },
    { lat: 48.480109, lng: 18.533175 },
    { lat: 48.480101, lng: 18.533165 },
    { lat: 48.479982, lng: 18.533231 },
    { lat: 48.479923, lng: 18.533277 },
    { lat: 48.47986, lng: 18.53333 },
    { lat: 48.479855, lng: 18.53333 },
    { lat: 48.479747, lng: 18.533282 },
    { lat: 48.479729, lng: 18.533273 },
    { lat: 48.479629, lng: 18.533259 },
    { lat: 48.479563, lng: 18.53325 },
    { lat: 48.479534, lng: 18.533248 },
    { lat: 48.479512, lng: 18.533236 },
    { lat: 48.479378, lng: 18.533238 },
    { lat: 48.479313, lng: 18.533259 },
    { lat: 48.47928, lng: 18.533272 },
    { lat: 48.479247, lng: 18.533285 },
    { lat: 48.479189, lng: 18.533303 },
    { lat: 48.479044, lng: 18.533327 },
    { lat: 48.478984, lng: 18.533286 },
    { lat: 48.478959, lng: 18.533287 },
    { lat: 48.478741, lng: 18.533345 },
    { lat: 48.478725, lng: 18.533357 },
    { lat: 48.478693, lng: 18.533375 },
    { lat: 48.478612, lng: 18.533434 },
    { lat: 48.4784631, lng: 18.5334063 },
    { lat: 48.478214, lng: 18.53336 },
    { lat: 48.478112, lng: 18.533316 },
    { lat: 48.478057, lng: 18.533292 },
    { lat: 48.477771, lng: 18.533162 },
    { lat: 48.477682, lng: 18.533066 },
    { lat: 48.477397, lng: 18.532766 },
    { lat: 48.477384, lng: 18.532746 },
    { lat: 48.477232, lng: 18.532497 },
    { lat: 48.477222, lng: 18.53248 },
    { lat: 48.477177, lng: 18.532483 },
    { lat: 48.477113, lng: 18.532494 },
    { lat: 48.477057, lng: 18.532502 },
    { lat: 48.477007, lng: 18.532513 },
    { lat: 48.476996, lng: 18.5325 },
    { lat: 48.476948, lng: 18.532459 },
    { lat: 48.476903, lng: 18.532419 },
    { lat: 48.476861, lng: 18.53238 },
    { lat: 48.476814, lng: 18.532341 },
    { lat: 48.476638, lng: 18.532184 },
    { lat: 48.476624, lng: 18.532177 },
    { lat: 48.476613, lng: 18.532174 },
    { lat: 48.476516, lng: 18.532158 },
    { lat: 48.476492, lng: 18.532154 },
    { lat: 48.476407, lng: 18.532128 },
    { lat: 48.476366, lng: 18.532121 },
    { lat: 48.476356, lng: 18.532114 },
    { lat: 48.476213, lng: 18.532023 },
    { lat: 48.476197, lng: 18.532018 },
    { lat: 48.476078, lng: 18.532003 },
    { lat: 48.476018, lng: 18.531995 },
    { lat: 48.475908, lng: 18.53199 },
    { lat: 48.475689, lng: 18.53208 },
    { lat: 48.475579, lng: 18.532134 },
    { lat: 48.47554, lng: 18.532114 },
    { lat: 48.475446, lng: 18.532036 },
    { lat: 48.475392, lng: 18.531994 },
    { lat: 48.475333, lng: 18.531948 },
    { lat: 48.475281, lng: 18.531909 },
    { lat: 48.475234, lng: 18.531857 },
    { lat: 48.475183, lng: 18.531803 },
    { lat: 48.475131, lng: 18.531748 },
    { lat: 48.475082, lng: 18.531699 },
    { lat: 48.475031, lng: 18.531646 },
    { lat: 48.475021, lng: 18.531635 },
    { lat: 48.474924, lng: 18.531584 },
    { lat: 48.474693, lng: 18.531468 },
    { lat: 48.474625, lng: 18.531377 },
    { lat: 48.474434, lng: 18.531339 },
    { lat: 48.474411, lng: 18.531325 },
    { lat: 48.474395, lng: 18.531317 },
    { lat: 48.474267, lng: 18.531202 },
    { lat: 48.474118, lng: 18.531065 },
    { lat: 48.474123, lng: 18.531114 },
    { lat: 48.474148, lng: 18.531311 },
    { lat: 48.474097, lng: 18.531326 },
    { lat: 48.474044, lng: 18.531394 },
    { lat: 48.474054, lng: 18.531683 },
    { lat: 48.474077, lng: 18.531832 },
    { lat: 48.474079, lng: 18.532045 },
    { lat: 48.474088, lng: 18.532674 },
    { lat: 48.474185, lng: 18.532925 },
    { lat: 48.474233, lng: 18.533098 },
    { lat: 48.474263, lng: 18.53332 },
    { lat: 48.474327, lng: 18.533558 },
    { lat: 48.47441, lng: 18.533689 },
    { lat: 48.474448, lng: 18.533778 },
    { lat: 48.474673, lng: 18.534206 },
    { lat: 48.474757, lng: 18.534389 },
    { lat: 48.474789, lng: 18.534536 },
    { lat: 48.474812, lng: 18.534652 },
    { lat: 48.474819, lng: 18.53473 },
    { lat: 48.474829, lng: 18.53494 },
    { lat: 48.474828, lng: 18.535149 },
    { lat: 48.474829, lng: 18.535554 },
    { lat: 48.474837, lng: 18.535836 },
    { lat: 48.474876, lng: 18.535963 },
    { lat: 48.474955, lng: 18.536139 },
    { lat: 48.474867, lng: 18.536522 },
    { lat: 48.474867, lng: 18.536835 },
    { lat: 48.474906, lng: 18.537151 },
    { lat: 48.474883, lng: 18.537396 },
    { lat: 48.474872, lng: 18.537474 },
    { lat: 48.474884, lng: 18.537651 },
    { lat: 48.474941, lng: 18.537812 },
    { lat: 48.474956, lng: 18.538104 },
    { lat: 48.474998, lng: 18.538346 },
    { lat: 48.475003, lng: 18.538363 },
    { lat: 48.475011, lng: 18.538383 },
    { lat: 48.475037, lng: 18.538465 },
    { lat: 48.475048, lng: 18.538495 },
    { lat: 48.47506, lng: 18.538542 },
    { lat: 48.47507, lng: 18.538567 },
    { lat: 48.475074, lng: 18.538589 },
    { lat: 48.47509, lng: 18.538641 },
    { lat: 48.475097, lng: 18.538664 },
    { lat: 48.475113, lng: 18.538714 },
    { lat: 48.475133, lng: 18.538786 },
    { lat: 48.475152, lng: 18.53885 },
    { lat: 48.475199, lng: 18.538969 },
    { lat: 48.475251, lng: 18.539029 },
    { lat: 48.475281, lng: 18.539111 },
    { lat: 48.475405, lng: 18.539306 },
    { lat: 48.475411, lng: 18.539326 },
    { lat: 48.47526, lng: 18.539343 },
    { lat: 48.475228, lng: 18.539346 },
    { lat: 48.475122, lng: 18.539323 },
    { lat: 48.475167, lng: 18.539431 },
    { lat: 48.475266, lng: 18.53968 },
    { lat: 48.475305, lng: 18.539789 },
    { lat: 48.475449, lng: 18.540182 },
    { lat: 48.475545, lng: 18.54034 },
    { lat: 48.47557, lng: 18.54036 },
    { lat: 48.475597, lng: 18.540386 },
    { lat: 48.476011, lng: 18.540777 },
    { lat: 48.476287, lng: 18.541355 },
    { lat: 48.476313, lng: 18.541449 },
    { lat: 48.476259, lng: 18.541697 },
    { lat: 48.476189, lng: 18.541701 },
    { lat: 48.476299, lng: 18.542573 },
    { lat: 48.476345, lng: 18.542725 },
    { lat: 48.476483, lng: 18.542972 },
    { lat: 48.476655, lng: 18.543207 },
    { lat: 48.476771, lng: 18.543303 },
    { lat: 48.476956, lng: 18.54342 },
    { lat: 48.477171, lng: 18.543709 },
    { lat: 48.477314, lng: 18.544001 },
    { lat: 48.477475, lng: 18.544323 },
    { lat: 48.477576, lng: 18.544457 },
    { lat: 48.477593, lng: 18.544567 },
    { lat: 48.477617, lng: 18.544711 },
    { lat: 48.477631, lng: 18.5448 },
    { lat: 48.477653, lng: 18.544925 },
    { lat: 48.477503, lng: 18.54501 },
    { lat: 48.477351, lng: 18.545032 },
    { lat: 48.477211, lng: 18.545149 },
    { lat: 48.477134, lng: 18.545308 },
    { lat: 48.47709, lng: 18.545505 },
    { lat: 48.47713, lng: 18.545812 },
    { lat: 48.477165, lng: 18.545984 },
    { lat: 48.477168, lng: 18.546093 },
    { lat: 48.477092, lng: 18.546262 },
    { lat: 48.477019, lng: 18.546433 },
    { lat: 48.476962, lng: 18.546591 },
    { lat: 48.4772615, lng: 18.5463554 },
    { lat: 48.4774687, lng: 18.5460389 },
    { lat: 48.4777299, lng: 18.5451246 },
    { lat: 48.4788051, lng: 18.5449409 },
    { lat: 48.4792225, lng: 18.5451093 },
    { lat: 48.4793393, lng: 18.5452217 },
    { lat: 48.4793445, lng: 18.5453996 },
    { lat: 48.4795201, lng: 18.5455374 },
    { lat: 48.4795971, lng: 18.5454792 },
    { lat: 48.4801238, lng: 18.5457482 },
    { lat: 48.4809934, lng: 18.5458481 },
    { lat: 48.4813954, lng: 18.5461229 },
    { lat: 48.481713, lng: 18.5464245 },
    { lat: 48.482698, lng: 18.5470069 },
    { lat: 48.4834194, lng: 18.5480479 },
    { lat: 48.4839243, lng: 18.54973 },
    { lat: 48.4842503, lng: 18.5502483 },
    { lat: 48.4847503, lng: 18.5519226 },
    { lat: 48.4848959, lng: 18.5520255 },
    { lat: 48.4848559, lng: 18.5524695 },
    { lat: 48.4850475, lng: 18.5532345 },
    { lat: 48.4851321, lng: 18.5534195 },
    { lat: 48.4851561, lng: 18.5537117 },
    { lat: 48.4849769, lng: 18.5547266 },
    { lat: 48.4849183, lng: 18.5547061 },
    { lat: 48.4850666, lng: 18.5557502 },
    { lat: 48.4849259, lng: 18.5569946 },
    { lat: 48.4849798, lng: 18.5571547 },
    { lat: 48.4848994, lng: 18.5576635 },
    { lat: 48.4847587, lng: 18.5577705 },
    { lat: 48.4847974, lng: 18.5579851 },
    { lat: 48.4847645, lng: 18.5581242 },
    { lat: 48.4845214, lng: 18.5586499 },
    { lat: 48.4846563, lng: 18.5591794 },
    { lat: 48.484613, lng: 18.5598329 },
    { lat: 48.4843945, lng: 18.5602724 },
    { lat: 48.4838731, lng: 18.5607009 },
    { lat: 48.484016, lng: 18.5611892 },
    { lat: 48.4840215, lng: 18.5617645 },
    { lat: 48.4839463, lng: 18.5620195 },
    { lat: 48.4835483, lng: 18.5624359 },
    { lat: 48.4835039, lng: 18.5630483 },
    { lat: 48.4833228, lng: 18.5633057 },
    { lat: 48.4831487, lng: 18.5638153 },
    { lat: 48.4829939, lng: 18.5639973 },
    { lat: 48.4827422, lng: 18.5645091 },
    { lat: 48.4827578, lng: 18.5650241 },
    { lat: 48.482626, lng: 18.5652942 },
    { lat: 48.4822722, lng: 18.5656757 },
    { lat: 48.4817138, lng: 18.5660457 },
    { lat: 48.4815107, lng: 18.5657992 },
    { lat: 48.4806691, lng: 18.5658565 },
    { lat: 48.4804313, lng: 18.5666435 },
    { lat: 48.4801056, lng: 18.566528 },
    { lat: 48.4797983, lng: 18.5651925 },
    { lat: 48.4790874, lng: 18.5648565 },
    { lat: 48.4773719, lng: 18.5650058 },
    { lat: 48.4769991, lng: 18.5652196 },
    { lat: 48.4765508, lng: 18.5652955 },
    { lat: 48.4758111, lng: 18.5651341 },
    { lat: 48.4756554, lng: 18.5647612 },
    { lat: 48.4755438, lng: 18.5641035 },
    { lat: 48.4755354, lng: 18.5632873 },
    { lat: 48.4756784, lng: 18.5622923 },
    { lat: 48.4752891, lng: 18.5618975 },
    { lat: 48.4749481, lng: 18.5617046 },
    { lat: 48.4749105, lng: 18.5621711 },
    { lat: 48.4751104, lng: 18.5622175 },
    { lat: 48.4752484, lng: 18.5623511 },
    { lat: 48.475385, lng: 18.5629912 },
    { lat: 48.4751826, lng: 18.5638082 },
    { lat: 48.475311, lng: 18.5648574 },
    { lat: 48.4755366, lng: 18.5653564 },
    { lat: 48.475645, lng: 18.5654596 },
    { lat: 48.4759566, lng: 18.5655296 },
    { lat: 48.4762747, lng: 18.5657157 },
    { lat: 48.4767018, lng: 18.5658109 },
    { lat: 48.477208, lng: 18.5652887 },
    { lat: 48.4775765, lng: 18.5652198 },
    { lat: 48.477945, lng: 18.5653804 },
    { lat: 48.4786835, lng: 18.5650824 },
    { lat: 48.4789173, lng: 18.5651321 },
    { lat: 48.4797919, lng: 18.5665052 },
    { lat: 48.4797842, lng: 18.5671265 },
    { lat: 48.4803489, lng: 18.567968 },
    { lat: 48.480336, lng: 18.5681427 },
    { lat: 48.4811901, lng: 18.567963 },
    { lat: 48.481554, lng: 18.567622 },
    { lat: 48.4820431, lng: 18.5677198 },
    { lat: 48.4824628, lng: 18.5679908 },
    { lat: 48.4828015, lng: 18.5684496 },
    { lat: 48.4828367, lng: 18.5686618 },
    { lat: 48.4828194, lng: 18.5693768 },
    { lat: 48.4826466, lng: 18.5696235 },
    { lat: 48.4828261, lng: 18.5700789 },
    { lat: 48.4827827, lng: 18.5702986 },
    { lat: 48.4825269, lng: 18.5708599 },
    { lat: 48.482498, lng: 18.5711973 },
    { lat: 48.4829093, lng: 18.5712536 },
    { lat: 48.4829352, lng: 18.5714609 },
    { lat: 48.4826856, lng: 18.5722621 },
    { lat: 48.4806817, lng: 18.5763261 },
    { lat: 48.4804049, lng: 18.5766685 },
    { lat: 48.4802766, lng: 18.5771287 },
    { lat: 48.4796962, lng: 18.5780069 },
    { lat: 48.4794669, lng: 18.5796001 },
    { lat: 48.4790152, lng: 18.5819526 },
    { lat: 48.4791826, lng: 18.5842155 },
    { lat: 48.4793852, lng: 18.5852457 },
    { lat: 48.4792159, lng: 18.5874831 },
    { lat: 48.4793272, lng: 18.5888264 },
    { lat: 48.4790895, lng: 18.5908834 },
    { lat: 48.4784051, lng: 18.5945648 },
    { lat: 48.477867, lng: 18.5950184 },
    { lat: 48.477867, lng: 18.5963675 },
    { lat: 48.4782681, lng: 18.597378 },
    { lat: 48.4785671, lng: 18.5975041 },
    { lat: 48.4791564, lng: 18.5974537 },
    { lat: 48.4798697, lng: 18.5975226 },
    { lat: 48.4814005, lng: 18.5979819 },
    { lat: 48.4845047, lng: 18.6009978 },
    { lat: 48.4862399, lng: 18.6021718 },
    { lat: 48.4885898, lng: 18.6023687 },
    { lat: 48.4895888, lng: 18.6026514 },
    { lat: 48.4903737, lng: 18.602357 },
    { lat: 48.4910192, lng: 18.6021145 },
    { lat: 48.4905818, lng: 18.6007864 },
    { lat: 48.4909938, lng: 18.60019 },
    { lat: 48.4908932, lng: 18.5998962 },
    { lat: 48.49081, lng: 18.5998403 },
    { lat: 48.4909384, lng: 18.5995664 },
    { lat: 48.4909935, lng: 18.5991887 },
    { lat: 48.490975, lng: 18.5988902 },
    { lat: 48.4910321, lng: 18.5987356 },
    { lat: 48.4908905, lng: 18.5984522 },
    { lat: 48.4908611, lng: 18.598225 },
    { lat: 48.4911769, lng: 18.5977247 },
    { lat: 48.4911249, lng: 18.5975159 },
    { lat: 48.4911425, lng: 18.597278 },
    { lat: 48.4913613, lng: 18.5968639 },
    { lat: 48.4913904, lng: 18.5964859 },
    { lat: 48.4918248, lng: 18.5952402 },
    { lat: 48.4930825, lng: 18.593021 },
    { lat: 48.4934199, lng: 18.5925223 },
    { lat: 48.4934104, lng: 18.5919125 },
    { lat: 48.4933351, lng: 18.591484 },
    { lat: 48.494307, lng: 18.5908307 },
    { lat: 48.4945338, lng: 18.590625 },
    { lat: 48.4947313, lng: 18.5902967 },
    { lat: 48.4950212, lng: 18.5884915 },
    { lat: 48.4955675, lng: 18.5880231 },
    { lat: 48.495885, lng: 18.5878841 },
    { lat: 48.4970616, lng: 18.5871622 },
    { lat: 48.4973263, lng: 18.5864709 },
    { lat: 48.4976747, lng: 18.5861983 },
    { lat: 48.4988407, lng: 18.5850341 },
    { lat: 48.4998107, lng: 18.5844079 },
    { lat: 48.499933, lng: 18.5842747 },
    { lat: 48.500638, lng: 18.5841694 },
    { lat: 48.5007947, lng: 18.5842078 },
    { lat: 48.5012288, lng: 18.5838335 },
    { lat: 48.5017809, lng: 18.5835941 },
    { lat: 48.5023343, lng: 18.5829046 },
    { lat: 48.5035679, lng: 18.5819221 },
    { lat: 48.5043602, lng: 18.5809983 },
    { lat: 48.5045618, lng: 18.5808276 },
    { lat: 48.5056946, lng: 18.5804188 },
    { lat: 48.5060857, lng: 18.5806004 },
    { lat: 48.5063081, lng: 18.5802301 },
    { lat: 48.5068022, lng: 18.5803751 },
    { lat: 48.5079235, lng: 18.5804803 },
    { lat: 48.5091351, lng: 18.5807148 },
    { lat: 48.5097219, lng: 18.5799538 },
    { lat: 48.5103767, lng: 18.5792559 },
    { lat: 48.510522, lng: 18.5790064 },
    { lat: 48.5106588, lng: 18.5783066 },
    { lat: 48.5110871, lng: 18.5772652 },
    { lat: 48.5120976, lng: 18.5743025 },
    { lat: 48.5125426, lng: 18.5729417 },
    { lat: 48.5125568, lng: 18.5727087 },
    { lat: 48.512563, lng: 18.5726749 },
    { lat: 48.5125078, lng: 18.5726771 },
    { lat: 48.512504, lng: 18.5725897 },
    { lat: 48.5125589, lng: 18.5720614 },
    { lat: 48.5128318, lng: 18.5714222 },
    { lat: 48.5131057, lng: 18.5710661 },
    { lat: 48.5134993, lng: 18.5699648 },
    { lat: 48.5138196, lng: 18.5693675 },
    { lat: 48.5150609, lng: 18.5666268 },
    { lat: 48.5155168, lng: 18.565923 },
    { lat: 48.5159296, lng: 18.5649331 },
    { lat: 48.5165664, lng: 18.5644869 },
    { lat: 48.5172312, lng: 18.5628813 },
    { lat: 48.5185602, lng: 18.5579701 },
    { lat: 48.5194694, lng: 18.555039 },
    { lat: 48.5195082, lng: 18.5523443 },
    { lat: 48.5197101, lng: 18.5503599 },
    { lat: 48.5204262, lng: 18.5473439 },
    { lat: 48.5210185, lng: 18.5442749 },
    { lat: 48.5205993, lng: 18.5433537 },
    { lat: 48.5204621, lng: 18.5427203 },
    { lat: 48.5200672, lng: 18.5417499 },
    { lat: 48.5199217, lng: 18.5409268 },
    { lat: 48.5198433, lng: 18.5394574 },
    { lat: 48.5199403, lng: 18.53859 },
    { lat: 48.5200775, lng: 18.5378414 },
    { lat: 48.5203267, lng: 18.5353063 },
    { lat: 48.5206106, lng: 18.5343733 },
    { lat: 48.5213944, lng: 18.5334035 },
    { lat: 48.5215585, lng: 18.5319128 },
    { lat: 48.5219714, lng: 18.5311915 },
    { lat: 48.5224763, lng: 18.5299897 },
    { lat: 48.5224627, lng: 18.5298662 },
    { lat: 48.5227684, lng: 18.52972 },
    { lat: 48.5235541, lng: 18.5297327 },
    { lat: 48.5240803, lng: 18.5294961 },
    { lat: 48.5249217, lng: 18.5287105 },
    { lat: 48.5264399, lng: 18.5264257 },
    { lat: 48.5279663, lng: 18.5243127 },
    { lat: 48.529041, lng: 18.5232612 },
    { lat: 48.5303462, lng: 18.5207364 },
    { lat: 48.5303983, lng: 18.5198939 },
    { lat: 48.5289628, lng: 18.5184263 },
    { lat: 48.528946, lng: 18.518049 },
  ],
  TekovskáBreznica: [
    { lat: 48.344924, lng: 18.605851 },
    { lat: 48.344909, lng: 18.60589 },
    { lat: 48.344146, lng: 18.607969 },
    { lat: 48.343846, lng: 18.610535 },
    { lat: 48.343795, lng: 18.612215 },
    { lat: 48.343788, lng: 18.612465 },
    { lat: 48.343784, lng: 18.612639 },
    { lat: 48.344245, lng: 18.613742 },
    { lat: 48.344587, lng: 18.614559 },
    { lat: 48.345494, lng: 18.61641 },
    { lat: 48.345669, lng: 18.616522 },
    { lat: 48.346865, lng: 18.617232 },
    { lat: 48.347538, lng: 18.617554 },
    { lat: 48.348141, lng: 18.621209 },
    { lat: 48.348683, lng: 18.622986 },
    { lat: 48.348631, lng: 18.623582 },
    { lat: 48.347891, lng: 18.62432 },
    { lat: 48.34756, lng: 18.625414 },
    { lat: 48.347681, lng: 18.626551 },
    { lat: 48.348461, lng: 18.628704 },
    { lat: 48.349767, lng: 18.630695 },
    { lat: 48.351597, lng: 18.632018 },
    { lat: 48.35334, lng: 18.63284 },
    { lat: 48.353586, lng: 18.632709 },
    { lat: 48.353822, lng: 18.633187 },
    { lat: 48.353886, lng: 18.634946 },
    { lat: 48.353825, lng: 18.636293 },
    { lat: 48.353504, lng: 18.637363 },
    { lat: 48.352673, lng: 18.63911 },
    { lat: 48.352936, lng: 18.640512 },
    { lat: 48.352711, lng: 18.642951 },
    { lat: 48.351476, lng: 18.646224 },
    { lat: 48.351019, lng: 18.652486 },
    { lat: 48.350884, lng: 18.65247 },
    { lat: 48.350754, lng: 18.652535 },
    { lat: 48.350659, lng: 18.652672 },
    { lat: 48.350571, lng: 18.652709 },
    { lat: 48.350472, lng: 18.652702 },
    { lat: 48.350398, lng: 18.65277 },
    { lat: 48.350209, lng: 18.652779 },
    { lat: 48.350143, lng: 18.652878 },
    { lat: 48.350061, lng: 18.652853 },
    { lat: 48.349918, lng: 18.652991 },
    { lat: 48.349828, lng: 18.653045 },
    { lat: 48.349731, lng: 18.652983 },
    { lat: 48.349646, lng: 18.653007 },
    { lat: 48.349404, lng: 18.652997 },
    { lat: 48.349295, lng: 18.652921 },
    { lat: 48.349095, lng: 18.652704 },
    { lat: 48.349005, lng: 18.652791 },
    { lat: 48.348797, lng: 18.652761 },
    { lat: 48.348713, lng: 18.652874 },
    { lat: 48.348386, lng: 18.653038 },
    { lat: 48.348205, lng: 18.653056 },
    { lat: 48.348074, lng: 18.652907 },
    { lat: 48.347839, lng: 18.652959 },
    { lat: 48.347676, lng: 18.653032 },
    { lat: 48.347459, lng: 18.65319 },
    { lat: 48.347316, lng: 18.653164 },
    { lat: 48.347056, lng: 18.653034 },
    { lat: 48.34688, lng: 18.653239 },
    { lat: 48.346753, lng: 18.653104 },
    { lat: 48.346612, lng: 18.653121 },
    { lat: 48.346487, lng: 18.652923 },
    { lat: 48.346374, lng: 18.652957 },
    { lat: 48.346216, lng: 18.652771 },
    { lat: 48.346087, lng: 18.65284 },
    { lat: 48.345909, lng: 18.652574 },
    { lat: 48.345799, lng: 18.652571 },
    { lat: 48.345715, lng: 18.65251 },
    { lat: 48.345627, lng: 18.652568 },
    { lat: 48.345584, lng: 18.652711 },
    { lat: 48.345503, lng: 18.652789 },
    { lat: 48.345307, lng: 18.652774 },
    { lat: 48.345181, lng: 18.652793 },
    { lat: 48.3455401, lng: 18.6534859 },
    { lat: 48.345803, lng: 18.653993 },
    { lat: 48.346811, lng: 18.655931 },
    { lat: 48.347988, lng: 18.658022 },
    { lat: 48.349803, lng: 18.660403 },
    { lat: 48.35086, lng: 18.662298 },
    { lat: 48.35139, lng: 18.663568 },
    { lat: 48.351519, lng: 18.663872 },
    { lat: 48.35302, lng: 18.666266 },
    { lat: 48.353063, lng: 18.666445 },
    { lat: 48.353234, lng: 18.667216 },
    { lat: 48.353247, lng: 18.667267 },
    { lat: 48.353373, lng: 18.667848 },
    { lat: 48.353548, lng: 18.668645 },
    { lat: 48.354045, lng: 18.671073 },
    { lat: 48.354876, lng: 18.674268 },
    { lat: 48.355072, lng: 18.675544 },
    { lat: 48.356434, lng: 18.676364 },
    { lat: 48.356644, lng: 18.676497 },
    { lat: 48.357261, lng: 18.676838 },
    { lat: 48.357405, lng: 18.676917 },
    { lat: 48.358246, lng: 18.677348 },
    { lat: 48.358747, lng: 18.677623 },
    { lat: 48.359528, lng: 18.678039 },
    { lat: 48.360131, lng: 18.678365 },
    { lat: 48.359529, lng: 18.679434 },
    { lat: 48.359352, lng: 18.679665 },
    { lat: 48.359926, lng: 18.679997 },
    { lat: 48.360803, lng: 18.680312 },
    { lat: 48.360904, lng: 18.680336 },
    { lat: 48.362199, lng: 18.681074 },
    { lat: 48.36388, lng: 18.681167 },
    { lat: 48.365719, lng: 18.680785 },
    { lat: 48.365976, lng: 18.680763 },
    { lat: 48.366423, lng: 18.680645 },
    { lat: 48.366454, lng: 18.680637 },
    { lat: 48.367624, lng: 18.680328 },
    { lat: 48.368302, lng: 18.680193 },
    { lat: 48.368975, lng: 18.680074 },
    { lat: 48.369143, lng: 18.680036 },
    { lat: 48.371204, lng: 18.679566 },
    { lat: 48.373171, lng: 18.679177 },
    { lat: 48.373666, lng: 18.679059 },
    { lat: 48.373737, lng: 18.679042 },
    { lat: 48.373832, lng: 18.679272 },
    { lat: 48.3740988, lng: 18.6791414 },
    { lat: 48.3744995, lng: 18.6790635 },
    { lat: 48.3751051, lng: 18.6790828 },
    { lat: 48.3757994, lng: 18.6789052 },
    { lat: 48.3765132, lng: 18.6784009 },
    { lat: 48.3766264, lng: 18.6782827 },
    { lat: 48.376752, lng: 18.6781515 },
    { lat: 48.3768909, lng: 18.6780065 },
    { lat: 48.3769404, lng: 18.6779548 },
    { lat: 48.3773978, lng: 18.6775904 },
    { lat: 48.3774844, lng: 18.6775638 },
    { lat: 48.3780086, lng: 18.6774028 },
    { lat: 48.3780687, lng: 18.6773844 },
    { lat: 48.3790944, lng: 18.6770694 },
    { lat: 48.3794245, lng: 18.6768521 },
    { lat: 48.3799702, lng: 18.6762342 },
    { lat: 48.3800508, lng: 18.676143 },
    { lat: 48.3803406, lng: 18.6760036 },
    { lat: 48.3804026, lng: 18.6759738 },
    { lat: 48.3812861, lng: 18.6757501 },
    { lat: 48.3815341, lng: 18.6754565 },
    { lat: 48.3816754, lng: 18.6755217 },
    { lat: 48.3817514, lng: 18.6754678 },
    { lat: 48.3821171, lng: 18.6748302 },
    { lat: 48.3822815, lng: 18.6745436 },
    { lat: 48.3827119, lng: 18.6741381 },
    { lat: 48.3829, lng: 18.6738836 },
    { lat: 48.3832946, lng: 18.6732345 },
    { lat: 48.3835458, lng: 18.6728212 },
    { lat: 48.3840333, lng: 18.6716718 },
    { lat: 48.3843897, lng: 18.6706502 },
    { lat: 48.3844886, lng: 18.6703666 },
    { lat: 48.3845153, lng: 18.6702693 },
    { lat: 48.3848468, lng: 18.6690603 },
    { lat: 48.384892, lng: 18.6688958 },
    { lat: 48.3850212, lng: 18.6684244 },
    { lat: 48.3850454, lng: 18.6683363 },
    { lat: 48.3852138, lng: 18.6677222 },
    { lat: 48.3854055, lng: 18.6674053 },
    { lat: 48.3856106, lng: 18.6670663 },
    { lat: 48.3855913, lng: 18.6667499 },
    { lat: 48.3855826, lng: 18.6666074 },
    { lat: 48.3858844, lng: 18.6657636 },
    { lat: 48.3858977, lng: 18.6656739 },
    { lat: 48.3859473, lng: 18.6653387 },
    { lat: 48.3858994, lng: 18.6649918 },
    { lat: 48.3859103, lng: 18.6649618 },
    { lat: 48.3860682, lng: 18.6645251 },
    { lat: 48.3862117, lng: 18.6641282 },
    { lat: 48.3862608, lng: 18.6636407 },
    { lat: 48.3862286, lng: 18.6632004 },
    { lat: 48.3862269, lng: 18.6631765 },
    { lat: 48.3860947, lng: 18.6626049 },
    { lat: 48.3861688, lng: 18.6623088 },
    { lat: 48.3861788, lng: 18.662236 },
    { lat: 48.3863887, lng: 18.6607113 },
    { lat: 48.3862936, lng: 18.6599507 },
    { lat: 48.3860818, lng: 18.6590361 },
    { lat: 48.3858292, lng: 18.6583667 },
    { lat: 48.3857153, lng: 18.6579054 },
    { lat: 48.3857502, lng: 18.6574218 },
    { lat: 48.3858855, lng: 18.6568745 },
    { lat: 48.3860726, lng: 18.6564122 },
    { lat: 48.3863952, lng: 18.655807 },
    { lat: 48.386656, lng: 18.6548482 },
    { lat: 48.3867746, lng: 18.6542855 },
    { lat: 48.3868562, lng: 18.6529322 },
    { lat: 48.3873638, lng: 18.6512216 },
    { lat: 48.3875968, lng: 18.6500544 },
    { lat: 48.3876384, lng: 18.649846 },
    { lat: 48.3876569, lng: 18.6497534 },
    { lat: 48.3879288, lng: 18.6494177 },
    { lat: 48.3882852, lng: 18.6486249 },
    { lat: 48.3883691, lng: 18.6484903 },
    { lat: 48.3884575, lng: 18.6483485 },
    { lat: 48.3885414, lng: 18.6480412 },
    { lat: 48.3885612, lng: 18.6478079 },
    { lat: 48.388622, lng: 18.64709 },
    { lat: 48.3886806, lng: 18.6469559 },
    { lat: 48.3888117, lng: 18.6466557 },
    { lat: 48.3890544, lng: 18.6464278 },
    { lat: 48.3891283, lng: 18.6461675 },
    { lat: 48.3892136, lng: 18.6460972 },
    { lat: 48.3893743, lng: 18.6461919 },
    { lat: 48.3894664, lng: 18.6460984 },
    { lat: 48.3895463, lng: 18.6457615 },
    { lat: 48.389509, lng: 18.6449871 },
    { lat: 48.3896354, lng: 18.6439238 },
    { lat: 48.3895996, lng: 18.6436971 },
    { lat: 48.3897779, lng: 18.6432613 },
    { lat: 48.389808, lng: 18.642944 },
    { lat: 48.3901164, lng: 18.6428454 },
    { lat: 48.3904521, lng: 18.6421079 },
    { lat: 48.3904879, lng: 18.6421852 },
    { lat: 48.3907433, lng: 18.6419848 },
    { lat: 48.3917777, lng: 18.6420704 },
    { lat: 48.3919204, lng: 18.6419782 },
    { lat: 48.3920315, lng: 18.6414224 },
    { lat: 48.3924623, lng: 18.6410672 },
    { lat: 48.392773, lng: 18.6411007 },
    { lat: 48.3931124, lng: 18.6408074 },
    { lat: 48.3934224, lng: 18.6406951 },
    { lat: 48.3936291, lng: 18.6407023 },
    { lat: 48.3941381, lng: 18.6408752 },
    { lat: 48.394167, lng: 18.6403859 },
    { lat: 48.3944375, lng: 18.6403849 },
    { lat: 48.3949825, lng: 18.6400938 },
    { lat: 48.3950601, lng: 18.6399648 },
    { lat: 48.3955245, lng: 18.6398152 },
    { lat: 48.3955723, lng: 18.6392387 },
    { lat: 48.3957311, lng: 18.638775 },
    { lat: 48.3960589, lng: 18.6390588 },
    { lat: 48.3960494, lng: 18.6395696 },
    { lat: 48.3969822, lng: 18.6397776 },
    { lat: 48.3972337, lng: 18.6398012 },
    { lat: 48.3975059, lng: 18.6397028 },
    { lat: 48.3977746, lng: 18.6397893 },
    { lat: 48.3984405, lng: 18.6401117 },
    { lat: 48.3989509, lng: 18.6405823 },
    { lat: 48.3992578, lng: 18.6410255 },
    { lat: 48.3991569, lng: 18.6413679 },
    { lat: 48.3996421, lng: 18.6424798 },
    { lat: 48.4001678, lng: 18.6430266 },
    { lat: 48.400687, lng: 18.6434507 },
    { lat: 48.40082, lng: 18.643677 },
    { lat: 48.4006507, lng: 18.6443248 },
    { lat: 48.4010841, lng: 18.6444147 },
    { lat: 48.4012977, lng: 18.644213 },
    { lat: 48.4024851, lng: 18.6439368 },
    { lat: 48.4028724, lng: 18.6435894 },
    { lat: 48.4033214, lng: 18.6430665 },
    { lat: 48.4031549, lng: 18.6429732 },
    { lat: 48.4032159, lng: 18.642828 },
    { lat: 48.4031366, lng: 18.642651 },
    { lat: 48.4034255, lng: 18.6423198 },
    { lat: 48.4035789, lng: 18.6420491 },
    { lat: 48.4036163, lng: 18.6416551 },
    { lat: 48.4038846, lng: 18.6415287 },
    { lat: 48.4039112, lng: 18.6411641 },
    { lat: 48.4041478, lng: 18.641326 },
    { lat: 48.4041254, lng: 18.6414749 },
    { lat: 48.4042261, lng: 18.6415031 },
    { lat: 48.4042098, lng: 18.6416375 },
    { lat: 48.4043049, lng: 18.641627 },
    { lat: 48.4044355, lng: 18.6417471 },
    { lat: 48.4044601, lng: 18.6410292 },
    { lat: 48.4043889, lng: 18.6396928 },
    { lat: 48.4044438, lng: 18.6396645 },
    { lat: 48.4045078, lng: 18.639492 },
    { lat: 48.4046215, lng: 18.639405 },
    { lat: 48.4045337, lng: 18.6366282 },
    { lat: 48.4047406, lng: 18.6366175 },
    { lat: 48.4048376, lng: 18.6365361 },
    { lat: 48.4048538, lng: 18.6363325 },
    { lat: 48.4046749, lng: 18.6360263 },
    { lat: 48.4044252, lng: 18.6350628 },
    { lat: 48.4044669, lng: 18.6343297 },
    { lat: 48.404439, lng: 18.633249 },
    { lat: 48.4045013, lng: 18.632731 },
    { lat: 48.404443, lng: 18.6309668 },
    { lat: 48.4042927, lng: 18.6306046 },
    { lat: 48.4040013, lng: 18.6294727 },
    { lat: 48.4036233, lng: 18.6275941 },
    { lat: 48.4032937, lng: 18.6265262 },
    { lat: 48.4021894, lng: 18.6265037 },
    { lat: 48.4015068, lng: 18.6261263 },
    { lat: 48.400962, lng: 18.6247301 },
    { lat: 48.3999907, lng: 18.6230172 },
    { lat: 48.3993642, lng: 18.6224672 },
    { lat: 48.3987527, lng: 18.6206306 },
    { lat: 48.398353, lng: 18.6205602 },
    { lat: 48.3975131, lng: 18.6206315 },
    { lat: 48.3966435, lng: 18.6209837 },
    { lat: 48.3958859, lng: 18.6212009 },
    { lat: 48.3952038, lng: 18.6212277 },
    { lat: 48.3942869, lng: 18.6208943 },
    { lat: 48.394026, lng: 18.6206377 },
    { lat: 48.3938327, lng: 18.6201726 },
    { lat: 48.3937062, lng: 18.6196699 },
    { lat: 48.3937148, lng: 18.6191826 },
    { lat: 48.3940122, lng: 18.617265 },
    { lat: 48.3942198, lng: 18.6165159 },
    { lat: 48.3947167, lng: 18.614029 },
    { lat: 48.3947043, lng: 18.6137175 },
    { lat: 48.3946404, lng: 18.6134553 },
    { lat: 48.3943384, lng: 18.6128367 },
    { lat: 48.3935181, lng: 18.6119149 },
    { lat: 48.393241, lng: 18.6117799 },
    { lat: 48.3925628, lng: 18.6117731 },
    { lat: 48.3922459, lng: 18.6116482 },
    { lat: 48.3920052, lng: 18.6114757 },
    { lat: 48.3911824, lng: 18.6106959 },
    { lat: 48.3906796, lng: 18.6098071 },
    { lat: 48.3905154, lng: 18.6096291 },
    { lat: 48.3904339, lng: 18.6096207 },
    { lat: 48.3895712, lng: 18.6089207 },
    { lat: 48.3885065, lng: 18.6085708 },
    { lat: 48.3873389, lng: 18.607922 },
    { lat: 48.3870124, lng: 18.6078719 },
    { lat: 48.385808, lng: 18.6081041 },
    { lat: 48.3853497, lng: 18.6080959 },
    { lat: 48.3841989, lng: 18.6083177 },
    { lat: 48.3829709, lng: 18.6084516 },
    { lat: 48.3825168, lng: 18.608542 },
    { lat: 48.3819013, lng: 18.6088195 },
    { lat: 48.381666, lng: 18.6088263 },
    { lat: 48.3813487, lng: 18.6087761 },
    { lat: 48.3802512, lng: 18.6083221 },
    { lat: 48.3795038, lng: 18.6079349 },
    { lat: 48.3789973, lng: 18.6075609 },
    { lat: 48.3787238, lng: 18.6075085 },
    { lat: 48.3784395, lng: 18.6076951 },
    { lat: 48.3782256, lng: 18.6080133 },
    { lat: 48.3780892, lng: 18.608096 },
    { lat: 48.3772107, lng: 18.6085139 },
    { lat: 48.3770561, lng: 18.6084921 },
    { lat: 48.3764132, lng: 18.6077263 },
    { lat: 48.3758582, lng: 18.6067303 },
    { lat: 48.3756555, lng: 18.6057603 },
    { lat: 48.3744634, lng: 18.604888 },
    { lat: 48.3738426, lng: 18.6039476 },
    { lat: 48.373665, lng: 18.6035234 },
    { lat: 48.3733908, lng: 18.6025874 },
    { lat: 48.3733265, lng: 18.6019237 },
    { lat: 48.3728707, lng: 18.600343 },
    { lat: 48.3723724, lng: 18.5988176 },
    { lat: 48.3722123, lng: 18.5985463 },
    { lat: 48.3720992, lng: 18.598166 },
    { lat: 48.3721083, lng: 18.5978169 },
    { lat: 48.3723009, lng: 18.5973988 },
    { lat: 48.3724026, lng: 18.5961961 },
    { lat: 48.3724393, lng: 18.5950688 },
    { lat: 48.3722533, lng: 18.5944304 },
    { lat: 48.3717834, lng: 18.5937952 },
    { lat: 48.3706434, lng: 18.5924982 },
    { lat: 48.3702222, lng: 18.5918688 },
    { lat: 48.3698079, lng: 18.5911133 },
    { lat: 48.3692603, lng: 18.592057 },
    { lat: 48.3687902, lng: 18.5928692 },
    { lat: 48.3673704, lng: 18.5926052 },
    { lat: 48.3667362, lng: 18.5923744 },
    { lat: 48.364793, lng: 18.5952599 },
    { lat: 48.3643149, lng: 18.5967937 },
    { lat: 48.3639912, lng: 18.5958197 },
    { lat: 48.3637795, lng: 18.5946663 },
    { lat: 48.3636217, lng: 18.5943376 },
    { lat: 48.3632998, lng: 18.5931279 },
    { lat: 48.3625785, lng: 18.5914397 },
    { lat: 48.3619772, lng: 18.5902607 },
    { lat: 48.3612245, lng: 18.5890733 },
    { lat: 48.3606704, lng: 18.5887168 },
    { lat: 48.3604556, lng: 18.588436 },
    { lat: 48.3595952, lng: 18.5877205 },
    { lat: 48.359509, lng: 18.5874828 },
    { lat: 48.3591843, lng: 18.5874028 },
    { lat: 48.3588284, lng: 18.5871205 },
    { lat: 48.3586343, lng: 18.5868301 },
    { lat: 48.3583484, lng: 18.5865928 },
    { lat: 48.3578923, lng: 18.5860051 },
    { lat: 48.3576669, lng: 18.5855788 },
    { lat: 48.357145, lng: 18.5850438 },
    { lat: 48.3570083, lng: 18.5847535 },
    { lat: 48.3566899, lng: 18.5845184 },
    { lat: 48.3562247, lng: 18.5840326 },
    { lat: 48.3560539, lng: 18.5835265 },
    { lat: 48.3552604, lng: 18.5820327 },
    { lat: 48.3550617, lng: 18.581428 },
    { lat: 48.3546798, lng: 18.580878 },
    { lat: 48.3541737, lng: 18.579994 },
    { lat: 48.3524574, lng: 18.5782059 },
    { lat: 48.3519652, lng: 18.5778482 },
    { lat: 48.3517427, lng: 18.5776188 },
    { lat: 48.3513114, lng: 18.5773587 },
    { lat: 48.3510564, lng: 18.5770262 },
    { lat: 48.3504683, lng: 18.5766496 },
    { lat: 48.3500522, lng: 18.5765053 },
    { lat: 48.3487711, lng: 18.5757923 },
    { lat: 48.34821, lng: 18.5753254 },
    { lat: 48.347894, lng: 18.575202 },
    { lat: 48.347782, lng: 18.575366 },
    { lat: 48.347749, lng: 18.575467 },
    { lat: 48.347472, lng: 18.575746 },
    { lat: 48.347335, lng: 18.575871 },
    { lat: 48.347293, lng: 18.5759 },
    { lat: 48.347128, lng: 18.575913 },
    { lat: 48.347045, lng: 18.575994 },
    { lat: 48.346842, lng: 18.576402 },
    { lat: 48.346547, lng: 18.576779 },
    { lat: 48.346211, lng: 18.577411 },
    { lat: 48.346174, lng: 18.577493 },
    { lat: 48.346115, lng: 18.577717 },
    { lat: 48.345952, lng: 18.578088 },
    { lat: 48.34575, lng: 18.578199 },
    { lat: 48.345514, lng: 18.578591 },
    { lat: 48.345434, lng: 18.578836 },
    { lat: 48.345385, lng: 18.578917 },
    { lat: 48.345387, lng: 18.579155 },
    { lat: 48.345344, lng: 18.579274 },
    { lat: 48.345154, lng: 18.579377 },
    { lat: 48.345065, lng: 18.579513 },
    { lat: 48.344974, lng: 18.579847 },
    { lat: 48.344757, lng: 18.580191 },
    { lat: 48.344729, lng: 18.580411 },
    { lat: 48.344665, lng: 18.580501 },
    { lat: 48.344717, lng: 18.580761 },
    { lat: 48.344662, lng: 18.580961 },
    { lat: 48.344673, lng: 18.58118 },
    { lat: 48.344723, lng: 18.581513 },
    { lat: 48.34469, lng: 18.582166 },
    { lat: 48.344736, lng: 18.582354 },
    { lat: 48.344819, lng: 18.582467 },
    { lat: 48.344866, lng: 18.582591 },
    { lat: 48.344899, lng: 18.583047 },
    { lat: 48.344919, lng: 18.583236 },
    { lat: 48.344929, lng: 18.583888 },
    { lat: 48.344823, lng: 18.584057 },
    { lat: 48.344882, lng: 18.584325 },
    { lat: 48.344889, lng: 18.584545 },
    { lat: 48.344983, lng: 18.584966 },
    { lat: 48.345003, lng: 18.585092 },
    { lat: 48.345005, lng: 18.585175 },
    { lat: 48.345056, lng: 18.585271 },
    { lat: 48.345081, lng: 18.585431 },
    { lat: 48.345157, lng: 18.585581 },
    { lat: 48.345176, lng: 18.585934 },
    { lat: 48.345264, lng: 18.586091 },
    { lat: 48.34525, lng: 18.586266 },
    { lat: 48.345403, lng: 18.586493 },
    { lat: 48.345452, lng: 18.586694 },
    { lat: 48.345554, lng: 18.586863 },
    { lat: 48.345605, lng: 18.587058 },
    { lat: 48.345563, lng: 18.587198 },
    { lat: 48.345563, lng: 18.587256 },
    { lat: 48.345668, lng: 18.587528 },
    { lat: 48.345698, lng: 18.58783 },
    { lat: 48.345594, lng: 18.588163 },
    { lat: 48.345563, lng: 18.588551 },
    { lat: 48.345523, lng: 18.588972 },
    { lat: 48.345487, lng: 18.589137 },
    { lat: 48.345471, lng: 18.589395 },
    { lat: 48.34541, lng: 18.589626 },
    { lat: 48.345365, lng: 18.589859 },
    { lat: 48.345352, lng: 18.59015 },
    { lat: 48.345399, lng: 18.590332 },
    { lat: 48.345361, lng: 18.590574 },
    { lat: 48.345265, lng: 18.590822 },
    { lat: 48.345174, lng: 18.591385 },
    { lat: 48.345216, lng: 18.591599 },
    { lat: 48.345164, lng: 18.59195 },
    { lat: 48.345221, lng: 18.592273 },
    { lat: 48.344909, lng: 18.592712 },
    { lat: 48.344692, lng: 18.593393 },
    { lat: 48.344552, lng: 18.593613 },
    { lat: 48.344478, lng: 18.593698 },
    { lat: 48.344384, lng: 18.594016 },
    { lat: 48.344393, lng: 18.59423 },
    { lat: 48.344273, lng: 18.594516 },
    { lat: 48.344172, lng: 18.594651 },
    { lat: 48.344006, lng: 18.595174 },
    { lat: 48.343797, lng: 18.595986 },
    { lat: 48.343664, lng: 18.596177 },
    { lat: 48.343623, lng: 18.596346 },
    { lat: 48.343753, lng: 18.59652 },
    { lat: 48.343745, lng: 18.596649 },
    { lat: 48.343743, lng: 18.596701 },
    { lat: 48.343506, lng: 18.601083 },
    { lat: 48.344153, lng: 18.603835 },
    { lat: 48.344946, lng: 18.60579 },
    { lat: 48.344924, lng: 18.605851 },
  ],
  NováBaňa: [
    { lat: 48.4910192, lng: 18.6021145 },
    { lat: 48.4903737, lng: 18.602357 },
    { lat: 48.4895888, lng: 18.6026514 },
    { lat: 48.4885898, lng: 18.6023687 },
    { lat: 48.4862399, lng: 18.6021718 },
    { lat: 48.4845047, lng: 18.6009978 },
    { lat: 48.4814005, lng: 18.5979819 },
    { lat: 48.4798697, lng: 18.5975226 },
    { lat: 48.4791564, lng: 18.5974537 },
    { lat: 48.4785671, lng: 18.5975041 },
    { lat: 48.4782681, lng: 18.597378 },
    { lat: 48.4777601, lng: 18.5971628 },
    { lat: 48.475298, lng: 18.5962548 },
    { lat: 48.4746398, lng: 18.5958678 },
    { lat: 48.4723348, lng: 18.5936741 },
    { lat: 48.4718441, lng: 18.5937565 },
    { lat: 48.4715564, lng: 18.5933436 },
    { lat: 48.4711863, lng: 18.592943 },
    { lat: 48.4706126, lng: 18.5927039 },
    { lat: 48.4699486, lng: 18.5919186 },
    { lat: 48.468586, lng: 18.5909462 },
    { lat: 48.467927, lng: 18.5905449 },
    { lat: 48.4669255, lng: 18.5900489 },
    { lat: 48.4649484, lng: 18.5896799 },
    { lat: 48.4638609, lng: 18.5893893 },
    { lat: 48.4634168, lng: 18.5895374 },
    { lat: 48.462574, lng: 18.5893471 },
    { lat: 48.4619187, lng: 18.5890801 },
    { lat: 48.4616709, lng: 18.5890636 },
    { lat: 48.4612649, lng: 18.5887745 },
    { lat: 48.4604226, lng: 18.5885284 },
    { lat: 48.4597687, lng: 18.5878316 },
    { lat: 48.4594009, lng: 18.5875186 },
    { lat: 48.4591763, lng: 18.5871813 },
    { lat: 48.4585157, lng: 18.586652 },
    { lat: 48.4582544, lng: 18.5862413 },
    { lat: 48.4576732, lng: 18.585864 },
    { lat: 48.4569012, lng: 18.5852341 },
    { lat: 48.4565864, lng: 18.5843403 },
    { lat: 48.4562586, lng: 18.5836845 },
    { lat: 48.4556428, lng: 18.5826932 },
    { lat: 48.4554072, lng: 18.5824692 },
    { lat: 48.4552397, lng: 18.5821053 },
    { lat: 48.4549959, lng: 18.5817917 },
    { lat: 48.4541923, lng: 18.580416 },
    { lat: 48.4538506, lng: 18.5793214 },
    { lat: 48.4536259, lng: 18.5782415 },
    { lat: 48.4532086, lng: 18.5775587 },
    { lat: 48.4526113, lng: 18.5762014 },
    { lat: 48.4523244, lng: 18.5757596 },
    { lat: 48.4509145, lng: 18.5748101 },
    { lat: 48.4506295, lng: 18.5746899 },
    { lat: 48.450173, lng: 18.5739737 },
    { lat: 48.4484985, lng: 18.5721222 },
    { lat: 48.4474141, lng: 18.5707107 },
    { lat: 48.4472327, lng: 18.5704435 },
    { lat: 48.4468451, lng: 18.5696265 },
    { lat: 48.4454502, lng: 18.5680825 },
    { lat: 48.4442304, lng: 18.5672701 },
    { lat: 48.4433287, lng: 18.5660193 },
    { lat: 48.4425337, lng: 18.565156 },
    { lat: 48.4417234, lng: 18.5638701 },
    { lat: 48.4413563, lng: 18.5634891 },
    { lat: 48.4409888, lng: 18.5631622 },
    { lat: 48.4405283, lng: 18.562949 },
    { lat: 48.4396743, lng: 18.5626811 },
    { lat: 48.4390741, lng: 18.5607989 },
    { lat: 48.4388284, lng: 18.5604328 },
    { lat: 48.4382611, lng: 18.5601032 },
    { lat: 48.4359993, lng: 18.5579336 },
    { lat: 48.4354655, lng: 18.5579068 },
    { lat: 48.4347021, lng: 18.5562218 },
    { lat: 48.4340585, lng: 18.5557564 },
    { lat: 48.4331854, lng: 18.5553035 },
    { lat: 48.4323663, lng: 18.5546584 },
    { lat: 48.4315947, lng: 18.5535475 },
    { lat: 48.4302726, lng: 18.5522682 },
    { lat: 48.4300722, lng: 18.5521306 },
    { lat: 48.4289984, lng: 18.5509773 },
    { lat: 48.4286109, lng: 18.5507606 },
    { lat: 48.4263679, lng: 18.5481966 },
    { lat: 48.426201, lng: 18.5480536 },
    { lat: 48.4261475, lng: 18.5481042 },
    { lat: 48.4259382, lng: 18.5475789 },
    { lat: 48.4256687, lng: 18.5474961 },
    { lat: 48.4254012, lng: 18.5476198 },
    { lat: 48.4245043, lng: 18.5476987 },
    { lat: 48.4239784, lng: 18.5476732 },
    { lat: 48.4231057, lng: 18.5470826 },
    { lat: 48.4227299, lng: 18.5467516 },
    { lat: 48.4219238, lng: 18.545689 },
    { lat: 48.4212683, lng: 18.5438776 },
    { lat: 48.4207761, lng: 18.5427159 },
    { lat: 48.419638, lng: 18.5409718 },
    { lat: 48.4192914, lng: 18.5399572 },
    { lat: 48.418479, lng: 18.5392933 },
    { lat: 48.4176835, lng: 18.5393918 },
    { lat: 48.417438, lng: 18.539336 },
    { lat: 48.417458, lng: 18.5404 },
    { lat: 48.417466, lng: 18.540744 },
    { lat: 48.417491, lng: 18.542027 },
    { lat: 48.417431, lng: 18.54294 },
    { lat: 48.417398, lng: 18.543464 },
    { lat: 48.417413, lng: 18.544385 },
    { lat: 48.417419, lng: 18.544767 },
    { lat: 48.41743, lng: 18.545533 },
    { lat: 48.417447, lng: 18.546509 },
    { lat: 48.417448, lng: 18.546623 },
    { lat: 48.41746, lng: 18.547398 },
    { lat: 48.417467, lng: 18.547783 },
    { lat: 48.417477, lng: 18.548394 },
    { lat: 48.41749, lng: 18.549209 },
    { lat: 48.417498, lng: 18.549817 },
    { lat: 48.417503, lng: 18.550076 },
    { lat: 48.417614, lng: 18.551163 },
    { lat: 48.417615, lng: 18.551172 },
    { lat: 48.417661, lng: 18.551621 },
    { lat: 48.417715, lng: 18.552141 },
    { lat: 48.417856, lng: 18.554173 },
    { lat: 48.417966, lng: 18.556751 },
    { lat: 48.41797, lng: 18.556814 },
    { lat: 48.418081, lng: 18.557931 },
    { lat: 48.418151, lng: 18.559091 },
    { lat: 48.418203, lng: 18.56065 },
    { lat: 48.417713, lng: 18.560875 },
    { lat: 48.417679, lng: 18.56089 },
    { lat: 48.417147, lng: 18.56115 },
    { lat: 48.416695, lng: 18.561372 },
    { lat: 48.416491, lng: 18.561465 },
    { lat: 48.415755, lng: 18.5618 },
    { lat: 48.415542, lng: 18.561897 },
    { lat: 48.414891, lng: 18.562365 },
    { lat: 48.413973, lng: 18.562697 },
    { lat: 48.413285, lng: 18.563232 },
    { lat: 48.412916, lng: 18.56374 },
    { lat: 48.4132604, lng: 18.5650088 },
    { lat: 48.4133363, lng: 18.5652884 },
    { lat: 48.413478, lng: 18.5661266 },
    { lat: 48.4133985, lng: 18.5668167 },
    { lat: 48.4140269, lng: 18.5690308 },
    { lat: 48.41433, lng: 18.5694994 },
    { lat: 48.4143812, lng: 18.5697963 },
    { lat: 48.4145212, lng: 18.5701707 },
    { lat: 48.4146254, lng: 18.5708175 },
    { lat: 48.414588, lng: 18.5716405 },
    { lat: 48.4145186, lng: 18.572083 },
    { lat: 48.4145679, lng: 18.5729472 },
    { lat: 48.4140739, lng: 18.5742304 },
    { lat: 48.413979, lng: 18.5754657 },
    { lat: 48.4135115, lng: 18.5765326 },
    { lat: 48.4133135, lng: 18.5771417 },
    { lat: 48.4126762, lng: 18.5783226 },
    { lat: 48.4122648, lng: 18.5794444 },
    { lat: 48.4114983, lng: 18.5805708 },
    { lat: 48.4113259, lng: 18.5809253 },
    { lat: 48.4111796, lng: 18.5808904 },
    { lat: 48.4111465, lng: 18.5811338 },
    { lat: 48.411006, lng: 18.5812008 },
    { lat: 48.4107912, lng: 18.5815059 },
    { lat: 48.4099045, lng: 18.5833855 },
    { lat: 48.4098252, lng: 18.583834 },
    { lat: 48.4098484, lng: 18.5844248 },
    { lat: 48.4097884, lng: 18.58502 },
    { lat: 48.4096075, lng: 18.5855524 },
    { lat: 48.409233, lng: 18.5863802 },
    { lat: 48.4095894, lng: 18.5871466 },
    { lat: 48.4098485, lng: 18.5878638 },
    { lat: 48.4099648, lng: 18.5887364 },
    { lat: 48.4102366, lng: 18.5895657 },
    { lat: 48.41048, lng: 18.5900382 },
    { lat: 48.4108146, lng: 18.5905279 },
    { lat: 48.4111135, lng: 18.5911902 },
    { lat: 48.4113014, lng: 18.5914938 },
    { lat: 48.4107619, lng: 18.5928346 },
    { lat: 48.4101808, lng: 18.5938652 },
    { lat: 48.4097221, lng: 18.5952435 },
    { lat: 48.4093335, lng: 18.5959168 },
    { lat: 48.4091904, lng: 18.596437 },
    { lat: 48.4087036, lng: 18.5969433 },
    { lat: 48.4080994, lng: 18.5972631 },
    { lat: 48.4073507, lng: 18.5978047 },
    { lat: 48.4069421, lng: 18.5981541 },
    { lat: 48.4068233, lng: 18.5984388 },
    { lat: 48.4073834, lng: 18.5996109 },
    { lat: 48.4079661, lng: 18.6012211 },
    { lat: 48.4081539, lng: 18.6018765 },
    { lat: 48.4085724, lng: 18.6025228 },
    { lat: 48.4086578, lng: 18.6032426 },
    { lat: 48.40857, lng: 18.6040762 },
    { lat: 48.4084856, lng: 18.6042439 },
    { lat: 48.408434, lng: 18.6045218 },
    { lat: 48.4073392, lng: 18.6064199 },
    { lat: 48.4066415, lng: 18.6076967 },
    { lat: 48.4062946, lng: 18.6084332 },
    { lat: 48.4058743, lng: 18.6108723 },
    { lat: 48.4057812, lng: 18.6116518 },
    { lat: 48.4052821, lng: 18.6128865 },
    { lat: 48.4051627, lng: 18.6133693 },
    { lat: 48.4048755, lng: 18.6141226 },
    { lat: 48.4042271, lng: 18.6151284 },
    { lat: 48.4038443, lng: 18.6154528 },
    { lat: 48.4035732, lng: 18.6160287 },
    { lat: 48.4032995, lng: 18.6164309 },
    { lat: 48.4028966, lng: 18.6167368 },
    { lat: 48.4026445, lng: 18.6168301 },
    { lat: 48.4021267, lng: 18.6173726 },
    { lat: 48.4016958, lng: 18.6182875 },
    { lat: 48.4014957, lng: 18.6190041 },
    { lat: 48.4012758, lng: 18.6191723 },
    { lat: 48.4012006, lng: 18.6194505 },
    { lat: 48.3998156, lng: 18.6185138 },
    { lat: 48.3986175, lng: 18.617595 },
    { lat: 48.3984425, lng: 18.6180931 },
    { lat: 48.3985863, lng: 18.6184755 },
    { lat: 48.398816, lng: 18.6186803 },
    { lat: 48.3988166, lng: 18.618873 },
    { lat: 48.3986251, lng: 18.6193606 },
    { lat: 48.3985996, lng: 18.6195753 },
    { lat: 48.3987527, lng: 18.6206306 },
    { lat: 48.3993642, lng: 18.6224672 },
    { lat: 48.3999907, lng: 18.6230172 },
    { lat: 48.400962, lng: 18.6247301 },
    { lat: 48.4015068, lng: 18.6261263 },
    { lat: 48.4021894, lng: 18.6265037 },
    { lat: 48.4032937, lng: 18.6265262 },
    { lat: 48.4036233, lng: 18.6275941 },
    { lat: 48.4040013, lng: 18.6294727 },
    { lat: 48.4042927, lng: 18.6306046 },
    { lat: 48.404443, lng: 18.6309668 },
    { lat: 48.4045013, lng: 18.632731 },
    { lat: 48.404439, lng: 18.633249 },
    { lat: 48.4044669, lng: 18.6343297 },
    { lat: 48.4044252, lng: 18.6350628 },
    { lat: 48.4046749, lng: 18.6360263 },
    { lat: 48.4048538, lng: 18.6363325 },
    { lat: 48.4057766, lng: 18.6363776 },
    { lat: 48.4062858, lng: 18.6367426 },
    { lat: 48.4066059, lng: 18.6370497 },
    { lat: 48.4068953, lng: 18.6382743 },
    { lat: 48.406773, lng: 18.6394145 },
    { lat: 48.4063791, lng: 18.641455 },
    { lat: 48.4063459, lng: 18.641825 },
    { lat: 48.4063927, lng: 18.6418342 },
    { lat: 48.4064212, lng: 18.6420516 },
    { lat: 48.4063629, lng: 18.6420565 },
    { lat: 48.4061772, lng: 18.6431483 },
    { lat: 48.4061585, lng: 18.6446635 },
    { lat: 48.4061961, lng: 18.6448581 },
    { lat: 48.4068854, lng: 18.6465335 },
    { lat: 48.4069531, lng: 18.6465959 },
    { lat: 48.407429, lng: 18.6476413 },
    { lat: 48.4075494, lng: 18.6477612 },
    { lat: 48.4077093, lng: 18.6477602 },
    { lat: 48.4083931, lng: 18.6483256 },
    { lat: 48.4084713, lng: 18.6485234 },
    { lat: 48.4085516, lng: 18.6485484 },
    { lat: 48.4087514, lng: 18.6488031 },
    { lat: 48.4092565, lng: 18.6500147 },
    { lat: 48.4092015, lng: 18.6507303 },
    { lat: 48.4088664, lng: 18.6529245 },
    { lat: 48.4085547, lng: 18.6558474 },
    { lat: 48.4086077, lng: 18.6564367 },
    { lat: 48.4088154, lng: 18.6573788 },
    { lat: 48.4092816, lng: 18.6584578 },
    { lat: 48.4097238, lng: 18.6589795 },
    { lat: 48.4097838, lng: 18.6588824 },
    { lat: 48.4103485, lng: 18.6591468 },
    { lat: 48.4104848, lng: 18.6592918 },
    { lat: 48.4112936, lng: 18.6583302 },
    { lat: 48.4115442, lng: 18.6578702 },
    { lat: 48.4120441, lng: 18.656536 },
    { lat: 48.4125237, lng: 18.6557035 },
    { lat: 48.41301, lng: 18.6554719 },
    { lat: 48.4139179, lng: 18.6551805 },
    { lat: 48.4144615, lng: 18.6554311 },
    { lat: 48.4148732, lng: 18.6557851 },
    { lat: 48.4155953, lng: 18.656651 },
    { lat: 48.415794, lng: 18.6571768 },
    { lat: 48.4159532, lng: 18.6578125 },
    { lat: 48.4174063, lng: 18.6619243 },
    { lat: 48.4175408, lng: 18.6625794 },
    { lat: 48.4178081, lng: 18.6634619 },
    { lat: 48.4180742, lng: 18.6641522 },
    { lat: 48.4186879, lng: 18.6654127 },
    { lat: 48.4191183, lng: 18.6660539 },
    { lat: 48.4198277, lng: 18.6668136 },
    { lat: 48.4228419, lng: 18.668984 },
    { lat: 48.4232412, lng: 18.6690879 },
    { lat: 48.4232753, lng: 18.6689489 },
    { lat: 48.4234663, lng: 18.6681789 },
    { lat: 48.4238973, lng: 18.6684114 },
    { lat: 48.424046, lng: 18.6684066 },
    { lat: 48.4244643, lng: 18.6685746 },
    { lat: 48.4257716, lng: 18.6696891 },
    { lat: 48.4265106, lng: 18.6701765 },
    { lat: 48.4280016, lng: 18.6707202 },
    { lat: 48.4288842, lng: 18.6715106 },
    { lat: 48.4292781, lng: 18.6721028 },
    { lat: 48.4297882, lng: 18.6732232 },
    { lat: 48.4308407, lng: 18.6746561 },
    { lat: 48.4313715, lng: 18.6751533 },
    { lat: 48.4319701, lng: 18.675421 },
    { lat: 48.4325906, lng: 18.6759911 },
    { lat: 48.4329832, lng: 18.6765824 },
    { lat: 48.4332053, lng: 18.6764529 },
    { lat: 48.4334804, lng: 18.6759678 },
    { lat: 48.4337964, lng: 18.6764793 },
    { lat: 48.434006, lng: 18.6769373 },
    { lat: 48.4343696, lng: 18.6774903 },
    { lat: 48.4345991, lng: 18.6781121 },
    { lat: 48.4349569, lng: 18.6791108 },
    { lat: 48.4351527, lng: 18.6795496 },
    { lat: 48.4354772, lng: 18.6802295 },
    { lat: 48.4355912, lng: 18.6808607 },
    { lat: 48.435799, lng: 18.6812468 },
    { lat: 48.4386946, lng: 18.6804956 },
    { lat: 48.4400082, lng: 18.6804488 },
    { lat: 48.4414302, lng: 18.6808432 },
    { lat: 48.441698, lng: 18.6807862 },
    { lat: 48.4421676, lng: 18.680566 },
    { lat: 48.4422006, lng: 18.6805315 },
    { lat: 48.4428383, lng: 18.6798652 },
    { lat: 48.4438732, lng: 18.6793932 },
    { lat: 48.4445142, lng: 18.6786228 },
    { lat: 48.4447346, lng: 18.6784615 },
    { lat: 48.4456352, lng: 18.6781151 },
    { lat: 48.4457497, lng: 18.6780453 },
    { lat: 48.4459332, lng: 18.6779302 },
    { lat: 48.4466576, lng: 18.6768423 },
    { lat: 48.4467452, lng: 18.676479 },
    { lat: 48.447013, lng: 18.6763165 },
    { lat: 48.4470511, lng: 18.6761236 },
    { lat: 48.4473745, lng: 18.6759306 },
    { lat: 48.4476501, lng: 18.6755405 },
    { lat: 48.4476531, lng: 18.6751511 },
    { lat: 48.4480848, lng: 18.6744973 },
    { lat: 48.4484976, lng: 18.6737382 },
    { lat: 48.4488042, lng: 18.6735884 },
    { lat: 48.4489589, lng: 18.6732783 },
    { lat: 48.4491355, lng: 18.6732047 },
    { lat: 48.4492004, lng: 18.6729865 },
    { lat: 48.4491567, lng: 18.6728205 },
    { lat: 48.4506504, lng: 18.6718439 },
    { lat: 48.4517666, lng: 18.6717505 },
    { lat: 48.4523831, lng: 18.6718889 },
    { lat: 48.4527386, lng: 18.6720433 },
    { lat: 48.4532313, lng: 18.6716812 },
    { lat: 48.4535709, lng: 18.6716848 },
    { lat: 48.4538961, lng: 18.6720461 },
    { lat: 48.4540431, lng: 18.6723502 },
    { lat: 48.4544145, lng: 18.6726302 },
    { lat: 48.4546613, lng: 18.672477 },
    { lat: 48.4549934, lng: 18.6718999 },
    { lat: 48.4557326, lng: 18.6711287 },
    { lat: 48.456784, lng: 18.6706078 },
    { lat: 48.4579445, lng: 18.669934 },
    { lat: 48.4586781, lng: 18.6696097 },
    { lat: 48.4590456, lng: 18.6695211 },
    { lat: 48.4594778, lng: 18.6691268 },
    { lat: 48.4605488, lng: 18.6688227 },
    { lat: 48.4614295, lng: 18.668735 },
    { lat: 48.4617702, lng: 18.6685714 },
    { lat: 48.4636056, lng: 18.6685359 },
    { lat: 48.4638763, lng: 18.6684537 },
    { lat: 48.4642508, lng: 18.6688304 },
    { lat: 48.464516, lng: 18.6688963 },
    { lat: 48.4645915, lng: 18.6690446 },
    { lat: 48.4651676, lng: 18.669271 },
    { lat: 48.4655055, lng: 18.6695783 },
    { lat: 48.4658708, lng: 18.6696992 },
    { lat: 48.466462, lng: 18.6700817 },
    { lat: 48.4673412, lng: 18.6700716 },
    { lat: 48.4676931, lng: 18.669966 },
    { lat: 48.4680619, lng: 18.6696298 },
    { lat: 48.4686893, lng: 18.6695302 },
    { lat: 48.4692755, lng: 18.6692949 },
    { lat: 48.4696455, lng: 18.6695261 },
    { lat: 48.4702233, lng: 18.6696394 },
    { lat: 48.4706075, lng: 18.6698839 },
    { lat: 48.4709383, lng: 18.6698085 },
    { lat: 48.4710898, lng: 18.6698349 },
    { lat: 48.4716115, lng: 18.6693838 },
    { lat: 48.4720964, lng: 18.6693605 },
    { lat: 48.4723755, lng: 18.6687608 },
    { lat: 48.4727276, lng: 18.6682627 },
    { lat: 48.4727447, lng: 18.6670258 },
    { lat: 48.4726546, lng: 18.6655987 },
    { lat: 48.4727427, lng: 18.6642167 },
    { lat: 48.4729141, lng: 18.6632707 },
    { lat: 48.4753375, lng: 18.6593601 },
    { lat: 48.4752983, lng: 18.6592589 },
    { lat: 48.4755583, lng: 18.6591445 },
    { lat: 48.4755555, lng: 18.6590716 },
    { lat: 48.4756233, lng: 18.6590086 },
    { lat: 48.4758709, lng: 18.6588761 },
    { lat: 48.4761299, lng: 18.658866 },
    { lat: 48.4764417, lng: 18.6589589 },
    { lat: 48.4768459, lng: 18.6588646 },
    { lat: 48.477237, lng: 18.6588991 },
    { lat: 48.4775575, lng: 18.6587969 },
    { lat: 48.4779922, lng: 18.6585126 },
    { lat: 48.4785245, lng: 18.6577582 },
    { lat: 48.4790075, lng: 18.6566526 },
    { lat: 48.4790418, lng: 18.6566573 },
    { lat: 48.479127, lng: 18.6561684 },
    { lat: 48.4793464, lng: 18.655654 },
    { lat: 48.4799223, lng: 18.6549764 },
    { lat: 48.4806084, lng: 18.6534304 },
    { lat: 48.4807673, lng: 18.6521711 },
    { lat: 48.480698, lng: 18.6513224 },
    { lat: 48.4808342, lng: 18.6504146 },
    { lat: 48.481605, lng: 18.6480977 },
    { lat: 48.4817932, lng: 18.6466506 },
    { lat: 48.4821094, lng: 18.6461147 },
    { lat: 48.4823372, lng: 18.6458424 },
    { lat: 48.4825823, lng: 18.6451583 },
    { lat: 48.4828212, lng: 18.6437708 },
    { lat: 48.4830512, lng: 18.6430562 },
    { lat: 48.4831453, lng: 18.6426057 },
    { lat: 48.4832357, lng: 18.6425904 },
    { lat: 48.4836402, lng: 18.6418457 },
    { lat: 48.4836948, lng: 18.6413107 },
    { lat: 48.4834234, lng: 18.6409023 },
    { lat: 48.4835156, lng: 18.6405817 },
    { lat: 48.4836135, lng: 18.6404745 },
    { lat: 48.483615, lng: 18.6400199 },
    { lat: 48.483822, lng: 18.6395282 },
    { lat: 48.4839883, lng: 18.6384737 },
    { lat: 48.4840377, lng: 18.6383205 },
    { lat: 48.4841284, lng: 18.638228 },
    { lat: 48.4841138, lng: 18.6379966 },
    { lat: 48.4842121, lng: 18.6374346 },
    { lat: 48.4841571, lng: 18.6372298 },
    { lat: 48.4842465, lng: 18.6370909 },
    { lat: 48.4840918, lng: 18.6363973 },
    { lat: 48.484103, lng: 18.6358292 },
    { lat: 48.4841588, lng: 18.6357377 },
    { lat: 48.4841132, lng: 18.6356857 },
    { lat: 48.4840893, lng: 18.6354732 },
    { lat: 48.4841258, lng: 18.6350819 },
    { lat: 48.4840707, lng: 18.634565 },
    { lat: 48.4839074, lng: 18.6341081 },
    { lat: 48.4839228, lng: 18.6332857 },
    { lat: 48.4840012, lng: 18.6332104 },
    { lat: 48.483999, lng: 18.633003 },
    { lat: 48.4841329, lng: 18.63256 },
    { lat: 48.4840901, lng: 18.6324984 },
    { lat: 48.4841938, lng: 18.6316207 },
    { lat: 48.4843047, lng: 18.6310828 },
    { lat: 48.4843342, lng: 18.6305843 },
    { lat: 48.4840937, lng: 18.6295784 },
    { lat: 48.4840332, lng: 18.628875 },
    { lat: 48.4838277, lng: 18.6282855 },
    { lat: 48.4838883, lng: 18.6277362 },
    { lat: 48.4842326, lng: 18.627179 },
    { lat: 48.484257, lng: 18.6270896 },
    { lat: 48.4842256, lng: 18.6269885 },
    { lat: 48.4843696, lng: 18.6269197 },
    { lat: 48.4845397, lng: 18.6266423 },
    { lat: 48.4845596, lng: 18.6268236 },
    { lat: 48.4849318, lng: 18.6263013 },
    { lat: 48.4850434, lng: 18.6258383 },
    { lat: 48.4851645, lng: 18.6256838 },
    { lat: 48.4852726, lng: 18.6257633 },
    { lat: 48.485481, lng: 18.6257798 },
    { lat: 48.4859727, lng: 18.6255451 },
    { lat: 48.4859977, lng: 18.6254262 },
    { lat: 48.48623, lng: 18.6255304 },
    { lat: 48.4868682, lng: 18.6255888 },
    { lat: 48.4874072, lng: 18.6254195 },
    { lat: 48.4874289, lng: 18.6255505 },
    { lat: 48.4875601, lng: 18.6257428 },
    { lat: 48.487708, lng: 18.6258517 },
    { lat: 48.4878291, lng: 18.6258575 },
    { lat: 48.4880036, lng: 18.6262276 },
    { lat: 48.4882722, lng: 18.6266047 },
    { lat: 48.4884963, lng: 18.6267245 },
    { lat: 48.4886825, lng: 18.6265826 },
    { lat: 48.4890681, lng: 18.626746 },
    { lat: 48.4893156, lng: 18.627088 },
    { lat: 48.4896945, lng: 18.6271067 },
    { lat: 48.4901167, lng: 18.6269904 },
    { lat: 48.4903379, lng: 18.626867 },
    { lat: 48.4903881, lng: 18.626629 },
    { lat: 48.4905323, lng: 18.6266245 },
    { lat: 48.4906167, lng: 18.6265381 },
    { lat: 48.4909383, lng: 18.6266236 },
    { lat: 48.4912576, lng: 18.6265184 },
    { lat: 48.4913938, lng: 18.6263051 },
    { lat: 48.4912843, lng: 18.6259064 },
    { lat: 48.4911695, lng: 18.6258721 },
    { lat: 48.4911517, lng: 18.6257313 },
    { lat: 48.4910549, lng: 18.6256706 },
    { lat: 48.490961, lng: 18.6253944 },
    { lat: 48.4909817, lng: 18.6252138 },
    { lat: 48.4909005, lng: 18.6248567 },
    { lat: 48.4907854, lng: 18.6246472 },
    { lat: 48.4907146, lng: 18.624382 },
    { lat: 48.4907109, lng: 18.6241467 },
    { lat: 48.4905861, lng: 18.623779 },
    { lat: 48.4904147, lng: 18.6235515 },
    { lat: 48.4902187, lng: 18.6228639 },
    { lat: 48.4898254, lng: 18.6209102 },
    { lat: 48.4895753, lng: 18.6205425 },
    { lat: 48.489577, lng: 18.6201971 },
    { lat: 48.4894093, lng: 18.6195353 },
    { lat: 48.4892337, lng: 18.6183958 },
    { lat: 48.4892341, lng: 18.6176118 },
    { lat: 48.4890334, lng: 18.6166105 },
    { lat: 48.4887621, lng: 18.6160617 },
    { lat: 48.488476, lng: 18.6155876 },
    { lat: 48.4881499, lng: 18.6152087 },
    { lat: 48.4880998, lng: 18.6150566 },
    { lat: 48.48829, lng: 18.6143997 },
    { lat: 48.4885516, lng: 18.6143374 },
    { lat: 48.4887839, lng: 18.6132938 },
    { lat: 48.4888543, lng: 18.6126992 },
    { lat: 48.4889823, lng: 18.6121473 },
    { lat: 48.4890985, lng: 18.6102532 },
    { lat: 48.4892069, lng: 18.6093169 },
    { lat: 48.4891422, lng: 18.6087303 },
    { lat: 48.4892065, lng: 18.6078761 },
    { lat: 48.4897349, lng: 18.6063425 },
    { lat: 48.4907609, lng: 18.6050478 },
    { lat: 48.4915116, lng: 18.6038719 },
    { lat: 48.491226, lng: 18.6034133 },
    { lat: 48.4910192, lng: 18.6021145 },
  ],
  Brehy: [
    { lat: 48.392306, lng: 18.699891 },
    { lat: 48.392447, lng: 18.699705 },
    { lat: 48.393109, lng: 18.698313 },
    { lat: 48.3940261, lng: 18.6951216 },
    { lat: 48.394348, lng: 18.6943534 },
    { lat: 48.3943286, lng: 18.6936276 },
    { lat: 48.39388, lng: 18.6904772 },
    { lat: 48.3944335, lng: 18.6902822 },
    { lat: 48.3953678, lng: 18.6890706 },
    { lat: 48.3966249, lng: 18.6883023 },
    { lat: 48.3971461, lng: 18.6876729 },
    { lat: 48.3978319, lng: 18.6876543 },
    { lat: 48.3984521, lng: 18.6870647 },
    { lat: 48.3992836, lng: 18.6857422 },
    { lat: 48.3997363, lng: 18.6854753 },
    { lat: 48.4002544, lng: 18.6846428 },
    { lat: 48.4005681, lng: 18.6843103 },
    { lat: 48.4010967, lng: 18.6836078 },
    { lat: 48.401393, lng: 18.6834783 },
    { lat: 48.4018817, lng: 18.6831456 },
    { lat: 48.4022186, lng: 18.6827609 },
    { lat: 48.4028166, lng: 18.6824092 },
    { lat: 48.4030732, lng: 18.6824162 },
    { lat: 48.4033102, lng: 18.6820624 },
    { lat: 48.4034536, lng: 18.6814366 },
    { lat: 48.4036605, lng: 18.6808128 },
    { lat: 48.4048402, lng: 18.6785247 },
    { lat: 48.4059633, lng: 18.6789159 },
    { lat: 48.4072798, lng: 18.6788829 },
    { lat: 48.4078232, lng: 18.6787912 },
    { lat: 48.4103201, lng: 18.6790633 },
    { lat: 48.4111155, lng: 18.6788797 },
    { lat: 48.4116424, lng: 18.6788827 },
    { lat: 48.4120678, lng: 18.6790758 },
    { lat: 48.413211, lng: 18.6798864 },
    { lat: 48.4138749, lng: 18.6800687 },
    { lat: 48.4142334, lng: 18.6799792 },
    { lat: 48.4147412, lng: 18.6800079 },
    { lat: 48.4153489, lng: 18.6802571 },
    { lat: 48.4160982, lng: 18.6800954 },
    { lat: 48.417099, lng: 18.6803982 },
    { lat: 48.4196229, lng: 18.6801818 },
    { lat: 48.4198311, lng: 18.6801108 },
    { lat: 48.4204686, lng: 18.6802005 },
    { lat: 48.421122, lng: 18.6790488 },
    { lat: 48.4225531, lng: 18.6774065 },
    { lat: 48.4232597, lng: 18.6767887 },
    { lat: 48.4233663, lng: 18.6767758 },
    { lat: 48.4237642, lng: 18.6771316 },
    { lat: 48.4240554, lng: 18.6766916 },
    { lat: 48.4242017, lng: 18.6761409 },
    { lat: 48.4251915, lng: 18.6739038 },
    { lat: 48.4248312, lng: 18.6736201 },
    { lat: 48.4228023, lng: 18.6710416 },
    { lat: 48.4227324, lng: 18.6707646 },
    { lat: 48.4232412, lng: 18.6690879 },
    { lat: 48.4228419, lng: 18.668984 },
    { lat: 48.4198277, lng: 18.6668136 },
    { lat: 48.4191183, lng: 18.6660539 },
    { lat: 48.4186879, lng: 18.6654127 },
    { lat: 48.4180742, lng: 18.6641522 },
    { lat: 48.4178081, lng: 18.6634619 },
    { lat: 48.4175408, lng: 18.6625794 },
    { lat: 48.4174063, lng: 18.6619243 },
    { lat: 48.4159532, lng: 18.6578125 },
    { lat: 48.415794, lng: 18.6571768 },
    { lat: 48.4155953, lng: 18.656651 },
    { lat: 48.4148732, lng: 18.6557851 },
    { lat: 48.4144615, lng: 18.6554311 },
    { lat: 48.4139179, lng: 18.6551805 },
    { lat: 48.41301, lng: 18.6554719 },
    { lat: 48.4125237, lng: 18.6557035 },
    { lat: 48.4120441, lng: 18.656536 },
    { lat: 48.4115442, lng: 18.6578702 },
    { lat: 48.4112936, lng: 18.6583302 },
    { lat: 48.4104848, lng: 18.6592918 },
    { lat: 48.4103485, lng: 18.6591468 },
    { lat: 48.4097838, lng: 18.6588824 },
    { lat: 48.4097238, lng: 18.6589795 },
    { lat: 48.4092816, lng: 18.6584578 },
    { lat: 48.4088154, lng: 18.6573788 },
    { lat: 48.4086077, lng: 18.6564367 },
    { lat: 48.4085547, lng: 18.6558474 },
    { lat: 48.4088664, lng: 18.6529245 },
    { lat: 48.4092015, lng: 18.6507303 },
    { lat: 48.4092565, lng: 18.6500147 },
    { lat: 48.4087514, lng: 18.6488031 },
    { lat: 48.4085516, lng: 18.6485484 },
    { lat: 48.4084713, lng: 18.6485234 },
    { lat: 48.4083931, lng: 18.6483256 },
    { lat: 48.4077093, lng: 18.6477602 },
    { lat: 48.4075494, lng: 18.6477612 },
    { lat: 48.407429, lng: 18.6476413 },
    { lat: 48.4069531, lng: 18.6465959 },
    { lat: 48.4068854, lng: 18.6465335 },
    { lat: 48.4061961, lng: 18.6448581 },
    { lat: 48.4061585, lng: 18.6446635 },
    { lat: 48.4061772, lng: 18.6431483 },
    { lat: 48.4063629, lng: 18.6420565 },
    { lat: 48.4064212, lng: 18.6420516 },
    { lat: 48.4063927, lng: 18.6418342 },
    { lat: 48.4063459, lng: 18.641825 },
    { lat: 48.4063791, lng: 18.641455 },
    { lat: 48.406773, lng: 18.6394145 },
    { lat: 48.4068953, lng: 18.6382743 },
    { lat: 48.4066059, lng: 18.6370497 },
    { lat: 48.4062858, lng: 18.6367426 },
    { lat: 48.4057766, lng: 18.6363776 },
    { lat: 48.4048538, lng: 18.6363325 },
    { lat: 48.4048376, lng: 18.6365361 },
    { lat: 48.4047406, lng: 18.6366175 },
    { lat: 48.4045337, lng: 18.6366282 },
    { lat: 48.4046215, lng: 18.639405 },
    { lat: 48.4045078, lng: 18.639492 },
    { lat: 48.4044438, lng: 18.6396645 },
    { lat: 48.4043889, lng: 18.6396928 },
    { lat: 48.4044601, lng: 18.6410292 },
    { lat: 48.4044355, lng: 18.6417471 },
    { lat: 48.4043049, lng: 18.641627 },
    { lat: 48.4042098, lng: 18.6416375 },
    { lat: 48.4042261, lng: 18.6415031 },
    { lat: 48.4041254, lng: 18.6414749 },
    { lat: 48.4041478, lng: 18.641326 },
    { lat: 48.4039112, lng: 18.6411641 },
    { lat: 48.4038846, lng: 18.6415287 },
    { lat: 48.4036163, lng: 18.6416551 },
    { lat: 48.4035789, lng: 18.6420491 },
    { lat: 48.4034255, lng: 18.6423198 },
    { lat: 48.4031366, lng: 18.642651 },
    { lat: 48.4032159, lng: 18.642828 },
    { lat: 48.4031549, lng: 18.6429732 },
    { lat: 48.4033214, lng: 18.6430665 },
    { lat: 48.4028724, lng: 18.6435894 },
    { lat: 48.4024851, lng: 18.6439368 },
    { lat: 48.4012977, lng: 18.644213 },
    { lat: 48.4010841, lng: 18.6444147 },
    { lat: 48.4006507, lng: 18.6443248 },
    { lat: 48.40082, lng: 18.643677 },
    { lat: 48.400687, lng: 18.6434507 },
    { lat: 48.4001678, lng: 18.6430266 },
    { lat: 48.3996421, lng: 18.6424798 },
    { lat: 48.3991569, lng: 18.6413679 },
    { lat: 48.3992578, lng: 18.6410255 },
    { lat: 48.3989509, lng: 18.6405823 },
    { lat: 48.3984405, lng: 18.6401117 },
    { lat: 48.3977746, lng: 18.6397893 },
    { lat: 48.3975059, lng: 18.6397028 },
    { lat: 48.3972337, lng: 18.6398012 },
    { lat: 48.3969822, lng: 18.6397776 },
    { lat: 48.3960494, lng: 18.6395696 },
    { lat: 48.3960589, lng: 18.6390588 },
    { lat: 48.3957311, lng: 18.638775 },
    { lat: 48.3955723, lng: 18.6392387 },
    { lat: 48.3955245, lng: 18.6398152 },
    { lat: 48.3950601, lng: 18.6399648 },
    { lat: 48.3949825, lng: 18.6400938 },
    { lat: 48.3944375, lng: 18.6403849 },
    { lat: 48.394167, lng: 18.6403859 },
    { lat: 48.3941381, lng: 18.6408752 },
    { lat: 48.3936291, lng: 18.6407023 },
    { lat: 48.3934224, lng: 18.6406951 },
    { lat: 48.3931124, lng: 18.6408074 },
    { lat: 48.392773, lng: 18.6411007 },
    { lat: 48.3924623, lng: 18.6410672 },
    { lat: 48.3920315, lng: 18.6414224 },
    { lat: 48.3919204, lng: 18.6419782 },
    { lat: 48.3917777, lng: 18.6420704 },
    { lat: 48.3907433, lng: 18.6419848 },
    { lat: 48.3904879, lng: 18.6421852 },
    { lat: 48.3904521, lng: 18.6421079 },
    { lat: 48.3901164, lng: 18.6428454 },
    { lat: 48.389808, lng: 18.642944 },
    { lat: 48.3897779, lng: 18.6432613 },
    { lat: 48.3895996, lng: 18.6436971 },
    { lat: 48.3896354, lng: 18.6439238 },
    { lat: 48.389509, lng: 18.6449871 },
    { lat: 48.3895463, lng: 18.6457615 },
    { lat: 48.3894664, lng: 18.6460984 },
    { lat: 48.3893743, lng: 18.6461919 },
    { lat: 48.3892136, lng: 18.6460972 },
    { lat: 48.3891283, lng: 18.6461675 },
    { lat: 48.3890544, lng: 18.6464278 },
    { lat: 48.3888117, lng: 18.6466557 },
    { lat: 48.3886806, lng: 18.6469559 },
    { lat: 48.388622, lng: 18.64709 },
    { lat: 48.3885612, lng: 18.6478079 },
    { lat: 48.3885414, lng: 18.6480412 },
    { lat: 48.3884575, lng: 18.6483485 },
    { lat: 48.3883691, lng: 18.6484903 },
    { lat: 48.3882852, lng: 18.6486249 },
    { lat: 48.3879288, lng: 18.6494177 },
    { lat: 48.3876569, lng: 18.6497534 },
    { lat: 48.3876384, lng: 18.649846 },
    { lat: 48.3875968, lng: 18.6500544 },
    { lat: 48.3873638, lng: 18.6512216 },
    { lat: 48.3868562, lng: 18.6529322 },
    { lat: 48.3867746, lng: 18.6542855 },
    { lat: 48.386656, lng: 18.6548482 },
    { lat: 48.3863952, lng: 18.655807 },
    { lat: 48.3860726, lng: 18.6564122 },
    { lat: 48.3858855, lng: 18.6568745 },
    { lat: 48.3857502, lng: 18.6574218 },
    { lat: 48.3857153, lng: 18.6579054 },
    { lat: 48.3858292, lng: 18.6583667 },
    { lat: 48.3860818, lng: 18.6590361 },
    { lat: 48.3862936, lng: 18.6599507 },
    { lat: 48.3863887, lng: 18.6607113 },
    { lat: 48.3861788, lng: 18.662236 },
    { lat: 48.3861688, lng: 18.6623088 },
    { lat: 48.3860947, lng: 18.6626049 },
    { lat: 48.3862269, lng: 18.6631765 },
    { lat: 48.3862286, lng: 18.6632004 },
    { lat: 48.3862608, lng: 18.6636407 },
    { lat: 48.3862117, lng: 18.6641282 },
    { lat: 48.3860682, lng: 18.6645251 },
    { lat: 48.3859103, lng: 18.6649618 },
    { lat: 48.3858994, lng: 18.6649918 },
    { lat: 48.3859473, lng: 18.6653387 },
    { lat: 48.3858977, lng: 18.6656739 },
    { lat: 48.3858844, lng: 18.6657636 },
    { lat: 48.3855826, lng: 18.6666074 },
    { lat: 48.3855913, lng: 18.6667499 },
    { lat: 48.3856106, lng: 18.6670663 },
    { lat: 48.3854055, lng: 18.6674053 },
    { lat: 48.3852138, lng: 18.6677222 },
    { lat: 48.3850454, lng: 18.6683363 },
    { lat: 48.3850212, lng: 18.6684244 },
    { lat: 48.384892, lng: 18.6688958 },
    { lat: 48.3848468, lng: 18.6690603 },
    { lat: 48.3845153, lng: 18.6702693 },
    { lat: 48.3844886, lng: 18.6703666 },
    { lat: 48.3843897, lng: 18.6706502 },
    { lat: 48.3840333, lng: 18.6716718 },
    { lat: 48.3835458, lng: 18.6728212 },
    { lat: 48.3832946, lng: 18.6732345 },
    { lat: 48.3829, lng: 18.6738836 },
    { lat: 48.3827119, lng: 18.6741381 },
    { lat: 48.3822815, lng: 18.6745436 },
    { lat: 48.3821171, lng: 18.6748302 },
    { lat: 48.3817514, lng: 18.6754678 },
    { lat: 48.3816754, lng: 18.6755217 },
    { lat: 48.3815341, lng: 18.6754565 },
    { lat: 48.3812861, lng: 18.6757501 },
    { lat: 48.3804026, lng: 18.6759738 },
    { lat: 48.3803406, lng: 18.6760036 },
    { lat: 48.3800508, lng: 18.676143 },
    { lat: 48.3799702, lng: 18.6762342 },
    { lat: 48.3794245, lng: 18.6768521 },
    { lat: 48.3790944, lng: 18.6770694 },
    { lat: 48.3780687, lng: 18.6773844 },
    { lat: 48.3780086, lng: 18.6774028 },
    { lat: 48.3774844, lng: 18.6775638 },
    { lat: 48.3773978, lng: 18.6775904 },
    { lat: 48.3769404, lng: 18.6779548 },
    { lat: 48.3768909, lng: 18.6780065 },
    { lat: 48.376752, lng: 18.6781515 },
    { lat: 48.3766264, lng: 18.6782827 },
    { lat: 48.3765132, lng: 18.6784009 },
    { lat: 48.3757994, lng: 18.6789052 },
    { lat: 48.3751051, lng: 18.6790828 },
    { lat: 48.3744995, lng: 18.6790635 },
    { lat: 48.3740988, lng: 18.6791414 },
    { lat: 48.373832, lng: 18.679272 },
    { lat: 48.373591, lng: 18.679579 },
    { lat: 48.373554, lng: 18.679626 },
    { lat: 48.373539, lng: 18.679645 },
    { lat: 48.373506, lng: 18.679709 },
    { lat: 48.373236, lng: 18.680171 },
    { lat: 48.373284, lng: 18.680758 },
    { lat: 48.373257, lng: 18.680871 },
    { lat: 48.3733061, lng: 18.6810022 },
    { lat: 48.373321, lng: 18.681042 },
    { lat: 48.373335, lng: 18.681341 },
    { lat: 48.373302, lng: 18.68197 },
    { lat: 48.373235, lng: 18.682122 },
    { lat: 48.373231, lng: 18.682292 },
    { lat: 48.373196, lng: 18.682479 },
    { lat: 48.3732932, lng: 18.6829878 },
    { lat: 48.3733503, lng: 18.6832863 },
    { lat: 48.373372, lng: 18.6834 },
    { lat: 48.373537, lng: 18.683759 },
    { lat: 48.373609, lng: 18.683788 },
    { lat: 48.3736026, lng: 18.6838407 },
    { lat: 48.373583, lng: 18.684001 },
    { lat: 48.3736061, lng: 18.6841133 },
    { lat: 48.37367, lng: 18.684424 },
    { lat: 48.3737099, lng: 18.6845455 },
    { lat: 48.373838, lng: 18.684936 },
    { lat: 48.3740025, lng: 18.6858342 },
    { lat: 48.3741809, lng: 18.6868085 },
    { lat: 48.3742227, lng: 18.6870366 },
    { lat: 48.374231, lng: 18.687082 },
    { lat: 48.374188, lng: 18.687605 },
    { lat: 48.374222, lng: 18.688049 },
    { lat: 48.37429, lng: 18.688239 },
    { lat: 48.374286, lng: 18.688501 },
    { lat: 48.37436, lng: 18.688813 },
    { lat: 48.374476, lng: 18.688945 },
    { lat: 48.374628, lng: 18.689118 },
    { lat: 48.374803, lng: 18.689183 },
    { lat: 48.374978, lng: 18.689248 },
    { lat: 48.375559, lng: 18.689463 },
    { lat: 48.375836, lng: 18.689243 },
    { lat: 48.376124, lng: 18.689035 },
    { lat: 48.376296, lng: 18.68894 },
    { lat: 48.376361, lng: 18.688905 },
    { lat: 48.376457, lng: 18.688937 },
    { lat: 48.376975, lng: 18.689111 },
    { lat: 48.377175, lng: 18.689045 },
    { lat: 48.377297, lng: 18.68899 },
    { lat: 48.377407, lng: 18.688927 },
    { lat: 48.377668, lng: 18.688741 },
    { lat: 48.37808, lng: 18.688703 },
    { lat: 48.378421, lng: 18.688557 },
    { lat: 48.378887, lng: 18.688453 },
    { lat: 48.379167, lng: 18.6885 },
    { lat: 48.379268, lng: 18.688589 },
    { lat: 48.379346, lng: 18.688746 },
    { lat: 48.379501, lng: 18.688748 },
    { lat: 48.38006, lng: 18.688515 },
    { lat: 48.380463, lng: 18.688398 },
    { lat: 48.38073, lng: 18.688422 },
    { lat: 48.381395, lng: 18.688363 },
    { lat: 48.381728, lng: 18.688336 },
    { lat: 48.382189, lng: 18.688893 },
    { lat: 48.38271, lng: 18.689527 },
    { lat: 48.382913, lng: 18.689771 },
    { lat: 48.382932, lng: 18.69001 },
    { lat: 48.382871, lng: 18.690211 },
    { lat: 48.382778, lng: 18.690372 },
    { lat: 48.382814, lng: 18.690999 },
    { lat: 48.382917, lng: 18.691166 },
    { lat: 48.383123, lng: 18.691278 },
    { lat: 48.383335, lng: 18.691826 },
    { lat: 48.383444, lng: 18.692541 },
    { lat: 48.383613, lng: 18.693476 },
    { lat: 48.383794, lng: 18.694313 },
    { lat: 48.383805, lng: 18.69465 },
    { lat: 48.383804, lng: 18.694657 },
    { lat: 48.383763, lng: 18.694851 },
    { lat: 48.383759, lng: 18.694868 },
    { lat: 48.383768, lng: 18.694895 },
    { lat: 48.384071, lng: 18.695821 },
    { lat: 48.384522, lng: 18.695609 },
    { lat: 48.384837, lng: 18.695493 },
    { lat: 48.385532, lng: 18.695311 },
    { lat: 48.385429, lng: 18.695632 },
    { lat: 48.385432, lng: 18.695672 },
    { lat: 48.385434, lng: 18.695702 },
    { lat: 48.385437, lng: 18.695736 },
    { lat: 48.385439, lng: 18.695749 },
    { lat: 48.385462, lng: 18.695788 },
    { lat: 48.385485, lng: 18.695825 },
    { lat: 48.385591, lng: 18.695997 },
    { lat: 48.385714, lng: 18.696194 },
    { lat: 48.386014, lng: 18.69622 },
    { lat: 48.386402, lng: 18.696289 },
    { lat: 48.386888, lng: 18.696309 },
    { lat: 48.386973, lng: 18.696393 },
    { lat: 48.387462, lng: 18.696733 },
    { lat: 48.387928, lng: 18.697308 },
    { lat: 48.388155, lng: 18.697785 },
    { lat: 48.388544, lng: 18.698559 },
    { lat: 48.388711, lng: 18.699758 },
    { lat: 48.388864, lng: 18.700599 },
    { lat: 48.389201, lng: 18.701687 },
    { lat: 48.389672, lng: 18.702528 },
    { lat: 48.389739, lng: 18.702678 },
    { lat: 48.389969, lng: 18.702449 },
    { lat: 48.390188, lng: 18.702499 },
    { lat: 48.390421, lng: 18.702516 },
    { lat: 48.390694, lng: 18.70241 },
    { lat: 48.390932, lng: 18.702249 },
    { lat: 48.391143, lng: 18.701961 },
    { lat: 48.39143, lng: 18.700875 },
    { lat: 48.391911, lng: 18.700198 },
    { lat: 48.392055, lng: 18.700042 },
    { lat: 48.392208, lng: 18.699955 },
    { lat: 48.392277, lng: 18.699915 },
    { lat: 48.392306, lng: 18.699891 },
  ],
  HornéHámre: [
    { lat: 48.5114417, lng: 18.6308098 },
    { lat: 48.5116212, lng: 18.6302073 },
    { lat: 48.511735, lng: 18.6294314 },
    { lat: 48.51203, lng: 18.6286914 },
    { lat: 48.5119552, lng: 18.6280147 },
    { lat: 48.5114505, lng: 18.6286534 },
    { lat: 48.5111122, lng: 18.628322 },
    { lat: 48.5111363, lng: 18.6274735 },
    { lat: 48.5109647, lng: 18.6269876 },
    { lat: 48.5109162, lng: 18.6264265 },
    { lat: 48.5107846, lng: 18.6260769 },
    { lat: 48.5105784, lng: 18.6257912 },
    { lat: 48.5104269, lng: 18.6256365 },
    { lat: 48.5099895, lng: 18.6254724 },
    { lat: 48.5096708, lng: 18.6252741 },
    { lat: 48.5093133, lng: 18.6251873 },
    { lat: 48.5089505, lng: 18.6247984 },
    { lat: 48.5085109, lng: 18.6246374 },
    { lat: 48.5080819, lng: 18.62482 },
    { lat: 48.5071324, lng: 18.6246954 },
    { lat: 48.5067477, lng: 18.6243384 },
    { lat: 48.5064627, lng: 18.6241819 },
    { lat: 48.5064272, lng: 18.6234596 },
    { lat: 48.5061941, lng: 18.6230905 },
    { lat: 48.5058681, lng: 18.6229895 },
    { lat: 48.5055092, lng: 18.623005 },
    { lat: 48.5049224, lng: 18.6231675 },
    { lat: 48.5033164, lng: 18.6230617 },
    { lat: 48.5027393, lng: 18.6248442 },
    { lat: 48.5024412, lng: 18.6255258 },
    { lat: 48.5023994, lng: 18.6257415 },
    { lat: 48.5023764, lng: 18.6259136 },
    { lat: 48.5020378, lng: 18.6271635 },
    { lat: 48.5013033, lng: 18.627742 },
    { lat: 48.5011587, lng: 18.6276989 },
    { lat: 48.5012451, lng: 18.6273554 },
    { lat: 48.5012032, lng: 18.6270604 },
    { lat: 48.5008593, lng: 18.6268506 },
    { lat: 48.5005645, lng: 18.6262579 },
    { lat: 48.5000299, lng: 18.6258652 },
    { lat: 48.4997968, lng: 18.6256185 },
    { lat: 48.499686, lng: 18.6253009 },
    { lat: 48.4996352, lng: 18.6239237 },
    { lat: 48.4993615, lng: 18.622721 },
    { lat: 48.4987888, lng: 18.6216615 },
    { lat: 48.4986753, lng: 18.6212718 },
    { lat: 48.4984103, lng: 18.62069 },
    { lat: 48.4982528, lng: 18.6201538 },
    { lat: 48.4982288, lng: 18.6197716 },
    { lat: 48.4981731, lng: 18.6195978 },
    { lat: 48.4982204, lng: 18.6181168 },
    { lat: 48.4981447, lng: 18.6169899 },
    { lat: 48.4981647, lng: 18.61663 },
    { lat: 48.4979839, lng: 18.6160799 },
    { lat: 48.4979566, lng: 18.6154912 },
    { lat: 48.498205, lng: 18.6142466 },
    { lat: 48.4981933, lng: 18.6138206 },
    { lat: 48.4981362, lng: 18.6134951 },
    { lat: 48.498277, lng: 18.6131812 },
    { lat: 48.4983466, lng: 18.6127989 },
    { lat: 48.498066, lng: 18.6115971 },
    { lat: 48.4979296, lng: 18.6113038 },
    { lat: 48.4979208, lng: 18.6111032 },
    { lat: 48.4977777, lng: 18.6105414 },
    { lat: 48.4973349, lng: 18.6098638 },
    { lat: 48.4971648, lng: 18.6095156 },
    { lat: 48.4962798, lng: 18.6091252 },
    { lat: 48.4954446, lng: 18.6084726 },
    { lat: 48.4952752, lng: 18.6075923 },
    { lat: 48.4948461, lng: 18.606731 },
    { lat: 48.4943382, lng: 18.6062435 },
    { lat: 48.4936627, lng: 18.6067677 },
    { lat: 48.4937193, lng: 18.6069791 },
    { lat: 48.493294, lng: 18.6072905 },
    { lat: 48.4931959, lng: 18.6072503 },
    { lat: 48.4912694, lng: 18.6084776 },
    { lat: 48.4909999, lng: 18.6088513 },
    { lat: 48.4906487, lng: 18.6090352 },
    { lat: 48.4903624, lng: 18.6094963 },
    { lat: 48.4903077, lng: 18.6099823 },
    { lat: 48.490038, lng: 18.6102301 },
    { lat: 48.4896452, lng: 18.6095593 },
    { lat: 48.4895534, lng: 18.6092175 },
    { lat: 48.4891422, lng: 18.6087303 },
    { lat: 48.4892069, lng: 18.6093169 },
    { lat: 48.4890985, lng: 18.6102532 },
    { lat: 48.4889823, lng: 18.6121473 },
    { lat: 48.4888543, lng: 18.6126992 },
    { lat: 48.4887839, lng: 18.6132938 },
    { lat: 48.4885516, lng: 18.6143374 },
    { lat: 48.48829, lng: 18.6143997 },
    { lat: 48.4880998, lng: 18.6150566 },
    { lat: 48.4881499, lng: 18.6152087 },
    { lat: 48.488476, lng: 18.6155876 },
    { lat: 48.4887621, lng: 18.6160617 },
    { lat: 48.4890334, lng: 18.6166105 },
    { lat: 48.4892341, lng: 18.6176118 },
    { lat: 48.4892337, lng: 18.6183958 },
    { lat: 48.4894093, lng: 18.6195353 },
    { lat: 48.489577, lng: 18.6201971 },
    { lat: 48.4895753, lng: 18.6205425 },
    { lat: 48.4898254, lng: 18.6209102 },
    { lat: 48.4902187, lng: 18.6228639 },
    { lat: 48.4904147, lng: 18.6235515 },
    { lat: 48.4905861, lng: 18.623779 },
    { lat: 48.4907109, lng: 18.6241467 },
    { lat: 48.4907146, lng: 18.624382 },
    { lat: 48.4907854, lng: 18.6246472 },
    { lat: 48.4909005, lng: 18.6248567 },
    { lat: 48.4909817, lng: 18.6252138 },
    { lat: 48.490961, lng: 18.6253944 },
    { lat: 48.4910549, lng: 18.6256706 },
    { lat: 48.4911517, lng: 18.6257313 },
    { lat: 48.4911695, lng: 18.6258721 },
    { lat: 48.4912843, lng: 18.6259064 },
    { lat: 48.4913938, lng: 18.6263051 },
    { lat: 48.4912576, lng: 18.6265184 },
    { lat: 48.4909383, lng: 18.6266236 },
    { lat: 48.4906167, lng: 18.6265381 },
    { lat: 48.4905323, lng: 18.6266245 },
    { lat: 48.4903881, lng: 18.626629 },
    { lat: 48.4903379, lng: 18.626867 },
    { lat: 48.4901167, lng: 18.6269904 },
    { lat: 48.4896945, lng: 18.6271067 },
    { lat: 48.4893156, lng: 18.627088 },
    { lat: 48.4890681, lng: 18.626746 },
    { lat: 48.4886825, lng: 18.6265826 },
    { lat: 48.4884963, lng: 18.6267245 },
    { lat: 48.4882722, lng: 18.6266047 },
    { lat: 48.4880036, lng: 18.6262276 },
    { lat: 48.4878291, lng: 18.6258575 },
    { lat: 48.487708, lng: 18.6258517 },
    { lat: 48.4875601, lng: 18.6257428 },
    { lat: 48.4874289, lng: 18.6255505 },
    { lat: 48.4874072, lng: 18.6254195 },
    { lat: 48.4868682, lng: 18.6255888 },
    { lat: 48.48623, lng: 18.6255304 },
    { lat: 48.4859977, lng: 18.6254262 },
    { lat: 48.4859727, lng: 18.6255451 },
    { lat: 48.485481, lng: 18.6257798 },
    { lat: 48.4852726, lng: 18.6257633 },
    { lat: 48.4851645, lng: 18.6256838 },
    { lat: 48.4850434, lng: 18.6258383 },
    { lat: 48.4849318, lng: 18.6263013 },
    { lat: 48.4845596, lng: 18.6268236 },
    { lat: 48.4845397, lng: 18.6266423 },
    { lat: 48.4843696, lng: 18.6269197 },
    { lat: 48.4842256, lng: 18.6269885 },
    { lat: 48.484257, lng: 18.6270896 },
    { lat: 48.4842326, lng: 18.627179 },
    { lat: 48.4838883, lng: 18.6277362 },
    { lat: 48.4838277, lng: 18.6282855 },
    { lat: 48.4840332, lng: 18.628875 },
    { lat: 48.4840937, lng: 18.6295784 },
    { lat: 48.4843342, lng: 18.6305843 },
    { lat: 48.4843047, lng: 18.6310828 },
    { lat: 48.4841938, lng: 18.6316207 },
    { lat: 48.4840901, lng: 18.6324984 },
    { lat: 48.4841329, lng: 18.63256 },
    { lat: 48.483999, lng: 18.633003 },
    { lat: 48.4840012, lng: 18.6332104 },
    { lat: 48.4839228, lng: 18.6332857 },
    { lat: 48.4839074, lng: 18.6341081 },
    { lat: 48.4840707, lng: 18.634565 },
    { lat: 48.4841258, lng: 18.6350819 },
    { lat: 48.4840893, lng: 18.6354732 },
    { lat: 48.4841132, lng: 18.6356857 },
    { lat: 48.4841588, lng: 18.6357377 },
    { lat: 48.484103, lng: 18.6358292 },
    { lat: 48.4840918, lng: 18.6363973 },
    { lat: 48.4842465, lng: 18.6370909 },
    { lat: 48.4841571, lng: 18.6372298 },
    { lat: 48.4842121, lng: 18.6374346 },
    { lat: 48.4841138, lng: 18.6379966 },
    { lat: 48.4841284, lng: 18.638228 },
    { lat: 48.4840377, lng: 18.6383205 },
    { lat: 48.4839883, lng: 18.6384737 },
    { lat: 48.483822, lng: 18.6395282 },
    { lat: 48.483615, lng: 18.6400199 },
    { lat: 48.4836135, lng: 18.6404745 },
    { lat: 48.4835156, lng: 18.6405817 },
    { lat: 48.4834234, lng: 18.6409023 },
    { lat: 48.4836948, lng: 18.6413107 },
    { lat: 48.4836402, lng: 18.6418457 },
    { lat: 48.4832357, lng: 18.6425904 },
    { lat: 48.4831453, lng: 18.6426057 },
    { lat: 48.4830512, lng: 18.6430562 },
    { lat: 48.4828212, lng: 18.6437708 },
    { lat: 48.4825823, lng: 18.6451583 },
    { lat: 48.4823372, lng: 18.6458424 },
    { lat: 48.4821094, lng: 18.6461147 },
    { lat: 48.4817932, lng: 18.6466506 },
    { lat: 48.481605, lng: 18.6480977 },
    { lat: 48.4808342, lng: 18.6504146 },
    { lat: 48.480698, lng: 18.6513224 },
    { lat: 48.4807673, lng: 18.6521711 },
    { lat: 48.4806084, lng: 18.6534304 },
    { lat: 48.4799223, lng: 18.6549764 },
    { lat: 48.4793464, lng: 18.655654 },
    { lat: 48.479127, lng: 18.6561684 },
    { lat: 48.4790418, lng: 18.6566573 },
    { lat: 48.4790075, lng: 18.6566526 },
    { lat: 48.4785245, lng: 18.6577582 },
    { lat: 48.4779922, lng: 18.6585126 },
    { lat: 48.4775575, lng: 18.6587969 },
    { lat: 48.477237, lng: 18.6588991 },
    { lat: 48.4768459, lng: 18.6588646 },
    { lat: 48.4764417, lng: 18.6589589 },
    { lat: 48.4761299, lng: 18.658866 },
    { lat: 48.4758709, lng: 18.6588761 },
    { lat: 48.4756233, lng: 18.6590086 },
    { lat: 48.4755555, lng: 18.6590716 },
    { lat: 48.4755583, lng: 18.6591445 },
    { lat: 48.4752983, lng: 18.6592589 },
    { lat: 48.4753375, lng: 18.6593601 },
    { lat: 48.4729141, lng: 18.6632707 },
    { lat: 48.4727427, lng: 18.6642167 },
    { lat: 48.4726546, lng: 18.6655987 },
    { lat: 48.4727447, lng: 18.6670258 },
    { lat: 48.4727276, lng: 18.6682627 },
    { lat: 48.473369, lng: 18.6678351 },
    { lat: 48.4742015, lng: 18.6678577 },
    { lat: 48.4755698, lng: 18.6681771 },
    { lat: 48.4763933, lng: 18.6688307 },
    { lat: 48.4767779, lng: 18.6701977 },
    { lat: 48.4773892, lng: 18.6721127 },
    { lat: 48.4779385, lng: 18.6725533 },
    { lat: 48.4780549, lng: 18.6725613 },
    { lat: 48.4782342, lng: 18.6728104 },
    { lat: 48.4782328, lng: 18.6737103 },
    { lat: 48.4786138, lng: 18.6750116 },
    { lat: 48.4786143, lng: 18.6752487 },
    { lat: 48.4784938, lng: 18.6755719 },
    { lat: 48.4785265, lng: 18.6757812 },
    { lat: 48.4795953, lng: 18.6765325 },
    { lat: 48.4799286, lng: 18.6769428 },
    { lat: 48.4800233, lng: 18.6781186 },
    { lat: 48.4802819, lng: 18.6781694 },
    { lat: 48.4806502, lng: 18.6780946 },
    { lat: 48.480893, lng: 18.6781629 },
    { lat: 48.4811536, lng: 18.6778934 },
    { lat: 48.4813432, lng: 18.677537 },
    { lat: 48.4816791, lng: 18.6771106 },
    { lat: 48.4819096, lng: 18.6769525 },
    { lat: 48.4823697, lng: 18.6774762 },
    { lat: 48.4828764, lng: 18.6778385 },
    { lat: 48.4830114, lng: 18.6780234 },
    { lat: 48.4832638, lng: 18.6786729 },
    { lat: 48.4831554, lng: 18.6791951 },
    { lat: 48.4833291, lng: 18.6797855 },
    { lat: 48.4838019, lng: 18.680692 },
    { lat: 48.4840405, lng: 18.681485 },
    { lat: 48.4844747, lng: 18.6818651 },
    { lat: 48.4850425, lng: 18.6818102 },
    { lat: 48.4866991, lng: 18.6808159 },
    { lat: 48.4868954, lng: 18.681052 },
    { lat: 48.487136, lng: 18.6810776 },
    { lat: 48.487321, lng: 18.680637 },
    { lat: 48.4874328, lng: 18.6806314 },
    { lat: 48.4874195, lng: 18.6806226 },
    { lat: 48.4879028, lng: 18.6806963 },
    { lat: 48.4880448, lng: 18.6806307 },
    { lat: 48.4884483, lng: 18.6808165 },
    { lat: 48.4887727, lng: 18.6808106 },
    { lat: 48.4888454, lng: 18.6809013 },
    { lat: 48.489138, lng: 18.6808702 },
    { lat: 48.4904764, lng: 18.6811143 },
    { lat: 48.4908325, lng: 18.6811931 },
    { lat: 48.4915348, lng: 18.6819685 },
    { lat: 48.4917612, lng: 18.68251 },
    { lat: 48.491836, lng: 18.6832106 },
    { lat: 48.4918593, lng: 18.6840075 },
    { lat: 48.4919345, lng: 18.6844851 },
    { lat: 48.4918509, lng: 18.6849013 },
    { lat: 48.4917848, lng: 18.6857115 },
    { lat: 48.4919809, lng: 18.6857105 },
    { lat: 48.4922643, lng: 18.6866828 },
    { lat: 48.4922575, lng: 18.6870644 },
    { lat: 48.4926771, lng: 18.6875082 },
    { lat: 48.4931171, lng: 18.6875951 },
    { lat: 48.4932444, lng: 18.6875057 },
    { lat: 48.4936145, lng: 18.6875939 },
    { lat: 48.4938367, lng: 18.687679 },
    { lat: 48.4938424, lng: 18.6878913 },
    { lat: 48.494069, lng: 18.6879126 },
    { lat: 48.4944321, lng: 18.6880997 },
    { lat: 48.4948975, lng: 18.6880089 },
    { lat: 48.4949075, lng: 18.6878775 },
    { lat: 48.4950227, lng: 18.6878607 },
    { lat: 48.4950846, lng: 18.6881747 },
    { lat: 48.4950119, lng: 18.6882909 },
    { lat: 48.4949836, lng: 18.6885852 },
    { lat: 48.4950538, lng: 18.6888448 },
    { lat: 48.4957864, lng: 18.6897649 },
    { lat: 48.4961328, lng: 18.6900587 },
    { lat: 48.4962508, lng: 18.6899054 },
    { lat: 48.4964834, lng: 18.6898047 },
    { lat: 48.4965876, lng: 18.6896665 },
    { lat: 48.4992696, lng: 18.6888028 },
    { lat: 48.5000457, lng: 18.6886789 },
    { lat: 48.5013184, lng: 18.6884761 },
    { lat: 48.502437, lng: 18.6884719 },
    { lat: 48.5026925, lng: 18.6888443 },
    { lat: 48.5029361, lng: 18.6890321 },
    { lat: 48.5032005, lng: 18.6891057 },
    { lat: 48.5040057, lng: 18.6900311 },
    { lat: 48.5047122, lng: 18.6904837 },
    { lat: 48.5055191, lng: 18.6908299 },
    { lat: 48.5070522, lng: 18.6917458 },
    { lat: 48.5074019, lng: 18.6917711 },
    { lat: 48.507984, lng: 18.6919603 },
    { lat: 48.5083235, lng: 18.6921529 },
    { lat: 48.5086197, lng: 18.6921459 },
    { lat: 48.5090783, lng: 18.6924763 },
    { lat: 48.5096898, lng: 18.692583 },
    { lat: 48.5102703, lng: 18.6925138 },
    { lat: 48.5105714, lng: 18.6922405 },
    { lat: 48.5107807, lng: 18.6918481 },
    { lat: 48.5115516, lng: 18.6907033 },
    { lat: 48.5120369, lng: 18.6904752 },
    { lat: 48.5122184, lng: 18.6904533 },
    { lat: 48.5126276, lng: 18.6905741 },
    { lat: 48.513435, lng: 18.6902345 },
    { lat: 48.5139934, lng: 18.6896909 },
    { lat: 48.5146506, lng: 18.6891759 },
    { lat: 48.5152382, lng: 18.6888412 },
    { lat: 48.5153983, lng: 18.6888613 },
    { lat: 48.5157082, lng: 18.6891094 },
    { lat: 48.5169366, lng: 18.6888571 },
    { lat: 48.5176228, lng: 18.6886332 },
    { lat: 48.5177362, lng: 18.6886678 },
    { lat: 48.5184377, lng: 18.6885185 },
    { lat: 48.518816, lng: 18.6882133 },
    { lat: 48.5190331, lng: 18.6882461 },
    { lat: 48.5191955, lng: 18.6883936 },
    { lat: 48.5201497, lng: 18.6883286 },
    { lat: 48.5206067, lng: 18.6888403 },
    { lat: 48.5209984, lng: 18.6894396 },
    { lat: 48.5213842, lng: 18.6897992 },
    { lat: 48.5214628, lng: 18.6901519 },
    { lat: 48.5222051, lng: 18.6915271 },
    { lat: 48.522451, lng: 18.6922231 },
    { lat: 48.5226937, lng: 18.6909183 },
    { lat: 48.5220967, lng: 18.6900065 },
    { lat: 48.5217655, lng: 18.6896723 },
    { lat: 48.5213456, lng: 18.6890995 },
    { lat: 48.5212957, lng: 18.6886085 },
    { lat: 48.5213655, lng: 18.6879949 },
    { lat: 48.5217301, lng: 18.6873437 },
    { lat: 48.5224121, lng: 18.6863478 },
    { lat: 48.5235135, lng: 18.6844621 },
    { lat: 48.523466, lng: 18.683825 },
    { lat: 48.5232254, lng: 18.6829939 },
    { lat: 48.5226639, lng: 18.6819444 },
    { lat: 48.5223728, lng: 18.6812034 },
    { lat: 48.5223048, lng: 18.6802055 },
    { lat: 48.5221474, lng: 18.6796678 },
    { lat: 48.5215491, lng: 18.6787498 },
    { lat: 48.5210947, lng: 18.6782717 },
    { lat: 48.5202787, lng: 18.6776894 },
    { lat: 48.5194566, lng: 18.6769765 },
    { lat: 48.5190971, lng: 18.6765392 },
    { lat: 48.5188344, lng: 18.6764138 },
    { lat: 48.5185456, lng: 18.6763912 },
    { lat: 48.5185456, lng: 18.6763065 },
    { lat: 48.5176764, lng: 18.6764849 },
    { lat: 48.5172218, lng: 18.6749424 },
    { lat: 48.5171193, lng: 18.6744003 },
    { lat: 48.5172191, lng: 18.6738823 },
    { lat: 48.5175711, lng: 18.6742013 },
    { lat: 48.5177614, lng: 18.6740704 },
    { lat: 48.5181897, lng: 18.673118 },
    { lat: 48.5187409, lng: 18.6731945 },
    { lat: 48.5194996, lng: 18.6730191 },
    { lat: 48.5197123, lng: 18.6727795 },
    { lat: 48.5195252, lng: 18.6718341 },
    { lat: 48.5196402, lng: 18.6718358 },
    { lat: 48.5203001, lng: 18.6713291 },
    { lat: 48.5206296, lng: 18.6702421 },
    { lat: 48.5219156, lng: 18.6668806 },
    { lat: 48.5220147, lng: 18.6664142 },
    { lat: 48.5221662, lng: 18.6662125 },
    { lat: 48.5222458, lng: 18.6658008 },
    { lat: 48.5228542, lng: 18.6641504 },
    { lat: 48.5241188, lng: 18.6640297 },
    { lat: 48.5242312, lng: 18.6635844 },
    { lat: 48.5241363, lng: 18.6634547 },
    { lat: 48.5243386, lng: 18.6630835 },
    { lat: 48.5249389, lng: 18.6622071 },
    { lat: 48.5256086, lng: 18.6615503 },
    { lat: 48.5259829, lng: 18.6607259 },
    { lat: 48.5258701, lng: 18.6602664 },
    { lat: 48.5260601, lng: 18.6593505 },
    { lat: 48.5260119, lng: 18.6586636 },
    { lat: 48.5254584, lng: 18.6583303 },
    { lat: 48.5246376, lng: 18.6578108 },
    { lat: 48.5239722, lng: 18.656951 },
    { lat: 48.5235988, lng: 18.6567624 },
    { lat: 48.5229642, lng: 18.6563077 },
    { lat: 48.5225222, lng: 18.6556382 },
    { lat: 48.5226804, lng: 18.6556232 },
    { lat: 48.5225012, lng: 18.6554981 },
    { lat: 48.5222856, lng: 18.6551148 },
    { lat: 48.5221526, lng: 18.6549926 },
    { lat: 48.5212097, lng: 18.6544677 },
    { lat: 48.5208511, lng: 18.654404 },
    { lat: 48.5206211, lng: 18.6542189 },
    { lat: 48.5195859, lng: 18.6540329 },
    { lat: 48.5186836, lng: 18.6534231 },
    { lat: 48.518467, lng: 18.6532147 },
    { lat: 48.5181859, lng: 18.653081 },
    { lat: 48.5179867, lng: 18.6531127 },
    { lat: 48.5176272, lng: 18.6529271 },
    { lat: 48.5152756, lng: 18.6526112 },
    { lat: 48.5136796, lng: 18.6520234 },
    { lat: 48.5128548, lng: 18.6521239 },
    { lat: 48.5119305, lng: 18.6519839 },
    { lat: 48.5115022, lng: 18.6520329 },
    { lat: 48.511034, lng: 18.6519484 },
    { lat: 48.509608, lng: 18.6523366 },
    { lat: 48.5085225, lng: 18.6519726 },
    { lat: 48.5085096, lng: 18.6517942 },
    { lat: 48.5090491, lng: 18.651885 },
    { lat: 48.5087363, lng: 18.6507268 },
    { lat: 48.5088126, lng: 18.6506781 },
    { lat: 48.5086669, lng: 18.6490987 },
    { lat: 48.5086812, lng: 18.647146 },
    { lat: 48.508832, lng: 18.6468267 },
    { lat: 48.5090676, lng: 18.6453542 },
    { lat: 48.5083331, lng: 18.643486 },
    { lat: 48.5079873, lng: 18.6431828 },
    { lat: 48.5078654, lng: 18.6429686 },
    { lat: 48.5081272, lng: 18.6425109 },
    { lat: 48.5081925, lng: 18.6422802 },
    { lat: 48.5081475, lng: 18.6415505 },
    { lat: 48.5082283, lng: 18.6403917 },
    { lat: 48.5081526, lng: 18.6401143 },
    { lat: 48.5078963, lng: 18.6396903 },
    { lat: 48.5081078, lng: 18.6390534 },
    { lat: 48.5080654, lng: 18.6368248 },
    { lat: 48.5081779, lng: 18.6364002 },
    { lat: 48.508935, lng: 18.6360075 },
    { lat: 48.5091311, lng: 18.6358384 },
    { lat: 48.509608, lng: 18.6351417 },
    { lat: 48.5098617, lng: 18.6344953 },
    { lat: 48.5097779, lng: 18.6338386 },
    { lat: 48.5102115, lng: 18.6332204 },
    { lat: 48.5104647, lng: 18.6327516 },
    { lat: 48.5108962, lng: 18.6326145 },
    { lat: 48.5110206, lng: 18.6324549 },
    { lat: 48.5111913, lng: 18.6314779 },
    { lat: 48.5114536, lng: 18.6311587 },
    { lat: 48.5114417, lng: 18.6308098 },
  ],
  Kľak: [
    { lat: 48.633151, lng: 18.643863 },
    { lat: 48.633123, lng: 18.643154 },
    { lat: 48.630848, lng: 18.640622 },
    { lat: 48.626046, lng: 18.637303 },
    { lat: 48.625866, lng: 18.63718 },
    { lat: 48.62515, lng: 18.636685 },
    { lat: 48.62476, lng: 18.63599 },
    { lat: 48.624685, lng: 18.634823 },
    { lat: 48.6244119, lng: 18.63458 },
    { lat: 48.6239897, lng: 18.6341954 },
    { lat: 48.6238483, lng: 18.6340676 },
    { lat: 48.62383, lng: 18.634051 },
    { lat: 48.623457, lng: 18.633776 },
    { lat: 48.62311, lng: 18.63348 },
    { lat: 48.622482, lng: 18.633482 },
    { lat: 48.6219064, lng: 18.6335733 },
    { lat: 48.62102, lng: 18.633714 },
    { lat: 48.619774, lng: 18.632561 },
    { lat: 48.61895, lng: 18.631642 },
    { lat: 48.6178, lng: 18.630731 },
    { lat: 48.616864, lng: 18.629343 },
    { lat: 48.615089, lng: 18.627431 },
    { lat: 48.613785, lng: 18.62633 },
    { lat: 48.611554, lng: 18.623992 },
    { lat: 48.60994, lng: 18.622374 },
    { lat: 48.608679, lng: 18.621108 },
    { lat: 48.607485, lng: 18.621284 },
    { lat: 48.607039, lng: 18.620821 },
    { lat: 48.606417, lng: 18.620127 },
    { lat: 48.605785, lng: 18.61932 },
    { lat: 48.605093, lng: 18.618142 },
    { lat: 48.604733, lng: 18.61747 },
    { lat: 48.602689, lng: 18.614875 },
    { lat: 48.60164, lng: 18.612222 },
    { lat: 48.600816, lng: 18.610516 },
    { lat: 48.600022, lng: 18.609931 },
    { lat: 48.599847, lng: 18.609858 },
    { lat: 48.598997, lng: 18.609239 },
    { lat: 48.597636, lng: 18.607387 },
    { lat: 48.597118, lng: 18.606314 },
    { lat: 48.596789, lng: 18.605917 },
    { lat: 48.59621, lng: 18.605514 },
    { lat: 48.593723, lng: 18.603833 },
    { lat: 48.593121, lng: 18.602439 },
    { lat: 48.592587, lng: 18.601623 },
    { lat: 48.591957, lng: 18.60138 },
    { lat: 48.591736, lng: 18.601188 },
    { lat: 48.591133, lng: 18.600664 },
    { lat: 48.590465, lng: 18.600086 },
    { lat: 48.58974, lng: 18.599673 },
    { lat: 48.588938, lng: 18.599225 },
    { lat: 48.588381, lng: 18.599278 },
    { lat: 48.587626, lng: 18.599715 },
    { lat: 48.586801, lng: 18.600196 },
    { lat: 48.586342, lng: 18.600614 },
    { lat: 48.585765, lng: 18.601192 },
    { lat: 48.584914, lng: 18.601959 },
    { lat: 48.583841, lng: 18.602366 },
    { lat: 48.583506, lng: 18.602214 },
    { lat: 48.582413, lng: 18.601691 },
    { lat: 48.582351, lng: 18.601664 },
    { lat: 48.581772, lng: 18.601409 },
    { lat: 48.581299, lng: 18.601176 },
    { lat: 48.580795, lng: 18.600891 },
    { lat: 48.580224, lng: 18.600565 },
    { lat: 48.5774261, lng: 18.6144253 },
    { lat: 48.5770311, lng: 18.6154437 },
    { lat: 48.5769069, lng: 18.6160452 },
    { lat: 48.5763872, lng: 18.6174072 },
    { lat: 48.5760193, lng: 18.6180304 },
    { lat: 48.5742857, lng: 18.6228978 },
    { lat: 48.5726608, lng: 18.6259386 },
    { lat: 48.5711858, lng: 18.6351065 },
    { lat: 48.5720392, lng: 18.6373185 },
    { lat: 48.5727089, lng: 18.6394105 },
    { lat: 48.5728282, lng: 18.6395719 },
    { lat: 48.5732261, lng: 18.639421 },
    { lat: 48.5737851, lng: 18.6397288 },
    { lat: 48.5741237, lng: 18.6401939 },
    { lat: 48.5745493, lng: 18.6405999 },
    { lat: 48.5746693, lng: 18.6413628 },
    { lat: 48.5747972, lng: 18.6417076 },
    { lat: 48.5751654, lng: 18.6424928 },
    { lat: 48.5754773, lng: 18.6427336 },
    { lat: 48.575433, lng: 18.6424544 },
    { lat: 48.5754686, lng: 18.6421933 },
    { lat: 48.5754242, lng: 18.6419569 },
    { lat: 48.5754572, lng: 18.6418371 },
    { lat: 48.5764069, lng: 18.642968 },
    { lat: 48.5765459, lng: 18.6430551 },
    { lat: 48.5778488, lng: 18.6434373 },
    { lat: 48.5784918, lng: 18.6430222 },
    { lat: 48.5793393, lng: 18.6447096 },
    { lat: 48.5799554, lng: 18.646119 },
    { lat: 48.5805212, lng: 18.6470334 },
    { lat: 48.5806489, lng: 18.6474802 },
    { lat: 48.5817041, lng: 18.6488538 },
    { lat: 48.58325, lng: 18.6511156 },
    { lat: 48.5814603, lng: 18.6559732 },
    { lat: 48.5805328, lng: 18.6589475 },
    { lat: 48.5801231, lng: 18.6618235 },
    { lat: 48.5801899, lng: 18.6629848 },
    { lat: 48.5800338, lng: 18.665038 },
    { lat: 48.5805253, lng: 18.6678986 },
    { lat: 48.580715, lng: 18.6684486 },
    { lat: 48.5807824, lng: 18.6683172 },
    { lat: 48.5810368, lng: 18.6687297 },
    { lat: 48.5811931, lng: 18.6691025 },
    { lat: 48.5813741, lng: 18.668964 },
    { lat: 48.5814995, lng: 18.6689669 },
    { lat: 48.5816943, lng: 18.6687081 },
    { lat: 48.5817904, lng: 18.66909 },
    { lat: 48.5819052, lng: 18.6693186 },
    { lat: 48.5853573, lng: 18.6731709 },
    { lat: 48.5855266, lng: 18.6729195 },
    { lat: 48.5865445, lng: 18.6723205 },
    { lat: 48.5874562, lng: 18.6720526 },
    { lat: 48.5877387, lng: 18.6718733 },
    { lat: 48.588008, lng: 18.671553 },
    { lat: 48.5883657, lng: 18.6712826 },
    { lat: 48.5879708, lng: 18.672228 },
    { lat: 48.5879747, lng: 18.6725514 },
    { lat: 48.5885738, lng: 18.6728515 },
    { lat: 48.5893489, lng: 18.6735136 },
    { lat: 48.5902833, lng: 18.6736646 },
    { lat: 48.5910758, lng: 18.6737914 },
    { lat: 48.5913685, lng: 18.6737579 },
    { lat: 48.5931058, lng: 18.6731341 },
    { lat: 48.5945446, lng: 18.6732321 },
    { lat: 48.5955466, lng: 18.6732221 },
    { lat: 48.5980415, lng: 18.6730125 },
    { lat: 48.5991178, lng: 18.6726824 },
    { lat: 48.600059, lng: 18.6723121 },
    { lat: 48.6016117, lng: 18.6724126 },
    { lat: 48.6036014, lng: 18.6727525 },
    { lat: 48.6046301, lng: 18.6723359 },
    { lat: 48.6052059, lng: 18.6723471 },
    { lat: 48.605406, lng: 18.6724375 },
    { lat: 48.605638, lng: 18.672729 },
    { lat: 48.605867, lng: 18.6728779 },
    { lat: 48.6069435, lng: 18.6732224 },
    { lat: 48.6073768, lng: 18.6732684 },
    { lat: 48.6075354, lng: 18.6733881 },
    { lat: 48.607711, lng: 18.6733621 },
    { lat: 48.6078756, lng: 18.6732975 },
    { lat: 48.6093239, lng: 18.6722856 },
    { lat: 48.6111302, lng: 18.6699406 },
    { lat: 48.6114761, lng: 18.6693864 },
    { lat: 48.6116945, lng: 18.6689157 },
    { lat: 48.6118736, lng: 18.6683401 },
    { lat: 48.6120164, lng: 18.6675735 },
    { lat: 48.6122153, lng: 18.665815 },
    { lat: 48.6122749, lng: 18.6639144 },
    { lat: 48.6119615, lng: 18.6622332 },
    { lat: 48.6132796, lng: 18.6621856 },
    { lat: 48.6136337, lng: 18.663092 },
    { lat: 48.6140217, lng: 18.6636371 },
    { lat: 48.614064, lng: 18.663818 },
    { lat: 48.6141303, lng: 18.6645477 },
    { lat: 48.6144375, lng: 18.6666436 },
    { lat: 48.6144236, lng: 18.6677915 },
    { lat: 48.6144651, lng: 18.668443 },
    { lat: 48.6145181, lng: 18.6687802 },
    { lat: 48.614634, lng: 18.668983 },
    { lat: 48.614655, lng: 18.668949 },
    { lat: 48.615163, lng: 18.667871 },
    { lat: 48.615688, lng: 18.667501 },
    { lat: 48.61602, lng: 18.667153 },
    { lat: 48.617951, lng: 18.665136 },
    { lat: 48.619423, lng: 18.663599 },
    { lat: 48.619448, lng: 18.66358 },
    { lat: 48.620582, lng: 18.662718 },
    { lat: 48.620637, lng: 18.66268 },
    { lat: 48.621201, lng: 18.662259 },
    { lat: 48.62192, lng: 18.661863 },
    { lat: 48.622846, lng: 18.661212 },
    { lat: 48.624188, lng: 18.66098 },
    { lat: 48.624414, lng: 18.660957 },
    { lat: 48.625745, lng: 18.659746 },
    { lat: 48.625963, lng: 18.659599 },
    { lat: 48.626675, lng: 18.658725 },
    { lat: 48.6273, lng: 18.658377 },
    { lat: 48.627844, lng: 18.657361 },
    { lat: 48.629885, lng: 18.655436 },
    { lat: 48.6308, lng: 18.654794 },
    { lat: 48.631633, lng: 18.654654 },
    { lat: 48.632421, lng: 18.6542 },
    { lat: 48.633209, lng: 18.645409 },
    { lat: 48.633151, lng: 18.643863 },
  ],
  HronskýBeňadik: [
    { lat: 48.333002, lng: 18.525216 },
    { lat: 48.332642, lng: 18.524412 },
    { lat: 48.332313, lng: 18.524988 },
    { lat: 48.332168, lng: 18.525241 },
    { lat: 48.331989, lng: 18.525554 },
    { lat: 48.331812, lng: 18.525864 },
    { lat: 48.331668, lng: 18.526116 },
    { lat: 48.331638, lng: 18.526168 },
    { lat: 48.331466, lng: 18.526468 },
    { lat: 48.331296, lng: 18.526764 },
    { lat: 48.331132, lng: 18.527054 },
    { lat: 48.331121, lng: 18.527071 },
    { lat: 48.331106, lng: 18.527098 },
    { lat: 48.331045, lng: 18.527046 },
    { lat: 48.330984, lng: 18.527055 },
    { lat: 48.330973, lng: 18.527055 },
    { lat: 48.330849, lng: 18.52716 },
    { lat: 48.330701, lng: 18.527269 },
    { lat: 48.3305, lng: 18.527561 },
    { lat: 48.330473, lng: 18.527611 },
    { lat: 48.330447, lng: 18.527677 },
    { lat: 48.330404, lng: 18.527753 },
    { lat: 48.330371, lng: 18.527792 },
    { lat: 48.330343, lng: 18.527818 },
    { lat: 48.330178, lng: 18.527965 },
    { lat: 48.330068, lng: 18.528084 },
    { lat: 48.329954, lng: 18.528322 },
    { lat: 48.329866, lng: 18.528478 },
    { lat: 48.329759, lng: 18.528578 },
    { lat: 48.329676, lng: 18.528726 },
    { lat: 48.329646, lng: 18.528812 },
    { lat: 48.329824, lng: 18.528756 },
    { lat: 48.330088, lng: 18.528676 },
    { lat: 48.330203, lng: 18.528658 },
    { lat: 48.330342, lng: 18.528661 },
    { lat: 48.330416, lng: 18.528673 },
    { lat: 48.330519, lng: 18.528712 },
    { lat: 48.330602, lng: 18.528742 },
    { lat: 48.330566, lng: 18.52883 },
    { lat: 48.330414, lng: 18.529201 },
    { lat: 48.330328, lng: 18.529395 },
    { lat: 48.330227, lng: 18.529624 },
    { lat: 48.330137, lng: 18.529828 },
    { lat: 48.33004, lng: 18.530077 },
    { lat: 48.329987, lng: 18.530256 },
    { lat: 48.32996, lng: 18.530369 },
    { lat: 48.329916, lng: 18.530609 },
    { lat: 48.329912, lng: 18.530879 },
    { lat: 48.329955, lng: 18.531441 },
    { lat: 48.32993, lng: 18.531607 },
    { lat: 48.329884, lng: 18.531796 },
    { lat: 48.329799, lng: 18.53214 },
    { lat: 48.329703, lng: 18.532484 },
    { lat: 48.329594, lng: 18.532843 },
    { lat: 48.32944, lng: 18.533288 },
    { lat: 48.329385, lng: 18.533435 },
    { lat: 48.329363, lng: 18.533492 },
    { lat: 48.329291, lng: 18.533679 },
    { lat: 48.329265, lng: 18.533743 },
    { lat: 48.329224, lng: 18.53385 },
    { lat: 48.329193, lng: 18.533847 },
    { lat: 48.328406, lng: 18.533365 },
    { lat: 48.328159, lng: 18.533187 },
    { lat: 48.327489, lng: 18.532701 },
    { lat: 48.327061, lng: 18.533746 },
    { lat: 48.326602, lng: 18.534874 },
    { lat: 48.326234, lng: 18.535752 },
    { lat: 48.327266, lng: 18.537185 },
    { lat: 48.328022, lng: 18.538234 },
    { lat: 48.328001, lng: 18.538339 },
    { lat: 48.327933, lng: 18.538561 },
    { lat: 48.327926, lng: 18.5386 },
    { lat: 48.327829, lng: 18.53933 },
    { lat: 48.327809, lng: 18.539543 },
    { lat: 48.327775, lng: 18.539769 },
    { lat: 48.327665, lng: 18.54015 },
    { lat: 48.327542, lng: 18.540518 },
    { lat: 48.3274, lng: 18.540879 },
    { lat: 48.327205, lng: 18.541343 },
    { lat: 48.327063, lng: 18.541707 },
    { lat: 48.327054, lng: 18.541729 },
    { lat: 48.327026, lng: 18.54172 },
    { lat: 48.327015, lng: 18.541717 },
    { lat: 48.326917, lng: 18.541489 },
    { lat: 48.326691, lng: 18.540963 },
    { lat: 48.326284, lng: 18.540124 },
    { lat: 48.326059, lng: 18.539649 },
    { lat: 48.3258677, lng: 18.5392584 },
    { lat: 48.32556, lng: 18.53863 },
    { lat: 48.3251323, lng: 18.5378633 },
    { lat: 48.325083, lng: 18.537775 },
    { lat: 48.324465, lng: 18.536694 },
    { lat: 48.3243141, lng: 18.5364505 },
    { lat: 48.324276, lng: 18.536389 },
    { lat: 48.324254, lng: 18.536355 },
    { lat: 48.324049, lng: 18.536055 },
    { lat: 48.3232874, lng: 18.5349976 },
    { lat: 48.322937, lng: 18.534511 },
    { lat: 48.32273, lng: 18.534204 },
    { lat: 48.322616, lng: 18.53403 },
    { lat: 48.322355, lng: 18.533625 },
    { lat: 48.322322, lng: 18.533708 },
    { lat: 48.322289, lng: 18.533795 },
    { lat: 48.322251, lng: 18.533894 },
    { lat: 48.322201, lng: 18.534002 },
    { lat: 48.322189, lng: 18.534022 },
    { lat: 48.322093, lng: 18.534149 },
    { lat: 48.322002, lng: 18.534267 },
    { lat: 48.321913, lng: 18.534397 },
    { lat: 48.321858, lng: 18.534482 },
    { lat: 48.321835, lng: 18.534518 },
    { lat: 48.321813, lng: 18.534557 },
    { lat: 48.32176, lng: 18.534647 },
    { lat: 48.32164, lng: 18.534879 },
    { lat: 48.321607, lng: 18.53493 },
    { lat: 48.321573, lng: 18.53499 },
    { lat: 48.321466, lng: 18.535201 },
    { lat: 48.321454, lng: 18.535225 },
    { lat: 48.321438, lng: 18.535259 },
    { lat: 48.321395, lng: 18.535221 },
    { lat: 48.321345, lng: 18.535174 },
    { lat: 48.3213, lng: 18.535135 },
    { lat: 48.321255, lng: 18.535093 },
    { lat: 48.321238, lng: 18.535077 },
    { lat: 48.321152, lng: 18.534996 },
    { lat: 48.321044, lng: 18.534899 },
    { lat: 48.320935, lng: 18.534798 },
    { lat: 48.32087, lng: 18.53474 },
    { lat: 48.320826, lng: 18.534701 },
    { lat: 48.320721, lng: 18.534606 },
    { lat: 48.320611, lng: 18.534509 },
    { lat: 48.320577, lng: 18.534479 },
    { lat: 48.320496, lng: 18.534404 },
    { lat: 48.320289, lng: 18.534867 },
    { lat: 48.320256, lng: 18.534838 },
    { lat: 48.320219, lng: 18.534807 },
    { lat: 48.320193, lng: 18.53478 },
    { lat: 48.320157, lng: 18.534748 },
    { lat: 48.320131, lng: 18.534724 },
    { lat: 48.320098, lng: 18.534692 },
    { lat: 48.320063, lng: 18.53466 },
    { lat: 48.320034, lng: 18.534631 },
    { lat: 48.319911, lng: 18.534517 },
    { lat: 48.319828, lng: 18.534439 },
    { lat: 48.31978, lng: 18.534395 },
    { lat: 48.319742, lng: 18.534359 },
    { lat: 48.319669, lng: 18.534293 },
    { lat: 48.319623, lng: 18.534252 },
    { lat: 48.3196, lng: 18.534227 },
    { lat: 48.319381, lng: 18.534849 },
    { lat: 48.319254, lng: 18.535164 },
    { lat: 48.319215, lng: 18.53526 },
    { lat: 48.319201, lng: 18.535238 },
    { lat: 48.319162, lng: 18.535175 },
    { lat: 48.31916, lng: 18.53517 },
    { lat: 48.319081, lng: 18.535049 },
    { lat: 48.319004, lng: 18.534932 },
    { lat: 48.318991, lng: 18.534907 },
    { lat: 48.31845, lng: 18.534064 },
    { lat: 48.318289, lng: 18.534128 },
    { lat: 48.318263, lng: 18.534141 },
    { lat: 48.31824, lng: 18.534151 },
    { lat: 48.31821, lng: 18.534164 },
    { lat: 48.318171, lng: 18.53418 },
    { lat: 48.318129, lng: 18.5342 },
    { lat: 48.31809, lng: 18.534218 },
    { lat: 48.318056, lng: 18.534234 },
    { lat: 48.318015, lng: 18.534251 },
    { lat: 48.317987, lng: 18.534264 },
    { lat: 48.317965, lng: 18.534277 },
    { lat: 48.317884, lng: 18.534316 },
    { lat: 48.317864, lng: 18.534326 },
    { lat: 48.317838, lng: 18.534342 },
    { lat: 48.318437, lng: 18.535138 },
    { lat: 48.318482, lng: 18.535213 },
    { lat: 48.318613, lng: 18.535433 },
    { lat: 48.318703, lng: 18.535597 },
    { lat: 48.318632, lng: 18.535675 },
    { lat: 48.318554, lng: 18.535763 },
    { lat: 48.318435, lng: 18.535898 },
    { lat: 48.318342, lng: 18.536004 },
    { lat: 48.318179, lng: 18.536269 },
    { lat: 48.318173, lng: 18.536283 },
    { lat: 48.318133, lng: 18.536259 },
    { lat: 48.318113, lng: 18.536244 },
    { lat: 48.318093, lng: 18.536233 },
    { lat: 48.318075, lng: 18.536218 },
    { lat: 48.318026, lng: 18.536183 },
    { lat: 48.31797, lng: 18.536142 },
    { lat: 48.317939, lng: 18.536117 },
    { lat: 48.317904, lng: 18.536089 },
    { lat: 48.317828, lng: 18.536036 },
    { lat: 48.317765, lng: 18.535985 },
    { lat: 48.317723, lng: 18.535953 },
    { lat: 48.317694, lng: 18.535934 },
    { lat: 48.317621, lng: 18.535878 },
    { lat: 48.317478, lng: 18.53579 },
    { lat: 48.317405, lng: 18.535736 },
    { lat: 48.317332, lng: 18.535685 },
    { lat: 48.317261, lng: 18.53564 },
    { lat: 48.317184, lng: 18.53559 },
    { lat: 48.317043, lng: 18.535478 },
    { lat: 48.31695, lng: 18.535405 },
    { lat: 48.316852, lng: 18.535328 },
    { lat: 48.316824, lng: 18.535307 },
    { lat: 48.316809, lng: 18.535296 },
    { lat: 48.316761, lng: 18.535258 },
    { lat: 48.316494, lng: 18.535067 },
    { lat: 48.316459, lng: 18.53504 },
    { lat: 48.316403, lng: 18.534982 },
    { lat: 48.31638, lng: 18.534958 },
    { lat: 48.316349, lng: 18.534929 },
    { lat: 48.316308, lng: 18.534882 },
    { lat: 48.316287, lng: 18.534862 },
    { lat: 48.316268, lng: 18.534838 },
    { lat: 48.316245, lng: 18.534817 },
    { lat: 48.316212, lng: 18.534785 },
    { lat: 48.316181, lng: 18.534751 },
    { lat: 48.316166, lng: 18.534736 },
    { lat: 48.316156, lng: 18.534727 },
    { lat: 48.316128, lng: 18.534695 },
    { lat: 48.316107, lng: 18.53467 },
    { lat: 48.316083, lng: 18.534646 },
    { lat: 48.315996, lng: 18.534551 },
    { lat: 48.315925, lng: 18.534458 },
    { lat: 48.315859, lng: 18.534378 },
    { lat: 48.315838, lng: 18.534351 },
    { lat: 48.315798, lng: 18.534294 },
    { lat: 48.315765, lng: 18.534256 },
    { lat: 48.315406, lng: 18.535083 },
    { lat: 48.315315, lng: 18.535253 },
    { lat: 48.315284, lng: 18.535336 },
    { lat: 48.315249, lng: 18.535397 },
    { lat: 48.315232, lng: 18.535428 },
    { lat: 48.315189, lng: 18.535526 },
    { lat: 48.315076, lng: 18.535729 },
    { lat: 48.315025, lng: 18.535831 },
    { lat: 48.314877, lng: 18.536127 },
    { lat: 48.314764, lng: 18.536221 },
    { lat: 48.314674, lng: 18.536227 },
    { lat: 48.314582, lng: 18.536229 },
    { lat: 48.31455, lng: 18.536227 },
    { lat: 48.314489, lng: 18.536218 },
    { lat: 48.314414, lng: 18.53618 },
    { lat: 48.314348, lng: 18.536142 },
    { lat: 48.314278, lng: 18.536103 },
    { lat: 48.314146, lng: 18.536027 },
    { lat: 48.314093, lng: 18.535993 },
    { lat: 48.3141, lng: 18.535874 },
    { lat: 48.314112, lng: 18.535698 },
    { lat: 48.314106, lng: 18.53546 },
    { lat: 48.314095, lng: 18.535337 },
    { lat: 48.314095, lng: 18.535298 },
    { lat: 48.314094, lng: 18.535188 },
    { lat: 48.314075, lng: 18.535129 },
    { lat: 48.314066, lng: 18.535107 },
    { lat: 48.31403, lng: 18.535036 },
    { lat: 48.313995, lng: 18.535007 },
    { lat: 48.313951, lng: 18.534972 },
    { lat: 48.313939, lng: 18.534962 },
    { lat: 48.31393, lng: 18.53496 },
    { lat: 48.313844, lng: 18.534949 },
    { lat: 48.313826, lng: 18.534947 },
    { lat: 48.313809, lng: 18.534945 },
    { lat: 48.313696, lng: 18.534933 },
    { lat: 48.313578, lng: 18.53494 },
    { lat: 48.313572, lng: 18.534961 },
    { lat: 48.313524, lng: 18.535159 },
    { lat: 48.313513, lng: 18.535212 },
    { lat: 48.313476, lng: 18.535379 },
    { lat: 48.313419, lng: 18.535632 },
    { lat: 48.31336, lng: 18.535608 },
    { lat: 48.313273, lng: 18.535568 },
    { lat: 48.313176, lng: 18.535522 },
    { lat: 48.313134, lng: 18.535497 },
    { lat: 48.313018, lng: 18.535426 },
    { lat: 48.312927, lng: 18.535376 },
    { lat: 48.312826, lng: 18.535321 },
    { lat: 48.31251, lng: 18.535146 },
    { lat: 48.312448, lng: 18.535113 },
    { lat: 48.3124, lng: 18.535072 },
    { lat: 48.312292, lng: 18.534982 },
    { lat: 48.312212, lng: 18.534916 },
    { lat: 48.312181, lng: 18.53489 },
    { lat: 48.312083, lng: 18.53481 },
    { lat: 48.312026, lng: 18.534761 },
    { lat: 48.311869, lng: 18.534633 },
    { lat: 48.311849, lng: 18.534784 },
    { lat: 48.311824, lng: 18.534977 },
    { lat: 48.31179, lng: 18.535235 },
    { lat: 48.311758, lng: 18.535486 },
    { lat: 48.311714, lng: 18.53582 },
    { lat: 48.311707, lng: 18.535882 },
    { lat: 48.311569, lng: 18.5360777 },
    { lat: 48.311416, lng: 18.5363 },
    { lat: 48.311395, lng: 18.53633 },
    { lat: 48.311376, lng: 18.536357 },
    { lat: 48.311306, lng: 18.536457 },
    { lat: 48.311271, lng: 18.536507 },
    { lat: 48.311222, lng: 18.536575 },
    { lat: 48.310969, lng: 18.536453 },
    { lat: 48.310334, lng: 18.536153 },
    { lat: 48.310231, lng: 18.536346 },
    { lat: 48.310138, lng: 18.53652 },
    { lat: 48.310097, lng: 18.536594 },
    { lat: 48.310051, lng: 18.536656 },
    { lat: 48.309947, lng: 18.536799 },
    { lat: 48.309921, lng: 18.536834 },
    { lat: 48.309897, lng: 18.536859 },
    { lat: 48.309891, lng: 18.536878 },
    { lat: 48.309872, lng: 18.536937 },
    { lat: 48.309846, lng: 18.537027 },
    { lat: 48.309823, lng: 18.537319 },
    { lat: 48.309813, lng: 18.537443 },
    { lat: 48.310419, lng: 18.537692 },
    { lat: 48.310926, lng: 18.537994 },
    { lat: 48.311259, lng: 18.538164 },
    { lat: 48.311712, lng: 18.538425 },
    { lat: 48.31204, lng: 18.538647 },
    { lat: 48.31218, lng: 18.538733 },
    { lat: 48.312529, lng: 18.538816 },
    { lat: 48.312667, lng: 18.538888 },
    { lat: 48.313102, lng: 18.539115 },
    { lat: 48.31333, lng: 18.539208 },
    { lat: 48.313495, lng: 18.539303 },
    { lat: 48.313894, lng: 18.539598 },
    { lat: 48.314062, lng: 18.539721 },
    { lat: 48.31429, lng: 18.53991 },
    { lat: 48.314467, lng: 18.540068 },
    { lat: 48.31463, lng: 18.540221 },
    { lat: 48.315107, lng: 18.540693 },
    { lat: 48.315262, lng: 18.540863 },
    { lat: 48.315368, lng: 18.540966 },
    { lat: 48.3154, lng: 18.54099 },
    { lat: 48.315453, lng: 18.541031 },
    { lat: 48.315719, lng: 18.541217 },
    { lat: 48.315888, lng: 18.541311 },
    { lat: 48.316232, lng: 18.541502 },
    { lat: 48.316432, lng: 18.541617 },
    { lat: 48.316651, lng: 18.541764 },
    { lat: 48.317198, lng: 18.541974 },
    { lat: 48.317316, lng: 18.54202 },
    { lat: 48.318074, lng: 18.542304 },
    { lat: 48.318773, lng: 18.542544 },
    { lat: 48.31915, lng: 18.542709 },
    { lat: 48.319213, lng: 18.542783 },
    { lat: 48.31945, lng: 18.543061 },
    { lat: 48.320193, lng: 18.543603 },
    { lat: 48.320771, lng: 18.543805 },
    { lat: 48.321221, lng: 18.544021 },
    { lat: 48.321359, lng: 18.543862 },
    { lat: 48.321822, lng: 18.544076 },
    { lat: 48.321894, lng: 18.544109 },
    { lat: 48.321907, lng: 18.544146 },
    { lat: 48.322002, lng: 18.544407 },
    { lat: 48.322499, lng: 18.544707 },
    { lat: 48.32272, lng: 18.544957 },
    { lat: 48.323674, lng: 18.546182 },
    { lat: 48.324256, lng: 18.546974 },
    { lat: 48.324503, lng: 18.547292 },
    { lat: 48.324692, lng: 18.547584 },
    { lat: 48.325076, lng: 18.548252 },
    { lat: 48.325179, lng: 18.548429 },
    { lat: 48.325313, lng: 18.548985 },
    { lat: 48.325342, lng: 18.549341 },
    { lat: 48.325358, lng: 18.549635 },
    { lat: 48.3254, lng: 18.550694 },
    { lat: 48.325469, lng: 18.551227 },
    { lat: 48.325512, lng: 18.551422 },
    { lat: 48.325766, lng: 18.55222 },
    { lat: 48.325901, lng: 18.552711 },
    { lat: 48.326051, lng: 18.55311 },
    { lat: 48.326411, lng: 18.553965 },
    { lat: 48.32655, lng: 18.554262 },
    { lat: 48.326645, lng: 18.554454 },
    { lat: 48.326718, lng: 18.554585 },
    { lat: 48.326825, lng: 18.554779 },
    { lat: 48.326965, lng: 18.555062 },
    { lat: 48.327127, lng: 18.555409 },
    { lat: 48.327285, lng: 18.555808 },
    { lat: 48.327416, lng: 18.556125 },
    { lat: 48.327572, lng: 18.556482 },
    { lat: 48.327835, lng: 18.556999 },
    { lat: 48.328079, lng: 18.557407 },
    { lat: 48.3285213, lng: 18.5580156 },
    { lat: 48.3287748, lng: 18.5582383 },
    { lat: 48.3292193, lng: 18.5585107 },
    { lat: 48.3297217, lng: 18.5588185 },
    { lat: 48.3304031, lng: 18.5590214 },
    { lat: 48.3307778, lng: 18.5590709 },
    { lat: 48.3309589, lng: 18.5590949 },
    { lat: 48.3310293, lng: 18.5591042 },
    { lat: 48.331968, lng: 18.5591 },
    { lat: 48.332309, lng: 18.559093 },
    { lat: 48.332731, lng: 18.559084 },
    { lat: 48.333149, lng: 18.559092 },
    { lat: 48.333148, lng: 18.559125 },
    { lat: 48.333288, lng: 18.559103 },
    { lat: 48.333599, lng: 18.559139 },
    { lat: 48.333896, lng: 18.5592 },
    { lat: 48.335324, lng: 18.559388 },
    { lat: 48.335463, lng: 18.559384 },
    { lat: 48.335972, lng: 18.559369 },
    { lat: 48.33606, lng: 18.559324 },
    { lat: 48.336132, lng: 18.559305 },
    { lat: 48.336395, lng: 18.559238 },
    { lat: 48.33678, lng: 18.559127 },
    { lat: 48.336806, lng: 18.55912 },
    { lat: 48.337288, lng: 18.559023 },
    { lat: 48.337882, lng: 18.55892 },
    { lat: 48.338548, lng: 18.558672 },
    { lat: 48.338808, lng: 18.558543 },
    { lat: 48.339141, lng: 18.558504 },
    { lat: 48.339443, lng: 18.558512 },
    { lat: 48.339535, lng: 18.558602 },
    { lat: 48.339769, lng: 18.558929 },
    { lat: 48.339886, lng: 18.559274 },
    { lat: 48.339939, lng: 18.559606 },
    { lat: 48.33994, lng: 18.559774 },
    { lat: 48.33994, lng: 18.559808 },
    { lat: 48.339928, lng: 18.559891 },
    { lat: 48.339906, lng: 18.560027 },
    { lat: 48.339871, lng: 18.560247 },
    { lat: 48.339813, lng: 18.560456 },
    { lat: 48.339733, lng: 18.560741 },
    { lat: 48.339597, lng: 18.560939 },
    { lat: 48.339516, lng: 18.560971 },
    { lat: 48.339363, lng: 18.561031 },
    { lat: 48.339306, lng: 18.561054 },
    { lat: 48.339077, lng: 18.561255 },
    { lat: 48.339001, lng: 18.561341 },
    { lat: 48.338801, lng: 18.561623 },
    { lat: 48.338741, lng: 18.561774 },
    { lat: 48.338646, lng: 18.562145 },
    { lat: 48.33863, lng: 18.562718 },
    { lat: 48.338622, lng: 18.562977 },
    { lat: 48.338723, lng: 18.56351 },
    { lat: 48.338834, lng: 18.563852 },
    { lat: 48.338846, lng: 18.563892 },
    { lat: 48.338955, lng: 18.564144 },
    { lat: 48.33918, lng: 18.564679 },
    { lat: 48.339404, lng: 18.564973 },
    { lat: 48.339783, lng: 18.565576 },
    { lat: 48.339859, lng: 18.565683 },
    { lat: 48.340664, lng: 18.566836 },
    { lat: 48.341382, lng: 18.567708 },
    { lat: 48.341647, lng: 18.568063 },
    { lat: 48.34199, lng: 18.56852 },
    { lat: 48.342259, lng: 18.568786 },
    { lat: 48.342444, lng: 18.56891 },
    { lat: 48.342739, lng: 18.569025 },
    { lat: 48.342863, lng: 18.569094 },
    { lat: 48.343199, lng: 18.569231 },
    { lat: 48.343796, lng: 18.569444 },
    { lat: 48.345047, lng: 18.570045 },
    { lat: 48.345868, lng: 18.570583 },
    { lat: 48.346051, lng: 18.570716 },
    { lat: 48.346134, lng: 18.570781 },
    { lat: 48.34659, lng: 18.571253 },
    { lat: 48.346642, lng: 18.571321 },
    { lat: 48.346709, lng: 18.571415 },
    { lat: 48.346969, lng: 18.571811 },
    { lat: 48.347296, lng: 18.57236 },
    { lat: 48.347441, lng: 18.572607 },
    { lat: 48.347836, lng: 18.573074 },
    { lat: 48.347896, lng: 18.573145 },
    { lat: 48.347908, lng: 18.57316 },
    { lat: 48.347921, lng: 18.573142 },
    { lat: 48.347936, lng: 18.573119 },
    { lat: 48.34795, lng: 18.573136 },
    { lat: 48.348258, lng: 18.573507 },
    { lat: 48.348329, lng: 18.574094 },
    { lat: 48.348351, lng: 18.574295 },
    { lat: 48.348402, lng: 18.574427 },
    { lat: 48.348411, lng: 18.574509 },
    { lat: 48.348422, lng: 18.574604 },
    { lat: 48.348352, lng: 18.574607 },
    { lat: 48.348216, lng: 18.574613 },
    { lat: 48.348113, lng: 18.574644 },
    { lat: 48.347966, lng: 18.574687 },
    { lat: 48.347865, lng: 18.574917 },
    { lat: 48.347894, lng: 18.575202 },
    { lat: 48.34821, lng: 18.5753254 },
    { lat: 48.3487711, lng: 18.5757923 },
    { lat: 48.3500522, lng: 18.5765053 },
    { lat: 48.3504683, lng: 18.5766496 },
    { lat: 48.3510564, lng: 18.5770262 },
    { lat: 48.3513114, lng: 18.5773587 },
    { lat: 48.3517427, lng: 18.5776188 },
    { lat: 48.3519652, lng: 18.5778482 },
    { lat: 48.3524574, lng: 18.5782059 },
    { lat: 48.3541737, lng: 18.579994 },
    { lat: 48.3546798, lng: 18.580878 },
    { lat: 48.3550617, lng: 18.581428 },
    { lat: 48.3552604, lng: 18.5820327 },
    { lat: 48.3560539, lng: 18.5835265 },
    { lat: 48.3562247, lng: 18.5840326 },
    { lat: 48.3566899, lng: 18.5845184 },
    { lat: 48.3570083, lng: 18.5847535 },
    { lat: 48.357145, lng: 18.5850438 },
    { lat: 48.3576669, lng: 18.5855788 },
    { lat: 48.3578923, lng: 18.5860051 },
    { lat: 48.3583484, lng: 18.5865928 },
    { lat: 48.3586343, lng: 18.5868301 },
    { lat: 48.3588284, lng: 18.5871205 },
    { lat: 48.3591843, lng: 18.5874028 },
    { lat: 48.359509, lng: 18.5874828 },
    { lat: 48.3595952, lng: 18.5877205 },
    { lat: 48.3604556, lng: 18.588436 },
    { lat: 48.3606704, lng: 18.5887168 },
    { lat: 48.3612245, lng: 18.5890733 },
    { lat: 48.3619772, lng: 18.5902607 },
    { lat: 48.3625785, lng: 18.5914397 },
    { lat: 48.3632998, lng: 18.5931279 },
    { lat: 48.3636217, lng: 18.5943376 },
    { lat: 48.3637795, lng: 18.5946663 },
    { lat: 48.3639912, lng: 18.5958197 },
    { lat: 48.3643149, lng: 18.5967937 },
    { lat: 48.364793, lng: 18.5952599 },
    { lat: 48.3667362, lng: 18.5923744 },
    { lat: 48.3673704, lng: 18.5926052 },
    { lat: 48.3687902, lng: 18.5928692 },
    { lat: 48.3692603, lng: 18.592057 },
    { lat: 48.3698079, lng: 18.5911133 },
    { lat: 48.3704529, lng: 18.590015 },
    { lat: 48.3705342, lng: 18.5899713 },
    { lat: 48.3701551, lng: 18.5896208 },
    { lat: 48.3698086, lng: 18.5891614 },
    { lat: 48.3682514, lng: 18.5857353 },
    { lat: 48.3679168, lng: 18.5851432 },
    { lat: 48.3672691, lng: 18.5843742 },
    { lat: 48.3668739, lng: 18.5837459 },
    { lat: 48.3667602, lng: 18.5834814 },
    { lat: 48.3662296, lng: 18.5827879 },
    { lat: 48.3660546, lng: 18.5824742 },
    { lat: 48.3659002, lng: 18.5823516 },
    { lat: 48.3656497, lng: 18.5820198 },
    { lat: 48.3651346, lng: 18.5812208 },
    { lat: 48.3649185, lng: 18.5807735 },
    { lat: 48.3647147, lng: 18.5805736 },
    { lat: 48.3643131, lng: 18.5797461 },
    { lat: 48.363626, lng: 18.5771228 },
    { lat: 48.3635993, lng: 18.5768196 },
    { lat: 48.3633762, lng: 18.5763054 },
    { lat: 48.3630669, lng: 18.5759089 },
    { lat: 48.3624887, lng: 18.5759165 },
    { lat: 48.3623984, lng: 18.5746138 },
    { lat: 48.3624949, lng: 18.5734903 },
    { lat: 48.3624873, lng: 18.5730426 },
    { lat: 48.3628273, lng: 18.5730493 },
    { lat: 48.3635126, lng: 18.5706669 },
    { lat: 48.3636281, lng: 18.570116 },
    { lat: 48.363705, lng: 18.569477 },
    { lat: 48.363446, lng: 18.569846 },
    { lat: 48.363296, lng: 18.569776 },
    { lat: 48.36295, lng: 18.569093 },
    { lat: 48.362901, lng: 18.569094 },
    { lat: 48.361829, lng: 18.568455 },
    { lat: 48.359472, lng: 18.567038 },
    { lat: 48.358694, lng: 18.566959 },
    { lat: 48.357568, lng: 18.563576 },
    { lat: 48.356877, lng: 18.557702 },
    { lat: 48.355892, lng: 18.556465 },
    { lat: 48.355789, lng: 18.556254 },
    { lat: 48.355643, lng: 18.555955 },
    { lat: 48.355478, lng: 18.55562 },
    { lat: 48.355163, lng: 18.554977 },
    { lat: 48.354661, lng: 18.553956 },
    { lat: 48.354574, lng: 18.553777 },
    { lat: 48.354415, lng: 18.553548 },
    { lat: 48.353662, lng: 18.552444 },
    { lat: 48.35311, lng: 18.551709 },
    { lat: 48.352702, lng: 18.551114 },
    { lat: 48.35299, lng: 18.549698 },
    { lat: 48.352997, lng: 18.549659 },
    { lat: 48.353012, lng: 18.549366 },
    { lat: 48.353023, lng: 18.549124 },
    { lat: 48.352864, lng: 18.548898 },
    { lat: 48.351411, lng: 18.546404 },
    { lat: 48.35089, lng: 18.546016 },
    { lat: 48.35059, lng: 18.545652 },
    { lat: 48.35018, lng: 18.544966 },
    { lat: 48.350159, lng: 18.544932 },
    { lat: 48.350011, lng: 18.545057 },
    { lat: 48.349816, lng: 18.54518 },
    { lat: 48.349442, lng: 18.545456 },
    { lat: 48.348829, lng: 18.546124 },
    { lat: 48.348528, lng: 18.546762 },
    { lat: 48.348359, lng: 18.547122 },
    { lat: 48.348158, lng: 18.547314 },
    { lat: 48.347758, lng: 18.5476 },
    { lat: 48.347438, lng: 18.547399 },
    { lat: 48.347146, lng: 18.547298 },
    { lat: 48.347064, lng: 18.547346 },
    { lat: 48.346882, lng: 18.54768 },
    { lat: 48.346819, lng: 18.547879 },
    { lat: 48.346376, lng: 18.548037 },
    { lat: 48.345873, lng: 18.547408 },
    { lat: 48.345766, lng: 18.547274 },
    { lat: 48.345691, lng: 18.547181 },
    { lat: 48.345635, lng: 18.547113 },
    { lat: 48.345555, lng: 18.547265 },
    { lat: 48.345533, lng: 18.54721 },
    { lat: 48.34531, lng: 18.546596 },
    { lat: 48.344851, lng: 18.545235 },
    { lat: 48.344776, lng: 18.545266 },
    { lat: 48.344714, lng: 18.545294 },
    { lat: 48.344688, lng: 18.545309 },
    { lat: 48.344781, lng: 18.545851 },
    { lat: 48.344957, lng: 18.547006 },
    { lat: 48.344997, lng: 18.547574 },
    { lat: 48.345058, lng: 18.547886 },
    { lat: 48.34478, lng: 18.54818 },
    { lat: 48.344756, lng: 18.548205 },
    { lat: 48.344752, lng: 18.547863 },
    { lat: 48.344716, lng: 18.547416 },
    { lat: 48.344652, lng: 18.54735 },
    { lat: 48.344484, lng: 18.547213 },
    { lat: 48.344336, lng: 18.547045 },
    { lat: 48.344186, lng: 18.546724 },
    { lat: 48.344172, lng: 18.546651 },
    { lat: 48.344129, lng: 18.54632 },
    { lat: 48.344085, lng: 18.546241 },
    { lat: 48.343916, lng: 18.545996 },
    { lat: 48.343975, lng: 18.545652 },
    { lat: 48.343918, lng: 18.545558 },
    { lat: 48.343718, lng: 18.544941 },
    { lat: 48.343533, lng: 18.544632 },
    { lat: 48.343589, lng: 18.544384 },
    { lat: 48.343529, lng: 18.544241 },
    { lat: 48.343251, lng: 18.544167 },
    { lat: 48.343197, lng: 18.543465 },
    { lat: 48.343051, lng: 18.543129 },
    { lat: 48.342703, lng: 18.542556 },
    { lat: 48.342414, lng: 18.541606 },
    { lat: 48.342309, lng: 18.540897 },
    { lat: 48.342325, lng: 18.539968 },
    { lat: 48.342324, lng: 18.538519 },
    { lat: 48.341845, lng: 18.537751 },
    { lat: 48.341496, lng: 18.537241 },
    { lat: 48.341319, lng: 18.536835 },
    { lat: 48.34103, lng: 18.536892 },
    { lat: 48.339952, lng: 18.537563 },
    { lat: 48.339201, lng: 18.538302 },
    { lat: 48.338516, lng: 18.53702 },
    { lat: 48.338491, lng: 18.536931 },
    { lat: 48.336159, lng: 18.540943 },
    { lat: 48.331925, lng: 18.536448 },
    { lat: 48.331884, lng: 18.536376 },
    { lat: 48.331799, lng: 18.536377 },
    { lat: 48.335836, lng: 18.530982 },
    { lat: 48.333002, lng: 18.525216 },
  ],
  Orovnica: [
    { lat: 48.4113014, lng: 18.5914938 },
    { lat: 48.4111135, lng: 18.5911902 },
    { lat: 48.4108146, lng: 18.5905279 },
    { lat: 48.41048, lng: 18.5900382 },
    { lat: 48.4102366, lng: 18.5895657 },
    { lat: 48.4099648, lng: 18.5887364 },
    { lat: 48.4098485, lng: 18.5878638 },
    { lat: 48.4095894, lng: 18.5871466 },
    { lat: 48.409233, lng: 18.5863802 },
    { lat: 48.4096075, lng: 18.5855524 },
    { lat: 48.4097884, lng: 18.58502 },
    { lat: 48.4098484, lng: 18.5844248 },
    { lat: 48.4098252, lng: 18.583834 },
    { lat: 48.4099045, lng: 18.5833855 },
    { lat: 48.4107912, lng: 18.5815059 },
    { lat: 48.411006, lng: 18.5812008 },
    { lat: 48.4111465, lng: 18.5811338 },
    { lat: 48.4111796, lng: 18.5808904 },
    { lat: 48.4113259, lng: 18.5809253 },
    { lat: 48.4114983, lng: 18.5805708 },
    { lat: 48.4122648, lng: 18.5794444 },
    { lat: 48.4126762, lng: 18.5783226 },
    { lat: 48.4133135, lng: 18.5771417 },
    { lat: 48.4135115, lng: 18.5765326 },
    { lat: 48.413979, lng: 18.5754657 },
    { lat: 48.4140739, lng: 18.5742304 },
    { lat: 48.4145679, lng: 18.5729472 },
    { lat: 48.4145186, lng: 18.572083 },
    { lat: 48.414588, lng: 18.5716405 },
    { lat: 48.4146254, lng: 18.5708175 },
    { lat: 48.4145212, lng: 18.5701707 },
    { lat: 48.4143812, lng: 18.5697963 },
    { lat: 48.41433, lng: 18.5694994 },
    { lat: 48.4140269, lng: 18.5690308 },
    { lat: 48.4133985, lng: 18.5668167 },
    { lat: 48.413478, lng: 18.5661266 },
    { lat: 48.4133363, lng: 18.5652884 },
    { lat: 48.4132604, lng: 18.5650088 },
    { lat: 48.412916, lng: 18.56374 },
    { lat: 48.412274, lng: 18.563247 },
    { lat: 48.411958, lng: 18.56339 },
    { lat: 48.411153, lng: 18.56375 },
    { lat: 48.410209, lng: 18.564154 },
    { lat: 48.409103, lng: 18.564606 },
    { lat: 48.408848, lng: 18.564894 },
    { lat: 48.408642, lng: 18.565119 },
    { lat: 48.408049, lng: 18.56572 },
    { lat: 48.40789, lng: 18.565909 },
    { lat: 48.407853, lng: 18.565952 },
    { lat: 48.407514, lng: 18.566355 },
    { lat: 48.407327, lng: 18.566419 },
    { lat: 48.407166, lng: 18.566431 },
    { lat: 48.407127, lng: 18.566434 },
    { lat: 48.40677, lng: 18.566462 },
    { lat: 48.406315, lng: 18.566464 },
    { lat: 48.405798, lng: 18.565926 },
    { lat: 48.405743, lng: 18.566035 },
    { lat: 48.405509, lng: 18.566126 },
    { lat: 48.40529, lng: 18.56626 },
    { lat: 48.405272, lng: 18.566586 },
    { lat: 48.405202, lng: 18.566851 },
    { lat: 48.405268, lng: 18.567084 },
    { lat: 48.405236, lng: 18.567237 },
    { lat: 48.405169, lng: 18.567386 },
    { lat: 48.405103, lng: 18.567813 },
    { lat: 48.405052, lng: 18.56797 },
    { lat: 48.405143, lng: 18.568294 },
    { lat: 48.405159, lng: 18.568504 },
    { lat: 48.405081, lng: 18.568706 },
    { lat: 48.405026, lng: 18.568724 },
    { lat: 48.404939, lng: 18.568864 },
    { lat: 48.404974, lng: 18.569058 },
    { lat: 48.404961, lng: 18.569307 },
    { lat: 48.404927, lng: 18.569506 },
    { lat: 48.404825, lng: 18.569632 },
    { lat: 48.404755, lng: 18.569785 },
    { lat: 48.404725, lng: 18.569819 },
    { lat: 48.40462, lng: 18.56994 },
    { lat: 48.404438, lng: 18.570263 },
    { lat: 48.404352, lng: 18.570435 },
    { lat: 48.404103, lng: 18.57063 },
    { lat: 48.404045, lng: 18.570748 },
    { lat: 48.403596, lng: 18.570793 },
    { lat: 48.403475, lng: 18.570527 },
    { lat: 48.402799, lng: 18.570349 },
    { lat: 48.402586, lng: 18.570435 },
    { lat: 48.402517, lng: 18.570241 },
    { lat: 48.402408, lng: 18.570178 },
    { lat: 48.402154, lng: 18.570273 },
    { lat: 48.401515, lng: 18.569815 },
    { lat: 48.400965, lng: 18.569922 },
    { lat: 48.400881, lng: 18.569669 },
    { lat: 48.400228, lng: 18.569577 },
    { lat: 48.399901, lng: 18.56952 },
    { lat: 48.399836, lng: 18.569533 },
    { lat: 48.399214, lng: 18.569618 },
    { lat: 48.398799, lng: 18.569228 },
    { lat: 48.398529, lng: 18.569301 },
    { lat: 48.398354, lng: 18.569139 },
    { lat: 48.398218, lng: 18.568748 },
    { lat: 48.397987, lng: 18.568747 },
    { lat: 48.397714, lng: 18.568375 },
    { lat: 48.396971, lng: 18.568526 },
    { lat: 48.396572, lng: 18.568551 },
    { lat: 48.396347, lng: 18.568796 },
    { lat: 48.396193, lng: 18.568677 },
    { lat: 48.395808, lng: 18.568783 },
    { lat: 48.395303, lng: 18.568652 },
    { lat: 48.395049, lng: 18.568703 },
    { lat: 48.394841, lng: 18.568699 },
    { lat: 48.394439, lng: 18.568596 },
    { lat: 48.393912, lng: 18.56863 },
    { lat: 48.39362, lng: 18.568805 },
    { lat: 48.393434, lng: 18.568675 },
    { lat: 48.393274, lng: 18.568862 },
    { lat: 48.392934, lng: 18.569095 },
    { lat: 48.392783, lng: 18.569157 },
    { lat: 48.392664, lng: 18.569479 },
    { lat: 48.392454, lng: 18.569614 },
    { lat: 48.391668, lng: 18.570124 },
    { lat: 48.390931, lng: 18.570304 },
    { lat: 48.390658, lng: 18.570005 },
    { lat: 48.390529, lng: 18.570047 },
    { lat: 48.390008, lng: 18.570393 },
    { lat: 48.389899, lng: 18.570511 },
    { lat: 48.389556, lng: 18.571117 },
    { lat: 48.389435, lng: 18.571454 },
    { lat: 48.388933, lng: 18.572198 },
    { lat: 48.388527, lng: 18.572652 },
    { lat: 48.388306, lng: 18.572868 },
    { lat: 48.387986, lng: 18.573007 },
    { lat: 48.387501, lng: 18.573767 },
    { lat: 48.387144, lng: 18.573921 },
    { lat: 48.387009, lng: 18.573742 },
    { lat: 48.386965, lng: 18.57377 },
    { lat: 48.386938, lng: 18.573748 },
    { lat: 48.38577, lng: 18.572698 },
    { lat: 48.385024, lng: 18.571946 },
    { lat: 48.384568, lng: 18.571871 },
    { lat: 48.383615, lng: 18.571804 },
    { lat: 48.383115, lng: 18.571985 },
    { lat: 48.382516, lng: 18.572245 },
    { lat: 48.382079, lng: 18.572491 },
    { lat: 48.381056, lng: 18.573131 },
    { lat: 48.380313, lng: 18.57346 },
    { lat: 48.379857, lng: 18.573647 },
    { lat: 48.378786, lng: 18.574116 },
    { lat: 48.37807, lng: 18.574528 },
    { lat: 48.377418, lng: 18.575075 },
    { lat: 48.376622, lng: 18.575823 },
    { lat: 48.375922, lng: 18.576243 },
    { lat: 48.375536, lng: 18.574364 },
    { lat: 48.374788, lng: 18.573529 },
    { lat: 48.373768, lng: 18.572547 },
    { lat: 48.373748, lng: 18.572528 },
    { lat: 48.373728, lng: 18.572502 },
    { lat: 48.373144, lng: 18.571813 },
    { lat: 48.37278, lng: 18.571267 },
    { lat: 48.372203, lng: 18.570336 },
    { lat: 48.371751, lng: 18.569357 },
    { lat: 48.371349, lng: 18.568528 },
    { lat: 48.370612, lng: 18.567087 },
    { lat: 48.37053, lng: 18.567201 },
    { lat: 48.370477, lng: 18.567273 },
    { lat: 48.369649, lng: 18.568426 },
    { lat: 48.369061, lng: 18.568442 },
    { lat: 48.368573, lng: 18.567658 },
    { lat: 48.368244, lng: 18.566963 },
    { lat: 48.36776, lng: 18.565867 },
    { lat: 48.3677221, lng: 18.5657758 },
    { lat: 48.367522, lng: 18.566007 },
    { lat: 48.367422, lng: 18.566109 },
    { lat: 48.367179, lng: 18.566404 },
    { lat: 48.366844, lng: 18.566467 },
    { lat: 48.366716, lng: 18.566623 },
    { lat: 48.366613, lng: 18.566795 },
    { lat: 48.366328, lng: 18.566844 },
    { lat: 48.366169, lng: 18.566945 },
    { lat: 48.366036, lng: 18.567115 },
    { lat: 48.365866, lng: 18.567245 },
    { lat: 48.365604, lng: 18.567633 },
    { lat: 48.365469, lng: 18.567765 },
    { lat: 48.365347, lng: 18.567747 },
    { lat: 48.365282, lng: 18.567738 },
    { lat: 48.365205, lng: 18.567726 },
    { lat: 48.364752, lng: 18.568076 },
    { lat: 48.364297, lng: 18.56843 },
    { lat: 48.364163, lng: 18.56856 },
    { lat: 48.363836, lng: 18.568688 },
    { lat: 48.363748, lng: 18.56897 },
    { lat: 48.363705, lng: 18.569477 },
    { lat: 48.3636281, lng: 18.570116 },
    { lat: 48.3635126, lng: 18.5706669 },
    { lat: 48.3628273, lng: 18.5730493 },
    { lat: 48.3624873, lng: 18.5730426 },
    { lat: 48.3624949, lng: 18.5734903 },
    { lat: 48.3623984, lng: 18.5746138 },
    { lat: 48.3624887, lng: 18.5759165 },
    { lat: 48.3630669, lng: 18.5759089 },
    { lat: 48.3633762, lng: 18.5763054 },
    { lat: 48.3635993, lng: 18.5768196 },
    { lat: 48.363626, lng: 18.5771228 },
    { lat: 48.3643131, lng: 18.5797461 },
    { lat: 48.3647147, lng: 18.5805736 },
    { lat: 48.3649185, lng: 18.5807735 },
    { lat: 48.3651346, lng: 18.5812208 },
    { lat: 48.3656497, lng: 18.5820198 },
    { lat: 48.3659002, lng: 18.5823516 },
    { lat: 48.3660546, lng: 18.5824742 },
    { lat: 48.3662296, lng: 18.5827879 },
    { lat: 48.3667602, lng: 18.5834814 },
    { lat: 48.3668739, lng: 18.5837459 },
    { lat: 48.3672691, lng: 18.5843742 },
    { lat: 48.3679168, lng: 18.5851432 },
    { lat: 48.3682514, lng: 18.5857353 },
    { lat: 48.3698086, lng: 18.5891614 },
    { lat: 48.3701551, lng: 18.5896208 },
    { lat: 48.3705342, lng: 18.5899713 },
    { lat: 48.3704529, lng: 18.590015 },
    { lat: 48.3698079, lng: 18.5911133 },
    { lat: 48.3702222, lng: 18.5918688 },
    { lat: 48.3706434, lng: 18.5924982 },
    { lat: 48.3717834, lng: 18.5937952 },
    { lat: 48.3722533, lng: 18.5944304 },
    { lat: 48.3724393, lng: 18.5950688 },
    { lat: 48.3724026, lng: 18.5961961 },
    { lat: 48.3723009, lng: 18.5973988 },
    { lat: 48.3721083, lng: 18.5978169 },
    { lat: 48.3720992, lng: 18.598166 },
    { lat: 48.3722123, lng: 18.5985463 },
    { lat: 48.3723724, lng: 18.5988176 },
    { lat: 48.3728707, lng: 18.600343 },
    { lat: 48.3733265, lng: 18.6019237 },
    { lat: 48.3733908, lng: 18.6025874 },
    { lat: 48.373665, lng: 18.6035234 },
    { lat: 48.3738426, lng: 18.6039476 },
    { lat: 48.3744634, lng: 18.604888 },
    { lat: 48.3756555, lng: 18.6057603 },
    { lat: 48.3758582, lng: 18.6067303 },
    { lat: 48.3764132, lng: 18.6077263 },
    { lat: 48.3770561, lng: 18.6084921 },
    { lat: 48.3772107, lng: 18.6085139 },
    { lat: 48.3780892, lng: 18.608096 },
    { lat: 48.3782256, lng: 18.6080133 },
    { lat: 48.3784395, lng: 18.6076951 },
    { lat: 48.3787238, lng: 18.6075085 },
    { lat: 48.3789973, lng: 18.6075609 },
    { lat: 48.3795038, lng: 18.6079349 },
    { lat: 48.3802512, lng: 18.6083221 },
    { lat: 48.3813487, lng: 18.6087761 },
    { lat: 48.381666, lng: 18.6088263 },
    { lat: 48.3819013, lng: 18.6088195 },
    { lat: 48.3825168, lng: 18.608542 },
    { lat: 48.3829709, lng: 18.6084516 },
    { lat: 48.3841989, lng: 18.6083177 },
    { lat: 48.3853497, lng: 18.6080959 },
    { lat: 48.385808, lng: 18.6081041 },
    { lat: 48.3870124, lng: 18.6078719 },
    { lat: 48.3873389, lng: 18.607922 },
    { lat: 48.3885065, lng: 18.6085708 },
    { lat: 48.3895712, lng: 18.6089207 },
    { lat: 48.3904339, lng: 18.6096207 },
    { lat: 48.3905154, lng: 18.6096291 },
    { lat: 48.3906796, lng: 18.6098071 },
    { lat: 48.3911824, lng: 18.6106959 },
    { lat: 48.3920052, lng: 18.6114757 },
    { lat: 48.3922459, lng: 18.6116482 },
    { lat: 48.3925628, lng: 18.6117731 },
    { lat: 48.393241, lng: 18.6117799 },
    { lat: 48.3935181, lng: 18.6119149 },
    { lat: 48.3943384, lng: 18.6128367 },
    { lat: 48.3946404, lng: 18.6134553 },
    { lat: 48.3947043, lng: 18.6137175 },
    { lat: 48.3947167, lng: 18.614029 },
    { lat: 48.3942198, lng: 18.6165159 },
    { lat: 48.3940122, lng: 18.617265 },
    { lat: 48.3937148, lng: 18.6191826 },
    { lat: 48.3937062, lng: 18.6196699 },
    { lat: 48.3938327, lng: 18.6201726 },
    { lat: 48.394026, lng: 18.6206377 },
    { lat: 48.3942869, lng: 18.6208943 },
    { lat: 48.3952038, lng: 18.6212277 },
    { lat: 48.3958859, lng: 18.6212009 },
    { lat: 48.3966435, lng: 18.6209837 },
    { lat: 48.3975131, lng: 18.6206315 },
    { lat: 48.398353, lng: 18.6205602 },
    { lat: 48.3987527, lng: 18.6206306 },
    { lat: 48.3985996, lng: 18.6195753 },
    { lat: 48.3986251, lng: 18.6193606 },
    { lat: 48.3988166, lng: 18.618873 },
    { lat: 48.398816, lng: 18.6186803 },
    { lat: 48.3985863, lng: 18.6184755 },
    { lat: 48.3984425, lng: 18.6180931 },
    { lat: 48.3986175, lng: 18.617595 },
    { lat: 48.3998156, lng: 18.6185138 },
    { lat: 48.4012006, lng: 18.6194505 },
    { lat: 48.4012758, lng: 18.6191723 },
    { lat: 48.4014957, lng: 18.6190041 },
    { lat: 48.4016958, lng: 18.6182875 },
    { lat: 48.4021267, lng: 18.6173726 },
    { lat: 48.4026445, lng: 18.6168301 },
    { lat: 48.4028966, lng: 18.6167368 },
    { lat: 48.4032995, lng: 18.6164309 },
    { lat: 48.4035732, lng: 18.6160287 },
    { lat: 48.4038443, lng: 18.6154528 },
    { lat: 48.4042271, lng: 18.6151284 },
    { lat: 48.4048755, lng: 18.6141226 },
    { lat: 48.4051627, lng: 18.6133693 },
    { lat: 48.4052821, lng: 18.6128865 },
    { lat: 48.4057812, lng: 18.6116518 },
    { lat: 48.4058743, lng: 18.6108723 },
    { lat: 48.4062946, lng: 18.6084332 },
    { lat: 48.4066415, lng: 18.6076967 },
    { lat: 48.4073392, lng: 18.6064199 },
    { lat: 48.408434, lng: 18.6045218 },
    { lat: 48.4084856, lng: 18.6042439 },
    { lat: 48.40857, lng: 18.6040762 },
    { lat: 48.4086578, lng: 18.6032426 },
    { lat: 48.4085724, lng: 18.6025228 },
    { lat: 48.4081539, lng: 18.6018765 },
    { lat: 48.4079661, lng: 18.6012211 },
    { lat: 48.4073834, lng: 18.5996109 },
    { lat: 48.4068233, lng: 18.5984388 },
    { lat: 48.4069421, lng: 18.5981541 },
    { lat: 48.4073507, lng: 18.5978047 },
    { lat: 48.4080994, lng: 18.5972631 },
    { lat: 48.4087036, lng: 18.5969433 },
    { lat: 48.4091904, lng: 18.596437 },
    { lat: 48.4093335, lng: 18.5959168 },
    { lat: 48.4097221, lng: 18.5952435 },
    { lat: 48.4101808, lng: 18.5938652 },
    { lat: 48.4107619, lng: 18.5928346 },
    { lat: 48.4113014, lng: 18.5914938 },
  ],
  VeľkéPole: [
    { lat: 48.566948, lng: 18.553472 },
    { lat: 48.566926, lng: 18.553475 },
    { lat: 48.566685, lng: 18.5532 },
    { lat: 48.566615, lng: 18.553065 },
    { lat: 48.566454, lng: 18.552739 },
    { lat: 48.566232, lng: 18.55227 },
    { lat: 48.566132, lng: 18.552061 },
    { lat: 48.565893, lng: 18.551402 },
    { lat: 48.565614, lng: 18.550347 },
    { lat: 48.565361, lng: 18.549973 },
    { lat: 48.564829, lng: 18.549414 },
    { lat: 48.56456, lng: 18.549104 },
    { lat: 48.564355, lng: 18.548799 },
    { lat: 48.56373, lng: 18.547714 },
    { lat: 48.563561, lng: 18.547446 },
    { lat: 48.563748, lng: 18.546599 },
    { lat: 48.563748, lng: 18.545708 },
    { lat: 48.563237, lng: 18.544827 },
    { lat: 48.562937, lng: 18.544308 },
    { lat: 48.563604, lng: 18.544161 },
    { lat: 48.563796, lng: 18.544118 },
    { lat: 48.56421, lng: 18.544102 },
    { lat: 48.565056, lng: 18.544126 },
    { lat: 48.564993, lng: 18.542912 },
    { lat: 48.564981, lng: 18.542358 },
    { lat: 48.564912, lng: 18.540836 },
    { lat: 48.564874, lng: 18.540071 },
    { lat: 48.564415, lng: 18.540027 },
    { lat: 48.564213, lng: 18.539684 },
    { lat: 48.564121, lng: 18.539536 },
    { lat: 48.564098, lng: 18.539498 },
    { lat: 48.564081, lng: 18.539465 },
    { lat: 48.563761, lng: 18.538918 },
    { lat: 48.563752, lng: 18.538129 },
    { lat: 48.563651, lng: 18.537568 },
    { lat: 48.563446, lng: 18.537158 },
    { lat: 48.563323, lng: 18.536972 },
    { lat: 48.563151, lng: 18.536712 },
    { lat: 48.563255, lng: 18.536207 },
    { lat: 48.56324, lng: 18.536179 },
    { lat: 48.56323, lng: 18.536162 },
    { lat: 48.563282, lng: 18.536131 },
    { lat: 48.563394, lng: 18.536149 },
    { lat: 48.563455, lng: 18.536149 },
    { lat: 48.563507, lng: 18.536117 },
    { lat: 48.563543, lng: 18.536063 },
    { lat: 48.563635, lng: 18.536002 },
    { lat: 48.563666, lng: 18.535867 },
    { lat: 48.563766, lng: 18.535784 },
    { lat: 48.563801, lng: 18.53574 },
    { lat: 48.563862, lng: 18.535741 },
    { lat: 48.563876, lng: 18.5357 },
    { lat: 48.563849, lng: 18.535533 },
    { lat: 48.563862, lng: 18.535432 },
    { lat: 48.563906, lng: 18.535341 },
    { lat: 48.56398, lng: 18.53532 },
    { lat: 48.564026, lng: 18.535311 },
    { lat: 48.564071, lng: 18.535229 },
    { lat: 48.564129, lng: 18.535161 },
    { lat: 48.564119, lng: 18.535002 },
    { lat: 48.564131, lng: 18.535004 },
    { lat: 48.564145, lng: 18.535006 },
    { lat: 48.564439, lng: 18.53493 },
    { lat: 48.564512, lng: 18.535139 },
    { lat: 48.564641, lng: 18.535188 },
    { lat: 48.56475, lng: 18.53523 },
    { lat: 48.564758, lng: 18.534441 },
    { lat: 48.564583, lng: 18.534254 },
    { lat: 48.564577, lng: 18.534248 },
    { lat: 48.56456, lng: 18.53423 },
    { lat: 48.564388, lng: 18.534046 },
    { lat: 48.56416, lng: 18.533773 },
    { lat: 48.563969, lng: 18.533509 },
    { lat: 48.563705, lng: 18.53314 },
    { lat: 48.563388, lng: 18.532644 },
    { lat: 48.563306, lng: 18.532556 },
    { lat: 48.563284, lng: 18.532535 },
    { lat: 48.56306, lng: 18.532321 },
    { lat: 48.562675, lng: 18.531997 },
    { lat: 48.561869, lng: 18.531274 },
    { lat: 48.561845, lng: 18.53124 },
    { lat: 48.561813, lng: 18.531193 },
    { lat: 48.561514, lng: 18.530943 },
    { lat: 48.561416, lng: 18.53093 },
    { lat: 48.561325, lng: 18.530773 },
    { lat: 48.561568, lng: 18.529847 },
    { lat: 48.561649, lng: 18.529636 },
    { lat: 48.56189, lng: 18.529135 },
    { lat: 48.562164, lng: 18.5287 },
    { lat: 48.562383, lng: 18.528753 },
    { lat: 48.56246, lng: 18.528369 },
    { lat: 48.562505, lng: 18.528044 },
    { lat: 48.562583, lng: 18.527493 },
    { lat: 48.562837, lng: 18.526849 },
    { lat: 48.563, lng: 18.526492 },
    { lat: 48.563142, lng: 18.526159 },
    { lat: 48.563376, lng: 18.525754 },
    { lat: 48.563448, lng: 18.525598 },
    { lat: 48.563471, lng: 18.525486 },
    { lat: 48.563508, lng: 18.525315 },
    { lat: 48.56351, lng: 18.525295 },
    { lat: 48.563494, lng: 18.525107 },
    { lat: 48.563489, lng: 18.525029 },
    { lat: 48.563481, lng: 18.524928 },
    { lat: 48.563479, lng: 18.524914 },
    { lat: 48.562875, lng: 18.524622 },
    { lat: 48.56295, lng: 18.524163 },
    { lat: 48.563001, lng: 18.523816 },
    { lat: 48.563076, lng: 18.52318 },
    { lat: 48.563066, lng: 18.522748 },
    { lat: 48.563064, lng: 18.522696 },
    { lat: 48.563063, lng: 18.522607 },
    { lat: 48.563143, lng: 18.521873 },
    { lat: 48.563177, lng: 18.521556 },
    { lat: 48.563188, lng: 18.521462 },
    { lat: 48.563198, lng: 18.521372 },
    { lat: 48.563215, lng: 18.521212 },
    { lat: 48.563221, lng: 18.520523 },
    { lat: 48.563242, lng: 18.520046 },
    { lat: 48.563311, lng: 18.519647 },
    { lat: 48.563343, lng: 18.519196 },
    { lat: 48.563339, lng: 18.51913 },
    { lat: 48.563316, lng: 18.518882 },
    { lat: 48.563317, lng: 18.518856 },
    { lat: 48.563318, lng: 18.518807 },
    { lat: 48.563323, lng: 18.518489 },
    { lat: 48.563349, lng: 18.517652 },
    { lat: 48.563475, lng: 18.517468 },
    { lat: 48.563698, lng: 18.517416 },
    { lat: 48.563963, lng: 18.517297 },
    { lat: 48.564109, lng: 18.517266 },
    { lat: 48.565, lng: 18.517425 },
    { lat: 48.565161, lng: 18.517423 },
    { lat: 48.565216, lng: 18.517439 },
    { lat: 48.565246, lng: 18.517447 },
    { lat: 48.565318, lng: 18.517468 },
    { lat: 48.565343, lng: 18.517159 },
    { lat: 48.565353, lng: 18.517011 },
    { lat: 48.565377, lng: 18.516935 },
    { lat: 48.565457, lng: 18.516662 },
    { lat: 48.565612, lng: 18.516296 },
    { lat: 48.565304, lng: 18.516017 },
    { lat: 48.564904, lng: 18.515708 },
    { lat: 48.564464, lng: 18.515341 },
    { lat: 48.564315, lng: 18.515216 },
    { lat: 48.563792, lng: 18.514491 },
    { lat: 48.563494, lng: 18.513743 },
    { lat: 48.562987, lng: 18.513114 },
    { lat: 48.56301, lng: 18.513064 },
    { lat: 48.563017, lng: 18.513048 },
    { lat: 48.562945, lng: 18.513005 },
    { lat: 48.562911, lng: 18.51298 },
    { lat: 48.562849, lng: 18.512916 },
    { lat: 48.562834, lng: 18.512714 },
    { lat: 48.562793, lng: 18.512647 },
    { lat: 48.562696, lng: 18.512562 },
    { lat: 48.562663, lng: 18.512518 },
    { lat: 48.562654, lng: 18.512467 },
    { lat: 48.562615, lng: 18.512483 },
    { lat: 48.562462, lng: 18.512325 },
    { lat: 48.56245, lng: 18.512266 },
    { lat: 48.562434, lng: 18.512191 },
    { lat: 48.562429, lng: 18.512041 },
    { lat: 48.562344, lng: 18.511952 },
    { lat: 48.562352, lng: 18.511928 },
    { lat: 48.562332, lng: 18.511911 },
    { lat: 48.562403, lng: 18.511134 },
    { lat: 48.562534, lng: 18.510981 },
    { lat: 48.56252, lng: 18.510946 },
    { lat: 48.562853, lng: 18.510597 },
    { lat: 48.563043, lng: 18.510197 },
    { lat: 48.563061, lng: 18.509833 },
    { lat: 48.563026, lng: 18.509242 },
    { lat: 48.562948, lng: 18.508945 },
    { lat: 48.562924, lng: 18.508797 },
    { lat: 48.562877, lng: 18.50849 },
    { lat: 48.562816, lng: 18.507835 },
    { lat: 48.562761, lng: 18.507628 },
    { lat: 48.562643, lng: 18.507191 },
    { lat: 48.562274, lng: 18.506122 },
    { lat: 48.56237, lng: 18.506001 },
    { lat: 48.562396, lng: 18.50577 },
    { lat: 48.56248, lng: 18.505376 },
    { lat: 48.562697, lng: 18.504894 },
    { lat: 48.562765, lng: 18.504736 },
    { lat: 48.56293, lng: 18.50435 },
    { lat: 48.563123, lng: 18.503397 },
    { lat: 48.5633, lng: 18.502673 },
    { lat: 48.563644, lng: 18.501267 },
    { lat: 48.563708, lng: 18.500943 },
    { lat: 48.563719, lng: 18.500891 },
    { lat: 48.56388, lng: 18.500082 },
    { lat: 48.564577, lng: 18.500322 },
    { lat: 48.56481, lng: 18.500603 },
    { lat: 48.565014, lng: 18.500341 },
    { lat: 48.565282, lng: 18.500037 },
    { lat: 48.565531, lng: 18.499653 },
    { lat: 48.565848, lng: 18.499756 },
    { lat: 48.566114, lng: 18.499842 },
    { lat: 48.566389, lng: 18.499434 },
    { lat: 48.566757, lng: 18.499034 },
    { lat: 48.566842, lng: 18.499151 },
    { lat: 48.566964, lng: 18.499068 },
    { lat: 48.567385, lng: 18.498384 },
    { lat: 48.567548, lng: 18.498167 },
    { lat: 48.567742, lng: 18.497936 },
    { lat: 48.568039, lng: 18.497957 },
    { lat: 48.568355, lng: 18.497945 },
    { lat: 48.568434, lng: 18.497909 },
    { lat: 48.568577, lng: 18.497889 },
    { lat: 48.568583, lng: 18.497823 },
    { lat: 48.56867, lng: 18.497758 },
    { lat: 48.568849, lng: 18.497498 },
    { lat: 48.568849, lng: 18.497467 },
    { lat: 48.568598, lng: 18.497484 },
    { lat: 48.568295, lng: 18.497381 },
    { lat: 48.567652, lng: 18.497136 },
    { lat: 48.567463, lng: 18.497055 },
    { lat: 48.567213, lng: 18.496992 },
    { lat: 48.566788, lng: 18.496795 },
    { lat: 48.566479, lng: 18.496689 },
    { lat: 48.566144, lng: 18.4964863 },
    { lat: 48.565997, lng: 18.496331 },
    { lat: 48.565763, lng: 18.496241 },
    { lat: 48.56512, lng: 18.495973 },
    { lat: 48.5649354, lng: 18.4958571 },
    { lat: 48.564584, lng: 18.495555 },
    { lat: 48.564399, lng: 18.495319 },
    { lat: 48.5643046, lng: 18.4951878 },
    { lat: 48.564253, lng: 18.495116 },
    { lat: 48.564108, lng: 18.495095 },
    { lat: 48.563639, lng: 18.495152 },
    { lat: 48.563639, lng: 18.495133 },
    { lat: 48.562533, lng: 18.494525 },
    { lat: 48.561972, lng: 18.494209 },
    { lat: 48.561635, lng: 18.49402 },
    { lat: 48.561447, lng: 18.493916 },
    { lat: 48.561141, lng: 18.493754 },
    { lat: 48.560803, lng: 18.493598 },
    { lat: 48.560511, lng: 18.493594 },
    { lat: 48.560192, lng: 18.493504 },
    { lat: 48.560105, lng: 18.493461 },
    { lat: 48.559966, lng: 18.493335 },
    { lat: 48.559794, lng: 18.493125 },
    { lat: 48.559686, lng: 18.49304 },
    { lat: 48.559356, lng: 18.492913 },
    { lat: 48.559183, lng: 18.492713 },
    { lat: 48.558961, lng: 18.492606 },
    { lat: 48.558567, lng: 18.492559 },
    { lat: 48.55827, lng: 18.492389 },
    { lat: 48.558087, lng: 18.492282 },
    { lat: 48.557638, lng: 18.491887 },
    { lat: 48.557507, lng: 18.491784 },
    { lat: 48.557259, lng: 18.491605 },
    { lat: 48.557033, lng: 18.491415 },
    { lat: 48.55674, lng: 18.491333 },
    { lat: 48.556486, lng: 18.491213 },
    { lat: 48.55638, lng: 18.491184 },
    { lat: 48.555942, lng: 18.491065 },
    { lat: 48.555496, lng: 18.490838 },
    { lat: 48.554794, lng: 18.490465 },
    { lat: 48.554345, lng: 18.49019 },
    { lat: 48.554181, lng: 18.490117 },
    { lat: 48.553842, lng: 18.48992 },
    { lat: 48.553705, lng: 18.489775 },
    { lat: 48.553229, lng: 18.48956 },
    { lat: 48.55313, lng: 18.489492 },
    { lat: 48.552877, lng: 18.489381 },
    { lat: 48.552682, lng: 18.489233 },
    { lat: 48.552531, lng: 18.48915 },
    { lat: 48.552346, lng: 18.489037 },
    { lat: 48.551969, lng: 18.488865 },
    { lat: 48.551495, lng: 18.488558 },
    { lat: 48.551302, lng: 18.488454 },
    { lat: 48.550974, lng: 18.488254 },
    { lat: 48.550466, lng: 18.487972 },
    { lat: 48.550379, lng: 18.487901 },
    { lat: 48.550339, lng: 18.487869 },
    { lat: 48.550189, lng: 18.487823 },
    { lat: 48.550041, lng: 18.487739 },
    { lat: 48.549333, lng: 18.487337 },
    { lat: 48.549302, lng: 18.487335 },
    { lat: 48.549216, lng: 18.487068 },
    { lat: 48.54909, lng: 18.486761 },
    { lat: 48.548999, lng: 18.48635 },
    { lat: 48.548998, lng: 18.485835 },
    { lat: 48.548888, lng: 18.485327 },
    { lat: 48.548693, lng: 18.484578 },
    { lat: 48.54853, lng: 18.483852 },
    { lat: 48.548456, lng: 18.483582 },
    { lat: 48.548318, lng: 18.48309 },
    { lat: 48.548332, lng: 18.482904 },
    { lat: 48.548662, lng: 18.482294 },
    { lat: 48.548852, lng: 18.48165 },
    { lat: 48.54897, lng: 18.480869 },
    { lat: 48.548902, lng: 18.479932 },
    { lat: 48.54876, lng: 18.479509 },
    { lat: 48.548602, lng: 18.478673 },
    { lat: 48.548578, lng: 18.478683 },
    { lat: 48.548348, lng: 18.479153 },
    { lat: 48.548081, lng: 18.479733 },
    { lat: 48.547632, lng: 18.480819 },
    { lat: 48.547162, lng: 18.481864 },
    { lat: 48.546925, lng: 18.482448 },
    { lat: 48.546738, lng: 18.482895 },
    { lat: 48.546547, lng: 18.483398 },
    { lat: 48.546396, lng: 18.483793 },
    { lat: 48.546233, lng: 18.484316 },
    { lat: 48.545921, lng: 18.485318 },
    { lat: 48.54577, lng: 18.485737 },
    { lat: 48.544087, lng: 18.488177 },
    { lat: 48.543823, lng: 18.488809 },
    { lat: 48.542173, lng: 18.490058 },
    { lat: 48.54137, lng: 18.490667 },
    { lat: 48.539402, lng: 18.492278 },
    { lat: 48.538581, lng: 18.491963 },
    { lat: 48.538505, lng: 18.491934 },
    { lat: 48.538044, lng: 18.491756 },
    { lat: 48.537811, lng: 18.491756 },
    { lat: 48.537539, lng: 18.49176 },
    { lat: 48.536454, lng: 18.492229 },
    { lat: 48.535241, lng: 18.492726 },
    { lat: 48.534645, lng: 18.493225 },
    { lat: 48.532818, lng: 18.495137 },
    { lat: 48.531181, lng: 18.497702 },
    { lat: 48.530541, lng: 18.499004 },
    { lat: 48.527662, lng: 18.501222 },
    { lat: 48.526684, lng: 18.501769 },
    { lat: 48.526003, lng: 18.502519 },
    { lat: 48.525731, lng: 18.505418 },
    { lat: 48.525704, lng: 18.505719 },
    { lat: 48.525681, lng: 18.505888 },
    { lat: 48.525672, lng: 18.505958 },
    { lat: 48.525578, lng: 18.506664 },
    { lat: 48.525652, lng: 18.506744 },
    { lat: 48.525966, lng: 18.507622 },
    { lat: 48.526138, lng: 18.507897 },
    { lat: 48.526356, lng: 18.50814 },
    { lat: 48.526662, lng: 18.509459 },
    { lat: 48.527851, lng: 18.511064 },
    { lat: 48.528463, lng: 18.512881 },
    { lat: 48.528648, lng: 18.514031 },
    { lat: 48.528946, lng: 18.518049 },
    { lat: 48.5289628, lng: 18.5184263 },
    { lat: 48.5303983, lng: 18.5198939 },
    { lat: 48.5303462, lng: 18.5207364 },
    { lat: 48.529041, lng: 18.5232612 },
    { lat: 48.5279663, lng: 18.5243127 },
    { lat: 48.5264399, lng: 18.5264257 },
    { lat: 48.5249217, lng: 18.5287105 },
    { lat: 48.5240803, lng: 18.5294961 },
    { lat: 48.5235541, lng: 18.5297327 },
    { lat: 48.5227684, lng: 18.52972 },
    { lat: 48.5224627, lng: 18.5298662 },
    { lat: 48.5224763, lng: 18.5299897 },
    { lat: 48.5219714, lng: 18.5311915 },
    { lat: 48.5215585, lng: 18.5319128 },
    { lat: 48.5213944, lng: 18.5334035 },
    { lat: 48.5206106, lng: 18.5343733 },
    { lat: 48.5203267, lng: 18.5353063 },
    { lat: 48.5200775, lng: 18.5378414 },
    { lat: 48.5199403, lng: 18.53859 },
    { lat: 48.5198433, lng: 18.5394574 },
    { lat: 48.5199217, lng: 18.5409268 },
    { lat: 48.5200672, lng: 18.5417499 },
    { lat: 48.5204621, lng: 18.5427203 },
    { lat: 48.5205993, lng: 18.5433537 },
    { lat: 48.5210185, lng: 18.5442749 },
    { lat: 48.5204262, lng: 18.5473439 },
    { lat: 48.5197101, lng: 18.5503599 },
    { lat: 48.5195082, lng: 18.5523443 },
    { lat: 48.5194694, lng: 18.555039 },
    { lat: 48.5185602, lng: 18.5579701 },
    { lat: 48.5172312, lng: 18.5628813 },
    { lat: 48.5165664, lng: 18.5644869 },
    { lat: 48.5159296, lng: 18.5649331 },
    { lat: 48.5155168, lng: 18.565923 },
    { lat: 48.5150609, lng: 18.5666268 },
    { lat: 48.5138196, lng: 18.5693675 },
    { lat: 48.5134993, lng: 18.5699648 },
    { lat: 48.5131057, lng: 18.5710661 },
    { lat: 48.5128318, lng: 18.5714222 },
    { lat: 48.5125589, lng: 18.5720614 },
    { lat: 48.512504, lng: 18.5725897 },
    { lat: 48.5125078, lng: 18.5726771 },
    { lat: 48.512563, lng: 18.5726749 },
    { lat: 48.5132344, lng: 18.5732189 },
    { lat: 48.5137405, lng: 18.5743745 },
    { lat: 48.5139727, lng: 18.5742455 },
    { lat: 48.5147321, lng: 18.574398 },
    { lat: 48.5154285, lng: 18.5741986 },
    { lat: 48.5162136, lng: 18.574158 },
    { lat: 48.5163189, lng: 18.5742873 },
    { lat: 48.5165356, lng: 18.5748074 },
    { lat: 48.5167466, lng: 18.5751226 },
    { lat: 48.5182515, lng: 18.5744011 },
    { lat: 48.5183516, lng: 18.574477 },
    { lat: 48.5185757, lng: 18.5754456 },
    { lat: 48.5186812, lng: 18.5756816 },
    { lat: 48.5188628, lng: 18.5772599 },
    { lat: 48.5189217, lng: 18.5774291 },
    { lat: 48.5191682, lng: 18.5778331 },
    { lat: 48.519759, lng: 18.5784512 },
    { lat: 48.5200696, lng: 18.5785392 },
    { lat: 48.5201732, lng: 18.5787872 },
    { lat: 48.5207341, lng: 18.5789148 },
    { lat: 48.5210566, lng: 18.5791195 },
    { lat: 48.5209591, lng: 18.5797357 },
    { lat: 48.5210196, lng: 18.5797702 },
    { lat: 48.520941, lng: 18.5803367 },
    { lat: 48.521046, lng: 18.580359 },
    { lat: 48.5214391, lng: 18.5808855 },
    { lat: 48.5216001, lng: 18.5813766 },
    { lat: 48.5215778, lng: 18.581944 },
    { lat: 48.5219671, lng: 18.5825059 },
    { lat: 48.5221757, lng: 18.5826691 },
    { lat: 48.5223017, lng: 18.5825814 },
    { lat: 48.5228119, lng: 18.5826498 },
    { lat: 48.5229196, lng: 18.5831791 },
    { lat: 48.5232678, lng: 18.5836102 },
    { lat: 48.523297, lng: 18.5836251 },
    { lat: 48.5233607, lng: 18.5829095 },
    { lat: 48.5234841, lng: 18.5828765 },
    { lat: 48.5236499, lng: 18.5830653 },
    { lat: 48.5237332, lng: 18.5834753 },
    { lat: 48.52448, lng: 18.5838515 },
    { lat: 48.5237417, lng: 18.5864318 },
    { lat: 48.5240535, lng: 18.5878938 },
    { lat: 48.5241276, lng: 18.5879904 },
    { lat: 48.5246768, lng: 18.5878235 },
    { lat: 48.5249233, lng: 18.5878985 },
    { lat: 48.525628, lng: 18.5883921 },
    { lat: 48.5258891, lng: 18.5883806 },
    { lat: 48.5264043, lng: 18.5885844 },
    { lat: 48.526936, lng: 18.5890263 },
    { lat: 48.5272352, lng: 18.5891166 },
    { lat: 48.5279999, lng: 18.5889925 },
    { lat: 48.5281461, lng: 18.5890436 },
    { lat: 48.52835, lng: 18.5897899 },
    { lat: 48.528457, lng: 18.5896803 },
    { lat: 48.5287118, lng: 18.5896148 },
    { lat: 48.5286827, lng: 18.5894362 },
    { lat: 48.5288859, lng: 18.5891261 },
    { lat: 48.5292277, lng: 18.5892517 },
    { lat: 48.5293361, lng: 18.5895034 },
    { lat: 48.5295518, lng: 18.5895281 },
    { lat: 48.5297051, lng: 18.5891943 },
    { lat: 48.5297794, lng: 18.5891695 },
    { lat: 48.5301063, lng: 18.5891279 },
    { lat: 48.5301487, lng: 18.5890547 },
    { lat: 48.5306294, lng: 18.5889535 },
    { lat: 48.530749, lng: 18.5891409 },
    { lat: 48.5309484, lng: 18.5890733 },
    { lat: 48.5311, lng: 18.5889582 },
    { lat: 48.5311357, lng: 18.5887624 },
    { lat: 48.5312376, lng: 18.5887505 },
    { lat: 48.5315126, lng: 18.588517 },
    { lat: 48.531648, lng: 18.5884989 },
    { lat: 48.5317335, lng: 18.5887251 },
    { lat: 48.5318978, lng: 18.5887778 },
    { lat: 48.5319578, lng: 18.5887811 },
    { lat: 48.5320469, lng: 18.5885762 },
    { lat: 48.5322895, lng: 18.5886399 },
    { lat: 48.5325918, lng: 18.5883699 },
    { lat: 48.5327065, lng: 18.5881757 },
    { lat: 48.5328305, lng: 18.5884864 },
    { lat: 48.5331258, lng: 18.5885842 },
    { lat: 48.5332036, lng: 18.5886668 },
    { lat: 48.5333986, lng: 18.5886464 },
    { lat: 48.5337491, lng: 18.5887627 },
    { lat: 48.5339645, lng: 18.5885991 },
    { lat: 48.5341793, lng: 18.588294 },
    { lat: 48.5344945, lng: 18.5881389 },
    { lat: 48.5347987, lng: 18.5881313 },
    { lat: 48.5352399, lng: 18.5879072 },
    { lat: 48.5354507, lng: 18.5875503 },
    { lat: 48.5355741, lng: 18.5875596 },
    { lat: 48.5356679, lng: 18.5876669 },
    { lat: 48.5360991, lng: 18.5875683 },
    { lat: 48.536203, lng: 18.5871978 },
    { lat: 48.5368396, lng: 18.5869386 },
    { lat: 48.5370478, lng: 18.587177 },
    { lat: 48.5372666, lng: 18.5873238 },
    { lat: 48.5375648, lng: 18.5873739 },
    { lat: 48.5380171, lng: 18.5871858 },
    { lat: 48.5382277, lng: 18.5872193 },
    { lat: 48.5384265, lng: 18.5871204 },
    { lat: 48.5386291, lng: 18.5871339 },
    { lat: 48.538612, lng: 18.5868192 },
    { lat: 48.5386443, lng: 18.5867316 },
    { lat: 48.5388755, lng: 18.5865839 },
    { lat: 48.5390577, lng: 18.5863657 },
    { lat: 48.5394366, lng: 18.5861564 },
    { lat: 48.5396429, lng: 18.5862395 },
    { lat: 48.5398173, lng: 18.586438 },
    { lat: 48.540133, lng: 18.5866024 },
    { lat: 48.5402455, lng: 18.5867416 },
    { lat: 48.5405037, lng: 18.5866994 },
    { lat: 48.540676, lng: 18.5869213 },
    { lat: 48.5408225, lng: 18.5869823 },
    { lat: 48.5410819, lng: 18.5872497 },
    { lat: 48.5412331, lng: 18.58723 },
    { lat: 48.5413484, lng: 18.5873827 },
    { lat: 48.541534, lng: 18.5873531 },
    { lat: 48.5419025, lng: 18.5874875 },
    { lat: 48.5421973, lng: 18.5875191 },
    { lat: 48.5426993, lng: 18.5874577 },
    { lat: 48.5433197, lng: 18.5877497 },
    { lat: 48.5437216, lng: 18.5876283 },
    { lat: 48.543949, lng: 18.5877683 },
    { lat: 48.5442718, lng: 18.5874576 },
    { lat: 48.5445219, lng: 18.5873937 },
    { lat: 48.5446736, lng: 18.5874231 },
    { lat: 48.544915, lng: 18.5872154 },
    { lat: 48.5452076, lng: 18.5870989 },
    { lat: 48.5455754, lng: 18.5872241 },
    { lat: 48.5457608, lng: 18.5871923 },
    { lat: 48.5460115, lng: 18.5873202 },
    { lat: 48.5463627, lng: 18.5872891 },
    { lat: 48.5465779, lng: 18.5874092 },
    { lat: 48.546702, lng: 18.5878884 },
    { lat: 48.5470102, lng: 18.5879141 },
    { lat: 48.5472161, lng: 18.5881935 },
    { lat: 48.5473674, lng: 18.5881991 },
    { lat: 48.5476792, lng: 18.5886829 },
    { lat: 48.5482105, lng: 18.588746 },
    { lat: 48.5486282, lng: 18.5886906 },
    { lat: 48.5496824, lng: 18.5883917 },
    { lat: 48.5499425, lng: 18.5883629 },
    { lat: 48.5502305, lng: 18.5884306 },
    { lat: 48.5507861, lng: 18.5883426 },
    { lat: 48.5509994, lng: 18.5882177 },
    { lat: 48.5515415, lng: 18.5883546 },
    { lat: 48.5516641, lng: 18.5885766 },
    { lat: 48.5517239, lng: 18.5885977 },
    { lat: 48.5519366, lng: 18.5884006 },
    { lat: 48.5534498, lng: 18.5882719 },
    { lat: 48.5535161, lng: 18.5882217 },
    { lat: 48.5536684, lng: 18.5878712 },
    { lat: 48.5540379, lng: 18.587737 },
    { lat: 48.5542849, lng: 18.5878422 },
    { lat: 48.5545532, lng: 18.5877992 },
    { lat: 48.5549161, lng: 18.5879176 },
    { lat: 48.5550018, lng: 18.5876453 },
    { lat: 48.5553793, lng: 18.5875547 },
    { lat: 48.5554636, lng: 18.5876756 },
    { lat: 48.5558026, lng: 18.587751 },
    { lat: 48.5559855, lng: 18.5876669 },
    { lat: 48.5565429, lng: 18.5867362 },
    { lat: 48.5568662, lng: 18.586397 },
    { lat: 48.557189, lng: 18.5866096 },
    { lat: 48.5573331, lng: 18.586604 },
    { lat: 48.5579465, lng: 18.5862079 },
    { lat: 48.558345, lng: 18.5863176 },
    { lat: 48.5587126, lng: 18.5860655 },
    { lat: 48.5587828, lng: 18.5858004 },
    { lat: 48.559495, lng: 18.5857668 },
    { lat: 48.5596823, lng: 18.5859769 },
    { lat: 48.5599338, lng: 18.5859769 },
    { lat: 48.5601789, lng: 18.585878 },
    { lat: 48.5603968, lng: 18.5860901 },
    { lat: 48.5605163, lng: 18.586337 },
    { lat: 48.5607572, lng: 18.5864106 },
    { lat: 48.5609656, lng: 18.5866748 },
    { lat: 48.5611778, lng: 18.5870962 },
    { lat: 48.5616957, lng: 18.5872012 },
    { lat: 48.5621981, lng: 18.587479 },
    { lat: 48.5622378, lng: 18.5876518 },
    { lat: 48.5625624, lng: 18.588063 },
    { lat: 48.5627733, lng: 18.5882902 },
    { lat: 48.5630072, lng: 18.5884024 },
    { lat: 48.5631147, lng: 18.588575 },
    { lat: 48.5633154, lng: 18.5887376 },
    { lat: 48.5636639, lng: 18.5887178 },
    { lat: 48.5636924, lng: 18.5889119 },
    { lat: 48.5637754, lng: 18.588964 },
    { lat: 48.564119, lng: 18.5887508 },
    { lat: 48.5646522, lng: 18.5890761 },
    { lat: 48.5652663, lng: 18.5891906 },
    { lat: 48.56584, lng: 18.5891829 },
    { lat: 48.5659516, lng: 18.5890524 },
    { lat: 48.5661641, lng: 18.5889789 },
    { lat: 48.5664065, lng: 18.5890419 },
    { lat: 48.5666465, lng: 18.5892001 },
    { lat: 48.5668302, lng: 18.5895716 },
    { lat: 48.5685199, lng: 18.5906135 },
    { lat: 48.5686457, lng: 18.5908744 },
    { lat: 48.56896, lng: 18.591179 },
    { lat: 48.5692061, lng: 18.5915547 },
    { lat: 48.569425, lng: 18.5920342 },
    { lat: 48.5696902, lng: 18.5944101 },
    { lat: 48.5695629, lng: 18.5950785 },
    { lat: 48.5693681, lng: 18.5957717 },
    { lat: 48.5701498, lng: 18.5965837 },
    { lat: 48.5705878, lng: 18.5967146 },
    { lat: 48.5727812, lng: 18.5962916 },
    { lat: 48.5735141, lng: 18.5969543 },
    { lat: 48.573774, lng: 18.5970869 },
    { lat: 48.5744305, lng: 18.5971692 },
    { lat: 48.575273, lng: 18.597696 },
    { lat: 48.575275, lng: 18.597673 },
    { lat: 48.57528, lng: 18.597633 },
    { lat: 48.575286, lng: 18.597593 },
    { lat: 48.575457, lng: 18.596728 },
    { lat: 48.575524, lng: 18.596423 },
    { lat: 48.57568, lng: 18.595706 },
    { lat: 48.575698, lng: 18.595625 },
    { lat: 48.575704, lng: 18.595597 },
    { lat: 48.575754, lng: 18.595368 },
    { lat: 48.575862, lng: 18.594988 },
    { lat: 48.57592, lng: 18.593864 },
    { lat: 48.576043, lng: 18.593347 },
    { lat: 48.57617, lng: 18.5929 },
    { lat: 48.576229, lng: 18.592647 },
    { lat: 48.576258, lng: 18.592522 },
    { lat: 48.576316, lng: 18.592278 },
    { lat: 48.576625, lng: 18.591131 },
    { lat: 48.576682, lng: 18.589819 },
    { lat: 48.5767, lng: 18.589163 },
    { lat: 48.576824, lng: 18.588478 },
    { lat: 48.576974, lng: 18.587611 },
    { lat: 48.576989, lng: 18.587526 },
    { lat: 48.577017, lng: 18.587362 },
    { lat: 48.577005, lng: 18.587184 },
    { lat: 48.576998, lng: 18.587073 },
    { lat: 48.57698, lng: 18.586805 },
    { lat: 48.576724, lng: 18.585562 },
    { lat: 48.576528, lng: 18.58402 },
    { lat: 48.576414, lng: 18.583015 },
    { lat: 48.576289, lng: 18.582008 },
    { lat: 48.57648, lng: 18.580723 },
    { lat: 48.576513, lng: 18.580418 },
    { lat: 48.576935, lng: 18.578849 },
    { lat: 48.576956, lng: 18.578773 },
    { lat: 48.577029, lng: 18.578501 },
    { lat: 48.577248, lng: 18.578265 },
    { lat: 48.577469, lng: 18.577452 },
    { lat: 48.577447, lng: 18.577391 },
    { lat: 48.576954, lng: 18.576519 },
    { lat: 48.576668, lng: 18.576101 },
    { lat: 48.576437, lng: 18.575762 },
    { lat: 48.576417, lng: 18.575734 },
    { lat: 48.576352, lng: 18.575692 },
    { lat: 48.576293, lng: 18.575617 },
    { lat: 48.576263, lng: 18.575577 },
    { lat: 48.576136, lng: 18.575331 },
    { lat: 48.576104, lng: 18.57527 },
    { lat: 48.576037, lng: 18.57514 },
    { lat: 48.576018, lng: 18.575113 },
    { lat: 48.575655, lng: 18.574594 },
    { lat: 48.574918, lng: 18.573331 },
    { lat: 48.574248, lng: 18.572297 },
    { lat: 48.573788, lng: 18.571556 },
    { lat: 48.573683, lng: 18.571374 },
    { lat: 48.573363, lng: 18.570843 },
    { lat: 48.572922, lng: 18.570109 },
    { lat: 48.572722, lng: 18.569772 },
    { lat: 48.572693, lng: 18.569721 },
    { lat: 48.572362, lng: 18.569163 },
    { lat: 48.571854, lng: 18.568289 },
    { lat: 48.571431, lng: 18.567571 },
    { lat: 48.571133, lng: 18.567063 },
    { lat: 48.570598, lng: 18.566138 },
    { lat: 48.570069, lng: 18.565188 },
    { lat: 48.569598, lng: 18.56555 },
    { lat: 48.569409, lng: 18.565696 },
    { lat: 48.569385, lng: 18.565715 },
    { lat: 48.569265, lng: 18.565807 },
    { lat: 48.568641, lng: 18.566371 },
    { lat: 48.56801, lng: 18.567456 },
    { lat: 48.567284, lng: 18.567925 },
    { lat: 48.566977, lng: 18.56727 },
    { lat: 48.56683, lng: 18.566714 },
    { lat: 48.56654, lng: 18.565633 },
    { lat: 48.566568, lng: 18.564698 },
    { lat: 48.566587, lng: 18.564053 },
    { lat: 48.566645, lng: 18.563788 },
    { lat: 48.566703, lng: 18.563566 },
    { lat: 48.56696, lng: 18.562534 },
    { lat: 48.566901, lng: 18.561727 },
    { lat: 48.56689, lng: 18.561555 },
    { lat: 48.566927, lng: 18.560372 },
    { lat: 48.567033, lng: 18.559189 },
    { lat: 48.567037, lng: 18.559141 },
    { lat: 48.567138, lng: 18.558243 },
    { lat: 48.567169, lng: 18.557089 },
    { lat: 48.56717, lng: 18.557037 },
    { lat: 48.567177, lng: 18.55604 },
    { lat: 48.567087, lng: 18.554926 },
    { lat: 48.56703, lng: 18.5543243 },
    { lat: 48.567002, lng: 18.554029 },
    { lat: 48.567004, lng: 18.553846 },
    { lat: 48.566948, lng: 18.553472 },
  ],
  Župkov: [
    { lat: 48.5572452, lng: 18.6337028 },
    { lat: 48.556539, lng: 18.632527 },
    { lat: 48.5563696, lng: 18.6319158 },
    { lat: 48.5559956, lng: 18.6314438 },
    { lat: 48.5558559, lng: 18.6321146 },
    { lat: 48.5555772, lng: 18.6324296 },
    { lat: 48.5550504, lng: 18.6324905 },
    { lat: 48.5546769, lng: 18.6320039 },
    { lat: 48.5545681, lng: 18.6317249 },
    { lat: 48.5538784, lng: 18.6311289 },
    { lat: 48.5542081, lng: 18.6295014 },
    { lat: 48.5539498, lng: 18.629125 },
    { lat: 48.553946, lng: 18.6286783 },
    { lat: 48.5541047, lng: 18.6281893 },
    { lat: 48.5543833, lng: 18.6269789 },
    { lat: 48.5531668, lng: 18.6255866 },
    { lat: 48.5511829, lng: 18.6248982 },
    { lat: 48.5514018, lng: 18.6266995 },
    { lat: 48.5515884, lng: 18.6272487 },
    { lat: 48.5506284, lng: 18.6270177 },
    { lat: 48.5504802, lng: 18.6269258 },
    { lat: 48.5500373, lng: 18.6268903 },
    { lat: 48.5497444, lng: 18.6270867 },
    { lat: 48.549619, lng: 18.6270629 },
    { lat: 48.5494278, lng: 18.6267556 },
    { lat: 48.5493735, lng: 18.6274005 },
    { lat: 48.5489471, lng: 18.6275663 },
    { lat: 48.5484335, lng: 18.6271411 },
    { lat: 48.5482183, lng: 18.6274597 },
    { lat: 48.5475104, lng: 18.6280942 },
    { lat: 48.5468743, lng: 18.6282855 },
    { lat: 48.5467832, lng: 18.6273505 },
    { lat: 48.5467997, lng: 18.6266302 },
    { lat: 48.5469247, lng: 18.6266259 },
    { lat: 48.546934, lng: 18.6263378 },
    { lat: 48.5468124, lng: 18.6257145 },
    { lat: 48.546785, lng: 18.6250404 },
    { lat: 48.5465819, lng: 18.6243686 },
    { lat: 48.5462078, lng: 18.624041 },
    { lat: 48.5460346, lng: 18.6232973 },
    { lat: 48.5460232, lng: 18.6230694 },
    { lat: 48.5464112, lng: 18.6212901 },
    { lat: 48.5462504, lng: 18.6207381 },
    { lat: 48.5457188, lng: 18.6205832 },
    { lat: 48.5453608, lng: 18.6203057 },
    { lat: 48.5439811, lng: 18.6197987 },
    { lat: 48.542255, lng: 18.6200216 },
    { lat: 48.5416651, lng: 18.6202928 },
    { lat: 48.5413121, lng: 18.6198976 },
    { lat: 48.5407872, lng: 18.6198668 },
    { lat: 48.5400289, lng: 18.6199234 },
    { lat: 48.5392019, lng: 18.6200614 },
    { lat: 48.5383726, lng: 18.6203744 },
    { lat: 48.5374333, lng: 18.6217558 },
    { lat: 48.536616, lng: 18.6224727 },
    { lat: 48.5353218, lng: 18.621856 },
    { lat: 48.5354407, lng: 18.6208638 },
    { lat: 48.5355838, lng: 18.6206299 },
    { lat: 48.5355352, lng: 18.6204433 },
    { lat: 48.535712, lng: 18.6200412 },
    { lat: 48.5358345, lng: 18.620029 },
    { lat: 48.5358623, lng: 18.6199178 },
    { lat: 48.5354171, lng: 18.6200289 },
    { lat: 48.5348547, lng: 18.6203288 },
    { lat: 48.5345285, lng: 18.6203452 },
    { lat: 48.5343605, lng: 18.6206609 },
    { lat: 48.5339413, lng: 18.6203965 },
    { lat: 48.5336612, lng: 18.620492 },
    { lat: 48.5328992, lng: 18.6212961 },
    { lat: 48.5323863, lng: 18.6216539 },
    { lat: 48.5303876, lng: 18.6233676 },
    { lat: 48.5298751, lng: 18.6216678 },
    { lat: 48.529207, lng: 18.6220372 },
    { lat: 48.5291066, lng: 18.6219103 },
    { lat: 48.5289784, lng: 18.621541 },
    { lat: 48.5288034, lng: 18.6213521 },
    { lat: 48.5285562, lng: 18.6212646 },
    { lat: 48.5283885, lng: 18.6211047 },
    { lat: 48.5283985, lng: 18.6207032 },
    { lat: 48.5282291, lng: 18.6206175 },
    { lat: 48.5284939, lng: 18.6198581 },
    { lat: 48.5284889, lng: 18.6195765 },
    { lat: 48.528066, lng: 18.6200393 },
    { lat: 48.527731, lng: 18.6205401 },
    { lat: 48.5276304, lng: 18.6210584 },
    { lat: 48.5273828, lng: 18.6215681 },
    { lat: 48.5272205, lng: 18.622116 },
    { lat: 48.5271442, lng: 18.622444 },
    { lat: 48.5272182, lng: 18.6227529 },
    { lat: 48.5273377, lng: 18.6231001 },
    { lat: 48.5273524, lng: 18.6233948 },
    { lat: 48.5270735, lng: 18.623442 },
    { lat: 48.5267363, lng: 18.6228603 },
    { lat: 48.5265839, lng: 18.6228967 },
    { lat: 48.5259655, lng: 18.6232663 },
    { lat: 48.525631, lng: 18.6232347 },
    { lat: 48.5253939, lng: 18.623147 },
    { lat: 48.5249128, lng: 18.6226544 },
    { lat: 48.5244335, lng: 18.6223654 },
    { lat: 48.5241061, lng: 18.6223375 },
    { lat: 48.5235224, lng: 18.622056 },
    { lat: 48.5231905, lng: 18.6220287 },
    { lat: 48.5228715, lng: 18.6222218 },
    { lat: 48.5229687, lng: 18.6224771 },
    { lat: 48.5227284, lng: 18.6228282 },
    { lat: 48.5225632, lng: 18.6227972 },
    { lat: 48.5223933, lng: 18.6240804 },
    { lat: 48.5222717, lng: 18.624376 },
    { lat: 48.5223272, lng: 18.6245268 },
    { lat: 48.522469, lng: 18.624623 },
    { lat: 48.5224652, lng: 18.6247021 },
    { lat: 48.5223203, lng: 18.6247547 },
    { lat: 48.521581, lng: 18.6238873 },
    { lat: 48.5214066, lng: 18.6240642 },
    { lat: 48.5214131, lng: 18.6241619 },
    { lat: 48.5211641, lng: 18.6243351 },
    { lat: 48.5203682, lng: 18.6251995 },
    { lat: 48.5204551, lng: 18.6254074 },
    { lat: 48.5201727, lng: 18.6256935 },
    { lat: 48.520251, lng: 18.6260144 },
    { lat: 48.5198037, lng: 18.6267956 },
    { lat: 48.5200862, lng: 18.6272862 },
    { lat: 48.5195386, lng: 18.6277328 },
    { lat: 48.5191335, lng: 18.6279215 },
    { lat: 48.5189684, lng: 18.6281196 },
    { lat: 48.5184861, lng: 18.6281928 },
    { lat: 48.5184193, lng: 18.6278968 },
    { lat: 48.5182193, lng: 18.6274047 },
    { lat: 48.5173295, lng: 18.6276811 },
    { lat: 48.5168068, lng: 18.6279645 },
    { lat: 48.5165323, lng: 18.6278533 },
    { lat: 48.5163916, lng: 18.6276282 },
    { lat: 48.5164346, lng: 18.6266681 },
    { lat: 48.5165451, lng: 18.6265219 },
    { lat: 48.5162716, lng: 18.626534 },
    { lat: 48.5164544, lng: 18.6259072 },
    { lat: 48.5159626, lng: 18.625929 },
    { lat: 48.5155798, lng: 18.6248982 },
    { lat: 48.5152681, lng: 18.6248065 },
    { lat: 48.5149038, lng: 18.6248056 },
    { lat: 48.5149691, lng: 18.6250729 },
    { lat: 48.5148229, lng: 18.6255825 },
    { lat: 48.5148106, lng: 18.6258366 },
    { lat: 48.5149416, lng: 18.6261962 },
    { lat: 48.5146246, lng: 18.6268835 },
    { lat: 48.5143761, lng: 18.6272924 },
    { lat: 48.5144838, lng: 18.6276197 },
    { lat: 48.5149727, lng: 18.6273144 },
    { lat: 48.5151837, lng: 18.6276074 },
    { lat: 48.5152508, lng: 18.6288059 },
    { lat: 48.515189, lng: 18.6292503 },
    { lat: 48.5150073, lng: 18.6292693 },
    { lat: 48.5146824, lng: 18.6307523 },
    { lat: 48.5141833, lng: 18.6310157 },
    { lat: 48.5139237, lng: 18.631553 },
    { lat: 48.513594, lng: 18.6320088 },
    { lat: 48.5127618, lng: 18.6314257 },
    { lat: 48.5121821, lng: 18.6311775 },
    { lat: 48.5121734, lng: 18.6310163 },
    { lat: 48.5114417, lng: 18.6308098 },
    { lat: 48.5114536, lng: 18.6311587 },
    { lat: 48.5111913, lng: 18.6314779 },
    { lat: 48.5110206, lng: 18.6324549 },
    { lat: 48.5108962, lng: 18.6326145 },
    { lat: 48.5104647, lng: 18.6327516 },
    { lat: 48.5102115, lng: 18.6332204 },
    { lat: 48.5097779, lng: 18.6338386 },
    { lat: 48.5098617, lng: 18.6344953 },
    { lat: 48.509608, lng: 18.6351417 },
    { lat: 48.5091311, lng: 18.6358384 },
    { lat: 48.508935, lng: 18.6360075 },
    { lat: 48.5081779, lng: 18.6364002 },
    { lat: 48.5080654, lng: 18.6368248 },
    { lat: 48.5081078, lng: 18.6390534 },
    { lat: 48.5078963, lng: 18.6396903 },
    { lat: 48.5081526, lng: 18.6401143 },
    { lat: 48.5082283, lng: 18.6403917 },
    { lat: 48.5081475, lng: 18.6415505 },
    { lat: 48.5081925, lng: 18.6422802 },
    { lat: 48.5081272, lng: 18.6425109 },
    { lat: 48.5078654, lng: 18.6429686 },
    { lat: 48.5079873, lng: 18.6431828 },
    { lat: 48.5083331, lng: 18.643486 },
    { lat: 48.5090676, lng: 18.6453542 },
    { lat: 48.508832, lng: 18.6468267 },
    { lat: 48.5086812, lng: 18.647146 },
    { lat: 48.5086669, lng: 18.6490987 },
    { lat: 48.5088126, lng: 18.6506781 },
    { lat: 48.5087363, lng: 18.6507268 },
    { lat: 48.5090491, lng: 18.651885 },
    { lat: 48.5085096, lng: 18.6517942 },
    { lat: 48.5085225, lng: 18.6519726 },
    { lat: 48.509608, lng: 18.6523366 },
    { lat: 48.511034, lng: 18.6519484 },
    { lat: 48.5115022, lng: 18.6520329 },
    { lat: 48.5119305, lng: 18.6519839 },
    { lat: 48.5128548, lng: 18.6521239 },
    { lat: 48.5136796, lng: 18.6520234 },
    { lat: 48.5152756, lng: 18.6526112 },
    { lat: 48.5176272, lng: 18.6529271 },
    { lat: 48.5179867, lng: 18.6531127 },
    { lat: 48.5181859, lng: 18.653081 },
    { lat: 48.518467, lng: 18.6532147 },
    { lat: 48.5186836, lng: 18.6534231 },
    { lat: 48.5195859, lng: 18.6540329 },
    { lat: 48.5206211, lng: 18.6542189 },
    { lat: 48.5208511, lng: 18.654404 },
    { lat: 48.5212097, lng: 18.6544677 },
    { lat: 48.5221526, lng: 18.6549926 },
    { lat: 48.5222856, lng: 18.6551148 },
    { lat: 48.5225012, lng: 18.6554981 },
    { lat: 48.5226804, lng: 18.6556232 },
    { lat: 48.5225222, lng: 18.6556382 },
    { lat: 48.5229642, lng: 18.6563077 },
    { lat: 48.5235988, lng: 18.6567624 },
    { lat: 48.5239722, lng: 18.656951 },
    { lat: 48.5246376, lng: 18.6578108 },
    { lat: 48.5254584, lng: 18.6583303 },
    { lat: 48.5260119, lng: 18.6586636 },
    { lat: 48.5260565, lng: 18.6585913 },
    { lat: 48.5263064, lng: 18.6586025 },
    { lat: 48.527441, lng: 18.6591259 },
    { lat: 48.5279662, lng: 18.6594953 },
    { lat: 48.5281342, lng: 18.659055 },
    { lat: 48.5279157, lng: 18.6585778 },
    { lat: 48.5286389, lng: 18.6576574 },
    { lat: 48.5285156, lng: 18.6567305 },
    { lat: 48.528576, lng: 18.6563816 },
    { lat: 48.5284666, lng: 18.6551459 },
    { lat: 48.5286727, lng: 18.6546355 },
    { lat: 48.5288064, lng: 18.6540352 },
    { lat: 48.5289662, lng: 18.6537585 },
    { lat: 48.529371, lng: 18.6540088 },
    { lat: 48.5296297, lng: 18.6535542 },
    { lat: 48.52973, lng: 18.6536783 },
    { lat: 48.5302626, lng: 18.6526743 },
    { lat: 48.5299708, lng: 18.6524522 },
    { lat: 48.5302655, lng: 18.6520167 },
    { lat: 48.5303933, lng: 18.6520449 },
    { lat: 48.5306689, lng: 18.6512544 },
    { lat: 48.530691, lng: 18.6508706 },
    { lat: 48.5308076, lng: 18.6503087 },
    { lat: 48.5314329, lng: 18.6506329 },
    { lat: 48.532096, lng: 18.6507595 },
    { lat: 48.5323623, lng: 18.6511363 },
    { lat: 48.5323553, lng: 18.6517232 },
    { lat: 48.5328097, lng: 18.6519486 },
    { lat: 48.5334709, lng: 18.6521015 },
    { lat: 48.533822, lng: 18.6520242 },
    { lat: 48.5346881, lng: 18.6515784 },
    { lat: 48.5350529, lng: 18.6528084 },
    { lat: 48.5352998, lng: 18.6521569 },
    { lat: 48.5359504, lng: 18.6521954 },
    { lat: 48.5361383, lng: 18.6521419 },
    { lat: 48.5366012, lng: 18.6521535 },
    { lat: 48.536942, lng: 18.6523248 },
    { lat: 48.5372509, lng: 18.6527822 },
    { lat: 48.5374753, lng: 18.6525737 },
    { lat: 48.5379041, lng: 18.6517345 },
    { lat: 48.5379427, lng: 18.6518031 },
    { lat: 48.5381798, lng: 18.6513457 },
    { lat: 48.5386616, lng: 18.6511639 },
    { lat: 48.538986, lng: 18.6512592 },
    { lat: 48.5400799, lng: 18.6510668 },
    { lat: 48.5408545, lng: 18.6502865 },
    { lat: 48.5411727, lng: 18.6500808 },
    { lat: 48.542249, lng: 18.6500855 },
    { lat: 48.5438284, lng: 18.6498207 },
    { lat: 48.5447476, lng: 18.6498229 },
    { lat: 48.5451892, lng: 18.6499392 },
    { lat: 48.5461041, lng: 18.6498771 },
    { lat: 48.5467279, lng: 18.6496295 },
    { lat: 48.5481585, lng: 18.6493429 },
    { lat: 48.5489144, lng: 18.6488873 },
    { lat: 48.5494601, lng: 18.6483772 },
    { lat: 48.5503545, lng: 18.6469943 },
    { lat: 48.5516829, lng: 18.6453986 },
    { lat: 48.5522119, lng: 18.6465103 },
    { lat: 48.5522213, lng: 18.6465181 },
    { lat: 48.5522937, lng: 18.6461415 },
    { lat: 48.5528253, lng: 18.6452696 },
    { lat: 48.5530676, lng: 18.6446148 },
    { lat: 48.553438, lng: 18.6432186 },
    { lat: 48.5535629, lng: 18.6431517 },
    { lat: 48.5536909, lng: 18.6429292 },
    { lat: 48.5537551, lng: 18.641733 },
    { lat: 48.5538739, lng: 18.6414294 },
    { lat: 48.5538561, lng: 18.6409093 },
    { lat: 48.554023, lng: 18.640634 },
    { lat: 48.5538949, lng: 18.6402879 },
    { lat: 48.5539347, lng: 18.6398138 },
    { lat: 48.5540401, lng: 18.6397184 },
    { lat: 48.5540236, lng: 18.6395566 },
    { lat: 48.5539146, lng: 18.6395244 },
    { lat: 48.553846, lng: 18.6393174 },
    { lat: 48.553785, lng: 18.6392739 },
    { lat: 48.5541616, lng: 18.6374282 },
    { lat: 48.5543274, lng: 18.6373848 },
    { lat: 48.554565, lng: 18.6374612 },
    { lat: 48.5555488, lng: 18.6364017 },
    { lat: 48.5562208, lng: 18.6354124 },
    { lat: 48.5566922, lng: 18.6352651 },
    { lat: 48.5568489, lng: 18.6350982 },
    { lat: 48.5570124, lng: 18.6347214 },
    { lat: 48.5572452, lng: 18.6337028 },
  ],
  HodrušaHámre: [
    { lat: 48.481636, lng: 18.815547 },
    { lat: 48.481643, lng: 18.815466 },
    { lat: 48.481668, lng: 18.815188 },
    { lat: 48.481789, lng: 18.814122 },
    { lat: 48.481866, lng: 18.813644 },
    { lat: 48.481762, lng: 18.812614 },
    { lat: 48.481751, lng: 18.81213 },
    { lat: 48.481816, lng: 18.811728 },
    { lat: 48.482034, lng: 18.811406 },
    { lat: 48.482334, lng: 18.811007 },
    { lat: 48.482793, lng: 18.810755 },
    { lat: 48.48318, lng: 18.810247 },
    { lat: 48.483928, lng: 18.809632 },
    { lat: 48.484193, lng: 18.809027 },
    { lat: 48.484271, lng: 18.808748 },
    { lat: 48.484253, lng: 18.808318 },
    { lat: 48.483953, lng: 18.807737 },
    { lat: 48.483677, lng: 18.807031 },
    { lat: 48.483417, lng: 18.806609 },
    { lat: 48.483407, lng: 18.806275 },
    { lat: 48.483605, lng: 18.805779 },
    { lat: 48.483664, lng: 18.805549 },
    { lat: 48.483738, lng: 18.805074 },
    { lat: 48.483863, lng: 18.804419 },
    { lat: 48.483929, lng: 18.803915 },
    { lat: 48.483912, lng: 18.803484 },
    { lat: 48.483487, lng: 18.803303 },
    { lat: 48.483209, lng: 18.803112 },
    { lat: 48.482884, lng: 18.802854 },
    { lat: 48.48255, lng: 18.802368 },
    { lat: 48.482218, lng: 18.801923 },
    { lat: 48.481951, lng: 18.801481 },
    { lat: 48.481645, lng: 18.801481 },
    { lat: 48.481295, lng: 18.801513 },
    { lat: 48.480914, lng: 18.801359 },
    { lat: 48.480496, lng: 18.801266 },
    { lat: 48.4804116, lng: 18.8012193 },
    { lat: 48.48008, lng: 18.801036 },
    { lat: 48.479823, lng: 18.801017 },
    { lat: 48.479288, lng: 18.801012 },
    { lat: 48.478742, lng: 18.800822 },
    { lat: 48.478377, lng: 18.800759 },
    { lat: 48.478044, lng: 18.800575 },
    { lat: 48.4779267, lng: 18.8004701 },
    { lat: 48.477521, lng: 18.800107 },
    { lat: 48.477537, lng: 18.800066 },
    { lat: 48.477711, lng: 18.799635 },
    { lat: 48.477798, lng: 18.798583 },
    { lat: 48.477682, lng: 18.797685 },
    { lat: 48.477511, lng: 18.79755 },
    { lat: 48.477344, lng: 18.797617 },
    { lat: 48.477315, lng: 18.79763 },
    { lat: 48.477215, lng: 18.797195 },
    { lat: 48.477197, lng: 18.796938 },
    { lat: 48.477204, lng: 18.79668 },
    { lat: 48.477299, lng: 18.796317 },
    { lat: 48.477473, lng: 18.795645 },
    { lat: 48.477606, lng: 18.795266 },
    { lat: 48.478175, lng: 18.794372 },
    { lat: 48.478475, lng: 18.793901 },
    { lat: 48.478757, lng: 18.793291 },
    { lat: 48.478916, lng: 18.792541 },
    { lat: 48.479472, lng: 18.791835 },
    { lat: 48.479789, lng: 18.791223 },
    { lat: 48.47991, lng: 18.790739 },
    { lat: 48.48015, lng: 18.790294 },
    { lat: 48.480433, lng: 18.789954 },
    { lat: 48.480757, lng: 18.789271 },
    { lat: 48.480825, lng: 18.78879 },
    { lat: 48.480897, lng: 18.788371 },
    { lat: 48.481345, lng: 18.787596 },
    { lat: 48.481764, lng: 18.787169 },
    { lat: 48.48177, lng: 18.786353 },
    { lat: 48.481787, lng: 18.78539 },
    { lat: 48.481633, lng: 18.785206 },
    { lat: 48.481172, lng: 18.784438 },
    { lat: 48.480885, lng: 18.784036 },
    { lat: 48.4808068, lng: 18.7836941 },
    { lat: 48.480511, lng: 18.78275 },
    { lat: 48.480458, lng: 18.782236 },
    { lat: 48.480385, lng: 18.781689 },
    { lat: 48.480302, lng: 18.781149 },
    { lat: 48.480275, lng: 18.780569 },
    { lat: 48.480435, lng: 18.780166 },
    { lat: 48.480763, lng: 18.779442 },
    { lat: 48.480805, lng: 18.779343 },
    { lat: 48.480859, lng: 18.778691 },
    { lat: 48.480838, lng: 18.778353 },
    { lat: 48.4798826, lng: 18.7776867 },
    { lat: 48.4796003, lng: 18.7772722 },
    { lat: 48.4794703, lng: 18.7768185 },
    { lat: 48.4794741, lng: 18.7765042 },
    { lat: 48.4797237, lng: 18.7757386 },
    { lat: 48.4798716, lng: 18.774762 },
    { lat: 48.4797128, lng: 18.7741198 },
    { lat: 48.4796896, lng: 18.7734032 },
    { lat: 48.4799399, lng: 18.7728703 },
    { lat: 48.4800474, lng: 18.772255 },
    { lat: 48.480033, lng: 18.7715533 },
    { lat: 48.4803478, lng: 18.7711363 },
    { lat: 48.4803591, lng: 18.7711536 },
    { lat: 48.4804419, lng: 18.7704173 },
    { lat: 48.4807339, lng: 18.7695935 },
    { lat: 48.4807702, lng: 18.7694946 },
    { lat: 48.4809262, lng: 18.7688827 },
    { lat: 48.4805151, lng: 18.7685931 },
    { lat: 48.4797423, lng: 18.7682876 },
    { lat: 48.4790959, lng: 18.7679234 },
    { lat: 48.4787279, lng: 18.7674025 },
    { lat: 48.478423, lng: 18.7672232 },
    { lat: 48.4780158, lng: 18.7673511 },
    { lat: 48.4776663, lng: 18.7671824 },
    { lat: 48.4774768, lng: 18.7658981 },
    { lat: 48.4772823, lng: 18.7654195 },
    { lat: 48.4770523, lng: 18.765078 },
    { lat: 48.4763624, lng: 18.7644002 },
    { lat: 48.4759299, lng: 18.7637066 },
    { lat: 48.4759358, lng: 18.7625163 },
    { lat: 48.4766187, lng: 18.7602025 },
    { lat: 48.4755653, lng: 18.7604323 },
    { lat: 48.47514, lng: 18.7603666 },
    { lat: 48.4751225, lng: 18.760273 },
    { lat: 48.4744345, lng: 18.7609293 },
    { lat: 48.4735718, lng: 18.761015 },
    { lat: 48.4735045, lng: 18.7608728 },
    { lat: 48.4735374, lng: 18.7608116 },
    { lat: 48.4733644, lng: 18.7599072 },
    { lat: 48.4730669, lng: 18.7596129 },
    { lat: 48.4729252, lng: 18.7593647 },
    { lat: 48.473656, lng: 18.7580661 },
    { lat: 48.4738739, lng: 18.7573878 },
    { lat: 48.4738194, lng: 18.7572955 },
    { lat: 48.474031, lng: 18.7569475 },
    { lat: 48.4746317, lng: 18.7548487 },
    { lat: 48.4748441, lng: 18.7533248 },
    { lat: 48.4749303, lng: 18.7510099 },
    { lat: 48.4740843, lng: 18.7490694 },
    { lat: 48.4730929, lng: 18.7474669 },
    { lat: 48.4716663, lng: 18.7474281 },
    { lat: 48.4711869, lng: 18.747551 },
    { lat: 48.4708246, lng: 18.7474821 },
    { lat: 48.4702121, lng: 18.7476706 },
    { lat: 48.470394, lng: 18.7464871 },
    { lat: 48.47043, lng: 18.7456557 },
    { lat: 48.470242, lng: 18.7446174 },
    { lat: 48.4700381, lng: 18.7441326 },
    { lat: 48.469946, lng: 18.7434783 },
    { lat: 48.4695129, lng: 18.7429286 },
    { lat: 48.4693158, lng: 18.7422098 },
    { lat: 48.4691324, lng: 18.7411749 },
    { lat: 48.4686554, lng: 18.7401532 },
    { lat: 48.4682031, lng: 18.7399402 },
    { lat: 48.4677643, lng: 18.7398738 },
    { lat: 48.4674132, lng: 18.7395282 },
    { lat: 48.4666127, lng: 18.7390631 },
    { lat: 48.4663841, lng: 18.7386431 },
    { lat: 48.4663007, lng: 18.7381581 },
    { lat: 48.4661333, lng: 18.7376579 },
    { lat: 48.4660195, lng: 18.7369381 },
    { lat: 48.4661364, lng: 18.7364653 },
    { lat: 48.4671264, lng: 18.7356339 },
    { lat: 48.4672983, lng: 18.7353237 },
    { lat: 48.4675784, lng: 18.735031 },
    { lat: 48.4676484, lng: 18.7346093 },
    { lat: 48.4674242, lng: 18.7332118 },
    { lat: 48.4677158, lng: 18.7328836 },
    { lat: 48.4684546, lng: 18.7325052 },
    { lat: 48.468718, lng: 18.7320056 },
    { lat: 48.4688012, lng: 18.7315563 },
    { lat: 48.4687771, lng: 18.7310172 },
    { lat: 48.4685997, lng: 18.7306721 },
    { lat: 48.4693422, lng: 18.7300395 },
    { lat: 48.4695167, lng: 18.7297165 },
    { lat: 48.4695549, lng: 18.729778 },
    { lat: 48.4697096, lng: 18.7293585 },
    { lat: 48.4700841, lng: 18.7287211 },
    { lat: 48.4699482, lng: 18.7283731 },
    { lat: 48.4701682, lng: 18.7279287 },
    { lat: 48.4704739, lng: 18.7275273 },
    { lat: 48.4702178, lng: 18.7273982 },
    { lat: 48.4697021, lng: 18.7269893 },
    { lat: 48.4694866, lng: 18.7265631 },
    { lat: 48.4692847, lng: 18.7259886 },
    { lat: 48.4689465, lng: 18.7256941 },
    { lat: 48.4685648, lng: 18.7257688 },
    { lat: 48.4678806, lng: 18.7261812 },
    { lat: 48.4675467, lng: 18.7262008 },
    { lat: 48.4674994, lng: 18.7257775 },
    { lat: 48.467419, lng: 18.7258117 },
    { lat: 48.4676392, lng: 18.7250553 },
    { lat: 48.4677485, lng: 18.7244394 },
    { lat: 48.4683387, lng: 18.7237226 },
    { lat: 48.4673566, lng: 18.7218624 },
    { lat: 48.4675162, lng: 18.7215567 },
    { lat: 48.4673005, lng: 18.7211676 },
    { lat: 48.4673985, lng: 18.720762 },
    { lat: 48.4671023, lng: 18.720325 },
    { lat: 48.4666592, lng: 18.7204541 },
    { lat: 48.4664561, lng: 18.7197766 },
    { lat: 48.4664036, lng: 18.7192357 },
    { lat: 48.4661638, lng: 18.7192621 },
    { lat: 48.4654491, lng: 18.7197369 },
    { lat: 48.4654142, lng: 18.7197571 },
    { lat: 48.4643881, lng: 18.7192505 },
    { lat: 48.4639868, lng: 18.7188948 },
    { lat: 48.4636704, lng: 18.7188835 },
    { lat: 48.4633822, lng: 18.7189995 },
    { lat: 48.4630996, lng: 18.7188818 },
    { lat: 48.4629153, lng: 18.7192544 },
    { lat: 48.4625563, lng: 18.7191398 },
    { lat: 48.4623672, lng: 18.7187028 },
    { lat: 48.4619866, lng: 18.7183947 },
    { lat: 48.4614199, lng: 18.7189984 },
    { lat: 48.4609359, lng: 18.719337 },
    { lat: 48.4596005, lng: 18.7199287 },
    { lat: 48.4586403, lng: 18.7192011 },
    { lat: 48.4582669, lng: 18.7195779 },
    { lat: 48.4578914, lng: 18.7202797 },
    { lat: 48.4576753, lng: 18.7208593 },
    { lat: 48.4575759, lng: 18.7234189 },
    { lat: 48.4573113, lng: 18.7241897 },
    { lat: 48.4566545, lng: 18.7245221 },
    { lat: 48.456052, lng: 18.7249196 },
    { lat: 48.455494, lng: 18.7251594 },
    { lat: 48.4550173, lng: 18.72582 },
    { lat: 48.454652, lng: 18.7261865 },
    { lat: 48.4544449, lng: 18.7267852 },
    { lat: 48.4546822, lng: 18.7283015 },
    { lat: 48.4544844, lng: 18.7291008 },
    { lat: 48.4539067, lng: 18.7306866 },
    { lat: 48.4537133, lng: 18.7316716 },
    { lat: 48.4536379, lng: 18.7332677 },
    { lat: 48.453171, lng: 18.7337771 },
    { lat: 48.4527921, lng: 18.7344155 },
    { lat: 48.4524189, lng: 18.7349279 },
    { lat: 48.4522394, lng: 18.735347 },
    { lat: 48.4522003, lng: 18.7363969 },
    { lat: 48.4518849, lng: 18.7373996 },
    { lat: 48.4516713, lng: 18.7386463 },
    { lat: 48.4515687, lng: 18.7390288 },
    { lat: 48.4512535, lng: 18.7394837 },
    { lat: 48.450356, lng: 18.7400649 },
    { lat: 48.4497854, lng: 18.7403195 },
    { lat: 48.4495137, lng: 18.7407439 },
    { lat: 48.4492404, lng: 18.741674 },
    { lat: 48.4493931, lng: 18.7424045 },
    { lat: 48.4493073, lng: 18.7434877 },
    { lat: 48.4491164, lng: 18.7442907 },
    { lat: 48.4489677, lng: 18.7445479 },
    { lat: 48.4494209, lng: 18.7457146 },
    { lat: 48.4497799, lng: 18.7471285 },
    { lat: 48.4500216, lng: 18.7476672 },
    { lat: 48.4501309, lng: 18.7480484 },
    { lat: 48.4495359, lng: 18.7491194 },
    { lat: 48.4492795, lng: 18.7497078 },
    { lat: 48.4494226, lng: 18.7503042 },
    { lat: 48.449463, lng: 18.751066 },
    { lat: 48.4489885, lng: 18.7514553 },
    { lat: 48.4487894, lng: 18.7522922 },
    { lat: 48.4480795, lng: 18.7533893 },
    { lat: 48.4470403, lng: 18.7554226 },
    { lat: 48.4474511, lng: 18.7560892 },
    { lat: 48.4478907, lng: 18.7580071 },
    { lat: 48.4478836, lng: 18.7582751 },
    { lat: 48.4477157, lng: 18.7590227 },
    { lat: 48.4472031, lng: 18.7601663 },
    { lat: 48.4469237, lng: 18.7610672 },
    { lat: 48.446205, lng: 18.7604405 },
    { lat: 48.4453334, lng: 18.7601216 },
    { lat: 48.4440097, lng: 18.7597665 },
    { lat: 48.443676, lng: 18.7597659 },
    { lat: 48.4431972, lng: 18.760053 },
    { lat: 48.4426599, lng: 18.7600447 },
    { lat: 48.4418595, lng: 18.759495 },
    { lat: 48.441408, lng: 18.7599809 },
    { lat: 48.440936, lng: 18.7603317 },
    { lat: 48.4400906, lng: 18.7604462 },
    { lat: 48.4400832, lng: 18.7606673 },
    { lat: 48.4399027, lng: 18.7609371 },
    { lat: 48.4398856, lng: 18.7609626 },
    { lat: 48.439885, lng: 18.7610407 },
    { lat: 48.439881, lng: 18.7615549 },
    { lat: 48.4397779, lng: 18.7617248 },
    { lat: 48.4394163, lng: 18.7623208 },
    { lat: 48.4394474, lng: 18.7623976 },
    { lat: 48.4395576, lng: 18.7626691 },
    { lat: 48.4396652, lng: 18.7629342 },
    { lat: 48.439559, lng: 18.7635713 },
    { lat: 48.4393657, lng: 18.763924 },
    { lat: 48.439361, lng: 18.7642204 },
    { lat: 48.4392239, lng: 18.7647396 },
    { lat: 48.4389654, lng: 18.7657185 },
    { lat: 48.4386543, lng: 18.7664454 },
    { lat: 48.4382886, lng: 18.7667192 },
    { lat: 48.4381806, lng: 18.7668727 },
    { lat: 48.4381034, lng: 18.7668806 },
    { lat: 48.437873, lng: 18.766904 },
    { lat: 48.437856, lng: 18.766915 },
    { lat: 48.437868, lng: 18.766955 },
    { lat: 48.437644, lng: 18.767045 },
    { lat: 48.43748, lng: 18.767365 },
    { lat: 48.436631, lng: 18.767552 },
    { lat: 48.436442, lng: 18.767636 },
    { lat: 48.4362713, lng: 18.7678994 },
    { lat: 48.43614, lng: 18.768102 },
    { lat: 48.4361093, lng: 18.7681764 },
    { lat: 48.436057, lng: 18.768303 },
    { lat: 48.435739, lng: 18.768763 },
    { lat: 48.4354744, lng: 18.7693673 },
    { lat: 48.435288, lng: 18.769793 },
    { lat: 48.435188, lng: 18.770358 },
    { lat: 48.434967, lng: 18.770745 },
    { lat: 48.434873, lng: 18.770914 },
    { lat: 48.434853, lng: 18.770979 },
    { lat: 48.434839, lng: 18.771197 },
    { lat: 48.43491, lng: 18.772212 },
    { lat: 48.434967, lng: 18.772323 },
    { lat: 48.434973, lng: 18.77268 },
    { lat: 48.434968, lng: 18.773425 },
    { lat: 48.434852, lng: 18.773906 },
    { lat: 48.434803, lng: 18.774258 },
    { lat: 48.434611, lng: 18.774617 },
    { lat: 48.4345402, lng: 18.7749551 },
    { lat: 48.434482, lng: 18.775233 },
    { lat: 48.434087, lng: 18.776558 },
    { lat: 48.434087, lng: 18.776777 },
    { lat: 48.434174, lng: 18.777318 },
    { lat: 48.4341705, lng: 18.7773695 },
    { lat: 48.4341611, lng: 18.777509 },
    { lat: 48.434152, lng: 18.777644 },
    { lat: 48.434193, lng: 18.77819 },
    { lat: 48.4342254, lng: 18.7784687 },
    { lat: 48.43433, lng: 18.779367 },
    { lat: 48.4343224, lng: 18.7794386 },
    { lat: 48.43426, lng: 18.78003 },
    { lat: 48.43424, lng: 18.780137 },
    { lat: 48.434241, lng: 18.78024 },
    { lat: 48.43424, lng: 18.780265 },
    { lat: 48.43418, lng: 18.780422 },
    { lat: 48.434229, lng: 18.78101 },
    { lat: 48.434258, lng: 18.781326 },
    { lat: 48.434226, lng: 18.781829 },
    { lat: 48.434215, lng: 18.782124 },
    { lat: 48.434166, lng: 18.782478 },
    { lat: 48.434036, lng: 18.782695 },
    { lat: 48.433959, lng: 18.783043 },
    { lat: 48.433765, lng: 18.783277 },
    { lat: 48.433574, lng: 18.783758 },
    { lat: 48.4335538, lng: 18.7838174 },
    { lat: 48.433407, lng: 18.7842486 },
    { lat: 48.4332646, lng: 18.7846667 },
    { lat: 48.433254, lng: 18.784698 },
    { lat: 48.433013, lng: 18.784954 },
    { lat: 48.432815, lng: 18.785444 },
    { lat: 48.432746, lng: 18.785675 },
    { lat: 48.4326773, lng: 18.7858284 },
    { lat: 48.432622, lng: 18.785952 },
    { lat: 48.432427, lng: 18.785943 },
    { lat: 48.432327, lng: 18.786118 },
    { lat: 48.432099, lng: 18.786386 },
    { lat: 48.43209, lng: 18.786541 },
    { lat: 48.432002, lng: 18.786668 },
    { lat: 48.431913, lng: 18.786744 },
    { lat: 48.431766, lng: 18.786835 },
    { lat: 48.4315755, lng: 18.786857 },
    { lat: 48.431515, lng: 18.786864 },
    { lat: 48.431231, lng: 18.786919 },
    { lat: 48.430918, lng: 18.786944 },
    { lat: 48.430795, lng: 18.787013 },
    { lat: 48.430662, lng: 18.787216 },
    { lat: 48.430482, lng: 18.787245 },
    { lat: 48.430371, lng: 18.787226 },
    { lat: 48.430234, lng: 18.787101 },
    { lat: 48.430095, lng: 18.786938 },
    { lat: 48.4300304, lng: 18.7868881 },
    { lat: 48.429937, lng: 18.786816 },
    { lat: 48.429744, lng: 18.786789 },
    { lat: 48.429535, lng: 18.786823 },
    { lat: 48.4294951, lng: 18.7868837 },
    { lat: 48.429361, lng: 18.787088 },
    { lat: 48.429216, lng: 18.787389 },
    { lat: 48.429018, lng: 18.787744 },
    { lat: 48.428811, lng: 18.787689 },
    { lat: 48.428716, lng: 18.788028 },
    { lat: 48.4285, lng: 18.788124 },
    { lat: 48.4284188, lng: 18.7881467 },
    { lat: 48.428249, lng: 18.788194 },
    { lat: 48.4282266, lng: 18.7882132 },
    { lat: 48.428037, lng: 18.788376 },
    { lat: 48.427968, lng: 18.788513 },
    { lat: 48.42783, lng: 18.788649 },
    { lat: 48.427742, lng: 18.788967 },
    { lat: 48.427609, lng: 18.789219 },
    { lat: 48.427503, lng: 18.789276 },
    { lat: 48.4272856, lng: 18.789491 },
    { lat: 48.427229, lng: 18.789547 },
    { lat: 48.427139, lng: 18.789804 },
    { lat: 48.4271, lng: 18.790057 },
    { lat: 48.426938, lng: 18.790193 },
    { lat: 48.4267861, lng: 18.7904759 },
    { lat: 48.42677, lng: 18.790506 },
    { lat: 48.426658, lng: 18.790623 },
    { lat: 48.426475, lng: 18.790761 },
    { lat: 48.4262842, lng: 18.7908858 },
    { lat: 48.426261, lng: 18.790901 },
    { lat: 48.426172, lng: 18.790972 },
    { lat: 48.425928, lng: 18.791032 },
    { lat: 48.425755, lng: 18.791227 },
    { lat: 48.425579, lng: 18.791392 },
    { lat: 48.425437, lng: 18.791476 },
    { lat: 48.425309, lng: 18.791614 },
    { lat: 48.425288, lng: 18.791798 },
    { lat: 48.425248, lng: 18.791868 },
    { lat: 48.425188, lng: 18.792231 },
    { lat: 48.425171, lng: 18.7922685 },
    { lat: 48.4250427, lng: 18.7925509 },
    { lat: 48.424995, lng: 18.792656 },
    { lat: 48.424893, lng: 18.792791 },
    { lat: 48.4248, lng: 18.793024 },
    { lat: 48.424685, lng: 18.79322 },
    { lat: 48.424618, lng: 18.793266 },
    { lat: 48.424592, lng: 18.793458 },
    { lat: 48.424597, lng: 18.793641 },
    { lat: 48.424685, lng: 18.793983 },
    { lat: 48.4247217, lng: 18.7943465 },
    { lat: 48.42473, lng: 18.794429 },
    { lat: 48.424936, lng: 18.794757 },
    { lat: 48.424941, lng: 18.795021 },
    { lat: 48.424978, lng: 18.795251 },
    { lat: 48.424949, lng: 18.795486 },
    { lat: 48.424918, lng: 18.795814 },
    { lat: 48.424855, lng: 18.795908 },
    { lat: 48.4248292, lng: 18.7959764 },
    { lat: 48.424773, lng: 18.796125 },
    { lat: 48.424779, lng: 18.796311 },
    { lat: 48.4248617, lng: 18.7964682 },
    { lat: 48.424889, lng: 18.79652 },
    { lat: 48.4249209, lng: 18.7965348 },
    { lat: 48.425081, lng: 18.796609 },
    { lat: 48.4251136, lng: 18.7966322 },
    { lat: 48.425244, lng: 18.796725 },
    { lat: 48.42535, lng: 18.796848 },
    { lat: 48.4254409, lng: 18.79726 },
    { lat: 48.4256519, lng: 18.7982157 },
    { lat: 48.425661, lng: 18.798257 },
    { lat: 48.4257438, lng: 18.7982812 },
    { lat: 48.425815, lng: 18.798302 },
    { lat: 48.425843, lng: 18.798308 },
    { lat: 48.425916, lng: 18.79833 },
    { lat: 48.426023, lng: 18.798508 },
    { lat: 48.426235, lng: 18.799099 },
    { lat: 48.426244, lng: 18.799328 },
    { lat: 48.426341, lng: 18.799504 },
    { lat: 48.426467, lng: 18.799799 },
    { lat: 48.426389, lng: 18.800136 },
    { lat: 48.426361, lng: 18.800522 },
    { lat: 48.426201, lng: 18.800581 },
    { lat: 48.4261955, lng: 18.8006234 },
    { lat: 48.426181, lng: 18.800735 },
    { lat: 48.4261983, lng: 18.8010448 },
    { lat: 48.426207, lng: 18.801201 },
    { lat: 48.4261496, lng: 18.8016034 },
    { lat: 48.426075, lng: 18.801817 },
    { lat: 48.426111, lng: 18.801923 },
    { lat: 48.426132, lng: 18.801971 },
    { lat: 48.42617, lng: 18.802071 },
    { lat: 48.426191, lng: 18.802225 },
    { lat: 48.426243, lng: 18.802601 },
    { lat: 48.426342, lng: 18.802804 },
    { lat: 48.426379, lng: 18.803001 },
    { lat: 48.42639, lng: 18.803213 },
    { lat: 48.42641, lng: 18.803474 },
    { lat: 48.426458, lng: 18.803668 },
    { lat: 48.426434, lng: 18.803984 },
    { lat: 48.426391, lng: 18.804411 },
    { lat: 48.4263201, lng: 18.804689 },
    { lat: 48.4261612, lng: 18.8048338 },
    { lat: 48.42615, lng: 18.804844 },
    { lat: 48.425971, lng: 18.805078 },
    { lat: 48.4259496, lng: 18.8051008 },
    { lat: 48.425794, lng: 18.805217 },
    { lat: 48.425634, lng: 18.805358 },
    { lat: 48.425444, lng: 18.805449 },
    { lat: 48.425315, lng: 18.8055 },
    { lat: 48.4251619, lng: 18.8055682 },
    { lat: 48.425131, lng: 18.805582 },
    { lat: 48.4250532, lng: 18.805664 },
    { lat: 48.424964, lng: 18.805758 },
    { lat: 48.424711, lng: 18.806068 },
    { lat: 48.424602, lng: 18.806271 },
    { lat: 48.424552, lng: 18.806418 },
    { lat: 48.424418, lng: 18.806477 },
    { lat: 48.424304, lng: 18.806663 },
    { lat: 48.4242, lng: 18.806842 },
    { lat: 48.4241166, lng: 18.8073109 },
    { lat: 48.424102, lng: 18.807393 },
    { lat: 48.423985, lng: 18.807673 },
    { lat: 48.423923, lng: 18.807905 },
    { lat: 48.423864, lng: 18.807947 },
    { lat: 48.423793, lng: 18.807989 },
    { lat: 48.423705, lng: 18.808105 },
    { lat: 48.423614, lng: 18.80824 },
    { lat: 48.42353, lng: 18.8084216 },
    { lat: 48.423515, lng: 18.808454 },
    { lat: 48.423317, lng: 18.80866 },
    { lat: 48.4232331, lng: 18.8087347 },
    { lat: 48.4231658, lng: 18.8087946 },
    { lat: 48.423153, lng: 18.808806 },
    { lat: 48.422958, lng: 18.808895 },
    { lat: 48.42282, lng: 18.80896 },
    { lat: 48.422689, lng: 18.809058 },
    { lat: 48.422564, lng: 18.809124 },
    { lat: 48.422486, lng: 18.809186 },
    { lat: 48.422362, lng: 18.80922 },
    { lat: 48.42226, lng: 18.809294 },
    { lat: 48.422204, lng: 18.809322 },
    { lat: 48.422143, lng: 18.809353 },
    { lat: 48.422039, lng: 18.809349 },
    { lat: 48.421955, lng: 18.809392 },
    { lat: 48.42188, lng: 18.809464 },
    { lat: 48.421816, lng: 18.809559 },
    { lat: 48.421751, lng: 18.809761 },
    { lat: 48.421615, lng: 18.809939 },
    { lat: 48.421526, lng: 18.810074 },
    { lat: 48.421439, lng: 18.810291 },
    { lat: 48.4214009, lng: 18.8103686 },
    { lat: 48.421383, lng: 18.810405 },
    { lat: 48.421375, lng: 18.810535 },
    { lat: 48.4213638, lng: 18.8105635 },
    { lat: 48.4212928, lng: 18.8107437 },
    { lat: 48.421258, lng: 18.810832 },
    { lat: 48.421179, lng: 18.810997 },
    { lat: 48.421211, lng: 18.811165 },
    { lat: 48.42123, lng: 18.811265 },
    { lat: 48.421194, lng: 18.811422 },
    { lat: 48.421163, lng: 18.811686 },
    { lat: 48.421025, lng: 18.812005 },
    { lat: 48.420946, lng: 18.812138 },
    { lat: 48.420865, lng: 18.812236 },
    { lat: 48.4208689, lng: 18.8123386 },
    { lat: 48.4208783, lng: 18.8125856 },
    { lat: 48.420881, lng: 18.812656 },
    { lat: 48.420883, lng: 18.812827 },
    { lat: 48.420859, lng: 18.81309 },
    { lat: 48.420923, lng: 18.813346 },
    { lat: 48.421016, lng: 18.8135929 },
    { lat: 48.4210339, lng: 18.8136403 },
    { lat: 48.421039, lng: 18.813654 },
    { lat: 48.420965, lng: 18.813959 },
    { lat: 48.420924, lng: 18.814322 },
    { lat: 48.420854, lng: 18.814543 },
    { lat: 48.42083, lng: 18.814792 },
    { lat: 48.420897, lng: 18.8149781 },
    { lat: 48.420933, lng: 18.815078 },
    { lat: 48.421017, lng: 18.815335 },
    { lat: 48.42101, lng: 18.8154203 },
    { lat: 48.4209861, lng: 18.8157085 },
    { lat: 48.420978, lng: 18.815807 },
    { lat: 48.4208796, lng: 18.8159011 },
    { lat: 48.420773, lng: 18.816003 },
    { lat: 48.4207043, lng: 18.8162672 },
    { lat: 48.420684, lng: 18.816345 },
    { lat: 48.4206432, lng: 18.8163841 },
    { lat: 48.420445, lng: 18.816574 },
    { lat: 48.4203845, lng: 18.8166563 },
    { lat: 48.4202982, lng: 18.8167736 },
    { lat: 48.42027, lng: 18.816812 },
    { lat: 48.4202721, lng: 18.8168906 },
    { lat: 48.420277, lng: 18.817078 },
    { lat: 48.420317, lng: 18.817275 },
    { lat: 48.420408, lng: 18.817406 },
    { lat: 48.420558, lng: 18.81752 },
    { lat: 48.420615, lng: 18.817594 },
    { lat: 48.420839, lng: 18.817615 },
    { lat: 48.421006, lng: 18.81761 },
    { lat: 48.421195, lng: 18.817635 },
    { lat: 48.421359, lng: 18.817636 },
    { lat: 48.421536, lng: 18.817593 },
    { lat: 48.421693, lng: 18.817618 },
    { lat: 48.421842, lng: 18.81769 },
    { lat: 48.421928, lng: 18.817818 },
    { lat: 48.422191, lng: 18.818086 },
    { lat: 48.422282, lng: 18.818191 },
    { lat: 48.422409, lng: 18.81825 },
    { lat: 48.422537, lng: 18.818339 },
    { lat: 48.422719, lng: 18.818399 },
    { lat: 48.422848, lng: 18.818519 },
    { lat: 48.42288, lng: 18.818705 },
    { lat: 48.422929, lng: 18.818906 },
    { lat: 48.422927, lng: 18.819052 },
    { lat: 48.42288, lng: 18.819181 },
    { lat: 48.422881, lng: 18.819324 },
    { lat: 48.422843, lng: 18.819564 },
    { lat: 48.422821, lng: 18.819678 },
    { lat: 48.422834, lng: 18.819778 },
    { lat: 48.422806, lng: 18.819885 },
    { lat: 48.422802, lng: 18.820015 },
    { lat: 48.422791, lng: 18.820182 },
    { lat: 48.422787, lng: 18.820363 },
    { lat: 48.422785, lng: 18.820482 },
    { lat: 48.422789, lng: 18.820672 },
    { lat: 48.422752, lng: 18.820832 },
    { lat: 48.422725, lng: 18.821076 },
    { lat: 48.422636, lng: 18.821484 },
    { lat: 48.422668, lng: 18.821684 },
    { lat: 48.422696, lng: 18.8219 },
    { lat: 48.422744, lng: 18.822009 },
    { lat: 48.422888, lng: 18.822288 },
    { lat: 48.42304, lng: 18.822467 },
    { lat: 48.423252, lng: 18.822646 },
    { lat: 48.42344, lng: 18.822684 },
    { lat: 48.423566, lng: 18.822795 },
    { lat: 48.423647, lng: 18.822911 },
    { lat: 48.423746, lng: 18.823102 },
    { lat: 48.423778, lng: 18.823287 },
    { lat: 48.423781, lng: 18.82347 },
    { lat: 48.423816, lng: 18.8236 },
    { lat: 48.423798, lng: 18.823732 },
    { lat: 48.423845, lng: 18.823878 },
    { lat: 48.423865, lng: 18.824037 },
    { lat: 48.423923, lng: 18.824277 },
    { lat: 48.423966, lng: 18.824422 },
    { lat: 48.424007, lng: 18.824535 },
    { lat: 48.424006, lng: 18.82461 },
    { lat: 48.424033, lng: 18.824693 },
    { lat: 48.424036, lng: 18.824792 },
    { lat: 48.424084, lng: 18.824837 },
    { lat: 48.424111, lng: 18.8249 },
    { lat: 48.424205, lng: 18.824959 },
    { lat: 48.424275, lng: 18.825159 },
    { lat: 48.424355, lng: 18.825346 },
    { lat: 48.42447, lng: 18.825393 },
    { lat: 48.424524, lng: 18.825626 },
    { lat: 48.424596, lng: 18.825858 },
    { lat: 48.424556, lng: 18.826102 },
    { lat: 48.424573, lng: 18.82623 },
    { lat: 48.424579, lng: 18.826482 },
    { lat: 48.424571, lng: 18.826797 },
    { lat: 48.424579, lng: 18.826987 },
    { lat: 48.424572, lng: 18.82719 },
    { lat: 48.4246, lng: 18.827285 },
    { lat: 48.424702, lng: 18.827434 },
    { lat: 48.424774, lng: 18.827532 },
    { lat: 48.424894, lng: 18.827696 },
    { lat: 48.424983, lng: 18.827868 },
    { lat: 48.425209, lng: 18.828266 },
    { lat: 48.425383, lng: 18.828445 },
    { lat: 48.425498, lng: 18.828508 },
    { lat: 48.425611, lng: 18.828595 },
    { lat: 48.42573, lng: 18.828763 },
    { lat: 48.425843, lng: 18.828782 },
    { lat: 48.425959, lng: 18.828856 },
    { lat: 48.426141, lng: 18.828888 },
    { lat: 48.42618, lng: 18.828986 },
    { lat: 48.426342, lng: 18.829077 },
    { lat: 48.426464, lng: 18.82908 },
    { lat: 48.426596, lng: 18.829168 },
    { lat: 48.42682, lng: 18.829218 },
    { lat: 48.427099, lng: 18.829273 },
    { lat: 48.427389, lng: 18.829289 },
    { lat: 48.427627, lng: 18.829672 },
    { lat: 48.42773, lng: 18.829818 },
    { lat: 48.42796, lng: 18.829881 },
    { lat: 48.428123, lng: 18.829663 },
    { lat: 48.428177, lng: 18.829611 },
    { lat: 48.428405, lng: 18.829424 },
    { lat: 48.428785, lng: 18.829074 },
    { lat: 48.428989, lng: 18.828886 },
    { lat: 48.42924, lng: 18.828603 },
    { lat: 48.42926, lng: 18.828584 },
    { lat: 48.42933, lng: 18.828503 },
    { lat: 48.429574, lng: 18.82843 },
    { lat: 48.429856, lng: 18.828408 },
    { lat: 48.429974, lng: 18.828389 },
    { lat: 48.430193, lng: 18.828354 },
    { lat: 48.430446, lng: 18.828431 },
    { lat: 48.430739, lng: 18.828332 },
    { lat: 48.430928, lng: 18.828209 },
    { lat: 48.431236, lng: 18.82804 },
    { lat: 48.431783, lng: 18.827671 },
    { lat: 48.431906, lng: 18.82743 },
    { lat: 48.432102, lng: 18.827126 },
    { lat: 48.432244, lng: 18.826744 },
    { lat: 48.432305, lng: 18.826427 },
    { lat: 48.432363, lng: 18.82601 },
    { lat: 48.432428, lng: 18.825528 },
    { lat: 48.432662, lng: 18.825243 },
    { lat: 48.432961, lng: 18.824901 },
    { lat: 48.433174, lng: 18.824594 },
    { lat: 48.433334, lng: 18.824576 },
    { lat: 48.433472, lng: 18.824406 },
    { lat: 48.433657, lng: 18.824167 },
    { lat: 48.433844, lng: 18.824035 },
    { lat: 48.433941, lng: 18.823605 },
    { lat: 48.434261, lng: 18.822797 },
    { lat: 48.434546, lng: 18.82215 },
    { lat: 48.4347493, lng: 18.8218457 },
    { lat: 48.434812, lng: 18.821752 },
    { lat: 48.435002, lng: 18.821245 },
    { lat: 48.435325, lng: 18.820225 },
    { lat: 48.4353744, lng: 18.8201034 },
    { lat: 48.43553, lng: 18.819668 },
    { lat: 48.435679, lng: 18.819248 },
    { lat: 48.435893, lng: 18.819221 },
    { lat: 48.436201, lng: 18.819162 },
    { lat: 48.436366, lng: 18.819209 },
    { lat: 48.436719, lng: 18.8193 },
    { lat: 48.437016, lng: 18.819338 },
    { lat: 48.437258, lng: 18.819322 },
    { lat: 48.437367, lng: 18.819275 },
    { lat: 48.437554, lng: 18.819171 },
    { lat: 48.437664, lng: 18.818818 },
    { lat: 48.437761, lng: 18.818576 },
    { lat: 48.438, lng: 18.818338 },
    { lat: 48.438087, lng: 18.8182045 },
    { lat: 48.4380976, lng: 18.8181801 },
    { lat: 48.4381443, lng: 18.8181049 },
    { lat: 48.438199, lng: 18.817972 },
    { lat: 48.438482, lng: 18.817584 },
    { lat: 48.4386611, lng: 18.8173449 },
    { lat: 48.438699, lng: 18.817272 },
    { lat: 48.438856, lng: 18.817093 },
    { lat: 48.438916, lng: 18.817008 },
    { lat: 48.438921, lng: 18.817018 },
    { lat: 48.438908, lng: 18.817038 },
    { lat: 48.4388996, lng: 18.8171356 },
    { lat: 48.438877, lng: 18.817393 },
    { lat: 48.438845, lng: 18.81814 },
    { lat: 48.43878, lng: 18.818489 },
    { lat: 48.438801, lng: 18.818886 },
    { lat: 48.438847, lng: 18.819256 },
    { lat: 48.438696, lng: 18.819819 },
    { lat: 48.438403, lng: 18.820439 },
    { lat: 48.438282, lng: 18.820821 },
    { lat: 48.43832, lng: 18.821243 },
    { lat: 48.438288, lng: 18.821968 },
    { lat: 48.438352, lng: 18.822637 },
    { lat: 48.438367, lng: 18.823269 },
    { lat: 48.438357, lng: 18.824233 },
    { lat: 48.438182, lng: 18.824797 },
    { lat: 48.438299, lng: 18.825896 },
    { lat: 48.43834, lng: 18.826584 },
    { lat: 48.438264, lng: 18.826817 },
    { lat: 48.438124, lng: 18.827799 },
    { lat: 48.438409, lng: 18.828921 },
    { lat: 48.438724, lng: 18.829915 },
    { lat: 48.439203, lng: 18.830808 },
    { lat: 48.439013, lng: 18.831729 },
    { lat: 48.438504, lng: 18.832572 },
    { lat: 48.438096, lng: 18.832766 },
    { lat: 48.437621, lng: 18.833032 },
    { lat: 48.437568, lng: 18.832972 },
    { lat: 48.437352, lng: 18.833646 },
    { lat: 48.437217, lng: 18.834549 },
    { lat: 48.437136, lng: 18.835063 },
    { lat: 48.437092, lng: 18.835437 },
    { lat: 48.436988, lng: 18.835662 },
    { lat: 48.436666, lng: 18.83607 },
    { lat: 48.436532, lng: 18.836242 },
    { lat: 48.436381, lng: 18.836554 },
    { lat: 48.436358, lng: 18.836531 },
    { lat: 48.436168, lng: 18.83691 },
    { lat: 48.436085, lng: 18.837142 },
    { lat: 48.436026, lng: 18.837308 },
    { lat: 48.435984, lng: 18.837653 },
    { lat: 48.436112, lng: 18.838342 },
    { lat: 48.436366, lng: 18.838947 },
    { lat: 48.43668, lng: 18.839501 },
    { lat: 48.436704, lng: 18.839873 },
    { lat: 48.436569, lng: 18.840415 },
    { lat: 48.436552, lng: 18.840484 },
    { lat: 48.436322, lng: 18.841005 },
    { lat: 48.436131, lng: 18.842162 },
    { lat: 48.436114, lng: 18.843058 },
    { lat: 48.436134, lng: 18.843053 },
    { lat: 48.436202, lng: 18.843364 },
    { lat: 48.436251, lng: 18.843665 },
    { lat: 48.436637, lng: 18.844015 },
    { lat: 48.436964, lng: 18.844418 },
    { lat: 48.43705, lng: 18.844586 },
    { lat: 48.437068, lng: 18.844621 },
    { lat: 48.437212, lng: 18.844435 },
    { lat: 48.437521, lng: 18.844769 },
    { lat: 48.437885, lng: 18.845053 },
    { lat: 48.43811, lng: 18.84538 },
    { lat: 48.437923, lng: 18.845762 },
    { lat: 48.438336, lng: 18.846458 },
    { lat: 48.438358, lng: 18.846593 },
    { lat: 48.438462, lng: 18.846752 },
    { lat: 48.438664, lng: 18.847064 },
    { lat: 48.438816, lng: 18.847003 },
    { lat: 48.439296, lng: 18.846807 },
    { lat: 48.439582, lng: 18.847522 },
    { lat: 48.439628, lng: 18.848051 },
    { lat: 48.439686, lng: 18.848088 },
    { lat: 48.439732, lng: 18.848117 },
    { lat: 48.439873, lng: 18.848451 },
    { lat: 48.439935, lng: 18.848595 },
    { lat: 48.440055, lng: 18.849119 },
    { lat: 48.440113, lng: 18.849193 },
    { lat: 48.440345, lng: 18.849392 },
    { lat: 48.44043, lng: 18.849465 },
    { lat: 48.440546, lng: 18.849482 },
    { lat: 48.440807, lng: 18.849409 },
    { lat: 48.440924, lng: 18.849615 },
    { lat: 48.440972, lng: 18.849702 },
    { lat: 48.441117, lng: 18.849841 },
    { lat: 48.441289, lng: 18.849791 },
    { lat: 48.441634, lng: 18.8496 },
    { lat: 48.441678, lng: 18.849612 },
    { lat: 48.441847, lng: 18.8496559 },
    { lat: 48.441936, lng: 18.849679 },
    { lat: 48.442208, lng: 18.849543 },
    { lat: 48.442566, lng: 18.849894 },
    { lat: 48.442698, lng: 18.850024 },
    { lat: 48.443344, lng: 18.851053 },
    { lat: 48.4436375, lng: 18.8516603 },
    { lat: 48.443285, lng: 18.851816 },
    { lat: 48.442732, lng: 18.852467 },
    { lat: 48.442852, lng: 18.852877 },
    { lat: 48.443099, lng: 18.853201 },
    { lat: 48.443518, lng: 18.85375 },
    { lat: 48.443634, lng: 18.853973 },
    { lat: 48.443945, lng: 18.854568 },
    { lat: 48.444251, lng: 18.854935 },
    { lat: 48.444275, lng: 18.854964 },
    { lat: 48.444102, lng: 18.856038 },
    { lat: 48.444234, lng: 18.857344 },
    { lat: 48.444292, lng: 18.857508 },
    { lat: 48.444525, lng: 18.858182 },
    { lat: 48.445176, lng: 18.859976 },
    { lat: 48.445206, lng: 18.860125 },
    { lat: 48.445293, lng: 18.860938 },
    { lat: 48.445144, lng: 18.862373 },
    { lat: 48.445114, lng: 18.862784 },
    { lat: 48.44511, lng: 18.862838 },
    { lat: 48.445454, lng: 18.862534 },
    { lat: 48.445681, lng: 18.862336 },
    { lat: 48.445979, lng: 18.862163 },
    { lat: 48.446369, lng: 18.861861 },
    { lat: 48.446601, lng: 18.86176 },
    { lat: 48.4467, lng: 18.861717 },
    { lat: 48.446892, lng: 18.8617 },
    { lat: 48.44718, lng: 18.86173 },
    { lat: 48.447575, lng: 18.861709 },
    { lat: 48.44788, lng: 18.861834 },
    { lat: 48.448051, lng: 18.861842 },
    { lat: 48.448212, lng: 18.861709 },
    { lat: 48.448397, lng: 18.861374 },
    { lat: 48.448561, lng: 18.861163 },
    { lat: 48.448747, lng: 18.861015 },
    { lat: 48.44895, lng: 18.86103 },
    { lat: 48.44905, lng: 18.861079 },
    { lat: 48.44917, lng: 18.861138 },
    { lat: 48.449433, lng: 18.861195 },
    { lat: 48.449553, lng: 18.861348 },
    { lat: 48.449616, lng: 18.861621 },
    { lat: 48.449452, lng: 18.862084 },
    { lat: 48.449432, lng: 18.862813 },
    { lat: 48.449451, lng: 18.86307 },
    { lat: 48.449583, lng: 18.863243 },
    { lat: 48.449731, lng: 18.863267 },
    { lat: 48.449967, lng: 18.863235 },
    { lat: 48.450164, lng: 18.863462 },
    { lat: 48.450547, lng: 18.863762 },
    { lat: 48.45064, lng: 18.863838 },
    { lat: 48.450952, lng: 18.863894 },
    { lat: 48.450937, lng: 18.86398 },
    { lat: 48.450893, lng: 18.864 },
    { lat: 48.4508092, lng: 18.8640422 },
    { lat: 48.450766, lng: 18.864064 },
    { lat: 48.450544, lng: 18.864353 },
    { lat: 48.450161, lng: 18.864523 },
    { lat: 48.449946, lng: 18.864556 },
    { lat: 48.44968, lng: 18.864572 },
    { lat: 48.449535, lng: 18.864619 },
    { lat: 48.449299, lng: 18.864762 },
    { lat: 48.449086, lng: 18.865012 },
    { lat: 48.448943, lng: 18.865125 },
    { lat: 48.448762, lng: 18.865229 },
    { lat: 48.448563, lng: 18.865407 },
    { lat: 48.448405, lng: 18.865674 },
    { lat: 48.4482954, lng: 18.8658076 },
    { lat: 48.448243, lng: 18.865886 },
    { lat: 48.448223, lng: 18.865922 },
    { lat: 48.448091, lng: 18.866143 },
    { lat: 48.447973, lng: 18.866526 },
    { lat: 48.447959, lng: 18.86721 },
    { lat: 48.447964, lng: 18.867324 },
    { lat: 48.447969, lng: 18.867467 },
    { lat: 48.447999, lng: 18.86822 },
    { lat: 48.448379, lng: 18.869307 },
    { lat: 48.44842, lng: 18.86963 },
    { lat: 48.448623, lng: 18.870075 },
    { lat: 48.449033, lng: 18.87077 },
    { lat: 48.449327, lng: 18.87135 },
    { lat: 48.449452, lng: 18.871339 },
    { lat: 48.44949, lng: 18.871335 },
    { lat: 48.450035, lng: 18.871285 },
    { lat: 48.450349, lng: 18.871742 },
    { lat: 48.450509, lng: 18.871324 },
    { lat: 48.450627, lng: 18.87158 },
    { lat: 48.450816, lng: 18.871552 },
    { lat: 48.451007, lng: 18.870317 },
    { lat: 48.45053, lng: 18.869585 },
    { lat: 48.450735, lng: 18.868469 },
    { lat: 48.4515533, lng: 18.8691468 },
    { lat: 48.452003, lng: 18.869415 },
    { lat: 48.452192, lng: 18.869828 },
    { lat: 48.452463, lng: 18.869914 },
    { lat: 48.455115, lng: 18.872027 },
    { lat: 48.455261, lng: 18.872462 },
    { lat: 48.455517, lng: 18.872319 },
    { lat: 48.455595, lng: 18.873069 },
    { lat: 48.456114, lng: 18.873183 },
    { lat: 48.45645, lng: 18.872288 },
    { lat: 48.456565, lng: 18.872723 },
    { lat: 48.456745, lng: 18.873059 },
    { lat: 48.45696, lng: 18.873874 },
    { lat: 48.457506, lng: 18.874384 },
    { lat: 48.458146, lng: 18.874743 },
    { lat: 48.458497, lng: 18.874721 },
    { lat: 48.459131, lng: 18.874597 },
    { lat: 48.459228, lng: 18.874456 },
    { lat: 48.459293, lng: 18.8741 },
    { lat: 48.459396, lng: 18.873749 },
    { lat: 48.459464, lng: 18.873403 },
    { lat: 48.459754, lng: 18.873187 },
    { lat: 48.460047, lng: 18.873401 },
    { lat: 48.460517, lng: 18.87299 },
    { lat: 48.460491, lng: 18.872458 },
    { lat: 48.460647, lng: 18.872199 },
    { lat: 48.460926, lng: 18.872199 },
    { lat: 48.461333, lng: 18.872501 },
    { lat: 48.461548, lng: 18.872514 },
    { lat: 48.461549, lng: 18.872448 },
    { lat: 48.4623, lng: 18.871278 },
    { lat: 48.463324, lng: 18.871903 },
    { lat: 48.463511, lng: 18.871872 },
    { lat: 48.46355, lng: 18.872043 },
    { lat: 48.464462, lng: 18.87231 },
    { lat: 48.46475, lng: 18.872005 },
    { lat: 48.465476, lng: 18.872096 },
    { lat: 48.465616, lng: 18.871613 },
    { lat: 48.466046, lng: 18.871532 },
    { lat: 48.466388, lng: 18.870852 },
    { lat: 48.466539, lng: 18.871067 },
    { lat: 48.466375, lng: 18.871275 },
    { lat: 48.466436, lng: 18.871277 },
    { lat: 48.466637, lng: 18.871456 },
    { lat: 48.466798, lng: 18.871668 },
    { lat: 48.466825, lng: 18.871948 },
    { lat: 48.466765, lng: 18.872135 },
    { lat: 48.466815, lng: 18.872183 },
    { lat: 48.466769, lng: 18.872305 },
    { lat: 48.466843, lng: 18.872405 },
    { lat: 48.466656, lng: 18.872803 },
    { lat: 48.466614, lng: 18.873092 },
    { lat: 48.466468, lng: 18.873183 },
    { lat: 48.466465, lng: 18.873393 },
    { lat: 48.466382, lng: 18.873621 },
    { lat: 48.466955, lng: 18.874589 },
    { lat: 48.467102, lng: 18.874676 },
    { lat: 48.467018, lng: 18.875079 },
    { lat: 48.467213, lng: 18.875394 },
    { lat: 48.467295, lng: 18.875345 },
    { lat: 48.467404, lng: 18.875517 },
    { lat: 48.467307, lng: 18.87566 },
    { lat: 48.467355, lng: 18.875765 },
    { lat: 48.46738, lng: 18.87581 },
    { lat: 48.467507, lng: 18.87601 },
    { lat: 48.468347, lng: 18.875352 },
    { lat: 48.469156, lng: 18.875048 },
    { lat: 48.469302, lng: 18.875235 },
    { lat: 48.468949, lng: 18.875868 },
    { lat: 48.469784, lng: 18.876198 },
    { lat: 48.469909, lng: 18.875602 },
    { lat: 48.470007, lng: 18.874984 },
    { lat: 48.469991, lng: 18.874595 },
    { lat: 48.469918, lng: 18.874192 },
    { lat: 48.46969, lng: 18.873696 },
    { lat: 48.469338, lng: 18.872829 },
    { lat: 48.469328, lng: 18.872533 },
    { lat: 48.469378, lng: 18.872176 },
    { lat: 48.469447, lng: 18.871983 },
    { lat: 48.469517, lng: 18.871746 },
    { lat: 48.469593, lng: 18.871568 },
    { lat: 48.46974, lng: 18.871394 },
    { lat: 48.46995, lng: 18.87122 },
    { lat: 48.470232, lng: 18.871016 },
    { lat: 48.470375, lng: 18.87087 },
    { lat: 48.470443, lng: 18.870716 },
    { lat: 48.470508, lng: 18.870443 },
    { lat: 48.470525, lng: 18.87027 },
    { lat: 48.470519, lng: 18.870029 },
    { lat: 48.470474, lng: 18.869742 },
    { lat: 48.470462, lng: 18.869519 },
    { lat: 48.470481, lng: 18.869324 },
    { lat: 48.470533, lng: 18.86899 },
    { lat: 48.470605, lng: 18.868744 },
    { lat: 48.470705, lng: 18.868487 },
    { lat: 48.470723, lng: 18.868447 },
    { lat: 48.47084, lng: 18.868181 },
    { lat: 48.47088, lng: 18.867948 },
    { lat: 48.470896, lng: 18.867239 },
    { lat: 48.470915, lng: 18.867037 },
    { lat: 48.470971, lng: 18.866762 },
    { lat: 48.47097, lng: 18.866688 },
    { lat: 48.470964, lng: 18.866648 },
    { lat: 48.470917, lng: 18.866572 },
    { lat: 48.47086, lng: 18.866543 },
    { lat: 48.470738, lng: 18.866563 },
    { lat: 48.470446, lng: 18.866672 },
    { lat: 48.4703, lng: 18.866694 },
    { lat: 48.470116, lng: 18.866665 },
    { lat: 48.469939, lng: 18.866564 },
    { lat: 48.469852, lng: 18.866409 },
    { lat: 48.469782, lng: 18.866179 },
    { lat: 48.469746, lng: 18.865915 },
    { lat: 48.469777, lng: 18.865625 },
    { lat: 48.469782, lng: 18.865505 },
    { lat: 48.469766, lng: 18.865418 },
    { lat: 48.469719, lng: 18.865323 },
    { lat: 48.469601, lng: 18.86523 },
    { lat: 48.469456, lng: 18.865094 },
    { lat: 48.469395, lng: 18.86491 },
    { lat: 48.469417, lng: 18.864731 },
    { lat: 48.469457, lng: 18.864533 },
    { lat: 48.469463, lng: 18.86437 },
    { lat: 48.469432, lng: 18.864217 },
    { lat: 48.469354, lng: 18.863867 },
    { lat: 48.469322, lng: 18.863438 },
    { lat: 48.469284, lng: 18.8631 },
    { lat: 48.469234, lng: 18.862848 },
    { lat: 48.469089, lng: 18.862372 },
    { lat: 48.469035, lng: 18.862187 },
    { lat: 48.469092, lng: 18.861928 },
    { lat: 48.469265, lng: 18.86191 },
    { lat: 48.46942, lng: 18.861889 },
    { lat: 48.469513, lng: 18.861828 },
    { lat: 48.469592, lng: 18.861719 },
    { lat: 48.469761, lng: 18.861421 },
    { lat: 48.469971, lng: 18.861216 },
    { lat: 48.470034, lng: 18.861131 },
    { lat: 48.47005, lng: 18.861028 },
    { lat: 48.470038, lng: 18.86096 },
    { lat: 48.469993, lng: 18.860901 },
    { lat: 48.469859, lng: 18.860892 },
    { lat: 48.469605, lng: 18.860922 },
    { lat: 48.469472, lng: 18.860901 },
    { lat: 48.469406, lng: 18.860806 },
    { lat: 48.469275, lng: 18.860298 },
    { lat: 48.469178, lng: 18.859821 },
    { lat: 48.4692, lng: 18.859638 },
    { lat: 48.469239, lng: 18.859563 },
    { lat: 48.469386, lng: 18.859511 },
    { lat: 48.469581, lng: 18.859454 },
    { lat: 48.469785, lng: 18.859348 },
    { lat: 48.469995, lng: 18.859298 },
    { lat: 48.470246, lng: 18.85931 },
    { lat: 48.470417, lng: 18.859319 },
    { lat: 48.470475, lng: 18.859251 },
    { lat: 48.470503, lng: 18.85915 },
    { lat: 48.470494, lng: 18.859019 },
    { lat: 48.47044, lng: 18.85882 },
    { lat: 48.470325, lng: 18.858657 },
    { lat: 48.470277, lng: 18.8586153 },
    { lat: 48.470142, lng: 18.858498 },
    { lat: 48.469882, lng: 18.858356 },
    { lat: 48.46979, lng: 18.858265 },
    { lat: 48.469754, lng: 18.858127 },
    { lat: 48.46976, lng: 18.857935 },
    { lat: 48.469825, lng: 18.857729 },
    { lat: 48.469923, lng: 18.857597 },
    { lat: 48.470064, lng: 18.857447 },
    { lat: 48.47012, lng: 18.8573 },
    { lat: 48.470125, lng: 18.856875 },
    { lat: 48.470093, lng: 18.856693 },
    { lat: 48.469992, lng: 18.85642 },
    { lat: 48.469979, lng: 18.856206 },
    { lat: 48.47, lng: 18.855533 },
    { lat: 48.469985, lng: 18.855365 },
    { lat: 48.469953, lng: 18.8553 },
    { lat: 48.469877, lng: 18.855277 },
    { lat: 48.469532, lng: 18.855781 },
    { lat: 48.469423, lng: 18.855903 },
    { lat: 48.469316, lng: 18.855904 },
    { lat: 48.469006, lng: 18.855782 },
    { lat: 48.468725, lng: 18.855699 },
    { lat: 48.468266, lng: 18.855343 },
    { lat: 48.467922, lng: 18.855109 },
    { lat: 48.467561, lng: 18.854824 },
    { lat: 48.467498, lng: 18.85473 },
    { lat: 48.467465, lng: 18.854579 },
    { lat: 48.467462, lng: 18.854299 },
    { lat: 48.467403, lng: 18.853939 },
    { lat: 48.467446, lng: 18.853674 },
    { lat: 48.467439, lng: 18.853597 },
    { lat: 48.467404, lng: 18.853516 },
    { lat: 48.467345, lng: 18.853425 },
    { lat: 48.467225, lng: 18.853254 },
    { lat: 48.467155, lng: 18.853023 },
    { lat: 48.467121, lng: 18.852793 },
    { lat: 48.467123, lng: 18.852618 },
    { lat: 48.467254, lng: 18.852306 },
    { lat: 48.467323, lng: 18.852038 },
    { lat: 48.467345, lng: 18.851905 },
    { lat: 48.467364, lng: 18.851908 },
    { lat: 48.46741, lng: 18.851913 },
    { lat: 48.467434, lng: 18.851917 },
    { lat: 48.473225, lng: 18.851894 },
    { lat: 48.473103, lng: 18.851726 },
    { lat: 48.473073, lng: 18.851549 },
    { lat: 48.4729, lng: 18.851561 },
    { lat: 48.472794, lng: 18.851353 },
    { lat: 48.472664, lng: 18.851191 },
    { lat: 48.472407, lng: 18.851257 },
    { lat: 48.472365, lng: 18.851122 },
    { lat: 48.472181, lng: 18.8506 },
    { lat: 48.472044, lng: 18.850371 },
    { lat: 48.472005, lng: 18.850049 },
    { lat: 48.471852, lng: 18.849471 },
    { lat: 48.471923, lng: 18.849388 },
    { lat: 48.471992, lng: 18.849166 },
    { lat: 48.472372, lng: 18.849569 },
    { lat: 48.472829, lng: 18.849622 },
    { lat: 48.473613, lng: 18.849763 },
    { lat: 48.473775, lng: 18.849687 },
    { lat: 48.474139, lng: 18.849576 },
    { lat: 48.474378, lng: 18.849496 },
    { lat: 48.474348, lng: 18.849772 },
    { lat: 48.474304, lng: 18.850062 },
    { lat: 48.474393, lng: 18.850564 },
    { lat: 48.474433, lng: 18.850568 },
    { lat: 48.474451, lng: 18.850112 },
    { lat: 48.474494, lng: 18.849774 },
    { lat: 48.474553, lng: 18.84955 },
    { lat: 48.474725, lng: 18.849128 },
    { lat: 48.474813, lng: 18.84885 },
    { lat: 48.474838, lng: 18.848484 },
    { lat: 48.474886, lng: 18.848163 },
    { lat: 48.474942, lng: 18.848024 },
    { lat: 48.475091, lng: 18.847836 },
    { lat: 48.475209, lng: 18.847672 },
    { lat: 48.4753, lng: 18.847462 },
    { lat: 48.475359, lng: 18.847202 },
    { lat: 48.475374, lng: 18.846805 },
    { lat: 48.475288, lng: 18.846178 },
    { lat: 48.475138, lng: 18.845442 },
    { lat: 48.47506, lng: 18.844986 },
    { lat: 48.475055, lng: 18.844693 },
    { lat: 48.475092, lng: 18.844434 },
    { lat: 48.475159, lng: 18.844115 },
    { lat: 48.475216, lng: 18.843911 },
    { lat: 48.475482, lng: 18.843324 },
    { lat: 48.475909, lng: 18.842718 },
    { lat: 48.476114, lng: 18.842351 },
    { lat: 48.476178, lng: 18.842193 },
    { lat: 48.476332, lng: 18.8417 },
    { lat: 48.476397, lng: 18.841398 },
    { lat: 48.476412, lng: 18.841202 },
    { lat: 48.476405, lng: 18.840938 },
    { lat: 48.476403, lng: 18.840752 },
    { lat: 48.47644, lng: 18.840618 },
    { lat: 48.47658, lng: 18.840265 },
    { lat: 48.476652, lng: 18.840147 },
    { lat: 48.476846, lng: 18.839965 },
    { lat: 48.476962, lng: 18.839773 },
    { lat: 48.477001, lng: 18.839611 },
    { lat: 48.477057, lng: 18.839263 },
    { lat: 48.47712, lng: 18.838978 },
    { lat: 48.477146, lng: 18.838913 },
    { lat: 48.477164, lng: 18.839077 },
    { lat: 48.477214, lng: 18.83916 },
    { lat: 48.477363, lng: 18.839448 },
    { lat: 48.477673, lng: 18.839423 },
    { lat: 48.477935, lng: 18.839083 },
    { lat: 48.478188, lng: 18.838957 },
    { lat: 48.47877, lng: 18.839224 },
    { lat: 48.47895, lng: 18.838568 },
    { lat: 48.478451, lng: 18.838458 },
    { lat: 48.478101, lng: 18.83792 },
    { lat: 48.4779, lng: 18.837279 },
    { lat: 48.477945, lng: 18.837176 },
    { lat: 48.47812, lng: 18.836814 },
    { lat: 48.478155, lng: 18.836585 },
    { lat: 48.478148, lng: 18.836376 },
    { lat: 48.478075, lng: 18.835521 },
    { lat: 48.478011, lng: 18.835217 },
    { lat: 48.478696, lng: 18.835052 },
    { lat: 48.479107, lng: 18.834951 },
    { lat: 48.479289, lng: 18.835046 },
    { lat: 48.479395, lng: 18.834963 },
    { lat: 48.479347, lng: 18.8342 },
    { lat: 48.48037, lng: 18.833798 },
    { lat: 48.480569, lng: 18.833694 },
    { lat: 48.480655, lng: 18.833868 },
    { lat: 48.480725, lng: 18.834067 },
    { lat: 48.4814504, lng: 18.8331754 },
    { lat: 48.48179, lng: 18.832758 },
    { lat: 48.481793, lng: 18.832707 },
    { lat: 48.481847, lng: 18.832664 },
    { lat: 48.481765, lng: 18.832041 },
    { lat: 48.481651, lng: 18.831624 },
    { lat: 48.481563, lng: 18.83121 },
    { lat: 48.481624, lng: 18.831088 },
    { lat: 48.481505, lng: 18.830425 },
    { lat: 48.481445, lng: 18.830436 },
    { lat: 48.48145, lng: 18.830119 },
    { lat: 48.481726, lng: 18.830021 },
    { lat: 48.481784, lng: 18.829928 },
    { lat: 48.481844, lng: 18.828783 },
    { lat: 48.48205, lng: 18.828613 },
    { lat: 48.482348, lng: 18.82878 },
    { lat: 48.482158, lng: 18.827419 },
    { lat: 48.481972, lng: 18.826683 },
    { lat: 48.481421, lng: 18.825588 },
    { lat: 48.481346, lng: 18.825242 },
    { lat: 48.481261, lng: 18.824394 },
    { lat: 48.481303, lng: 18.823375 },
    { lat: 48.481367, lng: 18.822965 },
    { lat: 48.481704, lng: 18.82177 },
    { lat: 48.481975, lng: 18.820923 },
    { lat: 48.481089, lng: 18.820834 },
    { lat: 48.480386, lng: 18.821398 },
    { lat: 48.480146, lng: 18.821502 },
    { lat: 48.479794, lng: 18.820768 },
    { lat: 48.479527, lng: 18.820988 },
    { lat: 48.479295, lng: 18.821536 },
    { lat: 48.478589, lng: 18.821299 },
    { lat: 48.478463, lng: 18.82091 },
    { lat: 48.478421, lng: 18.820271 },
    { lat: 48.478178, lng: 18.819584 },
    { lat: 48.478148, lng: 18.819514 },
    { lat: 48.477881, lng: 18.819576 },
    { lat: 48.477651, lng: 18.819312 },
    { lat: 48.477408, lng: 18.81904 },
    { lat: 48.477098, lng: 18.819304 },
    { lat: 48.476995, lng: 18.819424 },
    { lat: 48.476674, lng: 18.818805 },
    { lat: 48.47663, lng: 18.818665 },
    { lat: 48.476574, lng: 18.817993 },
    { lat: 48.476604, lng: 18.81765 },
    { lat: 48.47674, lng: 18.817116 },
    { lat: 48.476876, lng: 18.81664 },
    { lat: 48.476981, lng: 18.816451 },
    { lat: 48.477412, lng: 18.815888 },
    { lat: 48.477621, lng: 18.815463 },
    { lat: 48.477742, lng: 18.815282 },
    { lat: 48.47787, lng: 18.815032 },
    { lat: 48.477972, lng: 18.814983 },
    { lat: 48.478114, lng: 18.814963 },
    { lat: 48.478477, lng: 18.815203 },
    { lat: 48.478919, lng: 18.815401 },
    { lat: 48.479216, lng: 18.815437 },
    { lat: 48.48007, lng: 18.815765 },
    { lat: 48.481095, lng: 18.815534 },
    { lat: 48.481351, lng: 18.815474 },
    { lat: 48.481544, lng: 18.815502 },
    { lat: 48.481636, lng: 18.815547 },
  ],
};

export default UnitsŽarnovica;
